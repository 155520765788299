const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {SOURCE_DND3_FRCS} = require('./../../sourceList')
const {
  LANG_ROUSHOUM,
  LANG_TUIGAN,
} = require('./../../languageIdList')
const {
  ALPHABET_IMASKARI,
  ALPHABET_THORASS,
} = require('./../../alphabetList')

module.exports = {
  id: LANG_ROUSHOUM,
  alphabetId: [
    ALPHABET_IMASKARI,
    ALPHABET_THORASS,
  ],
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Roushoum',
  name: {
    nominative: 'Рушум',
    genitive: 'Рушума',
    instrumental: 'Рушумом',
    prepositional: 'Рушуме',
  },
  typicalSpeakers: 'Имаскари',
  description: {
    text: `Предок [Туйганского](LANG:${LANG_TUIGAN}) языка.`,
    source: {
      id: SOURCE_DND3_FRCS,
      page: 86,
    },
  },
  isReady: true,
  isRealLang: true,
}
