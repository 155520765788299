const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  GEAR_CANDLE,
} = require('./../../../../../gearIdList')
const {
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_GATE,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
} = require('./../../../../../pcClassIdList')
const {
  MAGIC_ITEM_CANDLE_OF_INVOCATION,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_CANDLE_OF_INVOCATION,
  name: `Свеча мольбы`,
  nameEn: `Candle of Invocation`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_VERY_RARE,
  gearType: GEAR_CANDLE,
  attunement: true,
  isConsumable: true,
  description: `Эта тонкая восковая свеча посвящена определённому божеству и настроена на мировоззрение этого божества. Мировоззрение свечи можно определить заклинанием [Обнаружение добра и зла](SPELL:${SPELL_DETECT_EVIL_AND_GOOD}). Мастер сам выбирает божество и мировоззрение, либо определяет их случайным образом.

| к20   | Мировоззрение             |
|-------|---------------------------|
| 1–2   | Хаотично-злое             |
| 3–4   | Хаотично-нейтральное      |
| 5–7   | Хаотично-доброе           |
| 8–9   | Нейтрально-злое           |
| 10–11 | Нейтральное               |
| 12–13 | Нейтрально-доброе         |
| 14–15 | Принципиально-злое        |
| 16–17 | Принципиально-нейтральное |
| 18–20 | Принципиально-доброе      |

Магия свечи активируется, когда свечу зажигают, что совершается действием. Погорев 4 часа, свеча уничтожается. Вы можете затушить свечу раньше, чтобы воспользоваться ей же позже. Минимальная единица учёта времени горения — 1 минута.

Будучи зажжённой, свеча испускает тусклый свет в радиусе 30 футов. Все существа в области этого света, чьё мировоззрение совпадает с мировоззрением свечи, совершают броски атаки, испытания и проверки характеристик с преимуществом. Кроме того, [жрецы](PC_CLASS:${PC_CLASS_CLERIC}) и [друиды](PC_CLASS:${PC_CLASS_DRUID}) в области света, чьё мировоззрение совпадает с мировоззрением свечи, могут сотворять подготовленные заклинания 1 уровня, не тратя ячейки заклинаний, и эффект таких заклинаний будет как у заклинаний, наложенных с использованием ячейки 1 уровня.

В качестве альтернативы, когда Вы впервые зажигаете совершенно новую свечу, Вы можете наложить ей заклинание [Врата](SPELL:${SPELL_GATE}). Это мгновенно уничтожает свечу.`,
  genderId: GENDER_FEMALE,
  spellIdList: [SPELL_GATE],
  source: {
    id: SOURCE_DMG,
    page: 199,
  },
}
