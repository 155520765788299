const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ABERRATION,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_SWIM,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    CONDITION_PRONE,
  } = require('./../../../../conditionList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    CREATURE_GIBBERING_MOUTHER,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Бормочущий ротовик',
  nameEn: 'Gibbering Mouther',
  id: CREATURE_GIBBERING_MOUTHER,
  description: `Из всех ужасов, созданных отвратительным колдовством, бормочущие ротовики — одни из самых ужасных и порочных порождений. Это существо состоит из глаз, ртов и сжиженных тел его прежних жертв. Впавшие в безумие из-за разрушения их тел и поглощения ротовиком, эти жертвы бессвязно бормочут, пожирая всё, до чего смогут добраться.

# Бесформенное тело

Тело бормочущего ротовика представляет собой аморфную массу из ртов и глаз, передвигающуюся, цепляясь за поверхность несколькими ртами, и подтаскивая остальное тело. И хотя они медленно передвигаются, они легко могут плавать в воде, грязи и зыбучих песках.

# Безумное бормотание

Когда бормочущий ротовик чует добычу, его рты начинают бормотать и шептать на разные голоса: басовитыми и высокими, плачущими и воющими, орущими в агонии и экстазе одновременно. Эта какофония голосов заполняет собой всё, что может услышать существо, и большинство бежит от неё в ужасе. Остальные же, подавленные страхом и безумием, стоят парализованными, глядя, как ужасающее существо подползает всё ближе, чтобы поглотить их.

# Всепоглощающие

Ведомый жаждой поглощать всех, до кого он доберётся, бормочущий ротовик подползает к парализованным его безумными голосами жертвам и хор на время замолкает, пока пасти обгладывают и поглощают плоть. Это чудовище разжижает камень, к которому прикасается, затрудняя бегство существ, которым удалось справиться с воздействием его бормотания.

Бормочущий ротовик не оставляет от своих жертв даже следа. Однако когда останки тела жертвы будут поглощены, её глаза и рот всплывают на поверхность чудовища, готовые присоединиться к хору мучительного бормотания, который услышит следующая жертва твари.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 26,
  },
  armor: 9,
  hp: {
    diceCount: 9,
  },
  cr: CR_2,
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_SWIM]: 10,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 16,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  immunityConditionList: [
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  featureList: [
    {
      name: 'Искажённая земля',
      description: `Пол в радиусе 10 футов вокруг ★СУЩЕСТВО★ является воскообразной труднопроходимой местностью. Все существа, начинающие ход в этой области, должны пройти испытание Силы СЛ 10, иначе их скорость уменьшается до 0 до начала их следующего хода.`,
    },
    {
      name: 'Бормотание',
      description: `★СУЩЕСТВО★ постоянно неразборчиво бормочет, пока видит другое существо, и ★сам★ дееспособен. Все существа, начинающие ход в пределах 20 футов от ★него★, и слышащие бормотание, должны пройти испытание Мудрости СЛ 10. При провале существо не может совершать реакции до начала своего следующего хода и бросает к8, чтобы определить, что оно будет делать в текущем ходу.

* При результате от «1» до «4» существо ничего не делает.

* При результате «5» или «6» существо не совершает ни действий, ни бонусных действий, и всё своё перемещение тратит на то, чтобы перемещаться в случайным образом выбранном направлении.

* При результате «7» или «8» существо совершает рукопашную атаку по случайным образом выбранному существу в пределах досягаемости, или не делает ничего, если не может совершить такой атаки.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает одну атаку _Укусом_ и, если может, использует _Ослепляющий плевок_.`,
    },
    {
      name: 'Укусы',
      description: `Если размер цели не больше Среднего, она должна пройти испытание Силы СЛ 10, иначе будет сбита с ног. Если цель убита этим уроном, ★СУЩЕСТВО★ поглощает её.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 5,
          diceType: 6,
        },
      },
    },
    {
      name: 'Ослепляющий плевок',
      description: `★СУЩЕСТВО★ выплёвывает шар слизи в точку, которую видит в пределах 15 футов. Шар при столкновении взрывается ослепительной вспышкой. Все существа в пределах 5 футов от вспышки должны пройти испытание Ловкости СЛ 13, иначе станут ослеплёнными до конца следующего хода ★СУЩЕСТВО★.`,
      restore: 5,
    },
  ],
  genderId: GENDER_MALE,
}
