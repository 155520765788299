const {
  SOURCE_DMG,
  SOURCE_GAME_BG_3,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  GEAR_PLATE_ARMOR,
} = require('./../../../../../gearIdList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_BLESS,
  SPELL_CLAIRVOYANCE,
  SPELL_DETECT_THOUGHTS,
  SPELL_ENLARGE_REDUCE,
  SPELL_ETHEREALNESS,
  SPELL_GASEOUS_FORM,
  SPELL_HASTE,
  SPELL_IDENTIFY,
  SPELL_GATE,
} = require('./../../../../../spellIdList')
const {
  CREATURE_GIANT_RAT,
  CREATURE_RAT,
  CREATURE_SWARM_OF_RATS,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_ARMOR,
  MGC_TYPE_POTION,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_COMMON,
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_PEARL_OF_POWER,
  MAGIC_ITEM_PERIAPT_OF_HEALTH,
  MAGIC_ITEM_PERIAPT_OF_PROOF_AGAINST_POISON,
  MAGIC_ITEM_PERIAPT_OF_WOUND_CLOSURE,
  MAGIC_ITEM_PHILTER_OF_LOVE,
  MAGIC_ITEM_PIPES_OF_HAUNTING,
  MAGIC_ITEM_PIPES_OF_THE_SEWERS,
  MAGIC_ITEM_PLATE_ARMOR_OF_ETHEREALNESS,
  MAGIC_ITEM_PORTABLE_HOLE,
  MAGIC_ITEM_POTION_OF_ANIMAL_FRIENDSHIP,
  MAGIC_ITEM_POTION_OF_CLAIRVOYANCE,
  MAGIC_ITEM_POTION_OF_CLIMBING,
  MAGIC_ITEM_POTION_OF_DIMINUTION,
  MAGIC_ITEM_POTION_OF_FIRE_BREATH,
  MAGIC_ITEM_POTION_OF_FLYING,
  MAGIC_ITEM_POTION_OF_GASEOUS_FORM,
  MAGIC_ITEM_POTION_OF_GROWTH,
  MAGIC_ITEM_POTION_OF_HEALING_COMMON,
  MAGIC_ITEM_POTION_OF_HEROISM,
  MAGIC_ITEM_POTION_OF_INVISIBILITY,
  MAGIC_ITEM_POTION_OF_INVULNERABILITY,
  MAGIC_ITEM_POTION_OF_LONGEVITY,
  MAGIC_ITEM_POTION_OF_MIND_READING,
  MAGIC_ITEM_POTION_OF_POISON,
  MAGIC_ITEM_POTION_OF_SPEED,
  MAGIC_ITEM_POTION_OF_VITALITY,
  MAGIC_ITEM_POTION_OF_WATER_BREATHING,
  MAGIC_ITEM_BAG_OF_HOLDING,
  MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_PIPES_OF_HAUNTING,
    name: `Свирель ужаса`,
    nameEn: `Pipes of Haunting`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Вы должны владеть духовыми музыкальными инструментами, чтобы использовать эту свирель.

У неё есть 3 заряда. Вы можете действием поиграть на ней и потратить 1 заряд, чтобы издать жуткую мелодию. Все существа в пределах 30 футов от Вас, слышащие Вашу мелодию, должны пройти испытание Мудрости СЛ 15, иначе они станут испуганными Вами на 1 минуту. Если хотите, все существа в этой области, не враждебные по отношению к Вам, автоматически преуспеют в этом испытании. Существа, провалившие испытание, могут повторять его в конце каждого своего хода, оканчивая эффект на себе при успехе. Существо, прошедшее испытание, получает иммунитет к этой свирели на 24 часа.

Свирель ежедневно восстанавливает 1к3 заряда на рассвете.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 200,
    },
  },
  {
    id: MAGIC_ITEM_PIPES_OF_THE_SEWERS,
    name: `Свирель канализации`,
    nameEn: `Pipes of the Sewers`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Вы должны владеть духовыми музыкальными инструментами, чтобы использовать эту свирель. Пока Вы настроены на неё, [обычные](CREATURE:${CREATURE_RAT}) и [гигантские крысы](CREATURE:${CREATURE_GIANT_RAT}) безразличны к Вам и не будут Вас атаковать, если Вы им не угрожаете и не вредите им.

У свирели есть 3 заряда. Если Вы играете на ней действием, Вы можете бонусным действием потратить от 1 до 3 зарядов, призывая по одному [рою крыс](CREATURE:${CREATURE_SWARM_OF_RATS}) за каждый потраченный заряд, при условии, что в пределах полумили от Вас есть достаточное количество крыс, которых можно призвать свирелью (на усмотрение Мастера). Если достаточного количества крыс нет, заряд тратится впустую. Призванные рои движутся к источнику музыки кратчайшим возможным маршрутом, но во всём остальном не находятся под Вашим контролем. Свирель ежедневно восстанавливает 1к3 заряда на рассвете.

Каждый раз, когда [рой крыс](CREATURE:${CREATURE_SWARM_OF_RATS}), не находящийся под контролем другого существа, оказывается в пределах 30 футов от Вас, пока Вы играете на этой свирели, Вы можете совершить проверку Харизмы, противопоставленную проверке Мудрости крыс. Если Вы проигрываете состязание, [рой](CREATURE:${CREATURE_SWARM_OF_RATS}) действует как обычно, и не может становиться одержимым музыки этой свирели в течение 24 часов. Если Вы выиграете состязание, [рой](CREATURE:${CREATURE_SWARM_OF_RATS}) становится одержимым музыкой свирели и становится дружественным к Вам и Вашим спутникам, пока Вы каждый раунд продолжаете играть на свирели действием. Дружественный [рой](CREATURE:${CREATURE_SWARM_OF_RATS}) подчиняется Вашим командам. Если Вы не отдаёте команды дружественному рою, он защищается, но других действий не совершает. Если дружественный [рой](CREATURE:${CREATURE_SWARM_OF_RATS}) начинает ход, и при этом не слышит музыки свирели, Ваш контроль над этим роем заканчивается, [рой] (CREATURE:${CREATURE_SWARM_OF_RATS}) действует как обычно, и не может быть одержим музыкой свирели в течение следующих 24 часов.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 199,
    },
  },
  {
    id: MAGIC_ITEM_PERIAPT_OF_HEALTH,
    name: `Медальон здоровья`,
    nameEn: `Periapt of Health`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Вы обладаете иммунитетом ко всем болезням, пока носите этот медальон.

Если Вы уже болеете, эффект болезни подавляются на время, пока Вы носите этот медальон.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 184,
    },
  },
  {
    id: MAGIC_ITEM_PERIAPT_OF_PROOF_AGAINST_POISON,
    name: `Медальон защиты от яда`,
    nameEn: `Periapt of Proof against Poison`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `На этой изысканной серебряной цепочке висит подвеска из чёрного драгоценного камня бриллиантовой огранки. Пока Вы её носите, яды не оказывают на Вас эффекта.

Вы обладаете иммунитетом к состоянию «отравлен» и к урону ядом.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 184,
    },
  },
  {
    id: MAGIC_ITEM_PERIAPT_OF_WOUND_CLOSURE,
    name: `Медальон затягивающихся ран`,
    nameEn: `Periapt of Wound Closure`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите этот медальон, Ваше состояние стабилизируется каждый раз, когда Вы находитесь в умирающем состоянии в начале своего хода.

Кроме того, каждый раз, когда Вы бросаете Кости Хитов для восстановления хитов, Вы удваиваете число восстановленных хитов.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 183,
    },
  },
  {
    id: MAGIC_ITEM_PHILTER_OF_LOVE,
    name: `Приворотное зелье любви`,
    nameAlt: `Любовное зелье`,
    nameEn: `Philter of Love`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Жидкость представляет собой розовую, шипучую жидкость, с пузырьками в виде сердец.

Вы становитесь на 1 час очарованы первым же существом, которое Вы увидите в течение 10 минут после того, как выпили это зелье. Если существо принадлежит к биологическому виду и полу, которые привлекают Вас в обычных условиях, то Вы воспринимаете его как свою истинную любовь, пока очарованы им.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 180,
    },
  },
  {
    id: MAGIC_ITEM_PLATE_ARMOR_OF_ETHEREALNESS,
    name: `Латный доспех эфирности`,
    nameEn: `Plate Armor of Etherealness`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_PLATE_ARMOR,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Если Вы носите этот доспех, Вы можете действием произнести его командное слово, чтобы получить эффект заклинания [Эфирность](SPELL:${SPELL_ETHEREALNESS}), который длится 10 минут, либо пока Вы не снимете доспех, либо пока Вы не произнесёте командное слово ещё раз.

Вы не можете использовать это свойство повторно до следующего рассвета.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_ETHEREALNESS],
    source: {
      id: SOURCE_DMG,
      page: 180,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_CLAIRVOYANCE,
    name: `Зелье ясновидения`,
    nameEn: `Potion of Clairvoyance`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `Выпив это зелье, Вы получаете эффект заклинания [Подсматривание](SPELL:${SPELL_CLAIRVOYANCE}). В желтоватой жидкости зелья плавают глазные яблоки, но они исчезают, когда пузырёк открывают.`,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_CLAIRVOYANCE],
    source: {
      id: SOURCE_DMG,
      page: 166,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_POISON,
    name: `Зелье яда`,
    nameEn: `Potion of Poison`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Эта смесь выглядит, пахнет и обладает таким же вкусом, что и [Зелье лечения](MAGIC_ITEM:${MAGIC_ITEM_POTION_OF_HEALING_COMMON}) или другое полезное зелье. Однако, это самый настоящий яд, чья истинная природа скрыта магией иллюзий. Заклинание [Опознание](SPELL:${SPELL_IDENTIFY}) раскрывает этот обман.

Если Вы всё же выпьете это зелье, Вы получаете урон ядом 3к6 и должны пройти испытание Телосложения СЛ 13, иначе станете отравленным. В начале каждого Вашего хода, пока Вы отравлены этим зельем, Вы получаете урон ядом 3к6. В конце каждого своего хода Вы можете повторять испытание. В случае успеха каждый урон ядом в последующих раундах будет уменьшен на 1к6. Яд прекращает действие, когда его урон станет равным нулю.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 166,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_MIND_READING,
    name: `Зелье чтения мыслей`,
    nameEn: `Potion of Mind Reading`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: [
      {
        header: 'Зелье чтения мыслей',
        text: `На вид это густое, фиолетовое зелье, с плавающим в нём небольшим овальным облачком.

Выпив это зелье, Вы получаете эффект заклинания [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) (СЛ испытания 13).`,
        source: {
          id: SOURCE_DMG,
          page: 166,
        },
      },
      {
        header: 'Зелье чтения мыслей',
        text: 'В этой жидкости взвесь из фрагментов памяти и восприятия — грубое созвездие мыслей.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_DETECT_THOUGHTS],
    source: {
      id: SOURCE_DMG,
      page: 166,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_DIMINUTION,
    name: `Зелье уменьшения`,
    nameEn: `Potion of Diminution`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `Маленький красный шарик в этой жидкости непрерывно сжимается до размера бусины и тут же расширяется, окрашивая прозрачную жидкость вокруг, после чего сжимается вновь. Тряска пузырька не прерывает этот процесс.

Когда Вы выпиваете это зелье, Вы получаете на 1к4 часа эффект «Уменьшение» заклинания [Увеличение/Уменьшение](SPELL:${SPELL_ENLARGE_REDUCE}) (концентрация не требуется). `,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_ENLARGE_REDUCE],
    source: {
      id: SOURCE_DMG,
      page: 166,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_GROWTH,
    name: `Зелье увеличения`,
    nameEn: `Potion of Growth`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Маленький красный шарик в этой жидкости непрерывно расширяется, окрашивая прозрачную жидкость вокруг, и тут же сжимается вновь. Тряска пузырька не прерывает этот процесс.

Когда Вы выпиваете это зелье, Вы получаете на 1к4 часа эффект «Увеличение» заклинания [Увеличение/Уменьшение](SPELL:${SPELL_ENLARGE_REDUCE}) (концентрация не требуется).`,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_ENLARGE_REDUCE],
    source: {
      id: SOURCE_DMG,
      page: 166,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_SPEED,
    name: `Зелье скорости`,
    nameEn: `Potion of Speed`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: `В жёлтой жидкости этого зелья видны чёрные полоски, и она перемешивается сама собой.

Когда Вы выпьете это зелье, Вы получите на 1 минуту эффект заклинания [Ускорение](SPELL:${SPELL_HASTE}) (концентрация не требуется).`,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_HASTE],
    source: {
      id: SOURCE_DMG,
      page: 166,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_FLYING,
    name: `Зелье полёта`,
    nameEn: `Potion of Flying`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: [
      {
        header: 'Зелье полёта',
        text: `Прозрачная жидкость этого зелья парит у горлышка пузырька, и в ней плавают молочно-белые примеси.

Выпив это зелье, Вы на 1 час получаете скорость полёта, равную Вашей скорости ходьбы, и можете парить. Если Вы находитесь в воздухе, когда зелье теряет силу, Вы падаете, если не обладаете другими средствами, позволяющими оставаться в полёте.`,
        source: {
          id: SOURCE_DMG,
          page: 166,
        },
      },
      {
        header: 'Зелье полёта',
        text: 'Это зелье настолько лёгкое, что кажется, подбрось его высоко вверх — и оно будет парить, словно лилия в воздушном пруду.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 166,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_WATER_BREATHING,
    name: `Зелье подводного дыхания`,
    nameEn: `Potion of Water Breathing`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `В мутно-зелёной жидкости, пахнущей морем, плавают небольшие пузырьки, похожие на медуз.

Вы можете дышать под водой в течение 1 часа после того, как выпьете это зелье.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_FIRE_BREATH,
    name: `Зелье огненного дыхания`,
    nameEn: `Potion of Fire Breath`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Зелье представляет собой оранжевую мерцающую жидкость, над которой стелется дымок, выходящий наружу каждый раз, когда Вы откупориваете пробку.

После того, как Вы выпили это зелье, Вы можете бонусным действием выдохнуть огонь на цель, находящуюся в пределах 30 футов от Вас. Цель должна пройти испытание Ловкости СЛ 13, получая урон огнём 4к6 при провале или половину этого урона при успехе. Эффект заканчивается после того, как Вы трижды используете огненное дыхание, или по истечении 1 часа.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_INVULNERABILITY,
    name: `Зелье неуязвимости`,
    nameEn: `Potion of Invulnerability`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `Это густое как сироп зелье выглядит как расплавленное железо.

В течение 1 минуты после того, как Вы Выпили это зелье, Вы обладаете сопротивлением ко всем видам урона.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_INVISIBILITY,
    name: `Зелье невидимости`,
    nameEn: `Potion of Invisibility`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: `Контейнер с этим зельем выглядит пустым, но на вес чувствуется, что он всё же содержит жидкость.

Выпив её, Вы становитесь невидимым на 1 час. Всё, что Вы несёте и носите, становится невидимым вместе с Вами. Эффект оканчивается преждевременно, если Вы атакуете или наложите заклинание.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_CLIMBING,
    name: `Зелье лазания`,
    nameEn: `Potion of Climbing`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Зелье представляет собой трёхцветную жидкость. Коричневый, серебряный и серый слои структурой напоминают рисунок на камне и не смешиваются между собой, как бы Вы ни трясли пузырёк.

Когда Вы выпиваете это зелье, Вы получаете на 1 час скорость лазания, равную Вашей скорости ходьбы. В течение этого времени Вы совершаете с преимуществом проверки Силы (Атлетика), совершённые, чтобы лезть.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_VITALITY,
    name: `Зелье живучести`,
    nameEn: `Potion of Vitality`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: `Алая жидкость этого зелья периодически пульсирует, напоминая биение сердца.

Если Вы выпьете это зелье, оно устранит всё имеющееся у Вас истощение, а также все болезни и яды, действующие на Вас. В течение следующих 24 часов Вы восстанавливаете максимум хитов за каждую использованную Кость Хитов.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_ANIMAL_FRIENDSHIP,
    name: 'Зелье дружбы с животными',
    nameAlt: 'Зелье общения с животными',
    nameEn: 'Potion of Animal Friendship',
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: [
      {
        header: 'Зелье дружбы с животными',
        text: `При взбалтывании этой мутной жидкости Вы видите рыбью чешую, кошачьи когти, беличью шерсть и прочие фрагменты.

После того как Вы выпили это зелье, Вы можете в течение одного часа неограниченно сотворять заклинание [Дружба с животными](SPELL:${SPELL_ANIMAL_FRIENDSHIP}) (СЛ испытания 13).`,
        source: {
          id: SOURCE_DMG,
          page: 165,
        },
      },
      {
        header: 'Зелье общения с животными',
        text: 'Из открытого флакона в нос бьют пьянящие запахи сена, мускуса и навоза.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_ANIMAL_FRIENDSHIP],
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_LONGEVITY,
    name: `Зелье долголетия`,
    nameEn: `Potion of Longevity`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: `В янтарной жидкости плавают хвост скорпиона, клык гадюки, мёртвый паук и крохотное сердце, которое вопреки всему всё ещё бьётся. Эти ингредиенты исчезают, когда зелье откупоривают.

После того как Вы выпьете это зелье, Ваш физический возраст уменьшается на 1к6 + 6 лет, при минимуме в 13 лет. Каждый раз, когда Вы в дальнейшем будете пить зелья долголетия, существует накопительный штраф 10 процентов, что вместо этого Вы постареете на 1к6 + 6 лет.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_HEROISM,
    name: `Зелье героизма`,
    nameEn: `Potion of Heroism`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `Это синее зелье пузырится и парит, словно оно находится в состоянии закипания.

После того, как Вы выпили это зелье, Вы на 1 час получаете 10 временных хитов. Кроме того, в течение этого часа Вы находитесь под эффектом заклинания [Благословение](SPELL:${SPELL_BLESS}) (концентрация не требуется).`,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_BLESS],
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_GASEOUS_FORM,
    name: `Зелье газообразной формы`,
    nameEn: `Potion of Gaseous Form`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `Когда Вы выпиваете это зелье, Вы получаете эффект заклинания [Газообразная форма](SPELL:${SPELL_GASEOUS_FORM}) (концентрация не требуется) на 1 час, или пока Вы бонусным действием не прервёте его. Пузырёк с этим зельем как будто содержит туман, перетекающий, словно вода. `,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_GASEOUS_FORM],
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_PEARL_OF_POWER,
    name: `Жемчужина силы`,
    nameEn: `Pearl of Power`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    attunementComment: `заклинателем`,
    description: `Если эта жемчужина находится у Вас, Вы можете действием произнести командное слово и восстановить одну использованную ячейку заклинания.

Если уровень потраченной ячейки 4 или больше, Вы получите ячейку 3 уровня. Вы не можете повторно использовать жемчужину до следующего рассвета.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 163,
    },
  },
  {
    id: MAGIC_ITEM_PORTABLE_HOLE,
    name: `Переносная дыра`,
    nameEn: `Portable Hole`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Эта тонкая чёрная ткань, гладкая как шёлк, складывается до размеров носового платка. Она разворачивается в круг диаметром 6 футов.

Вы можете действием развернуть переносную дыру и поместить её на твёрдую поверхность, после чего дыра создаёт межпространственное отверстие глубиной 10 футов. Цилиндрическое пространство внутри дыры находится на другом плане, поэтому с её помощью не получится создавать сквозные проходы. Все существа, находящиеся внутри открытой переносной дыры, могут покинуть её, просто вылезая из неё.

Вы можете действием закрыть переносную дыру, взявшись за края ткани и сложив её. Складывание ткани закрывает дыру, и все существа и предметы, находящиеся в ней, остаются в межпространстве. Что бы в ней ни находилось, дыра практически ничего не весит.

Если дыра сложена, существо, находящееся в её межпространстве, может действием совершить проверку Силы СЛ 10. При успехе существо вырывается наружу и появляется в пределах 5 футов от переносной дыры или существа, несущего её. Дышащее существо, находящееся в закрытой переносной дыре, может перетерпеть 10 минут, после чего начинает задыхаться.

Помещение переносной дыры в межпространство, созданное [Сумкой хранения](MAGIC_ITEM:${MAGIC_ITEM_BAG_OF_HOLDING}), [Удобным рюкзаком Хеварда](MAGIC_ITEM:${MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK}) или подобным предметом, мгновенно уничтожает оба предмета и открывает [Врата](SPELL:${SPELL_GATE}) в Астральный План. [Врата](SPELL:${SPELL_GATE}) появляются в месте, где один предмет пытались поместить в другой. Все существа в пределах 10 футов от [Врат](SPELL:${SPELL_GATE}) затягиваются внутрь и помещаются в случайным образом определённое место на Астральном Плане. После этого [Врата](SPELL:${SPELL_GATE}) закрываются. [Врата](SPELL:${SPELL_GATE}) односторонние, и повторно не открываются.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 188,
    },
  },
]
