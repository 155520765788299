const {
  MAGIC_DIVINATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  SPELL_ALARM,
  SPELL_FIND_TRAPS,
  SPELL_GLYPH_OF_WARDING,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FIND_TRAPS,
  name: 'Поиск ловушек',
  nameEn: `Find Traps`,
  description: `Вы чувствуете присутствие любых ловушек в пределах дистанции, находящихся в пределах линии обзора. Для этого заклинания ловушкой считается всё, что причиняет неожиданный эффект, считающийся для Вас вредным или нежелательным, и специально подготовленный таким своим создателем. Таким образом, это заклинание почувствует область, защищённую заклинанием [сигнал тревоги](SPELL:${SPELL_ALARM}), [охранные руны](SPELL:${SPELL_GLYPH_OF_WARDING}), или механической ловушкой, но не распознает ветхий пол, неустойчивый потолок, или промоину в земле.

Это заклинание просто даёт знать, что ловушка присутствует. Вы не узнаёте местоположение всех ловушек, но зато знаете общий характер опасности, исходящей от почувствованной Вами ловушки.`,
  lvl: 2,
  magicSchoolId: MAGIC_DIVINATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  source: [
    {
      id: SOURCE_PHB,
      page: 260,
    },
    {
      id: SOURCE_SRD,
      page: 144,
    },
  ],
}
