const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_ALTER_SELF,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ALTER_SELF,
  name: 'Смена обличья',
  nameEn: 'Alter Self',
  description: `Вы принимаете другой облик. При накладывании выберите один из представленных ниже вариантов, эффект от которого будет длиться всю длительность заклинания. Пока заклинание активно, Вы можете действием окончить один эффект, чтобы получить преимущества другого.

## Адаптация к воде

Вы приспосабливаете своё тело к существованию в воде, отращивая жабры и перепонки между пальцами. Вы можете дышать под водой и получаете скорость плавания, равную скорости хождения.

## Естественное оружие

Вы отращиваете когти, клыки, шипы, рога или другое естественное оружие на свой выбор. Ваш безоружный удар причиняет дробящий, колющий или рубящий урон 1к6, в зависимости от выбранного Вами оружия, и Вы владеете безоружными ударами. Это оружие магическое, и Вы получаете бонус +1 к броскам атаки и урона им.

## Изменение внешности

Вы изменяете свою внешность. Вы сами решаете, на кого будете походить, включая рост, вес, лицо, звук голоса, длину волос, цвета и отличительные характеристики. Вы можете стать похожим на представителя другой расы, но Ваши показатели не изменяются. Вы также не можете выглядеть как существо другой категории размера, и Ваше тело остаётся примерно тем же самым; например, это заклинание не сделает Вас четырёхногим. Пока заклинание активно, Вы можете действием изменять свою внешность.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_HOUR, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 278,
    },
    {
      id: SOURCE_SRD,
      page: 114,
    },
  ],
}
