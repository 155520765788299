const {
  MAGIC_DIVINATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_TONGUES,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TONGUES,
  name: 'Языки',
  nameEn: 'Tongues',
  description: `Это заклинание дарует существу, которого Вы касаетесь, способность понимать все языки, которые оно слышит. Более того, когда цель говорит, все существа, знающие хотя бы один язык, и слышащие цель, понимают, что она сказала.`,
  lvl: 3,
  magicSchoolId: MAGIC_DIVINATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_MATERIAL],
  materialText: 'маленькая глиняная модель зиккурата',
  duration: { timeId: TIME_HOUR, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 290,
    },
    {
      id: SOURCE_SRD,
      page: 187,
    },
  ],
}
