const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {DAMAGE_PSYCHIC} = require('./../../../../../damageTypeList')
const {MAGIC_ILLUSION} = require('./../../../../../magicList')
const {PARAM_INT} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_MENTAL_PRISON} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MENTAL_PRISON,
  name: 'Ментальная тюрьма',
  nameEn: 'Mental Prison',
  description: `Вы пытаетесь поймать одно существо в иллюзорную клетку, которую может воспринимать только оно само. Существо, которое Вы можете видеть в пределах дистанции, должно пройти испытание Интеллекта. Цель автоматически преуспевает в испытании, если она невосприимчива к очарованию. При успехе цель получает 5к10 урона психической энергией, и заклинание оканчивается. При провале цель получает 5к10 урона психической энергией, и область непосредственно вокруг занимаемого целью пространства начинает по какой-либо причине казаться ей опасной.

Вы можете заставить цель ощущать себя окружённой огнём, парящими лезвиями, или отвратительными, брызжущими слюной зубастыми пастями. Вне зависимости от формы иллюзии, цель не может видеть или слышать ничего за её пределами и обездвиживается, пока заклинание не окончится. Если цель выталкивается из области действия иллюзии, делает ближнюю атаку сквозь неё или высовывает какую-либо часть тела сквозь иллюзию, она получает 10к10 урона психической энергией, и заклинание оканчивается.`,
  lvl: 6,
  magicSchoolId: MAGIC_ILLUSION,
  range: 60,
  componentIdList: [CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_INT,
    damage: {
      type: DAMAGE_PSYCHIC,
      diceCount: 5,
      diceType: 10,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 156,
  },
}
