const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_MIND_FLAYER,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: `Квалит`,
    text: `В тех редких случаях, когда [свежевателю разума](CREATURE:${CREATURE_MIND_FLAYER}) надо что-то записать, он пользуется квалитом. Это система тактильного письма (как шрифт Брайля), и читается она с помощью щупалец. Текст на квалите записывается четырёхстрочными строфами. Его конструкция настолько чужда другим существам, что им приходится прибегать к магии, чтобы понять смысл записанного. Квалит можно использовать для ведения записей, но гораздо чаще [иллитиды](CREATURE:${CREATURE_MIND_FLAYER}) его используют для того, чтобы написать предостережение или инструкцию на порталах или других важных объектах.`,
    source: {
      id: SOURCE_MM,
      page: 259,
    },
  },
]
