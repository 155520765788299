const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_GOBLIN} = require('./../../creatureTypeIdList')
const {goblinDescriptionList} = require ('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_GOBLIN,
  nameEn: 'Goblin',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'гоблин',
      genitive: 'гоблина',
      dative: 'гоблину',
      accusative: 'гоблина',
      instrumental: 'гоблином',
      prepositional: 'гоблине',
    },
    plural: {
      nominative: 'гоблины',
      genitive: 'гоблинов',
      dative: 'гоблинам',
      accusative: 'гоблинов',
      instrumental: 'гоблинами',
      prepositional: 'гоблинах',
    },
  },
  description: goblinDescriptionList,
}
