const upLetter = require('./../../../../../../utils/upLetter')

const {damageTypeCollection} = require('./../../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_TATTOO} = require('./../../../../../magicItemTypeList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    id,
    colorNameEn,
    colorNameRuFemale,
    colorNameRuMale,
    damageTypeId,
  }
) => {
  const {
    fullName: damageNameAccusative,
    fullNameByCase: {
      dative: damageNameDative,
    },
  } = damageTypeCollection[damageTypeId]

  return {
    id,
    name: `Поглощающая татуировка, ${colorNameRuFemale}`,
    nameEn: `Absorbing Tattoo, ${upLetter(colorNameEn)}`,
    type: MGC_TYPE_TATTOO,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Эта магическая татуировка выглядит как изображение **${colorNameRuMale}** цвета. Она наносится специальной иглой.

# Настройка на татуировку

Для настройки на _Поглощающую татуировку_, Вам нужно держать иглу у своей кожи в том месте, где Вы хотите нанести _Татуировку_, надавливая на иглу всё время настройки. Когда настройка завершится, игла превратится в чернила, которые станут _Татуировкой_ на коже.

Если Ваша настройка на _Татуировку_ завершится, она исчезнет, а игла снова появится в Вашем пространстве.

# Сопротивление урону

Пока _Татуировка_ на Вашей коже, Вы имеет сопротивление к **${damageNameDative}**.

# Поглощение урона

Когда Вы получаете **${damageNameAccusative.toLowerCase()}**, Вы можете реакцией получить иммунитет к этой порции урона и восстанавливаете количество хитов, равное половине урона, который Вы должны были получить.

Использовав эту реакцию, Вы не сможете использовать её снова до следующего восхода.`,
    genderId: GENDER_FEMALE,
    siblingIdList,
    source: {
      id: SOURCE_TCoE,
      page: 171,
    },
  }
}

module.exports = generator
