module.exports = [
  ...require('./adamantineArmor'),
  ...require('./ammunitionPlus'),
  ...require('./armorOfResistance'),
  ...require('./armorPlus'),
  ...require('./bagOfTricks'),
  ...require('./beltOfGiantStrength'),
  ...require('./crystalBall'),
  ...require('./dragonScaleMail'),
  ...require('./elementalGem'),
  ...require('./figurineOfWondrousPower'),
  ...require('./hornOfValhalla'),
  ...require('./instrumentOfTheBards'),
  ...require('./iounStone'),
  ...require('./manualOfGolems'),
  ...require('./marinersArmor'),
  ...require('./mithralArmor'),
  ...require('./potionOfGiantStrength'),
  ...require('./potionOfHealing'),
  ...require('./potionOfResistance'),
  ...require('./quaalFeatherToken'),
  ...require('./ringOfElementalCommand'),
  ...require('./ringOfResistance'),
  ...require('./rodOfThePactKeeper'),
  ...require('./shieldPlus'),
  ...require('./spellOfProtection'),
  ...require('./spellScrollVariant'),
  ...require('./swordOfAnswering'),
  ...require('./viciousWeapon'),
  ...require('./vorpalSword'),
  ...require('./wandOfTheWarMage'),
  ...require('./weaponPlus'),
]
