const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BEAST,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_SWIM,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_AMPHIBIOUS,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_GIANT_SNAPPING_TURTLE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гигантская щёлкающая черепаха',
  nameEn: 'Giant Snapping Turtle',
  id: CREATURE_GIANT_SNAPPING_TURTLE,
  description: `Гигантские щёлкающие черепахи могут вырасти до 12 футов в диаметре.

Хотя они кажутся медленными и тяжёлыми, они способны поражать всплеском свой скорости и агрессивно атаковать меньших существа, которые подходят к ним. Одной лишь челюстью гигантская черепаха может разрезать человека пополам, и этим существам всё равно, что они едят.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 219,
  },
  armor: [
    {
      ac: 17,
      type: ARMOR_TYPE_NATURAL,
    },
    {
      ac: 12,
      comment: 'пока лежит ничком',
    },
  ],
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 14,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 5,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_3,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Устойчивость',
      description: `Всякий раз, когда эффект должен сбить ★СУЩЕСТВО★ с ног, ★он★ может пройти испытание Телосложения СЛ 10, чтобы избежать падения.

Сбитая с ног ★СУЩЕСТВО★ ★перевёрнут★. Чтобы встать, ★он★ должна в свой ход пройти проверку Ловкости СЛ 10, а затем использовать всё своё движение.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
