const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../../damageTypeList')
const {
  GEAR_CLUB,
  GEAR_QUARTERSTAFF,
} = require('./../../../../../gearIdList')
const {
  SPELL_SHILLELAGH,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SHILLELAGH,
  name: 'Шилейла',
  nameAlt: 'Дубинка',
  nameEn: 'Shillelagh',
  description: `Дерево [дубинки](GEAR:${GEAR_CLUB}) или [боевого посоха](GEAR:${GEAR_QUARTERSTAFF}), который Вы держите, наполняется силой природы.

Пока заклинание активно, Вы можете использовать свою базовую характеристику вместо Силы для бросков рукопашной атаки и урона при использовании этого оружия, и кость урона становится равной к8. Если оружие не было магическим, оно становится им. 

Заклинание оканчивается, если Вы наложите его ещё раз или выпустите оружие из рук.`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `омела, лист клевера и дубинка или боевой посох`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  effect: {
    attackType: ATTACK_MELEE_WEAPON,
    damage: {
      type: DAMAGE_BLUDGEONING,
      diceCount: 1,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 225,
    },
    {
      id: SOURCE_SRD,
      page: 179,
    },
  ],
}
