const {
  SPELL_ARCANE_GATE,
  SPELL_ARMOR_OF_AGATHYS,
  SPELL_ARMS_OF_HADAR,
  SPELL_ASTRAL_PROJECTION,
  SPELL_BANISHMENT,
  SPELL_BLADE_OF_DISASTER,
  SPELL_BLADE_WARD,
  SPELL_BLIGHT,
  SPELL_BOOMING_BLADE,
  SPELL_CAUSE_FEAR,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_CONJURE_FEY,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_CREATE_BONFIRE,
  SPELL_CREATE_UNDEAD,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CROWN_OF_STARS,
  SPELL_DANSE_MACABRE,
  SPELL_DARKNESS,
  SPELL_DEMIPLANE,
  SPELL_DIMENSION_DOOR,
  SPELL_DISPEL_MAGIC,
  SPELL_DISTORT_VALUE,
  SPELL_DOMINATE_MONSTER,
  SPELL_DREAM,
  SPELL_DREAM_OF_THE_BLUE_VEIL,
  SPELL_EARTHBIND,
  SPELL_EGO_WHIP_2019_11_25,
  SPELL_ELDRITCH_BLAST,
  SPELL_ELEMENTAL_BANE,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENEMIES_ABOUND,
  SPELL_ENERVATION,
  SPELL_ENTHRALL,
  SPELL_ETHEREALNESS,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_EYEBITE,
  SPELL_FAR_STEP,
  SPELL_FEAR,
  SPELL_FEEBLEMIND,
  SPELL_FINGER_OF_DEATH,
  SPELL_FLESH_TO_STONE,
  SPELL_FLY,
  SPELL_FORCECAGE,
  SPELL_FORESIGHT,
  SPELL_FRIENDS,
  SPELL_FROSTBITE,
  SPELL_GASEOUS_FORM,
  SPELL_GATE,
  SPELL_GLIBNESS,
  SPELL_GREEN_FLAME_BLADE,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HEALING_ELIXIR_2017_04_03,
  SPELL_HELLISH_REBUKE,
  SPELL_HEX,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HUNGER_OF_HADAR,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_ID_INSINUATION_2019_11_25,
  SPELL_ILLUSORY_SCRIPT,
  SPELL_IMPRISONMENT,
  SPELL_INCITE_GREED,
  SPELL_INFERNAL_CALLING,
  SPELL_INFESTATION,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2019_11_25,
  SPELL_INVESTITURE_OF_FLAME,
  SPELL_INVESTITURE_OF_ICE,
  SPELL_INVESTITURE_OF_STONE,
  SPELL_INVESTITURE_OF_WIND,
  SPELL_INVISIBILITY,
  SPELL_LIGHTNING_LURE,
  SPELL_MADDENING_DARKNESS,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_STONE,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_SUGGESTION,
  SPELL_MENTAL_BARRIER_2019_11_25,
  SPELL_MENTAL_PRISON,
  SPELL_MIND_SLIVER,
  SPELL_MIND_SPIKE,
  SPELL_MIND_THRUST_2019_11_25,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRROR_IMAGE,
  SPELL_MISLEAD,
  SPELL_MISTY_STEP,
  SPELL_NEGATIVE_ENERGY_FLOOD,
  SPELL_OTHERWORLDLY_FORM_2020_03_26,
  SPELL_PLANAR_BINDING,
  SPELL_PLANE_SHIFT,
  SPELL_POISON_SPRAY,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_PAIN,
  SPELL_POWER_WORD_STUN,
  SPELL_PRESTIDIGITATION,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PSIONIC_BLAST_2019_11_25,
  SPELL_PSYCHIC_CRUSH_2019_11_25,
  SPELL_PSYCHIC_SCREAM,
  SPELL_PUPPET_2017_04_03,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_REMOVE_CURSE,
  SPELL_SCATTER,
  SPELL_SCRYING,
  SPELL_SENSE_EMOTION_2017_04_03,
  SPELL_SHADOW_BLADE,
  SPELL_SHADOW_OF_MOIL,
  SPELL_SHATTER,
  SPELL_SICKENING_RADIANCE,
  SPELL_SOUL_CAGE,
  SPELL_SPIDER_CLIMB,
  SPELL_SPIRIT_SHROUD,
  SPELL_SPIRIT_SHROUD_2020_03_26,
  SPELL_SUGGESTION,
  SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
  SPELL_SUMMON_ABERRATION,
  SPELL_SUMMON_FEY,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SUMMON_FIEND,
  SPELL_SUMMON_FIENDISH_SPIRIT_2020_03_26,
  SPELL_SUMMON_GREATER_DEMON,
  SPELL_SUMMON_LESSER_DEMONS,
  SPELL_SUMMON_SHADOW_SPIRIT_2020_03_26,
  SPELL_SUMMON_SHADOWSPAWN,
  SPELL_SUMMON_UNDEAD,
  SPELL_SUMMON_UNDEAD_SPIRIT_2020_03_26,
  SPELL_SWORD_BURST,
  SPELL_SYNAPTIC_STATIC,
  SPELL_TASHA_S_OTHERWORLDLY_GUISE,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_THOUGHT_SHIELD_2019_11_25,
  SPELL_THUNDER_STEP,
  SPELL_THUNDERCLAP,
  SPELL_TOLL_THE_DEAD,
  SPELL_TONGUES,
  SPELL_TRUE_POLYMORPH,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_UNSEEN_SERVANT,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_WALL_OF_LIGHT,
  SPELL_WEIRD,
  SPELL_WITCH_BOLT,
} = require('./../../spellIdList')

module.exports = [
  SPELL_ARCANE_GATE,
  SPELL_ARMOR_OF_AGATHYS,
  SPELL_ARMS_OF_HADAR,
  SPELL_ASTRAL_PROJECTION,
  SPELL_BANISHMENT,
  SPELL_BLADE_OF_DISASTER,
  SPELL_BLADE_WARD,
  SPELL_BLIGHT,
  SPELL_BOOMING_BLADE,
  SPELL_CAUSE_FEAR,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_CONJURE_FEY,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_CREATE_BONFIRE,
  SPELL_CREATE_UNDEAD,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CROWN_OF_STARS,
  SPELL_DANSE_MACABRE,
  SPELL_DARKNESS,
  SPELL_DEMIPLANE,
  SPELL_DIMENSION_DOOR,
  SPELL_DISPEL_MAGIC,
  SPELL_DISTORT_VALUE,
  SPELL_DOMINATE_MONSTER,
  SPELL_DREAM,
  SPELL_DREAM_OF_THE_BLUE_VEIL,
  SPELL_EARTHBIND,
  SPELL_EGO_WHIP_2019_11_25,
  SPELL_ELDRITCH_BLAST,
  SPELL_ELEMENTAL_BANE,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENEMIES_ABOUND,
  SPELL_ENERVATION,
  SPELL_ENTHRALL,
  SPELL_ETHEREALNESS,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_EYEBITE,
  SPELL_FAR_STEP,
  SPELL_FEAR,
  SPELL_FEEBLEMIND,
  SPELL_FINGER_OF_DEATH,
  SPELL_FLESH_TO_STONE,
  SPELL_FLY,
  SPELL_FORCECAGE,
  SPELL_FORESIGHT,
  SPELL_FRIENDS,
  SPELL_FROSTBITE,
  SPELL_GASEOUS_FORM,
  SPELL_GATE,
  SPELL_GLIBNESS,
  SPELL_GREEN_FLAME_BLADE,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HEALING_ELIXIR_2017_04_03,
  SPELL_HELLISH_REBUKE,
  SPELL_HEX,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HUNGER_OF_HADAR,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_ID_INSINUATION_2019_11_25,
  SPELL_ILLUSORY_SCRIPT,
  SPELL_IMPRISONMENT,
  SPELL_INCITE_GREED,
  SPELL_INFERNAL_CALLING,
  SPELL_INFESTATION,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2019_11_25,
  SPELL_INVESTITURE_OF_FLAME,
  SPELL_INVESTITURE_OF_ICE,
  SPELL_INVESTITURE_OF_STONE,
  SPELL_INVESTITURE_OF_WIND,
  SPELL_INVISIBILITY,
  SPELL_LIGHTNING_LURE,
  SPELL_MADDENING_DARKNESS,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_STONE,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_SUGGESTION,
  SPELL_MENTAL_BARRIER_2019_11_25,
  SPELL_MENTAL_PRISON,
  SPELL_MIND_SLIVER,
  SPELL_MIND_SPIKE,
  SPELL_MIND_THRUST_2019_11_25,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRROR_IMAGE,
  SPELL_MISLEAD,
  SPELL_MISTY_STEP,
  SPELL_NEGATIVE_ENERGY_FLOOD,
  SPELL_OTHERWORLDLY_FORM_2020_03_26,
  SPELL_PLANAR_BINDING,
  SPELL_PLANE_SHIFT,
  SPELL_POISON_SPRAY,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_PAIN,
  SPELL_POWER_WORD_STUN,
  SPELL_PRESTIDIGITATION,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PSIONIC_BLAST_2019_11_25,
  SPELL_PSYCHIC_CRUSH_2019_11_25,
  SPELL_PSYCHIC_SCREAM,
  SPELL_PUPPET_2017_04_03,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_REMOVE_CURSE,
  SPELL_SCATTER,
  SPELL_SCRYING,
  SPELL_SENSE_EMOTION_2017_04_03,
  SPELL_SHADOW_BLADE,
  SPELL_SHADOW_OF_MOIL,
  SPELL_SHATTER,
  SPELL_SICKENING_RADIANCE,
  SPELL_SOUL_CAGE,
  SPELL_SPIDER_CLIMB,
  SPELL_SPIRIT_SHROUD,
  SPELL_SPIRIT_SHROUD_2020_03_26,
  SPELL_SUGGESTION,
  SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
  SPELL_SUMMON_ABERRATION,
  SPELL_SUMMON_FEY,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SUMMON_FIEND,
  SPELL_SUMMON_FIENDISH_SPIRIT_2020_03_26,
  SPELL_SUMMON_GREATER_DEMON,
  SPELL_SUMMON_LESSER_DEMONS,
  SPELL_SUMMON_SHADOW_SPIRIT_2020_03_26,
  SPELL_SUMMON_SHADOWSPAWN,
  SPELL_SUMMON_UNDEAD,
  SPELL_SUMMON_UNDEAD_SPIRIT_2020_03_26,
  SPELL_SWORD_BURST,
  SPELL_SYNAPTIC_STATIC,
  SPELL_TASHA_S_OTHERWORLDLY_GUISE,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_THOUGHT_SHIELD_2019_11_25,
  SPELL_THUNDER_STEP,
  SPELL_THUNDERCLAP,
  SPELL_TOLL_THE_DEAD,
  SPELL_TONGUES,
  SPELL_TRUE_POLYMORPH,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_UNSEEN_SERVANT,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_WALL_OF_LIGHT,
  SPELL_WEIRD,
  SPELL_WITCH_BOLT,
]
