const {
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const throwWeaponDescription = require('./../../constants/throwWeaponDescription')
const {CAT_SIMPLE_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_JAVELIN} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_JAVELIN,
  genderId: GENDER_MALE,
  name: 'Пилум',
  nameByCase: {
    nominative: 'пилум',
    genitive: 'пилума',
    accusative: 'пилум',
    instrumental: 'пилумом',
  },
  nameAlt: 'Метательное копьё',
  nameEn: 'Javelin',
  damage: {
    diceType: 6,
    diceCount: 1,
  },
  cost: 50,
  damageType: DAMAGE_PIERCING,
  weight: 2,
  description: throwWeaponDescription,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 114,
    },
  ],
  weaponPropList: [
    WEAPON_THROWN,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 30,
        max: 120,
      },
    },
  ],
  category: CAT_SIMPLE_MELEE_WEAPON,
}
