const {PC_SUBCLASS_ROGUE_INQUISITIVE} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  FEATURE_EAR_FOR_DECEIT,
  FEATURE_EYE_FOR_DETAIL,
  FEATURE_EYE_FOR_WEAKNESS,
  FEATURE_INSIGHTFUL_FIGHTING,
  FEATURE_SNEAK_ATTACK,
  FEATURE_STEADY_EYE,
  FEATURE_UNERRING_EYE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EAR_FOR_DECEIT,
    name: `Слух на обман`,
    nameEn: `Ear for Deceit`,
    lvl: 3,
    text: `Вы вырабатываете талант подмечать ложь. Когда Вы делаете проверку Мудрости (Проницательность), чтобы распознать, обманывает ли существо, считайте выпавшие на к20 результаты 7 и ниже за 8.`,
  },
  {
    id: FEATURE_EYE_FOR_DETAIL,
    name: `Внимательный взгляд`,
    nameEn: `Eye for Detail`,
    lvl: 3,
    text: `Вы можете использовать бонусное действие, чтобы сделать проверку Мудрости (Восприятие) с целью заметить спрятанное существо или предмет или проверку Интеллекта (Расследование) с целью найти или расшифровать улики.`,
  },
  {
    id: FEATURE_INSIGHTFUL_FIGHTING,
    name: `Проницательный бой`,
    nameEn: `Insightful Fighting`,
    lvl: 3,
    text: `Вы получаете способность распознать тактику врага и выработать ей противодействие.

Бонусным действием Вы можете сделать проверку Мудрости (Проницательность) против существа, которое Вы видите и которое не недееспособно, против проверки Харизмы (Обман) цели. При успехе Вы можете использовать _Скрытую Атаку_ против цели даже если у Вас нет преимущества на бросок атаки, однако не в случае, если у Вас на этот бросок помеха.

Этот эффект длится 1 минуту либо до тех пор, когда Вы успешно используете эту способность против другой цели.`,
  },
  {
    id: FEATURE_STEADY_EYE,
    name: `Неторопливый взгляд`,
    nameEn: `Steady Eye`,
    lvl: 9,
    text: `Вы получаете преимущество на все проверки Мудрости (Восприятие) и Интеллекта (Расследование), если в данных ход Вы перемещаетесь не более чем на половину своей скорости.`,
  },
  {
    id: FEATURE_UNERRING_EYE,
    name: `Безошибочный взгляд`,
    nameEn: `Unerring Eye`,
    lvl: 13,
    text: `Ваши чувства практически невозможно обмануть.

Действием Вы можете почувствовать наличие иллюзий, перевертышей в их непервоначальной форме, и прочей магии, предназначенной для обмана чувств, в пределах 30 футов от себя, если Вы в этот момент не ослеплены или не оглушены. Вы чувствуете, что присутствует какой-то направленный на обман чувств эффект, но не знаете, что именно сокрыто или какова его истинная природа.

Вы можете использовать это умение количество раз, равное модификатору Вашей Мудрости (минимум один), и восстанавливаете потраченные использования, сделав длительный отдых.`,
  },
  {
    id: FEATURE_EYE_FOR_WEAKNESS,
    name: `Взгляд на слабость`,
    nameEn: `Eye for Weakness`,
    lvl: 17,
    text: `Вы учитесь играть на слабостях существа, внимательно изучив его тактику и передвижения.

Пока против существа действует Ваша способность [Проницательный бой](FEATURE:${FEATURE_INSIGHTFUL_FIGHTING}), повреждения от Вашей [Скрытой атаки](FEATURE:${FEATURE_SNEAK_ATTACK}) против этого существа увеличиваются на 3к6.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_INQUISITIVE,
    source: {
      id: SOURCE_XGTE,
      page: 53,
    },
  })
)

