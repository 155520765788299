const listToCollectionById = require('./../utils/listToCollectionById')
const {
  SOURCE_MGZN_DRGN_ANNUAL_1999,
  SOURCE_DND3_FRCS,
} = require('./sourceList')

const LANG_TYPE_STANDARD = 'standard'
const LANG_TYPE_DIALECT = 'dialect'
const LANG_TYPE_ETHNIC = 'ethnic'
const LANG_TYPE_EXOTIC = 'exotic'
const LANG_TYPE_HIDDEN = 'hidden'
const LANG_TYPE_DEAD = 'dead'

const languageTypeList = [
  {
    id: LANG_TYPE_STANDARD,
    name: 'Обычный',
    title: 'Распространённые языки, доступные для выбора при создании персонажа',
  },
  {
    id: LANG_TYPE_EXOTIC,
    name: 'Экзотический',
    title: `Малораспространённые языки, выбрать которые можно только с разрешения Мастера`,
  },
  {
    id: LANG_TYPE_HIDDEN,
    name: 'Тайный',
    title: `Тайный язык, такой как жаргон воров или язык друидов. Обычно выучить их можно только будучи членом некой организации`,
  },
  {
    id: LANG_TYPE_ETHNIC,
    name: 'Этнический',
    title: 'Народные языки человеческих этносов и наций',
  },
  {
    id: LANG_TYPE_DEAD,
    name: 'Мёртвый',
    title: 'Учёные и исследователи сокрытого могут назвать несколько мёртвых языков. Эти языки часто являются предками одного или более современных языков, но оригинальный язык столь отличен, что совершенно непонятен для тех, кто говорит на его современных потомках. Ни один из таких языков не использовался для живого общения уже тысячи лет и весьма сомнительно что хоть кто-то в мире знает, как они на самом деле звучат. ',
    source: {
      id: SOURCE_DND3_FRCS,
      page: 86,
    },
  },
  {
    id: LANG_TYPE_DIALECT,
    name: 'Диалект',
    title: `Диалект — это отдельная вариация языка, отличающаяся лексиконом, произношением, и/или грамматикой. Он используется группой носителей в конкретном языковом сообществе, особенно часто это речь, отличающаяся от стандартной литературной традиции этой культуры.

Близкие друг к другу диалекты обычно взаимно понимаемы, но при увеличении расстояния различия между диалектами могут вырасти до точки, где они станут полностью взаимно непонятны.`,
    source: {
      id: SOURCE_MGZN_DRGN_ANNUAL_1999,
      page: 30,
    },
  },
]

module.exports = languageTypeList

module.exports.languageTypeCollection = listToCollectionById(languageTypeList)

module.exports.LANG_TYPE_STANDARD = LANG_TYPE_STANDARD
module.exports.LANG_TYPE_DIALECT = LANG_TYPE_DIALECT
module.exports.LANG_TYPE_ETHNIC = LANG_TYPE_ETHNIC
module.exports.LANG_TYPE_EXOTIC = LANG_TYPE_EXOTIC
module.exports.LANG_TYPE_HIDDEN = LANG_TYPE_HIDDEN
module.exports.LANG_TYPE_DEAD = LANG_TYPE_DEAD
