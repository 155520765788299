const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_TINY,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_DRAGON,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CG,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_DRACONIC,
    LANG_SYLVAN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_ARCANA,
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_DANCING_LIGHTS,
    SPELL_MAGE_HAND,
    SPELL_MINOR_ILLUSION,
  } = require('./../../../../spellIdList'),
  {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ABILITY_MAGIC_RESISTANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_CAT,
    CREATURE_FAERIE_DRAGON_BLUE,
    CREATURE_FAERIE_DRAGON_GREEN,
    CREATURE_FAERIE_DRAGON_LIGHT_BLUE,
    CREATURE_FAERIE_DRAGON_ORANGE,
    CREATURE_FAERIE_DRAGON_RED,
    CREATURE_FAERIE_DRAGON_VIOLET,
    CREATURE_FAERIE_DRAGON_YELLOW,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Красный волшебный дракончик',
  nameEn: 'Red Faerie Dragon',
  id: CREATURE_FAERIE_DRAGON_RED,
  description: [
    {
      header: 'Волшебные дракончики',
      text: `Волшебный дракончик — дракон размером с [кошку](CREATURE:${CREATURE_CAT}) с крыльями бабочки. У него острые зубы, а восторг он выражает подёргиванием хвоста. Его игривое настроение исчезает только если на него напасть.`,
      source: {
        id: SOURCE_MM,
        page: 45,
      },
    },
    {
      header: 'Невидимые озорники',
      text: `Присутствие волшебного дракончика выдаёт лишь его сдавленный смешок. Дракончик остаётся вне поля зрения, скрытно наблюдая за тем, как жертвы разбираются с его розыгрышами. Когда веселье заканчивается, он может показаться, в зависимости от настроения своих «жертв».`,
      source: {
        id: SOURCE_MM,
        page: 45,
      },
    },
    {
      header: 'Дружелюбные и смышлёные',
      text: `У волшебного дракончика острый ум, любовь к сокровищам и хорошей компании и озорное чувство юмора. Путешественники могут сыграть на драконьей натуре дракончика, предложив ему «сокровища» в виде конфет, выпечки и побрякушек в обмен на информацию и безопасный проход через его территорию.`,
      source: {
        id: SOURCE_MM,
        page: 45,
      },
    },
    {
      header: 'Цвет и возраст',
      text: `Чешуя волшебного дракончика меняет оттенок с возрастом, проходя все цвета радуги. У всех волшебных дракончиков есть способности к магии, и они получают доступ к новым заклинаниям по мере взросления.

| Цвета дракона                                           | Возраст         |
|---------------------------------------------------------|-----------------|
| [Красный](CREATURE:${CREATURE_FAERIE_DRAGON_RED})       | 5 лет и меньше  |
| [Оранжевый](CREATURE:${CREATURE_FAERIE_DRAGON_ORANGE})  | 6–10 лет        |
| [Жёлтый](CREATURE:${CREATURE_FAERIE_DRAGON_YELLOW})     | 11–20 лет       |
| [Зелёный](CREATURE:${CREATURE_FAERIE_DRAGON_GREEN})     | 21–30 лет       |
| [Голубой](CREATURE:${CREATURE_FAERIE_DRAGON_LIGHT_BLUE})| 31–40 лет       |
| [Синий](CREATURE:${CREATURE_FAERIE_DRAGON_BLUE})        | 41–50 лет       |
| [Фиолетовый](CREATURE:${CREATURE_FAERIE_DRAGON_VIOLET}) | 51 год и старше |
`,
      source: {
        id: SOURCE_MM,
        page: 45,
      },
    },
  ],
  note: {
    text: `Что ни говори о волшебных дракончиках, но они как сыры в масле катаются. И в сливках. И в варенье! И с печеньем! М-м-м, теперь я проголодался`,
    author: `Физбан`,
  },
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
  ],
  alignmentId: ALIGNMENT_CG,
  source: {
    id: SOURCE_MM,
    page: 45,
  },
  armor: 15,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 3,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 13,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_SYLVAN,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Превосходная невидимость',
      description: `★СУЩЕСТВО★ может бонусным действием магическим образом стать невидимым, пока концентрируется (как при концентрации на заклинании). Всё снаряжение, которое ★СУЩЕСТВО★ несёт или носит, становится невидимым вместе с ★ним★.`,
    },
    {
      name: 'Ограниченная телепатия',
      description: `При помощи телепатии ★СУЩЕСТВО★ может магическим образом общаться со всеми другими волшебными дракончиками в пределах 60 футов.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_MAGE_HAND,
          SPELL_MINOR_ILLUSION,
          SPELL_DANCING_LIGHTS,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 0,
          diceType: 0,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Благодушное дыхание',
      description: `★СУЩЕСТВО★ выдыхает облако газа, вызывающего эйфорию, на одно существо, находящееся в пределах 5 футов от него. Цель должна пройти испытание Мудрости СЛ 11, иначе в течение 1 минуты не сможет совершать реакции, и в начале каждого своего хода должна будет бросать к6, чтобы определить своё поведение в этом ходу:

* **1–4**. Цель не совершает ни действия, ни бонусные действия, и всё своё перемещение тратит на перемещение в случайным образом выбранном направлении.
* **5–6**. Цель не перемещается, и единственное, что может делать в этом ходу, это прохождение испытания Мудрости СЛ 11, оканчивая эффект на себе при успехе.`,
      restore: 5,
    },
  ],
  genderId: GENDER_MALE,
}
