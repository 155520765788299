const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_DUERGAR,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_JAVELIN,
  GEAR_SHIELD,
  GEAR_SPLINT_ARMOR,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DUERGAR_RESILIENCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_DUERGAR_WARLORD} = require('./../../../../creatureIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Двергар военачальник',
  nameAlt: 'Дуэргар военачальник',
  nameEn: 'Duergar Warlord',
  id: CREATURE_DUERGAR_WARLORD,
  description: [
    `Военачальники — одновременно и хитрые, и воодушевляющие, и жестокие представители двергаров. Военачальник является умелым воином, ведущим двергаров в бой, и способным использовать скачки псионической энергии, что побуждает двергарских воинов усерднее сражаться в бою.`,
    ...duergarDescription,
  ],
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DWARF,
    CREATURE_TYPE_DUERGAR,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 170,
  },
  armor: {
    ac: 20,
    gearId: [GEAR_SPLINT_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 17,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_6,
  featureList: [
    ABILITY_DUERGAR_RESILIENCE,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Двергар совершает три атаки _Психически настроенным молотом_ или _Метательным копьём_, и использует, по возможности, способности _Призыв к атаке_ или _Увеличение_.`,
    },
    {
      name: 'Психически настроенный молот',
      comment: `в обычном состоянии`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 10,
            diceBonus: 4,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 1,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Психически настроенный молот',
      comment: `в увеличенном состоянии`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 10,
            diceBonus: 4,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 1,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      gearId: GEAR_JAVELIN,
      comment: `в обычном состоянии`,
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      comment: `в увеличенном состоянии`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 1,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      comment: `в увеличенном состоянии`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 7,
        range: {
          normal: 30,
          max: 120,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Призыв к атаке',
      description: `До трёх услышавших союзных двергаров в пределах 120 футов от этого двергара, каждый могут использовать свою реакцию, чтобы совершить одну атаку оружием.`,
    },
    {
      name: 'Увеличение',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `На 1 минуту двергар магическим образом увеличивается в размере вместе со всем, что несёт и носит. В увеличенном состоянии двергар Большого размера, удваивает кости урона в атаках, основанных на Силе (уже учтено), и совершает с преимуществом проверки Силы. Если двергару не хватает места, чтобы стать Большим, он приобретает максимальный размер, допустимый имеющимся пространством.`,
    },
    {
      name: 'Невидимость',
      restore: 4,
      description: `Двергар магическим образом становится невидимым, и этот эффект будет продолжаться до 1 часа, или же до того момента, когда он атакует, прочитает заклинание, использует _Увеличение_ или потеряет концентрацию (как если бы он концентрировался на заклинании). Любое снаряжение, в которое одет двергар, или которое он носит с собой, становится невидимым вместе с ним.`,
    },
  ],
  reactionList: [
    {
      name: 'Чистые указания',
      description: `Когда союзник, которого видит этот двергар, совершает бросок к20, двергар может бросить к6, а выпавший результат союзник может добавить к своему результату, получив при этом 3 (1к6) урона психической энергией. Существо с иммунитетом к психическому урону не подвергается воздействию Чистого указания.`,
    },
  ],
}
