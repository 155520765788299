const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {LANG_SERUSAN} = require('./../../languageIdList')
const {ALPHABET_DETHEK} = require('./../../alphabetList')
const {PLACE_INNER_SEA} = require('./../../placeIdList')

module.exports = {
  id: LANG_SERUSAN,
  alphabetId: ALPHABET_DETHEK, // Акван?
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Суресан',
    genitive: 'Суресана',
    instrumental: 'Суресаном',
    prepositional: 'Суресане',
  },
  nameEn: 'Serusan',
  spokenPlaceList: {
    id: PLACE_INNER_SEA,
    comment: '(водный торговый язык)',
  },
  isReady: false,
  isRealLang: true,
}
