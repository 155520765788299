const {PC_SUBCLASS_FIGHTER_BATTLE_MASTER} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  FEATURE_COMBAT_SUPERIORITY,
  FEATURE_STUDENT_OF_WAR,
  FEATURE_KNOW_YOUR_ENEMY,
  FEATURE_IMPROVED_COMBAT_SUPERIORITY,
  FEATURE_RELENTLESS,
} = require('./../../../featureIdList')
const {
  MANEUVER_AMBUSH,
  MANEUVER_BAIT_AND_SWITCH,
  MANEUVER_BRACE,
  MANEUVER_COMMANDER_S_STRIKE,
  MANEUVER_COMMANDING_PRESENCE,
  MANEUVER_DETECT_HIDDEN_AND_LIES_2016_04_04,
  MANEUVER_DISARMING_ATTACK,
  MANEUVER_DISTRACTING_STRIKE,
  MANEUVER_EVASIVE_FOOTWORK,
  MANEUVER_FEINTING_ATTACK,
  MANEUVER_GOADING_ATTACK,
  MANEUVER_GRAPPLING_STRIKE,
  MANEUVER_INT_WIS_CHA_SAVING_THROW_2016_04_04,
  MANEUVER_LUNGING_ATTACK,
  MANEUVER_MAINTAIN_CONCENTRATION_DISADVANTAGE_2016_04_04,
  MANEUVER_MANEUVERING_ATTACK,
  MANEUVER_MENACING_ATTACK,
  MANEUVER_PARRY,
  MANEUVER_PRECISION_ATTACK,
  MANEUVER_PUSHING_ATTACK,
  MANEUVER_QUICK_TOSS,
  MANEUVER_RALLY,
  MANEUVER_RESTRAINING_STRIKE_2019_04_11,
  MANEUVER_RIPOSTE,
  MANEUVER_SNIPE_2019_04_11,
  MANEUVER_SWEEPING_ATTACK,
  MANEUVER_TACTICAL_ASSESSMENT,
  MANEUVER_TRIP_ATTACK,
} = require('./../../../maneuverList')

module.exports = [
  {
    id: FEATURE_COMBAT_SUPERIORITY,
    name: `Боевое превосходство`,
    nameEn: `Combat Superiority`,
    lvl: [3, 7, 10, 15],
    text: `Вы выбираете этот архетип, Вы изучаете приёмы, использующие специальные кости, называемые _Костями превосходства_.

# Приёмы

Вы изучаете три приёма на Ваш выбор. Они описаны в разделе «приёмы», расположенном ниже. Большинство приёмов тем или иным образом усиливают атаку. Во время одной атаки Вы можете использовать только один приём.

Вы изучаете два дополнительных приёма при достижении 7, 10 и 15 уровней. Каждый раз, при изучении новых приёмов, Вы можете также заменить один из известных Вам приёмов на другой.

# Кости превосходства

У Вас есть четыре _Кости превосходства_. Это кости к8. _Кости превосходства_ тратятся при использовании. Вы восполняете все потраченные кости в конце короткого или длинного отдыха. 

Вы получаете ещё по одной _Кости превосходства_ на 7 и 15 уровнях.

# Испытания

Некоторые из Ваших приёмов требуют от цели испытания, чтобы избежать эффекта приёма. Сложность такого испытания рассчитывается следующим образом:

**Сложность испытания приёма = 8 + Ваш бонус мастерства + модификатор Силы или Ловкости** (на Ваш выбор).`,
    maneuverIdList: [
      MANEUVER_AMBUSH,
      MANEUVER_BAIT_AND_SWITCH,
      MANEUVER_BRACE,
      MANEUVER_COMMANDER_S_STRIKE,
      MANEUVER_COMMANDING_PRESENCE,
      MANEUVER_DETECT_HIDDEN_AND_LIES_2016_04_04,
      MANEUVER_DISARMING_ATTACK,
      MANEUVER_DISTRACTING_STRIKE,
      MANEUVER_EVASIVE_FOOTWORK,
      MANEUVER_FEINTING_ATTACK,
      MANEUVER_GOADING_ATTACK,
      MANEUVER_GRAPPLING_STRIKE,
      MANEUVER_INT_WIS_CHA_SAVING_THROW_2016_04_04,
      MANEUVER_LUNGING_ATTACK,
      MANEUVER_MAINTAIN_CONCENTRATION_DISADVANTAGE_2016_04_04,
      MANEUVER_MANEUVERING_ATTACK,
      MANEUVER_MENACING_ATTACK,
      MANEUVER_PARRY,
      MANEUVER_PRECISION_ATTACK,
      MANEUVER_PUSHING_ATTACK,
      MANEUVER_QUICK_TOSS,
      MANEUVER_RALLY,
      MANEUVER_RESTRAINING_STRIKE_2019_04_11,
      MANEUVER_RIPOSTE,
      MANEUVER_SNIPE_2019_04_11,
      MANEUVER_SWEEPING_ATTACK,
      MANEUVER_TACTICAL_ASSESSMENT,
      MANEUVER_TRIP_ATTACK,
    ],
    source: {
      id: SOURCE_PHB,
      page: 73,
    },
  },
  {
    id: FEATURE_STUDENT_OF_WAR,
    name: `Ученик войны`,
    nameEn: `Student of War`,
    lvl: 3,
    text: `Вы осваиваете владением одним из ремесленных инструментов на Ваш выбор.`,
    source: {
      id: SOURCE_PHB,
      page: 74,
    },
  },
  {
    id: FEATURE_KNOW_YOUR_ENEMY,
    name: `Познай своего врага`,
    nameEn: `Know Your Enemy`,
    lvl: 7,
    text: `Если Вы потратите как минимум 1 минуту, рассматривая, или по другому взаимодействуя с существом вне боя, Вы можете узнать некоторую информацию о его способностях в сравнении с Вашими. Мастер сообщит Вам, равняется ли существо, превосходит или уступает Вам в двух характеристиках на Ваш выбор из перечисленных:

* Значение Силы
* Значение Ловкости
* Значение Телосложения
* Класс Доспеха
* Текущие хиты
* Общее количество уровней (если есть)
* Количество уровней в классе Воин (если есть)`,
    source: {
      id: SOURCE_PHB,
      page: 74,
    },
  },
  {
    id: FEATURE_IMPROVED_COMBAT_SUPERIORITY,
    name: `Улучшенное боевое превосходство`,
    nameEn: `Improved Combat Superiority`,
    lvl: [10, 18],
    text: `Ваша [Кость превосходства](FEATURE:${FEATURE_COMBAT_SUPERIORITY}) увеличивается до к10.

На 18 уровне — до к12.`,
    source: {
      id: SOURCE_PHB,
      page: 74,
    },
  },
  {
    id: FEATURE_RELENTLESS,
    name: `Неослабевающий`,
    nameEn: `Relentless`,
    lvl: 15,
    text: `Если Вы совершаете бросок инициативы, не имея [Костей превосходства](FEATURE:${FEATURE_COMBAT_SUPERIORITY}), Вы получаете одну.`,
    source: {
      id: SOURCE_PHB,
      page: 74,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_BATTLE_MASTER,
  })
)
