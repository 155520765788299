const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_18,
  } = require('./../../../../crList'),
  {
    SIZE_TINY,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_SKELETON,
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    DAMAGE_MAGIC_WEAPON,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_TRUE_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_ANTIMAGIC_FIELD,
    SPELL_GREATER_RESTORATION,
  } = require('./../../../../spellIdList'),
  {
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_STUNNED,
  } = require('./../../../../conditionList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_TURN_IMMUNITY,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_DEMILICH,
    CREATURE_LICH,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Демилич',
  nameEn: 'Demilich',
  id: CREATURE_DEMILICH,
  description: [
    `Бессмертие [лича](CREATURE:${CREATURE_LICH}) длится, пока он скармливает своему филактерию души смертных. Если этого не происходит, его кости рассыпаются пылью, оставляя только череп. В таком «демиличе» осталась только часть злой жизненной силы лича, но и этого достаточно, чтобы при угрозе останки поднялись в воздух, окутанные призрачной формой. Череп испускает страшный рёв, убивающий слабых сердцем, а остальных заставляющий трястись от ужаса. Оставшись один, он падает и вновь погружается в пустоту своего существования.

Немногие [личи](CREATURE:${CREATURE_LICH}) стремятся стать демиличами, ведь для них это конец существования, которое они старались продлить, став нежитью. Тем не менее, время может разрушить стремления и память лича, заставляя его забиться в гробницу и забыть о питании душами. Заклинания выветриваются из его разума, и больше он не может использовать тайную магию. Тем не менее, даже череп остаётся смертельно опасным и очень неприятным противником.

# Нерушимое существование

Даже после превращения в демилича, филактерий сохраняется. Пока филактерий цел, демилич не может быть окончательно убит. Череп восстанавливается через 1к10 дней, возвращая его к жалкому существованию. Если у него ещё остался разум, он может вернуть былую силу, скормив всего одну душу филактерию. После этого демилич вернётся к облику лича, восстановив своё немёртвое тело.

# Природа нежити

Демиличу не нужен воздух, еда, питьё и сон. Желание выжить у демилича столь сильно, что у него всегда максимальное количество хитов на Костях Хитов вместо средних.`,
    {
      header: 'Логово демилича',
      text: `Демилич хранит свои бренные останки и сокровища в подобной лабиринту гробнице, заполненной чудовищами и ловушками. В сердце лабиринта — череп демилича и прах его костей. В склепе демилич может использовать действия логова и получает дополнительные использования легендарных действий. Само логово тоже обладает уникальными свойствами. Демилич в своём логове имеет показатель опасности 20 (24500 опыта).

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) демилич совершает бросок к20. При результате «11» или более, он совершает действие в логове, создавая один из описанных ниже эффектов. Демилич не может использовать один и тот же эффект два раунда подряд.

* На мгновение, гробница сотрясается. Все существа, стоящие на полу, должны пройти испытание Ловкости СЛ 19, иначе будут сбиты с ног.
* Демилич нацеливается на одно существо, видимое им в пределах 60 футов. [Преграда магии](SPELL:${SPELL_ANTIMAGIC_FIELD}) заполняет пространство цели, двигаясь вместе с ней, до значения инициативы «20» в следующем раунде.
* Демилич нацеливается на любое количество существ, видимых им в пределах 30 футов. Цели не могут восстанавливать хиты до значения инициативы «20» в следующем раунде.

# Свойства логова

Гробница демилича может обладать любыми или всеми следующими эффектами:

* В первый раз, когда не злое существо входит в гробницу, это существо получает урон некротической энергией 16 (3к10).
* Чудовища гробницы с преимуществом проходят испытания против очарования и испуга, а также от умений, изгоняющих нежить.
* Гробница защищена от магических путешествий существ, которым демилич не разрешил это предварительно. Такие существа не могут телепортироваться в гробницу и из неё, и не могут использовать планарное перемещение, чтобы войти или выйти. Телепортация и планарные путешествия работают, пока их не используют для того, чтобы покинуть гробницу или войти в неё.

Если демилич будет уничтожен, эти эффекты исчезают через 10 дней.`,
      source: {
        id: SOURCE_MM,
        page: 79,
      },
    },
  ],
  note: {
    text: `Я, Ацерерак Вечный, ваша погибель. Придите, глупцы, возьмите мои сокровища, если смеете! Другие пытались, но никому не удалось! Из вашей кожи будут сотканы мои гобелены, ваши кости будут ковром моей могилы. Только я нахожусь между жизнью и смертью, только я знаю секрет истинного бессмертия!`,
    author: `Эпитафия демилича`,
  },
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 78,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 20,
    useMax: true,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 10,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 20,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_MAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_PSYCHIC,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_STUNNED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  cr: CR_18,
  featureList: [
    {
      name: 'Избегание',
      description: `Если ★СУЩЕСТВО★ подвергается эффекту, позволяющему пройти испытание, чтобы получить только половину урона, ★он★ вообще не получает урон, если преуспеет, и получает лишь половину урона при провале.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_TURN_IMMUNITY,
  ],
  actionList: [
    {
      name: 'Вой',
      description: `★СУЩЕСТВО★ испускает душераздирающий вопль. Все существа в пределах 30 футов от ★СУЩЕСТВО★, слышащие вой, должны пройти испытание Телосложения СЛ 15, иначе их хиты опускаются до 0. При успешном испытании существо становится испуганным до конца своего следующего хода.`,
      restore: 5,
    },
    {
      name: 'Вытягивание жизни',
      description: `★СУЩЕСТВО★ выбирает до трёх существ, которых видит в пределах 10 футов от себя. Каждая цель должна пройти испытание Телосложения СЛ 19, иначе получит урон некротической энергией 21 (6к6), а ★СУЩЕСТВО★ восстановит количество хитов, равное сумме урона, причинённого всем целям.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Полёт',
      description: `★СУЩЕСТВО★ пролетает расстояние, не превышающее половину скорости полёта.`,
    },
    {
      name: 'Облако пыли',
      description: `★СУЩЕСТВО★ магическим образом превращается в облако праха. Все существа в пределах 10 футов от демилича, включая тех, что стоят за углом, должны пройти испытание Телосложения СЛ 15, иначе станут ослеплёнными до конца следующего хода демилича. Преуспевшее в испытании существо получает иммунитет к этому эффекту до конца следующего хода демилича.`,
    },
    {
      name: 'Вытягивание энергии',
      cost: 2,
      description: `Все существа в пределах 30 футов от ★СУЩЕСТВО★ должны пройти испытание Телосложения СЛ 15. При провале максимум хитов существа магическим образом уменьшается на 10 (3к6). Если максимум хитов из-за этого уменьшился до 0, существо умирает. Максимум хитов восстанавливается заклинанием [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) и подобной магией.`,
    },
    {
      name: 'Подлое проклятье',
      cost: 3,
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 30 футов от себя. Цель должна пройти испытание Мудрости СЛ 15, иначе она станет магическим образом проклятой. Пока проклятье действует, цель совершает с помехой броски атаки и испытания. Цель может повторять это испытание в конце каждого своего хода, оканчивая проклятье при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
