const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_MITHRAL_ARMOR} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_CHAIN_SHIRT,
  GEAR_BREASTPLATE,
} = require('./../../../../../gearIdList')

const siblingIdList = require('./siblingIdList')
const gearTypeExclude = require('./gearTypeExclude')
const gearCategoryList = require('./gearCategoryList')

module.exports = {
  id: MAGIC_ITEM_MITHRAL_ARMOR,
  name: `Мифрильный доспех`,
  nameAlt: `Мифриловый доспех`,
  nameEn: `Mithral Armor`,
  type: MGC_TYPE_ARMOR,
  gearTypeExclude,
  gearCategory: gearCategoryList,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Мифрил — это лёгкий и гибкий металл.

Сделанная из мифрила [кольчужная рубаха](GEAR:${GEAR_CHAIN_SHIRT}) или [кираса](GEAR:${GEAR_BREASTPLATE}) может носиться под [обычной одеждой](GEAR:${GEAR_CLOTHES_COMMON}).

Если в обычном исполнении доспех накладывает помеху на проверки Ловкости (Скрытность) или имеет требования к Силе, то в версии из мифрила он не обладает этими недостатками.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 185,
  },
}
