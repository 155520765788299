const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26,
  CREATURE_LAND_BEAST_SPIRIT,
  CREATURE_LAND_BESTIAL_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Дух земного зверя',
  nameEn: 'Land Bestial Spirit',
  id: CREATURE_LAND_BESTIAL_SPIRIT_2020_03_26,
  releasedAs: CREATURE_LAND_BEAST_SPIRIT,
  parentId: CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  featureList: [
    ABILITY_PACK_TACTICS,
  ],
}
