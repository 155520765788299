const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_MIDDLE} = require('./../../genderList')
const {CREATURE_TYPE_PLANT} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_PLANT,
  nameEn: 'Plant',
  genderId: GENDER_MIDDLE,
  description: {
    text: `Растения, в контексте вида монстров, — это растительные создания, а не обычная флора. Большая часть из них подвижна, а некоторые и плотоядны. Типичные растения — это ползающие насыпи и тренты. Грибковые существа, такие как газовые споры или микониды, также попадают в эту категорию.`,
    source: {
      id: SOURCE_MM,
      page: 9,
    },
  },
  name: {
    singular: {
      nominative: 'растение',
      genitive: 'растения',
      dative: 'растению',
      accusative: 'растение',
      instrumental: 'растением',
      prepositional: 'растении',
    },
    plural: {
      nominative: 'растения',
      genitive: 'растений',
      dative: 'растениям',
      accusative: 'растения',
      instrumental: 'растениями',
      prepositional: 'растениях',
    },
  },
}
