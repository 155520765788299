const {
  CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  CREATURE_KOBOLD_DRAGONSHIELD_BLUE,
} = require('./../../../../creatureIdList')
const {DAMAGE_ELECTRICITY} = require('./../../../../damageTypeList')

module.exports = {
  name: 'Кобольд синий драконий щит',
  nameEn: 'Kobold Dragonshield (Blue)',
  id: CREATURE_KOBOLD_DRAGONSHIELD_BLUE,
  parentId: CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  resistanceList: [
    DAMAGE_ELECTRICITY,
  ],
}
