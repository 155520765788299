const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_GIANT_WASP} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Гигантская оса',
  nameEn: 'Giant Wasp',
  id: CREATURE_GIANT_WASP,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 324,
  },
  armor: 12,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 50,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  cr: CR_1_2,
  actionList: [
    {
      name: 'Жало',
      description: `Цель должна пройти испытание Телосложения СЛ 11, получая урон ядом 10 (3к6) при провале, или половину этого урона при успехе. Если урон ядом уменьшает хиты цели до 0, цель остаётся стабилизированной, но отравленной на 1 час, даже если позже восстановит хиты, и она парализована, пока отравлена таким образом.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
