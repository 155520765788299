const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {
  WEAPON_AMMUNITION,
  WEAPON_RANGE,
} = require('./../../../weaponPropList')
const slingDescription = require('./../../constants/slingDescription')
const {CAT_SIMPLE_RANGE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_BLUDGEONING} = require('./../../../damageTypeList')
const {GEAR_SLING} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_SLING,
  genderId: GENDER_FEMALE,
  name: 'Праща',
  nameByCase: {
    nominative: 'праща',
    genitive: 'пращи',
    accusative: 'пращу',
    instrumental: 'пращой',
  },
  nameEn: 'Sling',
  description: slingDescription,
  damage: {
    diceType: 4,
    diceCount: 1,
  },
  cost: 10,
  damageType: DAMAGE_BLUDGEONING,
  weight: 0,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 114,
    },
  ],
  weaponPropList: [
    WEAPON_AMMUNITION,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 30,
        max: 120,
      },
    },
  ],
  category: CAT_SIMPLE_RANGE_WEAPON,
}
