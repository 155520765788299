const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_CLIMB,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_NECROTIC,
} = require('./../../../../damageTypeList')
const {
  CREATURE_DEMON_SPIRIT,
  CREATURE_YUGOLOTH_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух демона',
  nameEn: 'Demon Spirit',
  id: CREATURE_DEMON_SPIRIT,
  parentId: CREATURE_YUGOLOTH_SPIRIT,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  hp: `50 + 15-кратный круг заклинания выше 6-го`,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 1,
          diceType: 12,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  extendPropCollection: {
    speed: {
      [SPEED_CLIMB]: 40,
    },
    featureList: [
      {
        name: 'Предсмертная агония',
        description: `Когда хиты ★его★ опускаются до 0 или заклинание оканчивается, ★СУЩЕСТВО★ взрывается, и все существа в пределах 10 футов от ★него★ должны пройти испытания Ловкости, получая 2к10 урона огнём при провале, или половину этого урона при успехе.`,
      },
    ],
  },
}
