const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  CREATURE_GLABREZU,
  CREATURE_GREEN_HAG,
  CREATURE_SEA_HAG,
  CREATURE_NIGHT_HAG,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Карги',
    text: `Карга олицетворяет собой злобу и жестокость. Хоть карги и напоминают морщинистых старух, нет никого беспощаднее этих чудовищных существ, чей внешний вид отражает лишь нечестивость их сердец.`,
    source: {
      id: SOURCE_MM,
      page: 169,
    },
  },
  {
    header: 'Лики зла',
    text: `Карги — древние существа родом из Страны Фей и язвы на мире смертных. Иссохшие лица, покрытые пятнами и бородавками, обрамлены длинными истрёпанными волосами, а костлявые пальцы оканчиваются когтями, режущими плоть как масло. Простая одежда их всегда разорвана и потрёпана.

Все карги владеют магическими способностями, а некоторые осваивают колдовство. Они меняют обличье и проклинают врагов, а высокомерие заставляет их считать собственную ворожбу вызовом магии богов, которых они поносят при каждом случае.

Карги называют себя причудливо, выбирая странные прозвища, такие как Чёрная Морвен, Пегги Свинноногая, Старушка Ивовая Колода, Бабуля Шуг, Гнилая Этель или Тётушка Червезуб.`,
    source: {
      id: SOURCE_MM,
      page: 169,
    },
  },
  {
    header: 'Чудовищные матери',
    text: `Карги размножаются, похищая и пожирая человеческих младенцев. Украв ребёнка из колыбели или утробы матери, карга его съедает. Через неделю карга рожает дочь, которая до тринадцатилетия выглядит как человек, после чего превращается в копию карги-матери.

Иногда карги взращивают дочерей, создавая шабаш. Они могут и вернуть ребёнка скорбящим родителям, наблюдая из теней, за тем, как он растёт и вот-вот превратится в чудище.`,
    source: {
      id: SOURCE_MM,
      page: 169,
    },
  },
  {
    header: 'Тёмные сделки',
    text: `Высокомерные карги считают себя самыми хитрыми, и ко всем остальным относятся как к низшим существам. Но даже при этом карга может сотрудничать со смертным, покуда он выказывает должное уважение и почтение. За свою долгую жизнь карги накапливают много знаний об окружающих землях, тёмных существах и магии, которыми они с удовольствием торгуют.

Карги любят наблюдать за развращением смертных, и сделки с ними всегда опасны. Условия обычно предполагают сделки с совестью или отказ от чего-то дорогого, особенно если речь идёт о потерянной вещи или знаниях.`,
    source: {
      id: SOURCE_MM,
      page: 169,
    },
  },
  {
    header: 'Дурная порода',
    text: `Карги любят украшать себя чем-то мёртвым, подчёркивая внешность костьми, кусками плоти и грязью. Они специально теребят раны и не сводят пятна с кожи, превращая их в гноящуюся плоть. Красивые существа вызывают у карг отвращение, но они могут им «помочь», изменив их тело.

Отвращение красной нитью проходит через все сферы жизни карг. Карга может летать в огромном черепе великана, который она хранит на верхушке дерева, сделанного в виде огромного обезглавленного тела. Другая может путешествовать с чудовищами и рабами в клетях, прикрытых иллюзией, чтобы заманить поближе доверчивых путников. Карги заостряют зубы точильными камнями, а материю для одеяний ткут из внутренностей жертв, с усмешкой реагируя на ужас, который испытывают при виде их действий другие.`,
    source: {
      id: SOURCE_MM,
      page: 169,
    },
  },
  {
    header: 'Тёмное сестринство',
    text: `Карги поддерживают друг с другом связи и обмениваются знаниями. Поэтому, вполне вероятно, что каждая карга знает о существовании всех других. Карги друг друга недолюбливают, но соблюдают извечный кодекс поведения. Они заявляют о своём присутствии до пересечения границ другой карги, приносят дары, посещая жилища карг, и не нарушают клятв, данных другим каргам, если только эти клятвы не были произнесены со скрещёнными за спиной пальцами.

Некоторые ошибаются, думая, что эти правила распространяются на всех существ. Если такое случится, карга будет водить существо за нос, играя, а потом преподаст урок, который оно вряд ли забудет.`,
    source: {
      id: SOURCE_MM,
      page: 169,
    },
  },
  {
    header: 'Тёмные логова',
    text: `Карги живут в тёмных лесах, мрачных трясинах, унылых болотах и на штормовых побережьях. Со временем пейзаж вокруг логова заражается тлетворностью карги, и земля будто сама старается убить пришедших. Искривлённые деревья хватают прохожих в темноте, а виноградные лозы ползут сквозь подлесок, утаскивая путников по одному. Зловонный туман превращает воздух в яд, а топи и водовороты готовы засосать неосторожных.`,
    source: {
      id: SOURCE_MM,
      page: 169,
    },
  },
  {
    header: 'Карги',
    text: `Карги наслаждаются тем, что несут горе и страдания в мир. Злоба — настолько важная часть карги, что она даже изменяет её физическую форму и модифицирует магические силы.`,
    source: {
      id: SOURCE_VGTM,
      page: 153,
    },
  },
  {
    header: `Встреча со злом: трактат о каргах`,
    text: `_Выдержка из трактата пера Элизабет М. Кокс._

Как давно, мой милый читатель, мы не садились вместе, внимая рассказу. Хотелось бы мне, чтобы причиной такой разлуки были мои путешествия по горам и долам, из которых я могла бы привезти тебе новые полезные советы о том, как победить зло. Увы, причина не в этом.

Ибо я стала жертвой [болотной карги](CREATURE:${CREATURE_GREEN_HAG}).

Не беспокойся за меня, читатель, я жива и здорова. Однако последние два года мне пришлось провести у неё в услужении. Однажды я расскажу тебе, что произошло в той тёмной клетке. Но не сегодня.

Сегодня я хочу предупредить тебя. Мы говорили о каргах и раньше — и о [ночных](CREATURE:${CREATURE_NIGHT_HAG}), и о [болотных](CREATURE:${CREATURE_GREEN_HAG}), и о [морских](CREATURE:${CREATURE_SEA_HAG}) — и знаем, что с ними шутки плохи. Но даже и всё моё знание не могло помочь мне подготовиться к встрече с каргой воочию.

Злобные создания, карги используют магию, обман, иллюзию и даже шантаж, чтобы заманить к себе своих жертв. Я это знала. Я повторяла это тебе много раз, дорогой читатель. Но я не понимала всей величины их обаяния, их шарма. Я не знала что полюблю её всей душой. Что я буду мечтать сделать ей приятное. Она тянула меня за ниточки, испытывала самыми дикими моими желаниями. Я думала, что умна, что сумею её переиграть. Однажды я перехитрила [глабрезу](CREATURE:${CREATURE_GLABREZU}) — неужели я не справлюсь с [болотной каргой](CREATURE:${CREATURE_GREEN_HAG})?! И она позволила мне в это поверить… а потом ловушка захлопнулась.

Карга обожает страдание. Она гордится своей способностью придумывать всё новые и более изуверские способы, чтобы мучить тех, кто угодил в её «объятия». И пускай мне и удалось ускользнуть, я живу в непрерывном ужасе перед тем, что она сама или одна из её «сестёр» могут придти за мной. Ибо их ковен не оставляет без наказания ни малейшей обиды.

Поэтому я хочу переписать начисто всё, что касается возможной встречи с каргой, вычеркнуть все выдуманные мною хитрости и уловки — они не дали мне спасения. Вместо них я скажу тебе так: если ты когда-либо встретишься с каргой, беги. И молись, чтобы она тебя не догнала.

— Книга из серии, повествующей о самых зловредных существах Фаэруна. Конкретно этот том, посвящённый каргам, стал самой известной работой автора.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
]
