const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_SHOTGUN} = require('./../../../gearIdList')
const {GENDER_MIDDLEE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  WEAPON_AMMUNITION_MODERN,
  WEAPON_BURST_FIRE,
  WEAPON_RELOAD,
  WEAPON_RANGE,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  CAT_FIREARMS,
  CAT_MARTIAL_RANGE_WEAPON,
  CAT_TECH_MODERN,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_SHOTGUN,
  name: 'Ружьё',
  nameAlt: 'Дробовик',
  nameEn: 'Shotgun',
  nameByCase: {
    nominative: 'ружьё',
    genitive: 'ружья',
    accusative: 'ружьё',
    instrumental: 'ружьём',
  },
  weight: 7,
  damageType: DAMAGE_PIERCING,
  damage: {
    diceCount: 2,
    diceType: 8,
  },
  genderId: GENDER_MIDDLEE,
  category: [
    CAT_FIREARMS,
    CAT_MARTIAL_RANGE_WEAPON,
    CAT_TECH_MODERN,
  ],
  weaponPropList: [
    WEAPON_AMMUNITION_MODERN,
    WEAPON_BURST_FIRE,
    WEAPON_TWO_HANDED,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 30,
        max: 90,
      },
    },
    {
      id: WEAPON_RELOAD,
      value: 2,
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
