const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_BLINDED,
  CONDITION_DEAFENED,
} = require('./../../../../conditionList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {blightDescriptionList} = require('./../../../../textCommonParts')
const {CR_1_8} = require('./../../../../crList')
const {CREATURE_TWIG_BLIGHT} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_PLANT} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ветвистая зараза',
  nameEn: 'Twig Blight',
  id: CREATURE_TWIG_BLIGHT,
  description: [
    `Когда добычи вокруг недостаточно, ветвистая зараза пускает в почву корни. В такие моменты они ничем не отличаться от обычного сухого кустарника. Когда же они высвобождают корни из земли для передвижения, ветки переплетаются друг с другом, формируя человекоподобное тело с головой и конечностями.

Ветвистая зараза ищет поляны и колодцы, прорастая там, и устраивая засады на потенциальных жертв, желающих отдохнуть или утолить жажду. Сбиваясь в группы, зараза сливается с естественной растительностью или грудами мусора или хвороста. Ветвистая зараза очень суха, и потому особенно восприимчива к огню.`,
    ...blightDescriptionList,
  ],
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 157,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 12,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  immunityConditionList: [
    [CONDITION_DEAFENED],
    [CONDITION_BLINDED],
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  languageList: [
    {
      id: LANG_COMMON,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_8,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `засохшего кустарника`,
    },
  ],
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
