const {
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2020_09_15_TCoE_PC_WtG,
  SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK,
  SOURCE_UA_2016_08_01_THE_FAITHFUL,
  SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
  SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
  SOURCE_UA_2019_09_05_ABERRANT_LURK,
  SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
  SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
  SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
  SOURCE_VRGtR,
  SOURCE_XGTE,
} = require('./../../sourceList')
const {
  GOD_ASMODEUS,
  GOD_AZUTH,
  GOD_DENDAR,
  GOD_GHAUNADAUR,
  GOD_GOND,
  GOD_LOLTH,
  GOD_MYSTRA,
  GOD_OGHMA,
  GOD_RAVEN_QUEEN,
  GOD_TYR,
} = require('./../../godIdList')
const {
  CREATURE_BALOR,
  CREATURE_BAPHOMET,
  CREATURE_CAMBION,
  CREATURE_DAO,
  CREATURE_DEMOGORGON,
  CREATURE_DJINNI,
  CREATURE_EFREETI,
  CREATURE_EMPYREAN,
  CREATURE_FRAZ_URBLUU,
  CREATURE_KI_RIN,
  CREATURE_MARID,
  CREATURE_ORCUS,
  CREATURE_PIT_FIEND,
  CREATURE_SKELETON,
  CREATURE_SOLAR,
  CREATURE_ULTROLOTH,
  CREATURE_UNICORN,
  CREATURE_ZOMBIE,
} = require('./../../creatureIdList')
const {
  PC_CLASS_WARLOCK,
} = require('./../../pcClassIdList')
const {
  PC_RACE_ELF,
} = require('./../../pcRaceIdList')

const {
  PC_SUBCLASS_WARLOCK_THE_ARCHFEY,
  PC_SUBCLASS_WARLOCK_THE_CELESTIAL,
  PC_SUBCLASS_WARLOCK_THE_CELESTIAL_2017_06_05,
  PC_SUBCLASS_WARLOCK_THE_FATHOMLESS,
  PC_SUBCLASS_WARLOCK_THE_FIEND,
  PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE,
  PC_SUBCLASS_WARLOCK_THE_GENIE,
  PC_SUBCLASS_WARLOCK_THE_GENIE_TCoE_PC_WtG,
  PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
  PC_SUBCLASS_WARLOCK_THE_HEXBLADE_2017_02_13,
  PC_SUBCLASS_WARLOCK_THE_LURKER_IN_THE_DEEP_2019_05_09,
  PC_SUBCLASS_WARLOCK_THE_NOBLE_GENIE_2020_01_14,
  PC_SUBCLASS_WARLOCK_THE_RAVEN_QUEEN_2017_02_13,
  PC_SUBCLASS_WARLOCK_THE_SEEKER_2016_08_01,
  PC_SUBCLASS_WARLOCK_THE_UNDEAD,
  PC_SUBCLASS_WARLOCK_THE_UNDEAD_2020_08_05,
  PC_SUBCLASS_WARLOCK_THE_UNDYING,
  PC_SUBCLASS_WARLOCK_THE_UNDYING_LIGHT_2015_11_02,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_WARLOCK_THE_ARCHFEY,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: true,
    name: 'Архифея',
    nameEn: 'The Archfey',
    description: [
      {
        header: 'Архифея',
        text: `Ваш покровитель это лорд или леди фей. Существо из легенд, хранящее секреты, забытые ещё до возникновения смертных рас.

Мотивы, движущие такими сущностями, непостижимы, причудливы, и могут диктоваться стремлением к величайшим магическим познаниям или вековыми обидами.

Сущности такого рода включают Морозного Принца, Королеву Воздуха и Тьмы — правительницу Сумеречного Двора, Титанию из Летнего Двора, её супруга Оберона — Зелёного Лорда, Хирсама — Принца Дураков и древних карг.`,
        source: {
          id: SOURCE_PHB,
          page: 109,
        },
      },
      {
        header: 'Архифеи',
        text: `На безграничных просторах дикой местности Королевств всё ещё можно найти связи со Страной Фей. Это перекрёстки фей, места загадочной природной красы мира, почти идеально совпадающие с таковыми местами в Стране Фей. Перейти перекрёсток фей можно войдя на поляну, войдя в пруд, вступив в грибной «ведьмин круг» или пролезть сквозь корни дерева. Некоторые колдуны ищут такие места, чтобы выторговать у Архифей той страны силу. Ниже приведены наиболее примечательные покровители Архифеи:

# Титания

Королева Лета скорее всего является самой могущественной из архифей. От её улыбки созревает урожай, а когда она хмурится в лесах вспыхивают пожары. Она правит благословенным Летним Двором.

# Оберон

Оберон, Зелёный Владыка, несравненный охотник и лесной воин, любовник Титании и часто её же противник. Оберон существует в гармонии с каждым сучком каждого дерева и с каждым ручейком лесов Страны Фей. Если у Оберона и есть слабость, то это дикая природа его сердца. Его настроение переменчиво, как полёт листка в урагане.

# Хисрам

Хисрам, Принц Дурней, считается первым сатиром. Он обладает кристально чистым голосом, а его шутки и гримасы могут заставить даже камни хохотать до слёз. Впрочем, Хисрам также олицетворяет дух безудержной дикости. Хисрам Дурень часто устраивает розыгрыши, но, когда всё заходит слишком далеко, а шутки становятся жестокими и смертельно опасными, в дело вступает Хисрам Дикарь.

# Королева Тьмы и Воздуха

Королева Тьмы и Воздуха правит тёмным Сумеречным Двором с ониксового трона, который стоит пустым и лишь Бриллиант Ночи размером с человеческую голову парит над ним, тускло отблёскивая пойманными звёздами. Королева Тьмы и Воздуха незримо присутствует рядом с ним, и голос её громогласно вещает из Бриллианта Ночи или же едва слышно нашёптывает секреты придворным прямо в уши, а иногда и то, и другое одновременно.

# Принц Мороза

Принц Мороза, ранее был известен как Солнечный Принц, но его сердце охладело, когда его наречённая предала его и сбежала, превратив свою душу в одну из звёзд. С тех пор, гневный принц стремится воссоединиться со своей невестой, когда та воплотится в смертной форме.`,
        source: {
          id: SOURCE_SCAG,
          page: 139,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 109,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_CELESTIAL,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: true,
    name: 'Небожитель',
    nameEn: 'The Celestial',
    description: `Ваш покровитель — могущественное существо с Верхних Планов. Вы связаны с древним [эмпиреем](CREATURE:${CREATURE_EMPYREAN}), [соларом](CREATURE:${CREATURE_SOLAR}), [ки-рином](CREATURE:${CREATURE_KI_RIN}), [единорогом](CREATURE:${CREATURE_UNICORN}) или другим существом, которое находится в планах вечного блаженства. Ваш договор с этим существом позволяет вам ощутить малейшие колебания священного света, который освещает мультивселенную.

Присоединение к такой силе может привести к изменениям в Вашем поведении и убеждениях. Вы можете пробудить в себе желание уничтожить нежить, победить исчадий и защитить невинных. Время от времени Ваше сердце также может быть наполнено стремлением к небесному царству Вашего покровителя и желанием бродить по этому раю до конца Ваших дней. Но Вы знаете, что сейчас Ваша миссия среди смертных, и что Ваш договор обязывает Вас принести свет в темные уголки мира.`,
    source: {
      id: SOURCE_XGTE,
      page: 39,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_FIEND,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: true,
    name: 'Исчадие',
    nameEn: 'The Fiend',
    description: [
      {
        header: 'Исчадие',
        text: `Вы заключили договор с исчадием из нижних планов бытия, сущностью, обладающей злыми устремлениями, даже если Вы сами не разделяете их.

Эти сущности жаждут осквернения и разрушения всего сущего, особенно Вас самих. Исчадия настолько мощные, чтоб заключить договор, включают демонических повелителей, таких как [Демогоргон](CREATURE:${CREATURE_DEMOGORGON}), [Оркус](CREATURE:${CREATURE_ORCUS}), [Фраз’Урб-луу](CREATURE:${CREATURE_FRAZ_URBLUU}) и [Бафомет](CREATURE:${CREATURE_BAPHOMET}); архидьяволов, таких как [Асмодей](GOD:${GOD_ASMODEUS}), Диспатер, Мефистофель и Белиал; наиболее могущественных [исчадий преисподней](CREATURE:${CREATURE_PIT_FIEND}) и [балоров](CREATURE:${CREATURE_BALOR}); [ультролотов](CREATURE:${CREATURE_ULTROLOTH}) и других повелителей юголотов.`,
        source: {
          id: SOURCE_PHB,
          page: 109,
        },
      },
      {
        header: 'Исчадия',
        text: `Бесчисленные сделки заключаются исчадиями со смертными колдунами по всем Королевствам — и их так много, что «колдун» стал почти синонимом адских сил на Фаэруне. Исчадия включают в себя Архидьяволов Девяти Преисподен и их наиболее влиятельных герцогов, Демонических Лордов Бездны и ультралотов, правящих армиями юголотов. Впрочем, такие сделки не обязательно должны быть заключены со столь мощными сущностями напрямую. Часто, более слабые исчадия служат посредниками, и колдун может даже не знать кому он служит. Ниже приведены наиболее примечательные покровители исчадия:

# Баазка

Баазка, дьявол ямы, стоит за большинством недавних вторжений инфернальных сил из Замка Драконье Копьё. Его планы на Побережье Мечей были разрушены вместе с планами Красных Магов, но его амбиции по отношению к региону остались в силе.

# Белафосс

Белафосс, подручный демон [Демогоргона](CREATURE:${CREATURE_DEMOGORGON}), считает себя величайшим слугой Принца Демонов и потому соперником мощи [Демогоргона](CREATURE:${CREATURE_DEMOGORGON}).

# Вендонай

Вендонай, лорд-[балор](CREATURE:${CREATURE_BALOR}) был первым, кто соблазнил тёмных [эльфов](PC_RACE:${PC_RACE_ELF}) вызывать демонов в древних войнах эльфийских народов. Он также наставил их поклоняться [Ллос](GOD:${GOD_LOLTH}) и продолжал советовать и учить их ещё долгое время после Падения.

# Горгот

Горгот, загадочная инфернальная сущность, которая ищет божественности, будучи запертой в мире внутри магического щита.

# Лоркан

Лоркан является [камбионом](CREATURE:${CREATURE_CAMBION}), который собирает колдунов, как коллекционеры — бабочек. Его любимая часть коллекции, Троильская Чёртова Дюжина, включает в себя колдунов, кровных потомков тринадцати заключивших первый пакт с [Асмодеем](GOD:${GOD_ASMODEUS}).

# Малкизид

Малкизид, павший [солар](CREATURE:${CREATURE_SOLAR}), предавший Селдарин. С тех пор, Малкизид наслаждался каждым несчастьем, которое он может принести [эльфам](PC_RACE:${PC_RACE_ELF}), но наивысшее наслаждение он получает, когда настраивает [эльфов](PC_RACE:${PC_RACE_ELF}) друг против друга.

# Эльтаб

Эльтаб, когда-то заключённый под тэйским городом Эльтаббар, который своими улицами и каналами образовывал печать его заключения. Демон нынче на свободе в мире и ищет мщения.

# Эррту

Эррту, [балор](CREATURE:${CREATURE_BALOR}) мучивший Дриззта До’Урдена более столетия, по большей части из-за обладания артефактом под названием Креншинибон. Проиграв последнюю битву и будучи изгнанным из мира, [балор](CREATURE:${CREATURE_BALOR}) теперь ищет непрямые способы отомстить.`,
        source: {
          id: SOURCE_SCAG,
          page: 139,
        },
      },
    ],
    source: [
      {
        id: SOURCE_PHB,
        page: 110,
      },
      {
        id: SOURCE_SRD,
        page: 50,
      },
    ],
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: true,
    name: 'Великий Древний',
    nameEn: 'The Great Old One',
    description: [
      {
        header: 'Великий Древний',
        text: `Ваш покровитель — таинственная сущность, природа которой совершенно чужда ткани реальности. Он может прийти из Дальнего Предела, пространства за пределами реальности, или это может быть один из древних богов, известных только в легендах. Его мотивы непонятны смертным, и его знания настолько великие и древние, что даже величайшие библиотеки меркнут в сравнении с имеющимися у него секретами. Великий Древний может не знать о Вашем существовании или быть полностью равнодушным к Вам, но секреты, которые Вы узнали, позволяют черпать магию из этого источника.

Сущностями этого типа могут быть [Гонадор](GOD:${GOD_GHAUNADAUR}), зовущийся «Тот Кто Таится», Тариздун «Скованный Бог», [Дендар «Ночная Змея»](GOD:${GOD_DENDAR}), Заргон «Возвращающийся», Великий Ктулху, и прочие непостижимые создания.`,
        source: {
          id: SOURCE_PHB,
          page: 110,
        },
      },
      {
        header: 'Великие Древние',
        text: `Вне планов существования, известных волшебникам и мудрецам, лежит Дальний Предел, где живут Великие Древние — непознаваемые существа вне времени и пространства. Попасть туда можно лишь через нечестивые ритуалы и в снах немногих, кто черпает силу у таких существ. Некоторые богохульные имена, связанные с той страной и её безумием, приведены ниже:

# [Гонадор](GOD:${GOD_GHAUNADAUR})

[Гонадор, Тот Кто Скрыт](GOD:${GOD_GHAUNADAUR}), бог аберраций Подземья, также известный как Древнее Око. Ему поклоняются (если вообще можно так выразиться) слизи и сходные существа.

# [Дендар](GOD:${GOD_DENDAR})

[Дендар Ночная Змея, Пожирательница Миров](GOD:${GOD_DENDAR}), по слухам, отродье первого кошмара, пожиратель скверных видений и предвестник конца света. Её колдунам часто снится шипение Дендар и сухой шелест её чешуи, когда они только осознают свой потенциал.

# Заргон

Заргон, Возвращающийся, также называемый Несокрушимым Тираном, по слухам не убиваемое и не умирающее зло. Некоторые истории утверждают, что Заргон был первым владыкой Девяти Преисподен. Другие, что он могущественный Принц Демонов, изгнанный из Бездны. Возможно, ни одна из этих историй не является правдой. Наверняка известно лишь, что Заргон — это сила вселяющая ужас и безумие.

# Кезеф

Кезеф, Гончая Хаоса, чёрный скелет мастифа покрытый копошащимися червями, а кровь его — чёрная кислота. Боги заключили Кезефа на нерушимой привязи, скованной [Гондом](GOD:${GOD_GOND}), в сияющей тюрьме, созданной [Мистрой](GOD:${GOD_MYSTRA}). Для того чтобы заманить Гончую Хаоса туда, [Тиру](GOD:${GOD_TYR}) пришлось позволить ему откусить себе руку.

# Моандер

Моандер, тёмная сила разложения и гнили. Отмеченные его влиянием сначала видят сон, _«семя Моандера»_, в котором слышат слова: _«Не ставь под сомнения слов Моандера, или будешь наказан Пожирающим Изнутри. Иди и владей сущностью силы, действуй для меня. Убивай и пусть всё покроет гниль. Бойся меня и подчиняйся мне»_.

# Тирантракс

Тирантракс, также известный как Дух Одержимости или Горящий, ищет власти над миром через тела других. Как и Мать Земля, он использует магические колодцы как окна в мир, чтобы распространять своё влияние.`,
        source: {
          id: SOURCE_SCAG,
          page: 139,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 110,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_GENIE,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: true,
    name: 'Гений',
    nameEn: 'The Genie',
    description: [
      `Вы заключаете договор с одним из редчайших представителей гениев — благородным гением.

Эти сущности — правители обширных владений Стихийных планов, имеющие огромное влияние на младших гениев и элементалей.

Благородные гении различаются по своим характерам и мотивации, но все они — высокомерны и обладают силой, сравнимой с силой малых божеств. Они с удовольствием обращают внимание на тех смертных, которые хотят заполучить гениев в услужение, с готовностью заключая договоры, расширяющие их влияние.

Выберите вид своего покровителя или определите его случайно по таблице «Виды гениев».

Каждый вид гениев связан с определённой стихией, как указано в таблице.`,
      {
        header: `Виды гениев`,
        text: `
| к4 | Вид   | Стихия | 
|----|-------|--------| 
| 1  | [Дао](CREATURE:${CREATURE_DAO})   | Земля  |
| 2  | [Джинн](CREATURE:${CREATURE_DJINNI}) | Воздух |
| 3  | [Ифрит](CREATURE:${CREATURE_EFREETI}) | Огонь  |
| 4  | [Марид](CREATURE:${CREATURE_MARID}) | Воды   |
`,
        source: {
          id: SOURCE_TCoE,
          page: 109,
        },
      },
    ],
    note: {
      text: `Я знаю что найти жильё в Сером Ястребе сложно, но, если гении или колдуны предлагают дешёвую аренду — надо бежать`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 109,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_GENIE_TCoE_PC_WtG,
    pcClassId: PC_CLASS_WARLOCK,
    releasedAs: PC_SUBCLASS_WARLOCK_THE_GENIE,
    isReady: true,
    name: 'Гений',
    nameEn: 'The Genie',
    description: [
      `Вы заключаете договор с одним из редчайших представителей гениев — благородным гением.

Эти сущности — правители обширных владений Стихийных планов, имеющие огромное влияние на младших гениев и элементалей.

Благородные гении различаются по своим характерам и мотивации, но все они — высокомерны и обладают силой, сравнимой с силой малых божеств. Они с удовольствием обращают внимание на тех смертных, которые хотят заполучить гениев в услужение, с готовностью заключая договоры, расширяющие своё влияние по всей мультивселенной.

Выберите вид своего покровителя или определите его случайно по таблице «Виды гениев».

Каждый вид гениев связан с определённой стихией, как указано в таблице.`,
      {
        header: `Виды гениев`,
        text: `
| к4 | Вид   | Стихия | 
|----|-------|--------| 
| 1  | Дао   | Земля  |
| 2  | Джинн | Воздух |
| 3  | Ифрит | Огонь  |
| 4  | Марид | Воды   |
`,
        source: {
          id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
          page: 1,
        },
      },
    ],
    source: [
      {
        id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
        page: 1,
      },
      {
        id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
        page: 2,
      },
    ],
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: true,
    name: 'Ведьмовской клинок',
    nameEn: 'The Hexblade',
    description: `Вы заключили свой договор с таинственной сущностью из Царства теней — силой, которая проявляется в разумном магическом оружии, сотворённом из тени. Могущественный меч Чёрный клинок — самый примечательный из этого оружия, которое на протяжении веков распространялось по мультивселенной. Теневая сила, лежащая в основе этого оружия, может предложить силу колдунам, которые заключают с ними договор. Некоторые колдуны ведьмовского клинка создают своё оружие, которое подражает тому, что создано в Царства теней. Другие отказываются от такого оружия, чтобы использовать тёмную магию из этого плана в своих заклинаниях.

Поскольку, как известно, [Королева Воронов](GOD:${GOD_RAVEN_QUEEN}) выковала первое из этих оружий, многие мудрецы предполагают, что она и сила едины, и что оружие вместе с колдунами ведьмовского клинка — это инструменты, которые она использует для манипулирования событиями на Материальном плане в своих, непостижимых целях.`,
    note: {
      text: `Ведьмовской клинок. Какое крутое имя! Так твой меч злой или проклят или что-то в этом роде? Но ты злой, да?… Ладно, теперь мне не нравится это имя. Имена не крутые, если они не имеют смысла. Как у меня: Занатар. Видишь? Оно крутое, и имеет смысл!`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 38,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_HEXBLADE_2017_02_13,
    pcClassId: PC_CLASS_WARLOCK,
    releasedAs: PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
    isReady: false,
    name: 'Ведьмовской клинок',
    nameEn: 'The Hexblade',
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_LURKER_IN_THE_DEEP_2019_05_09,
    pcClassId: PC_CLASS_WARLOCK,
    releasedAs: PC_SUBCLASS_WARLOCK_THE_FATHOMLESS,
    isReady: true,
    name: 'Таящийся в глубине',
    nameEn: 'The Lurker in the Deep',
    description: [
      `Вы заключили договор с сущностью, что скрывается где-то в глубинах океана или даже Стихийного плана Воды, такой как могучий кракен, древний первородный, или чудовищное порождение самых первых дней творения.

Вы служите его глазами и ушами, следя за миром вне его владений и отчитываясь о своих находках. Вы могли получить этот договор как член культа этой сущности, либо же Ваш покровитель мог спасти Вам жизнь, когда Вы тонули в море.`,
      {
        header: `Хватка Таящегося`,
        text: `Некоторые особенности Таящегося в глубине создают щупальца или пасть, проникающие в мир. Форма этих конечностей должна отражать природу конкретного покровителя. Например, колдун кракена может вызывать огромные осьминогоподобные щупальца, зазубренные клешни краба или громадный осьминожий клюв. А слуга водяного первородного может вызывать усики или извивающиеся потоки живой воды.`,
        source: {
          id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
          page: 2,
        },
      },
    ],
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_NOBLE_GENIE_2020_01_14,
    pcClassId: PC_CLASS_WARLOCK,
    releasedAs: PC_SUBCLASS_WARLOCK_THE_GENIE,
    isReady: true,
    name: 'Благородный гений',
    nameEn: 'The Noble Genie',
    description: `Вы заключаете договор с одним из редчайших представителей гениев — благородным гением.

Эти сущности — правители обширных владений Стихийных планов, имеющие огромное влияние на младших гениев и элементалей.

Благородные гении различаются по своим характерам и мотивации, но все они — высокомерные коллекционеры существ, сокровищ и знаний. Гений ценит свою коллекцию и будет защищать то, что считает своим.

Благодаря своей связи с благородным гением, Вы можете расширять их влияние и пополнять их коллекции вещами, существами и знаниями со всей мультивселенной.`,
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 4,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_RAVEN_QUEEN_2017_02_13,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: true,
    name: 'Королева Воронов',
    nameEn: 'The Raven Queen',
    description: `Ваш покровитель — [Королева Воронов](GOD:${GOD_RAVEN_QUEEN}), таинственная сущность, правящая Царством Тьмы из находящегося в этом жутком месте ледяного дворца. [Королева Воронов](GOD:${GOD_RAVEN_QUEEN}) наблюдает за миром, ожидая смерти каждого существа и следя за тем, чтобы оно встретило свой конец в нужное время и в нужном месте. Как правительница Царства Теней, она обитает в этом разлагающемся тёмном отражении мира. Её способность проникать в мир ограничена. Поэтому она ищет смертных колдунов, готовых служить её воле. Колдуны, служащие [Королеве Воронов](GOD:${GOD_RAVEN_QUEEN}), слышат во сне её шёпот и получают видения, которые отправляют их на задания и предупреждают о грядущих опасностях.

От своих последователей [Королева Воронов](GOD:${GOD_RAVEN_QUEEN}) ожидает, что они послужат проводниками её воли в мире. Она заботится о том, чтобы те, кому суждено умереть, ушли из мира так, как ожидается, и приказывает своим слугам побеждать тех, кто пытается обмануть смерть с помощью нежизни или других имитаций бессмертия. Она ненавидит разумную нежить и ожидает, что её последователи сразят её. При этом бездумная нежить, такая как [скелеты](CREATURE:${CREATURE_SKELETON}) и [зомби](CREATURE:${CREATURE_ZOMBIE}), в её глазах не более чем хромающие автоматоны.`,
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_SEEKER_2016_08_01,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: true,
    name: 'Искатель',
    nameEn: 'The Seeker',
    description: `Ваш покровитель — непостижимое существо, путешествующее по Астральному Плану в поисках тайн и знаний. В обмен на дары Искателя Вы блуждаете по миру в поисках знаний, которыми можете с ним поделиться.

Вашим покровителем может быть любое божество или другое могущественное существо, посвященное знаниям или забытым тайнам. Селестиан — идеальный покровитель в мире Серого Ястреба, и именно он вдохновил этот подкласс. В Забытых Королевствах Вашим покровителем может быть [Азут](GOD:${GOD_AZUTH}) или [Огма](GOD:${GOD_OGHMA}). Ауреон — отличный покровитель в Эберроне, а в Кринне и кампаниях сеттинга Саги о Копье для роли Искателя отлично подходит Гилиан.`,
    source: {
      id: SOURCE_UA_2016_08_01_THE_FAITHFUL,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_UNDEAD_2020_08_05,
    pcClassId: PC_CLASS_WARLOCK,
    releasedAs: PC_SUBCLASS_WARLOCK_THE_UNDEAD,
    isReady: true,
    name: 'Неживой',
    nameEn: 'The Undead',
    description: `Неживой — сущность, обитающая в тёмных углах мультивселенной. Ваш покровитель — Ацерерак, Азалин, Лорд Сот, Страд или какая-то другая древняя неживая сущность. Возможно, Вы ищете знаний, накопленных Вашим покровителем за бесчисленные года, а он может считать Вас лишь частью своего многовекового плана.`,
    source: {
      id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_UNDEAD,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: false,
    name: 'Неживой',
    nameEn: 'The Undead',
    source: {
      id: SOURCE_VRGtR,
      page: 36,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_UNDYING,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: true,
    name: 'Бессмертный',
    nameEn: 'The Undying',
    description: `Смерть не властна более над Вашим покровителем, который открыл секреты вечной жизни, хотя такая сила, как и всякая другая, имеет свою цену. Для Бессмертных, бывших когда-то смертными, целые жизни проходят, как времена года, а бесконечная смена дней и ночей для них лишь ровное мерцание свечи. Они хранят секреты веков жизни и смерти.

Векна, Лорд Руки и Глаза; ужасный Йуз; королева-лич Воль; Бессмертный Двор Аэринала; Влаакит, королева-лич гитьянки; бессмертный волшебник Фистанданталус — все они Бессмертные сущности.

В Королевствах Бессмертные покровители включают в себя Ларлоха, Теневого Короля, легендарного владыку Гробницы Варлока, и Гильгеама, Бога-Короля Унтера.`,
    source: {
      id: SOURCE_SCAG,
      page: 140,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_UNDYING_LIGHT_2015_11_02,
    pcClassId: PC_CLASS_WARLOCK,
    releasedAs: PC_SUBCLASS_WARLOCK_THE_CELESTIAL,
    isReady: false,
    name: 'Бессмертный свет',
    nameEn: 'The Undying Light',
    source: {
      id: SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_CELESTIAL_2017_06_05,
    pcClassId: PC_CLASS_WARLOCK,
    releasedAs: PC_SUBCLASS_WARLOCK_THE_CELESTIAL,
    isReady: false,
    name: 'Небожитель',
    nameEn: 'The Celestial',
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 4,
    },
  },
  {
    id: PC_SUBCLASS_WARLOCK_THE_FATHOMLESS,
    pcClassId: PC_CLASS_WARLOCK,
    isReady: false,
    name: 'Глубинный',
    nameEn: 'The Fathomless',
    note: {
      text: `Я никогда не понимала, почему некоторые люди так пугаются щупалец. Вы пробовали нигири с осьминогом? Одно из немногих свидетельств благосклонности мультивселенной.`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 107,
    },
  },
]
