const {PC_SUBCLASS_SORCERER_WILD_MAGIC} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  CREATURE_FLUMPH,
  CREATURE_UNICORN,
} = require('./../../../creatureIdList')
const {
  SPELL_CONFUSION,
  SPELL_FIREBALL,
  SPELL_FLY,
  SPELL_FOG_CLOUD,
  SPELL_GREASE,
  SPELL_LEVITATE,
  SPELL_MAGIC_MISSILE,
  SPELL_MIRROR_IMAGE,
  SPELL_POLYMORPH,
  SPELL_REINCARNATE,
  SPELL_REMOVE_CURSE,
} = require('./../../../spellIdList')
const {
  FEATURE_BEND_LUCK,
  FEATURE_CONTROLLED_CHAOS,
  FEATURE_METAMAGIC,
  FEATURE_SORCERY_POINTS,
  FEATURE_SPELL_BOMBARDMENT,
  FEATURE_TIDES_OF_CHAOS,
  FEATURE_WILD_MAGIC_SURGE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_WILD_MAGIC_SURGE,
    name: `Волна дикой магии`,
    nameEn: `Wild Magic Surge`,
    lvl: 1,
    text: `Ваше чародейство может вызвать волны дикой магии.

Сразу после накладывания Вами заклинания как минимум 1 уровня Мастер может попросить Вас бросить к20. Если выпадает 1, бросьте кость по таблице «Волна дикой магии» для создания случайного магического эффекта. Волна может возникать только один раз за ход.

Если эффект волны является заклинанием, он слишком непредсказуем, чтобы его можно было модифицировать _[Метамагией](FEATURE:${FEATURE_METAMAGIC})_. Если заклинание-эффект в обычных обстоятельствах требует концентрацию, то в данном случае оно не требует концентрацию. Заклинание-эффект длится свою максимальную длительность.

# Волна дикой магии

| к100  | Эффект |
|-------|--------|
| 01–02 | Совершайте бросок по этой таблице в начале каждого своего хода в течение следующей минуты, игнорируя результат «01–02». |
| 03–04 | В течение минуты Вы можете видеть невидимых существ, если у Вас есть линия обзора до них. |
| 05–06 | В свободном пространстве в пределах 5 футов от Вас появляется модрон, выбранный и контролируемый Мастером. Модрон исчезает через 1 минуту. |
| 07–08 | Вы сотворяете [Огненный шар](${SPELL_FIREBALL}) как заклинание 3 уровня, с центром на себе. |
| 09–10 | Вы сотворяете [Волшебную стрелу](SPELL:${SPELL_MAGIC_MISSILE}) как заклинание 5 уровня. |
| 11–12 | Бросьте к10. Ваш рост изменяется на количество дюймов, равное результату броска. Если бросок нечётный, Вы уменьшаетесь. Если бросок чётный, Вы растёте. |
| 13–14 | Вы сотворяете заклинание [Смятение](SPELL:${SPELL_CONFUSION}) с центром на себе. |
| 15–16 | В течение следующей минуты Вы восстанавливаете по 5 хитов в начале каждого своего хода. |
| 17–18 | У Вас вырастает длинная борода из перьев, которая остаётся, пока Вы не чихнёте, и в этот момент перья разлетаются во все стороны. |
| 19–20 | Вы сотворяете заклинание [Скольжение](SPELL:${SPELL_GREASE}) с центром на себе. |
| 21–22 | Существа получают помеху к испытаниям от следующего заклинания, которое Вы сотворите в течение следующей минуты, требующего пройти испытание. |
| 23–24 | Ваша кожа окрашивается в ярко-синий цвет. Заклинание [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) отменяет этот эффект. |
| 25–26 | На Вашем лбу на минуту появляется глаз. В течение этого времени, Вы совершаете с преимуществом проверки Мудрости (Внимательности), полагающиеся на зрение. |
| 27–28 | В течение следующей минуты все Ваши заклинания со временем накладывания «1 действие» могут быть сотворены бонусным действием. |
| 29–30 | Вы телепортируетесь на расстояние до 60 футов в свободное пространство по Вашему выбору, которое видите. |
| 31–32 | Вы перемещаетесь на Астральный План до конца своего следующего хода, после чего Вы вернётесь в пространство, ранее занимаемое, или ближайшее свободное пространство, если это пространство занято. |
| 33–34 | Урон от следующего заклинания, причиняющего урон и сотворённого в течение следующей минуты, будет максимальным. |
| 35–36 | Бросьте к10. Ваш возраст изменяется на число лет, равное броску. Если бросок нечётный, Вы молодеете (минимум на 1 год). Если бросок чётный, Вы стареете. |
| 37–38 | В пределах 60 футов от Вас в свободном пространстве появляются 1к6 [фламфов](CREATURE:${CREATURE_FLUMPH}), контролируемых Мастером, и напуганных Вами. Они исчезают через 1 минуту. |
| 39–40 | Вы восстанавливаете 2к10 хитов. |
| 41–42 | Вы превращаетесь в горшок с цветами до начала своего следующего хода. Будучи растением, Вы недееспособны и уязвимы для всех видов урона. Если Ваши хиты опускаются до 0, Ваш горшок раскалывается, и Вы возвращаетесь в свою форму. |
| 43–44 | В течение следующей минуты Вы можете в каждый свой ход бонусным действием телепортироваться на расстояние до 20 футов. |
| 45–46 | Вы сотворяете заклинание [Левитация](SPELL:${SPELL_LEVITATE}) на себя. |
| 47–48 | В пределах 5 футов от Вас появляется [единорог](CREATURE:${CREATURE_UNICORN}), контролируемый Мастером. Он исчезает через 1 минуту |
| 49–50 | Вы не можете говорить в течение следующей минуты. Всякий раз, когда Вы пытаетесь говорить, из Вашего рта вылетают розовые пузырьки. |
| 51–52 | В течение следующей минуты рядом с Вами парит призрачный щит, предоставляющий бонус +2 к КД и иммунитет к [Волшебной стреле](SPELL:${SPELL_MAGIC_MISSILE}). |
| 53–54 | Вы получаете иммунитет к воздействию алкоголя на Ваш организм в течение следующих 5к6 дней. |
| 55–56 | У Вас выпадают все волосы, но отрастают обратно за 24 часа. |
| 57–58 | В течение следующей минуты все легковоспламеняющиеся предметы, не несомые и не носимые другим существом, которых Вы касаетесь, загораются. |
| 59–60 | Вы восстанавливаете самую низкоуровневую ячейку заклинания, потраченную Вами. |
| 61–62 | В течение следующей минуты Вы должны кричать, когда говорите. |
| 63–64 | Вы сотворяете [Туманное облако](SPELL:${SPELL_FOG_CLOUD}) с центром на себе. |
| 65–66 | До трёх существ на Ваш выбор в пределах 30 футов от Вас получают урон электричеством 4к10. |
| 67–68 | Вы испуганы ближайшим к Вам существом до конца своего следующего хода. |
| 69–70 | Все существа в пределах 30 футов от Вас становятся невидимыми на 1 минуту. Невидимость прекращается, если существо атакует или сотворяет заклинание. |
| 71–72 | Вы получаете на 1 минуту сопротивление ко всем видам урона. |
| 73–74 | Случайное существо в пределах 60 футов от Вас становится отравленным на 1к4 часа. |
| 75–76 | Вы в течение минуты излучаете яркий свет в радиусе 30 футов. Все существа, оканчивающие ход в пределах 5 футов от Вас, слепнет до конца своего следующего хода. |
| 77–78 | Вы сотворяете на себя заклинание [Превращение](SPELL:${SPELL_POLYMORPH}). Если Вы проваливаете испытание, то на время действия заклинания превращаетесь в овцу. |
| 79–80 | В течение минуты в пределах 10 футов от Вас в воздухе порхают иллюзорные бабочки и лепестки цветов. |
| 81–82 | Вы можете немедленно совершить одно дополнительное действие. |
| 83–84 | Все существа в пределах 30 футов от Вас получают урон некротической энергией 1к10. Вы восстанавливаете хиты, равные сумме причинённого урона. |
| 85–86 | Вы сотворяете [Отражения](SPELL:${SPELL_MIRROR_IMAGE}). |
| 87–88 | Вы сотворяете [Полёт](SPELL:${SPELL_FLY}) на случайное существо в пределах 60 футов от Вас. |
| 89–90 | Вы становитесь невидимым на 1 минуту. При этом другие существа не могут услышать Вас. Невидимость оканчивается, если Вы атакуете или сотворяете заклинание. |
| 91–92 | Если Вы погибнете в течение следующей минуты, то мгновенно вернётесь к жизни, как если бы Вас воскресили заклинанием [Реинкарнация](SPELL:${SPELL_REINCARNATE}). |
| 93–94 | Ваш размер на 1 минуту увеличивается на одну категорию. |
| 95–96 | Вы и все существа в пределах 30 футов от Вас получаете на 1 минуту уязвимость к колющему урону. |
| 97–98 | В течение следующей минуты Вы окружены тихой неземной музыкой. |
| 99–00 | Вы восстанавливаете все потраченные [Очки чародейства](FEATURE:${FEATURE_SORCERY_POINTS}). |
`,
  },
  {
    id: FEATURE_TIDES_OF_CHAOS,
    name: `Поток хаоса`,
    nameEn: `Tides of Chaos`,
    lvl: 1,
    text: `Вы можете манипулировать силами случая и хаоса, чтобы получить преимущество для одного броска атаки, проверки характеристики или испытания. Сделав это, Вы должны совершить длинный отдых, прежде чем Вы сможете использовать это умение ещё раз.

В любой момент до того как Вы восстановите это умение, сразу после использования Вами заклинания чародея как минимум 1 уровня, Мастер может попросить Вас бросить кость на эффект от [Волны дикой магии](FEATURE:${FEATURE_WILD_MAGIC_SURGE}). После этого Вы восстанавливаете использование этого умения.`,
  },
  {
    id: FEATURE_BEND_LUCK,
    name: `Подчинение удачи`,
    nameEn: `Bend Luck`,
    lvl: 6,
    text: `Вы получаете возможность менять судьбу с помощью дикой магии.

Когда другое существо, видимое Вам, совершает бросок атаки, проверку характеристики или проходил испытание, Вы можете реакцией потратить 2 [Очка чародейства](FEATURE:${FEATURE_SORCERY_POINTS}) и бросить 1к4. Результат броска станет бонусом или штрафом (на Ваш выбор) к броску существа.

Вы можете сделать это после броска существа, но до того, как начнут действовать эффекты от броска.`,
  },
  {
    id: FEATURE_CONTROLLED_CHAOS,
    name: `Контролируемый хаос`,
    nameEn: `Controlled Chaos`,
    lvl: 14,
    text: `Вы получаете толику контроля над [Волнами дикой магии](FEATURE:${FEATURE_WILD_MAGIC_SURGE}).

Каждый раз, когда Вы совершаете бросок по таблице дикой магии, Вы можете совершить два броска и использовать любой из двух.`,
  },
  {
    id: FEATURE_SPELL_BOMBARDMENT,
    name: `Разрушительные заклинания`,
    nameEn: `Spell Bombardment`,
    lvl: 18,
    text: `Разрушительная энергия Ваших заклинаний увеличивается.

Когда Вы совершаете бросок урона от заклинания, и на одной из костей выпадает максимально возможное значение, выберите одну из этих костей и бросьте её ещё раз. Добавьте выпавшее значение к урону.

Вы можете использовать это умение только один раз в ход.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_SORCERER_WILD_MAGIC,
    source: {
      id: SOURCE_PHB,
      page: 104,
    },
  })
)

