const {
  SOURCE_ROT,
} = require('./../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_30,
} = require('./../../../../crList')
const {
  SIZE_GARGANTUA,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ARCHDEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_TRUE_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DIVINE_WORD,
} = require('./../../../../spellIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_5,
  ABILITY_FRIGHTFUL_PRESENCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  colorDragonDescriptionList,
  tiamatDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_TIAMAT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Тиамат',
  nameEn: 'Tiamat',
  id: CREATURE_TIAMAT,
  hasSpoilers: true,
  description: [
    ...tiamatDescriptionList,
    ...colorDragonDescriptionList,
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_ARCHDEVIL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_ROT,
    page: 95,
  },
  armor: {
    ac: 25,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 30,
  },
  speed: {
    [SPEED_WALK]: 60,
    [SPEED_FLY]: 120,
  },
  params: {
    [PARAM_STR]: 30,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 30,
    [PARAM_INT]: 26,
    [PARAM_WIT]: 26,
    [PARAM_CHA]: 28,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 240,
    },
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DRACONIC,
    LANG_INFERNAL,
  ],
  cr: CR_30,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_5,
    ABILITY_MAGIC_WEAPONS,
    {
      name: `Ограниченный иммунитет к магии`,
      description: `На ★СУЩЕСТВО★ не действуют заклинания 6 уровня или ниже, если только ★он★ ★сам★ не желает, чтобы они на ★него★ подействовали. ★СУЩЕСТВО★ получает преимущество к испытаниям ото всех остальных заклинаний и магических эффектов.`,
    },
    {
      id: ABILITY_REGENERATION,
      regen: 30,
    },
    {
      name: 'Развоплощение',
      description: `Когда хиты Тиамат опускаются до 0 или она должна умереть, её тело уничтожается, но сущность отправляется назад в её домен в Девяти Преисподнях и она вновь на некоторое время теряет способность принять физическую форму.`,
    },
    {
      name: 'Несколько голов',
      description: `Тиамат может совершать реакцию каждый ход, нежели одну за раунд. Она также получает преимущество к испытаниям от потери сознания. Если она проваливает испытание против ошеломления, она тратит впустую одно непотраченное легендарное действие.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    spellIdByCountList: [
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DIVINE_WORD,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Тиамат может использовать _Ужасающее присутствие_. После этого она совершает три атаки: две — _Когтями_ и одну — _Хвостом_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 15,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 25,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 10,
        },
      },
    },
    {
      id: ABILITY_FRIGHTFUL_PRESENCE,
      range: 240,
      witSave_DC: 26,
    },
  ],
  legendaryPoints: 5,
  legendaryActionDescription: `Варианты легендарных действий Тиамат связаны с её пятью драконьими головами (_Укус_ и _Оружие дыхания_ каждой). Как только Тиамат совершит легендарное действие одной из голов, до начала своего следующего хода она не может предпринимать ещё одно действие, связанное с этой же головой.`,
  legendaryActionList: [
    {
      name: 'Голова белого дракона: Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 20,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 4,
            diceType: 10,
            diceBonus: 10,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 4,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Голова зелёного дракона: Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 20,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 4,
            diceType: 10,
            diceBonus: 10,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 4,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Голова красного дракона: Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 20,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 4,
            diceType: 10,
            diceBonus: 10,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 4,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Голова синего дракона: Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 20,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 4,
            diceType: 10,
            diceBonus: 10,
          },
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 4,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Голова чёрного дракона: Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 20,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 4,
            diceType: 10,
            diceBonus: 10,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 4,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Голова белого дракона: Ледяное дыхание',
      cost: 2,
      description: `Тиамат выдыхает ледяной воздух 90-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 27, получая урон холодом 72 (16к8) при провале, или половину этого урона при успехе.`,
    },
    {
      name: 'Голова зелёного дракона: Ядовитое дыхание',
      cost: 2,
      description: `Тиамат выдыхает ядовитый газ 90-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 27, получая урон ядом 77 (22к6) при провале, или половину этого урона при успехе.`,
    },
    {
      name: 'Голова красного дракона: Огненное дыхание',
      cost: 2,
      description: `Тиамат выдыхает огонь 90-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 27, получая урон огнём 91 (26к6) при провале, или половину этого урона при успехе.`,
    },
    {
      name: 'Голова синего дракона: Электрическое дыхание',
      cost: 2,
      description: `Тиамат выдыхает молнию 120-футовой линией шириной 10 футов. Все существа в этой области должны пройти испытание Ловкости СЛ 27, получая урон кислотой 88 (16к10) при провале, или половину этого урона при успехе.`,
    },
    {
      name: 'Голова чёрного дракона: Кислотное дыхание',
      cost: 2,
      description: `Тиамат выдыхает кислоту 120-футовой линией шириной 10 футов. Все существа в этой области должны пройти испытание Ловкости СЛ 27, получая урон кислотой 67 (15к8) при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
