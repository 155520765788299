const generateRarityList = require('./../../../../../../utils/generateRarityList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {MAGIC_ITEM_WEAPON_PLUS_1_2_OR_3} = require('./../../../../../magicItemIdList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')
const list = require('./list')

module.exports = {
  id: MAGIC_ITEM_WEAPON_PLUS_1_2_OR_3,
  name: `Оружие, +1, +2 или +3`,
  nameEn: `Weapon, +1, +2 or +3`,
  rarity: generateRarityList(list),
  type: MGC_TYPE_WEAPON,
  description: `Вы получаете бонус к броскам атаки и урона, совершённым этим магическим оружием.

Размер бонуса определяется редкостью оружия.`,
  typeComment: `любое`,
  genderId: GENDER_MIDDLE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 187,
  },
}
