const {SOURCE_PHB} = require('./../../sourceList')
const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_THIEVES_CANT} = require('./../../languageIdList')
const {PC_CLASS_ROGUE} = require('./../../pcClassIdList')

module.exports = {
  id: LANG_THIEVES_CANT,
  alphabetId: null,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Воровской жаргон',
    genitive: 'Воровского жаргона',
    instrumental: 'Воровским жаргоном',
    prepositional: 'Воровском жаргоне',
  },
  nameEn: 'Thieves’ cant',
  typicalSpeakers: 'Плуты',
  description: {
    text: `Во время обучения [плуты](PC_CLASS:${PC_CLASS_ROGUE}) выучивают воровской жаргон, тайную смесь диалекта, жаргона и шифра, который позволяет скрывать сообщения в, казалось бы, обычном разговоре. Только другое существо, знающее воровской жаргон, понимает такие сообщения. Это занимает в четыре раза больше времени, нежели передача тех же слов прямым текстом.

Кроме того, [плуты](PC_CLASS:${PC_CLASS_ROGUE}) понимают набор секретных знаков и символов, используемый для передачи коротких и простых сообщений. Например, является ли область опасной или территорией гильдии воров, находится ли поблизости добыча, простодушны ли люди в округе, и предоставляют ли здесь безопасное убежище для воров в бегах.`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  isRealLang: true,
  isReady: true,
}
