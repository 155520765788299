const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../sourceList')

const {
  CREATURE_DRYAD,
  CREATURE_LICH,
  CREATURE_TROGLODYTE,
} = require('./../../creatureIdList')
const {fullCasterSpellSlotTable} = require('./../../spellSlotTable')
const {BACKGROUND_HERMIT} = require('./../../backgroundIdList')

const {
  PARAM_CHA,
  PARAM_CON,
} = require('./../../paramList')

const {
  GEAR_COMPONENT_POUCH,
  GEAR_CROSSBOW_BOLTS,
  GEAR_CROSSBOW_LIGHT,
  GEAR_DAGGER,
  GEAR_DART,
  GEAR_DUNGEONEER_S_PACK,
  GEAR_EXPLORER_S_PACK,
  GEAR_QUARTERSTAFF,
  GEAR_SLING,
  GEAR_SPELLBOOK,
} = require('./../../gearIdList')

const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_PERSUASION,
  SKILL_RELIGION,
} = require('./../../skillList')

const {
  SPELL_LIGHT,
  SPELL_MAGIC_MISSILE,
  SPELL_PRESTIDIGITATION,
  SPELL_RAY_OF_FROST,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
} = require('./../../spellIdList')

const {
  PC_CLASS_SORCERER,
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_SORCERER,
  name: {
    singular: {
      nominative: 'чародей',
      genitive: 'чародея',
      dative: 'чародею',
      accusative: 'чародея',
      instrumental: 'чародеем',
      prepositional: 'чародее',
    },
    plural: {
      nominative: 'чародеи',
      genitive: 'чародеев',
      dative: 'чародеям',
      accusative: 'чародеев',
      instrumental: 'чародеями',
      prepositional: 'чародеях',
    },
  },
  nameEn: 'sorcerer',
  description: [
    `Золотые глаза вспыхивают, человек протягивает вперёд руку и высвобождает всепоглощающее пламя, что горит в его жилах. Пока адское пламя бушует вокруг его врагов, кожистые крылья раскрываются у него за спиной, и он взмывает в воздух.

Длинные волосы развеваются от магического ветра, полуэльф широко разводит руки в стороны и запрокидывает голову. Моментально подняв его над землёй, волны магической энергии проходят сквозь него и выплёскиваются в окружающее пространство громадной вспышкой молний.

Крадущийся за сталагмитом полурослик указывает пальцем на [троглодита](CREATURE:${CREATURE_TROGLODYTE}). Огненная струя устремляется из его пальца к существу. С усмешкой он прячется обратно за скалу, не подозревая, что дикая магия изменила цвет его кожи на ярко-синий.

Чародеи являются носителями магии, дарованной им при рождении их экзотической родословной, неким потусторонним влиянием или воздействием неизвестных вселенских сил. Никто не может обучиться чародейству, как, например, выучить язык, так же как никто не может обучить, как прожить легендарную жизнь. Никто не может избрать путь чародейства, сила сама выбирает носителя. `,
    {
      header: `Грубая магия`,
      text: `Магия является частью каждого чародея, наполняя тело, разум и дух скрытой силой, которая ждёт, когда её используют. Некоторые чародеи владеют магией, которая проистекает из древней родословной, связанной с магией драконов. Другие же несут в себе сырую, неконтролируемую силу, хаотичный шторм, который может выплеснуться самым неожиданным образом.

Проявление сил чародея крайне непредсказуемо. Некоторые драконьи родословные получают ровно одного чародея в каждом поколении, в других же родословных каждый индивид может являться чародеем. В большинстве случаев способность к чародейству возникает случайно. Некоторые чародеи не могут назвать источник своей силы, в то время как другие связывают его со странными событиями в своей жизни. Прикосновение демона, благословление [дриады](CREATURE:${CREATURE_DRYAD}) при рождении, или вода из мистического источника — всё это может разжечь искру чародейского дара. Это может быть дар божества магии, воздействие стихийных сил из Внутренних Планов, прикосновение к безумному хаосу Лимбо или понимание внутреннего устройства реальности.

Чародеи не используют [книги заклинаний](GEAR:${GEAR_SPELLBOOK}), на которые полагаются [волшебники](PC_CLASS:${PC_CLASS_WIZARD}), и, в отличие от [колдунов](PC_CLASS:${PC_CLASS_WARLOCK}), не полагаются на высокого покровителя, даровавшего им заклятья. Научившись использовать и направлять свою собственную врождённую магию, они могут открыть для себя новые ошеломляющие способы высвобождения этой силы. `,
      source: {
        id: SOURCE_PHB,
        page: 100,
      },
    },
    {
      header: `Необъяснимые силы`,
      text: `Чародеи довольно редко встречаются в мире, и совсем уж необычно встретить чародея, каким-либо образом не вовлечённого в приключения. Люди с магическими силами, текущими по их жилам, вскоре обнаруживают, что данные силы не могут постоянно дремать. Чародейская магия требует использования, и имеет тенденцию вырываться самым непредсказуемым образом, если её игнорировать. Зачастую, у чародеев туманные мотивы, толкающие их на приключения.

Некоторые ищут более глубокого понимания магических сил, влияющих на них, или разгадок их таинственного происхождения. Другие же надеются найти способ избавления от своего дара, или раскрытия его полного потенциала. Вне зависимости от их целей, чародеи столь же полезны в отряде искателей приключений, как и [волшебники](PC_CLASS:${PC_CLASS_WIZARD}). Хотя чародеи и не владеют множеством заклинаний, это с лихвой компенсируется большой гибкостью в использовании тех заклятий, что им известны.`,
      source: {
        id: SOURCE_PHB,
        page: 100,
      },
    },
    {
      header: `Создание чародея`,
      text: `Самым важным аспектом при создании вашего чародея, является источник его силы. Вы в самом начале выбираете происхождение, которое может быть связано с драконьей родословной или влиянием дикой магии, но конкретный источник Вашей силы определяется только вами. Возможно, это семейное проклятье, доставшееся вам от далёких предков? Или какое-нибудь экстраординарное событие оставило своё благословление, даровав врождённые способности к магии, или, может, располосовав вас шрамами?

Как Вы ощущаете магические силы, струящиеся сквозь вас? Вы приняли их, попытались освоить или упиваетесь их непредсказуемой природой? Благословление это или проклятье? Искали ли Вы их, или они сами нашли вас? Есть ли возможность отказаться от них, и поступили бы Вы так, если бы могли? Что Вы намерены делать с ними? Возможно, Вы чувствуете, что были одарены этой силой для свершения какого-то высшего предназначения. Или же, Вы могли решить, что такая власть даёт вам право делать всё, что вздумается, и отбирать всё необходимое у тех, кто такой силы не имеет. Возможно, ваши силы связаны с могущественной фигурой, известной в этом мире — фейским существом, благословившим вас при рождении, драконом, который смешал вашу кровь с каплей своей, [личом](CREATURE:${CREATURE_LICH}), который создал вас в качестве эксперимента, или божеством, которое избрало вас носителем этой силы. `,
      source: {
        id: SOURCE_PHB,
        page: 101,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать чародея, следуя этим рекомендациям.

1. Во-первых, у Вашей Харизмы должно быть наивысшее значение. Следующей по величине характеристикой должно быть Телосложение.
2. Во-вторых, выберите предысторию «[Отшельник](BACKGROUND:${BACKGROUND_HERMIT})».
3. В-третьих, получите заговоры [Луч холода](SPELL:${SPELL_RAY_OF_FROST}), [Свет](SPELL:${SPELL_LIGHT}), [Фокусы](SPELL:${SPELL_PRESTIDIGITATION}) и [Электрошок](SPELL:${SPELL_SHOCKING_GRASP}) вместе с заклинаниями 1 уровня [Волшебная стрела](SPELL:${SPELL_MAGIC_MISSILE}) и [Щит](SPELL:${SPELL_SHIELD}). `,
      source: {
        id: SOURCE_PHB,
        page: 101,
      },
    },
  ],
  note: [
    {
      text: `Практика и учёба для любителей. Истинная сила — это право первородства.`,
      author: `Хеннет, отпрыск Тиамат`,
    },
    {
      text: `Божественное? Тайное? Какая разница, откуда приходит волшебство? Либо оно у вас есть, либо нет. К счастью, у меня его предостаточно.`,
      author: `Занатар`,
    },
  ],
  featureCollection: {
    hitDice: 6,
    proficiency: {
      weapon: {
        gearList: [
          GEAR_CROSSBOW_LIGHT,
          GEAR_DAGGER,
          GEAR_DART,
          GEAR_QUARTERSTAFF,
          GEAR_SLING,
        ],
      },
      savingThrow: [
        PARAM_CHA,
        PARAM_CON,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_ARCANA,
            SKILL_DECEPTION,
            SKILL_INSIGHT,
            SKILL_INTIMIDATION,
            SKILL_PERSUASION,
            SKILL_RELIGION,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 3,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) [Лёгкий арбалет](GEAR:${GEAR_CROSSBOW_LIGHT}) и 20 [арбалетных болтов](GEAR:${GEAR_CROSSBOW_BOLTS})
    * б) Любое простое оружие 
2. Выберите одно
    * а) [Мешочек с компонентами](GEAR:${GEAR_COMPONENT_POUCH})
    * б) Магическая фокусировка 
3. Выберите одно
    * а) [Набор исследователя подземелий](GEAR:${GEAR_DUNGEONEER_S_PACK})
    * б) [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})
4. Два [кинжала](GEAR:${GEAR_DAGGER})`,
    },
  },
  spellSlotTable: fullCasterSpellSlotTable,
  source: [
    {
      id: SOURCE_PHB,
      page: 100,
    },
    {
      id: SOURCE_SRD,
      page: 42,
    },
  ],
}
