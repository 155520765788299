module.exports.SPELL_ABI_DALZIM_S_HORRID_WILTING = 'abi_dalzim_s_horrid_wilting'
module.exports.SPELL_ABSORB_ELEMENTS = 'absorb_elements'
module.exports.SPELL_ACID_SPLASH = 'acid_splash'
module.exports.SPELL_ACID_STREAM_2020_03_26 = 'acid_stream_2020_03_26'
module.exports.SPELL_AGANAZZAR_S_SCORCHER = 'aganazzar_s_scorcher'
module.exports.SPELL_AID = 'aid'
module.exports.SPELL_ALARM = 'alarm'
module.exports.SPELL_ALTER_SELF = 'alter_self'
module.exports.SPELL_ANIMAL_FRIENDSHIP = 'animal_friendship'
module.exports.SPELL_ANIMAL_MESSENGER = 'animal_messenger'
module.exports.SPELL_ANIMAL_SHAPES = 'animal_shapes'
module.exports.SPELL_ANIMATE_DEAD = 'animate_dead'
module.exports.SPELL_ANIMATE_OBJECTS = 'animate_objects'
module.exports.SPELL_ANTILIFE_SHELL = 'antilife_shell'
module.exports.SPELL_ANTIMAGIC_FIELD = 'antimagic_field'
module.exports.SPELL_ANTIPATHY_SYMPATHY = 'antipathy_sympathy'
module.exports.SPELL_ARCANE_EYE = 'arcane_eye'
module.exports.SPELL_ARCANE_GATE = 'arcane_gate'
module.exports.SPELL_ARCANE_LOCK = 'arcane_lock'
module.exports.SPELL_ARMOR_OF_AGATHYS = 'armor_of_agathys'
module.exports.SPELL_ARMS_OF_HADAR = 'arms_of_hadar'
module.exports.SPELL_ASHARDALON_S_STRIDE = 'ashardalon_s_stride'
module.exports.SPELL_ASTRAL_PROJECTION = 'astral_projection'
module.exports.SPELL_AUGURY = 'augury'
module.exports.SPELL_AURA_OF_LIFE = 'aura_of_life'
module.exports.SPELL_AURA_OF_PURITY = 'aura_of_purity'
module.exports.SPELL_AURA_OF_VITALITY = 'aura_of_vitality'
module.exports.SPELL_AWAKEN = 'awaken'
module.exports.SPELL_BANE = 'bane'
module.exports.SPELL_BANISHING_SMITE = 'banishing_smite'
module.exports.SPELL_BANISHMENT = 'banishment'
module.exports.SPELL_BARKSKIN = 'barkskin'
module.exports.SPELL_BEACON_OF_HOPE = 'beacon_of_hope'
module.exports.SPELL_BEAST_BOND = 'beast_bond'
module.exports.SPELL_BEAST_SENSE = 'beast_sense'
module.exports.SPELL_BESTOW_CURSE = 'bestow_curse'
module.exports.SPELL_BIGBY_S_HAND = 'bigby_s_hand'
module.exports.SPELL_BLADE_BARRIER = 'blade_barrier'
module.exports.SPELL_BLADE_OF_DISASTER = 'blade_of_disaster'
module.exports.SPELL_BLADE_WARD = 'blade_ward'
module.exports.SPELL_BLESS = 'bless'
module.exports.SPELL_BLIGHT = 'blight'
module.exports.SPELL_BLINDING_SMITE = 'blinding_smite'
module.exports.SPELL_BLINDNESS_DEAFNESS = 'blindness_deafness'
module.exports.SPELL_BLINK = 'blink'
module.exports.SPELL_BLUR = 'blur'
module.exports.SPELL_BONES_OF_THE_EARTH = 'bones_of_the_earth'
module.exports.SPELL_BOOMING_BLADE = 'booming_blade'
module.exports.SPELL_BOOMING_BLADE_SCAG = 'booming_blade_scag'
module.exports.SPELL_BRANDING_SMITE = 'branding_smite'
module.exports.SPELL_BURNING_HANDS = 'burning_hands'
module.exports.SPELL_CALL_LIGHTNING = 'call_lightning'
module.exports.SPELL_CALM_EMOTIONS = 'calm_emotions'
module.exports.SPELL_CATAPULT = 'catapult'
module.exports.SPELL_CATNAP = 'catnap'
module.exports.SPELL_CAUSE_FEAR = 'cause_fear'
module.exports.SPELL_CEREMONY = 'ceremony'
module.exports.SPELL_CHAIN_LIGHTNING = 'chain_lightning'
module.exports.SPELL_CHAOS_BOLT = 'chaos_bolt'
module.exports.SPELL_CHARM_MONSTER = 'charm_monster'
module.exports.SPELL_CHARM_PERSON = 'charm_person'
module.exports.SPELL_CHILL_TOUCH = 'chill_touch'
module.exports.SPELL_CHROMATIC_ORB = 'chromatic_orb'
module.exports.SPELL_CIRCLE_OF_DEATH = 'circle_of_death'
module.exports.SPELL_CIRCLE_OF_POWER = 'circle_of_power'
module.exports.SPELL_CLAIRVOYANCE = 'clairvoyance'
module.exports.SPELL_CLONE = 'clone'
module.exports.SPELL_CLOUD_OF_DAGGERS = 'cloud_of_daggers'
module.exports.SPELL_CLOUDKILL = 'cloudkill'
module.exports.SPELL_COLOR_SPRAY = 'color_spray'
module.exports.SPELL_COMMAND = 'command'
module.exports.SPELL_COMMUNE = 'commune'
module.exports.SPELL_COMMUNE_WITH_NATURE = 'commune_with_nature'
module.exports.SPELL_COMPELLED_DUEL = 'compelled_duel'
module.exports.SPELL_COMPREHEND_LANGUAGES = 'comprehend_languages'
module.exports.SPELL_COMPULSION = 'compulsion'
module.exports.SPELL_CONE_OF_COLD = 'cone_of_cold'
module.exports.SPELL_CONFUSION = 'confusion'
module.exports.SPELL_CONJURE_ANIMALS = 'conjure_animals'
module.exports.SPELL_CONJURE_BARRAGE = 'conjure_barrage'
module.exports.SPELL_CONJURE_CELESTIAL = 'conjure_celestial'
module.exports.SPELL_CONJURE_ELEMENTAL = 'conjure_elemental'
module.exports.SPELL_CONJURE_FEY = 'conjure_fey'
module.exports.SPELL_CONJURE_MINOR_ELEMENTALS = 'conjure_minor_elementals'
module.exports.SPELL_CONJURE_VOLLEY = 'conjure_volley'
module.exports.SPELL_CONJURE_WOODLAND_BEINGS = 'conjure_woodland_beings'
module.exports.SPELL_CONTACT_OTHER_PLANE = 'contact_other_plane'
module.exports.SPELL_CONTAGION = 'contagion'
module.exports.SPELL_CONTINGENCY = 'contingency'
module.exports.SPELL_CONTINUAL_FLAME = 'continual_flame'
module.exports.SPELL_CONTROL_FLAMES = 'control_flames'
module.exports.SPELL_CONTROL_WATER = 'control_water'
module.exports.SPELL_CONTROL_WEATHER = 'control_weather'
module.exports.SPELL_CONTROL_WINDS = 'control_winds'
module.exports.SPELL_CORDON_OF_ARROWS = 'cordon_of_arrows'
module.exports.SPELL_COUNTERSPELL = 'counterspell'
module.exports.SPELL_CREATE_BONFIRE = 'create_bonfire'
module.exports.SPELL_CREATE_FOOD_AND_WATER = 'create_food_and_water'
module.exports.SPELL_CREATE_HOMUNCULUS = 'create_homunculus'
module.exports.SPELL_CREATE_MAGEN = 'create_magen'
module.exports.SPELL_CREATE_OR_DESTROY_WATER = 'create_or_destroy_water'
module.exports.SPELL_CREATE_UNDEAD = 'create_undead'
module.exports.SPELL_CREATION = 'creation'
module.exports.SPELL_CROWN_OF_MADNESS = 'crown_of_madness'
module.exports.SPELL_CROWN_OF_STARS = 'crown_of_stars'
module.exports.SPELL_CRUSADER_S_MANTLE = 'crusader_s_mantle'
module.exports.SPELL_CURE_WOUNDS = 'cure_wounds'
module.exports.SPELL_DANCING_LIGHTS = 'dancing_lights'
module.exports.SPELL_DANSE_MACABRE = 'danse_macabre'
module.exports.SPELL_DARK_STAR = 'dark_star'
module.exports.SPELL_DARKNESS = 'darkness'
module.exports.SPELL_DARKVISION = 'darkvision'
module.exports.SPELL_DAWN = 'dawn'
module.exports.SPELL_DAYLIGHT = 'daylight'
module.exports.SPELL_DEATH_WARD = 'death_ward'
module.exports.SPELL_DELAYED_BLAST_FIREBALL = 'delayed_blast_fireball'
module.exports.SPELL_DEMIPLANE = 'demiplane'
module.exports.SPELL_DESTRUCTIVE_WAVE = 'destructive_wave'
module.exports.SPELL_DETECT_EVIL_AND_GOOD = 'detect_evil_and_good'
module.exports.SPELL_DETECT_MAGIC = 'detect_magic'
module.exports.SPELL_DETECT_POISON_AND_DISEASE = 'detect_poison_and_disease'
module.exports.SPELL_DETECT_THOUGHTS = 'detect_thoughts'
module.exports.SPELL_DIMENSION_DOOR = 'dimension_door'
module.exports.SPELL_DISGUISE_SELF = 'disguise_self'
module.exports.SPELL_DISINTEGRATE = 'disintegrate'
module.exports.SPELL_DISPEL_EVIL_AND_GOOD = 'dispel_evil_and_good'
module.exports.SPELL_DISPEL_MAGIC = 'dispel_magic'
module.exports.SPELL_DISSONANT_WHISPERS = 'dissonant_whisper'
module.exports.SPELL_DISTORT_VALUE = 'distort_value'
module.exports.SPELL_DIVINATION = 'divination'
module.exports.SPELL_DIVINE_FAVOR = 'divine_favor'
module.exports.SPELL_DIVINE_WORD = 'divine_word'
module.exports.SPELL_DOMINATE_BEAST = 'dominate_beast'
module.exports.SPELL_DOMINATE_MONSTER = 'dominate_monster'
module.exports.SPELL_DOMINATE_PERSON = 'dominate_person'
module.exports.SPELL_DRACONIC_TRANSFORMATION = 'draconic_transformation'
module.exports.SPELL_DRACONIC_TRANSFORMATION_2021_04_14 = 'draconic_transformation_2021_04_14'
module.exports.SPELL_DRAGON_S_BREATH = 'dragon_s_breath'
module.exports.SPELL_DRAWMIJ_S_INSTANT_SUMMONS = 'drawmij_s_instant_summons'
module.exports.SPELL_DREAM = 'dream'
module.exports.SPELL_DREAM_OF_THE_BLUE_VEIL = 'dream_of_the_blue_veil'
module.exports.SPELL_DRUID_GROVE = 'druid_grove'
module.exports.SPELL_DRUIDCRAFT = 'druidcraft'
module.exports.SPELL_DUST_DEVIL = 'dust_devil'
module.exports.SPELL_EARTH_TREMOR = 'earth_tremor'
module.exports.SPELL_EARTHBIND = 'earthbind'
module.exports.SPELL_EARTHQUAKE = 'earthquake'
module.exports.SPELL_EGO_WHIP_2019_11_25 = 'ego_whip_2019_11_25'
module.exports.SPELL_ELDRITCH_BLAST = 'eldritch_blast'
module.exports.SPELL_ELEMENTAL_BANE = 'elemental_bane'
module.exports.SPELL_ELEMENTAL_WEAPON = 'elemental_weapon'
module.exports.SPELL_ENCODE_THOUGHTS = 'encode_thoughts'
module.exports.SPELL_ENEMIES_ABOUND = 'enemies_abound'
module.exports.SPELL_ENERVATION = 'enervation'
module.exports.SPELL_ENHANCE_ABILITY = 'enhance_ability'
module.exports.SPELL_ENLARGE_REDUCE = 'enlarge_reduce'
module.exports.SPELL_ENSNARING_STRIKE = 'ensnaring_strike'
module.exports.SPELL_ENTANGLE = 'entangle'
module.exports.SPELL_ENTHRALL = 'enthrall'
module.exports.SPELL_ERUPTING_EARTH = 'erupting_earth'
module.exports.SPELL_ETHEREALNESS = 'etherealness'
module.exports.SPELL_EVARD_S_BLACK_TENTACLES = 'evard_s_black_tentacles'
module.exports.SPELL_EXPEDITIOUS_RETREAT = 'expeditious_retreat'
module.exports.SPELL_EYEBITE = 'eyebite'
module.exports.SPELL_FABRICATE = 'fabricate'
module.exports.SPELL_FAERIE_FIRE = 'faerie_fire'
module.exports.SPELL_FALSE_LIFE = 'false_life'
module.exports.SPELL_FAR_STEP = 'far_step'
module.exports.SPELL_FAST_FRIENDS = 'fast_friends'
module.exports.SPELL_FEAR = 'fear'
module.exports.SPELL_FEATHER_FALL = 'feather_fall'
module.exports.SPELL_FEEBLEMIND = 'feeblemind'
module.exports.SPELL_FEIGN_DEATH = 'feign_death'
module.exports.SPELL_FIND_FAMILIAR = 'find_familiar'
module.exports.SPELL_FIND_GREATER_STEED = 'find_greater_steed'
module.exports.SPELL_FIND_STEED = 'find_steed'
module.exports.SPELL_FIND_THE_PATH = 'find_the_path'
module.exports.SPELL_FIND_TRAPS = 'find_traps'
module.exports.SPELL_FINGER_OF_DEATH = 'finger_of_death'
module.exports.SPELL_FIRE_BOLT = 'fire_bolt'
module.exports.SPELL_FIRE_SHIELD = 'fire_shield'
module.exports.SPELL_FIRE_STORM = 'fire_storm'
module.exports.SPELL_FIREBALL = 'fireball'
module.exports.SPELL_FIZBAN_S_PLATINUM_SHIELD = 'fizban_s_platinum_shield'
module.exports.SPELL_FIZBAN_S_PLATINUM_SHIELD_2021_04_14 = 'fizban_s_platinum_shield_2021_04_14'
module.exports.SPELL_FLAME_ARROWS = 'flame_arrows'
module.exports.SPELL_FLAME_BLADE = 'flame_blade'
module.exports.SPELL_FLAME_STRIDE_2021_04_14 = 'flame_stride_2021_04_14'
module.exports.SPELL_FLAME_STRIKE = 'flame_strike'
module.exports.SPELL_FLAMING_SPHERE = 'flaming_sphere'
module.exports.SPELL_FLESH_TO_STONE = 'flesh_to_stone'
module.exports.SPELL_FLY = 'fly'
module.exports.SPELL_FOG_CLOUD = 'fog_cloud'
module.exports.SPELL_FORBIDDANCE = 'forbiddance'
module.exports.SPELL_FORCECAGE = 'forcecage'
module.exports.SPELL_FORESIGHT = 'foresight'
module.exports.SPELL_FORTUNE_S_FAVOR = 'fortune_s_favor'
module.exports.SPELL_FREEDOM_OF_MOVEMENT = 'freedom_of_movement'
module.exports.SPELL_FRIENDS = 'friends'
module.exports.SPELL_FROST_FINGERS = 'frost_fingers'
module.exports.SPELL_FROSTBITE = 'frostbite'
module.exports.SPELL_GASEOUS_FORM = 'gaseous_form'
module.exports.SPELL_GATE = 'gate'
module.exports.SPELL_GEAS = 'geas'
module.exports.SPELL_GENTLE_REPOSE = 'gentle_repose'
module.exports.SPELL_GIANT_INSECT = 'giant_insect'
module.exports.SPELL_GIFT_OF_ALACRITY = 'gift_of_alacrity'
module.exports.SPELL_GIFT_OF_GAB = 'gift_of_gab'
module.exports.SPELL_GLIBNESS = 'glibness'
module.exports.SPELL_GLOBE_OF_INVULNERABILITY = 'globe_of_invulnerability'
module.exports.SPELL_GLYPH_OF_WARDING = 'glyph_of_warding'
module.exports.SPELL_GOODBERRY = 'goodberry'
module.exports.SPELL_GRASPING_VINE = 'grasping_vine'
module.exports.SPELL_GRAVITY_FISSURE = 'gravity_fissure'
module.exports.SPELL_GRAVITY_SINKHOLE = 'gravity_sinkhole'
module.exports.SPELL_GREASE = 'grease'
module.exports.SPELL_GREATER_INVISIBILITY = 'greater_invisibility'
module.exports.SPELL_GREATER_RESTORATION = 'greater_restoration'
module.exports.SPELL_GREEN_FLAME_BLADE = 'green_flame_blade'
module.exports.SPELL_GREEN_FLAME_BLADE_SCAG = 'spell_green_flame_blade_scag'
module.exports.SPELL_GUARDIAN_OF_FAITH = 'guardian_of_faith'
module.exports.SPELL_GUARDIAN_OF_NATURE = 'guardian_of_nature'
module.exports.SPELL_GUARDS_AND_WARDS = 'guards_and_wards'
module.exports.SPELL_GUIDANCE = 'guidance'
module.exports.SPELL_GUIDING_BOLT = 'guiding_bolt'
module.exports.SPELL_GUIDING_HAND_2017_04_03 = 'guiding_hand_2017_04_03'
module.exports.SPELL_GUST = 'gust'
module.exports.SPELL_GUST_OF_WIND = 'gust_of_wind'
module.exports.SPELL_HAIL_OF_THORNS = 'hail_of_thorns'
module.exports.SPELL_HALLOW = 'hallow'
module.exports.SPELL_HALLUCINATORY_TERRAIN = 'hallucinatory_terrain'
module.exports.SPELL_HARM = 'harm'
module.exports.SPELL_HASTE = 'haste'
module.exports.SPELL_HEAL = 'heal'
module.exports.SPELL_HEALING_ELIXIR_2017_04_03 = 'healing_elixir_2017_04_03'
module.exports.SPELL_HEALING_SPIRIT = 'healing_spirit'
module.exports.SPELL_HEALING_WORD = 'healing_word'
module.exports.SPELL_HEAT_METAL = 'heat_metal'
module.exports.SPELL_HELLISH_REBUKE = 'hellish_rebuke'
module.exports.SPELL_HEROES_FEAST = 'heroes_feast'
module.exports.SPELL_HEROISM = 'heroism'
module.exports.SPELL_HEX = 'hex'
module.exports.SPELL_HOLD_MONSTER = 'hold_monster'
module.exports.SPELL_HOLD_PERSON = 'hold_person'
module.exports.SPELL_HOLY_AURA = 'holy_aura'
module.exports.SPELL_HOLY_WEAPON = 'holy_weapon'
module.exports.SPELL_HUNGER_OF_HADAR = 'hunger_of_hadar'
module.exports.SPELL_HUNTERS_MARK = 'hunter_s_mark'
module.exports.SPELL_HYPNOTIC_PATTERN = 'hypnotic_pattern'
module.exports.SPELL_ICE_KNIFE = 'ice_knife'
module.exports.SPELL_ICE_STORM = 'ice_storm'
module.exports.SPELL_ICINGDEATH_S_FROST_2021_04_14 = 'icingdeath_s_frost_2021_04_14'
module.exports.SPELL_ID_INSINUATION_2019_11_25 = 'id_insinuation_2019_11_25'
module.exports.SPELL_IDENTIFY = 'identify'
module.exports.SPELL_ILLUSORY_DRAGON = 'illusory_dragon'
module.exports.SPELL_ILLUSORY_SCRIPT = 'illusory_script'
module.exports.SPELL_IMMOLATION = 'immolation'
module.exports.SPELL_IMMOVABLE_OBJECT = 'immovable_object'
module.exports.SPELL_IMPRISONMENT = 'imprisonment'
module.exports.SPELL_INCENDIARY_CLOUD = 'incendiary_cloud'
module.exports.SPELL_INCITE_GREED = 'incite_greed'
module.exports.SPELL_INFERNAL_CALLING = 'infernal_calling'
module.exports.SPELL_INFESTATION = 'infestation'
module.exports.SPELL_INFLICT_WOUNDS = 'inflict_wounds'
module.exports.SPELL_INSECT_PLAGUE = 'insect_plague'
module.exports.SPELL_INTELLECT_FORTRESS = 'intellect_fortress'
module.exports.SPELL_INTELLECT_FORTRESS_2019_11_25 = 'intellect_fortress_2019_11_25'
module.exports.SPELL_INTELLECT_FORTRESS_2020_04_14 = 'intellect_fortress_2020_04_14'
module.exports.SPELL_INVESTITURE_OF_FLAME = 'investiture_of_flame'
module.exports.SPELL_INVESTITURE_OF_ICE = 'investiture_of_ice'
module.exports.SPELL_INVESTITURE_OF_STONE = 'investiture_of_stone'
module.exports.SPELL_INVESTITURE_OF_WIND = 'investiture_of_wind'
module.exports.SPELL_INVISIBILITY = 'invisibility'
module.exports.SPELL_INVULNERABILITY = 'invulnerability'
module.exports.SPELL_JIM_S_GLOWING_COIN = 'jim_s_glowing_coin'
module.exports.SPELL_JIM_S_MAGIC_MISSILE = 'jim_s_magic_missile'
module.exports.SPELL_JUMP = 'jump'
module.exports.SPELL_KNOCK = 'knock'
module.exports.SPELL_LEGEND_LORE = 'legend_lore'
module.exports.SPELL_LEOMUND_S_SECRET_CHEST = 'leomund_s_secret_chest'
module.exports.SPELL_LEOMUND_S_TINY_HUT = 'leomund_s_tiny_hut'
module.exports.SPELL_LESSER_RESTORATION = 'lesser_restoration'
module.exports.SPELL_LEVITATE = 'levitate'
module.exports.SPELL_LIFE_TRANSFERENCE = 'life_transference'
module.exports.SPELL_LIGHT = 'light'
module.exports.SPELL_LIGHTNING_ARROW = 'lightning_arrow'
module.exports.SPELL_LIGHTNING_BOLT = 'lightning_bolt'
module.exports.SPELL_LIGHTNING_LURE = 'lightning_lure'
module.exports.SPELL_LOCATE_ANIMALS_OR_PLANTS = 'locate_animals_or_plants'
module.exports.SPELL_LOCATE_CREATURE = 'locate_creature'
module.exports.SPELL_LOCATE_OBJECT = 'locate_object'
module.exports.SPELL_LONGSTRIDER = 'longstrider'
module.exports.SPELL_MADDENING_DARKNESS = 'maddening_darkness'
module.exports.SPELL_MAELSTROM = 'maelstrom'
module.exports.SPELL_MAGE_ARMOR = 'mage_armor'
module.exports.SPELL_MAGE_HAND = 'mage_hand'
module.exports.SPELL_MAGIC_CIRCLE = 'magic_circle'
module.exports.SPELL_MAGIC_JAR = 'magic_jar'
module.exports.SPELL_MAGIC_MISSILE = 'magic_missile'
module.exports.SPELL_MAGIC_MOUTH = 'magic_mouth'
module.exports.SPELL_MAGIC_STONE = 'magic_stone'
module.exports.SPELL_MAGIC_WEAPON = 'magic_weapon'
module.exports.SPELL_MAGNIFY_GRAVITY = 'magnify_gravity'
module.exports.SPELL_MAJOR_IMAGE = 'major_image'
module.exports.SPELL_MASS_CURE_WOUNDS = 'mass_cure_wounds'
module.exports.SPELL_MASS_HEAL = 'mass_heal'
module.exports.SPELL_MASS_HEALING_WORD = 'mass_healing_word'
module.exports.SPELL_MASS_POLYMORPH = 'mass_polymorph'
module.exports.SPELL_MASS_SUGGESTION = 'mass_suggestion'
module.exports.SPELL_MAXIMILIAN_S_EARTHEN_GRASP = 'maximilian_s_earthen_grasp'
module.exports.SPELL_MAZE = 'maze'
module.exports.SPELL_MELD_INTO_STONE = 'meld_into_stone'
module.exports.SPELL_MELF_S_ACID_ARROW = 'melf_s_acid_arrow'
module.exports.SPELL_MELF_S_MINUTE_METEORS = 'melf_s_minute_meteors'
module.exports.SPELL_MENDING = 'mending'
module.exports.SPELL_MENTAL_BARRIER_2019_11_25 = 'mental_barrier_2019_11_25'
module.exports.SPELL_MENTAL_PRISON = 'mental_prison'
module.exports.SPELL_MESSAGE = 'message'
module.exports.SPELL_METEOR_SWARM = 'meteor_swarm'
module.exports.SPELL_MIGHTY_FORTRESS = 'mighty_fortress'
module.exports.SPELL_MIND_BLANK = 'mind_blank'
module.exports.SPELL_MIND_SLIVER = 'mind_sliver'
module.exports.SPELL_MIND_SPIKE = 'mind_spike'
module.exports.SPELL_MIND_THRUST_2019_11_25 = 'mind_thrust_2019_11_25'
module.exports.SPELL_MIND_THRUST_2020_04_14 = 'mind_thrust_2020_04_14'
module.exports.SPELL_MINOR_ILLUSION = 'minor_illusion'
module.exports.SPELL_MIRAGE_ARCANE = 'mirage_arcane'
module.exports.SPELL_MIRROR_IMAGE = 'mirror_image'
module.exports.SPELL_MISLEAD = 'mislead'
module.exports.SPELL_MISTY_STEP = 'misty_step'
module.exports.SPELL_MODIFY_MEMORY = 'modify_memory'
module.exports.SPELL_MOLD_EARTH = 'mold_earth'
module.exports.SPELL_MOONBEAM = 'moonbeam'
module.exports.SPELL_MORDENKAINEN_S_FAITHFUL_HOUND = 'mordenkainen_s_faithful_hound'
module.exports.SPELL_MORDENKAINEN_S_MAGNIFICENT_MANSION = 'mordenkainen_s_magnificent_mansion'
module.exports.SPELL_MORDENKAINEN_S_PRIVATE_SANCTUM = 'mordenkainen_s_private_sanctum'
module.exports.SPELL_MORDENKAINEN_S_SWORD = 'mordenkainen_s_sword'
module.exports.SPELL_MOTIVATIONAL_SPEECH = 'motivational_speech'
module.exports.SPELL_MOVE_EARTH = 'move_earth'
module.exports.SPELL_NATHAIR_S_MISCHIEF = 'nathair_s_mischief'
module.exports.SPELL_NATHAIR_S_MISCHIEF_2021_04_14 = 'nathair_s_mischief_2021_04_14'
module.exports.SPELL_NEGATIVE_ENERGY_FLOOD = 'negative_energy_flood'
module.exports.SPELL_NONDETECTION = 'nondetection'
module.exports.SPELL_NYSTUL_S_MAGIC_AURA = 'nystul_s_magic_aura'
module.exports.SPELL_OTHERWORLDLY_FORM_2020_03_26 = 'otherworldly_form_2020_03_26'
module.exports.SPELL_OTILUKE_S_FREEZING_SPHERE = 'otiluke_s_freezing_sphere'
module.exports.SPELL_OTILUKE_S_RESILIENT_SPHERE = 'otiluke_s_resilient_sphere'
module.exports.SPELL_OTTO_S_IRRESISTIBLE_DANCE = 'otto_s_irresistible_dance'
module.exports.SPELL_PASS_WITHOUT_TRACE = 'pass_without_trace'
module.exports.SPELL_PASSWALL = 'passwall'
module.exports.SPELL_PHANTASMAL_FORCE = 'phantasmal_force'
module.exports.SPELL_PHANTASMAL_KILLER = 'phantasmal_killer'
module.exports.SPELL_PHANTOM_STEED = 'phantom_steed'
module.exports.SPELL_PLANAR_ALLY = 'planar_ally'
module.exports.SPELL_PLANAR_BINDING = 'planar_binding'
module.exports.SPELL_PLANE_SHIFT = 'plane_shift'
module.exports.SPELL_PLANT_GROWTH = 'plant_growth'
module.exports.SPELL_POISON_SPRAY = 'poison_spray'
module.exports.SPELL_POLYMORPH = 'polymorph'
module.exports.SPELL_POWER_WORD_HEAL = 'power_word_heal'
module.exports.SPELL_POWER_WORD_KILL = 'power_word_kill'
module.exports.SPELL_POWER_WORD_PAIN = 'power_word_pain'
module.exports.SPELL_POWER_WORD_STUN = 'power_word_stun'
module.exports.SPELL_PRAYER_OF_HEALING = 'prayer_of_healing'
module.exports.SPELL_PRESTIDIGITATION = 'prestidigitation'
module.exports.SPELL_PRIMAL_SAVAGERY = 'primal_savagery'
module.exports.SPELL_PRIMORDIAL_WARD = 'primordial_ward'
module.exports.SPELL_PRISMATIC_SPRAY = 'prismatic_spray'
module.exports.SPELL_PRISMATIC_WALL = 'prismatic_wall'
module.exports.SPELL_PRODUCE_FLAME = 'produce_flame'
module.exports.SPELL_PROGRAMMED_ILLUSION = 'programmed_illusion'
module.exports.SPELL_PROJECT_IMAGE = 'project_image'
module.exports.SPELL_PROTECTION_FROM_ENERGY = 'protection_from_energy'
module.exports.SPELL_PROTECTION_FROM_EVIL_AND_GOOD = 'protection_from_evil_and_good'
module.exports.SPELL_PROTECTION_FROM_POISON = 'protection_from_poison'
module.exports.SPELL_PSIONIC_BLAST_2019_11_25 = 'psionic_blast_2019_11_25'
module.exports.SPELL_PSYCHIC_CRUSH_2019_11_25 = 'psychic_crush_2019_11_25'
module.exports.SPELL_PSYCHIC_SCREAM = 'psychic_scream'
module.exports.SPELL_PULSE_WAVE = 'pulse_wave'
module.exports.SPELL_PUPPET_2017_04_03 = 'puppet_2017_04_03'
module.exports.SPELL_PURIFY_FOOD_AND_DRINK = 'purify_food_and_drink'
module.exports.SPELL_PYROTECHNICS = 'pyrotechnics'
module.exports.SPELL_RAISE_DEAD = 'raise_dead'
module.exports.SPELL_RARY_S_TELEPATHIC_BOND = 'rary_s_telepathic_bond'
module.exports.SPELL_RAULOTHIM_S_PSYCHIC_LANCE = 'raulothim_s_psychic_lance'
module.exports.SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14 = 'raulothim_s_psychic_lance_2021_04_14'
module.exports.SPELL_RAVENOUS_VOID = 'ravenous_void'
module.exports.SPELL_RAY_OF_ENFEEBLEMENT = 'ray_of_enfeeblement'
module.exports.SPELL_RAY_OF_FROST = 'ray_of_frost'
module.exports.SPELL_RAY_OF_SICKNESS = 'ray_of_sickness'
module.exports.SPELL_REALITY_BREAK = 'reality_break'
module.exports.SPELL_REGENERATE = 'regenerate'
module.exports.SPELL_REINCARNATE = 'reincarnate'
module.exports.SPELL_REMOVE_CURSE = 'remove_curse'
module.exports.SPELL_RESISTANCE = 'resistance'
module.exports.SPELL_RESURRECTION = 'resurrection'
module.exports.SPELL_REVERSE_GRAVITY = 'reverse_gravity'
module.exports.SPELL_REVIVIFY = 'revivify'
module.exports.SPELL_RIME_S_BINDING_ICE = 'rime_s_binding_ice'
module.exports.SPELL_ROPE_TRICK = 'rope_trick'
module.exports.SPELL_SACRED_FLAME = 'sacred_flame'
module.exports.SPELL_SANCTUARY = 'sanctuary'
module.exports.SPELL_SAPPING_STING = 'sapping_sting'
module.exports.SPELL_SCATTER = 'scatter'
module.exports.SPELL_SCORCHING_RAY = 'scorching_ray'
module.exports.SPELL_SCRYING = 'scrying'
module.exports.SPELL_SEARING_SMITE = 'searing_smite'
module.exports.SPELL_SEE_INVISIBILITY = 'see_invisibility'
module.exports.SPELL_SEEMING = 'seeming'
module.exports.SPELL_SENDING = 'sending'
module.exports.SPELL_SENSE_EMOTION_2017_04_03 = 'sense_emotion_2017_04_03'
module.exports.SPELL_SEQUESTER = 'sequester'
module.exports.SPELL_SHADOW_BLADE = 'shadow_blade'
module.exports.SPELL_SHADOW_OF_MOIL = 'shadow_of_moil'
module.exports.SPELL_SHAPE_WATER = 'shape_water'
module.exports.SPELL_SHAPECHANGE = 'shapechange'
module.exports.SPELL_SHATTER = 'shatter'
module.exports.SPELL_SHIELD = 'shield'
module.exports.SPELL_SHIELD_OF_FAITH = 'shield_of_faith'
module.exports.SPELL_SHILLELAGH = 'shillelagh'
module.exports.SPELL_SHOCKING_GRASP = 'shocking_grasp'
module.exports.SPELL_SICKENING_RADIANCE = 'sickening_radiance'
module.exports.SPELL_SILENCE = 'silence'
module.exports.SPELL_SILENT_IMAGE = 'silent_image'
module.exports.SPELL_SIMULACRUM = 'simulacrum'
module.exports.SPELL_SKILL_EMPOWERMENT = 'skill_empowerment'
module.exports.SPELL_SKYWRITE = 'skywrite'
module.exports.SPELL_SLEEP = 'sleep'
module.exports.SPELL_SLEET_STORM = 'sleet_storm'
module.exports.SPELL_SLOW = 'slow'
module.exports.SPELL_SNARE = 'snare'
module.exports.SPELL_SNILLOC_S_SNOWBALL_SWARM = 'snilloc_s_snowball_swarm'
module.exports.SPELL_SOUL_CAGE = 'soul_cage'
module.exports.SPELL_SPARE_THE_DYING = 'spare_the_dying'
module.exports.SPELL_SPEAK_WITH_ANIMALS = 'speak_with_animals'
module.exports.SPELL_SPEAK_WITH_DEAD = 'speak_with_dead'
module.exports.SPELL_SPEAK_WITH_PLANTS = 'speak_with_plants'
module.exports.SPELL_SPIDER_CLIMB = 'spider_climb'
module.exports.SPELL_SPIKE_GROWTH = 'spike_growth'
module.exports.SPELL_SPIRIT_GUARDIANS = 'spirit_guardians'
module.exports.SPELL_SPIRIT_SHROUD = 'spirit_shroud'
module.exports.SPELL_SPIRIT_SHROUD_2020_03_26 = 'spirit_shroud_2020_03_26'
module.exports.SPELL_SPIRITUAL_WEAPON = 'spiritual_weapon'
module.exports.SPELL_STAGGERING_SMITE = 'staggering_smite'
module.exports.SPELL_STEEL_WIND_STRIKE = 'steel_wind_strike'
module.exports.SPELL_STINKING_CLOUD = 'stinking_cloud'
module.exports.SPELL_STONE_SHAPE = 'stone_shape'
module.exports.SPELL_STONESKIN = 'stoneskin'
module.exports.SPELL_STORM_OF_VENGEANCE = 'storm_of_vengeance'
module.exports.SPELL_STORM_SPHERE = 'storm_sphere'
module.exports.SPELL_SUDDEN_AWAKENING_2017_04_03 = 'sudden_awakening_2017_04_03'
module.exports.SPELL_SUGGESTION = 'suggestion'
module.exports.SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26 = 'summon_aberrant_spirit_2020_03_26'
module.exports.SPELL_SUMMON_ABERRATION = 'summon_aberration'
module.exports.SPELL_SUMMON_BEAST = 'summon_beast'
module.exports.SPELL_SUMMON_BESTIAL_SPIRIT_2020_03_26 = 'summon_bestial_spirit_2020_03_26'
module.exports.SPELL_SUMMON_CELESTIAL = 'summon_celestial'
module.exports.SPELL_SUMMON_CELESTIAL_SPIRIT_2020_03_26 = 'summon_celestial_spirit_2020_03_26'
module.exports.SPELL_SUMMON_CONSTRUCT = 'summon_construct'
module.exports.SPELL_SUMMON_DRACONIC_SPIRIT = 'summon_draconic_spirit'
module.exports.SPELL_SUMMON_DRACONIC_SPIRIT_2021_04_14 = 'summon_draconic_spirit_2021_04_14'
module.exports.SPELL_SUMMON_ELEMENTAL = 'summon_elemental'
module.exports.SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26 = 'summon_elemental_spirit_2020_03_26'
module.exports.SPELL_SUMMON_FEY = 'summon_fey'
module.exports.SPELL_SUMMON_FEY_SPIRIT_2020_03_26 = 'summon_fey_spirit_2020_03_26'
module.exports.SPELL_SUMMON_FIEND = 'summon_fiend'
module.exports.SPELL_SUMMON_FIENDISH_SPIRIT_2020_03_26 = 'summon_fiendish_spirit_2020_03_26'
module.exports.SPELL_SUMMON_GREATER_DEMON = 'summon_greater_demon'
module.exports.SPELL_SUMMON_LESSER_DEMONS = 'summon_lesser_demons'
module.exports.SPELL_SUMMON_SHADOW_SPIRIT_2020_03_26 = 'summon_shadow_spirit_2020_03_26'
module.exports.SPELL_SUMMON_SHADOWSPAWN = 'summon_shadowspawn'
module.exports.SPELL_SUMMON_UNDEAD = 'summon_undead'
module.exports.SPELL_SUMMON_UNDEAD_SPIRIT_2020_03_26 = 'summon_undead_spirit_2020_03_26'
module.exports.SPELL_SUNBEAM = 'sunbeam'
module.exports.SPELL_SUNBURST = 'sunburst'
module.exports.SPELL_SWIFT_QUIVER = 'swift_quiver'
module.exports.SPELL_SWORD_BURST = 'sword_burst'
module.exports.SPELL_SYMBOL = 'symbol'
module.exports.SPELL_SYNAPTIC_STATIC = 'synaptic_static'
module.exports.SPELL_TASHA_S_CAUSTIC_BREW = 'tasha_s_caustic_brew'
module.exports.SPELL_TASHA_S_HIDEOUS_LAUGHTER = 'tasha_s_hideous_laughter'
module.exports.SPELL_TASHA_S_MIND_WHIP = 'tasha_s_mind_whip'
module.exports.SPELL_TASHA_S_OTHERWORLDLY_GUISE = 'tasha_s_otherworldly_guise'
module.exports.SPELL_TELEKINESIS = 'telekinesis'
module.exports.SPELL_TELEPATHY = 'telepathy'
module.exports.SPELL_TELEPORT = 'teleport'
module.exports.SPELL_TELEPORTATION_CIRCLE = 'teleportation_circle'
module.exports.SPELL_TEMPLE_OF_THE_GODS = 'temple_of_the_gods'
module.exports.SPELL_TEMPORAL_SHUNT = 'temporal_shunt'
module.exports.SPELL_TENSER_S_FLOATING_DISK = 'tenser_s_floating_disk'
module.exports.SPELL_TENSER_S_TRANSFORMATION = 'tenser_s_transformation'
module.exports.SPELL_TETHER_ESSENCE = 'tether_essence'
module.exports.SPELL_THAUMATURGY = 'thaumaturgy'
module.exports.SPELL_THORN_WHIP = 'thorn_whip'
module.exports.SPELL_THOUGHT_SHIELD_2019_11_25 = 'thought_shield_2019_11_25'
module.exports.SPELL_THUNDER_STEP = 'thunder_step'
module.exports.SPELL_THUNDERCLAP = 'thunderclap'
module.exports.SPELL_THUNDEROUS_SMITE = 'thunderous_smite'
module.exports.SPELL_THUNDERWAVE = 'thunderwave'
module.exports.SPELL_TIDAL_WAVE = 'tidal_wave'
module.exports.SPELL_TIME_RAVAGE = 'time_ravage'
module.exports.SPELL_TIME_STOP = 'time_stop'
module.exports.SPELL_TINY_SERVANT = 'tiny_servant'
module.exports.SPELL_TOLL_THE_DEAD = 'toll_the_dead'
module.exports.SPELL_TONGUES = 'tongues'
module.exports.SPELL_TRANSMUTE_ROCK = 'transmute_rock'
module.exports.SPELL_TRANSPORT_VIA_PLANTS = 'transport_via_plants'
module.exports.SPELL_TREE_STRIDE = 'tree_stride'
module.exports.SPELL_TRUE_POLYMORPH = 'true_polymorph'
module.exports.SPELL_TRUE_RESURRECTION = 'true_resurrection'
module.exports.SPELL_TRUE_SEEING = 'true_seeing'
module.exports.SPELL_TRUE_STRIKE = 'true_strike'
module.exports.SPELL_TSUNAMI = 'tsunami'
module.exports.SPELL_UNEARTHLY_CHORUS_2017_04_03 = 'unearthly_chorus_2017_04_03'
module.exports.SPELL_UNSEEN_SERVANT = 'unseen_servant'
module.exports.SPELL_VAMPIRIC_TOUCH = 'vampiric_touch'
module.exports.SPELL_VICIOUS_MOCKERY = 'vicious_mockery'
module.exports.SPELL_VIRTUE_2017_04_03 = 'virtue_2017_04_03'
module.exports.SPELL_VITRIOLIC_SPHERE = 'vitriolic_sphere'
module.exports.SPELL_WALL_OF_FIRE = 'wall_of_fire'
module.exports.SPELL_WALL_OF_FORCE = 'wall_of_force'
module.exports.SPELL_WALL_OF_ICE = 'wall_of_ice'
module.exports.SPELL_WALL_OF_LIGHT = 'wall_of_light'
module.exports.SPELL_WALL_OF_SAND = 'wall_of_sand'
module.exports.SPELL_WALL_OF_STONE = 'wall_of_stone'
module.exports.SPELL_WALL_OF_THORNS = 'wall_of_thorns'
module.exports.SPELL_WALL_OF_WATER = 'wall_of_water'
module.exports.SPELL_WARDING_BOND = 'warding_bond'
module.exports.SPELL_WARDING_WIND = 'warding_wind'
module.exports.SPELL_WATER_BREATHING = 'water_breathing'
module.exports.SPELL_WATER_WALK = 'water_walk'
module.exports.SPELL_WATERY_SPHERE = 'watery_sphere'
module.exports.SPELL_WEB = 'web'
module.exports.SPELL_WEIRD = 'weird'
module.exports.SPELL_WHIRLWIND = 'whirlwind'
module.exports.SPELL_WILD_CUNNING_2017_04_03 = 'wild_cunning_2017_04_03'
module.exports.SPELL_WIND_WALK = 'wind_walk'
module.exports.SPELL_WIND_WALL = 'wind_wall'
module.exports.SPELL_WISH = 'wish'
module.exports.SPELL_WITCH_BOLT = 'witch_bolt'
module.exports.SPELL_WORD_OF_RADIANCE = 'word_of_radiance'
module.exports.SPELL_WORD_OF_RECALL = 'word_of_recall'
module.exports.SPELL_WRATH_OF_NATURE = 'wrath_of_nature'
module.exports.SPELL_WRATHFUL_SMITE = 'wrathful_smite'
module.exports.SPELL_WRISTPOCKET = 'wristpocket'
module.exports.SPELL_ZEPHYR_STRIKE = 'zephyr_strike'
module.exports.SPELL_ZONE_OF_TRUTH = 'zone_of_truth'
