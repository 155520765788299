const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_UA_2017_04_03_STARTER_SPELLS,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {CONDITION_RESTRAINED} = require('./../../../../../conditionList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_SNARE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SNARE,
  name: 'Силок',
  nameEn: 'Snare',
  description: `Когда Вы сотворяете это заклинание, Вы используете веревку, чтобы создать круг с радиусом 5 футов на земле или на полу. По завершении этого процесса веревка исчезает, и круг становится волшебной ловушкой.

Эта ловушка почти невидима, и чтобы её заметить требуется совершить успешную проверку Интеллекта (Расследование) против СЛ Вашего заклинания.

Ловушка срабатывает, когда Маленькое, Среднее или Большое существо перемещается по земле или полу в зоне действия заклинания. Это существо должно пройти испытание Ловкости или будет волшебным образом поднято в воздух и останется висеть вверх ногами на высоте 3 фута над землёй или полом. Существо обездвижено на этом месте, пока заклинание не закончится.

Удерживаемое существо может проходить испытание Ловкости в конце каждого своего хода, оканчивая эффект на себе при успехе. В качестве альтернативы существо или кто-то другой, кто может дотянуться до него, может использовать действие, чтобы совершить проверку Интеллекта (Магия) против СЛ Вашего заклинания. При успехе удерживающий эффект оканчивается.

После срабатывания ловушки заклинание заканчивается, если никакое существо им больше не удерживается.`,
  lvl: 1,
  magicSchoolId: MAGIC_ABJURATION,
  range: 0,
  hasConsumable: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `25 футов веревки, расходуемой заклинанием`,
  duration: {timeId: TIME_HOUR, count: 8},
  castTime: {timeId: TIME_MINUTE, count: 1},
  effect: {
    savethrowParam: PARAM_DEX,
    condition: CONDITION_RESTRAINED,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 166,
    },
    {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 4,
    },
  ],
}
