const {
  LANG_ABYSSAL,
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_PLANE_SHIFT,
  SPELL_WEB,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_14} = require('./../../../../crList')
const {CREATURE_RETRIEVER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Ловчий',
  nameEn: 'Retriever',
  id: CREATURE_RETRIEVER,
  description: [
    `Ловчий — мощный, паукоподобный конструкт, задуманный и построенный дроу для проникновения в Бездну и захвата демонов, чтобы поработить их или использовать в своих ритуалах. Механизмы оказались настолько эффективными и страшными, что теперь они выполняют множество различных заданий.`,
    {
      header: 'Заключённый демон',
      text: `Хотя каждый ловчий является конструктом из металла и магии, в нём находится заключённый в тюрьму дух бебилита. Большая часть разума демона очищена, оставив в нём только жестокость и коварство.`,
      source: {
        id: SOURCE_MTOF,
        page: 205,
      },
    },
    {
      header: 'Смертельные собиратели',
      text: `Хоть они и были созданы для работы только в Бездне, ловчие иногда выполняют миссии могущественных дроу по поимке живьём и без повреждений, некоторых существ или объектов, нужных этим дроу. Только в очень редких случаях ловчий передаётся или продаётся существам, не являющимися дроу, поскольку дроу не желают рисковать тем, что конструкт может быть задействован против них самих.`,
      source: {
        id: SOURCE_MTOF,
        page: 205,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 205,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 20,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 4,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: [
        LANG_ABYSSAL,
        LANG_ELVEN,
        LANG_UNDERCOMMON,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_14,
  featureList: [
    {
      name: 'Безупречный следопыт',
      description: `Хозяин указывает ловчему добычу. Добычей может быть конкретное существ или объект, известное хозяину лично, или это может быть разновидность существа или объекта, который хозяин видел раньше. Ловчий знает направление и расстояние до своей добычи, пока они находятся на одном плане. У ловчего может быть только одна добыча за раз. Ловчий также всегда знает местоположение своего хозяина.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_WIT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_WEB,
          {
            id: SPELL_PLANE_SHIFT,
            comment: 'только на себя и на одно недееспособное существо, которое считается согласным на заклинание',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Ловчий совершает две атаки _Передними ножками_ и использует _Луч силового поля_ или _Парализующий луч_ если это возможно.`,
    },
    {
      name: 'Передняя ножка',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Луч силового поля',
      description: `Ловчий нацеливается на одно видимое им существо в пределах 60 футов. Цель должна пройти испытание Ловкости СЛ 16. При провале цель получит 27 (5к10) урона силовым полем, или половину этого урона при успехе.`,
    },
    {
      name: 'Парализующий луч ',
      restore: 5,
      description: `Ловчий нацеливается на одно видимое им существо в пределах 60 футов. Цель должна пройти испытание Телосложения СЛ 18, иначе станет парализованной на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.

Если парализованное существо Среднего или Маленького размера, то ловчий может подобрать его частью движения и передвигаться или карабкаться с ним на полной скорости.`,
    },
  ],
}
