const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_ELEMENTAL_BANE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_ELEMENTAL_BANE,
  name: 'Проклятье стихий',
  nameEn: 'Elemental Bane',
  description: `Выберите одно существо, видимое в пределах дистанции заклинания, а затем выберите один из следующих видов урона: звук, кислота, огонь, холод или электричество. Цель должна пройти испытание Телосложения, иначе будет подвержена эффекту заклинания в течение времени его действия. Когда цель впервые за ход получает урон выбранного вида, она также получает дополнительно 2к6 урона этого вида. Кроме того, цель теряет любые сопротивления к этому виду урона, пока не закончится заклинание.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 5 круга или выше, Вы можете выбрать одну дополнительную цель за каждый круг ячейки выше 5. Цели должны находиться в пределах 30 футов друг от друга, когда Вы сотворяете заклинание.`,
  lvl: 4,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: [
        DAMAGE_THUNDER,
        DAMAGE_ACID,
        DAMAGE_FIRE,
        DAMAGE_COLD,
        DAMAGE_ELECTRICITY,
      ],
      diceCount: 2,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 164,
    },
    {
      id: SOURCE_EE,
      page: 21,
    },
  ],
}
