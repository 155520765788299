const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_WARLOCK,
} = require('./../../../../pcClassIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_CYCLOPS} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_GIANT} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Циклоп`,
  nameEn: 'Cyclops',
  id: CREATURE_CYCLOPS,
  description: [
    `Циклопы это одноглазые великаны, влачащие жалкое существование в диких землях. Будучи замкнутыми по своей природе, они избегают контакта с остальными расами и стараются изгонять чужаков со своих территорий.`,
    {
      header: 'Нерелигиозные',
      text: `Легенды гласят, что циклопы являются порождением одного из богов великанов, но эти существа почти не уделяют внимания каким либо божествам. Они видят мало пользы в молитвах и не любят ритуалы, которые считают сложными и непонятными. Однако, циклопы, почуявшие ощутимую выгоду из некоторого места божественной силы, или те, которым угрожает какая либо сверхъестественная сила или сущность, будут воздавать богам, пока существует выгода или угроза.`,
      source: {
        id: SOURCE_MM,
        page: 296,
      },
    },
    {
      header: 'Простые',
      text: `Хотя циклопы и достаточно умные, они живут простыми, затворническими жизнями, разводя стада животных для пропитания. Они предпочитают жить в одиночку или небольшими семейными группами, обитая в пещерах, руинах или в грубых сооружениях из самостоятельно построенных сухих каменных конструкций. Циклопы загоняют стада на ночь в своё жилище, заваливая вход булыжниками, и используют свой дом как сарай.

Жилища циклопов расположены в дне пути друг от друга, так что они могут встречаться для торговли товарами или поиска помощников. Они создают оружие и инструменты из дерева и камня, но будут использовать металл, если смогут его найти. И хотя циклопы понимают Великаний язык, они ничего не пишут и мало говорят, используя для взаимодействия друг с другом хрюканье и жесты.

Циклопы не используют деньги для торговли, но ценят золото, ракушки и другие сверкающие и разноцветные предметы, как ювелирные изделия. Циклопы могут носить ожерелья с нанизанными на них перьями и серебряными монетами, а также вместе с оловянными кубками, столовыми приборами и всякими металлическими обломками.`,
      source: {
        id: SOURCE_MM,
        page: 296,
      },
    },
    {
      header: 'Глупые',
      text: `Циклопы не являются великими мыслителями или стратегами. Медленно соображающие и привязанные к своему обычному образу жизни, они находят нововведения трудными. И хотя они представляют огромную угрозу в бою благодаря размеру и силе, они зачастую могут быть обмануты умными противниками.

Циклопы могут испытывать страх или благоговение перед явными проявлениями магии. Простаки, увидев небольшое проявление магии, могут по ошибке принять [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}), [жреца](PC_CLASS:${PC_CLASS_CLERIC}) или другого заклинателя за могущественную божественную личность. Однако их чувство гордости заставляет их реагировать с мстительной, кровавой жестокостью, когда они узнают, что тот, кого они считали «богом», является простым смертным.`,
      source: {
        id: SOURCE_MM,
        page: 296,
      },
    },
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 296,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 20,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 10,
  },
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_6,
  featureList: [
    {
      name: `Плохое восприятие глубины`,
      description: `★СУЩЕСТВО★ совершает с помехой броски атаки по цели, находящейся на расстоянии более 30 футов.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Палицей_.`,
    },
    {
      name: `Палица`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: `Камень`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 9,
        range: {
          normal: 30,
          max: 120,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
