const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Модроны',
    text: `Модроны — создания абсолютного порядка, поддерживающие в своём обществе иерархию как в пчелином улье. Они обитают на плане Механус, и следят за его вечно вращающимися шестернями, ведя идеально упорядоченное существование механических аппаратов.`,
    source: {
      id: SOURCE_MM,
      page: 212,
    },
  },
  {
    header: 'Абсолютный закон и порядок',
    text: `Под управлением своего лидера, Прайма, модроны стараются упорядочивать мультивселенную в соответствии с принципами, недоступными пониманию простых смертных. Их собственные разумы встроены в иерархическую пирамиду, в которой каждый модрон получает приказы от вышестоящих и делегирует задания нижестоящим. Модроны выполняют команды с полным повиновением, максимальной эффективностью, полностью игнорируя моральные аспекты и совершенно не заботясь о себе.

Для модронов не существует концепции самоидентификации за пределами того минимума, который необходим для исполнения ими их функций. Они существуют в виде объединённого коллектива, и, несмотря на то, что он делится на ранги, про себя модроны всегда говорят собирательно. Для модрона нет слова «я», есть только «мы».`,
    source: {
      id: SOURCE_MM,
      page: 212,
    },
  },
  {
    header: 'Абсолютная иерархия',
    text: `Модроны взаимодействуют только с другими модронами своего ранга, а также с теми, кто на один ранг выше или ниже их. Модроны, отстоящие более чем на один ранг, либо слишком сложны, либо слишком примитивны, чтобы с ними можно было общаться.`,
    source: {
      id: SOURCE_MM,
      page: 212,
    },
  },
  {
    header: 'Шестерни Великого Механизма',
    text: `Если модрона уничтожить, его останки исчезают. Замена из нижестоящего ранга мгновенно трансформируется во вспышке света, приобретая физический облик своего нового ранга. Повышенный таким образом модрон точно так же заменяется одним из подчинённых, и так до самого низа в иерархии. А на нижнем ранге новый модрон создаётся Праймом; из Великого Собора Модронов на Механусе постоянно выходит равномерный поток монодронов.`,
    source: {
      id: SOURCE_MM,
      page: 212,
    },
  },
  {
    header: 'Великий Марш Модронов',
    text: `Когда шестерни Механуса завершают семнадцать циклов, что происходит раз в 289 лет, Прайм отправляет огромную армию модронов по Внешним Планам, вероятно, с разведывательной миссией. Марш долог и опасен, и лишь малое число модронов в результате возвращается на Механус.`,
    source: {
      id: SOURCE_MM,
      page: 212,
    },
  },
]
