const {FEATURE_CLOCKWORK_SPELLS} = require('./../../../featureIdList')
const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_SORCERER_CLOCKWORK_SOUL_2020_02_06} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_02_06_SUBCLASSES_PART_2} = require('./../../../sourceList')
const {
  SPELL_ALARM,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ARCANE_EYE,
  SPELL_COUNTERSPELL,
  SPELL_FIND_TRAPS,
  SPELL_GLYPH_OF_WARDING,
  SPELL_HEAT_METAL,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_WALL_OF_FORCE,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_CLOCKWORK_SPELLS,
    name: `Заводные заклинания`,
    nameEn: `Clockwork Spells`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_SORCERER_CLOCKWORK_SOUL_2020_02_06,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_ALARM,
      SPELL_ANIMATE_OBJECTS,
      SPELL_ARCANE_EYE,
      SPELL_COUNTERSPELL,
      SPELL_FIND_TRAPS,
      SPELL_GLYPH_OF_WARDING,
      SPELL_HEAT_METAL,
      SPELL_OTILUKE_S_RESILIENT_SPHERE,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_WALL_OF_FORCE,
    ],
    source: {
      id: SOURCE_UA_2020_02_06_SUBCLASSES_PART_2,
      page: 4,
    },
  },
]
