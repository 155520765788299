const {
  SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_SKELETON,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  CREATURE_SKELETAL_UNDEAD_SPIRIT,
  CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  SPELL_SUMMON_UNDEAD_SPIRIT_2020_03_26,
} = require('./../../../../spellIdList')

module.exports = {
  name: 'Дух скелетной нежити',
  nameEn: 'Skeletal Undead Spirit',
  id: CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26,
  releasedAs: CREATURE_SKELETAL_UNDEAD_SPIRIT,
  createdBySpellId: SPELL_SUMMON_UNDEAD_SPIRIT_2020_03_26,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_SKELETON,
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
    page: 8,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `модификатор Телосложения духа + модификатор заклинательной характеристики заклинателя + десятикратный круг заклинания`,
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Могильный снаряд',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 150,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 8,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
