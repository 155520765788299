const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_GREATER_RESTORATION,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_INCORPOREAL_MOVEMENT} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_GHOST} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Привидение',
  nameEn: 'Ghost',
  id: CREATURE_GHOST,
  description: [
    {
      header: 'Привидение',
      text: `Привидение это душа смертного существа, обречённая обитать в определённом месте, которое при жизни имело для него особое значение.`,
      source: {
        id: SOURCE_MM,
        page: 242,
      },
    },
    {
      header: 'Незавершённое дело',
      text: `Привидение может пытаться завершить какую-то нерешённую при жизни задачу. Он может стремиться отомстить за собственную смерть, исполнить данную клятву или что-то сообщить тому, кого любил. Привидение может не понимать, что оно уже мертво, и пытаться совершать свои ежедневные дела как при жизни. Иными движет злоба, и такое привидение не желает упокоиться до тех пор, пока жив хоть один представитель какого-либо рода или организации.

Самый надёжный способ избавиться от привидения — помочь ему завершить невыполненную задачу. Более лёгкий способ избавиться от привидения — ослабить его тем, от чего оно устало при жизни. Привидение замученного до смерти можно убить снова при помощи орудий пыток. Привидение садовника ослабеет от сильного цветочного аромата.`,
      source: {
        id: SOURCE_MM,
        page: 242,
      },
    },
    {
      header: 'Признаки присутствия привидения',
      text: `Чувства глубокой печали, одиночества и беспричинной тоски исходят из мест обитания привидений. Странные звуки или неестественная тишина создают тревожную атмосферу. В комнатах, где пылает огонь, может появиться участок сильного холода. Волны удушающей вони могут просочиться в помещение, предметы могут двигаться без причины, а из могил могут вставать трупы. Привидение не властно над этими проявлениями, они появляются сами по себе.`,
      source: {
        id: SOURCE_MM,
        page: 242,
      },
    },
    {
      header: 'Природа нежити',
      text: `Привидению не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 242,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 242,
  },
  armor: 11,
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 40,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 10,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 17,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_COLD,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_GRAPPLED,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_4,
  featureList: [
    {
      name: `Эфирное зрение`,
      description: `Зрение ★СУЩЕСТВО★ простирается на 60 футов на Эфирный План, когда ★он★ находится на Материальном плане, и наоборот.`,
    },
    ABILITY_INCORPOREAL_MOVEMENT,
  ],
  actionList: [
    {
      name: `Иссушающее касание`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 4,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: `Эфирность`,
      description: `★СУЩЕСТВО★ входит на Эфирный План с Материального плана, или наоборот. ★Он★ видимо на Материальном плане, пока находится в Пограничном Эфире, и наоборот, хотя не может воздействовать на то, что находится на другом плане, и становиться целью эффектов, происходящих на другом плане.`,
    },
    {
      name: `Ужасный облик`,
      description: `Все существа, находящиеся в пределах 60 футов от ★СУЩЕСТВО★, не являющиеся нежитью, и способные видеть ★его★, должны пройти испытание Мудрости СЛ 13, иначе станут испуганными на 1 минуту. Если цель проваливает испытание на 5 и более единиц, она также стареет на 1к4 × 10 лет. Испуганная цель может повторять это испытание в конце каждого своего хода, оканчивая испуганное состояние на себе при успехе. Если цель успешно прошла испытание или действие эффекта закончилось, она получает иммунитет к _Ужасному облику_ ★этого★ ★СУЩЕСТВО★ на следующие 24 часа. Эффект старения можно отменить заклинанием [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}), но только если оно будет наложено в течение 24 часов после воздействия.`,
    },
    {
      name: `Одержимость`,
      restore: 6,
      description: `Один гуманоид, видимый ★СУЩЕСТВО★ в пределах 5 футов от себя, должен пройти испытание Харизмы СЛ 13, иначе станет одержимым; после этого ★СУЩЕСТВО★ исчезает, а цель становится недееспособной и теряет контроль над своим телом. Теперь ★СУЩЕСТВО★ контролирует это тело, но цель не теряет сознание. ★СУЩЕСТВО★ не может быть целью атак, заклинаний и прочих эффектов, кроме тех, что изгоняют нежить, и ★он★ сохраняет своё мировоззрение, Интеллект, Мудрость, Харизму и иммунитет к испугу и очарованию. Во всём остальном ★СУЩЕСТВО★ использует статистику одержимой цели, но ★он★ не получает доступ к знаниям, классовым умениям и владениям цели.

Одержимость длится до тех пор, пока хиты тела не опустятся до 0, пока ★СУЩЕСТВО★ не окончит его бонусным действием, или пока ★СУЩЕСТВО★ не изгонят или не заставят уйти таким эффектом как заклинание [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}). Когда одержимость заканчивается, ★СУЩЕСТВО★ возвращается в свободное пространство в пределах 5 футов от тела. Цель получает на 24 часа иммунитет к _Одержимости_ этого ★СУЩЕСТВО★ после того как преуспеет в испытании или после того как _Одержимость_ закончится.`,
    },
  ],
  genderId: GENDER_MALE,
}
