const {ALPHABET_HAMARFAE} = require('./../../alphabetList')
const {LANG_SELDRUIN} = require('./../../languageIdList')
const {LANG_TYPE_DEAD} = require('./../../languageTypeList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: LANG_SELDRUIN,
  alphabetId: ALPHABET_HAMARFAE,
  type: LANG_TYPE_DEAD,
  name: {
    nominative: 'Селдруин',
    genitive: 'Селдруина',
    instrumental: 'Селдруином',
    prepositional: 'Селдруине',
  },
  nameEn: 'Seldruin',
  description: {
    text: `Селдруин — древний язык эльфийской Высшей Магии, в наши дни почти забыт.`,
    source: {
      id: SOURCE_SCAG,
      page: 105,
    },
  },
  isReady: true,
  isRealLang: true,
}
