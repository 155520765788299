const {
  CREATURE_BEHOLDER,
  CREATURE_ELDER_BRAIN,
  CREATURE_FLUMPH,
  CREATURE_INTELLECT_DEVOURER,
  CREATURE_MIND_FLAYER,
  CREATURE_MINDWITNESS,
} = require('./../../../../creatureIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_DEEP_SPEECH,
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_BEHOLDER_KEEN,
} = require('./../../../../creatureTypeIdList')
const {
  beholderLikeDescription,
  illithidDescriptionList,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_PRONE} = require('./../../../../conditionList')
const {CR_5} = require('./../../../../crList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Свидетель разума',
  nameEn: 'Mindwitness',
  id: CREATURE_MINDWITNESS,
  description: [
    `Если оглушить [бехолдера](CREATURE:${CREATURE_BEHOLDER}) и безопасно притащить в бассейн соляного раствора [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}), то его можно превратить в свидетеля разума посредством цереморфоза. Процесс цереморфоза трансформирует четыре глазных стебелька в щупальца, похожие на щупальца [свежевателя разума](CREATURE:${CREATURE_MIND_FLAYER}) и изменяет один из глазных лучей [бехолдера](CREATURE:${CREATURE_BEHOLDER}).

Менее интеллектуальные чем [бехолдеры](CREATURE:${CREATURE_BEHOLDER}) и менее опасные для колонии, свидетели разума псионически отмечены преданностью [старшему мозгу](CREATURE:${CREATURE_ELDER_BRAIN}) и подчинением командам [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}), делая их такими же покорными как и [пожирателей интеллекта](CREATURE:${CREATURE_INTELLECT_DEVOURER}).`,
    {
      header: `Усилитель телепатии`,
      text: `Первичная функция свидетеля разума — это усиление телепатической коммуникации в колонии [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}). Существо во время телепатического разговора со свидетелем разума может передавать разговор телепатически через него до семи другим существам, которых может видеть свидетель разума, что позволяет осуществить быстрое распространение приказов и другую информацию. `,
      source: {
        id: SOURCE_VGTM,
        page: 192,
      },
    },
    {
      header: `Искатели одиночества`,
      text: `Отделённый от хозяев-[иллитидов](CREATURE:${CREATURE_MIND_FLAYER}) свидетель разума разыскивает других телепатических существ, чтобы те говорили ему что делать. Свидетели разума бывает вступает в союз с [фламфами](CREATURE:${CREATURE_FLUMPH}) и телепатическими планарными существами, такими как демоны, и меняют свое мировоззрение в зависимости от новых хозяев.`,
      source: {
        id: SOURCE_VGTM,
        page: 192,
      },
    },
    ...illithidDescriptionList,
    beholderLikeDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_BEHOLDER_KEEN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 192,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 20,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  immunityConditionList: [
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
    {
      id: LANG_TELEPATHY,
      range: 600,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Усилитель телепатии',
      description: `Когда свидетель разума получает телепатическое сообщение, он может передавать его телепатически до семи существам одновременно, которых он может видеть не далее 600 футов от себя.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Совершает две атаки: одну _Щупальцем_, а вторую _Укусом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Щупальца',
      description: `Если размер цели Большой или меньше, то она будет схвачена и должна будет пройти испытание Интеллекта СЛ 13 или будет ошеломлена до тех пор, пока не закончится захват.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 4,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Лучи из глаза',
      description: `Свидетель разума выстреливает тремя случайными из следующих магических лучей из глаза (перебрасывайте повторы), выбирая от одной до трех целей, которые он может видеть не далее 120 футов от него:

1. **Луч отвращения.** Целевое существо должно пройти испытание Харизмы СЛ 13. При провале цель получает помеху на атаки на 1 минуту. Цель может повторять испытание в конце каждого своего хода и окончить эффект при успехе.
2. **Луч Страха.** Целевое существо должно успешно пройти испытание Мудрости СЛ 13 или стать испуганным на 1 минуту. Цель может повторять испытание в конце каждого своего хода и окончить эффект при успехе.
3. **Психический луч.** Цель должна успешно пройти испытание Интеллекта СЛ 13 или получить 27 (6к8) психического урона.
4. **Замедляющий луч.** Целевое существо должно пройти испытание Ловкости 13. При провале скорость существа уменьшается вдвое. Кроме того, существо не может совершать реакции и может в свой ход совершать либо действие, либо бонусное действие, но не оба. Существо может повторять испытание в конце каждого своего хода и окончить этот эффект при успехе.
5. **Ошеломляющий луч.** Целевое существо должно успешно пройти испытание Телосложения СЛ 13 или стать ошеломлённым на 1 минуту. Цель может повторять испытание в конце каждого своего хода и окончить эффект при успехе.
6. **Телекинетический луч.** Если цель существо, то оно должно пройти испытание Силы СЛ 13. При провале свидетель разума сдвигает его на 30 футов в любом направлении и оно обездвижено телекинетической хваткой до начала следующего хода свидетеля разума или до того как он станет недееспособен. Если целью является объект, весящий 300 фунтов или меньше, который никто не несёт и не носит, то он телепатически сдвигается на 30 футов в любом направлении. Свидетель разума также может осуществлять этим лучом тонкие манипуляции, такие как работа простыми инструментами или открытие двери или контейнера.`,
    },
  ],
}
