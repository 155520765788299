module.exports = [
  ...require('./generated/dragonfang'),
  ...require('./generated/dragonsoul'),
  ...require('./generated/dragonwing'),

  require('./list/dragon_cult_fanatic'),
  require('./list/dragon_cult_initiate'),
  require('./list/dragonclaw'),
  require('./list/scrag'),
  require('./list/tiamat'),
]
