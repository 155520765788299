const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_SHAARAN} = require('./../../languageIdList')
const {ALPHABET_DETHEK} = require('./../../alphabetList')
const {
  PLACE_LAKE_OF_STEAM,
  PLACE_LAPALIIYA,
  PLACE_SESPECH,
  PLACE_SHAAR,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_SHAARAN,
  alphabetId: ALPHABET_DETHEK,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Шаарский',
    genitive: 'Шаарского',
    instrumental: 'Шаарским',
    prepositional: 'Шаарском',
  },
  nameEn: 'Shaaran',
  typicalSpeakers: 'Шаары',
  spokenPlaceList: [
    PLACE_LAKE_OF_STEAM,
    PLACE_LAPALIIYA,
    PLACE_SESPECH,
    PLACE_SHAAR,
  ],
  isReady: false,
  isRealLang: true,
}
