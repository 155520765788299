const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_SHORTSWORD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_GNOLL,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_RAMPAGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_GNOLL_FLESH_GNAWER} = require('./../../../../creatureIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {gnollDescriptionList} = require('./../../../../textCommonParts')
const {LANG_GNOLL} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гнолл глодатель',
  nameEn: 'Gnoll Flesh Gnawer',
  id: CREATURE_GNOLL_FLESH_GNAWER,
  description: [
    `Если какую то группу гноллов и можно назвать самой дикой из всех, то это, безусловно, гноллы глодатели. Эти гноллы предпочитают [короткие клинки](GEAR:${GEAR_SHORTSWORD}) оружию дальнего боя, которыми они орудуют с небывалой скоростью и эффективностью. В гуще сражения они носятся по полю боя, рубя и рыча, сбивая с ног отступающих и добивая раненых врагов.`,
    ...gnollDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GNOLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 141,
  },
  armor: {
    ac: 14,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  saveThrowList: [
    PARAM_DEX,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GNOLL,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_RAMPAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Гнолл делает три атаки: одну _Укусом_ и две _Коротким мечом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      name: 'Внезапная спешка',
      description: `До конца хода скорость гнолла увеличивается до 60 футов и он не вызывает атак по возможности.`,
    },
  ],
}
