const {
  SOURCE_MOOT,
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2016_01_04_KITS_OF_OLD,
  SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES,
  SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
  SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
  SOURCE_UA_2020_02_06_SUBCLASSES_PART_2,
  SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
  SOURCE_VRGtR,
  SOURCE_XGTE,
} = require('./../../sourceList')
const {
  GOD_TIAMAT,
  GOD_BAHAMUT,
} = require('./../../godIdList')
const {
  PC_RACE_GNOME,
  PC_RACE_DRAGONBORN,
  PC_RACE_DWARF,
} = require('./../../pcRaceIdList')
const {PC_CLASS_BARD} = require('./../../pcClassIdList')
const {
  PC_SUBCLASS_BARD_COLLEGE_OF_CREATION,
  PC_SUBCLASS_BARD_COLLEGE_OF_CREATION_2020_02_06,
  PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE,
  PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE_2019_09_18,
  PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR,
  PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR_2016_11_14,
  PC_SUBCLASS_BARD_COLLEGE_OF_LORE,
  PC_SUBCLASS_BARD_COLLEGE_OF_SATIRE_2016_01_04,
  PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS,
  PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS_2020_08_05,
  PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS,
  PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS_2016_01_04,
  PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS_2017_05_01,
  PC_SUBCLASS_BARD_COLLEGE_OF_VALOR,
  PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS,
  PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS_2016_11_14,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_CREATION,
    pcClassId: PC_CLASS_BARD,
    isReady: true,
    name: 'Коллегия творения',
    nameEn: 'College of Creation',
    description: `Барды верят, что космос — это произведение искусства, творение первых драконов и богов. Работа по его сотворению включала в себя гармонии, чьи отголоски продолжают звучать и сегодня, известные как Песнь творения.

Барды Коллегии творения ищут эту первозданную Песнь в танце, музыке и поэзии, а их учителя говорят: _«До солнца и луны была Песнь, и её музыка пробудила первый рассвет. Его мелодии так восхитили камни и деревья, что некоторые из них получили собственные голоса. И теперь они тоже поют. Изучайте Песнь, ученики, и Вы сможете научить даже горы петь и танцевать»_.

[Дварфы](PC_RACE:${PC_RACE_DWARF}) и [гномы](PC_RACE:${PC_RACE_GNOME}) часто поощряют своих бардов изучать Песнь творения. А среди [драконорождённых](PC_RACE:${PC_RACE_DRAGONBORN}) Песнь творения почитается, потому что легенды изображают [Бахамута](GOD:${GOD_BAHAMUT}) и [Тиамат](GOD:${GOD_TIAMAT}) — величайших из драконов — двумя первыми её певцами.`,
    note: {
      text: `Для барда-то — песня творения, а для других — ночные кошмары`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 35,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_CREATION_2020_02_06,
    pcClassId: PC_CLASS_BARD,
    releasedAs: PC_SUBCLASS_BARD_COLLEGE_OF_CREATION,
    isReady: false,
    name: 'Коллегия творения',
    nameEn: 'College of Creation',
    source: {
      id: SOURCE_UA_2020_02_06_SUBCLASSES_PART_2,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE,
    pcClassId: PC_CLASS_BARD,
    isReady: true,
    name: 'Коллегия красноречия',
    nameEn: 'College of Eloquence',
    description: `Последователи Коллегии красноречия овладевают искусством ораторского мастерства. Убеждение считается ими высоким искусством, и разумный, хорошо высказанный аргумент часто оказывается более убедительным, чем факты.

Эти барды умело сочетают логику и театральное искусство, переубеждая скептиков и недоброжелателей логическими аргументами и игрой на чувствах публики.`,
    note: {
      text: `Себе на заметку: пересмотреть работу над речеопровергательным заклинанием. Требуемый уровень: кровь из ушей`,
      author: `Таша`,
    },
    source: [
      {
        id: SOURCE_MOOT,
        page: 29,
      },
      {
        id: SOURCE_TCoE,
        page: 37,
      },
    ],
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE_2019_09_18,
    pcClassId: PC_CLASS_BARD,
    releasedAs: PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE,
    isReady: false,
    name: 'Коллегия красноречия',
    nameEn: 'College of Eloquence',
    source: {
      id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR,
    pcClassId: PC_CLASS_BARD,
    isReady: true,
    name: 'Коллегия очарования',
    nameEn: 'College of Glamour',
    description: `Коллегия Очарования открыта для тех бардов, чьё мастерство было взращено в Царстве Фей или под руководством того, кто проживал там. Обученные сатирами, эладринами, и другими фейскими существами, эти барды учатся использовать свою магию, чтобы восхищать и очаровывать окружающих.

Барды этой коллегии в своём ремесле используют магию, которая наделена одновременно благоговением и страхом. Их выступления — легендарны. Барды этой коллегии настолько красноречивы, что одной их песни или речи может хватить, чтобы убедить похитителей отпустить барда нетронутым или успокоить разъяренного дракона. Но та же магия, что помогает им усмирять зверей, может и подчинять разумы людей. Недобрые барды этой коллегии могут неделями паразитировать на выбранном ими сообществе, используя свои чары, чтобы превратить гостеприимных хозяев в рабов своей воли. Героические барды этой коллегии вместо этого используют свою силу, чтобы радовать угнетенных и ослаблять диктаторов.`,
    note: {
      text: `Иллюзии? Как оригинально. Перед тем, как я тебя убью, сделай мне одну в виде очень большой золотой рыбки — большой как я! Хм-м. Что-то слишком большая. Пока!`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 13,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR_2016_11_14,
    pcClassId: PC_CLASS_BARD,
    releasedAs: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR,
    isReady: false,
    name: 'Коллегия очарования',
    nameEn: 'College of Glamour',
    description: `The College of Glamour is open to those bards who mastered their craft in the vibrant, deadly realm of the Feywild. Tutored by satyrs, eladrin, and other fey, these bards learn to use their magic to delight and captivate others.

The bards of this college are regarded with a mixture of awe and fear. Their performances are the stuff of legend. The bards of this college are so eloquent that a speech or song that one of them performs can cause captors to release the bard unharmed and can lull a furious dragon into complacency. The same magic that allows them to quell beasts can also bend minds. Villainous bards of this college can leech off a community for weeks, abusing their magic to turn their hosts into thralls.`,
    source: {
      id: SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_LORE,
    pcClassId: PC_CLASS_BARD,
    isReady: true,
    name: 'Коллегия знаний',
    nameEn: 'College of Lore',
    description: `Барды коллегии знаний понемногу осведомлены обо всём на свете. Они собирают крупицы знаний отовсюду, будь то научные труды или крестьянские побасенки. Напевают ли они народные баллады в таверне, или исполняют изысканные композиции при дворе, эти барды используют свой талант, чтобы очаровать публику. И когда смолкают аплодисменты, публика может обнаружить себя рассказывающей все свои секреты, начиная со сплетен местного прихода, и заканчивая подробностями своей верности королю.

Лояльность таких бардов покоится на стремлении к красоте и правде, а не на верности монархам или приверженности к религиозным догматам. Дворяне, содержащие таких бардов в качестве советников или герольдов, хорошо знают, что бард скорее скажет неприятную правду, чем благоразумно промолчит.

Члены коллегии собираются в библиотеках, а иногда даже в колледжах, с настоящими классами и общежитиями. Там они делятся друг с другом знаниями. Они также посещают фестивали и общественные собрания, где могут выявлять растратчиков, разоблачать лжецов или выставлять на посмешище самоуверенных политиков.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 55,
      },
      {
        id: SOURCE_SRD,
        page: 13,
      },
    ],
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_SATIRE_2016_01_04,
    pcClassId: PC_CLASS_BARD,
    isReady: true,
    name: 'Коллегия сатиры',
    nameEn: 'College of Satire',
    description: `Барды коллегии сатиры называются шутами. Они развлекают зрителей, от толпы в занюханном портовом пабе до знати королевского двора, используя легкомысленные истории, дерзкую акробатику и шутки. Когда другие барды ищут забытые знания или рассказы о героической храбрости, шуты разыскивают пошлые и весёлые истории всех мастей. Это могут быть как непристойные рассказы о романе мускулистого конюха с престарелой герцогиней или злобная сатира на паладинскую веру о прискорбной невинности Хельма. Шут никогда не позволит социальным приличиям или стыду помешать хорошей шутке.

В то время как шуты — мастера каламбуров, шуток и словесных подколок, они представляют собой нечто большее, чем просто развлекателей публики. Ожидается, что они будут насмехаться и провоцировать, пользуясь тем, что даже самые влиятельные люди по традиции должны с улыбкой сносить насмешки шута. Это ожидание позволяет шуту выступать в роли критика или голоса разума, когда другие слишком запуганы, чтобы говорить правду.

Для герцогини, любящей крепких молодых работников, такие истории могут послужить предупреждением для них и оставит её без партнеров. Месть шуту только разрушит её и без того уже испорченную репутацию и будет лучшим доказательством того, что сатиры достигла цели. Но если такая герцогиня доброжелательна и щедра к своим любовникам, эти шутки и истории превращают её в своего рода народную героиню, одновременно привлекая к ней еще больше потенциальных партнеров.

Шуты верны только одному: нахождению и распространению истины. Они используют свои шутки и безобидную внешность, чтобы разрушать социальные барьеры и разоблачить коррупцию, некомпетентность и глупость среди богатых и влиятельных. Раскрывая ли мошенничество шарлатана или разоблачая планы барона на войну, движимые алчностью и жаждой крови, шут служит совестью королеств.

Шут-приключенец защищает простых людей и подрывает планы богатых, могущественных и высокомерных. Их магия поддерживает дух союзников, в то же время заставляя сомневаться противников. Среди бардов шуты — непревзойденные акробаты, а их способность падать, уворачиваться, прыгать и лазать делает их опасными противниками в бою.`,
    source: {
      id: SOURCE_UA_2016_01_04_KITS_OF_OLD,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS,
    pcClassId: PC_CLASS_BARD,
    isReady: true,
    name: 'Коллегия мечей',
    nameEn: 'College of Swords',
    description: `Бардов Коллегии Мечей называют клинками, и они выступают на публике, выказывая свое мастерство обращения с оружием. Клинки выполняют такие трюки как шпагоглотание, метание и жонглирование кинжалами и демонстрационные бои. Хоть они и используют свое оружие для развлечения, они хорошо натренированные и умелые бойцы.

Талант обращения с оружием вдохновляет многих клинков вести двойную жизнь. Клинок может использовать цирковую труппу, чтобы прикрывать такие гнусные дела, как убийства, ограбления и шантаж. Другие клинки сражают злодеев, воздают по справедливости жестоким и могущественным. Многие труппы рады принять талант клинка ради зрелищности, которую он добавит представлению, но мало кто полностью доверяет клинку в своих рядах.

Клинки часто забрасывают жизнь артистов просто потому, что сталкиваются с неприятностями, после которых трудно скрыть их тайную деятельность. Клинок, пойманный на воровстве или в качестве мстителя — слишком большая обуза для большинства трупп. Со своими навыками обращения с оружием и магией, эти клинки либо работают силовиками на гильдии воров, либо начинают скитаться сами по себе как искатели приключений.`,
    source: {
      id: SOURCE_XGTE,
      page: 12,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS_2016_01_04,
    pcClassId: PC_CLASS_BARD,
    releasedAs: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS,
    isReady: false,
    name: 'Коллегия мечей',
    nameEn: 'College of Swords',
    source: {
      id: SOURCE_UA_2016_01_04_KITS_OF_OLD,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS_2017_05_01,
    pcClassId: PC_CLASS_BARD,
    releasedAs: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS,
    isReady: false,
    name: 'Коллегия мечей',
    nameEn: 'College of Swords',
    source: {
      id: SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_VALOR,
    pcClassId: PC_CLASS_BARD,
    isReady: true,
    name: 'Коллегия доблести',
    nameEn: 'College of Valor',
    description: `Барды коллегии доблести это отважные скальды, чьи сказания не дают забыть великих героев прошлого и вдохновляют на подвиги нынешних героев.

Такие барды собираются в медовых залах или вокруг походных костров, чтобы воспеть великие свершения прошлого и настоящего. Они путешествуют, чтобы стать очевидцами великих событий, и не дать памяти о них угаснуть. Своими песнями они вдохновляют на достижения, достойные памяти предков.`,
    source: {
      id: SOURCE_PHB,
      page: 55,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS,
    pcClassId: PC_CLASS_BARD,
    isReady: true,
    name: 'Коллегия шёпотов',
    nameEn: 'College of Whispers',
    description: `Все рады приветствовать бардов в своём обществе, и представители Коллегии Шёпотов используют это в своих интересах. Они с виду подобны всем прочим бардам: делятся новостями, поют песни и рассказывают истории своей аудитории. На самом же деле Коллегия Шёпотов учит своих учеников, что они должны быть подобны волкам среди овец. Эти барды используют свои знания и магию, чтобы раскрывать чужие тайны и оборачивать их против других через шантаж и угрозы.

Большинство прочих бардов ненавидят Коллегию Шёпотов, рассматривая их как паразитов, которые используют репутацию бардов, чтобы обрести богатство и власть. По этой причине барды этой коллегии редко раскрывают свою истинную природу. Как правило, они утверждают, что являются представителем какой-либо другой коллегии, или сохраняют свою истинную природу втайне с целью проникновения и эксплуатации королевских дворов и других властных структур.`,
    note: {
      text: `Выкладывай! Очень трудно расслышать что-то сквозь твои крики. Нет. Бесполезно. Нужно остановить эти крики. Ну тогда дезинтегрирую тут всё.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 13,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS_2016_11_14,
    pcClassId: PC_CLASS_BARD,
    releasedAs: PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS,
    isReady: false,
    name: 'Коллегия шёпотов',
    nameEn: 'College of Whispers',
    description: `Most folk are happy to welcome a bard into their midst. Bards of the College of Whispers use this to their advantage. They appear to be like any other bard, sharing news, singing songs, and telling tales to the audiences they gather. In truth, the College of Whispers teaches its students that they are wolves among sheep. These bards use their knowledge and magic to uncover secrets and turn them against others through extortion and threats.

Many other bards hate the College of Whispers, viewing it as a parasite that uses the bards’ reputation to acquire wealth and power. For this reason, these bards rarely reveal their true nature unless they must. They typically claim to follow some other college, or keep their true nature secret in order to better infiltrate and exploit royal courts and other settings of power.`,
    source: {
      id: SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS_2020_08_05,
    pcClassId: PC_CLASS_BARD,
    releasedAs: PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS,
    isReady: false,
    name: 'Коллегия духов',
    nameEn: 'College of Spirits',
    source: {
      id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS,
    pcClassId: PC_CLASS_BARD,
    isReady: false,
    name: 'Коллегия духов',
    nameEn: 'College of Spirits',
    source: {
      id: SOURCE_VRGtR,
      page: 34,
    },
  },
]
