const {
  MAGIC_ENCHANTMENT,
} = require('./../../../../../magicList')
const {
  PARAM_INT,
} = require('./../../../../../paramList')
const {
  CONDITION_INCAPACITATED,
} = require('./../../../../../conditionList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_FTD,
} = require('./../../../../../sourceList')
const {
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  name: 'Ралотимово психическое копьё',
  nameEn: 'Raulothim’s Psychic Lance',
  description: `Вы испускаете изо лба мерцающее копьё психической силы в существо, видимое Вами в пределах дистанции.

Альтернативно, Вы можете назвать имя существа. Если названная цель находится в пределах дистанции, она становится целью заклинания даже если Вы его не видите. Если названная цель не находится в пределах дистанции, копьё рассеивается без какого-либо эффекта.

Цель должна пройти испытание Интеллекта.

* При провале цель получает 7к6 психического урона и становится Недееспособной до начала Вашего следующего хода.
* При успехе цель получает только половину урона и не становится Недееспособной.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше четвёртого.`,
  lvl: 4,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 120,
  componentIdList: [CAST_VERBAL],
  effect: {
    savethrowParam: PARAM_INT,
    condition: CONDITION_INCAPACITATED,
    damage: {
      type: DAMAGE_PSYCHIC,
      diceCount: 7,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_FTD,
    page: 21,
  },
}
