const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_MORDENKAINEN_S_PRIVATE_SANCTUM,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_MORDENKAINEN_S_PRIVATE_SANCTUM,
  name: 'Кабинет Морденкайнена',
  nameEn: `Mordenkainen’s Private Sanctum`,
  nameEnAlt: `Private Sanctum`,
  description: `Вы создаёте в пределах дистанции защищённое магией место. Это куб с длиной ребра от 5 до 100 футов. Заклинание длится, пока не окончится, или Вы не окончите его действием.

Когда Вы накладываете это заклинание, Вы решаете, какую именно безопасность обеспечивает заклинание, выбирая все или некоторые из следующих свойств:

* Звуки не проходят сквозь границу защищённого пространства.
* Граница защищённого пространства выглядит тёмной и затуманенной, блокируя обзор (в том числе и тёмное зрение) сквозь неё.
* Сенсоры, создаваемые заклинаниями школы Прорицания, не могут появляться в защищённой области и проходить сквозь её периметр.
* Существа в области не могут становиться целями заклинаний школы Прорицания.
* Ничто не может телепортироваться в охраняемую область или из неё.
* В охраняемой области блокируются планарные перемещения.

Накладывание этого заклинания на одно и то же место в течение года делает этот эффект постоянным.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, Вы можете увеличить размер куба на 100 футов за каждый круг ячейки выше 4. Таким образом, это заклинание, использованное ячейкой 5 уровня, может создать куб с длиной ребра 200 футов.`,
  lvl: 4,
  magicSchoolId: MAGIC_ABJURATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `тонкий свинцовый лист, кусок матового стекла, шарик из ваты или ткани и толчёный хризолит`,
  duration: { timeId: TIME_HOUR, count: 24 },
  castTime: { timeId: TIME_MINUTE, count: 10 },
  source: [
    {
      id: SOURCE_PHB,
      page: 233,
    },
    {
      id: SOURCE_SRD,
      page: 171,
    },
  ],
}
