const {CONDITION_PRONE} = require('./../../../../../conditionList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_SLEET_STORM} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')

module.exports = {
  id: SPELL_SLEET_STORM,
  name: 'Метель',
  nameEn: `Sleet Storm`,
  description: `Пока заклинание активно, в цилиндре высотой 20 футов и радиусом 40 футов с центром на точке, выбранной Вами в пределах дистанции, идёт мокрый снег. Это сильно заслонённая местность, и открытые источники огня в ней тухнут.

Пол в этой местности покрывается скользким льдом, что делает его труднопроходимой местностью. Когда существо впервые за ход входит в область заклинания или начинает там ход, оно должно пройти испытание Ловкости. При провале оно падает ничком.

Если существо в начале своего хода концентрируется, находясь в области этого заклинания, оно должно пройти испытание Телосложения, иначе потеряет концентрацию.`,
  lvl: 3,
  magicSchoolId: MAGIC_CONJURATION,
  range: 150,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `щепотка пыли и несколько капель воды`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    condition: CONDITION_PRONE,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 239,
    },
    {
      id: SOURCE_SRD,
      page: 180,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Концентрация теперь тоже сбивается в начале своего хода, а не абы когда',
      page: 3,
    },
  ],
}
