const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_FTD,
} = require('./../../../../../sourceList')
const {
  CREATURE_CHROMATIC_DRAGON_SPIRIT,
  CREATURE_GEM_DRAGON_SPIRIT,
  CREATURE_METALLIC_DRAGON_SPIRIT,
} = require('./../../../../../creatureIdList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_SUMMON_DRACONIC_SPIRIT,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SUMMON_DRACONIC_SPIRIT,
  name: 'Призыв духа дракона',
  nameEn: 'Summon Draconic Spirit',
  description: `Вы призываете духа дракона. Дух проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите драконий род: [цветной](CREATURE:${CREATURE_CHROMATIC_DRAGON_SPIRIT}), [самоцветный](CREATURE:${CREATURE_GEM_DRAGON_SPIRIT}) или [металлический](CREATURE:${CREATURE_METALLIC_DRAGON_SPIRIT}). Существо выглядит как дракон выбранного рода, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.
 
Существо дружественно к Вам и Вашим спутникам. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно совершает действие Уклонение и передвигается так, чтобы избегать опасности.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, параметры призванного духа увеличиваются, как указано в его описании.`,
  createdCreatureId: [
    CREATURE_CHROMATIC_DRAGON_SPIRIT,
    CREATURE_GEM_DRAGON_SPIRIT,
    CREATURE_METALLIC_DRAGON_SPIRIT,
  ],
  lvl: 5,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_MATERIAL, CAST_SOMATIC],
  materialText: `предмет с вырезанным изображением дракона, стоимостью не менее 500 золотых`,
  hasCost: true,
  duration: { timeId: TIME_HOUR, count: 1 },
  needConcentration: true,
  source: {
    id: SOURCE_FTD,
    page: 21,
  },
}
