const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  GOD_SEMUANYA,
} = require('./../../godIdList')

module.exports = [
  {
    text: `Людоящеры — примитивные рептилоидные гуманоиды, обитающие в болотах и джунглях. Их деревушки из хижин процветают в неприступных гротах, полузатонувших руинах и в залитых водою пещерах.`,
    source: {
      id: SOURCE_MM,
      page: 197,
    },
  },
  {
    header: 'Территориальные ксенофобы',
    text: `Людоящеры очень редко заключают сделки и торгуют с другими расами. Они очень сильно привязаны к своей территории, выставляя замаскированных разведчиков на границах для охраны. Когда у границ появляется противник, людоящеры отправляют отряды охотников, чтобы преследовать или изгнать захватчиков со своей территории. Также они могут воспользоваться знанием своей земли и заманить непрошеных гостей в логово крокодилов или других опасных существ.

У людоящеров нет и малейшего представления о морали, а привычная концепция добра и зла вовсе чужда им. Истинно нейтральные существа, они убивают только тогда, когда это целесообразно или критично для выживания.

Людоящеры очень редко выходят за пределы своих охотничьих угодий. Любое существо, которое зайдёт на их территорию, будет преследоваться, а затем будет убито и съедено. Для них нет разницы в том, кто пришёл в их владения — человек, зверь или монстр. Людоящеры не любят покидать свои угодья потому, что за их пределами они могут превратиться из охотников в добычу.

Иногда людощеры заключают союз с соседями. Это может случиться только тогда, когда эльфы, люди, полурослики или другие могут принести им пользу или заслужили доверие. После того, как людоящеры налаживают связи, они выступают в роли жестоких, но надёжных союзников.`,
    source: {
      id: SOURCE_MM,
      page: 197,
    },
  },
  {
    header: 'Великие пиры и жертвоприношения',
    text: `Людоящеры всеядны, но они предпочитают мясо гуманоидов. Пленники очень часто попадают в лагеря в качестве главного блюда на праздниках и обрядах, которые сопровождаются танцами, байками и ритуальными битвами. Жертвы либо готовятся на огне и съедаются, либо приносятся в качестве дара для [Семуаньи](GOD:${GOD_SEMUANYA}), божества людоящеров.`,
    source: {
      id: SOURCE_MM,
      page: 197,
    },
  },
  {
    header: 'Барахольщики',
    text: `Несмотря на то, что людоящеры не слишком умелые ремесленники, они используют кости поверженных врагов для изготовления инструментов. Шкуры и панцири убитых чудовищ часто используются для изготовления щитов.`,
    source: {
      id: SOURCE_MM,
      page: 197,
    },
  },
  {
    header: 'Лидеры людоящеров',
    text: `Людоящеры уважают и боятся магию с религиозным благоговением. Племена людоящеров возглавляют шаманы, исполняющие обряды и церемонии во имя [Семуаньи](GOD:${GOD_SEMUANYA}). Однако в племени людоящеров может появиться личность, благословенная не [Семуаньей](GOD:${GOD_SEMUANYA}), а Сесс’инеком, рептилоидным демоническим повелителем, который пытается развратить людоящеров и полностью контролировать их.

Людоящеры, рождённые под влиянием Сесс’инека, крупнее и хитрее сородичей, а также намного злее. Такие короли и королевы ящеров захватывают под жёсткий контроль племена людоящеров, узурпируя авторитет шаманов и вдохновляя своих подданных на нехарактерную агрессию.`,
    source: {
      id: SOURCE_MM,
      page: 197,
    },
  },
  {
    header: 'Последователи драконов',
    text: `Людоящеры говорят на Драконьем языке, которому они научились (или, по крайней мере, они так думают) в давние времена у самих драконов. Если племя заходит на территорию дракона, то предлагает ему подношение, чтобы получить его благосклонность. Злой дракон может использовать людоящеров в своих целях, сделав из них налётчиков и грабителей.`,
    source: {
      id: SOURCE_MM,
      page: 197,
    },
  },
  {
    header: 'Людоящеры',
    text: `Только дурак смотрит на людоящеров и видит не более, чем просто чешуйчатых гуманоидов. Несмотря на их физическую форму, людоящеры имеют больше общего с игуанами или драконами, чем с людьми, дварфами или эльфами. Людоящеры обладают чуждым и непостижимым образом мысли, их желаниями и мыслями руководит другой набор основных принципов, чем у существ с теплой кровью. Их мрачные болотные дома могут располагаться в сотнях миль от ближайшего людского поселения, но разрыв между образом мыслей у них и у гладкокожих намного больше.

Несмотря на их чужеродное мировоззрение, некоторые людоящеры прилагают усилия, чтобы понять и, по–своему, подружиться с представителями других рас. Такие людоящеры становятся преданными и умелыми союзниками.`,
    source: {
      id: SOURCE_VGTM,
      page: 114,
    },
  },
  {
    header: 'Иная логика',
    text: `Рептилоидная натура людоящеров проявляется не только в их внешности, но также и в том, как они думают и действуют. Людоящеры испытывают куда как меньше эмоций, чем другие гуманоиды. Как и у большинства рептилий, их чувства в основном вращаются вокруг страха, агрессии, и удовольствия.

Вместо чувств людоящеры испытывают реакции на существ и ситуации. Например, люди, столкнувшиеся с сердитым троллем, на подсознательном уровне испытывают страх. Они испуганны, их движения и мысли путаются, они реагируют инстинктивно. Страх захватывает их и управляет ими. Для людоящеров же, наоборот, эмоции — это особые реакции на других существ, объекты и ситуации. Людоящер не думает, «я боюсь.» Вместо этого он рассматривает самых сильных и агрессивных существ как угрозу, которую по возможности нужно избегать. Если такие существа нападают, людоящер бежит, сражаясь только если его загнали в угол. Людоящер не боится тролля; вместо этого он понимает, что тролль это внушающее страх, опасное существо, и реагирует соответственно.

Людоящеры никогда не сердятся так, как это делают другие, но они могут быть агрессивны к существам, которых они могут победить в бою, и которые не опасны для них как–то иначе. Они агрессивны к добыче, которую хотят съесть, существам, которые приносят им вред, и так далее.

Людоящерам приятны существа и вещи, которые облегчают жизнь. Приятные вещи должны быть сохранены и защищены, иногда даже за счет безопасности самого людоящера. Большинство приятных для людоящера существ и вещей оцениваются скорее как хорошие, чем как пугающие.`,
    source: {
      id: SOURCE_VGTM,
      page: 114,
    },
  },
  {
    header: 'Холодные и расчётливые',
    text: `Большинство гуманоидов считает хладнокровных людей недостаточно эмоциональными и сочувствующими. Тот же ярлык навесили на людоящеров.

В отсутствие каких–либо внутренних эмоциональных реакций, людоящеры ведут себя отстраненно. Они не оплакивают павших товарищей и не впадают в ярость по отношению к врагам. Они просто наблюдают и реагируют согласно ситуации.

У людоящеров нет эмоциональных связей с прошлым. Они оценивают ситуации, основываясь на их текущей и будущей полезности и важности. Наиболее сильно это проявляется тогда, когда людоящеры имеют дело с мертвыми. Для людоящера умерший товарищ становится потенциальным источником еды. Этот компаньон, возможно, когда-то был воином или охотником, но теперь он — не более чем свежее мясо.

Людоящер, который живет среди других гуманоидов, может, в течение долгого времени учиться уважать эмоции других существ. Людоящер не разделяет эти чувства, но вместе с тем оценивает их так же беспристрастно. Да, павший дварф мог бы быть полезнее как еда, но разрубание тела на стейки вызывает агрессию у других гуманоидов и делает их менее полезными в сражении.`,
    source: {
      id: SOURCE_VGTM,
      page: 114,
    },
  },
  {
    header: 'Полезность и выживание',
    text: `Мышление людоящеров могло бы показаться излишне жестоким, но оно помогает им выжить во враждебном окружении. Болота, которые они населяют, наполнены потрясающим разнообразием угроз. Людоящеры нацелены на выживание любой ценой, без сантиментов.

Людоящеры оценивают всё и всех с точки зрения полезности. Искусство и красота не имеют для них значения. Острый меч полезен и хорошо служит, в то время как тупой меч — мертвый груз без точильного камня.

Людоящеры не видят необходимости планировать будущее дальше чем на сезон. Этот подход позволяет им поддерживать свой текущий уровень влияния в мире, но это ограничивает их развитие. У людоящеров нет никакого интереса к развитию письма, созданию долгосрочных планов, или культивированию других методов развития за пределы их обычного существования как охотников и собирателей.`,
    source: {
      id: SOURCE_VGTM,
      page: 114,
    },
  },
  {
    header: 'Несчастные мягкокожие',
    text: `По сути, людоящеры относятся к другим гуманоидам с безразличием, граничащим с жалостью. Учитывая, что другие гуманоиды родились без крепкой чешуи и острых зубов, это вообще чудо, что им удалось выжить так долго. Типичный человек загнулся бы на болотах за день.

Однако, если другие существа оказываются полезными для людоящера, то они вызывают у людоящеров желание защитить их, которое тем сильнее, чем внешне слабее защищаемый. Людоящеры оценивают таких существ как молодых и неспособных защищаться детёнышей, которые могут оказаться полезным в будущем, если о них заботиться.`,
    source: {
      id: SOURCE_VGTM,
      page: 114,
    },
  },
  {
    header: 'Индивидуальность людоящеров',
    text: `Вы можете использовать таблицу, чтобы определить персональные причуды или особые манеры Вашего людоящера.

# Причуды людоящеров

| к8 | Причуда |
|----|---------|
| 1 | Вы ненавидите расточительство и не видите причин, почему бы не освежевать павших врагов. Пальцы — это вкусно и компактно! |
| 2 | Вы лучше всего спите, когда большей частью погружены в воду. |
| 3 | Деньги для Вас бессмысленны. |
| 4 | Вы думаете, что есть только две разновидности гуманоидов: людоящеры и мясо. |
| 5 | Вы научились смеяться. Вы используете этот талант в ответ на все эмоциональные ситуации, чтобы лучше находить общий язык со своими товарищами. |
| 6 | Вы всё ещё не понимаете, как работают метафоры, но это не мешает Вам использовать их при любом удобном случае. |
| 7 | Вы высоко цените мягких гуманоидов, которые понимают, что нуждаются в кольчуге и мечах, чтобы соответствовать тем подаркам, с которыми Вы родились. |
| 8 | Вы любите есть свою еду пока она всё ещё трепыхается. |`,
    source: {
      id: SOURCE_VGTM,
      page: 114,
    },
  },
  {
    header: 'Имена людоящеров',
    text: `Людоящеры берут свои имена из драконьего языка. Они используют простые определения, даваемые племенем, основанные на известных делах и поступках. Например, Гарурт переводится как «топор»: имя, данное воину людоящеров, который победил орка и завладел его оружием. Людоящера, которому нравится скрываться в зарослях тростника, чтобы заманить в засаду животное, можно было бы назвать Ачуак, что означает «зелёный», описывая, как она смешивается с листвой.

Людоящеры не делают различия между мужскими и женскими именами. Каждое приведенное в качестве примера имя дано с переводом в круглых скобках.

# Имена людоящеров

Арит (война), Ачуак (зеленый), Биэшра (животное), Варгач (сражение), Вертича (гора), Вилигнат (ожог), Вит (сталь), Вута (чёрный), Гарурт (топор), Дарастрикс (дракон), Чанк (молоток), Ирхтос (тайна), Кепеск (шторм), Кетенд (самоцвет), Корт (опасность), Косч (небольшой), Котар (демон), Литрикс (броня), Миирик (песня), Отокент (умный), Саурив (глаз), Троден (много), Туркер (ночь), Уск (железо).`,
    source: {
      id: SOURCE_VGTM,
      page: 114,
    },
  },
  {
    header: 'Речь людоящеров',
    text: `Людоящеры могут отлично владеть Всеобщим языком, но в следствие особенностей их склада ума, структура их речи отличается от других гуманоидов.

Людоящеры редко используют метафоры. Их речь почти всегда буквальна. Они могут понимать идиомы, но с некоторыми трудностями.

Имена путают их, если они не являются описательными. Они склонны давать собственные имена согласно своим традициям другим существам, используя слова Всеобщего языка.

Людоящеры используют активные глаголы, чтобы описывать мир. Людоящер в холодную погоду может сказать, _«Этот ветер приносит холод»_ вместо _«Мне холодно»_. Людоящеры склонны определять вещи с точки зрения действий, а не результатов.`,
    source: {
      id: SOURCE_VGTM,
      page: 114,
    },
  },
]
