const {LANG_TYPE_DEAD} = require('./../../languageTypeList')
const {ALPHABET_OLD_OMUAN} = require('./../../alphabetList')
const {LANG_OLD_OMUAN} = require('./../../languageIdList')
const {
  PLACE_CHULT,
  PLACE_OMU,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_OLD_OMUAN,
  alphabetId: ALPHABET_OLD_OMUAN,
  type: LANG_TYPE_DEAD,
  nameEn: 'Old Omuan',
  name: {
    nominative: 'Древнеомуанский',
    genitive: 'Древнеомуанского',
    instrumental: 'Древнеомуанским',
    prepositional: 'Древнеомуанском',
  },
  typicalSpeakers: 'Древние омуанцы',
  spokenPlaceList: [
    PLACE_CHULT,
    PLACE_OMU,
  ],
  isReady: false,
  isRealLang: true,
}
