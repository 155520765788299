const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_15,
} = require('./../../../../crList')
const {
  SIZE_HUGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DRAGON_METALLIC,
  CREATURE_TYPE_DRAGON_TRUE,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LG,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  bronzeDragonDescriptionList,
  dragonsBronzeNoteList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_CHANGE_SHAPE,
  ABILITY_DETECT,
  ABILITY_FRIGHTFUL_PRESENCE,
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_TAIL_ATTACK,
  ABILITY_WING_ATTACK,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const dragonBreatheWeaponCollection = require('./../../../dragonBreatheWeaponCollection')
const {
  CREATURE_DRAGON_BRONZE_ADULT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Взрослый бронзовый дракон',
  nameEn: 'Adult Bronze Dragon',
  id: CREATURE_DRAGON_BRONZE_ADULT,
  description: bronzeDragonDescriptionList,
  note: dragonsBronzeNoteList,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAGON_TRUE,
    CREATURE_TYPE_DRAGON_METALLIC,
  ],
  alignmentId: ALIGNMENT_LG,
  source: {
    id: SOURCE_MM,
    page: 122,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 80,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 25,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 23,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  cr: CR_15,
  languageList: [
    LANG_DRACONIC,
    LANG_COMMON,
  ],
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_LEGENDARY_RESISTANCE_3,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ может использовать _Ужасающее присутствие_. Затем он совершает три атаки: одну _Укусом_, и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 7,
        },
      },
    },
    {
      id: ABILITY_FRIGHTFUL_PRESENCE,
      range: 120,
      witSave_DC: 17,
    },
    {
      id: ABILITY_CHANGE_SHAPE,
      nextForm: `гуманоида или зверя, чей показатель опасности не превышает его собственный`,
      gearMerge: `сливается с новым обликом или используется им (на ★его★ выбор)`,
      statChange: `В новом облике ★СУЩЕСТВО★ сохраняет мировоззрение, хиты, Кости Хитов, способность говорить, владения, Легендарные действия, действия логова, значения Интеллекта, Мудрости и Харизмы, а также действия. Все остальные характеристики и умения заменяются характеристиками и умениями нового облика, за исключением классовых умений и легендарных действий нового облика`,
    },
    dragonBreatheWeaponCollection[CREATURE_DRAGON_BRONZE_ADULT],
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    ABILITY_DETECT,
    ABILITY_TAIL_ATTACK,
    {
      id: ABILITY_WING_ATTACK,
      cost: 2,
      range: 15,
      dexSave_DC: 20,
      damage: '14 (2к6 + 7)',
    },
  ],
  genderId: GENDER_MALE,
}
