const {CREATURE_AIR_ELEMENTAL} = require('./../../../../creatureIdList')
const {GEAR_BOTTLE_GLASS} = require('./../../../../gearIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {MAGIC_ITEM_BOTTLED_BREATH} = require('./../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_POTION} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPELL_GUST_OF_WIND} = require('./../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_BOTTLED_BREATH,
  name: 'Бутилированное дыхание',
  nameEn: 'Bottled Breath',
  type: MGC_TYPE_POTION,
  gearType: GEAR_BOTTLE_GLASS,
  rarity: MGC_RARITY_UNCOMMON,
  isConsumable: true,
  description: `В этой бутылке содержится дыхание [воздушного элементаля](CREATURE:${CREATURE_AIR_ELEMENTAL}). Проглотив его, вы можете либо выдохнуть его, либо удержать в себе.

В первом случае Вы создаёте эффект, подобный заклинанию [Порыв ветра](SPELL:${SPELL_GUST_OF_WIND}).

Во втором случае Вы можете в течение 1 часа обходиться без дыхания. При желании Вы можете окончить этот эффект преждевременно (например, если захотите что-нибудь сказать), но эффект выдоха уже не сработает.`,
  genderId: GENDER_MIDDLE,
  source: {
    id: SOURCE_PotA,
    page: 227,
  },
}
