const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    GEAR_RATIONS,
  } = require('./../../../../gearIdList'),
  {
    CR_0,
  } = require('./../../../../crList'),
  {
    SIZE_TINY,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_FEY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_HISTORY,
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    LANG_COMMON,
    LANG_SYLVAN,
    LANG_TELEPATHY,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_KILLMOULIS,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Кильмулис',
  nameEn: 'Killmoulis',
  id: CREATURE_KILLMOULIS,
  description: `Кильмулис может быть как проклятьем, так и благом для приключенческой группы. Когда его кормят вкусными угощениями, это существо является полезным помощником, который чинит доспехи, находит травы, превращающие самые скудные рационы в пир, и создаёт гостеприимное пристанище из самого унылого и неудобного лагеря. Но если отряд использует кульмулиса или пренебрегает им, то подлая месть этого существа может превратить даже самую простую экспедицию в истощающее испытание, делающее всех участников усталыми, подавленными и угрюмыми.

**Путешествующий фей.** Всякий раз, когда кто-то совершает акт благотворительности, помогая усталому или потерянному путешественнику, в Стране Фей спонтанно появляется кильмулис. Выйдя из тени рядом с местом действия, существо отправляется в путь, бродя в поисках путешественников. У них которых может быть еда, которую он может утащить в обмен на преимущества своей магии. Застенчивые и одинокие от природы, кильмулисы любят наблюдать и слушать людей из отдаленных мест, о которых они мало знают. Будь то яростные налётчики, предприимчивые торговцы или смелые искатели приключений, все путешественники могут предложить ему что-нибудь интересное.

**Голодные помощники.** Несмотря на своё странное появление и происхождение, кильмулисы хотят тех же удобств, что и обычные люди. Они обожают сладкие конфеты, свежие фрукты и другие деликатесы. Когда кильмулис сталкивается с группой путешественников, он прячется и внимательно следит за ними. Ночью он пробирается в лагерь путешественников и ищет вкусности в их запасах, но удоволетворится и [сухпайками](GEAR:${GEAR_RATIONS}), орехами и другими закусками. Если он их находит, то быстро засысывает длинным носом целую дневную порцию. В обмен на это он использует свою магию для повышения комфорта путешественников во время отдыха.

**Горький позор.** Кильмулис пойдёт за любой группой, у которой он добыл вкусные закуски, каждый вечер проскальзывая в лагерь, чтобы наесться и заплатить за свой пир полезной магией. Но если путешественники нападают, вмешиваются или пытаются поймать кульмулиса в ловушку, он накладывает небольшое проклятие на группу и пытается сбежать. На протяжении всего путешествия персонажей их сухпайки на вкус напоминают пепел, а любые места для лагеря кишат вшами и кусачими насекомыми. Все члены отряда просыпается больными и уставшими, поскольку камни или острые палки как-то оказываются у них в спальниках.

Если кильмулис зол, он продолжит преследовать группу и накладывать своё проклятие. Только предложение хорошей еды и искренние извинения могут успокоить кульмулиса. Без этого эти существа будут держать обиду на грубое поведение группы в течение нескольких дней.`,
  note: [
    {
      text: `Кульмулис может быть щедрым, но не ожидайте что его доброжелательность продлится хоть одно мгновение после того, как у вас закончатся вкусности`,
      author: `Морденкайнен`,
    },
  ],
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 13,
  },
  armor: 14,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 13,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_SYLVAN,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_0,
  actionList: [
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: -1,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Благословение обильной щедрости',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ выбирает до 8 видимых в пределах 100 футов от себя существ. В следующий раз, когда они закончат длинный отдых, они восстановят все кости хитов и получат 10 временных хитов.`,
    },
    {
      name: 'Проклятие дурного гостепреимства',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ выбирает до 8 видимых в пределах 100 футов от себя существ. В следующий раз, когда они закончат длинный отдых, они не восстановят кости хитов.`,
    },
  ],
}
