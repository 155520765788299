const { SPELL_WISH } = require('./../../../../spellIdList')
const {
  CREATURE_LICH,
  CREATURE_ORCUS,
} = require('./../../../../creatureIdList')
const {
  MAGIC_ITEM_EYE_OF_VECNA,
  MAGIC_ITEM_HAND_OF_VECNA,
  MAGIC_ITEM_SWORD_OF_KAS,
} = require('./../../../../magicItemIdList')

const eyeAndHandOfVecna = {
  preText: `Имя Векны почти всегда произносят шёпотом. В своё время он был одним из самых могущественных волшебников. Благодаря завоеваниям и тёмной магии он создал ужасную империю. Однако, несмотря на всю свою мощь, Векна оставался смертным. Он начал бояться смерти и предпринимать меры, чтобы максимально отдалить свой конец.

[Оркус](CREATURE:${CREATURE_ORCUS}), демонический повелитель нежити, научил Векну ритуалу, который позволил жить в качестве [лича](CREATURE:${CREATURE_LICH}). Совершив его, тот стал могущественнейшим из всех [личей](CREATURE:${CREATURE_LICH}). Несмотря на иссохшее и разлагающееся тело, Векна продолжил расширять свои владения. Характер его был таким ужасным, что подчинённые боялись произнести его имя. Он был Шепчущим, Владыкой Паучьего Трона, Неумирающим Королём и Владыкой Гниющей Башни.

Одни говорят, что полководец Векны, Кас, сам возжелал занять Паучий Трон, другие утверждают, что поднять восстание Каса уговорил [меч](MAGIC_ITEM:${MAGIC_ITEM_SWORD_OF_KAS}), созданный его же владыкой. Какова бы ни была причина, но Кас окончил правление Неумирающего Короля в ужасном сражении, которое превратило башню Векны в горстку пепла. От Векны остались лишь одна [рука](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}) и один [глаз](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}), жуткие артефакты, всё ещё воплощающие волю Шепчущего.`,
  postText: `Если Вы настроены одновременно на [Глаз](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) и на [Руку](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}), Вы получаете следующие дополнительные преимущества:

* Вы получаете иммунитет к болезням и ядам.
* Использование _Проникающего зрения_ [Глаза](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) не подвергает Вас истощению. 
* Вы предчувствуете опасность, и если Вы дееспособны, Вы не можете быть захвачены врасплох.
* Если Вы начинаете ход с как минимум 1 хитом, Вы восстанавливаете 1к10 хитов.
* Если у существа есть скелет, Вы можете попытаться превратить его кости в желе прикосновением [Руки Векны]. Вы совершаете действием рукопашную атаку по существу, к которому можете прикоснуться, используя на свой выбор бонус рукопашной атаки или для оружия или для заклинания. При попадании цель должна пройти испытание Телосложения СЛ 18, иначе её хиты опускаются до 0.
* Вы можете действием наложить заклинание [Исполнение желаний](SPELL:${SPELL_WISH}). Это свойство нельзя использовать повторно, пока не пройдёт 30 дней.

# Уничтожение [Глаза](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) и [Руки](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA})

Если [Глаз Векны](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) и [Рука Векны](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}) приросли к одному и тому же существу, и это существо убивается [Мечом Каса](MAGIC_ITEM:${MAGIC_ITEM_SWORD_OF_KAS}), и [Глаз](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) и [Рука](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}) вспыхивают, превращаются в пепел и уничтожаются навсегда. Любые другие попытки уничтожить [Глаз](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) и [Руку](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}) кажутся успешными, но артефакты восстанавливаются в одном из многочисленных тайных хранилищ Векны, где снова ждут, пока их найдут.`,
}

module.exports = eyeAndHandOfVecna
