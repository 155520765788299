import React from 'react'
import PropTypes from 'prop-types'

import HeaderBlockComponent from './HeaderBlockComponent'

const HeaderBlockContainer = (
  {
    children,
    ...rest
  },
) => children
  ? (
    <HeaderBlockComponent
      children={children}
      {...rest}
    />
  )
  : null

HeaderBlockContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
}

HeaderBlockContainer.defaultProps = {
  children: null,
}

export default HeaderBlockContainer
