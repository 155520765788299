const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SIZE_MEDIUM,
  SIZE_TINY,
} = require('./../../../../sizeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ABILITY_SWARM} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_SWARM_OF_ROT_GRUBS} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Рой трупных личинок',
  nameEn: 'Swarm of Rot Grubs',
  id: CREATURE_SWARM_OF_ROT_GRUBS,
  description: `Трупные личинки — личинки размером с палец, которые едят живую или мёртвую плоть, хотя они могут выжить, питаясь растениями. Они наводняют трупы и кучи гнилья и нападают на живых существ, потревоживших их. Вгрызаясь в плоть живого существа, трупная личинка, инстинктивно, проедает себе путь к сердцу, чтобы убить своего носителя.

Трупные личинки представляют угрозу как отдельно, так и роем. Смотри приведённый статблок с механикой роя трупных личинок. У отдельной личинки гнили нет никакого статблока. Любое существо, которое входит в контакт с ней, должно пройти испытание Ловкости СЛ 10. При провале испытания, трупная личинка вгрызается в плоть существа и наносит колющий урон 3 (1к6) в начале каждого хода существа-носителя. Если прижечь рану огнём до конца следующего хода существа-носителя, носитель получит урон огнём 1, а трупная личинка, пролезшая в рану, умрёт. По истечении этого времени трупная личинка заползёт слишком глубоко под кожу носителя, чтобы её сжечь.

Если существо, заражённое одной или несколькими трупными личинками, заканчивает свой ход с 0 хитами, оно умирает, поскольку личинки добираются до его сердца и убивают его. Любой эффект, который вылечивает болезни, убивает все трупные личинки, заразившие цель. Сжигание тела убивает все трупные личинки, находящиеся в нём.`,
  sizeType: SIZE_MEDIUM,
  swarmMemberSizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 210,
  },
  armor: 8,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_CLIMB]: 5,
  },
  params: {
    [PARAM_STR]: 2,
    [PARAM_DEX]: 7,
    [PARAM_CON]: 10,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 2,
    [PARAM_CHA]: 1,
  },
  resistanceList: [
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 10,
    },
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_SWARM,
  ],
  actionList: [
    {
      name: 'Укусы',
      description: ``,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 0,
        range: 0,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            comment: ` в пространстве роя`,
          },
        },
        hit: `в цель проникает 1к4 трупных личинок. В начале каждого своего хода, цель получает колющий урон 1к6 за каждую трупную личинку в нём. Если прижечь огнём рану от _Укуса_ до конца следующего хода цели, цель получает урон огнём 1 и убивает эти трупные личинки. По истечении этого времени эти трупные личинки заползают слишком глубоко под кожу носителя, чтобы их сжечь. Если цель, заражённая трупными личинками, заканчивает свой ход с 0 хитами, она умирает, так как трупные личинки добрались до её сердца и убили её. Любой эффект, который вылечивает болезни, убивает все трупные личинки, заразившие цель.`,
      },
    },
  ],
}
