const {
  SPELL_ARMS_OF_HADAR,
  SPELL_CHILL_TOUCH,
  SPELL_DETECT_MAGIC,
  SPELL_DISGUISE_SELF,
  SPELL_DISPEL_MAGIC,
  SPELL_ELDRITCH_BLAST,
  SPELL_HOLD_PERSON,
  SPELL_HUNGER_OF_HADAR,
  SPELL_INVISIBILITY,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_SPIDER_CLIMB,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {ABILITY_TURN_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_DEATHLOCK} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {deathlockDescription} = require('./../../../../textCommonParts')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дэтлок',
  nameEn: 'Deathlock',
  id: CREATURE_DEATHLOCK,
  description: deathlockDescription,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 197,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 10,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_4,
  featureList: [
    ABILITY_TURN_RESISTANCE,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DETECT_MAGIC,
            SPELL_DISGUISE_SELF,
            SPELL_MAGE_ARMOR,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 5,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_CHILL_TOUCH,
            SPELL_ELDRITCH_BLAST,
            SPELL_MAGE_HAND,
          ],
        },
        {
          preText: `1–3 уровни (2 ячейки 3-го уровня)`,
          list: [
            SPELL_ARMS_OF_HADAR,
            SPELL_DISPEL_MAGIC,
            SPELL_HOLD_PERSON,
            SPELL_HUNGER_OF_HADAR,
            SPELL_INVISIBILITY,
            SPELL_SPIDER_CLIMB,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      name: 'Смертельные когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
