const {
  WEAPON_HEAVY,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_BLUDGEONING} = require('./../../../damageTypeList')
const {GEAR_MAUL} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
} = require('./../../../sourceList')

module.exports = {
  id: GEAR_MAUL,
  genderId: GENDER_MALE,
  name: 'Двуручный молот',
  nameByCase: {
    nominative: 'двуручный молот',
    genitive: 'двуручного молота',
    accusative: 'двуручный молот',
    instrumental: 'двуручным молотом',
  },
  nameAlt: [
    'Молот',
    'Кувалда',
  ],
  nameEn: 'Maul',
  description: {
    header: 'Двуручный молот',
    text: 'Такие молоты часто используются в состязаниях дварфов — например, кто дальше забросит молот, выпив больше всех эля.',
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  damage: {
    diceType: 6,
    diceCount: 2,
  },
  cost: 1000,
  damageType: DAMAGE_BLUDGEONING,
  weight: 10,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    WEAPON_HEAVY,
    WEAPON_TWO_HANDED,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
