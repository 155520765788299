const {CREATURE_WATER_ELEMENTAL} = require('./../../../../creatureIdList')
const {GEAR_TRIDENT} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_AQUAN} = require('./../../../../languageIdList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {
  SPELL_DOMINATE_MONSTER,
  SPELL_GREATER_RESTORATION,
} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_DEVASTATION_ORB_WATER,
  MAGIC_ITEM_DROWN,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_DROWN,
  name: 'Топитель',
  nameEn: 'Drown',
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_TRIDENT,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: [
    {
      header: 'Топитель',
      text: `_Топитель_ — это стальной трезубец, содержащий в себе частичку силы Ольгидры, Принцессы Злой Воды. Верхняя часть его древка украшена бронзовыми ракушками, на конце рукояти красуется серебряная раковина, а чуть ниже острия видна вставленная внутрь зеленоватая жемчужина. Если трезубец бросить в воду, то он останется лежать на её поверхности, а если его выпустить из рук под водой, то он не пойдёт ко дну, а зависнет на месте. Трезубец всегда холоден на ощупь, и его не портит даже длительное нахождение в воде.

Вы получаете +1 к броскам атаки и урона этим магическим оружием. При попадании цель получает дополнительный урон холодом 1к8.`,
      source: {
        id: SOURCE_PotA,
        page: 230,
      },
    },
    {
      header: 'Власть над водой',
      text: `Если Вы вооружены _Топителем_, то получаете следующие преимущества:

* Вы свободно говорите на [Акване](LANG:${LANG_AQUAN}).
* Вы обладаете сопротивление к урону холодом.
* Вы можете сотворить заклинание [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}) (СЛ 17) на [водного элементаля](CREATURE:${CREATURE_WATER_ELEMENTAL}). Если Вы сотворили это заклинание, то не сможете вновь использовать данную особенность до следующего рассвета.`,
      source: {
        id: SOURCE_PotA,
        page: 230,
      },
    },
    {
      header: 'Слёзы бесконечных страданий',
      text: `Находясь в стихийном узле воды, Вы, с помощью _Топителя_, можете совершить ритуал под названием «Слёзы бесконечных страданий», который позволит Вам создать [водную сферу опустошения](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_WATER}). После окончания ритуала Вы не сможете повторить его до следующего рассвета.`,
      source: {
        id: SOURCE_PotA,
        page: 230,
      },
    },
    {
      header: 'Слабость',
      text: `Топитель делает своего владельца алчным. Пока существо настроено на это оружие, оно получает следующую слабость: _«Я по праву требую самую большую долю добычи и отказываюсь расстаться со всем, что принадлежит мне»_.

Кроме того, если Вы настроены на _Топитель_ в течение 24 часов подряд, на Вашей коже начинают расти ракушки. Они могут быть удалены с помощью заклинания [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) или аналогичной магии, но не тогда, когда Вы настроены на это оружие.`,
      source: {
        id: SOURCE_PotA,
        page: 230,
      },
    },
  ],
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PotA,
    page: 230,
  },
}
