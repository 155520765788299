const {SOURCE_MM} = require('./../../sourceList')

module.exports = {
  header: 'Элементали',
  text: `Элементали — это воплощения стихий, которые составляют вселенную: воздух, земля, огонь и вода. Являясь чуть более, чем живой энергией на своих родных планах существования, они могут быть призваны заклинателями и могущественными существами, чтобы принять определённую форму и исполнять поручения.

# Живые стихии

На своём родном плане, элементаль — бестелесная жизненная сила. Его тусклая искра сознания проявляется в виде какой-то формы только когда она сфокусирована силой магии. У дикого духа стихийной силы нет никаких желаний кроме как находиться на своём родном плане. Как у зверей Материального плана, у стихийных духов нет ни общества, ни культуры, ни смысла жизни.

# Призванные магией

Определённые заклинания и магические предметы могут вызывать элементаля, призывая его с Внутренних Планов на Материальный. Элементали инстинктивно негодуют, когда их забирают с их родных планов и заставляют служить. Существо, которое призывает элементаля, должно обладать достаточной силой воли, чтобы управлять им.

# Связанные и сформированные

Могучая магия может привязать стихийный дух к материальной заготовке, которая определит их специфические функции и методы использования. Невидимые охотники — это воздушные элементали, привязанные к специфической форме, также как водяные элементали могут быть сформированы в водные аномалии.

Сила магии и материалы, которые используются при привязке, определяют, насколько хорошо элементали будут функционировать в связанной форме. Големы — это стихийные духи, привязанные к физической форме, но более слабые материалы, такие как плоть и глина, не могут в достаточной мере связать стихийную силу. Долговечные же материалы, такие как камень и железо, требуют более сильной магии, но она более надёжно связывает элементаля.

# Стихийная натура

Элементалю не нужен воздух, еда, питьё и сон.`,
  source: {
    id: SOURCE_MM,
    page: 302,
  },
}
