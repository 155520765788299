const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  GOD_ANNAM,
  GOD_MEMNOR,
} = require('./../../godIdList')
const {
  CREATURE_CLOUD_GIANT,
  CREATURE_CLOUD_GIANT_SMILING_ONE,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Дети Обманщика',
    text: `Покровительствующее божество и отец [облачных великанов](CREATURE:${CREATURE_CLOUD_GIANT}) — [Мемнор Обманщик](GOD:${GOD_MEMNOR}), умнейший и коварнейший из всех божеств великанов. [Облачные великаны](CREATURE:${CREATURE_CLOUD_GIANT}) ориентируются на деяния [Мемнора](GOD:${GOD_MEMNOR}) и равняются на него. Злые великаны при этом подражают его двуличности и своенравности, а добрые любят его за интеллект и красноречие. Члены одной семьи, как правило, следуют одним курсом.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Два лика Мемнора',
    text: `Главное божество [облачных великанов](CREATURE:${CREATURE_CLOUD_GIANT}) — [Мемнор](GOD:${GOD_MEMNOR}), самый умный из всех отпрысков [Аннама](GOD:${GOD_ANNAM}). Но [Мемнор](GOD:${GOD_MEMNOR}) не только умён, он хитер и обманчив. Истории о его похождениях делают упор на его харизме, сладкоречивости и способности манипулировать и управлять своими братьями и другими легендарными фигурами с целью заставить их делать именно то, что ему нужно, и как правило, большой для них ценой.

Таким образом, для [облачных великанов](CREATURE:${CREATURE_CLOUD_GIANT}) есть два аспекта [Мемнора](GOD:${GOD_MEMNOR}), которые они почитают, и которым подражают. Великаны с добрым нравом уважают его за его шарм, ум и убедительность, а те, кто более склонен ко злу, имитируют его эгоизм и плутовство. [Облачных великанов](CREATURE:${CREATURE_CLOUD_GIANT}), которые особенно интересуются его плутовским аспектом, называют «[улыбающиеся](CREATURE:${CREATURE_CLOUD_GIANT_SMILING_ONE})»; они носят двуликие маски, практикуя свой обман, и наживаются на тех, кто будет восприимчив к их очарованию.`,
    source: {
      id: SOURCE_VGTM,
      page: 27,
    },
  },
]
