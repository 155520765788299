const {
  SPELL_BIGBY_S_HAND,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CREATION,
  SPELL_ENLARGE_REDUCE,
  SPELL_FOG_CLOUD,
  SPELL_LEGEND_LORE,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_POLYMORPH,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_SLEEP,
} = require('./../../../spellIdList')
const {
  FEATURE_COLLECTOR_S_CALL_2020_01_14,
  FEATURE_COLLECTOR_S_VESSEL_2020_01_14,
  FEATURE_ELEMENTAL_RESISTANCE_2020_01_14,
  FEATURE_EXPANDED_SPELLS_WARLOCK_THE_NOBLE_GENIE_2020_01_14,
  FEATURE_GENIE_S_ENTERTAINMENT_2020_01_14,
  FEATURE_PACT_BOON,
  FEATURE_PACT_OF_THE_TOME,
  FEATURE_PROTECTIVE_WISH_2020_01_14,
} = require('./../../../featureIdList')
const {
  CREATURE_DAO,
  CREATURE_DJINNI,
  CREATURE_EFREETI,
  CREATURE_MARID,
} = require('./../../../creatureIdList')
const {PC_SUBCLASS_WARLOCK_THE_NOBLE_GENIE_2020_01_14} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_01_14_SUBCLASSES_PART_1} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_WARLOCK_THE_NOBLE_GENIE_2020_01_14,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    text: `Благородный гений позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_BIGBY_S_HAND,
      SPELL_CREATE_FOOD_AND_WATER,
      SPELL_CREATION,
      SPELL_ENLARGE_REDUCE,
      SPELL_FOG_CLOUD,
      SPELL_PHANTASMAL_FORCE,
      SPELL_PHANTASMAL_KILLER,
      SPELL_POLYMORPH,
      SPELL_PROTECTION_FROM_ENERGY,
      SPELL_SLEEP,
    ],
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 4,
    },
  },
  {
    id: FEATURE_COLLECTOR_S_VESSEL_2020_01_14,
    name: `Сосуд коллекционера`,
    nameEn: `Collector’s Vessel`,
    lvl: 1,
    text: `Покровитель даёт Вам магический сосуд, которым Вы можете привязывать существ к себе и к зверинцу Вашего покровителя.

Сосуд — это Крохотный предмет, и Вы можете использовать его как заклинательную фокусировку. Вы выбираете что это за предмет, либо можете определить это случайно, броском по таблице «Сосуды Коллекционера».

# Сосуды Коллекционера

| к6 | Сосуд                 |
|----|-----------------------|
| 1  | Масляная лампа        |
| 2  | Урна                  |
| 3  | Кольцо с секретом     |
| 4  | Запечатанная бутыль   |
| 5  | Полая статуэтка       |
| 6  | Изысканный светильник |

Если Вы потеряете свой сосуд, Вы можете провести часовую церемонию, чтобы получить замену от покровителя. Эту церемонию можно провести во время короткого или длинного отдыха и она уничтожает предыдущий сосуд. Если Вы умираете, сосуд исчезает в стихийном пламени.

Держа сосуд, Вы можете действием выбрать видимое вами в пределах 100 футов согласное существо и создать связь из тончайшего стихийного материала, связывающую цель с Вами. Связь длится до 1 часа, или пока Вы не используете эту способность на другом существе, создавая новую связь, или пока цель не достигнет 0 хитов, или пока цель не закончит свой ход дальше чем в 100 футах от Вас.

Пока Вы привязаны к существу, Вы получаете следующие преимущества.

* Вы получаете бонус к проверкам Мудрости (Внимательность), равный своему модификатору Харизмы (минимум +1).
* Когда Вы сотворяете заклинание, Вы можете сотворять его как из своего пространства, так и из пространства привязанного существа.

Вы можете создавать такую связь число раз, равное Вашему модификатору Харизмы (минимум один раз) и восстанавливаете все использования, завершив длинный отдых.
 
# Сосуд Коллекционера и Предметы договора

Связь, создаваемая Сосудом Коллекционера, принимает форму, отражающую тип гения Вашего покровителя: кружащийся ветром туман для [джинна](CREATURE:${CREATURE_DJINNI}), песок и камешки для [дао](CREATURE:${CREATURE_DAO}), дым и искры для [ифрита](CREATURE:${CREATURE_EFREETI}), и пенящаяся вода для [марида](CREATURE:${CREATURE_MARID}).

Если умение 3 уровня _Предмет Договора_ даёт Вам физический предмет, Ваш сосуд превращается в этот предмет. Например, колдун, чей покровитель — благородный ифрит, и который выбрал [Предметом договора](FEATURE:${FEATURE_PACT_BOON}) [гримуар](FEATURE:${FEATURE_PACT_OF_THE_TOME}), может обнаружить что его масляная лампа превратилась в искусный свиток из крыла красного дракона, с медными и железными роликами.`,
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 5,
    },
  },
  {
    id: FEATURE_ELEMENTAL_RESISTANCE_2020_01_14,
    name: `Сопротивление стихиям`,
    nameEn: `Elemental Resistance`,
    lvl: 6,
    text: `Ваш покровитель дарует Вам защиту от стихии. Заканчивая длинный отдых, Вы получаете сопротивление к кислоте, холоду, огню или электричеству (по Вашему выбору) до конца своего следующего длинного отдыха.

Пока связь Вашего [Сосуда коллекционера](FEATURE:${FEATURE_COLLECTOR_S_VESSEL_2020_01_14}) активна, привязанное существо тоже получает это сопротивление.`,
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 5,
    },
  },
  {
    id: FEATURE_PROTECTIVE_WISH_2020_01_14,
    name: `Желание защиты`,
    nameEn: `Protective Wish`,
    lvl: 10,
    text: `Теперь Вы можете пожелать защитить [Сосудом коллекционера](FEATURE:${FEATURE_COLLECTOR_S_VESSEL_2020_01_14}) себя или привязанное существо.

Если по Вам или привязанному существу попадают атакой, Вы можете реакцией телепортироваться, меняясь с ним местами и выбирая, по кому из вас двоих попадут этой атакой.`,
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 5,
    },
  },
  {
    id: FEATURE_GENIE_S_ENTERTAINMENT_2020_01_14,
    name: `Развлечение гения`,
    nameEn: `Genie’s Entertainment`,
    lvl: 10,
    text: `Действием, Вы можете попытаться послать видимое Вами в пределах 90 футов существо ко двору своего покровителя.

Цель должна пройти испытание Харизмы или её магически засосёт в Ваш [Сосуд](FEATURE:${FEATURE_COLLECTOR_S_VESSEL_2020_01_14}) и телепортирована ко двору Вашего покровителя на Стихийном плане. Будучи там, цель ошеломлена, а Ваш покровитель развлекается, разглядывая её, но не причиняет ей вреда. Цель может повторять испытание в конце каждого своего хода, при успехе возвращаясь в пространство, которое занимала, или ближайшее свободное к нему.

Использовав эту способность, Вы не сможете использовать её вновь, пока не завершите длинный отдых. Если цель оставалась при дворе Вашего покровителя в течение 1 минуты, гений вернёт её назад, как если бы она преуспела в испытании, и Вы восстанавливаете использование этой способности.`,
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 6,
    },
  },
  {
    id: FEATURE_COLLECTOR_S_CALL_2020_01_14,
    name: `Зов коллекционера`,
    nameEn: `Collector’s Call`,
    lvl: 14,
    text: `Взамен на расширение влияния Вашего покровителя в мультивселенной, Вы получаете больше его сил.

Действием, Вы можете умолять своего покровителя о помощи, совершая проверку Харизмы (Убеждение) против своей собственной СЛ заклинаний. При успехе Вы можете выбрать один из следующих эффектов.

* Видимое Вами в пределах 60 футов существо восстанавливает 8к6 хитов и излечивается от болезни или прекращает одно из состояний: Слепота, Очарованность, Глухота, Испуг, Паралич, Отравление.
* Видимое Вами в пределах 60 футов существо получает помеху к броскам атаки и испытаниям до начала Вашего следующего хода
* Вы сотворяете заклинание [Знание легенд](SPELL:${SPELL_LEGEND_LORE}) без материальных компонентов.

Независимо от того, преуспели ли Вы в проверке или нет — Вы не сможете использовать эту способность вновь, пока не завершите длинный отдых. Альтернативно, Вы можете восстановить использование этой способности, жертвуя покровителю немагическое сокровище стоимостью от 500 зм. Для этого сокровище должно находиться в пределах 10 футов от Вас в течение 1 минуты, в конце которой Вы, держа в руках Сосуд Коллекционера, действием телепортируете его во владения своего покровителя.`,
    spellIdList: SPELL_LEGEND_LORE,
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 6,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_NOBLE_GENIE_2020_01_14,
  })
)

