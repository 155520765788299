const {PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE} = require('./../../../pcSubClassIdList')
const {
  SOURCE_MOOT,
  SOURCE_TCoE,
} = require('./../../../sourceList')
const {
  FEATURE_BARDIC_INSPIRATION,
  FEATURE_INFECTIOUS_INSPIRATION,
  FEATURE_SILVER_TONGUE,
  FEATURE_UNFAILING_INSPIRATION,
  FEATURE_UNIVERSAL_SPEECH,
  FEATURE_UNSETTLING_WORDS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_SILVER_TONGUE,
    name: `Острый язык`,
    nameEn: `Silver Tongue`,
    lvl: 3,
    text: `Вы мастер говорить нужные вещи в нужное время. Когда Вы совершаете проверку Харизмы (Убеждение) или Харизмы (Обман), то можете считать результат броска к20, равный 9 или меньше, как 10.`,
    source: [
      {
        id: SOURCE_MOOT,
        page: 29,
      },
      {
        id: SOURCE_TCoE,
        page: 38,
      },
    ],
  },
  {
    id: FEATURE_UNSETTLING_WORDS,
    name: `Волнующие слова`,
    nameEn: `Unsettling Words`,
    lvl: 3,
    text: `Вы можете вворачивать магию в свои слова, сбивая с толку существ и заставляют их сомневаться в себе.

Бонусным действием Вы можете потратить одно использование своего [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и выбрать одно видимое Вами в пределах 60 футов существо. Бросьте кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}). Существо должно вычесть выпавшее число из следующего испытания, которое оно совершит до начала Вашего следующего хода.`,
    source: [
      {
        id: SOURCE_MOOT,
        page: 29,
      },
      {
        id: SOURCE_TCoE,
        page: 38,
      },
    ],
  },
  {
    id: FEATURE_UNFAILING_INSPIRATION,
    name: `Безотказное вдохновение`,
    nameEn: `Unfailing Inspiration`,
    lvl: 6,
    text: `Ваши вдохновляющие слова настолько убедительны, что другие чувствуют стремление к успеху.

Когда существо добавляет одну из Ваших костей [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) к своей проверке характеристики, броску атаки или испытанию и проваливает бросок, существо может не тратить эту кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}).`,
    source: [
      {
        id: SOURCE_MOOT,
        page: 29,
      },
      {
        id: SOURCE_TCoE,
        page: 38,
      },
    ],
  },
  {
    id: FEATURE_UNIVERSAL_SPEECH,
    name: `Универсальная речь`,
    nameEn: `Universal Speech`,
    lvl: 6,
    text: `Вы получаете способность делать свою речь понятной для любого существа. Действием выберите одно или несколько существ в пределах 60 футов (но не больше Вашего модификатору Харизмы, минимум одно существо). Выбранные существа могут магическим образом понимать Вас, независимо от того, на каком языке Вы говорите, в течение 1 часа.

Использовав эту способность, Вы не можете использовать её снова, пока не закончите длинный отдых, или пока не потратите ячейку заклинания любого круга, чтобы использовать её снова.`,
    source: [
      {
        id: SOURCE_MOOT,
        page: 29,
      },
      {
        id: SOURCE_TCoE,
        page: 38,
      },
    ],
  },
  {
    id: FEATURE_INFECTIOUS_INSPIRATION,
    name: `Заразительное вдохновение`,
    nameEn: `Infectious Inspiration`,
    lvl: 14,
    text: `Успешно кого-то вдохновив, Вы можете распространить силу своего красноречия на кого-то другого.

Когда существо в пределах 60 футов от Вас добавляет одну из Ваших костей [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) к своей проверке характеристики, броску атаки или испытанию и преуспевает, Вы можете реакцией поощрить другое существо в пределах 60 футов (кроме себя), которое может Вас слышать, давая ему еость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}), не тратя использований [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}).

Вы можете использовать эту реакцию число раз, равное Вашему модификатору Харизмы (минимум один раз), и восстанавливаете все использования, заканчивая длинный отдых.`,
    source: [
      {
        id: SOURCE_MOOT,
        page: 29,
      },
      {
        id: SOURCE_TCoE,
        page: 39,
      },
    ],
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE,
  })
)


