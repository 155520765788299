const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_BLIGHT} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_BLIGHT,
  name: 'Усыхание',
  nameEn: 'Blight',
  description: `Некротическая энергия омывает выбранное Вами существо, которое Вы видите в пределах дистанции, забирая из него жидкость и жизненные силы. Цель должна пройти испытание Телосложения. Цель получает урон некротической энергией 8к8 в случае провала или половину этого урона в случае успеха. Это заклинание не оказывает никакого эффекта на нежить и конструктов. Если цель — растительное существо или магическое растение, оно проходит испытание с помехой, и заклинание причиняет ему максимальный урон. Если цель — немагическое растение, не являющееся существом, например, дерево или куст, оно не проходит испытание; а просто засыхает и умирает.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше четвертого.`,
  lvl: 4,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_NECROTIC,
      halfOnSuccess: true,
      diceCount: 8,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 287,
    },
    {
      id: SOURCE_SRD,
      page: 122,
    },
  ],
}
