module.exports = [
  require('./lamia'),
  require('./lemure'),
  require('./lich'),
  require('./lion'),
  require('./lizard'),
  require('./lizardfolk'),
  require('./lizardfolk_king_queen'),
  require('./lizardfolk_shaman'),
]
