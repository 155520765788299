const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  GEAR_LONGBOW,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {ABILITY_PETRIFYING_GAZE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_MEDUSA} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Медуза',
  nameEn: 'Medusa',
  description: [
    {
      header: 'Медузы',
      text: `Смертоносные и восхитительные медузы — существа с волосами из змей, навеки проклятые за своё тщеславие. В одиночестве они блуждают по руинам своих старых жилищ среди обломков былой жизни, окружённые окаменевшими телами обожателей и несостоявшихся героев.`,
      source: {
        id: SOURCE_MM,
        page: 202,
      },
    },
    {
      header: 'Вечное великолепие',
      text: `Мужчины и женщины, жаждущие вечной молодости, неувядающей красоты и всеобщего обожания, обычно возносят мольбы к богам, умоляют драконов даровать им древнюю магию или разыскивают всемогущих архимагов, способных исполнить их желание. Но некоторые идут и другим путём — они приносят жертвы демоническим повелителям или архидьяволам, предлагая им всё в обмен на этот дар, не зная, что вместе с даром получат и проклятье. Заключившие сделку обретают непревзойдённую физическую красоту и вечную молодость. Любой, кто увидит их, будет покорён их великолепием. Они обретают влияние, а вместе с ним и власть, которой жаждали. Годами живут они подобно полубогам среди смертных, но рано или поздно приходит расплата — и они навеки обращаются в медуз. Волосы их превращаются в клубок ядовитых змей, а любой, кто взглянет на них — каменеет, становясь молчаливым свидетелем их проклятья.`,
      source: {
        id: SOURCE_MM,
        page: 202,
      },
    },
    {
      header: 'Логово медузы',
      text: `Медузы обречены на вечное одиночество из-за их чудовищного обличья и свойства обращать всё живое в камень. Их когда-то великолепные дома с годами разрушаются, потихоньку превращаясь в руины, в лабиринт обломков и укрытий, наполненный тенями, поросший вьюном и колючками. Мародёры и искатели приключений, попавшие в логово медузы, зачастую не подозревают о том, кто здесь хозяин, до тех пор, пока не будет уже слишком поздно.

Медузы подвержены собственному проклятью. Если они взглянут на своё отражение, то превратятся в камень так же, как любое другое живое существо. Поэтому медузы избавляются от всех зеркал и отражающих поверхностей в своих логовах — разбив или просто выбросив.`,
      source: {
        id: SOURCE_MM,
        page: 202,
      },
    },
  ],
  id: CREATURE_MEDUSA,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 202,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_6,
  featureList: [
    {
      id: ABILITY_PETRIFYING_GAZE,
      dc: 14,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает либо три рукопашные атаки — одну _Змеиными волосами_ и две _Коротким мечом_ — либо две дальнобойные атаки _Длинным луком_.`,
    },
    {
      name: 'Змеиные волосы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 2,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 4,
            diceType: 6,
          },
        ],
      },
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_LONGBOW,
      attack: {
        hit: {
          type: DAMAGE_POISON,
          diceCount: 2,
          diceType: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
