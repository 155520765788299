const prepareForSearch = (text = '') => text
  .toString()
  .trim()
  .toLowerCase()
  .replace(/_/g, ' ')
  .replace(/Ё/g, 'Е')
  .replace(/ё/g, 'е')
  .replace(/[-.,:’́]/g, '') // Знак ударе́ния

module.exports = prepareForSearch
