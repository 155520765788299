const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ARCHDEVIL,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_DEAD,
  SPELL_BURNING_HANDS,
  SPELL_CONFUSION,
  SPELL_DETECT_MAGIC,
  SPELL_FLAME_STRIKE,
  SPELL_FLY,
  SPELL_GEAS,
  SPELL_MAJOR_IMAGE,
  SPELL_STINKING_CLOUD,
  SPELL_SUGGESTION,
  SPELL_SYMBOL,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  archDevilDescriptionList,
  archDevilNoteList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_REGENERATION,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_IMP,
  CREATURE_MOLOCH,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_21} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Молох',
  nameEn: 'Moloch',
  id: CREATURE_MOLOCH,
  hasSpoilers: true,
  description: [
    `Изгнанный из Девяти Преисподних, Молох сделает что угодно, чтобы вернуть своё место. Давным-давно, Молох заслужил быть среди архидьяволов за счёт своих заслуг по изгнанию демонов из Девяти Преисподних. Асмодей наградил Молоха, повысив того до правителя Малболга.

Веками Молох правил в своих владениях, борясь с другими архидьяволами, стремясь заполучить ещё большую власть. Такая враждебность была на пользу Асмодею, поскольку он знал, что такие козни Молоха помогали ему испытывать прочих архидьяволов. Такие меры перестали срабатывать, когда Молох взял в своё распоряжение советника, ночную ведьму по имени Малгард. Её слова отравляли, постепенно она убедила Молоха направить все силы, чтобы свергнуть Асмодея. Хоть и заговор почти сработал, он был сорван, а Молоха лишили власти и приговорили к смерти, и только своевременное использование планарного портала позволило ему сбежать.

Молох, не теряя времени, готовился к своему возвращению. Он собрал армию дьяволов и чудовищ, и покинул их, чтобы сделать пару последних приготовлений по вторжению в Девять Преисподней. Скитаясь по дальнему Материальному плану в надежде найти артефакт, способный обеспечить ему успешную победу, Молох оказался там в ловушке, оставив свою армию на милость своих врагов. В скором времени, все они были разбиты и уничтожены.

Теперь Молох стал почти бессильным после своего последнего провала. Он неустанно планирует пути возвращения к своим прежним владениям, но каждый раз, когда он входит в Девять Преисподних, он понижается до уровня [беса](CREATURE:${CREATURE_IMP}) и не может восстановить свои силы, пока не покинет эти земли. Таким образом, он живёт, строя планы в Малболже или других слоях Преисподней, а иногда и путешествуя по другим планам в поисках магической мощи или секретов, что помогут ему вернуть обратно свой титул.

Ходят слухи, что его можно найти в Сигиле, где он проводит сделки с юголотами, чтобы построить очередную армию, с помощью которой он вторгнется в Малболж и вырвет свой престол из лап Глэсьи. В невыгодном для себя положении, ему мало что есть предложить им, и поэтому он заключает сделки со смертными, чтобы получить их помощь в виде золота, драгоценных камней и прочих богатств взамен на знания о Девяти Преисподних и других планах.

Большинство культистов Молоха сменили верность ему на других архидьяволов, но идолы, построенные почитать его, по-прежнему стоят в глубинах подземелий, а их драгоценные глаза и остаток их силы ведёт чудовищных последователей и глупых искателей приключений в те места, где сохранилось его злобное влияние.`,
    ...archDevilDescriptionList,
  ],
  note: [
    {
      text: `Молох одержим властью, что он потерял, когда давно уже мог потратить все свои силы на захват власти где-нибудь в другом плане. Как жаль, что он так впустую тратит свой потенциал.`,
      author: `Архимаг Морденкайнен`,
    },
    ...archDevilNoteList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ARCHDEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 192,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 22,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 26,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 22,
    [PARAM_INT]: 21,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 23,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_21,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      id: ABILITY_REGENERATION,
      regen: 20,
      other: `. Если ★он★ получает урон излучением, эта особенность не работает до начала ★его★ следующего хода. ★СУЩЕСТВО★ умирает только в случае, если начинает ход с 0 хитами и не может регенерировать`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_ANIMATE_DEAD,
          SPELL_CONFUSION,
          SPELL_DETECT_MAGIC,
          SPELL_FLY,
          SPELL_GEAS,
          SPELL_MAJOR_IMAGE,
          SPELL_STINKING_CLOUD,
          SPELL_SUGGESTION,
          SPELL_WALL_OF_FIRE,
          {
            id: SPELL_ALTER_SELF,
            comment: 'может становиться Среднего размера',
          },
          {
            id: SPELL_BURNING_HANDS,
            comment: 'как заклинание 7 уровня',
          },
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_FLAME_STRIKE,
          {
            id: SPELL_SYMBOL,
            comment: 'только эффект _«Ошеломления»_',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Молох совершает три атаки: одну _Укусом_, одну _Когтями_ и одну _Многохвостым хлыстом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Многохвостый хлыст',
      description: `Если целью является существо, оно должно пройти испытание Силы СЛ 24, иначе будет притянуто на 30 футов к Молоху.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 30,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 4,
            diceBonus: 8,
          },
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 2,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Дыхание отчаяния',
      restore: 5,
      description: `Молох выдыхает куб со стороной в 30 фт. Каждое существо в этой области должно пройти испытание Мудрости СЛ 21, иначе получит 27 (5к10) урона психической энергией, уронит всё, что держало в руках, а также станет Испуганным на 1 минуту. Испуганное таким образом существо должно использовать действие Рывок на каждом своём ходу, чтобы двигаться как можно дальше от Молоха максимально безопасным путём. Если существу некуда двигаться, оно не использует действие Рывок. Если существо завершает ход в области, откуда не видно Молоха, оно может повторить испытание, завершая эффект Испуга в случае успеха.`,
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Зловонное облако',
      description: `Молох творит заклинание [Зловонное облако](SPELL:${SPELL_STINKING_CLOUD}).`,
    },
    {
      name: 'Телепортация',
      description: `Молох использует действие _Телепортация_.`,
    },
    {
      name: 'Хлыст',
      description: `Молох совершает одну атаку _Многохвостым хлыстом_.`,
    },
  ],
}
