const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_14} = require('./../../../../crList')
const {LANG_ABYSSAL} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')
const {demonDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  DAMAGE_ELECTRICITY,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_STANDING_LEAP,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_BARLGURA,
  CREATURE_CROKEKTOECK,
  CREATURE_DRETCH,
  CREATURE_GNOLL,
  CREATURE_GNOLL_FANG_OF_YEENOGHU,
  CREATURE_VROCK,
  CREATURE_YEENOGHU,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Крокек’тоик',
  nameEn: 'Crokek’toeck',
  id: CREATURE_CROKEKTOECK,
  description: [
    {
      header: 'Крокек’тоик',
      text: `Демон-питомец [Йеногу](CREATURE:${CREATURE_YEENOGHU}) напоминает гигантского илистого прыгуна, только с зубами, шерстью, ушами и смехом гиены. Этот уникальный демон способен держать в брюхе демонов поменьше и изрыгать их, где велит [Йеногу](CREATURE:${CREATURE_YEENOGHU}). Крокек’тоик невосприимчив к лишающему разума воздействию реки Стикс, а потому его часто используют для перевозки демонов (порой даже самого [Йеногу](CREATURE:${CREATURE_YEENOGHU})) через жуткие стигийские воды.`,
      source: {
        id: SOURCE_BGDIA,
        page: 234,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_BGDIA,
    page: 234,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 60,
    [SPEED_SWIM]: 60,
  },
  params: {
    [PARAM_STR]: 28,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 24,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  resistanceList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_NONMAGIC,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ABYSSAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_14,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 60,
      jumpHeight: 30,
    },
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Надёжная память',
      description: `★СУЩЕСТВО★ ★невосприимчив★ к водам реки Стикс и прочим эффектам, которые отнимают или меняют воспоминания либо определяют или читают его мысли.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 15,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 10,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Изрыгание союзников',
      restore: 6,
      description: `★СУЩЕСТВО★ открывает пасть и изрыгает 1к4 [барлгуры](CREATURE:${CREATURE_BARLGURA}), или 3к6 [гноллов](CREATURE:${CREATURE_GNOLL}) во главе с 1 [клыком Йеногу](CREATURE:${CREATURE_GNOLL_FANG_OF_YEENOGHU}), или 6к6 [дретчей](CREATURE:${CREATURE_DRETCH}), или 1к3 [врока](CREATURE:${CREATURE_VROCK}). Каждое изрыгнутое существо появляется в свободном пространстве в пределах 30 футов от пасти ★СУЩЕСТВО★ или в ближайшем свободном пространстве.`,
    },
  ],
}
