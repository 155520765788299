const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {CONDITION_UNCONSCIOUS} = require('./../../../../../conditionList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {SPELL_SLEEP} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_SLEEP,
  name: 'Усыпление',
  nameEn: 'Sleep',
  description: `Это заклинание посылает существ в магический сон. Бросьте 5к8; результат будет количеством хитов существ, на которых это заклинание может подействовать. Существа в пределах 20 фт. от точки, выбранной Вами в пределах дистанции, попадают под действие в порядке увеличения их текущих хитов (игнорируя тех, что находятся без сознания).

Начиная с существа с наименьшим количеством текущих хитов все существа, попадающие под действие этого заклинания, теряют сознание до окончания действия заклинания, пока не получат урон, или пока кто-нибудь другой не разбудит их, потратив действие на тряску или пощечину. Вычитайте хиты каждого существа из общей суммы, после чего переходите к следующему существу с наименьшим числом хитов. Для того чтобы существо попало под действие заклинания, нужно чтобы количество ее текущих хитов не превышало оставшуюся сумму.

Нежить и существа, обладающие иммунитетом к очарованию, не попадают под действие этого заклинания.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, бросайте дополнительно 2к8 за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'щепотка мелкого песка, лепестки розы или сверчок',
  duration: { timeId: TIME_MINUTE, count: 1 },
  effect: {
    condition: CONDITION_UNCONSCIOUS,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 286,
    },
    {
      id: SOURCE_SRD,
      page: 180,
    },
  ],
}
