const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_KOBOLD_INVENTOR,
  CREATURE_SKUNK,
  CREATURE_WEASEL,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Скунс',
  nameEn: 'Skunk',
  id: CREATURE_SKUNK,
  description: `Статблок собран из параметров скунса из способности _«Скунс в клетке»_ [кобольда-изобретателя](CREATURE:${CREATURE_KOBOLD_INVENTOR}) и статблока [куницы](CREATURE:${CREATURE_WEASEL}).`,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 160,
  },
  armor: 10,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 3,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 8,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  cr: CR_0,
  actionList: [
    {
      name: 'Струя мускуса',
      comment: 'перезарядка после короткого или длинного отдыха',
      description: `Скунс выпускает струю мускуса в существо в 5 футах от себя. Цель должна пройти испытание Телосложения СЛ 9. При провале цель начинает рвать и она не может совершать действия 1 минуту.

Цель может повторять испытание в конце каждого своего хода и окончить эффект при успехе. Существо, которому не требуется дышать или имеет иммунитет к яду, автоматически преуспевает в испытании.`,
    },
  ],
}
