const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {SPELL_RESURRECTION} = require('./../../spellIdList')

module.exports = [
  {
    header: 'Зомби',
    text: `Откуда-то из темноты слышатся гортанные стоны. В поле зрения появляется шатающееся тело, подволакивающее распухшую ногу и тянущее изломанные руки. Зомби с лёгкостью убьют всех, кто не успеет избежать их объятий.`,
    source: {
      id: SOURCE_MM,
      page: 163,
    },
  },
  {
    header: 'Зомби',
    text: `Бездумные трупы, движимые тёмной магией, зомби не задумываясь без колебания исполняют повеления своего хозяина.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Тёмные слуги',
    text: `Зловещая некромантия заставляет мёртвых подниматься в виде зомби, выполняющих без страха и сомнения то, что прикажет их создатель. Они двигаются неровной, дёргающейся походкой, одетые в гниющие остатки того, в чём их похоронили, окружая себя зловонным запахом разложения.

Большинство зомби создаётся из гуманоидных останков, однако подобием жизни могут быть наполнены плоть и кости любого живого существа. Обычно для этого используется заклинание из арсенала некромантии. Некоторые зомби могут подниматься самостоятельно, когда тёмная магия окутывает какое-либо пространство. Единожды поднятое как зомби, существо не сможет больше вернуться к жизни, разве что кроме как посредством могущественной магии, например, заклинания [Воскрешение](SPELL:${SPELL_RESURRECTION}).

У зомби нет никакого осознания бывшего себя, его разум лишён мыслей и фантазии. Без приказа зомби будет просто стоять на месте и гнить, пока рядом не окажется кто-то, кого можно убить. Магия, поднявшая зомби, наполняет его злом, поэтому оставленный без цели, он атакует любое живое существо, столкнувшееся с ним.`,
    source: {
      id: SOURCE_MM,
      page: 163,
    },
  },
  {
    header: 'Отвратительные тела',
    text: `Зомби появляются такими, какими они были при жизни, демонстрируя раны, от которых умерли. Однако магия, которая создаёт этих ужасных существ, требует времени. Мёртвые воины могут подняться на поле боя, выпотрошенные и раздутые, после нескольких дней на солнце. Грязный мертвец, бывший когда-то крестьянином, может прорыть себе путь из земли, покрытый личинками и червями. Зомби может быть вынесен прибоем или восстать посреди болота, разбухший и воняющий, после многих недель, проведённых в воде.`,
    source: {
      id: SOURCE_MM,
      page: 163,
    },
  },
  {
    header: 'Безмозглые солдаты',
    text: `Зомби выбирают самый кратчайший путь к своему врагу, неспособные оценить препятствия на пути, тактику и опасный ландшафт. В стремлении достичь своего врага, находящегося на другом берегу, они могут войти в бурлящую реку, где их размажет о камни. Чтобы убить противника, стоящего внизу, зомби могут выйти в открытое окно. Они бездумно шагнут в полыхающий огонь, колодец с кислотой, и напрямки пойдут через поле, усеянное шипами.

Они могут следовать простым приказам и отличать друзей от врагов, но их способности ограничены волочением ног туда, куда укажут, убивая всех врагов на пути. Зомби обычно вооружены тем, что использовали, и не будут поднимать выпавшее оружие и другие инструменты, пока им не прикажут.`,
    source: {
      id: SOURCE_MM,
      page: 163,
    },
  },
  {
    header: 'Природа нежити',
    text: `Зомби не нужен воздух, еда, питьё и сон.`,
    source: {
      id: SOURCE_MM,
      page: 163,
    },
  },
]
