const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_NEOGI} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_NEOGI,
  nameEn: 'Neogi',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'неоги',
      genitive: 'неоги',
      dative: 'неоги',
      accusative: 'неоги',
      instrumental: 'неоги',
      prepositional: 'неоги',
    },
    plural: {
      nominative: 'неоги',
      genitive: 'неоги',
      dative: 'неоги',
      accusative: 'неоги',
      instrumental: 'неоги',
      prepositional: 'неоги',
    },
  },
}
