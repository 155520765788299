const {
  SPELL_CURE_WOUNDS,
  SPELL_DISPEL_MAGIC,
  SPELL_GUIDING_BOLT,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHT,
  SPELL_SACRED_FLAME,
  SPELL_SANCTUARY,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_THAUMATURGY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_ACOLYTE,
  CREATURE_COMMONER,
  CREATURE_PRIEST,
} = require('./../../../../creatureIdList')
const {
  SKILL_MEDICINE,
  SKILL_PERSUASION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  SOURCE_MM,
  SOURCE_ERRATA_MONSTER_MANUAL,
} = require('./../../../../sourceList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_CHAIN_SHIRT,
  GEAR_MACE,
} = require('./../../../../gearIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Священник',
  nameEn: 'Priest',
  id: CREATURE_PRIEST,
  description: `Священники несут учение своих богов простым [обывателям](CREATURE:${CREATURE_COMMONER}). Это духовные лидеры храмов и часовен, и часто они обладают влиянием в своих общинах. Злые священники могут открыто работать на тирана, а могут быть лидерами религиозных сект, скрывающихся среди общества и исполняющих порочные ритуалы.

Обычно при священнике находится один или несколько [прислужников](CREATURE:${CREATURE_ACOLYTE}), помогающих с религиозными церемониями или просто священными ритуалами.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: [
    {
      id: SOURCE_MM,
      page: 350,
    },
    {
      id: SOURCE_ERRATA_MONSTER_MANUAL,
      page: 1,
    },
  ],
  armor: {
    ac: 13,
    gearId: GEAR_CHAIN_SHIRT,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 12,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_MEDICINE]: PROF_DOUBLE,
    [SKILL_RELIGION]: PROF_DOUBLE,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Божественная слава',
      description: `★СУЩЕСТВО★ может бонусным действием потратить одну ячейку заклинания, чтобы ★его★ рукопашные атаки оружием магическим образом причиняли цели при попадании дополнительный урон излучением 10 (3к6). Это преимущество длится до конца текущего хода. Если ★СУЩЕСТВО★ потратит ячейку 2 круга или выше, дополнительный урон увеличивается на 1к6 за каждый уровень выше 1.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_CURE_WOUNDS,
      SPELL_DISPEL_MAGIC,
      SPELL_GUIDING_BOLT,
      SPELL_LESSER_RESTORATION,
      SPELL_LIGHT,
      SPELL_SACRED_FLAME,
      SPELL_SANCTUARY,
      SPELL_SPIRIT_GUARDIANS,
      SPELL_SPIRITUAL_WEAPON,
      SPELL_THAUMATURGY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      gearId: GEAR_MACE,
    },
  ],
  genderId: GENDER_MALE,
}
