const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_DEEP_SPEECH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  starSpawnDescription,
  starSpawnNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_STAR_SPAWN_HULK,
  CREATURE_STAR_SPAWN_SEER,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_STAR_SPAWN,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_13} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Провидец звёздных порождений',
  nameEn: 'Star Spawn Seer',
  id: CREATURE_STAR_SPAWN_SEER,
  description: [
    `С провидцем звёздных порождений зачастую сталкиваются в качестве лидера культа Древних. Обычно провидец является единственным членом культа, что обладает властью над ужасами, которым поклоняется культ.

Сущность, появляющаяся в виде провидца звёздных порождений в Материальном плане, обычно пребывает как нечто чужеродное… нечто бестелесное. Когда колдун или любой другой заклинатель устанавливает контакт с этой сущностью, она берёт контроль над телом смертного и его духом, превращая того в провидца звёздных порождений. Кем бы раньше не был тот, кто устанавливал контакт с провидцем, он исчезал навсегда, а его тело становилось склизкой, мерзопакостной тушей, не имеющей кожи, по которой ползают нечто вроде червей. Его руки становятся громоздкими, отвратительными конечностями, способные держать свой странный посох (сформировавшаяся некая смесь из плоти, костей и звёздной материи), но при взаимодействии с прочими объектами выглядят очень неуклюже и неприятно.

Провидец звёздных порождений всегда сопровождается одним или несколькими [громилами звёздных порождений](CREATURE:${CREATURE_STAR_SPAWN_HULK}). Хоть [громила](CREATURE:${CREATURE_STAR_SPAWN_HULK}) и является достойным противником, он также является важным тактическим элементом, который используется провидцами. Когда провидец наносит [громиле](CREATURE:${CREATURE_STAR_SPAWN_HULK}) урон психической энергией, громиле не наносится этот урон, а отражается и проходит по другим существам возле него.

Целью провидца является использование источников энергии и совершение обрядов, позволяющих расширить мост между уязвимым рассудком Материального плана и полным безумия местом заточения Древних.`,
    ...starSpawnDescription,
  ],
  note: starSpawnNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_STAR_SPAWN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 222,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 18,
    [PARAM_INT]: 22,
    [PARAM_WIT]: 19,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_13,
  featureList: [
    {
      name: 'Дефазированное перемещение',
      description: `Провидец перемещается сквозь существ и объекты, как если бы они были труднопроходимой местностью. Каждое существо, сквозь которое он перемещается, получает 5 (1к10) урона психической энергией; ни одно существо не может получить этот урон более раза за ход. Провидец получает 5 (1к10) урона силовым полем, если завершает движение внутри объекта.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Провидец совершает две атаки _Кометным посохом_, или использует _Психический шар_ дважды.`,
    },
    {
      name: 'Кометный посох',
      description: `Цель должна пройти испытание Телосложения СЛ 19, иначе станет недееспособной до конца своего следующего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 1,
              diceType: 6,
              diceBonus: 6,
            },
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 1,
              diceType: 8,
              diceBonus: 6,
              use2Hand: true,
            },
          ],
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 4,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Психический шар',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 11,
        range: 120,
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 5,
          diceType: 10,
          diceBonus: 0,
        },
      },
    },
    {
      name: 'Сократить расстояние',
      restore: 6,
      description: `Провидец искажает пространство вокруг существа, которое может видеть в пределах 30 футов от себя. Существо должно пройти испытание Мудрости СЛ 19. При провале цель вместе со всем снаряжением, которое оно несёт или носит, магическим образом телепортируется на 60 футов в незанятое пространство, которое может видеть провидец, а каждое другое существо в пределах 10 футов от начальной точки телепортации получает 39 (6к12) урона психической энергией. При успешном испытании цель получает 19 (3к12) урона психической энергией.`,
    },
  ],
  reactionList: [
    {
      name: 'Искажение пространства',
      description: `Когда по провидцу попадают любой атакой, он телепортируется, меняясь местами с другим звёздным порождением, которую он может видеть в пределах 60 фт. от себя. Другое звёздное порождение получает урон от атаки вместо провидца.`,
    },
  ],
}
