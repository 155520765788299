const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {CAT_MUSIC_TOOLS} = require('./../../../gearCategoryList')
const {GEAR_VIOL} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {
  SOURCE_PHB,
  SOURCE_XGTE,
} = require('./../../../sourceList')

module.exports = {
  id: GEAR_VIOL,
  genderId: GENDER_FEMALE,
  name: 'Виола',
  nameByCase: {
    nominative: 'виола',
    genitive: 'виолы',
    accusative: 'виолу',
    instrumental: 'виолой',
  },
  nameEn: 'Viol',
  description: musicalToolDescriptionList,
  cost: 3000,
  weight: 1,
  source: [
    {
      id: SOURCE_PHB,
      page: 154,
    },
    {
      id: SOURCE_XGTE,
      page: 85,
    },
  ],
  category: CAT_MUSIC_TOOLS,
}
