import React from 'react'
import arrify from 'arrify'
import PropTypes from 'prop-types'

import { sourceCollection } from '@/constants/sourceList'

import SourceInfoComponent from './SourceInfoComponent'

const SourceInfoContainer = ({ source, showPage, useFullName, ...rest }) => source
  ? (
    <>
      {
        arrify(source)
          .filter(e => e)
          .map(
            (sourceItem, i) => {
              if (sourceItem) {
                let url = ''
                let title = ''
                let text = ''
                let id = ''

                if (typeof sourceItem === 'string') {
                  id = sourceItem
                } else if (typeof sourceItem === 'object') {
                  id = sourceItem.id
                }

                if (id) {
                  const { page } = sourceItem
                  const { name, nameEn, shortName, isUA, date, translator } = sourceCollection[id]
                  const dateText = isUA && date
                    ? ` [${date}]`
                    : ''
                  const pageText = showPage && page
                    ? `, ${page}`
                    : ''

                  url = sourceCollection[id].url || url

                  const titleRow = showPage
                    ? `${name}${dateText}${pageText} страница`
                    : name

                  title = translator
                    ? `${titleRow}\n\nПеревод: ${translator}\n\n${nameEn}`
                    : `${titleRow}\n\n${nameEn}`

                  text = useFullName
                    ? `${name}${dateText}${pageText}`
                    : `${shortName}${dateText}${pageText}`
                }

                if (sourceItem.url) {
                  url = sourceItem.url
                  const { host } = new URL(url)

                  text = text || host

                  if (sourceItem.problemText) {
                    title = `Почему ${host}?\n\n${sourceItem.problemText}`
                  }
                }

                const key = `${sourceItem.id || sourceItem.url || sourceItem}_${i}`

                return (
                  <SourceInfoComponent
                    key={key}
                    url={url}
                    title={title}
                    {...rest}
                  >
                    {text}
                  </SourceInfoComponent>
                )
              }
            },
          )
          .filter(e => e)
      }
    </>
  )
  : null

SourceInfoContainer.propTypes = {
  source: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.shape({
      url: PropTypes.string,
      problemText: PropTypes.string,
    }),
    PropTypes.shape({
      id: PropTypes.string,
      page: PropTypes.number,
    }),
  ]).isRequired,
  showPage: PropTypes.bool,
  useFullName: PropTypes.bool,
}

SourceInfoContainer.defaultProps = {
  showPage: true,
  useFullName: false,
}

export default SourceInfoContainer
