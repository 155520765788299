const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_BULLYWUG,
  CREATURE_ELEPHANT,
  CREATURE_FROGHEMOTH,
} = require('./../../../../creatureIdList')
const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')

module.exports = {
  name: 'Лягемот',
  nameEn: 'Froghemoth',
  id: CREATURE_FROGHEMOTH,
  description: [
    {
      header: 'Лягемот',
      text: `Лягемот — хищная амфибия размером со [слона](CREATURE:${CREATURE_ELEPHANT}). Он водится в болотах, и у него четыре щупальца, толстая резинистая шкура, клыкастая пасть с липким языком и удлиняющийся отросток с тремя круглыми глазами, смотрящими в разные стороны.`,
      source: {
        id: SOURCE_VGTM,
        page: 168,
      },
    },
    {
      header: 'Внеземные существа',
      text: `Лягемоты — существа из другого мира. В дневнике, предположительно написанном давным-давно волшебником Лумом Безумным, описываются странные цилиндрические помещения, сделанные из металла, закопанные глубоко в земле, из которых появились лягемоты, но надёжных свидетельств расположения этого места не существует.`,
      source: {
        id: SOURCE_VGTM,
        page: 168,
      },
    },
    {
      header: 'Голодные от рождения',
      text: `Каждые несколько лет лягемот может отложить без спаривания оплодотворенное яйцо. Лягемот совершенно не заботится о яйце и может даже съесть вылупившегося детёныша. Выживание юного лягемота чаще всего зависит от того, оставит ли его родитель в безразличии. Новорожденный лягемот вырастает до полного размера за несколько месяцев, без разбора поедая других существ в своем болоте. Он учится прятать свою огромную тушу в мутной воде, высовывая над водой только отросток с глазами, чтобы следить за проходящими существами. Когда еда проходит в пределах досягаемости, лягемот вылезает из воды с щупальцами и языком наготове. Он может схватить несколько целей одновременно, удерживая их, пока языком он хватает ещё одну и тянет к себе, чтобы сожрать.`,
      source: {
        id: SOURCE_VGTM,
        page: 168,
      },
    },
    {
      header: 'Почитаемые жаболюдами',
      text: `Если племя [жаболюдов](CREATURE:${CREATURE_BULLYWUG}) натыкается на лягемота, оно начинает почитать его как бога и делает всё возможное, чтобы завлечь его в своё логово. Лягемота можно приручить (в каком-то смысле) предлагая ему пищу, и жаболюды могут общаться с ним на базовом уровне, так что существо может съесть всего нескольких жаболюдов, прежде чем начать следовать за остальными. Жаболюды собирают ему еду как дань, предоставляют ему комфортное логово, фанатично защищают от любого вреда и стараются удостовериться, что юный лягемот достигает зрелости.`,
      source: {
        id: SOURCE_VGTM,
        page: 168,
      },
    },
    {
      header: 'Лягемот',
      text: `Лягемот — хищная амфибия размером со [слона](CREATURE:${CREATURE_ELEPHANT}). Он водится в болотах, и у него четыре щупальца, толстая резинистая шкура, клыкастая пасть с липким языком и удлиняющийся отросток с тремя круглыми глазами, смотрящими в разные стороны. Он учится прятать свою огромную тушу в мутной воде, высовывая над водой только отросток с глазами, чтобы следить за проходящими существами. Когда еда проходит в пределах досягаемости, лягемот вылезает из воды с щупальцами и языком наготове.`,
      source: {
        id: SOURCE_TOA,
        page: 233,
      },
    },
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 168,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 20,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 5,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_10,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Шоковая восприимчивость',
      description: `Если лягемот получает урон электричеством, то он получает несколько эффектов до конца своего следующего хода: 

* его скорость уменьшена вдвое, 
* он получает штраф –2 к КД и испытаниям Ловкости,
* он не может использовать реакции или Мультиатаку,
* в свой ход он может использовать или действие или бонусным действие, но не оба сразу.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Лягемот совершает две атаки _Щупальцами_. Он также может использовать _Язык_ или _Укус_.`,
    },
    {
      name: 'Щупальце',
      description: `Цель схвачена (Сл высвобождение 16) если она Огромного размера или меньше. До окончания захвата лягемот не может использовать это щупальце для другой цели. У лягемота всего четыре щупальца.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 20,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Укус',
      description: `Цель проглочена если она существо Среднего размера или меньше. Проглоченное существо ослеплено и обездвижено, имеет полное укрытие от атак и других эффектов снаружи лягемота, и получает 10 (3к6) урона кислотой в начале каждого хода лягемота.

Желудок лягемота может содержать двух существ одновременно. Если лягемот получит 20 или больше урона в один ход от существа внутри себя, то он должен успешно пройти испытание Телосложения СЛ 20 в конце этого хода или изрыгнуть всех проглоченных существ, каждое из которых падает сбитым с ног в 10 футах от лягемота. Если лягемот умирает, то проглоченное существо больше не обездвижено и может выбраться из тела, использовав 10 футов передвижения и упав сбитым с ног при выходе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Язык',
      description: `Лягемот выбирает целью одно Среднее существо или меньше, которое может видеть не далее 20 футов от себя. Цель должна пройти испытание Силы СЛ 18. При провале цель подтягивается на незанятое место в 5 футах от лягемота и он бонусным действием может совершить по ней атаку _Укусом_.`,
    },
  ],
}
