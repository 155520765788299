import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'

import { googleVerification, yandexVerification } from 'root/config'

function Seo({ description, lang, meta, title, img }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            version
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const titleTemplate = title
    ? `%s | ${site.siteMetadata.title}`
    : site.siteMetadata.title

  const imgMetaData = img
    ? [{
      property: `og:image`,
      content: img,
    }]
    : []

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || site.siteMetadata.title}
      titleTemplate={titleTemplate}
      script={[
        // Активация РСЯ
        { type: 'text/javascript', src: withPrefix('yaContextCb.js') },
        { type: 'text/javascript', src: 'https://yandex.ru/ads/system/context.js', async: true },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: lang,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        ...imgMetaData,
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `version`,
          content: site.siteMetadata.version,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `yandex-verification`,
          content: yandexVerification,
        },
        {
          name: `google-site-verification`,
          content: googleVerification,
        },<meta name="google-site-verification" content="" />
      ]
        .concat(meta)}
    />
  )
}

Seo.defaultProps = {
  description: ``,
  img: null,
  lang: `ru`,
  meta: [],
  title: '',
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Seo
