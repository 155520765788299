const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    GEAR_HANDAXE,
    GEAR_HIDE_ARMOR,
  } = require('./../../../../gearIdList'),
  {
    CR_1_4,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_DWARF,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
    SKILL_SURVIVAL,
  } = require('./../../../../skillList'),
  {
    LANG_COMMON,
    LANG_DWARVISH,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_ANY,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_DWARVEN_RESILIENCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    albinoDwarfDescription,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_ALBINO_DWARF_WARRIOR,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дварф-альбинос воин',
  nameEn: 'Albino Dwarf Warrior',
  id: CREATURE_ALBINO_DWARF_WARRIOR,
  description: albinoDwarfDescription,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DWARF,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_TOA,
    page: 221,
  },
  armor: {
    ac: 13,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 17,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DWARVISH,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_DWARVEN_RESILIENCE,
  ],
  actionList: [
    {
      gearId: GEAR_HANDAXE,
    },
  ],
}
