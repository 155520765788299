import arrify from 'arrify'
import React from 'react'

import { TYPE_ADS, TYPE_ITEM } from '@/constants/itemTypes'

const ADS_GAP = 5
const BLOCK_COUNT_MIN_FOR_ADS = 3

function enrichWithAds (listRaw, AdsBlock) {
  let list = arrify(listRaw)
    .filter(e => e && !e.isHidden)
    .map(
      item => ({
        item,
        type: TYPE_ITEM,
      })
    )

  if (list.length >= BLOCK_COUNT_MIN_FOR_ADS) {
    // Если блоков больше BLOCK_COUNT_MIN_FOR_ADS — вставлять баннер после каждого ADS_GAP.
    if (list.length > ADS_GAP) {
      list = list.reduce(
        (acc, item, i) => {
          const result = [
            ...acc,
            item,
          ]

          return (
            (
              (i + 1) % ADS_GAP === 0
            )
            && (
              i + 1 !== list.length
            )
          )
            ? [
              ...result,
              {
                item: AdsBlock,
                type: TYPE_ADS,
              }
            ]
            : result
        },
        []
      )
    } else {
      // Если блоков меньше ADS_GAP — просто вставлять баннер предпоследним
      list.splice(
        -1,
        0,
        {
          item: AdsBlock,
          type: TYPE_ADS,
        }
      )
    }
  }

  return list
}

export default enrichWithAds
