const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_CHARDIC} = require('./../../languageIdList')
const {LANG_SUBGROUP_CHARD} = require('./../../languageSubGroupList')

module.exports = {
  id: LANG_CHARDIC,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_CHARD,
  nameEn: 'Chardic',
  name: {
    nominative: 'Чардик',
    genitive: 'Чардика',
    instrumental: 'Чардиком',
    prepositional: 'Чардике',
  },
  isReady: false,
  isRealLang: true,
}
