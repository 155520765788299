const {SOURCE_MM} = require('./../../sourceList')
const {GOD_SEKOLAH} = require('./../../godIdList')
const {CREATURE_HUNTER_SHARK} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Сахуагины',
    text: `Раздающийся над туманными побережьями и волнами бесконечного океана угрожающий гул раковины холодит кровь всех, кто его слышит. Так звучит охотничий рог сахуагинов, призывающий к нападению. Жители побережья называют сахуагинов «морскими дьяволами» за то, что они не ведают жалости, топя корабли и истребляя прибрежные деревни.`,
    source: {
      id: SOURCE_MM,
      page: 257,
    },
  },
  {
    header: 'Дьяволы глубин',
    text: `Сахуагины — хищная рыбоподобная раса, выходящая периодически из тёмных глубин океана, чтобы охотиться на существ мелководья и побережья. Несмотря на то, что они обитают в глубочайших океанических впадинах, сахуагины считают всё водное царство своими владениями, а живущих в воде существ — своей добычей.

Самопровозглашённые правители океанических владений сахуагинов — огромные самцы-мутанты, обладающие второй парой рук. Это страшные противники в бою, и все сахуагины преклоняются перед этими могущественными баронами.`,
    source: {
      id: SOURCE_MM,
      page: 257,
    },
  },
  {
    header: 'Путь акулы',
    text: `Сахуагины поклоняются богине-акуле [Секоле](GOD:${GOD_SEKOLAH}). Лишь женщины сахуагинов считаются достойными проводниками божественной силы, и жрицы имеют громадное влияние в обществе сахуагинов.

Сахуагины входят в неистовство, почуяв свежую кровь. Будучи почитателями [Секолы](GOD:${GOD_SEKOLAH}), они имеют особое родство с [акулами](GOD:${CREATURE_HUNTER_SHARK}), которых тренируют в боевых целях. Даже нетренированные [акулы](GOD:${CREATURE_HUNTER_SHARK}) признают сахуагинов своими союзниками, и не охотятся на них.`,
    source: {
      id: SOURCE_MM,
      page: 257,
    },
  },
  {
    header: 'Неприязнь к эльфам',
    text: `Сахуагины могли бы контролировать океаны, если бы там не было их смертельных врагов, водных эльфов. Войны между этими двумя расами бушевали столетиями в морях и на побережьях по всему миру, подрывая морскую торговлю и планы других рас.

Так сильна была ненависть сахуагинов к водным эльфам, что морские дьяволы адаптировались к борьбе со своими давними врагами. Вблизи сообщества водных эльфов у сахуагинов может появиться на свет _маленти_ — сахуагин, который внешне неотличим от водного эльфа. Сахуагины склонны к мутации, но никто не может сказать, стал ли этот редкий феномен результатом войн между сахуагинами и водными эльфами, или так было изначально, или, возможно, это он послужил причиной конфликта.

Сахуагины находят хорошее применение маленти, используя их в качестве шпионов и ассасинов в городах водных эльфов и прочих существ, которые представляют угрозу для сахуагинов. Всего лишь намёк на присутствие маленти разжигает паранойю и подозрение среди водных эльфов, подрывая их моральный дух, и это лишь прелюдия к предстоящему вторжению сахуагинов.`,
    source: {
      id: SOURCE_MM,
      page: 257,
    },
  },
]
