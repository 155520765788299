const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    GOD_AERDRIE_FAENYA,
    GOD_FENMAREL_MESTARINE,
  } = require('./../../../../godIdList'),
  {
    PC_RACE_ELF,
  } = require('./../../../../pcRaceIdList'),
  {
    CREATURE_HARPY,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гарпия',
  nameEn: 'Harpy',
  id: CREATURE_HARPY,
  description: `Находя удовольствие в страданиях и смерти, гарпии, обладающие садистскими вкусами, постоянно ищут себе новые жертвы. Их сладкие песни привели к смерти бесчисленное множество искателей приключений, приманивая их гарпиям на съедение.

Гарпия обладает телом, ногами и крыльями стервятника и туловищем, руками и головой человека. Их глаза отражают абсолютное зло их душ, а острые когти гарпий и костяные дубинки делают их опасными в бою.

# Божественное проклятье

В давние времена блуждающая по лесу [эльфийка](PC_RACE:${PC_RACE_ELF}) услышала пение птиц, настолько чистое и прекрасное, что была тронута до слез. Следуя этой музыке, она вышла на поляну, где стоял красивый юный [эльф](PC_RACE:${PC_RACE_ELF}), который тоже остановился, чтобы послушать песню птицы. Это был [Фенмарел Местарин](GOD:${GOD_FENMAREL_MESTARINE}), эльфийский бог-затворник. Его божественный облик похитил её сердце, но эльф сбежал, исчезнув среди деревьев, словно его там никогда и не было.

[Эльфийка](PC_RACE:${PC_RACE_ELF}) обыскала лес и звала незнакомца, но она не нашла никаких следов его присутствия. Доведённая до отчаяния свой страстью, она умоляла богов о помощи. [Аэрдри Фаэниа](GOD:${GOD_AERDRIE_FAENYA}), [эльфийская](PC_RACE:${PC_RACE_ELF}) богиня неба, услышала плач [эльфийки](PC_RACE:${PC_RACE_ELF}) и отправилась ей на помощь. Она явилась в образе птицы, чья песня очаровала бога-затворника, и научила [эльфийку](PC_RACE:${PC_RACE_ELF}) этой песне красоты и обольщения.

Когда её пению не удалось привлечь [Фенмарела Местарина](GOD:${GOD_FENMAREL_MESTARINE}) к себе, [эльфийка](PC_RACE:${PC_RACE_ELF}) прокляла богов, вызвав ужасающую силу, и превратилась в первую [гарпию](CREATURE:${CREATURE_HARPY}). Магия наложенного проклятья изменила дух эльфийки, также как и её тело, превратив её желание любви в жажду плоти, но её красивая песня продолжает привлекать жертв в её смертельные объятия.

# Песнь гарпии

Услышать песнь гарпии это услышать музыку красивее, чем что-либо в этом мире. Путешественник, который поддастся чарующему эффекту этого пения, вынужден слепо двигаться к его источнику. Гарпия очаровывает жертв, прежде чем атакует, но иногда использует свою песню более эффективно, заманивая добычу на утёсы, в болота, зыбучие пески или в смертельно опасные ямы. Существа, попавшие в ловушку или потерявшие сознание, становятся лёгкой мишенью для гнева гарпии.

# Садисты и трусы

Гарпии устраивают логово у мрачных прибрежных скал и в других местах, опасных для нелетающих существ. Гарпии не ведут честных битв, и они никогда не нападают, если не обладают явным преимуществом. Если бой оборачивается не в пользу гарпий, им не хватает хитрости, чтобы менять тактику, и потому они скорее сбегут и останутся голодными, чем будут рисковать в прямом бою.

Во время нападения гарпии играют со своей едой, восторгаясь «музыкой» криков своих жертв. Гарпия не торопится расчленять беспомощного врага, и может днями пытать жертву, пока не наступит её конец.

# Жуткие коллекционеры

Гарпии забирают блестящие побрякушки, ценности и другие трофеи со своих жертв, иногда сражаясь друг с другом за право претендовать на отборную добычу. Если ценности не найдены, гарпия забирает волосы, кости или части тела жертвы, чтобы вплести их в своё гнездо. Логово гарпии обычно сокрыто в отдалённых руинах, и там искатели приключений могут обнаружить ценные сокровища и магию, спрятанные под мерзкими кучами отходов.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 47,
  },
  armor: 11,
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 12,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 13,
  },
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Когтями_, и одну _Дубиной_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Дубина',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Манящая песня',
      description: `★СУЩЕСТВО★ начинает петь магическую мелодию. Все гуманоиды и великаны в пределах 300 футов от ★СУЩЕСТВО★, слышащие песню, должны пройти испытание Мудрости СЛ 11, иначе они станут очарованными до окончания песни. ★СУЩЕСТВО★ может продолжать петь в следующих раундах бонусным действием. ★Он★ может прекратить петь в любое время. Песня оканчивается, если ★СУЩЕСТВО★ становится ★недееспособным★.

Будучи очарованной ★СУЩЕСТВО★, цель недееспособна и игнорирует песни других ★СУЩЕСТВО★. Если очарованная цель находится более чем в 5 футах от ★СУЩЕСТВО★, цель должна в свой ход переместиться к ★СУЩЕСТВО★ кратчайшим маршрутом. ★Он★ не избегает провоцированных атак, но перед вхождением в опасную местность, такую как лава или яма, а также при получении урона из любого источника кроме ★этого★ ★СУЩЕСТВО★ цель может повторять испытание. Существо также может повторять испытание в конце каждого своего хода. Если испытание существа было успешным, эффект на нём прекращается и оно получает иммунитет к песне ★этого★ ★СУЩЕСТВО★ на следующие 24 часа.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
