const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_8,
  } = require('./../../../../crList'),
  {
    SIZE_HUGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_PIERCING,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_UNDEAD_FORTITUDE,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_TYRANNOSAURUS_ZOMBIE,
    CREATURE_ZOMBIE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Тираннозавр-зомби',
  nameEn: 'Tyrannosaurus Zombie',
  id: CREATURE_TYRANNOSAURUS_ZOMBIE,
  description: `У тираннозавра-зомби есть пищевод, заполненный меньшими [зомби](CREATURE:${CREATURE_ZOMBIE}), которые он может изрыгнуть.

Эти [зомби](CREATURE:${CREATURE_ZOMBIE}) не находятся под контролем тираннозавра.`,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 228,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 25,
    [PARAM_DEX]: 6,
    [PARAM_CON]: 19,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 3,
    [PARAM_CHA]: 5,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_8,
  featureList: [
    {
      name: 'Изрыгнуть зомби',
      description: `★СУЩЕСТВО★ может бонусным действием изрыгнуть обычного [зомби](CREATURE:${CREATURE_ZOMBIE}), который появляется в свободном пространстве в пределах 10 футов. Изрыгнутые так [зомби](CREATURE:${CREATURE_ZOMBIE}) действуют действует на собственной инициативе.

После того, как [зомби](CREATURE:${CREATURE_ZOMBIE}) изрыгнули, бросьте к6. При результате 1, ★СУЩЕСТВО★ теряет эту способность, потому что внутри ★него★ заканчиваются [зомби](CREATURE:${CREATURE_ZOMBIE}).

Если ★СУЩЕСТВО★ умирает, сохранив эту способность, то в начале следующего хода из его трупа вылезают 1к4 обычных [зомби](CREATURE:${CREATURE_ZOMBIE}). Вылезшие [зомби](CREATURE:${CREATURE_ZOMBIE}) действуют на собственной инициативе.`,
    },
    {
      id: ABILITY_UNDEAD_FORTITUDE,
      damageType: `излучением`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_ и одну _Хвостом_. ★Он★ не может совершать эти атаки по одной цели.`,
    },
    {
      name: 'Укус',
      description: `Если цель — существо с размером не больше Среднего, она становится схваченной (СЛ побега 17). Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может кусать другую цель или изрыгать [зомби](CREATURE:${CREATURE_ZOMBIE}).`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 12,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 7,
        },
      },
    },
  ],
}
