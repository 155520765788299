const {PC_SUBCLASS_ROGUE_REVIVED_2019_10_17} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED} = require('./../../../sourceList')
const {
  SPELL_SPEAK_WITH_DEAD,
  SPELL_WALL_OF_FORCE,
} = require('./../../../spellIdList')

const {
  FEATURE_AUDIENCE_WITH_DEATH_2019_10_17,
  FEATURE_BOLTS_FROM_THE_GRAVE_2019_10_17,
  FEATURE_CONNECT_WITH_THE_DEAD_2019_10_17,
  FEATURE_CUNNING_ACTION,
  FEATURE_ETHEREAL_JAUNT_2019_10_17,
  FEATURE_REVIVED_NATURE_2019_10_17,
  FEATURE_SNEAK_ATTACK,
  FEATURE_TOKENS_OF_PAST_LIVES_2019_10_17,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_TOKENS_OF_PAST_LIVES_2019_10_17,
    name: `Память прошлых жизней`,
    nameEn: `Tokens of Past Lives`,
    lvl: 3,
    text: `Вы помните свои умения из прошлой жизни. Завершая длинный отдых, Вы получаете владение одним навыком или инструментом по Вашему выбору.

Вы можете заменять это владение на другое в конце каждого длинного отдыха.`,
    source: {
      id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
      page: 4,
    },
  },
  {
    id: FEATURE_REVIVED_NATURE_2019_10_17,
    name: `Воскрешённая природа`,
    nameEn: `Revived Nature`,
    lvl: 3,
    text: `Ваша новая связь со смертью дает Вам следующие преимущества:

* Вы получаете преимущество к испытаниям от болезней и яда и сопротивление к урону ядом.
* Вам не нужно есть, пить и дышать.
* Вам не нужно спать. Совершая длинный отдых, Вы должны провести как минимум 4 часа и неподвижном состоянии вместо сна. В этом состоянии Вы остаётесь наполовину в сознании и слышите как обычно.`,
    source: {
      id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
      page: 4,
    },
  },
  {
    id: FEATURE_BOLTS_FROM_THE_GRAVE_2019_10_17,
    name: `Стрелы из могилы`,
    nameEn: `Bolts from the Grave`,
    lvl: 3,
    text: `Вы научились высвобождать заряды некротической энергии из своего воскрешённого тела.

Сразу же после использования [Хитрого действия](FEATURE:${FEATURE_CUNNING_ACTION}), Вы можете совершить дальнобойную атаку заклинанием по существу в пределах 30 футов, если ещё не использовали [Скрытую атаку](FEATURE:${FEATURE_SNEAK_ATTACK}) на этом ходу.

Вы добавляете свой модификатор Ловкости к броскам атаки и урона. Существо, пораженное этой атакой, получает урон некротической энергией, равный Вашей [Скрытой атаке](FEATURE:${FEATURE_SNEAK_ATTACK}). При этом Ваша [Скрытая атака](FEATURE:${FEATURE_SNEAK_ATTACK}) считается использованой на этом ходу.`,
    source: {
      id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
      page: 4,
    },
  },
  {
    id: FEATURE_CONNECT_WITH_THE_DEAD_2019_10_17,
    name: `Единство с мёртвыми`,
    nameEn: `Connect with the Dead`,
    lvl: 9,
    text: `Вы можете создать связь с духом существа через его труп. Делая это, Вы сотворяете заклинание [Разговор с мёртвыми](SPELL:${SPELL_SPEAK_WITH_DEAD}), без использования ячейки заклинания или материальных компонентов. Интеллект — Ваша заклинательная способность для него.

Разговор с мёртвыми таким образом временно даёт Вам возможности из Вашей прошлой жизни — Вы не уверены, из Вашего ли прошлого или из прошлого этого духа.

Когда заклинание заканчивается, Вы получаете одно случайное преимущество из таблицы «Перерождённые способности». Это премущество длится до конца короткого или длинного отдыха.

# Перерождённые способности

| к6   | Способность                                                                    |
|------|--------------------------------------------------------------------------------|
| 1–2. | Вы можете говорить, понимать, читать и писать на одном языке по Вашему выбору. |
| 3–4. | Вы получаете владение одним навыком или инструментом по Вашему выбору.         |
| 5–6. | Вы получаете владение одним испытанием по Вашему выбору.                       |

Использовав это умение, Вы не можете использовать его снова, не завершив короткого либо длинного отдыха.`,
    spellIdList: SPELL_SPEAK_WITH_DEAD,
    source: {
      id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
      page: 4,
    },
  },
  {
    id: FEATURE_AUDIENCE_WITH_DEATH_2019_10_17,
    name: `Беседа со Смертью`,
    nameEn: `Audience with Death`,
    text: `На пороге смерти Вы можете общаться с силами Смерти.

Вы получаете преимущество к испытаниям от смерти и каждый раз, проходя испытание от смерти, Ваш дух может задать сущности Смерти один вопрос, на который можно ответить «Да», «Нет» или «Неизвестно». Сущность отвечает правдиво, используя знания всех умерших.

Кроме того, когда Вы, имея 0 хитов, стабилизируетесь или получаете хиты, Вы можете изменить одну из особенностей Вашей личности: черту характера, идеал, привязанность или слабость.`,
    lvl: 13,
    source: {
      id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
      page: 4,
    },
  },
  {
    id: FEATURE_ETHEREAL_JAUNT_2019_10_17,
    name: `Эфирный гуляка`,
    nameEn: `Ethereal Jaunt`,
    text: `Вы получаете способность переходить на Эфирный план и обратно, подобно привидению.

Вы можете использовать [Хитрое действие](FEATURE:${FEATURE_CUNNING_ACTION}) чтобы телепортироваться в незанятое пространство в пределах 30 футов. Вам не нужно видеть это пространство, но Ваша телепортация проваливается, тратя бонусное действие, если Вы пытаетесь телепортироваться через магическое силовое поле среднего или большего размера, такое как [Стена силы](SPELL:${SPELL_WALL_OF_FORCE}).

Если Вы появляетесь в пространстве, занятом другим существом или предметом, Вас немедленно выталкивает в ближайшее незанятое пространство, куда Вы можете поместиться, и получаете урон силовым полем, равный удвоенному расстоянию выталкивания.`,
    lvl: 17,
    source: {
      id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
      page: 4,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_REVIVED_2019_10_17,
  })
)
