const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  SPELL_CLOUDKILL,
  SPELL_FIREBALL,
  SPELL_STINKING_CLOUD,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_NECKLACE_OF_ADAPTATION,
  MAGIC_ITEM_NECKLACE_OF_FIREBALLS,
  MAGIC_ITEM_NINE_LIVES_STEALER,
  MAGIC_ITEM_NOLZURS_MARVELOUS_PIGMENTS,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_NOLZURS_MARVELOUS_PIGMENTS,
    name: `Чудесные краски Нолзура`,
    nameEn: `Nolzur’s Marvelous Pigments`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: `Обычно эти краски находят в 1к4 баночках в красивом деревянном ящике вместе с кисточкой (весь набор весит 1 фунт). Эти краски позволяют рисовать трёхмерные предметы в двух измерениях. Краска сама стекает с кисти и формирует желаемый предмет, когда Вы концентрируетесь на изображении.

Одной баночки достаточно, чтобы покрыть 1 000 квадратных футов поверхности, что позволяет Вам создавать неживые предметы и особенности местности — такие как двери, ямы, цветы, деревья, решётки, комнаты или оружие — не превышающие в объёме 10 000 кубических футов. Разрисовать 100 квадратных футов можно за 10 минут.

Когда Вы заканчиваете рисовать, предмет (или особенность местности) становится реальным, немагическим предметом. Нарисовав дверь на стене, Вы создаёте настоящую дверь, которая ведёт на ту сторону стены. Нарисовав яму на полу, Вы получаете настоящую яму, а её глубина учитывается при подсчёте объёма создаваемого предмета.

То, что создаётся этими красками, не должно стоить больше 25 зм. Если Вы нарисуете что-то, что стоит больше (например, бриллиант или кучу золота), предмет будет выглядеть настоящим, но тщательное исследование даст понять, что он изготовлен из глины, кости или другого бросового материала.

Если Вы нарисуете энергию, такую как огонь или электричество, в конце рисования энергия возникает, но тут же исчезает, не причиняя ничему урон.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 212,
    },
  },
  {
    id: MAGIC_ITEM_NECKLACE_OF_FIREBALLS,
    name: `Ожерелье огненных шаров`,
    nameEn: `Necklace of Fireballs`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `На этом ожерелье висят 1к6 + 3 бусины. Вы можете действием оторвать одну бусину и метнуть её на расстояние до 60 футов. Достигнув конечной точки, бусина детонирует как заклинание [Огненный шар](SPELL:${SPELL_FIREBALL}) 3 уровня (СЛ испытания 15).

Вы можете метнуть одним действием сразу несколько бусин или даже всё ожерелье. При этом увеличьте уровень огненного шара на 1 за каждую бусину после первой.`,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_FIREBALL],
    source: {
      id: SOURCE_DMG,
      page: 187,
    },
  },
  {
    id: MAGIC_ITEM_NECKLACE_OF_ADAPTATION,
    name: `Ожерелье адаптации`,
    nameEn: `Necklace of Adaptation`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Нося это ожерелье, Вы можете нормально дышать в любой окружающей среде и получаете преимущество к испытаниям от вредоносных газов и испарений (таких как эффекты [Облака смерти](SPELL:${SPELL_CLOUDKILL}) и [Зловонного облака](SPELL:${SPELL_STINKING_CLOUD}), вдыхаемых ядов и оружие дыхания некоторых драконов).`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 187,
    },
  },
  {
    id: MAGIC_ITEM_NINE_LIVES_STEALER,
    name: `Вор девяти жизней`,
    nameEn: `Nine Lives Stealer`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч`,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Вы получаете бонус +2 к броскам атаки и урона этим магическим оружием.

У меча есть 1к8 + 1 заряд. Если Вы совершаете критическое попадание по существу, у которого меньше 100 хитов, оно должно пройти испытание Телосложения СЛ 15, иначе мгновенно умрёт, когда меч вырвет его жизненную силу из тела (конструкты и нежить обладают иммунитетом).

Меч теряет 1 заряд, если существо умирает. Когда у меча не останется зарядов, он теряет это свойство.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 158,
    },
  },
]
