const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_AURA_OF_VITALITY,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_AURA_OF_VITALITY,
  name: 'Аура живучести',
  nameEn: `Aura of Vitality`,
  description: `От Вас исходит аура живительной энергии с радиусом 30 футов. Пока заклинание активно, аура перемещается вместе с Вами, оставаясь с центром на Вас. Вы можете бонусным действием восстанавливать одному любому существу в ауре (включая себя) 2к6 хитов.`,
  lvl: 3,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  rangeComment: `30-футовый радиус`,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  source: {
    id: SOURCE_PHB,
    page: 212,
  },
}
