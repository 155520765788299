const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {gearCollection} = require('./../../../../../gearList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../../../genderList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    gearId,
    id,
  }
) => {
  const {
    name,
    nameByCase: {
      accusative: nameAccusative,
    },
    nameEn: gearNameEn,
    genderId,
  } = gearCollection[gearId]

  const {
    adamantine,
    adamantineAccusative,
    pronoun,
    strengthen,
  } = {
    [GENDER_MALE]: {
      adamantine: 'Адамантиновый',
      adamantineAccusative: 'Адамантиновый',
      pronoun: 'Этот',
      strengthen: 'усилен',
    },
    [GENDER_FEMALE]: {
      adamantine: 'Адамантиновая',
      adamantineAccusative: 'Адамантиновую',
      pronoun: 'Эта',
      strengthen: 'усилена',
    },
    [GENDER_MIDDLE]: {
      adamantine: 'Адамантиновое',
      adamantineAccusative: 'Адамантиновое',
      pronoun: 'Это',
      strengthen: 'усилено',
    },
    [GENDER_MULTIPLE]: {
      adamantine: 'Адамантиновые',
      adamantineAccusative: 'Адамантиновые',
      pronoun: 'Эти',
      strengthen: 'усилены',
    },
  }[genderId]

  const itemName = `${adamantine} ${name.toLowerCase()}`

  return {
    id,
    name: itemName,
    nameEn: `Adamantine ${gearNameEn}`,
    type: MGC_TYPE_ARMOR,
    gearType: gearId,
    rarity: MGC_RARITY_UNCOMMON,
    description: `${pronoun} ${name.toLowerCase()} ${strengthen} адамантином, одним из самых прочных из существующих веществ.

Пока Вы носите _${adamantineAccusative} ${nameAccusative.toLowerCase()}_, все критические попадания по Вам считаются обычными попаданиями.`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 151,
    },
  }
}
