const {CREATURE_TYPE_GIANT_STONE} = require('./../../creatureTypeIdList')
const {
  giantStoneNote,
  stoneGiantDescriptionList,
} = require('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GIANT_STONE,
  nameEn: 'Stone Giant',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'каменный великан',
        genitive: 'каменного великана',
        dative: 'каменному великану',
        accusative: 'каменного великана',
        instrumental: 'каменным великаном',
        prepositional: 'каменном великане',
      },
      [GENDER_FEMALE]: {
        nominative: 'каменная великанша',
        genitive: 'каменной великанши',
        dative: 'каменной великанше',
        accusative: 'каменную великаншу',
        instrumental: 'каменной великаншей',
        prepositional: 'каменной великанше',
      },
    },
    plural: {
      nominative: 'каменные великаны',
      genitive: 'каменных великанов',
      dative: 'каменным великанам',
      accusative: 'каменных великанов',
      instrumental: 'каменными великанами',
      prepositional: 'каменных великанах',
    },
  },
  note: giantStoneNote,
  description: stoneGiantDescriptionList,
}
