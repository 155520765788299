const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {CAT_MUSIC_TOOLS} = require('./../../../gearCategoryList')
const {GEAR_ZULKOON} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_SCAG} = require('./../../../sourceList')

module.exports = {
  id: GEAR_ZULKOON,
  genderId: GENDER_MALE,
  name: 'Зулкун',
  nameEn: 'Zulkoon',
  nameByCase: {
    nominative: 'зулкун',
    genitive: 'зулкуна',
    accusative: 'зулкун',
    instrumental: 'зулкуном',
  },
  description: [
    `Замысловатая фисгармония (орган), часто ассоциирующаяся с зулкирами Тея, которые используют инструмент при чтении своих заклинаний. Обычно его отличает драматический, но зловещий звук.`,
    ...musicalToolDescriptionList,
  ],
  source: {
    id: SOURCE_SCAG,
    page: 125,
  },
  category: CAT_MUSIC_TOOLS,
}
