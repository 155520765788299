const {SOURCE_BGDIA} = require('./../../sourceList')
const {CREATURE_NIGHT_BLADE} = require('./../../creatureIdList')
const {GOD_BHAAL} = require('./../../godIdList')

module.exports = [
  {
    header: 'Баал и его последователи',
    text: `[Баал](CREATURE:${GOD_BHAAL}) — бог убийств, дарующий свою мощь тем, чьи убийства вызывают страх и ужас. Но само убийство — лишь половина желаемого. Вторая — подозрения, недоверие и паника, которые способен вызвать поистине неуловимый убийца. Баал жаждет внушать ужас, убивая тех, кто казался недосягаемым для смерти.

Баал всегда носит при себе алый платок. Он способен принимать разные облики, непохожие на его истинный — облик громилы с длинным изогнутым кинжалом, с которого капает кровь. В этой форме он одет лишь в драную набедренную повязку, лишён волос и покрыт кровоточащими ранами от острых лезвий. Его лицо — череп, лишённый плоти и окружённый нимбом из капель крови.

Баал — жестокий садист, обожающий убивать жуткими способами. Его способны сдержать только Бейн и Миркул вместе.

Подражая своему богу, культисты Баала совершают жестокие убийства, чтобы распространять ужас и панику. Они могут быть обезоруживающе милы, когда нужно, но в бою выходят на свет их истинные кровожадные натуры.`,
    source: {
      id: SOURCE_BGDIA,
      page: 237,
    },
  },
  {
    header: 'Кровопускание',
    text: `Все культисты [Баала](CREATURE:${GOD_BHAAL}) умеют призывать мощь своего бога, чтобы сделать жертв уязвимыми для их длинных ножей. Когда магия Баала срабатывает, колотые раны извергают жуткие фонтаны крови. Царапины, казавшиеся мелкими, проникают вглубь тела жертвы и вызывают жуткую боль и кровотечение.`,
    source: {
      id: SOURCE_BGDIA,
      page: 238,
    },
  },
  {
    header: 'Убийцы из тени',
    text: `Последователи [Баала](CREATURE:${GOD_BHAAL}) — коварные убийцы, обожающие сеять страх и ужас в тех городах, где живут. Они призывают мощь своего бога, чтобы легко скрываться в тенях, а то и становиться невидимыми, когда это важно.`,
    source: {
      id: SOURCE_BGDIA,
      page: 239,
    },
  },
  {
    header: 'Иерархия культа',
    text: `Низшие в иерархии культа [Баала](CREATURE:${GOD_BHAAL}) зовутся [ночными клинками](CREATURE:${CREATURE_NIGHT_BLADE}), их Баал наделяет ночным зрением и мастерством скрытности. Следующие над ними — жнецы. Они получают способность к невидимости и с помощью магии Баала могут избегать подозрений. Высшее звание — главы смерти, они могут принимать облик своего бога с черепом вместо лица, чтобы пугать жертв. В бою они внушают врагам ужас, легко выдерживая смертоносные удары и давая понять, что сопротивление планам Баала бесполезно.`,
    source: {
      id: SOURCE_BGDIA,
      page: 239,
    },
  },
]
