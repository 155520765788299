const pickMagicSchoolSpellIdListByLvl = require('./../utils/pickMagicSchoolSpellIdListByLvl')
const { MAGIC_NECROMANCY } = require('./magicList')

const pickSpellIdListByLvl = pickMagicSchoolSpellIdListByLvl(MAGIC_NECROMANCY)

module.exports = [
  pickSpellIdListByLvl(0),
  pickSpellIdListByLvl(1),
  pickSpellIdListByLvl(2),
  pickSpellIdListByLvl(3),
  pickSpellIdListByLvl(4),
  pickSpellIdListByLvl(5),
  pickSpellIdListByLvl(6),
  pickSpellIdListByLvl(7),
  pickSpellIdListByLvl(8),
  pickSpellIdListByLvl(9),
]
