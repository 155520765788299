module.exports = [
  require('./creatureTypes/aarakocra'),
  require('./creatureTypes/aasimar'),
  require('./creatureTypes/aberration'),
  require('./creatureTypes/abishai'),
  require('./creatureTypes/anvilwrought'),
  require('./creatureTypes/any_race'),
  require('./creatureTypes/archdevil'),
  require('./creatureTypes/archon'),
  require('./creatureTypes/autognome'),
  require('./creatureTypes/beast'),
  require('./creatureTypes/beholder_keen'),
  require('./creatureTypes/blindheim'),
  require('./creatureTypes/bugbear'),
  require('./creatureTypes/bullywug'),
  require('./creatureTypes/celestial'),
  require('./creatureTypes/centaur'),
  require('./creatureTypes/cerberus'),
  require('./creatureTypes/changeling'),
  require('./creatureTypes/construct'),
  require('./creatureTypes/darkling'),
  require('./creatureTypes/demon'),
  require('./creatureTypes/demon_lord'),
  require('./creatureTypes/derro'),
  require('./creatureTypes/devil'),
  require('./creatureTypes/dhampir'),
  require('./creatureTypes/dinosaur'),
  require('./creatureTypes/dire_corby'),
  require('./creatureTypes/dragon'),
  require('./creatureTypes/dragon_chromatic'),
  require('./creatureTypes/dragon_gem'),
  require('./creatureTypes/dragon_metallic'),
  require('./creatureTypes/dragon_true'),
  require('./creatureTypes/dragonborn'),
  require('./creatureTypes/drake'),
  require('./creatureTypes/drow'),
  require('./creatureTypes/duergar'),
  require('./creatureTypes/dwarf'),
  require('./creatureTypes/eladrin'),
  require('./creatureTypes/elemental'),
  require('./creatureTypes/elemental_air'),
  require('./creatureTypes/elemental_earth'),
  require('./creatureTypes/elemental_fire'),
  require('./creatureTypes/elemental_water'),
  require('./creatureTypes/elf'),
  require('./creatureTypes/fairy'),
  require('./creatureTypes/fey'),
  require('./creatureTypes/fiend'),
  require('./creatureTypes/filborg'),
  require('./creatureTypes/firenewt'),
  require('./creatureTypes/genasi_air'),
  require('./creatureTypes/genasi_earth'),
  require('./creatureTypes/genasi_fire'),
  require('./creatureTypes/genasi_water'),
  require('./creatureTypes/ghost'),
  require('./creatureTypes/giant'),
  require('./creatureTypes/giant_cloud'),
  require('./creatureTypes/giant_fire'),
  require('./creatureTypes/giant_frost'),
  require('./creatureTypes/giant_hill'),
  require('./creatureTypes/giant_stone'),
  require('./creatureTypes/giant_storm'),
  require('./creatureTypes/giff'),
  require('./creatureTypes/gith'),
  require('./creatureTypes/githyanki'),
  require('./creatureTypes/githzerai'),
  require('./creatureTypes/glitchling'),
  require('./creatureTypes/gnoll'),
  require('./creatureTypes/gnome'),
  require('./creatureTypes/goblin'),
  require('./creatureTypes/goblinoid'),
  require('./creatureTypes/goliath'),
  require('./creatureTypes/grimlock'),
  require('./creatureTypes/grung'),
  require('./creatureTypes/hadozee'),
  require('./creatureTypes/hag'),
  require('./creatureTypes/half_elf'),
  require('./creatureTypes/half_orc'),
  require('./creatureTypes/halfling'),
  require('./creatureTypes/harengon'),
  require('./creatureTypes/hexblood'),
  require('./creatureTypes/hobgoblin'),
  require('./creatureTypes/homunculus'),
  require('./creatureTypes/human'),
  require('./creatureTypes/humanoid'),
  require('./creatureTypes/illithid'),
  require('./creatureTypes/inevitable'),
  require('./creatureTypes/jermlaine'),
  require('./creatureTypes/kalashtar'),
  require('./creatureTypes/kender'),
  require('./creatureTypes/kenku'),
  require('./creatureTypes/kobold'),
  require('./creatureTypes/kruthik'),
  require('./creatureTypes/kuo_toa'),
  require('./creatureTypes/leonin'),
  require('./creatureTypes/lizardfolk'),
  require('./creatureTypes/locathah'),
  require('./creatureTypes/loxodon'),
  require('./creatureTypes/lycanthrope'),
  require('./creatureTypes/magen'),
  require('./creatureTypes/meazel'),
  require('./creatureTypes/mephit'),
  require('./creatureTypes/merfolk'),
  require('./creatureTypes/mimic'),
  require('./creatureTypes/minotaur'),
  require('./creatureTypes/modrone'),
  require('./creatureTypes/monstrosity'),
  require('./creatureTypes/myconid'),
  require('./creatureTypes/myrmidon'),
  require('./creatureTypes/naga'),
  require('./creatureTypes/nagpa'),
  require('./creatureTypes/needlefolk'),
  require('./creatureTypes/neogi'),
  require('./creatureTypes/norker'),
  require('./creatureTypes/ogre'),
  require('./creatureTypes/ooze'),
  require('./creatureTypes/orc'),
  require('./creatureTypes/owlin'),
  require('./creatureTypes/plant'),
  require('./creatureTypes/plasmoid'),
  require('./creatureTypes/quaggoth'),
  require('./creatureTypes/reborn'),
  require('./creatureTypes/sahuagin'),
  require('./creatureTypes/satyr'),
  require('./creatureTypes/shadar_kai'),
  require('./creatureTypes/shadow_mastiff'),
  require('./creatureTypes/shapeshifter'),
  require('./creatureTypes/shifter'),
  require('./creatureTypes/simic_hybrid'),
  require('./creatureTypes/skeleton'),
  require('./creatureTypes/slaad'),
  require('./creatureTypes/sorrow_sworn'),
  require('./creatureTypes/star_spawn'),
  require('./creatureTypes/tabaxi'),
  require('./creatureTypes/thri_kreen'),
  require('./creatureTypes/tiefling'),
  require('./creatureTypes/titan'),
  require('./creatureTypes/tortle'),
  require('./creatureTypes/triton'),
  require('./creatureTypes/troglodyte'),
  require('./creatureTypes/troll'),
  require('./creatureTypes/undead'),
  require('./creatureTypes/vedalken'),
  require('./creatureTypes/vegepygmy'),
  require('./creatureTypes/verdan'),
  require('./creatureTypes/warforged'),
  require('./creatureTypes/xvart'),
  require('./creatureTypes/yakfolk'),
  require('./creatureTypes/yuan_ti'),
  require('./creatureTypes/yugoloth'),
]
