const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CREATURE_AERISI_KALINOTH} = require('./../../../../creatureIdList')
const {CR_7} = require('./../../../../crList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {howlingHatredDescriptionList} = require('./../../../../textCommonParts')
const {MAGIC_ITEM_WINDVANE} = require('./../../../../magicItemIdList')
const {
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPELL_CHAIN_LIGHTNING,
  SPELL_CHARM_PERSON,
  SPELL_CLOUDKILL,
  SPELL_DUST_DEVIL,
  SPELL_FEATHER_FALL,
  SPELL_FLY,
  SPELL_GASEOUS_FORM,
  SPELL_GUST,
  SPELL_GUST_OF_WIND,
  SPELL_ICE_STORM,
  SPELL_INVISIBILITY,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_PRESTIDIGITATION,
  SPELL_RAY_OF_FROST,
  SPELL_SEEMING,
  SPELL_SHOCKING_GRASP,
  SPELL_STORM_SPHERE,
  SPELL_THUNDERWAVE,
} = require('./../../../../spellIdList')
const {
  DAMAGE_ELECTRICITY,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  LANG_AURAN,
  LANG_COMMON,
  LANG_ELVEN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_LEGENDARY_RESISTANCE_2,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Аэриси Калинос',
  nameAlt: ['Дара Альгвиненн Калинос', 'Аериси Калинос'],
  nameEn: 'Aerisi Kalinoth',
  nameEnAlt: 'Dara Algwynenn Kalinoth',
  id: CREATURE_AERISI_KALINOTH,
  hasSpoilers: true,
  description: [
    {
      header: 'Аэриси Калинос',
      text: `Аэриси Калинос — пророк воздуха Древнего Стихийного Ока и лидер культа Воющей Ненависти — это высокая, стройная, черноволосая эльфийка, за спиной которой колышутся иллюзорные пернатые крылья, создающие нежное дуновение ветерка. Аэриси разговаривает со своими людьми шёпотом, что контрастирует с её вспыльчивой натурой, проявляющейся, когда ей перечат.
      
Раньше Аэриси носила имя Дара Альгвиненн Калинос. Она выросла в далёком королевстве фей и с возрастом должна была стать принцессой лунных эльфов. Родители хотели защитить Дару от сурового реального мира и прятали её на родине, но это лишь испортило её. Когда они попытались призвать свою своенравную дочь к порядку, она использовала против них силу стихийного воздуха. Вскоре после этого видения привели Дару к древним дварфийским руинам, где её дожидалось копьё Перст Ветра.

Она сменила своё имя на Аэриси и притворилась принцессой авариэлей — крылатых эльфов из сказок, которыми она зачитывалась в детстве. Затем Аэриси использовала свой талант в магии очарования, чтобы склонить смертных присоединиться к её культу. Она убедила всех своих последователей, что действительно происходит из рода авариэлей, и, кажется, поверила в это сама, хотя ей и приходится каждый день произносить заклинание [Притворство](SPELL:${SPELL_SEEMING}), чтобы «явить» свои крылья.

Аэриси склонна к бредовым полётам фантазии и импульсивной распущенности. Она видит себя прекрасной, свирепой и справедливой правительницей, которая способна управлять силой стихий, потому что этого заслуживает.`,
      source: {
        id: SOURCE_PotA,
        page: 198,
      },
    },
    {
      header: 'В узле воздуха',
      text: `Когда храму Воющей Ненависти угрожает опасность, Аэриси отступает в Воющие Пещеры к узлу воздуха. Здесь Аэриси получает дополнительное использование её способности «Легендарное сопротивление».`,
      source: {
        id: SOURCE_PotA,
        page: 198,
      },
    },
    {
      header: 'Действия логова',
      text: `Если Аэриси находится в узле воздуха, без Ян-СиБина, она может использовать действия логова. При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) она может использовать действие логова, чтобы сотворить одно из своих заклинаний не выше 3 круга без использования компонентов и ячеек заклинаний. Она не может использовать одно и то же заклинание два раунда подряд, хотя она может продолжить концентрироваться на заклинании, вызванном действием логова ранее. Аэриси не может совершать иных действий логова, если она концентрируется на заклинании, вызванном действием логова.

Если Аэриси произносит заклинание [Невидимость](SPELL:${SPELL_INVISIBILITY}) действием логова, она также вбирает в себя силу узла воздуха, восстанавливая 15 (3к8 + 2) хитов.

Аэриси в своём логове имеет показатель опасности 9 (5 000 опыта).`,
      source: {
        id: SOURCE_PotA,
        page: 198,
      },
    },
    ...howlingHatredDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 198,
  },
  armor: [
    13,
    {
      ac: 16,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 12,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AURAN,
    LANG_COMMON,
    LANG_ELVEN,
  ],
  cr: CR_7,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_LEGENDARY_RESISTANCE_2,
    {
      name: 'Посмертный вой',
      nameEn: 'Howling Defeat',
      description: `Когда хиты ★СУЩЕСТВО★ опускаются до 0, ★его★ тело исчезает в завывающем вихре, который вскоре быстро и безвредно рассеивается. Всё, что ★он★ ★нёс★ и ★носил★, остаётся лежать на месте.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 12,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_CHAIN_LIGHTNING,
      SPELL_CHARM_PERSON,
      SPELL_CLOUDKILL,
      SPELL_DUST_DEVIL,
      SPELL_FEATHER_FALL,
      SPELL_FLY,
      SPELL_GASEOUS_FORM,
      SPELL_GUST,
      SPELL_GUST_OF_WIND,
      SPELL_ICE_STORM,
      SPELL_INVISIBILITY,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGE_ARMOR,
      SPELL_MAGE_HAND,
      SPELL_MESSAGE,
      SPELL_PRESTIDIGITATION,
      SPELL_RAY_OF_FROST,
      {
        id: SPELL_SEEMING,
        comment: 'сотворяет на себя каждый день',
      },
      SPELL_SHOCKING_GRASP,
      SPELL_STORM_SPHERE,
      SPELL_THUNDERWAVE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
    ],
  },
  actionList: [
    {
      magicItemId: MAGIC_ITEM_WINDVANE,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 6,
              diceBonus: 5,
            },
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 8,
              diceBonus: 5,
              use2Hand: true,
            },
          ],
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
    {
      magicItemId: MAGIC_ITEM_WINDVANE,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 8,
        range: {
          normal: 20,
          max: 60,
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 5,
          },
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
