const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1_8,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_ANY,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ANY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  GEAR_BREASTPLATE,
  GEAR_RAPIER,
} = require('./../../../../gearIdList')
const {
  ABILITY_PARRY,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_COMMONER,
  CREATURE_GUARD,
  CREATURE_NOBLE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дворянин',
  nameEn: 'Noble',
  id: CREATURE_NOBLE,
  description: `Дворяне обладают большой властью и влиянием, будучи представителями высшего класса. У них есть богатство и связи, делающие их такими же влиятельными как монархи и генералы. Дворяне часто путешествуют в сопровождении [стражей](CREATURE:${CREATURE_GUARD}), а также слуг, являющихся [обывателями](CREATURE:${CREATURE_COMMONER}).

Статистику дворянина можно использовать для отыгрыша придворного без благородного происхождения.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 345,
  },
  armor: {
    ac: 15,
    gearId: GEAR_BREASTPLATE,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 11,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_1_8,
  actionList: [
    {
      gearId: GEAR_RAPIER,
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 2,
    },
  ],
  genderId: GENDER_MALE,
}
