module.exports = [
  require('./catapult'),
  require('./catnap'),
  require('./cause_fear'),
  require('./ceremony'),
  require('./chaos_bolt'),
  require('./charm_monster'),
  require('./control_flames'),
  require('./control_winds'),
  require('./create_bonfire'),
  require('./create_homunculus'),
  require('./crown_of_stars'),
]
