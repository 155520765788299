const {SPELL_CREATE_MAGEN} = require('./../../spellIdList')
const {SOURCE_IDRotF} = require('./../../sourceList')
const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')

module.exports = {
  header: 'Магены',
  text: `Магены — магические человекоподобные создания, создаваемые заклинанием [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) [Создание магена](SPELL:${SPELL_CREATE_MAGEN}) или иными волшебными методами.

Хотя магены выглядят как зеленокожие гуманоиды, они всё же конструкты. Будучи ранеными, они кровоточат кровью цвета и консистентности ртути. Они существуют только благодаря магии. Если убить магена, его тело исчезнет во вспышке безвредного пламени и лёгком облачке дыма.

# Магические слуги

Магены — идеальные слуги. При создании каждый маген наполняется инстинктом защищать себя и своего создателя и беспрекословно следовать всем приказам создателя. Выполнив все полученные приказы, маген встанет тихо и неподвижно, пока не получит новые.`,
  source: {
    id: SOURCE_IDRotF,
    page: 301,
  },
}
