const {CAT_CLOTHES} = require('./../../../../gearCategoryList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {MAGIC_ITEM_KNAVE_S_EYE_PATCH} = require('./../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {SOURCE_WDH} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_KNAVE_S_EYE_PATCH,
  name: `Глазная повязка валета`,
  nameEn: 'Knave’s Eye Patch',
  type: MGC_TYPE_WONDROUS_ITEM,
  gearCategory: CAT_CLOTHES,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Пока вы носите эту повязку, Вы получаете следующие преимущества:

* У Вас преимущество к проверкам Мудрости (Внимание), основанным на зрении.
* Если у Вас была способность «Чувствительность к солнцу», она на Вас не действует.
* Вы невосприимчивы к магии, позволяющей другим существам читать Ваши мысли или понимать, что Вы лжёте. Существа могут телепатически общаться с Вами только с Вашего позволения.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_WDH,
    page: 189,
  },
}
