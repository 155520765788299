const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_12,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_TRUE_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_LESSER_RESTORATION,
} = require('./../../../../spellIdList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_PLATE_ARMOR,
} = require('./../../../../gearIdList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_PARRY,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_ERINYES,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Эриния',
  nameEn: 'Erinyes',
  id: CREATURE_ERINYES,
  description: [
    `Самые прекрасные и завораживающие из всех младших и старших дьяволов — это эринии, дисциплинированные и яростные воины. Пикируя с небес, они несут быструю смерть существам, предавшим их повелителей или нарушившим эдикты Асмодея. Эринии выглядят как гуманоиды мужского или женского пола с фигурами, достойными изваяний, и большими пернатыми крыльями. Большинство одето в стилизованную броню и рогатые шлемы, и пользуется изысканными мечами и луками. У некоторых при себе есть верёвки опутывания, чтобы обездвиживать сильных противников.

Легенды гласят, что первые эринии были ангелами, падшими с Верхних Планов из-за какого-то проступка или искушения. Эринии всегда пользуются возможностью быть неверно принятыми за ангела, чтобы легче выполнить свою миссию по завоеванию или совращению.`,
    ...devilDescriptionList,
  ],
  note: [
    {
      text: `Они живут мечом и им же убивают. Их красота — ничто по сравнению с их гневом`,
      author: `из КНИГИ МЕРЗКОЙ ТЬМЫ`,
    },
    ...devilNote,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 152,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 18,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_12,
  featureList: [
    {
      name: 'Адское оружие',
      description: `Атаки оружием ★СУЩЕСТВО★ являются магическими, и причиняют при попадании дополнительный урон ядом 13 (3к8) (уже учтён в атаках).`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки.`,
    },
    {
      gearId: GEAR_LONGSWORD,
      attack: {
        hit: {
          type: DAMAGE_POISON,
          isDamageByEquipment: true,
          diceCount: 3,
          diceType: 8,
        },
      },
    },
    {
      gearId: GEAR_LONGBOW,
      description: `Цель должна пройти испытание Телосложения СЛ 14, иначе станет отравленной. Яд действует до тех пор, пока не будет устранён заклинанием [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}) или подобной магией.`,
      attack: {
        hit: {
          type: DAMAGE_POISON,
          diceCount: 3,
          diceType: 8,
        },
      },
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 4,
    },
  ],
  genderId: GENDER_FEMALE,
}
