const {DAMAGE_FIRE} = require('./../../../damageTypeList')
const {GEAR_GUNPOWDER_POWDER_HORN} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {PARAM_DEX} = require('./../../../paramList')
const {
  CAT_GUNPOWDER,
  CAT_GOODS,
  CAT_EXPLOSIVES,
  CAT_TECH_RENAISSANCE,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_GUNPOWDER_POWDER_HORN,
  name: 'Порох, рожок',
  nameEn: 'Gunpowder, powder horn',
  nameByCase: {
    nominative: 'рожок пороха',
    genitive: 'рожка пороха',
    accusative: 'рожок пороха',
    instrumental: 'рожком пороха',
  },
  description: {
    header: 'Рожок пороха',
    text: `Поджигание рожка с порохом может вызвать взрыв, причиняющий 3к6 урона огнём всем существам в пределах 10 футов. Успех в испытании Ловкости СЛ 12 уменьшает урон вдвое.`,
    source: {
      id: SOURCE_DMG,
      page: 267,
    },
  },
  cost: 3500,
  weight: 2,
  range: 10,
  damageType: DAMAGE_FIRE,
  damage: {
    diceCount: 3,
    diceType: 6,
  },
  saveThrow: {
    type: PARAM_DEX,
    dc: 12,
  },
  genderId: GENDER_MALE,
  category: [
    CAT_GUNPOWDER,
    CAT_GOODS,
    CAT_EXPLOSIVES,
    CAT_TECH_RENAISSANCE,
  ],
  source: {
    id: SOURCE_DMG,
    page: 267,
  },
}
