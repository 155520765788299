const {LANG_ALL_KNOWN_AT_PREVIOUS_FORM} = require('./../../languageIdList')

module.exports = {
  id: LANG_ALL_KNOWN_AT_PREVIOUS_FORM,
  name: {
    nominative: 'Все языки, известные в предыдущей форме',
    genitive: 'Всех языков, известных в предыдущей форме',
    instrumental: 'Всеми языками, известными в предыдущей форме',
    prepositional: 'Всех языках, известных в предыдущей форме',
  },
  isReady: false,
  isRealLang: false,
}
