const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_10,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ELEMENTAL,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    LANG_TERRAN,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_FLY,
  } = require('./../../../../speedList'),
  {
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
    DAMAGE_PIERCING,
    DAMAGE_POISON,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_FALSE_APPEARANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    gargoyleDescription,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_GIANT_FOUR_ARMED_GARGOYLE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гигантская четырёхрукая горгулья',
  nameEn: 'Giant Four-Armed Gargoyle',
  id: CREATURE_GIANT_FOUR_ARMED_GARGOYLE,
  description: [
    `Только Ацерерак знает секрет создания этих существ.
  
Гигантская четырёхрукая горгулья высотой 8-9 футов и весит около пяти тысяч фунтов. Обычно их используют в качестве стражей гробниц, они раздирают злоумышленников своими клыками и смертоносными когтями.`,
    ...gargoyleDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_TOA,
    page: 218,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 20,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 9,
  },
  saveThrowList: [
    PARAM_INT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_PETRIFIED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_TERRAN,
  ],
  cr: CR_10,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `неживой статуи`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает пять атак: одну _Укусом_ и четыре _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
  ],
}
