const {
    SPELL_COLOR_SPRAY,
    SPELL_DANCING_LIGHTS,
    SPELL_MAGE_HAND,
    SPELL_MINOR_ILLUSION,
    SPELL_MIRROR_IMAGE,
  } = require('./../../../../spellIdList'),
  {
    CREATURE_FAERIE_DRAGON_ORANGE,
    CREATURE_FAERIE_DRAGON_YELLOW,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Жёлтый волшебный дракончик',
  nameEn: 'Yellow Faerie Dragon',
  id: CREATURE_FAERIE_DRAGON_YELLOW,
  parentId: CREATURE_FAERIE_DRAGON_ORANGE,
  extendPropCollection: {
    spellCast: {
      spellIdByCountList: [
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_MAGE_HAND,
            SPELL_MINOR_ILLUSION,
            SPELL_DANCING_LIGHTS,
            SPELL_COLOR_SPRAY,
            SPELL_MIRROR_IMAGE,
          ],
        },
      ],
    },
  },
}
