const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_COLD} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_FROSTBITE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FROSTBITE,
  name: 'Обморожение',
  nameEn: 'Frostbite',
  description: `Сильный холод окружает одно существо, которое Вы можете видеть в пределах дистанции заклинания. Цель должна пройти испытание Телосложения. При провале цель получает урон холодом 1к6 и следующий бросок атаки оружием до конца своего следующего хода совершает с помехой.`,
  highLvlCast: `Урон заклинания увеличивается на 1к6, когда Вы достигаете 5 уровня (2к6), 11 уровня (3к6) и 17 уровня (4к6).`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_COLD,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 159,
    },
    {
      id: SOURCE_EE,
      page: 19,
    },
  ],
}
