module.exports = [
  require('./mage'),
  require('./magma_mephit'),
  require('./magmin'),
  require('./mammoth'),
  require('./manes'),
  require('./manticore'),
  require('./marid'),
  require('./marilith'),
  require('./mastiff'),
  require('./medusa'),
  require('./merfolk'),
  require('./merrow'),
  require('./mezzoloth'),
  require('./mimic'),
  require('./mind_flayer'),
  require('./minotaur'),
  require('./minotaur_skeleton'),
  require('./monodrone'),
  require('./mud_mephit'),
  require('./mule'),
  require('./mummy'),
  require('./mummy_lord'),
  require('./myconid_adult'),
  require('./myconid_sovereign'),
  require('./myconid_sprout'),
]
