const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_GIANT,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_FOMORIAN} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_GIANT} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PC_RACE_ELF} = require('./../../../../pcRaceIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Фомор`,
  nameEn: 'Fomorian',
  id: CREATURE_FOMORIAN,
  description: [
    `Безбожные фоморы — наиболее ужасные и злобные из всех великанов. Их искажённые тела отражают их отвратительные повадки. У некоторых черты лица произвольно расположены на бесформенных, покрытых бородавками головах. Другие обладают разными по размеру конечностями или издают ужасный вой каждый раз, когда втягивают воздух через деформированные рты. Их жалкий внешний вид редко вызывает симпатию, ведь погрязшие во зле, руководящем их сердцами и мыслями, фоморы сами обрекли себя на эту незавидную участь.`,
    {
      header: 'Проклятье фей',
      text: `[Эльфы](PC_RACE:${PC_RACE_ELF}) помнят те времена, когда фоморы были наиболее прекрасной из рас, обладателями блестящих умов и непревзойдённых магических способностей. Но это физическое совершенство не распространилось на их сердца, которые снедала жажда магии и могущества.

Фоморы стремились завоевать Страну Фей, поработить её обитателей и подчинить себе их магию. Тогда феи объединились в борьбе за свой План и навели на фоморов ужасное проклятье.

Один за одним падали эти великаны, по мере того, как коверкались их тела, отражая злобу их сердец. Лишённые своей привлекательности и магических сил, эти жалкие уроды бежали подальше от света, закопавшись глубоко под миром, где они вынашивают свою ненависть. Проклиная свою судьбу, с тех пор они готовят месть своим обидчикам-феям.`,
      source: {
        id: SOURCE_MM,
        page: 291,
      },
    },
    {
      header: 'Великаны Подземья',
      text: `Фоморы обитают в устрашающе прекрасных пещерах Подземья, редко рискуя подниматься на поверхность. В их пещерах есть доступ к воде, рыбе и грибным лесам, равно как и к существам, чей рабский труд обеспечивает фоморов питанием. Когда же эти рабы слабеют от непосильного труда, их убивают и пожирают. Порочность и злодеяния являются краеугольными камнями общества фоморов, правят которым самые сильные и жестокие из этих великанов. Фоморы отмечают границу своих территорий, используя тела своих врагов — исписывают их кровью стены своих пещер или сшивают части их трупов в глумливой пародии на убитых ими существ.`,
      source: {
        id: SOURCE_MM,
        page: 291,
      },
    },
    {
      header: 'В гиблом теле злобный дух',
      text: `Уродство, доставшееся фоморам от проклятья, не даёт им в бою швырять камни, как это делает большинство их родичей-великанов или носить какую-нибудь одежду, кроме обрывков тряпья. Тем не менее, гротескно расположенные на их уродливых лицах носы, уши и глаза дают фоморам превосходные чувства восприятия, что делает почти невозможным застать фомора врасплох или устроить на него засаду.

Жадность и злоба лежат в основе их вырождения и падения, и продолжает изводить их. Фоморы заключают союзы с другими существами, когда им это выгодно, но они бесчестны по своей природе и предают своих союзников по первой же прихоти.`,
      source: {
        id: SOURCE_MM,
        page: 291,
      },
    },
    {
      header: 'Проклятье дурного глаза',
      text: `Фоморы могут передавать своё проклятие другим, используя силу сглаза — последний отголосок их некогда превосходных магических способностей. Существо, проклятое дурным глазом фомора, магически деформируется и уродуется, получая лишь намёк на боль и ненависть, поглотившие эту злую расу.`,
      source: {
        id: SOURCE_MM,
        page: 291,
      },
    },
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 291,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 20,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_GIANT,
    LANG_UNDERCOMMON,
  ],
  cr: CR_8,
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Палицей_, либо совершает одну атаку _Палицей_ и один раз использует _Дурной глаз_.`,
    },
    {
      name: `Палица`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: `Дурной глаз`,
      description: `★СУЩЕСТВО★ магическим образом заставляет существо, которое видит в пределах 60 футов от себя, пройти испытание Харизмы СЛ 14. Существо получает урон психической энергией 27 (6к8) при провале, или половину этого урона при успехе.`,
    },
    {
      name: `Проклятье дурного глаза`,
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `Пристально уставившись на существо, ★СУЩЕСТВО★ использует _Дурной глаз_, но при провале цель также получает проклятье магического уродства. Будучи проклятым, существо уменьшает свою скорость вдвое, и совершает с помехой проверки характеристик, испытания и атаки, основанные на Силе и Ловкости. Обезображенное существо может повторять испытания каждый раз, когда оканчивает длинный отдых, оканчивая эффект при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
