const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GEAR_CROSSBOW_HEAVY,
  GEAR_HALBERD,
} = require('./../../../../gearIdList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_MERREGON} = require('./../../../../creatureIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Меррегон',
  nameEn: 'Merregon',
  id: CREATURE_MERREGON,
  description: [
    `Души павших солдат, наёмников и телохранителей, которые служили злу без оговорок, часто находили вечное рабство в Девяти Преисподних в качестве меррегонов. Эти безликие пехотинцы — адские легионеры, которым поручено защищать царство и его правителей от злоумышленников.`,
    {
      header: 'Маски единообразия',
      text: `У меррегонов нет индивидуальности и, следовательно, нет необходимости в лицах. У каждого легионера меррегона есть металлическая маска, прикреплённая к его голове. Маркировка на маске указывает на единственные элементы идентичности владельца, которые имеют значение: слой Девяти Преисподней, и какому командиру он служит.`,
      source: {
        id: SOURCE_MTOF,
        page: 181,
      },
    },
    {
      header: 'Бесстрашное послушание',
      text: `Из-за их непоколебимой лояльности, меррегоны составляют основу многих защитных свит дьяволов. Они не уклоняются от какой-либо задачи, независимо от того, насколько она опасна. Если им не приказали отступить, они не отступят из боя.`,
      source: {
        id: SOURCE_MTOF,
        page: 181,
      },
    },
    ...devilDescriptionList,
  ],
  note: [
    {
      text: `Меррегоны не могут говорить, а общаются они друг с другом с помощью телепатии. Если Вы убьёте их хозяев, в их рядах поселится хаос.`,
      author: `Архимаг Морденкайнен`,
    },
    ...devilNote,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 181,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 17,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 8,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_INFERNAL,
      doNotSpeak: true,
    },
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Алебардой_, или, если в пределах 60 фт от ★него★ есть союзное исчадие с уровнем опасности 6 и выше, то три атаки _Алебардой_.`,
    },
    {
      gearId: GEAR_HALBERD,
    },
    {
      gearId: GEAR_CROSSBOW_HEAVY,
    },
  ],
  reactionList: [
    {
      name: 'Верный телохранитель',
      description: `Если другое исчадие в пределах 5 фт от ★него★ поражается атакой, ★СУЩЕСТВО★ подставляется под неё, получая урон вместо цели.`,
    },
  ],
}
