const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_FEAR,
  SPELL_SPEAK_WITH_ANIMALS,
} = require('./../../../../../spellIdList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MAGIC_ITEM_RING_OF_ANIMAL_INFLUENCE} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_RING_OF_ANIMAL_INFLUENCE,
  name: `Кольцо влияния на животных`,
  nameEn: `Ring of Animal Influence`,
  type: MGC_TYPE_RING,
  rarity: MGC_RARITY_RARE,
  description: `У этого кольца есть 3 заряда, и оно ежедневно восстанавливает 1к3 заряда на рассвете. Нося это кольцо, Вы можете действием потратить 1 заряд, чтобы наложить одно из следующих заклинаний:

* [Дружба с животными](SPELL:${SPELL_ANIMAL_FRIENDSHIP}) (СЛ испытания 13).
* [Ужас](SPELL:${SPELL_FEAR}) (СЛ испытания 13), нацеливается только на зверей с Интеллектом 3 или ниже.
* [Разговор с животными](SPELL:${SPELL_SPEAK_WITH_ANIMALS}).`,
  genderId: GENDER_MIDDLE,
  spellIdList: [
    SPELL_ANIMAL_FRIENDSHIP,
    SPELL_FEAR,
    SPELL_SPEAK_WITH_ANIMALS,
  ],
  source: {
    id: SOURCE_DMG,
    page: 173,
  },
}
