const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_FAR_STEP} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FAR_STEP,
  name: 'Далёкий шаг',
  nameEn: 'Far Step',
  description: `Вы телепортируетесь до 60 футов в свободное пространство, видимое вами. Каждый Ваш ход до окончания действия заклинания Вы можете использовать бонусное действие, чтобы телепортироваться таким образом снова.`,
  lvl: 5,
  magicSchoolId: MAGIC_CONJURATION,
  range: -1,
  componentIdList: [CAST_VERBAL],
  duration: {timeId: TIME_MINUTE, count: 1},
  castTime: {timeId: TIME_BONUS_ACTION, count: 1},
  needConcentration: true,
  source: {
    id: SOURCE_XGTE,
    page: 151,
  },
}
