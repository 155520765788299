const {SOURCE_TCoE} = require('./../../../sourceList')
const {druidCircleSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE} = require('./../../../pcSubClassIdList')
const {CREATURE_WILDFIRE_SPIRIT} = require('./../../../creatureIdList')

const {
  SPELL_AURA_OF_LIFE,
  SPELL_BURNING_HANDS,
  SPELL_CURE_WOUNDS,
  SPELL_FIRE_SHIELD,
  SPELL_FLAME_STRIKE,
  SPELL_FLAMING_SPHERE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_PLANT_GROWTH,
  SPELL_REVIVIFY,
  SPELL_SCORCHING_RAY,
} = require('./../../../spellIdList')

const {
  FEATURE_BLAZING_REVIVAL,
  FEATURE_CAUTERIZING_FLAMES,
  FEATURE_CIRCLE_OF_WILDFIRE_SPELLS,
  FEATURE_ENHANCED_BOND,
  FEATURE_SUMMON_WILDFIRE_SPIRIT,
  FEATURE_WILD_SHAPE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_CIRCLE_OF_WILDFIRE_SPELLS,
    name: `Заклинания круга лесного пожара`,
    nameEn: `Circle of Wildfire Spells`,
    lvl: 2,
    text: `Вы установили связь с духом лесного пожара, первобытной сущностью созидания и разрушения. Ваша связь с этим духом даёт Вам доступ к некоторым заклинаниям при достижении определённых уровней в этом классе.

Как только Вы получаете доступ к одному из этих заклинаний, оно всегда будет у Вас подготовлено, не учитывается в количестве заклинаний, которые Вы можете подготавливать и считается для Вас заклинанием друида.`,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_AURA_OF_LIFE,
      SPELL_BURNING_HANDS,
      SPELL_CURE_WOUNDS,
      SPELL_FIRE_SHIELD,
      SPELL_FLAME_STRIKE,
      SPELL_FLAMING_SPHERE,
      SPELL_MASS_CURE_WOUNDS,
      SPELL_PLANT_GROWTH,
      SPELL_REVIVIFY,
      SPELL_SCORCHING_RAY,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 56,
    },
  },
  {
    id: FEATURE_SUMMON_WILDFIRE_SPIRIT,
    name: `Призыв духа лесного пожара`,
    nameEn: `Summon Wildfire Spirit`,
    creatureId: CREATURE_WILDFIRE_SPIRIT,
    lvl: 2,
    text: `Вы можете призвать первобытного духа, привязанного к Вашей душе.

Вы можете действием потратить одно использование [Дикого облика](FEATURE:${FEATURE_WILD_SHAPE}), чтобы призвать дух лесного пожара, а не принимать форму зверя.

Дух появляется в видимом Вами свободном пространстве в пределах 30 футов. Каждое существо в пределах 10 футов от духа (кроме Вас), когда оно появляется, должно пройти испытание Ловкости против СЛ Ваших заклинаний, или получит 2к6 урона огнём.

Дух дружелюбен к Вам и Вашим спутникам и подчиняется Вашим командам. Вы определяете внешний вид духа. Некоторые духи принимают форму гуманоидной фигуры, состоящей из узловатых ветвей, покрытых огнем, в то время как другие выглядят как звери, обвитые огнём.

В бою дух действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Единственное действие, которое он выполняет в свой ход — Уклонение, если только Вы бонусным действием на своём ходу не прикажете ему совершить другое действие. Это действие может быть как одним из действий в его статблоке, так и любым другим. Если Вы недееспособны, дух может выполнять любые действие по своему выбору, а не только Уклоняться.

Дух проявляется в течение 1 часа, пока его хиты не уменьшатся до 0, пока Вы не воспользуетесь этой способностью для повторного вызова духа или пока не умрёте.`,
    source: {
      id: SOURCE_TCoE,
      page: 56,
    },
  },
  {
    id: FEATURE_ENHANCED_BOND,
    name: `Улучшенная связь`,
    nameEn: `Enhanced Bond`,
    lvl: 6,
    text: `Связь с духом лесного пожара усиливает Ваши разрушительные и восстанавливающие заклинания.

Каждый раз, когда Вы сотворяете заклинание, наносящее урон огнём или восстанавливает хиты, пока Ваш дух лесного пожара вызван, бросьте к8, и добавьте результат к одной из костей урона или лечения того заклинания.

Кроме того, когда Вы сотворяете заклинание с дистанцией, отличной от «На себя», заклинание может исходить как от Вас, так и от Вашего духа лесного пожара.`,
    source: {
      id: SOURCE_TCoE,
      page: 57,
    },
  },
  {
    id: FEATURE_CAUTERIZING_FLAMES,
    name: `Прижигающее пламя`,
    nameEn: `Cauterizing Flames`,
    lvl: 10,
    text: `Вы получаете способность превращать смерть в магическое пламя, которое может сжигать и исцелять.

Когда в пределах 30 футов от Вас или Вашего духа лесного пожара умирает Маленькое (или большее) существо, безобидный призрачный огонёк возникает в пространстве мёртвого существа и мерцает там в течение 1 минуты. Когда видимое Вами существо входит в это пространство, Вы можете реакцией погасить этот призрачный огонёк и либо вылечить это существо, либо нанести ему урон огнём. Исцеление или урон равны 2к10 + Ваш модификатор Мудрости.

Вы можете использовать эту реакцию количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, заканчивая длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 57,
    },
  },
  {
    id: FEATURE_BLAZING_REVIVAL,
    name: `Пламенное возрождение`,
    nameEn: `Blazing Revival`,
    lvl: 14,
    text: `Связь с Вашим духом лесного пожара может спасти Вас от смерти.

Если дух находится в пределах 120 футов, когда Ваши хиты уменьшаются до 0 и Вы из-за этого теряете сознание, то Вы можете заставить хиты духа уменьшиться до 0. Затем Вы восстанавливаете половину своих хитов и сразу же поднимаетесь на ноги.

Воспользовавшись этой способностью, Вы не сможете сделать это снова, пока не закончите длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 58,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE,
  })
)

