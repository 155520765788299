const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_DRAGON_BLUE_ADULT,
  CREATURE_GIANT_SCORPION,
  CREATURE_TLINCALLI,
} = require('./../../../../creatureIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GEAR_LONGSWORD} = require('./../../../../gearIdList')
const {LANG_TLINCALLI} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Тлинкалли',
  nameAlt: 'Человек-скорпион',
  nameEn: 'Tlincalli',
  id: CREATURE_TLINCALLI,
  description: [
    `Тлинкалли, которых ещё называют люди-скорпионы, — это покрытые хитином существа, которые от талии и выше имеют тело гуманоида, а нижняя часть у них как у [огромного скорпиона](CREATURE:${CREATURE_GIANT_SCORPION}), включая жало на конце длинного хвоста.`,
    {
      header: 'Пустынные кочевники',
      text: `Тлинкалли живут неприхотливо. Они бродят по засушливым землям, охотясь на рассвете и на закате. В промежутках между они пережидают дневную жару и ночной холод, зарываясь в сыпучий песок или землю, либо, если местность этого не позволяет, таясь в руинах или неглубоких пещерах. Племя тлинкалли остаётся на одном месте только до тех пор, пока в непосредственных окрестностях имеется добыча, хотя в своих странствиях они могут приходить в одни и те же места снова и снова. Племя также на время оседает, когда приходит время отложить яйца и дождаться вылупления нового выводка.`,
      source: {
        id: SOURCE_VGTM,
        page: 196,
      },
    },
    {
      header: 'Ядовитые яйца',
      text: `Тлинкалл откладывают яйца в тёплое место вне прямых солнечных лучей, часто среди поросли кактусов вблизи их нынешней стоянки. Яйца защищены толстой скорлупой, покрытой паралитическим ядом типа того, что у них на жалах. Потенциальный хищник, который посмеет расколоть яйцо, будет беззащитен перед тлинкалли, которые придут проверить, в чём дело.`,
      source: {
        id: SOURCE_VGTM,
        page: 196,
      },
    },
    {
      header: 'Ужасные похитители',
      text: `Тлинкалли съедают то, что убивают, но когда у них появляются новые рты, они также начинают брать добычу живьем. Использовав свои жала, чтобы парализовать жертву, и шипованные цепи, чтобы связать её, тлинкалли притаскивают таких пленников в свое стойбище и привязывают к кактусу или камням. Там жертвы дожидаются, пока не сядет солнце и нововылупившийся молодняк выйдет из логова, чтобы съесть их живьём.`,
      source: {
        id: SOURCE_VGTM,
        page: 196,
      },
    },
    {
      header: 'Гордые охотники',
      text: `Тлинкалли считают себя великими охотниками. Если племя тлинкалли встречает более могущественного охотника, такого, как [синий дракон](CREATURE:${CREATURE_DRAGON_BLUE_ADULT}), вождь племени должен решить, станет ли племя повиноваться более умелому охотнику, просто пройдет дальше, или будет сражаться насмерть, чтобы одолеть его.`,
      source: {
        id: SOURCE_VGTM,
        page: 196,
      },
    },
    {
      header: 'Оружие и предметы из подручных материалов',
      text: `Тлинкалли нецивилизованные, и не строят городов, не шьют одежду и не добывают металлы. Вместо этого они подбирают то, что им нужно, или что они хотят. При этом они, тем не менее, знают, как расплавить найденный металл и выковать грубое оружие и инструменты.`,
      source: {
        id: SOURCE_VGTM,
        page: 196,
      },
    },
  ],
  note: {
    text: `Панцири тлинкалли покрыты ядом, который может можно собрать и сварить, не теряя парализующие силы, и повторно использовать алхимиками. Требуется тщательное обращение.`,
    author: `Эльминстер`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 196,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_TLINCALLI,
  ],
  cr: CR_5,
  actionList: [
    {
      name: 'Мультиатака',
      description: ` Тлинкалли делает две атаки: одну _Длинным мечом_ или _Шипованной цепью_, и одну _Жалом_.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      name: 'Шипованная цепь',
      description: `Цель схвачена (СЛ высвобождения 11) если это Большое существо или меньше. До конца захвата цель обездвижена и тлинкалли не может использовать шипованную цепь против другой цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Жало',
      description: `Цель должна пройти испытание Телосложения СЛ 14 или стать отравленной на 1 минуту. Если цель проваливает испытание на 5 или больше, то она ещё и парализована, пока отравлена. Цель может повторять испытание в конце каждого своего хода и окончить эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 4,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
}
