const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  GEAR_WAND,
} = require('./../../../../../gearIdList')
const {
  SPELL_COMMAND,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_ENLARGE_REDUCE,
  SPELL_FAERIE_FIRE,
  SPELL_FIREBALL,
  SPELL_GREATER_RESTORATION,
  SPELL_GUST_OF_WIND,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_INVISIBILITY,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGIC_MISSILE,
  SPELL_POLYMORPH,
  SPELL_SLOW,
  SPELL_STINKING_CLOUD,
  SPELL_WEB,
} = require('./../../../../../spellIdList')
const {
  CREATURE_ELEPHANT,
  CREATURE_RAT,
  CREATURE_RHINOCEROS,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WAND,
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_WAND_OF_BINDING,
  MAGIC_ITEM_WAND_OF_ENEMY_DETECTION,
  MAGIC_ITEM_WAND_OF_FEAR,
  MAGIC_ITEM_WAND_OF_FIREBALLS,
  MAGIC_ITEM_WAND_OF_LIGHTNING_BOLTS,
  MAGIC_ITEM_WAND_OF_MAGIC_DETECTION,
  MAGIC_ITEM_WAND_OF_MAGIC_MISSILES,
  MAGIC_ITEM_WAND_OF_PARALYSIS,
  MAGIC_ITEM_WAND_OF_POLYMORPH,
  MAGIC_ITEM_WAND_OF_SECRETS,
  MAGIC_ITEM_WAND_OF_WEB,
  MAGIC_ITEM_WAND_OF_WONDER,
  MAGIC_ITEM_WEAPON_OF_WARNING,
  MAGIC_ITEM_WELL_OF_MANY_WORLDS,
  MAGIC_ITEM_WIND_FAN,
  MAGIC_ITEM_WINGED_BOOTS,
  MAGIC_ITEM_WINGS_OF_FLYING,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_WEAPON_OF_WARNING,
    name: `Оружие предупреждения`,
    nameEn: `Weapon of Warning`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любое`,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Это магическое оружие предупреждает Вас об опасности.

Пока это оружие находится у Вас, Вы совершаете с преимуществом проверки инициативы.

Кроме того, Вы и все Ваши спутники в пределах 30 футов не можете быть захвачены врасплох, кроме случаев, когда недееспособны из-за чего угодно кроме немагического сна. Это оружие с помощью магии будит Вас и Ваших спутников, если кто-то из Вас спит, когда начинается сражение.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 188,
    },
  },
  {
    id: MAGIC_ITEM_WINGS_OF_FLYING,
    name: `Крылья полёта`,
    nameEn: `Wings of Flying`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Пока Вы носите этот плащ, Вы можете действием произнести командное слово. Это превратит плащ в пару крыльев летучей мыши, или птицы на Вашей спине на 1 час или пока Вы не повторите командное слово действием.

Крылья дают Вам скорость полёта 60 футов. Если они исчезают, Вы не можете повторно вызвать их 1к12 часов.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 179,
    },
  },
  {
    id: MAGIC_ITEM_WINGED_BOOTS,
    name: `Крылатые сапоги`,
    nameEn: `Winged Boots`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите эти сапоги, Вы обладаете скоростью полёта, равной Вашей скорости ходьбы. С помощью сапогов Вы можете лететь до 4 часов, за раз или по частям, но каждое использование считается как минимум за 1 минуту. Если Вы летите, когда 4 часа заканчиваются, Вы спускаетесь со скоростью 30 футов в раунд, пока не приземлитесь.

Сапоги восстанавливают 2 часа полёта за каждые 12 часов, пока не используются.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 179,
    },
  },
  {
    id: MAGIC_ITEM_WELL_OF_MANY_WORLDS,
    name: `Колодец многих миров`,
    nameEn: `Well of Many Worlds`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_LEGENDARY,
    description: `Эту чёрную ткань, гладкую как шёлк, можно свернуть до размеров носового платка. В развёрнутом виде это круг диаметром 6 футов.

Вы можете действием развернуть колодец многих миров и разместить его на твёрдой поверхности, после чего он станет двусторонним порталом в другой мир или план существования. Каждый раз, когда этот предмет открывает портал, Мастер решает, куда он будет вести. Вы можете действием закрыть открытый портал, свернув ткань за края. Колодец многих миров не может повторно открыть портал, пока не пройдёт 1к8 часов.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 173,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_WONDER,
    name: `Волшебная палочка чудес`,
    nameEn: `Wand of Wonder`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `заклинателем`,
    description: `У этой волшебной палочки 7 зарядов. Если Вы её держите, Вы можете действием потратить 1 заряд и выбрать цель в пределах 120 футов. Цель должна быть существом, предметом или точкой в пространстве. Бросьте к100 и определите последствия с помощью таблицы.

Если эффект сотворяет заклинание, СЛ испытания от него равна 15. Если обычно у этого заклинания есть дистанция в футах, то эта дистанция становится равной 120 футам.

Если эффект покрывает площадь, то Вы должны сделать центром заклинания цель и включить её в качестве цели заклинания. Если эффект может воздействовать на несколько разных субъектов, Мастер случайным образом выбирает, кто станет целью.

Палочка ежедневно восстанавливает 1к6 + 1 заряд на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится.

| к100 | Эффект |
|------|--------|
| 01–05 | Вы накладываете [Замедление](SPELL:${SPELL_SLOW}). |
| 06–10 | Вы накладываете [Огонь фей](SPELL:${SPELL_FAERIE_FIRE}). |
| 11–15 | Вы становитесь ошеломлённым до начала своего следующего хода и считаете, что только что произошло что-то невероятное. |
| 16–20 | Вы накладываете [Порыв ветра](SPELL:${SPELL_GUST_OF_WIND}). |
| 21–25 | Вы накладываете [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) на выбранную цель. Если Вы целились не в существо, то получаете урон психической энергией 1к6. |
| 26–30 | Вы накладываете [Зловонное облако](SPELL:${SPELL_STINKING_CLOUD}). |
| 31–33 | Область в пределах 60 футов от цели заполнятся сильным ливнем. Эта область становится слабо заслонённой. Ливень идёт до начала Вашего следующего хода. |
| 34–36 | В ближайшем свободном пространстве у цели появляется животное. Животное не находится под Вашим контролем и действует так, как уместно. Бросьте к100, чтобы определить, кто появился. При результате 01–25 появляется [носорог](CREATURE:${CREATURE_RHINOCEROS}); при результате 26–50 появляется [слон](CREATURE:${CREATURE_ELEPHANT}); при результате 51–100 появляется [крыса](CREATURE:${CREATURE_RAT}). |
| 37–46 | Вы накладываете [Молнию](SPELL:${SPELL_LIGHTNING_BOLT}). |
| 47–49 | Область в пределах 30 футов заполняют 600 огромных бабочек. Это пространство становится сильно заслонённым. Бабочки остаются на 10 минут. |
| 50–53 | Вы увеличиваете цель, как если бы наложили [Увеличение/Уменьшение](SPELL:${SPELL_ENLARGE_REDUCE}). Если на цель не действует это заклинание, или если Вы целились не в существо, то целью становитесь Вы | 
| 54–58 | Вы накладываете [Тьму](SPELL:${SPELL_DARKNESS}). |
| 59–62 | На земле в пределах 60 футов от цели вырастает трава. Если трава там уже была, то она вырастает в десять раз больше своего обычного размера и остаётся такой 1 минуту. |
| 63–65 | Один предмет на выбор Мастера переносится на Эфирный План. Этот предмет никто не должен ни носить, ни нести, он должен находиться в пределах 120 футов от цели, и не должен превышать 10 футов ни по одному из линейных размеров. |
| 66–69 | Вы уменьшаетесь, как если бы наложили на себя [Увеличение/Уменьшение](SPELL:${SPELL_ENLARGE_REDUCE}). |
| 70–79 | Вы накладываете [Огненный шар](SPELL:${SPELL_FIREBALL}). |
| 80–84 | Вы накладываете на себя [Невидимость](SPELL:${SPELL_INVISIBILITY}). |
| 85–87 | На цели вырастают листья. Если в качестве цели Вы выбрали точку в пространстве, то листья вырастают на ближайшем к этой точке существе. Если листья не оборвать, они побуреют и опадут через 24 часа. |
| 88–90 | Из кончика палочки линией длиной 30 футов и шириной 5 футов выстреливают 1к4 × 10 драгоценных камней, стоящих по 1 зм каждый. Каждый камень причиняет дробящий урон 1, и общий урон от камней поровну делится между всеми существами в этой линии. |
| 91–95 | От Вас исходит 30-футовая вспышка переливающегося света. Вы и все существа в этой области, способные видеть, должны пройти испытание Телосложения СЛ 15, иначе ослепнете на 1 минуту. Существа могут повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. |
| 96–97 | Кожа цели становится ярко-синей на 1к10 дней. Если Вы выбрали точку в пространстве, то эффект действует на ближайшее к этой точке существо. |
| 98–00 | Если Вы целились в существо, оно должно пройти испытание Телосложения СЛ 15. Если Вы целились не в существо, то сами становитесь целью и должны пройти испытание. Если испытание провалено на 5 или больше единиц, цель мгновенно превращается в камень. При просто проваленном испытании цель становится обездвиженной и начинает превращаться в камень. Будучи обездвиженной этой магией, цель должна повторить испытание в конце своего следующего хода, становясь окаменевшей при провале или оканчивая эффект при успехе. Окаменение длится, пока цель не будет освобождена заклинанием [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) или подобной магией. | 
`,
    genderId: GENDER_FEMALE,
    spellIdList: [
      SPELL_DARKNESS,
      SPELL_DETECT_THOUGHTS,
      SPELL_FAERIE_FIRE,
      SPELL_FIREBALL,
      SPELL_GUST_OF_WIND,
      SPELL_INVISIBILITY,
      SPELL_LIGHTNING_BOLT,
      SPELL_SLOW,
      SPELL_STINKING_CLOUD,
    ],
    source: {
      id: SOURCE_DMG,
      page: 157,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_FEAR,
    name: `Волшебная палочка страха`,
    nameEn: `Wand of Fear`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `У этой волшебной палочки 7 зарядов для использования описанными ниже свойствами.

Она ежедневно восстанавливает 1к6 + 1 заряд на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится.

# Приказ

Если Вы держите эту палочку, Вы можете действием потратить 1 заряд, чтобы приказать другому существу убежать или упасть, как заклинанием [Приказ](SPELL:${SPELL_COMMAND}) (СЛ испытания 15).

# Конус страха

Если Вы держите эту палочку, Вы можете действием потратить 2 заряда, чтобы кончик палочки испустил 60-футовый конус янтарного света. Все существа в конусе должны пройти испытание Мудрости СЛ 15, иначе они станут испуганными Вами на 1 минуту. Будучи испуганным таким образом, существо обязано тратить ходы на то, чтобы переместиться максимально далеко от Вас, и оно не может добровольно переместиться в пространство в пределах 30 футов от Вас. Оно также не может совершать реакции. Действием существо может совершать только Рывок или пытаться освободиться от эффекта, препятствующего его передвижению. Если двигаться некуда, существо может использовать действие Уклонение. В конце каждого своего хода существо может повторять испытание, оканчивая эффект на себе при успехе.`,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_COMMAND],
    source: {
      id: SOURCE_DMG,
      page: 156,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_MAGIC_MISSILES,
    name: `Волшебная палочка снарядов`,
    nameEn: `Wand of Magic Missiles`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_UNCOMMON,
    description: `У этой волшебной палочки 7 зарядов. Если Вы её держите, Вы можете действием потратить 1 или несколько зарядов, чтобы наложить ей заклинание [Волшебная стрела](SPELL:${SPELL_MAGIC_MISSILE}). За 1 заряд Вы накладываете это заклинание 1-го круга. Вы можете увеличить круг заклинания на 1 за каждый дополнительный используемый заряд.

Палочка ежедневно восстанавливает 1к6 + 1 заряд на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится.`,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_MAGIC_MISSILE],
    source: {
      id: SOURCE_DMG,
      page: 156,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_BINDING,
    name: `Волшебная палочка сковывания`,
    nameEn: `Wand of Binding`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `заклинателем`,
    description: `У этой волшебной палочки 7 зарядов для использования описанными ниже свойствами.

Она ежедневно восстанавливает 1к6 + 1 заряд на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится.

# Заклинания

Если Вы держите эту палочку, Вы можете действием потратить часть зарядов, чтобы наложить одно из следующих заклинаний (СЛ испытания 17):

* [Удержание чудовища](SPELL:${SPELL_HOLD_MONSTER}) (5 зарядов),
* [Удержание личности](SPELL:${SPELL_HOLD_PERSON}) (2 заряда).

# Помощь в освобождении

Если Вы держите эту палочку, Вы можете реакцией потратить 1 заряд, чтобы получить преимущество к испытанию от парализации или опутывания, или Вы можете потратить 1 заряд, чтобы получить преимущество для проверки, совершаемой, чтобы вырваться из захвата.`,
    genderId: GENDER_FEMALE,
    spellIdList: [
      SPELL_HOLD_MONSTER,
      SPELL_HOLD_PERSON,
    ],
    source: {
      id: SOURCE_DMG,
      page: 156,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_SECRETS,
    name: `Волшебная палочка секретов`,
    nameEn: `Wand of Secrets`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_UNCOMMON,
    description: `У этой волшебной палочки 3 заряда. Если Вы держите её, Вы можете действием потратить 1 заряд, и если в пределах 30 футов от Вас есть потайные двери или ловушки, то палочка завибрирует и укажет на ту, что находится ближе всего к Вам.

Палочка ежедневно восстанавливает 1к3 заряда на рассвете. `,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 155,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_POLYMORPH,
    name: `Волшебная палочка превращения`,
    nameEn: `Wand of Polymorph`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    attunementComment: `заклинателем`,
    description: `У этой волшебной палочки 7 зарядов. Если Вы её держите, Вы можете действием потратить 1 заряд, чтобы наложить ей заклинание [Превращение](SPELL:${SPELL_POLYMORPH}) (СЛ испытания 15).

Палочка ежедневно восстанавливает 1к6 + 1 заряд на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится. `,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_POLYMORPH],
    source: {
      id: SOURCE_DMG,
      page: 155,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_WEB,
    name: `Волшебная палочка паутины`,
    nameEn: `Wand of Web`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    attunementComment: `заклинателем`,
    description: `У этой волшебной палочки 7 зарядов. Если Вы её держите, Вы можете действием потратить 1 заряд, чтобы наложить ей заклинание [Паутина](SPELL:${SPELL_WEB}) (СЛ испытания 15).

Палочка ежедневно восстанавливает 1к6 + 1 заряд на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится. `,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_WEB],
    source: {
      id: SOURCE_DMG,
      page: 155,
    },
  },
  {
    id: MAGIC_ITEM_WIND_FAN,
    name: `Веер ветра`,
    nameEn: `Wind Fan`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Если Вы держите этот веер, Вы можете действием наложить им заклинание [Порыв ветра](SPELL:${SPELL_GUST_OF_WIND}) (СЛ испытания 13).

До следующего рассвета веером лучше не пользоваться. За каждое повторное использование до рассвета существует накопительный 20% шанс, что веер не сработает и превратится в бесполезные немагические обрывки.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_GUST_OF_WIND],
    source: {
      id: SOURCE_DMG,
      page: 154,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_LIGHTNING_BOLTS,
    name: `Волшебная палочка молний`,
    nameEn: `Wand of Lightning Bolts`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `заклинателем`,
    description: `У этой волшебной палочки 7 зарядов. Если Вы её держите, Вы можете действием потратить 1 или несколько зарядов, чтобы наложить ей заклинание [Молния](SPELL:${SPELL_LIGHTNING_BOLT}) (СЛ испытания 15). За 1 заряд Вы накладываете это заклинание 3-го круга. Вы можете увеличить круг заклинания на 1 за каждый дополнительный используемый заряд.

Палочка ежедневно восстанавливает 1к6 + 1 заряд на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится.`,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_LIGHTNING_BOLT],
    source: {
      id: SOURCE_DMG,
      page: 154,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_ENEMY_DETECTION,
    name: `Волшебная палочка обнаружения врагов`,
    nameEn: `Wand of Enemy Detection`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `У этой волшебной палочки 7 зарядов. Если Вы её держите, Вы можете действием потратить 1 заряд, чтобы сказать командное слово. В течение следующей минуты Вы знаете направление к ближайшему существу, враждебному к Вам и находящемуся в пределах 60 футов, но не расстояние до него. Палочка может чувствовать присутствие враждебных существ, которые эфирны, невидимы, замаскированы или прячутся, а также тех, кто и так видны. Эффект оканчивается, если Вы перестаёте держать палочку.

Палочка ежедневно восстанавливает 1к6 + 1 заряд на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 154,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_MAGIC_DETECTION,
    name: `Волшебная палочка обнаружения магии`,
    nameEn: `Wand of Magic Detection`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_UNCOMMON,
    description: `У этой волшебной палочки 3 заряда. Если Вы её держите, Вы можете действием потратить 1 заряд, чтобы наложить ей заклинание [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}).

Палочка ежедневно восстанавливает 1к3 заряда на рассвете.`,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_DETECT_MAGIC],
    source: {
      id: SOURCE_DMG,
      page: 155,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_FIREBALLS,
    name: `Волшебная палочка огненных шаров`,
    nameEn: `Wand of Fireballs`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `заклинателем`,
    description: `У этой волшебной палочки 7 зарядов. Если Вы её держите, Вы можете действием потратить 1 или несколько зарядов, чтобы наложить ей заклинание [Огненный шар](SPELL:${SPELL_FIREBALL}) (СЛ испытания 15). За 1 заряд Вы накладываете это заклинание 3-го круга. Вы можете увеличить круг заклинания на 1 за каждый дополнительный используемый заряд.

Палочка ежедневно восстанавливает 1к6 + 1 зарядов на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится.`,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_FIREBALL],
    source: {
      id: SOURCE_DMG,
      page: 155,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_PARALYSIS,
    name: `Волшебная палочка паралича`,
    nameEn: `Wand of Paralysis`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `заклинателем`,
    description: `У этой волшебной палочки 7 зарядов. Если Вы её держите, Вы можете действием потратить 1 заряд, чтобы выпустить из её кончика тонкий синий луч в сторону существа, которое видите в пределах 60 футов. Цель должна пройти испытание Телосложения СЛ 15, иначе она станет парализованной на 1 минуту. В конце каждого своего хода цель может повторять испытание, оканчивая эффект на себе при успехе.

Палочка ежедневно восстанавливает 1к6 + 1 заряд на рассвете. Если Вы истратили последний заряд в палочке, бросьте к20. Если выпадет «1», палочка рассыплется в пыль и уничтожится. `,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 155,
    },
  },
]
