const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../sourceList')

const {CREATURE_ZOMBIE} = require('./../../creatureIdList')
const {fullCasterSpellSlotTable} = require('./../../spellSlotTable')
const {BACKGROUND_SAGE} = require('./../../backgroundIdList')

const {
  PARAM_INT,
  PARAM_WIT,
} = require('./../../paramList')

const {
  GEAR_COMPONENT_POUCH,
  GEAR_CROSSBOW_LIGHT,
  GEAR_DAGGER,
  GEAR_DART,
  GEAR_EXPLORER_S_PACK,
  GEAR_QUARTERSTAFF,
  GEAR_SCHOLAR_S_PACK,
  GEAR_SLING,
  GEAR_SPELLBOOK,
} = require('./../../gearIdList')

const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_INVESTIGATION,
  SKILL_MEDICINE,
  SKILL_RELIGION,
} = require('./../../skillList')

const {
  SPELL_AUGURY,
  SPELL_BURNING_HANDS,
  SPELL_CHARM_PERSON,
  SPELL_FEATHER_FALL,
  SPELL_FIREBALL,
  SPELL_LIGHT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_RAY_OF_FROST,
  SPELL_SLEEP,
  SPELL_TELEPORTATION_CIRCLE,
} = require('./../../spellIdList')

const {
  PC_CLASS_WIZARD,
} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_WIZARD,
  name: {
    singular: {
      nominative: 'волшебник',
      genitive: 'волшебника',
      dative: 'волшебнику',
      accusative: 'волшебника',
      instrumental: 'волшебником',
      prepositional: 'волшебнике',
    },
    plural: {
      nominative: 'волшебники',
      genitive: 'волшебников',
      dative: 'волшебникам',
      accusative: 'волшебников',
      instrumental: 'волшебниками',
      prepositional: 'волшебниках',
    },
  },
  nameEn: 'wizard',
  description: [
    `Одетая в серебряные одежды, обозначающие её статус, эльфийка закрывает глаза, приглушает в своём сознании суматоху боя и начинает свой тихий напев. Движениями рук она сплетает перед собой заклинание и запускает крошечный [шарик огня](SPELL:${SPELL_FIREBALL}) во вражеские ряды, где он взрывается потоками пламени, которые охватывают солдат.

Проверив и перепроверив свои наброски, человек рисует [затейливый магический круг](SPELL:${SPELL_TELEPORTATION_CIRCLE}) мелом на голом каменном полу, затем посыпает железным порошком все линии и изящные кривые. Когда круг готов, он начинает монотонно читать длинное заклинание. Внутри круга появляется проход, из которого тянет запахом серы из потустороннего мира.

Припав к полу на перекрёстке в подземелье, гном бросает небольшие костяшки с мистическими символами, бормоча над ними слова силы. Закрыв глаза, чтобы [прочитать видения](SPELL:${SPELL_AUGURY}) более чётко, он медленно кивает, затем открывает глаза и указывает на проход слева от себя.

Волшебники — адепты высшей маги, объединяющиеся по типу своих заклинаний. Опираясь на тонкие плетения магии, пронизывающей вселенную, волшебники способны создавать заклинания взрывного огня, искрящихся молний, тонкого обмана и грубого контроля над сознанием. Их магия вызывает чудовищ с других планов бытия, предсказывает будущее и обращает поверженных врагов в [зомби](CREATURE:${CREATURE_ZOMBIE}). Их самые могущественные заклинания могут превращать одно вещество в другое, вызывать метеориты с небес и открывать порталы в другие миры.`,
    {
      header: `Адепты магии`,
      text: `Дикая и загадочная, разнообразная по форме и функциям, магия привлекает учеников, которые стремятся достичь мастерства в её тайнах. Некоторые хотят уподобиться богам, изменять саму реальность. И хотя для сотворения обычного заклинания требуется лишь произношение нескольких странных слов, совершение пары мимолётных жестов, а иногда щепотка или комок экзотических материалов, эти поверхностные проявления не могут показать опыт, достигнутый долгими часами ученичества и бессчётными часами исследований. Волшебники живут и умирают со своими заклинаниями. Всё остальное вторично. Они учат новые заклинания, экспериментируя и становясь опытнее. Они также могут узнать их от других волшебников, из древних томов или свитков, и от древних существ (таких как феи), которые погружены в магию.`,
      source: {
        id: SOURCE_PHB,
        page: 113,
      },
    },
    {
      header: `Соблазн знаний`,
      text: `Жизнь волшебников редко бывает обыденной. Наиболее близки к простой жизни, вероятно, те из волшебников, которые работают знатоками или лекторами в библиотеке университета, обучая других секретам мультивселенной. Другие волшебники предлагают свои услуги в качестве прорицателей, служат в вооружённых силах, или проводят жизнь в преступлениях и стремлении к власти.

Но соблазн знаний и силы зовёт даже самых обделённых смелостью волшебников из безопасности их библиотек и лабораторий в разрушенные руины и потерянные города. Большинство волшебников считает, что их коллеги из древних цивилизаций знали секреты магии, которые были потеряны навек, и открывшие эти секреты смогут владеть силами, недоступными в нынешнем веке.`,
      source: {
        id: SOURCE_PHB,
        page: 113,
      },
    },
    {
      header: 'Волшебник',
      text: 'Волшебники — мастера магии, специализирующиеся в различных её школах. Они прекрасно умеют объединять древнюю магию с современной наукой.',
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Создание волшебника`,
      text: `При создании персонажа волшебника в его истории обязательно должно быть некое выдающееся событие. Как Ваш персонаж впервые встретился с магией? Как он открыл свою предрасположенность к ней? Владелец ли он природного таланта или же достиг всего усердной учёбой и постоянной практикой? Или же произошла неожиданная встреча с магическим существом или древней книгой, что могли обучить его основам магического искусства?

Что привлекло вас к жизни в обучении? Сподвиг ли вас на большее вкус первого обретённого магического знания? Постигли ли Вы слова тайного хранилища знаний, ещё не разграбленного другими волшебниками? Возможно, Вы просто желаете противопоставить обретённые магические навыки опасностям для проверки своих сил.`,
      source: {
        id: SOURCE_PHB,
        page: 114,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать волшебника, следуя этим рекомендациям.

1. Во-первых, у вашего Интеллекта должно быть наивысшее значение. Следующим по величине должно быть значение Телосложения или Ловкости. Если Вы планируете присоединиться к школе Очарования, сделайте вторым по величине значение Харизмы.
2. Во-вторых, выберите предысторию «[Мудрец](BACKGROUND:${BACKGROUND_SAGE})».
3. В-третьих, выберите заговоры [Волшебная рука](SPELL:${SPELL_MAGE_HAND}), [Луч холода](SPELL:${SPELL_RAY_OF_FROST}) и [Свет](SPELL:${SPELL_LIGHT}), а также заклинания 1 уровня для Вашей книги заклинаний: [Волшебная стрела](SPELL:${SPELL_MAGIC_MISSILE}), [Доспехи мага](SPELL:${SPELL_MAGE_ARMOR}), [Огненные ладони](SPELL:${SPELL_BURNING_HANDS}), [Очарование личности](SPELL:${SPELL_CHARM_PERSON}), [Падение пёрышком](SPELL:${SPELL_FEATHER_FALL}) и [Усыпление](SPELL:${SPELL_SLEEP}). `,
      source: {
        id: SOURCE_PHB,
        page: 114,
      },
    },
  ],
  note: [
    {
      text: `Искусство волшебника требует понимания. Знание того, как и почему магия работает, и наши усилия по расширению этого понимания привели к ключевым прорывам в развитии цивилизации на протяжении столетий.`,
      author: `Гимбл, иллюзионист`,
    },
    {
      text: `Смотри. Люблю этот фокус. Эй, волшебник! Ты не можешь творить магию. Оп, теперь можешь. Оп, и опять не можешь. Ха-ха-ха!`,
      author: `Занатар`,
    },
  ],
  featureCollection: {
    hitDice: 6,
    proficiency: {
      weapon: {
        gearList: [
          GEAR_CROSSBOW_LIGHT,
          GEAR_DAGGER,
          GEAR_DART,
          GEAR_QUARTERSTAFF,
          GEAR_SLING,
        ],
      },
      savingThrow: [
        PARAM_INT,
        PARAM_WIT,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_ARCANA,
            SKILL_HISTORY,
            SKILL_INSIGHT,
            SKILL_INVESTIGATION,
            SKILL_MEDICINE,
            SKILL_RELIGION,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 4,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) [Боевой посох](GEAR:${GEAR_QUARTERSTAFF})
    * б) [Кинжал](GEAR:${GEAR_DAGGER})
2. Выберите одно
    * а) [Мешочек с компонентами](GEAR:${GEAR_COMPONENT_POUCH})
    * б) Магическая фокусировка
3. Выберите одно
    * а) [Набор учёного](GEAR:${GEAR_SCHOLAR_S_PACK})
    * б) [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})
4. [Книга заклинаний](GEAR:${GEAR_SPELLBOOK})`,
    },
  },
  spellSlotTable: fullCasterSpellSlotTable,
  source: [
    {
      id: SOURCE_PHB,
      page: 113,
    },
    {
      id: SOURCE_SRD,
      page: 52,
    },
  ],
}
