const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {ABILITY_CHARGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_AUROCHS} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Тур',
  nameEn: 'Aurochs',
  id: CREATURE_AUROCHS,
  description: `Богтру, сын Груумша и Лутик, является орочьим божеством необузданной силы. Легенда гласит, что Богтуру было нужно ездовое животное, столь же свирепое как и он, чтобы вести войну, для этого он нашел тура, покорил это существо голыми руками и привел его в Нишрек, королевство Груумша. Богтру назвал животное Казахт, то есть «Бык» на орочьем. На голой спине Казахта Богтур врывается в сражение, врезается во вражеское войско и прыгает с рогов тура, чтобы приземлиться в гущу противников.

Орки, которые почитают Богтру, могут ухаживать за стойлами с боевыми быками, которые несут их в бой. С раннего возраста обучаемые быть свирепыми ездовыми животными, туры — священные символы Богтру. Ни один орк не ест этих существ, рассматривая их как павших славных воинов.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 209,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 19,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 5,
  },
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Боданием',
      damageText: '9 (2к8) колющего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 15, иначе будет сбита с ног.',
    },
  ],
  actionList: [
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
  ],
}
