const {PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {SPELL_DISPEL_MAGIC} = require('./../../../spellIdList')
const {MAGIC_ITEM_SPELL_SCROLL} = require('./../../../magicItemIdList')
const {
  GEAR_BOOK,
  GEAR_PAPER,
  GEAR_PARCHMENT,
  GEAR_SPELLBOOK,
} = require('./../../../gearIdList')

const {
  FEATURE_AWAKENED_SPELLBOOK,
  FEATURE_MANIFEST_MIND,
  FEATURE_MASTER_SCRIVENER,
  FEATURE_ONE_WITH_THE_WORD,
  FEATURE_SPELLBOOK,
  FEATURE_WIZARDLY_QUILL,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_WIZARDLY_QUILL,
    name: `Волшебное перо`,
    nameEn: `Wizardly Quill`,
    lvl: 2,
    text: `Вы можете бонусным действием магически создать Крошечное перо в своей свободной руке. Это магическое перо имеет следующие свойства.

* _Перо_ не требует чернил. Когда Вы им пишете, оно создаёт на поверхности чернила цвета по Вашему выбору.
* Если Вы используете это _Волшебное перо_ для копирование заклинания в свою [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), то тратите только 2 минуты времени за круг заклинания.
* Всё, что Вы написали _Пером_, Вы можете бонусным действием стереть, взмахнув _Пером_ над текстом. Текст должен находиться в пределах 5 футов.

Перо исчезает, если Вы умираете или создаёте новое.`,
    source: {
      id: SOURCE_TCoE,
      page: 115,
    },
  },
  {
    id: FEATURE_AWAKENED_SPELLBOOK,
    name: `Пробуждённая книга заклинаний`,
    nameEn: `Awakened Spellbook`,
    lvl: 2,
    text: `Используя специально подготовленные чернила и известные Вашему Ордену древние формулы, Вы пробуждаете в своей [Книге заклинаний](FEATURE:${FEATURE_SPELLBOOK}) волшебное сознание.

Пока Вы держите _Книгу_ в руках, она даёт Вам следующие преимущества:

* Вы можете использовать книгу как магическую фокусировку для своих заклинаний волшебника.
* Сотворяя заклинание волшебника с тратой ячейки заклинаний, Вы можете временно заменить его тип урона на тип урона другого заклинания из своей _Книги заклинаний_. Ваша _Книга_ магически изменяет формулу заклинания только для этого сотворения. Второе заклинание должно быть того же круга, что и потраченная ячейка заклинания.
* Сотворяя заклинание волшебника ритуалом, Вы можете использовать его обычное время сотворения, а не добавлять к нему 10 минут. Использовав эту способность, Вы не сможете сделать это снова, пока не закончите длинный отдых.

При необходимости Вы можете заменить свою _Книгу_ во время короткого отдыха, используя [Волшебное перо](FEATURE:${FEATURE_WIZARDLY_QUILL}) для написания волшебных знаков в [пустой книге](GEAR:${GEAR_BOOK}) или магической [книге заклинаний](GEAR:${GEAR_SPELLBOOK}), на которую Вы настроены. В конце отдыха сознание Вашей [книги заклинаний](FEATURE:${FEATURE_SPELLBOOK}) вызывается в новую книгу, и трансформирует её в Вашу [книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}) вместе со всеми её заклинаниями. Если предыдущая книга ещё где-то существует, то все заклинания исчезают с её страниц.`,
    source: {
      id: SOURCE_TCoE,
      page: 115,
    },
  },
  {
    id: FEATURE_MANIFEST_MIND,
    name: `Проявление разума`,
    nameEn: `Manifest Mind`,
    lvl: 6,
    text: `Вы можете призвать разум своей [Пробуждённой книги заклинаний](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}). Пока [книга](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}) у Вас с собой, Вы можете бонусным действием заставить её разум проявиться в виде Крошечного призрачного предмета, парящего в свободном пространстве по Вашему выбору в пределах 60 футов. _Призрачный разум_ нематериален, не занимает своего пространства, и излучает тусклый свет в пределах 10 футов. Он выглядит как призрачный фолиант, каскад текста или ученый прошлого (на Ваш выбор).

В проявленном состоянии _призрачный разум_ может слышать и видеть. У него есть имеет тёмное зрение в пределах 60 футов. _Разум_ может телепатически делиться с Вами тем, что видит и слышит (никаких действий не требуется).

Каждый раз, когда Вы на своём ходу сотворяете заклинание волшебника, Вы можете сотворить его так, как если бы Вы находились в пространстве _призрачного разума_, а не в своём собственном, используя его чувства. Вы можете делать это количество раз в день, равное бонусу мастерства, и восстанавливаете все потраченные использования, заканчивая длинный отдых.

Вы можете бонусным действием заставить _призрачный разум_ перелететь на расстояние до 30 футов в видимое Вами или им свободное пространство. Он может проходить сквозь существ, но не сквозь предметы.

_Призрачный разум_ перестаёт проявляться, если оказывается дальше 300 футов от Вас, если кто-то применяет к нему [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}), если [Пробуждённая книга заклинаний](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}) уничтожена, если Вы умираете или если прогоняете его бонусным действием.

Вызвав _разум_, Вы не сможете сделать это снова, пока не закончите длинный отдых, если только не потратите ячейку заклинания любого круга на то, чтобы снова его вызвать.`,
    source: {
      id: SOURCE_TCoE,
      page: 115,
    },
  },
  {
    id: FEATURE_MASTER_SCRIVENER,
    name: `Мастер-писец`,
    nameEn: `Master Scrivener`,
    lvl: 10,
    text: `Заканчивая длинный отдых, Вы можете создать магический свиток, прикоснувшись [Волшебным пером](FEATURE:${FEATURE_WIZARDLY_QUILL}) к чистому листу [бумаги](GEAR:${GEAR_PAPER}) или [пергамента](GEAR:${GEAR_PARCHMENT}) и заставив одно заклинание из своей [Пробужденной книги заклинаний](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}) скопироваться на свиток. Когда Вы создаёте этот свиток, [книга заклинаний](FEATURE:${FEATURE_SPELLBOOK}) должна находиться в пределах 5 футов от Вас.

Выбранное заклинание должно быть 1-го или 2-го круга и иметь время сотворения «1 действие». Оказавшись в свитке, заклинание усиливается, считаясь на один круг выше, чем обычно. Вы можете сотворить заклинание из свитка, прочитав его действием. Свиток непонятен никому другому, и заклинание исчезает со свитка, когда Вы его сотворяете или когда заканчиваете свой следующий длинный отдых.

Вы также можете создавать [свитки заклинаний](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL}). Золото и время, которые Вы должны потратить на создание такого свитка, уменьшаются вдвое, если Вы используете [Волшебное перо](FEATURE:${FEATURE_WIZARDLY_QUILL}).`,
    source: {
      id: SOURCE_TCoE,
      page: 115,
    },
  },
  {
    id: FEATURE_ONE_WITH_THE_WORD,
    name: `Един со Словом`,
    nameEn: `One with the Word`,
    lvl: 14,
    text: `Связь с [Пробужденной книгой заклинаний](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}) стала настолько глубокой, что Ваша душа переплелась с ней. Пока книга у Вас с собой, Вы совершаете с преимуществом все проверки Интеллекта (Магии), поскольку [Книга](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}) помогает Вам вспоминать магические знания.

Более того, если Вы получаете урон, пока проявлен [разум](FEATURE:${FEATURE_MANIFEST_MIND}) Вашей [книги заклинаний](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}), Вы можете реакцией избежать всего этого урона, используя магию [призрачного разума](FEATURE:${FEATURE_MANIFEST_MIND}). При этом [призрачный разум](FEATURE:${FEATURE_MANIFEST_MIND}) прогоняется. Затем бросьте 3к6. [Книга заклинаний](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}) временно теряет выбранные Вами заклинания, у которых сумма кругов заклинаний равна или превышает результат броска. Например, если результат броска равен 9, из книги исчезают заклинания, сумма кругов которых не менее 9, что может означать одно заклинание 9-го круга, три заклинания 3-го круга или некоторую другую комбинацию. Если в [книге](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}) недостаточно заклинаний, чтобы покрыть эту цену, то Ваши хиты опускаются до 0.

Пока Вы не закончите 1к6 длинных отдыхов, Вы не сможете сотворять потерянные заклинания, даже если Вы найдете их в [свитках](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL}) или в другой [книге заклинаний](GEAR:${GEAR_SPELLBOOK}). После того, как Вы закончите необходимое количество отдыхов, заклинания снова появятся в Вашей [книге заклинаний](FEATURE:${FEATURE_AWAKENED_SPELLBOOK}).

Воспользовавшись этой реакцией, Вы не сможете повторить её, пока не закончите длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 115,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES,
  })
)

