const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHZERAI,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_LN,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_GITH,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_PSYCHIC,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    SKILL_INSIGHT,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_FEATHER_FALL,
    SPELL_JUMP,
    SPELL_MAGE_HAND,
    SPELL_SEE_INVISIBILITY,
    SPELL_SHIELD,
  } = require('./../../../../spellIdList'),
  {
    CAST_NONE,
  } = require('./../../../../castComponentList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    githzeraiDescription,
    githNote,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_GITHZERAI_MONK,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гитцерай монах',
  nameEn: 'Githzerai Monk',
  id: CREATURE_GITHZERAI_MONK,
  description: [
    `Сосредоточенные философы и строгие аскеты, гитцераи следуют жёсткому жизненному укладу. Худощавые и жилистые, они носят одежду без изысков, держа своё мнение при себе и доверяя единицам чужаков. Отвернувшиеся от своих воинственных собратьев гитьянок, гитцераи придерживаются монашеского уклада, проживая на островах порядка, что находятся в бескрайнем море хаоса на плане Лимбо.`,
    ...githzeraiDescription,
  ],
  note: githNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHZERAI,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 58,
  },
  armor: 14,
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
  },
  languageList: [
    LANG_GITH,
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Психическая защита',
      description: `Пока ★СУЩЕСТВО★ не носит доспех и не использует щит, к его КД добавляется модификатор Мудрости.`,
    },
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_WIT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_MAGE_HAND,
            comment: 'рука невидима',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_SEE_INVISIBILITY,
          SPELL_FEATHER_FALL,
          SPELL_JUMP,
          SPELL_SHIELD,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Безоружным ударом_.`,
    },
    {
      name: 'Безоружный удар',
      description: `Это считается атакой магическим оружием.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 2,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 2,
            diceType: 8,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
