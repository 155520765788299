const {LANG_SUBGROUP_ILLUSKI} = require('./../../languageSubGroupList')
const {PLACE_MOONSHAES} = require('./../../placeIdList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {
  LANG_ILLUSKAN,
  LANG_MOONSHAE,
} = require('./../../languageIdList')
const {
  LANG_TYPE_DIALECT,
  LANG_TYPE_ETHNIC,
} = require('./../../languageTypeList')

module.exports = {
  id: LANG_MOONSHAE,
  type: [
    LANG_TYPE_DIALECT,
    LANG_TYPE_ETHNIC,
  ],
  alphabetId: ALPHABET_THORASS,
  langSubGroupId: LANG_SUBGROUP_ILLUSKI,
  dialectOf: LANG_ILLUSKAN,
  name: {
    nominative: 'Муншае',
    genitive: 'Муншае',
    instrumental: 'Муншае',
    prepositional: 'Муншае',
  },
  nameEn: 'Moonshae',
  spokenPlaceList: PLACE_MOONSHAES,
  isReady: false,
  isRealLang: true,
}
