const {
  SPELL_ALTER_SELF,
  SPELL_BANISHMENT,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_COUNTERSPELL,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_FEAR,
  SPELL_FINGER_OF_DEATH,
  SPELL_FIRE_BOLT,
  SPELL_FIREBALL,
  SPELL_HEAT_METAL,
  SPELL_HOLD_MONSTER,
  SPELL_IDENTIFY,
  SPELL_INVISIBILITY,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MIND_BLANK,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRROR_IMAGE,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PRESTIDIGITATION,
  SPELL_SHIELD,
  SPELL_TENSER_S_FLOATING_DISK,
} = require('./../../../../spellIdList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_YUGOLOTH,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_CHARMED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_12} = require('./../../../../crList')
const {CREATURE_ARCANALOTH} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {yugolothDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Арканалот',
  nameEn: 'Arcanaloth',
  id: CREATURE_ARCANALOTH,
  description: [
    `Арканалоты — пронырливые существа с человекоподобными телами и головами шакалов, но они могут с помощью магии принимать облик любого гуманоида. Этим они пользуются, чтобы заручиться доверием существ, с которыми они ведут переговоры, заменяя шакальи оскалы на обворожительные улыбки.

Вне зависимости от выбранного облика, арканалот всегда выглядит ухоженным и одет в дорогие облачения. Арканалоты, являясь высокоинтеллектуальными заклинателями, командуют подразделениями низших юголотов и ведут учёт контрактов, записей и счетов себе подобных.

Арканалоты говорят и пишут на всех языках, что делает их искусными дипломатами и переговорщиками. Если арканалоту хорошо заплатить, он может тонко и незаметно способствовать заключению союзов и альянсов, точно так же, как переметнувшийся на другую сторону арканалот может даже самые хорошо подготовленные мирные переговоры превратить в тотальную войну. За свои время и талант исчадие обычно требует информацию или мощные магические вещи, которые он может обменять на ещё большее количество информации.`,
    ...yugolothDescriptionList,
  ],
  note: {
    text: `Власть. Мы все её жаждем, но лишь единицы из нас заслуживают её`,
    author: `Шемешка Мародёр, арканалот из Сигила`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_YUGOLOTH,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 318,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 14,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_DOUBLE,
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_INSIGHT]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_12,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_MAGIC_MISSILE,
            {
              id: SPELL_INVISIBILITY,
              comment: `только на себя`,
            },
            SPELL_HEAT_METAL,
            SPELL_ALTER_SELF,
            SPELL_DARKNESS,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 16,
      spellCasterClass: PC_CLASS_WIZARD,
      spellIdList: [
        SPELL_BANISHMENT,
        SPELL_CHAIN_LIGHTNING,
        SPELL_CONTACT_OTHER_PLANE,
        SPELL_COUNTERSPELL,
        SPELL_DETECT_MAGIC,
        SPELL_DETECT_THOUGHTS,
        SPELL_DIMENSION_DOOR,
        SPELL_FEAR,
        SPELL_FINGER_OF_DEATH,
        SPELL_FIRE_BOLT,
        SPELL_FIREBALL,
        SPELL_HOLD_MONSTER,
        SPELL_IDENTIFY,
        SPELL_MAGE_HAND,
        SPELL_MIND_BLANK,
        SPELL_MINOR_ILLUSION,
        SPELL_MIRROR_IMAGE,
        SPELL_PHANTASMAL_FORCE,
        SPELL_PRESTIDIGITATION,
        SPELL_SHIELD,
        SPELL_TENSER_S_FLOATING_DISK,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        2,
        1,
        1,
        1,
      ],
    },
  ],
  actionList: [
    {
      name: 'Когти',
      description: `Цель должна пройти испытание Телосложения СЛ 14, получая урон ядом 10 (3к6) при провале, или половину этого урона при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  genderId: GENDER_MALE,
}
