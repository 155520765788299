const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_WINTER_WOLF} = require('./../../languageIdList')

module.exports = {
  id: LANG_WINTER_WOLF,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык полярных волков',
    genitive: 'Языка полярных волков',
    instrumental: 'Языком полярных волков',
    prepositional: 'Языке полярных волков',
  },
  isRealLang: true,
}
