const PROGRESS_TABLE_ID = require('./../../PROGRESS_TABLE_ID')
const {GEAR_SHIELD} = require('./../../gearIdList')
const {PC_CLASS_BARBARIAN} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
} = require('./../../sourceList')

const {
  abilityScoreImprovementTemplate,
  extraAttackTemplate,
} = require('./../featureCommonRawList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_BARBARIAN,
  FEATURE_BRUTAL_CRITICAL,
  FEATURE_DANGER_SENSE,
  FEATURE_EXTRA_ATTACK_BARBARIAN,
  FEATURE_FAST_MOVEMENT,
  FEATURE_FERAL_INSTINCT,
  FEATURE_INDOMITABLE_MIGHT,
  FEATURE_INSTINCTIVE_POUNCE,
  FEATURE_PERSISTENT_RAGE,
  FEATURE_PRIMAL_CHAMPION,
  FEATURE_PRIMAL_KNOWLEDGE,
  FEATURE_PRIMAL_PATH,
  FEATURE_RAGE,
  FEATURE_RECKLESS_ATTACK,
  FEATURE_RELENTLESS_RAGE,
  FEATURE_UNARMORED_DEFENSE_BARBARIAN,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_UNARMORED_DEFENSE_BARBARIAN,
    lvl: 1,
    name: `Защита без доспехов`,
    nameEn: `Unarmored Defense`,
    text: `Если Вы не носите доспехов, Ваш Класс Доспеха равен 10 + модификатор Ловкости + модификатор Телосложения.

Вы можете использовать [щит](GEAR:${GEAR_SHIELD}), не теряя этого преимущества.`,
    source: {
      id: SOURCE_PHB,
      page: 49,
    },
  },
  {
    id: FEATURE_RAGE,
    lvl: 1,
    name: `Ярость`,
    nameEn: `Rage`,
    text: `В бою Вы сражаетесь с первобытной свирепостью. В свой ход Вы можете бонусным действием войти в состояние _Ярости_.

В состоянии _Ярости_ Вы получаете следующие преимущества, если не носите тяжёлую броню:

* Вы совершаете с преимуществом проверки и испытания Силы.
* Если Вы совершаете рукопашную атаку оружием, используя Силу, то получаете бонус к броску урона, соответствующий уровню варвара, как показано в колонке «Урон ярости» таблицы «[Развитие варвара](PC_CLASS:${PC_CLASS_BARBARIAN}#${PROGRESS_TABLE_ID})».
* Вы получаете сопротивление дробящему, колющему и рубящему урону.

Если Вы были способны использовать заклинания, то Вы не можете использовать или концентрироваться на заклинаниях, пока находитесь в состоянии _Ярости_. 

Ваша _Ярости_ длится 1 минуту. Она прекращается раньше, если Вы потеряли сознание, если Вы закончили ход, не атаковав враждебное по отношению к Вам существо с момента окончания прошлого хода, или если Вы с момента окончания прошлого хода не получили урон. Также Вы можете прекратить свою _Ярость_ бонусным действием.

Если Вы впадали в состояние ярости максимальное для Вашего уровня количество раз (смотрите колонку «[Ярость](PC_CLASS:${PC_CLASS_BARBARIAN}#${PROGRESS_TABLE_ID})»), то Вы должны совершить длинный отдых, прежде чем сможете использовать _Ярость_ ещё раз.`,
    source: {
      id: SOURCE_PHB,
      page: 49,
    },
  },
  {
    id: FEATURE_RECKLESS_ATTACK,
    lvl: 2,
    name: `Безрассудная атака`,
    nameEn: `Reckless Attack`,
    text: `Вы способны отбросить любую заботу о защите, чтобы атаковать ожесточённо и безрассудно.

Когда Вы совершаете первую атаку в свой ход, Вы можете решить, что будете атаковать безрассудно. Решившись на это, Вы в этом ходу совершаете рукопашные атаки оружием, использующие Силу, с преимуществом, но все броски атаки по Вам до Вашего следующего хода тоже совершаются с преимуществом.`,
    source: {
      id: SOURCE_PHB,
      page: 49,
    },
  },
  {
    id: FEATURE_DANGER_SENSE,
    lvl: 2,
    name: `Чувство опасности`,
    nameEn: `Danger Sense`,
    text: `Вы получаете обострённое ощущение происходящего вокруг, помогающее Вам избегать опасности.

Вы совершаете с преимуществом испытания Ловкости от эффектов, которые Вы можете видеть, такие как заклинания и ловушки. Для использования этого преимущества Вы не должны быть ослеплены, оглушены и не должны быть недееспособны.`,
    source: {
      id: SOURCE_PHB,
      page: 49,
    },
  },
  {
    id: FEATURE_PRIMAL_PATH,
    lvl: [3, 6, 10, 14],
    name: `Путь дикости`,
    nameEn: `Primal Path`,
    text: `Вы выбираете путь, определяющий природу Вашей ярости.

Ваш выбор обеспечит Вам умения на 3, 6, 10 и 14 уровнях.`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 49,
    },
  },
  {
    id: FEATURE_PRIMAL_KNOWLEDGE,
    lvl: [3, 10],
    name: `Первобытное знание`,
    nameEn: `Primal Knowledge`,
    text: `Достигая 3 и 10 уровней Вы получаете владение одным навыком по Вашему выбору из навыков, доступных для выбора варвару на 1 уровне.`,
    source: {
      id: SOURCE_TCoE,
      page: 29,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_BARBARIAN,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_BARBARIAN,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_FAST_MOVEMENT,
    lvl: 5,
    name: `Быстрое передвижение`,
    nameEn: `Fast Movement`,
    text: `Ваша скорость передвижения увеличивается на 10 футов, если Вы не носите тяжёлые доспехи.`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_FERAL_INSTINCT,
    lvl: 7,
    name: `Дикий инстинкт`,
    nameEn: `Feral Instinct`,
    text: `Ваши инстинкты настолько обостряются, что Вы совершаете с преимуществом броски инициативы.

Кроме того, если Вы были захвачены врасплох в начале боя, и Вы дееспособны, Вы можете в первом ходу действовать нормальным образом, но только если Вы впадёте в [Ярость](FEATURE:${FEATURE_RAGE}) раньше всех других действий в этом ходу.`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_INSTINCTIVE_POUNCE,
    lvl: 7,
    name: `Резкий скачок`,
    nameEn: `Instinctive Pounce`,
    text: `Частью того же бонусного действия, которым Вы входите в Ярость, Вы можете переместиться на расстояние, не превышающее половины своей скорости.`,
    source: {
      id: SOURCE_TCoE,
      page: 29,
    },
  },
  {
    id: FEATURE_BRUTAL_CRITICAL,
    lvl: [9, 13, 17],
    name: `Сильный критический удар`,
    nameEn: `Brutal Critical`,
    text: `Вы можете бросать одну дополнительную кость урона оружия, когда определяете дополнительный урон от критического попадания рукопашной атакой.

Количество костей увеличивается до двух на 13 уровне и трёх на 17 уровне.

| Уровень варвара | Количество дополнительных костей |
|-----------------|----------------------------------|
|  9              | 1                                |
| 13              | 2                                |
| 17              | 3                                |
`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_RELENTLESS_RAGE,
    lvl: 11,
    name: `Непреклонная ярость`,
    nameEn: `Relentless Rage`,
    text: `Ваша [Ярость](FEATURE:${FEATURE_RAGE}) позволяет Вам сражаться, несмотря на тяжелейшие раны. Если Ваши хиты опустилось до 0, когда Вы находились в состоянии [Ярости](FEATURE:${FEATURE_RAGE}), и Вы не умерли сразу, Вы можете пройти испытание Телосложения СЛ 10. При успехе Ваше здоровье опускается всего лишь до 1.

Каждый раз, когда Вы используете эту способность повторно, СЛ испытания повышается на 5. Когда Вы закончите короткий либо длинный отдых, СЛ снова равняется 10.`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_PERSISTENT_RAGE,
    lvl: 15,
    name: `Непрерывная ярость`,
    nameEn: `Persistent Rage`,
    text: `Ваша [Ярость](FEATURE:${FEATURE_RAGE}) становится настолько сильной, что досрочно прекращается только если Вы теряете сознание или сами прекращаете её.`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_INDOMITABLE_MIGHT,
    lvl: 18,
    name: `Неукротимая мощь`,
    nameEn: `Indomitable Might`,
    text: `Если результат Вашей проверки Силы оказался меньше значения Вашей Силы, то Вы можете использовать значение характеристики вместо результата проверки.`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_PRIMAL_CHAMPION,
    lvl: 20,
    name: `Дикий чемпион`,
    nameEn: `Primal Champion`,
    text: `Вы становитесь воплощением силы дикой природы. Значение Силы и Телосложения увеличивается на 4. Максимальное значение для этих характеристик теперь 24.`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_BARBARIAN,
  })
)
