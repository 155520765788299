const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_BLINK_DOG} = require('./../../languageIdList')

module.exports = {
  id: LANG_BLINK_DOG,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык мерцающих псов',
    genitive: 'Языка мерцающих псов',
    instrumental: 'Языком мерцающих псов',
    prepositional: 'Языке мерцающих псов',
  },
  isReady: false,
  isRealLang: true,
}
