const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {
  LANG_ABYSSAL,
  LANG_PRIMORDIAL,
} = require('./../../languageIdList')
const {
  SOURCE_ADND2_FoEtF,
  SOURCE_DND4_DMG,
} = require('./../../sourceList')
const {
  CREATURE_BARLGURA,
  CREATURE_BALOR,
} = require('./../../creatureIdList')
const {
  ALPHABET_INFERNAL,
  ALPHABET_BARAZHAD,
} = require('./../../alphabetList')

module.exports = {
  id: LANG_ABYSSAL,
  alphabetId: [
    ALPHABET_INFERNAL,
    ALPHABET_BARAZHAD,
  ],
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Язык Бездны',
    genitive: 'Языка Бездны',
    instrumental: 'Языком Бездны',
    prepositional: 'Языке Бездны',
  },
  nameEn: 'Abyssal',
  nameAlt: 'Демонический',
  typicalSpeakers: 'Демоны, гноллы, сахуагины',
  description: [
    {
      text: `Язык Бездны — это форма [Первородного](LANG:${LANG_PRIMORDIAL}), искажённая и искривлённая злом в сердце Бездны.`,
      source: {
        id: SOURCE_DND4_DMG,
        page: 172,
      },
    },
    {
      text: `Речь низших танар’ри представляет собой собачий лай и постоянное тявканье, поскольку они соперничают друг с другом за внимание. Речь более значимых исчадий звучит низко, как гул океанских волн и грозно, как потревоженное осиное гнездо. Но бывает что и [балор](CREATURE:${CREATURE_BALOR}) мерзко визжит, и что [барлгура](CREATURE:${CREATURE_BARLGURA}), случается, шепчет мягким голосом. Учитывая всё прочее, известное о танар’ри, невозможно предсказать то, как именно они будут пользоваться речью. Хотя одна вещь всё же ясна: их вокальные паттерны не столько точны, как телепатия и выдают местонахождение говорящего. Для жизни среди невероятно территориальных исчадий это может стать смертным приговором.

По правде говоря, танар’ри — мечта исследователя. Они не полностью понимают даже речь других исчадий и уж не стоит говорить о том, что самих их тоже мало кто может полностью понимать. Когда смертные учёные пытаются изучать язык танар’ри, они обычно фокусируются на диалекте одного или двух исчадий, но даже в этом случае это разочаровывающая задача. Возможно, с этим связано то, что танар’ри всё время так злы — их совершенно никто не понимает. Это также может объяснить то, почему танар’ри части тяготеют к способам общения, которые нельзя неверно истолковать — например, к пыткам.`,
      source: {
        id: SOURCE_ADND2_FoEtF,
        page: 56,
      },
    },
    {
      header: `Слова Силы`,
      text: `Язык Бездны включает слова силы — слова, чьи слоги содержат первородное зло. Персонажи игроков могут изучить основы общения на языке Бездны, но не смогут освоить эти могущественные звуки. Тексты, содержащие такие слова могут обладать мощными эффектами — и тома или свитки с ними могут быть артефактами сами по себе.`,
      source: {
        id: SOURCE_DND4_DMG,
        page: 172,
      },
    },
  ],
  isRealLang: true,
  isReady: true,
}
