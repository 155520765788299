const {
  SPELL_CHARM_PERSON,
  SPELL_CHROMATIC_ORB,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FIRE_BOLT,
  SPELL_MAGE_HAND,
  SPELL_MENDING,
  SPELL_POISON_SPRAY,
  SPELL_SCORCHING_RAY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SOURCE_ERRATA_VGTM,
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KOBOLD,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_PACK_TACTICS,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  SKILL_ARCANA,
  SKILL_MEDICINE,
} = require('./../../../../skillList')
const {
  koboldDescriptionList,
  koboldNote,
} = require('./../../../../textCommonParts')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_KOBOLD_SCALE_SORCERER} = require('./../../../../creatureIdList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Кобольд чешуйчатый чародей',
  nameEn: 'Kobold Scale Sorcerer',
  id: CREATURE_KOBOLD_SCALE_SORCERER,
  description: [
    `Кобольд чешуйчатый чародей обладает врождённым магическим талантом, что делает его делает его ценным членом племени по нескольким причинам. Поскольку божество кобольдов остается плененным, в большинстве племен нет членов, которые могли бы использовать божественную магию, и чешуйчатые чародеи берут на себя роль советников и историков. В мирное время они используют свои заклинания для защиты и усиления укреплений норы и помощи остальному племени. Когда племя находится под угрозой, чешуйчатый чародей бросается на врагов с огнём и ядом, сохраняя немного магии для себя на случай если ему понадобится сбежать или перехитрить захватчика.

**Связанный долгом с драконом.** В племени кобольдов, связанном с драконом, обычно таком, которое живет неподалеку от логова дракона или в нём, чешуйчатый чародей также служит дипломатом, предвосхищает нужды дракона, раздаёт команды другим кобольдам от имени дракона и докладывает ему об исполнении. Чародей так же трепещет и уважает драконов как и обычные кобольды, но он знает, что его долг требует не подлизываться к хозяину всё время. Он также понимает, что его частая близость к дракону означает, что если его хозяин разозлится или будет недоволен, то он погибнет первым, так что он неистово поддерживает баланс между обожанием и ужасом в своем отношении к дракону.`,
    ...koboldDescriptionList,
  ],
  note: koboldNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_KOBOLD,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 161,
    },
    {
      id: SOURCE_ERRATA_VGTM,
      title: 'Описано восстановление очков чародейства и убрана трата бонусного действия',
      page: 1,
    },
    {
      id: SOURCE_TOA,
      page: 231,
    },
  ],
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_MEDICINE]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Очки чародейства',
      description: `У ★него★ есть 3 очка чародейства. Завершая длинный отдых, ★СУЩЕСТВО★ восстанавливает все очки чародейства. ★Он★ может потратить очки чародейства, чтобы получить один из следующих полезных эффектов:

* **Непреодолимое заклинание:** Накладывая заклинание, вынуждающее существо совершить испытание для защиты от его эффектов, ★СУЩЕСТВО★ может потратить 3 очка чародейства, чтобы одна из целей заклинания совершила первое испытание от этого заклинания с помехой.
* **Неуловимое заклинание:** Во время использования заклинания ★СУЩЕСТВО★ может потратить 1 очко чародейства, чтобы сотворить его без вербальных и соматических компонентов.`,
    },
    ABILITY_PACK_TACTICS,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    spellCasterLevel: 3,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_CHARM_PERSON,
      SPELL_CHROMATIC_ORB,
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_FIRE_BOLT,
      SPELL_MAGE_HAND,
      SPELL_MENDING,
      SPELL_POISON_SPRAY,
      SPELL_SCORCHING_RAY,
    ],
    slotCountList: [
      Infinity,
      4,
      2,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
