const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_ABYSSAL,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_YUGOLOTH,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_TRUE_VISION,
} = require('./../../../../senseList')
const {
  SKILL_INVESTIGATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_CANOLOTH} = require('./../../../../creatureIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {yugolothDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Канолот',
  nameEn: 'Canoloth',
  id: CREATURE_CANOLOTH,
  description: [
    `Канолоты предпочитают заключать контракты, связанные с охраной ценных сокровищ и важных мест. Они всегда выполняют задачу ровно так, как договорено — ни больше, ни меньше.

Обладая острым чутьём, достаточным для точного определения местоположения близлежащих невидимых существ, канолоты безотлагательно реагируют на любую замеченную угрозу. К тому же, они создают поле магических помех, которое предотвращает телепортацию существ рядом с ними.

Канолоты противостоят злоумышленникам быстро и с ужасающей силой, используя длинные, колючие языки для захвата противников и притягивания их ближе к себе. Дальнейшие действия зависят от контракта. Если не было приказа убивать, канолот будет только удерживает злоумышленника, но если такой приказ был, то он разрывает жертву на мелкие куски.`,
    ...yugolothDescriptionList,
  ],
  note: {
    text: `Канолоты ленивы по своей природе. Если у них нет причины нападать, то вряд ли они станут это делать.`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_YUGOLOTH,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 250,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_INVESTIGATION]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_8,
  featureList: [
    {
      name: 'Межпространственный замок',
      description: `Другие существа не могут телепортироваться в пределах 60 футов от канолота. Любые попытки это сделать, оборачиваются неудачей.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Невероятные чутьё',
      description: `Канолот не может быть застигнут врасплох, пока он ★дееспособен★.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Канолот совершает две атаки: одну _Языком_ или _Укусом_ и одну _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 6,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Язык',
      description: `Если цель среднего или меньшего размера, она становится схваченной (СЛ высвобождения 15), подтягивается на 30 футов к канолоту и обездвиживается до конца действия захвата. Канолот может держать языком в захвате только одну цель одновременно.`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 7,
        range: 30,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 12,
          diceBonus: 4,
        },
      },
    },
  ],
}
