const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_LIZARDFOLK,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  lizardfolkNotes,
  lizardfolkDescriptionList,
} = require('./../../../../textCommonParts')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  GEAR_JAVELIN,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {ABILITY_HOLD_BREATH} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_LIZARDFOLK} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_DRACONIC} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Людоящер',
  nameEn: 'Lizardfolk',
  note: lizardfolkNotes,
  description: lizardfolkDescriptionList,
  id: CREATURE_LIZARDFOLK,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_LIZARDFOLK,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 197,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
    gearId: GEAR_SHIELD,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 13,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  languageList: [
    LANG_DRACONIC,
  ],
  cr: CR_1_2,
  featureList: [
    {
      id: ABILITY_HOLD_BREATH,
      time: '15 минут',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки, каждую разным оружием.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Тяжёлая дубина',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_JAVELIN,
    },
    {
      name: 'Шипастый щит',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
