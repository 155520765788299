const {CREATURE_STORM_GIANT} = require('./../../creatureIdList')
const {SOURCE_VGTM} = require('./../../sourceList')
const {
  GOD_ANNAM,
  GOD_STRONMAUS,
} = require('./../../godIdList')

module.exports = [
  {
    header: 'Настроения Стронмауса',
    text: `[Штормовые великаны](CREATURE:${CREATURE_STORM_GIANT}) преклоняются перед [Стронмаусом](GOD:${GOD_STRONMAUS}), старшим из детей [Аннама](GOD:${GOD_ANNAM}), который является самым радостным, смешливым, он наслаждается общением со своими братьями. Этот образ [Стронмауса](GOD:${GOD_STRONMAUS}) резко контрастирует с тем, как [штормовые великаны](CREATURE:${CREATURE_STORM_GIANT}) воспринимаются в мире: отстраненными и мрачными. Тем не менее, этот образ правдив.

В легендах великанов [Стронмаус](GOD:${GOD_STRONMAUS}) часто впадает в невесёлое настроение и глубокую задумчивость, и эти моменты бывают так же ярки, как и моменты его жизнерадостности. Правда и то, что [штормовые великаны](CREATURE:${CREATURE_STORM_GIANT}) не настолько обделены чувством юмора, насколько их изображают. Они тихие и сдержанные, когда находятся в одиночестве, то есть большую часть времени. Но когда они собираются вместе с собратьями, они веселятся, поют песни, и пьют столько же, сколько и [Стронмаус](GOD:${GOD_STRONMAUS}). Ради сохранения своей приватности, а также для обеспечения безопасности мелких существ в окрестностях, эти редкие встречи происходят вдали от других существ, тем самым увековечив репутацию великанов как постоянно мрачных и угрюмых.`,
    source: {
      id: SOURCE_VGTM,
      page: 33,
    },
  },
]
