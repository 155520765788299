const {
  SOURCE_GGTR,
  SOURCE_TCoE,
} = require('./../../../sourceList')
const {druidCircleSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_DRUID_CIRCLE_OF_SPORES} = require('./../../../pcSubClassIdList')
const {CREATURE_ZOMBIE} = require('./../../../creatureIdList')

const {
  SPELL_ANIMATE_DEAD,
  SPELL_BLIGHT,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CHILL_TOUCH,
  SPELL_CLOUDKILL,
  SPELL_CONFUSION,
  SPELL_CONTAGION,
  SPELL_GASEOUS_FORM,
  SPELL_GENTLE_REPOSE,
} = require('./../../../spellIdList')
const {
  FEATURE_CIRCLE_OF_SPORES_SPELLS,
  FEATURE_CIRCLE_OF_SPORES_SPELLS_spellLvl_0,
  FEATURE_FUNGAL_BODY,
  FEATURE_FUNGAL_INFESTATION,
  FEATURE_HALO_OF_SPORES,
  FEATURE_SPREADING_SPORES,
  FEATURE_SYMBIOTIC_ENTITY,
  FEATURE_WILD_SHAPE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_CIRCLE_OF_SPORES_SPELLS,
    name: `Заклинания круга`,
    nameEn: `Circle Spells`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_SPORES,
    text: `Ваша симбиотическая связь с грибами и Ваша способность вмешиваться в цикл жизни и смерти предоставляет Вам доступ к определенным заклинаниям. На 3-м, 5-м, 7-м и 9-м уровнях Вы получаете доступ к заклинаниям, указанным для этого уровня в таблице «Заклинания круга спор».

Доступное Вам заклинание круга всегда считается у Вас подготовленным, и не учитывается при подсчёте количества заклинаний, которые Вы можете подготавливать. Если Вы получаете доступ к заклинанию, отсутствующему в списке заклинаний друида, оно всё равно будет считаться для Вас заклинанием друида.`,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_ANIMATE_DEAD,
      SPELL_BLIGHT,
      SPELL_BLINDNESS_DEAFNESS,
      SPELL_CLOUDKILL,
      SPELL_CONFUSION,
      SPELL_CONTAGION,
      SPELL_GASEOUS_FORM,
      SPELL_GENTLE_REPOSE,
    ],
    source: [
      {
        id: SOURCE_GGTR,
        page: 27,
      },
      {
        id: SOURCE_TCoE,
        page: 52,
      },
    ],
  },
  {
    id: FEATURE_CIRCLE_OF_SPORES_SPELLS_spellLvl_0,
    name: `Леденящее прикосновение`,
    nameEn: `Chill Touch`,
    lvl: 2,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_SPORES,
    text: `Вы изучаете заговор [Леденящее прикосновение](SPELL:${SPELL_CHILL_TOUCH}). Он считается для Вас заклинанием друида.`,
    spellIdList: SPELL_CHILL_TOUCH,
    source: [
      {
        id: SOURCE_GGTR,
        page: 27,
      },
      {
        id: SOURCE_TCoE,
        page: 52,
      },
    ],
  },
  {
    id: FEATURE_HALO_OF_SPORES,
    name: `Облако спор`,
    nameEn: `Halo of Spores`,
    lvl: [2, 6, 10, 14],
    text: `Вы окружены невидимыми некротическими спорами, которые безвредны, пока Вы не натравите их на существо поблизости.

Когда видимое Вами существо подходит к Вам на расстояние 10 футов или начинает там ход, Вы можете реакцией заставить споры нанести ему 1к4 некротического урона, если оно не пройдёт испытание Телосложения против Вашей сложности заклинаний.

Некротический урон увеличивается до 1к6 на 6 уровне, 1к8 на 10 уровне и 1к10 на 14 уровне.`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 28,
      },
      {
        id: SOURCE_TCoE,
        page: 52,
      },
    ],
  },
  {
    id: FEATURE_SYMBIOTIC_ENTITY,
    name: `Симботическая сущность`,
    nameEn: `Symbiotic Entity`,
    lvl: 2,
    text: `Вы получаете способность вливать магию в Ваши споры. Действием Вы можете потратить одно использование [Дикого облика](FEATURE:${FEATURE_WILD_SHAPE}), пробуждая споры вместо превращения в звериную форму. Вы получаете 5 временных хитов за каждый уровень друида.

Пока эта способность действует, Вы получаете следующие преимущества:

* Когда Вы наносите урон [Облаком спор](FEATURE:${FEATURE_HALO_OF_SPORES}), добавьте вторую кость урона.
* Ваши атаки рукопашным оружием наносят дополнительно 1к6 урона ядом.

Эти преимущества длятся 10 минут, пока Вы не потеряете эти временные хиты или пока Вы снова не используете [Дикий облик](FEATURE:${FEATURE_WILD_SHAPE}).`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 28,
      },
      {
        id: SOURCE_TCoE,
        page: 52,
      },
    ],
  },
  {
    id: FEATURE_FUNGAL_INFESTATION,
    name: `Грибное заражение`,
    nameEn: `Fungal Infestation`,
    lvl: 6,
    text: `Ваши споры дают Вам способность заразить ими труп и поднять его. Если зверь или гуманоид маленького или среднего размера умирает в пределах 10 футов от Вас, Вы можете реакцией поднять его, немедленно заставляя его встать на ноги с 1 хитом. Существо использует статблок [зомби](CREATURE:${CREATURE_ZOMBIE}). Оно остаётся оживлённым 1 час, после чего падает и умирает.

В бою [зомби](CREATURE:${CREATURE_ZOMBIE}) ходит сразу после Вас. Он подчиняется Вашим ментальным командам и из действий может выполнять только действие Атака, совершая одну рукопашную атаку оружием.

Вы можете использовать эту способность число раз, равное Вашему модификатору Мудрости (минимум один раз) и восстанавливаете все использования, завершив длинный отдых.`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 28,
      },
      {
        id: SOURCE_TCoE,
        page: 52,
      },
    ],
  },
  {
    id: FEATURE_SPREADING_SPORES,
    name: `Распространение спор`,
    nameEn: `Spreading Spores`,
    lvl: 10,
    text: `Вы получаете способность засеивать область смертоносными спорами. Пока активна Ваша [Симботическая сущность](FEATURE:${FEATURE_SYMBIOTIC_ENTITY}), Вы можете бонусным действием метнуть споры на расстояние до 30 футов, где они начинают клубиться 10-футовым кубом на 1 минуту. Споры исчезают раньше, если Вы снова используете эту способность, разгоните их бонусным действием или Ваша [Симботическая сущность](FEATURE:${FEATURE_SYMBIOTIC_ENTITY}) перестанет действовать.

Если существо входит в этот куб или начинает в нём свой ход, оно получает урон от Вашего [Облака спор](FEATURE:${FEATURE_HALO_OF_SPORES}), если не пройдёт испытание Телосложения против Вашей сложности заклинаний. Существо не может получать этот урон чаще, чем один раз в ход.

Пока куб спор существует, Вы не можете использовать Ваше [Облако спор](FEATURE:${FEATURE_HALO_OF_SPORES}) реакцией.`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 28,
      },
      {
        id: SOURCE_TCoE,
        page: 52,
      },
    ],
  },
  {
    id: FEATURE_FUNGAL_BODY,
    name: `Грибное тело`,
    nameEn: `Fungal Body`,
    lvl: 14,
    text: `Грибные споры в Вашем теле изменяют Вас.

Вы не можете быть ослеплены, оглушены, отравлены, и, пока Вы в сознании, все критические попадания по Вам считаются обычными попаданиями.`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 28,
      },
      {
        id: SOURCE_TCoE,
        page: 52,
      },
    ],
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_SPORES,
  })
)
