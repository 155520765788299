const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_2,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_MONSTROSITY,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  grickDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_STONE_CAMOUFLAGE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_GRICK,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Грик',
  nameEn: 'Grick',
  id: CREATURE_GRICK,
  description: grickDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 75,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 5,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_STONE_CAMOUFLAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает одну атаку _Щупальцами_. Если эта атака попадает, ★СУЩЕСТВО★ может совершить одну атаку _Клювом_ по той же самой цели.`,
    },
    {
      name: 'Щупальца',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
