const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_FROST_GIANT,
  CREATURE_FROST_GIANT_SKELETON,
  CREATURE_NECROMANCER,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_GIANT_FROST,
  CREATURE_TYPE_SKELETON,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {ABILITY_UNUSUAL_NATURE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_SCRAPS} = require('./../../../../armorTypeList')
const {CR_6} = require('./../../../../crList')
const {GEAR_GREATAXE} = require('./../../../../gearIdList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {skeletonDescriptionList} = require('./../../../../textCommonParts')
const {SOURCE_IDRotF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Скелет ледяного великана',
  nameAlt: 'Скелет ледяного гиганта',
  nameEn: 'Frost Giant Skeleton',
  id: CREATURE_FROST_GIANT_SKELETON,
  description: [
    `[Некроманты](CREATURE:${CREATURE_NECROMANCER}) могут превращать кости давно мёртвых [ледяных великанов](CREATURE:${CREATURE_FROST_GIANT}) в злобных разрушителей, которым нравится вредить всему живому.`,
    ...skeletonDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GIANT_FROST,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_IDRotF,
    page: 290,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_SCRAPS,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 15,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 5,
  },
  vulnerabilityList: [
    DAMAGE_BLUDGEONING,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_GIANT,
      doNotSpeak: true,
    },
  ],
  cr: CR_6,
  featureList: [
    ABILITY_UNUSUAL_NATURE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Секирой_.`,
    },
    {
      name: 'Секира',
      // gearId: GEAR_GREATAXE, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 3,
          diceType: 12,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Леденящий взгляд',
      description: `★СУЩЕСТВО★ выбирает одно видимое им в пределах 60 футов существо. Цель должна пройти испытание Телосложения СЛ 13 или получить 35 (10к6) урона холодом и стать парализованой до конца своего следующего хода.`,
    },
  ],
}
