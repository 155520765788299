const giantNoteList = require('./giant')

module.exports = [
  ...giantNoteList,
  {
    text: `Здесь пал варвар Ангеррот под натиском отряда великанов. Кости его лежат вон под тем валуном`,
    author: `Старейшина Зелан из Истивана, воспоминания о Войне Великанов`,
  },
  {
    text: `Если Вы хотите сковать что–то достойное королей, у вас два варианта: дварфы и огненные гиганты. И если Вы не хотите стать рабом в угольных шахтах, то у вас остаться только один вариант.`,
    author: `Воло`,
  },
]
