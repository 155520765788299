const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {POISON_TYPE_CONTACT} = require('./../../../poisonTypeList')
const {VOLUME_UNIT_FEET_CUBE} = require('./../../../volumeUnitList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
} = require('./../../../paramList')
const {
  SOURCE_DMG,
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_TOA,
  SOURCE_XGTE,
  SOURCE_AYAGWnW,
  SOURCE_VGTM,
} = require('./../../../sourceList')
const {
  CREATURE_CAMEL,
  CREATURE_CARRION_CRAWLER,
  CREATURE_COW,
} = require('./../../../creatureIdList')
const {
  CAT_AMMUNITION,
  CAT_ANIMALS,
  CAT_ARTISAN_TOOLS,
  CAT_CLOTHES,
  CAT_CONTAINERS,
  CAT_EQUIPMENT,
  CAT_FOOD,
  CAT_GOODS,
  CAT_HEAVY_ARMOR,
  CAT_LIGHT_SOURCE,
  CAT_MAGIC_FOCUS,
  CAT_MEDIUM_ARMOR,
  CAT_POISONS,
  CAT_TRANSPORT_GROUND,
  CAT_TRANSPORT_WATER,
} = require('./../../../gearCategoryList')
const {
  GEAR_ARROWS,
  GEAR_CALLIGRAPHERS_SUPPLIES,
  GEAR_CALTROPS,
  GEAR_CAMEL,
  GEAR_CANDLE,
  GEAR_CANOE,
  GEAR_CANVAS,
  GEAR_CARPENTER_S_TOOLS,
  GEAR_CARRIAGE,
  GEAR_CART,
  GEAR_CARTOGRAPHER_S_TOOLS,
  GEAR_CASE_CROSSBOW_BOLT,
  GEAR_CASE_MAP_OR_SCROLL,
  GEAR_CHAIN,
  GEAR_CHAIN_MAIL,
  GEAR_CHAIN_SHIRT,
  GEAR_CHALK,
  GEAR_CHARIOT,
  GEAR_CHEESE_HUNK,
  GEAR_CHEST,
  GEAR_CHICKEN,
  GEAR_CINNAMON,
  GEAR_CLIMBER_S_KIT,
  GEAR_CLOTHES_COMMON,
  GEAR_CLOTHES_COSTUME,
  GEAR_CLOTHES_FINE,
  GEAR_CLOTHES_TRAVELERS,
  GEAR_CLOVES,
  GEAR_COBBLER_S_TOOLS,
  GEAR_COMPONENT_POUCH,
  GEAR_COOK_S_UTENSILS,
  GEAR_COPPER,
  GEAR_COTTON_CLOTH,
  GEAR_COW,
  GEAR_CRAWLER_MUCUS,
  GEAR_CROSSBOW_BOLTS,
  GEAR_CROWBAR,
  GEAR_CRYSTAL,
  GEAR_HAMMER,
  GEAR_INK,
  GEAR_INK_PEN,
  GEAR_PAPER,
  GEAR_PARCHMENT,
  GEAR_PITON,
  GEAR_POT_IRON,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_CHAIN_SHIRT,
    genderId: GENDER_FEMALE,
    name: 'Кольчужная рубаха',
    nameByCase: {
      nominative: 'кольчужная рубаха',
      genitive: 'кольчужной рубахи',
      accusative: 'кольчужную рубаху',
      instrumental: 'кольчужной рубахой',
    },
    nameEn: 'Chain Shirt',
    description: `Сделанная из переплетённых металлических колец кольчужная рубаха носится между слоями одежды или кожи. Этот доспех предоставляет умеренную защиту торса и заглушает звон колец внешним покрытием.`,
    cost: 5000,
    weight: 20,
    acChangeTo: 13,
    acUseDexMod: true,
    acDexModMax: 2,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_MEDIUM_ARMOR,
  },
  {
    id: GEAR_CHAIN_MAIL,
    genderId: GENDER_FEMALE,
    name: 'Кольчуга',
    nameByCase: {
      nominative: 'кольчуга',
      genitive: 'кольчуги',
      accusative: 'кольчугу',
      instrumental: 'кольчугой',
    },
    nameEn: 'Chain Mail',
    description: `Изготовленная из переплетающихся металлических колец кольчуга включает также слой стёганой ткани, надеваемой под низ, дабы предотвратить натирание и смягчать удары. В комплект входят рукавицы.
    
Переплетенные металлические кольца поверх слоя стёганой ткани в этой броне обеспечивают надежную защиту от оружия с острыми лезвиями, такого как мечи и [стрелы](GEAR:${GEAR_ARROWS}). Будьте осторожны, однако, Вы всё ещё можете получать синяки и переломы через кольчугу, да и её кольца могут шуметь при движении!`,
    cost: 7500,
    weight: 55,
    hidingDisadvantage: true,
    acChangeTo: 16,
    requirementList: [
      {
        type: 'param',
        paramType: PARAM_STR,
        min: 13,
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_HEAVY_ARMOR,
  },
  {
    id: GEAR_CROSSBOW_BOLTS,
    genderId: GENDER_MULTIPLE,
    name: 'Арбалетные болты',
    nameByCase: {
      nominative: 'арбалетные болты',
      genitive: 'арбалетных болтов',
      accusative: 'арбалетные болты',
      instrumental: 'арбалетными болтами',
    },
    nameEn: 'Crossbow Bolts',
    quantity: 20,
    cost: 100,
    weight: 1.5,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_AMMUNITION,
  },
  {
    id: GEAR_CALTROPS,
    genderId: GENDER_MULTIPLE,
    name: 'Калтропы',
    nameAlt: [
      'Чеснок',
      'Рогульки',
    ],
    nameByCase: {
      nominative: 'калтропы',
      genitive: 'калтропов',
      accusative: 'калтропы',
      instrumental: 'калтропами',
    },
    nameEn: 'Caltrops',
    description: '20 штук в сумке. Вы можете действием рассыпать сумку калтропов по площади в 5×5 фт. Все существа, входящие в эту область, должны пройти испытание Ловкости СЛ 15, иначе они останавливаются и получают колющий урон 1. Пока это существо не восстановит как минимум 1 хит, его скорость ходьбы уменьшена на 10 фт. Существо, перемещающееся по этой области с уменьшенной вдвое скоростью, не обязано проходить испытание.',
    cost: 100,
    quantity: 20,
    damageType: DAMAGE_PIERCING,
    damage: {
      diceBonus: 1,
    },
    weight: 2,
    saveThrow: {
      type: PARAM_DEX,
      dc: 15,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_CLIMBER_S_KIT,
    genderId: GENDER_MALE,
    name: 'Набор верхолаза',
    nameByCase: {
      nominative: 'набор верхолаза',
      genitive: 'набора верхолаза',
      accusative: 'набор верхолаза',
      instrumental: 'набором верхолаза',
    },
    nameAlt: 'Комплект для лазания',
    nameEn: 'Climber’s Kit',
    description: `Независимо от того, лезете ли Вы на скалу, дерево или стену замка, делать это вам будет безопаснее с набором для лазания.

В набор для лазания входят

* [шлямбуры](GEAR:${GEAR_PITON}),
* накладные подошвы,
* перчатки
* страховочная привязь.

Вы можете действием использовать набор верхолаза, чтобы закрепиться на высоте; если Вы делаете это, Вы не можете упасть более чем на 25 футов от того места, где закрепились, но и не можете подняться выше 25 фт от этого места, не открепившись.`,
    cost: 2500,
    weight: 12,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_CASE_CROSSBOW_BOLT,
    genderId: GENDER_MALE,
    name: 'Контейнер для арбалетных болтов',
    nameByCase: {
      nominative: 'контейнер для арбалетных болтов',
      genitive: 'контейнера для арбалетных болтов',
      accusative: 'контейнер для арбалетных болтов',
      instrumental: 'контейнером для арбалетных болтов',
    },
    nameEn: 'Case, Crossbow Bolt',
    description: `В этот деревянный контейнер помещаются 20 [арбалетных болтов](GEAR:${GEAR_CROSSBOW_BOLTS}).`,
    cost: 100,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_CASE_MAP_OR_SCROLL,
    genderId: GENDER_MALE,
    name: 'Тубус (для свитков и карт)',
    nameByCase: {
      nominative: 'тубус (для свитков и карт)',
      genitive: 'тубуса (для свитков и карт)',
      accusative: 'тубус (для свитков и карт)',
      instrumental: 'тубусом (для свитков и карт)',
    },
    nameAlt: 'Контейнер для карт и свитков',
    nameEn: 'Case, Map or Scroll',
    description: `В этом цилиндрическом кожаном тубусе может храниться до десяти скрученных листов [бумаги](GEAR:${GEAR_PAPER}) или пять скрученных листов [пергамента](GEAR:${GEAR_PARCHMENT}).`,
    cost: 100,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_CROWBAR,
    genderId: GENDER_MALE,
    name: 'Ломик',
    nameByCase: {
      nominative: 'ломик',
      genitive: 'ломика',
      accusative: 'ломик',
      instrumental: 'ломиком',
    },
    nameEn: 'Crowbar',
    description: 'Использование ломика позволяет совершать проверки Силы с преимуществом, если рычаг должен помочь.',
    cost: 200,
    weight: 5,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_CRYSTAL,
    genderId: GENDER_MALE,
    name: 'Кристалл',
    nameByCase: {
      nominative: 'Кристалл',
      accusative: 'Кристалл',
      instrumental: 'Кристаллом',
    },
    nameEn: 'Crystal',
    description: 'Это особый предмет, созданный для проведения тайных заклинаний. Волшебники, колдуны и чародеи могут использовать эти предметы в качестве фокусировки для заклинаний.',
    cost: 1000,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_MAGIC_FOCUS,
  },
  {
    id: GEAR_CHALK,
    genderId: GENDER_MALE,
    name: 'Мел',
    nameByCase: {
      nominative: 'Мел',
      accusative: 'Мел',
      instrumental: 'Мелом',
    },
    nameEn: 'Chalk',
    description: '1 кусочек',
    cost: 1,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_COMPONENT_POUCH,
    genderId: GENDER_MALE,
    name: 'Сумка с реагентами',
    nameByCase: {
      nominative: 'Сумка с реагентами',
      accusative: 'Сумка с реагентами',
      instrumental: 'Сумкой с реагентами',
    },
    nameAlt: 'Мешочек с компонентами',
    nameEn: 'Component Pouch',
    description: `Мешочек с компонентами это маленький водонепроницаемый кожаный поясной кошель с отделениями для хранения материальных компонентов и других особых предметов, нужных для накладывания заклинаний, если только у этих компонентов не указана стоимость (смотрите описание заклинания).`,
    cost: 2500,
    weight: 2,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_CLOTHES_TRAVELERS,
    genderId: GENDER_FEMALE,
    name: 'Одежда путешественника',
    nameByCase: {
      nominative: 'Одежда путешественника',
      accusative: 'Одежду путешественника',
      instrumental: 'Одеждой путешественника',
    },
    nameAlt: 'Дорожная одежда',
    nameEn: 'Clothes, Traveler’s',
    description: {
      text: `Прочная практичная одежда из грубой, износостойкой ткани. Часто дополняется длинным плащом для тепла и защиты от стихий!

Надевайте её, когда путешествуете на большие расстояния или пытаетесь найти место в таверне.
    
Не надевайте её на модный банкет, если не хотите расстроить его хозяина.`,
      source: {
        id: SOURCE_AYAGWnW,
        page: 100,
      },
    },
    cost: 200,
    weight: 4,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CLOTHES,
  },
  {
    id: GEAR_CLOTHES_COSTUME,
    genderId: GENDER_MALE,
    name: 'Одежда, сценический костюм',
    nameByCase: {
      nominative: 'Одежда, сценический костюм',
      accusative: 'Одежда, сценический костюм',
      instrumental: 'Одеждой, сценическим костюмом',
    },
    nameAlt: 'Костюм',
    nameEn: 'Costume clothes',
    cost: 500,
    weight: 4,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CLOTHES,
  },
  {
    id: GEAR_CLOTHES_COMMON,
    genderId: GENDER_FEMALE,
    name: 'Одежда, повседневная',
    nameByCase: {
      nominative: 'Одежда, повседневная',
      accusative: 'Одежду, повседневную',
      instrumental: 'Одеждой, повседневной',
    },
    nameAlt: 'Обычная одежда',
    nameEn: 'Common clothes',
    cost: 50,
    weight: 3,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CLOTHES,
  },
  {
    id: GEAR_CLOTHES_FINE,
    genderId: GENDER_FEMALE,
    name: 'Одежда, парадная',
    nameByCase: {
      nominative: 'Одежда, парадная',
      accusative: 'Одежду, парадную',
      instrumental: 'Одеждой, парадной',
    },
    nameAlt: 'Отличная одежда',
    nameEn: 'Fine clothes',
    description: `Изысканный наряд из дорогих материалов, который даже может быть украшен шёлковыми нитями и драгоценными камнями.

Надейвайте её, когда вам нужно произвести впечатление на дворян, участвовать в дипломатии или запугать бедных людей.

Не надевайте её, когда отправляетесь в приключение — модные ткани слишком тонки для опасностей подземелий, а всякие побрякушки могут привлечь неприятных существ!`,
    cost: 1500,
    weight: 6,
    source: [
      {
        id: SOURCE_PHB,
        page: 150,
      },
      {
        id: SOURCE_AYAGWnW,
        page: 100,
      },
    ],
    category: CAT_CLOTHES,
  },
  {
    id: GEAR_CANDLE,
    genderId: GENDER_FEMALE,
    name: 'Свеча',
    nameByCase: {
      nominative: 'Свеча',
      accusative: 'Свечу',
      instrumental: 'Свечой',
    },
    nameEn: 'Candle',
    description: 'В течение 1 часа свеча испускает яркий свет в пределах радиуса 5 фт и тусклый свет в пределах ещё 5 фт.',
    range: {
      normal: 5,
      max: 10,
    },
    cost: 1,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_LIGHT_SOURCE,
  },
  {
    id: GEAR_CHEST,
    genderId: GENDER_MALE,
    name: 'Сундук',
    nameByCase: {
      nominative: 'Сундук',
      accusative: 'Сундук',
      instrumental: 'Сундуком',
    },
    nameEn: 'Chest',
    description: '12 фт³/300 фунтов',
    cost: 500,
    weight: 25,
    volume: {
      unitId: VOLUME_UNIT_FEET_CUBE,
      count: 12,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_CHAIN,
    genderId: GENDER_FEMALE,
    name: 'Цепь',
    nameByCase: {
      nominative: 'цепь',
      genitive: 'цепа',
      accusative: 'цепь',
      instrumental: 'цепью',
    },
    nameEn: 'Chain',
    description: `Тяжёлые железные цепи — сложное оружие для владения, но если у вас достаточно силы, чтобы тащить и раскачивать цепь, Вы можете сильно ей ударить!

Длина 10 фт. У цепи 10 хитов. Её можно порвать успешной проверкой Силы СЛ 20.`,
    length: 10,
    hp: 10,
    cost: 500,
    saveThrow: {
      type: PARAM_STR,
      dc: 20,
    },
    weight: 10,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_CALLIGRAPHERS_SUPPLIES,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты каллиграфа',
    nameByCase: {
      nominative: 'инструменты каллиграфа',
      genitive: 'инструментов каллиграфа',
      accusative: 'инструменты каллиграфа',
      instrumental: 'инструментами каллиграфа',
    },
    nameEn: 'Calligrapher’s Supplies',
    description: `Каллиграфия трактует письмо как прекрасное утончённое искусство. Каллиграфы создают текст, который радует глаз, используя стиль, который сложно подделать. Их инструменты также дают им возможность исследовать рукописный текст и определить его подлинность, поскольку обучение каллиграфа включало в себя множество часов обучения письму и попыток повторить его стиль и оформление.

**Компоненты.** Инструменты каллиграфа включают в себя

* [чернила](GEAR:${GEAR_INK}),
* множество [пергаментных листов](GEAR:${GEAR_PARCHMENT}),
* три [писчих пера](GEAR:${GEAR_INK_PEN}).

**Магия.** Хотя каллиграфия мало помогает в расшифровке содержимого магического текста, владение этими инструментами помогает определить, кто написал магический текст.

**История.** Владение этими инструментами может увеличить выгоду при успешной проверке, совершаемой для анализа или исследования древних записей, свитков или другого текста, включая руны, вырезанные на камне, а также письмена на фресках или других изображениях.

**Расшифровка карты сокровищ.** Владение этими инструментами позволяет вам проводить экспертизу карт. Вы можете пройти проверку Интеллекта, чтобы оценить возраст карты, определить, содержит ли карта скрытое послание, и так далее.

| Действие                                         | СЛ |
|--------------------------------------------------|----|
| Опознать писавшего немагический рукописный текст | 10 |
| Определить душевное состояние писавшего          | 15 |
| Распознать поддельный текст                      | 15 |
| Подделать подпись                                | 20 |
`,
    cost: 1000,
    weight: 5,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 81,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_CARTOGRAPHER_S_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты картографа',
    nameByCase: {
      nominative: 'инструменты картографа',
      genitive: 'инструментов картографа',
      accusative: 'инструменты картографа',
      instrumental: 'инструментами картографа',
    },
    nameEn: 'Cartographer’s Tools',
    description: `Используя инструменты картографа, Вы можете составить достоверную карту, чтобы сделать путешествие проще для вас и для тех, кто пойдёт здесь после вас. Это может быть, как крупномасштабная карта, описывающая горные хребты и перевалы, так и схема, показывающая планировку уровня подземелья.

**Компоненты.** Инструменты картографа включают в себя

* [писчее перо](GEAR:${GEAR_INK_PEN}),
* [чернила](GEAR:${GEAR_INK}),
* [пергамент](GEAR:${GEAR_PARCHMENT}),
* циркуль,
* кронциркуль,
* линейку.

**Магия, История, Религия.** Вы можете использовать ваши познания в картах и различных местах, чтобы извлекать более детальную информацию при использовании этих навыков. Например, Вы можете найти скрытое послание на карте, установить, когда была составлена карта, чтобы определить, какие географические особенности изменились с тех пор и так далее.

**Природа.** Ваше знакомство с физической географией облегчает вам поиск ответов на вопросы и разрешение проблем, касающихся окружающей местности.

**Выживание.** Ваше понимание географии облегчает поиск пути к цивилизации, позволяет прогнозировать, в какой местности могут быть найдены деревни или городки, и уменьшает вероятность того, что Вы заблудитесь. Вы изучили так много карт, что общие тенденции, например, как развиваются торговые маршруты или в каких местах обычно строят поселения, вам хорошо знакомы.

**Составление карты.** Путешествуя, Вы можете рисовать карту в дополнение к участию в других действиях.

| Действие                                        | СЛ |
|-------------------------------------------------|----|
| Определить возраст и происхождение карты        | 10 |
| Прикинуть направление и расстояние до ориентира | 15 |
| Распознать что карта поддельная                 | 15 |
| Дорисовать недостающий кусок карты              | 20 |
`,
    cost: 1500,
    weight: 6,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 82,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_CARPENTER_S_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты плотника',
    nameByCase: {
      nominative: 'инструменты плотника',
      genitive: 'инструментов плотника',
      accusative: 'инструменты плотника',
      instrumental: 'инструментами плотника',
    },
    nameEn: 'Carpenter’s Tools',
    description: `Навыки плотничества позволяют персонажу строить деревянные сооружения. Плотник может построить дом, хижину, деревянный шкаф или подобный предмет.

**Компоненты.** Инструменты плотника включают в себя

* пилу,
* [молоток](GEAR:${GEAR_HAMMER}),
* гвозди,
* топор,
* угольник,
* линейку,
* кородёр,
* рубанок,
* стамеску.

**История.** Владение этими инструментами помогает вам определять назначение и происхождение деревянных строений и прочих больших деревянных объектов.

**Расследование.** Вы получаете дополнительные знания при обследовании области в деревянном здании, потому что Вы знаете строительные хитрости, которые могут скрыть что-то в области при обследовании.

**Восприятие.** Вы можете заметить неровности на деревянных стенах и полах, что облегчает поиск ловушек и тайных ходов.

**Скрытность.** Вы можете быстро найти слабое место в деревянном полу, что облегчает вам определение мест, которые сломаются или заскрипят, если на них наступить.

**Укрепление.** За 1 минуту при наличии материалов Вы можете укрепить дверь или окно. СЛ броска на выбивание увеличивается на 5.

**Временное укрытие.** Как часть длинного отдыха Вы можете построить навес или подобное укрытие, чтобы ваша группа была укрыта от дождя или солнечного зноя на время отдыха. Поскольку оно было построено на скорую руку из первой попавшейся древесины, укрытие разваливается через 1к3 дня после постройки.

| Действие                                   | СЛ |
|--------------------------------------------|----|
| Построить простое деревянное сооружение    | 10 |
| Спроектировать сложное деревянное строение | 15 |
| Найти слабое место в деревянной стене      | 15 |
| Снять дверь с петель                       | 20 |
`,
    cost: 800,
    weight: 6,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 82,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_COOK_S_UTENSILS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты повара',
    nameByCase: {
      nominative: 'инструменты повара',
      genitive: 'инструментов повара',
      accusative: 'инструменты повара',
      instrumental: 'инструментами повара',
    },
    nameEn: 'Cook’s Utensils',
    description: `Жизнь искателя приключений тяжела. При наличии в компании повара ваша пища будет намного вкуснее, чем привычные галеты и сухофрукты.

**Компоненты.** Инструменты повара включают в себя

* [металлический котёл](GEAR:${GEAR_POT_IRON}),
* ножи,
* вилки,
* ложку для размешивания,
* половник.

**История.** Ваши знания кулинарных методик позволяют вам оценивать структуру социального развития, основываясь на гастрономических обычаях этой культуры.

**Медицина.** Оказывая лечение, Вы можете превратить горькое или кислое лекарство в приятную на вкус стряпню.

**Выживание.** Добывая еду, Вы можете использовать собранные вами ингредиенты, которые другие не смогли бы приготовить.

**Приготовление еды.** Как часть короткого отдыха Вы можете приготовить вкусное блюдо, которое поможет вашим компаньонам восстановить силы. Вы и до пяти существ на ваш выбор восстанавливают по 1 дополнительному хиту за каждую потраченную во время короткого отдыха Кость Хитов, при условии, что у вас есть доступ к вашим инструментам повара и достаточно еды.

| Действие                        | СЛ |
|---------------------------------|----|
| Приготовить обычную пищу        | 10 |
| Повторить блюдо                 | 10 |
| Обнаружить яд или примесь в еде | 15 |
| Приготовить изысканное блюдо    | 15 |
`,
    cost: 100,
    weight: 8,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 83,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_COBBLER_S_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты сапожника',
    nameByCase: {
      nominative: 'инструменты сапожника',
      genitive: 'инструментов сапожника',
      accusative: 'инструменты сапожника',
      instrumental: 'инструментами сапожника',
    },
    nameEn: 'Cobbler’s Tools',
    description: `Несмотря на то, что профессия сапожника может показаться искателю приключений слишком простой, пара хороших сапог будет сопровождать персонажа и в труднопроходимой глуши, и в смертельных подземельях.

**Компоненты.** Инструменты сапожника включают в себя

* [молоток](GEAR:${GEAR_HAMMER}),
* шило,
* нож,
* обувную колодку,
* ножницы,
* запас кожи,
* запас ниток.

**Магия, История.** Ваши знания об обуви помогают вам определить магические свойства зачарованных сапог или узнать историю подобных предметов.

**Расследование.** Обувь скрывает множество секретов. Вы можете узнать, где недавно был тот, чью обувь Вы осматриваете на предмет грязи и поношенности. Ваш опыт в починке обуви облегчает вам определение того, как она была повреждена.

**Уход за обувью.** Как часть длинного отдыха, Вы можете починить обувь ваших компаньонов. В течение следующих 24 часов до 6 существ по вашему выбору, носящих обувь, над которой Вы поработали, могут путешествовать до 10 часов в день, не проходя испытания от истощения.

**Создание потайного отделения.** За 8 часов работы Вы можете добавить потайное отделение в пару обуви. Отделение может содержать в себе предмет до 3 дюймов длинной и 1 дюйм шириной и высотой. Вы совершаете проверку Интеллекта, добавляя ваш бонус мастерства за владение этими инструментами, для определения СЛ проверки Интеллекта (Расследование), необходимой для нахождения отделения.

| Действие                                 | СЛ |
|------------------------------------------|----|
| Определить возраст и происхождение обуви | 10 |
| Найти потайное отделение в каблуке       | 15 |
`,
    cost: 500,
    weight: 5,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 82,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_CAMEL,
    genderId: GENDER_MALE,
    name: 'Верблюд',
    nameByCase: {
      nominative: 'верблюд',
      genitive: 'верблюда',
      accusative: 'верблюда',
      instrumental: 'верблюдом',
    },
    nameEn: 'Camel',
    creatureId: CREATURE_CAMEL,
    description: 'Скорость 50 фт. Грузоподъёмность 480 фунтов.',
    cost: 5000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_CHARIOT,
    genderId: GENDER_FEMALE,
    name: 'Колесница',
    nameByCase: {
      nominative: 'колесница',
      genitive: 'колесницы',
      accusative: 'колесницу',
      instrumental: 'колесницей',
    },
    nameAlt: 'Карета',
    nameEn: 'Chariot',
    cost: 25000,
    weight: 100,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_GROUND,
  },
  {
    id: GEAR_CARRIAGE,
    genderId: GENDER_FEMALE,
    name: 'Карета',
    nameByCase: {
      nominative: 'карета',
      genitive: 'кареты',
      accusative: 'карету',
      instrumental: 'каретой',
    },
    nameAlt: 'Коляска',
    nameEn: 'Carriage',
    cost: 10000,
    weight: 600,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_GROUND,
  },
  {
    id: GEAR_CART,
    genderId: GENDER_FEMALE,
    name: 'Двуколка',
    nameByCase: {
      nominative: 'двуколка',
      genitive: 'двуколки',
      accusative: 'двуколку',
      instrumental: 'двуколкой',
    },
    nameAlt: 'Телега',
    nameEn: 'Cart',
    cost: 1500,
    weight: 200,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_GROUND,
  },
  {
    id: GEAR_CANOE,
    genderId: GENDER_MIDDLE,
    name: 'Каноэ',
    nameByCase: {
      nominative: 'каноэ',
      genitive: 'каноэ',
      accusative: 'каноэ',
      instrumental: 'каноэ',
    },
    nameEn: 'Canoe',
    description: 'Скорость: 2 узла. Мест: 6',
    cost: 5000,
    weight: 100,
    source: {
      id: SOURCE_TOA,
      page: 32,
    },
    category: CAT_TRANSPORT_WATER,
  },
  {
    id: GEAR_CHICKEN,
    genderId: GENDER_FEMALE,
    name: 'Курица',
    nameByCase: {
      nominative: 'курица',
      genitive: 'курицы',
      accusative: 'курицу',
      instrumental: 'курицей',
    },
    nameEn: 'Chicken',
    cost: 2,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_CANVAS,
    genderId: GENDER_MALE,
    name: 'Холст',
    nameByCase: {
      nominative: 'холст',
      genitive: 'холста',
      accusative: 'холст',
      instrumental: 'холстом',
    },
    nameEn: 'Canvas',
    description: '10 фт²',
    cost: 10,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_COPPER,
    genderId: GENDER_FEMALE,
    name: 'Медь',
    nameByCase: {
      nominative: 'медь',
      genitive: 'меди',
      accusative: 'медь',
      instrumental: 'медью',
    },
    nameEn: 'Copper',
    cost: 50,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_COTTON_CLOTH,
    genderId: GENDER_FEMALE,
    name: 'Ткань, хлопчатобумажная',
    nameByCase: {
      nominative: 'хлопчатобумажная ткань',
      genitive: 'хлопчатобумажной ткань',
      accusative: 'хлопчатобумажную ткань',
      instrumental: 'хлопчатобумажной тканью',
    },
    nameEn: 'Cotton Cloth',
    description: '10 фт²',
    cost: 50,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_CINNAMON,
    genderId: GENDER_FEMALE,
    name: 'Корица',
    nameByCase: {
      nominative: 'корица',
      genitive: 'корицы',
      accusative: 'корицу',
      instrumental: 'корицей',
    },
    nameEn: 'Cinnamon',
    cost: 200,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_CLOVES,
    genderId: GENDER_FEMALE,
    name: 'Гвоздика',
    nameByCase: {
      nominative: 'гвоздика',
      genitive: 'гвоздики',
      accusative: 'гвоздику',
      instrumental: 'гвоздикой',
    },
    nameEn: 'Cloves',
    cost: 300,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_COW,
    genderId: GENDER_FEMALE,
    creatureId: CREATURE_COW,
    name: 'Корова',
    nameByCase: {
      nominative: 'корова',
      genitive: 'коровы',
      accusative: 'корову',
      instrumental: 'коровой',
    },
    nameEn: 'Cow',
    cost: 1000,
    source: [
      {
        id: SOURCE_PHB,
        page: 157,
      },
      {
        id: SOURCE_VGTM,
        page: 210,
      },
    ],
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_CHEESE_HUNK,
    genderId: GENDER_MALE,
    name: 'Сыр, кусок',
    nameByCase: {
      nominative: 'кусок сыра',
      genitive: 'куска сыра',
      accusative: 'кусок сыра',
      instrumental: 'куском сыра',
    },
    nameEn: 'Cheese, chunk',
    cost: 10,
    source: {
      id: SOURCE_PHB,
      page: 158,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_CRAWLER_MUCUS,
    genderId: GENDER_FEMALE,
    name: 'Яд, слизь ползающего падальщика',
    nameByCase: {
      nominative: 'слизь ползающего падальщика',
      genitive: 'слизи ползающего падальщика',
      accusative: 'слизь ползающего падальщика',
      instrumental: 'слизью ползающего падальщика',
    },
    nameEn: 'Crawler Mucus',
    description: `Этот яд собирают с мёртвого или недееспособного [ползающего падальщика](CREATURE:${CREATURE_CARRION_CRAWLER}). Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 13, иначе оно станет отравленным на 1 минуту. Будучи отравленным, существо также парализовано. Существо может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    saveThrow: {
      type: PARAM_CON,
      dc: 13,
    },
    cost: 20000,
    poisonType: POISON_TYPE_CONTACT,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
]

module.exports = gearRawList
