const {
  CREATURE_BARBED_DEVIL,
  CREATURE_BEARDED_DEVIL,
} = require('./../../../../../creatureIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_INFERNAL_CALLING} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_INFERNAL_CALLING,
  name: 'Инфернальный зов',
  nameEn: 'Infernal Calling',
  description: `Произнося тёмное заклинание, Вы вызываете дьявола из Девяти Преисподних. Вы выбираете тип дьявола, который должен иметь показатель опасности 6 или ниже, например, [шипастый](CREATURE:${CREATURE_BARBED_DEVIL}) или [бородатый дьявол](CREATURE:${CREATURE_BEARDED_DEVIL}). Дьявол появляется в незанятом пространстве, которое Вы видите в радиусе действия. Дьявол исчезает, когда его хиты опускаются до 0 или когда заклинание заканчивается.

Дьявол недружелюбен к Вам и Вашим спутникам. Совершите проверку инициативы за дьявола, который будет совершать свои собственные ходы. Он находится под контролем Мастера и в каждом своем ходу действует в соответствии со своей природой. Дьявол может атаковать вас, если считает, что может победить, или он попытается искусить вас, чтобы Вы совершили злой поступок в обмен на небольшую услугу. Параметры существа есть у Мастера.

Каждый Ваш ход Вы можете попытаться дать вербальную команду дьяволу (действие не требуется). Он подчиняется команде, если вероятный результат соответствует его желаниям, особенно если результат приведет Вас ко злу. В противном случае Вы должны совершить проверку Харизмы (Обман, Запугивание или Убеждение) против его проверки Мудрости (Проницательность).

Вы совершаете проверку с преимуществом, если произносите истинное имя дьявола. При провале дьявол становится невосприимчивым к Вашим вербальным командам на всё время действия заклинания, хотя он всё равно может выполнять Ваши команды, если захочет. При успехе дьявол будет выполнять Вашу команду, например, — «атакуй моих врагов», «исследуй комнату впереди» или «отнеси это сообщение королеве», — пока он не завершит это дело, после чего вернется к вам, чтобы сообщить о выполнении.

Если Вы теряете концентрацию до того, как заклинание закончится, дьявол не исчезает, если он стал невосприимчив к Вашим вербальным командам. Вместо этого он действует по своему желанию в течение 3к6 минут и только потом исчезает.

Если у Вас есть талисман конкретного дьявола, Вы можете призвать этого дьявола, если он имеет соответствующий показатель опасности плюс 1, и он подчиняется всем Вашим командам без каких-либо проверок Харизмы.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 6 круга или выше, показатель опасности увеличивается на 1 за каждый круг ячейки выше 5.`,
  lvl: 5,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `рубин стоимостью не менее 999 зм`,
  duration: {timeId: TIME_HOUR, count: 1},
  castTime: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  source: {
    id: SOURCE_XGTE,
    page: 153,
  },
}
