const {SOURCE_MOOT} = require('./../../sourceList')
const archonMounts = require('./archonMounts')

module.exports = [
  {
    header: 'Архонты',
    text: `Таинственные завоеватели, известные как архонты, когда-то правили огромными империями. Эти бронированные военачальники считали себя поборниками безжалостного правосудия и правили железным кулаком. Но их владычество в конце концов окончилось. Когда архонты-правители были свергнуты, они рассеялись по окраинам мира, а их владения преобразовались в современные полисы.

Несмотря на то, что эпоха архонтов давно миновала, многие всё же задаются вопросом — могут ли уцелевшие архонты когда-нибудь попытаться восстановить свою империю, или же они действительно смирились с уменьшением своей значимости в мире?`,
    source: {
      id: SOURCE_MOOT,
      page: 213,
    },
  },
  archonMounts,
]
