const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_THE_ANCIENTS} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_ENSNARING_STRIKE,
  SPELL_ICE_STORM,
  SPELL_MISTY_STEP,
  SPELL_MOONBEAM,
  SPELL_PLANT_GROWTH,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_STONESKIN,
  SPELL_TREE_STRIDE,
} = require('./../../../spellIdList')
const {
  FEATURE_AURA_OF_WARDING,
  FEATURE_CHANNEL_DIVINITY_NATURE_S_WRATH,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CHANNEL_DIVINITY_TURN_THE_FAITHLESS,
  FEATURE_ELDER_CHAMPION,
  FEATURE_OATH_OF_THE_ANCIENTS_SPELLS,
  FEATURE_UNDYING_SENTINEL,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_THE_ANCIENTS_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_COMMUNE_WITH_NATURE,
      SPELL_ENSNARING_STRIKE,
      SPELL_ICE_STORM,
      SPELL_MISTY_STEP,
      SPELL_MOONBEAM,
      SPELL_PLANT_GROWTH,
      SPELL_PROTECTION_FROM_ENERGY,
      SPELL_SPEAK_WITH_ANIMALS,
      SPELL_STONESKIN,
      SPELL_TREE_STRIDE,
    ],
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_NATURE_S_WRATH,
    name: `Божественный канал: Гнев природы`,
    nameEn: `Channel Divinity: Nature’s Wrath`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), чтобы призвать первородные силы для опутывания врага.

Вы можете действием опутать призрачными лозами существо, которое Вы можете видеть, и которое находится в пределах 10 футов от Вас. Это существо должно преуспеть в испытании Силы или Ловкости (по своему выбору), иначе оно станет опутанным. Опутанное лозами существо повторяет испытание в конце каждого своего хода. В случае успеха оно освобождается, а лозы исчезают.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_TURN_THE_FAITHLESS,
    name: `Божественный канал: Изгнать неверного`,
    nameEn: `Channel Divinity: Turn the Faithless`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}) для произнесения древних слов, причиняющих боль феям и исчадиям, когда они слышат их.

Вы действием демонстрируете свой священный символ и все феи и исчадия в пределах 30 футов, слышащие Вас, должны совершить испытание Мудрости. Провалив его, существо изгоняется на 1 минуту или пока не получит урон.

Изгнанные существа должны весь свой ход пытаться убежать от Вас так далеко, как только могут, и не могут добровольно переместиться в пространство, находящееся в пределах 30 футов от Вас. Также они не могут совершать реакции. Из всех действий они могут совершать только Рывок и попытки избежать эффекта, не дающего им перемещаться. Если же двигаться некуда, существа могут использовать действие Уклонение. Если истинная форма существа скрыта иллюзией, изменением облика, или другим эффектом, скрывающим её, то она раскрывается, когда существо изгоняется.`,
  },
  {
    id: FEATURE_AURA_OF_WARDING,
    name: `Аура опеки`,
    nameEn: `Aura of Warding`,
    lvl: [7, 18],
    text: `Древняя магия так сильно Вас пропитывает, что образует мистическую защиту. Вы и дружественные существа в пределах 10 футов от Вас получаете сопротивление к урону от заклинаний.

На 18 уровне радиус ауры увеличивается до 30 футов.`,
  },
  {
    id: FEATURE_UNDYING_SENTINEL,
    name: `Бессмертный страж`,
    nameEn: `Undying Sentinel`,
    lvl: 15,
    text: `Если Ваши хиты опускаются до 0, но Вы не были убиты, Вы можете решить, чтобы Вы вместо этого остались с 1 хитом. Использовав это умение, Вы не можете повторно его использовать, пока не окончите длинный отдых.

Кроме того, Вы не получаете недостатков от большого возраста, и не можете быть состарены магией.`,
  },
  {
    id: FEATURE_ELDER_CHAMPION,
    name: `Древний чемпион`,
    nameEn: `Elder Champion`,
    lvl: 20,
    text: `Вы можете принимать форму древней силы природы, выбрав её внешний вид. Например, Ваша кожа может позеленеть или превратиться в кору, а Ваши волосы могут стать листьями или мхом, или у Вас могут вырасти рога или львиная грива.

Вы можете действием подвергнуться этой трансформации. На 1 минуту Вы получаете следующие преимущества:

* В начале каждого своего хода Вы восстанавливаете 10 хитов.
* Каждый раз, когда Вы накладываете заклинание паладина, которое накладывается за 1 действие, Вы можете вместо этого сотворить его бонусным действием.
* Вражеские существа в пределах 10 футов от Вас совершают с помехой испытания от Ваших заклинаний паладина и вариантов Божественного канала.

Использовав эту способность, Вы не сможете использовать её вновь, пока не завершите длинный отдых.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_THE_ANCIENTS,
    source: {
      id: SOURCE_PHB,
      page: 87,
    },
  })
)


