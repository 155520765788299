const {
  SPELL_CHILL_TOUCH,
  SPELL_CONJURE_ANIMALS,
  SPELL_CROWN_OF_MADNESS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISPEL_MAGIC,
  SPELL_DOMINATE_MONSTER,
  SPELL_ELDRITCH_BLAST,
  SPELL_ETHEREALNESS,
  SPELL_EYEBITE,
  SPELL_FAERIE_FIRE,
  SPELL_FEAR,
  SPELL_FLY,
  SPELL_GIANT_INSECT,
  SPELL_HOLD_MONSTER,
  SPELL_INSECT_PLAGUE,
  SPELL_INVISIBILITY,
  SPELL_LEVITATE,
  SPELL_MAGE_HAND,
  SPELL_POISON_SPRAY,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_WEB,
  SPELL_WITCH_BOLT,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_CHANGE_SHAPE,
  ABILITY_FEY_ANCESTRY,
  ABILITY_SPIDER_CLIMB,
  ABILITY_SUNLIGHT_SENSITIVITY,
  ABILITY_WEB_WALKER,
} = require('./../../../../creatureAbilityList')
const {
  SKILL_ARCANA,
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_CAN_TALK_WITH_SPIDERS,
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  drowDescription,
  drowMageNote,
} = require('./../../../../textCommonParts')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  CREATURE_DROW_ARACHNOMANCER,
  CREATURE_GIANT_SPIDER,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_13} = require('./../../../../crList')
const {GEAR_STUDDED_LEATHER_ARMOR} = require('./../../../../gearIdList')
const {GOD_LOLTH} = require('./../../../../godIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Дроу арахномант',
  nameEn: 'Drow Arachnomancer',
  id: CREATURE_DROW_ARACHNOMANCER,
  description: [
    `Заклинатели дроу, ищущие возможности полностью посвятить себя Паучьей Королеве, иногда вступают на тёмный путь арахноманта. Жертвуя душу и тело [Ллос](GOD:${GOD_LOLTH}), они получают невероятную мощь и необычную связь с древними пауками из провалов Демонической паутины, насытившись магией из этого жуткого места.`,
    ...drowDescription,
  ],
  note: drowMageNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 163,
  },
  armor: {
    ac: 18,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 25,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 14,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_NATURE]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 10,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ELVEN,
    LANG_UNDERCOMMON,
    LANG_CAN_TALK_WITH_SPIDERS,
  ],
  cr: CR_13,
  featureList: [
    {
      id: ABILITY_CHANGE_SHAPE,
      comment: `перезарядка после короткого или длинного отдыха`,
      actionType: `Бонусным действием`,
      nextForm: `[Гигантского паука](CREATURE:${CREATURE_GIANT_SPIDER}), оставаясь в этой форме до 1 часа`,
      gearMerge: `сливается с новым обликом.  Он не может активировать, использовать, владеть или иным образом извлекать выгоду из любого своего снаряжения`,
      statChange: `Он может вернуть свою изначальную форму бонусным действием. Все его параметры, кроме размера, остаются одинаковыми во всех формах. Он может говорить и сотворять заклинания, находясь в форме [Гигантского паука](CREATURE:${CREATURE_GIANT_SPIDER})`,
    },
    ABILITY_FEY_ANCESTRY,
    ABILITY_SPIDER_CLIMB,
    ABILITY_SUNLIGHT_SENSITIVITY,
    ABILITY_WEB_WALKER,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DANCING_LIGHTS,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_DARKNESS,
            SPELL_FAERIE_FIRE,
            {
              id: SPELL_LEVITATE,
              comment: 'только на себя',
            },
          ],
        },
      ],
    },
    {
      spellCasterLevel: 16,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_CHILL_TOUCH,
            SPELL_ELDRITCH_BLAST,
            SPELL_MAGE_HAND,
            SPELL_POISON_SPRAY,
          ],
        },
        {
          preText: `1–5 уровни (3 ячейки 5-го уровня)`,
          list: [
            SPELL_CROWN_OF_MADNESS,
            SPELL_DIMENSION_DOOR,
            SPELL_DISPEL_MAGIC,
            SPELL_FEAR,
            SPELL_FLY,
            SPELL_GIANT_INSECT,
            SPELL_HOLD_MONSTER,
            SPELL_INSECT_PLAGUE,
            SPELL_INVISIBILITY,
            SPELL_VAMPIRIC_TOUCH,
            SPELL_WEB,
            SPELL_WITCH_BOLT,
            {
              id: SPELL_CONJURE_ANIMALS,
              comment: 'только пауки',
            },
          ],
        },
        {
          preText: `1/день каждое`,
          list: [
            SPELL_DOMINATE_MONSTER,
            SPELL_ETHEREALNESS,
            SPELL_EYEBITE,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Дроу совершает две атаки _Ядовитым касанием_ или две атаки _Укусом_. Один раз за раунд первая из этих атак, попавшая в цель, наносит дополнительных 26 (4к12) урона ядом по цели.`,
    },
    {
      name: 'Ядовитое касание',
      comment: `только в облике гуманоида`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_POISON,
          diceCount: 8,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
    {
      name: 'Укус',
      comment: `только в облике Гигантского паука`,
      description: `Цель должна пройти испытание Телосложения СЛ 15, получая 26 (4к12) урона ядом при провале, или половину этого урона при успехе. Если урона ядом уменьшает хиты цели до 0, то цель остаётся стабилизированной, но отравленной на 1 час, даже если позже восстановит хиты, и остаётся парализованной, пока отравлена таким образом.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Паутина',
      restore: 5,
      comment: `только в облике Гигантского паука`,
      description: `Обездвиженное существо может действием совершить проверку Силы СЛ 15, разрывая паутину при успехе. Паутину можно атаковать и уничтожить (КД 10; 5 хитов; уязвимость к урону огнём; иммунитет к дробящему урону, а также урону ядом и психической энергией).`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 8,
        range: {
          normal: 30,
          max: 60,
        },
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: `Существо обездвиживается паутиной`,
      },
    },
  ],
}
