const {PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_CLAIRVOYANCE,
  SPELL_DETECT_THOUGHTS,
  SPELL_DISSONANT_WHISPERS,
  SPELL_DOMINATE_BEAST,
  SPELL_DOMINATE_PERSON,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_PHANTASMAL_FORCE,
  SPELL_REMOVE_CURSE,
  SPELL_SENDING,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  SPELL_TELEKINESIS,
} = require('./../../../spellIdList')
const {
  FEATURE_AWAKENED_MIND,
  FEATURE_CREATE_THRALL,
  FEATURE_ENTROPIC_WARD,
  FEATURE_EXPANDED_SPELLS_WARLOCK_THE_GREAT_OLD_ONE,
  FEATURE_THOUGHT_SHIELD,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_WARLOCK_THE_GREAT_OLD_ONE,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    text: `Великий Древний позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_CLAIRVOYANCE,
      SPELL_DETECT_THOUGHTS,
      SPELL_DISSONANT_WHISPERS,
      SPELL_DOMINATE_BEAST,
      SPELL_DOMINATE_PERSON,
      SPELL_EVARD_S_BLACK_TENTACLES,
      SPELL_PHANTASMAL_FORCE,
      SPELL_SENDING,
      SPELL_TASHA_S_HIDEOUS_LAUGHTER,
      SPELL_TELEKINESIS,
    ],
    source: {
      id: SOURCE_PHB,
      page: 110,
    },
  },
  {
    id: FEATURE_AWAKENED_MIND,
    name: `Пробуждённый разум`,
    nameEn: `Awakened Mind`,
    lvl: 1,
    text: `Ваше чуждое знание даёт Вам возможность касаться разума других существ.

Вы можете говорить телепатически любому видимом Вами в пределах 30 футов существу.

Вам не нужно знать общий с существом язык для того, чтобы оно понимало Ваши телепатические высказывания, но существо должно быть в состоянии понимать как минимум один язык.`,
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: FEATURE_ENTROPIC_WARD,
    name: `Энтропическая опека`,
    nameEn: `Entropic Ward`,
    lvl: 6,
    text: `Вы научитесь магически опекать себя от атак и использовать неудачную вражескую атаку на пользу себе.

Если существо совершает бросок атаки по Вам, Вы можете реакцией создать помеху для этого броска. Если атака промахнётся по Вам, Ваш следующий бросок атаки по этому существу совершается с преимуществом, если Вы совершите его до конца своего следующего хода.

Использовав это умение, Вы не можете использовать его снова до окончания короткого или длинного отдыха. `,
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: FEATURE_THOUGHT_SHIELD,
    name: `Ментальный щит`,
    nameEn: `Thought Shield`,
    lvl: 10,
    text: `Ваши мысли не могут быть прочитаны телепатически или иными средствами, если Вы не пожелаете обратного.

Вы также получаете сопротивление к урону психической энергией, и каждый раз, когда существо причиняет Вам урон психической энергией, это существо получает такое же количество урона, что и Вы.`,
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: FEATURE_CREATE_THRALL,
    name: `Создать раба`,
    nameEn: `Create Thrall`,
    lvl: 14,
    text: `Вы получаете способность инфицировать разум гуманоидов чужеродной магией своего покровителя.

Вы можете действием коснуться недееспособного гуманоида. Это существо становится очарованным Вами до тех пора, пока на него не будет сотворено заклинание [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}), либо пока оно не перестанет быть очарованным, либо пока Вы не используете это умение ещё раз.

Вы можете общаться телепатически с очарованным существом, пока Вы оба находитесь на одном плане существования.`,
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE,
  })
)
