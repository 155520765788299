const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    description,
    id,
    name,
    nameEn,
  }
) => ({
  id,
  name,
  nameEn,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  isConsumable: true,
  description: `Этот маленький предмет выглядит как перо.

${description}`,
  genderId: GENDER_MIDDLE,
  siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 189,
  },
})
