const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ABILITY_SUNLIGHT_SENSITIVITY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_FRIGHTENED} = require('./../../../../conditionList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_MEENLOCK} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_FEY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {FEATURE_DIVINE_INTERVENTION} = require('./../../../../featureIdList')
const {LANG_TELEPATHY} = require('./../../../../languageIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_WISH} = require('./../../../../spellIdList')

module.exports = {
  name: 'Минлок',
  nameEn: 'Meenlock',
  id: CREATURE_MEENLOCK,
  description: [
    `Минлоки — извращённые феи, наводящие ужас и стремящиеся уничтожить всё доброе, невинное и красивое. Они живут в основном в лесах, хотя хорошо адаптируются и к городской или подземной среде.`,
    {
      header: 'Воплощение страха',
      text: `Минлоки порождаются страхом. Когда страх одолевает существо в Стране Фей или любом другом месте, где сильно влияние Страны Фей, один и более минлоков могут появиться в тенях или темноте поблизости. Если рождается более одного минлока, также магически формируется и логово. Земля скрипит и стонет, когда узкие, извилистые тоннели появляются в ней. Один из таких вновь сформировавшихся проходов становится единственным входом и выходом из логова.

От минлоков у других существ мурашки идут по телу. Минлоки проецируют сверхъестественную ауру, вселяющую ужас в находящихся рядом существ. Они настолько злые и извращённые, что осязаемое дурное предчувствие одолевает тех, кто входит в логово минлоков. Внутри норы чёрный мох покрывает все поверхности, приглушая звук. Большая центральная пещера служит жилищем минлоков, в котором они пытают пленников.`,
      source: {
        id: SOURCE_VGTM,
        page: 169,
      },
    },
    {
      header: 'Обитатели тьмы',
      text: `Минлоки не выносят яркого света. Они могут сверхъестественным образом чувствовать области тьмы и тени поблизости от себя и таким образом могут телепортироваться из одного затенённого места в другое, что позволяет им подкрадываться к добыче или убегать, если расклад сил не в их пользу.`,
      source: {
        id: SOURCE_VGTM,
        page: 169,
      },
    },
    {
      header: 'Телепатические мучители',
      text: `У минлоков нет других форм общения кроме телепатии. Они могут использовать её, чтобы проецировать страшные галлюцинации в разум добычи. Эти галлюцинации принимают форму ужасного шепота или стремительного движения на границе периферийного зрения.

Днём минлоки сидят в своих тёмных норах. Ночью же они выползают из своих тоннелей, чтобы мучить спящих жертв, особенно тех, кто воплощает всё то, что есть доброго в мире. Минлоки любят парализовать существ своими когтями, утаскивать их в логова, избивать их до бессознательного состояния и телепатически мучить их долгие часы. Гуманоид, не выдерживающий такого психического мучения, трансформируется в злого взрослого минлока.`,
      source: {
        id: SOURCE_VGTM,
        page: 169,
      },
    },
    {
      header: 'Телепатическое мучение',
      text: `До четырёх минлоков могут телепатически мучить одно недееспособное существо, наполняя его разум страшными звуками и ужасными картинами. Участвующие в этом минлоки не могут использовать свою телепатию для каких-либо иных целей в это время, хотя они могут перемещаться и совершать действия нормально. Эти пытки не имеют эффекта на существ, иммунных к испугу. Если существо не обладает иммунитетом и остается недееспособным на 1 час, оно должно пройти испытание Мудрости, получая 10 (3к6) психического урона при провале или половину урона при успехе. СЛ испытания равна 10 + количество минлоков, участвующих в пытке, считая только тех, кто весь час остаётся в поле видимости жертвы и будет дееспособен всё это время. Этот процесс может повторяться. Если от этих повреждений хиты гуманоида снижаются до 0, он мгновенно превращается в минлока в полных хитах и под контролем Мастера. Только заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) или [Божественное вмешательство](PC_CLASS:${PC_CLASS_CLERIC}#${FEATURE_DIVINE_INTERVENTION}) может восстановить превращённое существо в его прежней форме.`,
      source: {
        id: SOURCE_VGTM,
        page: 169,
      },
    },
  ],
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 169,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Аура страха',
      description: `Любой зверь или гуманоид, который начинает свой ход не далее 10 футов от минлока, должен пройти испытание Мудрости СЛ 11 или стать испуганным до начала своего следующего хода.`,
    },
    ABILITY_SUNLIGHT_SENSITIVITY,
    {
      name: 'Теневой телепорт',
      restore: 4,
      description: `Бонусным действием минлок может телепортироваться в свободное пространство не далее 30 футов от себя, если и место откуда и место куда он телепортируется находятся в тусклом свете или темноте. Место назначения не обязательно должно находиться в его поле зрения.`,
    },
  ],
  actionList: [
    {
      name: 'Когти',
      description: `Цель должна пройти испытание Телосложения СЛ 11 или стать парализованной на 1 минуту. Цель может повторять испытание в конце каждого своего хода и окончить эффект при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
}
