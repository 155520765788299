const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_YUAN_TI} = require('./../../creatureTypeIdList')
const {
  yuantiDescriptionList,
  yuantiNoteList,
} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_YUAN_TI,
  nameEn: 'Yuan-ti',
  genderId: GENDER_MALE,
  note: yuantiNoteList,
  description: yuantiDescriptionList,
  name: {
    singular: {
      nominative: 'юань-ти',
      genitive: 'юань-ти',
      dative: 'юань-ти',
      accusative: 'юань-ти',
      instrumental: 'юань-ти',
      prepositional: 'юань-ти',
    },
    plural: {
      nominative: 'юань-ти',
      genitive: 'юань-ти',
      dative: 'юань-ти',
      accusative: 'юань-ти',
      instrumental: 'юань-ти',
      prepositional: 'юань-ти',
    },
  },
}
