const { creatureAbilityCollection } = require('./../constants/creatureAbilityList')

const extendCreaturePropList = list => list.map(
  feature => {
    const isObjFeature = typeof feature !== 'string'
    const featureId = isObjFeature
      ? feature.id
      : feature
    const ability = creatureAbilityCollection[featureId]

    if (ability) {
      const description = ability.description || ''
      const updatedAbility = {
        ...ability,
        description: (
          isObjFeature
            ? Object
              .entries(feature)
              .reduce(
                (text, [key, value = '']) => text.replace(
                  new RegExp(`▲${key}▲`, 'g'),
                  value
                ),
                description,
              )
            : description
        )
          .replace(
            new RegExp(`▲([^▲]+)▲`, 'g'),
            ''
          ),
      }

      return isObjFeature
        ? {
          ...updatedAbility,
          ...feature,
        }
        : updatedAbility
    }

    return isObjFeature
      ? {
        ...feature,
        description: feature.description || '',
      }
      : feature
  },
)

module.exports = extendCreaturePropList
