const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_DAGGER,
  GEAR_SCIMITAR,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  SKILL_ATHLETICS,
  SKILL_DECEPTION,
} = require('./../../../../skillList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_PARRY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NOT_LAWFUL} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_BANDIT_CAPTAIN} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Капитан разбойников',
  nameEn: 'Bandit Captain',
  id: CREATURE_BANDIT_CAPTAIN,
  description: `Требуется сильный характер, хитрость и язык без костей, чтобы держать банду разбойников в узде. Капитан разбойников обладает этими качествами сполна.

Капитан пиратов руководит не просто толпой эгоистичных злодеев, но целым кораблём. Для того чтобы держать экипаж в узде, капитан должен применять и поощрения и наказания.

Больше сокровищ капитаны разбойников и капитаны пиратов ценят только дурную славу. С пленником, который потешит эго или тщеславие капитана, скорее всего, обращаться будут лучше, чем с тем, который этого не делает, или ничего не знает о репутации капитана.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_NOT_LAWFUL,
  source: {
    id: SOURCE_MM,
    page: 346,
  },
  armor: {
    ac: 15,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 14,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_2,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три рукопашные атаки: две _Скимитаром_, и одну _Кинжалом_. Вместо этого ★СУЩЕСТВО★ может совершить две дальнобойные атаки _Кинжалами_.`,
    },
    {
      gearId: GEAR_SCIMITAR,
    },
    {
      gearId: GEAR_DAGGER,
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 2,
    },
  ],
  genderId: GENDER_MALE,
}
