const {
  SPELL_ANIMAL_MESSENGER,
  SPELL_BARKSKIN,
  SPELL_DRUIDCRAFT,
  SPELL_ENTANGLE,
  SPELL_LONGSTRIDER,
  SPELL_PRODUCE_FLAME,
  SPELL_SHILLELAGH,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_THUNDERWAVE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_MEDICINE,
  SKILL_NATURE,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  LANG_ANY,
  LANG_DRUIDIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_DRUID} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PC_CLASS_DRUID} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Друид',
  nameEn: 'Druid',
  id: CREATURE_DRUID,
  description: `Друиды живут в лесах и других уединённых местах в дикой местности, где занимаются защитой природы от чудовищ и вторжения цивилизации. Некоторые из них являются шаманами племени, лечащими больных, молящимися духам животных и дающими духовное руководство.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 345,
  },
  armor: [
    11,
    {
      ac: 16,
      comment: `с [Дубовой корой](SPELL:${SPELL_BARKSKIN})`,
    },
  ],
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_MEDICINE]: PROF_NORMAL,
    [SKILL_NATURE]: PROF_NORMAL,
  },
  languageList: [
    LANG_DRUIDIC,
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_2,
  spellCast: {
    spellCasterLevel: 4,
    spellCasterClass: PC_CLASS_DRUID,
    spellIdList: [
      SPELL_ANIMAL_MESSENGER,
      SPELL_BARKSKIN,
      SPELL_DRUIDCRAFT,
      SPELL_ENTANGLE,
      SPELL_LONGSTRIDER,
      SPELL_PRODUCE_FLAME,
      SPELL_SHILLELAGH,
      SPELL_SPEAK_WITH_ANIMALS,
      SPELL_THUNDERWAVE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
    ],
  },
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
    {
      name: 'Боевой посох',
      // gearId: GEAR_QUARTERSTAFF, // TODO: Найти способ показывать оружие с изменёнными костями
      comment: `с [Дубинкой](SPELL:${SPELL_SHILLELAGH})`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
