const {
  SPELL_BANE,
  SPELL_BANISHMENT,
  SPELL_BESTOW_CURSE,
  SPELL_BLADE_BARRIER,
  SPELL_CLAIRVOYANCE,
  SPELL_COMMAND,
  SPELL_CONTAGION,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_DEATH_WARD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINE_WORD,
  SPELL_FAERIE_FIRE,
  SPELL_FLAME_STRIKE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GATE,
  SPELL_GEAS,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_GUIDANCE,
  SPELL_GUIDING_BOLT,
  SPELL_HARM,
  SPELL_HOLD_PERSON,
  SPELL_HOLY_AURA,
  SPELL_LEVITATE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MENDING,
  SPELL_PLANE_SHIFT,
  SPELL_RESISTANCE,
  SPELL_SACRED_FLAME,
  SPELL_SILENCE,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_SUGGESTION,
  SPELL_THAUMATURGY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_DROW_MATRON_MOTHER,
  CREATURE_RETRIEVER,
  CREATURE_YOCHLOL,
} = require('./../../../../creatureIdList')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  drowDescription,
  drowNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_20} = require('./../../../../crList')
const {
  GEAR_HALF_PLATE,
  GEAR_QUARTERSTAFF,
} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {GOD_LOLTH} = require('./../../../../godIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дроу верховная мать',
  nameEn: 'Drow Matron Mother',
  id: CREATURE_DROW_MATRON_MOTHER,
  description: [
    `Во главе каждого благородного дома дроу стоит верховная мать — влиятельная жрица [Ллос](GOD:${GOD_LOLTH}), которой поручено исполнять волю богини, а также продвигать интересы семьи. Верховные матери являются олицетворением интриг и предательств, связанных с Королевой Пауков. Каждая из них стоит в центре обширной паутине заговоров, где демоны, дроу, пауки и рабы расположены между ними и их врагами. Хоть и верховные матери повелевают великой силой, эта сила зависит от обеспечения благосклонности Паучьей Королевы, а тёмная богиня может порой, забрать то, что когда-то даровала. Блок характеристик верховной матери дроу представляет её на пике своей силы.`,
    ...drowDescription,
  ],
  note: [
    {
      text: `Верховная мать — довольно чуждый титул для безжалостного тирана, но скорее всего, зная дроу и их тёмную богиню, ничему не удивишься.`,
      author: `Архимаг Морденкайнен`,
    },
    drowNote,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 165,
  },
  armor: {
    ac: 17,
    gearId: GEAR_HALF_PLATE,
  },
  hp: {
    diceCount: 35,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 16,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 21,
    [PARAM_CHA]: 22,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ELVEN,
    LANG_UNDERCOMMON,
  ],
  cr: CR_20,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    {
      name: 'Изменчивая благосклонность Ллос',
      description: `Бонусным действием верховная мать посылает благословение Паучьей Королевы на одного союзника в пределах 30 фт. от себя. Союзник получает 7 (2к6) урона психической энергией, но также получает Преимущество на следующий бросок атаки, который совершит до конца своего следующего хода.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DANCING_LIGHTS,
            SPELL_DETECT_MAGIC,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_CLAIRVOYANCE,
            SPELL_DARKNESS,
            SPELL_DETECT_THOUGHTS,
            SPELL_DISPEL_MAGIC,
            SPELL_FAERIE_FIRE,
            SPELL_SUGGESTION,
            {
              id: SPELL_LEVITATE,
              comment: 'только на себя',
            },
          ],
        },
      ],
    },
    {
      spellCasterLevel: 20,
      spellCasterClass: PC_CLASS_CLERIC,
      spellIdList: [
        SPELL_BANE,
        SPELL_BANISHMENT,
        SPELL_BESTOW_CURSE,
        SPELL_BLADE_BARRIER,
        SPELL_CLAIRVOYANCE,
        SPELL_COMMAND,
        SPELL_CONTAGION,
        SPELL_CURE_WOUNDS,
        SPELL_DEATH_WARD,
        SPELL_DISPEL_MAGIC,
        SPELL_DIVINE_WORD,
        SPELL_FLAME_STRIKE,
        SPELL_FREEDOM_OF_MOVEMENT,
        SPELL_GATE,
        SPELL_GEAS,
        SPELL_GUARDIAN_OF_FAITH,
        SPELL_GUIDANCE,
        SPELL_GUIDING_BOLT,
        SPELL_HARM,
        SPELL_HOLD_PERSON,
        SPELL_HOLY_AURA,
        SPELL_MASS_CURE_WOUNDS,
        SPELL_MENDING,
        SPELL_PLANE_SHIFT,
        SPELL_RESISTANCE,
        SPELL_SACRED_FLAME,
        SPELL_SILENCE,
        SPELL_SPIRIT_GUARDIANS,
        SPELL_SPIRITUAL_WEAPON,
        SPELL_THAUMATURGY,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        3,
        2,
        2,
        1,
        1,
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Верховная мать совершает две атаки _Демоническим посохом_, или три атаки _Жезлом щупалец_.`,
    },
    {
      name: 'Демонический посох',
      gearId: GEAR_QUARTERSTAFF,
      description: `Цель должна пройти испытание Мудрости СЛ 19, иначе станет испуганной дроу на 1 минуту. Испуганная цель может повторять испытание в конце каждого своего хода, завершая эффект в случае успеха.`,
      attack: {
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 4,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
    {
      name: 'Жезл из щупалец',
      description: `Если все три щупальца поразили цель во время одного хода, она должна пройти испытание Телосложения СЛ 15, иначе пострадает от следующих эффектов на протяжении 1 минуты:

* её скорость сократится вдвое,
* она получит помеху на испытания Ловкости,
* она не сможет использовать реакции,
* на каждом её ходу она сможет совершать либо Действие, либо Бонусное действие, но не оба их сразу.

В конце каждого хода цели она может повторить испытание, завершая эффект при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
    {
      name: 'Призыв слуги',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Дроу магически призывает [Ловчего](CREATURE:${CREATURE_RETRIEVER}) или [Йоклол](CREATURE:${CREATURE_YOCHLOL}). Призванное существо появляется в незанятом пространстве в пределах 60 фт от призывателя, действует как союзник призывателя и не может самостоятельно призывать прочих демонов. Оно существует 10 минут, или пока не погибнет, или же пока призыватель не отпустит его Действием.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Демонический посох',
      description: `Дроу совершает атаку _Демоническим посохом_.`,
    },
    {
      name: 'Подчинить демона',
      cost: 2,
      description: `Союзный демон в пределах 30 фт. от дроу использует Реакцию для совершения одной атаки против цели по выбору дроу, которую она может видеть.`,
    },
    {
      name: 'Наложение заклинания',
      comment: 'стоит 1–3 действия',
      description: `Дроу тратит ячейку заклинания 1-го, 2-го или 3-го уровня и сотворяетподготовленное заклинание. За каждый уровень потраченной ячейки расходуется одно легендарное действие.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
