const PROGRESS_TABLE_ID = require('./../../PROGRESS_TABLE_ID')
const {halfCasterSpellCircleList} = require('./../../spellCircleByCasterType')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
  SOURCE_ERRATA_PHB,
} = require('./../../sourceList')
const {spellIdCollectionByPcClassId} = require('./../../spellIdPcClassIdBounds')

const {
  abilityScoreImprovementTemplate,
  extraAttackTemplate,
} = require('./../featureCommonRawList')

const {
  PC_CLASS_CLERIC,
  PC_CLASS_PALADIN,
} = require('./../../pcClassIdList')

const {
  SPELL_CURE_WOUNDS,
  SPELL_HALLOW,
} = require('./../../spellIdList')

const {
  FIGHTING_STYLE_BLESSED_WARRIOR,
  FIGHTING_STYLE_BLIND_FIGHTING,
  FIGHTING_STYLE_DEFENSE,
  FIGHTING_STYLE_DUELING,
  FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
  FIGHTING_STYLE_INTERCEPTION,
  FIGHTING_STYLE_PROTECTION,
} = require('./../../fightingStyleIdList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_PALADIN,
  FEATURE_AURA_OF_COURAGE,
  FEATURE_AURA_OF_PROTECTION,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CLEANSING_TOUCH,
  FEATURE_DIVINE_HEALTH,
  FEATURE_DIVINE_SENSE,
  FEATURE_DIVINE_SMITE,
  FEATURE_EXTRA_ATTACK_PALADIN,
  FEATURE_FIGHTING_STYLE_PALADIN,
  FEATURE_HARNESS_DIVINE_POWER_PALADIN,
  FEATURE_IMPROVED_DIVINE_SMITE,
  FEATURE_LAY_ON_HANDS,
  FEATURE_MARTIAL_VERSATILITY_PALADIN,
  FEATURE_OATH_SPELLS,
  FEATURE_PREPARING_SPELLS_PALADIN,
  FEATURE_SACRED_OATH,
  FEATURE_SPELLCASTING_ABILITY_PALADIN,
  FEATURE_SPELLCASTING_FOCUS_PALADIN,
  FEATURE_SPELLCASTING_PALADIN,
} = require('./../../featureIdList')

const {fightingStyleFeatureTemplate} = require('./../featureCommonRawList')

module.exports = [
  {
    id: FEATURE_DIVINE_SENSE,
    name: `Божественное чувство`,
    nameEn: `Divine Sense`,
    lvl: 1,
    text: `Присутствие сильного зла воспринимается Вашими чувствами как неприятный запах, а могущественное добро звучит как небесная музыка в Ваших ушах.

Вы можете действием открыть своё сознание для обнаружения таких сил. Вы до конца своего следующего хода знаете местоположение всех исчадий, небожителей и нежити в пределах 60 футов, не имеющих полного укрытия. Вы знаете тип (исчадие, небожитель, нежить) любого существа, чьё присутствие Вы чувствуете, но не можете определить, кто это конкретно (например, вампир Граф Страд фон Зарович). В этом же радиусе Вы также обнаруживаете присутствие мест и предметов, которые были освящены или осквернены, например, заклинанием [Святилище](SPELL:${SPELL_HALLOW}).

Вы можете использовать это умение количество раз, равное 1 + модификатор Харизмы. Когда Вы заканчиваете длинный отдых, Вы восстанавливаете все потраченные использования.`,
    source: {
      id: SOURCE_PHB,
      page: 85,
    },
  },
  {
    id: FEATURE_LAY_ON_HANDS,
    name: `Наложение рук`,
    nameEn: `Lay on Hands`,
    lvl: 1,
    text: `Ваше благословенное касание может лечить раны.

У Вас есть запас целительной силы, который восстанавливается после длинного отдыха. При помощи этого запаса Вы можете восстанавливать количество хитов, равное уровню паладина, умноженному на 5.

Вы можете действием коснуться существа и, зачерпнув силу из запаса, восстановить количество хитов этого существа на любое число, вплоть до максимума, оставшегося в Вашем запасе.

В качестве альтернативы, Вы можете потрать 5 хитов из своего запаса хитов для излечения цели от одной болезни или одного действующего на неё яда. Вы можете устранить несколько эффектов болезни и ядов одним использованием _Наложения рук_, тратя хиты отдельно для каждого эффекта.

Это умение не оказывает никакого эффекта на нежить и конструктов.`,
    source: {
      id: SOURCE_PHB,
      page: 85,
    },
  },
  {
    ...fightingStyleFeatureTemplate,
    id: FEATURE_FIGHTING_STYLE_PALADIN,
    lvl: 2,
    fightingStyleIdList: [
      FIGHTING_STYLE_BLESSED_WARRIOR,
      FIGHTING_STYLE_BLIND_FIGHTING,
      FIGHTING_STYLE_DEFENSE,
      FIGHTING_STYLE_DUELING,
      FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
      FIGHTING_STYLE_INTERCEPTION,
      FIGHTING_STYLE_PROTECTION,
    ],
    source: {
      id: SOURCE_PHB,
      page: 85,
    },
  },
  {
    id: FEATURE_SPELLCASTING_PALADIN,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 2,
    text: `Вы узнаёте, как черпать божественную магию посредством медитации и молитв, чтобы творить заклятья подобно [жрецу](PC_CLASS:${PC_CLASS_CLERIC}).`,
    source: {
      id: SOURCE_PHB,
      page: 85,
    },
  },
  {
    id: FEATURE_PREPARING_SPELLS_PALADIN,
    name: `Подготовка и сотворение заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 2,
    text: `Таблица «[Паладин](PC_CLASS:${PC_CLASS_PALADIN}#${PROGRESS_TABLE_ID})» демонстрирует, какое количество ячеек для сотворения заклинаний у Вас есть. Для сотворения одного из этих заклинаний Вы должны потратить ячейку заклинаний того же круга или выше, что и само заклинание. Вы восстановите все потраченные ячейки, когда завершите длинный отдых.

Вы подготавливаете список заклинаний паладина, доступных для сотворения. При этом Вы выбираете число заклинаний паладина из списка заклинаний паладина, равное модификатору Харизмы + половина уровня паладина (минимум одно заклинание). Круг заклинаний не должен превышать круг самой высокой имеющейся у Вас ячейки заклинаний.

Например, если Вы паладин 5 уровня, то у Вас есть четыре ячейки заклинаний 1 круга и две ячейки 2 круга. При Харизме 14 Ваш список подготовленных заклинаний может включать в себя четыре заклинания 1 или 2 круга, в любой комбинации. Если Вы подготовили заклинание 1 круга [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}), то можете сотворить его, используя ячейку 1 круга или ячейку 2 круга. Сотворение заклинания не удаляет его из списка подготовленных заклинаний.

Вы можете изменить список подготовленных заклинаний, когда завершаете длинный отдых. Подготовка нового списка заклинаний паладина требует времени, проведённого в молитвах и медитации: не менее 1 минуты за круг заклинания для каждого заклинания в Вашем списке.`,
    spellCircleLvl: halfCasterSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_PALADIN],
    source: {
      id: SOURCE_PHB,
      page: 85,
    },
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_PALADIN,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 2,
    text: `Заклинания паладина творятся с помощью Харизмы, так как их мощь проистекает из силы Ваших убеждений.

Вы используете Харизму в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете модификатор Харизмы при определении СЛ испытаний от Ваших заклинаний паладина, и при броске атаки заклинаниями. 

**СЛ испытания = 8 + бонус мастерства + модификатор Харизмы**

**Модификатор броска атаки = бонус мастерства + модификатор Харизмы**`,
    source: {
      id: SOURCE_PHB,
      page: 85,
    },
  },
  {
    id: FEATURE_SPELLCASTING_FOCUS_PALADIN,
    name: `Фокусировка заклинания`,
    nameEn: `Spellcasting Focus`,
    lvl: 2,
    text: `Вы можете использовать священный символ в качестве заклинательной фокусировки для заклинаний паладина.`,
    source: {
      id: SOURCE_PHB,
      page: 85,
    },
  },
  {
    id: FEATURE_DIVINE_SMITE,
    name: `Божественная кара`,
    nameEn: `Divine Smite`,
    lvl: 2,
    text: `Если Вы попадаете по существу атакой рукопашным оружием, Вы можете потратить одну ячейку заклинания любого своего класса для причинения цели урона излучением, который добавится к урону от оружия.

Дополнительный урон равен 2к8 за ячейку 1 круга, плюс 1к8 за каждый круг ячейки выше первого, до максимума 5к8. Урон увеличивается на 1к8, до максимума 6к8, если цель — нежить или исчадие.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 86,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Разрешена трата ячеек любых классов. Явно указан верхний предел урона в 6к8 для нежити и исчадий',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_DIVINE_HEALTH,
    name: `Божественное здоровье`,
    nameEn: `Divine Health`,
    lvl: 3,
    text: `Божественная магия, текущая через Вас, даёт Вам иммунитет к болезням.`,
    source: {
      id: SOURCE_PHB,
      page: 86,
    },
  },
  {
    id: FEATURE_SACRED_OATH,
    name: `Священная клятва`,
    nameEn: `Sacred Oath`,
    lvl: [3, 7, 15, 20],
    text: `Вы даёте клятву, навсегда связывающую Вас, как паладина. До этого момента Вы были в подготовительной стадии, следуя по пути, но ещё не дав присягу.

Ваш выбор предоставляет вам умения на 3, 7, 15 и 20 уровнях. Эти умения включают в себя заклинания клятвы и умения [Божественного канала](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}).`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 86,
    },
  },
  {
    id: FEATURE_OATH_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    text: `Каждая клятва имеет список связанных с ней заклинаний. Вы получаете доступ к этим заклинаниям на уровнях, указанных в описании клятвы. После того как Вы получаете доступ к заклинанию клятвы, оно всегда у Вас подготовлено. Заклинания клятвы не учитывается в количестве заклинаний, которые можно подготовить каждый день.

Если Вы получаете заклинание клятвы, которое не входит в список заклинаний паладина, это заклинание всё равно считается для Вас заклинанием паладина. `,
    source: {
      id: SOURCE_PHB,
      page: 86,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_PALADIN,
    name: `Божественный канал`,
    nameEn: `Channel Divinity`,
    lvl: 3,
    text: `Ваша клятва позволяет направлять божественную энергию, чтобы подпитывать магические эффекты. Каждый вариант [Божественного канала](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), предоставленный клятвой, объясняет, как его использовать.

Когда Вы используете [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), то выбираете, какой эффект создать. Затем Вы должны окончить короткий или длинный отдых, чтобы использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}) снова.

Некоторые эффекты [Божественного канала](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}) требуют пройти испытание. Когда Вы используете эффекты такого рода, СЛ равна [СЛ испытания от Ваших заклинаний паладина](FEATURE:${FEATURE_SPELLCASTING_PALADIN}).`,
    source: {
      id: SOURCE_PHB,
      page: 86,
    },
  },
  {
    id: FEATURE_HARNESS_DIVINE_POWER_PALADIN,
    name: `Усмирение божественной мощи`,
    nameEn: `Harness Divine Power`,
    lvl: [3, 7, 15],
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}) для подпитки своих заклинаний.

Вы бонусным действием касаетесь своего священного символа, произносите молитву и восстанавливаете одну израсходованную ячейку заклинаний, круг которой не превышает половину Вашего бонуса мастерства (округляя вверх).

Количество раз, которое Вы можете использовать эту способность, зависит от уровня в этом классе: один раз на 3 уровне; дважды на 7 уровне; и трижды на 15 уровне.

Вы восстанавливаете все израсходованные использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 40,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_PALADIN,
    source: {
      id: SOURCE_PHB,
      page: 86,
    },
  },
  {
    id: FEATURE_MARTIAL_VERSATILITY_PALADIN,
    name: `Боевая гибкость`,
    nameEn: `Martial Versatility`,
    lvl: abilityScoreImprovementTemplate.lvl,
    text: `Всякий раз, достигая в этом классе нового уровня, на котором Вы получаете способность [Увеличения характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_PALADIN}), Вы можете заменить один известный Вам [боевой стиль](FEATURE:${FEATURE_FIGHTING_STYLE_PALADIN}) на другой, доступный паладинам.

Это демонстрирует смещение фокуса Ваших боевых тренировок.`,
    source: {
      id: SOURCE_TCoE,
      page: 59,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_PALADIN,
    source: {
      id: SOURCE_PHB,
      page: 86,
    },
  },
  {
    id: FEATURE_AURA_OF_PROTECTION,
    name: `Аура защиты`,
    nameEn: `Aura of Protection`,
    lvl: [6, 18],
    text: `Если Вы или дружественное существо в пределах 10 футов от Вас должны пройти испытание, это существо получает бонус к испытанию, равный модификатору Вашей Харизмы (минимальный бонус +1). Вы должны находиться в сознании, чтобы предоставлять этот бонус.

На 18 уровне дистанция этой ауры увеличивается до 30 футов.`,
    source: {
      id: SOURCE_PHB,
      page: 86,
    },
  },
  {
    id: FEATURE_AURA_OF_COURAGE,
    name: `Аура отваги`,
    nameEn: `Aura of Courage`,
    lvl: [10, 18],
    text: `Вы и дружественные существа в пределах 10 футов от Вас не можете быть испуганы, пока Вы находитесь в сознании.

На 18 уровне дистанция этой ауры увеличивается до 30 футов.`,
    source: {
      id: SOURCE_PHB,
      page: 86,
    },
  },
  {
    id: FEATURE_IMPROVED_DIVINE_SMITE,
    name: `Улучшенная божественная кара`,
    nameEn: `Improved Divine Smite`,
    lvl: 11,
    text: `Вы проникаетесь праведной мощью, что даёт всем Вашим атакам рукопашным оружием божественную силу.

Каждый раз, когда Вы попадаете по существу рукопашным оружием, это существо получает дополнительный урон излучением 1к8.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 86,
      },
      {
        id: SOURCE_ERRATA_PHB,
        page: 1,
        text: 'Убрано последнее предложение: «Если Вы используете Божественную кару, Вы добавляете этот урон к дополнительному урону от Божественной кары.»',
      },
    ],
  },
  {
    id: FEATURE_CLEANSING_TOUCH,
    name: `Очищающее касание`,
    nameEn: `Cleansing Touch`,
    lvl: 14,
    text: `Вы можете действием окончить действие заклинания на себе или на одном согласном существе, которого Вы касаетесь.

Вы можете использовать это умение количество раз, равное Вашему модификатору Харизмы (минимум 1). Вы восстанавливаете возможность использования после длинного отдыха.`,
    source: {
      id: SOURCE_PHB,
      page: 86,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_PALADIN,
  })
)
