const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_EVERSMOKING_BOTTLE,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_EVERSMOKING_BOTTLE,
  name: `Вечнодымящаяся бутылка`,
  nameEn: `Eversmoking Bottle`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Из этой закупоренной свинцовой пробкой латунной бутылки, весящей 1 фунт, постоянно сочится тонкая струйка дыма. Если Вы действием вынете пробку, из бутылки вырвется облако густого дыма с радиусом 60 футов. Пространство в облаке считается сильно заслонённой местностью. За каждую минуту, пока бутылка стоит открытой и находится внутри облака, его радиус увеличивается на 10 футов, пока не достигнет максимального радиуса 120 футов.

Облако остаётся, пока бутылка открыта. Для закрывания бутылки нужно действием произнести её командное слово. Когда бутылку закроют, дым рассеется за 10 минут. Умеренный ветер (от 11 до 20 миль в час) рассеивает дым за 1 минуту, а сильный ветер (21 миля в час и больше) сделает это за 1 раунд. `,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 154,
  },
}
