const {
  CREATURE_REINDEER,
  CREATURE_ELK,
} = require('./../../../../creatureIdList')
const {SOURCE_IDRotF} = require('./../../../../sourceList')

module.exports = {
  name: 'Северный олень',
  nameEn: 'Reindeer',
  id: CREATURE_REINDEER,
  parentId: CREATURE_ELK,
  source: {
    id: SOURCE_IDRotF,
    page: 290,
  },
}
