const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_TROGLODYTE,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_SUNLIGHT_SENSITIVITY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_TROGLODYTE} = require('./../../../../creatureIdList')
const {GEAR_LONGSWORD} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_TROGLODYTE} = require('./../../../../languageIdList')
const {laogzedDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Троглодит',
  nameEn: 'Troglodyte',
  id: CREATURE_TROGLODYTE,
  description: [
    {
      header: 'Троглодиты',
      text: `Дикие и первобытные троглодиты обитают в верхних частях Подземья, живя в постоянном состоянии войны с соседями и друг с другом. Они помечают границы своей территории потрескавшимися костями и черепами или пиктограммами, нарисованными кровью или помётом.

Возможно, самые отвратительные из всех гуманоидов, троглодиты едят всё, что могут проглотить. Они живут в грязи. Стены их пещерных жилищ покрыты глубоко въевшимися нечистотами, маслянистыми выделениями и остатками их омерзительных трапез.`,
      source: {
        id: SOURCE_MM,
        page: 285,
      },
    },
    {
      header: 'Скудоумные громилы',
      text: `У троглодитов примитивная общинная культура, практически полностью сфокусированная на добыче пропитания. Недостаточно сообразительные, чтобы планировать дальше, чем на несколько дней вперёд, троглодиты для выживания полагаются на постоянные грабежи и охоту. Они получают садистское удовольствие от охоты на более слабых разумных существ, и не выказывают жалости к тем, кого они захватывают, утаскивая в свои логова, чтобы сожрать. Самые большие и сильные троглодиты становятся вожаками в охоте и лидерами своих племён. Однако при малейшем проявлении слабости или неуверенности другие троглодиты набрасываются на них и сжирают.

Троглодиты мало что изготавливают, а строят и того меньше, получая все своё имущество от жертв. Они осознают ценность металлического оружия и брони, и дерутся между собой за право обладания ими. Племя троглодитов может сильно пострадать в результате междоусобной драки за один-единственный [длинный меч](GEAR:${GEAR_LONGSWORD}).`,
      source: {
        id: SOURCE_MM,
        page: 285,
      },
    },
    laogzedDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_TROGLODYTE,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 285,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 14,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_TROGLODYTE,
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Кожа хамелеона',
      description: `★СУЩЕСТВО★ совершает с преимуществом проверки Ловкости (Скрытность), совершённые чтобы спрятаться.`,
    },
    {
      name: 'Вонь',
      description: `Все существа кроме троглодитов, начинающие ход в пределах 5 футов от ★СУЩЕСТВО★, должны пройти испытание Телосложения СЛ 12, иначе станут отравленными до начала своего следующего хода. При успешном испытании существо получает иммунитет к вони всех троглодитов на 1 час.`,
    },
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
