const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_AURAN,
  LANG_TERRAN,
} = require('./../../../../languageIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_MEPHIT,
} = require('./../../../../creatureTypeIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_DUST_MEPHIT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {mephitDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPELL_SLEEP} = require('./../../../../spellIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Пылевой мефит',
  nameEn: 'Dust Mephit',
  description: [
    `Состоящие из земли и воздуха пылевые мефиты любят обитать в катакомбах и считают смерть ужасно захватывающей.`,
    mephitDescription,
  ],
  id: CREATURE_DUST_MEPHIT,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_MEPHIT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 206,
  },
  armor: 12,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 5,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AURAN,
    LANG_TERRAN,
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Предсмертная вспышка',
      description: `Когда ★СУЩЕСТВО★ умирает, ★он★ разлетается во все стороны пылью. Все существа в пределах 5 футов от него должны пройти испытание Телосложения СЛ 10, иначе станут ослеплёнными на 1 минуту. Ослеплённое существо может повторять испытание в каждом своём ходу, оканчивая эффект на себе при успехе.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_SLEEP,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Ослепляющее дыхание',
      restore: 6,
      description: `★СУЩЕСТВО★ выдыхает ослепляющую пыль 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 10, иначе станут ослеплёнными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
