const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_13,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_BEHOLDER_KEEN,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_DEEP_SPEECH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_GREATER_RESTORATION,
  SPELL_ANTIMAGIC_FIELD,
} = require('./../../../../spellIdList')
const {
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  PROF_DOUBLE,
} = require('./../../../../proficiencyList')
const {
  CREATURE_BEHOLDER,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Злобоглаз',
  nameAlt: 'Бехолдер',
  nameEn: 'Beholder',
  id: CREATURE_BEHOLDER,
  description: [
    {
      header: `Злобоглазы`,
      text: `Одного взгляда на злобоглаза достаточно, чтобы понять всю его отвратительную и чужеродную природу. Эти агрессивные, ненавидящие всех и жадные аберрации считают всех остальных существ низшими, и либо играют ими, либо уничтожают их.

Шаровидное тело злобоглаза все время левитирует, его большой выпуклый глаз расположен над широкой пастью, полной острых зубов, глаза поменьше располагаются на стебельках, создавая эффект короны. Они всё время изгибаются и крутятся, держа противников в поле зрения. Когда злобоглаз спит, он закрывает центральный глаз, но оставляет мелкие глаза открытыми, готовыми послать ему сигнал опасности.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
    {
      header: `Ксенофобы изоляционисты`,
      text: `Враги повсюду, по крайне мере, в это верит каждый злобоглаз. Они убеждены, что другие существа, мелкие, грубые и мерзкие, завидуют их великолепию и магическим силам. Злобоглазы всегда подозревают других в составлении заговоров против них, даже когда вокруг никого нет.

Презрение, которое злобоглазы испытывают по отношению к другим существам, не исключает презрения к себе подобным. Каждый злобоглаз считает, что его форма идеальная, а любое отклонение от неё — это недостаток расовой чистоты. Злобоглазы сильно отличаются друг от друга, поэтому конфликт между ними неизбежен. Некоторые злобоглазы защищены перекрывающимися хитиновыми пластинами. У других шкуры гладкие. У кого-то стебельки с глазами извиваются как щупальца, у других они похожи на глаза раков. Даже незначительные различия в окрасе шкуры могут сделать из двух злобоглазов врагов на всю жизнь.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
    {
      header: `Бехолдеры`,
      text: `Агрессивные и жадные аберрации, считающие, что их тела идеальны, и презирающие всех других существ — и «низших», и даже других бехолдеров.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Око-тиран`,
      text: `Некоторые злобоглазы превращают свои ксенофобские наклонности в настоящий деспотизм. Вместо жизни в изоляции, они порабощают других существ, основывая и контролируя обширные империи. Такие злобоглазы иногда захватывают себе области внутри города или под ним, командуя целыми агентурными сетями.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
    {
      header: `Отчуждённые логова`,
      text: `Из-за отказа делить территорию с другими, большинство злобоглазов удаляться в холодные холмы, заброшенные руины и глубокие пещеры. Своё логово злобоглаз обычно вырезает лучом расщепления из своего глаза, соединяя вертикальными проходами камеры, расположенные друг над другом. Такое расположение позволяет злобоглазу спокойно перемещаться, одновременно затрудняя перемещения проникших в логово врагов. Если вдруг злоумышленники проникнут в логово, высокие потолки позволят злобоглазу левитировать наверху, пока его враги находятся на полу.

Комнаты в логове злобоглаза в полной мере отражают высокомерие этого существа. Они украшена трофеями, полученными в битвах, в том числе и окаменевшими искателями приключений, застывшими в последний момент с испуганными лицами, частичками других злобоглазов, и магическими вещами, полученными от могущественных врагов. Злобоглазы сами оценивают стоимость своих вещей, и по собственной воле редко расстанутся с сокровищами.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
    {
      header: `Логово злобоглаза`,
      text: `Центр логова злобоглаза это, как правило, просторная пещера с высокими потолками, где он может атаковать, не рискуя столкнуться в ближнем бою с врагом. У злобоглаза, обнаруженного в логове, показатель опасности 14 (11500 опыта).

# Действия логова

При сражении в своём логове, злобоглаз может воззвать к окружающей магии и использовать действие логова. При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) злобоглаз может совершить действие логова, чтобы вызвать один из следующих эффектов:

* Квадратная площадь 50 × 50 футов, находящаяся в пределах 120 футов от злобоглаза, становится вязкой; это зона — труднопроходимая местность, до значения инициативы 20 в следующем раунде.
* Стены в пределах 120 футов от злобоглаза обрастают хватательными придатками до значения инициативы 20 через два раунда. Все существа по выбору злобоглаза, начинающие свой ход в пределах 10 футов от такой стены, должно пройти испытание Ловкости СЛ 15, или будут схвачены. Для высвобождения необходима успешная проверка Силы (Атлетика) или Ловкости (Акробатика) СЛ 15.
* На твёрдой поверхности в пределах 60 футов от злобоглаза открывается глаз, испускающий случайным образом выбранный луч из глаз этого злобоглаза в цель, видимую злобоглазом на его выбор. После этого глаз закрывается и исчезает.

Злобоглаз не может повторять эффект, пока все эффекты не будут использованы, и он не может использовать один эффект два раунда подряд.

# Местные эффекты

Местность, в которой находится логово злобоглаза, искажается сверхъестественной природой существа. Обычно это один или несколько из следующих эффектов:

* Существа в пределах 1 мили от логова злобоглаза иногда ощущают, что за ними наблюдают, хотя рядом никого нет.
* Когда злобоглаз спит, в пределах 1 мили от его логова происходят незначительные искажения, исчезающие через 24 часа. Отметки на стенах пещер могут едва заметно измениться, мрачные безделушки могут появиться там, где их не было, безобидная слизь может покрыть статую, и так далее. Эти эффекты применяются только к естественным поверхностям и немагическим объектам, которые ни на кого не надеты.

Если злобоглаз умирает, эти эффекты пропадают в течение 1к10 дней.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
  ],
  note: {
    text: `Каждый злобоглаз думает, что он — олицетворение всего вида злобоглазов, и единственное, чего он боится, так это мысли о том, что он ошибается`,
    author: `Ваклар Айронфел, дварфийский мудрец`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_BEHOLDER_KEEN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 160,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 19,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 20,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  immunityConditionList: [
    [CONDITION_PRONE],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_13,
  featureList: [
    {
      name: 'Конус антимагии',
      description: `Центральный глаз ★СУЩЕСТВО★ создаёт 150-футовым конусом зону антимагии, как заклинание [Преграда магии](SPELL:${SPELL_ANTIMAGIC_FIELD}). В начале каждого своего хода ★СУЩЕСТВО★ решает, в какую сторону направлен конус, и будет ли он активен. Эта зона работает и против лучей из глаз ★самого★ ★СУЩЕСТВО★.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 6,
        },
      },
    },
    {
      name: 'Лучи из глаз',
      description: `★СУЩЕСТВО★ испускает три магических луча из глаз, выбранных случайным образом (перебрасывая повторяющиеся результаты), выбирая от одной до трёх целей, видимых в пределах 120 футов:

1. **Очаровывающий луч.** Целевое существо должно пройти испытание Мудрости СЛ 16, иначе ★СУЩЕСТВО★ очаровывает его на 1 час, или пока ★СУЩЕСТВО★ не причинит ему вред.
2. **Парализующий луч.** Целевое существо должно пройти испытание Телосложения СЛ 16, иначе станет парализованным на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
3. **Луч страха.** Целевое существо должно пройти испытание Мудрости СЛ 16, иначе станет испуганным на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
4. **Замедляющий луч.** Целевое существо должно пройти испытание Ловкости СЛ 16. При провале скорость цели на 1 минуту уменьшается вдвое. Кроме того, существо не может совершать реакции, и может в свой ход совершать либо действие, либо бонусное действие, но не то и другое. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
5. **Луч бессилия.** Целевое существо должно пройти испытание Телосложения СЛ 16, получая урон некротической энергией 36 (8к8) при провале, или половину этого урона при успехе.
6. **Телекинетический луч.**
    * Если цель — существо, она должна пройти испытание Силы СЛ 16, иначе ★СУЩЕСТВО★ переместит её на расстояние до 30 футов в любом направлении. Цель обездвижена телекинетической хваткой луча до начала следующего хода ★СУЩЕСТВО★, либо пока ★СУЩЕСТВО★ не станет ★недееспособным★.
    * Если цель — предмет, весящий не больше 300 фунтов, никем не несомый и не носимый, он перемещается в любом направлении на расстояние до 30 футов. ★СУЩЕСТВО★ может этим лучом совершать аккуратные действия, например, манипулировать простым инструментом, открывать дверь или контейнеры.
7. **Усыпляющий луч.** Целевое существо должно пройти испытание Мудрости СЛ 16, иначе уснёт и в течение 1 минуты будет лишено сознания. Цель просыпается, если получает урон или другое существо действием разбудит его. Этот луч не оказывает никакого эффекта на конструктов и нежить.
8. **Луч окаменения.** Целевое существо должно пройти испытание Ловкости СЛ 16. При провале существо начинает превращаться в камень и обездвиживается. В конце своего следующего хода оно должно повторить это испытание. При успехе эффект оканчивается. При провале существо становится окаменевшим, пока не будет использовано заклинание [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) или подобная магия.
9. **Луч расщепления.**
    * Если цель — существо, она должна пройти испытание Ловкости СЛ 16, иначе получит урон силовым полем 45 (10к8). Если этот урон снижает хиты существа до 0, его тело становится кучкой серой пыли.
    * Если цель — немагический предмет или творение магической силы с размером не больше Большого, она расщепляется без испытания.
    * Если цель — немагический предмет или творение магической силы с размером Огромный или больше, этот луч расщепляет объём куба с длиной ребра 10 футов.
10. **Луч смерти.** Целевое существо должно пройти испытание Ловкости СЛ 16, иначе получит урон некротической энергией 55 (10к10). Цель умирает, если луч уменьшает её хиты до 0.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Луч из глаз',
      description: `★СУЩЕСТВО★ использует один случайным образом выбранный луч из глаз.`,
    },
  ],
  genderId: GENDER_MALE,
}
