const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_MYCONID} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_MYCONID,
  nameEn: 'Myconid',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'миконид',
      genitive: 'миконида',
      dative: 'микониду',
      accusative: 'миконида',
      instrumental: 'миконидом',
      prepositional: 'микониде',
    },
    plural: {
      nominative: 'микониды',
      genitive: 'миконидов',
      dative: 'миконидам',
      accusative: 'миконидов',
      instrumental: 'миконидами',
      prepositional: 'миконидах',
    },
  },
}
