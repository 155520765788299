const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_AQUAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_MERFOLK,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {CR_1_8} = require('./../../../../crList')
const {CREATURE_MERFOLK} = require('./../../../../creatureIdList')
const {GEAR_SPEAR} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Мерфолк',
  nameEn: 'Merfolk',
  description: `Мерфолки — раса, обитающих в воде гуманоидов, верхняя часть тела которых человеческая, а нижняя — рыбья. Они используют ракушки для украшения своей кожи и чешуи.

Племена и королевства мерфолков простираются по всему миру, а их народы различны по цвету, культуре и взглядам подобно человеческой расе на суше. Наземные народы и мерфолки редко встречаются, но мечтательные моряки рассказывают истории о романтических встречах с этими существами на берегах далёких островов.

У мерфолков отсутствуют материалы и реальные возможности для того, чтобы выковать оружие на дне моря, написать книги, сохранить знания или придать форму камню, чтобы возвести здания и города. Поэтому большинство живёт в маленьких племенах охотников и собирателей, каждое из которых обладает уникальными ценностями и убеждениями. Время от времени, мерфолки объединяются под предводительством единого лидера. Они делают это чтобы противостоять общей угрозе или совершить завоевание. Такие объединения могут создать подводные королевства и династии, продолжающиеся сотни лет.

**Поселения мерфолков.** Мерфолки основывают свои поселения в обширных подводных пещерах, коралловых лабиринтах, руинах затопленных городов или сооружениях, которые они вырезали из скалистого морского дна. Они живут на мелководье, где можно различить усиление и ослабление света солнца через толщу воды. В рифах и рвах рядом со своим поселением мерфолки добывают кораллы, возделывают морское дно и пасут косяки рыб, как наземные фермеры пасут овец. Лишь изредка они отваживаются на путешествие в тёмные бездны океана. На таких глубинах и в подводных пещерах мерфолки полагаются на свет, исходящий от растительности и животных наподобие медуз, чьи слабые пульсирующие движения наделяют поселения мерфолков особой эстетикой.

Мерфолки защищают свои поселения с копьями, сделанными из таких материалов, которые они могут собрать после кораблекрушений, с пляжей и с мёртвых подводных существ.`,
  id: CREATURE_MERFOLK,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_MERFOLK,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 203,
  },
  armor: 11,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_AQUAN,
    LANG_COMMON,
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_AMPHIBIOUS,
  ],
  actionList: [
    {
      gearId: GEAR_SPEAR,
    },
  ],
  genderId: GENDER_MALE,
}
