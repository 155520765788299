const spellIdCollectionByPcClassId = require('./spellIdCollectionByPcClassId')
const pcClassIdCollectionBySpellId = require('./pcClassIdCollectionBySpellId')

module.exports = {
  pcClassIdCollectionBySpellId,
  spellIdCollectionByPcClassId,
}

module.exports.spellIdCollectionByPcClassId = spellIdCollectionByPcClassId
module.exports.pcClassIdCollectionBySpellId = pcClassIdCollectionBySpellId
