const {GENDER_MIDDLE} = require('./../../../../genderList')
const {MAGIC_ITEM_RING_OF_TRUTH_TELLING} = require('./../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_RING} = require('./../../../../magicItemTypeList')
const {SOURCE_WDH} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_RING_OF_TRUTH_TELLING,
  name: `Кольцо распознавания истины`,
  nameEn: `Ring of Truth Telling`,
  type: MGC_TYPE_RING,
  rarity: MGC_RARITY_UNCOMMON,
  attunement: true,
  description: `Пока Вы носите это кольцо, у Вас преимущество к проверкам Мудрости (Проницательность) при попытке определить, не лгут ли Вам.`,
  genderId: GENDER_MIDDLE,
  source: {
    id: SOURCE_WDH,
    page: 191,
  },
}
