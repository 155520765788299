const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_FAERIE_FIRE,
  SPELL_LEVITATE,
} = require('./../../../../spellIdList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_CROSSBOW_HAND,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  drowDescription,
  drowNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_11} = require('./../../../../crList')
const {CREATURE_DROW_SHADOWBLADE} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дроу клинок теней',
  nameEn: 'Drow Shadowblade',
  id: CREATURE_DROW_SHADOWBLADE,
  description: [
    `Дроу Клинок теней скрывается в тёмных проходах Подземья, намереваясь посеять хаос. Безжалостные убийцы — клинки теней находят работу у благородных домов, и это, как правило, связано с устранением конкурента в другом доме. Также, клинки теней защищают анклавы и города Подземья от врагов и выслеживают воров, похитивших ценные сокровища. Не важно, кому они служат, они перемещаются незамеченными, пока не нападут на свою жертву, и становятся единственным, что видит жертва перед смертью.

Клинок теней использует тёмную магию, которая, по слухам, исходит из ритуалов исчадий, где дроу убивает низшего демона и таинственным образом, не допускает того переродиться в Бездне. Этот ритуал создаёт теневого демона и наделяет дроу теневой магией.`,
    ...drowDescription,
  ],
  note: drowNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 168,
  },
  armor: {
    ac: 17,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 21,
    [PARAM_CON]: 16,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ELVEN,
    LANG_UNDERCOMMON,
  ],
  cr: CR_11,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    {
      name: 'Шаг в тень',
      description: `В тусклом свете или во тьме дроу может бонусным действием телепортироваться на незанятое пространство расстоянием до 60 фт. в пределах его видимости, и которое также находится во тьме или в тусклом свете. `,
    },
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DANCING_LIGHTS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DARKNESS,
          SPELL_FAERIE_FIRE,
          {
            id: SPELL_LEVITATE,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Дроу совершает две атаки _Теневым мечом_. Если обе атаки поражают цель, и она находится в пределах 10 фт от куба со стороной 5 фт, созданного _Теневым мечом_ на предыдущем ходу, дроу может развеять эту тьму, причиняя цели 21 (6к6) урона некротической энергией. Дроу может таким образом развеивать тьму только один раз за свой ход.`,
    },
    {
      name: 'Теневой меч',
      description: `Дроу может создать в незанятом пространстве в пределах 5 футов от цели магическую тьму в кубе со стороной 5 футов. Тьма существует 1 минуту.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 5,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 3,
            diceType: 6,
            diceBonus: 0,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 3,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      gearId: GEAR_CROSSBOW_HAND,
      description: `Если цель — существо, оно должно пройти испытание Телосложения СЛ 13, иначе станет Отравленным на 1 час. Если испытание провалено на 5 или больше, то оно также теряет сознание. К существу возвращается сознание, если оно получило урон или его привели в себя Действием.`,
    },
  ],
}
