const generateRarityList = require('./../../../../../../utils/generateRarityList')

const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {MAGIC_ITEM_ALL_PURPOSE_TOOL} = require('./../../../../../magicItemIdList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {PC_CLASS_ARTIFICER} = require('./../../../../../pcClassIdList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_ALL_PURPOSE_TOOL,
  name: `Всезадачный инструмент, +1, +2 или +3`,
  nameEn: `All-Purpose Tool, +1, +2 or +3`,
  rarity: generateRarityList(list),
  attunement: true,
  attunementComment: `изобретателем`,
  type: MGC_TYPE_WONDROUS_ITEM,
  description: `Эта простая отвёртка может превращаться во множество других инструментов.

Вы можете действием коснуться _Всезадачного инструмента_ и превратить его в любой вид ремесленных инструментов во Вашему выбору. В какой бы инструмент он ни превратился, Вы им владеете.

Держа _Всезадачный инструмент_, Вы получаете бонус к броскам атаки заклинаниями и СЛ испытаний Ваших заклинаний [изобретателя](PC_CLASS:${PC_CLASS_ARTIFICER}). Размер бонуса определяется редкостью _Инструмента_.

Вы можете действием сфокусироваться на _Всезадачном инструменте_, направляя свои творческие силы. Выберите неизвестный Вам заговор любого класса. В течение 8 часов Вы можете сотворять этот заговор и он считается для Вас заговором [изобретателя](PC_CLASS:${PC_CLASS_ARTIFICER}). Использовав это свойство _Инструмента_, Вы не можете использовать его повторно до следующего рассвета.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 172,
  },
}
