const {SOURCE_MM} = require('./../../sourceList')

module.exports = {
  header: 'Наги',
  text: `Наги — разумные змеевидные создания, населяющие древние руины и собирающие волшебные сокровища и знания.

Первые наги давным-давно были созданы как бессмертные стражи гуманоидной расы. Когда эта раса вымерла, наги посчитали себя законными наследниками сокровищ и магических таинств своих хозяев. Трудолюбивые наги иногда выбираются из своего логова, чтоб отыскать волшебные предметы и редкие книги заклинаний.

Наги никогда не чувствуют разрушительное воздействие времени и не поддаются болезням. Даже если тело наги уничтожить, её бессмертная душа за несколько дней формирует новое тело, готовое продолжать бессмертное дело.

**Доброжелательные диктаторы и жестокие тираны.** В своих владениях нага обладает абсолютной властью. Вне зависимости от того, руководит она милосердно или терроризирует своих подданных, нага верит, что является хозяином всех прочих существ, населяющих её территорию.

**Соперничество.** Наги ведут давнюю вражду с юань-ти, расой, считающей себя воплощением змеиной эволюции. Хотя сотрудничество между ними редко, наги и юань-ти порой забывают о разногласиях, чтобы достичь общей цели. Однако юань-ти очень не любят находиться во власти наги.

**Бессмертная натура.** Нага не нуждается в воздухе, еде, питье и сне.`,
  source: {
    id: SOURCE_MM,
    page: 218,
  },
}
