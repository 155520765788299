const {SOURCE_DMG} = require('./../../../../../sourceList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {SPELL_GATE} = require('./../../../../../spellIdList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_BAG_OF_HOLDING,
  MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK,
  MAGIC_ITEM_PORTABLE_HOLE,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_BAG_OF_HOLDING,
  name: 'Сумка хранения',
  nameAlt: 'Бездонная сумка',
  nameEn: 'Bag of Holding',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Эта _сумка_ внутри гораздо больше, чем можно было предположить, исходя из её внешних размеров (приблизительно 2 фута в диаметре и 4 фута глубины). _Сумка_ может вместить до 500 фунтов, не превышающих в объёме 64 кубических фута. При этом _сумка_ всегда весит 15 фунтов, вне зависимости от её содержания. Извлечение чего-либо из _сумки_ совершается действием.

Если _сумка_ перегружена, проткнута, или порвана, она рвётся окончательно, уничтожается, и её содержимое разбрасывается по Астральному Плану. Если _сумка_ оказывается вывернутой наизнанку, то её содержимое вываливается наружу невредимым, но прежде, чем её можно будет снова использовать, _сумку_ необходимо вывернуть обратно. Дышащие существа, помещённые внутрь _сумки_, способны оставаться в живых в течение времени, равного десяти минутам, поделённым на количество существ (минимум 1 минута), после чего они начинают задыхаться.

Помещение _сумки хранения_ в межпространство, созданное с помощью [удобного рюкзака Хеварда](MAGIC_ITEM:${MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK}), [переносной дыры](MAGIC_ITEM:${MAGIC_ITEM_PORTABLE_HOLE}) или подобного предмета, оба предмета мгновенно уничтожаются и открываются [врата](SPELL:${SPELL_GATE}), ведущие на Астральный План. [Врата](SPELL:${SPELL_GATE}) появляются в месте, где один предмет пытались поместить в другой. Все существа в пределах 10 футов от [врат](SPELL:${SPELL_GATE}) затягиваются внутрь и помещаются в случайным образом определённое место на Астральном Плане. После этого врата закрываются. [Врата](SPELL:${SPELL_GATE}) односторонние, и повторно не открываются.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 205,
  },
}
