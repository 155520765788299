const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CONDITION_RESTRAINED} = require('./../../../../../conditionList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SPELL_WATERY_SPHERE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_WATERY_SPHERE,
  name: 'Водяная сфера',
  nameEn: 'Watery Sphere',
  description: `Вы создаёте сферу воды с радиусом 10 футов в указанной точке в пределах дистанции заклинания. Сфера может парить в воздухе, однако не выше, чем в 10 футах над землёй. Сфера существует до тех пор, пока действует заклинание.

Все существа, оказавшиеся внутри сферы, должны пройти испытание Силы. При успехе существо выталкивается из сферы в ближайшее свободное пространство. Существа как минимум Огромного размера автоматически проходят это испытание, существа размером Большое и меньше могут добровольно провалить испытание. При провале существо Обездвиживается сферой и погружается в воду. В конце каждого своего хода Обездвиженная цель может повторить испытание, при успехе заканчивая действие заклинания на себе.

Сфера может обездвижить до четырёх существ Среднего или меньшего размера или одно Большое существо. Если сфера обездвиживает существо, превышая при этом данное ограничение, то случайное из уже захваченных существ выпадает из сферы и падает Ничком в 5 футах от неё.

Вы можете действием переместить сферу на расстояние до 30 футов по прямой. Если на её пути оказывается яма, обрыв или другой провал, она безопасно спускается до тех пор, пока не достигнет высоты в 10 футов над полом. Все захваченные существа перемещаются вместе со сферой. Вы можете направить сферу в существ, заставляя их пройти испытание.

Когда заклинание заканчивается, сфера падает на землю и гасит все источники огня немагического происхождения в пределах 30 футов. Все существа, которые находились в ней, Ничком падают на землю. После этого вода исчезает.`,
  lvl: 4,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `капля воды`,
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_STR,
    condition: CONDITION_RESTRAINED,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 150,
    },
    {
      id: SOURCE_EE,
      page: 16,
    },
  ],
}
