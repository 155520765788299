const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MAGIC_ITEM_RING_OF_JUMPING} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_JUMP} = require('./../../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_RING_OF_JUMPING,
  name: `Кольцо прыжков`,
  nameEn: `Ring of Jumping`,
  type: MGC_TYPE_RING,
  rarity: MGC_RARITY_UNCOMMON,
  attunement: true,
  description: `Нося это кольцо, Вы можете бонусным действием сотворять заклинание [Прыжок](SPELL:${SPELL_JUMP}), но нацеливаясь при этом только на себя.`,
  genderId: GENDER_MIDDLE,
  spellIdList: [SPELL_JUMP],
  source: {
    id: SOURCE_DMG,
    page: 177,
  },
}
