const {SOURCE_AYAGWnW} = require('./../../sourceList')

const swordDescription = {
  header: 'Мечи',
  text: `Вечная классика, мечи, сделаны из длинного куска металла, заостренного с двух сторон и закрепленного на прочной рукояти. Мечи бывают разной длины и веса и могут быть как разукрашены, так и нет, в зависимости от богатства и вкуса владельца.

Мечами можно рубить врагов, коля их острым концом и даже наносить тупые удары лезвием плашмя или рукоятью. Чем больше ваш боец может придумать применений для меча, тем сложнее будет врагам от них отбиваться!`,
  source: {
    id: SOURCE_AYAGWnW,
    page: 106,
  },
}

module.exports = swordDescription
