const {SOURCE_VRGtR} = require('./../../sourceList')
const {SPELL_CURE_WOUNDS} = require('./../../spellIdList')
const {LANG_COMMON} = require('./../../languageIdList')
const {
  PC_RACE_HEXBLOOD,
  PC_RACE_HUMAN,
  PC_RACE_DHAMPIR,
  PC_RACE_DRAGONBORN,
} = require('./../../pcRaceIdList')

module.exports = [
  {
    header: 'Наследия',
    text: `В Земле Туманов сила и ужас заключаются в простом вопросе: _«Что со мной случилось?»_.

Наследия ([Дампир](PC_RACE:${PC_RACE_DHAMPIR}), [Порчекровный](PC_RACE:${PC_RACE_HEXBLOOD}) или Возрождённый) — это расы, которые персонажи могут получить в результате знаменательных событий. Они затмевают первоначальную расу, если таковая имеется, становясь их новой расой.

Вы можете выбрать наследие во время создания персонажа, и тогда его трансформация произошла до начала игры. Или же события могут разворачиваться во время приключений, которые приведут Вашего персонажа к замене своей расы наследием.

Поработайте со своим Мастером, чтобы определить, ответственны ли Вы за свою трансформацию и то, как история повернулась после этого события.`,
    source: {
      id: SOURCE_VRGtR,
      page: 14,
    },
  },
  {
    header: 'Что со мной случилось?',
    text: `Наследия представляют физические и магические трансформации, которые фундаментально изменили Вас. Вы можете всё ещё выглядеть как раньше, но значительно изменяетесь, что может полностью поменять Ваши физические и магические способности.

Например, [драконорождённый](PC_RACE:${PC_RACE_DRAGONBORN}), став [дампиром](PC_RACE:${PC_RACE_DHAMPIR}), теряет свою связь с драконьим наследием из-за бессмертной вампирской энергии, плещущейся внутри него. Вместо смертельного дыхания, он теперь чувствует внутри себя мощный голод, а его клыки теперь могут высасывать чужую жизнь.

Некоторые расовые особенности могут остаться нетронутыми при получении наследия, как указано в особенности _«Наследие предков»_. Учитывайте это, когда будете изучать подробности того, как именно наследие изменило Вашего персонажа.`,
    source: {
      id: SOURCE_VRGtR,
      page: 15,
    },
  },
  {
    header: 'Создание Вашего персонажа',
    text:  `На первом уровне Вы выбираете — будет ли Ваш персонаж принадлежать расе [людей](PC_RACE:${PC_RACE_HUMAN}) или же одной из игровых фантастических рас. Альтернативно, Вы можете выбрать одно из наследий ([Дампир](PC_RACE:${PC_RACE_DHAMPIR}), [Порчекровный](PC_RACE:${PC_RACE_HEXBLOOD}) или Возрождённый). Если Вы выбираете наследие, то, возможно, раньше Вы принадлежали к другой расе, но теперь это уже не так. Вы получаете только особенности выбранного наследия. 

Когда Вы создаёте персонажа, используя эти варианты наследий, учитывайте следующие дополнительные правила создания персонажа.

# Увеличение характеристик

Для определения значений Ваших характеристик, Вы повышаете одну из них на 2 и одну другую на 1, либо же повышаете три разных характеристики на 1. Вы соблюдаете это правила вне зависимости от способа определения значений характеристик, таких как броски костей или покупка за очки.

Врезка _«Быстрое создание»_ в описании Вашего класса предлагает характеристики, которые рекомендуется повысить. Вы можете как следовать этим рекомендациям, так и игнорировать их. Какие бы характеристики Вы не повысили, они не могут превышать 20.

Если Вы заменяете свою расу этим наследием, то замените и расовую особенность _«Увеличение характеристик»_ на эту.

# Языки

Ваш персонаж может говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и одном другом языке, который Вы вместе с Вашим Мастером почитаете подходящим для него. Книга игрока предлагает на выбор распространённые языки. Мастер может добавлять или убирать языки из этого списка для каждой конкретной кампании.

Если Вы заменяете свою расу этим наследием, то сохраняете все языки, которые Вам были известны и не получаете новых языков.

# Тип существа

Все существа в D&D, включая персонажей игроков, имеют специальную пометку в правилах, которая определяет к какому типу существ они относятся. Большинство персонажей игроков — гуманоиды. Предлагаемые наследия указывают к какому типу существ будет относиться Ваш персонаж.

Вот список игровых типов существ: аберрации, великаны, гуманоиды, драконы, звери, исчадия, конструкты, монстры, небожители, нежить, растения, слизи, феи, элементали. Эти типы не имеют связанных с ними правил сами по себе, но некоторые игровые правила затрагивают разных типов по-разному.

Например, текст заклинания [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}) уточняет что оно не действует на конструктов.`,
    source: {
      id: SOURCE_VRGtR,
      page: 15,
    },
  },
]
