const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../../../genderList')

module.exports = {
  friendly: {
    [GENDER_FEMALE]: 'дружественна',
    [GENDER_MALE]: 'дружественен',
    [GENDER_MIDDLE]: 'дружественно',
    [GENDER_MULTIPLE]: 'дружественны',
  },
  alive: {
    [GENDER_FEMALE]: 'живой',
    [GENDER_MALE]: 'живым',
    [GENDER_MIDDLE]: 'живым',
    [GENDER_MULTIPLE]: 'живыми',
  },
  must: {
    [GENDER_FEMALE]: 'должна',
    [GENDER_MALE]: 'должен',
    [GENDER_MIDDLE]: 'должно',
    [GENDER_MULTIPLE]: 'должны',
  },
  pronoun: {
    nominative: {
      [GENDER_FEMALE]: 'она',
      [GENDER_MALE]: 'он',
      [GENDER_MIDDLE]: 'оно',
      [GENDER_MULTIPLE]: 'они',
    },
    genitive: {
      [GENDER_FEMALE]: 'её',
      [GENDER_MALE]: 'его',
      [GENDER_MIDDLE]: 'его',
      [GENDER_MULTIPLE]: 'их',
    },
  },
}
