const listToCollectionById = require('./../../utils/listToCollectionById')
const generateListReleasedBeforeAs = require('./../../utils/generateListReleasedBeforeAs')
const sortByName = require('./../../utils/sortByName')
const checkIsFromUA = require('./../../utils/checkIsFromUA')

const infusionRawList = require('./infusionRawList')

const infusionList = infusionRawList
  .map(
    (item, i, list) => ({
      ...item,
      isAbandoned: Boolean(item.abandonedAt),
      isReReleased: Boolean(item.releasedAs),
      releasedAs: item.releasedAs || null,
      releasedBeforeAsList: generateListReleasedBeforeAs(list, item.id),
      isUA: checkIsFromUA(item.source),
    }),
  )
  .sort(sortByName)

module.exports = infusionList

module.exports.infusionCollection = listToCollectionById(infusionList)
