import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import generateNameStr from '@/utils/generateNameStr'

import './ItemListStyles.less'

const ItemListComponent = (
  {
    itemCollection,
    idList,
    pageUrlGenerator,
  },
) => (
  <ul className='ItemList'>
    {
      idList.map(
        letter => (
          <li
            className='ItemList_letterItem'
            key={letter}
          >
            <ul className='ItemList_idList'>
              {
                itemCollection[letter].map(
                  ({ id, isUA, hasSpoilers, ...rest }, i) => {
                    const { header, title } = generateNameStr(rest)

                    return (
                      <li
                        className={classNames(
                          'ItemList_item',
                          {
                            'ItemList_item-isUA': isUA,
                          }
                        )}
                        key={id}
                        {
                          ...(
                            i === 0
                              ? { 'data-letter': letter }
                              : {}
                          )
                        }
                      >
                        <Link
                          className={classNames(
                            'ItemList_link',
                            {
                              'ItemList_link-hasSpoilers': hasSpoilers,
                            }
                          )}
                          title={
                            isUA
                              ? `Раскопанные тайны\n${title}`
                              : title
                          }
                          to={pageUrlGenerator(id)}
                        >
                          {header}
                        </Link>
                      </li>
                    )
                  },
                )
              }
            </ul>
          </li>
        ),
      )
    }
  </ul>
)

ItemListComponent.defaultProps = {
  itemCollection: {},
}

ItemListComponent.propTypes = {
  itemCollection: PropTypes.object,
  idList: PropTypes.array.isRequired,
  pageUrlGenerator: PropTypes.func.isRequired,
}

export default ItemListComponent
