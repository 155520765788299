const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  PARAM_DEX,
} = require('./../../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../../damageTypeList')
const {
  CONDITION_RESTRAINED,
} = require('./../../../../../conditionList')
const {
  SPELL_EVARD_S_BLACK_TENTACLES,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_EVARD_S_BLACK_TENTACLES,
  name: 'Эвардовы чёрные щупальца',
  nameEn: 'Evard’s Black Tentacles',
  nameEnAlt: 'Black Tentacles',
  description: `Чёрные извивающиеся щупальца заполняют квадрат на полу с длиной стороны 20 футов, который Вы видите в пределах дистанции. Пока заклинание активно, эти щупальца делают местность труднопроходимой.

Когда существо впервые за ход входит в область заклинания или начинает там ход, оно должно пройти испытание Ловкости, иначе получит дробящий урон 3к6 и станет обездвиженым щупальцами до окончания заклинания. Существо, начинающее ход в этой области, уже обездвиженое щупальцами, получает дробящий урон 3к6.

Обездвиженое щупальцами существо может действием совершить проверку Силы или Ловкости (на свой выбор) против СЛ Ваших заклинаний. При успехе оно высвобождается.`,
  lvl: 4,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кусочек щупальца гигантского осьминога или гигантского кальмара`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    condition: CONDITION_RESTRAINED,
    damage: {
      type: DAMAGE_BLUDGEONING,
      diceCount: 3,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 290,
    },
    {
      id: SOURCE_SRD,
      page: 121,
    },
  ],
}
