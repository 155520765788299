const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {gearCollection} = require('./../../../../../gearList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../../../genderList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    gearId,
    id,
  }
) => {
  const {
    name,
    nameEn: gearNameEn,
    nameByCase: {
      accusative,
    },
    genderId,
  } = gearCollection[gearId]

  const {
    pronoun,
    pronounAccusative,
    verb,
  } = {
    [GENDER_MALE]: {
      pronoun: 'Этот',
      pronounAccusative: 'этот',
      verb: 'декорирован',
    },
    [GENDER_FEMALE]: {
      pronoun: 'Эта',
      pronounAccusative: 'эту',
      verb: 'декорирована',
    },
    [GENDER_MIDDLE]: {
      pronoun: 'Это',
      pronounAccusative: 'это',
      verb: 'декорировано',
    },
    [GENDER_MULTIPLE]: {
      pronoun: 'Эти',
      pronounAccusative: 'эти',
      verb: 'декорированы',
    },
  }[genderId]

  return {
    id,
    name: `${name} моряка`,
    nameEn: `Mariner’s ${gearNameEn}`,
    type: MGC_TYPE_ARMOR,
    gearType: gearId,
    rarity: MGC_RARITY_UNCOMMON,
    description: `${pronoun} ${name.toLowerCase()} искусно ${verb} различными мотивами с изображениями рыб и морских раковин.

Пока Вы носите ${pronounAccusative} ${accusative.toLowerCase()}, Вы обладаете скоростью плавания, равной скорости ходьбы. Кроме того, каждый раз, когда Вы начинаете ход, находясь под водой и с 0 хитов, Вы поднимаетесь к поверхности на 60 футов.`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 159,
    },
  }
}
