import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons'

import AdsAtCatalog from '@/ads/components/AdsAtCatalog'

import Icon from '@/components/Icon'
import Loading from '@/components/Loading'
import PageTitle from '@/components/PageTitle'

import FilterList from './components/FilterList'
import ItemList from './components/ItemList'

import './CatalogStyles.less'
import Markdown from '@/components/Markdown'
import updateNameAtDescription from '@/utils/updateNameAtDescription'

const CatalogComponent = (
  {
    count,
    children = null,
    isLoading,
    itemCollection,
    itemNotGroupedList,
    List,
    pageTitle,
    pageUrlGenerator,
    Table,
    toggleUseTable,
    useTable,
    warningList,
    ...rest
  },
) => {
  const idList = Object.keys(itemCollection)

  return (
    <section className={`Catalog Catalog-lettersCount_${idList.length}`}>
      <PageTitle>{pageTitle}</PageTitle>
      {
        children
          ? <div className="Catalog_description">{children}</div>
          : null
      }
      <section className="Catalog_controls">
        <FilterList
          disabled={isLoading}
          {...rest}
        />
        {
          Table
            ? (
              <label className={classNames(
                'Catalog_useTable',
                {
                  'Catalog_useTable-disabled': isLoading,
                }
              )}>
                <Icon
                  className='Catalog_checkboxIcon'
                  icon={
                    useTable
                      ? faCheckSquare
                      : faSquare
                  }
                />
                <input
                  className='Catalog_checkboxInput'
                  onChange={toggleUseTable}
                  disabled={isLoading}
                  type='checkbox'
                  value={useTable}
                />
                Показывать таблицей
              </label>
            )
            : null
        }
      </section>

      <p className='Catalog_result'>
        {
          isLoading
            ? <Loading>Загрузка каталога…</Loading>
            : idList.length
              ? `Найдено: ${count}`
              : 'Ничего не найдено'
        }
      </p>

      {warningList.length ? (
        <ul className="Catalog_warningList">
          {warningList.map(
            (text) => (
              <li
                className='Catalog_warningItem'
                key={text}
              >
                <Markdown>{text}</Markdown>
              </li>
            )
          )}
        </ul>
      ) : null}

      <div className='Catalog_content'>
        <AdsAtCatalog className="Catalog_adsBlock"/>

        {
          useTable
            ? (
              <Table
                showList={true}
                pageUrlGenerator={pageUrlGenerator}
                itemList={itemNotGroupedList}
              />
            )
            : (
              <List
                showList={true}
                pageUrlGenerator={pageUrlGenerator}
                itemCollection={itemCollection}
                idList={idList}
                itemNotGroupedList={itemNotGroupedList}
              />
            )
        }
      </div>
    </section>
  )
}

CatalogComponent.defaultProps = {
  itemCollection: {},
  List: ItemList,
  warningList: [],
}

CatalogComponent.propTypes = {
  itemCollection: PropTypes.object,
  List: PropTypes.element,
  warningList: PropTypes.arrayOf(PropTypes.string),
}

export default CatalogComponent
