const {PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD_2016_11_28} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE} = require('./../../../sourceList')
const {SPELL_DEATH_WARD} = require('./../../../spellIdList')
const {
  FEATURE_GUARDIAN_SPIRIT_2016_11_28,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_GUARDIAN_SPIRIT_2016_11_28,
    name: `Охранный дух`,
    nameEn: `Guardian Spirit`,
    lvl: 10,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD_2016_11_28,
    spellIdList: SPELL_DEATH_WARD,
    source: {
      id: SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE,
      page: 2,
    },
  },
]
