const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_KEOGHTOM_S_OINTMENT,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_KEOGHTOM_S_OINTMENT,
  name: `Мазь Кеогтома`,
  nameEn: `Keoghtom’s Ointment`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  isConsumable: true,
  description: `Это стеклянная банка, три дюйма в диаметре, содержит 1к4 + 1 доз густой мази, которая слабо пахнет алоэ. Банка и её содержимое весят 0,5 фунта.

Действием можно проглотить или нанести на кожу одну дозу мази. Существо, которое делает так, восстанавливает 2к8 + 2 хита, перестаёт быть отравленным и излечивается от всех болезней.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 180,
  },
}
