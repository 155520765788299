const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MULTIPLE,
} = require('./../../../../../genderList')
const {
  GEAR_LANCE,
  GEAR_LONGSWORD,
} = require('./../../../../../gearIdList')
const { SPELL_ANIMAL_MESSENGER } = require('./../../../../../spellIdList')
const {
  CREATURE_ELEPHANT,
  CREATURE_GIANT_FLY,
  CREATURE_GIANT_GOAT,
  CREATURE_GIANT_OWL,
  CREATURE_GRIFFON,
  CREATURE_LION,
  CREATURE_MASTIFF,
  CREATURE_NIGHTMARE,
  CREATURE_RAVEN,
  CREATURE_RIDING_HORSE,
} = require('./../../../../../creatureIdList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_BRONZE_GRIFFON,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_EBONY_FLY,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_GOLDEN_LIONS,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_IVORY_GOATS,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_MARBLE_ELEPHANT,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_OBSIDIAN_STEED,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_ONYX_DOG,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SERPENTINE_OWL,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SILVER_RAVEN,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_BRONZE_GRIFFON,
    rarity: MGC_RARITY_RARE,
    genderId: GENDER_MALE,
    name: 'Бронзовый грифон',
    nameEn: 'Bronze Griffon',
    description: {
      image: `Это бронзовая статуэтка грифона, стоящего на задних лапах.`,
      alive: '6 часов',
      restore: '5 дней',
    },
    creatureId: CREATURE_GRIFFON,
    creatureName: {
      nominative: 'грифон',
      genitive: 'грифона',
      instrumental: 'грифоном',
    },
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_MARBLE_ELEPHANT,
    rarity: MGC_RARITY_RARE,
    genderId: GENDER_MALE,
    name: 'Мраморный слон',
    nameEn: 'Marble Elephant',
    description: {
      image: `Эта мраморная статуэтка примерно 4 дюйма в высоту и длину.`,
      alive: '24 часа',
      restore: '7 дней',
    },
    creatureId: CREATURE_ELEPHANT,
    creatureName: {
      nominative: 'слон',
      genitive: 'слона',
      instrumental: 'слоном',
    },
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_OBSIDIAN_STEED,
    rarity: MGC_RARITY_VERY_RARE,
    genderId: GENDER_FEMALE,
    name: 'Обсидиановый скакун',
    nameEn: 'Obsidian Steed',
    description: {
      image: `Это лошадь из полированного обсидиана.`,
      properties: `Мара сражается только чтобы защитить саму себя. Если у Вас доброе мировоззрение, каждый раз, когда Вы отдаёте приказ, в том числе приказ вернуться в форму статуэтки, у статуэтки есть 10% шанс, что она проигнорирует его. Если Вы едете верхом на маре, когда она игнорирует Вас, Вы с ней мгновенно переноситесь в случайное место на плане Гадес, где она вновь становится статуэткой.`,
      alive: '24 часа',
      restore: '5 дней',
    },
    creatureId: CREATURE_NIGHTMARE,
    creatureName: {
      nominative: 'мара',
      genitive: 'мары',
      instrumental: 'марой',
    },
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_ONYX_DOG,
    rarity: MGC_RARITY_RARE,
    genderId: GENDER_MALE,
    name: 'Ониксовая собака',
    nameEn: 'Onyx Dog',
    description: {
      image: `Это ониксовая статуэтка собаки.`,
      properties: `У [мастифа](CREATURE:${CREATURE_MASTIFF}) Интеллект 8, и он может говорить на Общем. Он также обладает тёмным зрением в пределах 60 футов и может видеть невидимых существ и предметы в пределах этого диапазона.`,
      alive: '6 часов',
      restore: '7 дней',
    },
    creatureId: CREATURE_MASTIFF,
    creatureName: {
      nominative: 'мастиф',
      genitive: 'мастифа',
      instrumental: 'мастифом',
    },
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_IVORY_GOATS,
    rarity: MGC_RARITY_RARE,
    genderId: GENDER_MULTIPLE,
    name: 'Костяные козлы',
    nameEn: 'Ivory Goats',
    description: {
      image: `Эти статуэтки козлов из слоновой кости всегда создаются по три штуки. Каждый козёл имеет уникальную внешность, и функционирует по-своему.`,
      alive: 'определённое время (зависит от типа козла)',
      restore: 'определённое время (зависит от типа козла)',
      properties: `## Козёл путешествия

Становится _Большим_ козлом с параметрами [ездовой лошади](CREATURE:${CREATURE_RIDING_HORSE}). У него есть 24 заряда, и каждый час или его часть, пока козёл остаётся животным, расходуется 1 заряд.

Пока у него есть заряды, Вы можете его использовать так часто, как Вам вздумается.

Когда у него кончаются заряды, он становится статуэткой и не может использоваться, пока не пройдёт 7 дней, после чего он восстанавливает все свои заряды.

## Козёл перевозки

Становится [гигантским козлом](CREATURE:${CREATURE_GIANT_GOAT}) на 3 часа.

Его нельзя использовать повторно, пока не пройдёт 30 дней.

## Козёл ужаса

Становится [гигантским козлом](CREATURE:${CREATURE_GIANT_GOAT}) на 3 часа. Он не может атаковать, но Вы можете брать его рога и использовать их в качестве оружия. Один рог становится [длинным копьём](GEAR:${GEAR_LANCE}) +1, а другой становится [длинным мечом](GEAR:${GEAR_LONGSWORD}) +2. Рог берётся действием, и оружие исчезает, когда козёл становится статуэткой, вновь превращаясь в рог.

Кроме того, козёл излучает _ауру ужаса_ с радиусом 30 футов, когда Вы на нём едете. Все враждебные Вам существа, начинающие ход в ауре, должны пройти испытание Мудрости СЛ 15, иначе они станут испуганными козлом на 1 минуту, ли пока козёл не станет статуэткой. Испуганное существо может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. При успехе существо получает иммунитет к ауре козла на следующие 24 часа.

Статуэтку нельзя использовать повторно, пока не пройдёт 15 дней.`,
    },
    creatureId: CREATURE_GIANT_GOAT,
    creatureName: {
      nominative: 'козёл',
      genitive: 'козла',
      instrumental: 'козлом',
    },
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SILVER_RAVEN,
    rarity: MGC_RARITY_UNCOMMON,
    genderId: GENDER_MALE,
    name: 'Серебряный ворон',
    nameEn: 'Silver Raven',
    description: {
      image: `Это серебряная статуэтка ворона.`,
      properties: `Пока статуэтка находится в облике [ворона](CREATURE:${CREATURE_RAVEN}), Вы можете неограниченно сотворять на неё заклинание [Почтовое животное](SPELL:${SPELL_ANIMAL_MESSENGER}).`,
      alive: '12 часов',
      restore: '2 дня',
    },
    creatureId: CREATURE_RAVEN,
    creatureName: {
      nominative: 'ворон',
      genitive: 'ворона',
      instrumental: 'вороном',
    },
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_GOLDEN_LIONS,
    rarity: MGC_RARITY_RARE,
    genderId: GENDER_MULTIPLE,
    name: 'Золотые львы',
    nameEn: 'Golden Lions',
    description: {
      image: `Эти золотые статуэтки львов всегда создаются парами.`,
      properties: `Вы можете использовать одну статуэтку или сразу две одновременно.`,
      alive: '1 час',
      restore: '7 дней',
    },
    creatureId: CREATURE_LION,
    creatureName: {
      nominative: 'лев',
      genitive: 'льва',
      instrumental: 'львом',
    },
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SERPENTINE_OWL,
    rarity: MGC_RARITY_RARE,
    genderId: GENDER_FEMALE,
    name: 'Серпентиновая сова',
    nameEn: 'Serpentine Owl',
    description: {
      image: `Эта серпентиновая статуэтка изображает сову.`,
      properties: `Сова может телепатически общаться с Вами на любом расстоянии, если Вы с ней находитесь на одном плане существования.`,
      alive: '8 часов',
      restore: '2 дня',
    },
    creatureId: CREATURE_GIANT_OWL,
    creatureName: {
      nominative: 'сова',
      genitive: 'совы',
      instrumental: 'совой',
    },
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_EBONY_FLY,
    rarity: MGC_RARITY_RARE,
    genderId: GENDER_FEMALE,
    name: 'Эбеновая муха',
    nameEn: 'Ebony Fly',
    description: {
      image: `Эта статуэтка из чёрного дерева похожа на обычную муху.`,
      properties: `На ней можно летать, как на верховом животном.`,
      alive: '12 часов',
      restore: '2 дня',
    },
    creatureId: CREATURE_GIANT_FLY,
    creatureName: {
      nominative: 'муха',
      genitive: 'мухи',
      instrumental: 'мухой',
    },
  },
]
