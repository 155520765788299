const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_COMMON} = require('./../../languageIdList')
const {LANG_TYPE_STANDARD} = require('./../../languageTypeList')
const {PLACE_FAERUN} = require('./../../placeIdList')
const {
  SOURCE_DND1_CotR,
  SOURCE_DND3_FRCS,
  SOURCE_DND4_FRPG,
} = require('./../../sourceList')
const {
  humanLanguagesDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: LANG_COMMON,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_STANDARD,
  name: {
    nominative: 'Всеобщий',
    genitive: 'Всеобщего',
    instrumental: 'Всеобщим',
    prepositional: 'Всеобщем',
  },
  nameAlt: 'Общий',
  nameEn: 'Common',
  spokenPlaceList: {
    comment: '— повсюду, торговый язык',
    id: PLACE_FAERUN,
  },
  description: [
    {
      text: `Почти все разумные существа, с которыми Вы можете столкнуться, могут понимать и говорить на Всеобщем языке (на торговом языке людей, на котором, с небольшими вариациями, говорят во всех известных Королевствах), хотя они и могут делать вид, что не понимают его.

От региона к региону Королевств Всеобщий может (под влиянием других местных человеческих и нечеловеческих языков) иметь разные акценты и немного различающуюся лексику. Хотя уроженец Тэя сможет общаться с обитателем Врат Балдура, каждый из них будет знать о смехотворном акценте другого.`,
      source: {
        id: SOURCE_DND1_CotR,
        page: 10,
      },
    },
    {
      header: `Всеобщий язык`,
      text: `Все владеющие речью народы, включая людей с разнообразных островов, имеют свой родной язык. В дополнение к нему все люди и многие нелюди владеют Всеобщим как вторым языком. Всеобщий вырос из своеобразного чондатанского пиджина и очень близок к этому языку, но намного проще его и менее выразителен. Нюансы речи, именований и речевых оборотов намного лучше выражать на старых, более зрелых языках, потому что Всеобщий лишь немногим больше чем торговый язык.

Огромным преимуществом Всеобщего, конечно же, является его распространённость. Все жители Сердцеземья говорят на Всеобщем довольно неплохо, чтобы мочь вести довольно эзотерические обсуждения. Даже в таких отдалённых областях, как Мургром или Самарач, почти все знают Всеобщий достаточно, чтобы мочь на нём плохо говорить. В крайнем случае они дополняют речь пантомимой, но могут изъясняться так, чтобы быть понятыми. Уроженцы отделённых друг от друга областей скорее всего будут считать акцент друг друга странным или даже глупым, но всё ещё смогут понимать друг друга.`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 85,
      },
    },
    {
      header: `Всеобщий язык`,
      text: `Всеобщий широко распространён по Фаэруну, хотя иногда им плохо владеют. Это прямолинейный язык, лучше всего подходящий для торговли и переговоров.

Всеобщий язык Фаэруна тесно связан с человеческим чондатанским языком.`,
      source: {
        id: SOURCE_DND4_FRPG,
        page: 157,
      },
    },
    ...humanLanguagesDescriptionList,
  ],
  typicalSpeakers: 'Люди',
  isRealLang: true,
  isReady: true,
}
