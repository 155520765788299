const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_COLD} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_INVESTITURE_OF_ICE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_INVESTITURE_OF_ICE,
  name: 'Облачение льда',
  nameEn: 'Investiture of Ice',
  description: `Пока действует заклинание, Ваше тело покрывается инеем, и Вы получаете следующие преимущества:

* Вы получаете иммунитет к урону холодом, а также сопротивление к урону огнём.
* Вы можете передвигаться по труднопроходимой местности, состоящей из льда или снега, не тратя дополнительное перемещение.
* Земля в радиусе 10 футов вокруг Вас покрывается льдом и является труднопроходимой местностью для всех существ, кроме вас. Эта область перемещается вместе с вами.
* Вы можете действием создать 15-футовый конус морозного ветра, исходящий из Вашей руки в выбранном направлении. Все существа в конусе должны пройти испытание Телосложения. При провале они получают урон холодом 4к6 или половину этого урона при успехе. Скорость существа, провалившего испытание, снижается вдвое до начала Вашего следующего хода.`,
  lvl: 6,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_COLD,
      halfOnSuccess: true,
      diceCount: 4,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 159,
    },
    {
      id: SOURCE_EE,
      page: 19,
    },
  ],
}
