const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../magicItemTypeList')
const {gearCollection} = require('./../../../gearList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    gearId,
    id,
  }
) => {
  const {
    name,
    nameByCase: {
      accusative,
    },
    nameEn: gearNameEn,
    genderId,
  } = gearCollection[gearId]

  const pronoun = {
    [GENDER_MALE]: 'этот',
    [GENDER_FEMALE]: 'эту',
    [GENDER_MIDDLE]: 'это',
    [GENDER_MULTIPLE]: 'эти',
  }[genderId]

  return {
    id,
    name: `${name} быстрого снятия`,
    nameEn: `Cast-Off ${gearNameEn}`,
    type: MGC_TYPE_ARMOR,
    gearType: gearId,
    rarity: MGC_RARITY_COMMON,
    description: `Вы можете снять ${pronoun} ${accusative.toLowerCase()} действием.`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  }
}
