import React from 'react'
import arrify from "arrify"
import PropTypes from 'prop-types';

import AdsAtDescription from '@/ads/components/AdsAtDescription'
import enrichWithAds from '@/utils/enrichWithAds'
import {infusionCollection} from '@/constants/infusionList'
import {spellCollection} from '@/constants/spellList'

import DescriptionListComponent from './DescriptionListComponent'

const DescriptionListContainer = ({createdBySpellId, createdByInfusionId, name, nameEn, description}) => {
  if (description || createdBySpellId || createdByInfusionId) {
    let list = []

    if (description) {
      list = arrify(description).map(
        item => typeof item === 'string'
          ? {
            header: name,
            subHeader: nameEn,
            text: item,
          }
          : item
      )
    }

    if (createdBySpellId) {
      list = [
        ...arrify(createdBySpellId).map(
          spellId => {
            const {name: spellName} = spellCollection[spellId]

            return {
              header: name,
              subHeader: nameEn,
              text: `${name} создаётся заклинанием «[${spellName}](SPELL:${spellId})».`,
            }
          }
        ),
        ...list,
      ]
    }

    if (createdByInfusionId) {
      list = [
        ...arrify(createdByInfusionId).map(
          infusionId => {
            const {name: infusionName} = infusionCollection[infusionId]

            return {
              header: name,
              subHeader: nameEn,
              text: `${name} создаётся инфузией «[${infusionName}](INFUSION:${infusionId})».`,
            }
          }
        ),
        ...list,
      ]
    }

    const listWithAds = enrichWithAds(list, AdsAtDescription)

    return (
      <DescriptionListComponent list={listWithAds}/>
    )
  }

  return null
}

DescriptionListContainer.propTypes = {
  createdBySpellId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  createdByInfusionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  nameEn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape({
      header: PropTypes.string,
      subHeader: PropTypes.string,
      text: PropTypes.string,
    }),
  ]),
}

DescriptionListContainer.defaultProps = {
  name: '',
  nameEn: '',
  description: '',
  createdBySpellId: '',
  createdByInfusionId: '',
}

export default DescriptionListContainer
