const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_UNITY_DOMAIN_2020_02_06} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_02_06_SUBCLASSES_PART_2} = require('./../../../sourceList')
const {
  SPELL_AID,
  SPELL_AURA_OF_PURITY,
  SPELL_BEACON_OF_HOPE,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_HEROISM,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_SENDING,
  SPELL_SHIELD_OF_FAITH,
  SPELL_WARDING_BOND,
} = require('./../../../spellIdList')
const {
  FEATURE_DIVINE_DOMAIN,
  FEATURE_UNITY_DOMAIN_SPELLS_2020_02_06,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_UNITY_DOMAIN_SPELLS_2020_02_06,
    name: `Заклинания домена`,
    nameEn: ` Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    pcSubClassId: PC_SUBCLASS_CLERIC_UNITY_DOMAIN_2020_02_06,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_AID,
      SPELL_AURA_OF_PURITY,
      SPELL_BEACON_OF_HOPE,
      SPELL_GREATER_RESTORATION,
      SPELL_GUARDIAN_OF_FAITH,
      SPELL_HEROISM,
      SPELL_RARY_S_TELEPATHIC_BOND,
      SPELL_SENDING,
      SPELL_SHIELD_OF_FAITH,
      SPELL_WARDING_BOND,
    ],
    source: {
      id: SOURCE_UA_2020_02_06_SUBCLASSES_PART_2,
      page: 2,
    },
  },
]
