const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  name: 'Защита от добра и зла',
  nameAlt: 'Защита от зла и от добра',
  nameEn: `Protection from Evil and Good`,
  description: `Пока заклинание активно, одно согласное существо, которого Вы коснулись, защищено от определённых типов существ: аберрации, исчадия, небожители, нежить, феи и элементали.

Защита предоставляет несколько преимуществ.

1. Существа выбранного вида совершают с помехой броски атаки по цели.
2. Цель также не может быть очарована, испугана и одержима ими.
3. Если цель уже очарована, испугана или одержима таким существом, цель проходит последующие испытания от таких эффектов с преимуществом.`,
  lvl: 1,
  magicSchoolId: MAGIC_ABJURATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: false,
  hasConsumable: true,
  materialText: `святая вода или порошок серебра и железа, расходуемые заклинанием`,
  duration: { timeId: TIME_MINUTE, count: 10 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 228,
    },
    {
      id: SOURCE_SRD,
      page: 173,
    },
  ],
}
