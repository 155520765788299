module.exports.BACKGROUND_ACOLYTE = 'acolyte'
module.exports.BACKGROUND_AGENT_EMERALD_ENCLAVE = 'agent_emerald_enclave'
module.exports.BACKGROUND_AGENT_HARPERS = 'agent_harpers'
module.exports.BACKGROUND_AGENT_LORDS_ALLIANCE = 'agent_lords_alliance'
module.exports.BACKGROUND_AGENT_ORDER_GAUNTLET = 'agent_order_gauntlet'
module.exports.BACKGROUND_AGENT_ZHENTARIM = 'agent_zhentarim'
module.exports.BACKGROUND_ANTHROPOLOGIST = 'anthropologist'
module.exports.BACKGROUND_ARCHAEOLOGIST = 'archaeologist'
module.exports.BACKGROUND_ATHLETE = 'athlete'
module.exports.BACKGROUND_CHARLATAN = 'charlatan'
module.exports.BACKGROUND_CITY_WATCH = 'city_watch'
module.exports.BACKGROUND_CLAN_CRAFTER = 'clan_crafter'
module.exports.BACKGROUND_CLOISTERED_SCHOLAR = 'cloistered_scholar'
module.exports.BACKGROUND_COURTIER = 'courtier'
module.exports.BACKGROUND_CRIMINAL = 'criminal'
module.exports.BACKGROUND_ENTERTAINER = 'entertainer'
module.exports.BACKGROUND_FAR_TRAVELLER = 'far_traveller'
module.exports.BACKGROUND_FOLK_HERO = 'folk_hero'
module.exports.BACKGROUND_GATE_WARDEN_2022_07_18 = 'gate_warden_2022_07_18'
module.exports.BACKGROUND_GIANT_FOUNDLING_2022_07_18 = 'giant_foundling_2022_07_18'
module.exports.BACKGROUND_GLADIATOR = 'gladiator'
module.exports.BACKGROUND_GUILD_ARTISAN = 'guild_artisan'
module.exports.BACKGROUND_GUILD_MERCHANT = 'guild_merchant'
module.exports.BACKGROUND_HERMIT = 'hermit'
module.exports.BACKGROUND_INHERITOR = 'inheritor'
module.exports.BACKGROUND_INVESTIGATOR = 'investigator'
module.exports.BACKGROUND_KNIGHT = 'knight'
module.exports.BACKGROUND_KNIGHT_OF_THE_ORDER = 'knight_of_the_order'
module.exports.BACKGROUND_MERCENARY_VETERAN = 'mercenary_veteran'
module.exports.BACKGROUND_NOBLE = 'noble'
module.exports.BACKGROUND_OUTLANDER = 'outlander'
module.exports.BACKGROUND_PIRATE = 'pirate'
module.exports.BACKGROUND_PLANAR_PHILOSOPHER_2022_07_18 = 'planar_philosopher_2022_07_18'
module.exports.BACKGROUND_RUNE_CARVER_2022_07_18 = 'rune_carver_2022_07_18'
module.exports.BACKGROUND_SAGE = 'sage'
module.exports.BACKGROUND_SAILOR = 'sailor'
module.exports.BACKGROUND_SOLDIER = 'soldier'
module.exports.BACKGROUND_SPY = 'spy'
module.exports.BACKGROUND_URBAN_BOUNTY_HUNTER = 'urban_bounty_hunter'
module.exports.BACKGROUND_URCHIN = 'urchin'
module.exports.BACKGROUND_UTHGARDT_TRIBE_MEMBER = 'uthgardt_tribe_member'
module.exports.BACKGROUND_WATERDHAVIAN_NOBLE = 'waterdhavian_noble'
