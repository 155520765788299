const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  ATTACK_RANGE_SPELL,
} = require('./../../../../../attackTypeList')
const {
  DAMAGE_ELECTRICITY,
} = require('./../../../../../damageTypeList')
const {
  SPELL_WITCH_BOLT,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_WITCH_BOLT,
  name: 'Ведьмин снаряд',
  nameEn: 'Witch Bolt',
  description: `Луч потрескивающей синеватой энергии устремляется к существу в пределах дистанции, формируя между Вами и целью непрерывный дуговой разряд. Совершите дальнобойную атаку заклинанием по этому существу. При попадании цель получает урон электричеством 1к12, и пока заклинание активно, Вы можете в каждый свой ход действием автоматически причинять цели урон электричеством 1к12. Это заклинание оканчивается, если Вы действием сделаете что-то иное. Заклинание также оканчивается, если цель окажется за пределами дистанции заклинания или получит от Вас полное укрытие. `,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, первичный урон увеличивается на 1к12 за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `веточка дерева, в которое ударила молния`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    attackType: ATTACK_RANGE_SPELL,
    damage: {
      type: DAMAGE_ELECTRICITY,
      diceCount: 1,
      diceType: 12,
    },
  },
  source: {
    id: SOURCE_PHB,
    page: 214,
  },
}
