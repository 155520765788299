const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../../../genderList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {gearCollection} = require('./../../../../../gearList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    gearId,
    id,
  }
) => {
  const {
    name,
    nameByCase: {
      nominative,
      instrumental,
    },
    nameEn,
    genderId,
  } = gearCollection[gearId]

  const {
    ignores,
    magical,
    pronounInstrumental,
    pronounNominative,
    vorpalName,
  } = {
    [GENDER_MALE]: {
      ignores: 'игнорирует',
      magical: 'магическим',
      pronounInstrumental: 'этим',
      pronounNominative: 'этот',
      vorpalName: 'головоруб',
    },
    [GENDER_FEMALE]: {
      ignores: 'игнорирует',
      magical: 'магической',
      pronounInstrumental: 'этой',
      pronounNominative: 'эта',
      vorpalName: 'головорубка',
    },
    [GENDER_MIDDLE]: {
      ignores: 'игнорирует',
      magical: 'магическим',
      pronounInstrumental: 'этим',
      pronounNominative: 'это',
      vorpalName: 'головоруб',
    },
    [GENDER_MULTIPLE]: {
      ignores: 'игнорируют',
      magical: 'магическими',
      pronounInstrumental: 'этими',
      pronounNominative: 'эти',
      vorpalName: 'головорубы',
    },
  }[genderId]

  return {
    id,
    name: `${name}-${vorpalName}`,
    nameEn: `Vorpal ${nameEn}`,
    rarity: MGC_RARITY_LEGENDARY,
    type: MGC_TYPE_WEAPON,
    gearType: gearId,
    description: `Вы получаете бонус +3 к броскам атаки и урона ${pronounInstrumental} ${magical} ${instrumental}. Кроме того, ${pronounNominative} ${nominative} игнорирует сопротивление к рубящему урону.

Если Вы атакуете ${pronounInstrumental} ${instrumental} существо, у которого есть как минимум одна голова, и при броске атаки у Вас выпадает «20», Вы отрубаете одну из голов чудовища. Существо умирает, если не может жить без головы. Существо обладает иммунитетом к этому эффекту, если обладает иммунитетом к рубящему урону, не имеет головы или не нуждается в ней, обладает легендарными действиями или Мастер решает, что существо слишком большое, чтобы Вы могли отрубить ему голову этим оружием. Такие существа вместо этого получают рубящий урон 6к8.`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 184,
    },
  }
}

module.exports = generator
