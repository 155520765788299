const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  fireGiantDescriptionList,
  fireGiantNote,
} = require('./../../../../textCommonParts')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_GIANT_FIRE,
  CREATURE_TYPE_GIANT,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_14} = require('./../../../../crList')
const {CREATURE_FIRE_GIANT_DREADNOUGHT} = require('./../../../../creatureIdList')
const {GEAR_PLATE_ARMOR} = require('./../../../../gearIdList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дредноут огненный великан',
  nameAlt: 'Дредноут огненный гигант',
  nameEn: 'Fire Giant Dreadnought',
  id: CREATURE_FIRE_GIANT_DREADNOUGHT,
  description: [
    `Уложение подчеркивает не только силу, но и кузнечное мастерство огненных великанов. Литейный цех является сердцем сообщества огненных великанов. Это храм, школа, испытательный полигон и политический центр, совмещённые вместе.

Те, у кого много силы, но мало мозгов, обычно назначаются на самые низшие задания, такие как работа на кузнечных мехах или подкидывание угля. Однако, существует роль, на которую могут претендовать лишь сильнейшие и получить титул: дредноут.`,
    {
      header: 'Орудия войны',
      text: `Дредноуты — это невероятно сильные огненные великаны, которые используют два огромных щита, как плут — клинки. На этих щитах снаружи находятся шипы, а внутри — пустоты, в которые дредноуты помещают горящие угли при первых признаках опасности. Вооруженный двумя щитами, дредноут представляет из себя огненную стену для любого нападающего. После боя с дредноутом от противника зачастую остаётся лишь дымящееся пятно на полу.

Не будучи на битве, дредноуты поддерживают физическую форму и используют щиты для копания огромного количества угля, камня или руды для литейного цеха. Иногда дредноутов призывают вышестоящие для сопровождения военной или дипломатической делегации. В любом случае, присутствие дредноутов создает устрашающее впечатление.`,
      source: {
        id: SOURCE_VGTM,
        page: 135,
      },
    },
    ...fireGiantDescriptionList,
  ],
  note: fireGiantNote,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_FIRE,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 135,
    },
    {
      url: 'https://5e.tools/bestiary.html#fire%20giant%20dreadnought_vgm',
      problemText: `В «Справочнике Воло по монстрам» группы переводов dungeonsanddragons.ru у «Дредноута огненного гиганта» неверно указана цель атаки «Огненным щитом» — одно существо вместо одной цели.`,
    },
  ],
  armor: {
    ac: 21,
    type: 'щиты',
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 27,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 23,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_14,
  featureList: [
    {
      name: 'Двойные щиты',
      description: `Великан носит два щита, каждый из которых учитывается в его КД. Великан должен положить или бросить один из щитов, чтобы бросать _Камни_.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Великан совершает две атаки _Огненным щитом_.`,
    },
    {
      name: 'Огненный щит',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 4,
            diceType: 6,
            diceBonus: 8,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 13,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Удар щитом с разбега',
      description: `Великан передвигается на расстояние до 30 футов по прямой линии и может двигаться сквозь пространство любого существа, которое меньше Огромного. Когда он в первый раз попадает в пространство существа во время такого движения, он делает атаку _Огненным щитом_ по этому существу. Если атака попадает, то цель также должна пройти испытание Силы СЛ 21 или великан будет толкать её впереди себя во время остального перемещения. Если существо проваливает испытание на 5 очков и более, то оно сбито с ног и получает 18 (3к6+8) дробящего урона или 29 (6к6+8) дробящего урона, если оно уже было сбито с ног.`,
    },
  ],
}
