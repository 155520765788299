const {
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_MAGNIFY_GRAVITY} = require('./../../../../../spellIdList')
const {TIME_ROUND} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MAGNIFY_GRAVITY,
  name: 'Гравитационная линза',
  nameEn: 'Усиление гравитации',
  description: `Гравитация ненадолго усиливается в сфере 10-футового радиуса с центром в указанной Вами точке. Все существа, находящиеся в пределах сферы в ход, на котором Вы сотворили это заклинание, должны пройти испытание Телосложения.
 
При провале существо получает 2к8 урона силовым полем и его скорость уменьшается вдвое. При успехе существо получает половину урона и его скорость не уменьшается.

До начала Вашего следующего хода для того чтобы поднять или передвинуть любой предмет в пределах сферы, который никто не несёт и не носит, нужно совершить проверку Силы.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_ROUND, count: 1 },
  isDunamisBased: true,
  effect: {
    savethrowParam: PARAM_STR,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 2,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_EGTW,
    page: 189,
  },
}
