const {
  SPELL_ARMS_OF_HADAR,
  SPELL_COUNTERSPELL,
  SPELL_DIMENSION_DOOR,
  SPELL_ELDRITCH_BLAST,
  SPELL_FEAR,
  SPELL_GUIDANCE,
  SPELL_HOLD_PERSON,
  SPELL_HUNGER_OF_HADAR,
  SPELL_INVISIBILITY,
  SPELL_MAGE_HAND,
  SPELL_MINOR_ILLUSION,
  SPELL_PRESTIDIGITATION,
  SPELL_UNSEEN_SERVANT,
  SPELL_VICIOUS_MOCKERY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_DEEP_SPEECH,
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_NEOGI,
} = require('./../../../../creatureTypeIdList')
const {
  neogiDescription,
  neogiNote,
} = require('./../../../../textCommonParts')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SOURCE_ERRATA_VGTM,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {ABILITY_SPIDER_CLIMB} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_NEOGI_MASTER} = require('./../../../../creatureIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')

module.exports = {
  name: 'Неоги повелитель',
  nameEn: 'Neogi Master',
  id: CREATURE_NEOGI_MASTER,
  description: neogiDescription,
  note: neogiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_NEOGI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 174,
    },
    {
      id: SOURCE_ERRATA_VGTM,
      title: 'Исправлены число и размер костей хитов',
      page: 1,
    },
  ],
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 14,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
      comment: 'прозревает магическую тьму',
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
    {
      id: LANG_TELEPATHY,
      range: 30,
    },
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Ментальная стойкость',
      description: `Неоги обладает преимуществом на испытания против очарования и испуга, и его невозможно магически усыпить.`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  spellCast: {
    spellCasterLevel: 7,
    spellCasterClass: PC_CLASS_WARLOCK,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_ELDRITCH_BLAST,
            comment: `дистанция 300 фт., +4 к каждому броску урона`,
          },
          SPELL_GUIDANCE,
          SPELL_MAGE_HAND,
          SPELL_MINOR_ILLUSION,
          SPELL_PRESTIDIGITATION,
          SPELL_VICIOUS_MOCKERY,
        ],
      },
      {
        preText: `1–4 уровни (2 ячейки 4-го уровня)`,
        list: [
          SPELL_ARMS_OF_HADAR,
          SPELL_COUNTERSPELL,
          SPELL_DIMENSION_DOOR,
          SPELL_FEAR,
          SPELL_HOLD_PERSON,
          SPELL_HUNGER_OF_HADAR,
          SPELL_INVISIBILITY,
          SPELL_UNSEEN_SERVANT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Неоги делает две атаки: одну _Укусом_ и одну _Когтями_.`,
    },
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения СЛ 12 или стать отравленной на 1 минуту. Цель может повторять испытание в конце каждого своего хода, окончивая этот эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 4,
            diceType: 6,
            diceBonus: 3,
          },
        ],
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Порабощение',
      comment: `перезаряжается после короткого или долгого отдыха`,
      description: `Неоги выбирает целью одно существо, которое может видеть, не далее 30 футов от себя. Цель должна пройти испытание Мудрости СЛ 14 или стать магически очарованной неоги на 1 день или пока неоги не умрёт или не окажется дальше, чем за 1 милю от цели. Очарованная цель подчиняется командам неоги и не может совершать реакции. Неоги и цель могут общаться телепатически друг с другом на расстоянии до 1 мили. Когда очарованная цель получает урон она повторяет испытание и оканчивает этот эффект на себе при успехе.`,
    },
  ],
}
