const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {ATTACK_MELEE_SPELL} = require('./../../../../../attackTypeList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_STEEL_WIND_STRIKE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_STEEL_WIND_STRIKE,
  name: 'Удар стального ветра',
  nameEn: 'Steel Wind Strike',
  description: `Вы взмахиваете оружием, используемым для заклинания, а затем исчезаете, чтобы ударить, подобно ветру. Выберите до пяти существ в пределах дистанции, которых Вы можете видеть. Совершите рукопашную атаку заклинанием по каждой цели. При попадании цель получает урон силовым полем 6к10.

После этого Вы можете телепортироваться в свободное пространство, которое Вы можете увидеть в пределах 5 футов от одной из целей, которую Вы атаковали, независимо от того, попали Вы по ней или промахнулись.`,
  lvl: 5,
  magicSchoolId: MAGIC_CONJURATION,
  range: 30,
  hasCost: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `оружие ближнего боя стоимостью не менее 1 см`,
  effect: {
    attackType: ATTACK_MELEE_SPELL,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 6,
      diceType: 10,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 170,
  },
}
