const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_VEGEPYGMY} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_VEGEPYGMY,
  nameEn: 'Vegepygmy',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'плесневик',
      genitive: 'плесневика',
      dative: 'плесневику',
      accusative: 'плесневика',
      instrumental: 'плесневиком',
      prepositional: 'плесневике',
    },
    plural: {
      nominative: 'плесневики',
      genitive: 'плесневиков',
      dative: 'плесневикам',
      accusative: 'плесневиков',
      instrumental: 'плесневиками',
      prepositional: 'плесневиках',
    },
  },
}
