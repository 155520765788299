const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_KEEN_SMELL,
  ABILITY_SPIDER_CLIMB,
} = require('./../../../../creatureAbilityList')
const {
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_CARRION_CRAWLER,
  CREATURE_OTYUGH,
} = require('./../../../../creatureIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Ползающий падальщик',
  nameEn: 'Carrion Crawler',
  id: CREATURE_CARRION_CRAWLER,
  description: [
    {
      header: 'Ползающие падальщики',
      text: `Ползающие падальщики объедают гнилую плоть с трупов, а потом обгладывают оставшиеся кости. Они агрессивно атакуют любое существо, которое зашло на их территорию или беспокоит их пиршество.`,
      source: {
        id: SOURCE_MM,
        page: 240,
      },
    },
    {
      header: 'Пожиратели падали',
      text: `Падальщик следует за запахом смерти к своей еде, но предпочитает не конкурировать с другими мусорщиками, поэтому обитают на территориях, где царствует смерть, но другие падальщики туда не могут проникнуть. Пещеры, водостоки, подземелья и лесистые болота являются их излюбленными логовами, но падальщики также появляются на полях сражений и местах захоронения.

На охоте падальщик бесцельно блуждает, зондируя воздух щупальцами в поисках запаха крови или разложения. В туннелях или руинах, падальщики передвигаются по потолку на пути к еде. Так они избегают контакта со слизями, [отиджами](CREATURE:${CREATURE_OTYUGH}) и другими опасными обитателями тьмы, а также застают врасплох потенциальную пищу, которая и не думает посмотреть наверх.`,
      source: {
        id: SOURCE_MM,
        page: 240,
      },
    },
    {
      header: 'Терпеливые хищники',
      text: `И в подземной темноте и во время охоты в ночное время, свет сигнализирует о потенциальной еде. Падальщик может следовать за далёким источником света в течение нескольких часов, в надежде учуять запах крови. Несмотря на большие размеры, падальщики могут также легко устраивать засады, ожидая добычу в тёмных закоулках, пока добыча не приблизится к ним.

При столкновении с потенциальной добычей или захватчиком, падальщик позволяет своему яду делать его работу. Как только жертву парализует, падальщик охватывает её своими щупальцами и тащит прочь к высокому выступу или изолированному проходу, где может убить её в безопасности. Затем чудовище возобновляет патрулирование своей территории, ожидая, пока его еда созреет.`,
      source: {
        id: SOURCE_MM,
        page: 240,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 240,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_KEEN_SMELL,
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Щупальцами_, и одну _Укусом_.`,
    },
    {
      name: `Щупальца`,
      description: `Цель должна пройти испытание Телосложения СЛ 13, иначе станет отравленной на 1 минуту. Пока цель отравлена, она парализована. Цель может повторять это испытание в конце каждого своего хода, оканчивая отравление на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_POISON,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
