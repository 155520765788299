module.exports = [
  require('./eagle'),
  require('./earth_elemental'),
  require('./efreeti'),
  require('./elephant'),
  require('./elk'),
  require('./empyrean'),
  require('./erinyes'),
  require('./ettercap'),
  require('./ettin'),
]
