const upLetter = require('./../../../../../../utils/upLetter')
const {creatureTypeCollection} = require('./../../../../../creatureTypeList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_SCROLL} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    creatureTypeId,
    id,
    nameEn,
  }
) => {
  const {
    genderId,
    name: {
      plural: {genitive, dative},
      singular,
    },
  } = creatureTypeCollection[creatureTypeId]
  const {nominative, accusative} = singular[genderId]

  const pronoun = genderId === GENDER_MALE
    ? 'этого конкретного'
    : genderId === GENDER_FEMALE
      ? 'эту конкретную'
      : 'это конкретное'

  return {
    id,
    name: `Свиток защиты от ${genitive}`,
    nameEn,
    type: MGC_TYPE_SCROLL,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `Прочитав действием свиток, Вы окружаете себя невидимым барьером, формирующим цилиндр с радиусом 5 футов и высотой 10 футов. В течение 5 минут этот барьер препятствует **${dative}** входить в цилиндр и каким-либо образом воздействовать на то, что заключено в него.

Этот цилиндр перемещается вместе с Вами, оставаясь с центром на Вас. Однако, если Вы перемещаетесь так, что **${nominative}** окажется внутри цилиндра, защитный эффект заканчивается.

**${upLetter(nominative)}** может попытаться преодолеть барьер, совершив действием проверку Харизмы СЛ 15. При успехе барьер на ${pronoun} **${accusative}** не действует.`,
    genderId: GENDER_MALE,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 200,
    },
  }
}

module.exports = generator
