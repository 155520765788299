const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_4,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_GNOLL,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_ABYSSAL,
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_HIDE_ARMOR,
  } = require('./../../../../gearIdList'),
  {
    gnollDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_RAMPAGE,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_GNOLL_FANG_OF_YEENOGHU,
    CREATURE_HYENA,
    CREATURE_YEENOGHU,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гнолл клык Йеногу',
  nameEn: 'Gnoll Fang of Yeenoghu',
  id: CREATURE_GNOLL_FANG_OF_YEENOGHU,
  description: [
    `Празднование побед у гноллов сопровождается демоническими ритуалами и кровавыми приношениями [Йеногу](CREATURE:${CREATURE_YEENOGHU}). Иногда демонический повелитель награждает своих последователей, делая одного из них одержимым демоном. Помеченный Йеногу счастливчик становится «клыком Йеногу» — избранным Властелина Гноллов. [Гиена](CREATURE:${CREATURE_HYENA}), пирующая врагом, которого поразил клык, подвергается ужасным трансформациям, становясь взрослым гноллом. Именно так Йеногу сотворил первых гноллов. Клык Йеногу может значительно увеличить популяцию гноллов в зависимости от количества [гиен](CREATURE:${CREATURE_HYENA}) в округе. Единственный способ это предотвратить — убить клыка.`,
    ...gnollDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_GNOLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 61,
  },
  armor: {
    ac: 14,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 15,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
  ],
  cr: CR_4,
  featureList: [
    ABILITY_RAMPAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, и две _Когтями_.`,
    },
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения СЛ 12, иначе получит урон ядом 7 (2к6).`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
