const {
    SOURCE_XGTE,
  } = require('./../../../../sourceList'),
  {
    SIZE_TINY,
  } = require('./../../../../sizeList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CREATURE_TYPE_CONSTRUCT,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_TINY_SERVANT,
  } = require('./../../../../spellIdList'),
  {
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    CREATURE_TINY_SERVANT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Крошечный слуга',
  nameEn: 'Tiny Servant',
  id: CREATURE_TINY_SERVANT,
  createdBySpellId: SPELL_TINY_SERVANT,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_XGTE,
    page: 155,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 10,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  actionList: [
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
