const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {SPELL_SHADOW_OF_MOIL} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SHADOW_OF_MOIL,
  name: 'Облачение тени',
  nameEn: 'Shadow of Moil',
  description: `Пламевидные тени окутывают Ваше тело на всё время действия заклинания, в результате чего Вы становитесь сильно заслонённым для других. Тени превращают тусклый свет в пределах 10 футов от Вас в темноту, а яркий свет в той же области — в тусклый свет.

Вы получаете сопротивление к урону излучением до окончания действия заклинания. Кроме того, всякий раз, когда существо, находящееся в пределах 10 футов от вас, попадает по Вам рукопашной атакой, тени атакуют его, причиняя урон некротической энергией 2к8.`,
  lvl: 4,
  magicSchoolId: MAGIC_NECROMANCY,
  range: -1,
  hasCost: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `глазное яблоко нежити, заключенное в драгоценный камень стоимостью не менее 150 зм`,
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    damage: {
      type: DAMAGE_NECROTIC,
      diceCount: 2,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 159,
  },
}
