const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_CLIMB,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_NECROTIC,
} = require('./../../../../damageTypeList')
const {
  CREATURE_DEMON_SPIRIT,
  CREATURE_DEMON_SPIRIT_2020_03_26,
  CREATURE_YUGOLOTH_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух демона',
  nameEn: 'Demon Spirit',
  id: CREATURE_DEMON_SPIRIT_2020_03_26,
  releasedAs: CREATURE_DEMON_SPIRIT,
  parentId: CREATURE_YUGOLOTH_SPIRIT_2020_03_26,
  alignmentId: ALIGNMENT_CE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 1,
          diceType: 12,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  extendPropCollection: {
    speed: {
      [SPEED_CLIMB]: 40,
    },
    featureList: [
      {
        name: 'Смертная мука',
        description: `Когда его хиты опускаются до 0 или когда заклинание оканчивается, ★СУЩЕСТВО★ взрывается. Каждое существо в пределах 10 футов от него должно пройти испытание Ловкости против сложности заклинаний заклинателя. Оно получает урон огнём 2к10 + круг заклинания при провале, или половину этого урона при успехе.`,
      },
    ],
  },
}
