const {CREATURE_TYPE_LIZARDFOLK} = require('./../../creatureTypeIdList')
const {
  lizardfolkNotes,
  lizardfolkDescriptionList,
} = require ('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_LIZARDFOLK,
  nameEn: 'Lizardfolk',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'людоящер',
        genitive: 'людоящера',
        dative: 'людоящеру',
        accusative: 'людоящера',
        instrumental: 'людоящером',
        prepositional: 'людоящере',
      },
      [GENDER_FEMALE]: {
        nominative: 'людоящерица',
        genitive: 'людоящерицы',
        dative: 'людоящерице',
        accusative: 'людоящерицу',
        instrumental: 'людоящерицей',
        prepositional: 'людоящерице',
      },
    },
    plural: {
      nominative: 'людоящеры',
      genitive: 'людоящеров',
      dative: 'людоящерам',
      accusative: 'людоящеров',
      instrumental: 'людоящерами',
      prepositional: 'людоящерах',
    },
  },
  note: lizardfolkNotes,
  description: lizardfolkDescriptionList,
}
