const {
    SOURCE_XGTE,
  } = require('./../../../../sourceList'),
  {
    SPELL_DRUID_GROVE,
  } = require('./../../../../spellIdList'),
  {
    CREATURE_AWAKENED_TREE,
    CREATURE_GROVE_GUARDIAN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Хранитель рощи',
  nameEn: 'Grove Guardian',
  id: CREATURE_GROVE_GUARDIAN,
  createdBySpellId: SPELL_DRUID_GROVE,
  parentId: CREATURE_AWAKENED_TREE,
  languageList: null,
  extendPropCollection: {
    source: [{
      id: SOURCE_XGTE,
      page: 165,
    }],
  },
}
