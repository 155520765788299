const {
  SOURCE_EGTW,
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2016_08_01_THE_FAITHFUL,
  SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
  SOURCE_UA_2017_03_20_WIZARD_REVISITED,
  SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
  SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
  SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
  SOURCE_UA_2022_05_22_GIANT_OPTIONS,
  SOURCE_XGTE,
} = require('./../../sourceList')

const {
  GEAR_CHAIN,
  GEAR_FLAIL,
  GEAR_HANDAXE,
  GEAR_LONGSWORD,
  GEAR_SCIMITAR,
  GEAR_SHORTSWORD,
  GEAR_WAR_PICK,
  GEAR_WHIP,
} = require('./../../gearIdList')

const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')
const {
  PC_RACE_ELF,
  PC_RACE_HALF_ELF,
} = require('./../../pcRaceIdList')
const {
  dunamancyDescription,
  dunamisDescriptionList,
} = require('./../../textCommonParts')

const {
  PC_SUBCLASS_WIZARD_BLADESINGING,
  PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC,
  PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC,
  PC_SUBCLASS_WIZARD_LORE_MASTERY_2017_02_13,
  PC_SUBCLASS_WIZARD_ONOMANCY_2019_10_03,
  PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES,
  PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES_2020_05_12,
  PC_SUBCLASS_WIZARD_PSIONICS_2019_11_25,
  PC_SUBCLASS_WIZARD_RUNECRAFTER_2022_05_22,
  PC_SUBCLASS_WIZARD_SCHOOL_OF_ABJURATION,
  PC_SUBCLASS_WIZARD_SCHOOL_OF_CONJURATION,
  PC_SUBCLASS_WIZARD_SCHOOL_OF_DIVINATION,
  PC_SUBCLASS_WIZARD_SCHOOL_OF_ENCHANTMENT,
  PC_SUBCLASS_WIZARD_SCHOOL_OF_EVOCATION,
  PC_SUBCLASS_WIZARD_SCHOOL_OF_ILLUSION,
  PC_SUBCLASS_WIZARD_SCHOOL_OF_NECROMANCY,
  PC_SUBCLASS_WIZARD_SCHOOL_OF_TRANSMUTATION,
  PC_SUBCLASS_WIZARD_THEURGY_2016_08_01,
  PC_SUBCLASS_WIZARD_THEURGY_2017_03_20,
  PC_SUBCLASS_WIZARD_WAR_MAGIC,
  PC_SUBCLASS_WIZARD_WAR_MAGIC_2017_03_20,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_WIZARD_BLADESINGING,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Песнь клинка',
    nameEn: 'Bladesinging',
    description: [
      `Певцами клинка становятся эльфы, решившие отважно защищать свой народ и земли.

Обычно, это эльфийские волшебники, которые достигли мастерства в школе боя, основанной на владении клинком и тайной магией. В бою певец клинка использует ряд сложных, но элегантных манёвров, которые отражают возможный урон и позволяют использовать магию для разрушительных атак и ловкой защиты.`,
      {
        header: `Ограничение: Только для эльфов`,
        text: `Только [эльфы](PC_RACE:${PC_RACE_ELF}) и [полуэльфы](PC_RACE:${PC_RACE_HALF_ELF}) могут выбрать эту магическую традицию. В Забытых Королевствах эльфы строго хранят секреты песни клинка.

Ваш Мастер может снять это ограничение, если посчитает что так будет лучше для его кампании. Это ограничение отражает историю певцов клинка Забытых Королевств и может не применяться в сеттинге вашего Мастера или его версии Забытых Королевств.`,
        source: {
          id: SOURCE_SCAG,
          page: 142,
        },
      },
      {
        header: `Стили Песни клинка`,
        text: `Проистекая из искусств боя и магии, Песнь клинка тесно связана с мечами, а точнее — с [длинными мечами](GEAR:${GEAR_LONGSWORD}). Впрочем, многие поколения изучения дали толчок для развития разных стилей песни клинка, основанных на различном оружии. Техники этих стилей передаются от учителя к ученикам в маленьких школах, из которых лишь некоторые являются зданиями в прямом понимании. Даже самым молодым стилям сотни лет, но их всё ещё преподают их основатели (из-за долгих лет эльфийских жизней). Большинство школ песни клинка находится на Эвермите или Эвереске. Одна была расположена в Миф Дранноре, но падение города рассеяло выживших её учеников.

Стили Песни клинка делятся на широкие категории, основанные на виде применяемого оружия, и каждый из них связан с группой животных одного вида. В рамках категории специализации названы по отдельным видам животных, основываясь на типах применяемых заклятий, технике мастера и типе его оружия. Певцы клинка, достигающие уровня мастера, получают тату животного, представляющего их стиль. Некоторые певцы клинка изучают несколько стилей и имеют множество татуировок, предупреждающих всякого об их смертельных навыках.

# Кошка

Стили, в которых применяются мечи, принадлежат к этому семейству. Мастера стиля льва, старейшего из стилей, применяют [длинные мечи](GEAR:${GEAR_LONGSWORD}) и не отдают предпочтения каким-то определённым заклятиям. Стиль леопарда концентрируется на [коротком мече](GEAR:${GEAR_SHORTSWORD}) и заклинаниях иллюзии и скрытности. Стиль красного тигра, которому три сотни лет, использует [скимитар](GEAR:${GEAR_SCIMITAR}) в стремительном танце защиты и внезапных наскоков и атак.

# Птица

Стили, в которых применяется древковое оружие, такое как топоры или молоты, собраны под крылом этого семейства, но всё же они сильно разнятся. Все они относительно новые и используют оружие, не характерное для эльфов. Певцы клинка орлиного стиля используют [ручные топоры](GEAR:${GEAR_HANDAXE}) и множество манёвров, которые позволяют им плавными движениями метать оружие, доставая новое. Стиль ворона использует [кирку](GEAR:${GEAR_WAR_PICK}) и заклятия, которые дают певцу клинка большую подвижность и ловкость в бою.

# Змея

Практики этого стиля включают в себя [кистень](GEAR:${GEAR_FLAIL}), [цепь](GEAR:${GEAR_CHAIN}) и [кнут](GEAR:${GEAR_WHIP}). Стиль гадюки использует [кнут](GEAR:${GEAR_WHIP}), не смотря на неэлегантность этого оружия, и имеет почти такую же долгую историю, как и стиль льва. Мастера этого стиля задают ритм своей песне клинка невероятно быстрыми ударами [кнута](GEAR:${GEAR_WHIP}), которые держат противника на расстоянии и дают певцу пространство для сотворения жестоких заклинаний яда и болезней, предпочитаемых в этом стиле.`,
        source: {
          id: SOURCE_SCAG,
          page: 143,
        },
      },
    ],
    note: {
      text: `Некоторые волшебники, сталкивающиеся с ошеломляющим потоком магических возможностей, переживают кризис личности. Некоторые превозмогают, другие ломаются, а третьи становятся мече-бардами.`,
      author: `Таша`,
    },
    source: [
      {
        id: SOURCE_SCAG,
        page: 142,
      },
      {
        id: SOURCE_TCoE,
        page: 114,
      },
    ],
  },
  {
    id: PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Хронургия',
    nameEn: 'Chronurgy Magic',
    description: [
      {
        text: `Те, кто следует традициям Хронургии, сосредотачиваются на манипуляции временем. Они учатся изменять движение реальности по своему желанию. Используя бурление ожидания энергии _динамия_, эти маги мгновенно создают преимущество для себя и своих союзников. Они могут искривлять поток времени так же ловко, как опытные музыканты играют на инструментах.`,
        source: {
          id: SOURCE_EGTW,
          page: 185,
        },
      },
      ...dunamisDescriptionList,
      dunamancyDescription,
    ],
    source: {
      id: SOURCE_EGTW,
      page: 185,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Гравитургия',
    nameEn: 'Graviturgy Magic',
    description: [
      {
        text: `Изучая и овладевая силами, что тянут материальные тела друг к другу или, наоборот, разлучают их, студенты волшебной традиции Гравитургии учатся изгибать бессердечную энергию гравитации и направлять её на пользу себе и на неописуемый вред своим врагам.`,
        source: {
          id: SOURCE_EGTW,
          page: 185,
        },
      },
      ...dunamisDescriptionList,
      dunamancyDescription,
    ],
    source: {
      id: SOURCE_EGTW,
      page: 186,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_LORE_MASTERY_2017_02_13,
    pcClassId: PC_CLASS_WIZARD,
    releasedAs: PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES,
    isReady: false,
    name: 'Владение знаниями',
    nameEn: 'Lore Mastery',
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 5,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_ONOMANCY_2019_10_03,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Ономантия',
    nameEn: 'Onomancy',
    description: [
      `Практики магии хорошо знают силу имён, но волшебники, следующие традиции Ономантии, используют свою магию, чтобы манипулировать словами, которые охватывают всё существование. Ономанты расширяют свои исследования на сам язык, ища нити магического значения, пронизывающие имена.

Всё то, что названо, выделяется в мультивселенной, отличной от ткани творения вокруг него. Это различие создает силу, которую стремятся использовать ономанты. Произнося истинное имя цели, заклинания волшебника проскальзывают между трещинами защиты цели в соответствии с её сущностью, силой её имени.

Чтобы защитить себя, волшебники, которые следуют этой традиции, часто скрывают свои истинные имена, обычно принимая псевдонимы и псевдонимы.`,
      {
        header: `Истинные имена`,
        text: `Ономантия, или магия называния, — это способ сотворения заклинаний, который использует истинное имя существа для усиления эффектов заклинания. Истинное имя — это имя, которым самоосознающее существо идентифицирует себя. Это имя может быть именем, которое было дано ему при рождении, или именем, которое оно выбрало или заработало позже в жизни. Независимо от происхождения имени, самый простой способ узнать своё истинное имя — это подумать правдиво о себе и затем подумать: _«Меня зовут…»_. Ваше настоящее имя — то, как Вы заканчиваете это предложение.

Вы можете попытаться скрыть своё настоящее имя, используя псевдоним, но Вы должны быть осторожны, чтобы не заселить это ложное имя слишком глубоко. Если ложное имя становится лучшим выражением того, кем Вы являетесь, оно становится Вашим настоящим именем. Изменение настоящего имени никогда не происходит быстро; на это требуется время, когда имя становится истинным для существа. У существа есть настоящее имя, если оно понимает хотя бы один язык или имеет мировоззрение.`,
        source: {
          id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
          page: 4,
        },
      },
    ],
    abandonedAt: {
      id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
      page: 5,
    },
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 4,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Орден писцов',
    nameEn: 'Order of Scribes',
    description: `«Книжная магия» — именно так многие зовут искусство волшебства. Название вполне уместно, учитывая количество времени, которое волшебники тратят на изучение фолиантов и записывание теорий о природе магии. Редко можно увидеть волшебников, путешествующих без торчащих из сумок книг и свитков. Волшебник обычно пойдёт на всё, чтобы докопаться до архива древних знаний.

Среди волшебников Орден писцов — самый книжный. Он принимает разные формы в разных мирах, но его основная задача везде одинакова: записывать магические открытия, чтобы волшебство могло процветать. И пока обычные волшебники ценят книги заклинаний, волшебник Ордена писцов волшебным образом пробуждает свою книгу, превращая её в надёжную спутницу. Все волшебники изучают книги, но волшебный писец с своими книгами разговаривает!`,
    note: {
      text: `Магия — это круто и всё такое, но ты хоть раз нюхал книгу?`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 116,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES_2020_05_12,
    pcClassId: PC_CLASS_WIZARD,
    releasedAs: PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES,
    isReady: false,
    name: 'Орден писцов',
    nameEn: 'Order of Scribes',
    source: {
      id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
      page: 4,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_PSIONICS_2019_11_25,
    pcClassId: PC_CLASS_WIZARD,
    isReady: false,
    name: 'Псионика',
    nameEn: 'Psionics',
    abandonedAt: {
      id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
      page: 5,
    },
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_SCHOOL_OF_ABJURATION,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Школа Ограждения',
    nameAlt: 'Школа Преграждения',
    nameEn: 'School of Abjuration',
    description: [
      {
        header: 'Школа Ограждения',
        text:  `Школа Ограждения делает упор на магию блокировки, изгнания и защиты. Противники этой школы говорят, что её традиции в отрицании, а не в положительном утверждении. Однако Вы понимаете, что прекращение действия ужасных эффектов, защита слабых и воздаяние злу может быть чем угодно, но только не философской пустотой. Это гордое и уважаемое призвание.

Называемые оградителями, члены это школы становятся востребованными, когда злобные духи нуждаются в изгнании, когда важные места должны быть защищены от магического шпионажа, и когда порталы на другие планы бытия должны быть закрыты.`,
        source: {
          id: SOURCE_PHB,
          page: 118,
        },
      },
      {
        header: 'Преграждение',
        text: 'Заклинания преграждения используют для призыва защитников, изгнания врагов и рассеивания магических чар. Используют для себя и союзников.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 118,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_SCHOOL_OF_CONJURATION,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Школа Вызова',
    nameEn: 'School of Conjuration',
    description: [
      {
        header: 'Школа Вызова',
        text:  `Являясь вызывателем, Вы предпочитаете заклинания, которые создают предметы и существ из воздуха.

Вы можете силой мысли вызвать клубящиеся облака убийственного тумана или призвать откуда угодно существ для битвы на своей стороне. С ростом мастерства Вы узнаёте заклинания перемещения и сможете в мгновение ока телепортироваться на огромные расстояния, и даже на другие планы существования.`,
        source: {
          id: SOURCE_PHB,
          page: 117,
        },
      },
      {
        header: 'Вызов',
        text: 'Вас завораживают формы и движение и ваш любимый стиль волшебства — призывать существ и предметы и перемещать их в пространстве.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_SCHOOL_OF_DIVINATION,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Школа Прорицания',
    nameEn: 'School of Divination',
    description: [
      {
        header: 'Школа Прорицания',
        text:  `Советник-прорицатель востребован у знати и простолюдинов, так как все хотят знать о прошлом, настоящем и будущем.

Являясь прорицателем, Вы стремитесь снять покровы пространства, времени и сознания, чтобы видеть более ясно. Вы работаете над освоением заклинаний проницательности, удалённого наблюдения, сверхъестественного знания и предвидения.`,
        source: {
          id: SOURCE_PHB,
          page: 120,
        },
      },
      {
        header: 'Прорицание',
        text: 'Ваш взгляд проникает через само время, и Вы способны лепить будущее, как податливую глину, придавая ему более благоприятную форму.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 120,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_SCHOOL_OF_ENCHANTMENT,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Школа Очарования',
    nameEn: 'School of Enchantment',
    description: [
      {
        header: 'Школа Очарования',
        text:  `В качестве адепта школы Очарования, Вы знаете, как с помощью магии заворожить и обмануть других существ и чудовищ.

Некоторые обаятели являются миротворцами, подчиняющими враждебных существ, чтобы те сложили оружие или чтобы жестокие проявили милосердие. Другие чародеи являются тиранами, завораживающими не желающих идти к ним на службу. Существует также множество других обаятелей, представляющих нечто среднее.`,
        source: {
          id: SOURCE_PHB,
          page: 119,
        },
      },
      {
        header: 'Очарование',
        text: 'Ваша магия влияет на окружающих, завораживает их, подчиняет их волю и меняет убеждения.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 119,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_SCHOOL_OF_EVOCATION,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Школа Воплощения',
    nameAlt: 'Школа Эвокации',
    nameEn: 'School of Evocation',
    description: [
      {
        header: 'Школа Воплощения',
        text:  `Вы сосредоточили свои исследования на магии, которая создаёт могущественные стихийные эффекты, такие как ледяной холод, жгучее пламя, раскатистый гром, трещащие молнии и едкая кислота.

Некоторые боевые маги находят своё призвание в армии, идя на службу в артиллерию и уничтожая вражеские армии издалека.

Другие используют свои впечатляющие возможности, чтобы защищать слабых. Остальные же ищут свою собственную выгоду на поприще бандита, искателя приключений или начинающего тирана.`,
        source: {
          id: SOURCE_PHB,
          page: 116,
        },
      },
      {
        header: 'Эвокация',
        text: 'Заклинания эвокации превращают энергию стихий в мощные атаки и чары. Адепты данной школы называются «эвокаторы».',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: [
      {
        id: SOURCE_PHB,
        page: 116,
      },
      {
        id: SOURCE_SRD,
        page: 54,
      },
    ],
  },
  {
    id: PC_SUBCLASS_WIZARD_SCHOOL_OF_ILLUSION,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Школа Иллюзии',
    nameEn: 'School of Illusion',
    description: [
      {
        header: 'Школа Иллюзии',
        text:  `Вы обучаетесь магии, которая обманывает чувства, затмевает разум и дурачит даже самых мудрых.

Ваша магия утончённа, но иллюзии, сотканные Вашим острым умом, заставляют казаться реальным невозможное. Некоторые иллюзионисты — включая многих гномов-волшебников — являются трюкачами, использующими свои заклинания в представлениях.

Другие, более зловещие мастера обмана, используют свои иллюзии, чтобы пугать и обманывать других для собственной выгоды.`,
        source: {
          id: SOURCE_PHB,
          page: 117,
        },
      },
      {
        header: 'Иллюзия',
        text: 'Ваша специальность — расплетать и перешивать ткань реальности, чтобы одурачивать невнимательных, легковерных и ужасных.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_SCHOOL_OF_NECROMANCY,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Школа Некромантии',
    nameEn: 'School of Necromancy',
    description: [
      {
        header: 'Школа Некромантии',
        text: `Школа Некромантии изучает вселенские силы жизни, смерти и не-жизни.

Если Вы сосредоточите свои изыскания в этом направлении, то узнаете, как манипулировать этой энергией, которая питает все живые организмы. По мере увеличения Ваших знаний Вы научитесь вытягивать жизненные силы существ, разрушая своей магией их тела, трансформируя их в магическую энергию, которой можете манипулировать.

Большинство обычных людей видит в некромантах угрозу или даже вселенское зло, благодаря их тесной связи со смертью. Но не все некроманты злые. Просто силы, которыми они управляют, являются табу для общества.`,
        source: {
          id: SOURCE_PHB,
          page: 118,
        },
      },
      {
        header: 'Некромантия',
        text: 'Вы идёте долиной смертной тени и находите там множество друзей.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 118,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_SCHOOL_OF_TRANSMUTATION,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Школа Преобразования',
    nameAlt: 'Школа Превращения',
    nameEn: 'School of Transmutation',
    description: [
      {
        header: 'Школа Преобразования',
        text:  `Вы были обучены заклинаниям, изменяющим энергию и материю.

Для Вас мир не является чем-то постоянным, напротив, он в высшей степени изменчив, и Вы наслаждаетесь своей ролью агента перемен. Вы умеете манипулировать сырьём создания и можете изменять как физическую форму, так и ментальные качества. Ваша магия даёт Вам возможность стать кузнецом в кузне реальности.

Некоторые преобразователи являются проказниками, превращающими людей в жаб и преобразующими медь в серебро ради забавы и случайной наживы. Другие проводят свои магические исследования со всей возможной серьёзностью, ища силу богов, чтобы создавать и разрушать миры.`,
        source: {
          id: SOURCE_PHB,
          page: 119,
        },
      },
      {
        header: 'Превращение',
        text: 'Ваше врождённое увлечение структурой вещей дало Вам невероятную власть над грубой материей и способность манипулировать ею.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 119,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_THEURGY_2017_03_20,
    pcClassId: PC_CLASS_WIZARD,
    isReady: false,
    name: 'Теургия',
    nameEn: 'Theurgy',
    source: {
      id: SOURCE_UA_2017_03_20_WIZARD_REVISITED,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_THEURGY_2016_08_01,
    pcClassId: PC_CLASS_WIZARD,
    releasedAs: PC_SUBCLASS_WIZARD_THEURGY_2017_03_20,
    isReady: false,
    name: 'Теургия',
    nameEn: 'Theurgy',
    source: {
      id: SOURCE_UA_2016_08_01_THE_FAITHFUL,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_WAR_MAGIC,
    pcClassId: PC_CLASS_WIZARD,
    isReady: true,
    name: 'Военная магия',
    nameEn: 'War Magic',
    description: `Разнообразные магические коллегии специализируются в подготовке волшебников к войне. Традиция военной магии сочетает в себе принципы воплощения и ограждения, не специализируясь в какой-то из этих школ. Она учит техникам, усиливающим заклинания, также предлагая волшебникам методы поддержки своей защиты.

Последователи этой традиции известны как военные маги. Они рассматривают свою магию и как оружие, и как броню, средство мощнее любого куска металла. В бою военные маги действуют быстро, используя свои заклинания для получения тактического контроля над ситуацией. Их заклинания бьют сильно, а их защита расстраивает попытки противников контратаковать. Военные маги также знатоки использования магической энергии своих противников против них самих.

В великих битвах военный маг часто работает с воплотителями, оградителями и другими волшебниками. Воплотители иногда дразнят военных магов, из-за того, что те распыляются между нападением и защитой. Обычный ответ военного мага: _«Что хорошего в том, чтобы бросить огромный огненный шар, если я умру пока смогу его бросить?»_`,
    note: {
      text: `Военная магия? Это делает тебя Военным Волшебником? Я слышал о них. По-моему, они одеваются в пурпур, но ты не в пурпурном. Досадно. Иди переоденься.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 25,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_WAR_MAGIC_2017_03_20,
    pcClassId: PC_CLASS_WIZARD,
    releasedAs: PC_SUBCLASS_WIZARD_WAR_MAGIC,
    isReady: false,
    name: 'Военная магия',
    nameEn: 'War Magic',
    source: {
      id: SOURCE_UA_2017_03_20_WIZARD_REVISITED,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_WIZARD_RUNECRAFTER_2022_05_22,
    pcClassId: PC_CLASS_WIZARD,
    isReady: false,
    name: 'Рунный мастер',
    nameEn: 'Runecrafter',
    source: {
      id: SOURCE_UA_2022_05_22_GIANT_OPTIONS,
      page: 3,
    },
  },
]
