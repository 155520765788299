const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../magicItemRarityList')
const {
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_BELL_BRANCH,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_BELL_BRANCH,
  name: `Ветка с колокольчиками`,
  nameEn: `Bell Branch`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  attunementComment: `друидом или колдуном`,
  description: `Эта вещь выглядит как серебряная ветка дерева, украшенная маленькими золотыми колокольчиками.

Держа _Ветку с колокольчиками_, Вы можете использовать её как заклинательную фокусировку для Ваших заклинаний.

У _Ветки_ есть 3 заряда и она восстанавливает 1к3 заряда на рассвете. Держа её, Вы можете использовать её заряды следующими способами.

* Вы можете бонусным действием потратить 1 заряд, чтобы обнаружить присутствие аббераций, небожителей, конструктов, элементалей, фей, исчадий или нежити в пределах 60 футов, не имеющих от Вас полного укрытия. Если такие существа есть поблизости, колокольчики начинают мягко позванивать, а их тон указывает на конкретный тип существ.
* Вы можете действием потратить 1 заряд и сотворить заклинание [Защита от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}).`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_TCoE,
    page: 176,
  },
}
