const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_FOLDING_BOAT,
  MAGIC_ITEM_FROST_BRAND,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_FOLDING_BOAT,
    name: `Складная лодка`,
    nameEn: `Folding Boat`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Этот предмет выглядит как деревянная коробка 12 дюймов в длину, 6 дюймов шириной и 6 дюймов высотой. Коробка весит 4 фунта и держится на плаву. Её можно открывать и хранить внутри вещи. У этого предмета есть три командных слова, и каждое из них произносится действием.

Первое командное слово разворачивает коробку в лодку 10 футов длиной, 4 фута шириной и 2 фута высотой. У лодки есть пара вёсел, якорь, мачта и треугольный парус. В этой лодке с комфортом разместятся четыре существа Среднего размера.

Второе командное слово разворачивает коробку в корабль 24 фута в длину, 8 футов шириной и 6 футов высотой. У корабля есть палуба, скамьи для гребли, пять пар вёсел, кормовое весло, якорь, палубная каюта и мачта с прямым парусом. На корабле с комфортом разместятся пятнадцать существ Среднего размера.

Если коробка стала судном, оно весит как обычное судно его размера, а всё, что хранилось в коробке, остаётся на судне.

Третье командное слово превращает судно в коробку, при условии, что на борту нет существ. Все предметы на судне, не помещающиеся в коробке, остаются вне коробки, когда та складывается. Все предметы на судне, которые могут поместиться в коробке, оказываются в коробке. `,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 201,
    },
  },
  {
    id: MAGIC_ITEM_FROST_BRAND,
    name: `Морозный клинок`,
    nameEn: `Frost Brand`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч`,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Если Вы попадаете атакой, используя этот магический меч, цель получает дополнительный урон холодом 1к6.

Кроме того, пока Вы держите этот меч, Вы обладаете сопротивлением к урону огнём. При температуре, не превышающей 0 °C, клинок испускает яркий свет в радиусе 10 футов и тусклый свет в радиусе ещё 10 футов.

Когда Вы вынимаете оружие из ножен, Вы можете погасить все источники немагического огня в пределах 30 футов. Это свойство можно использовать не чаще раза в час.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 186,
    },
  },
]
