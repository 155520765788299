const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GIANT_RAT,
  CREATURE_JERMLAINE,
  CREATURE_RAT,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_JERMLAINE,
} = require('./../../../../creatureTypeIdList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  GEAR_CLUB,
  GEAR_DART,
} = require('./../../../../gearIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_1_8} = require('./../../../../crList')
const {LANG_UNDERCOMMON} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MFFV_1_MMB} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гремлин',
  nameEn: 'Jermlaine',
  id: CREATURE_JERMLAINE,
  description: [
    {
      header: 'Гремлины',
      text: `Гремлины доказывают, что даже крохотные вещи могут быть ужасными. Их крохотные тельца размером в фут едва вмещают в себя всю их жестокость и враждебность. Глубоко в Подземье гремлины прячутся в туннелях, подземельях и пещерах, населённых гораздо большим, более опасным народом. Гремлины ютятся в маленьких норах, избегая контакта со всеми другими существами, если только они не могут наброситься на тех подавляющим числом. Но когда шансы в их пользу, их злобная решимость поразительна и может сокрушить даже отлично вооруженный патруль дроу или военную отряд двергаров.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 11,
      },
    },
    {
      header: 'Злобные засады',
      text: `Гремлины ненавидят и таят злобу на всех более крупных существ. Они прекрасно понимают, что их маленький размер и слабость в бою делают их лёгкой добычей для бесчисленных обитателей Подземья. Этот гнев и обида приводят к желанию отомстить всем крупным существам, которые входят в их владения. Гремлины внимательно следят, выжидая подходящего момента, чтобы обрушиться на ненавистных врагов. Нападая, они выбегают из своих нор подавляющими волнами. В пылу битвы отдельные гремлины даже бросаются на оружие мерзких здоровяков, чтобы упростить бой своим союзникам.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 11,
      },
    },
    {
      header: 'Скрытая угроза',
      text: `Кожа гремлина грубая, жёсткая и похожа камень. То ли по замыслу какого-то сочувствующего им бога, то ли благодаря счастливой удаче, их шкура делает их невидимыми для тёмного зрения. Часть ненависти гремлинов происходит ещё и из-за попыток ловить их и шить плащи и другую одежду из их кожи. Такие эксперименты ни разу ещё не создали работающего магического предмета, и ненависть гремлинов к чужакам частично обусловлена их предположением, что любой пришелец хочет заполучить их шкуры.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 11,
      },
    },
    {
      header: 'Тайные логова',
      text: `Из-за того, что на них охотятся ради еды и шкур, гремлины стараются скрыть свои логова от посторонних. Они роют туннели, узкие даже для их 1-футовых тел, из-за чего крупным врагам их практически невозможно преследовать. Гремлины роют туннели вдоль коридоров подземелий и троп Подземья, прячя входы в них, чтобы не пускать чужаков и устраивать засады. Гремлины прокапывают проходы в полу, в стенах и на потолке. Когда они нападают, то обрушиваются на врагов со всех сторон, пытаясь просто задавить их числом.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 11,
      },
    },
    {
      header: 'Союзные грызуны',
      text: `Гремлины могут расшифровывать крысиные писки и так же общаться с этими грызунами. Исследователи Подземья знают, что нужно внимательно относиться к признакам появления крыс. Эти грызуны, как существа поверхностного мира, редки в глубоком Подземье. Их присутствие неизменно сигнализирует о колонии гремлинов.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 11,
      },
    },
  ],
  note: {
    text: `Отправляясь в Подземье, я всегда подготавливаю заклинание, которое увеличивает размер существа. Ничто так не злит гремлина, как другой гремлин, вступивший в ряды большого народа. Гремлины начинают драться друг с другом, позволяя вам убежать`,
    author: `Морденкайнен`,
  },
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_JERMLAINE,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 11,
  },
  armor: 11,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Прячущиеся в тьме',
      description: `★СУЩЕСТВО★ невидим для тёмного зрения.`,
    },
    {
      name: 'Друг вредителей',
      description: `★СУЩЕСТВО★ может говорить с [Крысами](CREATURE:${CREATURE_RAT}) и [Гигантскими крысами](CREATURE:${CREATURE_GIANT_RAT}) и понимать их.`,
    },
    {
      name: 'Нападение толпой',
      description: `★СУЩЕСТВО★ добавляет 1к4 к урону своего оружия, находясь в пределах 5 футов от другого гремлина, находящегося в сознании.`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_CLUB,
    },
    {
      gearId: GEAR_DART,
    },
  ],
}
