const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1_8,
  CR_1_4,
  CR_1_2,
  CR_2,
  CR_3,
  CR_5,
  CR_14,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
  SIZE_LARGE,
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEHOLDER_KEEN,
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_FEY,
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_HAG,
  CREATURE_TYPE_OGRE,
  CREATURE_TYPE_PLANT,
  CREATURE_TYPE_SKELETON,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_ANY_EVIL,
  ALIGNMENT_LE,
  ALIGNMENT_NE,
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_DIG,
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_ALL_KNOWN_AT_LIFE,
  LANG_COMMON,
  LANG_DEEP_SPEECH,
  LANG_DRACONIC,
  LANG_GIANT,
  LANG_INFERNAL,
  LANG_SYLVAN,
  LANG_TERRAN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_GREATER_RESTORATION,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  PROF_DOUBLE,
} = require('./../../../../proficiencyList')
const {
  CREATURE_BEHOLDER,
  CREATURE_DEATH_TYRANT,
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Тиран смерти',
  nameEn: 'Death Tyrant',
  id: CREATURE_DEATH_TYRANT,
  description: [
    {
      header: `Тиран смерти`,
      text: `Иногда разум спящего [злобоглаза](CREATURE:${CREATURE_BEHOLDER}) забредает дальше обычного безумия, воображая себе реальность, в которой он существует после смерти. Когда такие сны завладевают злобоглазом, он может трансформироваться, скинув плоть, и став тираном смерти. Он хитёр и управляет той же магией, которой владел при жизни, но теперь он подпитывается силой нежити. Тиран смерти выглядит как массивный голый череп с точечкой красного света, сверкающей в пустой глазнице. Его глаза на отростках сгнили, и десять призрачных глаз теперь парят над ним, свирепо смотря во всех направлениях.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
    {
      header: `Смертоносный деспот`,
      text: `Как и при существовании в виде злобоглазов, тираны смерти распространяют свою власть на других существ. Кроме того, способность злобоглаза отменять магию взглядом центрального глаза, сменяется на более зловещую силу тирана смерти, который теперь может превращать бывших рабов или врагов в нежить.

Зомби, созданные тираном смерти, используют и выбрасываются им по мере необходимости. Они охраняют вход в логово тирана смерти или его сокровищницу. Они служат приманкой в ловушках, а в бою выступают в роли расходного материала. Зомби отвлекают сильных врагов и дают время тирану смерти занять положение и подготовиться к уничтожению врага.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
    {
      header: `Армия мёртвых`,
      text: `Тиран смерти, ставший нежитью, становится машиной уничтожения. Движимый жаждой власти и безопасности, он приближается к человеческим поселениями, уничтожая своими лучами все живое, попавшееся ему на пути, а затем создаёт себе армию нежити. Если его не остановить, тиран смерти может за неделю уничтожить население большого города, а затем он устремит свой мёртвый взгляд на дальнейшие завоевания. Любое уничтоженное поселение пополняет армию [зомби](CREATURE:${CREATURE_ZOMBIE}) тирана смерти.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
    {
      header: `Природа нежити`,
      text: `Тиран смерти не нуждается в воздухе, еде, питье и сне.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
    {
      header: `Логово тирана смерти`,
      text: `Логово тирана смерти обычно похоже на логово злобоглаза, но содержит больше смертоносных ловушек и более запущено. У тирана смерти, обнаруженного в логове, показатель опасности 15 (13000 опыта).

# Действия логова

При сражении в своём логове, тиран смерти может воззвать к окружающей магии и использовать действие логова. При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) тиран смерти может совершить действие логова, чтобы вызвать один из следующих эффектов:

* Квадратная площадь 50 × 50 футов, находящаяся в пределах 120 футов от тирана, наполняется призрачными глазами и щупальцами. Для всех существ кроме тирана смерти эта местность слабо заслонена и является труднопроходимой, до значения инициативы 20 в следующем раунде.
* Стены обрастают призрачными придатками до значения инициативы 20 через два раунда. Все существа, включая тех, что находятся на Эфирном Плане, враждебные тирану и начинающих свой ход в пределах 10 футов от стены, должны пройти испытание Ловкости СЛ 17, иначе станут схваченными. Для высвобождения необходима успешная проверка Силы (Атлетика) или Ловкости (Акробатика) СЛ 17.
* В воздухе в пределах 50 футов от тирана открывается призрачный глаз, испускающий случайным образом выбранный луч из глаз этого тирана в цель на выбор тирана. Призрачный глаз считается эфирным источником. После этого глаз закрывается и исчезает.

Тиран смерти не может повторять эффект пока все эффекты не будут использованы, и он не может использовать один эффект два раунда подряд.

# Местные эффекты

Местность, в которой находится логово тирана смерти, искажается сверхъестественной природой существа. Обычно это один или несколько из следующих эффектов:

* Существа в пределах 1 мили от логова тирана смерти иногда ощущают, что за ними наблюдают, хотя рядом никого нет.
* Когда существо, враждебное тирану, и знающее о его существовании, заканчивает длинный отдых в пределах 1 мили от логова тирана, бросьте к20 для этого существа. При результате «10» или меньше существо подвергается атаке одного случайным образом выбранного луча из глаза тирана.

Если тиран смерти умирает, эти эффекты пропадают в течение 1к10 дней.`,
      source: {
        id: SOURCE_MM,
        page: 159,
      },
    },
  ],
  note: {
    text: `Скопление крошечных огоньков спустилось из тёмной расщелины на потолке. Эти искры своим свечением осветили большой чужеродный череп, висящий под ними`,
    author: `из дневников Джастуса Холлоуквила, исследователя Подгорья`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_BEHOLDER_KEEN,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 161,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 25,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 20,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_EXHAUSTION],
    [CONDITION_PETRIFIED],
    [CONDITION_POISONED],
    [CONDITION_CHARMED],
    [CONDITION_PARALYZED],
    [CONDITION_PRONE],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_14,
  featureList: [
    {
      name: 'Конус отрицательной энергии',
      description: `Центральный глаз ★СУЩЕСТВО★ испускает 150-футовым конусом невидимую магическую отрицательную энергию. В начале каждого своего хода ★СУЩЕСТВО★ решает, в какую сторону направлен конус, и будет ли он активен.

Все существа в этой области не могут восстанавливать хиты. Все гуманоиды, умирающие в ней, становятся [зомби](CREATURE:${CREATURE_ZOMBIE}) под командованием тирана. Мёртвый гуманоид сохраняет своё место в порядке инициативы и оживает в начале своего следующего хода, при условии, что его тело не уничтожено полностью.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 6,
        },
      },
    },
    {
      name: 'Лучи из глаз',
      description: `★СУЩЕСТВО★ испускает три магических луча из глаз, выбранных случайным образом (перебрасывая повторяющиеся результаты), выбирая от одной до трёх целей, видимых в пределах 120 футов:

1. **Очаровывающий луч.** Целевое существо должно пройти испытание Мудрости СЛ 17, иначе станет очарованным тираном на 1 час, или пока злобоглаз не причинит ему вред.
2. **Парализующий луч.** Целевое существо должно пройти испытание Телосложения СЛ 17, иначе станет парализованным на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
3. **Луч страха.** Целевое существо должно пройти испытание Мудрости СЛ 17, иначе станет испуганным на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
4. **Замедляющий луч.** Целевое существо должно пройти испытание Ловкости СЛ 17. При провале скорость цели на 1 минуту уменьшается вдвое. Кроме того, существо не может совершать реакции, и может в свой ход совершать либо действие, либо бонусное действие, но не то и другое. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
5. **Луч бессилия.** Целевое существо должно пройти испытание Телосложения СЛ 17, получая урон некротической энергией 36 (8к8) при провале, или половину этого урона при успехе.
6. **Телекинетический луч.**
    * Если цель — существо, она должна пройти испытание Силы со 17 , иначе ★СУЩЕСТВО★ переместит её на расстояние до 30 футов в любом направлении. Цель обездвижена телекинетической хваткой луча до начала следующего хода ★СУЩЕСТВО★, либо пока ★СУЩЕСТВО★ не станет ★недееспособным★.
    * Если цель — предмет, весящий не больше 300 фунтов, никем не несомый и не носимый, он перемещается в любом направлении на расстояние до 30 футов. ★СУЩЕСТВО★ может этим лучом совершать аккуратные действия, например, манипулировать простым инструментом, открывать дверь или контейнеры.
7. **Усыпляющий луч.** Целевое существо должно пройти испытание Мудрости СЛ 17, иначе уснёт и в течение 1 минуты будет лишено сознания. Цель просыпается, если получает урон или другое существо действием разбудит его. Этот луч не оказывает никакого эффекта на конструктов и нежить.
8. **Луч окаменения.** Целевое существо должно пройти испытание Ловкости СЛ 17. При провале существо начинает превращаться в камень и обездвиживается. В конце своего следующего хода оно должно повторить это испытание. При успехе эффект оканчивается. При провале существо становится окаменевшим, пока не будет использовано заклинание [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) или подобная магия.
9. **Луч расщепления.**
    * Если цель — существо, она должна пройти испытание Ловкости СЛ 17, иначе получит урон силовым полем 45 (10к8). Если этот урон снижает хиты существа до 0, его тело становится кучкой серой пыли.
    * Если цель — немагический предмет или творение магической силы с размером не больше Большого, она расщепляется без испытания.
    * Если цель — немагический предмет или творение магической силы с размером Огромный или больше, этот луч расщепляет объём куба с длиной ребра 10 футов.
10. **Луч смерти.** Целевое существо должно пройти испытание Ловкости СЛ 17, иначе получит урон некротической энергией 55 (10к10). Цель умирает, если луч уменьшает её хиты до 0.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Луч из глаз',
      description: `★СУЩЕСТВО★ использует один случайным образом выбранный луч из глаз.`,
    },
  ],
  genderId: GENDER_MALE,
}
