const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  SOURCE_PHB,
} = require('./../../../sourceList')
const {
  CAT_TRANSPORT_WATER,
} = require('./../../../gearCategoryList')
const {
  GEAR_KEELBOAT,
} = require('./../../../gearIdList')

module.exports = {
  id: GEAR_KEELBOAT,
  genderId: GENDER_FEMALE,
  name: 'Когг',
  nameByCase: {
    nominative: 'когг',
    genitive: 'когга',
    accusative: 'когг',
    instrumental: 'коггом',
  },
  nameAlt: 'Килевая лодка',
  nameEn: 'Keelboat',
  description: 'Скорость: 1 узел',
  cost: 300000,
  source: {
    id: SOURCE_PHB,
    page: 157,
  },
  category: CAT_TRANSPORT_WATER,
}
