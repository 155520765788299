const {
  CAST_VERBAL,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SPELL_BOOMING_BLADE,
  SPELL_BOOMING_BLADE_SCAG,
} = require('./../../../../../spellIdList')
const {ATTACK_MELEE_SPELL} = require('./../../../../../attackTypeList')
const {DAMAGE_THUNDER} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SOURCE_SCAG} = require('./../../../../../sourceList')
const {TIME_ROUND} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_BOOMING_BLADE_SCAG,
  releasedAs: SPELL_BOOMING_BLADE,
  name: 'Громовой клинок',
  nameEn: 'Booming Blade',
  description: `Как часть действия, используемого для произнесения этого заклинания, Вы должны совершить рукопашную атаку оружием по одному из существ в пределах дальности заклинания или заклинание провалится.

При попадании, цель получает нормальный урон от атаки и оказывается окутанной громовой энергией до начала Вашего следующего хода. Если существо по собственной воле передвинется до того момента, оно немедленно получает 1к8 урона звуком, и заклинание заканчивается.`,
  highLvlCast: `Урон заклинания растёт с повышением Вашего уровня. На 5-м уровне рукопашная атака наносит дополнительно 1к8 урона звуком по цели и урон при передвижении возрастает до 2к8. Обе кости урона возрастают на 1к8 на 11-м и на 17-м уровнях.`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 5,
  componentIdList: [CAST_VERBAL, CAST_MATERIAL],
  materialText: 'любое оружие',
  duration: { timeId: TIME_ROUND, count: 1 },
  effect: {
    attackType: ATTACK_MELEE_SPELL,
    damage: {
      type: DAMAGE_THUNDER,
      diceCount: 1,
      diceType: 8,
      diceBonus: 0,
    },
  },
  source: {
    id: SOURCE_SCAG,
    page: 143,
  },
}
