const {PC_SUBCLASS_MONK_WAY_OF_THE_OPEN_HAND} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {SPELL_SANCTUARY} = require('./../../../spellIdList')
const {
  FEATURE_KI,
  FEATURE_OPEN_HAND_TECHNIQUE,
  FEATURE_QUIVERING_PALM,
  FEATURE_TRANQUILITY,
  FEATURE_WHOLENESS_OF_BODY,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OPEN_HAND_TECHNIQUE,
    name: `Техники открытой ладони`,
    nameEn: `Open Hand Technique`,
    lvl: 3,
    text: `Вы получаете возможность манипулировать вражеской энергией ци, направляя свою собственную. Если Вы попадаете по существу одной из атак, дарованных [Шквалом ударов](FEATURE:${FEATURE_KI}), Вы можете наложить на цель один из следующих эффектов:

* Цель должна пройти испытание Ловкости, иначе она сбивается с ног.
* Цель должна пройти испытание Силы. При провале Вы можете толкнуть её от себя на расстояние до 15 футов.
* Цель не может совершать реакции до конца Вашего следующего хода.
`,
  },
  {
    id: FEATURE_WHOLENESS_OF_BODY,
    name: `Исцеление тела`,
    nameEn: `Wholeness of Body`,
    lvl: 6,
    text: `Вы получаете способность лечить себя.

Вы можете действием восстановить количество хитов, равное Вашему утроенному уровню монаха.

Вы должны завершить длинный отдых, прежде чем сможете использовать это умение снова.`,
  },
  {
    id: FEATURE_TRANQUILITY,
    name: `Умиротворение`,
    nameEn: `Tranquility`,
    lvl: 11,
    text: `Вы можете совершить специальную медитацию, окружающую Вас аурой мира.

В конце длинного отдыха Вы получаете эффект заклинания [Убежище](SPELL:${SPELL_SANCTUARY}), длящийся до начала Вашего следующего длинного отдыха (эффект может окончиться раньше, согласно описанию заклинания).

СЛ испытания от заклинания равна 8 + модификатор Мудрости + Ваш бонус мастерства.`,
    spellIdList: SPELL_SANCTUARY,
  },
  {
    id: FEATURE_QUIVERING_PALM,
    name: `Дрожащая ладонь`,
    nameEn: `Quivering Palm`,
    lvl: 17,
    text: `Вы получаете возможность создавать смертельные колебания в чужом теле.

Если Вы попадёте по существу безоружным ударом, то можете потратить 3 очка ци, чтобы создать неуловимые колебания в его теле, которые длятся в течение количества суток, равного Вашему уровню монаха.

Колебания безвредны, пока Вы не остановите их действием. Для того чтобы остановить их, цель должна находится на одном плане существования с Вами. При этом существо должно пройти испытание Телосложения. При успехе цель получает урон некротической энергией 10к10, а при провале её хиты опускаются до 0. Вы можете держать одновременно только одно существо под действием _Дрожащей ладони_.

Вы можете решить закончить колебания без вреда, не тратя действие.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_THE_OPEN_HAND,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  })
)
