const generateFeaturePageUrlById = require('./generateFeaturePageUrlById')
const generatePcSubClassPageUrlById = require('./generatePcSubClassPageUrlById')

const featureUrlGenerator = (match, featureId) => generateFeaturePageUrlById(featureId)
const subClassUrlGenerator = (match, subClassId, hashStr = '') => generatePcSubClassPageUrlById(subClassId, null, hashStr)

module.exports = text => text
  ? typeof text === 'string'
    ? text
      .replace(/BACKGROUND:/g, '/background-catalog/')
      .replace(/CREATURE:/g, '/creature-catalog/')
      .replace(/FEAT:/g, '/feat-catalog/')
      .replace(/FEATURE:([^#?)]+)/g, featureUrlGenerator)
      .replace(/GEAR:/g, '/gear-catalog/')
      .replace(/GOD:/g, '/god-catalog/')
      .replace(/INFUSION:/g, '/infusion-catalog/')
      .replace(/INVOCATION:/g, '/invocation-catalog/')
      .replace(/LANG:/g, '/language-catalog/')
      .replace(/MAGIC_ITEM:/g, '/magic-item-catalog/')
      .replace(/PC_CLASS:/g, '/pc-class-catalog/')
      .replace(/PC_RACE:/g, '/race-catalog/')
      .replace(/PC_SUBCLASS:([^#?)]+)([^)]*)/g, subClassUrlGenerator)
      .replace(/SPELL:/g, '/spell-catalog/')
      .replace(/VEHICLE:/g, '/vehicle-catalog/')
    : text
  : ''
