const {
  MAGIC_NECROMANCY,
} = require('./../../../../../magicList')
const {
  DAMAGE_COLD,
  DAMAGE_NECROTIC,
  DAMAGE_RADIANT,
} = require('./../../../../../damageTypeList')
const {
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_SPIRIT_SHROUD,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SPIRIT_SHROUD,
  name: 'Духовный саван',
  nameAlt: 'Покров духа',
  nameEn: 'Spirit Shroud',
  description: `Вы вызываете духов мёртвых, которые летают вокруг Вас всё время действия заклинания. Духи неосязаемы и неуязвимы.

Пока заклинание действует, все совершаемые Вами атаки наносят дополнительно 1к8 урона, если Вы попадаете по существу в пределах 10 футов. Это урон излучением, некротический или холодом (Вы выбираете тип урона при сотворении заклинания). Существо, получившее этот урон, не может восстанавливать хиты до начала Вашего следующего хода.

Кроме того, скорость любых видимых Вами существ по Вашему выбору, начинающих свой ход в пределах 10 футов от Вас, уменьшается на 10 футов до начала Вашего следующего хода.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, дополнительный урон увеличивается на 1к8 за каждые два круга ячейки выше третьего.`,
  lvl: 3,
  magicSchoolId: MAGIC_NECROMANCY,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count:  1},
  castTime: { timeId: TIME_BONUS_ACTION, count:  1},
  needConcentration: true,
  effect: {
    damage: {
      type: [
        DAMAGE_RADIANT,
        DAMAGE_NECROTIC,
        DAMAGE_COLD,
      ],
      diceCount: 1,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_TCoE,
    page: 156,
  },
}
