module.exports = [
  require('./dao'),
  require('./darkmantle'),
  require('./death_dog'),
  require('./death_knight'),
  require('./death_tyrant'),
  require('./deer'),
  require('./demilich'),
  require('./deva'),
  require('./dire_wolf'),
  require('./displacer_beast'),
  require('./djinni'),
  require('./donkey'),
  require('./doppelganger'),
  require('./draft_horse'),
  require('./dretch'),
  require('./drider'),
  require('./drow'),
  require('./drow_elite_warrior'),
  require('./drow_mage'),
  require('./drow_priestess_of_lolth'),
  require('./druid'),
  require('./dryad'),
  require('./duergar'),
  require('./duodrone'),
  require('./dust_devil'),
  require('./dust_mephit'),
]
