const {PC_SUBCLASS_MONK_WAY_OF_THE_LONG_DEATH} = require('./../../../pcSubClassIdList')
const {SOURCE_SCAG} = require('./../../../sourceList')

const {
  FEATURE_HOUR_OF_REAPING,
  FEATURE_MASTERY_OF_DEATH,
  FEATURE_TOUCH_OF_DEATH,
  FEATURE_TOUCH_OF_THE_LONG_DEATH,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_TOUCH_OF_DEATH,
    name: `Касание смерти`,
    nameEn: `Touch of Death`,
    lvl: 3,
    text: `Ваши познания смерти позволяют Вам извлекать жизненные силы других существ, приближая их гибель.

Когда Вы снижаете до 0 хиты цели в 5 футах от Вас, Вы получаете временные хиты. Количество временных хитов равно Вашему модификатора Мудрости + Ваш уровень монаха (минимум 1 временный хит).`,
  },
  {
    id: FEATURE_HOUR_OF_REAPING,
    name: `Час жатвы`,
    nameEn: `Hour of Reaping`,
    lvl: 6,
    text: `Вы можете действием вывести из себя или напугать существ вокруг Вас, так как Ваша душа несёт на себе печать смерти.

Когда Вы так делаете, каждое видящее Вас существо в пределах 30 футов, должно пройти испытание Мудрости или будет напугано Вами до конца Вашего следующего хода.`,
  },
  {
    id: FEATURE_MASTERY_OF_DEATH,
    name: `Мастерство смерти`,
    nameEn: `Mastery of Death`,
    lvl: 11,
    text: `Вы можете использовать свои знания о смерти, чтобы избежать её хватки.

Когда Ваши хиты опускаются до 0, Вы можете потратить 1 очко ци (действие не требуется), чтобы они опустились до 1 хита вместо этого.`,
  },
  {
    id: FEATURE_TOUCH_OF_THE_LONG_DEATH,
    name: `Касание долгой смерти`,
    nameEn: `Touch of the Long Death`,
    lvl: 17,
    text: `Ваше прикосновение может проводить энергию смерти прямо в существо.

Вы действием касаетесь одного существа в 5 футах от Вас и расходуете от 1 до 10 очков ци.

Цель должна пройти испытание Телосложения, при провале получая 2к10 некротического урона за потраченную единицу ци, и получая при успехе только половину урона.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_THE_LONG_DEATH,
    source: {
      id: SOURCE_SCAG,
      page: 131,
    },
  })
)
