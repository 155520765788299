module.exports = [
  require('./geryon'),
  require('./giff'),
  require('./githyanki_gish'),
  require('./githyanki_kithrak'),
  require('./githyanki_supreme_commander'),
  require('./githzerai_anarch'),
  require('./githzerai_enlightened'),
  require('./gloom_weaver'),
  require('./gray_render'),
  require('./grazzt'),
]
