const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ELEMENTAL,
  nameEn: 'Elemental',
  genderId: GENDER_MALE,
  description: {
    text: `Элементали — это существа со стихийных планов. Некоторые существа этого вида представляют собой просто живую массу соответствующей стихии, в том числе и существа, которых называют «элементалями». У других есть биологические тела, пропитанные стихийной энергией. Расы гениев, включающие джиннов и ифритов, формируют наиболее важные цивилизации на стихийных планах. К другим стихийным существам можно отнести эйзеров, невидимых охотников и водных аномалий.`,
    source: {
      id: SOURCE_MM,
      page: 9,
    },
  },
  name: {
    singular: {
      nominative: 'элементаль',
      genitive: 'элементаля',
      dative: 'элементалю',
      accusative: 'элементаля',
      instrumental: 'элементалем',
      prepositional: 'элементале',
    },
    plural: {
      nominative: 'элементали',
      genitive: 'элементалей',
      dative: 'элементалям',
      accusative: 'элементалей',
      instrumental: 'элементалями',
      prepositional: 'элементалях',
    },
  },
}
