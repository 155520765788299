const {
    SOURCE_MM,
    SOURCE_MTOF,
  } = require('./../../../../sourceList'),
  {
    CR_8,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHYANKI,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_LE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_GITH,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_PSYCHIC,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_ASTRAL_PROJECTION,
    SPELL_JUMP,
    SPELL_MAGE_HAND,
    SPELL_MISTY_STEP,
    SPELL_NONDETECTION,
    SPELL_PLANE_SHIFT,
    SPELL_TELEKINESIS,
    SPELL_TONGUES,
  } = require('./../../../../spellIdList'),
  {
    CAST_NONE,
  } = require('./../../../../castComponentList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_PLATE_ARMOR,
  } = require('./../../../../gearIdList'),
  {
    githyankiDescription,
    githyankiNote,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_GITHYANKI_KNIGHT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гитьянки рыцарь',
  nameAlt: 'Гитьянки Сарт',
  nameEn: 'Githyanki Knight',
  nameEnAt: 'Githyanki Sarth',
  id: CREATURE_GITHYANKI_KNIGHT,
  description: [
    `Гитьянки грабят бесчисленные миры с палуб своих астральных кораблей и спин красных драконов. Перья, бисер, драгоценные камни и металлы украшают их доспехи и оружие — легендарные серебряные мечи с которыми они прорубаются с боем через своих врагов. Вырвав свободу у свежевателей разума, гитьянки стали безжалостными завоевателями под руководством ужасной королевы-лича Влаакит.`,
    ...githyankiDescription,
  ],
  note: githyankiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHYANKI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_MM,
      page: 59,
    },
    {
      id: SOURCE_MTOF,
      page: 127,
    },
  ],
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 15,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  languageList: [
    LANG_GITH,
  ],
  cr: CR_8,
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_MAGE_HAND,
            comment: 'рука невидима',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          {
            id: SPELL_NONDETECTION,
            comment: 'только на себя',
          },
          SPELL_JUMP,
          SPELL_MISTY_STEP,
          SPELL_TONGUES,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_TELEKINESIS,
          SPELL_PLANE_SHIFT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Серебряным двуручным мечом_.`,
    },
    {
      name: 'Серебряный двуручный меч',
      description: `Это атака магическим оружием. При критическом попадании по цели, находящейся в астральном теле (как при использовании заклинания [Проекция в астрал](SPELL:${SPELL_ASTRAL_PROJECTION})), ★СУЩЕСТВО★ может перерезать серебряную нить, соединяющую цель с материальным телом, вместо причинения урона.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 6,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
