const {CAT_CLOTHES} = require('./../../../../gearCategoryList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {MAGIC_ITEM_WINGWEAR} = require('./../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_WINGWEAR,
  name: 'Полётное одеяние',
  nameEn: 'Wingwear',
  type: MGC_TYPE_WONDROUS_ITEM,
  gearCategory: CAT_CLOTHES,
  rarity: MGC_RARITY_UNCOMMON,
  attunement: true,
  description: `На этом удобном одеянии вышиты символы воздуха, а похожие на перепонки кожаные вставки, соединяющие рукава, талию и ноги, играют роль импровизированных крыльев, на которых можно планировать по воздуху. Полётное одеяние имеет 3 заряда.

Нося _полётное одеяние_, Вы можете бонусным действием потратить 1 заряд и получить скорость полёта 30 футов до тех пор, пока не коснётесь земли. В конце каждого своего хода Вы опускаетесь на 5 футов. Вы мгновенно опускаетесь до 0 футов, если не пролетаете по крайней мере 30 футов по горизонтали в свой ход. Опустившись до 0 футов, Вы приземляетесь (или падаете). Чтобы вновь использовать одеяние, Вы должны потратить ещё один заряд.

Одеяние восстанавливает все использованные заряды после перезарядки в стихийном узле воздуха, которая занимает как минимум 1 час.`,
  genderId: GENDER_MIDDLE,
  source: {
    id: SOURCE_PotA,
    page: 227,
  },
}
