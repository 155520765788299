const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_LONGBOW,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_BANSHEE,
  CREATURE_GNOLL_HUNTER,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_GNOLL,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {ABILITY_RAMPAGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_1_2} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {gnollDescriptionList} = require('./../../../../textCommonParts')
const {LANG_GNOLL} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гнолл охотник',
  nameEn: 'Gnoll Hunter',
  id: CREATURE_GNOLL_HUNTER,
  description: [
    `Охотники это самые скрытные, бесшумные гноллы в боевой банде, и они нашли множество применений своим талантам на поле боя. Охотники крадутся в авангарде банды и убирают отдельных противников, тем самым расчищая путь основным силам для проникновения на территорию врага.

Охотники мастерски владеют [длинными луками](GEAR:${GEAR_LONGBOW}), они пользуются стрелами с грубыми, зазубренными наконечниками. Даже если охотник не убивает свою цель с первого выстрела, стрела причиняет такую боль, что жертва уже никуда не может убежать. Когда охотник находит добычу и ему не нужно оставаться незаметным, он использует костяной рог, который издает звук похожий на ужасный крик [банши](CREATURE:${CREATURE_BANSHEE}).`,
    ...gnollDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GNOLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 141,
  },
  armor: {
    ac: 13,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GNOLL,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_RAMPAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Гнолл делает две рукопашные атаки _Копьём_ или две дальнобойные атаки _Длинным луком_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_SPEAR,
    },
    {
      gearId: GEAR_LONGBOW,
      description: `Скорость цели уменьшена на 10 футов до конца её следующего хода.`,
    },
  ],
}
