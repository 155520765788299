const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  GOD_MEMNOR,
} = require('./../../godIdList')
const {
  CREATURE_FIRE_GIANT,
  CREATURE_FROST_GIANT,
  CREATURE_GIANT_EAGLE,
  CREATURE_GIANT_OWL,
  CREATURE_GRIFFON,
  CREATURE_HILL_GIANT,
  CREATURE_LION,
  CREATURE_MANTICORE,
  CREATURE_OWLBEAR,
  CREATURE_PERYTON,
  CREATURE_STORM_GIANT,
  CREATURE_WYVERN,
} = require('./../../creatureIdList')

const giantDescriptionList = require('./giant')

module.exports = [
  {
    header: 'Облачные великаны',
    text: `Облачные великаны живут расточительной жизнью высоко над землёй, нисколько не заботясь об участи других рас кроме как ради развлечения. Они мускулистые, кожа у них светлая, а волосы серебряного или голубого цвета.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Великие и могучие',
    text: `Облачные великаны путешествуют вместе с ветрами, и могут оказаться в любом месте мира. В трудные времена разрозненные семейства облачных великанов объединяются в единый клан. Впрочем, у них не всегда получается сделать это быстро.

Будучи настроенными на магическую энергетику своих воздушных просторов, облачные великаны могут создавать завесы густых туманов и сами могут превращаться в туман. Они обитают в замках на пиках высоких гор или на прочных облаках, где находятся их владения. Время от времени эти магические облака все ещё проносятся по небесам, напоминая о потерянных империях великанов.

Облачные великаны — лучшие заклинатели среди всех великанов; они могут контролировать погоду, вызывать шторм и управлять ветром почти так же хорошо, как родственные им штормовые великаны.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Влиятельные правители',
    text: `Несмотря на то, что облачные великаны ниже по рангу, чем [штормовые](CREATURE:${CREATURE_STORM_GIANT}), ведя уединённый образ жизни, штормовые великаны редко вступают в контакт с остальными представителями своего рода. Поэтому многие облачные великаны считают себя обладателями самого высокого статуса и могущества среди других представителей своего вида. Они приказывают стоящим ниже великанам искать для них богатства и предметы искусства, нанимают [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) в качестве кузнецов и ремесленников, и используя [ледяных великанов](CREATURE:${CREATURE_FROST_GIANT}) как разбойников, мародёров и грабителей. Глуповатые [холмовые великаны](CREATURE:${CREATURE_HILL_GIANT}) служат им громилами и пушечным мясом — иногда они сражаются ради развлечения облачных великанов. Облачный великан может приказать холмовым или ледяным великанам присвоить земли гуманоидов, которые они считают справедливой платой за долгое милосердие.

На вершинах гор и массивных облаков облачные великаны разводят необыкновенные сады. Там растёт виноград величиной с яблоко, яблоки размером с тыкву, и тыквы размером с телегу. С этих рассеянных по миру семян и пошли сказания об овощах размером с дом и волшебных бобах.

Человеческая знать разводит охотничьих соколов, а облачные великаны для этих же целей содержат [грифонов](CREATURE:${CREATURE_GRIFFON}), [перитонов](CREATURE:${CREATURE_PERYTON}) и [виверн](CREATURE:${CREATURE_WYVERN}). Подобные создания часто охраняют сады облачных великанов наравне с натренированными хищниками, такими как [совомеды](CREATURE:${CREATURE_OWLBEAR}) и [львы](CREATURE:${CREATURE_LION}).`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Дети Обманщика',
    text: `Покровительствующее божество и отец облачных великанов — [Мемнор Обманщик](GOD:${GOD_MEMNOR}), умнейший и коварнейший из всех божеств великанов. Облачные великаны ориентируются на деяния [Мемнора](GOD:${GOD_MEMNOR}) и равняются на него. Злые великаны при этом подражают его двуличности и своенравности, а добрые любят его за интеллект и красноречие. Члены одной семьи, как правило, следуют одним курсом.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Богатство и власть',
    text: `Облачные великаны заслуживают своё место в уложении собранными сокровищами, носимым богатством и подарками, даруемыми другим облачным великанам. Однако, стоимость — лишь одна сторона медали. Роскошь на самом великане и окружение его дома также должны быть дивными и прекрасными. Мешки золота или самоцветов ценятся облачными великанами меньше, чем украшения, сделанные из этих материалов. Создание сокровищ, приносящих уважение, является частью домашнего хозяйства великана.

Облачные великаны предпочитают не красть и не драться за сокровища друг с другом, а ставить их на кон в азартных играх, любя высокие риски и соответствующую награду. Они часто спорят насчёт событий, которые не зависят от них самих, например, о жизни малых существ. Положение в Уложении и баснословные суммы можно выиграть или проиграть в споре о военных успехах гуманоидных наций. Вмешательство в конфликт влечёт за собой потерю ставки, но такой обман считается жульничеством только если будет обнаружен. В противном случае это ум, чествующий [Мемнора](GOD:${GOD_MEMNOR}).`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Облачные великаны',
    text: `Облачные великаны прозваны метко, ну или были когда-то. Теперь редко кто из них живёт в буквальном смысле на облаке, большинство живет на вершинах высоких гор, внутри или даже выше почти постоянного слоя облаков. Некоторые из них — находящиеся на вершине клана в Уложении — занимают последние из древних облачных замков, которые до сих пор парят по небу.

В наше время уже никто не может строить эти величественные сооружения. Методы их строительства были утрачены (наряду с большим количеством других знаний), когда Остория пала. Некоторые облачные великаны считают, что информация может ещё быть захоронена в какой-нибудь давно забытой разрушенной библиотеке. Время от времени, возникают слухи о её существовании, вызывая споры и мечты о возрождении былой славы облачных великанов, но точно её местоположение никто не узнал. Многие облачные великаны надеются, что когда-нибудь какой-то герой раскроет эту древнюю тайну. А до тех пор они получают удовольствие от лицезрения вереницы облаков, проплывающих мимо их горных домов, вместо того, чтобы жить там как раньше.`,
    source: {
      id: SOURCE_VGTM,
      page: 25,
    },
  },
  {
    header: 'Семья превыше всего',
    text: `Большинство типов великанов живут коммуной в больших группах соклановцев, но центральной группой облачных великанов является семья — супружеская пара, их ребёнок (если таковые имеются), и, возможно, несколько близких родственников. Облачные великаны предпочитают не сосредотачиваться в большом количестве в одном месте, чтобы не привлекать слишком много внимания. Это не значит, что они боятся нападения гуманоидов или монстров, потому что кроме драконов мало кто может бросить им вызов. Но если в одном месте проживает больше, чем несколько великанов, размер их совокупного сокровища привлекает непрекращающийся поток искателей приключений и потенциальных воров, что создаёт проблему из разряда заведшихся в погребе крыс.

Несмотря на расстояния, которые отделяют дома семейств друг от друга, облачные великаны не изолированы. Каждая семья или её член знает, где его ближайшие соседи, даже в сотнях миль, и эти соседи знают, где их ближайшие соседи, и так далее по всему миру. В условиях кризиса, сообщение распространяется от семьи к семье, так что могучий отряд облачных великанов может быть собран в кратчайший срок, если в этом возникнет необходимость.

Большинство облачных великанов держат дома одного или нескольких домашних животных. [Виверны](CREATURE:${CREATURE_WYVERN}), [грифоны](CREATURE:${CREATURE_GRIFFON}), [гигантские орлы](CREATURE:${CREATURE_GIANT_EAGLE}) и [совы](CREATURE:${CREATURE_GIANT_OWL}), и другие летучие звери являются популярным выбором. Питомцы не обязаны быть летающими существами. В зверинце облачных великанов можно найти животных любого вида . Редкие экземпляры рассматриваться скорее как символ статуса, чем как компаньоны.`,
    source: {
      id: SOURCE_VGTM,
      page: 25,
    },
  },
  {
    header: 'Доброжелательные повелители',
    text: `Облачные великаны известны (печально) тем, что требуют дань с гуманоидов, которые живут под ними. Такая дань является единственно правильной с их точки зрения, по двум причинам. Во-первых, их присутствие в зоне приносит пользу всем, отгоняя многие злые силы, особенно летающих хищников, таких как [мантикоры](CREATURE:${CREATURE_MANTICORE}) и [виверны](CREATURE:${CREATURE_WYVERN}). Во-вторых, великаны считают, что они заслуживают того, чтобы быть вознаграждены за снисходительность; никто не мог бы воспрепятствовать тому, чтобы они просто взяли, что захотят, но вместо этого, они принимают добровольную дань (логика этой позиции более ясна для великанов, чем для платящих).

Большая часть дани, которую принимают облачные великаны, приходит в форме скота и урожаев, но это не единственный их источник пищи. Облачные великаны заядлые садоводы. Почти во всех их крепостях есть специальные участки, приносящие великанский урожай: бобы размером с репу, репу размером с тыкву, и тыкву размером с карету.

Сад семьи облачных великанов редко страдает от засухи, заморозков или саранчи. Когда такое бедствие поражает близлежащие человеческие фермы, семьи великанов нередко делятся своими щедротами, чтобы облегчить нехватку продовольствия у гуманоидов. Такие события являются основанием сказок о волшебных бобах, о жизни человеческой семьи в коттедже, вырезанном из одной огромной тыквы. Кроме того, великодушие облачных великанов во времена нужды помогает им укрепить свою репутацию в качестве друзей человечества — репутация, которая им хорошо служит, хотя не совсем заслуженна.`,
    source: {
      id: SOURCE_VGTM,
      page: 26,
    },
  },
  {
    header: 'Уложение расточительности',
    text: `Положение облачного великана в Уложении определяется отнюдь не талантами или умениями, но богатством. Чем богаче великан, тем выше от стоит. Вот настолько всё просто. Почти.

Владение богатством — это конечно хорошо, но богатство, которое держится взаперти, мало что значит. Для того, чтобы в полной мере содействовать своему статусу, богатство должно выставляться напоказ, и чем более нарочито, тем лучше. В доме семьи облачных великанов во всём присуща экстравагантность. Можно похвастаться окнами, обрамлёнными золотым листом, редким парфюмом, хранящимся во флаконах из хрусталя с серебряными крышками, или сценами в небесах, изображенными на гобелене, полностью расшитом жемчугом.

Ещё один способ для семьи продемонстрировать своё богатство — делать щедрые подарки другим семьям (подарок одного члена семьи другому ничего не говорит о щедрости семьи). Ни один облачный великан не считает, что лучше давать, чем получать; семья делает это лишь с прицелом на поддержание своего статуса. [Мемнор](GOD:${GOD_MEMNOR}) и его обман играют определенную роль в этой «игре». Самый лучший подарок (с точки зрения дарителя) — это тот, который все сочтут гораздо более ценным, чем он есть на самом деле. Только даритель и получатель знают истинную ценность подарка, и ни один из них никогда не показывает, что подарок стоит меньше, чем кажется, поскольку это отразится на статусе обоих.

Богатство облачных великанов также переходит из рук в руки, когда они потакают своей одержимости азартными играми и пари. Облачные великаны не участвуют в ставках для удовольствия; это отнюдь не форма развлечения, но вид бескровной вражды. Ни один облачный великан не принимает поражение благожелательно, и Вы никогда не услышите, чтобы кто-то из них сказал: _«Я проиграл 40 фунтов золота, но отлично провёл время»_. Войны ставок между семьями могут продолжаться в течение нескольких поколений, а богатства и поместья (и положение в Уложении, которое с ними приходит) переходит туда и обратно несколько раз. То, что проигрывает родитель, ребёнок надеется когда-нибудь вернуть сполна; то, что отыгрывает ребёнок, скорее всего будет проиграно внуком. Истории, которые рассказывают облачные великаны о своих предках, редко повествуют о войнах, магии или битвах с драконами — чаще они о том, как прародитель блестяще выиграл пари через смелость или обман, а соперничающая семья была тем же доведена до разорения и упадка.`,
    source: {
      id: SOURCE_VGTM,
      page: 26,
    },
  },
  {
    header: 'Маски благородства',
    text: `Древние живописания [Мемнора](GOD:${GOD_MEMNOR}) часто показывают, что он носил двуликую маску. Из-за этого дворяне облачных великанов редко показывают свои лица, а вместо этого носят изысканные маски, изготовленные из драгоценных материалов, украшенных драгоценными камнями. Каждый благородный облачный великан имеет коллекцию из масок, которые он носит, чтобы скрыть свое лицо, но которые по-прежнему отражают его текущее настроение; дворянин может менять маски много раз в течение дня, в зависимости от своих эмоций.

Маска ценится как за её материальную ценность, так и за её точность в выражении настроения. Только самые богатые облачные великаны могут себе позволить коллекцию из дюжин масок, которая могла бы отразить все оттенки эмоций, которые возможны для великанов. Ремесленники, способные изготовить маску таким образом, чтобы она полностью соответствовала притязательным запросам великана, щедро вознаграждаются за своё мастерство.`,
    source: {
      id: SOURCE_VGTM,
      page: 27,
    },
  },
  ...giantDescriptionList,
]
