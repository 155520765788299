import React from 'react'
import PropTypes from 'prop-types'
import arrify from 'arrify'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'

import DEFAULT_ID from '@/constants/DEFAULT_ID'

import generateNameStr from '@/utils/generateNameStr'
import upLetter from '@/utils/upLetter'

const CommonCatalogPageTemplate = (
  {
    defaultSeoData,
    itemCollection,
    ItemComponent,
    itemImageCollection,
    ListComponent,
    pageContext: {slug},
    useSingular = true,
  }
) => {
  const itemId = slug || DEFAULT_ID

  let seoData = defaultSeoData

  if (itemId) {
    const item = itemCollection[itemId] || {}
    const {description} = item
    const {title} = generateNameStr(item, useSingular, true)
    const [descriptionText] = description
      ? arrify(description)
      : []

    seoData = {
      title: `${upLetter(title)} | ${defaultSeoData.title}`,
      description: descriptionText && descriptionText.text
        ? descriptionText.text
        : descriptionText,
    }

    const imageList = itemImageCollection
      ? arrify(itemImageCollection[itemId])
      : null

    if (imageList && imageList.length) {
      seoData.img = imageList[0].src || imageList[0]
    }
  }

  const { title, description, img } = seoData

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        img={img}
      />
      {
        itemId
          ? <ItemComponent id={itemId}/>
          : <ListComponent/>
      }
    </Layout>
  )
}

CommonCatalogPageTemplate.defaultProps = {
  itemImageCollection: null,
  ListComponent: null,
  pageContext: {
    slug: DEFAULT_ID,
  },
}

CommonCatalogPageTemplate.propTypes = {
  defaultSeoData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    img: PropTypes.string,
  }).isRequired,
  itemCollection: PropTypes.object.isRequired,
  ItemComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  itemImageCollection: PropTypes.object,
  ListComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
  }),
}

export default CommonCatalogPageTemplate
