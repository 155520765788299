const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_ABYSSAL,
  LANG_ELVEN,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_GHAST,
  CREATURE_GHOUL,
  CREATURE_MAUREZHI,
} = require('./../../../../creatureIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_7} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_DECEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Маурежи',
  nameEn: 'Maurezhi',
  id: CREATURE_MAUREZHI,
  description: [
    `Когда Доресэйн, Король Упырей, развратил общество эльфов, он породил новый вид демона, который возглавит стаи [упырей](CREATURE:${CREATURE_GHOUL}) и [вурдалаков](CREATURE:${CREATURE_GHAST}) в материальный мир.`,
    {
      header: 'Жуткое перевоплощение',
      text: `Когда маурежи поглощает труп убитого им гуманоида (процесс занимает 10 минут), то он немедленно приобретает внешность существа, что была при его жизни. Через несколько дней новый облик начинает гнить тем самым, раскрывая свой изначальный облик.`,
      source: {
        id: SOURCE_MTOF,
        page: 133,
      },
    },
    {
      header: 'Заражение упырей',
      text: `Маурежи — само олицетворение чумы. Их атаки укусом могут высосать самосознание жертвы. Если позволить ему высосать больше самосознания, то жертва заражается нечестивой жаждой к плоти, которая пересиливает личность жертвы и превращает её в [упыря](CREATURE:${CREATURE_GHOUL}).`,
      source: {
        id: SOURCE_MTOF,
        page: 133,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 133,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_ELVEN,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_7,
  featureList: [
    {
      name: 'Принятие формы',
      description: `Маурежи может принимать форму любого гуманоида Среднего размера, которого он съел. Демон остаётся в этой форме на протяжении 1к6 дней, во время которых принятая форма постепенно разлагается до тех пор, пока не будет полностью сброшена. с тела демона.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Маурежи совершает две атаки: одну _Укусом_ и одну _Когтями_.`,
    },
    {
      name: 'Укус',
      description: `Если цель гуманоид, то показатель его Харизмы уменьшается на 1к4. Понижение параметра длится до завершения гуманоидом короткого или длинного отдыха. Цель умирает, если значение Харизмы опускается до 0. После этого она восстаёт через 24 часа в виде [упыря](CREATURE:${CREATURE_GHOUL}), если она не была воскрешена или её труп не был уничтожен.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Когти',
      description: `Если целью является существо, отличное от нежити, оно должно пройти испытание телосложения СЛ 12, или быть парализованным на 1 минуту. Цель может повторять испытание в конце каждого своего хода, завершая эффект в случае успеха.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Оживить упыря',
      restore: 5,
      description: `Маурежи выбирает целью одного мёртвого [упыря](CREATURE:${CREATURE_GHOUL}) или [вурдалака](CREATURE:${CREATURE_GHAST}). Цель оживляется с полными хитами.`,
    },
  ],
}
