const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CREATURE_ETERNAL_FLAME_GUARDIAN} = require('./../../../../creatureIdList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_FIRE} = require('./../../../../damageTypeList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {eternalFlameDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  GEAR_BREASTPLATE,
  GEAR_CROSSBOW_HEAVY,
  GEAR_LONGSWORD,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Хранитель Вечного Пламени',
  nameEn: 'Eternal Flame Guardian',
  id: CREATURE_ETERNAL_FLAME_GUARDIAN,
  description: [
    {
      header: 'Хранители Вечного Пламени',
      text: `Хранители Вечного Пламени очарованы силой огня и сами жаждут показать врагам культа, каково это — когда тебя охватывают языки пламени. Они любят поджигать всё подряд, делая это во славу стихии огня, а иногда для собственного удовольствия, но эти олухи редко думают о том, что пламя может перекинуться на что-нибудь важное поблизости.`,
      source: {
        id: SOURCE_PotA,
        page: 206,
      },
    },
    eternalFlameDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_PotA,
    page: 206,
  },
  armor: [
    {
      ac: 17,
      gearId: [GEAR_BREASTPLATE, GEAR_SHIELD],
    },
    {
      ac: 15,
      gearId: [GEAR_CROSSBOW_HEAVY],
    },
  ],
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 14,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Пылающее оружие',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `Бонусным действием ★СУЩЕСТВО★ может сделать так, что одно оружие ближнего боя, которое ★он★ держит в руках, охватывает пламя. ★СУЩЕСТВО★ не обжигается от этого огня, а эффект длится до конца ★его★ следующего хода. Охваченное пламенем оружие при попадании наносит дополнительный урон огнём 3 (1к6).`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      gearId: GEAR_CROSSBOW_HEAVY,
    },
  ],
}
