const {SOURCE_MOOT} = require('./../../sourceList')

module.exports = {
  header: 'Церберы',
  text: `Пугающие как живых, так и мёртвых, церберы патрулируют оба берега реки Тартикс.

Эти многоголовые псы Подземного царства выдыхают сгустки расплавленного камня, в которых, горя, увязают нарушители границ жизни и смерти.

Большинство церберов безмерно жаждут свежего мяса, особенно гуманоидной плоти. Случается, что злодеи используют этот голод, выманивая церберов от реки и натравливая их на поселения смертных.`,
  source: {
    id: SOURCE_MOOT,
    page: 215,
  },
}
