const {
  SPELL_CHARM_PERSON,
  SPELL_DISGUISE_SELF,
  SPELL_GEAS,
  SPELL_MAJOR_IMAGE,
  SPELL_MIRROR_IMAGE,
  SPELL_SCRYING,
  SPELL_SUGGESTION,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_MELEE_SPELL,
} = require('./../../../../attackTypeList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_GRAZZT,
  CREATURE_LAMIA,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Ламия',
  nameEn: 'Lamia',
  description: [
    {
      header: `Ламии`,
      text: `Руины пустынных городов и гробницы забытых монархов являются прекрасными логовами для нечестивых ламий. Эти падшие чудовища берут то, что было забыто, и создают из него двор своего гедонистического правления, окружая себя разного рода подхалимами. Ламии полагаются на шакальников, которые выполняют разного рода поручения для них. Ламии отправляют их через пустыни, чтобы привести рабов или украсть сокровища в караванах, лагерях или деревнях, укрывая их нападения своей магией.

Ламия обладает прекрасным телом гуманоида выше пояса, которое соединяется с могучим четвероногим львиным телом. Их ужасные чёрные когти говорят об их хищной натуре, как и их тяга к пыткам и вкусу плоти гуманоидов.`,
      source: {
        id: SOURCE_MM,
        page: 187,
      },
    },
    {
      header: `Гедонистические тираны`,
      text: `Ламии украшают свои ветхие убежища убранством, украденным у проходящих караванов, а после используют магию, чтобы ещё больше подчеркнуть богатство своего логова, скрывая тлен иллюзиями. Захватывающие дух сады, тонко украшенные апартаменты и множество рабов логова, кажется, идут вразрез с его удалённостью и ветхостью.

Используя дурманящее касание, ламия ослабляет ум своих врагов, делая их восприимчивыми к чарующим заклинаниям, и делая из них рабов. Те, кто попадут под чары её заклинания [Обет](SPELL:${SPELL_GEAS}), будут стравлены между собой в замысловатых состязаниях, придуманных для развлечения ламии.`,
      source: {
        id: SOURCE_MM,
        page: 187,
      },
    },
    {
      header: `Тщеславные хищники`,
      text: `Всегда жаждущие больше богатств и рабов, ламии используют водные поверхности или зеркала, чтобы наблюдать за своими «владениями» при помощи заклинания [Наблюдение](SPELL:${SPELL_SCRYING}). Ламии следят за торговыми маршрутами и ближайшими поселениями или ищут предметы и существ, которые им понравятся.

Ламиям особенно нравится выискивать искателей приключений с чистыми сердцами, чтобы соблазнить и совратить их ко злу, смакуя крушение их добродетелей. Они используют свою магию, чтобы заманить потенциальных жертв в своё логово, и полагаются на иллюзии и рабов в поимке незадачливых противников. Впрочем, больше всего ламии ценят красоту и силу. Любой пленник, впавший в немилость, становится главным блюдом на ужасном пиру или же его отпускают, чтобы он нашёл свою смерть, блуждая по пустошам.

Пока у них есть рабы, чтобы выставить их против врага, ламии будут сражаться издали, обманывая противника с помощью магии, если это возможно. Ламия, которой навязывают рукопашное сражение, не задержится в нём надолго — кромсая плоть когтями и кинжалами, она постарается убраться на безопасное расстояние.`,
      source: {
        id: SOURCE_MM,
        page: 187,
      },
    },
    {
      header: `Подручные Граз’зта`,
      text: `Демонический повелитель [Граз’зт](CREATURE:${CREATURE_GRAZZT}) создаёт ламий из своих смертных слуг, даруя им бессмертие в обмен на клятву верности. Граз’зт иногда даёт задание ламиям охранять важные для него места, но ламии на его службе вольны сеять зло там, где им заблагорассудится.`,
      source: {
        id: SOURCE_MM,
        page: 187,
      },
    },
  ],
  id: CREATURE_LAMIA,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 187,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 15,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
  ],
  cr: CR_4,
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_DISGUISE_SELF,
            comment: `любая гуманоидная форма`,
          },
          SPELL_MAJOR_IMAGE,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_SUGGESTION,
          SPELL_SCRYING,
          SPELL_MIRROR_IMAGE,
          SPELL_CHARM_PERSON,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_GEAS,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Когтями_, и одну либо _Кинжалом_, либо _Дурманящим касанием_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_DAGGER,
    },
    {
      name: 'Дурманящее касание',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 3,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: `Цель магическим образом становится проклятой на 1 час. Пока проклятье действует, цель с помехой проходит испытания Мудрости и все проверки характеристик.`,
      },
    },
  ],
  genderId: GENDER_MALE,
}
