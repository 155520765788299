const {PC_SUBCLASS_WARLOCK_THE_CELESTIAL} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_CURE_WOUNDS,
  SPELL_DAYLIGHT,
  SPELL_FLAME_STRIKE,
  SPELL_FLAMING_SPHERE,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_GUIDING_BOLT,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHT,
  SPELL_REVIVIFY,
  SPELL_SACRED_FLAME,
  SPELL_WALL_OF_FIRE,
} = require('./../../../spellIdList')
const {
  FEATURE_BONUS_CANTRIPS_WARLOCK_THE_CELESTIAL,
  FEATURE_CELESTIAL_RESILIENCE,
  FEATURE_EXPANDED_SPELLS_WARLOCK_THE_CELESTIAL,
  FEATURE_HEALING_LIGHT,
  FEATURE_RADIANT_SOUL,
  FEATURE_SEARING_VENGEANCE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_WARLOCK_THE_CELESTIAL,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    text: `Небожитель позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_CURE_WOUNDS,
      SPELL_DAYLIGHT,
      SPELL_FLAME_STRIKE,
      SPELL_FLAMING_SPHERE,
      SPELL_GREATER_RESTORATION,
      SPELL_GUARDIAN_OF_FAITH,
      SPELL_GUIDING_BOLT,
      SPELL_LESSER_RESTORATION,
      SPELL_REVIVIFY,
      SPELL_WALL_OF_FIRE,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 39,
    },
  },
  {
    id: FEATURE_BONUS_CANTRIPS_WARLOCK_THE_CELESTIAL,
    name: `Дополнительные заговоры`,
    nameEn: `Bonus Cantrips`,
    lvl: 1,
    text: `Вы узнаёте заговоры [Свет](SPELL:${SPELL_LIGHT}) и [Священное пламя](SPELL:${SPELL_SACRED_FLAME}).

Для Вас они считаются заговорами колдуна, но они не учитываются при подсчете известных Вам заговоров.`,
    spellIdList: [
      SPELL_LIGHT,
      SPELL_SACRED_FLAME,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 40,
    },
  },
  {
    id: FEATURE_HEALING_LIGHT,
    name: `Лечащий свет`,
    nameEn: `Healing Light`,
    lvl: 1,
    text: `Вы получаете способность направлять небесную энергию для лечения ран. У Вас есть пул костей к6, который Вы тратите, чтобы подпитывать это лечение. Количество костей равно 1 + уровень Вашего колдуна.

Вы можете бонусным действием вылечить одно существо, видимое Вами в пределах 60 футах, потратив несколько костей. Максимальное количество костей, которые Вы можете потратить единовременно, равно Вашему модификатору Харизмы (минимум 1 кость). Бросьте кости, которые Вы тратите, сложите их значения и восстанавливайте количество хитов, равное их сумме. Вы восстанавливаете все потраченные кости, когда Вы закончите длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 40,
    },
  },
  {
    id: FEATURE_RADIANT_SOUL,
    name: `Сияющая душа`,
    nameEn: `Radiant Soul`,
    lvl: 6,
    text: `Ваша связь с Небожителем позволяет Вам служить проводником для энергии излучения.

Вы получаете сопротивление урону излучением, и когда Вы сотворяете заклинание, которое наносит урон излучением или огнём, Вы можете добавить модификатор Харизмы к одному броску урона излучением или огнём по одной из целей этого заклинания.`,
    source: {
      id: SOURCE_XGTE,
      page: 40,
    },
  },
  {
    id: FEATURE_CELESTIAL_RESILIENCE,
    name: `Небесное сопротивление`,
    nameEn: `Celestial Resilience`,
    lvl: 10,
    text: `Вы получаете временные хиты, когда Вы заканчиваете короткий или длинный отдых. Эти временные хиты равны Вашему уровню колдуна + Ваш модификатор Харизмы.

Кроме того, в конце отдыха, выбирите до пяти видимых Вами существ. Эти существа получают временные хиты, равные половине Вашего уровня колдуна + Ваш модификатор Харизмы.`,
    source: {
      id: SOURCE_XGTE,
      page: 40,
    },
  },
  {
    id: FEATURE_SEARING_VENGEANCE,
    name: `Жгучее возмездие`,
    nameEn: `Searing Vengeance`,
    lvl: 14,
    text: `Используемая Вами энергия излучения позволяет Вам противостоять смерти.

Когда Вам нужно пройти испытание от смерти в начале Вашего хода, Вы можете вместо этого подняться на ноги с сияющим взрывом.

Вы восстанавливаете хиты, равные половине Вашего максимального значения хитов, после чего можете встать, если захотите. Каждое существо по Вашему выбору, находящееся в пределах 30 футов от Вас, получает урон излучением, равный 2к8 + Ваш модификатор Харизмы и ослепляется до конца текущего хода.

После использования этой способности Вы не сможете использовать её снова, пока не закончите длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 40,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_CELESTIAL,
  })
)
