const {
  SPELL_BLINK,
  SPELL_CHARM_PERSON,
  SPELL_CONJURE_FEY,
  SPELL_DANCING_LIGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DOMINATE_BEAST,
  SPELL_ELDRITCH_BLAST,
  SPELL_FAERIE_FIRE,
  SPELL_FEAR,
  SPELL_FRIENDS,
  SPELL_HOLD_MONSTER,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MINOR_ILLUSION,
  SPELL_MISTY_STEP,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PRESTIDIGITATION,
  SPELL_SEEMING,
  SPELL_SILENT_IMAGE,
  SPELL_SLEEP,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_VICIOUS_MOCKERY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BOGGLE,
  CREATURE_QUICKLING,
  CREATURE_REDCAP,
  CREATURE_SATYR,
  CREATURE_SPRITE,
  CREATURE_WARLOCK_OF_THE_ARCHFEY,
} = require('./../../../../creatureIdList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_NATURE,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_CHARMED} = require('./../../../../conditionList')
const {CR_4} = require('./../../../../crList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Колдун Архифеи',
  nameEn: 'Warlock of the Archfey',
  id: CREATURE_WARLOCK_OF_THE_ARCHFEY,
  description: `Колдуны архифей получают свои силы от магического договора, заключённого с владыками Страны Фей. Эти колдуны обычно сотрудничают с меньшими фейскими существами, такими как [богглы](CREATURE:${CREATURE_BOGGLE}), [квиклинги](CREATURE:${CREATURE_QUICKLING}), [красные колпаки](CREATURE:${CREATURE_REDCAP}), [сатиры](CREATURE:${CREATURE_SATYR}) и [спрайты](CREATURE:${CREATURE_SPRITE}).`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 215,
  },
  armor: [
    11,
    {
      ac: 14,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 11,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_NATURE]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_CHARMED,
  ],
  languageList: [
    {
      id: LANG_ANY,
      comment: 'обычно — Сильван',
      count: 2,
    },
  ],
  cr: CR_4,
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DISGUISE_SELF,
            SPELL_SILENT_IMAGE,
            SPELL_SPEAK_WITH_ANIMALS,
            {
              id: SPELL_MAGE_ARMOR,
              comment: 'только на себя',
            },
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_CONJURE_FEY,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 11,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DANCING_LIGHTS,
            SPELL_ELDRITCH_BLAST,
            SPELL_FRIENDS,
            SPELL_MAGE_HAND,
            SPELL_MINOR_ILLUSION,
            SPELL_PRESTIDIGITATION,
            SPELL_VICIOUS_MOCKERY,
          ],
        },
        {
          preText: `1–5 уровни (3 ячейки 5-го уровня)`,
          list: [
            SPELL_BLINK,
            SPELL_CHARM_PERSON,
            SPELL_DIMENSION_DOOR,
            SPELL_DOMINATE_BEAST,
            SPELL_FAERIE_FIRE,
            SPELL_FEAR,
            SPELL_HOLD_MONSTER,
            SPELL_MISTY_STEP,
            SPELL_PHANTASMAL_FORCE,
            SPELL_SEEMING,
            SPELL_SLEEP,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
  reactionList: [
    {
      name: 'Туманное исчезновение',
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `В ответ на получение урона, колдун становится невидимым и телепортируется на расстояние до 60 футов в видимое ему свободное пространство. Он остаётся невидимым до начала его следующего хода или пока он не нападёт, совершит бросок урона или наложит заклинание.`,
    },
  ],
}
