const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DETECT_MAGIC,
  SPELL_FIREBALL,
  SPELL_HOLD_MONSTER,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_20} = require('./../../../../crList')
const {CREATURE_PIT_FIEND} = require('./../../../../creatureIdList')
const {GEAR_MACE} = require('./../../../../gearIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Исчадие преисподней',
  nameAlt: 'Исчадие из ямы',
  nameEn: 'Pit Fiend',
  id: CREATURE_PIT_FIEND,
  description: [
    `Безоговорочные повелители большинства других дьяволов, исчадия преисподней служат напрямую эрцгерцогам и эрцгерцогиням Девяти Преисподних, и выполняют их желания. Эти могучие дьяволы — генералы Девяти Преисподних, ведущие в битву инфернальные легионы.

С их гипертрофированным чувством собственного превосходства и права повелевать другими, исчадия преисподней формируют некую гротескную аристократию инфернального царства. Эти доминирующие тираны-манипуляторы плетут внутри сложной и опасной политики Девяти Преисподних заговоры с целью уничтожения всего, что стоит между ними и их желаниями.

Исчадие преисподней представляет собой громадное чудовище с подобным кнуту хвостом и огромными крыльями, в которые он заворачивается как в плащ. Его тело покрыто крепчайшей чешуёй, а с клыкастой пасти капает яд, который может свалить с ног даже самое крепкое смертное существо. Исчадия преисподней бесстрашны в бою, они стараются сойтись в поединке один-на-один с самым сильным врагом, демонстрируя всем своё превосходство и надменность, которая не позволяет им допустить и мысли о возможности поражения.`,
    ...devilDescriptionList,
  ],
  note: [
    {
      text: `Твоё разрываемое войной королевство погрязло в коррупции, а люди умирают от голода и нищеты. Им очень нужен новый правитель — кто-то, у кого хватит мужества и харизмы положить конец страданиям и навести порядок. И это можешь быть ты!`,
      author: `Иеробаал, исчадие преисподней`,
    },
    ...devilNote,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 147,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 24,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 26,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 24,
    [PARAM_INT]: 22,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 24,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_20,
  featureList: [
    {
      name: 'Аура страха',
      description: `Все враждебные ★СУЩЕСТВО★ существа, начинающие ход в пределах 20 футов от ★него★, должны проходить испытание Мудрости СЛ 21, если ★СУЩЕСТВО★ ★дееспособен★. При провале существо становится испуганным до начала своего следующего хода. Если испытание пройдено, оно получает иммунитет к _Ауре страха_ этого ★СУЩЕСТВО★ на следующие 24 часа.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
          SPELL_FIREBALL,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_WALL_OF_FIRE,
          SPELL_HOLD_MONSTER,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает четыре атаки: одну _Укусом_, одну _Когтем_, одну _Булавой_, и одну _Хвостом_.`,
    },
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения СЛ 21, иначе станет отравленной. Будучи отравленной таким образом, цель не может восстанавливать хиты, и получает урон ядом 21 (6к6) в начале каждого своего хода. Отравленная цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Булава',
      // gearId: GEAR_MACE, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 8,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 6,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 8,
        },
      },
    },
  ],
  genderId: GENDER_MIDDLE,
}
