const {
    SOURCE_IDRotF,
  } = require('./../../../../sourceList'),
  {
    CR_0,
  } = require('./../../../../crList'),
  {
    SIZE_TINY,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BEAST,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_DIG,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_KEEN_HEARING,
  } = require('./../../../../creatureAbilityList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_FOX,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Лиса',
  nameAlt: 'Песец',
  nameEn: 'Fox',
  id: CREATURE_FOX,
  description: `Песцы Долины Ледяного Ветра живут в норах и приспособлены к холодному климату.

Они рыщут по окраинам Десяти Городов и ближайшим лесам в поисках еды, охотясь на зайцев или крадя рыбу у рыбаков. Эти трусливые создания избегают гуманоидов, но иногда чвинги используют их как скакунов.`,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_IDRotF,
    page: 289,
  },
  armor: 13,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 5,
  },
  params: {
    [PARAM_STR]: 2,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 11,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_KEEN_HEARING,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceBonus: 1,
        },
      },
    },
  ],
}
