const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  SOURCE_DMG,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_TOA,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  CREATURE_BANDIT,
  CREATURE_DEINONYCHUS,
  CREATURE_DRAFT_HORSE,
  CREATURE_DROW,
  CREATURE_THUG,
} = require('./../../../creatureIdList')
const {
  CAT_ANIMALS,
  CAT_GAME_SET,
  CAT_MUSIC_TOOLS,
  CAT_POISONS,
  CAT_TOOLS,
} = require('./../../../gearCategoryList')
const {drowPoison} = require('./../../../textCommonParts')
const gameSetDescription = require('./../../constants/gameSetDescription')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {PARAM_CON} = require('./../../../paramList')
const {POISON_TYPE_INJURY} = require('./../../../poisonTypeList')

const {
  GEAR_CLOTHES_COSTUME,
  GEAR_DEINONYCHUS,
  GEAR_DICE_SET,
  GEAR_DISGUISE_KIT,
  GEAR_FLUTE,
  GEAR_LONGHORN,
  GEAR_DRAFT_HORSE,
  GEAR_DRAGON_CHESS,
  GEAR_DROW_POISON,
  GEAR_DRUM,
  GEAR_DULCIMER,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_DRAGON_CHESS,
    genderId: GENDER_MULTIPLE,
    name: 'Драконьи шахматы',
    nameByCase: {
      nominative: 'драконьи шахматы',
      genitive: 'драконьих шахмат',
      accusative: 'драконьи шахматы',
      instrumental: 'драконьими шахматами',
    },
    nameEn: 'Dragon Chess',
    description: gameSetDescription,
    cost: 100,
    weight: 0.5,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 83,
      },
    ],
    category: CAT_GAME_SET,
  },
  {
    id: GEAR_DICE_SET,
    genderId: GENDER_MULTIPLE,
    name: 'Кости',
    nameByCase: {
      nominative: 'кости',
      genitive: 'костей',
      accusative: 'кости',
      instrumental: 'костями',
    },
    nameEn: 'Dice Set',
    description: gameSetDescription,
    cost: 10,
    weight: 0,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 83,
      },
    ],
    category: CAT_GAME_SET,
  },
  {
    id: GEAR_DISGUISE_KIT,
    genderId: GENDER_MALE,
    name: 'Набор для грима',
    nameByCase: {
      nominative: 'набор для грима',
      genitive: 'набора для грима',
      accusative: 'набор для грима',
      instrumental: 'набором для грима',
    },
    nameEn: 'Disguise Kit',
    description: `Этот набор косметики, красителей для волос и бутафории позволяет изменять ваш внешний облик. Владение этим набором позволяет добавлять бонус мастерства к проверкам характеристик, совершённым для визуальной маскировки.

Отличный выбор для любого, кто хочет заниматься обманом, набор для грима позволяет своему владельцу принимать фальшивую личину.

**Компоненты.** Набор для грима включает в себя

* различную косметику,
* краску для волос,
* немного реквизита,
* несколько [нарядов](GEAR:${GEAR_CLOTHES_COSTUME}).

**Обман.** В некоторых случаях грим может помочь вам плести более убедительную ложь.

**Запугивание.** Правильный образ может сделать вас более устрашающим, когда Вы хотите кого-то отпугнуть, притворившись заражённым чумой, либо, когда запугиваете шайку [бандитов](CREATURE:${CREATURE_BANDIT}) под видом [громилы-головореза](CREATURE:${CREATURE_THUG}).

**Выступление.** Искусство перевоплощения может увеличить удовлетворённость публики выступлением, при условии, что обличье должным образом разработано, чтобы вызывать нужную реакцию.

**Убеждение.** Народ склонен доверять людям в униформе. Если Вы замаскировались под авторитетную фигуру, ваши попытки убедить других обычно более эффективны.

**Создание обличья.** Как часть длинного отдыха Вы можете создать обличье для маскировки. На надевание обличья после его создания требуется 1 минута. Вы можете одновременно иметь при себе только одно такое обличье, не привлекая ненужного внимания, если только у вас нет _Сумки хранения_ (Bag of holding) или другого подобного способа оставить их незамеченными. Каждое такое обличье весит 1 фунт.

В других случаях требуется 10 минут на создание обличья, которое немного изменяет вашу внешность и 30 минут, если требуются более обширные изменения.

| Действие                                            | СЛ |
|-----------------------------------------------------|----|
| Скрыть травмы или особые приметы                    | 10 |
| Распознать использование маскировки кем-либо другим | 15 |
| Скопировать внешность гуманоида                     | 20 |
`,
    cost: 2500,
    weight: 3,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 83,
      },
    ],
    category: CAT_TOOLS,
  },
  {
    id: GEAR_DRUM,
    genderId: GENDER_MALE,
    name: 'Барабан',
    nameByCase: {
      nominative: 'барабан',
      genitive: 'барабана',
      accusative: 'барабан',
      instrumental: 'барабаном',
    },
    nameEn: 'Drum',
    description: musicalToolDescriptionList,
    cost: 600,
    weight: 3,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_DULCIMER,
    genderId: GENDER_MALE,
    name: 'Дульцимер',
    nameByCase: {
      nominative: 'дульцимер',
      genitive: 'дульцимера',
      accusative: 'дульцимер',
      instrumental: 'дульцимером',
    },
    nameAlt: 'Цимбалы',
    nameEn: 'Dulcimer',
    description: musicalToolDescriptionList,
    cost: 2500,
    weight: 10,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_LONGHORN,
    genderId: GENDER_MALE,
    name: 'Длинный рог',
    nameEn: 'Longhorn',
    nameByCase: {
      nominative: 'длинный рог',
      genitive: 'длинного рога',
      accusative: 'длинный рог',
      instrumental: 'длинным рогом',
    },
    description: [
      `Сложная фаэрунская [флейта](GEAR:${GEAR_FLUTE}), которую можно найти только в регионах с высококвалифицированными ремесленниками, таких как крупнейшие города или эльфийские анклавы.`,
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_DRAFT_HORSE,
    genderId: GENDER_FEMALE,
    name: 'Лошадь-тяжеловоз',
    nameByCase: {
      nominative: 'лошадь-тяжеловоз',
      genitive: 'лошади-тяжеловоза',
      accusative: 'лошадь-тяжеловоз',
      instrumental: 'лошадью-тяжеловозом',
    },
    nameAlt: 'Лошадь, тягловая',
    nameEn: 'Draft Horse',
    creatureId: CREATURE_DRAFT_HORSE,
    description: 'Скорость 40 фт. Грузоподъёмность 540 фунтов.',
    cost: 5000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_DEINONYCHUS,
    genderId: GENDER_MALE,
    name: 'Дейноних',
    nameByCase: {
      nominative: 'дейноних',
      genitive: 'дейнониха',
      accusative: 'дейнониха',
      instrumental: 'дейнонихом',
    },
    nameEn: 'Deinonychus',
    creatureId: CREATURE_DEINONYCHUS,
    description: 'Скорость: 40 фт. Грузоподъёмность 225 фунтов.',
    cost: 25000,
    source: [
      {
        id: SOURCE_TOA,
        page: 29,
      },
      {
        id: SOURCE_TOA,
        page: 222,
      },
    ],
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_DROW_POISON,
    genderId: GENDER_MALE,
    name: 'Яд дроу',
    nameByCase: {
      nominative: 'яд дроу',
      genitive: 'яда дроу',
      accusative: 'яд дроу',
      instrumental: 'ядом дроу',
    },
    nameEn: 'Drow Poison',
    description: [
      `Обычно этот яд изготавливают только [дроу](CREATURE:${CREATURE_DROW}), к тому же в местах, где нет солнечного света.

Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 13, иначе оно станет отравленным на 1 час. Если испытание провалено на 5 или больше единиц, существо также лишено сознания, пока отравлено. Существо приходит в сознание, если получает урон или другое существо действием его потрясёт, чтобы разбудить.`,
      drowPoison,
    ],
    saveThrow: {
      type: PARAM_CON,
      dc: 13,
    },
    cost: 20000,
    poisonType: POISON_TYPE_INJURY,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
]

module.exports = gearRawList
