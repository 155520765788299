const {CREATURE_BERSERKER} = require('./../../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_HORN_OF_VALHALLA} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const {
  MAGIC_ITEM_HORN_OF_VALHALLA_BRASS,
  MAGIC_ITEM_HORN_OF_VALHALLA_BRONZE,
  MAGIC_ITEM_HORN_OF_VALHALLA_IRON,
  MAGIC_ITEM_HORN_OF_VALHALLA_SILVER,
} = require('./../../../../../magicItemIdList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_HORN_OF_VALHALLA,
  name: `Рог Валгаллы`,
  nameEn: `Horn of Valhalla`,
  rarity: generateRarityList(list),
  type: MGC_TYPE_WONDROUS_ITEM,
  description: `Вы можете действием подуть в этот рог. После этого в пределах 60 футов от Вас появляются духи воителей с плана Асгард. Эти духи используют статистику [берсерков](CREATURE:${CREATURE_BERSERKER}) из Бестиария. Они возвращаются в Асгард через 1 час, или когда их хиты опускаются до 0. Рог нельзя использовать повторно, пока не пройдёт 7 дней.

Существует четыре вида рогов Валгаллы, изготовленных из разных материалов. Вид рога определяет, сколько берсерков будет призвано, а также требования для их использования. Мастер определяет вид рога самостоятельно или случайным образом.

| к100  | Вид рога                                                       | Берсерки | Требование                             |
|-------|----------------------------------------------------------------|----------|----------------------------------------|
| 01–40 | [Серебряный](MAGIC_ITEM:${MAGIC_ITEM_HORN_OF_VALHALLA_SILVER}) | 2к4 + 2  | Нет                                    |
| 41–75 | [Латунный](MAGIC_ITEM:${MAGIC_ITEM_HORN_OF_VALHALLA_BRASS})    | 3к4 + 3  | Владение всеми видами простого оружия  |
| 76–90 | [Бронзовый](MAGIC_ITEM:${MAGIC_ITEM_HORN_OF_VALHALLA_BRONZE})  | 4к4 + 4  | Владение всеми видами средних доспехов |
| 91–00 | [Железный](MAGIC_ITEM:${MAGIC_ITEM_HORN_OF_VALHALLA_IRON})     | 5к4 + 5  | Владение всеми видами воинского оружия |

Если Вы подуете в рог, не выполняя требований, призванные берсерки нападут на Вас. Если Вы выполняете требования, они будут дружественны к Вам и Вашим спутникам, и будут выполнять Ваши команды.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 198,
  },
}
