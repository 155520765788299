const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_ABOLETH,
  CREATURE_FLUMPH,
  CREATURE_GITHYANKI_WARRIOR,
  CREATURE_GITHZERAI_MONK,
  CREATURE_MIND_FLAYER,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_ACID,
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {ALIGNMENT_LG} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_8} = require('./../../../../crList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPELL_LESSER_RESTORATION} = require('./../../../../spellIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Фламф`,
  nameEn: 'Flumph',
  id: CREATURE_FLUMPH,
  description: [
    `Таинственные фламфы перемещаются по Подземью за счёт испускаемых потоков воздуха, звучание которых и дало название этим существам. Фламфы слегка светятся, выражая своё настроение цветом. Нежно-розовый говорит о том, что существо довольно; тёмно-синий говорит о печали; зелёный выражает заинтересованность; красный — злость.`,
    {
      header: 'Разумные и мудрые',
      text: `Фламфы общаются при помощи телепатии. Выглядят они как медузы, но обладают огромным интеллектом, мудростью и обширными знаниями в религии, философии, математике и множестве других предметов.

Фламфы чувствуют эмоциональное состояние ближайших существ. Если мысли существа говорят о добрых намерениях, фламф стремится к нему. А встретив существо, излучающее зло, фламф скроется.`,
      source: {
        id: SOURCE_MM,
        page: 290,
      },
    },
    {
      header: 'Высасывание псионики',
      text: `Фламфы питаются, высасывая ментальную энергию из псионических существ, и их можно обнаружить близ поселений [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), [аболетов](CREATURE:${CREATURE_ABOLETH}), [гитьянки](CREATURE:${CREATURE_GITHYANKI_WARRIOR}) и [гитцераев](CREATURE:${CREATURE_GITHZERAI_MONK}). Пассивно паразитируя, они поглощают только необходимый им объём ментальной энергии, и большинство существ не ощущает потерь или дискомфорта от этого.

Поглощение псионической энергии открывает им мысли и эмоции существ, от которых фламфы питаются. Из-за того, что большинство этих существ злы, фламфы часто подвергаются воздействию их мыслей, эмоций и чаяний, которые отравляют чистую природу фламфов. Когда фламфы встречают добросердечных искателей приключений, они с радостью делятся тёмными секретами, о которых они узнали, в надежде, что это поможет повергнуть злые источники этих тайн, даже если это будет означать, что им придётся искать новый источник пропитания.`,
      source: {
        id: SOURCE_MM,
        page: 290,
      },
    },
    {
      header: 'Общество фламфов',
      text: `Фламфы живут сложными и организованными группами, называемыми _клуатрами_. В клуатре у каждого фламфа есть своё место и назначение. Такие гармоничные группы не нуждаются в лидерах, так как все фламфы вносят индивидуальный вклад в общее дело.`,
      source: {
        id: SOURCE_MM,
        page: 290,
      },
    },
  ],
  note: {
    text: `Верьте фламфу`,
    author: `первое правило выживания в подземельях Мистика Х`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_LG,
  source: {
    id: SOURCE_MM,
    page: 290,
  },
  armor: 12,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 10,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_PSYCHIC,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_UNDERCOMMON,
      doNotSpeak: true,
    },
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_1_8,
  featureList: [
    {
      name: 'Улучшенная телепатия',
      description: `★СУЩЕСТВО★ может воспринимать содержание любого телепатического общения, происходящего в пределах 60 футов от него, и не может быть захвачен врасплох существами, обладающими любой формой телепатии.`,
    },
    {
      name: 'Беспомощность лёжа',
      description: `Если ★СУЩЕСТВО★ сбит с ног, бросьте любую кость. Если выпадет нечет, ★СУЩЕСТВО★ падает на спину и становится ★недееспособным★. В конце каждого своего хода ★СУЩЕСТВО★ может проходить испытание Ловкости СЛ 10, переворачиваясь в случае успеха и переставая быть ★недееспособным★.`,
    },
    {
      name: 'Телепатический покров',
      description: `★СУЩЕСТВО★ обладает иммунитетом ко всем эффектам, чувствующим его эмоции и читающим его мысли, а также всем заклинаниям школы Прорицания.`,
    },
  ],
  actionList: [
    {
      name: `Усики`,
      description: `В конце каждого своего хода цель должна проходить испытание Телосложения СЛ 10, получая урон кислотой 2 (1к4) при провале или оканчивая продолжительный урон кислотой при успехе. Заклинание [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}) наложенное на цель, тоже оканчивает продолжительный урон кислотой.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 2,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 1,
            diceType: 4,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: `Вонючие брызги`,
      description: `Все существа в 15-футовом конусе, исходящем от ★СУЩЕСТВО★, должны пройти испытание Ловкости СЛ 10, иначе будут покрыты дурно пахнущей жидкостью. Обрызганное существо испускает отвратительную вонь 1к4 часа. Обрызганное существо отравлено, пока воняет, а другие существа отравлены, пока находятся в пределах 5 футов от обрызганного существа. Существо может избавиться от вони, потратив короткий отдых на купание в воде, спирте или уксусе.`,
      limit: {
        count: 1,
        period: 'день',
      },
    },
  ],
  genderId: GENDER_MALE,
}
