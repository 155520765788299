const {
  MAGIC_ITEM_SWORD_OF_ANSWERING_ANSWERER,
  MAGIC_ITEM_SWORD_OF_ANSWERING_BACK_TALKER,
  MAGIC_ITEM_SWORD_OF_ANSWERING_CONCLUDER,
  MAGIC_ITEM_SWORD_OF_ANSWERING_LAST_QUIP,
  MAGIC_ITEM_SWORD_OF_ANSWERING_REBUTTER,
  MAGIC_ITEM_SWORD_OF_ANSWERING_REPLIER,
  MAGIC_ITEM_SWORD_OF_ANSWERING_RETORTER,
  MAGIC_ITEM_SWORD_OF_ANSWERING_SCATHER,
  MAGIC_ITEM_SWORD_OF_ANSWERING_SQUELCHER,
} = require('./../../../../../magicItemIdList')
const {
  ALIGNMENT_CE,
  ALIGNMENT_CG,
  ALIGNMENT_CN,
  ALIGNMENT_LE,
  ALIGNMENT_LG,
  ALIGNMENT_LN,
  ALIGNMENT_N,
  ALIGNMENT_NE,
  ALIGNMENT_NG,
} = require('./../../../../../aligmentList')

const list = [
  {
    alignmentId: ALIGNMENT_LG,
    gemName: 'Аквамарин',
    id: MAGIC_ITEM_SWORD_OF_ANSWERING_RETORTER,
    name: 'Возразитель',
    nameEn: 'Retorter',
  },
  {
    alignmentId: ALIGNMENT_LE,
    gemName: 'Гранат',
    id: MAGIC_ITEM_SWORD_OF_ANSWERING_SCATHER,
    name: 'Грубитель',
    nameEn: 'Scather',
  },
  {
    alignmentId: ALIGNMENT_CE,
    gemName: 'Агат',
    id: MAGIC_ITEM_SWORD_OF_ANSWERING_BACK_TALKER,
    name: 'Дерзитель',
    nameEn: 'Back Talker',
  },
  {
    alignmentId: ALIGNMENT_NG,
    gemName: 'Топаз',
    id: MAGIC_ITEM_SWORD_OF_ANSWERING_REBUTTER,
    name: 'Опровергатель',
    nameEn: 'Rebutter',
  },
  {
    alignmentId: ALIGNMENT_CG,
    gemName: 'Изумруд',
    id: MAGIC_ITEM_SWORD_OF_ANSWERING_ANSWERER,
    name: 'Ответчик',
    nameEn: 'Answerer',
  },
  {
    alignmentId: ALIGNMENT_NE,
    gemName: 'Шпинель',
    id: MAGIC_ITEM_SWORD_OF_ANSWERING_SQUELCHER,
    name: 'Подавитель',
    nameEn: 'Squelcher',
  },
  {
    alignmentId: ALIGNMENT_N,
    gemName: 'Перидот',
    id: MAGIC_ITEM_SWORD_OF_ANSWERING_REPLIER,
    name: 'Рассказчик',
    nameEn: 'Replier',
  },
  {
    alignmentId: ALIGNMENT_LN,
    gemName: 'Аметист',
    id: MAGIC_ITEM_SWORD_OF_ANSWERING_CONCLUDER,
    name: 'Решатель',
    nameEn: 'Concluder',
  },
  {
    alignmentId: ALIGNMENT_CN,
    gemName: 'Турмалин',
    id: MAGIC_ITEM_SWORD_OF_ANSWERING_LAST_QUIP,
    name: 'Язвитель',
    nameEn: 'Last Quip',
  },
]

module.exports = list
