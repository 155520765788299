const {PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF_2019_08_15} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_08_15_WILD_ASTRAL} = require('./../../../sourceList')
const {
  FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15,
  FEATURE_AWAKENING_OF_THE_ASTRAL_SELF_2019_08_15,
  FEATURE_COMPLETE_ASTRAL_SELF_2019_08_15,
  FEATURE_EXTRA_ATTACK_MONK,
  FEATURE_VISAGE_OF_THE_ASTRAL_SELF_2019_08_15,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15,
    name: `Руки астрального Я`,
    nameEn: `Arms of the Astral Self`,
    lvl: [3, 11, 17],
    text: `Ваше владение Ци позволяет Вам вызывать часть своего астрального Я.

В свой ход Вы можете бонусным действием потратить 2 очка Ци, чтобы призвать _Руки своего астрального Я_ на 10 минут. Эти призрачные руки парят у Ваших плеч.

Вы определяете внешность _Рук_, основываясь на особенностях Вашего персонажа. 

Пока _Руки_ вызваны, Вы получаете следующие преимущества

* Вы можете использовать свой модификатор Мудрости вместо модификатора Силы при выполнении проверок Силы и испытаний Силы.
* _Руки_ считаются оружием монаха и имеют радиус досягаемости 10 футов. Они наносят урон излучением или некротический урон (по Вашему выбору). Когда Вы атакуете _Руками_, Вы можете использовать свой модификатор Мудрости вместо модификатора Силы или Ловкости для бросков атаки и урона.
* Сразу же после того, как Вы совершаете _Руками_ действие Атака в свой ход, Вы можете бонусным действием сделать ещё одну дополнительную атаку _Руками_. Количество дополнительных атак увеличивается, когда Вы достигаете определенных уровней в этом классе, увеличиваясь до двух на 11 уровне и трёх на 17 уровне.`,
    source: {
      id: SOURCE_UA_2019_08_15_WILD_ASTRAL,
      page: 2,
    },
  },
  {
    id: FEATURE_VISAGE_OF_THE_ASTRAL_SELF_2019_08_15,
    name: `Лик астрального Я`,
    nameEn: `Visage of the Astral Self`,
    lvl: 6,
    text: `Вы можете вызвать _Лик своего астрального Я_. В свой ход Вы можете бонусным действием (либо как часть призыва [Рук](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15})) потратить 1 очко Ци, чтобы вызвать _Лик своего астрального Я_ на 10 минут.

Призрачный _Лик_ покрывает Ваше лицо, как шлем или маска. Вы определяете его внешность исходя из особенностей Вашего персонажа.

Пока _Лик_ вызван, Вы получаете следующие преимущества

# Мудрость Духа

Вы получаете преимущество в проверках Мудрости (Проницательности) и Харизмы (Запугивания).

# Астральный Взгляд

Вы можете видеть в темноте, как магической, так и немагической, на расстоянии 120 футов.`,
    source: {
      id: SOURCE_UA_2019_08_15_WILD_ASTRAL,
      page: 3,
    },
  },
  {
    id: FEATURE_AWAKENING_OF_THE_ASTRAL_SELF_2019_08_15,
    name: `Пробуждение астрального Я`,
    nameEn: `Awakening of the Astral Self`,
    lvl: 11,
    text: `Вы наращиваете силу своего астрального Я.

Пока вызваны Ваши [Руки](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15}) и [Лик](FEATURE:${FEATURE_VISAGE_OF_THE_ASTRAL_SELF_2019_08_15}), Вы получаете следующие преимущества

# Отклонение энергии

Когда Вы получаете урон кислотой, холодом, огнём, молнией или силовым полем, Вы можете отклонить его реакцией. При этом получаемый Вами урон уменьшается на 1к10 + модификатор Мудрости + Ваш уровень монаха.

# Усиленные [Руки](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15})

Один раз, когда на своём ходу Вы попадаете по цели атакой [Руками](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15}), Вы можете нанести ей дополнительный урон, равный Вашей кости боевых искусств.

# Духовные слова

Когда Вы говорите через [Лик](FEATURE:${FEATURE_VISAGE_OF_THE_ASTRAL_SELF_2019_08_15}), Вы можете направить свои слова к видимому Вами в пределах 30 футов существу так, чтобы только это существо могло Вас слышать. Либо же Вы можете усилить свой голос так, чтобы Вас могли слышать все существа в пределах 600 футов.`,
    source: {
      id: SOURCE_UA_2019_08_15_WILD_ASTRAL,
      page: 3,
    },
  },
  {
    id: FEATURE_COMPLETE_ASTRAL_SELF_2019_08_15,
    name: `Цельное астральное Я`,
    nameEn: `Complete Astral Self`,
    lvl: 17,
    text: `Ваша связь с Вашим астральным Я завершена и Вы можете вызвать его целиком. В свой ход бонусным действием Вы можете потратить 10 очков Ци, вызывая [Руки](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15}), [Лик](FEATURE:${FEATURE_VISAGE_OF_THE_ASTRAL_SELF_2019_08_15}) и _Тело_ Вашего астрального Я на 10 минут. Призрачное _Тело_ покрывает Вашу физическую форму, как доспехи, соединяющие [Руки](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15}) и [Лик](FEATURE:${FEATURE_VISAGE_OF_THE_ASTRAL_SELF_2019_08_15}). Вы определяете его внешность исходя из особенностей Вашего персонажа.

Пока _Цельное астральное Я_ призвано, Вы получаете следующие преимущества

# Доспех Духа

Вы получаете бонус +2 к КД, пока дееспособны.

# Астральный шквал

Каждый раз, когда Вы используете способность [Дополнительная атака](FEATURE:${FEATURE_EXTRA_ATTACK_MONK}), чтобы атаковать дважды, Вы можете вместо этого атаковать три раза, используя [Руки](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15}).

# Поглощение Ци

Когда хиты существа в пределах 10 футов от Вас уменьшаются до 0, Вы можете реакцией восстановить количество очков Ци, равное модификатору Мудрости (минимум 1).`,
    source: {
      id: SOURCE_UA_2019_08_15_WILD_ASTRAL,
      page: 3,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF_2019_08_15,
  })
)
