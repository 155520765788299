const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_2,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_PLANT,
  CREATURE_TYPE_VEGEPYGMY,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  LANG_VEGEPYGMY,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_ELECTRICITY,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_VEGEPYGMY_CHIEF,
} = require('./../../../../creatureIdList')
const {
  ABILITY_PLANT_CAMOUFLAGE,
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  moldCreaturesDescriptionList,
  moldCreaturesNote,
} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Вегепигмей вождь',
  nameAlt: 'Плесневик вождь',
  nameEn: 'Vegepygmy Chief',
  id: CREATURE_VEGEPYGMY_CHIEF,
  description: moldCreaturesDescriptionList,
  note: moldCreaturesNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
    CREATURE_TYPE_VEGEPYGMY,
  ],
  alignmentId: ALIGNMENT_N,
  source: [
    {
      id: SOURCE_VGTM,
      page: 133,
    },
    {
      id: SOURCE_TOA,
      page: 216,
    },
  ],
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_PIERCING,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_VEGEPYGMY,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_PLANT_CAMOUFLAGE,
    {
      id: ABILITY_REGENERATION,
      regen: 3,
      description: `. Если ★СУЩЕСТВО★ получает урон холодом, огнём или некротический урон, то эта особенность не работает в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только если начинает ход с 0 хитов и не может регенерировать`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Когтями_ или две атаки _Копьём_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_SPEAR,
    },
    {
      name: 'Споры',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Облако токсичных спор радиусом 15 футов испускается из ★СУЩЕСТВО★. Споры огибают углы. Каждое существо в этой области, которое не является растением, должно пройти испытание Телосложения СЛ 12 или станет отравленным. Отравленная таким образом цель получает 9 (2к8) урона ядом в начале каждого своего хода. Цель может повторять испытание в конце каждого своего хода и окончить этот эффект на себе при успехе.`,
    },
  ],
}
