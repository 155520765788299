const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {howlingHatredDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_HURRICANE,
  CREATURE_SKYWEAVER,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  SPELL_BLADE_WARD,
  SPELL_FEATHER_FALL,
  SPELL_FLY,
  SPELL_GUST_OF_WIND,
  SPELL_INVISIBILITY,
  SPELL_LIGHT,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_ARMOR,
  SPELL_MESSAGE,
  SPELL_RAY_OF_FROST,
  SPELL_SHOCKING_GRASP,
  SPELL_WITCH_BOLT,
} = require('./../../../../spellIdList')
const {
  LANG_AURAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Небесный ткач',
  nameEn: 'Skyweaver',
  id: CREATURE_SKYWEAVER,
  description: [
    {
      header: 'Небесные ткачи',
      text: `Небесные ткачи переполнены яростью и страстями, что делает их прекрасным сосудом для дикой, разрушительной магии культа Воющей Ненависти. В отличие от своих братьев по культу воздуха — [ураганов](CREATURE:${CREATURE_HURRICANE}) — небесные ткачи поклоняются Стихийному Злу в надежде обрести лёгкий путь к могуществу. У них не так много опыта в обращении с магией стихий, поэтому, когда небесные ткачи чувствуют прилив сырой стихийной энергии, они начинают дрожать от возбуждения.`,
      source: {
        id: SOURCE_PotA,
        page: 195,
      },
    },
    ...howlingHatredDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_PotA,
    page: 195,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  languageList: [
    LANG_AURAN,
    LANG_COMMON,
  ],
  cr: CR_3,
  spellCast: {
    spellCasterLevel: 6,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_BLADE_WARD,
      SPELL_FEATHER_FALL,
      SPELL_FLY,
      SPELL_GUST_OF_WIND,
      SPELL_INVISIBILITY,
      SPELL_LIGHT,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGE_ARMOR,
      SPELL_MESSAGE,
      SPELL_RAY_OF_FROST,
      SPELL_SHOCKING_GRASP,
      SPELL_WITCH_BOLT,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
