const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_IMMOVABLE_OBJECT} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_IMMOVABLE_OBJECT,
  name: 'Неподвижный предмет',
  nameEn: 'Immovable Object',
  description: `Вы касаетесь предмета весом не более 10 фунтов и магически закрепляете его в пространстве.

Вы и существа, выбранные Вами при накладывании этого заклинания, могут передвигать этот предмет как обычно. Вы можете указать пароль, когда накладываете это заклинание. Если пароль будет произнесён в пределах 5 футов от предмета, заклинание будет подавлено на 1 минуту.

Предмет может выдержать до 4000 фунтов веса. Больший вес заставит предмет упасть. Существо может действием совершить проверку Силы, при успехе сдвигая закреплённый предмет на расстояние до 10 футов.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 или 5 уровня, СЛ сдвигания предмета увеличивается на 5 и он может выдержать до 8 000 фунтов веса. Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, СЛ сдвигания предмета увеличивается на 10, он может выдержать до 20 000 фунтов веса и эффект становится постоянным, пока не развеется.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `золотая пыль стоимостью не менее 25 зм, расходуемые заклинанием`,
  hasCost: true,
  hasConsumable: true,
  isDunamisBased: true,
  duration: { timeId: TIME_HOUR, count: 1 },
  source: {
    id: SOURCE_EGTW,
    page: 188,
  },
}
