const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_TRICKERY_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  SPELL_BLINK,
  SPELL_CHARM_PERSON,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISPEL_MAGIC,
  SPELL_DOMINATE_PERSON,
  SPELL_MIRROR_IMAGE,
  SPELL_MODIFY_MEMORY,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_POLYMORPH,
} = require('./../../../spellIdList')
const {
  FEATURE_BLESSING_OF_THE_TRICKSTER,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_CLOAK_OF_SHADOWS,
  FEATURE_CHANNEL_DIVINITY_INVOKE_DUPLICITY,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_STRIKE_TRICKERY_DOMAIN,
  FEATURE_IMPROVED_DUPLICITY,
  FEATURE_TRICKERY_DOMAIN_SPELLS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_TRICKERY_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_BLINK,
      SPELL_CHARM_PERSON,
      SPELL_DIMENSION_DOOR,
      SPELL_DISGUISE_SELF,
      SPELL_DISPEL_MAGIC,
      SPELL_DOMINATE_PERSON,
      SPELL_MIRROR_IMAGE,
      SPELL_MODIFY_MEMORY,
      SPELL_PASS_WITHOUT_TRACE,
      SPELL_POLYMORPH,
    ],
  },
  {
    id: FEATURE_BLESSING_OF_THE_TRICKSTER,
    name: `Благословение обманщика`,
    nameEn: `Blessing of the Trickster`,
    lvl: 1,
    text: `Вы можете действием коснуться согласного существа, отличного от Вас, чтобы позволить ему совершать с преимуществом проверки Ловкости (Скрытность).

Это благословение длится 1 час, или Вы не используете это умение снова.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_INVOKE_DUPLICITY,
    name: `Божественный канал: Двуличность`,
    nameEn: `Channel Divinity: Invoke Duplicity`,
    lvl: 2,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы создать иллюзорную копию себя.

Вы действием создаёте идеальную иллюзию себя, которая существует в течение 1 минуты, или пока Вы не потеряете концентрацию (как при концентрирации на заклинании). Иллюзия появляется в свободном пространстве, видимом Вами в пределах 30 футов.

Бонусным действием в свой ход Вы можете переместить иллюзию на расстояние до 30 футов в видимое Вами пространство, но иллюзия должна оставаться в пределах 120 футов от Вас.

На протяжении действия умения Вы можете творить заклинания, как если бы Вы находились в пространстве иллюзии, но должны использовать собственные чувства. Кроме того, когда и Вы и Ваша иллюзия находитесь в пределах 5 футов от существа, которое может видеть иллюзию, Вы совершаете броски атаки по этому существу с преимуществом, так как иллюзия отвлекает его.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_CLOAK_OF_SHADOWS,
    name: `Божественный канал: Плащ теней`,
    nameEn: `Channel Divinity: Cloak of Shadows`,
    lvl: 6,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы исчезать.

Вы действием становитесь невидимым до конца своего следующего хода. Вы становитесь видимым, если атакуете или сотворяете заклинание. `,
  },
  {
    id: FEATURE_DIVINE_STRIKE_TRICKERY_DOMAIN,
    name: `Божественный удар`,
    nameEn: `Divine Strike`,
    lvl: [8, 14],
    text: `Вы получаете способность наполнять удары своего оружия ядом — это дар Вашего божества.

Один раз в каждый свой ход, когда Вы попадаете по существу атакой оружием, Вы можете причинить цели дополнительный урон ядом 1к8.

Когда Вы достигаете 14 уровня, дополнительный урон возрастает до 2к8.`,
  },
  {
    id: FEATURE_IMPROVED_DUPLICITY,
    name: `Улучшенная двуличность`,
    nameEn: `Improved Duplicity`,
    lvl: 17,
    text: `Вы можете создать до четырёх копий себя, вместо одной, когда используете [Двуличность](FEATURE:${FEATURE_CHANNEL_DIVINITY_INVOKE_DUPLICITY}).

Бонусным действием в свой ход Вы можете переместить любое количество копий на 30 футов, максимальная дистанция от Вас не должна превышать 120 футов.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_TRICKERY_DOMAIN,
    source: {
      id: SOURCE_PHB,
      page: 63,
    },
  })
)
