const {SOURCE_MM} = require('./../../sourceList')
const {GOD_MAGLUBIYET} = require('./../../godIdList')
const {PC_RACE_HOBGOBLIN} = require('./../../pcRaceIdList')

module.exports = {
  header: 'Армия Маглубиета',
  text: `[Хобгоблины](PC_RACE:${PC_RACE_HOBGOBLIN}) поклоняются [Маглубиету Могучему](GOD:${GOD_MAGLUBIYET}), великому богу гоблиноидов.

Хобгоблины не боятся смерти, они верят, что если они умрут в бою, их духи присоединяться к армии [Маглубиета](PC_RACE:${PC_RACE_HOBGOBLIN}) на плане Ахерон.`,
  source: {
    id: SOURCE_MM,
    page: 293,
  },
}
