const {
  CREATURE_COW,
  CREATURE_ROTHE,
} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Роф',
  nameEn: 'Rothe',
  id: CREATURE_ROTHE,
  parentId: CREATURE_COW,
  description: `Обычные рофы напоминают овцебыков.`,
  creatureId: GENDER_MALE,
  source: {
    id: SOURCE_VGTM,
    page: 210,
  },
  extendPropCollection: {
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 30,
      },
    ],
  },
}
