module.exports = {
  text: `Круглая, но плоская

Алтарь Собачьих Владык

Самоцвет на черном бархате

Жемчужина в темном море

Переменчивая, но вечно неизменная`,
  author: `Загадка гиносфинкса из горы Белый Шлейф`,
}
