const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {SOURCE_AI} = require('./../../../../../sourceList')
const {SPELL_MOTIVATIONAL_SPEECH} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_MOTIVATIONAL_SPEECH ,
  name: 'Мотивационная речь',
  nameEn: 'Motivational Speech',
  description: `Вы обращаетесь к союзникам, персоналу, или невинным прохожим, чтобы убеждать и вдохновлять их на величие, вне зависимости от того, есть ли что-то, чему им стоит радоваться.
    
Выберите до пяти существ в пределах дистанции, которые могут Вас слышать. За время действия каждое выбранное существо получает 5 временных хитов и получает преимущество к испытаниям Мудрости. Если по этому существу попадают атакой, оно получает преимущество к своему следующему броску атаки.

Как только существо теряет временные хиты, созданные этим заклинанием, оно прекращает на него действовать.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, временные хиты увеличиваются на 5 за каждый круг ячейки выше третьего.`,
  lvl: 3,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 60,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_HOUR, count: 1 },
  castTime: { timeId: TIME_MINUTE, count: 1 },
  source: {
    id: SOURCE_AI,
    page: 78,
  },
}
