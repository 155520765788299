const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_XGTE,
  SOURCE_ERRATA_XGTE,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../../attackTypeList')
const {CONDITION_BLINDED} = require('./../../../../../conditionList')
const {DAMAGE_RADIANT} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_HOLY_WEAPON} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_HOLY_WEAPON,
  name: 'Священное оружие',
  nameEn: 'Holy Weapon',
  description: `Вы наполняете оружие, которого касаетесь, святой силой. Пока заклинание активно, оружие излучает яркий свет в радиусе 30 футов и тусклый свет в пределах еще 30 футов. Кроме того, атаки, совершенные этим оружием, наносят дополнительный урон светом 2к8 при попадании. Если оружие ещё не является волшебным, оно становится таковым на время действия заклинания.

Бонусным действием Вы можете отменить это заклинание и создать вспышку света, исходящую из оружия. Каждое существо по Вашему выбору, которое Вы видите в пределах 30 футов от оружия, должно пройти испытание Телосложения. При провале испытания существо получает 4к8 урона излучением, и ослепляется на 1 минуту.

При успешном испытании существо получает половину урона и не ослепляется. В конце каждого своего хода ослеплённое существо может повторить испытание Телосложения. При успехе эффект на нём оканчивается.`,
  lvl: 5,
  magicSchoolId: MAGIC_EVOCATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 1},
  castTime: {timeId: TIME_BONUS_ACTION, count: 1},
  needConcentration: true,
  effect: [
    {
      attackType: ATTACK_MELEE_WEAPON,
      damage: {
        type: DAMAGE_RADIANT,
        diceCount: 2,
        diceType: 8,
      },
    },
    {
      savethrowParam: PARAM_CON,
      condition: CONDITION_BLINDED,
      damage: {
        type: DAMAGE_RADIANT,
        halfOnSuccess: true,
        diceCount: 4,
        diceType: 8,
      },
    },
  ],
  source: [
    {
      id: SOURCE_XGTE,
      page: 166,
    },
    {
      id: SOURCE_ERRATA_XGTE,
      page: 1,
    },
  ],
}
