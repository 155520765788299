const {PC_SUBCLASS_RANGER_DRAKEWARDEN_2020_10_26} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_10_26_SUBCLASSES_PART_5} = require('./../../../sourceList')
const {SPELL_THAUMATURGY} = require('./../../../spellIdList')

const {
  FEATURE_DRACONIC_GIFT_2020_10_26,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_DRACONIC_GIFT_2020_10_26,
    name: `Драконий дар`,
    nameEn: `Draconic Gift`,
    lvl: 3,
    spellIdList: [
      SPELL_THAUMATURGY
    ],
    source: {
      id: SOURCE_UA_2020_10_26_SUBCLASSES_PART_5,
      page: 3,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_RANGER_DRAKEWARDEN_2020_10_26,
  })
)
