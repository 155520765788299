const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_GIANT_OWL,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_GIANT_OWL,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык гигантских сов',
    genitive: 'Языка гигантских сов',
    instrumental: 'Языком гигантских сов',
    prepositional: 'Языке гигантских сов',
  },
  nameEn: 'Giant Owl',
  isReady: false,
  isRealLang: true,
}
