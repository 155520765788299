const {druidCircleSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')

const {
  SPELL_BARKSKIN,
  SPELL_BLIGHT,
  SPELL_BLUR,
  SPELL_CALL_LIGHTNING,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_CONE_OF_COLD,
  SPELL_CONJURE_ELEMENTAL,
  SPELL_CONTROL_WATER,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_DARKNESS,
  SPELL_DAYLIGHT,
  SPELL_DIVINATION,
  SPELL_DREAM,
  SPELL_ENTANGLE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HASTE,
  SPELL_HOLD_PERSON,
  SPELL_ICE_STORM,
  SPELL_INSECT_PLAGUE,
  SPELL_INVISIBILITY,
  SPELL_LIGHTNING_BOLT,
  SPELL_LOCATE_CREATURE,
  SPELL_MELD_INTO_STONE,
  SPELL_MELF_S_ACID_ARROW,
  SPELL_MIRROR_IMAGE,
  SPELL_MISTY_STEP,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_PASSWALL,
  SPELL_PLANT_GROWTH,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_SCRYING,
  SPELL_SILENCE,
  SPELL_SLEET_STORM,
  SPELL_SLOW,
  SPELL_SPIDER_CLIMB,
  SPELL_SPIKE_GROWTH,
  SPELL_STINKING_CLOUD,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_TREE_STRIDE,
  SPELL_WALL_OF_STONE,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
} = require('./../../../spellIdList')
const {
  FEATURE_BONUS_CANTRIP_CIRCLE_OF_THE_LAND,
  FEATURE_CIRCLE_OF_THE_LAND_SPELLS_ARCTIC,
  FEATURE_CIRCLE_OF_THE_LAND_SPELLS_COAST,
  FEATURE_CIRCLE_OF_THE_LAND_SPELLS_DESERT,
  FEATURE_CIRCLE_OF_THE_LAND_SPELLS_FOREST,
  FEATURE_CIRCLE_OF_THE_LAND_SPELLS_GRASSLAND,
  FEATURE_CIRCLE_OF_THE_LAND_SPELLS_MOUNTAIN,
  FEATURE_CIRCLE_OF_THE_LAND_SPELLS_SWAMP,
  FEATURE_LAND_S_STRIDE,
  FEATURE_NATURAL_RECOVERY,
  FEATURE_NATURE_S_SANCTUARY,
  FEATURE_NATURE_S_WARD,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_CIRCLE_OF_THE_LAND_SPELLS_ARCTIC,
    name: `Заклинания круга Земли: Тундра`,
    nameEn: `Circle of the Land Spells: Arctic`,
    lvl: 2,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_COMMUNE_WITH_NATURE,
      SPELL_CONE_OF_COLD,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_HOLD_PERSON,
      SPELL_ICE_STORM,
      SPELL_SLEET_STORM,
      SPELL_SLOW,
      SPELL_SPIKE_GROWTH,
    ],
  },
  {
    id: FEATURE_CIRCLE_OF_THE_LAND_SPELLS_COAST,
    name: `Заклинания круга Земли: Побережье`,
    nameEn: `Circle of the Land Spells: Coast`,
    lvl: 2,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_CONJURE_ELEMENTAL,
      SPELL_CONTROL_WATER,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_MIRROR_IMAGE,
      SPELL_MISTY_STEP,
      SPELL_SCRYING,
      SPELL_WATER_BREATHING,
      SPELL_WATER_WALK,
    ],
  },
  {
    id: FEATURE_CIRCLE_OF_THE_LAND_SPELLS_DESERT,
    name: `Заклинания круга Земли: Пустыня`,
    nameEn: `Circle of the Land Spells: Desert`,
    lvl: 2,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_BLIGHT,
      SPELL_BLUR,
      SPELL_CREATE_FOOD_AND_WATER,
      SPELL_HALLUCINATORY_TERRAIN,
      SPELL_INSECT_PLAGUE,
      SPELL_PROTECTION_FROM_ENERGY,
      SPELL_SILENCE,
      SPELL_WALL_OF_STONE,
    ],
  },
  {
    id: FEATURE_CIRCLE_OF_THE_LAND_SPELLS_FOREST,
    name: `Заклинания круга Земли: Лес`,
    nameEn: `Circle of the Land Spells: Forest`,
    lvl: 2,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_BARKSKIN,
      SPELL_CALL_LIGHTNING,
      SPELL_COMMUNE_WITH_NATURE,
      SPELL_DIVINATION,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_PLANT_GROWTH,
      SPELL_SPIDER_CLIMB,
      SPELL_TREE_STRIDE,
    ],
  },
  {
    id: FEATURE_CIRCLE_OF_THE_LAND_SPELLS_GRASSLAND,
    name: `Заклинания круга Земли: Луг`,
    nameEn: `Circle of the Land Spells: Grassland`,
    lvl: 2,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_DAYLIGHT,
      SPELL_DIVINATION,
      SPELL_DREAM,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_HASTE,
      SPELL_INSECT_PLAGUE,
      SPELL_INVISIBILITY,
      SPELL_PASS_WITHOUT_TRACE,
    ],
  },
  {
    id: FEATURE_CIRCLE_OF_THE_LAND_SPELLS_MOUNTAIN,
    name: `Заклинания круга Земли: Гора`,
    nameEn: `Circle of the Land Spells: Mountain`,
    lvl: 2,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_LIGHTNING_BOLT,
      SPELL_MELD_INTO_STONE,
      SPELL_PASSWALL,
      SPELL_SPIDER_CLIMB,
      SPELL_SPIKE_GROWTH,
      SPELL_STONE_SHAPE,
      SPELL_STONESKIN,
      SPELL_WALL_OF_STONE,
    ],
  },
  {
    id: FEATURE_CIRCLE_OF_THE_LAND_SPELLS_SWAMP,
    name: `Заклинания круга Земли: Болото`,
    nameEn: `Circle of the Land Spells: Swamp`,
    lvl: 2,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_DARKNESS,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_INSECT_PLAGUE,
      SPELL_LOCATE_CREATURE,
      SPELL_MELF_S_ACID_ARROW,
      SPELL_SCRYING,
      SPELL_STINKING_CLOUD,
      SPELL_WATER_WALK,
    ],
  },
  {
    id: FEATURE_BONUS_CANTRIP_CIRCLE_OF_THE_LAND,
    name: `Дополнительный заговор`,
    nameEn: `Bonus Cantrip`,
    lvl: 2,
    text: `Вы осваиваете один дополнительный заговор друида на свой выбор.`,
  },
  {
    id: FEATURE_NATURAL_RECOVERY,
    name: `Естественное восстановление`,
    nameEn: `Natural Recovery`,
    lvl: 2,
    text: `Единение с природой через медитацию позволяет Вам частично восстановить магическую силу.

Во время короткого отдыха Вы можете восстановить потраченные ячейки заклинаний. Сумма кругов выбранных ячеек не должна превышать половину уровня друида (округляя в большую сторону), и ни одна ячейка не может быть 6 круга или выше. Применение этого умения восстанавливается после длинного отдыха.

Например, будучи друидом 4 уровня, Вы можете восстановить два круга ячеек заклинаний. Вы можете восстановить или ячейку 2 круга или две ячейки 1 круга.`,
  },
  {
    id: FEATURE_LAND_S_STRIDE,
    name: `Тропами земли`,
    nameEn: `Land’s Stride`,
    lvl: 6,
    text: `Перемещение по немагической труднопроходимой местности не затрудняет перемещение. Вы также можете проходить через немагическую растительность без снижения скорости и не получая урона от её шипов, выростов и других помех.

Кроме того, Вы получаете преимущество к испытаниям от магически созданных или изменённых растений, должных затруднять перемещение — например, от заклинания [Опутывание](SPELL:${SPELL_ENTANGLE}).`,
  },
  {
    id: FEATURE_NATURE_S_WARD,
    name: `Покровительство природы`,
    nameEn: `Nature’s Ward`,
    lvl: 10,
    text: `Вы не можете быть очарованным или напуганным феями и элементалями.

Вы также получаете иммунитет к болезням и ядам.`,
  },
  {
    id: FEATURE_NATURE_S_SANCTUARY,
    name: `Природная благодать`,
    nameEn: `Nature’s Sanctuary`,
    lvl: 14,
    text: `Природные создания начинают чувствовать Вашу тесную связь с естественным миром и остерегаются нападать.

Когда зверь или растение атакует Вас, это существо должно пройти испытание Мудрости против СЛ Ваших заклинаний друида. При провале существо должно выбрать иную цель, или же его атака автоматически промахивается. При успехе существо получает иммунитет к этому эффекту на 24 часа.

Существо осознаёт этот эффект ещё до попытки атаковать Вас.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND,
    source: {
      id: SOURCE_PHB,
      page: 69,
    },
  })
)

