const {
  SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ELEMENTAL_AIR,
  CREATURE_TYPE_ELEMENTAL,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ITS_CREATOR,
  LANG_PRIMORDIAL,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  CREATURE_AIR_ELEMENTAL_SPIRIT,
  CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
} = require('./../../../../spellIdList')
const {
  ABILITY_AMORPHOUS,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Дух воздушного элементаля',
  nameEn: 'Air Elemental Spirit',
  id: CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
  releasedAs: CREATURE_AIR_ELEMENTAL_SPIRIT,
  createdBySpellId: SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_AIR,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
    page: 5,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `модификатор Телосложения духа + модификатор заклинательной характеристики заклинателя + десятикратный круг заклинания`,
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: {
      value: 40,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 17,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 16,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  resistanceList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_THUNDER,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_PRIMORDIAL,
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  featureList: [
    ABILITY_AMORPHOUS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 4,
          comment: `+ круг заклинания`,
        },
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 4,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
