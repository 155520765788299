const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_COMMAND,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_CONFUSION,
  SPELL_DETECT_THOUGHTS,
  SPELL_DOMINATE_MONSTER,
} = require('./../../../../spellIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SIZE_MEDIUM,
  SIZE_TINY,
} = require('./../../../../sizeList')
const {ABILITY_SWARM} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_5} = require('./../../../../crList')
const {craniumRatsDescriptionList} = require('./../../../../textCommonParts')
const {CREATURE_SWARM_OF_CRANIUM_RATS} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_TELEPATHY} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Стая черепных крыс',
  nameEn: 'Swarm of Cranium Rats',
  id: CREATURE_SWARM_OF_CRANIUM_RATS,
  description: craniumRatsDescriptionList,
  sizeType: SIZE_MEDIUM,
  swarmMemberSizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 203,
  },
  armor: 12,
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 14,
  },
  resistanceList: [
    DAMAGE_BLUDGEONING,
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  languageList: [
    {
      id: LANG_TELEPATHY,
      range: 30,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_SWARM,
    {
      name: 'Свечение',
      description: `Бонусным действием стая может испускать тусклый свет из мозгов в радиусе 5 футов, увеличить свечение до яркого света в радиусе от 5 до 20 футов (и тусклого свет ещё на столько же футов), или гасить его.`,
    },
    {
      name: 'Телепатический покров',
      description: `Стая имеет иммунитет к любому эффекту, который может почувствовать её эмоции или прочесть её мысли, а так же ко всем заклинаниям школы прорицания.`,
    },
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_COMMAND,
          SPELL_COMPREHEND_LANGUAGES,
          SPELL_DETECT_THOUGHTS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONFUSION,
          SPELL_DOMINATE_MONSTER,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Укусы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 0,
        target: {
          count: 1,
          limit: {
            comment: ` в пространстве стаи`,
          },
        },
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 4,
              diceType: 6,
            },
            {
              type: DAMAGE_PIERCING,
              diceCount: 2,
              diceType: 6,
              diceBonus: 0,
              comment: `, если у стаи половина хитов или меньше`,
            },
          ],
        ],
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
