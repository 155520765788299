const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {LANG_LOXODON} = require('./../../languageIdList')

module.exports = {
  id: LANG_LOXODON,
  type: LANG_TYPE_EXOTIC,
  nameEn: 'Loxodon',
  name: {
    nominative: 'Локсодонский',
    genitive: 'Локсодонского',
    instrumental: 'Локсодонском',
    prepositional: 'Локсодонском',
  },
  isReady: false,
  isRealLang: true,
}
