const {CREATURE_TYPE_BUGBEAR} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {bugbearsDescriptionList} = require ('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_BUGBEAR,
  nameEn: 'Bugbear',
  nameAlt: 'медвежатники',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'багбир',
        genitive: 'багбира',
        dative: 'багбиру',
        accusative: 'багбира',
        instrumental: 'багбиром',
        prepositional: 'багбире',
      },
      [GENDER_FEMALE]: {
        nominative: 'багбирша',
        genitive: 'багбирши',
        dative: 'багбирше',
        accusative: 'багбиршу',
        instrumental: 'багбиршей',
        prepositional: 'багбирше',
      },
    },
    plural: {
      nominative: 'багбиры',
      genitive: 'багбиров',
      dative: 'багбирам',
      accusative: 'багбиров',
      instrumental: 'багбирами',
      prepositional: 'багбирах',
    },
  },
  description: bugbearsDescriptionList,
}
