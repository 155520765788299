const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1,
} = require('./../../../../crList')
const {
  SIZE_TINY,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_IMP,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Бес',
  nameEn: 'Imp',
  id: CREATURE_IMP,
  description: [
    `Бесы могут быть замечены повсеместно на Нижних Планах. Они работают на побегушках у дьявольских господ, шпионят за конкурентами, а также вводят в заблуждение и подстрекают смертных. Бесы будут с гордостью служить своим хозяевам, но на них нельзя положиться, если задача должна быть выполнена эффективно и быстро.

Бес может принимать животный облик по своему желанию, но в естественном состоянии он напоминает крохотного краснокожего гуманоида с колючим хвостом, маленькими рожками и кожаными крыльями. Они нападают из невидимости, ударяя своим ядовитым жалом.`,
    {
      header: `Бесы`,
      text: `Бесы, эти мелкие адские пакостники, обычно состоят на службе у более могущественных дьяволов.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    ...devilDescriptionList,
  ],
  note: devilNote,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 144,
  },
  armor: 13,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 13,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    LANG_COMMON,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием принять звериный облик, напоминающий ворона (20 фт., летая 60 фт.), крысу (скорость 20 фт.) или паука (20 фт., лазая 20 фт.), или принять свой истинный облик. Во всех обликах его статистика остаётся той же самой, исключая указанные изменения скорости. Всё несомое и носимое им снаряжение не превращается. Он принимает свой истинный облик, если умирает.`,
    },
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Жало',
      comment: 'в истинном облике',
      description: `Цель должна пройти испытание Телосложения СЛ 11, получая урон ядом 10 (3к6) при провале, или половину этого урона при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Укус',
      comment: 'в зверином облике',
      description: `Цель должна пройти испытание Телосложения СЛ 11, получая урон ядом 10 (3к6) при провале, или половину этого урона при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Невидимость',
      description: `★СУЩЕСТВО★ магическим образом становится ★невидимым★, пока не атакует или не утратит концентрацию (как при концентрации на заклинании). Всё снаряжение, которое ★СУЩЕСТВО★ несёт или носит, становится невидимым вместе с ★ним★.`,
    },
  ],
  genderId: GENDER_MALE,
}
