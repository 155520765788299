const {
  SPELL_BLUR,
  SPELL_DISPEL_MAGIC,
  SPELL_FIREBALL,
  SPELL_FLAMING_SPHERE,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_REMOVE_CURSE,
  SPELL_SHIELD,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_SKELETON,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_ARCANA,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_RANGE_SPELL} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_VERBAL} = require('./../../../../castComponentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_FLAMESKULL} = require('./../../../../creatureIdList')
const {GEAR_HOLY_WATER} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Пылающий череп',
  nameEn: 'Flameskull',
  id: CREATURE_FLAMESKULL,
  description: [
    {
      header: 'Пылающий череп',
      text: `Сверкание зелёного пламени и безумный, раскатистый смех следуют за бестелесным черепом, когда тот патрулирует свою территорию. Когда неживой пылающий череп обнаруживает вторжение, он атакует нарушителей огненными лучами из глаз и ужасными заклинаниями, вызываемыми из тёмного кладезя его памяти.

Тёмные заклинатели создают пылающие черепа из останков мёртвых [волшебников](PC_CLASS:${PC_CLASS_WIZARD}). По завершении ритуала из черепа вырывается зелёное пламя, знаменуя его ужасающее перерождение.`,
      source: {
        id: SOURCE_MM,
        page: 247,
      },
    },
    {
      header: 'Наследие жизни',
      text: `Пылающий череп лишь смутно помнит свою прошлую жизнь. И хотя он и может говорить своим прежним голосом и вспоминать важные события из своего прошлого, это всего лишь отголосок его прошлой личности. Впрочем, трансформация в нежить оставляет ему полный доступ к магии, коей он обладал при жизни, позволяя творить заклинания даже без материальных и соматических компонентов, которые он не способен предоставить, по понятным причинам.`,
      source: {
        id: SOURCE_MM,
        page: 247,
      },
    },
    {
      header: 'Связанные навек',
      text: `Разумные и бдительные, пылающие черепа служат своему создателю, защищая спрятанные клады, секретные места или конкретных личностей. Пылающие черепа выполняют приказы, отданные им при их создании, и толкуют их буквально. Создатель пылающего черепа должен хорошенько продумать приказы, чтобы быть уверенным, что существо выполнит их верно.`,
      source: {
        id: SOURCE_MM,
        page: 247,
      },
    },
    {
      header: 'Охваченные пламенем',
      text: `Пламя, покрывающее пылающий череп, горит постоянно, создавая яркий свет от существа. Череп контролирует пламя и может использовать его в качестве оружия — фокусируя его в лучи из своих пустых глазниц.`,
      source: {
        id: SOURCE_MM,
        page: 247,
      },
    },
    {
      header: 'Жуткое возрождение',
      text: `Пылающий череп вновь собирается из разбитых кусков, если его не окропить [святой водой](GEAR:${GEAR_HOLY_WATER}) или не подвергнуть заклинаниям [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) или [Снятия проклятья](SPELL:${SPELL_REMOVE_CURSE}). Если он не может более выполнять то, для чего был создан, возрождённый пылающий череп более никому не принадлежит и действует независимо.`,
      source: {
        id: SOURCE_MM,
        page: 247,
      },
    },
    {
      header: 'Природа нежити',
      text: `Пылающему черепу не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 247,
      },
    },
  ],
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 247,
  },
  armor: 13,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 40,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 14,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_PIERCING,
    DAMAGE_NECROTIC,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_4,
  featureList: [
    {
      name: `Свечение`,
      description: `★СУЩЕСТВО★ испускает тусклый свет в радиусе 15 футов, либо яркий свет в радиусе 15 футов и тусклый свет в пределах ещё 15 футов. ★Он★ может действием переключаться между этими двумя вариантами.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    {
      name: `Восстановление`,
      description: `Если ★СУЩЕСТВО★ уничтожен, ★он★ восстанавливает все свои хиты через 1 час, если его останки не сбрызнут [святой водой](GEAR:${GEAR_HOLY_WATER}) или на них не наложат [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) или [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE})`,
    },
  ],
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_WIZARD,
    componentOnly: CAST_VERBAL,
    spellIdList: [
      SPELL_BLUR,
      SPELL_FIREBALL,
      SPELL_FLAMING_SPHERE,
      SPELL_MAGE_HAND,
      SPELL_MAGIC_MISSILE,
      SPELL_SHIELD,
    ],
    slotCountList: [
      Infinity,
      3,
      2,
      1,
    ],
  },
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ дважды использует _Огненный луч_.`,
    },
    {
      name: `Огненный луч`,
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 5,
        range: 30,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 3,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
