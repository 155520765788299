const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_MYCONID,
  CREATURE_TYPE_PLANT,
} = require('./../../../../creatureTypeIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_MYCONID_SOVEREIGN} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {myconidDescriptionList} = require('./../../../../textCommonParts')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Верховный миконид',
  nameEn: 'Myconid Sovereign',
  id: CREATURE_MYCONID_SOVEREIGN,
  description: myconidDescriptionList,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
    CREATURE_TYPE_MYCONID,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 207,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 14,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 10,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Споры страдания',
      description: `Если ★СУЩЕСТВО★ получает урон, все остальные микониды в пределах 240 футов чувствуют ★его★ боль.`,
    },
    {
      name: 'Слабость на солнце',
      description: `Находясь на солнечном свету, ★СУЩЕСТВО★ совершает с помехой проверки характеристик, броски атаки и испытания. ★СУЩЕСТВО★ умирает, если проводит больше 1 часа под прямыми солнечными лучами.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ использует либо _Галлюциногенные споры_, либо _Усмиряющие споры_, а затем совершает атаку _Кулаком_.`,
    },
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 3,
            diceType: 4,
            diceBonus: 1,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 3,
            diceType: 4,
          },
        ],
      },
    },
    {
      name: 'Оживляющие споры',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ нацеливается на один труп гуманоида или зверя с размером не больше Большого, находящийся в пределах 5 футов, и покрывает его спорами. Через 24 часа труп оживает в качестве спорового слуги. Труп остаётся живым 1к4+1 неделю, или пока не будет уничтожен, и больше его таким образом не оживить.`,
    },
    {
      name: 'Галлюциногенные споры',
      description: `★СУЩЕСТВО★ выпускает споры на одно существо, видимое в пределах 5 футов. Цель должна пройти испытание Телосложения СЛ 12, иначе станет отравленной на 1 минуту. Отравленная цель недееспособна, пока галлюцинирует. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
    {
      name: 'Усмиряющие споры',
      description: `★СУЩЕСТВО★ выпускает споры на одно существо, которое видит в пределах 5 футов. Цель должна пройти испытание Телосложения СЛ 12, иначе станет ошеломлённой на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
    {
      name: 'Споры взаимопонимания',
      description: `★СУЩЕСТВО★ выпускает споры в радиусе 30 футов. Эти споры обходят углы и воздействуют только на существ с Интеллектом 2 и выше, которые не являются нежитью, конструктами и элементалями. Попавшие под действие существа могут телепатически общаться друг с другом, если находятся в пределах 30 футов друг от друга. Эффект длится 1 час.`,
    },
  ],
  genderId: GENDER_MALE,
}
