const {SOURCE_WDH} = require('./../../../../sourceList')
const {waterdeepCityGuardDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_VETERAN,
  CREATURE_WATERDEEP_S_CITY_GUARD_CIVILAR,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Цивилар гвардии Глубоководья',
  nameAlt: 'Лейтенант гвардии Глубоководья',
  nameEn: 'Waterdeep’s city guard, Civilar',
  nameEnAlt: 'Waterdeep’s city guard, Lieutenant',
  id: CREATURE_WATERDEEP_S_CITY_GUARD_CIVILAR,
  parentId: CREATURE_VETERAN,
  description: waterdeepCityGuardDescriptionList,
  source: {
    id: SOURCE_WDH,
    page: 197,
  },
}
