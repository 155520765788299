const {SOURCE_VGTM} = require('./../../sourceList')
const {CREATURE_FROST_GIANT} = require('./../../creatureIdList')
const {
  GOD_ANNAM,
  GOD_SURTUR,
  GOD_THRYM,
} = require('./../../godIdList')

module.exports = [
  {
    header: 'Холодная сила Трима',
    text: `[Трим](GOD:${GOD_THRYM}) уже давно соперничает со своим братом-близнецом [Суртуром](GOD:${GOD_SURTUR}) за любовь и гордость [Аннама](GOD:${GOD_ANNAM}). [Ледяные великаны](CREATURE:${CREATURE_FROST_GIANT}) гордятся победами [Трима](GOD:${GOD_THRYM}) над [Суртуром](GOD:${GOD_SURTUR}) и другими легендарными угрозами, когда он доказал, что у него больше силы и более храброе сердце. Тем не менее, [Аннам](GOD:${GOD_ANNAM}) был больше тронут умело изготовленными подарками [Суртура](GOD:${GOD_SURTUR}), чем трофейными головами, которые [Трим](GOD:${GOD_THRYM}) положил у его ног. По этой причине, [ледяные великаны](CREATURE:${CREATURE_FROST_GIANT}) более недовольны [Аннамом](GOD:${GOD_ANNAM}), чем большинство других великанов.

В отличие от своих братьев [Трим](GOD:${GOD_THRYM}) редко изображается в одиночку. Его обычно сопровождают до десяти боевых братьев и сестёр, героических [ледяных великанов](CREATURE:${CREATURE_FROST_GIANT}), которые завоевали такую большую славу во время войны между великанами и драконами, что [Трим](GOD:${GOD_THRYM}) предоставил им честь всегда сражаться бок о бок с ним.`,
    source: {
      id: SOURCE_VGTM,
      page: 30,
    },
  },
]
