const {
  CREATURE_BLACK_PUDDING,
  CREATURE_GELATINOUS_CUBE,
  CREATURE_JUIBLEX,
} = require('./../../creatureIdList')
const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Слизь',
    text: `Слизь хорошо чувствует себя в темноте, избегая солнечных областей и экстремальных температур. Она течёт по влажным подземельям, питаясь всем, что можно растворить, крадётся по земле, капает со стен и потолков, собирается у краёв подземных бассейнов и просачивается через щели. Первым предупреждением для искателя приключений о присутствия слизи является жгучая боль от её кислотного касания.

Слизи тянутся в сторону движения и тепла. Они питаются органикой, но в отсутствие добычи они могут поглощать грязь, грибы и отбросы. Бывалые искатели приключений знают, что вычищенные проходы подземелий — признак близкого расположения логова слизи.`,
    source: {
      id: SOURCE_MM,
      page: 268,
    },
  },
  {
    header: 'Медленная смерть',
    text: `Слизь убивает свою жертву медленно. Некоторые виды слизи, такие как [чёрная слизь](CREATURE:${CREATURE_BLACK_PUDDING}) и [студенистый куб](CREATURE:${CREATURE_GELATINOUS_CUBE}), поглощают жертву, не давая ей убежать. Единственный путь избежать мучительной смерти — это своевременная помощь товарищей.

Так как слизи переваривают не все виды вещества, в их дрожащих телах можно увидеть монеты, металлические детали, кости и другой мусор в подвешенном состоянии. Поверженная слизь может быть хорошим источником сокровищ для убийцы.`,
    source: {
      id: SOURCE_MM,
      page: 268,
    },
  },
  {
    header: 'Невольные слуги',
    text: `Слизи не хватает интеллекта для союза с другими существами, но другие используют их потребность в пище и заманивают в локации, где они будут полезны. Умные чудовища держат слизь для защиты проходов или утилизации мусора. Аналогично, слизь заманивают в ямы-ловушки и кормят достаточно, чтобы она не преследовала хозяев. Хитрые существа располагают факелы и жаровни в специальных местах, чтобы слизь не покидала определённые туннели и комнаты.`,
    source: {
      id: SOURCE_MM,
      page: 268,
    },
  },
  {
    header: 'Порождение Джуиблекса',
    text: `Согласно Демономикону Иггвильв и другим источникам, слизь является рассеянными фрагментами или потомством демонического повелителя [Джуиблекса](CREATURE:${CREATURE_JUIBLEX}). Верно это или нет, но [Безликий Лорд](CREATURE:${CREATURE_JUIBLEX}) — одно из малочисленных существ, которые могут управлять слизью и вселять в них толику разума. В основном слизь не имеет чувства тактики или самосохранения. Они прямы и предсказуемы, нападают и питаются бесхитростно. Под контролем [Джуиблекса](CREATURE:${CREATURE_JUIBLEX}) слизь демонстрирует проблески сознания и злого умысла.`,
    source: {
      id: SOURCE_MM,
      page: 268,
    },
  },
  {
    header: 'Природа слизи',
    text: `Слизь не нуждается во сне.`,
    source: {
      id: SOURCE_MM,
      page: 268,
    },
  },
]
