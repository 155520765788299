const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  yuantiDescriptionList,
  yuantiPurebloodDescription,
  yuantiNoteList,
} = require('./../../../../textCommonParts')
const {
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_YUAN_TI,
} = require('./../../../../creatureTypeIdList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_SUGGESTION,
} = require('./../../../../spellIdList')
const {
  GEAR_SCIMITAR,
  GEAR_SHORTBOW,
} = require('./../../../../gearIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_YUAN_TI_PUREBLOOD} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Чистокровный юань-ти',
  nameEn: 'Yuan-ti Pureblood',
  id: CREATURE_YUAN_TI_PUREBLOOD,
  description: [
    yuantiPurebloodDescription,
    ...yuantiDescriptionList,
  ],
  note: yuantiNoteList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_YUAN_TI,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 315,
  },
  armor: 11,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 11,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_DRACONIC,
    LANG_COMMON,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_ANIMAL_FRIENDSHIP,
            comment: `только змеи`,
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_SUGGESTION,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_SCIMITAR,
    },
    {
      gearId: GEAR_SHORTBOW,
      attack: {
        hit: {
          type: DAMAGE_POISON,
          diceCount: 2,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
