const {CAT_WEAPON} = require('./../../../../../gearCategoryList')
const {MAGIC_ITEM_VICIOUS_WEAPON} = require('./../../../../../magicItemIdList')
const {
  gearByCatCollection,
  gearCollection,
} = require('./../../../../../gearList')

const list = gearByCatCollection[CAT_WEAPON]
  .filter(
    gearId => gearCollection[gearId].damageType
  )
  .map(
    gearId => ({
      id: `${gearId}_${MAGIC_ITEM_VICIOUS_WEAPON}`,
      gearId,
    })
  )

module.exports = list
