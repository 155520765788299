const {
  SPELL_ABSORB_ELEMENTS,
  SPELL_ACID_SPLASH,
  SPELL_AID,
  SPELL_ALARM,
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ARCANE_EYE,
  SPELL_ARCANE_LOCK,
  SPELL_ASHARDALON_S_STRIDE,
  SPELL_BIGBY_S_HAND,
  SPELL_BLINK,
  SPELL_BLUR,
  SPELL_BOOMING_BLADE,
  SPELL_CATAPULT,
  SPELL_CATNAP,
  SPELL_CONTINUAL_FLAME,
  SPELL_CREATE_BONFIRE,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CREATION,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKVISION,
  SPELL_DETECT_MAGIC,
  SPELL_DISGUISE_SELF,
  SPELL_DISPEL_MAGIC,
  SPELL_ELEMENTAL_BANE,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FABRICATE,
  SPELL_FAERIE_FIRE,
  SPELL_FALSE_LIFE,
  SPELL_FEATHER_FALL,
  SPELL_FIRE_BOLT,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_STRIDE_2021_04_14,
  SPELL_FLY,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_FROSTBITE,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREASE,
  SPELL_GREATER_RESTORATION,
  SPELL_GREEN_FLAME_BLADE,
  SPELL_GUIDANCE,
  SPELL_HASTE,
  SPELL_HEAT_METAL,
  SPELL_IDENTIFY,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INVISIBILITY,
  SPELL_JUMP,
  SPELL_LEOMUND_S_SECRET_CHEST,
  SPELL_LESSER_RESTORATION,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_LIGHTNING_LURE,
  SPELL_LONGSTRIDER,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MOUTH,
  SPELL_MAGIC_STONE,
  SPELL_MAGIC_WEAPON,
  SPELL_MENDING,
  SPELL_MESSAGE,
  SPELL_MORDENKAINEN_S_FAITHFUL_HOUND,
  SPELL_MORDENKAINEN_S_PRIVATE_SANCTUM,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_POISON_SPRAY,
  SPELL_PRESTIDIGITATION,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_PYROTECHNICS,
  SPELL_RAY_OF_FROST,
  SPELL_RESISTANCE,
  SPELL_REVIVIFY,
  SPELL_ROPE_TRICK,
  SPELL_SANCTUARY,
  SPELL_SEE_INVISIBILITY,
  SPELL_SHOCKING_GRASP,
  SPELL_SKILL_EMPOWERMENT,
  SPELL_SKYWRITE,
  SPELL_SNARE,
  SPELL_SPARE_THE_DYING,
  SPELL_SPIDER_CLIMB,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_SWORD_BURST,
  SPELL_TASHA_S_CAUSTIC_BREW,
  SPELL_THORN_WHIP,
  SPELL_THUNDERCLAP,
  SPELL_TINY_SERVANT,
  SPELL_TRANSMUTE_ROCK,
  SPELL_WALL_OF_STONE,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
  SPELL_WEB,
} = require('./../../spellIdList')

module.exports = [
  SPELL_ABSORB_ELEMENTS,
  SPELL_ACID_SPLASH,
  SPELL_AID,
  SPELL_ALARM,
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ARCANE_EYE,
  SPELL_ARCANE_LOCK,
  SPELL_ASHARDALON_S_STRIDE,
  SPELL_BOOMING_BLADE,
  SPELL_BIGBY_S_HAND,
  SPELL_BLINK,
  SPELL_BLUR,
  SPELL_CATAPULT,
  SPELL_CATNAP,
  SPELL_CONTINUAL_FLAME,
  SPELL_CREATE_BONFIRE,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CREATION,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKVISION,
  SPELL_DETECT_MAGIC,
  SPELL_DISGUISE_SELF,
  SPELL_DISPEL_MAGIC,
  SPELL_ELEMENTAL_BANE,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FABRICATE,
  SPELL_FAERIE_FIRE,
  SPELL_FALSE_LIFE,
  SPELL_FEATHER_FALL,
  SPELL_FIRE_BOLT,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_STRIDE_2021_04_14,
  SPELL_FLY,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_FROSTBITE,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREASE,
  SPELL_GREATER_RESTORATION,
  SPELL_GREEN_FLAME_BLADE,
  SPELL_GUIDANCE,
  SPELL_HASTE,
  SPELL_HEAT_METAL,
  SPELL_IDENTIFY,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INVISIBILITY,
  SPELL_JUMP,
  SPELL_LEOMUND_S_SECRET_CHEST,
  SPELL_LESSER_RESTORATION,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_LIGHTNING_LURE,
  SPELL_LONGSTRIDER,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MOUTH,
  SPELL_MAGIC_STONE,
  SPELL_MAGIC_WEAPON,
  SPELL_MENDING,
  SPELL_MESSAGE,
  SPELL_MORDENKAINEN_S_FAITHFUL_HOUND,
  SPELL_MORDENKAINEN_S_PRIVATE_SANCTUM,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_POISON_SPRAY,
  SPELL_PRESTIDIGITATION,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_PYROTECHNICS,
  SPELL_RAY_OF_FROST,
  SPELL_RESISTANCE,
  SPELL_REVIVIFY,
  SPELL_ROPE_TRICK,
  SPELL_SANCTUARY,
  SPELL_SEE_INVISIBILITY,
  SPELL_SHOCKING_GRASP,
  SPELL_SKILL_EMPOWERMENT,
  SPELL_SKYWRITE,
  SPELL_SNARE,
  SPELL_SPARE_THE_DYING,
  SPELL_SPIDER_CLIMB,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_SWORD_BURST,
  SPELL_TASHA_S_CAUSTIC_BREW,
  SPELL_THORN_WHIP,
  SPELL_THUNDERCLAP,
  SPELL_TINY_SERVANT,
  SPELL_TRANSMUTE_ROCK,
  SPELL_WALL_OF_STONE,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
  SPELL_WEB,
]
