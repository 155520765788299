module.exports = [
  ...require('./const/multiClassed'),

  ...require('./const/artificer'),
  ...require('./const/barbarian'),
  ...require('./const/bard'),
  ...require('./const/cleric'),
  ...require('./const/druid'),
  ...require('./const/fighter'),
  ...require('./const/monk'),
  ...require('./const/paladin'),
  ...require('./const/ranger'),
  ...require('./const/rogue'),
  ...require('./const/sorcerer'),
  ...require('./const/warlock'),
  ...require('./const/wizard'),
]
