const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1_4,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GOBLIN,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_COMMON,
    LANG_GOBLIN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_LEATHER_ARMOR,
    GEAR_SCIMITAR,
    GEAR_SHIELD,
    GEAR_SHORTBOW,
  } = require('./../../../../gearIdList'),
  {
    goblinDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_NIMBLE_ESCAPE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_GOBLIN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гоблин',
  nameEn: 'Goblin',
  id: CREATURE_GOBLIN,
  description: goblinDescriptionList,
  note: {
    text: `Хотите солдат и головорезов — нанимайте хобгоблинов. Хотите раздавить кому-то череп во сне — наймите медвежатника. Хотите мелких жалких глупцов — нанимайте гоблинов`,
    author: `Сталман Клим, рабовладелец`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_GOBLIN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 64,
  },
  armor: {
    ac: 15,
    gearId: [GEAR_LEATHER_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GOBLIN,
    LANG_COMMON,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_NIMBLE_ESCAPE,
  ],
  actionList: [
    {
      gearId: GEAR_SCIMITAR,
    },
    {
      gearId: GEAR_SHORTBOW,
    },
  ],
  genderId: GENDER_MALE,
}
