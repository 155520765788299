const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_LEVITATE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')

module.exports = {
  id: SPELL_LEVITATE,
  name: 'Левитация',
  nameEn: 'Levitate',
  description: `Одно выбранное Вами существо или никем не несомый и не носимый предмет, видимые в пределах дистанции, поднимается вертикально на расстояние до 20 футов, и остается висеть там на время действия заклинания. Заклинание может заставить левитировать цель, весящую до 500 фунтов. Несогласное существо, преуспевшее в испытании Телосложения, не попадает под действие этого заклинания.

Цель может перемещаться только отталкиваясь от твердых предметов и поверхностей (таких как стены и потолок), а также подтягиваясь за них, что позволяет перемещаться так, как если бы они лазали. В свой ход Вы можете изменить высоту цели на 20 футов в любом направлении. Если целью являетесь Вы сами, Вы можете частью перемещения двигаться вверх или вниз. В противном случае, Вы можете действием переместить цель, которая должна оставаться при этом в пределах досягаемости заклинания.

Когда заклинание оканчивается, если цель все еще находится в воздухе, она плавно опускается на землю.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  needConcentration: true,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'либо маленькая кожаная петля, либо кусочек золотой проволоки, согнутый в форме кубка с длинной ножкой',
  duration: { timeId: TIME_MINUTE, count: 10 },
  effect: {
    savethrowParam: PARAM_CON,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 235,
    },
    {
      id: SOURCE_SRD,
      page: 158,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Теперь предметы больше нельзя вырывать из рук',
      page: 3,
    },
  ],
}
