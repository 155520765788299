const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_HAG,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_INFERNAL,
  LANG_PRIMORDIAL,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DETECT_MAGIC,
  SPELL_GREATER_RESTORATION,
  SPELL_MAGIC_MISSILE,
  SPELL_PLANE_SHIFT,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_SLEEP,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_MAGIC_CIRCLE,
} = require('./../../../../spellIdList')
const {
  CAST_MATERIAL,
} = require('./../../../../castComponentList')
const {
  CONDITION_CHARMED,
} = require('./../../../../conditionList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  hagsDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_CHANGE_SHAPE,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_NIGHT_HAG,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Ночная карга',
  nameEn: 'Night Hag',
  id: CREATURE_NIGHT_HAG,
  description: [
    `Хитрые и разрушительные ночные карги любят поворачивать помыслы во зло: любовь в одержимость, доброту в ненависть, преданность в пренебрежение, щедрость в эгоизм. Ночные карги испытывают извращённую радость, развращая смертных.

Ночные карги когда-то были существами Страны Фей, но за испорченность их давным-давно изгнали в Гадес, где они превратились в исчадий. Ночные карги уже давно распространились по всем Нижним Планам.`,
    {
      header: 'Торговцы душами',
      text: `Пока человек спит, ночная карга может, находясь на Эфирном Плане, вторгнуться в его сны. Существо с истинным зрением увидит призрачный облик карги, сидящей на жертве. Карга наполняет разум жертвы сомнениями и страхами, надеясь подвигнуть её на злые поступки. Она не окончит свои ночные бдения, пока жертва не издохнет во сне. Если карге удалось побудить жертву совершить тёмное деяние, она ловит её искалеченную душу в _сумку душ_ и уносит её в Гадес.`,
      source: {
        id: SOURCE_MM,
        page: 171,
      },
    },
    {
      header: 'Предметы ночной карги',
      text: `Ночная карга носит с собой два крайне редких магических предмета, созданных для себя. Если они потеряются, она пойдёт на многое, чтобы вернуть их, потому что создание новых требует много времени и сил.

1. **Каменное сердце.** Этот блестящий чёрный камень позволяет карге становиться бестелесной. Прикосновение к нему также лечит все болезни. Создание занимает 30 дней.
2. **Сумка душ.** Если существо умирает от воздействия Ночных кошмаров, карга ловит его душу в чёрный мешок, сшитый из плоти. _Сумка_ вмещает только одну злую душу, и только та карга, что создала его, может ей пользоваться. Создание _сумки душ_ занимает 7 дней и требует приношения в жертву гуманоида (из плоти которого и делается сумка).`,
      source: {
        id: SOURCE_MM,
        page: 171,
      },
    },
    ...hagsDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_HAG,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 172,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_INFERNAL,
    LANG_COMMON,
    LANG_PRIMORDIAL,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_MAGIC_MISSILE,
          SPELL_DETECT_MAGIC,
        ],
      },
      {
        limit: {
          count: 2,
          period: 'день',
        },
        list: [
          SPELL_RAY_OF_ENFEEBLEMENT,
          SPELL_SLEEP,
          {
            id: SPELL_PLANE_SHIFT,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Когти',
      comment: 'только в форме карги',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      id: ABILITY_CHANGE_SHAPE,
      nextForm: `гуманоидную женщину Маленького или Среднего размера`,
      gearMerge: `не превращается`,
      statChange: `★Его★ статистика одинакова во всех обликах`,
    },
    {
      name: 'Эфирность',
      description: `★СУЩЕСТВО★ магическим образом переходит на Эфирный План с Материального плана, или наоборот. Для этого у ★СУЩЕСТВО★ должно быть _каменное сердце_.`,
    },
    {
      name: 'Ночные кошмары',
      description: `Находясь на Эфирном Плане, ★СУЩЕСТВО★ магическим образом касается спящего на Материальном плане гуманоида. Заклинание [Защита от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}), наложенное на цель, предотвращает этот контакт, равно как и [Магический круг](SPELL:${SPELL_MAGIC_CIRCLE}). Пока контакт поддерживается, цель переживает ужасные видения. Если эти видения длятся как минимум 1 час, цель не получает преимущества от отдыха, и максимум её хитов уменьшается на 5 (1к10). Если этот эффект опускает максимум хитов до 0, цель умирает, и если цель была злой, её душа захватывается сумкой душ. Уменьшение максимума хитов длится до тех пор, пока не будет устранено заклинанием [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) или подобной магией.`,
      limit: {
        count: 1,
        period: 'день',
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
