const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  GEAR_TRIDENT,
} = require('./../../../../../gearIdList')
const {
  SPELL_SPEAK_WITH_ANIMALS,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_CAP_OF_WATER_BREATHING,
  MAGIC_ITEM_CUBE_OF_FORCE,
  MAGIC_ITEM_TRIDENT_OF_FISH_COMMAND,
  MAGIC_ITEM_WAVE,
  MAGIC_ITEM_WEAPON_OF_WARNING,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_WAVE,
  name: `Волна`,
  nameEn: `Wave`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_TRIDENT,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `существом, поклоняющемся божеству моря`,
  isSentient: true,
  description: `Этот трезубец, находящийся под горой Белый Шлейф, — изысканное оружие, украшенное изображениями волн, раковин и морских существ. Для настройки на это оружие владелец должен поклоняться божеству моря, но _Волна_ с радостью принимает и тех, кто только-только обратился в новую веру.

Вы получаете бонус +3 к броскам атаки и урона, совершённым этим магическим оружием. Если Вы совершаете им критическое попадание, цель получает дополнительный урон некротической энергией, равный половине максимума её хитов.

Это оружие также действует как [Трезубец командования рыбами](MAGIC_ITEM:${MAGIC_ITEM_TRIDENT_OF_FISH_COMMAND}) и [Оружие предупреждения](MAGIC_ITEM:${MAGIC_ITEM_WEAPON_OF_WARNING}). Он может предоставить Вам преимущества [Шапки подводного дыхания](MAGIC_ITEM:${MAGIC_ITEM_CAP_OF_WATER_BREATHING}), пока Вы держите его, и Вы можете использовать его как [Куб силового поля](MAGIC_ITEM:${MAGIC_ITEM_CUBE_OF_FORCE}), выбирая эффект самостоятельно, а не нажимая на грани куба.

# Разум

_Волна_ — разумное нейтральное оружие с Интеллектом 14, Мудростью 10 и Харизмой 18. Она обладает слухом и тёмным зрением в пределах 120 футов.

Это оружие может понимать язык Акван, а также говорить и читать на нём и телепатически общаться с владельцем. Оно также может говорить с водными животными, как будто под действием заклинания [Разговор с животными](SPELL:${SPELL_SPEAK_WITH_ANIMALS}), используя телепатию, чтобы владелец был вовлечён в разговор.

# Индивидуальность

Если _Волна_ начинает беспокоиться, она начинает издавать какую-нибудь мелодию, от песни моряков до священного песнопения морского божества.

_Волна_ фанатично желает обращать смертных в услужение морским богам, а неверных предавать смерти. Если владелец отказывается следовать целям оружия, возникает конфликт.

Трезубец привязан ностальгическими воспоминаниями к месту, в котором он был выкован, пустынному острову Громовая Кузня. Морской бог заточил здесь семью штормовых великанов, и те выковали _Волну_ в знак не то преданности этому богу, не то восстания против него.

_Волна_ тайно сомневается в своей природе и своём предназначении. Несмотря на всю преданность морским богам, она боится, что создана на погибель одному из морских богов, и что она не сможет предотвратить эту свою судьбу.`,
  genderId: GENDER_FEMALE,
  spellIdList: [
    SPELL_SPEAK_WITH_ANIMALS,
  ],
  source: {
    id: SOURCE_DMG,
    page: 217,
  },
}
