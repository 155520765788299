const {PC_SUBCLASS_DRUID_CIRCLE_OF_STARS} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {
  SPELL_GUIDANCE,
  SPELL_GUIDING_BOLT,
} = require('./../../../spellIdList')
const {
  FEATURE_COSMIC_OMEN,
  FEATURE_FULL_OF_STARS,
  FEATURE_STAR_MAP,
  FEATURE_STARRY_FORM,
  FEATURE_TWINKLING_CONSTELLATIONS,
  FEATURE_WILD_SHAPE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_STAR_MAP,
    name: `Звёздная карта`,
    nameEn: `Star Map`,
    lvl: 2,
    text: `Вы создали звёздную карту, отражающую результаты Ваших тщательных исследований.

Это Крошечный предмет, который может быть фокировкой для Ваших заклинаний друида. Вы можете определяете его форму броском  по таблице «Звёздная карта» или выбрав её самостоятельно.

Держа _Звёздную карту_, Вы получаете следующие преимущества:

* Вы знаете заговор [Указание](SPELL:${SPELL_GUIDANCE}).
* У Вас подготовлено заклинание [Направленный снаряд](SPELL:${SPELL_GUIDING_BOLT}). Оно считается для Вас заклинанием друида и не учитывается в количестве заклинаний, которое Вы можете подготавливать.
* Вы можете сотворять [Направленный снаряд](SPELL:${SPELL_GUIDING_BOLT}), не тратя ячеек заклинаний. Вы можете сделать это количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.

Если Вы потеряете карту, то можете провести часовую церемонию, чтобы магически создать ей замену. Эту церемонию можно совершать во время короткого или длинного отдыха и она уничтожает предыдущую карту.

# Звёздная карта

| к6 | Вид карты                                          |
|----|----------------------------------------------------|
| 1  | Свиток с изображениями созвездий                   |
| 2  | Каменная табличка с просверленными отверстиями     |
| 3  | Пятнистая шкура медвесыча с выпуклыми отметинами   |
| 4  | Коллекция карт в обложке из чёрного дерева         |
| 5  | Кристалл, при освещении проецирующий звёздный узор |
| 6  | Стеклянные диски с изображением созвездий          |
`,
    spellIdList: [
      SPELL_GUIDANCE,
      SPELL_GUIDING_BOLT,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 54,
    },
  },
  {
    id: FEATURE_STARRY_FORM,
    name: `Звёздный облик`,
    nameEn: `Starry Form`,
    lvl: 2,
    text: `
Вы можете бонусным действием использовать [Дикий облик](FEATURE:${FEATURE_WILD_SHAPE}), принимая _Звёздный облик_ вместо превращения в зверя.

Находясь в _Звёздном облике_, Вы сохраняете свои игровые параметры, но Ваше тело начинает светиться: суставы мерцают, как звёзды, и их соединяют светящиеся линии, как на звёздной карте. _Звёздный облик_ излучает яркий свет в пределах 10 футов и тусклый в пределах ещё 10 футов. _Облик_ длится 10 минут. Он прекращается раньше, если Вы его отменяете (действий не требуется), теряете дееспособность, умираете или снова используете эту способность.

Каждый раз принимая _Звёздный облик_, выберите, какое из созвездий сияет на Вашем теле; Ваш выбор дает Вам определенные преимущества:

# Стрелец

На Вас появляется созвездие стрельца.

Когда Вы активируете этот облик (а также в следующих ходах бонусным действием, пока _Звёздный облик_ активен), Вы можете совершать дальнобойную атаку заклинанием, бросая светящуюся стрелу в одно существо в пределах 60 футов. При попадании атака наносит 1к8 + Ваш модификатор Мудрости урона излучением.

# Чаша

На Вас появляется созвездие живительного кубка.

Каждый раз, когда Вы тратите ячейку заклинаний для сотворения заклинания, восстанавливающего существу хиты, Вы или другое существо в пределах 30 футов можете восстановить 1к8 + Ваш модификатор Мудрости хитов.

# Дракон

На Вас появляется созвездие мудрого дракона.

Когда Вы совершаете проверку Интеллекта или Мудрости или испытание Телосложения для сохранения концентрации на заклинании, Вы можете считать результат 9 или меньше на к20 как 10.`,
    source: {
      id: SOURCE_TCoE,
      page: 55,
    },
  },
  {
    id: FEATURE_COSMIC_OMEN,
    name: `Космическое знамение`,
    nameEn: `Cosmic Omen`,
    lvl: 6,
    text: `Каждый раз, когда Вы завершаете длинный отдых, Вы можете свериться со своей [Звёздной картой](FEATURE:${FEATURE_STAR_MAP}) в поисках знамений. Делая это, бросьте любой кубик. Пока Вы не закончите свой следующий длинный отдых, Вы получите специальную реакцию, зависящую от того, выпал ли чёт или нечет.

# Благо (чёт)

Каждый раз, когда видимое Вами в пределах 30 футов существо собирается совершить атаку, испытание или проверку характеристики, Вы можете реакцией бросить к6 и добавить результат к броску.

# Горе (нечет)

Каждый раз, когда видимое Вами в пределах 30 футов существо собирается совершить атаку, испытание или проверку характеристики, Вы можете реакцией бросить к6 и вычесть результат из броска.

Вы можете использовать эту реакцию количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 55,
    },
  },
  {
    id: FEATURE_TWINKLING_CONSTELLATIONS,
    name: `Мерцающие созвездия`,
    nameEn: `Twinkling Constellations`,
    lvl: 10,
    text: `Созвездия [Звёздного облика](FEATURE:${FEATURE_STARRY_FORM}) улучшаются.

Кости 1к8 Стрелка и Чаши становится 2к8, и, пока Дракон активен, Вы получате скорость полёта 20 футов и можете парить.

Более того, в начале каждого своего хода, будучи в [Звёздном облике](FEATURE:${FEATURE_STARRY_FORM}) Вы можете изменить созвездие, мерцающее на Вашем теле.`,
    source: {
      id: SOURCE_TCoE,
      page: 55,
    },
  },
  {
    id: FEATURE_FULL_OF_STARS,
    name: `Целиком из звёзд`,
    nameEn: `Full of Stars`,
    lvl: 14,
    text: `Находясь в [Звёздном облике](FEATURE:${FEATURE_STARRY_FORM}), Вы становитесь частично бестелесным, что даёт Вам сопротивление к дробящему, колющему и рубящему урону.`,
    source: {
      id: SOURCE_TCoE,
      page: 55,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_STARS,
  })
)
