const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ARCHDEVIL} = require('./../../creatureTypeIdList')
const {
  archDevilDescriptionList,
  archDevilNoteList,
} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_ARCHDEVIL,
  nameEn: 'Archdevil',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  notRealRace: true,
  name: {
    singular: {
      nominative: 'архидьявол',
      genitive: 'архидьявола',
      dative: 'архидьяволу',
      accusative: 'архидьявола',
      instrumental: 'архидьяволом',
      prepositional: 'архидьяволе',
    },
    plural: {
      nominative: 'архидьяволы',
      genitive: 'архидьяволов',
      dative: 'архидьяволам',
      accusative: 'архидьяволов',
      instrumental: 'архидьяволами',
      prepositional: 'архидьяволах',
    },
  },
  note: archDevilNoteList,
  description: archDevilDescriptionList,
}
