const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ELADRIN} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ELADRIN,
  nameEn: 'Eladrin',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'эладрин',
      genitive: 'эладрина',
      dative: 'эладрину',
      accusative: 'эладрина',
      instrumental: 'эладрином',
      prepositional: 'эладрине',
    },
    plural: {
      nominative: 'эладрины',
      genitive: 'эладринов',
      dative: 'эладринам',
      accusative: 'эладринов',
      instrumental: 'эладринами',
      prepositional: 'эладринах',
    },
  },
}
