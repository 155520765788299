const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  GENDER_MIDDLE,
} = require('./../../../../genderList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ALL_KNOWN_AT_LIFE,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_ORCUS,
  CREATURE_SPAWN_OF_KYUSS,
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')
const {
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Отродье Кьюсса',
  nameAlt: 'Порождение Кьюсса',
  nameEn: 'Spawn of Kyuss',
  id: CREATURE_SPAWN_OF_KYUSS,
  description: [
    {
      header: 'Отродье Кьюсса',
      text: `Кьюсс был верховным жрецом [Оркуса](CREATURE:${CREATURE_ORCUS}), который воровал трупы из некрополей, чтобы создавать первых отродий Кьюсса. Даже столетия спустя после смерти Кьюсса, его безумные последователи продолжают проводить ужасающие ритуалы, которые он усовершенствовал.`,
      source: {
        id: SOURCE_VGTM,
        page: 186,
      },
    },
    {
      header: 'Мор червей',
      text: `На расстоянии или при плохом освещении, отродье Кьюсса выглядит как обычный [зомби](CREATURE:${CREATURE_ZOMBIE}). Когда же удается поближе его разглядеть, можно заметить полчища маленьких зелёных червей, ползающих по телу и внутри его. Эти черви прыгают на ближайших гуманоидов и зарываются в их плоть. Червь, прогрызший тело гуманоида, пробирается к его мозгу. Оказавшись внутри мозга червь убивает носителя и оживляет тело, превращая его в отродье Кьюсса, в котором разводятся новые черви. Душа мертвого гуманоида остаётся запертой в трупе, что не дает воскресить или оживить его до тех пор, пока неживое тело не будет уничтожено. Ужас от того, что душа заперта в неживом теле сводит отродье Кьюсса с ума.`,
      source: {
        id: SOURCE_VGTM,
        page: 186,
      },
    },
    {
      header: 'Бесконечное разложение',
      text: `Отродья Кьюсса являются выражением желания [Оркуса](CREATURE:${CREATURE_ORCUS}) заменить всю жизнь нежитью. Оставленное само по себе, одинокое отродье Кьюсса бродит бесцельно. Если оно натыкается на живое существо, то отродье нападает с единственным намерением создать ещё одно отродье. Разбросаны ли они или сгруппированы, порождения воспроизводятся экспоненциально если их не остановить.`,
      source: {
        id: SOURCE_VGTM,
        page: 186,
      },
    },
    {
      header: 'Природа нежити',
      text: `Отродье Кьюсса не нуждается в воздухе, пище, питье и сне.`,
      source: {
        id: SOURCE_VGTM,
        page: 186,
      },
    },
  ],
  note: [
    {
      text: `Кьюсс много за что в ответе. Иногда Вы съедаете червя — а иногда червь съедает вас.`,
      author: `Воло`,
    },
    {
      text: `Некоторые черви хороши на вкус. Не эти`,
      author: `Эльминстер`,
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 186,
  },
  armor: 10,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 18,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 3,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
    CONDITION_EXHAUSTION,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ALL_KNOWN_AT_LIFE,
      doNotSpeak: true,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      id: ABILITY_REGENERATION,
      regen: 10,
      other: `, если у ★него★ есть по крайней мере 1 хит и ★он★ не находится на солнечном свету или в текущей воде. Если ★СУЩЕСТВО★ получает урон кислотой, огнём или излучением, то эта особенность не работает в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только если начинает ход с 0 хитов и не может регенерировать.`,
    },
    {
      name: 'Черви',
      description: `Если ★СУЩЕСТВО★ ★выбран★ целью эффекта. который излечивает болезни или снимает проклятье, то все населяющие ★его★ черви отмирают и ★он★ теряет своё действие _Роющий червь_.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Когтями_ и использует действие _Роющий червь_.`,
    },
    {
      name: 'Роющий червь',
      description: `Червь из ★СУЩЕСТВО★ запускается в одного видимого ★им★ в пределах 10 футов гуманоида. Червь закрепляется на коже цели, если только цель не пройдёт испытание Ловкости СЛ 11. Червь является Крошечной нежитью с КД 6, 1 хитом, 2 (–4) в каждой способности и скоростью в 1 фут.

На коже цели червя можно убить обычным способом или сорвать действием (★СУЩЕСТВО★ может использовать действие _Роющий червь_, чтобы запустить такого оторванного червя в видимого ★им★ гуманоида в пределах 10 футов от червя).

В противном случае червь зарывается под кожу цели в конце следующего её хода и наносит ей 1 колющего урона. А конце каждого последующего хода цель получает 7 (2к6) некротического урона за каждого червя, который её заразил (максимум 10к6). Заражённая червём цель умирает, если её хиты падают до 0, а через 10 минут поднимается как новое отродье Кьюсса. Если заражённое червём существо является целью эффекта, который излечивает болезнь или снимает проклятие, то все черви внутри отмирают.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MIDDLE,
}
