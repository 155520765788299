const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_AIR_ELEMENTAL} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {elementalDescription} = require('./../../../../textCommonParts')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_AURAN} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Воздушный элементаль`,
  nameEn: 'Air Elemental',
  id: CREATURE_AIR_ELEMENTAL,
  description: [
    `Воздушный элементаль представляет собой клубящееся облако воздуха с неким подобием лица. Хотя он любит нестись вдоль земли, вздымая вверх пыль и мусор, он также может взлететь в воздух и атаковать сверху.

Элементаль воздуха может превращаться в ревущий ураган, создавая вихрь, который, затягивая в себя существ, молотит их на лету.`,
    elementalDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 303,
  },
  armor: 15,
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 90,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 14,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_UNCONSCIOUS,
    CONDITION_GRAPPLED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AURAN,
  ],
  cr: CR_5,
  featureList: [
    {
      name: `Воздушное тело`,
      description: `★СУЩЕСТВО★ может входить в пространство враждебных существ и останавливаться там. Он может перемещаться сквозь пространство шириной в 1 дюйм без протискивания.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает два _Удара_.`,
    },
    {
      name: `Удар`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
    {
      name: `Вихрь`,
      restore: 4,
      description: `Все существа в пространстве ★СУЩЕСТВО★ должны пройти испытание Силы СЛ 13. При провале цель получает дробящий урон 15 (3к8+2) и отбрасывается на расстояние до 20 футов от ★СУЩЕСТВО★ в случайном направлении, после чего сбивается с ног. Если отброшенная цель ударяется о предмет, такой как стена или пол, цель получает дробящий урон 3 (1к6) за каждые 10 футов перемещения. Если цель отброшена в другое существо, это существо должно пройти испытание Ловкости СЛ 13, иначе получит такой же урон и будет сбито с ног.

Если исходное испытание пройдено, цель получает половину дробящего урона, не отбрасывается и не сбивается с ног.`,
    },
  ],
  genderId: GENDER_MALE,
}
