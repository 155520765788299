const {
  CAST_ROYALTY,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SPELL_JIM_S_MAGIC_MISSILE,
  SPELL_MAGIC_MISSILE,
} = require('./../../../../../spellIdList')
const {ATTACK_RANGE_SPELL} = require('./../../../../../attackTypeList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SOURCE_AI} = require('./../../../../../sourceList')

module.exports = {
  id: SPELL_JIM_S_MAGIC_MISSILE,
  name: 'Джимова волшебная стрела',
  nameAlt: 'Волшебная стрела Джима',
  nameEn: 'Jim’s Magic Missile',
  description: `Любой ученик волшебника может наложить скучную старую [волшебную стрелу](SPELL:${SPELL_MAGIC_MISSILE}). Ну да, она всегда попадает в цель. Ску-ка. Сбросьте эту старпёрскую нудятину с новой, улучшенной версией заклинания, как это делает сам Джим Тёмномагия!

Вы создаёте три крутящихся, свистящих, гипоаллергенных, безглютеновых дротика магической силы. Каждый дротик нацеливается на видимое Вами в пределах дистанции существо по Вашему выбору. Совершите дальнобойную атаку заклинанием для каждого дротика. При попадании дротик наносит цели 2к4 урона силовым полем.

При критическом попадании, вместо двойных костей урона дротик наносит 5к4 урона силовым полем. Если при броске атаки любого из дротиков выпадает 1, то все дротики вместо попадания в цели взрываются Вам в лицо, нанося 1 урона силовым полем за каждый дротик.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы можете создать один дополнительный дротик и гонорарный компонент увеличивается на 1 зм за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_ROYALTY],
  royaltyCost: 100,
  effect: {
    attackType: ATTACK_RANGE_SPELL,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 6,
      diceType: 4,
      diceBonus: 0,
    },
  },
  source: {
    id: SOURCE_AI,
    page: 77,
  },
}
