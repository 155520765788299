const {GENDER_FEMALE} = require('./../../genderList')
const {CREATURE_TYPE_FAIRY} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_FAIRY,
  nameEn: 'Fairy',
  genderId: GENDER_FEMALE,
  name: {
    singular: {
      nominative: 'фэйри',
      genitive: 'фэйри',
      dative: 'фэйри',
      accusative: 'фэйри',
      instrumental: 'фэйри',
      prepositional: 'фэйри',
    },
    plural: {
      nominative: 'фэйри',
      genitive: 'фэйри',
      dative: 'фэйри',
      accusative: 'фэйри',
      instrumental: 'фэйри',
      prepositional: 'фэйри',
    },
  },
}
