const {
  SOURCE_XGTE,
  SOURCE_ERRATA_XGTE,
} = require('./../../../../../sourceList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {DAMAGE_PSYCHIC} = require('./../../../../../damageTypeList')
const {MAGIC_DIVINATION} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SPELL_MIND_SPIKE} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MIND_SPIKE,
  name: 'Пронзание разума',
  nameEn: 'Mind Spike',
  description: `Вы достигаете разума одного существа, которое видите в пределах дистанции. Цель должна пройти испытание Мудрости и получить урон психической энергией 3к8 про провале или половину этого урона при успехе. При провале испытания Вы также всегда знаете местоположение цели до тех пор, пока заклинание активно, но только пока Вы находитесь на одном и том же плане существования. Пока у Вас есть это знание, цель не может стать спрятанной от Вас, и если она невидима, она не получает никакой выгоды от этого состояния против вас.`,
  highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку заклинания 3 круга или выше, урон увеличивается на 1к8 для каждого уровня ячейки выше 2.`,
  lvl: 2,
  magicSchoolId: MAGIC_DIVINATION,
  range: 60,
  componentIdList: [CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 1},
  effect: {
    savethrowParam: PARAM_WIT,
    damage: {
      type: DAMAGE_PSYCHIC,
      halfOnSuccess: true,
      diceCount: 3,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 164,
    },
    {
      id: SOURCE_ERRATA_XGTE,
      page: 1,
    },
  ],
}
