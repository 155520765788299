const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_OGRE,
} = require('./../../../../creatureTypeIdList')
const {
  ogreDescription,
  ogreNote,
} = require('./../../../../textCommonParts')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_OGRE_CHAIN_BRUTE} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GEAR_HIDE_ARMOR} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Огр цепной громила',
  nameEn: 'Ogre Chain Brute',
  id: CREATURE_OGRE_CHAIN_BRUTE,
  description: [
    `Цепной громила имеет при себе длинную цель с шипами. Держа цепь обеими руками, он размахивает ею по широкой дуге вокруг себя чтобы сбивать противников с ног. Также он способен махать цепью с такой скоростью, что её практически невозможно заблокировать или отразить.`,
    ...ogreDescription,
  ],
  note: ogreNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_OGRE,
  ],
  alignmentId: ALIGNMENT_CE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 218,
    },
    {
      url: `https://5e.tools/bestiary.html#ogre%20chain%20brute_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Огра цепного громилы» ошибочно указана дальность атаки Кулаком в 1 фут.`,
    },
  ],
  armor: {
    ac: 11,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 16,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 7,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_3,
  actionList: [
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Взмах цепью',
      description: `Огр размахивает цепью, и все существа в пределах 10 фт от него должны пройти испытание Ловкости СЛ 14. При провале существо получает 8 (1к8 + 4) дробящего урона и сбивается с ног. При успехе существо получает только половину этого урона и не сбивается с ног.`,
    },
    {
      name: 'Цепное сокрушение',
      restore: 6,
      description: `Цель должна пройти испытание Телосложения СЛ 14, иначе будет сбита с ног и потеряет сознание на 1 минуту. Бессознательная цель повторяет испытание, когда получает урон и в конце каждого своего хода, завершая эффект в случае успеха.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
  ],
}
