const {
  SPELL_DANCING_LIGHTS,
  SPELL_FAERIE_FIRE,
  SPELL_LIGHT,
} = require('./../../../../../spellIdList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MAGIC_ITEM_RING_OF_SHOOTING_STARS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_RING_OF_SHOOTING_STARS,
  name: `Кольцо падающих звёзд`,
  nameEn: `Ring of Shooting Stars`,
  type: MGC_TYPE_RING,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  attunementComment: `ночью на открытом воздухе`,
  description: [
    {
      header: 'Кольцо падающих звёзд',
      text: `Нося это кольцо в области тусклого света или темноте, Вы можете неограниченно действием сотворять им [Пляшущие огоньки](SPELL:${SPELL_DANCING_LIGHTS}) или [Свет](SPELL:${SPELL_LIGHT}).

У кольца есть 6 зарядов для использования перечисленных дальше свойств. Кольцо ежедневно восстанавливает 1к6 зарядов на рассвете.`,
      source: {
        id: SOURCE_DMG,
        page: 176,
      },
    },
    {
      header: 'Огонь фей',
      text: `Вы можете действием потратить 1 заряд, чтобы наложить кольцом [Огонь фей](SPELL:${SPELL_FAERIE_FIRE}).`,
      source: {
        id: SOURCE_DMG,
        page: 176,
      },
    },
    {
      header: 'Шаровая молния',
      text: `Вы можете действием потратить 2 заряда, чтобы создать кольцом от одной до четырёх шаровых молний диаметром 3 фута. Чем больше шаров Вы создадите, тем менее эффективен каждый из них.

Каждый шар появляется в свободном пространстве, видимом Вами в пределах 120 футов. Шары существуют, пока Вы концентрируетесь (как при концентрации на заклинании), но не более 1 минуты. Каждый шар испускает тусклый свет в радиусе 30 футов.

Вы можете бонусным действием переместить каждый шар на расстояние до 30 футов, но не более чем на 120 футов от себя. Если какое-нибудь существо кроме Вас оказывается в пределах 5 футов от шара, шар выпускает в него разряд молнии и исчезает. Это существо должно пройти испытание Ловкости СЛ 15. При провале существо получает урон электричеством, зависящий от количества созданных Вами шаров.

| Шары | Урон электричеством |
|------|---------------------|
| 1    | 4к12                |
| 2    | 5к4                 |
| 3    | 2к6                 |
| 4    | 2к4                 |
`,
      source: {
        id: SOURCE_DMG,
        page: 176,
      },
    },
    {
      header: 'Падающие звёзды',
      text: `Вы можете действием потратить от 1 до 3 зарядов. За каждый потраченный заряд Вы выпускаете из кольца по одному светящемуся шарику в точку, которую видите в пределах 60 футов. Все существа в пределах 15-футового куба, исходящего из этой точки, покрываются искрами и должны пройти испытание Ловкости СЛ 15, получая урон огнём 5к4 при провале или половину урона при успехе.`,
      source: {
        id: SOURCE_DMG,
        page: 176,
      },
    },
  ],
  genderId: GENDER_MIDDLE,
  spellIdList: [
    SPELL_DANCING_LIGHTS,
    SPELL_FAERIE_FIRE,
    SPELL_LIGHT,
  ],
  source: {
    id: SOURCE_DMG,
    page: 176,
  },
}
