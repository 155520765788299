const {
  SOURCE_MOOT,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_ANVILWROUGHT,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_ONE_OF_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_CHARGE,
  ABILITY_SPELL_TURNING,
} = require('./../../../../creatureAbilityList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  anvilwroughtDescription,
} = require('./../../../../textCommonParts')
const {
  CREATURE_GOLD_FORGED_SENTINEL,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Выкованный из золота страж',
  nameEn: 'Gold Forged Sentinel',
  id: CREATURE_GOLD_FORGED_SENTINEL,
  description: [
    {
      header: 'Выкованный из золота страж',
      text: `Истинное назначение выкованных из золота стражей — тайна. Контролировать эти конструкты сложнее, чем любые другие откованные творения Пирфора.

Стражи часто действуют самостоятельно, без хозяина. Они гуляют по важным местам смертного мира или же замирают, спокойные, как статуи. Иногда стражи стоят так десятилетиями, после чего вновь оживают и продолжают движение к своим таинственным целям.`,
      source: {
        id: SOURCE_MOOT,
        page: 211,
      },
    },
    anvilwroughtDescription,
  ],
  note: `Благословенна богами. Желанна людьми. Не признательна никому`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_ANVILWROUGHT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MOOT,
    page: 212,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 19,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ONE_OF_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Тараном',
      damageText: '10 (3к6) дробящего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 15, иначе будет сбита с ног.',
    },
    {
      id: ABILITY_SPELL_TURNING,
      spellLvl: 4,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Тараном_.`,
    },
    {
      name: 'Таран',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Огненное дыхание',
      restore: 5,
      description: `★СУЩЕСТВО★ выдыхает пламя 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 15, получая 27 (6к8) урона огнём при провале, или половину этого урона при успехе.`,
    },
  ],
}
