const {
  CREATURE_ZOMBIE,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_NECROMANCY,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  PARAM_CON,
} = require('./../../../../../paramList')
const {
  DAMAGE_NECROTIC,
} = require('./../../../../../damageTypeList')
const {
  SPELL_FINGER_OF_DEATH,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FINGER_OF_DEATH,
  name: 'Перст смерти',
  nameEn: 'Finger of Death',
  description: `Вы посылаете негативную энергию в видимое Вами существо в пределах дистанции, вызывая у него сильную боль.

Цель должна пройти испытание Телосложения. В случае провала она получает урон некротической энергией 7к8 + 30, или половину этого урона в случае успеха. Гуманоид, убитый этим заклинанием, воскресает в начале Вашего следующего хода как [зомби](CREATURE:${CREATURE_ZOMBIE}), постоянно находящийся под Вашим командованием и выполняющий Ваши устные команды.`,
  lvl: 7,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_NECROTIC,
      halfOnSuccess: true,
      diceCount: 7,
      diceType: 8,
      diceBonus: 30,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 256,
    },
    {
      id: SOURCE_SRD,
      page: 144,
    },
  ],
}
