const {
  SOURCE_ERFTLW,
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  INFUSION_HOMUNCULUS_SERVANT,
} = require('./../../../../infusionIdList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SIZE_TINY,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_HOMUNCULUS,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  ABILITY_EVASION,
} = require('./../../../../creatureAbilityList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FORCE,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CR_0,
} = require('./../../../../crList')
const {
  homunculiDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_HOMUNCULUS_SERVANT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Слуга-гомункул',
  nameEn: 'Homunculus Servant',
  id: CREATURE_HOMUNCULUS_SERVANT,
  description: homunculiDescriptionList,
  createdByInfusionId: INFUSION_HOMUNCULUS_SERVANT,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_HOMUNCULUS,
  ],
  alignmentId: ALIGNMENT_N,
  source: [
    {
      id: SOURCE_ERFTLW,
      page: 63,
    },
    {
      id: SOURCE_TCoE,
      page: 24,
    },
  ],
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: `1 + модификатор Интеллекта изобретателя + уровень изобретателя`,
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  saveThrowList: [
    PARAM_DEX,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_0,
  featureList: [
    {
      id: ABILITY_EVASION,
      commentText: `Эта способность работает только пока ★он★ ★Дееспособен★.`,
    },
    {
      name: 'Мощь хозяина',
      description: `Следующие значения ★его★ параметров увеличиваются на 1, когда бонус мастерства изобретателя увеличивается на 1:

* Бонусы к навыкам.
* Бонусы к испытаниям.
* Бонусы к попаданию атаками.
* Бонусы к урону атак.
`,
    },
  ],
  actionList: [
    {
      name: 'Силовой удар',
      comment: `требует бонусного действия изобретателя`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 4,
        range: 30,
        target: {
          count: 1,
          comment: 'видимая изобретателем',
        },
        hit: {
          type: DAMAGE_FORCE,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
  reactionList: [
    {
      name: 'Передача магии',
      description: `★СУЩЕСТВО★ доставляет накладываемое изобретателем заклинание с дистанцией «касание». ★СУЩЕСТВО★ ★должен★ при этом находиться в пределах 120 футов от изобретателя.`,
    },
  ],
}
