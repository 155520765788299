const {
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_PUTRID_UNDEAD_SPIRIT,
  CREATURE_PUTRID_UNDEAD_SPIRIT_2020_03_26,
  CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух разлагающейся нежити',
  nameEn: 'Putrid Undead Spirit',
  id: CREATURE_PUTRID_UNDEAD_SPIRIT_2020_03_26,
  releasedAs: CREATURE_PUTRID_UNDEAD_SPIRIT,
  parentId: CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  featureList: [
    {
      name: 'Тлетворная аура',
      description: `★СУЩЕСТВО★ источает ауру гниения радиусом 5 футов. Каждое существо в этой области (кроме заклинателя), начинающее свой ход в этой ауре, должно пройти испытание Телосложения против сложности заклинаний заклинателя, или стать отравленным до начала своего следующего хода.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Разлагающая клешня',
      description: `Если цель отравлена, то она должна пройти испытание Телосложения против сложности заклинаний заклинателя или стать парализованной до конца своего следующего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  extendPropCollection: {
    speed: {
      [SPEED_FLY]: {
        value: 40,
        isFloating: true,
      },
    },
  },
}
