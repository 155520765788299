const reducePropIdList = require('./../../../utils/reducePropIdList')

const generateSpellPossibleRangeList = spellList => spellList
  .reduce(reducePropIdList('range'), [])
  .sort(
    (A, B) => {
      if (A === Infinity) {
        return 1
      } else if (B === Infinity) {
        return -1
      } else if (typeof A === typeof B) {
        return A > B
          ? 1
          : -1
      } else if (typeof A === 'string' && typeof B === 'number') {
        return 1
      } else if (typeof A === 'number' && typeof B === 'string') {
        return -1
      }
    }
  )

module.exports = generateSpellPossibleRangeList
