const {PC_SUBCLASS_WIZARD_SCHOOL_OF_ILLUSION} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {SPELL_MINOR_ILLUSION} = require('./../../../spellIdList')
const {
  FEATURE_ILLUSION_SAVANT,
  FEATURE_ILLUSORY_REALITY,
  FEATURE_ILLUSORY_SELF,
  FEATURE_IMPROVED_MINOR_ILLUSION,
  FEATURE_MALLEABLE_ILLUSIONS,
  FEATURE_SPELLBOOK,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ILLUSION_SAVANT,
    name: `Мастер иллюзий`,
    nameEn: `Illusion Savant`,
    lvl: 2,
    text: `Когда Вы выбираете эту школу на 2 уровне, золото и время, которое Вы тратите на копирование заклинания Иллюзии в свою [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), уменьшаются вдвое.`,
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
  {
    id: FEATURE_IMPROVED_MINOR_ILLUSION,
    name: `Улучшенная малая иллюзия`,
    nameEn: `Improved Minor Illusion`,
    lvl: 2,
    text: `Когда Вы выбираете данную школу на 2 уровне, Вы узнаёте заговор [Малая иллюзия](SPELL:${SPELL_MINOR_ILLUSION}). Если Вам уже известен этот заговор, то Вы изучаете любой другой заговор волшебника на свой выбор. Этот заговор не учитывается в общем количестве известных Вам заговоров.

При сотворении [Малой иллюзии](SPELL:${SPELL_MINOR_ILLUSION}) Вы можете использовать эффекты звука и изображения вместе, единым заклинанием.`,
    pcSubClassId: PC_SUBCLASS_WIZARD_SCHOOL_OF_ILLUSION,
    spellIdList: SPELL_MINOR_ILLUSION,
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
  {
    id: FEATURE_MALLEABLE_ILLUSIONS,
    name: `Пластичные иллюзии`,
    nameEn: `Malleable Illusions`,
    lvl: 6,
    text: `Если Вы сотворили заклинание школы Иллюзии длительностью как минимум 1 минута, Вы можете действием изменить характер этой иллюзии (используя обычные ограничения для этой иллюзии), при условии, что можете видеть эту иллюзию.`,
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
  {
    id: FEATURE_ILLUSORY_SELF,
    name: `Собственная иллюзорность`,
    nameEn: `Illusory Self`,
    lvl: 10,
    text: `Вы можете инстинктивно создать иллюзорную копию самого себя в ответ на внезапную опасность.

Если существо совершает по Вам бросок атаки, Вы можете реакцией создать иллюзорную копию между атакующим и собой. Атака автоматически промахивается по Вам и уничтожает иллюзию.

После использования данного умения Вы должны завершить короткий или длинный отдых, прежде чем сможете воспользоваться им вновь.`,
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
  {
    id: FEATURE_ILLUSORY_REALITY,
    name: `Иллюзорная реальность`,
    nameEn: `Illusory Reality`,
    lvl: 14,
    text: `Вы постигли секреты плетения теневых заклятий, что делает ваши иллюзии наполовину реальными.

Когда Вы накладываете заклинание Иллюзии 1 круга или выше, Вы можете выбрать любой неодушевлённый и немагический объект, являющийся частью данной иллюзии, чтобы сделать его реальным. Вы можете сделать это в свой ход бонусным действием, пока действует заклинание. Объект становится реальным на 1 минуту. Например, Вы можете создать иллюзию моста через разлом и держать его реальным достаточно долго, чтобы Ваши союзники могли по нему перебраться.

Объект не может причинять урон или непосредственно ранить кого-либо.`,
    source: {
      id: SOURCE_PHB,
      page: 118,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_SCHOOL_OF_ILLUSION,
  })
)

