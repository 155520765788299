const {
  SPEED_WALK,
  SPEED_CLIMB,
  SPEED_DIG,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  SKILL_PERCEPTION,
  SKILL_HISTORY,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  DAMAGE_THUNDER,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
  DAMAGE_BLUDGEONING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_BLIND_VISION,
} = require('./../../../../senseList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_CLAIRVOYANCE,
  SPELL_SCRYING,
  SPELL_TELEKINESIS,
  SPELL_TELEPORTATION_CIRCLE,
} = require('./../../../../spellIdList')
const {
  GOD_BAHAMUT,
  GOD_TIAMAT,
} = require('./../../../../godIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_2,
  ABILITY_FRIGHTFUL_PRESENCE,
  ABILITY_SPIDER_CLIMB,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_ABOLETH,
  CREATURE_DRAGON_SAPPHIRE_ADULT,
  CREATURE_GIANT_SPIDER,
  CREATURE_MIND_FLAYER,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DRAGON_GEM,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CONDITION_FRIGHTENED} = require('./../../../../conditionList')
const {CR_15} = require('./../../../../crList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_DnD_SADS_CLE} = require('./../../../../sourceList')
const {dragonsSapphireNoteList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Взрослый сапфировый дракон',
  nameEn: 'Adult Sapphire Dragon',
  id: CREATURE_DRAGON_SAPPHIRE_ADULT,
  description: [
    `Сияющие сапфировые драконы — самые территориальные (до опасного уровня) из всех самоцветных драконов. Они коллекционируют оружие и доспехи как ключевые части своих кладов и хранят их в изолированных пещерах.

Псионическая природа сапфировых драконов проявляется в рогах и костяных узорах на их телах. Их шипы на хвостах и кончики рогов независимо парят в воздухе, поддерживаемые псионической силой, пока дракон жив. Эти левитирующие рога и шипы немного сдвигаются в зависимости от настроения дракона, потряхиваясь от радости или дрожа от гнева. Их чешуйки и крылья различных оттенков синего, от светлых оттенков весеннего неба до насыщенной лазури сапфировых кристаллов и плотного льда ледников. На свету множество чешуек блестят и переливаются мерцающими отблесками звёздного света.

В отличие от большинства драконов, чешуя детёнышей сапфировых драконов переливается глубочайшими оттенками синего, и будет ослепительно блистать на протяжении всей долгой драконьей жизни.`,
    {
      header: 'Пещерные жители',
      text: `Сапфировые драконы живут в колоссальных пещерах и сетях туннелей глубоко под земной поверхностью. Они вылепляют стены и выкапывают секретные проходы и тайные заслонки, через которые они скрытно перемещаются по своим логовам, выслеживая и охотясь на пришельцев.

Становясь старше и опытнее, дракон может даже убрать все доступные входы и выходы в логово. Дракон может даже запечатать их естественно выглядящими скалами, настолько полна его власть над своим логовом.

[Гигантские пауки](CREATURE:${CREATURE_GIANT_SPIDER}) — любимая пища сапфировых драконов. Они обожают охотиться на этих гигантских насекомых в глубинных проходах своих логов.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      header: 'Воинственные и территориальные',
      text: `Сапфировые драконы милитаристичны и воинственны, они защищают свою территорию с таким тщанием, которые почти не встречается у других драконов. Они продумывают стратегии и засады, учитывая свою врождённую способность подземного маневрирования. Достигая определённого возраста, они научаются магически управлять камнем, из которого состоят их логова. Несмотря на это, они не нападают немедленно, предпочитая изучить и оценить вторженцев, чтобы использовать наиболее выгодную против них тактику.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      header: 'Враги и слуги',
      text: `Народы, живущие под землёй и копающие вглубь, легко обнаруживают что их интересы пересекаются с таковыми живущего в глубине сапфирового дракона. Из-за этого драконы часто презирают как дроу, так и дварфов, не говоря уже об их открытой враждебности ко всем аберрациям.

Сапфировый дракон иногда может заключить мирный договор с сообществом скальных или глубинных гномов, своим могуществом преобразуя их поселения в вооружённые отряды, помогающие дракону защищать прилоговую территорию.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      header: 'Клад-арсенал',
      text: `Золото и драгоценности, предметы искусства и волшебные артефакты так же желанны сапфировыми драконами, как и всеми другими видами, но больше всего они ценят магическое оружие и доспехи, равно как и письменные отчёты о военной истории и тактике.

Сердце клада сапфирового дракона — каталогизированная упорядоченная коллекция накопленного им военного снаряжения, часто содержащая древние реликвии непомерной мощи.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      header: 'Самоцветные драконы',
      text: `Пока металлические драконы следуют за [Багамутом](GOD:${GOD_BAHAMUT}), а цветные поклялись в верности [Тиамат](GOD:${GOD_TIAMAT}), самоцветные — сторонники таинственного рубинового дракона Сардиора. Самоцветные драконы включают аметистовых, изумрудных, сапфировых, топазовых и хрустальных драконов.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      header: 'Скрытные стражи',
      text: `Самоцветные драконы держатся в стороне от дел других существ, даже своего вида. Они тщательно выбирают логова вдали от существующих поселений, где они могли бы обитать в одиночестве, либо же воспитывать тщательно отобранных слуг или вассалов, которые будут отстаивать интересы дракона в окружающем регионе.

Самоцветные драконы отбрасывают своё стремление к одиночеству для спаривания или защиты кладки яиц, но крайне редко по каким-то иным причинам.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      header: 'Харизматичные и учтивые',
      text: `Несмотря на тягу к изоляционизму, при взаимодействии с другими существами самоцветные драконы могут быть блестящими хозяевами и остроумными собеседниками.

Самоцветный дракон, принимая гостей, постарается дать им почувствовать себя в его логове как у себя дома — спокойно и расслаблено. Тогда дракону будет проще убедить гостей в идеях, которые в других условиях им бы никогда не показались разумными.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      header: 'Могучие умы',
      text: `Самоцветные драконы — глубоко псионические существа. Они развивают телепатию вскоре после вылупления (иногда даже до) и её мощь растёт вместе с ними. Некоторые из них развивают ментальные силы ещё больше, получая могучие магические способности одной силой своего разума.

Эти искушённые в псионике драконы часто вступают в конфликты с аберрациями, особенно со [свежевателями разума](CREATURE:${CREATURE_MIND_FLAYER}) и [аболетами](CREATURE:${CREATURE_ABOLETH}). Самоцветные драконы умеют определять области, где Дальний Предел вторгается на Материальный план и борются с чуждыми тварями, рождёнными или искажёнными такими местами.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      header: 'Правитель самоцветных драконов',
      text: `Уникальный рубиновый дракон Сардиор — величайший из всех самоцветных драконов. Он — их король, обитающий в парящем замке, вырезанном из рубина и красного золота. Его рубиновая цитадель летает высоко над поверхностью мира и только в ночном небе. Если заметить её, стоя на земле, то она выглядит багровой звездой, медленно пересекающей созвездия высоко в небе.

В крепости Сардиора сопровождает совет пяти, состоящий из невероятно могущественных древних драконов каждого из самоцветных видов.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      header: 'Логово сапфирового дракона',
      text: `Сапфировые драконы устраивают логова в раскидистых системах пещер и туннелей. Вырастая, они обновляют и переделывают логова своей способностью управлять камнем и врождённому дару к копанию.

Драконы создают системы немыслимой сложности из скрытых проходов, тонких стен и тайных комнат, позволяющие им совершенно незаметно для вторженцев перемещаться по всему логову. В логове вообще может не быть нормального входа, доступного без использования магии или раскопок.

# Действия логова

На инициативе 20 (проигрыш при ничье), дракон может действием логова вызвать один из следующих эффектов; дракон не может использовать один и тот же эффект два раза подряд

* Дракон выбирает целью существо в пределах 120 футов, которое может его слышать и понимать. Существо должно пройти испытание Мудрости СЛ 15 или стать Очарованным драконом до инициативы 20 в следующем раунде. Очарованное таким образом существо наилучшим возможным образом выполняет любые приказы дракона, кроме тех, что нанесут ему самому вред напрямую.
* Дракон касается области камня размером не более 10 футов по любой из сторон. Дракон может сформировать этот камень в любую нужную ему форму.
* На твёрдой поверхности в пределах 120 футов от дракона возникает каменная стена. Стена до 60 футов длинной, до 10 футов высотой и толщиной в 1 фут. Когда стена возникает, все существа в её пространстве выталкиваются на одну из сторон стены (по выбору существа).Каждая 5-футовая секция стены имеет КД 17, 20 хитов, уязвимость к урону звуком и иммунитет к яду и психическому урону. Стена исчезает, когда дракон использует это действие логова снова или умирает.

# Региональные эффекты

Область, окружающая логово легендарного сапфирового дракона, искажена драконьей магией, что создаёт один или несколько из следующих эффектов.

* [Гигантские пауки](CREATURE:${CREATURE_GIANT_SPIDER}) привлекаются в область в пределах 6 миль от логова и селятся здесь в больших количествах.
* Природный камень в пределах 6 миль от логова отращивают множество кристаллов и пронизываются сапфировыми жилами, особенно под землёй.
* Природный камень в пределах 6 миль от логова становится проводником драконьего псионического присутствия. Дракон может действием сотворить заклинание [Подсматривание](SPELL:${SPELL_CLAIRVOYANCE}) без каких-либо компонентов, нацеливаясь им на любое каменистое образование в этой области.`,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
  ],
  sizeType: SIZE_HUGE,
  note: dragonsSapphireNoteList,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAGON_GEM,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_DnD_SADS_CLE,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
    [SPEED_DIG]: 30,
    [SPEED_FLY]: 80,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 21,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_PERSUASION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_THUNDER,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DRACONIC,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_15,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_2,
    ABILITY_SPIDER_CLIMB,
    {
      name: 'Прокладывание туннеля',
      nameEn: 'Tunneller',
      description: `★СУЩЕСТВО★ может копать сквозь сплошной камень со скоростью копания, уменьшенной вдвое, и может решить оставить за собой туннель диаметром 10 футов.`,
    },
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_SCRYING,
          SPELL_TELEKINESIS,
          SPELL_TELEPORTATION_CIRCLE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ может использоватьь _Ужасающее присутствие_. После этого ★он★ совершает две рукопашные атаки, одну — _Укусом_ и одну — _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 10,
            diceBonus: 6,
          },
          {
            type: DAMAGE_THUNDER,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      id: ABILITY_FRIGHTFUL_PRESENCE,
      range: 120,
      witSave_DC: 17,
    },
    {
      name: 'Сотрясающее дыхание',
      nameEn: 'Debilitating Breath',
      restore: 5,
      description: `★СУЩЕСТВО★ выдыхает поток пронзительного, почти неразличимого звука 60-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 18. При провале существо получает 45 (13к6) урона звуком и теряет дееспособность до конца своего следующего хода. При успехе существо получает только половину урона и не теряет дееспособность.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Удар хвостом',
      description: '★СУЩЕСТВО★ совершает одну атаку _Хвостом_.',
    },
    {
      name: 'Телекинетический бросок',
      description: `★СУЩЕСТВО★ выбирает видимый ★им★ в пределах 60 футов Маленький или меньший предмет, который никто не несёт и не носит и магически бросает его в видимое ★им★ в пределах 60 футов от этого предмета существо. Цель должна пройти испытание Ловкости СЛ 17 или получить 14 (4к6) дробящего урона.`,
    },
    {
      name: 'Телепортация',
      cost: 2,
      description: `★СУЩЕСТВО★ магически телепортируется в видимое ★им★ в пределах 30 футов свободное пространство`,
    },
  ],
}
