const {SOURCE_VGTM} = require('./../../sourceList')
const {CREATURE_TYPE_AASIMAR} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_AASIMAR,
  nameEn: 'Aasimar',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'аасимар',
        genitive: 'аасимара',
        dative: 'аасимару',
        accusative: 'аасимара',
        instrumental: 'аасимаром',
        prepositional: 'аасимаре',
      },
      [GENDER_FEMALE]: {
        nominative: 'аасимарша',
        genitive: 'аасимарши',
        dative: 'аасимарше',
        accusative: 'аасимаршу',
        instrumental: 'аасимаршой',
        prepositional: 'аасимарше',
      },
    },
    plural: {
      nominative: 'аасимары',
      genitive: 'аасимаров',
      dative: 'аасимарам',
      accusative: 'аасимаров',
      instrumental: 'аасимарами',
      prepositional: 'аасимарах',
    },
  },
  note: {
    text: `Я видел её, окутанную крыльями из чистого света, её глаза сияли яростью богов. Костяные дьяволы замерли, прикрывая лица. Её клинок, а теперь уже меч света, взметнулся раз, другой, третий. Головы дьяволов летели на землю, одна за другой. Так мы и узнали, что в составе нашего разношерстного отряда есть аасимар.`,
    author: `Гелдон Пэрл, о Тирании Драконов`,
  },
  description: {
    text: `Аасимары несут в себе дух небесного света. Они происходят от людей, осененных силой с Горы Селестии, божественных чертогов многих принципиально-добрых божеств. Аасимары рождаются, чтобы служить поборниками богов, их рождение провозглашается как благодать. Внешне они люди другого мира, ярко выраженные особенности которых выдают их небесное происхождение.`,
    source: {
      id: SOURCE_VGTM,
      page: 106,
    },
  },
}
