const {CREATURE_CULTIST} = require('./../../creatureIdList')
const {SOURCE_MTOF} = require('./../../sourceList')
const {
  GOD_ASMODEUS,
  GOD_TIAMAT,
} = require('./../../godIdList')

module.exports = [
  {
    header: 'Абишаи',
    text: `Каждый абишай когда-то был смертным, который каким-то образом завоевал милость [Тиамат](GOD:${GOD_TIAMAT}) до своей смерти, и в качестве награды, его душа преобразовалась в отвратительного дьявола, чтобы служить ей в Девяти Преисподней.`,
    source: {
      id: SOURCE_MTOF,
      page: 175,
    },
  },
  {
    header: 'Посланники погибели',
    text: `[Тиамат](GOD:${GOD_TIAMAT}) использует абишаев как агентов, отправляя их, чтобы представлять её интересы в Преисподней и по всей Мультивселенной. У некоторых простые задачи, такие как предоставление послания [культистам](CREATURE:${CREATURE_CULTIST}) или принятие на себя обязанностей, поклоняющихся выполнять чуждую миссию. Другие имеют более широкие обязанности, такие как ведение больших групп, убийство целей и участие в войсках. Во всех случаях абишаи фанатично лояльны к [Тиамат](GOD:${GOD_TIAMAT}), и при необходимости, готовы отдать свою жизнь.`,
    source: {
      id: SOURCE_MTOF,
      page: 175,
    },
  },
  {
    header: 'Чужаки в Аду',
    text: `Абишаи не входят в обычную иерархию Девяти Преисподней, имея свою собственную цепь командования, беспрекословно подчиняясь [Тиамат](GOD:${GOD_TIAMAT}) (и [Асмодею](GOD:${GOD_ASMODEUS}), когда тёмный лорд решает использовать их). Другие архидьяволы могут приказывать абишаям работать на них, но большинство из них редко делает это, так как никогда не ясно, выполняет ли абишай приказы [Тиамат](GOD:${GOD_TIAMAT}) или [Асмодея](GOD:${GOD_ASMODEUS}). Существует неотъемлемый риск того, что приказ, отданный [Тиамат](GOD:${GOD_TIAMAT}), может отмениться из-за вмешательства в планы [Асмодея](GOD:${GOD_ASMODEUS}).`,
    source: {
      id: SOURCE_MTOF,
      page: 175,
    },
  },
]
