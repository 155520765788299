const {FEATURE_EYES_OF_THE_DEEP} = require('./../../../featureIdList')
const {PC_SUBCLASS_BARBARIAN_PATH_OF_THE_DEPTHS} = require('./../../../pcSubClassIdList')
const {SOURCE_LOR_DTOB} = require('./../../../sourceList')
const {SPELL_TRUE_SEEING} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_EYES_OF_THE_DEEP,
    name: `Глаза Глубин`,
    nameEn: `Eyes of the Deep`,
    lvl: 10,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_DEPTHS,
    spellIdList: SPELL_TRUE_SEEING,
    source: {
      id: SOURCE_LOR_DTOB,
      url: 'https://www.dndbeyond.com/sources/lrdtob/heroes-and-scoundrels-of-bilgewater#ManifestationsoftheDeep',
    },
  },
]
