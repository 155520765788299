const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
  TIME_ROUND,
} = require('./../../../../../timePeriodList')
const {
  PARAM_STR,
} = require('./../../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../../damageTypeList')
const {
  SPELL_TSUNAMI,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TSUNAMI,
  name: 'Цунами',
  nameEn: `Tsunami`,
  description: `В выбранной точке в пределах дистанции появляется стена из воды. Вы можете создать стену длиной до 300 футов, высотой 300 футов и толщиной 50 футов. Стена существует, пока заклинание активно.

Когда стена появляется, все существа в её области должны пройти испытание Силы. При провале они получают дробящий урон 6к10 или половину этого урона при успехе.

В начале каждого Вашего хода, после того как стена появилась, стена, вместе со всеми находящимися в ней существами, перемещается на 50 футов от Вас. Все существа с размером не больше Огромного, находящиеся внутри стены, или те, в чьё пространство стена входит при перемещении, должны пройти испытание Силы, иначе они получают дробящий урон 5к10. Существо может получить этот урон только один раз за раунд. В конце хода высота стены уменьшается на 50 футов, а получаемый существами урон в последующие раунды уменьшается на 1к10. Когда высота стены достигает 0 футов, заклинание оканчивается.

Существа, оказавшиеся в стене, могут перемещаться, плавая. Однако, из-за того, что волна очень сильная, существо должно совершить успешную проверку Силы (Атлетика) против СЛ Ваших заклинаний, чтобы получить возможность перемещаться. Провалив проверку, существо не может перемещаться. Существо, выбравшееся из области цунами, падает на землю.`,
  lvl: 8,
  magicSchoolId: MAGIC_CONJURATION,
  range: 'Видимость',
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_ROUND, count: 6 },
  castTime: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_STR,
    damage: {
      type: DAMAGE_BLUDGEONING,
      diceCount: 5,
      diceType: 10,
    },
  },
  source: {
    id: SOURCE_PHB,
    page: 288,
  },
}
