const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_3,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_FEY,
  CREATURE_TYPE_HAG,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DANCING_LIGHTS,
  SPELL_MINOR_ILLUSION,
  SPELL_VICIOUS_MOCKERY,
} = require('./../../../../spellIdList')
const {
  CAST_MATERIAL,
} = require('./../../../../castComponentList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  hagsDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AMPHIBIOUS,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_GREEN_HAG,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Зелёная карга',
  nameAlt: 'Болотная карга',
  nameEn: 'Green Hag',
  id: CREATURE_GREEN_HAG,
  description: [
    `Гнусные и злобные зелёные карги живут в умирающих лесах, на безлюдных болотах и туманных топях, устраивая логова в пещерах. Зелёные ведьмы любят манипулировать людьми, скрывая намерения за слоями лжи. Они заманивают жертв, имитируя голоса и прося о помощи, отваживая нежеланных гостей криками диких зверей.`,
    {
      header: 'Одержимость печалью',
      text: `Зелёные карги пируют на печали и трагедиях других. Они ликуют, когда надежда оборачивается отчаяньем не только для одного существа, но и для целых народов.`,
      source: {
        id: SOURCE_MM,
        page: 170,
      },
    },
    ...hagsDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_HAG,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 170,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 16,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_COMMON,
    LANG_SYLVAN,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Подражание',
      description: `★СУЩЕСТВО★ может подражать звукам животных и голосам гуманоидов. Существо, слышащее эти звуки, может понять, что это подражание, если совершит успешную проверку Мудрости (Проницательность) СЛ 14.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_VICIOUS_MOCKERY,
          SPELL_MINOR_ILLUSION,
          SPELL_DANCING_LIGHTS,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Иллюзорная внешность',
      description: `★СУЩЕСТВО★ покрывает себя и всё, что несёт и носит, магической иллюзией, заставляющей ★его★ выглядеть как другое существо примерно ★его★ размера и с гуманоидным телом. Иллюзия оканчивается, если ★СУЩЕСТВО★ умирает, и ★СУЩЕСТВО★ может окончить её бонусным действием.

Изменения, внесённые эти эффектом, не проходят физическую проверку. Например, кожа карги может выглядеть гладкой, но тот, кто её коснётся, почувствует морщины. Если же полагаться только на зрение, то существо должно действием совершить проверку Интеллекта (Анализ) СЛ 20 и преуспеть, чтобы понять, что была использована иллюзия.`,
    },
    {
      name: 'Невидимая ходьба',
      description: `★СУЩЕСТВО★ магическим образом становится ★невидимым★, пока не атакует, или не наложит заклинание, либо пока не прервёт концентрацию (как при концентрации на заклинании). Будучи ★невидимым★, ★он★ не оставляет физических следов, так что выследить ★его★ можно только с помощью магии. Всё снаряжение, которое ★он★ несёт и носит, становится невидимым вместе с ★ним★.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
