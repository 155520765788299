const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPELL_CHARM_PERSON,
  SPELL_COMMAND,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_FEEBLEMIND,
  SPELL_FIREBALL,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_COMMON,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_CHARMED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_AMNIZU,
  CREATURE_LEMURE,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_MELEE_SPELL,
} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_18} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {
  SOURCE_BGDIA,
  SOURCE_MTOF,
} = require('./../../../../sourceList')

module.exports = {
  name: 'Амнизу',
  nameAlt: [
    'Амницу',
    'Дьявол Стикса',
  ],
  nameEn: 'Amnizu',
  id: CREATURE_AMNIZU,
  description: [
    {
      header: 'Амнизу',
      text: `Амнизу ведут адские легионы в битву и командуют стражами на Вратах Ада. Амнизу высокомерны, склонны к насилию и беспощадны, но, при этом, они весьма искусны в тактике и безотказно верны, что и ценят эрцгерцоги Ада.`,
      source: {
        id: SOURCE_MTOF,
        page: 180,
      },
    },
    {
      header: 'Охрана реки Стикс',
      text: `Некоторые амнизу выполняют опасную задачу — следить за рекой Стикс из крепостей вдоль разбитых берегов реки, где она протекает через Дис и Стигию. Души, прибывающие в виде [лемуров](CREATURE:${CREATURE_LEMURE}), не имеют никакой личности или воспоминаний; они движимы только желанием совершить зло. Патрулирующий эту область амнизу внедряет правила Девяти Преисподних в жалкие мозги новоприбывших и размещает этих [лемуров](CREATURE:${CREATURE_LEMURE}) в легионы.`,
      source: {
        id: SOURCE_MTOF,
        page: 180,
      },
    },
    {
      header: 'Амнизу',
      text: `Это высшие дьяволы, командующие легионами Преисподней в битвах и стражами врат в Девять Преисподних. Амнизу самодовольны, жестоки и беспринципны, но при этом  они хитроумны в тактике и беспрекословно верны — архидьяволы это ценят.

Некоторые амнизу выполняют важное задание — следят за рекой Стикс с башен и крепостей, стоящих вдоль отравленных берегов. За это амнизу называют ещё дьяволами Стикса.`,
      source: {
        id: SOURCE_BGDIA,
        page: 228,
      },
    },
    ...devilDescriptionList,
  ],
  note: devilNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 180,
    },
    {
      id: SOURCE_BGDIA,
      page: 228,
    },
  ],
  armor: {
    ac: 21,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 27,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 1000,
    },
  ],
  cr: CR_18,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_INT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_CHARM_PERSON,
          SPELL_COMMAND,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DOMINATE_PERSON,
          SPELL_FIREBALL,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DOMINATE_MONSTER,
          SPELL_FEEBLEMIND,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Амнизу использует _Отравление разума_ и совершает две атаки: одну — _Кнутом надсмотрщика_ и одну — _Разрушающим прикосновением_.`,
    },
    {
      name: 'Кнут надсмотрщика',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 4,
            diceBonus: 5,
          },
          {
            type: DAMAGE_FORCE,
            diceCount: 6,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Разрушающее прикосновение',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 11,
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 8,
          diceType: 10,
          diceBonus: 0,
        },
      },
    },
    {
      name: 'Отравление разума',
      description: `Амнизу выбирает одно или два существа в пределах 60 футов от него. Каждая цель должна пройти испытание Мудрости СЛ 19 или получить 26 (4к12) урона некротической энергией и ослепнуть до начала следующего хода Амнизу.`,
    },
    {
      name: 'Забывчивость',
      restore: 6,
      description: `Амнизу выбирает одно существо в пределах 60 фт от себя. Это существо должно пройти испытание Интеллекта СЛ 16 или стать Ошеломлённым на одну минуту. Ошеломлённое существо повторяет испытание в конце каждого своего хода, заканчивая эффект при успехе. Если существо пробыло ошеломлённым 1 полную минуту, оно забывает всё, что чувствовало, испытывало и знало за последние 5 часов.`,
    },
  ],
  reactionList: [
    {
      name: 'Инстинктивное очарование',
      description: `Когда существо в пределах 60 фт от амнизу совершает атаку по нему, и другое существо находится в пределах досягаемости атаки, атакующий должен пройти испытание Мудрости СЛ 19. При провале, атакующий должен сделать целью атаки ближайшее к нему существо, исключая его самого и Амнизу. Если ближайшими являются несколько существ сразу, атакующий выбирает, какую из них сделать целью. Если испытание пройдено, то атакующий невосприимчив к _Инстинктивному очарованию_ этого амнизу на 24 часа.`,
    },
  ],
}
