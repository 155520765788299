const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_MIDANI} = require('./../../languageIdList')
const {
  PLACE_ZAKHARA,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_MIDANI,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Мидани',
    genitive: 'Мидани',
    instrumental: 'Мидани',
    prepositional: 'Мидани',
  },
  nameEn: 'Midani',
  spokenPlaceList: PLACE_ZAKHARA,
  typicalSpeakers: 'Бедины',
  isReady: false,
  isRealLang: true,
}
