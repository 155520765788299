const {
  SPELL_CONTROL_WATER,
  SPELL_DETECT_MAGIC,
  SPELL_DIVINATION,
  SPELL_GUIDANCE,
  SPELL_HOLD_PERSON,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_SACRED_FLAME,
  SPELL_SANCTUARY,
  SPELL_SCRYING,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_THAUMATURGY,
  SPELL_TONGUES,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_OTHERWORLDLY_PERCEPTION,
  ABILITY_SLIPPERY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_PERCEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KUO_TOA,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
} = require('./../../../../damageTypeList')
const {
  kuoToaDescriptionList,
  kuoToaNote,
} = require('./../../../../textCommonParts')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_KUO_TOA_ARCHPRIEST} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_UNDERCOMMON} = require('./../../../../languageIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Верховный жрец куо-тоа',
  nameEn: 'Kuo-toa Archpriest',
  id: CREATURE_KUO_TOA_ARCHPRIEST,
  note: kuoToaNote,
  description: kuoToaDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_KUO_TOA,
  ],
  alignmentId: ALIGNMENT_NE,
  source: [
    {
      id: SOURCE_MM,
      page: 185,
    },
    {
      url: 'https://www.aidedd.org/dnd/monstres.php?vo=kuo-toa-archpriest',
      problemText: `В Бестиарии перевода студии Phantom ошибка: «у Верховного жреца куо-тоа» не заклинание «Власть над погодой», а заклинание «Власть над водами». У него не может быть заклинания «Власть над погодой», потому что оно 8 уровня. На английском слова weather и water схожи, скорее всего, редакторы просто проглядели.`,
    },
  ],
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_RELIGION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_6,
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_OTHERWORLDLY_PERCEPTION,
    ABILITY_SLIPPERY,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    spellCasterLevel: 10,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_CONTROL_WATER,
      SPELL_DETECT_MAGIC,
      SPELL_DIVINATION,
      SPELL_GUIDANCE,
      SPELL_HOLD_PERSON,
      SPELL_MASS_CURE_WOUNDS,
      SPELL_SACRED_FLAME,
      SPELL_SANCTUARY,
      SPELL_SCRYING,
      SPELL_SHIELD_OF_FAITH,
      SPELL_SPIRIT_GUARDIANS,
      SPELL_SPIRITUAL_WEAPON,
      SPELL_THAUMATURGY,
      SPELL_TONGUES,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Скипетр',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 4,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Безоружный удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
