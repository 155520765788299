const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {ABILITY_INCORPOREAL_MOVEMENT} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_SPELL} = require('./../../../../attackTypeList')
const {CREATURE_WILL_O_WISP} = require('./../../../../creatureIdList')
const {CR_2} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Блуждающий огонёк',
  nameAlt: 'Волшебный огонёк',
  nameEn: 'Will-o’-Wisp',
  id: CREATURE_WILL_O_WISP,
  description: [
    {
      header: 'Блуждающие огоньки',
      text: `Блуждающие огоньки являются злобными, похожими на дымку, шарами света, которые обитают в уединённых местах и на полях сражений, связанных с мрачной судьбой или тёмной магией, и питаются страхом и отчаянием.`,
      source: {
        id: SOURCE_MM,
        page: 25,
      },
    },
    {
      header: 'Надежда и обречение',
      text: `Издали блуждающие огоньки выглядят как свет раскачивающегося фонаря, хотя они могут менять свой цвет и уменьшать яркость до минимума. Активируя свой свет, блуждающие огоньки внушают надежду, намекая на безопасность существам, которые следуют за ними. Блуждающие огоньки заманивают опрометчивых созданий в ямы с зыбучим песком, убежища чудовищ и другие опасные места, чтобы кормиться страданиями своей жертвы и наслаждаться её предсмертными криками. Злое существо, которое стало жертвой блуждающего огонька, тоже может стать огоньком, и его несчастный дух материализуется над его бездыханным трупом подобно мерцающему пламени.`,
      source: {
        id: SOURCE_MM,
        page: 25,
      },
    },
    {
      header: 'Поглощённые отчаянием',
      text: `Блуждающие огоньки являются душами злых существ, которые погибли в мучениях или страданиях, поскольку они блуждали по покинутым местам, пропитанным могущественной магией. Они появляются в болотистых трясинах и полях сражений, усыпанных костями, где угнетающее настроение насыщеннее и тяжелее, чем густой туман и мгла. Заточенные в этих заброшенных местах потерянных надежд и воспоминаний, блуждающие огоньки приманивают других созданий, и питаются страданиями путников.`,
      source: {
        id: SOURCE_MM,
        page: 25,
      },
    },
    {
      header: 'На службе зла',
      text: `Блуждающие огоньки редко разговаривают, однако если говорят, то их голос звучит как далёкий шёпот. В скверных местах, в которых они обитают, блуждающие огоньки иногда образуют симбиотические отношения с опасными соседями. Карги, они, чёрные драконы и злобные культисты используют блуждающих огоньков для заманивания существ в засаду. И пока злобные союзники истребляют созданий, которых они окружили, огоньки, паря над ними, питаются агонией умирающих тел.`,
      source: {
        id: SOURCE_MM,
        page: 25,
      },
    },
    {
      header: 'Природа нежити',
      text: `Блуждающему огоньку не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 25,
      },
    },
  ],
  note: 'Следуй за светом на свой страх и риск',
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 25,
  },
  armor: 19,
  hp: {
    diceCount: 9,
  },
  cr: CR_2,
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 50,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 28,
    [PARAM_CON]: 10,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  immunityList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
  ],
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_NECROTIC,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_UNCONSCIOUS,
    CONDITION_GRAPPLED,
    CONDITION_EXHAUSTION,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  featureList: [
    {
      name: 'Истребление жизни',
      description: `★СУЩЕСТВО★ может бонусным действием нацелиться на одно существо, которое видит в пределах 5 футов, у которого при этом 0 хитов, и которое всё ещё живо. Цель должна пройти испытание Телосложения СЛ 10 от этой магии, иначе умрёт. Если цель умирает, блуждающий огонёк восстанавливает 10 (3к6) хитов.`,
    },
    {
      name: 'Эфемерность',
      description: `Блуждающий огонёк не может ничего носить и нести.`,
    },
    ABILITY_INCORPOREAL_MOVEMENT,
    {
      name: 'Переменное свечение',
      description: `★СУЩЕСТВО★ испускает яркий свет в радиусе от 5 до 20 футов и тусклый свет в пределах ещё такого же количества футов. ★СУЩЕСТВО★ может изменять радиус бонусным действием.`,
    },
  ],
  actionList: [
    {
      name: 'Шок',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_ELECTRICITY,
          diceCount: 2,
          diceType: 8,
        },
      },
    },
    {
      name: 'Невидимость',
      description: `★СУЩЕСТВО★ и его свет магическим образом становятся невидимыми, пока он не совершит атаку, не использует _Истребление жизни_, или пока не прервётся его концентрация (как при концентрации на заклинании).`,
    },
  ],
  genderId: GENDER_MALE,
}
