const {FEATURE_DEEP_STALKER_CONCLAVE_UA_2016_09_12_THE_RANGER_REVISED_SPELLS} = require('./../../../featureIdList')
const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_DEEP_STALKER_CONCLAVE_2016_09_12} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2016_09_12_THE_RANGER_REVISED} = require('./../../../sourceList')
const {
  SPELL_DISGUISE_SELF,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREATER_INVISIBILITY,
  SPELL_ROPE_TRICK,
  SPELL_SEEMING,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_DEEP_STALKER_CONCLAVE_UA_2016_09_12_THE_RANGER_REVISED_SPELLS,
    name: `Заклинания глубинных охотников`,
    nameEn: `Deep Stalker Spells`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    pcSubClassId: PC_SUBCLASS_RANGER_DEEP_STALKER_CONCLAVE_2016_09_12,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_DISGUISE_SELF,
      SPELL_GLYPH_OF_WARDING,
      SPELL_GREATER_INVISIBILITY,
      SPELL_ROPE_TRICK,
      SPELL_SEEMING,
    ],
    source: {
      id: SOURCE_UA_2016_09_12_THE_RANGER_REVISED,
      page: 7,
    },
  },
]
