const {GENDER_FEMALE} = require('./../../genderList')
const {CREATURE_TYPE_LOCATHAH} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_LOCATHAH,
  nameEn: 'Locathah',
  genderId: GENDER_FEMALE,
  name: {
    singular: {
      nominative: 'локата',
      genitive: 'локаты',
      dative: 'локате',
      accusative: 'локату',
      instrumental: 'локатой',
      prepositional: 'локате',
    },
    plural: {
      nominative: 'локаты',
      genitive: 'локат',
      dative: 'локатам',
      accusative: 'локат',
      instrumental: 'локатами',
      prepositional: 'локатах',
    },
  },
}
