const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_UA_2017_04_03_STARTER_SPELLS,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SPELL_TOLL_THE_DEAD} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TOLL_THE_DEAD,
  name: 'Погребальный звон',
  nameEn: 'Toll the Dead',
  description: `Вы указываете на одно существо, которое можете видеть в пределах дистанции, и воздух вокруг него на мгновение наполняется скорбным звучанием колокола. Цель должна пройти испытание Мудрости или получает 1к8 урона некротической энергией. Если хиты цели были не полные, то вместо 1к8 она получает 1к12 урона некротической энергией.`,
  highLvlCast: `Урон заклинания увеличивается на одну кость, когда Вы достигаете 5 уровня (2к8 или 2к12), 11 уровня (3к8 или 3к12) и 17 уровня (4к8 или 4к12).`,
  lvl: 0,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_WIT,
    damage: {
      type: DAMAGE_NECROTIC,
      diceCount: 1,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 161,
    },
    {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 4,
    },
  ],
}
