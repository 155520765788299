const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  MAGIC_ITEM_RING_OF_RESISTANCE_ACID,
  MAGIC_ITEM_RING_OF_RESISTANCE_COLD,
  MAGIC_ITEM_RING_OF_RESISTANCE_ELECTRICITY,
  MAGIC_ITEM_RING_OF_RESISTANCE_FIRE,
  MAGIC_ITEM_RING_OF_RESISTANCE_FORCE,
  MAGIC_ITEM_RING_OF_RESISTANCE_NECROTIC,
  MAGIC_ITEM_RING_OF_RESISTANCE_POISON,
  MAGIC_ITEM_RING_OF_RESISTANCE_PSYCHIC,
  MAGIC_ITEM_RING_OF_RESISTANCE_RADIANT,
  MAGIC_ITEM_RING_OF_RESISTANCE_THUNDER,
} = require('./../../../../../magicItemIdList')

const list = [
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_ACID,
    damageTypeId: DAMAGE_ACID,
    gemName: 'Жемчужина',
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_COLD,
    damageTypeId: DAMAGE_COLD,
    gemName: 'Турмалин',
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_FIRE,
    damageTypeId: DAMAGE_FIRE,
    gemName: 'Гранат',
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_FORCE,
    damageTypeId: DAMAGE_FORCE,
    gemName: 'Сапфир',
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_ELECTRICITY,
    damageTypeId: DAMAGE_ELECTRICITY,
    gemName: 'Цитрин',
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_NECROTIC,
    damageTypeId: DAMAGE_NECROTIC,
    gemName: 'Гагат',
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_POISON,
    damageTypeId: DAMAGE_POISON,
    gemName: 'Аметист',
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_PSYCHIC,
    damageTypeId: DAMAGE_PSYCHIC,
    gemName: 'Жадеит',
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_RADIANT,
    damageTypeId: DAMAGE_RADIANT,
    gemName: 'Топаз',
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_THUNDER,
    damageTypeId: DAMAGE_THUNDER,
    gemName: 'Шпинель',
  },
]

module.exports = list
