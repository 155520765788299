const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
  ABILITY_KEEN_SMELL,
  ABILITY_MOUNTAIN_BORN,
  ABILITY_POWERFUL_BUILD,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_GOLIATH,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  goliathDescriptionList,
  goliathNote,
  lycantropDescription,
} = require('./../../../../textCommonParts')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NG} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_GOLIATH_WEREBEAR} = require('./../../../../creatureIdList')
const {GEAR_GREATAXE} = require('./../../../../gearIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_IDRotF} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Голиаф-вермедведь',
  nameEn: 'Goliath Werebear',
  id: CREATURE_GOLIATH_WEREBEAR,
  note: goliathNote,
  description: [
    `Легенды голиафов говорят о существе, рыщущем на границах Долины Ледяного Ветра, известном как Ойяминарток или Зимняя Бродяга. Она может принимать облик белого медведя или громадную двуногий облик, скрытую метелью и вьюгой.

Старейшины называют её имя, чтобы уберечь молодых голиафов от странствий слишком далеко от дома, пока они не будут готовы к этому. Взрослые голиафы иногда встречают эту легендарную личность в своих путешествиях в самые дальние уголки долины. Если они бегут как трусы, Ойяминарток смеётся и позволяет им скрыться во вьюге. Если они вызывают её на бой, Зимняя Бродяга улыбается и проверяет их в бою. Тех, кто впечатляет её своей удалью, она кусает и превращает в голиафов-вермедведей — голиафов, носящих проклятье ликантропии Ойяминарток.

Вопреки мифу, Ойяминарток — не чудовище, а сила добра в регионе. Она передаёт своё проклятие только тем голиафам, чьи сердца чисты.

Ойяминарток и прочие из её рода стараются помогать существам, с которыми пересекаются их пути, ведь погода и ландшафт Долины Ледяного Ветра бывают очень тяжёлыми. Они знают самые безопасные пути через тундру и могут провести путников через Море Движущегося Льда и по его постоянно меняющимся льдам. Эти белые вермедведи исстребляют на зло в глуши, но держатся подальше от лагерей и поселений. Огонь — погибель для Ойяминарток и поэтому она и её род избегают каминов и открытых костров.`,
    ...goliathDescriptionList,
    ...lycantropDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOLIATH,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_NG,
  source: {
    id: SOURCE_IDRotF,
    page: 294,
  },
  armor: [
    {
      ac: 10,
      comment: 'в облике гуманоида',
    },
    {
      ac: 12,
      type: ARMOR_TYPE_NATURAL,
      comment: 'в облике медведя и гибридном облике',
    },
  ],
  hp: {
    diceCount: 19,
  },
  speed: {
    [SPEED_WALK]: {
      value: 30,
      comment: '40 фт. в облике медведя и гибридном облике',
    },
    [SPEED_SWIM]: {
      value: 30,
      comment: 'в облике медведя и гибридном облике',
    },
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 18,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  resistanceList: [
    DAMAGE_COLD,
  ],
  immunityList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  languageList: [
    {
      id: [LANG_COMMON, LANG_GIANT],
      comment: 'не может говорить в облике медведя',
    },
  ],
  cr: CR_8,
  featureList: [
    ABILITY_KEEN_SMELL,
    ABILITY_MOUNTAIN_BORN,
    {
      id: ABILITY_POWERFUL_BUILD,
      comment: 'только в облике гуманоида',
    },
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в Большого гибрида гуманоида и медведя или в Большого белого медведя или принять свой истинный облик. Во всех обликах ★его★ характеристики, кроме КД и размера, одинаковы во всех обликах. Всё несомое и носимое им снаряжение не превращается. Он принимает свой истинный облик, если умирает.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Укус',
      comment: `только в облике медведя и гибридном облике`,
      description: `Если цель — гуманоид, она должна пройти испытание Телосложения СЛ 15 или стать проклятой ликантропией вермедведей.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Когти',
      comment: `только в облике медведя и гибридном облике`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
    {
      gearId: GEAR_GREATAXE,
      comment: `только в облике гуманоида и гибридном облике`,
    },
  ],
  reactionList: [
    {
      name: 'Устойчивость камня',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Когда ★СУЩЕСТВО★ получает урон, ★он★ может уменьшить его на 10 (1к12 + 4).`,
    },
  ],
}
