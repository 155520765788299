const {BACKGROUND_CHARLATAN} = require('./../../backgroundIdList')
const {CREATURE_PSEUDODRAGON} = require('./../../creatureIdList')
const {PC_CLASS_WARLOCK} = require('./../../pcClassIdList')
const {warlockSpellSlotTable} = require('./../../spellSlotTable')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../sourceList')
const {
  PARAM_CHA,
  PARAM_WIT,
} = require('./../../paramList')
const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_WARLOCK,
  FEATURE_ELDRITCH_INVOCATIONS,
  FEATURE_ELDRITCH_MASTER,
  FEATURE_MYSTIC_ARCANUM,
  FEATURE_OTHERWORLDLY_PATRON,
  FEATURE_PACT_BOON,
  FEATURE_PACT_MAGIC,
} = require('./../../featureIdList')
const {
  GEAR_COMPONENT_POUCH,
  GEAR_CROSSBOW_BOLTS,
  GEAR_CROSSBOW_LIGHT,
  GEAR_DAGGER,
  GEAR_DUNGEONEER_S_PACK,
  GEAR_LEATHER_ARMOR,
  GEAR_SCHOLAR_S_PACK,
} = require('./../../gearIdList')
const {
  CAT_LIGHT_ARMOR,
  CAT_SIMPLE_WEAPON,
} = require('./../../gearCategoryList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_HISTORY,
  SKILL_INTIMIDATION,
  SKILL_INVESTIGATION,
  SKILL_NATURE,
  SKILL_RELIGION,
} = require('./../../skillList')
const {
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_ELDRITCH_BLAST,
  SPELL_WITCH_BOLT,
} = require('./../../spellIdList')

module.exports = {
  id: PC_CLASS_WARLOCK,
  name: {
    singular: {
      nominative: 'колдун',
      genitive: 'колдуна',
      dative: 'колдуну',
      accusative: 'колдуна',
      instrumental: 'колдуном',
      prepositional: 'колдуне',
    },
    plural: {
      nominative: 'колдуны',
      genitive: 'колдунов',
      dative: 'колдунам',
      accusative: 'колдунов',
      instrumental: 'колдунами',
      prepositional: 'колдунах',
    },
  },
  nameEn: 'warlock',
  description: [
    `Молодой эльф в золотых одеждах, с [псевдодраконом](CREATURE:${CREATURE_PSEUDODRAGON}), свернувшимся у него на плече, тепло улыбается дворцовому стражу, вплетая магическое очарование в свои сладкие речи, чтобы подчинить его своей воле.

Когда в руках морщинистой старухи оживает пламя, она шепчет тайное имя своего демонического покровителя, вливая в заклинание зловещую магию.

Переведя взгляд с потрёпанного тома на необычное сочетание звёзд над головой, тифлинг с дикими глазами начинает мистический ритуал, который откроет проход в далёкий мир.

Колдуны — искатели знаний, что скрываются в ткани мультивселенной. Через договор, заключённый с таинственными существами сверхъестественной силы, колдуны открывают для себя магические эффекты, как едва уловимые, так и впечатляющие воображение. Колдуны подпитывают свои силы древними знаниями таких существ как феи, демоны, дьяволы, карги и чужеродные сущности Дальнего Предела.`,
    {
      header: `Клятва и долг`,
      text: `Колдун заключает договор с потусторонней сущностью. Иногда отношения между колдуном и его покровителем похожи на отношения жреца и его божества, но сущности, выступающие в роли покровителей, вовсе не боги. Колдун может возглавить культ, посвящённый принцу демонов, архидьяволу или совершенно чужеродной сущности — созданиям, у которых обычно нет жрецов. Куда чаще их взаимоотношения похожи на отношения мастера и ученика. Знания колдуна и его сила растут по мере оказания услуг покровителю.

Магия, которой наделён колдун, может вносить незначительные изменения в его сущность (например, способность видеть в темноте или читать на любом языке), а также дарует возможность пользоваться мощными заклинаниями. В отличие от начитанных волшебников, колдуны могут дополнять магию некоторыми элементами рукопашного боя. Они хорошо себя чувствуют в лёгком доспехе, и знают, как пользоваться простым оружием.`,
      source: {
        id: SOURCE_PHB,
        page: 106,
      },
    },
    {
      header: `Поиски тайн`,
      text: `Колдунами управляет ненасытная жажда знаний и власти, это вынуждает их заключать договоры и определяет их будущую жизнь.

Истории о колдунах, связавших себя с исчадиями, широко известны. Но не все покровители колдунов — демонические сущности. Иногда путешественник находит в дебрях леса красивую башню, встречает её хозяина или хозяйку — фею, и оказывается втянутым в договор, не получив должной информации. А иногда, углубившись в толстые тома книг с запрещёнными знаниями, ученик открывает свой ум — блестящий, но сумасшедший, реальности, находящейся за пределами материального мира в пустоте, в которой таятся чужеродные создания. 

Как только договор заключён, жажда колдуна к знаниям и власти не может быть утолена обычной учёбой и исследованиями. Никто не заключает договор с могущественным покровителем без намерения использовать полученную силу.

Подавляющее большинство колдунов проводит свои дни в погоне за своими собственными целями. Как правило, это разного рода приключения. Кроме того, требования покровителей ведут колдунов навстречу приключениям.`,
      source: {
        id: SOURCE_PHB,
        page: 106,
      },
    },
    {
      header: `Создание колдуна`,
      text: `Когда Вы создаёте персонажа колдуна, потратьте немного времени и подумайте о том, каков будет Ваш покровитель, и какие обязательства на вас накладывает договор с ним. Что привело вас к заключению договора, и как Вы встретились с покровителем? Соблазнил ли вас дьявол, когда Вы его вызывали, или же Вы искали ритуал, который бы позволил вызвать древнего бога? Искали ли Вы покровителя или покровитель сам нашёл и выбрал вас? Раздражают ли вас обязательства, возложенные договором, или же Вы радостно служите в ожидании награды?

Посоветуйтесь со своим Мастером, чтобы определить, какую роль будет играть Ваш договор во время приключений. Требования вашего покровителя могут втянуть вас в различные приключения, или же это будут небольшие услуги, которые персонаж выполняет между приключениями. Какие отношения у вас с покровителем? Они дружественные, враждебные, сложные или же романтичные? Насколько важным Вы являетесь для своего покровителя? Какую роль играете в его планах? Знаете ли Вы других слуг своего покровителя?

Как покровитель общается с вами? Если у вас есть фамильяр, он может неожиданно заговорить голосом покровителя. Некоторые колдуны находят послания от своих покровителей вырезанными на деревьях, среди листьев чайной заварки или в движении облаков — такие послания может увидеть только сам колдун. Другие колдуны разговаривают со своими покровителями во снах или видениях, или имеют дело только с посредниками.`,
      source: {
        id: SOURCE_PHB,
        page: 107,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать колдуна, следуя этим рекомендациям.

1. Во-первых, у Вашей Харизмы должно быть наивысшее значение. Следующим по величине должно быть значение Телосложения.
2. Во-вторых, выберите предысторию «[Шарлатан](BACKGROUND:${BACKGROUND_CHARLATAN})».
3. В-третьих, выберите заговоры [Леденящее прикосновение](SPELL:${SPELL_CHILL_TOUCH}) и [Мистический заряд](SPELL:${SPELL_ELDRITCH_BLAST}), а также заклинания 1 уровня [Ведьмин снаряд](SPELL:${SPELL_WITCH_BOLT}) и [Очарование личности](SPELL:${SPELL_CHARM_PERSON}).`,
      source: {
        id: SOURCE_PHB,
        page: 107,
      },
    },
  ],
  note: [
    {
      text: `Думаешь, я свихнулся? Я думаю, по-настоящему свихнуться — это тянуть лямку простого смертного, когда знания и сила под рукой, чтобы получить их и править миром.`,
      author: `Заррен, вестник Акамара`,
    },
    {
      text: `Так ты получил свои силы, заключив до говор с кем-то? В письменной форме, на пергаменте? Ладно, не важно. Просто ска жи мне вот что: могу ли я получить твои силы, сожрав тебя?`,
      author: `Занатар`,
    },
    {
      text: `Каждый раз, когда непостижимо могущественная сущность врывается и предлагает богоподобные награды в обмен на несколько крошечных одолжений — это мошенничество.

Если только это не я. Я никогда не солгу тебе, дорогой читатель.`,
      author: `Таша`,
    },
  ],
  featureCollection: {
    hitDice: 8,
    proficiency: {
      armor: [
        CAT_LIGHT_ARMOR,
      ],
      weapon: {
        catList: [
          CAT_SIMPLE_WEAPON,
        ],
      },
      savingThrow: [
        PARAM_CHA,
        PARAM_WIT,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_ARCANA,
            SKILL_DECEPTION,
            SKILL_HISTORY,
            SKILL_INTIMIDATION,
            SKILL_INVESTIGATION,
            SKILL_NATURE,
            SKILL_RELIGION,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 4,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) [Лёгкий арбалет](GEAR:${GEAR_CROSSBOW_LIGHT}) и 20 [арбалетных болтов](GEAR:${GEAR_CROSSBOW_BOLTS})
    * б) Любое простое оружие
2. Выберите одно
    * а) [Мешочек с компонентами](GEAR:${GEAR_COMPONENT_POUCH})
    * б) Магическая фокусировка
3. Выберите одно
    * а) [Набор учёного](GEAR:${GEAR_SCHOLAR_S_PACK})
    * б) [Набор исследователя подземелий](GEAR:${GEAR_DUNGEONEER_S_PACK})
4. [Кожаный доспех](GEAR:${GEAR_LEATHER_ARMOR})
5. Любое простое оружие
6. Два [кинжала](GEAR:${GEAR_DAGGER})`,
    },
  },
  progressTable: `
| Уровень | Умения | Известные заговоры | Известные заклинания | Ячейки заклинаний | Круг ячеек | Известные воззвания |
|---------|--------|--------------------|----------------------|-------------------|------------|---------------------|
|  1 | [Потусторонний покровитель](FEATURE:${FEATURE_OTHERWORLDLY_PATRON}), [Магия договора](FEATURE:${FEATURE_PACT_MAGIC}) | 2 | 2 | 1 | 1 | — |
|  2 | [Таинственные воззвания](FEATURE:${FEATURE_ELDRITCH_INVOCATIONS})                | 2 |  3 | 2 | 1 | 2 |
|  3 | [Предмет договора](FEATURE:${FEATURE_PACT_BOON})                                 | 2 |  4 | 2 | 2 | 2 |
|  4 | [Увеличение характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_WARLOCK}) | 3 |  5 | 2 | 2 | 2 |
|  5 | —                                                                                | 3 |  6 | 2 | 3 | 3 |
|  6 | Умение покровителя                                                               | 3 |  7 | 2 | 3 | 3 |
|  7 | —                                                                                | 3 |  8 | 2 | 4 | 4 |
|  8 | Увеличение характеристик                                                         | 3 |  9 | 2 | 4 | 4 |
|  9 | —                                                                                | 3 | 10 | 2 | 5 | 5 |
| 10 | Умение покровителя                                                               | 4 | 10 | 2 | 5 | 5 |
| 11 | [Таинственный арканум](FEATURE:${FEATURE_MYSTIC_ARCANUM}) (6 уровень)            | 4 | 11 | 3 | 5 | 5 |
| 12 | Улучшение характеристик                                                          | 4 | 11 | 3 | 5 | 6 |
| 13 | Таинственный арканум (7 уровень)                                                 | 4 | 12 | 3 | 5 | 6 |
| 14 | Умение покровителя                                                               | 4 | 12 | 3 | 5 | 6 |
| 15 | Таинственный арканум (8 уровень)                                                 | 4 | 13 | 3 | 5 | 7 |
| 16 | Увеличение характеристик                                                         | 4 | 13 | 3 | 5 | 7 |
| 17 | Таинственный арканум (9 уровень)                                                 | 4 | 14 | 4 | 5 | 7 |
| 18 | —                                                                                | 4 | 14 | 4 | 5 | 8 |
| 19 | Увеличение характеристик                                                         | 4 | 15 | 4 | 5 | 8 |
| 20 | [Таинственный мастер](FEATURE:${FEATURE_ELDRITCH_MASTER})                        | 4 | 15 | 4 | 5 | 8 |
`,
  spellSlotTable: warlockSpellSlotTable,
  source: [
    {
      id: SOURCE_PHB,
      page: 106,
    },
    {
      id: SOURCE_SRD,
      page: 46,
    },
  ],
}
