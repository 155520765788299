const {FEATURE_METAL_MAGIC} = require('./../../../featureIdList')
const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_SORCERER_STONE_SORCERY_2017_02_06} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2017_02_06_SORCERER} = require('./../../../sourceList')
const {
  SPELL_BLINDING_SMITE,
  SPELL_BRANDING_SMITE,
  SPELL_COMPELLED_DUEL,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_MAGIC_WEAPON,
  SPELL_SEARING_SMITE,
  SPELL_STAGGERING_SMITE,
  SPELL_THUNDEROUS_SMITE,
  SPELL_WRATHFUL_SMITE,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_METAL_MAGIC,
    name: `Металлическая магия`,
    nameEn: `Metal Magic`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_SORCERER_STONE_SORCERY_2017_02_06,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_BLINDING_SMITE,
      SPELL_BRANDING_SMITE,
      SPELL_COMPELLED_DUEL,
      SPELL_ELEMENTAL_WEAPON,
      SPELL_MAGIC_WEAPON,
      SPELL_SEARING_SMITE,
      SPELL_STAGGERING_SMITE,
      SPELL_THUNDEROUS_SMITE,
      SPELL_WRATHFUL_SMITE,
    ],
    source: {
      id: SOURCE_UA_2017_02_06_SORCERER,
      page: 3,
    },
  },
]
