const {
  CREATURE_AIR_ELEMENTAL,
  CREATURE_EARTH_ELEMENTAL,
  CREATURE_FIRE_ELEMENTAL,
  CREATURE_WATER_ELEMENTAL,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_ITEM_ELEMENTAL_GEM_AIR,
  MAGIC_ITEM_ELEMENTAL_GEM_EARTH,
  MAGIC_ITEM_ELEMENTAL_GEM_FIRE,
  MAGIC_ITEM_ELEMENTAL_GEM_WATER,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_ELEMENTAL_GEM_WATER,
    name: `Камень водного элементаля`,
    nameEn: `Elemental Gem: Water`,
    stoneAppearance: `изумруд`,
    creatureId: CREATURE_WATER_ELEMENTAL,
  },
  {
    id: MAGIC_ITEM_ELEMENTAL_GEM_AIR,
    name: `Камень воздушного элементаля`,
    nameEn: `Elemental Gem: Air`,
    stoneAppearance: `синий сапфир`,
    creatureId: CREATURE_AIR_ELEMENTAL,
  },
  {
    id: MAGIC_ITEM_ELEMENTAL_GEM_EARTH,
    name: `Камень земляного элементаля`,
    nameEn: `Elemental Gem: Earth`,
    stoneAppearance: `жёлтый бриллиант`,
    creatureId: CREATURE_EARTH_ELEMENTAL,
  },
  {
    id: MAGIC_ITEM_ELEMENTAL_GEM_FIRE,
    name: `Камень огненного элементаля`,
    nameEn: `Elemental Gem: Fire`,
    stoneAppearance: `красный корунд`,
    creatureId: CREATURE_FIRE_ELEMENTAL,
  },
]
