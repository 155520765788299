const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_CONSTRUCT,
  nameEn: 'Construct',
  genderId: GENDER_MALE,
  description: {
    text: `Конструкты создаются, а не рождаются. Некоторые запрограммированы создателем следовать простому набору инструкций, другие же имеют зачатки разума и способны к самостоятельному мышлению. Големы — это классические конструкты. Многие существа родом из внешнего плана Механус, такие как модроны, являются конструктами, созданными из материи этого плана волей могущественных существ.`,
    source: {
      id: SOURCE_MM,
      page: 8,
    },
  },
  name: {
    singular: {
      nominative: 'конструкт',
      genitive: 'конструкта',
      dative: 'конструкту',
      accusative: 'конструкта',
      instrumental: 'конструктом',
      prepositional: 'конструкте',
    },
    plural: {
      nominative: 'конструкты',
      genitive: 'конструктов',
      dative: 'конструктам',
      accusative: 'конструктов',
      instrumental: 'конструктами',
      prepositional: 'конструктах',
    },
  },
}
