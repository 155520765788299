const {CREATURE_DRAGON_GOLD_ANCIENT} = require('./../../creatureIdList')
const elegyForTheFirstWorldDescription = require('./elegy_for_the_first_world')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_PALADIN,
} = require('./../../pcClassIdList')
const {
  GOD_BAHAMUT,
  GOD_TIAMAT,
} = require('./../../godIdList')

module.exports = [
  {
    header: 'Король добрых драконов',
    text: `Главным божеством металлических драконов является [Бахамут, Платиновый Дракон](GOD:${GOD_BAHAMUT}). Он обитает в Семи Небесах Горы Селестии, но часто блуждает по Материальному плану в магическом образе почтенного человека мужского пола в крестьянских одеждах. В таком виде он обычно сопровождается семью золотыми канарейками — на самом деле семью [древними золотыми драконами](CREATURE:${CREATURE_DRAGON_GOLD_ANCIENT}) в превращённом облике.

Багамут редко вмешивается в дела смертных, хотя делает исключения для того, чтобы помешать махинациям [Тиамат, Королевы Драконов](GOD:${GOD_TIAMAT}) и её злых потомков. [Жрецы](PC_CLASS:${PC_CLASS_CLERIC}) и [паладины](PC_CLASS:${PC_CLASS_PALADIN}) с добрым мировоззрением иногда поклоняются [Бахамуту](GOD:${GOD_BAHAMUT}) за его приверженность справедливости и защите. Будучи малым божеством, он может предоставлять божественные заклинания.`,
    source: {
      id: SOURCE_MM,
      page: 120,
    },
  },
  elegyForTheFirstWorldDescription,
  {
    header: `Бахамут`,
    text: `Ангел Семи Небес — драконий бог правосудия. Он привержен идеалам милосердия и справедливой власти, а потому издревле враждует со своей сестрой [Тиамат](GOD:${GOD_TIAMAT}).`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
]
