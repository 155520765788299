const {CREATURE_TYPE_GOLIATH} = require('./../../creatureTypeIdList')
const {
  goliathDescriptionList,
  goliathNote,
} = require('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GOLIATH,
  nameEn: 'Goliath',
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'голиаф',
        genitive: 'голиафа',
        dative: 'голиафу',
        accusative: 'голиафа',
        instrumental: 'голиафом',
        prepositional: 'голиафе',
      },
      [GENDER_FEMALE]: {
        nominative: 'голиафша',
        genitive: 'голиафши',
        dative: 'голиафше',
        accusative: 'голиафшу',
        instrumental: 'голиафшей',
        prepositional: 'голиафше',
      },
    },
    plural: {
      nominative: 'голиафы',
      genitive: 'голиафов',
      dative: 'голиафам',
      accusative: 'голиафов',
      instrumental: 'голиафами',
      prepositional: 'голиафах',
    },
  },
  note: goliathNote,
  description: goliathDescriptionList,
}
