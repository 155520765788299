const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_PRESTIDIGITATION} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_PRESTIDIGITATION,
  name: 'Фокусы',
  nameAlt: 'Мелкие фокусы',
  nameEn: 'Prestidigitation',
  description: `Это заклинание — небольшой магический трюк, на котором практикуются начинающие заклинатели.

Вы создаёте один из следующих магических эффектов в пределах дистанции:

* Вы создаёте мгновенный безвредный сенсорный эффект, такой как сноп искр, порыв ветра, тихую мелодию, или необычный запах.
* Вы мгновенно зажигаете или тушите свечу, факел или небольшой костёр.
* Вы мгновенно чистите или мараете предмет, размерами не превышающий 1 кубического фута.
* Вы остужаете, нагреваете или придаёте запах 1 кубическому футу неживой материи на 1 час.
* Вы создаёте на поверхности или предмете маленькую цветную метку или символ, существующую 1 час.
* Вы создаёте немагическую безделушку или иллюзорный предмет, помещающийся в Вашу ладонь, и существующий до конца Вашего следующего хода.

Если Вы накладываете это заклинание несколько раз, то можете иметь не более трёх немгновенных эффектов одновременно, и можете действием окончить один любой из этих эффектов.`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 10,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_HOUR, count: 1 },
  isDurationUntil: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 287,
    },
    {
      id: SOURCE_SRD,
      page: 170,
    },
  ],
}
