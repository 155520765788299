import React, { createElement } from 'react'
import PropTypes from 'prop-types'

import Image from '@/components/Image'

import { TYPE_ITEM } from '@/constants/itemTypes'

import './ImageListStyles.less'

const ImageListComponent = ({ list, ...rest }) => (
  <div className='ImageList'>
    {
      list.map(
        ({ item, type }, i) => type === TYPE_ITEM
          ? (
            <Image
              {...rest}
              key={i}
              data={item}
            />
          )
          : createElement(item, {key: i})
      )
    }
  </div>
)

ImageListComponent.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ])
  ).isRequired,
}

export default ImageListComponent
