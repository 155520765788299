const {
  SPELL_ARCANE_GATE,
  SPELL_ARMOR_OF_AGATHYS,
  SPELL_ARMS_OF_HADAR,
  SPELL_CHILL_TOUCH,
  SPELL_CLAIRVOYANCE,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_CROWN_OF_MADNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISSONANT_WHISPERS,
  SPELL_DOMINATE_BEAST,
  SPELL_ELDRITCH_BLAST,
  SPELL_GUIDANCE,
  SPELL_JUMP,
  SPELL_LEVITATE,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MINOR_ILLUSION,
  SPELL_PRESTIDIGITATION,
  SPELL_SHOCKING_GRASP,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_TELEKINESIS,
  SPELL_TRUE_SEEING,
  SPELL_VAMPIRIC_TOUCH,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {
  LANG_ANY,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_WARLOCK_OF_THE_GREAT_OLD_ONE} = require('./../../../../creatureIdList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Колдун Великого Древнего',
  nameEn: 'Warlock of the Great Old One',
  id: CREATURE_WARLOCK_OF_THE_GREAT_OLD_ONE,
  description: `Колдуны Великого Древнего получают свои силы от магического договора, заключённого со сверхъестественной сущностью из странных и отдалённых сфер существования. Некоторые из этих колдунов связаны с культистами этих сущностей, таких как аберрации, которые разделяют их цели, а другие колдуны Великого Древнего — эксперты в выкорчёвывании безумия и порока, вдохновляемые странными существами из-за звезд.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 216,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 15,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_PSYCHIC,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
    },
    {
      id: LANG_TELEPATHY,
      range: 30,
    },
  ],
  cr: CR_6,
  featureList: [
    {
      name: 'Шепчущая аура',
      description: `В начале каждого хода колдуна, каждое существо по его выбору в пределах 5 футов от него должно пройти испытание Мудрости СЛ 15 или получит 10 (3к6) урона психической энергией, если колдун дееспособен.`,
    },
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DETECT_MAGIC,
            SPELL_JUMP,
            SPELL_LEVITATE,
            SPELL_SPEAK_WITH_DEAD,
            {
              id: SPELL_MAGE_ARMOR,
              comment: 'только на себя',
            },
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_ARCANE_GATE,
            SPELL_TRUE_SEEING,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 14,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_CHILL_TOUCH,
            SPELL_ELDRITCH_BLAST,
            SPELL_GUIDANCE,
            SPELL_MAGE_HAND,
            SPELL_MINOR_ILLUSION,
            SPELL_PRESTIDIGITATION,
            SPELL_SHOCKING_GRASP,
          ],
        },
        {
          preText: `1–5 уровни (3 ячейки 5-го уровня)`,
          list: [
            SPELL_ARMS_OF_HADAR,
            SPELL_CROWN_OF_MADNESS,
            SPELL_ARMOR_OF_AGATHYS,
            SPELL_CLAIRVOYANCE,
            SPELL_VAMPIRIC_TOUCH,
            SPELL_CONTACT_OTHER_PLANE,
            SPELL_DISSONANT_WHISPERS,
            SPELL_DETECT_THOUGHTS,
            SPELL_DIMENSION_DOOR,
            SPELL_DOMINATE_BEAST,
            SPELL_TELEKINESIS,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
