const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_QUAGGOTH,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_QUAGGOTH} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_UNDERCOMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_ATHLETICS} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Кваггот',
  nameEn: 'Quaggoth',
  id: CREATURE_QUAGGOTH,
  description: [

    {
      header: 'Квагготы',
      text: `Дикие и территориальные квагготы карабкаются по расщелинам Подземья. Они яростно сметают своих противников, становясь ещё более смертоносными перед лицом смерти.`,
      source: {
        id: SOURCE_MM,
        page: 173,
      },
    },
    {
      header: 'Происхождение квагготов',
      text: `Квагготы никогда не были просвещённым видом, но и не всегда были жестокими обитателями Подземья, коими они являются сегодня. В отдалённом прошлом племена квагготов обитали на поверхности, как ночные лесные охотники, обладающие своим языком и культурой. Когда в мире смертных появились эльфы, они схлестнулись с квагготами, что со временем почти привело к вымиранию последних. Лишь побег в глубины Подземья позволил квагготам выжить.

С течением столетий под поверхностью мира, шкуры квагготов обесцветились, а их зрение приспособилось к темноте. Постоянная опасность и странная магия их новой среды обитания изменила их. Становясь всё более дикими и жестокими, они поедали всю еду, которую могли найти, а когда не могли найти её — охотились друг на друга. Они забыли своё прошлое тогда, когда каннибализм стал частью их новой культуры.`,
      source: {
        id: SOURCE_MM,
        page: 173,
      },
    },
    {
      header: 'Слуги дроу',
      text: `Древняя вражда с эльфами поверхности облегчила привлечение квагготов тёмными эльфами на свою сторону. За недавние годы дроу заинтересовались разведением квагготов, поощряя их свирепость, и укрепляя их послушание. Богатые дома дроу содержат легионы квагготов под своим командованием. Более того, дроу культивируют ненависть квагготов к эльфам, отправляясь с ними в набеги на известные им анклавы эльфов на поверхности.`,
      source: {
        id: SOURCE_MM,
        page: 173,
      },
    },
    {
      header: 'Тоноты',
      text: `Некоторые квагготы впитывают псионическую энергию, которой пронизаны части Подземья. Когда племя обнаруживает, что один из них унаследовал способности такого рода, они выдвигают его на роль племенного шамана, или тонота. Тонот хранит знания племени и гарантирует его превосходство над врагами. Тонота, который подвёл своё племя, убивают и съедают во время каннибальского ритуала, в надежде, что его силы перейдут к другому, более достойному квагготу.`,
      source: {
        id: SOURCE_MM,
        page: 173,
      },
    },
    {
      header: 'Иммунитет к ядам',
      text: `Поколения охоты на ядовитых подземных существ и постоянное воздействие плесени и грибов, растущих в глубинах, заставили квагготов выработать иммунитет к ядам всех видов.`,
      source: {
        id: SOURCE_MM,
        page: 173,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_QUAGGOTH,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 173,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 16,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Ярость от ранения',
      description: `Если у ★СУЩЕСТВО★ 10 или меньше хитов, он овершает броски атаки с преимуществом. Кроме того, он причиняет дополнительный урон 7 (2к6) всем целям, по которым попадает рукопашными атаками.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Когтем_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
