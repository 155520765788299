import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"

import Header from './components/Header'
import Footer from './components/Footer'

import './LayoutStyles.less'

const LayoutComponent = ({ children, className = '' }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <section className={classNames(
        'Layout',
        className,
      )}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <Footer/>
      </section>
    )}
  />
)

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutComponent
