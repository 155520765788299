const {CREATURE_TYPE_HEXBLOOD} = require('./../../creatureTypeIdList')
const {SOURCE_VRGtR} = require('./../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_HEXBLOOD,
  nameEn: 'Hexblood',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'порчекровный',
        genitive: 'порчекровного',
        dative: 'порчекровному',
        accusative: 'порчекровного',
        instrumental: 'порчекровным',
        prepositional: 'порчекровном',
      },
      [GENDER_FEMALE]: {
        nominative: 'порчекровная',
        genitive: 'порчекровной',
        dative: 'порчекровной',
        accusative: 'порчекровную',
        instrumental: 'порчекровной',
        prepositional: 'порчекровной',
      },
    },
    plural: {
      nominative: 'порчекровные',
      genitive: 'порчекровных',
      dative: 'порчекровным',
      accusative: 'порчекровных',
      instrumental: 'порчекровными',
      prepositional: 'порчекровных',
    },
  },
  description: [
    {
      header: 'Порчекровные',
      text: `В вопросах, где одного хотения недостаточно, древняя магия может помочь обрести желаемое — по крайней мере, временно. Порчекровные — это индивидуумы, наполненные сверхъестественной магией, фейской энергией или таинственным колдовством. Некоторые из тех, кто заключает сделки с каргами, получают то, чего так желали, но в конечном итоге оказываются превращёнными. Эти изменения свидетельствуют о влиянии карги: уши расщепляющиеся на раздвоенные кисточки, кожа мертвенного оттенка, длинные волосы, отрастающие после стрижки, и неснимаемый живой венок. Наряду с этими отметинами, порчекровные проявляют карго-подобные черты, такие как тёмное зрение и разнообразные магические способы обмана чужих чувств и защиты своих чувств от подобного.

Некоторые порчекровные получают своё наследие, заключив сделку с каргой. Другие раскрывают свою природу с возрастом — особенно если карга повлияла на них в детстве или даже до рождения. Многие порчекровные становятся приключенцами, чтобы раскрыть тайны своей магии, наладить связь со своей фейской натурой или спастись от преследующей их карги.`,
      source: {
        id: SOURCE_VRGtR,
        page: 18,
      },
    },
    {
      header: 'Наследник карг',
      text: `Создание порчекровных — это один из способов, которым карги создают себе подобных. Все порчекровные внешне напоминают именно тех карг, что наполнили их магией. Это включает и необычный венец, часто называемый бузинокрестьем или ведьминым завитком. Эта живая, похожая на венок часть тела порчекровного растёт из висков вдоль головы, служа видимым знаком сделки между каргой и порчекровным, невыплаченного долга или предстоящих перемен.`,
      source: {
        id: SOURCE_VRGtR,
        page: 19,
      },
    },
    {
      header: 'Происхождение порчекровного',
      text: `Сделка с каргой или другими жуткими силами превратила Вашего персонажа в волшебное существо. Определите его происхождение случайно, броском по таблице «Происхождение порчекровного», или же выберите его самостоятельно.

# Происхождение порчекровного
| к6 | Происхождение |
|-|-|
| 1 | Заводя ребёнка, Ваши родители заключили сделку с каргой. Вы — результат той сделки. |
| 2 | Феи-похитители детей подменили настоящего ребёнка Ваших родителей на Вас. |
| 3 | Шабаш карг потерял одну из своих членов. И Вас создали ей на замену. |
| 4 | Вас прокляли в детстве. Сделка с духами леса освободила Вас от проклятия, но превратила в порчекровного. |
| 5 | Вы родились феей, но некое происшествие изменило Вас и вынудило покинуть дом. |
| 6 | Друид-отступник превратил Вас в порчекровного. Теперь Вы будете жить только пока заповедное древо будет приносить плоды. |
`,
      source: {
        id: SOURCE_VRGtR,
        page: 19,
      },
    },
    {
      header: 'Порчекровные в Доменах Ужаса',
      text: `Создавая порчекровного, спросите своего Мастера о том, имеет ли смысл создать для Вашего персонажа связь с одним из следующих Доменов Ужаса

# Хазлан

Причудливая магия этого рушащегося домена подвергает население воздействию сверхъестественных сил, иногда порождая порчекровных.

# Картакасс

Странные ведьмы обосновались в лесах Картакасса. Они охотно исполняют желания местных жителей, стремящихся разбогатеть, в обмен на своих детей.

# Тепест

Многие из детей в городе Виктал — порчекровные, проявляющие свою сверхъестественную природу с раннего детства. Каждый ребенок считается подарком от покровительствующего городу божества, известного как Мать.`,
      source: {
        id: SOURCE_VRGtR,
        page: 19,
      },
    },
  ],
}
