const {
  SPELL_CHARM_PERSON,
  SPELL_CONFUSION,
  SPELL_ENTHRALL,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_OTTO_S_IRRESISTIBLE_DANCE,
  SPELL_SUGGESTION,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ELADRIN,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_FEY,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  SKILL_DECEPTION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
} = require('./../../../../gearIdList')
const {
  eladrinDescription,
  eladrinNote,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_ELADRIN_SPRING} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Весенний эладрин',
  nameEn: 'Spring Eladrin',
  id: CREATURE_ELADRIN_SPRING,
  description: [
    `С полной радости сердцами, весенние эладрины скачут по своим лесным царствам, а по воздуху слышны их смех и песни. Эти игривые эладрины очаровывают других существ, наполняя их весенней радостью. Их выходки могут привести других существ к опасности и натворить им много бед.`,
    ...eladrinDescription,
  ],
  note: eladrinNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_ELADRIN,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MTOF,
    page: 243,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 18,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ELVEN,
    LANG_SYLVAN,
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Фейский шаг',
      restore: 4,
      description: `Бонусным действием эладрин может телепортироваться на расстояние до 30 фт. в незанятое пространство, которое он может видеть.`,
    },
    {
      name: 'Пленительное присутствие',
      description: `Любое существо, не являющееся эладрином и начинающее свой ход в пределах 60 футов от весеннего эладрина, должно пройти испытание Мудрости СЛ 16. При провале существо очаровывается эладрином на 1 минуту. При успехе существо становится невосприимчивым к _Пленительному присутствию_ всех эладринов на 24 часа. Каждый раз, когда эладрин причиняет урон очарованному существу, оно может повторить испытание, оканчивая эффект при успехе.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_CHARM_PERSON,
          SPELL_TASHA_S_HIDEOUS_LAUGHTER,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_CONFUSION,
          SPELL_ENTHRALL,
          SPELL_SUGGESTION,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_HALLUCINATORY_TERRAIN,
          SPELL_OTTO_S_IRRESISTIBLE_DANCE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Эладрин совершает две атаки оружием. Вместо одной из атак он может наложить заклинание. `,
    },
    {
      gearId: GEAR_LONGSWORD,
      attack: {
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 1,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
    {
      gearId: GEAR_LONGBOW,
      attack: {
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 1,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
  ],
}
