const {DAMAGE_RADIANT} = require('./../../../damageTypeList')
const {GEAR_LASER_PISTOL} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  WEAPON_AMMUNITION_ENERGY_CELLS,
  WEAPON_RELOAD,
  WEAPON_RANGE,
} = require('./../../../weaponPropList')
const {
  CAT_FIREARMS,
  CAT_MARTIAL_RANGE_WEAPON,
  CAT_TECH_FUTURISTIC,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_LASER_PISTOL,
  name: 'Лазерный пистолет',
  nameAlt: 'Лазпистолет',
  nameEn: 'Laser Pistol',
  nameByCase: {
    nominative: 'лазерный пистолет',
    genitive: 'лазерного пистолета',
    accusative: 'лазерный пистолет',
    instrumental: 'лазерным пистолетом',
  },
  weight: 2,
  damageType: DAMAGE_RADIANT,
  damage: {
    diceCount: 3,
    diceType: 6,
  },
  genderId: GENDER_MALE,
  category: [
    CAT_FIREARMS,
    CAT_MARTIAL_RANGE_WEAPON,
    CAT_TECH_FUTURISTIC,
  ],
  weaponPropList: [
    WEAPON_AMMUNITION_ENERGY_CELLS,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 40,
        max: 120,
      },
    },
    {
      id: WEAPON_RELOAD,
      value: 50,
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
