const {
  SOURCE_GAME_BG_3,
  SOURCE_MTOF,
} = require('./../../sourceList')
const {GOD_TIAMAT} = require('./../../godIdList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_HUMAN,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')

const queenOfEvilDragonsDescription = require('./queen_of_evil_dragons')
const elegyForTheFirstWorldDescription = require('./elegy_for_the_first_world')

module.exports = [
  queenOfEvilDragonsDescription,
  {
    header: 'Тиамат ждёт и наблюдает',
    text: `Королева Драконов живёт на Аверно. Её логово находится на вершине огромной горы, и стаи драконов кружат вокруг неё, отпугивая демонов. Со своей стороны, демоны избегают этого места. Орды Бездны по-плохому усвоили, что [Тиамат](GOD:${GOD_TIAMAT}) и её двор лучше оставить в покое. Она, похоже, совсем не заинтересована в том, чтобы участвовать в Войне Крови.

Присутствие Тиамат на Аверно уже давно озадачивает мудрецов. Как и в случае с дьяволами, она не может покинуть этот план по своей воле. Её требуется вызвать, и только самые могущественные ритуалы могут вытянуть её в мир. Тогда, если она здесь была заточена, как и почему это произошло?

Наиболее распространённая теория гласит, что её заточение связано с окончанием долгой войны между драконами и великанами, что она была заперта здесь могучим проклятием, наложенным забытым богом великанов, которого она убила. Проклятье положило конец войне, но также окончило эру великанов и драконов на Материальном плане, расчистив дорогу для становления [людей](PC_RACE:${PC_RACE_HUMAN}), [орков](PC_RACE:${PC_RACE_ORC}), [дварфов](PC_RACE:${PC_RACE_DWARF}), [эльфов](PC_RACE:${PC_RACE_ELF}) и других народов. Если бы [Тиамат](GOD:${GOD_TIAMAT}) вернулась в материальный мир, это событие могло спровоцировать или стать сигналом возобновления войны между великанами и драконами.`,
    source: {
      id: SOURCE_MTOF,
      page: 13,
    },
  },
  elegyForTheFirstWorldDescription,
  {
    header: `Тиамат`,
    text: `Тиамат Многоглавая — это извивающаяся масса алчности и ненависти, ныне пленённая в Девяти Преисподних. Драконья богиня жадности вечно замышляет побег, и к тому же стремятся её многочисленные поклонники в мире Торила.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
]
