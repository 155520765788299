const {
  CREATURE_BAT,
  CREATURE_GARGOYLE,
  CREATURE_NYCALOTH,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_YUGOLOTH,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_ABYSSAL,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_INVISIBILITY,
  SPELL_MIRROR_IMAGE,
} = require('./../../../../spellIdList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_9} = require('./../../../../crList')
const {GEAR_GREATAXE} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {yugolothDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Никалот',
  nameEn: 'Nycaloth',
  id: CREATURE_NYCALOTH,
  description: [
    `Никалоты, элитные воздушные штурмовики юголотов, выглядят как мускулистые [горгульи](CREATURE:${CREATURE_GARGOYLE}). Они стремительно взмывают в небо на своих мощных крыльях, похожих на крылья [летучих мышей](CREATURE:${CREATURE_BAT}), а их бритвенно-острые когти на руках и ногах с лёгкостью рассекают плоть и кость. Никалот — кошмарный враг, он внезапно и безо всякого предупреждения наносит мощный удар, а потом телепортируется прочь. Он использует врождённые магические способности, чтобы становиться невидимым или создавать свою иллюзорную копию, таким образом ещё больше вводя в замешательство своих врагов.

Никалоты — наиболее лояльные из юголотов. Если они находят злобного хозяина, который хорошо к ним относится, они вряд ли нарушат соглашение с ним, если только им не пообещают действительно хорошую награду`,
    ...yugolothDescriptionList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_YUGOLOTH,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 319,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 19,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_9,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DARKNESS,
          SPELL_DETECT_MAGIC,
          SPELL_DISPEL_MAGIC,
          SPELL_INVISIBILITY,
          SPELL_MIRROR_IMAGE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки, либо одну рукопашную атаку и телепортируется до или после атаки.`,
    },
    {
      name: 'Коготь',
      description: `Если цель — существо, она должна пройти испытание Телосложения СЛ 16, иначе будет получать рубящий урон 5 (2к4) в начале каждого своего хода из-за инфернальной раны. Каждый раз, когда ★СУЩЕСТВО★ попадает этой атакой по цели инфернальной раной, урон, причиняемый раной, увеличивается на 5 (2к4). Любое существо может действием закрыть рану успешной проверкой Мудрости (Медицина) СЛ 13. Рана также закрывается, если цель получает магическое лечение`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Секира',
      // gearId: GEAR_GREATAXE, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 12,
          diceBonus: 5,
        },
      },
    },
    {
      id: ABILITY_TELEPORT,
      range: 60,
    },
  ],
  genderId: GENDER_MALE,
}
