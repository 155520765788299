module.exports = [
  require('./half_ogre'),
  require('./harpy'),
  require('./hawk'),
  require('./hell_hound'),
  require('./helmed_horror'),
  require('./hezrou'),
  require('./hill_giant'),
  require('./hippogriff'),
  require('./hobgoblin'),
  require('./hobgoblin_captain'),
  require('./hobgoblin_warlord'),
  require('./homunculus'),
  require('./hook_horror'),
  require('./horned_devil'),
  require('./hunter_shark'),
  require('./hydra'),
  require('./hyena'),
]
