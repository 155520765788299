const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_KUO_TOA} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_KUO_TOA,
  nameEn: 'Kuo-toa',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'куо-тоа',
      genitive: 'куо-тоа',
      dative: 'куо-тоа',
      accusative: 'куо-тоа',
      instrumental: 'куо-тоа',
      prepositional: 'куо-тоа',
    },
    plural: {
      nominative: 'куо-тоа',
      genitive: 'куо-тоа',
      dative: 'куо-тоа',
      accusative: 'куо-тоа',
      instrumental: 'куо-тоа',
      prepositional: 'куо-тоа',
    },
  },
}
