const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_ETHEREALNESS,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ETHEREALNESS,
  name: 'Эфирность',
  nameEn: `Etherealness`,
  description: `Вы вступаете в пограничные окрестности Эфирного Плана, в местность, в которой он пересекается с Вашим текущим планом. Вы остаётесь в Пограничном Эфире, пока активно заклинание, или пока Вы не окончите его действием. Всё это время Вы можете перемещаться в любом направлении. Если Вы перемещаетесь вверх или вниз, каждый фут перемещения стоит 1 дополнительный фут. Вы можете видеть и слышать то, что происходит на плане, с которого Вы пришли, но всё выглядит серым, и Вы не видите то, что находится за пределами 60 футов.

Находясь на Эфирном Плане, Вы можете воздействовать только на существ, которые находятся там же, и только они могут воздействовать на Вас. Существа, не находящиеся на Эфирном Плане, не могут воспринимать Вас и взаимодействовать с Вами, если только у них нет особого умения или магии, позволяющей делать это.

Вы игнорируете все предметы и эффекты, не находящиеся на Эфирном Плане, что позволяет Вам проходить сквозь предметы, оставленные на исходном плане.

Когда заклинание заканчивается, Вы мгновенно возвращаетесь на план, с которого пришли, в месте, которое занимаете в данный момент. Если Вы при этом находитесь в месте, занятом предметом или существом, Вас мгновенно переносит в ближайшее свободное пространство, которое Вы можете занимать, и Вы получаете урон силовым полем, равный удвоенному количеству футов, на которое Вас телепортировало.

Это заклинание не оказывает эффекта, если Вы накладываете его, уже находясь на Эфирном Плане или плане, который не граничит с ним, например, на одном из Внешних Планов.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 8 круга или выше, Вы можете сделать целью до трёх согласных существ (включая Вас) за каждый круг ячейки выше седьмого. Существа должны находиться в пределах 10 футов от Вас, когда Вы накладываете это заклинание.`,
  lvl: 7,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_HOUR, count: 8 },
  source: [
    {
      id: SOURCE_PHB,
      page: 290,
    },
    {
      id: SOURCE_SRD,
      page: 140,
    },
  ],
}
