const {
  CREATURE_NARZUGON,
  CREATURE_NIGHTMARE,
} = require('./../../../../creatureIdList')
const {GEAR_BIT_AND_BRIDLE} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {MAGIC_ITEM_INFERNAL_TACK} = require('./../../../../magicItemIdList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_INFERNAL_TACK,
  name: 'Инфернальная сбруя',
  nameAlt: 'Дьявольская сбруя',
  nameEn: `Infernal Tack`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_BIT_AND_BRIDLE,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `существом со злым мировоззрением`,
  description: `[Нарзугоны](CREATURE:${CREATURE_NARZUGON}) вызывают себе на службу [кошмаров](CREATURE:${CREATURE_NIGHTMARE}) с помощью _инфернальной сбруи_, в которую входят: уздечка, мундштук, седло, стремена и шпоры. [Кошмар](CREATURE:${CREATURE_NIGHTMARE}), снаряжённый такой сбруей, обязан служить носителю специальных шпор, пока носитель не умрёт или пока _сбруя_ не будет снята.

Вы можете действием призвать [кошмара](CREATURE:${CREATURE_NIGHTMARE}), снаряжённого _инфернальной сбруей_, постучав шпорами друг о друга или пройдясь ими по луже крови. В начале Вашего следующего хода, в пределах 20 футов от Вас появляется [кошмар](CREATURE:${CREATURE_NIGHTMARE}). [Кошмар](CREATURE:${CREATURE_NIGHTMARE}) служит Вам союзником и в бою действует на одной инициативе с Вами. Он остаётся c Вами один день, пока либо Вы, либо он не умрёте, или пока Вы не отпустите его действием. Если [кошмар](CREATURE:${CREATURE_NIGHTMARE}) умрёт, то в течение 24 часов он возродится в Девяти Преисподних, и после этого Вы сможете призвать его снова.

_Инфернальная сбруя_ не появляется у кошмара просто так. Тот, кто хочет подчинить кошмара себе, для начала должен его обуздать и насильно навесить на него седло. Ни один кошмар не примет добровольно такую вынужденную службу, однако, некоторые со временем формируют сильную привязанность к своим хозяевам и становятся им верными спутниками.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_MTOF,
    page: 182,
  },
}
