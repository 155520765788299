const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {CR_8} = require('./../../../../crList')
const {MAGIC_ITEM_IRONFANG} = require('./../../../../magicItemIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_EARTHQUAKE} = require('./../../../../spellIdList')
const {blackEarthDescription} = require('./../../../../textCommonParts')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  DAMAGE_ACID,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_TERRAN,
} = require('./../../../../languageIdList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_PETRIFYING_GAZE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_MARLOS_URNRAYLE,
  CREATURE_MEDUSA,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Марлос Урнрейл',
  nameEn: 'Marlos Urnrayle',
  id: CREATURE_MARLOS_URNRAYLE,
  hasSpoilers: true,
  description: [
    {
      header: 'Марлос Урнрейл',
      text: `Марлос Урнрейл — пророк земли на службе у Стихийного Зла и лидер культа Чёрной Земли в холмах Самбер. Некогда бывший тщеславным и жестоким дворянином, он превратился в [медузу](CREATURE:${CREATURE_MEDUSA}) необычайной силы. Марлосу доставляет истинное наслаждение превращать своих врагов в камень (особенно тех, кто обладает красивой внешностью), а затем разбивать эти статуи.

Марлос вооружён волшебной боевой киркой под названием [Железный Клык](MAGIC_ITEM:${MAGIC_ITEM_IRONFANG}). Он нашел её в святилище Ока, куда его привели видения. Служители Чёрной Земли верят, что и проклятье, превратившее Марлоса в [медузу](CREATURE:${CREATURE_MEDUSA}), и обретение им стихийного оружия — это знаки благосклонности Огремоха, и поэтому все они фанатично преданы своему лидеру.`,
      source: {
        id: SOURCE_PotA,
        page: 204,
      },
    },
    {
      header: 'В узле земли',
      text: `Когда храму Чёрной Земли угрожает опасность, Марлос отступает в Чёрную Жеоду к узлу земли. Здесь Марлос получает дополнительное использование своей способности «легендарное сопротивление».`,
      source: {
        id: SOURCE_PotA,
        page: 204,
      },
    },
    {
      header: 'Действия логова',
      text: `Если Марлос находится в узле земли, без Огремоха, он может использовать действия логова. При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) он может использовать действие логова, чтобы сотворить заклинание [Землетрясение](SPELL:${SPELL_EARTHQUAKE}) без использования компонентов. Пока он поддерживает концентрацию на землетрясении, его инициатива в раунде остается равной 20. Он не может совершать иных действий логова, пока концентрируется на этом заклинании.

Вторая способность Марлоса в логове — получение энергии от узла земли для лечения. Он может действием логова восстановить 30 (6к8 + 3) хитов.`,
      source: {
        id: SOURCE_PotA,
        page: 204,
      },
    },
    blackEarthDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 204,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 18,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 17,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_TERRAN,
  ],
  cr: CR_8,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    {
      name: 'Посмертные объятия земли',
      nameEn: 'Earthen Defeat',
      description: `Когда хиты ★СУЩЕСТВО★ опускаются до 0, ★его★ тело превращается в грязь и растекается лужей. Всё, во что ★он★ был одет и что ★носил★ с собой, остается лежать в ней.`,
    },
    {
      name: 'Проход сквозь землю',
      nameEn: 'Earth Passage',
      description: `★СУЩЕСТВО★ может перемещаться по труднопроходимой местности из любых видов земли или камней, не затрачивая дополнительное перемещение. ★Он★ может передвигаться прямо сквозь землю и камень, как по труднопроходимой местности. Если ★он★ заканчивает свой ход в земле или камне, ★он★ перемещается в ближайшее свободное пространство, через которое ★он★ проходил до того, как погрузиться туда.`,
    },
    {
      id: ABILITY_PETRIFYING_GAZE,
      dc: 14,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает либо три атаки — одну _Змеиными волосами_ и две атаки _Железным клыком_.`,
    },
    {
      name: 'Змеиные волосы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 4,
            diceType: 6,
          },
        ],
      },
    },
    {
      magicItemId: MAGIC_ITEM_IRONFANG,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 3,
          },
          {
            type: DAMAGE_THUNDER,
            diceCount: 1,
            diceType: 8,
          },
        ],
      },
    },
  ],
}
