const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    description,
    genderId,
    stoneDescription,
    stoneTypeName: [
      stoneTypeNameRu,
      stoneTypeNameEn,
    ],
    ...rest
  }
) => {
  const stoneGenderText = genderId === GENDER_MALE ? 'этот' : 'эту'
  const stonePronoun = genderId === GENDER_MALE ? 'он' : 'она'
  const stoneText = `${stoneGenderText} **${stoneDescription}**`

  return {
    ...rest,
    name: `Камень Йоун: ${stoneTypeNameRu}`,
    nameEn: `Ioun stone: ${stoneTypeNameEn}`,
    type: MGC_TYPE_WONDROUS_ITEM,
    attunement: true,
    description: `_Камень Йоун_ назван в честь богини знаний и пророчеств, почитаемой в нескольких мирах.
  
  Если Вы действием подбрасываете ${stoneText} в воздух, ${stonePronoun} начинает вращаться вокруг Вашей головы на расстоянии 1к3 футов, предоставляя Вам постоянный бонус. Впоследствии другое существо может действием схватить руками или чем-то иным камень, забирая его себе, если совершит успешный бросок атаки по КД 24 или совершит успешную проверку Ловкости (Акробатика) СЛ 24. Вы сами можете действием схватить и убрать камень, оканчивая его эффект.
  
  У камня КД 24, 10 хитов и сопротивление ко всем видам урона. Пока он вращается вокруг Вашей головы, он считается носимым.
  
  # ${stoneTypeNameRu}
  
  ${description}`,
    genderId: GENDER_MALE,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 168,
    },
  }
}
