const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_MEDALLION_OF_THOUGHTS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_DETECT_THOUGHTS} = require('./../../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_MEDALLION_OF_THOUGHTS,
  name: `Медальон мыслей`,
  nameEn: `Medallion of Thoughts`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  attunement: true,
  description: `У этого медальона есть 3 заряда.

Нося его, Вы можете действием потратить 1 заряд, чтобы наложить им заклинание [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) (СЛ испытания 13).

Медальон ежедневно восстанавливает 1к3 заряда на рассвете. `,
  genderId: GENDER_MALE,
  spellIdList: [SPELL_DETECT_THOUGHTS],
  source: {
    id: SOURCE_DMG,
    page: 184,
  },
}
