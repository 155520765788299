const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_MERFOLK} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_MERFOLK,
  nameEn: 'Merfolk',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'мерфолк',
      genitive: 'мерфолка',
      dative: 'мерфолку',
      accusative: 'мерфолка',
      instrumental: 'мерфолком',
      prepositional: 'мерфолке',
    },
    plural: {
      nominative: 'мерфолки',
      genitive: 'мерфолков',
      dative: 'мерфолкам',
      accusative: 'мерфолков',
      instrumental: 'мерфолками',
      prepositional: 'мерфолках',
    },
  },
}
