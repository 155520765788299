const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FALSE_APPEARANCE,
  ABILITY_SPIDER_CLIMB,
} = require('./../../../../creatureAbilityList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_PIERCER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Пронзатель',
  nameEn: 'Piercer',
  id: CREATURE_PIERCER,
  description: [
    {
      text: `Цепляясь за потолки пещер и больших подземных ходов, пронзатель прекрасно сливается со скалами, обрушиваясь в тишине на ничего не подозревающих врагов и прижимая их к земле.

Пронзатель является личиночной формой верёвочника, и эти два существа часто атакуют в тандеме. Тело пронзателя покрыто похожей на камень оболочкой, придающей ему вид и текстуру сталактита. Эта оболочка защищает мягкую, студенистую верхнюю часть тела пронзателя, при помощи которой он передвигается по стенам и потолку пещеры, ища место для засады. Когда глаза и рот пронзателя закрыты, его трудно отличить от обычных скальных образований.`,
      source: {
        id: SOURCE_MM,
        page: 244,
      },
    },
    {
      header: 'Терпеливый охотник',
      text: `Пронзатели обладают зрением, но также реагируют на шум и тепло, ожидая живых существ, проходящих под местом засады. Они нападают, обрушиваясь на них. Промахнувшийся пронзатель вынужден проделать медленный путь обратно на потолок. Упавший пронзатель, при атаке на него, выделяет дурно пахнущую слизь, и многие хищники думают дважды, прежде чем съесть его.

Для увеличения эффективности атак пронзатели собираются в колонии и обрушиваются одновременно, чтобы более успешно поразить добычу. Как только одному из них удаётся убить существо, все остальные ползут в направлении трупа и присоединяются к пиру.`,
      source: {
        id: SOURCE_MM,
        page: 244,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 244,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_CLIMB]: 5,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного сталактита`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: `Падение`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        target: {
          count: 1,
          comment: ', находящееся точно под ★СУЩЕСТВО★',
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          comment: ' за каждые 10 футов падения, вплоть до 21 (6к6)',
        },
        miss: '★СУЩЕСТВО★ получает половину обычного урона за падение',
      },
    },
  ],
  genderId: GENDER_MALE,
}
