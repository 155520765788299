const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  GEAR_MACE,
  GEAR_WAND,
} = require('./../../../../../gearIdList')
const {
  SPELL_ANIMATE_DEAD,
  SPELL_BLIGHT,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_FINGER_OF_DEATH,
  SPELL_POWER_WORD_KILL,
  SPELL_SPEAK_WITH_DEAD,
} = require('./../../../../../spellIdList')
const {
  CREATURE_ORCUS,
  CREATURE_SKELETON,
  CREATURE_ZOMBIE,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WAND,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_ARTIFACT,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_WAND_OF_ORCUS,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_WAND_OF_ORCUS,
  name: `Палочка Оркуса`,
  nameEn: `Wand of Orcus`,
  type: MGC_TYPE_WAND,
  gearType: GEAR_WAND,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  description: [
    `Эту жуткую волшебную палочку редко можно встретить без её хозяина, [Оркуса](CREATURE:${CREATURE_ORCUS}). Это устройство, такое же злое как его создатель, разделяет стремление демонического повелителя к уничтожению всего живого и превращению Материального плана в мир нежити. Иногда [Оркус](CREATURE:${CREATURE_ORCUS}) позволяет _Палочке_ покидать его. Когда это происходит, _Палочка_ появляется в месте, в котором её настоящий хозяин чувствует возможность добиться выполнения своих планов.

Сделанная из костей, твёрдых как железо, Палочка увенчана увеличенным магией черепом, который когда-то принадлежал человеческому герою, убитому [Оркусом](CREATURE:${CREATURE_ORCUS}). Палочка при помощи магии меняет свой размер, чтобы подходить хватке своего текущего владельца. В присутствии _Палочки_ растения засыхают, вода портится, плоть гниёт, а паразиты наоборот, плодятся с ужасающей скоростью.

Все существа кроме [Оркуса](CREATURE:${CREATURE_ORCUS}), пытающиеся настроиться на _Палочку_, должны пройти испытание Телосложения СЛ 17. При успехе существо получает урон некротической энергией 10к6. При провале существо умирает и становится [зомби](CREATURE:${CREATURE_ZOMBIE}).

В руках того, кто настроился на неё, _Палочка_ может использоваться как магическая [булава](GEAR:${GEAR_MACE}), предоставляющая бонус +3 к броскам атаки и урона ей. При попадании _Палочка_ причиняет дополнительный урон некротической энергией 2к12.`,
    {
      header: 'Случайные свойства',
      text: `Палочка [Оркуса](CREATURE:${CREATURE_ORCUS}) обладает несколькими свойствами, определяемыми случайным образом: 

* 2 малых положительных свойства
* 1 основное положительное свойство
* 2 малых отрицательных свойства
* 1 основное отрицательное свойство 

Отрицательные свойства _Палочки Оркуса_ подавляются, пока она настроена на самого [Оркуса](CREATURE:${CREATURE_ORCUS}).`,
    },
    {
      header: 'Защита',
      text: `Вы получаете бонус +3 к КД, пока держите _Палочку_.`,
    },
    {
      header: 'Заклинания',
      text: `У Палочки есть 7 зарядов. Держа её, Вы можете действием потратить 1 или несколько зарядов, чтобы наложить ей одно из следующих заклинаний (СЛ испытания 18)

* [Восставший труп](SPELL:${SPELL_ANIMATE_DEAD}) (1 заряд),
* [Круг смерти](SPELL:${SPELL_CIRCLE_OF_DEATH}) (3 заряда),
* [Перст смерти](SPELL:${SPELL_FINGER_OF_DEATH}) (3 заряда),
* [Разговор с мёртвыми](SPELL:${SPELL_SPEAK_WITH_DEAD}) (1 заряд),
* [Слово силы: смерть](SPELL:${SPELL_POWER_WORD_KILL}) (4 заряда),
* [Усыхание](SPELL:${SPELL_BLIGHT}) (2 заряда).

Палочка ежедневно восстанавливает 1к4 + 3 заряда на рассвете.

Будучи настроенным на _Палочку_, [Оркус](CREATURE:${CREATURE_ORCUS}) или благословлённый им последователь может сотворять заклинания _Палочки_, тратя на 2 заряда меньше (минимум 0).`,
    },
    {
      header: 'Призыв нежити',
      text: `Если Вы держите _Палочку_, Вы можете действием призвать [скелетов](CREATURE:${CREATURE_SKELETON}) и [зомби](CREATURE:${CREATURE_ZOMBIE}), в любых пропорциях, но разделяя между ними 500 хитов. У каждой нежити среднее для неё число хитов. Нежить с помощью магии восстаёт из земли или как-то иначе формируется в пределах 300 футов от Вас и подчиняется Вашим командам, пока не будет уничтожена, но на рассвете следующего дня они рассыпаются обычными грудами костей и гниющими трупами. Использовав это свойство _Палочки_, Вы не можете использовать его повторно до следующего рассвета.

Настроенный на эту _Палочку_ [Оркус](CREATURE:${CREATURE_ORCUS}) [может призывать любую нежить](/campaigns/oota), не ограничиваясь [скелетами](CREATURE:${CREATURE_SKELETON}) и [зомби](CREATURE:${CREATURE_ZOMBIE}). Эта нежить не погибает и не исчезает на рассвете следующего дня, она остаётся, пока [Оркус](CREATURE:${CREATURE_ORCUS}) не отпустит её.`,
    },
    {
      header: 'Разум',
      text: `_Палочка Оркуса_ — разумный хаотично-злой предмет с Интеллектом 16, Мудростью 12 и Харизмой 16. Она обладает слухом и тёмным зрением в пределах 120 футов.

Это оружие может понимать языки Бездны и Всеобщий, а также говорить и читать на них и телепатически общаться с владельцем.`,
    },
    {
      header: 'Индивидуальность',
      text: `Предназначение _Палочки_ в утолении жажды [Оркуса](CREATURE:${CREATURE_ORCUS}) убивать всех подряд в мультивселенной. Она хладнокровна, жестока, лишена чувства юмора, и ей не чужд нигилизм.

Стремясь помочь своему настоящему хозяину, Палочка притворяется верной своему текущему владельцу и торжественно клянётся в этой верности, обещая, например, помогать в свержении [Оркуса](CREATURE:${CREATURE_ORCUS}).`,
    },
    {
      header: 'Уничтожение Палочки',
      text: `Для уничтожения _Палочку Оркуса_ должен отнести на Положительный План древний герой, чей череп и венчает её. Чтобы это произошло, этого героя нужно вначале воскресить — а это нелёгкая задача, учитывая тот факт, что [Оркус](CREATURE:${CREATURE_ORCUS}) заточил его душу в тайном, хорошо охраняемом месте.`,
    },
  ],
  genderId: GENDER_FEMALE,
  spellIdList: [
    SPELL_ANIMATE_DEAD,
    SPELL_BLIGHT,
    SPELL_CIRCLE_OF_DEATH,
    SPELL_FINGER_OF_DEATH,
    SPELL_POWER_WORD_KILL,
    SPELL_SPEAK_WITH_DEAD,
  ],
  source: {
    id: SOURCE_DMG,
    page: 227,
  },
}
