const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_SCATTER} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SCATTER,
  name: 'Раскидывание',
  nameEn: 'Scatter',
  description: `Воздух начинает дрожать вокруг пяти существ по Вашему выбору, которых Вы можете видеть в пределах дистанции. Несогласное существо должно пройти испытание Мудрости, чтобы противостоять этому заклинанию. Вы телепортируете каждую затронутую цель в незанятое пространство, которое Вы можете видеть в пределах 120 футов от вас. Это пространство должно быть на земле или на полу.`,
  lvl: 6,
  magicSchoolId: MAGIC_CONJURATION,
  range: 30,
  componentIdList: [CAST_VERBAL],
  effect: {
    savethrowParam: PARAM_WIT,
  },
  source: {
    id: SOURCE_XGTE,
    page: 165,
  },
}
