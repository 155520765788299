const {
  SPELL_COMMAND,
  SPELL_DARKNESS,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_GATE,
  SPELL_HARM,
  SPELL_TELEKINESIS,
  SPELL_TELEPORT,
  SPELL_WIND_WALK,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  LANG_ABYSSAL,
  LANG_GIANT,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_FROST_GIANT,
  CREATURE_HILL_GIANT,
  CREATURE_KOSTCHTCHIE,
} = require('./../../../../creatureIdList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_DEMON_LORD,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_25} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {MAGIC_ITEM_MATALOTOK} = require('./../../../../magicItemIdList')
const {GOD_THRYM} = require('./../../../../godIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Кощище',
  nameAlt: ['Кащей', 'Костчти', 'Коштиктикай'],
  nameEn: 'Kostchtchie',
  id: CREATURE_KOSTCHTCHIE,
  hasSpoilers: true,
  description: [
    `Демонический владыка Кощище похож на приземистого [холмового великана](CREATURE${CREATURE_HILL_GIANT}) с короткими толстыми ногами и уродливой головой.

[Ледяные великаны](CREATURE${CREATURE_FROST_GIANT}), предавшие своих богов и обратившиеся к служению демонам, могут призвать Кощище, принося ему кровавые жертвы на алтарях, вытесанных изо льда. Если демонический владыка решит ответить на зов великанов, он охотится и сражается в их рядах какое-то время, наделяя [ледяных великанов](CREATURE${CREATURE_FROST_GIANT}) диким безумием и жаждой крови. Затем он возвращается в Бездну. Любимое оружие Кощища — Маталоток, боевой молот, выкованный богом ледяных великанов [Тримом](GOD${GOD_THRYM}).`,
    ...demonDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_DEMON_LORD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_BGDIA,
    page: 104,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 30,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 27,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 22,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_SURVIVAL]: PROF_DOUBLE,
    [SKILL_INTIMIDATION]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_CHARMED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_GIANT,
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_25,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_COMMAND,
          SPELL_DARKNESS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DISPEL_EVIL_AND_GOOD,
          SPELL_GATE,
          SPELL_HARM,
          SPELL_TELEKINESIS,
          SPELL_TELEPORT,
          SPELL_WIND_WALK,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки, только одна из которых может быть _Укусом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 18,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 10,
        },
      },
    },
    {
      magicItemId: MAGIC_ITEM_MATALOTOK,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 18,
        range: 10,
        hit: [
          [
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 10,
            },
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 2,
              diceType: 10,
              diceBonus: 10,
              use2Hand: true,
            },
          ],
          {
            type: DAMAGE_COLD,
            diceCount: 3,
            diceType: 6,
            comment: ` всем существам в пределах 30 футов`,
          },
        ],
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
     {
       name: 'Атака',
       description: `★СУЩЕСТВО★ совершает одну рукопашную атаку оружием.`,
     },
     {
       name: 'Перемещение',
       description: `★СУЩЕСТВО★ перемещается на расстояние не больше своей скорости.`,
     },
     {
       name: 'Проклятие',
       cost: 2,
       description: `★СУЩЕСТВО★ проклинает одно существо, видимое ★им★ в пределах 60 футов. Проклятое существо получает уязвимость ко всему урону, наносимому ★СУЩЕСТВО★, до конца следующего хода ★СУЩЕСТВО★.`,
     },
  ],
}
