const {
  WEAPON_HEAVY,
  WEAPON_REACH,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const polearmDescription = require('./../../constants/polearmDescription')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_GLAIVE} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_GLAIVE,
  genderId: GENDER_FEMALE,
  name: 'Глефа',
  nameByCase: {
    nominative: 'глефа',
    genitive: 'глефы',
    accusative: 'глефу',
    instrumental: 'глефой',
  },
  nameEn: 'Glaive',
  description: `${polearmDescription}
    
Изогнутое навершие глефы позволяет наносить дополнительный урон. Вы можете даже использовать его, чтобы заманить противников в ловушку и притянуть их ближе! Острый край клинка можно использовать, чтобы колоть или рубить врагов, получая ещё больше возможностей для атаки.`,
  damage: {
    diceType: 10,
    diceCount: 1,
  },
  cost: 2000,
  damageType: DAMAGE_SLASHING,
  weight: 6,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 109,
    },
  ],
  weaponPropList: [
    WEAPON_TWO_HANDED,
    WEAPON_REACH,
    WEAPON_HEAVY,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
