const {DAMAGE_BLUDGEONING} = require('./../../../damageTypeList')
const {GEAR_DYNAMITE_STICK} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {PARAM_DEX} = require('./../../../paramList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')
const {
  CAT_EJECTIVES,
  CAT_EXPLOSIVES,
  CAT_TECH_MODERN,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_DYNAMITE_STICK,
  name: 'Динамит, шашка',
  nameEn: 'Dynamite, stick',
  nameByCase: {
    nominative: 'шашка динамита',
    genitive: 'шашки динамита',
    accusative: 'шашку динамита',
    instrumental: 'шашкой динамита',
  },
  description: {
    header: 'Динамит',
    text: `Существо может действием поджечь динамитную шашку и бросить её на расстояние до 60 футов. Все существа в пределах 5 футов от этой точки должны пройти испытание Ловкости СЛ 12, получая дробящий урон 3к6 при провале или половину этого урона при успехе.

Персонаж может связать вместе несколько динамитных шашек, чтоб они взорвались одновременно. Каждая добавленная шашка увеличивает урон на 1к6 (максимум — 10к6), а радиус взрыва — на 5 футов (максимум — 20 футов).

Динамит может быть переделан на удлинённый фитиль, чтоб взорваться через определённое время, обычно от 1 до 6 раундов. Совершите проверку инициативы за динамит. После того, как пройдёт указанное количество раундов, динамит взорвётся при своей инициативе.`,
    source: {
      id: SOURCE_DMG,
      page: 267,
    },
  },
  weight: 1,
  damageType: DAMAGE_BLUDGEONING,
  damage: {
    diceCount: 3,
    diceType: 6,
  },
  range: 5,
  saveThrow: {
    type: PARAM_DEX,
    dc: 12,
  },
  genderId: GENDER_FEMALE,
  category: [
    CAT_EJECTIVES,
    CAT_EXPLOSIVES,
    CAT_TECH_MODERN,
  ],
  weaponPropList: [
    WEAPON_THROWN,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 60,
        max: 60,
      },
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
