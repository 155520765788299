const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_CHARGE,
  ABILITY_RECKLESS,
  ABILITY_LABYRINTHINE_RECALL,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_BAPHOMET,
  CREATURE_MINOTAUR,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GEAR_GREATAXE} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ABYSSAL} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Минотавр',
  nameEn: 'Minotaur',
  id: CREATURE_MINOTAUR,
  description: [
    {
      header: 'Минотавры',
      text: `Рёв минотавра — дикий боевой клич, которого страшится большинство цивилизованных существ. Созданные в смертных мирах демоническими ритуалами, минотавры — дикие завоеватели и хищники, живущие, чтобы охотиться. Их бурый или чёрный мех запятнан кровью павших врагов, и за ними следует мерзкий запах смерти.`,
      source: {
        id: SOURCE_MM,
        page: 211,
      },
    },
    {
      header: 'Внутренний зверь',
      text: `Большинство минотавров — одинокие хищники, бродящие по лабиринтам подземелий, извилистым пещерам, первобытным лесам и запутанным улицам и переходам заброшенных руин. Минотавр прекрасно представляет все пути, которыми можно добраться до добычи.

Запах крови, разрываемая плоть и треск костей пробуждают в нем жажду разрушения, затмевая разум и мысли. В кровавом угаре минотавр с разбегу атакует всех, кого видит, сначала бодая рогами, а потом разрубая сбитого с ног врага надвое.

Помимо создания засад на забредших в их лабиринты существ, минотавры мало заботятся о тактике или стратегии. Минотавры редко действуют организованно, у них нет уважения к иерархии или власти, и их очень сложно поработить, не говоря уже о том, чтобы контролировать.`,
      source: {
        id: SOURCE_MM,
        page: 211,
      },
    },
    {
      header: 'Культисты Рогатого Короля',
      text: `Минотавры — тёмные потомки гуманоидов, трансформированных ритуалами культов, которые отвергают гнёт подчинения властям через возвращение в природу. Новобранцы часто принимают эти культы за друидические круги или тотемные религии, но во время их церемоний гуманоидное существо входит в лабиринт, надев звериную маску.

В этом ограниченном пространстве культисты охотятся на диких зверей, убивают их и съедают, утоляя свои первобытные инстинкты. Но в итоге жертвенные животные заменяются на гуманоидную жертву — иногда это новобранец, который пытался сбежать из культа, узнав его истинную сущность. Эти лабиринты превращаются в залитые кровью бойни, по коридорам которых эхом разносится шум от дикого поведения культистов.

Только наивысшим лидерам этих культов известно, что они были созданы демоническим повелителем [Бафометом](CREATURE:${CREATURE_BAPHOMET}), Рогатым Королём, чей слой Бездны представляет собой гигантский лабиринт. Некоторые из его последователей — пылкие просители, молящие о силе и величии. Другие приходят в культ, ища жизни в свободе от оков властей — а вместо этого их освобождают от их человечности, превращая их в минотавров, вторящих его собственному зверскому обличью.

Несмотря на то, что минотавры были созданы Рогатым Королём, они могут размножаться и нормальным образом, давая миру самостоятельную расу детей [Бафомета](CREATURE:${CREATURE_BAPHOMET}).`,
      source: {
        id: SOURCE_MM,
        page: 211,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 211,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 16,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
  ],
  cr: CR_3,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 10,
      attackName: 'Боданием',
      damageText: '9 (2к8) колющего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 14, иначе будет оттолкнута на расстояние до 10 футов и сбита с ног.',
    },
    ABILITY_LABYRINTHINE_RECALL,
    ABILITY_RECKLESS,
  ],
  actionList: [
    {
      name: 'Секира',
      // gearId: GEAR_GREATAXE, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 12,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
