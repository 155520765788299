const {fullCasterSpellCircleList} = require('./../../spellCircleByCasterType')
const {PC_CLASS_SORCERER} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
  SOURCE_ERRATA_PHB,
} = require('./../../sourceList')
const {spellIdCollectionByPcClassId} = require('./../../spellIdPcClassIdBounds')
const SPELL_SLOT_TABLE_ID = require('./../../SPELL_SLOT_TABLE_ID')

const {abilityScoreImprovementTemplate} = require('./../featureCommonRawList')

const {
  SPELL_BURNING_HANDS,
  SPELL_CHROMATIC_ORB,
  SPELL_MAGIC_MISSILE,
  SPELL_RAY_OF_FROST,
  SPELL_SCORCHING_RAY,
} = require('./../../spellIdList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_SORCERER,
  FEATURE_CANTRIPS_SORCERER,
  FEATURE_FLEXIBLE_CASTING,
  FEATURE_FONT_OF_MAGIC,
  FEATURE_MAGICAL_GUIDANCE,
  FEATURE_METAMAGIC,
  FEATURE_SORCEROUS_ORIGIN,
  FEATURE_SORCEROUS_RESTORATION,
  FEATURE_SORCEROUS_VERSATILITY,
  FEATURE_SORCERY_POINTS,
  FEATURE_SPELL_KNOWN_SORCERER,
  FEATURE_SPELL_SLOTS_SORCERER,
  FEATURE_SPELLCASTING_ABILITY_SORCERER,
  FEATURE_SPELLCASTING_FOCUS_SORCERER,
  FEATURE_SPELLCASTING_SORCERER,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_SPELLCASTING_SORCERER,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 1,
    text: `Определённое событие в Вашем прошлом или в жизни Ваших родителей или предков оставило на Вас неизгладимый отпечаток, связав Вас с магией. Ваши заклинания питает некий источник магии, какого бы происхождения он не был.`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_SORCERER],
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
  {
    id: FEATURE_CANTRIPS_SORCERER,
    name: `Заговоры`,
    nameEn: `Cantrips`,
    lvl: 1,
    text: `На 1 уровне Вам известны четыре заговора, которые Вы выбираете из списка заклинаний чародея.

Вы обучаетесь дополнительным заговорам чародея на свой выбор по мере роста в уровнях, как это показано в таблице «Известные заговоры».

## Известные заговоры

| Уровень чародея | Известно заговоров |
|-|-|
|  1–3  | 4 |
|  4–9  | 5 |
| 10–20 | 6 |
`,
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
  {
    id: FEATURE_SPELL_SLOTS_SORCERER,
    name: `Ячейки заклинаний`,
    nameEn: `Spell Slots`,
    lvl: 1,
    text: `Таблица «[Ячейки заклинаний Чародея](PC_CLASS:${PC_CLASS_SORCERER}#${SPELL_SLOT_TABLE_ID})» показывает, сколько ячеек заклинаний у Вас есть для заклинаний 1 и других кругов. Для использования заклинания Вы должны потратить ячейку соответствующего, либо превышающего круга. Вы восстанавливаете все потраченные ячейки по окончании длинного отдыха.

Например, если Вы знаете заклинание 1 круга [Огненные ладони](SPELL:${SPELL_BURNING_HANDS}), и у Вас есть ячейки 1 и 2 кругов, то Вы можете использовать его с помощью любой из этих ячеек.`,
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
  {
    id: FEATURE_SPELL_KNOWN_SORCERER,
    name: `Известные заклинания`,
    nameEn: `Spells Known`,
    lvl: 1,
    text: `Вы знаете два заклинания 1 круга на свой выбор из списка заклинаний чародея.

Таблица показывает, когда Вы сможете выучить новые заклинания. Круг заклинаний не должен превышать круг самой высокой имеющейся у Вас ячейки заклинаний. Например, когда Вы достигнете 3 уровня в этом классе, Вы можете выучить одно новое заклинание 1 или 2 круга.

Кроме того, когда Вы получаете новый уровень в этом классе, Вы можете одно из известных Вам заклинаний чародея заменить на другое из списка чародея, круг которого тоже должен соответствовать имеющимся ячейкам заклинаний.

## Количество известных заклинаний

| Уровень чародея | Заклинаний | Уровень чародея | Заклинаний |
|-|-|-|-|
|  1 | 2 |  8    |  9 |
|  2 | 3 |  9    | 10 |
|  3 | 4 | 10    | 11 |
|  4 | 5 | 11–12 | 12 |
|  5 | 6 | 13–14 | 13 |
|  6 | 7 | 15–16 | 14 |
|  7 | 8 | 17–20 | 15 |
`,
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_SORCERER,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 1,
    text: `Вы используете Харизму для сотворения заклинаний чародея. Мощь заклинаний зависит от Вашей способности проецировать свою волю в реальность.

Вы используете Харизму в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете Харизму при определении СЛ испытаний от Ваших заклинаний, и при броске атаки заклинаниями.

**СЛ испытания = 8 + бонус мастерства + модификатор Харизмы**

**Модификатор броска атаки = бонус мастерства + модификатор Харизмы**`,
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
  {
    id: FEATURE_SPELLCASTING_FOCUS_SORCERER,
    name: `Фокусировка заклинания`,
    nameEn: `Spellcasting Focus`,
    lvl: 1,
    text: `Вы можете использовать магическую фокусировку в качестве фокусировки для заклинаний чародея.`,
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
  {
    id: FEATURE_SORCEROUS_ORIGIN,
    name: `Происхождение чародея`,
    nameEn: `Sorcerous Origin`,
    lvl: [1, 6, 14, 18],
    text: `Выберите источник, из которого Ваш персонаж черпает свою силу.

Ваш выбор предоставляет Вам умения на 1, 6, 14 и 18 уровнях.`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
  {
    id: FEATURE_FONT_OF_MAGIC,
    name: `Источник магии`,
    nameEn: `Font of Magic`,
    lvl: 2,
    text: `Вы получаете доступ к внутреннему источнику магии.

Этот источник выражен в [Очках чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), позволяющих Вам создавать разнообразные магические эффекты.`,
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
  {
    id: FEATURE_SORCERY_POINTS ,
    name: `Очки чародейства`,
    nameAlt: `Единицы чародейства`,
    nameEn: `Sorcery Points `,
    lvl: 2,
    text: `Вы получаете 2 _Очка чародейства_, и их становится больше на более высоких уровнях.

У Вас не может быть этих _Очков_ больше, чем указанно в таблице для Вашего уровня. Вы восстанавливаете все использованные _Очки чародейства_, завершая длинный отдых.

| Уровень | Очки чародейства | Уровень | Очки чародейства |
|---------|------------------|---------|------------------|
|  1      |  —               | 11      | 11               |
|  2      |  2               | 12      | 12               |
|  3      |  3               | 13      | 13               |
|  4      |  4               | 14      | 14               |
|  5      |  5               | 15      | 15               |
|  6      |  6               | 16      | 16               |
|  7      |  7               | 17      | 17               |
|  8      |  8               | 18      | 18               |
|  9      |  9               | 19      | 19               |
| 10      | 10               | 20      | 20               |
`,
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
  {
    id: FEATURE_FLEXIBLE_CASTING,
    name: `Гибкое заклинательство`,
    nameEn: `Flexible Casting`,
    lvl: 2,
    text: `Вы можете использовать [Очки чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы получить дополнительные ячейки заклинаний, и наоборот, пожертвовать ячейками, чтоб получить [Очки](FEATURE:${FEATURE_SORCERY_POINTS}). Другие способы использования [Очков чародейства](FEATURE:${FEATURE_SORCERY_POINTS}) Вы освоите на более высоких уровнях.

# Создание ячеек заклинаний

В свой ход Вы можете бонусным действием превратить оставшиеся [Очки чародейства](FEATURE:${FEATURE_SORCERY_POINTS}) в дополнительные ячейки заклинаний. Приведённая таблица отображает стоимость создания ячеек разных кругов. Вы не можете создавать ячейки с кругом выше 5. Созданные ячейки заклинаний исчезают в конце длинного отдыха.

| Круг ячейки заклинаний | Очки чародейства |
|------------------------|------------------|
| 1                      | 2                |
| 2                      | 3                |
| 3                      | 5                |
| 4                      | 6                |
| 5                      | 7                |

# Преобразование ячейки заклинания в [Очки чародейства](FEATURE:${FEATURE_SORCERY_POINTS})

Вы можете в свой ход бонусным действием преобразовать одну ячейку заклинаний в [Очки чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), количество которых равно кругу ячейки. `,
    source: [
      {
        id: SOURCE_PHB,
        page: 102,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Явно оговаривается исчезновение ячеек в конце длинного отдыха',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_METAMAGIC,
    name: `Метамагия`,
    nameEn: `Metamagic`,
    lvl: [3, 10, 17],
    text: `Вы получаете способность подстраивать заклинания под свои нужды. Вы выбираете два варианта метамагии из перечисленных ниже. На 10 и 17 уровне Вы получаете ещё по одному варианту.

При сотворении заклинания может быть использован только один метамагический вариант, если в его описании не указано обратное.

# Аккуратное заклинание

Когда Вы накладываете заклинание, которое вынуждает других существ пройти испытание, Вы можете защитить некоторых из них от магического воздействия. Для этого Вы тратите 1 [Очко чародейства](FEATURE:${FEATURE_SORCERY_POINTS}) и выбираете существ в количестве, равном Вашему модификатору Харизмы (минимум одно существо). Указанные существа автоматически преуспевают в испытании от данного заклинания.

# Далёкое заклинание

При накладывании заклинания, дистанция которого 5 футов и более, Вы можете потратить 1 [Очко чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы удвоить это расстояние. При накладывании заклинания с дистанцией «прикосновение», Вы можете потратить 1 [Очко чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы увеличить это расстояние до 30 футов.

# Ищущее заклинание

Если Вы совершаете бросок атаки заклинанием и промахиваетесь, то можете потратить 2 [Очка чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы перебросить к20 и Вы обязаны использовать новый результат.

Вы можете использовать _Ищущее заклинание_ даже вместе с другим метамагический вариантом.

# Непреодолимое заклинание

Когда Вы накладываете заклинание, которое вынуждает существо пройти испытание для защиты от его эффектов, Вы можете потратить 3 [Очка чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы одна из целей заклинания совершила первое испытание от этого заклинания с помехой.

# Неуловимое заклинание

Во время использования заклинания Вы можете потратить 1 [Очко чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтоб сотворить его без вербальных и соматических компонентов.

# Преобразованное заклинание

Если Вы сотворяете заклинание, наносящее урон одного из перечисленных типов, то Вы можете потратить 1 [Очко чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы изменить его тип урона на другой из этого же списка.

* Звук
* Кислота
* Огонь
* Холод
* Электричество
* Яд

# Продлённое заклинание

При накладывании заклинания с длительностью 1 минута или более, Вы можете потратить 1 [Очко чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы удвоить это время, вплоть до максимального в 24 часа.

# Раздвоенное заклинание

Если Вы используете заклинание, нацеливаемое на текущем накладываемом уровне только на одно существо и не имеющее дальность «на себя», Вы можете потратить количество [Очков чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), равное уровню заклинания (1 для заговоров), чтобы нацелиться им на второе существо-цель в пределах дистанции этого заклинания.

Раздваивать можно только заклинания, которые не могут выбирать целями более одного существа при сотворении на выбранным круге заклинания. Например, нельзя раздваивать [Волшебную стрелу](SPELL:${SPELL_MAGIC_MISSILE}) или [Палящий луч](SPELL:${SPELL_SCORCHING_RAY}), но можно раздваивать [Луч холода](SPELL:${SPELL_RAY_OF_FROST}) или [Цветной шарик](SPELL:${SPELL_CHROMATIC_ORB}).

# Усиленное заклинание

При совершении броска урона от заклинания Вы можете потратить 1 [Очко чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы перебросить несколько костей урона (количество равно Вашему модификатору Харизмы, минимум 1). Вы должны использовать новое выпавшее значение.

Вы можете воспользоваться вариантом _«Усиленное заклинание»_ даже если Вы уже использовали другой вариант метамагии для этого заклинания.

# Ускоренное заклинание

Если Вы используете заклинание со временем накладывания «1 действие», Вы можете потратить 2 [Очка чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы сотворить это заклинание бонусным действием.`,
    listMetaMagic: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 102,
      },
      {
        id: SOURCE_TCoE,
        page: 98,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Явно оговорены правила раздваивания заклинаний',
        page: 1,
      },
    ],
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_SORCERER,
    source: {
      id: SOURCE_PHB,
      page: 103,
    },
  },
  {
    id: FEATURE_SORCEROUS_VERSATILITY,
    name: `Чародейская гибкость`,
    nameEn: `Sorcerous Versatility`,
    lvl: abilityScoreImprovementTemplate.lvl,
    text: `Всякий раз, достигая в этом классе нового уровня, на котором Вы получаете способность [Увеличения характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_SORCERER}), Вы можете выполнить одно из следующего, демонстрируя то, что магия внутри Вас начинает течь по-новому:

* Замените одну известную Вам [метамагию](FEATURE:${FEATURE_METAMAGIC}) на другую.
* Замените один заговор, который Вы выучили способностью [Заговоры](FEATURE:${FEATURE_SPELLCASTING_SORCERER}), другим заговором из списка заклинаний чародея.`,
    source: {
      id: SOURCE_TCoE,
      page: 98,
    },
  },
  {
    id: FEATURE_MAGICAL_GUIDANCE,
    name: `Магическое наставление`,
    nameEn: `Magical Guidance`,
    lvl: 5,
    text: `Вы можете подключиться к своему внутреннему источнику магии и попытаться обратить неудачу в успех.

Когда Вы проваливаете проверку характеристики, то можете потратить 1 [Очко чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы перебросить к20, и должны использовать новый результат, потенциально превращая неудачу в успех.`,
    source: {
      id: SOURCE_TCoE,
      page: 98,
    },
  },
  {
    id: FEATURE_SORCEROUS_RESTORATION,
    name: `Чародейское восстановление`,
    nameEn: `Sorcerous Restoration`,
    lvl: 20,
    text: `Вы восстанавливаете 4 [Очка чародейства](FEATURE:${FEATURE_SORCERY_POINTS}) по окончании короткого отдыха.`,
    source: {
      id: SOURCE_PHB,
      page: 102,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_SORCERER,
  })
)
