const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_22,
} = require('./../../../../crList')
const {
  SIZE_GARGANTUA,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DRAGON_CHROMATIC,
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DRAGON_TRUE,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  greenDragonDescriptionList,
  dragonsGreenNoteList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_DETECT,
  ABILITY_FRIGHTFUL_PRESENCE,
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_TAIL_ATTACK,
  ABILITY_WING_ATTACK,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_DRAGON_GREEN_ANCIENT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Древний зелёный дракон',
  nameEn: 'Ancient Green Dragon',
  id: CREATURE_DRAGON_GREEN_ANCIENT,
  description: greenDragonDescriptionList,
  note: dragonsGreenNoteList,
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAGON_TRUE,
    CREATURE_TYPE_DRAGON_CHROMATIC,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 107,
  },
  armor: {
    ac: 21,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 22,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 80,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 27,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 25,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  cr: CR_22,
  languageList: [
    LANG_DRACONIC,
    LANG_COMMON,
  ],
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_LEGENDARY_RESISTANCE_3,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ может использовать _Ужасающее присутствие_. Затем он совершает три атаки: одну _Укусом_, и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 15,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 10,
            diceBonus: 8,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 20,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 8,
        },
      },
    },
    {
      id: ABILITY_FRIGHTFUL_PRESENCE,
      range: 120,
      witSave_DC: 19,
    },
    {
      name: 'Ядовитое дыхание',
      description: `★СУЩЕСТВО★ выдыхает ядовитый газ 90-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 22, получая урон ядом 77 (22к8) при провале, или половину этого урона при успехе.`,
      restore: 5,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    ABILITY_DETECT,
    ABILITY_TAIL_ATTACK,
    {
      id: ABILITY_WING_ATTACK,
      cost: 2,
      range: 15,
      dexSave_DC: 23,
      damage: '15 (2к6 + 8)',
    },
  ],
  genderId: GENDER_MALE,
}
