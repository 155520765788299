const {PC_SUBCLASS_FIGHTER_CHAMPION} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  FEATURE_ADDITIONAL_FIGHTING_STYLE,
  FEATURE_IMPROVED_CRITICAL,
  FEATURE_REMARKABLE_ATHLETE,
  FEATURE_SUPERIOR_CRITICAL,
  FEATURE_SURVIVOR,
} = require('./../../../featureIdList')
const {
  FIGHTING_STYLE_ARCHERY,
  FIGHTING_STYLE_BLIND_FIGHTING,
  FIGHTING_STYLE_DEFENSE,
  FIGHTING_STYLE_DUELING,
  FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
  FIGHTING_STYLE_INTERCEPTION,
  FIGHTING_STYLE_PROTECTION,
  FIGHTING_STYLE_SUPERIOR_TECHNIQUE,
  FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
  FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
  FIGHTING_STYLE_UNARMED_FIGHTING,
} = require('./../../../fightingStyleIdList')

module.exports = [
  {
    id: FEATURE_IMPROVED_CRITICAL,
    name: `Улучшенные критические попадания`,
    nameEn: `Improved Critical`,
    lvl: 3,
    text: `Ваши атаки оружием совершают критическое попадание при выпадении «19» или «20» на кости атаки.`,
  },
  {
    id: FEATURE_REMARKABLE_ATHLETE,
    name: `Выдающийся атлет`,
    nameEn: `Remarkable Athlete`,
    lvl: 7,
    text: `Вы можете добавлять половину бонуса мастерства, округлённую в большую сторону, ко всем проверкам Силы, Ловкости или Телосложения, куда этот бонус ещё не включён.

Кроме того, если Вы совершаете прыжок в длину с разбега, дальность прыжка увеличивается на количество футов, равное модификатору Силы.`,
  },
  {
    id: FEATURE_ADDITIONAL_FIGHTING_STYLE,
    name: `Дополнительный боевой стиль`,
    nameEn: `Additional Fighting Style`,
    lvl: 10,
    text: `Вы можете выбрать второй боевой стиль.`,
    fightingStyleIdList: [
      FIGHTING_STYLE_ARCHERY,
      FIGHTING_STYLE_BLIND_FIGHTING,
      FIGHTING_STYLE_DEFENSE,
      FIGHTING_STYLE_DUELING,
      FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
      FIGHTING_STYLE_INTERCEPTION,
      FIGHTING_STYLE_PROTECTION,
      FIGHTING_STYLE_SUPERIOR_TECHNIQUE,
      FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
      FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
      FIGHTING_STYLE_UNARMED_FIGHTING,
    ],
  },
  {
    id: FEATURE_SUPERIOR_CRITICAL,
    name: `Превосходные критические попадания`,
    nameEn: `Superior Critical`,
    lvl: 15,
    text: `Ваши атаки оружием совершают критическое попадание при выпадении «18–20» на кости атаки.`,
  },
  {
    id: FEATURE_SURVIVOR,
    name: `Уцелевший`,
    nameEn: `Survivor`,
    lvl: 18,
    text: `Вы достигаете вершин стойкости в бою.

В начале каждого своего хода Вы восстанавливаете количество хитов, равное 5 + модификатор Телосложения, если количество Ваших хитов не превышает половины от максимума.

Эта способность не работает, если у вас 0 хитов.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_CHAMPION,
    source: {
      id: SOURCE_PHB,
      page: 76,
    },
  })
)
