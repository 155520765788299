const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_ALHOON,
  CREATURE_ELDER_BRAIN,
  CREATURE_LICH,
  CREATURE_MIND_FLAYER,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Алхун',
    text: `[Свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), которые исследуют тайную магию, изгоняют как отщепенцев, и им отказано в вечном соединении со [старшим мозгом](CREATURE:${CREATURE_ELDER_BRAIN}). Путь становления [личом](CREATURE:${CREATURE_LICH}) предлагает способ избежать неминуемой смерти, но путь этот долог и одинок. [Алхуны](CREATURE:${CREATURE_ALHOON}) — это свежеватели разума, которые нашли короткую дорожку.`,
    source: {
      id: SOURCE_VGTM,
      page: 187,
    },
  },
  {
    header: 'Искушение тайной',
    text: `[Старший мозг](CREATURE:${CREATURE_ELDER_BRAIN}) запрещает [свежевателям разума](CREATURE:${CREATURE_MIND_FLAYER}) исследовать мощь магии за исключением псионики, но это не табу, которое приходится часто насаждать силой. Иллитиды не подчиняются никому кроме членов своего вида, так что поклоняться богам или покровителям из другого мира не в их природе. Однако, волшебство остается редким искушением. На страницах книги заклинаний иллитид видит систему получения власти.

В записях волшебника, написавшего эту книгу, иллитид распознаёт работу автора с высоким интеллектом. Большинство [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), которые находят книгу заклинаний, реагируют омерзением или безразличием, но для некоторых из них книга заклинаний является путём к новому способу мышления.

Некоторое время изучение таких запретных текстов может проводиться в тайне от других [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}) и даже от [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}). Понимание волшебства ускользает от разума как живое существо. Но в конце концов понимание приходит, и тайный заклинатель свежеватель разума должен осознать себя отщепенцем и сбежать из колонии, чтобы выжить.`,
    source: {
      id: SOURCE_VGTM,
      page: 187,
    },
  },
  {
    header: 'Экзистенциальный страх',
    text: `Изгои заклинатели, которые почувствовали вкус свободы от колонии, реагируют на это по-разному. Некоторые ценят приватность, а другие хотят общаться с похожими разумами, а иные ищут способ завладеть властью над колонией и вознестись на лидерскую позицию, которую обычно занимает [старший мозг](CREATURE:${CREATURE_ELDER_BRAIN}). Вне зависимости от личных наклонностей заклинателя он сталкивается с одним и тем же непреклонным фактом: когда он погибнет, то не присоединиться к множеству разумов в [старшем мозге](CREATURE:${CREATURE_ELDER_BRAIN}). Разумы-изгои никогда не признаются как часть коллектива. Для них смерть означает забвение.`,
    source: {
      id: SOURCE_VGTM,
      page: 187,
    },
  },
  {
    header: 'Ужасное избавление',
    text: `Становление [личом](CREATURE:${CREATURE_LICH}) предлагает спасение и перспективу бесконечного преследования знаний. Свежеватель разума ест мозги людей при жизни, поэтому не испытывает никаких угрызений совести, скармливая души филактерию. Единственное препятствие для свежевателя разума на пути становления [личом](CREATURE:${CREATURE_LICH}) — это способ такого становления, который является секретом, ради овладения которым некоторые свежеватели разума заклинатели не остановятся ни перед чем. Становление личом требует от тайного заклинателя быть на вершине могущества, что для многих свежевателей разума очень сложно, если вообще достижимо.

Столкнувшись с осознанием этой ужасной реальности, группа из девяти изгоев свежевателей разума использовала тайную магию и псионику, чтобы вывести новую истину. Эти девятеро назвались «алхун» и после того все, кто идет по их стопам, называют себя тем же именем.`,
    source: {
      id: SOURCE_VGTM,
      page: 187,
    },
  },
  {
    header: 'Тайна псионики',
    text: `[Алхуны](CREATURE:${CREATURE_ALHOON}) могут работать сообща в создании _Амулета поимки разума_, контейнера размером в кулак, сделанного из серебра, изумруда и аметиста. Этот процесс требует по крайней мере трёх тайных заклинателей свежевателей разума и жертвы такого же числа душ живых жертв в трёхдневном ритуале из комбинации заклинаний и псионики.

По его завершении свежеватели разума по своей воле присваивают себе нежизнь и превращаются в алхунов. Поначалу алхуна трудно отличить от обычного свежевателя разума. Самое очевидное отличие — это отсутствие всегда присутствующего на свежевателях разума покрытия слизью. Без этой защиты кожа алхуна становится сухой и потрескавшейся. Его глаза могут показаться утоплёнными и ссохшимися. Оба этих признака легко не заметить, если Вы никогда не видели свежевателя разума. Однако, через небольшой промежуток времени, плоть алхуна высыхает и глазницы начинают блестеть холодным светом, как и у других [личей](CREATURE:${CREATURE_LICH}).`,
    source: {
      id: SOURCE_VGTM,
      page: 187,
    },
  },
  {
    header: 'Осторожное бессмертие',
    text: `В отличие от [истинного лича](CREATURE:${CREATURE_LICH}), _Амулет поимки разума_ не возвращает алхуна к нежизни, если его уничтожат. Вместо этого разум алхуна переносится в амулет, где он и остается в общности с остальными запертыми разумами алхунов, а также с пожертвованными душами. Нежизнь, присваемая амулетом поимки разума, длится столько, сколько длится жизнь выбранной живой жертвы. Так что алхун, который привёл 200-летнего эльфа на жертву, скорее всего просуществует гораздо дольше, чем тот, кто пожертвовал 35-летнего человека. Алхуны могут продлить своё существование, повторив ритуал с новыми жертвами, перезапустив для себя часы.

Уничтожение _Амулета поимки разума_ отправляет заключённые в нем души в забвение, и поэтому алхуны часто работают вместе для создания изощрённой защиты амулета и своего предпочитаемого ритуального места. Иногда одному алхуну доверяют _Амулет поимки разума_, но это опасное предприятие. Любой, кто держит амулет поимки разума, получает преимущество на броски атаки, испытания и проверки против алхунов, связанных с его созданием, а те алхуны, в свою очередь, получают помеху на броски атаки, испытания и проверки против держателя. Кроме того, держатель амулета может телепатически общаться с любой пожертвованной душой внутри, а алхуны внутри амулета могут телепатически общаться с держателем. Существо, держащее амулет, не может избежать разговоров с [алхунами](CREATURE:${CREATURE_ALHOON}), но может заставить молчать пойманные души.`,
    source: {
      id: SOURCE_VGTM,
      page: 187,
    },
  },
]
