const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ELEMENTAL_AIR} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ELEMENTAL_AIR,
  nameEn: 'Air Elemental',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  hideFromFilter: true,
  name: {
    singular: {
      nominative: 'воздушный элементаль',
      genitive: 'воздушного элементаля',
      dative: 'воздушному элементалю',
      accusative: 'воздушного элементаля',
      instrumental: 'воздушным элементалем',
      prepositional: 'воздушном элементале',
    },
    plural: {
      nominative: 'воздушные элементали',
      genitive: 'воздушных элементалей',
      dative: 'воздушным элементалям',
      accusative: 'воздушных элементалей',
      instrumental: 'воздушными элементалями',
      prepositional: 'воздушных элементалях',
    },
  },
}
