const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_FAERIE_FIRE,
  SPELL_LEVITATE,
} = require('./../../../../spellIdList')
const {
  GEAR_CROSSBOW_HAND,
  GEAR_SHIELD,
  GEAR_SHORTSWORD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  drowDescription,
  drowNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_PARRY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GNOME,
} = require('./../../../../pcRaceIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_DROW_ELITE_WARRIOR} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Элитный воитель дроу`,
  nameEn: 'Drow Elite Warrior',
  id: CREATURE_DROW_ELITE_WARRIOR,
  description: [
    `Элитные воители дроу защищают свои дома и вышестоящих от всех врагов, хотя они и специализируются на боях с [дварфами](PC_RACE:${PC_RACE_DWARF}), [гномами](PC_RACE:${PC_RACE_GNOME}) и [эльфами](PC_RACE:${PC_RACE_ELF}) (включая других дроу). Они часто совершают набеги на надземные поселения под покровом ночи, возвращаясь с пленниками и награбленным ещё до рассвета.
  
  Элитными воителями дроу могут быть как женщины, так и мужчины.`,
    ...drowDescription,
  ],
  note: drowNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 308,
  },
  armor: {
    ac: 18,
    gearId: [GEAR_STUDDED_LEATHER_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
    LANG_ELVEN,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DANCING_LIGHTS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          {
            id: SPELL_LEVITATE,
            comment: 'только на себя',
          },
          SPELL_FAERIE_FIRE,
          SPELL_DARKNESS,
        ],
      },
    ],
  },
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Коротким мечом_`,
    },
    {
      gearId: GEAR_SHORTSWORD,
      attack: {
        hit: {
          type: DAMAGE_POISON,
          diceCount: 3,
          diceType: 6,
        },
      },
    },
    {
      gearId: GEAR_CROSSBOW_HAND,
      description: `Цель должна пройти испытание Телосложения СЛ 13, иначе станет отравленной на 1 час. Если испытание провалено на 5 или больше единиц, цель также лишена сознания, пока отравлена таким образом. Цель просыпается, если получает урон или другое существо разбудит её действием.`,
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 3,
    },
  ],
  genderId: GENDER_MALE,
}
