module.exports = [
  require('./baboon'),
  require('./badger'),
  require('./balor'),
  require('./bandit'),
  require('./bandit_captain'),
  require('./banshee'),
  require('./barbed_devil'),
  require('./barlgura'),
  require('./basilisk'),
  require('./bat'),
  require('./bearded_devil'),
  require('./behir'),
  require('./beholder'),
  require('./beholder_zombie'),
  require('./berserker'),
  require('./black_bear'),
  require('./black_pudding'),
  require('./blink_dog'),
  require('./blood_hawk'),
  require('./boar'),
  require('./bone_devil'),
  require('./bone_naga_guardian'),
  require('./bone_naga_spirit'),
  require('./brown_bear'),
  require('./bugbear_chief'),
  require('./bugbear'),
  require('./bulette'),
  require('./bullywug'),
]
