const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../../damageTypeList')
const {
  SPELL_SPIKE_GROWTH,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SPIKE_GROWTH,
  name: 'Шипы',
  nameEn: 'Spike Growth',
  description: `Пол в пределах 20-футового радиуса с центром на точке в пределах дистанции покрывается шипами и колючками. Эта местность становится труднопроходимой на время действия заклинания. Когда существо перемещением входит в эту область или идёт по ней, оно получает колющий урон 2к4 за каждые 5 пройдённых футов.

Трансформация поверхности проходит практически незаметно, и шипы хорошо замаскированы. Существа, которые не видели эту местность в момент накладывания заклинания, должны совершить проверку Мудрости (Внимательность) против СЛ Ваших заклинаний, чтобы распознать местность опасной, перед тем как в неё войти.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 150,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `семь острых шипов или семь заострённых веточек`,
  duration: { timeId: TIME_MINUTE, count: 10 },
  needConcentration: true,
  effect: {
    damage: {
      type: DAMAGE_PIERCING,
      diceCount: 2,
      diceType: 4,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 289,
    },
    {
      id: SOURCE_SRD,
      page: 182,
    },
  ],
}
