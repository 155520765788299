const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const hammersDescription = require('./../../constants/hammersDescription')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_XGTE,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../damageTypeList')
const {
  CAT_ARTISAN_TOOLS,
  CAT_EQUIPMENT,
  CAT_GOODS,
  CAT_LIGHT_ARMOR,
  CAT_LIGHT_SOURCE,
  CAT_MUSIC_TOOLS,
  CAT_SIMPLE_MELEE_WEAPON,
  CAT_TRANSPORT_WATER,
} = require('./../../../gearCategoryList')
const {
  WEAPON_LIGHT,
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')

const {
  GEAR_LADDER,
  GEAR_LAMP,
  GEAR_LANTERN_BULLSEYE,
  GEAR_LANTERN_HOODED,
  GEAR_LEATHER_ARMOR,
  GEAR_LEATHERWORKER_S_TOOLS,
  GEAR_LIGHT_HAMMER,
  GEAR_LINEN,
  GEAR_LOCK,
  GEAR_LONGSHIP,
  GEAR_LUTE,
  GEAR_LYRE,
  GEAR_OIL,
  GEAR_THIEVES_TOOLS,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_LEATHER_ARMOR,
    genderId: GENDER_MALE,
    name: 'Кожаный доспех',
    nameByCase: {
      nominative: 'кожаный доспех',
      genitive: 'кожаного доспеха',
      accusative: 'кожаный доспех',
      instrumental: 'кожаным доспехом',
    },
    nameEn: 'Leather Armor',
    description: [
      {
        header: 'Кожаный доспех',
        text: `Нагрудник и плечи этого доспеха изготовлены из кожи, вываренной в масле. Остальные части доспеха сделаны из более мягких и гибких материалов.`,
        source: {
          id: SOURCE_PHB,
          page: 145,
        },
      },
      {
        header: 'Кожаный доспех',
        text: `Нагрудник и плечи этого доспеха изготовлены из кожи, которую, для упрочнения, выварили в масле. Остальные части сделаны из более мягких материалов, чтобы не стеснять движения.

Отличный компромисс между защитой и весом, а также довольно недорогой.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 122,
        },
      },
      {
        header: 'Кожаный доспех',
        text: 'Края рукавных пройм этого кожаного нагрудника потемнели от долгого использования.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    cost: 1000,
    weight: 10,
    acChangeTo: 11,
    acUseDexMod: true,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_LIGHT_ARMOR,
  },
  {
    id: GEAR_LIGHT_HAMMER,
    genderId: GENDER_MALE,
    name: 'Лёгкий молот',
    nameByCase: {
      nominative: 'лёгкий молот',
      genitive: 'лёгкого молота',
      accusative: 'лёгкий молот',
      instrumental: 'лёгким молотом',
    },
    nameEn: 'Light Hammer',
    description: hammersDescription,
    damage: {
      diceType: 4,
      diceCount: 1,
    },
    cost: 200,
    damageType: DAMAGE_BLUDGEONING,
    weight: 2,
    source: [
      {
        id: SOURCE_PHB,
        page: 149,
      },
      {
        id: SOURCE_AYAGWnW,
        page: 111,
      },
    ],
    weaponPropList: [
      WEAPON_LIGHT,
      WEAPON_THROWN,
      {
        id: WEAPON_RANGE,
        value: {
          normal: 20,
          max: 60,
        },
      },
    ],
    category: CAT_SIMPLE_MELEE_WEAPON,
  },
  {
    id: GEAR_LOCK,
    genderId: GENDER_MALE,
    name: 'Замок',
    nameByCase: {
      nominative: 'замок',
      genitive: 'замка',
      accusative: 'замок',
      instrumental: 'замком',
    },
    nameEn: 'Lock',
    description: `Вместе с замком идёт и ключ. Без ключа существо, владеющее [воровскими инструментами](GEAR:${GEAR_THIEVES_TOOLS}), может вскрыть замок успешной проверкой Ловкости СЛ 15. Мастер может решить, что есть более качественные замки, стоящие больше.`,
    cost: 1000,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_LAMP,
    genderId: GENDER_FEMALE,
    name: 'Лампа',
    nameByCase: {
      nominative: 'лампа',
      genitive: 'лампы',
      accusative: 'лампу',
      instrumental: 'лампой',
    },
    nameEn: 'Lamp',
    description: `Лампа испускает яркий свет в пределах 15 фт и тусклый свет в пределах ещё 30 фт. Зажжённая лампа горит 6 часов от одной [фляги масла](GEAR:${GEAR_OIL}) (1 пинта).`,
    range: {
      normal: 15,
      max: 45,
    },
    cost: 50,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_LIGHT_SOURCE,
  },
  {
    id: GEAR_LADDER,
    genderId: GENDER_FEMALE,
    name: 'Лестница',
    nameByCase: {
      nominative: 'лестница',
      genitive: 'лестницы',
      accusative: 'лестницу',
      instrumental: 'лестницей',
    },
    nameEn: 'Ladder',
    length: 10,
    cost: 10,
    weight: 25,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_LANTERN_HOODED,
    genderId: GENDER_MALE,
    name: 'Фонарь, обычный',
    nameByCase: {
      nominative: 'фонарь',
      genitive: 'фонаря',
      accusative: 'фонарь',
      instrumental: 'фонарём',
    },
    nameAlt: 'Фонарь, закрытый',
    nameEn: `Lantern, Hooded`,
    description: `Закрытый фонарь испускает яркий свет в пределах 30 футов и тусклый свет в пределах ещё 30 футов. Зажжённый фонарь горит 6 часов от одной фляги (1 пинта) [масла](GEAR:${GEAR_OIL}). Вы можете действием опустить козырёк, уменьшив освещение до тусклого света в пределах 5 футов.`,
    range: {
      normal: 30,
      max: 60,
    },
    cost: 500,
    weight: 2,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_LIGHT_SOURCE,
  },
  {
    id: GEAR_LANTERN_BULLSEYE,
    genderId: GENDER_MALE,
    name: 'Фонарь, потайной',
    nameByCase: {
      nominative: 'потайной фонарь',
      genitive: 'потайного фонаря',
      accusative: 'потайной фонарь',
      instrumental: 'потайным фонарём',
    },
    nameAlt: 'Фонарь, направленный',
    nameEn: 'Lantern, Bullseye',
    description: `Направленный фонарь испускает яркий свет 60-футовым конусом и тусклый свет в пределах ещё 60 футов. Зажжённый фонарь горит 6 часов от одной фляги (1 пинта) [масла](GEAR:${GEAR_OIL}).`,
    range: {
      normal: 60,
      max: 120,
    },
    cost: 1000,
    weight: 2,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_LIGHT_SOURCE,
  },
  {
    id: GEAR_LEATHERWORKER_S_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты кожевника',
    nameByCase: {
      nominative: 'инструменты кожевника',
      genitive: 'инструментов кожевника',
      accusative: 'инструменты кожевника',
      instrumental: 'инструментами кожевника',
    },
    nameEn: 'Leatherworker’s Tools',
    description: `Познания в кожевенном деле распространяются на знания о шкурах животных и их свойствах. Они также включают в себя знания о кожаных доспехах и аналогичных вещах.

**Компоненты.** Инструменты кожевника включают

* кожевенный резак (нож),
* небольшую киянку,
* канавкорез,
* пробойник,
* нить,
* куски кожи.

**Магия.** Ваши знания и опыт в работе с кожей даёт вам дополнительные знания при обследовании магических предметов, сделанных из кожи, как, например, ботинки или некоторые плащи.

**Расследование.** Вы получаете дополнительные знания, когда исследуете кожаные предметы или улики, связанные с ними, также Вы используете свои познания в кожевничестве, чтобы находить зацепки, которые другие могут не заметить.

**Опознание кожи.** Смотря на кусок кожи или кожаный предмет, Вы можете определить источник кожи и любые особые методы, использованные в её обработке. Например, Вы можете обнаружить разницу между кожей, выделанной с использованием дварфийских методик, и кожей, выделанной с использованием методик полуросликов.

| Действие                               | СЛ |
|----------------------------------------|----|
| Изменить внешний вид кожаного предмета | 10 |
| Определить прошлое кожаного предмета   | 20 |
`,
    cost: 500,
    weight: 5,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 84,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_LYRE,
    genderId: GENDER_FEMALE,
    name: 'Лира',
    nameByCase: {
      nominative: 'лира',
      genitive: 'лиры',
      accusative: 'лиру',
      instrumental: 'лирой',
    },
    nameEn: 'Lyre',
    description: musicalToolDescriptionList,
    cost: 3000,
    weight: 2,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_LUTE,
    genderId: GENDER_FEMALE,
    name: 'Лютня',
    nameByCase: {
      nominative: 'лютня',
      genitive: 'лютни',
      accusative: 'лютню',
      instrumental: 'лютней',
    },
    nameEn: 'Lute',
    description: [
      {
        header: 'Лютня',
        text: 'Лютня — главный инструмент бардов. Её звуки слышны во всех тавернах Фаэруна.',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
      ...musicalToolDescriptionList,
    ],
    cost: 3500,
    weight: 2,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_LONGSHIP,
    genderId: GENDER_MALE,
    name: 'Драккар',
    nameByCase: {
      nominative: 'драккар',
      genitive: 'драккара',
      accusative: 'драккар',
      instrumental: 'драккаром',
    },
    nameAlt: 'Ладья',
    nameEn: 'Longship',
    description: 'Скорость: 3 узла',
    cost: 1000000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_WATER,
  },
  {
    id: GEAR_LINEN,
    genderId: GENDER_FEMALE,
    name: 'Ткань, льняная',
    nameByCase: {
      nominative: 'льняная ткань',
      genitive: 'льняной ткани',
      accusative: 'льняную ткань',
      instrumental: 'льняной тканью',
    },
    nameEn: 'Linen',
    description: '10 фт.²',
    cost: 300,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
]

module.exports = gearRawList
