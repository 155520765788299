module.exports = [
  {
    text: `Во время всех моих встреч с людоящерами я никогда не мог сказать, о чём они думают. Глаза этих рептилий никак не выказывают намерений. В обмен на свои припасы я получил лишь нервную дрожь по телу`,
    author: `записи торговца после встречи с племенем людоящеров на Болоте ящеров`,
  },
  {
    text: `Если Вы обдумываете, брать ли с собой чешуйчатого в приключение, запомните этот важный факт. Странный, нечеловеческий блеск в его глазах, когда он оглядывает вас — таким же взглядом Вы смотрите на свежеподжареный стейк`,
    author: 'Тордек, дварфийский воин и приключенец',
  },
]
