module.exports = {
  text: `Они падут перед нашими щитами,

Они падут от ударов наших мечей;

Их дом будет захвачен нами,

Их дети станут нашими рабами.

Ахерон!

Ахерон!

Победа за нами!`,
  author: `перевод боевой песни хобгоблинов`,
}
