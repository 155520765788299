const listToCollectionById = require('./../utils/listToCollectionById')

const PROF_DOUBLE = 'double'
const PROF_HALF = 'half'
const PROF_NONE = 'none'
const PROF_NORMAL = 'normal'

const proficiencyList = [
  {
    id: PROF_NONE,
    name: 'Без владения',
    description: `Персонаж не имеет такого владения`,
  },
  {
    id: PROF_NORMAL,
    name: 'Владение',
    description: `Персонаж добавляет бонус мастерства к таким проверкам`,
  },
  {
    id: PROF_HALF,
    name: 'Владение',
    description: `Персонаж добавляет половину бонуса мастерства к таким проверкам`,
  },
  {
    id: PROF_DOUBLE,
    name: 'Экспертиза',
    description: `Персонаж добавляет удвоенный бонус мастерства к таким проверкам`,
  },
]

module.exports = proficiencyList

module.exports.proficiencyCollection = listToCollectionById(proficiencyList)

module.exports.PROF_DOUBLE = PROF_DOUBLE
module.exports.PROF_HALF = PROF_HALF
module.exports.PROF_NONE = PROF_NONE
module.exports.PROF_NORMAL = PROF_NORMAL
