const {PC_CLASS_SORCERER} = require('./../../pcClassIdList')
const {
  CREATURE_DJINNI,
  CREATURE_SAHUAGIN,
} = require('./../../creatureIdList')

const {
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK,
  SOURCE_UA_2017_02_06_SORCERER,
  SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
  SOURCE_UA_2018_06_11_GIANT_SOUL,
  SOURCE_UA_2019_09_05_ABERRANT_LURK,
  SOURCE_UA_2020_02_06_SUBCLASSES_PART_2,
  SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
  SOURCE_UA_2022_03_08_HEROES_OF_KRYNN,
  SOURCE_XGTE,
} = require('./../../sourceList')

const {
  PC_SUBCLASS_SORCERER_ABERRANT_MIND,
  PC_SUBCLASS_SORCERER_ABERRANT_MIND_2019_05_09,
  PC_SUBCLASS_SORCERER_CLOCKWORK_SOUL,
  PC_SUBCLASS_SORCERER_CLOCKWORK_SOUL_2020_02_06,
  PC_SUBCLASS_SORCERER_DIVINE_SOUL,
  PC_SUBCLASS_SORCERER_DRACONIC_BLOODLINE,
  PC_SUBCLASS_SORCERER_FAVORED_SOUL_2017_02_06,
  PC_SUBCLASS_SORCERER_FAVORED_SOUL_2017_05_01,
  PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11,
  PC_SUBCLASS_SORCERER_LUNAR_MAGIC_2022_03_08,
  PC_SUBCLASS_SORCERER_PHOENIX_SORCERY_2017_02_06,
  PC_SUBCLASS_SORCERER_PSIONIC_SOUL_2020_04_14,
  PC_SUBCLASS_SORCERER_SEA_SORCERY_2017_02_06,
  PC_SUBCLASS_SORCERER_SHADOW_MAGIC,
  PC_SUBCLASS_SORCERER_SHADOW_MAGIC_2015_11_02,
  PC_SUBCLASS_SORCERER_STONE_SORCERY_2017_02_06,
  PC_SUBCLASS_SORCERER_STORM_SORCERY,
  PC_SUBCLASS_SORCERER_WILD_MAGIC,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_SORCERER_ABERRANT_MIND,
    pcClassId: PC_CLASS_SORCERER,
    isReady: false,
    name: 'Искажённый разум',
    nameEn: 'Aberrant Mind',
    description: `Чуждое влияние обвило свои щупальца вокруг Вашего разума, давая Вам псионические силы. Теперь Вы можете ими касаться чужих умов и изменять мир вокруг себя, управляя энергиями мультивселенной. Будет ли эта сила сиять для других маяком надежды? Или же Вы будете ужасом для тех, кто ощутит удар Вашего разума и странные проявления Вашей мощи?

Как чародей искажённого разума, Вы решаете как именно Вы получили свои силы. Родились ли Вы с ними? Или же некое событие в прошлом наделило Вас выдающейся псионикой? Определите возможный источник Вашей силы по таблице Источники искажений.

# Источники искажений

| к6 | Источник |
|----|----------|
| 1  | Вы подверглись искажающему влиянию Дальнего Предела. Вы убеждены что теперь на Вас растут щупальцы, но никто больше их не видит. |
| 2  | Психический ветер Астрального плана обвеял Вас псионической энергией. Когда Вы используете свои силы, вокруг Вас сияют слабые пятна света. |
| 3  | Однажды Вы пережили подчинение сознанием аболета, оставившее психический след на Вашем разуме. |
| 4  | Вам подсадили личинку пожирателя разума, но цереброморфизм так и не завершился. Теперь психическая сила личинки стала Вашей. Когда Вы ей пользуетесь, Ваша плоть порывается странной слизью. |
| 5  | В детстве у Вас был воображаемый друг, который выглядел точь-в-точь как флампф или странное жёлтое утконосоподобное существо. Однажды он наделил Вас псионическими способностями, которые в итоге оказались не такими уж воображаемыми. |
| 6  | Ваши кошмары шепчут Вам правду: Ваша псионическая сила — не Ваша. Вы забрали её у своего паразита-близнеца! |
`,
    note: {
      text: `Щупальцы, психические силы, твари из-за далёких звёзд — кошмар для одного и хорошая новость для другого`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 99,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_ABERRANT_MIND_2019_05_09,
    pcClassId: PC_CLASS_SORCERER,
    releasedAs: PC_SUBCLASS_SORCERER_ABERRANT_MIND,
    isReady: false,
    name: 'Искажённый разум',
    nameEn: 'Aberrant Mind',
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_CLOCKWORK_SOUL,
    pcClassId: PC_CLASS_SORCERER,
    isReady: false,
    name: 'Заводная душа',
    nameEn: 'Clockwork Soul',
    note: {
      text: `Я редко упоминаю что говорю по-модронски, потому что все хотят одного и того же — выучить ругательства, так что давай сразу с этим разберёмся.

Урок первый: „Бип-буп“ и всякие постукивания.`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 101,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_CLOCKWORK_SOUL_2020_02_06,
    pcClassId: PC_CLASS_SORCERER,
    releasedAs: PC_SUBCLASS_SORCERER_CLOCKWORK_SOUL,
    isReady: false,
    name: 'Заводная душа',
    nameEn: 'Clockwork Soul',
    source: {
      id: SOURCE_UA_2020_02_06_SUBCLASSES_PART_2,
      page: 4,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_DIVINE_SOUL,
    pcClassId: PC_CLASS_SORCERER,
    isReady: true,
    name: 'Божественная душа',
    nameEn: 'Divine Soul',
    description: `Иногда искра магии, питающая чародея, исходит от божественного начала, мерцающего в душе.

Наличие такой благословенной души является признаком того, что Ваша врожденная магия может исходить из далекой, но могущественной семейной связи с божественным существом. Возможно, Ваш предок был ангелом, превращённый в смертного и посланный сражаться во имя своего бога. Или Ваше рождение может быть предрешено древним пророчеством, что делает из Вас служителя богов или избранным сосудом божественной магии.

Традиционная религиозная иерархия часто видит в божественных душах угрозу из-за их природного магнетизма. Эти чародеи, повелевая силой богов, могут подорвать существующий строй и заявить, что они напрямую связаны с божеством.

В некоторых культурах, только тот, кто обладает божественной душой, может стать священнослужителем. На таких землях, духовенство, как правило, является наследным и передаётся из поколения в поколение в виде нескольких избранных семей или кланов.`,
    note: {
      text: `Почему у большинства небесных сущностей птичьи крылья, а у инфернальных сущностей крылья летучей мыши? Это кажется деспотичным. Должен быть ангел с крыльями летучей мыши!`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 59,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_DRACONIC_BLOODLINE,
    pcClassId: PC_CLASS_SORCERER,
    isReady: true,
    name: 'Наследие драконьей крови',
    nameEn: 'Draconic Bloodline',
    description: `Ваша врождённая магия произошла из магии драконов, примешавшейся к Вашей крови, или унаследованной Вами.

Чаще всего, чародеи с таким источником силы могут проследить свою родословную до могучих чародеев древности, заключавших сделки с драконами или даже объявлявших себя потомками драконов. Некоторые из таких наследственных ветвей хорошо известны, но большинство из них скрыты. Или чародей может являться родоначальником такой ветви, получив силу в результате договора или других сверхъестественных обстоятельств.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 103,
      },
      {
        id: SOURCE_SRD,
        page: 44,
      },
    ],
  },
  {
    id: PC_SUBCLASS_SORCERER_FAVORED_SOUL_2017_02_06,
    pcClassId: PC_CLASS_SORCERER,
    releasedAs: PC_SUBCLASS_SORCERER_DIVINE_SOUL,
    isReady: false,
    name: 'Избранная душа',
    nameEn: 'Favored Soul',
    source: {
      id: SOURCE_UA_2017_02_06_SORCERER,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_FAVORED_SOUL_2017_05_01,
    pcClassId: PC_CLASS_SORCERER,
    releasedAs: PC_SUBCLASS_SORCERER_DIVINE_SOUL,
    isReady: false,
    name: 'Избранная душа',
    nameEn: 'Favored Soul',
    source: {
      id: SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
      page: 5,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11,
    pcClassId: PC_CLASS_SORCERER,
    isReady: false,
    name: 'Душа великана',
    nameEn: 'Giant Soul',
    source: {
      id: SOURCE_UA_2018_06_11_GIANT_SOUL,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_PHOENIX_SORCERY_2017_02_06,
    pcClassId: PC_CLASS_SORCERER,
    isReady: false,
    name: 'Волшебство феникса',
    nameEn: 'Phoenix Sorcery',
    source: {
      id: SOURCE_UA_2017_02_06_SORCERER,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_PSIONIC_SOUL_2020_04_14,
    pcClassId: PC_CLASS_SORCERER,
    releasedAs: PC_SUBCLASS_SORCERER_ABERRANT_MIND,
    isReady: false,
    name: 'Псионическая душа',
    nameEn: 'Psionic Soul',
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 5,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_SEA_SORCERY_2017_02_06,
    pcClassId: PC_CLASS_SORCERER,
    isReady: false,
    name: 'Волшебство моря',
    nameEn: 'Sea Sorcery',
    source: {
      id: SOURCE_UA_2017_02_06_SORCERER,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_SHADOW_MAGIC,
    pcClassId: PC_CLASS_SORCERER,
    isReady: true,
    name: 'Теневая магия',
    nameEn: 'Shadow Magic',
    description: [
      `Вы — существо тени, потому что Ваша врожденная магия исходит из самого Царства теней. Вы могли бы проследить свою родословную до сущности из этого места, или, возможно, Вы подверглись воздействию её беспощадной энергии и трансформированы ею.

Сила теневой магии накладывает странный покров на Ваш внешний вид. Искра жизни, которая поддерживает Вас приглушена, как будто она изо всех сил пытается противостоять тёмной энергии, которая проникает в Вашу душу. Вы можете самостоятельно выбрать причуду для Вашего персонажа или определить её случайным образом по таблице _Причуды теневых чародеев_.`,
      {
        header: 'Причуды теневых чародеев',
        text: `
| к6 | Причуда                                                                                                   |
|----|-----------------------------------------------------------------------------------------------------------|
| 1  | Вы всегда холодны на ощупь.                                                                               |
| 2  | Когда Вы спите, кажется, что Вы не дышите (хотя Вы всё равно должны дышать, чтобы жить).                  |
| 3  | Вы почти не истекаете кровью, даже когда получили тяжёлые ранения.                                        |
| 4  | Ваше сердце бьётся один раз в минуту. Это событие иногда Вас удивляет.                                    |
| 5  | У Вас есть проблема с тем, чтобы вспомнить, что к живым существам и трупам следует относиться по-разному. |
| 6  | Вы моргнули. Один раз. На прошлой неделе.                                                                 |
`,
        source: {
          id: SOURCE_XGTE,
          page: 60,
        },
      },
    ],
    note: {
      text: `Иногда я уничтожаю свою тень, когда вижу её, потому что я думаю, что это другой бехолдер.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 60,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_SHADOW_MAGIC_2015_11_02,
    pcClassId: PC_CLASS_SORCERER,
    releasedAs: PC_SUBCLASS_SORCERER_SHADOW_MAGIC,
    isReady: false,
    name: 'Теневая магия',
    nameEn: 'Shadow Magic',
    source: {
      id: SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_STONE_SORCERY_2017_02_06,
    pcClassId: PC_CLASS_SORCERER,
    isReady: false,
    name: 'Волшебство камня',
    nameEn: 'Stone Sorcery',
    source: {
      id: SOURCE_UA_2017_02_06_SORCERER,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_STORM_SORCERY,
    pcClassId: PC_CLASS_SORCERER,
    isReady: true,
    name: 'Штормовое Колдовство',
    nameEn: 'Storm Sorcery',
    description: `Ваша врожденная магия исходит от силы стихии воздуха.

Многие с этой силой могут отследить свою магию до околосмертельного опыта, вызванного Великим Дождём, но, возможно, Вы родились во время бушующего шторма, настолько сильного, что народ всё ещё рассказывает истории о нем, или Ваша родословная может включать влияние могущественных воздушных существ, таких как [джинн](CREATURE:${CREATURE_DJINNI}). В любом случае штормовая магия пронизывает само Ваше существование.

Штормовые чародеи — бесценные члены экипажа корабля. Их магия позволяет им контролировать ветер и погоду в непосредственной близости от себя. Их способности также полезны в отражении нападений [сахуагинов](CREATURE:${CREATURE_SAHUAGIN}), пиратов и других водных угроз.`,
    note: {
      text: `Что это с людьми и погодой? Это просто небо плачет и кричит, потому что так далеко от меня.`,
      author: `Занатар`,
    },
    source: [
      {
        id: SOURCE_SCAG,
        page: 138,
      },
      {
        id: SOURCE_XGTE,
        page: 61,
      },
    ],
  },
  {
    id: PC_SUBCLASS_SORCERER_WILD_MAGIC,
    pcClassId: PC_CLASS_SORCERER,
    isReady: true,
    name: 'Дикая магия',
    nameEn: 'Wild Magic',
    description: `Ваша магия исходит от диких сил хаоса, лежащих в основе мироздания.

Вы, возможно, подверглись воздействию грубой магии в той или иной форме. Возможно, она просочилась через портал, ведущий в Лимбо, Стихийные Планы или таинственный Дальний Предел. Может быть, Вы были благословлены могущественной феей или отмечены демоном. Или Ваша магия может быть случайностью Вашего рождения, без очевидной причины.

Вне зависимости от её происхождения, хаотическая магия бурлит внутри Вас, готовая вырваться наружу.`,
    source: {
      id: SOURCE_PHB,
      page: 104,
    },
  },
  {
    id: PC_SUBCLASS_SORCERER_LUNAR_MAGIC_2022_03_08,
    pcClassId: PC_CLASS_SORCERER,
    isReady: false,
    name: 'Лунная магия',
    nameEn: 'Lunar Magic',
    source: {
      id: SOURCE_UA_2022_03_08_HEROES_OF_KRYNN,
      page: 2,
    },
  },
]
