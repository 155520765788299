const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  CREATURE_ARCANALOTH,
  CREATURE_BALOR,
  CREATURE_BARBED_DEVIL,
  CREATURE_ICE_DEVIL,
  CREATURE_IMP,
  CREATURE_INCUBUS,
  CREATURE_MARILITH,
  CREATURE_NIGHT_HAG,
  CREATURE_PIT_FIEND,
  CREATURE_QUASIT,
  CREATURE_RAKSHASA,
  CREATURE_SUCCUBUS,
  CREATURE_VROCK,
} = require('./../../../../creatureIdList')
const {
  MGC_RARITY_ARTIFACT,
} = require('./../../../../magicItemRarityList')
const {
  SPELL_AURA_OF_LIFE,
  SPELL_AURA_OF_PURITY,
  SPELL_BANISHMENT,
  SPELL_BEACON_OF_HOPE,
  SPELL_GATE,
  SPELL_MASS_CURE_WOUNDS,
} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_CROOK_OF_RAO,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_CROOK_OF_RAO,
  name: `Посох Рао`,
  nameEn: `Crook of Rao`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  description: [
    `Много лет назад безмятежный бог Рао создал инструмент, чтобы защитить своих юных послушников от зла Нижних планов. Тем не менее, эпохи спустя, смертные разработали свои собственные методы борьбы с экзистенциальными угрозами, и _Посох_ был в значительной степени забыт. Однако в последние годы _Посох Рао_ был заново открыт и использован против растущей силы Королевы Ведьм Иггвильв (одно из имён волшебницы Таши). Несмотря на то, что она была побеждена, Иггвильв сумела повредить _Посох_ во время битвы, заразив его коварным проклятием и потенциалом для будущей победы. После этого _Посох_ снова был потерян. Время от времени он появляется снова, но знаменитый артефакт уже не тот, каким был. Независимо от того, осознают ли носители артефакта всю его угрозу, немногие рискуют использовать _Посох Рао_ — возможно, в последний раз.`,
    {
      header: 'Случайные свойства',
      text: `* 2 малых положительных свойства
* 1 основное положительное свойство
* 1 малое отрицательное свойство`,
    },
    {
      header: 'Заклинания',
      text: `У _Посоха_ есть 6 зарядов. Удерживая его, Вы можете действием потратить 1 или более его зарядов, чтобы сотворить им одно из следующих заклинаний (СЛ 18)

* [Аура жизни](SPELL:${SPELL_AURA_OF_LIFE}) (2 заряда),
* [Аура очищения](SPELL:${SPELL_AURA_OF_PURITY}) (2 заряда),
* [Изгнание](SPELL:${SPELL_BANISHMENT}) (1 заряд),
* [Маяк надежды](SPELL:${SPELL_BEACON_OF_HOPE}) (1 заряд),
* [Множественное лечение ран](SPELL:${SPELL_MASS_CURE_WOUNDS}) (3 заряда).

Ежедневно на рассвете _Посох Рао_ восстанавливает 1к6 потраченных заряда.`,
    },
    {
      header: 'Абсолютное изгнание',
      text: `Пока Вы настроены на _Посох_ и держите его, Вы можете потратить 10 минут, на изгнание всех, кроме самых могущественных исчадий в пределах 1 мили. Исчадия с ПО 19 и выше игнорируют эту способность. Каждое изгнанное исчадие отправляется обратно на свой родной план и не может вернуться на план, из которого _Посох Рао_ его изгнал в течение 100 лет.`,
    },
    {
      header: 'Испорченная матрица',
      text: `Каждый раз, когда используется способность «Абсолютное изгнание» _Посоха Рао_ или когда израсходован его последний заряд, совершите бросок по таблице «Внешнепланарный разворот». Любые существа, созданные этим эффектом, появляются в случайных свободных пространствах в пределах 60 футов и не находятся под Вашим контролем.

## Внешнепланарный разворот

| к100 | Эффект |
|-|-|
| 1–25 | Открывается портал на случайный план. Портал закрывается через 5 минут. |
| 26–45 | Появляются 2к4 [беса](CREATURE:${CREATURE_IMP}) и 2к4 [квазита](CREATURE:${CREATURE_QUASIT}). |
| 46–60 | Появляются 1к8 [суккубов](CREATURE:${CREATURE_SUCCUBUS}) или [инкубов](CREATURE:${CREATURE_INCUBUS}). |
| 61–70 | Появляются 1к10 [шипастых дьяволов](CREATURE:${CREATURE_BARBED_DEVIL}) и 1к10 [вроков](CREATURE:${CREATURE_VROCK}). |
| 71–80 | Появляются 1 [арканалот](CREATURE:${CREATURE_ARCANALOTH}), 1 [ночная карга](CREATURE:${CREATURE_NIGHT_HAG}) и 1 [ракшас](CREATURE:${CREATURE_RAKSHASA}). |
| 81–85 | Появляются 1 [ледяной дьявол](CREATURE:${CREATURE_ICE_DEVIL}) и 1 [марилит](CREATURE:${CREATURE_MARILITH}). |
| 86–90 | Появляются 1 [балор](CREATURE:${CREATURE_BALOR}) и 1 [исчадие преисподней](CREATURE:${CREATURE_PIT_FIEND}). По усмотрению Мастера портал открывается к местонахождению архидьявола или лорда демонов, а затем закрывается через 5 минут. |
| 91–00 | Проклятие Иггвильв. |`,
    },
    {
      header: 'Проклятие Иггвильв',
      text: `Когда _Посох Рао_ в последний раз использовался против Иггвильв, Королева Ведьм ударила артефакт, заразив его магическую матрицу. С годами это проклятие распространилось по _Посоху_, угрожая жестоко извратить его древнюю магию. Если это произойдет, тот _Посох Рао_, который известен сейчас, будет уничтожен, его магическая матрица инвертируется и взорвётся, образуя портал диаметром 50 футов. Этот портал функционирует как постоянное заклинание [Врата](SPELL:${SPELL_GATE}), наложенное Иггвильв. После этого врата на инициативе 20 один раз в раунд громко произносят имя исчадия голосом Иггвильв, до тех пор, пока врата не назовут всех исчадий, когда-либо изгнанных _Посохом Рао_. Если исчадие всё ещё существует, оно проходит через эти врата. Для завершения этого процесса требуется восемнадцать лет, в конце которых врата становятся постоянным порталом в Пазунию, первый слой Бездны.`,
    },
    {
      header: 'Уничтожение или починка Посоха',
      text: `_Посох Рао_ можно либо уничтожить, либо починить, отправившись на гору Селестию и получив слезу вечно безмятежного бога Рао. Один из способов заставить бесчувственного бога плакать — это воссоединить Рао с духом его первого послушника, который давным-давно искал откровений за пределами мультивселенной. _Посох_ растворяется, если на год и день погрузится в слезы бога. Если промывать слезу ежедневно в течение 30 дней, _Посох_ теряет способность «Испорченная матрица».`,
    },
  ],
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_TCoE,
    page: 178,
  },
}
