const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_STANDING_LEAP,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_FROG} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Лягушка',
  nameEn: 'Frog',
  id: CREATURE_FROG,
  description: `У лягушки нет эффективных атак. Она питается крохотными насекомыми и обычно обитает у воды, в деревьях или под землёй. Статистику лягушки можно использовать для изображения жабы.`,
  couldBeFamiliar: true,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 333,
  },
  armor: 11,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 20,
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 8,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 10,
      jumpHeight: 5,
    },
  ],
  genderId: GENDER_FEMALE,
}
