const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_17,
} = require('./../../../../crList')
const {
  SIZE_HUGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_CHARGE,
  ABILITY_LABYRINTHINE_RECALL,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_SIEGE_MONSTER,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_GORISTRO,
  CREATURE_MINOTAUR,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гористро',
  nameEn: 'Goristro',
  id: CREATURE_GORISTRO,
  description: [
    `Гористро выглядит как изуверский [минотавр](CREATURE:${CREATURE_MINOTAUR}) двадцати футов ростом. Под началом демонических повелителей гористро становятся грозными осадными орудиями и ценными питомцами. Преследуя добычу, они сверхъестественным образом выбираются из лабиринтов и запутанных проходов.

Громадные гористро иногда носят на плечах паланкины для меньших размерами демонов, перевозя их, как слоны на своих спинах.`,
    ...demonDescription,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 89,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 23,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 25,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 25,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
  ],
  cr: CR_17,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 15,
      attackName: 'Боданием',
      damageText: '38 (7к10) колющего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 21, иначе её оттолкнёт на 20 футов и собьёт с ног.',
    },
    ABILITY_LABYRINTHINE_RECALL,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SIEGE_MONSTER,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: две _Кулаками_ и одну _Копытом_.`,
    },
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Копыто',
      description: `Если цель — существо, она должна пройти испытание Силы СЛ 21, иначе её собьёт с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 7,
          diceType: 10,
          diceBonus: 7,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
