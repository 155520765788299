const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  CREATURE_FIRE_GIANT,
  CREATURE_FIRE_ELEMENTAL,
  CREATURE_FIRE_GIANT_DREADNOUGHT,
  CREATURE_FROST_GIANT,
  CREATURE_STORM_GIANT,
  CREATURE_HELL_HOUND,
  CREATURE_CLOUD_GIANT,
  CREATURE_TROLL,
} = require('./../../creatureIdList')
const {
  PC_RACE_DWARF,
} = require('./../../pcRaceIdList')
const {
  GOD_SURTUR,
  GOD_THRYM,
} = require('./../../godIdList')
const {
  PC_CLASS_WIZARD,
} = require('./../../pcClassIdList')

const giantDescriptionList = require('./giant')

module.exports = [
  {
    header: 'Огненные великаны',
    text: `Умелые ремесленники и организованные бойцы, [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) обитают среди вулканов, потоков лавы и скалистых гор. Это безжалостные милитаристские громилы, чьё мастерство в обработке металлов поистине легендарно.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Огненные кузни',
    text: `Крепости [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) находятся около и внутри вулканов или рядом с озёрами магмы. Жар, исходящий от тех мест, где они живут, раскаляет докрасна стены их железных крепостей. В землях, где нет источников высокой температуры, им приходится сжигать много угля. Обычно кузни занимают почётные места в их владениях, и каменные крепости великанов постоянно извергают столбы дыма и сажи. В более отдалённых заставах [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) сжигают тонны дров, чтобы поддержать температуру в своих кузницах. Это приводит к тому, что на много километров в округе не остаётся ни одного дерева, а вся почва покрывается сажей.

[Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) ненавидят холод так же, как их родственники, [ледяные великаны](CREATURE:${CREATURE_FROST_GIANT}), ненавидят жар. Они могут приспосабливаться к холоду, но только с усилиями, надевая тяжёлую шерстяную одежду и меха, и поддерживая тепло в очаге, чтобы оставаться в тепле.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Боевые эксперты',
    text: `С самого рождения [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) учат воевать. Ещё в колыбели родители поют им песни о сражениях. Детьми [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) играют в войну, кидаясь друг в друга вулканическими породами на берегах магмовых рек. Позднее военное обучение становится неотъемлемой частью их жизни в крепостях и подземных царствах дыма и пепла. [Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) поют оды о выигранных и проигранных сражениях, а их танцы представляют собой боевое топанье ногами, резонирующее, словно кузнечные молоты в их задымленных залах.

[Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) передают секреты своего мастерства из поколения в поколение, и их боевая удаль происходит не из дикой ярости, а из дисциплины и бесконечных тренировок. Многие враги недооценивают [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) из-за их грубого поведения, и слишком поздно узнают, что те живут сражениями и могут быть хитрыми тактиками.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Феодальный строй',
    text: `Гуманоиды, побеждённые в войне, становятся вассалами [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}). Вассалы должны отдавать своих подданных, чтобы те обрабатывали фермы и поля на окраине владений [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}), выполняли роль прислуги в их замках, разводили скот и убирали поля. Также они вынуждены платить десятину [огненным великанам](CREATURE:${CREATURE_FIRE_GIANT}).

Ремесленники [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) чаще работают по опыту и вдохновению, чем по записям и вычислениям. Большинство [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) не тратят время на подобную чепуху, для этого они держат рабов, которые разбираются в этом. Тех рабов, что не подходят для двора или полевых работ (особенно [дварфов](PC_RACE:${PC_RACE_DWARF})), [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) отправляют в свои горные владения добывать руду и драгоценные камни из-под земли.

[Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) низкого ранга управляют шахтами и рабами, трудящимися в них — немногие живут долго при такой опасной и трудной работе. Хоть [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) и умеют прокладывать туннели и добывать руду, их не столько заботит безопасность рабов, сколько выплавка и обработка богатств, добываемых этими рабами.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Умелые ремесленники',
    text: `[Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) заработали грозную репутацию солдат и завоевателей своими поджогами, грабежом и разрушениями. Однако они воспитывают величайших мастеров и ремесленников среди всех великанов. Они превосходны как в выплавке и ковке, так и в больших постройках из металлов и камня, а качество их мастерства видно даже в разрушительных орудиях и оружии.

[Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) стараются строить нерушимые крепости и сильнейшие осадные орудия. Они экспериментируют со сплавами, создавая крепчайшую броню, а затем куют мечи, способные её пронзить. Для такой работы в равной степени важны и мускулы и мозги, а [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}), занимающие высокое положение, как правило, сильнейшие и умнейшие в своём роду.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Огненные великаны',
    text: `[Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) были офицерами, инженерами и ремесленниками древней Остории. Их положение и не имеющие аналогов мастерство, наряду с их властным видов, сделали их заносчивыми и высокомерными.`,
    source: {
      id: SOURCE_VGTM,
      page: 27,
    },
  },
  {
    header: 'Уложение ремесла',
    text: `[Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) — величайшие кузнецы, архитекторы и техники среди всех великанов. Обитые железом залы крепостей [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) глубоко внутри горы или вулкана несут на себе невообразимый вес камня над ними, и позволяют великанам использовать жар рек магмы для работы их кузниц.

Доблесть [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) в ремесленных занятиях определяет их место в Уложении. Хотя [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) придают большое значение боевому мастерству, они считают, что успех в бою или на охоте происходит в основном от качества оружия и доспехов, а те, кто могут создать лучшие образцы последних получают самый высокий статус в клане. Мастера-ремесленники, архитекторы, инженеры выбирают лучших учеников, чтобы передать свои знания наряду со своей репутацией. Часто ученики — дети или братья и сёстры своих учителей, но это не всегда так. Лидеры выбираются общим признанием из числа лучших ремесленников в клане.

Одна группа [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}), известная как [дредноуты](CREATURE:${CREATURE_FIRE_GIANT_DREADNOUGHT}), обязана своим местом в Уложении не своей ремесленной способности, но их необычайной физической силе. Они выполняют значительную часть работы по охране литейных и поддержанию их функционирования — задача, без выполнения которой ремесленники не смогли бы ничего сделать.

[Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) не уделяют много времени изготовлению произведения искусства, хотя они утверждают, что все их подвиги в металлообработке и машиностроении сами по себе являются формами художественного выражения. Помимо этих достижений, произведения художественного мастерства среди [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) редки, а большинство из немногих существующих произведений представляют собой ювелирные изделия, изготовленные из драгоценных камней и руды, которые добывают и очищают [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}). Уникальная форма искусства, которой владеют [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}), — это манипулирование магмой, её охлаждение и преобразование в причудливые, уникальные в своем роде формы. Наиболее яркие из этих работ собираются и выставляются напоказ внутри крепости, подобно тому, как другие культуры создают подстриженные сады-топиарии.`,
    source: {
      id: SOURCE_VGTM,
      page: 27,
    },
  },
  {
    header: 'Очищающий огонь Сурта',
    text: `[Сурт](GOD:${GOD_SURTUR}) — главное божество [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}), как полагают, был рожден вместе с [Тримом](GOD:${GOD_THRYM}). Каждый из близнецов пытался первым закричать, первым научиться ходить и первым заговорить, и они соревнуются друг с другом до сих пор. Часто в легендах эти соревнования носят кровопролитный характер, но в некоторых сказаниях братья действуют бок о бок в грандиозных приключениях. [Сурт](GOD:${GOD_SURTUR}) показан как более умный из двоих, и [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) подражают его непревзойденному мастерству в создании вещей.

В мире [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}), огонь — сила, которая выжигает несовершенства в материале и оставляет только то, что достаточно сильно, чтобы выдержать высокую температуру, например, лучшая сталь из горна. Когда огонь находится под контролем, это самый мощный инструмент великанов; когда он бушует бесконтрольно, он может уничтожать леса и опустошать города.

Из-за разрушительной силы огня, поклонение [Сурту](GOD:${GOD_SURTUR}) окрашено апокалиптическими красками. Некоторые наблюдатели подозревают, что жрецы [Сурта](GOD:${GOD_SURTUR}) содержат подпольные мастерские и арсеналы в рамках подготовки к финальной всеобщей битве, что решит судьбу мира. Если подозрения верны, эти места искусно скрыты и держатся в секрете даже от большинства [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}).`,
    source: {
      id: SOURCE_VGTM,
      page: 27,
    },
  },
  {
    header: 'Могущественные воины, плохие стратеги',
    text: `Когда [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) не оттачивают свои навыки ремесла, они тренируются с оружием или занимаются физическими упражнениями, чтобы держать себя в форме. Типичный [огненный великан](CREATURE:${CREATURE_FIRE_GIANT}) владеет мастерством тактики боя, неведомым большинству других воинов, но понимание стратегии у великанов находится в зачаточном состоянии.

Этот недостаток порождается не отсутствием способностей, а уходит корнями в традиции. В прошлые века, когда великаны работали совместно, чтобы доминировать в мире, стратегию определяли [облачные](CREATURE:${CREATURE_CLOUD_GIANT}) и [штормовые великаны](CREATURE:${CREATURE_STORM_GIANT}). С тех пор как кланы разошлись после войны Остории с драконами, [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) не участвовали в широкомасштабных кампаниях для расширения своих владений, а сражались в бесчисленных стычках, главным образом чтобы укрепить свою власть на территории, которую они уже получили. Если бы амбициозный [огненный великан](CREATURE:${CREATURE_FIRE_GIANT}) когда-либо сумел стать мастером стратегического планирования, мало что могло бы остановить племя [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}), которые воспользовались этим дополнительным преимуществом над своими соседями.

[Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) растят и тренируют своих [адских псов](CREATURE:${CREATURE_HELL_HOUND}) как военных собак, и иногда они убеждают человеческих [волшебников](PC_CLASS:${PC_CLASS_WIZARD}) (свободных или порабощенных) подчинить [огненных элементалей](CREATURE:${CREATURE_FIRE_ELEMENTAL}) и сделать их стражами цитаделей [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}). Некоторые позволяют троллям свободно перемещаться в редко используемых частях их крепостей, чтобы служили охранниками по периметру территории. [Тролли](CREATURE:${CREATURE_TROLL}) требуют минимального ухода, в состоянии выжить на крохах, бросаемых [огненными великанами](CREATURE:${CREATURE_FIRE_GIANT}), а также питаясь больными или мертвыми рабами. [Тролли](CREATURE:${CREATURE_TROLL}) достаточно сильны, чтобы отвадить большинство вторженцев, а их уязвимость к огню делает их безвредными для [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}).`,
    source: {
      id: SOURCE_VGTM,
      page: 28,
    },
  },
  {
    header: 'Рабы: сберегающий труд инструмент',
    text: `Постройка и управление цитаделью [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) требует много сил. Большая часть работ ложится не на плечи самих великанов, а на рабов, которыми они владеют. [Огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) порабощают других существ, чтобы использовать в качестве рабочей силы, в то время как великаны могут сфокусироваться на более важных аспектах кузнечных работ и ремесла, на которые способны только они сами. Они не самые жестокие хозяева, но и не особо добрые — на рабов им наплевать, поскольку они не великаны, и если они начнут кончаться, всегда можно добыть ещё.

Большинство существ, которых захватывают [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}), насильственно заставляют работать на шахтах великанов либо на фермах на поверхности, которые великаны считают частью своих владений. Даже мастера-ремесленники других рас считаются низкоквалифицированной рабочей силой, потому что только у ничтожно малой их части есть таланты, которые бы [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) сочли «квалификацией». Только существа, которые обладают навыками, нужными великанам, но не практикуемыми ими (потому что они не ценятся с точки зрения Уложения), такими, как бухгалтерия, пивоварение и медицина, могут сохранить свой род деятельности.

С квалифицированными рабами обращаются лучше, по крайней мере в том смысле, в котором к тонким инструментам обычно применяется меньшая физическая сила, но как правило [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) смотрят на людей примерно так же, как люди смотрят на лошадей: они будут полезными, если их правильно направлять, некоторых могут даже ценить за редкие качества, но даже лучшая и наиболее дрессированная лошадь не будет считаться личностью. При этом случается и такое, что [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) «консультируются» рабом-врачом, если случится заболеть, либо с рабом-инженером прямо перед началом сложной фазы рытья тоннеля. Такая консультация нужна исключительно, чтобы убедиться, что нужные инструменты и материалы подготовлены для раскопок, а не чтобы получить квалифицированное заключение относительно произведённой великаном оценки надёжности конструкции.

Великаны, находящиеся на нижних ступенях в Уложении, назначаются управлять рабами или руководить разработкой шахты. Выкапывание штреков шахт и добыча руды — важная работа, но литьё и металлообработка ценятся выше, чем усилия по удержанию тоннеля от обрушения на головы рабов.`,
    source: {
      id: SOURCE_VGTM,
      page: 28,
    },
  },
  {
    header: 'Высокая цена',
    text: `Во многих случаях [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) отпускали рабов домой к семьям за выкуп, когда им становилось ясно, что у раба нет никаких необходимых великанам навыков, и когда обнаруживались желающие заплатить. Влиятельным пленника, таким как купцы и аристократы, наиболее вероятно повезет снискать такую участь по очевидным причинам. Требуемый выкуп редко состоит из побрякушек типа золота или бриллиантов: [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) предпочитают плату мифрилом, адамантином или другими рабами (более выносливыми или талантливыми).`,
    source: {
      id: SOURCE_VGTM,
      page: 28,
    },
  },
  ...giantDescriptionList,
]
