const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1_4,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KENKU,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CN,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_AURAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  GEAR_SHORTBOW,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  kenkuDescriptionList,
  kenkuNote,
} = require('./../../../../textCommonParts')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_KENKU,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Кенку',
  nameEn: 'Kenku',
  id: CREATURE_KENKU,
  description: kenkuDescriptionList,
  note: kenkuNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_KENKU,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 174,
  },
  armor: 13,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 10,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  languageList: [
    {
      id: [LANG_COMMON, LANG_AURAN],
      featureName: 'Подражание',
    },
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Мастер устраивать засады',
      description: `В первом раунде сражения ★СУЩЕСТВО★ совершает с преимуществом броски атаки по всем существам, которых ★он★ застал врасплох.`,
    },
    {
      name: 'Подражание',
      description: `★СУЩЕСТВО★ может подражать любым звукам, которые он уже слышал, включая голоса. Существо, слышащее эти звуки, может понять, что это подражание, если совершит успешную проверку Мудрости (Проницательность) СЛ 14.`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_SHORTBOW,
    },
  ],
  genderId: GENDER_MALE,
}
