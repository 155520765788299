const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_FROST_GIANT,
  CREATURE_KILLER_WHALE,
  CREATURE_KOBOLD,
  CREATURE_MAMMOTH,
  CREATURE_REMORHAZ,
} = require('./../../creatureIdList')

const colorDragonDescriptionList = require('./dragonColor')

module.exports = [
  {
    header: 'Логово белого дракона',
    text: `Логова белых драконов располагаются в ледяных пещерах и глубоких подземных палатах, укрытых от солнца. Они предпочитают высокогорные долины, приспособленные только для полёта, пещеры на отвесных утёсах и лабиринты ледяных пустот в ледниках. Белым драконам нравятся возвышенности в пещерах, подлетев к которым они могли бы, зацепившись, свеситься как [летучие мыши] или соскользнуть вниз по ледяным расщелинам.

Легендарная врождённая магия белых драконов усиливается холодом на территории вокруг их логова. Горные пещеры быстрее покрываются льдом в присутствии белых драконов. Белый дракон зачастую может обнаружить вторжение в его логово по изменившемуся там оттенку звука леденящего ветра.

Белый дракон лежит на ледяной возвышенности в своём логове, пол вокруг него — коварное месиво из битого льда и камня, скрытых ям и скользких склонов. Пока враги тратят силы на перемещение среди всего того, дракон парит над ними от навеса к навесу и уничтожает их своим замораживающим дыханием.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов; дракон не может использовать один и тот же эффект два раунда подряд:

* Ледяной туман заполняет сферу с радиусом 20 футов с центром на точке, видимой драконом в пределах 120 футов. Этот туман огибает углы и является сильно заслоняющей местностью. Все существа в области тумана, когда он появляется, должно пройти испытание Телосложения СЛ 10, получая урон холодом 10 (3к6) при провале, или половину этого урона при успехе. Порыв ветра силой 20 миль в час рассеивает туман. В противном случае туман держится, пока дракон не воспользуется этим действием логова ещё раз или не погибнет.
* Зазубренные осколки льда падают с потолка, попадая не более чем по трём существам под потолком, которые дракон видит в пределах 120 футов. Дракон совершает по одной дальнобойной атаке по каждой цели (+7 к попаданию). При попадании цель получает колющий урон 10 (3к6).
* Дракон создаёт непрозрачную стену изо льда на твёрдой поверхности, которую видит в пределах 120 футов. Стена может достигать 30 футов в длину и в высоту, и до 1 фута толщиной. Когда стена появляется, все существа в области, занимаемой ею, выталкивается на 5 футов на одну из сторон стены по собственному выбору. У каждой 10-футовой секции КД 5, 30 хитов, уязвимость к урону огнём и иммунитет к урону кислотой, некротической энергией, психической энергией, холодом и ядом. Стена исчезает, когда дракон снова совершает это действие логова или когда он погибает.

# Местные эффекты

Регион, в котором есть логово легендарного белого дракона, искажается драконьей магией, которая создаёт один или несколько следующих эффектов:

* Лёгкий морозный туман покрывает землю в пределах 6 миль от логова дракона.
* Ледяные осадки выпадают в пределах 6 миль от логова дракона, иногда формируя метель, когда дракон отдыхает.
* Ледяные стены перекрывают области драконьего логова. Каждая стена 6 дюймов в толщину, а её 10-футовые секции имеют КД 5, 15 хитов, уязвимость к урону огнём, иммунитет к урону кислотой, некротической энергией, психической энергией, холодом и ядом. Дракон может проходить сквозь такие стены без замедления, но это приводит к тому, что стены в таких местах рушатся.

Если дракон умирает, туман и осадки исчезают в течение одного дня. Ледяные стены тают в течение 1к10 дней.`,
    source: {
      id: SOURCE_MM,
      page: 105,
    },
  },
  {
    header: 'Белые драконы',
    text: `Меньшие, менее разумные и наиболее звероподобные из цветных драконов, белые драконы обитают в холодных условиях, предпочитая снежные области и ледяные горы. Эти драконы злобны, жестоки и ведомы голодом и жадностью.

Белые драконы отличаются диким взглядом, обтекаемой формой тела и острым хохолком. Чешуя вирмлингов белых драконов сверкает идеальной белизной. С возрастом их блеск исчезает, и некоторые из чешуек темнеют, к старости создавая бледно-голубые и светло-серые пятна. Такая расцветка помогает драконам во время охоты спрятаться среди льдов и камней или затеряться на фоне затянутого тучами неба.

# Дикие и мстительные

Белым драконам не хватает хитрости и тактики, присущей другим драконам. Но их звериная природа делает их лучшими охотниками среди драконьего племени, полностью сосредоточенными на выживании и уничтожении врагов. Белые драконы питаются только замороженной пищей, поглощая существ, убитых их дыханием, пока те всё ещё твёрдые и замороженные. Не съеденных убитых они заключают в лёд или закапывают в снег у логова. Обнаружение такого «запаса» служит верным знаком того, что белый дракон обитает поблизости.

Белые драконы также держат тела своих величайших врагов в качестве трофеев, замораживая их трупы на видных местах, чтобы после, глядя на них, злорадствовать. Останки великанов, [реморазов](CREATURE:${CREATURE_REMORHAZ}) и других драконов часто располагаются на виду в логове белого дракона, как предупреждение нарушителям.

Белые драконы, хотя и не наделены выдающимся интеллектом, но обладают экстраординарной памятью. Они помнят каждую мельчайшую обиду и поражение, которое они познали, и вершат зловещие расправы ради мести своим противникам. Ими часто оказываются серебряные драконы, чьи логова располагаются на схожих территориях. Белые драконы, как и другие драконы, могут говорить, но делают это с неохотой и лишь когда это крайне необходимо.

# Одиночки

Белые драконы избегают других драконов, кроме белых драконов противоположного пола. Но даже найдя себе пару, белые драконы держатся вместе лишь до тех пор, пока не зачнут потомков, и после опять отправляются в изоляцию.

Белые драконы не терпят соперников возле своего логова. Вследствие этого, белый дракон нападает на других существ без предупреждения, считая их либо слишком слабыми, либо слишком сильными, чтобы жить. Единственные существа, которые обычно служат белым драконам — это разумные гуманоиды, которые достаточно сильны, чтобы пережить гнев дракона, и смириться с регулярными потерями из-за его голода. Обычно это поклоняющиеся дракону [кобольды](CREATURE:${CREATURE_KOBOLD}), которые часто встречаются в драконьих логовах.

Могучие существа иногда подчиняют белых драконов с помощью физической или волшебной силы. [Ледяные великаны](CREATURE:${CREATURE_FROST_GIANT}) выходят на бой с белыми драконами, чтобы доказать свою силу и упрочить своё положение в клане. Обломки их костей устилают логова многих белых драконов. Тем не менее, белый дракон, побеждённый ледяным великаном, часто становится его слугой, принимая более могучее существо своим хозяином в обмен на утверждение своего собственного господства над другими существами, которые служат великану или выступают против него.

# Сокровища во льдах

Белые драконы любят холодный блеск льда и предпочитают сокровища, обладающие схожими свойствами, в частности — бриллианты. Однако в логовах, расположенных в ледяных удалённых местах, сокровища чаще состоят из бивней моржей и [мамонтов](CREATURE:${CREATURE_MAMMOTH}), скульптур из [китовых](CREATURE:${CREATURE_KILLER_WHALE}) костей, носовых украшений кораблей, шкур и волшебных предметов, захваченных у излишне смелых искателей приключений.

Монеты и драгоценные камни лежат, разбросанными по всему логову белого дракона, сверкая, как звезды, когда свет падает на них. Большие сокровища и сундуки покрыты слоями инея от дыхания белого дракона или находятся в безопасности под толстым слоем прозрачного льда. Огромная сила дракона позволяет ему легко добраться до своего богатства, в то время как более слабые существа должны будут потратить многие часы, раскалывая или растапливая лёд, чтобы добраться до основных сокровищ дракона.

Безупречная память белого дракона даёт ему возможность вспомнить, как он получил каждую монету, самоцвет и каждый магический предмет в своей сокровищнице, что связывает каждый предмет с определённой победой. Белых драконов, как известно, очень сложно подкупить, поскольку любые такие предложения воспринимаются как сомнение в их способности просто убить предлагающего откуп и присвоить его сокровища.`,
    source: {
      id: SOURCE_MM,
      page: 105,
    },
  },
  ...colorDragonDescriptionList,
]
