const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../../../genderList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {gearCollection} = require('./../../../../../gearList')
const {damageTypeCollection} = require('./../../../../../damageTypeList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    gearId,
    id,
  }
) => {
  const {
    nameByCase: {
      nominative,
      instrumental,
    },
    nameEn,
    genderId,
    damageType,
  } = gearCollection[gearId]

  const damageText = damageTypeCollection[damageType].nameByCase.genitive
  const {
    magical,
    pronoun,
    viciousName,
  } = {
    [GENDER_MALE]: {
      magical: 'магическим',
      pronoun: 'этим',
      viciousName: 'Жестокий',
    },
    [GENDER_FEMALE]: {
      magical: 'магической',
      pronoun: 'этой',
      viciousName: 'Жестокая',
    },
    [GENDER_MIDDLE]: {
      magical: 'магическим',
      pronoun: 'этим',
      viciousName: 'Жестокое',
    },
    [GENDER_MULTIPLE]: {
      magical: 'магическими',
      pronoun: 'этими',
      viciousName: 'Жестокие',
    },
  }[genderId]

  return {
    id,
    name: `${viciousName} ${nominative}`,
    nameEn: `Vicious ${nameEn}`,
    rarity: MGC_RARITY_RARE,
    type: MGC_TYPE_WEAPON,
    gearType: gearId,
    description: `Если у Вас при броске атаки ${pronoun} ${magical} ${instrumental} выпадает «20», цель получает дополнительно 7 ${damageText}.`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 163,
    },
  }
}

module.exports = generator
