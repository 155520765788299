const arrify = require('arrify')

const generateListReleasedBeforeAs = require('./../../utils/generateListReleasedBeforeAs')
const generatePcClassPageUrlById = require('./../../utils/generatePcClassPageUrlById')
const joinLastItem = require('./../../utils/joinLastItem')
const upLetter = require('./../../utils/upLetter')
const listToCollectionById = require('./../../utils/listToCollectionById')

const {domainCollection} = require('./../domainList')
const {pcClassCollection} = require('./../pcClassList')
const {sourceCollection} = require('./../sourceList')

const pcSubClassRawList = require('./pcSubClassRawList')

const pcSubClassList = pcSubClassRawList
  .map(
    pcSubClass => {
      const { domainId } = pcSubClass

      if (domainId) {
        const { nameFull: name, nameEn } = domainCollection[domainId]

        return {
          ...pcSubClass,
          name,
          nameEn,
        }
      }

      return pcSubClass
    },
  )
  .map(
    (pcSubClass, i, list) => {
      const { name, pcClassId: pcClassIdList } = pcSubClass
      const pcClassNameLinkList = joinLastItem(
        arrify(pcClassIdList).map(
          pcClassId => {
            const pcClassName = upLetter(pcClassCollection[pcClassId].name.singular.nominative)
            const pcClassLink = generatePcClassPageUrlById(pcClassId)
            return `[${pcClassName}](${pcClassLink})`
          }
        )
      )

      return {
        ...pcSubClass,
        isReady: Boolean(pcSubClass.isReady),
        fullName: `${pcClassNameLinkList}: ${upLetter(name)}`,
        isReReleased: Boolean(pcSubClass.releasedAs),
        releasedAs: pcSubClass.releasedAs || null,
        releasedBeforeAsList: generateListReleasedBeforeAs(list, pcSubClass.id),
      }
    },
  )
  .sort(
    ({ fullName: A }, { fullName: B }) => A > B
      ? 1
      : -1,
  )

module.exports = pcSubClassList

module.exports.pcSubClassReadyList = pcSubClassList.filter(({isReady}) => isReady)
module.exports.pcSubClassCollection = listToCollectionById(pcSubClassList)
module.exports.pcSubClassIdByClassIdCollection = pcSubClassList.reduce(
  (collection, {id, pcClassId: pcClassIdList}) => ({
    ...collection,
    ...arrify(pcClassIdList).reduce(
      (obj, pcClassId) => ({
        ...obj,
        [pcClassId]: [
          ...(obj[pcClassId] || []),
          id,
        ],
      }),
      collection
    ),
  }),
  {}
)

module.exports.pcClassIdBySubClassIdCollection = pcSubClassList.reduce(
  (collection, {id, pcClassId}) => ({
    ...collection,
    [id]: pcClassId,
  }),
  {}
)

module.exports.pcSubClassIdByDomainIdCollection = pcSubClassList.reduce(
  (collection, {id, domainId, source}) => {
    const isUA = arrify(source).every(
      item => {
        const sourceId = typeof item === 'string'
          ? item
          : item.id

        return sourceCollection[sourceId].isUA
      }
    )

    return domainId && (
      !isUA
      || (!collection[domainId] && isUA)
    )
      ? {
        ...collection,
        [domainId]: id,
      }
      : collection
  },
  {}
)
