const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SHAPESHIFTER,
  CREATURE_TYPE_YUAN_TI,
} = require('./../../../../creatureTypeIdList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_FEAR,
  SPELL_SUGGESTION,
} = require('./../../../../spellIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_LONGBOW,
  GEAR_SCIMITAR,
} = require('./../../../../gearIdList')
const {
  yuantiDescriptionList,
  yuantiNoteList,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_YUAN_TI_ABOMINATION} = require('./../../../../creatureIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Отродье юань-ти',
  nameEn: 'Yuan-ti Abomination',
  id: CREATURE_YUAN_TI_ABOMINATION,
  description: [
    `Отродья юань-ти — чудовищные змеи с крепкими гуманоидными торсами и руками. Отродья формируют наивысшую касту в обществе юань-ти, и они ближе остальных приблизились к расе, которую змеиные боги уготовали им. Они руководят подготовкой планов и выполнением тёмных обрядов в надежде однажды стать правителями мира.`,
    ...yuantiDescriptionList,
  ],
  note: yuantiNoteList,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_YUAN_TI,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 313,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 17,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 18,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_DRACONIC,
    LANG_COMMON,
  ],
  cr: CR_7,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в Большую змею или принять свой истинный облик. Во всех обликах ★его★ характеристики остаются теми же самыми. Всё несомое и носимое им снаряжение не превращается. ★Он★ не меняет облик, если умирает.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    comment: 'только в облике отродья',
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_ANIMAL_FRIENDSHIP,
            comment: `только змеи`,
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_SUGGESTION,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_FEAR,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в облике отродья',
      description: `★СУЩЕСТВО★ совершает две дальнобойные атаки или три рукопашные атаки, но _Укус_ и _Сжимание_ может использовать только по одному разу.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 3,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Сжимание',
      description: `Цель становится схваченной (СЛ высвобождения 14). Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может сжимать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Скимитар',
      // gearId: GEAR_SCIMITAR, // TODO: Найти способ показывать оружие с изменёнными костями
      comment: 'только в облике отродья',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Длинный лук',
      // gearId: GEAR_LONGBOW, // TODO: Найти способ показывать оружие с изменёнными костями
      comment: 'только в облике отродья',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 6,
        range: {
          normal: 150,
          max: 600,
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 3,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MIDDLE,
}
