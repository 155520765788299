const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_BLINDED} = require('./../../../../conditionList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_SKULK} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_HUMANOID} = require('./../../../../creatureTypeIdList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Скаллк',
  nameEn: 'Skulk',
  id: CREATURE_SKULK,
  description: [
    `Скаллки — это бездушные оболочки потерявшихся путешественников, блуждающих по серым пустошам Царства Теней до тех пор, пока не потеряли самоощущение. Они настолько лишены собственной личности, что стали невидимыми. Только дети могут видеть скаллков без помощи зеркал или специальной свечи. В тех редких случаях, когда скаллк становится видимым, он предстаёт в виде серого, безликого, безволосого гуманоида.`,
    {
      header: 'Призванные слуги',
      text: `Скаллк может быть призван из Царства Теней посредством ритуала. Если существу предоставляется часть личности призывающего, скаллк обязан подчинятся командам призывателя на протяжении 30 дней. Если скаллк видим, сообразительный наблюдатель может определить того, кто призвал скаллка, поскольку скаллк всегда имеет некое сходство со своим хозяином.

Жестокие и необузданные скаллки выполняют приказы наиболее свирепым образом. Призванный скаллк не вернётся в Царство Теней пока не умрёт, и поэтому у него есть мотивация учинять как можно больше резни и кровопролития.`,
      source: {
        id: SOURCE_MTOF,
        page: 227,
      },
    },
    {
      header: 'Пустая жизнь',
      text: `После убийства кого-либо в материальном мире, скаллк иногда принимает молчаливое подражание жизни этой личности. В крайних случаях, скалки вторгались в деревни, убивали всех обитателей, и превращали их в подобия городов призраков, где ежедневно готовили безвкусную еду, вывешивали на сушку бесцветную сухую одежду, а домашний скот перегоняли из загона в загон, пока животные не умирали от голода.`,
      source: {
        id: SOURCE_MTOF,
        page: 227,
      },
    },
  ],
  note: {
    text: `Иногда у детей есть вымышленные друзья, что невидимы их родителям. Иногда эти друзья невидимы, но не вымышлены.`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MTOF,
    page: 227,
  },
  armor: 14,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 10,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 1,
  },
  saveThrowList: [
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_RADIANT,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_COMMON,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Несовершенная невидимость',
      description: `Скаллк невидим. Такую невидимость можно развеять тремя способами:

* Скаллк виден как серый гладкокожий гуманоид, если смотреть на его отражение в зеркале или другой отражающей поверхности.
* Скаллк виден как тусклый полупрозрачный силуэт в свете свечи, сделанной из жира, собранного c трупа, чья личность неизвестна.
* Дети гуманоидов младше 10 лет могут видеть невидимого скаллка.`,
    },
    {
      name: 'Не оставляющий следов',
      description: `Скаллк не оставляет следов, по которым его можно было бы отследить или понять в какую сторону он движется.`,
    },
  ],
  actionList: [
    {
      name: 'Когти',
      description: `Если скаллк совершает бросок атаки с преимуществом, то цель также получает дополнительно 7 (2к6) урона некротической энергией.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
  ],
}
