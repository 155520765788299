module.exports = [
  require('./galeb_duhr'),
  require('./gargoyle'),
  require('./gas_spore'),
  require('./gelatinous_cube'),
  require('./ghast'),
  require('./ghost'),
  require('./ghoul'),
  require('./gibbering_mouther'),
  require('./githyanki_knight'),
  require('./githyanki_warrior'),
  require('./githzerai_monk'),
  require('./githzerai_zerth'),
  require('./glabrezu'),
  require('./gladiator'),
  require('./gnoll'),
  require('./gnoll_fang_of_yeenoghu'),
  require('./gnoll_pack_lord'),
  require('./gnome_deep'),
  require('./goat'),
  require('./goblin'),
  require('./goblin_boss'),
  require('./gorgon'),
  require('./goristro'),
  require('./gray_ooze'),
  require('./green_hag'),
  require('./grell'),
  require('./grick'),
  require('./grick_alpha'),
  require('./griffon'),
  require('./grimlock'),
  require('./guard'),
  require('./guardian_naga'),
  require('./gynosphinx'),
]
