const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  SPELL_SUMMON_ABERRATION,
} = require('./../../../../spellIdList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_BEHOLDER_KEEN,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DEEP_SPEECH,
  LANG_ITS_SUMMONER,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_N,
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BEHOLDERKIN_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух злобоглаза',
  nameEn: 'Beholdrekin Spirit',
  id: CREATURE_BEHOLDERKIN_SPIRIT,
  createdBySpellId: SPELL_SUMMON_ABERRATION,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_BEHOLDER_KEEN,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TCoE,
    page: 157,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `10-кратный круг заклинания`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 15,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  immunityList: [
    DAMAGE_PSYCHIC,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    {
      id: LANG_ITS_SUMMONER,
      doNotSpeak: true,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Луч из глаза',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 150,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
}
