const {GEAR_ROD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROD_OF_RULERSHIP} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ROD} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ROD_OF_RULERSHIP,
  name: `Жезл правления`,
  nameEn: `Rod of Rulership`,
  type: MGC_TYPE_ROD,
  gearType: GEAR_ROD,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Вы можете действием продемонстрировать жезл и потребовать послушания от всех существ на Ваш выбор, видимых Вами в пределах 120 футов. Каждая цель должна пройти испытание Мудрости СЛ 15, иначе она станет очарованной Вами на 8 часов. Будучи очарованным из-за этого эффекта, существо считает Вас своим истинным предводителем. Получив урон от Вас или Ваших спутников, или получив приказ, противоречащий её природе, цель перестаёт быть очарованной этим эффектом.

Жезл нельзя использовать повторно до следующего рассвета. `,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 162,
  },
}
