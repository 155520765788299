const {
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_RADIANT,
} = require('./../../../../damageTypeList')
const {
  CREATURE_AVENGER_SPIRIT,
  CREATURE_CELESTIAL_AVENGER_SPIRIT_2020_03_26,
  CREATURE_CELESTIAL_DEFENDER_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух небожителя-мстителя',
  nameEn: 'Celestial Avenger Spirit',
  id: CREATURE_CELESTIAL_AVENGER_SPIRIT_2020_03_26,
  releasedAs: CREATURE_AVENGER_SPIRIT,
  parentId: CREATURE_CELESTIAL_DEFENDER_SPIRIT_2020_03_26,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Светящийся лук',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: {
          value: 2,
          comment: `+ круг заклинания`,
        },
        range: {
          normal: 150,
          max: 600,
        },
        hit: {
          type: DAMAGE_RADIANT,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
          comment: ` + круг заклинания`,
        },
      },
    },
    {
      name: 'Целебное касание',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ прикасается к другому существу. Цель магическим образом восстанавливает 8 (2к8) + круг заклинания хитов.`,
    },
  ],
}
