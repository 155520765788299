const {LANG_SUBGROUP_ILLUSKI} = require('./../../languageSubGroupList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {
  LANG_ILLUSKAN,
  LANG_RUATHEN,
} = require('./../../languageIdList')
const {
  LANG_TYPE_DIALECT,
  LANG_TYPE_ETHNIC,
} = require('./../../languageTypeList')

module.exports = {
  id: LANG_RUATHEN,
  type: [
    LANG_TYPE_DIALECT,
    LANG_TYPE_ETHNIC,
  ],
  alphabetId: ALPHABET_THORASS,
  langSubGroupId: LANG_SUBGROUP_ILLUSKI,
  dialectOf: LANG_ILLUSKAN,
  name: {
    nominative: 'Руатен',
    genitive: 'Руатена',
    instrumental: 'Руатеном',
    prepositional: 'Руатене',
  },
  nameEn: 'Ruathen',
  isReady: false,
  isRealLang: true,
}
