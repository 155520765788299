const {PC_CLASS_MONK} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
} = require('./../../sourceList')
const {
  SPELL_ASTRAL_PROJECTION,
  SPELL_FIREBALL,
} = require('./../../spellIdList')
const PROGRESS_TABLE_ID = require('./../../PROGRESS_TABLE_ID')
const { CREATURE_DRAGON_BLUE_ANCIENT } = require('./../../creatureIdList')

const {
  abilityScoreImprovementTemplate,
  extraAttackTemplate,
} = require('./../featureCommonRawList')

const {
  GEAR_CLUB,
  GEAR_QUARTERSTAFF,
  GEAR_SHIELD,
  GEAR_SHORTSWORD,
  GEAR_SICKLE,
} = require('./../../gearIdList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_MONK,
  FEATURE_DEDICATED_WEAPON,
  FEATURE_DEFLECT_MISSILES,
  FEATURE_DIAMOND_SOUL,
  FEATURE_EMPTY_BODY,
  FEATURE_EVASION_MONK,
  FEATURE_EXTRA_ATTACK_MONK,
  FEATURE_FOCUSED_AIM,
  FEATURE_KI,
  FEATURE_KI_EMPOWERED_STRIKES,
  FEATURE_KI_FUELED_ATTACK,
  FEATURE_MARTIAL_ARTS,
  FEATURE_MONASTIC_TRADITION,
  FEATURE_PERFECT_SELF,
  FEATURE_PURITY_OF_BODY,
  FEATURE_QUICKENED_HEALING,
  FEATURE_SLOW_FALL,
  FEATURE_STILLNESS_OF_MIND,
  FEATURE_STUNNING_STRIKE,
  FEATURE_TIMELESS_BODY_MONK,
  FEATURE_TONGUE_OF_THE_SUN_AND_MOON,
  FEATURE_UNARMORED_DEFENSE_MONK,
  FEATURE_UNARMORED_MOVEMENT,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_UNARMORED_DEFENSE_MONK,
    name: `Защита без доспехов`,
    nameEn: `Unarmored Defense`,
    lvl: 1,
    text: `Если Вы не носите ни доспех, ни [щит](GEAR:${GEAR_SHIELD}), Ваш Класс Доспеха равен 10 + модификатор Ловкости + модификатор Мудрости.`,
    source: {
      id: SOURCE_PHB,
      page: 78,
    },
  },
  {
    id: FEATURE_MARTIAL_ARTS,
    name: `Боевые искусства`,
    nameEn: `Martial Arts`,
    lvl: 1,
    text: `Ваше знание боевых искусств позволяет Вам эффективно использовать в бою безоружные удары и оружие монахов — [короткие мечи](GEAR:${GEAR_SHORTSWORD}), а также любое простое оружие, не имеющее свойств «двуручное» и «тяжёлое».

Если Вы безоружны или используете только монашеское оружие, и не носите ни доспехов, ни [щита](GEAR:${GEAR_SHIELD}), Вы получаете следующие преимущества:

* Вы можете использовать Ловкость вместо Силы для бросков атак и урона Ваших безоружных ударов и атак монашеским оружием.
* Вы можете использовать к4 вместо обычной кости урона Ваших безоружных ударов или атак монашеским оружием. Эта кость увеличивается с Вашим уровнем, как показано в колонке «[Боевые искусства](PC_CLASS:${PC_CLASS_MONK}#${PROGRESS_TABLE_ID})».
* Если в свой ход Вы используете действие Атака для безоружной атаки, или атаки монашеским оружием, Вы можете совершить ещё одну безоружную атаку бонусным действием. Например, если Вы совершили действие Атака и атаковали [боевым посохом](GEAR:${GEAR_QUARTERSTAFF}), Вы можете совершить бонусным действием безоружную атаку, при условии, что в этом ходу Вы ещё не совершали бонусное действие.

Некоторые монастыри используют особые виды монашеского оружия. Например, Вы можете использовать [дубинку](GEAR:${GEAR_CLUB}) в виде двух деревянных брусков, соединённых короткой цепью (такое оружие называется нунчаками), или [серп](GEAR:${GEAR_SICKLE}) с более коротким и прямым лезвием (называется камой).

Как бы ни называлось Ваше монашеское оружие, Вы используете характеристики, соответствующие этому оружию.`,
    source: {
      id: SOURCE_PHB,
      page: 79,
    },
  },
  {
    id: FEATURE_KI,
    name: `Ци`,
    nameEn: `Ki`,
    lvl: 2,
    text: `Ваши тренировки позволяют Вам управлять мистической энергией Ци.

Ваш доступ к этой энергии выражается количеством очков Ци. Ваш уровень монаха определяет это количество, согласно колонке «[Очки Ци](PC_CLASS:${PC_CLASS_MONK}#${PROGRESS_TABLE_ID})».

Вы можете использовать эти очки чтобы активировать различные умения Ци. Вначале Вам известны следующие три умения: _Поступь ветра_, _Терпеливая оборона_ и _Шквал ударов_. С повышением уровня в этом классе Вы выучите новые умения Ци.

Все потраченные очки Ци восполняются по окончании короткого или длинного отдыха. Вы должны потратить как минимум 30 минут на медитацию для их восстановления.

Некоторые из Ваших умений Ци требуют от цели пройти испытание, позволяющее избежать эффекта. СЛ такого испытания определяется следующим образом:

**СЛ испытания Ци = 8 + бонус мастерства + модификатор Мудрости**

# Поступь ветра

Вы можете потратить 1 очко Ци в свой ход, чтобы совершить бонусным действием Отход или Рывок. В этот ход дальность Ваших прыжков удваивается.

# Терпеливая оборона

Вы тратите 1 очко Ци в свой ход, чтобы совершить бонусным действием Уклонение. 

# Шквал ударов

Сразу же после того, как Вы в свой ход совершили действие Атака, Вы можете потратить 1 очко Ци, чтобы совершить бонусным действием две безоружные атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 79,
    },
  },
  {
    id: FEATURE_DEDICATED_WEAPON,
    name: `Выбранное оружие`,
    nameEn: `Dedicated Weapon`,
    lvl: 2,
    text: `Вы тренируетесь использовать как монашеское оружие не только простое рукопашное оружие и короткие мечи, но и многие другие виды оружия.

Заканчивая короткий или длинный отдых, Вы можете прикоснуться к одному оружию, фокусируя на нём свою Ци и после этого считать его монашеским оружием, пока Вы не снова не используете эту способность.

Выбранное оружие должно соответствовать требованиям:

* Оружие должно быть простым или воинским.
* Вы должны им владеть.
* Оружие не должно быть тяжёлым или особым.
`,
    source: {
      id: SOURCE_TCoE,
      page: 70,
    },
  },
  {
    id: FEATURE_UNARMORED_MOVEMENT,
    name: `Движение без доспехов`,
    nameEn: `Unarmored Movement`,
    lvl: [2, 9],
    text: `Ваша скорость увеличивается на 10 футов, если Вы не носите доспехов и [щит](GEAR:${GEAR_SHIELD}). Этот бонус увеличивается с ростом Вашего уровня, как показано в [таблице](PC_CLASS:${PC_CLASS_MONK}#${PROGRESS_TABLE_ID}).

На 9 уровне Вы получаете возможность в свой ход перемещаться по вертикальным поверхностям и по поверхности жидкости, не падая во время движения.`,
    source: {
      id: SOURCE_PHB,
      page: 79,
    },
  },
  {
    id: FEATURE_MONASTIC_TRADITION,
    name: `Монастырская традиция`,
    nameEn: `Monastic Tradition`,
    lvl: [3, 6, 11, 17],
    text: `Вы выбираете монастырскую традицию, которой следуете.

Выбранная традиция обеспечивает Вам дополнительные умения на 3, 6, 11 и 17 уровнях.`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 79,
    },
  },
  {
    id: FEATURE_DEFLECT_MISSILES,
    name: `Отражение снарядов`,
    nameEn: `Deflect Missiles`,
    lvl: 3,
    text: `Вы можете реакцией отразить или поймать снаряд, если по Вам попали атакой дальнобойным оружием. Если Вы делаете это, урон снижается на 1к10 + модификатор Ловкости + уровень монаха.

Если Вы снизили урон до 0, Вы можете поймать снаряд в случае, если он достаточно мал, чтоб держать его одной рукой, и одна из ваших рук свободна. Если Вы поймали снаряд с помощью этого умения, Вы можете потратить одно очко Ци, чтобы частью реакции совершить дальнобойную атаку пойманным оружием или боеприпасом с дистанцией 20/60 футов. Вы совершаете эту атаку с владением, вне зависимости от владения данным оружием, и этот снаряд считается для данной атаки монашеским оружием.`,
    source: {
      id: SOURCE_PHB,
      page: 79,
    },
  },
  {
    id: FEATURE_KI_FUELED_ATTACK,
    name: `Наполненный Ци удар`,
    nameEn: `Ki-Fueled Attack`,
    lvl: 3,
    text: `Если Вы потратили 1 или больше очков Ци как часть действия на своём ходу, Вы можете до конца хода бонусным действием совершить одну безоружную атаку или атаку монашеским оружием.`,
    source: {
      id: SOURCE_TCoE,
      page: 70,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_MONK,
    source: {
      id: SOURCE_PHB,
      page: 79,
    },
  },
  {
    id: FEATURE_QUICKENED_HEALING,
    name: `Ускоренное исцеление`,
    nameEn: `Quickened Healing`,
    lvl: 4,
    text: `Вы можете действием потратить 2 очка Ци и бросить кость боевых искусств.

Вы восстанавливаете количество хитов, равное результату броска + Ваш бонус мастерства.`,
    source: {
      id: SOURCE_TCoE,
      page: 70,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_MONK,
    source: {
      id: SOURCE_PHB,
      page: 79,
    },
  },
  {
    id: FEATURE_SLOW_FALL,
    name: `Медленное падение`,
    nameEn: `Slow Fall`,
    lvl: 4,
    text: `Если Вы падаете, то можете реакцией уменьшить урон от падения на значение, равное Вашему уровню монаха, умноженному на пять.`,
    source: {
      id: SOURCE_PHB,
      page: 79,
    },
  },
  {
    id: FEATURE_STUNNING_STRIKE,
    name: `Ошеломляющий удар`,
    nameEn: `Stunning Strike`,
    lvl: 5,
    text: `Вы можете взаимодействовать с энергией Ци, текущей в теле вашего противника.

Если Вы попали по другому существу атакой рукопашным оружием, Вы можете потратить 1 [очко Ци](FEATURE:${FEATURE_KI}), чтобы нанести ошеломляющий удар. Цель должна пройти испытание Телосложения, иначе станет ошеломлённой до конца Вашего следующего хода. `,
    source: {
      id: SOURCE_PHB,
      page: 79,
    },
  },
  {
    id: FEATURE_FOCUSED_AIM,
    name: `Точный прицел`,
    nameEn: `Focused Aim`,
    lvl: 5,
    text: `Когда Вы промахиваетесь атакой, то можете потратить от 1 до 3 очков Ци, увеличивая бросок атаки на 2 за каждое потраченное очко Ци, что потенциально может превратить промах в попадание.`,
    source: {
      id: SOURCE_TCoE,
      page: 70,
    },
  },
  {
    id: FEATURE_KI_EMPOWERED_STRIKES,
    name: `Наполненные Ци удары`,
    nameAlt: `Энергетические удары`,
    nameEn: `Ki-Empowered Strikes`,
    lvl: 6,
    text: `Ваши безоружные удары считаются магическими при определении преодоления сопротивления и иммунитета к немагическим атакам и урону.`,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
  {
    id: FEATURE_EVASION_MONK,
    name: `Увёртливость`,
    nameEn: `Evasion`,
    lvl: 7,
    text: `Ваше инстинктивное проворство позволяет уклоняться от эффектов, направленных на определённую область, вроде дыхания [синего дракона](CREATURE:${CREATURE_DRAGON_BLUE_ANCIENT}) или заклинания [Огненный шар](SPELL:${SPELL_FIREBALL}).

Если Вы попадаете под действие эффекта, позволяющего пройти испытание Ловкости, чтобы получить только половину урона, Вы вместо этого не получаете урона при успехе и получаете только половину урона при провале.`,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
  {
    id: FEATURE_STILLNESS_OF_MIND,
    name: `Спокойствие разума`,
    nameEn: `Stillness of Mind`,
    lvl: 7,
    text: `Вы можете действием окончить один из действующих на Вас эффектов, делающих Вас очарованным или испуганным. `,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
  {
    id: FEATURE_PURITY_OF_BODY,
    name: `Чистота тела`,
    nameEn: `Purity of Body`,
    lvl: 10,
    text: `Ваше мастерство Ци даёт Вам иммунитет к болезням и яду.`,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
  {
    id: FEATURE_TONGUE_OF_THE_SUN_AND_MOON,
    name: `Язык Солнца и Луны`,
    nameEn: `Tongue of the Sun and Moon`,
    lvl: 13,
    text: `Вы понимаете, как взаимодействовать с энергией Ци в чужом разуме, и теперь Вы понимаете речь на любом языке.

Кроме того, все существа, способные понимать хотя бы один язык, понимают то, что Вы сказали.`,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
  {
    id: FEATURE_DIAMOND_SOUL,
    name: `Алмазная душа`,
    nameEn: `Diamond Soul`,
    lvl: 14,
    text: `Ваше мастерство Ци предоставляет Вам владение всеми испытаниями.

Кроме того, если Вы провалили испытание, Вы можете повторить его, потратив 1 [очко Ци](FEATURE:${FEATURE_KI}), и должны использовать второй результат.`,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
  {
    id: FEATURE_TIMELESS_BODY_MONK,
    name: `Безвременное тело`,
    nameEn: `Timeless Body`,
    lvl: 16,
    text: `Ваша Ци поддерживает Вас, и Ваше тело больше не подвержено признакам старения.

Вы не можете быть состарены магически. Впрочем, Вы всё ещё можете умереть от старости. Кроме того, Вам больше не требуется еда и вода.`,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
  {
    id: FEATURE_EMPTY_BODY,
    name: `Пустое тело`,
    nameEn: `Empty Body`,
    lvl: 18,
    text: `Вы можете действием потратить 4 [очка Ци](FEATURE:${FEATURE_KI}), чтобы стать невидимым на 1 минуту. В течение этого времени Вы получаете сопротивление ко всем видам урона, кроме урона силовым полем.

Кроме того, Вы можете потратить 8 [очков Ци](FEATURE:${FEATURE_KI}), чтобы использовать заклинание [Проекция в астрал](SPELL:${SPELL_ASTRAL_PROJECTION}) без применения материальных компонентов. Вы не можете перемещать кого-либо вместе с собой.`,
    spellIdList: SPELL_ASTRAL_PROJECTION,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
  {
    id: FEATURE_PERFECT_SELF,
    name: `Совершенство`,
    nameEn: `Perfect Self`,
    lvl: 20,
    text: `Если при броске инициативы у Вас нет [очков Ци](FEATURE:${FEATURE_KI}), то Вы получаете 4 таких очка.`,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_MONK,
  })
)
