const {SOURCE_MM} = require('./../../sourceList')
const {CREATURE_TYPE_GIANT} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GIANT,
  nameEn: 'Giant',
  genderId: GENDER_MALE,
  description: {
    text: `Великаны возвышаются над людьми и им подобными. Телом они похожи на людей, хотя у некоторых могут быть уродства (фоморы) или несколько голов (эттины). Существует шесть разновидностей истинных великанов: холмовые, каменные, ледяные, огненные, облачные и штормовые. Также к этому виду относятся огры и тролли.`,
    source: {
      id: SOURCE_MM,
      page: 8,
    },
  },
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'великан',
        genitive: 'великана',
        dative: 'великану',
        accusative: 'великана',
        instrumental: 'великаном',
        prepositional: 'великане',
      },
      [GENDER_FEMALE]: {
        nominative: 'великанша',
        genitive: 'великанши',
        dative: 'великанше',
        accusative: 'великаншу',
        instrumental: 'великаншей',
        prepositional: 'великанше',
      },
    },
    plural: {
      nominative: 'великаны',
      genitive: 'великанов',
      dative: 'великанам',
      accusative: 'великанов',
      instrumental: 'великанами',
      prepositional: 'великанах',
    },
  },
}
