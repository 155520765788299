const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {CR_1} = require('./../../../../crList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {SPEED_WALK} = require('./../../../../speedList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {GENDER_MALE} = require('./../../../../genderList')
const {CREATURE_DEATH_DOG} = require('./../../../../creatureIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Пёс смерти',
  nameEn: 'Death Dog',
  id: CREATURE_DEATH_DOG,
  description: `Пёс смерти это уродливая двуглавая гончая, обитающая на равнинах, в пустынях и в Подземье. Ненависть пылает в его сердце, а любовь к пожиранию гуманоидной плоти заставляет нападать на путников и исследователей. Слюна пса смерти переносит болезнь, из-за которой плоть жертвы медленно отгнивает от костей.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 336,
  },
  armor: 12,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Двуглавость',
      description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), а также испытания от глухоты, испуги, лишения сознания, ослепления, очарования и ошеломления.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Укусом_`,
    },
    {
      name: 'Укус',
      description: `Если цель — существо, она должна пройти испытание Телосложения СЛ 12 от болезни, иначе станет отравленной, пока не вылечится от болезни. За каждые прошедшие 24 часа существо должно повторить это испытание, уменьшая максимум хитов на 5 (1к10) при провале. Это уменьшение длится до тех пор, пока болезнь не будет вылечена. Существо умирает, если болезнь уменьшает максимум хитов до 0.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
