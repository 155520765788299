const {
  SOURCE_MOOT,
} = require('./../../../../sourceList')
const {
  CR_4,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CELESTIAL,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  LANG_CELESTIAL,
} = require('./../../../../languageIdList')
const {
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_CHARGE,
} = require('./../../../../creatureAbilityList')
const {
  archonMountsDescription,
} = require('./../../../../textCommonParts')
const {
  CREATURE_WINGED_BULL,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Крылатый бык',
  nameEn: 'Winged Bull',
  id: CREATURE_WINGED_BULL,
  description: archonMountsDescription,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: CREATURE_TYPE_CELESTIAL,
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MOOT,
    page: 215,
  },
  armor: 12,
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 60,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  languageList: [
    {
      id: LANG_CELESTIAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_4,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Боданием',
      damageText: '19 (3к12) колющего урона',
    },
  ],
  actionList: [
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 12,
          diceBonus: 5,
        },
      },
    },
  ],
}
