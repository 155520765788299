module.exports = [
  {
    text: `Мы, гитцераи, жаждем вызова, чтобы быть готовыми к тому дню, когда Зертимон вернётся к нам. Посему мы отправились в воющий Либо, чтобы сделать его нашим новым домом.`,
    author: `Учение Меняр-Ага`,
  },
  {
    text: `Зертимон не ждёт, что мы будем терпеливо ждать его возвращения. Вместо этого мы должны дать путь его усилиям, тем самым приблизив наш золотой век.`,
    author: `Учение Меняр-Ага`,
  },
  {
    text: `Не могу с уверенностью утверждать, что лидер гитцераев жив, мёртв или стал нежитью. Но псионическое давление, которое я ощущал во время аудиенции с ним, было столь велико, что я едва мог думать. Интересно, это давление создаёт сам Меняр-Аг-Гит или дюжины последователей, окружающих его тело? Увы, я остался с этой мыслью и неотвеченным вопросом.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Я обучался какое-то время с гитцераями в Лимбо. Их адамантовые цитадели вдохновили меня на создание одного заклинания.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Разум не познавший себя, ущербен. Ущербный разум делает ущербным своего носителя. Ущербный носитель делает ущербным всё, чего касается. Ибо сказано, что руки ущербных портят всё, на что глядят их глаза. Именно такова Гит. Вместо следования её пути, помни, что сила — в познании себя. В познании себя, своего истинного центра, надлежит вам трудиться и набираться сил.`,
    author: `Зертимон`,
  },
]
