const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  SPELL_DETECT_THOUGHTS,
  SPELL_DISGUISE_SELF,
  SPELL_SUGGESTION,
  SPELL_TELEPORT,
} = require('./../../../../../spellIdList')
const {
  CREATURE_RIDING_HORSE,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_HAT_OF_DISGUISE,
  MAGIC_ITEM_HEADBAND_OF_INTELLECT,
  MAGIC_ITEM_HELM_OF_TELEPATHY,
  MAGIC_ITEM_HELM_OF_TELEPORTATION,
  MAGIC_ITEM_HORN_OF_BLASTING,
  MAGIC_ITEM_HORSESHOES_OF_A_ZEPHYR,
  MAGIC_ITEM_HORSESHOES_OF_SPEED,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_HELM_OF_TELEPORTATION,
    name: `Шлем телепортации`,
    nameEn: `Helm of Teleportation`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `У этого шлема есть 3 заряда. Если Вы его носите, Вы можете действием потратить 1 заряд, чтобы наложить им заклинание [Телепортация](SPELL:${SPELL_TELEPORT}). Шлем ежедневно восстанавливает 1к3 заряда на рассвете.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_TELEPORT],
    source: {
      id: SOURCE_DMG,
      page: 214,
    },
  },
  {
    id: MAGIC_ITEM_HELM_OF_TELEPATHY,
    name: `Шлем телепатии`,
    nameEn: `Helm of Telepathy`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите этот шлем, Вы можете действием сотворять им заклинание [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) (СЛ испытания 13). Пока Вы поддерживаете концентрацию на этом заклинании, Вы можете бонусным действием отправить телепатическое послание существу, на котором Вы сосредоточились. Оно может ответить бонусным действием, если Вы продолжите сосредотачиваться на нём.

Сосредоточившись на существе обнаружением мыслей, Вы можете действием наложить шлемом на него заклинание [Внушение](SPELL:${SPELL_SUGGESTION}) (СЛ испытания 13). Вы не можете использовать эту способность шлема повторно до следующего рассвета.`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_DETECT_THOUGHTS,
      SPELL_SUGGESTION,
    ],
    source: {
      id: SOURCE_DMG,
      page: 213,
    },
  },
  {
    id: MAGIC_ITEM_HAT_OF_DISGUISE,
    name: `Шапка маскировки`,
    nameEn: `Hat of Disguise`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите эту шапку, Вы можете неограниченно действием сотворять ей заклинание [Маскировка](SPELL:${SPELL_DISGUISE_SELF}). Это заклинание оканчивается, если снять шапку.`,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_DISGUISE_SELF],
    source: {
      id: SOURCE_DMG,
      page: 212,
    },
  },
  {
    id: MAGIC_ITEM_HORN_OF_BLASTING,
    name: `Рог взрыва`,
    nameEn: `Horn of Blasting`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Вы можете действием произнести командное слово рога и подуть в него, испуская взрыв 30-футовым конусом, слышимый на расстоянии 600 футов. Все существа в конусе должны пройти испытание Телосложения СЛ 15. При провале существо получает урон звуком 5к6 и становится оглохшим на 1 минуту. При успехе существо получает половину урона и не становится оглохшим. Существа и предметы, изготовленные из стекла или кристаллов, получают помеху к испытанию и получают урон звуком 10к6, а не 5к6.

При каждом использовании магии рога существует 20-процентный шанс, что он взорвётся. Взрыв причиняет урон огнём 10к6 тому, кто в него дул, и уничтожает рог.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 198,
    },
  },
  {
    id: MAGIC_ITEM_HORSESHOES_OF_SPEED,
    name: `Подковы скорости`,
    nameEn: `Horseshoes of Speed`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Эти железные подковы изготавливаются комплектом по четыре штуки.

Если все четыре прикрепить к копытам [лошади](CREATURE:${CREATURE_RIDING_HORSE}) или подобного существа, они увеличивают скорость ходьбы этого существа на 30 футов.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 192,
    },
  },
  {
    id: MAGIC_ITEM_HORSESHOES_OF_A_ZEPHYR,
    name: `Подковы ветра`,
    nameEn: `Horseshoes of a Zephyr`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    description: `Эти железные подковы изготавливаются комплектом по четыре штуки.

Если все четыре прикрепить к копытам [лошади](CREATURE:${CREATURE_RIDING_HORSE}) или подобного существа, они позволят ему перемещаться как обычно, но паря в 4 дюймах от земли. Этот эффект позволяет существу пересекать нетвёрдые и неустойчивые субстанции, такие как вода и лава, а также стоять на них. Существо не оставляет следы и игнорирует труднопроходимую местность. Кроме того, оно может перемещаться с обычной скоростью до 12 часов в день, не получая истощение за форсированный марш.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 192,
    },
  },
  {
    id: MAGIC_ITEM_HEADBAND_OF_INTELLECT,
    name: `Повязка интеллекта`,
    nameEn: `Headband of Intellect`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Значение Вашего Интеллекта равно 19, пока Вы носите эту повязку. Она не оказывает на Вас эффект, если Ваш Интеллект без неё и так уже 19 или выше.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 191,
    },
  },
]
