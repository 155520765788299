const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_BRONTOSAURUS} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {dinoDescriptionList} = require('./../../../../textCommonParts')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Бронтозавр',
  nameEn: 'Brontosaurus',
  id: CREATURE_BRONTOSAURUS,
  description: [
    `Этот массивный четвероногий динозавр достаточно велик, чтобы большинство хищников оставили его в покое. Его смертоносных хвост может отвадить или убить небольшие угрозы.`,
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: [
    {
      id: SOURCE_VGTM,
      page: 150,
    },
    {
      id: SOURCE_TOA,
      page: 215,
    },
  ],
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 17,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  saveThrowList: [
    PARAM_CON,
  ],
  cr: CR_5,
  actionList: [
    {
      name: 'Топот',
      description: `Цель должна успешно пройти испытание Силы СЛ 14 или оказаться сбитой с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 20,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 5,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 20,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 6,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
  ],
}
