const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GNOLL,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_GNOLL,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_HIDE_ARMOR,
    GEAR_LONGBOW,
    GEAR_SHIELD,
    GEAR_SPEAR,
  } = require('./../../../../gearIdList'),
  {
    gnollDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_RAMPAGE,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_GNOLL,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гнолл',
  nameEn: 'Gnoll',
  id: CREATURE_GNOLL,
  description: gnollDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GNOLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 61,
  },
  armor: {
    ac: 15,
    gearId: [GEAR_HIDE_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 11,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GNOLL,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_RAMPAGE,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_SPEAR,
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
  genderId: GENDER_MALE,
}
