const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS} = require('./../../../pcSubClassIdList')
const {
  SOURCE_TCoE,
} = require('./../../../sourceList')
const {
  SPELL_ALARM,
  SPELL_AURA_OF_PURITY,
  SPELL_BANISHMENT,
  SPELL_COUNTERSPELL,
  SPELL_DETECT_MAGIC,
  SPELL_HOLD_MONSTER,
  SPELL_MOONBEAM,
  SPELL_NONDETECTION,
  SPELL_SCRYING,
  SPELL_SEE_INVISIBILITY,
} = require('./../../../spellIdList')
const {
  FEATURE_AURA_OF_THE_SENTINEL,
  FEATURE_CHANNEL_DIVINITY_ABJURE_THE_EXTRAPLANAR,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CHANNEL_DIVINITY_WATCHER_S_WILL,
  FEATURE_MORTAL_BULWARK,
  FEATURE_OATH_OF_WATCHERS_SPELLS,
  FEATURE_VIGILANT_REBUKE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_WATCHERS_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_ALARM,
      SPELL_AURA_OF_PURITY,
      SPELL_BANISHMENT,
      SPELL_COUNTERSPELL,
      SPELL_DETECT_MAGIC,
      SPELL_HOLD_MONSTER,
      SPELL_MOONBEAM,
      SPELL_NONDETECTION,
      SPELL_SCRYING,
      SPELL_SEE_INVISIBILITY,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 79,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_WATCHER_S_WILL,
    name: `Божественный канал: Воля стража`,
    nameEn: `Channel Divinity: Watcher’s Will`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), чтобы наделить свое присутствие защитной силой своей веры.

Вы можете действием выбрать существ, видимых Вами в пределах 30 футов, количеством до Вашего модификатора Харизмы (минимум 1). В течение 1 минуты Вы и выбранные существа получаете преимущество к испытаниям Интеллекта, Мудрости и Харизмы.`,
    source: {
      id: SOURCE_TCoE,
      page: 80,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_ABJURE_THE_EXTRAPLANAR,
    name: `Божественный канал: Наказание внешнепланарных`,
    nameEn: `Channel Divinity: Abjure the Extraplanar`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), чтобы бичевать потусторонних существ.

Вы действием демонстрируете свой священный символ, и каждая аберрация, исчадие, небожитель, фея или элементаль в пределах 30 футов, которые могут Вас слышать, должны пройти испытание Мудрости. При провале существо изгоняется на 1 минуту или пока не получит урон.

Изгнанное существо должно тратить свои ходы, пытаясь уйти от Вас как можно дальше, и не может добровольно переместиться в пространство, находящееся в пределах 30 футов от Вас. Действием существо может использовать только Рывок или пытаться освободиться от эффекта, препятствующего его передвижению. Если двигаться некуда, существо может использовать действие Уклонение.`,
    source: {
      id: SOURCE_TCoE,
      page: 80,
    },
  },
  {
    id: FEATURE_AURA_OF_THE_SENTINEL,
    name: `Аура часового`,
    nameEn: `Aura of the Sentinel`,
    lvl: [7, 18],
    text: `Вы излучаете ауру бдительности, пока дееспособны.

Когда Вы и любые существа по Вашему выбору в пределах 10 футов бросаете инициативу, все Вы получаете бонус к инициативе, равный Вашему бонусу мастерства.

На 18 уровне дальность действия этой ауры увеличивается до 30 футов.`,
    source: {
      id: SOURCE_TCoE,
      page: 80,
    },
  },
  {
    id: FEATURE_VIGILANT_REBUKE,
    name: `Бдительное возмездие`,
    nameEn: `Vigilant Rebuke`,
    lvl: 15,
    text: `Вы научились наказывать любого, кто осмеливатся запутывать Вас и Ваших подопечных.

Каждый раз, когда Вы или видимое Вами в пределах 30 футов существо, преуспевает в испытании Интеллекта, Мудрости или Харизмы, Вы можете реакцией нанести 2к8 + Ваш модификатор Харизмы урона силовым полем существу, вызвавшему то испытание.`,
    source: {
      id: SOURCE_TCoE,
      page: 80,
    },
  },
  {
    id: FEATURE_MORTAL_BULWARK,
    name: `Смертный оплот`,
    nameEn: `Mortal Bulwark`,
    lvl: 20,
    text: `Вы проявляете искру божественной силы, защищая царства смертных.

Вы можете бонусным действием получить на 1 минуту следующие преимущества:

* Вы получаете истинное зрение в пределах 120 футов.
* Вы получаете преимущество к броскам атаки по аберрациям, исчадиям, небожителям, феям и элементалям.
* Когда Вы попадаете по существу атакой и наносите ему урон, Вы можете заставить его пройти испытание Харизмы. При провале существо магически изгоняется на свой родной план (если только оно уже там не находится). При успехе существо не может быть изгнано этой способностью в течение следующих 24 часов.

Использовав эту способность, Вы не сможете использовать её снова, пока не закончите длинный отдых, если только не потратите ячейку заклинания 5 круга, чтобы использовать его снова.`,
    source: {
      id: SOURCE_TCoE,
      page: 81,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS,
  })
)

