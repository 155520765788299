const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  GOD_SSETH,
  GOD_DENDAR,
  GOD_MERRSHAULK,
} = require('./../../godIdList')
const snakeGods = require('./snakeGods')

module.exports = [
  {
    header: 'Юань-ти',
    text: `Юань-ти — хитрый змеиный народ, лишённый сострадания. В далёких храмах, построенных в джунглях, болотах и пустынях, юань-ти строят планы о том, как будут господствовать над остальными расами, а самих себя сделают богами.`,
    source: {
      id: SOURCE_MM,
      page: 312,
    },
  },
  {
    header: 'Потерянная человечность',
    text: `Юань-ти раньше были людьми, которые процветали на заре цивилизации и поклонялись змеям как тотемным животным. Они превозносили змеиную гибкость, расчётливое самообладание и смертоносность. Их передовая философия учила, что добродетель состоит в отрешённости от эмоций и в ясных сосредоточенных мыслях.

Культура юань-ти была одной из богатейших в мире смертных. Их воины были легендарны, их империи всегда расширялись. Храмы юань-ти стояли в центре древних метрополий, вознося молитвы богам, которым они стремились подражать. Со временем змеиные боги услышали эти молитвы, и их шипящие голоса ответили из тьмы, сказав юань-ти, что те должны делать. Религия юань-ти становилась всё более фанатичной в своём выражении. Культы начинали поклоняться змеиным богам и подражали им, практикуя каннибализм и человеческие жертвоприношения. Посредством грязного колдовства юань-ти скрещивались со змеями, полностью жертвуя своей человечностью, чтобы стать полностью подобными змеиным богам как внешне, так и в мыслях и эмоциях.`,
    source: {
      id: SOURCE_MM,
      page: 312,
    },
  },
  {
    header: 'Змеиные короли павших империй',
    text: `Юань-ти рассматривали свою физическую трансформацию как торжественный момент в истории своей расы, позволяющий сбросить хрупкую человечность как мёртвую кожу. Те, кто не трансформировались, стали рабами или едой для благословлённых змеиными богами. Но империи юань-ти распались или были побеждены теми, кто сражался против рабства и каннибализма, и змеиный народ покинул руины своих великих столиц, уединившись от других рас.`,
    source: {
      id: SOURCE_MM,
      page: 312,
    },
  },
  {
    header: 'Холод сердца',
    text: `Человеческие эмоции чужды большинству юань-ти, которые понимают настроение только как уязвимость, которую можно эксплуатировать. Юань-ти рассматривают мир и события своей собственной жизни с таким экстремальным прагматизмом, что ими почти невозможно манипулировать, влиять или контролировать немагическими способами, в то время как сами они стремятся контролировать других существ через ужас, удовольствие или благоговение.

Юань-ти знают, что мир, которым они надеются управлять, не может быть удержан надолго грубой силой, и что многие существа будут отказываться служить. Поэтому юань-ти сперва влияют на других существ обещаниями богатства и силы. Снова и снова гуманоидные культуры делают фатальную ошибку, доверившись юань-ти. Они забывают, что те случаи, когда юань-ти действуют благородно или оказывают помощь в тяжёлое время, являются лишь деталями грандиозного замысла.

Лидеры юань-ти — хитрые и безжалостные тактики, охотно жертвующие меньшими юань-ти, если возможная победа оправдает эти потери. Им чужд честный бой, и они при любой возможности устраивают засады.`,
    source: {
      id: SOURCE_MM,
      page: 312,
    },
  },
  {
    header: 'Фальшивое поклонение',
    text: `Жизни юань-ти вращается вокруг их храмов, однако юань-ти не любят богов, которым они поклоняются. Вместо этого они рассматривают поклонение как способ достижения силы. Юань-ти верят, что индивид, у которого окажется достаточно силы, сможет поглотить и заменить одного из богов юань-ти. Юань-ти стремятся к вознесению, и готовы совершить самые ужасные злодейства, чтобы его достичь.`,
    source: {
      id: SOURCE_MM,
      page: 312,
    },
  },
  ...snakeGods,
  {
    header: 'Юань-ти',
    text: `Юань-ти проклинатели, которые становятся преданными жрецами определенного божества — будь то [Ссет](GOD:${GOD_SSETH}), [Дендар Ночная Змея](GOD:${GOD_DENDAR}), или [Мершолк](GOD:${GOD_MERRSHAULK}) — часто продвигаются по социальной лестнице и становятся духовными лидерами среди змеиного народа. Эти священники исполняют жертвенные обряды чтобы ублажить своих гнусных божеств. `,
    source: {
      id: SOURCE_VGTM,
      page: 204,
    },
  },
]
