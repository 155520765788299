const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
  CREATURE_DEVIL_SPIRIT,
  CREATURE_DEVIL_SPIRIT_2020_03_26,
  CREATURE_YUGOLOTH_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  ABILITY_DEVIL_S_SIGHT,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Дух дьявола',
  nameEn: 'Devil Spirit',
  id: CREATURE_DEVIL_SPIRIT_2020_03_26,
  releasedAs: CREATURE_DEVIL_SPIRIT,
  parentId: CREATURE_YUGOLOTH_SPIRIT_2020_03_26,
  alignmentId: ALIGNMENT_LE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Бросок пламени',
      description: `Если цель — горючий объект, который никто не несёт и не носит, она загорается.`,
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 150,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
          comment: `+ круг заклинания`,
        },
      },
    },
  ],
  extendPropCollection: {
    speed: {
      [SPEED_FLY]: 60,
    },
    featureList: [
      ABILITY_DEVIL_S_SIGHT,
    ],
  },
}
