const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../genderList')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const ropeDescription = require('./../../constants/ropeDescription')
const {
  VOLUME_UNIT_GALLON,
} = require('./../../../volumeUnitList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_PALADIN,
} = require('./../../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_TOA,
} = require('./../../../sourceList')
const {
  CREATURE_RIDING_HORSE,
} = require('./../../../creatureIdList')
const {
  CAT_ANIMALS,
  CAT_CLOTHES,
  CAT_EQUIPMENT,
  CAT_GOODS,
  CAT_HEAVY_ARMOR,
  CAT_HOLY_SYMBOL,
  CAT_MAGIC_FOCUS,
  CAT_MUSIC_TOOLS,
  CAT_SADDLES,
  CAT_TRANSPORT_WATER,
} = require('./../../../gearCategoryList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_RAIN_CATCHER,
  GEAR_RAM_PORTABLE,
  GEAR_RATIONS,
  GEAR_RELIQUARY,
  GEAR_RIDING_HORSE,
  GEAR_RIDING_SADDLE,
  GEAR_RING_MAIL,
  GEAR_ROBES,
  GEAR_ROD,
  GEAR_ROPE_HEMP,
  GEAR_ROPE_SILK,
  GEAR_ROWBOAT,
  GEAR_HAND_DRUM,
  GEAR_SHIELD,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_RING_MAIL,
    genderId: GENDER_MALE,
    name: 'Колечный доспех',
    nameByCase: {
      nominative: 'колечный доспех',
      genitive: 'колечного доспеха',
      accusative: 'колечный доспех',
      instrumental: 'колечным доспехом',
    },
    nameAlt: 'Кольчатый доспех',
    nameEn: 'Ring Mail',
    description: `Это кожаный доспех с нашитыми на него толстыми кольцами. Эти кольца усиливают доспех от ударов мечей и топоров. Колечный доспех хуже [кольчуги](GEAR:${GEAR_CHAIN_MAIL}), и обычно его носят только те, кто не могут позволить себе доспех получше.

Это кожаная броня с тяжелыми кольцами, сшитыми по всей поверхности. Металл делает его тяжелым для ношения, но он не так защищен, как [кольчуга](GEAR:${GEAR_CHAIN_MAIL}), потому что его кольца более разрежены. С другой стороны, он дешевле [кольчуги](GEAR:${GEAR_CHAIN_MAIL}), что делает его разумным выбором для начинающих искателей приключений.`,
    cost: 3000,
    weight: 40,
    hidingDisadvantage: true,
    acChangeTo: 14,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_HEAVY_ARMOR,
  },
  {
    id: GEAR_ROPE_HEMP,
    genderId: GENDER_FEMALE,
    name: 'Верёвка пеньковая',
    nameByCase: {
      nominative: 'пеньковая верёвка',
      genitive: 'пеньковой верёвки',
      accusative: 'пеньковую верёвку',
      instrumental: 'пеньковой верёвкой',
    },
    nameEn: 'Rope, hemp',
    description: ropeDescription,
    cost: 100,
    weight: 10,
    length: 50,
    hp: 2,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_ROPE_SILK,
    genderId: GENDER_FEMALE,
    name: 'Верёвка шёлковая',
    nameByCase: {
      nominative: 'шёлковая верёвка',
      genitive: 'шёлковой верёвки',
      accusative: 'шёлковую верёвку',
      instrumental: 'шёлковой верёвкой',
    },
    nameEn: 'Rope, silk',
    description: ropeDescription,
    cost: 1000,
    weight: 5,
    length: 50,
    hp: 2,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_ROD,
    genderId: GENDER_MALE,
    name: 'Скипетр',
    nameByCase: {
      nominative: 'скипетр',
      genitive: 'скипетра',
      accusative: 'скипетр',
      instrumental: 'скипетром',
    },
    nameAlt: 'Жезл',
    nameEn: 'Rod',
    description: 'Это особый предмет, созданный для проведения тайных заклинаний. Волшебники, колдуны и чародеи могут использовать эти предметы в качестве фокусировки для заклинаний.',
    cost: 1000,
    weight: 2,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_MAGIC_FOCUS,
  },
  {
    id: GEAR_RATIONS,
    genderId: GENDER_MALE,
    name: 'Сухой паёк',
    nameByCase: {
      nominative: 'Сухой паёк',
      accusative: 'Сухой паёк',
      instrumental: 'Сухим пайком',
    },
    nameAlt: 'Рацион',
    nameEn: 'Rations',
    description: 'На 1 день. Рационы состоят из обезвоженной пищи, подходящей для путешествий, включая вяленое мясо, сухофрукты, галеты и орехи.',
    cost: 50,
    weight: 2,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_ROBES,
    genderId: GENDER_FEMALE,
    name: 'Мантия',
    nameByCase: {
      nominative: 'Мантия',
      accusative: 'Мантию',
      instrumental: 'Мантией',
    },
    nameAlt: 'Ряса',
    nameEn: 'Robes',
    cost: 100,
    weight: 4,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CLOTHES,
  },
  {
    id: GEAR_RELIQUARY,
    genderId: GENDER_MALE,
    name: 'Реликварий',
    nameByCase: {
      nominative: 'Реликварий',
      accusative: 'Реликварий',
      instrumental: 'Реликварием',
    },
    nameEn: 'Reliquary',
    description: `Священный символ изображает божество или целый пантеон. Это крохотная коробочка, в которой хранится священная реликвия. [Жрец](PC_CLASS:${PC_CLASS_CLERIC}) или [паладин](PC_CLASS:${PC_CLASS_PALADIN}) может использовать священный символ в качестве фокусировки для заклинаний. Для такого использования символа заклинатель должен держать его в руке, носить у всех на виду или нести на [щите](GEAR:${GEAR_SHIELD}).`,
    cost: 500,
    weight: 2,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_HOLY_SYMBOL,
  },
  {
    id: GEAR_RAM_PORTABLE,
    genderId: GENDER_MALE,
    name: 'Таран, ручной',
    nameByCase: {
      nominative: 'Таран, ручной',
      accusative: 'Таран, ручной',
      instrumental: 'Тараном, ручным',
    },
    nameAlt: 'Таран, портативный',
    nameEn: 'Ram, Portable',
    description: 'Вы можете вышибать портативным тараном двери. Вы получаете бонус +4 к проверкам Силы. Если другой персонаж помогает вам использовать таран, Вы совершаете проверку с преимуществом.',
    cost: 400,
    weight: 35,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_HAND_DRUM,
    genderId: GENDER_MALE,
    name: 'Ручной барабан',
    nameEn: 'Hand drum',
    nameByCase: {
      nominative: 'ручной барабан',
      genitive: 'ручного барабана',
      accusative: 'ручной барабан',
      instrumental: 'ручным барабаном',
    },
    description: [
      {
        header: 'Ручной барабан',
        text: `Двусторонний кожаный барабан, оснащенный по бокам ручками.`,
        source: {
          id: SOURCE_SCAG,
          page: 125,
        },
      },
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_RIDING_HORSE,
    genderId: GENDER_FEMALE,
    name: 'Лошадь скаковая',
    nameByCase: {
      nominative: 'скаковая лошадь',
      genitive: 'скаковой лошади',
      accusative: 'скаковую лошадь',
      instrumental: 'скаковой лошадью',
    },
    nameAlt: 'Лошадь, ездовая',
    nameEn: 'Riding Horse',
    creatureId: CREATURE_RIDING_HORSE,
    description: 'Скорость 60 фт. Грузоподъёмность 480 фунтов.',
    cost: 7500,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_RIDING_SADDLE,
    genderId: GENDER_MIDDLE,
    name: 'Скаковое седло',
    nameByCase: {
      nominative: 'скаковое седло',
      genitive: 'скакового седла',
      accusative: 'скаковое седло',
      instrumental: 'скаковым седлом',
    },
    nameAlt: 'Ездовое седло',
    nameEn: 'Riding Saddle',
    cost: 1000,
    weight: 25,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_SADDLES,
  },
  {
    id: GEAR_ROWBOAT,
    genderId: GENDER_FEMALE,
    name: 'Лодка',
    nameByCase: {
      nominative: 'лодка',
      genitive: 'лодки',
      accusative: 'лодку',
      instrumental: 'лодкой',
    },
    nameAlt: [
      'Шлюпка',
      'Вёсельная лодка',
    ],
    nameEn: 'Rowboat',
    description: 'Скорость: 1,5 узла. Мест: 2',
    cost: 5000,
    weight: 100,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_WATER,
  },
  {
    id: GEAR_RAIN_CATCHER,
    genderId: GENDER_MALE,
    name: 'Дождесборник',
    nameByCase: {
      nominative: 'дождесборник',
      genitive: 'дождесборника',
      accusative: 'дождесборник',
      instrumental: 'дождесборником',
    },
    nameEn: 'Rain Catcher',
    description: 'Простое устройство, состоящее из квадратного куска шкуры со стороной 5 фт и деревянного каркаса с ножками. Будучи растянутой на каркасе, шкура формирует бассейн, который может собрать два галлона питьевой воды за дюйм осадков и вмещает до 8 галлонов воды. Шкура и каркас складываются и удобны в транспортировке.',
    cost: 100,
    volume: {
      unitId: VOLUME_UNIT_GALLON,
      count: 8,
    },
    weight: 5,
    source: {
      id: SOURCE_TOA,
      page: 32,
    },
    category: CAT_GOODS,
  },
]

module.exports = gearRawList
