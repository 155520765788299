const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GIANT_FROST,
  CREATURE_TYPE_GIANT,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  frostGiantDescriptionList,
  frostGiantNote,
} = require('./../../../../textCommonParts')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_PATCHWORK} = require('./../../../../armorTypeList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_FROST_GIANT} = require('./../../../../creatureIdList')
const {GEAR_GREATAXE} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ледяной великан',
  nameAlt: [
    'Морозный гигант',
    'Исаётун',
  ],
  nameEn: 'Frost Giant',
  id: CREATURE_FROST_GIANT,
  description: frostGiantDescriptionList,
  note: frostGiantNote,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_FROST,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 38,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_PATCHWORK,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 21,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_8,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Секирой_.`,
    },
    {
      name: 'Секира',
      // gearId: GEAR_GREATAXE, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 3,
          diceType: 12,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 9,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
