const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  clockworkDescriptionList,
  clockworkNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FALSE_APPEARANCE,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_STONE_DEFENDER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {LANG_ONE_OF_ITS_CREATOR} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Каменный защитник',
  nameEn: 'Stone Defender',
  id: CREATURE_STONE_DEFENDER,
  description: [
    `Толстые каменные пластины, прикованные к Каменному Защитнику, не только дают ему существенную защиту, но и позволяют прятаться на каменистой поверхности. Однако его главная цель — не обмануть противника, а защищать гномов и другие заводные механизмы.`,
    ...clockworkDescriptionList,
  ],
  note: clockworkNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MTOF,
    page: 201,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ONE_OF_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_4,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      place: `на земляной или каменистой поверхности`,
      appearance: `этой местности`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Удар',
      description: `Если цель — существо Большого размера или меньше, она сбивается с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  reactionList: [
    {
      name: 'Перехват атаки',
      description: `Если по существу в 5 фт. от Каменного защитника попадает атака, защитник может дать существу бонус +5 к КД против этой атаки, что может привести к промаху. Чтобы использовать эту способность, Каменный защитник должен видеть как это существо, так и атакующего.`,
    },
  ],
}
