const {ABILITY_UNYIELDING} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CREATURE_BLACK_EARTH_PRIEST} = require('./../../../../creatureIdList')
const {CR_3} = require('./../../../../crList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {blackEarthDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERSUASION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  GEAR_GLAIVE,
  GEAR_SPLINT_ARMOR,
} = require('./../../../../gearIdList')
const {
  SPELL_ACID_SPLASH,
  SPELL_BLADE_WARD,
  SPELL_EARTH_TREMOR,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_LIGHT,
  SPELL_MENDING,
  SPELL_MOLD_EARTH,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_SLOW,
  SPELL_SPIDER_CLIMB,
} = require('./../../../../spellIdList')
const {
  LANG_COMMON,
  LANG_TERRAN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Жрец Чёрной Земли',
  nameEn: 'Black Earth Priest',
  id: CREATURE_BLACK_EARTH_PRIEST,
  description: [
    {
      header: 'Жрецы Чёрной Земли',
      text: `Жрецы Чёрной Земли — это движущая сила и основные организаторы культа Огремоха. Истинно уверовавшие в его божественную силу, они создали извращённую доктрину, которая объясняет, как именно злу стихийной земли суждено переделать мир и править всем вокруг.

Жрецы Чёрной Земли образуют круг лидеров, чей фанатизм и познания в магии служат основной опорой культа. Хотя обладающие необычной силой одиночки и занимают более высокое положение в культе, именно жрецы обеспечивают лидерам их власть над остальными последователями Огремоха.`,
      source: {
        id: SOURCE_PotA,
        page: 200,
      },
    },
    blackEarthDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 200,
  },
  armor: {
    ac: 17,
    gearId: GEAR_SPLINT_ARMOR,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 14,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_TERRAN,
  ],
  cr: CR_3,
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_ACID_SPLASH,
      SPELL_BLADE_WARD,
      SPELL_EARTH_TREMOR,
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_LIGHT,
      SPELL_MENDING,
      SPELL_MOLD_EARTH,
      SPELL_SHATTER,
      SPELL_SHIELD,
      SPELL_SLOW,
      SPELL_SPIDER_CLIMB,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Глефой_.`,
    },
    {
      gearId: GEAR_GLAIVE,
    },
  ],
  reactionList: [
    ABILITY_UNYIELDING,
  ],
}
