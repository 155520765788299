const {
  SPELL_BESTOW_CURSE,
  SPELL_CALM_EMOTIONS,
  SPELL_COMMAND,
  SPELL_HOLD_PERSON,
  SPELL_MENDING,
  SPELL_SACRED_FLAME,
  SPELL_SHIELD_OF_FAITH,
  SPELL_THAUMATURGY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_NAGA,
  CREATURE_TYPE_SKELETON,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ANY,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_VERBAL} = require('./../../../../castComponentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_BONE_NAGA_GUARDIAN} = require('./../../../../creatureIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {nagaDescription} = require('./../../../../textCommonParts')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Костяная нага (охранная)',
  nameEn: 'Bone Naga (Guardian)',
  id: CREATURE_BONE_NAGA_GUARDIAN,
  description: [
    `В ответ на длительную историю вражды между юань-ти и нагами, юань-ти создали некромантский ритуал, способный остановить воскрешение наги, трансформируя живую нагу в подчиняющуюся нежить-скелет. Костяная нага помнит лишь несколько заклинаний, которые знала при жизни.`,
    nagaDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_NAGA,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 219,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 12,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 16,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_4,
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_CLERIC,
    componentOnly: CAST_VERBAL,
    spellIdList: [
      SPELL_BESTOW_CURSE,
      SPELL_CALM_EMOTIONS,
      SPELL_COMMAND,
      SPELL_HOLD_PERSON,
      SPELL_MENDING,
      SPELL_SACRED_FLAME,
      SPELL_SHIELD_OF_FAITH,
      SPELL_THAUMATURGY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
