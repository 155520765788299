const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_ACID} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_VITRIOLIC_SPHERE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_VITRIOLIC_SPHERE,
  name: 'Едкий шар',
  nameEn: 'Vitriolic Sphere',
  description: `В указанной Вами точке в пределах дистанции заклинания появляется сияющий шар изумрудно-зелёной кислоты диаметром 1 фут, взрывающийся сферой с радиусом 20 футов. Все существа в этой области должны пройти испытание Ловкости. При провале существо получает урон кислотой 10к4 и ещё 5к4 урона кислотой в конце своего следующего хода. В случае успешного испытания существо получает половину первичного урона и не получает урона в конце своего следующего хода.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 5 круга или выше, то первичный урон увеличивается на 2к4 за каждый круг ячейки выше 4.`,
  lvl: 4,
  magicSchoolId: MAGIC_EVOCATION,
  range: 150,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `капля желчи гигантского слизня`,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_ACID,
      halfOnSuccess: true,
      diceCount: 10,
      diceType: 4,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 151,
    },
    {
      id: SOURCE_EE,
      page: 16,
    },
  ],
}
