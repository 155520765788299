const generateListReleasedBeforeAs = (list = [], itemId = null) => {
  const releasedBeforeAsList = list.reduce(
    (acc, {id, releasedAs}) => releasedAs === itemId
      ? [
        ...acc,
        id,
      ]
      : acc,
    []
  )

  return releasedBeforeAsList.length
    ? releasedBeforeAsList
    : null
}

module.exports = generateListReleasedBeforeAs
