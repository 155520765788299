const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
} = require('./../../../../../damageTypeList')
const {
  PARAM_INT,
  PARAM_WIT,
} = require('./../../../../../paramList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {CONDITION_FRIGHTENED} = require('./../../../../../conditionList')
const {MAGIC_ILLUSION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_ILLUSORY_DRAGON} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_ILLUSORY_DRAGON,
  name: 'Иллюзорный дракон',
  nameEn: 'Illusory Dragon',
  description: `Собирая нити теневой материи в Царстве Теней, Вы создаёте Огромного теневого дракона в незанятом пространстве, которое Вы можете видеть в пределах дистанции. Иллюзия существует всю длительность заклинания и занимает свое пространство, как если бы она была существом.

Когда иллюзия появляется, каждый враг, который может её видеть, должен пройти испытание Мудрости, иначе становится напуганными на 1 минуту. Если напуганное существо оканчивает свой ход в том месте, где у него нет прямой видимости иллюзии, оно может повторить испытание, окончив эффект на себе при успехе.

В свой ход Вы можете переместить иллюзию на 60 футов бонусным действием. В любой момент во время её движения Вы можете заставить её извергнуть поток энергии в 60-футовом конусе, исходящем от неё. Когда Вы создаете дракона, выберите тип урона: кислота, холод, огонь, электричество, некротическая энергия или яд. Каждое существо в конусе должно пройти испытание Интеллекта, получив 7к6 выбранного типа урона при провале или половину этого урона при успехе.

Иллюзия осязаема из-за теневого материала, используемого при её создании, но атаки по ней проваливаются автоматически, она преуспевает во всех испытаниях и иммунна к любым повреждениям и состояниям. Существо, которое использует действие для изучения дракона, может определить, что это иллюзия, выполнив проверку Интеллекта (Расследование) против Вашей СЛ заклинания. Если существо распознаёт иллюзию, оно может видеть сквозь неё и имеет преимущество при испытании от её дыхания.`,
  lvl: 8,
  magicSchoolId: MAGIC_ILLUSION,
  range: 120,
  componentIdList: [CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: [
    {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_FRIGHTENED,
    },
    {
      effect: {
        savethrowParam: PARAM_INT,
        damage: {
          type: [
            DAMAGE_ACID,
            DAMAGE_COLD,
            DAMAGE_ELECTRICITY,
            DAMAGE_FIRE,
            DAMAGE_NECROTIC,
            DAMAGE_POISON,
          ],
          halfOnSuccess: true,
          diceCount: 7,
          diceType: 6,
        },
      },
    }
  ],
  source: {
    id: SOURCE_XGTE,
    page: 153,
  },
}
