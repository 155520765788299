const {
  SOURCE_GAME_BG_3,
  SOURCE_MTOF,
} = require('./../../sourceList')
const {SPELL_WISH} = require('./../../spellIdList')
const {GOD_ASMODEUS} = require('./../../godIdList')
const {CREATURE_ZARIEL} = require('./../../creatureIdList')

const devilDescriptionList = require('./devil')

module.exports = [
  {
    header: 'Дьяволы: Архидьяволы',
    text: `На вершине иерархии Девяти Преисподней стоят архидьяволы — группа жестоких и коварных элитных дьяволов Преисподней.

Среди них можно встретить разнообразных лордов, знатных дьяволов, следующих своим лордам, а также некоторых изгоев и изгнанников, от которых многие отвернулись. Репутация в Девяти Преисподней меняется с дуновением ветра, а судьбы великих и могучих взлетают и падают по прихоти их вышестоящих чинов.

Много устрашающих имён включены в списки архидьяволов; и только некоторые из них, те, кто вероятнее всего встретятся приключенцам, описаны здесь.`,
    source: {
      id: SOURCE_MTOF,
      page: 185,
    },
  },
  {
    header: 'Исполнение желаний',
    text: `Все дьяволы желают иметь контроль над смертными душами, и поэтому они завлекают смертных в совершении бездумных сделок.

Архидьяволы, владеющие истинной силы Девяти Преисподней, могут исполнить любое желание (в рамках ограничений заклинания [Исполнение желаний](SPELL:${SPELL_WISH})), которое попросит любой смертный, связавшимся с ними инфернальным договором. Архидьяволы всегда исполняют желания, вознаграждая отчаянных смертных их искажёнными желаниями. В обмен на такой дар, дьявол ожидает, и, в итоге, получает контроль над душой и обычно забирает её после смерти смертного.

Архидьявол не может исполнить своё собственное желание.`,
    source: {
      id: SOURCE_MTOF,
      page: 185,
    },
  },
  {
    header: 'Высвобожденный ад',
    text: `_(«Девять шагов к его адскому трону»: в этой главе перечислены все девять слоёв преисподней, их правители и характер соответствующего предмета, необходимого, чтобы заглянуть туда хоть одним глазком)_

| Слой       | Правитель | Предмет |
|------------|-----------|---------|
| Аверно     | [Эрцгерцогиня Зариэль](CREATURE:${CREATURE_ZARIEL}) | Гелиотроп, обрамляющий осколок кости |
| Дис        | Эрцгерцог Диспатер | Руда адского железа |
| Минаурос   | Эрцгерцог Маммон | Золотая монета, окаменевшая в едком торфе |
| Флегетос   | Эрцгерцогиня Фьёрна и её отец, эрцгерцог Белиал | Вечногорящий пепел, идеально уравновешенный на каменной чешуйке |
| Стигия     | Эрцгерцог Левистус | Угольно-чёрный глаз, застывший в кристалле из собственных слёз |
| Малболг    | Эрцгерцогиня Гласия | Корона, запятнанная кровью троих законных правителей |
| Маладомини | Эрцгерцог Вельзевул | Горийская муха, откормленная кровью просителя |
| Кания      | Эрцгерцог Мефистофель | Единственный и драгоценный текст, сожжённый дотла |
| Нессус     | [Владыка Асмодей](GOD:${GOD_ASMODEUS}) | _Все известные субстанции приводят к немедленному исчезновению просителя_ |
`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  ...devilDescriptionList,
]
