const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_SAHUAGIN} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_SAHUAGIN,
  nameEn: 'Sahuagin',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'сахуагин',
      genitive: 'сахуагина',
      dative: 'сахуагину',
      accusative: 'сахуагина',
      instrumental: 'сахуагином',
      prepositional: 'сахуагине',
    },
    plural: {
      nominative: 'сахуагины',
      genitive: 'сахуагинов',
      dative: 'сахуагинам',
      accusative: 'сахуагинов',
      instrumental: 'сахуагинами',
      prepositional: 'сахуагинах',
    },
  },
}
