const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_WEAPON_DARK,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SORROW_SWORN,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_13} = require('./../../../../crList')
const {CREATURE_THE_ANGRY} = require('./../../../../creatureIdList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {sorrowSwornDescriptionList} = require('./../../../../textCommonParts')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Злобный',
  nameAlt: 'Злость',
  nameEn: 'The Angry',
  id: CREATURE_THE_ANGRY,
  description: [
    `Опираясь на насилие для поддержания своё существования, Злобные становятся сильнее, когда их противники сопротивляются. Если существо вопреки ожиданиям предпочитает не атаковать, Злобный приходит в замешательство и его атаки ослабевают. У каждого Злобного по две головы, которые препираются друг с другом, пока не найдут кого-то другого, на ком они могут выразить свой гнев.`,
      ...sorrowSwornDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SORROW_SWORN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 237,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 30,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 19,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_WEAPON_DARK,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_13,
  featureList: [
    {
      name: 'Две головы',
      description: `Злобный с преимуществом совершает проверки Мудрости (Восприятие) и проходит испытания против ослепления, очарования, оглушения, испуга, ошеломления или лишения сознания.`,
    },
    {
      name: 'Нарастающий гнев',
      description: `Если другое существо причиняет урон Злобному, то Злобный совершает с преимуществом броски атаки до конца своего следующего хода, а первое попадания _Крюком_наносит дополнительно 19 (3к12) урона психической энергией.

Злобный совершает с помехой броски атаки, если никакое другое существо не причинило ему урона на прошлом ходу.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Злобный совершает две атаки _Крюком_.`,
    },
    {
      name: 'Крюк',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 12,
          diceBonus: 3,
        },
      },
    },
  ],
}
