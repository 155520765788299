const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
  TIME_INSTANT,
} = require('./../../../../../timePeriodList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_SHAPE_WATER} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SHAPE_WATER,
  name: 'Формование воды',
  nameEn: 'Shape Water',
  description: `Выберите область воды, которую Вы видите в пределах дистанции заклинания, и которая помещается в 5-футовый куб. Вы можете управлять ею одним из нижеперечисленных способов:

* Вы мгновенно перемещаете воду или изменяете скорость её течения на 5 футов в любом направлении. Этого воздействия недостаточно, чтобы причинить урон. 
* Вы можете заставить воду принимать простые формы или двигаться согласно Вашим указаниям. Этот эффект действует 1 час. 
* Вы можете изменить цвет или прозрачность воды. Вся область воды должна быть одного цвета и прозрачности. Эффект действует 1 час. 
* Вы замораживаете воду, если в ней нет никаких существ. Вода размораживается через 1 час.

Если Вы накладываете это заклинание несколько раз, Вы не можете поддерживать более 2 немгновенных эффектов одновременно. Вы можете действием отменить один из действующих эффектов.`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  componentIdList: [CAST_SOMATIC],
  duration: [TIME_INSTANT, {timeId: TIME_HOUR, count: 1}],
  source: [
    {
      id: SOURCE_XGTE,
      page: 170,
    },
    {
      id: SOURCE_EE,
      page: 24,
    },
  ],
}
