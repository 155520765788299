const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {ABILITY_SPIDER_CLIMB} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TRAPPER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Траппер',
  nameEn: 'Trapper',
  id: CREATURE_TRAPPER,
  description: [
    `Траппер — это скатоподобное существо, обитающее в природной среде и в подземельях. Он может менять цвет и текстуру своей крепкой внешней стороны, чтобы лучше сливаться с окрестностями, а его мягкая внутренняя сторона цепляется за пол, стены или потолки на территории его охоты. Он остается неподвижным и ждёт, пока добыча подходит ближе. Когда цель находится в пределах досягаемости, он отслаивается от поверхности и оборачивается вокруг добычи, ломает, душит, а после и поедает её.`,
    {
      header: 'Универсальный камуфляж',
      text: `Траппер может изменить цвет и текстуру своей внешней стороны, чтобы она подходила под окружение. Он может смешаться с любой текстурой, сделанной из камня, земли или дерева, маскируя свое присутствие для всех, кроме самых тщательных исследователей. Он не может изменить структуру на травяную или снежную поверхность, но может поменять свой цвет и укрыться под тонким слоем растений или настоящим снегом.`,
      source: {
        id: SOURCE_VGTM,
        page: 197,
      },
    },
    {
      header: 'Неподвижные охотники',
      text: `Трапперу нужно съедать пищи примерно с одного полурослика раз в неделю, чтобы оставаться сытым. Он обычно остаётся на одном и том же месте, при постоянном притоке пищи, поэтому трапперы могут стать угрозой в любом часто посещаемом подземелье и на популярных маршрутах в дикой местности. При недостатке добычи траппер входит в состояние спячки, которое может продолжаться месяцами, но даже в нём он чувствует, когда добыча оказывается неподалёку. Траппер на грани голодной смерти может отринуть свои инстинкты и уползти в другое место, покинув свою старую территорию в поисках лучшей охоты.`,
      source: {
        id: SOURCE_VGTM,
        page: 197,
      },
    },
    {
      header: 'Бойтесь останков',
      text: `Когда добыча убита, траппер растворяет и поглощает сочную плоть, оставляя кучу костей, металла, сокровищ и других неперевариваемых вещей на том месте, где было существо. Траппер, который скрывается на полу своих охотничьих угодий, может укрыть эти останки своим телом, чтобы они выглядели просто как неровная поверхность. Существо также может прилепиться к стене или потолку вблизи от недавнего убийства, эффективно используя останки как приманку: существо, которое остановится и исследует кости на предмет ценностей имеет неплохие шансы стать следующим обедом траппера.`,
      source: {
        id: SOURCE_VGTM,
        page: 197,
      },
    },
  ],
  note: {
    text: `Трапперы знают, когда их добыча проходит мимо, так что следует исследовать руины и подземелья с особенной осторожностью. Для тупых зверей они слишком хорошо знают что такое сокровища, сундуки с сокровищами и как они привлекают таких, как мы`,
    author: `Воло`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 197,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_CLIMB]: 10,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 4,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_3,
  featureList: [
    ABILITY_SPIDER_CLIMB,
    {
      name: 'Ложная внешность',
      description: `Пока траппер прикреплён к потолку, полу или стене и не двигается, его почти невозможно отличить от обычной секции потолка, пола или стены. Существо, которое может его видеть и успешно прошедшее проверку Интеллекта (Расследование) или Интеллекта (Природа) СЛ 20, может различить его присутствие.`,
    },
  ],
  actionList: [
    {
      name: 'Удушение',
      description: `Одно существо Большого размера или меньше в 5 футах от траппера должно успешно пройти испытание Ловкости СЛ 14 или стать схваченным (СЛ высвобождения 14). До конца захвата цель получает 17 (4к6 + 3) дробящего урона плюс 3 (1к6) урона кислотой в начале каждого из своих ходов. В таком захвате цель обездвижена, ослеплена и не может дышать. Траппер может душить только одно существо одновременно.`,
    },
  ],
}
