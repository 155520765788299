const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_ICE_MEPHIT,
  CREATURE_MAGMA_MEPHIT,
} = require('./../../creatureIdList')

module.exports = {
  header: 'Мефиты',
  text: `Мефиты — своенравные, похожие на бесов существа, родом со стихийных планов. Есть шесть разновидностей мефитов, каждая из которых представляет собой смесь двух стихий.

Мефиты эти нестареющие озорники, во множестве населяющие Стихийные Планы и Стихийный Хаос. Они также нередко находят пути на Материальный план, где предпочитают обитать в местах, богатых их родными стихиями. Например, [магмовый мефит](CREATURE:${CREATURE_MAGMA_MEPHIT}) состоит из огня и земли, и предпочитает обитать внутри вулканов, в то время как [ледяной мефит](CREATURE:${CREATURE_ICE_MEPHIT}), состоящий из воздуха и воды, предпочитает места, где похолоднее.

**Стихийная натура.** Мефиту не нужны еда, питьё и сон.`,
  source: {
    id: SOURCE_MM,
    page: 204,
  },
}
