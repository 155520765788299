const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_FEMALE} = require('./../../genderList')
const {CREATURE_TYPE_UNDEAD} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_UNDEAD,
  nameEn: 'Undead',
  genderId: GENDER_FEMALE,
  description: {
    text: `Нежить — это бывшие живые существа, которые приняли такое состояние либо из-за магии некромантии, либо из-за нечестивого проклятья. К нежити относятся ходячие трупы, например, вампиры и зомби, а также бесплотные духи, например, приведения и спектры.`,
    source: {
      id: SOURCE_MM,
      page: 9,
    },
  },
  name: {
    singular: {
      nominative: 'нежить',
      genitive: 'нежити',
      dative: 'нежити',
      accusative: 'нежить',
      instrumental: 'нежитью',
      prepositional: 'нежити',
    },
    plural: {
      nominative: 'нежить',
      genitive: 'нежити',
      dative: 'нежити',
      accusative: 'нежить',
      instrumental: 'нежитью',
      prepositional: 'нежити',
    },
  },
}
