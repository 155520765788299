const {CREATURE_AIR_ELEMENTAL} = require('./../../../../creatureIdList')
const {GEAR_BACKPACK} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {MAGIC_ITEM_BALLOON_PACK} = require('./../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {
  SPELL_FEATHER_FALL,
  SPELL_LEVITATE,
} = require('./../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_BALLOON_PACK,
  name: `Рюкзак с аэростатом`,
  nameEn: `Balloon Pack`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_BACKPACK,
  rarity: MGC_RARITY_UNCOMMON,
  isConsumable: true,
  description: `В этом рюкзаке содержится дух [воздушного элементаля](CREATURE:${CREATURE_AIR_ELEMENTAL}) и компактный кожаный аэростат.

Нося этот рюкзак, Вы можете действием развернуть аэростат, получив при этом эффект, подобный заклинанию [левитация](SPELL:${SPELL_LEVITATE}). Эффект длится 10 минут, нацеливается на себя и не требует концентрации.

В качестве альтернативы Вы можете реакцией развернуть аэростат при падении и, выбрав целью самого себя, получить эффект, подобный заклинанию [Падение пёрышком](SPELL:${SPELL_FEATHER_FALL}).

Когда любой из этих эффектов заканчивается, из аэростата начинает постепенно выходить воздух, а дух элементаля покидает рюкзак и возвращается на Стихийный План Воздуха. При этом вы медленно опускаетесь на расстояние до 60 футов. Если в конце этой дистанции Вы всё ещё находитесь в воздухе и у Вас нет других способов замедлить падение, то вы начинаете стремительно падать вниз.

После того, как дух покинет рюкзак, его будет невозможно использовать, однако его можно перезарядить в стихийном узле воздуха за 1 час. В предмет будет помещён дух другого элементаля, и он снова будет пригоден к использованию.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PotA,
    page: 228,
  },
}
