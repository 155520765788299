const listToCollectionById = require ('./../utils/listToCollectionById')
const getParentCatIdList = require ('./../utils/getParentCatIdList')
const {
  FEATURE_SPELLCASTING_FOCUS_BARD,
  FEATURE_SPELLCASTING_FOCUS_CLERIC,
  FEATURE_SPELLCASTING_FOCUS_DRUID,
  FEATURE_SPELLCASTING_FOCUS_PALADIN,
  FEATURE_SPELLCASTING_FOCUS_SORCERER,
  FEATURE_SPELLCASTING_FOCUS_WARLOCK,
  FEATURE_SPELLCASTING_FOCUS_WIZARD,
} = require ('./../constants/featureIdList')
const {
  PC_RACE_ELF,
  PC_RACE_GNOME,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
} = require ('./../constants/pcRaceIdList')
const {
  PC_CLASS_BARD,
  PC_CLASS_DRUID,
} = require ('./../constants/pcClassIdList')
const {
  PARAM_STR,
  PARAM_DEX,
} = require ('./paramList')
const {
  CREATURE_ASSASSIN,
  CREATURE_DROW,
  CREATURE_GIANT_SEA_HORSE,
  CREATURE_GRIFFON,
  CREATURE_HIPPOGRIFF,
  CREATURE_PEGASUS,
} = require ('./creatureIdList')
const {
  SOURCE_AYAGWnW,
  SOURCE_DMG,
  SOURCE_PHB,
} = require ('./sourceList')
const {
  VEHICLE_ROWBOAT,
} = require ('./vehicleIdList')
const {
  GEAR_BACKPACK,
  GEAR_BOMB,
  GEAR_BULLETS,
  GEAR_BULLETS_MODERN,
  GEAR_CARRIAGE,
  GEAR_CART,
  GEAR_CHAIN_MAIL,
  GEAR_CHARIOT,
  GEAR_CLUB,
  GEAR_DAGGER,
  GEAR_DICE_SET,
  GEAR_ENERGY_CELL,
  GEAR_EXOTIC_SADDLE,
  GEAR_GRENADE_FRAGMENTATION,
  GEAR_GRENADE_LAUNCHER,
  GEAR_GRENADE_SMOKE,
  GEAR_GUNPOWDER_KEG,
  GEAR_GUNPOWDER_POWDER_HORN,
  GEAR_HERBALISM_KIT,
  GEAR_HIDE_ARMOR,
  GEAR_JEWELERS_TOOLS,
  GEAR_KEELBOAT,
  GEAR_LEATHER_ARMOR,
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_MACE,
  GEAR_MILITARY_SADDLE,
  GEAR_PLATE_ARMOR,
  GEAR_PLAYING_CARD_SET,
  GEAR_QUIVER,
  GEAR_REVOLVER,
  GEAR_SHORTBOW,
  GEAR_SLED,
  GEAR_SLING,
  GEAR_WAGON,
  GEAR_WARHAMMER,
  GEAR_WHIP,
} = require ('./gearIdList')

const CAT_AMMUNITION = 'ammunition'
const CAT_AMMUNITION_ENERGY_CELLS = 'ammunition_energy_cells'
const CAT_AMMUNITION_FIREARM = 'ammunition_firearm'
const CAT_ANIMALS = 'animals'
const CAT_ARMOR = 'armor'
const CAT_ARTISAN_TOOLS = 'artisan_tools'
const CAT_CLOTHES = 'clothes'
const CAT_CONTAINERS = 'container'
const CAT_DRUID_FOCUS = 'druid_focus'
const CAT_EJECTIVES = 'ejectives'
const CAT_EQUIPMENT = 'equipment'
const CAT_EQUIPMENT_KIT = 'equipment_kit'
const CAT_EXPLOSIVES = 'explosives'
const CAT_FIREARMS = 'firearms'
const CAT_FOCUS_THINGS = 'focus_things'
const CAT_FOOD = 'food'
const CAT_GAME_SET = 'game_set'
const CAT_GOODS = 'goods'
const CAT_GRENADES = 'grenades'
const CAT_GUNPOWDER = 'gunpowder'
const CAT_HEAVY_ARMOR = 'heavy_armor'
const CAT_HOLY_SYMBOL = 'holy_symbol'
const CAT_LIGHT_ARMOR = 'light_armor'
const CAT_LIGHT_SOURCE = 'light_source'
const CAT_MAGIC_FOCUS = 'magic_focus'
const CAT_MARTIAL_MELEE_WEAPON = 'martial_melee_weapon'
const CAT_MARTIAL_RANGE_WEAPON = 'martial_range_weapon'
const CAT_MARTIAL_WEAPON = 'martial_weapon'
const CAT_MEDIUM_ARMOR = 'medium_armor'
const CAT_MELEE_WEAPON = 'melee_weapon'
const CAT_MUSIC_TOOLS = 'music_tools'
const CAT_POISONS = 'poisons'
const CAT_RANGE_WEAPON = 'range_weapon'
const CAT_SADDLES = 'saddles'
const CAT_SHIELDS = 'shields'
const CAT_SIMPLE_MELEE_WEAPON = 'simple_melee_weapon'
const CAT_SIMPLE_RANGE_WEAPON = 'simple_range_weapon'
const CAT_SIMPLE_WEAPON = 'simple_weapon'
const CAT_TECH_ALIEN = 'tech_alien'
const CAT_TECH_FUTURISTIC = 'tech_futuristic'
const CAT_TECH_MODERN = 'tech_modern'
const CAT_TECH_RENAISSANCE = 'tech_renaissance'
const CAT_TOOLS = 'tools'
const CAT_TRANSPORT = 'transport'
const CAT_TRANSPORT_GROUND = 'transport_ground'
const CAT_TRANSPORT_WATER = 'transport_water'
const CAT_WEAPON = 'weapon'

const gearCategoryListRaw = [
  {
    id: CAT_ARMOR,
    name: 'Доспехи',
    nameEn: 'Armor',
    description: [
      {
        text: `Миры D&D — широкий гобелен, сотканный из разнообразных культур, где каждая находится на своём технологическом уровне. Поэтому искателям приключений могут попасться самые разнообразные доспехи, от [кожаных курток](GEAR:${GEAR_LEATHER_ARMOR}) и [кольчуг](GEAR:${GEAR_CHAIN_MAIL}) до дорогих [лат](GEAR:${GEAR_PLATE_ARMOR}).`,
        source: {
          id: SOURCE_PHB,
          page: 145,
        },
      },
      {
        header: 'Доспехи: основа защиты героя',
        subHeader: 'Armor: The foundation of a hero’s defense',
        text: `Самая крепкая броня — не всегда лучшая защита. Слишком толстый доспех может замедлить вашего персонажа или даже помешать его магическим способностям. Слишком тонкий доспех может сделать Вас уязвимым, особенно если Вы склонны подходить близко к противникам в бою. Помните, что у некоторых классов есть ограничения на то, какие виды доспехов они могут использовать, поэтому проверьте свои возможности, прежде чем отдать своё сердце этому блестящему кольчужному костюму. Выбирайте тщательно, если хотите пережить будущие опасности приключений!

Цена тоже важна для начинающих персонажей. Модная металлическая броня может съесть все Ваши сбережения, оставив без медяка в кармане и Вам не на что будет купить еду и дополнительное снаряжение. С другой стороны, самодельный [костюм из шкуры](GEAR:${GEAR_HIDE_ARMOR}) стоит дёшево, но и не обеспечит особой защиты в реальном бою. При выборе брони всегда важно соблюдать баланс.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 119,
        },
      },
    ],
  },
  {
    id: CAT_LIGHT_ARMOR,
    parentCatId: CAT_ARMOR,
    name: 'Лёгкие доспехи',
    nameEn: 'Light Armor',
    description: [
      {
        text: `Если Вы носите лёгкий доспех, Вы при определении Класса Доспеха добавляете модификатор Ловкости к базовому числу, предоставленному доспехом.`,
        source: {
          id: SOURCE_PHB,
          page: 145,
        },
      },
      {
        text: `Лёгкие доспехи, изготовленные из лёгких и тонких материалов, предпочитают ловкие искатели приключений, поскольку те предоставляют защиту, и при этом не ограничивают подвижность. Это уменьшит урон от слабых ударов, но может оказаться недостаточным для защиты от более сильных противников.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 122,
        },
      },
    ],
  },
  {
    id: CAT_MEDIUM_ARMOR,
    parentCatId: CAT_ARMOR,
    name: 'Средние доспехи',
    nameEn: 'Medium Armor',
    description: [
      {
        text: `Средние доспехи предлагают лучшую защиту, чем лёгкие, но немного ограничивают перемещение. Если Вы носите средний доспех, Вы при определении Класса Доспеха к базовому числу, предоставленному доспехом, добавляете модификатор Ловкости, но не более +2.`,
        source: {
          id: SOURCE_PHB,
          page: 145,
        },
      },
      {
        text: `Готовы пожертвовать подвижностью ради дополнительной защиты? Средняя броня может быть как раз тем, что вам нужно. Это идеальный вариант для тех, кто любит оказываться в гуще боя, но умеет делать что-то сложнее, чем просто бить врагов изо всех сил.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 125,
        },
      },
    ],
  },
  {
    id: CAT_HEAVY_ARMOR,
    parentCatId: CAT_ARMOR,
    name: 'Тяжёлые доспехи',
    nameEn: 'Heavy Armor',
    description: `Из всех видов доспехов, тяжёлые доспехи предоставляют лучшую защиту. Эти комплекты доспехов покрывают всё тело и созданы для защиты от самых разных атак. Их вес и нагрузку могут выдержать только самые тренированные воители. Тяжёлый доспех не позволяет добавлять к Классу Доспеха модификатор Ловкости, но и не даёт штраф, если модификатор Ловкости отрицательный.

Эта тяжёлая штука — ваш лучший выбор для того, чтобы выйти из сложного боя невредимым. Тем не менее, вес и толщина тяжёлой брони означает, что только самые сильные и опытные воины могут носить её в бою, не сталкиваясь со проблемами со скоростью и передвижением.`,
  },
  {
    id: CAT_SHIELDS,
    parentCatId: CAT_ARMOR,
    name: 'Щиты',
    nameEn: 'Shields',
    description: [
      {
        text: `Часто щит — лучший друг искателя приключений. Способные остановить различные атаки, от мечей и стрел до [боевых молотов](GEAR:${GEAR_WARHAMMER}) и [кнутов](GEAR:${GEAR_WHIP}), щиты — это быстрые и универсальные защитные инструменты. Вы даже можете использовать щит как дубину, если вас загнали в угол и разоружили.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 131,
        },
      },
      {
        header: 'Материал и детали щита',
        subHeader: 'Shield Material and Parts',
        text: `Щиты изготавливаются из множества различных материалов, включая дерево, жёсткую кожу и металл. Щиты из дерева и кожи могут иметь металлические детали, такие как края или шипы, для повышения их долговечности.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 131,
        },
      },
      {
        header: 'Умбон',
        subHeader: 'Umbo',
        text: `Умбон, выпуклый металлический круг в центре некоторых щитов, помогает отражать удары, направленные на середину щита.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 131,
        },
      },
      {
        header: 'Энармс',
        subHeader: 'Enarmes',
        text: `К задней части щита прикреплён кожаный ремень для руки.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 131,
        },
      },
      {
        header: 'Гиж',
        subHeader: 'Guige',
        text: `Длинный кожаный ремень для ношения щита на спине.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 132,
        },
      },
      {
        header: 'Баклер',
        subHeader: 'Buckler',
        text: `Небольшой круглый щиток, предназначенный для базовой личной защиты. Баклеры, как правило, лёгкие, недорогие, удобные для переноски и использования. Их универсальный дизайн делает их наиболее распространенным из всех щитов.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 131,
        },
      },
      {
        header: 'Каплевидный щит',
        subHeader: 'Kite',
        text: `Широкие в верхней части и узкие в нижней, каплевидные щиты намного длиннее баклера, что обеспечивает лучшую защиту. Вы можете вбить заостренный край в землю для дополнительной устойчивости при обороне.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 132,
        },
      },
      {
        header: 'Экю',
        subHeader: 'Heater',
        text: `Меньший вариант каплевидного щиты, экю часто имеет остроконечную верхушку, чтобы помочь ударить мечом. Его основание немного шире, обеспечивает большую защиту верхних конечностей, но потенциально оставляет ваших икры открытыми для атак.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 132,
        },
      },
      {
        header: 'Импровизированный',
        subHeader: 'Improvised',
        text: `Почти любой объект может стать щитом при достаточной изобретательности или отчаянии! Снятые с петель двери, крышки гробов, металлические блюда — почти всё крепкое в пределах осягаемости поможет защитить вас от нападения врага. Вам даже не нужно поднимать его — перевёрнутый стол или сундук с сокровищами — отличное место, чтобы спрятаться от стрел! Какими импровизированными щитами будете пользоваться Вы?`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 132,
        },
      },
    ],
  },
  {
    id: CAT_WEAPON,
    name: 'Оружие',
    nameEn: 'Weapon',
    description: [
      {
        header: 'Оружие',
        text: `Ваш класс предоставляет вам владение некоторыми видами оружия, отражая направленность вашего класса и инструменты, которые вы, скорее всего, будете использовать. И не важно, чем Вы вооружены, мечом или луком, от вашего оружия и умения с ним обращаться зависит ваше выживание.`,
        source: {
          id: SOURCE_PHB,
          page: 147,
        },
      },
      {
        header: 'Оружие: будьте готовы к бою',
        subHeader: 'Weapons: Be ready for combat',
        text: `Предпочитаете ли Вы [длинный меч](GEAR:${GEAR_LONGSWORD}) или [длинный лук](GEAR:${GEAR_LONGBOW}), ваше оружие и ваша способность эффективно использовать его во время приключений будет означать разницу между жизнью и смертью. Ваша раса и класс могут делать вас эффективнее с определенными оружиями или помешать вам использовать другие, поэтому вам стоит их учитывать.

Большинство людей могут использовать простое оружие без дополнительной подготовки. К нему относятся [дубины](GEAR:${GEAR_CLUB}), [булавы](GEAR:${GEAR_MACE}) и другие виды оружия, часто находящиеся в руках простых людей. Боевое оружие, включая мечи, топоры и копья, требует более специализированной подготовки. Можно ранить себя, а не врага, если атаковать оружием, которым Вы не умеет пользоваться, так что будьте осторожны!

В дополнение к функциональности, Вы должны подумать о личных предпочтениях, которые делают ваше оружие крутым и уникальным. На вашем топоре выгравированы дварфийские руны? У вашего [кинжала](GEAR:${GEAR_DAGGER}) есть драгоценная ручка или специальные ножны? Был ли этот [короткий лук](GEAR:${GEAR_SHORTBOW}) вырезан из священного дерева в отдаленных эльфийских землях? Ваше оружие будет вашим постоянным спутником в предстоящем приключении, поэтому выберите то, которому Вы можете доверять!`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 103,
        },
      },
    ],
  },
  {
    id: CAT_MELEE_WEAPON,
    parentCatId: CAT_WEAPON,
    param: PARAM_STR,
    name: 'Рукопашное оружие',
    nameEn: 'Melee Weapon',
    description: {
      text: `Рукопашное оружие используется для атаки целей, находящихся в пределах 5 футов от Вас.`,
      source: {
        id: SOURCE_PHB,
        page: 147,
      },
    },
  },
  {
    id: CAT_RANGE_WEAPON,
    parentCatId: CAT_WEAPON,
    param: PARAM_DEX,
    name: 'Дальнобойное оружие',
    nameEn: 'Ranged Weapons',
    description: [
      {
        text: `Дальнобойное оружие используется для атаки целей, находящихся на расстоянии.`,
        source: {
          id: SOURCE_PHB,
          page: 147,
        },
      },
      {
        text: `Изначально оружие дальнего боя предназначалось для охоты, но в мире заклинателей, огнедышащих и ужасных летающих зверей способность атаковать на большом расстоянии может быть жизненно важной. Луки и стрелы являются наиболее популярными видами оружия дальнего боя, но они бывают нескольких разных стилей, и есть некоторые другие виды оружия дальнего боя, которые могут лучше подойти вашему персонажу.`,
        source: {
          id: SOURCE_AYAGWnW,
          page: 114,
        },
      },
    ],
  },
  {
    id: CAT_SIMPLE_WEAPON,
    parentCatId: CAT_WEAPON,
    name: 'Простое оружие',
    nameEn: 'Simple Weapons',
    description: {
      text: `Простым оружием могут пользоваться многие. В это оружие входят дубинки, булавы и прочее оружие, доступное обывателям.`,
      source: {
        id: SOURCE_PHB,
        page: 147,
      },
    },
  },
  {
    id: CAT_SIMPLE_MELEE_WEAPON,
    parentCatId: [
      CAT_SIMPLE_WEAPON,
      CAT_MELEE_WEAPON,
    ],
    param: PARAM_STR,
    name: 'Простое рукопашное оружие',
    nameEn: 'Simple Melee Weapon',
  },
  {
    id: CAT_SIMPLE_RANGE_WEAPON,
    parentCatId: [
      CAT_SIMPLE_WEAPON,
      CAT_RANGE_WEAPON,
    ],
    param: PARAM_DEX,
    name: 'Простое дальнобойное оружие',
    nameEn: 'Simple Range Weapon',
  },
  {
    id: CAT_MARTIAL_WEAPON,
    parentCatId: CAT_WEAPON,
    name: 'Воинское оружие',
    nameEn: 'Martial Weapon',
    description: {
      text: `Воинское оружие включает мечи, топоры и древковое оружие, требующее для эффективного использования особых тренировок. Многие воители используют воинское оружие, потому что оно позволяет максимально использовать их стиль и обучение.`,
      source: {
        id: SOURCE_PHB,
        page: 147,
      },
    },
  },
  {
    id: CAT_MARTIAL_MELEE_WEAPON,
    parentCatId: [
      CAT_MARTIAL_WEAPON,
      CAT_MELEE_WEAPON,
    ],
    param: PARAM_STR,
    name: 'Воинское рукопашное оружие',
    nameEn: 'Martial Melee Weapon',
  },
  {
    id: CAT_MARTIAL_RANGE_WEAPON,
    parentCatId: [
      CAT_MARTIAL_WEAPON,
      CAT_RANGE_WEAPON,
    ],
    param: PARAM_DEX,
    name: 'Воинское дальнобойное оружие',
    nameEn: 'Martial Range Weapon',
  },
  {
    id: CAT_EQUIPMENT,
    name: 'Снаряжение',
    nameEn: 'Equipment',
    description: {
      text: `Рынки городов заполнены самыми разнообразными покупателями и продавцами: тут и кузнецы дварфов, и [эльфы](PC_RACE:${PC_RACE_ELF}) резчики по дереву, и фермеры [полуросликов](PC_RACE:${PC_RACE_HALFLING}) и ювелиры [гномов](PC_RACE:${PC_RACE_GNOME}), не говоря уже о [людях](PC_RACE:${PC_RACE_HUMAN}) самых разных национальностей и культур. В самых больших городах продаётся практически всё, от плетёных корзин и тренировочных мечей до экзотических специй и изысканных нарядов.

Искателям же приключений важно наличие в продаже доспехов, оружия, [рюкзаков](GEAR:${GEAR_BACKPACK}), верёвок и подобных товаров, так как от хорошего снаряжения зависит выживание в подземельях и диких пустошах.`,
      source: {
        id: SOURCE_PHB,
        page: 144,
      },
    },
  },
  {
    id: CAT_AMMUNITION,
    name: 'Боеприпасы',
    nameEn: 'Ammunition',
    description: {
      header: 'Боеприпасы',
      subheader: 'Ammunition',
      text: `Вы можете использовать оружие со свойством «боеприпас» для совершения дальнобойной атаки только если у вас есть боеприпасы для стрельбы. Каждый раз, когда Вы совершаете атаку с помощью этого оружия, Вы тратите один боеприпас. Вынимается боеприпас из [колчана](GEAR:${GEAR_QUIVER}) или другого контейнера частью атаки. Для зарядки одноручного оружия требуется одна свободная рука. В конце сражения Вы восстанавливаете половину использованных боеприпасов, потратив минуту на поиски на поле боя.

Если Вы используете оружие со свойством «боеприпас» для совершения рукопашной атаки, Вы считаете его импровизированным оружием. [Праща](GEAR:${GEAR_SLING}) при этом должна быть заряжена.`,
      source: {
        id: SOURCE_PHB,
        page: 147,
      },
    },
  },
  {
    id: CAT_AMMUNITION_FIREARM,
    name: 'Боеприпасы огнестрела',
    nameEn: 'Firearm ammunition',
    parentCatId: CAT_AMMUNITION,
    description: {
      header: 'Боеприпасы огнестрела',
      subheader: 'Firearm ammunition',
      text: `Боеприпасы огнестрельного оружия уничтожаются при использовании.

Современное оружие и оружие эпохи Возрождения использует [пули](GEAR:${GEAR_BULLETS}) и [патроны](GEAR:${GEAR_BULLETS_MODERN}).`,
      source: {
        id: SOURCE_DMG,
        page: 267,
      },
    },
  },
  {
    id: CAT_AMMUNITION_ENERGY_CELLS,
    name: 'Батареи',
    nameEn: 'Energy Cells',
    parentCatId: CAT_AMMUNITION,
    description: {
      header: 'Батареи',
      subheader: 'Energy Cells',
      text: `Футуристическое оружие использует особые боеприпасы, называемые [батареями](GEAR:${GEAR_ENERGY_CELL}). В одной батарее достаточно энергии, чтобы сделать все выстрелы, которые способно совершить оружие.`,
      source: {
        id: SOURCE_DMG,
        page: 267,
      },
    },
  },
  {
    id: CAT_POISONS,
    parentCatId: CAT_EQUIPMENT,
    name: 'Яды',
    nameEn: 'Poisons',
    description: {
      text: `Яды коварны и смертельно опасны, и потому запрещены в большинстве обществ, но всё равно остаются любимым оружием [наёмных убийц](CREATURE:${CREATURE_ASSASSIN}), [дроу](CREATURE:${CREATURE_DROW}) и других злых существ.`,
      source: {
        id: SOURCE_DMG,
        page: 258,
      },
    },
  },
  {
    id: CAT_FOCUS_THINGS,
    name: 'Фокусировки',
    nameEn: 'Focus Things',
    description: `Предметы, которые можно использовать в качестве фокусировки для заклинаний.`,
  },
  {
    id: CAT_MAGIC_FOCUS,
    parentCatId: CAT_FOCUS_THINGS,
    name: 'Мистический фокусирующий предмет',
    nameAlt: 'Магическая фокусировка',
    nameEn: 'Magic Focus',
    description: `Магическая фокусировка это особый предмет — сфера, кристалл, жезл, особый посох, короткая деревянная палочка или похожий предмет — созданный для проведения тайных заклинаний.

[Волшебники](FEATURE:${FEATURE_SPELLCASTING_FOCUS_WIZARD}), [колдуны](FEATURE:${FEATURE_SPELLCASTING_FOCUS_WARLOCK}) и [чародеи](FEATURE:${FEATURE_SPELLCASTING_FOCUS_SORCERER}) могут использовать эти предметы в качестве фокусировки для заклинаний.`,
  },
  {
    id: CAT_DRUID_FOCUS,
    parentCatId: CAT_FOCUS_THINGS,
    name: 'Фокусирующий предмет друида',
    nameAlt: 'Фокусировка друидов',
    nameEn: 'Druid Focus',
    description: `Фокусировкой [друида](PC_CLASS:${PC_CLASS_DRUID}) может быть веточка омелы или падуба, палочка или скипетр из тиса или другого дерева, посох, созданный из живого дерева, или тотем с перьями, мехом, костями и зубами священных животных.

[Друид](PC_CLASS:${PC_CLASS_DRUID}) может использовать эти предметы в качестве [фокусировки для заклинаний](FEATURE:${FEATURE_SPELLCASTING_FOCUS_DRUID}).`,
  },
  {
    id: CAT_CLOTHES,
    parentCatId: CAT_EQUIPMENT,
    name: 'Одежда',
    nameEn: 'Clothes',
    description: `Вещи, в которые могут одеваться персонажи`,
  },
  {
    id: CAT_HOLY_SYMBOL,
    parentCatId: CAT_FOCUS_THINGS,
    name: 'Символ религии',
    nameAlt: 'Священный символ',
    nameEn: 'Holy Symbol',
    description: `Священный символ изображает божество или целый пантеон. Это может быть амулет, изображающий символ божества, символ, выгравированный или выложенный камнями в качестве эмблемы на щите, или крохотная коробочка, в которой хранится священная реликвия. [Жрец](FEATURE:${FEATURE_SPELLCASTING_FOCUS_CLERIC}) или [паладин](FEATURE:${FEATURE_SPELLCASTING_FOCUS_PALADIN}) может использовать священный символ в качестве фокусировки для заклинаний.`,
  },
  {
    id: CAT_EQUIPMENT_KIT,
    parentCatId: CAT_EQUIPMENT,
    name: 'Наборы снаряжения',
    nameEn: 'Equipment Kit',
    description: `В начальное снаряжение, получаемое от класса, входит готовый набор полезного снаряжения. Если Вы покупаете стартовое снаряжение, то можете приобрести его по указанной цене, что может быть дешевле, чем покупать его порознь.`,
  },
  {
    id: CAT_TOOLS,
    parentCatId: CAT_EQUIPMENT,
    name: 'Инструменты',
    nameEn: 'Tools',
    description: `Независимо от того, выбираете ли Вы замки, смешиваете зелья или выступаете перед толпой, вам нужны правильные инструменты для работы — и вам стоит знать, как их использовать.

Инструменты — это обычные предметы, связанные с ремеслом, торговлей или хобби. Если Вы играете за персонажа, который, как предполагается, особенно хорош в выполнении какой-то работы, будь то честный столяр или хитрый вор, вам стоит убедиться, что у вас есть подходящее снаряжение.

Инструменты часто доступны в наборах, основанных на профессии. Считайте, что они содержат всё, что вам нужно для выполнения этой работы, но не содержат сырья. Например, [набор травника](GEAR:${GEAR_HERBALISM_KIT}) включают ступку и пестик, кусачки и мешочки, но не растения. [Набор ювелира](GEAR:${GEAR_JEWELERS_TOOLS}) включают крошечные плоскогубцы, молотки и зажимы, но не драгоценности.

Инструменты помогают делать что-то, что без них сделать нельзя, например, создавать или чинить вещи, подделывать документы и вскрывать замки. Ваши раса, класс, предыстории и черты могут давать владение некоторыми инструментами.

Владение инструментом позволяет добавлять бонус мастерства к проверкам характеристик, сделанным при использовании этих инструментов. Использование инструментов не привязано к какой-то одной характеристике, так как владение инструментом отражает широкие познания в его использовании. Например, Мастер может попросить вас совершить проверку Ловкости для вырезания мелких деталей инструментом резчика по дереву, или проверку Силы для вырезания чего-то из очень твёрдого дерева.`,
  },
  {
    id: CAT_ARTISAN_TOOLS,
    parentCatId: CAT_TOOLS,
    name: 'Инструменты ремесленников',
    nameEn: 'Artisan`s Tools',
    description: `В эти особые наборы входят инструменты, необходимые для ремесла и торговли. В таблице приведены примеры самых распространённых наборов, каждый из которых связан со своим ремеслом.

Владение инструментами ремесленника позволяет добавлять бонус мастерства к проверкам характеристик, совершённым при использовании инструментов в ремесле. Для каждого набора инструментов требуется отдельное владение.`,
  },
  {
    id: CAT_MUSIC_TOOLS,
    parentCatId: CAT_FOCUS_THINGS,
    name: 'Музыкальные инструменты',
    nameEn: 'Music Tools',
    description: `Необходимы для каждого [барда](PC_CLASS:${PC_CLASS_BARD}), будь то простая флейта или набор волынок.

Если Вы владеете определённым музыкальным инструментом, Вы можете добавлять бонус мастерства к проверкам характеристик, совершённым во время игры на нём. [Бард](PC_CLASS:${PC_CLASS_BARD}) может использовать музыкальный инструмент в качестве [фокусировки для заклинаний](FEATURE:${FEATURE_SPELLCASTING_FOCUS_BARD}). Для каждого музыкального инструмента нужно отдельное владение.`,
  },
  {
    id: CAT_GAME_SET,
    parentCatId: CAT_TOOLS,
    name: 'Игровой набор',
    nameEn: 'Game Set',
    description: `Включают в себя [кости](GEAR:${GEAR_DICE_SET}), [игральные карты](GEAR:${GEAR_PLAYING_CARD_SET}) и другие игровые предметы, с помощью которых можно завести как друзей, так и врагов в местной таверне.

Игры могут состоять из разных предметов, включая [кости](GEAR:${GEAR_DICE_SET}) и [колоды карт](GEAR:${GEAR_PLAYING_CARD_SET}). Если Вы владеете игровым набором, Вы можете добавлять бонус мастерства к проверкам характеристик, совершаемым во время игры. Для каждой игры требуется отдельное владение.`,
  },
  {
    id: CAT_ANIMALS,
    name: 'Скакуны и другие животные',
    nameEn: 'Steeds & Animals',
    description: `Хороший скакун поможет вам быстро пересечь пустоши, но основное его предназначение — везти груз, который замедлял бы вас.

Животное, тянущее [тележку](GEAR:${GEAR_CARRIAGE}), [повозку](GEAR:${GEAR_CART}), [карету](GEAR:${GEAR_CHARIOT}), [сани](GEAR:${GEAR_SLED}) или [фургон](GEAR:${GEAR_WAGON}), может перевозить в пять раз больше базовой грузоподъёмности, включая вес транспортного средства. Если в одно транспортное средство впряжены сразу несколько животных, они могут суммировать свою грузоподъёмность.

В мирах D&D есть и другие скакуны, но они редкие, и их сложно купить. В эту категорию входят летающие скакуны ([пегасы](CREATURE:${CREATURE_PEGASUS}), [грифоны](CREATURE:${CREATURE_GRIFFON}), [гиппогрифы](CREATURE:${CREATURE_HIPPOGRIFF}) и подобные животные), а также водные ([гигантские морские коньки](CREATURE:${CREATURE_GIANT_SEA_HORSE}), например). Обычно таких скакунов получают, выращивая питомца с рождения, посредством сделки с влиятельной сущностью, или с разрешения самого скакуна.`,
  },
  {
    id: CAT_TRANSPORT,
    name: 'Транспорт',
    nameEn: 'Transport',
    description: `Всё, что нужно для лихой поездки`,
  },
  {
    id: CAT_TRANSPORT_WATER,
    parentCatId: CAT_TRANSPORT,
    name: 'Водный транспорт',
    nameEn: 'Transport Water',
    description: `[Килевые лодки](GEAR:${GEAR_KEELBOAT}) и [шлюпки](VEHICLE:${VEHICLE_ROWBOAT}) используют для перемещения по рекам и озёрам. При плавании по течению добавьте к скорости судна скорость потока (обычно 3 узла или 5 километров в час). На вёслах против быстрого течения на этих судах не поднимешься, но их могут тянуть животные, идущие по берегу. [Шлюпка](VEHICLE:${VEHICLE_ROWBOAT}) весит 100 фунтов, если искатели приключений понесут её на руках.`,
  },
  {
    id: CAT_TRANSPORT_GROUND,
    parentCatId: CAT_TRANSPORT,
    name: 'Наземный транспорт',
    nameEn: 'Transport Ground',
  },
  {
    id: CAT_GOODS,
    name: 'Товары',
    nameEn: 'Goods',
    description: `Богатство исчисляется не только монетами. Оно может измеряться домашним скотом, зерном, землями, правами на сбор налогов или правами добывать ресурсы (в шахте или лесу).

Гильдии, дворяне и королевства постоянно обмениваются товарами. Нанятым компаниям дают право торговать по определённым маршрутам, отправлять торговые корабли в разные порты или покупать и продавать определённые товары. Гильдии устанавливают цены на контролируемые ими товары и услуги, а также определяют, кто может, а кто не может пользоваться их товарами и услугами. Торговцы обычно обмениваются между собой товарами, не используя монеты.`,
  },
  {
    id: CAT_FOOD,
    parentCatId: CAT_GOODS,
    name: 'Еда и напитки',
    nameEn: 'Food & Drinks',
    description: `Всё, что можно заказать в гостеприимной таверне`,
  },
  {
    id: CAT_SADDLES,
    parentCatId: CAT_TRANSPORT_GROUND,
    name: 'Сёдла',
    nameEn: 'Saddles',
    description: `[Боевое седло](GEAR:${GEAR_MILITARY_SADDLE}) охватывает всадника, помогая удержаться на нём в бою. Благодаря ему Вы совершаете с преимуществом проверки, совершённые чтобы остаться в седле. [Экзотические сёдла](GEAR:${GEAR_EXOTIC_SADDLE}) требуются для водных и летающих скакунов.`,
  },
  {
    id: CAT_LIGHT_SOURCE,
    parentCatId: CAT_EQUIPMENT,
    name: 'Источники света',
    nameEn: 'Light Sources',
    description: `Снаряжение, дающее свет в темноте`,
  },
  {
    id: CAT_EJECTIVES,
    parentCatId: CAT_EQUIPMENT,
    name: 'Метательные снаряды',
    nameEn: 'Ejectives',
    description: `Снаряжение для метания во врагов`,
  },
  {
    id: CAT_CONTAINERS,
    parentCatId: CAT_EQUIPMENT,
    name: 'Контейнеры',
    nameEn: 'Containers',
    description: `Контейнеры, ящики и бутылки для хранения жидкостей и вещей.`,
  },
  {
    id: CAT_FIREARMS,
    name: 'Огнестрел',
    nameEn: 'Firearms',
    description: {
      header: 'Огнестрел',
      text: `Если Мастер хочет передать бесшабашный дух Трёх Мушкетёров и подобных романов, он может ввести в свою кампанию в стиле эпохи Возрождения пороховое оружие. То же самое касается кампаний, в которых разбились космические корабли или присутствуют элементы современной Земли.`,
      source: {
        id: SOURCE_DMG,
        page: 267,
      },
    },
  },
  {
    id: CAT_EXPLOSIVES,
    name: 'Взрывчатка',
    nameEn: 'Explosives',
    description: {
      header: 'Взрывчатка',
      text: `В кампании могут встречаться взрывчатые вещества из эпохи Возрождения или современного мира.`,
      source: {
        id: SOURCE_DMG,
        page: 267,
      },
    },
  },
  {
    id: CAT_GRENADES,
    name: 'Гранаты',
    nameEn: 'Grenades',
    parentCatId: CAT_EXPLOSIVES,
    description: {
      header: 'Гранаты',
      text: `Персонаж может действием кинуть гранату на расстояние до 60 футов. Имея [гранатомёт](GEAR:${GEAR_GRENADE_LAUNCHER}), можно запустить её на расстояние до 120 футов.

Все существа в пределах 20 футов от места взрыва [осколочной гранаты](GEAR:${GEAR_GRENADE_FRAGMENTATION}) должны пройти испытание Ловкости СЛ 15, получая при провале колющий урон 5к6 или половину этого урона при успехе.

Спустя раунд после приземления [дымовой гранаты](GEAR:${GEAR_GRENADE_SMOKE}) появляется облако дыма, создающее сильно заслонённую местность с радиусом 20 футов. Умеренный ветер (по крайней мере, 10 миль в час) разгоняет дым за 4 раунда, сильный ветер (20 или больше миль в час) разгоняет его за 1 раунд.`,
      source: {
        id: SOURCE_DMG,
        page: 268,
      },
    },
  },
  {
    id: CAT_GUNPOWDER,
    name: 'Порох',
    nameEn: 'Gunpowder',
    parentCatId: CAT_EXPLOSIVES,
    description: {
      header: 'Порох',
      text: `Порох преимущественно используется для приведения в движение пули в стволе пистолета или винтовки, а также для изготовления [бомб](GEAR:${GEAR_BOMB}). Порох продаётся в [маленьких деревянных бочонках](GEAR:${GEAR_GUNPOWDER_KEG}) или [водонепроницаемых рожках](GEAR:${GEAR_GUNPOWDER_POWDER_HORN}).

Поджигание контейнера с порохом может вызвать взрыв, причиняющий урон огнём всем существам в пределах 10 футов от него (3к6 от [рожка](GEAR:${GEAR_GUNPOWDER_POWDER_HORN}), 7к6 от [бочонка](GEAR:${GEAR_GUNPOWDER_KEG})). Успех в испытании Ловкости СЛ 12 уменьшает урон вдвое. Подожжённая унция пороха просто горит 1 раунд, распространяя яркий свет в радиусе 30 футов и тусклый свет в пределах ещё 30 футов.`,
      source: {
        id: SOURCE_DMG,
        page: 268,
      },
    },
  },
  {
    id: CAT_TECH_FUTURISTIC,
    name: 'Технология будущего',
    nameEn: 'Futuristic tech',
    parentCatId: CAT_TECH_ALIEN,
    description: `Если Мастер хочет, он может ввести в свою кампанию, где присутствуют разбившиеся космические корабли, футуристическое оружие.`,
  },
  {
    id: CAT_TECH_RENAISSANCE,
    name: 'Технология эпохи Возрождения',
    nameEn: 'Renaissance tech',
    parentCatId: CAT_TECH_ALIEN,
    description: {
      header: 'Технология эпохи Возрождения',
      text: `Если Мастер хочет передать бесшабашный дух Трёх Мушкетёров и подобных романов, он может ввести в свою кампанию в стиле эпохи Возрождения пороховое оружие.`,
      source: {
        id: SOURCE_DMG,
        page: 267,
      },
    },
  },
  {
    id: CAT_TECH_MODERN,
    name: 'Технология современности',
    nameEn: 'Modern tech',
    parentCatId: CAT_TECH_ALIEN,
    description: `Если Мастер хочет, он может ввести в свою кампанию, где присутствуют элементы современной Земли, современное оружие.`,
  },
  {
    id: CAT_TECH_ALIEN,
    name: 'Чуждые технологии',
    nameEn: 'Alien Technology',
    description: [
      {
        header: 'Чуждые технологии',
        subHeader: 'Alien Technology',
        text: `Когда искатели приключений находят образец технологии, который принадлежит другому миру или временному периоду, игроки могут понять, чем является предмет, но персонажи — вряд ли. Для имитации незнания персонажей, пусть они совершают серию проверок Интеллекта, чтобы разобраться в этой технологии.

Для определения того, как работает технология, персонаж должен преуспеть в нескольких проверках Интеллекта, количество которых зависит от сложности предмета: две для простого предмета (зажигалка, калькулятор или [револьвер](GEAR:${GEAR_REVOLVER})) и четыре для сложного предмета (такого как компьютер, бензопила или летательный аппарат). После этого обратитесь к таблице «определение чуждой технологии». Вы можете объявить, что предмет сломался, если персонаж до совершения продолжительного отдыха получил четыре или больше провала.

Персонаж, видевший, как предмет используют, или уже управлявший подобным предметом, совершает эти проверки Интеллекта с преимуществом.`,
        source: {
          id: SOURCE_DMG,
          page: 268,
        },
      },
      {
        header: 'Определение чуждой технологии',
        subHeader: 'Figuring Out Alien Technology',
        text: `
| Проверка Интеллекта | Результат |
|-|-|
| 9 или меньше | Один провал; один заряд или использование тратится впустую, если это возможно; следующую проверку персонаж совершает с помехой |
| 10–14 | Один провал |
| 15–19 | Один успех |
| 20 или выше | Один успех; следующую проверку персонаж совершает с преимуществом |
`,
        source: {
          id: SOURCE_DMG,
          page: 268,
        },
      },
    ],
  },
]

const gearCategoryCollectionRaw = listToCollectionById(gearCategoryListRaw)

const gearCategoryList = gearCategoryListRaw.map(
  cat => {
    const parentCatId = cat.parentCatId
      ? getParentCatIdList(cat, gearCategoryCollectionRaw)
      : null

    return {
      ...cat,
      parentCatId
    }
  }
)

module.exports = gearCategoryList

module.exports.gearCategoryCollection = listToCollectionById(gearCategoryList)

module.exports.CAT_AMMUNITION = CAT_AMMUNITION
module.exports.CAT_AMMUNITION_ENERGY_CELLS = CAT_AMMUNITION_ENERGY_CELLS
module.exports.CAT_AMMUNITION_FIREARM = CAT_AMMUNITION_FIREARM
module.exports.CAT_ANIMALS = CAT_ANIMALS
module.exports.CAT_ARMOR = CAT_ARMOR
module.exports.CAT_ARTISAN_TOOLS = CAT_ARTISAN_TOOLS
module.exports.CAT_CLOTHES = CAT_CLOTHES
module.exports.CAT_CONTAINERS = CAT_CONTAINERS
module.exports.CAT_DRUID_FOCUS = CAT_DRUID_FOCUS
module.exports.CAT_EJECTIVES = CAT_EJECTIVES
module.exports.CAT_EQUIPMENT = CAT_EQUIPMENT
module.exports.CAT_EQUIPMENT_KIT = CAT_EQUIPMENT_KIT
module.exports.CAT_EXPLOSIVES = CAT_EXPLOSIVES
module.exports.CAT_FIREARMS = CAT_FIREARMS
module.exports.CAT_FOCUS_THINGS = CAT_FOCUS_THINGS
module.exports.CAT_FOOD = CAT_FOOD
module.exports.CAT_GAME_SET = CAT_GAME_SET
module.exports.CAT_GOODS = CAT_GOODS
module.exports.CAT_GRENADES = CAT_GRENADES
module.exports.CAT_GUNPOWDER = CAT_GUNPOWDER
module.exports.CAT_HEAVY_ARMOR = CAT_HEAVY_ARMOR
module.exports.CAT_HOLY_SYMBOL = CAT_HOLY_SYMBOL
module.exports.CAT_LIGHT_ARMOR = CAT_LIGHT_ARMOR
module.exports.CAT_LIGHT_SOURCE = CAT_LIGHT_SOURCE
module.exports.CAT_MAGIC_FOCUS = CAT_MAGIC_FOCUS
module.exports.CAT_MARTIAL_MELEE_WEAPON = CAT_MARTIAL_MELEE_WEAPON
module.exports.CAT_MARTIAL_RANGE_WEAPON = CAT_MARTIAL_RANGE_WEAPON
module.exports.CAT_MARTIAL_WEAPON = CAT_MARTIAL_WEAPON
module.exports.CAT_MEDIUM_ARMOR = CAT_MEDIUM_ARMOR
module.exports.CAT_MELEE_WEAPON = CAT_MELEE_WEAPON
module.exports.CAT_MUSIC_TOOLS = CAT_MUSIC_TOOLS
module.exports.CAT_POISONS = CAT_POISONS
module.exports.CAT_RANGE_WEAPON = CAT_RANGE_WEAPON
module.exports.CAT_SADDLES = CAT_SADDLES
module.exports.CAT_SHIELDS = CAT_SHIELDS
module.exports.CAT_SIMPLE_MELEE_WEAPON = CAT_SIMPLE_MELEE_WEAPON
module.exports.CAT_SIMPLE_RANGE_WEAPON = CAT_SIMPLE_RANGE_WEAPON
module.exports.CAT_SIMPLE_WEAPON = CAT_SIMPLE_WEAPON
module.exports.CAT_TECH_ALIEN = CAT_TECH_ALIEN
module.exports.CAT_TECH_FUTURISTIC = CAT_TECH_FUTURISTIC
module.exports.CAT_TECH_MODERN = CAT_TECH_MODERN
module.exports.CAT_TECH_RENAISSANCE = CAT_TECH_RENAISSANCE
module.exports.CAT_TOOLS = CAT_TOOLS
module.exports.CAT_TRANSPORT = CAT_TRANSPORT
module.exports.CAT_TRANSPORT_GROUND = CAT_TRANSPORT_GROUND
module.exports.CAT_TRANSPORT_WATER = CAT_TRANSPORT_WATER
module.exports.CAT_WEAPON = CAT_WEAPON
