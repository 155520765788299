const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_2,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_OGRE,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  GEAR_MORNINGSTAR,
} = require('./../../../../gearIdList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ogreDescription,
  zombieDesciptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_UNDEAD_FORTITUDE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_OGRE_ZOMBIE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Огр-зомби',
  nameEn: 'Ogre Zombie',
  id: CREATURE_OGRE_ZOMBIE,
  description: [
    ...zombieDesciptionList,
    ...ogreDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_OGRE,
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 164,
  },
  armor: 8,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 6,
    [PARAM_CON]: 18,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 5,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: [LANG_GIANT, LANG_COMMON],
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_UNDEAD_FORTITUDE,
      damageType: `излучением`,
    },
  ],
  actionList: [
    {
      name: 'Моргенштерн',
      // gearId: GEAR_MORNINGSTAR, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
