const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_CLOAK_OF_ELVENKIND,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_CLOAK_OF_ELVENKIND,
  name: `Эльфийский плащ`,
  nameEn: `Cloak of Elvenkind`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  attunement: true,
  description: `Пока Вы носите этот плащ с накинутым капюшоном, проверки Мудрости (Внимательность), совершённые чтобы увидеть Вас, совершаются с помехой, а Вы совершаете с преимуществом проверки Ловкости (Скрытность), когда прячетесь — плащ способен изменять свой цвет, обеспечивая Вам камуфляж. Накидывание и снятие капюшона совершается действием.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 215,
  },
}
