const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CREATURE_DROW_GUNSLINGER} = require('./../../../../creatureIdList')
const {CR_4} = require('./../../../../crList')
const {DAMAGE_POISON} = require('./../../../../damageTypeList')
const {MAGIC_ITEM_SMOKEPOWDER_PACKET} = require('./../../../../magicItemIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_WDH} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_BULLETS,
  GEAR_PISTOL,
  GEAR_SHORTSWORD,
  GEAR_SHIELD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_FAERIE_FIRE,
  SPELL_LEVITATE,
} = require('./../../../../spellIdList')
const {
  LANG_UNDERCOMMON,
  LANG_ELVEN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  drowDescription,
  drowNote,
} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Дроу-стрелок',
  nameEn: 'Drow Gunslinger',
  id: CREATURE_DROW_GUNSLINGER,
  description: [
    {
      header: 'Дроу-стрелки',
      text: `Огнестрельное оружие на Севере встречается нечасто, но этим дроу удалось разжиться лантанскими [пистолями](GEAR:${GEAR_PISTOL}), [пулями](GEAR:${GEAR_BULLETS}) и [мешочками дым-пороха](MAGIC_ITEM:${MAGIC_ITEM_SMOKEPOWDER_PACKET}).
  
  Они — мастера стрельбы из [пистоля](GEAR:${GEAR_PISTOL}). Они палят из него так же метко, как хорошие лучники — из луков.`,
      source: {
        id: SOURCE_WDH,
        page: 200,
      },
    },
    ...drowDescription,
  ],
  note: drowNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_WDH,
    page: 201,
  },
  armor: {
    ac: 18,
    gearId: [GEAR_STUDDED_LEATHER_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
    LANG_ELVEN,
  ],
  cr: CR_4,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_SUNLIGHT_SENSITIVITY,
    {
      name: 'Стрелок',
      description: `★СУЩЕСТВО★ не ★получает★ помех к дальнобойным атакам из [пистоля](GEAR:${GEAR_PISTOL}) из-за нахождения врага в пределах 5 футов, или дальности атаки. Кроме того, совершая дальнобойные атаки из [пистоля](GEAR:${GEAR_PISTOL}), ★СУЩЕСТВО★ ★игнорирует★ укрытие наполовину и на три четверти.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DANCING_LIGHTS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DARKNESS,
          SPELL_FAERIE_FIRE,
          {
            id: SPELL_LEVITATE,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Коротким мечом_.`,
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      name: 'Ядовитый пистоль',
      gearId: GEAR_PISTOL,
      attack: {
        hit: {
          type: DAMAGE_POISON,
          diceCount: 2,
          diceType: 10,
        },
      },
    },
  ],
}
