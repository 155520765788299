const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  PC_CLASS_SORCERER,
} = require('./../../../../pcClassIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../magicItemRarityList')
const {
  MAGIC_ITEM_ASTRAL_SHARD,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_ASTRAL_SHARD,
  name: `Астральный осколок`,
  nameEn: `Astral Shard`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  attunementComment: `чародеем`,
  description: `Этот кристалл — затвердевший осколок Астрального плана, окружённый серебристым туманом.

Вы можете  действием прикрепить _Осколок_ к крошечному предметы (такому как оружие или украшение), или открепить его. Если Ваша настройка прекрашается — он отпадает. Держа или нося _Астральный осколок_, Вы можете использовать его как заклинательную фокусировку для Ваших заклинаний [Чародея](PC_CLASS:${PC_CLASS_SORCERER}).

Когда Вы, держа или нося _Астральный осколок_, используете Метамагический вариант заклинания, немедленно после сотворения этого заклинания Вы можете телепортироваться в видимое Вами в пределах 30 футов свободное пространство.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_TCoE,
    page: 173,
  },
}
