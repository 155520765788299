const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_1,
  MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_2,
  MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_3,
} = require('./../../../../../magicItemIdList')

const list = [
  {
    id: MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_1,
    rarity: MGC_RARITY_UNCOMMON,
    bonus: 1,
  },
  {
    id: MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_2,
    rarity: MGC_RARITY_RARE,
    bonus: 2,
  },
  {
    id: MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_3,
    rarity: MGC_RARITY_VERY_RARE,
    bonus: 3,
  },
]

module.exports = list
