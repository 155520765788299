const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GNOME_DEEP,
  CREATURE_GOBLIN,
  CREATURE_KOBOLD,
  CREATURE_OGRE_HOWDAH,
} = require('./../../../../creatureIdList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_OGRE,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_BREASTPLATE,
  GEAR_MACE,
} = require('./../../../../gearIdList')
const {
  ogreDescription,
  ogreNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Огр паланкин',
  nameEn: 'Ogre Howdah',
  id: CREATURE_OGRE_HOWDAH,
  description: [
    `Наиболее необычные из специализированных огров, паланкины несут на своей спине древесное укрепление. Укрепление достаточно велико чтобы служить боевой платформой для четырёх маленьких гуманоидов. Огры паланкины часто были замечены доставляющими в битву [гоблинов](CREATURE:${CREATURE_GOBLIN}), вооружённые луками и копьями, но они также запросто могут транспортировать [кобольдов](CREATURE:${CREATURE_KOBOLD}), [глубинных гномов](CREATURE:${CREATURE_GNOME_DEEP}) или других гуманоидов аналогичного размера.`,
    ...ogreDescription,
  ],
  note: ogreNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_OGRE,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 217,
  },
  armor: {
    ac: 13,
    gearId: GEAR_BREASTPLATE,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 16,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 7,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Паланкин',
      description: `Огр несёт небольшое укрепление у себя на спине. До четырёх существ Маленького размера могут поместиться в нём без протискивания. Для совершения рукопашной атаки в пределах 5 футов от огра они должны использовать копья или другое оружие с досягаемостью. Существа в укреплении имеют укрытие на ¾ от атак и эффектов снаружи укрепления. Если огр погибает, эти существа появляются в незанятом пространстве в пределах 5 футов от огра.`,
    },
  ],
  actionList: [
    {
      name: 'Булава',
      // gearId: GEAR_MACE, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
}
