const {
  CREATURE_BALOR,
  CREATURE_BAPHOMET,
  CREATURE_DEMOGORGON,
  CREATURE_FRAZ_URBLUU,
  CREATURE_GRAZZT,
  CREATURE_MANES,
  CREATURE_MOLYDEUS,
  CREATURE_ORCUS,
  CREATURE_YEENOGHU,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPELL_DISPEL_MAGIC,
  SPELL_IMPRISONMENT,
  SPELL_LIGHTNING_BOLT,
  SPELL_POLYMORPH,
  SPELL_TELEKINESIS,
  SPELL_TELEPORT,
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_21} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Молидей',
  nameEn: 'Molydeus',
  id: CREATURE_MOLYDEUS,
  description: [
    `Молидей — самый безжалостный и опаснейший из демонов, кого боятся даже больше, чем ужасающего [балора](CREATURE:${CREATURE_BALOR}). Молидей уполномочен говорить за лорда демонов, которому он служит, когда исполняет волю своего хозяина. Ростом иногда в 12 футов, молидей, внешне выглядит как краснокожий гуманоид с двумя головами, одна из которых — волчья голова, распускающая слюни, а другая — змеиная, с острыми и стекающими ядом клыками. Молидеи могут охранять владения своего хозяина, скитаться по полям битвы Бездны и следить за преданностью войск, или сеять скорую смерть своим врагам.`,
    {
      header: 'Заклеймён и привязан',
      text: `Когда демон привлекает внимание лорда демонов жестокостью, коварством или проявлением удивительной преданности, тот может наградить исчадие за такое услужение. Лорд хватает его и подвергает невыносимым пыткам, преобразуя демона в молидея.`,
      source: {
        id: SOURCE_MTOF,
        page: 134,
      },
    },
    {
      header: 'Голос хозяина',
      text: `У лорда демонов есть прямая связь с его молидеем и он использует его змеиную голову для распространения своих желаний. Молидей, тем самым, обращает волю хозяина, заставляя прочих демонов выполнять приказы и прибегает к насилию для обеспечения их повиновения. Молидей должен постоянно быть готов к пристальному вниманию своего хозяина, поскольку лорд демонов в любой момент может решить понаблюдать за действиями молидея через его змеиную голову. Таким образом, молидей не посмеет предать своего хозяина.`,
      source: {
        id: SOURCE_MTOF,
        page: 134,
      },
    },
    {
      header: 'Особое оружие',
      text: `Как часть доверия лорда демонов молидею, тот дарует демону-хранителю мощное оружие. Лорд демонов создаёт оружие из частиц эссенции исчадия так, чтобы демон и его оружие были навеки связаны друг с другом. Если умрёт молидей, то оружие превратится в лужу зловонной слизи. Можно попытаться украсть оружие, однако лишившись своего оружия, молидей ни перед чем не остановится, чтобы вернуть его. Оружие, которым владеет молидей, отражает сущность самого его хозяина. Те, кто служат: [Бафомету](CREATURE:${CREATURE_BAPHOMET}), владеют глефой; [Демогоргону](CREATURE:${CREATURE_DEMOGORGON}) — кнутом; [Фраз-Урб’луу](CREATURE:${CREATURE_FRAZ_URBLUU}) — боевым топором; [Граз’зту](CREATURE:${CREATURE_GRAZZT}) — двуручным мечом; [Оркусу(CREATURE:${CREATURE_ORCUS}) — моргенштерном; и [Йеногу](CREATURE:${CREATURE_YEENOGHU}) — цепом. Вид оружия не влияет на его возможности.`,
      source: {
        id: SOURCE_MTOF,
        page: 134,
      },
    },
    {
      header: 'Тёмные хранители',
      text: `Одной из основных задач любого молидея — это оказать помощь в защите амулета хозяина, самого ценного имущества всех лордов демонов. Каждый из этих опасных реликвий позволяет лорду демонов вернуться к жизни в Бездне, если случится нечто невообразимое и его непостижимая форма будет уничтожена. Будучи весьма полезными, эти амулеты также являются большой ответственностью, так как существо, что носит такой амулет, может склонить лорда демонов, к которому он принадлежит, и повелевать им, либо изгнать его из Бездны, если амулет будет разрушен.`,
      source: {
        id: SOURCE_MTOF,
        page: 134,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 134,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 28,
    [PARAM_DEX]: 22,
    [PARAM_CON]: 25,
    [PARAM_INT]: 21,
    [PARAM_WIT]: 24,
    [PARAM_CHA]: 24,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_21,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DISPEL_MAGIC,
          SPELL_POLYMORPH,
          SPELL_TELEKINESIS,
          SPELL_TELEPORT,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_LIGHTNING_BOLT,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_IMPRISONMENT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Молидей совершает три атаки: одну _Демоническим оружием_, одну _Волчьим укусом_ и одну _Змеиным укусом_.`,
    },
    {
      name: 'Демоническое оружие',
      description: `Если у цели атаки как минимум одна голова, и Молидей выбрасывает 20 при броске атаки, то цель обезглавлена и умирает, если не может жить без головы. Цель невосприимчива к этому эффекту, если она не получила урона от атаки, обладает легендарными действиями, или имеет размер Огромный или больше. Кроме того, цель получает дополнительно 6к8 рубящего урона.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 15,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Волчий укус',
      description: ``,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Змеиный укус',
      description: `Цель должна пройти испытание Телосложения 22 или её максимальное значение хитов уменьшится на значение полученного урона от этой атаки. Уменьшение хитов сохраняется до того, как цель совершит длинный отдых. Цель превращается в [мэйна](CREATURE:${CREATURE_MANES}), если её максимальное значение хитов становится равно 0. Превращение может быть отменено только заклинанием [исполнение желаний](SPELL:${SPELL_WISH})`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 15,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака',
      description: `Молидей совершает одну атаку своим демоническим оружием или змеиным укусом`,
    },
    {
      name: 'Передвижение',
      description: `Молидей передвигается, не провоцируя атаки.`,
    },
    {
      name: 'Наложение заклинания',
      description: `Молидей применяет одно из заклинаний с особенности «Врождённое колдовство».`,
    },
  ],
}
