const {CREATURE_NECROMANCER} = require('./../../creatureIdList')
const {SOURCE_MTOF} = require('./../../sourceList')

module.exports = {
  header: 'Дэтлок',
  text: `Нарушение договора между колдуном и покровителем не относится к малозначимым событиям, по крайней мере для колдуна. Последствия разрыва такого договора могут быть ужасны, а иногда и смертельны. Колдун, который не оправдал надежд злого покровителя, рискует восстать из мёртвых в виде дэтлока — отвратительной нежити, призванной служить своему покровителю после смерти.

Необычайно могущественный [некромант](CREATURE:${CREATURE_NECROMANCER}) может отыскать способ создания дэтлока, а затем заставить его служить себе, исполняя роль его покровителя.

**Покорные и одержимые.** Разум поднятого дэтлока подчинён лишь непреодолимому желанию служить. Цели и амбиции, которые были у него при жизни, исчезают, если неугодны его покровителю, поскольку желания покровителя становятся той волей, которая движет дэтлоком. Существо сразу же начинает действовать во имя своего покровителя. Для выполнения сложной задачи дэтлок может быть вынужден служить другому могущественному существу или искать себе слуг.

Какой бы ни была цель, она всегда отражает интересы покровителя, от мелких проблем и до вопросов вселенского масштаба. Дэтлок, находящийся в рабстве у исчадия, может действовать с целью уничтожения храма доброго божества, в то время как слуга Великого Древнего может искать редкие компоненты для призыва ужасающей сущности в мир.

**Природа нежити.** Дэтлок не нуждается в воздухе, еде, питье или сне.`,
  source: {
    id: SOURCE_MTOF,
    page: 197,
  },
}
