const {PC_SUBCLASS_FIGHTER_CAVALIER} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {GEAR_SHIELD} = require('./../../../gearIdList')
const {
  FEATURE_BONUS_PROFICIENCY_FIGHTER_CAVALIER,
  FEATURE_BORN_TO_THE_SADDLE,
  FEATURE_FEROCIOUS_CHARGER,
  FEATURE_HOLD_THE_LINE,
  FEATURE_UNWAVERING_MARK,
  FEATURE_VIGILANT_DEFENDER,
  FEATURE_WARDING_MANEUVER,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BONUS_PROFICIENCY_FIGHTER_CAVALIER,
    name: `Дополнительные владения`,
    nameEn: `Bonus Proficiency`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_FIGHTER_CAVALIER,
    text: `Вы получаете владение одним из следующих навыков по своему выбору:

* Выступление,
* История,
* Проницательность,
* Убеждение,
* Уход за Животными.

В качестве альтернативы Вы можете выучить один язык по своему выбору.`,
    source: {
      id: SOURCE_XGTE,
      page: 20,
    },
  },
  {
    id: FEATURE_UNWAVERING_MARK,
    name: `Непоколебимая метка`,
    nameEn: `Unwavering Mark`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_FIGHTER_CAVALIER,
    text: `Вы можете угрожать врагам, мешая их атакам и карая их за вред другим. Когда Вы попадаете по существу рукопашной атакой оружием, Вы можете пометить это существо до конца Вашего следующего хода. Эффект метки заканчивается, если Вы становитесь недееспособным или умираете, или же если кто-то ещё отметит эту цель.

Пока отмеченное существо находится в пределах 5 футах от Вас, оно совершает с помехой броски атак, целью которых не являетесь вы.

Кроме того, если отмеченное существо наносит урон кому угодно, кроме Вас, Вы можете совершить особую рукопашную атаку оружием против помеченного существа бонусным действием в свой следующий ход. Эту атаку Вы совершаете с преимуществом, при попадании нанося дополнительный урон, равный половине Вашего уровня воина.

Независимо от количества существ, которых Вы отметили, Вы можете совершить эту специальную атаку количество раз, равное модификатору Вашей Силы (минимум один), и восстанавливаете все потраченные атаки, совершив длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 20,
    },
  },
  {
    id: FEATURE_BORN_TO_THE_SADDLE,
    name: `Рожденный в седле`,
    nameEn: `Born to the Saddle`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_FIGHTER_CAVALIER,
    text: `Ваше мастерство всадника становится очевидным. Вы получаете преимущество на испытания, чтобы избежать падения со скакуна. Если Вы падаете и остаетесь дееспособным, Вы приземляетесь на ноги в пределах 10 футов.

И наконец, забраться в седло и спешиться стоит Вам всего 5 футов передвижения, а не половину скорости.`,
    source: {
      id: SOURCE_XGTE,
      page: 20,
    },
  },
  {
    id: FEATURE_WARDING_MANEUVER,
    name: `Защитный маневр`,
    nameEn: `Warding Maneuver`,
    lvl: 7,
    pcSubClassId: PC_SUBCLASS_FIGHTER_CAVALIER,
    text: `Вы научились отражать удары, направленные на Вас, Вашего скакуна или других существ поблизости.

Если по Вам или по другому существу, видимому Вами в пределах 5 футов, попадают атакой, Вы можете реакцией сделать бросок 1к8, если в данный момент вооружены рукопашным оружием или надели [щит](GEAR:${GEAR_SHIELD}). Результат броска добавляется к КД цели против этой атаки. Если атака всё равно попадает, цель имеет сопротивление к урону от этой атаки.

Вы можете использовать это умение количество раз, равное модификатору Телосложения (минимум 1), и восстанавливаете все потраченные использования, совершив длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 20,
    },
  },
  {
    id: FEATURE_HOLD_THE_LINE,
    name: `Держать строй`,
    nameEn: `HOLD THE LINE`,
    lvl: 10,
    pcSubClassId: PC_SUBCLASS_FIGHTER_CAVALIER,
    text: `Вы становитесь мастером сдерживания своих противников.

Вы можете совершить провоцированную атаку, если враждебное существо перемещается на 5 футов и более, оставаясь в пределах досягаемости. Для этого Вы реакцией совершаете одну рукопашную атаку по спровоцировавшему существу, и если Вы попадаете по существу такой провоцированной атакой, его скорость уменьшается до 0 до конца текущего хода.`,
    source: {
      id: SOURCE_XGTE,
      page: 21,
    },
  },
  {
    id: FEATURE_FEROCIOUS_CHARGER,
    name: `Свирепый натиск`,
    nameEn: `Ferocious Charger`,
    lvl: 15,
    pcSubClassId: PC_SUBCLASS_FIGHTER_CAVALIER,
    text: `Вы можете сбивать своих врагов, независимо от того, на скакуне Вы или нет.

Если перед атакой существа Вы двигаетесь не менее 10 футов по прямой линии, а затем попадаете атакой, существо должно пройти испытание Силы (СЛ 8 + Ваш бонус мастерства + Ваш модификатор силы), иначе будет сбито с ног. Вы можете использовать эту особенность только один раз за ход.`,
    source: {
      id: SOURCE_XGTE,
      page: 21,
    },
  },
  {
    id: FEATURE_VIGILANT_DEFENDER,
    name: `Бдительный защитник`,
    nameEn: `Vigilant Defender`,
    lvl: 18,
    pcSubClassId: PC_SUBCLASS_FIGHTER_CAVALIER,
    text: `Вы реагируете на опасность с чрезвычайной бдительностью.

В бою Вы получаете особую реакцию, которую Вы можете использовать один раз за ход каждого существа, кроме Вас самих. Вы можете использовать эту реакцию только чтобы совершить провоцированную атаку и не можете использовать её в тот же ход, когда использовали свою нормальную реакцию.`,
    source: {
      id: SOURCE_XGTE,
      page: 21,
    },
  },
]
