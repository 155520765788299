const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_GATE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GATE,
  name: 'Врата',
  nameEn: `Gate`,
  description: `Вы создаёте портал, соединяющий свободное пространство, видимое Вами в пределах дистанции, с точным местом на другом плане существования. Портал представляет собой круглое отверстие, которое может быть диаметром от 5 до 20 футов. Вы можете ориентировать портал в любом направлении. Портал существует, пока заклинание активно.

На каждом плане у портала есть передняя и задняя сторона. Путешествовать через портал можно только проходя через его переднюю часть. Всё, что так проходит, мгновенно перемещается на другой план, появляясь в свободном пространстве рядом с порталом.

Божества и другие планарные правители могут предотвращать создание порталов этим заклинанием в их присутствии или где угодно в пределах их домена.

Когда Вы накладываете это заклинание, Вы можете назвать имя конкретного существа (псевдоним, титул или прозвище не подходят). Если это существо находится на другом плане, портал открывается непосредственно возле него, и это существо втягивается, оказываясь в ближайшем свободном пространстве с Вашей стороны портала. Вы не получаете над ним никакого особого контроля, и оно может действовать так, как сочтёт нужным Мастер. Оно может уйти, может напасть на Вас, а может помочь вам.`,
  lvl: 9,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `бриллиант, стоящий как минимум 5 000 зм`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 219,
    },
    {
      id: SOURCE_SRD,
      page: 148,
    },
  ],
}
