const {
  CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  CREATURE_KOBOLD_DRAGONSHIELD_GREEN,
} = require('./../../../../creatureIdList')
const {DAMAGE_POISON} = require('./../../../../damageTypeList')

module.exports = {
  name: 'Кобольд зелёный драконий щит',
  nameEn: 'Kobold Dragonshield (Green)',
  id: CREATURE_KOBOLD_DRAGONSHIELD_GREEN,
  parentId: CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  resistanceList: [
    DAMAGE_POISON,
  ],
}
