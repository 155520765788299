const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_DART,
  GEAR_SCIMITAR,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_ORC,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_GIANT_BAT,
  CREATURE_ORC_RED_FANG_OF_SHARGAAS,
} = require('./../../../../creatureIdList')
const {
  orcDescription,
  orcNote,
} = require('./../../../../textCommonParts')
const {ABILITY_CUNNING_ACTION} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {GOD_SHARGAAS} = require('./../../../../godIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_DARKNESS} = require('./../../../../spellIdList')

module.exports = {
  name: 'Орк красный клык Шаргааса',
  nameEn: 'Orc red fang of Shargaas',
  id: CREATURE_ORC_RED_FANG_OF_SHARGAAS,
  description: [
    `[Шаргаас](GOD:${GOD_SHARGAAS}) — это орочье божество глубокой тьмы и скрытности, бог убийца, который ненавидит всё живое, кроме орков. Орки считают [Шаргааса](GOD:${GOD_SHARGAAS}) божеством, подходящим для отверженных и слабаков, неподходящих для существенных ролей в племени. Эти неудачники живут в самых отдалённых и глубоких частях владений племени.

Ассассины и воры — элита среди последователей [Шаргааса](GOD:${GOD_SHARGAAS}), которые входят в культ Красного Клыка. Они совершают убийства, скрытные нападения и другие тайные операции для племени. Они полагаются на смесь усиленных тренировок и магии, дарованной им [Шаргаасом](GOD:${GOD_SHARGAAS}).

Большинство анклавов Красных Клыков держат и воспитывают [гигантских летучих мышей](CREATURE:${CREATURE_GIANT_BAT}), которые считаются священными для [Шаргааса](GOD:${GOD_SHARGAAS}). Красные Клыки седлают их в бою или во время скрытных нападений и убийств на вражеской территории.`,
    ...orcDescription,
  ],
  note: orcNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ORC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 180,
  },
  armor: {
    ac: 15,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ORC,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_CUNNING_ACTION,
    {
      name: 'Рука Шаргааса',
      description: `Орк наносит 2 дополнительных кости урона, когда попадает по цели атакой оружием (уже включено в его атаки).`,
    },
    {
      name: 'Зрение Шаргааса',
      description: `Магическая тьма не препятствует тёмному зрению орка.`,
    },
    {
      name: 'Убийца',
      description: `В первый раунд боя орк имеет преимущество на броски атаки по любому существу, которое ещё не ходило. Если в этот раунд орк попадает по существу, которое застали врасплох, то это попадание автоматически считается критическим.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Орк делает две атаки _Скимитаром_ или _Дротиком_.`,
    },
    {
      name: 'Скимитар',
      // gearId: GEAR_SCIMITAR, // TODO: Найти способ использовать gearId
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Дротик',
      // gearId: GEAR_DART, // TODO: Найти способ использовать gearId
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Вуаль Шаргааса',
      comment: `Перезаряжается после короткого или долгого отдыха`,
      description: `Орк сотворяет [Тьму](SPELL:${SPELL_DARKNESS}) без использования любых компонентов. Его базовая характеристика для этого заклинания Мудрость.`,
    },
  ],
}
