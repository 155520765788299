const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_HUGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  dinoDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_TRAMPLING_CHARGE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TRICERATOPS,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Трицератопс',
  nameEn: 'Triceratops',
  id: CREATURE_TRICERATOPS,
  description: [
    `Один из самых агрессивных травоядных динозавров. Череп трицератопса имеет костяной воротник, служащий для защиты. С внушительными рогами и впечатляющей скоростью трицератопс может без проблем нанизать хищника на рога или растоптать.`,
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 97,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 17,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 5,
  },
  cr: CR_5,
  featureList: [
    {
      id: ABILITY_TRAMPLING_CHARGE,
      attackName_1: 'Боданием',
      attackName_2: 'Растаптыванием',
      strSave_DC: 13,
    },
  ],
  actionList: [
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Растаптывание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            condition: CONDITION_PRONE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
