const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_ENERVATION} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_ENERVATION,
  name: 'Обессиливание',
  nameEn: 'Enervation',
  description: `Из Вас тянется отросток чернильной тьмы, касаясь существа, которое Вы можете видеть в пределах дистанции, и вытягивая его жизненные силы. Цель должна пройти испытание Ловкости. При успешном испытании цель получает 2к8 урона некротической энергией, и заклинание заканчивается. При провале испытания цель получает 4к8 урона некротической энергией, и до тех пор, пока заклинание не закончится, Вы можете в каждый свой ход действием наносить цели урон некротической энергией 4к8. Заклинание заканчивается, если Вы используете свое действие, чтобы делать что-либо еще, если цель окажется за пределами дистанции заклинания или получит от Вас полное укрытие.

Всякий раз, когда заклинание наносит урон цели, Вы восстанавливаете хиты, равные половине количества урона некротической энергией, наносимого цели.`,
  highLvlCast: `Когда Вы произносите это заклинание, используя ячейку заклинания 6 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше 5.`,
  lvl: 5,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_NECROTIC,
      diceCount: 4,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 158,
  },
}
