const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {CREATURE_WATER_ELEMENTAL} = require('./../../../../creatureIdList')
const {elementalDescription} = require('./../../../../textCommonParts')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_AQUAN} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Водяной элементаль`,
  nameEn: 'Water Elemental',
  id: CREATURE_WATER_ELEMENTAL,
  description: [
    `Водяной элементаль вздымающейся волной катится по земле, становясь почти невидимым в водоёме. Он поглощает существ, которые стоят у него на пути, заполняет их рот и лёгкие, так же легко, как тушит пламя.`,
    elementalDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 303,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 90,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_UNCONSCIOUS,
    CONDITION_GRAPPLED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AQUAN,
  ],
  cr: CR_5,
  featureList: [
    {
      name: `Водяное тело`,
      description: `★СУЩЕСТВО★ может входить в пространство враждебных существ и останавливаться там. ★Он★ может перемещаться сквозь пространство шириной в 1 дюйм без протискивания.`,
    },
    {
      name: `Замерзание`,
      description: `Если ★СУЩЕСТВО★ получает урон холодом, ★он★ частично замерзает; ★его★ скорость уменьшается на 20 футов до конца ★его★ следующего хода.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает два _Удара_.`,
    },
    {
      name: `Удар`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Захлёст`,
      restore: 4,
      description: `Все существа в пространстве ★СУЩЕСТВО★ должны пройти испытание Силы СЛ 15. При провале цель получает дробящий урон 13 (2к8+4). Если размер цели не больше Большого, она также становится схваченной (СЛ высвобождения 14). Пока цель схвачена, она обездвижена и не может дышать, если только не может дышать под водой. Если испытание пройдено, цель выталкивается из пространства элементаля.

★СУЩЕСТВО★ может одновременно держать в захвате одно существо Большого размера или до двух существ Среднего размера. В начале каждого хода ★СУЩЕСТВО★ каждая схваченная ★им★ цель получает дробящий урон 13 (2к8 + 4). Существо, находящееся в пределах 5 футов от ★СУЩЕСТВО★, может вытянуть существо или предмет, если совершит действием проверку Силы СЛ 14 и преуспеет.`,
    },
  ],
  genderId: GENDER_MALE,
}
