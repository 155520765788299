const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_ESPRUAR} = require('./../../alphabetList')
const {LANG_AGLARONDAN} = require('./../../languageIdList')
const {
  PLACE_AGLAROND,
  PLACE_ALTUMBEL,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_AGLARONDAN,
  alphabetId: ALPHABET_ESPRUAR,
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Aglarondan',
  name: {
    nominative: 'Агларондский',
    genitive: 'Агларондского',
    instrumental: 'Агларондским',
    prepositional: 'Агларондском',
  },
  spokenPlaceList: [
    PLACE_AGLAROND,
    PLACE_ALTUMBEL,
  ],
  isReady: false,
  isRealLang: true,
}
