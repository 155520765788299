const {
  WEAPON_LIGHT,
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {CREATURE_THRI_KREEN} = require('./../../../creatureIdList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_CHATKCHA} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_MM} = require('./../../../sourceList')

module.exports = {
  id: GEAR_CHATKCHA,
  genderId: GENDER_FEMALE,
  name: 'Чаткча',
  nameByCase: {
    nominative: 'чаткча',
    genitive: 'чаткчи',
    accusative: 'чаткчу',
    instrumental: 'чаткчой',
  },
  nameEn: 'Chatkcha',
  description: `Чаткча это особое плоское оружие [три-кринов](CREATURE:${CREATURE_THRI_KREEN}) с тремя треугольными лезвиями.`,
  damage: {
    diceCount: 1,
    diceType: 6,
  },
  damageType: DAMAGE_SLASHING,
  source: {
    id: SOURCE_MM,
    page: 284,
  },
  weaponPropList: [
    WEAPON_LIGHT,
    WEAPON_THROWN,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 30,
        max: 120,
      },
    },
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
