const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_HOMUNCULUS} = require('./../../creatureTypeIdList')
const {homunculiDescriptionList} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_HOMUNCULUS,
  nameEn: 'Homunculus',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'гомункул',
      genitive: 'гомункула',
      dative: 'гомункулу',
      accusative: 'гомункула',
      instrumental: 'гомункулом',
      prepositional: 'гомункуле',
    },
    plural: {
      nominative: 'гомункулы',
      genitive: 'гомункулов',
      dative: 'гомункулам',
      accusative: 'гомункулов',
      instrumental: 'гомункулами',
      prepositional: 'гомункулах',
    },
  },
  description: homunculiDescriptionList,
}
