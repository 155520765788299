const generateFullAdjectiveList = require('./../utils/generateFullAdjectiveList')
const listToCollectionById = require('./../utils/listToCollectionById')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
} = require('./genderList')

const SIZE_ANY = 'any'
const SIZE_TINY = 'tiny'
const SIZE_SMALL = 'sm'
const SIZE_MEDIUM = 'med'
const SIZE_LARGE = 'lg'
const SIZE_HUGE = 'huge'
const SIZE_GARGANTUA = 'grg'

const sizeList = [
  {
    id: SIZE_ANY,
    name: {
      singular: {
        [GENDER_FEMALE]: {
          nominative: 'любая',
          genitive: 'любой'
        },
        [GENDER_MALE]: {
          nominative: 'любой',
          genitive: 'любого',
          prepositional: 'любом'
        },
        [GENDER_MIDDLE]: {
          nominative: 'любое'
        }
      },
      dualFemale: {
        nominative: 'любые',
        genitive: 'любых'
      }
    },
  },
  {
    id: SIZE_TINY,
    hitDiceSize: 4,
    description: `Существо крошечного размера

Занимает на сетке: 4 в одной клетке или 4 в гексе
Съедает в день: ¼ фунта еды и ¼ галлона воды`,
    name: {
      singular: {
        [GENDER_FEMALE]: {
          nominative: 'крошечная',
          genitive: 'крошечной'
        },
        [GENDER_MALE]: {
          nominative: 'крошечный',
          genitive: 'крошечного',
          prepositional: 'крошечном'
        },
        [GENDER_MIDDLE]: {
          nominative: 'крошечное'
        }
      },
      dualFemale: {
        nominative: 'крошечные',
        genitive: 'крошечных'
      }
    },
  },
  {
    id: SIZE_SMALL,
    hitDiceSize: 6,
    description: `Существо маленького размера

Занимает на сетке: 1 клетка или 1 гекс
Съедает в день: 1 фунт еды и 1 галлон воды`,
    name: {
      singular: {
        [GENDER_FEMALE]: {
          nominative: 'маленькая',
          genitive: 'маленькой'
        },
        [GENDER_MALE]: {
          nominative: 'маленький',
          genitive: 'маленького',
          prepositional: 'маленьком'
        },
        [GENDER_MIDDLE]: {
          nominative: 'маленькое'
        }
      },
      dualFemale: {
        nominative: 'маленькие',
        genitive: 'маленьких'
      }
    },
  },
  {
    id: SIZE_MEDIUM,
    hitDiceSize: 8,
    description: `Существо среднего размера

Занимает на сетке: 1 клетка или 1 гекс
Съедает в день: 1 фунт еды и 1 галлон воды`,
    name: {
      singular: {
        [GENDER_FEMALE]: {
          nominative: 'средняя',
          genitive: 'средней'
        },
        [GENDER_MALE]: {
          nominative: 'средний',
          genitive: 'среднего',
          prepositional: 'среднем'
        },
        [GENDER_MIDDLE]: {
          nominative: 'среднее'
        }
      },
      dualFemale: {
        nominative: 'средние',
        genitive: 'средних'
      }
    },
  },
  { // Hobby World — Крупный
    id: SIZE_LARGE,
    hitDiceSize: 10,
    description: `Существо большого размера

Занимает на сетке: 4 клетки (2×2) или 3 гекса
Съедает в день: 4 фунта еды и 4 галлона воды`,
    name: {
      singular: {
        [GENDER_FEMALE]: {
          nominative: 'большая',
          genitive: 'большой'
        },
        [GENDER_MALE]: {
          nominative: 'большой',
          genitive: 'большого',
          prepositional: 'большом'
        },
        [GENDER_MIDDLE]: {
          nominative: 'большое'
        }
      },
      dualFemale: {
        nominative: 'большие',
        genitive: 'больших'
      }
    },
  },
  {
    id: SIZE_HUGE,
    hitDiceSize: 12,
    description: `Существо огромного размера

Занимает на сетке: 9 клеток (3×3) или 7 гексов
Съедает в день: 16 фунтов еды и 16 галлонов воды`,
    name: {
      singular: {
        [GENDER_FEMALE]: {
          nominative: 'огромная',
          genitive: 'огромной'
        },
        [GENDER_MALE]: {
          nominative: 'огромный',
          genitive: 'огромного',
          prepositional: 'огромном'
        },
        [GENDER_MIDDLE]: {
          nominative: 'огромное'
        }
      },
      dualFemale: {
        nominative: 'огромные',
        genitive: 'огромных'
      }
    },
  },
  {
    id: SIZE_GARGANTUA,
    hitDiceSize: 20,
    description: `Существо громадного размера

Занимает на сетке: 16+ клеток (4×4 или больше) или 12+ гексов
Съедает в день: 64 фунта еды и 64 галлона воды`,
    name: {
      singular: {
        [GENDER_FEMALE]: {
          nominative: 'громадная',
          genitive: 'громадной'
        },
        [GENDER_MALE]: {
          nominative: 'громадный',
          genitive: 'громадного',
          prepositional: 'громадном'
        },
        [GENDER_MIDDLE]: {
          nominative: 'громадное'
        }
      },
      dualFemale: {
        nominative: 'громадные',
        genitive: 'громадных'
      }
    },
  }
].map(generateFullAdjectiveList)

module.exports = sizeList

module.exports.sizeCollection = listToCollectionById(sizeList)

module.exports.SIZE_ANY = SIZE_ANY
module.exports.SIZE_TINY = SIZE_TINY
module.exports.SIZE_SMALL = SIZE_SMALL
module.exports.SIZE_MEDIUM = SIZE_MEDIUM
module.exports.SIZE_LARGE = SIZE_LARGE
module.exports.SIZE_HUGE = SIZE_HUGE
module.exports.SIZE_GARGANTUA = SIZE_GARGANTUA
