const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ADAMANTINE_ARMOR} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')
const gearTypeExclude = require('./gearTypeExclude')
const gearCategoryList = require('./gearCategoryList')

module.exports = {
  id: MAGIC_ITEM_ADAMANTINE_ARMOR,
  name: `Адамантиновый доспех`,
  nameEn: `Adamantine Armor`,
  type: MGC_TYPE_ARMOR,
  gearTypeExclude,
  gearCategory: gearCategoryList,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Этот доспех усилен адамантином, одним из самых прочных из существующих веществ.

Пока Вы носите эти доспехи, все критические попадания по Вам считаются обычными попаданиями.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 151,
  },
}
