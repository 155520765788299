const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_DECEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_SCIMITAR,
} = require('./../../../../gearIdList')
const {
  CREATURE_ACOLYTE,
  CREATURE_CULTIST,
} = require('./../../../../creatureIdList')
const {ABILITY_DARK_DEVOTION} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NOT_GOOD} = require('./../../../../aligmentList')
const {CR_1_8} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Культист',
  nameEn: 'Cultist',
  id: CREATURE_CULTIST,
  description: `Культисты клянутся в преданности тёмным силам, таким как стихийные князья, демонические повелители и архидьяволы. Многие скрывают эту преданность, боясь изгнания, заключения или даже казни. В отличие от злых [прислужников](CREATURE:${CREATURE_ACOLYTE}), у культистов часто есть элементы безумия в вере или ритуалах.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_NOT_GOOD,
  source: {
    id: SOURCE_MM,
    page: 346,
  },
  armor: {
    ac: 12,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 10,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_DARK_DEVOTION,
  ],
  actionList: [
    {
      gearId: GEAR_SCIMITAR,
    },
  ],
  genderId: GENDER_MALE,
}
