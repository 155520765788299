const {ATTACK_MELEE_SPELL} = require('./../../../../../attackTypeList')
const {DAMAGE_PIERCING} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SOURCE_PHB} = require('./../../../../../sourceList')
const {SPELL_THORN_WHIP} = require('./../../../../../spellIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')

module.exports = {
  id: SPELL_THORN_WHIP,
  name: 'Терновый кнут',
  nameEn: 'Thorn Whip',
  description: `Вы создаёте длинный, похожий на лозу кнут, покрытый шипами, бьющий по Вашей команде существо, находящееся в пределах дистанции. Совершите рукопашную атаку заклинанием по цели. Если атака попадает, существо получает колющий урон 1к6, и если размер существа не больше Большого, Вы подтягиваете существо на 10 футов к себе.`,
  highLvlCast: `Урон этого заклинания увеличивается на 1к6, когда Вы достигаете 5 уровня (2к6), 11 уровня (3к6) и 17 уровня (4к6).`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `стебель растения с шипами`,
  effect: {
    attackType: ATTACK_MELEE_SPELL,
    damage: {
      type: DAMAGE_PIERCING,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_PHB,
    page: 284,
  },
}
