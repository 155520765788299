const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  FEATURE_ANIMATING_PERFORMANCE,
  FEATURE_PRIMAL_COMPANION,
} = require('./../../../../featureIdList')
const {
  PC_SUBCLASS_RANGER_BEAST_MASTER,
} = require('./../../../../pcSubClassIdList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ITS_SUMMONER,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_CHARGE,
  ABILITY_PRIMAL_BOND,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_BEAST_OF_THE_LAND,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Лесной зверь',
  nameEn: 'Beast of the Land',
  id: CREATURE_BEAST_OF_THE_LAND,
  description: `Призывается способностью [Первобытный спутник](FEATURE:${FEATURE_PRIMAL_COMPANION}) следопыта [Повелителя зверей](PC_SUBCLASS:${PC_SUBCLASS_RANGER_BEAST_MASTER}).`,
  createdByFeatureId: FEATURE_ANIMATING_PERFORMANCE,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TCoE,
    page: 90,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
    comment: '+ бонус мастерства следопыта',
  },
  hp: `5 + пятикратный уровень следопыта (у зверя есть кость хитов к8, их количество равно уровню следопыта)`,
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 15,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_SUMMONER,
      onlyUnderstands: true,
    },
  ],
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Терзанием',
      damageText: '1к6 рубящего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы против сложности заклинаний следопыта, или будет сбита с ног.',
    },
    ABILITY_PRIMAL_BOND,
  ],
  actionList: [
    {
      name: 'Терзание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 'бонус атаки заклинаниями следопыта',
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: '2 + бонус мастерства следопыта',
        },
      },
    },
  ],
}
