const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_VAMPIRE,
  CREATURE_VAMPIRIC_MIST,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Вампирический туман',
  nameAlt: 'Багровый туман',
  nameEn: 'Vampiric Mist',
  nameEnAlt: 'Crimson Mist',
  id: CREATURE_VAMPIRIC_MIST,
  description: `Во мгле обитает вампирический туман, жалкие остатки [вампиров](CREATURE:${CREATURE_VAMPIRE}), что не смогли найти покоя. Будучи неразличимыми в туманной местности, в которой они обитают, они нападают скрытно и незаметно, высасывая кровь жертву досуха.

**Бывшие вампиры.** Вампирический туман, иногда называемый багровым туманом, всё, что осталось от вампиров, которые не смогли вернутся в свои захоронения после того, как были побеждены или пострадали в несчастном случае. Лишённые восстановительных сил из этих мест, тела вампиров растворяются в туман. Трансформация отдирает интеллект и личность от тела, пока не останется только нечестивая, ненасытная жажда крови.

**Кровосос.** Неотличимый от мглы, кроме исходящего из него сильнейшего мерзкого запаха, вампирический туман окутывает существо и высасывает его сочную кровь через ткани кожи, либо через глазные и носовые отверстия, и ротовую полость. Кровь, что высасывается из жертвы, принимает вид лёгкого дыма алого цвета, который поглощается туманом. Высасывание крови не причиняет боли или дискомфорта жертве, поэтому вампирические туманы могут питаться спящими, даже не разбудив их. Чем больше туман питается, тем более краснее он становится, начиная с розового, затем красного, и заканчивая густым багряным оттенком, после которого, из этой сущности, во время перемещения, проливаются капли крови.

**Влечение к крови.** Подобно акуле в воде, вампирический туман может учуять кровь на расстоянии до мили. Любая травма, какой бы маленькой она не была, может привлечь внимание и притянуть туман к жертве. В битве, туман фокусирует атаки на раненых существах, поскольку открытые раны являются готовыми источниками крови.

**Природа нежити.** Вампирический туман не нуждается в воздухе или сне.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 123,
  },
  armor: 13,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NECROTIC,
    DAMAGE_THUNDER,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Чувство жизни',
      description: `Вампирический туман может почувствовать любое существо в радиусе 60 футов от себя, если только это не нежить или конструкт.`,
    },
    {
      name: 'Запрет',
      description: `Туман не может войти в жильё без приглашения одного из его обитателей.`,
    },
    {
      name: 'Туманная форма',
      description: `Туман может занимать чужое пространство и наоборот. Кроме того, если воздух может проходить сквозь пространство, то и туман может проходить сквозь него без протискивания. Каждый фут передвижения в воде стоит 2 дополнительные фута, а не 1 дополнительный фут. Туман не может взаимодействовать с объектами, требующими наличия пальцев или ловкости рук.`,
    },
    {
      name: 'Гиперчувствительность к солнечному свету',
      description: `Туман получает 10 урона излучением каждый раз, когда начинает ход под солнечным светом. Находясь на солнечном свету, он совершает с помехой броски атаки и проверки характеристик.`,
    },
  ],
  actionList: [
    {
      name: 'Вытягивание жизни',
      description: `Туман касается одного существа в своём пространстве. Цель должна пройти испытание Телосложения СЛ 13 (нежить и конструкты делают это автоматически), иначе получит 10 (2к6 + 3) урона некротической энергией; туман восстанавливает 10 хитов в то время, как максимальное значение хитов цели понижается на значение полученного урона некротической энергией. Понижение хитов длится до завершения целью длинного отдыха. Цель умирает, если максимальное значение её хитов снизится до 0.`,
    },
  ],
}
