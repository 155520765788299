const {CREATURE_GARGOYLE} = require('./../../creatureIdList')
const {
  SOURCE_EE,
  SOURCE_MM,
} = require('./../../sourceList')

module.exports = [
  {
    header: 'Ааракокры',
    text: `Живущие в изоляции высоко в горах на вершинах самых высоких деревьев, ааракокры, называемые иногда «птичьим народом», вызывают страх и удивление. Многие ааракокры даже не являются уроженцами Материального плана. Они родом из мира, находящегося очень далеко — с бескрайних просторов Стихийного Плана Воздуха. Это иммигранты, беженцы, разведчики и исследователи, а их форпосты служат им плацдармом в неизвестном и чужом мире.`,
    source: {
      id: SOURCE_EE,
      page: 3,
    },
  },
  {
    header: 'Клюв и перо',
    text: `С земли ааракокры выглядят как большие птицы. Только когда они спускаются на свои насесты на ветвях или ходят по земле, раскрывается их гуманоидный облик. Стоя прямо, ааракокра может достигать 5 футов высотой. У них длинные, тонкие ноги, заканчивающиеся острыми когтями.

Их тела покрыты перьями. Цвет их оперения обычно означает положение в племени. У мужчин перья ярко выкрашены в красный, оранжевый или жёлтый цвета. У женщин же цвета более приглушённые, как правило, это коричневый или серый. Их птичьи головы внешне напоминают гибрид попугая и орла с вариациями, характерными для разных племён.`,
    source: {
      id: SOURCE_EE,
      page: 3,
    },
  },
  {
    header: 'Хранители неба',
    text: `Наиболее комфортно ааракокры чувствуют себя в небе. Они могут проводить часы в воздухе, а некоторые даже и дни, расправляя крылья и позволяя восходящим потокам тёплого воздуха держать их в небесах. В сражении они показывают все свои способности динамики и акробатики, двигаясь с невероятной скоростью и изяществом, набрасываясь с оружием или выставленными вперёд когтями на противника, а затем отлетая обратно.

Однажды летавший ааракокра оставляет небо с неохотой. На своём родном плане они могут летать в течение нескольких дней или даже месяцев, приземляясь только чтобы отложить яйца и накормить своих птенцов, после чего отправляются обратно в небеса. Попавшие на Материальный план считают это место странным.

Они иногда забывают или просто игнорируют вертикальные расстояния, а к народам, вынужденным жить и трудиться на земле, они не испытывают ничего кроме жалости.`,
    source: {
      id: SOURCE_EE,
      page: 3,
    },
  },
  {
    header: 'Птичьи особенности',
    text: `Сходство ааракокр и птиц не ограничивается лишь физическим. Многие ааракокры имеют манеру поведения как у самых обычных птиц. Они скрупулёзно ухаживают за своими перьями, чистят их и вычёсывают «пассажиров», которых могли подхватить. Когда они всё-таки спускаются с небес, то предпочитают делать это рядом с водоёмами, где они могут ловить рыбу и купаться сами.

Многие ааракокры добавляют в свою речь щебет, чтобы акцентировать внимание на том, что у сказанного может быть и другой смысл, так же как человек сделал бы это при помощи мимики и жестов. Ааракокра может расстроиться, если собеседник не в состоянии понять эти нюансы; например, угроза может быть воспринята как насмешка, и наоборот.

Идея владения чем-либо озадачивает большинство ааракокр. Разве может кто-то владеть небом? Даже когда им объясняют, они первоначально полагают, что собственность — это нечто мистическое. В результате у ааракокр, которые мало взаимодействуют с другими народами, могут быть неприятности, когда они спускаются с неба, чтобы забрать домашний скот, фрукты или зерно. Блестящие сверкающие объекты привлекают их взгляд. Им очень трудно не воровать подобные сокровища, так как считают, что они отлично подходят для украшения жилища. Ааракокры, которые проводят годы среди других рас, могут научиться подавлять эти порывы.

Заключение пугает ааракокр. Не иметь возможности летать, быть в ловушке под землёй или в ледяной тюрьме — это пытка, которую не каждый ааракокра может выдержать. Даже сидя на высоких ветвях или отдыхая в своих домах на вершине горы, они всегда настороже с бегающими глазами и телами, готовыми взлететь.`,
    source: {
      id: SOURCE_EE,
      page: 3,
    },
  },
  {
    header: 'Места обитания',
    text: `Большинство ааракокр живёт на Стихийном Плане Воздуха. Ааракокры могут оказаться на Материальном плане, преследуя врагов или пытаясь помешать их планам на той стороне. Несчастный случай может перенести гнездо ааракокр на другой план. Некоторые проникают в этот мир через порталы на своём собственном плане, после чего гнездятся в высоких горах или под пологом старых лесов.

После того, как племя ааракокр поселится в районе, они разделяют охотничьи угодья, площадь которых может достигать до 100 миль в поперечнике, и начинают охотиться в землях возле своей колонии, продвигаясь дальше, когда ресурсов становится недостаточно.

Типичная колония состоит из одного большого гнезда без крыши, сплетённого из ветвей. Старейшина, при поддержке шамана, выступает в качестве лидера.`,
    source: {
      id: SOURCE_EE,
      page: 3,
    },
  },
  {
    header: 'Ааракокры в Забытых Королевствах',
    text: `Не особо хорошо обосновавшись в Фаэруне, аракокры имеют только четыре крупных колонии: в Звёздных горах в Высоком лесу, в Рогах Бури в Кормире, в Расколотых горах на Вилхонской протоке и в Дымчатых утёсах в Чульте.

Колонии, находящиеся в Звёздных горах, рядом с долиной Дессарин, всегда были скрытными, и перемещались по Высокому лесу только в определённых местах. Жестокий и жадный зелёный дракон почти истребил населении колонии и рассеял выживших. Эти ааракокры и их потомки поклялись отомстить дракону, и их можно найти в землях Севера и Кормира, ищущих своего обидчика.

Их единственное оставшееся поселение находится на южных склонах Звёздных гор. В верховьях Бега Единорога находится Последнее Орлиное гнездо, которое является домом для нескольких десятков ааракокр. Недавно старейшины обнаружили изменения в преобладающих ветрах и расценили это как плохое предзнаменование.

В отличие от представителей своей расы из других миров, на Материальном плане ааракокры Королевств редко путешествуют на Стихийный План Воздуха.`,
    source: {
      id: SOURCE_EE,
      page: 3,
    },
  },
  {
    header: 'Великая цель',
    text: `Ааракокры наслаждаются тишиной и уединением. Большинство из них мало заинтересовано в общении с другими народами, и ещё меньше заинтересовано проводить время на земле. Поэтому для ааракокр нужны веские причины, чтобы оставить своё племя и стать искателем приключений. Ни сокровищ, ни славы недостаточно, чтобы соблазнить их оставить своё племя. Обычно в основе выбора пути искателя приключений лежит ужасная угроза их народу, месть или катастрофа.

Есть ещё два обстоятельства, которые могут стать причиной для становления искателем приключений.

Во-первых, у ааракокр есть историческая связь с Герцогами Ветра Аакуа. Некоторые особи чтят эту связь и могут отправиться на поиски потерянных кусков Жезла Семи Частей, остатков артефакта, давно сотворённого Герцогами Ветра для победы над чудовищным героем Королевы Хаоса, Мишкой Волчьим Пауком. Когда жезл оказался внутри Мишки, хаос в его крови расколол жезл и разбросал его части по мультивселенной. Восстановление кусочков жезла — это великая честь и дань уважения ваати, сотворившим его, а также возможность восстановить могущественное оружие для борьбы с агентами стихийного зла.

Во-вторых, ааракокры — заклятые враги элементалей земли, в частности, горгулий, которые служат Огремоху, Князю Земли. Слово, которым ааракокры называют горгулий, дословно переводится как «летающий камень», а бои между ааракокрами и горгульями проходят и на плане земли и на плане воздуха, иногда вырываясь даже на Материальный план. Ааракокры на этом плане могут оставлять свои колонии, чтобы помочь другим гуманоидам в борьбе с культами земли.`,
    source: {
      id: SOURCE_EE,
      page: 4,
    },
  },
  {
    header: `Ааракокры`,
    text: `Ааракокры населяют Воющий Вихрь, бесконечный шторм могучих ветров и дождей, который окружает царство Аакуа в Стихийном Плане Воздуха. Осуществляя воздушное патрулирование, эти птицеподобные гуманоиды охраняют воздушные границы своего дома от захватчиков из Стихийного Плана Земли, своих заклятых врагов — [горгулий](CREATURE:${CREATURE_GARGOYLE}).`,
    source: {
      id: SOURCE_MM,
      page: 14,
    },
  },
  {
    header: `Враги Стихийного Зла`,
    text: `На службе у Воздушных Герцогов Аакуы ааракокры разведывают планы в поисках храмов Стихийного Зла. Они шпионят за злыми стихийными существами, а затем либо вступают с ними в бой, либо возвращаются с докладом к герцогам.
  
  На Материальном плане ааракокры возводят гнёзда на вершинах самых высоких гор, особенно на пиках вблизи порталов, ведущих на Стихийный План Воздуха. С этих высот ааракокры следят за признаками стихийного вторжения, а также за малейшими угрозами для своего домашнего плана. Ааракокры предпочитают жить сами по себе, как перекати-поле, скитаясь по области в течение многих лет, защищая её от набегов Стихийного Зла.
  
  У ааракокр нет представления о политических границах или личной собственности, они не знают цену драгоценным камням, золоту и другим драгоценностям. Всё это для них это ничего не значит. По их мнению, существо должно использовать то, что необходимо, а потом бросить это на ветер, чтобы этим воспользовались другие.`,
    source: {
      id: SOURCE_MM,
      page: 14,
    },
  },
  {
    header: `Поиск семи осколков`,
    text: `Воздушные Герцоги Аакуы происходят от стихийных существ, называемых _ваати_, которые некогда правили множеством миров. Существо, известное как Королева Хаоса, появившись, развязало межплановую войну с ваати. Для борьбы с этой угрозой семь героев ваати объединили свои силы, чтобы создать могущественный _Жезл Закона_. В битве с величайшим генералом королевы, Мишкой Волчьим Пауком, ваати убили Мишку, проткнув его жезлом как копьём. Жезл разбился на семь осколков, которые разлетелись по всей мультивселенной. Ааракокры ищут эти осколки в разных местах, чтобы восстановить то, что теперь известно как _Жезл Семи Частей_.`,
    source: {
      id: SOURCE_MM,
      page: 14,
    },
  },
  {
    header: 'Имена ааракокр',
    text: `Как и большая часть их речи имена ааракокр содержат щелчки, трели и посвистывания до такой степени, что другим народам сложно их выговорить. Как правило в имени присутствует от двух до четырёх слогов, со звуками, действующими как соединители. При взаимодействии с другими расами ааракокры могут использовать прозвища, полученные от людей, которых они встречали, а также могут представляться сокращёнными формами своего полного имени.

Ааракокры любого пола могут иметь одно из этих коротких имён: Айял, Аур, Аэра, Дикек, Зид, Икки, Ирк, Кваф, Квирк, Клик, Оор, Оус, Салик, Уррик или Хик.`,
    source: {
      id: SOURCE_EE,
      page: 4,
    },
  },
]
