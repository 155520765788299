const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_MEPHIT,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_AQUAN,
  LANG_IGNAN,
} = require('./../../../../languageIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_STEAM_MEPHIT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {mephitDescription} = require('./../../../../textCommonParts')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPELL_BLUR} = require('./../../../../spellIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Паровый мефит',
  nameEn: 'Steam Mephit',
  description: [
    `Состоящие из воды и огня паровые мефиты всегда оставляют за собой след из горячей воды, а также шипят, испуская пар. Паровые мефиты — существа властные и гиперчувствительные, это самопровозглашённые повелители всех мефитов.`,
    mephitDescription,
  ],
  id: CREATURE_STEAM_MEPHIT,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_MEPHIT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 206,
  },
  armor: 10,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 5,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 10,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 12,
  },
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AQUAN,
    LANG_IGNAN,
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Предсмертная вспышка',
      description: `Когда ★СУЩЕСТВО★ умирает, ★он★ взрывается облаком пара. Все существа в пределах 5 футов от мефита должны пройти испытание Ловкости СЛ 10, иначе получат урон огнём 4 (1к8).`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_BLUR,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 1,
            diceType: 4,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 4,
          },
        ],
      },
    },
    {
      name: 'Паровое дыхание',
      restore: 6,
      description: `★СУЩЕСТВО★ выдыхает обжигающий пар 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 10, получая урон огнём 4 (1к8) при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
