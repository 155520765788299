const gameSetDescription = require('./../../constants/gameSetDescription')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {CREATURE_TRICERATOPS} = require('./../../../creatureIdList')
const {DAMAGE_FIRE} = require('./../../../damageTypeList')
const {PARAM_CON} = require('./../../../paramList')
const {PC_CLASS_BARD} = require('./../../../pcClassIdList')
const {POISON_TYPE_INGESTED} = require('./../../../poisonTypeList')
const {SPELL_ZONE_OF_TRUTH} = require('./../../../spellIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  VOLUME_UNIT_GALLON,
  VOLUME_UNIT_PINT,
} = require('./../../../volumeUnitList')
const {
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
} = require('./../../../pcRaceIdList')
const {
  SOURCE_DMG,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TOA,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  CAT_ANIMALS,
  CAT_ARTISAN_TOOLS,
  CAT_CONTAINERS,
  CAT_DRUID_FOCUS,
  CAT_EQUIPMENT,
  CAT_FOOD,
  CAT_GAME_SET,
  CAT_LIGHT_SOURCE,
  CAT_MUSIC_TOOLS,
  CAT_POISONS,
  CAT_TOOLS,
} = require('./../../../gearCategoryList')
const {
  GEAR_ALE_GALLON,
  GEAR_CHAIN_MAIL,
  GEAR_COTTON_CLOTH,
  GEAR_MIRROR_STEEL,
  GEAR_OIL,
  GEAR_TANKARD,
  GEAR_TANTAN,
  GEAR_TEJ_CASK,
  GEAR_TEJ_MUG,
  GEAR_THELARR,
  GEAR_TENT,
  GEAR_THIEVES_TOOLS,
  GEAR_THREE_DRAGON_ANTE,
  GEAR_TINDERBOX,
  GEAR_TINKERS_TOOLS,
  GEAR_TORCH,
  GEAR_TORPOR,
  GEAR_TOCKEN,
  GEAR_TOTEM,
  GEAR_TRICERATOPS,
  GEAR_TRUTH_SERUM,
  GEAR_WHETSTONE,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_TENT,
    genderId: GENDER_FEMALE,
    name: 'Палатка',
    nameByCase: {
      nominative: 'Палатка',
      accusative: 'Палатку',
      instrumental: 'Палаткой',
    },
    nameEn: 'Tent',
    description: 'В палатке, простом парусиновом жилище, могут спать двое.',
    cost: 200,
    weight: 20,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_TINDERBOX,
    genderId: GENDER_FEMALE,
    name: 'Трутница',
    nameByCase: {
      nominative: 'Трутница',
      accusative: 'Трутницу',
      instrumental: 'Трутницей',
    },
    nameEn: 'Tinderbox',
    description: `В этом небольшом контейнере находится кремень, кресало и трут (обычно это сухая тряпка, вымоченная в [масле](GEAR:${GEAR_OIL})), используемые для разжигания огня. Использование его для разжигания [факела](GEAR:${GEAR_TORCH}) — или чего-нибудь другого, легковоспламеняющегося — требует одного действия. Разжигание другого огня требует 1 минуты.`,
    cost: 50,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_TORCH,
    genderId: GENDER_MALE,
    name: 'Факел',
    nameByCase: {
      nominative: 'Факел',
      accusative: 'Факел',
      instrumental: 'Факелом',
    },
    nameEn: 'Torch',
    description: 'Факел горит 1 час, испуская яркий свет в пределах 20 фт и тусклый свет в пределах ещё 20 фт. Если Вы совершаете рукопашную атаку горящим факелом и попадаете, он причиняет урон огнём 1.',
    damage: {
      diceBonus: 1,
    },
    range: {
      normal: 20,
      max: 40,
    },
    cost: 1,
    damageType: DAMAGE_FIRE,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_LIGHT_SOURCE,
  },
  {
    id: GEAR_TANKARD,
    genderId: GENDER_FEMALE,
    name: 'Кружка',
    nameByCase: {
      nominative: 'кружка',
      genitive: 'кружки',
      accusative: 'кружку',
      instrumental: 'кружкой',
    },
    nameAlt: 'Склянка',
    nameEn: 'Tankard',
    cost: 2,
    weight: 1,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 1,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_TOTEM,
    genderId: GENDER_MALE,
    name: 'Тотем',
    nameByCase: {
      nominative: 'тотем',
      genitive: 'тотема',
      accusative: 'тотем',
      instrumental: 'тотемом',
    },
    nameEn: GEAR_TOTEM,
    description: 'Тотем с перьями, мехом, костями и зубами священных животных. Друид может использовать этот предмет в качестве фокусировки для заклинаний.',
    cost: 100,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_DRUID_FOCUS,
  },
  {
    id: GEAR_THIEVES_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Воровские инструменты',
    nameByCase: {
      nominative: 'воровские инструменты',
      genitive: 'воровских инструментов',
      accusative: 'воровские инструменты',
      instrumental: 'воровскими инструментами',
    },
    nameEn: 'Thieves’ Tools',
    description: `Владение этими инструментами позволяет добавлять бонус мастерства ко всем проверкам характеристик, сделанным для отключения ловушек и взлома замков.

Возможно, чаще всего используемые искателями приключений, воровские инструменты разработаны для взлома замков и обезвреживания ловушек. Владение этими инструментами также даёт вам общие знания о принципах действия ловушек и замков.

**Компоненты.** Воровские инструменты включают в себя

* небольшой напильник,
* набор отмычек,
* [небольшое зеркальце](GEAR:${GEAR_MIRROR_STEEL}) на длинной ручке,
* набор ножниц с узкими лезвиями,
* пара щипчиков.

**История.** Ваши познания в области ловушек дают вам дополнительные знания при ответах на вопросы о местах, знаменитых своими ловушками.

**Расследование и Восприятие.** Вы получаете дополнительные знания при поиске ловушек, потому что вам известны различные простые признаки, выдающие их наличие.

**Установка ловушки.** Вы можете не только обезвреживать ловушки, но и устанавливать их. Как часть короткого отдыха Вы можете создать ловушку из подручных средств. Результат вашей проверки становится сложностью для пытающегося обнаружить или обезвредить ловушку. Ловушка наносит урон, соответствующий материалам, использованным при создании (как то яд или оружие), или урон, равный половине результата вашей проверки, смотря что Мастер сочтёт более подходящим.

| Действие            | Сл        |
|---------------------|-----------|
| Вскрыть замок       | Различная |
| Обезвредить ловушку | Различная |
`,
    cost: 2500,
    weight: 1,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 86,
      },
    ],
    category: CAT_TOOLS,
  },
  {
    id: GEAR_THREE_DRAGON_ANTE,
    genderId: GENDER_FEMALE,
    name: 'Ставка трёх драконов',
    nameByCase: {
      nominative: 'ставка трёх драконов',
      genitive: 'ставкой трёх драконов',
      accusative: 'ставку трёх драконов',
      instrumental: 'ставкой трёх драконов',
    },
    nameEn: 'Three-Dragon Ante',
    description: gameSetDescription,
    cost: 100,
    weight: 0,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 83,
      },
    ],
    category: CAT_GAME_SET,
  },
  {
    id: GEAR_TINKERS_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты ремонтника',
    nameByCase: {
      nominative: 'инструменты ремонтника',
      genitive: 'инструментов ремонтника',
      accusative: 'инструменты ремонтника',
      instrumental: 'инструментами ремонтника',
    },
    nameEn: 'Tinker’s Tools',
    description: `Набор инструментов ремонтника предназначен для того, чтобы чинить множество обычных предметов. И хотя Вы не можете создать большинство из них инструментами ремонтника, Вы можете починить порвавшуюся одежду, наточить затупившийся меч и подлатать истрепавшуюся [кольчугу](GEAR:${GEAR_CHAIN_MAIL}).

**Компоненты.** Инструменты ремонтника включают в себя

* различные ручные инструменты,
* нитки,
* иголки,
* [точильный камень](GEAR:${GEAR_WHETSTONE}),
* куски [ткани](GEAR:${GEAR_COTTON_CLOTH}) и кожи,
* небольшую банку клея (синюю изоленту пока, увы, не придумали).

**История.** Вы можете определить возраст и происхождение предмета, даже если у вас в наличии всего несколько осколков, оставшихся от оригинала.

**Расследование.** Когда Вы осматриваете повреждённый предмет, Вы узнаёте, как и насколько давно он был повреждён.

**Починка.** Вы можете восстановить по 10 хитов повреждённого предмета за каждый час работы. Для починки любого предмета вам необходим доступ к материалам, необходимым для ремонта. Для металлических предметов вам нужен доступ к открытому огню, жара которого достаточно, чтобы сделать металл пластичным.

| Действие                                | СЛ |
|-----------------------------------------|----|
| Временно починить повреждённый механизм | 10 |
| Починить предмет вдвое быстрее          | 15 |
| Смастерить из хлама временный предмет   | 20 |
`,
    cost: 5000,
    weight: 10,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 86,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_TANTAN,
    genderId: GENDER_MALE,
    name: 'Тантан',
    nameEn: 'Tantan',
    nameByCase: {
      nominative: 'тантан',
      genitive: 'тантана',
      accusative: 'тантан',
      instrumental: 'тантаном',
    },
    nameAlt: 'Бубен',
    description: [
      `Бубен, популярный инструмент у [полуросликов](PC_RACE:${PC_RACE_HALFLING}) и [людей](PC_RACE:${PC_RACE_HUMAN}) к югу от Долин.`,
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_THELARR,
    genderId: GENDER_MALE,
    name: 'Теларр',
    nameEn: 'Thelarr',
    nameByCase: {
      nominative: 'теларр',
      genitive: 'теларра',
      accusative: 'теларр',
      instrumental: 'теларром',
    },
    nameAlt: 'Свистелка',
    nameEnAlt: 'Whistlecane',
    description: [
      `Так же известный как свистелка, простой и лёгкий в изготовлении духовой инструмент, вырезаемый из тростника. Они настолько просты в изготовлении и использовании, что квалифицированные [барды](PC_CLASS:${PC_CLASS_BARD}) часто изготавливают их и отдают детям, к удовольствию, или сожалению, их родителей.`,
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_TOCKEN,
    genderId: GENDER_MALE,
    name: 'Тоскен',
    nameEn: 'Tocken',
    nameByCase: {
      nominative: 'тоскен',
      genitive: 'тоскена',
      accusative: 'тоскен',
      instrumental: 'тоскеном',
    },
    description: [
      `Подвесной набор овальных колокольчиков, на которых играют обычно парой деревянных молоточков (или открытыми ладонями). Чаще всего встречаются в подземных сообществах, где эхо окрашивает игру дополнительными тонами.`,
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_TRICERATOPS,
    genderId: GENDER_MALE,
    name: 'Трицератопс',
    nameByCase: {
      nominative: 'трицератопс',
      genitive: 'трицератопса',
      accusative: 'трицератопса',
      instrumental: 'трицератопсом',
    },
    nameEn: 'Triceratops',
    creatureId: CREATURE_TRICERATOPS,
    description: 'Скорость: 50 фт. Грузоподъёмность 1320 фунтов.',
    cost: 50000,
    source: [
      {
        id: SOURCE_PHB,
        page: 157,
      },
      {
        id: SOURCE_TOA,
        page: 29,
      },
    ],
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_TEJ_MUG,
    genderId: GENDER_MALE,
    name: 'Теж (кружка)',
    nameByCase: {
      nominative: 'кружка тежа',
      genitive: 'кружки тежа',
      accusative: 'кружка тежа',
      instrumental: 'кружкой тежа',
    },
    nameEn: 'Tej (mug)',
    description: `Перебродивший напиток янтарного цвета на основе мёда. На Чульте он куда более распространён и популярен, чем [эль](GEAR:${GEAR_ALE_GALLON}).`,
    cost: 4,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 1,
    },
    source: {
      id: SOURCE_TOA,
      page: 32,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_TEJ_CASK,
    genderId: GENDER_MALE,
    name: 'Теж (бочонок)',
    nameByCase: {
      nominative: 'бочонок тежа',
      genitive: 'бочонка тежа',
      accusative: 'бочонок тежа',
      instrumental: 'бочонком тежа',
    },
    nameEn: 'Tej (cask)',
    description: `Перебродивший напиток янтарного цвета на основе мёда. На Чульте он куда более распространён и популярен, чем [эль](GEAR:${GEAR_ALE_GALLON}).`,
    cost: 20,
    volume: {
      unitId: VOLUME_UNIT_GALLON,
      count: 1,
    },
    source: {
      id: SOURCE_TOA,
      page: 32,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_TORPOR,
    genderId: GENDER_MALE,
    name: 'Яд, ступор',
    nameByCase: {
      nominative: 'ступор',
      genitive: 'ступора',
      accusative: 'ступор',
      instrumental: 'ступором',
    },
    nameEn: 'Torpor',
    description: `Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 15, иначе оно станет отравленным на 4к6 часов. Существо также недееспособно, пока отравлено.`,
    saveThrow: {
      type: PARAM_CON,
      dc: 15,
    },
    cost: 60000,
    poisonType: POISON_TYPE_INGESTED,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
  {
    id: GEAR_TRUTH_SERUM,
    genderId: GENDER_FEMALE,
    name: 'Яд, сыворотка правды',
    nameByCase: {
      nominative: 'сыворотка правды',
      genitive: 'сыворотки правды',
      accusative: 'сыворотку правды',
      instrumental: 'сывороткой правды',
    },
    nameEn: 'Truth Serum',
    description: `Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 11, иначе станет отравленным на 1 час. Пока существо отравлено, оно не может сознательно говорить ложь, как если бы оно находилось под действием заклинания [Область истины](SPELL:${SPELL_ZONE_OF_TRUTH}).`,
    saveThrow: {
      type: PARAM_CON,
      dc: 11,
    },
    cost: 15000,
    poisonType: POISON_TYPE_INGESTED,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
]

module.exports = gearRawList
