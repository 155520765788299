const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {CAT_SIMPLE_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_BLUDGEONING} = require('./../../../damageTypeList')
const {GEAR_MACE} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_MACE,
  genderId: GENDER_FEMALE,
  name: 'Булава',
  nameByCase: {
    nominative: 'булава',
    genitive: 'булавы',
    accusative: 'булаву',
    instrumental: 'булавой',
  },
  nameEn: 'Mace',
  description: `Относящиеся к молотам, но специально задуманные как оружие, булавы представляют собой металлические дубинки с шипами или лезвиями, предназначенные для пробивания брони или сбивания солдата с лошади. Люди высокого статуса могут замысловато украшать свои булавы, и такие булавы — произведения искусства. Но булаву также можно сделать из простого дешёвого железа и бить она будет так же хорошо!`,
  damage: {
    diceType: 6,
    diceCount: 1,
  },
  cost: 500,
  damageType: DAMAGE_BLUDGEONING,
  weight: 4,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 111,
    },
  ],
  category: CAT_SIMPLE_MELEE_WEAPON,
}
