const {GENDER_FEMALE} = require('./../../genderList')
const {LANG_DEEP_SPEECH} = require('./../../languageIdList')
const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {SOURCE_DND4_DMG} = require('./../../sourceList')
const {
  ALPHABET_ESPRUAR,
  ALPHABET_RELLANIC,
} = require('./../../alphabetList')

module.exports = {
  id: LANG_DEEP_SPEECH,
  alphabetId: [
    ALPHABET_ESPRUAR,
    ALPHABET_RELLANIC,
  ],
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Глубинная речь',
    genitive: 'Глубинной речи',
    instrumental: 'Глубинной речью',
    prepositional: 'Глубинной речи',
  },
  nameEn: 'Deep Speech',
  typicalSpeakers: 'Иллитиды, бехолдеры',
  description: [
    {
      text: `Глубинная речь — это чужеродный язык общения Дальнего Предела, используемый существами, затронутых энергией этого места вне мира и планов.

Глубинная речь использует письмо Релланик, из-за того что дроу были первыми, кто попытался её записывать, потому что они делят Подземье с этими искажёнными существами.`,
      source: {
        id: SOURCE_DND4_DMG,
        page: 172,
      },
    },
  ],
  isRealLang: true,
  isReady: true,
  genderId: GENDER_FEMALE,
}
