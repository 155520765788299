const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  SPELL_COMMUNE,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_RAISE_DEAD,
} = require('./../../../../spellIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_RADIANT,
} = require('./../../../../damageTypeList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ABILITY_CHANGE_SHAPE,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LG} = require('./../../../../aligmentList')
const {angelDescriptionList} = require('./../../../../textCommonParts')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_VERBAL} = require('./../../../../castComponentList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_DEVA} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CELESTIAL} = require('./../../../../creatureTypeIdList')
const {GEAR_MACE} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Дэв',
  nameEn: 'Deva',
  id: CREATURE_DEVA,
  description: [
    `Дэвы это ангелы, которые выступают в роли божественных посланников или агентов на Материальном плане, в Царстве Теней или Стране Фей. Они могут принимать форму, которая подходит той области, куда они направляются.

Легенды гласят об ангелах, которые принимают облик смертных на много лет, и даруют помощь, надежду и мужество добрым народам. Дэв может принимать любой облик, хотя предпочитает являться смертным в облике безобидного гуманоида или животного. Когда обстоятельства требуют явить истинный облик, дэв предстаёт в образе прекрасного человекоподобного существа с серебряной кожей. Его волосы и глаза сверкают неземным блеском, а большие, покрытые перьями крылья разворачиваются у него за спиной.`,
    ...angelDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_LG,
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 90,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 18,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 20,
    [PARAM_CHA]: 20,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_RADIANT,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_10,
  source: {
    id: SOURCE_MM,
    page: 19,
  },
  featureList: [
    {
      name: 'Оружие ангела',
      description: `Атаки ★его★ оружием являются магическими. Если ★СУЩЕСТВО★ попадает любым оружием, оно причиняет дополнительный урон излучением 4к8 (уже включено в атаку).`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentOnly: CAST_VERBAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_EVIL_AND_GOOD,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_COMMUNE,
          SPELL_RAISE_DEAD,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_MACE,
      attack: {
        hit: {
          type: DAMAGE_RADIANT,
          diceCount: 4,
          diceType: 8,
        },
      },
    },
    {
      name: 'Целебное касание',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ прикасается к другому существу. Цель магическим образом восстанавливает 20 (4к8+2) хитов и избавляется от проклятий, болезней, отравлений, слепоты и глухоты.`,
    },
    {
      id: ABILITY_CHANGE_SHAPE,
      nextForm: `гуманоида или зверя, чей показатель опасности не превышает ★его★ собственный`,
      gearMerge: `сливается с новым обликом или используется ★им★ (на ★его★ выбор)`,
      statChange: `В новом облике ★СУЩЕСТВО★ сохраняет игровую статистику и способность говорить, но КД, режимы перемещения, Сила, Ловкость и специальные чувства заменяются теми, что есть у нового облика, и ★он★ получает все элементы статистики и умения (кроме классовых умений, легендарных действий и действий логова), которые есть у нового облика, но отсутствуют у ★него★`,
    },
  ],
  genderId: GENDER_MALE,
}
