const {SOURCE_PHB} = require('./../../../sourceList')

const {PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BERSERKER} = require('./../../../pcSubClassIdList')
const {
  FEATURE_FRENZY,
  FEATURE_INTIMIDATING_PRESENCE,
  FEATURE_MINDLESS_RAGE,
  FEATURE_RAGE,
  FEATURE_RETALIATION,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_FRENZY,
    name: `Бешенство`,
    nameEn: `Frenzy`,
    lvl: 3,
    text: `Находясь в состоянии [Ярости](FEATURE:${FEATURE_RAGE}), Вы можете впасть в _Бешенство_.

В этом случае, пока Ваша [Ярость](FEATURE:${FEATURE_RAGE}) не прекратилась, Вы можете в каждый свой ход после текущего совершать бонусным действием одну рукопашную атаку оружием. После окончания [Ярости](FEATURE:${FEATURE_RAGE}) Вы получаете один уровень усталости.`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_MINDLESS_RAGE,
    name: `Бездумная ярость`,
    nameEn: `Mindless Rage`,
    lvl: 6,
    text: `Вы не можете быть испуганы или очарованы, пока находитесь в состоянии [Ярости](FEATURE:${FEATURE_RAGE}).

Если Вы были испуганы или очарованы до того, как впали в состояние [Ярости](FEATURE:${FEATURE_RAGE}), эти эффекты приостанавливаются до окончания Вашей [Ярости](FEATURE:${FEATURE_RAGE}).`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_INTIMIDATING_PRESENCE,
    name: `Пугающее присутствие`,
    nameEn: `Intimidating Presence`,
    lvl: 10,
    text: `Вы можете действием пугать других своим ужасающим видом.

Чтобы сделать это, выберите видимое Вами существо в пределах 30 футов. Если оно может видеть или слышать Вас, оно должно пройти испытание Мудрости (СЛ равна 8 + Ваш бонус мастерства + Ваш модификатор Харизмы), иначе станет испуганным Вами до конца Вашего следующего хода. В последующие ходы Вы можете действием поддерживать этот эффект до конца своего следующего хода.

Эффект оканчивается, если цель оказалась вне линии обзора, или далее чем в 60 футах от Вас. Если существо преуспело в испытании, Вы не можете использовать на нём эту способность в следующие 24 часа.`,
    source: {
      id: SOURCE_PHB,
      page: 50,
    },
  },
  {
    id: FEATURE_RETALIATION,
    name: `Ответный удар`,
    nameEn: `Retaliation`,
    lvl: 14,
    text: `При получении урона от существа, находящегося в пределах 5 футов от Вас, Вы можете реакцией совершить по нему рукопашную атаку оружием.`,
    source: {
      id: SOURCE_PHB,
      page: 51,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BERSERKER,
  })
)

