const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_TYPE_DEAD} = require('./../../languageTypeList')
const {
  LANG_COMMON,
  LANG_THORASS,
} = require('./../../languageIdList')
const {
  SOURCE_DND1_CotR,
  SOURCE_DND3_FRCS,
} = require('./../../sourceList')

module.exports = {
  id: LANG_THORASS,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_DEAD,
  name: {
    nominative: 'Торасс',
    genitive: 'Торасса',
    instrumental: 'Торассом',
    prepositional: 'Торассе',
  },
  nameAlt: 'Старовсеобщий',
  nameEn: 'Thorass',
  nameEnAlt: 'Old Common',
  description: [
    {
      text: `Торасс — прямой предок современного [Всеобщего](LANG:${LANG_COMMON}) устного языка.`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
    {
      text: `Исходный торговый язык.`,
      source: {
        id: SOURCE_DND1_CotR,
        page: 10,
      },
    },
    {
      text: `Никто больше не говорит на Торассе.`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
    {
      text: `Торасс — архаичный [Всеобщий](LANG:${LANG_COMMON}) и всё ещё немного понятен тем, кто знает [Всеобщий](LANG:${LANG_COMMON})`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
    {
      header: `Торасс (старовсеобщий)`,
      text: `Торасс — это письменный торговый и универсальный язык старых Королевств. Он часто встречается в гробницах, подземных проходах и обиталищах. Его даже всё ещё используют в некоторых южных регионах. Торасс — предок современного [Всеобщего](LANG:${LANG_COMMON}) языка.`,
      source: {
        id: SOURCE_DND1_CotR,
        page: 10,
      },
    },
  ],
  isRealLang: true,
  isReady: true,
}
