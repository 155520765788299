const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_CHARGE,
  ABILITY_SURE_FOOTED,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_GIANT_GOAT} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гигантский козёл',
  nameEn: 'Giant Goat',
  id: CREATURE_GIANT_GOAT,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 325,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 12,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  cr: CR_1_2,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Тараном',
      damageText: '5 (2к4) дробящего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 13, иначе будет сбита с ног.',
    },
    ABILITY_SURE_FOOTED,
  ],
  actionList: [
    {
      name: 'Таран',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
