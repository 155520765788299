const {
  CREATURE_SWARM_OF_CENTIPEDES,
  CREATURE_SWARM_OF_INSECTS,
} = require('./../../../../creatureIdList')
const {insectSwarmDescription} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Рой многоножек',
  id: CREATURE_SWARM_OF_CENTIPEDES,
  parentId: CREATURE_SWARM_OF_INSECTS,
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 340,
    }],
    description: insectSwarmDescription,
  },
  editPropCollection: {
    actionList: ({ actionList }) => actionList.map(
      action => action.name === 'Укусы'
        ? {
          ...action,
          description: `Существо, хиты которого ★СУЩЕСТВО★ опускает до 0, остаётся стабилизированным, но отравленным на 1 час, даже если восстановит хиты, и пока оно отравлено из-за этого, оно ещё и парализовано.`,
        }
        : action,
    ),
  },
}
