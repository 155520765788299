const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_KEEN_HEARING_AND_SMELL,
  ABILITY_PACK_TACTICS,
  ABILITY_SNOW_CAMOUFLAGE,
} = require('./../../../../creatureAbilityList')
const {
  LANG_COMMON,
  LANG_GIANT,
  LANG_WINTER_WOLF,
} = require('./../../../../languageIdList')
const {
  CREATURE_DIRE_WOLF,
  CREATURE_FROST_GIANT,
  CREATURE_WINTER_WOLF,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_COLD,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Полярный волк',
  nameEn: 'Winter Wolf',
  id: CREATURE_WINTER_WOLF,
  description: `Обитающие на севере полярные волки велики как [лютые волки](CREATURE:${CREATURE_DIRE_WOLF}), но у них белый мех и бледно-голубые глаза. [Ледяные великаны](CREATURE:${CREATURE_FROST_GIANT}) используют этих злых существ в качестве охранников и спутников на охоте, и используют смертельное дыхание этих волков против врагов. Полярные волки общаются друг с другом рыком и лаем, но они также могут поддерживать несложную беседу на Великаньем и Всеобщем языках.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 337,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 14,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_COLD,
  ],
  languageList: [
    LANG_GIANT,
    LANG_COMMON,
    LANG_WINTER_WOLF,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_KEEN_HEARING_AND_SMELL,
    ABILITY_PACK_TACTICS,
    ABILITY_SNOW_CAMOUFLAGE,
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Если цель — существо, она должна пройти испытание Силы СЛ 14, иначе будет сбита с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Холодное дыхание',
      restore: 5,
      description: `★СУЩЕСТВО★ выдыхает ледяной ветер 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 12, получая урон холодом 18 (4к8) при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
