const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {CONDITION_CHARMED} = require('./../../../../../conditionList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_CHARM_MONSTER} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_CHARM_MONSTER,
  name: 'Очарование монстра',
  nameEn: 'Charm Monster',
  description: `Вы пытаетесь очаровать существо, которое Вы можете видеть в пределах дистанции. Оно должно пройти испытание Мудрости, с преимуществом, если Вы или Ваши спутники сражаетесь с ним. Если оно проваливает испытание, оно очаровано Вами до тех пор, пока заклинание активно или пока Вы или Ваши спутники не причиняете ему вред. Очарованное существо считает Вас своим другом. Когда заклинание оканчивается, существо знает, что было очаровано вами.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 5 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше 4. Эти существа должны находиться в пределах 30 футов друг от друга, когда Вы выбираете их целями.`,
  lvl: 4,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 1},
  effect: {
    savethrowParam: PARAM_WIT,
    condition: CONDITION_CHARMED,
  },
  source: {
    id: SOURCE_XGTE,
    page: 160,
  },
}
