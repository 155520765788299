const valLink = Symbol('counter value')
const initValLink = Symbol('counter init value')

class Counter {
  constructor(initValue = 0) {
    this[valLink] = initValue
    this[initValLink] = initValue
  }

  getVal = () => this[valLink]
  increment = () => ++this[valLink]
  reset = () => {
    this[valLink] = this[initValLink]
  }
}

export default Counter
