const {
  SOURCE_LOR_DTOB,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2016_04_04_GOTHIC_HEROES,
  SOURCE_UA_2017_01_16_RANGER_ROGUE,
  SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
  SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
  SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
  SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
  SOURCE_XGTE,
} = require('./../../sourceList')
const {GEAR_THIEVES_TOOLS} = require('./../../gearIdList')
const {
  PC_CLASS_BARBARIAN,
  PC_CLASS_RANGER,
  PC_CLASS_ROGUE,
} = require('./../../pcClassIdList')

const {
  PC_SUBCLASS_ROGUE_ARCANE_TRICKSTER,
  PC_SUBCLASS_ROGUE_ASSASSIN,
  PC_SUBCLASS_ROGUE_INQUISITIVE,
  PC_SUBCLASS_ROGUE_INQUISITIVE_2016_04_04,
  PC_SUBCLASS_ROGUE_MASTERMIND,
  PC_SUBCLASS_ROGUE_PHANTOM,
  PC_SUBCLASS_ROGUE_PHANTOM_2020_05_12,
  PC_SUBCLASS_ROGUE_REVIVED_2019_10_17,
  PC_SUBCLASS_ROGUE_SCOUT,
  PC_SUBCLASS_ROGUE_SCOUT_2017_01_16,
  PC_SUBCLASS_ROGUE_SOULKNIFE,
  PC_SUBCLASS_ROGUE_SOULKNIFE_2019_11_25,
  PC_SUBCLASS_ROGUE_SOULKNIFE_2020_04_14,
  PC_SUBCLASS_ROGUE_SWASHBUCKLER,
  PC_SUBCLASS_ROGUE_THIEF,
  PC_SUBCLASS_ROGUE_WILD_CARD,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_ROGUE_ARCANE_TRICKSTER,
    pcClassId: PC_CLASS_ROGUE,
    isReady: true,
    name: 'Мистический ловкач',
    nameEn: 'Arcane Trickster',
    description: `Некоторые плуты усиливают свои прекрасно отточенные навыки скрытности и ловкости магией, обучаясь премудростям чар и иллюзий.

Этими плутами становятся не только карманники и грабители, но и шутники, интриганы и достаточное количество искателей приключений.`,
    source: {
      id: SOURCE_PHB,
      page: 98,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_ASSASSIN,
    pcClassId: PC_CLASS_ROGUE,
    isReady: true,
    name: 'Убийца',
    nameEn: 'Assassin',
    description: `Вы сосредоточили своё обучение на мрачном искусстве смерти.

Те, кто придерживаются этого архетипа, разнообразны: наёмные убийцы, шпионы, охотники за головами и даже особо помазанные священники, обученные истреблять врагов своего божества. Скрытность, яд и маскировка помогут в борьбе с врагами со смертоносной эффективностью.`,
    source: {
      id: SOURCE_PHB,
      page: 98,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_INQUISITIVE,
    pcClassId: PC_CLASS_ROGUE,
    isReady: true,
    name: 'Сыщик',
    nameEn: 'Inquisitive',
    description: `Как архетипичному Сыщику, Вам нет равных в раскрытии секретов и распутывании тайн.

Вы полагаетесь на внимательность к деталям, но также и на отточенные навыки читать истинные намерения других существ по их словам и поведению. Вы превосходно искореняете существ, которые прячутся среди простых людей и охотятся на них, а Ваша эрудиция и способность к дедукции прекрасно помогают находить и искоренять скрытое зло.`,
    source: {
      id: SOURCE_XGTE,
      page: 53,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_INQUISITIVE_2016_04_04,
    pcClassId: PC_CLASS_ROGUE,
    releasedAs: PC_SUBCLASS_ROGUE_INQUISITIVE,
    isReady: false,
    name: 'Сыщик',
    nameEn: 'Inquisitive',
    source: {
      id: SOURCE_UA_2016_04_04_GOTHIC_HEROES,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_MASTERMIND,
    pcClassId: PC_CLASS_ROGUE,
    isReady: true,
    name: 'Комбинатор',
    nameEn: 'Mastermind',
    description: `Вы концентрируете свою деятельность на людях, влиянии на них и их секретах.

Многие шпионы, придворные и заговорщики следуют этому архетипу, ведя жизнь, полную интриг. Слова служат Вам оружием также часто, как ножи и яды, а секреты и расположение властьпридержащих — одни из Ваших наиболее ценных сокровищ.`,
    note: {
      text: `Ой, прошу вас. Если здесь кто-то и великий комбинатор, то это я. А ты великий болван или великий дуралей. Нет, ты!`,
      author: `Занатар`,
    },
    source: [
      {
        id: SOURCE_SCAG,
        page: 136,
      },
      {
        id: SOURCE_XGTE,
        page: 52,
      },
    ],
  },
  {
    id: PC_SUBCLASS_ROGUE_PHANTOM_2020_05_12,
    pcClassId: PC_CLASS_ROGUE,
    releasedAs: PC_SUBCLASS_ROGUE_PHANTOM,
    isReady: true,
    name: 'Фантом',
    nameEn: 'Phantom',
    description: `Многие плуты идут по тонкой грани между жизнью и смертью, рискуя собственной жизнью и забирая чужие. Идя по этой линии, некоторые плуты открывают таинственную связь с самой смертью. Эти плуты пользуются знаниями мёртвых и могут погружаться в отрицательную энергию, выглядя как призраки. Воровские гильдии ценят их как высокоэффективных информаторов и шпионов.

Многие Шадар-каи Царства Теней являются мастерами этих мрачных техник и некоторые из них обучают этому искусству. В местах вроде Тэя (Забытые Королевства) и Каррнат (Эберрон), где многие некроманты оттачивают своё ремесло, Фантомы часто становятся доверенными лицами волшебников и их правыми руками. В храмах богов смерти Фантомы служат агентами, выслеживающими тех, кто пытается обмануть смерть и восстановить знания, иначе бы канувшие в могилу.

Как Вы открыли эти мрачные способности? Вы спали на кладбище и проснулись, уже обладая ими? Или Вы оттачивали из в храме или гильдии воров, посвященной богу смерти?`,
    source: {
      id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_PHANTOM,
    pcClassId: PC_CLASS_ROGUE,
    isReady: true,
    name: 'Фантом',
    nameEn: 'Phantom',
    description: `Многие плуты идут по тонкой грани между жизнью и смертью, рискуя собственной жизнью и забирая чужие. Идя по этой линии, некоторые плуты открывают таинственную связь с самой смертью. Эти плуты пользуются знаниями мёртвых и могут погружаться в отрицательную энергию, выглядя как призраки. Воровские гильдии ценят их как высокоэффективных информаторов и шпионов.

Многие Шадар-каи Царства Теней являются мастерами этих мрачных техник и некоторые из них обучают этому искусству. В местах вроде Тэя (Забытые Королевства) и Каррнат (Эберрон), где многие некроманты оттачивают своё ремесло, Фантомы часто становятся доверенными лицами волшебников и их правыми руками. В храмах богов смерти Фантомы служат агентами, выслеживающими тех, кто пытается обмануть смерть и восстановить знания, иначе бы канувшие в могилу.

Как Вы открыли эти мрачные способности? Вы спали на кладбище и проснулись, уже обладая ими? Или Вы оттачивали из в храме или гильдии воров, посвященной богу смерти?`,
    note: {
      text: `Собирать души убитых врагов в повседневные предметы — хорошая идея. Хотя, мне, пожалуй, понадобится целая энциклопедия для содержания всех моих недоброжелателей`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 93,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_REVIVED_2019_10_17,
    pcClassId: PC_CLASS_ROGUE,
    releasedAs: PC_SUBCLASS_ROGUE_PHANTOM,
    isReady: true,
    name: 'Воскрешённый',
    nameEn: 'Revived',
    description: `На Вас снисходит ошеломляющее понимание: Вы были мертвы, но теперь снова живы.

Это не Ваша первая жизнь и может быть даже не вторая. Ваша прошлая жизнь или жизни для Вас не совсем ясны, но Вы знаете что Вы проходили через врата смерти. Силы смерти или какие-то другие другие внешние воздействия с Вами ещё не закончили. 

Возможно, Вы убедили божество в том, что Вам нужно вернуться на Материальный план, подписали договор с дьяволом или Вас воскресил некий артефакт.

Что бы ни вернуло Вас к жизни, теперь Вы знаете точную правду о себе: Вы один из представителей смерти среди живых.`,
    source: {
      id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
      page: 4,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_SCOUT,
    pcClassId: PC_CLASS_ROGUE,
    isReady: true,
    name: 'Скаут',
    nameEn: 'Scout',
    description: `Вы обучены скрываться и выживать вдали от городских улиц, что позволяет Вам во время экспедиций идти на разведку впереди своих компаньонов.

Плуты, выбирающие этот архетип, чувствуют себя как дома в дикой местности и среди [варваров](PC_CLASS:${PC_CLASS_BARBARIAN}) и [следопытов](PC_CLASS:${PC_CLASS_RANGER}), и многие Скауты служат глазами и ушами военных отрядов.

Налетчик, шпион, охотник за головами — вот лишь некоторые из ролей, которые играют Скауты, бродя по свету.`,
    source: {
      id: SOURCE_XGTE,
      page: 52,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_SCOUT_2017_01_16,
    pcClassId: PC_CLASS_ROGUE,
    releasedAs: PC_SUBCLASS_ROGUE_SCOUT,
    isReady: false,
    name: 'Скаут',
    nameEn: 'Scout',
    source: {
      id: SOURCE_UA_2017_01_16_RANGER_ROGUE,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_SOULKNIFE,
    pcClassId: PC_CLASS_ROGUE,
    isReady: true,
    name: 'Клинок душ',
    nameAlt: 'Духовный нож',
    nameEn: 'Soulknife',
    description: `Убийцы убивают физическим оружием, а взломщики используют [отмычки](GEAR:${GEAR_THIEVES_TOOLS}), чтобы проникать в охраняемые места. Но духовный нож бьёт и проникает силой самого своего разума, преодолевая как физические, так и психические барьеры. Эти плуты открывают в себе псионическую силу и используют её в своём тайном ремесле. Они легко находят себе работу в воровских гильдиях. Другие плуты с подозрением относятся ко всем, кто использует в привычном им ремесле такую странную магию и поэтому часто не доверяют духовным ножам. А большинство правительств были бы очень рады иметь духовного ножа в рядах своих шпионов.

Некоторые лесные эльфы идут по пути духовных ножей среди древних лесов как Материального плана, так и Страны Фей. Они служат тихими, смертоносными стражами своих рощ. Гитцераи в бесконечной войне против гитьянок используют духовных ножей, когда им требуется скрытность.

Возможно, псионические способности духовного ножа преследовали Вас с детства, раскрыв свой потенциал только когда Вы испытали стресс в приключениях. Или же, возможно, Вы сами искали орден адептов-псиоников и потратили годы на изучение того, как проявить свою внутреннюю силу.`,
    note: {
      text: `Я тоже могу проявлять свои мысли так, чтобы делать людям больно. Я называю это… «слова».`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 94,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_SOULKNIFE_2019_11_25,
    pcClassId: PC_CLASS_ROGUE,
    releasedAs: PC_SUBCLASS_ROGUE_SOULKNIFE,
    isReady: false,
    name: 'Клинок душ',
    nameAlt: 'Духовный нож',
    nameEn: 'Soulknife',
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_SOULKNIFE_2020_04_14,
    pcClassId: PC_CLASS_ROGUE,
    releasedAs: PC_SUBCLASS_ROGUE_SOULKNIFE,
    isReady: true,
    name: 'Клинок душ',
    nameAlt: 'Духовный нож',
    nameEn: 'Soulknife',
    description: `Убийцы убивают физическим оружием, а взломщики используют [отмычки](GEAR:${GEAR_THIEVES_TOOLS}), чтобы проникать в охраняемые места. Но духовный нож бьёт и проникает силой самого своего разума, преодолевая как физические, так и психические барьеры. Эти плуты открывают в себе псионическую силу и используют её в своём тайном ремесле. Они легко находят себе работу в воровских гильдиях. Другие плуты с подозрением относятся ко всем, кто использует в привычном им ремесле такую странную магию и поэтому часто не доверяют духовным ножам. А большинство правительств были бы очень рады иметь духовного ножа в рядах своих шпионов.

Некоторые лесные эльфы идут по пути духовных ножей среди древних лесов как Материального плана, так и Страны Фей. Они служат тихими, смертоносными стражами своих рощ. Гитцераи в бесконечной войне против гитьянок используют духовных ножей, когда им требуется скрытность. А в мире Атхас, Король-Чародей часто обращается к духовным ножам, чтобы уничтожать врагов, точно так же, как и повстанцы посылают духовных ножей подрывать планы самого Короля-Чародея.

Возможно, псионические способности духовного ножа преследовали Вас с детства, раскрыв свой потенциал только когда Вы испытали стресс в приключениях. Или же, возможно, Вы сами искали орден адептов-псиоников и потратили годы на изучение того, как проявить свою внутреннюю силу.`,
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_SWASHBUCKLER,
    pcClassId: PC_CLASS_ROGUE,
    isReady: true,
    name: 'Дуэлянт',
    nameEn: 'Swashbuckler',
    description: `Ваше воинское искусство в равной степени опирается как на мастерство фехтования и скорость, так и на элегантность и харизму. В то время, как некоторые воины — громилы в тяжёлых доспехах, Ваш метод вести бой больше похож на выступление. Дуэлянты и пираты обычно принадлежат к этому архетипу.

Дуэлянты обычно имеют преимущество в поединках один на один, фехтуя двумя руками и держась на безопасном расстоянии от оппонента.`,
    note: {
      text: `Что такое дуэлянт? Ты дуешь эль или элишь дуэты? Только не говори, что не считаешь это слово забавным`,
      author: `Занатар`,
    },
    source: [
      {
        id: SOURCE_SCAG,
        page: 136,
      },
      {
        id: SOURCE_XGTE,
        page: 51,
      },
    ],
  },
  {
    id: PC_SUBCLASS_ROGUE_WILD_CARD,
    pcClassId: PC_CLASS_ROGUE,
    isReady: false,
    name: 'Катала',
    nameEn: 'Wild Card',
    source: {
      id: SOURCE_LOR_DTOB,
      url: 'https://www.dndbeyond.com/sources/lrdtob/heroes-and-scoundrels-of-bilgewater#RoguishArchetypeWildCard',
    },
  },
  {
    id: PC_SUBCLASS_ROGUE_THIEF,
    pcClassId: PC_CLASS_ROGUE,
    isReady: true,
    name: 'Вор',
    nameEn: 'Thief',
    description: `Вы отточили навыки воровского искусства.

Грабители, бандиты, карманники и прочие преступники, как правило, следуют этому архетипу, но его же выбирают и плуты, которые предпочитают думать о себе как о профессиональных искателях сокровищ, исследователях, посыльных и ищейках.

В дополнение к улучшению ловкости и хитрости, Вы узнаёте навыки, которые пригодятся во время изучения древних руин, чтения незнакомых языков, использования магических предметов, которые обычно Вы не можете использовать.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 98,
      },
      {
        id: SOURCE_SRD,
        page: 40,
      },
    ],
  },
]
