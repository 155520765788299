const {CREATURE_TYPE_TIEFLING} = require('./../../creatureTypeIdList')
const {CREATURE_DJINNI} = require('./../../creatureIdList')
const {uncommonRaces} = require ('./../../textCommonParts')
const {PC_CLASS_WARLOCK} = require ('./../../pcClassIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_ASMODEUS,
  GOD_AZUTH,
  GOD_BESHABA,
  GOD_ILMATER,
  GOD_MASK,
  GOD_SELUNE,
  GOD_SHAR,
  GOD_TYMORA,
} = require('./../../godIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  PC_RACE_HUMAN,
  PC_RACE_ELF,
} = require('./../../pcRaceIdList')

module.exports = {
  id: CREATURE_TYPE_TIEFLING,
  nameEn: 'Tiefling',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'тифлинг',
        genitive: 'тифлинга',
        dative: 'тифлингу',
        accusative: 'тифлинга',
        instrumental: 'тифлингом',
        prepositional: 'тифлинге',
      },
      [GENDER_FEMALE]: {
        nominative: 'тифлингша',
        genitive: 'тифлингши',
        dative: 'тифлингше',
        accusative: 'тифлингшу',
        instrumental: 'тифлингшой',
        prepositional: 'тифлингше',
      },
    },
    plural: {
      nominative: 'тифлинги',
      genitive: 'тифлингов',
      dative: 'тифлингам',
      accusative: 'тифлингов',
      instrumental: 'тифлингами',
      prepositional: 'тифлингах',
    },
  },
  note: [
    {
      text: `«Но ты же видишь, как люди смотрят на тебя, дитя дьявола». Взгляд чёрных глаз, холодных, как зимняя метель, был устремлён прямо в её душу. Её потрясла неожиданная серьёзность его тона.

«Как там говорится? — спросил он. — Один — любопытство, двое — заговор…»

«А трое — проклятье. — закончила она. — Думаешь, я никогда не слышала этот бред?»

«Конечно, слышала». Когда она уставилась на него, он добавил: «Для этого не обязательно копаться в твоих мыслях, девочка. Это ноша каждого тифлинга. Некоторые ломаются под её тяжестью, некоторые спокойно несут это бремя, некоторые даже наслаждаются им». Он снова наклонил голову, разглядывая её с озорным блеском в глазах. «А ты с этим борешься, не так ли? Как дикая кошка, я бы сказал. Все упрёки и замечания лишь делают твои когти острее».`,
      author: `Эрин М. Эванс, Серные ангелы`,
    },
    {
      text: `
— „Вождь, который их возглавляет“ — спросил Туэль, — „Что мы о нём знаем?“

— „Его зовут Катрик Мел. Он пробуждает у варваров невероятную преданность, почти религиозный пыл.“

— „Он демон?“

— „Не думаю. Гааш’кала зовёт его «сак’вакаррак» — это примерно переводится как «затронутый исчадиями». Каррнатский волшебник использовал слово «тифлинг». Думаю, он — какая-то смесь исчадия и смертного, больше спаситель, чем бог“

Туэль нахмурился: „Для них спаситель, для нас — проклятие“.`,
      author: `Джеймс Вятт, Драконья Кузница`,
    },
  ],
  description: [
    {
      header: `Тифлинги`,
      text: `Быть тифлингом — значит постоянно натыкаться на пристальные взгляды и перешёптывания, терпеть страдания и оскорбления, видеть страх и недоверие в глазах каждого встречного. Беда в том, что тифлинги знают причину этого — договор, заключённый много поколений назад и позволивший [Асмодею](GOD:${GOD_ASMODEUS}) — владыке Девяти Преисподних — внести вклад в их родословную. Такая внешность и природа — не их вина, а последствие древнего прегрешения, расплачиваться за которое предстоит им, их детям, и детям их детей.`,
      source: {
        id: SOURCE_PHB,
        page: 43,
      },
    },
    {
      header: 'Тифлинги',
      text: `Тифлинги происходят от дьяволов Девяти кругов ада. В Фаэруне к ним относятся с недоверием. К счастью, мистические способности помогают им выжить.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Тифлинги`,
      text: `Люди от крови исчадий, большинство тифлингов Фаэруна объединены благодаря вековым махинациям архидьявола [Асмодея](GOD:${GOD_ASMODEUS}).`,
      source: {
        id: SOURCE_PHB,
        page: 43,
      },
    },
    {
      header: `Дьявольская родословная`,
      text: `Тифлинги произошли от людей, и по большому счёту всё ещё выглядят как люди. Тем не менее, их дьявольское наследие оставило заметный отпечаток на внешности. У тифлингов есть большие рога, принимающие различные формы: у некоторых витые рога, как у барана, у других длинные и прямые, как у газели, у третьих спиральные, как у антилопы. Их толстый хвост, от 120 до 150 сантиметров длиной, вьётся между ног, похлёстывая их, когда тифлинг нервничает. Острые зубы превращают улыбку в звериный оскал, а их глаза сплошного цвета без намёка на зрачок или белок, обычно чёрного, красного, белого, серебряного или золотого цвета. Цвет кожи тифлингов может принимать все тона, характерные для человека, а также различные оттенки красного. Их волосы, струящиеся из-за рогов, обычно тёмные, от чёрного или коричневого до тёмно-красного, синего или фиолетового.`,
      source: {
        id: SOURCE_PHB,
        page: 43,
      },
    },
    {
      header: `Самодостаточные и недоверчивые`,
      text: `Тифлинги живут небольшими общинами, в основном в людских городах, чаще всего в самых трущобах, где из них вырастают жулики, воры и короли преступного мира. Иногда они живут группами среди других меньшинств, которые относятся к ним с чуть большим уважением.

У тифлингов нет родины, и они знают, что им нужно найти свой путь в жизни, и что им понадобится сила для выживания. Они с опаской относятся ко всем, кто называется их другом, но когда кто-то проявляет доверие по отношению к ним, они стремятся отплатить той же монетой. Для того, кто заслужит верность тифлинга, он станет верным другом и надёжным союзником до конца своей жизни.`,
      source: {
        id: SOURCE_PHB,
        page: 43,
      },
    },
    {
      header: `Взаимное недоверие`,
      text: `Люди склонны с недоверием относиться к тифлингам, полагая, что их дьявольское наследие выражается не только во внешнем виде, но и в их личных качествах. Владельцы магазинов с особой тщательностью следят за вошедшими к ним тифлингами, городская стража может пройтись за тифлингом пару кварталов — на всякий случай, а молва часто винит тифлингов в происходящих вокруг странностях.

На самом же деле родословная тифлингов ни к коей мере не влияет на их характер. Но годы жизни в атмосфере недоверия не проходят даром, по-разному сказываясь на поведении тифлингов. Некоторые соответствуют распространённым стереотипам, другие — нет. Большинство просто относятся к другим так же, как те относятся к ним. Вырастая в таком недоверии, тифлинги зачастую учатся преодолевать предрассудки при помощи обаяния или запугивания.`,
      source: {
        id: SOURCE_PHB,
        page: 44,
      },
    },
    {
      header: `Метка Асмодея`,
      text: `Во время Магической Чумы, [Асмодей](GOD:${GOD_ASMODEUS}) поглотил божественную искру [Азута](GOD:${GOD_AZUTH}), достигнув таким образом божественного статуса. После этого, [Асмодей](GOD:${GOD_ASMODEUS}) и шабаш колдунов, Чёртова Дюжина Торила, провели ритуал, с помощью которого архидьявол заявил свои права на всех тифлингов, обрекая их носить «кровь Асмодея». Это отметило всех тифлингов как «потомков» Владыки Девяти Преисподен, независимо от их истинного наследия и изменило их, превратив в существ, более похожих на их предполагаемого «предка». Люд Фаэруна, и без того встревоженный внешностью этих дьяволоподобных существ, стал ещё более подозрителен, а в некоторых случаях даже враждебен, ко всем тифлингам без исключения.

Вопреки этому, некоторые люди верят, что [Асмодей](GOD:${GOD_ASMODEUS}) не властен над своими «детьми», а тифлинги сегодня свободны в своей воле также, как они были и ранее. Некоторые из них добровольно избирают службу Владыке Девяти Преисподен и его планам, в то время как другие связывают себя с другими фракциями исчадий или ни с одной из них вовсе, стараясь остаться вне адской политики.

Из-за того, что ритуал, распространивший проклятие [Асмодея](GOD:${GOD_ASMODEUS}), был проведен сотню лет назад, на Фаэруне были рождены тифлинги, принадлежащие к другим инфернальным родословным, но носящие метку архидьявола (или их потомки) всё ещё остаются наиболее распространёнными представителями их вида.

Тифлинги Фаэруна по большинству своему имеют расовые черты тифлингов из Книги Игрока, за исключением тех, кто не возводит свою родословную к [Асмодею](GOD:${GOD_ASMODEUS}) и чьи особенности могут отличаться.`,
      source: {
        id: SOURCE_SCAG,
        page: 119,
      },
    },
    {
      header: `Бездомная раса`,
      text: `Тифлинги, потомки исчадий, не могут нигде на Фаэруне чувствовать себя дома, хотя в некоторых местах и странах к ним относятся более толерантно, чем во всех прочих. Больше всего тифлингов Севера обитает в Невервинтере. Из-за того, что Ашмадай, жестокий культ, посвящённый [Асмодею](GOD:${GOD_ASMODEUS}), всё также действует в городе, недоверие к тифлингам не является чем-то необычным даже здесь. Это вызвано тем, что простой люд не может знать наверняка является ли тифлинг членом Ашмадай или же просто его привлекла в Невервинтер предоставляемая метрополией возможность смешаться с людом.

Небольшие, раздробленные группы тифлингов также могут быть найдены кое-где на Фаэруне, в частности в космополитичных городах (где они могут сохранять некоторую меру анонимности) и в бедных, нищих поселениях, которые приветствуют любого, кто может помочь им выжить и развиваться.

Тифлинги распространены в Калимшане, на юге, где многие из них сражались за [джиннов](CREATURE:${CREATURE_DJINNI}) в качестве наёмников, а нынче служат другим хозяевам, которые способны заплатить. На востоке, множество тифлингов обитают в Агларонде (беглые рабы Тэя или их потомки) и в Мулхоранде, где тифлинги считаются носителями крови самих древних Мулхорандских богов.`,
      source: {
        id: SOURCE_SCAG,
        page: 120,
      },
    },
    {
      header: `Одинокие верующие`,
      text: `Несмотря на то, что фаэрунский люд верит, что все тифлинги поклоняются [Асмодею](GOD:${GOD_ASMODEUS}) и Владыкам Девяти Преисподних, на самом деле это правдиво лишь для небольшой их части, которой, впрочем, достаточно, чтобы слухи и подозрения в дьяволо- и демонопоклонничестве имели под собой достаточно стойкий фундамент.

Тифлинги, не поклоняющиеся [Асмодею](GOD:${GOD_ASMODEUS}), а другим богам, часто избирают божества, которые следят и заботятся об отверженных, такие как [Ильматер](GOD:${GOD_ILMATER}), [Маск](GOD:${GOD_MASK}), [Селунэ](GOD:${GOD_SELUNE}), [Шар](GOD:${GOD_SHAR}) и [Тимора](GOD:${GOD_TYMORA}). Боги знаний, выживания, сообразительности и военного ремесла часто привлекают тифлингов, ценящих именно эти качества. [Бешабе](GOD:${GOD_BESHABA}) поклоняются тифлинги, считающие случайность своего появления на свет в некотором роде жестокой шуткой, которую они смиренно принимают.

В равной мере интригуют и беспокоят последователей некоторых верований Фаэруна истории, распространяемые некоторыми тифлингами, которые заявляют, что им были видения, в которых боги Фаэруна предстают им в виде тифлинга. Одну такую сущность они называли «бледная рогатая богиня луны» ([Селунэ](GOD:${GOD_SELUNE})); другая была _«тёмной, дьявольской леди удачи»_ ([Тимора](GOD:${GOD_TYMORA})) — это показывает, по заявлениям тифлингов, что чей-либо внешний вид или родословная мало важны для богов, чем сердце и душа, что скрывается внутри.`,
      source: {
        id: SOURCE_SCAG,
        page: 120,
      },
    },
    {
      header: 'Знакомый дьявол: автобиография',
      text: `Вливал ли когда-либо бог в ваши жилы чуждую кровь? Это страшная процедура — даже для тех, кто выбрал её по собственной воле. Немногие из тифлингов хотели, чтобы [Асмодей](GOD:${GOD_ASMODEUS}) завладел их телами — но их мнения никто не спрашивал.

При этом и до коварного вмешательства архидьявола все прочие народы относились к нам не то чтобы с приязнью. Нам всегда приходилось мириться с ярлыком _«дьяволова отродья»_, словно бы одной-единственной капли адского ихора было достаточно , чтобы безвозвратно совратить любого. Занятно: [люди](PC_RACE:${PC_RACE_HUMAN}), или, скажем, [эльфы](PC_RACE:${PC_RACE_ELF}) сплошь и рядом сами продают свои души дьяволам, однако же никому не приходит в голову винить в этом порочность [людской](PC_RACE:${PC_RACE_HUMAN}) или [эльфийской](PC_RACE:${PC_RACE_ELF}) расы.

И как нам, спрашивается, искупить вину, которой на нас просто нет? Я бы с радостью вонзил клинок в грудь каждого из исполнивших проклятый ритуал [колдунов](PC_CLASS:${PC_CLASS_WARLOCK}), но месть одного тифлинга не отменит воли бога, тем более такого изворотливого, как [Властелин лжи](GOD:${GOD_ASMODEUS}).

Когда каждый встречный считает тебя вором или еретиком, искушение стать им чрезвычайно велико. Меня останавливает лишь мысль, что ничто не порадовало бы [Асмодея](GOD:${GOD_ASMODEUS}) больше, чем наше коллективное падение.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    uncommonRaces,
    {
      header: `Имена тифлингов`,
      text: `Имена тифлингов можно разделить на три категории.

1. Тифлинги, выросшие в какой-либо другой культуре, часто носят имена, характерные для этой среды.
2. Некоторые носят имена, передающиеся из поколения в поколение и произошедшие из Инфернального языка.
3. А некоторые молодые тифлинги, стремящиеся найти своё место в этом мире, берут имена, отражающие какую-либо добродетель или другую концепцию, а затем стараются воплотить её в жизнь. Для некоторых выбранное имя становится путеводной звездой, для другим — тяжким грузом.

# Мужские инфернальные имена

Акменос, Амнон, Баракас, Дамакос, Йадос, Кайрон, Люцис, Мелех, Мордай, Мортос, Пелайос, Скамос, Терай, Экемон

# Женские инфернальные имена

Акта, Анакис, Брисеис, Дамая, Каллиста, Криелла, Лерисса, Макария, Немея, Орианна, Риета, Фелая, Эа

# Идейные имена

Безрассудство, Вера, Идеал, Искусство, Музыка, Мука, Надежда, Напев, Нигде, Открытость, Отчаяние, Падаль, Поиск, Почтение, Поэзия, Превосходство, Скорбь, Слава, Случайность, Страх, Усталость.`,
      source: {
        id: SOURCE_PHB,
        page: 44,
      },
    },
    {
      header: `Тифлингские имена`,
      text: `Значительная часть родителей тифлингов следует обычаям именования детей, бытующих в тех местах, где ребенок родился, например, использовать человеческие имена, если он родился в человеческом поселении (чтобы больше походить на «нормального» гражданина). Другие же используют имена, производные от Инфернального языка, который передавался из поколения в поколение с давних времен.

Вдобавок к этому, некоторые тифлинги Фаэруна берут себе имена из языков и культур, которые в общем и целом приемлют их, таких как калишиты и мулхорандцы, которые иностранно звучат для жителей Севера и на Побережье Мечей.

Аль-Кхем и Бени-Асмодаи — две распространённых калишитских фамилии тифлингов, говорящих об наследии их расы. Имена могут быть такими как Хароун, Исхак и Низам (мужские) или Ханья, Рашаа и Заар (женские).

Мулхорандские фамилии, начинающиеся на «Сья» или «Зья» с последующей приставкой имени бога, выделяют потомков родословной этого бога, как-то, например, Зьянур и Сьясобек. Имена, привычные для мулхорандских тифлингов, могут быть Аюбтеп («рогатый»), Бахати («мудрая душа»), Хет («дым»), Камен («тёмный»), Катсу («звёзднорождённый»), Кёль («темноокий»).`,
      source: {
        id: SOURCE_SCAG,
        page: 120,
      },
    },
  ],
}
