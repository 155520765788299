const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const hammersDescription = require('./../../constants/hammersDescription')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_BLUDGEONING} = require('./../../../damageTypeList')
const {GEAR_WARHAMMER} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {WEAPON_VERSATILE} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_WARHAMMER,
  genderId: GENDER_MALE,
  name: 'Боевой молот',
  nameByCase: {
    nominative: 'боевой молот',
    genitive: 'боевого молота',
    accusative: 'боевой молот',
    instrumental: 'боевым молотом',
  },
  nameEn: 'Warhammer',
  description: hammersDescription,
  damage: {
    diceType: 8,
    diceCount: 1,
  },
  cost: 1500,
  damageType: DAMAGE_BLUDGEONING,
  weight: 2,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 111,
    },
  ],
  weaponPropList: [
    {
      id: WEAPON_VERSATILE,
      value: {
        diceCount: 1,
        diceType: 10,
      },
    },
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
