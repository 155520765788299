module.exports = [
  require('./list/diatryma'),
  require('./list/drow_gunslinger'),
  require('./list/griffon_cavalry_rider'),
  require('./list/nimblewright'),
  require('./list/vajra_safahr'),
  require('./list/walking_statue_of_waterdeep'),
  require('./list/waterdeep_s_city_guard_armar'),
  require('./list/waterdeep_s_city_guard_civilar'),
  require('./list/waterdeep_s_city_guard_private'),
  require('./list/waterdeep_s_city_guard_senior_civilar'),
]
