const {
  CREATURE_LION,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_ILLUSION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_MINOR_ILLUSION,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_MINOR_ILLUSION,
  name: 'Малая иллюзия',
  nameEn: 'Minor Illusion',
  description: `Вы создаете звук или образ предмета в пределах дистанции, существующий, пока активно заклинание. Иллюзия также оканчивается, если Вы отпустите ее действием или используете это заклинание еще раз.

* **Звук.** Если Вы создаете звук, его громкость может быть как шепотом, так и криком. Это может быть Ваш голос, чей-то другой голос, [львиный](CREATURE:${CREATURE_LION}) рык, бой барабанов или любой другой звук. Звук звучит всю длительность заклинания, или Вы можете создавать отдельные звуки в разное время, пока заклинание активно.
* **Образ предмета.** Если Вы создаете образ предмета — например, стул, отпечаток в грязи, или небольшой сундук — он должен помещаться в куб с длиной ребра 5 фт. Образ не может издавать звуки, свет, запах или прочие сенсорные эффекты. Физическое взаимодействие с образом дает понять, что это иллюзия, потому что сквозь него все проходит.

Если существо действием исследует звук или образ, оно может понять, что это иллюзия, совершив успешную проверку Интеллекта (Анализ) против СЛ Ваших заклинаний. Если существо распознает иллюзию, она для него становится нечеткой.`,
  lvl: 0,
  magicSchoolId: MAGIC_ILLUSION,
  range: 30,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'кусок овечьей шерсти',
  duration: { timeId: TIME_MINUTE, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 238,
    },
    {
      id: SOURCE_SRD,
      page: 164,
    },
  ],
}
