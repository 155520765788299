const {
  SOURCE_FTD,
} = require('./../../sourceList')
const {
  GOD_BAHAMUT,
  GOD_TIAMAT,
} = require('./../../godIdList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GOBLIN,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
  PC_RACE_LIZARDFOLK,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')

module.exports = {
  header: 'Элегия о Первом мире',
  text: `Вдохните, драконы и пойте, пойте о Первом мире

Выкованном из хаоса, раскрашенным красотой.

Пойте, драконы, пойте, о [Бахамуте Платиновом](GOD:${GOD_BAHAMUT}),

О [Бахамуте](GOD:${GOD_BAHAMUT}), вылепившим формы всех гор и рек.

Пойте, драконы, пойте о [Тиамат Цветной](GOD:${GOD_TIAMAT}),

О [Тиамат](GOD:${GOD_TIAMAT}), раскрасившей Первого мира холст.

Вместе они проснулись, два близнеца во тьме.

Вместе они трудились, мир вдвоём создавая.



Вдохните, драконы и пойте, пойте о Сардиоре,

Которого из алого камня они сделали подобным себе.

Сардиоре Перворождённом, Сардиоре, первом драконе.

Что трудился вместе с [Бахамутом](GOD:${GOD_BAHAMUT}) и [Тиамат](GOD:${GOD_TIAMAT}),

Сардиоре, формировавшем создаваемых ими драконов:

Драконов разных видов — металлических и цветных.

Вдохните, драконы, дышите, черпайте из дара жизни,

Того, что они в вас вдохнули, тогда, на заре мирозданья.



Вдохните, драконы и пойте, пойте же о пришельцах,

О божествах-захватчиках и смертных слугах богов.

Толпою заполонивших, ворвавшихся в Первый мир,

О легионах их слуг, ищущих место для жизни.

Что мощной владели магией и было их несть числа.

Так боги-завоеватели захватили весь Первый мир.

И вот, на поле боя, гордый сражён [Бахамут](GOD:${GOD_BAHAMUT}),

А Сардиор, спасаясь, в сердце творенья скрылся.



Вдохните, драконы и пойте, пойте о [Тиамат](GOD:${GOD_TIAMAT}),

Что, продолжала драться, когда луч надежды иссяк.

Той, что не убежала, той, что врагам не сдалась,

Даже когда сама смерть в неё впила холодные когти.

Боги её схватили, связали они её крепко,

Из лап отобрали у смерти лишь чтоб обрести на муки,

Навечно во тьме запечатав. [Тиамат](GOD:${GOD_TIAMAT}) так узницей стала,

Вечно в плену у богов, укравших её творенье.



Вдохните, драконы и пойте, пойте о завоеваньи,

Что засеяло мир легионами божьих слуг.

Каждый из божьих народов решил поселиться отдельно.

[Эльфы](PC_RACE:${PC_RACE_ELF}) селились в лесах, а [дварфы](PC_RACE:${PC_RACE_DWARF}) заполнили горы,

[Орки](PC_RACE:${PC_RACE_ORC}) облюбовали себе пещеры, ущелья,

[Гоблины](PC_RACE:${PC_RACE_GOBLIN}) заняли пустошь, [полурослики](PC_RACE:${PC_RACE_HALFLING}) луг заселили,

В болота ушли [людоящеры](PC_RACE:${PC_RACE_LIZARDFOLK}), а [люди](PC_RACE:${PC_RACE_HUMAN}) селились повсюду.



Вдохните, драконы и пойте, пойте о [Бахамуте](GOD:${GOD_BAHAMUT}),

Пойте о миротворце, мир заключившим с богами,

О [Бахамуте](GOD:${GOD_BAHAMUT}), вхожем в небесные их чертоги,

Которому поклонялись как Платиновому Паладину.

О его долгих скитаньях и поисках пониманья,

О его пылком стремленьи понять богов и людей,

О [Бахамуте](GOD:${GOD_BAHAMUT}), так горько о [Тиамат](GOD:${GOD_TIAMAT}) тосковавшем,

О [Бахамуте](GOD:${GOD_BAHAMUT}), скорбящем о том бессрочном плененьи.


Вдохните, драконы и пойте, пойте об заключенье —

Как [Тиамат](GOD:${GOD_TIAMAT}) вырывалась из тёмной тюрьмы мучений!

Расскажите, как дети, её цветные драконы,

Сплотились одной её волей, готовы к любым разрушеньям.

Об её ярости пойте, о накопившейся мести, об её молниях, яде, 

Об её льду и пламеньях, едком кислотном дыханьи,

О [Тиамат](GOD:${GOD_TIAMAT}) пятиглавой, неиствующей в разрушенье.



Вдохните, драконы и пойте, пойте о Первом мире,

Рассыпавшемся повсюду реальностей семенами.

Пойте, драконы, пойте о [Тиамат](GOD:${GOD_TIAMAT}) с [Бахамутом](GOD:${GOD_BAHAMUT}),

Что беспомощно смотрят за их творенья расколом.

Пойте, драконы, пойте, пойте о Сардиоре,

Что был расколот, разбросан, малыми равно частями.

Вдохните драконы, глубже: это — ваше наследство,

Правьте остовом этим, осколком Первого мира.
`,
  source: {
    id: SOURCE_FTD,
    page: 4,
  },
}
