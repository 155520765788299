import React from 'react'
import PropTypes from 'prop-types'

import ItemListComponent from './ItemListComponent'

const ItemListContainer = (
  {
    itemCollection,
    idList,
    showList,
    ...rest
  },
) => showList && itemCollection && idList.length
  ? (
    <ItemListComponent
      itemCollection={itemCollection}
      idList={idList}
      {...rest}
    />
  )
  : null

ItemListContainer.defaultProps = {
  itemCollection: {},
  idList: [],
}

ItemListContainer.propTypes = {
  itemCollection: PropTypes.object,
  idList: PropTypes.array.isRequired,
  showList: PropTypes.bool.isRequired,
}

export default ItemListContainer
