const {
  MAGIC_ENCHANTMENT,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  PARAM_CHA,
} = require('./../../../../../paramList')
const {
  SPELL_ZONE_OF_TRUTH,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ZONE_OF_TRUTH,
  name: 'Область истины',
  nameEn: 'Zone of Truth',
  description: `Вы создаёте магическую зону, защищающую от обмана в сфере с радиусом 15 футов с центром на точке, выбранной Вами в пределах дистанции. Пока заклинание активно, существо, впервые за ход входящее в область этого заклинания или начинающее в ней ход, должно пройти испытание Харизмы. При провале существо не может умышленно лгать, находясь в указанном радиусе. Вы знаете, какие существа прошли испытание, а какие провалили.

Попавшее под действие заклинания существо знает о заклинании, и потому может избегать отвечать на вопросы, на которые оно предпочло бы солгать. Такие существа могут давать уклончивые ответы, пока принуждены говорить правду.`,
  lvl: 2,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 10 },
  effect: {
    savethrowParam: PARAM_CHA,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 245,
    },
    {
      id: SOURCE_SRD,
      page: 193,
    },
  ],
}
