const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_DRAGON_BRONZE_WYRMLING,
  CREATURE_RAT,
} = require('./../../creatureIdList')
const {
  SPELL_CONTROL_WEATHER,
  SPELL_FOG_CLOUD,
} = require('./../../spellIdList')

const metalDragonDescriptionList = require('./dragonMetallic')

module.exports = [
  {
    header: 'Логово бронзового дракона',
    text: `Бронзовый дракон располагает своё логово в прибрежных пещерах. В своём логове он может реконструировать обломки корабля, используя их как сокровищницу или гнездо для яиц.
    
# Действия логова
    
При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов:

* Дракон создаёт туман, как если бы наложил заклинание [туманное облако](SPELL:${SPELL_FOG_CLOUD}). Туман длится до показателя инициативы «20» в следующем раунде. 
* Раскат грома исходит из точки, которую дракон видит в пределах 120 футов. Все существа в пределах 20 футов от этой точки должны пройти испытание Телосложения СЛ 15, иначе получат урон звуком 5 (1d10) и станут глухими до конца своего следующего хода.
    
# Местные эффекты
    
Регион, в котором есть логово легендарного бронзового дракона, искажается драконьей магией, которая создаёт один или несколько следующих эффектов:

* Один раз в день, дракон может изменить погоду в радиусе 6 миль с центром на своём логове. Дракон не обязан для этого находиться на открытом воздухе; во всём остальном эффект идентичен заклинанию [власть над погодой](SPELL:${SPELL_CONTROL_WEATHER}). 
* Подводные растения в пределах 6 миль от драконьего логова ослепительно сияют яркими красками. 
* В своём логове дракон может создавать разные иллюзорные звуки, такие как мягкая музыка или странные звуки эхо, которые могут быть слышны в разных частях логова.
    
Если дракон умирает, изменённая погода возвращается в норму, как описано в заклинании, а прочие эффекты угасают за 1к10 дней.`,
    source: {
      id: SOURCE_MM,
      page: 123,
    },
  },
  {
    header: 'Бронзовые драконы',
    text: `Бронзовые драконы это обитатели побережий, в основном питающиеся водными растениями и рыбой. Они принимают облик дружелюбных животных и с интересом наблюдают за другими существами. Также они восхищаются военными действиями и с готовностью вступают в армию, борющуюся за правое дело.

Форма головы бронзового дракона определяется пластинами, покрытыми сетью рёбер и канавок. Изгибающиеся рога, отходящие от этих пластин, уравновешиваются шипами, расположенными на его нижней челюсти и подбородке. В плавании бронзовым драконам помогают перепончатые лапы и гладкая чешуя. [Вирмлинги бронзовых драконов](CREATURE:${CREATURE_DRAGON_BRONZE_WYRMLING}) жёлтые, с зелёным отливом; однако, по мере взросления дракона, цвет его чешуи становится более глубокого, насыщенного бронзового оттенка. Зрачки в глазах бронзовых драконов исчезают с годами, пока глаза не становятся похожи на светящиеся зелёные шары.

# Драконы побережья

Бронзовые драконы любят наблюдать за кораблями, путешествующими вдоль береговой линии мимо их логова, и иногда принимают облик дельфина или чайки, чтобы детально рассмотреть эти корабли и их команду. Отважные бронзовые драконы могут проскользнуть на борт корабля под видом птицы или [крысы](CREATURE:${CREATURE_RAT}), осматривая трюм на предмет сокровищ. Если дракон найдёт достойное пополнение для своей коллекции, он будет торговаться с капитаном корабля за эту вещь.

# Машины войны

Бронзовые драконы активно выступают против тирании, и многие бронзовые драконы стремятся испытать свой характер, используя свой размер и силу во благо.

Если конфликт разворачивается возле его логова, бронзовый дракон удостоверятся в понимании причины, а после предлагает свои услуги стороне, сражающейся за добро. После того как бронзовый дракон принимает одну из сторон, он остаётся верным союзником.

# Хорошо организованное богатство

Бронзовые драконы собирают сокровища затонувших судов, а также красочные кораллы и жемчуг с рифов и морского дна, вблизи своего логова. Когда бронзовый дракон обещает помочь армии вести войну против тирании, он просит символической оплаты. Если даже такой запрос не по карману союзникам, он может согласиться на коллекцию старых книг по военной истории или безделушку в память о союзе. Бронзовый дракон может также претендовать на сокровище, принадлежавшее врагу, если чувствует, что сокровище будет в большей сохранности под его защитой.`,
    source: {
      id: SOURCE_MM,
      page: 123,
    },
  },
  ...metalDragonDescriptionList,
]
