const {SOURCE_MTOF} = require('./../../sourceList')
const {
  CREATURE_GELATINOUS_CUBE,
  CREATURE_MIND_FLAYER,
  CREATURE_OCHRE_JELLY,
} = require('./../../creatureIdList')

module.exports = {
  header: 'Облекс',
  text: `После экспериментов на слизи, [желатиновых кубах](CREATURE:${CREATURE_GELATINOUS_CUBE}) и [студнях](CREATURE:${CREATURE_OCHRE_JELLY}), которыми кишат глубины Подземья, [свежеватели разума](CREATURE:${CREATURE_MIND_FLAYER}) вывели особый вид слизи. Облекс — слизь, способная атаковать разум других существ. Будучи хитрыми хищниками, эти склизкие желеподобные создания преследуют жертву в поисках воспоминаний, которых они так отчаянно жаждут. Когда облексы поглощают мысли, иногда убивая свою добычу, они могут сформировать странные копии своих жертв, что помогает им добывать ещё больше страдальцев для своих мрачных хозяев.

**Пожиратели воспоминаний.** Облексы питаются мыслями и воспоминаниями. Чем ум острее, тем он вкуснее, поэтому очевидно, что облексы охотятся преимущественно на цели, наделённые интеллектом, такие как волшебники и другие заклинатели. Когда подходящая еда попадает в зону их досягаемости, облекс выбрасывает своё тело вверх чтобы поглотить жертву. После стекания с жертвы, он похищает разум, оставляя её озадаченной и в растерянности.

**Природа слизи.** Облекс не нуждается во сне.`,
  source: {
    id: SOURCE_MTOF,
    page: 214,
  },
}
