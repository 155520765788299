module.exports = [
  ...require('./AI'),
  ...require('./BGDIA'),
  ...require('./DDHC_MORD_01'),
  ...require('./DMG'),
  ...require('./DnD_SADS_CLE'),
  ...require('./ERFTLW'),
  ...require('./FTD'),
  ...require('./HOTDQ'),
  ...require('./IDRotF'),
  ...require('./MFFV_1_MMB'),
  ...require('./MM'),
  ...require('./MOOT'),
  ...require('./MTOF'),
  ...require('./PotA'),
  ...require('./ROT'),
  ...require('./SKT'),
  ...require('./TCOE'),
  ...require('./TOA'),
  ...require('./UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS'),
  ...require('./UA_2021_04_14_DRACONIC_OPTIONS'),
  ...require('./VGTM'),
  ...require('./WDH'),
  ...require('./WGtE'),
  ...require('./XGTE'),
]
