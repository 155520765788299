const {DAMAGE_RADIANT} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_MOONBEAM} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')

module.exports = {
  id: SPELL_MOONBEAM,
  name: 'Лунный луч',
  nameEn: `Moonbeam`,
  description: `Бледный серебристый луч сияет в цилиндре с радиусом 5 футов и высотой 40 футов с центром на точке в пределах дистанции. Пока заклинание активно, цилиндр освещён тусклым светом. Когда существо впервые за ход входит в область заклинания или начинает там ход, оно окутывается призрачным пламенем, причиняющим настоящую боль, и должно пройти испытание Телосложения. Оно получает урон излучением 2к10 при провале или половину этого урона при успехе.

Перевёртыши проходят испытание с помехой. При провале они мгновенно принимают свой истинный облик, и не могут принимать другие формы, пока не выйдут из света от заклинания.

В каждый последующий после накладывания заклинания ход Вы можете действием перемещать луч в пределах 60 футов в любом направлении.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, урон увеличивается на 1к10 за каждый круг ячейки выше второго.`,
  lvl: 2,
  magicSchoolId: MAGIC_EVOCATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `несколько семечек лунносемянника и кусочек опалесцирующего полевого шпата`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_RADIANT,
      halfOnSuccess: true,
      diceCount: 2,
      diceType: 10,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 236,
    },
    {
      id: SOURCE_SRD,
      page: 166,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Теперь луч можно двигать и меньше чем на 60 футов',
      page: 3,
    },
  ],
}
