const {
  MAGIC_ABJURATION,
  MAGIC_CONJURATION,
  MAGIC_DIVINATION,
  MAGIC_ENCHANTMENT,
  MAGIC_EVOCATION,
  MAGIC_ILLUSION,
  MAGIC_NECROMANCY,
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {CONDITION_PARALYZED} = require('./../../../../../conditionList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SPELL_HOLD_PERSON} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_HOLD_PERSON,
  name: 'Удержание личности',
  nameEn: 'Hold Person',
  description: `Выберите гуманоида, которого видите в пределах дистанции. Цель должна пройти испытание Мудрости, иначе станет парализованной на период действия заклинания. В конце каждого своего хода цель может совершать новые испытания Мудрости. При успехе заклинание на этой цели оканчивается.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше 2. Эти гуманоиды должны находиться в пределах 30 футов друг от друга, когда Вы нацеливаетесь на них.`,
  lvl: 2,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `небольшой прямой кусочек железа`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
    condition: CONDITION_PARALYZED,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 285,
    },
    {
      id: SOURCE_SRD,
      page: 154,
    },
  ],
}
