const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_PACK_TACTICS,
  ABILITY_WATER_BREATHING,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_AIR_BEAST_SPIRIT,
  CREATURE_WATER_BEAST_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух водного зверя',
  nameEn: 'Water Beast Spirit',
  id: CREATURE_WATER_BEAST_SPIRIT,
  parentId: CREATURE_AIR_BEAST_SPIRIT,
  hp: `30 + 5-кратный круг заклинания выше 2-го`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  featureList: [
    ABILITY_PACK_TACTICS,
    ABILITY_WATER_BREATHING,
  ],
}
