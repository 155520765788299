const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  SPELL_ANTIMAGIC_FIELD,
  SPELL_DISPEL_MAGIC,
} = require('./../../../../spellIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {animatedThingsDescriptionList} = require('./../../../../textCommonParts')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_ANIMATED_ARMOR} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {GEAR_SPLINT_ARMOR} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Оживлённый доспех',
  nameEn: 'Animated Armor',
  id: CREATURE_ANIMATED_ARMOR,
  description: [
    `Эта пустая стальная оболочка громыхает при передвижении, тяжёлые пластины стучат и трутся друг о друга. Оживлённый доспех похож на мстительный дух павшего рыцаря. Тяжеловесный, но настойчивый, этот волшебный страж почти всегда одет в [пластинчатый доспех](GEAR:${GEAR_SPLINT_ARMOR}).

Для большей угрозы, оживлённый доспех часто может произносить заготовленную речь. Например, доспех может предупреждать о чём-то, спрашивать пароли или загадывать загадки. Очень редко они могут вести настоящий разговор.`,
    {
      header: 'Оживлённый доспех',
      text: `Магически оживлённые рыцарские доспехи. Массивные и неутомимые, эти конструкции особенно хороши в роли охранников.`,
      source: {
        id: SOURCE_GAME_BG_3,
        page: 224,
      },
    },
    ...animatedThingsDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 225,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 13,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 3,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_PSYCHIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_BLINDED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Восприимчивость к антимагии',
      description: `★СУЩЕСТВО★ ★недееспособен★, пока находится в пределах области [Преграды магии](SPELL:${SPELL_ANTIMAGIC_FIELD}). Став целью [Рассеивания магии](SPELL:${SPELL_DISPEL_MAGIC}), ★СУЩЕСТВО★ ★должен★ пройти испытание Телосложения СЛ испытания заклинателя, иначе потеряет сознание на 1 минуту.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного комплекта доспехов`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
