const {
  CREATURE_DONKEY,
  CREATURE_MULE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Осёл',
  nameEn: 'Donkey',
  id: CREATURE_DONKEY,
  parentId: CREATURE_MULE,
}
