const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ABILITY_HEATED_BODY} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_FIRE_SNAKE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_IGNAN} = require('./../../../../languageIdList')
const {salamanderDescriptionList} = require('./../../../../textCommonParts')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Огненная змея',
  nameEn: 'Fire Snake',
  id: CREATURE_FIRE_SNAKE,
  description: salamanderDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 254,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  vulnerabilityList: [
    DAMAGE_COLD,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_IGNAN,
      doNotSpeak: true,
    },
  ],
  cr: CR_1,
  featureList: [
    {
      id: ABILITY_HEATED_BODY,
      damage: `3 (1к6)`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Хвостом_.`,
    },
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 1,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: `Хвост`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 1,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
