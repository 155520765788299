const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_HOLD_BREATH,
  ABILITY_UNDERWATER_CAMOUFLAGE,
  ABILITY_WATER_BREATHING,
} = require('./../../../../creatureAbilityList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_OCTOPUS} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Осьминог',
  nameEn: 'Octopus',
  id: CREATURE_OCTOPUS,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 335,
  },
  armor: 12,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 11,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 4,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  cr: CR_0,
  featureList: [
    {
      id: ABILITY_HOLD_BREATH,
      condition: 'Находясь вне воды,',
      time: '1 час',
    },
    ABILITY_UNDERWATER_CAMOUFLAGE,
    ABILITY_WATER_BREATHING,
  ],
  actionList: [
    {
      name: 'Щупальца',
      description: `Цель становится схваченной (СЛ высвобождения 10). Пока цель схвачена, ★СУЩЕСТВО★ не может использовать _Щупальца_ на другой цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 0,
          diceType: 0,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Чернильное облако',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `★СУЩЕСТВО★ выпускает чернильное облако с радиусом 5 футов, если находится под водой. Эта местность становится сильно заслонённой на 1 минуту, хотя сильное течение может развеять это облако. Выпустив чернила, ★СУЩЕСТВО★ может бонусным действием совершить Рывок.`,
    },
  ],
  genderId: GENDER_MALE,
}
