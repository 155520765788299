const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_BROWN_BEAR,
  CREATURE_MASTIFF,
  CREATURE_SHRIEKER,
  CREATURE_THORNY,
  CREATURE_VEGEPYGMY,
  CREATURE_VEGEPYGMY_CHIEF,
  CREATURE_VIOLET_FUNGUS,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Вегепигмеи',
    text: `[Вегепигмеи](CREATURE:${CREATURE_VEGEPYGMY}) — грибные существа, живущие примитивными племенными сообществами, охотясь за пищей и распространяя споры, которыми они размножаются.`,
    source: {
      id: SOURCE_VGTM,
      page: 132,
    },
  },
  {
    header: 'Примитивные растения',
    text: `[Вегепигмеи](CREATURE:${CREATURE_VEGEPYGMY}), также называемые плесневый народец или плесневики, населяют тёмные области, в которых тепло и влажно, так что чаще всего их можно встретить под землёй или в плотных лесах, которые не пропускают солнечный свет. Вегепигмей инстинктивно чувствует родство с другими растениями и грибами, поэтому их племена хорошо сосуществуют с такими существами как микониды, [визгуны](CREATURE:${CREATURE_SHRIEKER}) и [лиловые грибы](CREATURE:${CREATURE_VIOLET_FUNGUS}).

Хоть они и предпочитают есть свежее мясо, кости и кровь, вегепигмеи также могут поглощать питательные вещества из почвы и многих видов органической материи, что означает, что они редко бывают голодны. Вегепигмеи могут шипеть и издавать другие звуки, пропуская воздух через рот, но они не разговаривают в обычном смысле. Друг с другом вегепигмеи общаются свистом, жестами и ритмичными постукиваниями по телу. Вегепигмеи мало что изготавливают и строят; всё снаряжение они берут у других существ или строят копии простых зданий, которые видели.`,
    source: {
      id: SOURCE_VGTM,
      page: 132,
    },
  },
  {
    header: 'Плесень порождает плесень',
    text: `Вегепигмеи рождаются из останков гуманоида или гиганта, убитого красно-коричневой плесенью. Через день из тела убитого появляется один или несколько вегепигмеев. Если от красно-коричневой плесени умирает зверь, такой как [собака](CREATURE:${CREATURE_MASTIFF}) или [медведь](CREATURE:${CREATURE_BROWN_BEAR}), то создается звероподобная плесень, называющаяся [колючка](CREATURE:${CREATURE_THORNY}), вместо гуманоидного вегепигмея. Колючки менее развиты, чем вегепигмеи, но они больше и свирепее, а их тела покрыты шипами.

Пока вегепигмей стареет, он становится мощнее и развивает на теле области спор. Вегепигмеи, переносящие споры, уважаемы другими вегепигмеями, так что чужаки часто обращаются к таким вегепигмеям как к [вождям](CREATURE:${CREATURE_VEGEPYGMY_CHIEF}). [Вождь](CREATURE:${CREATURE_VEGEPYGMY_CHIEF}) может испустить свои споры облаком, и заразить ближайших существ. Если существо умирает, будучи заражённым, то его тело производит вегепигмея, так же как и красно-коричневая плесень.

Никто не знает, откуда взялась красно-коричневая плесень. Одни истории рассказывают об искателях приключений, которые обнаружили красно-коричневую плесень и вегепигмеев в запретной горной гряде, в своеобразном металлическом подземелье, полном странной жизни. Другие истории рассказывают о том, что исследователи нашли красно-коричневую плесень в кратере, оставленном упавшей звездой, а вегепигмеи наводнили окрестные густые джунгли.`,
    source: {
      id: SOURCE_VGTM,
      page: 132,
    },
  },
  {
    header: 'Красно-коричневая плесень',
    text: `Гриб, известный как красно-коричневая плесень, обладает следующей из названия расцветкой и произрастает только в тёмных, тёплых и сырых местах. Красно-коричневую плесень, которая распространяется по металлическим объектам легко перепутать с естественной ржавчиной, и нужно успешно пройти проверку Интеллекта (Природа) или Мудрости (Выживание) СЛ 15, чтобы точно определить её на вид в таком случае.

Любое существо, находящееся в пределах 5 футов от красно-коричневой плесени, должно пройти испытание Телосложения СЛ 13, когда плесень испускает облако спор. При провале существо становится Отравленным. Будучи отравленным таким образом, существо получает 7 (2к6) урона ядом в начале каждого своего хода, и зарастает плесенью при получении этого урона. Существо может повторять испытание в конце каждого своего хода, закончив эффект при успехе. Любая магия, которая нейтрализует яды или излечивает болезни, снимает заражение. Любое существо, чьи хиты падают до 0 от яда плесени, умирает. Если погибшее существо — зверь, великан или гуманоид, то 24 часа спустя из его тела появляются один или несколько новорождённых [вегепигмеев](CREATURE:${CREATURE_VEGEPYGMY}): один из маленького тела, два из среднего, четыре из большого, восемь из огромного или шестнадцать из громадного.

Красно-коричневую плесень трудно убить, так как оружие и большинство типов урона не причиняют ей вреда. Эффекты, которые наносят урон кислотой, излучением или некротической энергией, убивают 1 квадратный фут красно-коричневой плесени за каждый 1 единицу урона. Фунт соли, галлон алкоголя или магический эффект, который излечивает болезни, убивает красно-коричневую плесень в квадратной области со стороной 10 футов. Солнечный свет убивает любую красно-коричневую плесень, которая находится в области света.`,
    source: {
      id: SOURCE_VGTM,
      page: 133,
    },
  },
]
