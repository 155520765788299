const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_ORDER_DOMAIN} = require('./../../../pcSubClassIdList')
const {
  SOURCE_GGTR,
  SOURCE_TCoE,
} = require('./../../../sourceList')

const {
  SPELL_COMMAND,
  SPELL_COMMUNE,
  SPELL_COMPULSION,
  SPELL_DOMINATE_PERSON,
  SPELL_HEROISM,
  SPELL_HOLD_PERSON,
  SPELL_LOCATE_CREATURE,
  SPELL_MASS_HEALING_WORD,
  SPELL_SLOW,
  SPELL_ZONE_OF_TRUTH,
} = require('./../../../spellIdList')
const {
  FEATURE_BONUS_PROFICIENCY_ORDER_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_ORDER_S_DEMAND,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_STRIKE_ORDER_DEMAND,
  FEATURE_EMBODIMENT_OF_THE_LAW,
  FEATURE_ORDER_DOMAIN_SPELLS,
  FEATURE_ORDER_S_WRATH,
  FEATURE_VOICE_OF_AUTHORITY,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ORDER_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_COMMAND,
      SPELL_COMMUNE,
      SPELL_COMPULSION,
      SPELL_DOMINATE_PERSON,
      SPELL_HEROISM,
      SPELL_HOLD_PERSON,
      SPELL_LOCATE_CREATURE,
      SPELL_MASS_HEALING_WORD,
      SPELL_SLOW,
      SPELL_ZONE_OF_TRUTH,
    ],
    source: [
      {
        id: SOURCE_GGTR,
        page: 26,
      },
      {
        id: SOURCE_TCoE,
        page: 42,
      },
    ],
  },
  {
    id: FEATURE_BONUS_PROFICIENCY_ORDER_DOMAIN,
    name: `Дополнительные владения`,
    nameEn: `Bonus Proficiency`,
    lvl: 1,
    text: `Вы получаете владение тяжёлыми доспехами.

Также Вы получаете владение Запугиванием или Убеждением (на Ваш выбор).`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 27,
      },
      {
        id: SOURCE_TCoE,
        page: 43,
      },
    ],
  },
  {
    id: FEATURE_VOICE_OF_AUTHORITY,
    name: `Глас властей`,
    nameEn: `Voice of Authority`,
    lvl: 1,
    text: `Вы можете использовать силу закона, чтобы вдохновить союзника на атаку.

Если Вы сотворяете на союзника заклинание ячейкой 1-го круга или выше, то сразу после сотворения того заклинания этот союзник может реакцией совершить одну атаку оружием по видимому Вами существу (существо выбираете Вы).

Если заклинание нацелено более чем на одного союзника, Вы выбираете того союзника, который сможет атаковать.`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 27,
      },
      {
        id: SOURCE_TCoE,
        page: 43,
      },
    ],
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_ORDER_S_DEMAND,
    name: `Божественный канал: Наведение порядка`,
    nameEn: `Channel Divinity: Order’s Demand`,
    lvl: 2,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}) для устрашения других.

Вы действием демонстрируете свой священный символ, и каждое существо в пределах 30 футов по Вашему выбору, которое может Вас видеть или слышать, должно пройти испытание Мудрости или быть очарованным Вами до конца Вашего следующего хода или до тех пор, пока оно не получит урон. Вы также можете заставить любое из очарованных существ бросить то, что оно несёт, когда провалит испытание.`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 27,
      },
      {
        id: SOURCE_TCoE,
        page: 43,
      },
    ],
  },
  {
    id: FEATURE_EMBODIMENT_OF_THE_LAW,
    name: `Воплощение закона`,
    nameEn: `Embodiment of the Law`,
    lvl: 6,
    text: `Вы стали очень искусно направлять магическую энергию на подчинение других.

Сотворяя заклинание школы Очарования ячейкой 1-го круга или выше, Вы можете изменить время произнесения заклинания с 1 действия на 1 бонусное действие.

Вы можете использовать эту способность количество раз, равное Вашему модификатору Мудрости (минимум один раз) и восстанавливаете все использования, завершив длинный отдых.`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 27,
      },
      {
        id: SOURCE_TCoE,
        page: 43,
      },
    ],
  },
  {
    id: FEATURE_DIVINE_STRIKE_ORDER_DEMAND,
    name: `Божественный удар`,
    nameEn: `Divine Strike`,
    lvl: [8, 14],
    text: `Вы получаете способность наполнять удары своего оружия божественной энергией.

Один раз в каждый свой ход, попав по существу атакой оружием, Вы можете заставить эту атаку нанести цели дополнительно 1к8 психического урона.

Когда Вы достигнете 14-го уровня, дополнительный урон увеличивается до 2к8.`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 27,
      },
      {
        id: SOURCE_TCoE,
        page: 44,
      },
    ],
  },
  {
    id: FEATURE_ORDER_S_WRATH,
    name: `Гнев порядка`,
    nameEn: `Order’s Wrath`,
    lvl: 17,
    text: `Враги, которых Вы выбираете для уничтожения, слабеют от объединения Ваших усилий и усилий Ваших союзников.

Нанося в свой ход существу дополнительный урон от [Божественного удара](FEATURE:${FEATURE_DIVINE_STRIKE_ORDER_DEMAND}), Вы можете проклясть это существо до начала Вашего следующего хода.

В следующий раз, когда один из Ваших союзников попадёт по проклятому существу атакой, цель также получит 2к8 психического урона, и проклятие закончится. Вы можете проклясть существо таким образом только один раз за ход.`,
    source: [
      {
        id: SOURCE_GGTR,
        page: 27,
      },
      {
        id: SOURCE_TCoE,
        page: 44,
      },
    ],
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_ORDER_DOMAIN,
  })
)

