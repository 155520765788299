import _ from 'lodash'

import upLetter from '@/utils/upLetter'

export default (
  {
    name,
    nameEn = '',
  },
  useSingular = false,
  upEnLetter = false,
) => {
  const propList = [
    useSingular
      ? 'singular'
      : 'plural',
    'nominative'
  ]

  const header = upLetter(
    typeof name === 'string'
      ? name
      : _.get(name, propList) || _.get(name, 'nominative') || ''
  )
  const subHeader = upLetter(nameEn || '')
  const title = `${header} (${upEnLetter ? upLetter(nameEn) : nameEn})`

  return {
    header,
    subHeader,
    title,
  }
}
