const {
  SPELL_BIGBY_S_HAND,
  SPELL_BURNING_HANDS,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CONE_OF_COLD,
  SPELL_COUNTERSPELL,
  SPELL_FIRE_BOLT,
  SPELL_FIREBALL,
  SPELL_ICE_STORM,
  SPELL_LIGHT,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGIC_MISSILE,
  SPELL_MIRROR_IMAGE,
  SPELL_MISTY_STEP,
  SPELL_PRESTIDIGITATION,
  SPELL_RAY_OF_FROST,
  SPELL_SHATTER,
  SPELL_STONESKIN,
  SPELL_WALL_OF_ICE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {
  SOURCE_ERRATA_VGTM,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_9} = require('./../../../../crList')
const {CREATURE_EVOKER} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Воплотитель',
  nameEn: 'Evoker',
  id: CREATURE_EVOKER,
  description: `Воплотители — специализированные волшебники, которые используют магическую энергию и стихийные силы, чтобы разрушать. Многие имеют склонность быть импульсивными и агрессивными. Другие холодны и сдержанны, выпуская свою силу только в необходимый момент, чтобы использовать слабости противников.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: [
    {
      id: SOURCE_VGTM,
      page: 213,
    },
    {
      id: SOURCE_ERRATA_VGTM,
      title: 'Построение заклинаний теперь можно использовать только для заклинаний, работающих через испытание для других существ',
      page: 1,
    },
  ],
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 4,
    },
  ],
  cr: CR_9,
  featureList: [
    {
      name: 'Построение заклинаний',
      description: `Когда ★СУЩЕСТВО★ сотворяет заклинание Воплощения, заставляющее других видимых ★им★ существ пройти испытание, ★он★ может выбрать 13 существ _(уровень заклинателя + 1)_. Эти существа автоматически преуспевают в испытании от заклинания и не получают урона, если по описанию должны получить половину урона от заклинания при успехе.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 12,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      {
        id: SPELL_FIRE_BOLT,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_LIGHT,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_RAY_OF_FROST,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_BURNING_HANDS,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_MAGIC_MISSILE,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_SHATTER,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_FIREBALL,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_LIGHTNING_BOLT,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_ICE_STORM,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_BIGBY_S_HAND,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_CONE_OF_COLD,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_CHAIN_LIGHTNING,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },
      {
        id: SPELL_WALL_OF_ICE,
        showMagicSchoolIcon: true,
        iconTitle: `Заклинание школы Воплощения, которое позволяет использовать особенность «Построение заклинаний»`,
      },

      SPELL_COUNTERSPELL,
      SPELL_MAGE_ARMOR,
      SPELL_MIRROR_IMAGE,
      SPELL_MISTY_STEP,
      SPELL_PRESTIDIGITATION,
      SPELL_STONESKIN,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
  ],
}
