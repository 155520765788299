const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_GRUNG,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_GRUNG,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Грунгский',
    genitive: 'Грунгского',
    instrumental: 'Грунгским',
    prepositional: 'Грунгском',
  },
  nameEn: 'Grung',
  isReady: false,
  isRealLang: true,
}
