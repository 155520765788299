const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_CROSSBOW_HEAVY,
  GEAR_LONGSWORD,
  GEAR_SHORTSWORD,
  GEAR_SPLINT_ARMOR,
} = require('./../../../../gearIdList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_VETERAN} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ветеран',
  nameEn: 'Veteran',
  id: CREATURE_VETERAN,
  description: `Ветераны это профессиональные воители, состоящие в армии за отдельную плату или защищающие то, что им дорого. В их ряды входят как солдаты, прошедшие службу, так и воители, никогда не служившие никому кроме себя.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 344,
  },
  armor: {
    ac: 17,
    gearId: GEAR_SPLINT_ARMOR,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_3,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Длинным мечом_. Если у него вынут короткий меч, ★он★ может также совершить атаку _Коротким мечом_.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_CROSSBOW_HEAVY,
    },
  ],
  genderId: GENDER_MALE,
}
