const {PC_SUBCLASS_WARLOCK_THE_HEXBLADE} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {GEAR_SHIELD} = require('./../../../gearIdList')
const {CREATURE_SPECTER} = require('./../../../creatureIdList')
const {
  SPELL_BANISHING_SMITE,
  SPELL_BLINK,
  SPELL_BLUR,
  SPELL_BRANDING_SMITE,
  SPELL_CONE_OF_COLD,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_PHANTASMAL_KILLER,
  SPELL_SHIELD,
  SPELL_STAGGERING_SMITE,
  SPELL_WRATHFUL_SMITE,
} = require('./../../../spellIdList')
const {
  FEATURE_ACCURSED_SPECTER,
  FEATURE_ARMOR_OF_HEXES,
  FEATURE_EXPANDED_SPELLS_WARLOCK_THE_HEXBLADE,
  FEATURE_HEX_WARRIOR,
  FEATURE_HEXBLADE_S_CURSE,
  FEATURE_MASTER_OF_HEXES,
  FEATURE_PACT_OF_THE_BLADE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_WARLOCK_THE_HEXBLADE,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    text: `Ведьмовской клинок позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_BANISHING_SMITE,
      SPELL_BLINK,
      SPELL_BLUR,
      SPELL_BRANDING_SMITE,
      SPELL_CONE_OF_COLD,
      SPELL_ELEMENTAL_WEAPON,
      SPELL_PHANTASMAL_KILLER,
      SPELL_SHIELD,
      SPELL_STAGGERING_SMITE,
      SPELL_WRATHFUL_SMITE,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 38,
    },
  },
  {
    id: FEATURE_HEXBLADE_S_CURSE,
    name: `Проклятие Ведьмовского клинка`,
    nameEn: `Hexblade’s Curse`,
    lvl: 1,
    text: `Вы получаете возможность наложить на кого-то жестокое проклятие.

Выберите бонусным действием одно видимое Вами в пределах 30 футов существо. Цель получает проклятье на 1 минуту. Проклятие заканчивается раньше, если цель умирает, если Вы умираете или Вы недееспособны. Пока проклятие не закончится, Вы получите следующие преимущества:

* Вы получаете бонус к урону по проклятой цели. Бонус равен Вашему бонусу мастерства.
* Любой бросок атаки, который Вы делаете по проклятой цели, является критическим попаданием при результате 19 или 20 на к20.
* Если проклятая цель умирает, Вы восстанавливаете количество хитов, равное Вашему уровню колдуна + Ваш модификатор Харизмы (минимум 1 хит).

Вы не сможете использовать эту умение еще раз, пока не закончите короткий или длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 38,
    },
  },
  {
    id: FEATURE_HEX_WARRIOR,
    name: `Проклятый воитель`,
    nameEn: `Hex Warrior`,
    lvl: 1,
    text: `Вы проходите необходимую подготовку, чтобы быть эффективнее в бою. Вы получаете навык владения со средними доспехами, [щитами](GEAR:${GEAR_SHIELD}) и воинским оружием.

Влияние Вашего покровителя также позволяет мистически направлять Вашу волю через оружие. Всякий раз, когда Вы заканчиваете длинный отдых, Вы можете коснуться одного оружия, которым Вы владеете, и у которого нет свойства «двуручное». Когда Вы атакуете этим оружием, Вы можете использовать для бросков атаки и урона свой модификатор Харизмы вместо модификатора Силы или Ловкости.

Это умение действует до тех пор, пока Вы не закончите длинный отдых. Если Вы позже заключаете [Договор клинка](FEATURE:${FEATURE_PACT_OF_THE_BLADE}), это умение распространяется на любое оружие договора, которое Вы вызываете этим умение, независимо от типа оружия.`,
    source: {
      id: SOURCE_XGTE,
      page: 39,
    },
  },
  {
    id: FEATURE_ACCURSED_SPECTER,
    name: `Проклятый спектр`,
    nameEn: `Accursed Specter`,
    lvl: 6,
    text: `Вы можете проклясть душу того, кого убиваете, временно обязывая его служить Вам.

Когда Вы убиваете гуманоида, Вы можете заставить его дух подняться из своего тела в качестве [спектра](CREATURE:${CREATURE_SPECTER}). Когда появляется [спектр](CREATURE:${CREATURE_SPECTER}), он получает временные хиты, равные половине уровня Вашего колдуна. Пройдите проверку инициативы для [спектра](CREATURE:${CREATURE_SPECTER}), который получает свой собственный ход. Он подчиняется Вашим устным командам, а также получает специальный бонус к своим броскам атаки, равный Вашему модификатору Харизмы (минимум +0).

[Спектр](CREATURE:${CREATURE_SPECTER}) продолжает Вам служить до конца Вашего следующего длинного отдыха, после чего он уходит в загробную жизнь.

После сковывания [спектра](CREATURE:${CREATURE_SPECTER}) этим умением, Вы не сможете снова использовать это умение, пока не закончите длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 39,
    },
  },
  {
    id: FEATURE_ARMOR_OF_HEXES,
    name: `Защита проклятья`,
    nameAlt: `Проклятый доспех`,
    nameEn: `Armor of Hexes`,
    lvl: 10,
    text: `Ваше [Проклятие](FEATURE:${FEATURE_HEXBLADE_S_CURSE}) становится более мощным.

Если цель, проклятая Вашим [Проклятием ведьмовского клинка](FEATURE:${FEATURE_HEXBLADE_S_CURSE}), попадает по Вам броском атаки, Вы можете реакцией бросить к6. При результате 4 или выше, атака промахивается по Вам, независимо от броска проклятого существа.`,
    source: {
      id: SOURCE_XGTE,
      page: 39,
    },
  },
  {
    id: FEATURE_MASTER_OF_HEXES,
    name: `Мастер проклятий`,
    nameEn: `Master of Hexes`,
    lvl: 14,
    text: `Вы можете перенести [Проклятие ведьмовского клинка](FEATURE:${FEATURE_HEXBLADE_S_CURSE}) с убитого существа на другое существо.
 
Когда существо, проклятое [Проклятием ведьмовского клинка](FEATURE:${FEATURE_HEXBLADE_S_CURSE}), умирает, Вы можете переместить [Проклятие](FEATURE:${FEATURE_HEXBLADE_S_CURSE}) на другое видимое Вами в пределах 30 футов существо, если Вы не недееспособны.
 
Когда Вы перемещаете [Проклятие](FEATURE:${FEATURE_HEXBLADE_S_CURSE}) таким образом, Вы не восстанавливаете хиты от смерти ранее проклятого существа.`,
    source: {
      id: SOURCE_XGTE,
      page: 39,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
  })
)
