const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  PARAM_DEX,
} = require('./../../../../../paramList')
const {
  SPELL_GRASPING_VINE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GRASPING_VINE,
  name: 'Цепкая лоза',
  nameEn: `Grasping Vine`,
  description: `Вы создаёте лозу, вырастающую из земли в свободном пространстве на Ваш выбор, видимом Вами в пределах дистанции. Когда Вы накладываете это заклинание, Вы можете приказать лозе ухватить видимое Вами существо, находящееся в пределах 30 футов от неё. Это существо должно пройти испытание Ловкости, иначе его подтянет на 20 футов к лозе.

Пока заклинание активно, Вы можете в каждом своём ходу бонусным действием приказывать лозе ухватить то же самое или другое существо.`,
  lvl: 4,
  magicSchoolId: MAGIC_CONJURATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 1 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
  },
  source: {
    id: SOURCE_PHB,
    page: 288,
  },
}
