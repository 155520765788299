const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_CELESTIAL} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_CELESTIAL,
  nameEn: 'Celestial',
  genderId: GENDER_MALE,
  description: {
    text: `Небожители — это существа из Верхних Планов. Многие из них являются слугами божеств, и выполняют роль посланников и агентов в мире смертных и других планах. Небожители добры от природы, поэтому небожитель, сбившийся с пути добра, это ужасная редкость. К небожителям относятся ангелы, коатли и пегасы.`,
    source: {
      id: SOURCE_MM,
      page: 9,
    },
  },
  name: {
    singular: {
      nominative: 'небожитель',
      genitive: 'небожителя',
      dative: 'небожителю',
      accusative: 'небожителя',
      instrumental: 'небожителем',
      prepositional: 'небожителе',
    },
    plural: {
      nominative: 'небожители',
      genitive: 'небожителей',
      dative: 'небожителям',
      accusative: 'небожителей',
      instrumental: 'небожителями',
      prepositional: 'небожителях',
    },
  },
}
