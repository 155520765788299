const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {GEAR_NET} = require('./../../gearIdList')
const {
  CREATURE_ABOLETH,
  CREATURE_DROW,
  CREATURE_KUO_TOA,
  CREATURE_KUO_TOA_ARCHPRIEST,
  CREATURE_KUO_TOA_MONITOR,
  CREATURE_KUO_TOA_WHIP,
  CREATURE_MIND_FLAYER,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Куо-тоа',
    text: `Куо-тоа это выродившиеся рыбоподобные гуманоиды, которые когда то населяли побережья и острова. Давним давно люди и их род изгнали куо-тоа под землю, где те и обитают в безумии и вечной темноте. Отныне куо-тоа не переносят дневной свет.`,
    source: {
      id: SOURCE_MM,
      page: 183,
    },
  },
  {
    header: 'Безумные рабы',
    text: `Во время расцвета империи [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}), свежеватели разума захватывали куо-тоа тысячами и обращали их в рабство. Куо-тоа были простыми существами, не способными противостоять психической силе иллитидов. К тому времени, когда иллитиды покинули их, длительное нахождение куо-тоа в психическом подчинении свело их с ума.

Их разум был разрушен без надежды на восстановление, и куо-тоа стали чересчур набожными, выдумывая себе богов, чтобы те защищали их от угроз. Наиболее значимой угрозой являются [дроу](CREATURE:${CREATURE_DROW}), которые убивали куо-тоа с тех пор, как две расы впервые встретились.`,
    source: {
      id: SOURCE_MM,
      page: 183,
    },
  },
  {
    header: 'Создатели богов',
    text: `Куо-тоа поклоняются богам собственного безумного творения, но если куо-тоа достаточно уверовали в то, что это божество реальное, энергия их коллективного подсознания может материализовать бога как физическую сущность. Форма, которую принимает божество, зависит от вдохновения, внушаемого этим божественным образом, и зачастую является случайной или бессмысленной.

Один из наиболее уважаемых богов куо-тоа является Матерь Морей Блибдулпулп, выглядящая как женщина с головой и клешнями речного рака, а также составным панцирем, покрывающий плечи. Блибдулпулп, вероятно, была придумана куо-тоа, которые снабдили сломанную человеческую статую конечностями и головой рака. Неожиданно их творение вселило страх в своих создателей, и в конечном итоге получило статус божества.

Куо-тоа, чьи пути пересекались с [аболетами](CREATURE:${CREATURE_ABOLETH}), зачастую поклоняются им как богам. Ослеплённые безумием, они не понимают, что аболеты используют их для своих гнусных целей.`,
    source: {
      id: SOURCE_MM,
      page: 183,
    },
  },
  {
    header: 'Теократические правители',
    text: `Первосвященники куо-тоа окружены фанатично настроенными приверженцами их веры. Первосвященник домена куо-тоа требует, чтобы все поданные поклонялись определённому божеству. Безумная вера первосвященника в собственного бога столь пылкая, что наделяет его полномочиями [верховного жреца](CREATURE:${CREATURE_KUO_TOA_ARCHPRIEST}). Первосвященник может также обучать заклинаниям набожных подчинённых, называемых [кнутами](CREATURE:${CREATURE_KUO_TOA_WHIP}). Один или несколько этих кнутов являются детьми первосвященника, их основная роль в обществе куо-тоа — бороться насмерть за право воссесть на трон, когда первосвященник умрёт. Если кнут разгневает первосвященника, первосвященник может лишить его способностей заклинателя, если не жизни.

Приказы первосвященника воплощаются [надзирателями](CREATURE:${CREATURE_KUO_TOA_MONITOR}), набожными куо-тоа, действующими как его глаза и уши. Надзиратели — смертоносные рукопашные воители, и меньшие [куо-тоа](CREATURE:${CREATURE_KUO_TOA}) живут в страхе перед ними.`,
    source: {
      id: SOURCE_MM,
      page: 183,
    },
  },
  {
    header: `Куо-тоа`,
    text: `Безумные обитатели Подземья, похожие на рыб. Известны тем, что с неистовым фанатизмом поклоняются воображаемым богам.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Снаряжение куо-тоа',
    text: `Большинство оружия куо-тоа разработано, чтобы пленить, а не убить противника. Часто используются [сети](GEAR:${GEAR_NET}), однако некоторые носят с собой похожие на клещи рогатины (также называемые клешнепосохами и человекохватателями), сконструированные для поимки и обездвиживания врагов. Воины куо-тоа также смазывают свои щиты липкой слизью, которая клеится к оружию, которым их атакуют.

В целом, куо-тоа не любят носить доспехи на своих скользких телах, и полагаются на свою упругую от природы шкуру. Однако они любят носить драгоценности, сделанные из очищенных костей, раковин, жемчуга, драгоценных камней и фрагментов панциря.`,
    source: {
      id: SOURCE_MM,
      page: 183,
    },
  },
]
