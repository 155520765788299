module.exports = [
  ...require('./itemGroups/devastation_orb'),

  require('./list/balloon_pack'),
  require('./list/bottled_breath'),
  require('./list/claws_of_the_umber_hulk'),
  require('./list/drown'),
  require('./list/ironfang'),
  require('./list/lost_сrown_of_besilmer'),
  require('./list/orcsplitter'),
  require('./list/seeker_dart'),
  require('./list/storm_boomerang'),
  require('./list/tinderstrike'),
  require('./list/weird_tank'),
  require('./list/windvane'),
  require('./list/wingwear'),
]
