const {LANG_TYPE_DEAD} = require('./../../languageTypeList')
const {LANG_LOROSS} = require('./../../languageIdList')
const {SOURCE_DND3_FRCS} = require('./../../sourceList')
const {ALPHABET_IOKHARIC} = require('./../../alphabetList')
const {PLACE_NETHERIL} = require('./../../placeIdList')

module.exports = {
  id: LANG_LOROSS,
  type: LANG_TYPE_DEAD,
  alphabetId: ALPHABET_IOKHARIC,
  nameEn: 'Loross',
  name: {
    nominative: 'Лоросс',
    genitive: 'Лоросса',
    instrumental: 'Лороссом',
    prepositional: 'Лороссе',
  },
  typicalSpeakers: 'Нетерильское дворянство',
  description: [
    {
      text: `Язык дворянства Нетерила`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
  ],
  spokenPlaceList: PLACE_NETHERIL,
  isRealLang: true,
  isReady: true,
}
