module.exports = [
  require('./gauth'),
  require('./gazer'),
  require('./gnoll_witherling'),
  require('./giant_strider'),
  require('./girallon'),
  require('./gnoll_flesh_gnawer'),
  require('./gnoll_hunter'),
  require('./grung'),
  require('./grung_elite_warrior'),
  require('./grung_wildling'),
  require('./guard_drake'),
  require('./guard_drake_black'),
  require('./guard_drake_blue'),
  require('./guard_drake_green'),
  require('./guard_drake_red'),
  require('./guard_drake_white'),
]
