const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_5,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_DIG,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_UMBER_HULK,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
    SENSE_VIBRATION_SENSE,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CREATURE_UMBER_HULK,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Бурый увалень',
  nameEn: 'Umber Hulk',
  id: CREATURE_UMBER_HULK,
  description: `Отвратительные и ужасные бурые увальни из глубин земли роются в пещерных комплексах, подземельях или поселениях Подземья в поисках еды. Счастливчики, пережившие нападение бурого увальня, часто помнят слишком мало о произошедшем из-за заглушающего разум взгляда бурого увальня.

# Обитатели окраин

Бурые увальни могут прорываться через твёрдый камень, образовывая целые туннели на своём пути. Крепкий как сталь хитин их тел может выдержать даже обвалы, обрушения туннелей и камнепады, обычно следующие за ними.

Закопавшись в стену пещеры или прохода, бурый увалень лежит и ждёт существ, которые будут проходить мимо. Его усы-щупальца ощущают любое движение рядом. Когда он вырывается из своей засады в облаке земли и камня, его ничего не подозревающая добыча оборачивается к нему лицом, немедленно впадая в транс от его сбивающего с толку взгляда, вынужденная беспомощно стоять, пока его жвала защёлкиваются на её глотке.

# Заглушающий разум

Многие пережившие столкновение с бурым увальнем могут вспомнить лишь пару деталей нападения, потому что в момент нападения взгляд бурого увальня заглушает их разум. Те, кто бился и убивал бурых увальней, могут распознать некоторые признаки. Для обитателей Подземья о неведомом враге рассказывают пугающие сказки об исчезнувших исследователях и неожиданных обвалах. Бурые увальни обладают сверхъестественными способностями в этих историях. Мораль многих из них заключается в предупреждении: если Вы заметили бурого увальня, бежать от него уже поздно.`,
  note: {
    text: `Стена пещеры обрушилась. Это последнее, что я помню`,
    author: `рассказ пережившего нападение бурого увальня`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 27,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  cr: CR_5,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 20,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  languageList: [
    LANG_UMBER_HULK,
  ],
  featureList: [
    {
      name: 'Сбивающий с толку взгляд',
      description: `Если существо начинает ход в пределах 30 футов от ★СУЩЕСТВО★ и при этом может видеть ★его★ глаза, ★СУЩЕСТВО★ может магическим образом заставить его пройти испытание Харизмы СЛ 15, если ★сам★ при этом ★дееспособен★.

При провале испытания существо не может совершать реакции до начала своего следующего хода, и бросает к8, чтобы определить, что оно будет делать в текущем ходу.

* При результате «1–4» существо ничего не делает.
* При результате «5–6» существо не совершает действий, а всё перемещение тратит на то, что перемещается в случайном направлении.
* При результате «7–8» существо совершает одну рукопашную атаку по случайным образом выбранному существу, или не делает ничего, если в пределах досягаемости существ нет.

Если существо не захвачено врасплох, оно может отводить взгляд, чтобы не проходить испытание в начале своего хода. Если существо делает это, оно не видит ★СУЩЕСТВО★ до начала своего следующего хода, после чего может снова отводить взгляд. Если существо в этот период посмотрит на ★СУЩЕСТВО★, оно тут же проходит испытание.`,
    },
    {
      name: 'Прокладывание туннеля',
      description: `★СУЩЕСТВО★ может копать сквозь сплошной камень со скоростью копания, уменьшенной вдвое, оставляя за собой туннель шириной 5 футов и высотой 8 футов.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: две _Когтями_, и одну _Жвалами_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Жвалы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
