const {SOURCE_MM} = require('./../../sourceList')
const {
  MAGIC_ITEM_MANUAL_OF_GOLEMS,
} = require('./../../magicItemIdList')

module.exports = {
  header: 'Големы',
  text: `Големы сделаны из простых материалов — глина, плоть с костями, железо или камень — но обладают удивительной силой и прочностью. У голема нет амбиций, его не нужно содержать, он не чувствует боли и не знает жалости. Эта неостановимая машина разрушений существует для того, чтобы следовать приказам хозяина, защищая или атакуя по воле создателя.

Чтобы создать голема, необходим [Справочник по големам](MAGIC_ITEM:${MAGIC_ITEM_MANUAL_OF_GOLEMS}). Красочные иллюстрации и инструкции подробно объясняют процесс создания голема определённого типа.

# Стихийный дух в материальном теле

Создание голема начинается с конструкции тела, требующего высокого мастерства в ваянии, обработке камня, работе с металлами или хирургии. Иногда сам создатель голема — мастер искусств, но часто те, кто желают создать голема, должны нанять мастеров-ремесленников для этой работы.

После изготовления тела из глины, плоти, железа или камня, создатель голема вселяет в него духа со Стихийного Плана Земли. У этой маленькой искры жизни нет ни памяти, ни личности, ни истории. Она просто заставляет двигаться и подчинятся. Этот процесс привязывает духа к искусственному телу и подчиняет его воле создателя голема.

# Вечные стражи

Големы могут охранять священные места, гробницы и сокровищницы много лет после смерти хозяина, вечно выполняя назначенные задания, игнорируя при этом физический урон и все заклинания, кроме самых мощных.

Големы создаются с особым амулетом или другой вещью, которая позволит её владельцу контролировать голема. Таким образом, големы, чьи хозяева давно мертвы, могут быть перенастроены для службы новому хозяину.

# Слепая покорность

Голем безотказно исполняет команды своего создателя или владельца. Если голем оставлен без инструкций или выведен из строя, он продолжит выполнять последние указания с максимальной усердностью. Если он не может выполнить свой приказ, голем может яростно отреагировать или стоять и ничего не делать. Голем, получивший противоречащие друг другу приказы, иногда переключается между ними.

Голем не может думать или действовать по своей воле. Несмотря на то, что он прекрасно понимает команды, он не понимает языка этих команд, и не может быть уговорён или обманут ими.

# Искусственная натура

Голему не нужен воздух, еда, питьё и сон.`,
  source: {
    id: SOURCE_MM,
    page: 65,
  },
}
