const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_DROW,
  CREATURE_MIND_FLAYER,
  CREATURE_NEOGI,
  CREATURE_NEOGI_HATCHLING,
  CREATURE_NEOGI_MASTER,
  CREATURE_UMBER_HULK,
} = require('./../../creatureIdList')

module.exports = {
  header: 'Неоги',
  text: `[Неоги](CREATURE:${CREATURE_NEOGI}) — это злобные работорговцы, которые считают большинство остальных существ, даже более слабых неоги, слугами и добычей. Неоги выглядят как очень крупные пауки с шеей и головой угря. Они могут отравить тело и разум своей цели, и способны подчинять себе других существ, которые превосходят их по физической силе.

**Иноземные тираны.** Неоги обычно обитают в отдаленных местах Материального плана, а так же в Стране Фей, Царстве Теней и в Астральном и Эфирном Планах. Они давным давно пришли из отдаленных мест Материального плана, и покинули дом для покорения и поедания существ в других мирах. Для ориентирования на больших расстояниях неоги сперва покорили и ассимилировали [бурых увальней](CREATURE:${CREATURE_UMBER_HULK}) из другого потерянного мира. Потом, с этими рабами, делающими физическую работу, неоги разработали и построили быстрые суда, некоторые из которых могут перемещаться между планами, чтобы путешествовать к новым горизонтам. Некоторые группы неоги до сих пор создают и используют такие судна, у которых довольно паучий внешний вид.

[Некоторые неоги используют магию](CREATURE:${CREATURE_NEOGI_MASTER}) — как результат соглашения между неоги и ненормальными сущностями, которых они встретили во время путешествия из домашнего мира. Эти сущности выглядят как звёзды и вмещают воплощение зла. Они известны под именами Акамар, Кейфон, Джиббет и Хадар.

Нет ничего более непостижимого в неоги, чем их сознание. Обладая способностью контролировать разум, неоги считают её применение абсолютно приемлемым. Их сообщество не видит никаких различий в индивидах, кроме способности существа контролировать других, и они не понимают эмоциональных аспектов существования, которые испытывают люди и другие похожие существа. Для неоги ненависть так же непостижима как и любовь, а верность в отсутствии власти является безрассудством.

**Круг жизни и смерти.** Неоги живут почти так же долго как и люди, и как и люди они испытывают физическое и ментальное старение по ходу жизни. Когда индивид сильно ослабевает из-за возраста, другие неоги в группе подавляют его и вводят ему особый яд. Токсин превращает старого неоги в раздутую, беспомощную массу плоти, которая называется «великий старый хозяин». Молодые неоги откладывают на него яйца и когда появляется [потомство](CREATURE:${CREATURE_NEOGI_HATCHLING}), они пожирают великого старого хозяина и друг друга, пока лишь несколько сильнейших не останется.

**Иерархия владения.** Выжившие [детёныши](CREATURE:${CREATURE_NEOGI_HATCHLING}) неоги начинают свою жизнь под контролем [взрослого неоги](CREATURE:${CREATURE_NEOGI}). Они изучают общество и зарабатывают место в нем, и каждый начинает свою подготовку с получения в подчинение молодого [бурого увальня](CREATURE:${CREATURE_UMBER_HULK}).

Неоги отмечают себя и своих рабов, используя чернила, магию превращения и татуировки, которые должны отмечать ранг, достижения и право владения. По этим знакам неоги могут определять более высокопоставленных членов своего общества, и они должны уступать тем, кто выше, или рискуют получить суровое наказание.

Кроме обязанностей слуги для своего хозяина, неоги охотно участвуют в любой деятельности, которая несёт им выгоду и в этом они хитры как дьяволы. Неоги покупают или продают, но это смертельный риск для потенциальных клиентов, так как их легко могут обратить в рабов, так что их клиентами обычно являются отчаянные или злые индивиды, или существа, которые достаточно внушительны, чтобы обращаться с неоги как с равными. Торговцы неоги могут открыть лавку на планарном базаре, на границе города [дроу](CREATURE:${CREATURE_DROW}) или около анклава [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}). В прочих местах местные скорее объединятся и уничтожат караваны неоги, чем позволят им свободно торговать и дадут какие-то привилегии.`,
  source: {
    id: SOURCE_VGTM,
    page: 173,
  },
}
