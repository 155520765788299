const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER,
  rarity: generateRarityList(list),
  type: MGC_TYPE_WONDROUS_ITEM,
  name: `Статуэтка чудесной силы`,
  nameEn: `Figurine of Wondrous Power`,
  description: `Статуэтка чудесной силы это небольшая статуэтка зверя, помещающаяся в карман. Если Вы действием произнесёте командное слово и бросите статуэтку на расстояние до 60 футов, статуэтка станет живым существом.

Если пространство, в котором существо должно появиться, занято другим существом или предметом, или если для этого существа просто нет пространства, статуэтка не становится существом.

Существо дружественно по отношению к Вам и Вашим спутникам. Оно понимает Ваши языки и подчиняется устным командам. Если Вы не отдаёте команды, существо обороняется, но других действий не совершает.

Животное существует в течение времени, зависящего от вида статуэтки. В конце этого периода животное вновь становится статуэткой. Оно становится статуэткой преждевременно, если его хиты опускаются до 0, или если Вы действием произнесёте командное слово, касаясь его. Когда существо становится статуэткой, её свойства нельзя использовать повторно, пока не пройдёт определённое количество времени, специфичное для каждой статуэтки.`,
  genderId: GENDER_FEMALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 203,
  },
}
