const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_10,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DRAGON_CHROMATIC,
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DRAGON_TRUE,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_CLIMB,
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  redDragonDescriptionList,
  dragonsRedNoteList,
} = require('./../../../../textCommonParts')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const dragonBreatheWeaponCollection = require('./../../../dragonBreatheWeaponCollection')
const {
  CREATURE_DRAGON_RED_YOUNG,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Молодой красный дракон',
  nameEn: 'Young Red Dragon',
  id: CREATURE_DRAGON_RED_YOUNG,
  description: redDragonDescriptionList,
  note: dragonsRedNoteList,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAGON_TRUE,
    CREATURE_TYPE_DRAGON_CHROMATIC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 112,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
    [SPEED_FLY]: 80,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 21,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  cr: CR_10,
  languageList: [
    LANG_DRACONIC,
    LANG_COMMON,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 10,
            diceBonus: 6,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
    dragonBreatheWeaponCollection[CREATURE_DRAGON_RED_YOUNG],
  ],
  genderId: GENDER_MALE,
}
