const listToCollectionById = require('./../utils/listToCollectionById')
const {faLips} = require('./../fonts/fontawesome-pro-5.11.2-web/reworked/lips')
const {faSack} = require('./../fonts/fontawesome-pro-5.11.2-web/reworked/sack')

const {
  faAmericanSignLanguageInterpreting,
  faCoins,
} = require('@fortawesome/free-solid-svg-icons')

const { SOURCE_AI } = require('./sourceList')

const CAST_VERBAL = 'verbal'
const CAST_SOMATIC = 'somatic'
const CAST_MATERIAL = 'material'
const CAST_ROYALTY = 'royalty'
const CAST_NONE = 'none'

const castComponentList = [
  {
    id: CAST_VERBAL,
    name: {
      singular:  {
        nominative: 'вербальный',
        genitive: 'вербального',
        dative: 'вербальному',
        accusative: 'вербального',
        instrumental: 'вербальным',
        prepositional: 'вербальном'
      },
      plural:  {
        nominative: 'вербальные',
        genitive: 'вербальных',
        dative: 'вербальным',
        accusative: 'вербальных',
        instrumental: 'вербальными',
        prepositional: 'вербальных'
      },
    },
    iconName: faLips,
    shortName: 'В',
    description: `Большинство заклинаний требуют произношения таинственных слов. Сами по себе слова не являются источником силы заклинания просто комбинация звуков с особой тональностью вызывает резонанс в прядях магии, приводя их в движение.

Таким образом, персонаж с кляпом во рту или в области заклинания _Тишина_, не может активировать заклинания с вербальным компонентом.`
  },
  {
    id: CAST_SOMATIC,
    name: {
      singular:  {
        nominative: 'соматический',
        genitive: 'соматического',
        dative: 'соматическому',
        accusative: 'соматического',
        instrumental: 'соматическим',
        prepositional: 'соматическом'
      },
      plural:  {
        nominative: 'соматические',
        genitive: 'соматических',
        dative: 'соматическим',
        accusative: 'соматических',
        instrumental: 'соматическими',
        prepositional: 'соматических'
      },
    },
    iconName: faAmericanSignLanguageInterpreting,
    shortName: 'С',
    description: `Заклинание может требовать энергичной жестикуляции или замысловатой последовательности телодвижений. Если у заклинания есть соматический компонент, у заклинателя должна быть свободной хотя бы одна рука для исполнения этих жестов.`
  },
  {
    id: CAST_MATERIAL,
    name: {
      singular:  {
        nominative: 'материальный',
        genitive: 'материального',
        dative: 'материальному',
        accusative: 'материального',
        instrumental: 'материальным',
        prepositional: 'материальном'
      },
      plural:  {
        nominative: 'материальные',
        genitive: 'материальных',
        dative: 'материальным',
        accusative: 'материальных',
        instrumental: 'материальными',
        prepositional: 'материальных'
      },
    },
    iconName: faSack,
    shortName: 'М',
    description: `Накладывание некоторых заклинаний требует наличия особых предметов, указанных в скобках в описании заклинания. Персонаж может использовать мешочек с компонентами или заклинательную фокусировку вместо указанных компонентов. Однако, если для компонента указана цена, у персонажа для накладывания заклинания должен быть именно такой компонент.

Если в заклинании сказано, что материальные компоненты расходуются, заклинатель должен предоставить компоненты для каждого использования этого заклинания.

У заклинателя должна быть одна свободная рука для доступа к материальным компонентам или чтобы держать заклинательную фокусировку, но это может быть та же самая рука, что используется для выполнения соматического компонента.`
  },
  {
    id: CAST_ROYALTY,
    name: {
      singular:  {
        nominative: 'гонорарный',
        genitive: 'гонорарного',
        dative: 'гонорарному',
        accusative: 'гонорарного',
        instrumental: 'гонорарным',
        prepositional: 'гонорарном'
      },
      plural:  {
        nominative: 'гонорарные',
        genitive: 'гонорарных',
        dative: 'гонорарным',
        accusative: 'гонорарных',
        instrumental: 'гонорарными',
        prepositional: 'гонорарных'
      },
    },
    iconName: faCoins,
    shortName: 'Г',
    description: `Уникальный бренд заклинаний Джима Тёмномагии сочетает в себе магический профессионализм с особым видом денежного пыла. Новые заклинания, первоначально разработанные Джимом, породили новый тип компонентов заклинаний, который постенно распространился на другие заклинания, созданные сотрудниками и франчайзи Корпорации Приобретений: гонорарный компонент.

Чтобы сотворить заклинание, использующее гонорарный компонент (включая использование свитка заклинаний или другого магического предмета, в котором хранится такое заклинание), заклинатель должен иметь с собой достаточно денег. Стоимость применения заклинателя устанавливается автором заклинания, хотя обычно она составляет l зм на круг ячейки заклинания. При сотворении заклинания, гонорар волшебным образом переносится в сундук или другой объект, обозначенный автором этого заклинания. Этот платеж производится независимо от того, знает ли заклинатель, использующий заклинание, о его гонорарном компоненте или нет. Если у заклинателя недостаточно средств, заклинание не проваливается, но сотворить его нельзя.

Хотя многие заклинатели пытались обойти гонорарный компонент, ни один из них так и не достиг успеха. Тем не менее, говорят, что заклинатель может попытаться пройти проверку Интеллекта (Магия) СЛ 15, разыгрывая заклинание с гонорарным компонентом. При успешной проверке оплата берется у случайного существа в пределах 10 футов от заклинателя, без ведома этого существа.`,
    source: {
      id: SOURCE_AI,
      page: 77,
    },
  },
]

module.exports = castComponentList

module.exports.castComponentCollection = listToCollectionById(castComponentList)

module.exports.CAST_VERBAL = CAST_VERBAL
module.exports.CAST_SOMATIC = CAST_SOMATIC
module.exports.CAST_MATERIAL = CAST_MATERIAL
module.exports.CAST_ROYALTY = CAST_ROYALTY
module.exports.CAST_NONE = CAST_NONE
