const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  ATTACK_MELEE_SPELL,
} = require('./../../../../../attackTypeList')
const {
  DAMAGE_ELECTRICITY,
} = require('./../../../../../damageTypeList')
const {
  SPELL_SHOCKING_GRASP,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SHOCKING_GRASP,
  name: 'Электрошок',
  nameEn: 'Shocking Grasp',
  description: `Молния спрыгивает с Вашей руки и ударяет существо, которого Вы пытались коснуться. Совершите по цели рукопашную атаку заклинанием. Вы совершаете бросок атаки с преимуществом, если цель носит доспех из металла. При попадании цель получает урон электричеством 1к8, и до начала своего следующего хода не может совершать реакции.`,
  highLvlCast: `Урон заклинания увеличивается на 1к8, когда Вы достигаете 5 уровня (2к8), 11 уровня (3к8) и 17 уровня (4к8).`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    attackType: ATTACK_MELEE_SPELL,
    damage: {
      type: DAMAGE_ELECTRICITY,
      diceCount: 1,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 290,
    },
    {
      id: SOURCE_SRD,
      page: 179,
    },
  ],
}
