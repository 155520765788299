const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_HALFLING} = require('./../../languageIdList')
const {LANG_TYPE_STANDARD} = require('./../../languageTypeList')
const {PLACE_LUIREN} = require('./../../placeIdList')
const {
  SOURCE_MGZN_DRGN_ANNUAL_1999,
  SOURCE_DND3_5_PHB,
  SOURCE_PHB,
} = require('./../../sourceList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GNOME,
} = require('./../../pcRaceIdList')

module.exports = {
  id: LANG_HALFLING,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_STANDARD,
  name: {
    nominative: 'Луйрик',
    genitive: 'Луйрика',
    instrumental: 'Луйриком',
    prepositional: 'Луйрике',
  },
  name5eOfficial: {
    nominative: 'Полуросличий',
    genitive: 'Полуросличьего',
    instrumental: 'Полуросличьим',
    prepositional: 'Полуросличьем',
  },
  nameAlt: [
    'Полуросличий',
    'Луриеналь',
  ],
  nameEn: 'Luiric',
  nameEnAlt: [
    'Halfling',
    'Lurienal',
  ],
  spokenPlaceList: PLACE_LUIREN,
  description: [
    {
      text: `Их язык не является секретным, но они не торопятся делиться им с остальными. Пишут они мало, и почти не создали собственной литературы, но устные предания у них очень распространены.`,
      source: {
        id: SOURCE_PHB,
        page: 29,
      },
    },
    {
      text: `Полурослики общаются на своём собственном языке, который использует символы из Всеобщего. Они очень мало пишут на своём собственном языке и, в отличие от [дварфов](PC_RACE:${PC_RACE_DWARF}), [эльфов](PC_RACE:${PC_RACE_ELF}) и [гномов](PC_RACE:${PC_RACE_GNOME}), у них нет богатой библиотеки письменных работ. Но у них очень сильна традиция устного повествования. И хотя язык полуросликов не секретен, они не очень желают делиться им с другими.`,
      source: {
        id: SOURCE_DND3_5_PHB,
        page: 17,
      },
    },
    {
      text: `Практически не употребляется вне Луриена — нынче большинство полуросликов говорят на человеческих языках`,
      source: {
        id: SOURCE_MGZN_DRGN_ANNUAL_1999,
        page: 31,
      },
    },
  ],
  typicalSpeakers: 'Полурослики',
  isRealLang: true,
  isReady: true,
}
