const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CREATURE_BASTIAN_THERMANDAR} = require('./../../../../creatureIdList')
const {CR_8} = require('./../../../../crList')
const {DAMAGE_FIRE} = require('./../../../../damageTypeList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {MAGIC_ITEM_TINDERSTRIKE} = require('./../../../../magicItemIdList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {eternalFlameDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_GENASI_FIRE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
} = require('./../../../../skillList')
const {
  SPELL_BURNING_HANDS,
  SPELL_COUNTERSPELL,
  SPELL_DIMENSION_DOOR,
  SPELL_FIREBALL,
  SPELL_FIRE_BOLT,
  SPELL_HOLD_MONSTER,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MESSAGE,
  SPELL_MISTY_STEP,
  SPELL_PRESTIDIGITATION,
  SPELL_PRODUCE_FLAME,
  SPELL_SCORCHING_RAY,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  LANG_COMMON,
  LANG_IGNAN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Бастиан Термандар',
  nameEn: 'Bastian Thermandar',
  id: CREATURE_BASTIAN_THERMANDAR,
  hasSpoilers: true,
  description: [
    {
      header: 'Бастиан Термандар',
      text: `Подобно многим в культе огня, Бастиан горит внутренним огнём, но его пламя питает скорее честолюбие, а не желание увидеть выжженный дочиста мир. Бастиан замышляет свергнуть Вейнифер и присвоить [Высекающий Искры](MAGIC_ITEM:${MAGIC_ITEM_TINDERSTRIKE}) себе.

Бастиан практикует тайные искусства, причем многое о своей магии огня он узнал от самой Вейнифер. В бою он полагается на свои заклинания: его прозвали «быстрый ожог», так как обычно он пытается нанести максимальный урон в начале схватки. Если Бастиан знает, что грядёт бой, но не может нанести упреждающий удар, он становится более осторожным, вызывая [стену огня](SPELL:${SPELL_WALL_OF_FIRE}), чтобы защитить себя, прежде чем использовать другие боевые заклинания.`,
      source: {
        id: SOURCE_PotA,
        page: 206,
      },
    },
    eternalFlameDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_GENASI_FIRE,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 206,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 15,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 18,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_IGNAN,
  ],
  cr: CR_8,
  spellCast: [
    {
      baseStat: PARAM_CON,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_PRODUCE_FLAME,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_BURNING_HANDS,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 9,
      spellCasterClass: PC_CLASS_SORCERER,
      spellIdList: [
        SPELL_COUNTERSPELL,
        SPELL_DIMENSION_DOOR,
        SPELL_FIREBALL,
        SPELL_FIRE_BOLT,
        SPELL_HOLD_MONSTER,
        SPELL_MAGE_ARMOR,
        SPELL_MAGE_HAND,
        SPELL_MAGIC_MISSILE,
        SPELL_MESSAGE,
        SPELL_MISTY_STEP,
        SPELL_PRESTIDIGITATION,
        SPELL_SCORCHING_RAY,
        SPELL_SHIELD,
        SPELL_SHOCKING_GRASP,
        SPELL_WALL_OF_FIRE,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        1,
      ],
    },
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
