const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_GIANT_CENTIPEDE,
  CREATURE_KOBOLD,
  CREATURE_LIZARDFOLK,
  CREATURE_SCORPION,
  CREATURE_POISONOUS_SNAKE,
  CREATURE_SHAMBLING_MOUND,
} = require('./../../creatureIdList')

const colorDragonDescriptionList = require('./dragonColor')

module.exports = [
  {
    header: 'Логово чёрного дракона',
    text: `Чёрные драконы обитают в болотах на оборванных окраинах цивилизации. Логово чёрного дракона обычно располагается в мрачных пещерах, гротах или руинах, которые хотя бы частично затоплены, и в которых есть заводи, в которых дракон может отдыхать, переваривая своих жертв. Логово заполняют изъеденные кислотой кости предыдущих жертв и облепленные мухами останки свежих убитых. На всё это взирают обваливающиеся статуи. Многоножки, [скорпионы](CREATURE:${CREATURE_SCORPION}) и [змеи](CREATURE:${CREATURE_POISONOUS_SNAKE}) населяют логово, наполненное вонью смерти и разложения.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов; дракон не может использовать один и тот же эффект два раунда подряд:

* Вода в заводях, которые дракон видит в пределах 120 футов, вздымаются в попытке захватить с собой стоящих рядом. Все существа, находящиеся на земле в пределах 20 футов от такой заводи, должны пройти испытание Силы СЛ 15, иначе волна сбивает их с ног и утягивает на 20 футов в воду. 
* Облако роящихся насекомых заполняет сферу 20-футового радиуса с центром в точке, которую выберет дракон в пределах 120 футов от себя. Это облако огибает углы и остаётся на месте, пока дракон не рассеет его действием, не воспользуется другим действием логова или не умрёт. Облако создаёт слабо заслонённую местность. Все существа в этом облаке, когда оно появляется, должны пройти испытание Телосложения СЛ 15, получая при провале колющий урон 10 (3к6), или половину этого урона при успехе. Существо, закончившее свой ход в облаке, получает колющий урон 10 (3к6). 
* Магическая тьма распространяется от точки, которую выберет дракон в пределах 60 футов от себя, создавая сферу тьмы с радиусом 15 футов, пока дракон не рассеет её действием, не воспользуется другим действием логова или не умрёт. Тьма огибает углы. Существа с тёмным зрением не могут видеть сквозь эту тьму, а немагический свет не может её осветить. Если область действия этого эффекта пересечётся с областью света, созданного заклинанием с уровнем не больше 2, заклинание, создавшее свет, рассеивается.

# Местные эффекты

Регион, в котором есть логово легендарного чёрного дракона, искажается драконьей магией, которая создаёт один или несколько следующих эффектов:

* Путешествия в пределах 6 миль вокруг логова занимают вдвое больше времени из-за того, что крепкие, густо сплетающиеся растения разрастаются ещё сильнее, а топи становятся менее проходимыми, заполняясь дурно пахнущей грязью. 
* Водные источники в пределах 1 мили от логова сверхъестественным образом портятся. Врагов дракона, выпивших воду из них, стошнит через несколько минут. 
* Туман, слабо заслоняющий местность, покрывает землю в пределах 6 миль от логова.

Если дракон умирает, растительность остаётся такой, какой она уже выросла, но прочие эффекты угасают за 1к10 дней.`,
    source: {
      id: SOURCE_MM,
      page: 119,
    },
  },
  {
    header: 'Чёрные драконы',
    text: `Наиболее злобные и подлые из цветных драконов, чёрные драконы собирают сокровища погибших народов. Этим драконам отвратительно видеть, как слабые процветают, и они упиваются моментами краха королевств гуманоидов. Они располагают свои логова в смрадных болотах и ветхих руинах, там, где когда-то были такие царства.

Морда чёрного дракона, с глубоко посаженными глазами и широкими ноздрями, очень напоминает череп. Его изогнутые, составные рога меняют свой цвет от костного у основания до мёртвенно-чёрного у кончиков. С возрастом плоть чёрного дракона у его рогов и на скулах портится, становясь будто бы проеденной кислотой, оставляя лишь тонкий слой шкуры, которая лишь подчёркивает его схожесть с черепом. Голова чёрного дракона покрыта шипами и рогами. Его язык плоский и раздвоенный, а изо рта течёт слюна, чей кислотный запах дополняет вонь гнилых растений и тухлой воды, исходящую от дракона.

Когда чёрный дракон вылупляется, его чешуя глянцево-чёрная, но со временем она тускнеет и толстеет, помогая сливаться с трясиной и руинами, служащими ему домом.

# Грубые и жестокие

Все цветные драконы злы, но чёрные драконы особо выделяются садизмом. Они живут моментами, когда жертва умоляет о пощаде, и часто предлагает иллюзию спасения или побега, прежде чем покончить с врагом.

Чёрный дракон нападает в первую очередь на самых слабых, обеспечивая себе скорую и жестокую победу, которая подогревает его эго и ужасает остальных противников. На грани поражения чёрный дракон сделает что угодно, чтобы спастись самому, но предпочтёт смерть, не давая другому существу получить над собой власть.

# Противники и слуги

Чёрные драконы ненавидят и боятся других драконов. Они шпионят за своими драконьими соперниками издали, выискивая возможность убить более слабых и избежать более сильных. Если более сильный дракон будет ему угрожать, чёрный дракон покинет своё логово и отправится на поиски новой территории.

Злые [людоящеры](CREATURE:${CREATURE_LIZARDFOLK}) почитают чёрных драконов и служат им, устраивая набеги на поселения гуманоидов ради сокровищ и еды, которые они преподносят драконам в качестве дани. Они также возводят грубые подобия дракона на границах владений своего драконьего хозяина.

Злобное влияние чёрного дракона может также привести к спонтанным появлениям [ползающих насыпей](CREATURE:${CREATURE_SHAMBLING_MOUND}), которые ищут и убивают добрых существ, приближающихся к логову чёрного дракона.

[Кобольды](CREATURE:${CREATURE_KOBOLD}) населяют логова многих чёрных драконов, как паразиты. Они становятся жестокими, как их тёмные хозяева, и часто пытают и мучают пленных укусами [многоножек](CREATURE:${CREATURE_GIANT_CENTIPEDE}) и [скорпионьими](CREATURE:${CREATURE_SCORPION}) жалами, прежде чем подать несчастных в качестве блюда для драконьего пиршества.

# Богатства древних

Чёрные драконы создают клады из сокровищ и магических предметов рухнувших империй и завоёванных королевств, чтобы напомнить себе о собственном величии. Чем больше прошлых цивилизаций дракон переживает, тем больше заявляет он прав на богатства нынешней.`,
    source: {
      id: SOURCE_MM,
      page: 119,
    },
  },
  ...colorDragonDescriptionList,
]
