const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_GIANT_EAGLE,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_GIANT_EAGLE,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык гигантских орлов',
    genitive: 'Языка гигантских орлов',
    instrumental: 'Языком гигантских орлов',
    prepositional: 'Языке гигантских орлов',
  },
  nameEn: 'Giant Eagle',
  isRealLang: true,
  isReady: false,
}
