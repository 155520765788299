module.exports = [
  require('./ice_knife'),
  require('./illusory_dragon'),
  require('./immolation'),
  require('./infernal_calling'),
  require('./infestation'),
  require('./investiture_of_flame'),
  require('./investiture_of_ice'),
  require('./investiture_of_stone'),
  require('./investiture_of_wind'),
  require('./invulnerability'),
]
