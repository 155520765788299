const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_ITS_SUMMONER} = require('./../../../../languageIdList')
const {SOURCE_WDH} = require('./../../../../sourceList')
const {
  CREATURE_AXE_BEAK,
  CREATURE_DIATRYMA,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Диатрима',
  nameEn: 'Diatryma',
  id: CREATURE_DIATRYMA,
  parentId: CREATURE_AXE_BEAK,
  genderId: GENDER_FEMALE,
  description: {
    header: 'Диатрима',
    text: `Это цветастая нелетучая птица Большого размера, обитающая в Подземье.

У диатримы параметры [топороклюва](CREATURE:${CREATURE_AXE_BEAK}), но она наносит колющий урон вместо режущего.

Если диатрима призвана, то она дружественна к призывателю и его спутникам, на ней можно ездить верхом, она понимает языки призывателя и подчиняется его устным приказам. Если призыватель не отдаст приказ, диатрима будет только защищаться, но не совершает иных действий.`,
    source: {
      id: SOURCE_WDH,
      page: 192,
    },
  },
  source: {
    id: SOURCE_WDH,
    page: 192,
  },
  languageList: [{
    id: LANG_ITS_SUMMONER,
    onlyUnderstands: true,
  }],
  actionList: [
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
  ],
}
