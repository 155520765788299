const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_TORTLE} = require('./../../creatureTypeIdList')
const {tortleDescriptionList} = require ('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_TORTLE,
  nameEn: 'Tortle',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'тортл',
      genitive: 'тортла',
      dative: 'тортлу',
      accusative: 'тортла',
      instrumental: 'тортлом',
      prepositional: 'тортле',
    },
    plural: {
      nominative: 'тортлы',
      genitive: 'тортлов',
      dative: 'тортлам',
      accusative: 'тортлов',
      instrumental: 'тортлами',
      prepositional: 'тортлах',
    },
  },
  note: {
    text: `Большую рыбу поймав,

Ищу я доброго друга,

Дабы улов разделить`,
    author: 'хокку тортла',
  },
  description: tortleDescriptionList,
}
