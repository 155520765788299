const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    CR_0,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BEAST,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_KEEN_HEARING_AND_SIGHT,
  } = require('./../../../../creatureAbilityList'),
  {
    SPELL_FIND_FAMILIAR,
  } = require('./../../../../spellIdList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_ALMIRAJ,
    CREATURE_UNICORN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Альмираж',
  nameEn: 'Almiraj',
  id: CREATURE_ALMIRAJ,
  description: `Альмираж — большой, робкий кролик с закрученный спиралью рогом длиной в 1 фут, растущим изо лба, похожий на рог [единорога](CREATURE:${CREATURE_UNICORN}). Если он атакует, он пытается проткнуть врагов своим рогом.

Когда-то давно альмиражей привезли на Чульт торговцы из далёкой Захары. Умеющие избегать хищников, эти существа расплодились по всему тропическому полуострову. Они живут в земляных норах и могут быть пойманы и одомашнены.

С разрешения Мастера, при помощи заклинания [Поиск фамильяра](SPELL:${SPELL_FIND_FAMILIAR}) можно вызвать альмиража.`,
  couldBeFamiliar: true,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 210,
  },
  armor: 13,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 2,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 10,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_KEEN_HEARING_AND_SIGHT,
  ],
  actionList: [
    {
      name: 'Рог',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
