const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  SPELL_REMOVE_CURSE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_REMOVE_CURSE,
  name: 'Снятие проклятия',
  nameEn: 'Remove Curse',
  description: `После Вашего касания все проклятья, действующие на одно существо или предмет, оканчиваются. Если предмет был проклятым магическим предметом, проклятье остаётся, но заклинание разрывает настройку владельца на него, поэтому его можно снять или выбросить.`,
  lvl: 3,
  magicSchoolId: MAGIC_ABJURATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  source: [
    {
      id: SOURCE_PHB,
      page: 279,
    },
    {
      id: SOURCE_SRD,
      page: 174,
    },
  ],
}
