const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SPEED_WALK,
  SPEED_FLY,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_GIANT_STORM,
} = require('./../../../../creatureTypeIdList')
const {
  giantStormNote,
  stormGiantDescriptionList,
} = require('./../../../../textCommonParts')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CG} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_16} = require('./../../../../crList')
const {CREATURE_STORM_GIANT_QUINTESSENT} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPELL_CONTROL_WEATHER} = require('./../../../../spellIdList')

module.exports = {
  name: 'Штормовой великан квинтэссент',
  nameAlt: 'Штормовой гигант квинтэссент',
  nameEn: 'Storm Giant Quintessent',
  id: CREATURE_STORM_GIANT_QUINTESSENT,
  note: [
    ...giantStormNote,
    {
      text: `Женты утверждают, что Чёрная Дорога — безопаснейший путь через пустыню Анорох, но на ней есть отрезок, проходящий мимо поглощённых песками руин некогда громадной арки — говорят, портала, построенного великанами. Вокруг неё бушует песчаная буря, и в ней некоторые видели лицо: хмурый лик великана.`,
      author: `Эльминстер`,
    },
  ],
  description: [
    `Чтобы предвосхитить неминуемое, некоторые штормовые великаны, приближающиеся к концу своей жизни, ищут путь избежать смерти. Они проникают в глубины своей могущественной связи со стихиями и растворяют себя в природе, буквально превращаясь в полубессознательные шторма. Метель, которая яростно и бесконечно вьет вокруг горного пика, водоворот, вращающийся вокруг отдаленного острова, или гроза, которая воет беспрерывно вдоль рваного берега, может на самом деле являться бессмертной формой штормового великана, цепляющегося за существование.`,
    {
      header: 'Стихийные орудия',
      text: `Штормовой великан квинтэссент отбрасывает свои доспехи и оружие, но получает способность формировать оружие просто из воздуха. Когда великану они не нужны или когда он умирает, его стихийное оружие исчезает.`,
      source: {
        id: SOURCE_VGTM,
        page: 138,
      },
    },
    {
      header: 'Забытая форма',
      text: `Штормовой великан квинтэссент может в любой момент превратиться в свою настоящую великанскую форму. Эта перемена временная, но может поддерживаться достаточно долго, чтобы великан мог поговорить со смертным, выполнить простую задачу или защитить жилище от агрессоров.`,
      source: {
        id: SOURCE_VGTM,
        page: 138,
      },
    },
    {
      header: 'Логово квинтэссента',
      text: `Штормовому великану квинтэссенту не нужен ни замок, ни логово в подземелье. Его логово — это обычно уединенная область или известное географическое место, такое как горный пик, большой водопад, удалённый остров, покрытое туманом озеро, красивый коралловый риф или обветренный пустынный утёс. В зависимости от окружающей среды, шторм, в котором живёт штормовой великан, может быть метелью, тайфуном, грозой или песчаной бурей.

# Действия логова

Штормовой великан квинтэссент может использовать действия логова в форме великана и в виде шторма. По инициативе 20 (проигрыш при ничье), великан может совершить действие логова, чтобы вызвать один из следующих эффектов; великан не может использовать один и тот же эффект два раза подряд:

* Великан может вызвать удар грома с центром в любой точке своего логова. Любое существо не далее 20 футов от этой точки должно пройти испытание Телосложения СЛ 18 или оглохнет до конца своего следующего хода. 
* Великан создаёт сферу тумана радиусом 20 футов (или мутную воду внутри воды) с центром в любой точке своего логова. Сфера распространяется огибая углы и видимость в ней сильно затруднена. Туман держится, пока великан не развеет его (действие не требуется) и его нельзя развеять ветром. 
* Великан создаёт сильный порыв ветра (или сильное течение в воде) линией 60 футов в длину и 10 футов в ширину, начинающийся из любой точки логова. Каждое существо в линии должно пройти испытание Силы СЛ 18 или его оттолкнёт на 15 футов по дуновению ветра. Порыв развеивает газы и пары, тушит свечи, факела и другое незащищённое пламя. Защищённое пламя, такое как фонари, имеет 50% вероятность погаснуть.

# Местные эффекты

Местность, содержащая логово штормового великана квинтэссента, изменяется из-за его присутствия, которое создаёт один или несколько из следующих эффектов: 

* Сильный ветер дует везде в радиусе 1 мили от логова, что делает невозможным зажигание огня, если только область, где зажигается огонь, не защищена от ветра. 
* В радиусе 1 мили от логова всегда идёт дождь, снег, дуют пыльники или песчаные бури (зависит от местности). Дождь заставляет реки и ручьи выходить из берегов; снег, пыльники и песчаные бури наметают сильные сугробы или дюны. 
* В радиусе 5 миль от логова ни днём ни ночью не прекращаются вспышки молний и раскаты грома. Если великан погибает, то молнии, гром и сильные ветра прекращаются незамедлительно. Дождь, снег, пыльники и песчаные бури постепенно затухают в течение 1к8 дней.`,
      source: {
        id: SOURCE_VGTM,
        page: 138,
      },
    },
    ...stormGiantDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_STORM,
  ],
  alignmentId: ALIGNMENT_CG,
  source: {
    id: SOURCE_VGTM,
    page: 139,
  },
  armor: 12,
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 50,
    [SPEED_SWIM]: 50,
    [SPEED_FLY]: {
      value: 50,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 29,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 20,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 20,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_THUNDER,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_16,
  featureList: [
    ABILITY_AMPHIBIOUS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Гигант делает две атаки _Мечом молний_ или дважды использует _Копьё ветра_.`,
    },
    {
      name: 'Меч молний',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 15,
        hit: {
          type: DAMAGE_ELECTRICITY,
          diceCount: 9,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Копьё ветра',
      description: `Великан формирует ветер в форме копья и метает его в видимое им существо в пределах 600 футов. Копьё считается магическим оружием и бьёт без промаха, нанося 19 (3к6+9) колющего урона. Копьё исчезает после удара.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Порыв ветра',
      description: `Великан выбирает целью видимое им существо в пределах 60 футов и создаёт магический порыв ветра вокруг него. Цель должна пройти испытание Силы СЛ 18 или её оттолкнет на 20 футов в любом горизонтальном направлении по выбору великана.`,
    },
    {
      name: 'Удар молнии',
      cost: 2,
      description: `Великан швыряет молнию в видимое им существо в пределах 600 футов. Цель должна пройти испытание Ловкости СЛ 18 и получить 22 (4к10) урона звуком при провале или половину урона при успехе.`,
    },
    {
      name: 'Единство с бурей',
      cost: 3,
      description: `Великан исчезает, растворившись в буре, окружающей его логово. Великан может окончить этот эффект в начале любого своего хода, снова став великаном и появившись в любом месте внутри своего логова. Растворившись, великан не может делать ничего, кроме действий логова и его нельзя выбрать целью атак, заклинаний или других эффектов. Великан не может использовать эту способность вне логова, и не может её использовать, если другое существо использует заклинание [Власть над погодой](SPELL:${SPELL_CONTROL_WEATHER}) или похожую магию для подавления бури.`,
    },
  ],
}
