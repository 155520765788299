const {SOURCE_XGTE} = require('./../../../sourceList')
const {SPELL_COMMAND} = require('./../../../spellIdList')
const {PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR} = require('./../../../pcSubClassIdList')
const {
  FEATURE_BARDIC_INSPIRATION,
  FEATURE_ENTHRALLING_PERFORMANCE,
  FEATURE_MANTLE_OF_INSPIRATION,
  FEATURE_MANTLE_OF_MAJESTY,
  FEATURE_UNBREAKABLE_MAJESTY,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ENTHRALLING_PERFORMANCE,
    name: 'Завораживающее выступление',
    nameAlt: 'Чарующее представление',
    nameEn: 'Enthralling Performance',
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR,
    text: `Вы можете наполнить свое выступление притягательными чарами фей.

Если Вы выступаете как минимум в течение 1 минуты, то можете попытаться очаровать слушателей своим пением, чтением стихотворения или танцем. В конце выступления выберите количество гуманоидов в пределах 60 футов от Вас, которые смотрели или слушали всё выступление, количество которых не превышает Ваш модификатор Харизмы (минимум 1). Каждая цель должна пройти испытание Мудрости против Вашей СЛ заклинаний или будет очарована Вами. Очарованные таким образом существа боготворят Вас и говорят о Вас крайне восторженно с любым заговорившим с ними. Они мешают Вашим недругам, хотя при этом избегают насилия, если только к этому моменту уже и так не были настроены драться за Вас. Эффект на цели заканчивается через 1 час или если цель получает любой урон, или если Вы атакуете её, или если она замечает, как Вы атакуете или наносите урон кому-то из её союзников.

Если цель пройдёт испытание, то не ощущает того, что Вы пытались ее очаровать.

Вы должны окончить короткий или длинный отдых, чтобы использовать это умение вновь.`,
    source: {
      id: SOURCE_XGTE,
      page: 13,
    },
  },
  {
    id: FEATURE_MANTLE_OF_INSPIRATION,
    name: `Мантия вдохновения`,
    nameEn: `Mantle of Inspiration`,
    lvl: [3, 5, 10, 15],
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR,
    text: `Вы получаете возможность слагать песни, сплетённые с чарами фей, которые наполняют Ваших союзников отвагой и проворством.

Бонусным действием Вы можете потратить одно использование своего [Вдохновения барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}), чтобы придать себе поразительный внеземной облик. Когда Вы делаете это, выберите несколько видимых Вами в пределах 60 футов союзников, которые могут Вас видеть. Количество выбранных целей не должно превышать Ваш модификатор Харизмы (минимум 1). Каждая цель при этом получает 5 временных хитов. Когда цель получает временные хиты от этой способности, она также может использовать немедленно свою реакцию, чтобы переместиться на расстояние, не превышающее своей скорости, не вызывая провоцированных атак.

Количество временных хитов увеличивается, когда Вы достигаете определенных уровней в этом классе, увеличиваясь до 8 на 5 уровне, 11 на 10 уровне и 14 на 15 уровне.`,
    source: {
      id: SOURCE_XGTE,
      page: 13,
    },
  },
  {
    id: FEATURE_MANTLE_OF_MAJESTY,
    name: `Мантия величия`,
    nameEn: `Mantle of Majesty`,
    lvl: 6,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR,
    text: `Вы получаете возможность укутаться в чары фей, заставляющие других хотеть служить Вам.

Бонусным действием Вы можете сотворить заклинание [Приказ](SPELL:${SPELL_COMMAND}) без траты ячейки заклинания и принимаете облик неземной красоты на 1 минуту или пока не завершится Ваша концентрация (как если бы Вы концентрировались на заклинании). В течение этого времени Вы можете сотворять заклинание [Приказ](SPELL:${SPELL_COMMAND}) бонусным действием в каждый свой ход, не тратя ячейку заклинания.

Любое существо, очарованное Вами, автоматически проваливает испытание против [Приказа](SPELL:${SPELL_COMMAND}), которое Вы сотворяете с помощью этого умения

После использования этого умения Вы не можете использовать его вновь, пока не завершите длинный отдых.`,
    spellIdList: SPELL_COMMAND,
    source: {
      id: SOURCE_XGTE,
      page: 13,
    },
  },
  {
    id: FEATURE_UNBREAKABLE_MAJESTY,
    name: `Несокрушимое величие`,
    nameEn: `Unbreakable Majesty`,
    lvl: 14,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR,
    text: `Ваша внешность приобретает потусторонние черты, заставляя Вас выглядеть диким и прекрасным.

Вы можете бонусным действием принять магически величественный вид на 1 минуту или пока Вы не станете недееспособны. В течение этого времени, когда существо пытается атаковать Вас в первый раз за ход, атакующий должен пройти испытание Харизмы против Вашей СЛ заклинаний. При провале оно не может атаковать Вас в этот ход и должно выбрать новую цель для атаки, иначе атака потеряна. При успешном испытании оно может атаковать Вас в этот ход, но получает помеху на любые испытания, которые будет делать против Ваших заклинаний в Ваш следующий ход.

Приняв этот величественный вид, Вы не можете сделать это снова, пока не завершите короткий или длинный отдых`,
    source: {
      id: SOURCE_XGTE,
      page: 13,
    },
  },
]
