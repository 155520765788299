module.exports = [
  {
    text: `Когда дела у них идут плохо, они похищают детей, до каких могут дотянуться, и приносят их в жертву своему трусливому богу. Хм, похоже что в конце концов они не слишком-то отличаются от большинства людей`,
    author: `Воло`,
  },
  {
    text: `Если произнести имя Раксиворт трижды, глядя на свое отражение в зеркале, то ксварты придут к тебе во тьме ночной и украдут заветную безделушку`,
    author: `Воло`,
  },
  {
    text: `Если Вы верите в то, что говорит вам Воло, то Вы этого заслуживаете`,
    author: `Эльминстер`,
  },
]
