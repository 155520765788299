const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_MONSTROSITY,
  nameEn: 'Monstrosity',
  genderId: GENDER_MALE,
  description: {
    text: `Монстры — это чудовища в абсолютном смысле этого слова. Это жуткие чудища, обычно с неестественным происхождением, и напрочь лишённые милосердия. Некоторые — результат магических экспериментов, которые пошли не так, как задумано (например, совомеды), другие — порождение страшных проклятий (например, минотавры или юань-ти). Любое такое существо сложно классифицировать, и, в некотором роде, это универсальная категория для существ, которые не вписываются в любой другой вид.`,
    source: {
      id: SOURCE_MM,
      page: 9,
    },
  },
  name: {
    singular: {
      nominative: 'монстр',
      genitive: 'монстра',
      dative: 'монстру',
      accusative: 'монстра',
      instrumental: 'монстром',
      prepositional: 'монстре',
    },
    plural: {
      nominative: 'монстры',
      genitive: 'монстров',
      dative: 'монстрам',
      accusative: 'монстров',
      instrumental: 'монстрами',
      prepositional: 'монстрах',
    },
  },
}
