const {
  GEAR_ROD,
  GEAR_STAFF,
  GEAR_WAND,
} = require('./../../../gearIdList')

const gearIdList = [
  GEAR_ROD,
  GEAR_STAFF,
  GEAR_WAND,
]

module.exports = gearIdList
