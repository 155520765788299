const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN} = require('./../../../sourceList')
const {PC_SUBCLASS_MAGE_OF_LOREHOLD_2021_06_08} = require('./../../../pcSubClassIdList')
const {
  FEATURE_LOREHOLD_SPELLS_2021_06_08,
} = require('./../../../featureIdList')
const {
  SPELL_ARCANE_EYE,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_DESTRUCTIVE_WAVE,
  SPELL_KNOCK,
  SPELL_LEGEND_LORE,
  SPELL_LOCATE_OBJECT,
  SPELL_SACRED_FLAME,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_STONE_SHAPE,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_LOREHOLD_SPELLS_2021_06_08,
    name: `Заклинания Лорхолда`,
    nameEn: `Lorehold Spells`,
    lvl: 1,
    text: `You learn the cantrip sacred flame and the 1st-level spell comprehend languages. You learn additional spells when you reach certain levels in this class, as shown on the Lorehold Spells table.

Each of these spells counts as a class spell for you, but it doesn’t count against the number of spells you know. If you are a wizard, you can add these spells to your spellbook upon learning them, without expending any gold, and prepare them as normal. `,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_ARCANE_EYE,
      SPELL_COMPREHEND_LANGUAGES,
      SPELL_DESTRUCTIVE_WAVE,
      SPELL_KNOCK,
      SPELL_LEGEND_LORE,
      SPELL_LOCATE_OBJECT,
      SPELL_SACRED_FLAME,
      SPELL_SPEAK_WITH_DEAD,
      SPELL_SPIRIT_GUARDIANS,
      SPELL_STONE_SHAPE,
    ],
    source: {
      id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
      page: 2,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MAGE_OF_LOREHOLD_2021_06_08,
  })
)

