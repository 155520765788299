const {LANG_TYPE_DEAD} = require('./../../languageTypeList')
const {LANG_ARAGRAKH} = require('./../../languageIdList')
const {SOURCE_DND3_FRCS} = require('./../../sourceList')
const {ALPHABET_IOKHARIC} = require('./../../alphabetList')

module.exports = {
  id: LANG_ARAGRAKH,
  type: LANG_TYPE_DEAD,
  alphabetId: ALPHABET_IOKHARIC,
  nameEn: 'Aragrakh',
  name: {
    nominative: 'Араграх',
    genitive: 'Араграха',
    instrumental: 'Араграхом',
    prepositional: 'Араграхе',
  },
  description: [
    {
      text: `Старый высокий язык змеев`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
  ],
  isRealLang: true,
  isReady: true,
}
