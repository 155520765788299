const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_DREAM_OF_THE_BLUE_VEIL,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_DREAM_OF_THE_BLUE_VEIL,
  name: 'Сон синей вуали',
  nameEn: 'Dream of the Blue Veil',
  description: `Вы и до восьми согласных существ в пределах дальности теряете сознание на время действия заклинания и испытываете видения другого мира на Материальном плане, такого как Оэрт, Торил, Кринн или Эберрон. Если заклинание достигает своей полной продолжительности, видения завершаются тем, что каждый из Вас сталкивается с таинственной синей вуалью и отодвигает её. После этого заклинание заканчивается и Вы мысленно и физически переноситесь в мир, который видели в видениях.

Чтобы сотворить это заклинание, у Вас должен быть магический предмет, который был создан в мире, в который Вы хотите попасть, и Вы должны знать о существовании этого мира, даже если не знаете его названия. Ваш пункт назначения в другом мире — безопасное место в пределах 1 мили от того места, где был создан магический предмет. В качестве альтернативы, Вы можете сотворить заклинание, если одно из выбранных существ родилось в другом мире, в результате чего Вашим пунктом назначения будет безопасное место в пределах 1 мили от того места, где это существо родилось.

Заклинание преждевременно заканчивается на существе, если это существо получает какой-либо урон, и это существо не переносится. Если Вы получаете какой-либо урон, заклинание заканчивается для Вас и всех других существ, при этом никто из Вас не перемещается.`,
  lvl: 7,
  magicSchoolId: MAGIC_CONJURATION,
  range: 20,
  componentIdList: [
    CAST_SOMATIC,
    CAST_MATERIAL,
    CAST_VERBAL,
  ],
  materialText: `магический предмет или согласное существо из мира назначения`,
  hasCost: true,
  castTime: { timeId: TIME_MINUTE, count: 10 },
  duration: { timeId: TIME_HOUR, count: 6 },
  source: [
    {
      id: SOURCE_TCoE,
      page: 153,
    },
  ],
}
