const {ALPHABET_DETHEK} = require('./../../alphabetList')
const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {primordialLanguageDescriptionList} = require('./../../textCommonParts')
const {SOURCE_DND3_5_MotP} = require('./../../sourceList')
const {
  LANG_AURAN,
  LANG_PRIMORDIAL,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_AURAN,
  alphabetId: ALPHABET_DETHEK,
  type: LANG_TYPE_EXOTIC,
  dialectOf: LANG_PRIMORDIAL,
  name: {
    nominative: 'Ауран',
    genitive: 'Аурана',
    instrumental: 'Аураном',
    prepositional: 'Ауране',
  },
  nameEn: 'Auran',
  typicalSpeakers: 'Обитатели Стихийного Плана Воздуха',
  description: [
    {
      text: `Родной язык на Стихийном Плане Воздуха — Ауран, хриплый, спокойный язык, похожий на глубокий, медленный выдох.`,
      source: {
        id: SOURCE_DND3_5_MotP,
        page: 68,
      },
    },
    ...primordialLanguageDescriptionList,
  ],
  isRealLang: true,
  isReady: true,
}
