const {PC_SUBCLASS_WIZARD_WAR_MAGIC} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  SPELL_COUNTERSPELL,
  SPELL_DISPEL_MAGIC,
} = require('./../../../spellIdList')

const {
  FEATURE_ARCANE_DEFLECTION,
  FEATURE_DEFLECTING_SHROUD,
  FEATURE_DURABLE_MAGIC,
  FEATURE_POWER_SURGE,
  FEATURE_TACTICAL_WIT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ARCANE_DEFLECTION,
    name: `Магическое отражение`,
    nameEn: `Arcane Deflection`,
    lvl: 2,
    text: `Вы обучаетесь тому, как плести магию так, чтобы укрепить своё сопротивление всякому вреду.

Когда по Вам попадает атака или Вы проваливаете испытание, то можете реакцией получить бонус +2 к КД от этой атаки или бонус +4 к этому испытанию.

Использовав эту способность, Вы не сможете сотворять никаких заклинаний, кроме заговоров до конца своего следующего хода.`,
    source: {
      id: SOURCE_XGTE,
      page: 25,
    },
  },
  {
    id: FEATURE_TACTICAL_WIT,
    name: `Тактическая смекалка`,
    nameEn: `Tactical Wit`,
    lvl: 2,
    text: `Ваша способность оценивать тактические ситуации позволяет Вам действовать быстрее в бою.

Вы можете получить бонус к броску инициативы, равный модификатору Интеллекта.`,
    source: {
      id: SOURCE_XGTE,
      page: 25,
    },
  },
  {
    id: FEATURE_POWER_SURGE,
    name: `Всплеск мощи`,
    nameEn: `Power Surge`,
    lvl: 6,
    text: `Вы можете запасать магическую энергию внутри себя чтобы позднее усилить ею свои наносящие урон заклинания. Такая запасённая энергия называется _Всплеском мощи_.

Максимальное число Ваших запасённых Всплесков мощи равно модификатору Интеллекта (минимум 1). Когда Вы завершаете длинный отдых, число _Всплесков_ в Вашем запасе становится равным одному. Когда Вы успешно отменяете заклинание [Рассеиванием магии](SPELL:${SPELL_DISPEL_MAGIC}) или [Контрзаклинанием](SPELL:${SPELL_COUNTERSPELL}), Вы получаете один _Всплеск мощи_, забирая магию из отменённого заклинания. Если Вы заканчиваете короткий отдых не имея запасённых всплесков мощи, Вы получаете один в свой запас.

Один раз за ход, когда Вы наносите урон существу или предмету заклинанием волшебника, Вы можете потратить один _Всплеск мощи_, нанося цели дополнительный урон силовым полем. Дополнительный урон равен половине Вашего уровня волшебника.`,
    source: {
      id: SOURCE_XGTE,
      page: 25,
    },
  },
  {
    id: FEATURE_DURABLE_MAGIC,
    name: `Стойкая магия`,
    nameEn: `Durable Magic`,
    lvl: 10,
    text: `Магия, которую Вы поддерживаете, позволяет Вам более эффективно защищаться.

Пока Вы поддерживаете концентрацию на заклинании, Вы получаете бонус +2 к КД и всем испытаниям.`,
    source: {
      id: SOURCE_XGTE,
      page: 26,
    },
  },
  {
    id: FEATURE_DEFLECTING_SHROUD,
    name: `Отражающий покров`,
    nameEn: `Deflecting Shroud`,
    lvl: 14,
    text: `Ваше [Магическое отражение](FEATURE:${FEATURE_ARCANE_DEFLECTION}) наделяется смертоносной магией.

Используя [Магическое отражение](FEATURE:${FEATURE_ARCANE_DEFLECTION}), Вы можете испустить разряды магической энергии. До трёх существ по Вашему выбору, видимых Вами в пределах 60 футов, получают урон силовым полем, равный половине Вашего уровня волшебника.`,
    source: {
      id: SOURCE_XGTE,
      page: 26,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_WAR_MAGIC,
  })
)

