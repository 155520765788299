const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_PERCEPTION,
  SKILL_PERFORMANCE,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_SHORTBOW,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {
  satyrDescriptionList,
  satyrNote,
} = require('./../../../../textCommonParts')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_SATYR} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_FEY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Сатир',
  nameEn: 'Satyr',
  id: CREATURE_SATYR,
  description: satyrDescriptionList,
  note: satyrNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 256,
  },
  armor: {
    ac: 14,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 11,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERFORMANCE]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_SYLVAN,
    LANG_ELVEN,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: `Таран`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_SHORTBOW,
    },
  ],
  genderId: GENDER_MALE,
}
