const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {
  CREATURE_STONEMELDER,
  CREATURE_BLACK_EARTH_PRIEST,
} = require('./../../../../creatureIdList')
const {CR_4} = require('./../../../../crList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GEAR_SPLINT_ARMOR} = require('./../../../../gearIdList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_VIBRATION_SENSE} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {blackEarthDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  SPELL_ACID_SPLASH,
  SPELL_BLADE_WARD,
  SPELL_ERUPTING_EARTH,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FALSE_LIFE,
  SPELL_LIGHT,
  SPELL_MAXIMILIAN_S_EARTHEN_GRASP,
  SPELL_MELD_INTO_STONE,
  SPELL_MENDING,
  SPELL_MOLD_EARTH,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_STONESKIN
} = require('./../../../../spellIdList')
const {
  LANG_COMMON,
  LANG_TERRAN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Ваятель камня',
  nameEn: 'Stonemelder',
  id: CREATURE_STONEMELDER,
  description: [
    {
      header: 'Ваятели камня',
      text: `Ваятели камня — это заклинатели, получившие свою силу напрямую от Огремоха. При помощи стихийной магии земли они защищают своё тело каменными панцирями. Ваятели камня составляют элиту войск культа и отчитываются напрямую перед его лидерами. Даже [жрецы Чёрной Земли](CREATURE:${CREATURE_BLACK_EARTH_PRIEST}) не могут отдавать им приказания.

Каждый ваятель камня вооружён особым предметом — жезлом Чёрной Земли. На первый взгляд жезл похож на обычную булаву, но в руках ваятелей камня он становится проводником для гнева Огремоха.`,
      source: {
        id: SOURCE_PotA,
        page: 200,
      },
    },
    blackEarthDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 200,
  },
  armor: {
    ac: 17,
    gearId: GEAR_SPLINT_ARMOR,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 16,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 17,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_VIBRATION_SENSE,
      value: 30,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_TERRAN,
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Предсмертный взрыв',
      description: `Когда ★СУЩЕСТВО★ умирает, ★он★ превращается в камень, после чего взрывается кучей осколков, осыпаясь дымящейся грудой щебня. Каждое существо в 10 футах от ★взрывающегося★ ★СУЩЕСТВО★ должно пройти испытание Ловкости СЛ 14. При провале существо получает дробящий урон 11 (2к10), а при успехе — половину этого урона.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 7,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_ACID_SPLASH,
      SPELL_BLADE_WARD,
      SPELL_ERUPTING_EARTH,
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_FALSE_LIFE,
      SPELL_LIGHT,
      SPELL_MAXIMILIAN_S_EARTHEN_GRASP,
      SPELL_MELD_INTO_STONE,
      SPELL_MENDING,
      SPELL_MOLD_EARTH,
      SPELL_SHATTER,
      SPELL_SHIELD,
      SPELL_STONESKIN
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      name: 'Жезл Чёрной Земли',
      description: `★СУЩЕСТВО★ также может использовать ячейку заклинания, чтобы нанести дополнительный дробящий урон 2к8 за ячейку 1 уровня, плюс ещё 1к8 урона за каждый уровень ячейки выше 1.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
