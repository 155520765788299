module.exports = [
  require('./sea_spawn'),
  require('./shadow_mastiff'),
  require('./shadow_mastiff_alpha'),
  require('./shoosuva'),
  require('./skunk'),
  require('./slithering_tracker'),
  require('./spawn_of_kyuss'),
  require('./stegosaurus'),
  require('./stench_kaw'),
  require('./stone_giant_dreamwalker'),
  require('./storm_giant_quintessent'),
  require('./swarm_of_cranium_rats'),
  require('./swarm_of_rot_grubs'),
  require('./swashbuckler'),
]
