const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MAGIC_ITEM_RING_OF_THREE_WISHES} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_WISH} = require('./../../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_RING_OF_THREE_WISHES,
  name: `Кольцо трёх желаний`,
  nameEn: `Ring of Three Wishes`,
  type: MGC_TYPE_RING,
  rarity: MGC_RARITY_LEGENDARY,
  isConsumable: true,
  description: `Нося это кольцо, Вы можете действием использовать 1 из 3 его зарядов, чтобы наложить им заклинание [Исполнение желаний](SPELL:${SPELL_WISH}). Кольцо перестаёт быть магическим, когда тратится последний заряд.`,
  genderId: GENDER_MIDDLE,
  spellIdList: [SPELL_WISH],
  source: {
    id: SOURCE_DMG,
    page: 178,
  },
}
