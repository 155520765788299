const {abilityScoreImprovementTemplate} = require('./../featureCommonRawList')
const {CREATURE_DRAGON_RED_ANCIENT} = require('./../../creatureIdList')
const {PC_CLASS_ROGUE} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
} = require('./../../sourceList')
const {SPELL_ICE_STORM} = require('./../../spellIdList')
const {GEAR_THIEVES_TOOLS} = require('./../../gearIdList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_ROGUE,
  FEATURE_BLINDSENSE,
  FEATURE_CUNNING_ACTION,
  FEATURE_ELUSIVE,
  FEATURE_EVASION_ROGUE,
  FEATURE_EXPERTISE_ROGUE,
  FEATURE_RELIABLE_TALENT,
  FEATURE_ROGUISH_ARCHETYPE,
  FEATURE_SLIPPERY_MIND,
  FEATURE_SNEAK_ATTACK,
  FEATURE_STEADY_AIM,
  FEATURE_STROKE_OF_LUCK,
  FEATURE_THIEVES_CANT,
  FEATURE_UNCANNY_DODGE,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_EXPERTISE_ROGUE,
    name: `Компетентность`,
    nameEn: `Expertise`,
    lvl: [1, 6],
    text: `Выберите два Ваших владения в навыках или одно владение навыком и владение [воровскими инструментами](GEAR:${GEAR_THIEVES_TOOLS}). Ваш бонус мастерства удваивается для всех проверок характеристик, которые Вы совершаете, используя любое из выбранных владений.

На 6 уровне Вы можете выбрать ещё два владения (навыки или [воровские инструменты](GEAR:${GEAR_THIEVES_TOOLS})), чтобы получить эту же выгоду.`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_SNEAK_ATTACK,
    name: `Скрытая атака`,
    nameEn: `Sneak Attack`,
    lvl: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
    text: `Вы знаете, как точно наносить удар и использовать отвлечение врага. Один раз в ход Вы можете причинить дополнительный урон 1к6 одному из существ, по которому Вы попали атакой, совершённой с преимуществом к броску атаки. Атака должна использовать дальнобойное оружие или оружие со свойством «фехтовальное».

Вам не нужно иметь преимущество при броске атаки, если другой враг цели находится в пределах 5 футов от неё. Этот враг не должен быть недееспособным, и у Вас не должно быть помехи для броска атаки.

Дополнительный урон увеличивается, когда Вы получаете уровни в этом классе, как показано в таблице. 

# Урон скрытой атаки

| Уровень плута | Урон | Уровень плута | Урон |
|---------------|------|---------------|------|
|  1–2          |  1к6 | 11–12         |  6к6 |
|  3–4          |  2к6 | 13–14         |  7к6 |
|  5–6          |  3к6 | 15–16         |  8к6 |
|  7–8          |  4к6 | 17–18         |  9к6 |
|  9–10         |  5к6 | 19–20         | 10к6 |
`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_THIEVES_CANT,
    name: `Воровской жаргон`,
    nameEn: `Thieves’ Cant`,
    lvl: 1,
    text: `Во время плутовского обучения Вы выучили воровской жаргон, тайную смесь диалекта, жаргона и шифра, который позволяет скрывать сообщения в, казалось бы, обычном разговоре. Только другое существо, знающее воровской жаргон, понимает такие сообщения. Это занимает в четыре раза больше времени, нежели передача тех же слов прямым текстом.

Кроме того, Вы понимаете набор секретных знаков и символов, используемый для передачи коротких и простых сообщений. Например, является ли область опасной или территорией гильдии воров, находится ли поблизости добыча, простодушны ли люди в округе, и предоставляют ли здесь безопасное убежище для воров в бегах. `,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_CUNNING_ACTION,
    name: `Хитрое действие`,
    nameEn: `Cunning Action`,
    lvl: 2,
    text: `Ваше мышление и ловкость позволяют двигаться и действовать быстрее.

Каждый свой ход в бою Вы совершать Рывок, Отход или Засаду бонусным действием.`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_STEADY_AIM ,
    name: `Устойчивый прицел`,
    nameEn: `Steady Aim`,
    lvl: 3,
    text: `Вы Бонусным действием даёте себе преимущество на следующий бросок атаки на текущем ходу.

Вы можете использовать эту способность, только если не двигались на этом ходу, и после её использования Ваша скорость станет равна 0 до конца текущего хода.`,
    source: {
      id: SOURCE_TCoE,
      page: 92,
    },
  },
  {
    id: FEATURE_ROGUISH_ARCHETYPE,
    name: `Архетип плута`,
    nameEn: `Roguish Archetype`,
    lvl: [3, 9, 13, 17],
    text: `Вы выбираете архетип, который отображает Ваши плутовские способности.

Выбранный Вами архетип предоставляет умения на 9, 13 и 17 уровнях.`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_ROGUE,
    lvl: [4, 8, 10, 12, 16, 19],
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_UNCANNY_DODGE,
    name: `Невероятное уклонение`,
    nameEn: `Uncanny Dodge`,
    lvl: 5,
    text: `Когда видимый Вами нападающий, попадает по Вам атакой, Вы можете реакцией уменьшить вдвое урон от этой атаки по себе.`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_EVASION_ROGUE,
    name: `Увёртливость`,
    nameEn: `Evasion`,
    lvl: 7,
    text: `Вы можете ловко увернуться от зональных эффектов, например, огненного дыхания [красного дракона](CREATURE:${CREATURE_DRAGON_RED_ANCIENT}) или заклинания [Град](SPELL:${SPELL_ICE_STORM}).

Если Вы попадаете под действие эффекта, который позволяет Вам пройти испытание Ловкости, чтобы получить только половину урона, вместо этого при успехе Вы не получаете вовсе никакого урона, и получаете только половину урона при провале.`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_RELIABLE_TALENT,
    name: `Надёжный талант`,
    nameEn: `Reliable Talent`,
    lvl: 11,
    text: `Вы улучшаете выбранные навыки, пока они не достигнут совершенства.

Каждый раз, когда Вы совершаете проверку характеристики, которая позволяет добавить бонус мастерства, Вы можете при выпадении на к20 результата «1–9» считать, что выпало «10».`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_BLINDSENSE,
    name: `Слепое чутьё`,
    nameAlt: `Слепое зрение`,
    nameEn: `Blindsense`,
    lvl: 14,
    text: `Если Вы можете слышать, то знаете о местонахождении всех скрытых и невидимых существ в пределах 10 футов.`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_SLIPPERY_MIND,
    name: `Скользкий ум`,
    nameEn: `Slippery Mind`,
    lvl: 15,
    text: `Вы увеличиваете силу мышления.

Вы получаете владение испытаниями Мудрости.`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_ELUSIVE,
    name: `Неуловимость`,
    nameEn: `Elusive`,
    lvl: 18,
    text: `Вы можете уклоняться так хорошо, что противник крайне редко может взять над Вами верх.

Никакие броски атаки не получают преимущества над Вами, пока Вы дееспособны.`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
  {
    id: FEATURE_STROKE_OF_LUCK ,
    name: `Удача`,
    nameEn: `Stroke of Luck`,
    lvl: 20,
    text: `Вы получаете сверхъестественный дар преуспевать, когда это нужнее всего.

Если Ваша атака промахивается по цели, находящейся в пределах досягаемости, Вы можете изменить промах на попадание. В качестве альтернативы, если Вы провалили проверку характеристики, Вы можете заменить результат, выпавший на к20, на «20».

Использовав это умение, Вы не можете использовать его повторно, пока не завершите короткий или длинный отдых.`,
    source: {
      id: SOURCE_PHB,
      page: 97,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_ROGUE,
  })
)
