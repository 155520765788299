const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_2,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_FEY,
  CREATURE_TYPE_HAG,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_AQUAN,
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  hagsDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AMPHIBIOUS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_SEA_HAG,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Морская карга',
  nameEn: 'Sea Hag',
  id: CREATURE_SEA_HAG,
  description: [
    `Морские карги живут в мрачных и грязных подводных логовах, в окружении водянников и других водных чудовищ.

Красота приводит морских карг в ярость. Когда морская карга сталкивается с чем-то красивым, она может просто атаковать, или испортить это. Если чтото прекрасное даёт надежду, морская ведьма хочет вселить отчаяние. Если что-то внушает мужество, морская ведьма хочет вселить страх.

**Уродство внутри и снаружи.** Морские карги являются самыми уродливыми из всех карг. Склизкая чешуя покрывает их мертвенно-бледную кожу. Волосы морской карги похожи на водоросли и покрывают её тощее тело, а её стеклянные глаза выглядят безжизненными, как у куклы. Также морская карга может скрывать свой истинный облик под покровом иллюзии, но из-за проклятья она всё равно кажется уродливой. В лучшем случае она может притвориться нищенкой.`,
    ...hagsDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_HAG,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 171,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 13,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AQUAN,
    LANG_GIANT,
    LANG_COMMON,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Кошмарная внешность',
      description: `Все гуманоиды, начинающие ход в пределах 30 футов от ★СУЩЕСТВО★, и видящие её истинный облик, должны проходить испытание Мудрости СЛ 11. При провале существо становится испуганным на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, с помехой, если ★СУЩЕСТВО★ находится в пределах линии обзора, оканчивая эффект на себе при успехе. Если испытание существа было успешным, или эффект на нём окончился, оно получает иммунитет к _Кошмарной внешности_ ★этого★ ★СУЩЕСТВО★ на следующие 24 часа.

Если цель не захвачена врасплох, и обнаружение истинного облика ★СУЩЕСТВО★ не было неожиданным, цель может отвести взгляд, чтобы не проходить испытание. В этом случае отводящее взгляд существо до начала своего следующего хода совершает с помехой броски атаки по карге.`,
    },
  ],
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Смертельный взгляд',
      description: `★СУЩЕСТВО★ нацеливается на одно испуганное существо, которое видит в пределах 30 футов от себя. Если цель видит ★СУЩЕСТВО★, она должна пройти испытание Мудрости СЛ 11 от этой магии, иначе её хиты опускаются до 0.`,
    },
    {
      name: 'Иллюзорная внешность',
      description: `★СУЩЕСТВО★ покрывает себя и всё, что несёт и носит, магической иллюзией, заставляющей ★его★ выглядеть как другое уродливое существо примерно ★его★ размера и с гуманоидным телом. Иллюзия оканчивается, если ★СУЩЕСТВО★ умирает, и ★СУЩЕСТВО★ может окончить её бонусным действием.

Изменения, внесённые эти эффектом, не проходят физическую проверку. Например, ★СУЩЕСТВО★ может скрыть когти на пальцах, но тот, кто коснётся ★его★ руки, почувствует когти. Если же полагаться только на зрение, то существо должно действием совершить проверку Интеллекта (Анализ) СЛ 16 и преуспеть, чтобы понять, что была использована иллюзия.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
