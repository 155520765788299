const {
  CREATURE_FEAR_SHADOW_SPIRIT_2020_03_26,
  CREATURE_FURY_SHADOW_SPIRIT_2020_03_26,
  CREATURE_FEAR_SHADOWSPAWN_SPIRIT,
} = require('./../../../../creatureIdList')
const {
  ABILITY_SHADOW_STEALTH,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Дух испуганной тени',
  nameEn: 'Fear Shadow Spirit',
  id: CREATURE_FEAR_SHADOW_SPIRIT_2020_03_26,
  releasedAs: CREATURE_FEAR_SHADOWSPAWN_SPIRIT,
  parentId: CREATURE_FURY_SHADOW_SPIRIT_2020_03_26,
  featureList: [
    ABILITY_SHADOW_STEALTH,
  ],
}
