const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_YETI} = require('./../../languageIdList')

module.exports = {
  id: LANG_YETI,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык йети',
    genitive: 'Языка йети',
    instrumental: 'Языком йети',
    prepositional: 'Языке йети',
  },
  isRealLang: true,
}
