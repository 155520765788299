const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  PARAM_DEX,
} = require('./../../../../../paramList')
const {
  DAMAGE_ACID,
} = require('./../../../../../damageTypeList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_TASHA_S_CAUSTIC_BREW,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TASHA_S_CAUSTIC_BREW,
  name: 'Ташино едкое варево',
  nameAlt: 'Едкое варево Таши',
  nameEn: 'Tasha’s Caustic Brew',
  description: `Вы испускаете поток кислоты 30-футовой линией шириной 5 футов в выбранном Вами направлении. Все существа в этой линии должны пройти испытание Ловкости.

При провале цель покрывается кислотой на всё время действия заклинания, или пока она сама или другое существо действием не очистит её от кислоты. Пока цель покрыта кислотой, она получает 2к4 урона кислотой в начале каждого своего хода.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 2к4 за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  rangeComment: `30-футовая линия`,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL ],
  materialText: `немного гниющей еды`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_ACID,
      diceCount: 2,
      diceType: 4,
    },
  },
  source: [
    {
      id: SOURCE_TCoE,
      page: 165,
    },
  ],
}
