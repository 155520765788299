const {
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_PULSE_WAVE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_PULSE_WAVE,
  name: 'Волновой толчок',
  nameEn: 'Pulse Wave',
  description: `Вы создаёте мощное давление, выпуская его 30-футовым конусом и выбираете — оно отталкивает или притягивает существ и предметы. Все существа в пределах этого конуса должно пройти испытание Телосложения. При провале существо получает 6к6 урона силовым полем и половину урона при успехе. Все существа, провалившие испытание, отталкиваются или притягиваются на 15 футов (в зависимости от Вашего выбора).

Кроме того, незакреплённые объекты, которые целиком находятся в конусе, также отталкиваются или притягиваются на 15 футов.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, урон увеличивается на 1к6, а дистанция увеличивается на 5 футов за каждый круг ячейки выше третьего.`,
  lvl: 3,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  rangeComment: `30-футовый конус`,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  isDunamisBased: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 6,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_EGTW,
    page: 189,
  },
}
