const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GHOST,
  CREATURE_SPECTER,
  CREATURE_WRAITH,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_INCORPOREAL_MOVEMENT,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_SPELL} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Спектр`,
  nameEn: 'Specter',
  id: CREATURE_SPECTER,
  description: `Спектр это злой свободный дух человека, который был удержан на пути в загробную жизнь. Спектры не имеют представления о том, кем или чем они были раньше, и обречены вечно блуждать по миру. Некоторые из них появились при помощи тёмной магии или от касания [призрака](CREATURE:${CREATURE_WRAITH}), который вырвал душу из живого тела.

# Отсутствие искупления

Когда незавершённые дела [привидения](CREATURE:${CREATURE_GHOST}), наконец, решаются, оно обретает покой. Но этого не могут испытать спектры. Они обречены бродить по Материальному плану, и только уничтожение души может принести им забвение. До тех пор они одиноко существуют в заброшенных местах, неся своё бремя в течение многих веков.

# Неумолимая ненависть

Живые существа напоминают спектрам, что их жизнь никогда не сможет стать другой. Один лишь вид живого существа переполняет спектра горем и гневом, который утихает только после угасания жизни в существе. Он убивает быстро и безжалостно, так как только от быстрого лишения жизни спектр получает облегчение. Однако сколько бы целей спектр не убил — это никогда не потушит огонь ненависти внутри него.

# Обитатели тьмы

Солнечный свет — это источник жизни, который не может терпеть ни один спектр. Этот свет причиняет им боль. Когда наступает ночь, они покидают место отдыха в поисках живых существ для убийства, осознавая, что мало какое оружие может навредить им. После первых лучей рассвета они возвращаются в темноту, где остаются до наступления следующей ночи.

# Природа нежити

Спектру не нужен воздух, еда, питьё и сон.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 273,
  },
  armor: 12,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 50,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 11,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_UNCONSCIOUS,
    CONDITION_GRAPPLED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ALL_KNOWN_AT_LIFE,
      doNotSpeak: true,
    },
  ],
  cr: CR_1,
  featureList: [
    ABILITY_INCORPOREAL_MOVEMENT,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: `Вытягивание жизни`,
      description: `Цель должна пройти испытание Телосложения СЛ 10, иначе максимум её хитов уменьшится на количество, равное полученному урону. Это уменьшение длится до тех пор, пока существо не окончит длинный отдых. Цель умирает, если этот эффект уменьшает максимум её хитов до 0.`,
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 3,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
