const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_QUAGGOTH} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_QUAGGOTH,
  nameEn: 'Quaggoth',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'кваггот',
      genitive: 'кваггота',
      dative: 'квагготу',
      accusative: 'кваггота',
      instrumental: 'квагготом',
      prepositional: 'квагготе',
    },
    plural: {
      nominative: 'квагготы',
      genitive: 'квагготов',
      dative: 'квагготам',
      accusative: 'квагготов',
      instrumental: 'квагготами',
      prepositional: 'квагготах',
    },
  },
}
