const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  SPELL_GOODBERRY,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GOODBERRY,
  name: 'Чудо-ягоды',
  nameEn: 'Goodberry',
  description: `В Вашей руке появляются до десяти ягод, наполненных магией. Любое существо может действием съесть одну ягоду. Это восстанавливает 1 хит, и ягода настолько питательна, что насыщает существо на весь день.

Ягоды теряют силу, если их не съесть через 24 часа после создания.`,
  lvl: 1,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `ветка омелы`,
  source: [
    {
      id: SOURCE_PHB,
      page: 289,
    },
    {
      id: SOURCE_SRD,
      page: 150,
    },
  ],
}
