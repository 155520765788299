const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ARMS_OF_HADAR,
  SPELL_CHILL_TOUCH,
  SPELL_DARKNESS,
  SPELL_ELDRITCH_BLAST,
  SPELL_FEAR,
  SPELL_HEX,
  SPELL_HOLD_PERSON,
  SPELL_HUNGER_OF_HADAR,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_POISON_SPRAY,
  SPELL_PRESTIDIGITATION,
  SPELL_SUGGESTION,
  SPELL_WITCH_BOLT,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SHAPESHIFTER,
  CREATURE_TYPE_YUAN_TI,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  yuantiDescriptionList,
  yuantiNoteList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_YUAN_TI_MALISON_3,
  CREATURE_YUAN_TI_NIGHTMARE_SPEAKER,
} = require('./../../../../creatureIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_4} = require('./../../../../crList')
const {GEAR_SCIMITAR} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {GOD_DENDAR} = require('./../../../../godIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Юань-ти говорящая в кошмарах',
  nameEn: 'Yuan-ti Nightmare Speaker',
  id: CREATURE_YUAN_TI_NIGHTMARE_SPEAKER,
  description: [
    `Говорящие в кошмарах — жрицы юань-ти проклинатели, которые заключили пакт с [Дендар Ночной Змеёй](GOD:${GOD_DENDAR}), и кормят божество страхами и кошмарами своих жертв в обмен на силу в мире смертных. Жрицы получают кошмарные видения от [Дендар](GOD:${GOD_DENDAR}), интерпретируют их как пророчества, и используют свою магию и влияние для воплощения виденного в реальность.

Жесточайшие из юань-ти, шепчущие в кошмарах наслаждаются пытками заключенных и рабов, держа их в непрерывном страхе и ужасе. Они предпочитают ужасать, а не убивать противников. Они манипулируют сообществами гуманоидов для приобретения большего количества жертв и наслаждаются обществом нежити.

У [этого типа проклинателей](CREATURE:${CREATURE_YUAN_TI_MALISON_3}) человеческая голова и верхняя часть туловища с змеевидной нижней частью тела вместо ног.`,
    ...yuantiDescriptionList,
  ],
  note: yuantiNoteList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_YUAN_TI,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 205,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
      comment: 'прозревает магическую тьму',
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_4,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Перевёртыш',
      description: `Юань-ти может действием превратиться в Среднюю змею или обратно в свою истинную форму. Её характеристики одинаковы в обеих формах. Любое снаряжение, которое она носит или несёт, не превращается. Если она умирает, то остается в своей текущей форме.`,
    },
    {
      name: 'Клыки смерти',
      limit: {
        count: 2,
        period: 'день',
      },
      description: `В первый раз, когда юань-ти попадает рукопашной атакой в свой ход, она может дополнительно нанести 16 (3к10) некротического урона цели.`,
    },
  ],
  spellCast: [
    {
      comment: 'только в форме юань-ти',
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            {
              id: SPELL_ANIMAL_FRIENDSHIP,
              comment: 'только змеи',
            },
          ],
        },
        {
          limit: {
            count: 3,
            period: 'день',
          },
          list: [
            SPELL_SUGGESTION,
          ],
        },
      ],
    },
    {
      comment: 'только в форме юань-ти',
      spellCasterLevel: 13,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            {
              id: SPELL_ELDRITCH_BLAST,
              comment: `дистанция 300 фт., +3 к каждому броску урона`,
            },
            SPELL_CHILL_TOUCH,
            SPELL_MAGE_HAND,
            SPELL_MESSAGE,
            SPELL_POISON_SPRAY,
            SPELL_PRESTIDIGITATION,
          ],
        },
        {
          preText: `1–3 уровни (2 ячейки 3-го уровня)`,
          list: [
            SPELL_ARMS_OF_HADAR,
            SPELL_DARKNESS,
            SPELL_FEAR,
            SPELL_HEX,
            SPELL_HOLD_PERSON,
            SPELL_HUNGER_OF_HADAR,
            SPELL_WITCH_BOLT,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в форме юань-ти',
      description: `Юань-ти делает одну атаку _Удушением_ и одну атаку _Скимитаром_.`,
    },
    {
      name: 'Сжимание',
      description: `Цель схвачена (СЛ высвобождения 14) если это Большое существо или меньше. До окончания захвата цель обездвижена и юань-ти не может сжимать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_SCIMITAR,
      comment: 'только в форме юань-ти',
    },
    {
      name: 'Призыв кошмара',
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `Юань-ти заглядывает в кошмары существа, которое может видеть не далее чем в 60 футах от себя, и создает иллюзорное, неподвижное воплощение самых сокровенных страхов существа, видимое только для него. Цель должна пройти испытание Интеллекта СЛ 13. При провале цель получает 11 (2к10) психического урона и пугается воплощения, считая его реальным. Юань-ти должна концентрироваться для поддержания иллюзии (как при концентрации на заклинании), которая длится до 1 минуты и которой нельзя нанести урон. Цель может повторять испытание в конце каждого своего хода, оканчивая иллюзию при успехе или получая 11 (2к10) урона при провале.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
