const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_DEMON_LORD} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_DEMON_LORD,
  nameEn: 'Demon Lord',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  notRealRace: true,
  name: {
    singular: {
      nominative: 'лорд демонов',
      genitive: 'лорда демонов',
      dative: 'лорду демонов',
      accusative: 'лорда демонов',
      instrumental: 'лордом демонов',
      prepositional: 'лорде демонов',
    },
    plural: {
      nominative: 'лорды демонов',
      genitive: 'лордов демонов',
      dative: 'лордам демонов',
      accusative: 'лордов демонов',
      instrumental: 'лордами демонов',
      prepositional: 'лордах демонов',
    },
  },
}
