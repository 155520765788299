const {SOURCE_UA_2020_08_05_SUBCLASSES_PART_4} = require('./../../../sourceList')
const {SPELL_GUIDANCE} = require('./../../../spellIdList')
const {PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS_2020_08_05} = require('./../../../pcSubClassIdList')
const {
  FEATURE_GUIDING_WHISPERS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_GUIDING_WHISPERS,
    name: `Указующий шёпот`,
    nameEn: `Guiding Whispers`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS_2020_08_05,
    text: `You can reach out to spirits to guide you and others. You learn the [Указание](SPELL:${SPELL_GUIDANCE}) cantrip, which doesn’t count against the number of bard cantrips you know. For you, it has a range of 60 feet when you cast it.`,
    spellIdList: SPELL_GUIDANCE,
    source: {
      id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
      page: 1,
    },
  },
  // {
  //   id: FEATURE_,
  //   name: ``,
  //   nameEn: ``,
  //   lvl: ,
  //   pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS_2020_08_05,
  //   text: ``,
  //   spellIdPickByLvl: ,
  //   spellCircleLvl: ,
  //   spellIdList: SPELL_,
  //   source: {
  //     id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
  //     page: ,
  //   },
  // },
]
