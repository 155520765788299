const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  PC_RACE_DWARF,
} = require('./../../pcRaceIdList')
const {
  GOD_ANNAM,
  GOD_MEMNOR,
  GOD_SKORAEUS_STONEBONES,
  GOD_SURTUR,
  GOD_THRYM,
} = require('./../../godIdList')
const {
  CREATURE_BAT,
  CREATURE_CAVE_BEAR,
  CREATURE_GIANT_BAT,
  CREATURE_GIANT_FIRE_BEETLE,
  CREATURE_PURPLE_WORM,
  CREATURE_STONE_GIANT,
  CREATURE_STONE_GIANT_DREAMWALKER,
  CREATURE_TARRASQUE,
  CREATURE_XORN,
} = require('./../../creatureIdList')

const giantDescriptionList = require('./giant')

module.exports = [
  {
    header: 'Каменные великаны',
    text: `Каменные великаны — затворники, тихие и мирные, пока их не беспокоят. Их гранитно-серая кожа, измождённые черты лица и чёрные, запавшие глаза придают каменным великанам суровое выражение. Они любят уединение, скрывая свои жизни и искусство от мира.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Обитатели каменного мира',
    text: `Домом каменным великанам служат укромные пещеры. Пещерные сети — города, скальные туннели — дороги, а подземные потоки — водные пути. Горные цепи это их материки, с огромными просторами земли между ними, будто океаны, которые каменные великаны редко пересекают.

В своих тёмных, тихих пещерах каменные великаны безмолвно трудятся над искусной резьбой, измеряя время эхом воды, капающей в подземные пруды. В глубочайших комнатах поселений каменных великанов, далеко от шума [летучих мышей](CREATURE:${CREATURE_BAT}) и союзных великанам [пещерных медведей](CREATURE:${CREATURE_CAVE_BEAR}), находятся святые места, где тишина и тьма совершенны. Камень вбирает в себя самые святые качества в этих пещерных соборах, а их опоры и колонны покрыты такой красивой резьбой, что устыдят легендарных [дварфийских](PC_RACE:${PC_RACE_DWARF}) камнетёсов.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Резчики и пророки',
    text: `Среди каменных великанов ремесло считается величайшим достоянием. Они создают замысловатые фрески на пещерных стенах, раскрашивают эти фрески и занимаются другими видами искусства. Резьбу по камню они почитают величайшим навыком.

Каменные великаны стремятся к выявлению форм из необработанного камня, которые, как они верят, раскрывают смысл, вдохновлённый их богом Скореусом Камнекостным. Великаны назначают лучших резчиков своими лидерами, шаманами и пророками. Святые руки такого великана во время работы становятся руками бога.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Изящные атлеты',
    text: `Несмотря на большой размер и мускулатуру, каменные великаны гибки и изящны. Умелые метатели камней занимают высокое положение в уложении великанов, проверяя и показывая своё умение метать и ловить огромные валуны. Такие великаны занимают передовые ранги, когда племени надо защищать свой дом или атаковать врагов. Однако, даже в бою главное — мастерство. Метание булыжника каменным великаном — проявление не просто грубой силы, но ошеломляющего равновесия и атлетизма.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Мечтатели под открытым небом',
    text: `Каменные великаны воспринимают мир за пределами своих подземных жилищ как царство снов, где ничто по-настоящему не истинно, и не реально. На поверхности они ведут себя так, как гуманоиды могут вести себя в своих снах, не отдавая особого отчёта в своих действиях и никогда не доверяя полностью тому, что видят или слышат. Обещание, данное над землёй, не обязательно надо сдерживать. Оскорбление можно нанести без извинений. Убийство добычи или разумного существа не вызывает вины в мире грёз под открытым небом.

Некоторые великаны, не обладающие грацией атлетов или художественным мастерством, живут на задворках общества, служа племени охранниками границ или скитающимися охотниками. Когда нарушители проникают слишком глубоко на горную территорию клана каменных великанов, эти охранники встречают их летящими булыжниками и ливнем расколотого камня. Выжившие после такого рассказывают истории о жестокости каменных великанов, так и не поняв, как мало эти громилы, проживающие в мире грёз, походят на свой тихий артистичный род.`,
    source: {
      id: SOURCE_MM,
      page: 35,
    },
  },
  {
    header: 'Каменные великаны',
    text: `Каменные великаны — затворнические, задумчивые и непонятные — стараются оставаться в стороне от мира солнечного света и неба. Только окруженные камнем, они считают себя находящимися в реальном мире. Мир в окружении камня — мир постоянства и надежности, где целая жизнь может пройти, уходя в бесконечные века, в кропотливом вырезании. Мир поверхности, с его непостоянным освещением, бесконечным небом, меняющимися временами года и разрушающим камни ветром, представляется как сон, нереальность, где ничто не постоянно и потому не заслуживает внимания.`,
    source: {
      id: SOURCE_VGTM,
      page: 31,
    },
  },
  {
    header: 'Уложение художественности',
    text: `Среди каменных великанов владение искусством считается величайшим благом, а среди всех искусств резьба по камню имеет наивысшую ценность. Большинство каменных великанов проводят свою жизнь в бесконечной погоне за совершенным художественным творчеством. Молодые каменные великаны практикуют неустанно, в надежде доказать, что они достойны помогать лучшим резчикам племени. Каменный великан может посвятить годы тому, чтобы найти лучший камень перед началом большой работы. Лучшие резчики почитаются как лидеры и шаманы племени, и их руки воспринимаются как святые — в буквальном смысле становясь руками Скорауса Камнекоста во время работы.

Конечно, не все каменные великаны имеют руки бога. Те, кто не проявляют большого мастерства в резьбе, считаются жалкими, и на них смотрят со смесью жалости и презрения. Для определения положения в Уложении под величайшими мастерами, каменные великаны соревнуются в игре бросания и ловли валуна. Их навыки бросания камня хорошо служат каменным великанам, когда они защищают свои дома или атакуют врагов. Но даже когда дело касается метания камней, ключевым в нём является искусство. Швыряя булыжник, каменный великан не только демонстрирует физическую силу, но также стремится выказать атлетизм и изящество.

Те, кто не могут привнести артистизм в каждый аспект своей жизни, опускаются до самых низких ступеней Уложения, и часто буквально впечатываются в периметр общества, чтобы служить охранниками на самых дальних границах племени или охотниками, которые блуждают вне этих границ. Таким образом, каменные великаны, с которыми с первыми сталкиваются посторонние, — это почти всегда наименее успешные члены общества и худшие примеры идеала, к которому стремятся каменные великаны. Это неотёсанные громилы, изгнанные обществом художников и философов.

Для народа, который проводит свою жизнь в основном во тьме, у каменных великанов удивительное чувство восприятия эффектов света и тени. Они делают резьбу, которая отбрасывает тень особым образом, когда источник света помещен в нужное место. Без света и тени резьба будет неполной и не сможет быть воспринята в своей истинной форме. Например, сказочная резьба, сделанная с использованием этой особой техники, повествует одну историю, если смотреть на неё при неярком и равномерном освещении, но раскроет вторую, гораздо более глубокую историю, при добавлении правильного освещения.`,
    source: {
      id: SOURCE_VGTM,
      page: 31,
    },
  },
  {
    header: 'Скораус Камнекост, великий создатель',
    text: `[Каменные великаны](CREATURE:${CREATURE_STONE_GIANT}) поклоняются [Скораусу Камнекосту](GOD:${GOD_SKORAEUS_STONEBONES}) как Великому Создателю, второму по умениям после [Аннама](GOD:${GOD_ANNAM}), но старшим над другими божествами в отсутствие отца. Он появляется в искусстве каменных великанов двумя способами: как пара рук, одна держит резец, а другая — молоток, и как самая большая статуя или рельефная резьба [каменного великана](CREATURE:${CREATURE_STONE_GIANT}) в пещерах племени. Как правило, [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) изображен ростом вдвое выше любого другого [каменного великана](CREATURE:${CREATURE_STONE_GIANT}).

В легендах великанов [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) часто сидит в стороне от затей или битв его братьев и сестёр. Он является наблюдателем, доверенным лицом других богов и хранителем секретов, которые можно выведать у него только силой или обманом. В классической сказке [Мемнор](GOD:${GOD_MEMNOR}) пришел к [Скораусу](GOD:${GOD_SKORAEUS_STONEBONES}) и что-то прошептал ему на ухо. Когда [Суртур](GOD:${GOD_SURTUR}) потребовал узнать, что сказал [Мемнор](GOD:${GOD_MEMNOR}), [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) сказал своему брату именно то, что он услышал. [Суртур](GOD:${GOD_SURTUR}) размышлял над сообщением, которое вводило в заблуждение, будучи вырванным из контекста, и в конце концов отреагировал опрометчиво, но последствия его действий не были сочтены виной [Скорауса](GOD:${GOD_SKORAEUS_STONEBONES}). Если бы [Суртур](GOD:${GOD_SURTUR}) вместо этого попросил у [Скорауса](GOD:${GOD_SKORAEUS_STONEBONES}) совета по поводу слов [Мемнора](GOD:${GOD_MEMNOR}), в легенде всё закончилось бы по-другому.

[Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) считается самым осведомленным из великанских богов в вопросах магии, охранных заклинаниях и рунах, проклятиях, тайных сокровищах и тайнах земли. [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) открыл секрет выплавки [Суртуру]. [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) показал [Триму](GOD:${GOD_THRYM}) как вырезать руны на его старом оружии, чтобы наделить их магией, когда [Суртур](GOD:${GOD_SURTUR}) отказался сковать ему новое оружие. [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) изготовил копья для Хиатеи, чтобы она могла выполнить свои десять задач доблести. [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) постучал своим молотом по камню под водой, так что Стронмаус смог найти цепь тоннелей, по которой тот смог затянуть [тарраску](CREATURE:${CREATURE_TARRASQUE}) на дно океана, где Стронмаус смог её, наконец, утопить.`,
    source: {
      id: SOURCE_VGTM,
      page: 32,
    },
  },
  {
    header: 'Говорящие камни',
    text: `Несмотря на то, что они являются непревзойдёнными мастерами сказочной резьбы, каменные великаны также используют обычную письменность в своих каменных картинах. Имена, даты и описания появляются в их рассказах, часто как часть изображения (например, оружие или броня персонажа могут включать рунические буквы).

Каменные великаны также широко используют резное слово через «говорящие камни». Говорящий камень представляет собой вертикальный каменный цилиндр, в котором письмена вырезаны в нисходящей спирали. Когда цилиндр поворачивают в руках (действие невозможное для любого существа с размером и силой человека), или, когда он вращается на специальной подставке, сделанной таким образом, чтобы сбалансировать его в вертикальном положении, запись может быть прочитана по мере того как цилиндр вращается. Сообщение оборачивается вокруг столба, как резьба винта, но в двух чередующихся спиралях. Первая читается сверху вниз при вращении цилиндра; потом цилиндр должен быть перевернут, чтобы открыть вторую строку надписи, которая также должна быть прочитана сверху вниз.

Говорящие камни по размеру соответствуют длине сообщения, которое они несут, так что на камне не остается пустого места. Цилиндр, который оказывается слишком толстым или длинным, так что сообщение заканчивается прежде, чем будет использована вся поверхность камня, считается плохой работой. Традиция и честь требуют, чтобы такой камень был разбит в щебень, и был создан новый говорящий камень.`,
    source: {
      id: SOURCE_VGTM,
      page: 32,
    },
  },
  {
    header: 'Вежливые великаны?',
    text: `Новички, которые знают лишь о концентрации каменных великанов на артистизме, могут думать, что это мирные и разумные существа. Внутри своего собственного вида они имеют тенденцию и быть таковыми. Но гости, особенно невеликаны любого рода, являются нежелательными в пещерах каменных великанов, и с нарушителями не обходятся вежливо.

Первый признак того, что ты вторгся на территорию каменных великанов может быть валун, прилетевший, казалось бы, из ниоткуда и разлетевшийся на осколки у соседней скалы. Те, кто знает что-нибудь о каменных великанах понимают, что это был не промах; это было предупреждение, и следующий камень не пролетит так безобидно.

Но вполне возможно для путешественников вести переговоры с каменными великанами о безопасном проходе через их территорию, если кто-то в группе говорит на языке великанов и предлагает дань. Красивые и большие меха, экзотические блюда, или предметы искусства подойдут для дани; деньги — слабый соблазн для всех, кроме нижайших каменных великанов. Если предлагаются такие предметы, один или два великана могут выйти вперёд вести переговоры, пока остальные остаются на расстоянии броска валуна.

Для незнакомых глаз, каменные великаны, встречающиеся на периферии их территорий, выглядят и ведут себя весьма примитивно. Во-первых, личные украшения не имеют значения у каменных великанов с точки зрения Уложения, поэтому их одежда простая и практичная. Во-вторых, это наименее успешные члены клана. Они хороши в засадах и метании камней, но не являются лидерами или даже типичными представителями своего рода.

Даже если великаны принимают предложенную дань, как разрешение на въезд на их территорию, они могут потребовать более высокую цену, чтобы пройти через неё. Обычно такой «подарок» — это услуга какого-то рода — то, что великаны предпочли бы не делать сами, или что они не в состоянии выполнить, например, изгнание кобольдов из узкой пещеры или извлечение чего-то из глубины озера (каменные великаны — плохие пловцы, они вообще не захотят лезть в воду, если только не смогут легко ходить по дну).

Каменные великаны редко держат домашних животных. Они иногда выращивают колонии [гигантских летучих мышей](CREATURE:${CREATURE_GIANT_BAT}) на окраинах их территории, как для источника пропитания, так и как систему предупреждения против злоумышленников. Кроме того, они не против делить свои пещеры с [пещерными медведями](CREATURE:${CREATURE_CAVE_BEAR}), [огненными жуками](CREATURE:${CREATURE_GIANT_FIRE_BEETLE}) и другими животными, которые не причинят им никакого вреда. Остальных подземных соседей они держат на расстоянии. [Лиловые черви](CREATURE:${CREATURE_PURPLE_WORM}) являются их величайшим проклятием, потому что голодный червь жуёт всё, что попадается у него на пути, в том числе резьбу и скульптуры великанов. [Зорны](CREATURE:${CREATURE_XORN}) — среди немногих существ, которых ценят каменные великаны; их прохождение через землю не вызывает каких-либо повреждений, и их чужеродный образ мысли делает их интересными собеседниками.`,
    source: {
      id: SOURCE_VGTM,
      page: 32,
    },
  },
  {
    header: 'Жизнь во тьме',
    text: `Каменные великаны хорошо видят в темноте, и пещеры и гроты, где они живут, большую часть времени темны. Они предпочитают не использовать освещение для целей, не связанных с созданием или показом искусства.

Большую часть времени бодрствования великанов занимает выполнение ими своих обязанностей, будь то повседневная рутина или художественные занятия. Вождь племени или другой лидер, такой как шаман, определяют, когда охранники и охотники племени находятся при исполнении служебных обязанностей. Другие великаны подстраивают графики своего сна и бодрствования под стоящих выше в Уложении каменных великанов, у которых они учатся.

Мастера искусств могут требовать многого от учеников более низкого ранга, например, рано проснуться, чтобы быть уверенными, что у мастера готова пища к его пробуждению, или долго не ложиться, чтобы изготовить что-то, что мастеру понадобится, когда он проснется, или что он будет оценивать. По той или иной причине, около трёх четвертей членов племени бодрствуют в любой момент времени.

За пределами своих поселений каменные великаны путешествуют почти исключительно в темноте или — когда они осмеливаются посетить поверхностный мир — в ночное время, чтобы избежать ярких «снов и видений», которые они рискуют увидеть в дневное время суток. Каменный великан, который выходит на поверхность на длительное время или вынужден уйти из подземного мира, рискует потеряться в царстве снов, живя после этого в раздвоенной версии себя, называемой каменными великанами «[сноходцем](CREATURE:${CREATURE_STONE_GIANT_DREAMWALKER})».`,
    source: {
      id: SOURCE_VGTM,
      page: 33,
    },
  },
  {
    header: 'Линйенстейн (предки камня)',
    text: `Когда каменный великан приближается к концу своего невероятно долгого жизненного пути, он присоединяется к _Линйенстейн_ (предкам камня). Термин относится и к предкам великанов, и к помещению в каждом поселении каменных великанов, в котором они «живут».

Мёртвый (или иногда умирающий) каменный великан переносится в палату предков и облокачивается в вертикальном положении на край одного из рядов мёртвых великанов, уже находящихся там. Тело постепенно затвердевает в течение многих десятилетий, пока не станет неотличимо от огромного сталагмита.

Члены семьи часто посещают эту комнату-могилу, чтобы почтить своих предков. Некоторые из этих визитов, особенно пожилыми великанами, которые знают, что они в скором времени займут там своё место, длятся в течение нескольких недель или даже месяцев.`,
    source: {
      id: SOURCE_VGTM,
      page: 33,
    },
  },
  ...giantDescriptionList,
]
