const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ELEMENTAL_FIRE} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ELEMENTAL_FIRE,
  nameEn: 'Fire Elemental',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  hideFromFilter: true,
  name: {
    singular: {
      nominative: 'огненный элементаль',
      genitive: 'огненного элементаля',
      dative: 'огненному элементалю',
      accusative: 'огненного элементаля',
      instrumental: 'огненным элементалем',
      prepositional: 'огненном элементале',
    },
    plural: {
      nominative: 'огненные элементали',
      genitive: 'огненных элементалей',
      dative: 'огненным элементалям',
      accusative: 'огненных элементалей',
      instrumental: 'огненными элементалями',
      prepositional: 'огненных элементалях',
    },
  },
}
