const {SOURCE_TOA} = require('./../../sourceList')

module.exports = {
  header: 'Дварфы-альбиносы',
  text: `Дварфы-альбиносы Чульта были вынуждены покинуть свои подземные дома из-за вулканической активности, и те, кто не нашли убежища в Порту Нянзару, адаптировались к жизни в джунглях.

Они делают броню из шкур динозавров; оружие — из костей динозавров, кремня и дерева; их изделия обильно украшены костьми, перьями, клыками и каменными бусами. Дварфы-альбиносы не забыли, как обрабатывать металл, но им редко попадается этот материал.`,
  source: {
    id: SOURCE_TOA,
    page: 221,
  },
}
