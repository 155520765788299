const {
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_ENSNARING_STRIKE,
  SPELL_ENTANGLE,
  SPELL_ETHEREALNESS,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_PLANT_GROWTH,
  SPELL_RAY_OF_SICKNESS,
  SPELL_TELEPORT,
} = require('./../../../../spellIdList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_DEMON_LORD,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_GAS_SPORE,
  CREATURE_VIOLET_FUNGUS,
  CREATURE_ZUGGTMOY,
} = require('./../../../../creatureIdList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_23} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Заггтмой',
  nameAlt: [
    'Королева грибов',
    'Леди гниения и разложения',
  ],
  nameEn: 'Zuggtmoy',
  id: CREATURE_ZUGGTMOY,
  hasSpoilers: true,
  description: [
    `Демоническая Королева грибов. Леди гниения и разложения. Заггтмой — чужеродное существо, желающее лишь заразить живых спорами, превратить их в бездумных слуг и, со временем, в разлагающихся носителей грибов, плесени и другой гнили, которую она рождает.

Совершенно далекая от человечности Заггтмой способна изменять свою грибовидную форму в подобие гуманоида, в том числе похожую на скелет фигуру, одетую в наряды из мицелия и лишайников, изображаемую в гримуарах и на древних рисунках. Несомненно, сам внешний вид Заггтмой и её слуг является бездушной насмешкой над жизнью смертных и её многочисленных аспектов.

Культисты Заггтмой часто служат ей, сами того не осознавая. Большинство из них были заражены грибковыми инфекциями до какой-то степени, либо вдохнув её споры власти над разумом, либо преобразившись до той степени, когда плоть и грибы становятся одним целым. Такие культисты являются грибовидным продолжением воли Демонической Королевы. Их поклонение может начаться с безобидных, на первый взгляд, возможностей, даваемых различным спорам и грибам, но оно быстро поглощает их тела и души.`,
    {
      header: 'Логово Заггтмой',
      text: `Главное логово Заггтмой расположено в её дворце на слое Шедакла. Оно состоит из двух дюжин грибов бледно-жёлтого и тускло-коричневого цветов. Эти огромные грибы являются одними из самых больших из всех существующих. Они окружены полем кислотных дождевиков и ядовитыми испарениями. Эти грибы соединены между собой трутовиковыми мостами и содержат в своих эластичных и волокнистых ножках неисчислимые помещения.

# Действия логова

По очереди инициативы 20 (проигрыш при ничье), Заггтмой может совершить действие в логове, чтобы создать один из следующих эффектов; она не может использовать один и тот же эффект два раунда подряд:


* Заггтмой заставляет четырех [газовых спор](CREATURE:${CREATURE_GAS_SPORE}) или [фиолетовых грибов](CREATURE:${CREATURE_VIOLET_FUNGUS}) появиться в выбранном ей незанятом пространстве на территории логова. Они исчезают спустя 1 час. 
* До четырёх существ-растений, дружественных Заггтмой, которых она может видеть, могут потратить свои реакции для того, чтобы переместиться на свою скорость и совершить одну рукопашную атаку. 
* Заггтмой использует либо _Заразные споры_, либо _Споры власти над разумом_, с центром на каком-либо из грибов, находящихся в её логове, а не на себе.

# Местные эффекты

Область, в которой расположено логово Заггтмой, пронизана магией Демонической Королевы, создающей в ней один или более из следующих эффектов:

* Плесень и грибы растут на поверхностях в пределах 6 миль от логова, даже если в этом месте нет пригодной почвы. 
* Растительность в пределах 1 мили от логова заражена паразитическими грибами и медленно мутирует, поскольку полностью покрыта заразой. 
* Если гуманоид проводит по крайней мере 1 час на расстоянии 1 мили от логова, то он должен пройти испытание Мудрости СЛ 17 или станет жертвой безумия, определяемого по таблице _Безумие Заггтмой_. Существо, успешно прошедшее испытание, не может стать жертвой этого регионального эффекта в течение следующих 24 часов.

Если Заггтмой умирает, эти эффекты прекращаются в течение 1к10 дней.`,
      source: {
        id: SOURCE_MTOF,
        page: 149,
      },
    },
    {
      header: 'Безумие Заггтмой',
      text: `Если существо становится безумным в логове Заггтмой или в поле зрения лорда демонов, совершите бросок по таблице Безумие Заггтмой, чтобы определить природу безумия, которое будет слабостью персонажа и будет действовать до тех пор, пока не будет излечено.

| к100 | Слабость (длится пока не будет вылечена) |
|------|------------------------------------------|
| 1-20 | _«Вокруг себя я вижу образы, невидимые для остальных»_ |
| 21-40 | _«Я периодически впадаю в кататоническое состояние, подолгу смотря вдаль невидящим взглядом»_ |
| 41-60 | _«Я вижу изменённую версию реальности, и моё сознание убеждает себя в том, что она истинна, даже несмотря на то, что многочисленные доказательства утверждают обратное»_ |
| 61-80 | _«Моё сознание постепенно ускользает от меня, а интеллект притупляется»_ |
| 81-00 | _«Я постоянно чешусь от невидимых грибковых инфекций»_ |
`,
      source: {
        id: SOURCE_MTOF,
        page: 150,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_DEMON_LORD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 150,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 22,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 18,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 19,
    [PARAM_CHA]: 24,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_23,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
          SPELL_LOCATE_ANIMALS_OR_PLANTS,
          SPELL_RAY_OF_SICKNESS,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DISPEL_MAGIC,
          SPELL_ENSNARING_STRIKE,
          SPELL_ENTANGLE,
          SPELL_PLANT_GROWTH,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_ETHEREALNESS,
          SPELL_TELEPORT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Заггтмой совершает четыре атаки _Ложноножкой_.`,
    },
    {
      name: 'Ложноножка',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 10,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 6,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 2,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Заразные споры',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Заггтмой распространяет споры, образующие облако в форме сферы радиусом 20 футов, с центром на ней, и оно существует в течение 1 минуты. Любое существо из плоти и крови, находящиеся в облаке при его появлении, или входящие в него позднее, должны пройти испытание Телосложения СЛ 19. При успешном испытании существо не может быть заражено этими спорами в течение следующих 24 часов. При провале существо заражено болезнью, называемой «спорами Заггтмой» и, кроме всего прочего, получает случайную форму постоянного безумия (определяется броском по таблице Безумие Заггтмой), оканчивающегося только в том случае, если существо излечивается от болезни или умирает. Будучи зараженным таким образом, существо не может быть заражено повторно и должно повторять испытание в конце каждых суток, оканчивая действие инфекции при успехе. При провале, над телом существа постепенно обретают контроль растущие в нем грибы, и после трёх провалов испытания существо умирает, а затем оживает споровым слугой, если оно относится к тому типу существ, которые могут быть споровыми слугами.`,
    },
    {
      name: 'Споры власти над разумом',
      restore: 5,
      description: `Заггтмой распространяет облако спор, заполняющее область в форме сферы с радиусом 20 футов и центром на ней, которое будет существовать в течение 1 минуты. Гуманоиды и звери, оказывающиеся в облаке в момент его появления, или входящие в него позднее, должны пройти испытание Мудрости СЛ 19. При успешном испытании существо не сможет стать инфицированным этими спорами в течение следующих 24 часов. При провале существо будет заражено болезнью под названием «влияние Заггтмой» на 24 часа. Будучи зараженным существо очаровано ей и не может быть заражено этими спорами повторно.`,
    },
  ],
  reactionList: [
    {
      name: 'Раб-защитник',
      description: `Когда по Заггтмой было совершено попадание атакой, одно существо, находящееся на расстоянии 5 футов от Заггтмой, очарованное ей, должно использовать свою реакцию, чтобы принять эту атаку на себя вместо нее.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака',
      description: `Заггтмой совершает одну атаку _Ложноножкой_.`,
    },
    {
      name: 'Контроль воли',
      description: `Одно существо, очарованное Заггтмой, которое она может видеть, должно потратить реакцию для того, чтобы совершить перемещение со своей скоростью туда, куда она укажет, или чтобы совершить атаку по тому, по кому она укажет.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
