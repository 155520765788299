module.exports.PC_SUBCLASS_ARTIFICER_ALCHEMIST = 'artificer_alchemist'
module.exports.PC_SUBCLASS_ARTIFICER_ALCHEMIST_2017_01_09 = 'artificer_alchemist_2017_01_09'
module.exports.PC_SUBCLASS_ARTIFICER_ALCHEMIST_2019_05_14 = 'artificer_alchemist_2019_05_14'
module.exports.PC_SUBCLASS_ARTIFICER_ARCHIVIST_2019_05_14 = 'artificer_archivist_2019_05_14'
module.exports.PC_SUBCLASS_ARTIFICER_ARMORER = 'artificer_armorer'
module.exports.PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24 = 'artificer_armorer_2020_02_24'
module.exports.PC_SUBCLASS_ARTIFICER_ARTILLERIST = 'artificer_artillerist'
module.exports.PC_SUBCLASS_ARTIFICER_ARTILLERIST_2019_05_14 = 'artificer_artillerist_2019_05_14'
module.exports.PC_SUBCLASS_ARTIFICER_BATTLE_SMITH = 'artificer_battle_smith'
module.exports.PC_SUBCLASS_ARTIFICER_BATTLE_SMITH_2019_05_14 = 'artificer_battle_smith_2019_05_14'
module.exports.PC_SUBCLASS_ARTIFICER_GUNSMITH_2017_01_09 = 'artificer_gunsmith_2017_01_09'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ANCESTRAL_GUARDIAN = 'barbarian_path_of_the_ancestral_guardian'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ANCESTRAL_GUARDIAN_2017_05_01 = 'barbarian_path_of_the_ancestral_guardian_2017_05_01'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BATTLERAGER = 'barbarian_path_of_the_battlerager'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST = 'barbarian_path_of_the_beast'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST_2020_01_14 = 'barbarian_path_of_the_beast_2020_01_14'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BERSERKER = 'barbarian_path_of_the_berserker'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_DEPTHS = 'barbarian_path_of_the_depths'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_GIANT_2022_05_22 = 'barbarian_path_of_the_giant_2022_05_22'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_STORM_HERALD = 'barbarian_path_of_the_storm_herald'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_TOTEM_WARRIOR = 'barbarian_path_of_the_totem_warrior'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_WILD_SOUL_2019_08_15 = 'barbarian_path_of_the_wild_soul_2019_08_15'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ZEALOT = 'barbarian_path_of_the_zealot'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC = 'barbarian_path_of_wild_magic'
module.exports.PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC_TCoE_PC_BPoWM = 'barbarian_path_of_wild_magic_tcoe_pc_bpowm'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_CREATION = 'bard_college_of_creation'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_CREATION_2020_02_06 = 'bard_college_of_creation_2020_02_06'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE = 'bard_college_of_eloquence'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE_2019_09_18 = 'bard_college_of_eloquence_2019_09_18'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR = 'bard_college_of_glamour'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR_2016_11_14 = 'bard_college_of_glamour_2016_11_14'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_LORE = 'bard_college_of_lore'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_SATIRE_2016_01_04 = 'bard_college_of_satire_2016_01_04'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS = 'bard_college_of_spirits'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_SPIRITS_2020_08_05 = 'bard_college_of_spirits_2020_08_05'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS = 'bard_college_of_swords'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS_2016_01_04 = 'bard_college_of_swords_2016_01_04'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS_2017_05_01 = 'bard_college_of_swords_2017_05_01'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_VALOR = 'bard_college_of_valor'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS = 'bard_college_of_whispers'
module.exports.PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS_2016_11_14 = 'bard_college_of_whispers_2016_11_14'
module.exports.PC_SUBCLASS_CLERIC_ARCANA_DOMAIN = 'cleric_arcana_domain'
module.exports.PC_SUBCLASS_CLERIC_DEATH_DOMAIN = 'cleric_death_domain'
module.exports.PC_SUBCLASS_CLERIC_FATE_DOMAIN_2022_07_18 = 'cleric_fate_domain_2022_07_18'
module.exports.PC_SUBCLASS_CLERIC_FORGE_DOMAIN = 'cleric_forge_domain'
module.exports.PC_SUBCLASS_CLERIC_FORGE_DOMAIN_2016_11_21 = 'cleric_forge_domain_2016_11_21'
module.exports.PC_SUBCLASS_CLERIC_GRAVE_DOMAIN = 'cleric_grave_domain'
module.exports.PC_SUBCLASS_CLERIC_GRAVE_DOMAIN_2016_11_21 = 'cleric_grave_domain_2016_11_21'
module.exports.PC_SUBCLASS_CLERIC_KNOWLEDGE_DOMAIN = 'cleric_knowledge_domain'
module.exports.PC_SUBCLASS_CLERIC_LIFE_DOMAIN = 'cleric_life_domain'
module.exports.PC_SUBCLASS_CLERIC_LIGHT_DOMAIN = 'cleric_light_domain'
module.exports.PC_SUBCLASS_CLERIC_NATURE_DOMAIN = 'cleric_nature_domain'
module.exports.PC_SUBCLASS_CLERIC_ORDER_DOMAIN = 'cleric_order_domain'
module.exports.PC_SUBCLASS_CLERIC_ORDER_DOMAIN_2018_04_09 = 'cleric_order_domain_2018_04_09'
module.exports.PC_SUBCLASS_CLERIC_PEACE_DOMAIN = 'cleric_peace_domain'
module.exports.PC_SUBCLASS_CLERIC_PROTECTION_DOMAIN_2016_11_21 = 'cleric_protection_domain_2016_11_21'
module.exports.PC_SUBCLASS_CLERIC_TEMPEST_DOMAIN = 'cleric_tempest_domain'
module.exports.PC_SUBCLASS_CLERIC_TRICKERY_DOMAIN = 'cleric_trickery_domain'
module.exports.PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN = 'cleric_twilight_domain'
module.exports.PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN_2019_10_03 = 'cleric_twilight_domain_2019_10_03'
module.exports.PC_SUBCLASS_CLERIC_UNITY_DOMAIN_2020_02_06 = 'cleric_unity_domain_2020_02_06'
module.exports.PC_SUBCLASS_CLERIC_WAR_DOMAIN = 'cleric_war_domain'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS = 'druid_circle_of_dreams'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS_2016_11_28 = 'druid_circle_of_dreams_2016_11_28'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_SPORES = 'druid_circle_of_spores'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_STARS = 'druid_circle_of_stars'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_STARS_2020_02_24 = 'druid_circle_of_stars_2020_02_24'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND = 'druid_circle_of_the_land'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON = 'druid_circle_of_the_moon'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_THE_PRIMEVAL_2022_05_22 = 'druid_circle_of_the_primeval_2022_05_22'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD = 'druid_circle_of_the_shepherd'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD_2016_11_28 = 'druid_circle_of_the_shepherd_2016_11_28'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD_2017_06_05 = 'druid_circle_of_the_shepherd_2017_06_05'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_TWILIGHT_2016_11_28 = 'druid_circle_of_twilight_2016_11_28'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE = 'druid_circle_of_wildfire'
module.exports.PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE_2019_10_03 = 'druid_circle_of_wildfire_2019_10_03'
module.exports.PC_SUBCLASS_FIGHTER_ARCANE_ARCHER = 'fighter_arcane_archer'
module.exports.PC_SUBCLASS_FIGHTER_ARCANE_ARCHER_2016_12_05 = 'fighter_arcane_archer_2016_12_05'
module.exports.PC_SUBCLASS_FIGHTER_ARCANE_ARCHER_2017_05_01 = 'fighter_arcane_archer_2017_05_01'
module.exports.PC_SUBCLASS_FIGHTER_BATTLE_MASTER = 'fighter_battle_master'
module.exports.PC_SUBCLASS_FIGHTER_BRUTE_2018_01_08 = 'fighter_brute_2018_01_08'
module.exports.PC_SUBCLASS_FIGHTER_CAVALIER = 'fighter_cavalier'
module.exports.PC_SUBCLASS_FIGHTER_CAVALIER_2016_01_04 = 'fighter_cavalier_2016_01_04'
module.exports.PC_SUBCLASS_FIGHTER_CAVALIER_2017_06_05 = 'fighter_cavalier_2017_06_05'
module.exports.PC_SUBCLASS_FIGHTER_CHAMPION = 'fighter_champion'
module.exports.PC_SUBCLASS_FIGHTER_ECHO_KNIGHT = 'fighter_echo_knight'
module.exports.PC_SUBCLASS_FIGHTER_ELDRITCH_KNIGHT = 'fighter_eldritch_knight'
module.exports.PC_SUBCLASS_FIGHTER_KNIGHT_2016_12_05 = 'fighter_knight_2016_12_05'
module.exports.PC_SUBCLASS_FIGHTER_MONSTER_HUNTER_2016_04_04 = 'fighter_monster_hunter_2016_04_04'
module.exports.PC_SUBCLASS_FIGHTER_PSI_KNIGHT_2020_04_14 = 'fighter_psi_knight_2020_04_14'
module.exports.PC_SUBCLASS_FIGHTER_PSI_WARRIOR = 'fighter_psi_warrior'
module.exports.PC_SUBCLASS_FIGHTER_PSYCHIC_WARRIOR_2019_11_25 = 'fighter_psychic_warrior_2019_11_25'
module.exports.PC_SUBCLASS_FIGHTER_PURPLE_DRAGON_KNIGHT = 'fighter_purple_dragon_knight'
module.exports.PC_SUBCLASS_FIGHTER_RENEGADE = 'fighter_renegade'
module.exports.PC_SUBCLASS_FIGHTER_RUNE_KNIGHT = 'fighter_rune_knight'
module.exports.PC_SUBCLASS_FIGHTER_RUNE_KNIGHT_2019_11_25 = 'fighter_rune_knight_2019_11_25'
module.exports.PC_SUBCLASS_FIGHTER_SAMURAI = 'fighter_samurai'
module.exports.PC_SUBCLASS_FIGHTER_SAMURAI_2016_12_05 = 'fighter_samurai_2016_12_05'
module.exports.PC_SUBCLASS_FIGHTER_SCOUT_2016_01_04 = 'fighter_scout_2016_01_04'
module.exports.PC_SUBCLASS_FIGHTER_SHARPSHOOTER_2016_12_05 = 'fighter_sharpshooter_2016_12_05'
module.exports.PC_SUBCLASS_MAGE_OF_LOREHOLD_2021_06_08 = 'mage_of_lorehold_2021_06_08'
module.exports.PC_SUBCLASS_MAGE_OF_PRISMARI_2021_06_08 = 'mage_of_prismari_2021_06_08'
module.exports.PC_SUBCLASS_MAGE_OF_QUANDRIX_2021_06_08 = 'mage_of_quandrix_2021_06_08'
module.exports.PC_SUBCLASS_MAGE_OF_SILVERQUILL_2021_06_08 = 'mage_of_silverquill_2021_06_08'
module.exports.PC_SUBCLASS_MAGE_OF_WITHERBLOOM_2021_06_08 = 'mage_of_witherbloom_2021_06_08'
module.exports.PC_SUBCLASS_MONK_WAY_OF_ASCENDANT_DRAGON = 'monk_way_of_ascendant_dragon'
module.exports.PC_SUBCLASS_MONK_WAY_OF_ASCENDANT_DRAGON_2020_10_26 = 'monk_way_of_ascendant_dragon_2020_10_26'
module.exports.PC_SUBCLASS_MONK_WAY_OF_MERCY = 'monk_way_of_mercy'
module.exports.PC_SUBCLASS_MONK_WAY_OF_MERCY_2020_01_14 = 'monk_way_of_mercy_2020_01_14'
module.exports.PC_SUBCLASS_MONK_WAY_OF_SHADOW = 'monk_way_of_shadow'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF = 'monk_way_of_the_astral_self'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF_2019_08_15 = 'monk_way_of_the_astral_self_2019_08_15'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_DRUNKEN_MASTER = 'monk_way_of_the_drunken_master'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_DRUNKEN_MASTER_2017_03_27 = 'monk_way_of_the_drunken_master_2017_03_27'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_FOUR_ELEMENTS = 'monk_way_of_the_four_elements'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI = 'monk_way_of_the_kensei'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI_2016_12_12 = 'monk_way_of_the_kensei_2016_12_12'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI_2017_05_01 = 'monk_way_of_the_kensei_2017_05_01'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_LONG_DEATH = 'monk_way_of_the_long_death'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_OPEN_HAND = 'monk_way_of_the_open_hand'
module.exports.PC_SUBCLASS_MONK_WAY_OF_THE_SUN_SOUL = 'monk_way_of_the_sun_soul'
module.exports.PC_SUBCLASS_MONK_WAY_OF_TRANQUILITY_2016_12_12 = 'monk_way_of_tranquility_2016_12_12'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST = 'paladin_oath_of_conquest'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST_2016_12_19 = 'paladin_oath_of_conquest_2016_12_19'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST_2017_06_05 = 'paladin_oath_of_conquest_2017_06_05'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_DEVOTION = 'paladin_oath_of_devotion'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_GLORY = 'paladin_oath_of_glory'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_HEROISM_2019_09_18 = 'paladin_oath_of_heroism_2019_09_18'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_REDEMPTION = 'paladin_oath_of_redemption'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_REDEMPTION_2017_03_27 = 'paladin_oath_of_redemption_2017_03_27'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_THE_ANCIENTS = 'paladin_oath_of_the_ancients'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_THE_CROWN = 'paladin_oath_of_the_crown'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS = 'paladin_oath_of_the_watchers'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS_2020_01_14 = 'paladin_oath_of_the_watchers_2020_01_14'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19 = 'paladin_oath_of_treachery_2016_12_19'
module.exports.PC_SUBCLASS_PALADIN_OATH_OF_VENGEANCE = 'paladin_oath_of_vengeance'
module.exports.PC_SUBCLASS_PALADIN_OATHBREAKER = 'paladin_oathbreaker'
module.exports.PC_SUBCLASS_RANGER_BEAST_CONCLAVE_2016_09_12 = 'ranger_beast_conclave_2016_09_12'
module.exports.PC_SUBCLASS_RANGER_BEAST_MASTER = 'ranger_beast_master'
module.exports.PC_SUBCLASS_RANGER_DEEP_STALKER_2015_11_02 = 'ranger_gloom_stalker_2015_11_02'
module.exports.PC_SUBCLASS_RANGER_DEEP_STALKER_CONCLAVE_2016_09_12 = 'ranger_gloom_stalker_2016_09_12'
module.exports.PC_SUBCLASS_RANGER_FEY_WANDERER = 'ranger_fey_wanderer'
module.exports.PC_SUBCLASS_RANGER_FEY_WANDERER_2020_02_24 = 'ranger_fey_wanderer_2020_02_24'
module.exports.PC_SUBCLASS_RANGER_DRAKEWARDEN = 'ranger_drakewarden'
module.exports.PC_SUBCLASS_RANGER_DRAKEWARDEN_2020_10_26 = 'ranger_drakewarden_2020_10_26'
module.exports.PC_SUBCLASS_RANGER_GLOOM_STALKER = 'ranger_gloom_stalker'
module.exports.PC_SUBCLASS_RANGER_HORIZON_WALKER = 'ranger_horizon_walker'
module.exports.PC_SUBCLASS_RANGER_HORIZON_WALKER_2017_01_16 = 'ranger_horizon_walker_2017_01_16'
module.exports.PC_SUBCLASS_RANGER_HUNTER = 'ranger_hunter'
module.exports.PC_SUBCLASS_RANGER_HUNTER_CONCLAVE_2016_09_12 = 'ranger_hunter_conclave_2016_09_12'
module.exports.PC_SUBCLASS_RANGER_MONSTER_SLAYER = 'ranger_monster_slayer'
module.exports.PC_SUBCLASS_RANGER_MONSTER_SLAYER_2017_03_27 = 'ranger_monster_slayer_2017_03_27'
module.exports.PC_SUBCLASS_RANGER_PRIMEVAL_GUARDIAN_2017_01_16 = 'ranger_primeval_guardian_2017_01_16'
module.exports.PC_SUBCLASS_RANGER_SWARMKEEPER = 'ranger_swarmkeeper'
module.exports.PC_SUBCLASS_RANGER_SWARMKEEPER_2019_10_17 = 'ranger_swarmkeeper_2019_10_17'
module.exports.PC_SUBCLASS_ROGUE_ARCANE_TRICKSTER = 'rogue_arcane_trickster'
module.exports.PC_SUBCLASS_ROGUE_ASSASSIN = 'rogue_assassin'
module.exports.PC_SUBCLASS_ROGUE_INQUISITIVE = 'rogue_inquisitive'
module.exports.PC_SUBCLASS_ROGUE_INQUISITIVE_2016_04_04 = 'rogue_inquisitive_2016_04_04'
module.exports.PC_SUBCLASS_ROGUE_MASTERMIND = 'rogue_mastermind'
module.exports.PC_SUBCLASS_ROGUE_PHANTOM = 'rogue_phantom'
module.exports.PC_SUBCLASS_ROGUE_PHANTOM_2020_05_12 = 'rogue_phantom_2020_05_12'
module.exports.PC_SUBCLASS_ROGUE_REVIVED_2019_10_17 = 'rogue_revived_2019_10_17'
module.exports.PC_SUBCLASS_ROGUE_SCOUT = 'rogue_scout'
module.exports.PC_SUBCLASS_ROGUE_SCOUT_2017_01_16 = 'rogue_scout_2017_01_16'
module.exports.PC_SUBCLASS_ROGUE_SOULKNIFE = 'rogue_soulknife'
module.exports.PC_SUBCLASS_ROGUE_SOULKNIFE_2019_11_25 = 'rogue_soulknife_2019_11_25'
module.exports.PC_SUBCLASS_ROGUE_SOULKNIFE_2020_04_14 = 'rogue_soulknife_2020_04_14'
module.exports.PC_SUBCLASS_ROGUE_SWASHBUCKLER = 'rogue_swashbuckler'
module.exports.PC_SUBCLASS_ROGUE_THIEF = 'rogue_thief'
module.exports.PC_SUBCLASS_ROGUE_WILD_CARD = 'rogue_wild_card'
module.exports.PC_SUBCLASS_SORCERER_ABERRANT_MIND = 'sorcerer_aberrant_mind'
module.exports.PC_SUBCLASS_SORCERER_ABERRANT_MIND_2019_05_09 = 'sorcerer_aberrant_mind_2019_05_09'
module.exports.PC_SUBCLASS_SORCERER_CLOCKWORK_SOUL = 'sorcerer_clockwork_soul'
module.exports.PC_SUBCLASS_SORCERER_CLOCKWORK_SOUL_2020_02_06 = 'sorcerer_clockwork_soul_2020_02_06'
module.exports.PC_SUBCLASS_SORCERER_DIVINE_SOUL = 'sorcerer_divine_soul'
module.exports.PC_SUBCLASS_SORCERER_DRACONIC_BLOODLINE = 'sorcerer_draconic_bloodline'
module.exports.PC_SUBCLASS_SORCERER_FAVORED_SOUL_2017_02_06 = 'sorcerer_favored_soul_2017_02_06'
module.exports.PC_SUBCLASS_SORCERER_FAVORED_SOUL_2017_05_01 = 'sorcerer_favored_soul_2017_05_01'
module.exports.PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11 = 'sorcerer_giant_soul_2018_06_11'
module.exports.PC_SUBCLASS_SORCERER_LUNAR_MAGIC_2022_03_08 = 'sorcerer_lunar_magic_2022_03_08'
module.exports.PC_SUBCLASS_SORCERER_PHOENIX_SORCERY_2017_02_06 = 'sorcerer_phoenix_sorcery_2017_02_06'
module.exports.PC_SUBCLASS_SORCERER_PSIONIC_SOUL_2020_04_14 = 'sorcerer_psionic_soul_2020_04_14'
module.exports.PC_SUBCLASS_SORCERER_SEA_SORCERY_2017_02_06 = 'sorcerer_sea_sorcery_2017_02_06'
module.exports.PC_SUBCLASS_SORCERER_SHADOW_MAGIC = 'sorcerer_shadow_magic'
module.exports.PC_SUBCLASS_SORCERER_SHADOW_MAGIC_2015_11_02 = 'sorcerer_shadow_magic_2015_11_02'
module.exports.PC_SUBCLASS_SORCERER_STONE_SORCERY_2017_02_06 = 'sorcerer_stone_sorcery_2017_02_06'
module.exports.PC_SUBCLASS_SORCERER_STORM_SORCERY = 'sorcerer_storm_sorcery'
module.exports.PC_SUBCLASS_SORCERER_WILD_MAGIC = 'sorcerer_wild_magic'
module.exports.PC_SUBCLASS_WARLOCK_THE_ARCHFEY = 'warlock_the_archfey'
module.exports.PC_SUBCLASS_WARLOCK_THE_CELESTIAL = 'warlock_the_celestial'
module.exports.PC_SUBCLASS_WARLOCK_THE_CELESTIAL_2017_06_05 = 'warlock_the_celestial_2017_06_05'
module.exports.PC_SUBCLASS_WARLOCK_THE_FATHOMLESS = 'warlock_the_fathomless'
module.exports.PC_SUBCLASS_WARLOCK_THE_FIEND = 'warlock_the_fiend'
module.exports.PC_SUBCLASS_WARLOCK_THE_GENIE = 'warlock_the_genie'
module.exports.PC_SUBCLASS_WARLOCK_THE_GENIE_TCoE_PC_WtG = 'warlock_the_genie_tcoe_pc_wtg'
module.exports.PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE = 'warlock_the_great_old_one'
module.exports.PC_SUBCLASS_WARLOCK_THE_HEXBLADE = 'warlock_the_hexblade'
module.exports.PC_SUBCLASS_WARLOCK_THE_HEXBLADE_2017_02_13 = 'warlock_the_hexblade_2017_02_13'
module.exports.PC_SUBCLASS_WARLOCK_THE_LURKER_IN_THE_DEEP_2019_05_09 = 'warlock_the_lurker_in_the_deep_2019_05_09'
module.exports.PC_SUBCLASS_WARLOCK_THE_NOBLE_GENIE_2020_01_14 = 'warlock_the_noble_genie_2020_01_14'
module.exports.PC_SUBCLASS_WARLOCK_THE_RAVEN_QUEEN_2017_02_13 = 'warlock_the_raven_queen_2017_02_13'
module.exports.PC_SUBCLASS_WARLOCK_THE_SEEKER_2016_08_01 = 'warlock_the_seeker_2016_08_01'
module.exports.PC_SUBCLASS_WARLOCK_THE_UNDEAD = 'warlock_the_undead'
module.exports.PC_SUBCLASS_WARLOCK_THE_UNDEAD_2020_08_05 = 'warlock_the_undead_2020_08_05'
module.exports.PC_SUBCLASS_WARLOCK_THE_UNDYING = 'warlock_the_undying'
module.exports.PC_SUBCLASS_WARLOCK_THE_UNDYING_LIGHT_2015_11_02 = 'warlock_the_undying_light_2015_11_02'
module.exports.PC_SUBCLASS_WIZARD_BLADESINGING = 'wizard_bladesinging'
module.exports.PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC = 'wizard_chronurgy_magic'
module.exports.PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC = 'wizard_graviturgy_magic'
module.exports.PC_SUBCLASS_WIZARD_LORE_MASTERY_2017_02_13 = 'wizard_lore_mastery_2017_02_13'
module.exports.PC_SUBCLASS_WIZARD_ONOMANCY_2019_10_03 = 'wizard_onomancy_2019_10_03'
module.exports.PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES = 'wizard_order_of_scribes'
module.exports.PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES_2020_05_12 = 'wizard_order_of_scribes_2020_05_12'
module.exports.PC_SUBCLASS_WIZARD_PSIONICS_2019_11_25 = 'wizard_psionics_2019_11_25'
module.exports.PC_SUBCLASS_WIZARD_RUNECRAFTER_2022_05_22 = 'wizard_runecrafter_2022_05_22'
module.exports.PC_SUBCLASS_WIZARD_SCHOOL_OF_ABJURATION = 'wizard_school_of_abjuration'
module.exports.PC_SUBCLASS_WIZARD_SCHOOL_OF_CONJURATION = 'wizard_school_of_conjuration'
module.exports.PC_SUBCLASS_WIZARD_SCHOOL_OF_DIVINATION = 'wizard_school_of_divination'
module.exports.PC_SUBCLASS_WIZARD_SCHOOL_OF_ENCHANTMENT = 'wizard_school_of_enchantment'
module.exports.PC_SUBCLASS_WIZARD_SCHOOL_OF_EVOCATION = 'wizard_school_of_evocation'
module.exports.PC_SUBCLASS_WIZARD_SCHOOL_OF_ILLUSION = 'wizard_school_of_illusion'
module.exports.PC_SUBCLASS_WIZARD_SCHOOL_OF_NECROMANCY = 'wizard_school_of_necromancy'
module.exports.PC_SUBCLASS_WIZARD_SCHOOL_OF_TRANSMUTATION = 'wizard_school_of_transmutation'
module.exports.PC_SUBCLASS_WIZARD_THEURGY_2016_08_01 = 'wizard_theurgy_2016_08_01'
module.exports.PC_SUBCLASS_WIZARD_THEURGY_2017_03_20 = 'wizard_theurgy_2017_03_20'
module.exports.PC_SUBCLASS_WIZARD_WAR_MAGIC = 'wizard_school_of_war_magic'
module.exports.PC_SUBCLASS_WIZARD_WAR_MAGIC_2017_03_20 = 'wizard_war_magic_2017_03_20'
