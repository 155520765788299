const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  SOURCE_PHB,
} = require('./../../../sourceList')
const {
  CAT_EQUIPMENT,
} = require('./../../../gearCategoryList')
const {
  GEAR_ARROWS,
  GEAR_QUIVER,
} = require('./../../../gearIdList')

module.exports = {
  id: GEAR_QUIVER,
  genderId: GENDER_MALE,
  name: 'Колчан',
  nameByCase: {
    nominative: 'колчан',
    genitive: 'колчана',
    accusative: 'колчан',
    instrumental: 'колчаном',
  },
  nameEn: 'Quiver',
  description: `В колчан помещается 20 [стрел](GEAR:${GEAR_ARROWS}).`,
  cost: 100,
  weight: 1,
  source: {
    id: SOURCE_PHB,
    page: 150,
  },
  category: CAT_EQUIPMENT,
}
