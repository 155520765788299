const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_TASHA_S_OTHERWORLDLY_GUISE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TASHA_S_OTHERWORLDLY_GUISE,
  name: 'Ташин иномирный облик',
  nameAlt: 'Потусторонний облик Таши',
  nameEn: 'Tasha’s Otherworldly Guise',
  description: `Произнося волшебную формулу, Вы зачерпываете преобразовывающую Вас энергию Верхних или Нижних планов (на Ваш выбор).

Вы получаете следующие преимущества на время длительности заклинания

* Вы имунны к урону огнём и ядом (Нижние планы) или излучением и некротической энергией (Верхние планы).
* Вы имунны к Отравлению (Нижние планы) или Очарованию (Верхние планы).
* На Вашей спине появляются призрачные крылья, дающие Вам скорость полёта 40 футов.
* Вы получаете +2 к КД.
* Все Ваши атаки оружием — магические, и совершая атаку оружием, Вы можете использовать своб заклинательную характеристику вместо Силы или Ловкости для бросков атаки и урона.
* Совершая действие Атака на своём ходу, Вы можете атаковать дважды вместо одного раза. Вы игнорируете это преимущество, если у Вас уже есть подобная способность (например «Дополнительная атака»), позволяющая Вам атаковать действием Атака больше чем один раз на своём ходу.`,
  lvl: 6,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL, CAST_VERBAL],
  materialText: `предмет с вырезанным символом внешних планов, стоимостью не менее 500 золотых`,
  hasCost: true,
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  source: {
    id: SOURCE_TCoE,
    page: 166,
  },
}
