const {
  GEAR_LUTE,
  GEAR_LYRE,
} = require('./../../../../../gearIdList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_BARKSKIN,
  SPELL_CONFUSION,
  SPELL_CONTROL_WEATHER,
  SPELL_CURE_WOUNDS,
  SPELL_DISPEL_MAGIC,
  SPELL_ENTANGLE,
  SPELL_FAERIE_FIRE,
  SPELL_FIRE_STORM,
  SPELL_FOG_CLOUD,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_SHILLELAGH,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_STONE_SHAPE,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_THORNS,
  SPELL_WIND_WALL,
} = require('./../../../../../spellIdList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_ANSTRUTH_HARP,
  MAGIC_ITEM_CANAITH_MANDOLIN,
  MAGIC_ITEM_CLI_LYRE,
  MAGIC_ITEM_DOSS_LUTE,
  MAGIC_ITEM_FOCHLUCAN_BANDORE,
  MAGIC_ITEM_MAC_FUIRMIDH_CITTERN,
  MAGIC_ITEM_OLLAMH_HARP,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_ANSTRUTH_HARP,
    name: `Арфа Анструт`,
    nameEn: `Anstruth Harp`,
    rarity: MGC_RARITY_VERY_RARE,
    spellIdList: [
      SPELL_CONTROL_WEATHER,
      {
        id: SPELL_CURE_WOUNDS,
        comment: '5 уровень',
      },
      SPELL_WALL_OF_THORNS,
    ],
  },
  {
    id: MAGIC_ITEM_DOSS_LUTE,
    name: `Лютня Досс`,
    nameEn: `Doss Lute`,
    rarity: MGC_RARITY_UNCOMMON,
    gearType: GEAR_LUTE,
    spellIdList: [
      SPELL_ANIMAL_FRIENDSHIP,
      {
        id: SPELL_PROTECTION_FROM_ENERGY,
        comment: 'только огонь',
      },
      SPELL_PROTECTION_FROM_POISON,
    ],
  },
  {
    id: MAGIC_ITEM_CANAITH_MANDOLIN,
    name: `Мандолина Канаит `,
    nameEn: `Canaith Mandolin`,
    rarity: MGC_RARITY_RARE,
    spellIdList: [
      {
        id: SPELL_PROTECTION_FROM_ENERGY,
        comment: 'только электричество',
      },
      {
        id: SPELL_CURE_WOUNDS,
        comment: '3 уровень',
      },
      SPELL_DISPEL_MAGIC,
    ],
  },
  {
    id: MAGIC_ITEM_CLI_LYRE,
    name: `Лира Кли`,
    nameEn: `Cli Lyre`,
    rarity: MGC_RARITY_RARE,
    gearType: GEAR_LYRE,
    spellIdList: [
      SPELL_STONE_SHAPE,
      SPELL_WALL_OF_FIRE,
      SPELL_WIND_WALL,
    ],
  },
  {
    id: MAGIC_ITEM_OLLAMH_HARP,
    name: `Арфа Олламна`,
    nameAlt: `Арфа Оллава`,
    nameEn: `Ollamh Harp`,
    rarity: MGC_RARITY_LEGENDARY,
    spellIdList: [
      SPELL_CONFUSION,
      SPELL_CONTROL_WEATHER,
      SPELL_FIRE_STORM,
    ],
  },
  {
    id: MAGIC_ITEM_FOCHLUCAN_BANDORE,
    name: `Бандура Фоклучан`,
    nameEn: `Fochlucan Bandore`,
    rarity: MGC_RARITY_UNCOMMON,
    spellIdList: [
      SPELL_ENTANGLE,
      SPELL_FAERIE_FIRE,
      SPELL_SHILLELAGH,
      SPELL_SPEAK_WITH_ANIMALS,
    ],
  },
  {
    id: MAGIC_ITEM_MAC_FUIRMIDH_CITTERN,
    name: `Цитра Мак-Фуирмид`,
    nameEn: `Mac-Fuirmidh Cittern`,
    rarity: MGC_RARITY_UNCOMMON,
    spellIdList: [
      SPELL_BARKSKIN,
      SPELL_CURE_WOUNDS,
      SPELL_FOG_CLOUD,
    ],
  },
]
