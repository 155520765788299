const dragonsCommonNote = require('./dragons_common')

module.exports = [
  {
    text: `В некотором смысле зелёные драконы — самые «драконистые» из всех драконов. Жаль, что у Тиамат именно они стали столь известны`,
    author: `Физбан`,
  },
  {
    text: `Вижу древнего эльфийского короля, величие которого давным-давно померкло, сутулого и дремлющего на своём троне. Зелёный дракон нашёптывает в уши короля, совращая и искажая его сны. Имя этому дракону Циан Кровавая Погибель, и он собирается уничтожить нас всех`,
    author: `Пелиос из Эргота, провидец сильванести`,
  },
  ...dragonsCommonNote,
]
