const {SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES} = require('./../../../sourceList')
const {
  SPELL_COMMAND,
  SPELL_SANCTUARY,
} = require('./../../../spellIdList')
const {PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR_2016_11_14} = require('./../../../pcSubClassIdList')
const {
  FEATURE_MANTLE_OF_MAJESTY_2016_11_14,
  FEATURE_UNBREAKABLE_MAJESTY_2016_11_14,
} = require('./../../../featureIdList')

module.exports = [
  // {
  //   id: FEATURE_,
  //   name: ``,
  //   nameEn: ``,
  //   lvl: ,
  //   pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR_2016_11_14,
  //   text: ``,
  //   spellIdPickByLvl: ,
  //   spellCircleLvl: ,
  //   spellIdList: SPELL_,
  //   source: {
  //     id: SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES,
  //     page: ,
  //   },
  // },
  {
    id: FEATURE_MANTLE_OF_MAJESTY_2016_11_14,
    name: `Мантия величия`,
    nameEn: `Mantle of Majesty`,
    lvl: 6,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR_2016_11_14,
    text: `At 6th level, you gain the ability to cloak yourself in a fey magic that makes others want to serve you. As a bonus action, you take on an appearance of unearthly beauty for 1 minute. During this time, you can cast commandas a bonus action on each of your turns, without using a spell slot. This effect lasts for 1 minute, and any creature charmed by you automatically fails its saving throw against the spell.

Once you use this feature, you can’t use it again until you finish a long rest.`,
    spellIdList: SPELL_COMMAND,
    source: {
      id: SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES,
      page: 1,
    },
  },
  {
    id: FEATURE_UNBREAKABLE_MAJESTY_2016_11_14,
    name: `Несокрушимое величие`,
    nameEn: `Unbreakable Majesty`,
    lvl: 14,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR_2016_11_14,
    text: `At 14th level, you gain an otherworldly aspect to your appearance that makes you look more fierce and lovely.

In addition, through this feature, you can cast sanctuary on yourself. If a creature fails its saving throw against the spell, you also gain advantage on all Charisma checks against the creature for 1 minute, and it has disadvantage on any saving throw it makes against your spells on your next turn.

Once you cast sanctuary using this feature, you can’t do so again until you finish a short or long rest.`,
    spellIdList: SPELL_SANCTUARY,
    source: {
      id: SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES,
      page: 2,
    },
  },
]
