import React from 'react'
import classNames from 'classnames'
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons'

import Icon from '@/components/Icon'

import './LoadingStyles.less'

const DiceIcon = () => (
  <Icon
    icon={faDiceD20}
    className="Loading_icon"
  />
)

const LoadingComponent = (
  {
    children,
    isLoading = true,
    theme = 'medium',
    showIconAfter = false,
  }
) => isLoading
  ? (
    <span className={classNames('Loading', `Loading-${theme}`)}>
      {
        showIconAfter
          ? null
          : <DiceIcon/>
      }
      <span className="Loading_text">
        {children}
      </span>
      {
        showIconAfter
          ? <DiceIcon/>
          : null
      }
    </span>
  )
  : null

export default LoadingComponent
