const {SOURCE_GGTR} = require ('./../../sourceList')
const {GENDER_FEMALE, GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_VEDALKEN} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_VEDALKEN,
  nameEn: 'Vedalken',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'ведалкен',
        genitive: 'ведалкена',
        dative: 'ведалкену',
        accusative: 'ведалкена',
        instrumental: 'ведалкеном',
        prepositional: 'ведалкене',
      },
      [GENDER_FEMALE]: {
        nominative: 'ведалкенша',
        genitive: 'ведалкенши',
        dative: 'ведалкенше',
        accusative: 'ведалкенгшу',
        instrumental: 'ведалкеншей',
        prepositional: 'ведалкенше',
      },
    },
    plural: {
      nominative: 'ведалкены',
      genitive: 'ведалкенов',
      dative: 'ведалкенам',
      accusative: 'ведалкенов',
      instrumental: 'ведалкенами',
      prepositional: 'ведалкенах',
    },
  },
  description: [
    {
      header: 'Ведалкены',
      text: `Ничто не идеально. Ведалкены не только верят в этот факт, они этому рады. Каждое несовершенство — это шанс для улучшения, а прогресс — это бесконечный путь к совершенству, которого никогда не получится достичь. Эта точка зрения ведалкенов приводит к тому, что они выполняют свою работу с восторженным энтузиазмом, никогда не задерживаясь на неудачах и радуясь каждой возможности для улучшения.

Ведалкены высокие и тонкие, в среднем они почти на голову выше человека такого же веса. Их безволосая кожа бывает всех оттенков синего. Цвет глаз ведалкена включает тёмные оттенки синего или фиолетового, а носы у них широкие и плоские. Кроме того, ведалкены — наполовину амфибии.`,
      source: {
        id: SOURCE_GGTR,
        page: 22,
      },
    },
    {
      header: 'Холодная рациональность',
      text: `Несмотря на болтливость, ведалкены держат свою личную жизнь в тайне и чаще взаимодействуют с идеями, чем с собеседниками. Они устанавливают крепкие дружеские отношения на основе общих интересов или непреодолимых разногласиях. Социальные взаимодействия ведалкенов основываются на совместном размышлении о проблемах, а не на чувствах.

Для других рас ведалкены часто выглядят холодными, даже бесчувственными. Такое предположение несправедливо —  эмоции ведалкенов так же сильны, как и у всех остальных, просто они умеют их не показывать. Их действиями руководит холодная рациональность, они тщательно продумывают планы и следуют им. Ведалкены достаточно терпеливы чтобы совершенно ничего не делать, если именно бездействие будет нужно для приближения к идеальному результату.`,
      source: {
        id: SOURCE_GGTR,
        page: 22,
      },
    },
    {
      header: 'Стремление к идеалу',
      text: `Любознательный ум и рациональность ведалкенов подталкивают их ко вступлению в сенат Азориусов, объединение Симиков и (реже) лигу Иззетов. Вне зависимости от гильдии, они применяют свой интеллект для создания и улучшения вещей, будь то законы, процедуры или волшебная наука.

Ведалкены считают что путь к недостижимому совершенству выложен кирпичиками образования, тщательного обдумывание и управляемых экспериментов. Некоторые ведалкены стремятся улучшать самих себя, как с помощью биоинженерии Симиков так и путём старательного самообучения. Другие же фокусируются на улучшении всего общества через тщательную подготовку и принятие законов.`,
      source: {
        id: SOURCE_GGTR,
        page: 22,
      },
    },
    {
      header: 'Имена ведалкенов',
      text: `Ведалкены получает свои имена при рождении, но обычно они выбирают себе новые как перехода во взрослую жизнь. Они редко пользуются фамилиями. 

# Мужские имена

Аглар, Беллин, Дахид, Ёлов, Затаз, Кавин, Коплоний, Ломар, Матван, Модар, Небун, Ниллош, Нитт, Овлан, Огнелан, Отровак, Пеленер, Рилл, Триваз, Ульдин

# Женские имена

Ази, Барвиса, Бразия, Грия, Дирелла, Зловоля, Катрилль, Ковель, Лилла, Мирела, Морай, Недресс, Осся, Пьеренн, Роя, Сестри, Триэль, Узана, Файна, Халия, Ярагья`,
      source: {
        id: SOURCE_GGTR,
        page: 22,
      },
    },
  ],
}
