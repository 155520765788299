const {
  CREATURE_SHADOW_MASTIFF,
  CREATURE_SHADOW_MASTIFF_ALPHA,
} = require('./../../../../creatureIdList')
const {PARAM_INT} = require('./../../../../paramList')

module.exports = {
  name: 'Альфа теневой мастиф',
  nameEn: 'Shadow Mastiff Alpha',
  id: CREATURE_SHADOW_MASTIFF_ALPHA,
  parentId: CREATURE_SHADOW_MASTIFF,
  extendPropCollection: {
    hp: {
      diceCount: 9,
    },
    params: {
      [PARAM_INT]: 6,
    },
    actionList: [
      {
        name: 'Ужасающий вой',
        description: `★СУЩЕСТВО★ воет. Любой зверь или гуманоид в пределах 300 футов от ★него★, который может его слышать, должен пройти испытание Мудрости СЛ 11 или Испугаться на 1 минуту. Испуганная цель может повторять испытание в конце каждого своего хода, заканчивая эффект при успехе. Если испытание пройдено успешно или эффект на ней заканчивается, то цель получает иммунитет к _Ужасающему вою_ любого теневого мастифа на следующие 24 часа.`,
      },
    ],
  },
}
