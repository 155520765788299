const extendCreaturePropList = require('./extendCreaturePropList')

const extendablePropNameList = [
  'actionList',
  'featureList',
  'legendaryActionList',
  'reactionList',
]

module.exports = creature => extendablePropNameList.reduce(
  (resultedCreature, propName) => resultedCreature[propName]
    ? {
      ...resultedCreature,
      [propName]: extendCreaturePropList(creature[propName]),
    }
    : resultedCreature,
  creature
)
