const {PC_SUBCLASS_WIZARD_SCHOOL_OF_EVOCATION} = require('./../../../pcSubClassIdList')
const {
  SOURCE_PHB,
  SOURCE_ERRATA_PHB,
} = require('./../../../sourceList')
const {
  FEATURE_EMPOWERED_EVOCATION,
  FEATURE_EVOCATION_SAVANT,
  FEATURE_OVERCHANNEL,
  FEATURE_POTENT_CANTRIP,
  FEATURE_SCULPT_SPELLS,
  FEATURE_SPELLBOOK,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EVOCATION_SAVANT,
    name: `Мастер воплощения`,
    nameEn: `Evocation Savant`,
    lvl: 2,
    text: `Золото и время, которое Вы тратите на копирование заклинания Воплощения в свою [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), уменьшаются вдвое.`,
    source: {
      id: SOURCE_PHB,
      page: 116,
    },
  },
  {
    id: FEATURE_SCULPT_SPELLS,
    name: `Построение заклинаний`,
    nameEn: `Sculpt Spells`,
    lvl: 2,
    text: `Когда Вы накладываете разрушительное заклинание, то можете создать относительно безопасные участки внутри зоны поражения, которые оно не затрагивает.

При сотворении заклинания школы Воплощения, воздействующего на других существ, которых Вы видите, Вы можете выбрать количество существ, равное 1 + круг заклинания. Выбранные существа автоматически преуспевают в испытаниях от этого заклинания и не получают урона, если по описанию должны получить половину урона от заклинания при успешном спасении.`,
    source: {
      id: SOURCE_PHB,
      page: 116,
    },
  },
  {
    id: FEATURE_POTENT_CANTRIP,
    name: `Мощный заговор`,
    nameEn: `Potent Cantrip`,
    lvl: 6,
    text: `Ваши причиняющие урон заговоры воздействуют даже на тех существ, которые избегают основного эффекта.

Если существо преуспевает в испытании от Вашего заговора, оно получает половину урона от него (если он есть), но не получает дополнительных эффектов от него.`,
    source: {
      id: SOURCE_PHB,
      page: 116,
    },
  },
  {
    id: FEATURE_EMPOWERED_EVOCATION,
    name: `Усиленное воплощение`,
    nameEn: `Empowered Evocation`,
    lvl: 10,
    text: `Вы добавляете свой модификатор Интеллекта к одному броску урона всех сотворённых Вами заклинаний школы Воплощения.`,
    source: {
      id: SOURCE_PHB,
      page: 116,
    },
  },
  {
    id: FEATURE_OVERCHANNEL,
    name: `Перегрузка`,
    nameEn: `Overchannel`,
    lvl: 14,
    text: `Вы можете увеличить силу своих простейших заклинаний. Сотворяя причиняющее урон заклинание волшебника с 1 по 5 круг, Вы можете причинить им максимальный урон.

Без вреда для себя Вы можете использовать это умение один раз. Если Вы повторно используете это умение до окончания длинного отдыха, то получаете урон некротической энергией 2к12 за каждый круг заклинания сразу после его сотворения. Каждый следующий раз, при использовании данного умения до окончания длинного отдыха урон некротической энергией увеличивается на 1к12. Этот урон игнорирует сопротивление и иммунитет.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 116,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь явно ограничено снизу 1 кругом заклинаний',
        page: 2,
      },
    ],
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_SCHOOL_OF_EVOCATION,
  })
)
