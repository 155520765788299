const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_AURAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_INVISIBLE_STALKER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Невидимый охотник',
  nameEn: 'Invisible Stalker',
  id: CREATURE_INVISIBLE_STALKER,
  description: `Невидимый охотник — это воздушный элементаль, призванный со своего родного плана и превращённый мощной магией. Единственная его цель — охотиться за кем-то и добывать определённые предметы для своего призывателя. Если невидимый охотник повержен или магия, которая связывает его, рассеивается, невидимый охотник исчезает в порыве ветра.

**Целеустремлённый охотник.** После создания невидимый охотник остаётся при своём призывателе, пока не получит от него задание. Если такое задание не состоит в поиске и уничтожении конкретного существа или получении определённого предмета, то магия, связывающая невидимого охотника, рассеивается, и элементаль вновь обретает свободу. В противном случае он выполняет задание и возвращается к призывателю за следующим поручением, служа ему таким образом, пока связывающая его магия не рассеивается. Если во время выполнения задания призыватель погибает, то невидимый охотник исчезает после того, как выполнит это задание.

Невидимый охотник служит в лучшем случае неохотно, и ему отвратительны все получаемые поручения. Если задание занимает много времени, невидимый охотник может даже попытаться извратить полученное поручение, поэтому оно должно быть сформулировано очень аккуратно.

**Невидимая угроза.** Невидимые охотники состоят из воздуха, поэтому по своей природе невидимы. Его перемещения можно услышать или почувствовать, но элементаль остаётся невидимым даже когда атакует. Заклинания, позволяющие видеть невидимое, показывают только расплывчатые очертания охотника.

**Стихийная натура.** Невидимому охотнику не нужен воздух, еда, питьё и сон.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 220,
  },
  armor: 14,
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 50,
    [SPEED_FLY]: {
      value: 50,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_UNCONSCIOUS,
    CONDITION_GRAPPLED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AURAN,
    {
      id: LANG_COMMON,
      doNotSpeak: true,
    },
  ],
  cr: CR_6,
  featureList: [
    {
      name: 'Невидимость',
      description: `★СУЩЕСТВО★ невидим.`,
    },
    {
      name: 'Безупречный следопыт',
      description: `Призыватель назначает ★СУЩЕСТВО★ добычу. ★СУЩЕСТВО★ знает, в каком направлении находится добыча и расстояние до неё, если они находятся на одном плане существования. ★СУЩЕСТВО★ также знает местонахождение того, кто его призвал.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает два _Удара_.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
