const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  CREATURE_DRETCH,
  CREATURE_MANES,
} = require('./../../../../../creatureIdList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_SUMMON_LESSER_DEMONS} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_SUMMON_LESSER_DEMONS,
  name: 'Призыв низших демонов',
  nameEn: 'Summon Lesser Demons',
  description: `Вы произносите инфернальные слова, призывая демонов из хаоса Бездны. Совершите бросок по следующей таблице, чтобы определить, что именно появится.

| к6  | Призванные демоны                                     | 
|-----|-------------------------------------------------------|
| 1-2 | Два демона с показателем опасности 1 или меньше       |
| 3-4 | Четыре демона с показателем опасности 1/2 или меньше  |
| 5-6 | Восемь демонов с показателем опасности 1/4 или меньше |

Мастер выбирает демонов, таких как [мэйны](CREATURE:${CREATURE_MANES}) или [дретчи](CREATURE:${CREATURE_DRETCH}), а Вы выбираете видимые Вами свободные пространства в пределах дистанции, в которых они появятся. Призванный демон исчезает, когда его хиты опускаются до 0 или когда заклинание закончится.

Демоны враждебны ко всем существам, включая вас. Совершите проверку инициативы за призванных демонов как за одну группу, и они будут совершать свои собственные ходы. Демоны преследуют и атакуют ближайших не-демонов в меру своих способностей.

Как часть колдовства Вы можете начертить на земле круг кровью, используемой в качестве материального компонента. Круг должен быть достаточного размера, чтобы вместить вас. Пока заклинание длится, призванные демоны не могут пересечь круг или разрушить его, и они не могут атаковать никого в нём. Если материальный компонент использован подобным образом, он расходуется при окончании заклинания.`,
  highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку 6 или 7 уровня, Вы вызываете в два раза больше демонов. Если Вы сотворяете его с помощью ячейки заклинания 8 или 9 уровня, Вы вызываете в три раза больше демонов.`,
  lvl: 3,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `сосуд с кровью гуманоида, убитого за последние 24 часа`,
  duration: {timeId: TIME_HOUR, count: 1},
  needConcentration: true,
  source: {
    id: SOURCE_XGTE,
    page: 163,
  },
}
