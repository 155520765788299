const listToCollectionById = require('./../utils/listToCollectionById')

const VOLUME_UNIT_GALLON = 'gallon'
const VOLUME_UNIT_PINT = 'pint'
const VOLUME_UNIT_OUNCE = 'ounce'
const VOLUME_UNIT_FEET_CUBE = 'feet_cube'

const volumeUnitList = [
  {
    id: VOLUME_UNIT_GALLON,
    name: {
      singular: 'галлон',
      dual: 'галлона',
      plural: 'галлонов',
    },
    litreCoef: 3.785411784,
  },
  {
    id: VOLUME_UNIT_PINT,
    name: {
      singular: 'пинта',
      dual: 'пинты',
      plural: 'пинт',
    },
    litreCoef: 0.473176473,
  },
  {
    id: VOLUME_UNIT_OUNCE,
    name: {
      singular: 'унция',
      dual: 'унции',
      plural: 'унций',
    },
    litreCoef: 0.0296,
  },
  {
    id: VOLUME_UNIT_FEET_CUBE,
    name: {
      singular: 'фт³',
      dual: 'фт³',
      plural: 'фт³',
    },
    litreCoef: 28.3168,
  },
]

module.exports = volumeUnitList

module.exports.volumeUnitCollection = listToCollectionById(volumeUnitList)

module.exports.VOLUME_UNIT_FEET_CUBE = VOLUME_UNIT_FEET_CUBE
module.exports.VOLUME_UNIT_GALLON = VOLUME_UNIT_GALLON
module.exports.VOLUME_UNIT_PINT = VOLUME_UNIT_PINT
module.exports.VOLUME_UNIT_OUNCE = VOLUME_UNIT_OUNCE
