const {SOURCE_GGTR} = require ('./../../sourceList')
const {CREATURE_TYPE_MINOTAUR} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_MINOTAUR,
  nameEn: 'Minotaur',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'минотавр',
        genitive: 'минотавра',
        dative: 'минотавру',
        accusative: 'минотавра',
        instrumental: 'минотавром',
        prepositional: 'минотавре',
      },
      [GENDER_FEMALE]: {
        nominative: 'минотаврица',
        genitive: 'минотаврицы',
        dative: 'минотаврице',
        accusative: 'минотаврицу',
        instrumental: 'минотаврицей',
        prepositional: 'минотаврице',
      },
    },
    plural: {
      nominative: 'минотавры',
      genitive: 'минотавров',
      dative: 'минотаврам',
      accusative: 'минотавров',
      instrumental: 'минотаврами',
      prepositional: 'минотаврах',
    },
  },
  description: [
    {
      header: 'Минотавры',
      text: `Минотавры Равники сильны духом, упорством и отвагой. Поле боя — их родной дом, а биться они станут по множеству причин.

Минотавры сочетают бурлящую ярость в бою и острый тактический ум, делающий их блестящими командирами и ценными штурмовиками.`,
      source: {
        id: SOURCE_GGTR,
        page: 19,
      },
    },
    {
      header: 'Рога и копыта',
      text: `Минотавры — бочкообразные гуманоиды с быкоподобными головами. Их рога разнятся от однофутовых до огромных изогнутых боевых орудий не менее трёх футов длинной. Минотавры часто украшают свои рога металлическими кольцами или обшивают их металлом для защиты от повреждений.

С шей и могучих спин минотавров свисают гривы ломатого меха, а самцы дополнительно носят длинные пучки волос на щеках и подбородке. Ноги минотавров заканчиваются тяжёлыми раздвоенными копытами.

У минотавров с рождения длинные хвосты с кисточками, но минотаврам из клана Ордруун (и некоторых других) хвосты купируют как часть церемонии совершеннолетия; они считают что носить тяжёлые доспехи легиона Боросов намного удобнее, когда не мешает длинный хвост.`,
      source: {
        id: SOURCE_GGTR,
        page: 19,
      },
    },
    {
      header: 'Сила и упорны',
      text: `Минотавры упорны и любят битву. Они встречаются в кланах Груулов, но минотавры семейства Ордруун, давно ассоциирующиеся с легионом Боросов, более известны и уважаемы. Минотавры Боросов считают что дисциплина легиона лучше ярости стаи.

Минотавры обычно выражают гнев через жестокость, но это не значит что их легко разозлить. Минотавры страстные, они пылко любят друзей и любимых, и громко и долго смеются хорошим шуткам.`,
      source: {
        id: SOURCE_GGTR,
        page: 19,
      },
    },
    {
      header: 'Семья и гильдия',
      text: `Легенды минотавров описывают небольшой пантеон героев, которых они когда-то почитали как богов. Они определили место минотавров в мире. Каждый минотавр в Равнике считает себя произошедшим от одного из тех героев.

Линия Ордрууна — самая известная такая линия, с тысячами членов, произошедших от древнего героя, который, как говорят, научил минотавров искусству войны. Другие значимые линии включают линию Харранов (обычно ассоциируемую с кланом Груулов), линию Дрендаа (встречающихся среди кланов Груулов) и линию Тазгралов (разделённых среди Боросов, Груулов и Ракдосов).

Так как в каждой семейной линии так много членов, минотавры обычно не считают важным добавлять имя семьи к личному имени; например, хотя командующий Гроздан из крепости Камен легиона Боросов — известный член линии Ордрууна, он никогда не станет называть себя Гроздан Ордруун, как это принято в человеческом обществе.`,
      source: {
        id: SOURCE_GGTR,
        page: 19,
      },
    },
    {
      header: 'Имена минотавров',
      text: `Легенды, повествующие о деяниях древних героев-минотавров, полны и других имен: вассалов, союзников, любовников, слуг, врагов и других, которые сыграли роль, даже небольшую, в жизнях героев. Почти каждое имя минотавра взято из этого длинного списка второстепенных персонажей легенд, так что они никогда не будет забыт.

# Мужские имена

Аловнек, Брогмир, Брождар, Враслак, Гроздан, Дорник, Дракмир, Калазмир, Клаттик, Межислек, Нириков, Оражан, Прежлек, Радолак, Ругилар, Саровнек, Свараков, Тровик, Ярвем

# Женские имена

Акра, Больса, Веска, Врокя, Дракисла, Елеска, Зарка, Зока, Ирная, Йелка, Калка, Макла, Оакка, Перша, Райша, Сиса, Сокали, Такят, Энка, Яска`,
      source: {
        id: SOURCE_GGTR,
        page: 20,
      },
    },
  ],
}
