const {
    SOURCE_SKT,
  } = require('./../../../../sourceList'),
  {
    CR_4,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_YAKFOLK,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_DECEPTION,
    SKILL_MEDICINE,
    SKILL_SURVIVAL,
  } = require('./../../../../skillList'),
  {
    GEAR_HIDE_ARMOR,
    GEAR_QUARTERSTAFF,
  } = require('./../../../../gearIdList'),
  {
    PC_CLASS_CLERIC,
  } = require('./../../../../pcClassIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    SPELL_AUGURY,
    SPELL_BANE,
    SPELL_BANISHMENT,
    SPELL_BESTOW_CURSE,
    SPELL_COMMAND,
    SPELL_CURE_WOUNDS,
    SPELL_DISPEL_EVIL_AND_GOOD,
    SPELL_HOLD_PERSON,
    SPELL_LIGHT,
    SPELL_MENDING,
    SPELL_PROTECTION_FROM_ENERGY,
    SPELL_SACRED_FLAME,
    SPELL_SANCTUARY,
    SPELL_SENDING,
    SPELL_SPIRITUAL_WEAPON,
    SPELL_THAUMATURGY,
  } = require('./../../../../spellIdList'),
  {
    LANG_COMMON,
    LANG_YIKARIA,
  } = require('./../../../../languageIdList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    yakfolkDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_EARTH_ELEMENTAL,
    CREATURE_YAKFOLK_PRIEST,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Жрец яколюдов',
  nameEn: 'Yakfolk Priest',
  id: CREATURE_YAKFOLK_PRIEST,
  description: yakfolkDescriptionList,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_YAKFOLK,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_SKT,
    page: 249,
  },
  armor: {
    ac: 12,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 15,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_MEDICINE]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_YIKARIA,
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Одержимость',
      comment: 'восстанавливается после короткого или длинного отдыха',
      description: `★СУЩЕСТВО★ пытается магически овладеть гуманоидом или великаном. ★СУЩЕСТВО★ ★должен★ коснуться цели в течении короткого отдыха, или попытка провалится. В конце отдыха, цель должна пройти испытание Телосложения СЛ 12 или стать одержимой ★им★. ★СУЩЕСТВО★ исчезает со всем, что несёт и носит носит. Пока длится одержимость, цель находится без сознания, теряет контроль над телом и не воспринимает своё окружение. ★СУЩЕСТВО★ теперь контролирует тело и не может быть целью атак, заклинаний и других эффектов; при этом ★СУЩЕСТВО★ сохраняет свои мировоззрение, Интеллект, Мудрость, Харизму, и владения. В остальном используются параметры цели за исключением знаний, классовых способностей, черт и владений.

Одержимость длится до тех пор, пока хиты носителя не падают до 0, ★СУЩЕСТВО★ действием оканчивает эффект, или ★СУЩЕСТВО★ заставляют покинуть тело носителя с помощью эффекта такого заклинания, как например [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}). Когда одержимость заканчивается, ★СУЩЕСТВО★ появляется в свободном пространстве в пределах 5 футах от носителя, Ошеломляясь до конца своего следующего хода. Если носитель умирает, будучи одержимым, ★СУЩЕСТВО★ также умирает, и ★его★ тело не появляется.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 7,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_AUGURY,
      SPELL_BANE,
      SPELL_BANISHMENT,
      SPELL_BESTOW_CURSE,
      SPELL_COMMAND,
      SPELL_CURE_WOUNDS,
      SPELL_HOLD_PERSON,
      SPELL_LIGHT,
      SPELL_MENDING,
      SPELL_PROTECTION_FROM_ENERGY,
      SPELL_SACRED_FLAME,
      SPELL_SANCTUARY,
      SPELL_SENDING,
      SPELL_SPIRITUAL_WEAPON,
      SPELL_THAUMATURGY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_QUARTERSTAFF,
    },
    {
      name: 'Призыв земляного элементаля',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ призывает [земляного элементаля](CREATURE:${CREATURE_EARTH_ELEMENTAL}). Призванный [земляной элементаль](CREATURE:${CREATURE_EARTH_ELEMENTAL}) появляется в свободном пространстве в пределах 60 футов от ★СУЩЕСТВО★ и действует как ★его★ союзник. Элементаль существует 10 минут, либо до тех пор пока не умрет, или пока ★СУЩЕСТВО★ не отпустит его действием.`,
    },
  ],
}
