const {CAT_AMMUNITION} = require('./../../../../../gearCategoryList')
const {gearByCatCollection} = require('./../../../../../gearList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')

module.exports = [
  {
    rarity: MGC_RARITY_UNCOMMON,
    bonus: 1,
  },
  {
    rarity: MGC_RARITY_RARE,
    bonus: 2,
  },
  {
    rarity: MGC_RARITY_VERY_RARE,
    bonus: 3,
  },
]
  .reduce(
    (
      list,
      {
        rarity,
        bonus,
      }
    ) => [
      ...list,
      ...gearByCatCollection[CAT_AMMUNITION].map(
        gearId => ({
          id: `${gearId}_plus_${bonus}`,
          gearId,
          rarity,
          bonus,
        })
      )
    ],
    []
  )
