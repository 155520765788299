const {SOURCE_TOA} = require('./../../sourceList')

module.exports = [
  {
    header: 'Табакси',
    text: `Табакси — кошачий народ, родом с земли Мазки. Конечные скитальцы, Табакси редко остаются в одном месте надолго. Они любят собирать интересные артефакты, собирать рассказы и созерцать все чудеса мира. Это любопытство подталкивает их к тому, чтобы не оставлять не раскрытых тайн, и никакие ни сокровища или ни легенды не остались утерянными. Они почитают непостоянное божество, называемое Кошачьим Богом, которое, как говорят, странствует по миру.

Охотники табакси обучаются выживать в дикой природе, делая их отличными проводниками. Табакси-менестрели собираются в небольшие труппы и обустраивают свой дом в каком-нибудь посёлке, зарабатывая деньги музыкой и рассказыванием историй.`,
    source: {
      id: SOURCE_TOA,
      page: 239,
    },
  },
  {
    header: 'Имена',
    text: `Обычно у табакси одно имя, данное его или её кланом, и основанное на сложной формуле, включающей астрологию, прорицание, историю клана и другие факторы. Имена табакси могут относиться как к мужчинам, так и к женщинам, большинство использует прозвища, получаемые из полных имён или вдохновлённые ими. Названия кланов табакси обычно основаны на географической достопримечательности, расположенной на территории клана или рядом с ним.

Примеры имён табакси включают в себя «Гнездо яиц» («Яйца»), «Мёртвый лист» («Лист»), «Глаза оникса» («Оникс»), «Потерянное копье» («Потерянный») и «Дневная луна» («Луна»). Имена кланов включают «Отдалённый Дождь», «Сто Перьев», «Спящий Крик», «Яркие Скалы» и «Храпящая Гора».`,
    source: {
      id: SOURCE_TOA,
      page: 239,
    },
  },
]
