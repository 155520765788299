const {GEAR_ROD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROD_OF_SECURITY} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ROD} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ROD_OF_SECURITY,
  name: `Жезл безопасности`,
  nameEn: `Rod of Security`,
  type: MGC_TYPE_ROD,
  gearType: GEAR_ROD,
  rarity: MGC_RARITY_VERY_RARE,
  description: `Если Вы держите этот жезл, Вы можете действием активировать его. После этого жезл мгновенно телепортирует Вас и до 199 других согласных существ, которых Вы видите, в райское место между планами. Вы сами определяете вид этого райского места. Это может быть тихий сад, уютная лужайка, шумная таверна, огромный дворец, тропический остров, фантастический карнавал, или что Вы ещё придумаете. Вне зависимости от внешнего облика, там хватает воды и пищи для пропитания всех гостей. Всё остальное, с чем можно взаимодействовать в этом месте, существует только там. Например, цветок, сорванный в саду, исчезнет, если его вынести наружу.

За каждый час, проведённый в райском месте, посетитель восстанавливает хиты, как если бы он потратил 1 Кость Хитов. Кроме того, существа не стареют, находясь в раю, хотя время течёт как обычно. Посетители могут находиться в этом месте не больше 200 дней, разделённых на число присутствующих существ (округляя в меньшую сторону).

Когда отведённое время заканчивается или Вы действием оканчиваете эффект, все посетители возвращаются в места, которые они занимали до активации жезла, или в свободном пространстве, ближайшем к прошлому местонахождению. Жезл нельзя использовать повторно, пока не пройдёт 10 дней.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 160,
  },
}
