const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GOD_MORADIN,
} = require('./../../../../../godIdList')
const {
  GEAR_BATTLEAXE,
  GEAR_BREWERS_SUPPLIES,
  GEAR_MASONS_TOOLS,
  GEAR_SMITHS_TOOLS,
} = require('./../../../../../gearIdList')
const {
  SPELL_CONJURE_ELEMENTAL,
  SPELL_GREATER_RESTORATION,
  SPELL_REMOVE_CURSE,
  SPELL_TELEPORT,
} = require('./../../../../../spellIdList')
const {
  CREATURE_EARTH_ELEMENTAL,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_ARTIFACT,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_AXE_OF_THE_DWARVISH_LORDS,
  MAGIC_ITEM_BELT_OF_DWARVENKIND,
  MAGIC_ITEM_DWARVEN_THROWER,
  MAGIC_ITEM_SWORD_OF_SHARPNESS,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_AXE_OF_THE_DWARVISH_LORDS,
  name: `Топор владык дварфов`,
  nameEn: `Axe of the Dwarvish Lords`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_BATTLEAXE,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  description: [
    `Увидев проблемы, с которыми столкнулся его народ, молодой принц дварфов понял, что его народу нужно что-то, что его объединило бы. Так он выковал оружие, которое стало бы таким символом.

Уйдя глубоко в горы, глубже, чем когда-либо копали дварфы, он дошёл до пылающего сердца огромного вулкана. С помощью [Морадина](GOD:${GOD_MORADIN}), дварфийского бога творения, он создал четыре инструмента: _Жестокую кирку_, _Горн сердца земли_, _Наковальню песен_ и _Формирующий молот_. С их помощью он выковал _Топор владык дварфов_.

Вооружившись этим артефактом, принц вернулся к кланам дварфов и принёс им мир. Его топор покончил с взаимными обидами и презрением. Кланы стали союзниками, отбросили врагов, и настала эра благополучия. Этого дварфа помнят как Первого Короля. Когда он постарел, он передал это оружие, ставшее символом власти, своему наследнику. Его преемники передавали топор много поколений подряд.

Позже, в тёмную эру, ознаменовавшуюся предательствами и пороками, топор был утрачен в кровавой гражданской войне, которая как раз возникла из-за стремления заполучить топор вместе с силой и статусом, который он даёт. Прошло много веков, и дварфы всё ещё ищут топор, и многие искатели приключений гонялись за слухами и разоряли старые склепы в его поисках.`,
    {
      header: 'Магическое оружие',
      text: `_Топор владык дварфов_ это магическое оружие, дающее бонус +3 к броскам атаки и урона им. Этот топор также функционирует как [Пояс дварфов](MAGIC_ITEM:${MAGIC_ITEM_BELT_OF_DWARVENKIND}), [Дварфийский метатель](MAGIC_ITEM:${MAGIC_ITEM_DWARVEN_THROWER}) и [Меч остроты](MAGIC_ITEM:${MAGIC_ITEM_SWORD_OF_SHARPNESS}).`,
    },
    {
      header: 'Случайные свойства',
      text: `Этот топор обладает несколькими свойствами, определяемыми случайным образом:

* 2 малых положительных свойства
* 1 основное положительное свойство
* 2 малых отрицательных свойства`,
    },
    {
      header: 'Благословение Морадина',
      text: `Если Вы — дварф, настроенный на этот топор, Вы получаете следующие преимущества:

* Вы получаете иммунитет к урону ядом.
* Дистанция вашего тёмного зрения увеличивается на 60 футов.
* Вы получаете владение инструментами ремесленника, связанными с [кузнечным делом](GEAR:${GEAR_SMITHS_TOOLS}), [пивоварением](GEAR:${GEAR_BREWERS_SUPPLIES}) и [каменной кладкой](GEAR:${GEAR_MASONS_TOOLS}).`,
    },
    {
      header: 'Призыв земляного элементаля',
      text: `Если Вы держите этот топор, Вы можете действием наложить им заклинание [Призыв элементаля](SPELL:${SPELL_CONJURE_ELEMENTAL}), призывая [земляного элементаля](CREATURE:${CREATURE_EARTH_ELEMENTAL}). Вы не сможете повторно использовать это свойство до следующего рассвета.`,
    },
    {
      header: 'Путешествие в глубину',
      text: `Вы можете действием коснуться топором неподвижной части дварфийской каменной кладки и наложить топором заклинание [Телепортация](SPELL:${SPELL_TELEPORT}). Если место назначения находится под землёй, шансы промахнуться мимо или прибыть в другое место равны нулю. Вы не можете использовать это свойство ещё раз, пока не пройдёт 3 дня.`,
    },
    {
      header: 'Уничтожение Топора',
      text: `Топор можно уничтожить только расплавив его в _Горне сердца земли_, в котором он и был создан. Он должен оставаться в пылающем горне пятьдесят лет, после чего, наконец, расплавится и перестанет существовать.`,
    },
  ],
  curse: `Этот топор хранит проклятье, падающее на не-дварфов, настроенных на него. Даже если настройка окончится, проклятье останется. С каждым днём его внешность становится всё больше и больше похожей на облик дварфа. Через семь дней существо выглядит как обычный дварф, хотя и не теряет своих расовых особенностей и не приобретает расовых особенностей дварфа. Эти физические изменения не считаются магическими (и потому их нельзя рассеять), но их можно отменить эффектом, снимающим проклятья, например, заклинаниями [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) и [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}).`,
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_CONJURE_ELEMENTAL,
    SPELL_TELEPORT,
  ],
  source: {
    id: SOURCE_DMG,
    page: 227,
  },
}
