const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_ELEPHANT,
  CREATURE_MAMMOTH,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ABILITY_TRAMPLING_CHARGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_PRONE} = require('./../../../../conditionList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Мамонт',
  nameEn: 'Mammoth',
  id: CREATURE_MAMMOTH,
  description: `Мамонт это похожее на [слона](CREATURE:${CREATURE_ELEPHANT}) существо с толстым мехом и длинными бивнями. Более коренастые и свирепые чем обычные [слоны](CREATURE:${CREATURE_ELEPHANT}), мамонты обитают в самых разных климатических районах, от субарктики до субтропиков.`,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 334,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 21,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 6,
  },
  cr: CR_6,
  featureList: [
    {
      id: ABILITY_TRAMPLING_CHARGE,
      attackName_1: 'Боданием',
      attackName_2: 'Растаптыванием',
      strSave_DC: 18,
    },
  ],
  actionList: [
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Растаптывание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            condition: CONDITION_PRONE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 7,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
