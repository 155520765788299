module.exports = [
  require('./abishai_black'),
  require('./abishai_blue'),
  require('./abishai_green'),
  require('./abishai_red'),
  require('./abishai_white'),
  require('./abyssal_wretch'),
  require('./alkilith'),
  require('./allip'),
  require('./amnizu'),
  require('./armanite'),
  require('./astral_dreadnought'),
]
