const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_PLANT,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_PRONE,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    CREATURE_TRI_FLOWER_FROND,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Вайя трёхсоцветная',
  nameEn: 'Tri-flower Frond',
  id: CREATURE_TRI_FLOWER_FROND,
  description: `Когда полностью вырастает, вайя трехсоцветная достигает в высоту от 6 до 7 футов.

У вайи три ярких, трубчатых цветка, каждый размером с человеческую голову, и каждый из них отличается цветом: ярко-красный, ярко-оранжевый и ярко-жёлтый.

Каждый из цветков может навредить существу ужасающим образом, отличным друг от друга.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 215,
  },
  armor: 10,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 5,
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 12,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 1,
  },
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_BLINDED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  cr: CR_1_2,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ использует _Оранжевый цветок_, потом _Жёлтый цветок_ и затем _Красный цветок_.`,
    },
    {
      name: 'Оранжевый цветок',
      description: `★СУЩЕСТВО★ выбирает одно видимое ей в пределах 5 футов существо. Цель должна пройти испытание Телосложения СЛ 11 или получить отравление на 1 час. Пока существо отравлено таким образом, оно находится без сознания. В конце каждой минуты отравленная цель может повторить испытание, оканчивая эффект на себе при успехе.`,
    },
    {
      name: 'Жёлтый цветок',
      description: `★СУЩЕСТВО★ выбирает одно видимое ей в пределах 5 футов существо. Цель должна пройти испытание Ловкости СЛ 11 или её покроет едким соком, наносит по 5 урона кислотой в начале каждого хода цели. Если на цель вылить пинту или флягу с водой, то кислотный урон снижается на 1.`,
    },
    {
      name: 'Красный цветок',
      description: `Цель схвачена (СЛ высвобождения 11). Пока захват не окончен, цель получает в начале каждого своего хода 5 (2к4) урона ядом. ★СУЩЕСТВО★ может одновременно удерживать в захвате только одну цель. Другое существо, находящееся в пределах досягаемости ★СУЩЕСТВО★, может действием закончить захват на цели`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
        },
      },
    },
  ],
}
