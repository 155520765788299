const {
  LANG_TYPE_STANDARD,
} = require('./../../languageTypeList')
const {
  SOURCE_ADND2_EE,
  SOURCE_DND3_5_PHB,
} = require('./../../sourceList')
const {
  ALPHABET_DETHEK,
} = require('./../../alphabetList')
const {
  LANG_ORC,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_ORC,
  alphabetId: ALPHABET_DETHEK,
  type: LANG_TYPE_STANDARD,
  name: {
    nominative: 'Дарактан',
    genitive: 'Дарактана',
    instrumental: 'Дарактаном',
    prepositional: 'Дарактане',
  },
  name5eOfficial: {
    nominative: 'Орочий',
    genitive: 'Орочьего',
    instrumental: 'Орочий',
    prepositional: 'Орочьем',
  },
  nameAlt: 'Орочий',
  nameEn: 'Daraktan',
  nameEnAlt: 'Orc',
  dictionary: [
    {
      list: [
        [`группа орочьих шаманов`, `уларим`],
      ],
      source: {
        id: SOURCE_ADND2_EE,
        page: 168,
      },
    },
  ],
  description: [
    {
      text: `Орки, не имеющие своего собственного алфавита, используют шрифт Дварфов лишь в редких случаях чтобы написать что-то на орочьем. Очень часто письменность орков напоминает какие-то грубые надписи.`,
      source: {
        id: SOURCE_DND3_5_PHB,
        page: 15,
      },
    },
  ],
  typicalSpeakers: 'Орки',
  isRealLang: true,
  isReady: true,
}
