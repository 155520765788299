const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_SLAAD_BLUE,
  CREATURE_SLAAD_GREEN,
  CREATURE_SLAAD_RED,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_SLAAD,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_SLAAD,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_7} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {slaadDescription} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_WISH} = require('./../../../../spellIdList')

module.exports = {
  name: `Синий слаад`,
  nameEn: 'Blue Slaad',
  id: CREATURE_SLAAD_BLUE,
  description: [
    `Синие костяные наросты, выступающие из локтей синих слаадов, наносят гуманоидам страшные заражённые раны, от которых те превращаются в слаадов. Эта инфекция, называемая «вирусом хаоса», превращает цель во взрослого [красного слаада](CREATURE:${CREATURE_SLAAD_RED}) (или в [зелёного слаада](CREATURE:${CREATURE_SLAAD_GREEN}), если цель была заклинателем, способным сотворять заклинания 3 уровня и выше).`,
    slaadDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_SLAAD,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 266,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 18,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_SLAAD,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_7,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: `Регенерация`,
      description: `★СУЩЕСТВО★ восстанавливает 10 хитов в начале своего хода, если у ★него★ есть хотя бы 1 хит.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, и две _Когтями_.`,
    },
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: `Коготь`,
      description: `Если цель — гуманоид, она должна пройти испытание Телосложения СЛ 15, иначе заразится вирусом хаоса. Будучи больной, цель не может восстанавливать хиты, и максимум её хитов уменьшается на 10 (3к6) каждые 24 часа. Если болезнь уменьшает максимум хитов до 0, цель мгновенно превращается в [красного слаада](CREATURE:${CREATURE_SLAAD_RED}) или, если могла сотворять заклинания как минимум 3 уровня, в [зелёного слаада](CREATURE:${CREATURE_SLAAD_GREEN}). Только заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) может обратить это превращение.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
