const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_VEGEPYGMY,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_VEGEPYGMY,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Вегепигмейский',
    genitive: 'Вегепигмейского',
    instrumental: 'Вегепигмейским',
    prepositional: 'Вегепигмейском',
  },
  nameEn: 'Vegepygmy',
  isRealLang: true,
}
