module.exports = [
  require('./ochre_jelly'),
  require('./octopus'),
  require('./ogre'),
  require('./ogre_zombie'),
  require('./oni'),
  require('./orc'),
  require('./orc_eye_of_gruumsh'),
  require('./orc_war_chief'),
  require('./orog'),
  require('./otyugh'),
  require('./owl'),
  require('./owlbear'),
]
