const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_TLINCALLI,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_TLINCALLI,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык тлинкалли',
    genitive: 'Языка тлинкалли',
    instrumental: 'Языком тлинкалли',
    prepositional: 'Языке тлинкалли',
  },
  nameEn: 'Tlincalli',
  isReady: false,
  isRealLang: true,
}
