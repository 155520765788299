const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_KRUTHIK,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_KRUTHIK,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык крутиков',
    genitive: 'Языка крутиков',
    instrumental: 'Языком крутиков',
    prepositional: 'Языке крутиков',
  },
  nameEn: 'Kruthik',
  isReady: false,
  isRealLang: true,
}
