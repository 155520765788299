const {SOURCE_VGTM} = require('./../../sourceList')

module.exports = {
  header: 'Словарь великанских слов',
  text: `
* Белый — _квит_
* Битва — _слаг_
* Броня — _харбунад_
* Верх — _опп_
* Ветер — _винд_
* Вождь — _фёрер_
* Воин — _кригга_
* Враг — _увен_
* Вторженец — _убуден_
* Дварф — _дверг_
* Добрый/благочестивый/святой — _маат_
* Дом — _хайм_
* Злой/бесчестный/богопротивный — _мауг_
* Золото — _гил_
* Зубы — _теннер_
* Каменный великан — _стейнъётун_
* Корова — _куэ_
* Король — _кёнг_
* Красный — _род_
* Крепость — _фестинг_
* Мать — _хильд_
* Ледяной великан — _исаётун_
* Мясо — _кйотт_
* Облачный великан — _скьеётун_
* Огненный великан — _ильдъётун_
* Опасность — _фарэ_
* Племя — _стомм_
* Приветствие — _хельсинген (хельс)_
* Путешествие — _ферд_
* Свет — _стиг_
* Серебро — _сольв_
* Смерть — _дод_
* Стрела — _пит_
* Холмовой великан — _хаугъётун_
* Храбрость — _практ_
* Храм — _бапарт_
* Человек — _ван_
* Чёрный — _сорт_
* Честь — _ранг_
* Штормовой великан — _уваръётун_
* Щит — _скольд_
* Эльф — _альв_
`,
  columnCount: 2,
  source: {
    id: SOURCE_VGTM,
    page: 22,
  },
}
