const listToCollectionById = require ('./../utils/listToCollectionById')
const {
  GEAR_CHAIN_MAIL,
  GEAR_PLATE_ARMOR,
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_QUARTERSTAFF,
} = require('./gearIdList')

const MGC_TYPE_ARMOR = 'armor'
const MGC_TYPE_POTION = 'potion'
const MGC_TYPE_RING = 'ring'
const MGC_TYPE_ROD = 'rod'
const MGC_TYPE_SCROLL = 'scroll'
const MGC_TYPE_STAFF = 'staff'
const MGC_TYPE_TATTOO = 'tattoo'
const MGC_TYPE_WAND = 'wand'
const MGC_TYPE_WEAPON = 'weapon'
const MGC_TYPE_WONDROUS_ITEM = 'wondrous_item'

const magicItemTypeList = [
  {
    id: MGC_TYPE_WAND,
    name: 'Волшебная палочка',
    nameEn: 'Wand',
    description: `Волшебные палочки достигают в длину 15 дюймов и изготавливаются из металла, кости или дерева. Палочка может быть также украшена навершием из стекла, камня, или какого-либо другого материала.`,
  },
  {
    id: MGC_TYPE_ARMOR,
    name: 'Доспех',
    nameEn: 'Armor',
    description: `Если в описании доспехов не сказано иначе, то для использования их магических свойств они должны быть надеты.
Для некоторых комплектов магических доспехов может быть указан вид доспеха, например, «[кольчуга](GEAR:${GEAR_CHAIN_MAIL})» или «[латный доспех](GEAR:${GEAR_PLATE_ARMOR})». Если же особого указания по этому поводу нет, Вы можете определить вид доспеха случайным образом.`,
  },
  {
    id: MGC_TYPE_ROD,
    name: 'Жезл',
    nameEn: 'Rod',
    description: `Магические жезлы могут выглядеть как скипетры, другие же просто представляют собой тяжёлые цилиндры. Они, как правило, изготовлены из металла, дерева или кости; весят от двух до пяти фунтов, имеют длину 2–3 фута при толщине в один дюйм.`,
  },
  {
    id: MGC_TYPE_POTION,
    name: 'Зелье',
    nameEn: 'Potion',
    isConsumable: true,
    description: `Различные виды магических жидкостей сгруппированы в категорию зелий: это варева, сделанные из колдовских трав, вода из магических фонтанов или священных источников, и масла, которые наносятся на существо или предмет. Объём большинства зелий составляет примерно одну унцию.

Зелья являются расходуемыми магическими предметами. Употребление зелья или его применение на другого персонажа совершается действием. Применение масла может занять больше времени, и это указано в его описании. Использованное зелье действует немедленно и считается израсходованным.`,
  },
  {
    id: MGC_TYPE_RING,
    name: 'Кольцо',
    nameEn: 'Ring',
    description: `Магические кольца наделяют удивительной силой тех, кому повезло найти их. Если в описании кольца не сказано иначе, то для того, чтобы магия кольца работала, его необходимо носить на пальце.`,
  },
  {
    id: MGC_TYPE_WEAPON,
    name: 'Оружие',
    nameEn: 'Weapon',
    description: `Созданное для воплощения самых жестоких замыслов своего владельца или же напротив, выкованное служить высоким рыцарским идеалам, магическое оружие является желанным предметом для многих искателей приключений.

Для некоторых экземпляров магического оружия в описании может быть указан его вид, такой как «[длинный меч](GEAR:${GEAR_LONGSWORD})» или «[длинный лук](GEAR:${GEAR_LONGBOW})». Если же вид оружия не указан, Вы можете выбрать его самостоятельно или же определить случайным образом.

Если у магического оружия есть свойство «боеприпас», то выпущенные им боеприпасы считаются магическими в вопросе преодоления сопротивления и иммунитета к немагическим атакам и урону.`,
  },
  {
    id: MGC_TYPE_STAFF,
    name: 'Посох',
    nameEn: 'Staff',
    description: `Длина магического посоха 5–6 футов. Внешне посохи могут весьма отличаться друг от друга: одни гладкие и имеют практически равный диаметр по всей длине, другие — витые и корявые; одни сделаны из дерева, другие из полированного металла или кристалла. В зависимости от материала, посох может весить от 2 до 7 фунтов.

Если не сказано иного, то посох может использоваться, как оружие, являясь при этом «[боевым посохом](GEAR:${GEAR_QUARTERSTAFF})».`,
  },
  {
    id: MGC_TYPE_TATTOO,
    name: 'Татуировка',
    nameEn: 'Tattoo',
    description: `Магические татуировки — это смесь магии и искусства, сделанная чернилами и иглам. Такие татуировки наделяют носителей чудесными способностями. Татуировки создаются привязанными к магическим иглам, которыми можно передать магию существам.

После нанесения татуировки на тело, повреждения, раны и даже порча татуировки уже не могут ослабить её эффект. При нанесении магической татуировки на существо оно может выбрать её внешний вид. Татуировка может выглядетьт как клеймо, шрамирование, родинка, узор чешуи или любое другое изображение.

Чем выше редкость магической татуировки, тем больше места она занимает на коже существа.

# Размеры магических татуировок

| Редкость татуировки | Занимаемая область                      | 
|---------------------|-----------------------------------------| 
| Обычная             | Ладонь, ступня или четверть конечности  |
| Необычная           | Половина конечности или скальп          |
| Редкая              | Целая конечность                        |
| Очень редкая        | Две конечности или грудь или верх спины |
| Легендарная         | Две конечности и торс                   |
`,
  },
  {
    id: MGC_TYPE_SCROLL,
    name: 'Свиток',
    nameEn: 'Scroll',
    isConsumable: true,
    description: `Большинство свитков это заклинания, хранящиеся в письменной форме, но есть и те, что несут уникальные чары, которые предоставляют мощную охрану. Каким бы ни было его содержание, свиток представляет собой свёрток бумаги, иногда прикреплённый к деревянному стержню, и, как правило, хранящийся в тубе из слоновой кости, нефрита, кожи, дерева или металла.

Свиток является расходуемым магическим предметом. Независимо от характера магии, содержащейся в свитке, активация этой магии требует использования действия, чтобы прочитать свиток. После того, как магия, заключённая в свитке, высвобождается, свиток не может быть использован повторно. Слова исчезают, или он рассыпается в прах.

Любое существо, которое может понимать письмена, может прочитать и магические письмена свитка и попытаться активировать его, прочитав символы.`,
  },
  {
    id: MGC_TYPE_WONDROUS_ITEM ,
    name: 'Чудесный предмет',
    nameEn: 'Wondrous Item',
    description: `Чудесные предметы это довольно обширная категория магических предметов, которая включает в себя предметы одежды, такие как: сапоги, ремни, плащи, перчатки и различные ювелирные изделия и украшения: амулеты, броши и обручи. Сумки, ковры, хрустальные шары, разного рода фигурки, музыкальные инструменты и многие другие предметы также попадают в эту категорию.`,
  },
]
  .map(
    magicItemType => ({
      ...magicItemType,
      isConsumable: Boolean(magicItemType.isConsumable),
    })
  )

module.exports = magicItemTypeList
module.exports.magicItemTypeCollection = listToCollectionById(magicItemTypeList)

module.exports.MGC_TYPE_ARMOR = MGC_TYPE_ARMOR
module.exports.MGC_TYPE_POTION = MGC_TYPE_POTION
module.exports.MGC_TYPE_RING = MGC_TYPE_RING
module.exports.MGC_TYPE_ROD = MGC_TYPE_ROD
module.exports.MGC_TYPE_SCROLL = MGC_TYPE_SCROLL
module.exports.MGC_TYPE_STAFF = MGC_TYPE_STAFF
module.exports.MGC_TYPE_TATTOO = MGC_TYPE_TATTOO
module.exports.MGC_TYPE_WAND = MGC_TYPE_WAND
module.exports.MGC_TYPE_WEAPON = MGC_TYPE_WEAPON
module.exports.MGC_TYPE_WONDROUS_ITEM = MGC_TYPE_WONDROUS_ITEM
