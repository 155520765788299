const {CREATURE_TYPE_HOBGOBLIN} = require('./../../creatureTypeIdList')
const {
  hobgoblinDescriptionList,
  hobgoblinNote,
} = require('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_HOBGOBLIN,
  nameEn: 'Hobgoblin',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'хобгоблин',
        genitive: 'хобгоблина',
        dative: 'хобгоблину',
        accusative: 'хобгоблина',
        instrumental: 'хобгоблином',
        prepositional: 'хобгоблине',
      },
      [GENDER_FEMALE]: {
        nominative: 'хобгоблинша',
        genitive: 'хобгоблинши',
        dative: 'хобгоблинше',
        accusative: 'хобгоблиншу',
        instrumental: 'хобгоблиншей',
        prepositional: 'хобгоблинше',
      },
    },
    plural: {
      nominative: 'хобгоблины',
      genitive: 'хобгоблинов',
      dative: 'хобгоблинам',
      accusative: 'хобгоблинов',
      instrumental: 'хобгоблинами',
      prepositional: 'хобгоблинах',
    },
  },
  description: hobgoblinDescriptionList,
  note: hobgoblinNote,
}
