import React from 'react'

import ScriptPlacer from '@/components/ScriptPlacer'

const GoogleAnalytics = () => (
  <ScriptPlacer
    onlyProd
    scriptCode={`
      <div>
        <!-- Global site tag (gtag.js) - Google Analytics -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-158561793-1"></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-158561793-1');
        </script>
      </div>
    `}
  />
)

export default GoogleAnalytics
