const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_LIZARDFOLK,
  CREATURE_PTERAFOLK,
  CREATURE_PTERANODON,
} = require('./../../../../creatureIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GEAR_JAVELIN} = require('./../../../../gearIdList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_TOA} = require('./../../../../sourceList')

module.exports = {
  name: 'Птералюд',
  nameAlt: 'Страхолюды',
  nameEn: 'Pterafolk',
  nameEnAlt: 'Terror Folk',
  id: CREATURE_PTERAFOLK,
  description: {
    header: 'Птералюд',
    text: `Птералюд — это 10-футовые крылатые рептилоидные двуногие жители Чульта. Яростные изоляционисты, они рассматривают большинство других существ как своих врагов. В культуре чультов птералюдов часто называют _«страхолюды»_ за их привычку падать с неба, чтобы урвать все, до чего они могут дотянуться, а затем унести сокровища и еду обратно в свои норы.

Птералюды имеют размах крыльев 20 футов и рожают живых молодых. Древние ритуалы когда-то давали птералюдам способность принимать форму [птеранодонов](CREATURE:${CREATURE_PTERANODON}) и бескрылых [людоящеров](CREATURE:${CREATURE_LIZARDFOLK}), но эти ритуалы давно забыты.`,
    source: {
      id: SOURCE_TOA,
      page: 236,
    },
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_TOA,
    page: 236,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 50,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 12,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 11,
  },
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Ужасающее пикирование',
      description: `Если ★СУЩЕСТВО★ летает и пикирует как минимум на 30 футов по прямой к цели, а затем попадает по ней рукопашной атакой оружием, то цель Пугается до конца своего следующего хода.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну — _Укусом_ и две — _Когтями_. В качестве альтернативы, он совершает две рукопашные атаки _Пилумом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 4,
        range: {
          normal: 30,
          max: 120,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
