const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MAGIC_ITEM_POTION_OF_GIANT_STRENGTH} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_POTION} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {creatureCollection} = require('./../../../../../creatureList')
const {magicItemRarityCollection} = require('./../../../../../magicItemRarityList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

const tableText = list.reduce(
  (text, {giantId, str, rarity}) => {
    const {name} = creatureCollection[giantId]
    const {nameByGender} = magicItemRarityCollection[rarity]
    return `${text}
| [${name}](CREATURE:${giantId}) | ${str} | ${nameByGender[GENDER_MIDDLE]} |`
  },
  `
| Тип великана | Сила | Редкость |
|--------------|------|----------|`
)

module.exports = {
  id: MAGIC_ITEM_POTION_OF_GIANT_STRENGTH,
  name: `Зелье силы великана`,
  nameEn: `Potion of Giant Strength`,
  type: MGC_TYPE_POTION,
  rarity: generateRarityList(list),
  isConsumable: true,
  description: `В прозрачной жидкости этого зелья плавают обрезки ногтей великана соответствующего вида.

Когда Вы выпиваете это зелье, Ваше значение Силы изменяется на один час. Это новое значение зависит от того, силой какого именно великана Вы будете обладать (смотрите таблицу ниже). Зелье не оказывает на Вас никакого эффекта в том случае, если значение Вашей Силы уже равно этому значению или превосходит его.

${tableText}`,
  genderId: GENDER_MIDDLE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 166,
  },
}
