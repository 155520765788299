const {CREATURE_TYPE_GIANT_STORM} = require('./../../creatureTypeIdList')
const {stormGiantDescriptionList} = require('./../../textCommonParts')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GIANT_STORM,
  nameEn: 'Storm Giant',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'штормовой великан',
        genitive: 'штормового великана',
        dative: 'штормовому великану',
        accusative: 'штормового великана',
        instrumental: 'штормовым великаном',
        prepositional: 'штормовом великане',
      },
      [GENDER_FEMALE]: {
        nominative: 'штормовая великанша',
        genitive: 'штормовой великанши',
        dative: 'штормовой великанше',
        accusative: 'штормовую великаншу',
        instrumental: 'штормовой великаншей',
        prepositional: 'штормовой великанше',
      },
    },
    plural: {
      nominative: 'штормовые великаны',
      genitive: 'штормовых великанов',
      dative: 'штормовым великанам',
      accusative: 'штормовых великанов',
      instrumental: 'штормовыми великанами',
      prepositional: 'штормовых великанах',
    },
  },
  description: stormGiantDescriptionList,
}
