const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_6,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_GREATER_RESTORATION,
} = require('./../../../../spellIdList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  GEAR_HOLY_WATER,
} = require('./../../../../gearIdList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_SPIDER_CLIMB,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_CHASME,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Чазм',
  nameEn: 'Chasme',
  id: CREATURE_CHASME,
  description: [
    `Этот отвратительный демон выглядит как ужасающая помесь человека и мухи. Он перемешается на четырёх ногах, способных цепляться за стены и потолок. Прилёт чазма предвещает жужжание, вгоняющее жертву в ступор, не позволяющий защититься.

Слабые чазмы служат более могущественным хозяевам информаторами или надсмотрщиками. Они предпочитают в качестве наказаний пытки, и прекрасно выслеживают демонов-дезертиров, бежавших от своих владык. Ловля предателей даёт чазмам возможность пытать жертву, не опасаясь наказания.`,
    ...demonDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 95,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 10,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_6,
  featureList: [
    {
      name: 'Гул',
      description: `★СУЩЕСТВО★ издаёт ужасающий гудящий звук, к которому демоны обладают иммунитетом. Все остальные существа, начинающие ход в пределах 30 футов от ★него★, должны пройти испытание Телосложения СЛ 12, иначе они потеряют сознание на 10 минут. Существо, не слышащее гул, автоматически преуспевает в испытании. Эффект на существе оканчивается, если оно получает урон или другое существо действием обольёт его [святой водой](GEAR:${GEAR_HOLY_WATER}). Если испытание существа было успешным, или эффект на нём окончился, оно получает иммунитет к этому гулу на следующие 24 часа.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: 'Хоботок',
      description: `Максимум хитов цели уменьшается на количество полученного урона некротической энергией. Если этот эффект уменьшает максимум хитов существа до 0, это существо умирает. Максимум хитов снижен, пока существо не окончит длинный отдых, или пока существо не получит эффект заклинания, такого как [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}).`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 4,
            diceType: 6,
            diceBonus: 2,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 7,
            diceType: 6,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
