const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_HOLD_BREATH,
  ABILITY_KEEN_HEARING,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_KILLER_WHALE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Косатка',
  nameEn: 'Killer Whale',
  id: CREATURE_KILLER_WHALE,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 331,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_SWIM]: 60,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 13,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Эхолокация',
      description: `★СУЩЕСТВО★ не может использовать слепое зрение, будучи ★оглохшим★.`,
    },
    {
      id: ABILITY_HOLD_BREATH,
      time: '30 минут',
    },
    ABILITY_KEEN_HEARING,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 5,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
