const listToCollectionById = require('./../utils/listToCollectionById')
const {
  CREATURE_DRAGON_BLACK_ANCIENT,
  CREATURE_DRAGON_BLUE_ANCIENT,
  CREATURE_DRAGON_GREEN_ANCIENT,
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_DRAGON_WHITE_ANCIENT,
  CREATURE_BLACK_PUDDING,
  CREATURE_ICE_DEVIL,
  CREATURE_MIND_FLAYER,
  CREATURE_SOLAR,
} = require('./../constants/creatureIdList')

const DAMAGE_ACID = 'acid'
const DAMAGE_BLUDGEONING = 'bludgeoning'
const DAMAGE_COLD = 'cold'
const DAMAGE_ELECTRICITY = 'lightning'
const DAMAGE_FIRE = 'fire'
const DAMAGE_FORCE = 'force'
const DAMAGE_MAGIC_PIERCING_GOOD = 'magic_good'
const DAMAGE_MAGIC_WEAPON = 'magic_piercing_weapon'
const DAMAGE_NECROTIC = 'necrotic'
const DAMAGE_NONMAGIC = 'nonmagic'
const DAMAGE_NONMAGICAL_ATTACKS = 'nonmagical_attacks'
const DAMAGE_NONMAGIC_NONADAMANTINE_NONSILVER_ATTACKS = 'nonmagic_nonadamantine_nonsilver_attacks'
const DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON = 'nonmagic_nonadamantine_weapon'
const DAMAGE_NONMAGIC_NONSILVER_WEAPON = 'nonmagic_nonsilver_weapon'
const DAMAGE_SLASHING_NONMAGIC_NONSILVER_WEAPON = 'slashing_nonmagic_nonsilver_weapon'
const DAMAGE_NONMAGIC_WEAPON = 'nonmagic_weapon'
const DAMAGE_WEAPON_DARK = 'weapon_dark'
const DAMAGE_WEAPON_DARK_NONMAGIC = 'weapon_dark_nonmagic'
const DAMAGE_PIERCING = 'piercing'
const DAMAGE_POISON = 'poison'
const DAMAGE_PSYCHIC = 'psychic'
const DAMAGE_RADIANT = 'radiant'
const DAMAGE_SLASHING = 'slashing'
const DAMAGE_SPELLS = 'spells'
const DAMAGE_THUNDER = 'thunder'

const damageTypeList = [
  {
    id: DAMAGE_BLUDGEONING,
    name: 'Дробящий',
    nameEn: 'bludgeoning',
    fullName: 'Дробящий урон',
    nameByCase: {
      genitive: `дробящего урона`,
      dative: `дробящему урону`,
    },
    fullNameByCase: {
      genitive: `дробящего урона`,
      dative: `дробящему урону`,
    },
    description: `**Дробящий.** Тяжёлые силовые атаки — молотом, падением, сдавливанием и т. п. — причиняют дробящий урон.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_PIERCING,
    name: 'Колющий',
    nameEn: 'piercing',
    fullName: 'Колющий урон',
    nameByCase: {
      genitive: `колющего урона`,
      dative: `колющему урону`,
    },
    fullNameByCase: {
      genitive: `колющего урона`,
      dative: `колющему урону`,
    },
    description: `**Колющий.** Колющие и пронзающие атаки, включая удары копьём и укусы чудовищ, причиняют колющий урон.`,
    isRealDamageType: true,
  },
  { // Пример: Демилич
    id: DAMAGE_MAGIC_WEAPON,
    name: 'Дробящий, колющий и рубящий урон от магического оружия',
    nameEn: 'Bludgeoning, piercing, and slashing from magic weapons',
    fullName: 'Дробящий, колющий и рубящий урон от магического оружия',
    nameByCase: {
      genitive: `дробящего, колющего и рубящего урона от магического оружия`,
      dative: `дробящему, колющему и рубящему урону от магического оружия`,
    },
    fullNameByCase: {
      genitive: `дробящего, колющего и рубящего урона от магического оружия`,
      dative: `дробящему, колющему и рубящему урону от магического оружия`,
    },
    description: `Дробящий, колющий и рубящий урон от магического оружия`,
    isEnumeration: true,
  },
  { // Пример: Заклинание «Каменная кожа»
    id: DAMAGE_NONMAGIC,
    name: 'Немагический дробящий, колющий и рубящий урон',
    nameEn: `Nonmagical bludgeoning, piercing, and slashing damage`,
    fullName: 'Немагический дробящий, колющий и рубящий урон',
    nameByCase: {
      genitive: `немагического дробящего, колющего и рубящего урона`,
      dative: `немагическому дробящему, колющему и рубящему урону`,
    },
    fullNameByCase: {
      genitive: `немагического дробящего, колющего и рубящего урона`,
      dative: `немагическому дробящему, колющему и рубящему урону`,
    },
    description: `Немагический дробящий, колющий и рубящий урон`,
    isEnumeration: true,
  },
  { // Пример: Демилич
    id: DAMAGE_NONMAGIC_WEAPON,
    name: 'Дробящий, колющий и рубящий урон от немагического оружия',
    nameEn: `Bludgeoning, piercing, and slashing from nonmagical weapons`,
    fullName: 'Дробящий, колющий и рубящий урон от немагического оружия',
    nameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагического оружия`,
      dative: `дробящему, колющему и рубящему урону от немагического оружия`,
    },
    fullNameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагического оружия`,
      dative: `дробящему, колющему и рубящему урону от немагического оружия`,
    },
    description: `Дробящий, колющий и рубящий урон от немагического оружия`,
    isEnumeration: true,
  },
  { // Пример: Шлемоносный ужас
    id: DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
    name: 'Дробящий, колющий и рубящий урон от немагического оружия, которое при этом не изготовлено из адамантина',
    nameEn: 'Bludgeoning, piercing, and slashing from nonmagical weapons that aren’t adamantine',
    fullName: 'Дробящий, колющий и рубящий урон от немагического оружия, которое при этом не изготовлено из адамантина',
    nameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагического оружия, которое при этом не изготовлено из адамантина`,
      dative: `дробящему, колющему и рубящему урону от немагического оружия, которое при этом не изготовлено из адамантина`,
    },
    fullNameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагического оружия, которое при этом не изготовлено из адамантина`,
      dative: `дробящему, колющему и рубящему урону от немагического оружия, которое при этом не изготовлено из адамантина`,
    },
    description: `Дробящий, колющий и рубящий урон от немагического оружия, которое при этом не изготовлено из адамантина`,
    isEnumeration: true,
  },
  { // Пример: Голем из исчадий
    id: DAMAGE_NONMAGIC_NONADAMANTINE_NONSILVER_ATTACKS,
    name: 'Дробящий, колющий и рубящий урон от немагических атак, кроме адамантина или серебра',
    nameEn: 'bludgeoning, piercing, and slashing from nonmagical attacks that aren’t adamantine or silvered',
    fullName: 'Дробящий, колющий и рубящий урон от немагических атак, кроме адамантина или серебра',
    nameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагических атак, кроме адамантина или серебра`,
      dative: `дробящему, колющему и рубящему урону от немагических атак, кроме адамантина или серебра`,
    },
    fullNameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагических атак, кроме адамантина или серебра`,
      dative: `дробящему, колющему и рубящему урону от немагических атак, кроме адамантина или серебра`,
    },
    description: `Дробящий, колющий и рубящий урон от немагических атак, кроме адамантина или серебра`,
    isEnumeration: true,
  },
  { // Пример: Вертигр
    id: DAMAGE_NONMAGIC_NONSILVER_WEAPON,
    name: 'Дробящий, колющий и рубящий урон от немагического оружия, которое при этом не посеребрено',
    nameEn: 'Bludgeoning, piercing, and slashing from nonmagical weapons that aren’t silvered',
    fullName: 'Дробящий, колющий и рубящий урон от немагического оружия, которое при этом не посеребрено',
    nameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагического оружия, которое при этом не посеребрено`,
      dative: `дробящему, колющему и рубящему урону от немагического оружия, которое при этом не посеребрено`,
    },
    fullNameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагического оружия, которое при этом не посеребрено`,
      dative: `дробящему, колющему и рубящему урону от немагического оружия, которое при этом не посеребрено`,
    },
    description: `Дробящий, колющий и рубящий урон от немагического оружия, которое при этом не посеребрено`,
    isEnumeration: true,
  },
  { // Пример: Колдун Исчадия
    id: DAMAGE_SLASHING_NONMAGIC_NONSILVER_WEAPON,
    name: 'Рубящий урон от немагических атак, а также от немагического оружия, которое при этом не посеребрено',
    nameEn: `Slashing damage from nonmagical attacks not made with silvered weapons`,
    fullName: 'Рубящий урон от немагических атак, а также от немагического оружия, которое при этом не посеребрено',
    nameByCase: {
      genitive: `рубящего урона от немагических атак, а также от немагического оружия, которое при этом не посеребрено`,
      dative: `рубящему урону от немагических атак, а также от немагического оружия, которое при этом не посеребрено`,
    },
    fullNameByCase: {
      genitive: `рубящего урона от немагических атак, а также от немагического оружия, которое при этом не посеребрено`,
      dative: `рубящему урону от немагических атак, а также от немагического оружия, которое при этом не посеребрено`,
    },
    description: `Рубящий урон от немагических атак, а также от немагического оружия, которое при этом не посеребрено`,
    isEnumeration: true,
  },
  { // Пример: Кегобот
    id: DAMAGE_NONMAGICAL_ATTACKS,
    name: 'Дробящий, колющий и рубящий урон от немагических атак',
    nameEn: `Bludgeoning, piercing, and slashing damage from nonmagical attacks`,
    fullName: 'Дробящий, колющий и рубящий урон от немагических атак',
    nameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагических атак`,
      dative: `дробящему, колющему и рубящему урону от немагических атак`,
    },
    fullNameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагических атак`,
      dative: `дробящему, колющему и рубящему урону от немагических атак`,
    },
    description: `Дробящий, колющий и рубящий урон от немагических атак`,
    isEnumeration: true,
  },
  { // Пример: Ракшас
    id: DAMAGE_MAGIC_PIERCING_GOOD,
    name: 'Колющий от магического оружия, используемого добрыми существами',
    nameEn: `Piercing from magic weapons wielded by good creatures`,
    fullName: 'Колющий от магического оружия, используемого добрыми существами',
    nameByCase: {
      genitive: `колющего урона от магического оружия, используемого добрыми существами`,
      dative: `колющему урону от магического оружия, используемого добрыми существами`,
    },
    fullNameByCase: {
      genitive: `колющего урона от магического оружия, используемого добрыми существами`,
      dative: `колющему урону от магического оружия, используемого добрыми существами`,
    },
    description: 'Колющий от магического оружия, используемого добрыми существами',
    isEnumeration: true,
  },
  {
    id: DAMAGE_ACID,
    name: 'Кислотой',
    nameEn: 'acid',
    fullName: 'Урон кислотой',
    nameByCase: {
      genitive: `кислоты`,
      dative: `кислоте`,
    },
    fullNameByCase: {
      genitive: `урона кислотой`,
      dative: `урону кислотой`,
    },
    description: `**Кислота.** Едкое дыхание [чёрного дракона](CREATURE:${CREATURE_DRAGON_BLACK_ANCIENT}) и растворяющая слизь [чёрного пудинга](CREATURE:${CREATURE_BLACK_PUDDING}) причиняют урон кислотой.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_SLASHING,
    name: 'Рубящий',
    nameEn: 'slashing',
    fullName: 'Рубящий урон',
    nameByCase: {
      genitive: `рубящего урона`,
      dative: `рубящему урону`,
    },
    fullNameByCase: {
      genitive: `рубящего урона`,
      dative: `рубящему урону`,
    },
    description: `**Рубящий.** Мечи, топоры и когти чудовищ причиняют рубящий урон.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_FIRE,
    name: 'Огнём',
    nameEn: 'fire',
    fullName: 'Урон огнём',
    nameByCase: {
      genitive: `огня`,
      dative: `огню`,
    },
    fullNameByCase: {
      genitive: `урона огнём`,
      dative: `урону огнём`,
    },
    description: `**Огонь.** [Красный дракон](CREATURE:${CREATURE_DRAGON_RED_ANCIENT}), выдыхающий пламя, и многие заклинания, создающие жар, причиняют урон огнём.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_COLD,
    name: 'Холодом',
    nameEn: 'cold',
    fullName: 'Урон холодом',
    nameByCase: {
      genitive: `холода`,
      dative: `холоду`,
    },
    fullNameByCase: {
      genitive: `урона холодом`,
      dative: `урону холодом`,
    },
    description: `**Холод.** Лютый холод от копья [ледяного дьявола](CREATURE:${CREATURE_ICE_DEVIL}) и морозное дыхание [белого дракона](CREATURE:${CREATURE_DRAGON_WHITE_ANCIENT}) причиняют урон холодом.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_FORCE,
    name: 'Силовым полем',
    nameEn: 'force',
    fullName: 'Урон силовым полем',
    nameByCase: {
      genitive: `силового поля`,
      dative: `силовому полю`,
    },
    fullNameByCase: {
      genitive: `урона силовым полем`,
      dative: `урону силовым полем`,
    },
    description: `**Силовое **поле. Силовое поле это чистая магия, сфокусированная в разрушительную силу. Чаще всего силовым полем причиняют урон заклинания, такие как _Волшебная стрела_ и _Божественное оружие_.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_NECROTIC,
    name: 'Некротический',
    nameEn: 'necrotic',
    fullName: 'Некротический урон',
    nameByCase: {
      genitive: `некротического урона`,
      dative: `некротическому урону`,
    },
    fullNameByCase: {
      genitive: `некротического урона`,
      dative: `некротическому урону`,
    },
    description: `**Некротическая энергия.** Некротическая энергия, излучаемая некоторой нежитью и такими заклинаниями как _Леденящее прикосновение_, иссушают плоть и даже душу.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_ELECTRICITY,
    name: 'Электричеством',
    nameEn: 'electricity',
    fullName: 'Урон электричеством',
    nameByCase: {
      genitive: `электричества`,
      dative: `электричеству`,
    },
    fullNameByCase: {
      genitive: `урона электричеством`,
      dative: `урону электричеством`,
    },
    description: `**Электричество.** Заклинание _Молния_ и дыхание [синего дракона](CREATURE:${CREATURE_DRAGON_BLUE_ANCIENT}) причиняют урон электричеством.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_POISON,
    name: 'Ядом',
    nameEn: 'poison',
    fullName: 'Урон ядом',
    nameByCase: {
      genitive: `яда`,
      dative: `яду`,
    },
    fullNameByCase: {
      genitive: `урона ядом`,
      dative: `урону ядом`,
    },
    description: `**Яд.** Ядовитые укусы и токсичное дыхание [зелёного дракона](CREATURE:${CREATURE_DRAGON_GREEN_ANCIENT}) причиняют урон ядом.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_PSYCHIC,
    name: 'Психикой',
    nameEn: 'psychic',
    fullName: 'Психический урон',
    nameByCase: {
      genitive: `психического урона`,
      dative: `психическому урону`,
    },
    fullNameByCase: {
      genitive: `психического урона`,
      dative: `психическому урону`,
    },
    description: `**Психическая энергия.** Атаки силой разума, такие как у [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}), причиняют урон психической энергией.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_RADIANT,
    name: 'Излучением',
    nameEn: 'radiant',
    fullName: 'Урон излучением',
    nameByCase: {
      genitive: `излучением`,
      dative: `излучению`,
    },
    fullNameByCase: {
      genitive: `урона излучением`,
      dative: `урону излучением`,
    },
    description: `**Излучение.** Урон излучением, причиняемый заклинанием _Небесный огонь_ жреца и карающим оружием [ангела](CREATURE:${CREATURE_SOLAR}), опаляют плоть как огонь и переполняют дух силой.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_THUNDER,
    name: 'Звуком',
    nameEn: 'thunder',
    fullName: 'Урон звуком',
    nameByCase: {
      genitive: `звуком`,
      dative: `звуку`,
    },
    fullNameByCase: {
      genitive: `урона звуком`,
      dative: `урону звуком`,
    },
    description: `**Звук.** Оглушительные звуковые волны, такие как от заклинания _Волна грома_, причиняют урон звуком.`,
    isRealDamageType: true,
  },
  {
    id: DAMAGE_SPELLS,
    name: 'От заклинаний',
    nameEn: 'by spells',
    fullName: 'Урон от заклинаний',
    nameByCase: {
      genitive: `заклинаниями`,
      dative: `заклинаниям`,
    },
    fullNameByCase: {
      genitive: `урона от заклинаний`,
      dative: `урону от заклинаний`,
    },
    description: `Урон от заклинаний`,
  },
  {
    id: DAMAGE_WEAPON_DARK,
    name: 'Дробящий, колющий и рубящий, пока находится в области тусклого света или тьмы',
    nameEn: 'weapon at darkness',
    fullName: 'Дробящий, колющий и рубящий, пока находится в области тусклого света или тьмы',
    nameByCase: {
      genitive: `дробящего, колющего и рубящего урона, пока находится в области тусклого света или тьмы`,
      dative: `дробящему, колющему и рубящему урону, пока находится в области тусклого света или тьмы`,
    },
    fullNameByCase: {
      genitive: `дробящего, колющего и рубящего урона, пока находится в области тусклого света или тьмы`,
      dative: `дробящему, колющему и рубящему урону, пока находится в области тусклого света или тьмы`,
    },
    description: `Дробящий, колющий и рубящий, пока находится в области тусклого света или тьмы`,
    isEnumeration: true,
  },
  {
    id: DAMAGE_WEAPON_DARK_NONMAGIC,
    name: 'Дробящий, колющий и рубящий от немагических атак, пока находится в области тусклого света или тьмы',
    nameEn: 'nonmagic weapon at darkness',
    fullName: 'Дробящий, колющий и рубящий от немагических атак, пока находится в области тусклого света или тьмы',
    nameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагических атак, пока находится в области тусклого света или тьмы`,
      dative: `дробящему, колющему и рубящему урону от немагических атак, пока находится в области тусклого света или тьмы`,
    },
    fullNameByCase: {
      genitive: `дробящего, колющего и рубящего урона от немагических атак, пока находится в области тусклого света или тьмы`,
      dative: `дробящему, колющему и рубящему урону от немагических атак, пока находится в области тусклого света или тьмы`,
    },
    description: `Дробящий, колющий и рубящий от немагических атак, пока находится в области тусклого света или тьмы`,
    isEnumeration: true,
  },
]

module.exports = damageTypeList

module.exports.damageTypeCollection = listToCollectionById(damageTypeList)

module.exports.DAMAGE_ACID = DAMAGE_ACID
module.exports.DAMAGE_BLUDGEONING = DAMAGE_BLUDGEONING
module.exports.DAMAGE_COLD = DAMAGE_COLD
module.exports.DAMAGE_ELECTRICITY = DAMAGE_ELECTRICITY
module.exports.DAMAGE_FIRE = DAMAGE_FIRE
module.exports.DAMAGE_FORCE = DAMAGE_FORCE
module.exports.DAMAGE_MAGIC_PIERCING_GOOD = DAMAGE_MAGIC_PIERCING_GOOD
module.exports.DAMAGE_MAGIC_WEAPON = DAMAGE_MAGIC_WEAPON
module.exports.DAMAGE_NECROTIC = DAMAGE_NECROTIC
module.exports.DAMAGE_NONMAGIC = DAMAGE_NONMAGIC
module.exports.DAMAGE_NONMAGICAL_ATTACKS = DAMAGE_NONMAGICAL_ATTACKS
module.exports.DAMAGE_NONMAGIC_NONADAMANTINE_NONSILVER_ATTACKS = DAMAGE_NONMAGIC_NONADAMANTINE_NONSILVER_ATTACKS
module.exports.DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON = DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON
module.exports.DAMAGE_NONMAGIC_NONSILVER_WEAPON = DAMAGE_NONMAGIC_NONSILVER_WEAPON
module.exports.DAMAGE_NONMAGIC_WEAPON = DAMAGE_NONMAGIC_WEAPON
module.exports.DAMAGE_PIERCING = DAMAGE_PIERCING
module.exports.DAMAGE_POISON = DAMAGE_POISON
module.exports.DAMAGE_PSYCHIC = DAMAGE_PSYCHIC
module.exports.DAMAGE_RADIANT = DAMAGE_RADIANT
module.exports.DAMAGE_SLASHING = DAMAGE_SLASHING
module.exports.DAMAGE_SLASHING_NONMAGIC_NONSILVER_WEAPON = DAMAGE_SLASHING_NONMAGIC_NONSILVER_WEAPON
module.exports.DAMAGE_SPELLS = DAMAGE_SPELLS
module.exports.DAMAGE_THUNDER = DAMAGE_THUNDER
module.exports.DAMAGE_WEAPON_DARK = DAMAGE_WEAPON_DARK
module.exports.DAMAGE_WEAPON_DARK_NONMAGIC = DAMAGE_WEAPON_DARK_NONMAGIC
