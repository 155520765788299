const {PHB} = require('./../../../sourceList')
const {PC_SUBCLASS_BARD_COLLEGE_OF_LORE} = require('./../../../pcSubClassIdList')
const {
  FEATURE_ADDITIONAL_MAGICAL_SECRETS,
  FEATURE_BARDIC_INSPIRATION,
  FEATURE_BONUS_PROFICIENCIES_BARD_COLLEGE_OF_LORE,
  FEATURE_CUTTING_WORDS,
  FEATURE_PEERLESS_SKILL,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BONUS_PROFICIENCIES_BARD_COLLEGE_OF_LORE,
    name: `Дополнительные навыки`,
    nameEn: `Bonus Proficiencies`,
    lvl: 3,
    text: `Вы овладеваете тремя навыками на Ваш выбор.`,
  },
  {
    id: FEATURE_CUTTING_WORDS,
    name: `Острое словцо`,
    nameEn: `Cutting Words`,
    lvl: 3,
    text: `Вы узнаёте, как использовать собственное остроумие, чтобы отвлечь, смутить или по-другому подорвать способности и уверенность противников.

Если существо, которое Вы можете видеть, в пределах 60 футов от Вас совершает бросок атаки, урона или проверку характеристики, Вы можете реакцией потратить одну из Ваших костей [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}), и вычесть результат броска этой кости из броска этого существа.

Вы можете принять решение об использовании этой способности после броска существа, но до того момента, когда Мастер объявит результат броска или проверки. Существо не подвержено этой способности, если не может слышать Вас, или обладает иммунитетом к очарованию.`,
  },
  {
    id: FEATURE_ADDITIONAL_MAGICAL_SECRETS,
    name: `Дополнительные тайны магии`,
    nameEn: `Additional Magical Secrets`,
    lvl: 6,
    text: `Вы можете выучить 2 заклинания из доступных любому классу на свой выбор.

Их уровень не должен превышать уровня заклинаний, которые Вы можете использовать на этом уровне. Они также могут быть заговорами.

Выбранные заклинания теперь считаются для Вас заклинаниями барда, но максимальное количество заклинаний, которое Вы можете знать, не меняется.`,
  },
  {
    id: FEATURE_PEERLESS_SKILL,
    name: `Непревзойдённый навык`,
    nameEn: `Peerless Skill`,
    lvl: 14,
    text: `Если Вы совершаете проверку характеристики, Вы можете бросить кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и добавить результат к проверке.

Вы можете принять решение об использовании этой способности после броска проверки, но до того, как Мастер объявит результат этой проверки.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_LORE,
    source: {
      id: PHB,
      page: 56,
    },
  })
)

