const {CREATURE_FROST_GIANT} = require('./../../../../creatureIdList')
const {GEAR_WARHAMMER} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {GOD_THRYM} = require('./../../../../godIdList')
const {MAGIC_ITEM_MATALOTOK} = require('./../../../../magicItemIdList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../magicItemTypeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_MATALOTOK,
  name: `Маталоток`,
  nameAlt: `Отец Мороз`,
  nameEn: `Matalotok`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_WARHAMMER,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: `_Маталоток_, он же Отец Мороз — это древний молот, выкованный [Тримом](GOD${GOD_THRYM}), богом [ледяных великанов](CREATURE${CREATURE_FROST_GIANT}), и любимое оружие демонического владыки Кощища. _Маталоток_ холоден на ощупь и окутан дымкой.

Держа _Маталоток_ в руках, Вы невосприимчивы к урону холодом Каждый раз, когда молот наносит урон существу, он испускает взрыв сильного холода в пределах 30 футов. Каждое существо в этой области получает 10 (3к6) урона холодом.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_BGDIA,
    page: 2,
  },
}
