const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_UMBER_HULK} = require('./../../languageIdList')

module.exports = {
  id: LANG_UMBER_HULK,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык бурых увальней',
    genitive: 'Языка бурых увальней',
    instrumental: 'Языком бурых увальней',
    prepositional: 'Языке бурых увальней',
  },
  isRealLang: true,
}
