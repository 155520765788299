const {
  SOURCE_EE,
  SOURCE_VGTM,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SPELL_WALL_OF_WATER} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_WALL_OF_WATER,
  name: 'Водяная стена',
  nameAlt: 'Стена воды',
  nameEn: 'Wall of Water',
  description: `Вы создаёте стену воды в видимой точке на горизонтальной поверхности в пределах дистанции заклинания. Точка должна располагаться на поверхности. Стена может быть длиной до 30 футов, высотой до 10 футов и толщиной до 1 фута. Или Вы можете создать кольцо диаметром 20 футов, высотой 20 футов и толщиной 1 фут. Стена существует, пока действует заклинание. Стена считается труднопроходимой местностью.

Все атаки дальнобойным оружием, траектория которых проходит через пространство стены, совершаются с помехой. Огненный урон от эффектов, которые прошли через стену, уменьшается вдвое. Заклинания, которые причиняют урон холодом, при прохождении через стену замораживают ту область стены, сквозь которую прошли (минимум клетка 5×5 футов). Каждая замёрзшая секция площадью 5×5 футов имеет КД 5 и 15 хитов. Если хиты секции уменьшаются до 0, она разрушается. Разрушенные секции стены не заполняются водой повторно.`,
  lvl: 3,
  magicSchoolId: MAGIC_EVOCATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `капля воды`,
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  source: [
    {
      id: SOURCE_XGTE,
      page: 149,
    },
    {
      id: SOURCE_VGTM,
      page: 118,
    },
    {
      id: SOURCE_EE,
      page: 16,
    },
  ],
}
