const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  CR_0,
} = require('./../../../../crList')
const {
  SIZE_TINY,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_MIMIC,
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_ACID,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FALSE_APPEARANCE,
  ABILITY_SPIDER_CLIMB,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  mimicDescription,
  mimicNoteList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_JUVENILE_MIMIC,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Мимикёнок',
  nameAlt: 'Юный мимик',
  nameEn: 'Juvenile Mimic',
  id: CREATURE_JUVENILE_MIMIC,
  note: mimicNoteList,
  description: mimicDescription,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_MIMIC,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TCoE,
    page: 235,
  },
  armor: 11,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_CLIMB]: 10,
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_ACID,
  ],
  immunityConditionList: [
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_UNDERCOMMON,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_0,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      comment: 'только в облике предмета',
      appearance: `обычного предмета`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceBonus: 1,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 1,
            diceType: 4,
          },
        ],
      },
    },
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в любой предмет, или принять свой истинный, бесформенный облик. Во всех обликах его характеристики остаются теми же самыми. Всё несомое и носимое им снаряжение не превращается. Он принимает свой истинный облик, если умирает.`,
    },
  ],
}
