const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {CONDITION_DEAFENED} = require('./../../../../../conditionList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SPELL_WARDING_WIND} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_WARDING_WIND,
  name: 'Защитный ветер',
  nameEn: 'Warding Wind',
  description: `Сильный ветер (20 миль в час) дует вокруг Вас в радиусе 10 футов. Он перемещается вместе с Вами так, что Вы остаётесь в центре. Ветер существует на протяжении длительности заклинания.

Ветер обладает следующими эффектами:

* Все существа в области действия, включая вас, оглушены.
* Гасит все незащищённые источники огня размером с факел и меньше.
* Для всех существ, кроме вас, область считается труднопроходимой местностью.
* Броски атаки для атак дальнобойным оружием совершаются с помехой, если атака исходит из области ветра или заходит в неё.
* Ограждает от паров, газов и тумана, которые могут быть развеяны сильным ветром.`,
  lvl: 2,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  componentIdList: [CAST_VERBAL],
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    condition: CONDITION_DEAFENED,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 151,
    },
    {
      id: SOURCE_EE,
      page: 17,
    },
  ],
}
