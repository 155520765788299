const {ALPHABET_DETHEK} = require('./../../alphabetList')
const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {primordialLanguageDescriptionList} = require('./../../textCommonParts')
const {SOURCE_DND3_5_MotP} = require('./../../sourceList')
const {
  LANG_IGNAN,
  LANG_PRIMORDIAL,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_IGNAN,
  alphabetId: ALPHABET_DETHEK,
  type: LANG_TYPE_EXOTIC,
  dialectOf: LANG_PRIMORDIAL,
  name: {
    nominative: 'Игнан',
    genitive: 'Игнана',
    instrumental: 'Игнаном',
    prepositional: 'Игнане',
  },
  nameEn: 'Ignan',
  typicalSpeakers: 'Обитатели Стихийного Плана Огня',
  description: [
    {
      text: `Родной язык большинства обитателей Стихийного Плана Огня — Игнан, резкий, шипящий и щёлкающий язык.`,
      source: {
        id: SOURCE_DND3_5_MotP,
        page: 74,
      },
    },
    ...primordialLanguageDescriptionList,
  ],
  isRealLang: true,
  isReady: true,
}
