const {
  SPELL_BANE,
  SPELL_BANISHMENT,
  SPELL_BESTOW_CURSE,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CLAIRVOYANCE,
  SPELL_CONTAGION,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINATION,
  SPELL_FAERIE_FIRE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GUIDANCE,
  SPELL_HARM,
  SPELL_INFLICT_WOUNDS,
  SPELL_INSECT_PLAGUE,
  SPELL_LEVITATE,
  SPELL_MAGIC_CIRCLE,
  SPELL_MESSAGE,
  SPELL_POISON_SPRAY,
  SPELL_RESISTANCE,
  SPELL_SILENCE,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_SUGGESTION,
  SPELL_THAUMATURGY,
  SPELL_TRUE_SEEING,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_DROW_INQUISITOR,
  CREATURE_DROW_MATRON_MOTHER,
  CREATURE_DROW_PRIESTESS_OF_LOLTH,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  drowDescription,
  drowNote,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_FRIGHTENED} = require('./../../../../conditionList')
const {CR_14} = require('./../../../../crList')
const {
  GEAR_BREASTPLATE,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дроу инквизитор',
  nameEn: 'Drow Inquisitor',
  id: CREATURE_DROW_INQUISITOR,
  description: [
    `Дроу всегда ожидает предательства. Ведь в конце концов, Королева Пауков поощряет это. Определённое количество ударов в спину и предательств ещё имеет место быть, но, если их будет слишком много — это подорвёт целое сообщество. Чтобы сохранить подобие порядка и искоренить предателей, [жрицы дроу](CREATURE:${CREATURE_DROW_PRIESTESS_OF_LOLTH}) используют инквизиторов. Избранные из рядов духовенства, эти женщины обладают авторитетом, с которым могут сравниться разве что [старшие матери](CREATURE:${CREATURE_DROW_MATRON_MOTHER}) знатных домов. Любой, несогласный с иерархией, подвергается пыткам и, обычно, мучительной смерти.`,
    ...drowDescription,
  ],
  note: [
    {
      text: `Многие создания увлекаются пытками, но тёмные эльфы превратили их в изысканное искусство.`,
      author: `Архимаг Морденкайнен`,
    },
    drowNote,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 167,
  },
  armor: {
    ac: 16,
    gearId: GEAR_BREASTPLATE,
  },
  hp: {
    diceCount: 22,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 21,
    [PARAM_CHA]: 20,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ELVEN,
    LANG_UNDERCOMMON,
  ],
  cr: CR_14,
  featureList: [
    {
      name: 'Распознание лжи',
      description: `Дроу знает, когда слышит ложь, произносимую другим существом на языке, знакомом ей.`,
    },
    ABILITY_FEY_ANCESTRY,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DANCING_LIGHTS,
            SPELL_DETECT_MAGIC,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_CLAIRVOYANCE,
            SPELL_DARKNESS,
            SPELL_DETECT_THOUGHTS,
            SPELL_DISPEL_MAGIC,
            SPELL_FAERIE_FIRE,
            SPELL_SUGGESTION,
            {
              id: SPELL_LEVITATE,
              comment: 'только на себя',
            },
          ],
        },
      ],
    },
    {
      spellCasterLevel: 12,
      spellCasterClass: PC_CLASS_CLERIC,
      spellIdList: [
        SPELL_BANE,
        SPELL_BANISHMENT,
        SPELL_BESTOW_CURSE,
        SPELL_BLINDNESS_DEAFNESS,
        SPELL_CONTAGION,
        SPELL_CURE_WOUNDS,
        SPELL_DISPEL_EVIL_AND_GOOD,
        SPELL_DISPEL_MAGIC,
        SPELL_DIVINATION,
        SPELL_FREEDOM_OF_MOVEMENT,
        SPELL_GUIDANCE,
        SPELL_HARM,
        SPELL_INFLICT_WOUNDS,
        SPELL_INSECT_PLAGUE,
        SPELL_MAGIC_CIRCLE,
        SPELL_MESSAGE,
        SPELL_POISON_SPRAY,
        SPELL_RESISTANCE,
        SPELL_SILENCE,
        SPELL_SPIRITUAL_WEAPON,
        SPELL_THAUMATURGY,
        SPELL_TRUE_SEEING,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        2,
        1,
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Дроу совершает три атаки _Копьём смерти_.`,
    },
    {
      name: 'Копьё смерти',
      gearId: GEAR_SPEAR,
      description: `Максимальное значение хитов цели уменьшается на значение полученного урона от некротической энергии. Это уменьшение сохраняется до совершения целью длинного отдыха. Цель погибает, если её максимальное значение хитов уменьшается до 0.`,
      attack: {
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 4,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
