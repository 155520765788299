module.exports.aarakocraDescriptionList = require('./parts/aarakocra')
module.exports.abishaiDescriptionList = require('./parts/abishai')
module.exports.abishaiNote = require('./notes/abishai')
module.exports.albinoDwarfDescription = require('./parts/albinoDwarf')
module.exports.alhoonDescriptionList = require('./parts/alhoon')
module.exports.ancientElementalDescriptionList = require('./parts/ancientElemental')
module.exports.angelDescriptionList = require('./parts/angel')
module.exports.animatedThingsDescriptionList = require('./parts/animatedThings')
module.exports.anvilwroughtDescription = require('./parts/anvilwrought')
module.exports.archDevilDescriptionList = require('./parts/archDevil')
module.exports.archDevilNoteList = require('./notes/archDevil')
module.exports.archonDescriptionList = require('./parts/archon')
module.exports.flamingFistDescriptionList = require('./parts/flamingFist')
module.exports.flamingFistNoteList = require('./notes/flamingFist')
module.exports.archonMountsDescription = require('./parts/archonMounts')
module.exports.bahamutDescriptionList = require('./parts/bahamut')
module.exports.baneFollowerList = require('./parts/baneFollowers')
module.exports.beholderLikeDescription = require('./parts/beholderLike')
module.exports.bhaalFollowerList = require('./parts/bhaalFollowers')
module.exports.blackDragonDescriptionList = require('./parts/dragonColorBlack')
module.exports.blackEarthDescription = require('./parts/blackEarthDescription')
module.exports.blightDescriptionList = require('./parts/blight')
module.exports.blueDragonDescriptionList = require('./parts/dragonColorBlue')
module.exports.blueDragonLocalEffectText = require('./parts/blueDragonLocalEffectText')
module.exports.boneDevilSummoner = require('./extends/boneDevilSummoner')
module.exports.brassDragonDescriptionList = require('./parts/dragonMetallicBrass')
module.exports.bronzeDragonDescriptionList = require('./parts/dragonMetallicBronze')
module.exports.bugbearsDescriptionList = require('./parts/bugbears')
module.exports.centaurDescriptionList = require('./parts/centaur')
module.exports.centaurNote = require('./notes/centaur')
module.exports.cerberiDescription = require('./parts/cerberi')
module.exports.chardalynDescriptionList = require('./parts/chardalyn')
module.exports.chitineDescriptionList = require('./parts/chitine')
module.exports.chitineNote = require('./notes/chitine')
module.exports.clawfootDescriptionList = require('./parts/clawfoot')
module.exports.clockworkDescriptionList = require('./parts/clockwork')
module.exports.clockworkNote = require('./notes/clockwork')
module.exports.cloudGiantDescriptionList = require('./parts/giantCloud')
module.exports.colorDragonDescriptionList = require('./parts/dragonColor')
module.exports.copperDragonDescriptionList = require('./parts/dragonMetallicCopper')
module.exports.craniumRatsDescriptionList = require('./parts/craniumRats')
module.exports.cultOfDragonDescriptionList = require('./parts/cult_of_dragon')
module.exports.darklingDescriptionList = require('./parts/darkling')
module.exports.deathlockDescription = require('./parts/deathlock')
module.exports.deepCrowDescriptionList = require('./parts/deepCrow')
module.exports.deepCrowNote = require('./notes/deepCrow')
module.exports.demonDescription = require('./parts/demon')
module.exports.derroDescription = require('./parts/derro')
module.exports.derroNote = require('./notes/derro')
module.exports.devilDescriptionList = require('./parts/devil')
module.exports.devilNote = require('./notes/devil')
module.exports.dinoDescriptionList = require('./parts/dino')
module.exports.dragonTurtlesNote = require('./notes/dragon_turtles')
module.exports.dragonsBlackNoteList = require('./notes/dragons/dragons_black')
module.exports.dragonsBlueNoteList = require('./notes/dragons/dragons_blue')
module.exports.dragonsBrassNoteList = require('./notes/dragons/dragons_brass')
module.exports.dragonsBronzeNoteList = require('./notes/dragons/dragons_bronze')
module.exports.dragonsCopperNoteList = require('./notes/dragons/dragons_copper')
module.exports.dragonsGoldNoteList = require('./notes/dragons/dragons_gold')
module.exports.dragonsGreenNoteList = require('./notes/dragons/dragons_green')
module.exports.dragonsNoteList = require('./notes/dragons/dragons_common')
module.exports.dragonsRedNoteList = require('./notes/dragons/dragons_red')
module.exports.dragonsSapphireNoteList = require('./notes/dragons/dragons_sapphire')
module.exports.dragonsSilverNoteList = require('./notes/dragons/dragons_silver')
module.exports.dragonsWhiteNoteList = require('./notes/dragons/dragons_white')
module.exports.drowDescription = require('./parts/drow')
module.exports.drowMageNote = require('./notes/drowMage')
module.exports.drowNote = require('./notes/drow')
module.exports.drowPoison = require('./parts/drowPoison')
module.exports.duergarDescription = require('./parts/duergar')
module.exports.duergarNote = require('./notes/duergar')
module.exports.dunamancyDescription = require('./parts/dunamancy')
module.exports.dunamisDescriptionList = require('./parts/dunamis')
module.exports.eladrinDescription = require('./parts/eladrin')
module.exports.eladrinNote = require('./notes/eladrin')
module.exports.elegyForTheFirstWorldDescription = require('./parts/elegy_for_the_first_world')
module.exports.elementalDescription = require('./parts/elemental')
module.exports.elementalMyrmidonDescription = require('./parts/elementalMyrmidon')
module.exports.elementalWeaponDescription = require('./parts/elementalWeapon')
module.exports.eternalFlameDescription = require('./parts/eternalFlameDescription')
module.exports.fireGiantDescriptionList = require('./parts/giantFire')
module.exports.fireGiantNote = require('./notes/giantFire')
module.exports.fireNewtDescriptionList = require('./parts/fireNewt')
module.exports.fireNewtNote = require('./notes/fireNewt')
module.exports.firstFamilyDescription = require('./parts/firstFamily')
module.exports.frostGiantDescriptionList = require('./parts/giantFrost')
module.exports.frostGiantEverlastingOneList = require('./parts/frostGiantEverlastingOne')
module.exports.frostGiantNote = require('./notes/giantFrost')
module.exports.gargoyleDescription = require('./parts/gargoyle')
module.exports.geniusDescriptionList = require('./parts/genius')
module.exports.giantCloudNote = require('./notes/giantCloud')
module.exports.giantDescriptionList = require('./parts/giant')
module.exports.giantGodsAllDescriptionList = require('./parts/giantGodsAll')
module.exports.giantGodsCommonDescriptionList = require('./parts/giantGodsCommon')
module.exports.giantLanguageDescriptionList = require('./parts/giantLanguage')
module.exports.giantLanguageWithoutVoloDictDescriptionList = require('./parts/giantLanguageWithoutVoloDict')
module.exports.giantRunesDescriptionList = require('./parts/giantRunes')
module.exports.giantStoneNote = require('./notes/giantStone')
module.exports.giantStormNote = require('./notes/giantStorm')
module.exports.giantStriderDescription = require('./parts/giantStrider')
module.exports.giffDescriptionList = require('./parts/giff')
module.exports.githDescription = require('./parts/gith')
module.exports.githNote = require('./notes/gith')
module.exports.githyankiDescription = require('./parts/githyanki')
module.exports.githyankiNote = require('./notes/githyankiNote')
module.exports.githyankiPureDescription = require('./parts/githyankiPure')
module.exports.githyankiPureNote = require('./notes/githyankiPureNote')
module.exports.githzeraiDescription = require('./parts/githzerai')
module.exports.githzeraiPureDescription = require('./parts/githzeraiPure')
module.exports.gitzeraiNote = require('./notes/gitzeraiNote')
module.exports.gitzeraiPureNote = require('./notes/gitzeraiPureNote')
module.exports.gnollDescriptionList = require('./parts/gnoll')
module.exports.goblinDescriptionList = require('./parts/goblin')
module.exports.godAnnamOnlyDescriptionList = require('./parts/godAnnamOnly')
module.exports.godDendarDescriptionList = require('./parts/godDendar')
module.exports.godGrolantorOnlyDescriptionList = require('./parts/godGrolantorOnly')
module.exports.godMemnorOnlyDescriptionList = require('./parts/godMemnorOnly')
module.exports.godMerrshaulkDescriptionList = require('./parts/godMerrshaulk')
module.exports.godSkoraeusOnlyDescriptionList = require('./parts/godSkoraeusOnly')
module.exports.godSsethDescriptionList = require('./parts/godSseth')
module.exports.godStronmausOnlyDescriptionList = require('./parts/godStronmausOnly')
module.exports.godSurturOnlyDescriptionList = require('./parts/godSurturOnly')
module.exports.godThrymOnlyDescriptionList = require('./parts/godThrymOnly')
module.exports.goldDragonDescriptionList = require('./parts/dragonMetallicGold')
module.exports.golemDescription = require('./parts/golem')
module.exports.goliathDescriptionList = require('./parts/goliath')
module.exports.goliathNote = require('./notes/goliath')
module.exports.greenDragonDescriptionList = require('./parts/dragonColorGreen')
module.exports.grickDescriptionList = require('./parts/grick')
module.exports.grungDescriptionList = require('./parts/grung')
module.exports.grungNote = require('./notes/grung')
module.exports.gruumshDescriptionList = require('./parts/gruumsh')
module.exports.gruumshMarkDescription = require('./parts/gruumshMark')
module.exports.guardDrakeDescription = require('./parts/guardDrake')
module.exports.hagsDescriptionList = require('./parts/hag')
module.exports.hillGiantDescriptionList = require('./parts/giantHill')
module.exports.hobgoblinDescriptionList = require('./parts/hobgoblin')
module.exports.hobgoblinNote = require('./notes/hobgoblin')
module.exports.homunculiDescriptionList = require('./parts/homunculi')
module.exports.howlingHatredDescriptionList = require('./parts/howlingHatred')
module.exports.hruggekDescription = require('./parts/hruggek')
module.exports.humanLanguagesDescriptionList = require('./parts/humanLanguages')
module.exports.iceDevilSummoner = require('./extends/iceDevilSummoner')
module.exports.illithidDescriptionList = require('./parts/illithid')
module.exports.insectSwarmDescription = require('./parts/insectSwarm')
module.exports.kenkuDescriptionList = require('./parts/kenku')
module.exports.kenkuNote = require('./notes/kenku')
module.exports.killAsLemureText = require('./parts/killAsLemureText')
module.exports.koboldDescriptionList = require('./parts/kobold')
module.exports.koboldNote = require('./notes/kobold')
module.exports.kruthikDescription = require('./parts/kruthik')
module.exports.kruthikNote = require('./notes/kruthik')
module.exports.kuoToaDescriptionList = require('./parts/kuoToa')
module.exports.kuoToaNote = require('./notes/kuoToa')
module.exports.kurtulmakDescription = require('./parts/kurtulmak')
module.exports.laogzedDescription = require('./parts/laogzed')
module.exports.lineagesDescriptionList = require('./parts/lineages')
module.exports.lizardfolkDescriptionList = require('./parts/lizardfolk')
module.exports.lizardfolkNotes = require('./notes/lizardfolk')
module.exports.lolthDescriptionList = require('./parts/lolth')
module.exports.luthicDescription = require('./parts/luthic')
module.exports.lycantropDescription = require('./parts/lycantrop')
module.exports.magenDescription = require('./parts/magen')
module.exports.maglubiyetBarghestDescription = require('./parts/maglubiyetBarghest')
module.exports.maglubiyetDescription = require('./parts/maglubiyet')
module.exports.maglubiyetGoblinDescription = require('./parts/maglubiyetGoblin')
module.exports.mephitDescription = require('./parts/mephit')
module.exports.mimicDescription = require('./parts/mimic')
module.exports.mimicNoteList = require('./notes/mimic')
module.exports.modronDescriptionList = require('./parts/modron')
module.exports.modronNote = require('./notes/modron')
module.exports.moldCreaturesDescriptionList = require('./parts/moldCreatures')
module.exports.moldCreaturesNote = require('./notes/moldCreatures')
module.exports.monsterAdventurers = require('./parts/monsterAdventurers')
module.exports.mummyDescriptionList = require('./parts/mummy')
module.exports.mummyNote = require('./notes/mummy')
module.exports.mushroomsDescription = require('./parts/mushrooms')
module.exports.myconidDescriptionList = require('./parts/myconid')
module.exports.myrkulFollowerList = require('./parts/myrkulFollowers')
module.exports.nagaDescription = require('./parts/naga')
module.exports.needleDescription = require('./parts/needle')
module.exports.needleNote = require('./notes/needle')
module.exports.neogiDescription = require('./parts/neogi')
module.exports.neogiNote = require('./notes/neogi')
module.exports.norkerDescription = require('./parts/norker')
module.exports.oblexAdultAndElderDescription = require('./parts/oblexAdultAndElder')
module.exports.oblexDescription = require('./parts/oblex')
module.exports.oblexNoteList = require('./notes/oblex')
module.exports.ogreDescription = require('./parts/ogre')
module.exports.ogreNote = require('./notes/ogre')
module.exports.oozeDescriptionList = require('./parts/ooze')
module.exports.oozeNote = require('./notes/ooze')
module.exports.orcDescription = require('./parts/orc')
module.exports.orcEyeOfGruumshDescription = require('./parts/orc_eye_of_gruumsh')
module.exports.orcNote = require('./notes/orc')
module.exports.primordialLanguageDescriptionList = require('./parts/primordialLanguage')
module.exports.qualithDescriptionList = require('./parts/qualith')
module.exports.ravenQueenAndNagpaDescriptionList = require('./parts/raven_queen_and_nagpa')
module.exports.ravenQueenAndShadarKaiDescriptionList = require('./parts/raven_queen_and_shadarKai')
module.exports.redDragonDescriptionList = require('./parts/dragonColorRed')
module.exports.remorhazDescriptionList = require('./parts/remorhaz')
module.exports.sahuaginDescriptionList = require('./parts/sahuagin')
module.exports.sahuaginNote = require('./notes/sahuagin')
module.exports.salamanderDescriptionList = require('./parts/salamander')
module.exports.satyrDescriptionList = require('./parts/satyr')
module.exports.satyrNote = require('./notes/satyr')
module.exports.shadarKaiDescriptionList = require('./parts/shadarKai')
module.exports.silverDragonDescriptionList = require('./parts/dragonMetallicSilver')
module.exports.skeletonDescriptionList = require('./parts/skeleton')
module.exports.slaadDescription = require('./parts/slaad')
module.exports.sorrowSwornDescriptionList = require('./parts/sorrowSworn')
module.exports.sphinxDescription = require('./parts/sphinx')
module.exports.sphinxNote = require('./notes/sphinx')
module.exports.starSpawnDescription = require('./parts/starSpawn')
module.exports.starSpawnNote = require('./notes/starSpawn')
module.exports.steederDescriptionList = require('./parts/steeder')
module.exports.steederNote = require('./notes/steeder')
module.exports.stoneGiantDescriptionList = require('./parts/giantStone')
module.exports.stormGiantDescriptionList = require('./parts/giantStorm')
module.exports.swarmDescription = require('./parts/swarm')
module.exports.swordWraithDescription = require('./parts/swordWraith')
module.exports.tabaxiDescriptionList = require('./parts/tabaxi')
module.exports.thriKreenDescriptionList = require('./parts/thri_kreen')
module.exports.tiamatDescriptionList = require('./parts/tiamat')
module.exports.tirsuDescription = require('./parts/tirsu')
module.exports.tortleDescriptionList = require('./parts/tortle')
module.exports.trollDescription = require('./parts/troll')
module.exports.uncommonRaces = require('./parts/uncommon_races')
module.exports.vampireDescriptionList = require('./parts/vampire')
module.exports.vaprakDescription = require('./parts/vaprak')
module.exports.waterdeepCityGuardDescriptionList = require('./parts/waterdeep_city_guard')
module.exports.whiteDragonDescriptionList = require('./parts/dragonColorWhite')
module.exports.xvartDescription = require('./parts/xvart')
module.exports.xvartNote = require('./notes/xvart')
module.exports.yakfolkDescriptionList = require('./parts/yakfolk')
module.exports.yellowMuskDescription = require('./parts/yellowMusk')
module.exports.yuantiDescriptionList = require('./parts/yuanti')
module.exports.yuantiNoteList = require('./notes/yuanti')
module.exports.yuantiPurebloodDescription = require('./parts/yuantiPureblood')
module.exports.yugolothDescriptionList = require('./parts/yugoloth')
module.exports.zombieDesciptionList = require('./parts/zombie')
