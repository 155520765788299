const {GENDER_MIDDLE} = require('./../../genderList')
const {CREATURE_TYPE_ANY_RACE} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ANY_RACE,
  nameEn: 'Any race',
  genderId: GENDER_MIDDLE,
  hideFromFilter: true,
  notRealRace: true,
  name: {
    singular: {
      nominative: 'любая раса',
      genitive: 'любой расы',
      dative: 'любой расе',
      accusative: 'любую расу',
      instrumental: 'любой расой',
      prepositional: 'любой расе',
    },
    plural: {
      nominative: 'любые расы',
      genitive: 'любых рас',
      dative: 'любым расам',
      accusative: 'любые расы',
      instrumental: 'любыми расами',
      prepositional: 'любых расах',
    },
  },
}
