const {SOURCE_UA_2020_08_05_SUBCLASSES_PART_4} = require('./../../../sourceList')
const {PC_SUBCLASS_WARLOCK_THE_UNDEAD_2020_08_05} = require('./../../../pcSubClassIdList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_ANTILIFE_SHELL,
  SPELL_BANE,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CLOUDKILL,
  SPELL_DEATH_WARD,
  SPELL_FALSE_LIFE,
  SPELL_GREATER_INVISIBILITY,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTOM_STEED,
  SPELL_SPEAK_WITH_DEAD,
} = require('./../../../spellIdList')
const {
  FEATURE_EXPANDED_SPELLS_WARLOCK_THE_UNDEAD_2020_08_05,
  FEATURE_FORM_OF_DREAD_2020_08_05,
  FEATURE_GRAVE_TOUCHED_2020_08_05,
  FEATURE_MORTAL_HUSK_2020_08_05,
  FEATURE_SPIRIT_PROJECTION_2020_08_05,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_WARLOCK_THE_UNDEAD_2020_08_05,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded SpellList`,
    lvl: 3,
    text: `Неживой позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_ANTILIFE_SHELL,
      SPELL_BANE,
      SPELL_BLINDNESS_DEAFNESS,
      SPELL_CLOUDKILL,
      SPELL_DEATH_WARD,
      SPELL_FALSE_LIFE,
      SPELL_GREATER_INVISIBILITY,
      SPELL_PHANTASMAL_FORCE,
      SPELL_PHANTOM_STEED,
      SPELL_SPEAK_WITH_DEAD,
    ],
    source: {
      id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
      page: 3,
    },
  },
  {
    id: FEATURE_FORM_OF_DREAD_2020_08_05,
    name: `Жуткая форма`,
    nameEn: `Form of Dread`,
    lvl: 1,
    text: `Вы проявляете часть ужасающей мощи Вашего покровителя. Бонусным действием Вы можете трансформироваться на 1 минуту. Пока Вы трансформированы, Вы получаете следующие преимущества.

* Вы получаете 1к10 + Ваш уровень колдуна временных хитов.
* Один раз на своём ходу, когда Вы попадаете по существу атакой, Вы можете заставить его пройти испытание Мудрости. При провале это существо испугано Вами до конца Вашего следующего хода.
* Вы получаете иммунитет к испугу.

Вы можете трансформироваться число раз, равное Вашему бонусу мастерства и восстанавливаете все потраченные использования, когда заканчиваете длинный отдых.

Внешность Вашей Жуткой формы отражает некий аспект Вашего покровителя. Например, Вы формируете из сгустившейся тьмы корону и мантию своего покровителя-лича, или Ваше лицо может стать похожим на морду летучей мыши, если Ваш покровитель — вампир.`,
    source: {
      id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
      page: 3,
    },
  },
  {
    id: FEATURE_GRAVE_TOUCHED_2020_08_05,
    name: `Тронутый могилой`,
    nameEn: `Grave Touched`,
    lvl: 6,
    text: `Силы Вашего покровителя оказывают глубокое влияние на Ваше тело и магию.

Вам не нужно есть, пить или дышать.

Крому того, коглда Вы попадаете по существу атакой и бросаете кости урона, Вы можете заменить тип урона на некротический. Если при этом Ваша [Жуткая форма](FEATURE:${FEATURE_FORM_OF_DREAD_2020_08_05}) активна, Вы можете бросить дополнительную кость некротического урона.`,
    source: {
      id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
      page: 3,
    },
  },
  {
    id: FEATURE_MORTAL_HUSK_2020_08_05,
    name: `Смертная оболочка`,
    nameEn: `Mortal Husk`,
    lvl: 10,
    text: `Связь с нежизнью и некротической энергией теперь насыщает Ваше тело.

Вы получаете сопротивление к некротическому урону. Пока Ваша [Жуткая форма](FEATURE:${FEATURE_FORM_OF_DREAD_2020_08_05}) активна, вместо сопротивления Вы получаете иммунитет к некротическому урону.

Кроме того, когда Ваши хиты опускаются до 0, Вы можете заставить своё тело взорваться. Все существа в пределах 30 футов получают некротический урон, равный 2к10 + Ваш уровень колдуна. После этого Вы возрождаетесь с 1 хитом в том же месте, где и были, со всем своим снаряжением, и получаете 1 уровень истощения. Переродившись таким образом, Вы не сможете сделать это снова, пока не завершите 1к4 длинных отдыха.`,
    source: {
      id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
      page: 4,
    },
  },
  {
    id: FEATURE_SPIRIT_PROJECTION_2020_08_05,
    name: `Проекция духа`,
    nameEn: `Spirit Projection`,
    lvl: 14,
    text: `Ваше тело становится просто сосудом для духа.

Действием Вы можете проецировать свой дух из тела. Тело остаётся без сознания в состоянии приостановленной жизнедеятельности.

Ваш дух может оставаться вне тела до 1 часа или пока Ваша концентрация не закончится (как при концентрации на заклинании). Когда Ваша проекция заканчивается, дух возвращается в тело или тело магически телепортируется в местонахождение духа (на Ваш выбор).

Пока Ваш дух спроецирован вовне тела, Вы получаете следующие преимущества.

* Ваши дух и тело получают сопротивление к дробящему, колющему и рубящему урону.
* Сотворение заклинаний школ Вызова или Некромантии не требует от Вас вербальных, соматических или материальных компонентов, если у них не указана цена. 
* Вы получаете скорость полёта, равную Вашей скорости ходьбы и можете парить. Вы можете двигаться сквозь предметы и существ, как если бы они были труднопроходимой местностью, но получаете 1к10 урона силовым полем, если завершает ход внутри предмета или существа.
* Пока Ваша [Жуткая форма](FEATURE:${FEATURE_FORM_OF_DREAD_2020_08_05}) активна, один раз на своём ходу, когда Вы наносите некротический урон существу, Вы восстанавливаете хиты в количистве половины нанесённого некротического урона.

Если Вы используете эту способность, то не сможете использовать её снова, пока не закончите длинный отдых.`,
    source: {
      id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
      page: 4,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_UNDEAD_2020_08_05,
  })
)

