const {
  SPELL_ABI_DALZIM_S_HORRID_WILTING,
  SPELL_ABSORB_ELEMENTS,
  SPELL_ACID_SPLASH,
  SPELL_ACID_STREAM_2020_03_26,
  SPELL_AGANAZZAR_S_SCORCHER,
  SPELL_ALARM,
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_DEAD,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ANTIMAGIC_FIELD,
  SPELL_ANTIPATHY_SYMPATHY,
  SPELL_ARCANE_EYE,
  SPELL_ARCANE_GATE,
  SPELL_ARCANE_LOCK,
  SPELL_ASHARDALON_S_STRIDE,
  SPELL_ASTRAL_PROJECTION,
  SPELL_AUGURY,
  SPELL_BANISHMENT,
  SPELL_BESTOW_CURSE,
  SPELL_BIGBY_S_HAND,
  SPELL_BLADE_OF_DISASTER,
  SPELL_BLADE_WARD,
  SPELL_BLIGHT,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BLINK,
  SPELL_BLUR,
  SPELL_BOOMING_BLADE,
  SPELL_BURNING_HANDS,
  SPELL_CATAPULT,
  SPELL_CATNAP,
  SPELL_CAUSE_FEAR,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CHROMATIC_ORB,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CLAIRVOYANCE,
  SPELL_CLONE,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_CLOUDKILL,
  SPELL_COLOR_SPRAY,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_CONE_OF_COLD,
  SPELL_CONFUSION,
  SPELL_CONJURE_ELEMENTAL,
  SPELL_CONJURE_MINOR_ELEMENTALS,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_CONTINGENCY,
  SPELL_CONTINUAL_FLAME,
  SPELL_CONTROL_FLAMES,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WEATHER,
  SPELL_CONTROL_WINDS,
  SPELL_COUNTERSPELL,
  SPELL_CREATE_BONFIRE,
  SPELL_CREATE_HOMUNCULUS,
  SPELL_CREATE_MAGEN,
  SPELL_CREATE_UNDEAD,
  SPELL_CREATION,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CROWN_OF_STARS,
  SPELL_DANCING_LIGHTS,
  SPELL_DANSE_MACABRE,
  SPELL_DARKNESS,
  SPELL_DARKVISION,
  SPELL_DAWN,
  SPELL_DELAYED_BLAST_FIREBALL,
  SPELL_DEMIPLANE,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISINTEGRATE,
  SPELL_DISPEL_MAGIC,
  SPELL_DISTORT_VALUE,
  SPELL_DIVINATION,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_DRACONIC_TRANSFORMATION,
  SPELL_DRACONIC_TRANSFORMATION_2021_04_14,
  SPELL_DRAGON_S_BREATH,
  SPELL_DRAWMIJ_S_INSTANT_SUMMONS,
  SPELL_DREAM,
  SPELL_DREAM_OF_THE_BLUE_VEIL,
  SPELL_DUST_DEVIL,
  SPELL_EARTH_TREMOR,
  SPELL_EARTHBIND,
  SPELL_EGO_WHIP_2019_11_25,
  SPELL_ELEMENTAL_BANE,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENEMIES_ABOUND,
  SPELL_ENERVATION,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_ERUPTING_EARTH,
  SPELL_ETHEREALNESS,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_EYEBITE,
  SPELL_FABRICATE,
  SPELL_FALSE_LIFE,
  SPELL_FAR_STEP,
  SPELL_FAST_FRIENDS,
  SPELL_FEAR,
  SPELL_FEATHER_FALL,
  SPELL_FEEBLEMIND,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_FAMILIAR,
  SPELL_FINGER_OF_DEATH,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FIREBALL,
  SPELL_FIZBAN_S_PLATINUM_SHIELD,
  SPELL_FIZBAN_S_PLATINUM_SHIELD_2021_04_14,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_STRIDE_2021_04_14,
  SPELL_FLAMING_SPHERE,
  SPELL_FLESH_TO_STONE,
  SPELL_FLY,
  SPELL_FOG_CLOUD,
  SPELL_FORCECAGE,
  SPELL_FORESIGHT,
  SPELL_FRIENDS,
  SPELL_FROST_FINGERS,
  SPELL_FROSTBITE,
  SPELL_GASEOUS_FORM,
  SPELL_GATE,
  SPELL_GEAS,
  SPELL_GENTLE_REPOSE,
  SPELL_GIFT_OF_GAB,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREASE,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GREEN_FLAME_BLADE,
  SPELL_GUARDS_AND_WARDS,
  SPELL_GUIDING_HAND_2017_04_03,
  SPELL_GUST,
  SPELL_GUST_OF_WIND,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HASTE,
  SPELL_HEALING_ELIXIR_2017_04_03,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_ICE_KNIFE,
  SPELL_ICE_STORM,
  SPELL_ICINGDEATH_S_FROST_2021_04_14,
  SPELL_ID_INSINUATION_2019_11_25,
  SPELL_IDENTIFY,
  SPELL_ILLUSORY_DRAGON,
  SPELL_ILLUSORY_SCRIPT,
  SPELL_IMMOLATION,
  SPELL_IMPRISONMENT,
  SPELL_INCENDIARY_CLOUD,
  SPELL_INCITE_GREED,
  SPELL_INFERNAL_CALLING,
  SPELL_INFESTATION,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2019_11_25,
  SPELL_INTELLECT_FORTRESS_2020_04_14,
  SPELL_INVESTITURE_OF_FLAME,
  SPELL_INVESTITURE_OF_ICE,
  SPELL_INVESTITURE_OF_STONE,
  SPELL_INVESTITURE_OF_WIND,
  SPELL_INVISIBILITY,
  SPELL_INVULNERABILITY,
  SPELL_JIM_S_GLOWING_COIN,
  SPELL_JIM_S_MAGIC_MISSILE,
  SPELL_JUMP,
  SPELL_KNOCK,
  SPELL_LEGEND_LORE,
  SPELL_LEOMUND_S_SECRET_CHEST,
  SPELL_LEOMUND_S_TINY_HUT,
  SPELL_LEVITATE,
  SPELL_LIFE_TRANSFERENCE,
  SPELL_LIGHT,
  SPELL_LIGHTNING_BOLT,
  SPELL_LIGHTNING_LURE,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MADDENING_DARKNESS,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_CIRCLE,
  SPELL_MAGIC_JAR,
  SPELL_MAGIC_MISSILE,
  SPELL_MAGIC_MOUTH,
  SPELL_MAGIC_WEAPON,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_POLYMORPH,
  SPELL_MASS_SUGGESTION,
  SPELL_MAXIMILIAN_S_EARTHEN_GRASP,
  SPELL_MAZE,
  SPELL_MELF_S_ACID_ARROW,
  SPELL_MELF_S_MINUTE_METEORS,
  SPELL_MENDING,
  SPELL_MENTAL_BARRIER_2019_11_25,
  SPELL_MENTAL_PRISON,
  SPELL_MESSAGE,
  SPELL_METEOR_SWARM,
  SPELL_MIGHTY_FORTRESS,
  SPELL_MIND_BLANK,
  SPELL_MIND_SLIVER,
  SPELL_MIND_SPIKE,
  SPELL_MIND_THRUST_2019_11_25,
  SPELL_MIND_THRUST_2020_04_14,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRAGE_ARCANE,
  SPELL_MIRROR_IMAGE,
  SPELL_MISLEAD,
  SPELL_MISTY_STEP,
  SPELL_MODIFY_MEMORY,
  SPELL_MOLD_EARTH,
  SPELL_MORDENKAINEN_S_FAITHFUL_HOUND,
  SPELL_MORDENKAINEN_S_MAGNIFICENT_MANSION,
  SPELL_MORDENKAINEN_S_PRIVATE_SANCTUM,
  SPELL_MORDENKAINEN_S_SWORD,
  SPELL_MOVE_EARTH,
  SPELL_NATHAIR_S_MISCHIEF,
  SPELL_NATHAIR_S_MISCHIEF_2021_04_14,
  SPELL_NEGATIVE_ENERGY_FLOOD,
  SPELL_NONDETECTION,
  SPELL_NYSTUL_S_MAGIC_AURA,
  SPELL_OTHERWORLDLY_FORM_2020_03_26,
  SPELL_OTILUKE_S_FREEZING_SPHERE,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_OTTO_S_IRRESISTIBLE_DANCE,
  SPELL_PASSWALL,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PHANTOM_STEED,
  SPELL_PLANAR_BINDING,
  SPELL_PLANE_SHIFT,
  SPELL_POISON_SPRAY,
  SPELL_POLYMORPH,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_PAIN,
  SPELL_POWER_WORD_STUN,
  SPELL_PRESTIDIGITATION,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PRISMATIC_WALL,
  SPELL_PROGRAMMED_ILLUSION,
  SPELL_PROJECT_IMAGE,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PSIONIC_BLAST_2019_11_25,
  SPELL_PSYCHIC_CRUSH_2019_11_25,
  SPELL_PSYCHIC_SCREAM,
  SPELL_PUPPET_2017_04_03,
  SPELL_PYROTECHNICS,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_RAY_OF_FROST,
  SPELL_RAY_OF_SICKNESS,
  SPELL_REMOVE_CURSE,
  SPELL_REVERSE_GRAVITY,
  SPELL_RIME_S_BINDING_ICE,
  SPELL_ROPE_TRICK,
  SPELL_SCATTER,
  SPELL_SCORCHING_RAY,
  SPELL_SCRYING,
  SPELL_SEE_INVISIBILITY,
  SPELL_SEEMING,
  SPELL_SENDING,
  SPELL_SENSE_EMOTION_2017_04_03,
  SPELL_SEQUESTER,
  SPELL_SHADOW_BLADE,
  SPELL_SHAPE_WATER,
  SPELL_SHAPECHANGE,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
  SPELL_SICKENING_RADIANCE,
  SPELL_SILENT_IMAGE,
  SPELL_SIMULACRUM,
  SPELL_SKILL_EMPOWERMENT,
  SPELL_SKYWRITE,
  SPELL_SLEEP,
  SPELL_SLEET_STORM,
  SPELL_SLOW,
  SPELL_SNARE,
  SPELL_SNILLOC_S_SNOWBALL_SWARM,
  SPELL_SOUL_CAGE,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPIDER_CLIMB,
  SPELL_SPIRIT_SHROUD,
  SPELL_SPIRIT_SHROUD_2020_03_26,
  SPELL_STEEL_WIND_STRIKE,
  SPELL_STINKING_CLOUD,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_STORM_SPHERE,
  SPELL_SUDDEN_AWAKENING_2017_04_03,
  SPELL_SUGGESTION,
  SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
  SPELL_SUMMON_ABERRATION,
  SPELL_SUMMON_CONSTRUCT,
  SPELL_SUMMON_DRACONIC_SPIRIT,
  SPELL_SUMMON_DRACONIC_SPIRIT_2021_04_14,
  SPELL_SUMMON_ELEMENTAL,
  SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_FEY,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SUMMON_FIEND,
  SPELL_SUMMON_FIENDISH_SPIRIT_2020_03_26,
  SPELL_SUMMON_GREATER_DEMON,
  SPELL_SUMMON_LESSER_DEMONS,
  SPELL_SUMMON_SHADOW_SPIRIT_2020_03_26,
  SPELL_SUMMON_SHADOWSPAWN,
  SPELL_SUMMON_UNDEAD,
  SPELL_SUMMON_UNDEAD_SPIRIT_2020_03_26,
  SPELL_SUNBEAM,
  SPELL_SUNBURST,
  SPELL_SWORD_BURST,
  SPELL_SYMBOL,
  SPELL_SYNAPTIC_STATIC,
  SPELL_TASHA_S_CAUSTIC_BREW,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  SPELL_TASHA_S_MIND_WHIP,
  SPELL_TASHA_S_OTHERWORLDLY_GUISE,
  SPELL_TELEKINESIS,
  SPELL_TELEPATHY,
  SPELL_TELEPORT,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_TENSER_S_FLOATING_DISK,
  SPELL_TENSER_S_TRANSFORMATION,
  SPELL_THOUGHT_SHIELD_2019_11_25,
  SPELL_THUNDER_STEP,
  SPELL_THUNDERCLAP,
  SPELL_THUNDERWAVE,
  SPELL_TIDAL_WAVE,
  SPELL_TIME_STOP,
  SPELL_TINY_SERVANT,
  SPELL_TOLL_THE_DEAD,
  SPELL_TONGUES,
  SPELL_TRANSMUTE_ROCK,
  SPELL_TRUE_POLYMORPH,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_UNSEEN_SERVANT,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_VITRIOLIC_SPHERE,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_FORCE,
  SPELL_WALL_OF_ICE,
  SPELL_WALL_OF_LIGHT,
  SPELL_WALL_OF_SAND,
  SPELL_WALL_OF_STONE,
  SPELL_WALL_OF_WATER,
  SPELL_WARDING_WIND,
  SPELL_WATER_BREATHING,
  SPELL_WATERY_SPHERE,
  SPELL_WEB,
  SPELL_WEIRD,
  SPELL_WHIRLWIND,
  SPELL_WISH,
  SPELL_WITCH_BOLT,
} = require('./../../spellIdList')

module.exports = [
  SPELL_ABI_DALZIM_S_HORRID_WILTING,
  SPELL_ABSORB_ELEMENTS,
  SPELL_ACID_SPLASH,
  SPELL_ACID_STREAM_2020_03_26,
  SPELL_AGANAZZAR_S_SCORCHER,
  SPELL_ALARM,
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_DEAD,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ANTIMAGIC_FIELD,
  SPELL_ANTIPATHY_SYMPATHY,
  SPELL_ARCANE_EYE,
  SPELL_ARCANE_GATE,
  SPELL_ARCANE_LOCK,
  SPELL_ASHARDALON_S_STRIDE,
  SPELL_ASTRAL_PROJECTION,
  SPELL_AUGURY,
  SPELL_BANISHMENT,
  SPELL_BESTOW_CURSE,
  SPELL_BIGBY_S_HAND,
  SPELL_BLADE_OF_DISASTER,
  SPELL_BLADE_WARD,
  SPELL_BLIGHT,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BLINK,
  SPELL_BLUR,
  SPELL_BOOMING_BLADE,
  SPELL_BURNING_HANDS,
  SPELL_CATAPULT,
  SPELL_CATNAP,
  SPELL_CAUSE_FEAR,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CHROMATIC_ORB,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CLAIRVOYANCE,
  SPELL_CLONE,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_CLOUDKILL,
  SPELL_COLOR_SPRAY,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_CONE_OF_COLD,
  SPELL_CONFUSION,
  SPELL_CONJURE_ELEMENTAL,
  SPELL_CONJURE_MINOR_ELEMENTALS,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_CONTINGENCY,
  SPELL_CONTINUAL_FLAME,
  SPELL_CONTROL_FLAMES,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WEATHER,
  SPELL_CONTROL_WINDS,
  SPELL_COUNTERSPELL,
  SPELL_CREATE_BONFIRE,
  SPELL_CREATE_HOMUNCULUS,
  SPELL_CREATE_MAGEN,
  SPELL_CREATE_UNDEAD,
  SPELL_CREATION,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CROWN_OF_STARS,
  SPELL_DANCING_LIGHTS,
  SPELL_DANSE_MACABRE,
  SPELL_DARKNESS,
  SPELL_DARKVISION,
  SPELL_DAWN,
  SPELL_DELAYED_BLAST_FIREBALL,
  SPELL_DEMIPLANE,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISINTEGRATE,
  SPELL_DISPEL_MAGIC,
  SPELL_DISTORT_VALUE,
  SPELL_DIVINATION,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_DRACONIC_TRANSFORMATION,
  SPELL_DRACONIC_TRANSFORMATION_2021_04_14,
  SPELL_DRAGON_S_BREATH,
  SPELL_DRAWMIJ_S_INSTANT_SUMMONS,
  SPELL_DREAM,
  SPELL_DREAM_OF_THE_BLUE_VEIL,
  SPELL_DUST_DEVIL,
  SPELL_EARTH_TREMOR,
  SPELL_EARTHBIND,
  SPELL_EGO_WHIP_2019_11_25,
  SPELL_ELEMENTAL_BANE,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENEMIES_ABOUND,
  SPELL_ENERVATION,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_ERUPTING_EARTH,
  SPELL_ETHEREALNESS,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_EYEBITE,
  SPELL_FABRICATE,
  SPELL_FALSE_LIFE,
  SPELL_FAR_STEP,
  SPELL_FAST_FRIENDS,
  SPELL_FEAR,
  SPELL_FEATHER_FALL,
  SPELL_FEEBLEMIND,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_FAMILIAR,
  SPELL_FINGER_OF_DEATH,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FIREBALL,
  SPELL_FIZBAN_S_PLATINUM_SHIELD,
  SPELL_FIZBAN_S_PLATINUM_SHIELD_2021_04_14,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_STRIDE_2021_04_14,
  SPELL_FLAMING_SPHERE,
  SPELL_FLESH_TO_STONE,
  SPELL_FLY,
  SPELL_FOG_CLOUD,
  SPELL_FORCECAGE,
  SPELL_FORESIGHT,
  SPELL_FRIENDS,
  SPELL_FROST_FINGERS,
  SPELL_FROSTBITE,
  SPELL_GASEOUS_FORM,
  SPELL_GATE,
  SPELL_GEAS,
  SPELL_GENTLE_REPOSE,
  SPELL_GIFT_OF_GAB,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREASE,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GREEN_FLAME_BLADE,
  SPELL_GUARDS_AND_WARDS,
  SPELL_GUIDING_HAND_2017_04_03,
  SPELL_GUST,
  SPELL_GUST_OF_WIND,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HASTE,
  SPELL_HEALING_ELIXIR_2017_04_03,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_ICE_KNIFE,
  SPELL_ICE_STORM,
  SPELL_ICINGDEATH_S_FROST_2021_04_14,
  SPELL_ID_INSINUATION_2019_11_25,
  SPELL_IDENTIFY,
  SPELL_ILLUSORY_DRAGON,
  SPELL_ILLUSORY_SCRIPT,
  SPELL_IMMOLATION,
  SPELL_IMPRISONMENT,
  SPELL_INCENDIARY_CLOUD,
  SPELL_INCITE_GREED,
  SPELL_INFERNAL_CALLING,
  SPELL_INFESTATION,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2019_11_25,
  SPELL_INTELLECT_FORTRESS_2020_04_14,
  SPELL_INVESTITURE_OF_FLAME,
  SPELL_INVESTITURE_OF_ICE,
  SPELL_INVESTITURE_OF_STONE,
  SPELL_INVESTITURE_OF_WIND,
  SPELL_INVISIBILITY,
  SPELL_INVULNERABILITY,
  SPELL_JIM_S_GLOWING_COIN,
  SPELL_JIM_S_MAGIC_MISSILE,
  SPELL_JUMP,
  SPELL_KNOCK,
  SPELL_LEGEND_LORE,
  SPELL_LEOMUND_S_SECRET_CHEST,
  SPELL_LEOMUND_S_TINY_HUT,
  SPELL_LEVITATE,
  SPELL_LIFE_TRANSFERENCE,
  SPELL_LIGHT,
  SPELL_LIGHTNING_BOLT,
  SPELL_LIGHTNING_LURE,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MADDENING_DARKNESS,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_CIRCLE,
  SPELL_MAGIC_JAR,
  SPELL_MAGIC_MISSILE,
  SPELL_MAGIC_MOUTH,
  SPELL_MAGIC_WEAPON,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_POLYMORPH,
  SPELL_MASS_SUGGESTION,
  SPELL_MAXIMILIAN_S_EARTHEN_GRASP,
  SPELL_MAZE,
  SPELL_MELF_S_ACID_ARROW,
  SPELL_MELF_S_MINUTE_METEORS,
  SPELL_MENDING,
  SPELL_MENTAL_BARRIER_2019_11_25,
  SPELL_MENTAL_PRISON,
  SPELL_MESSAGE,
  SPELL_METEOR_SWARM,
  SPELL_MIGHTY_FORTRESS,
  SPELL_MIND_BLANK,
  SPELL_MIND_SLIVER,
  SPELL_MIND_SPIKE,
  SPELL_MIND_THRUST_2019_11_25,
  SPELL_MIND_THRUST_2020_04_14,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRAGE_ARCANE,
  SPELL_MIRROR_IMAGE,
  SPELL_MISLEAD,
  SPELL_MISTY_STEP,
  SPELL_MODIFY_MEMORY,
  SPELL_MOLD_EARTH,
  SPELL_MORDENKAINEN_S_FAITHFUL_HOUND,
  SPELL_MORDENKAINEN_S_MAGNIFICENT_MANSION,
  SPELL_MORDENKAINEN_S_PRIVATE_SANCTUM,
  SPELL_MORDENKAINEN_S_SWORD,
  SPELL_MOVE_EARTH,
  SPELL_NATHAIR_S_MISCHIEF,
  SPELL_NATHAIR_S_MISCHIEF_2021_04_14,
  SPELL_NEGATIVE_ENERGY_FLOOD,
  SPELL_NONDETECTION,
  SPELL_NYSTUL_S_MAGIC_AURA,
  SPELL_OTHERWORLDLY_FORM_2020_03_26,
  SPELL_OTILUKE_S_FREEZING_SPHERE,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_OTTO_S_IRRESISTIBLE_DANCE,
  SPELL_PASSWALL,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PHANTOM_STEED,
  SPELL_PLANAR_BINDING,
  SPELL_PLANE_SHIFT,
  SPELL_POISON_SPRAY,
  SPELL_POLYMORPH,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_PAIN,
  SPELL_POWER_WORD_STUN,
  SPELL_PRESTIDIGITATION,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PRISMATIC_WALL,
  SPELL_PROGRAMMED_ILLUSION,
  SPELL_PROJECT_IMAGE,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PSIONIC_BLAST_2019_11_25,
  SPELL_PSYCHIC_CRUSH_2019_11_25,
  SPELL_PSYCHIC_SCREAM,
  SPELL_PUPPET_2017_04_03,
  SPELL_PYROTECHNICS,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_RAY_OF_FROST,
  SPELL_RAY_OF_SICKNESS,
  SPELL_REMOVE_CURSE,
  SPELL_REVERSE_GRAVITY,
  SPELL_RIME_S_BINDING_ICE,
  SPELL_ROPE_TRICK,
  SPELL_SCATTER,
  SPELL_SCORCHING_RAY,
  SPELL_SCRYING,
  SPELL_SEE_INVISIBILITY,
  SPELL_SEEMING,
  SPELL_SENDING,
  SPELL_SENSE_EMOTION_2017_04_03,
  SPELL_SEQUESTER,
  SPELL_SHADOW_BLADE,
  SPELL_SHAPE_WATER,
  SPELL_SHAPECHANGE,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
  SPELL_SICKENING_RADIANCE,
  SPELL_SILENT_IMAGE,
  SPELL_SIMULACRUM,
  SPELL_SKILL_EMPOWERMENT,
  SPELL_SKYWRITE,
  SPELL_SLEEP,
  SPELL_SLEET_STORM,
  SPELL_SLOW,
  SPELL_SNARE,
  SPELL_SNILLOC_S_SNOWBALL_SWARM,
  SPELL_SOUL_CAGE,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPIDER_CLIMB,
  SPELL_SPIRIT_SHROUD,
  SPELL_SPIRIT_SHROUD_2020_03_26,
  SPELL_STEEL_WIND_STRIKE,
  SPELL_STINKING_CLOUD,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_STORM_SPHERE,
  SPELL_SUDDEN_AWAKENING_2017_04_03,
  SPELL_SUGGESTION,
  SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
  SPELL_SUMMON_ABERRATION,
  SPELL_SUMMON_CONSTRUCT,
  SPELL_SUMMON_DRACONIC_SPIRIT,
  SPELL_SUMMON_DRACONIC_SPIRIT_2021_04_14,
  SPELL_SUMMON_ELEMENTAL,
  SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_FEY,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SUMMON_FIEND,
  SPELL_SUMMON_FIENDISH_SPIRIT_2020_03_26,
  SPELL_SUMMON_GREATER_DEMON,
  SPELL_SUMMON_LESSER_DEMONS,
  SPELL_SUMMON_SHADOW_SPIRIT_2020_03_26,
  SPELL_SUMMON_SHADOWSPAWN,
  SPELL_SUMMON_UNDEAD,
  SPELL_SUMMON_UNDEAD_SPIRIT_2020_03_26,
  SPELL_SUNBEAM,
  SPELL_SUNBURST,
  SPELL_SWORD_BURST,
  SPELL_SYMBOL,
  SPELL_SYNAPTIC_STATIC,
  SPELL_TASHA_S_CAUSTIC_BREW,
  SPELL_TASHA_S_MIND_WHIP,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  SPELL_TASHA_S_OTHERWORLDLY_GUISE,
  SPELL_TELEKINESIS,
  SPELL_TELEPATHY,
  SPELL_TELEPORT,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_TENSER_S_FLOATING_DISK,
  SPELL_TENSER_S_TRANSFORMATION,
  SPELL_THOUGHT_SHIELD_2019_11_25,
  SPELL_THUNDER_STEP,
  SPELL_THUNDERCLAP,
  SPELL_THUNDERWAVE,
  SPELL_TIDAL_WAVE,
  SPELL_TIME_STOP,
  SPELL_TINY_SERVANT,
  SPELL_TOLL_THE_DEAD,
  SPELL_TONGUES,
  SPELL_TRANSMUTE_ROCK,
  SPELL_TRUE_POLYMORPH,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_UNSEEN_SERVANT,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_VITRIOLIC_SPHERE,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_FORCE,
  SPELL_WALL_OF_ICE,
  SPELL_WALL_OF_LIGHT,
  SPELL_WALL_OF_SAND,
  SPELL_WALL_OF_STONE,
  SPELL_WALL_OF_WATER,
  SPELL_WARDING_WIND,
  SPELL_WATER_BREATHING,
  SPELL_WATERY_SPHERE,
  SPELL_WEB,
  SPELL_WEIRD,
  SPELL_WHIRLWIND,
  SPELL_WISH,
  SPELL_WITCH_BOLT,
]
