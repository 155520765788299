const arrify = require('arrify')

const { featureCollection } = require('./../constants/featureList')
const { pcClassIdBySubClassIdCollection } = require('./../constants/pcSubClassList')

module.exports = (featureId) => {
  const feature = featureCollection[featureId]

  if (feature) {
    if (feature.pcClassId) {
      return `/pc-class-catalog/${feature.pcClassId}#${featureId}`
    } else if (feature.pcSubClassId) {
      const [pcClassId] = arrify(pcClassIdBySubClassIdCollection[feature.pcSubClassId])

      return `/pc-class-catalog/${pcClassId}/${feature.pcSubClassId}#${featureId}`
    }
  }

  return '/404'
}
