module.exports = `
| Уровень |1|2|3|4|5|
|---------|-|-|-|-|-|
|  1      |1| | | | |
|  2      |2| | | | |
|  3–4    | |2| | | |
|  5–6    | | |2| | |
|  7–8    | | | |2| |
|  9–10   | | | | |2|
| 11–16   | | | | |3|
| 17–20   | | | | |4|
`
