const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_5,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    ABILITY_FALSE_APPEARANCE,
    ABILITY_SPIDER_CLIMB,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_ROPER,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Верёвочник',
  nameEn: 'Roper',
  id: CREATURE_ROPER,
  description: `Живущие в пещерах по всему Подземью ненасытные верёвочники рады всему, что могут поймать и сожрать. Верёвочники едят любых существ, от зверей Подземья до искателей приключений вместе с их снаряжением.

Верёвочник имеет вид сталактита или сталагмита, что позволяет ему атаковать внезапно. Существо может медленно двигаться, используя тысячи липких ресничек под своим основанием. Оно подкрадывается по стенам пещеры и вдоль потолков, выбирая лучшее место, чтобы атаковать.

# Охотники Подземья

Верёвочник является развитой и зрелой формой пронзателя, с которым его объединяет внешний вид и тактика охоты. Верёвочник может оставаться неподвижным на протяжении долгих часов, прикрывая свой единственный глаз, чтобы больше походить на каменное формирование. Существа, подошедшие слишком близко, удивляются, когда этот глаз распахивается, и в их направлении вылетают липкие хватающие щупальца. Верёвочник издаёт ужасные гортанные звуки, подтягивая борющихся жертв к смертельному укусу своих каменных зубов.

Верёвочник может переваривать практически всё, за исключением платины, драгоценных камней и магических вещей, которые могут быть извлечены из его глотки после смерти. Желудочный сок верёвочника также ценится алхимиками, которые используют его в качестве растворителя.

# Ослабляющие щупальца

Верёвочник имеет шесть утолщений вдоль тела, в которых находятся щупальца, липнущие ко всему, чего касаются. Каждое щупальце имеет похожие на волосы наросты, которые проникают в плоть врагов и вытягивают их силу, так что жертва может лишь слабо сопротивляться тянущему её верёвочнику. Если щупальце отрубается или уничтожается, верёвочник производит новое ему на замену.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 41,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_CLIMB]: 10,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 17,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного пещерного образования, такого как сталагмит`,
    },
    {
      name: 'Хваткие усики',
      description: `У ★СУЩЕСТВО★ может быть до шести усиков одновременно. Каждый усик можно атаковать (КД 20; 10 хитов; иммунитет к урону психической энергией и ядом). Уничтожение усика не причиняет урон ★СУЩЕСТВО★, который может в своём следующем ходу создать усик на замену уничтоженному. Усик также можно сломать, если существо действием совершит проверку Силы СЛ 15 и преуспеет.`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает четыре атаки _Усиками_, использует _Подтаскивание_ и совершает одну атаку _Укусом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Усик',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 50,
        hit: `Цель становится схваченной (СЛ высвобождения равна 15). Пока цель схвачена, она также обездвижена и совершает с помехой проверки Силы и испытания Силы, а ★СУЩЕСТВО★ не может использовать этот усик против другой цели`,
      },
    },
    {
      name: 'Подтаскивание',
      description: `★СУЩЕСТВО★ подтаскивает всех схваченных существ на расстояние до 25 футов к себе.`,
    },
  ],
  genderId: GENDER_MALE,
}
