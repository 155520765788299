const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  GEAR_PLAYING_CARD_SET,
} = require('./../../../../../gearIdList')
const {
  SPELL_WISH,
} = require('./../../../../../spellIdList')
const {
  CREATURE_AVATAR_OF_DEATH,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  FEATURE_DIVINE_INTERVENTION,
} = require('./../../../../../featureIdList')
const {
  MAGIC_ITEM_DECK_OF_MANY_THINGS,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_DECK_OF_MANY_THINGS,
  name: `Колода многих вещей`,
  nameEn: `Deck of Many Things`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_PLAYING_CARD_SET,
  rarity: MGC_RARITY_LEGENDARY,
  description: `Эта колода, находящаяся в коробке или мешочке, состоит из карт, сделанных из слоновой кости или пергамента. Большинство (75%) этих колод содержат только тринадцать карт, но остальные включают двадцать две.

Перед тем, как тянуть карты, Вы должны объявить, сколько собираетесь брать, а затем тяните их по одной (можете использовать модифицированную колоду обычных игральных карт). Карты, взятые сверх названного числа, не будут иметь эффекта. Как только Вы вытянули карту, её магия начинает действовать. Вы должны тянуть следующую карту не позднее, чем через час после предыдущей. Если Вы не вытянули названное число карт, оставшиеся сами вылетают из колоды и вступают в силу одновременно.

Как только карта вытягивается, она исчезает. Если это не Дурак, и не Шут, карта вновь появляется в колоде, что позволяет вытянуть её повторно. 

| Игральная карта    | Карта           |
|--------------------|-----------------|
| Туз бубен          | Визирь *        |
| Король бубен       | Солнце          |
| Дама бубен         | Луна            |
| Валет бубен        | Звезда          |
| Двойка бубен       | Комета *        |
| Туз червей         | Судьба *        |
| Король червей      | Трон            |
| Дама червей        | Ключ            |
| Валет червей       | Рыцарь          |
| Двойка червей      | Драгоценность * |
| Туз треф           | Когти *         |
| Король треф        | Пустота         |
| Дама треф          | Огонь           |
| Валет треф         | Череп           |
| Двойка треф        | Юродивый *      |
| Туз пик            | Тюрьма *        |
| Король пик         | Руины           |
| Дама пик           | Эвриала         |
| Валет пик          | Плут            |
| Двойка пик         | Равновесие *    |
| Джокер (со знаком) | Дурак *         |
| Джокер (без знака) | Шут             |

__* Только в колоде из 22 карт__

# Карты

## Визирь

В любой момент в течение года, после того как Вы вытянули эту карту, Вы можете заняться медитацией и задать вопрос, чтобы получить мысленный правдивый ответ. Кроме простой информации ответ может помочь Вам решить головоломку или другую дилемму. Другими словами, приходит не только знание, но и мудрость, позволяющая этим знанием распорядиться.

## Драгоценность

Двадцать пять ювелирных украшений стоимостью 2 000 зм каждое или пятьдесят драгоценных камней стоимостью 1 000 зм каждый появляются у Ваших ног.

## Дурак

Вы теряете 10 000 опыта, сбрасываете эту карту и вытягиваете из колоды новую, считая их вместе за одну в объявленном Вами количестве вытягиваемых карт. Если потеря этого опыта заставила бы Вас потерять уровень, Вы вместо этого теряете такое количество, которое оставляет Вас только с достигнутым уровнем.

## Звезда

Увеличьте одну из Ваших характеристик на 2. Новое значение может превысить 20, но не должно превышать 24.

## Ключ

В Ваших руках появляется волшебное оружие с редкостью как минимум «редкое», навыком владения которым Вы обладаете (по выбору Мастера).

## Когти

Все магические предметы, которые Вы несёте и носите, распадаются. Ваши артефакты не уничтожаются, но исчезают.

## Комета

Если Вы в одиночку побеждаете следующее встретившееся Вам враждебное чудовище или группу чудовищ, Вы получаете достаточно опыта, чтобы получить следующий уровень. В противном случае эта карта не оказывает никакого эффекта.

## Луна

Вы получаете возможность 1к3 раза наложить заклинание [Исполнение желаний](SPELL:${SPELL_WISH}). 

## Огонь

Могущественный дьявол становится Вашим врагом. Он стремится Вас уничтожить, смакуя Ваши страдания перед попыткой убить Вас. Эта вражда длиться до Вашей или его гибели.

## Плут

НИП по выбору Мастера становится враждебным по отношению к Вам. Его личность Вам не известна до тех пор, пока он сам или кто-либо ещё не проявит это. Остановить эту вражду сможет как минимум заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) или [Божественное вмешательство](FEATURE:${FEATURE_DIVINE_INTERVENTION}).

## Пустота

Эта чёрная карта предсказывает катастрофу. Ваша душа вырывается из тела и заключается в некоем предмете в месте, выбранном Мастером. Это место охраняет сильное создание или даже группа таких созданий. Пока Ваша душа похищена, Ваше тело недееспособно. Заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) не может вернуть Вашу душу, но раскрывает местонахождение предмета, вмещающего Вашу душу. Вы больше не тянете карты.

## Равновесие

Ваше сознание претерпевает мучительные изменения, заставляя Ваше мировоззрение измениться. Принципиальное становится хаотичным, доброе становится злым, и наоборот. Если у Вас истинно нейтральное мировоззрение или у Вас нет мировоззрения, эта карта не оказывает никакого эффекта.

## Руины

Вы теряете все богатства, которые Вы носите или которыми владеете, кроме волшебных предметов. Движимое имущество исчезает. Бизнес, строения и земли, которыми Вы владели, пропадают таким способом, который оказывает наименьшее влияние на действительность. Любые документы, подтверждающие Ваши права собственности на утерянное имущество, тоже исчезают.

## Рыцарь

Вы получаете на службу воина 4 уровня, который появляется в пределах 30 футов от Вас (по Вашему выбору). Воин той же расы, что и Вы, и будет верно служить Вам до смерти, полагая, что судьба привела его к Вам. Этим персонажем управляете Вы.

## Солнце

Вы получаете 50000 опыта и в Ваших руках появляется чудесный предмет (на выбор Мастера).

## Судьба

Ткань реальности разворачивается и складывается вновь, позволяя Вам избежать или стереть один случай, как будто его никогда не было. Вы можете использовать магию карты сразу, как только вытянете её, или в любое другое время, пока не умрёте.

## Трон

Вы получаете владение навыком Убеждение, и удваиваете бонус мастерства при проверках этого навыка. Кроме того, Вы получаете право владения небольшой цитаделью где-то в мире. Однако сейчас эта крепость занята чудовищами, которых предстоит уничтожить, чтобы объявить цитадель своей.

## Тюрьма

Вы исчезаете и оказываетесь в состоянии приостановленной жизнедеятельности в межпространственной сфере. Все вещи, которые были у Вас или на Вас, остаются в том месте, где Вы были до исчезновения. Вы остаётесь заключёнными в эту тюрьму до тех пор, пока не будете найдены и освобождены. Вы не можете быть обнаружены с помощью школы Прорицания, но заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) способно определить Ваше местонахождение. Вы больше не тянете карты.

## Череп

Вы вызываете [образ смерти](CREATURE:${CREATURE_AVATAR_OF_DEATH}) — призрачного гуманоидного скелета, одетого в изодранную чёрную одежду и вооружённого призрачной косой. Он появляется в пределах 10 футов от Вас (в месте по выбору Мастера) и нападает на Вас, предупреждая всех, что Вы должны выиграть это сражение в одиночку. [Образ смерти](CREATURE:${CREATURE_AVATAR_OF_DEATH}) сражается до тех пор, пока Вы не погибнете, или его хиты не опустятся до 0. После этого он исчезает. Если кто-нибудь попытается помочь Вам, он вызовет этим собственный [образ смерти](CREATURE:${CREATURE_AVATAR_OF_DEATH}). Существо, убитое [образом смерти](CREATURE:${CREATURE_AVATAR_OF_DEATH}), не может быть возвращено к жизни.

## Шут

Вы получаете 10000 опыта или можете вытянуть две карты в дополнение к заявленному ранее количеству карт.

## Эвриала

Напоминающий медузу образ на карте проклинает Вас. Вы получаете штраф −2 к испытаниям, пока на Вас лежит это проклятье. Только бог или магия карты _Судьба_ может закончить действие этого проклятья.

## Юродивый

Уменьшите Ваш Интеллект на 1к4 + 1 (но не ниже 1). Вы можете вытянуть дополнительно ещё одну карту сверх заявленного ранее количества. 

# Примечание: враги

Две карты в колоде могут вызвать вражду другого существа. С картой Огонь вражда явная. Дьявол будет противостоять персонажу в многочисленных случаях. Поиск исчадия не должен быть простой задачей, и искатель приключений должен несколько раз столкнуться с союзниками и последователями дьявола, прежде чем сможет сразиться с ним самим.

В случае карты Плут вражда будет тайной, она будет проистекать от кого-то, кажущегося другом или союзником. Вы, как Мастер, должны ждать соответствующего драматического момента, чтобы показать эту вражду, заставляя персонажа гадать, кто же станет предателем.`,
  genderId: GENDER_FEMALE,
  spellIdList: [SPELL_WISH],
  source: {
    id: SOURCE_DMG,
    page: 171,
  },
}
