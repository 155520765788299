import React, { useEffect, useCallback, useMemo } from 'react'

import Counter from '@/ads/singletones/Counter'
import checkIsProduction from '@/utils/checkIsProduction/checkIsProduction'

import AdsPlacerComponent from './AdsPlacerComponent'

// Обновлять баннер РСЯ каждые 31 секунду (не чаще раза в 30 секунд по правилам РСЯ)
const UPDATE_BANNER_INTERVAL = 31000

const counter = new Counter()

const AdsPlacerContainer = (
  {
    blockId,
    incrementIndex = false,
    selfReload = false,
    type = '',
    ...rest
  }
) => {
  const index = useMemo(
    () => incrementIndex
      ? counter.increment()
      : 0,
    [incrementIndex]
  );

  const elementId = `yandex_rtb_${blockId}_${index}`

  const renderAd = useCallback(
    () => {
      const options = {
        blockId,
        renderTo: elementId,
      }

      window.yaContextCb.push(() => {
        Ya.Context.AdvManager.render(
          type ? {
            ...options,
            type,
          }
          : options
        );
      });
    },
    [blockId, type]
  )

  const selfReloadAd = useCallback(() => {
      renderAd();
      setInterval(renderAd, UPDATE_BANNER_INTERVAL);
    },
    [renderAd]
  )

  const IS_PRODUCTION = checkIsProduction()

  if (IS_PRODUCTION) {
    useEffect(
      selfReload
        ? selfReloadAd
        : renderAd,
      []
    );
  }

  return (
    <AdsPlacerComponent
      blockId={blockId}
      elementId={elementId}
      showSkeleton={!IS_PRODUCTION}
      {...rest}
    />
  )
}

export default AdsPlacerContainer
