const {CREATURE_TYPE_HALF_ORC} = require('./../../creatureTypeIdList')
const {
  gruumshMarkDescription,
  uncommonRaces,
} = require ('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  PC_RACE_HUMAN,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../sourceList')
const {PC_CLASS_WARLOCK} = require ('./../../pcClassIdList')
const {GOD_GRUUMSH} = require ('./../../godIdList')

module.exports = {
  id: CREATURE_TYPE_HALF_ORC,
  nameEn: 'Half-orc',
  genderId: GENDER_MALE,
  notRealRace: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'полуорк',
        genitive: 'полуорка',
        dative: 'полуорку',
        accusative: 'полуорка',
        instrumental: 'полуорком',
        prepositional: 'полуорке',
      },
      [GENDER_FEMALE]: {
        nominative: 'полуорчиха',
        genitive: 'полуорчихи',
        dative: 'полуорчихе',
        accusative: 'полуорчиху',
        instrumental: 'полуорчихой',
        prepositional: 'полуорчихе',
      },
    },
    plural: {
      nominative: 'полуорки',
      genitive: 'полуорков',
      dative: 'полуоркам',
      accusative: 'полуорков',
      instrumental: 'полуорками',
      prepositional: 'полуорках',
    },
  },
  note: [
    {
      text: `Вождь Муррен поднялся с мехов, на которых он лежал со своими женщинами, и натянул короткую кольчугу из тяжёлых стальных колец на свой мощный мускулистый торс. Обычно он вставал раньше своих воинов, так как в нём была сильна примесь человеческих кровей, и он лучше переносил дневной свет. У Кровавых Черепов о воине судили по его силе, бесстрашию и уму. Человеческое происхождение — не недостаток для воина, при условии, что он был таким же сильным, выносливым и кровожадным, как его чистокровные собратья. Полуорки, которые были слабее, чем их собратья, недолго прожили бы среди Кровавых Черепов, как и в любом другом орочьем племени. Но часто оказывалось, что человеческая кровь давала воину правильное сочетание хитрости, амбиций и самодисциплины, чтобы добиться многого, как это сделал Муррен. Он был хозяином племени, которое могло насчитать две тысячи копий, и имело сильнейшего вождя в Таре.`,
      author: 'Ричард Бейкер, Маг меча',
    },
    {
      text: `Мы — охотники в мире овец`,
      author: 'Калааш’аррна, сыщик Дома Тарашк',
    },
  ],
  description: [
    {
      header: 'Полуорки',
      text: `Находясь ли под предводительством могучего [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}), или стараясь установить мир после многолетнего конфликта, [орки](PC_RACE:${PC_RACE_ORC}) и племена [людей](PC_RACE:${PC_RACE_HUMAN}) иногда заключали союзы, объединяя силы в огромные орды, терроризирующие более цивилизованные государства по соседству. Когда такие союзы скреплялись узами брака, появлялись полуорки.

Некоторые полуорки возвышались, становясь гордыми вождями племён. Их [человечья](PC_RACE:${PC_RACE_HUMAN}) кровь давала им преимущество над их чистокровными соперниками. Другие отправлялись в мир, чтоб доказать своё превосходство над представителями более цивилизованных народов. Многие из них становились искателями приключений, достигая величия благодаря своим могучим свершениям, и дурной славы, благодаря варварским нравам и дикарской ярости.`,
      source: {
        id: SOURCE_PHB,
        page: 41,
      },
    },
    {
      header: 'Полуорки',
      text: `Полуорки живут сильными эмоциями и скорее склонны действовать, чем думать — ярость ли пылает в их жилах, требуя выхода в схватке, или любовь наполняет их сердца, побуждая к невероятно добрым поступкам.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: 'Крепкие и покрытые шрамами',
      text: `Серый цвет кожи, плоский лоб, выступающая челюсть, торчащие клыки и могучее телосложение не оставляет сомнений в орочьем происхождении для любого, кто смотрит на полуорка. Рост полуорков между 6 и 7 футами (183 и 213 сантиметрами), а вес обычно колеблется между 180 и 250 фунтов (80 и 113 килограммами).

[Орки](PC_RACE:${PC_RACE_ORC}) гордятся своими боевыми шрамами, а узоры, составленные из шрамов, украшают их тела. Другие шрамы могут отмечать бывшего раба или изгнанника. Все полуорки, живущие с [орками](PC_RACE:${PC_RACE_ORC}), или поблизости от них, имеют шрамы, следы унижений и поводы для гордости, отмечающие их победы и ранения. Живущие же среди [людей](PC_RACE:${PC_RACE_HUMAN}) полуорки могут как выставлять свои шрамы на обозрение с гордостью, так и со стыдом прятать их.`,
      source: {
        id: SOURCE_PHB,
        page: 41,
      },
    },
    gruumshMarkDescription,
    {
      header: 'Племена и трущобы',
      text: `Полуорки наиболее часто живут среди [орков](PC_RACE:${PC_RACE_ORC}). Среди других рас люди наиболее терпимо принимают полуорков, поэтому полуорки, которые не живут среди [орков](PC_RACE:${PC_RACE_ORC}), чаще всего живут среди [людей](PC_RACE:${PC_RACE_HUMAN}). Заслуживая ли признание среди жестоких варварских племен, или борясь за выживание в трущобах крупных городов, полуорки достигают всего своей физической мощью, выносливостью и решительностью, унаследованной от [человеческих](PC_RACE:${PC_RACE_HUMAN}) предков. `,
      source: {
        id: SOURCE_PHB,
        page: 42,
      },
    },
    {
      header: 'Имена полуорков',
      text: `Полуорки обычно имеют имена, соответствующие культуре, в которой они были выращены. Полуорк, который хочет находиться среди [людей](PC_RACE:${PC_RACE_HUMAN}), может поменять [орочье](PC_RACE:${PC_RACE_ORC}) имя на [человеческое](PC_RACE:${PC_RACE_HUMAN}). Некоторые полуорки с [человеческими](PC_RACE:${PC_RACE_HUMAN}) именами решают принять гортанное орочье имя, потому что считают, что это сделает их более устрашающими.

# Мужские орочьи имена

Гел, Денч, Имш, Кет, Краск, Муррен, Ронт, Токк, Фенг, Хенк, Холг, Шамп

# Женские орочьи имена

Багги, Вола, Волен, Евельда, Кансиф, Мев, Нига, Овак, Оунка, Сута, Шаута, Эмен, Энгонг`,
      source: {
        id: SOURCE_PHB,
        page: 42,
      },
    },
    uncommonRaces,
    {
      header: 'Неприязненное отношение',
      text: `Каждый полуорк находит способ, как добиться признания тех, кто ненавидит [орков](PC_RACE:${PC_RACE_ORC}).

Некоторые из них скрытны, и стараются не привлекать к себе никакого внимания.

Некоторые из них демонстрируют благочестие и добросердечность настолько публично, насколько это возможно (независимо от того, насколько искренни эти демонстрации).

А некоторые просто стараются быть настолько жестокими, что остальные просто избегают их. `,
      source: {
        id: SOURCE_PHB,
        page: 42,
      },
    },
    {
      header: 'Полуорки',
      text: `Полуорки существовали в мире с самого рассвета истории, когда [орки](PC_RACE:${PC_RACE_ORC}) и [люди](PC_RACE:${PC_RACE_HUMAN}) впервые встретились. И всё же, на протяжении всего этого времени они не могли найти себе места в цивилизации Фаэруна — или, если быть более точным, то цивилизация никогда не давала им такой возможности.

У большинства [людей](PC_RACE:${PC_RACE_HUMAN}) отвращение к полуоркам вызвано их внешностью: любой, кто настолько похож на [орка](PC_RACE:${PC_RACE_ORC}), думают они, должно быть похож на [орка](PC_RACE:${PC_RACE_ORC}) во всём, и потому его следует держать на расстоянии. Из-за того, что полуорки обычно обладают большей силой чем их человеческие сверстники, они могут найти работу в городах, но их внешность делает их изгоями. В ответ, будучи отверженными, полуорки принимают своё отличие и гордятся своим физическим превосходством, отступают в тень в попытке не привлекать к себе лишнего внимания, или же пытаются где бы то ни было вписаться в быт и принимают кочевой образ жизни.

Полуорки Фаэруна имеют расовые черты полуорков из Книги Игрока. Они говорят на Всеобщем и Орочьем. В редких экземплярах орочьей письменности используются Детекский алфавит.`,
      source: {
        id: SOURCE_SCAG,
        page: 118,
      },
    },
    {
      header: 'Кровь подскажет',
      text: `Полуорки в общем-то обязаны своим положением божеству [Груумшу](GOD:${GOD_GRUUMSH}), создателю [орков](PC_RACE:${PC_RACE_ORC}). Легенда гласит, что [Груумш](GOD:${GOD_GRUUMSH}), обнаружив, что все территории уже заняты другими расами, поклялся, что [орки](PC_RACE:${PC_RACE_ORC}) отомстят за себя и возьмут всё желаемое силой. Великие орочьи орды продолжают делать именно так и по сей день, периодически появляясь из дикой местности для грабежа и поборов.

Результатом этого стало то, что в умах фаэрунцев нет положительно окрашенного смысла для слова «орк». В то же время, слово «полу» является меткой позора среди [орков](PC_RACE:${PC_RACE_ORC}). Некоторые полуорки, выросшие среди [орков](PC_RACE:${PC_RACE_ORC}) реагируют на это клеймо становясь более брутальными, чем остальные представители племени, что выводит их в лидеры. Чужаков полуорков не жалуют в орочьем обществе из-за их нечистокровности, а другие общества не приемлют полуорков из-за их орочьего наследия.

Полуорки видятся другим народам неприятными и пугающими, что может быть как благом, так и проклятием, потому что их часто оставляют в покое те, кто их боится, но и так же часто они становятся целью дискриминации и необоснованных нападок со стороны тех, кто чувствует от них угрозу. Это предубеждение против их расы делает полуорков недоверчивыми даже с теми, кто с ними учтив — у каждого полуорка найдётся парочка историй, связанных с тем как их обмануло такое отношение. Их орочья кровь — Метка [Груумша](GOD:${GOD_GRUUMSH}) — делает их вспыльчивыми и скорыми на расправу над теми, кто поступает с ними не по чести.

Вырастая среди других [орков](PC_RACE:${PC_RACE_ORC}) или в тени их наследия, полуорки редко помнят что-то хорошее от общества, и потому становятся непристойными, прямолинейными и грубыми в отношении других народов. С орочьей прямолинейностью, они говорят то, что думают, без размышлений на счёт того, как будет это воспринято. Не важно, где они живут, о полуорках обычно думают, как о полезной тяжёлой рабочей силе и хороших солдатах. Лишь немногие из полуорков могут похвастаться суждениями к ним по делам, а не по их происхождению.`,
      source: {
        id: SOURCE_SCAG,
        page: 118,
      },
    },
    {
      header: 'Родные земли полуорков',
      text: `В землях вдали от Побережья Мечей, таких как Теск и Чессента, существуют большие общины полуорков, где из поколения в поколение они вольно живут. Впрочем, несколько таких мест существует и на Севере. Небольшая община выросла близ Королевства Много Стрел, но недавняя война с местными [орками](PC_RACE:${PC_RACE_ORC}) привела к тому, что растущая община вынуждена была распасться.

На сегодняшний день нет ни одного цивилизованного места на Севере, где существует значительное население полуорков, впрочем, как минимум парочка их всегда обитает в или на окраинах любого стабильного поселения. По иронии, чем больше город и его цивилизованность, тем больше шансов того, что там полуорки будут нормально восприняты — в больших городах люд более космополитичен в своих взглядах.

В Глубоководье, к примеру, процент полуорочьего очень мал, но всё же их реальное количество измеряется сотнями. Полуорки называющие Глубоководье своим домом ценят это приятие, или хотя бы толерантность, которую оказывает им город вне зависимости от того, родились ли они там, прибыли по суше из глубины континента или по морю, торговыми судами`,
      source: {
        id: SOURCE_SCAG,
        page: 118,
      },
    },
  ],
}
