const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_YAKFOLK,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_YIKARIA,
} = require('./../../../../languageIdList')
const {
  GEAR_GREATSWORD,
  GEAR_HIDE_ARMOR,
  GEAR_LONGBOW,
} = require('./../../../../gearIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_YAKFOLK_WARRIOR} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_SKT} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_DISPEL_EVIL_AND_GOOD} = require('./../../../../spellIdList')
const {yakfolkDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Воин яколюдов',
  nameEn: 'Yakfolk Warrior',
  id: CREATURE_YAKFOLK_WARRIOR,
  description: yakfolkDescriptionList,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_YAKFOLK,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_SKT,
    page: 248,
  },
  armor: {
    ac: 11,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 15,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_YIKARIA,
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Одержимость',
      comment: 'восстанавливается после короткого или длинного отдыха',
      description: `★СУЩЕСТВО★ пытается магически овладеть гуманоидом или великаном. ★СУЩЕСТВО★ ★должен★ коснуться цели в течении короткого отдыха, или попытка провалится. В конце отдыха, цель должна пройти испытание Телосложения СЛ 12 или стать одержимой ★им★. ★СУЩЕСТВО★ исчезает со всем, что несёт и носит носит. Пока длится одержимость, цель находится без сознания, теряет контроль над телом и не воспринимает своё окружение. ★СУЩЕСТВО★ теперь контролирует тело и не может быть целью атак, заклинаний и других эффектов; при этом ★СУЩЕСТВО★ сохраняет свои мировоззрение, Интеллект, Мудрость, Харизму, и владения. В остальном используются параметры цели за исключением знаний, классовых способностей, черт и владений.

Одержимость длится до тех пор, пока хиты носителя не падают до 0, ★СУЩЕСТВО★ действием оканчивает эффект, или ★СУЩЕСТВО★ заставляют покинуть тело носителя с помощью эффекта такого заклинания, как например [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}). Когда одержимость заканчивается, ★СУЩЕСТВО★ появляется в свободном пространстве в пределах 5 футах от носителя, Ошеломляясь до конца своего следующего хода. Если носитель умирает, будучи одержимым, ★СУЩЕСТВО★ также умирает, и ★его★ тело не появляется.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки, либо _Двуручным мечом_, либо _Длинным луком_.`,
    },
    {
      name: 'Двуручный меч',
      // gearId: GEAR_GREATSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Двуручный лук',
      // gearId: GEAR_LONGBOW, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 2,
        range: {
          normal: 150,
          max: 600,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
        },
      },
    },
  ],
}
