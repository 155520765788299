const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROBE_OF_SCINTILLATING_COLORS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ROBE_OF_SCINTILLATING_COLORS,
  name: `Мантия сияющих цветов`,
  nameEn: `Robe of Scintillating Colors`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `У этой мантии 3 заряда, и она ежедневно восстанавливает 1к3 заряда на рассвете.

Пока Вы её носите, Вы можете действием потратить 1 заряд, чтобы на ней появился до конца Вашего следующего хода узор из завораживающих оттенков. В это время мантия испускает яркий свет в пределах 30 футов и тусклый свет в пределах ещё 30 футов.

Существа, видящие Вас, совершают по Вам броски атаки с помехой. Кроме того, когда свойство мантии активируется, все существа в области яркого света, видящие Вас, должны пройти испытание Мудрости СЛ 15, иначе они станут ошеломлёнными до окончания эффекта.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 182,
  },
}
