const {
  SOURCE_MM,
} = require('./../../../../sourceList');
const {
  CR_1_4,
} = require('./../../../../crList');
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList');
const {
  CREATURE_TYPE_GRIMLOCK,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList');
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList');
const {
  SPEED_WALK
} = require('./../../../../speedList');
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList');
const {
  LANG_UNDERCOMMON
} = require('./../../../../languageIdList');
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList');
const {
  SENSE_BLIND_VISION,
} = require('./../../../../senseList');
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_STEALTH
} = require('./../../../../skillList');
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA
} = require('./../../../../paramList');
const {
  CONDITION_BLINDED,
} = require('./../../../../conditionList');
const {
  GENDER_MALE,
} = require('./../../../../genderList');
const {
  ABILITY_KEEN_HEARING_AND_SMELL,
  ABILITY_STONE_CAMOUFLAGE,
} = require('../../../../creatureAbilityList');
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  PC_RACE_HUMAN
} = require('../../../../pcRaceIdList');
const {
  CREATURE_GRIMLOCK,
  CREATURE_MIND_FLAYER,
} = require('./../../../../creatureIdList');

module.exports = {
  name: 'Гримлок',
  nameEn: 'Grimlock',
  id: CREATURE_GRIMLOCK,
  description: [
    {
      header: 'Гримлок',
      text: `Деградирующие подземные гримлоки когда-то были [людьми](PC_RACE:${PC_RACE_HUMAN}), но поклонение [свежевателям разума](CREATURE:${CREATURE_MIND_FLAYER}), наряду со столетиями бродяжничества по Подземью, давным-давно превратили их в слепых чудовищных каннибалов.`,
      source: {
        id: SOURCE_MM,
        page: 76
      },
    },
    {
      header: 'Жалкие культисты',
      text: `Империя [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}) когда-то простиралась на множество миров, подчиняя бессчётные расы. Среди них были и [людские](PC_RACE:${PC_RACE_HUMAN}) культуры, чьи жрецы были извращены [свежевателями разума](CREATURE:${CREATURE_MIND_FLAYER}) с помощью контроля мыслей. Эти лидеры отвергли веру своих последователей, обратившись к [иллитидам](CREATURE:${CREATURE_MIND_FLAYER}), которых стали почитать как богохульных божеств.

Со временем, на ритуалах этих порабощённых [людей](PC_RACE:${PC_RACE_HUMAN}) выросли культы каннибалов, которые считают поедание мозга [свежевателем разума](CREATURE:${CREATURE_MIND_FLAYER}) священным обрядом. [Иллитиды](CREATURE:${CREATURE_MIND_FLAYER}) приказывали своим поклонникам похищать других разумных существ для жертвоприношений. Как только мозг жертвы поглощался, [свежеватель разума](CREATURE:${CREATURE_MIND_FLAYER}) отдавал безжизненное тело культистам.`,
      source: {
        id: SOURCE_MM,
        page: 76
      },
    },
    {
      header: 'Слепые охотники',
      text: `С рухнувшим правлением [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), их культы столкнулись с постоянным потоком врагов, когда-то бывших их жертвам. Культы нашли спасение в Подземье — мире их богов [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}). Прожив много столетий в мире без света, культисты научились выживать, используя остальные чувства. Со временем их глаза высохли, а веки закрылись навсегда.

Гримлок услышит едва уловимый топот и шёпот, эхом отдающийся в каменных проходах. Он может говорить в таких низких диапазонах, что большинство гуманоидов его не услышат. Запахи пота, плоти и крови пробуждают его аппетит, и он может отследить их, словно ищейка. Чтобы усилить эти чувства, гримлоки оставляют кровавые следы, кучи навоза и внутренности добычи подальше от своего логова. Когда чужаки проходят через эти зоны, все эти отвратительные запахи оседают на них, предупреждая гримлоков о приближении.

Для большинства существ слепота это огромный недостаток. Для гримлока, у которого усилены остальные чувства, слепота это дар. Его нельзя одурачить видимыми иллюзиями или образами. И он бесстрашен, пока преследует добычу.`,
      source: {
        id: SOURCE_MM,
        page: 76
      },
    },
    {
      header: 'Бесконечная война',
      text: `Гримлоки всё ещё чтят [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), служа им там, где это возможно. И они всё ещё помнят войну, что загнала их глубоко под землю. Для них она никогда не закончится. Они продолжают возвращаться на поверхность, похищая пленников для своих хозяев [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}).`,
      source: {
        id: SOURCE_MM,
        page: 76
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GRIMLOCK
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 76
  },
  armor: 11,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 12,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 6
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_BLINDED
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
      comment: 'или 10 фт., когда глух',
      isBlindOutside: true
    },
  ],
  cr: CR_1_4,
  languageList: [
    LANG_UNDERCOMMON
  ],
  featureList: [
    {
      name: 'Компенсация слепоты',
      description: `★СУЩЕСТВО★ не может использовать слепое зрение, будучи оглохшим и лишённым обоняния.`
    },
    ABILITY_KEEN_HEARING_AND_SMELL,
    ABILITY_STONE_CAMOUFLAGE
  ],
  actionList: [
    {
      name: 'Костяная дубина с шипами',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3
          },
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4
          }
        ]
      }
    }
  ],
  genderId: GENDER_MALE
};
