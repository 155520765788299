module.exports = [
  {
    text: `Отсутствие света порождает темноту. А отсутствие добра? В Подземье, впрочем, недостаток и того, и другого.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Думаете, это дварфы непреклонны и неприятны? Значит, вам ещё не встретился двергар.`,
    author: `Архимаг Морденкайнен`,
  },
]
