const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_GIANT_FIRE_BEETLE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гигантский огненный жук',
  nameEn: 'Giant Fire Beetle',
  id: CREATURE_GIANT_FIRE_BEETLE,
  description: `Гигантский огненный жук это ночное существо, получившее название из-за пары светящихся желёз, испускающих свет. Шахтёры и искатели приключений ценят этих существ, так как железы испускают свет в течение 1к6 дней после смерти жука. Чаще всего гигантские огненные жуки встречаются под землёй, а также в тёмных лесах.`,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 327,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 12,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 3,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  cr: CR_0,
  featureList: [
    {
      name: 'Свечение',
      description: `★СУЩЕСТВО★ испускает яркий свет в радиусе 10 футов и тусклый свет в пределах ещё 10 футов.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 1,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: -1,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
