const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_GLORY} = require('./../../../pcSubClassIdList')
const {
  SOURCE_MOOT,
  SOURCE_TCoE,
} = require('./../../../sourceList')
const {
  SPELL_COMMUNE,
  SPELL_COMPULSION,
  SPELL_ENHANCE_ABILITY,
  SPELL_FLAME_STRIKE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GUIDING_BOLT,
  SPELL_HASTE,
  SPELL_HEROISM,
  SPELL_MAGIC_WEAPON,
  SPELL_PROTECTION_FROM_ENERGY,
} = require('./../../../spellIdList')
const {
  FEATURE_AURA_OF_ALACRITY,
  FEATURE_CHANNEL_DIVINITY_INSPIRING_SMITE,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CHANNEL_DIVINITY_PEERLESS_ATHLETE,
  FEATURE_DIVINE_SMITE,
  FEATURE_GLORIOUS_DEFENSE,
  FEATURE_LIVING_LEGEND,
  FEATURE_OATH_OF_GLORY_SPELLS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_GLORY_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_COMMUNE,
      SPELL_COMPULSION,
      SPELL_ENHANCE_ABILITY,
      SPELL_FLAME_STRIKE,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_GUIDING_BOLT,
      SPELL_HASTE,
      SPELL_HEROISM,
      SPELL_MAGIC_WEAPON,
      SPELL_PROTECTION_FROM_ENERGY,
    ],
    source: [
      {
        id: SOURCE_MOOT,
        page: 30,
      },
      {
        id: SOURCE_TCoE,
        page: 77,
      },
    ],
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_PEERLESS_ATHLETE,
    name: `Божественный канал: Несравненный атлет`,
    nameEn: `Channel Divinity: Peerless Athlete`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), усиливая свой атлетизм.

Вы можете бонусным действием получить следующие преимущества на 10 минут.

* Вы получаете преимущество к проверкам Силы (Атлетика) и Ловкости (Акробатика).
* Вы можете нести, толкать, тащить и поднимать вес вдвое больше обычного.
* Дальность Ваших прыжков в длину и высоту увеличивается на 10 футов (эта дополнительная дистанция стоит передвижения, как обычно).`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_INSPIRING_SMITE,
    name: `Божественный канал: Вдохновляющая кара`,
    nameEn: `Channel Divinity: Inspiring Smite`,
    lvl: 3,
    text: `Сразу после того, как Вы нанесли существу урон [Божественной карой](FEATURE:${FEATURE_DIVINE_SMITE}), Вы можете бонусным действием использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}) и распределить временные хиты между существами по Вашему выбору в пределах 30 футов, включая себя.

Общее количество временных хитов равно 2к8 + Ваш уровень в этом классе. Вы распределяете их между существами по своему усмотрению.`,
    source: [
      {
        id: SOURCE_MOOT,
        page: 30,
      },
      {
        id: SOURCE_TCoE,
        page: 78,
      },
    ],
  },
  {
    id: FEATURE_AURA_OF_ALACRITY,
    name: `Аура быстроты`,
    nameEn: `Aura of Alacrity`,
    lvl: [7, 18],
    text: `Вы испускаете ауру, что наполняет Вас и Ваших спутников сверхъестественной скоростью, позволяя бежать строем через поле брани.

Ваша скорость ходьбы увеличивается на 10 футов. Кроме того, если Вы дееспособны, скорость ходьбы союзников, начинающих свой ход в пределах 5 футов от Вас, увеличивается на 10 футов до конца этого хода.

Когда Вы достигнете 18 уровня в этом классе, радиус ауры увеличится до 10 футов.`,
    source: [
      {
        id: SOURCE_MOOT,
        page: 30,
      },
      {
        id: SOURCE_TCoE,
        page: 78,
      },
    ],
  },
  {
    id: FEATURE_GLORIOUS_DEFENSE,
    name: `Славная защита`,
    nameEn: `Glorious Defense`,
    lvl: 15,
    text: `Вы можете обернуть защиту внезапным ударом.

Когда по Вам или видимому Вами в пределах 10 футов существу попадают атакой, Вы можете реакцией дать цели бонус к КД от этой атаки, что потенциально  может привести к промаху. Бонус равен Вашему модификатору Харизмы (минимум +1).

Если атакующее существо промахивается, Вы можете совершить по нему одну атаку оружием как часть этой реакции, если это существо находится в пределах дальность Вашего оружия.

Вы можете использовать эту способность число раз, равное Вашему модификатору Харизмы (минимум однажды) и восстановите все использования, завершив длинный отдых.`,
    source: [
      {
        id: SOURCE_MOOT,
        page: 30,
      },
      {
        id: SOURCE_TCoE,
        page: 78,
      },
    ],
  },
  {
    id: FEATURE_LIVING_LEGEND,
    name: `Живая легенда`,
    nameEn: `Living Legend`,
    lvl: 20,
    text: `Вы можете наполнять себя легендами о своих великих деяниях — как правдивыми, так и преувеличенными.

Вы можете бонусным действием получить следующие преимущества на 1 минуту.

* Вы наполняетесь неземным великолепием, получая преимущество ко всем проверкам Харизмы.
* Один раз на каждом своём ходу, когда Вы совершаете атаку и промахиваетесь, Вы можете изменить промах на попадание.
* Если Вы проваливаете испытание, то реакцией можете перебросить его. Вы обязаны использовать новый результат.

Использовав эту способность, Вы не сможете использовать её вновь, пока не завершите длинный отдых, если только не потратите для этого ячейку заклинания 5 круга.`,
    source: [
      {
        id: SOURCE_MOOT,
        page: 30,
      },
      {
        id: SOURCE_TCoE,
        page: 79,
      },
    ],
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_GLORY,
  })
)

