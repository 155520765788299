const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_12} = require('./../../../../crList')
const {
  CREATURE_GRAY_RENDER,
  CREATURE_RIDING_HORSE,
} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Серый рендер',
  nameEn: 'Gray Render',
  id: CREATURE_GRAY_RENDER,
  description: [
    `Любознательность движет серым рендером. Несмотря на свою неуклюжую форму и страшный аппетит, оно больше всего хочет связать себя с разумным существом и, соединившись с ним, посвятить свою жизнь защите этого существа. Благодаря великой силе и дикой природе, серые рендеры способны быть жестокими защитниками, но лишены малейшей проворности.`,
    {
      header: 'Распространяющее бедствие',
      text: `Серые рендеры размножаются путём формирования выделений на своих телах, которые, достигнув зрелости, отрываются и начинают собственную жизнь юными серыми рендерами. У этих чудищ нет никаких чувств перед своими молодыми отпрысками, и нет склонности собираться группой вместе со своими сородичами.`,
      source: {
        id: SOURCE_MTOF,
        page: 226,
      },
    },
    {
      header: 'Непредсказуемые союзники',
      text: `Побочным эффектом после спаривания, у каждого серого рендера появляется непреодолимое желание связаться с разумным существом. Когда оно сталкивается с подходящим хозяином, рендер воспевает ему странный выщёлкивающий клич в сопровождении с царапаньем об землю и демонстрации своего почтения. Сформировав связь, рендер во всём служит своему мастеру.

Хоть эта связь и может быть хорошим преимуществом, рендеры по своей природе непредсказуемы. В бою, рендер сражается со всей своей свирепостью и никогда сознательно не навредит своему хозяину, однако вне битвы, серый рендер может значительно усложнить своим присутствием ситуацию для компаньонов хозяина. Оно может следовать за хозяином, даже когда тому прикажут оставаться на месте; может уничтожить дом хозяина, создать дыры на корабле, убить [лошадей](CREATURE:${CREATURE_RIDING_HORSE}), атаковать любого, кому позавидует, и так далее. Серый рендер может быть неплохим спутником, но его поведение всегда непредсказуемо.

В таблице «Причуды серого рендера» представлены возможные причуды, которые могут быть определены случайным образом или выбраны по желанию.`,
      source: {
        id: SOURCE_MTOF,
        page: 226,
      },
    },
    {
      header: 'Причуды серого рендера',
      text: `
| к12 | Причуда                                                                          |
|-----|----------------------------------------------------------------------------------|
| 1   | Ненавидит лошадей и других ездовых животных                                      | 
| 2   | Громко рычит из-за ревности к любому, кто прикоснётся к его связанному существу  |
| 3   | Любит уютно устраиваться рядом со своим связанным существом                      | 
| 4   | Выкорчёвывает и жуёт деревья                                                     | 
| 5   | Иногда испускает слезоточивые газы                                               | 
| 6   | Приносит привязному существу часть добытого мяса                                 | 
| 7   | Безудержно копает почву                                                          | 
| 8   | Бросается на телеги и фургоны словно они ужасные монстры                         | 
| 9   | Воет во время дождя                                                              | 
| 10  | Жалобно скулит в темноте                                                         | 
| 11  | Зарывает найденные сокровища                                                     | 
| 12  | Гоняется за птицами, пытаясь схватить в воздухе, не замечая учинённые разрушения |
`,
      source: {
        id: SOURCE_MTOF,
        page: 226,
      },
    },
  ],
  note: {
    text: `Полагаю, что серые рендеры обязаны своим происхождением неоги, поскольку они часто находятся в их обществе. Скорее всего, те существа, что мы видим в дикой местности, когда-то были изгнаны разъярёнными неоги-хозяевами`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MTOF,
    page: 226,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 20,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 8,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_12,
  actionList: [
    {
      name: 'Мультиатака',
      description: `Серый рендер совершает три атаки: одну _Укусом_ и две _Когтями_.`,
    },
    {
      name: 'Укус',
      description: `Если цель — существо Среднего размера или меньше, она должна пройти испытание Силы СЛ 16, иначе же будет сбита с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 12,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 4,
            },
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 2,
              diceType: 6,
              diceBonus: 0,
              comment: `, если цель сбита с ног`,
            },
          ],
        ],
      },
    },
  ],
  reactionList: [
    {
      name: 'Кровавое неистовство',
      description: `Когда серый рендер получает урон, он совершает одну рукопашную атаку по случайному существу в пределах досягаемости, которое не является его хозяином.`,
    },
  ],
}
