const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require ('./../../sourceList')

module.exports = [
  {
    header: 'Кенку',
    text: `Кенку — пернатые гуманоиды, блуждающие по миру, как бродяги, движимые жадностью. Они могут превосходно имитировать любой ранее услышанный звук.`,
    source: {
      id: SOURCE_MM,
      page: 174,
    },
  },
  {
    header: 'Падшие стаи',
    text: `Кенку носят криво пошитые плащи, мантии и тряпьё. Эти одеяния покрывают мягкие, гладкие перья их тел, а также лысые руки и ноги. Они шагают осторожно, когда передвигаются на когтях, сделанных для обхвата ветвей деревьев и захвата добычи с необъятных высот. Двигаются они тише ветра, чтоб не привлечь лишнего внимания к своему постыдному виду.

Когда-то кенку парили в небесах, и в их распоряжении было небо и мелодичное щебетание птиц. Служа хозяину, чья личность давно всеми забыта, кенку возжелали блестящих безделушек его дома и захотели разговаривать, чтобы можно было лестью и обманом достать подобные сокровища. Похитив тайну речи из книги в хозяйской библиотеке, они задрапировались в лохмотья и стали выпрашивать красивые вещи. Когда их господин узнал об их жадности, он лишил их крыльев в качестве наказания, заставив их попрошайничать до скончания времён.`,
    source: {
      id: SOURCE_MM,
      page: 174,
    },
  },
  {
    header: 'Подражающая речь',
    text: `Кенку могут имитировать все звуки, которые слышали. Кенку, прося денег, может имитировать звук звенящих монет, а описывая рынок, может воспроизвести какофонию уличных торговцев, лающих собак, блеющих овец и криков уличных бродяжек. Имитируя голоса, они могут только повторять слова и фразы, которые слышали, а не создавать новые предложения. Разговор с кенку это выслушивание звуков и бессмысленных стихов.

Кенку говорят друг с другом почти таким же способом. Поскольку они владеют мастерством интерпретации взглядов и жестов друг друга, звуки, которые они создают, чтобы выразить сложные мысли или эмоции, могут быть краткими. Группы кенку также разрабатывают секретные коды. Например, мяуканье кошки может быть секретным кодом для _«Готовься нападать!»_ или _«Спасайся кто может!»_

Их талант к имитированию распространяется на почерк, и преступные организации часто используют кенку, чтобы подделывать документы. Если кенку совершает преступление, он может подделать доказательства, чтобы обвинить другое существо.`,
    source: {
      id: SOURCE_MM,
      page: 174,
    },
  },
  {
    header: 'Печаль бескрылых',
    text: `Все кенку тоскуют по способности летать, и поэтому наказания, которые они назначают друг к другу, часто включают ложные крылья. Такие тяжёлые деревянные крылья носят как клеймо позора. В качестве заключительного, трагического напоминания о крыльях, которые у них когда-то были, кенку осуществляют казни, швыряя приговорённых с высоких зданий или скал.`,
    source: {
      id: SOURCE_MM,
      page: 174,
    },
  },
  {
    header: 'Кенку',
    text: `Преследуемые древним преступлением, которое лишило их крыльев, кенку блуждают по миру как бродяги и грабители, живущие на отшибе человеческого общества. Кенку имеют зловещую репутацию, которая не совсем уж незаслуженна, но они могут оказаться ценными союзниками.`,
    source: {
      id: SOURCE_VGTM,
      page: 111,
    },
  },
  {
    header: 'Древнее проклятие',
    text: `Кенку однажды служили таинственной, могущественной сущности на другом плане существования. Некоторые полагают, что они были приспешниками Граз’зта, другие говорят, что они были разведчиками и исследователями Герцогов Ветра Аакуы. В любом случае, согласно легенде, кенку предали своего повелителя. Не сумев противиться соблазну красивого сверкающего сокровища, кенку спланировали украсть предмет и сбежать на Материальный план.

К сожалению для кенку, их повелитель раскрыл их план прежде, чем они смогли его осуществить. В ярости, эта сущность наложила на них три ужасных проклятия. Во–первых, любимые крылья кенку иссохли и отвалились, оставив их привязанными к земле. Во–вторых, за то, что их изобретательность и умения обернулись интригами против их покровителя, из их душ была вырвана искра творческого потенциала. И наконец, чтобы гарантировать, что кенку никогда не смогут выдать его тайны, их повелитель лишил их голоса. Как только эта сущность удовлетворилась наказанием, кенку были отпущены на Материальный план.

С тех пор кенку блуждают по миру. Они обосновываются в местах, которые принимают их, обычно мрачные города, которые переживают тяжелое время и наводнены преступностью.`,
    source: {
      id: SOURCE_VGTM,
      page: 111,
    },
  },
  {
    header: 'Мечты о полёте',
    text: `Больше чем что либо, кенку хотят вернуть свою способность летать. Каждый кенку рождается с желанием подняться в воздух, и те, кто изучает колдовство, делают это в надежде овладеть заклинаниями, которые позволят им лететь. Слухи о магических предметах, таких как ковры–самолеты, метлы, способные летать, и аналогичных предметах, пробуждают у кенку большое желание приобрести их себе.

Несмотря на отсутствие крыльев, кенку любят жить в башнях и других высоких строениях. Они ищут руины, которые простираются к небу, хотя им не хватает мотивации и креативности, чтобы сделать в них ремонт или укрепить их. Даже в этом случае, их легкий вес и размер позволяют им жить в хрупких строениях, которые обрушились бы под человеком или тем более орком.

Некоторые гильдии воров используют кенку как наблюдателей и посыльных. Кенку живут в самых высоких зданиях и башнях, контролируемых гильдией, что позволяет им, прячась на верхних уровнях, наблюдать за городом.`,
    source: {
      id: SOURCE_VGTM,
      page: 111,
    },
  },
  {
    header: 'Безнадежные плагиаторы',
    text: `В результате отсутствия у них творческого потенциала, кенку комфортнее быть прислужниками могущественного руководителя. Лидеры стай поддерживают дисциплину и минимизируют конфликты, но они не способны эффективно планировать или составлять долгосрочные планы.

Хотя они неспособны говорить своим собственным голосом, кенку отлично могут подражать любому звуку, который они слышат, от голоса халфлинга до шума камней, падающих вниз по склону. Однако, кенку не может создать новые звуки и может общаться только используя звуки, которые он уже слышал. Большинство кенку использует смесь из подслушанных фраз и звуковых эффектов, чтобы передать свои идеи и мысли.

К тому же, кенку неспособны придумывать новые идеи или создавать новые вещи. Кенку может скопировать существующий предмет с исключительным умением, что позволяет им стать превосходными ремесленниками и писцами. Они могут копировать книги, делать точные копии предметов, и преуспевать на других должностях, где от них требуется создавать большое количество одинаковых предметов. Немногие кенку находят удовлетворение в такой работе, так как их поиск свободного полета делает их малопригодными для занятий рутиной.`,
    source: {
      id: SOURCE_VGTM,
      page: 112,
    },
  },
  {
    header: 'Идеальные прислужники',
    text: `Кенку собираются в группы, называемые стаями. Стаю возглавляет самый старый и самый опытный Кенку с самым широким запасом знаний из которого можно черпать информацию, часто называемый Мастером.

Хотя кенку не может создать новую вещь, у них есть талант к изучению и запоминанию подробностей. Таким образом, амбициозный кенку может отличится как превосходный шпион и разведчик. Кенку, который знает хитрые схемы и планы, разработанные другими существами, может их использовать. У кенку отсутствует способность импровизировать или изменять план, но мудрый Мастер приводит в исполнение одновременно несколько планов, уверенный, что подчиненные будут в точности следовать приказам.

Поэтому многие кенку живут немудрёной жизнью, служа посыльными, шпионами, и наблюдателями для гильдий воров, бандитов и других преступных картелей. Сеть кенку может передать птичий щебет или подобный шум через город, предупреждая союзников о подходе патруля стражи или сообщая о наилучшей возможности для грабежа.

Так как кенку может точно воспроизвести любой звук, сообщения, которые они передают, редко меняются или теряют смысл. Посыльные-люди могли бы изменить слова или фразы и нечаянно исказить сообщение, но кенку с точностью воспроизводят то, что они слышали.`,
    source: {
      id: SOURCE_VGTM,
      page: 112,
    },
  },
  {
    header: 'Искатели приключений кенку',
    text: `Приключенец кенку — это обычно оставшийся в живых из стаи, которая понесла тяжелые потери, или редкий кенку, которому надоела жизнь преступника. Такие кенку более амбициозны и смелы, чем их товарищи. Другие начинают самостоятельные исследования тайн полета, овладевая магией, или пытаются раскрыть тайну своего проклятия и найти способ снять его.

У приключенцев кенку, несмотря на их относительную независимость, всё ещё есть склонность искать компаньона, чтобы подражать ему и следовать за ним. Кенку любят подражать голосу и словам своего компаньона.`,
    source: {
      id: SOURCE_VGTM,
      page: 112,
    },
  },
  {
    header: 'Имена кенку',
    text: `Учитывая, что кенку может копировать любой звук, их имена состоят из поразительного разнообразия звуков и шумов. Имена кенку в основном делятся на три нижеследующие категории, мужские и женские имена не отличаются.

Кенку головорезы, воины и бандиты используют как имя звук, издаваемый оружием, таким как лязг булавы о броню или звук ломающейся кости. Не–кенку обращаются к кенку, описывая этот звук. Примеры этого типа имен: Разбиватель, Лязгун, Разрезатель, и Грохотун.

Воры, мошенники и грабители кенку используют как имя крики животных, обычных для города. Таким образом кенку могут обращаться друг к другу, в то время как те, кто слышит их, принимают их за обыкновенных животных.

Не-кенку используют имена, которые относятся к издаваемому звуку или к животному, которому кенку подражает, такие как: Крысиный Скрежет, Свистун, Мышатник и Рычун.

Некоторые кенку отворачиваются от преступности, чтобы вести законные дела. Эти кенку используют как имя звуки, издаваемые при их ремесле. Моряк повторяет звук трепещущего паруса, в то время как кузнец подражает лязгу молотка о металл. Не-кенку дают им имена по ремесленным звукам, такие как Хлопок Паруса, Молоточник и Резатель.`,
    source: {
      id: SOURCE_VGTM,
      page: 112,
    },
  },
  {
    header: 'Отыгрыш кенку',
    text: `Если Вы играете кенку, постоянные попытки подражать шумам могут приводить к путанице или раздражать вместо того чтобы вызывать интерес. Вы можете просто описать звуки, которые издает Ваш персонаж и что он имеет в виду. Поясните намерения своего персонажа, если Вы не стремитесь сознательно к недопониманию или таинственности.

Вы можете сказать: _«Щелкун издает звук молотка медленно и ритмично постукивающего по камню, чтобы показать, насколько ему скучно. Он играет со своим кинжалом и изучает агента Альянса Лордов, сидящего в баре»_. Создание вокабуляра шумов, которые другие игроки должны будут расшифровать, может показаться веселой идеей, но на самом деле это может сильно отвлекать и замедлять игру.`,
    source: {
      id: SOURCE_VGTM,
      page: 113,
    },
  },
]
