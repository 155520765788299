const {SOURCE_PotA} = require('./../../sourceList')

module.exports = [
  {
    header: 'Культисты Воющей Ненависти',
    text: `Почти все служители культа воздуха — это идеалисты, которых привлекают возвышенные принципы, вроде отказа от материальных ценностей, личных пристрастий и физических утех. Некоторые из них вступили в культ из-за ложных слухов о том, что там они смогут научиться летать, укротить силу ветра или избавиться от лишнего веса. Независимо от причин, толкнувших их на этот путь, все эти люди рано или поздно становятся жертвами идеологической обработки культа. Они отдают секте свои накопления и жизненные силы: первые переходят в казну культа, а вторые стремительно тают в ходе изнурительных обрядов. Те же, кто смог пережить посвящение в культ, получают обещанное — ценой своей воли.

Как при вербовке новых членов, так и в сражениях со своими противниками культ Воющей Ненависти полагается на хитрость и ложь. Не стоит недооценивать культистов воздуха за их мечтательность и мистицизм: их противники должны помнить, что служители воздуха в любую минуту могут обрушить на них мощь ураганов и торнадо.

Культ Воющей Ненависти сотрудничает с культами огня и воды, но они не выносят служителей культа Чёрной Земли, считая их упрямыми, невежественными бездельниками, которые не имеют воображения и не способны мечтать.`,
    source: {
      id: SOURCE_PotA,
      page: 194,
    },
  },
]
