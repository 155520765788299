const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_MAGEN} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_MAGEN,
  nameEn: 'Magen',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'маген',
      genitive: 'магена',
      dative: 'магену',
      accusative: 'магена',
      instrumental: 'магеном',
      prepositional: 'магене',
    },
    plural: {
      nominative: 'магены',
      genitive: 'магенов',
      dative: 'магенам',
      accusative: 'магенов',
      instrumental: 'магенами',
      prepositional: 'магенах',
    },
  },
}
