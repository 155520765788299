const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SPELL_WISH} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_WISH,
  name: 'Исполнение желаний',
  nameEn: `Wish`,
  description: `_Исполнение желаний_ это самое мощное заклинание, которое могут сотворять смертные. Высказав вслух свои пожелания, Вы можете изменить основы мироздания.

Это заклинание может дублировать эффекты любого заклинания с уровнем не больше 8. Вы не обязаны выполнять требования этих заклинаний, включая стоимость дорогих компонентов. Заклинание просто начинает оказывать свой эффект.

В качестве альтернативы, Вы можете создать один из следующих эффектов на свой выбор:

* Вы создаёте один предмет, стоящий до 25 000 зм, не являющийся волшебным. Этот предмет не может превышать ни в одном измерении 300 футов, и он появляется в свободном пространстве, видимом Вами, на земле.
* Вы позволяете до двенадцати другим существам, видимым Вами, восстановить все хиты, и Вы оканчиваете все действующие на них эффекты, описанные в заклинании высшее восстановление.
* Вы даруете до десяти существам, видимым Вами, сопротивление к выбранному Вами виду урона.
* Вы даруете до десяти существам, видимым Вами, иммунитет к одному заклинанию или другому магическому эффекту на 8 часов. Например, Вы можете даровать себе и всем своим спутникам иммунитет к вытягивающей жизнь атаке лича.
* Вы отменяете одно недавно произошедшее событие, заставив перебросить любой бросок, совершённый в последнем раунде (включая Ваш последний ход). Реальность будет сформирована заново, согласно новому результату. Например, заклинание исполнение желаний может провалить успешное испытание противника, его критическое попадание, или проваленное испытание друга. Вы можете даровать помеху или преимущество к броску, и можете либо выбрать новый результат, либо оставить старый.

Вы можете попытаться получить эффект, отличный от приведённых примеров. Максимально точно передайте желание Мастеру. Мастер обладает широкой свободой выбора при вынесении суждения о том, что произошло; чем больше желание, тем больше вероятность того, что что-то пойдёт не так. Это заклинание может просто провалиться, желаемый эффект может осуществиться лишь частично, или же Вы можете претерпеть непредвиденные последствия из-за формулировки желания. Например, желание, чтобы злодей был мёртв, может зашвырнуть Вас вперёд во времени, фактически, выводя Вас из игры. Желание получить легендарный магический предмет или артефакт может мгновенно перенести Вас к его текущему обладателю.

Создание этим заклинанием любого эффекта кроме дублирования другого заклинания ослабляет Вас. Получив это ослабление, каждый раз до окончания длинного отдыха, когда Вы накладываете заклинание, Вы получаете урон некротической энергией 1к10 за каждый уровень этого заклинания. Этот урон никак нельзя отменить или уменьшить. Кроме того, Ваша Сила на 2к4 дня опускается до 3, если она была больше 3. За каждый день, в течение которого Вы только отдыхаете и не делаете ничего кроме лёгкой деятельности, оставшееся время сокращается на 2 дня. Кроме того, если Вы пережили это ослабление, существует 33-процентный шанс, что Вы больше никогда не сможете сотворять _исполнение желаний_.  `,
  lvl: 9,
  magicSchoolId: MAGIC_CONJURATION,
  range: -1,
  componentIdList: [CAST_VERBAL],
  source: [
    {
      id: SOURCE_PHB,
      page: 232,
    },
    {
      id: SOURCE_SRD,
      page: 193,
    },
  ],
}
