const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../languageIdList')

module.exports = {
  id: LANG_ALL_KNOWN_AT_LIFE,
  name: {
    nominative: 'Все языки, известные при жизни',
    genitive: 'Всех языков, известных при жизни',
    instrumental: 'Всеми языками, известными при жизни',
    prepositional: 'Всех языках, известных при жизни',
  },
  isReady: false,
  isRealLang: false,
}
