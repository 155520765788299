const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_THE_CROWN} = require('./../../../pcSubClassIdList')
const {SOURCE_SCAG} = require('./../../../sourceList')
const {
  SPELL_AURA_OF_VITALITY,
  SPELL_BANISHMENT,
  SPELL_CIRCLE_OF_POWER,
  SPELL_COMMAND,
  SPELL_COMPELLED_DUEL,
  SPELL_GEAS,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_WARDING_BOND,
  SPELL_ZONE_OF_TRUTH,
} = require('./../../../spellIdList')
const {
  FEATURE_CHANNEL_DIVINITY_CHAMPION_CHALLENGE,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CHANNEL_DIVINITY_TURN_THE_TIDE,
  FEATURE_DIVINE_ALLEGIANCE,
  FEATURE_EXALTED_CHAMPION,
  FEATURE_OATH_OF_THE_CROWN_SPELLS,
  FEATURE_UNYIELDING_SPIRIT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_THE_CROWN_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    lvl: 3,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_AURA_OF_VITALITY,
      SPELL_BANISHMENT,
      SPELL_CIRCLE_OF_POWER,
      SPELL_COMMAND,
      SPELL_COMPELLED_DUEL,
      SPELL_GEAS,
      SPELL_GUARDIAN_OF_FAITH,
      SPELL_SPIRIT_GUARDIANS,
      SPELL_WARDING_BOND,
      SPELL_ZONE_OF_TRUTH,
    ],
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_CHAMPION_CHALLENGE,
    name: `Божественный канал: Вызов чемпиона`,
    nameEn: `Channel Divinity: Champion Challenge`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), Вы бросаете вызов, который заставляет других существ сражаться с вами. Любое существо по Вашему выбору, которое может Вас видеть и находящееся не далее, чем в 30 футах от Вас, должно совершить испытание Мудрости.

При провале существо не может добровольно отойти от Вас дальше, чем на 30 футов. Этот эффект прекращается если существо недееспособно, умирает или отдаляется от Вас на расстояние, превышающее 30 футов.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_TURN_THE_TIDE,
    name: `Божественный канал: Повернуть вспять`,
    nameEn: `Channel Divinity: Turn the Tide`,
    lvl: 3,
    text: `Бонусным действием Вы можете поддержать раненых союзников с помощью Вашего [Божественного канала](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}).

Любое существо по Вашему выбору, способное слышать Вас и находящееся не далее, чем в 30 футах от Вас, может восстановить количество своих хитов равное 1к6 + Ваш модификатор Харизмы (минимум 1), но только в том случае, если текущее количество его хитов составляет менее половины от полного значения.`,
  },
  {
    id: FEATURE_DIVINE_ALLEGIANCE,
    name: `Божественная преданность`,
    nameEn: `Divine Allegiance`,
    lvl: 7,
    text: `Когда существо, находящееся в 5 футах от Вас получает урон, Вы можете использовать Вашу реакцию, чтобы магически заменить Ваше собственное здоровье на здоровье целевого существа таким образом, чтобы оно не получило урон. Вместо цели урон получаете Вы.

Этот урон невозможно понизить или предотвратить никаким образом.`,
  },
  {
    id: FEATURE_UNYIELDING_SPIRIT,
    name: `Непоколебимый дух`,
    nameEn: `Unyielding Spirit`,
    lvl: 15,
    text: `Испытания против паралича или ошеломления Вы делаете с преимуществом.`,
  },
  {
    id: FEATURE_EXALTED_CHAMPION,
    name: `Возвышенный чемпион`,
    nameEn: `Exalted Champion`,
    lvl: 20,
    text: `Ваше присутствие на поле битвы вдохновляет посвященных Вашему делу. Вы можете потратить действие, чтобы получить следующее преимущество на 1 час:

* Вы получаете сопротивление дробящему, колющему и рубящему урону от немагического оружия
* Ваши союзники в пределах 30 футов получают преимущество к испытаниям от смерти.
* Вы и Ваши союзники в пределах 30 футов получаете преимущество на испытания Мудрости.

Эффект преждевременно закончится, если Вы окажетесь недееспособным или умрёте.

Использовав эту способность, Вы не сможете использовать её вновь, пока не завершите длинный отдых.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_THE_CROWN,
    source: {
      id: SOURCE_SCAG,
      page: 133,
    },
  })
)
