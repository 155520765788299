const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SOURCE_PHB} = require('./../../../../../sourceList')
const {SPELL_SWIFT_QUIVER} = require('./../../../../../spellIdList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')

module.exports = {
  id: SPELL_SWIFT_QUIVER,
  name: 'Быстрый колчан',
  nameEn: `Swift Quiver`,
  description: `Вы так модифицируете свой колчан, что он начинает производить бесконечный запас немагических боеприпасов, которые сами прыгают в Вашу руку, когда Вы тянетесь за ними.

Пока заклинание активно, Вы в каждом своём ходу можете бонусным действием совершить две атаки оружием, использующим боеприпасы из колчана. Каждый раз, когда Вы совершаете такую дальнобойную атаку, Ваш колчан при помощи магии заменяет использованный Вами боеприпас таким же немагическим боеприпасом. Все боеприпасы, созданные этим заклинанием, распадаются, когда заклинание оканчивается. Если колчан перестаёт быть Вашей собственностью, заклинание оканчивается.`,
  lvl: 5,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `колчан, содержащий как минимум один боеприпас`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  needConcentration: true,
  source: {
    id: SOURCE_PHB,
    page: 214,
  },
}
