const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_DAY,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_RADIANT,
} = require('./../../../../../damageTypeList')
const {
  SPELL_FORBIDDANCE,
  SPELL_GATE,
  SPELL_PLANE_SHIFT,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FORBIDDANCE,
  name: 'Запрет',
  nameEn: `Forbiddance`,
  description: `Вы создаёте защиту от магического перемещения, покрывающую до 40 000 квадратных футов пола и 30 футов над полом. Пока заклинание активно, существа не могут телепортироваться в эту область и использовать порталы, например, созданные заклинанием [врата](SPELL:${SPELL_GATE}), чтобы входить в эту область. Это заклинание предотвращает и перемещения между планами, поэтому защищает от перемещений с Астрального Плана, Эфирного Плана, из Страны Фей, Царства Теней, а также от заклинания [уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}).

Кроме того, это заклинание причиняет урон всем существам вида, выбираемого Вами при его накладывании. Выберите один или несколько видов существ: исчадия, небожители, нежить, феи и элементали. Когда существо выбранного вида впервые за ход входит в пространство заклинания или начинает в нём ход, оно получает урон излучением или некротической энергией 5к10 (вид урона выбираете Вы при накладывании заклинания).

Когда Вы накладываете это заклинание, можете указать пароль. Существо, произносящее пароль при входе в эту местность, не получает урон от этого заклинания.

Пространство этого заклинания не может перекрываться с пространством другого заклинания _запрет_. Если Вы накладываете заклинание _запрет_ каждый день в течение 30 дней в одном и том же месте, заклинание длится, пока не будет рассеяно, и материальные компоненты последнего накладывания тратятся.`,
  lvl: 6,
  magicSchoolId: MAGIC_ABJURATION,
  range: 0,
  isRitual: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `немного святой воды, редкие благовония и толчёный рубин, стоящий как минимум 1 000 зм`,
  duration: { timeId: TIME_DAY, count: 1 },
  castTime: { timeId: TIME_MINUTE, count: 10 },
  effect: {
    damage: {
      type: [
        DAMAGE_RADIANT,
        DAMAGE_NECROTIC,
      ],
      halfOnSuccess: false,
      diceCount: 5,
      diceType: 10,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 227,
    },
    {
      id: SOURCE_SRD,
      page: 146,
    },
  ],
}
