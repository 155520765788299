const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {channelDivinityGuidedStrikeTemplate} = require('./../../featureCommonRawList')
const {
  SPELL_ARMOR_OF_AGATHYS,
  SPELL_BESTOW_CURSE,
  SPELL_CLOUDKILL,
  SPELL_COMMAND,
  SPELL_DOMINATE_BEAST,
  SPELL_DOMINATE_PERSON,
  SPELL_FEAR,
  SPELL_HOLD_PERSON,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_STONESKIN,
} = require('./../../../spellIdList')

const {
  FEATURE_AURA_OF_CONQUEST,
  FEATURE_CHANNEL_DIVINITY_CONQUERING_PRESENCE,
  FEATURE_CHANNEL_DIVINITY_GUIDED_STRIKE_OATH_OF_CONQUEST,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_INVINCIBLE_CONQUEROR,
  FEATURE_OATH_OF_CONQUEST_SPELLS,
  FEATURE_SCORNFUL_REBUKE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_CONQUEST_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_ARMOR_OF_AGATHYS,
      SPELL_BESTOW_CURSE,
      SPELL_CLOUDKILL,
      SPELL_COMMAND,
      SPELL_DOMINATE_BEAST,
      SPELL_DOMINATE_PERSON,
      SPELL_FEAR,
      SPELL_HOLD_PERSON,
      SPELL_SPIRITUAL_WEAPON,
      SPELL_STONESKIN,
    ],
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_CONQUERING_PRESENCE,
    name: `Божественный канал: Покоряющее присутствие`,
    nameEn: `Channel Divinity: Conquering Presence`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), чтобы источать ужасающее присутствие.

Действием Вы можете заставить каждое существо по Вашему выбору в пределах 30 футов пройти испытание Мудрости.

При провале, существо становится испуганным на 1 минуту. Испуганное существо может повторять это испытание в конце каждого своего хода, завершая эффект при успехе.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_GUIDED_STRIKE_OATH_OF_CONQUEST,
    lvl: 3,
    ...channelDivinityGuidedStrikeTemplate,
  },
  {
    id: FEATURE_AURA_OF_CONQUEST,
    name: `Аура покорения`,
    nameEn: `Aura of Conquest`,
    lvl: [7, 18],
    text: `Вы постоянно излучаете ауру угрозы, пока дееспособны. Аура распространяется на 10 футов от Вас в каждом направлении, но не проходит через полные укрытия.

Если существо испуганно Вами, его скорость падает до 0, пока оно находится в области действия ауры, и оно получает психический урон, равный половине Вашего уровня паладина, если начинает там свой ход.

На 18 уровне область этой ауры увеличивается до 30 футов.`,
  },
  {
    id: FEATURE_SCORNFUL_REBUKE,
    name: `Презрительное возмездие`,
    nameEn: `Scornful Rebuke`,
    lvl: 15,
    text: `Те, кто дерзнут Вас ударить, психически караются за свою наглость.

Когда существо попадает по Вам атакой, оно получает психический урон, равный Вашему модификатору Харизмы (минимум 1), если Вы дееспособны.`,
  },
  {
    id: FEATURE_INVINCIBLE_CONQUEROR,
    name: `Непобедимый покоритель`,
    nameEn: `Invincible conqueror`,
    lvl: 20,
    text: `Вы получаете способность овладевать необычайной воинской удалью.

Действием Вы можете магически превратиться в аватар покорения, получая следующие преимущества на 1 минуту:

* Вы получаете сопротивление ко всему урону.
* Когда Вы совершаете действие Атака в свой ход, Вы можете частью этого действия совершить одну дополнительную атаку.
* Ваши рукопашные атаки оружием наносят критические повреждения при результатах «19» и «20» на к20.

Как только Вы использовали эту способность, Вы не можете использовать её вновь пока не совершите длинный отдых.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST,
    source: {
      id: SOURCE_XGTE,
      page: 49,
    },
  })
)

