const {CREATURE_ZARIEL} = require('./../../creatureIdList')
const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Ангелы',
    text: `Ангелы это небожители, отправленные в другие планы, чтобы исполнить приказы своих богов и принести благо или горе. Их величественная красота заставляет увидевших их с благоговением падать ниц. Однако ангелы могут быть и разрушителями, и их появление может предвещать гибель в той же мере, что и стать сигналом надежды.`,
    source: {
      id: SOURCE_MM,
      page: 18,
    },
  },
  {
    header: 'Осколки божественного',
    text: `Ангелы формируются из астральной сущности добрых божеств, и потому эти существа наделены огромной силой и предвиденьем.

С неустанным рвением ангелы исполняют волю своих богов. Даже хаотично-добрые божества управляют законно-добрыми ангелами, понимая, что рациональные ангелы — лучший инструмент исполнения их воли. Ангел исполняет одну-единственную цель, установленную его божеством. Однако ангел не способен исполнять приказы, которые отклоняются от пути закона и добра.

Ангелы убивают злых существ без угрызений совести. Будучи воплощением добра и порядка, ангелы почти никогда не ошибаются в своём правосудии. Это качество порождает чувство превосходства ангела, и оно выходит на первый план, когда задача ангела противоречит целям другого существа. Ангел никогда не идёт на уступки и не отступает от цели. Когда ангела отправляют на помощь смертным, его отправляют не служить, а командовать. Поэтому добрые боги отправляют своих ангелов к смертным только в ответ на самые чудовищные обстоятельства.`,
    source: {
      id: SOURCE_MM,
      page: 18,
    },
  },
  {
    header: 'Падшие ангелы',
    text: `Моральный компас ангела дарит чувство непогрешимости, но и он иногда может дать сбой. Как правило, ангелы достаточно мудры, чтобы не поддаться на этот обман, но иногда гордость может толкнуть на совершение злого поступка. Неважно, намерено или случайно совершается проступок, он навсегда ложится пятном, отмечающим ангела как изгоя.

Падшие ангелы сохраняют свою силу, но теряют связь с породившим их божеством. Большинство падших ангелов принимает своё изгнание слишком близко, восстают против сил, которым они служили, и ищут власти в районах Бездны или занимают место среди других павших в иерархии Девяти Преисподних. [Зариэль](CREATURE:${CREATURE_ZARIEL}), правительница первого слоя Девяти Преисподних, именно из таких. Иногда, вместо бунта, некоторые из падших примиряются с судьбой, и живут в уединении на Материальном плане, принимая личину отшельников. Искупив свой грех, они могут стать могучими союзниками, посвящёнными правосудию и милосердию.`,
    source: {
      id: SOURCE_MM,
      page: 18,
    },
  },
  {
    header: 'Бессмертная природа',
    text: `Ангел не нуждается в еде, питье и сне.`,
    source: {
      id: SOURCE_MM,
      page: 18,
    },
  },
]
