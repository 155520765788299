const {
  GEAR_GRENADE_FRAGMENTATION,
  GEAR_GRENADE_LAUNCHER,
} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {PARAM_DEX} = require('./../../../paramList')
const {
  CAT_EJECTIVES,
  CAT_EXPLOSIVES,
  CAT_GRENADES,
  CAT_TECH_MODERN,
} = require('./../../../gearCategoryList')
const {
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_GRENADE_FRAGMENTATION,
  name: 'Граната, осколочная',
  nameEn: 'Grenade, fragmentation',
  nameByCase: {
    nominative: 'осколочная граната',
    genitive: 'осколочной гранаты',
    accusative: 'осколочную гранату',
    instrumental: 'осколочной гранатой',
  },
  description: {
    header: 'Осколочная граната',
    text: `Персонаж может действием кинуть гранату на расстояние до 60 футов. Имея [гранатомёт](GEAR:${GEAR_GRENADE_LAUNCHER}), можно запустить её на расстояние до 120 футов.

Все существа в пределах 20 футов от места взрыва осколочной гранаты должны пройти испытание Ловкости СЛ 15, получая при провале колющий урон 5к6 или половину этого урона при успехе.`,
    source: {
      id: SOURCE_DMG,
      page: 268,
    },
  },
  weight: 1,
  genderId: GENDER_FEMALE,
  damageType: DAMAGE_PIERCING,
  damage: {
    diceCount: 5,
    diceType: 6,
  },
  range: 20,
  saveThrow: {
    type: PARAM_DEX,
    dc: 15,
  },
  category: [
    CAT_EJECTIVES,
    CAT_EXPLOSIVES,
    CAT_GRENADES,
    CAT_TECH_MODERN,
  ],
  weaponPropList: [
    WEAPON_THROWN,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 60,
        max: 60,
      },
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
