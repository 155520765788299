const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_0,
} = require('./../../../../crList')
const {
  SIZE_TINY,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_HOMUNCULUS,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_CREATE_HOMUNCULUS,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  ABILITY_TELEPATHIC_BOND,
} = require('./../../../../creatureAbilityList')
const {
  homunculiDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_HOMUNCULUS,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гомункул',
  nameEn: 'Homunculus',
  id: CREATURE_HOMUNCULUS,
  createdBySpellId: SPELL_CREATE_HOMUNCULUS,
  description: [
    `Смешав глину, пепел, корень мандрагоры и кровь, можно с помощью [специального ритуала](SPELL:${SPELL_CREATE_HOMUNCULUS}) создать верного компаньона размером с белку.

Гомункул это конструкт, действующий как продолжение своего создателя с двусторонним обменом мыслей, чувств и языков через мистическую связь. У хозяина может быть только один гомункул одновременно (попытка создать ещё одного всегда проваливается), и когда умирает хозяин, умирает и гомункул.`,
    ...homunculiDescriptionList,
  ],
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_HOMUNCULUS,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 69,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 11,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_TELEPATHIC_BOND,
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения СЛ 10, иначе станет отравленной на 1 минуту. Если испытание провалено на 5 или больше единиц, цель вместо этого становится отравленной на 5 (1к10) минут, и пока отравлена таким образом, она лишена сознания.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
