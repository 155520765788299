const {CREATURE_TYPE_REBORN} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_REBORN,
  nameEn: 'Reborn',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'перерождённый',
        genitive: 'перерождённого',
        dative: 'перерождённому',
        accusative: 'перерождённого',
        instrumental: 'перерождённым',
        prepositional: 'перерождённом',
      },
      [GENDER_FEMALE]: {
        nominative: 'перерождённая',
        genitive: 'перерождённой',
        dative: 'перерождённой',
        accusative: 'перерождённую',
        instrumental: 'перерождённой',
        prepositional: 'перерождённой',
      },
    },
    plural: {
      nominative: 'перерождённые',
      genitive: 'перерождённых',
      dative: 'перерождённым',
      accusative: 'перерождённых',
      instrumental: 'перерождёнными',
      prepositional: 'перерождённых',
    },
  },
}
