const {
  SOURCE_GAME_BG_3,
  SOURCE_IDRotF,
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')
const {
  CREATURE_MIND_FLAYER,
  CREATURE_OGRE,
} = require('./../../creatureIdList')
const {PC_RACE_DWARF} = require('./../../pcRaceIdList')
const {GOD_ASMODEUS} = require('./../../godIdList')

module.exports = [
  {
    header: 'Двергары',
    text: `Тираничные двергары, также известные как серые дварфы, обитают в фантастических городах глубоко в Подземье. Используя древние знания [дварфов](PC_RACE:${PC_RACE_DWARF}) и мириады рабов, они неустанно трудятся над расширением своих подземных королевств.
    
Большинство двергаров (включая женщин) — лысые, а их кожа пепельно-серая. Они носят серые одежды, которые помогают слиться с камнем, и простые украшения, которые отражают их суровый и практичный нрав.`,
    source: {
      id: SOURCE_MM,
      page: 139,
    },
  },
  {
    header: 'Рабы господ',
    text: `Двергары когда-то были [дварфами](PC_RACE:${PC_RACE_DWARF}), до того как их жадность и бесконечные рыскания под землёй не привели их к встрече со [свежевателями разума](CREATURE:${CREATURE_MIND_FLAYER}). Поколениями находясь в плену иллитидов, дварфы, в конечном счёте, отвоевали свою независимость при помощи злого божества Ладугуэра. Однако рабство навсегда изменило их, сделав их дух тёмным, а самих двергаров такими же злыми, как и тираны, от которых они освободились. Несмотря на полученную свободу, двергары — суровые, пессимистичные и недоверчивые существа, которые всегда тяжело трудятся и жалуются, что не помнят, что значит быть счастливым или гордым. Их произведения и достижения долговечны, но лишены тепла, и им чуждо искусство.

Двергары воюют со своими [дварфийскими сородичами](PC_RACE:${PC_RACE_DWARF}) и другими подземными расами. Они заключают союзы, когда это выгодно, и нарушают их, когда в них более нет выгоды. Они берут рабов, чтобы те работали в Подземье, относясь к ним как к бесплатной рабочей силе и грубому эквиваленту валюты.`,
    source: {
      id: SOURCE_MM,
      page: 139,
    },
  },
  {
    header: 'Крепкие как камень',
    text: `Как и [дварфы](PC_RACE:${PC_RACE_DWARF}), двергары крепко сложены. Вдобавок к их физической выносливости, они обладают невероятной ментальной стойкостью ещё со времён рабства у [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}). Разум двергара — это крепость, способная отражать очарования, иллюзии и другие заклинания.`,
    source: {
      id: SOURCE_MM,
      page: 139,
    },
  },
  {
    header: 'Рождённые во тьме',
    text: `Подземье насыщено странными магическими силами, которые двергары впитали за поколения рабства. Двергары могут увеличиться в размере и силе на короткое время, становясь могучими воинами, размерами сравниваясь с [ограми](CREATURE:${CREATURE_OGRE}). Если же они столкнутся с противником, который им не по зубам, или во время шпионажа за существами, нарушающими их границы, двергары могут становиться невидимыми, сливаясь с тьмой.

Эоны, проведённые в Подземье, также отточили их тёмное зрение, позволяя им во тьме видеть вдвое дальше, чем другим [дварфам](PC_RACE:${PC_RACE_DWARF}). Однако за это приходится платить, ведь зрению двергаров угрожает солнечный свет.`,
    source: {
      id: SOURCE_MM,
      page: 139,
    },
  },
  {
    header: 'Дьявольский хозяин',
    text: `Известно, что [Асмодей, Владыка Девяти Преисподних](GOD:${GOD_ASMODEUS}), изображает богов двергаров, чтобы взращивать зло, тлеющее в сердцах серых дварфов. Предлагая им божественное провидение и отмщение их врагам, он в то же время подталкивает их к всё более тираническим действиям и скрывает свою истинную личину.`,
    source: [
      {
        id: SOURCE_MM,
        page: 139,
      },
      {
        id: SOURCE_IDRotF,
        page: 286,
      },
    ],
  },
  {
    header: 'Двергары',
    text: `Жестокие двергары жаждут не только уничтожить дварфов, но и свергнуть весь дварфийский пантеон, в отместку за то, что те оставили двергаров и позволили [свежевателям разума](CREATURE:${CREATURE_MIND_FLAYER}) поработить их. Для достижения своей цели двергары тренируют бойцов, способных выполнять различные роли.`,
    source: {
      id: SOURCE_MTOF,
      page: 170,
    },
  },
  {
    header: 'Двергары',
    text: `Порабощённые жуткими свежевателями разума двергары, обретя разум и свободу, стали грубыми и мрачными. Их скрытность и надменный нрав известны всем Подземью.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
]
