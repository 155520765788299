const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  PARAM_DEX,
} = require('./../../../../../paramList')
const {
  DAMAGE_ACID,
} = require('./../../../../../damageTypeList')
const {
  SPELL_ACID_SPLASH,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ACID_SPLASH,
  name: 'Брызги кислоты',
  nameEn: `Acid Splash`,
  description: `Вы кидаете кислотный шарик. Выберите одно видимое Вами в пределах дистанции существо (или два видимых Вами в пределах дистанции существа, находящихся в пределах 5 футов друг от друга). Цель должна пройти испытание Ловкости, иначе она получает урон кислотой 1к6.`,
  highLvlCast: `Урон этого заклинания увеличивается на 1к6, когда Вы достигаете 5 уровня (2к6), 11 уровня (3к6) и 17 уровня (4к6).`,
  lvl: 0,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_ACID,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 214,
    },
    {
      id: SOURCE_SRD,
      page: 114,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Добавлено требование видеть цель',
      page: 3,
    },
  ],
}
