module.exports = [
  ...require('./giantGodsCommon'),
  ...require('./godAnnamOnly'),
  ...require('./godGrolantorOnly'),
  ...require('./godMemnorOnly'),
  ...require('./godSkoraeusOnly'),
  ...require('./godStronmausOnly'),
  ...require('./godSurturOnly'),
  ...require('./godThrymOnly'),
]
