const {SOURCE_MM} = require('./../../sourceList')
const {SPELL_SPIKE_GROWTH} = require('./../../spellIdList')

const metalDragonDescriptionList = require('./dragonMetallic')

module.exports = [
  {
    header: 'Логово медного дракона',
    text: `Медные драконы обитают на сухих возвышенностях и холмах, обустраивая свои логова в узких пещерах. Ложные стены скрывают от случайных прохожих тайники с драгоценностями, предметами искусства и прочими сокровищами, которые собрал медный дракон за свою жизнь. Разные безделушки он специально раскидывает в открытых пещерах, чтобы завладеть вниманием искателей сокровищ, но отвлечь от истинного клада.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов:

* Дракон выбирает точку, видимую в пределах 120 футов. Каменные шипы вырастают из пола в пределах 20 футов от этой точки. Во всём остальном эффект идентичен заклинанию [шипы](SPELL:${SPELL_SPIKE_GROWTH}) и действует, пока дракон не использует это действия логова ещё раз, или пока он не умрёт.
* Дракон выбирает квадратную зону с длиной стороны 10 футов, видимую в пределах 120 футов. Земля в этой области становится грязью глубиной 3 фута. Все существа в этой области, когда появляется грязь, должны пройти испытание Ловкости СЛ 15, иначе завязнуть в грязи и будут обездвижены. Существо может действием совершить проверку Силы СЛ 15, при успехе освобождая себя или другое существо в пределах досягаемости, оканчивая обездвиженость. Перемещение на 1 фут по грязи равняется 2 футам движения. На следующий раунд, при инициативе «20», грязь затвердевает, а СЛ проверки Силы увеличивается до 20.

# Местные эффекты

Регион, в котором есть логово легендарного медного дракона, искажается драконьей магией, которая создаёт один или несколько следующих эффектов:

* Волшебные барельефы улыбающегося драконьего лика можно найти вырезанными в каменистой местности или предметах в пределах 6 миль от драконьего логова. 
* Крошечные животные, такие как грызуны и птицы, которые обычно не могут говорить, получают магическую способность говорить и понимать Драконий язык, пока находятся в пределах 1 мили от логова дракона. Эти существа хорошо отзываются о драконе, но не могут раскрыть его местонахождение.
* Разумные существа в пределах 1 мили от логова дракона склонны к хихиканью. Даже серьёзные вопросы вдруг кажутся забавными.

Если дракон умирает, магические изображения исчезают через 1к10 дней. Прочие эффекты исчезают немедленно.`,
    source: {
      id: SOURCE_MM,
      page: 132,
    },
  },
  {
    header: 'Медные драконы',
    text: `Медные драконы, обожающие розыгрыши, шутки и загадки, живут среди холмов и на каменистых возвышенностях. Несмотря на общительную и уравновешенную натуру, они очень жадные, и могут стать опасными, если их сокровищам угрожает опасность. У медных драконов есть сегментированные надбровные пластины, которые выступают над глазами и идут назад к длинным рогам. Изогнутые скулы и челюстные оборки придают им задумчивый вид. При рождении медный дракон покрыт ярко-коричневой с металлическим оттенком чешуёй. По мере взросления, чешуя принимает более медный цвет, а затем покрывается зеленоватым налётом. Зрачки у медных драконов исчезают с возрастом, и глаза старейших драконов выглядят как светящиеся бирюзовые сферы.

# Гостеприимность

Медный дракон ценит умные, хорошие шутки, истории и загадки с чувством юмора. Его раздражают существа, которые не смеются над его шутками или не веселятся над его розыгрышами.

Медные драконы особенно любят бардов. Дракон может выделить часть своего логова в качестве временного места жительства для барда, желая вкусить его истории, загадки и музыку. Для медного дракона такое соседство — желанное сокровище.

# Хитрый и осмотрительный

Для создания своего клада медный дракон предпочитает сокровища недр. Металлы и драгоценные камни приоритетны для этих существ.

Медный дракон осторожен, когда дело доходит до хвастовства богатствами. Если он знает, что кто-либо ищет конкретно часть его сокровищ, он не станет раскрывать детали. Вместо этого он отправит искателей сокровищ на ложные поиски и будет, потешаясь, наблюдать за ними издалека.`,
    source: {
      id: SOURCE_MM,
      page: 132,
    },
  },
  ...metalDragonDescriptionList,
]
