const uniqBy = require('lodash/uniqBy')

const generateRarityList = list => uniqBy(
  list.map(
    item => item.bonus
      ?  {
        value: item.rarity,
        comment: `(+${item.bonus})`,
      }
      : {
        value: item.rarity,
      }
  ),
  ({value}) => value
)

module.exports = generateRarityList
