const {
  SOURCE_ERFTLW,
} = require('./../../sourceList')
const {
  PC_RACE_HALFLING,
} = require('./../../pcRaceIdList')
const dinoDescriptionList = require('./dino')

module.exports = [
  {
    header: 'Когтеноги',
    text: `Когтеноги — двуногие ящеры с острыми зубами и смертоносными когтями на ногах, похожими как косы. Это внушительные существа, размеров со взрослого человека. Когтеноги достаточно яростные, чтобы завалить добычу даже больше себя размером.

Неприрученный когтеног в дикой природе — отличный боец. Но в стаях они становятся ещё яростнее. Когтеноги быстро обучаются, легко дрессируются и охотно реагируют на команды. Их инстинктивная тяга к стайности сделал этих динозавров традиционными боевыми скакунами для [полуросликов](PC_RACE:${PC_RACE_HALFLING}) равнин Таленты.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 290,
    },
  },
  ...dinoDescriptionList,
]
