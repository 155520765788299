const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_GRAVITY_FISSURE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GRAVITY_FISSURE,
  name: 'Гравитационный разлом',
  nameEn: 'Gravity Fissure',
  description: `Вы создаёте разрыв силы гравитации исходящей от Вас 100-футовой линией шириной 5 футов. Все существа на этой линии должны пройти испытание Телосложения, получая 8к8 урона силовым полем при провале или половину при успехе. Все существа, находящиеся в пределах 10 футов от этой линии, но не на ней, должны пройти испытание Телосложения или получить 8к8 урона силовым полем и их затянет в неё.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше шестого.`,
  lvl: 6,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  rangeComment: `100-футовая линия`,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `горсть железных опилок`,
  isDunamisBased: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 8,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_EGTW,
    page: 188,
  },
}
