const {PC_SUBCLASS_ROGUE_PHANTOM} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {
  FEATURE_DEATH_S_FRIEND,
  FEATURE_GHOST_WALK,
  FEATURE_SNEAK_ATTACK,
  FEATURE_TOKENS_OF_THE_DEPARTED,
  FEATURE_WAILS_FROM_THE_GRAVE,
  FEATURE_WHISPERS_OF_THE_DEAD,
} = require('./../../../featureIdList')


module.exports = [
  {
    id: FEATURE_WHISPERS_OF_THE_DEAD,
    name: `Шёпоты смерти`,
    nameEn: `Whispers of the Dead`,
    lvl: 3,
    text: `К Вам начинают льнуть отголоски умерших.

Заканчивая короткий или длинный отдых, Вы можете выбрать и получить владение одним навыком или инструментом, поскольку умершие делятся с Вами своими знаниями.

Это владение длится, пока Вы снова не воспользуетесь этой способностью.`,
    source: {
      id: SOURCE_TCoE,
      page: 93,
    },
  },
  {
    id: FEATURE_WAILS_FROM_THE_GRAVE,
    name: `Стоны из могилы`,
    nameEn: `Wails from the Grave`,
    lvl: 3,
    text: `Подталкивая кого-то ближе к могиле, Вы можете направить энергию смерти так, чтобы навредить кому-то ещё.

Немедленно после того, как Вы в свой ход нанесёте урон [Скрытой атакой](FEATURE:${FEATURE_SNEAK_ATTACK}) существу, Вы можете выбрать целью второе существо, видимое Вами в пределах 30 футов от первого. Бросьте половину количества костей [Скрытой атаки](FEATURE:${FEATURE_SNEAK_ATTACK}) (округлённое вверх), и второе существо получит некротический урон, равный сумме броска, поскольку на мгновение вокруг него раздаются вопли мертвых.

Вы можете использовать эту способность количество раз, равное своему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 94,
    },
  },
  {
    id: FEATURE_TOKENS_OF_THE_DEPARTED,
    name: `Части усопших`,
    nameEn: `Tokens of the Departed`,
    lvl: 9,
    text: `Когда жизнь обрывается в Вашем присутствии, Вы можете вырвать часть уходящей души, кусочек её жизненной сущности, принимающий физическую форму.

Реакцией, когда умирает видимое Вами в пределах 30 футов существо, Вы раскрываете свободную ладонь, и в ней появляется Крошечная безделушка, безделушка души. Мастер выбирает форму безделушки или просит Вас бросить по таблице «Безделушки» в Книге Игрока, чтобы определить её вид.

Количество безделушек души у Вас не может превышать Ваш бонуса мастерства, и Вы не можете создать их, пока используете максимум.

Вы можете использовать безделушки души следующими способами

* Пока безделушка души при Вас, Вы совершаете с преимуществом испытания от смерти и испытания Телосложения, так как Ваша жизнеспособность увеличивается за счёт жизненной сущности внутри объекта.
* Нанося на своём ходу урон Скрытой атакой, Вы можете уничтожить одну из своих безделушек души (из тех, что находятся при Вас) и немедленно использовать [Стоны из могилы](FEATURE:${FEATURE_WAILS_FROM_THE_GRAVE}), не тратя использования этой способности.
* Вы можете действием уничтожить одну из своих безделушек души, где бы она ни находилась. Сделав это, Вы можете задать духу, связанному с этой безделушкой, один вопрос. Дух является Вам и отвечает на языке, который он знал при жизни. Он не обязан быть правдивым и отвечает как можно более кратко, стремясь освободиться. Дух знает только то, что знал при жизни, это определяется Мастером.`,
    source: {
      id: SOURCE_TCoE,
      page: 94,
    },
  },
  {
    id: FEATURE_GHOST_WALK,
    name: `Призрачная походка`,
    nameEn: `Ghost Walk`,
    lvl: 13,
    text: `Теперь Вы можете частично смещаться в царство мёртвых, выглядя при этом как призрак.

Бонусным действием Вы принимаете призрачную форму. Будучи в этой форме, Вы получаете скорость полёта 10 футов, можете парить и все атаки по Вам совершаются с помехами. Также Вы можете двигаться сквозь существ и предметы, как если бы они были труднопроходимой местностью. Вы получаете 1к10 урона силовым полем, если оканчиваете ход внутри существа или предмета.

Вы остаётесь в этой форме до 10 минут или пока не окончите её бонусным действием.

Использовав эту способность, Вы не можете использовать её снова, пока не закончите длинный отдых, или пока не уничтожите одну из своих [безделушек души](FEATURE:${FEATURE_TOKENS_OF_THE_DEPARTED}) тем же бонусным действием, которым снова принимаете _Призрачную походку_.`,
    source: {
      id: SOURCE_TCoE,
      page: 94,
    },
  },
  {
    id: FEATURE_DEATH_S_FRIEND,
    name: `Друг смерти`,
    nameEn: `Death’s Friend`,
    lvl: 17,
    text: `Ваша связь со смертью стала столь близка, что Вы получаете следующие преимущества

* Когда Вы используете [Стоны из могилы](FEATURE:${FEATURE_WAILS_FROM_THE_GRAVE}), Вы можете наносить некротический урон обоим существам.
* Если в конце длинного отдыха у Вас нет безделушек души, то одна такая безделушка появляется у Вас в руке, потому как духи мёртвых тянутся к Вам.`,
    source: {
      id: SOURCE_TCoE,
      page: 94,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_PHANTOM,
  })
)

