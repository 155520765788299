const {FEATURE_UNDYING_LIGHT_EXPANDED_SPELLS} = require('./../../../featureIdList')
const {PC_SUBCLASS_WARLOCK_THE_UNDYING_LIGHT_2015_11_02} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_BURNING_HANDS,
  SPELL_DAYLIGHT,
  SPELL_FIRE_SHIELD,
  SPELL_FLAME_STRIKE,
  SPELL_FLAMING_SPHERE,
  SPELL_LIGHT,
  SPELL_SACRED_FLAME,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_UNDYING_LIGHT_EXPANDED_SPELLS,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_UNDYING_LIGHT_2015_11_02,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_BURNING_HANDS,
      SPELL_DAYLIGHT,
      SPELL_FIRE_SHIELD,
      SPELL_FLAME_STRIKE,
      SPELL_FLAMING_SPHERE,
      SPELL_LIGHT,
      SPELL_SACRED_FLAME,
    ],
    source: {
      id: SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK,
      page: 3,
    },
  },
]
