const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_LONGBOW,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_KEEN_HEARING_AND_SIGHT} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_SCOUT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Разведчик',
  nameEn: 'Scout',
  id: CREATURE_SCOUT,
  description: `Разведчики это умелые охотники и следопыты, предлагающие свои услуги за оплату. Большая их часть охотится на диких животных, но некоторые также являются охотниками за головами, либо служат проводниками или военными разведчиками.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 349,
  },
  armor: {
    ac: 13,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_SURVIVAL]: PROF_DOUBLE,
    [SKILL_NATURE]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_KEEN_HEARING_AND_SIGHT,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки или две дальнобойные атаки.`,
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
  genderId: GENDER_MALE,
}
