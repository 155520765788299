const {GENDER_MALE} = require('./../../../genderList')
const {MAGIC_ITEM_SMOLDERING_ARMOR} = require('./../../../magicItemIdList')
const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../magicItemTypeList')
const {SOURCE_XGTE} = require('./../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_SMOLDERING_ARMOR,
  name: `Дымящий доспех`,
  nameEn: `Smoldering Armor`,
  type: MGC_TYPE_ARMOR,
  typeComment: `любой`,
  rarity: MGC_RARITY_COMMON,
  description: `Когда этот доспех надет, он источает клубы не имеющего запаха безвредного дыма.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_XGTE,
    page: 136,
  },
}
