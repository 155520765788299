const {
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../../conditionList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_SLASHING,
} = require('./../../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
} = require('./../../../../../paramList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {ATTACK_RANGE_SPELL} = require('./../../../../../attackTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_WRATH_OF_NATURE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_WRATH_OF_NATURE,
  name: 'Гнев природы',
  nameEn: 'Wrath of Nature',
  lvl: 5,
  description: `Вы взываете к духам природы, обращая их против своих врагов.

Выберите точку, видимую Вами в пределах дистанции. Духи оживляют деревья, камни и травы в 60-футовом кубе с центром в этой точке на всё время действия заклинания.

# Травы и подлесок

Любая область земли в кубе, покрытая травой или подлеском, является труднопроходимой местностью для Ваших врагов.

# Деревья

В начале каждого Вашего хода каждый враг в пределах 10 футов от любого дерева внутри куба должен пройти испытание Ловкости или получить 4к6 рубящего урона от хлещущих его ветвей.

# Корни и лозы

В конце каждого Вашего хода одно существо по Вашему выбору, которое находится на земле внутри куба, должно пройти испытание Силы или обездвижиться на всё время действия заклинания. Обездвиженное существо может действием совершить проверку Силы (Атлетика) против СЛ Ваших заклинаний, оканчивая жффект на себе при успехе.

# Камни

Бонусным действием в свой ход Вы можете призвать внутри куба камень, чтобы запустить его в видимое Вами внутри куба существо. Совершите дальнобойную атаку заклинанием против этой цели. При попадании цель получает 3к8 немагического дробящего урона и должна пройти испытание Силы или упасть ничком.`,
  magicSchoolId: MAGIC_EVOCATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: [
    {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_SLASHING,
        diceCount: 4,
        diceType: 6,
      },
    },
    {
      savethrowParam: PARAM_STR,
      condition: CONDITION_RESTRAINED,
    },
    {
      attackType: ATTACK_RANGE_SPELL,
      savethrowParam: PARAM_STR,
      condition: CONDITION_PRONE,
      damage: {
        type: DAMAGE_BLUDGEONING,
        diceCount: 3,
        diceType: 8,
      },
    },
  ],
  source: {
    id: SOURCE_XGTE,
    page: 150,
  },
}
