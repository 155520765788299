const {SOURCE_MTOF} = require('./../../sourceList')
const {PC_RACE_DWARF} = require('./../../pcRaceIdList')
const {
  GOD_DEEP_DUERRA,
  GOD_LADUGUER,
} = require('./../../godIdList')
const {
  CREATURE_DERRO,
  CREATURE_DERRO_SAVANT,
  CREATURE_MIND_FLAYER,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Дерро',
    text: `[Дерро](CREATURE:${CREATURE_DERRO}) крадутся по подземным царствам в поисках мест, которые обезопасили бы их от опасностей Подземья. Одинаково страшные и порочные группы этих дварфоподобных существ нападают на тех, кто слабее их, выказывая сдержанное почитание тем, кого они считают более могущественными. Заросший, осунувшийся, дрожащий и бедно одетый одинокий дерро кажется жалким существом, но когда клекочущие, плюющиеся, рычащие, воющие орды этих тварей нападают — это зрелище внушает одновременно и страх, и отвращение.`,
    source: {
      id: SOURCE_MTOF,
      page: 157,
    },
  },
  {
    header: 'Безумство и чародейство',
    text: `Склочные в группах и слабые раздельно, дерро бы давно вымерли, если бы не две черты их характера. У них есть врождённая склонность к паранойе, и эта особенность хорошо служит им, поскольку они ориентируются в опасностях Подземья и его обществ. Помимо этого, у них превышающая норму склонность к совершенству чародейских способностей. Те, у кого они проявляются и становятся лидерами, более известными как [саванты](CREATURE:${CREATURE_DERRO_SAVANT}). Дерро считают, что дар чародейства — это благословение от их божества, Дииринки.`,
    source: {
      id: SOURCE_MTOF,
      page: 157,
    },
  },
  {
    header: 'Забытые двергары',
    text: `Амбициозные мечты и безудержный фанатизм скрыли истинное происхождение дерро даже от них самих. Большинство [дварфов](PC_RACE:${PC_RACE_DWARF}) не признают в дерро собратьями, но в легендах, которые дерро рассказывают о своей расе и истории, в которую верят двергары, есть общее зерно истины.

Согласно двергарам, дерро произошли из клана [дварфов](PC_RACE:${PC_RACE_DWARF}), что были оставлен позади, когда другие сбежали из-под власти [пожирателей разума](CREATURE:${CREATURE_MIND_FLAYER}). Вскоре они тоже сбежали, но не смогли избежать безумия и преобразования.

Дерро рассказывают свою собственную историю о жизни и выживании в Подземье, и в них свежеватели разума не всегда выступают врагами. [Ладугуэр](GOD:${GOD_LADUGUER}) и [Глубинная Дуэрра](GOD:${GOD_DEEP_DUERRA}) не играют в их истории никакой роли. Вместо них их история повествует о двух братьях, Дииринке и Диинкаразане, и о том, как Дииринка предал своего брата, и благодаря этому украл магическую силу у того зла, от которого они сбежали. Опасность, с которой встретились братья, в этих легендах не одна и та же, и зависит от того, против кого хотят вести свой народ саванты, но всё же суть истории не меняется: он даёт урок того, что выживать нужно любой ценой и примером того, как коварство и жестокость могут быть добродетелями.`,
    source: {
      id: SOURCE_MTOF,
      page: 157,
    },
  },
  {
    header: 'Безумие дерро',
    text: `Все дерро страдают от различных форм безумия, чаще всего проявляющихся в виде мании и паранойи, но и другие психические расстройства и странные тики также обычно влияют на них. Дерро редко обращают внимание на странности в поведении их собратьев, по крайней мере пока эти странности не относятся к проявлениям [саванта](CREATURE:${CREATURE_DERRO_SAVANT}). Они считают, что странное поведение [савантов](CREATURE:${CREATURE_DERRO_SAVANT}) возникает, потому что эти лидеры несут послание от Дииринки. Вы можете использовать таблицу безумия дерро, чтобы создать сгенерировать одно или несколько странных качеств для дерро-персонажа Мастера.`,
    source: {
      id: SOURCE_MTOF,
      page: 158,
    },
  },
  {
    header: 'Таблица безумия дерро',
    text: `
| к20 | Странность                                          |
|-----|-----------------------------------------------------|
| 1   | Никогда не купается и не меняет одежду              |
| 2   | Мучается с волосами или усами                       |
| 3   | Говорит с кем-то, кого там нет                      |
| 4   | По возможности, идёт задом                          |
| 5   | Никогда не смотрит в глаза другим                   |
| 6   | Скалит зубы, после каждого предложения              |
| 7   | Выплёскивает половину каждого глотка напитка и пищи |
| 8   | Оскорбляет всех, то сначала обращаются к нему       |
| 9   | Трогает всех, с кем говорит                         |
| 10  | Видит души мёртвых, покидающих свои тела            |
| 11  | Падает в обморок при первом ярком свете             |
| 12  | Часто лижет свои ладони                             |
| 13  | Слышит голоса                                       |
| 14  | Пугается письменности                               |
| 15  | Пробует объекты на вкус                             |
| 16  | Стоит слишком близко к другим                       |
| 17  | Громко дышит                                        |
| 18  | Постоянно несёт чепуху                              |
| 19  | Бормочет при разговоре                              |
| 20  | Прыгает с места на место                            |
`,
    source: {
      id: SOURCE_MTOF,
      page: 158,
    },
  },
]
