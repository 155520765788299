const {GENDER_FEMALE} = require('./../../genderList')
const {CREATURE_TYPE_AARAKOCRA} = require('./../../creatureTypeIdList')
const {aarakocraDescriptionList} = require ('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_AARAKOCRA,
  nameEn: 'Aarakocra',
  nameAlt: 'Птичий народ',
  nameEnAlt: 'Birdfolk',
  genderId: GENDER_FEMALE,
  name: {
    singular: {
      nominative: 'ааракокра',
      genitive: 'ааракокры',
      dative: 'ааракокре',
      accusative: 'ааракокру',
      instrumental: 'ааракокрой',
      prepositional: 'ааракокре',
    },
    plural: {
      nominative: 'ааракокры',
      genitive: 'ааракокр',
      dative: 'ааракокрам',
      accusative: 'ааракокр',
      instrumental: 'ааракокрами',
      prepositional: 'ааракокрах',
    },
  },
  description: aarakocraDescriptionList,
}
