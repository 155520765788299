const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_INVULNERABILITY} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_INVULNERABILITY,
  name: 'Неуязвимость',
  nameEn: 'Invulnerability',
  description: `Вы иммунны ко всему урону, пока заклинание активно.`,
  lvl: 9,
  magicSchoolId: MAGIC_ABJURATION,
  range: -1,
  hasCost: true,
  hasConsumable: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `небольшой кусочек адамантина стоимостью не менее 500 зм, расходуемый заклинанием`,
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  source: {
    id: SOURCE_XGTE,
    page: 158,
  },
}
