const {LANG_TYPE_DEAD} = require('./../../languageTypeList')
const {LANG_HULGORKYN} = require('./../../languageIdList')
const {SOURCE_DND3_FRCS} = require('./../../sourceList')
const {ALPHABET_DETHEK} = require('./../../alphabetList')
const {PC_RACE_ORC} = require('./../../pcRaceIdList')

module.exports = {
  id: LANG_HULGORKYN,
  type: LANG_TYPE_DEAD,
  alphabetId: ALPHABET_DETHEK,
  nameEn: 'Hulgorkyn',
  name: {
    nominative: 'Хулгоркин',
    genitive: 'Хулгоркина',
    instrumental: 'Хулгоркином',
    prepositional: 'Хулгоркине',
  },
  typicalSpeakers: 'Древние орки',
  description: [
    {
      text: `Древний язык [орков](PC_RACE:${PC_RACE_ORC})`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
  ],
  isRealLang: true,
  isReady: true,
}
