const {SOURCE_XGTE} = require('./../../../sourceList')
const {PC_SUBCLASS_CLERIC_FORGE_DOMAIN} = require('./../../../pcSubClassIdList')
const {GEAR_SMITHS_TOOLS} = require('./../../../gearIdList')
const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_ANIMATE_OBJECTS,
  SPELL_CREATION,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_FABRICATE,
  SPELL_HEAT_METAL,
  SPELL_IDENTIFY,
  SPELL_MAGIC_WEAPON,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_SEARING_SMITE,
  SPELL_WALL_OF_FIRE,
} = require('./../../../spellIdList')
const {
  FEATURE_BLESSING_OF_THE_FORGE,
  FEATURE_BONUS_PROFICIENCIES_FORGE_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_ARTISAN_S_BLESSING,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_STRIKE_FORGE_DOMAIN,
  FEATURE_FORGE_DOMAIN_SPELLS,
  FEATURE_SAINT_OF_FORGE_AND_FIRE,
  FEATURE_SOUL_OF_THE_FORGE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_FORGE_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_ANIMATE_OBJECTS,
      SPELL_CREATION,
      SPELL_ELEMENTAL_WEAPON,
      SPELL_FABRICATE,
      SPELL_HEAT_METAL,
      SPELL_IDENTIFY,
      SPELL_MAGIC_WEAPON,
      SPELL_PROTECTION_FROM_ENERGY,
      SPELL_SEARING_SMITE,
      SPELL_WALL_OF_FIRE,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 34,
    },
  },
  {
    id: FEATURE_BONUS_PROFICIENCIES_FORGE_DOMAIN,
    name: `Бонусные владения`,
    nameEn: `Bonus Proficiencies`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
    text: `Вы получаете владение тяжёлыми доспехами и [инструментами кузнеца](GEAR:${GEAR_SMITHS_TOOLS}).`,
    source: {
      id: SOURCE_XGTE,
      page: 34,
    },
  },
  {
    id: FEATURE_BLESSING_OF_THE_FORGE,
    name: `Благословление кузницы`,
    nameEn: `Blessing of the Forge`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
    text: `Вы получаете способность наделять магией оружие или доспехи.

В конце длинного отдыха Вы можете коснуться одного немагического доспеха или оружия (простого или воинского). До конца Вашего следующего длинного отдыха или до тех пор пока Вы не умрёте, этот предмет считается магическим предметом дающим +1 к КД, если это броня, или +1 к броскам атаки и урона, если это оружие.

После использования этого умения Вы не сможете использовать его, пока не закончите длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 35,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_ARTISAN_S_BLESSING,
    name: `Божественный канал: Благословение ремесленника`,
    nameEn: `Channel Divinity: Artisan’s Blessing`,
    lvl: 2,
    pcSubClassId: PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
    text: `Вы можете использовать свой [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}) для создания простых вещей.

Вы проводите ритуал длительностью в час, который создает немагический предмет, содержащий немного металла: простое или воинское оружие, доспехи, десять боеприпасов, набор инструментов или другой металлический предмет снаряжения. Создание завершается через час, предмет появляется в незанятом пространстве по Вашему выбору на поверхности в пределах 5 футов от Вас.

То, что Вы создаете, может быть чем-то, что стоит не более 100 зм. В рамках этого ритуала Вы должны выложить металл, который может содержать монеты, со стоимостью, равной Вашему творению. Металл безвозвратно сливается и превращается в творение на конце ритуала, магически формируя даже неметаллические части творения.

Ритуал может создать дубликат немагического предмета, который содержит металл, такой как ключ, если Вы обладаете оригиналом во время ритуала.`,
    source: {
      id: SOURCE_XGTE,
      page: 35,
    },
  },
  {
    id: FEATURE_SOUL_OF_THE_FORGE,
    name: `Душа кузницы`,
    nameEn: `Soul of the Forge`,
    lvl: 6,
    pcSubClassId: PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
    text: `Ваше мастерство жреца кузни предоставляет Вам особые способности:

* Вы получаете сопротивление огненному урону.
* Когда Вы носите тяжёлый доспех, то получаете +1 к КД.`,
    source: {
      id: SOURCE_XGTE,
      page: 35,
    },
  },
  {
    id: FEATURE_DIVINE_STRIKE_FORGE_DOMAIN,
    name: `Божественный удар`,
    nameEn: `Divine Strike`,
    lvl: [8, 14],
    pcSubClassId: PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
    text: `Вы получаете возможность наполнять свои удары оружием огненной силой кузницы.

Один раз в каждый свой ход, когда Вы попадаете по существу атакой оружием, Вы можете причинить цели дополнительный урон огнём 1к8.

Когда Вы достигаете 14 уровня, дополнительный урон увеличивается до 2к8.`,
    source: {
      id: SOURCE_XGTE,
      page: 35,
    },
  },
  {
    id: FEATURE_SAINT_OF_FORGE_AND_FIRE,
    name: `Святой кузницы и огня`,
    nameEn: `Saint of Forge and Fire`,
    lvl: 17,
    pcSubClassId: PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
    text: `Ваше благословенное сходство с огнем и металлом становится более мощным:

* Вы получаете иммунитет к огненному урону
* Когда Вы носите тяжёлые доспехи, Вы получаете сопротивление к дробящему, колющему и режущему урону от немагических атак.`,
    source: {
      id: SOURCE_XGTE,
      page: 35,
    },
  },
]
