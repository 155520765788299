import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"

import './SourceInfoStyles.less'

const SourceInfoComponent = (
  {
    url,
    title,
    children,
    className = '',
    themeColumn = false,
    themeSameSize = false,
  }
) => url
  ? (
    <a
      className={classNames(
        `SourceInfo SourceInfo-link ${className}`,
        {
          'SourceInfo-column': themeColumn,
          'SourceInfo-sameSize': themeSameSize,
        }
      )}
      title={title}
      rel='nofollow noopener noreferrer'
      href={url}
      target='_blank'
    >
      {children}
    </a>
  )
  : (
    <cite
      className={classNames(
        `SourceInfo ${className}`,
        {
          'SourceInfo-column': themeColumn,
        }
      )}
      title={title}
    >
      {children}
    </cite>
  )

SourceInfoComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

SourceInfoComponent.defaultProps= {
  className: '',
  title: '',
  url: '',
}

export default SourceInfoComponent
