const {CREATURE_TYPE_GENASI_WATER} = require('./../../creatureTypeIdList')
const {GENDER_MALE} = require ('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GENASI_WATER,
  nameEn: 'Water genasi',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'генази воды',
      genitive: 'генази воды',
      dative: 'генази воды',
      accusative: 'генази воды',
      instrumental: 'генази воды',
      prepositional: 'генази воды',
    },
    plural: {
      nominative: 'генази воды',
      genitive: 'генази воды',
      dative: 'генази воды',
      accusative: 'генази воды',
      instrumental: 'генази воды',
      prepositional: 'генази воды',
    },
  },
}
