const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SPELL_MAELSTROM} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MAELSTROM,
  name: 'Водоворот',
  nameEn: 'Maelstrom',
  description: `В точке, которую Вы можете видеть в пределах дистанции, возникает воронка воды радиусом 30 футов и глубиной 5 футов. Точка должна находиться на поверхности земли или в толще воды. Пока действует заклинание, эта область считается труднопроходимой местностью, и все существа, начинающие в ней ход, должны пройти испытание Силы, иначе получат дробящий урон 6к6 и будет подтянуты на 10 футов в направлении центра.`,
  lvl: 5,
  magicSchoolId: MAGIC_EVOCATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `бумага или лист в форме воронки`,
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_STR,
    damage: {
      type: DAMAGE_BLUDGEONING,
      diceCount: 6,
      diceType: 6,
      diceBonus: 0,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 149,
    },
    {
      id: SOURCE_EE,
      page: 16,
    },
  ],
}
