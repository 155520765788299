const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_TROGLODYTE,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_TROGLODYTE,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Троглодитский',
    genitive: 'Троглодитского',
    instrumental: 'Троглодитским',
    prepositional: 'Троглодитском',
  },
  nameEn: 'Troglodyte',
  isReady: false,
  isRealLang: true,
}
