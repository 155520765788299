const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SPELL_GUST} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GUST,
  name: 'Шквал',
  nameEn: 'Gust',
  description: `Вы контролируете окружающий воздух и можете создать один из нижеперечисленных эффектов в точке, которую видите в пределах дистанции заклинания:

* Одно существо с размером не больше Среднего должно пройти испытание Силы, иначе его оттолкнёт на 5 футов от вас. 
* Вы создаёте небольшой порыв ветра, способный переместить предмет массой не более 5 фунтов, который никто не несёт и который ни на кого не надет. Объект толкается на расстояние до 10 футов от вас. Этого толчка недостаточно, чтобы причинить урон. 
* Вы создаёте безвредный эффект с использованием воздуха, например, заставляете листву шелестеть, ветром захлопнуть ставни или Вашу одежду колыхаться на ветру.`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_STR,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 171,
    },
    {
      id: SOURCE_EE,
      page: 24,
    },
  ],
}
