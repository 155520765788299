const {GEAR_ROD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROD_OF_ABSORPTION} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ROD} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ROD_OF_ABSORPTION,
  name: `Жезл поглощения`,
  nameEn: `Rod of Absorption`,
  type: MGC_TYPE_ROD,
  gearType: GEAR_ROD,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `Держа этот жезл, Вы можете реакцией поглотить заклинание, нацеленное только на Вас, и не имеющее зоны воздействия. Эффект поглощённого заклинания отменяется, а его энергия — но не само заклинание — сохраняется в жезле. Уровень поглощённой энергии равен уровню заклинания, с которым оно накладывалось. Жезл за весь свой срок существования может впитать не более 50 уровней энергии. Когда жезл впитает 50 уровней энергии, больше он никогда не сможет их поглощать, и если Вы станете целью заклинания, которое жезл мог бы поглотить, жезл никак не отреагирует.

Когда Вы настраиваетесь на жезл, Вы узнаёте, сколько уровней энергии успел впитать жезл, и сколько энергии хранится в нём на данный момент.

Если Вы — заклинатель, и держите этот жезл, Вы можете превратить хранящуюся в нём энергию в ячейки заклинаний для накладывания подготовленных или известных Вам заклинаний. Вы можете создавать ячейки с уровнем не больше тех, что уже есть у Вас, и при этом не больше 5 уровня. Вместо своих ячеек Вы используете хранящуюся в жезле энергию, во всём же остальном накладываете заклинание как обычно. Например, Вы можете использовать 3 уровня энергии, хранящейся в жезле, в качестве ячейки заклинания 3 уровня.

В найденном жезле уже хранится 1к10 уровней энергии. Жезл, не способный больше поглощать заклинания, в котором не осталось энергии, перестаёт быть магическим.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 162,
  },
}
