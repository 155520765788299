const {FEATURE_SWARMKEEPER_SPELLS_2019_10_17} = require('./../../../featureIdList')
const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_SWARMKEEPER_2019_10_17} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED} = require('./../../../sourceList')
const {
  SPELL_FAERIE_FIRE,
  SPELL_GASEOUS_FORM,
  SPELL_GIANT_INSECT,
  SPELL_INSECT_PLAGUE,
  SPELL_MAGE_HAND,
  SPELL_WEB,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_SWARMKEEPER_SPELLS_2019_10_17,
    name: `Заклинания Роевода`,
    nameEn: `Swarmkeeper Spells`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    pcSubClassId: PC_SUBCLASS_RANGER_SWARMKEEPER_2019_10_17,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_FAERIE_FIRE,
      SPELL_GASEOUS_FORM,
      SPELL_GIANT_INSECT,
      SPELL_INSECT_PLAGUE,
      SPELL_MAGE_HAND,
      SPELL_WEB,
    ],
    source: {
      id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
      page: 3,
    },
  },
]
