const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_MODRONE,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_AXIOMATIC_MIND,
  ABILITY_DISINTEGRATION,
} = require('./../../../../creatureAbilityList')
const {
  modronDescriptionList,
  modronNote,
} = require('./../../../../textCommonParts')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_PENTADRONE} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_MODRONE} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Пентадрон',
  nameEn: 'Pentadrone',
  id: CREATURE_PENTADRONE,
  description: [
    `Пентадроны управляют рабочим населением Механуса и могут импровизировать в ответ на непредвиденные изменения ситуации.`,
    ...modronDescriptionList,
  ],
  note: modronNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_MODRONE,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 214,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_MODRONE,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AXIOMATIC_MIND,
    ABILITY_DISINTEGRATION,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает пять атак _Рукой_.`,
    },
    {
      name: 'Рука',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Парализующий газ',
      restore: 5,
      description: `★СУЩЕСТВО★ выдыхает газ 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 11, иначе станут парализованными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
