const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateCreatureTable = require('./generateCreatureTable')
const list = require('./list')

const siblingIdList = list.map(({id}) => id)

module.exports = (
  {
    id,
    name,
    nameEn,
    colorText,
    creatureIdList,
  }
) => {
  const creatureTableText = generateCreatureTable(creatureIdList)

  return {
    id,
    name,
    nameEn,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Эта обычная с виду сумка, изготовленная из ${colorText} ткани, выглядит пустой. Однако если засунуть в неё руку, можно нащупать маленький пушистый предмет. Весит сумка половину фунта.

Вы можете действием вынуть из сумки пушистый предмет и бросить его на 20 футов. Когда предмет приземляется, он превращается в существо, которое Вы выбираете броском к8, консультируясь с приведённой таблицей, соответствующей цвету сумки. Существо исчезает на следующем рассвете или когда его хиты будут уменьшены до 0.

${creatureTableText}

Существо дружественно к Вам и Вашим спутникам, и действует в Ваш ход. Вы можете бонусным действием отдать команду, как существу перемещаться, и какое действие ему совершать в следующем ходу, или можете отдать общий приказ, такой как _«атакуй врагов»_. Если приказов нет, существо действует сообразно своей природе.

Как только из сумки достанут три пушистых предмета, её нельзя будет использовать до следующего рассвета.`,
    genderId: GENDER_FEMALE,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 206,
    },
  }
}
