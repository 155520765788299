const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_6,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_ABYSSAL,
  LANG_ALL_KNOWN_AT_LIFE,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  CREATURE_BALOR,
  CREATURE_BODAK,
  CREATURE_ORCUS,
} = require('./../../../../creatureIdList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')

module.exports = {
  name: 'Бодак',
  nameEn: 'Bodak',
  id: CREATURE_BODAK,
  description: [

    {
      header: 'Бодак',
      text: `Бодак — это неживые останки того, кто почитал [Оркуса](CREATURE:${CREATURE_ORCUS}). Лишенный жизни и души, он существует только для того, чтобы нести смерть.`,
      source: {
        id: SOURCE_VGTM,
        page: 130,
      },
    },
    {
      header: 'Отмеченный Оркусом',
      text: `Поклоняющийся [Оркусу](CREATURE:${CREATURE_ORCUS}) может принести ритуальные клятвы и вырезать символ лорда демонов на груди над сердцем. Сила Оркуса свежует тело, разум и душу, оставляя лишь разумную оболочку, которая засасывает в себя всю жизненную энергию вокруг. Большинство бодаков появляются именно так, а потом отправляются нести смерть во имя [Оркуса](CREATURE:${CREATURE_ORCUS}).

Оркус создал первых бодаков в Бездне из семерых фанатиков, которых называют Иерофантами Истребления. Эти существа, могучие как [балоры](CREATURE:${CREATURE_BALOR}), имеют свободу воли, но служат [Принцу Нежизни](CREATURE:${CREATURE_ORCUS}) напрямую. Любой из этих бодаков может обратить смертного в бодака взглядом. Как и каждый Иерофант Истребления, каждый бодак носит метку Оркуса как рану на груди — пустоту, там, где у смертного гуманоида находится сердце.

[Оркус](CREATURE:${CREATURE_ORCUS}) может узнать все, что бодак видит или слышит. Если он делает это, то может говорить через бодака для обращения к врагам и последователям напрямую. Бодаки являются продолжением воли Оркуса вне Бездны, служа целям принца демонов и другим его миньонам.`,
      source: {
        id: SOURCE_VGTM,
        page: 130,
      },
    },
    {
      header: 'Грешные обрывки',
      text: `Бодак сохраняет туманные воспоминания о своей прошлой жизни. Он ищет и бывших союзников и врагов, чтобы уничтожить их, так как его извращённая душа желает уничтожить всё, что связано с его прошлой жизнью. Миньоны [Оркуса](CREATURE:${CREATURE_ORCUS}) являются исключением из этого желания; бодак считает их родственными душами и не обрушивает на них свой гнев. Любой, кто знал его до превращения в бодака, может узнать манеры или другие едва уловимые черты его прошлой личности.`,
      source: {
        id: SOURCE_VGTM,
        page: 130,
      },
    },
    {
      header: 'Даже природа презирает бодаков',
      text: `Солнце сжигает их разлагающуюся плоть. Взгляд этого существа разрушает жизнь. Любой, кого бодак убивает своим взглядом, увядает, а лицо замирает маской ужаса. Само присутствие чудовища так противоестественно, что душа замирает. Животные, не приученные к войне, инстинктивно сбегают прямо перед появлением бодака.`,
      source: {
        id: SOURCE_VGTM,
        page: 130,
      },
    },
    {
      header: 'Опустошённая душа',
      text: `Душа существа, которое стало бодаком, так повреждена, что не подходит для большинства форм магического воскрешения. Только заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) или схожая магия может вернуть бодака к его прошлой жизни.`,
      source: {
        id: SOURCE_VGTM,
        page: 130,
      },
    },
    {
      header: 'Природа нежити',
      text: `Бодаку не нужен воздух, пища, питье или сон.`,
      source: {
        id: SOURCE_VGTM,
        page: 130,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 130,
    },
    {
      id: SOURCE_TOA,
      page: 215,
    },
  ],
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_6,
  featureList: [
    {
      name: 'Аура уничтожения',
      description: `★СУЩЕСТВО★ может активировать или дезактивировать эту способность бонусным действием. Будучи активной, аура наносит 5 некротического урона любому существу, которое заканчивает свой ход в пределах 30 футов от ★СУЩЕСТВО★. Нежить и исчадия игнорируют этот эффект.`,
    },
    {
      name: 'Взгляд смерти',
      description: `Когда существо, видящее глаза ★СУЩЕСТВО★, начинает свой ход в пределах 30 футов от ★него★, ★СУЩЕСТВО★ может заставить его пройти испытание Телосложения СЛ 13, если ★СУЩЕСТВО★ ★дееспособен★ и может видеть существо. Если испытание провалено на 5 и более, то хиты существа уменьшаются до 0, если существо не имеет иммунитета к испугу. Иначе существо получает 16 (3к10) психического урона при провале.

Если существо не застали врасплох, то оно может отвести глаза и избежать испытания в начале своего хода. Тогда оно получает помеху на броски на попадание по бодаку до начала своего следующего хода. Если же существо взглянет на ★СУЩЕСТВО★ между этим, то оно тут же проходит это испытание.`,
    },
    {
      name: 'Сверхчувствительность к солнечному свету',
      description: `★СУЩЕСТВО★ получает 5 урона излучением, если начинает свой ход на солнечном свету. Будучи на солнечном свете, ★СУЩЕСТВО★ получает помеху на испытания и проверки способностей.`,
    },
  ],
  actionList: [
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 2,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 2,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Иссушающий взгляд',
      description: `Одно существо, видимое ★СУЩЕСТВО★ в пределах 60 футов, должно пройти испытание Телосложения СЛ 13, и получить 22 (4к10) некротического урона при провале или половину при успехе.`,
    },
  ],
}
