const {
  WEAPON_AMMUNITION,
  WEAPON_RANGE,
  WEAPON_RELOADING,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  SOURCE_AYAGWnW,
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
} = require('./../../../sourceList')
const crossbowsDescription = require('./../../constants/crossbowsDescription')
const {CAT_SIMPLE_RANGE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_CROSSBOW_LIGHT} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_CROSSBOW_LIGHT,
  genderId: GENDER_MALE,
  name: 'Лёгкий арбалет',
  nameByCase: {
    nominative: 'лёгкий арбалет',
    genitive: 'лёгкого арбалета',
    accusative: 'лёгкий арбалет',
    instrumental: 'лёгким арбалетом',
  },
  nameEn: 'Crossbow, light',
  description: [
    {
      header: 'Лёгкий арбалет',
      text: 'Железный замок этого арбалета покрыт пятнами ржавчины.',
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    crossbowsDescription,
  ],
  damage: {
    diceType: 8,
    diceCount: 1,
  },
  cost: 2500,
  damageType: DAMAGE_PIERCING,
  weight: 5,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 114,
    },
  ],
  weaponPropList: [
    WEAPON_AMMUNITION,
    WEAPON_TWO_HANDED,
    WEAPON_RELOADING,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 80,
        max: 320,
      },
    },
  ],
  category: CAT_SIMPLE_RANGE_WEAPON,
}
