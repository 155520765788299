const {SOURCE_VGTM} = require('./../../sourceList')
const {PC_CLASS_RANGER} = require('./../../pcClassIdList')

const {
  CREATURE_NEEDLE_LORD,
  CREATURE_NEEDLE_SPAWN,
  CREATURE_ZOMBIE,
} = require('./../../creatureIdList')

module.exports = {
  header: 'Иглюды',
  text: `Мудрый [следопыт](PC_CLASS:${PC_CLASS_RANGER}) знает, что увидеть [иглюда](CREATURE:${CREATURE_NEEDLE_SPAWN}) — дурная примета, потому что их появление предвещает появление [иглорда](CREATURE:${CREATURE_NEEDLE_LORD}) и начало войны за душу леса.
  
**Бродячие трупы.** Иглюды создается [иглордами](CREATURE:${CREATURE_NEEDLE_LORD}) — неповоротливыми растительными существами, которые происходят из фейских владений, разрушенных бессмысленным насилием. Когда иглорд входит в обычный лес, он распространяет коробочки семян везде, где бродит. Коробочка спит, пока рядом с ней не умирает гуманоид или к ней не подтаскивают оказывается труп гуманоида. Тогда коробочка раскрывается и семена врастают в разлагающуюся плоть, вырастая в виде длинных острых игл. Лозы обвивают труп, поднимая его и заставляют его встать и идти, пошатываясь, как [зомби](CREATURE:${CREATURE_ZOMBIE}).

**Инвазивные виды.** Когда в фейском лесу происходит большое сражение, его растения иногда тоже становятся одержимы жаждой насилия и завоеваний, которые побуждали фей к войне. Эти растения становятся [иглордами](CREATURE:${CREATURE_NEEDLE_LORD}) — массами колючих лоз в форме огра, покрытых длинными колючими иглами, пронзающими плоть.

[Иглорд](CREATURE:${CREATURE_NEEDLE_LORD}) воображает себя генералом-завоевателем. Он ищет леса, которые можно победить и завоевать. Когда [иглорд](CREATURE:${CREATURE_NEEDLE_LORD}) выбирает лес, он сначала сосредотачивается на распространении семенных коробочек, которые будут выращивать его слуг. Затем он рыскает по лесу в поисках слабых или беззащитных гуманоидов, чтобы убить, превращая их трупы в иглюдов, наращивая свою армию.

**Неестественный союзник.** Иглорд — непримиримый враг. Движимый только желанием захватывать и править, он ведет бесконечную войну своей армией ходячих мертвецов против всех существ, которые он рассматривает как угрозу своему господству. Он может даже заключать договоры с хобгоблинами, кобольдами и другими существами, желающими подчиниться ему, но считает, что любой союз, который делает его меньшим партнером, является лишь временной мерой. Иглорд предает любых союзников при первой возможности, так как считает, что только одно существо может претендовать на господство над лесом.`,
  source: {
    id: SOURCE_VGTM,
    page: 198,
  },
}
