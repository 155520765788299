const {
  SOURCE_ERFTLW,
} = require('./../../../../sourceList')
const {
  CR_3,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ABERRATION,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ACROBATICS,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
} = require('./../../../../damageTypeList')
const {
  LANG_DEEP_SPEECH,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {
  SENSE_BLIND_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_EVASION,
  ABILITY_UNARMORED_DEFENSE,
} = require('./../../../../creatureAbilityList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CONDITION_BLINDED,
} = require('./../../../../conditionList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_DOLGAUNT,
  CREATURE_DOLGRIM,
  CREATURE_HOBGOBLIN,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Долгонт',
  nameEn: 'Dolgaunt',
  id: CREATURE_DOLGAUNT,
  description: [
    {
      header: 'Долгонты',
      text: `Долгонты это иссохшие [хобгоблины](CREATURE:${CREATURE_HOBGOBLIN}) с плотью болезненного цвета. У них пустые открытые глазницы и червеобразный язык. Тело долгонта покрыто извивающимися усиками, а из плечей растут два длинных щупальца. Долгонты слепы, но ощущают всё вокруг по подрагиваниям покрывающих их кожу усиков. Своими щупальцами долгонты могут высасывать жизнь пойманных ими существ.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 291,
      },
    },
    {
      header: 'Искажённые хаосом',
      text: `Когда дэлькиры вышли из Зориата для завоевания Хорвайра, он ловит и преобразует местных жителей, создавая из них армии жутких солдат. Диррн Заразитель создаёт долгонтов из [хобгоблинов](CREATURE:${CREATURE_HOBGOBLIN}), превращая их в умных, безжалостных и эффективных убийц.

Когда дэлькиры были побеждены, долгонты со своими хозяевами спустились в глубины Хайбера. Там они обучаются в пещерных монастырях, превращая свои тела в живое оружие ради выполнения задач своих хозяев. Долгонтов часто командуют отрядами [долгримов](CREATURE:${CREATURE_DOLGRIM}), или работают с Культами Нижнего Дракона, особенно с теми, которые посвящены Диррну Заразителю.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 291,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_ERFTLW,
    page: 291,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 12,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_COLD,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_GOBLIN,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_UNARMORED_DEFENSE,
    ABILITY_EVASION,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Щупальцами_ и два _Безоружных удара_. Каждая атака _Щупальцем_ может быть заменена на _Высасывание жизни_.`,
    },
    {
      name: 'Щупальце',
      description: `Если цель Большая или меньше, то она Схвачена (СЛ побега 12). До окончания захвата ★СУЩЕСТВО★ не может использовать это щупальце против другой цели. У ★СУЩЕСТВО★ два щупальца.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Безоружный удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Высасывание жизни',
      description: `Одно Схваченное щупальцем ★СУЩЕСТВО★ существо должно пройти испытание Телосложения СЛ 11. При провале оно получает 2к8 урона некротической энергией, а ★СУЩЕСТВО★ восстанавливает хиты в количестве половины этого урона.`,
    },
  ],
}
