const {
  CAST_ROYALTY,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SPELL_JIM_S_GLOWING_COIN,
  SPELL_LIGHT,
} = require('./../../../../../spellIdList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SOURCE_AI} = require('./../../../../../sourceList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_JIM_S_GLOWING_COIN,
  name: 'Джимова сверкающая монетка',
  nameAlt: 'Сверкающая монетка Джима',
  nameEn: 'Jim’s Glowing Coin',
  description: `Среди множества тактик, использованных мастером-магом и известным приключенцем Джимом Тёмномагией, старый  трюк со светящейся монеткой — проверенная временем классика.

Сотворяя это заклинание, Вы бросаете монетку, являющуюся материальным компонентом заклинания, в любую точку в пределах дистанции. Монета светится, как будто под действием заговора [Свет](SPELL:${SPELL_LIGHT}). Каждое видимое Вами в пределах 30 футов от монеты существо по Вашему выбору, должно пройти испытание Мудрости или отвлечься на всю длительность заклинания. Будучи отвлечённым таким образом, существо получает получает помеху к проверкам Мудрости (Внимательности) и броскам инициативы.`,
  lvl: 2,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 60,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL, CAST_ROYALTY],
  materialText: `монетка`,
  royaltyCost: 200,
  duration: { timeId: TIME_MINUTE, count: 1 },
  effect: {
    savethrowParam: PARAM_WIT,
  },
  source: {
    id: SOURCE_AI,
    page: 77,
  },
}
