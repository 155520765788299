const {
  CREATURE_DESPAIR_SHADOWSPAWN_SPIRIT,
  CREATURE_DESPAIR_SHADOW_SPIRIT_2020_03_26,
  CREATURE_FURY_SHADOW_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух отчаявшейся тени',
  nameEn: 'Despair Shadow Spirit',
  id: CREATURE_DESPAIR_SHADOW_SPIRIT_2020_03_26,
  releasedAs: CREATURE_DESPAIR_SHADOWSPAWN_SPIRIT,
  parentId: CREATURE_FURY_SHADOW_SPIRIT_2020_03_26,
  featureList: [
    {
      name: 'Груз веков',
      description: `★СУЩЕСТВО★ источает ауру отчаяния радиусом 5 футов. Скорость всех зверей или гуманоидов (кроме заклинателя), начинающих свой ход в этой ауре, уменьшается на 20 футов до начала их следующего хода.`,
    },
  ],
}
