const {SOURCE_TWBTW} = require ('./../../sourceList')
const {GENDER_FEMALE, GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_HARENGON} = require('./../../creatureTypeIdList')
const {LANG_SYLVAN} = require ('./../../languageIdList')

module.exports = {
  id: CREATURE_TYPE_HARENGON,
  nameEn: 'Harengon',
  genderId: GENDER_FEMALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'зайчан',
        genitive: 'зайчана',
        dative: 'зайчану',
        accusative: 'зайчана',
        instrumental: 'зайчаном',
        prepositional: 'зайчане',
      },
      [GENDER_FEMALE]: {
        nominative: 'зайчана',
        genitive: 'зайчаны',
        dative: 'зайчане',
        accusative: 'зайчану',
        instrumental: 'зайчаной',
        prepositional: 'зайчане',
      },
    },
    plural: {
      nominative: 'зайчаны',
      genitive: 'зайчан',
      dative: 'зайчанам',
      accusative: 'зайчан',
      instrumental: 'зайчанами',
      prepositional: 'зайчанах',
    },
  },
  description: {
    text: `Зайчаны происходят из Страны Фей, где они говорили на [сильване](LANG:${LANG_SYLVAN}) и олицетворяли дух свободы и путешествий. Со временем эти зайцелюды упрыгали в другие миры, принося с собой изобилие Страны Фей и на скаку изучая новые языки.

Зайчаны двуноги, с характерными длинными заячьими ступнями. Цвет их меха весьма разнообразен. У них такие же чуткие чувства и мощные ноги, как и у зайцев. Зайчаны энергичны, как только что открывшийся родник и благословлены удачей малых фей. Во время приключений опасность часто минует их, удачно случаясь всего в нескольких шагах.`,
    source: {
      id: SOURCE_TWBTW,
    },
  },
}
