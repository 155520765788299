const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_MIDDLE,
} = require('./../../../../genderList')
const {
  GEAR_ALCHEMIST_S_SUPPLIES,
  GEAR_COOK_S_UTENSILS,
  GEAR_HERBALISM_KIT,
  GEAR_POISONERS_KIT,
  GEAR_QUARTERSTAFF,
} = require('./../../../../gearIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_ARTIFACT,
} = require('./../../../../magicItemRarityList')
const {
  MAGIC_ITEM_BABA_YAGA_S_MORTAR_AND_PESTLE,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_BABA_YAGA_S_MORTAR_AND_PESTLE,
  name: `Ступа и Пест Бабы Яги`,
  nameEn: `Baba Yaga’s Mortar and Pestle`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  description: [
    `Творения бессмертной карги Бабы Яги отрицают законы смертной магии. Среди самых известных орудий, общих в легендах о ней в бесчисленном множестве миров есть артефакты, которые перемещают её по планам: _Ступа_ и _Пест_ Бабы Яги. В вопросе настройки эти знаковые инструменты Бабы Яги представляют собой единый артефакт. Если они разделятся, то _Пест_ появится рядом со _Ступой_ на следующем рассвете.`,
    {
      header: 'Случайные свойства',
      text: `Этот артефакт имеет следующие случайные свойства, которые Вы можете определить бросками по таблицам в разделе «Артефакты» Руководства Мастера.

* 2 малых положительных свойства
* 1 основное положительное свойство
* 1 малое отрицательное свойство`,
    },
    {
      header: 'Свойства Ступы',
      text: `_Ступа_ — это крошечная деревянная миска.

_Ступа_ расширяется, увеличиваясь в размерах, чтобы вместить всё, что Вы помещаете внутрь, вплоть до Большого размера, что позволит ей вместить даже Большое существо.`,
    },
    {
      header: 'Свойства Песта',
      text: `_Пест_ представляет собой потёртый деревянный пестик длиной 6 дюймов.
 
Один раз за Ваш ход, держа _Пест_, Вы можете раздвинуть его в [Боевой посох](GEAR:${GEAR_QUARTERSTAFF}) или снова сжать в пестик (действий не требуется). В качестве боевого посоха _Пест_ — магическое оружие, которое имеет +3 к броскам атаки и урона. У _Песта_ 12 зарядов. Когда Вы попадаете рукопашной атакой _Пестом_, Вы можете потратить до 3 его зарядов, чтобы нанести дополнительно 1к8 урона силовым полем за каждый израсходованный заряд. _Пест_ восстанавливает все израсходованные заряды ежедневно на рассвете.`,
    },
    {
      header: 'Отличные инструменты',
      text: `Держа _Ступу_ и _Пест_, Вы можете действием назвать любое немагическое растение, минерал или жидкость и количество материала стоимостью 10 золотых или меньше. _Ступа_ мгновенно заполняется желаемым количеством названного материала. Вы не можете сделать это снова, пока не окончите короткий или длинный отдых.

Также Вы можете использовать _Ступу_ и _Пест_ как [инструменты алхимика](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}), [повара](GEAR:${GEAR_COOK_S_UTENSILS}), [набор травника](GEAR:${GEAR_HERBALISM_KIT}) или [отравителя](GEAR:${GEAR_POISONERS_KIT}). Вы получаете преимущество на все проверки, совершаемые Вами при использовании _Ступы_ и _Песта_ в качестве этих инструментов.`,
    },
    {
      header: 'Основные части',
      text: `Если _Ступа_ и _Пест_ находятся в пределах 5 футов от Вас, Вы можете действием приказать _Песту_ толочь. В течение следующей минуты, ии пока Вы действием устно не прикажете ему прекратить, _Пест_ будет двигаться самостоятельно, перемалывая содержимое _Ступы_ в кашиу или мелкий порошок, что может быть полезно в готовке или алхимии. В начале каждого Вашего хода всё внутри _Ступы_ получает 4к10 урона силовым полем. Если это уменьшает хиты цели до 0, цель превращается в порошок, кашицу, пасту или что-то подобное, сообразно её природе. Перемалывание не затрагивает только магические предметы. Если Вы захотите, то, когда _Пест_ остановится, _Ступа_ разделит своё содержимое — например, соберёт отдельно костяной порошок, измельчённые травы и раздавленные органы.`,
    },
    {
      header: 'Ночной полёт',
      text: `Если Вы держите _Пест_, находясь в _Ступе_, Вы можете действием устно приказать _Ступе_ отправиться к конкретному месту или существу. Вам не обязательно знать где находится Ваша цель, но она должна быть конкретной — не просто ближайшая речка или логово красного дракона. Если выбранная цель в пределах 1000 миль, то _Ступа_ поднимается в воздух и исчезает. Вы и все существа внутри _Ступы_ путешествуете по сказочному небу, видя внизу смутные отражения проносящегося мира. Существа могут видеть Ваш образ, летящий в небе между точками отправления и назначения. Вы прибываете в точку назначения через час, или, если сейчас ночь, через минуту.`,
    },
    {
      header: 'Катание на косточках',
      text: `Когда Вы приказываете _Ступе_ отправляться, Вы можете вместо этого расбросать порошок или кашицу из чего-то, растолчённого в _Ступе_ и назвать место на другом плане существования или в другом мире на Материальном плане. Если этот материал получен из существа, рождённого на том плане или мире, то _Ступа_ отправится через пустое ночное небо, прибывая в свободное пространство в выбранном месте через минуту.`,
    },
    {
      header: 'Уничтожение Ступы и Песта',
      text: `_Ступа_ и _Пест_ уничтожаются, если их растаптывает _Избушка на курьих ножках_ Бабы Яги или сама Баба Яга.`,
    },
  ],
  genderId: GENDER_MIDDLE,
  source: {
    id: SOURCE_TCoE,
    page: 174,
  },
}
