const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_NATURE_DOMAIN} = require('./../../../pcSubClassIdList')
const {PC_CLASS_DRUID} = require('./../../../pcClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')

const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_BARKSKIN,
  SPELL_DOMINATE_BEAST,
  SPELL_GRASPING_VINE,
  SPELL_INSECT_PLAGUE,
  SPELL_PLANT_GROWTH,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPIKE_GROWTH,
  SPELL_TREE_STRIDE,
  SPELL_WIND_WALL,
} = require('./../../../spellIdList')
const {
  FEATURE_ACOLYTE_OF_NATURE,
  FEATURE_BONUS_PROFICIENCY_NATURE_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_CHARM_ANIMALS_AND_PLANTS,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_DAMPEN_ELEMENTS,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_STRIKE_NATURE_DOMAIN,
  FEATURE_MASTER_OF_NATURE,
  FEATURE_NATURE_DOMAIN_SPELLS,
} = require('./../../../featureIdList')

const spellIdListByClassAndLvl = require('./../../../spellIdListByClassAndLvl')

module.exports = [
  {
    id: FEATURE_NATURE_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_ANIMAL_FRIENDSHIP,
      SPELL_SPEAK_WITH_ANIMALS,
      SPELL_BARKSKIN,
      SPELL_SPIKE_GROWTH,
      SPELL_PLANT_GROWTH,
      SPELL_WIND_WALL,
      SPELL_DOMINATE_BEAST,
      SPELL_GRASPING_VINE,
      SPELL_TREE_STRIDE,
      SPELL_INSECT_PLAGUE,
    ],
  },
  {
    id: FEATURE_ACOLYTE_OF_NATURE,
    name: `Послушник природы`,
    nameEn: `Acolyte of Nature`,
    lvl: 1,
    text: `Вы изучаете один заговор друида на свой выбор. Вы также получаете владение одним из следующих навыков:

* Выживание,
* Природа,
* Уход за животными.`,
    spellIdList: spellIdListByClassAndLvl[PC_CLASS_DRUID][0],
  },
  {
    id: FEATURE_BONUS_PROFICIENCY_NATURE_DOMAIN,
    name: `Бонусное владение`,
    nameEn: `Bonus Proficiency`,
    lvl: 1,
    text: `Вы осваиваете владение тяжёлыми доспехами.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_CHARM_ANIMALS_AND_PLANTS,
    name: `Божественный канал: Очарование животных и растений`,
    nameEn: `Channel Divinity: Charm Animals and Plants`,
    lvl: 2,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы очаровать животных и растения.

Вы действием демонстрируете свой священный символ и называете имя своего божества.

Все звери и растительные существа, которые могут видеть Вас, и находятся в пределах 30 футов от Вас, должны пройти испытание Мудрости. Если существо провалит испытание, оно становится очарованным Вами на 1 минуту, или пока не получит урон.

Пока существо очаровано, оно дружелюбно к Вам и другим существам, которых Вы укажете.`,
  },
  {
    id: FEATURE_DAMPEN_ELEMENTS,
    name: `Сдерживание стихий`,
    nameEn: `Dampen Elements`,
    lvl: 6,
    text: `Когда Вы или существо в пределах 30 футов от Вас получает урон звуком, кислотой, огнём, холодом или электричеством, Вы можете реакцией предоставить существу сопротивление этому виду урона.`,
  },
  {
    id: FEATURE_DIVINE_STRIKE_NATURE_DOMAIN,
    name: `Божественный удар`,
    nameEn: `Divine Strike`,
    lvl: [8, 14],
    text: `Вы получаете способность наполнять удары своего оружия божественной энергией.

Один раз в каждый свой ход, когда Вы попадаете по существу атакой оружием, Вы можете причинить цели дополнительный урон огнём, холодом или электричеством (на Ваш выбор) 1к8. Когда Вы достигаете 14 уровня, дополнительный урон возрастает до 2к8.`,
  },
  {
    id: FEATURE_MASTER_OF_NATURE,
    name: `Мастер природы`,
    nameEn: `Master of Nature`,
    lvl: 17,
    text: `Вы получаете способность командовать животными и растительными существами.

Пока существа очарованы Вашим умением [Очарование животных и растений](FEATURE:${FEATURE_CHANNEL_DIVINITY_CHARM_ANIMALS_AND_PLANTS}), Вы можете бонусным действием в свой ход устно указать, что каждое из них будет делать в свой следующий ход.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_NATURE_DOMAIN,
    source: {
      id: SOURCE_PHB,
      page: 64,
    },
  })
)
