const {
    SOURCE_XGTE,
  } = require('./../../../../sourceList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    FEATURE_HOUND_OF_ILL_OMEN,
  } = require('./../../../../featureIdList'),
  {
    ABILITY_INCORPOREAL_MOVEMENT,
  } = require('./../../../../creatureAbilityList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    CREATURE_DIRE_WOLF,
    CREATURE_HOUND_OF_ILL_OMEN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гончая дурного знамения',
  nameEn: 'Hound of Ill Omen',
  id: CREATURE_HOUND_OF_ILL_OMEN,
  createdByFeatureId: FEATURE_HOUND_OF_ILL_OMEN,
  parentId: CREATURE_DIRE_WOLF,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  featureList: [
    ABILITY_INCORPOREAL_MOVEMENT,
    {
      name: 'Гончая дурного знамения',
      description: `
* ★СУЩЕСТВО★ появляется с количеством временных хитов, равных половине уровня Чародея.
* В начале своего хода ★СУЩЕСТВО★ автоматически узнаёт местоположение своей цели. Если цель была спрятанной, то для ★СУЩЕСТВО★ она перестает быть спрятанной.
* В свой ход ★СУЩЕСТВО★ может двигаться только к своей цели по самому прямому маршруту, и может использовать своё действие только для атаки своей цели.
* ★СУЩЕСТВО★ может совершать провоцированные атаки, но только против своей цели.
* Пока ★СУЩЕСТВО★ находится в пределах 5 футов от цели, цель имеет помеху на испытания против любого заклинания, которое сотворяет Чародей.
* ★СУЩЕСТВО★ исчезает, если её хиты уменьшаются до 0, если хиты её цели уменьшены до 0 или спустя 5 минут.
`,
    },
  ],
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_XGTE,
    page: 61,
  },
}
