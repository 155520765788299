const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_POWER_WORD_PAIN} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_POWER_WORD_PAIN,
  name: 'Слово силы: боль',
  nameEn: 'Power Word Pain',
  description: `Вы произносите слово силы, вызывающее волны острой боли у одного существа, которое Вы можете видеть в пределах дистанции. Если у цели 100 хитов или меньше, она подвергается этой ужасной боли. В противном случае заклинание не оказывает никакого эффекта. Цель также не подвержена этому заклинанию, если она невосприимчива к очарованию.

Пока цель подвергается ужасной боли, её скорость не может быть больше чем 10 футов. Цель также совершает с помехой все броски атаки, проверки характеристик и испытания, за исключением испытаний Телосложения. И, наконец, если цель пытается сколдовать заклинание, она должна пройти испытание Телосложения, в противном случае заклинание тратится и не срабатывает.

Цель, страдающая от этой боли, может проходить испытание Телосложения в конце каждого своего хода. При успехе боль проходит.`,
  lvl: 7,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 60,
  componentIdList: [CAST_VERBAL],
  effect: {
    savethrowParam: PARAM_CON,
  },
  source: {
    id: SOURCE_XGTE,
    page: 166,
  },
}
