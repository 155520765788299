const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../../attackTypeList')
const {DAMAGE_PSYCHIC} = require('./../../../../../damageTypeList')
const {MAGIC_ILLUSION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_SHADOW_BLADE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SHADOW_BLADE,
  name: 'Теневой клинок',
  nameEn: 'Shadow Blade',
  description: `Вы сплетаете воедино нити тени, чтобы создать в Ваших руках меч затвердевшего мрака. Этот волшебный меч существует до тех пор, пока заклинание активно. Он считается простым оружием ближнего боя, которым Вы владеете. Он наносит урон психической энергией 2к8 при попадании и обладает свойствами фехтовальное, лёгкое и метательное (дистанция 20/60). Кроме того, когда Вы используете меч для атаки цели, которая находится в тусклом освещении или темноте, Вы совершаете бросок атаки с преимуществом.

Если Вы роняете оружие или метаете его, оно рассеивается в конце хода.

После этого, пока заклинание активно, Вы можете использовать бонусное действие, чтобы снова вызвать меч в Вашу руку.`,
  highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку заклинания 3 или 4 уровня, урон увеличивается до 3к8. Когда Вы сотворяете его с помощью ячейки заклинания 5 или 6 уровня, урон увеличивается до 4к8. Когда Вы сотворяете его с помощью ячейки заклинания 7 круга или выше, урон увеличивается до 5к8.`,
  lvl: 2,
  magicSchoolId: MAGIC_ILLUSION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  castTime: {timeId: TIME_BONUS_ACTION, count: 1},
  needConcentration: true,
  effect: {
    type: [
      ATTACK_MELEE_WEAPON,
      ATTACK_RANGE_WEAPON,
    ],
    damage: {
      type: DAMAGE_PSYCHIC,
      diceCount: 2,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 169,
  },
}
