const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_SLING,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {
  CR_1_8,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_XVART,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_ABYSSAL,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BAT,
  CREATURE_GIANT_BAT,
  CREATURE_GIANT_RAT,
  CREATURE_RAT,
  CREATURE_XVART,
} = require('./../../../../creatureIdList')
const {
  ABILITY_LOW_CUNNING,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  xvartDescription,
  xvartNote,
} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Ксварт',
  nameEn: 'Xvart',
  id: CREATURE_XVART,
  description: xvartDescription,
  note: xvartNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_XVART,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 165,
  },
  armor: {
    ac: 13,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_LOW_CUNNING,
    {
      name: 'Стайная Опора',
      description: `★СУЩЕСТВО★ имеет преимущество на проверки Силы (Атлетика), сделанные для толчка существа, если по крайней мере один союзник ксварта находится в 5 футах от цели и дееспособен.`,
    },
    {
      name: 'Язык Раксиворта',
      description: `★СУЩЕСТВО★ может общаться с обычными [летучими мышами](CREATURE:${CREATURE_BAT}) и [крысами](CREATURE:${CREATURE_RAT}), а также с [гигантскими летучими мышами](CREATURE:${CREATURE_GIANT_BAT}) и [гигантскими крысами](CREATURE:${CREATURE_GIANT_RAT}).`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_SLING,
    },
  ],
}
