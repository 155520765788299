const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_EASTING} = require('./../../languageIdList')
const {LANG_SUBGROUP_CHARD} = require('./../../languageSubGroupList')

module.exports = {
  id: LANG_EASTING,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_CHARD,
  nameEn: 'Easting',
  name: {
    nominative: 'Восточный',
    genitive: 'Восточного',
    instrumental: 'Восточным',
    prepositional: 'Восточном',
  },
  isReady: false,
  isRealLang: true,
}
