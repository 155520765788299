const {SOURCE_MM} = require('./../../sourceList')

module.exports = {
  header: 'Чистокровные юань-ти',
  text: `Чистокровные образуют низшую касту в обществе юань-ти. Они очень похожи на людей, но при тщательном осмотре разница заметна, потому что у них всегда есть какие-то намёки на их истинную природу, как, например, чешуйчатые пятна на руках, змеиные глаза, заострённые зубы или раздвоенный язык. Одевая накидки и капюшоны, они притворяются людьми и просачиваются в цивилизованные земли для сбора информации, похищений заключённых ради допроса и жертвоприношения, и для торговли с теми, у кого есть что-то, что может помочь воплощению их бесчисленных планов.`,
  source: {
    id: SOURCE_MM,
    page: 315,
  },
}
