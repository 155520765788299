const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_LYCANTHROPE,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {ABILITY_KEEN_SMELL} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_WERERAT} = require('./../../../../creatureIdList')
const {
  GEAR_CROSSBOW_HAND,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {lycantropDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Веркрыса',
  nameEn: 'Wererat',
  description: [
    `Веркрысы — это хитрые ликантропы, скользкие и алчные личности. Они жилистые и раздражительные в гуманоидном облике, с тонкими волосами и бегающими глазами. В своём человеческом и гибридном облике веркрысы предпочитают лёгкое оружие, а в качестве тактики во время боя предпочитают устроить засаду, а не идти в лоб. Кроме того, веркрысы могут укусить в своём зверином облике, что помогает им незаметно проникать в ряды врага или убегать из сражения.

Клан веркрыс действует примерно как гильдия воров. Оборотни распространяют своё проклятье только на тех, кого они хотят добавить в свой клан. Веркрысы, которые были обращены по случайности или сорвались с цепи и больше не подчиняются клану, быстро оказываются мёртвыми. Кланы веркрыс можно найти в развитых городах и поселениях. Они предпочитают скрываться в подвалах и катакомбах. Обитая в подземельях под крупными городами, веркрысы считают свои владения охотничьими угодьями. Часто вместе с ними живут крысы и гигантские крысы.`,
    ...lycantropDescription,
  ],
  id: CREATURE_WERERAT,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_LYCANTHROPE,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 190,
  },
  armor: 12,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
      comment: 'только в облике крысы',
    },
  ],
  languageList: [
    {
      id: LANG_COMMON,
      comment: 'не может говорить в облике крысы',
    },
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в гигантскую крысу, гибрид крысы и гуманоида, или же принять свой истинный облик гуманоида. Все её статистики кроме размера остаются одинаковыми во всех обликах. Всё несомое и носимое ей снаряжение не превращается. Она принимает свой истинный облик, если умирает.`,
    },
    ABILITY_KEEN_SMELL,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в облике гуманоида или гибрида',
      description: `★СУЩЕСТВО★ совершает две атаки, только одна из которых может быть _Укусом_.`,
    },
    {
      name: 'Укус',
      comment: 'только в облике крысы или гибрида',
      description: `Если цель — гуманоид, она должна пройти испытание Телосложения СЛ 11, иначе станет проклятой ликантропией веркрысы.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_SHORTSWORD,
      comment: 'только в облике гуманоида или гибрида',
    },
    {
      gearId: GEAR_CROSSBOW_HAND,
      comment: 'только в облике гуманоида или гибрида',
    },
  ],
  genderId: GENDER_FEMALE,
}
