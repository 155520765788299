const {
  SPELL_BLADE_WARD,
  SPELL_BLUR,
  SPELL_COUNTERSPELL,
  SPELL_DIMENSION_DOOR,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FIREBALL,
  SPELL_HASTE,
  SPELL_INVISIBILITY,
  SPELL_JUMP,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MESSAGE,
  SPELL_MISTY_STEP,
  SPELL_NONDETECTION,
  SPELL_PLANE_SHIFT,
  SPELL_SLEEP,
  SPELL_TELEKINESIS,
  SPELL_THUNDERWAVE,
  SPELL_TRUE_STRIKE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_GITH,
  CREATURE_TYPE_GITHYANKI,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  GEAR_HALF_PLATE,
  GEAR_LONGSWORD,
} = require('./../../../../gearIdList')
const {
  githyankiDescription,
  githyankiNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_GITHYANKI_GISH} = require('./../../../../creatureIdList')
const {LANG_GITH} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гитьянки Гиш',
  nameEn: 'Githyanki Gish',
  id: CREATURE_GITHYANKI_GISH,
  description: [
    `Острый ум и псионические дары позволяют гитьянкам осваивать магию. Гиши смешивают собственные магические способности с фехтованием, благодаря чему становятся особо опасными врагами в бою. Их специализированные возможности делают их хорошо подходящими для убийств, налётов и шпионажа.`,
    ...githyankiDescription,
  ],
  note: githyankiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHYANKI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 127,
  },
  armor: {
    ac: 17,
    gearId: GEAR_HALF_PLATE,
  },
  hp: {
    diceCount: 19,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_GITH,
  ],
  cr: CR_10,
  featureList: [
    {
      name: `Боевая магия`,
      description: `Когда гитьянки совершает действие, чтобы наложить заклинание, он может бонусным действием совершить одну атаку оружием.`,
    },
  ],
  spellCast: [
    {
      comment: 'псионика',
      baseStat: PARAM_INT,
      componentExclude: CAST_NONE,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            {
              id: SPELL_MAGE_HAND,
              comment: 'рука невидима',
            },
          ],
        },
        {
          limit: {
            count: 3,
            period: 'день',
          },
          list: [
            SPELL_JUMP,
            SPELL_MISTY_STEP,
            {
              id: SPELL_NONDETECTION,
              comment: 'только на себя',
            },
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_PLANE_SHIFT,
            SPELL_TELEKINESIS,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 8,
      spellCasterClass: PC_CLASS_WIZARD,
      spellIdList: [
        SPELL_BLADE_WARD,
        SPELL_MESSAGE,
        SPELL_TRUE_STRIKE,
        SPELL_BLUR,
        SPELL_COUNTERSPELL,
        SPELL_DIMENSION_DOOR,
        SPELL_EXPEDITIOUS_RETREAT,
        SPELL_FIREBALL,
        SPELL_HASTE,
        SPELL_INVISIBILITY,
        SPELL_LEVITATE,
        SPELL_LIGHT,
        SPELL_MAGIC_MISSILE,
        SPELL_SLEEP,
        SPELL_THUNDERWAVE,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        2,
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Гитьянки совершает две атаки _Длинным мечом_.`,
    },
    {
      gearId: GEAR_LONGSWORD,
      attack: {
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 4,
          diceType: 8,
        },
      },
    },
  ],
}
