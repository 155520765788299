const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_MONK,
  FEATURE_DEFLECT_MISSILES,
  FEATURE_DIAMOND_SOUL,
  FEATURE_EMPTY_BODY,
  FEATURE_EVASION_MONK,
  FEATURE_EXTRA_ATTACK_MONK,
  FEATURE_KI,
  FEATURE_KI_EMPOWERED_STRIKES,
  FEATURE_MARTIAL_ARTS,
  FEATURE_MONASTIC_TRADITION,
  FEATURE_PERFECT_SELF,
  FEATURE_PURITY_OF_BODY,
  FEATURE_SLOW_FALL,
  FEATURE_STILLNESS_OF_MIND,
  FEATURE_STUNNING_STRIKE,
  FEATURE_TIMELESS_BODY_MONK,
  FEATURE_TONGUE_OF_THE_SUN_AND_MOON,
  FEATURE_UNARMORED_DEFENSE_MONK,
  FEATURE_UNARMORED_MOVEMENT,
} = require('./../../featureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
} = require('./../../sourceList')
const {BACKGROUND_HERMIT} = require('./../../backgroundIdList')
const {
  CREATURE_HOBGOBLIN,
  CREATURE_ORC,
  CREATURE_REMORHAZ,
} = require('./../../creatureIdList')
const {
  GOD_ILMATER,
  GOD_SHAR,
  GOD_YONDALLA,
} = require('./../../godIdList')
const {
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')
const {
  PC_SUBCLASS_MONK_WAY_OF_SHADOW,
  PC_SUBCLASS_MONK_WAY_OF_THE_OPEN_HAND,
} = require('./../../pcSubClassIdList')
const {
  PARAM_DEX,
  PARAM_STR,
} = require('./../../paramList')
const {
  GEAR_DART,
  GEAR_DUNGEONEER_S_PACK,
  GEAR_EXPLORER_S_PACK,
  GEAR_SHORTSWORD,
} = require('./../../gearIdList')
const {
  SKILL_ACROBATICS,
  SKILL_ATHLETICS,
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_RELIGION,
  SKILL_STEALTH,
} = require('./../../skillList')
const {CAT_SIMPLE_WEAPON} = require('./../../gearCategoryList')
const {PC_CLASS_MONK} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_MONK,
  name: {
    singular: {
      nominative: 'монах',
      genitive: 'монаха',
      dative: 'монаху',
      accusative: 'монаха',
      instrumental: 'монахом',
      prepositional: 'монахе',
    },
    plural: {
      nominative: 'монахы',
      genitive: 'монахов',
      dative: 'монахам',
      accusative: 'монахов',
      instrumental: 'монахами',
      prepositional: 'монахах',
    },
  },
  nameEn: 'monk',
  description: [
    `Её кулаки превратились в марево, отражая обрушившийся град стрел. Полуэльфийка перескочила баррикаду и бросилась в плотные ряды [хобгоблинов](CREATURE:${CREATURE_HOBGOBLIN}). Кружась среди них, она раздавала удары во все стороны, расшвыривая противников, пока не осталась единственной, стоящей на ногах.

Глубоко вздохнув, покрытый татуировками человек принял боевую стойку. Когда первый из атакующих [орков](CREATURE:${CREATURE_ORC}) приблизился к нему, он выдохнул, и поток огня, с рёвом вырвавшись из его рта, поглотил противника.

Двигаясь бесшумно, как сама ночь, одетый во всё чёрное полурослик вступил в пятно тени под аркой, и мгновенно появился из другой чернильной тени на балконе, на расстоянии броска камня. Он медленно высвободил клинок из укутанных тканью ножен, и сквозь открытое окно взглянул на деспотичного принца, столь уязвимого во сне.

Вне зависимости от выбранной дисциплины, всех монахов объединяет одно — возможность управлять энергией, текущей в их телах. Вне зависимости от того, проявляется ли она выдающимися боевыми способностями, или чуть заметным усилением защиты и скорости, эта энергия влияет на всё, что делает монах. `,
    {
      header: `Магия ци`,
      text: `Монахи тщательно изучают магическую энергию, которая в большинстве монастырских традиций именуется ци. Эта энергия является составляющей частью магии, пронизывающей мультивселенную, а точнее, той её частью, которая протекает сквозь тела живущих. Монахи научились управлять энергией, протекающей сквозь их тела, повышая физические способности и нанося атаки, способные препятствовать течению ци в телах противников. Используя эту энергию, монахи наделяют удары своих голых рук и ног неестественной силой и скоростью. С приобретаемым опытом их боевые тренировки и тренировки владением ци дают им всё больше власти над собственными телами и над телами врагов. `,
      source: {
        id: SOURCE_PHB,
        page: 77,
      },
    },
    {
      header: `Тренировки и аскеза`,
      text: `Маленькие огороженные монастыри разбросаны по мирам D&D. Это крошечные убежища, позволяющие укрыться от потока повседневной жизни, где само время, кажется, замирает. Живущие тут монахи ищут путь к личному совершенству через созерцание и утомительные тренировки. Многие попадают в монастырь детьми, отправленные туда, если родителям не хватало еды прокормить их, если родители умерли, или в знак благодарности за оказанную монахами помощь.

Некоторые монахи живут обособленно от окружающих, оградившись от всего способного препятствовать их духовному росту. Другие дали обет изоляции, появляясь лишь в качестве шпионов или убийц, действуя по приказу своих лидеров, благородных покровителей или иных, смертных или божественных сил.

Однако большинство монахов не избегают своих соседей и часто посещают ближайшие городки и посёлки, обменивая свои услуги на еду и другие товары. Будучи универсальными воителями, монахи нередко оказываются защитниками соседей от чудовищ или захватчиков.

Для монаха становление искателем приключений означает конец упорядоченной жизни в коммуне. Это может быть трудным шагом, и монахи принимают такое решение нелегко. Те же, кто покинул свой монастырь, относятся к этому шагу очень серьёзно, воспринимая свои странствия как испытание своего физического и духовного развития. Как правило, монахи мало заботятся о материальных богатствах, и ими движет желание достичь большего, чем просто убить чудовище и завладеть его сокровищами. `,
      source: {
        id: SOURCE_PHB,
        page: 77,
      },
    },
    {
      header: `Создание монаха`,
      text: `Когда Вы создаёте персонажа монаха, подумайте о своей связи с монастырём, в котором Вы получили свои навыки и провели годы становления. Были ли Вы сиротой или ребёнком, оставленным на пороге монастыря? Ваши родители отдали вас в монастырь в благодарность за услуги, оказанные монахами? Вы начали этот замкнутый образ жизни, чтобы скрыться от преступлений, что Вы совершили? Или Вы выбрали монашескую жизнь для себя сами?

Подумайте, почему Вы оставили монастырь. Быть может, глава вашего монастыря выбрал вас для особо важной миссии за пределами обители? Возможно, Вы были изгнаны, потом что нарушили правила сообщества. Вы уходите с опаской, или были рады уйти? Есть то, что Вы надеетесь достичь вне монастыря? Хотите ли Вы вернуться домой?

В результате строгой организации жизни монашеской общины и дисциплины, необходимой для использования ци, мировоззрение монахов почти всегда законное.`,
      source: {
        id: SOURCE_PHB,
        page: 78,
      },
    },
    {
      header: `Монахи`,
      text: `Некоторые старейшие монашеские ордена Фаэруна возникли на южных землях Амна и Калимшана, их практики распространялись на север и восток, в то же время как подобные практики просачивались на запад из далёкого Кара-Тура.

Древнейшие ордена в течение долгого времени разветвлялись и дробились, так что сейчас каждый представлен дюжинами течений. Большинство из них — пара десятков посвящённых живущих в изоляции дикой местности. Некоторые монастырские общины исчисляются сотнями, располагаясь ближе к цивилизации, и зачастую обладая большим влиянием, что позволяет ордену участвовать в мировых делах.

Поскольку большинство монашеских орденов возникло среди людской нации, большинство монахов в общинах — [люди](PC_RACE:${PC_RACE_HUMAN}). Но монастыри давно стали прибежищами подкидышей и изгоев, поэтому монахи нечеловеческих рас не являются чем-то неслыханным.`,
      source: {
        id: SOURCE_SCAG,
        page: 130,
      },
    },
    {
      header: `Монашеские ордена`,
      text: `В разных уголках Забытых Королевств можно найти следующие ордена.

# Тёмная луна

Монашеский орден посвященный Шар — Тёмная Луна открыто работает там, где вера [Шар](GOD:${GOD_SHAR}) признана, и скрытно в дикой местности и подземных прибежищах там, где [Шар](GOD:${GOD_SHAR}) не привечают. Его последователи ищут _«знаний и бесед с тенями»_, веря, что истинная мудрость обретается только во тьме и потере, в прямом и духовном смысле. Его приверженцы часто следуют [Пути Тени](PC_SUBCLASS:${PC_SUBCLASS_MONK_WAY_OF_SHADOW}).

# Кулак хин

Монашеский орден [полуросликов](PC_RACE:${PC_RACE_HALFLING}) из Люирена, приверженцы которого преображают природную непоколебимость в духовный путь собственного самосовершенствования и раскрытия потенциала. Несколько мастеров Кулака хин основали монастыри за пределами Люирена, где учение было доступно только [полуросликам](PC_RACE:${PC_RACE_HALFLING}), сделав его открытым для учеников иных рас, готовых идти по пути указанному [Йондаллой](GOD:${GOD_YONDALLA}). Монахи Кулака хин обычно следуют [Пути открытой ладони](PC_SUBCLASS:${PC_SUBCLASS_MONK_WAY_OF_THE_OPEN_HAND}).

# Орден жёлтой розы

Также известный как Ученики святых соларов и Дважды-мученики, орден Жёлтой Розы — уединенный монастырь почитателей [Ильматера](GOD:${GOD_ILMATER}) расположенный в горах Каменного Шпиля в Дамаре. Он известен верностью союзникам и беспощадностью к врагам. Относясь с глубоким уважением к вопросам правды и дипломатии, монахи упорно трудятся, чтобы прожить в своём изолированном святилище. Монахи из монастыря Жёлтой розы используют [реморазов](CREATURE:${CREATURE_REMORHAZ}), чтобы проверять своих учеников. Молодые монахи должны доказать превосходство своего разума над болью и страхом, удерживаясь верхом на этих чудовищах.

Церковь [Ильматера](GOD:${GOD_ILMATER}) включает в себя намного больше орденов монахов, чем у прочих богов. К другим монашеским орденам [Ильматера](GOD:${GOD_ILMATER}) относятся Последователи Беспрепятственного Пути, Ученики Святого Моргана Молчаливого и Сёстры Святого Джаспера из Скал.

Монахи [Ильматера](GOD:${GOD_ILMATER}) часто путешествуют в качестве просящих милостыню странников, ища просветления и облегчая чужие страдания. Обычно они следуют [Пути открытой ладони](PC_SUBCLASS:${PC_SUBCLASS_MONK_WAY_OF_THE_OPEN_HAND}).`,
      source: {
        id: SOURCE_SCAG,
        page: 130,
      },
    },
    {
      header: 'Монах',
      text: `Вы укрощаете силу своего космического просветления, ловко уворачиваясь и умело разя врагов вихрем ошеломляющих ударов и искусных боевых приёмов.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать монаха, следуя этим рекомендациям.

1. Во-первых, у Вашей Ловкости должно быть наивысшее значение. Следующим по величине должно быть значение Мудрости.
2. Во-вторых, выберите предысторию «[Отшельник](BACKGROUND:${BACKGROUND_HERMIT})».`,
      source: {
        id: SOURCE_PHB,
        page: 78,
      },
    },
  ],
  note: [
    {
      text: `Не принимай моё молчание за принятие твоих злодеяний. Пока ты угрожал и хвастался, я придумал, как сломать тебе шею голыми руками четырьмя разными способами.`,
      author: `Эмбер, великий мастер цветов`,
    },
    {
      text: `Думаю, я смог бы стать монахом, если бы захотел. Что? Почему ты смеёшься?`,
      author: `Занатар`,
    },
    {
      text: `Толстяк поправил свой черный с золотом пояс и бросился вперед.

Даника подождала, когда он окажется прямо перед ней, а для зрителей это выглядело, как будто девушку сейчас погребет под горой плоти. В последний момент она проскользнула мимо него, схватила занесенную для удара руку и небрежно шагнула в сторону, когда тот пронесся мимо. Ловкий поворот запястья заставил его встать как вкопанного и, прежде чем жрец успел заметить, девушка пнула его под колени, повалив на пол.`,
      author: `Р. Э. Сальваторе, «Гимн Хаоса»`,
    },
  ],
  featureCollection: {
    hitDice: 8,
    proficiency: {
      weapon: {
        catList: [
          CAT_SIMPLE_WEAPON,
        ],
        gearList: [
          GEAR_SHORTSWORD,
        ],
      },
      savingThrow: [
        PARAM_DEX,
        PARAM_STR,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_ACROBATICS,
            SKILL_ATHLETICS,
            SKILL_HISTORY,
            SKILL_INSIGHT,
            SKILL_RELIGION,
            SKILL_STEALTH,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 5,
        diceType: 4,
      },
      text: `
1. Выберите одно
    * а) [Короткий меч](GEAR:${GEAR_SHORTSWORD})
    * б) Любое простое оружие
2. Выберите одно
    * а) [Набор исследователя подземелий](GEAR:${GEAR_DUNGEONEER_S_PACK})
    * б) [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})
3. Десять [дротиков](GEAR:${GEAR_DART})`,
    },
  },
  progressTable: `
| Уровень | Умения                                             | Боевые искусства | Очки ци | Скорость без доспехов |
|---------|----------------------------------------------------|------------------|---------|-----------------------|
|  1      | [Защита без доспехов](FEATURE:${FEATURE_UNARMORED_DEFENSE_MONK}), [Боевые искусства](FEATURE:${FEATURE_MARTIAL_ARTS})              | 1к4              |         | —               |
|  2      | [Движение без доспехов](FEATURE:${FEATURE_UNARMORED_MOVEMENT}), [Ци](FEATURE:${FEATURE_KI})                          | 1к4              |  2      | +10 фт.               |
|  3      | [Монастырская традиция](FEATURE:${FEATURE_MONASTIC_TRADITION}), [Отражение снарядов](FEATURE:${FEATURE_DEFLECT_MISSILES})          | 1к4              |  3      | +10 фт.               |
|  4      | [Медленное падение](FEATURE:${FEATURE_SLOW_FALL}), [Увеличение характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_MONK})        | 1к4              |  4      | +10 фт.               |
|  5      | [Дополнительная атака](FEATURE:${FEATURE_EXTRA_ATTACK_MONK}), [Ошеломляющий удар](FEATURE:${FEATURE_STUNNING_STRIKE})            | 1к6              |  5      | +10 фт.               |
|  6      | Умение монастырской традиции, [Энергетические удары](FEATURE:${FEATURE_KI_EMPOWERED_STRIKES}) | 1к6              |  6      | +15 фт.               |
|  7      | [Спокойствие разума](FEATURE:${FEATURE_STILLNESS_OF_MIND}), [Увёртливость](FEATURE:${FEATURE_EVASION_MONK})                   | 1к6              |  7      | +15 фт.               |
|  8      | Увеличение характеристик                           | 1к6              |  8      | +15 фт.               |
|  9      | Улучшенное движение без доспехов                   | 1к6              |  9      | +15 фт.               |
| 10      | [Чистота тела](FEATURE:${FEATURE_PURITY_OF_BODY})  | 1к6              | 10      | +20 фт.               |
| 11      | Умение монастырской традиции                       | 1к8              | 11      | +20 фт.               |
| 12      | Увеличение характеристик                           | 1к8              | 12      | +20 фт.               |
| 13      | [Язык солнца и луны](FEATURE:${FEATURE_TONGUE_OF_THE_SUN_AND_MOON})                                 | 1к8              | 13      | +20 фт.               |
| 14      | [Алмазная душа](FEATURE:${FEATURE_DIAMOND_SOUL})   | 1к8              | 14      | +25 фт.               |
| 15      | [Безвременное тело](FEATURE:${FEATURE_TIMELESS_BODY_MONK})                                  | 1к8              | 15      | +25 фт.               |
| 16      | Увеличение характеристик                           | 1к8              | 16      | +25 фт.               |
| 17      | Умение монастырской традиции                       | 1к10             | 17      | +25 фт.               |
| 18      | [Пустое тело](FEATURE:${FEATURE_EMPTY_BODY})       | 1к10             | 18      | +30 фт.               |
| 19      | Увеличение характеристик                           | 1к10             | 19      | +30 фт.               |
| 20      | [Совершенство](FEATURE:${FEATURE_PERFECT_SELF})    | 1к10             | 20      | +30 фт.               |
`,
  source: [
    {
      id: SOURCE_PHB,
      page: 77,
    },
    {
      id: SOURCE_SRD,
      page: 26,
    },
  ],
}
