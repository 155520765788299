const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_BURNING_HANDS,
  SPELL_DISGUISE_SELF,
  SPELL_FIRE_BOLT,
  SPELL_MENDING,
  SPELL_PRESTIDIGITATION,
  SPELL_SHIELD,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_APPRENTICE_WIZARD} = require('./../../../../creatureIdList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ученик волшебника',
  nameEn: 'Apprentice Wizard',
  id: CREATURE_APPRENTICE_WIZARD,
  description: `Ученики — новички среди заклинателей тайной магии, которые служат у более опытного волшебника или учатся в школе. Они выполняют чёрную работу, такую как готовка и уборка, в обмен на обучение магии.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 222,
  },
  armor: 10,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 10,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      comment: 'обычно — Всеобщий',
    },
  ],
  cr: CR_1_4,
  spellCast: {
    spellCasterLevel: 1,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_BURNING_HANDS,
      SPELL_DISGUISE_SELF,
      SPELL_FIRE_BOLT,
      SPELL_MENDING,
      SPELL_PRESTIDIGITATION,
      SPELL_SHIELD,
    ],
    slotCountList: [
      Infinity,
      2,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
