const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_SLAAD,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_BEHOLDERKIN_SPIRIT,
  CREATURE_SLAADI_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух слаада',
  nameEn: 'Slaadi Spirit',
  id: CREATURE_SLAADI_SPIRIT,
  parentId: CREATURE_BEHOLDERKIN_SPIRIT,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_SLAAD,
  ],
  speed: {
    [SPEED_WALK]: 30,
  },
  featureList: [
    {
      id: ABILITY_REGENERATION,
      regen: 5,
      other: `, если у ★него★ есть хотя бы 1 хит.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Когти',
      description: `Если цель — существо, оно не может восстанавливать хиты, до начала следующего хода ★СУЩЕСТВО★`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
}
