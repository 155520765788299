const {SOURCE_MTOF} = require('./../../sourceList')
const {GOD_RAVEN_QUEEN} = require('./../../godIdList')

module.exports = [
  {
    header: 'Шадар-каи',
    text: `В вечном мраке сумрака живёт общество, прислуживающее [Королеве Воронов](GOD:${GOD_RAVEN_QUEEN}). Они были принесены в это тёмное царство в прошлые века так давно, что теперь идеально адаптировались к этой угрюмой среде, как физически, так и умственно.`,
    source: {
      id: SOURCE_MTOF,
      page: 239,
    },
  },
  {
    header: 'Хранители душ',
    text: `Шадар-каи наблюдают за Царством Теней и материальным миром, разыскивая души и трагические события, которые могут порадовать их [божество](GOD:${GOD_RAVEN_QUEEN}). По слухам, они способны повлиять на мировые события трагическим образом ради [Её](GOD:${GOD_RAVEN_QUEEN}) удовольствия. Тем не менее, [Королева Воронов](GOD:${GOD_RAVEN_QUEEN}) представляет загадку даже для своих самых преданных последователей. Усилия шадар-каев вознаграждаются только неопределёнными предзнаменованиями, которые они интерпретируют как могут.`,
    source: {
      id: SOURCE_MTOF,
      page: 239,
    },
  },
  {
    header: `Слуги Королевы`,
    text: `Шадар-каи связаны с [Королевой Воронов](GOD:${GOD_RAVEN_QUEEN}), и обречены на вечную службу ей в Царстве Теней. Они обитают в различных местах, снаружи Крепости Воспоминаний, и обычно боятся входить в эту крепость по собственной воле. В своём обществе они воспроизводят свои прежние обряды и церемонии, что проводили когда-то, во времена светлых дней, когда они жили в мире и процветали в своём уже давно утерянном королевстве.

Когда шадар-каи вступают в Царство Теней, в их старых и чахлых лицах и телах, видна вся полнота последствий ужасной магии, что лишила их прежней эльфийской красоты. Чтобы скрыть свою внешность, они часто носят маски из дерева или металла, но даже эти маски не спрячут печаль их внешности. Когда шадар-каев посылают за пределы Царства Теней выполнить какое-либо распоряжение [Королевы Воронов](GOD:${GOD_RAVEN_QUEEN}), то они становятся молодыми, как и эльфы этого мира, хоть и их кожа остаётся мрачно бледного оттенка.`,
    source: {
      id: SOURCE_MTOF,
      page: 60,
    },
  },
  {
    header: `Бессмертные Слуги`,
    text: `Шадар-каи знают, что, когда они умрут, Королева Воронов поймает их души и вернёт их обратно в Царство Теней, где они возродятся, чтобы снова ей служить. В результате, они считают смерть временным состоянием, и многих шадар-каев мало заботит физическое тело, в котором в данный момент обитает их дух.

Шадар-каям ведомо, что тот, кто пришёл по своей воле к башне Королевы Воронов, будет о чём-то просить её, и поэтому пытаются подготовить таких посетителей к тому, с чем им придётся столкнуться. Слуги Королевы разговаривают с любым заинтересованным искателем приключений о всплеске эмоций, о том, с какой горечью придётся путешественнику столкнуться в Царстве Теней, и о том, как пройти испытание Королевы Воронов.`,
    source: {
      id: SOURCE_MTOF,
      page: 60,
    },
  },
  {
    header: `Следуйте за воронами`,
    text: `Когда Королева Воронов замечает душу или заинтересуется долей информации, которую она желает, то она посылает своих воронов, чтобы предупредить шадар-каев. Затем её приспешники, полагаясь на этих таинственных и каркающих проводников, что направляют шадар-каев к месту, где барьеры между планами настолько слабы, что можно попасть в нужное место их назначения. Прибыв на место назначения, шадар-каи следят и выжидают трагическое событие, которое желает заполучить их Королева. Иногда такое событие незначительное, вроде отвергнутого любовника, потерянного предмета или предательства. Но некоторые события гораздо трагичнее любых других, например: убийство, война или сделка с дьяволом. Шадар-каи используют свою магию тени, чтобы доставить нужную королеве безделушку. Если цель жива, они волшебным образом проникают в сознание индивида и поглощают желаемые фрагменты эмоций, либо если цель находится при смерти, шадар-каи захватывают душу полностью, и доставляют её Королеве Воронов.`,
    source: {
      id: SOURCE_MTOF,
      page: 60,
    },
  },
  {
    header: `Осадок воспоминаний`,
    text: `Шадар-каи весьма заинтересованы магическим илом на дне реки Стикс, в котором хранятся воспоминания и сама личность потерянных душ. Любые искатели приключений, готовые отправится в Девять Преисподней, чтобы добыть флакон такого зелья, скорее всего, привлекут внимание шадар-каев, которые попытаются украсть или выменять его. Искатели приключений также могут преподнести такие осадки в виде дара [Королеве Воронов](GOD:${GOD_RAVEN_QUEEN}). Никому не известно, что она может даровать взамен, но её дары могут быть разных и чудесных форм, например: восстановление потерянной души, возвращение потерянного воспоминания, или беглый взгляд на забытые знания древних.`,
    source: {
      id: SOURCE_MTOF,
      page: 61,
    },
  },
]
