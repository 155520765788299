const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_ULOUSHINN} = require('./../../languageIdList')
const {LANG_SUBGROUP_LOW_ULUTIM} = require('./../../languageSubGroupList')

module.exports = {
  id: LANG_ULOUSHINN,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_LOW_ULUTIM,
  nameEn: 'Uloushinn',
  name: {
    nominative: 'Улушин',
    genitive: 'Улушина',
    instrumental: 'Улушином',
    prepositional: 'Улушине',
  },
  isReady: false,
  isRealLang: true,
}
