const {
  SOURCE_GAME_BG_3,
  GENDER_MALE,
} = require('./../../genderList')
const {CREATURE_IMP} = require('./../../creatureIdList')
const {CREATURE_TYPE_MEPHIT} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_MEPHIT,
  nameEn: 'Mephit',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  description: {
    header: 'Мефит',
    text: `Мефиты капризные, вульгарные, а  милыми их могут назвать разве что их хозяева. Это любопытные напоминающие [бесов](CREATURE:${CREATURE_IMP}) существа со Стихийных планов.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  name: {
    singular: {
      nominative: 'мефит',
      genitive: 'мефита',
      dative: 'мефиту',
      accusative: 'мефита',
      instrumental: 'мефитом',
      prepositional: 'мефите',
    },
    plural: {
      nominative: 'мефиты',
      genitive: 'мефитов',
      dative: 'мефитам',
      accusative: 'мефитов',
      instrumental: 'мефитами',
      prepositional: 'мефитах',
    },
  },
}
