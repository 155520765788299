module.exports = [
  {
    text: `Представьте себе улей муравьёв размером с лошадей, но одетых в броню. Затем истребите их.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Прочие существа, что проживают в ульях, выполняют свою природную роль: пчёлы опыляют цветы, термиты грызут деревья. А крутики, напротив, только мешают обществу. Быть может их роль в природе столь же необходима, что и у других насекомых.`,
    author: `Архимаг Морденкайнен`,
  },
]
