const {ALPHABET_TIRSU} = require('./../../alphabetList')
const {LANG_GITH} = require('./../../languageIdList')
const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {
  SOURCE_ADND2_AGAP,
  SOURCE_DND4_MotP,
  SOURCE_GAME_BG_3,
  SOURCE_MGZN_DNGN_100,
  SOURCE_MGZN_DRGN_378,
  SOURCE_MGZN_PLHDRN_159,
} = require('./../../sourceList')

module.exports = {
  id: LANG_GITH,
  alphabetId: ALPHABET_TIRSU,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Гитский',
    genitive: 'Гитского',
    instrumental: 'Гитским',
    prepositional: 'Гитском',
  },
  description: {
    header: 'Язык гитьянки',
    text: `Транслитерация языка гитьянки выглядит пугающе, в основном из-за множества апострофов. Но апострофы не произносятся, а просто обозначают соединения отдельных морфем в единое слово. Например, в титуле _гиш’сарат_, апостроф соединяет две независимые смысловые единицы: _гиш_ , или «умелый», означает гитьянки, пользующегося как оружием, так и заклинаниями; _сарат_ же — модификация от _сарт_, обычно переводимого как «сержант», командир наименьшего военного отряда армии гитьянки. Вместе этот титул произносится «гиш’-сар-ат», без звукового выделения апострофа, кроме, разве что, небольшой паузы.`,
    source: {
      id: SOURCE_MGZN_PLHDRN_159,
      page: 10,
    },
  },
  dictionary: [
    {
      list: [
        // ['', 'виниск'],
        // ['', 'глик'], // ругательство
        // ['', 'зайтиск'],
        // ['', 'паврилк'], // ругательство
        // ['', 'шкакет'], // ругательство
        // ['', 'чрайт'], // ругательство // Ты умрёшь от моего меча, чрайт!
        // ['', 'шкатс зай'], // преклоняют колени
        // ['', 'Влаакит тавки назин'], // это какое-то безумие
        // ['великая?', 'гха’г'], // Влаакит гха’г шкатс зай
        // ['Ты знаешь меня Влаакит?', 'Чимар зал’а Влаакит'], // Чимар зал’а Влаакит! Ты знаешь меня
        ['старший', 'джестил'],
        ['женщина', 'растил'],
        ['инквизиция', 'чи’р’аи'], // chr’a’i
        ['мальчик', 'кчакхи'],
        ['мой клинок в покое', 'ска-кек кир Гит шабэллет'], // Ска-кек кир Гит шабэллет. Мой клинок в покое. Матерь Гит призывает тебя выслушать, что я скажу.
        ['дерьмо', 'цк’ва'],
        ['идеалист', 'шелак'],
        ['лекарь', 'густиль'], // healer (ghustil)
        ['Лишь во Влаакит обретаем мы свет', 'Влаакит’ка свим храт краш’хт'],
        ['наутилоид', 'гх’ат'],
        ['предатель', 'хшар-лак'],
        ['псионический детектор', 'ква’нит'],
        ['ребёнок', 'янк'],
        ['рыцарь', 'кит’рак'],
        ['свежеватель разума', 'гхайк'],
        ['иллитид', 'гхайк'],
        ['сестра-во-свободе', 'тэлакма-гит'],
        ['убийство слабейших', 'дже-квит двензир'],
        ['чужак (не-гит)', 'исстик'],
      ],
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      list: [
        ['капитан', 'кит’рак'],
        ['сержант', 'сарт'],
      ],
      source: {
        id: SOURCE_ADND2_AGAP,
        page: 49,
      },
    },
    {
      list: [
        ['дети Гит', 'гитьянки'],
      ],
      source: {
        id: SOURCE_MGZN_PLHDRN_159,
        page: 6,
      },
    },
    {
      list: [
        ['сержант', 'сарат'],
        ['умелый', 'гиш'],
        ['ученик', 'ги'],
      ],
      source: {
        id: SOURCE_MGZN_PLHDRN_159,
        page: 10,
      },
    },
    {
      list: [
        ['провидец', 'хр’а’книр'], // seer (hr’a’cknir)
      ],
      source: {
        id: SOURCE_MGZN_DNGN_100,
        page: 98,
      },
    },
    {
      list: [
        ['драконье дыхание', 'драа’звир'], // dragons’ breath (draa’zvir)
        ['мозговой огонь', 'г’ель’зор'], // brainfire (g’hel’zor)
        ['призыв мучений', 'ир’жарн'], // recall agony (ir’zharn)
        ['кристаллизация', 'мирханак'], // crystallize (mirhanac)
        ['эктоплазматический взрыв', 'рзиду’ун'], // extoplasmic blast (rzydu’un)
        ['безумие', 'т’рак'], // insanity (t’rac)
      ],
      source: {
        id: SOURCE_MGZN_DNGN_100,
        page: 104,
      },
    },
    {
      list: [
        ['смерть', 'влаакит'], // death (vlaakith)
      ],
      source: {
        id: SOURCE_MGZN_DNGN_100,
        page: 105,
      },
    },
    {
      list: [
        ['пламя', 'з’зиш'], // flame (z’zish)
      ],
      source: {
        id: SOURCE_MGZN_DNGN_100,
        page: 114,
      },
    },
    {
      list: [
        ['предатель', 'киир’врах'], // betrayer (kiir’vrahc)
      ],
      source: {
        id: SOURCE_MGZN_DNGN_100,
        page: 115,
      },
    },
    {
      list: [
        ['выход', 'занкор'], // exit (zanhkor)
      ],
      source: {
        id: SOURCE_MGZN_DNGN_100,
        page: 116,
      },
    },
    {
      list: [
        ['кража', 'торуун'], // theft (toruun)
        ['одна кража поглотит всё', 'гир таррак нех торуун'], // One theft consumes all (Gir tarrac neh toruun)
      ],
      source: {
        id: SOURCE_MGZN_DNGN_100,
        page: 119,
      },
    },
    {
      list: [
        ['слуга', 'реврикал'], // servant (revrykal)
      ],
      source: {
        id: SOURCE_MGZN_DNGN_100,
        page: 124,
      },
    },
    // {
    //   list: [
    //     ['???', 'мир’р’тал'], // ??? (mir’r’tal)
    //   ],
    //   source: {
    //     id: SOURCE_MGZN_DNGN_100,
    //     page: 124,
    //   },
    // },
    // {
    //   list: [
    //     ['???', 'зак’р’лев'], // ??? (zac’r’lev)
    //   ],
    //   source: {
    //     id: SOURCE_MGZN_DNGN_100,
    //     page: 130,
    //   },
    // },
    {
      list: [
        ['первейший', 'эдлишар'], // First among many (Adlishar)
      ],
      source: {
        id: SOURCE_DND4_MotP,
        page: 78,
      },
    },
    {
      list: [
        ['отказавшиеся от Гит', 'гитцераи'], // those who spurn Gith (githzerai)
      ],
      source: {
        id: SOURCE_MGZN_DRGN_378,
        page: 80,
      },
    },
    {
      list: [
        ['артефактор', 'млар'], // magical artisan and craftsman (mlar)
      ],
      source: {
        id: SOURCE_ADND2_AGAP,
        page: 50,
      },
    },
    {
      list: [
        ['астральный механик', 'вар’ит’н'], // craftsman (var’ith’n)
        ['астральный навигатор', 'сенжа’си'], // seer (senja’si)
        ['астральный штурман', 'и’рн'], // (y’rn)
        ['астральный офицер', 'хр’а’книр'], // (hr’a’cknir)
        ['смотритель', 'варш'], // caretaker (varsh)
        ['фермер', 'г’ласк'], // farmer (g’lathk)
        ['дух меча', 'тл’а’йкит'], // sword spirit (tl’a’ikith)
      ],
      source: {
        id: SOURCE_ADND2_AGAP,
        page: 51,
      },
    },
  ],
  nameEn: 'Gith',
  isReady: true,
  isRealLang: true,
}
