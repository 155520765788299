const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_DEEP_SPEECH,
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  SPELL_MIND_BLANK,
  SPELL_NONDETECTION,
  SPELL_WALL_OF_FORCE,
} = require('./../../../../spellIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_14} = require('./../../../../crList')
const {CREATURE_ELDER_BRAIN} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {illithidDescriptionList} = require('./../../../../textCommonParts')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_VGTM,
} = require('./../../../../sourceList')

module.exports = {
  name: 'Старший мозг',
  nameEn: 'Elder Brain',
  id: CREATURE_ELDER_BRAIN,
  description: [
    `Высшая форма доминирования иллитидов — старший мозг, нежится в ванне вязкого соляного раствора и прикасается к мыслям существ вблизи и вдали от себя. Он небрежно рисует на холсте их разумов, переписывая их мысли и затуманивая их сны.`,
    {
      header: 'Психическое вторжение',
      text: `Когда старший мозг вторгается в разум, то он меняет восприятие существа и обманывает его чувства, заставляя видеть, слышать, чувствовать вкус или ощущать реальность так, как желает старший мозг. На больших расстояниях он вкладывает подсознательные предложения или тонко влияет на сны и подталкивает существ к тому курсу действий, который идет на пользу его великому плану.

Если его коварные предложения не закрепляются в голове, то старший мозг утверждает свое доминирование более непосредственно. Он устанавливает контроль над сопротивляющимся разумом и контролирует тело существа как куклу. Против редких, стойких существ с сильной волей, которые отрицают его или нападают сами, старший мозг посылает заряд ошеломляющей психической силы для разрушения разума выскочки, оставляя от существа бездумную, распускающую слюни оболочку.`,
      source: {
        id: SOURCE_VGTM,
        page: 189,
      },
    },
    {
      header: 'Пожиратель мыслей',
      text: `Старший мозг питается, поглощая мозги других существ. Когда служащие свежеватели разума, которые охраняют и как правило носят пищу старшему мозгу напрямую, не справляются со своей задачей, старший мозг протягивается мысленными усиками, убеждая существ прийти к нему, чтобы он мог их съесть.

Когда свежеватель разума умирает, слуги старшего мозга скармливают содержимое его черепа своему хозяину, который поглощает мозг иллитида и все содержащиеся в нём знания и опыт. Таким способом старший мозг постоянно увеличивает свои знания, объединяя мысли и опыт колонии иллитидов в единое целое. Свежеватели разума считают это «единение» священным состоянием так же как поклоняющиеся человеческому божеству видят вечную жизнь после смерти в раю — так как старший мозг может вызвать личность любого иллитида, которого поглотил.`,
      source: {
        id: SOURCE_VGTM,
        page: 189,
      },
    },
    {
      header: 'Улей разумов',
      text: `Не-иллитиды называют это существо старшим мозгом, потому что он действует как центральный коммуникативный узел всей колонии свежевателей разума, также как и мозг для живого тела. Колония, подсоединенная к старшему мозгу, действует как единый организм, единым образом, как если бы каждый иллитид был бы пальцем руки.`,
      source: {
        id: SOURCE_VGTM,
        page: 189,
      },
    },
    {
      header: 'Невероятное эго',
      text: `Каждый старший мозг считает себя и свои желания самыми важными вещами в мультивселенной, а свежевателей разума в своей колонии не более чем продолжением своей воли. Нет двух одинаковых старших мозгов, и каждый руководит своей колонией по-разному в соответствии со своей уникальной личностью и запасом собранных знаний и опыта. Некоторые старшие мозги правят как доминирующие тираны, а другие держатся более милостиво как мудрецы, советники и хранилища знаний и легенд для свежевателей разума, которые защищают и питают его.

Амбиции старшего мозга всегда умерены его относительной неподвижностью. Хотя его телепатические чувства могут распространяться на мили, двигаться куда-либо это всегда опасное предприятие. Если его вынудят выйти из бассейна солевого раствора, то старший мозг быстро угаснет, а частые перемещения старшего мозга в его бассейне через тесные и извилистые подземные тоннели очень трудны или невозможны.`,
      source: {
        id: SOURCE_VGTM,
        page: 189,
      },
    },
    {
      header: 'Исследования старших мозгов',
      text: `_Анализ поведения старших мозгов в их естественной среде обитания_

Старший мозг  иллитидов — это невероятное существо. Абсолютный правитель своей колонии иллитидов. Движущая сила размножения через порождение личинок иллитидов, так называемых паразитов. Но прежде всего старший мозг — это сверхмощная органическая счётная машина, ментальный автомат, способный одновременно обрабатывать огромное количество мыслительных процессов. Он всегда на сто ходов впереди, и его нельзя удивить ничем, кроме как внезапным стечением неожиданных обстоятельств. Гений и инновации смертных не идут ни в какое сравнение с его огромной вычислительной мощью.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: 'Логово старшего мозга',
      text: `Логово старшего мозга всегда лежит глубоко в сердце колонии свежевателей разума. Существо обитает в тускло светящемся бассейне соляного раствора, наполненного отвратительной и солоноватой водой, насыщенной жизненными флюидами старшего мозга и псионической энергией.

# Действия логова

Сражаясь в логове, старший мозг может использовать действия логова. При инициативе 20 (проигрыш при ничье) старший мозг может сделать одно действие в логове, чтобы вызвать один из следующих эффектов; старший мозг не может использовать одно и то же действие два раунда подряд: 

* Старший мозг сотворяет [Силовую стену](SPELL:${SPELL_WALL_OF_FORCE}). 
* Старший мозг выбирает целью одно дружелюбное существо, которое может почувствовать не далее 120 футов от него. Цель получает вспышку вдохновения и получает преимущество на одну атаку, проверку способности или испытание, которые он делает до конца своего следующего хода. Если цель не хочет или не может использовать это преимущество, то вдохновение теряется. 
* Старший мозг выбирает целью одно существо, которое может почувствовать не далее 120 футов от себя и пригвозждает его силой воли. Цель должна успешно пройти испытание Харизмы СЛ 18 или не сможет покинуть свое текущее место. Цель может повторять испытание в конце каждого своего хода, и закончить эффект при успехе.

# Воздействия на местность

Территория в радиусе 5 миль от старшего мозга изменяется псионическим присутствием существа, что создает один или несколько из следующих эффектов: 

* Существа не далее 5 миль от старшего мозга чувствуют как за ними следят, даже если на самом деле ничего такого не происходит. 
* Старший мозг может подслушать любое телепатические разговоры, которые происходят не далее 5 миль от него. Существо, которое инициировало телепатический разговор делает проверку Мудрости (Проницательность) СЛ 18, когда телепатический контакт налаживается в первый раз. Если проверка пройдена успешно, то существо осознает, что кто-то подслушивает разговор. Природа подслушивающего не ясна, и старший мозг не может участвовать в телепатическом разговоре, если только он не сформировал психическую связь с существом, которое его начало. 
* Любое существо, с которым старший мозг сформировал психическую связь, слышит слабые неразличимые шепотки в глубочайших уголках разума. Психические осколки состоят из бессвязных мыслей старшего мозга смешанных с мыслями остальных существ, с которыми он связан.

Если старший мозг умирает, то эти эффекты немедленно заканчиваются.`,
      source: {
        id: SOURCE_VGTM,
        page: 189,
      },
    },
    ...illithidDescriptionList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 190,
  },
  armor: 10,
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_SWIM]: 10,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 20,
    [PARAM_INT]: 21,
    [PARAM_WIT]: 19,
    [PARAM_CHA]: 24,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_DOUBLE,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: [
        LANG_COMMON,
        LANG_DEEP_SPEECH,
        LANG_UNDERCOMMON,
      ],
      doNotSpeak: true,
    },
    {
      id: LANG_TELEPATHY,
      range: `5 миль`,
    },
  ],
  cr: CR_14,
  featureList: [
    {
      name: 'Чутье существ',
      description: `Старший мозг знает о присутствии существ в радиусе 5 миль от себя, чей Интеллект 4 или выше. Он знает расстояние и направление до каждого существа, а так же значение параметра Интеллект, но больше ничего не может почувствовать. Существо, защищённое заклинанием [Сокрытие разума](SPELL:${SPELL_MIND_BLANK}), [Необнаружимость](SPELL:${SPELL_NONDETECTION}) или похожей магией, он почувствовать не может.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Усилитель телепатии',
      description: `Старший мозг может использовать телепатию для начала и поддержания телепатических разговоров с десятью существами одновременно. Старший мозг может разрешить этим существам телепатически слышать друг друга во время такого соединения.`,
    },
  ],
  actionList: [
    {
      name: 'Щупальца',
      description: `Если размер цели Огромный или меньше, то она будет схвачена (СЛ высвобождения 15) и получает 9 (1к8 + 5) психического урона начале каждого своего хода пока не окончится захват. Старший мозг может держать в захвате одновременно до четырех целей.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 30,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Взрыв разума',
      restore: 5,
      description: `Старший мозг магически испускает психическую энергию. Существа по выбору старшего мозга, находящиеся не далее 60 футов от него, должны пройти испытание Интеллекта СЛ 18 или получить 32 (5к10 + 5) психического урона и стать ошеломленными на 1 минуту. Цель может повторять испытание в конце каждого своего хода и окончить эффект при успехе.`,
    },
    {
      name: 'Психическая связь',
      description: `Старший мозг выбирает целью одно недееспособное существо, которое может почувствовать своим действием _Чутьё существ_, и устанавливает с ним _Психическую связь_. До окончания этой связи старший мозг может воспринимать всё чувствами цели. Цель знает о том, что что-то подсоединилось к её разуму, если она уже дееспособна. Старший мозг может разорвать связь в любой момент (действие не требуется). Цель может попытаться разорвать психическую связь  действием в свой ход, пройдя испытание Харизмы СЛ 18. При успехе цель получает 10 (3к6) психического урона. Психическая связь также разрывается если цель и старший мозг находятся далее 5 миль друг от друга, и тогда цель не испытывает никаких последствий. Старший мозг может сформировать до 10 психических связей одновременно.`,
    },
    {
      name: 'Чутьё мыслей',
      description: `Старший мозг выбирает целью существо, с которым у него _Психическая связь_. Старший мозг получает видение мотивов цели, её эмоционального состояния и мыслей, которые занимают её разум (включают вещи о которых заботится цель, любит или ненавидит). Старший мозг также может сделать проверку Харизмы (Обман) с преимуществом, чтобы обмануть разум цели и заставить её верить в одну идею или испытывать конкретную эмоцию. Цель соревнуется с этой попыткой проверкой Мудрости (Проницательность). Если старший мозг достигает успеха, то разум верит в обман 1 час или пока ложь не станет очевидной.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Щупальца',
      description: `Старший мозг делает одну атаку _Щупальцами_.`,
    },
    {
      name: 'Слом концентрации',
      description: `Старший мозг выбирает целью существо не далее 120 футов от себя, с которым у него есть _Психическая связь_. Старший Мозг ломает концентрацию существа на заклинании, которое оно сотворило. Существо также получает 1к4 психического урона за каждый круг заклинания.`,
    },
    {
      name: 'Психическая пульсация',
      description: `Старший мозг выбирает целью существо не далее 120 футов от себя, с которым у него есть _Психическая связь_. Враги старшего мозга, находящиеся не далее 10 футов от этого существа получают 10 (3к6) психического урона.`,
    },
    {
      name: 'Разрыв психической связи',
      description: `Старший мозг выбирает целью существо, которое находится не далее 120 футов от него и имеет с ним _Психическую связь_. Старший мозг разрывает связь и существо получает помехи на все проверки способностей, броски на попадание и испытания до конца своего следующего хода. `,
    },
  ],
}
