const {
  SPELL_BANISHMENT,
  SPELL_CALM_EMOTIONS,
  SPELL_COMMAND,
  SPELL_CONTROL_WEATHER,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CURE_WOUNDS,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DISPEL_MAGIC,
  SPELL_ETHEREALNESS,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GASEOUS_FORM,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_HEROES_FEAST,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHT,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MENDING,
  SPELL_PLANE_SHIFT,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_REMOVE_CURSE,
  SPELL_SACRED_FLAME,
  SPELL_SANCTUARY,
  SPELL_SCRYING,
  SPELL_SENDING,
  SPELL_SILENCE,
  SPELL_SPARE_THE_DYING,
  SPELL_THAUMATURGY,
  SPELL_TRUE_RESURRECTION,
  SPELL_TRUE_SEEING,
  SPELL_WIND_WALK,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_DEER,
  CREATURE_ELEPHANT,
  CREATURE_KI_RIN,
  CREATURE_UNICORN,
} = require('./../../../../creatureIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  GOD_ILMATER,
  GOD_TORM,
  GOD_TYR,
} = require('./../../../../godIdList')
const {ALIGNMENT_LG} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_12} = require('./../../../../crList')
const {CREATURE_TYPE_CELESTIAL} = require('./../../../../creatureTypeIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Ки-рин',
  nameEn: 'Ki-rin',
  id: CREATURE_KI_RIN,
  description: [
    `Ки-рины — это благородные, небесные существа. Во Внешних Планах, ки-рины на службе доброжелательных божеств принимают прямое участие в вечной борьбе между добром и злом. В мире смертных ки-рин широко известен как предвестник судьбы, стражник святых и противопоставление силам зла.`,
    {
      header: 'Воплощение добра',
      text: `Ки-рин — воплощение добра и просто созерцая его наблюдатель может испытать страх или трепет. Обычно ки-рин выглядит как мускулистый [олень](CREATURE:${CREATURE_DEER}) размером со [слона](CREATURE:${CREATURE_ELEPHANT}), покрытый золотой чешуей, местами сочетающейся с золотым мехом. У него тёмно-золотая грива и хвост, медные раздвоенные копыта и спиралевидный медный рог между и над светящимися фиолетовыми глазами. На ветру или в воздухе чешуя существа и его волосы создают впечатление, что ки-рин горит святым, золотым огнем.

Кроме расцветки ки-рины различаются по внешнему виду, в зависимости от божества, которого почитают и функции, которую обычно выполняют на службе у него. Некоторые имеют форму коня и выглядят как гигантские [единороги](CREATURE:${CREATURE_UNICORN}), и часто используются как стражи. У других есть драконьи черты и они обычно агрессивные враги зла. Обычно у них один рог, но ки-рин с яростным поведением может иметь два рога или рога как у большого [оленя](CREATURE:${CREATURE_DEER}).`,
      source: {
        id: SOURCE_VGTM,
        page: 158,
      },
    },
    {
      header: 'Источник блага',
      text: `Обычный народ считает ки-ринов редкими и уединенными вестниками счастья и удачи. Ки-рин, пролетающий над головой, считается благословением, и события, происходящие в такой день, особенно благоприятными. Если ки-рин появляется во время церемонии, такой как объявление о рождении или коронации, то все присутствующие понимают, что существо говорит им о великом благе, которое не за горами. Ки-рин передает свои дары и знамения, а потом уносится обратно в небеса. Ки-рины как известно также появляются на местах великих сражений для вдохновения и усиления стороны добра, или для спасения героев от неминуемой смерти.

Ки-рин занимает в мире территорию, за которой приглядывает, а один ки-рин может охранять область, которая охватывает несколько наций. В других планах ки-рины, которые служат добрым божествам, идут туда, куда им прикажут, что может включать перемещение в Материальный план по заданию. Ки-рин последователь обычно служит своему божеству разведчиком, вестником или шпионом.

Ки-рины привлекаются на службу божествам храбрости, верности, бескорыстия и правды, а так же для продвижения справедливых сообществ. Например, в Фаэруне, ки-рины в основном сплочены вокруг [Торма](GOD:${GOD_TORM}), хотя они так же могут служить [Тиру](GOD:${GOD_TYR}) и [Ильматеру](GOD:${GOD_ILMATER}).`,
      source: {
        id: SOURCE_VGTM,
        page: 158,
      },
    },
    {
      header: 'Объекты преклонения',
      text: `Поскольку ки-рин знаменит своей мудростью, другие существа, конечно же, ищут его, чтобы задать вопросы и изложить свои просьбы. В основном по этой причине он селится на вершине неприступного горного пика или в примерно такой же недоступной местности. Только те, у кого достаточно упорства для пугающего путешествия к логову ки-рина, могут доказать, что они достойны говорить с владельцем жилища.

Многие, кто ищет напутствия ки-рина, в конечном итоге обещают служить существу. Они обучаются как монахи под его руководством и служат его агентами в мире. Последователи ки-рина могут путешествовать по миру, искать новости о растущем зле и работать за кулисами, или могут стать чемпионами по велению своего хозяина, чтобы победить злодеев, где бы они не были.`,
      source: {
        id: SOURCE_VGTM,
        page: 158,
      },
    },
    {
      header: 'Логово роскоши',
      text: `На небесных планах, ки-рины живут в величественных, элегантных гнездах, наполненных объектами роскоши. В мире ки-рин выбирает схожее место, такое как вершина горы или внутри облака, которое делает твердым магия ки-рина. Снаружи логово ки-рина неотличимо от естественной местности, а вход тяжело найти и до него тяжело добраться посетителям. Внутри же, логово — это безмятежное и комфортное место, атмосфера в нём — что-то среднее между дворцом и храмом. Если ки-рин берёт себе существ в услужение, то логово становится вдвойне священным, так как здесь ки-рин не только отдыхает, но и учит святым тайнам.

Внутри логова ки-рин может вызывать предметы до трёх раз в день, используя одну из трёх версий этой силы по одному разу.

1. Одна версия навсегда создает достаточно объектов из мягкого, растительного материала, включая изготовленные объекты, такие как ткани, подушки, нитки, одеяла и одежда, чтобы полностью заполнить куб со стороной 20 футов.
2. Вторая версия навсегда создаёт достаточно объектов из дерева или похожего по твёрдости материала, чтобы заполнить куб со стороной 10 футов.
3. Третья версия создает достаточно объектов из камня или металла, чтобы заполнить куб со стороной 2 фута, но любые материалы, созданные таким образом, пропадают через 1 час.

# Региональные эффекты

Божественная природа ки-рина трансформирует область вокруг его логова. Любые из следующих магических эффектов путешественники могут встретить в окрестностях:
         
* Потоки воды очищаются в 3 милях от логова ки-рина. Любая возможная порча воды длится не долее 3 минут. 
* Растения, животные и все добрые существа в радиусе 3 миль от логова ки-рина получают энергию и эволюционируют в сторону идеализированной формы. Такие существа редко ведут себя агрессивно по отношению к тем, кто не является обычной добычей. Злые существа не выносят святой атмосферы в той же области и обычно селятся гораздо дальше от владений ки-рина. 
* Проклятия, болезни и яды, действующие на существ с добрым мировоззрением подавляются, когда существа находятся не далее чем в 3 милях от логова. 
* Ки-рин может сотворять [Власть над погодой](SPELL:${SPELL_CONTROL_WEATHER}) пока находится не далее 3 миль от логова. Точка применения всегда находится снаружи логова ближе всего к центру логова. Ки-рину не нужно держать небо в поле зрения или концентрироваться для сохранении изменения погоды. 
* Не далее 3 миль от логова, ветра останавливают не злых существ, которые падают без воздействия ки-рина и его союзников. Такие существа падают со скоростью 60 футов в раунд и не получают урона от падения.

Когда ки-рин умирает, все эти эффекты исчезают незамедлительно, хотя влияние энергии на флору и фауну действует ещё 3 года.`,
      source: {
        id: SOURCE_VGTM,
        page: 158,
      },
    },
  ],
  note: {
    text: `Впервые встретив ки-рина я был восхищен до слёз, я встретил бога`,
    author: `Воло`,
  },
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_LG,
  source: {
    id: SOURCE_VGTM,
    page: 157,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 60,
    [SPEED_FLY]: {
      value: 120,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 20,
    [PARAM_CHA]: 20,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_12,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_GASEOUS_FORM,
            SPELL_WIND_WALK,
            {
              id: SPELL_MAJOR_IMAGE,
              comment: '6 уровня',
            },
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_CREATE_FOOD_AND_WATER,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 18,
      spellCasterClass: PC_CLASS_CLERIC,
      spellIdList: [
        SPELL_LIGHT,
        SPELL_MENDING,
        SPELL_SACRED_FLAME,
        SPELL_SPARE_THE_DYING,
        SPELL_THAUMATURGY,
        SPELL_COMMAND,
        SPELL_CURE_WOUNDS,
        SPELL_DETECT_EVIL_AND_GOOD,
        SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
        SPELL_SANCTUARY,
        SPELL_CALM_EMOTIONS,
        SPELL_LESSER_RESTORATION,
        SPELL_SILENCE,
        SPELL_DISPEL_MAGIC,
        SPELL_REMOVE_CURSE,
        SPELL_SENDING,
        SPELL_BANISHMENT,
        SPELL_FREEDOM_OF_MOVEMENT,
        SPELL_GUARDIAN_OF_FAITH,
        SPELL_GREATER_RESTORATION,
        SPELL_MASS_CURE_WOUNDS,
        SPELL_SCRYING,
        SPELL_HEROES_FEAST,
        SPELL_TRUE_SEEING,
        SPELL_ETHEREALNESS,
        SPELL_PLANE_SHIFT,
        SPELL_CONTROL_WEATHER,
        SPELL_TRUE_RESURRECTION,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        3,
        1,
        1,
        1,
        1,
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Ки-рин делает три атаки: две _Копытами_ и одну _Рогом_.`,
    },
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Рог',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Обнаружение',
      description: `Ки-рин делает проверку Мудрости (Восприятие) или Мудрости (Проницательность).`,
    },
    {
      name: 'Кара',
      description: `Ки-рин делает атаку копытом или сотворяет [Священное пламя](SPELL:${SPELL_SACRED_FLAME}).`,
    },
    {
      name: 'Передвижение',
      description: `Ки-рин перемещается на расстояние до половины его скорости, не вызывая атак по возможности.`,
    },
  ],
}
