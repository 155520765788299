const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_9,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_SKELETON,
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    LANG_ALL,
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
    CONDITION_STUNNED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_DETECT_EVIL_AND_GOOD,
    SPELL_DETECT_THOUGHTS,
    SPELL_ETHEREALNESS,
    SPELL_TRUE_SEEING,
  } = require('./../../../../spellIdList'),
  {
    CAST_NONE,
  } = require('./../../../../castComponentList'),
  {
    ABILITY_TURN_IMMUNITY,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_DEMILICH,
    CREATURE_EYE_OF_FEAR_AND_FLAME,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Око страха и пламени',
  nameEn: 'Eye of Fear and Flame',
  id: CREATURE_EYE_OF_FEAR_AND_FLAME,
  description: `Око страха и пламени шествует по миру живых, заставляя людям совершать придуманные ими злодеяния или погибнуть. Если его приказам не подчиняются, Око страха и пламени отбрасывает капюшон, открывая свой череп — с самоцветами красным и чёрным самоцветом в глазнице — и нападает.

**Призрачный лик.** Глаз страха и пламени выглядит как скелетоподобная гуманоидная фигура с капюшоном. Под капюшоном — непроглядная чёрная пустота. Зловещие истории говорят об этих существах, бродящих по городским улицам в самые тёмные ночи и во времена беспокойств и волнений.

Говорят, что Очи страха и пламени были созданы хаотичными богами, для уничтожения принципиальных существ, или принципиальными богами для испытания своих последователей. Слухи утверждают, что существует только двадцать таких существ, но большая часть правды о них неизвестна.

**Жестокие кукловоды.** Око страха и пламени часто появляется во времена неопределённости, например, после разрушительного поражения некой группы или в момент принятия какого-то важного решения. Эта нежить стремится развратить уязвимых людей, особенно принципиального или доброго мировоззрений, ставя их перед выборами, которые испытывают их моральные устои. Паладину может быть приказано убить невинного, утверждая, что если этого не сделать, произойдёт какое-то большее зло. В конечном счёте, успех или неудача задачи куда менее интересны для Ока страха и пламени, чем подстрекательство к злодеяниям и общее распространение сопровождающих его хаоса и страданий.

**Мистические самоцветы.** Око страха и пламени сотворяет свою магию самоцветными глазами. В бою оно нацелено не только на своих противников, но и на невинных сторонних наблюдателей, стремясь вызвать наибольшую путаницу и разрушения. Если существо уничтожено, эти самоцветы можно достать, хотя они и считаются опасными проклятыми трофеями, вроде камней душ [демилича](CREATURE:${CREATURE_DEMILICH}). Тем не менее, они могут быть проданы некоторым проницательным и наглым коллекционерам.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 8,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_PSYCHIC,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    {
      id: LANG_ALL,
      creatureRange: 30,
    },
  ],
  cr: CR_9,
  featureList: [
    ABILITY_TURN_IMMUNITY,
  ],
  spellCast: {
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_EVIL_AND_GOOD,
          SPELL_DETECT_THOUGHTS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_ETHEREALNESS,
          SPELL_TRUE_SEEING,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Когтем_ и использует _Самоцветный взгляд_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 12,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Самоцветный взгляд',
      description: `★СУЩЕСТВО★ испускает один из своих магических глазных лучей, выбирая цель в пределах 90 футов.

1. **Глаз страха.** Цель и до четырёх других существ по выбору ★СУЩЕСТВО★ в пределах 10 футов от цели должны пройти испытание Мудрости СЛ 16 или стать Испуганными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
2. **Глаз пламени.** Цель должна пройти испытание Ловкости СЛ 16. При провале цель получает 44 (8к10) урона огнём и, если это существо или воспламеняемый объект, загорается. При успехе цель получает только половину урона и не загорается. Горящая цель получает 5 (1к10) огненного урона в начале каждого своего хода, пока не потушит пламя действием.`,
    },
  ],
}
