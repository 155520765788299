const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_DEINONYCHUS,
  CREATURE_VELOCIRAPTOR,
} = require('./../../../../creatureIdList')
const {ABILITY_POUNCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {dinoDescriptionList} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дейноних',
  nameEn: 'Deinonychus',
  id: CREATURE_DEINONYCHUS,
  description: [
    `Этот большой родственник [велоцираптора](CREATURE:${CREATURE_VELOCIRAPTOR}) убивает жертву, захватывая её когтями и питаясь, пока существо ещё живо.`,
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: [
    {
      id: SOURCE_VGTM,
      page: 151,
    },
    {
      id: SOURCE_TOA,
      page: 222,
    },
  ],
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  cr: CR_1,
  featureList: [
    {
      id: ABILITY_POUNCE,
      range: 20,
      dc: 12,
      attack: 'одну атаку _Укусом_',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Дейноних делает три атаки: одну _Укусом_ и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
  ],
}
