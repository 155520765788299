const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  GEAR_LONGBOW,
  GEAR_SCIMITAR,
} = require('./../../../../gearIdList')
const {
  CREATURE_YUAN_TI_MALISON_1,
  CREATURE_YUAN_TI_MALISON_3,
} = require('./../../../../creatureIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Юань-ти проклинатель, вид 3',
  nameEn: 'Yuan-ti Malison, Type 3',
  id: CREATURE_YUAN_TI_MALISON_3,
  parentId: CREATURE_YUAN_TI_MALISON_1,
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в облике юань-ти',
      description: `★СУЩЕСТВО★ совершает две дальнобойные атаки или две рукопашные атаки, но _Сжимание_ может использовать только раз.`,
    },
    {
      name: 'Укус',
      comment: 'только в облике змеи',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Сжимание',
      description: `Цель становится схваченной (СЛ высвобождения 13). Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может сжимать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_SCIMITAR,
      comment: 'только в облике юань-ти',
    },
    {
      gearId: GEAR_LONGBOW,
      comment: 'только в облике юань-ти',
    },
  ],
}
