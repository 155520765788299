const {SOURCE_MTOF} = require('./../../sourceList')
const {
  CREATURE_OBLEX_ADULT,
  CREATURE_OBLEX_ELDER,
  CREATURE_OBLEX_SPAWN,
} = require('./../../creatureIdList')

const oblexDescription = require('./oblex')

module.exports = [
  {
    header: 'Зрелые и древние облексы',
    text: `[Только сформированный облекс](CREATURE:${CREATURE_OBLEX_SPAWN}) испытывает недостаток способностей своего прародителя. Они ищут лишь воспоминания и растут до тех пор, пока не смогут выдавать себя за своих жертв.

Взрослые облексы, зовущиеся [зрелыми](CREATURE:${CREATURE_OBLEX_ADULT}) и [древними](CREATURE:${CREATURE_OBLEX_ELDER}), поглотили так много воспоминаний, что могут сформировать копию существа из субстанции собственного тела, который будет связан с облексом длинным тонким слизистым усиком, отправляя его приманивать жертв в свою западню. Эти скопированные создания неотличимы от своих жертв, за исключением лёгкого запаха серы. Облексы используют этих созданий, чтобы заманивать жертв в опасную ловушку или незаметно проникать в поселения чтобы поживиться более предпочтительной целью.`,
    source: {
      id: SOURCE_MTOF,
      page: 214,
    },
  },
  oblexDescription,
]
