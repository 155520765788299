const listToCollectionById = require('./../utils/listToCollectionById')

const LANG_FAMILY_CHULTAN = 'chultan'
const LANG_FAMILY_FAERUNIAN = 'faerunian'
const LANG_FAMILY_IMASKARI = 'imaskari'
const LANG_FAMILY_NONHUMAN = 'nonhuman'
const LANG_FAMILY_RAURIC = 'rauric'

const languageFamilyList = [
  {
    id: LANG_FAMILY_FAERUNIAN,
    name: 'Фаэрунские языки',
    nameEn: 'Faerûnian Languages',
  },
  {
    id: LANG_FAMILY_IMASKARI,
    name: 'Имаскарские языки',
    nameEn: 'Imaskari Languages',
  },
  {
    id: LANG_FAMILY_RAURIC,
    name: 'Рорикские языки',
    nameEn: 'Rauric Languages',
  },
  {
    id: LANG_FAMILY_CHULTAN,
    name: 'Чультские языки',
    nameEn: 'Chultan Languages',
  },
  {
    id: LANG_FAMILY_NONHUMAN,
    name: 'Нечеловеческие языки',
    nameEn: 'Nonhuman Languages',
  },
]

module.exports = languageFamilyList

module.exports.languageFamilyCollection = listToCollectionById(languageFamilyList)

module.exports.LANG_FAMILY_CHULTAN = LANG_FAMILY_CHULTAN
module.exports.LANG_FAMILY_FAERUNIAN = LANG_FAMILY_FAERUNIAN
module.exports.LANG_FAMILY_IMASKARI = LANG_FAMILY_IMASKARI
module.exports.LANG_FAMILY_NONHUMAN = LANG_FAMILY_NONHUMAN
module.exports.LANG_FAMILY_RAURIC = LANG_FAMILY_RAURIC
