module.exports.GEAR_ABACUS = 'abacus'
module.exports.GEAR_ACID = 'acid'
module.exports.GEAR_ALCHEMIST_S_FIRE = 'alchemist_s_fire'
module.exports.GEAR_ALCHEMIST_S_SUPPLIES = 'alchemist_s_supplies'
module.exports.GEAR_ALE_GALLON = 'ale_gallon'
module.exports.GEAR_ALE_MUG = 'ale_mug'
module.exports.GEAR_AMULET = 'amulet'
module.exports.GEAR_ANKYLOSAURUS = 'ankylosaurus'
module.exports.GEAR_ANTIMATTER_RIFLE = 'antimatter_rifle'
module.exports.GEAR_ANTITOXIN = 'antitoxin'
module.exports.GEAR_ARROWS = 'arrows'
module.exports.GEAR_ASSASSIN_S_BLOOD = 'assassin_s_blood'
module.exports.GEAR_BACKPACK = 'backpack'
module.exports.GEAR_BAGPIPES = 'bagpipes'
module.exports.GEAR_BALL_BEARINGS = 'ball_bearings'
module.exports.GEAR_BANQUET = 'banquet'
module.exports.GEAR_BARREL = 'barrel'
module.exports.GEAR_BASKET = 'basket'
module.exports.GEAR_BATTLEAXE = 'battleaxe'
module.exports.GEAR_BEDROLL = 'bedroll'
module.exports.GEAR_BELL = 'bell'
module.exports.GEAR_BIRDPIPES = 'birdpipes'
module.exports.GEAR_BIT_AND_BRIDLE = 'bit_and_bridle'
module.exports.GEAR_BLANKET = 'blanket'
module.exports.GEAR_BLOCK_AND_TACKLE = 'block_and_tackle'
module.exports.GEAR_BLOWGUN = 'blowgun'
module.exports.GEAR_BLOWGUN_NEEDLES = 'blowgun_needles'
module.exports.GEAR_BOMB = 'bomb'
module.exports.GEAR_BOOK = 'book'
module.exports.GEAR_BOTTLE_GLASS = 'bottle_glass'
module.exports.GEAR_BREAD_LOAF = 'bread_loaf'
module.exports.GEAR_BREASTPLATE = 'breastplate'
module.exports.GEAR_BREWERS_SUPPLIES = 'brewers_supplies'
module.exports.GEAR_BUCKET = 'bucket'
module.exports.GEAR_BULLETS = 'bullets'
module.exports.GEAR_BULLETS_MODERN = 'bullets_modern'
module.exports.GEAR_BURGLAR_S_PACK = 'burglar_s_pack'
module.exports.GEAR_BURNT_OTHUR_FUMES = 'burnt_othur_fumes'
module.exports.GEAR_CALLIGRAPHERS_SUPPLIES = 'calligraphers_supplies'
module.exports.GEAR_CALTROPS = 'caltrops'
module.exports.GEAR_CAMEL = 'camel'
module.exports.GEAR_CANDLE = 'candle'
module.exports.GEAR_CANOE = 'canoe'
module.exports.GEAR_CANVAS = 'canvas'
module.exports.GEAR_CARPENTER_S_TOOLS = 'carpenter_s_tools'
module.exports.GEAR_CARRIAGE = 'carriage'
module.exports.GEAR_CART = 'cart'
module.exports.GEAR_CARTOGRAPHER_S_TOOLS = 'cartographer_s_tools'
module.exports.GEAR_CASE_CROSSBOW_BOLT = 'case_crossbow_bolt'
module.exports.GEAR_CASE_MAP_OR_SCROLL = 'case_map_or_scroll'
module.exports.GEAR_CHAIN = 'chain'
module.exports.GEAR_CHAIN_MAIL = 'chain_mail'
module.exports.GEAR_CHAIN_SHIRT = 'chain_shirt'
module.exports.GEAR_CHALK = 'chalk'
module.exports.GEAR_CHARIOT = 'chariot'
module.exports.GEAR_CHATKCHA = 'chatkcha'
module.exports.GEAR_CHEESE_HUNK = 'cheese_hunk'
module.exports.GEAR_CHEST = 'chest'
module.exports.GEAR_CHICKEN = 'chicken'
module.exports.GEAR_CINNAMON = 'cinnamon'
module.exports.GEAR_CLIMBER_S_KIT = 'climber_s_kit'
module.exports.GEAR_CLOTHES_COMMON = 'clothes_common'
module.exports.GEAR_CLOTHES_COSTUME = 'clothes_costume'
module.exports.GEAR_CLOTHES_FINE = 'clothes_fine'
module.exports.GEAR_CLOTHES_TRAVELERS = 'clothes_travelers'
module.exports.GEAR_CLOVES = 'cloves'
module.exports.GEAR_CLUB = 'club'
module.exports.GEAR_COBBLER_S_TOOLS = 'cobbler_s_tools'
module.exports.GEAR_COMPONENT_POUCH = 'component_pouch'
module.exports.GEAR_COOK_S_UTENSILS = 'cook_s_utensils'
module.exports.GEAR_COPPER = 'copper'
module.exports.GEAR_COTTON_CLOTH = 'cotton_cloth'
module.exports.GEAR_COW = 'cow'
module.exports.GEAR_CRAWLER_MUCUS = 'crawler_mucus'
module.exports.GEAR_CROSSBOW_BOLTS = 'crossbow_bolts'
module.exports.GEAR_CROSSBOW_DOUBLE = 'crossbow_double'
module.exports.GEAR_CROSSBOW_HAND = 'crossbow_hand'
module.exports.GEAR_CROSSBOW_HEAVY = 'crossbow_heavy'
module.exports.GEAR_CROSSBOW_LIGHT = 'crossbow_light'
module.exports.GEAR_CROWBAR = 'crowbar'
module.exports.GEAR_CRYSTAL = 'crystal'
module.exports.GEAR_DAGGER = 'dagger'
module.exports.GEAR_DART = 'dart'
module.exports.GEAR_DEINONYCHUS = 'deinonychus'
module.exports.GEAR_DICE_SET = 'dice_set'
module.exports.GEAR_DIPLOMAT_S_PACK = 'diplomat_s_pack'
module.exports.GEAR_DISGUISE_KIT = 'disguise_kit'
module.exports.GEAR_DONKEY = 'donkey'
module.exports.GEAR_DOUBLE_BLADED_SCIMITAR = 'double_bladed_scimitar'
module.exports.GEAR_DRAFT_HORSE = 'draft_horse'
module.exports.GEAR_DRAGON_CHESS = 'dragon_chess'
module.exports.GEAR_DROW_POISON = 'drow_poison'
module.exports.GEAR_DRUM = 'drum'
module.exports.GEAR_DULCIMER = 'dulcimer'
module.exports.GEAR_DUNGEONEER_S_PACK = 'dungeoneer_s_pack'
module.exports.GEAR_DYNAMITE_STICK = 'dynamite_stick'
module.exports.GEAR_ELEPHANT = 'elephant'
module.exports.GEAR_EMBLEM = 'emblem'
module.exports.GEAR_ENERGY_CELL = 'energy_cell'
module.exports.GEAR_ENTERTAINER_S_PACK = 'entertainer_s_pack'
module.exports.GEAR_ESSENCE_OF_ETHER = 'essence_of_ether'
module.exports.GEAR_EXOTIC_SADDLE = 'exotic_saddle'
module.exports.GEAR_EXPLORER_S_PACK = 'explorer_s_pack'
module.exports.GEAR_FEED = 'feed'
module.exports.GEAR_FISHING_TACKLE = 'fishing_tackle'
module.exports.GEAR_FLAIL = 'flail'
module.exports.GEAR_FLASK = 'flask'
module.exports.GEAR_FLOUR = 'flour'
module.exports.GEAR_FLUTE = 'flute'
module.exports.GEAR_FLYING_MONKEY = 'flying_monkey'
module.exports.GEAR_FLYING_SNAKE = 'flying_snake'
module.exports.GEAR_FORGERY_KIT = 'forgery_kit'
module.exports.GEAR_GALLEY = 'galley'
module.exports.GEAR_GIANT_LIZARD = 'giant_lizard'
module.exports.GEAR_GINGER = 'ginger'
module.exports.GEAR_GLAIVE = 'glaive'
module.exports.GEAR_GLASSBLOWERS_TOOLS = 'glassblowers_tools'
module.exports.GEAR_GLAUR = 'glaur'
module.exports.GEAR_GOAT = 'goat'
module.exports.GEAR_GOLD = 'gold'
module.exports.GEAR_GRAPPLING_HOOK = 'grappling_hook'
module.exports.GEAR_GREATAXE = 'greataxe'
module.exports.GEAR_GREATCLUB = 'greatclub'
module.exports.GEAR_GREATSWORD = 'greatsword'
module.exports.GEAR_GRENADE_FRAGMENTATION = 'grenade_fragmentation'
module.exports.GEAR_GRENADE_LAUNCHER = 'grenade_launcher'
module.exports.GEAR_GRENADE_SMOKE = 'grenade_smoke'
module.exports.GEAR_GUNPOWDER_KEG = 'gunpowder_keg'
module.exports.GEAR_GUNPOWDER_POWDER_HORN = 'gunpowder_powder_horn'
module.exports.GEAR_GYTHKA = 'gythka'
module.exports.GEAR_HADROSAURUS = 'hadrosaurus'
module.exports.GEAR_HALBERD = 'halberd'
module.exports.GEAR_HALF_PLATE = 'half_plate'
module.exports.GEAR_HAMMER = 'hammer'
module.exports.GEAR_HAMMER_SLEDGE = 'hammer_sledge'
module.exports.GEAR_HANDAXE = 'handaxe'
module.exports.GEAR_HAND_DRUM = 'hand_drum'
module.exports.GEAR_HEALERS_KIT = 'healers_kit'
module.exports.GEAR_HERBALISM_KIT = 'herbalism_kit'
module.exports.GEAR_HIDE_ARMOR = 'hide_armor'
module.exports.GEAR_HOLY_WATER = 'holy_water'
module.exports.GEAR_HORN = 'horn'
module.exports.GEAR_HOURGLASS = 'hourglass'
module.exports.GEAR_HUNTING_TRAP = 'hunting_trap'
module.exports.GEAR_INK = 'ink'
module.exports.GEAR_INK_PEN = 'ink_pen'
module.exports.GEAR_INSECT_REPELLENT_INCENSE = 'insect_repellent_incense'
module.exports.GEAR_INSECT_REPELLENT_SALVE = 'insect_repellent_salve'
module.exports.GEAR_IRON = 'iron'
module.exports.GEAR_JAVELIN = 'javelin'
module.exports.GEAR_JEWELERS_TOOLS = 'jewelers_tools'
module.exports.GEAR_JUG = 'jug'
module.exports.GEAR_KEELBOAT = 'keelboat'
module.exports.GEAR_LADDER = 'ladder'
module.exports.GEAR_LAMP = 'lamp'
module.exports.GEAR_LANCE = 'lance'
module.exports.GEAR_LANTERN_BULLSEYE = 'lantern_bullseye'
module.exports.GEAR_LANTERN_HOODED = 'lantern_hooded'
module.exports.GEAR_LASER_PISTOL = 'laser_pistol'
module.exports.GEAR_LASER_RIFLE = 'laser_rifle'
module.exports.GEAR_LEATHERWORKER_S_TOOLS = 'leatherworker_s_tools'
module.exports.GEAR_LEATHER_ARMOR = 'leather_armor'
module.exports.GEAR_LIGHT_HAMMER = 'light_hammer'
module.exports.GEAR_LINEN = 'linen'
module.exports.GEAR_LOCK = 'lock'
module.exports.GEAR_LONGBOW = 'longbow'
module.exports.GEAR_LONGHORN = 'longhorn'
module.exports.GEAR_LONGSHIP = 'longship'
module.exports.GEAR_LONGSWORD = 'longsword'
module.exports.GEAR_LUTE = 'lute'
module.exports.GEAR_LYRE = 'lyre'
module.exports.GEAR_MACE = 'mace'
module.exports.GEAR_MAGNIFYING_GLASS = 'magnifying_glass'
module.exports.GEAR_MALICE = 'malice'
module.exports.GEAR_MANACLES = 'manacles'
module.exports.GEAR_MASONS_TOOLS = 'masons_tools'
module.exports.GEAR_MASTIFF = 'mastiff'
module.exports.GEAR_MAUL = 'maul'
module.exports.GEAR_MEAT_CHUNK = 'meat_chunk'
module.exports.GEAR_MESS_KIT = 'mess_kit'
module.exports.GEAR_MIDNIGHT_TEARS = 'midnight_tears'
module.exports.GEAR_MILITARY_SADDLE = 'military_saddle'
module.exports.GEAR_MIRROR_STEEL = 'mirror_steel'
module.exports.GEAR_MORNINGSTAR = 'morningstar'
module.exports.GEAR_MULE = 'mule'
module.exports.GEAR_MUSKET = 'musket'
module.exports.GEAR_NAVIGATORS_TOOLS = 'navigators_tools'
module.exports.GEAR_NET = 'net'
module.exports.GEAR_OIL = 'oil'
module.exports.GEAR_OIL_OF_TAGGIT = 'oil_of_taggit'
module.exports.GEAR_ORB = 'orb'
module.exports.GEAR_OX = 'ox'
module.exports.GEAR_PACK_SADDLE = 'pack_saddle'
module.exports.GEAR_PADDED_ARMOR = 'padded_armor'
module.exports.GEAR_PAINTERS_SUPPLIES = 'painters_supplies'
module.exports.GEAR_PALE_TINCTURE = 'pale_tincture'
module.exports.GEAR_PAN_FLUTE = 'pan_flute'
module.exports.GEAR_PAPER = 'paper'
module.exports.GEAR_PARCHMENT = 'parchment'
module.exports.GEAR_PEPPER = 'pepper'
module.exports.GEAR_PERFUME = 'perfume'
module.exports.GEAR_PIG = 'pig'
module.exports.GEAR_PIKE = 'pike'
module.exports.GEAR_PISTOL = 'pistol'
module.exports.GEAR_PISTOL_AUTOMATIC = 'pistol_automatic'
module.exports.GEAR_PITCHER = 'pitcher'
module.exports.GEAR_PITON = 'piton'
module.exports.GEAR_PLATE_ARMOR = 'plate_armor'
module.exports.GEAR_PLATINUM = 'platinum'
module.exports.GEAR_PLAYING_CARD_SET = 'playing_card_set'
module.exports.GEAR_POISONERS_KIT = 'poisoners_kit'
module.exports.GEAR_POISON_BASIC = 'poison_basic'
module.exports.GEAR_POLE = 'pole'
module.exports.GEAR_PONY = 'pony'
module.exports.GEAR_POTION_OF_HEALING = 'potion_of_healing.'
module.exports.GEAR_POTTERS_TOOLS = 'potters_tools'
module.exports.GEAR_POT_IRON = 'pot_iron'
module.exports.GEAR_POUCH = 'pouch'
module.exports.GEAR_PRIEST_S_PACK = 'priest_s_pack'
module.exports.GEAR_PURPLE_WORM_POISON = 'purple_worm_poison'
module.exports.GEAR_QUARTERSTAFF = 'quarterstaff'
module.exports.GEAR_QUIVER = 'quiver'
module.exports.GEAR_RAIN_CATCHER = 'rain_catcher'
module.exports.GEAR_RAM_PORTABLE = 'ram_portable'
module.exports.GEAR_RAPIER = 'rapier'
module.exports.GEAR_RATIONS = 'rations'
module.exports.GEAR_RELIQUARY = 'reliquary'
module.exports.GEAR_REVOLVER = 'revolver'
module.exports.GEAR_RIDING_HORSE = 'riding_horse'
module.exports.GEAR_RIDING_SADDLE = 'riding_saddle'
module.exports.GEAR_RIFLE_AUTOMATIC = 'rifle_automatic'
module.exports.GEAR_RIFLE_HUNTING = 'rifle_hunting'
module.exports.GEAR_RING_MAIL = 'ring_mail'
module.exports.GEAR_ROBES = 'robes'
module.exports.GEAR_ROD = 'rod'
module.exports.GEAR_ROPE_HEMP = 'rope_hemp'
module.exports.GEAR_ROPE_SILK = 'rope_silk'
module.exports.GEAR_ROWBOAT = 'rowboat'
module.exports.GEAR_SACK = 'sack'
module.exports.GEAR_SADDLEBAGS = 'saddlebags'
module.exports.GEAR_SAFFRON = 'saffron'
module.exports.GEAR_SAILING_SHIP = 'sailing_ship'
module.exports.GEAR_SALT = 'salt'
module.exports.GEAR_SCALE_MAIL = 'scale_mail'
module.exports.GEAR_SCALE_MERCHANTS = 'scale_merchants'
module.exports.GEAR_SCHOLAR_S_PACK = 'scholar_s_pack'
module.exports.GEAR_SCIMITAR = 'scimitar'
module.exports.GEAR_SEALING_WAX = 'sealing_wax'
module.exports.GEAR_SERPENT_VENOM = 'serpent_venom'
module.exports.GEAR_SHATTERSTICK = 'shatterstick'
module.exports.GEAR_SHAWM = 'shawm'
module.exports.GEAR_SHEEP = 'sheep'
module.exports.GEAR_SHIELD = 'shield'
module.exports.GEAR_SHORTBOW = 'shortbow'
module.exports.GEAR_SHORTSWORD = 'shortsword'
module.exports.GEAR_SHOTGUN = 'shotgun'
module.exports.GEAR_SHOVEL = 'shovel'
module.exports.GEAR_SICKLE = 'sickle'
module.exports.GEAR_SIGNAL_WHISTLE = 'signal_whistle'
module.exports.GEAR_SIGNET_RING = 'signet_ring'
module.exports.GEAR_SILK = 'silk'
module.exports.GEAR_SILVER = 'silver'
module.exports.GEAR_SLED = 'sled'
module.exports.GEAR_SLING = 'sling'
module.exports.GEAR_SLING_BULLETS = 'sling_bullets'
module.exports.GEAR_SMITHS_TOOLS = 'smiths_tools'
module.exports.GEAR_SOAP = 'soap'
module.exports.GEAR_SONGHORN = 'songhorn'
module.exports.GEAR_SPEAR = 'spear'
module.exports.GEAR_SPELLBOOK = 'spellbook'
module.exports.GEAR_SPIKED_ARMOR = 'spiked_armor'
module.exports.GEAR_SPIKES_IRON = 'spikes_iron'
module.exports.GEAR_SPLINT_ARMOR = 'splint_armor'
module.exports.GEAR_SPRIG_OF_HOLLY = 'sprig_of_holly'
module.exports.GEAR_SPRIG_OF_MISTLETOE = 'sprig_of_mistletoe'
module.exports.GEAR_SPYGLASS = 'spyglass'
module.exports.GEAR_STAFF = 'staff'
module.exports.GEAR_STUDDED_LEATHER_ARMOR = 'studded_leather_armor'
module.exports.GEAR_TANKARD = 'tankard'
module.exports.GEAR_TANTAN = 'tantan'
module.exports.GEAR_TEJ_CASK = 'tej_cask'
module.exports.GEAR_TEJ_MUG = 'tej_mug'
module.exports.GEAR_TENT = 'tent'
module.exports.GEAR_THELARR = 'thelarr'
module.exports.GEAR_THIEVES_TOOLS = 'thieves_tools'
module.exports.GEAR_THREE_DRAGON_ANTE = 'three_dragon_ante'
module.exports.GEAR_TINDERBOX = 'Tinderbox'
module.exports.GEAR_TINKERS_TOOLS = 'tinkers_tools'
module.exports.GEAR_TOCKEN = 'tocken'
module.exports.GEAR_TORCH = 'torch'
module.exports.GEAR_TORPOR = 'torpor'
module.exports.GEAR_TOTEM = 'totem'
module.exports.GEAR_TRICERATOPS = 'triceratops'
module.exports.GEAR_TRIDENT = 'trident'
module.exports.GEAR_TRUTH_SERUM = 'truth_serum'
module.exports.GEAR_VIAL = 'vial'
module.exports.GEAR_VIOL = 'viol'
module.exports.GEAR_WAGON = 'wagon'
module.exports.GEAR_WAND = 'wand'
module.exports.GEAR_WARGONG = 'wargong'
module.exports.GEAR_WARHAMMER = 'warhammer'
module.exports.GEAR_WARHORSE = 'warhorse'
module.exports.GEAR_WARSHIP = 'warship'
module.exports.GEAR_WAR_PICK = 'war_pick'
module.exports.GEAR_WATERSKIN = 'waterskin'
module.exports.GEAR_WEAVERS_TOOLS = 'weavers_tools'
module.exports.GEAR_WHEAT = 'wheat'
module.exports.GEAR_WHETSTONE = 'whetstone'
module.exports.GEAR_WHIP = 'whip'
module.exports.GEAR_WINE_COMMON_BOTTLE = 'wine_common_bottle'
module.exports.GEAR_WINE_COMMON_PITCHER = 'wine_common_pitcher'
module.exports.GEAR_WINE_FINE_BOTTLE = 'wine_fine_bottle'
module.exports.GEAR_WOODCARVERS_TOOLS = 'woodcarvers_tools'
module.exports.GEAR_WOODEN_STAFF = 'wooden_staff'
module.exports.GEAR_WYVERN_POISON = 'wyvern_poison'
module.exports.GEAR_YARTING = 'yarting'
module.exports.GEAR_YEW_SCEPTER = 'yew_scepter'
module.exports.GEAR_YEW_WAND = 'yew_wand'
module.exports.GEAR_YKLWA = 'yklwa'
module.exports.GEAR_ZULKOON = 'zulkoon'
