const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {ATTACK_RANGE_SPELL} = require('./../../../../../attackTypeList')
const {CONDITION_BLINDED} = require('./../../../../../conditionList')
const {DAMAGE_RADIANT} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_WALL_OF_LIGHT} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_WALL_OF_LIGHT,
  name: 'Стена света',
  nameEn: 'Wall of Light',
  description: `Мерцающая стена яркого света появляется в точке, которую Вы выбираете в пределах дистанции. Стена появляется в любой выбранной Вами ориентации: по горизонтали, вертикали или диагонали. Она может свободно парить или же опираться на твёрдую поверхность. Стена может иметь длину до 60 футов, высоту до 10 футов и толщину до 5 футов. Стена блокирует линию обзора, но существа и объекты могут проходить через неё. Он излучает яркий свет на 120 футов и тусклый свет на дополнительные 120 футов.

Когда стена появляется, каждое существо в её области должно пройти испытание Телосложения. При провале существо получает 4к8 урона излучением и становится ослеплённым на 1 минуту. При успехе оно получает только половину урона и не ослеплено. Ослеплённое существо может проходить испытание Телосложения в конце каждого своего хода, при успехе оканчивая эффект на себе.

Существо, которое заканчивает свой ход в пространстве стены, получает 4к8 урона излучением.

Пока заклинание не закончится, Вы можете действием направить сияющий луч от стены на одно существо, которое Вы можете видеть на расстоянии 60 футов от неё. Для этого совершите дальнобойную атаку заклинанием. При попадании цель получает 4к8 урона излучением. Независимо от того, попали Вы или промахнулись, длина стены уменьшится на 10 футов. Если длина стены уменьшается до 0 футов, заклинание заканчивается.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку заклинания 6 круга или выше, урон увеличивается на 1к8 для каждого уровня ячейки выше 5.`,
  lvl: 5,
  magicSchoolId: MAGIC_EVOCATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `ручное зеркальце`,
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: [
    {
      savethrowParam: PARAM_CON,
      condition: CONDITION_BLINDED,
      damage: {
        type: DAMAGE_RADIANT,
        halfOnSuccess: true,
        diceCount: 4,
        diceType: 8,
      },
    },
    {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_RADIANT,
        diceCount: 4,
        diceType: 8,
      },
    },
  ],
  source: {
    id: SOURCE_XGTE,
    page: 168, // TODO
  },
}
