const {SOURCE_UA_2016_01_04_KITS_OF_OLD} = require('./../../../sourceList')
const {SPELL_DETECT_THOUGHTS} = require('./../../../spellIdList')
const {PC_SUBCLASS_BARD_COLLEGE_OF_SATIRE_2016_01_04} = require('./../../../pcSubClassIdList')
const {GEAR_THIEVES_TOOLS} = require('./../../../gearIdList')
const {
  FEATURE_BARDIC_INSPIRATION,
  FEATURE_BONUS_PROFICIENCIES_COLLEGE_OF_SATIRE_2016_01_04,
  FEATURE_FOOL_S_LUCK_2016_01_04,
  FEATURE_FOOL_S_INSIGHT_2016_01_04,
  FEATURE_TUMBLING_FOOL_2016_01_04,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BONUS_PROFICIENCIES_COLLEGE_OF_SATIRE_2016_01_04,
    name: `Бонусные владения`,
    nameEn: `Bonus Proficiencies`,
    lvl: 3,
    text: `Вступая в коллегию сатиры, Вы получаете владение [воровскими инструментами](GEAR:${GEAR_THIEVES_TOOLS}). Также Вы получаете владение навыком Ловкость рук и одним навыком по Вашему выбору.

Если Вы уже владете [воровскими инструментами](GEAR:${GEAR_THIEVES_TOOLS}) или навыком Ловкость рук, выберите другие навыки вместо них.`,
    source: {
      id: SOURCE_UA_2016_01_04_KITS_OF_OLD,
      page: 2,
    },
  },
  {
    id: FEATURE_TUMBLING_FOOL_2016_01_04,
    name: `Кувыркающийся дурак`,
    nameEn: `Tumbling Fool`,
    lvl: 3,
    text: `Вы осваиваете различные акробатические приёмы, позволяющие Вам избегать опасности. Бонусным действием Вы можете кувыркнуться.

Кувыркаясь, Вы получаете следующие преимущества до конца Вашего хода.

* Все преимущества Рывка и Отхода.
* Скорость лазания, равную Вашей текущей скорости.
* Если Вы падаете, то получаете только половину урона от падения.`,
    source: {
      id: SOURCE_UA_2016_01_04_KITS_OF_OLD,
      page: 2,
    },
  },
  {
    id: FEATURE_FOOL_S_INSIGHT_2016_01_04,
    name: `Дурацкое чутьё`,
    nameEn: `Fool’s Insight`,
    lvl: 6,
    text: `Ваша способность собирать истории и знания достигает сверхъестественной чуткости. Вы можете сотворять заклинание [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) количество раз, равное Вашему модификатору Харизмы.

Если существо проходит испытание от этого заклинания, оно немедленно страдает от социально неприятной оказии. Оно может громко испустить газы, громоподобно рыгнуть, глупо подскользнуться, или рассказать ужасную шутку.

Вы восстанавливаете все затраченные использования этого умения, когда заканчиваете длительный отдых.`,
    spellIdList: SPELL_DETECT_THOUGHTS,
    source: {
      id: SOURCE_UA_2016_01_04_KITS_OF_OLD,
      page: 2,
    },
  },
  {
    id: FEATURE_FOOL_S_LUCK_2016_01_04,
    name: `Дуракам везёт`,
    nameEn: `Fool’s Luck`,
    lvl: 14,
    text: `Шуты, кажется, умеют выбираться из самых сложных ситуаций, превращая то, что выглядит как гарантированный провал, в неловкий, но эффективный успех.

Провалив проверку способности, испытание или бросок атаки, Вы можете потратить одну кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и добавить её результат. Бросьте кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и добавьте выпавшее число к Вашей атаке, испытанию или проверке способностей, используя новый результат вместо неудачного. Если это позволило Вам успешно пройти проверку, испытание или попасть атакой, запомните число, выпавшее на кости [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}).

Позже Мастер может применить его как штраф к вашей атаке, проверке или испытанию, и Вы не можете использовать эту способность снова, пока не получите этот штраф. Когда Мастер применяет этот штраф, опишите помешавшую Вам дурацкую оплошность или ошибку.`,
    source: {
      id: SOURCE_UA_2016_01_04_KITS_OF_OLD,
      page: 3,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_SATIRE_2016_01_04,
  })
)
