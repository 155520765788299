const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CREATURE_ETERNAL_FLAME_PRIEST} = require('./../../../../creatureIdList')
const {CR_3} = require('./../../../../crList')
const {DAMAGE_FIRE} = require('./../../../../damageTypeList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {eternalFlameDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  PC_CLASS_DRUID,
  PC_CLASS_SORCERER,
  PC_CLASS_WIZARD,
} = require('./../../../../pcClassIdList')
const {
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  SPELL_BLUR,
  SPELL_BURNING_HANDS,
  SPELL_CONTROL_FLAMES,
  SPELL_CREATE_BONFIRE,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FIREBALL,
  SPELL_FIRE_BOLT,
  SPELL_LIGHT,
  SPELL_MAGE_ARMOR,
  SPELL_MINOR_ILLUSION,
  SPELL_SCORCHING_RAY,
} = require('./../../../../spellIdList')
const {
  LANG_COMMON,
  LANG_IGNAN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Жрец Вечного Пламени',
  nameEn: 'Eternal Flame Priest',
  id: CREATURE_ETERNAL_FLAME_PRIEST,
  description: [
    {
      header: 'Жрецы Вечного Пламени',
      text: `Жрецы Вечного Пламени видят мир вокруг себя нечистым и недостойным и верят, что только очищение огнём может все исправить. В результате любой огонь считается ими священным: от самого маленького пламени свечи до огромных пожаров.

Жрецы Вечного Пламени достаточно умны, чтобы скрывать свои истинные убеждения. Когда они путешествуют среди чужаков, то выдают себя за [друидов](PC_CLASS:${PC_CLASS_DRUID}) или [волшебников](PC_CLASS:${PC_CLASS_WIZARD}), владеющих магией огня.`,
      source: {
        id: SOURCE_PotA,
        page: 205,
      },
    },
    eternalFlameDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 205,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
  ],
  languageList: [
    LANG_COMMON,
    LANG_IGNAN,
  ],
  cr: CR_3,
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_BLUR,
      SPELL_BURNING_HANDS,
      SPELL_CONTROL_FLAMES,
      SPELL_CREATE_BONFIRE,
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_FIREBALL,
      SPELL_FIRE_BOLT,
      SPELL_LIGHT,
      SPELL_MAGE_ARMOR,
      SPELL_MINOR_ILLUSION,
      SPELL_SCORCHING_RAY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
