const {CREATURE_TYPE_GIANT_CLOUD} = require('./../../creatureTypeIdList')
const {cloudGiantDescriptionList} = require('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GIANT_CLOUD,
  nameEn: 'Cloud Giant',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'облачный великан',
        genitive: 'облачного великана',
        dative: 'облачному великану',
        accusative: 'облачного великана',
        instrumental: 'облачным великаном',
        prepositional: 'облачном великане',
      },
      [GENDER_FEMALE]: {
        nominative: 'облачная великанша',
        genitive: 'облачной великанши',
        dative: 'облачной великанше',
        accusative: 'облачную великаншу',
        instrumental: 'облачной великаншей',
        prepositional: 'облачной великанше',
      },
    },
    plural: {
      nominative: 'облачные великаны',
      genitive: 'облачных великанов',
      dative: 'облачным великанам',
      accusative: 'облачных великанов',
      instrumental: 'облачными великанами',
      prepositional: 'облачных великанах',
    },
  },
  description: cloudGiantDescriptionList,
}
