const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FEAR_OF_FIRE,
  ABILITY_KEEN_SMELL,
  ABILITY_SNOW_CAMOUFLAGE,
} = require('./../../../../creatureAbilityList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  DAMAGE_COLD,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_ABOMINABLE_YETI,
  CREATURE_YETI,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_YETI} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Йети',
  nameEn: 'Yeti',
  id: CREATURE_YETI,
  description: [
    `Несомые ветром с далёких гор вопли йети порождают страх в сердцах немногочисленных пастухов и шахтёров, обитающих среди вершин. Эти громадные существа рыщут среди горных пиков в непрестанной охоте за пищей. Их снежно-белый мех позволяет им как призракам бродить на фоне снежных ландшафтов. Холодные как лёд, обезьяньи глаза йети могут заморозить добычу на месте.`,
    {
      header: `Заядлые охотники`,
      text: `Коренные жители гор стараются путешествовать вооружёнными группами, зная, что йети способны учуять запахи на расстоянии многих миль. Стоит йети учуять добычу, как они, завывая от охотничьего азарта, несутся к ней по льду и камню. Даже в метель запах добычи привлекает йети сквозь холод и снег.

Йети охотятся в одиночку или небольшими семейными стаями. Когда существо убегает от йети или вовлекает его в сражение, могут прийти другие йети, учуявшие аромат крови. Йети борются друг с другом за территорию и за останки таких сражений на ней, и убитых в этой борьбе, также пожирают, сопровождая пиршество довольными воплями.`,
      source: {
        id: SOURCE_MM,
        page: 166,
      },
    },
    {
      header: `Ужасающие ревуны`,
      text: `Перед лавиной, снежной бурей или смертельным морозом завывания йети летят на ледяном ветру вниз по склонам гор. Некоторые горные обитатели верят в то, что в воплях йети звучат голоса их близких, погибших в лавинах и вьюгах, суля своим плачем недобрые предзнаменования. Более прагматичные люди считают, что вопли йети это напоминание о том, что, несмотря на все достижения цивилизации, они могут стать добычей сил дикой природы.`,
      source: {
        id: SOURCE_MM,
        page: 166,
      },
    },
    {
      header: `Зверские буяны`,
      text: `Когда горные стада изобилуют скотом, йети предпочитают держаться подальше от людских областей. Но ведомые голодом, они волнами накатываются на людские поселения; ломая ворота и стены частокола, которые отпугивали их когда-то, они поедают всех, кто обитал внутри них.

Коварные горцы иногда используют йети как невольное оружие. Военачальник может приказать подбросить зарезанных коз или овец в лагерь врага, чтобы тем самым спровоцировать нападение йети, которое создаст хаос и серьёзно проредит ряды неприятеля перед предстоящей битвой. Вожди горных кланов в желании расширить свою территорию устраивают большие облавы, лишая йети источников добычи и провоцируя их на нападения на человеческие поселения, которые потом легко можно будет захватить.`,
      source: {
        id: SOURCE_MM,
        page: 166,
      },
    },
  ],
  note: {
    text: `Будьте начеку! Это не ветер воет!`,
    author: `Келеста Хоук из Изумрудного Анклава`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 166,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_COLD,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_YETI,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_FEAR_OF_FIRE,
    ABILITY_KEEN_SMELL,
    ABILITY_SNOW_CAMOUFLAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ может использовать _Леденящий взгляд_ и совершает две атаки _Когтем_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Леденящий взгляд',
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 30 футов от себя. Если цель видит йети, она должна пройти испытание Телосложения СЛ 13 от этой магии, иначе получит урон холодом 10 (3к6) и станет парализованной на 1 минуту, если только не обладает иммунитетом к холоду. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.

Если испытание цели было пройдено, или эффект на ней закончился, цель получает иммунитет к _Леденящему взгляду_ всех ★СУЩЕСТВО★ (но не [Отвратительных йети](CREATURE:${CREATURE_ABOMINABLE_YETI})) на 1 час.`,
    },
  ],
  genderId: GENDER_MALE,
}
