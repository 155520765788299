module.exports = [
  require('./kenku'),
  require('./killer_whale'),
  require('./knight'),
  require('./kobold'),
  require('./kraken'),
  require('./kuo_toa'),
  require('./kuo_toa_archpriest'),
  require('./kuo_toa_monitor'),
  require('./kuo_toa_whip'),
]
