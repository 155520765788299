const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
} = require('./../../../../paramList')
const {
  ABILITY_FLYBY,
  ABILITY_PRIMAL_BOND,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_BEAST_OF_THE_LAND,
  CREATURE_BEAST_OF_THE_SKY,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Летучий зверь',
  nameEn: 'Beast of the Sky',
  id: CREATURE_BEAST_OF_THE_SKY,
  parentId: CREATURE_BEAST_OF_THE_LAND,
  extendPropCollection: {
    source: {
      page: 91,
    },
    params: {
      [PARAM_STR]: 6,
      [PARAM_DEX]: 16,
      [PARAM_CON]: 13,
    },
  },
  sizeType: SIZE_SMALL,
  hp: `4 + четырёхкратный уровень следопыта (у зверя есть кость хитов к6, их количество равно уровню следопыта)`,
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 60,
  },
  featureList: [
    ABILITY_FLYBY,
    ABILITY_PRIMAL_BOND,
  ],
  actionList: [
    {
      name: 'Кромсание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 'бонус атаки заклинаниями следопыта',
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: '3 + бонус мастерства следопыта',
        },
      },
    },
  ],
}
