const {
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_KUO_TOA_MONITOR,
  CREATURE_KUO_TOA_WHIP,
} = require('./../../../../creatureIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_3} = require('./../../../../crList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Куо-тоа надзиратель',
  nameEn: 'Kuo-toa Monitor',
  id: CREATURE_KUO_TOA_MONITOR,
  parentId: CREATURE_KUO_TOA_WHIP,
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  source: {
    id: SOURCE_MM,
    page: 183,
  },
  cr: CR_3,
  spellCast: null,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает одну атаку _Укусом_, и два _Безоружных удара_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Безоружный удар',
      description: `Цель не может совершать реакции до конца следующего хода ★СУЩЕСТВО★.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 2,
          },
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
  ],
}
