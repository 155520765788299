const {SOURCE_VGTM} = require('./../../sourceList')
const {GOD_IMIX} = require('./../../godIdList')
const {
  CREATURE_FIRENEWT_WARLOCK_OF_IMIX,
  CREATURE_FIRENEWT_WARRIOR,
} = require('./../../creatureIdList')

const giantStriderDescription = require('./giantStrider')

module.exports = [
  {
    header: 'Огненные тритоны',
    text: `В регионах, где есть горячие источники, вулканическая активность или иные влажные и жаркие условия, встречаются [огненные тритоны](CREATURE:${CREATURE_FIRENEWT_WARRIOR}). Эти гуманоидные амфибии живут в милитаристской теократии, почитающей стихийный огонь в его худшей инкарнации.`,
    source: {
      id: SOURCE_VGTM,
      page: 177,
    },
  },
  {
    header: 'Искатели жара',
    text: `Огненным тритонам для жизни и размножения необходима горячая вода. Огненный тритон становится медлительным физически и ментально после недели без внешнего источника жара и влаги. Продолжительная нехватка тепла может остановить жизнь сообществ огненных тритонов, так как существа впадают в гибернацию, и их яйца перестают развиваться.

Огненные тритоны разыскивают источники тепла в земле, такие как кипящая грязь и горячие источники, которые для них являются идеальными местами для заселения. Путём рытья и прокладки шахт в таких областях они устраивают себе жилища и заодно получают богатый запас минералов для различных целей, например литья, ковки или алхимии. Логово огненного тритона всегда включает в себя сеть каналов и стоков, по которой горячая вода циркулирует по поселению.

Практикуемая огненными тритонами алхимия фокусируется на огне. Одна из наиболее излюбленных ими смесей — паста из серы, минеральных солей и масла. Огненные тритоны частенько жуют эту смесь, потому что она производит приятный согревающий эффект внутри и позволяет огненному тритону плеваться небольшим шаром пламени. Большинство огненных тритонов носят с собой контейнер с этой пастой.`,
    source: {
      id: SOURCE_VGTM,
      page: 177,
    },
  },
  {
    header: 'Религиозные бойцы',
    text: `Культура и общество огненных тритонов основаны на поклонении [Имиксу](GOD:${GOD_IMIX}), Принцу Злого Огня. Почитание [Имикса](GOD:${GOD_IMIX}) приводит к тому, что огненные тритоны бывают агрессивными, гневными и жестокими. [Колдуны огненных тритонов](CREATURE:${CREATURE_FIRENEWT_WARLOCK_OF_IMIX}) учат этому, демонстрируя такие качества, а [огненный тритон воин](CREATURE:${CREATURE_FIRENEWT_WARRIOR}) в бою может стать «осенённым Лордом Огня», вводя себя в состояние практически неостановимой боевой ярости.

[Колдуны](CREATURE:${CREATURE_FIRENEWT_WARLOCK_OF_IMIX}) [Имикса](GOD:${GOD_IMIX}) повелевают [воинам](CREATURE:${CREATURE_FIRENEWT_WARRIOR}) доказать, чего те стоят, отправляясь в налёты с целью добыть сокровища и пленников. Колдуны забирают наилучшие сокровища как воздаяние [Имиксу](GOD:${GOD_IMIX}), а оставшиеся сокровища делят участники налёта в зависимости от проявленной доблести. Пленников, у которых на вид нет полезных качеств, приносят в жертву [Имиксу](GOD:${GOD_IMIX}), а потом съедают. Те, кто считается способным добывать ресурсы в шахтах или выполнять иную работу в логове, некоторое время будут рабами, прежде чем встретить такую же судьбу.

Когда огненные тритоны идут на войну, то вместо того, чтобы устраивать отдельные налёты, они перестают брать пленных. Их целью становится полное уничтожение врага, и самая лютая вражда приберегается ими для их сородичей. Если две группы огненных тритонов натыкаются друг на друга, скорее всего это произойдет в результате соперничества за территорию, и обычный результат — кровавая битва.`,
    source: {
      id: SOURCE_VGTM,
      page: 177,
    },
  },
  giantStriderDescription,
]
