const {
  SOURCE_ERFTLW,
} = require('./../../../../sourceList')
const {
  CR_1_2,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ABERRATION,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_CROSSBOW_HAND,
  GEAR_MORNINGSTAR,
  GEAR_SHIELD,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_DEEP_SPEECH,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CREATURE_DOLGAUNT,
  CREATURE_DOLGRIM,
  CREATURE_GOBLIN,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Долгрим',
  nameEn: 'Dolgrim',
  id: CREATURE_DOLGRIM,
  description: `Долгримы — приземистые искажённые дэлькирами существа. Долгрим — это, по сути, два [гоблина](CREATURE:${CREATURE_GOBLIN}), сдавленные в одно существо. У такого деформированного тела четыре руки и пара бормочущих искривлённых ртов, криво покрывающих переднюю часть безголового торса долгрима. Рты долгрима иногда ведут безумные разговоры друг с другом. Однако личность у долгрима только одна — садистская, кровожадная и твёрдо нацеленная на служение самому себе.

Небольшое количество этих существ иногда выбирается на поверхность, часто под командованием [долгонтов](CREATURE:${CREATURE_DOLGAUNT}), и выполняют некие задания, продвигая непостижимые планы своих злобных хозяев. Но огромные орды долгримов всё ещё заперты в Хайбере вместе с дэлькирами, мечтая о том дне, их выпустят в Эберрон — пировать и разрушать.`,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_ERFTLW,
    page: 292,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
    gearId: GEAR_SHIELD,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_GOBLIN,
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Двойное сознание',
      description: `★СУЩЕСТВО★ получает преимущество на испытания от Ослепления, Очарования, Глухоты, Испуга, Ошеломления и Потери сознания.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки.`,
    },
    {
      gearId: GEAR_MORNINGSTAR,
    },
    {
      gearId: GEAR_SPEAR,
    },
    {
      gearId: GEAR_CROSSBOW_HAND,
    },
  ],
}
