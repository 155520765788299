const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_AWAKENED_SHRUB} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_PLANT} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ONE_OF_ITS_CREATOR} = require('./../../../../languageIdList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_AWAKEN} = require('./../../../../spellIdList')

module.exports = {
  name: 'Пробуждённый куст',
  nameEn: 'Awakened Shrub',
  id: CREATURE_AWAKENED_SHRUB,
  description: `Пробуждённый куст это обычный куст, получивший сознание и подвижность заклинанием [Пробуждение разума](SPELL:${SPELL_AWAKEN}) или подобной магией.`,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 337,
  },
  armor: 9,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 3,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 11,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  resistanceList: [
    DAMAGE_PIERCING,
  ],
  languageList: [
    LANG_ONE_OF_ITS_CREATOR,
  ],
  cr: CR_0,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного куста`,
    },
  ],
  actionList: [
    {
      name: 'Разрывание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 1,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: -1,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
