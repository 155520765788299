const {
  SPELL_ANIMATE_DEAD,
  SPELL_BESTOW_CURSE,
  SPELL_BIGBY_S_HAND,
  SPELL_BLIGHT,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CHILL_TOUCH,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CLOUDKILL,
  SPELL_DANCING_LIGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_FALSE_LIFE,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MENDING,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_RAY_OF_SICKNESS,
  SPELL_STONESKIN,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_WEB,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {ATTACK_MELEE_SPELL} = require('./../../../../attackTypeList')
const {CR_9} = require('./../../../../crList')
const {CREATURE_NECROMANCER} = require('./../../../../creatureIdList')
const {DAMAGE_NECROTIC} = require('./../../../../damageTypeList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Некромант',
  nameEn: 'Necromancer',
  id: CREATURE_NECROMANCER,
  description: `Некроманты — специализированные волшебники, которые изучают взаимодействие жизни, смерти и посмертия. Некоторым нравится выкапывать трупы, чтобы создавать рабов-нежить. Некоторые используют свои силы ради добра, становясь охотниками на нежить и рискуя своими жизнями, чтобы спасти других.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 218,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
  ],
  languageList: [
    {
      id: LANG_ANY,
      count: 4,
    },
  ],
  cr: CR_9,
  featureList: [
    {
      name: 'Мрачная жатва',
      limit: {
        count: 1,
        period: 'ход',
      },
      description: `Когда Некромант убивает существо, не являющееся ни нежитью, ни конструктом, заклинанием 1 круга или выше, Некромант восстанавливает хиты равные удвоенному уровню заклинания или утроенному уровню заклинания, если это заклинание Некромантии.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 12,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      {
        id: SPELL_BESTOW_CURSE,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Некромантии 1 уровня и выше, которое может активировать особенность «Мрачная жатва»',
      },
      {
        id: SPELL_BLIGHT,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Некромантии 1 уровня и выше, которое может активировать особенность «Мрачная жатва»',
      },
      {
        id: SPELL_CIRCLE_OF_DEATH,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Некромантии 1 уровня и выше, которое может активировать особенность «Мрачная жатва»',
      },
      {
        id: SPELL_CLOUDKILL,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Некромантии 1 уровня и выше, которое может активировать особенность «Мрачная жатва»',
      },
      {
        id: SPELL_RAY_OF_SICKNESS,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Некромантии 1 уровня и выше, которое может активировать особенность «Мрачная жатва»',
      },
      {
        id: SPELL_VAMPIRIC_TOUCH,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Некромантии 1 уровня и выше, которое может активировать особенность «Мрачная жатва»',
      },

      SPELL_ANIMATE_DEAD,
      SPELL_BIGBY_S_HAND,
      SPELL_BLINDNESS_DEAFNESS,
      SPELL_CHILL_TOUCH,
      SPELL_DANCING_LIGHTS,
      SPELL_DIMENSION_DOOR,
      SPELL_FALSE_LIFE,
      SPELL_MAGE_ARMOR,
      SPELL_MAGE_HAND,
      SPELL_MENDING,
      SPELL_RAY_OF_ENFEEBLEMENT,
      SPELL_STONESKIN,
      SPELL_WEB,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
    ],
  },
  actionList: [
    {
      name: 'Иссушающее касание',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 4,
        },
      },
    },
  ],
}
