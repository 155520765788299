const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_DARKLING} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_DARKLING,
  nameEn: 'Darkling',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'дарклинг',
      genitive: 'дарклинга',
      dative: 'дарклингу',
      accusative: 'дарклинга',
      instrumental: 'дарклингом',
      prepositional: 'дарклинге',
    },
    plural: {
      nominative: 'дарклинги',
      genitive: 'дарклингов',
      dative: 'дарклингам',
      accusative: 'дарклингов',
      instrumental: 'дарклингами',
      prepositional: 'дарклингах',
    },
  },
}
