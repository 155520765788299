const {
  SPELL_CONTROL_WEATHER,
  SPELL_CURE_WOUNDS,
  SPELL_DETECT_MAGIC,
  SPELL_DISGUISE_SELF,
  SPELL_FEATHER_FALL,
  SPELL_FLY,
  SPELL_FOG_CLOUD,
  SPELL_GASEOUS_FORM,
  SPELL_INVISIBILITY,
  SPELL_LIGHT,
  SPELL_MAJOR_IMAGE,
  SPELL_MINOR_ILLUSION,
  SPELL_MISTY_STEP,
  SPELL_PRESTIDIGITATION,
  SPELL_SILENT_IMAGE,
  SPELL_SUGGESTION,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  SPELL_TELEKINESIS,
  SPELL_TONGUES,
  SPELL_VICIOUS_MOCKERY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_SLEIGHT_OF_HAND,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_GIANT_CLOUD,
  CREATURE_TYPE_GIANT,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_CLOUD_GIANT_SMILING_ONE,
  CREATURE_STORM_GIANT,
} = require('./../../../../creatureIdList')
const {
  ABILITY_CHANGE_SHAPE,
  ABILITY_KEEN_SMELL,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  cloudGiantDescriptionList,
  giantCloudNote,
} = require('./../../../../textCommonParts')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_11} = require('./../../../../crList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GEAR_MORNINGSTAR} = require('./../../../../gearIdList')
const {GOD_MEMNOR} = require('./../../../../godIdList')
const {PC_CLASS_BARD} = require('./../../../../pcClassIdList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Улыбающийся облачный великан',
  nameAlt: 'Улыбающийся облачный гигант',
  nameEn: 'Cloud Giant Smiling One',
  id: CREATURE_CLOUD_GIANT_SMILING_ONE,
  note: giantCloudNote,
  description: [
    `Облачные великаны в целом не религиозны. Они допускают разные конфликтующие идеи о своём главном божестве, [Мемноре](GOD:${GOD_MEMNOR}). Улыбающиеся же щепетильны по отношению к этим допущениям.

Улыбающиеся — это облачные великаны, которые чтят и подражают коварству и хитрости [Мемнора](GOD:${GOD_MEMNOR}) превыше всего. Они превосходные обманщики, которые используют ловкость рук, обман, запутывание и магию, преследуя богатство. У них также есть тяга к непредсказуемости и очень извращённое чувство юмора.

Хотя облачные великаны ожидают определенной степени какого-то обмана и хитрости в своих делах с другими собратьями, улыбающиеся преступают границы приличия своим поведением, делая и говоря вещи, которые благородные облачные великаны считают ниже достоинства своего вида.`,
    {
      header: 'Загадочные маски',
      text: `Улыбающиеся прозваны так из-за странных двуликих масок, которые они носят. Улыбающаяся половина лица чаще выглядит как ухмылка или торжествующая насмешка, чем как приятная улыбка. Грустная половина представляют неудовольствие, которые улыбающиеся чувствуют из-за своего места в Уложении — они вторые после [штормовых великанов](CREATURE:${CREATURE_STORM_GIANT}). Эти маски являются символом их преданности, а так же скрывают настоящее выражение лица носителя.`,
      source: {
        id: SOURCE_VGTM,
        page: 138,
      },
    },
    ...cloudGiantDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_CLOUD,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_VGTM,
    page: 138,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 21,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 26,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 22,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 17,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SLEIGHT_OF_HAND]: PROF_DOUBLE,
  },
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_11,
  featureList: [
    ABILITY_KEEN_SMELL,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DETECT_MAGIC,
            SPELL_FOG_CLOUD,
            SPELL_LIGHT,
          ],
        },
        {
          limit: {
            count: 3,
            period: 'день',
          },
          list: [
            SPELL_FEATHER_FALL,
            SPELL_FLY,
            SPELL_MISTY_STEP,
            SPELL_TELEKINESIS,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_CONTROL_WEATHER,
            SPELL_GASEOUS_FORM,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 5,
      spellCasterClass: PC_CLASS_BARD,
      spellIdList: [
        SPELL_CURE_WOUNDS,
        SPELL_DISGUISE_SELF,
        SPELL_INVISIBILITY,
        SPELL_MAJOR_IMAGE,
        SPELL_MINOR_ILLUSION,
        SPELL_PRESTIDIGITATION,
        SPELL_SILENT_IMAGE,
        SPELL_SUGGESTION,
        SPELL_TASHA_S_HIDEOUS_LAUGHTER,
        SPELL_TONGUES,
        SPELL_VICIOUS_MOCKERY,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        2,
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Великан совершает две атаки _Моргенштерном_.`,
    },
    {
      name: 'Моргенштерн',
      // gearId: GEAR_MORNINGSTAR, // TODO: Найти способ перетащить в оружие с комментариями
      description: `Атака причиняет дополнительно 14 (4к6) урона, если великан атаковал с преимуществом.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Скала',
      description: `Атака причиняет дополнительно 14 (4к6) урона, если великан атаковал с преимуществом.`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 12,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 8,
        },
      },
    },
    {
      id: ABILITY_CHANGE_SHAPE,
      nextForm: `гуманоида или зверя, которого ★видел★`,
      gearMerge: `сливается с новым обликом`,
      statChange: `В любом облике ★СУЩЕСТВО★ сохраняет свою игровую статистику и способность говорить`,
    },
  ],
}
