const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CONDITION_PRONE} = require('./../../../../../conditionList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_INVESTITURE_OF_STONE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_INVESTITURE_OF_STONE,
  name: 'Облачение камня',
  nameEn: 'Investiture of Stone',
  description: `Пока действует заклинание, кусочки камня покрывают Ваше тело, и Вы получаете следующие преимущества:

* Вы получаете сопротивление к дробящему, колющему и рубящему урону от немагического оружия.
* Вы можете действием вызвать небольшое землетрясение в радиусе 15 футов с центром на вас. Другие существа в этой области должны пройти испытание Ловкости, иначе будут сбиты с ног.
* Вы можете передвигаться по труднопроходимой местности, состоящей из земли или камня, не тратя дополнительное перемещение. Вы можете проходить сквозь твёрдую землю или камень так, словно это воздух, не разрушая их, однако не можете закончить там перемещение. Если Вы заканчиваете перемещение, находясь внутри земли или камня, Вас выталкивает в ближайшее свободное пространство, заклинание заканчивается, и Вы становитесь ошеломлённым до конца своего следующего хода.`,
  lvl: 6,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    condition: CONDITION_PRONE,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 159,
    },
    {
      id: SOURCE_EE,
      page: 19,
    },
  ],
}
