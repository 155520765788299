const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_STONE_GIANT,
  CREATURE_TARRASQUE,
} = require('./../../creatureIdList')
const {
  GOD_ANNAM,
  GOD_MEMNOR,
  GOD_SKORAEUS_STONEBONES,
  GOD_SURTUR,
  GOD_THRYM,
} = require('./../../godIdList')

module.exports = [
  {
    header: 'Скораус Камнекост, Великий создатель',
    text: `[Каменные великаны](CREATURE:${CREATURE_STONE_GIANT}) поклоняются [Скораусу Камнекосту](GOD:${GOD_SKORAEUS_STONEBONES}) как Великому создателю, второму по умениям после [Аннама](GOD:${GOD_ANNAM}), но старшим над другими божествами в отсутствие отца. Он появляется в искусстве каменных великанов двумя способами: как пара рук, одна держит резец, а другая — молоток, и как самая большая статуя или рельефная резьба [каменного великана](CREATURE:${CREATURE_STONE_GIANT}) в пещерах племени. Как правило, [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) изображен ростом вдвое выше любого другого [каменного великана](CREATURE:${CREATURE_STONE_GIANT}).

В легендах великанов [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) часто сидит в стороне от затей или битв его братьев и сестёр. Он является наблюдателем, доверенным лицом других богов и хранителем секретов, которые можно выведать у него только силой или обманом. В классической сказке [Мемнор](GOD:${GOD_MEMNOR}) пришел к [Скораусу](GOD:${GOD_SKORAEUS_STONEBONES}) и что-то прошептал ему на ухо. Когда [Суртур](GOD:${GOD_SURTUR}) потребовал узнать, что сказал [Мемнор](GOD:${GOD_MEMNOR}), [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) сказал своему брату именно то, что он услышал. [Суртур](GOD:${GOD_SURTUR}) размышлял над сообщением, которое вводило в заблуждение, будучи вырванным из контекста, и в конце концов отреагировал опрометчиво, но последствия его действий не были сочтены виной [Скорауса](GOD:${GOD_SKORAEUS_STONEBONES}). Если бы [Суртур](GOD:${GOD_SURTUR}) вместо этого попросил у [Скорауса](GOD:${GOD_SKORAEUS_STONEBONES}) совета по поводу слов [Мемнора](GOD:${GOD_MEMNOR}), в легенде всё закончилось бы по-другому.

[Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) считается самым осведомленным из великанских богов в вопросах магии, охранных заклинаниях и рунах, проклятиях, тайных сокровищах и тайнах земли. [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) открыл секрет выплавки [Суртуру]. [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) показал [Триму](GOD:${GOD_THRYM}) как вырезать руны на его старом оружии, чтобы наделить их магией, когда [Суртур](GOD:${GOD_SURTUR}) отказался сковать ему новое оружие. [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) изготовил копья для Хиатеи, чтобы она могла выполнить свои десять задач доблести. [Скораус](GOD:${GOD_SKORAEUS_STONEBONES}) постучал своим молотом по камню под водой, так что Стронмаус смог найти цепь тоннелей, по которой тот смог затянуть [тарраску](CREATURE:${CREATURE_TARRASQUE}) на дно океана, где Стронмаус смог её, наконец, утопить.`,
    source: {
      id: SOURCE_VGTM,
      page: 32,
    },
  },
]
