const {
  MAGIC_ILLUSION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  PARAM_WIT,
} = require('./../../../../../paramList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../../damageTypeList')
const {
  CONDITION_FRIGHTENED,
} = require('./../../../../../conditionList')
const {
  SPELL_WEIRD,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_WEIRD,
  name: 'Смертный ужас',
  nameEn: `Weird`,
  description: `Воззвав к сильнейшим страхам группы существ, Вы создаёте иллюзорных существ в их сознаниях, видимых только для них.

Все существа в сфере с радиусом 30 футов с центром на выбранной точке в пределах дистанции, должны пройти испытание Мудрости. При провале существо становится испуганным на время длительности заклинания. Иллюзия взывает к самым потаённым страхам, проявляясь в виде худших кошмаров и неумолимой угрозы.

В конце каждого своего хода испуганное существо должно преуспевать в испытании Мудрости, иначе оно получает урон психической энергией 4к10. При успехе для этого существа заклинание оканчивается.`,
  lvl: 9,
  magicSchoolId: MAGIC_ILLUSION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
    condition: CONDITION_FRIGHTENED,
    damage: {
      type: DAMAGE_PSYCHIC,
      diceCount: 4,
      diceType: 10,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 278,
    },
    {
      id: SOURCE_SRD,
      page: 192,
    },
  ],
}
