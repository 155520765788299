const {GENDER_FEMALE} = require('./../../genderList')
const {MAGIC_ITEM_WAND_OF_WINTER} = require('./../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../magicItemRarityList')
const {MGC_TYPE_WAND} = require('./../../magicItemTypeList')
const {SOURCE_HOTDQ} = require('./../../sourceList')
const {
  SPELL_ICE_STORM,
  SPELL_RAY_OF_FROST,
  SPELL_SLEET_STORM,
} = require('./../../spellIdList')

module.exports = [
  {
    id: MAGIC_ITEM_WAND_OF_WINTER,
    name: `Волшебная палочка зимы`,
    nameEn: `Wand of Winter`,
    type: MGC_TYPE_WAND,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Волшебная палочка выглядит и на ощупь как сосулька. Требуется настройка, чтоб использовать эту палочку.

У палочки есть 7 зарядов, которые можно использовать, чтобы творить заклинания с её помощью. С палочкой в руке, Вы можете действием сотворить одно из следующих заклинаний, даже если Вы не способны творить заклятия:

* [Град](SPELL:${SPELL_ICE_STORM}) (4 заряда; СЛ 15).
* [Луч холода](SPELL:${SPELL_RAY_OF_FROST}) (без траты зарядов, или 1 заряд, чтобы сотворить его как заклинание 5 уровня; +5 к попаданию дальнобойной атакой заклинанием).
* [Метель](SPELL:${SPELL_SLEET_STORM}) (3 заряда; СЛ 15).

Никаких компонентов для этого не требуется.

Волшебная палочка восполняет 1к6 + 1 потраченных зарядов каждый день на рассвете. Если Вы потратите последний заряд палочки, бросьте к20. При результате 20, палочка плавится у вас в руках — она разрушена навсегда.`,
    spellIdList: [
      SPELL_ICE_STORM,
      SPELL_RAY_OF_FROST,
      SPELL_SLEET_STORM,
    ],
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_HOTDQ,
      page: 105,
    },
  },
]
