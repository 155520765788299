const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CREATURE_DEATH_S_HEAD_OF_BHAAL} = require('./../../../../creatureIdList')
const {CR_5} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {bhaalFollowerList} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_AURA_OF_MURDER,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')

module.exports = {
  name: 'Рука смерти Баала',
  nameEn: 'Death’s head of Bhaal',
  id: CREATURE_DEATH_S_HEAD_OF_BHAAL,
  description: bhaalFollowerList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_BGDIA,
    page: 237,
  },
  armor: 15,
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 20,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_AURA_OF_MURDER,
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ использует _Шокирующий взгляд_ и совершает две атаки _Кинжалом_.`,
    },
    {
      gearId: GEAR_DAGGER,
    },
    {
      name: 'Шокирующий взгляд',
      description: `★СУЩЕСТВО★ выбирает целью одно существо, которые видит в пределах 30 футов от себя. Цель должна пройти испытание Мудрости СЛ 14, при провале она будет ошеломлена до конца своего следующего хода.`,
    },
  ],
  reactionList: [
    {
      name: 'Неудержимый',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ снижает урон, полученный от атаки, до 0.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
