const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_9,
  } = require('./../../../../crList'),
  {
    SIZE_HUGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GIANT_CLOUD,
    CREATURE_TYPE_GIANT,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NE,
    ALIGNMENT_NG,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_COMMON,
    LANG_GIANT,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    SKILL_INSIGHT,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_CONTROL_WEATHER,
    SPELL_DETECT_MAGIC,
    SPELL_FEATHER_FALL,
    SPELL_FLY,
    SPELL_FOG_CLOUD,
    SPELL_GASEOUS_FORM,
    SPELL_LIGHT,
    SPELL_MISTY_STEP,
    SPELL_TELEKINESIS,
  } = require('./../../../../spellIdList'),
  {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_MORNINGSTAR,
  } = require('./../../../../gearIdList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    cloudGiantDescriptionList,
    giantCloudNote,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_KEEN_SMELL,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_CLOUD_GIANT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Облачный великан',
  nameAlt: [
    'Облачный гигант',
    'Скьеётун',
  ],
  nameEn: 'Cloud Giant',
  id: CREATURE_CLOUD_GIANT,
  note: giantCloudNote,
  description: cloudGiantDescriptionList,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_CLOUD,
  ],
  alignmentId: [
    {
      id: ALIGNMENT_NG,
      comment: '50%',
    },
    {
      id: ALIGNMENT_NE,
      comment: '50%',
    },
  ],
  source: {
    id: SOURCE_MM,
    page: 39,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 27,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 22,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
  },
  languageList: [
    LANG_GIANT,
    LANG_COMMON,
  ],
  cr: CR_9,
  featureList: [
    ABILITY_KEEN_SMELL,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
          SPELL_LIGHT,
          SPELL_FOG_CLOUD,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_FEATHER_FALL,
          SPELL_FLY,
          SPELL_TELEKINESIS,
          SPELL_MISTY_STEP,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONTROL_WEATHER,
          SPELL_GASEOUS_FORM,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Моргенштерном_.`,
    },
    {
      name: 'Моргенштерн',
      // gearId: GEAR_MORNINGSTAR, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 12,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 8,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
