const {CR_1} = require('./../../../../crList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {howlingHatredDescriptionList} = require('./../../../../textCommonParts')
const {MAGIC_ITEM_WINGWEAR} = require('./../../../../magicItemIdList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ANIMAL_HANDLING,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {
  GEAR_LONGSWORD,
  GEAR_SCALE_MAIL,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {SPEED_WALK} = require('./../../../../speedList')
const {
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FEATHER_FALL,
  SPELL_GUST,
  SPELL_LIGHT,
  SPELL_MESSAGE,
  SPELL_RAY_OF_FROST,
} = require('./../../../../spellIdList')
const {
  LANG_AURAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_FEATHERGALE_KNIGHT,
  CREATURE_GIANT_VULTURE,
  CREATURE_HIPPOGRIFF,
  CREATURE_THURL_MEROSSKA,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Рыцарь Бури Перьев',
  nameEn: 'Feathergale Knight',
  id: CREATURE_FEATHERGALE_KNIGHT,
  description: [
    {
      header: 'Рыцари Бури Перьев',
      text: `Рыцари Бури Перьев — это амбициозные и обеспеченные жители больших городов (например, Глубоководья), которых культ воздуха соблазнил обещаниями могущества. Летая верхом на [гиппогрифах](CREATURE:${CREATURE_HIPPOGRIFF}) или [гигантских грифах](CREATURE:${CREATURE_GIANT_VULTURE}), они с высоты наблюдают за окрестностями шпиля Бури Перьев и охраняют вход в скрытый храм культа воздуха.

Рыцари, возглавляемые лордом-командующим [Тёрлом Меросской](CREATURE:${CREATURE_THURL_MEROSSKA}), презрительно относятся к прочим культам стихий, и считают их членов извращёнными безумцами. Себя же рыцари Бури Перьев воспринимают как элитарную группу людей, способных на тяжёлые решения, идущие во благо их земель. На самом деле, все они связаны с Ян-Си-Бином и не замечают собственной порочности и падения нравов. Несмотря на свои изысканные манеры и светскую утончённость, каждый рыцарь скрепил свой контракт с культом, убив разумное существо. Более того, они уверены, что эти смерти помогли обезопасить мир.

Рыцари могут использовать [полётное одеяние](MAGIC_ITEM:${MAGIC_ITEM_WINGWEAR}). Они довольно часто применяют его в бою и других столкновениях.`,
      source: {
        id: SOURCE_PotA,
        page: 196,
      },
    },
    ...howlingHatredDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_PotA,
    page: 196,
  },
  armor: {
    ac: 16,
    gearId: GEAR_SCALE_MAIL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_ANIMAL_HANDLING]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    LANG_AURAN,
    LANG_COMMON,
  ],
  cr: CR_1,
  spellCast: {
    spellCasterLevel: 1,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_FEATHER_FALL,
      SPELL_GUST,
      SPELL_LIGHT,
      SPELL_MESSAGE,
      SPELL_RAY_OF_FROST,
    ],
    slotCountList: [
      Infinity,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      gearId: GEAR_SPEAR,
    },
  ],
}
