const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_ABYSSAL,
  LANG_CELESTIAL,
  LANG_INFERNAL,
  LANG_PRIMORDIAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_KRAKEN,
  CREATURE_WATER_ELEMENTAL,
} = require('./../../../../creatureIdList')
const {
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_TITAN,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_SIEGE_MONSTER,
} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_23} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPELL_CONTROL_WEATHER} = require('./../../../../spellIdList')

module.exports = {
  name: 'Кракен',
  nameEn: 'Kraken',
  id: CREATURE_KRAKEN,
  description: [
    `Под волнами кракен спит неисчислимые века в ожидании какого-нибудь дурного знака или зова. Сухопутные смертные, которые плавают в открытом море, стали забывать причины, по которым их предки страшились океана, да и расы глубин игнорируют странные провалы в своих историях, когда их цивилизации практически исчезали после появления ужасных щупалец.
      
**Левиафаны из легенд.** В начале времён кракены служили жестокими воителями богов. Когда войны богов закончились, кракены возжелали свободы, чтобы никогда больше не служить другим существам.

Держа в страхе целые народы, кракен появляется из своих темных владений, и даже посреди океана штормы возникают или утихают по его воле. Кракен олицетворяет первобытную силу, которая стирает величайшие достижения цивилизации, словно они были песочными замками. Эти разрушительные атаки могут уничтожить судоходную торговлю и остановить связь между прибрежными городами.

Зловещая темнота предвещает нападение кракена, и облако чернильного яда окрашивает воду вокруг. Галеоны и военные корабли пропадают, когда его щупальца возникают из глубин. Кракен ломает их мачты как щепки, после чего тащит вглубь корабли и экипаж.

Даже не имеющие выходу к морю города не защищены от гнева кракена. Кракены могут дышать воздухом так же легко как и водой, и некоторые из них пробираются вверх по рекам, обосновываясь в пресноводных озёрах, уничтожая целые города на своём пути. Искатели приключений рассказывают, что эти чудовища гнездятся в руинах прибрежных цитаделей, обвивая щупальцами падающие башни, распадающиеся на камни.

**Смертоносные противники.** Некоторые кракены практически равны богам, со своими культами и приспешниками, разбросанными как по морю, так и по суше. Другие же являются союзниками Олидры, злой Княгини Стихийной Воды, и используют её культистов для воплощения своей воли, как на суше, так и на море. Кракены, довольные своими последователями, могут успокоить бушующие моря и послать им щедрый улов. Однако древний и коварный ум кракена жаждет расплаты, и в конечном итоге стремится разрушить всё вокруг себя.`,
    {
      header: `Логово кракена`,
      text: `Кракен живёт в темных глубинах, как правило, в подводном разломе или пещере, заполненной мусором, сокровищами и обломками кораблей.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) кракен совершает действие логова, чтобы вызвать один из следующих магических эффектов:

* По логову кракена проносится сильное течение. Все существа в пределах 60 футов от кракена должны пройти испытание Силы СЛ 23, иначе их оттолкнёт на 60 футов от кракена. При успехе существа толкаются на 10 футов от кракена.
* Существа, находящиеся в воде в пределах 60 футов от кракена, получают уязвимость к урону электричеством до значения инициативы «20» следующего раунда.
* Вода в логове становится заряженной электричеством. Все существа в пределах 120 футов от кракена должны пройти испытание Телосложения СЛ 23, получая урон электричеством 10 (3к6) при провале, или половину этого урона при успехе.

# Местные эффекты

Регион, содержащий логово кракена, осквернён присутствием не угодных богам созданий, создающих следующие магические эффекты:

* Кракен может изменить погоду по своему желанию в радиусе 6 миль с центром на логове. Эффект идентичен заклинанию [Власть над погодой](SPELL:${SPELL_CONTROL_WEATHER}).
* [Водяные элементали](CREATURE:${CREATURE_WATER_ELEMENTAL}) заводятся в пределах 6 миль от логова. Эти элементали не могут покинуть воду, и значения их Интеллекта и Харизмы равны 1 (−5).
* Водные существа с Интеллектом 2 и выше в пределах 6 миль от логова становятся очарованы кракеном и агрессивно настроены к чужакам в этой области.

Когда кракен умирает, все эти местные эффекты немедленно исчезают.`,
      source: {
        id: SOURCE_MM,
        page: 179,
      },
    },
  ],
  note: {
    text: `Во сне кракен мечтает дотянуться щупальцами до небес и задушить всё, что было рождено на них, и когда его щупальца действительно чего-то касаются, он топит проплывающие над ним суда`,
    author: `из книги «НОЧЬ КУЛЬТА КРАКЕНА», написанной Мэлфеором Серрэнгом, пиратским волшебником из Тетира`,
  },
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_TITAN,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 180,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 27,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 60,
  },
  params: {
    [PARAM_STR]: 30,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 25,
    [PARAM_INT]: 22,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 20,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  immunityList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: [LANG_ABYSSAL, LANG_INFERNAL, LANG_CELESTIAL, LANG_PRIMORDIAL],
      doNotSpeak: true,
    },
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_23,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Свобода перемещений',
      description: `★СУЩЕСТВО★ игнорирует труднопроходимую местность, и магические эффекты не могут ни уменьшить его скорость, ни сделать обездвиженым. Он может потратить 5 футов перемещения, чтобы избавиться от немагического обездвиживания или перестать быть схваченным.`,
    },
    ABILITY_SIEGE_MONSTER,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки _Щупальцем_, каждую из которых можно заменить _Броском_.`,
    },
    {
      name: 'Укус',
      description: `Если цель — существо с размером не больше Большого, схваченное ★СУЩЕСТВО★, это существо проглатывается, и захват оканчивается. Проглоченное существо слепо, обездвижено, обладает полным укрытием от атак и прочих эффектов, исходящих снаружи, и получает урон кислотой 42 (12к6) в начале каждого хода ★СУЩЕСТВО★.

Если ★СУЩЕСТВО★ получает в течение одного хода от находящегося внутри него существа урон 50 или больше, он должен в конце этого хода пройти испытание Телосложения СЛ 25, иначе изрыгнёт всех проглоченных существ, которые падают ничком в пространстве в пределах 10 футов от ★СУЩЕСТВО★. Если ★СУЩЕСТВО★ умирает, проглоченное существо перестаёт быть обездвиженым им, и может высвободиться из трупа, используя 15 футов перемещения, и на выходе падает ничком.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Щупальце',
      description: `Цель становится схваченной (СЛ высвобождения 18). Пока цель схвачена, она обездвижена. ★СУЩЕСТВО★ имеет десять щупалец, и каждое может держать в захвате одну цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 30,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Бросок',
      description: `Один несомый предмет с размером не больше Большого, или существо, которое ★схватил★ ★СУЩЕСТВО★, бросается на расстояние до 60 футов в случайном направлении, после чего падает ничком. Если брошенная цель попадает в твёрдую поверхность, цель получает дробящий урон 3 (1к6) за каждые 10 футов броска. Если цель была брошена в другое существо, это существо должно пройти испытание Ловкости СЛ 18, иначе получит такой же урон и будут сбито с ног.`,
    },
    {
      name: 'Буря с грозой',
      description: `★СУЩЕСТВО★ магическим образом создаёт три разряда молнии, каждый из которых может ударить цель, видимую в пределах 120 футов от ★него★. Цель должна пройти испытание Ловкости СЛ 23, получая урон электричеством 22 (4к10) при провале, или половину этого урона при успехе.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака щупальцем или бросок',
      description: `★СУЩЕСТВО★ совершает одну атаку _Щупальцем_ или использует _Бросок_.`,
    },
    {
      name: 'Буря с грозой',
      cost: 2,
      description: `★СУЩЕСТВО★ использует _Бурю с грозой_.`,
    },
    {
      name: 'Облако чернил',
      cost: 2,
      description: `Находясь под водой, ★СУЩЕСТВО★ выпускает облако чернил с радиусом 60 футов. Облако огибает углы, и его местность является сильно заслонённой для всех кроме ★него★ ★самого★. Все существа кроме ★СУЩЕСТВО★, оканчивающие там ход, должны пройти испытание Телосложения СЛ 23, получая урон ядом 16 (3к10) при провале, или половину этого урона при успехе. Сильное течение рассеивает облако, которое в противном случае всё равно исчезает в конце следующего хода ★СУЩЕСТВО★.`,
    },
  ],
  genderId: GENDER_MALE,
}
