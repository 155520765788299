const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_HILL_GIANT,
  CREATURE_STORM_GIANT,
  CREATURE_YEENOGHU,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Язык великанов',
    text: `Язык, который объединяет великанов, является одним из немногих остатков от их некогда великой империи. Со временем он раскололся на множество диалектов, и каждый тип имеет свой отличительный акцент, но великаны разных типов могут понимать друг друга.

Любой не-великан, который выучил язык великанов, может разговаривать со всеми типами великанов, но великанам иногда трудно услышать тихие голоса существ человеческого размера, а некоторые гласные звуки, издаваемые великанами, практически невозможно воспроизвести для любого существа, у которого нет легких размером с пивные бочки.`,
    source: {
      id: SOURCE_VGTM,
      page: 21,
    },
  },
  {
    header: 'Маат и мауг',
    text: `Два слова имеют особое значение в языке и мировоззрении великанов. Ни одно из них не переводится непосредственно в Общий или любой другой язык, потому что их определения охватывают несколько взаимосвязанных понятий. _Маат_ (произносится _«мотт»_) является термином, который великаны используют для описания идеи, модели поведения, а также существ и объектов, которых они считают хорошими, святыми, благородными, или желательными. _Мауг_ (произносится _«мог»_) является термином-антонимом, воплощая то, что другие языки называют злом, безобразием, бесчестием, чем-то нежелательным.

Отдельные великаны не обязательно считаются _маат_ или _мауг_ своими соплеменниками. Важна не личная философия великана, но его позиция в Уложении, которая находится под влиянием поведения и отношений, а также целого ряда других факторов. Каждый индивид совершает как _маат_, так и _мауг_ поступки, и соответственно поднимается или падает в Уложении. Великана другие великаны судят не на основании того, был ли совершенный им поступок сам по себе добрым или злым, а на основании того, усилил этот поступок или ослабил те качества, которые великаны почитают — если хотите, «величие» — самого великана и его клана.

К примеру [штормовой великан](CREATURE:${CREATURE_STORM_GIANT}) может считать рейдерство [холмовых великанов](CREATURE:${CREATURE_HILL_GIANT}) безвкусным, но не являющимся _мауг_, так как жестокое рейдерство — врожденная черта [холмовых великанов](CREATURE:${CREATURE_HILL_GIANT}). Если бы те же [холмовые великаны](CREATURE:${CREATURE_HILL_GIANT}) поклонялись [Йеногу](CREATURE:${CREATURE_YEENOGHU}), такое поведение могло считаться вопиющим уходом от традиций и Уложения. Те [холмовые великаны](CREATURE:${CREATURE_HILL_GIANT}), которые выбирают этот путь, становятся _мауг_.

Невеликаны считаются _мауг_ по умолчанию, и всегда должны доказывать, что они _маат_, чтобы заслужить уважение великанов.`,
    source: {
      id: SOURCE_VGTM,
      page: 22,
    },
  },
  {
    header: 'Разговорные фразы великанов',
    text: `
* _«К какому племени или рангу ты относишься?»_ — _Во дун стомм рад?_
* _«Кто твой лидер?»_ — _Вер дун фёрер?_
* _«Я тебя уважаю»_ — _Ам ду паарт._
* _«Кто там идет?»_ — _Вер фёрс дир?_
* _«Куда ты идешь?»_ — _Вие фирст ду?_
* _«Моё имя Красный Ветер Тысячного Зла»_ — _Родвинд Тусенмауг эр мег ном._
* _«Атакуй наших врагов!»_ — _Анфель су увенир!_
* _«Отведите меня к своему королю»_ — _Фанг мег зо дун кёнг._
`,
    source: {
      id: SOURCE_VGTM,
      page: 22,
    },
  },
]
