import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SourceInfo from '@/components/SourceInfo'

import './ImageStyles.less'

const ImageComponent = (
  {
    className = '',
    noBorder = false,
    noGap = false,
    noText = false,
    showEmpty = false,
    source,
    src,
    text,
    title,
  }
) => (
  <section
    className={classnames(
      'Image',
      className,
      {
        'Image-noBorder': noBorder,
        'Image-noGap': noGap,
        'Image-empty': showEmpty,
      }
    )}
    itemScope
    itemType="http://schema.org/ImageObject"
  >
    <img
      className='Image_img'
      src={src}
      alt={title}
      title={title}
      itemProp="contentUrl"
    />

    {
      noText
        ? null
        : (
          <p
            className='Image_text'
            itemProp="name"
          >
            {text}
          </p>
        )
    }

    {
      source && !noText
        ? (
          <p className='Image_sourceBlock'>
            Источник иллюстрации: <SourceInfo
            className='Image_source'
            source={source}
            useFullName={true}
            showPage={false}
          />
          </p>
        )
        : null
    }
  </section>
)

ImageComponent.propTypes = {
  className: PropTypes.string,
  source: PropTypes.object,
  src: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
}

export default ImageComponent
