const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../sourceList')
const {CREATURE_TYPE_HUMAN} = require('./../../creatureTypeIdList')
const {humanLanguagesDescriptionList} = require('./../../textCommonParts')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_HALFLING,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  GOD_SELUNE,
  GOD_SHAR,
} = require('./../../godIdList')

module.exports = {
  id: CREATURE_TYPE_HUMAN,
  nameEn: 'Human',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'мужчина',
        genitive: 'мужчины',
        dative: 'мужчине',
        accusative: 'мужчину',
        instrumental: 'мужчиной',
        prepositional: 'мужчине',
      },
      [GENDER_FEMALE]: {
        nominative: 'женщина',
        genitive: 'женщины',
        dative: 'женщине',
        accusative: 'женщину',
        instrumental: 'женщиной',
        prepositional: 'женщине',
      },
    },
    plural: {
      nominative: 'люди',
      genitive: 'людей',
      dative: 'людям',
      accusative: 'людей',
      instrumental: 'людьми',
      prepositional: 'людях',
    },
  },
  note: {
    text: `Это были рассказы о беспокойном народе, путешествовавшем по морям и рекам на длинных кораблях, сперва для грабежа и разбоя, потом в поисках мест для поселений. Но с каждой страницы била энергия, любовь к приключениям. Лириэль читала большую часть ночи, тратя драгоценные свечи.

Она никогда не задумывалась о людях, но эти истории восхищали её. В этих пожелтевших страницах говорилось о храбрых героях, странных и яростных животных, могучих богах и магии, которая была неотъемлемой частью той дальней земли.`,
    author: `Элейн Каннингем, Дочь дроу`,
  },
  description: [
    {
      text: `В большинстве миров люди — это самая молодая из распространённых рас. Они поздно вышли на мировую сцену и живут намного меньше, чем [дварфы](PC_RACE:${PC_RACE_DWARF}), [эльфы](PC_RACE:${PC_RACE_ELF}) и драконы. Возможно, именно краткость их жизней заставляет их стремиться достигнуть как можно большего в отведённый им срок. А быть может, они хотят что-то доказать старшим расам, и поэтому создают могучие империи, основанные на завоеваниях и торговле. Что бы ни двигало ими, люди всегда были инноваторами и пионерами во всех мирах.`,
      source: {
        id: SOURCE_PHB,
        page: 30,
      },
    },
    {
      header: `Многоликие`,
      text: `Со своей склонностью к миграции и завоеваниям, люди более разнообразны физически, чем другие распространенные расы.

Не существует типичной внешности человека. Рост отдельного представителя может составлять от 5 до 6 футов (от 152 до 184 сантиметров), а вес — от 125 до 250 фунтов (от 60 до 112 килограмм). Оттенок кожи у людей варьируется от почти чёрного до очень бледного, а волосы — от чёрного до светлого тонов (волнистые, кудрявые или прямые); мужчины могут носить волосы на лице, густые или редкие.

Многие люди имеют примесь нечеловеческой крови, выдающей черты [эльфа](PC_RACE:${PC_RACE_ELF}), [орка](PC_RACE:${PC_RACE_ORC}), или предков другого происхождения. Люди достигают совершеннолетия около двадцати лет, и редко проживают одно столетие.`,
      source: {
        id: SOURCE_PHB,
        page: 30,
      },
    },
    {
      header: `Разнообразие во всём`,
      text: `Люди являются самыми приспособленным и амбициозным народом среди распространённых рас. Их вкусы, нравы и обычаи сильно отличаются во множестве разных земель, где они поселились. Однако там, где они селятся, они остаются надолго: строят города, стоящие веками, и великие королевства, сохраняющиеся множество столетий.

У отдельного человека может быть относительно короткая продолжительность жизни, но человеческая нация и культура сохраняет традиции от своих истоков на время, намного превышающее память одного человека. Они живут сегодняшним днём, что весьма хорошо подходит для приключенческой жизни, и позволяет вдобавок строить планы на будущее, со стремлением оставить после себя прочное наследие. Индивидуально или в группах, люди легко приспосабливаются к ситуации, и всегда остаются внимательными к изменяющейся политической и социальной динамике.`,
      source: {
        id: SOURCE_PHB,
        page: 30,
      },
    },
    {
      header: `Вторые лучшие друзья для каждого`,
      text: `Так же легко как они смешиваются друг с другом, люди ассимилируются с представителями других рас. Они могут ужиться почти со всеми, хотя и редко сближаются. Люди служат послами, дипломатами, магистрами, торговцами и чиновниками всех мастей.

# О [дварфах](PC_RACE:${PC_RACE_DWARF})

_«Это крепкий народ, верные друзья, и они держат своё слово. И всё же, их жажда золота однажды погубит их»_.

# Об [эльфах](PC_RACE:${PC_RACE_ELF})

_«Лучше не бродить в эльфийских лесах. Они не любят незваных гостей, и Вы будете, вероятно, околдованы или изрешечены стрелами. Но если эльф сможет преодолеть проклятую расовую гордость и общаться с вами как с равным, Вы можете многому у него научиться»_.

# О [полуросликах](PC_RACE:${PC_RACE_HALFLING})

_«Трудно превзойти ужин в доме полурослика, примерно так же трудно как не проломить голову о его потолок; хорошая еда и хорошие истории перед уютным тёплым очагом. Ели бы у полуросликов была бы ещё толика амбиций, они могли бы действительно чего-то стоить»_.`,
      source: {
        id: SOURCE_PHB,
        page: 30,
      },
    },
    {
      header: `Прочные институты`,
      text: `Там, где один [эльф](PC_RACE:${PC_RACE_ELF}) или [дварф](PC_RACE:${PC_RACE_DWARF}) могут взять ответственность за охрану особого места или великой тайны, человек создаёт священные порядки и институты. В то время как кланы дварфов и старейшины полуросликов передают свои традиции новым поколениям, человеческие храмы, государства, библиотеки и своды законов запечатлевают их традиции в анналах истории. Люди мечтают о бессмертии, но (за исключением тех, кто становится нежитью или достигает божественного восхождения, чтобы избежать оков смерти) достигают его, когда продолжают жить в памяти, после смерти.

Хотя некоторые люди могут быть ксенофобами, в целом их общество очень смешано. На землях людей обитает большое количество нечеловеческих рас, по сравнению с количеством людей, живущих на землях нелюдей.`,
      source: {
        id: SOURCE_PHB,
        page: 30,
      },
    },
    {
      header: `Воплощение амбиций`,
      text: `Люди, ищущие приключений, являются самыми смелыми и амбициозными представителями смелой и амбициозной расы. Они стремятся заработать славу в глазах своих товарищей, накапливая власть, богатство и популярность. Более чем другие народы люди борются за цель, а не за расширение территорий или процветание отдельных фракций.`,
      source: {
        id: SOURCE_PHB,
        page: 30,
      },
    },
    {
      header: `Человеческие имена и этносы`,
      text: `Поскольку культура людей самая разнообразная среди всех существующих рас, у них нет типичных имён. Некоторые родители дают своим детям имена из других наречий, например, из Дварфийского или Эльфийского (произносимые более или менее правильно), но большинство родителей даёт имена, связанные с культурой своего региона или традициями предков.

Культура быта и физические характеристики людей могут сильно меняться в зависимости от региона. В Забытых Королевствах, например, одежда, архитектура, кухня, музыка, и литература в северо-западных землях Серебряных Пустошей отличаются от тех, что есть в далёком Тёрмише или Импилтуре на востоке, и совсем не похожи на их аналоги в далёком Кара-Туре. Физические характеристики людей различаются в соответствии с переселениями их предков, поэтому люди Серебряных Пустошей имеют все возможные сочетания цветов кожи.

В Забытых Королевствах широко известны девять этнических групп, хотя более дюжины других находятся в более локализованных областях Фаэруна. Эти группы и типичные имена их представителей могут быть использованы в качестве примера, вне зависимости от того, из какого мира Ваш человек. `,
      source: {
        id: SOURCE_PHB,
        page: 30,
      },
    },
    {
      header: `Люди`,
      text: `Люди обитают в каждом уголке Торила включают полный спектр культур и народов. Вдоль Побережья Мечей и по всему Северу, люди наиболее распространены среди рас и во многих местах наиболее влиятельная. Их культурная и социальная внешность сильно рознится, от космополитичного люда больших городов, таких как Врата Балдура, до варваров, беснующихся по всему Дикому Порубежью.

Люди славятся своей способностью приспосабливаться. Ни одна другая раса не живёт в таком разнообразии земель и природных условий как люди — от непроходимых джунглей, до палящих пустынь; от вечной мерзлоты Великого Ледника до плодородных побережий рек и морей. Люди находят способ выжить и развиваться где бы то ни было. В местах, откуда ушли [эльфы](PC_RACE:${PC_RACE_ELF}) и [дварфы](PC_RACE:${PC_RACE_DWARF}), часто, люди приходя, строят новое рядом или поверх того, что оставила им прошлая община. Ещё можно сказать, что наиболее общая черта всех людей — отсутствие таковой. Такое разнообразие позволяет человеческой цивилизации расти быстрее прочих рас, выдвигая людей на роль доминирующей расы в большей части сегодняшнего мира. Но также это ведёт и к конфликтам между сообществами людей из-за их культурной и политической разницы. Если бы не эта их склонность к такой внутренней борьбе, люди были бы более многочисленны и преобладали бы в ещё большей степени, чем это есть на данный момент.`,
      source: {
        id: SOURCE_SCAG,
        page: 111,
      },
    },
    ...humanLanguagesDescriptionList,
    {
      header: `Божества людей`,
      text: `Плодовитость и разнообразие человеческой расы на Фаэруне лучше, чем где-либо отражено в разнообразии богов, коим поклоняются люди. фаэрунский пантеон включает в себя богов каждой прослойки людей, и сферы части этих божеств пересекаются и дополняют друг друга — всё так, как люди любят.

Вдоль Побережья Мечей, большинство общин людей имеют храмы и часовни, за которыми ухаживают жрецы различных фаэрунских богов. В некоторых таких сообществах, последователи богов, которых почитают правители и наделённые силой, и играют большую социальную роль, чем последователи богов не столь почитаемых. В крайних случаях, поклонение, которое считается еретическим и опасным признано вне закона — например, в регионах, где властью и силой наделены поклонники [Шар](GOD:${GOD_SHAR}), поклонение её доброму двойнику и вечной противнице [Селунэ](GOD:${GOD_SELUNE}) может быть противозаконным.`,
      source: {
        id: SOURCE_SCAG,
        page: 113,
      },
    },
  ],
}
