const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  VOLUME_UNIT_PINT,
} = require('./../../../volumeUnitList')
const {
  POISON_TYPE_CONTACT,
} = require('./../../../poisonTypeList')
const {
  PARAM_CON,
} = require('./../../../paramList')
const {
  SOURCE_DMG,
  SOURCE_PHB,
  SOURCE_VGTM,
} = require('./../../../sourceList')
const {
  CREATURE_OX,
} = require('./../../../creatureIdList')
const {
  DAMAGE_FIRE,
} = require('./../../../damageTypeList')
const {
  CAT_ANIMALS,
  CAT_EJECTIVES,
  CAT_MAGIC_FOCUS,
  CAT_POISONS,
} = require('./../../../gearCategoryList')
const {
  GEAR_FLASK,
  GEAR_OIL,
  GEAR_OIL_OF_TAGGIT,
  GEAR_ORB,
  GEAR_OX,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_ORB,
    genderId: GENDER_FEMALE,
    name: 'Сфера',
    nameByCase: {
      nominative: 'Сфера',
      accusative: 'Сферу',
      instrumental: 'Сферой',
    },
    nameAlt: 'Шар',
    nameEn: 'Orb',
    description: 'Это особый предмет, созданный для проведения тайных заклинаний. Волшебники, колдуны и чародеи могут использовать эти предметы в качестве фокусировки для заклинаний.',
    cost: 2000,
    weight: 3,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_MAGIC_FOCUS,
  },
  {
    id: GEAR_OIL,
    genderId: GENDER_MIDDLE,
    name: 'Масло',
    nameByCase: {
      nominative: 'Масло',
      accusative: 'Масло',
      instrumental: 'Маслом',
    },
    nameEn: 'Oil',
    description: `Обычно масло продаётся в глиняных [флягах](GEAR:${GEAR_FLASK}).

Вы можете действием облить маслом из фляги существо, находящееся в пределах 5 фт, или кинуть её на 20 фт, ломая при ударе. Совершите дальнобойную атаку по целевому существу или предмету, считая масло импровизированным оружием. При попадании цель покрывается маслом. Если цель получает урон огнём, пока масло не высохло (1 минута), она получает дополнительный урон огнём 5 от горящего масла.

Вы можете также вылить фляжку масла на землю, покрыв площадь 5 × 5 фт, при условии, что пол ровный. Если теперь масло поджечь, оно горит 2 раунда и причиняет урон огнём 5 всем существам, входящим в эту область или оканчивающим в ней ход. Существо может получить этот урон только один раз за ход.`,
    cost: 10,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 1,
    },
    damage: {
      diceBonus: 5,
    },
    range: {
      normal: 20,
      max: 20,
    },
    damageType: DAMAGE_FIRE,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EJECTIVES,
  },
  {
    id: GEAR_OX,
    genderId: GENDER_MALE,
    creatureId: CREATURE_OX,
    name: 'Вол',
    nameByCase: {
      nominative: 'вол',
      genitive: 'вола',
      accusative: 'вол',
      instrumental: 'волом',
    },
    nameEn: 'Ox',
    description: `Вол, в основном, используется для тягловой работы, а не для мяса или молока.`,
    cost: 1500,
    source: [
      {
        id: SOURCE_PHB,
        page: 157,
      },
      {
        id: SOURCE_VGTM,
        page: 210,
      },
    ],
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_OIL_OF_TAGGIT,
    genderId: GENDER_MIDDLE,
    name: 'Яд, масло таггита',
    nameByCase: {
      nominative: 'масло таггита',
      genitive: 'масла таггита',
      accusative: 'масло таггита',
      instrumental: 'маслом таггита',
    },
    nameEn: 'Oil of Taggit',
    description: `Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 13, иначе оно станет отравленным на 24 часа. Существо также лишено сознания, пока отравлено. Существо приходит в сознание, если получает урон.`,
    saveThrow: {
      type: PARAM_CON,
      dc: 13,
    },
    cost: 40000,
    poisonType: POISON_TYPE_CONTACT,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
]

module.exports = gearRawList
