const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {SOURCE_DND3_FRCS} = require('./../../sourceList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {PLACE_HORDELANDS} = require('./../../placeIdList')
const {
  LANG_ROUSHOUM,
  LANG_TUIGAN,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_TUIGAN,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Tuigan',
  name: {
    nominative: 'Туйганский',
    genitive: 'Туйганского',
    instrumental: 'Туйганским',
    prepositional: 'Туйганском',
  },
  typicalSpeakers: 'Туйганы',
  spokenPlaceList: PLACE_HORDELANDS,
  description: {
    text: `Потомок [Рушума](LANG:${LANG_ROUSHOUM}).`,
    source: {
      id: SOURCE_DND3_FRCS,
      page: 86,
    },
  },
  isReady: true,
  isRealLang: true,
}
