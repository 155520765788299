const {
  SOURCE_MM,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {GOD_DENDAR} = require('./../../godIdList')

module.exports = [
  {
    header: 'Дендар, Ночная Змея',
    text: `Последователи [Дендар](GOD:${GOD_DENDAR}) говорят, что однажды она вырастет настолько большой, питаясь страхами и кошмарами мира, что сожрёт его целиком.

Юань-ти, которые служат [Дендар](GOD:${GOD_DENDAR}), терроризируют других существ всеми возможными способами, усиливая и подпитывая страхи гуманоидов, чтобы накормить Ночную Змею.`,
    source: {
      id: SOURCE_MM,
      page: 315,
    },
  },
  {
    header: 'Дендар Ночная Змея',
    text: `[Дендар Ночная Змея, Пожирательница Миров](GOD:${GOD_DENDAR}), по слухам, отродье первого кошмара, пожиратель скверных видений и предвестник конца света. Её колдунам часто снится шипение Дендар и сухой шелест её чешуи, когда они только осознают свой потенциал.`,
    source: {
      id: SOURCE_SCAG,
      page: 139,
    },
  },
]
