module.exports = [
  ...require('./itemGroups'),
  require('./list/alchemical_compendium'),
  require('./list/astral_shard'),
  require('./list/astromancy_archive'),
  require('./list/atlas_of_endless_horizons'),
  require('./list/baba_yaga_s_mortar_and_pestle'),
  require('./list/bell_branch'),
  require('./list/blood_fury_tattoo'),
  require('./list/cauldron_of_rebirth'),
  require('./list/coiling_grasp_tattoo'),
  require('./list/crook_of_rao'),
  require('./list/crystalline_chronicle'),
]
