const {
  SPELL_ALTER_SELF,
  SPELL_CONFUSION,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_DREAM,
  SPELL_MIRAGE_ARCANE,
  SPELL_MISLEAD,
  SPELL_MODIFY_MEMORY,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PROGRAMMED_ILLUSION,
  SPELL_PROJECT_IMAGE,
  SPELL_SEEMING,
  SPELL_SIMULACRUM,
} = require('./../../../../spellIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_DEMON_LORD,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_23} = require('./../../../../crList')
const {CREATURE_FRAZ_URBLUU} = require('./../../../../creatureIdList')
const {demonDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Фраз-Урб’луу',
  nameEn: 'Fraz-Urb’luu',
  nameAlt: [
    'Принц Обмана',
    'Владыка Иллюзий',
  ],
  id: CREATURE_FRAZ_URBLUU,
  hasSpoilers: true,
  description: [
    `Все демоны — лжецы, но Фраз-Урб’луу — это Принц Обмана и Владыка Иллюзий. Он использует каждую уловку, каждую крупинку демонической хитрости, чтобы заставить своих врагов — неважно исчадий или смертных — делать то, что он хочет. Фраз-Урб’луу может создавать земли грёз и сводящие с ума фантазии, способные обмануть самых проницательных противников.

Когда-то заключенный на сотни лет под замком Грэйхок в мире Оэрт, Фраз-Урб’луу постепенно восстановил былую силу в Бездне. Он ищет (и приказывает своим слугам делать то же самое) фрагменты легендарного посоха власти, отнятого у него теми, кто его пленил.

Истинная форма Принца обмана похожа на горгулью 12 футов (3,6 метра) высотой с удлинённой, мускулистой шеей и улыбающимся лицом, обрамлённым острыми ушами и редкими темными волосами. Крылья, подобные крыльям летучей мыши, сложены на его могучих плечах. Однако он может принимать другие формы, как ужасающие, так и прекрасные. Зачастую лорд демонов так сильно вживается в роль, которую играет, что теряет самого себя на какое-то время.

Многие культисты Фраз-Урб’луу даже не знают, что они служат Принцу Обмана, веря, что их хозяин является добродетельным существом, исполняющим желания, каким-нибудь забытым богом, небожителем или даже другим исчадием. Это не все маски, которые носит Фраз Урб’луу. Он особенно обожает помогать охотникам на демонов, натравливая их на своих демонических соперников, заставляя их совершать всё большие и большие зверства во имя цели, которую они сами считают священной. В конце концов Фраз-Урб’луу открывает свою истинную природу и поглощает души жертв.`,
    {
      header: 'Логово Фраз-Урб’луу',
      text: `Логово Фраз-Урб’луу лежит в слое Бездны, известном как Сердце Пустоты, — безликой равнине, покрытой белой пылью, с несколькими зданиями на ней. Его логово — это город Зораг-мелок, представляющий собой круговую крепость, окруженную адамантиновыми стенами, испещрёнными лезвиями и крюками. Спиральные башни, вздымающиеся над закрученными куполами и внушительными амфитеатрами, — всего лишь пример невероятной архитектуры города.

Опасность Фраз-Урб’луу составляет 24 (62 000 опыта), когда он находится в своем логове.

# Действия логова

По очереди инициативы 20 (проигрыш при ничье), Фраз-Урб’луу может совершить действие в логове, чтобы создать один из следующих магических эффектов; он не может использовать один и тот же эффект два раунда подряд:

* Фраз-Урб’луу заставляет 5 дверей в логове стать стенами, и при этом равное количество дверей появляется в стенах, где раньше их не было. 
* Фраз-Урб’луу выбирает одного гуманоида в логове и сразу же создает подобие этого существа (как если бы оно было создано заклинанием [Подобие](SPELL:${SPELL_SIMULACRUM})). Это подобие подчиняется командам Фраз-Урб’луу и уничтожается на следующей очереди инициативы 20. 
* Фраз-Урб’луу создаёт волны мучений. Каждое существо, которое он может видеть в его логове должно пройти испытание Мудрости СЛ 23 или получит психический урон 33 (6к10).

# Местные эффекты

Регион, в котором расположено логово Фраз-Урб’луу искажается его магией, создавая один или несколько следующих эффектов:

* Разумные существа в пределах 1 мили от логова часто испытывают галлюцинации, видя давно умерших друзей и товарищей, которые исчезают через мгновение после того, как их заметят. 
* Дороги и пути в пределах 6 миль от логова искривляются и петляют, создавая дополнительные осложнения для ориентирования. 
* Если гуманоид проводит хотя бы 1 час в 1 миле от логова, это существо должно пройти испытание Мудрости СЛ 23 или повредиться умом. Форма сумасшествия определяется по таблице Безумия Фраз-Урб’луу. Существо, преуспевшее в этом испытании, не подвержено этому региональному эффекту в течение ближайших 24 часов.

Если Фраз-Урб’луу погибает, эти эффекты рассеиваются за 1к10 дней.`,
      source: {
        id: SOURCE_MTOF,
        page: 155,
      },
    },
    {
      header: 'Безумие Фраз-Урб’луу',
      text: `Если существо сходит с ума в логове Фраз-Урб’луу или в поле зрения владыки демонов, совершите бросок по таблице Безумие Фраз-Урб’луу, чтобы определить природу безумия, которое становится слабостью персонажа и действует, пока не будет излечено.

| к100  | Слабость (длится пока не будет вылечена) |
|-------|------------------------------------------|
| 1–20  | _«Я никому не позволю узнать правду о моих действиях или намерениях, даже если это было бы выгодно мне»_ |
| 21–40 | _«Я страдаю от периодических галлюцинаций и приступов кататонии»_ |
| 41–60 | _«Мои мысли блуждают среди фантазий, не имеющих ничего общего с реальностью. Когда я спускаюсь с небес на землю, мне требуется много времени для того чтобы осознать, что я лишь грезил наяву»_ |
| 61–80 | _«Я продолжаю убеждать себя в истинности чего-либо, даже когда неопровержимые аргументы говорят об обратном»_ |
| 81–00 | _«Мое восприятие действительности не совпадает ни с чьим другим. Оно пробуждает во мне тягу к насилию, не имеющую смысла для кого-либо кроме меня»_ |
`,
      source: {
        id: SOURCE_MTOF,
        page: 156,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_DEMON_LORD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 155,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 27,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 29,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 25,
    [PARAM_INT]: 26,
    [PARAM_WIT]: 24,
    [PARAM_CHA]: 26,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_23,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Необнаружимый',
      description: `Фраз-Урб’луу не может стать целью магии прорицания, обнаружен посредством магических сенсоров или обнаружен способностями, которые определяют демонов или исчадий.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_ALTER_SELF,
            comment: 'может стать Среднего размера',
          },
          SPELL_DETECT_MAGIC,
          SPELL_DISPEL_MAGIC,
          SPELL_PHANTASMAL_FORCE,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_CONFUSION,
          SPELL_DREAM,
          SPELL_MISLEAD,
          SPELL_PROGRAMMED_ILLUSION,
          SPELL_SEEMING,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_MIRAGE_ARCANE,
          SPELL_MODIFY_MEMORY,
          SPELL_PROJECT_IMAGE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Фраз-Урб’луу проводит три атаки: одну _Укусом_ и две _Кулаками_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 9,
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Хвост',
      description: `Если целью было существо Большого или меньшего размера, оно также становится схваченным (Сл побега 24). Схваченное существо обездвижено. Фраз-Урб’луу может одновременно схватить только одно существо хвостом.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Воображаемый убийца ',
      cost: 2,
      description: `Фраз-Урб’луу может сотворить заклинание [Воображаемый убийца](SPELL:${SPELL_PHANTASMAL_KILLER}), не требующее поддержания концентрации.`,
    },
  ],
}
