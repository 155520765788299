const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {CAT_SIMPLE_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_SICKLE} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {WEAPON_LIGHT} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_SICKLE,
  genderId: GENDER_MALE,
  name: 'Серп',
  nameByCase: {
    nominative: 'серп',
    genitive: 'серпа',
    accusative: 'серп',
    instrumental: 'серпом',
  },
  nameEn: 'Sickle',
  description: `Маленькая одноручная версия косы. Изогнутое лезвие делает его отличным выбором для мастеров боевых искусств, которые бьют под неожиданными углами.`,
  damage: {
    diceType: 4,
    diceCount: 1,
  },
  damageType: DAMAGE_SLASHING,
  cost: 100,
  weight: 2,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 117,
    },
  ],
  weaponPropList: [
    WEAPON_LIGHT,
  ],
  category: CAT_SIMPLE_MELEE_WEAPON,
}
