const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_CRANIUM_RAT,
  CREATURE_ELDER_BRAIN,
  CREATURE_MIND_FLAYER,
  CREATURE_RAT,
  CREATURE_SWARM_OF_CRANIUM_RATS,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Черепные крысы',
    text: `[Свежеватели разума](CREATURE:${CREATURE_MIND_FLAYER}) создают [черепных крыс](CREATURE:${CREATURE_CRANIUM_RAT}), облучая их псионической энергией.`,
    source: {
      id: SOURCE_VGTM,
      page: 198,
    },
  },
  {
    header: 'Злые стаи',
    text: `Черепные крысы не умнее [обычных крыс](CREATURE:${CREATURE_RAT}) и ведут себя соответственно. Однако, если [черепные крысы](CREATURE:${CREATURE_CRANIUM_RAT}) собираются вместе в [стаю](CREATURE:${CREATURE_SWARM_OF_CRANIUM_RATS}), их разумы сливаются воедино в интеллект, обладающий суммарной памятью всех участников. В результате крысы становятся умнее, и их повышенный интеллект сохраняется, пока сохраняется стая. В стае также пробуждаются латентные психические способности, подаренные им их создателями [свежевателями разума](CREATURE:${CREATURE_MIND_FLAYER}), что даёт крысам псионические силы, похожие на заклинания.

Отделённая от стаи черепная крыса становится [обычной черепной крысой](CREATURE:${CREATURE_CRANIUM_RAT}) с Интеллектом 15. Она теряет единицу Интеллекта каждый день, который она проводит в отрыве от стаи. Её Интеллект не может снизиться ниже 4, и она снова приобретает Интеллект 15, если присоединится к старой или новой стае.`,
    source: {
      id: SOURCE_VGTM,
      page: 198,
    },
  },
  {
    header: 'Телепатические грызуны',
    text: `Одиночная низкоинтеллектуальная [черепная крыса](CREATURE:${CREATURE_CRANIUM_RAT}) использует свою природную телепатию, чтобы транслировать голод, страх и прочие базовые эмоции. [Стая черепных крыс](CREATURE:${CREATURE_SWARM_OF_CRANIUM_RATS}) телепатически «разговаривает» как одно существо, о себе говоря используя обобщающие слова типа _«мы»_ или _«нас»_.`,
    source: {
      id: SOURCE_VGTM,
      page: 198,
    },
  },
  {
    header: 'Шпионы старшего мозга',
    text: `Колонии [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}) используют черепных крыс как шпионов. Крысы пробираются в поселения на поверхности и служат глазами и ушами [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}), передавая свои мысли, когда они собираются в стаю и находятся в пределах досягаемости телепатии [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}).

Иногда черепные крысы распространяются за пределы зоны влияния [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}). Чем начинают заниматься такие черепные крысы старшему мозгу становится безразлично, так как [иллитиды](CREATURE:${CREATURE_MIND_FLAYER}) могут при надобности наделать ещё крыс.`,
    source: {
      id: SOURCE_VGTM,
      page: 198,
    },
  },
]
