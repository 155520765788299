module.exports = [
  require('./languages/aarakocra'),
  require('./languages/abyssal'),
  require('./languages/aglarondan'),
  require('./languages/all_known_at_life'),
  require('./languages/all_known_at_previous_form'),
  require('./languages/alzhedo'),
  require('./languages/andt'),
  require('./languages/any'),
  require('./languages/aquan'),
  require('./languages/aragrakh'),
  require('./languages/auran'),
  require('./languages/blink_dog'),
  require('./languages/bothii'),
  require('./languages/bullywug'),
  require('./languages/can_talk_with_spiders'),
  require('./languages/celestial'),
  require('./languages/chardic'),
  require('./languages/chessentan'),
  require('./languages/chondathan'),
  require('./languages/chult'),
  require('./languages/common'),
  require('./languages/cosh'),
  require('./languages/d_tarig'),
  require('./languages/daelic'),
  require('./languages/damaran'),
  require('./languages/dambrathan'),
  require('./languages/deep_crow'),
  require('./languages/deep_speech'),
  require('./languages/draconic'),
  require('./languages/druidic'),
  require('./languages/durpari'),
  require('./languages/dwarvish'),
  require('./languages/easting'),
  require('./languages/elven'),
  require('./languages/erakic'),
  require('./languages/giant'),
  require('./languages/giant_eagle'),
  require('./languages/giant_elk'),
  require('./languages/giant_owl'),
  require('./languages/gith'),
  require('./languages/gnoll'),
  require('./languages/gnomish'),
  require('./languages/goblin'),
  require('./languages/grell'),
  require('./languages/grung'),
  require('./languages/guran'),
  require('./languages/halfling'),
  require('./languages/halruaan'),
  require('./languages/hook_horror'),
  require('./languages/hulgorkyn'),
  require('./languages/ice_toad'),
  require('./languages/ignan'),
  require('./languages/illuskan'),
  require('./languages/infernal'),
  require('./languages/its_creator'),
  require('./languages/its_summoner'),
  require('./languages/ixitxachitl'),
  require('./languages/kruthik'),
  require('./languages/lantanese'),
  require('./languages/loross'),
  require('./languages/loxodon'),
  require('./languages/main_language_of_its_master'),
  require('./languages/midani'),
  require('./languages/modrone'),
  require('./languages/moonshae'),
  require('./languages/mulhorandi'),
  require('./languages/naric'),
  require('./languages/netherese'),
  require('./languages/nexalan'),
  require('./languages/old_omuan'),
  require('./languages/one_of_its_creator'),
  require('./languages/orc'),
  require('./languages/otyugh'),
  require('./languages/primordial'),
  require('./languages/rashemi'),
  require('./languages/reghedjic'),
  require('./languages/rengardt'),
  require('./languages/roushoum'),
  require('./languages/ruathen'),
  require('./languages/ruathlek'),
  require('./languages/sahuagin'),
  require('./languages/seldruin'),
  require('./languages/serusan'),
  require('./languages/shaaran'),
  require('./languages/shou'),
  require('./languages/slaad'),
  require('./languages/sphinx'),
  require('./languages/supernal'),
  require('./languages/sylvan'),
  require('./languages/tashalan'),
  require('./languages/telepathy'),
  require('./languages/terran'),
  require('./languages/thayan'),
  require('./languages/thieves_cant'),
  require('./languages/thorass'),
  require('./languages/thri_kreen'),
  require('./languages/tlincalli'),
  require('./languages/troglodyte'),
  require('./languages/true_illuskan'),
  require('./languages/truskan'),
  require('./languages/tuigan'),
  require('./languages/turmic'),
  require('./languages/uloushinn'),
  require('./languages/uluik'),
  require('./languages/ulutiun'),
  require('./languages/umber_hulk'),
  require('./languages/undercommon'),
  require('./languages/untheric'),
  require('./languages/vegepygmy'),
  require('./languages/waelan'),
  require('./languages/winter_wolf'),
  require('./languages/worgen'),
  require('./languages/yeti'),
  require('./languages/yikaria'),
]
