const {
  CREATURE_BONE_DEVIL,
  CREATURE_SPINED_DEVIL,
} = require('./../../creatureIdList')

module.exports = {
  extendPropCollection: {
    actionList: [
      {
        name: 'Призыв дьявола',
        description: `Дьявол выбирает, кого призвать, и пытается это сделать с шансом 30%.
 
 * 2к6 [игольчатых дьяволов](CREATURE:${CREATURE_SPINED_DEVIL})
 * одного [костяного дьявола](CREATURE:${CREATURE_BONE_DEVIL})

Призванные дьяволы появляются в свободном пространстве в пределах 60 футов от призывателя, и действуют как его союзники, не имеющие способности призывать дьяволов. Они помогают в течение 1 минуты или пока призыватель не умрёт или действием их не отпустит.`,
        limit: {
          count: 1,
          period: 'день',
        },
      },
    ],
  },
}
