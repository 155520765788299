const {
  CREATURE_CAMBION,
  CREATURE_GRAZZT,
  CREATURE_INCUBUS,
  CREATURE_NIGHT_HAG,
  CREATURE_RAKSHASA,
  CREATURE_SUCCUBUS,
} = require('./../../../../creatureIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_4} = require('./../../../../crList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {GOD_ASMODEUS} = require('./../../../../godIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Суккуб`,
  nameEn: 'Succubus',
  id: CREATURE_SUCCUBUS,
  description: [
    `[Суккубы](CREATURE:${CREATURE_SUCCUBUS}) и [инкубы](CREATURE:${CREATURE_INCUBUS}) населяют все Нижние Планы. Похотливых крылатых исчадий можно найти в подчинении у демонов, дьяволов, [ночных карг](CREATURE:${CREATURE_NIGHT_HAG}), [ракшасов](CREATURE:${CREATURE_RAKSHASA}) и юголотов. [Асмодей, правитель Девяти Преисподних](GOD:${GOD_ASMODEUS}), использует этих исчадий для искушения смертных, чтобы они совершали злые поступки. Демонический повелитель [Граз’зт](CREATURE:${CREATURE_GRAZZT}) держит суккубов и инкубов в качестве советников и любовников.

Несмотря на то, что в легендах о них говорят по отдельности, каждый [суккуб](CREATURE:${CREATURE_SUCCUBUS}) может стать [инкубом](CREATURE:${CREATURE_INCUBUS}) и наоборот. Однако большинство этих существ предпочитает один конкретный облик. Смертные редко сталкиваются с [суккубами](CREATURE:${CREATURE_SUCCUBUS}) и [инкубами](CREATURE:${CREATURE_INCUBUS}) в их истинных обликах, так как эти существа ведут тайную жизнь, и начинают совращать тайком и незаметно.`,
    {
      header: 'Проклятые, но прекрасные',
      text: `Суккубы и инкубы впервые появляются в эфирном облике, имея возможность проходить сквозь стены, словно призраки, и шептать на ухо спящим смертным о запретных удовольствиях. Спящие жертвы с лёгкостью раскрывают свои тёмные желания, они легко внушаемы и поддаются пагубному воздействию. По мере того, как мысли жертвы наполняются пагубными желаниями, она становится легко внушаемой в повседневной жизни.

Входя в мир смертных, эти исчадия принимают соблазнительную форму, чтобы напрямую влиять на других существ. Появившись в облике того, кого цель видела в своих снах, суккубы и инкубы соблазняют или начинают общаться с жертвой, побуждая ту совершить злые поступки.

Такой смертный не завещает свою душу клятвой или договором. Вместо этого суккуб или инкуб полностью развращает цель, вынуждая совершить три преступления — в мыслях, на словах и делом. После этого душа жертвы принадлежит исчадию. Чем больше у жертвы доброты, тем дольше она сопротивляется воздействию. Однако совершение преступлений на таких людях сказывается сильнее всего. Окончательно развратив жертву, суккуб или инкуб убивает её, и измученная душа отправляется в Нижние Планы.

Суккубы и инкубы используют магическое очарование только в случае необходимости, обычно в качестве самозащиты. Очарованное существо не отвечает за свои действия, поэтому выполнение приказов не принесёт того, чего и добиваются все суккубы и инкубы — души существа.`,
      source: {
        id: SOURCE_MM,
        page: 275,
      },
    },
    {
      header: 'Смертельный поцелуй',
      text: `Поцелуй суккуба или инкуба является отголоском пустоты, в которую твари пытаются затянуть душу смертного. Но этот поцелуй не приносит никакой выгоды для исчадий. Жертва испытает сильную боль и пустоту, воплощением которой и являются эти исчадия. Этот поцелуй предваряет атаку, это своеобразный подарок на прощание.`,
      source: {
        id: SOURCE_MM,
        page: 275,
      },
    },
    {
      header: 'Демонический отпрыск',
      text: `Суккубы и инкубы могут спариваться друг с другом, чтобы производить существ своего рода. Гораздо реже они спариваются с гуманоидами. От такого нечестивого союза рождаются [камбионы](CREATURE:${CREATURE_CAMBION}), такие же тёмные личности, как и сущности, создавшие их.`,
      source: {
        id: SOURCE_MM,
        page: 275,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 275,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 13,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 20,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_INSIGHT]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
    [SKILL_PERSUASION]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ABYSSAL,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_4,
  featureList: [
    {
      name: `Телепатическая связь`,
      description: `★СУЩЕСТВО★ игнорирует ограничения дистанции телепатии, когда общается с очарованным существом. Более того, они не обязаны находиться на одном плане существования.`,
    },
    {
      name: `Перевёртыш`,
      description: `★СУЩЕСТВО★ может действием превратиться в гуманоида Среднего или Маленького размера, или принять свой истинный облик. Будучи ★лишённым★ крыльев, ★СУЩЕСТВО★ теряет скорость полёта. За исключением размера и скорости, во всех обликах ★его★ характеристики остаются теми же самыми. Всё несомое и носимое ★им★ снаряжение не превращается. ★Он★ принимает свой истинный облик, если умирает.`,
    },
  ],
  actionList: [
    {
      name: `Коготь`,
      comment: `только в облике исчадия`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: `Очарование`,
      description: `Один гуманоид, которого ★СУЩЕСТВО★ видит в пределах 30 футов, должен пройти испытание Мудрости СЛ 15, иначе ★СУЩЕСТВО★ магическим образом очаровывает его на 1 день. Очарованная цель выполняет ★его★ устные или телепатические команды. Если цель испытает вред или получит самоубийственную команду, она может повторить испытание, оканчивая эффект при успехе. Если цель успешно спасается от эффекта или эффект на ней заканчивается, цель получает иммунитет к _Очарованию_ ★этого★ ★СУЩЕСТВО★ на следующие 24 часа. ★СУЩЕСТВО★ может иметь только одну очарованную цель одновременно. Если ★СУЩЕСТВО★ очарует новое существо, эффект на старом окончится.`,
    },
    {
      name: `Истощающий поцелуй`,
      description: `★СУЩЕСТВО★ целует очарованное ★им★ или просто согласное существо. Цель должна пройти испытание Телосложения СЛ 15 от этой магии, получая урон психической энергией 32 (5к10+5) при провале, или половину этого урона при успехе. Максимум хитов цели уменьшается на количество, равное полученному урону. Это уменьшение длится до тех пор, пока цель не окончит длинный отдых. Цель умирает, если этот эффект уменьшает максимум её хитов до 0.`,
    },
    {
      name: `Эфирность`,
      description: `★СУЩЕСТВО★ магическим образом переходит на Эфирный План с Материального плана, или наоборот.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
