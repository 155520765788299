const {PC_SUBCLASS_FIGHTER_ARCANE_ARCHER} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  GEAR_SHORTBOW,
  GEAR_LONGBOW,
} = require('./../../../gearIdList')
const {
  SPELL_DRUIDCRAFT,
  SPELL_PRESTIDIGITATION,
} = require('./../../../spellIdList')
const {
  FEATURE_ARCANE_ARCHER_LORE,
  FEATURE_ARCANE_SHOT,
  FEATURE_CURVING_SHOT,
  FEATURE_EVER_READY_SHOT,
  FEATURE_MAGIC_ARROW,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ARCANE_ARCHER_LORE,
    name: `Знания Мистического лучника`,
    nameEn: `Arcane Archer Lore`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER,
    text: `Вы учите магическую теорию или какие-то секреты природы — типичные для практиков этой военной эльфийской традиции.

Вы выбираете владение навыком либо Магия либо Природа, и учите заговор [Фокусы](SPELL:${SPELL_PRESTIDIGITATION}) или [Искусство друидов](SPELL:${SPELL_DRUIDCRAFT}).`,
    spellIdList: [
      SPELL_DRUIDCRAFT,
      SPELL_PRESTIDIGITATION,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 21,
    },
  },
  {
    id: FEATURE_ARCANE_SHOT,
    name: `Магический выстрел`,
    nameEn: `Arcane Shot`,
    lvl: [3, 7, 10, 15, 18],
    pcSubClassId: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER,
    text: `Вы учитесь бросать в бой особые магические эффекты вместе с некоторыми своими выстрелами. Когда Вы получаете это умение, Вы учите два варианта _Магического выстрела_ по своему выбору.

Раз в ход, когда Вы выпускаете стрелу из [короткого](GEAR:${GEAR_SHORTBOW}) или [длинного лука](GEAR:${GEAR_LONGBOW}) частью действия Атака, Вы можете применить один из своих вариантов _Магического выстрела_ к этой стреле. Вы решаете, применять ли эту способность, после успешного попадания стрелой в существо, кроме случаев, когда выбранный вариант не требует броска атаки. У Вас есть два использования этой способности, и Вы восстанавливаете все потраченные использования по завершении короткого или длинного отдыха.

Вы выбираете дополнительный вариант _Магического выстрела_, когда достигаете определенных уровней в этом классе: 7, 10, 15, 18. Каждый вариант улучшается, когда Вы становитесь воином 18 уровня.

Всё это магические эффекты, и каждый ассоциируется с одной из школ магии. Если вариант предполагает испытание, то Ваша СЛ _Магического выстрела_ равна 8 + Ваш бонус мастерства + модификатор Интеллекта.`,
    listAllArcaneShots: true,
    source: {
      id: SOURCE_XGTE,
      page: 21,
    },
  },
  {
    id: FEATURE_MAGIC_ARROW,
    name: `Зачарованная стрела`,
    nameEn: `Magic Arrow`,
    lvl: 7,
    pcSubClassId: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER,
    text: `Вы получаете способность заряжать стрелы магией. Когда Вы стреляете немагической стрелой из [короткого](GEAR:${GEAR_SHORTBOW}) или [длинного лука](GEAR:${GEAR_LONGBOW}), Вы можете сделать её магической для преодоления сопротивления и иммунитета к немагическим атакам и урону. Магия угасает в стреле сразу же после попадания или промаха по цели.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
  {
    id: FEATURE_CURVING_SHOT,
    name: `Странствующая стрела`,
    nameEn: `Curving Shot`,
    lvl: 7,
    pcSubClassId: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER,
    text: `Вы учитесь направлять сбившуюся стрелу к новой цели. Когда Вы делаете бросок атаки [Магической стрелой](FEATURE:${FEATURE_ARCANE_SHOT}) и промахиваетесь, Вы можете бонусным действием перебросить бросок атаки против другой цели, находящейся не далее чем в 60 футах от изначальной цели.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
  {
    id: FEATURE_EVER_READY_SHOT,
    name: `Выстрел всегда наготове`,
    nameEn: `Ever-Ready Shot`,
    lvl: 15,
    pcSubClassId: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER,
    text: `Ваше магическое мастерство всегда наготове вначале битвы. Если Вы делаете бросок инициативы, и у Вас не осталось использований [Магического выстрела](FEATURE:${FEATURE_ARCANE_SHOT}), Вы восстанавливаете одно использование.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
]
