const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {GEAR_SPELLBOOK} = require('./../../../../../gearIdList')
const {PC_CLASS_WIZARD} = require('./../../../../../pcClassIdList')
const {
  FEATURE_ARCANE_RECOVERY,
  FEATURE_SPELLBOOK,
} = require('./../../../../../featureIdList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    bonus,
    id,
    rarity,
  }
) => ({
  id,
  name: `Волшебный гримуар +${bonus}`,
  nameEn: `Arcane Grimoire +${bonus}`,
  rarity,
  gearType: GEAR_SPELLBOOK,
  attunement: true,
  attunementComment: `волшебником`,
  type: MGC_TYPE_WONDROUS_ITEM,
  description: `Держа эту книгу в кожаном переплёте, Вы можете использовать её как заклинательную фокусировку для Ваших заклинаний [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}) и получаете бонус +${bonus} к броскам атаки заклинаниями и СЛ испытаний Ваших заклинаний Волшебника.

_Волшебный гримуар_ может использоваться как [книга заклинаний](FEATURE:${FEATURE_SPELLBOOK}).

Когда Вы используете свою способность [Магическое восстановление](FEATURE:${FEATURE_ARCANE_RECOVERY}), Вы увеличиваете на 1 суммарный круг восстанавливаемых ячеек.`,
  genderId: GENDER_MALE,
  siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 172,
  },
})
