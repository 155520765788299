const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_BEHOLDER_KEEN} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_BEHOLDER_KEEN,
  nameEn: 'Beholder Keen',
  genderId: GENDER_MALE,
  notRealRace: true,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'злобоглаз',
      genitive: 'злобоглаза',
      dative: 'злобоглазу',
      accusative: 'злобоглаза',
      instrumental: 'злобоглазом',
      prepositional: 'злобоглазе',
    },
    plural: {
      nominative: 'злобоглазы',
      genitive: 'злобоглазов',
      dative: 'злобоглазам',
      accusative: 'злобоглазов',
      instrumental: 'злобоглазами',
      prepositional: 'злобоглазах',
    },
  },
}
