const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_FAERIE_FIRE,
} = require('./../../../../spellIdList')
const {
  GEAR_CHAIN_SHIRT,
  GEAR_CROSSBOW_HAND,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  drowDescription,
  drowNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_DROW} = require('./../../../../creatureIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Дроу`,
  nameEn: 'Drow',
  id: CREATURE_DROW,
  description: drowDescription,
  note: drowNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 307,
  },
  armor: {
    ac: 15,
    gearId: GEAR_CHAIN_SHIRT,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
    LANG_ELVEN,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DANCING_LIGHTS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_FAERIE_FIRE,
          SPELL_DARKNESS,
        ],
      },
    ],
  },
  actionList: [
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_CROSSBOW_HAND,
      description: `Цель должна пройти испытание Телосложения СЛ 13, иначе станет отравленной на 1 час. Если испытание провалено на 5 или больше единиц, цель также лишена сознания, пока отравлена таким образом. Цель просыпается, если получает урон или другое существо разбудит её действием.`,
    },
  ],
  genderId: GENDER_MALE,
}
