const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_13,
} = require('./../../../../crList')
const {
  SIZE_HUGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DRAGON_METALLIC,
  CREATURE_TYPE_DRAGON_TRUE,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CG,
} = require('./../../../../aligmentList')
const {
  SPEED_DIG,
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_HISTORY,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  brassDragonDescriptionList,
  dragonsBrassNoteList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DETECT,
  ABILITY_FRIGHTFUL_PRESENCE,
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_TAIL_ATTACK,
  ABILITY_WING_ATTACK,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const dragonBreatheWeaponCollection = require('./../../../dragonBreatheWeaponCollection')
const {
  CREATURE_DRAGON_BRASS_ADULT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Взрослый латунный дракон',
  nameEn: 'Adult Brass Dragon',
  id: CREATURE_DRAGON_BRASS_ADULT,
  description: brassDragonDescriptionList,
  note: dragonsBrassNoteList,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAGON_TRUE,
    CREATURE_TYPE_DRAGON_METALLIC,
  ],
  alignmentId: ALIGNMENT_CG,
  source: {
    id: SOURCE_MM,
    page: 128,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_DIG]: 30,
    [SPEED_FLY]: 80,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 21,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_COMMON,
  ],
  cr: CR_13,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ может использовать _Ужасающее присутствие_. Затем он совершает три атаки: одну _Укусом_, и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      id: ABILITY_FRIGHTFUL_PRESENCE,
      range: 120,
      witSave_DC: 16,
    },
    dragonBreatheWeaponCollection[CREATURE_DRAGON_BRASS_ADULT],
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    ABILITY_DETECT,
    ABILITY_TAIL_ATTACK,
    {
      id: ABILITY_WING_ATTACK,
      cost: 2,
      range: 10,
      dexSave_DC: 19,
      damage: '13 (2к6 + 6)',
    },
  ],
  genderId: GENDER_MALE,
}
