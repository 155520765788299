const {creatureCollection} = require('./../../../../../creatureList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_CONJURE_ELEMENTAL} = require('./../../../../../spellIdList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    creatureId,
    id,
    name,
    nameEn,
    stoneAppearance,
  }
) => {
  const { name: elementalName } = creatureCollection[creatureId]

  return {
    id,
    name,
    nameEn,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Этот ${stoneAppearance} содержит частичку стихийной энергии.

Если Вы действием разбиваете камень, призывается [${elementalName}](CREATURE:${creatureId}), как если бы Вы наложили заклинание [Призыв элементаля](SPELL:${SPELL_CONJURE_ELEMENTAL}), и магия, заключённая в камне, исчезает.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_CONJURE_ELEMENTAL],
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 169,
    },
  }
}
