const {
  SPELL_DETECT_THOUGHTS,
  SPELL_ENCODE_THOUGHTS,
  SPELL_MODIFY_MEMORY,
} = require('./../../../../spellIdList')
const {CAST_SOMATIC} = require('./../../../../castComponentList')
const {MAGIC_ENCHANTMENT} = require('./../../../../magicList')
const {SOURCE_GGTR} = require('./../../../../sourceList')
const {TIME_HOUR} = require('./../../../../timePeriodList')

module.exports = {
  id: SPELL_ENCODE_THOUGHTS,
  name: 'Зашифровать мысли',
  nameEn: 'Encode Thoughts',
  description: `Прикладывая палец к своей голове, Вы вытягиваете воспоминание, идею или сообщение из своего разума и превращаете его в осязаемую прядь мерцающей энергии, называемой мысленитью, которая существует всю длительность заклинания, или пока Вы не сотворите это заклинание снова. Мысленить появляется в свободном пространстве в пределах 5 футов как Крошечный  невесомый полутвёрдый предмет, который можно держать и нести как ленту. В остальном она неподвижна. 

Если Вы сотворяете это заклинание, концентрируясь на заклинании или способности, позволяющей читать или манипулировать мыслями других (таких как [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) или [Изменение памяти](SPELL:${SPELL_MODIFY_MEMORY})), то Вы можете превращать в мысленить те мысли или воспоминания, которые читаете, вместо собственных.

Если Вы сотворяете это заклинание, держа мысленить, Вы можете получить воспоминание, идею или сообщение, которые она содержит. Сотворение этого заклинания на мысленить действует точно так же.`,
  lvl: 0,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: -1,
  componentIdList: [CAST_SOMATIC],
  duration: { timeId: TIME_HOUR, count: 8 },
  source: {
    id: SOURCE_GGTR,
    page: 48,
  },
}
