const {PC_CLASS_FIGHTER} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
} = require('./../../sourceList')
const {
  FIGHTING_STYLE_ARCHERY,
  FIGHTING_STYLE_BLIND_FIGHTING,
  FIGHTING_STYLE_DEFENSE,
  FIGHTING_STYLE_DUELING,
  FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
  FIGHTING_STYLE_INTERCEPTION,
  FIGHTING_STYLE_PROTECTION,
  FIGHTING_STYLE_SUPERIOR_TECHNIQUE,
  FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
  FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
  FIGHTING_STYLE_UNARMED_FIGHTING,
} = require('./../../fightingStyleIdList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_FIGHTER,
  FEATURE_ACTION_SURGE,
  FEATURE_COMBAT_SUPERIORITY,
  FEATURE_EXTRA_ATTACK_FIGHTER,
  FEATURE_FIGHTING_STYLE_FIGHTER,
  FEATURE_INDOMITABLE,
  FEATURE_MARTIAL_ARCHETYPE,
  FEATURE_MARTIAL_VERSATILITY_FIGHTER,
  FEATURE_SECOND_WIND,
} = require('./../../featureIdList')

const {
  abilityScoreImprovementTemplate,
  extraAttackTemplate,
  fightingStyleFeatureTemplate,
} = require('./../featureCommonRawList')

module.exports = [
  {
    ...fightingStyleFeatureTemplate,
    id: FEATURE_FIGHTING_STYLE_FIGHTER,
    lvl: 1,
    fightingStyleIdList: [
      FIGHTING_STYLE_ARCHERY,
      FIGHTING_STYLE_BLIND_FIGHTING,
      FIGHTING_STYLE_DEFENSE,
      FIGHTING_STYLE_DUELING,
      FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
      FIGHTING_STYLE_INTERCEPTION,
      FIGHTING_STYLE_PROTECTION,
      FIGHTING_STYLE_SUPERIOR_TECHNIQUE,
      FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
      FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
      FIGHTING_STYLE_UNARMED_FIGHTING,
    ],
    source: {
      id: SOURCE_PHB,
      page: 73,
    },
  },
  {
    id: FEATURE_SECOND_WIND,
    name: `Второе дыхание`,
    nameEn: `Second Wind`,
    lvl: 1,
    text: `Вы обладаете ограниченным источником выносливости, которым можете воспользоваться, чтобы уберечь себя. В свой ход Вы можете бонусным действием восстановить хиты в размере 1к10 + Ваш уровень воина.

Использовав это умение, Вы должны завершить короткий либо длинный отдых, чтобы получить возможность использовать его снова.`,
    source: {
      id: SOURCE_PHB,
      page: 73,
    },
  },
  {
    id: FEATURE_ACTION_SURGE,
    name: `Всплеск действий`,
    nameEn: `Action Surge`,
    lvl: [2, 17],
    text: `Вы получаете возможность на мгновение преодолеть обычные возможности.

В свой ход Вы можете совершить одно дополнительное действие помимо обычного и бонусного действий. Использовав это умение, Вы должны завершить короткий или длинный отдых, чтобы получить возможность использовать его снова.

Начиная с 17 уровня Вы можете использовать это умение дважды, прежде чем Вам понадобится отдых, но в течение одного хода его всё равно можно использовать лишь один раз.`,
    source: {
      id: SOURCE_PHB,
      page: 73,
    },
  },
  {
    id: FEATURE_MARTIAL_ARCHETYPE,
    name: `Воинский архетип`,
    nameEn: `Martial Archetype`,
    lvl: [3, 7, 10, 15, 18],
    text: `Вы выбираете архетип, отражающий стиль и технику, к которым Вы стремитесь.

Выбранный Вами архетип предоставляет умения на 3, 7, 10, 15 и 18 уровнях. `,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 73,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_FIGHTER,
    lvl: [4, 8, 6, 12, 14, 16, 19],
    source: {
      id: SOURCE_PHB,
      page: 73,
    },
  },
  {
    id: FEATURE_MARTIAL_VERSATILITY_FIGHTER,
    name: `Боевая гибкость`,
    nameEn: `Martial Versatility`,
    lvl: [4, 8, 6, 12, 14, 16, 19],
    text: `Всякий раз, достигая в этом классе нового уровня, на котором Вы получаете способность [Увеличения характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_FIGHTER}), Вы можете выполнить одно из следующего, демонстрируя смещение фокуса Ваших боевых тренировок:

* Замените один известный Вам [боевой стиль](FEATURE:${FEATURE_FIGHTING_STYLE_FIGHTER}) на другой, доступный воинам.
* Если Вы знаете [приёмы Мастера боевых искусств](FEATURE:${FEATURE_COMBAT_SUPERIORITY}), то можете заменить один известный Вам приём на другой.`,
    source: {
      id: SOURCE_TCoE,
      page: 59,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_FIGHTER,
    lvl: [5, 11, 20],
    text: `${extraAttackTemplate.text}

Количество атак увеличивается до трёх на 11 уровне этого класса, и до четырёх на 20 уровне.`,
    source: {
      id: SOURCE_PHB,
      page: 73,
    },
  },
  {
    id: FEATURE_INDOMITABLE,
    name: `Упорный`,
    nameEn: `Indomitable`,
    lvl: [9, 13, 17],
    text: `Вы можете перебросить проваленное испытание, и должны использовать новый результат. После этого Вы можете повторно использовать это умение только после завершения длинного отдыха.

Вы можете использовать это умение дважды между периодами длинного отдыха после достижения 13 уровня, и трижды после достижения 17 уровня.`,
    source: {
      id: SOURCE_PHB,
      page: 73,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_FIGHTER,
  })
)
