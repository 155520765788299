const {
  SPELL_ANIMATE_DEAD,
  SPELL_COMMAND,
  SPELL_CONTAGION,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINATION,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_GUIDING_BOLT,
  SPELL_HARM,
  SPELL_HOLD_PERSON,
  SPELL_INSECT_PLAGUE,
  SPELL_REMOVE_CURSE,
  SPELL_SACRED_FLAME,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SILENCE,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_THAUMATURGY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SKILL_HISTORY,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  mummyDescriptionList,
  mummyNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_MUMMY,
  CREATURE_MUMMY_LORD,
} = require('./../../../../creatureIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_15} = require('./../../../../crList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Лорд-мумия',
  nameEn: 'Mummy Lord',
  id: CREATURE_MUMMY_LORD,
  description: [
    {
      header: `Лорд-мумия`,
      text: `В глубине древних гробниц деспотичные монархи и высшие жрецы тёмных богов лежат в покое без сновидений, в ожидании времени, когда они смогли бы вернуть себе трон и восстановить свои древние империи. Регалии их ужасающего правления до сих пор украшают их обёрнутые льном тела, их гниющие одежды с вышитыми на них злыми символами и бронзовые доспехи с выгравированными гербами династий, павших тысячи лет назад.

С помощью могущественных жрецов ритуал может создать более сильную мумию. Лорд-мумия, которая создаётся таким ритуалом, сохраняет воспоминания и личность прежней жизни, а также наделена сверхъестественной сопротивляемостью. Мёртвые императоры вооружены проклятыми руническими мечами, благодаря которым они и попали в легенды. Высшие колдуны используют запретную магию, которая когда-то контролировала перепуганные народы, а тёмные боги одаривают мёртвых королевских жрецов божественными заклинания.`,
      source: {
        id: SOURCE_MM,
        page: 216,
      },
    },
    {
      header: `Сердце лорд-мумии`,
      text: `В качестве части ритуала, сердце и внутренности удаляются из тела и помещаются в канопы. Эти сосуды обычно вырезаются из известняка или керамики и покрываются гравировкой или расписываются религиозными иероглифами.

Пока иссохшее сердце лорд-мумии остаётся целым, её нельзя окончательно уничтожить. Когда её хиты опускаются до 0, лорд-мумия превращается в пыль и вновь возрождается через 24 часа, поднимаясь из пыли в непосредственной близости от канопа, содержащего её сердце. Лорд-мумию можно уничтожить или прервать возрождение, если сжечь её сердце. По этой причине лорд-мумия обычно держит своё сердце и внутренности в скрытой могиле или убежище.

Сердце лорд-мумии имеет КД 5, 25 хитов и иммунитет ко всем видам урона кроме огня.`,
      source: {
        id: SOURCE_MM,
        page: 216,
      },
    },
    ...mummyDescriptionList,
    {
      header: `Логово лорд-мумии`,
      text: `Лорд-мумия охраняет древний храм или гробницу, защищённую младшей нежитью и оснащённую ловушками. В этом храме скрыт саркофаг, где лорд-мумия хранит свои величайшие сокровища.

Лорд-мумия, встреченная в логове, имеет показатель опасности 16 (15000 опыта).

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) лорд-мумия совершает действие логова, чтобы вызвать один из описанных ниже эффектов. Лорд-мумия не может использовать один и тот же эффект два раунда подряд.

* Все существа-нежить в логове могут точно определять местоположение всех живых существ в пределах 120 футов от себя до значения инициативы «20» следующего раунда.
* Вся нежить в логове с преимуществом проходит испытания от эффектов, изгоняющих нежить, до значения инициативы «20» следующего раунда.
* До значения инициативы «20» следующего раунда все существа, не являющиеся нежитью, пытающиес  наложить в логове лорд-мумии заклинание с уровнем не больше 5, страдают от боли. Существо может выбрать другое действие, но если оно всё же попытается наложить заклинание, оно должно пройти испытание Телосложения СЛ 16. При провале оно получает урон некротической энергией 1к6 за круг заклинания, а заклинание не оказывает никакого эффекта и тратится впустую.

# Местные эффекты

Существа, вошедшие в храм или гробницу лорд-мумии, чувствуют на себе её тёмное влияние:

* Еда мгновенно портится, а вода испаряется, если их занести в логово. Другие немагические напитки тоже портятся — например, вино превращается в уксус.
* Созданные не лорд-мумией заклинания школы Прорицания имеют 25-процентный шанс на ошибку, которую определяет Мастер. Если у заклинания школы Прорицания уже есть шанс потерпеть неудачу или вероятность неправильных результатов при многократном использова
* Существо, которое берёт сокровища из логова, проклято, пока не вернёт сокровища. Проклятая цель проходит все испытания с помехой. Проклятье действует, пока не будет снято с помощью заклинания [снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) или другой магии.

Если лорд-мумия убита, эффекты местности мгновенно пропадают.`,
      source: {
        id: SOURCE_MM,
        page: 216,
      },
    },
  ],
  note: mummyNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 217,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_15,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Восстановление',
      description: `★Уничтоженный★ ★СУЩЕСТВО★ получает новое тело через 24 часа, если ★его★ сердце оставлено нетронутым, восстанавливает все хиты и вновь становится ★активным★. Новое тело появляется в пределах 5 футов от сердца ★СУЩЕСТВО★.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 10,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_ANIMATE_DEAD,
      SPELL_COMMAND,
      SPELL_CONTAGION,
      SPELL_DISPEL_MAGIC,
      SPELL_DIVINATION,
      SPELL_GUARDIAN_OF_FAITH,
      SPELL_GUIDING_BOLT,
      SPELL_HARM,
      SPELL_HOLD_PERSON,
      SPELL_INSECT_PLAGUE,
      SPELL_SACRED_FLAME,
      SPELL_SHIELD_OF_FAITH,
      SPELL_SILENCE,
      SPELL_SPIRITUAL_WEAPON,
      SPELL_THAUMATURGY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ может использовать _Ужасающий взгляд_ и совершить одну атаку _Разлагающим кулаком_.`,
    },
    {
      name: 'Разлагающий кулак',
      description: `Если цель — существо, она должна пройти испытание Телосложения СЛ 16, иначе станет проклятой гнилью мумии. Проклятая цель не может восстанавливать хиты, и её максимум хитов уменьшается на 10 (3к6) за каждые прошедшие 24 часа. Если проклятье уменьшает максимум хитов цели до 0, цель умирает, а её тело становится прахом. Проклятье длится до тех пор, пока не будет снято заклинанием [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) или другой магией.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 3,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 6,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Ужасающий взгляд',
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 60 футов. Если цель видит ★СУЩЕСТВО★, она должна пройти испытание Мудрости СЛ 16 от этой магии, иначе станет испуганной до конца следующего хода мумии. Если цель проваливает испытание на 5 и более единиц, она также становится парализованной на тот же период. Цель, преуспевшая в испытании, получает иммунитет к Ужасающему взгляду всех [мумий](CREATURE:${CREATURE_MUMMY}) и лорд-мумий на следующие 24 часа.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака',
      description: `★СУЩЕСТВО★ совершает одну атаку _Разлагающим кулаком_ или использует _Ужасающий взгляд_.`,
    },
    {
      name: 'Богохульное слово',
      description: `★СУЩЕСТВО★ произносит богохульное слово. Все существа, не являющиеся нежитью, в пределах 10 футов от ★него★, слышащие магические слова, должны пройти испытание Телосложения СЛ 16, иначе станут ошеломлёнными до конца следующего хода ★СУЩЕСТВО★.`,
      cost: 2,
    },
    {
      name: 'Канал отрицательной энергии',
      description: `★СУЩЕСТВО★ магическим образом выпускает отрицательную энергию. Существа в пределах 60 футов от ★него★, включая тех, что стоят за препятствиями и углами, до конца следующего хода лорд-мумии не могут восстанавливать хиты.`,
      cost: 2,
    },
    {
      name: 'Ослепляющая пыль',
      description: `Вокруг ★СУЩЕСТВО★ магическим образом начинает вращаться ослепляющая пыль и песок. Все существа в пределах 5 футов от ★него★ должны пройти испытание Телосложения СЛ 16, иначе станут ослеплёнными до конца своего следующего хода.`,
    },
    {
      name: 'Песчаный вихрь',
      description: `★СУЩЕСТВО★ магическим образом превращается в песчаный вихрь, перемещается на расстояние до 60 футов и принимает свой обычный облик. Находясь в облике вихря, ★СУЩЕСТВО★ обладает иммунитетом ко всем видам урона, и не может быть ★обездвижен★, ★ошеломлён★, ★сбит★ с ног, ★схвачен★, и не может стать ★окаменевшим★. Снаряжение, несомое и носимое лорд-мумией, остаётся у ★него★.`,
      cost: 2,
    },
  ],
  genderId: GENDER_FEMALE,
}
