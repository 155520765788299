const {
  SPELL_DETECT_THOUGHTS,
  SPELL_SCRYING,
  SPELL_SUGGESTION,
} = require('./../../../../../spellIdList')
const {
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_CRYSTAL_BALL_USUAL,
  MAGIC_ITEM_CRYSTAL_BALL_OF_MIND_READING,
  MAGIC_ITEM_CRYSTAL_BALL_OF_TELEPATHY,
  MAGIC_ITEM_CRYSTAL_BALL_OF_TRUE_SEEING,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_CRYSTAL_BALL_USUAL,
    name: `Хрустальный шар, простой`,
    nameEn: `Crystal Ball, usual`,
    rarity: MGC_RARITY_VERY_RARE,
  },
  {
    id: MAGIC_ITEM_CRYSTAL_BALL_OF_TRUE_SEEING,
    name: `Хрустальный шар истинного зрения`,
    nameEn: `Crystal Ball of True Seeing`,
    rarity: MGC_RARITY_LEGENDARY,
    descriptionEnd: `Если Вы смотрите в этот шар, Вы обладаете истинным зрением в радиусе 120 футов с центром на сенсоре заклинания.`,
  },
  {
    id: MAGIC_ITEM_CRYSTAL_BALL_OF_TELEPATHY,
    name: `Хрустальный шар телепатии`,
    nameEn: `Crystal Ball of Telepathy.`,
    rarity: MGC_RARITY_LEGENDARY,
    descriptionEnd: `Если Вы смотрите в этот хрустальный шар, Вы можете телепатически общаться с существами, видимыми Вами в пределах 30 футов от сенсора. Вы также можете действием наложить заклинание [Внушение](SPELL:${SPELL_SUGGESTION}) (СЛ испытания 17) через сенсор на одно из таких существ. Вы не обязаны концентрироваться на [Внушении](SPELL:${SPELL_SUGGESTION}), но это заклинание оканчивается вместе с [Наблюдением](SPELL:${SPELL_SCRYING}). Вы не можете повторно использовать внушающую способность этого хрустального шара до следующего рассвета.`,
  },
  {
    id: MAGIC_ITEM_CRYSTAL_BALL_OF_MIND_READING,
    name: `Хрустальный шар чтения мыслей`,
    nameEn: `Crystal Ball of Mind Reading`,
    rarity: MGC_RARITY_LEGENDARY,
    descriptionEnd: `Вы можете действием наложить заклинание [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) (СЛ испытания 17), пока смотрите в этот хрустальный шар, нацеливаясь на существо, которое Вы видите в пределах 30 футов от сенсора заклинания. Вы не обязаны концентрироваться на [Обнаружении мыслей](SPELL:${SPELL_DETECT_THOUGHTS}), но это заклинание оканчивается вместе с [Наблюдением](SPELL:${SPELL_SCRYING}).`,
  },
]
