const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {CONDITION_STUNNED} = require('./../../../../../conditionList')
const {DAMAGE_PSYCHIC} = require('./../../../../../damageTypeList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_INT} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_PSYCHIC_SCREAM} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_PSYCHIC_SCREAM,
  name: 'Психический крик',
  nameEn: 'Psychic Scream',
  description: `Вы высвобождаете силу своей мысли, чтобы выжечь разум до десяти существ по Вашему выбору, которых Вы можете видеть в пределах дистанции. Существа, у которых Интеллект равен 2 или ниже, не попадают под действие этого заклинания.

Каждая цель должна пройти испытание Интеллекта. При провале цель получает 14к6 психического урона и становится ошеломлённой. При успехе цель получает только половину урона и не ошеломлена. Если этот урон убивает цель, её голова взрывается, при наличии таковой.

Ошеломлённая цель может проходить испытание Интеллекта в конце каждого своего хода. При успехе ошеломление спадает.`,
  lvl: 9,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 90,
  componentIdList: [CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_INT,
    condition: CONDITION_STUNNED,
    damage: {
      type: DAMAGE_PSYCHIC,
      halfOnSuccess: true,
      diceCount: 14,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 164,
  },
}
