const {ABILITY_PARRY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {howlingHatredDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ANIMAL_HANDLING,
  SKILL_ATHLETICS,
  SKILL_DECEPTION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  GEAR_GREATSWORD,
  GEAR_BREASTPLATE,
} = require('./../../../../gearIdList')
const {
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FEATHER_FALL,
  SPELL_FRIENDS,
  SPELL_GUST,
  SPELL_HASTE,
  SPELL_JUMP,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_MESSAGE,
  SPELL_MISTY_STEP,
  SPELL_RAY_OF_FROST,
} = require('./../../../../spellIdList')
const {
  LANG_AURAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_AERISI_KALINOTH,
  CREATURE_THURL_MEROSSKA,
  CREATURE_FEATHERGALE_KNIGHT,
  CREATURE_GRIFFON_CAVALRY_RIDER,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Тёрл Меросска',
  nameEn: 'Thurl Merosska',
  id: CREATURE_THURL_MEROSSKA,
  hasSpoilers: true,
  description: [
    {
      header: 'Тёрл Меросска',
      text: `Тёрл Меросска занимает пост предводителя [рыцарей Бури Перьев](CREATURE:${CREATURE_FEATHERGALE_KNIGHT}). Раньше Тёрл служил [всадником на грифоне в Глубоководье](CREATURE:${CREATURE_GRIFFON_CAVALRY_RIDER}), но ушёл в отставку после того, как чуть не погиб во время грозы. Пережитый опыт терзал его, и когда Тёрл узнал о Ян-Си-Бине, то в обмен на могущество поклялся стихийному принцу в верности.

Решив, что среди богатых граждан Глубоководья найдутся и другие достойные слуги Ян-Си-Бина, Тёрл основал общество Бури Перьев, с помощью которого привлекал подходящих кандидатов в ряды культа воздуха и знакомил своих [рыцарей](CREATURE:${CREATURE_FEATHERGALE_KNIGHT}) с его доктринами.

Когда [Аэриси Калинос](CREATURE:${CREATURE_AERISI_KALINOTH}) стала пророком воздуха, Тёрл неохотно привлёк своих соратников к общему делу. Хотя он и недоволен тем, что [Аэриси](CREATURE:${CREATURE_AERISI_KALINOTH}) занимает главенствующее положение в культе, он подчиняется ей в надежде использовать ресурсы культа и её саму для укрепления общества Бури Перьев. С Аериси и её последователями на своей стороне общество будет править Глубоководьем так, как должно.`,
      source: {
        id: SOURCE_PotA,
        page: 197,
      },
    },
    ...howlingHatredDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_PotA,
    page: 197,
  },
  armor: {
    ac: 16,
    gearId: GEAR_BREASTPLATE,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_ANIMAL_HANDLING]: PROF_NORMAL,
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  languageList: [
    LANG_AURAN,
    LANG_COMMON,
  ],
  cr: CR_3,
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_FEATHER_FALL,
      SPELL_FRIENDS,
      SPELL_GUST,
      SPELL_HASTE,
      SPELL_JUMP,
      SPELL_LEVITATE,
      SPELL_LIGHT,
      SPELL_MESSAGE,
      SPELL_MISTY_STEP,
      SPELL_RAY_OF_FROST,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_GREATSWORD,
    },
    {
      name: 'Кавалерийская пика',
      comment: `с помехой, если цель в пределах 5 футов`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 10,
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 12,
              diceBonus: 3,
            },
            {
              comment: `, если ★СУЩЕСТВО★ сражается верхом`,
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 12,
              diceBonus: 3,
            },
          ],
        ],
      },
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 2,
    },
  ],
}
