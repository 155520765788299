const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../damageTypeList')
const {
  MAGIC_ITEM_ORB_OF_SHIELDING_IRIAN_QUARTZ,
  MAGIC_ITEM_ORB_OF_SHIELDING_XORIAN_MARBLE,
  MAGIC_ITEM_ORB_OF_SHIELDING_KYTHRIAN_SKARN,
  MAGIC_ITEM_ORB_OF_SHIELDING_LAMANNIAN_FLINT,
  MAGIC_ITEM_ORB_OF_SHIELDING_MABARAN_OBSIDIAN,
  MAGIC_ITEM_ORB_OF_SHIELDING_RISIAN_SHALE,
  MAGIC_ITEM_ORB_OF_SHIELDING_FERNIAN_BASALT,
  MAGIC_ITEM_ORB_OF_SHIELDING_SHAVARRAN_CHERT,
} = require('./../../../magicItemIdList')

const orbOfShieldingList = [
  {
    id: MAGIC_ITEM_ORB_OF_SHIELDING_IRIAN_QUARTZ ,
    name: {
      nominative: 'айрианского кварца',
      genitive: 'айрианский кварц',
    },
    nameEn: 'Irian Quartz ',
    planeName: 'Айриан',
    damageTypeIdList: DAMAGE_RADIANT,
  },
  {
    id: MAGIC_ITEM_ORB_OF_SHIELDING_XORIAN_MARBLE,
    name: {
      nominative: 'зориатского мрамора',
      genitive: 'зориатский мрамор',
    },
    nameEn: 'Xorian Marble',
    planeName: 'Зориат',
    damageTypeIdList: DAMAGE_PSYCHIC,
  },
  {
    id: MAGIC_ITEM_ORB_OF_SHIELDING_KYTHRIAN_SKARN,
    name: {
      nominative: 'китрийского скарна',
      genitive: 'китрийский скарн',
    },
    nameEn: 'Kythrian Skarn',
    planeName: 'Китри',
    damageTypeIdList: [
      DAMAGE_ACID,
      DAMAGE_POISON,
    ],
  },
  {
    id: MAGIC_ITEM_ORB_OF_SHIELDING_LAMANNIAN_FLINT,
    name: {
      nominative: 'ламаннийского кремня',
      genitive: 'ламаннийский кремень',
    },
    nameEn: 'Lamannian Flint',
    planeName: 'Ламаннию',
    damageTypeIdList: [
      DAMAGE_ELECTRICITY,
      DAMAGE_THUNDER,
    ],
  },
  {
    id: MAGIC_ITEM_ORB_OF_SHIELDING_MABARAN_OBSIDIAN,
    name: {
      nominative: 'мабарского обсидиана',
      genitive: 'мабарский обсидиан',
    },
    nameEn: 'Mabaran Obsidian',
    planeName: 'Мабар',
    damageTypeIdList: DAMAGE_NECROTIC,
  },
  {
    id: MAGIC_ITEM_ORB_OF_SHIELDING_RISIAN_SHALE,
    name: {
      nominative: 'райсийской сланца',
      genitive: 'райсийский сланец',
    },
    nameEn: 'Risian Shale',
    planeName: 'Райсию',
    damageTypeIdList: DAMAGE_COLD,
  },
  {
    id: MAGIC_ITEM_ORB_OF_SHIELDING_FERNIAN_BASALT,
    name: {
      nominative: 'фернийского базальта',
      genitive: 'фернийский базальт',
    },
    nameEn: 'Fernian Basalt',
    planeName: 'Фернию',
    damageTypeIdList: DAMAGE_FIRE,
  },
  {
    id: MAGIC_ITEM_ORB_OF_SHIELDING_SHAVARRAN_CHERT,
    name: {
      nominative: 'шаваразского известняка',
      genitive: 'шаваразский известняк',
    },
    nameEn: 'Shavarran Chert',
    planeName: 'Шавараз',
    damageTypeIdList: DAMAGE_FORCE,
  },
]

module.exports = orbOfShieldingList
