const {
  GEAR_GUNPOWDER_KEG,
  GEAR_SACK,
} = require('./../../../../../gearIdList')
const {
  MAGIC_ITEM_SMOKEPOWDER_KEG,
  MAGIC_ITEM_SMOKEPOWDER_PACKET,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    damageDiceCount: 1,
    gearId: GEAR_SACK,
    id: MAGIC_ITEM_SMOKEPOWDER_PACKET,
    name: 'Дым-порох, мешочек',
    nameEn: 'Smokepowder, packet',
    namePrepositional: 'мешочке',
    shotCount: 'пять',
    storedAt: 'герметичном деревянном бочонке',
  },
  {
    damageDiceCount: 9,
    gearId: GEAR_GUNPOWDER_KEG,
    id: MAGIC_ITEM_SMOKEPOWDER_KEG,
    name: 'Дым-порох, бочонок',
    nameEn: 'Smokepowder, keg',
    namePrepositional: 'бочонке',
    shotCount: 'пятьсот',
    storedAt: `маленьком кожаном мешочке`,
  },
]
