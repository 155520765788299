const {halfCasterSpellCircleList} = require('./../../spellCircleByCasterType')
const {PC_CLASS_RANGER} = require('./../../pcClassIdList')
const SPELL_SLOT_TABLE_ID = require('./../../SPELL_SLOT_TABLE_ID')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_BEAST_SENSE,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_ENTANGLE,
  SPELL_LOCATE_CREATURE,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_PLANTS,
} = require('./../../spellIdList')
const {
  GEAR_SPRIG_OF_HOLLY,
  GEAR_SPRIG_OF_MISTLETOE,
  GEAR_TOTEM,
  GEAR_WOODEN_STAFF,
  GEAR_YEW_SCEPTER,
  GEAR_YEW_WAND,
} = require('./../../gearIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
} = require('./../../sourceList')
const {spellIdCollectionByPcClassId} = require('./../../spellIdPcClassIdBounds')

const {
  abilityScoreImprovementTemplate,
  extraAttackTemplate,
  fightingStyleFeatureTemplate,
} = require('./../featureCommonRawList')
const {
  FIGHTING_STYLE_ARCHERY,
  FIGHTING_STYLE_BLIND_FIGHTING,
  FIGHTING_STYLE_DEFENSE,
  FIGHTING_STYLE_DRUIDIC_WARRIOR,
  FIGHTING_STYLE_DUELING,
  FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
  FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
} = require('./../../fightingStyleIdList')
const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_RANGER,
  FEATURE_DEFT_EXPLORER,
  FEATURE_EXTRA_ATTACK_RANGER,
  FEATURE_FAVORED_ENEMY,
  FEATURE_FAVORED_FOE,
  FEATURE_FERAL_SENSES,
  FEATURE_FIGHTING_STYLE_RANGER,
  FEATURE_FOE_SLAYER,
  FEATURE_HIDE_IN_PLAIN_SIGHT,
  FEATURE_LANDS_STRIDE,
  FEATURE_MARTIAL_VERSATILITY_RANGER,
  FEATURE_NATURAL_EXPLORER,
  FEATURE_NATURE_S_VEIL,
  FEATURE_PRIMAL_AWARENESS,
  FEATURE_PRIMEVAL_AWARENESS,
  FEATURE_RANGER_ARCHETYPE,
  FEATURE_SPELL_KNOWN_RANGER,
  FEATURE_SPELL_SLOTS_RANGER,
  FEATURE_SPELLCASTING_ABILITY_RANGER,
  FEATURE_SPELLCASTING_DRUID,
  FEATURE_SPELLCASTING_FOCUS,
  FEATURE_SPELLCASTING_RANGER,
  FEATURE_VANISH,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_FAVORED_ENEMY,
    name: `Избранный враг`,
    nameEn: `Favored Enemy`,
    lvl: [1, 6, 14],
    text: `У Вас есть значительный опыт изучения, отслеживания, охоты и даже общения с определённым видом врагов.

Выберите вид избранного врага: аберрации, великаны, драконы, звери, исчадия, конструкты, монстры, небожители, нежить, растения, слизи, феи или элементали. В качестве альтернативы Вы можете выбрать в качестве избранных врагов две гуманоидные расы (например, гноллов и орков).

Вы совершаете с преимуществом проверки Мудрости (Выживание) для выслеживания избранных врагов, а также проверки Интеллекта для вспоминания информации о них.

Когда Вы получаете это умение, Вы также обучаетесь одному из языков, на котором говорит Ваш избранный враг, если он вообще умеет говорить.

Вы дополнительно выбираете по одному избранному врагу и языку, связанному с ним, на 6 и 14 уровнях. Получая уровни, Ваш выбор должен отражать чудовищ, с которыми Вы уже сталкивались во время приключений. `,
    source: {
      id: SOURCE_PHB,
      page: 92,
    },
  },
  {
    id: FEATURE_FAVORED_FOE,
    name: `Избранный противник`,
    nameEn: `Favored Foe`,
    isOptional: true,
    lvl: [1, 6, 14],
    text: `_Опциональная способность. Вы можете заменить этой способностью способность [Избранный враг](FEATURE:${FEATURE_FAVORED_ENEMY}). Она хорошо сочетается со способностью [Убийца врагов](FEATURE:${FEATURE_FOE_SLAYER})._
    
Когда Вы попадаете по существу атакой, Вы можете призвать свою мистическую связь с природой, помечая цель как своего _Избранного противника_ на 1 минуту или до тех пор, пока Вы не потеряете концентрацию (как если бы Вы концентрировались на заклинании).

В первый раз на каждом своём ходу, когда Вы попадаете по _Избранному противнику_ и наносите ему урон, в том числе на том ходу, когда Вы его помечаете, Вы можете увеличить этот урон на 1к4.

Вы можете использовать способность помечать _Избранного противника_ количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.

Дополнительный урон этой способности увеличивается, когда Вы достигаете определенных уровней в этом классе: до 1к6 на 6 уровне и до 1к8 на 14 уровне.`,
    source: {
      id: SOURCE_TCoE,
      page: 82,
    },
  },
  {
    id: FEATURE_NATURAL_EXPLORER,
    name: `Исследователь природы`,
    nameEn: `Natural Explorer`,
    lvl: [1, 6, 10],
    text: `Вы очень хорошо знакомы с одним видом природной среды и имеете большой опыт путешествий и выживания в регионах с таким климатом.

Выберите один вид известной местности: 

* Арктика,
* болота,
* горы,
* леса,
* луга,
* побережье,
* Подземье,
* пустыня.

Когда Вы совершаете проверку Интеллекта или Мудрости, связанную с известной Вам местностью, Ваш бонус мастерства удваивается, если Вы используете навык, которым владеете.

Путешествуя по избранной Вами местности в течении часа или более, Вы получаете следующие преимущества:

* Труднопроходимая местность не замедляет путешествие группы.
* Ваша группа не может заблудиться, кроме как по вине магии.
* Даже когда Вы занимаетесь другой деятельностью во время путешествия (например, ищете пищу, ориентируетесь или выслеживаете), Вы остаётесь готовы к опасности.
* Если Вы путешествуете в одиночку, Вы можете перемещаться скрытно в нормальном темпе.
* Когда Вы ищете пищу, то находите в два раза больше, чем обычно.
* Когда Вы выслеживаете других существ, Вы также узнаёте их точное количество, их размеры, и как давно они прошли через область.

Вы можете выбрать дополнительную известную местность на 6 и 10 уровнях.`,
    source: {
      id: SOURCE_PHB,
      page: 92,
    },
  },
  {
    id: FEATURE_DEFT_EXPLORER,
    name: `Искусный исследователь`,
    nameEn: `Deft Explorer`,
    isOptional: true,
    lvl: [1, 6, 10],
    text: `_Опциональная способность. Вы можете заменить этой способностью способность [Исследователь природы](FEATURE:${FEATURE_NATURAL_EXPLORER})._
    
Вы непревзойденный исследователь, чувствующий себя как рыба в воде как в дикой природе, так и в общении с другими людьми в путешествиях.

Вы получаете преимущество «Матёрый», указанное ниже, и дополнительные преимущества, достигая 6-го и 10-го уровней в этом классе.

# Матёрый (1 уровень)

Выберите один навык, которым Вы владеете. Ваш бонус мастерства удваивается для любых использующих его проверок характеристик.

Также Вы можете говорить, читать и писать на двух дополнительных языках по Вашему выбору.

# Скиталец (6 уровень)

Ваша скорость ходьбы увеличивается на 5, и Вы получаете скорости лазания и плавания, равные Вашей скорости ходьбы.

# Неутомимый (10 уровень)

Вы можете действием дать себе количество временных хитов, равное 1к8 + Ваш модификатор Мудрости (минимум 1 временный хит). Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.

Кроме того, каждый раз, когда Вы заканчиваете короткий отдых, Ваш уровень Истощения (если он есть), уменьшается на 1.`,
    source: {
      id: SOURCE_TCoE,
      page: 82,
    },
  },
  {
    ...fightingStyleFeatureTemplate,
    id: FEATURE_FIGHTING_STYLE_RANGER,
    lvl: 2,
    fightingStyleIdList: [
      FIGHTING_STYLE_ARCHERY,
      FIGHTING_STYLE_BLIND_FIGHTING,
      FIGHTING_STYLE_DEFENSE,
      FIGHTING_STYLE_DRUIDIC_WARRIOR,
      FIGHTING_STYLE_DUELING,
      FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
      FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
    ],
    source: {
      id: SOURCE_PHB,
      page: 92,
    },
  },
  {
    id: FEATURE_SPELLCASTING_RANGER,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 2,
    text: `Вы обучаетесь использованию волшебной сущности природы для сотворения заклинаний подобно [друиду](FEATURE:${FEATURE_SPELLCASTING_DRUID}).`,
    spellCircleLvl: halfCasterSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_RANGER],
    source: {
      id: SOURCE_PHB,
      page: 92,
    },
  },
  {
    id: FEATURE_SPELL_SLOTS_RANGER,
    name: `Ячейки заклинаний`,
    nameEn: `Spell Slots`,
    lvl: 2,
    text: `Таблица «[Ячейки заклинаний следопыта](PC_CLASS:${PC_CLASS_RANGER}#${SPELL_SLOT_TABLE_ID})» показывает, какое количество ячеек заклинаний у Вас есть на первом и более высоких уровнях. Для сотворения одного из этих заклинаний Вы должны потратить ячейку заклинаний того же круга или выше, что и само заклинание. Вы восстановите все потраченные ячейки, когда завершите продолжительный отдых.

Например, если Вы знаете заклинание 1 круга [Дружба с животными](SPELL:${SPELL_ANIMAL_FRIENDSHIP}), и у Вас есть ячейки 1 и 2 кругов, то Вы можете сотворить заклинание [Дружба с животными](SPELL:${SPELL_ANIMAL_FRIENDSHIP}) с помощью любой из этих ячеек.`,
    source: {
      id: SOURCE_PHB,
      page: 92,
    },
  },
  {
    id: FEATURE_SPELL_KNOWN_RANGER,
    name: `Известные заклинания`,
    nameEn: `Spells Known`,
    lvl: 2,
    text: `Вы знаете два заклинания 1 круга на свой выбор из списка доступных следопыту.

Таблица показывает, когда Вы сможете выучить новые заклинания. Круг заклинаний не должен превышать круга самой высокой имеющейся у Вас ячейки заклинаний. Например, достигнув 5 уровня в этом классе, Вы можете выучить одно новое заклинание 1 или 2 уровня.

Кроме того, получая новый уровень в этом классе, Вы можете одно из известных заклинаний следопыта заменить на другое из списка следопыта, круг которого тоже должен соответствовать имеющимся ячейкам заклинаний.

| Уровень следопыта | Известные заклинания | Уровень следопыта | Известные заклинания |
|-------------------|----------------------|-------------------|----------------------|
|  2                |  2                   | 11–12             |  7                   |
|  3–4              |  3                   | 13–14             |  8                   |
|  5–6              |  4                   | 15–16             |  9                   |
|  7–8              |  5                   | 17–18             | 10                   |
|  9–10             |  6                   | 19–20             | 11                   |
`,
    spellCircleLvl: halfCasterSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_RANGER],
    source: {
      id: SOURCE_PHB,
      page: 92,
    },
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_RANGER,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 2,
    text: `При создании заклинаний следопыт использует Мудрость, так как магия отображает Вашу взаимосвязь с природой. Вы используете Мудрость в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете модификатор Мудрости при определении СЛ испытаний от Ваших заклинаний следопыта, и при броске атаки заклинаниями.

**СЛ испытаний = 8 + бонус мастерства + модификатор Мудрости**

**Модификатор броска атаки = бонус мастерства + модификатор Мудрости**`,
    source: {
      id: SOURCE_PHB,
      page: 92,
    },
  },
  {
    id: FEATURE_SPELLCASTING_FOCUS,
    name: `Заклинательная фокусировка`,
    nameEn: `Spellcasting Focus`,
    lvl: 2,
    text: `Вы можете использовать фокусировку друидов в качестве заклинательной фокусировки для заклинаний следопыта.

Фокусировка друидов может быть [веточка омелы](GEAR:${GEAR_SPRIG_OF_MISTLETOE}) или [падуба](GEAR:${GEAR_SPRIG_OF_HOLLY}), [волшебная палочка](GEAR:${GEAR_YEW_WAND}) или [жезл из тиса](GEAR:${GEAR_YEW_SCEPTER}) или другого особого дерева, [посох](GEAR:${GEAR_WOODEN_STAFF}), целиком сделанный из живого дерева, или [тотем](GEAR:${GEAR_TOTEM}), состоящий из перьев, меха, костей и зубов священных животных.`,
    source: {
      id: SOURCE_TCoE,
      page: 82,
    },
  },
  {
    id: FEATURE_RANGER_ARCHETYPE,
    name: `Архетип следопыта`,
    nameEn: `Ranger archetype`,
    lvl: [3, 7, 11, 15],
    text: `Вы выбираете архетип, к которому Вы стремитесь.

Выбранный Вами архетип предоставляет умения на 3, 7, 11 и 15 уровнях.`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  },
  {
    id: FEATURE_PRIMEVAL_AWARENESS,
    name: `Первозданная осведомлённость`,
    nameEn: `Primeval awareness`,
    lvl: 3,
    text: `Вы можете действием потратить одну ячейку заклинаний следопыта, чтобы сосредоточиться на познании пространства вокруг себя.

В течение 1 минуты за каждый круг использованной ячейки заклинаний Вы можете ощутить присутствие следующих видов существ в пределах 1 мили (или в пределах 6 миль, если Вы находитесь в [Избранной местности](FEATURE:${FEATURE_NATURAL_EXPLORER})): 

* аберрации,
* драконы,
* исчадия,
* небожители,
* нежить,
* феи,
* элементали.

Это умение не раскрывает местоположение и количество существ.`,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  },
  {
    id: FEATURE_PRIMAL_AWARENESS,
    name: `Первобытная осведомлённость`,
    nameEn: `Primal Awareness`,
    isOptional: true,
    lvl: 3,
    text: `_Опциональная способность. Вы можете заменить этой способностью способность [Первозданная осведомлённость](FEATURE:${FEATURE_PRIMEVAL_AWARENESS})._

Вы можете сосредоточить своё внимание на взаимосвязях природы: Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе, если Вы их ещё не знаете. Эти заклинания не учитываются при подсчёте известных Вам заклинаний следопыта.

Вы можете сотворить каждое из них один раз без траты ячейки заклинаний. Сотворив заклинание таким образом, Вы не сможете сделать это снова, не закончив длинный отдых.`,
    spellCircleLvl: halfCasterSpellCircleList,
    spellIdList: [
      SPELL_BEAST_SENSE,
      SPELL_COMMUNE_WITH_NATURE,
      SPELL_LOCATE_CREATURE,
      SPELL_SPEAK_WITH_ANIMALS,
      SPELL_SPEAK_WITH_PLANTS,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 82,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_RANGER,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  },
  {
    id: FEATURE_MARTIAL_VERSATILITY_RANGER,
    name: `Боевая гибкость`,
    nameEn: `Martial Versatility`,
    lvl: abilityScoreImprovementTemplate.lvl,
    text: `Всякий раз, достигая в этом классе нового уровня, на котором Вы получаете способность [Увеличения характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_RANGER}), Вы можете заменить один известный Вам [боевой стиль](FEATURE:${FEATURE_FIGHTING_STYLE_RANGER}) на другой, доступный следопытам.

Это демонстрирует смещение фокуса Ваших боевых тренировок.`,
    source: {
      id: SOURCE_TCoE,
      page: 85,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_RANGER,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  },
  {
    id: FEATURE_LANDS_STRIDE,
    name: `Тропами земли`,
    nameEn: `Land’s Stride`,
    lvl: 8,
    text: `Перемещение по немагической труднопроходимой местности не стоит Вам никакого дополнительного перемещения.

Вы также можете проходить через немагические растения без замедления, и не получая от них урона, даже если у них есть шипы, колючки или аналогичная опасность.

Кроме того, Вы совершаете с преимуществом испытания от растений, которые были магическим образом созданы или управляются магией, препятствуя движению, например, созданных заклинанием [Опутывание](SPELL:${SPELL_ENTANGLE}).`,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  },
  {
    id: FEATURE_HIDE_IN_PLAIN_SIGHT,
    name: `Маскировка на виду`,
    nameEn: `Hide in Plain Sight`,
    lvl: 10,
    text: `Вы можете потратить 1 минуту для создания персонального камуфляжа.

У Вас должен быть доступ к свежим илу, грязи, растениям, саже и другим природным материалам, с помощью которых будет создаваться камуфляж.

После того как Вы замаскировались подобным образом, Вы можете попытаться спрятаться, прижавшись к твёрдой поверхности, такой как дерево или стена, не уступающей Вам по высоте и ширине. Вы получаете бонус +10 к проверкам Ловкости (Скрытность), пока находитесь без движения и не предпринимаете действий.

После того как Вы переместились, совершили действие или реакцию, Вы должны снова маскироваться с самого начала, чтобы получить это преимущество.`,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  },
  {
    id: FEATURE_NATURE_S_VEIL,
    name: `Покров природы`,
    nameEn: `Nature’s Veil`,
    isOptional: true,
    lvl: 10,
    text: `_Опциональная способность. Вы можете заменить этой способностью способность [Маскировка на виду](FEATURE:${FEATURE_HIDE_IN_PLAIN_SIGHT})._
    
Вы черпаете силы природы, чтобы ненадолго скрыться с глаз.

Вы можете бонусным действием магически стать невидимым до начала своего следующего хода вместе со всем снаряжением, которое Вы несёте и носите.

Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 82,
    },
  },
  {
    id: FEATURE_VANISH,
    name: `Исчезновение`,
    nameEn: `Vanish`,
    lvl: 14,
    text: `Вы можете бонусным действием в свой ход совершать действие Засада.

Кроме того, Вам нельзя выследить немагическими способом, если Вы не оставите след намеренно.`,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  },
  {
    id: FEATURE_FERAL_SENSES,
    name: `Дикие чувства`,
    nameEn: `Feral Senses`,
    lvl: 18,
    text: `Вы получаете сверхъестественные чувства, которые помогут Вам сражаться с существами, которых Вы не можете увидеть.

Когда Вы атакуете существо, которое не видите, Ваша неспособность видеть не накладывает помеху броскам атаки по нему.

Вам также известно о местонахождении всех невидимых существ в пределах 30 футов от Вас, при условии, что они не скрыты от Вас, и Вы не ослеплены и не оглушены.`,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  },
  {
    id: FEATURE_FOE_SLAYER,
    name: `Убийца врагов`,
    nameEn: `Foe Slayer`,
    lvl: 20,
    text: `Вы становитесь беспрецедентным охотником на своих врагов.

Один раз в каждом своём ходу Вы можете добавить модификатор Мудрости к броску атаки или урона по существу из списка Ваших [Избранных врагов](FEATURE:${FEATURE_FAVORED_ENEMY}). Вы можете использовать это умение до или после броска, но до того, как эффекты броска вступят в силу.`,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_RANGER,
  })
)
