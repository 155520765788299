const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  SPELL_DAYLIGHT,
  SPELL_FIREBALL,
  SPELL_PRISMATIC_SPRAY,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_HELM_OF_BRILLIANCE,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_HELM_OF_BRILLIANCE,
  name: `Шлем блеска`,
  nameEn: `Helm of Brilliance`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `Этот сверкающий шлем украшен 1к10 бриллиантами, 2к10 рубинами, 3к10 огненными опалами и 4к10 опалами. Любой камень, извлечённый из шлема, рассыпается в пыль. Если все камни изымут или уничтожат, шлем теряет свою магию.

Вы получает следующие преимущества, пока носите его:

* Вы можете действием наложить одно из следующих заклинаний (СЛ испытания 18), используя один из камней шлема определённого вида в качестве компонента. Соответствующий драгоценный камень уничтожается, когда накладывается заклинание, и исчезает со шлема.
  * [Дневной свет](SPELL:${SPELL_DAYLIGHT}) (опал),
  * [Огненная стена](SPELL:${SPELL_WALL_OF_FIRE}) (рубин),
  * [Огненный шар](SPELL:${SPELL_FIREBALL}) (огненный опал),
  * [Радужные брызги](SPELL:${SPELL_PRISMATIC_SPRAY}) (бриллиант).
* Пока у шлема есть хотя бы один бриллиант, он испускает тусклый свет в радиусе 30 футов, если в пределах этой области есть хотя бы одна нежить. Нежить, начинающая ход в этой области, получает урон излучением 1к6. 
* Пока у шлема есть хотя бы один рубин, Вы обладаете сопротивлением к урону огнём.
* Пока у шлема есть хотя бы один огненный опал, Вы можете действием произнести командное слово, чтобы окутать одно оружие, которое держите, пламенем. Пламя испускает яркий свет в пределах 10 футов и тусклый свет в пределах ещё 10 футов. Это пламя безвредно для Вас и Вашего оружия. Если Вы попадаете атакой, используя это пылающее оружие, цель получает дополнительный урон огнём 1к6. Пламя существует, пока Вы не произнесёте бонусным действием командное слово ещё раз, пока Вы не уберёте оружие в ножны или не бросите его.

Бросьте к20, если носите этот шлем и получаете урон огнём из-за провала испытания от заклинания. Если выпадет «1», шлем испустит из оставшихся камней лучи света. Все существа (кроме Вас) в пределах 60 футов должны пройти испытание Ловкости СЛ 17, иначе их коснётся луч, и они получат урон излучением, равный количеству драгоценных камней на шлеме. И шлем, и драгоценные камни при этом уничтожаются.`,
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_DAYLIGHT,
    SPELL_FIREBALL,
    SPELL_PRISMATIC_SPRAY,
    SPELL_WALL_OF_FIRE,
  ],
  source: {
    id: SOURCE_DMG,
    page: 212,
  },
}
