const {
  SPELL_CREATION,
  SPELL_FEATHER_FALL,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_JUMP,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_HAND,
  SPELL_PLANE_SHIFT,
  SPELL_REVERSE_GRAVITY,
  SPELL_SEE_INVISIBILITY,
  SPELL_SHIELD,
  SPELL_TELEKINESIS,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_WALL_OF_FORCE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_GITH,
  CREATURE_TYPE_GITHZERAI,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  githzeraiDescription,
  githNote,
} = require('./../../../../textCommonParts')
const {ABILITY_TELEPORT} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_16} = require('./../../../../crList')
const {CREATURE_GITHZERAI_ANARCH} = require('./../../../../creatureIdList')
const {LANG_GITH} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Гитцерай Анарх',
  nameEn: 'Githzerai Anarch',
  id: CREATURE_GITHZERAI_ANARCH,
  description: [
    `Как наиболее могущественные из гитцераев, Анархи правят общинами и поддерживают существование адамантовых цитаделей, являющихся укреплёнными пунктами гитцераев за пределами планов Лимбо. Они обладают огромными псионическими способностями и способны силой мысли манипулировать не сформированной материей их плана. Эти редчайшие гитцераи являются мудрецами и мистиками, а их слово — закон.`,
    {
      header: 'Логово анарха',
      text: `В Лимбо, гитцераи анархи создают острова умиротворённости в иных бурных планах. Направив свою псионическую силу, анарх способен создать бесформенную субстанцию, делая с помощью неё горы, озёра и даже сооружения любых размеров и материалов, служащие им основой для общества гитцерай.

# Действия логова

Анарх может использовать действия логова. При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам), анарх совершает действие логова, чтобы вызвать один из следующих эффектов; Анарх не может использовать один и тот же эффект два раунда подряд:

* Анарх сотворяет заклинание [молния](SPELL:${SPELL_LIGHTNING_BOLT}) (5 уровня), но анарх может сменить тип урона с электрического на урон холодом, огнём, психикой, излучением, или же звуком. Если заклинание не наносит урон огнём или электрическом, оно не воспламеняет легковоспламеняющиеся объекты. 
* Анарх сотворяет заклинание [сотворение](SPELL:${SPELL_CREATION}) (в качестве заклинания 9 уровня), но вместо теневого материала, он использует не сформировавшуюся субстанцию Лимбо. Применив заклинание в Лимбо, объект остаётся до того, как анарх потеряет свою концентрацию, вне зависимости от структуры. Если анарх переместится на расстояние, большее 120 футов от объекта, то потеряет концентрацию. 
* Анарх может магическим образом переместить объект, который он видит в пределах 150 футов от себя, совершив с преимуществом проверку Мудрости. СЛ зависит от размеров объекта: СЛ 5 для Крошечного, СЛ 10 для Маленького, СЛ 15 для Среднего, СЛ 20 для Большого и СЛ 25 для Огромного размеров или больше.

# Местные эффекты

Регион, содержащий логово анарха, искажён его присутствием, что создаёт один или несколько из следующих эффектов:

* В Лимбо анарх может потратить 10 минут, стабилизируя 5-мильную область, с центром на нём, в результате чего неоформленная субстанция примет любую неодушевлённую форму, которую выбирает анарх. Во время этого процесса анарх определяет форму и состав созданных форм.
* Анарх стабилизирует любой объект, созданный им в Лимбо и принесённый в Материальный план до тех пор, пока анарх находится в пределах 1 мили от объекта (не требует действия).

Если анарх умирает, эти эффекты оканчиваются через 1к6 раундов. Все сформированные субстанции превращаются в хаотичную смесь энергии и материи, разваливаясь в не сформированную субстанцию, которая рассеивается спустя 1к6 раундов.`,
      source: {
        id: SOURCE_MTOF,
        page: 124,
      },
    },
    ...githzeraiDescription,
  ],
  note: githNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHZERAI,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MTOF,
    page: 124,
  },
  armor: 20,
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: {
      value: 40,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 21,
    [PARAM_CON]: 18,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 20,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
  },
  languageList: [
    LANG_GITH,
  ],
  cr: CR_16,
  featureList: [
    {
      name: 'Телепатическая защита',
      description: `Пока ★СУЩЕСТВО★ не носит броню и не носит щит, к ★его★ классу доспеха добавляется модификатор Мудрости.`,
    },
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_WIT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_MAGE_HAND,
            comment: 'рука невидима',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_FEATHER_FALL,
          SPELL_JUMP,
          SPELL_SEE_INVISIBILITY,
          SPELL_SHIELD,
          SPELL_TELEKINESIS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_GLOBE_OF_INVULNERABILITY,
          SPELL_PLANE_SHIFT,
          SPELL_TELEPORTATION_CIRCLE,
          SPELL_WALL_OF_FORCE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три _Безоружные атаки_.`,
    },
    {
      name: 'Безоружная атака',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 5,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 4,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Удар',
      description: `★СУЩЕСТВО★ делает одну безоружную атаку.`,
    },
    {
      id: ABILITY_TELEPORT,
      range: 30,
    },
    {
      name: 'Изменение гравитации',
      cost: 3,
      description: `★СУЩЕСТВО★ сотворяет заклинание [изменение тяготения](SPELL:${SPELL_REVERSE_GRAVITY}). Заклинание действует по-обычному, за тем исключением, что ★СУЩЕСТВО★ ★сам★ выбирает направление действия гравитации и положение зоны заклинания и существа и объекты движутся по направлению к концу зоны.`,
    },
  ],
}
