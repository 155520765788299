const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_HOOK_HORROR,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_HOOK_HORROR,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык крюкастых ужасов',
    genitive: 'Языка крюкастых ужасов',
    instrumental: 'Языком крюкастых ужасов',
    prepositional: 'Языке крюкастых ужасов',
  },
  nameEn: 'Hook Horror',
  isReady: false,
  isRealLang: true,
}
