const {CREATURE_TYPE_LEONIN} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_LEONIN,
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'леонинец',
        genitive: 'леонинца',
        dative: 'леонинцу',
        accusative: 'леонинца',
        instrumental: 'леонинцом',
        prepositional: 'леонинце',
      },
      [GENDER_FEMALE]: {
        nominative: 'леонинка',
        genitive: 'леонинки',
        dative: 'леонинке',
        accusative: 'леонинку',
        instrumental: 'леонинкой',
        prepositional: 'леонинке',
      },
    },
    plural: {
      nominative: 'леонинцы',
      genitive: 'леонинцев',
      dative: 'леонинцам',
      accusative: 'леонинцев',
      instrumental: 'леонинцами',
      prepositional: 'леонинцах',
    },
  },
  nameEn: 'Leonin',
}
