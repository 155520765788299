const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_RASHEMI} = require('./../../languageIdList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {SOURCE_DND3_UE} = require('./../../sourceList')

module.exports = {
  id: LANG_RASHEMI,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Rashemi',
  name: {
    nominative: 'Рашемийский',
    genitive: 'Рашемийского',
    instrumental: 'Рашемийским',
    prepositional: 'Рашемийском',
  },
  typicalSpeakers: 'Рашеми',
  dictionary: {
    list: [
      [`древние`, `времённи`],
    ],
    source: {
      id: SOURCE_DND3_UE,
      page: 135,
    },
  },
  isReady: false,
  isRealLang: true,
}
