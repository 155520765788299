const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_FIRE} = require('./../../../../../damageTypeList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_CREATE_BONFIRE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_CREATE_BONFIRE,
  name: 'Сотворение костра',
  nameEn: 'Create Bonfire',
  description: `Вы создаёте огонь на поверхности земли в точке, которую можете видеть в пределах дистанции заклинания. Пока заклинание действует, огонь занимает область в 5-футовом кубе. Все существа, оказавшиеся в этом пространстве в момент накладывания заклинания, должны пройти испытание Ловкости, иначе получат урон огнём 1к8. Существо также должно проходить испытание, когда впервые за ход оказывается в области действия заклинания или заканчивает свой ход в ней.

Пламя поджигает все горючие предметы, никем не несомые и не носимые.`,
  highLvlCast: `Урон заклинания увеличивается на 1к8, когда Вы достигаете 5 уровня (2к8), 11 уровня (3к8) и 17 уровня (4к8).`,
  lvl: 0,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_FIRE,
      diceCount: 1,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 168,
    },
    {
      id: SOURCE_EE,
      page: 22,
    },
  ],
}
