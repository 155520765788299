const {
  SPELL_ABI_DALZIM_S_HORRID_WILTING,
  SPELL_ABSORB_ELEMENTS,
  SPELL_ACID_SPLASH,
  SPELL_ACID_STREAM_2020_03_26,
  SPELL_AGANAZZAR_S_SCORCHER,
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ARCANE_GATE,
  SPELL_ASHARDALON_S_STRIDE,
  SPELL_BANISHMENT,
  SPELL_BIGBY_S_HAND,
  SPELL_BLADE_OF_DISASTER,
  SPELL_BLADE_WARD,
  SPELL_BLIGHT,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BLINK,
  SPELL_BLUR,
  SPELL_BOOMING_BLADE,
  SPELL_BURNING_HANDS,
  SPELL_CATAPULT,
  SPELL_CATNAP,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CHAOS_BOLT,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CHROMATIC_ORB,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CLAIRVOYANCE,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_CLOUDKILL,
  SPELL_COLOR_SPRAY,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_CONE_OF_COLD,
  SPELL_CONFUSION,
  SPELL_CONTROL_FLAMES,
  SPELL_CONTROL_WINDS,
  SPELL_COUNTERSPELL,
  SPELL_CREATE_BONFIRE,
  SPELL_CREATION,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CROWN_OF_STARS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_DARKVISION,
  SPELL_DAYLIGHT,
  SPELL_DELAYED_BLAST_FIREBALL,
  SPELL_DEMIPLANE,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISINTEGRATE,
  SPELL_DISPEL_MAGIC,
  SPELL_DISTORT_VALUE,
  SPELL_DOMINATE_BEAST,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_DRACONIC_TRANSFORMATION,
  SPELL_DRACONIC_TRANSFORMATION_2021_04_14,
  SPELL_DRAGON_S_BREATH,
  SPELL_DREAM_OF_THE_BLUE_VEIL,
  SPELL_DUST_DEVIL,
  SPELL_EARTH_TREMOR,
  SPELL_EARTHBIND,
  SPELL_EARTHQUAKE,
  SPELL_EGO_WHIP_2019_11_25,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENEMIES_ABOUND,
  SPELL_ENERVATION,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_ERUPTING_EARTH,
  SPELL_ETHEREALNESS,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_EYEBITE,
  SPELL_FALSE_LIFE,
  SPELL_FAR_STEP,
  SPELL_FEAR,
  SPELL_FEATHER_FALL,
  SPELL_FINGER_OF_DEATH,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FIRE_STORM,
  SPELL_FIREBALL,
  SPELL_FIZBAN_S_PLATINUM_SHIELD,
  SPELL_FIZBAN_S_PLATINUM_SHIELD_2021_04_14,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_BLADE,
  SPELL_FLAME_STRIDE_2021_04_14,
  SPELL_FLAMING_SPHERE,
  SPELL_FLESH_TO_STONE,
  SPELL_FLY,
  SPELL_FOG_CLOUD,
  SPELL_FRIENDS,
  SPELL_FROSTBITE,
  SPELL_GASEOUS_FORM,
  SPELL_GATE,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_GREASE,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GREEN_FLAME_BLADE,
  SPELL_GUST,
  SPELL_GUST_OF_WIND,
  SPELL_HASTE,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_ICE_KNIFE,
  SPELL_ICE_STORM,
  SPELL_ICINGDEATH_S_FROST_2021_04_14,
  SPELL_ID_INSINUATION_2019_11_25,
  SPELL_IMMOLATION,
  SPELL_INCENDIARY_CLOUD,
  SPELL_INCITE_GREED,
  SPELL_INFESTATION,
  SPELL_INSECT_PLAGUE,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2019_11_25,
  SPELL_INTELLECT_FORTRESS_2020_04_14,
  SPELL_INVESTITURE_OF_FLAME,
  SPELL_INVESTITURE_OF_ICE,
  SPELL_INVESTITURE_OF_STONE,
  SPELL_INVESTITURE_OF_WIND,
  SPELL_INVISIBILITY,
  SPELL_JUMP,
  SPELL_KNOCK,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_LIGHTNING_BOLT,
  SPELL_LIGHTNING_LURE,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MAGIC_WEAPON,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_POLYMORPH,
  SPELL_MASS_SUGGESTION,
  SPELL_MAXIMILIAN_S_EARTHEN_GRASP,
  SPELL_MELF_S_MINUTE_METEORS,
  SPELL_MENDING,
  SPELL_MENTAL_BARRIER_2019_11_25,
  SPELL_MENTAL_PRISON,
  SPELL_MESSAGE,
  SPELL_METEOR_SWARM,
  SPELL_MIND_SLIVER,
  SPELL_MIND_SPIKE,
  SPELL_MIND_THRUST_2019_11_25,
  SPELL_MIND_THRUST_2020_04_14,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRROR_IMAGE,
  SPELL_MISTY_STEP,
  SPELL_MOLD_EARTH,
  SPELL_MOVE_EARTH,
  SPELL_NATHAIR_S_MISCHIEF,
  SPELL_NATHAIR_S_MISCHIEF_2021_04_14,
  SPELL_OTHERWORLDLY_FORM_2020_03_26,
  SPELL_OTILUKE_S_FREEZING_SPHERE,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PLANE_SHIFT,
  SPELL_POISON_SPRAY,
  SPELL_POLYMORPH,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_PAIN,
  SPELL_POWER_WORD_STUN,
  SPELL_PRESTIDIGITATION,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PSIONIC_BLAST_2019_11_25,
  SPELL_PSYCHIC_CRUSH_2019_11_25,
  SPELL_PSYCHIC_SCREAM,
  SPELL_PYROTECHNICS,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
  SPELL_RAY_OF_FROST,
  SPELL_RAY_OF_SICKNESS,
  SPELL_REVERSE_GRAVITY,
  SPELL_RIME_S_BINDING_ICE,
  SPELL_SCATTER,
  SPELL_SCORCHING_RAY,
  SPELL_SEE_INVISIBILITY,
  SPELL_SEEMING,
  SPELL_SHADOW_BLADE,
  SPELL_SHAPE_WATER,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
  SPELL_SICKENING_RADIANCE,
  SPELL_SILENT_IMAGE,
  SPELL_SKILL_EMPOWERMENT,
  SPELL_SLEEP,
  SPELL_SLEET_STORM,
  SPELL_SLOW,
  SPELL_SNILLOC_S_SNOWBALL_SWARM,
  SPELL_SPIDER_CLIMB,
  SPELL_STINKING_CLOUD,
  SPELL_STONESKIN,
  SPELL_STORM_SPHERE,
  SPELL_SUDDEN_AWAKENING_2017_04_03,
  SPELL_SUGGESTION,
  SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
  SPELL_SUMMON_DRACONIC_SPIRIT,
  SPELL_SUMMON_DRACONIC_SPIRIT_2021_04_14,
  SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SUMMON_SHADOW_SPIRIT_2020_03_26,
  SPELL_SUNBEAM,
  SPELL_SUNBURST,
  SPELL_SWORD_BURST,
  SPELL_SYNAPTIC_STATIC,
  SPELL_TASHA_S_CAUSTIC_BREW,
  SPELL_TASHA_S_MIND_WHIP,
  SPELL_TASHA_S_OTHERWORLDLY_GUISE,
  SPELL_TELEKINESIS,
  SPELL_TELEPORT,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_THOUGHT_SHIELD_2019_11_25,
  SPELL_THUNDER_STEP,
  SPELL_THUNDERCLAP,
  SPELL_THUNDERWAVE,
  SPELL_TIDAL_WAVE,
  SPELL_TIME_STOP,
  SPELL_TONGUES,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_VITRIOLIC_SPHERE,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_LIGHT,
  SPELL_WALL_OF_STONE,
  SPELL_WALL_OF_WATER,
  SPELL_WARDING_WIND,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
  SPELL_WATERY_SPHERE,
  SPELL_WEB,
  SPELL_WHIRLWIND,
  SPELL_WISH,
  SPELL_WITCH_BOLT,
} = require('./../../spellIdList')

module.exports = [
  SPELL_ABI_DALZIM_S_HORRID_WILTING,
  SPELL_ABSORB_ELEMENTS,
  SPELL_ACID_SPLASH,
  SPELL_ACID_STREAM_2020_03_26,
  SPELL_AGANAZZAR_S_SCORCHER,
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ARCANE_GATE,
  SPELL_ASHARDALON_S_STRIDE,
  SPELL_BANISHMENT,
  SPELL_BIGBY_S_HAND,
  SPELL_BLADE_OF_DISASTER,
  SPELL_BLADE_WARD,
  SPELL_BLIGHT,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BLINK,
  SPELL_BLUR,
  SPELL_BOOMING_BLADE,
  SPELL_BURNING_HANDS,
  SPELL_CATAPULT,
  SPELL_CATNAP,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CHAOS_BOLT,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CHROMATIC_ORB,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CLAIRVOYANCE,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_CLOUDKILL,
  SPELL_COLOR_SPRAY,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_CONE_OF_COLD,
  SPELL_CONFUSION,
  SPELL_CONTROL_FLAMES,
  SPELL_CONTROL_WINDS,
  SPELL_COUNTERSPELL,
  SPELL_CREATE_BONFIRE,
  SPELL_CREATION,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CROWN_OF_STARS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_DARKVISION,
  SPELL_DAYLIGHT,
  SPELL_DELAYED_BLAST_FIREBALL,
  SPELL_DEMIPLANE,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISINTEGRATE,
  SPELL_DISPEL_MAGIC,
  SPELL_DISTORT_VALUE,
  SPELL_DOMINATE_BEAST,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_DRACONIC_TRANSFORMATION,
  SPELL_DRACONIC_TRANSFORMATION_2021_04_14,
  SPELL_DRAGON_S_BREATH,
  SPELL_DREAM_OF_THE_BLUE_VEIL,
  SPELL_DUST_DEVIL,
  SPELL_EARTH_TREMOR,
  SPELL_EARTHBIND,
  SPELL_EARTHQUAKE,
  SPELL_EGO_WHIP_2019_11_25,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENEMIES_ABOUND,
  SPELL_ENERVATION,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_ERUPTING_EARTH,
  SPELL_ETHEREALNESS,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_EYEBITE,
  SPELL_FALSE_LIFE,
  SPELL_FAR_STEP,
  SPELL_FEAR,
  SPELL_FEATHER_FALL,
  SPELL_FINGER_OF_DEATH,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FIRE_STORM,
  SPELL_FIREBALL,
  SPELL_FIZBAN_S_PLATINUM_SHIELD,
  SPELL_FIZBAN_S_PLATINUM_SHIELD_2021_04_14,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_BLADE,
  SPELL_FLAME_STRIDE_2021_04_14,
  SPELL_FLAMING_SPHERE,
  SPELL_FLESH_TO_STONE,
  SPELL_FLY,
  SPELL_FOG_CLOUD,
  SPELL_FRIENDS,
  SPELL_FROSTBITE,
  SPELL_GASEOUS_FORM,
  SPELL_GATE,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_GREASE,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GREEN_FLAME_BLADE,
  SPELL_GUST,
  SPELL_GUST_OF_WIND,
  SPELL_HASTE,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_ICE_KNIFE,
  SPELL_ICE_STORM,
  SPELL_ICINGDEATH_S_FROST_2021_04_14,
  SPELL_ID_INSINUATION_2019_11_25,
  SPELL_IMMOLATION,
  SPELL_INCENDIARY_CLOUD,
  SPELL_INCITE_GREED,
  SPELL_INFESTATION,
  SPELL_INSECT_PLAGUE,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2019_11_25,
  SPELL_INTELLECT_FORTRESS_2020_04_14,
  SPELL_INVESTITURE_OF_FLAME,
  SPELL_INVESTITURE_OF_ICE,
  SPELL_INVESTITURE_OF_STONE,
  SPELL_INVESTITURE_OF_WIND,
  SPELL_INVISIBILITY,
  SPELL_JUMP,
  SPELL_KNOCK,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_LIGHTNING_BOLT,
  SPELL_LIGHTNING_LURE,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MAGIC_WEAPON,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_POLYMORPH,
  SPELL_MASS_SUGGESTION,
  SPELL_MAXIMILIAN_S_EARTHEN_GRASP,
  SPELL_MELF_S_MINUTE_METEORS,
  SPELL_MENDING,
  SPELL_MENTAL_BARRIER_2019_11_25,
  SPELL_MENTAL_PRISON,
  SPELL_MESSAGE,
  SPELL_METEOR_SWARM,
  SPELL_MIND_SLIVER,
  SPELL_MIND_SPIKE,
  SPELL_MIND_THRUST_2019_11_25,
  SPELL_MIND_THRUST_2020_04_14,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRROR_IMAGE,
  SPELL_MISTY_STEP,
  SPELL_MOLD_EARTH,
  SPELL_MOVE_EARTH,
  SPELL_NATHAIR_S_MISCHIEF,
  SPELL_NATHAIR_S_MISCHIEF_2021_04_14,
  SPELL_OTHERWORLDLY_FORM_2020_03_26,
  SPELL_OTILUKE_S_FREEZING_SPHERE,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PLANE_SHIFT,
  SPELL_POISON_SPRAY,
  SPELL_POLYMORPH,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_PAIN,
  SPELL_POWER_WORD_STUN,
  SPELL_PRESTIDIGITATION,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PSIONIC_BLAST_2019_11_25,
  SPELL_PSYCHIC_CRUSH_2019_11_25,
  SPELL_PSYCHIC_SCREAM,
  SPELL_PYROTECHNICS,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
  SPELL_RAY_OF_FROST,
  SPELL_RAY_OF_SICKNESS,
  SPELL_REVERSE_GRAVITY,
  SPELL_RIME_S_BINDING_ICE,
  SPELL_SCATTER,
  SPELL_SCORCHING_RAY,
  SPELL_SEE_INVISIBILITY,
  SPELL_SEEMING,
  SPELL_SHADOW_BLADE,
  SPELL_SHAPE_WATER,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
  SPELL_SICKENING_RADIANCE,
  SPELL_SILENT_IMAGE,
  SPELL_SKILL_EMPOWERMENT,
  SPELL_SLEEP,
  SPELL_SLEET_STORM,
  SPELL_SLOW,
  SPELL_SNILLOC_S_SNOWBALL_SWARM,
  SPELL_SPIDER_CLIMB,
  SPELL_STINKING_CLOUD,
  SPELL_STONESKIN,
  SPELL_STORM_SPHERE,
  SPELL_SUDDEN_AWAKENING_2017_04_03,
  SPELL_SUGGESTION,
  SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
  SPELL_SUMMON_DRACONIC_SPIRIT,
  SPELL_SUMMON_DRACONIC_SPIRIT_2021_04_14,
  SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SUMMON_SHADOW_SPIRIT_2020_03_26,
  SPELL_SUNBEAM,
  SPELL_SUNBURST,
  SPELL_SWORD_BURST,
  SPELL_SYNAPTIC_STATIC,
  SPELL_TASHA_S_CAUSTIC_BREW,
  SPELL_TASHA_S_MIND_WHIP,
  SPELL_TASHA_S_OTHERWORLDLY_GUISE,
  SPELL_TELEKINESIS,
  SPELL_TELEPORT,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_THOUGHT_SHIELD_2019_11_25,
  SPELL_THUNDER_STEP,
  SPELL_THUNDERCLAP,
  SPELL_THUNDERWAVE,
  SPELL_TIDAL_WAVE,
  SPELL_TIME_STOP,
  SPELL_TONGUES,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_VITRIOLIC_SPHERE,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_LIGHT,
  SPELL_WALL_OF_STONE,
  SPELL_WALL_OF_WATER,
  SPELL_WARDING_WIND,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
  SPELL_WATERY_SPHERE,
  SPELL_WEB,
  SPELL_WHIRLWIND,
  SPELL_WISH,
  SPELL_WITCH_BOLT,
]
