const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_4,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_INCORPOREAL_MOVEMENT,
  ABILITY_SHADOW_STEALTH,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_SHADOW_DEMON,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Теневой демон',
  nameEn: 'Shadow Demon',
  id: CREATURE_SHADOW_DEMON,
  description: [
    `Если тело демона уничтожено, но исчадие не смогло заново воплотиться в Бездне, его сущность может принять частично бесплотную форму. Теневые демоны существуют вне привычной демонической иерархии, так как их появление зачастую идёт от магии смертных, а не посредством превращения или роста. Теневые демоны исчезают во тьме и двигаются, не издавая ни звука. Бесплотными когтями они вырывают и пожирают страхи жертв, пробуют на вкус воспоминания и упиваются сомненьями. Яркий свет — их враг, он являет их настоящую форму, рассеивая размытую тьму и освещая крылатого гуманоида, нижняя часть которого рассеивается дымом, и чьи страшные когти рвут разум жертвы.

# Теневая натура

Теневому демону не нужен воздух, еда, питьё и сон.`,
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 94,
  },
  armor: 13,
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 12,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CHA,
  ],
  vulnerabilityList: [
    DAMAGE_RADIANT,
  ],
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_NECROTIC,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_GRAPPLED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_INCORPOREAL_MOVEMENT,
    {
      name: 'Чувствительность к свету',
      description: `Находясь на ярком свету, ★СУЩЕСТВО★ совершает с помехой броски атаки, а также проверки Мудрости (Внимательность), полагающиеся на зрение.`,
    },
    ABILITY_SHADOW_STEALTH,
  ],
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          [
            {
              type: DAMAGE_PSYCHIC,
              diceCount: 2,
              diceType: 6,
              diceBonus: 3,
            },
            {
              preText: ' если ★СУЩЕСТВО★ ★совершал★ бросок атаки с преимуществом ',
              type: DAMAGE_PSYCHIC,
              diceCount: 4,
              diceType: 6,
              diceBonus: 3,
            },
          ],
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
