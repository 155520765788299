const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  SPELL_LESSER_RESTORATION,
  SPELL_REMOVE_CURSE,
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_YUAN_TI,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_RECKLESS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_YUAN_TI_BROODGUARD} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {yuantiDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Юань-ти страж выводка',
  nameEn: 'Yuan-ti Broodguard',
  id: CREATURE_YUAN_TI_BROODGUARD,
  description: [
    `Стражи выводка — гуманоиды, которых юань-ти превратили в глуповатых, чешуйчатых существ, которые выполняют приказы своих хозяев. Процесс трансформации искажает не только тело, но и ум, заставляя прошедшего его инстинктивно подчиняться любому юань-ти, и наполняться злобой при виде нерептилоидных существ.

Несмотря на то, что у стражей выводка низкий интеллект, они могут выполнять простые, но важные для общества задачи, такие как охрана яиц или патрулирование. Юань-ти называют стражей выводка «хисташии» что значит «хранители яиц».`,
    {
      header: 'Больше не человек',
      text: `Большинство стражей выводка делается из пленённых людей, которых вынуждают пить магическое варево, которое делает их беспомощными и неспособными противостоять неминуемому. Человек, превращённый в стража выводка теряет всякое подобие того, кем был раньше и даже его происхождение едва заметно. Стражи выводка безволосы, поджары, с желтовато-зелёной чешуйчатой шкурой. Их бисеринки глаз налиты кровью, язык раздвоен, и от них слегка пахнет гниющим мясом. Они могут говорить, но редко это делают, предпочитая шипение и гортанные звуки.`,
      source: {
        id: SOURCE_VGTM,
        page: 206,
      },
    },
    {
      header: 'Создание стража выводка',
      text: `Юань-ти создают стражей выводков из захваченных гуманоидов. Каждому из них дается выпить специальное зелье, которое делает его недееспособным и за 1к6 + 6 дней превращает в стража выводка. Существо, которое заставляют выпить зелье, может пройти испытание Телосложения СЛ 15; в случае успеха оно получает 14 (4к6) урона ядом и не подвергается иным воздействиям.

До завершения трансформации заклинания вроде [Малого восстановления] (SPELL:${SPELL_LESSER_RESTORATION}) или [Снятия проклятья](SPELL:${SPELL_REMOVE_CURSE}) могут остановить трансформацию в любое время, пока она не завершена. Если процесс завершен, только заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) может обратить эффект.`,
      source: {
        id: SOURCE_VGTM,
        page: 206,
      },
    },
    ...yuantiDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_YUAN_TI,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 206,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 4,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Ментальное сопротивление',
      description: `Страж выводка имеет преимущество на испытания против очарования и магия не может его парализовать.`,
    },
    ABILITY_RECKLESS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: ` Страж выводка делает три атаки: одну _Укусом_ и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
