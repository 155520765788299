const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_AQUAN,
  LANG_AURAN,
} = require('./../../../../languageIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_MEPHIT,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_ICE_MEPHIT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {mephitDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPELL_FOG_CLOUD} = require('./../../../../spellIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Ледяной мефит',
  nameEn: 'Ice Mephit',
  description: [
    `Ледяные мефиты состоят из холодного воздуха и воды. Они отчуждённые и холодные, и превосходят всех остальных мефитов в беспощадной жестокости.`,
    mephitDescription,
  ],
  id: CREATURE_ICE_MEPHIT,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_MEPHIT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 205,
  },
  armor: 11,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 10,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
    DAMAGE_BLUDGEONING,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AQUAN,
    LANG_AURAN,
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Предсмертная вспышка',
      description: `Когда ★СУЩЕСТВО★ умирает, ★он★ разлетается во все стороны обломками льда. Все существа в пределах 5  футов от него должны пройти испытание Ловкости СЛ  10, получая рубящий урон 4 (1к8) при провале, или половину этого урона при успехе.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычной глыбы льда`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_FOG_CLOUD,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 1,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 1,
            diceType: 4,
          },
        ],
      },
    },
    {
      name: 'Ледяное дыхание',
      restore: 6,
      description: `★СУЩЕСТВО★ выдыхает холодный воздух 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 10, получая урон холодом 5 (2к4) при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
