const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {primordialLanguageDescriptionList} = require('./../../textCommonParts')
const {SOURCE_DND4_DMG} = require('./../../sourceList')
const {
  ALPHABET_DETHEK,
  ALPHABET_PRIMORDIAL,
} = require('./../../alphabetList')
const {
  LANG_ABYSSAL,
  LANG_ALL,
  LANG_GIANT,
  LANG_PRIMORDIAL,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_PRIMORDIAL,
  alphabetId: [
    ALPHABET_DETHEK,
    ALPHABET_PRIMORDIAL,
  ],
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Первичный',
    genitive: 'Первичного',
    instrumental: 'Первичным',
    prepositional: 'Первичном',
  },
  nameAlt: 'Первородный',
  nameEn: 'Primordial',
  description: [
    ...primordialLanguageDescriptionList,
    {
      text: `Первородные имели собственный язык, не имеющий ничего общего с [божественным](LANG:${LANG_ALL}). Титаны и великаны адаптировали изменённую версию этого языка как свой [собственный язык](LANG:${LANG_GIANT}), а [язык Бездны](LANG:${LANG_ABYSSAL}) — это форма Первородного, искажённая и искривлённая злом в сердце Бездны.`,
      source: {
        id: SOURCE_DND4_DMG,
        page: 172,
      },
    },
  ],
  typicalSpeakers: 'Элементали',
  isRealLang: true,
  isReady: true,
}
