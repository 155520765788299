const {
  SPELL_BANISHMENT,
  SPELL_CONE_OF_COLD,
  SPELL_COUNTERSPELL,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DISGUISE_SELF,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FLY,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_IDENTIFY,
  SPELL_INVISIBILITY,
  SPELL_LIGHT,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGIC_MISSILE,
  SPELL_MIND_BLANK,
  SPELL_MIRROR_IMAGE,
  SPELL_MISTY_STEP,
  SPELL_PRESTIDIGITATION,
  SPELL_SCRYING,
  SPELL_SHOCKING_GRASP,
  SPELL_STONESKIN,
  SPELL_TELEPORT,
  SPELL_TIME_STOP,
  SPELL_WALL_OF_FORCE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NONMAGIC,
  DAMAGE_SPELLS,
} = require('./../../../../damageTypeList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_ARCHMAGE,
  CREATURE_LICH,
} = require('./../../../../creatureIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_12} = require('./../../../../crList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Архимаг',
  nameEn: 'Archmage',
  id: CREATURE_ARCHMAGE,
  description: `Архимаги это сильные (и обычно весьма старые) заклинатели, преданные изучению магических искусств. Добрые помогают королям и королевам, а злые сами являются тиранами или же стремятся стать [личами](CREATURE:${CREATURE_LICH}). Те, кто не добрые и не злые, уединяются в башнях, где и практикуют магию, не отвлекаясь на посторонние дела.

Обычно рядом с архимагом находится один или несколько подмастерий, а жилище заполнено многочисленными магическими защитами и стражниками, отбивающими охоту вмешиваться в дела хозяина.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 343,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_DOUBLE,
    [SKILL_HISTORY]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_SPELLS,
    {
      id: DAMAGE_NONMAGIC,
      comment: `от каменной кожи`,
    },
  ],
  languageList: [
    {
      id: LANG_ANY,
      count: 6,
    },
  ],
  cr: CR_12,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    spellCasterLevel: 18,
    spellCasterClass: PC_CLASS_WIZARD,
    infinitySpellIdList: [
      SPELL_DISGUISE_SELF,
      SPELL_INVISIBILITY,
    ],
    spellIdList: [
      SPELL_BANISHMENT,
      SPELL_CONE_OF_COLD,
      SPELL_COUNTERSPELL,
      SPELL_DETECT_MAGIC,
      SPELL_DETECT_THOUGHTS,
      SPELL_FIRE_BOLT,
      SPELL_FIRE_SHIELD,
      SPELL_FLY,
      SPELL_GLOBE_OF_INVULNERABILITY,
      SPELL_IDENTIFY,
      SPELL_LIGHT,
      SPELL_LIGHTNING_BOLT,
      {
        id: SPELL_MAGE_ARMOR,
        alreadyCasted: true,
      },
      SPELL_MAGIC_MISSILE,
      {
        id: SPELL_MIND_BLANK,
        alreadyCasted: true,
      },
      SPELL_MIRROR_IMAGE,
      SPELL_MISTY_STEP,
      SPELL_PRESTIDIGITATION,
      SPELL_SCRYING,
      SPELL_SHOCKING_GRASP,
      {
        id: SPELL_STONESKIN,
        alreadyCasted: true,
      },
      SPELL_TELEPORT,
      SPELL_TIME_STOP,
      SPELL_WALL_OF_FORCE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      3,
      1,
      1,
      1,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
  genderId: GENDER_MALE,
}
