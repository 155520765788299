const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  CR_1_8,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_FEY,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_SLEIGHT_OF_HAND,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_CN,
} = require('./../../../../aligmentList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_BOGGLE,
  CREATURE_FOMORIAN,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Боггл',
  nameEn: 'Boggle',
  id: CREATURE_BOGGLE,
  description: [
    {
      header: 'Богглы',
      text: `Богглы — это маленькие буки из сказок. Они проживают на опушках Страны Фей и их можно найти на Материальном плане, где они прячутся под кроватями и в кладовках, выжидая, чтобы напугать и устрашить народ своим озорством.

Боггл рождается из чувства одиночества, материализующегося в месте, где Страна Фей касается мира поблизости от разумного существа, которое чувствует себя изолированным и брошенным. Например, брошенный ребёнок может непреднамеренно призвать боггла и считать его своим воображаемым другом. Боггл может появиться на чердаке дома одинокого вдовца или в пещере отшельника.`,
      source: {
        id: SOURCE_VGTM,
        page: 129,
      },
    },
    {
      header: 'Докучливые негодники',
      text: `Богглы затевают мелкие розыгрыши для собственного развлечения, проводя время досаждая своим хозяевам. Боггл может побить посуду, спрятать инструменты, издавать звуки пугающие коров и портящие молоко или прятать ребёнка на чердаке. Хотя проделки богглов могут причинять беспокойство или непреднамеренный вред, обычно их целью является баловство, а не разрушение. Если ему что-то будет угрожать, боггл скорее сбежит, чем начнет драку.`,
      source: {
        id: SOURCE_VGTM,
        page: 129,
      },
    },
    {
      header: 'Масляные выделения',
      text: `Боггл выделяет масло из пор и может сделать его скользким или липким. Масло высыхает и исчезает час спустя.`,
      source: {
        id: SOURCE_VGTM,
        page: 129,
      },
    },
    {
      header: 'Искривляющееся пространство',
      text: `Боггл может создать магические проходы для перемещения на короткие расстояния или для воровства вещей, которые по-другому для него недоступны. Чтобы создать такой проход в пространстве, боггл должен находиться рядом с чем-то, имеющим раму, таким как окно или дверь, пространство между прутьями клетки или пространство между кроватью и полом. Проход невидим и исчезает через несколько секунд — этого достаточно богглу для прохода, воровства или нападения через него.`,
      source: {
        id: SOURCE_VGTM,
        page: 129,
      },
    },
    {
      header: 'Ненадёжные союзники',
      text: `Богглы могут стать верными слугами хозяина с сильной волей, а злобные существа, такие как [фоморы](CREATURE:${CREATURE_FOMORIAN}) или карги иногда укрывают богглов в своих логовах. Чернокнижники, которые заключают пакт с архифеей могут так же командовать богглами, а харизматические индивиды, которые делают правильные предложения, заключают временные союзы с этими маленькими шутниками. Скучающий боггл всегда найдёт чем себя развлечь.`,
      source: {
        id: SOURCE_VGTM,
        page: 129,
      },
    },
  ],
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_VGTM,
    page: 129,
  },
  armor: 14,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 13,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SLEIGHT_OF_HAND]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_SYLVAN,
  ],
  cr: CR_1_8,
  featureList: [
    {
      name: 'Масло боггла',
      description: `★СУЩЕСТВО★ выделяет из пор негорючее масло. ★Он★ выбирает — скользкое оно или липкое и может менять консистенцию масла на своей коже с одной на другую бонусным действием.

* **Скользкое масло**. Покрывшись скользким маслом, ★СУЩЕСТВО★ получает преимущество на проверки Ловкости (Акробатика) при попытке выбраться из пут, протиснуться в узкое место и освободиться от захвата.
* **Липкое масло**. Покрывшись липким маслом, ★СУЩЕСТВО★ получает преимущество на проверки Силы (Атлетика), сделанные при захвате и любой проверке способности, сделанной для удержания другого существа, объекта или поверхности. ★СУЩЕСТВО★ также может карабкаться по сложным поверхностям, включая передвижение вниз головой по потолку, без проверок способностей.`,
    },
    {
      name: 'Пространственный разлом',
      description: `Бонусным действием ★СУЩЕСТВО★ может создать невидимый неподвижный проход в видимых в пределах 5 футов ★им★ отверстии или рамке, если они размером не больше 10 футов по любой стороне. Пространственный разлом связывает это пространство и любое другое пространство в пределах 30 футов, которое ★СУЩЕСТВО★ может видеть или описать указаниями направления и расстояния (например, _«30 футов строго вверх»_). Находясь рядом с разломом, ★СУЩЕСТВО★ может видеть сквозь него и считается ★стоящим★ рядом с местом назначения, а всё, что ★СУЩЕСТВО★ перемещает через разлом (включая части своего тела), появляется в точке назначения. Разломом может пользоваться только ★СУЩЕСТВО★, и он существует до конца следующего хода ★СУЩЕСТВО★.`,
    },
    {
      name: 'Сверхъестественный нюх',
      description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), полагающиеся на нюх.`,
    },
  ],
  actionList: [
    {
      name: 'Тумак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 1,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: -1,
        },
      },
    },
    {
      name: 'Лужа',
      description: `★СУЩЕСТВО★ создает лужу масла, которое или скользкое или липкое (по ★его★ выбору). Лужа 1 дюйм в глубину и покрывает пол в ★его★ пространстве. Лужа является труднопроходимой местностью для всех существ, кроме богглов, и существует 1 час.

Если масло скользкое, то любое существо, входящее в область лужи или начинающее свой ход в ней, должно пройти испытание Ловкости СЛ 11 или упасть на землю.

Если масло липкое, то любое существо, входящее в область лужи или начинающее свой ход в ней, должно пройти испытание Силы СЛ 11 или стать Обездвиженным. В свой ход существо может действием попытаться освободиться от липкой лужи проверкой Силы СЛ 11, заканчивая этот эффект и передвигаясь в ближайшее свободное пространство.`,
    },
  ],
}
