const {
  ABILITY_UNARMORED_DEFENSE,
  ABILITY_UNARMORED_MOVEMENT,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_UNARMORED_DEFENSE} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_HURRICANE} = require('./../../../../creatureIdList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GEAR_SHIELD} = require('./../../../../gearIdList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_ACROBATICS} = require('./../../../../skillList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {howlingHatredDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SPELL_BLADE_WARD,
  SPELL_FEATHER_FALL,
  SPELL_GUST,
  SPELL_GUST_OF_WIND,
  SPELL_JUMP,
  SPELL_LIGHT,
  SPELL_PRESTIDIGITATION,
  SPELL_THUNDERWAVE,
} = require('./../../../../spellIdList')
const {
  LANG_AURAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Ураган',
  nameEn: 'Hurricane',
  id: CREATURE_HURRICANE,
  description: [
    {
      header: 'Ураганы',
      text: `Ураганы во всеуслышание заявляют о своём отвращении к этому прогнившему миру и всей душой стремятся его уничтожить. Эти искусные послушники Воющей Ненависти исповедуют строгие, аскетические взгляды, а во время обучения выполняют особые дыхательные упражнения, имитируя цикличные движения воздуха в воронке урагана. Они считают, что существующий мир должен быть разрушен, чтобы существа, населяющие Материальный План, могли жить, не скованные физическими ограничениями.

Ураганы постоянно спокойны, словно находятся в своеобразном «оке бури», и ощущают окружающую их неистовую активность мира в замедленном движении. С помощью медитаций и мистических практик они учатся вызывать мощные порывы ветра, с лёгкостью ловить снаряды и плавно, будто пёрышко, приземляться при падении.`,
      source: {
        id: SOURCE_PotA,
        page: 196,
      },
    },
    ...howlingHatredDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_PotA,
    page: 196,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_UNARMORED_DEFENSE,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 45,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 13,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
  },
  languageList: [
    LANG_AURAN,
    LANG_COMMON,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_UNARMORED_DEFENSE,
    {
      id: ABILITY_UNARMORED_MOVEMENT,
      speed: 15,
    },
  ],
  spellCast: {
    spellCasterLevel: 3,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_BLADE_WARD,
      SPELL_FEATHER_FALL,
      SPELL_GUST,
      SPELL_GUST_OF_WIND,
      SPELL_JUMP,
      SPELL_LIGHT,
      SPELL_PRESTIDIGITATION,
      SPELL_THUNDERWAVE,
    ],
    slotCountList: [
      Infinity,
      4,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Безоружный удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  reactionList: [
    {
      name: 'Отражение снарядов',
      description: `В ответ на попадание по ★нему★ атакой дальнобойным оружием, ★СУЩЕСТВО★ отражает снаряд. Урон от этой атаки уменьшается на 1к10 + 9. Если урон уменьшился до 0, ★СУЩЕСТВО★ может поймать снаряд, если тот достаточно мал, чтобы держать его одной рукой, и у ★СУЩЕСТВО★ есть свободная рука.`,
    },
  ],
}
