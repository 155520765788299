const {FEATURE_PATH_OF_TRANQUILITY} = require('./../../../featureIdList')
const {PC_SUBCLASS_MONK_WAY_OF_TRANQUILITY_2016_12_12} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2016_12_12_MONK_MONASTIC_TRADITIONS} = require('./../../../sourceList')
const {SPELL_SANCTUARY} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_PATH_OF_TRANQUILITY,
    name: `Стезя спокойствия`,
    nameEn: `Path of Tranquility`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_TRANQUILITY_2016_12_12,
    spellIdList: SPELL_SANCTUARY,
    source: {
      id: SOURCE_UA_2016_12_12_MONK_MONASTIC_TRADITIONS,
      page: 2,
    },
  },
]
