const {CAT_DRUID_FOCUS} = require('./../../../gearCategoryList')
const {GEAR_YEW_WAND} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')

module.exports = {
  id: GEAR_YEW_WAND,
  genderId: GENDER_FEMALE,
  name: 'Тисовая палочка',
  nameByCase: {
    nominative: 'тисовая палочка',
    genitive: 'тисовой палочки',
    accusative: 'тисовая палочку',
    instrumental: 'тисовой палочкой',
  },
  nameEn: 'Yew wand',
  description: 'Палочка из тиса или другого дерева. Друид может использовать этот предмет в качестве фокусировки для заклинаний.',
  cost: 1000,
  weight: 1,
  source: {
    id: SOURCE_PHB,
    page: 150,
  },
  category: CAT_DRUID_FOCUS,
}
