const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ABILITY_KEEN_SMELL,
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_TROLL,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_VENOM_TROLL} = require('./../../../../creatureIdList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {trollDescription} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Ядовитый тролль',
  nameEn: 'Venom Troll',
  id: CREATURE_VENOM_TROLL,
  description: [
    `Тролль, получивший крупную дозу яда, может мутировать в ядовитого тролля. Впитавшийся яд наполняет его кровь и плоть, он же течёт из его пор, покрывая клыки и когти. Эти существа особенно опасны в ближнем бою, потому что яд сочится из их плоти и течёт из каждой полученной раны.`,
    ...trollDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_TROLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 234,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 20,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_7,
  featureList: [
    ABILITY_KEEN_SMELL,
    {
      name: 'Ядовитый всплеск',
      description: `Когда ★СУЩЕСТВО★ получает урон любого типа помимо психической энергии, каждое существо в пределах 5 футов от ★него★ получает 9 (2к8) урона ядом.`,
    },
    {
      id: ABILITY_REGENERATION,
      regen: 10,
      other: `. Если ★он★ получает повреждения огнём или кислотой, регенерация не срабатывает в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только если начинает ход с 0 хитов и не может регенерировать`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_ и две _Когтями_.`,
    },
    {
      name: 'Укус',
      description: `Если цель — существо, то оно становится отравленным до начала следующего хода тролля.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 1,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 1,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Ядовитые брызги',
      restore: 6,
      description: `Тролль раздирает себя когтями, брызгая ядом в 15-футовом конусе. Тролль получает 7 (2к6) рубящего урона (этот урон не может быть снижен никаким способом). Каждое существо, попавшее в конус, должно пройти испытание Телосложения СЛ 16. При провале существо получает 18 (4к8) урона ядом и отравляется на 1 минуту. При успехе существо получает половину этого урона и не отравляется. Отравленное существо может повторять испытание в конце каждого своего хода, завершая эффект при успехе.`,
    },
  ],
}
