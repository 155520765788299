const {SOURCE_PHB} = require('./../../sourceList')
const {
  PC_RACE_DRAGONBORN,
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GNOME,
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
  PC_RACE_TIEFLING,
} = require('./../../pcRaceIdList')

module.exports = {
  header: `Необычные расы`,
  text: `Драконорождённые и все последующие расы из этого раздела являются необычными. Они существуют не во всех мирах D&D, и даже там, где они присутствуют, они распространены гораздо меньше, чем [дварфы](PC_RACE:${PC_RACE_DWARF}), [люди](PC_RACE:${PC_RACE_HUMAN}), [полурослики](PC_RACE:${PC_RACE_HALFLING}) и [эльфы](PC_RACE:${PC_RACE_ELF}).

В многонациональных городах мультивселенной D&D большинство обитателей вряд ли заинтересуется представителем даже самой экзотической расы, Но в маленьких городках и деревнях всё по-другому. Обычные люди не привыкли видеть представителей таких рас, и будут вести себя соответственно.

# [Гномы](PC_RACE:${PC_RACE_GNOME})

Гномы не выглядят опасными, и могут хорошей шуткой быстро разрушить подозрения. Обычные люди относятся к ним с любопытством, если никогда их прежде не видели, и вряд ли будут враждебны к ним.

# [Драконорождённые](PC_RACE:${PC_RACE_DRAGONBORN})

Легко предположить, что драконорождённые это чудовища, особенно если цвет их чешуи выдаёт их родство с цветными драконами. Однако пока драконорождённый не начинает дышать огнём и сеять разрушение, большинство людей скорее проявят интерес, чем открытый страх.

# [Полуорки](PC_RACE:${PC_RACE_HALF_ORC})

Принято считать, что полуорки агрессивны и легко впадают в ярость, так что люди ведут себя осторожно в присутствии незнакомого полуорка. Торговцы могут тайком прятать наиболее ценные или хрупкие товары при появлении полуорка, и люди потихоньку покидают таверны, опасаясь возможной драки.

# [Полуэльфы](PC_RACE:${PC_RACE_HALF_ELF})

Несмотря на то, что большинство людей никогда не видели полуэльфа, каждый знает, что они существуют. Появление незнакомого полуэльфа скорее сопровождается перешёптыванием и бросаемыми исподтишка взглядами, чем открытой заинтересованностью или враждебностью.

# [Тифлинги](PC_RACE:${PC_RACE_TIEFLING})

Если полуорков встречают с обоснованными опасениями, то тифлинги вызывают сверхъестественный страх. Зло оставило такой ясный отпечаток на их внешности, что многие полагают, будто тифлинги могут оказаться настоящими чертями прямо из Девяти Преисподних. Люди делают ограждающие от зла знаки при их приближении, переходят на другую сторону улицы или запирают двери при их приближении.`,
  source: {
    id: SOURCE_PHB,
    page: 33,
  },
}
