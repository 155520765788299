const {
  SOURCE_MTOF,
} = require('./../../../../sourceList')
const {
  CR_3,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_GIFF,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_WIZARD,
} = require('./../../../../pcClassIdList')
const {
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_BREASTPLATE,
  GEAR_GRENADE_FRAGMENTATION,
  GEAR_GUNPOWDER_KEG,
  GEAR_LONGSWORD,
  GEAR_MUSKET,
  GEAR_PISTOL,
} = require('./../../../../gearIdList')
const {
  giffDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_GIFF,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гифф',
  nameEn: 'Giff',
  id: CREATURE_GIFF,
  description: [
    ...giffDescriptionList,
    {
      header: 'Военная организация',
      text: `Каждый аспект общества гиффов построен по воинскому образцу. С рождения и до самой смерти у каждого гиффа есть воинское звание. Гифф обязан подчиняться приказам высших чинов, и отдаёт приказы гиффам низших рангов. Повышение не зависит от возраста, но поощряется начальством в качестве награды за отвагу. Гиффы посвящают часть своей жизни своим детям, обучая их искусству войны и боевым навыкам, которые пригодятся в бою.`,
      source: {
        id: SOURCE_MTOF,
        page: 128,
      },
    },
    {
      header: 'Необычайные наёмники',
      text: `Гиффы пользуются большим спросом в качестве воинов по найму, но они упорно настаивают в службе в группах, состоящих только из гиффов; ещё не было такого, чтобы гифф нанялся единолично. Гифф отказывается сражаться против другого гиффа, и никогда не согласится развязывать войну против своих сородичей. Гифф ценит авторитет своего отряда больше, чем свою жизнь. Жизнь коротка, но режим должен оставаться поколениями или даже веками.`,
      source: {
        id: SOURCE_MTOF,
        page: 128,
      },
    },
    {
      header: 'Запах пороха',
      text: `Любимым оружием каждого гиффа являются [мушкеты](GEAR:${GEAR_MUSKET}) и [гранаты](GEAR:${GEAR_GRENADE_FRAGMENTATION}). Чем больше взрыв, тем ярче вспышка, и чем гуще дым от взрыва, тем больше гифф любит оружие. Их навыки обращения с порохом — очередная причина их популярности в качестве наёмников. Гиффы наслаждаются риском построить бомбу, достаточно большую, чтобы снести укрепления. Они рады принять плату в виде [пороховых бочек](GEAR:${GEAR_GUNPOWDER_KEG}), нежели в виде золота, драгоценных камней или любого другого вида платы.`,
      source: {
        id: SOURCE_MTOF,
        page: 128,
      },
    },
    {
      header: 'В магии нет чести',
      text: `Некоторые гиффы становятся [волшебниками](PC_CLASS:${PC_CLASS_WIZARD}), [жрецами](PC_CLASS:${PC_CLASS_CLERIC}) и другими видами заклинателей, но они достаточно редки, и поэтому у многих гиффов наёмников отсутствуют какие-либо магические способности. Обычные гиффы разумны, как и обычный человек, но их сосредоточение на военных тренировках, в отличии от прочих сфер жизни делает их немного глуповатыми перед теми, у кого развиты прочие интересы.`,
      source: {
        id: SOURCE_MTOF,
        page: 128,
      },
    },
    {
      header: 'Порох в бочке',
      text: `Помимо своего собственных пороховых орудий, корабли гиффов и наёмных организаций перевозят лишний порох в бочках. В экстренных случаях или, когда нужен большой взрыв, можно подорвать целую [бочку](GEAR:${GEAR_GUNPOWDER_KEG}). Гифф поджигает фитиль и затем бросает его на расстояние большее или равное 15 футов как часть этого же действия. Бочка взрывается в начале следующего хода гиффа. Каждое существо на расстоянии 20 футов от взрывающейся бочки должно пройти испытание Ловкости СЛ 12. При провале существо получает 24 (7к6) урона огнём и сбивается с ног. При успехе, существо получает половину урона и не сбивается с ног.

У каждого другого [бочонка пороха](GEAR:${GEAR_GUNPOWDER_KEG}) в пределах 20 футов от взрывающегося бочонка есть вероятность взрыва 50%. Проверяйте каждый бочонок только один раз за ход, независимо от того, сколько других бочонков взрывается вокруг него.`,
      source: {
        id: SOURCE_MTOF,
        page: 128,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_GIFF,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 128,
  },
  armor: {
    ac: 16,
    gearId: GEAR_BREASTPLATE,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 17,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 12,
  },
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Таран головой',
      description: `★СУЩЕСТВО★ может попытаться сбить существо с ног. Если гифф передвигался, как минимум, на 20 футов по прямой линии, заканчивающейся за 5 футов до существа Большого размера или меньше, то это существо должно пройти испытание Силы СЛ 14, иначе оно получит 7 (2к6) дробящего урона и будет сбито с ног.`,
    },
    {
      name: 'Знатоки огнестрельного оружия',
      description: `Гиффы — мастера обращения с огнестрельным оружием, что позволяет им игнорировать правила заряжания [пистолей](GEAR:${GEAR_PISTOL}) и [мушкетов](GEAR:${GEAR_MUSKET}).`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ дважды стреляет из _Пистоля_.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      gearId: GEAR_MUSKET,
    },
    {
      gearId: GEAR_PISTOL,
    },
    {
      name: 'Осколочная граната',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ бросает гранату на расстояние до 60 фт. Каждое существо в пределах 20 футов от места детонации гранаты должно пройти испытание Ловкости СЛ 15, получая 17 (5к6) колющего урона в случае провала, и половину этого урона при успехе.`,
    },
  ],
}
