const {PC_SUBCLASS_ROGUE_THIEF} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  GEAR_LOCK,
  GEAR_THIEVES_TOOLS,
} = require('./../../../gearIdList')
const {
  FEATURE_CUNNING_ACTION,
  FEATURE_FAST_HANDS,
  FEATURE_SECOND_STORY_WORK,
  FEATURE_SUPREME_SNEAK,
  FEATURE_THIEF_S_REFLEXES,
  FEATURE_USE_MAGIC_DEVICE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_FAST_HANDS,
    name: `Быстрые руки`,
    nameEn: `Fast Hands`,
    lvl: 3,
    text: `Вы можете бонусным действием, предоставленным Вашим [Хитрым действием](FEATURE:${FEATURE_CUNNING_ACTION}), совершить проверку Ловкости (Ловкость рук), использовать [Воровские инструменты](GEAR:${GEAR_THIEVES_TOOLS}), чтобы обезвредить ловушку или вскрыть [замок](GEAR:${GEAR_LOCK}), или же совершить действие Использование предмета.`,
  },
  {
    id: FEATURE_SECOND_STORY_WORK,
    name: `Форточник`,
    nameEn: `Second-Story Work`,
    lvl: 3,
    text: `Вы получаете возможность лазать быстрее, чем обычно; лазание больше не стоит Вам дополнительного движения. Кроме того, когда Вы совершаете прыжок с разбега, расстояние, которое Вы преодолеваете, увеличивается на число футов, равное Вашему модификатору Ловкости.`,
  },
  {
    id: FEATURE_SUPREME_SNEAK,
    name: `Высокая скрытность`,
    nameEn: `Supreme Sneak`,
    lvl: 9,
    text: `Вы совершаете с преимуществом проверки Ловкости (Скрытность), если в этом ходу перемещались не более чем на половину своей скорости.`,
  },
  {
    id: FEATURE_USE_MAGIC_DEVICE,
    name: `Использование магических предметов`,
    nameEn: `Use Magic Device`,
    lvl: 13,
    text: `Вы узнаёте достаточно о магических процессах, чтобы импровизировать при использовании предметов, даже если раньше Вы не умели ими пользоваться.

Вы игнорируете все требования класса, расы и уровня при использовании магических предметов.`,
  },
  {
    id: FEATURE_THIEF_S_REFLEXES,
    name: `Воровские рефлексы`,
    nameEn: `Thief's Reflexes`,
    lvl: 17,
    text: `Вы обретаете большой опыт организации засад и быстрого отступления при опасности.

Вы можете совершить два хода во время первого раунда боя. Совершите первый ход согласно своей нормальной инициативе, и второй ход с инициативой на 10 меньше. Вы не можете использовать это умение, если захвачены врасплох.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_THIEF,
    source: {
      id: SOURCE_PHB,
      page: 98,
    },
  })
)

