const {PC_SUBCLASS_MONK_WAY_OF_THE_FOUR_ELEMENTS} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {SPELL_BURNING_HANDS} = require('./../../../spellIdList')
const {
  FEATURE_CASTING_ELEMENTAL_SPELLS,
  FEATURE_DISCIPLE_OF_THE_ELEMENTS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_DISCIPLE_OF_THE_ELEMENTS,
    name: `Адепт стихий`,
    nameEn: `Disciple of the Elements`,
    lvl: [3, 6, 11, 17],
    text: `Вы изучаете практики, позволяющие управлять четырьмя стихиями. Эти практики требуют использования очков Ци. Вы знаете практику _Родство со стихией_ и ещё одну на свой выбор. Все практики описаны ниже. Вы изучите дополнительные практики на 6, 11 и 17 уровнях.

Каждый раз, когда Вы изучаете новую практику, Вы можете также заменить одну из ранее изученных практик другой, на свой выбор.`,
    listAllElementalDisciplines: true,
    source: {
      id: SOURCE_PHB,
      page: 81,
    },
  },
  {
    id: FEATURE_CASTING_ELEMENTAL_SPELLS,
    name: `Накладывание стихийных заклинаний`,
    nameEn: `Casting Elemental Spells`,
    lvl: [3, 5, 9, 13, 17],
    text: `Некоторые стихийные практики позволяют Вам сотворять заклинания. При использовании этих заклинаний Вы используете их обычное время накладывания и прочие правила, но Вам не нужны материальные компоненты.

При достижении 5 уровня в этом классе Вы можете тратить очки Ци для усиления заклинаний, полученных благодаря стихийным практикам, если эти заклинания увеличивают свою эффективность с повышением круга, как, например, [Огненные ладони](SPELL:${SPELL_BURNING_HANDS}). Круг заклинания увеличивается на 1 за каждое потраченное очко Ци. Например, если Вы — монах 5 уровня, и используете _Испепеляющий удар_ для накладывания заклинания [Огненные ладони](SPELL:${SPELL_BURNING_HANDS}), Вы можете потратить 3 очка Ци, чтобы создать его как заклинание 2 круга (базовая стоимость использования этой практики 2 очка Ци, плюс ещё 1).

Максимальное количество очков Ци, которое может быть потрачено на одно заклинание, определяется Вашим уровнем монаха и указано в таблице. Оно включает как базовую стоимость, так и дополнительно потраченные очки Ци.

# Заклинания и очки Ци

| Уровень монаха | Максимальное количество Ци на заклинание |
|----------------|------------------------------------------|
|  5–8           | 3                                        |
|  9–12          | 4                                        |
| 13–16          | 5                                        |
| 17–20          | 6                                        |
`,
    source: {
      id: SOURCE_PHB,
      page: 81,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_THE_FOUR_ELEMENTS,
  })
)
