const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_TABAXI,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ATHLETICS,
    SKILL_PERCEPTION,
    SKILL_STEALTH,
    SKILL_SURVIVAL,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_CG,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_CLIMB,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    LANG_ANY,
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
    GEAR_LEATHER_ARMOR,
    GEAR_SHORTBOW,
    GEAR_SHORTSWORD,
  } = require('./../../../../gearIdList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_TABAXI_HUNTER,
  } = require('./../../../../creatureIdList')
const {tabaxiDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Табакси-охотник',
  nameEn: 'Tabaxi Hunter',
  id: CREATURE_TABAXI_HUNTER,
  description: tabaxiDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_TABAXI,
  ],
  alignmentId: ALIGNMENT_CG,
  source: {
    id: SOURCE_TOA,
    page: 239,
  },
  armor: {
    ac: 14,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 20,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 11,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ANY,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Кошачье проворство',
      description: `Когда ★СУЩЕСТВО★ двигается в бою в свой ход, он может удвоить свою скорость до конца хода. Использовав эту способность, ★СУЩЕСТВО★ не можете использовать её снова, до тех пор, пока не переместится на 0 футов за один из своих ходов.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Когтями_, _Коротким мечом_ или _Коротким луком_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
        },
      },
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_SHORTBOW,
    },
  ],
}
