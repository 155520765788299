const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_CROSSBOW_HAND,
  GEAR_SCIMITAR,
  GEAR_WHIP,
} = require('./../../../../gearIdList')
const {
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_FAERIE_FIRE,
  SPELL_LEVITATE,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_PARRY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  drowDescription,
  drowNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_DROW_HOUSE_CAPTAIN,
  CREATURE_DROW_MATRON_MOTHER,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_9} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дроу капитан дома',
  nameEn: 'Drow House Captain',
  id: CREATURE_DROW_HOUSE_CAPTAIN,
  description: [
    `Каждый благородный дом дроу поручает командование своими военными силами капитану дома, звание обычно присваиваемое первому или второму сыну [верховной матери](CREATURE:${CREATURE_DROW_MATRON_MOTHER}). Капитан дома отдаёт приказы другим дроу и рабам, формируя тем самым семейную армию, и тщательно изучает стратегии и тактические действия, становясь эффективным лидером в бою.`,
    ...drowDescription,
  ],
  note: drowNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 167,
  },
  armor: {
    ac: 16,
    gearId: GEAR_CHAIN_MAIL,
  },
  hp: {
    diceCount: 25,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 15,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ELVEN,
    LANG_UNDERCOMMON,
  ],
  cr: CR_9,
  featureList: [
    {
      name: 'Боевая команда',
      description: `Бонусным действием дроу делает целью одного союзника, которого он может видеть в пределах 30 фт. от себя. Если союзник может видеть или слышать дроу, он использует Реакцию для совершения одной рукопашной атаки, или применения Уклонения или Засады.`,
    },
    ABILITY_FEY_ANCESTRY,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DANCING_LIGHTS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DARKNESS,
          SPELL_FAERIE_FIRE,
          {
            id: SPELL_LEVITATE,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Дроу совершает три атаки: две _Скимитаром_ и одну _Хлыстом_ или _Ручным арбалетом_.`,
    },
    {
      gearId: GEAR_SCIMITAR,
      attack: {
        hit: {
          type: DAMAGE_POISON,
          diceCount: 4,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
    {
      gearId: GEAR_WHIP,
      description: `Если цель является союзником, то она получает Преимущество на броски атаки до конца её следующего хода.`,
    },
    {
      gearId: GEAR_CROSSBOW_HAND,
      description: `Цель должна пройти испытание Телосложения СЛ 13, или станет Отравленной на 1 час. Если испытание провалено на 5 и больше, то цель в дополнение к первичному эффекту становится бессознательной. К цели возвращается сознание, если она получила урон или её привели в себя Действием.`,
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 3,
    },
  ],
}
