const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_GLOOM_STALKER} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  SPELL_DISGUISE_SELF,
  SPELL_FEAR,
  SPELL_GREATER_INVISIBILITY,
  SPELL_ROPE_TRICK,
  SPELL_SEEMING,
} = require('./../../../spellIdList')

const {
  FEATURE_DREAD_AMBUSHER,
  FEATURE_GLOOM_STALKER_SPELLS,
  FEATURE_IRON_MIND,
  FEATURE_SHADOWY_DODGE,
  FEATURE_STALKER_S_FLURRY,
  FEATURE_UMBRAL_SIGHT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_GLOOM_STALKER_SPELLS,
    name: `Заклинания Сумрачного охотника`,
    nameEn: `Gloom Stalker Spells`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_DISGUISE_SELF,
      SPELL_ROPE_TRICK,
      SPELL_FEAR,
      SPELL_GREATER_INVISIBILITY,
      SPELL_SEEMING,
    ],
  },
  {
    id: FEATURE_DREAD_AMBUSHER,
    name: `Угроза из засады`,
    nameEn: `Dread Ambusher`,
    lvl: 3,
    text: `Вы овладеваете мастерством засад.

Вы можете добавить к броску инициативы равный Вашему модификатору Мудрости.

В начале первого хода боя Ваша скорость хода увеличивается на 10 футов до конца этого хода. Если Вы используете действие Атаки в этот ход Вы можете сделать одну дополнительную атаку оружием, как часть этого действия. Если эта атака попадает, цель получает дополнительно 1к8 урона, такого же типа, что и урон оружия.`,
  },
  {
    id: FEATURE_UMBRAL_SIGHT,
    name: `Теневой взор`,
    nameEn: `Umbral Sight`,
    lvl: 3,
    text: `Вы получаете Тёмное зрение 60 футов. Если Вы имеете Тёмное зрение от Вашей расы, его дистанция увеличивается на 30 футов.

Вы также сведущи в уклонении от существ, которые полагаются на Тёмное зрение. Находясь во тьме, Вы невидимы для любого существа, полагающегося на Тёмное зрение, чтобы увидеть Вас в этой темноте.`,
  },
  {
    id: FEATURE_IRON_MIND,
    name: `Железный разум`,
    nameEn: `Iron Mind`,
    lvl: 7,
    text: `Вы отточили технику сопротивления ментальным способностям Вашей добычи.

Вы получаете владение испытаниями Мудрости. Если у Вас уже есть это владение, Вы вместо этого можете получить владение испытаниями Интеллекта или Харизмы (на Ваш выбор).`,
  },
  {
    id: FEATURE_STALKER_S_FLURRY,
    name: `Охотничья ярость`,
    nameEn: `Stalker’s Flurry`,
    lvl: 11,
    text: `Вы научились атаковать с такой неожиданной скоростью, что сможете превратить промах в очередной удар.

Один раз в каждый из своих ходов когда Вы промахиваетесь атакой оружием Вы можете совершить частью этого же действия ещё одну атаку оружием.`,
  },
  {
    id: FEATURE_SHADOWY_DODGE,
    name: `Теневое уклонение`,
    nameEn: `Shadowy Dodge`,
    lvl: 15,
    text: `Вы можете уклоняться непредвиденными способами, окружая себя пучками теней.

Всякий раз, когда существо совершает атаку против Вас и не имеет преимущества на бросок, Вы можете реакцией наложить на него помеху. Эту особенность необходимо использовать до того, как будет известен результат атаки.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_RANGER_GLOOM_STALKER,
    source: {
      id: SOURCE_XGTE,
      page: 56,
    },
  })
)
