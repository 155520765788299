const {
  CREATURE_EARTH_ELEMENTAL,
  CREATURE_FIRE_ELEMENTAL,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_CONJURE_ELEMENTAL,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_CONJURE_ELEMENTAL,
  name: 'Призыв элементаля',
  nameEn: 'Conjure Elemental',
  description: `Вы вызываете прислуживающего элементаля. Выберите область воды, воздуха, земли или огня, помещающуюся в куб с длиной ребра 10 фт. В свободном пространство в пределах 10 клеток от выбранной области появляется соответствующий местности элементаль с показателем опасности 5 или меньше. Например, из костра выходит [огненный элементаль](CREATURE:${CREATURE_FIRE_ELEMENTAL}), а [земляной элементаль](CREATURE:${CREATURE_EARTH_ELEMENTAL}) выбирается из-под земли. Элементаль исчезает, когда его хиты опускаются до 0, или когда заклинание оканчивается.

Элементаль дружественен к Вам и Вашим спутникам, пока заклинание активно. Совершите проверку инициативы за элементаля, и он будет совершать свои собственные ходы. Он подчиняется отданным Вами устно командам (действие не требуется). Если Вы не отдаёте команду, он защищается от враждебных существ, но действий не совершает.

Если Ваша концентрация нарушена, элементаль не исчезает. Вместо этого Вы теряете над ним контроль, и он становится враждебным к Вам и Вашим спутникам, и может напасть. Неуправляемый элементаль не может быть отпущен Вами, но он исчезает через 1 час после того, как был призван.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, показатель опасности увеличивается на 1 за каждый круг ячейки выше пятого.`,
  lvl: 5,
  magicSchoolId: MAGIC_CONJURATION,
  castTime: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'вода и песок для водяного, горящее благовоние для воздушного, мягкая глина для земляного или сера и фосфор для огненного',
  duration: { timeId: TIME_HOUR, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 267,
    },
    {
      id: SOURCE_SRD,
      page: 128,
    },
  ],
}
