const {SOURCE_PotA} = require('./../../sourceList')

module.exports = {
  header: 'Культисты Чёрной Земли',
  text: `Убийцы, безумцы и полные ненависти нигилисты — именно такие люди склонны поклоняться могуществу Элементального Зла. Последователи злого аспекта земной стихии называют себя культом Чёрной Земли и грезят о том дне, когда землетрясения превратят мир смертных в руины. Они привлекают на свою сторону злых и кровожадных чудовищ, которые живут в земле и могут в ней перемещаться, и, насколько это возможно, подражают подземным существам, используя магию, чтобы обустраивать в земной тверди свои скрытые логова.

Служители культа Чёрной Земли тяжелы на подъём, но когда они начинают действовать, то отбрасывают прочь всякую жалость. Они целеустремлённы, упрямы и готовы неустанно трудиться, чтобы сокрушить своих врагов. Они хорошо ладят с культом Сокрушительной Волны, но ненавидят культ Воющей Ненависти. Последователи стихийного воздуха, на их взгляд, слишком непостоянны и легкомысленны`,
  source: {
    id: SOURCE_PotA,
    page: 200,
  },
}
