const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1_2,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_PLANT,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_BLINDED,
  CONDITION_DEAFENED,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  mushroomsDescription,
} = require('./../../../../textCommonParts')
const {
  CREATURE_BEHOLDER,
  CREATURE_GAS_SPORE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Газовая спора',
  nameEn: 'Gas Spore',
  id: CREATURE_GAS_SPORE,
  description: [
    `Первые газовые споры, как считается, появились из мёртвых [злобоглазов](CREATURE:${CREATURE_BEHOLDER}), чьи гниющие трупы напитали грибы-паразиты искажённой магией. Прошло много времени, и газовые споры превратились в отдельное растение, быстро и целеустремлённо растущее уже безо всяких трупов, создавая злобно выглядящую насмешку над самым страшным обитателем Подземья.

# Форма злобоглаза

Газовая спора — это сферический пузыревидный гриб, напоминающий издалека [злобоглаза](CREATURE:${CREATURE_BEHOLDER}), однако его истинная натура становится все более очевидна по мере приближения к нему. У чудовища есть слепой центральный «глаз» и отростки на верхней части, на первый взгляд напоминающие глаза [злобоглаза](CREATURE:${CREATURE_BEHOLDER}).

# Предсмертный хлопок

Газовая спора это пустая оболочка, наполненная газом легче воздуха, который позволяет ей висеть в воздухе как [злобоглаз](CREATURE:${CREATURE_BEHOLDER}). Если пронзить спору даже слабейшей атакой, она лопается, выпуская облако смертоносных спор. Существо, которое вдохнёт их, становится носителем и может умереть всего лишь за один день. Его труп при этом становится пищей, из которой возникает новая газовая спора.

# Воспоминания злобоглаза

Газовая спора, возникшая из трупа [злобоглаза](CREATURE:${CREATURE_BEHOLDER}), иногда перенимает в себя часть воспоминаний своего погибшего прародителя. Когда она взрывается, смертоносные споры распространяют эти воспоминания. Вдохнувшее такую спору существо, если оно выживет, получает часть воспоминаний [злобоглаза](CREATURE:${CREATURE_BEHOLDER}), и может узнать о его логове, окружающей местности или её обитателях.`,
    mushroomsDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 74,
  },
  armor: 5,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 10,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 5,
    [PARAM_DEX]: 1,
    [PARAM_CON]: 3,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 1,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_BLINDED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
      isBlindOutside: true,
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Предсмертный взрыв',
      description: `★СУЩЕСТВО★ взрывается, когда её хиты опускаются до 0. Все существа в пределах 20 футов от неё должны пройти испытание Телосложения СЛ 15, иначе получат урон ядом 10 (3к6) и заболеют при провале. Существа, обладающие иммунитетом к отравленному состоянию, обладают и иммунитетом к болезни.

Споры населяют организм существа, и убивают его через количество часов, равное 1к12 + значение Телосложения существа, если болезнь до этого времени не будет вылечена. Когда пройдёт половина этого срока, существо станет отравленным до конца длительности болезни. После того, как существо умирает, из него вылетают 2к4 Крохотные газовые споры, которые вырастают до полного размера за 7 дней.`,
    },
    {
      name: 'Жуткое сходство',
      description: `★СУЩЕСТВО★ выглядят как злобоглазы. Существо, видящее ★СУЩЕСТВО★, может понять её истинную природу, если совершит успешную проверку Интеллекта (Природа) СЛ 15.`,
    },
  ],
  actionList: [
    {
      name: 'Касание',
      description: `Существо должно пройти испытание Телосложения СЛ 10, иначе будет поражено болезнью, описанной в тексте_ Предсмертного взрыва_.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 0,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_POISON,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
