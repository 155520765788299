const {SOURCE_PotA} = require('./../../sourceList')

module.exports = {
  header: 'Стихийное оружие',
  text: `Пророки, возглавляющие культы Стихийного Зла, вооружены могучим волшебным оружием. Каждое такое оружие содержит в себе сущность одного из Принцев Стихийного Зла. Это оружие не только помогает пророкам в битвах, но и служит ключом, благодаря которому они могут использовать силу стихийных узлов и создавать бреши в барьере между планами. Однако вместе с этим оружие пробуждает в своих хозяевах их худшие черты и склоняет их ко злу.`,
  source: {
    id: SOURCE_PotA,
    page: 229,
  },
}
