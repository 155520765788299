const {
  SOURCE_ERFTLW,
  SOURCE_MM,
} = require('./../../sourceList')
const {
  PC_RACE_HALFLING,
} = require('./../../pcRaceIdList')

module.exports = [
  {
    header: 'Динозавры',
    text: `Динозавры, или чудища, это старейшие рептилии в мире. Хищные динозавры — жестокие территориальные охотники. Травоядные рептилии менее агрессивны, но могут атаковать вас, защищая свой молодняк, или если напуганы притеснениями.

Динозавры бывают разных форм и размеров. Крупные разновидности зачастую имеют тусклую окраску, в то время как меньшие динозавры — яркие и красочные, как птицы. Динозавры обитают в отдалённых и изолированных районах, редко посещаемых людьми, включая удалённые горные долины, недоступные плато, тропические острова и глубокие топи.`,
    source: {
      id: SOURCE_MM,
      page: 96,
    },
  },
  {
    header: 'Динозавры',
    text: `Динозавры широко распространены по Эберрону, особенно в Ку’Барре, равнинах Таленты, Зен’Дрике и Аргонессене. В Ку’Барре и равнинах Таленты доминируют малые динозавры. Некоторые из них используются [полуросликами](PC_RACE:${PC_RACE_HALFLING}) Таленты как скакуны.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 290,
    },
  },
]
