const {
  CREATURE_BAT,
  CREATURE_CAT,
  CREATURE_CRAB,
  CREATURE_FROG,
  CREATURE_HAWK,
  CREATURE_LIZARD,
  CREATURE_OCTOPUS,
  CREATURE_OWL,
  CREATURE_POISONOUS_SNAKE,
  CREATURE_QUIPPER,
  CREATURE_RAT,
  CREATURE_RAVEN,
  CREATURE_SEA_HORSE,
  CREATURE_SPIDER,
  CREATURE_TOAD,
  CREATURE_WEASEL,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_FIND_FAMILIAR,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FIND_FAMILIAR,
  name: 'Поиск фамильяра',
  nameEn: `Find Familiar`,
  description: `Вы получаете в услужение фамильяра — духа, принимающего облик выбранного Вами животного.
    
* [Ворон](CREATURE:${CREATURE_RAVEN})
* [Жаба](CREATURE:${CREATURE_TOAD})
* [Кошка](CREATURE:${CREATURE_CAT})
* [Краб](CREATURE:${CREATURE_CRAB})
* [Крыса](CREATURE:${CREATURE_RAT})
* [Летучая мышь](CREATURE:${CREATURE_BAT})
* [Лягушка](CREATURE:${CREATURE_FROG})
* [Морской конёк](CREATURE:${CREATURE_SEA_HORSE})
* [Осьминог](CREATURE:${CREATURE_OCTOPUS})
* [Паук](CREATURE:${CREATURE_SPIDER})
* [Пиранья](CREATURE:${CREATURE_QUIPPER})
* [Сова](CREATURE:${CREATURE_OWL})
* [Хорёк](CREATURE:${CREATURE_WEASEL})
* [Ядовитая змея](CREATURE:${CREATURE_POISONOUS_SNAKE})
* [Ястреб](CREATURE:${CREATURE_HAWK})
* [Ящерица](CREATURE:${CREATURE_LIZARD})

Появившись в свободном пространстве в пределах дистанции, фамильяр приобретает характеристики выбранной формы, хотя он не зверь, а небожитель, фея или исчадие (на Ваш выбор).

Ваш фамильяр действует независимо от Вас, но всегда повинуется Вашим командам. В сражении оно определяет свою инициативу и действует в свой ход. Фамильяр не может атаковать, но может совершать другие действия как обычно.

Когда хиты фамильяра опускаются до 0, он исчезает, не оставляя физического тела. Он возвращается, когда Вы используете это заклинание ещё раз.

Пока фамильяр находится в пределах 100 футов, Вы можете общаться с ним телепатически. Кроме того, Вы можете действием начать смотреть глазами фамильяра и слушать его ушами до начала своего следующего хода, получая преимущество от особых чувств, которыми может обладать фамильяр. В это время для своих собственных чувств Вы слепы и глухи.

Вы можете действием временно отпустить своего фамильяра. Тот исчезает в карманном измерении, где будет ждать, пока Вы его не призовёте. В качестве альтернативы, Вы можете отпустить его насовсем. Пока фамильяр временно отпущен, Вы можете действием вернуть его в свободное пространство в пределах 30 футов от себя.

У Вас не может быть больше одного фамильяра одновременно. Если Вы накладываете это заклинание, уже имея фамильяра, Вы заставляете принять его новый облик. Выберите один из описанных выше обликов. Ваш фамильяр становится этим существом.

Если Вы накладываете заклинание с дистанцией «касание», его может передать фамильяр, как если бы это он его накладывал. Ваш фамильяр должен при этом находиться в пределах 100 футов от Вас, и он использует свою реакцию, когда Вы накладываете его. Если заклинание требует броска атаки, Вы используете свой модификатор атаки.`,
  lvl: 1,
  magicSchoolId: MAGIC_CONJURATION,
  range: 10,
  isRitual: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  hasConsumable: true,
  materialText: `стоящие 10 зм уголь, благовония и травы, сжигаемые в огне или латунной жаровне`,
  castTime: { timeId: TIME_HOUR, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 261,
    },
    {
      id: SOURCE_SRD,
      page: 143,
    },
  ],
}
