module.exports = [
  require('./swarm_of_bats'),
  require('./swarm_of_bugs'),
  require('./swarm_of_centipedes'),
  require('./swarm_of_insects'),
  require('./swarm_of_poisonous_snakes'),
  require('./swarm_of_quippers'),
  require('./swarm_of_rats'),
  require('./swarm_of_ravens'),
  require('./swarm_of_spiders'),
  require('./swarm_of_wasps'),
]
