const upLetter = require('./../../../../../../utils/upLetter')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_TYPE_POTION} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    id,
    rarity,
    giantNameRu,
    giantNameEn,
    str,
  },
) => ({
  id,
  name: `Зелье силы ${giantNameRu} великана`,
  nameEn: `Potion of ${upLetter(giantNameEn)} Giant Strength`,
  type: MGC_TYPE_POTION,
  rarity,
  isConsumable: true,
  description: `В прозрачной жидкости этого зелья плавают обрезки ногтей **${giantNameRu} великана**.

Когда Вы выпиваете это зелье, Ваше значение Силы становится равно **${str}** на один час. Зелье не оказывает на Вас никакого эффекта в том случае, если значение Вашей Силы уже равно или превосходит **${str}**.`,
  genderId: GENDER_MIDDLE,
  siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 166,
  },
})

module.exports = generator
