const {gearCollection} = require('./../../../../../gearList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    bonus,
    id,
    rarity,
    gearId,
  }
) => {
  const { name, nameEn } = gearCollection[gearId]
  const nameFull = `${name} +${bonus}`

  return {
    id,
    name: nameFull,
    nameEn: `${nameEn} +${bonus}`,
    type: MGC_TYPE_WEAPON,
    rarity,
    gearType: gearId,
    description: `Вы получаете бонус **+${bonus}** к броскам атаки и урона, совершённым при использовании этих магических боеприпасов.

Попадая в цель, *${nameFull}* перестают быть магическими.`,
    genderId: GENDER_MALE,
    isConsumable: true,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 152,
    },
  }
}
