const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  DAMAGE_FORCE,
} = require('./../../../../../damageTypeList')
const {
  PARAM_DEX,
} = require('./../../../../../paramList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_FTD,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_DRACONIC_TRANSFORMATION,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_DRACONIC_TRANSFORMATION,
  name: 'Драконья трансформация',
  nameEn: 'Draconic Transformation',
  description: `Рыча, Вы черпаете магию драконов и преобразуетесь, получая драконьи способности. Вы получаете следующие преимущества на время длительности заклинания

# Слепое зрение

Вы получаете слепое зрение на 30 футов. В пределах этой дистанции Вы можете явственно видеть всё, что не скрыто полным укрытием, даже если Вы Слепы или находитесь в темноте. Кроме того, вы можете видеть невидимых существ, если они не смогли спрятаться от Вас.

# Драконье дыхание

Сотворяя это заклинание и, бонусным действием, на последующих ходах, Вы можете выдохнуть 60-футовый конус мерцающей энергии. Все существа в этой области должны пройти испытание Ловкости, получая 6к8 урона силовым полем при провале или половину при успехе.

# Крылья

Из Вашей спины вырастают бесплотные крылья, давая Вам скорость полёта 60 футов.`,
  lvl: 7,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  rangeComment: '60-футовый конус',
  componentIdList: [
    CAST_SOMATIC,
    CAST_MATERIAL,
    CAST_VERBAL,
  ],
  materialText: `статуэтка дракона, стоимостью минимум 500 зм`,
  hasCost: true,
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 6,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_FTD,
    page: 19,
  },
}
