const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_11,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_HORNED_DEVIL,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Мальбранш',
  nameAlt: 'Рогатый дьявол',
  nameEn: 'Malebranche',
  nameEnAlt: 'Horned Devil',
  id: CREATURE_HORNED_DEVIL,
  description: [
    `Рогатые дьяволы безумно ленивы, и стараются не подвергать себя опасности. Более того, они ненавидят и боятся существ сильнее их самих. Однако если их достаточно спровоцировать или разозлить, ярость этих исчадий бывает ужасающей.

Мальбранш ростом примерно с огра и покрыт твёрдыми как железо чешуйками. Рогатые дьяволы, летающая пехота адских легионов, следуют приказам дословно. Их огромные крылья и гигантские рога являют собой устрашающую картину, когда они пикируют с неба, 
атакуя смертоносными трезубцами и хлеща хвостами.`,
    ...devilDescriptionList,
  ],
  note: devilNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 151,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 21,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_11,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три рукопашные атаки: две _Вилами_, и одну _Хвостом_. ★Он★ может использовать _Метание пламени_ вместо любой рукопашной атаки.`,
    },
    {
      name: 'Вилы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Хвост',
      description: `Если цель — существо, не являющееся ни нежитью, ни конструктом, она должна пройти испытание Телосложения СЛ 17, иначе будет терять 10 (3к6) хитов в начале каждого своего хода из-за инфернальной раны. Каждый раз, когда ★СУЩЕСТВО★ попадает этой атакой по цели, уже имеющей эту рану, урон от раны увеличивается на 10 (3к6). Любое существо может залечить рану, если действием совершит успешную проверку Мудрости (Медицина) СЛ 12. Эта рана также закрывается, если цель получит магическое лечение.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Метание пламени',
      description: `Если цель — горючий предмет, который никто не несёт и не носит, она также загорается.`,
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 7,
        range: 150,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 4,
          diceType: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
