const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_AMORPHOUS,
  ABILITY_FALSE_APPEARANCE,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_ALKILITH,
  CREATURE_CULTIST,
  CREATURE_JUIBLEX,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_11} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {LANG_ABYSSAL} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_CONFUSION} = require('./../../../../spellIdList')

module.exports = {
  name: 'Алкилит',
  nameEn: 'Alkilith',
  id: CREATURE_ALKILITH,
  description: [
    `Алкилит легко ошибочно принимается за какой-то грязный грибковый нарост, появляющийся на дверях, окнах и других проёмах. Это свисающая мерзость скрывает демоническую природу алкилитов, делая из того, что кажется очень странным что-то безобидное. Везде, где укореняются алкилиты, они ослабляют ткань реальности, создавая портал, через который могут проникать ещё более отвратительные демоны.

**Симптомы погибели.** Появление алкилита в мире свидетельствует о большой ошибке и неизбежной катастрофе. Алкилит ищет отверстие, такое как окно или дверь, вокруг которой он может укорениться, растягивая своё тело вокруг отверстия и фиксируя себя липкой секрецией. Если его не беспокоить, отверстие будет настроено на Бездну и в конечном итоге станет порталом к этому плану (см. «Планарные порталы» во 2 главе Руководства Мастера, стр. 45).

**Порождение Джуиблекса.** Алкилиты происходят из отброшенных кусочков отвратительного, содрогающегося тела [Джубилекса](CREATURE:${CREATURE_JUIBLEX}), а затем постепенно обретают сознание и ищут путь на Материальный план. Поскольку большинство [культистов](CREATURE:${CREATURE_CULTIST}) считают их слишком опасными для призыва (ведь, в конце концов, они могут создать порталы в Бездну), Алкилиты должны найти другие пути покинуть свой родной план.`,
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 129,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 22,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 7,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ABYSSAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_11,
  featureList: [
    ABILITY_AMORPHOUS,
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обыкновенного мха или грибка`,
    },
    {
      name: 'Разжигание безумия',
      description: `Любое отличное от демона существо, начинающее свой ход в пределах 30 фт. от алкилита должно пройти испытание Мудрости СЛ 18, или начнёт слышать слабое жужжание у себя в голове, получая Помеху на своё следующее испытание, бросок атаки или проверку навыка. В случае, если испытание провалено на 5 и более, существо становится целью для заклинания [смятение](SPELL:${SPELL_CONFUSION}) на 1 минуту (компоненты не требуются). Пока существо находится под действием заклинания смятение, оно невосприимчиво к _Разжиганию безумия_.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Алкилит совершает три атаки _Щупальцем_.`,
    },
    {
      name: 'Щупальце',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 15,
        hit: {
          type: DAMAGE_ACID,
          diceCount: 4,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
}
