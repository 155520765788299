const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SPELL_SUMMON_BEAST,
} = require('./../../../../spellIdList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ITS_SUMMONER,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FLYBY,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_AIR_BEAST_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух летучего зверя',
  nameEn: 'Air Beast Spirit',
  id: CREATURE_AIR_BEAST_SPIRIT,
  createdBySpellId: SPELL_SUMMON_BEAST,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: CREATURE_TYPE_BEAST,
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TCoE,
    page: 158,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `20 + 5-кратный круг заклинания выше 2-го`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 16,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 5,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_SUMMONER,
      onlyUnderstands: true,
    },
  ],
  featureList: [
    ABILITY_FLYBY,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Кромсание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
}
