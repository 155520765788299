const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {CREATURE_TINY_SERVANT} = require('./../../../../../creatureIdList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_TINY_SERVANT} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TINY_SERVANT,
  name: 'Крошечный слуга',
  nameEn: 'Tiny Servant',
  description: `Вы прикасаетесь к одному Крошечному, немагическому объекту который не прикреплен к другому объекту или поверхности и не переносится другим существом. Цель оживает и отращивает маленькие руки и ноги, превращаясь в [существо](CREATURE:${CREATURE_TINY_SERVANT}), которое находится под Вашим контролем, пока заклинание не закончится или пока хиты существа не упадут до 0.

Бонусным действием Вы можете мысленно давать существу команды, если оно находится в пределах 120 футов от вас. (Если Вы управляете несколькими существами с помощью этого заклинания, Вы можете одновременно командовать любым из них или сразу всеми, давая одну и ту же команду каждому из существ). Вы определяете, какое действие совершит существо и как оно будет двигаться в течение следующего хода, или Вы можете дать простую общую команду, например, достать ключ, стоять и наблюдать или собрать несколько книг. Если Вы не даёте никаких команд, слуга не делает ничего, кроме защиты себя от враждебных существ. После получения приказа слуга продолжает следовать ему, пока его задача не будет завершена.

Когда хиты существа опускаются до 0, оно возвращается к своей изначальной форме, весь «лишний» урон переносится на эту форму.`,
  highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку 4 круга или выше, Вы можете оживить два дополнительных объекта за каждый круг ячейки выше 3.`,
  lvl: 3,
  magicSchoolId: MAGIC_TRANSMUTATION,
  createdCreatureId: CREATURE_TINY_SERVANT,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 8},
  castTime: {timeId: TIME_MINUTE, count: 1},
  source: {
    id: SOURCE_XGTE,
    page: 155,
  },
}
