const {
  GEAR_SHIELD,
  GEAR_SHORTSWORD,
} = require('./../../../gearIdList')
const {
  WEAPON_FINESSE,
  WEAPON_LIGHT,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const swordDescription = require('./../../constants/swordDescription')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_SHORTSWORD,
  genderId: GENDER_MALE,
  name: 'Короткий меч',
  nameByCase: {
    nominative: 'короткий меч',
    genitive: 'короткого меча',
    accusative: 'короткий меч',
    instrumental: 'коротким мечом',
  },
  nameEn: 'Shortsword',
  description: [
    {
      header: 'Короткий меч',
      text: `Быстрые и универсальные, короткие мечи популярны среди большинства классов персонажей. Малый вес делает их отличным выбором для длительных путешествий, а низкая цена делает их популярным вариантом для искателей приключений, которые только начинают.

Как одноручное оружие, короткие мечи позволяют своему владельцу также носить [щит](GEAR:${GEAR_SHIELD}) — важный фактор для бойцов, которым недоступна прочная броня. Не стоит недооценивать ущерб, который умелый герой может нанести одним из этих быстрых, острых орудий!`,
      source: {
        id: SOURCE_AYAGWnW,
        page: 106,
      },
    },
    swordDescription,
  ],
  damage: {
    diceType: 6,
    diceCount: 1,
  },
  cost: 1000,
  damageType: DAMAGE_PIERCING,
  weight: 2,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    WEAPON_LIGHT,
    WEAPON_FINESSE,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
