module.exports = [
  require('./cambion'),
  require('./camel'),
  require('./carrion_crawler'),
  require('./cat'),
  require('./cave_bear'),
  require('./centaur'),
  require('./chain_devil'),
  require('./chasme'),
  require('./chimera'),
  require('./chuul'),
  require('./clay_golem'),
  require('./cloacker'),
  require('./cloud_giant'),
  require('./cockatrice'),
  require('./commoner'),
  require('./constrictor_snake'),
  require('./couatl'),
  require('./crab'),
  require('./crawling_claw'),
  require('./crocodile'),
  require('./cult_fanatic'),
  require('./cultist'),
  require('./cyclops'),
]
