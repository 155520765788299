const generateMagicItemTableText = require('./../utils/generateMagicItemTableText')
const magicItemList = require('./magicItemList')
const { MGC_RARITY_COMMON } = require('./magicItemRarityList')
const {
  MGC_TYPE_POTION,
  MGC_TYPE_SCROLL,
} = require('./magicItemTypeList')
const {
  MAGIC_ITEM_ALCHEMY_JUG,
  MAGIC_ITEM_AMULET_OF_HEALTH,
  MAGIC_ITEM_ARCANE_PROPULSION_ARM,
  MAGIC_ITEM_ARM_BLADE,
  MAGIC_ITEM_BAG_OF_HOLDING,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_HILL,
  MAGIC_ITEM_BOOTS_OF_ELVENKIND,
  MAGIC_ITEM_BOOTS_OF_LEVITATION,
  MAGIC_ITEM_BOOTS_OF_SPEED,
  MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING,
  MAGIC_ITEM_BOOTS_OF_THE_WINTERLANDS,
  MAGIC_ITEM_BRACERS_OF_ARCHERY,
  MAGIC_ITEM_BRACERS_OF_DEFENSE,
  MAGIC_ITEM_BROOCH_OF_SHIELDING,
  MAGIC_ITEM_CAP_OF_WATER_BREATHING,
  MAGIC_ITEM_CLOAK_OF_ELVENKIND,
  MAGIC_ITEM_CLOAK_OF_PROTECTION,
  MAGIC_ITEM_CLOAK_OF_THE_BAT,
  MAGIC_ITEM_CLOAK_OF_THE_MANTA_RAY,
  MAGIC_ITEM_DIMENSIONAL_SHACKLES,
  MAGIC_ITEM_EYES_OF_CHARMING,
  MAGIC_ITEM_EYES_OF_THE_EAGLE,
  MAGIC_ITEM_GAUNTLETS_OF_OGRE_POWER,
  MAGIC_ITEM_GEM_OF_SEEING,
  MAGIC_ITEM_GLOVES_OF_MISSILE_SNARING,
  MAGIC_ITEM_GLOVES_OF_SWIMMING_AND_CLIMBING,
  MAGIC_ITEM_GLOVES_OF_THIEVERY,
  MAGIC_ITEM_GOGGLES_OF_NIGHT,
  MAGIC_ITEM_HAT_OF_DISGUISE,
  MAGIC_ITEM_HEADBAND_OF_INTELLECT,
  MAGIC_ITEM_HELM_OF_TELEPATHY,
  MAGIC_ITEM_HORN_OF_BLASTING,
  MAGIC_ITEM_LANTERN_OF_REVEALING,
  MAGIC_ITEM_MEDALLION_OF_THOUGHTS,
  MAGIC_ITEM_PERIAPT_OF_WOUND_CLOSURE,
  MAGIC_ITEM_PIPES_OF_HAUNTING,
  MAGIC_ITEM_PIPES_OF_THE_SEWERS,
  MAGIC_ITEM_PROSTHETIC_LIMB,
  MAGIC_ITEM_QUIVER_OF_EHLONNA,
  MAGIC_ITEM_RING_OF_FREE_ACTION,
  MAGIC_ITEM_RING_OF_JUMPING,
  MAGIC_ITEM_RING_OF_MIND_SHIELDING,
  MAGIC_ITEM_RING_OF_PROTECTION,
  MAGIC_ITEM_RING_OF_THE_RAM,
  MAGIC_ITEM_RING_OF_WATER_WALKING,
  MAGIC_ITEM_ROPE_OF_CLIMBING,
  MAGIC_ITEM_SENDING_STONES,
  MAGIC_ITEM_SLIPPERS_OF_SPIDER_CLIMBING,
  MAGIC_ITEM_VENTILATING_LUNGS,
  MAGIC_ITEM_WAND_OF_MAGIC_DETECTION,
  MAGIC_ITEM_WAND_OF_SECRETS,
  MAGIC_ITEM_WAND_SHEATH,
  MAGIC_ITEM_WINGED_BOOTS,
} = require('./magicItemIdList')

const commonMagicItemIdList = magicItemList
  .filter(
    ({ rarity, type, isChild }) => (
      !isChild
      && rarity === MGC_RARITY_COMMON
      && (
        type !== MGC_TYPE_POTION
        || type !== MGC_TYPE_SCROLL
      )
    )
  )
  .map(({id}) => id)

const replicableMagicItemList = [
  {
    header: 'Обычные магические предметы',
    idList: commonMagicItemIdList,
  },
  {
    header: 'Изобретатель 2 уровня',
    idList: [
      MAGIC_ITEM_ALCHEMY_JUG,
      MAGIC_ITEM_ARM_BLADE,
      MAGIC_ITEM_BAG_OF_HOLDING,
      MAGIC_ITEM_CAP_OF_WATER_BREATHING,
      MAGIC_ITEM_GOGGLES_OF_NIGHT,
      MAGIC_ITEM_PROSTHETIC_LIMB,
      MAGIC_ITEM_ROPE_OF_CLIMBING,
      MAGIC_ITEM_SENDING_STONES,
      MAGIC_ITEM_WAND_OF_MAGIC_DETECTION,
      MAGIC_ITEM_WAND_OF_SECRETS,
    ],
  },
  {
    header: 'Изобретатель 6 уровня',
    idList: [
      MAGIC_ITEM_BOOTS_OF_ELVENKIND,
      MAGIC_ITEM_CLOAK_OF_ELVENKIND,
      MAGIC_ITEM_CLOAK_OF_THE_MANTA_RAY,
      MAGIC_ITEM_EYES_OF_CHARMING,
      MAGIC_ITEM_GLOVES_OF_THIEVERY,
      MAGIC_ITEM_LANTERN_OF_REVEALING,
      MAGIC_ITEM_PIPES_OF_HAUNTING,
      MAGIC_ITEM_RING_OF_WATER_WALKING,
      MAGIC_ITEM_WAND_SHEATH,
    ],
  },
  {
    header: 'Изобретатель 10 уровня',
    idList: [
      MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING,
      MAGIC_ITEM_BOOTS_OF_THE_WINTERLANDS,
      MAGIC_ITEM_BRACERS_OF_ARCHERY,
      MAGIC_ITEM_BROOCH_OF_SHIELDING,
      MAGIC_ITEM_CLOAK_OF_PROTECTION,
      MAGIC_ITEM_EYES_OF_THE_EAGLE,
      MAGIC_ITEM_GAUNTLETS_OF_OGRE_POWER,
      MAGIC_ITEM_GLOVES_OF_MISSILE_SNARING,
      MAGIC_ITEM_GLOVES_OF_SWIMMING_AND_CLIMBING,
      MAGIC_ITEM_HAT_OF_DISGUISE,
      MAGIC_ITEM_HEADBAND_OF_INTELLECT,
      MAGIC_ITEM_HELM_OF_TELEPATHY,
      MAGIC_ITEM_MEDALLION_OF_THOUGHTS,
      MAGIC_ITEM_PERIAPT_OF_WOUND_CLOSURE,
      MAGIC_ITEM_PIPES_OF_THE_SEWERS,
      MAGIC_ITEM_QUIVER_OF_EHLONNA,
      MAGIC_ITEM_RING_OF_JUMPING,
      MAGIC_ITEM_RING_OF_MIND_SHIELDING,
      MAGIC_ITEM_SLIPPERS_OF_SPIDER_CLIMBING,
      MAGIC_ITEM_VENTILATING_LUNGS,
      MAGIC_ITEM_WINGED_BOOTS,
    ],
  },
  {
    header: 'Изобретатель 14 уровня',
    idList: [
      MAGIC_ITEM_AMULET_OF_HEALTH,
      MAGIC_ITEM_ARCANE_PROPULSION_ARM,
      MAGIC_ITEM_GEM_OF_SEEING,
      MAGIC_ITEM_RING_OF_PROTECTION,
      MAGIC_ITEM_RING_OF_FREE_ACTION,
      MAGIC_ITEM_RING_OF_THE_RAM,
      MAGIC_ITEM_BRACERS_OF_DEFENSE,
      MAGIC_ITEM_DIMENSIONAL_SHACKLES,
      MAGIC_ITEM_CLOAK_OF_THE_BAT,
      MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_HILL,
      MAGIC_ITEM_HORN_OF_BLASTING,
      MAGIC_ITEM_BOOTS_OF_LEVITATION,
      MAGIC_ITEM_BOOTS_OF_SPEED,
    ],
  },
]

const replicableMagicItemTableText = replicableMagicItemList
  .map(generateMagicItemTableText)
  .join('')

module.exports = replicableMagicItemTableText
module.exports.replicableMagicItemTableText = replicableMagicItemTableText
