import React from 'react'
import Markdown from '@/components/Markdown'

import './TipStyles.less'

const TipBody = ({name, description}) => (
  <section className='TipBody'>
    <header className='TipBody_header'>{name}</header>
    <Markdown className='TipBody_body'>{description}</Markdown>
  </section>
)

export default TipBody
