const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_GHOST} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_GHOST,
  nameEn: 'Ghost',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'призрак',
      genitive: 'призрака',
      dative: 'призраку',
      accusative: 'призрака',
      instrumental: 'призраком',
      prepositional: 'призраке',
    },
    plural: {
      nominative: 'призраки',
      genitive: 'призраков',
      dative: 'призракам',
      accusative: 'призраков',
      instrumental: 'призраками',
      prepositional: 'призраках',
    },
  },
}
