const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_LONGSWORD,
} = require('./../../../../../gearIdList')
const {
  CREATURE_SHADOW,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_DEFENDER,
  MAGIC_ITEM_MOONBLADE,
  MAGIC_ITEM_RING_OF_SPELL_STORING,
  MAGIC_ITEM_VORPAL_SWORD,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_MOONBLADE,
  name: `Лунный клинок`,
  nameEn: `Moonblade`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_LONGSWORD,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `эльфом или полуэльфом нейтрально-доброго мировоззрения`,
  isSentient: true,
  description: `Из всех магических предметов, созданных эльфами, больше всех ценится и яростнее всех охраняется _Лунный клинок_. В древние времена практически у каждого эльфийского благородного дома было по одному такому клинку. По прошествии столетий часть клинков затерялась и магия их была утеряна вместе с родословными. Другая часть сгинула вместе с владельцами, отправившимися на выполнение заданий. Таким образом, осталось лишь несколько экземпляров этого оружия.

_Лунный клинок_ переходит от родителя к ребёнку. Он остаётся связанным с ним всю жизнь. Если владелец умирает, меч может достаться другому наследнику. Если достойных наследников нет, меч впадает в спячку. Он действует как обычный [длинный меч](GEAR:${GEAR_LONGSWORD}), пока его не найдёт достойная душа, способная пробудить его силу.

_Лунный клинок_ служит только одному хозяину одновременно. Процесс настройки требует особого ритуала в тронном зале эльфийского правителя или храме эльфийских богов.

_Лунный клинок_ не станет служить тому, кого он посчитает трусливым, непостоянным, порочным или нежелающим хранить и защищать эльфов. Если клинок Вас отвергает, Вы 24 часа совершаете с помехой проверки характеристик, броски атаки и испытания. Если клинок Вас принимает, Вы настраиваетесь на него, а на клинке появляется новая руна. Вы остаётесь настроенным на это оружие пока не умрёте или оно не уничтожится.

У _Лунного клинка_ на лезвии есть по одной руне за каждого владельца, которому он служил (обычно их 1к6 + 1). Первая руна всегда предоставляет бонус +1 к броскам атаки и урона, совершённым этим магическим оружием. За каждую последующую руну клинок получает дополнительное свойство. Мастер может сам выбрать каждое свойство, а может определить их случайным образом.

# Свойства лунного клинка

| к100  | Свойство |
|-------|----------|
| 01–40 | Увеличьте бонус к броскам атаки и урона на 1, до максимума +3. Перебросьте, если у _Лунного клинка_ бонус уже +3. |
| 41–80 | _Лунный клинок_ получает случайным образом определённое малое магическое свойство. |
| 81–82 | _Лунный клинок_ получает свойство «фехтовальное». |
| 83–84 | _Лунный клинок_ получает свойство «метательное» (дистанция 20/60 футов). |
| 85–86 | _Лунный клинок_ функционирует как [Защитник](MAGIC_ITEM:${MAGIC_ITEM_DEFENDER}). |
| 87–90 | _Лунный клинок_ совершает критическое попадание при выпадении «19» или «20». |
| 91–92 | Если Вы попадаете атакой, используя _Лунный клинок_, Вы причиняете дополнительный рубящий урон 1к6. |
| 93–94 | Если Вы попадаете _Лунным клинком_ по существу определённого вида (такому как дракон, исчадие или нежить), цель получает дополнительный урон 1к6 одного из следующих видов: звук, кислота, огонь, холод или электричество. |
| 95–96 | Вы можете бонусным действием заставить _Лунный клинок_ ярко вспыхнуть. Все существа, видящие Вас и находящиеся в пределах 30 футов от Вас, должны пройти испытание Телосложения СЛ 15, иначе они станут ослеплёнными на 1 минуту. Существо может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. Это свойство нельзя повторно использовать, пока Вы не совершите короткий отдых, будучи настроенным на это оружие. |
| 97–98 | _Лунный клинок_ действует как [Кольцо хранения заклинаний](MAGIC_ITEM:${MAGIC_ITEM_RING_OF_SPELL_STORING}). |
| 99    | Вы можете действием призвать эльфийскую тень, при условии, что у Вас сейчас нет в услужении такой тени. Эльфийская тень появляется в свободном пространстве в пределах 120 футов от Вас. Она использует статистику [тени](CREATURE:${CREATURE_SHADOW}), за исключением того, что она нейтральна, обладает иммунитетом к эффектам, изгоняющим нежить, и не создаёт новых теней. Это существо контролируете Вы, и Вы определяете его действия и перемещения. Оно существует, пока его хиты не опустятся до 0 или Вы не отпустите его действием. |
| 00    | _Лунный клинок_ действует как [Меч головоруб](MAGIC_ITEM:${MAGIC_ITEM_VORPAL_SWORD}). |

# Разум

_Лунный клинок_ — разумное нейтральное-доброе оружие с Интеллектом 12, Мудростью 10 и Харизмой 12. Он обладает слухом и тёмным зрением в пределах 120 футов.

Это оружие общается с владельцем, передавая эмоции, и посылая в руку владельца покалывающее ощущение, когда хочет передать что-то, что оно почувствовало. Клинок может общаться менее завуалированно, через видения и грёзы, когда владелец спит или находится в трансе.

# Индивидуальность

Все _Лунные клинки_ стремятся продвигать идеалы эльфов: храбрость, преданность, красоту, музыку и жизнь во всех её проявлениях.

Это оружие привязано к роду, которому должно служить. Когда оно связывается с владельцем, разделяющим его идеалы, его преданность будет абсолютной. 

Если у _Лунного клинка_ и есть недостаток, то это чрезмерная уверенность. Если он выбрал себе владельца, то будет считать, что только эта личность достойна его носить, даже если владелец позже предаст эльфийские идеалы.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 218,
  },
}
