const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_BAG_OF_TRICKS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateCreatureTable = require('./generateCreatureTable')
const siblingIdList = require('./siblingIdList')
const list = require('./list')

const bagTable = list.reduce(
  (tableText, {nameReverse, creatureIdList}) => `${tableText}
  
# ${nameReverse}

${generateCreatureTable(creatureIdList)}

`,
  ''
)

module.exports = {
  id: MAGIC_ITEM_BAG_OF_TRICKS,
  name: 'Сумка фокусов',
  nameEn: 'Bag of Tricks',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Эта обычная с виду сумка, изготовленная из серой, рыжей или коричневой ткани, выглядит пустой. Однако если засунуть в неё руку, можно нащупать маленький пушистый предмет. Весит сумка половину фунта. Вы можете действием вынуть из сумки пушистый предмет и бросить его на 20 футов. Когда предмет приземляется, он превращается в существо, которое Вы выбираете броском к8, консультируясь с приведённой таблицей, соответствующей цвету сумки. Характеристики существ смотрите в Бестиарии. Существо исчезает на следующем рассвете или когда его хиты будут уменьшены до 0.

Существо дружественно к Вам и Вашим спутникам, и действует в Ваш ход. Вы можете бонусным действием отдать команду, как существу перемещаться, и какое действие ему совершать в следующем ходу, или можете отдать общий приказ, такой как _«атакуй врагов»_. Если приказов нет, существо действует сообразно своей природе.

Как только из сумки достанут три пушистых предмета, её нельзя будет использовать до следующего рассвета.

${bagTable}`,
  genderId: GENDER_FEMALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 206,
  },
}
