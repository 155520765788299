const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FORCE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  LANG_ITS_SUMMONER,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CONDITION_CHARMED} = require('./../../../../conditionList')
const {CREATURE_FUMING_SPIRIT} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_FEY} = require('./../../../../creatureTypeIdList')
const {GEAR_SHORTSWORD} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_TCoE} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_SUMMON_FEY} = require('./../../../../spellIdList')

module.exports = {
  name: 'Дух вспыльчивой феи',
  nameEn: 'Fuming Fey Spirit',
  id: CREATURE_FUMING_SPIRIT,
  createdBySpellId: SPELL_SUMMON_FEY,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: CREATURE_TYPE_FEY,
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TCoE,
    page: 161,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `30 + 10-кратный круг заклинания выше 3-го`,
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 14,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 16,
  },
  immunityConditionList: [
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_SYLVAN,
    {
      id: LANG_ITS_SUMMONER,
      onlyUnderstands: true,
    },
  ],
  featureList: [
    {
      name: 'Шаг вспыльчивой феи',
      description: `★СУЩЕСТВО★ бонусным действием магически телепортируется в видимое ★им★ в пределах 30 футов свободное пространство. После этого ★он★ получает преимущество на следующий бросок атаки до конца этого хода.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Короткий меч',
      // gearId: GEAR_SHORTSWORD, // TODO: Найти способ перетащить в оружие с комментариями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 3,
            comment: ` + круг заклинания`,
          },
          {
            type: DAMAGE_FORCE,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
  ],
}
