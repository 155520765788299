const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  oozeDescriptionList,
  oozeNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AMORPHOUS,
  ABILITY_SPIDER_CLIMB,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_BLACK_PUDDING} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_OOZE} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Чёрная слизь`,
  nameAlt: `Чёрный пудинг`,
  nameEn: 'Black Pudding',
  id: CREATURE_BLACK_PUDDING,
  description: [
    `Чёрная слизь напоминает вздымающуюся кучу чёрной густой грязи. В тёмных проходах он выглядит просто пятном тени.

Плоть, древесина, металл и кости растворяются, когда на них наползает эта слизь. За ней остаётся только голый камень.`,
    ...oozeDescriptionList,
  ],
  note: oozeNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_OOZE,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 271,
  },
  armor: 7,
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_CLIMB]: 20,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 5,
    [PARAM_CON]: 16,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_SLASHING,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_AMORPHOUS,
    {
      name: `Едкое тело`,
      description: `Существо, касающееся ★СУЩЕСТВО★ или попадающее по ★нему★ рукопашной атакой, находясь в пределах 5 футов от ★него★, получает урон кислотой 4 (1к8). Немагическое оружие, изготовленное из металла или дерева, попадающее по ★СУЩЕСТВО★, начинает разъедаться. После причинения урона оружие получает постоянный накопительный штраф −1 к броскам урона. Если этот штраф дойдёт до −5, оружие будет уничтожено. Немагические боеприпасы, изготовленные из металла или дерева, попадающие по ★СУЩЕСТВО★, уничтожаются после причинения урона. ★СУЩЕСТВО★ может разъесть 2 дюйма немагического металла или дерева за 1 раунд.`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: `Ложноножка`,
      description: `Кроме того, немагический доспех, носимый целью, частично растворяется и получает постоянный накопительный штраф −1 к КД, который он предлагает. Доспех уничтожается, если штраф уменьшает его КД до 10.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 4,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  reactionList: [
    {
      name: `Разделение`,
      description: `Если ★СУЩЕСТВО★ с размером не меньше Среднего получает рубящий урон или урон электричеством, она разделяется на две новые слизи, если у ★него★ есть как минимум 10 хитов. У каждой новой слизи хитов в два раза меньше, чем у исходной, округляя в меньшую сторону. Новые слизи на один размер меньше чем исходная.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
