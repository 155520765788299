const DC_BASE = require('./../../../../../DC_BASE')
const {GEAR_SPELLBOOK} = require('./../../../../../gearIdList')
const {GENDER_MALE, GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_SPELL_SCROLL} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_SCROLL} = require('./../../../../../magicItemTypeList')
const {PC_CLASS_WIZARD} = require('./../../../../../pcClassIdList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {magicItemRarityCollection} = require('./../../../../../magicItemRarityList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

const tableText = list.reduce(
  (text, {id, lvl, rarity, attackBonus}) => {
    const rarityText = magicItemRarityCollection[rarity].nameByGender[GENDER_FEMALE]
    const saveThrowDc = DC_BASE + attackBonus
    const name = lvl
      ? `Свиток заклинания ${lvl} круга`
      : `Свиток заговора`

    return `${text}
| [${name}](MAGIC_ITEM:${id}) | ${rarityText} | ${saveThrowDc} | +${attackBonus} |`
  },
  `
# Свитки заклинаний

| Круг заклинания | Редкость | СЛ испытания | Бонус атаки |
|-----------------|----------|--------------|-------------|`
)

module.exports = {
  id: MAGIC_ITEM_SPELL_SCROLL,
  name: `Свиток заклинания`,
  nameEn: `Spell Scroll`,
  type: MGC_TYPE_SCROLL,
  rarity: generateRarityList(list),
  isConsumable: true,
  description: `Свиток заклинания несёт на себе слова одного заклинания, написанные таинственным шифром. Если это заклинание есть в списке заклинаний Вашего класса, Вы можете прочитать свиток и наложить его заклинание, не предоставляя материальные компоненты. В противном случае свиток непонятен для Вас. Накладывание заклинания свитком занимает столько же времени, сколько обычное накладывание заклинания. После того, как заклинание наложено, слова на свитке исчезают, а сам свиток рассыпается в прах. Если накладывание прервано, свиток остаётся целым.

Если заклинание есть в списке заклинаний Вашего класса, но имеет более высокий круг, чем те, что Вы способны сотворять, Вы должны совершить проверку базовой характеристики СЛ 10 + круг заклинания, чтобы определить, удалось ли Вам справиться с задачей. При провале заклинание просто исчезает со свитка, не произведя никаких эффектов.

круг заклинания на свитке определяет СЛ испытания от него, его бонус атаки, а также редкость этого свитка, как это показано в таблице.

${tableText}

Если свиток содержит заклинание из списка заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), то оно может быть скопировано в [книгу заклинаний](GEAR:${GEAR_SPELLBOOK}). Для этого надо преуспеть в проверке Интеллекта (Магия) СЛ 10 + круг заклинания. Если проверка успешна, заклинание удаётся скопировать. Вне зависимости от исхода проверки свиток после этого уничтожается.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 200,
  },
}
