const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    CR_12,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_CONSTRUCT,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_IMMUTABLE_FORM,
    ABILITY_SIEGE_MONSTER,
  } = require('./../../../../creatureAbilityList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
  } = require('./../../../../conditionList'),
  {
    CREATURE_STONE_JUGGERNAUT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Каменный джаггернаут',
  nameEn: 'Stone Juggernaut',
  id: CREATURE_STONE_JUGGERNAUT,
  description: {
    header: 'Каменный джаггернаут',
    text: `Каменный джаггернаут — это прочный конструкт, наполненный достаточным сознанием, чтобы избегать очевидных опасностей, таких как открытые ямы и пропасти. Он катится по открытым полям битв или по коридорам подземелий, сокрушая всех на своём пути. Каждый каменный джаггернаут имеет уникальную форму и внешний вид. Один может напоминать слона, украшенного бивнями, в то время как другой может выглядеть как хмурый демон с горящими глазами и обсидиановыми зубами.

Каменный джаггернаут быстр, но ему не хватает манёвренности и может двигаться только в одном направлении на своём ходу. Он не представляет опасности для существ, которых не может раздавить, пока катится. Его лучшая тактика заключается в том, чтобы врезаться в существо, сбить его с ног, а затем переехать.`,
    source: {
      id: SOURCE_TOA,
      page: 229,
    },
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 230,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: {
      value: 50,
      comment: 'в одном направлении, выбранном в начале его хода',
    },
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 1,
    [PARAM_CON]: 21,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 3,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
    },
  ],
  cr: CR_12,
  featureList: [
    ABILITY_IMMUTABLE_FORM,
    ABILITY_SIEGE_MONSTER,
    {
      name: 'Регенерация',
      nameEn: 'Regeneration',
      description: `★СУЩЕСТВО★ магически восстанавливает все свои хиты на рассвете, если у него есть хотя бы 1 хит. ★СУЩЕСТВО★ уничтожается и не регенерирует, если его хиты опускаются до 0.`,
    },
    {
      name: 'Разрушительный перекат',
      description: `★СУЩЕСТВО★ может перемещаться по пространству лежащего ничком существа. Существо, через чьё пространство первый раз за ход проходит ★СУЩЕСТВО★, должно пройти испытание Ловкости СЛ 17, получая 55 (10к10) дробящего урона при провале или половину урона при успехе.`,
    },
  ],
  actionList: [
    {
      name: 'Удар',
      description: `Если цель — Большое или меньшее существо, то оно должно пройти испытание Силы СЛ 17 или будет сбито с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 12,
          diceBonus: 6,
        },
      },
    },
  ],
}
