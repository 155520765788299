const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../sourceList')
const {CREATURE_TYPE_DRAGONBORN} = require('./../../creatureTypeIdList')
const {uncommonRaces} = require ('./../../textCommonParts')
const {
  GOD_BAHAMUT,
  GOD_KELEMVOR,
  GOD_RED_KNIGHT,
  GOD_TEMPUS,
  GOD_TIAMAT,
  GOD_TORM,
  GOD_TYR,
} = require('./../../godIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_DRAGONBORN,
  nameEn: 'Dragonborn',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'драконорождённый',
        genitive: 'драконорождённого',
        dative: 'драконорождённому',
        accusative: 'драконорождённого',
        instrumental: 'драконорождённым',
        prepositional: 'драконорождённом',
      },
      [GENDER_FEMALE]: {
        nominative: 'драконорождённая',
        genitive: 'драконорождённой',
        dative: 'драконорождённой',
        accusative: 'драконорождённую',
        instrumental: 'драконорождённой',
        prepositional: 'драконорождённой',
      },
    },
    plural: {
      nominative: 'драконорождённые',
      genitive: 'драконорождённых',
      dative: 'драконорождённым',
      accusative: 'драконорождённых',
      instrumental: 'драконорождёнными',
      prepositional: 'драконорождённых',
    },
  },
  note: [
    {
      text: `Её отец не двигаясь стоял на первой из трёх ступенек, ведущих вниз от портала. Чешуйки на его лице стали бледнее по краям, но Безродный Мехен всё ещё выглядел так, будто мог бы побороть лютого медведя. Его привычные изношенные доспехи сменила чешуйчатая броня фиолетового оттенка с ярким серебристым рисунком. Ещё на его рукаве был герб, знак какого-то чужого дома. Меч за его спиной был тот же, что он носил до того, как нашёл близнецов у ворот Аруш Ваема. Фаридэ научилась читать, что скрывается за выражением лица её отца; этот навык ей посчастливилось усвоить. Человек, не способный даже заметить движение её глаз, смог бы увидеть в лице Мехена Безродного только полное безразличие. Но она многое могла сказать по сдвигу чешуек, изгибу гребня, очертаниям глаз и его оскалу

Но сейчас чешуя была абсолютно неподвижна, и даже Фаридэ не видела в его лице ничего, кроме безразличия дракона.`,
      author: `Эрин М. Эванс, Соперник`,
    },
    {
      text: `Лишь всколыхнётся джунглей скрытая тьма, как дети дракона покинут дома, встав на вечный дозор в тенях`,
      author: `Драконье пророчество, записанное в Свитках Мар’савала`,
    },
  ],
  description: [
    {
      text: `Рождённые драконами, о чём говорит их название, драконорождённые идут гордо подняв голову по миру, который встречает их со страхом и непониманием. Сформированные драконьими богами или самими драконами, драконорождённые первоначально вылупились из драконьих яиц как новая раса, сочетающая в себе лучшие качества драконов и гуманоидов. Некоторые драконорождённые являются верными слугами истинных драконов, другие образуют ряды солдат в великих войнах, а некоторые ищут свою судьбу, не найдя себе призвания.`,
      source: {
        id: SOURCE_PHB,
        page: 33,
      },
    },
    {
      header: `Гордый драконий род`,
      text: `Драконорождённые выглядят как драконы, стоящие на задних лапах и принявшие гуманоидную форму, потеряв при этом крылья и хвост. Первые из них обладали чешуёй оттенков, присущих драконам разных видов, но поколения непрерывного скрещивания привели к появлению единого цвета. Их маленькие, аккуратные чешуйки обычно цвета меди или латуни, иногда с алым, золотым, медно-зелёным или рыжим оттенком. Они высоки и крепки, часто достигая шести с половиной футов (двух метров) в высоту и обладая весом свыше 300 фунтов (130 килограмм). Их крепкие, четырёхпалые конечности снабжены пальцами, не уступающими по прочности когтям.

Кровь определённых видов драконов чрезвычайно сильна в представителях некоторых кланов. Эти драконорождённые часто гордятся тем, что цвет их чешуи близок к цвету их прародителей — ярко красный, зелёный, синий или белый, глянцево-чёрный или блестяще-золотой, серебряный, латунный, медный или бронзовый. `,
      source: {
        id: SOURCE_PHB,
        page: 33,
      },
    },
    uncommonRaces,
    {
      header: `Самодостаточные кланы`,
      text: `Для каждого драконорождённого его клан важнее собственной жизни. Они ставят свою преданность и уважение клану выше всего, даже богов. Каждый поступок драконорождённого отражается на чести его клана, и тот, кто опозорил свой клан, может быть изгнан. Каждый драконорождённый знает своё место и свои обязанности в клане, и долг чести заставляет их придерживаться этого места.

Постоянное стремление к самосовершенствованию отражает самодостаточность всей расы драконорождённых в целом. Они ценят мастерство и совершенство во всём, к чему прикладывают усилия. Драконорождённые ненавидят проигрывать, и они приложат все возможные силы к достижению цели, прежде чем откажутся от неё. Они часто делают достижение мастерства в каком-либо навыке целью всей своей жизни. Представители других рас, разделяющие эти взгляды, могут легко завоевать уважение среди драконорождённых.

Однако, несмотря на стремление к самодостаточности, они понимают, что в трудной ситуации без помощи бывает не обойтись. Но лучший источник помощи это твой клан. А если помощь требуется всему клану, то они скорее обратятся к другому клану драконорождённых, чем к представителям других рас, или даже к помощи богов.`,
      source: {
        id: SOURCE_PHB,
        page: 33,
      },
    },
    {
      header: `Имена драконорождённых`,
      text: `Драконорождённые получают личные имена при рождении, но в знак уважения они на первое место ставят название клана. Детское имя или прозвище обычно используется среди представителей одного выводка как неофициальное. Такое имя может указывать на некоторое событие из жизни драконорождённого или его привычку.

# Мужские имена

Арджхан, Баласар, Бхараш, Гхеш, Донаар, Крив, Медраш, Мехен, Надарр, Панджед, Патрин, Рхогар, Тархун, Торинн, Хескан, Шамаш, Шединн

# Женские имена

Акра, Бири, Даар, Джхери, Кава, Коринн, Мисханн, Нала, Перра, Райанн, Сора, Сурина, Тхава, Уаджит, Фаридэ, Хавилар, Харанн 

# Детские имена

Кривоухий, Липучка, Праведник, Скалолаз, Фанатик, Щитогрыз

# Названия кланов

Версисатургиеш, Даардендриан, Делмирев, Драчедандион, Кепешкмолик, Кер рилон, Кимбатуул, Клестинсиаллор, Линксакасендалор, Мястан, Неммонис, Нориксиус, Офиншталажир, Прексижандилин, Турнурот, Фенкенкаьрадон, Шестенделиат, Яржерит`,
      source: {
        id: SOURCE_PHB,
        page: 33,
      },
    },
    {
      header: `Дракониды`,
      text: ` В мире Саги о Копье последователи злой богини Такхизис узнали тёмный ритуал, позволяющий им осквернять яйца металлических драконов, создавая злых драконорождённых, называемых драконидами. Вот пять видов драконидов, соответствующие пяти видам металлических драконов, сражавшихся на стороне Такхизис в Войне Копья: аураки (золотые), баазы (латунные), бозаки (бронзовые), капаки (медные) и сиваки (серебряные). Вместо оружия дыхания они обладают уникальными магическими способностями.`,
      source: {
        id: SOURCE_PHB,
        page: 33,
      },
    },
    {
      header: `Драконорождённые`,
      text: `Драконоподобные гуманоиды из другого мира, драконорождённые Фаэруна горды, честны и в той же мере редки. Бывшие рабы драконов их родного мира, теперь они вольный народ, ищущий своё место и призвание в их новом мире.`,
      source: {
        id: SOURCE_SCAG,
        page: 113,
      },
    },
    {
      header: `Неясное происхождение`,
      text: `Как и со всеми историями седой старины, сказания о происхождении драконорождённых туманны и подчас противоречивы. Но так или иначе, каждое открывает что-то о драконорождённых. Одна из таких историй гласит, что драконорождённые были созданы древним драконьим богом Ио вместе с драконами. На заре дней, Ио смешал выдающихся астральных духов с неуправляемой стихийной яростью. Величайшие духи стали драконами — существами столь мощными, гордыми и волевыми, что они стали правителями новорождённого мира. Меньшие духи стали драконорождёнными. Не смотря на меньший размер, они также были драконами по своей природе. Сказание выделяет близкое родство драконов и драконорождённых, и в то же время подчёркивает естественный порядок вещей — драконы правят, а драконорождённые служат, по крайней мере, согласно прежним хозяевам драконорождённых.

Другая легенда утверждает, что Ио создал драконов при рождении мира, но драконорождённые тогда ещё не существовали. На протяжении Рассветной Войны, Ио был убит предтечей по имени Эрек-Хус, Король Ужаса. Грубым адамантиновым топором, чудище разрубило Ио от головы до хвоста на две равные части, которые стали новыми богами — [Бахамутом](GOD:${GOD_BAHAMUT}) и [Тиамат](GOD:${GOD_TIAMAT}). Капли крови Ио разлетелись по миру, став драконорождёнными. Для тех, кто верит этой истории происхождения, она лишь подтверждает подчинённость драконорождённых драконам, которых Ио создал своей любящей десницей. В то же время другие обращают внимание на то, что драконорождённые были созданы из крови Ио, также как оба драконьих бога, восставшие из разрубленного тела творца. Так чем тогда драконорождённые не боги?

Третья история о происхождении позиционирует драконорождённых, как первородных мира, созданных Ио до всех прочих гуманоидных рас, которые позже стали блёклым подражанием их идеала. Ио сформировал их и закалил их своим дыханием, а после пролил свою кровь, чтобы дать им жизнь. 

Первые драконорождённые были спутниками и союзниками Ио, его астральными придворными и певчими хвалы его. Драконов же он сотворил позже, в начале Рассветной Войны, для того чтобы те служили машинами разрушения. Этот взгляд на историю драконорождённых разделяют и те, кто считают драконорождённых высшей расой над прочими расами, а потому они должны быть хозяевами драконов и никак иначе.

Несмотря на взаимоисключающие параграфы, общая тема связывает все эти легенды: драконорождённые обязаны своим существованием Ио, великому драконьему богу, который создал всех драконидов. Драконорождённые, и в этом сходятся все легенды, не являются творением [Бахамута](GOD:${GOD_BAHAMUT}) или [Тиамат](GOD:${GOD_TIAMAT}) — и потому у них нет предопределённой стороны в конфликте между этими богами. Каждый отдельный драконорождённый, не зависимо от своего драконьего наследия, делает выбор сам согласно своим морально-этическим взглядам.`,
      source: {
        id: SOURCE_SCAG,
        page: 113,
      },
    },
    {
      header: `Борьба за свободу`,
      text: `Драконорождённые происходят с Абейра, древнего двойника Торила. В том мире многие драконорождённые добились победами свободы для себя и сформировали нации вольных драконорождённых, хотя большинство драконорождённых всё ещё находятся в рабстве у драконов. Во время Магической Чумы два мира пересеклись и одна из таких вольных наций, Тиманчебар, переправилась на Фаэрун. Подвинув нацию Унтер, из пепла двух королевств, выжившие драконорождённые сформировали Тимантер — новую нацию драконорождённых на Фаэруне.

Со временем, драконорождённые Тимантера задумались об интеграции в новом мире и поддержании их собственных традиций и культуры. Усилия, приложенные в этом направлении, закрепили за их нацией репутацию честных и достойных уважения существ. Лишь несколько поколений спустя, события Раскола вернули Унтер на Фаэрун, и ранее исчезнувшая страна задумала вернуть всё, что было потеряно в пользу Тимантера. Подкошенные этими потрясениями, оставшиеся драконорождённые Фаэруна теперь вынуждены были сражаться с большими сложностями и меньшим количеством ресурсом за место среди прочих народов мира.`,
      source: {
        id: SOURCE_SCAG,
        page: 114,
      },
    },
    {
      header: `Честь и семья`,
      text: `Каждый аспект жизни драконорождённых диктуется кодексом чести и строгим следованием традициям расы. Сообщество драконорождённых сильно структурировано, ожидая от каждого, что он будет делать всё возможное для семьи и клана. Эта верность и чувство долга поддерживало драконорождённых на протяжении их истории, длинного рабства и позволило им образовать общины и нации вольных драконорождённых.

Последствия Раскола испытывали эти принципы, оставив некоторые кланы раздробленными и децентрализованными. Некоторые драконорождённые Фаэруна пытаются восстановить некоторое подобие связи, которая была у них с ныне утраченным кланом или семьёй, устанавливая новые взаимоотношения с их недраконорождёнными союзниками и компаньонами.

Драконорождённые Фаэруна имеют расовые особенности драконорождённых из Книги Игрока.`,
      source: {
        id: SOURCE_SCAG,
        page: 114,
      },
    },
    {
      header: `Философия и религия`,
      text: `Их кодекс достойного поведения и непоколебимой верности служит драконорождённым неким подобием веры, и, если верить их фундаменталистам, в этом и заключена вся религия, в которой они нуждаются. Из-за того, что они были вынуждены поклоняться своим драконьим хозяевам в прошлом, драконорождённые скептически относятся к религии, глядя на неё, как на форму порабощения. Скептики полагают, что не важно, как и почему их изначальный бог, Ио, создал их — это древнее божество либо давно мертво, либо его не волнует их судьба, а драконьи боги, которые пришли на смену Ио в первую очередь заинтересованы в накапливании солдат для их векового конфликта.

И всё же, некоторые драконорождённые слышат призыв богов Фаэруна и избирают служить им, и в этом служении они также верны, как и в любом другом деле. [Бахамут](GOD:${GOD_BAHAMUT}) и [Тиамат](GOD:${GOD_TIAMAT}) имеют последователей среди драконорождённых, а оба [Торм](GOD:${GOD_TORM}) и [Тир](GOD:${GOD_TYR}) обращаются к чувству чести и порядка драконорождённых.

Также, [Темпус](GOD:${GOD_TEMPUS}) и [Красный Рыцарь](GOD:${GOD_RED_KNIGHT}) обращаются к воинскому духу некоторых драконорождённых, а [Келемвор](GOD:${GOD_KELEMVOR}) нашёптывает некоторым из них о неизбежности смерти и необходимости достойно использовать отмеренное ему время. Религиозные взгляды — сугубо личная вещь для драконорождённого, который их придерживается. Некоторые из таких драконорождённых преданы своей религии также, как преданы они своим семьям и кланам.

В культуре драконорождённых, семья — это непосредственные связи индивида, в то время как клан — это ряд семей, объединённых вместе союзом, браками или общей историей. Им редко приходится выбирать между ними, из-за того, что благополучие клана более важно для большинства драконорождённых, чем благополучие семьи. Перспектива всеобщего уважения среди их клана толкает драконорождённых на героические и отважные деяния и достижение высот мастерства — всё для того чтобы принести славу в первую очередь клану, и уже после — добиться её для себя.`,
      source: {
        id: SOURCE_SCAG,
        page: 114,
      },
    },
  ],
}
