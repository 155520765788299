const {
  SPELL_BURNING_HANDS,
  SPELL_DETECT_MAGIC,
  SPELL_ELDRITCH_BLAST,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_INVISIBILITY,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MINOR_ILLUSION,
  SPELL_POISON_SPRAY,
  SPELL_PRESTIDIGITATION,
  SPELL_SCORCHING_RAY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BAT,
  CREATURE_GIANT_BAT,
  CREATURE_GIANT_RAT,
  CREATURE_RAT,
  CREATURE_XVART_WARLOCK_OF_RAXIVORT,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_XVART,
} = require('./../../../../creatureTypeIdList')
const {
  xvartDescription,
  xvartNote,
} = require('./../../../../textCommonParts')
const {ABILITY_LOW_CUNNING} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_1} = require('./../../../../crList')
const {GEAR_SCIMITAR} = require('./../../../../gearIdList')
const {LANG_ABYSSAL} = require('./../../../../languageIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ксварт колдун Раксиворта',
  nameEn: 'Xvart Warlock of Raxivort',
  id: CREATURE_XVART_WARLOCK_OF_RAXIVORT,
  description: xvartDescription,
  note: xvartNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_XVART,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 165,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_LOW_CUNNING,
    {
      name: 'Благословение Раксиворта',
      description: `Когда ★СУЩЕСТВО★ уменьшает хиты врага до 0, то получает 4 временных хита.`,
    },
    {
      name: 'Язык Раксиворта',
      description: `★СУЩЕСТВО★ может общаться с обычными [летучими мышами](CREATURE:${CREATURE_BAT}) и [крысами](CREATURE:${CREATURE_RAT}), а также с [гигантскими летучими мышами](CREATURE:${CREATURE_GIANT_BAT}) и [гигантскими крысами](CREATURE:${CREATURE_GIANT_RAT}).`,
    },
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DETECT_MAGIC,
            {
              id: SPELL_MAGE_ARMOR,
              comment: 'только на себя',
            },
          ],
        },
      ],
    },
    {
      spellCasterLevel: 3,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_ELDRITCH_BLAST,
            SPELL_MAGE_HAND,
            SPELL_MINOR_ILLUSION,
            SPELL_POISON_SPRAY,
            SPELL_PRESTIDIGITATION,
          ],
        },
        {
          preText: `1–2 круг (2 ячейки 2 круга)`,
          list: [
            SPELL_BURNING_HANDS,
            SPELL_EXPEDITIOUS_RETREAT,
            SPELL_INVISIBILITY,
            SPELL_SCORCHING_RAY,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      gearId: GEAR_SCIMITAR,
    },
  ],
}
