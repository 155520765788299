const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {
  PARAM_INT,
} = require('./../../../../paramList')
const {
  CREATURE_XVART,
  CREATURE_XVART_SPEAKER,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Ксварт-спикер',
  nameEn: 'Xvart Speaker',
  id: CREATURE_XVART_SPEAKER,
  parentId: CREATURE_XVART,
  extendPropCollection: {
    params: {
      [PARAM_INT]: 13,
    },
    languageList: [
      LANG_COMMON,
      LANG_GOBLIN,
    ],
    description: [
      {
        header: `Ксварты-спикеры`,
        text: `У племени ксвартов есть один спикер, который действует как их предводитель. У спикера параметры обычного ксварта но Интеллект 13 (+1), и он знает один дополнительный язык (обычно [Всеобщий](LANG:${LANG_COMMON}) или [Гоблинский](LANG:${LANG_GOBLIN})).`,
        source: {
          id: SOURCE_VGTM,
          page: 165,
        },
      },
    ],
  },
}
