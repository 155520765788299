const {SOURCE_MM} = require('./../../sourceList')
const {SPELL_WISH} = require('./../../spellIdList')

module.exports = [
  {
    header: 'Ликантропы',
    text: `Одно из самых древних и пугающих проклятий, ликантропия, может превратить даже самых цивилизованных гуманоидов в хищных зверей. В своей естественной гуманоидной форме существо, поражённое ликантропией, выглядит как обычно. С течением времени, однако, многие ликантропы приобретают черты своих звериных тел. В зверином теле ликантроп походит на усиленную версию нормального животного. При ближайшем рассмотрении можно заметить слабую искру неживотного интеллекта в его глазах, светящихся красным в темноте.

Злые ликантропы скрываются среди обычных гуманоидов, принимая звериный облик по ночам, чтобы распространять ужас и проливать кровь, особенно в полнолуние. Добрые ликантропы более отчуждены и чувствуют себя неуютно в окружении других цивилизованных существ, из-за чего обычно живут в диких ничейных землях подальше от деревень и городов.

**Проклятье ликантропии.** Гуманоидное существо может получить проклятье ликантропии после получения ранения от ликантропа, либо если один или оба его родителя ликантропы. Заклинание _Снятие проклятья_ (Remove curse) может излечить поражённое ликантропией существо, но ликантропы, рождённые таковыми, могут быть избавлены от проклятья лишь с помощью заклинания [Исполнение желаний](SPELL:${SPELL_WISH}).

Ликантроп может сопротивляться своему проклятью или же принять его. Сопротивляясь, ликантроп сохраняет своё мировоззрение и личность, покуда остаётся в гуманоидном теле. Он живёт как до заражения ликантропией, хороня глубоко внутри свои бушующие звериные позывы. Тем не менее, когда поднимается полная луна, проклятье становится непреодолимым, трансформируя существо в его звериную форму или же в ужасающего гибрида, объединяющего гуманоидные и животные черты. Когда луна уходит, зверь снова становится подконтрольным. Существо может не помнить события между трансформациями, особенно если оно не в курсе о собственном проклятье, хотя память об этих событиях нередко преследует ликантропов в виде кровавых снов.

Некоторые гуманоиды не видят смысла в сопротивлении проклятью и принимают его. С течением времени и накоплением опыта, они оттачивают умение трансформироваться и могут принимать животную или гибридную форму по своему желанию. Большинство ликантропов, принимающих свою животную натуру, поддаются жажде крови, становясь злыми существами, не упускающими шанса поохотиться на слабых.`,
    source: {
      id: SOURCE_MM,
      page: 188,
    },
  },
  {
    header: 'Вариант: Нечеловеческие ликантропы',
    text: `Статистика, приведённая в этом разделе, предполагает, что базовое существо было человеком. Однако Вы можете использовать эту статистику, чтобы представить нечеловеческого ликантропа, добавив или оставив ему для соответствия одну или несколько особенностей его гуманоидного тела. Например, оборотень эльф может сохранить свою расовую особенность «Наследие фей».`,
    source: {
      id: SOURCE_MM,
      page: 188,
    },
  },
  {
    header: 'Персонажи игроков в качестве ликантропов',
    text: `Персонаж, который стал ликантропом, сохраняет всю свою статистику, добавляя к ней бонусы в зависимости от вида ликантропа. Персонаж получает скорость ликантропа в негуманоидном облике, иммунитеты к урону, особенности и действия, которые не включают в себя использование снаряжения. Персонаж получает владение естественными атаками ликантропа, например, укусом или ударом когтями, которые причиняют урон, указанный в статистике оборотня. Персонаж не может говорить, находясь в облике зверя.

Гуманоид-неликантроп, по которому попадает атака, переносящая проклятье ликантропии, должен пройти испытание Телосложения (Сл 8 + бонус мастерства ликантропа + модификатор Телосложения ликантропа), или же станет проклят. Если персонаж получает проклятье, его мировоззрение становится таким, как у ликантропа. Мастер вправе решить, что смена мировоззрения переводит персонажа под его контроль, пока проклятье не будет снято.

Ниже представлена информация о конкретных видах оборотней.

**Вервепрь.** Персонаж получает Силу 17, если его текущий показатель меньше этого значения. Персонаж получает бонус +1 КД, находясь в облике кабана или гибридном облике (от природного доспеха). Броски атаки и урона _Клыков_ основаны на Силе. Для особенности _Атака в броске_ СЛ равна 8 + бонус мастерства персонажа + модификатор Силы.

**Вервольф.** Персонаж получает Силу 15, если его текущий показатель меньше этого значения. Персонаж получает бонус +1 КД, находясь в облике волка или гибридном облике (от природного доспеха). Броски атаки и урона природного оружия основаны на Силе.

**Веркрыса.** Персонаж получает Ловкость 15, если его текущий показатель меньше этого значения. Броски атаки и урона укуса основаны на Силе или Ловкости, в зависимости от того, какая характеристика выше.

**Вермедведь.** Персонаж получает Силу 19, если его текущий показатель меньше этого значения. Персонаж получает бонус +1 КД, находясь в облике медведя или гибридном облике (от природного доспеха). Броски атаки и урона природного оружия основаны на Силе.

**Вертигр.** Персонаж получает Силу 17, если его текущий показатель меньше этого значения. Броски атаки и урона природного оружия основаны на Силе. Для особенности _Наскок_ СЛ равна 8 + бонус мастерства персонажа + модификатор Силы.`,
    source: {
      id: SOURCE_MM,
      page: 189,
    },
  },
]
