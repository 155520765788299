import React from 'react'

import AdsPlacer from '@/components/AdsPlacer'

import './AdsAfterImagesStyles.less'

const AdsAfterImages = () => (
  <AdsPlacer
    blockId='R-A-2421058-3'
    className='AdsAfterImages'
    incrementIndex
  />
)

export default AdsAfterImages
