import React from 'react'

import ScriptPlacer from '@/components/ScriptPlacer'

const YandexMetrika = () => (
  <ScriptPlacer
    onlyProd
    scriptCode={`
      <div>
        <script type="text/javascript">
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        
            ym(55949917, "init", {
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true,
              webvisor:true
            });
        </script>

        <noscript>
          <div><img src="//mc.yandex.ru/watch/55949917" style="position:absolute; left:-9999px;" alt=""/></div>
        </noscript>
      </div>
    `}
  />
)

export default YandexMetrika
