const {SOURCE_GGTR} = require ('./../../sourceList')
const {CREATURE_TYPE_LOXODON} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_LOXODON,
  nameEn: 'Loxodon',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'локсодон',
        genitive: 'локсодона',
        dative: 'локсодону',
        accusative: 'локсодона',
        instrumental: 'локсодоном',
        prepositional: 'локсодоне',
      },
      [GENDER_FEMALE]: {
        nominative: 'локсодониха',
        genitive: 'локсодонихи',
        dative: 'локсодонихе',
        accusative: 'локсодониху',
        instrumental: 'локсодонихой',
        prepositional: 'локсодонихе',
      },
    },
    plural: {
      nominative: 'локсодоны',
      genitive: 'локсодонов',
      dative: 'локсодонам',
      accusative: 'локсодонов',
      instrumental: 'локсодонами',
      prepositional: 'локсодонах',
    },
  },
  description: [
    {
      header: 'Локсодоны',
      text: `Гуманоидные слоны, называемые локсодонами часто оказываются оазисами спокойствия на шумных улицах Равники. Они трубят или напевают звучными голосами и медленно идут или совершенно неподвижно сидят.

Будучи спровоцированными на действия, локсодоны становятся воплощениями ужаса, яростно трубя, вопя и хлопая ушами. Их безмятежная мудрость, яростная верность и непоколебимая убеждённость — очень важные качества для гильдий.`,
      source: {
        id: SOURCE_GGTR,
        page: 18,
      },
    },
    {
      header: 'Лесные великаны',
      text: `Локсодоны возвышаются над прочими гуманоидами, будучи более 7 футов в высоту. У ниъ слоноподобные хобот, бивни, уши и огромные толстые тела, покрытые толстой кожей. На каждой руке у локсодона по четыре толстых пальца, а их ступни — плоские и овальные, как у слонов.

Как и у слона, хобот локсодона — полезная конечность. Кроме того, что он даёт локсодону отличный нюх, хоботом локсодон может поднимать и переносить даже тяжёлые предметы. Хоботом локсодон может класть в рот еду и воду и даже использовать его как дыхательную трубку.`,
      source: {
        id: SOURCE_GGTR,
        page: 18,
      },
    },
    {
      header: 'Талантливые каменотёсы',
    text: `Локсодоны — неутомимые и терпеливые ремесленники, с непревзойдённой интуицией. Хотя из них получаются заботливые духовные лидеры, дар локсодонов к работе с камнем настолько естественнен для них, что они часто теряются, когда пытаются обучить этому других.

В конклаве Селезнии локсодоны в основном занимаются постройкой великолепных соборных дендрариев гильдии.`,
      source: {
        id: SOURCE_GGTR,
        page: 18,
      },
    },
    {
      header: 'Непримиримо верные',
      text: `Локсодоны верят в ценность жизни и общества и поэтому чаще всего встречаются в конклаве Селезнии. Некоторые из них находят себя, присоединяясь к упорядоченности синдиката Оржова и сената Азориусов.

Локсодоны верят что члены группы обязаны приглядывать друг за другом. Как только локсодон вступает в гильдию или каким-то образом связывается с другим индивидом, он посвящает себя поддержанию этой связи. Локсодоны координируют усилия и часто добровольно жертвуют собой во благо группы. Они ожидают такой же преданности и уровня ответственности от других членов своего сообщества и могут быть несдержаны в выражении своего разочарования, если их доверие будет предано.

Главное отличие между локсодонами, вступающими в разные гильдии — их чувство сообщества, к которому они принадлежат. Для локсодонов Селезнии, их сообщество — это весь мир и все живые существа в нём — все они ценны, взаимосвязаны и предназначены жить в гармонии друг с другом. Для локсодонов Азориусов сообщество обычно означает объединение многих людей, нуждающихся в соблюдении закона и порядка чтобы продолжать действовать сообща. Для тех же, кто входит в Синдикат Оржов, сообщество значит только сам синдикат, чьи интересы ставятся превыше интересов любой другой группы.`,
      source: {
        id: SOURCE_GGTR,
        page: 18,
      },
    },
    {
      header: 'Имена локсодонов',
      text: `Имена локсодонов включают низкие звуки, создаваемые в резонирующей носовой полости, которые означают статус, семейные связи и роль в сообществе каждого конкретного локсодона. Так как больишнство нелоксодонов не различают и не могут произносить такие низкие звуки, то локсодоны при общении с другими расами часто используют заменяющие их титулы, такие как Иерарх, Уважаемый, Бабушка, Лекарь или Святой.

# Мужские имена

Баюл, Беров, Бруж, Васул, Голомов, Добрун, Друж, Ильромов, Кел, Никум, Ондрос, Радомов, Светел, Тамуж, Трум, Херуж, Чедумов

# Женские имена

Ажж, Божа, Вериж, Весмова, Данку, Дужа, Жасу, Зариж, Зуря, Ириж, Катрун, Люда, Майя, Раду, Суфя, Тотур, Фанур, Шужа, Элюжа, Юльна`,
      source: {
        id: SOURCE_GGTR,
        page: 18,
      },
    },
  ],
}
