const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_PALADIN,
} = require('./../../../../../pcClassIdList')
const {
  MAGIC_ITEM_TALISMAN_OF_PURE_GOOD,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_TALISMAN_OF_PURE_GOOD,
  name: `Талисман чистого добра`,
  nameEn: `Talisman of Pure Good`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `требуется настройка существом с добрым мировоззрением`,
  isConsumable: true,
  description: `Этот талисман — могущественный символ добра. Существа, чьё мировоззрение и не доброе и не злое, получают урон излучением 6к6, когда прикасаются к талисману. Злое существо получает урон излучением 8к6, когда прикасается к талисману. Все эти существа получают такой же урон каждый раз, когда оканчивают ход, держа или неся талисман.

Если Вы — добрый [жрец](PC_CLASS:${PC_CLASS_CLERIC}) или [паладин](PC_CLASS:${PC_CLASS_PALADIN}), Вы можете использовать талисман как святой символ, и Вы получаете бонус +2 к броскам атаки заклинаний, пока носите или держите его.

У талисмана есть 7 зарядов. Если Вы носите или держите его, Вы можете действием потратить 1 заряд и выбрать одно существо, которое видите находящимся на полу в пределах 120 футов от Вас. Если у цели злое мировоззрение, под ней откроется огненная расщелина.

Цель должна пройти испытание Ловкости СЛ 20, иначе она упадёт в расщелину и уничтожится, не оставив после себя останков. Расщелина после этого закрывается, не оставляя следов. Когда Вы тратите последний заряд, талисман распадается искрами золотистого света и уничтожается.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 208,
  },
}
