const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_GRUNG} = require('./../../creatureTypeIdList')
const {
  grungNote,
  grungDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_GRUNG,
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'грунг',
      genitive: 'грунга',
      dative: 'грунгу',
      accusative: 'грунга',
      instrumental: 'грунгом',
      prepositional: 'грунге',
    },
    plural: {
      nominative: 'грунги',
      genitive: 'грунгов',
      dative: 'грунгам',
      accusative: 'грунгов',
      instrumental: 'грунгами',
      prepositional: 'грунгах',
    },
  },
  nameEn: 'Grung',
  note: grungNote,
  description: grungDescriptionList,
}
