const {CAT_ANIMALS} = require('./../../../gearCategoryList')
const {CREATURE_MULE} = require('./../../../creatureIdList')
const {GEAR_DONKEY} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')

module.exports = {
  id: GEAR_DONKEY,
  genderId: GENDER_MALE,
  name: 'Осёл',
  nameByCase: {
    nominative: 'осёл',
    genitive: 'осла',
    accusative: 'осла',
    instrumental: 'ослом',
  },
  nameEn: 'Donkey',
  creatureId: CREATURE_MULE,
  description: 'Скорость 40 фт. Грузоподъёмность 420 фунтов.',
  cost: 800,
  source: {
    id: SOURCE_PHB,
    page: 157,
  },
  category: CAT_ANIMALS,
}
