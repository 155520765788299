const {
    SOURCE_IDRotF,
  } = require('./../../../../sourceList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    SPELL_CHARM_PERSON,
    SPELL_CHILL_TOUCH,
    SPELL_COMPULSION,
    SPELL_DETECT_THOUGHTS,
    SPELL_HOLD_MONSTER,
    SPELL_HOLD_PERSON,
    SPELL_MAGE_HAND,
    SPELL_MIND_BLANK,
    SPELL_SLEEP,
    SPELL_TASHA_S_HIDEOUS_LAUGHTER,
    SPELL_ZONE_OF_TRUTH,
  } = require('./../../../../spellIdList'),
  {
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
    CONDITION_PRONE,
  } = require('./../../../../conditionList'),
  {
    LANG_ALL_KNOWN_AT_LIFE,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_ANY,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_RANGE_SPELL,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_UNUSUAL_NATURE,
  } = require('./../../../../creatureAbilityList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    CAST_NONE,
  } = require('./../../../../castComponentList'),
  {
    CREATURE_BRAIN_IN_A_JAR,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Мозг в банке',
  nameEn: 'Brain in a Jar',
  id: CREATURE_BRAIN_IN_A_JAR,
  description: `С помощью таинственного ритуала, соединяющего алхимию, некромантию и мрачную хирургическую точность, мозг смертного существа (с его согласия или без него) помещается в стеклянную банку с консервирующей жидкостью и жидкой слизью, полученной из его бывшего тела. Превращение делает мозг бессмертным и наделяет его псионическими силами, позволяя ему провести вечность, планируя и осуществляя свои желания.

Мозг в банке может говорить без голосовых связок, псионически проецируя наружу свой бестелесный голос так, что все вокруг могут его слышать. Ему так нравится общаться, что он может болтать часами напролёт, в том числе сам с собой ,если его некому слушать. Также он любит думать вслух, особенно о событиях и решениях, которые привели его к этому прекрасному состоянию.

Тем не менее, отделённость от собственного тела постепенно берёт своё. Чем дольше существует мозг в банке, тем вероятнее что он начнёт погружаться в безумие, легко поддаваясь деменции, шизофрении и паранойе.

# Бессмертные сосуды

Мозг парит в растворе внутри банки, реагируя пульсацией на своё окружение. Некоторые мозги могут от радости или раздражения начать биться о стенки своего сосуда. В зависимости от создателя, металлическая оправа банки может быть как ржавой, но ещё крепкой, так и элегантно выкованным шедевром. Мозг в банке весит около 125 фунтов.`,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_IDRotF,
    page: 280,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 10,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 3,
    [PARAM_CON]: 15,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_CHA,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
      isBlindOutside: true,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Обнаружение разума',
      description: `★СУЩЕСТВО★ может чувствовать присутствие и местоположение всех существ в радиусе 300 футов от себя, чей Интеллект 3 или выше, вне зависимости от барьеров и препятствий, кроме существ, защищённых заклинанием [Сокрытие разума](SPELL:${SPELL_MIND_BLANK}).`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_UNUSUAL_NATURE,
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_THOUGHTS,
          SPELL_MAGE_HAND,
          SPELL_ZONE_OF_TRUTH,
          {
            id: SPELL_CHILL_TOUCH,
            comment: 'смотрите атаку «Леденящее прикосновение»',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_CHARM_PERSON,
          SPELL_HOLD_PERSON,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_COMPULSION,
          SPELL_HOLD_MONSTER,
          SPELL_TASHA_S_HIDEOUS_LAUGHTER,
          {
            id: SPELL_SLEEP,
            comment: 'как заклинание 3 круга',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Леденящее прикосновение',
      comment: 'заговор',
      description: 'Цель не может восстанавливать хиты до начала следующего хода ★СУЩЕСТВО★. Если цель — нежить, она получает помехи на атаки  против ★СУЩЕСТВО★ до конца следующего хода ★СУЩЕСТВО★.',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 6,
        range: 120,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 3,
          diceType: 8,
        },
      },
    },
    {
      name: 'Взрыв разума',
      restore: 5,
      description: `★СУЩЕСТВО★ магически излучает психическую энергию 60-футовым конусом. Каждое существо в этой области должно пройти испытание Интеллекта СЛ 14 или получить 17 (3к8 + 4) психического урона и цель ошеломлена на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, заканчивая эффект на себе при успехе.`,
    },
  ],
}
