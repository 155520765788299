const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_19,
} = require('./../../../../crList')
const {
  SIZE_HUGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_TRUE_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  GEAR_LONGSWORD,
  GEAR_WHIP,
} = require('./../../../../gearIdList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_BALOR,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Балор',
  nameEn: 'Balor',
  id: CREATURE_BALOR,
  description: [
    `Часть древнего и ужасного зла, балоры командуют демоническими армиями, стремятся получить власть и уничтожают любого, кто встанет у них на пути.

Орудуя пламенным кнутом и мечом, наполненным силой шторма, балоры питают свой боевой дух ненавистью и яростью. В предсмертной агонии поток демонической ярости низвергается огненным взрывом, который может уничтожить даже самых крепких врагов.`,
    ...demonDescription,
  ],
  note: {
    text: `Призывать и контролировать демонов мучительно сложно. Это занятие не для слабых сердцем и духом`,
    author: `из ДЕМОНОМИКОНА ИГГВИЛЬВ`,
  },
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 85,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 21,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 80,
  },
  params: {
    [PARAM_STR]: 26,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 22,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 22,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_FIRE,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_19,
  featureList: [
    {
      name: 'Предсмертная агония',
      description: `Когда ★СУЩЕСТВО★ умирает, он взрывается, и все существа в пределах 30 футов от него должны пройти испытания Ловкости СЛ 20, получая урон огнём 70 (20к6) при провале, или половину этого урона при успехе. Взрыв воспламеняет горючие предметы в этой области, которые никто не несёт и не носит, и уничтожает оружие ★СУЩЕСТВО★.`,
    },
    {
      name: 'Огненная аура',
      description: `В начале каждого хода ★СУЩЕСТВО★ все существа в пределах 5 футов от него получают урон огнём 10 (3к6), а горючие предметы в ауре, которые никто не несёт и не носит, воспламеняются. Существо, касающееся ★СУЩЕСТВО★ или попадающее по ★нему★ рукопашной атакой, находясь в пределах 5 футов от ★него★, получает урон огнём 10 (3к6).`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Длинным мечом_, и одну _Кнутом_.`,
    },
    {
      name: 'Длинный меч',
      // gearId: GEAR_LONGSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      description: `Если ★СУЩЕСТВО★ совершает критическое попадание, ★он★ бросает кости урона три раза, а не два.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 3,
            diceType: 8,
            diceBonus: 8,
          },
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 3,
            diceType: 8,
          },
        ],
      },
    },
    {
      name: 'Кнут',
      // gearId: GEAR_WHIP, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      description: `Цель должна пройти испытание Силы СЛ 20, иначе её подтянет на 25 футов к ★СУЩЕСТВО★.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 30,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 8,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  genderId: GENDER_MALE,
}
