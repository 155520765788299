const {
  CREATURE_BUGBEAR,
  CREATURE_GOBLIN,
  CREATURE_HOBGOBLIN,
  CREATURE_OGRE,
} = require('./../../creatureIdList')
const {
  SOURCE_GAME_BG_3,
} = require('./../../sourceList')

module.exports = {
  header: 'Руководство по гоблинам для бегуна',
  text: `Поговорим о разновидностях гоблинов.

[Обычные гоблины](CREATURE:${CREATURE_GOBLIN}) хорошо вооружены и коварны, и хотя стратеги из них не самые лучшие, в больших группах они смертельно опасны.

[Хобгоблины](CREATURE:${CREATURE_HOBGOBLIN}) — народ более дисциплинированный. Они умнее, сильнее, и злобные, как мешок с ежами, так что естественным образом становятся вождями для своих собратьев.

[Багбиры](CREATURE:${CREATURE_BUGBEAR}) — крепкие, как [огры](CREATURE:${CREATURE_OGRE}), только куда сообразительнее, а если захотят, могут быть просто невероятно тихими. Избегайте этих хитрых громил.

Я? Ха!

Я избегаю их всех, дружище.

_— Автор: Колин Меренга_`,
  source: {
    id: SOURCE_GAME_BG_3,
  },
}
