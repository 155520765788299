const {
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
} = require('./../../sourceList')

const {
  CREATURE_GOBLIN,
  CREATURE_OGRE,
} = require('./../../creatureIdList')

const {
  PARAM_CON,
  PARAM_STR,
} = require('./../../paramList')
const {BACKGROUND_SOLDIER} = require('./../../backgroundIdList')
const {
  GEAR_ARROWS,
  GEAR_CHAIN_MAIL,
  GEAR_CROSSBOW_BOLTS,
  GEAR_CROSSBOW_LIGHT,
  GEAR_DUNGEONEER_S_PACK,
  GEAR_EXPLORER_S_PACK,
  GEAR_GREATSWORD,
  GEAR_HANDAXE,
  GEAR_LEATHER_ARMOR,
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_NET,
  GEAR_RAPIER,
  GEAR_SCALE_MAIL,
  GEAR_SCIMITAR,
  GEAR_SHIELD,
  GEAR_STUDDED_LEATHER_ARMOR,
  GEAR_TRIDENT,
} = require('./../../gearIdList')

const {
  CAT_HEAVY_ARMOR,
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_SHIELDS,
  CAT_SIMPLE_WEAPON,
  CAT_MARTIAL_WEAPON,
} = require('./../../gearCategoryList')

const {
  SKILL_ACROBATICS,
  SKILL_ANIMAL_HANDLING,
  SKILL_ATHLETICS,
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_SURVIVAL,
} = require('./../../skillList')

const {PC_CLASS_FIGHTER} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_FIGHTER,
  name: {
    singular: {
      nominative: 'воин',
      genitive: 'воина',
      dative: 'воину',
      accusative: 'воина',
      instrumental: 'воином',
      prepositional: 'воине',
    },
    plural: {
      nominative: 'воины',
      genitive: 'воинов',
      dative: 'воинам',
      accusative: 'воинов',
      instrumental: 'воинами',
      prepositional: 'воинах',
    },
  },
  nameEn: 'fighter',
  description: [
    `Женщина, прикрываясь [щитом](GEAR:${GEAR_SHIELD}) и лязгая доспехами, устремляется в толпу [гоблинов](CREATURE:${CREATURE_GOBLIN}). Из-за её спины одетый в [клёпаную кожу](GEAR:${GEAR_STUDDED_LEATHER_ARMOR}) эльф осыпает врагов стрелами, выпуская их из своего изящного лука. Невдалеке от них полуорк выкрикивает приказы, указывая лучшее направление для атаки.

Дварф в [кольчуге](GEAR:${GEAR_CHAIN_MAIL}) выставил [щит](GEAR:${GEAR_SHIELD}) перед компаньоном, отражая смертельный удар дубины [огра](GEAR:${CREATURE_OGRE}). Его напарник, полуэльф в [чешуйчатом доспехе](GEAR:${GEAR_SCALE_MAIL}), закрутил два своих [скимитара](GEAR:${GEAR_SCIMITAR}) в сверкающем вихре и двинулся в обход [огра](GEAR:${CREATURE_OGRE}), отыскивая брешь в его защите.

Опытный гладиатор сражается на арене и хорошо знает, как использовать свои [трезубец](GEAR:${GEAR_TRIDENT}) и [сеть](GEAR:${GEAR_NET}), чтобы опрокинуть противника и обойти его, вызывая ликование публики и получая тактическое преимущество. Меч его противника вспыхивает голубым светом и испускает сверкающую молнию.

Все эти герои — воины. Представители, возможно, самого разнообразного класса в мире D&D. Странствующие рыцари, военачальники-завоеватели, королевские чемпионы, элитная пехота, бронированные наёмники и короли разбоя — будучи воинами, все они мастерски владеют оружием, доспехами, и приёмами ведения боя. А ещё они хорошо знакомы со смертью — они несут её сами, и часто смотрят в её холодные глаза.`,
    {
      header: `Разносторонние специалисты`,
      text: `Воины владеют основами всех боевых стилей. Каждый воин может рубить топором, фехтовать [рапирой](GEAR:${GEAR_RAPIER}), владеет [длинным](GEAR:${GEAR_LONGSWORD}) и [двуручным мечом](GEAR:${GEAR_GREATSWORD}), может стрелять из лука и даже при некоторой сноровке способен поймать противника [сетью](GEAR:${GEAR_NET}). Помимо этого, воины хорошо знакомы с использованием [щита](GEAR:${GEAR_SHIELD}) и любых доспехов. Помимо общих знаний, каждый воин специализируется на определённом стиле боя. Некоторые концентрируются на стрельбе из лука, другие на сражении с оружием в каждой руке, а есть те, кто свои воинские способности усиливает заклинаниями. Сочетание широких общих навыков и углублённой специализации делает воинов непревзойдёнными на поле боя.`,
      source: {
        id: SOURCE_PHB,
        page: 71,
      },
    },
    {
      header: `Готовые к опасности`,
      text: `Не все члены городской стражи, деревенского ополчения или королевской армии являются воинами. Большинство из них это просто обученные солдаты, обладающие лишь основными воинскими навыками. Солдаты-ветераны, офицеры, обученные телохранители, посвящённые рыцари и похожие персоны, как правило, являются воинами.

Некоторые воины чувствуют потребность использовать свою подготовку в качестве искателей приключений. Исследование подземелий, убийство чудовищ, и другая опасная работа, обыденная для искателей приключений, является второй натурой воина, и не так сильно отличается от жизни, оставленной в прошлом. Риск здесь, возможно, и выше, но и награда значительно больше — например, воины в городском дозоре вряд ли могут найти меч _Язык пламени_.`,
      source: {
        id: SOURCE_PHB,
        page: 71,
      },
    },
    {
      header: `Воины`,
      text: `Будь то бесстрашный воин, инфантильный молодой солдат или тёртый наёмник — воинов можно найти повсюду в Королевствах. Даже самые мирные земли не обходятся без милиции для защиты от врагов, и многие величайшие правители Королевств были того или иного рода воинами. Для тех, кто знает, как вести себя в бою, всегда найдётся занятие.

Трудоспособное население многих регионов Побережья Меча и Севера изучают в рамках местной милиции как минимум основные принципы боя, прибегая к ним при необходимости, другие же идут дальше, становясь профессиональными солдатами, стражами или чем-то подобным. Офицеры, как правило, выходят из дворянства, хотя у способных лидеров есть возможность продемонстрировать свои навыки и подняться по служебной лестнице.

Воины, которых не прельщает карьера солдата, находят иные способы для демонстрации своих сил. Наёмники могут найти работу у того, кому нужны умелые бойцы, но нет времени или средств на их обучение. К таким работодателям относятся и группы искателей приключений, которым всегда пригодится надёжный воин.

Торговцы и гильдии нанимают охранников для защиты караванов, кораблей, своих складов и залов гильдий. Такая работа предполагает частые путешествия и опасность.

Немалая опасность исходит от воинов, отказавшихся от законной работы, чтобы стать бандитами — налёты на караваны, грабёж странников и разграбление одиноких подворий, особняков и деревень. Покинутые судьбой воины также могут принимать участие в гладиаторских боях или ином подобном кровавом спорте, зарабатывая на жизнь своими навыками, хотя таких развлечений практически не найдёшь на Побережье Меча и Севере, в отличие от южных стран наподобие Амна и Калимшана.`,
      source: {
        id: SOURCE_SCAG,
        page: 128,
      },
    },
    {
      header: `Создание воина`,
      text: `Когда Вы создаёте своего воина, продумайте два связанных элемента предыстории своего персонажа: где Вы получили боевую подготовку, и что отличало вас от обычных воителей, окружавших вас? Были ли Вы особенно безжалостным? Получали ли Вы особую помощь от наставника, возможно, из-за Вашей исключительной преданности? Что в первую очередь побудило вас к этой подготовке, угроза, нависшая над Вашей родиной, жажда мести, необходимость доказать себе, или все эти факторы вместе?

Вы могли пройти официальную подготовку в армии дворянина или в местном ополчении. Возможно, Вы тренировались в военной академии, изучая стратегию, тактику и военную историю. Или же Вы можете быть самоучкой, несовершенным, но проверенным бойцом. Взялись ли Вы за меч, чтобы сбежать от жизни на ферме, или следуете гордой фамильной традиции? Где Вы приобрели своё оружие и доспехи? Они могут быть военным наследием или семейными реликвиями, или, возможно, Вы экономили годами, чтобы купить их. Ваше вооружение теперь самое важное Ваше имущество, это единственное, что стоит межу вами и смертью.`,
      source: {
        id: SOURCE_PHB,
        page: 72,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать воина, следуя этим рекомендациям.

1. Во-первых, у Вашей Силы или Ловкости (в зависимости от того, хотите ли Вы сосредоточиться на рукопашном оружии или на стрельбе из лука или фехтовальном оружии) должно быть наивысшее значение. Следующим по величине должно быть значение Телосложения (или Интеллекта, если Вы планируете взять воинский архетип Мистический Рыцарь).
2. Во-вторых, выберите предысторию «[Солдат](BACKGROUND:${BACKGROUND_SOLDIER})». `,
      source: {
        id: SOURCE_PHB,
        page: 72,
      },
    },
  ],
  note: [
    {
      text: `„Ничего их не учит, да?“ — протянул седовласый рыцарь, потерявший свой шлем в прошлом бое: „Если так пойдёт, это будет похоже на ту заварушку в Драконьих челюстях! Надо бы поспросить моего менестреля написать об этом балладу…“

„Надеюсь, поёт он быстро“ — прорычал оруженосец Пурпурного Дракона: „Они уже здесь!“

Вой пронесся над следующей волной наступающих тел — мешаниной хлопающей кожаной брони, взмывающих мечей и бусин гоблинских глаз. Бежать или отступать было поздно, мужчины сжали кулаки, понимая, что косить в ритме смерти им придётся как косарям, перед которыми целое поле работы.`,
      author: `Трой Деннинг, «Смерть Дракона»`,
    },
    {
      text: `Палки и камни могут поломать мои кости, но мечи никогда не причинят мне вреда, пока я стою наверху и стреляю вниз под углом.`,
      author: `Занатар`,
    },
    {
      text: `Дайте мне знать, когда Вы все закончите говорить`,
      author: `Тордек`,
    },
  ],
  featureCollection: {
    hitDice: 10,
    proficiency: {
      armor: [
        CAT_LIGHT_ARMOR,
        CAT_MEDIUM_ARMOR,
        CAT_HEAVY_ARMOR,
        CAT_SHIELDS,
      ],
      weapon: {
        catList: [
          CAT_SIMPLE_WEAPON,
          CAT_MARTIAL_WEAPON,
        ],
      },
      savingThrow: [
        PARAM_STR,
        PARAM_CON,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_ACROBATICS,
            SKILL_ANIMAL_HANDLING,
            SKILL_ATHLETICS,
            SKILL_HISTORY,
            SKILL_INSIGHT,
            SKILL_INTIMIDATION,
            SKILL_PERCEPTION,
            SKILL_SURVIVAL,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 5,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) [Кольчуга](GEAR:${GEAR_CHAIN_MAIL})
    * б) [Кожаный доспех](GEAR:${GEAR_LEATHER_ARMOR}), [длинный лук](GEAR:${GEAR_LONGBOW}) и 20 [стрел](GEAR:${GEAR_ARROWS})
2. Выберите одно
    * а) Воинское оружие и [щит](GEAR:${GEAR_SHIELD})
    * б) Два воинских оружия
3. Выберите одно
    * а) [Лёгкий арбалет](GEAR:${GEAR_CROSSBOW_LIGHT}) и 20 [арбалетных болтов](GEAR:${GEAR_CROSSBOW_BOLTS})
    * б) Два [ручных топора](GEAR:${GEAR_HANDAXE})
4. Выберите одно
    * а) [Набор исследователя подземелий](GEAR:${GEAR_DUNGEONEER_S_PACK})
    * б) [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})`,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 71,
    },
    {
      id: SOURCE_SRD,
      page: 24,
    },
  ],
}
