module.exports = [
  ...require('./lists/AI'),
  ...require('./lists/EGTW'),
  ...require('./lists/FTD'),
  ...require('./lists/GGTR'),
  ...require('./lists/IDROTF'),
  ...require('./lists/TCoE'),
  ...require('./lists/PHB'),
  ...require('./lists/SCAG'),
  ...require('./lists/XGTE'),

  ...require('./lists/UA_2021_04_14_DRACONIC_OPTIONS'),

  ...require('./spellRawList_UA_PSIONIC_OPTIONS_REVISITED'),
  ...require('./spellRawList_UA_PSYCHIC_SOUL_PSIONICS'),
  ...require('./spellRawList_UA_STARTER_SPELLS'),
  ...require('./spellRawList_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS'),
]
