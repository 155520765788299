const {PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {SPELL_ALTER_SELF} = require('./../../../spellIdList')
const {
  CREATURE_AIR_ELEMENTAL,
  CREATURE_EARTH_ELEMENTAL,
  CREATURE_FIRE_ELEMENTAL,
  CREATURE_WATER_ELEMENTAL,
} = require('./../../../creatureIdList')
const {
  FEATURE_CIRCLE_FORMS,
  FEATURE_COMBAT_WILD_SHAPE,
  FEATURE_ELEMENTAL_WILD_SHAPE,
  FEATURE_PRIMAL_STRIKE,
  FEATURE_THOUSAND_FORMS,
  FEATURE_WILD_SHAPE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_COMBAT_WILD_SHAPE,
    name: `Боевой дикий облик`,
    nameEn: `Combat Wild Shape`,
    lvl: 2,
    text: `Вы получаете способность принимать [Дикий облик](FEATURE:${FEATURE_WILD_SHAPE}) бонусным действием вместо обычного.

Кроме того, в [Диком облике](FEATURE:${FEATURE_WILD_SHAPE}) Вы можете бонусным действием потратить ячейку заклинания, чтобы восстановить 1к8 хитов за каждый уровень потраченной ячейки.`,
  },
  {
    id: FEATURE_CIRCLE_FORMS,
    name: `Облики круга`,
    nameEn: `Circle Forms`,
    lvl: [2, 6],
    text: `Обряды круга Луны позволяют принимать облик более опасных животных.

Начиная со 2 уровня, можно превращаться в зверя с показателем опасности 1 (игнорируйте столбец «Макс. ПО» таблицы «Облик животного»).

Начиная с 6 уровня можно превращаться в зверя с показателем опасности вплоть до уровня друида, поделённого на 3 (округляя в меньшую сторону).`,
  },
  {
    id: FEATURE_PRIMAL_STRIKE,
    name: `Природный удар`,
    nameEn: `Primal Strike`,
    lvl: 6,
    text: `Ваши атаки в облике зверя считаются магическими для преодоления сопротивления и иммунитета к немагическим атакам и урону.`,
  },
  {
    id: FEATURE_ELEMENTAL_WILD_SHAPE,
    name: `Стихийный дикий облик`,
    nameEn: `Elemental Wild Shape`,
    lvl: 10,
    text: `Вы можете одновременно потратить два применения [Дикого облика](FEATURE:${FEATURE_WILD_SHAPE}), чтобы принять облик [водяного](CREATURE:${CREATURE_AIR_ELEMENTAL}), [воздушного](CREATURE:${CREATURE_WATER_ELEMENTAL}), [земляного](CREATURE:${CREATURE_EARTH_ELEMENTAL}) или [огненного элементаля](CREATURE:${CREATURE_FIRE_ELEMENTAL}).`,
  },
  {
    id: FEATURE_THOUSAND_FORMS,
    name: `Тысяча лиц`,
    nameEn: `Thousand Forms`,
    lvl: 14,
    text: `Вы понимаете, как магически изменять детали своего облика. Вы можете неограниченно творить заклинание [Смена обличья](SPELL:${SPELL_ALTER_SELF}).`,
    spellIdList: SPELL_ALTER_SELF,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON,
    source: {
      id: SOURCE_PHB,
      page: 70,
    },
  })
)
