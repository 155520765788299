const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPELL_DETECT_THOUGHTS,
  SPELL_DOMINATE_MONSTER,
  SPELL_LEVITATE,
  SPELL_PLANE_SHIFT,
} = require('./../../../../spellIdList')
const {
  LANG_DEEP_SPEECH,
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_ILLITHID,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CONDITION_INCAPACITATED} = require('./../../../../conditionList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_MIND_FLAYER} = require('./../../../../creatureIdList')
const {GEAR_BREASTPLATE} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {illithidDescriptionList} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Свежеватель разума`,
  nameAlt: [
    `Иллитид`,
    `Мозгоед`,
  ],
  nameEn: 'Mind Flayer',
  nameEnAlt: 'Illithid',
  id: CREATURE_MIND_FLAYER,
  description: illithidDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_ILLITHID,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 260,
  },
  armor: {
    ac: 15,
    gearId: GEAR_BREASTPLATE,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 12,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_7,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_LEVITATE,
          SPELL_DETECT_THOUGHTS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DOMINATE_MONSTER,
          {
            id: SPELL_PLANE_SHIFT,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: `Щупальца`,
      description: `Если размер цели не больше Среднего, она становится схваченной (СЛ высвобождения равна 15) и должна пройти испытание Интеллекта СЛ 15, иначе станет ошеломлённой, пока не перестанет быть схваченной.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 2,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Извлечение мозга`,
      description: `Если этот урон уменьшает хиты цели до 0, ★СУЩЕСТВО★ убивает цель, извлекая и пожирая её мозг.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          comment: `, которого ★схватил★ ★СУЩЕСТВО★`,
          limit: {
            creatureType: CREATURE_TYPE_HUMANOID,
            condition: CONDITION_INCAPACITATED,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 10,
          diceType: 10,
          diceBonus: 0,
        },
      },
    },
    {
      name: `Взрыв сознания`,
      restore: 5,
      description: `★СУЩЕСТВО★ магическим образом испускает психическую энергию 60-футовым конусом. Все существа в этой области должны пройти испытание Интеллекта СЛ 15, иначе получат урон психической энергией 22 (4к8+4) и станут ошеломлёнными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
