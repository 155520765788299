const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')

const {gearByCatCollection} = require('./../../../../../gearList')
const {CAT_WEAPON} = require('./../../../../../gearCategoryList')

const list = [
  {
    rarity: MGC_RARITY_UNCOMMON,
    bonus: 1,
  },
  {
    rarity: MGC_RARITY_RARE,
    bonus: 2,
  },
  {
    rarity: MGC_RARITY_VERY_RARE,
    bonus: 3,
  },
]
  .reduce(
    (
      list,
      {
        rarity,
        bonus,
      }
    ) => [
      ...list,
      ...gearByCatCollection[CAT_WEAPON]
        .reduce(
          (gearIdList, gearId) => gearIdList.includes(gearId)
            ? gearIdList
            : [
              ...gearIdList,
              gearId,
            ],
          []
        )
        .map(
          gearId => ({
            id: `${gearId}_plus_${bonus}`,
            gearId,
            rarity,
            bonus,
          })
        ),
    ],
    []
  )

module.exports = list
