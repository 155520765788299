module.exports = [
  require('./faerie_dragon_blue'),
  require('./faerie_dragon_green'),
  require('./faerie_dragon_light_blue'),
  require('./faerie_dragon_orange'),
  require('./faerie_dragon_red'),
  require('./faerie_dragon_violet'),
  require('./faerie_dragon_yellow'),
  require('./fire_elemental'),
  require('./fire_giant'),
  require('./fire_snake'),
  require('./flameskull'),
  require('./flumph'),
  require('./flying_snake'),
  require('./flying_sword'),
  require('./fomorian'),
  require('./frog'),
  require('./frost_giant'),
]
