const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const dartsDescription = require('./../../constants/dartsDescription')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {POISON_TYPE_INHALED} = require('./../../../poisonTypeList')
const {
  PC_RACE_DWARF,
  PC_RACE_GOBLIN,
} = require('./../../../pcRaceIdList')
const {
  VOLUME_UNIT_FEET_CUBE,
  VOLUME_UNIT_GALLON,
  VOLUME_UNIT_PINT,
} = require('./../../../volumeUnitList')
const {
  PARAM_DEX,
  PARAM_CON,
} = require('./../../../paramList')
const {
  SOURCE_DMG,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_XGTE,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../damageTypeList')
const {
  CAT_AMMUNITION,
  CAT_ARTISAN_TOOLS,
  CAT_CONTAINERS,
  CAT_EQUIPMENT,
  CAT_FOOD,
  CAT_EQUIPMENT_KIT,
  CAT_MARTIAL_RANGE_WEAPON,
  CAT_MEDIUM_ARMOR,
  CAT_MUSIC_TOOLS,
  CAT_POISONS,
  CAT_TRANSPORT,
} = require('./../../../gearCategoryList')
const {
  WEAPON_AMMUNITION,
  WEAPON_RANGE,
  WEAPON_RELOADING,
} = require('./../../../weaponPropList')
const {
  GEAR_BACKPACK,
  GEAR_BAGPIPES,
  GEAR_BALL_BEARINGS,
  GEAR_BANQUET,
  GEAR_BARREL,
  GEAR_BASKET,
  GEAR_BEDROLL,
  GEAR_BELL,
  GEAR_BIT_AND_BRIDLE,
  GEAR_BLANKET,
  GEAR_BLOCK_AND_TACKLE,
  GEAR_BLOWGUN,
  GEAR_BLOWGUN_NEEDLES,
  GEAR_BOOK,
  GEAR_BOTTLE_GLASS,
  GEAR_BREAD_LOAF,
  GEAR_BREASTPLATE,
  GEAR_BREWERS_SUPPLIES,
  GEAR_BUCKET,
  GEAR_BURGLAR_S_PACK,
  GEAR_BURNT_OTHUR_FUMES,
  GEAR_CANDLE,
  GEAR_CROWBAR,
  GEAR_HAMMER,
  GEAR_LANTERN_HOODED,
  GEAR_OIL,
  GEAR_PITON,
  GEAR_RATIONS,
  GEAR_ROPE_HEMP,
  GEAR_SHIELD,
  GEAR_TINDERBOX,
  GEAR_WARGONG,
  GEAR_WATERSKIN,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_BREASTPLATE,
    genderId: GENDER_MALE,
    name: 'Нагрудник',
    nameByCase: {
      nominative: 'нагрудник',
      genitive: 'нагрудника',
      accusative: 'нагрудник',
      instrumental: 'нагрудником',
    },
    nameAlt: 'Кираса',
    nameEn: 'Breastplate',
    description: `Этот доспех состоит из подогнанного металлического панциря, носимого с подкладкой из кожи. Несмотря на то, что руки и ноги остаются практически без защиты, этот доспех хорошо защищает жизненно важные органы, оставляя владельцу относительную подвижность.

Будьте осторожны, хотя ваша грудь может быть в безопасности, но ваши руки и ноги по-прежнему открыты`,
    cost: 40000,
    weight: 20,
    acChangeTo: 14,
    acUseDexMod: true,
    acDexModMax: 2,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_MEDIUM_ARMOR,
  },
  {
    id: GEAR_BLOWGUN,
    genderId: GENDER_FEMALE,
    name: 'Духовая трубка',
    nameByCase: {
      nominative: 'духовая трубка',
      genitive: 'духовой трубки',
      accusative: 'духовую трубку',
      instrumental: 'духовой трубкой',
    },
    nameEn: 'Blowgun',
    description: dartsDescription,
    damage: {
      diceBonus: 1,
    },
    cost: 1000,
    damageType: DAMAGE_PIERCING,
    weight: 1,
    source: [
      {
        id: SOURCE_PHB,
        page: 149,
      },
      {
        id: SOURCE_AYAGWnW,
        page: 114,
      },
    ],
    weaponPropList: [
      WEAPON_AMMUNITION,
      WEAPON_RELOADING,
      {
        id: WEAPON_RANGE,
        value: {
          normal: 25,
          max: 100,
        },
      },
    ],
    category: CAT_MARTIAL_RANGE_WEAPON,
  },
  {
    id: GEAR_BLOCK_AND_TACKLE,
    genderId: GENDER_MULTIPLE,
    name: 'Подъёмный блок',
    nameByCase: {
      nominative: 'подъёмный блок',
      genitive: 'подъёмного блока',
      accusative: 'подъёмный блок',
      instrumental: 'подъёмным блоком',
    },
    nameAlt: 'Блок и лебёдка',
    nameEn: 'Block and Tackle',
    description: 'Набор блоков и тросов с крюками для подвешивания предметов. Блок и лебёдка позволяют вам поднять в четыре раза больше, чем обычно.',
    cost: 100,
    weight: 5,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_BLOWGUN_NEEDLES,
    genderId: GENDER_MULTIPLE,
    name: 'Иглы для трубки',
    nameByCase: {
      nominative: 'иглы для трубки',
      genitive: 'игл для трубки',
      accusative: 'иглы для трубки',
      instrumental: 'иглами для трубки',
    },
    nameEn: 'Blowgun Needles',
    description: dartsDescription,
    quantity: 50,
    cost: 100,
    weight: 1,
    source: [
      {
        id: SOURCE_PHB,
        page: 150,
      },
      {
        id: SOURCE_AYAGWnW,
        page: 114,
      },
    ],
    category: CAT_AMMUNITION,
  },
  {
    id: GEAR_BARREL,
    genderId: GENDER_FEMALE,
    name: 'Бочка',
    nameByCase: {
      nominative: 'бочка',
      genitive: 'бочек',
      accusative: 'бочку',
      instrumental: 'бочкой',
    },
    nameEn: 'Barrel',
    cost: 200,
    volume: {
      unitId: VOLUME_UNIT_GALLON,
      count: 40,
    },
    weight: 70,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_BOTTLE_GLASS,
    genderId: GENDER_FEMALE,
    name: 'Бутылка, стеклянная',
    nameByCase: {
      nominative: 'стеклянная бутылка',
      genitive: 'стеклянной бутылки',
      accusative: 'стеклянная бутылку',
      instrumental: 'стеклянной бутылкой',
    },
    nameEn: 'Bottle, glass',
    cost: 200,
    weight: 2,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 1.5,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_BUCKET,
    genderId: GENDER_MIDDLE,
    name: 'Ведро',
    nameByCase: {
      nominative: 'ведро',
      genitive: 'ведра',
      accusative: 'ведро',
      instrumental: 'ведром',
    },
    nameEn: 'Bucket',
    cost: 5,
    weight: 2,
    volume: {
      unitId: VOLUME_UNIT_GALLON,
      count: 3,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_BOOK,
    genderId: GENDER_FEMALE,
    name: 'Книга',
    nameByCase: {
      nominative: 'книга',
      genitive: 'книги',
      accusative: 'книгу',
      instrumental: 'книгой',
    },
    nameEn: 'Book',
    description: 'В книге могут быть стихи, документальные сведения, информация о чём-либо, диаграммы и заметки о гномьих приспособлениях, или что угодно другое, представленное текстом и картинками.',
    cost: 2500,
    weight: 5,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_BELL,
    genderId: GENDER_MALE,
    name: 'Колокольчик',
    nameByCase: {
      nominative: 'колокольчик',
      genitive: 'колокольчика',
      accusative: 'колокольчик',
      instrumental: 'колокольчиком',
    },
    nameEn: 'Bell',
    cost: 100,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_BASKET,
    genderId: GENDER_MALE,
    name: 'Корзина',
    nameByCase: {
      nominative: 'корзина',
      genitive: 'корзины',
      accusative: 'корзина',
      instrumental: 'корзиной',
    },
    nameEn: 'Basket',
    description: '2 фт.³/40 фунтов',
    cost: 40,
    weight: 2,
    volume: {
      unitId: VOLUME_UNIT_FEET_CUBE,
      count: 2,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_BALL_BEARINGS,
    genderId: GENDER_MULTIPLE,
    name: 'Шарики, 1000',
    nameByCase: {
      nominative: 'Шарики, 1000',
      accusative: 'Шарики, 1000',
      instrumental: 'Шариками, 1000',
    },
    nameAlt: 'Металлические шарики',
    nameEn: 'Ball Bearings',
    description: `Любимец воров. Горсть шариков, брошенных на ровную поверхность, может создать мгновенное препятствие для любого, кто за вами гонится!

В сумке 1000 шт. Вы можете действием рассыпать из этого мешка крохотные металлические шарики, покрыв площадь 10 × 10 фт. Существа, перемещающиеся по этой области, должны пройти испытание Ловкости СЛ 10, иначе они падают ничком. Существо, перемещающееся по этой области с уменьшенной вдвое скоростью, не обязано проходить испытание`,
    cost: 100,
    quantity: 1000,
    weight: 2,
    saveThrow: {
      type: PARAM_DEX,
      dc: 10,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_BLANKET,
    genderId: GENDER_MIDDLE,
    name: 'Одеяло',
    nameByCase: {
      nominative: 'Одеяло',
      accusative: 'Одеяло',
      instrumental: 'Одеялом',
    },
    nameEn: 'Blanket',
    cost: 50,
    weight: 3,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_BACKPACK,
    genderId: GENDER_MALE,
    name: 'Рюкзак',
    nameByCase: {
      nominative: 'Рюкзак',
      accusative: 'Рюкзак',
      instrumental: 'Рюкзаком',
    },
    nameEn: 'Backpack',
    description: '1 фт³/30 фунтов',
    cost: 200,
    weight: 5,
    volume: {
      unitId: VOLUME_UNIT_FEET_CUBE,
      count: 1,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_BEDROLL,
    genderId: GENDER_MALE,
    name: 'Спальный мешок',
    nameByCase: {
      nominative: 'Спальный мешок',
      accusative: 'Спальный мешок',
      instrumental: 'Спальным мешком',
    },
    nameAlt: 'Спальник',
    nameEn: 'Bedroll',
    cost: 100,
    weight: 7,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_BURGLAR_S_PACK,
    genderId: GENDER_MALE,
    name: 'Набор взломщика',
    nameByCase: {
      nominative: 'набор взломщика',
      genitive: 'набора взломщика',
      accusative: 'набор взломщика',
      instrumental: 'набором взломщика',
    },
    nameEn: 'Burglar’s Pack',
    description: `Взломщики скажут вам, что то, что они делают — искусство; они не простые воры или грубые хулиганы. Они выбирают цель и идут тихо, беря только то, что им нужно, и покидают сцену как можно спокойнее.

В наборе взломщика содержится множество важных профессиональных инструментов, в том числе [фонарь](GEAR:${GEAR_LANTERN_HOODED}), который можно быстро закрыть, [колокольчик](GEAR:${GEAR_BELL}), который можно прикрепить к леске, чтобы предупредить о прохождении патрулей, много [веревки](GEAR:${GEAR_ROPE_HEMP}), немного [металлических шариков](GEAR:${GEAR_BALL_BEARINGS}), [ломик](GEAR:${GEAR_CROWBAR}) и [молоток](GEAR:${GEAR_HAMMER}) для взлома замков. Конечно, опытные грабители могут не использовать два последних, если знают, как управляться с набором отмычек.

Включает
    
* [рюкзак](GEAR:${GEAR_BACKPACK}),
* [сумку с 1 000 металлических шариков](GEAR:${GEAR_BALL_BEARINGS}),
* 10 футов лески,
* [колокольчик](GEAR:${GEAR_BELL}),
* 5 [свечек](GEAR:${GEAR_CANDLE}),
* [ломик](GEAR:${GEAR_CROWBAR}),
* [молоток](GEAR:${GEAR_HAMMER}),
* 10 [шлямбуров](GEAR:${GEAR_PITON}),
* [закрытый фонарь](GEAR:${GEAR_LANTERN_HOODED}),
* 2 [фляги масла](GEAR:${GEAR_OIL}),
* [рационы](GEAR:${GEAR_RATIONS}) на 5 дней,
* [трутницу](GEAR:${GEAR_TINDERBOX})
* [бурдюк](GEAR:${GEAR_WATERSKIN}).

В набор также входит 50-футовая [пеньковая верёвка](GEAR:${GEAR_ROPE_HEMP}), закреплённая сбоку.`,
    cost: 1600,
    weight: 39,
    source: {
      id: SOURCE_PHB,
      page: 151,
    },
    category: CAT_EQUIPMENT_KIT,
  },
  {
    id: GEAR_BREWERS_SUPPLIES,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты пивовара',
    nameByCase: {
      nominative: 'инструменты пивовара',
      genitive: 'инструментов пивовара',
      accusative: 'инструменты пивовара',
      instrumental: 'инструментами пивовара',
    },
    nameEn: 'Brewer’s Supplies',
    description: `Пивоварение — это искусство производства пива. Но этот процесс может использоваться не только для создания алкогольных напитков, но и для очищения воды. Для создания пива требуются недели на ферментацию, но всего несколько часов непосредственно на работу.

**Компоненты.** Инструменты пивовара включают в себя

* большую [стеклянную бутыль](GEAR:${GEAR_BOTTLE_GLASS}),
* некоторое количество хмеля,
* сифон,
* змеевик,
* несколько футов трубок.

**История.** Владение инструментами пивовара даёт вам дополнительные знания при проверках Интеллекта (История), касающихся событий, важной частью которых был алкоголь.

**Медицина.** Владение этими инструментами предоставляет дополнительные знания, когда Вы облегчаете страдания от алкогольного отравления, похмелья, или, когда используете алкоголь, чтобы притупить боль.

**Убеждение.** Крепкий напиток может помочь смягчить даже каменное сердце. Ваше владение инструментами пивовара может помочь вам угостить кого-то ровно таким количеством алкоголя, сколько понадобится для того, чтобы поднять ему настроение.

**Дистилляция.** Ваши знания пивоварения позволяют вам очищать воду, которая иначе была бы непригодной для питья. Как часть длинного отдыха Вы можете очистить до 6 галлонов воды или 1 галлон как часть короткого отдыха.

| Действие                          | СЛ |
|-----------------------------------|----|
| Обнаружить яд или примесь в питье | 10 |
| Опознать алкоголь                 | 15 |
| Игнорировать эффект алкоголя      | 20 |
`,
    cost: 2000,
    weight: 9,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 81,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_BAGPIPES,
    genderId: GENDER_FEMALE,
    name: 'Волынка',
    nameByCase: {
      nominative: 'волынка',
      genitive: 'волынки',
      accusative: 'волынку',
      instrumental: 'волынкой',
    },
    nameEn: 'Bagpipes',
    description: musicalToolDescriptionList,
    cost: 3000,
    weight: 6,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_WARGONG,
    genderId: GENDER_MALE,
    name: 'Боевой гонг',
    nameEn: 'Wargong',
    nameByCase: {
      nominative: 'боевой гонг',
      genitive: 'боевого гонга',
      accusative: 'боевой гонг',
      instrumental: 'боевым гонгом',
    },
    description: [
      `Металлический гонг, традиционно изготавливаемый из [щита](GEAR:${GEAR_SHIELD}), зачастую из щита противника. И [гоблины](PC_RACE:${PC_RACE_GOBLIN}), и [дварфы](PC_RACE:${PC_RACE_DWARF}) изготавливают и играют на боевых гонгах, звук которых разносится по всем туннелям в Подземье.`,
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_BIT_AND_BRIDLE,
    genderId: GENDER_FEMALE,
    name: 'Узда',
    nameByCase: {
      nominative: 'узда',
      genitive: 'узды',
      accusative: 'узду',
      instrumental: 'уздой',
    },
    nameAlt: 'Упряжь и уздечка',
    nameEn: 'Bit and Bridle',
    cost: 200,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT,
  },
  {
    id: GEAR_BANQUET,
    genderId: GENDER_MALE,
    name: 'Банкет',
    nameByCase: {
      nominative: 'банкет',
      genitive: 'банкета',
      accusative: 'банкет',
      instrumental: 'банкетом',
    },
    nameAlt: 'Торжественный обед',
    nameEn: 'Banquet',
    description: 'на 1 едока',
    cost: 1000,
    source: {
      id: SOURCE_PHB,
      page: 158,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_BREAD_LOAF,
    genderId: GENDER_MALE,
    name: 'Хлеб, буханка',
    nameByCase: {
      nominative: 'буханка хлеба',
      genitive: 'буханки хлеба',
      accusative: 'буханку хлеба',
      instrumental: 'буханкой хлеба',
    },
    nameAlt: 'Хлеб, ломоть',
    nameEn: 'Bread, loaf',
    cost: 2,
    source: {
      id: SOURCE_PHB,
      page: 158,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_BURNT_OTHUR_FUMES,
    genderId: GENDER_MALE,
    name: 'Яд, дым жжённого отура',
    nameByCase: {
      nominative: 'дым жжённого отура',
      genitive: 'дыма жжённого отура',
      accusative: 'дым жжённого отура',
      instrumental: 'дымом жжённого отура',
    },
    nameEn: 'Burnt Othur Fumes',
    description: `Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 13, иначе оно получит урон ядом 10 (3к6) и будет должно повторять это испытание в начале каждого своего хода. При каждом провале персонаж получит урон ядом 3 (1к6). После трёх успехов яд прекратит своё действие.`,
    damage: {
      diceType: 6,
      diceCount: 3,
    },
    saveThrow: {
      type: PARAM_CON,
      dc: 13,
    },
    cost: 50000,
    damageType: DAMAGE_POISON,
    poisonType: POISON_TYPE_INHALED,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
]

module.exports = gearRawList
