const {SOURCE_MM} = require('./../../sourceList')
const {GOD_MAGLUBIYET} = require('./../../godIdList')
const {PC_RACE_GOBLIN} = require('./../../pcRaceIdList')

module.exports = {
  header: 'Почитатели Маглубиета',
  text: `[Маглубиет Могучий, Владыка Глубин и Тьмы](GOD:${GOD_MAGLUBIYET}) — величайший из богов гоблиноидов. Большинство [гоблинов](PC_RACE:${PC_RACE_GOBLIN}) представляет его одиннадцатифутовым гоблином, покрытым боевыми шрамами, с чёрной кожей и горящими пламенем глазами. Ему поклоняются не из любви, но из страха. [Гоблины](PC_RACE:${PC_RACE_GOBLIN}) верят, что духи погибших в битве гоблинов вступают в ряды его армии на Ахероне.

Этой _«привилегии»_ — вечной тирании Маглубиета Могучего — большинство гоблинов страшится даже более самой смерти.`,
  source: {
    id: SOURCE_MM,
    page: 63,
  },
}
