const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GRAZZT,
  CREATURE_JACKAL,
  CREATURE_JACKALWERE,
  CREATURE_LAMIA,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_KEEN_HEARING_AND_SMELL,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {GEAR_SCIMITAR} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Шакальник`,
  nameEn: 'Jackalwere',
  id: CREATURE_JACKALWERE,
  description: `Шакальники — обыкновенные [шакалы](CREATURE:${CREATURE_JACKAL}), испорченные демоническими силами — рыщут по дорогам и тропам, подстерегая и убивая всех встречных.

У шакальников три физических облика, которые они могут принимать. В своём истинном облике его невозможно отличить от обычного шакала. Он может принять облик человека, часто представая в виде тощего и жалкого попрошайки. Когда же путники приглашают его к себе, чудовище принимает облик гибрида человеческих размеров, с шерстью и головой шакала, ходящего на двух ногах, и набрасывается на них.

# Обманщики и трусы

Демонический повелитель [Граз’зт](CREATURE:${CREATURE_GRAZZT}) создал шакальников, чтобы те служили его верным слугам — [ламиям](CREATURE:${CREATURE_LAMIA}). Он одарил шакалов даром речи и способностью принимать человеческие облики. Шакальники рождены, чтобы лгать, и наблюдательные персонажи могут заметить, как он кривится от боли, когда говорит правду.

Шакальники предпочитают драться в сопровождении [шакалов](CREATURE:${CREATURE_JACKAL}) и собратьев. Под руководством шакальников [шакалы](CREATURE:${CREATURE_JACKAL}) становятся свирепыми и преданными спутниками.

# Сверхъестественные слуги

Шакальники похищают гуманоидов для своих хозяев [ламий](CREATURE:${CREATURE_LAMIA}), обрекая жертв на пожизненное рабство или мучительную смерть. Магический взгляд шакальника лишает противника сознания, позволяя чудовищу вязать существо и тащить его прочь. Шакальник может также использовать свой взгляд, чтобы вывести из строя смертельно опасного врага и дать себе время сбежать.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 298,
  },
  armor: 12,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 11,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  languageList: [
    {
      id: LANG_COMMON,
      comment: 'не может говорить в форме шакала',
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: `Перевёртыш`,
      description: `★СУЩЕСТВО★ может действием превратиться в конкретного человека Среднего размера или в гибрид шакала и гуманоида, или же принять свой истинный облик ([шакал](CREATURE:${CREATURE_JACKAL}) Маленького размера). За исключением размера во всех обликах ★его★ характеристики остаются теми же самыми. Всё несомое и носимое им снаряжение не превращается. ★Он★ принимает свой истинный облик, если умирает.`,
    },
    ABILITY_KEEN_HEARING_AND_SMELL,
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      name: `Укус`,
      comment: `только в облике шакала или гибридном облике`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_SCIMITAR,
      comment: `только в облике человека или гибридном облике`,
    },
    {
      name: `Усыпляющий взгляд`,
      description: `★СУЩЕСТВО★ пристально смотрит на одно существо, видимое в пределах 30 футов. Цель должна пройти испытание Мудрости СЛ 10. При провале цель впадает в магическую дрёму и теряет сознание на 10 минут, или пока кто-нибудь не разбудит её действием. Успешно спасшееся от этого эффекта существо получает иммунитет к взгляду ★этого★ ★СУЩЕСТВО★ на следующие 24 часа. Нежить и существа, обладающие иммунитетом к очарованию, не попадают под его действие.`,
    },
  ],
  genderId: GENDER_MALE,
}
