const {
  CREATURE_BLACK_GAUNTLET_OF_BANE,
  CREATURE_FIST_OF_BANE,
  CREATURE_IRON_CONSUL,
} = require('./../../creatureIdList')
const {GOD_BANE} = require('./../../godIdList')
const {SOURCE_BGDIA} = require('./../../sourceList')

module.exports = [
  {
    header: `Бэйн и его последователи`,
    text: `[Бэйн](GOD:${GOD_BANE}) — бог тирании, он и его последователи жаждут править миром. Бэйн даёт силу тем, кто готов править железной рукой. Навязывая миру свой порядок, последователи Бэйна стремятся, чтобы умные, сильные и амбициозные достигали вершин, а слабые оставались в рабстве.

В какой бы форме Бэйн ни являлся, его всегда можно узнать по угольно-чёрной правой руке. Обычно он носит на ней рукавицу или перчатку, если хочет скрыть свою сущность, и раскрывается, лишь когда приходит время показать истинную мощь. Он всегда одет с иголочки в роскошный костюм благородного или броню и снаряжение успешного генерала наёмников. Его любимое оружие — булава.

Бэйн подавляет тех, кто проявит хоть каплю слабости, силой заставляет их покориться или сокрушает физической и магической мощью. Он так харизматичен, что способен убедить сильных присягнуть ему в обмен на власть над другими. Он пробуждает жажду власти, что есть в сердце любого смертного, тщательно продуманными посулами и лестью. Он — мозг Мёртвой Троицы, его амбиции направляют группу.

Преданные последователи Бэйна — воители, желающие править силой оружия и принуждением, жестокие тираны, обеспечивающие верность угрозами и дарами. Они порабощают слабых и одаряют сильных, превращая тех в верных вассалов.`,
    source: {
      id: SOURCE_BGDIA,
      page: 235,
    },
  },
  {
    header: `Жестокие тираны`,
    text: `Там, где последователи [Бэйна](GOD:${GOD_BANE}) захватывают власть, они устанавливают драконовские правила, чтобы защитить своё правление. Они подавляют любых соперников и щедро вознаграждают тех, кто им верен.`,
    source: {
      id: SOURCE_BGDIA,
      page: 235,
    },
  },
  {
    header: `Культ воинов`,
    text: `Культисты [Бэйна](GOD:${GOD_BANE}) — воители, они носят тяжёлую броню и сражаются булавами, мечами, копьями и арбалетами. Правые рукавицы своей брони они красят в чёрный в честь своего покровителя. Жрецы Бэйна носят чёрные булавы с навершиями в виде сжатого кулака.`,
    source: {
      id: SOURCE_BGDIA,
      page: 235,
    },
  },
  {
    header: `Иерархия культа`,
    text: `Культисты [Бэйна](GOD:${GOD_BANE}) подчиняются строгой военной субординации. Низшие в иерархии — [кулаки Бэйна](CREATURE:${CREATURE_FIST_OF_BANE}), солдаты, беспрекословно подчиняющиеся приказам. Ими командуют [железные консулы](CREATURE:${CREATURE_IRON_CONSUL}), коварные полевые офицеры, умело организующие кулаков в бою. [Чёрные рукавицы](CREATURE:${CREATURE_BLACK_GAUNTLET_OF_BANE}) — это жрецы, командующие консулами.`,
    source: {
      id: SOURCE_BGDIA,
      page: 235,
    },
  },
]
