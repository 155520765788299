const {
  SPELL_CHARM_PERSON,
  SPELL_DOMINATE_BEAST,
  SPELL_FIREBALL,
  SPELL_FRIENDS,
  SPELL_HASTE,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_INVISIBILITY,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MENDING,
  SPELL_MESSAGE,
  SPELL_STONESKIN,
  SPELL_SUGGESTION,
  SPELL_TONGUES,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_ENCHANTER} = require('./../../../../creatureIdList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Очарователь',
  nameEn: 'Enchanter',
  id: CREATURE_ENCHANTER,
  description: `Очарователи — специализированные волшебники, которые понимают, как изменять и управлять мыслями, используя магию. Они могут быть представительными и интересными, используя магию управления людьми только, когда обман и простое убеждение проваливаются, или они могут быть грубыми и требовательными, используя и полагаясь на очарованных, послушных фаворитов.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 219,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 4,
    },
  ],
  cr: CR_5,
  spellCast: {
    spellCasterLevel: 9,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      {
        id: SPELL_CHARM_PERSON,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Очарования 1 уровня и выше, перезаряжающее реакцию «Инстинктивное очарование»',
      },
      {
        id: SPELL_HOLD_MONSTER,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Очарования 1 уровня и выше, перезаряжающее реакцию «Инстинктивное очарование»',
      },
      {
        id: SPELL_HOLD_PERSON,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Очарования 1 уровня и выше, перезаряжающее реакцию «Инстинктивное очарование»',
      },
      {
        id: SPELL_SUGGESTION,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Очарования 1 уровня и выше, перезаряжающее реакцию «Инстинктивное очарование»',
      },
      {
        id: SPELL_DOMINATE_BEAST,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Очарования 1 уровня и выше, перезаряжающее реакцию «Инстинктивное очарование»',
      },

      SPELL_FIREBALL,
      SPELL_FRIENDS,
      SPELL_HASTE,
      SPELL_INVISIBILITY,
      SPELL_MAGE_ARMOR,
      SPELL_MAGE_HAND,
      SPELL_MAGIC_MISSILE,
      SPELL_MENDING,
      SPELL_MESSAGE,
      SPELL_STONESKIN,
      SPELL_TONGUES,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
    ],
  },
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
  ],
  reactionList: [
    {
      name: 'Инстинктивное очарование',
      comment: `перезаряжается после накладывания заклинания школы Очарования уровня 1 и выше`,
      description: `Очарователь пытается магией перенаправить атаку, совершаемую по нему существом, которое он видит в пределах 30 футов. Очарователь обязан решить, будете ли использовать это умение, до того как будет известно, попала атака или промахнулась.
 
 Атакующий должен пройти испытание Мудрости СЛ 14. При провале атакующий должен выбрать целью ближайшее существо (кроме Очарователя и себя). Если есть несколько потенциальных целей, атакующий сам выбирает из них цель.`,
    },
  ],
}
