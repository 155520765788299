const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_INTELLECT_FORTRESS,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_INTELLECT_FORTRESS,
  name: 'Крепость интеллекта',
  nameEn: 'Intellect Fortress',
  description: `На время действия заклинания Вы (или одно видимое Вами в пределах дальности заклинания согласное существо) получаете сопротивление психическому урону и преимущество к испытаниям Интеллекта, Мудрости и Харизмы.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше третьего. Существа должны находиться в пределах 30 футов друг от друга, когда Вы выбираете их целями.`,
  lvl: 3,
  magicSchoolId: MAGIC_ABJURATION,
  needConcentration: true,
  range: 30,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_HOUR, count: 1 },
  source: {
    id: SOURCE_TCoE,
    page: 154,
  },
}
