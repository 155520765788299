const {PC_SUBCLASS_WIZARD_SCHOOL_OF_DIVINATION} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  FEATURE_DIVINATION_SAVANT,
  FEATURE_EXPERT_DIVINATION,
  FEATURE_GREATER_PORTENT,
  FEATURE_PORTENT,
  FEATURE_SPELLBOOK,
  FEATURE_THE_THIRD_EYE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_DIVINATION_SAVANT,
    name: `Мастер Прорицания`,
    nameEn: `Divination Savant`,
    lvl: 2,
    text: `Золото и время, которое Вы тратите на копирование заклинания Прорицания в свою [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), уменьшаются вдвое.`,
  },
  {
    id: FEATURE_PORTENT,
    name: `Знамение`,
    nameEn: `Portent`,
    lvl: 2,
    text: `В Вашем сознании возникают проблески будущего.

Когда Вы заканчиваете длинный отдых, совершите два броска к20 и запишите их результаты. Вы можете заменить любой бросок атаки, испытание, или проверку характеристики, сделанную Вами или видимом Вами существом, одним из этих бросков предсказания. Вы должны сделать выбор о том, что так поступаете, до броска, и Вы можете заменить значение броска подобным путём только один раз за ход.

Каждый бросок предсказания может быть использован только один раз. Когда Вы заканчиваете длинный отдых, Вы теряете все неиспользованные броски предсказания.`,
  },
  {
    id: FEATURE_EXPERT_DIVINATION,
    name: `Эксперт прорицания`,
    nameEn: `Expert Divination`,
    lvl: 6,
    text: `Вы так легко творите заклинания школы Прорицания, что расходуете лишь малую часть своих сил.

Когда Вы сотворяете заклинание школы Прорицания 2 круга или выше, используя ячейку заклинаний, Вы восстанавливаете одну уже израсходованную ячейку заклинаний.

Восстанавливаемая ячейка должна быть ниже уровнем, чем заклинание, которое Вы накладываете, и не может быть выше 5 уровня.`,
  },
  {
    id: FEATURE_THE_THIRD_EYE,
    name: `Третий глаз`,
    nameEn: `The Third Eye`,
    lvl: 10,
    text: `Вы можете действием увеличить силу восприятия.

Когда Вы так поступаете, выберите одно преимущество, которое будет длиться, пока Вы не станете недееспособны или пока Вы не совершите короткий или длинный отдых. Вы не можете использовать это умение повторно, пока не завершите отдых.

# Большое понимание

Вы можете читать на любых языках.

# Видеть невидимое

Вы можете видеть находящихся на линии обзора невидимых существ и предметы в пределах 10 футов.

# Тёмное зрение

Вы получаете тёмное зрение в пределах 60 футов.

# Эфирный взгляд

Ваше зрение простирается на Эфирный План в пределах 60 футов.`,
  },
  {
    id: FEATURE_GREATER_PORTENT,
    name: `Великое знамение`,
    nameEn: `Greater Portent`,
    lvl: 14,
    text: `Видения в Ваших грёзах усиливаются и рисуют более точную картину того, что грядёт.

Вы совершаете три броска к20 для Вашего умения [Знамение](FEATURE:${FEATURE_PORTENT}) вместо двух.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_SCHOOL_OF_DIVINATION,
    source: {
      id: SOURCE_PHB,
      page: 120,
    },
  })
)
