const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {GENDER_FEMALE} = require('./../../genderList')
const {CREATURE_TYPE_OOZE} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_OOZE,
  nameEn: 'Ooze',
  genderId: GENDER_FEMALE,
  description: [
    {
      text: `Слизи — это студенистые существа, которые редко имеют фиксированную форму. В основном, они живут в подземельях или пещерах, питаясь падалью или существами, которые попались им на пути. Чёрная слизь и студенистые кубы — самые узнаваемые слизи.`,
      source: {
        id: SOURCE_MM,
        page: 9,
      },
    },
    {
      header: 'Жижа',
      text: `Эти желеобразные существа обычно обитают в пещерах и темницах, питаясь отбросами, падалью и неосторожными искателями приключений.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  name: {
    singular: {
      nominative: 'слизь',
      genitive: 'слизи',
      dative: 'слизи',
      accusative: 'слизь',
      instrumental: 'слизью',
      prepositional: 'слизи',
    },
    plural: {
      nominative: 'слизи',
      genitive: 'слизей',
      dative: 'слизям',
      accusative: 'слизей',
      instrumental: 'слизями',
      prepositional: 'слизях',
    },
  },
}
