const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  CREATURE_ANKHEG,
  CREATURE_CARRION_CRAWLER,
  CREATURE_DIRE_WOLF,
  CREATURE_GELATINOUS_CUBE,
  CREATURE_GHAST,
  CREATURE_GHOUL,
  CREATURE_MOUTH_OF_GROLANTOR,
  CREATURE_OTYUGH,
  CREATURE_ROPER,
} = require('./../../creatureIdList')
const {
  GOD_ANNAM,
  GOD_GROLANTOR,
} = require('./../../godIdList')
const {
  PC_RACE_ELF,
  PC_RACE_GNOME,
  PC_RACE_GOBLIN,
  PC_RACE_HALFLING,
} = require('./../../pcRaceIdList')

const giantDescriptionList = require('./giant')

module.exports = [
  {
    header: 'Холмовые великаны',
    text: `Холмовые великаны — эгоистичные и недалёкие громилы, охотящиеся ради пищи и совершающие набеги в постоянном поиске еды. Они бездумно бродят по холмам и лесам, поглощая всё, что могут, и заставляя меньших существ кормить их. Лень и тупость давно бы положила им конец, если бы не огромный размер и сила.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Примитивные',
    text: `Холмовые великаны живут в холмах и горных долинах по всему миру, собираясь в мазанках или хижинах из грубых брёвен. Их кожа смугла из-за жизни, проведённой на склонах холмов и сна под солнцем. Оружием им служат вырванные с корнем деревья и камни, поднятые с земли. Их пот смешивается с запахом носимых необработанных шкур животных, плохо сшитых волосами и кожаными ремнями.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Больше — значит лучше',
    text: `В мире холмовых великанов гуманоиды и животные это лёгкая добыча, на которую можно безнаказанно охотиться. Такие существа как драконы и другие великаны это суровые противники. Холмовые великаны оценивают силу по размеру.

Холмовые великаны не осознают, что подчиняются Уложению. Они знают только то, что другие великаны больше и сильнее, чем они, поэтому им надо подчиняться. Вождь племени холмовых великанов — обычно самый высокий и толстый, но который может при этом двигаться. Только в исключительных случаях холмовой великан, у которого мозгов больше, нежели мышц, с помощью хитрости получает расположение великанов более высокого статуса, умело подрывая общественный порядок.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Ненасытные пожиратели',
    text: `Если холмовым великанам нечем заняться, они едят так часто, как только могут. Холмовой великан охотится и добывает еду один или вместе с [лютым волком](CREATURE:${CREATURE_DIRE_WOLF}), так, чтобы не делиться с соплеменниками. Великан ест всё, кроме того, что на первый взгляд смертельно, как, например, известные ядовитые существа. Гнилое мясо — лёгкая добыча, впрочем, как и увядшие растения и даже грязь.

Фермеры боятся и ненавидят холмовых великанов. Какой-нибудь хищник, например, [анхег](CREATURE:${CREATURE_ANKHEG}), может вылезти на поле и съесть корову-другую, пока его не прогонят, а холмовой великан поглотит стадо крупнорогатого скота, потом перейдёт на овец, коз и куриц, а потом переключится на фрукты, овощи и зерно. Если же под рукой окажется и семья фермера, великан может перекусить и ей.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Глупые и смертоносные',
    text: `Благодаря способности переваривать практически что угодно, холмовые великаны пережили эры, но так и остались дикарями, питающимися и размножающимися в холмах как животные. Им никогда не требовалось адаптироваться или меняться, так что их разум и эмоции остались простыми и неразвитыми.

Не имея собственной культуры, холмовые великаны копируют традиции существ, за которыми наблюдают, прежде чем съесть. При этом они не думают о своём размере и весе. Племена холмовых великанов, старающиеся имитировать [эльфов](PC_RACE:${PC_RACE_ELF}), валили целые леса в попытках жить на деревьях. Другие хотели занять города и деревни гуманоидов, пока не столкнулись с дверьми и окнами зданий, после чего, от потуг войти, разрушались стены и крыши.

В общении холмовые великаны грубы, прямы и плохо разбираются в обмане. Холмового великана можно одурачить, и он будет убегать от другого великана, если несколько крестьян накроются одеялом и встанут друг другу на плечи, держа гигантскую раскрашенную тыквенную голову. Аргументы бессмысленны для холмового великана, однако умное существо иногда может подстрекнуть холмового великана на выгодные для себя действия.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Яростные хулиганы',
    text: `Холмовой великан, чувствующий, будто его обманули, оскорбили или выставили дураком, вымещает свою ужасающую ярость на всём, что попадается под руку. Даже размазав обидчика в кашу, великан продолжает буйствовать, пока не успокоится, не заметит что-нибудь более интересное или не проголодается.

Если холмовой великан провозглашает себя королём территории, на которой проживают гуманоиды, она управляется террором и тиранией. Его решения меняются вместе с настроением, и если он забудет свой самопровозглашённый титул, то может запросто съесть подданных.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Холмовые великаны',
    text: `Холмовые великаны живут, чтобы есть. Каждый, кто понимает этот факт, знает о них всё, что требуется`,
    source: {
      id: SOURCE_VGTM,
      page: 31,
    },
  },
  {
    header: 'Уложение чревоугодия',
    text: `Холмовые великаны — самые слабые среди истинных великанов. У них самый низкий рост, самые маленькие мозги, и они наименее амбициозны. Единственная область, в которой они выделяются, — это размер пояса.

Поскольку еда — единственное, что волнует холмовых великанов, во главе племени всегда стоит его самый жирный, самый тяжёлый член — наиболее успешный на поприще обжорства и потому наиболее уважаемый. Качества, которые другие существа ожидают или требуют от своих лидеров — такие, как интеллект, способность принимать решения и личный магнетизм — не имеют никакого значения для этих великанов. Они не признаются и не ценятся, за исключением того, что холмовой великан, соображающий немного лучше среднего, может обманом или запугиванием добыть себе побольше еды.`,
    source: {
      id: SOURCE_VGTM,
      page: 31,
    },
  },
  {
    header: 'Логово убожества и зловония',
    text: `Холмовые великаны не колеблясь запихивают в рот вещи самые гнилые и отталкивающие, что наводит на мысль, что у них либо нет чувства вкуса, либо их голод настолько всепоглощающий, что вкус не особенно важен. Какой бы ни была причина, суть заключается в том, что логова холмовых великанов грязные и вонючие. Разлагающиеся тушки и сломанные кости разбросаны повсюду. Земля пропитана кровью и собственными нечистотами великанов.

Пищеварительная система не каждого холмового великана настолько не избирательна; время от времени великан действительно заболевает, но большинство из них выздоравливают, не сделав никаких выводов из ситуации. Редкие исключения называются [пастью Гролантора](CREATURE:${CREATURE_MOUTH_OF_GROLANTOR}) — великаны, которых держали в неволе и морили голодом до истощения, прежде чем выпустить во время битвы или налета.

Вонь, которую источает берлога холмового великана, привлекает чудовищных падальщиков, таких как слизи, [верёвочники](CREATURE:${CREATURE_ROPER}), [ползающие падальщики](CREATURE:${CREATURE_CARRION_CRAWLER}) или [отиджи](CREATURE:${CREATURE_OTYUGH}). Холмовые великаны не приручают этих существ, но как правило, терпят их присутствие. Посещение [желатиновым кубом](CREATURE:${CREATURE_GELATINOUS_CUBE}) — вероятно, единственный тип «уборки», который когда-либо увидит логово холмового великана.

[Упыри](CREATURE:${CREATURE_GHOUL}) частенько бродят по окраинам поселений холмовых великанов, но они приветствуются меньше, чем другие виды падальщиков. С их большей хитростью — особенно, если в их главе стоит [вурдалак](CREATURE:${CREATURE_GHAST}) — упыри могут применять несложный обман, чтобы украсть еду великанов. Холмовые великаны не будут возражать, если [верёвочник](CREATURE:${CREATURE_ROPER}) утащит пару кусков, но рассердятся, если трое [упырей](CREATURE:${CREATURE_GHOUL}) украдут целую тушу.`,
    source: {
      id: SOURCE_VGTM,
      page: 31,
    },
  },
  {
    header: 'Вещи-вещи',
    text: `Холмовые великаны иногда забавляются бессмысленными играми, которые обычно включают в себя еду или пищу. Одна из таких игр называется вещи-вещи, в ней великаны соревнуются, сколько [полуросликов](PC_RACE:${PC_RACE_HALFLING}), [гномов](PC_RACE:${PC_RACE_GNOME}) или [гоблинов](PC_RACE:${PC_RACE_GOBLIN}) они могут поместить себе в рот за раз, не глотая.`,
    source: {
      id: SOURCE_VGTM,
      page: 31,
    },
  },
  {
    header: 'Гролантор: вечно голодный, никогда не сытый',
    text: `[Гролантор](GOD:${GOD_GROLANTOR}) — божество, наиболее почитаемое холмовыми великанами. Наименьший из шести сыновей [Аннама](GOD:${GOD_ANNAM}), паршивая овца семейства, он презираем братьями и родителями. Большинство проблем [Гролантора](GOD:${GOD_GROLANTOR}), однако, возникали по его собственной вине.

Гордясь своей огромной силой (его единственное стоящее качество), [Гролантор](GOD:${GOD_GROLANTOR}) отказался признать превосходство своих старших, более умных и сильных братьев, и настаивал на том, чтобы к нему относились как к равному. Он постоянно жаловался на бесконечный голод, но вместо того, чтобы самому охотиться, он хватал пищу с тарелок своих братьев и родителей.

Такое поведение вызвало много драк между [Гролантором](GOD:${GOD_GROLANTOR}) и его братьями, большинство из которых [Гролантор](GOD:${GOD_GROLANTOR}) проигрывал. Рассказы о [Гроланторе](GOD:${GOD_GROLANTOR}) неизменно заканчиваются появлением очередного шрама на его спине, который он получил, спасаясь бегством от гнева члена семьи, которого он вывел из себя своим оскорбительным хвастовством и эгоизмом.`,
    source: {
      id: SOURCE_VGTM,
      page: 31,
    },
  },
  ...giantDescriptionList,
]
