const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_8,
} = require('./../../../../crList')
const {
  SIZE_HUGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  dinoDescriptionList,
} = require('./../../../../textCommonParts')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_TYRANNOSAURUS_REX,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Тираннозавр рекс',
  nameEn: 'Tyrannosaurus Rex',
  id: CREATURE_TYRANNOSAURUS_REX,
  description: [
    `Этого огромного хищника боятся все остальные существа на его территории. Несмотря на его размер и вес, тираннозавр — отличный бегун. Он преследует всё, что может съесть. И есть не так много существ, которых он не пытался бы съесть. В поисках нормальной жертвы тираннозавр может перебиваться падалью, или любыми небольшими существами, пытающимися украсть его добычу.`,
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 97,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 25,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 19,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  cr: CR_8,
  actionList: [
    {
      name: 'Мультиатака',
      description: '★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Хвостом_. Он не может совершить обе атаки по одной и той же цели.',
    },
    {
      name: 'Укус',
      description: 'Если цель — существо с размером не больше Среднего, она становится схваченной (СЛ высвобождения 17). Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может кусать другую цель.',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 12,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 7,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
