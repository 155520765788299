const {ALPHABET_SUPERNAL} = require('./../../alphabetList')
const {
  LANG_ABYSSAL,
  LANG_ALL,
  LANG_COMMON,
  LANG_ELVEN,
  LANG_DRACONIC,
  LANG_DWARVISH,
  LANG_GOBLIN,
  LANG_GIANT,
  LANG_PRIMORDIAL,
} = require('./../../languageIdList')
const {
  PC_RACE_ELF,
  PC_RACE_HUMAN,
  PC_RACE_HALFLING,
  PC_RACE_DWARF,
} = require('./../../pcRaceIdList')
const {
  SOURCE_DND4_DMG,
  SOURCE_DND4_FRPG,
} = require('./../../sourceList')

module.exports = {
  id: LANG_ALL,
  alphabetId: ALPHABET_SUPERNAL,
  name: {
    nominative: 'Божественный',
    genitive: 'Божественного',
    instrumental: 'Божественным',
    prepositional: 'Божественном',
  },
  name5eOfficial: {
    nominative: 'Все языки',
    genitive: 'Всех языков',
    instrumental: 'Всеми языками',
    prepositional: 'Всех языках',
  },
  nameAlt: 'Все языки',
  nameEn: 'Supernal',
  nameEnAlt: 'All languages',
  description: [
    {
      text: `Божественный — это язык ангелов, дьяволов и божеств. Иногда он описывается диалектически (как Небесный и Инфернальный), когда используется ангелами и дьяволами, соответственно.`,
      source: {
        id: SOURCE_DND4_FRPG,
        page: 157,
      },
    },
    {
      text: `Боги имеют свой собственный Божественный язык. На нём же говорят их ангельские прислужники. Когда бог или ангел говорят на божественном, слушатель, не владеющий божественным, понимает слова как если бы говорящий говорил на понятном ему языке. Бог или ангел может решить скрыть смысл своих слов от такого слушателя, но обычно божественный язык универсален и понятен всем.

Когда боги создали расы мира, каждая раса слышала божественный язык по-своему, из-за фундаментальных особенностей её природы. Из-за этого появились основные языки — [Всеобщий](LANG:${LANG_COMMON}) у [людей](PC_RACE:${PC_RACE_HUMAN}) и [полуросликов](PC_RACE:${PC_RACE_HALFLING}), [эльфийский](LANG:${LANG_ELVEN}) у [эльфов](PC_RACE:${PC_RACE_ELF}), [гоблинский](LANG:${LANG_GOBLIN}) у гоблинских рас, [дварфийский](LANG:${LANG_DWARVISH}) у [дварфов](PC_RACE:${PC_RACE_DWARF}) и [драконий](LANG:${LANG_DRACONIC}) у драконов.

Первородные имели [собственный язык](LANG:${LANG_PRIMORDIAL}), не имеющий ничего общего с [божественным](LANG:${LANG_ALL}). Титаны и великаны адаптировали изменённую версию этого языка как свой [собственный язык](LANG:${LANG_GIANT}), а [язык Бездны](LANG:${LANG_ABYSSAL}) — это форма [Первородного](LANG:${LANG_PRIMORDIAL}), искажённая и искривлённая злом в сердце Бездны.

Эти основные языки распространились среди других существ мира и его планов, с разными диалектными отличиями, но всегда без значительных искажений.`,
      source: {
        id: SOURCE_DND4_DMG,
        page: 172,
      },
    },
    {
      header: `Слова Силы`,
      text: `Божественный язык включает слова силы — слова, чьи слоги содержат чистую магию творения. Персонажи игроков могут изучить основы общения на божественном языке, но не смогут освоить эти могущественные звуки. Смертные, изучающие божественный язык, не получают способность делать свои слова понятными для всех. Тексты, содержащие такие слова могут обладать мощными эффектами — и тома или свитки с ними могут быть артефактами сами по себе.`,
      source: {
        id: SOURCE_DND4_DMG,
        page: 172,
      },
    },
  ],
  isRealLang: true,
  isReady: false,
}
