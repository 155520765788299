const {
  SPELL_AID,
  SPELL_AURA_OF_LIFE,
  SPELL_AURA_OF_PURITY,
  SPELL_AURA_OF_VITALITY,
  SPELL_BANISHING_SMITE,
  SPELL_BANISHMENT,
  SPELL_BLESS,
  SPELL_BLINDING_SMITE,
  SPELL_BRANDING_SMITE,
  SPELL_CEREMONY,
  SPELL_CIRCLE_OF_POWER,
  SPELL_COMMAND,
  SPELL_COMPELLED_DUEL,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CRUSADER_S_MANTLE,
  SPELL_CURE_WOUNDS,
  SPELL_DAYLIGHT,
  SPELL_DEATH_WARD,
  SPELL_DESTRUCTIVE_WAVE,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINE_FAVOR,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENCODE_THOUGHTS,
  SPELL_FIND_GREATER_STEED,
  SPELL_FIND_STEED,
  SPELL_GEAS,
  SPELL_GENTLE_REPOSE,
  SPELL_HEROISM,
  SPELL_HOLY_WEAPON,
  SPELL_LESSER_RESTORATION,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_MAGIC_CIRCLE,
  SPELL_MAGIC_WEAPON,
  SPELL_PRAYER_OF_HEALING,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_RAISE_DEAD,
  SPELL_REMOVE_CURSE,
  SPELL_REVIVIFY,
  SPELL_SEARING_SMITE,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SPIRIT_SHROUD,
  SPELL_SPIRIT_SHROUD_2020_03_26,
  SPELL_STAGGERING_SMITE,
  SPELL_SUMMON_CELESTIAL,
  SPELL_SUMMON_CELESTIAL_SPIRIT_2020_03_26,
  SPELL_THUNDEROUS_SMITE,
  SPELL_WARDING_BOND,
  SPELL_WRATHFUL_SMITE,
  SPELL_ZONE_OF_TRUTH,
} = require('./../../spellIdList')

module.exports = [
  SPELL_AID,
  SPELL_AURA_OF_LIFE,
  SPELL_AURA_OF_PURITY,
  SPELL_AURA_OF_VITALITY,
  SPELL_BANISHING_SMITE,
  SPELL_BANISHMENT,
  SPELL_BLESS,
  SPELL_BLINDING_SMITE,
  SPELL_BRANDING_SMITE,
  SPELL_CEREMONY,
  SPELL_CIRCLE_OF_POWER,
  SPELL_COMMAND,
  SPELL_COMPELLED_DUEL,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CRUSADER_S_MANTLE,
  SPELL_CURE_WOUNDS,
  SPELL_DAYLIGHT,
  SPELL_DEATH_WARD,
  SPELL_DESTRUCTIVE_WAVE,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINE_FAVOR,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENCODE_THOUGHTS,
  SPELL_FIND_GREATER_STEED,
  SPELL_FIND_STEED,
  SPELL_GEAS,
  SPELL_GENTLE_REPOSE,
  SPELL_HEROISM,
  SPELL_HOLY_WEAPON,
  SPELL_LESSER_RESTORATION,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_MAGIC_CIRCLE,
  SPELL_MAGIC_WEAPON,
  SPELL_PRAYER_OF_HEALING,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_RAISE_DEAD,
  SPELL_REMOVE_CURSE,
  SPELL_REVIVIFY,
  SPELL_SEARING_SMITE,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SPIRIT_SHROUD,
  SPELL_SPIRIT_SHROUD_2020_03_26,
  SPELL_STAGGERING_SMITE,
  SPELL_SUMMON_CELESTIAL,
  SPELL_SUMMON_CELESTIAL_SPIRIT_2020_03_26,
  SPELL_THUNDEROUS_SMITE,
  SPELL_WARDING_BOND,
  SPELL_WRATHFUL_SMITE,
  SPELL_ZONE_OF_TRUTH,
]
