const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_BEARDED_DEVIL} = require('./../../../../creatureIdList')
const {GEAR_GLAIVE} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Барбазу',
  nameAlt: 'Бородатый дьявол',
  nameEn: 'Barbazu',
  nameEnAlt: 'Bearded Devil',
  id: CREATURE_BEARDED_DEVIL,
  description: [
    `Бородатые дьяволы служат ударной силой для архидьяволов, сражаясь плечом к плечу и упиваясь славой битвы. Они отвечают насилием на любое пренебрежение, реальное или воображаемое, упиваясь жестокостью, когда их зазубренные клинки разрубают врагов.

Бородатые дьяволы похожи на человека с заострёнными ушами, чешуйчатой кожей, длинным хвостом и когтями, выдающими их жестокую натуру. Эти дьяволы получили своё название из-за змееподобных отростков на подбородках, которые они используют, чтобы атаковать и отравлять врагов, ослабляя их опасным ядом.`,
    ...devilDescriptionList,
  ],
  note: devilNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 144,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 15,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_3,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Уверенный',
      description: `★СУЩЕСТВО★ не может быть ★испуган★, если видит в пределах 30 футов от себя союзное существо.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Бородой_, и одну _Глефой_.`,
    },
    {
      name: 'Борода',
      description: `Цель должна пройти испытание Телосложения СЛ 12, иначе станет отравленной на 1 минуту. Будучи отравленной таким образом, цель не может восстанавливать хиты. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_GLAIVE,
      description: `Если цель — существо, не являющееся ни нежитью, ни конструктом, она должна пройти испытание Телосложения СЛ 12, иначе будет терять 5 (1к10) хитов в начале каждого своего хода из-за инфернальной раны. Каждый раз, когда ★СУЩЕСТВО★ попадает этой атакой по цели, уже имеющей эту рану, урон от раны увеличивается на 5 (1к10). Любое существо может залечить рану, если действием совершит успешную проверку Мудрости (Медицина) СЛ 12. Эта рана также закрывается, если цель получит магическое лечение.`,
    },
  ],
  genderId: GENDER_MALE,
}
