const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    CR_0,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BEAST,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    SPEED_FLY,
    SPEED_WALK,
    SPEED_CLIMB,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_PACK_TACTICS,
  } = require('./../../../../creatureAbilityList'),
  {
    SPELL_FIND_FAMILIAR,
  } = require('./../../../../spellIdList'),
  {
    CREATURE_FLYING_MONKEY,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Летающая обезьяна',
  nameEn: 'Flying Monkey',
  id: CREATURE_FLYING_MONKEY,
  couldBeFamiliar: true,
  description: [
    {
      header: 'Летающая обезьяна',
      text: `Летающие обезьяны немного более умны и любопытны, чем обычные обезьяны, и их можно одомашнить и научить соблюдать простые команды. Они бывают разных цветов и разновидностей, с пернатыми крыльями, длина которых составляет около 5 футов.

С разрешения Мастера, при помощи заклинания [Поиск фамильяра](SPELL:${SPELL_FIND_FAMILIAR}) можно вызвать летающую обезьяну.`,
      source: {
        id: SOURCE_TOA,
        page: 232,
      },
    },
  ],
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 232,
  },
  armor: 12,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_CLIMB]: 20,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  cr: CR_0,
  featureList: [
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 1,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
