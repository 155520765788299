const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_ABYSSAL_WRETCH,
  CREATURE_RUTTERKIN,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {LANG_ABYSSAL} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_WISH} = require('./../../../../spellIdList')

module.exports = {
  name: 'Руттеркин',
  nameEn: 'Rutterkin',
  id: CREATURE_RUTTERKIN,
  description: [
    `Руттеркины, вид искажённых демонов, бродят по Бездне толпами, которые постоянно ищут чужаков, чтобы окружить их и сожрать.`,
    {
      header: 'Защитники из Бездны',
      text: `Руттеркины защищают Бездну от не демонических существ. Заметив чужаков, они собираются толпой и движутся вперёд к цели, образуя устрашающую волну, которая вселяет своим жертвам страх, отчего те застывают на месте.`,
      source: {
        id: SOURCE_MTOF,
        page: 136,
      },
    },
    {
      header: 'Деформирующее заражение',
      text: `Существа, укушенные руттеркинами, подвергаются ужасной болезни, что заражает их развращающим воздействием Бездны. Тела существ, которые поддались такой болезни, испытывают невероятную боль и становятся обезображенными ходячими трупами, чья плоть свисает с костей. В скором времени, они становятся [тварями Бездны](CREATURE:${CREATURE_ABYSSAL_WRETCH}), чудовищами, что следуют по пятам руттеркинов.`,
      source: {
        id: SOURCE_MTOF,
        page: 136,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 136,
  },
  armor: 12,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 17,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ABYSSAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Искажённый ужас',
      description: `Если существо, отличное от демона, начинает ход в пределах 30 фт. от трёх или более руттеркинов, оно должно пройти испытание Мудрости СЛ 11. Если существо находится в пределах 30 фт. от шести и более руттеркинов, то этот испытание проходится с помехой. При успехе существо становится невосприимчивым к этому навыку руттеркинов на следующие 24 часа. При провале существо становится Испуганным руттеркинами на 1 минуту. Испуганное таким образом существо обездвиживается. В конце каждого своего хода Испуганное существо может повторять испытание, завершая эффект в случае успеха.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Если цель — существо, то оно должно пройти испытание Телосложения СЛ 13 от болезни, или стать отравленной. В конце каждого длинного отдыха оно может повторять испытание, заканчивая эффект Отравления в случае успеха. Если хиты цели опускаются до 0, пока она находится под действием болезни, она умирает и немедленно превращается в живую [Тварь Бездны](CREATURE:${CREATURE_ABYSSAL_WRETCH}). Такое изменение тела может быть отменено лишь заклинанием [исполнение желаний](SPELL:${SPELL_WISH}).`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
