const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SPELL_ALTER_SELF,
  SPELL_COMMAND,
  SPELL_DETECT_MAGIC,
  SPELL_PLANE_SHIFT,
} = require('./../../../../spellIdList')
const {
  CREATURE_CAMBION,
  CREATURE_GRAZZT,
  CREATURE_INCUBUS,
  CREATURE_SUCCUBUS,
} = require('./../../../../creatureIdList')
const {
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  GEAR_SCALE_MAIL,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')
const {ALIGNMENT_ANY_EVIL} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_TYPE_FIEND} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')

module.exports = {
  name: 'Камбион',
  nameEn: 'Cambion',
  id: CREATURE_CAMBION,
  description: [
    {
      header: 'Камбионы',
      text: `Камбионы являются отпрысками исчадий (обычно [суккубов](CREATURE:${CREATURE_SUCCUBUS}) или [инкубов](CREATURE:${CREATURE_INCUBUS})) и гуманоидов (обычно людей). Камбионы наследуют черты обоих родителей, однако рога, кожистые крылья и жилистые хвосты явно указывают на их чужеродное происхождение.`,
      source: {
        id: SOURCE_MM,
        page: 168,
      },
    },
    {
      header: `Камбионы`,
      text: `Камбионы со своими алыми глазами и скверным характером — плод смешения дьяволов и смертных: слияние двух планов в одном теле.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: 'Рождённые быть плохими',
      text: `Взрослея, камбионы развратом и пороками повергают в ужас даже самых преданных смертных родителей. Даже в юности камбионы позиционируют себя как владыки смертных. Они могут организовать бунты в городах, собирая банды гуманоидов и младших дьяволов себе в услужение.`,
      source: {
        id: SOURCE_MM,
        page: 168,
      },
    },
    {
      header: 'Пешки сильных',
      text: `Камбионы вынуждены служить исчадьям, из восхищения и страха, но втайне ожидая, что однажды займут их место. Камбионы, выросшие в Девяти Преисподних, служат солдатами, посланниками и личными слугами дьяволов. В Бездне камбионы занимают своё место исходя из своей силы и воли.`,
      source: {
        id: SOURCE_MM,
        page: 168,
      },
    },
    {
      header: 'Порождения Граз’зта',
      text: `Демонический повелитель [Граз’зт](CREATURE:${CREATURE_GRAZZT}) любит сношаться с гуманоидами, которые заключили сделку с исчадиями, и он породил множество камбионов, которые помогают ему сеять хаос по всей мультивселенной. Отпрыски Граз’зта отличаются угольно-чёрной кожей, раздвоенными копытами, шестипалыми руками и невероятной красотой.`,
      source: {
        id: SOURCE_MM,
        page: 168,
      },
    },
  ],
  note: {
    text: `Они выбираются из утроб матерей, чтобы нести разрушение. Ну как таких не любить?`,
    author: `Баба Яга`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
  ],
  alignmentId: ALIGNMENT_ANY_EVIL,
  source: {
    id: SOURCE_MM,
    page: 168,
  },
  armor: {
    ac: 19,
    gearId: GEAR_SCALE_MAIL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 16,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_INT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_INFERNAL,
    LANG_COMMON,
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Благословение исчадия',
      description: `★Его★ КД включает бонус Харизмы.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
          SPELL_COMMAND,
          SPELL_ALTER_SELF,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          {
            id: SPELL_PLANE_SHIFT,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки или два раза использует _Огненный луч_.`,
    },
    {
      gearId: GEAR_SPEAR,
      attack: {
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 1,
          diceType: 6,
        },
      },
    },
    {
      name: 'Огненный луч',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 7,
        range: 120,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 3,
          diceType: 6,
        },
      },
    },
    {
      name: 'Чары исчадия',
      description: `Один гуманоид, которого ★СУЩЕСТВО★ видит в пределах 30 футов, должен пройти испытание Мудрости СЛ 14, иначе станет магическим образом очарованным на 1 день. Очарованная цель подчиняется устным командам ★СУЩЕСТВО★. Если цель терпит вред от ★СУЩЕСТВО★ или другого существа, или получает самоубийственную команду от ★СУЩЕСТВО★, цель может повторить испытание, оканчивая эффект на себе при успехе. Если испытание цели было пройдено, или эффект на ней закончился, она получает иммунитет к _Чарам исчадия_ этого ★СУЩЕСТВО★ на следующие 24 часа.`,
    },
  ],
  genderId: GENDER_MALE,
}
