const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  GOD_ANNAM,
  GOD_DIANCASTRA,
  GOD_GROLANTOR,
  GOD_HIATEA,
  GOD_IALLANIS,
  GOD_MEMNOR,
  GOD_SKORAEUS_STONEBONES,
  GOD_STRONMAUS,
  GOD_SURTUR,
  GOD_THRYM,
  GOD_VAPRAK,
} = require('./../../godIdList')
const {
  CREATURE_BAPHOMET,
  CREATURE_CLOUD_GIANT,
  CREATURE_FIRE_GIANT,
  CREATURE_FROST_GIANT,
  CREATURE_OGRE,
  CREATURE_HILL_GIANT,
  CREATURE_KOSTCHTCHIE,
  CREATURE_STONE_GIANT,
  CREATURE_STORM_GIANT,
  CREATURE_TROLL,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Боги великанов',
    text: `Когда древние империи великанов пали, [Аннам](GOD:${GOD_ANNAM}), отец всех великанов, покинул мир и своих детей. Он поклялся никогда не приглядывать за ними, пока великаны не возродят свою славу и не вернут своё первородное право быть властелинами мира. В итоге великаны молятся не Аннаму, а его небесным детям, наравне с множеством божеств-героев и божественных негодяев, что составляют пантеон великанов.
    
Главные из этих богов — сыновья [Аннама](GOD:${GOD_ANNAM}), представляющие каждый вид великанов: [Стронмаус](GOD:${GOD_STRONMAUS}) для [штормовых](CREATURE:${CREATURE_STORM_GIANT}), [Мемнор](GOD:${GOD_MEMNOR}) для [облачных](CREATURE:${CREATURE_CLOUD_GIANT}), [Скораус Камнекост](GOD:${GOD_SKORAEUS_STONEBONES}) для [каменных](CREATURE:${CREATURE_STONE_GIANT}), [Трим](GOD:${GOD_THRYM}) для [ледяных](CREATURE:${CREATURE_FROST_GIANT}), [Сурт](GOD:${GOD_SURTUR}) для [огненных](CREATURE:${CREATURE_FIRE_GIANT}) и [Гролантор](GOD:${GOD_GROLANTOR}) для [холмовых](CREATURE:${CREATURE_HILL_GIANT}).

Однако не все великаны поклоняются божеству своего вида. Большинство добрых [облачных великанов](CREATURE:${CREATURE_CLOUD_GIANT}) отказывается служить [Мемнору](GOD:${GOD_MEMNOR}), а [штормовые великаны](CREATURE:${CREATURE_STORM_GIANT}), живущие в ледяных горах на севере, могут почитать [Трима](GOD:${GOD_THRYM}), а не [Стронмауса](GOD:${GOD_STRONMAUS}).

Есть и великаны, чувствующие сильную связь с дочерьми [Аннама](GOD:${GOD_ANNAM}), среди которых [Хиатея](GOD:${GOD_HIATEA}) — охотница и домохозяйка, [Илланис](GOD:${GOD_IALLANIS}) — богиня любви и мира, и [Дианкастра](GOD:${GOD_DIANCASTRA}) — импульсивная и высокомерная обманщица.
    
Некоторые великаны отринули своих богов, став жертвой культов, вознося молитвы [Бафомету](CREATURE:${CREATURE_BAPHOMET}) и [Кощище](CREATURE:${CREATURE_KOSTCHTCHIE}). Молитвы им или любому другому божеству не из пантеона великанов — великое преступление перед Уложением, так что такие великаны почти наверняка отступники.`,
    source: {
      id: SOURCE_MM,
      page: 37,
    },
  },
  {
    header: 'Отпрыски Аннама — великанский пантеон',
    text: `Когда Остория пала, [Аннам](GOD:${GOD_ANNAM}) отрёкся от своих детей, поклявшись никогда не смотреть на великанов снова, пока они не вернут Остории её былую известность и восстановят свои законные позиции в качестве правителей мира. Поэтому великаны не молятся [Аннаму](GOD:${GOD_ANNAM}), который отказывается их слышать. Вместо этого они почитают его божественных детей, а также сонм других героев-богов и божественных злодеев, которые являются младшими членами пантеона.

Главные среди богов великанов — шестеро сыновей [Аннама](GOD:${GOD_ANNAM}): братья [Стронмаус](GOD:${GOD_STRONMAUS}) (герой и любимец [каменных великанов](CREATURE:${CREATURE_STONE_GIANT})), [Мемнор](GOD:${GOD_MEMNOR}) ([облачные великаны](CREATURE:${CREATURE_CLOUD_GIANT})), [Сурт](GOD:${GOD_SURTUR}) ([огненные великаны](CREATURE:${CREATURE_FIRE_GIANT})), [Трим](GOD:${GOD_THRYM}) ([ледяные великаны](CREATURE:${CREATURE_FROST_GIANT})), [Скораус Камнекост](GOD:${GOD_SKORAEUS_STONEBONES}) ([каменные великаны](CREATURE:${CREATURE_STONE_GIANT})) и Гролантор ([холмовые великаны](CREATURE:${CREATURE_HILL_GIANT})).

Хотя каждому из сыновей [Аннама](GOD:${GOD_ANNAM}), как правило, поклоняются великаны определённого типа, они, как и сам [Аннам](GOD:${GOD_ANNAM}), расово неразличимы. [Стронмаус](GOD:${GOD_STRONMAUS}), к примеру, не выглядит как [штормовой великан](CREATURE:${CREATURE_STORM_GIANT}), как он часто изображается в резьбе и другом искусстве. Как [Аннам](GOD:${GOD_ANNAM}) и каждый из его братьев, [Стронмаус](GOD:${GOD_STRONMAUS}) является уникальным божественным существом, без смертного эквивалента. Его темперамент и интересы аналогичны [штормовым великанам](CREATURE:${CREATURE_STORM_GIANT}), поэтому большинство его последователей именно этого типа.

Подобные утверждения могут быть сделаны о других пяти братьях. Большинство [облачных великанов](CREATURE:${CREATURE_CLOUD_GIANT}) почитают [Мемнора](GOD:${GOD_MEMNOR}), но многие его отвергают из-за его лживости и вместо этого восхваляют [Стронмауса](GOD:${GOD_STRONMAUS}). [Штормовой великан](CREATURE:${CREATURE_STORM_GIANT}), живущий среди метели и айсбергов в далеком северном море может отдать должное [Триму](GOD:${GOD_THRYM}), а не [Стронмаусу](GOD:${GOD_STRONMAUS}). Великаны, которые разуверились в Уложении иногда поклоняются [Вапраку Уничтожителю](GOD:${GOD_VAPRAK}), который признан великанами как отец [троллей](CREATURE:${CREATURE_TROLL}) и [огров](CREATURE:${CREATURE_OGRE}).

Великаны не поклоняются исключительно божествам мужского пола. Партнёрша [Аннама](GOD:${GOD_ANNAM}) Отеа, [Хиатеа](GOD:${GOD_HIATEA}) охотница и хранительница очага, [Ялланис](GOD:${GOD_IALLANIS}) — богиня любви и мира, и [Даянкастра](GOD:${GOD_DIANCASTRA}), импульсивная и высокомерная воровка, также имеют своих почитателей. Как и люди, некоторые великаны даже стали жертвой демонических культов, в которых они поклоняются лордам демонов, таким как [Бафомету](CREATURE:${CREATURE_BAPHOMET}) или [Кощище](CREATURE:${CREATURE_KOSTCHTCHIE}). Поклонение подобным существам, или любым не великанским божествам, считается великим грехом против Уложения. Быть уличенным в этом значит быть изгнанным из семьи и рода.`,
    source: {
      id: SOURCE_VGTM,
      page: 21,
    },
  },
]
