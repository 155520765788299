const {
  CAST_ROYALTY,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {SOURCE_AI} = require('./../../../../../sourceList')
const {SPELL_GIFT_OF_GAB} = require('./../../../../../spellIdList')
const {TIME_REACTION} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_GIFT_OF_GAB,
  name: 'Дар болтливости',
  nameEn: 'Gift of Gab',
  description: `Говорят, что, изобретая это заклинания, Джим Тёмномагия, первоначально назвав его _«Чего я сказал?!»_ Вы когда-нибудь, общаясь с монархом, случайно упоминали, что его сын выглядит точно как ваш любимый боров в родительском свинарнике? Да у всех такое было! Но вместо того, чтобы быть лишиться головы за свой предательский язык, Вы можете притвориться, что этого никогда не было, гарантируя, что никто не узнает, что это произошло.

Когда Вы сотворяете это заклинание, Вы мастерски изменяете воспоминания слушателей вокруг, заставляя каждое существо по Вашему выбору в пределах 5 футов забыть всё, что Вы говорили в последние 6 секунд. Вместо этого эти существа помнят, что Вы произносили слова словесного компонента этого заклинания.`,
  lvl: 2,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_ROYALTY],
  royaltyCost: 200,
  castTime: { timeId: TIME_REACTION, count: 1 },
  castTimeComment: `которую Вы используете, когда Вы говорите с другим существом`,
  source: {
    id: SOURCE_AI,
    page: 77,
  },
}
