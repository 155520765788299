const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_TETHER_ESSENCE} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_TETHER_ESSENCE,
  name: 'Связующая эссенция',
  nameEn: 'Tether Essence',
  description: `Выберите двух существ, видимых Вами в пределах дистанции. Они должны пройти испытание Телосложения. Если они находятся в пределах 30 футов друг от друга, то они получают помеху к этому испытанию. Существо может добровольно провалить испытание. Если хотя бы одно из существ преуспеет в испытании, заклинание не оказывает эффекта. 

Если оба существа проваливают испытание, они магически связываются, пока действует заклинание, вне зависимости от разделяющего их расстояния. Когда одному из них будет нанесён урон, такой же урон будет нанесён другому. Если одно из них восстановит хиты, столько же хитов восстановит другое. Если хиты одного из связанных существа опустятся до 0, заклинание на обоих оканчивается. Если заклинание оканчивается на одном из существ, оно оканчивается и на втором.`,
  lvl: 7,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `моток платиновой проволоки стоимостью не менее 250 зм, расходуемый заклинанием`,
  hasCost: true,
  hasConsumable: true,
  isDunamisBased: true,
  duration: { timeId: TIME_HOUR, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CON,
  },
  source: {
    id: SOURCE_EGTW,
    page: 190,
  },
}
