const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GRIFFON,
  CREATURE_HIPPOGRIFF,
  CREATURE_PEGASUS,
  CREATURE_WYVERN,
} = require('./../../../../creatureIdList')
const {
  LANG_CELESTIAL,
  LANG_COMMON,
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CG} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_TYPE_CELESTIAL} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')

module.exports = {
  name: 'Пегас',
  nameEn: 'Pegasus',
  id: CREATURE_PEGASUS,
  description: [
    {
      header: 'Пегасы',
      text: `Белокрылые пегасы парят в небесах прообразом грации и величия. Они спускаются на землю лишь на мгновения, чтобы утолить жажду из горных ручьев и девственно чистых озёр. Любой звук или присутствие другого существа пугает их, и они взмывают обратно в облака.`,
      source: {
        id: SOURCE_MM,
        page: 233,
      },
    },
    {
      header: 'Пегасы',
      text: `Пегасы родом из Арбореи. Там они служат эльфийским богам, паря на подобных широким веерам расправленных крыльях над пышными садами, и перья их сияют благословениями и жизненной силой.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: 'Благородные ездовые животные',
      text: `Пегасы высоко ценятся за скорость и надёжность, будучи более быстрыми и менее темпераментными, чем [грифоны](CREATURE:${CREATURE_GRIFFON}), [гиппогрифы](CREATURE:${CREATURE_HIPPOGRIFF}) и [виверны](CREATURE:${CREATURE_WYVERN}). Тем не менее, эти дикие и пугливые создания умны как гуманоиды, и не могут быть объезжены и приручены традиционным способом. Пегаса нужно убедить служить ездовым животным для существа с добрым мировоззрением, и когда это случается, между ним и его новым товарищем образуется связь на всю жизнь.`,
      source: {
        id: SOURCE_MM,
        page: 233,
      },
    },
    {
      header: 'Рождённые на планах',
      text: `Происхождение пегасов уходит корнями в Олимпийские просторы Арбореи, где они парили в небесах этого плана и служили верными ездовыми животными для Селдарин, пантеона эльфийских божеств. Известно, что эти божества посылали пегасов в Материальный план для помощи тем, кто нуждается.`,
      source: {
        id: SOURCE_MM,
        page: 233,
      },
    },
    {
      header: 'Гнездовья пегасов',
      text: `Пегасы образуют пары на всю жизнь, строят гнезда в труднодосягаемых местах и рожают там молодняк.`,
      source: {
        id: SOURCE_MM,
        page: 233,
      },
    },
  ],
  note: {
    text: `Смотри, это Пегас. Он может обогнать дракона в открытом небе, и только лучшие из нас могут надеяться когда-либо оседлать одного из них. Достойная эмблема для нашего великого дома, как ты думаешь?`,
    author: `Тиленвэйн д’Ориен, отмеченный драконом потомок и гордый представитель Дома Ориен`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_CG,
  source: {
    id: SOURCE_MM,
    page: 233,
  },
  armor: 12,
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 60,
    [SPEED_FLY]: 90,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  languageList: [
    {
      id: [
        LANG_CELESTIAL,
        LANG_COMMON,
        LANG_ELVEN,
        LANG_SYLVAN,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  actionList: [
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
