const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_DUERGAR} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_DUERGAR,
  nameEn: 'Duergar',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  notRealRace: true,
  name: {
    singular: {
      nominative: 'двергар',
      genitive: 'двергара',
      dative: 'двергару',
      accusative: 'двергара',
      instrumental: 'двергаром',
      prepositional: 'двергаре',
    },
    plural: {
      nominative: 'двергары',
      genitive: 'двергаров',
      dative: 'двергарам',
      accusative: 'двергаров',
      instrumental: 'двергарами',
      prepositional: 'двергарах',
    },
  },
}
