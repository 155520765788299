const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  GEAR_MACE,
} = require('./../../../../../gearIdList')
const {
  CAT_SIMPLE_MELEE_WEAPON,
} = require('./../../../../../gearCategoryList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_MACE_OF_TERROR,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_MACE_OF_TERROR,
  name: `Булава ужаса`,
  nameEn: `Mace of Terror`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_MACE,
  gearCategory: CAT_SIMPLE_MELEE_WEAPON,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `У этого оружия есть 3 заряда. Если Вы его держите, Вы можете действием потратить 1 заряд, чтобы испустить волну ужаса.

Все существа на Ваш выбор в пределах 30 футов должны пройти испытание Мудрости СЛ 15, иначе станут испуганными Вами на 1 минуту.

Будучи испуганным таким способом, существо должно тратить ходы на то, чтобы переместиться как можно дальше от Вас, и не может добровольно перемещаться в пространство в пределах 30 футов от Вас. Оно также не может совершать реакции. Из всех своих действий существо может использовать только Рывок или пытаться освободиться от эффекта, препятствующего его передвижению. Если двигаться некуда, существо может использовать действие Уклонение. В конце каждого своего хода существо может повторять испытание, оканчивая эффект при успехе.

Булава ежедневно восстанавливает 1к3 заряда на рассвете.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 153,
  },
}
