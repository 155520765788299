const {PC_SUBCLASS_WIZARD_SCHOOL_OF_CONJURATION} = require('./../../../pcSubClassIdList')
const {
  SOURCE_PHB,
  SOURCE_ERRATA_PHB,
} = require('./../../../sourceList')
const {
  FEATURE_BENIGN_TRANSPOSITION,
  FEATURE_CONJURATION_SAVANT,
  FEATURE_DURABLE_SUMMONS,
  FEATURE_FOCUSED_CONJURATION,
  FEATURE_MINOR_CONJURATION,
  FEATURE_SPELLBOOK,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_CONJURATION_SAVANT,
    name: `Мастер вызова`,
    nameEn: `Conjuration Savant`,
    lvl: 2,
    text: `Золото и время, которое Вы тратите на копирование заклинания Вызова в свою [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), уменьшаются вдвое.`,
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
  {
    id: FEATURE_MINOR_CONJURATION,
    name: `Малый вызов`,
    nameEn: `Minor Conjuration`,
    lvl: 2,
    text: `Вы можете действием создать неодушевлённый предмет в своей руке или на земле в видимом Вами в пределах 10 футов свободном пространстве.

Этот предмет не должен превышать в длину 1 фут (30 сантиметров) и весить более 10 фунтов, и его форма должна быть как у немагического предмета, который Вы уже видели. Видно, что предмет магический, и он излучает тусклый свет в пределах 5 футов.

Предмет исчезает через 1 час, когда Вы используете это умение вновь, или когда он наносит или получает любой урон.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 117,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь предмет исчезает и при нанесении им урона, а не только при получении',
        page: 2,
      },
    ],
  },
  {
    id: FEATURE_BENIGN_TRANSPOSITION,
    name: `Безопасное перемещение`,
    nameEn: `Benign Transposition`,
    lvl: 6,
    text: `Вы можете действием телепортироваться на 30 футов в свободное пространство, которое можете видеть.

В качестве альтернативы, Вы можете выбрать пространство в пределах этого диапазона, занятое существом Маленького или Среднего размера. Если это существо согласно, Вы оба телепортируетесь, меняясь местами.

Использовав это умение один раз, Вы не можете использовать его вновь до окончания длинного отдыха или пока не сотворите заклинание школы Вызова 1 круга или выше.`,
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
  {
    id: FEATURE_FOCUSED_CONJURATION,
    name: `Фокусированный вызов`,
    nameEn: `Focused Conjuration`,
    lvl: 10,
    text: `Когда Вы концентрируетесь на заклинании школы Вызова, Ваша концентрация не может быть нарушена в результате получения урона.`,
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
  {
    id: FEATURE_DURABLE_SUMMONS,
    name: `Надёжный призыв`,
    nameEn: `Durable Summons`,
    lvl: 14,
    text: `Все призванные или созданные Вами заклинанием школы Вызова существа имеют 30 временных хитов.`,
    source: {
      id: SOURCE_PHB,
      page: 117,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_SCHOOL_OF_CONJURATION,
  })
)
