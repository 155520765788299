const {
  SPELL_BANISHMENT,
  SPELL_COMMAND,
  SPELL_COMPELLED_DUEL,
  SPELL_DESTRUCTIVE_WAVE,
  SPELL_DISPEL_MAGIC,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_HOLD_PERSON,
  SPELL_MAGIC_WEAPON,
  SPELL_SEARING_SMITE,
  SPELL_STAGGERING_SMITE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_DEATH_KNIGHT,
  CREATURE_NIGHTMARE,
  CREATURE_OGRE,
  CREATURE_WARHORSE_SKELETON,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_SKELETON,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_LONGSWORD,
  GEAR_PLATE_ARMOR,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_PARRY,
} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_17} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PC_CLASS_PALADIN} = require('./../../../../pcClassIdList')
const {PC_RACE_ELF} = require('./../../../../pcRaceIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Рыцарь смерти',
  nameEn: 'Death Knight',
  id: CREATURE_DEATH_KNIGHT,
  description: [
    {
      header: `Рыцарь смерти`,
      text: `Когда падший [паладин](PC_CLASS:${PC_CLASS_PALADIN}) умирает, не получив искупления, тёмные силы могут превратить некогда рыцаря в ужасную нежить. Рыцарь смерти — скелет, одетый в жуткие латы. Под шлемом виден череп со злыми, горящими в пустых глазницах, огнями.`,
      source: {
        id: SOURCE_MM,
        page: 253,
      },
    },
    {
      header: `Мистические силы`,
      text: `Рыцарь смерти сохраняет способность сотворять божественные заклинания. Однако, ни один рыцарь смерти не может исцелять своей магией. Рыцарь смерти может привлекать младшую нежить и командовать ей, хотя, если он служит могущественным исчадиям, скорее всего вместо нежити будут их миньоны. Часто рыцарь смерти в качестве скакуна использует [кошмара](CREATURE:${CREATURE_NIGHTMARE}) или [скелет боевого коня](CREATURE:${CREATURE_WARHORSE_SKELETON}).`,
      source: {
        id: SOURCE_MM,
        page: 253,
      },
    },
    {
      header: `Бессмертный до искупления`,
      text: `Рыцарь смерти восстанет, даже если его уничтожить. Только после искупления грехов он может сбежать из темницы нежизни и действительно погибнуть.`,
      source: {
        id: SOURCE_MM,
        page: 253,
      },
    },
    {
      header: `Природа нежити`,
      text: `Рыцарю смерти не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 253,
      },
    },
    {
      header: `Лорд Сот`,
      text: `Лорд Сот начал своё грехопадение с акта героизма, cпаcя [эльфийку](PC_RACE:${PC_RACE_ELF}) Изольду от [огра](CREATURE:${CREATURE_OGRE}). Сот и Изольда влюбились, но Сот уже был женат. Он убил свою жену, но слуга это заметил и публично обвинил его, но он бежал с Изольдой. Когда его замок пал под осадой, он взмолился о помощи, и ему было сказано, что он должен искупить свои проступки, выполнив задание, но растущие опасения по поводу верности Изольды заставили его отказаться от этого задания. Из-за того, что его миссия не была выполнена, великий катаклизм охватил землю. Когда Изольда родила сына, Сот отказался верить, что ребёнок был его, и убил их обоих. Все они сгорели во время пожара, охватившего замок, но Сот не нашёл покоя, лишившись жизни, став рыцарем смерти.`,
      source: {
        id: SOURCE_MM,
        page: 253,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 253,
  },
  armor: {
    ac: 20,
    gearId: [GEAR_PLATE_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 19,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 20,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
    PARAM_CHA,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
  ],
  cr: CR_17,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: `Предводитель нежити`,
      description: `Если ★СУЩЕСТВО★ дееспособен, ★он★ и все существа-нежить по ★его★ выбору в пределах 60 футов от него с преимуществом проходят испытания от эффектов, изгоняющих нежить.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 19,
    spellCasterClass: PC_CLASS_PALADIN,
    spellIdList: [
      SPELL_BANISHMENT,
      SPELL_COMMAND,
      SPELL_COMPELLED_DUEL,
      {
        id: SPELL_DESTRUCTIVE_WAVE,
        comment: 'некротическая энергия',
      },
      SPELL_DISPEL_MAGIC,
      SPELL_ELEMENTAL_WEAPON,
      SPELL_HOLD_PERSON,
      SPELL_MAGIC_WEAPON,
      SPELL_SEARING_SMITE,
      SPELL_STAGGERING_SMITE,
    ],
    slotCountList: [
      0,
      4,
      3,
      3,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает три атаки _Длинным мечом_.`,
    },
    {
      gearId: GEAR_LONGSWORD,
      attack: {
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 4,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
    {
      name: `Сгусток адского пламени`,
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ бросает шар магического огня, который взрывается в точке, которую ★он★ видит в пределах 120 футов. Все существа в сфере с радиусом 20 футов с центром на этой точке должны пройти испытание Ловкости СЛ 18. Эта сфера огибает углы. Существо получает урон огнём 35 (10к6) и урон некротической энергией 35 (10к6) при провале, или половину этого урона при успехе.`,
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 6,
    },
  ],
  genderId: GENDER_MALE,
}
