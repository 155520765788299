const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_PALADIN,
  FEATURE_AURA_OF_COURAGE,
  FEATURE_AURA_OF_PROTECTION,
  FEATURE_CLEANSING_TOUCH,
  FEATURE_DIVINE_HEALTH,
  FEATURE_DIVINE_SENSE,
  FEATURE_DIVINE_SMITE,
  FEATURE_EXTRA_ATTACK_PALADIN,
  FEATURE_FIGHTING_STYLE_PALADIN,
  FEATURE_IMPROVED_DIVINE_SMITE,
  FEATURE_LAY_ON_HANDS,
  FEATURE_SACRED_OATH,
  FEATURE_SPELLCASTING_PALADIN,
} = require('./../../featureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../sourceList')

const {CREATURE_ORC} = require('./../../creatureIdList')
const {BACKGROUND_NOBLE} = require('./../../backgroundIdList')

const {
  GOD_BAHAMUT,
  GOD_TORM,
  GOD_TYR,
} = require('./../../godIdList')
const {halfCasterSpellSlotTable} = require('./../../spellSlotTable')

const {
  PARAM_CHA,
  PARAM_WIT,
} = require('./../../paramList')

const {
  GEAR_CHAIN_MAIL,
  GEAR_EXPLORER_S_PACK,
  GEAR_JAVELIN,
  GEAR_PLATE_ARMOR,
  GEAR_PRIEST_S_PACK,
  GEAR_SHIELD,
  GEAR_SPEAR,
} = require('./../../gearIdList')

const {
  CAT_HEAVY_ARMOR,
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_SHIELDS,
  CAT_SIMPLE_WEAPON,
  CAT_MARTIAL_WEAPON,
} = require('./../../gearCategoryList')

const {
  SKILL_ATHLETICS,
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_MEDICINE,
  SKILL_PERSUASION,
  SKILL_RELIGION,
} = require('./../../skillList')

const {PC_CLASS_PALADIN} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_PALADIN,
  name: {
    singular: {
      nominative: 'паладин',
      genitive: 'паладина',
      dative: 'паладину',
      accusative: 'паладина',
      instrumental: 'паладином',
      prepositional: 'паладине',
    },
    plural: {
      nominative: 'паладины',
      genitive: 'паладинов',
      dative: 'паладинам',
      accusative: 'паладинов',
      instrumental: 'паладинами',
      prepositional: 'паладинах',
    },
  },
  nameEn: 'paladin',
  description: [
    `Закованный в [латы](GEAR:${GEAR_PLATE_ARMOR}), сверкающие на солнце, несмотря на пыль и грязь долгого путешествия, человек складывает свой меч и щит и касается ладонями смертельно раненного мужчины. Божественное сияние исходит из его рук, и раны мужчины затягиваются, а глаза широко раскрываются в изумлении.

Дварф пригнулся к земле за камнем. Его чёрный плащ делал его почти невидимым в ночи, и он смотрел, как шайка [орков](${CREATURE_ORC}) праздновала недавнюю победу. Он тихо крадётся к ним и шепчет свою присягу, и двое [орков](${CREATURE_ORC}) умирают ещё до того, как понимают, что он здесь был.

Серебряные волосы сверкают в лучах света, которые, кажется, льются только на торжественно смеющегося эльфа. Его [копьё](GEAR:${GEAR_SPEAR}) вспыхивает, подобно его глазам, когда он снова и снова наносит удары уродливому великану, до тех пор, пока его свет не преодолевает отвратительную тьму.

Вне зависимости от происхождения и миссии, паладинов объединяет их клятва противостоять силам зла. Принесённая ли перед алтарём бога и заверенная священником, или же на священной поляне перед духами природы и феями, или в момент отчаяния и горя смерти, присяга паладина — могущественный договор. Это источник силы, который превращает набожного воина в благословенного героя.`,
    {
      header: `Источник праведности`,
      text: `Паладин клянётся защищать справедливость и праведность, отстаивать добродетели мира перед вторжениями тьмы, и охотиться на силы зла, где бы они ни скрывались. Разные паладины сосредотачиваются на различных аспектах праведности, но все они связаны клятвой, которая даёт им силы для совершения их священного дела. Многие паладины преданы богам добра, но сила паладина происходит скорее от собственного стремления к справедливости, чем от божества.

Паладины тренируются годами, чтобы обучиться навыкам боя, осваивая владение различными видами оружия и доспехов. Тем не менее, их воинские навыки вторичны по сравнению с магической силой, которой они обладают: силой исцелять больных и раненых, карать нечестивцев и нежить, защищать невинных и тех, кто присоединился к ним в борьбе за справедливость.`,
      source: {
        id: SOURCE_PHB,
        page: 83,
      },
    },
    {
      header: `За мирской жизнью`,
      text: `Жизнь паладина это жизнь искателя приключений. Пока тяжёлые травмы не сделают их инвалидами, паладины проживают жизнь на линии фронта мировой борьбы со злом. В рядах ополчений и армий мира мало представителей класса воин, и ещё меньшее количество простых людей могут претендовать на истинное звание паладина. Услышав зов, эти воины оставляют прежнее ремесло и берут в руки оружие, чтобы бороться со злом. Иногда их клятвы приводят их на службу государям в качестве лидеров элитных групп рыцарей, но даже тогда их верность в первую очередь отдана делу праведности, а не короне или стране.

Ставшие искателями приключений паладины относятся к своему делу серьёзно. Поход в древние руины или пыльный склеп может быть обусловлен поиском более высокой цели, чем поход за сокровищами. Зло таится в подземельях и дремучих лесах, и даже самые скромные победы над ним могут удержать мировое равновесие от небытия.`,
      source: {
        id: SOURCE_PHB,
        page: 83,
      },
    },
    {
      header: `Клятвы и догматы паладинов`,
      text: `Ты дал клятву, паладин. Принёс обет. Хранить и защищать свои догматы всегда и любой ценой. Это тяжёлое бремя, но ты несёшь его на своих измученных плечах, а если оступишься — превратишься в самое презренное из существ. Помни свои догматы, паладин. Помни о своей цели. Помни свой обет.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Создание паладина`,
      text: `Наиболее важным аспектом персонажа-паладина является природа его святого задания. Хотя классовые умения, связанные с Вашей клятвой, не проявляются до достижения 3 уровня, приготовьтесь заранее, прочитав описание клятв в конце описания класса. Вы преданный слуга добра, лояльный к богам справедливости и чести, святой рыцарь в сияющих доспехах, отважившийся идти вперёд, чтобы поразить зло? Вы доблестный чемпион света, лелеющий всё прекрасное, что выступает против тьмы, рыцарь, чья клятва старше, чем многие из богов? Или Вы озлобленный одиночка, поклявшийся отомстить тем, кто совершил великое зло, посланный подобно ангелу смерти богами или обуреваемый личной необходимостью мести? Приложение Б Книги Игрока перечисляет множество божеств, которым поклоняются паладины всей мультивселенной, например, [Торм](GOD:${GOD_TORM}), [Тир](GOD:${GOD_TYR}), Хейрониус, Паладайн, Кири-Джолит, Дол Арра, Серебряное Пламя, [Багамут](GOD:${GOD_BAHAMUT}), Афина, Ра-Хорахти и Хеймдалль.

Как Вы получили призыв к тому, чтобы стать паладином? Вы услышали шёпот невидимого бога или ангела во время молитвы? Другой паладин почувствовал Ваш потенциал и решил тренировать вас в качестве оруженосца? Или случилось какое-то ужасное событие — уничтожение вашего дома, которое, возможно, послужило отправной точкой ваших исканий? Может быть, Вы наткнулись в священной роще на тайный эльфийский анклав, и нашли себя в защите всех подобных убежищ добра и красоты? Или Вы знали с самых ранних воспоминаний, что жизнь паладина была вашим призванием, подобно тому, как если бы Вы были посланы в мир с целью, отпечатанной в Вашей душе.

Противостоящие силам зла паладины редко имеют любое злое мировоззрение. Большинство из них следует пути милосердия и справедливости. Подумайте, как Ваше мировоззрение ведёт вас по пути святого задания, и каким образом оно определяет поведение перед богами и смертными. Ваша клятва и мировоззрение могут находиться в гармонии, или Ваша клятва может представлять стандарты поведения, которых Вы ещё не достигли.`,
      source: {
        id: SOURCE_PHB,
        page: 84,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать паладина, следуя этим рекомендациям.

1. Во-первых, у Вашей Силы должно быть наивысшее значение. Следующим по величине должно быть значение Харизмы.
2. Во-вторых, выберите предысторию «[Благородный](BACKGROUND:${BACKGROUND_NOBLE})». `,
      source: {
        id: SOURCE_PHB,
        page: 84,
      },
    },
  ],
  note: [
    {
      text: `Истинная ценность паладина измеряется не в побеждённых врагах или разворованных подземельях. Она измеряется в спасённых жизнях и сердцах, возвращенных в милосердию и справедливости.`,
      author: `Истеваль`,
    },
    {
      text: `Жить ради клятвы? Это звучит нелепо. Чего ради кто-то идёт на это? Нет ничего плохого в том, чтобы давать обещания, но лишь слабаки их сдерживают.`,
      author: `Занатар`,
    },
  ],
  featureCollection: {
    hitDice: 10,
    proficiency: {
      armor: [
        CAT_LIGHT_ARMOR,
        CAT_MEDIUM_ARMOR,
        CAT_HEAVY_ARMOR,
        CAT_SHIELDS,
      ],
      weapon: {
        catList: [
          CAT_SIMPLE_WEAPON,
          CAT_MARTIAL_WEAPON,
        ],
      },
      savingThrow: [
        PARAM_CHA,
        PARAM_WIT,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_ATHLETICS,
            SKILL_INSIGHT,
            SKILL_INTIMIDATION,
            SKILL_MEDICINE,
            SKILL_PERSUASION,
            SKILL_RELIGION,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 5,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) Воинское оружие и [щит](GEAR:${GEAR_SHIELD})
    * б) Два воинских оружия
2. Выберите одно
    * а) Пять [пилумов](GEAR:${GEAR_JAVELIN})
    * б) Любое простое рукопашное оружие
3. Выберите одно
    * а) [Набор священника](GEAR:${GEAR_PRIEST_S_PACK})
    * б) [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})
4. [Кольчуга](GEAR:${GEAR_CHAIN_MAIL})
5. Священный символ`,
    },
  },
  spellSlotTable: halfCasterSpellSlotTable,
  progressTable: `
| Уровень | Умения                                                                                                                                                                                                                             |
|---------|------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
|  1      | [Божественное чувство](FEATURE:${FEATURE_DIVINE_SENSE}), [Наложение рук](FEATURE:${FEATURE_LAY_ON_HANDS})                                                                                |
|  2      | [Боевой стиль](FEATURE:${FEATURE_FIGHTING_STYLE_PALADIN}), [Использование заклинаний](FEATURE:${FEATURE_SPELLCASTING_PALADIN})                                                         |
|  3      | [Божественное здоровье](FEATURE:${FEATURE_DIVINE_HEALTH}), [Божественная кара](FEATURE:${FEATURE_DIVINE_SMITE}), [Священная клятва](FEATURE:${FEATURE_SACRED_OATH}) |
|  4      | [Увеличение характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_PALADIN})                                                                                                                               |
|  5      | [Дополнительная атака](FEATURE:${FEATURE_EXTRA_ATTACK_PALADIN})                                                                                                                                                       |
|  6      | [Аура защиты](FEATURE:${FEATURE_AURA_OF_PROTECTION})                                                                                                                                                          |
|  7      | Умение священной клятвы                                                                                                                                                                                                            |
|  8      | Увеличение характеристик                                                                                                                                                                                                           |
|  9      | —                                                                                                                                                                                                                                  |
| 10      | [Аура отваги](FEATURE:${FEATURE_AURA_OF_COURAGE})                                                                                                                                                             |
| 11      | [Улучшенная божественная кара](FEATURE:${FEATURE_IMPROVED_DIVINE_SMITE})                                                                                                                                                                                                       |
| 12      | Увеличение характеристик                                                                                                                                                                                                           |
| 13      | —                                                                                                                                                                                                                                  |
| 14      | [Очищающее касание](FEATURE:${FEATURE_CLEANSING_TOUCH})                                                                                                                                                       |
| 15      | Умение священной клятвы                                                                                                                                                                                                            |
| 16      | Увеличение характеристик                                                                                                                                                                                                           |
| 17      | —                                                                                                                                                                                                                                  |
| 18      | Улучшения ауры                                                                                                                                                                                                                     |
| 19      | Увеличение характеристик                                                                                                                                                                                                           |
| 20      | Умение священной клятвы                                                                                                                                                                                                            |
`,
  source: [
    {
      id: SOURCE_PHB,
      page: 83,
    },
    {
      id: SOURCE_SRD,
      page: 30,
    },
  ],
}
