const {
  SPELL_CALM_EMOTIONS,
  SPELL_CURE_WOUNDS,
  SPELL_GREATER_RESTORATION,
  SPELL_HEAL,
  SPELL_LESSER_RESTORATION,
  SPELL_RAISE_DEAD,
  SPELL_SLEEP,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ELADRIN,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_FEY,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SKILL_INSIGHT,
  SKILL_MEDICINE,
} = require('./../../../../skillList')
const {
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
} = require('./../../../../gearIdList')
const {
  eladrinDescription,
  eladrinNote,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_ELADRIN_AUTUMN} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Осенний эладрин',
  nameEn: 'Autumn Eladrin',
  id: CREATURE_ELADRIN_AUTUMN,
  description: [
    `Эладрины часто входят в осенний сезон, когда их переполняет доброжелательность. В этом аспекте они останавливают конфликты и облегчают страдания, используя свою магию, чтобы исцелять, лечить и облегчать любую хворь, которая может поразить людей, приходящих к ним за помощью. Такие эладрины не терпят насилия в их присутствии и быстро разрешают споры, чтобы наладить мир.`,
    ...eladrinDescription,
  ],
  note: eladrinNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_ELADRIN,
  ],
  alignmentId: ALIGNMENT_CN,
  source: [
    {
      id: SOURCE_MTOF,
      page: 245,
    },
    {
      url: `https://5e.tools/bestiary.html#soul%20monger_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Осеннего эладрина» ошибочно добавлена мультиатака.`,
    },
  ],
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 18,
  },
  skillCollection: {
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_MEDICINE]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ELVEN,
    LANG_SYLVAN,
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Фейский шаг',
      restore: 4,
      description: `Бонусным действием эладрин может телепортироваться на расстояние до 30 фт. в незанятое пространство, которое он может видеть.`,
    },
    {
      name: 'Чарующее присутствие',
      description: `Любое существо, не являющееся эладрином и начинающее свой ход в пределах 60 футов от осеннего эладрина, должно пройти испытание Мудрости СЛ 16. При провале существо очаровывается эладрином на 1 минуту. При успехе существо становится невосприимчивым к _Чарующему присутствию_ всех эладринов на 24 часа.

Каждый раз, когда эладрин причиняет урон очарованному существу, оно может повторить испытание, оканчивая эффект при успехе.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_CALM_EMOTIONS,
          SPELL_SLEEP,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          {
            id: SPELL_CURE_WOUNDS,
            comment: 'как заклинание 5 уровня',
          },
          SPELL_LESSER_RESTORATION,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_GREATER_RESTORATION,
          SPELL_HEAL,
          SPELL_RAISE_DEAD,
        ],
      },
    ],
  },
  actionList: [
    {
      gearId: GEAR_LONGSWORD,
      attack: {
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 4,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
    {
      gearId: GEAR_LONGBOW,
      attack: {
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 4,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
  ],
  reactionList: [
    {
      name: 'Принуждение к миру',
      description: `Если существо, очарованное Эладрином совершает атаку в пределах 60 футов от эладрина, эладрин магически заставляет атаку промахнуться, если он видит атакующего.`,
    },
  ],
}
