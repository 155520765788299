const {
  SPELL_BLIGHT,
  SPELL_BLINK,
  SPELL_CHAOS_BOLT,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_ENERVATION,
  SPELL_FINGER_OF_DEATH,
  SPELL_FLY,
  SPELL_HARM,
  SPELL_MENTAL_PRISON,
  SPELL_PHANTASMAL_KILLER,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_PAIN,
  SPELL_POWER_WORD_STUN,
  SPELL_SHIELD,
  SPELL_SYMBOL,
  SPELL_WEIRD,
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_SLAAD,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_SLAAD_DEATH,
  CREATURE_YGORL_LORD_OF_ENTROPY,
} = require('./../../../../creatureIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_23} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MFFV_1_MMB} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Игорл — Повелитель энтропии',
  nameEn: 'Ygorl – Lord of Entropy',
  id: CREATURE_YGORL_LORD_OF_ENTROPY,
  hasSpoilers: true,
  description: [
    {
      header: 'Игорл',
      text: `Загадочное существо, известное как Игорл, было одним из первых слаадов, созданных после того, как Прайм выпустил Нерестильный Камень на Лимбо. Мощное существо, наделенное энтропийной силой, Игорл наслаждается актами разрушения и принесения хаоса в любую экосистему, которую посещает. Малые слаады следуют за повелителем энтропии, радуясь и способствуя разрушениям, которое он приносит.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 20,
      },
    },
    {
      header: 'Циклы Хаоса',
      text: `Будучи олицетворением энтропии, Игорл проклят видеть вещи такими, какими они станут — поломанными и поглощёнными маршем вечности. В нём нет сочувствия или сострадания. Его ведёт только стремление разбирать всё так, чтобы базовые элементы реальности могли подпитывать бесконечные циклы создания и разрушения Мультивселенной.

Разговор с Игорлом — сложная задача, так как повелитель энтропии направляет свои мысли как поток сознания, который является одновременно запутывающим и трудным для понимания. Известно что смертные, которые пытались договориться с Игорлом, сошли с ума в процессе.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 20,
      },
    },
    {
      header: 'Смерть и разрушение',
      text: `Самым заметным достоянием Игорла является адамантиновая коса, которая может превращать живых существ в пепел, со слаадским словом «Смерть», начертанным на лезвии. Повелитель энтропии также несёт набор обсидиановых табличек, подвешенных вокруг талии, на которых он наносит разрушительные руны. Живые существа увядают и гниют в энтропийной энергии, излучаемой магией Игорла.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 20,
      },
    },
  ],
  note: {
    text: `После того, как пятый художник, отправленный зарисовать изображение Игорла, не вернулся, я решил прекратить эти попытки`,
    author: `Морденкайнен`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_SLAAD,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 20,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 26,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 24,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 26,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_THUNDER,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_23,
  featureList: [
    {
      name: 'Аура энтропии',
      description: `Каждое существо, кроме конструктов и нежити, заканчивающее свой ход в пределах 15 футов от ★СУЩЕСТВО★, получает 14 (4к6) некротического урона.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_BLIGHT,
          SPELL_BLINK,
          SPELL_CHAOS_BOLT,
          SPELL_DARKNESS,
          SPELL_DETECT_MAGIC,
          SPELL_FLY,
          SPELL_SHIELD,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_CIRCLE_OF_DEATH,
          SPELL_ENERVATION,
          SPELL_PHANTASMAL_KILLER,
          {
            id: SPELL_SYMBOL,
            comment: 'только Разногласие',
          },
        ],
      },
      {
        limit: {
          count: 2,
          period: 'день',
        },
        list: [
          SPELL_FINGER_OF_DEATH,
          SPELL_HARM,
          SPELL_MENTAL_PRISON,
          SPELL_POWER_WORD_PAIN,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_POWER_WORD_KILL,
          SPELL_POWER_WORD_STUN,
          SPELL_WEIRD,
          {
            id: SPELL_SYMBOL,
            comment: 'только Смерть',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: две — _Косой_ и одну — _Энтропическим касанием_.`,
    },
    {
      name: 'Коса',
      description: `Любое существо, чьи хиты уменьшены до 0 этой атакой, умирает, а его тело и всё, что оно несёт и носит, кроме магических предметов, взрывается облаком пепла. Существо может быть возвращено к жизни только заклинанием [Исполнение желаний](SPELL:${SPELL_WISH}).`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 10,
            diceBonus: 7,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 3,
            diceType: 12,
          },
        ],
      },
    },
    {
      name: 'Энтропическое касание',
      description: `Цель должна пройти испытание Телосложения СЛ 22, или получить один уровень истощения.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 8,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Призыв слаада',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ призывает 1к4 + 1 [смертельных слаада](CREATURE:${CREATURE_SLAAD_DEATH}). Призванные слаады появляются в в свободном пространстве в пределах 60 футов от ★СУЩЕСТВО★, действуют как ★его★ союзники, и не имеют способности призывать других слаадов. Они помогают в течение 1 минуты или пока ★СУЩЕСТВО★ не умрёт или действием их не отпустит.`,
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Коса',
      description: `★СУЩЕСТВО★ совершает атаку _Косой_.`,
    },
    {
      name: 'Телепортация',
      description: `★СУЩЕСТВО★ использует своё действие _Телепортация_.`,
    },
    {
      name: 'Зов Пустоты',
      cost: 3,
      description: `Каждое существо, кроме конструктов и нежити, в пределах 30 футов от ★СУЩЕСТВО★, должно пройти испытание Телосложения СЛ 22 от попытки владыки энтропии разобрать его жизнь, получая 42 (12к6) некротического урона при провале или половину при успехе. ★СУЩЕСТВО★ затем  восстанавливает хиты, равные половине всего урона, полученного всеми существами.`,
    },
  ],
}
