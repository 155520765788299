const spellIdCollectionByPcClassId = require('./spellIdCollectionByPcClassId')

module.exports = Object
  .keys(spellIdCollectionByPcClassId)
  .reduce(
    (collection, pcClassId) => {
      spellIdCollectionByPcClassId[pcClassId].forEach(
        spellId => {
          if (collection[spellId]) {
            collection[spellId].push(pcClassId)
          } else {
            collection[spellId] = [pcClassId]
          }
        }
      )

      return collection
    },
    {}
  )
