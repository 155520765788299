const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  PC_CLASS_WIZARD,
} = require('./../../../../pcClassIdList')
const {
  FEATURE_SPELLBOOK,
} = require('./../../../../featureIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../magicItemRarityList')
const {
  SPELL_ARCANE_GATE,
  SPELL_DIMENSION_DOOR,
  SPELL_GATE,
  SPELL_MISTY_STEP,
  SPELL_PLANE_SHIFT,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_WORD_OF_RECALL,
} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_ATLAS_OF_ENDLESS_HORIZONS,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_ATLAS_OF_ENDLESS_HORIZONS,
  name: `Атлас бесконечных горизонтов`,
  nameEn: `Atlas of Endless Horizons`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  attunementComment: `волшебником`,
  description: `Этот толстый том в переплёте тёмной кожи исчерчен серебряными линиями, напоминающими схему или карту.

_Атлас бесконечных горизонтов_ может использоваться как [книга заклинаний](FEATURE:${FEATURE_SPELLBOOK}). Он уже содержит некоторые заклинания, которые считаются для Вас заклинаниями [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}), пока Вы на него настроены.

* [Врата](SPELL:${SPELL_GATE})
* [Круг телепортации](SPELL:${SPELL_TELEPORTATION_CIRCLE})
* [Магические врата](SPELL:${SPELL_ARCANE_GATE})
* [Переносящая дверь](SPELL:${SPELL_DIMENSION_DOOR})
* [Слово возврата](SPELL:${SPELL_WORD_OF_RECALL})
* [Туманный шаг](SPELL:${SPELL_MISTY_STEP})
* [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT})

Держа _Атлас_, его можно использовать как заклинательную фокусировку для Ваших заклинаний [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}).

У _Атласа_ есть 3 заряда и он восстанавливает 1к3 заряда на рассвете. Держа его, Вы можете использовать его заряды следующими способами.

* Если Вы потратите 1 минуту, изучая _Атлас бесконечных горизонтов_, то можете потратить 1 заряд чтобы заменить одно из Ваших подготовленных заклинаний [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}) на другое из _Атласа_. Новое заклинание должно принадлежать школе Призыва.
* Когда по Вам попадают атакой, Вы можете реакцией потратить 1 заряд и телепортироваться в видимое Вами в пределах 10 футов свободное пространство. Если Ваша новая позиция вне радиуса атаки, она промахивается.`,
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_ARCANE_GATE,
    SPELL_DIMENSION_DOOR,
    SPELL_GATE,
    SPELL_MISTY_STEP,
    SPELL_PLANE_SHIFT,
    SPELL_TELEPORTATION_CIRCLE,
    SPELL_WORD_OF_RECALL,
  ],
  source: {
    id: SOURCE_TCoE,
    page: 173,
  },
}
