const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../../attackTypeList')
const {
  PARAM_WIT,
} = require('./../../../../../paramList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../../damageTypeList')
const {
  CONDITION_FRIGHTENED,
} = require('./../../../../../conditionList')
const {
  SPELL_WRATHFUL_SMITE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_WRATHFUL_SMITE,
  name: 'Гневная кара',
  nameEn: `Wrathful Smite`,
  description: `В следующий раз, когда Вы попадёте атакой рукопашным оружием, пока активно это заклинание, Ваша атака причиняет дополнительный урон психической энергией 1к6.

Кроме того, если цель — существо, оно должно пройти испытание Мудрости, иначе оно станет испуганным до окончания действия заклинания.

Существо может действием совершить проверку Мудрости, чтобы успокоиться и окончить это заклинание.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_MINUTE, count: 1 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  needConcentration: true,
  effect: {
    attackType: ATTACK_MELEE_WEAPON,
    savethrowParam: PARAM_WIT,
    condition: CONDITION_FRIGHTENED,
    damage: {
      type: DAMAGE_PSYCHIC,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_PHB,
    page: 221,
  },
}
