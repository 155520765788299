const {SOURCE_BGDIA} = require('./../../sourceList')
const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')
const {PC_SUBCLASS_WIZARD_SCHOOL_OF_NECROMANCY} = require('./../../pcSubClassIdList')
const {GEAR_SPELLBOOK} = require('./../../gearIdList')
const {GOD_MYRKUL} = require('./../../godIdList')
const {
  CREATURE_MASTER_OF_SOULS,
  CREATURE_NECROMITE_OF_MYRKUL,
  CREATURE_SKULL_LASHER_OF_MYRKUL,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: `Миркул и его последователи`,
    text: `[Миркул](GOD:${GOD_MYRKUL}) — Владыка Костей, повелевающий трупами и душами. Его культисты желают получить от мёртвых знания, силу и даже обратить их себе на службу.

[Миркул](GOD:${GOD_MYRKUL}) способен призвать душу любого убитого существа и заточить её в восковом черепе. Но для этого Миркулу нужна какая-нибудь часть физического тела существа, которую он измельчает и использует прах при создании черепа. Пока душа остаётся в черепе, его глазницы пылают холодным синим пламенем. Пока череп невредим, [Миркул](GOD:${GOD_MYRKUL}) может заставить пленнуюдушу отвечать ему на вопросы и делиться знаниями. С каждым ответом череп всё больше разрушается, пока не рассыплется совсем, освободив душу. Её также можно освободить, окатив восковой череп святой водой.

[Миркул](GOD:${GOD_MYRKUL}) расчётлив, необщителен и погружён в себя. Его волнует только накопление знаний, а повседневные проблемы жизни его раздражают и отвлекают. Он счастлив, когда может окружить себя книгами знаний и нежитью, которую он вызвал и подчинил.

Миркулу поклоняются либо [волшебники](PC_SUBCLASS:${PC_SUBCLASS_WIZARD_SCHOOL_OF_NECROMANCY}), либо те, кто хочет овладеть искусством некромантии.`,
    source: {
      id: SOURCE_BGDIA,
      page: 239,
    },
  },
  {
    header: `Искатели знаний`,
    text: `Культисты Миркула изучают ритуалы, позволяющие им подчинять души мёртвых, заставляя отвечать на вопросы и делиться забытым знанием. Культисты ищут мистические тайны в древних руинах и пытаются выкрасть [книги заклинаний](GEAR:${GEAR_SPELLBOOK}) и прочие тома у [волшебников](PC_CLASS:${PC_CLASS_WIZARD}), не входящих в культ.`,
    source: {
      id: SOURCE_BGDIA,
      page: 239,
    },
  },
  {
    header: `Иерархия культа`,
    text: `Последователи Миркула используют цепы с бойками в виде черепов.

[Некромайты](CREATURE:${CREATURE_NECROMITE_OF_MYRKUL}) — это послушники, которые ещё не овладели мистической магией и дерутся цепами.

[Черепобойцы](CREATURE:${CREATURE_SKULL_LASHER_OF_MYRKUL}) — это заклинатели, которые дополняют магией свои боевые навыки.

[Мастера душ](CREATURE:${CREATURE_MASTER_OF_SOULS}) глубоко погружены в тайны Миркула, умеют оживлять мёртвых и творить прочую зловещую магию.`,
    source: {
      id: SOURCE_BGDIA,
      page: 239,
    },
  },
]
