const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_CHANGELING} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_CHANGELING,
  nameEn: 'Changeling',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'подменыш',
      genitive: 'подменыша',
      dative: 'подменышу',
      accusative: 'подменыша',
      instrumental: 'подменышом',
      prepositional: 'подменыше',
    },
    plural: {
      nominative: 'подменыши',
      genitive: 'подменышей',
      dative: 'подменышам',
      accusative: 'подменышей',
      instrumental: 'подменышами',
      prepositional: 'подменышах',
    },
  },
}
