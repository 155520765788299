const {MAGIC_ILLUSION} = require('./../../../../../magicList')
const {SPELL_SILENCE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')

module.exports = {
  id: SPELL_SILENCE,
  name: 'Тишина',
  nameEn: 'Silence',
  description: `Пока заклинание активно, никакие звуки не могут раздаться в пределах сферы с радиусом 20 футов с центром на точке, выбранной в пределах дистанции, а также не могут проходить сквозь неё. Все существа и предметы, полностью находящиеся в сфере, получают иммунитет к урону звуком, и существа считаются оглохшими, когда полностью находятся в ней. Там невозможно сотворять заклинания с вербальным компонентом.`,
  lvl: 2,
  magicSchoolId: MAGIC_ILLUSION,
  range: 120,
  isRitual: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 10 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 284,
    },
    {
      id: SOURCE_SRD,
      page: 179,
    },
  ],
}
