const {PC_SUBCLASS_WIZARD_SCHOOL_OF_NECROMANCY} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {SPELL_ANIMATE_DEAD} = require('./../../../spellIdList')
const {
  CREATURE_SKELETON,
  CREATURE_ZOMBIE,
} = require('./../../../creatureIdList')
const {
  FEATURE_COMMAND_UNDEAD,
  FEATURE_GRIM_HARVEST,
  FEATURE_INURED_TO_UNDEATH,
  FEATURE_NECROMANCY_SAVANT,
  FEATURE_SPELLBOOK,
  FEATURE_UNDEAD_THRALLS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_NECROMANCY_SAVANT,
    name: `Мастер некромантии`,
    nameEn: `Necromancy Savant`,
    lvl: 2,
    text: `Золото и время, которое Вы тратите на копирование заклинания Некромантии в свою [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), уменьшаются вдвое.`,
  },
  {
    id: FEATURE_GRIM_HARVEST,
    name: `Мрачная жатва`,
    nameEn: `Grim Harvest`,
    lvl: 2,
    text: `Вы получаете возможность собирать жизненные силы существ, которых Вы убиваете своими заклинаниями.

Один раз за ход, когда Вы убиваете одно или несколько существ заклинанием 1 и более высокого уровня, Вы восстанавливаете хиты в количестве, равном удвоенному уровню этого заклинания, или утроенному, если заклинание принадлежало школе Некромантии.

Вы не получаете это преимущество, убивая конструктов и нежить.`,
  },
  {
    id: FEATURE_UNDEAD_THRALLS,
    name: `Неживые рабы`,
    nameEn: `Undead Thralls`,
    lvl: 6,
    text: `Вы добавляете заклинание [Восставший труп](SPELL:${SPELL_ANIMATE_DEAD}) в свою книгу заклинаний, если его в ней ещё нет. Когда Вы накладываете это заклинание, то можете сделать его целью один дополнительный труп или кучу костей, создавая [зомби](CREATURE:${CREATURE_ZOMBIE}) или [скелета](CREATURE:${CREATURE_SKELETON}) соответственно.

Каждый раз, когда Вы создаёте нежить, используя заклинание школы Некромантии, она получает дополнительные преимущества:

* Максимум хитов создаваемой нежити увеличивается на значение, равное Вашему уровню волшебника.
* Создаваемые существа добавляют Ваш бонус мастерства к броскам урона оружием.`,
    spellIdList: SPELL_ANIMATE_DEAD,
  },
  {
    id: FEATURE_INURED_TO_UNDEATH,
    name: `Единение с не-жизнью`,
    nameEn: `Inured to Undeath`,
    lvl: 10,
    text: `Вы получаете сопротивление к урону некротической энергией и максимум Ваших хитов уже не может уменьшаться.

Вы провели так много времени в контакте с нежитью и оживляющей их энергией, что научились преодолевать их самые опасные побочные эффекты.`,
  },
  {
    id: FEATURE_COMMAND_UNDEAD,
    name: `Управление нежитью`,
    nameEn: `Command Undead`,
    lvl: 14,
    text: `Вы можете магией подчинить себе нежить, даже если она создана другим волшебником.

Вы можете действием выбрать одну нежить в пределах 60 футов от себя, которую можете видеть. Это существо должно пройти испытание Харизмы против СЛ Ваших заклинаний волшебника.

* Если испытание успешно, Вы не можете больше использовать это умение на данной цели.
* Если испытание провалено, цель становится дружественной по отношению к Вам и подчиняется Вашим командам, пока Вы не используете это умение снова.

Разумная нежить управляется подобным способом куда как труднее. Если у цели Интеллект 8 или выше, она проходит испытание с преимуществом. Если она проваливает испытание и имеет Интеллект 12 или выше, она может повторять испытание в конце каждого часа, пока не преуспеет и не высвободится.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_SCHOOL_OF_NECROMANCY,
    source: {
      id: SOURCE_PHB,
      page: 118,
    },
  })
)

