const {GENDER_MALE} = require('./../../../genderList')
const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../magicItemTypeList')
const {SOURCE_XGTE} = require('./../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    gearType,
    id,
    name,
    nameEn,
  }
) => ({
  id,
  name,
  nameEn,
  gearType,
  type: MGC_TYPE_WEAPON,
  rarity: MGC_RARITY_COMMON,
  description: `В темноте обнажённое лезвие этого меча излучает лунный свет, создавая яркий свет в радиусе 15 футов и тусклый свет в радиусе ещё 15 футов.`,
  genderId: GENDER_MALE,
  siblingIdList,
  source: {
    id: SOURCE_XGTE,
    page: 137,
  },
})
