const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_SHADOW_MASTIFF} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_SHADOW_MASTIFF,
  nameEn: 'Shadow Mastiff',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'теневой мастиф',
      genitive: 'теневого мастифа',
      dative: 'теневому мастифу',
      accusative: 'теневого мастифа',
      instrumental: 'теневым мастифом',
      prepositional: 'теневом мастифе',
    },
    plural: {
      nominative: 'теневые мастифы',
      genitive: 'теневых мастифов',
      dative: 'теневым мастифам',
      accusative: 'теневых мастифов',
      instrumental: 'теневыми мастифами',
      prepositional: 'теневых мастифах',
    },
  },
}
