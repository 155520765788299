const {
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_DEAD,
  SPELL_CHARM_PERSON,
  SPELL_COUNTERSPELL,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_DOMINATE_MONSTER,
  SPELL_FLY,
  SPELL_INFLICT_WOUNDS,
  SPELL_INVISIBILITY,
  SPELL_MAJOR_IMAGE,
  SPELL_SUGGESTION,
  SPELL_SYMBOL,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_REGENERATION,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  archDevilDescriptionList,
  archDevilNoteList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_BAEL,
  CREATURE_BARBED_DEVIL,
} = require('./../../../../creatureIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_19} = require('./../../../../crList')
const {GEAR_PLATE_ARMOR} = require('./../../../../gearIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Баэль',
  nameAlt: [
    'Бронзовый генерал',
    'Король преисподней',
  ],
  nameEn: 'Bael',
  id: CREATURE_BAEL,
  hasSpoilers: true,
  description: [
    `С бушующей целую вечность Войной Крови, которой нет конца, немало возможностей предстало перед амбициозными архидьяволами, чтобы добиться известности, славы и силы в продолжающейся борьбе против демонов. Герцог Баэль, один из самых важных слуг Маммона, прославился и добился признания за свои заслуги. Порученный вести за собой шестьдесят шесть подразделений [шипастых дьяволов](CREATURE:${CREATURE_BARBED_DEVIL}), Баэль доказал свою тактическую одарённость, заработав уважение как к себе, так и к своему хозяину, одерживая победу за победой над хозяевами Бездны. Маммон полагается на Баэля из-за его боевой смекалки защищать владения своего хозяина. Маммона ещё до сих пор не свергли, тогда как многие архидьяволы уже давно потеряли свои позиции, что хорошо доказывает навыки Баэля на поле боя.

Как за свои достижения, так и за свой оттенок кожи, Баэля прозвали Бронзовым Генералом. Невзирая на похвалы, у Баэля были сложные времена в управлении пучиной инфернальных политиков. Многие его критиковали и называли наивным, но никогда не говорили этого ему в глаза. Основным интересом Баэля всегда было ведение солдат в бой, и поэтому его раздражает, что его стремлению взойти выше по рангу всегда препятствуют хитрые политические конкуренты.

Баэль предпочитает делать слуг из своих противников и смертных, которые присягнули ему на службу, чтобы заслужить своё жалкое место, будучи побеждёнными Баэлем. Баэль с удовольствием щадит тех, кого он поразил, но только если они продадут свою душу и будут служить ему. Впрочем, он готов совратить почти любого, кто встанет у него на пути, а демонов, над которыми он берёт вверх, убивает не щадя.

Баэль также рад смертным, что хотят служить ему, если конечно, они смогут обеспечить ему преимущество в его политической игре. Он вербует смекалистых индивидов и полагается на них в качестве представителей своих интересов во дворе Маммона, благодаря чему Баэль может свободно утолить свою жажду битвы.

Невзирая на свой малый интерес к делам вне боя, или, скорее всего, из-за этого, Баэля почитают меньше всего. Те, кто поклоняется его алтарю, зовут его Королём Преисподней, а самые заблудшие из них верят что он является повелителем всех дьяволов. В магическом обществе и некоторых письменах, например, в ужасающей _Книге Огня_, говорится, что Баэль открыл всему миру заклинание [Невидимость](SPELL:${SPELL_INVISIBILITY}), хоть и некоторые исследователи магии категорически опровергают такие утверждения. Баэля иногда изображают: жабой, котом, человеком мужского пола, или даже некой смесью из этих созданий, тем не мене, ни одно из этих изображений не отражает его истинную внешность.`,
    ...archDevilDescriptionList,
  ],
  note: archDevilNoteList,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 185,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 20,
    [PARAM_INT]: 21,
    [PARAM_WIT]: 24,
    [PARAM_CHA]: 24,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_19,
  featureList: [
    {
      name: 'Устрашение',
      description: `Баэль может действием стать ещё более устрашающим до конца своего следующего хода. Каждое существо, кроме дьяволов, начинающее свой ход в пределах 10 футов от Баэля, должно преуспеть в испытании Мудрости СЛ 22, иначе оно станет испуганным Баэлем до начала своего следующего хода.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_WEAPONS,
    ABILITY_MAGIC_RESISTANCE,
    {
      id: ABILITY_REGENERATION,
      regen: 20,
      other: `. Если ★СУЩЕСТВО★ получает урон холодом или излучением, эта особенность не действует в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только если начнёт свой ход с 0 хитов и не может регенерировать`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_ANIMATE_DEAD,
          SPELL_CHARM_PERSON,
          SPELL_DETECT_MAGIC,
          SPELL_MAJOR_IMAGE,
          {
            id: SPELL_ALTER_SELF,
            comment: 'может стать среднего размера',
          },
          {
            id: SPELL_INVISIBILITY,
            comment: 'только на себя',
          },
          {
            id: SPELL_INFLICT_WOUNDS,
            comment: 'как заклинание 8 уровня',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_COUNTERSPELL,
          SPELL_DISPEL_MAGIC,
          SPELL_FLY,
          SPELL_SUGGESTION,
          SPELL_WALL_OF_FIRE,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DOMINATE_MONSTER,
          {
            id: SPELL_SYMBOL,
            comment: 'только эффект _«Ошеломления»_',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Баэль совершает две рукопашные атаки.`,
    },
    {
      name: 'Адский моргенштерн',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 20,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 7,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 3,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Инфернальный приказ',
      description: `Каждый союзник Баэля, находящийся на расстоянии 60 футов от него, не может быть очарован или испуган до конца следующего хода Баэля.`,
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака моргенштерном',
      cost: 2,
      description: `Баэль совершает атаку _Адским моргенштерном_.`,
    },
    {
      name: 'Пробудившаяся алчность',
      description: `Баэль сотворяет заклинания [Очарование личности](SPELL:${SPELL_CHARM_PERSON}) и [Образ](SPELL:${SPELL_MAJOR_IMAGE}).`,
    },
    {
      name: 'Инфернальный приказ',
      description: `Баэль использует своё действие _Инфернальный приказ_.`,
    },
    {
      name: 'Телепортация',
      description: `Баэль использует действие _Телепортация_.`,
    },
  ],
}
