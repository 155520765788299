const {SOURCE_DMG} = require('./../../../../../sourceList')
const {CREATURE_DJINNI} = require('./../../../../../creatureIdList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../../magicItemRarityList')
const {MAGIC_ITEM_RING_OF_DJINNI_SUMMONING} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_RING_OF_DJINNI_SUMMONING,
  name: `Кольцо призыва джинна`,
  nameEn: `Ring of Djinni Summoning`,
  type: MGC_TYPE_RING,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: `Нося это кольцо, Вы можете действием произнести его командное слово, чтобы призвать конкретного [джинна](CREATURE:${CREATURE_DJINNI}) со Стихийного Плана Воздуха. [Джинн](CREATURE:${CREATURE_DJINNI}) появляется в свободном пространстве, выбранном Вами в пределах 120 футов. Он остаётся, пока Вы концентрируетесь (как при концентрации на заклинании), но не более 1 часа, или пока его хиты не опустятся до 0, после чего возвращается на родной план.

Будучи призванным, [джинн](CREATURE:${CREATURE_DJINNI}) дружелюбен к Вам и Вашим спутникам. Он повинуется командам, которые Вы отдаёте, вне зависимости от использованного языка. Если Вы не отдаёте приказы, [джинн](CREATURE:${CREATURE_DJINNI}) защищается от нападающих, но не совершает других действий.

После того как [джинн](CREATURE:${CREATURE_DJINNI}) возвращается на родной план, его нельзя призвать повторно, пока не пройдёт 24 часа, и кольцо перестаёт быть магическим, если [джинн](CREATURE:${CREATURE_DJINNI}) умирает.`,
  genderId: GENDER_MIDDLE,
  source: {
    id: SOURCE_DMG,
    page: 177,
  },
}
