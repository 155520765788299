const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_AZER,
  CREATURE_EFREETI,
  CREATURE_SALAMANDER,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_FIRE,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  GEAR_SHIELD,
  GEAR_WARHAMMER,
} = require('./../../../../gearIdList')
const {ABILITY_HEATED_BODY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_IGNAN} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Эйзер`,
  nameEn: 'Azer',
  id: CREATURE_AZER,
  description: [
    `Эйзеры, уроженцы Стихийного Плана Огня, это мастера-ремесленники, опытные шахтёры и заклятые враги [ифритов](CREATURE:${CREATURE_EFREETI}). И внешне и по манере поведения эйзеры похожи на мужчин дварфов, но только лишь внешне. Под выглядящей как металл кожей эйзеров бьётся огонь, который внешне заметен лишь по их огненным волосам и бороде.`,
    {
      header: 'Созданные, не рождённые',
      text: `Эйзеры не размножаются. Каждый из них изготовлен из бронзы другим эйзером и наполнен внутренним огнём создателя. Каждый эйзер внешне уникален. Всё это ограничивает рост популяции эйзеров и является основной причиной того, что эти существа остаются редкими.`,
      source: {
        id: SOURCE_MM,
        page: 301,
      },
    },
    {
      header: 'Вулканические жители',
      text: `Эйзеры живут в королевстве на границе между Стихийным Планом Земли и Стихийным Планом Огня, в области гор и вулканов, чьи пики возвышаются как ряд крепостей. Под горными пиками, под вулканическими жерлами, среди рек магмы, эйзеры добывают металлы и драгоценные камни. Отряды эйзеров патрулируют проходы и туннели своего царства, отражая набеги [саламандр](CREATURE:${CREATURE_SALAMANDER}), атакующих царство эйзеров по приказу своих хозяев, [ифритов](CREATURE:${CREATURE_EFREETI}).`,
      source: {
        id: SOURCE_MM,
        page: 301,
      },
    },
    {
      header: 'Враги ифритов',
      text: `Давным-давно эйзеры и ифриты были союзниками. Эйзеры помогли ифритам создать Латунный город, превратив его в одно из самых удивительных рукотворных мест. Когда эйзеры закончили свою работу, ифриты предали их, предприняв попытку поработить эйзеров, чтобы сохранить секреты города. Несмотря на отдельные налёты и стычки, обе стороны пока воздерживаются от полномасштабного конфликта. Эйзеры полагают, что только угроза раскрыть секреты тайных путей в Латунный город сдерживает ифритов.`,
      source: {
        id: SOURCE_MM,
        page: 301,
      },
    },
    {
      header: 'Мастера металла и драгоценных камней',
      text: `Эйзеры — умелые ремесленники, создающие прекрасные работы из самоцветов и драгоценных металлов, которые они добывают на своей вулканической родине. Они ценят эти сокровища выше всех других вещей. Иногда, в поисках редких металлов и самоцветов, они отправляются на другие планы. Когда, при помощи магии, эйзера призывают на Материальный план, обычно это делается для создания сложного магического предмета или произведения искусства, так как считается, что их умению в подобных вещах нет равных.`,
      source: {
        id: SOURCE_MM,
        page: 301,
      },
    },
    {
      header: 'Живой огонь',
      text: `Эйзер не нуждается в еде, питье и сне.`,
      source: {
        id: SOURCE_MM,
        page: 301,
      },
    },
  ],
  note: {
    text: `Дай мне сотню эйзеров рабов, и я скую империю, которая заставит богов дрожать`,
    author: `Аракес аль-Заказр, ифритский паша`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 301,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
    gearId: GEAR_SHIELD,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 15,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_CON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  languageList: [
    LANG_IGNAN,
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_HEATED_BODY,
      damage: `5 (1к10)`,
    },
    {
      name: `Раскалённое оружие`,
      description: `Если ★СУЩЕСТВО★ попадает металлическим рукопашным оружием, он причиняет дополнительный урон огнём 3 (1к6) (уже учтено в атаках).`,
    },
    {
      name: `Свечение`,
      description: `★СУЩЕСТВО★ испускает яркий свет в радиусе 10 футов и тусклый свет в пределах ещё 10 футов.`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_WARHAMMER,
      attack: {
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 1,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
