const listToCollectionById = require('./../utils/listToCollectionById')
const {
  LANG_GROUP_CHESSAN,
  LANG_GROUP_CHULTAN,
  LANG_GROUP_ILLUSKI,
  LANG_GROUP_IMASKARI,
  LANG_GROUP_MULANI,
  LANG_GROUP_RAUMTHERAN,
  LANG_GROUP_THORASS,
  LANG_GROUP_ULOU,
  LANG_GROUP_UNTHERIC,
  LANG_GROUP_WAELAN,
} = require('./languageGroupList')

const LANG_SUBGROUP_AGLARONDAN = 'aglarondan'
const LANG_SUBGROUP_AKALAIC = 'akalaic'
const LANG_SUBGROUP_ALZHO = 'alzho'
const LANG_SUBGROUP_ANCIENT_IMASK = 'ancient_imask'
const LANG_SUBGROUP_CENTRAL_THORASS = 'central_thorass'
const LANG_SUBGROUP_CHARD = 'chard'
const LANG_SUBGROUP_CHESSENTIC = 'chessentic'
const LANG_SUBGROUP_D_TARIG = 'd_tarig'
const LANG_SUBGROUP_DRUIDIC = 'druidic'
const LANG_SUBGROUP_DURPARI = 'durpari'
const LANG_SUBGROUP_DURPARI_CREOLE = 'durpari_creole'
const LANG_SUBGROUP_EAST_THORASS = 'east_thorass'
const LANG_SUBGROUP_HIGH_ULUTIM = 'high_ulutim'
const LANG_SUBGROUP_ILLUSKI = 'illuski'
const LANG_SUBGROUP_IMASK_CREOLE = 'imask_creole'
const LANG_SUBGROUP_IMASKARI = 'imaskari'
const LANG_SUBGROUP_LANTANNA = 'lantanna'
const LANG_SUBGROUP_LOW_ULUTIM = 'low_ulutim'
const LANG_SUBGROUP_MIDANI = 'midani'
const LANG_SUBGROUP_MUHJURI = 'muhjuri'
const LANG_SUBGROUP_MULHORANDI = 'mulhorandi'
const LANG_SUBGROUP_NETHERESE = 'netherese'
const LANG_SUBGROUP_NORTH_THORASS = 'north_thorass'
const LANG_SUBGROUP_RAUMTHERAN = 'raumtheran'
const LANG_SUBGROUP_TABAXI = 'tabaxi'
const LANG_SUBGROUP_TURMIC = 'turmic'
const LANG_SUBGROUP_UNTHERIC = 'untheric'
const LANG_SUBGROUP_WAELAN = 'waelan'

const languageSubGroupList = [
  {
    id: LANG_SUBGROUP_ILLUSKI,
    langGroupId: LANG_GROUP_ILLUSKI,
    name: 'Иллюсканская',
    nameEn: 'Illuski',
  },
  {
    id: LANG_SUBGROUP_WAELAN,
    langGroupId: LANG_GROUP_WAELAN,
    name: 'Ваэланская',
    nameEn: 'Waelan',
  },
  {
    id: LANG_SUBGROUP_DRUIDIC,
    langGroupId: LANG_GROUP_WAELAN,
    name: 'Друидическая',
    nameEn: 'Druidic',
  },
  {
    id: LANG_SUBGROUP_NETHERESE,
    langGroupId: LANG_GROUP_ULOU,
    name: 'Нетерезская',
    nameEn: 'Netherese',
  },
  {
    id: LANG_SUBGROUP_HIGH_ULUTIM,
    langGroupId: LANG_GROUP_ULOU,
    name: 'Верхнеулутимская',
    nameEn: 'High Ulutim',
  },
  {
    id: LANG_SUBGROUP_LOW_ULUTIM,
    langGroupId: LANG_GROUP_ULOU,
    name: 'Нижнеулутимская',
    nameEn: 'Low Ulutim',
  },
  {
    id: LANG_SUBGROUP_CHARD,
    langGroupId: LANG_GROUP_ULOU,
    name: 'Чардская',
    nameEn: 'Chard',
  },
  {
    id: LANG_SUBGROUP_D_TARIG,
    langGroupId: LANG_GROUP_ULOU,
    name: 'Д’тарижская',
    nameEn: 'D’tarig',
  },
  {
    id: LANG_SUBGROUP_CENTRAL_THORASS,
    langGroupId: LANG_GROUP_THORASS,
    name: 'Центральноторасская',
    nameEn: 'Central Thorass',
  },
  {
    id: LANG_SUBGROUP_NORTH_THORASS,
    langGroupId: LANG_GROUP_THORASS,
    name: 'Северноторасская',
    nameEn: 'North Thorass',
  },
  {
    id: LANG_SUBGROUP_EAST_THORASS,
    langGroupId: LANG_GROUP_THORASS,
    name: 'Восточноторасская',
    nameEn: 'East Thorass',
  },
  {
    id: LANG_SUBGROUP_TURMIC,
    langGroupId: LANG_GROUP_THORASS,
    name: 'Тёрамийская',
    nameEn: 'Turmic',
  },
  {
    id: LANG_SUBGROUP_AGLARONDAN,
    langGroupId: LANG_GROUP_THORASS,
    name: 'Агларондская',
    nameEn: 'Aglarondan',
  },
  {
    id: LANG_SUBGROUP_CHESSENTIC,
    langGroupId: LANG_GROUP_CHESSAN,
    name: 'Чессентийская',
    nameEn: 'Chessentic',
  },
  {
    id: LANG_SUBGROUP_AKALAIC,
    langGroupId: LANG_GROUP_CHESSAN,
    name: 'Акалайская',
    nameEn: 'Akalaic',
  },
  {
    id: LANG_SUBGROUP_RAUMTHERAN,
    langGroupId: LANG_GROUP_RAUMTHERAN,
    name: 'Ромтеранская',
    nameEn: 'Raumtheran',
  },
  {
    id: LANG_SUBGROUP_ANCIENT_IMASK,
    langGroupId: LANG_GROUP_IMASKARI,
    name: 'Древнеимаскская',
    nameEn: 'Ancient Imask',
  },
  {
    id: LANG_SUBGROUP_IMASKARI,
    langGroupId: LANG_GROUP_IMASKARI,
    name: 'Имаскарская',
    nameEn: 'Imaskari',
  },
  {
    id: LANG_SUBGROUP_IMASK_CREOLE,
    langGroupId: LANG_GROUP_IMASKARI,
    name: 'Имаскская креольская',
    nameEn: 'Imask Creole',
  },
  {
    id: LANG_SUBGROUP_DURPARI,
    langGroupId: LANG_GROUP_IMASKARI,
    name: 'Дюрпарская',
    nameEn: 'Durpari',
  },
  {
    id: LANG_SUBGROUP_DURPARI_CREOLE,
    langGroupId: LANG_GROUP_IMASKARI,
    name: 'Дюрпарская креольская',
    nameEn: 'Durpari',
  },
  {
    id: LANG_SUBGROUP_LANTANNA,
    langGroupId: LANG_GROUP_IMASKARI,
    name: 'Лантанская',
    nameEn: 'Lantanna',
  },
  {
    id: LANG_SUBGROUP_MULHORANDI,
    langGroupId: LANG_GROUP_MULANI,
    name: 'Мулхорандская',
    nameEn: 'Mulhorandi',
  },
  {
    id: LANG_SUBGROUP_MUHJURI,
    langGroupId: LANG_GROUP_MULANI,
    name: 'Мухжурская',
    nameEn: 'Muhjuri',
  },
  {
    id: LANG_SUBGROUP_UNTHERIC,
    langGroupId: LANG_GROUP_UNTHERIC,
    name: 'Унтерская',
    nameEn: 'Untheric',
  },
  {
    id: LANG_SUBGROUP_MIDANI,
    langGroupId: LANG_GROUP_UNTHERIC,
    name: 'Миданийская',
    nameEn: 'Midani',
  },
  {
    id: LANG_SUBGROUP_ALZHO,
    langGroupId: LANG_GROUP_UNTHERIC,
    name: 'Алжойская',
    nameEn: 'Alzho',
  },
  {
    id: LANG_SUBGROUP_TABAXI,
    langGroupId: LANG_GROUP_CHULTAN,
    name: 'Табаксийская',
    nameEn: 'Tabaxi',
  },
]

module.exports = languageSubGroupList

module.exports.languageSubGroupCollection = listToCollectionById(languageSubGroupList)

module.exports.LANG_SUBGROUP_AGLARONDAN = LANG_SUBGROUP_AGLARONDAN
module.exports.LANG_SUBGROUP_AKALAIC = LANG_SUBGROUP_AKALAIC
module.exports.LANG_SUBGROUP_ALZHO = LANG_SUBGROUP_ALZHO
module.exports.LANG_SUBGROUP_ANCIENT_IMASK = LANG_SUBGROUP_ANCIENT_IMASK
module.exports.LANG_SUBGROUP_CENTRAL_THORASS = LANG_SUBGROUP_CENTRAL_THORASS
module.exports.LANG_SUBGROUP_CHARD = LANG_SUBGROUP_CHARD
module.exports.LANG_SUBGROUP_CHESSENTIC = LANG_SUBGROUP_CHESSENTIC
module.exports.LANG_SUBGROUP_D_TARIG = LANG_SUBGROUP_D_TARIG
module.exports.LANG_SUBGROUP_DRUIDIC = LANG_SUBGROUP_DRUIDIC
module.exports.LANG_SUBGROUP_DURPARI = LANG_SUBGROUP_DURPARI
module.exports.LANG_SUBGROUP_DURPARI_CREOLE = LANG_SUBGROUP_DURPARI_CREOLE
module.exports.LANG_SUBGROUP_EAST_THORASS = LANG_SUBGROUP_EAST_THORASS
module.exports.LANG_SUBGROUP_HIGH_ULUTIM = LANG_SUBGROUP_HIGH_ULUTIM
module.exports.LANG_SUBGROUP_ILLUSKI = LANG_SUBGROUP_ILLUSKI
module.exports.LANG_SUBGROUP_IMASK_CREOLE = LANG_SUBGROUP_IMASK_CREOLE
module.exports.LANG_SUBGROUP_IMASKARI = LANG_SUBGROUP_IMASKARI
module.exports.LANG_SUBGROUP_LANTANNA = LANG_SUBGROUP_LANTANNA
module.exports.LANG_SUBGROUP_LOW_ULUTIM = LANG_SUBGROUP_LOW_ULUTIM
module.exports.LANG_SUBGROUP_MIDANI = LANG_SUBGROUP_MIDANI
module.exports.LANG_SUBGROUP_MUHJURI = LANG_SUBGROUP_MUHJURI
module.exports.LANG_SUBGROUP_MULHORANDI = LANG_SUBGROUP_MULHORANDI
module.exports.LANG_SUBGROUP_NETHERESE = LANG_SUBGROUP_NETHERESE
module.exports.LANG_SUBGROUP_NORTH_THORASS = LANG_SUBGROUP_NORTH_THORASS
module.exports.LANG_SUBGROUP_RAUMTHERAN = LANG_SUBGROUP_RAUMTHERAN
module.exports.LANG_SUBGROUP_TABAXI = LANG_SUBGROUP_TABAXI
module.exports.LANG_SUBGROUP_TURMIC = LANG_SUBGROUP_TURMIC
module.exports.LANG_SUBGROUP_UNTHERIC = LANG_SUBGROUP_UNTHERIC
module.exports.LANG_SUBGROUP_WAELAN = LANG_SUBGROUP_WAELAN
