const {GOD_ANNAM} = require('./../../godIdList')
const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')
const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')
const {
  CREATURE_DRAGON_TURTLE,
  CREATURE_LICH,
  CREATURE_MERFOLK,
  CREATURE_PEGASUS,
  CREATURE_STORM_GIANT_QUINTESSENT,
  CREATURE_WATER_WEIRD,
  CREATURE_YETI,
} = require('./../../creatureIdList')

const giantDescriptionList = require('./giant')

module.exports = [
  {
    header: 'Штормовые великаны',
    text: `Штормовые великаны это задумчивые провидцы, живущие в местах, далёких от цивилизации смертных. У большинства бледный, серо-фиолетовый цвет кожи и волос, а также блестящие изумрудные глаза. Редко встречаются и штормовые великаны с фиолетовой кожей, тёмно-фиолетовыми или тёмно-синими волосами, а также серебристо-серыми или фиолетовыми глазами. Они доброжелательны и мудры, но если разозлить штормового великана, то судьбы тысяч окажутся во власти его ярости.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Далёкие короли-пророки',
    text: `Штормовые великаны живут в отдалённых убежищах высоко над миром или так глубоко в океанах, что большинство существ не может их достичь. Некоторые селятся в облачных замках, откуда летящий дракон кажется лишь точкой. Другие живут на горных вершинах, пронизывающих облака. Есть и те, что занимают дворцы, покрытые водорослями и кораллами на дне океана, или мрачные крепости в подводных расщелинах.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Отрешённые оракулы',
    text: `Штормовые великаны помнят славу древних империй великанов, созданных богом Аннамом. Они пытаются восстановить то, что было утрачено при падении этих империй. Они не конкурируют за статус в уложении, но свои века проживают в задумчивой изоляции, ища на звёздном небе и в океанских глубинах знаки, символы и предзнаменования благословения Аннама.

Штормовые великаны видят мировые события в широкой перспективе. Они могут предсказать подъём и падение королей и империй, увидеть начало и конец удачи и несчастья, и найти связь в, казалось бы, несвязанных событиях. Читая знаки и пророчествуя, штормовые великаны изучают множество секретов, до того неизвестных, и знания, что уже позабыты.

Короли взойдут и падут, войны выиграют и проиграют, а добро и зло сойдутся в схватках. За всем этим штормовые великаны будут наблюдать, словно смертные боги на протяжении многих жизней, и им известно, что вмешиваться бессмысленно. Однако штормовой великан может добровольно раскрыть секреты доброжелательным существам, прибывших в его далёкие владения с определённой целью. Такие существа должны говорить и действовать уважительно, ведь разозлённый штормовой великан — сила абсолютного разрушения.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Уединённые жизни',
    text: `Штормовые великаны редко общаются с сородичами. Обычно они сравнивают символы и знамения или вступают в краткие браки. Родители штормовых великанов остаются вместе, пока растёт их ребёнок, а затем возвращаются к любимой ими изоляции.

Некоторые культуры гуманоидов поклоняются штормовым великанам как малым богам, создавая мифы и истории о подвигах великанов и их обширных знаниях. Штормовым великаном управляет совесть, а не законы или честь. Так что штормовой великан, чей разум одолела жадность или вкус власти, легко может стать страшной угрозой.`,
    source: {
      id: SOURCE_MM,
      page: 36,
    },
  },
  {
    header: 'Штормовые великаны',
    text: `Штормовые великаны — самые мощные и величественные из великанов, также являются наиболее отчуждёнными и наименее понятыми. Уваръётуны — не просто силы природы; они связаны с природой, и мистическим образом являются её продолжением, что людям бывает трудно понять.`,
    source: {
      id: SOURCE_VGTM,
      page: 33,
    },
  },
  {
    header: 'Уложение предзнаменований',
    text: `Каждый штормовой великан узнаёт свой статус в Уложении через сигналы, которые ему посылает вселенная. Предзнаменования могут быть посланы через кружащие стаи птиц, узоры на песке, оставленные прибоем, форму облаков и бессчётное количество других природных явлений. Штормовые великаны, получающие наибольшее количество таких предзнаменований, занимают, как правило, самый высокий ранг, но на статус также может повлиять значимость отдельных признаков. В тех редких случаях, когда штормовые великаны встречаются, приметы и знаки сопровождают каждого из них, из-за чего всем присутствующим становится ясно, кто из них какое место занимает. Споры о ранге в Уложении встречаются редко, так как все великаны в группе прилежно исследуют каждое предзнаменование в поисках сигнала, что один из них может быть самым великим из живших штормовых великанов, так как это может ознаменовать возвращение [Аннама](GOD:${GOD_ANNAM}).

С тех пор, как Остория пала, а [Аннам](GOD:${GOD_ANNAM}) отрёкся от своих детей, ни один король или император не правил всеми великанами. Согласно легенде, появление такого лидера будет предзнаменовано знаками во всех стихиях природы: в небе (воздухе), море (воде), континентах (земле) и под землёй (огне). Во всех этих царствах штормовые великаны бдительно наблюдают за появлением важных знамений. В былые эпохи, когда великанами правили династии, знамения, которые сопровождали их общего лидера были всегда ясными и безошибочно определяемыми. С течением веков после падения империи те немногие знамения, которые проявлялись, становились противоречивыми, нечеткими и спорными.

По понятным причинам, каждый штормовой великан интересуется тем, как скоро произойдет возвращение [Аннама](GOD:${GOD_ANNAM}) — они все хотят дожить, чтобы это увидеть. Некоторые получают для себя относительное бессмертие путем слияния со стихией. Эти штормовые великаны — [квинтэссенты](CREATURE:${CREATURE_STORM_GIANT_QUINTESSENT}), самые ярые затворники из всех, селятся в отдалённых и негостеприимных местах, окружённых жестокими ветрами и убийственной погодой.

Без императора, который бы мог быть их политическим и духовным лидером, штормовые великаны дрейфуют в океане неопределённости. Каждое возможное толкование каждого знамения тщательно исследуется. Споры о значении и правдивости того или иного предзнаменования ведутся на территориях больше человеческих королевств и продолжаются дольше человеческих жизней.

Исследователи и путешественники видят возможности в этой ситуации, так как иногда великаны нанимают агентов, чтобы те исследовали предвестия или добыли предметы, которые великанам нужны для их оракулов. Это опасная работа, опасная по двум причинам. Первая из них очевидна: такая работа предполагает спуск в осторианские руины, которые оставались нетронутыми тысячелетия. Вторая, менее очевидная — это то, что некоторые знамения, если они окажутся правдивыми, могут и впрямь привести к возвращению [Аннама](GOD:${GOD_ANNAM}), которое перевернёт социальный порядок великанов и начнёт новую эру. Некоторые приветствовали бы такое изменение; другие будут выступать против него и делать всё возможное, чтобы предотвратить это, возможно даже прибегая к тотальной войне.`,
    source: {
      id: SOURCE_VGTM,
      page: 33,
    },
  },
  {
    header: 'Сами за себя',
    text: `При отсутствии и [Аннама](GOD:${GOD_ANNAM}), и мирского императора, штормовые великаны не признают иной высшей власти. [Человек](PC_RACE:${PC_RACE_HUMAN}), [эльф](PC_RACE:${PC_RACE_ELF}), и короли [дварфов](PC_RACE:${PC_RACE_DWARF}), [личи](CREATURE:${CREATURE_LICH}), великие маги и [волшебники](PC_CLASS:${PC_CLASS_WIZARD}) — все могли бы накопить великую мощь, но они не имеют никакого влияния на штормовых великанов. Любой, кто пытается расширить свою власть этом направлении гарантированно потерпит неудачу.

Но до тех пор, пока мир оставляет штормовых великанов в покое, и великаны оставляют в покое мир. Они не желают ни добра, ни зла человечеству; они просто не задумываются над этим вопросом, за исключением тех редких случаев, когда люди неожиданно не упоминаются пророчеством или намёк на них не появится в предзнаменовании.

Когда штормовые великаны действительно взаимодействуют с не-великанами, те, к кому обращено их внимание, могут поставить под сомнение идею, что штормовые великаны «хорошие» существа. Они уважают принцип святости жизни, но даже самый спокойный штормовой великан имеет взрывной темперамент. Когда великан гневается, принципы уступают место ярости, и преступление, совершенное одним лицом против великана, может принести суровое воздаяние на всё сообщество.

Штормовой великан, который разрушает город и убивает невинных людей в порыве гнева, вероятно, сожалеет об этом позже, и может предложить компенсацию, чтобы загладить свою вину, хотя мешок золота, скорее всего, слабое утешение для тех, кто потерял близких, дома и средства к существованию. Поэтому всегда стоит действовать осторожно, говорить с почтением, и действовать с уважением в присутствии великана, что особенно касается штормовых великанов.`,
    source: {
      id: SOURCE_VGTM,
      page: 33,
    },
  },
  {
    header: 'Жизнь на грани',
    text: `После того, как штормовые великаны достаточно взрослеют, чтобы мочь постоять за себя, они проводят большую часть своей жизни в созерцательной изоляции. Штормовые великаны способны жить везде, где захотят, независимо от того, где это — на вершине горы, в ледяной пещере, или на дне самой глубокого океанского разлома. Тип места, который неизменно привлекает их внимание — это перекрёстки стихий, где Материальный план пересекается и взаимодействует со Стихийными планами. Стихийное влияние пронизывает архитектуру штормовых великанов и придает чужеродное, штормовое обличье их домам.

Штормовые великаны используют стихийные перекрёстки для межпланарных странствий, особенно в Стихийный план Воздуха и Стихийный план Воды. Частые водовороты, торнадо и ливни, которые хлещут в проходах этих двух планов помогают защитить дома великанов и обеспечить их уединённость.

Хотя штормовые великаны и предпочитают жить вдали от других великанов, это не обязательно жизнь в одиночестве в своей крепости. Штормовые великаны делятся своей обителью с другими существами, которым комфортно в соответствующей среде: к примеру, штормовой великан, обитающий в море, может иметь несколько [мерфолков](CREATURE:${CREATURE_MERFOLK}), [водных аномалий](CREATURE:${CREATURE_WATER_WEIRD}), или даже [дракочерепахи](CREATURE:${CREATURE_DRAGON_TURTLE} для компании, в то время как штормовой великан, живущий на вершине горы потянет руку дружбы любому [пегасу](CREATURE:${CREATURE_PEGASUS}), которого встретит, и может даже приветствовать [йети](CREATURE:${CREATURE_YETI}) в своем доме, если он считает, что может ему доверять. От гостей великана ожидается почтительность и полезность, а также умение поддержать интересную беседу или иным образом развлечь великана, когда тому захочется пообщаться.`,
    source: {
      id: SOURCE_VGTM,
      page: 33,
    },
  },
  ...giantDescriptionList,
]
