const {CREATURE_AARAKOCRA} = require('./../../creatureIdList')
const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Гаргульи',
    text: `Облик безжизненных горгулий, украшающих вершины величественных зданий, навеян этими злобными существами стихии земли, похожими на гротескные дьявольские статуи. Горгулья таится среди каменных стен и руин, неподвижная, подобно каменным скульптурам, и наслаждается тем ужасом, который она вселяет, когда оживает, и той болью, которую причиняет своим жертвам.`,
    source: {
      id: SOURCE_MM,
      page: 71,
    },
  },
  {
    header: 'Оживший камень',
    text: `Горгульи гнездятся на утёсах и скалах, а также на выступах в пещерах. Они обитают на городских крышах, подобно стервятникам, примостившись среди высоких каменных арок и контрфорсов замков и соборов, и способны сидеть настолько смирно, что выглядят безжизненными. В таком состоянии горгулья может находиться годами, что делает её идеальным стражем.`,
    source: {
      id: SOURCE_MM,
      page: 71,
    },
  },
  {
    header: 'Зловещая репутация',
    text: `Горгульи известны своей жестокостью. Статуи в виде горгулий присутствуют в архитектуре многих культур, и они призваны отпугивать злоумышленников. Несмотря на то, что это всего лишь статуи, среди них могут затаиться настоящие горгульи, поджидающие своих жертв. Со скуки горгулья может ловить и мучить птиц и грызунов, но чем дольше она ждёт, тем сильнее жаждет навредить разумным существам.`,
    source: {
      id: SOURCE_MM,
      page: 71,
    },
  },
  {
    header: 'Безжалостные слуги',
    text: `Хитрые существа с лёгкостью могут заставить горгулий служить себе. Им нравятся простые задачи, такие как охрана хозяйского дома, пытки и убийство нарушителей, в общем, всё, что связано с минимумом усилий и максимум боли и насилия.

Из-за склонности к беспричинному хаосу и разрушению горгульи иногда служат демонам. Могущественные заклинатели также без труда привлекают горгулий для охраны ворот и стен. Горгульи обладают терпением и стойкостью камня, и годами могут служить даже жесточайшему хозяину, не выражая недовольства.`,
    source: {
      id: SOURCE_MM,
      page: 71,
    },
  },
  {
    header: 'Стихийная натура',
    text: `Горгулье не нужен воздух, еда, питьё и сон.`,
    source: {
      id: SOURCE_MM,
      page: 71,
    },
  },
  {
    header: 'Осколки стихийного зла',
    text: `Проходя по своему каменному царству, Огремох, злой Князь Стихийной Земли, оставляет за собой след из осколков раздробленных камней. Эти наполненные частицами сознания осколки входят в резонанс с сущностью владыки стихии и за несколько лет принимают человекоподобную форму, становясь в итоге ужасными, безжалостными горгульями.

Огремох не создаёт их намеренно, но они являются материальным воплощением его злой сути. Горгульи — насмешка над стихией Воздуха, которую презирает Огремох. Это тяжеловесные существа из ожившего камня, которые всё же способны летать. Подобно своему создателю, они ненавидят существ воздушной стихии, в том числе [ааракокр](CREATURE:${CREATURE_AARAKOCRA}), и с наслаждением используют любую возможность, чтобы их уничтожить.

На своём родном плане горгульи вырезают каменные глыбы, которые Огремох швыряет в Аакуа — владения [аарококр](CREATURE:${CREATURE_AARAKOCRA}) и великодушных Герцогов Ветра, которым служит крылатое племя Стихийного Плана Воздуха.`,
    source: {
      id: SOURCE_MM,
      page: 71,
    },
  },
]
