const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_WRISTPOCKET} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_WRISTPOCKET,
  name: 'Наручный карман',
  nameEn: 'Wristpocket',
  description: `Вы дёргаете запястьем, заставляя один предмет в Вашей руке исчезнуть. Предмет, который держите только Вы и который весит не более 5 фунтов, исчезает в межпространстве, оставаясь там на всю длительность заклинания.

Пока заклинание не окончится, Вы можете действием вызвать тот предмет в свою свободную руку и действием снова вернуть его в межпространство. Предмет, всё ещё находящийся в межпространстве, когда заклинание оканчивается, появляется у Ваших ног.`,
  lvl: 2,
  magicSchoolId: MAGIC_CONJURATION,
  range: -1,
  componentIdList: [CAST_SOMATIC],
  duration: { timeId: TIME_HOUR, count: 1 },
  isRitual: true,
  needConcentration: true,
  isDunamisBased: true,
  source: {
    id: SOURCE_EGTW,
    page: 191,
  },
}
