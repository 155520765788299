const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_ANDT} = require('./../../languageIdList')
const {LANG_SUBGROUP_LOW_ULUTIM} = require('./../../languageSubGroupList')

module.exports = {
  id: LANG_ANDT,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_LOW_ULUTIM,
  nameEn: 'Andt',
  name: {
    nominative: 'Эндт',
    genitive: 'Эндта',
    instrumental: 'Эндтом',
    prepositional: 'Эндте',
  },
  isReady: false,
  isRealLang: true,
}
