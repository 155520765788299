const {
  SPELL_AID,
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ANIMAL_MESSENGER,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ANTIPATHY_SYMPATHY,
  SPELL_AWAKEN,
  SPELL_BANE,
  SPELL_BESTOW_CURSE,
  SPELL_BLADE_WARD,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CALM_EMOTIONS,
  SPELL_CATNAP,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_CLAIRVOYANCE,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_COLOR_SPRAY,
  SPELL_COMMAND,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_COMPULSION,
  SPELL_CONFUSION,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISPEL_MAGIC,
  SPELL_DISSONANT_WHISPERS,
  SPELL_DISTORT_VALUE,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_DREAM,
  SPELL_DREAM_OF_THE_BLUE_VEIL,
  SPELL_EARTH_TREMOR,
  SPELL_EGO_WHIP_2019_11_25,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENEMIES_ABOUND,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_ENTHRALL,
  SPELL_ETHEREALNESS,
  SPELL_EYEBITE,
  SPELL_FAERIE_FIRE,
  SPELL_FAST_FRIENDS,
  SPELL_FEAR,
  SPELL_FEATHER_FALL,
  SPELL_FEEBLEMIND,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_THE_PATH,
  SPELL_FORCECAGE,
  SPELL_FORESIGHT,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_FRIENDS,
  SPELL_GEAS,
  SPELL_GIFT_OF_GAB,
  SPELL_GLIBNESS,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDS_AND_WARDS,
  SPELL_GUIDING_HAND_2017_04_03,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HEALING_WORD,
  SPELL_HEAT_METAL,
  SPELL_HEROES_FEAST,
  SPELL_HEROISM,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_ID_INSINUATION_2019_11_25,
  SPELL_IDENTIFY,
  SPELL_ILLUSORY_SCRIPT,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2019_11_25,
  SPELL_INTELLECT_FORTRESS_2020_04_14,
  SPELL_INVISIBILITY,
  SPELL_KNOCK,
  SPELL_LEGEND_LORE,
  SPELL_LEOMUND_S_TINY_HUT,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHT,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MOUTH,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MASS_HEALING_WORD,
  SPELL_MASS_POLYMORPH,
  SPELL_MASS_SUGGESTION,
  SPELL_MENDING,
  SPELL_MENTAL_BARRIER_2019_11_25,
  SPELL_MESSAGE,
  SPELL_MIND_BLANK,
  SPELL_MIND_THRUST_2019_11_25,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRROR_IMAGE,
  SPELL_MISLEAD,
  SPELL_MODIFY_MEMORY,
  SPELL_MORDENKAINEN_S_MAGNIFICENT_MANSION,
  SPELL_MORDENKAINEN_S_SWORD,
  SPELL_MOTIVATIONAL_SPEECH,
  SPELL_NATHAIR_S_MISCHIEF,
  SPELL_NATHAIR_S_MISCHIEF_2021_04_14,
  SPELL_NONDETECTION,
  SPELL_OTTO_S_IRRESISTIBLE_DANCE,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PLANAR_BINDING,
  SPELL_PLANT_GROWTH,
  SPELL_POLYMORPH,
  SPELL_POWER_WORD_HEAL,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_STUN,
  SPELL_PRESTIDIGITATION,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PRISMATIC_WALL,
  SPELL_PROGRAMMED_ILLUSION,
  SPELL_PROJECT_IMAGE,
  SPELL_PSIONIC_BLAST_2019_11_25,
  SPELL_PSYCHIC_CRUSH_2019_11_25,
  SPELL_PSYCHIC_SCREAM,
  SPELL_PUPPET_2017_04_03,
  SPELL_PYROTECHNICS,
  SPELL_RAISE_DEAD,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
  SPELL_REGENERATE,
  SPELL_RESURRECTION,
  SPELL_SCRYING,
  SPELL_SEE_INVISIBILITY,
  SPELL_SEEMING,
  SPELL_SENDING,
  SPELL_SENSE_EMOTION_2017_04_03,
  SPELL_SHAPECHANGE,
  SPELL_SHATTER,
  SPELL_SILENCE,
  SPELL_SILENT_IMAGE,
  SPELL_SKILL_EMPOWERMENT,
  SPELL_SKYWRITE,
  SPELL_SLEEP,
  SPELL_SLOW,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_STINKING_CLOUD,
  SPELL_SUDDEN_AWAKENING_2017_04_03,
  SPELL_SUGGESTION,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SYMBOL,
  SPELL_SYNAPTIC_STATIC,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_THOUGHT_SHIELD_2019_11_25,
  SPELL_THUNDERCLAP,
  SPELL_THUNDERWAVE,
  SPELL_TONGUES,
  SPELL_TRUE_POLYMORPH,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_UNEARTHLY_CHORUS_2017_04_03,
  SPELL_UNSEEN_SERVANT,
  SPELL_VICIOUS_MOCKERY,
  SPELL_WARDING_WIND,
  SPELL_ZONE_OF_TRUTH,
} = require('./../../spellIdList')

module.exports = [
  SPELL_AID,
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ANIMAL_MESSENGER,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ANTIPATHY_SYMPATHY,
  SPELL_AWAKEN,
  SPELL_BANE,
  SPELL_BESTOW_CURSE,
  SPELL_BLADE_WARD,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CALM_EMOTIONS,
  SPELL_CATNAP,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_CLAIRVOYANCE,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_COLOR_SPRAY,
  SPELL_COMMAND,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_COMPULSION,
  SPELL_CONFUSION,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISPEL_MAGIC,
  SPELL_DISSONANT_WHISPERS,
  SPELL_DISTORT_VALUE,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_DREAM,
  SPELL_DREAM_OF_THE_BLUE_VEIL,
  SPELL_EARTH_TREMOR,
  SPELL_EGO_WHIP_2019_11_25,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENEMIES_ABOUND,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_ENTHRALL,
  SPELL_ETHEREALNESS,
  SPELL_EYEBITE,
  SPELL_FAERIE_FIRE,
  SPELL_FAST_FRIENDS,
  SPELL_FEAR,
  SPELL_FEATHER_FALL,
  SPELL_FEEBLEMIND,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_THE_PATH,
  SPELL_FORCECAGE,
  SPELL_FORESIGHT,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_FRIENDS,
  SPELL_GEAS,
  SPELL_GIFT_OF_GAB,
  SPELL_GLIBNESS,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDS_AND_WARDS,
  SPELL_GUIDING_HAND_2017_04_03,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HEALING_WORD,
  SPELL_HEAT_METAL,
  SPELL_HEROES_FEAST,
  SPELL_HEROISM,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_ID_INSINUATION_2019_11_25,
  SPELL_IDENTIFY,
  SPELL_ILLUSORY_SCRIPT,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2019_11_25,
  SPELL_INTELLECT_FORTRESS_2020_04_14,
  SPELL_INVISIBILITY,
  SPELL_KNOCK,
  SPELL_LEGEND_LORE,
  SPELL_LEOMUND_S_TINY_HUT,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHT,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MOUTH,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MASS_HEALING_WORD,
  SPELL_MASS_POLYMORPH,
  SPELL_MASS_SUGGESTION,
  SPELL_MENDING,
  SPELL_MENTAL_BARRIER_2019_11_25,
  SPELL_MESSAGE,
  SPELL_MIND_BLANK,
  SPELL_MIND_THRUST_2019_11_25,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRROR_IMAGE,
  SPELL_MISLEAD,
  SPELL_MODIFY_MEMORY,
  SPELL_MORDENKAINEN_S_MAGNIFICENT_MANSION,
  SPELL_MORDENKAINEN_S_SWORD,
  SPELL_MOTIVATIONAL_SPEECH,
  SPELL_NATHAIR_S_MISCHIEF,
  SPELL_NATHAIR_S_MISCHIEF_2021_04_14,
  SPELL_NONDETECTION,
  SPELL_OTTO_S_IRRESISTIBLE_DANCE,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PLANAR_BINDING,
  SPELL_PLANT_GROWTH,
  SPELL_POLYMORPH,
  SPELL_POWER_WORD_HEAL,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_STUN,
  SPELL_PRESTIDIGITATION,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PRISMATIC_WALL,
  SPELL_PROGRAMMED_ILLUSION,
  SPELL_PROJECT_IMAGE,
  SPELL_PSIONIC_BLAST_2019_11_25,
  SPELL_PSYCHIC_CRUSH_2019_11_25,
  SPELL_PSYCHIC_SCREAM,
  SPELL_PUPPET_2017_04_03,
  SPELL_PYROTECHNICS,
  SPELL_RAISE_DEAD,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
  SPELL_REGENERATE,
  SPELL_RESURRECTION,
  SPELL_SCRYING,
  SPELL_SEE_INVISIBILITY,
  SPELL_SEEMING,
  SPELL_SENDING,
  SPELL_SENSE_EMOTION_2017_04_03,
  SPELL_SHAPECHANGE,
  SPELL_SHATTER,
  SPELL_SILENCE,
  SPELL_SILENT_IMAGE,
  SPELL_SKILL_EMPOWERMENT,
  SPELL_SKYWRITE,
  SPELL_SLEEP,
  SPELL_SLOW,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_STINKING_CLOUD,
  SPELL_SUDDEN_AWAKENING_2017_04_03,
  SPELL_SUGGESTION,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SYMBOL,
  SPELL_SYNAPTIC_STATIC,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_THOUGHT_SHIELD_2019_11_25,
  SPELL_THUNDERCLAP,
  SPELL_THUNDERWAVE,
  SPELL_TONGUES,
  SPELL_TRUE_POLYMORPH,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_UNEARTHLY_CHORUS_2017_04_03,
  SPELL_UNSEEN_SERVANT,
  SPELL_VICIOUS_MOCKERY,
  SPELL_WARDING_WIND,
  SPELL_ZONE_OF_TRUTH,
]
