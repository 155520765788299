const {SOURCE_TOA} = require('./../../../../sourceList')
const {
  flamingFistDescriptionList,
  flamingFistNoteList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_GUARD,
  CREATURE_FLAMING_FIST,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Пылающий кулак',
  nameAlt: [
    'Рядовой Пылающего Кулака',
    'Наёмник Пылающего Кулака',
  ],
  nameEn: 'Flaming Fist',
  nameEnAlt: 'Flaming Fist Private',
  id: CREATURE_FLAMING_FIST,
  parentId: CREATURE_GUARD,
  description: flamingFistDescriptionList,
  note: flamingFistNoteList,
  source: {
    id: SOURCE_TOA,
    page: 75,
  },
}
