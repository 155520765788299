const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')
const {GOD_TIAMAT} = require('./../../godIdList')
const {PC_CLASS_CLERIC} = require('./../../pcClassIdList')
const {GEAR_GREATSWORD} = require('./../../gearIdList')
const {
  CREATURE_ALLIP,
  CREATURE_ELDER_BRAIN,
  CREATURE_LICH,
  CREATURE_GITHYANKI_KITHRAK,
  CREATURE_GITHYANKI_KNIGHT,
  CREATURE_GITHYANKI_SUPREME_COMMANDER,
  CREATURE_GITHYANKI_WARRIOR,
  CREATURE_GITHZERAI_MONK,
  CREATURE_MIND_FLAYER,
  CREATURE_PRIEST,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Гитьянки',
    text: `Гитьянки грабят бесчисленные миры с палуб своих астральных кораблей и спин красных драконов. Перья, бисер, самоцветы и металлы украшают их доспехи и оружие — легендарные серебряные мечи, с которыми они прорубаются с боем через врагов.

Вырвав свободу у [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), гитьянки стали безжалостными завоевателями под руководством ужасной королевы-лича Влаакит.`,
    source: {
      id: SOURCE_MM,
      page: 57,
    },
  },
  {
    header: `Гитьянки`,
    text: `Рабство у [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}) воспитало в гитьянки беспощадность, и они бороздят Астральное море на красных драконах, обрушивая серебряные мечи и псионическую мощь на любые следы угрозы [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}).`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Астральные налётчики',
    text: `Гитьянки презирают все прочие расы и совершают опустошительные набеги из своих крепостей на Астральном Плане в самые удалённые уголки мультивселенной.

Война — это высшее проявление культуры гитьянок, их безжалостные чёрные глаза не знают пощады. После налёта они оставляют побеждённым выжившим достаточно еды и ресурсов для выживания.
 
Позднее гитьянки снова вернутся к покорённому населению, грабя их снова и снова.`,
    source: {
      id: SOURCE_MM,
      page: 57,
    },
  },
  {
    header: 'Последователи Гит',
    text: `На их языке «гитьянки» значит «последователи Гит». Под руководством Гит гитьянки сформировались в милитаристское общество со строгой кастовой системой, посвятившее себя непрерывным сражениям с заклятыми врагами и жертвами своей расы.

Среди всех своих врагов гитьянки больше всего ненавидят своих бывших хозяев, [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}). Их близкие родственники [гитцераи](CREATURE:${CREATURE_GITHZERAI_MONK}) — второй по величине ненавистный враг. Все остальные существа презираются гитьянками одинаково, и ксенофобская гордость полностью определяет их отношение к низшим расам.`,
    source: {
      id: SOURCE_MM,
      page: 57,
    },
  },
  {
    header: 'Серебряные мечи',
    text: `В древние времена [рыцари](CREATURE:${CREATURE_GITHYANKI_KNIGHT}) гитов создавали специальное оружие, чтобы противостоять [свежевателям разума](CREATURE:${CREATURE_MIND_FLAYER}). Воля владельца проходит через такой меч, причиняя не только физический, но и психический урон. Прежде чем стать рыцарем, гитьянки должна в совершенстве постичь исключительную дисциплину, необходимую, чтобы воплотить такой меч в реальность.

Серебряный меч — эквивалент [двуручного меча](GEAR:${GEAR_GREATSWORD}), а в руках своего создателя получает свойства двуручного меча +3.

В глазах гитьянок каждый серебряный меч это бесценная реликвия, произведение искусства. [Рыцари](CREATURE:${CREATURE_GITHYANKI_KNIGHT}) гитьянок выследят и уничтожат любого, кто не является гитьянкой и при этом носит серебряный меч или просто владеет им, и вернут меч своему народу.`,
    source: {
      id: SOURCE_MM,
      page: 57,
    },
  },
  {
    header: 'Наездники красных драконов',
    text: `В восстании против [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}) Гит искала союзников. Влаакит, её советница, обратилась к богине злых драконов [Тиамат](GOD:${GOD_TIAMAT}), и Гит отправилась в Девять Преисподних для встречи с ней.

Ныне лишь [Тиамат](GOD:${GOD_TIAMAT}) знает о случившемся там, но Влаакит вернулась на Астральный План вместе с супругом Драконьей Королевы — красным драконом Эфеломоном, объявившим свой род вечным союзником гитьянок. Не все красные драконы чтут альянс, созданный так давно, но большинство хотя бы не считает гитьянок своими врагами.`,
    source: {
      id: SOURCE_MM,
      page: 57,
    },
  },
  {
    header: 'Аванпосты в царстве смертных',
    text: `Живущие на Астральном Плане не стареют, поэтому гитьянки создают ясли в отдалённых районах Материального плана, где растят своих детей.

Эти ясли выступают и в роли военных академий, где юные гитьянки совершенствуют своё псионическое и боевое мастерство. Воссоединиться со своими сородичами на Астральном Плане гитьянки может лишь когда повзрослеет и убьёт [свежевателя разума](CREATURE:${CREATURE_MIND_FLAYER}) в священном обряде инициации.`,
    source: {
      id: SOURCE_MM,
      page: 57,
    },
  },
  {
    header: 'Гитьянки',
    text: `Отвоевав свою свободу у [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), гитьянки стали развращёнными налётчиками и опустошителями, ведомыми ужасающей королевой-[личом](CREATURE:${CREATURE_LICH}) Влаакит. Она живёт на Астральном Плане в городе Ту’нарат, построенном на и в трупе божества.

Влаакит правит верными ей гитьянки из своей личной твердыни Сусуррус, расположенной глубоко внутри летающего города и называемой Дворцом Шёпота. Она восседает на Костяном Троне, могущественном артефакте, питаемом интеллектом [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}) и [старших мозгов](CREATURE:${CREATURE_ELDER_BRAIN}), захваченных её прислужниками. Он изготовлен из черепов и конечностей [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), а подушка, на которой она сидит, сделана из дублёной кожи [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}). Возле дворца возвышается монументальная статуя Гит высотой более 100 футов.`,
    source: {
      id: SOURCE_MTOF,
      page: 87,
    },
  },
  {
    header: 'Почитаемая королева',
    text: `Влаакит находится в центре всего, что связано с гитьянками. Она управляет ими во всех сферах деятельности, требуя и получая абсолютное повиновение.

Во время войны с иллитидами Влаакит убеждала Гит искать союзников на других планах, в частности обратиться к [Тиамат](GOD:${GOD_TIAMAT}). Гит согласилась отправиться в Девять Преисподних для заключения альянса с Королевой Драконов. Она не вернулась. Вместо этого великий красный дракон Эфеломон принёс гитам новость: [Тиамат](GOD:${GOD_TIAMAT}) отправляет множество своих красных драконов служить делу гитов. Они не будут нападать на гитов, будут поддерживать гитов в противостоянии с иллитидами и защищать форпосты гитов на Материальном плане. Взамен несколько избранных молодых красных драконов будут некоторое время сражаться бок о бок с гитами в целях, известных лишь [Тиамат](GOD:${GOD_TIAMAT}). Эфеломон также провозгласил, что Влаакит будет править вместо Гит, пока та не вернётся.

После того как гиты свергли [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), а последователи Зертимона начали представлять угрозу превосходству Гит, Влаакит сыграла решающую роль в защите гитьянки, находящихся под её властью, от прямых атак своих собратьев. Воспользовавшись своими познаниями в тайной магии, она помогла гитьянки заложить нерушимую крепость на Астральном Плане, откуда она начала планировать удары по ненавистным [свежевателям разума](CREATURE:${CREATURE_MIND_FLAYER}) и вероломным гитцераям.`,
    source: {
      id: SOURCE_MTOF,
      page: 87,
    },
  },
  {
    header: 'Великая декларация',
    text: `Влаакит укрепила своё положение верховного правителя гитьянки с помощи Великой Декларации, которая определила всеобъемлющую миссию гитьянки. Рождённые и обученные для противостояний, они не знали иной жизни, кроме как на войне. Им нужны были чёткая цель и сильный лидер, который поведёт к ней, Влаакит дала и то, и другое.

Она постановила, что, победив [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), гитьянки займут их место правителей Материального плана. Множество миров Материального плана будут садами гитьянки, где они будут собирать урожай, когда сочтут нужным. Астральный План будет их домом, потому что в этой вневременной области они смогут игнорировать потребности в еде и воде, а также другие повседневные проблемы, которые беспокоят низшие расы.

Влаакит также объявила, что гитьянки, проявившие себя в битве, возносятся в рай, который лучше и величественнее. Длительная служба открывает любому гитьянки путь к безграничному наслаждению в её сокрытом дворе — чудесном месте, которое Гит открыла в своих путешествиях, и где она ждёт тех, кто достойно себя показал.`,
    source: {
      id: SOURCE_MTOF,
      page: 88,
    },
  },
  {
    header: 'Жестокая правда',
    text: `С тех пор, как Влаакит дала эти обещания своему народу, она представила к этой награде многих грозных воинов гитьянки. Кульминацией грандиозной церемонии, которая якобы служит подготовкой к путешествию в жилище Гит, виновник торжества входит во внутреннее святилище, и его больше никогда не видят.

По правде говоря, вместо того, чтобы отправить их в рай, Влаакит осушает их души, поглощая их силу и становясь более могущественной после каждого «вознесения». Её познания в тайной магии сопоставимы с конклавом архимагов, а боевые умения — это сложенный вместе талант сотен мастеров меча.

Возможно, обещания королевы-лича не полностью вымышлены, но никто не может сказать об этом наверняка. И если Влаакит знает что-то ещё, она приняла радикальные меры, чтобы держать это в секрете. Некоторые мудрецы и заклинатели пытались узнать правду о судьбе Гит, используя тайную магию, но лишь стали жертвой странного проклятья, которое превращало их в бесформенных существ, известных как [аллипы](CREATURE:${CREATURE_ALLIP}).

Попытки узнать что-нибудь о Гит через божественную магию обернулись полным молчанием. Те, кто пытался это сделать, испытывали странное ощущение, как будто их разум колеблется на краю огромной пропасти, охватывающей время, пространство и память.`,
    source: {
      id: SOURCE_MTOF,
      page: 88,
    },
  },
  {
    header: 'Рождённые служить',
    text: `С рождения гитьянок готовят сражаться и умирать за свою королеву. Детей жестоко воспитывают, постоянно внушая преданность Влаакит. Каждое из укреплённых поселений, где воспитывают и обучают молодых гитьянки, представляет собой смесь военной академии и штаб-квартиры культа.`,
    source: {
      id: SOURCE_MTOF,
      page: 88,
    },
  },
  {
    header: 'Выживают только лучшие',
    text: `Гитьянки растят свой молодняк в скрытых яслях, которые они строят в отдалённых местах на Материальном плане. Такие меры необходимы, поскольку рождение и взросление невозможно на Астральном плане, обитатели которого не стареют. Взрослые надзиратели тут тренируют молодых гитьянок пользоваться своими психическими и физическими способностями.

Гитьянки вылупляются из яиц. Каждый новорождённый приходит в мир вместе с другими яйцами, преднамеренно отложенными так, чтобы вылупиться в одно и то же время. Поскольку взрослые гитьянки должны вернуться на Астральный План, чтоб сильно не постареть, состав инструкторов постоянно меняется, причём ни один взрослый не остаётся тут дольше, чем на несколько месяцев, и ни один не возвращается на второй срок.

Обучение, которое проходят молодые гитьянки жестоко и сурово. По мере взросления требования с каждого ученика становятся всё больше, а наказания за отставание — всё строже. На ранних этапах обучения тренировочные бои ведутся до первой раны. Позже, ближе к окончанию тренировок, тренировки такого рода могут включать бои на смерть как окончательный способ отсеять тех, кто не соответствует высоким стандартам Влаакит. Для гитьянок лучше, чтобы слабаки умирали на тренировках, чем проваливали задания и подвергали опасности отряд.`,
    source: {
      id: SOURCE_MTOF,
      page: 88,
    },
  },
  {
    header: 'Последнее испытание верности',
    text: `К тому времени, как группа гитьянок достигает совершеннолетия, они годами слушали рассказы про Влаакит и её бессмертных воинов, обитающих в серебристой пустоте. Молодым говорят, что они на грани вступления во владения королевы и каждому из них уготована судьба занять особое место в обществе. Их навыки доказывают, что они достойны, и теперь предстоит определить их преданность Почитаемой Королеве.

В последнем испытании группа гитьянок, вступающих во взрослую жизнь, должна убить [свежевателя разума](CREATURE:${CREATURE_MIND_FLAYER}) в качестве священного ритуала прохода, прежде чем им разрешат присоединиться к народу, живущему на Астральном Плане. Когда победители впервые входят в Ту’нарат, они несут охотничьи трофеи непосредственно Влаакит. Она принимает подарок и начинает ритуальное песнопение, которое знаменует приём молодняка в общество гитьянок.`,
    source: {
      id: SOURCE_MTOF,
      page: 89,
    },
  },
  {
    header: 'Дилемма Влаакит',
    text: `Давным-давно прошли дни, когда раса гитов была полностью вовлечена в конфликт. Когда гитьянки основали Ту’нарат и поселились на Астральном Плане, им больше не пришлось постоянно бороться за выживание, и в этом смысле жизнь всех гитьянок стала легче.

Миссия, изложенная Влаакит в Великой Декларации, остаётся крайне важной. Её правление абсолютно отчасти из-за того, что она не допускает конкуренции или других точек зрения. Её режим вне опасности, однако попавшим в Ту’нарат посторонним может показаться, что место в упадке.

Действительно, в каком-то смысле гитьянки — жертвы собственного успеха. После веков прибыльных рейдов, проведённых по всей мультивселенной, народ Ту’нарата стал избалованным и упадочным. Влаакит всё ещё может призвать народ к действию, и, если она это делает, они охотно подчиняются. Но если их чем-нибудь не занять, многие жители города будут тратить время на потворствование своим прихотям.

При всей кажущейся неуязвимости, Влаакит чувствует себя в ужасной ситуации, которая — в её-то параноидальном мозгу — не имеет решения. Если они заставляет своих людей чаще участвовать в рейдах, она рискует, что её лучшие воины и мародёры станут достаточно опытными и могущественными, чтоб бросить вызов её правлению. К тому же, если она отправит слишком много рейдовых групп, безопасность Ту’нарата может оказаться под угрозой. Поэтому она решает проблему, не занимаясь ею напрямую, а пытаясь подтолкнуть своих праздных последователей к нахождению смысла в полезных занятиях, которые не включают в себя грабежи и убийства. В этих начинаниях она не всегда преуспевает.`,
    source: {
      id: SOURCE_MTOF,
      page: 89,
    },
  },
  {
    header: 'Безжалостные мародёры',
    text: `Когда Влаакит отдаёт приказ о подготовке очередного рейда, Ту’нарат оживает в предвкушении. Рыцари и другие солдаты, выбранные для участия в миссии, считают это высочайшей привилегией. Во славу Влаакит все рейдеры стараются максимально жестоко обойтись с целью — они убивают без разбора, берут понравившиеся сокровища и оставляют за собой только разрушение.

Когда судно гитьянок возвращается с рейда, оно под завязку загружено трофеями. Влаакит не ставит специфических требований, оставляя каждому отдельному рейдеру свободу в выборе того, с чем они вернутся. Одни ищут экзотические специи и травы, другие мародёрствуют в поисках свитков и книг. В результате Ту’нарат заполнен (скорее завален) почти бесконечным количеством вещей размером от громадных зданий до мельчайших образцов изысканных ювелирных украшений, которые гитьянки награбили на других планах.`,
    source: {
      id: SOURCE_MTOF,
      page: 89,
    },
  },
  {
    header: 'Праздные дилетанты',
    text: `Как раса, выведенная [свежевателями разума](CREATURE:${CREATURE_MIND_FLAYER}) для боя, гитьянки, пока были порабощены, не знали ничего другого. Теперь они не воюют постоянно, и держать народ чем-то занятым, возможно, самая большая проблема, с которой сталкивается Влаакит.

Когда гитьянки не участвуют в рейдах или других миссиях во имя Влаакит, они наслаждаются апатичной жизнью в Ту’нарате. В астрале время не идёт, поэтому гитьянкам не нужно трудиться, чтобы получить воду и пищу. Чтоб они не отупели, Влаакит приказывает им заниматься различными искусствами и науками. Она регулярно организует соревнования, игры, связанные с поиском предметов, и другие испытания, чтоб занять слуг целенаправленной деятельностью, однако привлекательность таких развлечений быстро спадает через некоторое время. Большинство жителей Ту’нарата, когда они не призваны в рейде или на другую миссию, потакают себе во всём, в чём хотят.

Гитьянки, располагающие бесконечным количеством времени, жаждут новизны. Они ожидают, что каждый вернувшийся рейд даст им новые виды развлечений. Эта озабоченность новизной — краеугольный камень культуры гитьянок. Они поверхностно занимаются искусствами, но никогда не достигают в них мастерства. Они стоят среди сокровищ бесчисленных миров, но никогда истинно не восхищаются ими. Гитьянки переходят от темы к теме, от ремесла к ремеслу, никогда подолгу не останавливаясь на чём-то одном. Ту’нарат усеян полувысеченными скульптурами, недописанными фресками и прочими видами незаконченных произведений. Гитьянки просто бросают свои задумки, когда те им наскучат, и все их начинания заканчивались именно так.`,
    source: {
      id: SOURCE_MTOF,
      page: 90,
    },
  },
  {
    header: 'Держать клинки острыми',
    text: `Несмотря на декадентский образ жизни, гитьянки тренируются, чтобы оставаться в форме. Все должны присутствовать на тренировках с оружием и на боевых учениях, которые служат краткой передышкой от повсеместной скуки. Влаакит, естественно, находится на вершине воинской иерархии гитьянок. За ней идут [верховные главнокомандующие](CREATURE:${CREATURE_GITHYANKI_SUPREME_COMMANDER}), каждый из которых контролирует полк из тысячи [воинов](CREATURE:${CREATURE_GITHYANKI_WARRIOR}). Десять _[кит’рак](CREATURE:${CREATURE_GITHYANKI_KITHRAK})_, каждый из которых отвечает за сотню [воинов](CREATURE:${CREATURE_GITHYANKI_WARRIOR}), отвечают перед [главнокомандующим](CREATURE:${CREATURE_GITHYANKI_SUPREME_COMMANDER}). Каждый [кит’рак](CREATURE:${CREATURE_GITHYANKI_KITHRAK}) командует десятью _сартами_, каждый из которых ведёт группу из десяти [воинов](CREATURE:${CREATURE_GITHYANKI_WARRIOR}). Военачальники гитьянок поддерживают эту структуру и в мирное время, присматривая за подчинёнными и поддерживая дисциплину и боевую подготовку.`,
    source: {
      id: SOURCE_MTOF,
      page: 90,
    },
  },
  {
    header: 'Рыцари: особые войска',
    text: `[Рыцари гитьянок](CREATURE:${CREATURE_GITHYANKI_KNIGHT}) — это воины, заклинатели и разведчики исключительных способностей, самозабвенно служащие Влаакит. Рыцари подчиняются непосредственно королеве и не входят в воинскую иерархию. Личный состав каждой важной миссии включает в себя по крайней мере одного рыцаря, а каждая крепость или укреплённый форпост гитьянок в мультивселенной управляется как минимум одним рыцарем, проживающим там. Рыцарей выбирают, опираясь на их боевой и псионический потенциал; молодых гитьянок, которые подходят к этим требованиям, посвящают в служение вскоре после того, как они впервые входят в Ту’нарат.

Рыцари всегда участвуют в принятии важных решений, также недопустимо хранить от них секреты. Они выступают в роли комиссаров и исполнителей воли Влаакит. Грубо говоря, они эквивалент религиозных деятелей в культуре гитьянок, хотя [священников](CREATURE:${CREATURE_PRIEST}) или [жрецов](CREATURE:${PC_CLASS_CLERIC}) в обычном смысле у гитьянок нет вообще.

Два аспекта отделяют рыцарей от других гитьянки. У каждого рыцаря есть серебряный двуручный меч, который тот бережно хранит и который даёт своему владельцу особую силу. Помимо этого, рыцари — одни из немногих гитьянки, которые могут не только путешествовать между планами, но и брать с собой союзников. Часто [рыцари](CREATURE:${CREATURE_GITHYANKI_KNIGHT}) приходят из своих странствий между планами верхом на красных драконах, которые были союзниками гитьянки с самого момента освобождения последних от гнёта [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}).`,
    source: {
      id: SOURCE_MTOF,
      page: 90,
    },
  },
  {
    header: 'Серебряные мечи',
    text: `Первые серебряные мечи создали много веков назад, ещё когда гиты были единой расой, те, кто в будущем стал первыми рыцарями гитьянки. Серебряный меч, который работает как двуручный меч +3, является каналом, через который его владелец может сокрушить врага одновременно и физически, и психически. Это оружие чрезвычайно эффективно на Астральном Плане против всех путешественников, соединённых со своими телами серебряной нитью — после удара по таким врагам есть шанс, что нить оборвётся, после чего враг немедленно умрёт.

[Рыцари](CREATURE:${CREATURE_GITHYANKI_KNIGHT}) и их серебряные мечи неразделимы, и каждый из них будет сражаться до смерти, лишь бы избежать потери оружия. Если серебряный меч попадает в руки кого-то, кроме гитьянки, Влаакит отправит отряд рыцарей из Ту’нарата, чтобы убить злоумышленника и вернуть оружие.`,
    source: {
      id: SOURCE_MTOF,
      page: 90,
    },
  },
  {
    header: 'Боевые трофеи',
    text: `Личные оружие и доспехи гитьянок богато украшены трофеями с прошлых рейдов. После каждой новой победы воин приносит домой сувенир на память. Что угодно может стать объектом капризов гитьянок, от драгоценного камня из навершия меча поверженного противника до красочных знамён, взятых из разграбленного замка и сохраняющих свои первоначально яркие оттенки на протяжении веков благодаря вневременной природе Астрального плана. Чем более причудливы и пафосны трофеи с рейда, тем более вероятно, что ими будут восхищаться другие воины.`,
    source: {
      id: SOURCE_MTOF,
      page: 90,
    },
  },
  {
    header: 'Драконы под седлом',
    text: `Отношения между гитьянки и красными драконами по большей части не изменились с древних времён. Согласно договору с [Тиамат](GOD:${GOD_TIAMAT}), небольшая группа драконов служит охранниками и ездовыми животными рыцарям и другим высокопоставленным гитьянки. Драконы держатся в стороне от политики гитьянки. Они подчиняются приказам своих наездников и делают всё, что от них требуют, не давая советов и не выражая своего мнения.

Красные драконы обычно служат гитьянкам, пока молоды. Как только дракон достигает взрослого возраста, его освобождают от обязанностей и заменяют на более молодого дракона; взрослый дракон уходит с сокровищами, которые добыл в рейдах.

Поскольку драконы не стареют, находясь на Астральном Плане, они не растут в силе и размере. Посему, чтобы стать сильнее и богаче, драконы предпочитают как можно больше времени проводить в рейдах на Материальный план или другие миры. Наилучшая служба для дракона — охрана яслей гитьянки на Материальном плане, так как это назначение может длиться годами, а находящийся на службе дракон не только получает за службу сокровища, но и стареет, в итоге достигая взрослого возраста быстрее, чем драконы, живущие в Ту’нарате.

Драконы, которые обязаны служить гитьянки, относятся к своему назначению с раздражением, но без враждебности. Они возмущены своими хозяевами, но обещание добычи пробуждает драконов желание участвовать в рейдах. В рамках договора с [Тиамат](GOD:${GOD_TIAMAT}) гитьянки запрещено использовать псионику или магию, чтобы заставлять драконов что-либо делать или читать их мысли. Дракон остаётся верен своим союзникам до тех пор, пока его всадники и прислуга относятся к нему с уважением и дают множество возможностей для грабежа.`,
    source: {
      id: SOURCE_MTOF,
      page: 91,
    },
  },
  {
    header: 'Ужас с небес',
    text: `Одним из величайших открытий, совершённых гитьянки во время войны между со [свежевателями разума](CREATURE:${CREATURE_MIND_FLAYER}), была магия, которую [свежеватели разума](CREATURE:${CREATURE_MIND_FLAYER}) использовали для создания и управления летающими суднами, которые иллитиды использовали для путешествий между мирами.

Теперь гитьянки исследуют миры Материального плана на своих версиях этих астральных кораблей, идеально подходящих для перевозки войск и трофеев, добытых в рейдах. Магия прорицания их волшебников находит великие сокровища, верховные лидеры под руководством Влаакит организуют рейдерские группы и отправляют их вернуться с трофеями.

Гитьянки нападают под покровом ночи, мгновенно получая превосходство над противником, в виду того, что мало какие поселения хоть как-то защищены от атак с воздуха. [Рыцари](CREATURE:${CREATURE_GITHYANKI_KNIGHT}) верхом на красных драконах сопровождают корабли и расчищают дорогу воинам гитьянки.

В бою гитьянки всё время двигаются, не прекращая использовать псионику и магию чтобы уничтожать своих врагов. Они обрушивают на противников свои удары, сжигают здания дотла и убивают всех на своём пути, сея панику в рядах своих жертв и лишая защитников любой надежды.

Во многом из-за того, что цель гитьянки — грабёж, а не завоевание, рейдеры задерживаются у цели не более нескольких часов. На рассвете они исчезают, специально оставляя достаточно выживших, чтобы восстановить разрушенное поселение, тогда гитьянки смогут вернуться через годы или десятилетия и снова его разграбить.`,
    source: {
      id: SOURCE_MTOF,
      page: 91,
    },
  },
  {
    header: 'Руль',
    text: `Чтоб перемещаться по небу и путешествовать между планами, каждый корабль гитьянок оснащён рулём, волшебным аппаратом в форме трона, который преобразует психическую энергию в движущую силу.

Обычно за рулём находится гиш, гитьянки, который выделяется и как воин, и как волшебник. Гиш сочетает свои способности как для пилотирования корабля, так и для участия в битве, которая ожидает корабль в конце полёта. Остальная команда корабля состоит из воинов, которые обслуживают корабельные орудия и несут вахту.`,
    source: {
      id: SOURCE_MTOF,
      page: 91,
    },
  },
  {
    header: 'Астральный ялик',
    text: `Для управление яликом, который вмещает до двенадцати пассажиров, нужно три члена экипажа.

Гитьянки используют эти маленькие судна шириной в 10 футов и 30 футов в длину, чтобы патрулировать Астральный План и совершать быстрые рейды за определёнными объектами на Материальный план.

Максимальная скорость, которую развивает ялик, составляет 15 узлов. На нём нет оружия, кроме того, что есть в наличии у пассажиров, и мало места для грузов.`,
    source: {
      id: SOURCE_MTOF,
      page: 92,
    },
  },
  {
    header: 'Астральный бриг',
    text: `Астральный бриг — это стандартное военное судно гитьянки, развивающее скорость до 12 узлов.

Он вмещает до шестидесяти пассажиров и управляется пятью членами экипажа. Длина брига равна 90 футам, ширина — 30, на двух уровнях ниже палубы расположены каюты и грузовой отсек.
 
На корабле установлены две баллисты, для управления каждой из которых требуется два члена экипажа.`,
    source: {
      id: SOURCE_MTOF,
      page: 92,
    },
  },
  {
    header: 'Межпланарный рейдер',
    text: `Самый крупный из кораблей гитов, межпланарный рейдер служит передвижным штабом во время крупных атак на врагов гитьянки.

Кораблю необходимо десять членов экипажа, он способен вместить более сотни пассажиров. Рейдер развивает скорость вплоть до 12 узлов, его ширина достигает 40 футов, а длина — 120, под палубой есть два уровня, также корабль оснащён тремя баллистами и катапультой.`,
    source: {
      id: SOURCE_MTOF,
      page: 92,
    },
  },
  {
    header: 'Ту’нарат',
    text: `Когда гитьянки сбежали от [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), Влаакит привела их в безопасное место на Астральном Плане, расположенное в парящем трупе шестирукого бога. Тело этого существа давно превратилось в большую скалу, его нижняя половина разрушена какой-то древней катастрофой, а след из обломков, часть которых больше, чем замок, простирается от нижнего края трупа.

Город Ту’нарат построен внутри и снаружи торса трупа, его центральный район расположен в области, соответствующей его груди, а остальные районы расходятся в стороны вдоль его шести вытянутых рук и к его голове. Несмотря на частичное разрушение тела, случайные судороги, которые проходят сквозь эту скалистую массу, даёт основания полагать, что глубоко внутри всё ещё теплится искра жизни.

Любой, кто посещает город, делает это либо по приказу гитьянки, либо скрытно. К счастью для тех, кто пытается пробраться в город тайно, это место достаточно обширное, чтобы небольшая группа смогла проникнуть относительно легко.

Если незваный гость решает появиться в открытую, ему стоит ожидать холодный приём со стороны патрульных судов гитьянки. Если их будет недостаточно, на подмогу приходит отряд рыцарей верхом на драконах.`,
    source: {
      id: SOURCE_MTOF,
      page: 92,
    },
  },
  {
    header: 'Вкратце о Ту’нарате',
    text: `Ниже представлены ключевые факты о Ту’нарате.

# Население

Население города равняется приблизительно ста тысячам. Абсолютное большинство из них составляют гитьянки, но гости с других планов здесь обычное явление. Помимо них в число жителей входят те, кто пришёл к гитьянки по делу, и те, кого гитьянки пленили в рейдах.

# Закон и порядок

Воины патрулируют над улицами на астральных яликах, чтобы поддерживать порядок. Гитьянки, которые устраивают беспричинный конфликт, подвергаются наказанию, но оно редко бывает смертельным. Однако любой гость, который будет создавать беспорядки, скорее всего, будет убит на месте, если только Влаакит специально не запретила подобное.

# Гостиницы

В Ту’нарате нет гостиниц или таверн в обычном смысле слова. Гитьянки ожидают от гостей, что те сами найдут себе жильё; гости могут выбрать любую пустую постройку, которую найдут. Как вариант, можно арендовать комнату в рушащейся цитадели, называемой Железным Домом, которую поддерживает в надлежащем состоянии маленькая группа модронов-ренегатов. Посетители могут расплатиться интересными безделушками с других планов.

# Рынки

В Ту’нарате отсутствуют организованные рынки. Гитьянки не предлагают товаров на продажу гостям и не платят за вещи, которые им предлагают, — они просто берут, что хотят.`,
    source: {
      id: SOURCE_MTOF,
      page: 93,
    },
  },
  {
    header: 'Улицы Ту’нарата',
    text: `Ту’нарат представляет собой беспорядочное пересечение кривых улиц, которые проходят между зданиями и другими постройками, вырванными из миров других планов. У многих налётчиков гитьянок есть особая одержимость архитектурой, которую они удовлетворяют, захватывая здания из Материального плана и других мест и перемещая их в Ту’нарат.

Довольно редко эти здания остаются неизменными. Когда живущим в них становится скучно, или они оказываются в настроении для кутежа, природная склонность гитьянки к насилию проявляется в виде большой драки или дикого праздника, который наносит серьёзный ущерб всему вокруг них. Когда здание перестаёт служить своей цели и становится бесполезным (или даже неузнаваемым), гитьянки разрушают его до основания и бросают в кучу мусора или отправляют плавать в Астральное Море, чтобы в конечном итоге заменить его новым.

Тем не менее, в городе очень много постоянных зданий, и есть система районов, каждый из которых сосредоточен на конкретной функции или деятельности.

# Королевский район

Сусуррус, твердыня королевы, защищена толстыми обсидиановыми стенами. Внутрь крепости ведут только одни ворота, расположенные пот статуей Гит. Внутри прямой путь превращается в лабиринт, созданный, чтобы не пропустить врагов или незваных гостей к королеве. Тронный зал Влаакит, представляющий собой гигантское помещение, поддерживаемое обсидиановыми колоннами, расположен в центре лабиринта. Охраняемая двумя красными драконами, Влаакит восседает на Костяном Троне и принимает просителей.

# Район Глатк

Илистое поле, которое простирается настолько далеко, насколько может видеть глаз, — аналог исправительно-трудового лагеря гитьянки. Район Глатк, названный в честь гитского слова «фермер», своего рода насмешки, — это место, куда отправляются гитьянки, нарушившие правила общества. Наказания здесь несмертельны и часто даже не сводятся к телесным. Вместо этого, преступников заставляют выполнять одну и ту же работу, со временем доводящую до отупения своей монотонностью, и эта судьба для многих кажется худшей, чем смерть. Например, воин может быть отправлен сюда после того, как он расслабился во тренировки, после чего был приговорён к тюремному заключению и отправлен перетаскивать груды грязи с одного конца поля на другой. Или же отряд воинов могут заставить неопределённое время находиться в боевой готовности, если на те не смогли удержать строй на учениях.

Заключённые здесь редко находятся под пристальным наблюдением, но [рыцари](CREATURE:${CREATURE_GITHYANKI_KNIGHT}) регулярно патрулируют район.

# Район отбросов

Гитьянки избавляются от вещей, которые им больше не нужны, оставляя их на внешней поверхности Ту’нарата, и этими вещами может быть что угодно, начиная от трофеев и сокровищ и заканчивая пленниками, которых освобождают и оставляют на произвол судьбы. Некоторые гитьянки, которые живут здесь, сохраняют подобие порядка среди мусора, разделяя выброшенные предметы, что облегчает другим гитьянки поиск интересующих их вещей.

Существа, которые хотят проникнуть в Ту’нарат, с большой вероятностью смогут на первое время обосноваться здесь, поскольку воины и [рыцари](CREATURE:${CREATURE_GITHYANKI_KNIGHT}) патрулируют этот район крайне редко. Несложно будет и затесаться среди групп освобождённых пленников — людей, эльфов, хобгоблинов и прочих, сделавших это место своим домом.

# Военные районы

Солдаты и офицеры занимают несколько районов вокруг города, в которых чаще всего есть бронники и оружейники. Гитьянки также строят там казармы, служащие пунктами сбора перед грядущими рейдами. Все гитьянки обязаны проходить регулярные воинские тренировки в одном из таких районов. Не-гитьянки, которые рискнут сюда войти, будут атакованы, как только их увидят, если только с ними нет рыцаря, который может за них поручиться.

# Район Млар

Эта область отведена под район ремесленников. Гитьянки, в свободное время занимающиеся искусством или ремеслом, собираются здесь, чтобы поделиться своими навыками и показать свои творения. Чужаки могут передвигаться по району, если выглядят соответствующим образом: гитьянки приводят захваченных ремесленников сюда, чтобы учиться чему-то новому у них или даже брать некоторых из них под своё крыло. Но, в то же время, за этими ремесленниками особо не следят. В конце концов, шанс сбежать из города у них крайне мал.

# Верфи

Гитьянки хранят и ремонтируют свои суда в доках, примыкающих к военным районам. Назначенные на техническое обслуживание кораблей во всю могут наслаждаться своим высоким статусом в обществе и возможностью считать свою работу частью воинской службы. Воины гитьянки при необходимости исполняют роль грузчиков.

# Драконьи пещеры

В разрушенной нижней части туловища мёртвого бога есть природные пещеры и трещины в огромном количестве. У каждого дракона на службе гитьянки есть своё логово глубоко внутри этого региона, в котором они бережно хранят свои сокровища до тех пор, пока их вновь не вызовут на службу.

Ещё никому не удалось составить карту этих странных проходов и туннелей, чему во многом способствовало огромное количество падальщиков, астральных хищников и других существ, живущих здесь ещё с тех пор, когда гитьянки даже не знали об этом месте. Жители Ту’нарата обычно не появляются здесь без нужды, за исключением тех, кто служит драконам. Впрочем, иногда гитьянки, желающие испытать новые ощущения, то и дело заходят вглубь этих туннелей, чтобы разбавить свою скуку. Можно предположить, что те, кто не вернулся оттуда, нашли, что хотели.

Ходят слухи об огромном подземелье, которое находится среди пещер, скрытой крепости, занятой могучим полубогом, утверждающим, что Парящий в Пустоте был его домом до того, как тут обосновались гитьянки. Несколько лет назад группа Красных Волшебников из Тэя, который находится на Фаэруне, посетила эти места в сопровождении рыцарей гитьянки. Говорят, что тэйцы вышли с огромным адамантовым контейнером, который трясся и содрогался как будто в такт ударам гигантского сердца.`,
    source: {
      id: SOURCE_MTOF,
      page: 93,
    },
  },
  {
    header: 'Общины выживших',
    text: `Как и положено в обществе завоевателей, гитьянки не считаются с жертвами их набегов. Они забирают жизни, чтобы утвердить своё господство, а не из-за гнева или потому что чувствуют угрозу. Время от времени, вместо того, чтобы убивать всех, с кем они сталкиваются во время рейда, гитьянки забирают пленников в Ту’нарат по тем или иным причинам.

Гитьянки относятся к пленникам с теми же отчуждением и презрением, с какими относятся к тем, кого убивают. Когда пленники перестают быть полезными, их хозяева могут как убить их, так и просто забыть о них, оставив на произвол судьбы.

Самые выносливые и неуловимые из этих них пробираются в район Отбросов, где могут скрыться от угроз, с которыми сталкиваются в других частях города, и теперь живут там в относительной безвестности. Гитьянки нет дела до того, что творится в этих сообществах, до тех пор, пока беспорядки в них становится достаточно серьёзными, чтобы привлечь внимание. Потенциальные лазутчики, желающие пробраться в Ту’нарат, могут скрытно пробраться в район и смешаться с местными.`,
    source: {
      id: SOURCE_MTOF,
      page: 94,
    },
  },
]
