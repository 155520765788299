const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CREATURE_FLAMEWRATH} = require('./../../../../creatureIdList')
const {CR_6} = require('./../../../../crList')
const {DAMAGE_FIRE} = require('./../../../../damageTypeList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {eternalFlameDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  SPELL_BURNING_HANDS,
  SPELL_COLOR_SPRAY,
  SPELL_CONTROL_FLAMES,
  SPELL_FIREBALL,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FRIENDS,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_LIGHT,
  SPELL_MAGE_ARMOR,
  SPELL_MINOR_ILLUSION,
  SPELL_SCORCHING_RAY,
  SPELL_SUGGESTION,
} = require('./../../../../spellIdList')
const {
  LANG_COMMON,
  LANG_IGNAN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Яростное пламя',
  nameEn: 'Flamewrath',
  id: CREATURE_FLAMEWRATH,
  description: [
    {
      header: 'Яростное пламя',
      text: `Яростным пламенем называют заклинателя, заслужившего расположение Имикса, Принца Стихийного Огня через участие в мучительных ритуалах. Кожа таких заклинателей обожжена и покрыта рубцами; они привыкли к боли, и в бою получают истинное наслаждение, когда сжигают заживо тех, кто усомнился в могуществе стихии огня. Бойцы ближнего боя, подошедшие слишком близко, обнаружат, что огонь, танцующий на коже яростного пламени, легко перескакивает на них.`,
      source: {
        id: SOURCE_PotA,
        page: 206,
      },
    },
    eternalFlameDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_PotA,
    page: 206,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  languageList: [
    LANG_COMMON,
    LANG_IGNAN,
  ],
  cr: CR_6,
  featureList: [
    {
      name: 'Объятый пламенем',
      description: `Длительность заклинания [Огненный щит](SPELL:${SPELL_FIRE_SHIELD}), произнесённого ★СУЩЕСТВО★, меняется со стандартной на «пока не рассеется». После смерти ★СУЩЕСТВО★ [Огненный щит](SPELL:${SPELL_FIRE_SHIELD}) будет держаться на ★нём★ ещё 10 минут, постепенно сжигая ★его★ тело.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 7,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_BURNING_HANDS,
      SPELL_COLOR_SPRAY,
      SPELL_CONTROL_FLAMES,
      SPELL_FIREBALL,
      SPELL_FIRE_BOLT,
      SPELL_FIRE_SHIELD,
      SPELL_FRIENDS,
      SPELL_HYPNOTIC_PATTERN,
      SPELL_LIGHT,
      SPELL_MAGE_ARMOR,
      SPELL_MINOR_ILLUSION,
      SPELL_SCORCHING_RAY,
      SPELL_SUGGESTION,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
  genderId: GENDER_MIDDLE,
}
