const {SOURCE_VGTM} = require('./../../sourceList')
const {CREATURE_TYPE_TRITON} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_TRITON,
  nameEn: 'Triton',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'тритон',
        genitive: 'тритона',
        dative: 'тритону',
        accusative: 'тритона',
        instrumental: 'тритоном',
        prepositional: 'тритоне',
      },
      [GENDER_FEMALE]: {
        nominative: 'тритонша',
        genitive: 'тритонши',
        dative: 'тритонше',
        accusative: 'тритоншу',
        instrumental: 'тритоншой',
        prepositional: 'тритонше',
      },
    },
    plural: {
      nominative: 'тритоны',
      genitive: 'тритонов',
      dative: 'тритонам',
      accusative: 'тритонов',
      instrumental: 'тритонами',
      prepositional: 'тритонах',
    },
  },
  note: {
    text: `Ах, тритоны. Представьте себе эльфов, которые несколько столетий прожили глубоко на дне моря, где ничто не мешало расти их высокомерию и самомнению. Но по крайней мере, тритоны провели это время, сражаясь с сахуагинами и с кем похуже, так что на них можно положиться в бою.`,
    author: 'Брего Стоунхарт, капитан корабля',
  },
  description: [
    {
      header: 'Тритоны',
      text: `Тритоны охраняют океанские глубины, строя маленькие поселения около глубоководных впадин, порталов в стихийные планы и других опасных мест, далеких от глаз народов суши. Признанные защитники океанских глубин, в последние годы благородные тритоны стали всё более и более активными в мире на поверхности.`,
      source: {
        id: SOURCE_VGTM,
        page: 117,
      },
    },
    {
      header: 'Водные крестоносцы',
      text: `Столетия назад, тритоны пришли в мир в ответ на растущую угрозу стихийного зла. Тритоны вели много войн против своих врагов в Плане Воды, гоня их в Затемнённые Глубины, куда они сбегали в высокое давление и кромешную тьму. Со временем, тритоны заметили, что их древние стихийные противники затихли. Экспедиции в глубины показали, что кракены, сахуагины и намного более худшие враги сбежали с Плана Воды на Материальный план.

Тритоны, ведомые чувством долга и ответственности, не позволили своим противникам убежать так легко. Великий конклав тритонов собрал добровольцев, владеющих оружием и магией, в экспедиционный корпус, чтобы они пришли в Материальный план и нашли своих врагов.

Эти тритоны распространились по океанам и основали протектораты, следящие за глубокими впадинами, порталами, подводными пещерами, и другими местами, где могут скрываться их враги. Они победили своих противников, которых нашли, а остальных заставили попрятаться, и теперь очень гордятся тем, в каком долгу у них все оказались.

Изгнав врагов в самые темные глубины моря, тритоны осели, следя за малейшими знаками их возвращения. Со временем тритоны расширили свое наблюдение за морским дном за пределы окрестностей своих первоначальных поселений и построили аванпосты для торговли с другими расами. Несмотря на это распространение, о них знают немногие. Их поселения так отдалены, что даже мерфолки и морские эльфы редко встречают их.`,
      source: {
        id: SOURCE_VGTM,
        page: 117,
      },
    },
    {
      header: 'Надменная знать',
      text: `Как результат своей изоляции и недопонимания Материального плана, тритоны производят впечатление надменных и высокомерных. Они рассматривают себя как хранители морей, и ждут, что другие существа будут относится к ним если не с глубоким почтением, то хотя бы с уважением.

Хотя подобное отношение и возмущает других, но в нем есть доля истины. Мало кто знает о великих победах тритонов над ужасными подводными угрозами. Тритоны снисходительны к такому невежеству и с радостью поясняют на сколько все перед ними в неоплатном долгу.

У тритонов также есть обыкновение появляться из своей изоляции с мыслью, что другие народы их поприветствуют как уважаемых союзников и наставников. Это опять же вызвано расстояниями. Ограниченное знание тритонов о мире оставляет их в неведении о королевствах, войнах и прочих трудностях мира на поверхности. Тритоны с готовностью рассматривают все это как мелочи, происходящие всего лишь на заднем плане по сравнению с ролью тритонов, как защитников мира.`,
      source: {
        id: SOURCE_VGTM,
        page: 118,
      },
    },
    {
      header: 'Непоколебимые борцы',
      text: `Несмотря на свои отталкивающие манеры, в глубине души тритоны — доброжелательные существа, убежденные, что другие цивилизованные расы заслуживают их защиты. Их отношение может злить, но когда пиратские флоты рыскают по волнам, или пробуждается кракен, они одни из первых берутся за оружие, чтобы защитить других.

Тритоны с готовностью жертвуют собой ради общего блага. Они без сомнений будут сражаться и умирать за людей, мерфолков и других существ. Из–за своей замкнутости они не замечают историю других народов, но их терзает чувство вины за то, что они позволили злу из Плана Воды прийти на Материальный план и угрожать его жителям. Тритоны полагают, что это их долг чести, и они будут сражаться и умирать, чтобы оплатить его.

Время от времени их рвение и незнание мира вводят их в заблуждение. Тритоны, впервые сталкиваясь с другими существами, могут их недооценить, что делает тритонов уязвимыми для обмана. Имея сильные военные традиции, тритоны могут иногда слишком поспешно ввязываться в схватку.`,
      source: {
        id: SOURCE_VGTM,
        page: 118,
      },
    },
    {
      header: 'Чужаки на поверхности',
      text: `Учитывая их изоляцию, большинство тритонов никогда не было на поверхности. Для них трудна мысль, что они не могут так же легко двигаться вверх и вниз, как в воде, а смена сезонов сбивает их с толку.

Тритоны также считают разнообразные социальные институты, королевства и прочие обычаи изумительным. Несмотря на всю свою гордую культуру, они становятся наивными в сухопутном мире. Типичный протекторат тритонов прочно систематизирован, организован и объединен вокруг общего дела. Тритона на поверхности легко сбивают с толку множество союзов, противостояний и мелких обид, которые мешают поверхностным народам объединиться.

В худшем случае высокомерие может мешать тритонам пытаться понять, каким образом устроен мир поверхности. Для тритона легко списать непонятные социальные нормы на варварство, слабость, или трусость поверхностных народов.`,
      source: {
        id: SOURCE_VGTM,
        page: 118,
      },
    },
    {
      header: 'Индивидуальность тритонов',
      text: `Далекие от безупречности, эти заступники добра желают его окружающим, но другие их легко разочаровывают. Вы можете выбрать самостоятельно, определить броском кубика или переделать причуду из таблицы «Причуд Тритона». Используйте причуду как идею относительно того, как Вы будете изображать своего персонажа.

# Причуды тритонов

| к6 | Причуда |
|----|---------|
| 1 | Вы формулируете просьбы как приказы, которым Вы ожидаете, что другие будут подчиняться. |
| 2 | Вы энергично восхваляете величие Вашей цивилизации. |
| 3 | Вы изучали древний вариант Общего, и используете в Вашей речи «Азъ», «есьм», «доколе» и т.д. |
| 4 | Вы предполагаете, что люди говорят Вам правду о местных нравах и обычаях. |
| 5 | Поверхностный мир — поразительное место, и Вы записываете все его детали в журнале. |
| 6 | Вы ошибочно полагаете, что народы поверхности знают историю Вашего народа и преклоняются перед нею. |`,
      source: {
        id: SOURCE_VGTM,
        page: 118,
      },
    },
    {
      header: 'Имена тритонов',
      text: `У большинства имен тритонов есть два или три слога. Мужские имена как правило заканчиваются гласным и буквой «—с», а женские имена традиционно заканчиваются на «—н».

Тритоны используют свой домашний протекторат в качестве фамилии, добавляя гласную вместе с «–т» на конце названия протектората.

# Женские имена тритонов

Белтин, Влэрин, Вуолин, Дьютин, Отанин, Филорин, Шелрин, Эрин.

# Мужские имена тритонов

Водос, Делнис, Джимас, Зунис, Керос, Корус, Молос, Налос.

# Фамилии тритонов

Алорсат, Вууваксат, Пуманат.`,
      source: {
        id: SOURCE_VGTM,
        page: 119,
      },
    },
  ],
}
