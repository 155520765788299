const {
  SOURCE_ERFTLW,
  SOURCE_TCoE,
  SOURCE_UA_2017_01_09_ARTIFICER,
  SOURCE_UA_2019_05_14_ARTIFICER_RETURNS,
  SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
} = require('./../../sourceList')
const {CREATURE_STEEL_DEFENDER} = require('./../../creatureIdList')
const {PC_CLASS_ARTIFICER} = require('./../../pcClassIdList')

const {
  PC_SUBCLASS_ARTIFICER_ALCHEMIST,
  PC_SUBCLASS_ARTIFICER_ALCHEMIST_2017_01_09,
  PC_SUBCLASS_ARTIFICER_ALCHEMIST_2019_05_14,
  PC_SUBCLASS_ARTIFICER_ARCHIVIST_2019_05_14,
  PC_SUBCLASS_ARTIFICER_ARMORER,
  PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24,
  PC_SUBCLASS_ARTIFICER_ARTILLERIST,
  PC_SUBCLASS_ARTIFICER_ARTILLERIST_2019_05_14,
  PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
  PC_SUBCLASS_ARTIFICER_BATTLE_SMITH_2019_05_14,
  PC_SUBCLASS_ARTIFICER_GUNSMITH_2017_01_09,
  PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_ARTIFICER_ARCHIVIST_2019_05_14,
    pcClassId: PC_CLASS_ARTIFICER,
    releasedAs: PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES,
    isReady: false,
    name: 'Архивист',
    nameEn: 'Archivist',
    source: {
      id: SOURCE_UA_2019_05_14_ARTIFICER_RETURNS,
      page: 7,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_ALCHEMIST,
    pcClassId: PC_CLASS_ARTIFICER,
    isReady: true,
    name: 'Алхимик',
    nameEn: 'Alchemist',
    description: `Алхимик — эксперт по сочетанию реагентов для создания мистических эффектов.

Алхимики своими творениями как дают жизнь, так и забирают её. Алхимия — это старейшая из изобретательских традиций, и её универсальность издавна ценилась во времена войны и мира.`,
    note: {
      text: `Магия как алхимиков, так и ведьм полагается на могучую мультиверсальную истину: смертные не могут устоять перед пузырьками`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_ERFTLW,
      page: 59,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_ALCHEMIST_2019_05_14,
    pcClassId: PC_CLASS_ARTIFICER,
    releasedAs: PC_SUBCLASS_ARTIFICER_ALCHEMIST,
    isReady: false,
    name: 'Алхимик',
    nameEn: 'Alchemist',
    source: {
      id: SOURCE_UA_2019_05_14_ARTIFICER_RETURNS,
      page: 6,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_ALCHEMIST_2017_01_09,
    pcClassId: PC_CLASS_ARTIFICER,
    releasedAs: PC_SUBCLASS_ARTIFICER_ALCHEMIST,
    isReady: false,
    name: 'Алхимик',
    nameEn: 'Alchemist',
    source: {
      id: SOURCE_UA_2017_01_09_ARTIFICER,
      page: 5,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_GUNSMITH_2017_01_09,
    pcClassId: PC_CLASS_ARTIFICER,
    releasedAs: PC_SUBCLASS_ARTIFICER_ARTILLERIST,
    isReady: false,
    name: 'Оружейник',
    nameEn: 'Gunsmith',
    source: {
      id: SOURCE_UA_2017_01_09_ARTIFICER,
      page: 6,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24,
    pcClassId: PC_CLASS_ARTIFICER,
    releasedAs: PC_SUBCLASS_ARTIFICER_ARMORER,
    isReady: true,
    name: 'Бронник',
    nameEn: 'Armorer',
    description: `Изобретатель, становящийся Бронником, модифицирует свой доспех так, чтобы он практически стал его второй кожей. Такой улучшенный доспех помогает магии изобретателя, усиливает мощь его атак и служит крепкой защитой.

Бронники связаны со своими доспехами, становясь с ними единым целым, даже когда они экспериментируют с ними и улучшают их магические возможности.`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_ARMORER,
    pcClassId: PC_CLASS_ARTIFICER,
    isReady: true,
    name: 'Бронник',
    nameEn: 'Armorer',
    description: `Изобретатель, становящийся Бронником, модифицирует свой доспех так, чтобы он практически стал его второй кожей. Такой улучшенный доспех помогает магии изобретателя, усиливает мощь его атак и служит крепкой защитой.

Бронники связаны со своими доспехами, становясь с ними единым целым, даже когда они экспериментируют с ними и улучшают их магические возможности.`,
    note: {
      text: `Здесь мы наблюдаем типичную логику изобретателей: „А вдруг, когда моё очередное изобретение взорвётся, я буду внутри?“`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 14,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_ARTILLERIST,
    pcClassId: PC_CLASS_ARTIFICER,
    isReady: true,
    name: 'Артиллерист',
    nameEn: 'Artillerist',
    description: `Артиллерист специализируется на использовании магии для метания энергии, снарядов и взрывов на поле боя. Эта разрушительная мощь ценилась всеми армиями во время Последней Войны.

Теперь война окончена и некоторые их них хотят использовать свои способности чтобы построить более спокойный мир, борясь с новыми раздорами в Хорвайре.

Гномка-изобретательница Ви — нежелательный, но ключевой член проекта создания кованных Дома Каннит — особенно громко заявляла о том, как нужно всё исправить: _«Пора нам исправлять вещи, а не отправлять их в пекло»_.`,
    note: [
      {
        text: 'Некоторые изобретатели задают сложные вопросы: „Разве нельзя нанести ещё больше сопутствующего урона?“',
        author: 'Таша',
      },
      {
        text: 'Конечно, ты можешь помочь мне откалибровать механизм прицеливания. Встань вон там.',
        author: 'Гениальный Артиллерист',
      },
    ],
    source: {
      id: SOURCE_ERFTLW,
      page: 60,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_ARTILLERIST_2019_05_14,
    pcClassId: PC_CLASS_ARTIFICER,
    releasedAs: PC_SUBCLASS_ARTIFICER_ARTILLERIST,
    isReady: false,
    name: 'Артиллерист',
    nameEn: 'Artillerist',
    source: {
      id: SOURCE_UA_2019_05_14_ARTIFICER_RETURNS,
      page: 9,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
    pcClassId: PC_CLASS_ARTIFICER,
    isReady: true,
    name: 'Боевой кузнец',
    nameEn: 'Battle Smith',
    description: `Армиям нужна защита и кто-то должен привести всё в порядок, если оборона падает. Сочетающий в себе защитника и медика, боевой кузнец — эксперт в защите других и восстановлении как вещей, так и персонала. Боевых кузнецов обычно сопровождают созданные ими же спутники — [стальные защитники](CREATURE:${CREATURE_STEEL_DEFENDER}), помогающие кузнецам в их работе. Многие солдаты могут рассказать историю о том, как их, уже умирающих, спас боевой кузнец и его [стальной защитник](CREATURE:${CREATURE_STEEL_DEFENDER}).

Боевые кузнецы играли ключевую роль в разработке боевых конструктов Дома Каннит и создании кованых. После Последней Войны эти изобретатели направили свои силы на помощь пострадавшим в страшных битвах.`,
    note: {
      text: `Владея самой властью создавать жизнь, многие боевые кузнецы направляют свой гений на постройку технологически выдающихся котят и щенят.

Возможно, я их недооценила`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_ERFTLW,
      page: 61,
    },
  },
  {
    id: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH_2019_05_14,
    pcClassId: PC_CLASS_ARTIFICER,
    releasedAs: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
    isReady: false,
    name: 'Боевой кузнец',
    nameEn: 'Battle Smith',
    source: {
      id: SOURCE_UA_2019_05_14_ARTIFICER_RETURNS,
      page: 11,
    },
  },
]
