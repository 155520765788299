const {
  SOURCE_EE,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  LANG_AQUAN,
  LANG_AURAN,
  LANG_IGNAN,
  LANG_TERRAN,
} = require('./../../languageIdList')

module.exports = [
  {
    text: `Первичный — гортанный язык, заполненный резкими слогами и твёрдыми согласными.`,
    source: {
      id: SOURCE_EE,
      page: 9,
    },
  },
  {
    text: `Первичный язык включает диалекты [Акван](LANG:${LANG_AQUAN}), [Ауран](LANG:${LANG_AURAN}), [Игнан](LANG:${LANG_IGNAN}), и [Терран](LANG:${LANG_TERRAN}), по одному на каждый из четырёх стихийных планов. Существа, говорящие на разных диалектах одного языка могут общаться между собой.`,
    source: {
      id: SOURCE_PHB,
      page: 124,
    },
  },
  {
    text: `Знание первичного языка позволяет Вам понимать и быть понятыми теми, кто говорит на диалектах этого языка: [Ауране](LANG:${LANG_AURAN}), [Акване](LANG:${LANG_AQUAN}), [Игнане](LANG:${LANG_IGNAN}) и [Терране](LANG:${LANG_TERRAN})`,
    source: {
      id: SOURCE_SCAG,
      page: 138,
    },
  },
]
