const pcClassRawList = [
  require('./pcClass/artificer'),
  require('./pcClass/barbarian'),
  require('./pcClass/bard'),
  require('./pcClass/cleric'),
  require('./pcClass/druid'),
  require('./pcClass/fighter'),
  require('./pcClass/monk'),
  require('./pcClass/paladin'),
  require('./pcClass/ranger'),
  require('./pcClass/rogue'),
  require('./pcClass/sorcerer'),
  require('./pcClass/warlock'),
  require('./pcClass/wizard'),
]

module.exports = pcClassRawList
