const {CREATURE_TYPE_GIANT_FIRE} = require('./../../creatureTypeIdList')
const {fireGiantDescriptionList} = require('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GIANT_FIRE,
  nameEn: 'Fire Giant',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'огненный великан',
        genitive: 'огненного великана',
        dative: 'огненному великану',
        accusative: 'огненного великана',
        instrumental: 'огненным великаном',
        prepositional: 'огненном великане',
      },
      [GENDER_FEMALE]: {
        nominative: 'огненная великанша',
        genitive: 'огненной великанши',
        dative: 'огненной великанше',
        accusative: 'огненную великаншу',
        instrumental: 'огненной великаншей',
        prepositional: 'огненной великанше',
      },
    },
    plural: {
      nominative: 'огненные великаны',
      genitive: 'огненных великанов',
      dative: 'огненным великанам',
      accusative: 'огненных великанов',
      instrumental: 'огненными великанами',
      prepositional: 'огненных великанах',
    },
  },
  description: fireGiantDescriptionList,
}
