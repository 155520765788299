const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_FIRE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_AGANAZZAR_S_SCORCHER} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_AGANAZZAR_S_SCORCHER,
  name: 'Пекло Аганаззара',
  nameEn: 'Aganazzar’s Scorcher',
  description: `Вы выпускаете линию ревущего пламени длиной 30 футов и шириной 5 футов в выбранном направлении. Все существа в области действия должны пройти испытание Ловкости. В случае провала существо получает урон огнём 3к8, а в случае успеха — половину этого урона.`,
  highLvlCast: ` Если Вы сотворяете это заклинание, используя ячейку 3 круга или выше, то урон увеличивается на 1к8 за каждый круг ячейки выше 2.`,
  lvl: 2,
  magicSchoolId: MAGIC_EVOCATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `чешуя красного дракона`,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_FIRE,
      halfOnSuccess: true,
      diceCount: 3,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 160,
    },
    {
      id: SOURCE_EE,
      page: 19,
    },
  ],
}
