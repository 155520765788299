const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_WAR_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {channelDivinityGuidedStrikeTemplate} = require('./../../featureCommonRawList')
const {
  SPELL_CRUSADER_S_MANTLE,
  SPELL_DIVINE_FAVOR,
  SPELL_FLAME_STRIKE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_HOLD_MONSTER,
  SPELL_MAGIC_WEAPON,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_STONESKIN,
} = require('./../../../spellIdList')
const {
  FEATURE_AVATAR_OF_BATTLE,
  FEATURE_BONUS_PROFICIENCIES_WAR_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_GUIDED_STRIKE_WAR_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_WAR_GOD_S_BLESSING,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_STRIKE_WAR_DOMAIN,
  FEATURE_WAR_DOMAIN_SPELLS,
  FEATURE_WAR_PRIEST,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_WAR_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_DIVINE_FAVOR,
      SPELL_SHIELD_OF_FAITH,
      SPELL_SPIRITUAL_WEAPON,
      SPELL_MAGIC_WEAPON,
      SPELL_SPIRIT_GUARDIANS,
      SPELL_CRUSADER_S_MANTLE,
      SPELL_STONESKIN,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_FLAME_STRIKE,
      SPELL_HOLD_MONSTER,
    ],
  },
  {
    id: FEATURE_BONUS_PROFICIENCIES_WAR_DOMAIN,
    name: `Бонусное владение`,
    nameEn: `Bonus Proficiencies`,
    lvl: 1,
    text: `Вы осваиваете владение воинским оружием и тяжёлыми доспехами.`,
  },
  {
    id: FEATURE_WAR_PRIEST,
    name: `Боевой священник`,
    nameEn: `War Priest`,
    lvl: 1,
    text: `Ваш бог наделяет Вас воодушевлением, когда Вы вступаете в битву.

Когда Вы используете действие Атака, Вы можете совершить одну атаку оружием бонусным действием.

Вы можете использовать это умение количество раз, равное вашему модификатору Мудрости (минимум 1 раз). Вы восстанавливаете все потраченные использования, когда завершаете длинный отдых.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_GUIDED_STRIKE_WAR_DOMAIN,
    lvl: 2,
    ...channelDivinityGuidedStrikeTemplate,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_WAR_GOD_S_BLESSING,
    name: `Божественный канал: Благословение бога войны`,
    nameEn: `Channel Divinity: War God's Blessing`,
    lvl: 6,
    text: `Если существо в пределах 30 футов от Вас совершает бросок атаки, Вы можете реакцией предоставить этому существу бонус +10 к броску, использовав [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}).

Вы можете решить, применять ли это умение, после того как увидели результат броска, но до того как Мастер скажет, попала атака или промахнулась.`,
  },
  {
    id: FEATURE_DIVINE_STRIKE_WAR_DOMAIN,
    name: `Божественный удар`,
    nameEn: `Divine Strike`,
    lvl: [8, 14],
    text: `Вы получаете способность наполнять удары своего оружия божественной энергией.

Один раз в каждый свой ход, когда Вы попадаете по существу атакой оружием, Вы можете причинить цели дополнительный урон 1к8 того же вида, что и у оружия.

Когда Вы достигаете 14 уровня, дополнительный урон увеличивается до 2к8. `,
  },
  {
    id: FEATURE_AVATAR_OF_BATTLE,
    name: `Воплощение Битвы`,
    nameEn: `Avatar of Battle`,
    lvl: 17,
    text: `Вы получаете сопротивление к дробящему, колющему и рубящему урону от немагического оружия.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_WAR_DOMAIN,
    source: {
      id: SOURCE_PHB,
      page: 61,
    },
  })
)

