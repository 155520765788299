const {SOURCE_MTOF} = require('./../../sourceList')
const {PC_RACE_GNOME} = require('./../../pcRaceIdList')

module.exports = [
  {
    header: 'Заводные механизмы',
    text: `Усилия [гномов](PC_RACE:${PC_RACE_GNOME}), работающих с магическими и механическими вещами, порождают множество неудачных проектов, но иногда случаются воистину гениальные изобретения, такие как заводные механизмы. С момента своего открытия, секреты производства заводных механизмов среди [гномов](PC_RACE:${PC_RACE_GNOME}) переходят из поколения в поколение.`,
    source: {
      id: SOURCE_MTOF,
      page: 199,
    },
  },
  {
    header: 'Искусственная природа',
    text: `Заводные механизмы не нуждаются в воздухе, еде, питье, или сне.`,
    source: {
      id: SOURCE_MTOF,
      page: 199,
    },
  },
  {
    header: 'Уникальные проекты',
    text: `[Гномы](PC_RACE:${PC_RACE_GNOME})-ремесленники ценят уникальные заводные механизмы значительно выше, чем прекрасно работающие, но скопированные с других проектов. По этой причине даже механизмы, которые создаются по шаблонам, например, описанными здесь, редко идентичны.

Заводной механизм можно настроить, добавив одно из следующих улучшений или потенциальных уязвимостей в его блок статистики. Вы можете определить их случайно или выбрать пару модификаций, соответствующих характеру создателя механизма.`,
    source: {
      id: SOURCE_MTOF,
      page: 199,
    },
  },
  {
    header: 'Модификации заводных механизмов',
    text: `
| к10 | Модификация |
|-----|-------------|
| 1 | **Камуфляж.** Заводной механизм получает владение навыком Скрытность, если ещё не имеет его. Когда он не двигается — он не отличим от выключенных машин. |
| 2 | **Сенсоры.** Дальность тёмного зрения механизма увеличивается до 120 фт., если не была выше. Он получает владение навыком Восприятие, если ещё не имеет его. |
| 3 | **Улучшенная броня.** КД заводного механизма повышается на 2. |
| 4 | **Увеличенная скорость.** Скорость заводного механизма увеличивается на 10 фт. |
| 5 | **Укреплённая конструкция.** Заводной механизм получает сопротивление урону силовым полем, электричеством и звуком. |
| 6 | **Самовосстановление.** Если заводной механизм начинает свой ход хотя бы с одним хитом, он восстанавливает 5 хитов. Если он получал урон электричеством, эта способность не сработает в начале следующего хода. |
| 7 | **Прочный каркас.** Максимальное значение хитов заводного механизма увеличивается на число равное числу его Костей Хитов. |
| 8 | **Присоски.** Заводной механизм получает скорость лазания 30 фт. |
| 9 | **Вокальный резонатор.** Заводной механизм приобретает возможность говорить на ломанном Общем или Гномьем (по выбору создателя). |
| 10 | **Водные двигатели.** Заводной механизм получает скорость плавания 30 фт. |
`,
    source: {
      id: SOURCE_MTOF,
      page: 199,
    },
    isOneColumn: true,
  },
  {
    header: 'Неисправности заводных механизмов',
    text: `
| к10 | Неисправность |
|-----|---------------|
| 1 | **Неисправные сенсоры.** Бросайте к6 в начале каждого хода заводного механизма. Если выпадает «1», механизм Ослеплён до конца хода.| 
| 2 | **Сбитый прицел.** Бросайте к6 в начале каждого хода заводного механизма. Если выпадает «1», механизм совершает броски атаки с помехой до конца хода.| 
| 3 | **Плохое заземление.** Заводной механизм получает уязвимость к урону электричеством.| 
| 4 | **Закольцованная память.** Бросайте к6 в начале каждого хода заводного механизма. Если выпадает «1», механизм принимает одно из существ в пределах 30 фт. за своего создателя. Механизм не будет атаковать его в течении минуты или до тех пор, пока существо не нападёт на него.| 
| 5 | **Утечка масла.** Бросайте к6 в начале каждого хода заводного механизма. Если выпадает «1», механизм получает одну степень истощения, если не имеет иммунитета от него.| 
| 6 | **Ограниченное передвижение.** Заводной механизм может передвигаться только по прямой. Он может повернуться на 90 градусов до начала своего движения и ещё один раз в середине. Он может свободно вращаться на месте, если двигался в свой ход.| 
| 7 | **Программа самосохранения.** Если хиты заводного механизма составляют половину от максимума или ниже, в начале его хода бросайте к6. Если выпадает «1», механизм сбегает из боя. Если отступление невозможно — продолжает сражаться.| 
| 8 | **Перегрев.** Бросайте к6 в начале каждого хода заводного механизма. Если выпадает «1», механизм недееспособен до конца своего хода.| 
| 9 | **Ржавые шестерни.** Заводной механизм совершает с помехой броски инициативы, а его скорость снижена на 10 фт.
| 10 | **Недостаточное бронирование.** Заводной механизм теряет иммунитет к дробящему, колющему, и рубящему урону от немагических атак, совершённых не адамантовым оружием.| 
`,
    source: {
      id: SOURCE_MTOF,
      page: 199,
    },
    isOneColumn: true,
  },
]
