const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  SPELL_CIRCLE_OF_DEATH,
  SPELL_DOMINATE_MONSTER,
  SPELL_ELDRITCH_BLAST,
  SPELL_MINOR_ILLUSION,
} = require('./../../../../spellIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  CREATURE_CULTIST,
  CREATURE_STAR_SPAWN_LARVA_MAGE,
  CREATURE_SWARM_OF_INSECTS,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_STAR_SPAWN,
} = require('./../../../../creatureTypeIdList')
const {
  starSpawnDescription,
  starSpawnNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_16} = require('./../../../../crList')
const {LANG_DEEP_SPEECH} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Личиночный маг звёздных порождений',
  nameEn: 'Star Spawn Larva Mage',
  id: CREATURE_STAR_SPAWN_LARVA_MAGE,
  description: [
    `Личиночный маг — кошмарный сплав смертного тела и потусторонней субстанции. Когда могущественный [культист](CREATURE:${CREATURE_CULTIST}) червеподобных сущностей таких как Кайусс или Кезеф (обычно колдун или другой заклинатель) связывается с кометорождённым посланцем Древних, вестник последнего может слиться с сознанием обратившегося, чтобы породить личиночного мага. Сам культист не переживает трансформацию, и то существо, что замещает его, совершенно чужеродно.`,
    ...starSpawnDescription,
  ],
  note: starSpawnNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_STAR_SPAWN,
  ],
  alignmentId: ALIGNMENT_CE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 221,
    },
    {
      url: `https://5e.tools/bestiary.html#star%20spawn%20larva%20mage_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Личиночного мага звёздных порождений» у «Мистического заряда» вместо бонуса к урону почему-то указан бонус к атаке.`,
    },
  ],
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 23,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_RESTRAINED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
  ],
  cr: CR_16,
  featureList: [
    {
      name: 'Возвращение к червоподобной форме',
      description: `Когда хиты личиночного мага опускается до 0, он распадается на части [роем насекомых](CREATURE:${CREATURE_SWARM_OF_INSECTS}) в том же клетке. В случае, если эти насекомые не будут уничтожены, личиночный маг восстановится через 24 часа.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_MINOR_ILLUSION,
          {
            id: SPELL_ELDRITCH_BLAST,
            comment: '3 заряда, +3 к каждому броску урона',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DOMINATE_MONSTER,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CIRCLE_OF_DEATH,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Удар',
      description: `Цель должна пройти испытание Телосложения СЛ 19, иначе станет Отравленной до конца её следующего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Чума червей',
      restore: 6,
      description: `Каждое существо, отличное от звёздного порождения в пределах 10 футов от личиночного мага должно пройти испытание Ловкости СЛ 19, иначе получит 22 (5к8) урона некротической энергией, а также станет Ослеплённым и Обездвиженным массами копошащихся червей. Попавшая под действие эффекта цель получает 22 (5к8) урона некротической энергией в начале каждого хода личиночного мага. Существо может повторять испытание в конце каждого своего хода, завершая эффект в случае успеха.`,
    },
  ],
  reactionList: [
    {
      name: 'Подпитка слабостью',
      description: `Когда существо в пределах 20 футов от личиночного мага проваливает испытание, личиночный маг получает 10 временных хитов.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Заговор',
      cost: 2,
      description: `Личиночный маг творит один заговор.`,
    },
    {
      name: 'Удар',
      cost: 2,
      description: `Личиночный маг совершает атаку _Ударом_.`,
    },
    {
      name: 'Подпитка',
      cost: 3,
      description: `Каждое существо, оплетённое _Чумой червей_, получает 13 (3к8) урона некротической энергией, а личиночный маг получает 6 временных хитов.`,
    },
  ],
}
