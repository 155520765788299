const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_DUERGAR,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DUERGAR_RESILIENCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_DUERGAR_XARRORN} = require('./../../../../creatureIdList')
const {GEAR_SPLINT_ARMOR} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Двергар заррорн',
  nameAlt: 'Дуэргар ксаррорн',
  nameEn: 'Duergar Xarrorn',
  id: CREATURE_DUERGAR_XARRORN,
  description: [
    `Ксаррорн — специалисты, создающие орудия с помощью комбинации алхимии и псионики.`,
    ...duergarDescription,
  ],
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DWARF,
    CREATURE_TYPE_DUERGAR,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 173,
  },
  armor: {
    ac: 18,
    gearId: GEAR_SPLINT_ARMOR,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_DUERGAR_RESILIENCE,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Огненное копьё',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 5,
          comment: `атака с помехой, если цель находится в пределах 5 фт`,
        },
        range: 10,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 12,
            diceBonus: 3,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Огненное копьё',
      comment: `в увеличенном состоянии`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 5,
          comment: `с помехой, если цель находится на расстоянии 5 фт от двергара`,
        },
        range: 10,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 12,
            diceBonus: 3,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Огненный поток',
      restore: 5,
      description: `Из огненного копья двергар извлекает поток огня конусом в 15 футов или огненную струю длиной 30 футов и шириной 5 футов. Каждое существо в этой зоне должно пройти испытание Ловкости СЛ 12, получая при провале 10 (3к6) огненного урона или половину этого урона при успехе.`,
    },
    {
      name: 'Увеличение',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `На 1 минуту двергар магическим образом увеличивается в размере вместе со всем, что несёт и носит. В увеличенном состоянии двергар Большого размера, удваивает кости урона в атаках, основанных на Силе (уже учтено), и совершает с преимуществом проверки Силы. Если двергару не хватает места, чтобы стать Большим, он приобретает максимальный размер, допустимый имеющимся пространством.`,
    },
    {
      name: 'Невидимость',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Двергар магическим образом становится невидимым, и этот эффект будет продолжаться до 1 часа, или же до того момента, когда он атакует, прочитает заклинание, использует _Увеличение_ или потеряет концентрацию (как если бы он концентрировался на заклинании). Всё, что он несёт и носит, становится невидимым вместе с ним.`,
    },
  ],
}
