const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_SURVIVAL,
  } = require('./../../../../skillList'),
  {
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_LN,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_SWIM,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_AMPHIBIOUS,
  } = require('./../../../../creatureAbilityList'),
  {
    GOD_UBTAO,
  } = require('./../../../../godIdList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_ALDANI,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Алдани',
  nameAlt: 'Лобстеролюд',
  nameEn: 'Aldani',
  nameEnAlt: 'Lobsterfolk',
  id: CREATURE_ALDANI,
  description: `Многие Чульты могут вспомнить детские сказки об алдани, жутких лобстеролюдах, которые жили в реках и озёрах. Поскольку в течение многих лет никто не видел алдани, распространилось мнение, что они были уничтожены. По правде говоря, многие алдани выжили и отступили к озёрам Бассейна Алдани в самом сердце Чульта.

Алдани похож на гигантского, прямоходящего омара. Его лицо, по большей части, закрыто бронёй и выглядит почти гуманоидным, за исключением длинных глаз. Несмотря на их устрашающий вид, алдани избегают конфликтов. Будучи затворниками и ксенофобами, они прячутся в своих озёрах и реках.

# Взятка алдани

Если спокойствие алдани нарушено, то они пытаются отпугнуть непрошеных гостей, не убивая их. Столкнувшись с существами, которые не так легко напугать, они предлагают взятки в обмен на то, что их оставят в покое. Эти взятки представляются в виде сокровищ, которые алдани собирают со дна рек и озёр.

# Прокляты (GOD:${GOD_UBTAO})

Алдани — это чудовищные потомки чультов, принадлежащих к племени Алдани. Рыболовы племени тысячами ловили лобстеров в реках, пока лобстеров совсем не осталось. Их жадность рассердила [Убтао](GOD:${GOD_UBTAO}), и он проклял рыбаков, превратив их в лобстеров. Рыбаки приняли своё наказание и свою новую сущность. Однако, другие члены племени презирали их, и они были вынуждены найти убежище в реках, которые когда-то разграбили. Хотя племя Алдани вымерло, лобстеролюды остаются до сих пор.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_TOA,
    page: 210,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_AMPHIBIOUS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Клешнями_.`,
    },
    {
      name: 'Клешня',
      description: `Цель схвачена (СЛ высвобождения 11). У ★СУЩЕСТВО★ две клешни, каждой из которых он может держать одну цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 1,
        },
      },
    },
  ],
}
