const {
  CREATURE_GRAY_OOZE,
  CREATURE_MIND_FLAYER,
  CREATURE_PSYCHIC_GRAY_OOZE,
} = require('./../../../../creatureIdList')
const {PARAM_INT} = require('./../../../../paramList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Психическая серая слизь',
  nameEn: 'Psychic Gray Ooze',
  id: CREATURE_PSYCHIC_GRAY_OOZE,
  parentId: CREATURE_GRAY_OOZE,
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 268,
    }],
    params: {
      [PARAM_INT]: 6,
    },
    description: [
      {
        header: 'Вариант: Психическая серая слизь',
        text: `[Серая слизь](CREATURE:${CREATURE_GRAY_OOZE}), прожившая достаточно долго, эволюционирует до более разумного состояния и развивает в себе ограниченные псионические способности. Чаще всего это происходит с серой слизью, живущей рядом с существами, обладающими псионическими способностями, такими как [иллитиды](CREATURE:${CREATURE_MIND_FLAYER}), а это означает, что слизь чувствует и имитирует псионические способности.

Псионическая серая слизь имеет Интеллект 6 (−2) и следующее дополнительное действие:

**Психическое сдавливание (перезарядка 5–6).** Слизь нацеливается на одно существо, которое чувствует в пределах 60 футов от себя. Цель должна пройти испытание Интеллекта СЛ 10, получая урон психической энергией 10 (3к6) при провале или половину при успехе.`,
        source: {
          id: SOURCE_MM,
          page: 268,
        },
      },
    ],
    actionList: [
      {
        name: 'Психическое сдавливание',
        restore: 5,
        description: `★СУЩЕСТВО★ нацеливается на одно существо, которое чувствует в пределах 60 футов от себя. Цель должна пройти испытание Интеллекта СЛ 10, получая урон психической энергией 10 (3к6) при провале или половину при успехе.`,
      },
    ],
  },
}
