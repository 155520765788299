const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN_2019_10_03} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES} = require('./../../../sourceList')
const {
  SPELL_AURA_OF_LIFE,
  SPELL_AURA_OF_VITALITY,
  SPELL_CIRCLE_OF_POWER,
  SPELL_DARKNESS,
  SPELL_DREAM,
  SPELL_FAERIE_FIRE,
  SPELL_GREATER_INVISIBILITY,
  SPELL_INVISIBILITY,
  SPELL_LEOMUND_S_TINY_HUT,
  SPELL_SLEEP,
} = require('./../../../spellIdList')
const {
  FEATURE_DIVINE_DOMAIN,
  FEATURE_TWILIGHT_DOMAIN_SPELLS_2019_10_03,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_TWILIGHT_DOMAIN_SPELLS_2019_10_03,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    pcSubClassId: PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN_2019_10_03,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_AURA_OF_LIFE,
      SPELL_AURA_OF_VITALITY,
      SPELL_CIRCLE_OF_POWER,
      SPELL_DARKNESS,
      SPELL_DREAM,
      SPELL_FAERIE_FIRE,
      SPELL_GREATER_INVISIBILITY,
      SPELL_INVISIBILITY,
      SPELL_LEOMUND_S_TINY_HUT,
      SPELL_SLEEP,
    ],
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 1,
    },
  },
]
