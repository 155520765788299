const {
  MAGIC_ITEM_DEVASTATION_ORB_AIR,
  MAGIC_ITEM_DEVASTATION_ORB_EARTH,
  MAGIC_ITEM_DEVASTATION_ORB_FIRE,
  MAGIC_ITEM_DEVASTATION_ORB_WATER,
  MAGIC_ITEM_DROWN,
  MAGIC_ITEM_IRONFANG,
  MAGIC_ITEM_TINDERSTRIKE,
  MAGIC_ITEM_WINDVANE,
} = require('./../../../../../magicItemIdList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_EARTHQUAKE} = require('./../../../../../spellIdList')

module.exports = [
  {
    elementalName: 'воздуха',
    elementalWeaponId: MAGIC_ITEM_WINDVANE,
    elementalWeaponName: 'Перст ветра',
    id: MAGIC_ITEM_DEVASTATION_ORB_AIR,
    nameEn: 'Air Devastation Orb',
    oppositeElementalName: 'земли',
    orbOfWhat: 'воздушного опустошения',
    ritualName: 'Песнь четырёх ветров',
    detonationText: `В момент взрыва _Сфера воздушного опустошения_ создаёт сильную бурю, которая длится в течение 1 часа. На существ, которые заканчивают свой ход в зоне поражения _Сферы воздушного опустошения_, обрушивается сильный ветер с осколками камней и прочим мусором, поэтому каждое такое существо должно пройти испытание Телосложения СЛ 18, иначе получит дробящий урон 1к4. Силы ветра хватает, чтобы вырывать с корнем чахлые деревья или уничтожать ветхие строения, если те находятся в области действия _Сферы_ как минимум 10 минут.

В области эффекта взрыва действия _Сферы воздушного опустошения_ применяются правила по сильному ветру из главы 5 «Руководства Мастера».`,
    masterGuideDescription: {
      header: 'Сильный ветер',
      text: `Сильный ветер накладывает помеху к броскам дальнобойных атак оружием и проверкам Мудрости (Внимательность), полагающимся на слух. Сильный ветер также гасит открытое пламя, рассеивает туман и делает полёт без помощи магии практически невозможным. Летающее существо при сильном ветре должно приземляться в конце каждого своего хода, иначе оно падает.

Сильный ветер в пустыне может создать песчаную бурю, которая накладывает помеху к проверкам Мудрости (Внимательность), полагающимся на зрение.`,
      source: {
        id: SOURCE_DMG,
        page: 110,
      },
    },
  },
  {
    detonationText: `В момент взрыва сфера создаёт в зоне поражения эффект, подобный заклинанию [Землетрясение](SPELL:${SPELL_EARTHQUAKE}), который длится 1 минуту. Эффект заклинания начинает действовать в тот же ход, что и взрыв сферы.`,
    elementalName: 'земли',
    elementalWeaponId: MAGIC_ITEM_IRONFANG,
    elementalWeaponName: 'Железный клык',
    id: MAGIC_ITEM_DEVASTATION_ORB_EARTH,
    nameEn: 'Earth Devastation Orb',
    oppositeElementalName: 'воздуха',
    orbOfWhat: 'земляного опустошения',
    ritualName: 'Грохот',
  },
  {
    elementalName: 'огня',
    elementalWeaponId: MAGIC_ITEM_TINDERSTRIKE,
    elementalWeaponName: 'Высекающий искры',
    id: MAGIC_ITEM_DEVASTATION_ORB_FIRE,
    nameEn: 'Fire Devastation Orb',
    oppositeElementalName: 'воды',
    orbOfWhat: 'огненного опустошения',
    ritualName: 'Танец всепожирающего огня',
    detonationText: `В момент взрыва сфера создаёт в зоне поражения волну невероятной жары, действие которой длится на протяжении 24 часов. В зоне действия сферы применяются правила по чрезвычайной жаре из главы 5 «Руководства Мастера».

В конце каждого часа с вероятностью в 10% в случайном месте в пределах зоны действия сферы может начаться пожар, который мгновенно покрывает квадратную область со стороной в 10 футов, а затем каждый раунд распространяется на такую же область. Пожар будет бушевать до тех пор, пока не будет потушен или не выгорит полностью. Существа, которые проходят на расстоянии 10 футов от пожара или начинают там свой ход, получают урон огнём 3к6.`,
    masterGuideDescription: {
      header: 'Чрезвычайная жара',
      text: `При температуре выше +37° C существо, находящееся на жаре без доступа к питьевой воде, должно в конце каждого часа проходить испытание Телосложения, иначе оно получит одну степень истощения. Для первого часа СЛ 5, и увеличивается на 1 за каждый дополнительный час. Существа, носящие средние или тяжёлые доспехи, а также те, кто носит плотную одежду, проходят это испытание с помехой. Существа с сопротивлением или иммунитетом к урону огнём автоматически преуспевают в этом испытании, равно как и существа, адаптировавшиеся к жаркому климату.`,
      source: {
        id: SOURCE_DMG,
        page: 110,
      },
    },
  },
  {
    elementalName: 'воды',
    elementalWeaponId: MAGIC_ITEM_DROWN,
    elementalWeaponName: 'Топитель',
    id: MAGIC_ITEM_DEVASTATION_ORB_WATER,
    nameEn: 'Water Devastation Orb',
    oppositeElementalName: 'огня',
    orbOfWhat: 'водного опустошения',
    ritualName: 'Слёзы бесконечных страданий',
    detonationText: `В момент взрыва сфера создаёт в зоне поражения ужасный ливень, действие которого длится на протяжении 24 часов. В зоне действия сферы применяются правила по обильным осадкам из главы 5 «Руководства Мастера». 

Если в зоне действия сферы есть крупные водоёмы, они выходят из берегов через 2к10 часов с момента начала ливня. За это время уровень воды в них поднимается на 10 футов, затапливая окружающую местность. Наводнение движется со скоростью 100 футов в раунд, удаляясь от водоёма-источника до тех пор, пока не достигнет края области действия сферы: там вода уходит под землю (и, возможно, отступает назад к источнику). Поток воды сметает на своём пути лёгкие постройки и уносит существ, чей размер — Большой или меньше. Наводнение уничтожает возделанные поля и, в зависимости от ландшафта, может быть источником селей.`,
    masterGuideDescription: {
      header: 'Обильные осадки',
      text: `Всё в области сильного дождя или снегопада считается слабо заслонённым, и существа в этой области совершают с помехой проверки Мудрости (Внимательность), полагающиеся на зрение. Сильный дождь также гасит открытое пламя и накладывает помеху на проверки Мудрости (Внимательность), полагающиеся на слух.`,
      source: {
        id: SOURCE_DMG,
        page: 110,
      },
    },
  },
]
