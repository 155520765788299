module.exports = [
  require('./fear'),
  require('./find_familiar'),
  require('./find_steed'),
  require('./find_traps'),
  require('./finger_of_death'),
  require('./fog_cloud'),
  require('./forbiddance'),
  require('./forcecage'),
]
