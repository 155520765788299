const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  PARAM_INT,
} = require('./../../../../../paramList')
const {
  CONDITION_INCAPACITATED,
} = require('./../../../../../conditionList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
} = require('./../../../../../sourceList')
const {
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_RAULOTHIM_S_PSYCHIC_LANCE_2021_04_14,
  releasedAs: SPELL_RAULOTHIM_S_PSYCHIC_LANCE,
  name: 'Ралотимово психическое копьё',
  nameEn: 'Raulothim’s Psychic Lance',
  description: `Вы испускаете изо лба мерцающее копьё психической силы в существо, видимое Вами в пределах дистанции.

Альтернативно, Вы можете назвать имя существа. Если названная цель находится в пределах дистанции, она не получает преимуществ от укрытий и невидимости, поскольку копьё летит прямо к нему. Если цель не находится в пределах дистанции, копьё рассеивается и ячейка заклинаний не тратится.

Цель должна пройти испытание Интеллекта или получить 10к6 психического урона при провале и стать Недееспособной до начала Вашего следующего хода.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше четвёртого.`,
  lvl: 4,
  magicSchoolId: MAGIC_EVOCATION,
  range: 120,
  componentIdList: [CAST_VERBAL],
  effect: {
    savethrowParam: PARAM_INT,
    condition: CONDITION_INCAPACITATED,
    damage: {
      type: DAMAGE_PSYCHIC,
      diceCount: 10,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
    page: 6,
  },
}
