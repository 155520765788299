const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_ROD,
} = require('./../../../../../gearIdList')
const {
  MGC_TYPE_ROD,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_IMMOVABLE_ROD,
  MAGIC_ITEM_IRON_BANDS_OF_BILARRO,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_IMMOVABLE_ROD,
    name: `Неподвижный жезл`,
    nameEn: `Immovable Rod`,
    type: MGC_TYPE_ROD,
    gearType: GEAR_ROD,
    rarity: MGC_RARITY_UNCOMMON,
    description: `У этого прямого железного жезла есть кнопка на одном торце. Вы можете действием нажать эту кнопку, после чего жезл становится магическим образом зафиксирован в текущей точке пространства. Пока Вы или другое существо не нажмёте эту кнопку ещё раз, жезл не может быть сдвинут с места, игнорируя при этом даже гравитацию.

Жезл может выдержать до 8000 фунтов веса. Больший вес деактивирует жезл, и тогда тот падает. Существо может действием совершить проверку Силы СЛ 30, сдвигая зафиксированный жезл на 10 футов при успехе.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 186,
    },
  },
  {
    id: MAGIC_ITEM_IRON_BANDS_OF_BILARRO,
    name: `Железные ленты Биларро`,
    nameEn: `Iron Bands of Bilarro`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Это ржавая железная сфера диаметром 3 дюйма, весящая 1 фунт. Вы можете действием произнести командное слово и бросить сферу в существо с размером не больше Огромного, которое видите в пределах 60 футов от себя. В полёте сфера распадается на отдельные металлические ленты.

Совершите бросок дальнобойной атаки с бонусом броска атаки, равным модификатору Ловкости плюс Ваш бонус мастерства. При попадании цель становится обездвиженной, пока Вы не произнесёте командное слово ещё раз бонусным действием. После повторного произношения командного слова или промаха атаки ленты сжимаются и снова становятся сферой.

Любое существо, в том числе и обездвиженное, может действием совершить проверку Силы СЛ 20, чтобы порвать ленты. При успехе предмет уничтожается, а удерживаемое существо освобождается. При провале все дальнейшие попытки этого существа автоматически проваливаются в течение 24 часов.

Железные ленты нельзя использовать повторно до следующего рассвета.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 163,
    },
  },
]
