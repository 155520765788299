const {CREATURE_LEMURE} = require('./../../../../../creatureIdList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MAGIC_ITEM_HELLFIRE_WEAPON} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_BGDIA} = require('./../../../../../sourceList')
const {CAT_WEAPON} = require('./../../../../../gearCategoryList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_HELLFIRE_WEAPON,
  name: `Оружие пламени Преисподней`,
  nameEn: `Hellfire Weapon`,
  type: MGC_TYPE_WEAPON,
  gearCategory: CAT_WEAPON,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Это оружие выковано из дьявольского железа. По нему тянутся прожилки пламени Преисподней, испуская тусклый свет в пределах 5 футов.

Душа гуманоида, убитого атакой этого оружия, направляется в реку Стикс, где немедленно переродится в [лемура](CREATURE:${CREATURE_LEMURE}).`,
  genderId: GENDER_MIDDLE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_BGDIA,
    page: 226,
  },
}
