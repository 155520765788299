const {PC_SUBCLASS_FIGHTER_SHARPSHOOTER_2016_12_05} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES} = require('./../../../sourceList')
const {
  FEATURE_CAREFUL_EYES_2016_12_05,
  FEATURE_CLOSE_QUARTERS_SHOOTING_2016_12_05,
  FEATURE_RAPID_STRIKE_2016_12_05,
  FEATURE_SNAP_SHOT_2016_12_05,
  FEATURE_STEADY_AIM_2016_12_05,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_STEADY_AIM_2016_12_05,
    name: `Верный прицел`,
    nameEn: `Steady Aim`,
    lvl: 3,
    text: `Ваша меткость становится смертоносной.

Вы можете бонусным действием на своём ходу точно прицелиться в существо, видимое Вами в пределах дальности несомого Вами дальнобойного оружия. До конца этого хода Ваши дальнобойные атаки этим оружием по этой цели получают следующие преимущества:

* Атаки игнорируют укрытие наполовину и на три четверти.
* При каждом попадании оружие наносит дополнительный урон цели, равный 2 + Ваш уровень воина.

Вы можете использовать эту способность три раза. После этого Вы должны завершить короткий или длинный отдых, чтобы использовать её снова.`,
    source: {
      id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
      page: 3,
    },
  },
  {
    id: FEATURE_CAREFUL_EYES_2016_12_05,
    name: `Внимательный глаз`,
    nameEn: `Careful Eyes`,
    lvl: 7,
    text: `Вы великолепно обнаруживаете прячущихся врагов и другие угрозы.

Вы можете совершать действие Поиск бонусным действием.

Кроме того, Вы теперь владеете одним из навыков по Вашему выбору — Внимательностью, Анализом или Выживанием.`,
    source: {
      id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
      page: 4,
    },
  },
  {
    id: FEATURE_CLOSE_QUARTERS_SHOOTING_2016_12_05,
    name: `Стрельба в упор`,
    nameEn: `Close-Quarters Shooting`,
    lvl: 10,
    text: `Вы овладеваете навыками стрельбы в упор.

Совершение дальнобойной атаки в пределах 5 футов от врага не накладывает помеху на бросок атаки.

Кроме того, если Вы на своём ходу попадаете дальнобойной атакой по существу в пределах 5 футов, это существо не может совершать реакции до конца этого хода.`,
    source: {
      id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
      page: 4,
    },
  },
  {
    id: FEATURE_RAPID_STRIKE_2016_12_05,
    name: `Быстрый удар`,
    nameEn: `Rapid Strike`,
    lvl: 15,
    text: `Вы научились жертвовать точностью в обмен на скорость ударов.

Если у Вас есть преимущество на атаку оружием по цели на своём ходу, Вы можете бонусным действием отменить это преимущество, немедленно совершая одну дополнительную атаку по этой же цели.`,
    source: {
      id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
      page: 4,
    },
  },
  {
    id: FEATURE_SNAP_SHOT_2016_12_05,
    name: `Немедленный выстрел`,
    nameEn: `Snap Shot`,
    lvl: 18,
    text: `Вы всегда готовы начать действовать.

Если Вы совершаете действие Атака на своём первом ходу боя, то можете частью этого действия совершить одну дополнительную дальнобойную атаку оружием.`,
    source: {
      id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
      page: 4,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_SHARPSHOOTER_2016_12_05,
  })
)

