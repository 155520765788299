const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../sourceList')
const {
  CREATURE_GIANT_RAT,
  CREATURE_WERERAT,
} = require('./../../creatureIdList')
const {
  PARAM_DEX,
  PARAM_INT,
} = require('./../../paramList')
const {
  GEAR_ARROWS,
  GEAR_BURGLAR_S_PACK,
  GEAR_CROSSBOW_HAND,
  GEAR_DAGGER,
  GEAR_DUNGEONEER_S_PACK,
  GEAR_EXPLORER_S_PACK,
  GEAR_LEATHER_ARMOR,
  GEAR_LOCK,
  GEAR_LONGSWORD,
  GEAR_QUIVER,
  GEAR_RAPIER,
  GEAR_SHORTBOW,
  GEAR_SHORTSWORD,
  GEAR_THIEVES_TOOLS,
} = require('./../../gearIdList')
const {
  LANG_THIEVES_CANT,
} = require('./../../languageIdList')
const {BACKGROUND_CHARLATAN} = require('./../../backgroundIdList')
const {
  CAT_LIGHT_ARMOR,
  CAT_SIMPLE_WEAPON,
} = require('./../../gearCategoryList')
const {
  SKILL_ACROBATICS,
  SKILL_ATHLETICS,
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_INVESTIGATION,
  SKILL_PERCEPTION,
  SKILL_PERFORMANCE,
  SKILL_PERSUASION,
  SKILL_SLEIGHT_OF_HAND,
  SKILL_STEALTH,
} = require('./../../skillList')
const {
  PC_CLASS_FIGHTER,
  PC_CLASS_ROGUE,
} = require('./../../pcClassIdList')
const {
  PC_SUBCLASS_ROGUE_ARCANE_TRICKSTER,
} = require('./../../pcSubClassIdList')
const {
  PC_RACE_GNOME,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')

module.exports = {
  id: PC_CLASS_ROGUE,
  name: {
    singular: {
      nominative: 'плут',
      genitive: 'плута',
      dative: 'плуту',
      accusative: 'плута',
      instrumental: 'плутом',
      prepositional: 'плуте',
    },
    plural: {
      nominative: 'плуты',
      genitive: 'плутов',
      dative: 'плутам',
      accusative: 'плутов',
      instrumental: 'плутами',
      prepositional: 'плутах',
    },
  },
  nameEn: 'rogue',
  description: [
    `Дав спутникам сигнал ждать, [полурослик](PC_RACE:${PC_RACE_HALFLING}) крадётся по подземному залу. Он прижимает ухо к двери, потом вытаскивает [набор инструментов](GEAR:${GEAR_THIEVES_TOOLS}) и вскрывает [замок](GEAR:${GEAR_LOCK}) в мгновение ока. Затем он исчезает в тени, пока его друг [воин](PC_CLASS:${PC_CLASS_FIGHTER}) идёт вперёд, чтобы пинком открыть дверь.

[Человек](PC_RACE:${PC_RACE_HUMAN}) скрывается в тени переулка, в то время как его сообщница готовит свою часть засады. Когда их цель — известный работорговец — проходит по переулку, сообщница издаёт крик, работорговец начинает выяснять, что произошло, и лезвие убийцы перерезает его горло прежде, чем он может издать звук.

Подавив смешок, [гномка](PC_RACE:${PC_RACE_GNOME}) шевелит пальцами и с помощью магии снимает ключи с пояса охранника. Через миг ключи в её руке, дверь камеры открыта, она и её спутники могут спокойно совершать побег.

Плуты полагаются на мастерство, скрытность и уязвимые места врагов, чтобы взять верх в любой ситуации. У них достаточно сноровки для нахождения решения в любой ситуации, демонстрируя находчивость и гибкость, которые являются краеугольным камнем любой успешной группы искателей приключений.`,
    {
      header: `Навык и точность`,
      text: `Плуты прилагают больше усилий для освоения различных навыков, а также совершенствуют свои боевые способности, дающие им большой набор приёмов, каким мало какие персонажи могут похвастаться. Многие плуты сосредотачиваются на скрытности и обмане, в то время как другие совершенствуют навыки, помогающие им в подземельях, такие как лазание, поиск и обезвреживание ловушек, и вскрытие [замков](GEAR:${GEAR_LOCK}).

Когда дело доходит до боя, плуты отдают предпочтение хитрости, а не грубой силе. Плуту достаточно сделать один точный удар туда, где нападение наиболее повредит цели, а не одолевать врага шквалом атак. Плуты обладают почти сверхъестественной ловкостью для избегания опасностей, а некоторые обучились магическим трюкам в дополнение к своим способностям.`,
      source: {
        id: SOURCE_PHB,
        page: 95,
      },
    },
    {
      header: `Теневая жизнь`,
      text: `В каждом городе и посёлке есть своя доля плутов. Большинство из них живёт согласно худшим стереотипам класса, зарабатывая на жизнь грабежами, убийствами, срезанием кошельков и мошенничеством. Часто эти негодяи объединяются в гильдии воров или преступные кланы. Многие плуты действуют самостоятельно, но и они иногда берут учеников, помогающих в мошенничестве и грабеже. Некоторые плуты живут честной жизнью слесарей, следователей или крысоловов, работа которых очень опасна из-за обитающих в канализации [лютых крыс](CREATURE:${CREATURE_GIANT_RAT}) и [веркрыс](CREATURE:${CREATURE_WERERAT}).

Плуты искатели приключений встречаются по обе стороны закона. Часть из них — закоренелые преступники, которые решили искать удачу и клады, а другая часть живёт жизнью искателей приключений, чтобы уйти от закона. Некоторые обрели и усовершенствовали свои навыки с целью проникновения в древние руины и скрытые склепы в поисках сокровищ`,
      source: {
        id: SOURCE_PHB,
        page: 95,
      },
    },
    {
      header: 'Плут',
      text: `Плут скрытен, ловок и бесстрашен. Это универсальный персонаж, способный получить преимущество практически в любой ситуации.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Создание плута`,
      text: `Создавая персонажа плута, учитывайте отношение персонажа к закону. Есть ли у вас криминальное прошлое или настоящее? Вы в бегах от закона или от разъярённой гильдии воров? Или Вы ушли из своей гильдии в поисках больших опасностей и больших вознаграждений? Движет ли вами во время приключений жадность, или какие-либо другие желания и идеалы?

Что послужило причиной, которая увела вас прочь от прошлой жизни? Грандиозное мошенничество или ограбление пошло настолько неправильно, что заставило вас изменить взгляд на Ваше ремесло? Может, удача улыбнулась вам, и успешное ограбление дало деньги, которые были вам нужны для побега от убогой жизни. Страсть к путешествиям наконец позвала вас прочь от вашего дома? Возможно, Вы обнаружили себя отрезанным от семьи или наставника, и Вы должны найти новые средства поддержки. Или, может быть, Вы обрели нового друга — другого члена группы искателей приключений — который показал вам новые возможности для заработка, используя ваши особые таланты.`,
      source: {
        id: SOURCE_PHB,
        page: 96,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать плута, следуя этим рекомендациям.

1. Во-первых, у Вашей Ловкости должно быть наивысшее значение. Следующим по величине должно быть значение Интеллекта, при условии, что Вы планируете взять архетип [Мистический ловкач](PC_SUBCLASS:${PC_SUBCLASS_ROGUE_ARCANE_TRICKSTER}). В противном случае выберите Харизму, если планируете пользоваться обманом и социальным взаимодействием.
2. Во-вторых, выберите предысторию «[Шарлатан](BACKGROUND:${BACKGROUND_CHARLATAN})».`,
      source: {
        id: SOURCE_PHB,
        page: 96,
      },
    },
  ],
  note: [
    {
      text: `Люди забывают, что сам смысл спуска в пыльную гробницу — вынести оттуда спрятанные там сокровища. Драки для дураков. Мертвецы не могут потратить свои состояния.`,
      author: `Барнабас Клинкорез`,
    },
    {
      text: `Воры хуже всего. Ненавижу тех, кто трогает мои вещи, когда я не смотрю, особенно, если они потом не кладут вещи туда, откуда взяли.`,
      author: `Занатар`,
    },
  ],
  featureCollection: {
    hitDice: 8,
    proficiency: {
      armor: [
        CAT_LIGHT_ARMOR,
      ],
      weapon: {
        catList: [
          CAT_SIMPLE_WEAPON,
        ],
        gearList: [
          GEAR_SHORTSWORD,
          GEAR_CROSSBOW_HAND,
          GEAR_LONGSWORD,
          GEAR_RAPIER,
        ],
      },
      tool: {
        list: [
          GEAR_THIEVES_TOOLS,
        ],
      },
      savingThrow: [
        PARAM_DEX,
        PARAM_INT,
      ],
      skill: {
        toChoose: {
          count: 4,
          list: [
            SKILL_ACROBATICS,
            SKILL_ATHLETICS,
            SKILL_DECEPTION,
            SKILL_INSIGHT,
            SKILL_INTIMIDATION,
            SKILL_INVESTIGATION,
            SKILL_PERCEPTION,
            SKILL_PERFORMANCE,
            SKILL_PERSUASION,
            SKILL_SLEIGHT_OF_HAND,
            SKILL_STEALTH,
          ],
        },
      },
      language: LANG_THIEVES_CANT,
    },
    equipment: {
      coins: {
        diceCount: 4,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) [Рапира](GEAR:${GEAR_RAPIER})
    * б) [Короткий меч](GEAR:${GEAR_SHORTSWORD})
2. Выберите одно
    * а) [Короткий лук](GEAR:${GEAR_SHORTBOW}) и [колчан](GEAR:${GEAR_QUIVER}) с 20 [стрелами](GEAR:${GEAR_ARROWS})
    * б) [Короткий меч](GEAR:${GEAR_SHORTSWORD})
3. Выберите одно
    * а) [Набор взломщика](GEAR:${GEAR_BURGLAR_S_PACK})
    * б) [Набор исследователя подземелий](GEAR:${GEAR_DUNGEONEER_S_PACK})
    * в) [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})
4. [Кожаный доспех](GEAR:${GEAR_LEATHER_ARMOR})
5. Два [кинжала](GEAR:${GEAR_DAGGER})
6. [Воровские инструменты](GEAR:${GEAR_THIEVES_TOOLS})`,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 95,
    },
    {
      id: SOURCE_SRD,
      page: 39,
    },
  ],
}
