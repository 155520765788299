const {
  MAGIC_NECROMANCY,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_DAY,
} = require('./../../../../../timePeriodList')
const {
  ATTACK_MELEE_SPELL,
} = require('./../../../../../attackTypeList')
const {
  PARAM_CON,
} = require('./../../../../../paramList')
const {
} = require('./../../../../../damageTypeList')
const {
  CONDITION_BLINDED,
  CONDITION_STUNNED,
} = require('./../../../../../conditionList')
const {
  SPELL_CONFUSION,
  SPELL_CONTAGION,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_CONTAGION,
  name: 'Заражение',
  nameEn: 'Contagion',
  description: `Ваше касание вызывает болезнь. Совершите рукопашную атаку заклинанием по существу, находящемуся в пределах Вашей досягаемости. При попадании существо Отравлено.

В конце каждого своего хода цель должна проходить испытание Телосложения. После успеха в трёх таких испытаниях существо больше не Отравлено, и заклинание заканчивается. Если цель проваливает три испытания, то она больше не Отравлена, но Вы выбираете болезнь из списка ниже. Эффекты выбранной болезни действуют на цель до конца длительности заклинания.

Поскольку это заклинание вызывает у цели настоящую болезнь, все эффекты, исцеляющие болезни и влияющие на течение болезней, применимы.

# Воспаление разума

Сознание существа лихорадит. Существо совершает с помехой проверки и испытания Интеллекта, и в бою существо ведёт себя так, будто находится под действием заклинания [Смятение](SPELL:${SPELL_CONFUSION}).

# Гниение плоти

Плоть существа начинает разлагаться. Существо совершает с помехой проверки Харизмы и получает уязвимость ко всем видам урона.

# Грязевая лихорадка

Грязевая лихорадка охватывает тело существа. Существо совершает с помехой проверки Силы, испытания Силы и броски атаки, использующие Силу.

# Припадок

Существо сотрясают неостановимые судороги. Существо совершает с помехой проверки Ловкости, испытания Ловкости и броски атаки, использующие Ловкость.

# Склизкая смерть

Существо начинает неконтролируемо кровоточить. Существо совершает с помехой проверки и испытания Телосложения. Кроме того, каждый раз, когда цель получает урон, она становится Ошеломлённой до конца своего следующего хода.

# Слепотуха

Боль заволакивает сознание существа, а его глаза становятся молочно-белыми. Существо совершает с помехой проверки и испытания Мудрости, и становится Ослеплённым.`,
  lvl: 5,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_DAY, count: 7 },
  effect: [
    {
      attackType: ATTACK_MELEE_SPELL,
      savethrowParam: PARAM_CON,
      condition: CONDITION_STUNNED,
    },
    {
      attackType: ATTACK_MELEE_SPELL,
      savethrowParam: PARAM_CON,
      condition: CONDITION_BLINDED,
    },
  ],
  source: [
    {
      id: SOURCE_PHB,
      page: 227,
    },
    {
      id: SOURCE_SRD,
      page: 129,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Теперь заклинание «Заражение» работает через Отравление цели',
      page: 3,
    },
  ],
}
