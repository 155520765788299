const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GIANT,
  } = require('./../../../../creatureTypeIdList'),
  {
    LANG_COMMON,
    LANG_GIANT,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_SWIM,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_LIMITED_AMPHIBIOUSNESS,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_CRAB_FOLK,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Краболюд',
  nameEn: 'Crab Folk',
  id: CREATURE_CRAB_FOLK,
  description: `Краболюды — результат магического ритуала, который давным-давно провела могучая [зелёная карга], чтобы превратить своих [огров]-прислужников в более полезных слуг. Все современные краболюды произошли от тех существ. Большинство из них живут маленькими изолированными группами и довольны своей мирной жизнью охотников и собирателей. Но создавшая краболюдов магия иногда, при подходящих условиях, заставляет их совершать ужасающие набеги.

**Прибрежные слуги.** Давным-давно [зелёная карга] провозгласила пустынное побережье своей собственностью и собрала небольшую армию [огров], чтобы они служили её охранниками и солдатами. Карга находила простые умы и грубую силу огров полезными, но полное отсутствие у них хоть какой-то сообразительности портило все её планы. Многие огры упали в море и утонули, просто оптому что были слишком тупы и нетерпеливы, чтобы научиться плавать. Другие нападали на караваны и очень эффективно уничтожали их защитников, но забирали дешёвые, громоздкие товары, не обращая внимания на мелкие ценности.

Разочарованная своими слугами, карга сплела заклинание, чтобы адаптировать их к жизни на побережье, одновременно внедряя в их сознание несколько простых команд, чтобы помочь слугам с их задачами. Она увеличив силу огров, одарив их стойкостью и прочностью [крабов], сотворила им крепкие клешни, чтобы хватать нужные карге сокровища, и глубоко укоренила в них магическое стремление к послушанию.

**Жадные до сокровищ.** У карги была особая страсть к серебру, и она не желала, чтобы даже самый маленький кусочек этого металла ускользнул из её цепких лап. Её заклинание наполнило краболюдов ненасытной жаждой серебра. Любой видящий серебро краболюд испытывает непреодолимое желание схватить его и унести в своё логово. Они даже прибегают к насилию, если это необходимо для получения вожделенного металла.

**Жестокие налётчики.** Каждый год, когда теплое лето сменяется холодом осени, краболюды ищут в небе знамения. В древние времена [зелёная карга] пристально следила за рыбацкими деревнями и городками возле её владений. Если эти поселения становились слишком сильными, она посылала своего фамильяра, жестокую чайку, чтобы криками собрать краболюдов и послать их на войну. И по сей день иногда случается так, что кричащая у логова краболюдов чайка случайно произносит нужную комбинацию звуков и вызывает ту древнюю подчиняющую магию. И тогда, ведомые глубоко скрытой магией принуждения, краболюды неистовствует по всему побережью, грабя и разрушая все известные им поселения, о которых они знают или с которыми сталкиваются. Ровно через шесть дней их буйство заканчивается, и они возвращаются к своей скромной жизни.

**Глубинные воспоминания.** Завершив прибрежный налёт или кражу серебра, краболюды собирают добытые сокровища, и кладут их к ногам примитивного изображения их карги-создательницы. Они обычно делают её скульптуру из коряг и другого мусора, валяющегося в общем логове. Краболюды плохо понимают причины своего поведения, но они знают, что это — изображение матери-создательницы, которой нужно повиноваться.

Легенда гласит, что та карга предпочитала путешествовать в облике королевы [хобгоблинов]. Если краболюды когда-либо встречают кого-то, кто сильно похож на этот образ, то заложенная в них их магия может заставить их начать поклоняться этому существу, подчиняясь каждой его команде.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 6,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 9,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_3,
  featureList: [
    {
      id: ABILITY_LIMITED_AMPHIBIOUSNESS,
      time: `24 часа`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Клешнями_.`,
    },
    {
      name: 'Клешня',
      description: `Цель схвачена (СЛ высвобождения 14). До окончания захвата ★СУЩЕСТВО★ может автоматически попадать этой _Клешнёй_ по этой цели, но не может атаковать ей другие цели. У ★СУЩЕСТВО★ всего две клешни.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
}
