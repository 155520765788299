const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_DIVINATION} = require('./../../../../../magicList')
const {SPELL_GUIDANCE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_GUIDANCE,
  name: 'Указание',
  nameEn: 'Guidance',
  description: `Вы касаетесь одного согласного существа. Один раз, пока заклинание активно, цель может бросить к4 и добавить выпавшее число к одной проверке характеристики на свой выбор. Эту кость можно бросить до или после совершения проверки. После этого заклинание оканчивается.`,
  lvl: 0,
  magicSchoolId: MAGIC_DIVINATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 286,
    },
    {
      id: SOURCE_SRD,
      page: 151,
    },
  ],
}
