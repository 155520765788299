const {
  SPELL_BLADE_BARRIER,
  SPELL_COMMUNE,
  SPELL_CONTROL_WEATHER,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_INVISIBILITY,
  SPELL_LESSER_RESTORATION,
  SPELL_RESURRECTION,
} = require('./../../../../spellIdList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_DIVINE_AWARENES,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {ALIGNMENT_LG} = require('./../../../../aligmentList')
const {angelDescriptionList} = require('./../../../../textCommonParts')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_21} = require('./../../../../crList')
const {CREATURE_SOLAR} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CELESTIAL} = require('./../../../../creatureTypeIdList')
const {GEAR_GREATSWORD} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Солар',
  nameEn: 'Solar',
  id: CREATURE_SOLAR,
  description: [
    `Солар подобен богу по своей славе и силе. Меч солара самостоятельно летает по полю боя, а одна-единственная стрела из его лука может запросто убить цель. Настолько велика мощь солара, что даже демонические князья содрогаются от его звучных команд.

Говорят, что существует всего двадцать четыре солара. Известно, что несколько соларов являются верными помощниками некоторых богов. Остальные отдыхают в состоянии созерцания, ожидая, когда их услуги понадобятся для противостояния огромной угрозе силам добра.`,
    ...angelDescriptionList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_LG,
  armor: {
    ac: 21,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 50,
    [SPEED_FLY]: 150,
  },
  params: {
    [PARAM_STR]: 26,
    [PARAM_DEX]: 22,
    [PARAM_CON]: 26,
    [PARAM_INT]: 25,
    [PARAM_WIT]: 25,
    [PARAM_CHA]: 30,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_RADIANT,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_21,
  source: {
    id: SOURCE_MM,
    page: 21,
  },
  featureList: [
    {
      name: 'Оружие ангела',
      description: `Атаки ★его★ оружием являются магическими. Если ★СУЩЕСТВО★ попадает любым оружием, оно причиняет дополнительный урон излучением 6к8 (уже включено в атаку).`,
    },
    ABILITY_DIVINE_AWARENES,
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_EVIL_AND_GOOD,
          {
            id: SPELL_INVISIBILITY,
            comment: 'только на себя',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_BLADE_BARRIER,
          SPELL_DISPEL_EVIL_AND_GOOD,
          SPELL_RESURRECTION,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_COMMUNE,
          SPELL_CONTROL_WEATHER,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Двуручным мечом_.`,
    },
    {
      name: 'Двуурчный меч',
      // gearId: GEAR_GREATSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 4,
            diceType: 6,
            diceBonus: 8,
          },
          {
            type: DAMAGE_RADIANT,
            diceCount: 6,
            diceType: 8,
          },
        ],
      },
    },
    {
      name: 'Убийственный лук',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 13,
        range: {
          normal: 150,
          max: 600,
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 6,
          },
          {
            type: DAMAGE_RADIANT,
            diceCount: 6,
            diceType: 8,
          },
        ],
      },
      description: `Если цель — существо с количеством хитов 100 или меньше, она должна пройти испытание Телосложения СЛ 15, иначе умрёт.`,
    },
    {
      name: 'Летающий меч',
      description: `★СУЩЕСТВО★ отпускает _Двуручный меч_ в магическое парение в свободном пространстве в пределах 5 футов от себя. Если ★СУЩЕСТВО★ видит меч, он может мысленно бонусным действием отдать ему команду пролететь до 50 футов и либо совершить одну атаку по цели, либо вернуться в ★его★ руки. Если парящий меч становится целью любого эффекта, считается, что ★СУЩЕСТВО★ его держит. _Парящий меч_ падает, если ★СУЩЕСТВО★ умирает.`,
    },
    {
      name: 'Целебное касание',
      limit: {
        count: 4,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ прикасается к другому существу. Цель магическим образом восстанавливает 30 (6к8+3) хитов и избавляется от проклятий, болезней, отравлений, слепоты и глухоты.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
    {
      name: 'Опаляющая вспышка',
      cost: 2,
      description: `★СУЩЕСТВО★ излучает магическую, божественную энергию. Все выбранные им существа в пределах 10 футов должны пройти испытание Ловкости СЛ 23, получая при провале урон огнём 14 (4к6) плюс урон излучением 14 (4к6), или половину этого урона при успехе.`,
    },
    {
      name: 'Ослепляющий взор',
      cost: 3,
      description: `★СУЩЕСТВО★ нацеливается на видимое ★им★ в пределах 30 футов существо. Если цель видит ★его★, она должна пройти испытание Телосложения СЛ 15, иначе Ослепнет, пока Слепота не будет снята магией, такой как [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}).`,
    },
  ],
  genderId: GENDER_MALE,
}
