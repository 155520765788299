const {
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {
  VOLUME_UNIT_OUNCE,
} = require('./../../../volumeUnitList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_TOA,
} = require('./../../../sourceList')
const {
  CAT_EQUIPMENT,
  CAT_GOODS,
  CAT_MUSIC_TOOLS,
} = require('./../../../gearCategoryList')
const {
  GEAR_YARTING,
  GEAR_INK,
  GEAR_INK_PEN,
  GEAR_INSECT_REPELLENT_INCENSE,
  GEAR_INSECT_REPELLENT_SALVE,
  GEAR_IRON,
  GEAR_VIAL,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_INK_PEN,
    genderId: GENDER_MIDDLE,
    name: 'Перо',
    nameByCase: {
      nominative: 'Перо',
      accusative: 'Перо',
      instrumental: 'Пером',
    },
    nameAlt: 'Писчее перо',
    nameEn: 'Ink pen',
    cost: 2,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_INK,
    genderId: GENDER_MULTIPLE,
    name: 'Чернила',
    nameByCase: {
      nominative: 'чернила',
      genitive: 'чернил',
      accusative: 'чернила',
      instrumental: 'чернилами',
    },
    nameEn: 'Ink',
    description: 'Бутылочка чернил',
    cost: 1000,
    volume: {
      unitId: VOLUME_UNIT_OUNCE,
      count: 1,
    },
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_YARTING,
    genderId: GENDER_MALE,
    name: 'Яртинг',
    nameEn: 'Yarting',
    nameByCase: {
      nominative: 'яртинг',
      genitive: 'яртинга',
      accusative: 'яртинг',
      instrumental: 'яртингом',
    },
    description: [
      `Южный инструмент родом из Амна и Калимшана, фаэрунский аналог гитары. По всему континенту распространилось огромное количество вариаций этого инструмента.`,
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_IRON,
    genderId: GENDER_MIDDLE,
    name: 'Железо',
    nameByCase: {
      nominative: 'железо',
      genitive: 'железа',
      accusative: 'железо',
      instrumental: 'железом',
    },
    nameEn: 'Iron',
    description: '',
    cost: 10,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_INSECT_REPELLENT_SALVE,
    genderId: GENDER_MIDDLE,
    name: 'Средство от насекомых: мазь',
    nameByCase: {
      nominative: 'мазь от насекомых',
      genitive: 'мази от насекомых',
      accusative: 'мазь от насекомых',
      instrumental: 'мазью от насекомых',
    },
    nameEn: 'Insect Repellent: Salve',
    description: `Бутыль из тыквы или [флакон](GEAR:${GEAR_VIAL}) мази содержит 20 порций мази. Одна порция защищает носителя от обычных насекомых в течение 24 часов. Средство водостойкое, так что оно не смывается дождём.`,
    cost: 100,
    volume: {
      unitId: VOLUME_UNIT_OUNCE,
      count: 4,
    },
    source: {
      id: SOURCE_TOA,
      page: 32,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_INSECT_REPELLENT_INCENSE,
    genderId: GENDER_MIDDLE,
    name: 'Средство от насекомых: ароматическая свеча',
    nameByCase: {
      nominative: 'ароматическая свеча от насекомых',
      genitive: 'ароматической свечи от насекомых',
      accusative: 'ароматическая свеча от насекомых',
      instrumental: 'ароматической свечой от насекомых',
    },
    nameEn: 'Insect Repellent: Incense',
    description: 'Свеча горит в течение 8 часов и отгоняет насекомых в радиусе 20 фт вокруг себя.',
    cost: 10,
    source: {
      id: SOURCE_TOA,
      page: 32,
    },
    category: CAT_GOODS,
  },
]

module.exports = gearRawList
