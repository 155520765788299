const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  SPELL_CLAIRVOYANCE,
  SPELL_CROWN_OF_MADNESS,
  SPELL_DISINTEGRATE,
  SPELL_DOMINATE_MONSTER,
  SPELL_EYEBITE,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_ARTIFACT,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_EYE_OF_VECNA,
  MAGIC_ITEM_HAND_OF_VECNA,
  MAGIC_ITEM_RING_OF_X_RAY_VISION,
} = require('./../../../../../magicItemIdList')

const eyeAndHandOfVecna = require('./../../constants/eyeAndHandOfVecna')

module.exports = {
  id: MAGIC_ITEM_EYE_OF_VECNA,
  name: `Глаз Векны`,
  nameEn: `Eye of Vecna`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  description: [
    `${eyeAndHandOfVecna.preText}

_Глаз Векны_ и [Руку Векны](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}) можно найти вместе, а можно по отдельности. _Глаз_ выглядит как обычный глаз с кровоподтёками, вырванный из глазницы.

Для того чтобы настроится на _Глаз_, нужно вырвать свой собственный глаз и поместить артефакт в глазницу. Он сам прирастёт и останется с Вами, пока Вы не умрёте. Заняв своё место, _Глаз_ становится золотистым, но с вертикальным зрачком, как у кошки. Если _Глаз_ когда-нибудь изымут, Вы умрёте.`,
    {
      header: 'Случайные свойства',
      text: `_Глаз Векны_ обладает несколькими свойствами, определяемыми случайным образом:

* 1 малое положительное свойство
* 1 основное положительное свойство
* 1 малое отрицательное свойство`,
    },
    {
      header: 'Свойства Глаза',
      text: `Ваше мировоззрение становится нейтрально-злым, и Вы получаете следующие преимущества:

* Вы получаете истинное зрение.
* Вы можете действием начать видеть так, как если бы носили [Кольцо проникающего зрения](MAGIC_ITEM:${MAGIC_ITEM_RING_OF_X_RAY_VISION}). Вы можете окончить этот эффект бонусным действием.
* У _Глаза_ есть 8 зарядов. Вы можете действием потратить 1 или несколько зарядов, чтобы наложить им одно из следующих заклинаний (СЛ испытания 18):
  * [Корона безумия](SPELL:${SPELL_CROWN_OF_MADNESS}) (1 заряд),
  * [Подсматривание](SPELL:${SPELL_CLAIRVOYANCE}) (2 заряда),
  * [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}) (5 зарядов),
  * [Разящее око](SPELL:${SPELL_EYEBITE}) (4 заряда),
  * [Распад](SPELL:${SPELL_DISINTEGRATE}) (4 заряда).

_Глаз_ каждый день восстанавливает 1к4 + 4 заряда на рассвете. Каждый раз, когда Вы накладываете глазом заклинание, существует 5% шанс, что Векна вырвет Вашу душу и пожрёт её, сделав Ваше тело своей марионеткой. Если это произойдёт, Вы становитесь персонажем Мастера.`,
    },
    {
      header: 'Свойства Глаза и Руки',
      text: eyeAndHandOfVecna.postText,
    },
  ],
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_CLAIRVOYANCE,
    SPELL_CROWN_OF_MADNESS,
    SPELL_DISINTEGRATE,
    SPELL_DOMINATE_MONSTER,
    SPELL_EYEBITE,
  ],
  source: {
    id: SOURCE_DMG,
    page: 222,
  },
}
