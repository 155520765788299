const generateRarityList = require('./../../../../../../utils/generateRarityList')

const {GENDER_FEMALE} = require('./../../../../../genderList')
const {GEAR_SHIELD} = require('./../../../../../gearIdList')
const {MGC_TYPE_TATTOO} = require('./../../../../../magicItemTypeList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {
  MAGIC_ITEM_BARRIER_TATTOO,
  MAGIC_ITEM_BARRIER_TATTOO_12_PLUS,
  MAGIC_ITEM_BARRIER_TATTOO_15_PLUS,
  MAGIC_ITEM_BARRIER_TATTOO_18,
} = require('./../../../../../magicItemIdList')

const siblingIdList = require('./siblingIdList')
const list = require('./list')

module.exports = {
  id: MAGIC_ITEM_BARRIER_TATTOO,
  name: `Защитная татуировка`,
  nameEn: `Barrier Tattoo`,
  type: MGC_TYPE_TATTOO,
  rarity: generateRarityList(list),
  attunement: true,
  description: `Эта магическая татуировка выглядит как защитные изображения и использует чернила, выглядящие как жидкий металл. Она наносится специальной иглой.

# Настройка на татуировку

Для настройки на _Защитную татуировку_, Вам нужно держать иглу у своей кожи в том месте, где Вы хотите нанести _Татуировку_, надавливая на иглу всё время настройки. Когда настройка завершится, игла превратится в чернила, которые станут _Защитной татуировкой_ на коже.

Если Ваша настройка на _Татуировку_ завершится, она исчезнет, а игла снова появится в Вашем пространстве.

# Защита

Пока Вы не носите доспехов, _Защитная Татуировка_ даёт Вам КД в соответствии со своей редкостью, как показано в таблице ниже. Вы можете использовать [щит](GEAR:${GEAR_SHIELD}) и всё равно получать это преимущество.

| Редкость                                                     | КД                                      |
|--------------------------------------------------------------|-----------------------------------------|
| [Необычная](MAGIC_ITEM:${MAGIC_ITEM_BARRIER_TATTOO_12_PLUS}) | 12 + модификатор Ловкости               |
| [Редкая](MAGIC_ITEM:${MAGIC_ITEM_BARRIER_TATTOO_15_PLUS})    | 15 + модификатор Ловкости (максимум +2) |
| [Очень редкая](MAGIC_ITEM:${MAGIC_ITEM_BARRIER_TATTOO_18})   | 18                                      |`,
  genderId: GENDER_FEMALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 176,
  },
}
