const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MAGIC_ITEM_MIRROR_OF_LIFE_TRAPPING} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_MIRROR_OF_LIFE_TRAPPING,
  name: `Зеркало похищения жизни`,
  nameEn: `Mirror of Life Trapping`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_VERY_RARE,
  description: `Если на это 4-футовое в высоту зеркало посмотреть опосредованно, на его поверхности будут видны слабые изображения существ. Зеркало весит 50 фунтов, и у него КД 11, 10 хитов и уязвимость к дробящему урону. Когда его хиты опускаются до 0, оно разлетается вдребезги и уничтожается.

Если зеркало висит на вертикальной поверхности, и Вы находитесь в пределах 5 футов от него, Вы можете действием произнести командное слово и активировать его. Оно остаётся активированным, пока Вы вновь не произнесёте командное слово действием.

Все существа кроме Вас, видящие своё отражение в активированном зеркале, находясь в пределах 30 футов от него, должны пройти испытание Харизмы, иначе будут похищены вместе со всем, что несли и носили, в одной из двенадцати межпространственных ячеек. Это испытание совершается с преимуществом, если существо знает природу зеркала, а конструкты автоматически проходят это испытание.

Межпространственная ячейка является бесконечным простором, заполненным густым туманом, уменьшающим видимость до 10 футов. Существо, заключённое в ячейке зеркала, не стареет, и ему не нужно есть, пить и спать. Существо, заключённое в ячейке, может сбежать, используя магию, разрешающую планарное перемещение. В противном случае существо находится в ячейке, пока не освободится. Если зеркало похищает существо, но все двенадцать ячеек уже заняты, зеркало освобождает одно из похищенных существ, определённое случайным образом, и похищает нового пленника. Освобождённое существо появляется в свободном пространстве в пределах обзора зеркала, но спиной к нему. Если зеркало будет разбито, все заключённые в нём существа освобождаются и появляются в свободных клетках рядом с ним.

Находясь в пределах 5 футов от зеркала, Вы можете действием назвать имя одного существа, заключённого в нём, или номер конкретной ячейки. Названное существо или существо, находящееся в названной ячейке, появляется как образ на поверхности зеркала. После этого Вы с ним можете общаться как обычно.

Точно так же, Вы можете действием произнести второе командное слово и освободить одно существо, похищенное зеркалом. Освободившееся существо появляется со всем снаряжением в свободном пространстве, ближайшем к зеркалу, спиной к нему.`,
  genderId: GENDER_MIDDLE,
  source: {
    id: SOURCE_DMG,
    page: 166,
  },
}
