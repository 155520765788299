const {
  WEAPON_HEAVY,
  WEAPON_REACH,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const polearmDescription = require('./../../constants/polearmDescription')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_PIKE} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_PIKE,
  genderId: GENDER_FEMALE,
  name: 'Пика',
  nameByCase: {
    nominative: 'пика',
    genitive: 'пики',
    accusative: 'пику',
    instrumental: 'пикой',
  },
  nameEn: 'Pike',
  description: `${polearmDescription}
    
Недорогая, простая и эффективная пика — отличный выбор оружия для начинающих героев, которым не хватает золота.`,
  damage: {
    diceType: 10,
    diceCount: 1,
  },
  cost: 500,
  damageType: DAMAGE_PIERCING,
  weight: 18,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 109,
    },
  ],
  weaponPropList: [
    WEAPON_TWO_HANDED,
    WEAPON_REACH,
    WEAPON_HEAVY,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
