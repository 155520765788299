const {
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_FIREBALL,
  SPELL_HOLD_MONSTER,
  SPELL_IMPRISONMENT,
  SPELL_METEOR_SWARM,
  SPELL_MIRROR_IMAGE,
  SPELL_MISLEAD,
  SPELL_RAISE_DEAD,
  SPELL_TELEPORT,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ARCHDEVIL,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_BEL,
  CREATURE_ICE_DEVIL,
  CREATURE_ZARIEL,
} = require('./../../../../creatureIdList')
const {
  archDevilDescriptionList,
  archDevilNoteList,
} = require('./../../../../textCommonParts')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_25} = require('./../../../../crList')
const {GEAR_GREATSWORD} = require('./../../../../gearIdList')
const {GOD_ASMODEUS} = require('./../../../../godIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')

module.exports = {
  name: 'Бэл',
  nameEn: 'Bel',
  id: CREATURE_BEL,
  hasSpoilers: true,
  description: [
    `Бел присматривает за производством оружия и доспехов для Войны Крови. Хотя [Асмодей](GOD:${GOD_ASMODEUS}) и приказал [Зариэль](CREATURE:${CREATURE_ZARIEL}) принять Бэла своим советником, они оба, Бэл и [Зариэль](CREATURE:${CREATURE_ZARIEL}), ненавидят друг друга и всегда находят причины быть подальше друг от друга.`,
    ...archDevilDescriptionList,
  ],
  note: archDevilNoteList,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ARCHDEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_BGDIA,
    page: 116,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 27,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 28,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 26,
    [PARAM_INT]: 25,
    [PARAM_WIT]: 19,
    [PARAM_CHA]: 26,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_25,
  featureList: [
    {
      name: 'Аура страха',
      description: `Все враждебные ★СУЩЕСТВО★ существа, начинающие ход в пределах 20 футов от ★него★, должны проходить испытание Мудрости СЛ 23, если Бэл дееспособен. При провале существо становится испуганным до начала своего следующего хода. Если испытание пройдено, оно получает иммунитет к _Ауре страха_ ★СУЩЕСТВО★ на следующие 24 часа.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    ABILITY_LEGENDARY_RESISTANCE_3,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
          SPELL_FIREBALL,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DISPEL_MAGIC,
          SPELL_HOLD_MONSTER,
          SPELL_MIRROR_IMAGE,
          SPELL_MISLEAD,
          SPELL_RAISE_DEAD,
          SPELL_TELEPORT,
          SPELL_WALL_OF_FIRE,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_IMPRISONMENT,
          SPELL_METEOR_SWARM,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Бэл совершает три атаки: две — _Двуручным мечом_ и одну — _Хвостом_.`,
    },
    {
      name: 'Двуручный меч',
      // gearId: GEAR_GREATSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      description: `Если цель — горючий предмет, который никто не несёт и не носит, она загорается.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 10,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 4,
            diceType: 6,
            diceBonus: 9,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 6,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Хвост',
      description: `Если цель — существо, оно должно пройти испытание Телосложения СЛ 23 или стать ошеломлённым до конца своего следующего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 9,
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Огненный шар',
      description: `★СУЩЕСТВО★ сотворяет [Огненный шар](SPELL:${SPELL_FIREBALL}).`,
    },
    {
      name: 'Тактическое преимущество',
      cost: 2,
      description: `Бросьте к6. Результат броска вычитается из следующей атаки по ★СУЩЕСТВО★ или ★его★ союзнику (по выбору ★СУЩЕСТВО★), совершённой в течение минуты.`,
    },
    {
      name: 'Призыв геллюгонов',
      cost: 3,
      description: `★СУЩЕСТВО★ магически призывает [геллюгона](CREATURE:${CREATURE_ICE_DEVIL}) с ледяным копьём. Геллюгон появляется в свободном пространстве в пределах 60 футов от ★него★, действует как ★его★ союзник, и может призывать других дьяволов, если у него есть такая способность. Он помогает, пока ★СУЩЕСТВО★ не умрёт или не отпустит его действием.`,
    },
  ],
}
