const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_IOKHARIC} = require('./../../alphabetList')
const {SOURCE_DND3_FRCS} = require('./../../sourceList')
const {LANG_SUBGROUP_NETHERESE} = require('./../../languageSubGroupList')
const {
  LANG_HALRUAAN,
  LANG_NETHERESE,
} = require('./../../languageIdList')
const {
  PLACE_HALRUAA,
  PLACE_NIMBRAL,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_HALRUAAN,
  alphabetId: ALPHABET_IOKHARIC,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_NETHERESE,
  nameEn: 'Halruaan',
  name: {
    nominative: 'Халруанский',
    genitive: 'Халруанского',
    instrumental: 'Халруанским',
    prepositional: 'Халруанском',
  },
  typicalSpeakers: 'Халруанцы',
  description: [
    {
      text: `Потомок [Нетерезского языка](LANG:${LANG_NETHERESE})`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
  ],
  spokenPlaceList: [
    PLACE_HALRUAA,
    PLACE_NIMBRAL,
  ],
  isReady: true,
  isRealLang: true,
}
