const {CREATURE_TYPE_GENASI_FIRE} = require('./../../creatureTypeIdList')
const {GENDER_MALE} = require ('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GENASI_FIRE,
  nameEn: 'Fire genasi',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'генази огня',
      genitive: 'генази огня',
      dative: 'генази огня',
      accusative: 'генази огня',
      instrumental: 'генази огня',
      prepositional: 'генази огня',
    },
    plural: {
      nominative: 'генази огня',
      genitive: 'генази огня',
      dative: 'генази огня',
      accusative: 'генази огня',
      instrumental: 'генази огня',
      prepositional: 'генази огня',
    },
  },
}
