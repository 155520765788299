const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_DEMON} = require('./../../creatureTypeIdList')
const {demonDescription} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_DEMON,
  nameEn: 'Demon',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'демон',
      genitive: 'демона',
      dative: 'демону',
      accusative: 'демона',
      instrumental: 'демоном',
      prepositional: 'демоне',
    },
    plural: {
      nominative: 'демоны',
      genitive: 'демонов',
      dative: 'демонам',
      accusative: 'демонов',
      instrumental: 'демонами',
      prepositional: 'демонах',
    },
  },
  description: demonDescription,
}
