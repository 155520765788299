const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_14,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_ICE_DEVIL,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гелюгон',
  nameAlt: 'Ледяной дьявол',
  nameEn: 'Gelugon',
  nameEnAlt: 'Ice Devil',
  id: CREATURE_ICE_DEVIL,
  description: [
    `Ледяные дьяволы обычно обитают на холодных слоях Стигии и Кании, и служат командирами инфернальных армий, выплёскивая свои гнев и негодование на меньших дьяволов. Жаждя власти своих командиров — исчадий преисподней, — они упорно трудятся ради повышения, убивая врагов Девяти Преисподних и захватывая для своих хозяев-архидьяволов как можно больше душ.

У ледяных дьяволов, напоминающих внешне гигантских двуногих насекомых, когтистые руки и ноги, мощные мандибулы и длинные хвосты, покрытые бритвенно-острыми шипами. Некоторые из них вооружены зазубренными копьями, чьё ледяное прикосновение делает врагов беспомощными в бою.`,
    ...devilDescriptionList,
  ],
  note: devilNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 149,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 19,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_14,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, одну _Когтями_, и одну _Хвостом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 5,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 4,
            diceBonus: 5,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 5,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Стена льда',
      description: `★СУЩЕСТВО★ магическим образом формирует непрозрачную стену льда на твёрдой поверхности, которую видит в пределах 60 футов от себя. Толщина стены — 1 фут, длина до 30 футов и высота 10 футов, или же это купол полусферы с диаметром до 20 футов.

Когда стена появляется, все существа в её пространстве выталкиваются из неё по ближайшему маршруту. Существо само выбирает, с какой стороны стены окажется, если только оно не недееспособно. После этого существо проходит испытание Ловкости СЛ 17, получая урон холодом 35 (10к6) при провале или половину этого урона при успехе.

Стена существует 1 минуту, либо пока ★СУЩЕСТВО★ не станет ★недееспособным★ или не умрёт. Стене можно причинять урон и прорубать в ней проходы; у каждой 10-футовой секции КД 5, 30 хитов, уязвимость к урону огнём и иммунитет к урону кислотой, некротической энергией, психической энергией, холодом и ядом. Если секция уничтожена, она оставляет после себя область холодного воздуха. Каждый раз, когда существо оканчивает перемещение, проходя в текущем ходу через область холодного воздуха, как добровольно, так и не по своей воле, это существо должно пройти испытание Телосложения СЛ 17, получая урон холодом 17 (5к6) при провале, или половину этого урона при успехе. Область холодного воздуха исчезает, когда исчезнет вся стена.`,
      restore: 6,
    },
  ],
  genderId: GENDER_MALE,
}
