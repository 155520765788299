const {
  CREATURE_BAT,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_ENCHANTMENT,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_ANIMAL_MESSENGER,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ANIMAL_MESSENGER,
  name: 'Почтовое животное',
  nameEn: `Animal Messenger`,
  description: `При помощи этого заклинания Вы передаёте через животное сообщение. Выберите Крохотного зверя, которого видите в пределах дистанции, такого как белку, сойку или [летучую мышь](CREATURE:${CREATURE_BAT}). Вы указываете место, которое когда-то уже посещали, и получателя, описывая его признаки, такие как _«мужчина или женщина в униформе городской стражи»_ или _«рыжий дварф в остроконечной шляпе»_. Вы также произносите сообщение длиной не более 25 слов. Зверь в течение длительности заклинания двигается в указанное место, покрывая примерно 50 миль за 24 часа при полёте или 25 миль при других видах перемещения.

Прибыв на место, зверь передаёт Ваше сообщение описанному Вами существу, изображая звук Вашего голоса. Зверь передаст послание только существу, подходящему данному Вами описанию. Если зверь не достигнет места до окончания действия заклинания, сообщение будет утеряно, а зверь начнёт возвращаться к тому месту, где Вы наложили на него заклинание.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, его длительность увеличивается на 48 часов за каждый круг ячейки выше второго.`,
  lvl: 2,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 30,
  isRitual: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кусочек пищи`,
  duration: { timeId: TIME_HOUR, count: 24 },
  source: [
    {
      id: SOURCE_PHB,
      page: 263,
    },
    {
      id: SOURCE_SRD,
      page: 115,
    },
  ],
}
