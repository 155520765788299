const {
  SPELL_CHARM_PERSON,
  SPELL_DISGUISE_SELF,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_MINOR_ILLUSION,
  SPELL_PRESTIDIGITATION,
  SPELL_SILENT_IMAGE,
  SPELL_TRUE_STRIKE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GOBLINOID,
  CREATURE_TYPE_HOBGOBLIN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ACROBATICS,
  SKILL_ATHLETICS,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {
  hobgoblinDescriptionList,
  hobgoblinNote,
} = require('./../../../../textCommonParts')
const {ABILITY_UNARMORED_DEFENSE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_HOBGOBLIN_IRON_SHADOW} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GEAR_DART} = require('./../../../../gearIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Хобгоблин железная тень',
  nameEn: 'Hobgoblin Iron Shadow',
  id: CREATURE_HOBGOBLIN_IRON_SHADOW,
  description: [
    `Железные Тени — монахи хобгоблинов, которые служат тайной полицией, разведчиками и убийцами. Среди прочих хобгоблинов они занимаются шпионажем для выявления предателей и бунтарей.`,
    {
      header: 'Обучение в тайне',
      text: `Железные Тени набираются из любых хобгоблинов. Каждый член организации постоянно выискивает новых потенциальных рекрутов среди тех, чьи ловкость и выносливость равняются только их железной преданности воле Маглубиета.

Кандидат в рекруты проходит серию испытаний, предназначенных для выявления какого-либо потенциала для предательства. Тех, кто не проходит испытания, убивают, а те, кто проходит, получают секретные тренировки в боевых искусствах и магии. Это обучение — долгий и сложный процесс, многие соискатели не заканчивают его, и может пройти много лет, в течение которых Железные Тени вообще не принимают новых членов в свои ряды. Пока рекрут проходит обучение, он служит Железным Теням, ища и сообщая о подозрительном поведении.`,
      source: {
        id: SOURCE_VGTM,
        page: 200,
      },
    },
    {
      header: 'Мастера тени и кулака',
      text: `Когда обучение рекрута завершается, он будет готов применять смертоносную комбинацию техники невооруженного боя и теневой магии, чтобы вводить в заблуждение и сражать врагов. Он продолжает следить за другими хобгоблинами, но также теперь уполномочен выполнять миссии по устранению и шпионажу, как против врагов, так и среди гоблиноидов. Эти миссии назначаются им жрецами Маглубиета, которые зорко следят за сообществом гоблиноидов, чтобы оно функционировало в соответствии с волей Маглубиета.`,
      source: {
        id: SOURCE_VGTM,
        page: 200,
      },
    },
    {
      header: 'Дьяволы в масках',
      text: `Железные Тени, находящиеся на тайном задании, носят маски, сделанные так, чтобы напоминать дьяволов, с целью и скрыть их личность, и вселить страх в сердца врагов. Их маски также означает предполагаемое происхождение их боевых техник. Жрецы Маглубиета проповедуют, что Великий украл секреты теней у архидьявола, и теперь они позволяют его последователям скрывать свою личность, перемещаться между тенями и создавать иллюзии для запутывания врагов.`,
      source: {
        id: SOURCE_VGTM,
        page: 200,
      },
    },
    ...hobgoblinDescriptionList,
  ],
  note: hobgoblinNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_HOBGOBLIN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 200,
  },
  armor: 15,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GOBLIN,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_UNARMORED_DEFENSE,
  ],
  spellCast: {
    spellCasterLevel: 2,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_MINOR_ILLUSION,
      SPELL_PRESTIDIGITATION,
      SPELL_TRUE_STRIKE,
      SPELL_CHARM_PERSON,
      SPELL_DISGUISE_SELF,
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_SILENT_IMAGE,
    ],
    slotCountList: [
      Infinity,
      4,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Хобгоблин делает четыре атаки, каждая из которых может быть _Безоружным ударом_ или атакой _Дротиком_. Он также один раз может использовать _Прогулку по теням_, как до, так и после атак.`,
    },
    {
      name: 'Безоружный удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_DART,
    },
    {
      name: 'Прогулка по теням',
      description: `Хобгоблин магически телепортируется вместе со всем переносимым или одетым снаряжением на расстояние до 30 футов в незанятое место, которое может видеть. И место откуда и место куда он перемещается должны быть в тусклом свете или темноте.`,
    },
  ],
}
