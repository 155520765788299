const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    LANG_DRUIDIC,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_FLY,
  } = require('./../../../../speedList'),
  {
    CONDITION_PARALYZED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_KEEN_SMELL,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_ASSASSIN_BUG,
    CREATURE_CATOBLEPAS,
    CREATURE_GORGON,
    CREATURE_TROLL,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Жук-убийца',
  nameEn: 'Assassin Bug',
  id: CREATURE_ASSASSIN_BUG,
  description: `Жуки-убийцы изводят земли на границе цивилизации и дикие территории, где они пытаются высиживать своих ненасытных личинок в недееспособных носителях. У этих насекомоподобных, напоминающих гигантских мух, тварей есть тонкие гуманоидные конечности, намекающие на странные церемонии, создавшие их.

**Опасное выращивание.** Хотя точное происхождение жуков-убийц неясно, слухи говорят о древней секте друидов (возможно, на службе у Малара или Талоса), которые вырастили первое из этих существ. Тем не менее, остается неизвестным, было ли у них намерение создать полезное средство для разрушения трупов или более коварное средство избавления дикой природы от растущего гуманоидного населения. Об этой секте сохранилось мало сведений, но тонкие гуманоидоподобные конечности и ограниченные языковые способности жуков-убийц позволяют предположить, что друиды могли пойти на самопожертвование, создавая своё творение.

**Ужасные яицеклады.** Жук-убийца атакует паралитическим укусом, чтобы сначала вывести из строя свою жертву, что облегчит заражение этой парализованной жертвы яйцами. Тем не менее, они могут заражать любую цель по мере необходимости и будут делать это для устранения любых угроз. Из-за необходимости откладывать яйца в живого носителя, жуки-убийцы обычно убегают от конструктов и нежити. После откладывания в живого носителя из яиц жуков-убийц немедленно вылупляются личинки жуков-убийц, которые начинают прогрызать проходы к сердцу жертвы, поглощая его и убивая носителя, если их быстро не уничтожить. Пиатясь несколько дней телой своейжертвы, личинки вырастают до юных жуков-убийц, готовых продолжать свой ужасный жизненный цикл.

**Неестественная чума.** Целые поселения на порубежье были уничтожены эпидемиями жуков-убийц. Без быстро организованной защиты даже одна ошибка может заразить и сокрушить неподготовленное население. Отчаявшиеся правители, обнаружившие, что поселения находятся в опасности, часто приказывают сжигать целые кварталы или целые деревни при первых признаках заражения. Заражённые районы патрулируются стражниками с горящими факелами и пылающими мечами, готовые поджечь любой подозрительный дом. Огонь — эффективный уничтожитель личинок жуков-убийц. Дикие животные, которые зачем-то бросаются в пламя лесного пожара, часто оказываются заражёнными жуками-убийцами, поскольку они инстинктивно убивают себя, пытаясь избавиться от этого бедствия.

**Чудовищные ВЗАИМОДЕЙСТВИЯ.** Немногие существа могут выжить после заражения жуком-убийцей без немедленной обработки магией или огнём. Невероятно выносливые монстры, такие как [катоблепасы](CREATURE:${CREATURE_CATOBLEPAS}) и [горгоны](CREATURE:${CREATURE_GORGON}), иногда встречаются с глубокими оспинами, показывающими, где из их тел появились юные жуки-убийцы. Мерзкие существа, такие как карги, иногда выращивают жуков-убийц, чтобы использовать их личинок в ужасных рецептах. А [тролли](CREATURE:${CREATURE_TROLL}), как известно, преднамеренно позволяют жукам-убийцам проникать в свои тела, залечивают оставляемые ими раны и едят вылезающих личинок.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 4,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 50,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_DRUIDIC,
      doNotSpeak: true,
    },
  ],
  cr: CR_3,
  featureList: [
    ABILITY_KEEN_SMELL,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Укусом_.`,
    },
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения или стать отравленной на 1 минуту. Пока цель отравлена таким образом, она парализована. Цель может повторять испытание в конце каждого своего хода, прекращая эффект при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Яйцеклад',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: `цель заражается 1к3 яйцами жука-убийцы, из которых немедленно вылупляются личинки жуков-убийц. В начале каждого своего хода, цель получает колющий урон 1к6 за каждую личинку жука-убийцы в ней. Если прижечь огнём рану от _Укуса_ до конца следующего хода цели, цель получает урон огнём 1 и эти личинки погибают. По истечении этого времени личинки заползают слишком глубоко под кожу носителя, чтобы их сжечь.

Если цель, заражённая личинками жуков-убийц, заканчивает свой ход с 0 хитами, она умирает, так как личинки добрались до её сердца и убили её. Любой эффект, который вылечивает болезни, убивает все личинки жуков-убийц, заразившие цель.`,
      },
    },
  ],
}
