const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {CONDITION_EXHAUSTION} = require('./../../../../../conditionList')
const {DAMAGE_RADIANT} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_SICKENING_RADIANCE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_SICKENING_RADIANCE,
  name: 'Болезненное сияние',
  nameEn: 'Sickening Radiance',
  description: `Из точки, выбранной Вами в пределах дистанции, распространяется сфера тусклого зеленоватого света с радиусом 30 футов. Свет огибает углы и существует до тех пор, пока заклинание не закончится. Если существо впервые за раунд входит в область заклинания или начинает в ней свой ход, оно должно пройти испытание Телосложения, иначе получает урон излучением 4к10. Оно также получает один уровень истощения и само начинает испускать тусклый зеленоватый свет в радиусе 5 футов. Этот свет делает невозможным получение преимуществ от невидимости. Свет и любые уровни истощения, вызванные этим заклинанием, проходят, когда заклинание оканчивается.`,
  lvl: 4,
  magicSchoolId: MAGIC_EVOCATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CON,
    condition: CONDITION_EXHAUSTION,
    damage: {
      type: DAMAGE_RADIANT,
      diceCount: 4,
      diceType: 10,
      diceBonus: 0,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 149,
  },
}
