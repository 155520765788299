const listToCollectionById = require('./../utils/listToCollectionById')

const GENDER_ANY = 'any'
const GENDER_MALE = 'male'
const GENDER_FEMALE = 'female'
const GENDER_MIDDLE = 'middle'
const GENDER_MULTIPLE = 'multiple'

const genderList = [
  {
    name: 'Любой',
    pronoun: {
      nominative: 'он/она/оно',
      genitive: {
        full: 'него/неё',
        short: 'его/её',
      },
    },
    id: GENDER_ANY,
  },
  {
    name: 'Мужской',
    pronoun: {
      nominative: 'он',
      genitive: {
        full: 'него',
        short: 'его',
      },
    },
    id: GENDER_MALE,
  },
  {
    name: 'Женский',
    pronoun: {
      nominative: 'она',
      genitive: {
        full: 'неё',
        short: 'её',
      },
    },
    id: GENDER_FEMALE,
  },
  {
    name: 'Средний',
    pronoun: {
      nominative: 'оно',
      genitive: {
        full: 'него',
        short: 'его',
      },
    },
    id: GENDER_MIDDLE,
  },
  {
    name: 'Множественный',
    pronoun: {
      nominative: 'они',
      genitive: {
        full: 'них',
        short: 'их',
      },
    },
    id: GENDER_MULTIPLE,
  },
]

module.exports = genderList

module.exports.genderCollection = listToCollectionById(genderList)

module.exports.GENDER_ANY = GENDER_ANY
module.exports.GENDER_MALE = GENDER_MALE
module.exports.GENDER_FEMALE = GENDER_FEMALE
module.exports.GENDER_MIDDLE = GENDER_MIDDLE
module.exports.GENDER_MULTIPLE = GENDER_MULTIPLE
