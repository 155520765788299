const {PC_SUBCLASS_WIZARD_SCHOOL_OF_ABJURATION} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  SPELL_COUNTERSPELL,
  SPELL_DISPEL_MAGIC,
} = require('./../../../spellIdList')
const {
  FEATURE_ABJURATION_SAVANT,
  FEATURE_ARCANE_WARD,
  FEATURE_IMPROVED_ABJURATION,
  FEATURE_PROJECTED_WARD,
  FEATURE_SPELL_RESISTANCE,
  FEATURE_SPELLBOOK,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ABJURATION_SAVANT,
    name: `Мастер ограждения`,
    nameEn: `Abjuration Savant`,
    lvl: 2,
    text: `Золото и время, которое Вы тратите на копирование заклинания Ограждения в свою [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), уменьшаются вдвое.`,
    source: {
      id: SOURCE_PHB,
      page: 118,
    },
  },
  {
    id: FEATURE_ARCANE_WARD,
    name: `Магическая защита`,
    nameEn: `Arcane Ward`,
    lvl: 2,
    text: `Вы можете плести вокруг себя магию для защиты.

Когда Вы накладываете заклинание школы Ограждения 1 круга или выше, Вы можете одновременно с этим использовать прядь магии для создания магической защиты, существующей до окончания длинного отдыха. Защита имеет хиты, равные Вашему удвоенному уровню волшебника + модификатор Интеллекта. Когда Вы получаете урон, его вместо Вас получает защита. Если урон опускает хиты защиты до 0, то оставшийся после этого урон получаете Вы.

Когда у защиты остаётся 0 хитов, она не может поглощать урон, но её магия остаётся. Если Вы накладываете заклинание школы Ограждения 1 круга или выше, защита восстанавливает количество хитов, равное удвоенному уровню заклинания.

Единожды создав защиту, Вы не можете создать её вновь, пока не завершите длинный отдых.`,
    source: {
      id: SOURCE_PHB,
      page: 118,
    },
  },
  {
    id: FEATURE_PROJECTED_WARD,
    name: `Проекция защиты`,
    nameEn: `Projected Ward`,
    lvl: 6,
    text: `Когда существо, видимое Вами в пределах 30 футов, получает урон, Вы можете реакцией поглотить этот урон своей [Магической защитой](FEATURE:${FEATURE_ARCANE_WARD}).

Если урон опускает хиты защиты до 0, то защищаемое существо получает весь оставшийся урон.`,
    source: {
      id: SOURCE_PHB,
      page: 118,
    },
  },
  {
    id: FEATURE_IMPROVED_ABJURATION,
    name: `Улучшенное ограждение`,
    nameEn: `Improved Abjuration`,
    lvl: 10,
    text: `Когда Вы накладываете заклинание школы Ограждения, которое требует совершить проверку характеристики частью творимого заклинания (как в случае [Контрзаклинания](SPELL:${SPELL_COUNTERSPELL}) и [Рассеивания магии](SPELL:${SPELL_DISPEL_MAGIC})), Вы добавляете к этой проверке бонус мастерства.`,
    source: {
      id: SOURCE_PHB,
      page: 118,
    },
  },
  {
    id: FEATURE_SPELL_RESISTANCE,
    name: `Сопротивление заклинаниям`,
    nameEn: `Spell Resistance`,
    lvl: 14,
    text: `Вы получаете преимущество к испытаниям от заклинаний.

Кроме того, Вы получаете сопротивление к урону от заклинаний.`,
    source: {
      id: SOURCE_PHB,
      page: 119,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_SCHOOL_OF_ABJURATION,
  })
)
