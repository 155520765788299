const {
  SPELL_ARCANE_EYE,
  SPELL_CLAIRVOYANCE,
  SPELL_DELAYED_BLAST_FIREBALL,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_FEATHER_FALL,
  SPELL_FIRE_BOLT,
  SPELL_FIREBALL,
  SPELL_FLY,
  SPELL_ICE_STORM,
  SPELL_LIGHT,
  SPELL_LOCATE_OBJECT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MASS_SUGGESTION,
  SPELL_MAZE,
  SPELL_MESSAGE,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_SCORCHING_RAY,
  SPELL_SCRYING,
  SPELL_STONESKIN,
  SPELL_TELEPORT,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_DIVINER} = require('./../../../../creatureIdList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Прорицатель',
  nameEn: 'Diviner',
  id: CREATURE_DIVINER,
  description: `Прорицатели — специализированные волшебники, знающие, что знание — сила. Они могут действовать отчуждённо и таинственно, намекая на предзнаменования и тайны, или они могут быть всезнайками, раскрывающими секреты и проникая в суть, чтобы повысить свой собственный статус или репутацию.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 220,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 4,
    },
  ],
  cr: CR_8,
  featureList: [
    {
      name: 'Знамение',
      comment: `перезаряжается после сотворения призывателем заклинания Прорицания уровня 1 или выше`,
      description: `Когда прорицатель или видимое им существо совершает бросок атаки, проверку характеристик или проходит испытание, Прорицатель может бросить к20 и выбрать результат этого броска вместо броска атаки, проверки характеристик или испытания.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 15,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      {
        id: SPELL_ARCANE_EYE,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Прорицания 1 уровня и выше, перезаряжающее особенность «Знамение»',
      },
      {
        id: SPELL_CLAIRVOYANCE,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Прорицания 1 уровня и выше, перезаряжающее особенность «Знамение»',
      },
      {
        id: SPELL_DETECT_MAGIC,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Прорицания 1 уровня и выше, перезаряжающее особенность «Знамение»',
      },
      {
        id: SPELL_DETECT_THOUGHTS,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Прорицания 1 уровня и выше, перезаряжающее особенность «Знамение»',
      },
      {
        id: SPELL_LOCATE_OBJECT,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Прорицания 1 уровня и выше, перезаряжающее особенность «Знамение»',
      },
      {
        id: SPELL_RARY_S_TELEPATHIC_BOND,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Прорицания 1 уровня и выше, перезаряжающее особенность «Знамение»',
      },
      {
        id: SPELL_SCRYING,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Прорицания 1 уровня и выше, перезаряжающее особенность «Знамение»',
      },
      {
        id: SPELL_TRUE_SEEING,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Прорицания 1 уровня и выше, перезаряжающее особенность «Знамение»',
      },

      SPELL_DELAYED_BLAST_FIREBALL,
      SPELL_FEATHER_FALL,
      SPELL_FIRE_BOLT,
      SPELL_FIREBALL,
      SPELL_FLY,
      SPELL_ICE_STORM,
      SPELL_LIGHT,
      SPELL_MAGE_ARMOR,
      SPELL_MAGE_HAND,
      SPELL_MASS_SUGGESTION,
      SPELL_MAZE,
      SPELL_MESSAGE,
      SPELL_SCORCHING_RAY,
      SPELL_STONESKIN,
      SPELL_TELEPORT,
      SPELL_TRUE_STRIKE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
      1,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
  ],
}
