const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ILLITHID} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ILLITHID,
  nameEn: 'Illithid',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'иллитид',
      genitive: 'иллитида',
      dative: 'иллитиду',
      accusative: 'иллитида',
      instrumental: 'иллитидом',
      prepositional: 'иллитиде',
    },
    plural: {
      nominative: 'иллитиды',
      genitive: 'иллитидов',
      dative: 'иллитидам',
      accusative: 'иллитидов',
      instrumental: 'иллитидами',
      prepositional: 'иллитидах',
    },
  },
}
