const {CREATURE_TYPE_HALFLING} = require('./../../creatureTypeIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GNOME,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')

module.exports = {
  id: CREATURE_TYPE_HALFLING,
  nameEn: 'Halfling',
  nameEnAlt: 'Hin',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'полурослик',
        genitive: 'полурослика',
        dative: 'полурослику',
        accusative: 'полурослика',
        instrumental: 'полуросликом',
        prepositional: 'полурослике',
      },
      [GENDER_FEMALE]: {
        nominative: 'полурослица',
        genitive: 'полурослицы',
        dative: 'полурослице',
        accusative: 'полурослицу',
        instrumental: 'полурослицей',
        prepositional: 'полурослице',
      },
    },
    plural: {
      nominative: 'полурослики',
      genitive: 'полуросликов',
      dative: 'полуросликам',
      accusative: 'полуросликов',
      instrumental: 'полуросликами',
      prepositional: 'полуросликах',
    },
  },
  note: [
    {
      text: `Реджис, единственный полурослик на протяжении сотен миль, в какую сторону ни посмотри, сцепил ладони за головой и откинулся на густо заросший мхом ствол дерева. Реджис был чрезвычайно мал ростом даже по меркам собственного весьма миниатюрного народца. Верхние кончики волос его торчавшей во все стороны буйной гривы едва достигали трёх футов, однако живот Реджиса ясно свидетельствовал о том, что его обладатель любит съесть хороший обед, а то и несколько, смотря по обстоятельствам. Длинная кривая палка, служившая Реджису удилищем, приподнялась, чуть дрогнула, пристроилась поудобнее между мохнатыми пальцами полурослика и повисла над спокойными водами, безупречно отражаясь в зеркальной поверхности озера.`,
      author: `Р. А. Сальваторе, Магический кристалл`,
    },
    {
      text: `Зомби? Минотавры? Они меня вообще не пугают. Но вопящий воин-полурослик, несущийся на тебя верхом на когтеногом рапторе? Я не видел ничего более жуткого`,
      author: `Сэр Дантон ир’Лэйн, бреландский рыцарь`,
    },
  ],
  description: [
    {
      text: `Целью большинства полуросликов является домашний уют. Место, где можно поселиться в покое и тишине, подальше от мародёрствующих чудовищ и сражающихся армий. Огонь очага, сытная пища, добрая выпивка и добрая беседа. Хотя некоторые полурослики проживают свой век в удалённых сельских общинах, другие сбиваются в постоянно кочующие общины, влекомые открытыми дорогами, широкими горизонтами и возможностью открыть чудеса новых мест и новых людей. Но даже такие кочевники любят покой, вкусную еду, свой очаг и свой дом, даже если это повозка, трясущаяся по пыльной дороге или плот, плывущий по течению реки.`,
      source: {
        id: SOURCE_PHB,
        page: 27,
      },
    },
    {
      header: 'Полурослики',
      text: `Полурослики невелики, но талантливы; они любят домашний уют, но при этом везучи и ловки, и в случае необходимости из них выходят отличные искатели приключений.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      text: `Люд привык считать [эльфов](PC_RACE:${PC_RACE_ELF}) надменными и грациозными, [дварфов](PC_RACE:${PC_RACE_DWARF}) сильными и стойкими, а [гномов](PC_RACE:${PC_RACE_GNOME}) — если о них вообще думают — считают сообразительными и осторожными. Полурослики же обладают репутацией проворных и отважных.

Полурослики, или _хин_, как они сами себя называют, демонстрируют естественную находчивость, которая часто удивляет большие народы. Их проворство регулярно приходится им на руку, когда их отвага перевешивает здравый смысл, и рассказы о полуросликах изобилуют счастливыми спасениями и побегами на грани невозможного.

Кроме этих типичных особенностей полуросликов, их можно разделить на две крупных подрасы. Многие аспекты культур этих двух групп позволяют их различать, но и даже без этих деталей их отличает то основное, что движет ими: идти или оставаться. Легконогие полурослики как правило путешественники, а язык и сердце их так же легки, как и их поступь. Тогда как если сильные сердцем полурослики и отправляются куда-то, то ими всегда движет желание вернуться домой или обрести новый дом и осесть там. Пословица гласит: _«Легконогие — легкомысленные. Сильные сердцем — сильные узами»_.

Несмотря на то, что существует множество общин полуросликов, в частности в землях Луирена, полурослики часто находят себе место в дварфийских, гномьих, эльфийских и людских обществах. Легконогие полурослики приходят общины, гонимые ветрами странствий, легко заводят друзей и также легко покидают общины по зову тех же ветров или по какой-то другой прихоти. Сильные сердцем же, приходя, оседают, располагаясь как дома, и вплетаются в жизнь общины так плотно, что скоро люд не может вспомнить времени, когда они не были их частью.`,
      source: {
        id: SOURCE_SCAG,
        page: 109,
      },
    },
    {
      header: `Маленькие и практичные`,
      text: `Крошечные полурослики выживают в мире, полном более крупных существ, стараясь избегать внимания, а если это оказывается невозможным, то избегая враждебности. Имея рост около 3 футов (90 сантиметров), они кажутся относительно безвредными, и благодаря этому успешно существуют столетиями, оставаясь в тени империй, войн и политической борьбы. Они склонны к полноте, и весят от 40 до 45 фунтов (от 18 до 20 килограмм).

Кожа у полуросликов встречается от смуглой до бледной, с румянцем. Волосы обычно коричневые или рыже-коричневые, вьющиеся. Глаза полуросликов карие или ореховые. Мужчины часто отпускают длинные бакенбарды, но бороды носят редко, а усы тем более. Они любят носить простую, удобную одежду, предпочитая яркие цвета.

Практичность полуросликов распространяется не только на их одежду. Они довольствуются удовлетворением основных потребностей и простых радостей, уделяя совсем мало внимания роскоши. Даже богатейшие из них предпочитают хранить своё добро в закрытых сундуках и подвалах, а не выставлять его на всеобщее обозрение. Полурослики умеют находить простые решения своих проблем, и являются весьма решительными.`,
      source: {
        id: SOURCE_PHB,
        page: 27,
      },
    },
    {
      header: `Добрые и любопытные`,
      text: `Полурослики — приветливый и дружелюбный народ. Они ценят дружбу и родственные связи так же как собственный дом и очаг, лишь в тайне мечтая о золоте и славе. И даже те из них, кто стали искателями приключений, обычно отправляются в путь, преследуя цели дружбы или общества, тяги к переменам или любопытства. Они любят открывать для себя что-то новое, даже если это совсем простые вещи, вроде экзотической еды или незнакомого стиля одежды.

Полурослики легко поддаются жалости, и не выносят вида чужих страданий. Они щедры, и с радостью делятся тем, что имеют, даже в трудные времена.`,
      source: {
        id: SOURCE_PHB,
        page: 28,
      },
    },
    {
      header: `Единые с толпой`,
      text: `Полурослики легко вливаются в сообщества людей, дварфов или эльфов, где их ценят и всегда им рады. Сочетание их врождённых скрытности и скромности позволяют им легко избегать ненужного внимания.

Полурослики охотно работают с другими, и они верны своим друзьям, вне зависимости от их вида. Однако, если кто-то из их друзей, семьи или общины оказывается под угрозой, они способны проявить удивительную свирепость.`,
      source: {
        id: SOURCE_PHB,
        page: 28,
      },
    },
    {
      header: `Приветливые и положительные`,
      text: `Полурослики стараются поладить с кем угодно, и не склонны выносить поспешные суждения — особенно отрицательные.

# О [дварфах](PC_RACE:${PC_RACE_DWARF})

_«Дварфы — верные друзья, и Вы можете рассчитывать на то, что они всегда держат своё слово. Но им бы не помешало хотя бы иногда улыбаться»._

# Об [эльфах](PC_RACE:${PC_RACE_ELF})

_«Они так прекрасны! Их лица, их музыка, их грация, всё в них. Они как будто явились из чудесного сна. Но никто не сможет сказать, что происходит за их улыбающимися лицами — наверняка больше, чем они нам показывают»._

# О [людях](PC_RACE:${PC_RACE_HUMAN})

_«Люди, на самом деле, во многом похожи на нас. По крайней мере, некоторые из них. Отойдите от замков и цитаделей, поговорите с фермерами и скотоводами, и Вы обнаружите хороший, надёжный народ. Нет ничего плохого в баронах и солдатах — Вы должны восхищаться их убеждённостью. И, защищая свои земли, они также защищают нас»._`,
      source: {
        id: SOURCE_PHB,
        page: 28,
      },
    },
    {
      header: `Пасторальные удовольствия`,
      text: `Большинство полуросликов живёт в маленьких, мирных общинах с большими фермами, среди ухоженных рощ. Они редко создают собственные королевства, и не держат земель за пределами своих тихих владений. Они обычно не признают среди себя какого-либо рода знати или королевской власти, вместо этого прислушиваясь к семейным старейшинам, руководствуясь их мнением. Семьи сохраняют свой традиционный уклад, несмотря на подъёмы и падения империй.

Множество полуросликов живёт среди других рас, где усердная работа и надёжные перспективы принесут им обильное вознаграждение и земные блага. Некоторые общины полуросликов ведут кочевой образ жизни, путешествуя на повозках или плавая на суднах от одного места к другому, не оседая на одном месте постоянно.`,
      source: {
        id: SOURCE_PHB,
        page: 28,
      },
    },
    {
      header: `Поиск возможностей`,
      text: `Полурослики обычно становятся на путь искателя приключений, чтобы защитить свои общины или поддержать своих друзей или исследовать огромный наполненный чудесами мир. Для них авантюризм в меньшей степени карьера, скорее это возможность, а иногда и необходимость.`,
      source: {
        id: SOURCE_PHB,
        page: 28,
      },
    },
    {
      header: `Имена полуросликов`,
      text: `Полурослику даётся имя, фамилия, и, возможно, прозвище. Фамилии часто являются прозвищами, которые прилипли так сильно, что стали передаваться через поколения.

# Мужские имена

Альтон, Андер, Гаррет, Кейд, Коррин, Лайл, Линдал, Майло, Меррик, Осборн, Перрин, Рид, Роско, Уэллби, Финнан, Элдон, Эррих

# Женские имена

Бри, Вани, Верна, Джиллиан, Китри, Кора, Кэлли, Лавиния, Лидда, Мерла, Недда, Паэла, Портия, Серафина, Трим, Шаэна, Эндри, Юфемия

# Фамилии

Вверхтормашкин, Высокохолм, Галькоброс, Добробочка, Зеленофляг, Кустосбор, Лугодуг, Подветкин, Репейник, Чайнолист`,
      source: {
        id: SOURCE_PHB,
        page: 28,
      },
    },
  ],
}
