const {
  SPELL_CONFUSION,
  SPELL_DETECT_THOUGHTS,
  SPELL_DOMINATE_MONSTER,
  SPELL_EYEBITE,
  SPELL_FEEBLEMIND,
  SPELL_FLY,
  SPELL_MASS_SUGGESTION,
  SPELL_MIND_BLANK,
  SPELL_NONDETECTION,
  SPELL_PLANE_SHIFT,
  SPELL_PROJECT_IMAGE,
  SPELL_SCRYING,
  SPELL_TELEKINESIS,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_ELDER_BRAIN,
  CREATURE_MIND_FLAYER,
  CREATURE_ULITHARID,
} = require('./../../../../creatureIdList')
const {
  LANG_DEEP_SPEECH,
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_INCAPACITATED} = require('./../../../../conditionList')
const {CR_9} = require('./../../../../crList')
const {GEAR_BREASTPLATE} = require('./../../../../gearIdList')
const {illithidDescriptionList} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Улитарид',
  nameEn: 'Ulitharid',
  id: CREATURE_ULITHARID,
  description: [
    `Очень редко головастик из бассейна соляного раствора [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}) превращается в улитарида, более огромного и мощного [свежевателя разума](CREATURE:${CREATURE_MIND_FLAYER}), который может похвастаться шестью щупальцами.`,
    {
      header: `Хозяева разумов`,
      text: `Иллитиды врожденным образом распознают, что выживание улитарида более важно, чем их собственное. Реакция [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}) на восход улитарида бывает разной. В большинстве колоний улитарид становится самым любимым слугой [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}), обличенным властью и могуществом. В других же [старший мозг](CREATURE:${CREATURE_ELDER_BRAIN}) воспринимает улитарида как потенциального соперника в борьбе за власть и манипулирует или подавляет амбиции улитарида соответствующим образом.`,
      source: {
        id: SOURCE_VGTM,
        page: 191,
      },
    },
    {
      header: `Рождение колонии`,
      text: `Когда улитарид начинает считать разделение власти со [старшим мозгом](CREATURE:${CREATURE_ELDER_BRAIN}) неприемлемым, он уходит из колонии, забирая группу [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}) с собой, и перемещается в другое место, чтобы основать новую колонию. После смерти тела улитарида, [свежеватели разума](CREATURE:${CREATURE_MIND_FLAYER}) берут его мозг и помещают его в бассейн соляного раствора, где он вырастает до [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}) через несколько дней. Этот процесс не сработает на мозге улитарида, который умер естественной смертью, так как мозг из-за большого возраста слишком одряхлел для создания [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}).`,
      source: {
        id: SOURCE_VGTM,
        page: 191,
      },
    },
    {
      header: `Посох извлекателя`,
      text: `Каждый улитарид владеет псионически усиленным посохом, сделанным из чёрного металла. Когда улитарид готов отдать свою жизнь, он прикрепляет посох к затылку и тот раскалывает его череп и отсеивает осколки, так что мозг можно извлечь. Мозг и посох кладутся в тело улитарида после этого, что вызывает его растворение в ихор. Эта псионически активная слизь помогает начать превращение области в бассейн соляного раствора, который окружает эмбрион [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}).`,
      source: {
        id: SOURCE_VGTM,
        page: 191,
      },
    },
    ...illithidDescriptionList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 191,
  },
  armor: {
    ac: 15,
    gearId: GEAR_BREASTPLATE,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 15,
    [PARAM_INT]: 21,
    [PARAM_WIT]: 19,
    [PARAM_CHA]: 21,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
    {
      id: LANG_TELEPATHY,
      range: '2 мили',
    },
  ],
  cr: CR_9,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Чувство существ',
      description: `Улитарид знает о присутствии существ, находящихся на расстоянии до 2 миль от него, обладающих показателем Интеллекта равным 4 или выше. Он знает расстояние и направление к каждому существу, а также показатели Интеллекта этих существ, но не может чувствовать ничего другого, касаемо этих существ. Существо, защищенное заклинанием [сокрытие разума](SPELL:${SPELL_MIND_BLANK}), [необнаружимость](SPELL:${SPELL_NONDETECTION}) или похожей магией не замечаются улитаридом таким способом. `,
    },
    {
      name: 'Псионический концентратор',
      description: `Если [старший мозг](CREATURE:${CREATURE_ELDER_BRAIN}) устанавливает _Псионическую связь_ с улитаридом, [старший мозг](CREATURE:${CREATURE_ELDER_BRAIN}) может сформировать _Псионическую связь_ с любым другим существом, которого сможет обнаружить улитарид с помощью Чувства существ. Любая подобная связь оканчивается в том случае, если существо выходит из досягаемости телепатии улитарида и старшего мозга. Улитарид может поддерживать связь со старшим мозгом вне зависимости от расстояния между ними, пока они оба находятся на одном плане бытия. Если улитарид находится на расстоянии более чем 5 миль от старшего мозга, он может окончить _Псионическую связь_ в любой момент (действия для этого не требуется).`,
    },
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_THOUGHTS,
          SPELL_FLY,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONFUSION,
          SPELL_DOMINATE_MONSTER,
          SPELL_EYEBITE,
          SPELL_FEEBLEMIND,
          SPELL_MASS_SUGGESTION,
          SPELL_PROJECT_IMAGE,
          SPELL_SCRYING,
          SPELL_TELEKINESIS,
          {
            id: SPELL_PLANE_SHIFT,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Щупальца',
      description: `Если цель Большая или меньше, то она схвачена (Сл освобождения 14) и должна пройти испытание Интеллекта СЛ 17 или будет ошеломлена, пока не окончится захват.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 4,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Извлечение мозга',
      description: `Если этот урон уменьшает хиты цели до 0, то улитарид убивает цель, извлекает и пожирает её мозг.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        target: {
          count: 1,
          comment: `, схваченный улиталидом`,
          limit: {
            creatureType: CREATURE_TYPE_HUMANOID,
            condition: CONDITION_INCAPACITATED,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 10,
          diceType: 10,
          diceBonus: 0,
        },
      },
    },
    {
      name: 'Взрыв разума',
      restore: 5,
      description: `Улитарид магически излучает психическую энергию в конусе в 60 футов. Каждое существо в этой области должно пройти испытание Интеллекта СЛ 17 или получить 22 (4к12 + 5) психического урона и стать ошелёмлённым на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, заканчивая эффект на себе при успехе.`,
    },
  ],
}
