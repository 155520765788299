const {
    SOURCE_ERFTLW,
    SOURCE_TCoE,
    SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
  } = require('./../sourceList'),
  {
    GEAR_THIEVES_TOOLS,
  } = require('./../gearIdList'),
  {
    SPELL_MENDING,
  } = require('./../spellIdList'),
  {
    CREATURE_EXPEDITIOUS_MESSENGER,
    CREATURE_HOMUNCULUS_SERVANT,
    CREATURE_IRON_DEFENDER,
  } = require('./../creatureIdList'),
  replicableMagicItem = require('./../replicableMagicItem'),
  {
    INFUSION_ARCANE_PROPULSION_ARMOR,
    INFUSION_ARMOR_OF_MAGICAL_STRENGTH,
    INFUSION_ARMOR_OF_MAGICAL_STRENGTH_2020_02_24,
    INFUSION_ARMOR_OF_TOOLS_2020_02_24,
    INFUSION_BOOTS_OF_THE_WINDING_PATH,
    INFUSION_ENHANCED_ARCANE_FOCUS,
    INFUSION_ENHANCED_DEFENSE,
    INFUSION_ENHANCED_WEAPON,
    INFUSION_HELM_OF_AWARENESS,
    INFUSION_HOMUNCULUS_SERVANT,
    INFUSION_MIND_SHARPENER,
    INFUSION_MIND_SHARPENER_2020_02_24,
    INFUSION_RADIANT_WEAPON,
    INFUSION_REPEATING_SHOT,
    INFUSION_REPLICATE_MAGIC_ITEM,
    INFUSION_REPULSION_SHIELD,
    INFUSION_RESISTANT_ARMOR,
    INFUSION_RETURNING_WEAPON,
    INFUSION_SPELL_REFUELING_RING,
    INFUSION_SPELL_REFUELING_RING_2020_02_24,
  } = require('./../infusionIdList')

module.exports = [
  {
    id: INFUSION_BOOTS_OF_THE_WINDING_PATH,
    name: `Ботинки пути ветра`,
    nameAlt: `Сапоги извилистого пути`,
    nameEn: `Boots of the Winding Path`,
    requiredLvl: 6,
    type: 'Пара ботинок',
    attunement: true,
    description: `Существо, носящее эти ботинки, может бонусным действием телепортироваться в видимое им свободное пространство в пределах 15 футов.

Существо уже должно было занимать то пространство в какой-то момент на текущем ходу.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 63,
      },
      {
        id: SOURCE_TCoE,
        page: 23,
      }
    ],
  },
  {
    id: INFUSION_ENHANCED_ARCANE_FOCUS,
    name: `Улучшенная магическая фокусировка`,
    nameEn: `Enhanced Arcane Focus`,
    type: `Волшебная палочка, жезл или посох`,
    attunement: true,
    description: `Существо, держащее этот предмет, получает +1 к атакам заклинаниями и игнорирует укрытие на половину при совершении атак заклинаниями.

Бонус увеличивается до +2, когда Вы достигаете 10 уровня в этом классе.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 63,
      },
      {
        id: SOURCE_TCoE,
        page: 23,
      }
    ],
  },
  {
    id: INFUSION_ENHANCED_DEFENSE,
    name: `Улучшенная защита`,
    nameEn: `Enhanced Defense`,
    type: `Доспех или щит`,
    description: `Существо, носящее (доспех) или несущее (щит) этот предмет, получает +1 КД.

Бонус увеличивается до +2, когда Вы достигаете 10 уровня в этом классе.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 63,
      },
      {
        id: SOURCE_TCoE,
        page: 23,
      }
    ],
  },
  {
    id: INFUSION_ENHANCED_WEAPON,
    name: `Улучшенное оружие`,
    nameEn: `Enhanced Weapon`,
    type: `Простое или воинское оружие`,
    description: `Это магическое оружие имеет бонус +1 к броскам атаки и урона.

Бонус увеличивается до +2, когда Вы достигаете 10 уровня в этом классе.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 63,
      },
      {
        id: SOURCE_TCoE,
        page: 23,
      }
    ],
  },
  {
    id: INFUSION_HOMUNCULUS_SERVANT,
    name: `Слуга-гомункул`,
    nameEn: `Homunculus Servant`,
    requiredLvl: 6,
    creatureId: [
      CREATURE_EXPEDITIOUS_MESSENGER,
      CREATURE_HOMUNCULUS_SERVANT,
      CREATURE_IRON_DEFENDER,
    ],
    type: `Самоцвет или кристалл стоимостью не менее 100 зм`,
    description: `Вы изучаете сложные методы магического создания специального [гомункула](CREATURE:${CREATURE_HOMUNCULUS_SERVANT}), который будет Вам служить. Инфузируемый Вами предмет становится сердцем этого существа, вокруг которого мгновенно формируется его тело.

Вы определяете внешность [гомункула](CREATURE:${CREATURE_HOMUNCULUS_SERVANT}). Некоторые изобретатели предпочитают механически выглядящих птиц, тогда как другим нравятся крылатые пузырьки или маленькие оживлённые котелки.

[Гомункул](CREATURE:${CREATURE_HOMUNCULUS_SERVANT}) дружественен к Вам и Вашим спутникам и подчиняется Вашим командам.

В бою [гомункул](CREATURE:${CREATURE_HOMUNCULUS_SERVANT}) действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Он может двигаться и использовать реакции самостоятельно, но единственное доступное ему действие — Уклонение, если Вы не потратите бонусное действие на своём ходу, приказывая ему совершить Рывок, Отход, Помощь, Засаду, Поиск или одно из действий из его [стаблока](CREATURE:${CREATURE_HOMUNCULUS_SERVANT}). Если Вы недееспособны, [гомункул](CREATURE:${CREATURE_HOMUNCULUS_SERVANT}) может совершать одно любое действие, а не только Уклонение.

Если на [гомункула](CREATURE:${CREATURE_HOMUNCULUS_SERVANT}) сотворить заклинание [Починка](SPELL:${SPELL_MENDING}), он восстановит 2к6 хитов. Если он умирает, то исчезает, оставляя только своё сердце.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 63,
      },
      {
        id: SOURCE_TCoE,
        page: 23,
      }
    ],
  },
  {
    id: INFUSION_RADIANT_WEAPON,
    name: `Светящееся оружие`,
    nameAlt: `Излучающее свет оружие`,
    nameEn: `Radiant Weapon`,
    requiredLvl: 6,
    type: `Простое или воинское оружие`,
    attunement: true,
    description: `Это магическое оружие имеет бонус +1 к броскам атаки и урона. Держа его, носитель может бонусным действием заставить его засиять ярким светом в пределах 30 футов и тусклым светом ещё на 30 футов. Носитель может погасить свет бонусным действием.

У оружия есть 4 заряда. Носитель может реакцией, после того, как по нему попали атакой, потратить 1 заряд и ослепить атакующего до конца его следующего хода, если атакующий не пройдёт испытание Телосложения против Вашей сложности заклинаний. Оружие восстанавливает 1к4 заряда на рассвете.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 63,
      },
      {
        id: SOURCE_TCoE,
        page: 25,
      }
    ],
  },
  {
    id: INFUSION_REPEATING_SHOT,
    name: `Скорострел`,
    nameAlt: `Повторный выстрел`,
    nameEn: `Repeating Shot`,
    type: `Простое или воинское оружие со свойством «Боеприпас»`,
    attunement: true,
    description: `Это магическое оружие имеет бонус +1 к броскам атак и урона дальнобойных атак и игнорирует свойство «Перезарядка», если оно у него было.

Если Вы не заряжаете боеприпасы в это оружие, оно производит собственные, автоматически создавая один магический боеприпас, когда Вы совершаете им дальнобойную атаку. Созданный таким образом боеприпас мгновенно исчезает после попадания в цель или промаха.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 63,
      },
      {
        id: SOURCE_TCoE,
        page: 25,
      }
    ],
  },
  {
    id: INFUSION_REPLICATE_MAGIC_ITEM,
    name: `Реплика магического предмета`,
    nameEn: `Replicate Magic Item`,
    description: `Используя эту инфузию, Вы создаёте реплику определённого магического предмета. Вы можете выучить эту инфузию несколько раз, каждый раз выбирая магический предмет из таблиц «Реплицируемые предметы» ниже. В заголовке таблицы указан минимальный уровень изобретателя, при котором из неё можно выбирать предметы.

В таблице указано — требуется ли настройка на предмет. Более подробная информация — в описании самих магических предметов, в том числе там указан тип предмета, из которого их можно создавать.

Вы можете также выбирать для репликации обычные магические предметы, кроме зелий и свитков.

# Реплицируемые предметы

${replicableMagicItem}`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 64,
      },
      {
        id: SOURCE_TCoE,
        page: 25,
      }
    ],
  },
  {
    id: INFUSION_REPULSION_SHIELD,
    name: `Отталкивающий щит`,
    nameEn: `Repulsion Shield`,
    requiredLvl: 6,
    type: `Щит`,
    attunement: true,
    description: `Существо, несущее этот щит, получает бонус +1 к КД.

Щит имеет 4 заряда. Держа его, носитель может реакцией, сразу после того, как по нему попадут рукопашной атакой, потратить 1 заряд щита и оттолкнуть атакующего на 15 футов.

Щит восстанавливает 1к4 заряда на рассвете.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 64,
      },
      {
        id: SOURCE_TCoE,
        page: 28,
      }
    ],
  },
  {
    id: INFUSION_RESISTANT_ARMOR,
    name: `Броня сопротивления`,
    nameEn: `Resistant Armor`,
    requiredLvl: 6,
    type: `Доспех`,
    attunement: true,
    description: `Нося эту броню, существо получает сопротивление к одному из типов урона, который Вы выбираете, инфузируя этот предмет

* Звук
* Излучение
* Кислота
* Некротический урон
* Огонь
* Психический урон
* Силовое поле
* Холод
* Электричество
* Яд
`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 64,
      },
      {
        id: SOURCE_TCoE,
        page: 28,
      }
    ],
  },
  {
    id: INFUSION_RETURNING_WEAPON,
    name: `Возвращающееся оружие`,
    nameEn: `Returning Weapon`,
    type: `Простое или воинское оружие со свойством «Метательное»`,
    description: `Это магическое оружие имеет бонус +1 к броскам атак и урона и возвращается в руку носителя сразу после совершения дальнобойной атаки.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 64,
      },
      {
        id: SOURCE_TCoE,
        page: 28,
      }
    ],
  },
  {
    id: INFUSION_ARCANE_PROPULSION_ARMOR,
    name: `Движимый магией доспех`,
    nameAlt: `Волшебный ударный доспех`,
    nameEn: `Arcane Propulsion Armor`,
    type: `Доспех`,
    requiredLvl: 14,
    attunement: true,
    description: `Нося _Движимый магией доспех_, существо получает следующие преимущества
 
* Скорость ходьбы носителя увеличивается на 5 футов.
* _Движимый магией доспех_ включает в себя рукавицы, каждая из которых считается магическим рукопашным оружием (пока Вы в ней ничего не держите). Носитель считается владеющим этими рукавицами и каждая из них наносит 1к8 урона звуком при попадании и имеет свойство «Метательное» с нормальной дальностью 20 футов и максимальной дальностью 60 футов. Будучи брошенной, рукавица слетает с руки и летит к цели атаки, после чего немедленно возвращается к носителю и снова надевается ему на руку.
* _Движимый магией доспех_ не может быть снят против воли носителя.
* Если у носителя отсутствуют какие-либо конечности (ладони, руки, ступни, ноги и т. п.), то _Движимый магией доспех_ заменяет их, функционируя так же, как и заменяемая часть тела.`,
    source: {
      id: SOURCE_TCoE,
      page: 22,
    },
  },
  {
    id: INFUSION_ARMOR_OF_MAGICAL_STRENGTH,
    name: `Доспех волшебной силы`,
    nameAlt: `Доспех магической силы`,
    nameEn: `Armor of Magical Strength`,
    type: `Доспех`,
    attunement: true,
    description: `У _Доспеха волшебной силы_ есть 6 зарядов.

Когда носитель совершает проверки или проходит испытания Силы, он может потратить 1 заряд чтобы добавить к броску свой модификатор Интеллекта.

Когда носителя Опрокидывают, он может реакцией потратить 1 заряд и избежать этого.

Доспех восстанавливает 1к6 зарядов на рассвете.`,
    source: {
      id: SOURCE_TCoE,
      page: 22,
    },
  },
  {
    id: INFUSION_ARMOR_OF_MAGICAL_STRENGTH_2020_02_24,
    releasedAs: INFUSION_ARMOR_OF_MAGICAL_STRENGTH,
    name: `Доспех волшебной силы`,
    nameEn: `Armor of Magical Strength`,
    type: `Доспех`,
    requiredLvl: 10,
    attunement: true,
    description: `Нося этот доспех, существо может использовать свой модификатор Интеллекта вместо модификатора Силы, когда совершает проверки и проходит испытания Силы

Доспех имеет 4 заряда. Когда носителя опрокидывают, он может реакцией потратить 1 заряд и не упасть ничком. Доспех восстанавливает 1к4 заряда на рассвете.`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 3,
    },
  },
  {
    id: INFUSION_ARMOR_OF_TOOLS_2020_02_24,
    name: `Доспех инструментов`,
    nameEn: `Armor of Tools`,
    type: `Доспех`,
    description: `Существо, носящее этот доспех, может действием внедрить в него набор ремесленных или [воровских инструментов](GEAR:${GEAR_THIEVES_TOOLS}). Инструменты остаются внедрёнными в доспех на 8 часов или пока носитель действием не изымет их. Доспех может иметь только один набор внедрённый набор инструментов одновременно.

Носитель доспеха может добавлять свой модификатор Интеллекта ко всем проверкам характеристик при использовании внедрённых инструментов. Для использования этих инструментов носитель должен иметь свободную руку.`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 3,
    },
  },
  {
    id: INFUSION_HELM_OF_AWARENESS,
    name: `Шлем бдительности`,
    nameAlt: `Шлем осведомлённости`,
    nameEn: `Helm of Awareness`,
    type: `Шлем`,
    requiredLvl: 10,
    attunement: true,
    description: `Носящее этот шлем существо получает преимущество к проверкам инициативы. Кроме того, носителя нельзя застать врасплох, пока он Дееспособен.`,
    source: [
      {
        id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
        page: 3,
      },
      {
        id: SOURCE_TCoE,
        page: 23,
      }
    ],
  },
  {
    id: INFUSION_MIND_SHARPENER_2020_02_24,
    releasedAs: INFUSION_MIND_SHARPENER,
    name: `Заостритель ума`,
    nameEn: `Mind Sharpener`,
    type: `Доспех или одежда`,
    description: `Этот инфузированное снаряжение может трясти носителя, перефокусируя его разум.

Когда существо, носящее это снаряжение, проваливает испытание Телосложения для поддержания концентрации на заклинании, оно может реакцией сделать это испытание успешным.`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 3,
    },
  },
  {
    id: INFUSION_MIND_SHARPENER,
    name: `Заостритель ума`,
    nameAlt: `Усилитель разума`,
    nameEn: `Mind Sharpener`,
    type: `Доспех или мантия`,
    description: `Этот инфузированное снаряжение может трясти носителя, перефокусируя его разум.

У него есть 4 заряда. Когда носитель проваливает испытание Телосложения для поддержания концентрации на заклинании, он может реакцией потратить 1 заряд и сделать это испытание успешным.

_Заостритель ума_ восстанавливает 1к4 заряда ежедневно на рассвете.`,
    source: {
      id: SOURCE_TCoE,
      page: 25,
    },
  },
  {
    id: INFUSION_SPELL_REFUELING_RING,
    name: `Кольцо заклинательных сил`,
    nameAlt: `Кольцо восстановления заклинаний`,
    nameEn: `Spell-Refueling Ring`,
    type: `Кольцо`,
    requiredLvl: 6,
    attunement: true,
    description: `Носящее это кольцо существо может действием восстановить одну ячейку заклинаний 3 круга или ниже.

После использования, кольцо нельзя использовать снова до следующего рассвета.`,
    source: {
      id: SOURCE_TCoE,
      page: 28,
    },
  },
  {
    id: INFUSION_SPELL_REFUELING_RING_2020_02_24,
    releasedAs: INFUSION_SPELL_REFUELING_RING,
    name: `Кольцо заклинательных сил`,
    nameEn: `Spell-Refueling Ring`,
    type: `Кольцо`,
    requiredLvl: 6,
    attunement: true,
    description: `Носящее это кольцо существо может действием восстановить одну ячейку заклинаний. Максимальный круг ячейки равен числу магических предметов, на которых настроен носитель.

После использования, кольцо нельзя использовать снова до следующего рассвета.`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 3,
    },
  },
]
