const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_SKILL_EMPOWERMENT} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_SKILL_EMPOWERMENT,
  name: 'Усиление навыка',
  nameEn: 'Skill Empowerment',
  description: `Ваша магия углубляет понимание существом собственного таланта. Вы касаетесь одного согласного существа и наделяете его специальными познаниями в одном навыке на Ваш выбор; пока заклинание активно, существо удваивает свой бонус мастерства для всех совершаемых им проверок характеристик, использующих выбранный навык.

Вы должны выбрать навык, которым существо уже владеет и который ещё не получает преимуществ от подобных эффектов, таких как Компетентность (Expertise), удваивающих его бонус мастерства.`,
  lvl: 5,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 1},
  needConcentration: true,
  source: {
    id: SOURCE_XGTE,
    page: 170,
  },
}
