const upLetter = require('./../../../../../../utils/upLetter')
const {creatureTypeCollection} = require('./../../../../../creatureTypeList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MAGIC_ITEM_SCROLL_OF_PROTECTION} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_SCROLL} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')
const list = require('./list')

const tableText = list.reduce(
  (text, {creatureTypeId, diceText, id}) => {
    const {name: {singular}, genderId} = creatureTypeCollection[creatureTypeId]
    const {nominative} = singular[genderId]

    return `${text}
| ${diceText} | [${upLetter(nominative)}](MAGIC_ITEM:${id}) |`
  },
  `
# Виды существ

| к100  | Вид существ |
|-------|-------------|`
)

module.exports = {
  id: MAGIC_ITEM_SCROLL_OF_PROTECTION,
  name: 'Свиток защиты',
  nameEn: 'Scroll of Protection',
  type: MGC_TYPE_SCROLL,
  rarity: MGC_RARITY_RARE,
  isConsumable: true,
  description: `Каждый свиток защиты работает против какого-то одного определённого вида существ, выбранного Мастером самостоятельно или же случайным образом с помощью приведённой ниже таблицы.

${tableText}

Прочитав действием свиток, Вы окружаете себя невидимым барьером, формирующим цилиндр с радиусом 5 футов и высотой 10 футов. В течение 5 минут этот барьер препятствует созданиям определённого вида входить в цилиндр и каким-либо образом воздействовать на то, что заключено в него.

Этот цилиндр перемещается вместе с Вами, оставаясь с центром на Вас. Однако, если Вы перемещаетесь так, что существо указанного вида окажется внутри цилиндра, защитный эффект заканчивается.

Существо может попытаться преодолеть барьер, совершив действием проверку Харизмы СЛ 15. При успехе барьер на это конкретное существо не действует.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 200,
  },
}
