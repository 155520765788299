module.exports = [
  ...require('./_gearRawList_s'),
  require('./scimitar'),
  require('./shatterstick'),
  require('./shortbow'),
  require('./shortsword'),
  require('./shotgun'),
  require('./sickle'),
  require('./sling'),
  require('./spear'),
  require('./staff'),
]
