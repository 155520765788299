const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_STAFF} = require('./../../../../magicItemTypeList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {SOURCE_WDH} = require('./../../../../sourceList')
const {SPELL_DISPEL_MAGIC} = require('./../../../../spellIdList')
const {
  CREATURE_VAJRA_SAFAHR,
  CREATURE_WALKING_STATUE_OF_WATERDEEP,
} = require('./../../../../creatureIdList')
const {
  MAGIC_ITEM_BLACKSTAFF,
  MAGIC_ITEM_STAFF_OF_POWER,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_BLACKSTAFF,
  name: 'Чёрный посох',
  nameEn: 'Blackstaff',
  type: MGC_TYPE_STAFF,
  gearType: GEAR_QUARTERSTAFF,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `наследника Чёрного Посоха, который должен быть волшебником`,
  description: [
    `_Чёрный посох_ — это разумный посох, покрытый рунами и серебристыми прожилками. Это символ власти [Чёрного Посоха](CREATURE:${CREATURE_VAJRA_SAFAHR}), главного [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) Глубоководья. Сейчас [Ваджра Сафар](CREATURE:${CREATURE_VAJRA_SAFAHR}) — законная владелица _посоха_, и только она может настроиться на него. Однако _посох_ может и сам выбрать нового владельца.

У _Чёрного посоха_ есть все магические свойства [Посоха могущества](MAGIC_ITEM:${MAGIC_ITEM_STAFF_OF_POWER}) в придачу к следующим:`,
    {
      header: 'Вытягивание магии',
      text: `Это свойство действует только на существ, использующих ячейки заклинаний. Попав по такому существу рукопашной атакой _Чёрным посохом_, Вы можете бонусным действием потратить 1 заряд _посоха_, чтобы заставить существо потратить 1 ячейку заклинания наивысшего доступного ему круга, не сотворяя при этом заклинания. Если цель уже потратила все свои ячейки, ничего не произойдёт. Цель восстанавливает потраченные таким образом ячейки обычным для неё способом.`,
      source: {
        id: SOURCE_WDH,
        page: 192,
      },
    },
    {
      header: 'Мастер очарования',
      text: `Когда Вы, держа _Чёрный посох_, сотворяете заклинание очарования 1-го круга или выше, Вы можете пройти проверку Интеллекта (Магия) СЛ 10 + круг заклинания. При успехе Вы сотворяете это заклинание без траты ячейки.`,
      source: {
        id: SOURCE_WDH,
        page: 192,
      },
    },
    {
      header: 'Оживление ходячих статуй',
      text: `Вы можете действием потратить 1 или более зарядов _посоха_, чтобы оживить или отключить несколько [ходячих статуй Глубоководья](CREATURE:${CREATURE_WALKING_STATUE_OF_WATERDEEP}). Чтобы использовать это свойство, Вам нужно находиться в городе. За каждый потраченный заряд Вы можете оживить или отключить одну [статую](CREATURE:${CREATURE_WALKING_STATUE_OF_WATERDEEP}). Ожившая [статуя](CREATURE:${CREATURE_WALKING_STATUE_OF_WATERDEEP}) повинуется телепатическим приказам духа Келбена Эрансана, который заключён в _посохе_. Ходячая статуя становится неживой, если её отключить или сломать _посох_.`,
      source: {
        id: SOURCE_WDH,
        page: 192,
      },
    },
    {
      header: 'Рассеивание магии',
      text: `Вы можете бонусным действием потратить 1 заряд _посоха_, чтобы сотворить [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) на существо, предмет или магический эффект, которого касаетесь навершием _посоха_. Если цель — несогласное существо или предмет в распоряжении такого существа, Вам сначала нужно попасть по этому существу рукопашной атакой _Чёрным посохом_.`,
      source: {
        id: SOURCE_WDH,
        page: 192,
      },
    },
    {
      header: 'Разумность',
      text: `_Чёрный посох_ — разумный посох с нейтральным мировоззрением, у него Интеллект 22, Мудрость 15 и Харизма 18. У него есть слух и ночное зрение в пределах 120 футов. Он может телепатически общаться с существом, которое его держит.`,
      source: {
        id: SOURCE_WDH,
        page: 192,
      },
    },
    {
      header: 'Личность',
      text: `В _посохе_ заперты духи всех предыдущих [Чёрных Посохов](CREATURE:${CREATURE_VAJRA_SAFAHR}). Преобладает среди них личность создателя _посоха_ Келбена Эрансана. Подобно Келбену, _посох_ крайне коварен и любит манипулировать другими. Он старается давать владельцу советы, а не подчинять его напрямую. Главная цель _посоха_ — защитить Глубоководье и Явного лорда, в данном случае Лейраль Среброрукую. Вторая его цель — помочь носителю стать могущественнее.

Если вдруг тот, кто занимает должность [Чёрного Посоха](CREATURE:${CREATURE_VAJRA_SAFAHR}), перестанет удовлетворять желаниям _посоха_, тот перестанет работать, пока не найдёт достойного преемника — того, чья преданность Глубоководью вне сомнений.`,
      source: {
        id: SOURCE_WDH,
        page: 192,
      },
    },
    {
      header: 'Ловушка духов',
      text: `Когда [Чёрный Посох](CREATURE:${CREATURE_VAJRA_SAFAHR}) умирает, его дух оказывается заточён в _посохе_ рядом с духами предыдущих [Чёрных Посохов](CREATURE:${CREATURE_VAJRA_SAFAHR}) (пока дух [Чёрного Посоха](CREATURE:${CREATURE_VAJRA_SAFAHR}) заперт в _посохе_, его нельзя вернуть к жизни.

Если уничтожить _посох_, это высвободит заточённых в нём духов, но в таком случае дух Келбена способен остаться в любой уцелевшей части _посоха_. Эта часть _посоха_ с духом Келбена сохраняет свойство Разумность, но теряет все остальные. Пока этот фрагмент  существует, дух Келбена может по желанию воссоздать _посох_ снова. Когда _посох_ восстановят, духи прежних [Чёрных Посохов](CREATURE:${CREATURE_VAJRA_SAFAHR}) снова окажутся в нём заперты.`,
      source: {
        id: SOURCE_WDH,
        page: 192,
      },
    },
  ],
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_WDH,
    page: 192,
  },
}
