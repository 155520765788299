const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_LYCANTHROPE,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ABILITY_CHARGE,
  ABILITY_RELENTLESS,
} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_WEREBOAR} = require('./../../../../creatureIdList')
const {GEAR_MAUL} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {lycantropDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Вервепрь',
  nameAlt: 'Веркабан',
  nameEn: 'Wereboar',
  description: [
    `Вервепри — грубые верзилы с дурным нравом. В своём гуманоидном облике они выглядят коренастыми и мускулистыми, с короткими, жёсткими волосами. В гуманоидном и гибридном облике они пользуются тяжёлым оружием, но в гибридном и животном облике они получают разрушительную атаку клыками, через которую они распространяют своё проклятье. Вервепрь заражает других существ без разбору, наслаждаясь тем, что чем дольше те сдерживают своё проклятье, тем больше они дичают и уподобляются животным.

Вервепри живут маленькими семейными группами в отдалённых лесах, строя ветхие хижины или обитая в пещерах. Они с подозрением относятся к чужакам, но иногда объединяются с орками.`,
    ...lycantropDescription,
  ],
  id: CREATURE_WEREBOAR,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_LYCANTHROPE,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 190,
  },
  armor: [
    {
      ac: 10,
      comment: 'в облике гуманоида',
    },
    {
      ac: 11,
      type: ARMOR_TYPE_NATURAL,
      comment: 'в облике кабана и гибридном облике',
    },
  ],
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: {
      value: 30,
      comment: '40 фт. в облике кабана',
    },
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 15,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  languageList: [
    {
      id: LANG_COMMON,
      comment: 'не может говорить в облике кабана',
    },
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в кабана, гибрид кабана и гуманоида, или же принять свой истинный облик гуманоида. Все его статистики кроме КД остаются одинаковыми во всех обликах. Всё несомое и носимое им снаряжение не превращается. Он принимает свой истинный облик, если умирает.`,
    },
    {
      id: ABILITY_CHARGE,
      comment: 'только в облике кабана или гибрида',
      range: 15,
      attackName: 'Клыками',
      damageText: '7 (2к6) колющего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 13, иначе будет сбита с ног.',
    },
    {
      id: ABILITY_RELENTLESS,
      damage: 14,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в облике гуманоида или гибрида',
      description: `★СУЩЕСТВО★ совершает две атаки, только одна из которых может быть атакой _Клыками_.`,
    },
    {
      gearId: GEAR_MAUL,
      comment: 'только в облике гуманоида или гибрида',
    },
    {
      name: 'Клыки',
      comment: 'только в облике кабана или гибрида',
      description: `Если цель — гуманоид, она должна пройти испытание Телосложения СЛ 12, иначе станет проклятой ликантропией вервепря.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
