const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_1,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_PLANT,
  CREATURE_TYPE_VEGEPYGMY,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_ELECTRICITY,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_THORNY,
} = require('./../../../../creatureIdList')
const {
  ABILITY_PLANT_CAMOUFLAGE,
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  moldCreaturesDescriptionList,
  moldCreaturesNote,
} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Колючка',
  nameEn: 'Thorny',
  id: CREATURE_THORNY,
  description: moldCreaturesDescriptionList,
  note: moldCreaturesNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
    CREATURE_TYPE_VEGEPYGMY,
  ],
  alignmentId: ALIGNMENT_N,
  source: [
    {
      id: SOURCE_VGTM,
      page: 133,
    },
    {
      id: SOURCE_TOA,
      page: 232,
    },
  ],
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_PIERCING,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_1,
  featureList: [
    ABILITY_PLANT_CAMOUFLAGE,
    {
      id: ABILITY_REGENERATION,
      regen: 5,
      description: `. Если ★он★ получает урон холодом, огнём или некротический урон, то эта особенность не работает в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только если начинает ход с 0 хитов и не может регенерировать.`,
    },
    {
      name: 'Шипастое тело',
      description: `В начале каждого своего хода ★СУЩЕСТВО★ причиняет колющий урон 2 (1к4) всем схватившим ★его★ существам.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
