const {
  CREATURE_POLTERGEIST,
  CREATURE_SPECTER,
} = require('./../../../../creatureIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_FORCE} = require('./../../../../damageTypeList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Полтергейст',
  nameEn: 'Poltergeist',
  id: CREATURE_POLTERGEIST,
  parentId: CREATURE_SPECTER,
  cr: CR_2,
  actionList: [
    {
      name: `Силовой удар`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_FORCE,
          diceCount: 3,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
    {
      name: `Телекинетический рывок`,
      description: `★СУЩЕСТВО★ нацеливается на существо или никому не принадлежащий предмет в пределах 30 футов. Размер существа должен быть не больше Среднего, а предмет не должен весить больше 150 фунтов.

Если цель — существо, полтергейст совершает проверку Харизмы, противопоставленную проверке Силы цели. Если состязание выигрывает полтергейст, он отбрасывает цель на расстояние до 30 футов в любом направлении, включая вверх. Если цель после этого ударится о твёрдую поверхность или предмет, она получает урон 1к6 за каждые 10 футов перемещения.

Если цель — предмет, который никто не несёт и не носит, полтергейст отбрасывает его на расстояние до 30 футов в любом направлении. Полтергейст может использовать этот предмет как дальнобойное оружие, атакуя одно существо по траектории предмета (+4 к попаданию) и причиняя при попадании дробящий урон 5 (2к4).`,
    },
  ],
  extendPropCollection: {
    featureList: [
      {
        name: `Невидимость`,
        description: `Полтергейст невидим.`,
      },
    ],
    description: [
      {
        header: `Вариант: Полтергейст`,
        text: `Полтергейст это разновидность [спектра](CREATURE:${CREATURE_SPECTER}) — сбитый с толку невидимый дух того, кто не понимает, как умер. Полтергейст выражает свою ярость, разбрасывая существ и предметы силой расшатанной психики.

У полтергейста показатель опасности 2 (450 опыта), и он обладает дополнительной особенностью:

# Невидимость

Полтергейст невидим.

Полтергейст обладает следующими вариантами действий вместо _Вытягивания жизни_:

# Силовой удар

Рукопашная атака оружием: +4 к попаданию, досягаемость 5 фт., одно существо. Попадание: Урон силовым полем 10 (3к6).

# Телекинетический рывок

Полтергейст нацеливается на существо или никому не принадлежащий предмет в пределах 30 футов. Размер существа должен быть не больше Среднего, а предмет не должен весить больше 150 фунтов.

Если цель — существо, полтергейст совершает проверку Харизмы, противопоставленную проверке Силы цели. Если состязание выигрывает полтергейст, он отбрасывает цель на расстояние до 30 футов в любом направлении, включая вверх. Если цель после этого ударится о твёрдую поверхность или предмет, она получает урон 1к6 за каждые 10 футов перемещения.

Если цель — предмет, который никто не несёт и не носит, полтергейст отбрасывает его на расстояние до 30 футов в любом направлении. Полтергейст может использовать этот предмет как дальнобойное оружие, атакуя одно существо по траектории предмета (+4 к попаданию) и причиняя при попадании дробящий урон 5 (2к4).`,
      },
    ],
  },
}
