const {SOURCE_MM} = require('./../../sourceList')
const {GOD_TIAMAT} = require('./../../godIdList')
const {
  CREATURE_DRAGON_BLACK_ADULT,
  CREATURE_DRAGON_BLUE_ADULT,
  CREATURE_DRAGON_GREEN_ADULT,
  CREATURE_DRAGON_RED_ADULT,
  CREATURE_DRAGON_WHITE_ADULT,
} = require('./../../creatureIdList')

module.exports = {
  header: 'Королева Злых Драконов',
  text: `[Тиамат](GOD:${GOD_TIAMAT}), Королева Драконов, главенствует среди божеств злых драконов. Она обитает на Аверно, первом слое Девяти Преисподних. Являясь младшим божеством, Тиамат может даровать заклинания своим последователям, хотя и не любит делиться силами. Она олицетворяет тягу ко злу всех драконов, полагая, что мультивселенная и все её сокровища будут однажды принадлежать ей одной.

Тиамат представляет собой гигантскую драконицу, пять голов которой отражают виды драконов, поклоняющихся ей — [белых](CREATURE:${CREATURE_DRAGON_WHITE_ADULT}), [зелёных](CREATURE:${CREATURE_DRAGON_GREEN_ADULT}), [синих](CREATURE:${CREATURE_DRAGON_BLUE_ADULT}), [красных](CREATURE:${CREATURE_DRAGON_RED_ADULT}) и [чёрных](CREATURE:${CREATURE_DRAGON_BLACK_ADULT}). Она — ужас на поле боя, способная уничтожить целые армии дыханием пяти голов, мощью колдовства и страшными когтями.`,
  source: {
    id: SOURCE_MM,
    page: 102,
  },
}
