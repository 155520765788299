const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_GRIMLOCK} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_GRIMLOCK,
  nameEn: 'Grimlock',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'гримлок',
      genitive: 'гримлока',
      dative: 'гримлоку',
      accusative: 'гримлока',
      instrumental: 'гримлоком',
      prepositional: 'гримлоке',
    },
    plural: {
      nominative: 'гримлоки',
      genitive: 'гримлоков',
      dative: 'гримлокам',
      accusative: 'гримлоков',
      instrumental: 'гримлоками',
      prepositional: 'гримлоках',
    },
  },
}
