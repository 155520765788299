const {
  CREATURE_DEER,
  CREATURE_GIANT_BADGER,
  CREATURE_GIANT_HYENA,
  CREATURE_GNOLL,
  CREATURE_KNIGHT,
  CREATURE_LEUCROTTA,
  CREATURE_YEENOGHU,
} = require('./../../../../creatureIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  LANG_ABYSSAL,
  LANG_GNOLL,
} = require('./../../../../languageIdList')
const {
  ABILITY_KEEN_SMELL,
  ABILITY_RAMPAGE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GEAR_PLATE_ARMOR} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Лекротта',
  nameEn: 'Leucrotta',
  id: CREATURE_LEUCROTTA,
  description: [
    `Лекротта — это то, что получилось бы, если взять голову [гигантского барсука](CREATURE:${CREATURE_GIANT_BADGER}), мозг кого-нибудь, обожающего мучить и есть людей, ноги [оленя](CREATURE:${CREATURE_DEER}), тело [большой гиены](CREATURE:${CREATURE_GIANT_HYENA}), слепить всё это вместе и оживить с помощью демонической сукровицы, при этом не заботясь об удалении трупной вони.`,
    {
      header: 'Порождение Йеногу',
      text: `Первые лекротты появились вместе с [гноллами](CREATURE:${CREATURE_GNOLL}) во время зверств [Йеногу](CREATURE:${CREATURE_YEENOGHU}) на Материальном плане. Некоторые гиены, съевшие жертв [Йеногу](CREATURE:${CREATURE_YEENOGHU}), подверглись различным трансформациям, а не превратились в гноллов. Среди этих странных существ лекротты были наиболее многочисленными.

Такие же хитрые, как и жестокие, лекротты обожают обманывать, мучить и убивать. Так как лекротты умнее и сильнее большинства гноллов, они могут занимать одну из верховенствующих позиций в гнолльем племени. Хотя лекротта вряд ли возглавит группу гноллов, она может влиять на их лидера, и она может даже согласиться нести его в бой или давать советы в бою.

Гноллы также считают лекротт формой развлечения, частично потому, что лекротты могут подражать визгу страдающей жертвы — звуку, который всегда приятен гноллам, даже когда жертвы поблизости нет. Кроме того, гноллы кровожадные садисты, но по своей природе не способны продлить сладкие мгновения убийства. Большинство лекротт сознательно жестоки до такой степени, что тщательно соизмеряют усилия, с которыми нападают на жертву, чтобы подольше насладиться актом убийства и сделать его поинтереснее. Гноллам нравится следить за работой лекротты почти на столько же, на сколько им нравится убивать самим.`,
      source: {
        id: SOURCE_VGTM,
        page: 166,
      },
    },
    {
      header: 'Воплощение отвратности',
      text: `Лекротты настолько мерзки, что только гноллы и другие лекротты могут находиться рядом с ними долгое время. Её ужасающее, разномастное тело источает мерзейший запах, который пронизывает всё в месте, где лекротта устраивает себе логово. Этот смрад перебивает только дыхание существа, исходящее из пасти, из которой капает смесь пищеварительных соков и гнили. Вместо клыков у лекротты костные гребни, твёрдые, как сталь, которыми она легко ломает кости и рвет плоть. Эти пластины так тверды, что лекротта может сорвать ими [латный доспех](GEAR:${GEAR_PLATE_ARMOR}) с тела павшего [рыцаря](CREATURE:${CREATURE_KNIGHT}).

Вонь лекротты обычно отпугнет её добычу задолго до того, как существо сможет атаковать. Но при этом у неё есть две природные способности, дающие ей некоторые преимущества. Во-первых, следы лекротты почти невозможно отличить от следов обычного [оленя](CREATURE:${CREATURE_DEER}). Во-вторых, она может изобразить зов или голосовые выражения любого существа, которое она слышала. Монстр использует эту мимикрию, чтобы завлечь потенциальную жертву, а затем атаковать, пока она сконфужена или не осознает истинной угрозы.`,
      source: {
        id: SOURCE_VGTM,
        page: 166,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 166,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 15,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_GNOLL,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_KEEN_SMELL,
    {
      name: 'Отталкивание',
      description: `Если лекротта атакует своими копытами, то может сделать Выход из Боя бонусным действием.`,
    },
    {
      name: 'Мимикрия',
      description: `Лекротта может подражать звукам животных и голосам гуманоидов. Существо, слышащее эти звуки, может сказать что это имитация при успешной проверке Мудрости (Проницательность) СЛ 14.`,
    },
    ABILITY_RAMPAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: ` Лекротта делает две атаки: одну _Укусом_ и одну _Копытами_.`,
    },
    {
      name: 'Укус',
      description: `Если лекротта делает критическое попадание, то бросает кость урона трижды, а не дважды.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
