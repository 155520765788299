const gameSetDescription = `Владение игровым набором применяется к одному виду игры, например, к ставке трёх драконов или к азартным играм, использующим кости.

**Компоненты.** Игровой набор включает в себя всё необходимое для конкретной игры или группы игр, как, например, полная колода карт или доска и фигуры.

**История.** Ваше мастерство в игре включает в себя знания о её истории, а также знания о важных событиях и известных исторических фигурах, связанных с игрой.

**Проницательность.** Сыграть с кем-то — это отличный способ понять его характер и что он из себя представляет, что в свою очередь позволяет вам лучше распознавать ложь и определять его настроение.

**Ловкость рук.** Ловкость рук — это полезный навык для мухлежа в игре, поскольку позволяет вам менять местами фигуры, прятать карты или менять неудачный результат броска кости. С другой стороны, увлечь цель игрой, требующей совершения ловких движений — хорошее отвлечение при карманной краже.

| Действие                  | СЛ |
|---------------------------|----|
| Поймать игрока на мухлеже | 15 |
| Понять характер оппонента | 15 |
`

module.exports = gameSetDescription
