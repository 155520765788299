import React from 'react'
import PropTypes from 'prop-types'
import {
    FILTER_TYPE_GROUP,
    FILTER_TYPE_INPUT,
    FILTER_TYPE_SELECT,
} from '@/constants/filterTypes'

import Filter from './components/Filter'

import './FilterListStyles.less'

const filterTypeOrderedList = [
    FILTER_TYPE_GROUP,
    FILTER_TYPE_SELECT,
    FILTER_TYPE_INPUT,
]

const FilterListComponent = ({filterList, ...rest}) => {
  const filterBlockList = filterTypeOrderedList.map(
    type => ({
      type,
      list: filterList
        .map(
          filter => filter.children
            ? {
              ...filter,
              type: FILTER_TYPE_GROUP,
            }
            : filter
        )
        .filter(
          filter => filter.type === type
        ),
    })
  )

  return (
    <ul className='FilterList'>
      {
        filterBlockList.map(
          ({type, list}) => (
            <li
              className='FilterList_item'
              key={type}
            >
              <ul className='FilterList_blockList'>
                {
                  list.map(
                    (filter, i) => (
                      <li
                        className={`FilterList_blockItem FilterList_blockItem-${type}`}
                        key={
                          filter.children
                            ? `group_${i}`
                            : `${filter.propName}_${i}`
                        }
                      >
                        {
                          filter.children
                            ? (
                              <fieldset className='FilterList_fieldset'>
                                <legend className='FilterList_legend'>{filter.header}</legend>
                                <ul className='FilterList_fieldsetList'>
                                  {
                                    filter.children.map(
                                      (filterItem, j) => (
                                        <li key={`${filterItem.propName}_${j}`}>
                                          <Filter
                                            className='FilterList_fieldsetItem'
                                            id={filterItem.propName}
                                            {...filterItem}
                                            {...rest}
                                          />
                                        </li>
                                      )
                                    )
                                  }
                                </ul>
                             </fieldset>
                            )
                            : (
                              <Filter
                                id={filter.propName}
                                {...filter}
                                {...rest}
                              />
                            )
                        }
                      </li>
                    )
                  )
                }
              </ul>
            </li>
          )
        )
      }
    </ul>
  )
}

FilterListComponent.defaultProps = {
  filterList: [],
}

FilterListComponent.propTypes = {
  filterList: PropTypes.array,
}

export default FilterListComponent
