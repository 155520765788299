const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ABILITY_KEEN_SMELL,
  ABILITY_SWARM,
} = require('./../../../../creatureAbilityList')
const {
  SIZE_MEDIUM,
  SIZE_TINY,
} = require('./../../../../sizeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_SWARM_OF_RATS} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {swarmDescription} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Рой крыс',
  nameAlt: 'Стая крыс',
  nameEn: 'Swarm of Rats',
  id: CREATURE_SWARM_OF_RATS,
  description: swarmDescription,
  sizeType: SIZE_MEDIUM,
  swarmMemberSizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 339,
  },
  armor: 10,
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 9,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  resistanceList: [
    DAMAGE_BLUDGEONING,
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  ],
  immunityConditionList: [
    CONDITION_GRAPPLED,
    CONDITION_FRIGHTENED,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_CHARMED,
    CONDITION_STUNNED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_KEEN_SMELL,
    ABILITY_SWARM,
  ],
  actionList: [
    {
      name: 'Укусы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 0,
        target: {
          count: 1,
          limit: {
            comment: ` в пространстве ★СУЩЕСТВО★`,
          },
        },
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 2,
              diceType: 6,
              diceBonus: 0,
            },
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 6,
              diceBonus: 0,
              comment: `, если ★СУЩЕСТВО★ имеет половину хитов или меньше`,
            },
          ],
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
