const {
  SOURCE_GGTR,
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE,
  SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
  SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
  SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
  SOURCE_UA_2022_05_22_GIANT_OPTIONS,
  SOURCE_XGTE,
} = require('./../../sourceList')

const {PC_CLASS_DRUID} = require('./../../pcClassIdList')

const {
  PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS,
  PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS_2016_11_28,
  PC_SUBCLASS_DRUID_CIRCLE_OF_SPORES,
  PC_SUBCLASS_DRUID_CIRCLE_OF_STARS,
  PC_SUBCLASS_DRUID_CIRCLE_OF_STARS_2020_02_24,
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND,
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON,
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_PRIMEVAL_2022_05_22,
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD,
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD_2016_11_28,
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD_2017_06_05,
  PC_SUBCLASS_DRUID_CIRCLE_OF_TWILIGHT_2016_11_28,
  PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE,
  PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE_2019_10_03,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS,
    pcClassId: PC_CLASS_DRUID,
    isReady: true,
    name: 'Круг снов',
    nameEn: 'Circle of Dreams',
    description: `Друиды, являющиеся членами круга снов, родом из регионов, имеющих особенно сильную связь со Страной Фей и её сказочной реальностью.

Их охрана природного мира создает альянс между ними и феями с добрым мировоззрением. Эти друиды стремятся наполнить мир сказочными чудесами из снов. Их магия исцеляет раны и приносит радость подавленным сердцам. Священные земли, которые они оберегают — это мерцающие, плодородные места, где сон и реальность сливаются вместе, а уставшие могут найти покой.`,
    note: {
      text: `Я не вижу сны, потому что я не сплю. Я всегда бодрствую, поэтому никто никогда не сможет подкрасться ко мне. Если я и вижу сны, то они ярче и больше твоих, хотя бы потому, что моя голова больше твоей.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 29,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS_2016_11_28,
    pcClassId: PC_CLASS_DRUID,
    releasedAs: PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS,
    isReady: false,
    name: 'Круг снов',
    nameEn: 'Circle of Dreams',
    source: {
      id: SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_SPORES,
    pcClassId: PC_CLASS_DRUID,
    isReady: true,
    name: 'Круг спор',
    nameEn: 'Circle of Spores',
    description: `Друиды Круга Спор находят красоту в гниении. Они видят в плесени и других грибах способность превращать безжизненную материю в изобильную, хотя и несколько странную, жизнь.

Эти друиды верят, что жизнь и смерть — это части большого цикла, где одно следует за другим и всё начинается заново. Смерть — это не конец жизни, а изменение состояния, при котором жизнь переходит в новую форму.

Друиды этого круга имеют сложные отношения с нежитью. В отличие от большинства других друидов, они не видят ничего плохого в нежити, и считают её спутником как жизни, так и смерти. Но эти друиды считают, что цикл жизни и смерти становится только здоровее, когда каждый его участник движется и меняется. Та нежить, что стремится заменить всю жизнь нежизнью или пытается избежать перехода в смерть, нарушает этот цикл и её должно помешать.`,
    note: {
      text: `Я заядлая коллекционерша спор, мха и грибов. Мой самый ценный экземпляр — зуггтмата разумная из-под горы Зогон. Хотя я бы не хотела, чтобы она оживила труп и трогала мои вещи.`,
      author: `Таша`,
    },
    source: [
      {
        id: SOURCE_GGTR,
        page: 27,
      },
      {
        id: SOURCE_TCoE,
        page: 51,
      },
    ],
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_STARS_2020_02_24,
    pcClassId: PC_CLASS_DRUID,
    releasedAs: PC_SUBCLASS_DRUID_CIRCLE_OF_STARS,
    isReady: false,
    name: 'Круг звёзд',
    nameEn: 'Circle of Stars',
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_STARS,
    pcClassId: PC_CLASS_DRUID,
    isReady: true,
    name: 'Круг звёзд',
    nameEn: 'Circle of Stars',
    description: `Круг звезд позволяет друидам черпать силу звёздного света. Эти друиды с незапамятных времён отслеживают звёздные узоры, открывая секреты, спрятанные среди созвездий. Раскрывая и понимая эти секреты, члены круга звёзд стремятся использовать силы космоса.

Многие друиды этого круга записывают созвездия и влияние звёзд на мир. Некоторые группы документируют эти наблюдения на мегалитах, которые служат им библиотеками тайных знаний. Такие хранилища могут иметь форму каменных кругов, пирамид, петроглифов и подземных храмов - любой конструкции, достаточно прочной, чтобы защитить священные знания круга даже от великого катаклизма.`,
    note: {
      text: `А что насчёт тёмных областей между звёздами? Вы что, не понимаете, что именно там и находятся все хорошие штуки?`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 53,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND,
    pcClassId: PC_CLASS_DRUID,
    isReady: true,
    name: 'Круг Земли',
    nameEn: 'Circle of the Land',
    description: `Круг Земли состоит из мистиков и мудрецов, поколениями передающих из уст в уста сложную систему древних знаний и обрядов.

Собрания проводятся в кольце деревьев или мегалитов, где на тайном языке звучат секреты самого мироздания. Мудрейшие представители круга служат духовными лидерами и советниками вождей в общинах, проповедующих Старую Веру.

Причастность к кругу Земли наделяет магию друида силой тех мест, где персонажа посвятили в священные таинства.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 68,
      },
      {
        id: SOURCE_SRD,
        page: 21,
      },
    ],
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON,
    pcClassId: PC_CLASS_DRUID,
    isReady: true,
    name: 'Круг Луны',
    nameEn: 'Circle of the Moon',
    description: `Друиды круга Луны — непримиримые стражи дикой природы. Их собрания проходят под полной луной для обмена новостями и предостережениями. Стражи рыщут в самой дремучей глуши, где можно неделями не встретить другого гуманоида, не говоря уже о друиде.

Переменчивые как сама луна и дикие до глубины души, друиды этого круга то крадутся кошкой, то парят орлом, то ломятся в облике медведя, устрашая забредшее чудище.`,
    source: {
      id: SOURCE_PHB,
      page: 70,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD,
    pcClassId: PC_CLASS_DRUID,
    isReady: true,
    name: 'Круг Пастыря',
    nameEn: 'Circle of the Shepherd',
    description: `Друиды Круга Пастырей общаются с духами природы, особенно с духами зверей и фей, и взывают к этим духам за помощью. Эти друиды признают, что все живые существа играют роль в естественном мире, но они сосредоточены на защите животных и фей, которым трудно защитить себя. Известные как Пастыри, они считают таких существ своими подзащитными. Они отражают атаки чудовищ, которые угрожают им, отгоняют охотников, которые убивают больше добычи, чем необходимо, не позволяют цивилизации вторгаться в среду обитания редких животных и священные места фей. Многие из этих друидов счастливы вдали от больших и маленьких городов, довольствуясь тем, что проводят свои дни в обществе животных и фей из дикой природы.

Члены этого круга становятся авантюристами, чтобы противостоять силам, которые угрожают их подзащитным, или искать знания и силу, которые помогут им лучше охранять своих подопечных. Везде, куда бы ни пошли эти друиды, духи дикой природы следуют за ними.`,
    source: {
      id: SOURCE_XGTE,
      page: 28,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD_2016_11_28,
    pcClassId: PC_CLASS_DRUID,
    releasedAs: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD,
    isReady: false,
    name: 'Круг Пастыря',
    nameEn: 'Circle of the Shepherd',
    source: {
      id: SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD_2017_06_05,
    pcClassId: PC_CLASS_DRUID,
    releasedAs: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD,
    isReady: false,
    name: 'Круг Пастыря',
    nameEn: 'Circle of the Shepherd',
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_TWILIGHT_2016_11_28,
    pcClassId: PC_CLASS_DRUID,
    isReady: false,
    name: 'Круг сумрака',
    nameEn: 'Circle of Twilight',
    source: {
      id: SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE_2019_10_03,
    pcClassId: PC_CLASS_DRUID,
    releasedAs: PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE,
    isReady: false,
    name: 'Круг лесного пожара',
    nameEn: 'Circle of Wildfire',
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE,
    pcClassId: PC_CLASS_DRUID,
    isReady: true,
    name: 'Круг лесного пожара',
    nameEn: 'Circle of Wildfire',
    note: {
      text: `Я даже не смогу сосчитать, сколько раз я сжигала всё дотла и начинала заново`,
      author: `Таша`,
    },
    description: `Друиды круга Лесного пожара понимают, что разрушение иногда является предвестником творения, например, когда лесной пожар способствует росту новых растений.

Эти друиды связаны с первобытным духом, который таит в себе как разрушительную, так и созидательную силу, позволяя друидам создавать контролируемое пламя, которое сжигает одно, но даёт жизнь другому.`,
    source: {
      id: SOURCE_TCoE,
      page: 56,
    },
  },
  {
    id: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_PRIMEVAL_2022_05_22,
    pcClassId: PC_CLASS_DRUID,
    isReady: false,
    name: 'Круг Первобытного',
    nameEn: 'Circle of the Primeval',
    description: `The Circle of the Primeval teaches that, though the land may change over time, it never truly forgets. By tapping into the timeworn memory of the earth, these druids summon and bond with the spirit of a primeval behemoth—a hulking creature that once ruled the ancient world alongside the giants. The most well-known primeval behemoths are the dinosaurs, but spirits bonded with members of the Circle of the Primeval have also taken the form of ancient predecessors to today’s common beasts and other fantastical titanic creatures.

Working alongside their companion spirit, most druids of the Circle of the Primeval spend their lives delving into long-forgotten places and preserving the remnants of bygone eras. As a druid’s power grows, so does their companion, the beast starting near equal in size to its druid partner before increasing in stature until it too towers over the land.`,
    source: {
      id: SOURCE_UA_2022_05_22_GIANT_OPTIONS,
      page: 2,
    },
  },
]
