const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_KEEN_SIGHT,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_8} = require('./../../../../crList')
const {CREATURE_BLOOD_HAWK} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Кровавый ястреб',
  nameEn: 'Blood Hawk',
  id: CREATURE_BLOOD_HAWK,
  description: `Получивший своё название за алые перья и агрессивный характер, кровавый ястреб бесстрашно нападает практически на всех животных, орудуя похожим на кинжал клювом. Кровавые ястребы нападают на добычу большими стаями.`,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 331,
  },
  armor: 12,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  cr: CR_1_8,
  featureList: [
    ABILITY_KEEN_SIGHT,
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
