const {
  TIME_HOUR,
  TIME_INSTANT,
} = require('./../../../../../timePeriodList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_CONTROL_FLAMES} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_CONTROL_FLAMES,
  name: 'Власть над огнём',
  nameEn: 'Control Flames',
  description: `Вы выбираете немагическое пламя, которое Вы можете видеть в пределах дистанции и которое помещается в 5-футовый куб. Вы воздействуете на него одним из следующих способов:

* Вы мгновенно распространяете пламя на 5 футов в одном направлении, при условии, что там есть дерево или другой горючий материал.
* Вы мгновенно гасите огонь в кубе.
* Вы в два раза увеличиваете или уменьшаете области яркого света и тусклого света, отбрасываемого пламенем, изменяете его цвет, или и то, и другое. Изменение длится 1 час.
* Вы вызываете в пламени простые образы, такие как расплывчатое изображение существа, неодушевленного предмета или места, которые движутся по Вашему желанию. Образы существуют 1 час. 

Если Вы сотворяете это заклинание несколько раз, одновременно Вы можете поддерживать до трех его немгновенных эффектов, и Вы можете действием отменить такой эффект.`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 60,
  componentIdList: [CAST_SOMATIC],
  duration: [TIME_INSTANT, {timeId: TIME_HOUR, count: 1}],
  source: [
    {
      id: SOURCE_XGTE,
      page: 149,
    },
    {
      id: SOURCE_EE,
      page: 15,
    },
  ],
}
