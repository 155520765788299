const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {
  GOD_CORELLON_LARETHIAN,
  GOD_GRUUMSH,
} = require('./../../godIdList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')
const gruumshMark = require('./gruumshMark')

module.exports = [
  {
    header: 'Груумш Одноглазый',
    text: `Орки поклоняются [Груумшу](GOD:${GOD_GRUUMSH}), сильнейшему из орочьих богов, являющемуся также их создателем. Орки верят, что в древние времена боги собрались и поделили мир между своими последователями. Когда [Груумш](GOD:${GOD_GRUUMSH}) выбрал горы, оказалось, что их уже забрали [дварфы](PC_RACE:${PC_RACE_DWARF}). Тогда он выбрал леса, но там уже поселились [эльфы](PC_RACE:${PC_RACE_ELF}). Что бы ни выбирал [Груумш](GOD:${GOD_GRUUMSH}), всё уже было занято. Другие боги смеялись над [Груумшем](GOD:${GOD_GRUUMSH}), а он отвечал яростным рёвом. Схватив своё могучее копьё, он опустошил горы, сжёг леса и прорезал в полях огромные овраги. Такова будет роль орков, объявил он, — забирать силой или уничтожать всё, в чем другие расы им отказывают. И по сей день орки ведут нескончаемую войну с [людьми](PC_RACE:${PC_RACE_HUMAN}), [эльфами](PC_RACE:${PC_RACE_ELF}), [дварфами](PC_RACE:${PC_RACE_DWARF}) и прочими народами.

Оркам особенно ненавистны [эльфы](PC_RACE:${PC_RACE_ELF}). Эльфийский бог [Кореллон Ларетиан](GOD:${GOD_CORELLON_LARETHIAN}) наполовину ослепил [Груумша](GOD:${GOD_GRUUMSH}) метким выстрелом из лука прямо ему в глаз. С тех пор орки испытывают особую радость, убивая [эльфов](PC_RACE:${PC_RACE_ELF}). Превращая эту травму в ужасный дар, [Груумш](GOD:${GOD_GRUUMSH}) дарует божественную мощь любому своему воину, который добровольно лишает себя одного глаза в его честь.`,
    source: {
      id: SOURCE_MM,
      page: 227,
    },
  },
  gruumshMark,
  {
    header: `Груумш`,
    text: `Одноглазый — божественный покровитель орков, бог войны, завоеваний и победы любой ценой. С тех давних времён, как [Кореллон Ларетиан](GOD:${GOD_CORELLON_LARETHIAN}) лишил его глаза, он держит неизменную обиду на эльфийский пантеон.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
]
