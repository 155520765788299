const {ALPHABET_BARAZHAD} = require('./../../alphabetList')
const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {LANG_SAHUAGIN} = require('./../../languageIdList')

module.exports = {
  id: LANG_SAHUAGIN,
  type: LANG_TYPE_EXOTIC,
  alphabetId: ALPHABET_BARAZHAD,
  name: {
    nominative: 'Сахуагинский',
    genitive: 'Сахуагинского',
    instrumental: 'Сахуагинским',
    prepositional: 'Сахуагинском',
  },
  nameEn: 'Sahuagin',
  isReady: false,
  isRealLang: true,
}
