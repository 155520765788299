const {
  SOURCE_SCAG,
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_SWORD_BURST} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SWORD_BURST,
  name: 'Вспышка мечей',
  nameEn: 'Sword Burst',
  description: `Вы на мгновение создаете круг призрачных мечей, вращающихся вокруг Вас. Все прочие существа, находящиеся в области действия заклинания, должны пройти испытание Ловкости или получить 1к6 урона силовым полем.`,
  highLvlCast: `Урон заклинания возрастает на 1к6 на 5-м уровне (2к6), 11-м уровне (3к6) и на 17-м уровне (4к6).`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 5,
  componentIdList: [CAST_VERBAL],
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 1,
      diceType: 6,
      diceBonus: 0,
    },
  },
  source: [
    {
      id: SOURCE_SCAG,
      page: 144,
    },
    {
      id: SOURCE_TCoE,
      page: 165,
    }
  ],
}
