const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_MUSKET} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  WEAPON_AMMUNITION_RENAISSANCE,
  WEAPON_RANGE,
  WEAPON_RELOADING,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  CAT_FIREARMS,
  CAT_MARTIAL_RANGE_WEAPON,
  CAT_TECH_RENAISSANCE,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_MUSKET,
  name: 'Мушкет',
  nameEn: 'Musket',
  nameByCase: {
    nominative: 'мушкет',
    genitive: 'мушкета',
    accusative: 'мушкет',
    instrumental: 'мушкетом',
  },
  cost: 50000,
  weight: 10,
  damageType: DAMAGE_PIERCING,
  damage: {
    diceCount: 1,
    diceType: 12,
  },
  genderId: GENDER_MALE,
  category: [
    CAT_FIREARMS,
    CAT_MARTIAL_RANGE_WEAPON,
    CAT_TECH_RENAISSANCE,
  ],
  weaponPropList: [
    WEAPON_AMMUNITION_RENAISSANCE,
    WEAPON_RELOADING,
    WEAPON_TWO_HANDED,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 40,
        max: 120,
      },
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
