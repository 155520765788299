const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_REACTION,
} = require('./../../../../../timePeriodList')
const {
  PARAM_DEX,
} = require('./../../../../../paramList')
const {
  DAMAGE_FIRE,
} = require('./../../../../../damageTypeList')
const {
  SPELL_HELLISH_REBUKE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_HELLISH_REBUKE,
  name: 'Адское возмездие',
  nameEn: `Hellish Rebuke`,
  description: `Вы указываете пальцем, и существо, причинившее Вам урон, мгновенно окружается пламенем. Существо должно пройти испытание Ловкости. Оно получает урон огнём 2к10 при провале, или половину этого урона при успехе.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к10 за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  castTime: { timeId: TIME_REACTION, count: 1 },
  castTimeComment: `совершаемая Вами в ответ на получение урона от существа, видимого Вами в пределах 60 футов.`,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_FIRE,
      halfOnSuccess: true,
      diceCount: 2,
      diceType: 10,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 212,
    },
    {
      id: SOURCE_SRD,
      page: 154,
    },
  ],
}
