const {CREATURE_TYPE_DRAGON_TRUE} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_DRAGON_TRUE,
  nameEn: 'True Dragon',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'истинный дракон',
        genitive: 'истинного дракона',
        dative: 'истинному дракону',
        accusative: 'истинного дракона',
        instrumental: 'истинным драконом',
        prepositional: 'истинном драконе',
      },
      [GENDER_FEMALE]: {
        nominative: 'истинная драконица',
        genitive: 'истинной драконицы',
        dative: 'истинной драконице',
        accusative: 'истинную драконицу',
        instrumental: 'истинной драконицей',
        prepositional: 'истинной драконице',
      },
    },
    plural: {
      nominative: 'истинные драконы',
      genitive: 'истинных драконов',
      dative: 'истинным драконам',
      accusative: 'истинных драконов',
      instrumental: 'истинными драконами',
      prepositional: 'истинных драконах',
    },
  },
}
