const {
  SOURCE_DMG,
  SOURCE_GGTR,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS,
  SOURCE_UA_2018_04_09_ORDER_DOMAIN,
  SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
  SOURCE_UA_2020_02_06_SUBCLASSES_PART_2,
  SOURCE_UA_2022_07_18_WotM,
  SOURCE_XGTE,
} = require('./../../sourceList')
const {
  DOMAIN_ARCANA,
  DOMAIN_DEATH,
  DOMAIN_FATE,
  DOMAIN_FORGE,
  DOMAIN_GRAVE,
  DOMAIN_KNOWLEDGE,
  DOMAIN_LIFE,
  DOMAIN_LIGHT,
  DOMAIN_NATURE,
  DOMAIN_ORDER,
  DOMAIN_PEACE,
  DOMAIN_PROTECTION,
  DOMAIN_TEMPEST,
  DOMAIN_TRICKERY,
  DOMAIN_TWILIGHT,
  DOMAIN_UNITY,
  DOMAIN_WAR,
} = require('./../../domainList')
const {
  GOD_UMBERLEE,
  GOD_ANGHARRADH,
  GOD_AZUTH,
  GOD_BANE,
  GOD_BERRONAR,
  GOD_BESHABA,
  GOD_BHAAL,
  GOD_CORELLON_LARETHIAN,
  GOD_CYRROLLALEE,
  GOD_ELDATH,
  GOD_GAERDAL_IRONHAND,
  GOD_GARL_GLITTERGOLD,
  GOD_GOND,
  GOD_GRUUMSH,
  GOD_HELM,
  GOD_ILMATER,
  GOD_KELEMVOR,
  GOD_LATHANDER,
  GOD_LOVIATAR,
  GOD_MAGLUBIYET,
  GOD_MORADIN,
  GOD_MYRKUL,
  GOD_MYSTRA,
  GOD_OGHMA,
  GOD_SELUNE,
  GOD_SILVANUS,
  GOD_TALONA,
  GOD_TALOS,
  GOD_TEMPUS,
  GOD_TORM,
  GOD_TYMORA,
  GOD_TYR,
  GOD_YONDALLA,
} = require('./../../godIdList')
const {
  CREATURE_DEATH_KNIGHT,
  CREATURE_LICH,
  CREATURE_MUMMY_LORD,
  CREATURE_VAMPIRE,
} = require('./../../creatureIdList')

const {
  PC_CLASS_CLERIC,
} = require('./../../pcClassIdList')

const {
  PC_SUBCLASS_CLERIC_ARCANA_DOMAIN,
  PC_SUBCLASS_CLERIC_DEATH_DOMAIN,
  PC_SUBCLASS_CLERIC_FATE_DOMAIN_2022_07_18,
  PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
  PC_SUBCLASS_CLERIC_FORGE_DOMAIN_2016_11_21,
  PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
  PC_SUBCLASS_CLERIC_GRAVE_DOMAIN_2016_11_21,
  PC_SUBCLASS_CLERIC_KNOWLEDGE_DOMAIN,
  PC_SUBCLASS_CLERIC_LIFE_DOMAIN,
  PC_SUBCLASS_CLERIC_LIGHT_DOMAIN,
  PC_SUBCLASS_CLERIC_NATURE_DOMAIN,
  PC_SUBCLASS_CLERIC_ORDER_DOMAIN,
  PC_SUBCLASS_CLERIC_ORDER_DOMAIN_2018_04_09,
  PC_SUBCLASS_CLERIC_PEACE_DOMAIN,
  PC_SUBCLASS_CLERIC_PROTECTION_DOMAIN_2016_11_21,
  PC_SUBCLASS_CLERIC_TEMPEST_DOMAIN,
  PC_SUBCLASS_CLERIC_TRICKERY_DOMAIN,
  PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN,
  PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN_2019_10_03,
  PC_SUBCLASS_CLERIC_UNITY_DOMAIN_2020_02_06,
  PC_SUBCLASS_CLERIC_WAR_DOMAIN,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_CLERIC_ARCANA_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_ARCANA,
    description: `Магия — это энергия, наполняющая мультивселенную и питающая и разрушение, и созидание.

Боги домена Магии отлично знают секреты и возможности волшебства. Для некоторых подобных богов знание магии — великая ответственность, приходящая с пониманием природы реальности. Другие боги Магии видят в ней чистую силу, которая может использоваться владельцем по своему разумению.

Боги этого домена часто связаны со знаниями, как обучение и магические силы идут рука об руку. В Королевствах к богам этого домена относятся [Азут](GOD:${GOD_AZUTH}) и [Мистра](GOD:${GOD_MYSTRA}), наряду с [Кореллоном Ларетианом](GOD:${GOD_CORELLON_LARETHIAN}) из эльфийского пантеона. В иных мирах к этому домену причастны Геката, Мат Матонви и Изис; троица лунных богов — Солинари, Лунитари и Нуитари в Кринне; Боккоб, Векна и Ви Джас в Грейхоке.`,
    source: {
      id: SOURCE_SCAG,
      page: 126,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_DEATH_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_DEATH,
    description: `Домен смерти сфокусирован вокруг сил, причиняющих смерть, а также негативной энергии, создающей нежить.

Такие божества как Чемош, [Миркул](GOD:${GOD_MYRKUL}) и Ви Джас — покровители некромантов, [рыцарей смерти](CREATURE:${CREATURE_DEATH_KNIGHT}), [личей](CREATURE:${CREATURE_LICH}), [владык мумий](CREATURE:${CREATURE_MUMMY_LORD}) и [вампиров](CREATURE:${CREATURE_VAMPIRE}).

Боги домена смерти также воплощают убийство (Анубис, [Баал](GOD:${GOD_BHAAL}) и Пайремиус), боль (Иуз и [Ловиатар](GOD:${GOD_LOVIATAR})), болезни и яды (Инкабулос, [Талона](GOD:${GOD_TALONA}) и Моргион), а также подземный мир (Аид и Хель). `,
    source: {
      id: SOURCE_DMG,
      page: 97,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_FORGE,
    description: `Боги кузницы — покровители ремесленников, работающих с металлом, от скромного кузнеца, который обеспечивает деревню подковами и лезвиями плугов, до великого эльфийского мастера, чьи стрелы с алмазным наконечником из мифрила валили лордов демонов.

Боги кузницы учат, что с терпением и упорным трудом даже самый трудно обрабатываемый металл может быть превращен из куска руды в красивый предмет.

Жрецы этих божеств ищут потерянные силами тьмы предметы, освобождают шахты от орков и открывают редкие и чудесные материалы, необходимые для создания мощных магических предметов.

Последователи этих богов очень гордятся своей работой, и они готовы создавать и использовать тяжелую броню и мощное оружие для их защиты.

Божествами этого домена являются [Гонд](GOD:${GOD_GOND}), Реоркс, Онотар, [Морадин](GOD:${GOD_MORADIN}), Гефест и Гойбхну.`,
    note: {
      text: `Мой слуга говорит, что его подручные отлично подкованы в подделке документов. Перемазанные чернилами мелкие сопляки с отличным почерком. Хотя зачем они работают в кузне и зачем он их подковывает, скажите на милость? А, видимо, это наказание за ошибки!`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 34,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_FORGE_DOMAIN_2016_11_21,
    pcClassId: PC_CLASS_CLERIC,
    releasedAs: PC_SUBCLASS_CLERIC_FORGE_DOMAIN,
    isReady: false,
    domainId: DOMAIN_FORGE,
    description: `The gods of the forge are patrons of artisans who work with metal, from a humble blacksmith who keeps a village in horseshoes and plow blades to the mighty elf artisan whose diamond-tipped arrows of mithral have felled demon lords. The gods of the forge teach that, with patience and hard work, even the most intractable metal can transform from a lump of ore to a beautifully wrought object. Clerics of these deities quest to search for objects lost to the forces of darkness, liberate mines overrun by orcs, and uncover rare and wondrous materials necessary to create potent magic items. Followers of these gods take great pride in their work, and they are willing to craft and use heavy armor and powerful weapons to protect them. Deities of this domain include Gond, Reorx, Onatar, Moradin, Hephaestus, and Goibhniu.`,
    source: {
      id: SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_GRAVE,
    description: `Боги упокоения следят за границей между жизнью и смертью. Для этих божеств смерть и загробная жизнь являются фундаментальной частью мультивселенной.

Нарушение покоя мертвых для них — ужасный грех. Божества упокоения: [Келемвор](GOD:${GOD_KELEMVOR}), Ви Джас, духи предков Неумирающего Двора, Аид, Анубис и Осирис.

Последователи этих божеств стремятся упокоить блуждающих духов, уничтожить нежить и облегчить страдания умирающих. Их магия также позволяет им временно отсрочить смерть, особенно для человека, у которого ещё есть неоконченные великие дела. Это отсрочка смерти, а не её отрицание, потому что смерть в конечном итоге заберёт всех.`,
    note: {
      text: `Я полагаю, что если Вы не можете уничтожить или съесть их, то захоронение трупов имеет столько же смысла, как и всё остальное`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 35,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN_2016_11_21,
    releasedAs: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: false,
    domainId: DOMAIN_GRAVE,
    description: `Gods of the grave watch over the line between life and death. To these deities, death and the afterlife area foundational part of the multiverse’s workings. To resist death, or to desecrate the dead’s rest, is an abomination. Deities of the grave include Kelemvor, Wee Jas, the ancestral spirits of the Undying Court, Hades, Anubis, and Osiris. These deities teach their followers to respect the dead and pay them due homage. Followers of these deities seek to put restless spirits to rest, destroy the undead wherever they find them, and ease the suffering of dying creatures. Their magic also allows them to stave off a creature’s death, though they refuse to use such magic to extend a creature’s lifespan beyond its mortal limits.`,
    source: {
      id: SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_KNOWLEDGE_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_KNOWLEDGE,
    description: `Боги знаний — в том числе [Огма](GOD:${GOD_OGHMA}), Боккоб, Гилеан, Ауреон и Тот — ценят образованность и понимание прежде всего. Некоторые учат собирать знания и делать их доступными в библиотеках и университетах, или развивать практические знания ремёсел и изобретений.

Некоторые божества копят знания и хранят их в тайне для себя. А некоторые обещают своим последователям, что они получат огромную власть, если раскроют тайны мультивселенной.

Последователи этих богов изучают эзотерические знания, собирают старые фолианты, исследуют тайные уголки земли, и изучают всё, что смогут. Некоторые боги развивают практические знания ремесла и изобретения, в том числе боги-кузнецы [Гонд](GOD:${GOD_GOND}), Реоркс, Онатар, [Морадин](GOD:${GOD_MORADIN}), Гефест и Гоибниу.`,
    source: {
      id: SOURCE_PHB,
      page: 62,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_LIFE_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_LIFE,
    description: `Домен жизни сосредоточен на трепещущей позитивной энергии — одной из фундаментальных сил вселенной, которая поддерживает жизнь. Боги жизни способствуют жизнеспособности и здоровью, исцеляя больных и раненых, заботясь о нуждающихся, и отгоняя нежить и силы смерти.

Почти любое не злое божество может претендовать на влияние в этом домене, особенно божества земледелия (такие как Чонтиа, Аравай и Деметра), боги солнца (такие как [Латандер](GOD:${GOD_LATHANDER}), Пелор и Ра-Хорахти), боги исцеления и выносливости (такие как [Ильматер](GOD:${GOD_ILMATER}), Мишакаль, Аполлон и Диан Кехт) и боги домашнего очага и общин (такие как Гестия, Хатор и Болдрей).`,
    source: [
      {
        id: SOURCE_PHB,
        page: 61,
      },
      {
        id: SOURCE_SRD,
        page: 17,
      },
    ],
  },
  {
    id: PC_SUBCLASS_CLERIC_LIGHT_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_LIGHT,
    description: `Боги света — в том числе [Хельм](GOD:${GOD_HELM}), [Латандер](GOD:${GOD_LATHANDER}), Фолтус, Бранчала, Серебряное Пламя, Беленус, Аполлон и Ра-Хорахти — представляют идеалы возрождения и обновления, истины, бдительности и красоты, часто используя символ солнца.

Некоторые из этих богов сами изображаются как солнце или возничий, ведущий солнце через небо. Другие — как неутомимые часовые, чьи глаза пронзают любую тень и видят любой обман. Некоторые являются божествами красоты и артистизма, которые учат, что искусство — это средство совершенствования души.

Жрецы бога света — просветлённые души, исполненные сиянием и силой проницательности своего божества, отгоняющие прочь ложь и испепеляющие тьму.`,
    source: {
      id: SOURCE_PHB,
      page: 64,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_NATURE_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_NATURE,
    description: `Боги природы столь же разнообразны, как и сама дикая природа, от непостижимых богов глубоких лесов (таких как [Сильванус](GOD:${GOD_SILVANUS}), Обад-Хай, Числев, Балинор и Пан) до дружелюбных божеств, отождествляемых с родниками и рощами (таких как [Эльдат](GOD:${GOD_ELDATH})).

Друиды, почитающие природу в целом, могут служить одному из этих божеств, практикуя таинственные обряды и читая почти забытые молитвы на собственном тайном языке. Но многие из этих богов имеют также жрецов, героев, которые выполняют более активную роль в продвижении интересов конкретного бога природы.

Эти жрецы могут охотиться на злых чудовищ, портящих лесные угодья, благословлять урожай верующих, или иссушать посевы тех, кто прогневал их богов.`,
    source: {
      id: SOURCE_PHB,
      page: 63,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_ORDER_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_ORDER,
    description: [
      `Домен порядка воплощает дисциплину и приверженность законам, которые управляют обществом, учреждением или философией. Жрецы порядка размышляют о логике и справедливости, когда служат своим богам, примеры которых можно найти в таблице Божеств порядка.

Жрецы порядка верят, что хорошо разработанные законы устанавливают правильную иерархию, и тем, кто законно избран править, нужно подчиняться. Те, кто подчиняются, должны делать это в меру своих возможностей, и если те, кто руководят, не защищают закон, их следует заменить. Таким образом, из закона сплетается сеть взаимных обязательств, создавая порядок и безопасность в хаотической мультивселенной.`,
      {
        header: 'Божества порядка',
        text: `
| Пример божества         | Пантеон             |
|-------------------------|---------------------|
| [Бэйн](GOD:${GOD_BANE}) | Забытые Королевства |
| Ви Джас                 | Серый ястреб        |
| Маджере                 | Драконье копьё      |
| Ореон                   | Эберрон             |
| [Тир](GOD:${GOD_TYR})   | Забытые Королевства |
| Фолт                    | Серый ястреб        |
`,
        source: {
          id: SOURCE_TCoE,
          page: 42,
        },
      },
    ],
    note: {
      text: `Наконец-то, целая религия о том, как не выходить за рамки`,
      author: `Таша`,
    },
    source: [
      {
        id: SOURCE_GGTR,
        page: 26,
      },
      {
        id: SOURCE_TCoE,
        page: 41,
      },
    ],
  },
  {
    id: PC_SUBCLASS_CLERIC_ORDER_DOMAIN_2018_04_09,
    releasedAs: PC_SUBCLASS_CLERIC_ORDER_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: false,
    domainId: DOMAIN_ORDER,
    source: {
      id: SOURCE_UA_2018_04_09_ORDER_DOMAIN,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_PROTECTION_DOMAIN_2016_11_21,
    pcClassId: PC_CLASS_CLERIC,
    isReady: false,
    domainId: DOMAIN_PROTECTION,
    source: {
      id: SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_TEMPEST_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_TEMPEST,
    description: `Боги, чьи портфолио включают домен бури — в том числе [Талос](GOD:${GOD_TALOS}), [Амберли](GOD:${GOD_UMBERLEE}), Корд, Зебоим, Поглотитель, Зевс и Тор — правят бурями, морями и небесами.

Среди них боги грома и молний, боги землетрясений, некоторые боги огня и насилия, физической силы и бесстрашия. В некоторых пантеонах боги этого домена правят другими божествами, и известны скорым судом, заканчивающимся ударом молнии. В пантеонах мореходов боги этого домена являются божествами океана и покровителями моряков.

Боги бури отправляют своих жрецов внушать страх простым смертным, либо наставляя людей на праведный путь, либо заставляя их приносить жертвы, чтобы избежать божественного гнева.`,
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_TRICKERY_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_TRICKERY,
    description: `Боги обмана, такие как [Тимора](GOD:${GOD_TYMORA}), [Бешаба](GOD:${GOD_BESHABA}), Олидаммара, Странник, [Гарл Златоблеск](GOD:${GOD_GARL_GLITTERGOLD}) и Локи — интриганы и подстрекатели, которые постоянно бросают вызов принятому порядку среди богов и смертных.

Это покровители воров, негодяев, азартных игроков, мятежников и освободителей.

Их жрецы представляют разрушительные силы мира, пронзающие гордость, высмеивающие тиранов, крадущие у богатых, освобождающие пленных и попирающие бессмысленные традиции. Прямому противостоянию они предпочитают уловки, проделки, обман и воровство. `,
    source: {
      id: SOURCE_PHB,
      page: 63,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_TWILIGHT,
    description: [
      `Сумерки — это переход от света к темноте. Они часто приносит спокойствие и даже радость, поскольку дневные труды заканчиваются и начинаются часы отдыха.

Но тьма также может приносить ужас, а боги сумерек охраняют от ужасов ночи.

Жрецы, которые служат этим божествам, утешают тех, кто ищет покоя, и защищают их, рискуя входить в наступающую тьму, чтобы гарантировать, что тьма — это утешение, а не ужас.`,
      {
        header: 'Божества сумрака',
        text: `
| Пример божества                 | Пантеон              |
|---------------------------------|----------------------|
| Болдрей                         | Эберрон              |
| Дол Аррах                       | Серый ястреб         |
| [Ильматер](GOD:${GOD_ILMATER})  | Забытые Королевства  |
| [Йондалла](GOD:${GOD_YONDALLA}) | Пантеон полуросликов |
| Мишакаль                        | Драконье копьё       |
| Селестиан                       | Серый ястреб         |
| [Селюнэ](GOD:${GOD_SELUNE})     | Забытые Королевства  |
| [Хельм](GOD:${GOD_HELM})        | Забытые Королевства  |
`,
        source: {
          id: SOURCE_TCoE,
          page: 47,
        },
      },
    ],
    note: {
      text: `Не могу поверить, что пишу это, но я могла бы поддержать религию, основанную на приглушённом освещении и вечерних нарядах`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 46,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN_2019_10_03,
    releasedAs: PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: false,
    domainId: DOMAIN_TWILIGHT,
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_UNITY_DOMAIN_2020_02_06,
    releasedAs: PC_SUBCLASS_CLERIC_PEACE_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: false,
    domainId: DOMAIN_UNITY,
    source: {
      id: SOURCE_UA_2020_02_06_SUBCLASSES_PART_2,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_PEACE_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_PEACE,
    description: [
      `Жрецы домена мира руководят подписанием договоров, и их часто просят выступать в качестве арбитра в спорах. Их благословения объединяют людей и помогают им нести бремя друг друга, а их магия помогает тем, кто борется за мир.`,
      {
        header: 'Божества мира',
        text: `
| Пример божества                                     | Пантеон              |
|-----------------------------------------------------|----------------------|
| [Ангаррад](GOD:${GOD_ANGHARRADH})                   | Селдарин             |
| [Берронар Истинно Серебряная](GOD:${GOD_BERRONAR})  | Морндинсамман        |
| Болдрей                                             | Эберрон              |
| [Гаэрдал Железнорукий](GOD:${GOD_GAERDAL_IRONHAND}) | Пантеон гномов       |
| Паладайн                                            | Драконье копьё       |
| Рао                                                 | Серый ястреб         |
| [Цирроллали](GOD:${GOD_CYRROLLALEE})                | Пантеон полуросликов |
| [Эльдат](GOD:${GOD_ELDATH})                         | Забытые Королевства  |
`,
        source: {
          id: SOURCE_TCoE,
          page: 45,
        },
      },
    ],
    note: {
      text: `Эти миролюбивые жрецы вообще понимают, что они подрывают самую святую из всех систем — ту, в которой плохие решения соответствуют уровню боли?`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 45,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_WAR_DOMAIN,
    pcClassId: PC_CLASS_CLERIC,
    isReady: true,
    domainId: DOMAIN_WAR,
    description: `Война имеет много проявлений.

Она может сделать героев из обычных людей. Она может быть отчаянной и ужасающей, с проявлениями жестокости и трусости, затмевающими примеры мастерства и бесстрашия.

В любом случае, боги войны следят за воинами и вознаграждают за великие свершения. Жрецы таких богов отличаются в битве, вдохновляя остальных сражаться или призывая к насилию в своих молитвах.

Среди богов войны есть чемпионы чести и рыцарства (такие как [Торм](GOD:${GOD_TORM}), Хиронеус и Кири-Джолит), а также боги разрушения и мародёрства (такие как Эритнул, Ярость, [Груумш](GOD:${GOD_GRUUMSH}) и Арес) и боги завоеваний и покорения (такие как [Бэйн](GOD:${GOD_BANE}), Хекстор и [Маглубиет](GOD:${GOD_MAGLUBIYET})).

Другие боги войны (такие как [Темпус](GOD:${GOD_TEMPUS}), Ника и Нуада) занимают более нейтральную позицию, содействуя войне во всех её проявлениях и поддерживая воинов в любых обстоятельствах.`,
    source: {
      id: SOURCE_PHB,
      page: 61,
    },
  },
  {
    id: PC_SUBCLASS_CLERIC_FATE_DOMAIN_2022_07_18,
    pcClassId: PC_CLASS_CLERIC,
    isReady: false,
    domainId: DOMAIN_FATE,
    description: `Боги судьбы видят будущее и то, как выбор смертных ведёт к их судьбам. Некоторые божества считают будущее предопределенным, в то время как другие считают мультивселенную местом бесконечных возможностей.

Жрецы, черпающие силу из сил судьбы, иногда получают видения прямо от своего божества и получают мимолетные предзнаменования будущего. Они делятся со своими союзниками такими невозможными знаниями и пророчат гибель врагам.`,
    source: {
      id: SOURCE_UA_2022_07_18_WotM,
      page: 2,
    },
  },
]
