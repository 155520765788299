const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  PARAM_CON,
} = require('./../../../../../paramList')
const {
  DAMAGE_COLD,
} = require('./../../../../../damageTypeList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
} = require('./../../../../../sourceList')
const {
  SPELL_ICINGDEATH_S_FROST_2021_04_14,
  SPELL_RIME_S_BINDING_ICE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ICINGDEATH_S_FROST_2021_04_14,
  releasedAs: SPELL_RIME_S_BINDING_ICE,
  name: 'Мороз Ледяной Смерти',
  nameEn: 'Icingdeath’s Frost',
  description: `Вы исторгаете взрыв замораживающего холода 15-футовым конусом.

Все существа в этой области должны пройти испытание Телосложения.

* При провале существо получает 3к8 урона холодом и покрывается льдом на одну минуту или пока какое-то существо не счистит лёд действием. Скорость покрытого льдом существа равна 0.
* При успехе существо получает только половину урона и не получает прочих эффектов.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше второго.`,
  lvl: 2,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  rangeComment: `15-футовый конус`,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
  materialText: `флакон талой воды`,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_COLD,
      diceCount: 3,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
    page: 6,
  },
}
