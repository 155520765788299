import React from 'react'
import PropTypes from 'prop-types'

import generateNameStr from '@/utils/generateNameStr'

import {creatureCollection} from '@/constants/creatureList'

import ImageComponent from './ImageComponent'

const ImageContainer = ({ data, showEmpty, ...rest }) => {
  if (data) {
    let image = null

    if (typeof data === 'string') {
      const {header: text, title} = generateNameStr(rest)
      image = {
        src: data,
        text,
        title,
        source: rest.source,
      }
    } else if (data.src) {
      const {header: text, title} = generateNameStr(
        data.creatureId
          ? creatureCollection[data.creatureId]
          : rest
      )

      image = {
        src: data.src,
        title: data.title || data.text || title,
        text: data.text || text,
        source: data.source || rest.source,
      }
    }

    return image
      ? (
        <ImageComponent
          {...rest}
          {...image}
        />
      )
      : null
  }

  if (!data && showEmpty) {
    return (
      <ImageComponent
        showEmpty
        {...rest}
      />
    )
  }

  return null
}

ImageContainer.propTypes = {
  data: PropTypes.any,
}

ImageContainer.defaultProps = {
  data: null,
}

export default ImageContainer
