const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {CREATURE_ZOMBIE} = require('./../../../../../creatureIdList')
const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_NEGATIVE_ENERGY_FLOOD} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_NEGATIVE_ENERGY_FLOOD,
  name: 'Поток негативной энергии',
  nameEn: 'Negative Energy Flood',
  description: `Вы посылаете ленты негативной энергии в одно существо, которое видите в пределах дистанции. Если цель не является нежитью, она должна пройти испытание Телосложения и получить 5к12 урона некротической энергией при провале или половину этого урона при успехе. Цель, убитая этим уроном, восстает в виде [зомби](CREATURE:${CREATURE_ZOMBIE}) в начале Вашего следующего хода. Зомби преследует любое существо, которое может видеть и которое ближе всего к нему.

Если Вы нацеливаете это заклинание на нежить, цель не проходит испытание. Вместо этого бросьте 5к12. Цель получает половину выпавшего значения в качестве временных хитов.`,
  lvl: 5,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `сломанная кость и квадрат чёрного шелка`,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_NECROTIC,
      halfOnSuccess: true,
      diceCount: 5,
      diceType: 12,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 162,
  },
}
