const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_FLY,
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_MEPHIT,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_AQUAN,
  LANG_TERRAN,
} = require('./../../../../languageIdList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_MUD_MEPHIT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {mephitDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Грязевой мефит',
  nameEn: 'Mud Mephit',
  description: [
    `Грязевые мефиты медлительны и маслянисты. Они состоят из земли и воды. Они будут жаловаться на жизнь любому, кто станет их слушать, и будут беспрестанно требовать к себе внимания и выпрашивать подарки.`,
    mephitDescription,
  ],
  id: CREATURE_MUD_MEPHIT,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_MEPHIT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 204,
  },
  armor: 11,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 20,
    [SPEED_SWIM]: 20,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 12,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AQUAN,
    LANG_TERRAN,
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Предсмертная вспышка',
      description: `Когда ★СУЩЕСТВО★ умирает, ★он★ разлетается во все стороны липкой грязью. Все существа с размером не больше Среднего в пределах 5 футов от ★него★ должны преуспеть в испытании Ловкости СЛ 11, иначе обездвиживаются до конца своего следующего хода.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычной кучи грязи`,
    },
  ],
  actionList: [
    {
      name: 'Кулаки',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Грязевое дыхание',
      restore: 6,
      description: `★СУЩЕСТВО★ изрыгает липкую грязь на одно существо, находящееся в пределах 5 футов от ★него★. Если размер цели не больше Среднего, она должна пройти испытание Ловкости СЛ 11, иначе станет обездвиженой на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
