const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_CHESSENTAN} = require('./../../languageIdList')
const {PLACE_CHESSENTA} = require('./../../placeIdList')

module.exports = {
  id: LANG_CHESSENTAN,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Чессентский',
    genitive: 'Чессентского',
    instrumental: 'Чессентским',
    prepositional: 'Чессентском',
  },
  nameEn: 'Chessentan',
  typicalSpeakers: 'муланы',
  spokenPlaceList: PLACE_CHESSENTA,
  isReady: false,
  isRealLang: true,
}
