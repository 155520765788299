const {SOURCE_SKT} = require('./../../sourceList')
const {
  CREATURE_DAO,
  CREATURE_OGRE,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Яколюд (Йикария)',
    text: `Яколюды, известный среди своих как Йикария («счастливые избранные» на их языке), это гуманоиды размером с [огра](CREATURE:${CREATURE_OGRE}). Их головы напоминают рассерженных яков с кривыми рогами и строгим выражением лица. Их неповоротливые тела покрыты толстым мехом и волосами, и посторонние не могут с виду определить их пол.`,
    source: {
      id: SOURCE_SKT,
      page: 248,
    },
  },
  {
    header: 'Общество яколюдов',
    text: `Другие цивилизованные расы рассматривают яколюдов как «страшилищ» — страшную расу злых, безжалостных и могучих дикарей. Они живут в уединенных поселениях, защищённых от разрушительных явлений природы, включая горные долины, высокие плато и пустынные оазисы. В этих, на вид, идеальных убежищах, яколюды управляют гуманоидными рабами железным кулаком. При всём их образовании и культуре, яколюды чрезвычайно злые повелители. Они беспокоятся о своих подопечных только с точки зрения, что живой раб полезнее мертвого, и сохранять жизнь рабу легче, чем трудиться самому. Не то, чтобы они ленивые, наоборот! Просто они считают большую часть видов физического труда ниже своего достоинства.

Посторонние, которые натыкаются на анклав яколюдов, обычно удивляются и рады видеть то, что кажется на утопией, и яколюды с радостью поддерживают эту иллюзию, пока не представится возможность разоружить и поработить.

У яколюдов есть тяга к познанию, особенно когда это касается секретов стихийной магии и тёмных знаний, что могут помочь развратить или подчинить остальных. Знание, которое яколюды не могут постичь или использовать — ждёт уничтожение. Не сентиментальные по своей природе, родители отправляют своих детей в специальные ясли и больше никогда не видят их снова. Яколюды не чувствуют лояльности к своей семье — только к их богу и расе.`,
    source: {
      id: SOURCE_SKT,
      page: 248,
    },
  },
  {
    header: 'Слуги Забытого бога',
    text: `Яколюды функционируют как жестокая теократия в услужении Забытому богу. Вероисповедание этого дикого, безымянного божества направляет их жизнь. Бог принимает форму мужчины Йикарии, но на лице у божества гладкая безликая маска. Божество умиротворяют жертвами, в качестве которых последователи предлагают рабов в соответствии со стихиями, — а именно, огонь (сожжение), земля (погребение заживо), вода (утопление) и воздух (сбрасывают жертву с большой высоты). Жертвы не только гарантируют благосклонность божества, но и наказывают непослушных рабов.

Забытый бог помог яколюдам на время поработить [дао](CREATURE:${CREATURE_DAO}) в течение времени. Говорят, что Забытый бог путешествовал к Стихийному Плану Земли и, с помощью обмана и хитростей, нанес поражение Великому Хану дао. Цена поражения была суровой: [дао](CREATURE:${CREATURE_DAO}) были вынуждены служить Забытому богу и его прислужникам — и им запрещено атаковать их _«тысячу лет и один год»_. Приговор с тех времен истек, и яколюды больше не могут призывать [дао](CREATURE:${CREATURE_DAO}) как раньше, но страх перед Забытым богом удерживает [дао](CREATURE:${CREATURE_DAO}) от поисков мести.`,
    source: {
      id: SOURCE_SKT,
      page: 248,
    },
  },
  {
    header: 'Меняющие кожу',
    text: `Самое устрашающие оружие яколюдов — это способности магическим образом заползать под кожу других существ, контролировать тело и разум. Яколюды используют эту способность, чтобы шпионить за врагами, грабить их, убивать их лидеров и похищать их детей.`,
    source: {
      id: SOURCE_SKT,
      page: 248,
    },
  },
]
