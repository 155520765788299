const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_CROSSBOW_HEAVY,
  GEAR_LEATHER_ARMOR,
  GEAR_MACE,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_PACK_TACTICS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NOT_GOOD} = require('./../../../../aligmentList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_THUG} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_INTIMIDATION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Головорез',
  nameEn: 'Thug',
  id: CREATURE_THUG,
  description: `Головорезы это безжалостные громилы, умеющие запугивать и заниматься насилием. Они работают за деньги, и практически лишены моральных принципов.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_NOT_GOOD,
  source: {
    id: SOURCE_MM,
    page: 345,
  },
  armor: {
    ac: 11,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_MACE,
    },
    {
      gearId: GEAR_CROSSBOW_HEAVY,
    },
  ],
  genderId: GENDER_MALE,
}
