const {
  SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DRAGON_CHROMATIC,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DRACONIC,
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_FLY,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  DAMAGE_PIERCING,
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  CREATURE_CHROMATIC_DRAGON_SPIRIT,
  CREATURE_CHROMATIC_DRAGON_SPIRIT_2021_04_14,
} = require('./../../../../creatureIdList')
const {
  SPELL_SUMMON_DRACONIC_SPIRIT_2021_04_14,
} = require('./../../../../spellIdList')

module.exports = {
  name: 'Дух цветного дракона',
  nameEn: 'Chromatic Draconic Spirit',
  id: CREATURE_CHROMATIC_DRAGON_SPIRIT_2021_04_14,
  releasedAs: CREATURE_CHROMATIC_DRAGON_SPIRIT,
  createdBySpellId: SPELL_SUMMON_DRACONIC_SPIRIT_2021_04_14,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAGON_CHROMATIC,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
    page: 4,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `десятикратный круг заклинания`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 80,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 17,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 14,
  },
  resistanceList: [ // Chromatic and Metallic only
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  featureList: [
    {
      name: 'Общее сопротивление',
      description: `Призывая ★СУЩЕСТВО★, выберите одно из его сопротивлений урону. Вы получаете такое же сопротивление, пока заклинание активно.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ использует _Оружие дыхания_, после чего совершает число атак _Укусом и когтем_, равное половине круга заклинания.`,
    },
    {
      name: 'Укус и коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус к атакам заклинаниями заклинателя`,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
          comment: ` + круг заклинания`,
        },
      },
    },
    {
      name: 'Оружие дыхания',
      description: `★СУЩЕСТВО★ выдыхает поток многоцветной энергии 30-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 17, получая 7 (2к6) урона по Вашему выбору из списка сопротивлений дракона при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
