const {
  GEAR_GRENADE_SMOKE,
  GEAR_GRENADE_LAUNCHER,
} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  CAT_EJECTIVES,
  CAT_EXPLOSIVES,
  CAT_GRENADES,
  CAT_TECH_MODERN,
} = require('./../../../gearCategoryList')
const {
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_GRENADE_SMOKE,
  name: 'Граната, дымовая',
  nameEn: 'Grenade, smoke',
  nameByCase: {
    nominative: 'дымовая граната',
    genitive: 'дымовой гранаты',
    accusative: 'дымовую гранату',
    instrumental: 'дымовой гранатой',
  },
  description: {
    header: 'Дымовая граната',
    text: `Персонаж может действием кинуть дымовую гранату на расстояние до 60 футов. Имея [гранатомёт](GEAR:${GEAR_GRENADE_LAUNCHER}), можно запустить её на расстояние до 120 футов.

Спустя раунд после приземления дымовой гранаты появляется облако дыма, создающее сильно заслонённую местность с радиусом 20 футов. Умеренный ветер (по крайней мере, 10 миль в час) разгоняет дым за 4 раунда, сильный ветер (20 или больше миль в час) разгоняет его за 1 раунд.`,
    source: {
      id: SOURCE_DMG,
      page: 268,
    },
  },
  range: 20,
  weight: 2,
  genderId: GENDER_FEMALE,
  category: [
    CAT_EJECTIVES,
    CAT_EXPLOSIVES,
    CAT_GRENADES,
    CAT_TECH_MODERN,
  ],
  weaponPropList: [
    WEAPON_THROWN,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 60,
        max: 60,
      },
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
