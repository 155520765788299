const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_11,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ABERRATION,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PC_CLASS_WIZARD,
} = require('./../../../../pcClassIdList')
const {
  SPELL_ACID_SPLASH,
  SPELL_CHAIN_LIGHTNING,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISPEL_MAGIC,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_GEAS,
  SPELL_IDENTIFY,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_HAND,
  SPELL_MENDING,
  SPELL_MISTY_STEP,
  SPELL_RAY_OF_FROST,
  SPELL_SCRYING,
  SPELL_SENDING,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
  SPELL_WITCH_BOLT,
} = require('./../../../../spellIdList')
const {
  CREATURE_MORKOTH,
} = require('./../../../../creatureIdList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_SPELL_REFLECTION,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')

module.exports = {
  name: 'Моркот',
  nameEn: 'Morkoth',
  id: CREATURE_MORKOTH,
  description: [
    {
      header: 'Моркоты',
      text: `Моркоты — это древние и хитрые ненасытные коллекционеры. Каждый из них путешествует по планам, собирает ценности, странности и выброшенные мультивселенной вещи, чтобы пополнять свою коллекцию.`,
      source: {
        id: SOURCE_VGTM,
        page: 170,
      },
    },
    {
      header: 'Рождённые богом',
      text: `Давным давно божество жадности и раздора погибло в битвах между бессмертными. Его тело дрейфовало по Астральному Плану, становясь лишь окаменевшей оболочкой. Это тело залетело в перламутровые остатки небесной материи, наполненной энергией жизни и рождения. Столкновение разрушило оба объекта и породило бурю хаотической энергии. Бесчисленные острова смешанной материи понеслись в серебряную пустоту. Внутри некоторых были прожилки похожего на жемчужину материла, содержащего часть омоложенной сверхъестественной жизненной силы божества, которая спонтанно создала обитаемую среду. На этих же островах части окаменевшей плоти бога вернулись к жизни в форме чудовищ с щупальцами, переполненных жестокостью и жадностью. С тех пор у каждого моркота есть внешнепланарный остров, который он может назвать домом.`,
      source: {
        id: SOURCE_VGTM,
        page: 170,
      },
    },
    {
      header: 'Без какого-либо смысла',
      text: `Остров моркота имеет свойства фантастического ландшафта, в котором природа и предсказуемость отступают перед странностями и хаосом. Это беспорядочная куча объектов и смесь существ, некоторые из которых берут начало из забытых времён. Остров может иметь освещение, выглядящее естественно, но большинство окутано сумраком и на любом из них туманы и тени могут появиться без предупреждения. Тамошняя среда тёплая и мокрая, с субтропическим или тропическим климатом, который делает жизнь моркота и его «гостей» комфортабельной.

Жемчужная материя внутри острова позволяет ему скользить по планарным течениям, поддерживает на острове окружающую среду и охраняет местность от вредных внешних эффектов. Остров моркота можно найти где угодно, от глубин океана до пустоты Астрального Плана. Остров может парить в небесах Аверно в Девяти Преисподних, не уничтожаясь и без вреда для обитателей. Всё, что находится в пределах определенного расстояния от острова моркота, путешествует вместе с ним между планами. Поэтому во владениях моркота можно найти людей потерянных цивилизаций и существа или объекты из прошедших эпох.

Некоторые острова путешествуют по конкретному маршруту, регулярно прибывая в одни и те же места через цикл лет. Другие же привязаны к определенному месту или группе локаций, а иные двигаются по космосу хаотично. Иногда моркот учится контролировать движение острова, так что он движется туда, куда желает хозяин.`,
      source: {
        id: SOURCE_VGTM,
        page: 170,
      },
    },
    {
      header: 'Первобытные накопители',
      text: `Моркотами движет жадность и эгоизм, смешанные с тоской по конфликтам. Они желают всего чем не владеют, не испытывают никакой щепетильности по поводу того, чем хотят обладать, и стараются сохранить всё, чем обладают.

Моркот тратит время на наблюдение за своей коллекцией и планирование пополнения для неё. Накопления чудовища содержат огромные сокровища и знания. На острове содержится бесчисленное количество пленников, которые являются частью коллекции. Некоторые обитатели, такие как потомки изначальных пленников, считают моркота правителем или богом. Хранилище богатств и легенд моркота,  конечно же, привлекают обычных грабителей, а также тех, кто ищет что-то конкретное, чем владеет или что знает моркот. Существо не жалеет тех, кто хочет у него что-то украсть, но посетитель может договориться с моркотом, если предложит ему нечто желаемое.

Ни один моркот по своей воле не расстанется с тем, чем владеет. Моркоты существуют, чтобы копить, и расстаются с имуществом, только если это помогает увеличить накопления.

Моркот знает каждый объект в своей коллекции и может отследить свои вещи даже через планы. Если кто-то осмелится украсть у моркота или нарушит сделку с ним, то он не будет знать покоя пока моркот не умрёт или обещания не будут выполнены.`,
      source: {
        id: SOURCE_VGTM,
        page: 170,
      },
    },
    {
      header: 'Логово моркота',
      text: `Моркот владеет всем островом и поддерживает центральное убежище на нём. Это логово часто является извилистой системой узких тоннелей, которые соединяют несколько подземных комнат, хотя могут присутствовать и другие структурные формы. Моркот живёт среди своих самых ценных экспонатов в просторном хранилище в сердце своих владений, и там же находится жемчужная материя острова. Части логова и его центр могут быть сухими, чтобы лучше защитить и сохранить собранные объекты и существ, но большая часть логова находится под водой.

Моркот, встреченный в своём логове, имеет опасность 12 (8,400 опыта).

# Действия логова

Сражаясь в своём логове, моркот может вызывать охватывающую остров магию и совершать действия логова. По инициативе 20 (проигрыш при ничье), моркот совершает действие логова и вызывает один из эффектов, описанных ниже:
 
* Моркот использует _Гипноз_, в точке не далее 120 футов от себя. Ему не нужно видеть точку. 
* Моркот сотворяет [Тьму](SPELL:${SPELL_DARKNESS}), [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) или [Туманный шаг](SPELL:${SPELL_MISTY_STEP}), используя Интеллект как заклинательную способность и без траты ячейки заклинаний.

# Воздействие на местность

Остров, окружающий логово моркота, изменён присутствием существа, что создаёт следующие эффекты: 

* Моркот узнает о новоприбывших, будь то объекты или существа, на острове или в логове. Действием моркот может определить, где находится любое существо или объект на острове. Посетителям острова кажется, что за ними следят, даже если это не так. 
* Каждый раз, когда существо, пробывшее на острове меньше года, завершает короткий или долгий отдых, оно должно совершить проверку Интеллекта (Анализ) СЛ 10. При провале существо теряет одну вещь (выбранную игроком, если это существо — персонаж игрока). Вещь находится неподалеку, но скрыта на некоторое время и её можно обнаружить, пройдя проверку Мудрости (Внимательность) СЛ 15. Если вещь утеряна, но не возвращена, то она оказывается в логове моркота через 1 час. Если существо позже приходит в логово моркота, то его утерянное имущество выделяется для его восприятия и его легко вернуть. 
* На входы в логово моркота наложены чары, так что моркот может активировать или выключить их в любое время, пока находится в логове и дееспособен. Любое существо не далее 30 футов от входа, которое может его видеть, должно пройти испытание Мудрости СЛ 15. При провале существо чувствует невероятную тягу потратить свое передвижение на каждом ходу, чтобы войти в логово и двигаться к местоположению моркота (цель не осознаёт, что приближается к существу). Цель движется к моркоту по самому короткому пути. Когда цель видит моркота, она может повторить испытание, и при успехе закончить этот эффект. Она так же может повторять испытание в конце каждого своего хода и каждый раз, когда получает урон. 
* Мысленно (действие не требуется), моркот может начать изменение воды в своем логове, что возымеет эффект через 1 минуту. Водой можно дышать и она такая же чистая как воздух, либо это может быть обычная вода (по прозрачности от мутной до чистой).

Если моркот умирает, то воздействия на местность оканчиваются незамедлительно.`,
      source: {
        id: SOURCE_VGTM,
        page: 170,
      },
    },
  ],
  note: {
    text: `Собиратели всего странного, необычного и ценного — надейтесь, что не вас.`,
    author: `Воло`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 170,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 25,
    [SPEED_SWIM]: 50,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_DOUBLE,
    [SKILL_HISTORY]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_11,
  featureList: [
    ABILITY_AMPHIBIOUS,
  ],
  spellCast: {
    spellCasterLevel: 11,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_ACID_SPLASH,
      SPELL_CHAIN_LIGHTNING,
      SPELL_DARKNESS,
      SPELL_DETECT_MAGIC,
      SPELL_DETECT_THOUGHTS,
      SPELL_DIMENSION_DOOR,
      SPELL_DISPEL_MAGIC,
      SPELL_EVARD_S_BLACK_TENTACLES,
      SPELL_GEAS,
      SPELL_IDENTIFY,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGE_HAND,
      SPELL_MENDING,
      SPELL_RAY_OF_FROST,
      SPELL_SCRYING,
      SPELL_SENDING,
      SPELL_SHATTER,
      SPELL_SHIELD,
      SPELL_SHOCKING_GRASP,
      SPELL_WITCH_BOLT,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает либо две атаки _Укусом_ и одну _Щупальцем_, либо три — _Укусом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Щупальца',
      description: `Цель схвачена (СЛ высвобождения 14) если это Большое существо или меньше. До окончания захвата цель обездвижена и получает 15 (3к8 + 2) дробящего урона в начале каждого хода ★СУЩЕСТВО★ и ★СУЩЕСТВО★ не может использовать щупальца против другой цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Гипноз',
      description: `★СУЩЕСТВО★ проецирует 30 футовый конус магической энергии. Каждое существо в этой области должно пройти испытание Мудрости СЛ 17. При провале, ★СУЩЕСТВО★ очаровывает существо на 1 минуту. Будучи очарованной таким образом, цель пытается подобраться к ★СУЩЕСТВО★ как можно ближе, используя свои действия для Рывка, пока не окажется в 5 футах от ★СУЩЕСТВО★. Очарованная цель может повторять испытание в конце каждого своего хода и когда получает урон, окончив эффект на себе при успехе. Если испытание пройдено успешно или эффект заканчивается, то существо получает преимущество на испытания против _Гипноза_ этого ★СУЩЕСТВО★ на 24 часа.`,
    },
  ],
  reactionList: [
    {
      id: ABILITY_SPELL_REFLECTION,
      range: 120,
    },
  ],
}
