const {GEAR_BULLETS_MODERN} = require('./../../../gearIdList')
const {GENDER_MULTIPLE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  CAT_AMMUNITION_FIREARM,
  CAT_EQUIPMENT,
  CAT_FIREARMS,
  CAT_TECH_MODERN,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_BULLETS_MODERN,
  genderId: GENDER_MULTIPLE,
  name: 'Патроны, 10 штук',
  nameEn: 'Bullets (10)',
  nameByCase: {
    nominative: 'патроны',
    genitive: 'патронов',
    accusative: 'патроны',
    instrumental: 'патронами',
  },
  description: {
    header: 'Боеприпасы',
    subheader: 'Ammunition',
    text: `Боеприпасы огнестрельного оружия уничтожаются при использовании.

Современное оружие использует патроны.`,
    source: {
      id: SOURCE_DMG,
      page: 267,
    },
  },
  weight: 1,
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
  category: [
    CAT_AMMUNITION_FIREARM,
    CAT_EQUIPMENT,
    CAT_FIREARMS,
    CAT_TECH_MODERN,
  ],
}
