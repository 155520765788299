const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GHOUL,
  CREATURE_GNOLL,
  CREATURE_ORCUS,
  CREATURE_YEENOGHU,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PC_RACE_ELF} = require('./../../../../pcRaceIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Упырь`,
  nameEn: 'Ghoul',
  id: CREATURE_GHOUL,
  description: [
    {
      header: 'Упыри',
      text: `Упыри бродят по ночам группами, движимые жаждой человеческой плоти.`,
      source: {
        id: SOURCE_MM,
        page: 288,
      },
    },
    {
      header: 'Упыри',
      text: `Упыри, движимые неутолимым голодом к гуманоидной плоти, обычно охотятся стаями и по ночам.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: 'Пожиратели плоти',
      text: `Как черви или жуки-падальщики, упыри изобилуют там, где пировала смерть и властвует разложение. Упыри часто наведываются в места, где могут разжиться мертвечиной или вырванными из тела внутренностями. Если упырь не может найти мертвечину, он преследует живых. Упырь не может насытиться пожранным трупом, но, движимый неутолимым голодом, находится в постоянном поиске пищи. Плоть упыря не гниёт и не разлагается, и эта тварь, находясь без пищи в склепе или усыпальнице, может ожидать неисчислимые века.`,
      source: {
        id: SOURCE_MM,
        page: 288,
      },
    },
    {
      header: 'Порождения Бездны',
      text: `Упыри ведут своё происхождение из Бездны. Доресэйн, первый из их породы, был некогда [эльфом](PC_RACE:${PC_RACE_ELF}), поклонявшимся [Оркусу](CREATURE:${CREATURE_ORCUS}). Обратившись против собственного народа, он устраивал пиры из плоти человекоподобных рас во славу [Демонического Повелителя Нежити](CREATURE:${CREATURE_ORCUS}). В награду за служение [Оркус](CREATURE:${CREATURE_ORCUS}) превратил Доресэйна в первого [упыря](CREATURE:${CREATURE_GHOUL}). Доресэйн ревностно служил [Оркусу](CREATURE:${CREATURE_ORCUS}) в Бездне, превращая в [упырей](CREATURE:${CREATURE_GHOUL}) других слуг демонического повелителя, вплоть до вторжения [Йеногу](CREATURE:${CREATURE_YEENOGHU}), демонического повелителя [гноллов](CREATURE:${CREATURE_GNOLL}), лишившего Доресэйна его владений. Когда [Оркус](CREATURE:${CREATURE_ORCUS}) не заступился за него, Доресэйн обратился к эльфийским богам, моля о спасении, и те из жалости спасли его от неминуемой гибели. С тех пор [эльфы](PC_RACE:${PC_RACE_ELF}) неподвластны парализующим прикосновениям [упырей](CREATURE:${CREATURE_GHOUL}).`,
      source: {
        id: SOURCE_MM,
        page: 288,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 288,
  },
  armor: 12,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 10,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1,
  actionList: [
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: `Когти`,
      description: `Если цель — существо, не являющееся нежитью, она должна пройти испытание Телосложения СЛ 10, иначе станет парализованной на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
