const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_SKELETON,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_SHORTBOW,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_SCRAPS} = require('./../../../../armorTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_SKELETON} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {skeletonDescriptionList} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Скелет`,
  nameEn: 'Skeleton',
  id: CREATURE_SKELETON,
  description: skeletonDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 261,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_SCRAPS,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 15,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 5,
  },
  vulnerabilityList: [
    DAMAGE_BLUDGEONING,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ALL_KNOWN_AT_LIFE,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_4,
  actionList: [
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_SHORTBOW,
    },
  ],
  genderId: GENDER_MALE,
}
