const {GENDER_FEMALE} = require('./../../genderList')
const {CREATURE_TYPE_HAG} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_HAG,
  nameEn: 'Hag',
  genderId: GENDER_FEMALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'карга',
      genitive: 'карги',
      dative: 'карге',
      accusative: 'каргу',
      instrumental: 'каргой',
      prepositional: 'карге',
    },
    plural: {
      nominative: 'карги',
      genitive: 'карг',
      dative: 'каргам',
      accusative: 'карг',
      instrumental: 'каргами',
      prepositional: 'каргах',
    },
  },
}
