const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_FLYBY,
  ABILITY_LEGENDARY_RESISTANCE_3,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ancientElementalDescriptionList} = require('./../../../../textCommonParts')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_16} = require('./../../../../crList')
const {CREATURE_PHOENIX} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Феникс',
  nameEn: 'Phoenix',
  id: CREATURE_PHOENIX,
  description: [
    `Высвобождение феникса из Внутренних Планов создаёт взрыв огня, который расходится по небу. Огромная огненная птица формируется посреди огня и дыма… Древнейший элементаль, одержимый потребностью сжечь всё дотла, Феникс редко надолго остаётся на одном месте, поскольку стремится превратить весь мир в ад.`,
    ...ancientElementalDescriptionList,
  ],
  note: {
    text: `Возрождаться подобно фениксу из пепла — как же много существ используют это необычное разговорное выражение. Но они даже не догадываются об истинном ужасе такого возрождения.`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MTOF,
    page: 162,
  },
  armor: 18,
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 120,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 26,
    [PARAM_CON]: 25,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 21,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_16,
  featureList: [
    {
      name: 'Сгореть и возродиться',
      description: `Когда феникс умирает, он взрывается. Все существа в 60 футах от него должны пройти испытание Ловкости СЛ 20, получая 22 (4к10) огненного урона при провале, или половину этого урона при успехе. Огонь поджигает никем не несомые и не носимые воспламеняемые объекты в зоне поражения.

Взрыв уничтожает тело феникса, оставляя от него горку пепла в форме яйца. Пепел раскалён, и наносит 21 (6к6) огненного урона любому, кто касается его, но не чаще раза в раунд. Пепел невосприимчив ко всем типам урона, и после 1к6 дней из него появляется новый феникс.`,
    },
    {
      name: 'Огненное тело',
      description: `Феникс может проходить через пространства шириной хотя бы в 1 дюйм не протискиваясь. Любое существо, которое касается феникса или попадает по нему ближней атакой, находясь в пределах 5 футов от него, получает 5 (1к10) огненного урона. Более того, феникс может войти в пространство врага и остаться там. В первый раз, когда он входит в пространство существа в свой ход, существо получает 5 (1к10) огненного урона. Касанием феникс может поджигать никем не несомые и не носимые воспламеняемые объекты (не требует действия).`,
    },
    ABILITY_FLYBY,
    {
      name: 'Свечение',
      description: `Феникс распространяет яркий свет на 60 футов и тусклый ещё на 30.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    {
      name: 'Осадное чудовище',
      description: `Феникс наносит двойные повреждения объектам и строениям.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Феникс совершает две атаки: одну _Клювом_ и одну _Пылающими когтями_.`,
    },
    {
      name: 'Клюв',
      description: `Если цель — это существо или воспламеняемый объект, она загорается. Пока существо не собьёт огонь действием, оно получает 5 (1к10) огненного урона в начале каждого своего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 15,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 2,
          diceType: 6,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Пылающие когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 15,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 2,
          diceType: 8,
          diceBonus: 8,
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Удар клювом',
      description: `Феникс делает одну атаку _Клювом_.`,
    },
    {
      name: 'Перемещение',
      description: `Феникс перемещается на расстояние, не превышающее его скорость.`,
    },
    {
      name: 'Налёт',
      cost: 2,
      description: `Феникс перемещается на расстояние, не превышающее его скорость и атакует _Пылающими когтями_.`,
    },
  ],
}
