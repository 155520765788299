import React from 'react'
import PropTypes from 'prop-types'

import './IconStyles.less'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconComponent = ({ icon, className = '' }) => {
  if (icon) {
    if (typeof icon === 'string') {
      if (icon.includes('data:image/svg+xml;base64')) {
        return (
          <span
            className={`Icon Icon-base64 ${className}`}
            style={{backgroundImage: `url(${icon})`}}
          />
        )
      } else if (icon.includes('/static/') || icon.includes('data:image/png')) {
        return (
          <span
            className={`Icon Icon-img ${className}`}
            style={{
              backgroundImage: `url(${icon})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              display: 'inline-block',
            }}
          />
        )
      } else {
        return (
          <span className={`${icon} Icon ${className}`}/>
        )
      }
    }

    return (
      <FontAwesomeIcon
        icon={icon}
        className={`Icon ${className}`}
      />
    )
  }

  return null
}

IconComponent.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

IconComponent.defaultProps = {
  icon: '',
}

export default IconComponent
