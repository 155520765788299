const {LANG_TYPE_STANDARD} = require('./../../languageTypeList')
const {LANG_ITS_SUMMONER} = require('./../../languageIdList')

module.exports = {
  id: LANG_ITS_SUMMONER,
  type: LANG_TYPE_STANDARD,
  name: {
    nominative: 'Языки своего призывателя',
    genitive: 'Языков своего призывателя',
    instrumental: 'Языками своего призывателя',
    prepositional: 'Языках своего призывателя',
  },
  isReady: false,
  isRealLang: false,
}
