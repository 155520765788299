const {
  CREATURE_ETTIN,
  CREATURE_GREEN_HAG,
  CREATURE_HILL_GIANT,
  CREATURE_OGRE,
  CREATURE_ORC,
} = require('./../../creatureIdList')
const {
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')
const {GOD_VAPRAK} = require('./../../godIdList')
const vaprak = require('./vaprak')

module.exports = [
  {
    header: 'Тролли',
    text: `Родившиеся с чудовищным аппетитом, тролли едят всё, что могут поймать и запихнуть в пасть. У них нет никакого общества, о котором стоит упомянуть, но они служат наёмниками у [орков](CREATURE:${CREATURE_ORC}), [огров](CREATURE:${CREATURE_OGRE}), [эттинов](CREATURE:${CREATURE_ETTIN}), [карг](CREATURE:${CREATURE_GREEN_HAG}) и [великанов](CREATURE:${CREATURE_HILL_GIANT}). В качестве платы тролли требуют пищу и сокровища. Троллей трудно контролировать, и они делают то, что им заблагорассудится, даже когда работают на более могущественных существ.`,
    source: {
      id: SOURCE_MM,
      page: 286,
    },
  },
  {
    header: 'Регенерация',
    text: `Ломание тролльих костей и разрубание его упругой шкуры только злит тролля. Его раны быстро затягиваются. Если чудище теряет руку, ногу или даже голову, эти части иногда начинают жить своей жизнью. Тролль даже может прикрепить отрубленный орган обратно, при этом временная травма его совершенно не будет беспокоить. Только огонь или кислота могут остановить регенерацию плоти тролля. Разъярённые тролли будут в первую очередь атаковать тех, кто использовал против них огонь и кислоту.`,
    source: {
      id: SOURCE_MM,
      page: 286,
    },
  },
  {
    header: 'Тролли-уроды',
    text: `Регенеративная природа троллей делает их подверженными мутациям. Эти нечастые явления могут происходить в результате того, что сделал тролль, или что сделали с ним другие. Из обрубка шеи тролля, которому срубили голову, может вырасти две новые, а если тролль съест фейское существо, он может получить одну или несколько особенностей этого существа.`,
    source: {
      id: SOURCE_MM,
      page: 286,
    },
  },
  {
    header: 'Тролли',
    text: `Тролли, что после уничтожения смогли выжить и восстановить раны на своём теле, проявляют аномальные мутации. Часто подобные искаженные тролли появляются, если существо восстанавливается в присутствии магического излучения, планарной энергии, заболеваний, смертей в широких масштабах, или если тело было повреждено стихийными силами. Этих мутированных созданий также можно получить и сформировать с помощью обрядной магии злых заклинателей.`,
    source: {
      id: SOURCE_MTOF,
      page: 232,
    },
  },
  vaprak,
]
