// Use “spellRawList”, bcz “spellList” already
// imported this file (pcSubClassList.js)
const spellRawList = require('./../constants/spellList/spellRawList')
const { spellIdCollectionByPcClassId } = require('./../constants/spellIdPcClassIdBounds')

const pickPcClassSpellIdListByLvl = pcClassId => spellLvl => spellRawList.reduce(
  (list, { id, lvl }) => (
    lvl === spellLvl &&
    spellIdCollectionByPcClassId[pcClassId].includes(id)
  )
    ? [...list, id]
    : list,
  [],
)

module.exports = pickPcClassSpellIdListByLvl
