const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_CLASS_CLERIC} = require('./../../../pcClassIdList')
const {PC_SUBCLASS_SORCERER_DIVINE_SOUL} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {spellIdCollectionByPcClassId} = require('./../../../spellIdPcClassIdBounds')

const {
  SPELL_BANE,
  SPELL_BLESS,
  SPELL_CURE_WOUNDS,
  SPELL_INFLICT_WOUNDS,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
} = require('./../../../spellIdList')

const {
  FEATURE_DIVINE_MAGIC_SORCERER,
  FEATURE_DIVINE_MAGIC_SORCERER_CHAOS,
  FEATURE_DIVINE_MAGIC_SORCERER_EVIL,
  FEATURE_DIVINE_MAGIC_SORCERER_GOOD,
  FEATURE_DIVINE_MAGIC_SORCERER_LAW,
  FEATURE_DIVINE_MAGIC_SORCERER_NEUTRAL,
  FEATURE_EMPOWERED_HEALING,
  FEATURE_FAVORED_BY_THE_GODS,
  FEATURE_OTHERWORLDLY_WINGS,
  FEATURE_SPELLCASTING_SORCERER,
  FEATURE_UNEARTHLY_RECOVERY,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_DIVINE_MAGIC_SORCERER,
    name: `Божественная магия`,
    nameEn: `Divine Magic`,
    lvl: 1,
    text: `Ваша связь с божеством позволяет Вам изучать заклинания из класса жреца.

Когда Ваша особенность [Использование заклинаний](FEATURE:${FEATURE_SPELLCASTING_SORCERER}) позволяет Вам изучить или заменить заговор чародея или заклинание чародея 1 круга или выше, Вы можете выбрать новое заклинание из списка заклинаний жреца или списка заклинаний чародея. Вы должны соблюдать все остальные ограничения при выборе заклинания, но это заклинание для Вас становится заклинанием чародея.

Кроме того, выберите склонность к источнику Вашей божественной силы: добро, зло, принципы, хаос или нейтралитет. Вы изучаете дополнительное заклинание, основанное на этой склонности, как показано ниже. Это заклинание для Вас становится заклинанием чародея, но оно не учитывается при подсчете известных Вам заклинаний чародея. Если Вы позже замените это заклинание, Вы должны заменить его заклинанием из списка заклинаний жреца.`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_CLERIC],
  },
  {
    id: FEATURE_DIVINE_MAGIC_SORCERER_CHAOS,
    name: `Божественная магия: Хаос`,
    nameEn: `Divine Magic: Chaos`,
    lvl: 1,
    spellIdList: SPELL_BANE,
  },
  {
    id: FEATURE_DIVINE_MAGIC_SORCERER_EVIL,
    name: `Божественная магия: Зло`,
    nameEn: `Divine Magic: Evil`,
    lvl: 1,
    spellIdList: SPELL_INFLICT_WOUNDS,
  },
  {
    id: FEATURE_DIVINE_MAGIC_SORCERER_GOOD,
    name: `Божественная магия: Добро`,
    nameEn: `Divine Magic: Good`,
    lvl: 1,
    spellIdList: SPELL_CURE_WOUNDS,
  },
  {
    id: FEATURE_DIVINE_MAGIC_SORCERER_LAW,
    name: `Божественная магия: Принципы`,
    nameEn: `Divine Magic: Law`,
    lvl: 1,
    spellIdList: SPELL_BLESS,
  },
  {
    id: FEATURE_DIVINE_MAGIC_SORCERER_NEUTRAL,
    name: `Божественная магия: Нейтралитет`,
    nameEn: `Divine Magic: Neutral`,
    lvl: 1,
    spellIdList: SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  },
  {
    id: FEATURE_FAVORED_BY_THE_GODS,
    name: `Любимчик богов`,
    nameEn: `Favored by the Gods`,
    lvl: 1,
    text: `Божественная сила охраняет Вашу судьбу.

Если Вы проваливаете испытание или промахиваетесь броском атаки, Вы можете бросить 2к4 и добавить его к значению на кубике, возможно, изменив результат. После использования этой особенности Вы не сможете использовать ей снова, пока не закончите короткий или длинный отдых.`,
  },
  {
    id: FEATURE_EMPOWERED_HEALING,
    name: `Усиленное лечение`,
    nameEn: `Empowered Healing`,
    lvl: 6,
    text: `Божественная энергия, проходящая через Вас, может усилить лечащие заклинания.

Всякий раз, когда Вы или союзник в пределах 5 футов от Вас бросаете кубики, чтобы определить количество хитов, которое восстанавливается заклинанием, Вы можете потратить 1 единицу чародейства чтобы один раз перебросить любое количество кубиков, при условии, что Вы не недееспособны. Вы можете использовать эту особенность только один раз за ход.`,
  },
  {
    id: FEATURE_OTHERWORLDLY_WINGS,
    name: `Крылья из другого мира`,
    nameEn: `Otherworldly Wings`,
    lvl: 14,
    text: `Вы можете использовать бонусное действие, чтобы расправить пару спектральных крыльев за спиной. Пока крылья находятся там, Вы получаете скорость полёта 30 футов. Крылья не рассеиваются пока Вы дееспособны, и до тех пор, пока Вы не умрёте или не отключите их бонусным действием.

Склонность, которую Вы выбрали для своей особенности [Божественная магия](FEATURE:${FEATURE_DIVINE_MAGIC_SORCERER}), определяет внешний вид спектральных крыльев:

* крылья орла для добра или закона,
* крылья летучих мыши для зла или хаоса,
* крылья стрекозы для нейтралитета.
`,
  },
  {
    id: FEATURE_UNEARTHLY_RECOVERY,
    name: `Неземное восстановление`,
    nameEn: `Unearthly Recovery`,
    lvl: 18,
    text: `Вы получаете возможность переносить серьёзнейшие повреждения.

Вы можете бонусным действием восстановить половину своих максимальных хитов в случае, если у Вас осталось меньше половины хитов.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_SORCERER_DIVINE_SOUL,
    source: {
      id: SOURCE_XGTE,
      page: 60,
    },
  })
)

