module.exports = [
  require('./giant_ape'),
  require('./giant_badger'),
  require('./giant_bat'),
  require('./giant_boar'),
  require('./giant_centipede'),
  require('./giant_constrictor_snake'),
  require('./giant_crab'),
  require('./giant_crocodile'),
  require('./giant_eagle'),
  require('./giant_elk'),
  require('./giant_fire_beetle'),
  require('./giant_frog'),
  require('./giant_goat'),
  require('./giant_hyena'),
  require('./giant_lizard'),
  require('./giant_octopus'),
  require('./giant_owl'),
  require('./giant_poisonous_snake'),
  require('./giant_rat'),
  require('./giant_scorpion'),
  require('./giant_sea_horse'),
  require('./giant_shark'),
  require('./giant_spider'),
  require('./giant_toad'),
  require('./giant_vulture'),
  require('./giant_wasp'),
  require('./giant_weasel'),
  require('./giant_wolf_spider'),
]
