const {PC_SUBCLASS_MONK_WAY_OF_THE_DRUNKEN_MASTER} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {GEAR_BREWERS_SUPPLIES} = require('./../../../gearIdList')

const {
  FEATURE_BONUS_PROFICIENCIES_DRUNKEN_MASTER,
  FEATURE_DRUNKARD_S_LUCK,
  FEATURE_DRUNKEN_TECHNIQUE,
  FEATURE_INTOXICATED_FRENZY,
  FEATURE_KI,
  FEATURE_TIPSY_SWAY,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BONUS_PROFICIENCIES_DRUNKEN_MASTER,
    name: `Дополнительные владения`,
    nameEn: `Bonus Proficiencies`,
    text: `Вы получаете владение навыком Выступление, если у Вас его еще нет.

Ваша техника боевого искусства соединяет боевые тренировки с точностью танцора и выходками шута. Вы также получаете владение [инструментами пивовара](GEAR:${GEAR_BREWERS_SUPPLIES}), если у Вас его ещё нет.`,
    lvl: 3,
  },
  {
    id: FEATURE_DRUNKEN_TECHNIQUE,
    name: `Техника пьяницы`,
    nameEn: `Drunken Technique`,
    text: `Вы учитесь быстро поворачиваться во время использования [Шквала ударов](FEATURE:${FEATURE_KI}).

Когда Вы используете [Шквал ударов](FEATURE:${FEATURE_KI}), то получаете все преимущества от Отхода, а Ваша скорость передвижения увеличивается на 10 футов до конца этого хода.`,
    lvl: 3,
  },
  {
    id: FEATURE_TIPSY_SWAY,
    name: `Пьяная походка`,
    nameEn: `Tipsy Sway`,
    text: `Вы можете двигаться странным, покачивающимся образом. Вы получаете следующие преимущества.

# Вскочить на ноги

Когда Вы сбиты с ног, Вы можете встать, потратив 5 футов движения вместо половины скорости.

# Перенаправить атаку

Когда существо промахивается по Вам атакой ближнего боя, Вы можете реакцией потратить 1 очко Ци, чтобы эта атака попала по одному существу по Вашему выбору, кроме атакующего, которое Вы можете видеть в радиусе 5 футов от себя.`,
    lvl: 6,
  },
  {
    id: FEATURE_DRUNKARD_S_LUCK,
    name: `Удача пьяницы`,
    nameEn: `Drunkard’s Luck`,
    text: `Удача улыбается Вам именно тогда, когда Вы готовы провалиться в небытие.

Совершая проверку способности, бросок атаки или проходя испытание с помехой, Вы можете использовать 2 очка Ци, чтобы отменить помеху на этот бросок.`,
    lvl: 11,
  },
  {
    id: FEATURE_INTOXICATED_FRENZY,
    name: `Опьяняющая ярость`,
    nameEn: `Intoxicated Frenzy`,
    text: `Вы получаете возможность нанести ошеломляющее число атак против группы противников.

Когда Вы используете [Шквал ударов](FEATURE:${FEATURE_KI}), Вы можете совершить до трёх дополнительных атак (до пяти атак [Шквала ударов](FEATURE:${FEATURE_KI}) в общем), при условии, что все атаки [Шквала ударов](FEATURE:${FEATURE_KI}) должны быть нацелены на разных существ в этом ходу.`,
    lvl: 17,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_THE_DRUNKEN_MASTER,
    source: {
      id: SOURCE_XGTE,
      page: 44,
    },
  })
)
