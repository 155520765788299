const {ALPHABET_DETHEK} = require('./../../alphabetList')
const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {primordialLanguageDescriptionList} = require('./../../textCommonParts')
const {SOURCE_DND3_5_MotP} = require('./../../sourceList')
const {
  LANG_AQUAN,
  LANG_PRIMORDIAL,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_AQUAN,
  alphabetId: ALPHABET_DETHEK,
  type: LANG_TYPE_EXOTIC,
  dialectOf: LANG_PRIMORDIAL,
  name: {
    nominative: 'Акван',
    genitive: 'Аквана',
    instrumental: 'Акваном',
    prepositional: 'Акване',
  },
  nameEn: 'Aquan',
  typicalSpeakers: 'Обитатели Стихийного Плана Воды',
  description: [
    {
      text: `Жители Стихийного Плана Воды говорят на Акване, плавном и текучем языке, полном двойных смыслов и каламбуров.`,
      source: {
        id: SOURCE_DND3_5_MotP,
        page: 77,
      },
    },
    ...primordialLanguageDescriptionList,
  ],
  isRealLang: true,
  isReady: true,
}
