const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GOD_BAHGTRU,
  GOD_CORELLON_LARETHIAN,
  GOD_GRUUMSH,
  GOD_LUTHIC,
} = require('./../../godIdList')

module.exports = {
  header: 'Первая семья',
  text: `Орочий пантеон, известный как Племя Того, Кто Зрит, это группа брутальных и жестоких богов, над которыми стоит их отец и вождь, [Груумш Одноглазый](GOD:${GOD_GRUUMSH}). Этот бог завоеваний, силы и выживания является яростным противником эльфийского божества [Кореллона Ларетиана](GOD:${GOD_CORELLON_LARETHIAN}). Говорят, что Груумш обязан потерей своего глаза меткой стреле [Кореллона](GOD:${GOD_CORELLON_LARETHIAN})

Богоматерь орков — [Лутик](GOD:${GOD_LUTHIC}), супруга Груумша, богиня плодовитости, пещер и ведовства.
  
[Богтру](GOD:${GOD_BAHGTRU}), сын Груумша, бог чистой, грубой силы, известен своей мощью, но часто презираем за свою бестолковость и самолюбие.`,
  source: {
    id: SOURCE_SCAG,
    page: 119,
  },
}
