import React from 'react'
import PropTypes from 'prop-types'

import './HeaderBlockStyles.less'

const HeaderBlockComponent = (
  {
    children,
    className,
    subHeader,
  },
) => (
  <header className={`HeaderBlock ${className}`}>
    {children}
    {
      subHeader && (
        <div className='HeaderBlock_subHeader'>
          {subHeader}
        </div>
      )
    }
  </header>
)

HeaderBlockComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  subHeader: PropTypes.string,
}

HeaderBlockComponent.defaultProps = {
  className: '',
  subHeader: '',
}

export default HeaderBlockComponent
