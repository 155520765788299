const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  PARAM_STR,
  PARAM_DEX,
} = require('./../../../../../paramList')
const {CONDITION_RESTRAINED} = require('./../../../../../conditionList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SPELL_WHIRLWIND} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_WHIRLWIND,
  name: 'Смерч',
  nameEn: 'Whirlwind',
  description: `В указанной Вами точке на поверхности земли возникает ревущий смерч. Он представляет собой цилиндр с радиусом 10 футов и высотой 30 футов. Пока заклинание действует, Вы можете действием переместить его на расстояние до 30 футов в любом направлении вдоль земли. Смерч засасывает любые предметы с размером не больше Среднего, ни на кого не надетые и никем не переносимые.

Существо должно пройти испытание Ловкости в момент, когда в первый раз за ход оно входит в область смерча или когда область смерча входит в пространство существа, включая момент первоначального возникновения смерча. При провале существо получает дробящий урон 10к6, а при успехе — половину этого урона. Кроме того, существо с размером не больше Большого, провалившее испытание, должно пройти испытание Силы, иначе будет обездвижено смерчем на время действия заклинания. В начале каждого хода обездвиженое существо подтягивается на 5 футов вверх, пока не окажется на вершине смерча. Обездвиженое существо перемещается вместе со смерчем и падает на землю, когда заклинание заканчивается, если у него нет возможности остаться в воздухе.

Обездвиженное существо может действием совершить проверку Силы или Ловкости против СЛ Ваших заклинаний. При успехе оно перестаёт быть обездвиженым и вылетает из смерча на 3к6 × 10 футов в случайном направлении.`,
  lvl: 7,
  magicSchoolId: MAGIC_EVOCATION,
  range: 300,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `соломинка`,
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: [
    {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_BLUDGEONING,
        halfOnSuccess: true,
        diceCount: 10,
        diceType: 6,
      },
    },
    {
      savethrowParam: PARAM_STR,
      condition: CONDITION_RESTRAINED,
    },
  ],
  source: [
    {
      id: SOURCE_XGTE,
      page: 167,
    },
    {
      id: SOURCE_EE,
      page: 22,
    },
  ],
}
