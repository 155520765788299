const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_NARIC} = require('./../../languageIdList')
const {LANG_SUBGROUP_LOW_ULUTIM} = require('./../../languageSubGroupList')

module.exports = {
  id: LANG_NARIC,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_LOW_ULUTIM,
  nameEn: 'Naric',
  name: {
    nominative: 'Нарик',
    genitive: 'Нарика',
    instrumental: 'Нариком',
    prepositional: 'Нарике',
  },
  isReady: false,
  isRealLang: true,
}
