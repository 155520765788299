const {
  SOURCE_BGDIA,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  GEAR_ALCHEMIST_S_FIRE,
} = require('./../../../../gearIdList')
const {
  CREATURE_HELL_WASP,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Адская оса',
  nameAlt: 'Оса преисподней',
  nameEn: 'Hellwasp',
  id: CREATURE_HELL_WASP,
  description: [
    {
      header: 'Адские осы',
      text: `Эти разумные и злобные насекомые водятся в Девяти Преисподних. У них жёсткий защитный панцирь, тонкие металлические крылья и сильные лапы с похожими на мечи когтями. Осы жалами вводят своим жертвам химическую смесь, схожую с [алхимическим огнём](GEAR:${GEAR_ALCHEMIST_S_FIRE}). В ней также содержится парализующий фермент, который обездвиживает жертву на время, достаточное для того, чтобы оса могла её унести.

Осы Преисподней любят тёплый климат, от сильного холода же их панцири трескаются, а обмен веществ останавливается. Поэтому они избегают ледяных кругов Девяти Преисподних (Стигии и Кании).`,
      source: {
        id: SOURCE_BGDIA,
        page: 242,
      },
    },
    {
      header: 'Гнёзда',
      text: `Адские осы отрыгивают золотистую желчь, похожую на янтарь, изх которой они создают исполинские гнёзда, полные внутренних помещений и переходов. В этих гнёздах осы совместно живут и хранят пищу. Типичное гнездо достаточно велико, чтобы вместить сообщество из 1к10 + 10 адских ос.

Каждая оса играет равную роль в их обществе.`,
      source: {
        id: SOURCE_BGDIA,
        page: 242,
      },
    },
    {
      header: 'Телепатия адских ос',
      text: `Осы общаются друг с другом при помощи особой формы телепатии, которую воспринимают только представители их вида.`,
      source: {
        id: SOURCE_BGDIA,
        page: 243,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_BGDIA,
    page: 242,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: {
      value: 60,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
  ],
  vulnerabilityList: [
    DAMAGE_COLD,
  ],
  immunityList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 300,
      comment: 'только с другими адскими осами',
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну — _жалом_ и одну — _мечевидными когтями_.`,
    },
    {
      name: 'Жало',
      description: `Цель должны пройти испытание Телосложения СЛ 12, при провале отравляясь на 1 минуту. Пока цель отравлена таким образом, она также парализована. Цель может повторять испытание в конце каждого своего хода, при успехе эффект для неё прекращается.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 4,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Мечевидные когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
