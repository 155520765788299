const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_RIFLE_HUNTING} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  WEAPON_AMMUNITION_MODERN,
  WEAPON_RELOAD,
  WEAPON_RANGE,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  CAT_FIREARMS,
  CAT_MARTIAL_RANGE_WEAPON,
  CAT_TECH_MODERN,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_RIFLE_HUNTING,
  name: 'Винтовка',
  nameAlt: 'Винтовка, охотничья',
  nameEn: 'Rifle, hunting',
  nameByCase: {
    nominative: 'винтовка',
    genitive: 'винтовки',
    accusative: 'винтовку',
    instrumental: 'винтовкой',
  },
  weight: 8,
  damageType: DAMAGE_PIERCING,
  damage: {
    diceCount: 2,
    diceType: 10,
  },
  genderId: GENDER_FEMALE,
  category: [
    CAT_FIREARMS,
    CAT_MARTIAL_RANGE_WEAPON,
    CAT_TECH_MODERN,
  ],
  weaponPropList: [
    WEAPON_AMMUNITION_MODERN,
    WEAPON_TWO_HANDED,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 80,
        max: 240,
      },
    },
    {
      id: WEAPON_RELOAD,
      value: 5,
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
