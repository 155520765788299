const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_LIZARDFOLK,
} = require('./../../../../creatureTypeIdList')
const {
  lizardfolkNotes,
  lizardfolkDescriptionList,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_HOLD_BREATH} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_LIZARDFOLK_KING_QUEEN} = require('./../../../../creatureIdList')
const {GEAR_TRIDENT} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Король/королева ящеров',
  nameEn: 'Lizard King/Queen',
  note: lizardfolkNotes,
  description: lizardfolkDescriptionList,
  id: CREATURE_LIZARDFOLK_KING_QUEEN,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_LIZARDFOLK,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 198,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 15,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_SURVIVAL]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_DRACONIC,
  ],
  cr: CR_4,
  featureList: [
    {
      id: ABILITY_HOLD_BREATH,
      time: '15 минут',
    },
    {
      name: 'Пронзание',
      description: `Один раз в ход, когда ★СУЩЕСТВО★ совершает рукопашную атаку _Трезубцем_ и попадает, цель получает от атаки дополнительный урон 10 (3к6), а ★СУЩЕСТВО★ получает временные хиты, количество которых равно этому дополнительному урону.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну либо _Когтями_, либо _Трезубцем_, или же две рукопашные атаки _Трезубцем_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_TRIDENT,
    },
  ],
  genderId: GENDER_MALE,
}
