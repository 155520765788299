const artificerSpellCircleList  = [1, 1, 5, 9, 13, 17]
const fullCasterSpellCircleList = [1, 1, 3, 5, 7, 9, 11, 13, 15, 17]
const druidCircleSpellCircleList = [2, 2, 3, 5, 7, 9]
const halfCasterSpellCircleList = [2, 2, 5, 9, 13, 17]
const halfCasterSubClassSpellCircleList  = [3, 3, 5, 9, 13, 17]
const thirdCasterSpellCircleList = [3, 3, 7, 13, 19]
const warlockSpellCircleList    = fullCasterSpellCircleList

module.exports = {
  artificerSpellCircleList,
  druidCircleSpellCircleList,
  fullCasterSpellCircleList,
  halfCasterSpellCircleList,
  halfCasterSubClassSpellCircleList,
  thirdCasterSpellCircleList,
  warlockSpellCircleList,
}

module.exports.artificerSpellCircleList = artificerSpellCircleList
module.exports.druidCircleSpellCircleList = druidCircleSpellCircleList
module.exports.fullCasterSpellCircleList = fullCasterSpellCircleList
module.exports.halfCasterSpellCircleList = halfCasterSpellCircleList
module.exports.halfCasterSubClassSpellCircleList = halfCasterSubClassSpellCircleList
module.exports.thirdCasterSpellCircleList = thirdCasterSpellCircleList
module.exports.warlockSpellCircleList = warlockSpellCircleList
