const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MAGIC_ITEM_RING_OF_TELEKINESIS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_TELEKINESIS} = require('./../../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_RING_OF_TELEKINESIS,
  name: `Кольцо телекинеза`,
  nameEn: `Ring of Telekinesis`,
  type: MGC_TYPE_RING,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `Нося это кольцо, Вы можете неограниченно сотворять заклинание [Телекинез](SPELL:${SPELL_TELEKINESIS}), но нацеливаясь только на предметы, которые никто не несёт и не носит. `,
  genderId: GENDER_MIDDLE,
  spellIdList: [SPELL_TELEKINESIS],
  source: {
    id: SOURCE_DMG,
    page: 178,
  },
}
