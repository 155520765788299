const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_POISON,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_DRAGON_S_BREATH} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_DRAGON_S_BREATH,
  name: 'Дыхание дракона',
  nameEn: 'Dragon’s Breath',
  description: `Вы касаетесь одного согласного существа и наделяете его силой изрыгать магическую энергию изо рта, при условии, что у существа есть рот. Выберите кислоту, холод, огонь, электричество или яд. Пока заклинание не закончится, существо может использовать действие, чтобы выдыхать энергию выбранного типа в 15-футовом конусе. Каждое существо в области должно пройти испытание Ловкости, и получить 3к6 урона выбранного типа при провале испытания или вдвое меньший урон при успехе.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку заклинания 3 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше 2.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `острый перец`,
  duration: {timeId: TIME_MINUTE, count: 1},
  castTime: {timeId: TIME_BONUS_ACTION, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: [
        DAMAGE_ACID,
        DAMAGE_COLD,
        DAMAGE_ELECTRICITY,
        DAMAGE_FIRE,
        DAMAGE_POISON,
      ],
      halfOnSuccess: true,
      diceCount: 3,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 151,
  },
}
