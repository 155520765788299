const {abilityScoreImprovementTemplate} = require('./../featureCommonRawList')
const {fullCasterSpellCircleList} = require('./../../spellCircleByCasterType')
const {GEAR_SPELLBOOK} = require('./../../gearIdList')
const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
} = require('./../../sourceList')
const {SPELL_MAGIC_MISSILE} = require('./../../spellIdList')
const {spellIdCollectionByPcClassId} = require('./../../spellIdPcClassIdBounds')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_WIZARD,
  FEATURE_ARCANE_RECOVERY,
  FEATURE_ARCANE_TRADITION,
  FEATURE_CANTRIP_FORMULAS,
  FEATURE_SIGNATURE_SPELLS,
  FEATURE_SPELL_MASTERY,
  FEATURE_SPELLBOOK,
  FEATURE_SPELLCASTING_FOCUS_WIZARD,
  FEATURE_SPELLCASTING_WIZARD,
  FEATURE_CANTRIPS_WIZARD,
  FEATURE_PREPARING_SPELLS_WIZARD,
  FEATURE_SPELLCASTING_ABILITY_WIZARD,
  FEATURE_SPELL_KNOWN_WIZARD,
  FEATURE_RITUAL_CASTING_WIZARD,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_SPELLCASTING_WIZARD,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 1,
    text: `Являясь учеником тайной магии, Вы обладаете [Книгой](FEATURE:${FEATURE_SPELLBOOK}), содержащей заклинания, показывающие первые проблески Вашей истинной силы.`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_WIZARD],
    source: {
      id: SOURCE_PHB,
      page: 114,
    },
  },
  {
    id: FEATURE_CANTRIPS_WIZARD,
    name: `Заговоры`,
    nameEn: `Cantrips`,
    lvl: 1,
    text: `На 1 уровне Вы знаете три заговора на Ваш выбор из списка заклинаний волшебника.

Вы узнаёте дополнительные заговоры волшебника на более высоких уровнях, как показано в колонке «Известно заговоров».

## Известные заговоры

| Уровень волшебника | Известно заговоров |
|-|-|
|  1–3  | 3 |
|  4–9  | 4 |
| 10–20 | 5 |`,
    source: {
      id: SOURCE_PHB,
      page: 114,
    },
  },
  {
    id: FEATURE_SPELLBOOK,
    name: `Книга заклинаний`,
    nameEn: `Spellbook`,
    lvl: 1,
    text: `На первом уровне у Вас есть _Книга заклинаний_, содержащая шесть заклинаний волшебника 1 круга по Вашему выбору. Она хранит все известные Вам заклинания Волшебника, кроме заговоров, которые зафиксированы в Вашем разуме.

Заклинания, которые Вы добавляете в свою _Книгу заклинаний_, получая уровни, отражают исследования, которые Вы проводите, а также интеллектуальные прорывы в изучении природы вселенной, сделанные Вами. Вы также можете найти другие заклинания во время приключений. Вы можете обнаружить заклинание, записанное на свитке в сундуке злого волшебника, или, например, в пыльном фолианте в древней библиотеке.

# Копирование заклинания в книгу

Если Вы находите заклинание волшебника 1 или более высокого круга, то можете добавить его в свою _Книгу заклинаний_, если оно имеет такой круг, заклинания которого Вы можете подготавливать, и у Вас имеется свободное время для его расшифровки и копирования.

Копирование заклинания в Вашу _Книгу заклинаний_ включает воспроизведение основной формы заклинания, а также расшифровку уникальной системы обозначений, используемой волшебником, который записал его. Вы должны подобрать нужные жесты и звуки, после чего записываете его в свою книгу заклинаний, используя собственные обозначения.

За каждый круг заклинания процесс занимает 2 часа и стоит 50 золотых монет. Стоимость представляет собой материальные компоненты, которые Вы расходуете на эксперименты с заклинанием, чтобы им овладеть, а также высококачественные чернила для записи текста. После того как Вы потратили время и деньги, Вы можете подготавливать это заклинание как и свои прочие заклинания.

# Замена книги

Вы можете копировать заклинание из своей _Книги заклинаний_ в другую, например, если хотите сделать запасную копию. Это делается так же как и простое копирование заклинаний, но быстрее и проще, так как Вы понимаете свои собственные обозначения и уже знаете, как творить это заклинание. Вам необходимо потратить только по 10 золотых монет и 1 часу для каждого круга копируемого заклинания.

Если Вы потеряли свою _Книгу заклинаний_, Вы можете использовать ту же процедуру для записи заклинаний, которые у Вас подготовлены, в новую _Книгу заклинаний_. Для заполнения оставшейся части _Книги заклинаний_ потребует найти новые заклинания, чтобы вернуть всё как прежде. По этой причине многие волшебники держат запасные _Книги заклинаний_ в безопасном месте.

# Внешний вид книги

Ваша _Книга заклинаний_ является уникальным сборником заклинаний, со своими декоративными завитушками и ремаркам. Это может быть обычная, обтянутая кожей книга, которую Вы получили в подарок от наставника, или оплетённый золотом том, который Вы нашли в древней библиотеке, или даже разрозненная коллекция записей, собранная вместе после потери предыдущей книги заклинаний в результате несчастного случая.`,
    gearId: GEAR_SPELLBOOK,
    source: {
      id: SOURCE_PHB,
      page: 115,
    },
  },
  {
    id: FEATURE_PREPARING_SPELLS_WIZARD,
    name: `Подготовка и сотворение заклинаний`,
    nameEn: `Preparing and Casting Spells`,
    lvl: 1,
    text: `Таблица «Волшебник» показывает, какое количество ячеек для сотворения заклинаний у Вас есть на первом и более высоких уровнях. Для сотворения одного из этих заклинаний Вы должны потратить ячейку заклинаний того же круга или выше, что и само заклинание. Вы восстановите все потраченные ячейки, когда завершите длинный отдых.

Вы подготавливаете список заклинаний волшебника, доступных для сотворения. При этом Вы выбираете число заклинаний волшебника из своей [Книги заклинаний](FEATURE:${FEATURE_SPELLBOOK}), равное модификатору Интеллекта + уровень волшебника (минимум одно заклинание). Круг заклинаний не должен превышать круга самой высокой имеющейся у Вас ячейки заклинаний.

Например, если Вы волшебник 3 уровня, то у Вас есть четыре ячейки заклинаний 1 круга и две ячейки 2 круга. При Интеллекте 16 Ваш список подготовленных заклинаний может включать в себя шесть заклинаний 1 или 2 круга, в любой комбинации, выбранных из Вашей книги заклинаний. Если Вы подготовили заклинание 1 круга [Волшебная стрела](GEAR:${SPELL_MAGIC_MISSILE}), Вы можете сотворить его, используя ячейку 1 или 2 круга. Сотворение заклинания не удаляет его из списка подготовленных заклинаний.

Вы можете изменить список подготовленных заклинаний, когда завершаете длинный отдых. Подготовка нового списка заклинаний волшебника требует времени, проведённого в изучении [Книги заклинаний](FEATURE:${FEATURE_SPELLBOOK}) и запоминания слов и жестов, которые Вы должны совершить, чтобы сотворить заклинание: не менее 1 минуты за круг заклинания для каждого заклинания в Вашем списке.`,
    source: {
      id: SOURCE_PHB,
      page: 114,
    },
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_WIZARD,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 1,
    text: `При создании заклинаний волшебник использует Интеллект, так как Вы узнаёте свои заклинания специальными исследованиями и запоминанием.

Вы используете Интеллект в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете модификатор Интеллекта при определении СЛ испытаний от Ваших заклинаний волшебника, и при броске атаки заклинаниями.

**СЛ испытания = 8 + бонус мастерства + модификатор Интеллекта**

**Модификатор броска атаки = бонус мастерства + модификатор Интеллекта**`,
    source: {
      id: SOURCE_PHB,
      page: 114,
    },
  },
  {
    id: FEATURE_RITUAL_CASTING_WIZARD,
    name: `Ритуальное колдовство`,
    nameEn: `Ritual Casting`,
    lvl: 1,
    text: `Вы можете сотворить заклинание волшебника как ритуал, если у этого заклинания есть ключевое слово «ритуал», и оно есть в Вашей [Книге заклинаний](FEATURE:${FEATURE_SPELLBOOK}).

Вам не нужно иметь это заклинание подготовленным.`,
    source: {
      id: SOURCE_PHB,
      page: 114,
    },
  },
  {
    id: FEATURE_SPELLCASTING_FOCUS_WIZARD,
    name: `Фокусировка заклинания`,
    nameEn: `Spellcasting Focus`,
    lvl: 1,
    text: `Вы можете использовать магическую фокусировку в качестве заклинательной фокусировки для заклинаний волшебника.`,
    source: {
      id: SOURCE_PHB,
      page: 115,
    },
  },
  {
    id: FEATURE_SPELL_KNOWN_WIZARD,
    name: `Известные заклинания`,
    nameEn: `Learning Spells`,
    lvl: 1,
    text: `Каждый раз, когда Вы получаете уровень волшебника, Вы можете добавить два заклинания волшебника по Вашему выбору в [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}).

Круг этих заклинаний не должен превышать круга самой высокой имеющейся у Вас ячейки заклинаний. Во время приключений Вы можете найти другие заклинания, которые сможете добавить в [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}).`,
    source: {
      id: SOURCE_PHB,
      page: 114,
    },
  },
  {
    id: FEATURE_ARCANE_RECOVERY,
    name: `Магическое восстановление`,
    nameEn: `Arcane Recovery`,
    lvl: 1,
    text: `Вы знаете как восстанавливать часть магической энергии, изучая [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}).

Один раз в день, когда Вы заканчиваете короткий отдых, Вы можете восстановить часть использованных ячеек заклинаний. Ячейки заклинаний могут иметь суммарный круг, который не превышает половину уровня Вашего волшебника (округляя в большую сторону), и ни одна из ячеек не может быть шестого круга или выше.

Например, если Вы волшебник 4 уровня, Вы можете восстановить ячейки заклинаний с суммой кругов не больше двух. Вы можете восстановить одну ячейку заклинаний 2 круга, или две ячейки заклинаний 1 круга.`,
    source: {
      id: SOURCE_PHB,
      page: 116,
    },
  },
  {
    id: FEATURE_ARCANE_TRADITION,
    name: `Магические традиции`,
    nameEn: `Arcane Tradition`,
    lvl: [2, 6, 10, 14],
    text: `Вы выбираете магическую традицию, формирующую Вашу магическую практику.

Ваш выбор даёт Вам умения на 2, 6, 10 и 14 уровнях.`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 116,
    },
  },
  {
    id: FEATURE_CANTRIP_FORMULAS,
    name: `Формулы заговоров`,
    nameEn: `Cantrip Formulas`,
    lvl: 3,
    text: `Вы записали набор тайных формул в свою [книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), чтобы упростить себе формулирование заговоров в сознании.

Когда Вы завершаете длинный отдых и сверяетесь с этими формулами в [книге заклинаний](FEATURE:${FEATURE_SPELLBOOK}), то можете заменить один известный Вам заговор волшебника на другой.`,
    source: {
      id: SOURCE_TCoE,
      page: 114,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_WIZARD,
    source: {
      id: SOURCE_PHB,
      page: 116,
    },
  },
  {
    id: FEATURE_SPELL_MASTERY,
    name: `Мастерство заклинателя`,
    nameEn: `Spell Mastery`,
    lvl: 18,
    text: `Вы достигаете такого мастерства в отношении некоторых заклинаний, что можете творить их неограниченное количество раз.

Выберите одно заклинание волшебника 1 круга и одно заклинание волшебника 2 круга, которые есть в Вашей [Книге заклинаний](FEATURE:${FEATURE_SPELLBOOK}). Вы можете использовать эти заклинания без увеличения их круга, не тратя ячеек заклинаний, при условии, что Вы их подготовили. Если Вы хотите увеличить круг этих заклинаний, Вы должны потратить ячейку заклинаний как обычно.

Потратив 8 часов на обучение, Вы можете изменить одно или оба этих заклинания по своему усмотрению на заклинания тех же кругов.`,
    source: {
      id: SOURCE_PHB,
      page: 116,
    },
  },
  {
    id: FEATURE_SIGNATURE_SPELLS,
    name: `Фирменное заклинание`,
    nameEn: `Signature Spells`,
    lvl: 20,
    text: `Вы получаете власть над двумя мощными заклинаниями и можете творить их без усилий.

Выберите два заклинания волшебника 3 круга из своей [Книги заклинаний](FEATURE:${FEATURE_SPELLBOOK}) в качестве фирменных заклинаний. Для Вас эти заклинания всегда считаются подготовленными, они не учитываются в количестве подготовленных заклинаний, и Вы можете сотворить каждое из этих заклинаний 3 круга, не тратя ячейку заклинаний. Когда Вы так поступаете, Вы не можете сотворить их повторно таким же образом, пока не завершите короткий или длинный отдых.

Если Вы хотите сотворить заклинание более высокого круга, Вы должны потратить ячейку заклинаний как обычно.`,
    source: {
      id: SOURCE_PHB,
      page: 116,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_WIZARD,
  })
)
