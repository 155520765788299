const {CREATURE_TYPE_GITHYANKI} = require('./../../creatureTypeIdList')
const {GENDER_FEMALE} = require ('./../../genderList')
const {
  githyankiPureDescription,
  githyankiPureNote,
} = require ('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_GITHYANKI,
  nameEn: 'Githyanki',
  genderId: GENDER_FEMALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'гитьянки',
      genitive: 'гитьянки',
      dative: 'гитьянки',
      accusative: 'гитьянки',
      instrumental: 'гитьянки',
      prepositional: 'гитьянки',
    },
    plural: {
      nominative: 'гитьянки',
      genitive: 'гитьянок',
      dative: 'гитьянкам',
      accusative: 'гитьянок',
      instrumental: 'гитьянками',
      prepositional: 'гитъянках',
    },
  },
  description: githyankiPureDescription,
  note: githyankiPureNote,
}
