const {
  MAGIC_ENCHANTMENT,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  PARAM_WIT,
} = require('./../../../../../paramList')
const {
  SPELL_CONFUSION,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_CONFUSION,
  name: 'Смятение',
  nameEn: 'Confusion',
  description: `Это заклинание атакует и скручивает сознание существ, порождая галлюцинации и провоцируя на необдуманные действия. Все существа в сфере с радиусом 10 футов с центром на точке, выбранной в пределах дистанции, должны пройти испытание Мудрости, иначе попадут под действие эффекта.

Попавшая под эффект цель не может совершать реакции и должна бросать к10 в начале каждого своего хода, чтобы определить своё поведение в этом ходу.

| к10  | Поведение                                                     |
|------|---------------------------------------------------------------|
| 1    | Существо использует всё своё перемещение на движение в случайном направлении. Для определения направления бросьте к8, назначив каждой грани своё направление. Действие в этом ходу существо не совершает. |
| 2–6  | Существо в этом ходу не перемещается и не совершает действий. |
| 7–8  | Существо действием совершает рукопашную атаку по случайным образом выбранному существу в пределах досягаемости. Если в пределах досягаемости нет других существ, существо в этот ход ничего не делает. |
| 9–10 | Существо может действовать и перемещаться как обычно.         |

В конце каждого своего хода цель может проходить испытание Мудрости. При успехе на этой цели эффект заканчивается.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, радиус сферы увеличивается на 5 футов за каждый круг ячейки выше четвёртого.`,
  lvl: 4,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `три ореховые скорлупки`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 278,
    },
    {
      id: SOURCE_SRD,
      page: 127,
    },
  ],
}
