const {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    CREATURE_TYPE_GHOST,
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    SPEED_FLY,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_NECROTIC,
  } = require('./../../../../damageTypeList'),
  {
    ABILITY_INCORPOREAL_PASSAGE,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_GHOSTLY_UNDEAD_SPIRIT,
    CREATURE_SKELETAL_UNDEAD_SPIRIT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух призрачной нежити',
  nameEn: 'Ghostly Undead Spirit',
  id: CREATURE_GHOSTLY_UNDEAD_SPIRIT,
  parentId: CREATURE_SKELETAL_UNDEAD_SPIRIT,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  hp: `30 + 10-кратный круг заклинания выше 3-го`,
  extendPropCollection: {
    speed: {
      [SPEED_FLY]: {
        value: 40,
        isFloating: true,
      },
    },
  },
  featureList: [
    ABILITY_INCORPOREAL_PASSAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания.`,
    },
    {
      name: 'Смертельное касание',
      description: `Существо должно пройти испытание Мудрости, при провале пугаясь ★СУЩЕСТВО★ до конца своего следующего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
}
