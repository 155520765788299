const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN} = require('./../../../sourceList')
const {PC_SUBCLASS_MAGE_OF_QUANDRIX_2021_06_08} = require('./../../../pcSubClassIdList')
const {
  FEATURE_QUANDRIX_SPELLS_2021_06_08,
} = require('./../../../featureIdList')
const {
  SPELL_AURA_OF_VITALITY,
  SPELL_CIRCLE_OF_POWER,
  SPELL_CONTROL_WATER,
  SPELL_ENLARGE_REDUCE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GUIDANCE,
  SPELL_GUIDING_BOLT,
  SPELL_HASTE,
  SPELL_PASSWALL,
  SPELL_SPIKE_GROWTH,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_QUANDRIX_SPELLS_2021_06_08,
    name: `Заклинания Квандрикса`,
    nameEn: `Quandrix Spells`,
    lvl: 1,
    text: `You learn the cantrip guidance and the 1st-level spell guiding bolt. You learn additional spells when you reach certain levels in this class, as shown on the Quandrix Spells table.

Each of these spells counts as a class spell for you, but it doesn’t count against the number of spells you know. If you are a wizard, you can add these spells to your spellbook upon learning them, without expending any gold, and prepare them as normal.`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_AURA_OF_VITALITY,
      SPELL_CIRCLE_OF_POWER,
      SPELL_CONTROL_WATER,
      SPELL_ENLARGE_REDUCE,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_GUIDANCE,
      SPELL_GUIDING_BOLT,
      SPELL_HASTE,
      SPELL_PASSWALL,
      SPELL_SPIKE_GROWTH,
    ],
    source: {
      id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
      page: 6,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MAGE_OF_QUANDRIX_2021_06_08,
  })
)

