const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_CLOUD_GIANT,
  CREATURE_ELEPHANT,
  CREATURE_KILLER_WHALE,
  CREATURE_ROC,
  CREATURE_STORM_GIANT,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_KEEN_SIGHT} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_11} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Рух',
  nameEn: 'Roc',
  id: CREATURE_ROC,
  description: [
    {
      header: 'Рух',
      text: `На первый взгляд, силуэт рух похож на силуэт любой другой хищной птицы. Однако когда она приземляется, её ужасающий размер становится понятен. В полете размах крыльев рух может достигать более двух сотен футов. Сидя в покое на вершине скал, где гнездятся эти птицы, они могут посоревноваться в размере со старейшими драконами.`,
      source: {
        id: SOURCE_MM,
        page: 252,
      },
    },
    {
      header: 'Небесные титаны',
      text: `В древние времена, когда великаны сражались с драконами за контроль над миром, Аннам, отец богов великанов, создал этих птиц, чтобы его почитатели могли бросить вызов господству драконов в воздухе. Когда война кончилась, рух были освобождены от служенья великанам, и распространились по всему миру.

Хоть [облачные](CREATURE:${CREATURE_CLOUD_GIANT}) и [штормовые великаны](CREATURE:${CREATURE_STORM_GIANT}) иногда и укрощают этих птиц, рух даже великанов считают потенциальной добычей. Они пролетают огромные расстояния в поисках еды, паря высоко над облаками, чтобы достичь своих любимых охотничьих угодий. Рух редко охотятся на быстрых и маленьких существ, а также игнорируют города и леса, где добыча может легко укрыться. Выследив большую, медленно движущуюся цель, такую как великан, [кит](CREATURE:${CREATURE_KILLER_WHALE}) или [слон](CREATURE:${CREATURE_ELEPHANT}), рух пикирует вниз, хватая жертву длинными когтями.`,
      source: {
        id: SOURCE_MM,
        page: 252,
      },
    },
    {
      header: 'Изолированные и одинокие',
      text: `Рух — одиночные существа, которые могут жить несколько веков. Они строят свои гнёзда из деревьев, палаток, разбитых кораблей и остатков караванов, которые они уносят и размещают в горных ущельях, недоступных для мелких существ.

Иногда гнезда рух содержат в себе сокровища караванов или кораблей, нёсших их, но рух не обращают внимания на эти безделушки. Ещё реже в гнёздах можно найти яйца с человеческий рост и больше, появившиеся в результате нечастых спариваний рух.`,
      source: {
        id: SOURCE_MM,
        page: 252,
      },
    },
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 252,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 120,
  },
  params: {
    [PARAM_STR]: 28,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 20,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  cr: CR_11,
  featureList: [
    ABILITY_KEEN_SIGHT,
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Клювом_, и одну _Когтями_.`,
    },
    {
      name: `Клюв`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 9,
        },
      },
    },
    {
      name: `Когти`,
      description: `Цель становится схваченной (СЛ высвобождения 19). Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может использовать _Когти_ против другой цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
