const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GOBLINOID,
  CREATURE_TYPE_HOBGOBLIN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  hobgoblinDescriptionList,
  hobgoblinNote,
} = require('./../../../../textCommonParts')
const {
  GEAR_GREATSWORD,
  GEAR_HALF_PLATE,
  GEAR_JAVELIN,
} = require('./../../../../gearIdList')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_HOBGOBLIN_CAPTAIN} = require('./../../../../creatureIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Капитан хобгоблинов`,
  nameEn: 'Hobgoblin Captain',
  id: CREATURE_HOBGOBLIN_CAPTAIN,
  description: hobgoblinDescriptionList,
  note: hobgoblinNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_HOBGOBLIN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 294,
  },
  armor: {
    ac: 17,
    gearId: GEAR_HALF_PLATE,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 13,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GOBLIN,
    LANG_COMMON,
  ],
  cr: CR_3,
  featureList: [
    {
      name: `Воинское превосходство`,
      description: `Один раз в ход ★СУЩЕСТВО★ может причинить дополнительный урон 10 (3к6) существу, по которому ★он★ попал атакой оружием, если это существо находится в пределах 5 футов от ★его★ дееспособного союзника.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Двуручным мечом_.`,
    },
    {
      gearId: GEAR_GREATSWORD,
    },
    {
      gearId: GEAR_JAVELIN,
    },
    {
      name: `Лидерство`,
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `В течение 1 минуты ★СУЩЕСТВО★ может отдавать особые приказы и предупреждения, когда невраждебные существа, видимые ★им★ в пределах 30 футов, совершают бросок атаки или проходят испытание. Это существо может добавить к4 к своему броску, если слышит и понимает ★СУЩЕСТВО★. Существо может получать выгоду только от одного _Лидерства_ одновременно. Этот эффект оканчивается, если ★СУЩЕСТВО★ становится недееспособным.`,
    },
  ],
  genderId: GENDER_MALE,
}
