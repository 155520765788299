const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_POTION_OF_CLOUD_GIANT_STRENGTH,
  MAGIC_ITEM_POTION_OF_FIRE_GIANT_STRENGTH,
  MAGIC_ITEM_POTION_OF_FROST_GIANT_STRENGTH,
  MAGIC_ITEM_POTION_OF_HILL_GIANT_STRENGTH,
  MAGIC_ITEM_POTION_OF_STONE_GIANT_STRENGTH,
  MAGIC_ITEM_POTION_OF_STORM_GIANT_STRENGTH,
} = require('./../../../../../magicItemIdList')
const {
  CREATURE_CLOUD_GIANT,
  CREATURE_FIRE_GIANT,
  CREATURE_FROST_GIANT,
  CREATURE_HILL_GIANT,
  CREATURE_STONE_GIANT,
  CREATURE_STORM_GIANT,
} = require('./../../../../../creatureIdList')

module.exports = [
  {
    id: MAGIC_ITEM_POTION_OF_HILL_GIANT_STRENGTH,
    rarity: MGC_RARITY_UNCOMMON,
    giantNameRu: 'холмового',
    giantNameEn: 'hill',
    giantId: CREATURE_HILL_GIANT,
    str: 21,
  },
  {
    id: MAGIC_ITEM_POTION_OF_FROST_GIANT_STRENGTH,
    rarity: MGC_RARITY_RARE,
    giantNameRu: 'ледяного',
    giantNameEn: 'frost',
    giantId: CREATURE_FROST_GIANT,
    str: 23,
  },
  {
    id: MAGIC_ITEM_POTION_OF_STONE_GIANT_STRENGTH,
    rarity: MGC_RARITY_RARE,
    giantNameRu: 'каменного',
    giantNameEn: 'stone',
    giantId: CREATURE_STONE_GIANT,
    str: 23,
  },
  {
    id: MAGIC_ITEM_POTION_OF_FIRE_GIANT_STRENGTH,
    rarity: MGC_RARITY_RARE,
    giantNameRu: 'огненного',
    giantNameEn: 'fire',
    giantId: CREATURE_FIRE_GIANT,
    str: 25,
  },
  {
    id: MAGIC_ITEM_POTION_OF_CLOUD_GIANT_STRENGTH,
    rarity: MGC_RARITY_VERY_RARE,
    giantNameRu: 'облачного',
    giantNameEn: 'cloud',
    giantId: CREATURE_CLOUD_GIANT,
    str: 27,
  },
  {
    id: MAGIC_ITEM_POTION_OF_STORM_GIANT_STRENGTH,
    rarity: MGC_RARITY_LEGENDARY,
    giantNameRu: 'штормового',
    giantNameEn: 'storm',
    giantId: CREATURE_STORM_GIANT,
    str: 29,
  },
]
