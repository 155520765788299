const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_COMMON,
  LANG_GIANT,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  SPELL_DISGUISE_SELF,
  SPELL_FOG_CLOUD,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_FEY,
  CREATURE_TYPE_HAG,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_ANNIS_HAG,
  CREATURE_OGRE,
  CREATURE_TROLL,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_6} = require('./../../../../crList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {hagsDescriptionList} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Карга аннис',
  nameEn: 'Annis Hag',
  id: CREATURE_ANNIS_HAG,
  description: [
    `Карги аннис живут в горах или на холмах. Несмотря на то, что они горбатые сутулые, они самые большие и физически развитые среди всех карг и имеют около 8 футов роста.`,
    {
      header: 'Мучители слабых',
      text: `Хоть аннис карги и могут разорвать взрослого мужчину на части, они любят охотиться на детей, предпочитая их плоть всем остальным. Они используют содранную кожу таких жертв для всяческих изделий, а логово карги зачастую имеет признаки такой деятельности.

Карги аннис оставляют приметы своей жестокости на границах лесов и других областей в которых проживают. Поэтому они провоцируют страх и паранойю в окрестных деревнях и поселениях. Для карги аннис нет ничего более сладкого, чем превращение жизнерадостного сообщества в место, парализованное страхом, где народ никогда не гуляет по ночам, странников приветствуют с подозрение и злобой, а родители предупреждают детей: _«Будьте хорошими, а то вас схватит аннис»_.`,
      source: {
        id: SOURCE_VGTM,
        page: 153,
      },
    },
    {
      header: 'Развратительница детей',
      text: `Когда аннис чувствует особую жестокость, она маскируется под добро выглядящую старушку, которая подходит к ребёнку в уединенном месте и даёт ему железный жетон, который может использовать, чтобы ей доверились. Через некоторое время «бабуля» убеждает ребенка, что иметь плохие мысли и делать плохие вещи — это нормально, начиная от разбивания вещей и до прогулок вне дома без разрешения, а потом можно и столкнуть кого-нибудь с лестницы или поджечь дом. Рано или поздно семья ребенка и сообщество начинают бояться «дурной крови» и должны принять ужасное решение наказать ребенка или изгнать.`,
      source: {
        id: SOURCE_VGTM,
        page: 153,
      },
    },
    {
      header: 'Мать племени',
      text: `Примерно так же, как они становятся друзьями с детьми, чтобы их развратить, карги аннис имеют тенденцию усыновления группы [огров](CREATURE:${CREATURE_OGRE}), [троллей](CREATURE:${CREATURE_TROLL}) или других грубых существ, и управляют ими грубой силой, словесными ругательствами и суевериями.`,
      source: {
        id: SOURCE_VGTM,
        page: 153,
      },
    },
    {
      header: 'Шабаш',
      text: `Карга аннис, которая является частью шабаша, имеет опасность 8 (3,900 опыта).`,
      source: {
        id: SOURCE_VGTM,
        page: 153,
      },
    },
    {
      header: 'Железный жетон',
      text: `Карга аннис может вытащить один из своих железных зубов или ногтей и потратить 1 минуту, формируя и полируя его в виде монетки, кольца или крошечного зеркальца. После этого, любое существо, которое держит этот жетон, может шёпотом переговариваться с каргой, если существо и карга находятся на одном плане существования и не дальше 10 миль друг от друга. Держатель жетона может слышать лишь голос карги, но не существ около неё или окружающий её шум. Схожим образом карга слышит лишь держателя жетона, а не шум вокруг него.

Карга может иметь до трёх активных жетонов единовременно. Действием она может определить направление и примерное расстояние до всех своих активных жетонов. Она может мгновенно отключить любой из своих жетонов на любом расстоянии (действие не требуется), причем жетон оставляет свою форму, но теряет свои магические свойства.`,
      source: {
        id: SOURCE_VGTM,
        page: 153,
      },
    },
    ...hagsDescriptionList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_HAG,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 153,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 14,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
    LANG_SYLVAN,
  ],
  cr: CR_6,
  spellCast: {
    baseStat: PARAM_CHA,
    spellIdByCountList: [
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_FOG_CLOUD,
          {
            id: SPELL_DISGUISE_SELF,
            comment: 'может стать Среднего размера',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Аннис делает три атаки: одну _Укусом_ и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Сокрушительные объятия',
      description: `Цель схвачена (СЛ высвобождения 15) если она Большого размера или меньше. До окончания захвата цель получает 36 (9к6 + 5) дробящего урона в начале каждого хода карги. Карга не может совершать атак, пока держит в захвате существо таким образом.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 9,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
