const {SOURCE_ROT} = require('./../../../../sourceList')
const {cultOfDragonDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_CULT_FANATIC,
  CREATURE_DRAGON_CULT_FANATIC,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Фанатик Культа дракона',
  nameEn: 'Dragon Cult Initiate',
  id: CREATURE_DRAGON_CULT_FANATIC,
  parentId: CREATURE_CULT_FANATIC,
  description: cultOfDragonDescriptionList,
  source: {
    id: SOURCE_ROT,
    page: 10,
  },
}
