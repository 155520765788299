const {CREATURE_TYPE_KENDER} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_KENDER,
  nameEn: 'Kender',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'кендер',
        genitive: 'кендера',
        dative: 'кендеру',
        accusative: 'кендера',
        instrumental: 'кендером',
        prepositional: 'кендере',
      },
      [GENDER_FEMALE]: {
        nominative: 'кендерка',
        genitive: 'кендерки',
        dative: 'кендерке',
        accusative: 'кендерку',
        instrumental: 'кендеркой',
        prepositional: 'кендерке',
      },
    },
    plural: {
      nominative: 'кендеры',
      genitive: 'кендеров',
      dative: 'кендерам',
      accusative: 'кендеров',
      instrumental: 'кендерами',
      prepositional: 'кендерах',
    },
  },
}
