const {CREATURE_TYPE_GENASI_AIR} = require('./../../creatureTypeIdList')
const {GENDER_MALE} = require ('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GENASI_AIR,
  nameEn: 'Air genasi',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'генази воздуха',
      genitive: 'генази воздуха',
      dative: 'генази воздуха',
      accusative: 'генази воздуха',
      instrumental: 'генази воздуха',
      prepositional: 'генази воздуха',
    },
    plural: {
      nominative: 'генази воздуха',
      genitive: 'генази воздуха',
      dative: 'генази воздуха',
      accusative: 'генази воздуха',
      instrumental: 'генази воздуха',
      prepositional: 'генази воздуха',
    },
  },
}
