const {artificerSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_ARTIFICER_ALCHEMIST} = require('./../../../pcSubClassIdList')
const {SOURCE_ERFTLW} = require('./../../../sourceList')
const {SPELL_ALTER_SELF} = require('./../../../spellIdList')
const {
  GEAR_ALCHEMIST_S_SUPPLIES,
  GEAR_FLASK,
} = require('./../../../gearIdList')

const {
  SPELL_BLIGHT,
  SPELL_CLOUDKILL,
  SPELL_DEATH_WARD,
  SPELL_FLAMING_SPHERE,
  SPELL_GASEOUS_FORM,
  SPELL_GREATER_RESTORATION,
  SPELL_HEAL,
  SPELL_HEALING_WORD,
  SPELL_LESSER_RESTORATION,
  SPELL_MASS_HEALING_WORD,
  SPELL_MELF_S_ACID_ARROW,
  SPELL_RAISE_DEAD,
  SPELL_RAY_OF_SICKNESS,
} = require('./../../../spellIdList')

const {
  FEATURE_ALCHEMICAL_SAVANT,
  FEATURE_ALCHEMIST_SPELLS,
  FEATURE_CHEMICAL_MASTERY,
  FEATURE_EXPERIMENTAL_ELIXIR,
  FEATURE_RESTORATIVE_REAGENTS,
  FEATURE_TOOL_PROFICIENCY_ALCHEMIST,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ALCHEMIST_SPELLS,
    name: `Заклинания алхимика`,
    nameEn: `Alchemist Spells`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ALCHEMIST,
    text: `У Вас всегда подготовлены некоторые заклинания, когда Вы достигаете определённого уровня в этом классе.

Они не учитываются в количестве заклинаний изобретателя, которые Вы можете подготавливать и для Вас считаются заклинаниями изобретателя.`,
    spellCircleLvl: artificerSpellCircleList,
    spellIdList: [
      SPELL_BLIGHT,
      SPELL_CLOUDKILL,
      SPELL_DEATH_WARD,
      SPELL_FLAMING_SPHERE,
      SPELL_GASEOUS_FORM,
      SPELL_HEALING_WORD,
      SPELL_MASS_HEALING_WORD,
      SPELL_MELF_S_ACID_ARROW,
      SPELL_RAISE_DEAD,
      SPELL_RAY_OF_SICKNESS,
    ],
    source: {
      id: SOURCE_ERFTLW,
      page: 59,
    },
  },
  {
    id: FEATURE_TOOL_PROFICIENCY_ALCHEMIST,
    name: `Владение инструментами`,
    nameEn: `Tool Proficiency`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ALCHEMIST,
    text: `Вы получаете владение [инструментами алхимика](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}). Если Вы уже ими владеете, то получаете владение одним другим видом ремесленных инструментов по Вашему выбору.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 59,
    },
  },
  {
    id: FEATURE_EXPERIMENTAL_ELIXIR,
    name: `Экспериментальный эликсир`,
    nameEn: `Experimental Elixir`,
    lvl: [3, 6, 15],
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ALCHEMIST,
    text: `Заканчивая длинный отдых, Вы можете магически создать экспериментальный эликсир в пустой фляге, к которой прикасаетесь. Определите эффект эликсира броском по таблице «Экспериментальные эликсиры». Существо может действием выпить эликсир или влить его в рот недееспособному существу.

Для создания экспериментального эликсира Вы должны иметь с собой [алхимические инструменты](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}), и любой эликсир, созданный с помощью этой способности, действует до тех пор, пока он не будет выпит или до конца Вашего следующего длинного отдыха.

Когда Вы достигнете определенных уровней в этом классе, Вы сможете сделать больше эликсиров в конце длинного отдыха: два на 6 уровне и три на 15 уровне. Определите эффект каждого эликсира отдельно. Для каждого эликсира нужна своя [фляга](GEAR:${GEAR_FLASK}).

Вы можете создать дополнительные экспериментальные эликсиры, потратив на каждый по слоту заклинания 1-го круга или выше. При этом Вы действием создаёте эликсир в пустой [фляге](GEAR:${GEAR_FLASK}), к которой прикасаетесь, и выбираете эффект эликсира из таблицы «Экспериментальный эликсир».

# Экспериментальные эликсиры

| к6 | Эффект | Описание |
|-|-|-|
| 1  | Лечение     | Пьющий восстанавливает количество хитов, равное 2к4 + Ваш модификатор Интеллекта. |
| 2  | Скорость    | Скорость ходьбы пьющего увеличивается на 10 футов на 1 час.                       |
| 3  | Стойкость   | Пьющий получает +1 КД на 10 минут.                                                |
| 4  | Удаль       | Пьющий может добавить к4 ко всем броскам атаки и испытаниям на 10 минут.          |
| 5  | Полёт       | Пьющий получает скорость полёта 10 футов на 10 минут.                             |
| 6  | Превращение | Тело пьющего изменяется как будто под действием заклинания [Смена обличья](SPELL:${SPELL_ALTER_SELF}). Пьющий определяет эффект трансформации, и оно длится 10 минут. |
`,
    source: {
      id: SOURCE_ERFTLW,
      page: 59,
    },
  },
  {
    id: FEATURE_ALCHEMICAL_SAVANT,
    name: `Учёный-алхимик`,
    nameEn: `Alchemical Savant`,
    lvl: 5,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ALCHEMIST,
    text: `Вы оттачиваете владение магическими химикалиями, усиливая как исцеление, так и наносимый ими урон.

Каждый раз, когда Вы сотворяете заклинание, используя [алхимические инструменты](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}) как заклинательную фокусировку, Вы получаете бонус к одной из костей урона или лечения заклинания.

Эта кость должна либо восстанавливать хиты, либо наносить некротический урон, урон кислотой, огнём или ядом. Бонус равен Вашему модификатору Интеллекта (минимум +1).`,
    source: {
      id: SOURCE_ERFTLW,
      page: 59,
    },
  },
  {
    id: FEATURE_RESTORATIVE_REAGENTS,
    name: `Укрепляющие реагенты`,
    nameEn: `Restorative Reagents`,
    lvl: 9,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ALCHEMIST,
    text: `Вы можете включать укрепляющие реагенты в некоторые из своих творений:

* Каждый раз, когда существо пьет созданный Вами [Экспериментальный эликсир](FEATURE:${FEATURE_EXPERIMENTAL_ELIXIR}), существо получает временные хиты, равные 2к6 + Ваш модификатор Интеллекта (минимум 1 временный хит).
* Вы можете сотворять заклинание [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}), не тратя ячеек заклинаний и не подготавливая его, если используете [алхимические инструменты](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}) как заклинательную фокусировку. Вы можете сделать это количество раз, равное модификатору Интеллекта (минимум 1), и восстанавливаете все использования, завершив длинный отдых.`,
    spellIdList: SPELL_LESSER_RESTORATION,
    source: {
      id: SOURCE_ERFTLW,
      page: 60,
    },
  },
  {
    id: FEATURE_CHEMICAL_MASTERY,
    name: `Мастерство химии`,
    nameEn: `Chemical Mastery`,
    lvl: 15,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ALCHEMIST,
    text: `Вы подвергались воздействию стольких химических веществ, что они уже не представляют для Вас особой опасности, и Вы с их помощью быстро избавляться от некоторых недугов:

* Вы получаете сопротивление к урону кислотой и ядом, и Вы иммунитет к отравлению.
* Вы можете сотворять заклинание [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) и [Полное исцеление](SPELL:${SPELL_HEAL}), без материальных компонентов, не тратя ячеек заклинаний и не подготавливая их, если используете [алхимические инструменты](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}) как заклинательную фокусировку. Сотворив так одно из этих заклинаний, Вы не можете сотворять его снова, пока не закончите длинный отдых.`,
    spellIdList: [
      SPELL_GREATER_RESTORATION,
      SPELL_HEAL,
    ],
    source: {
      id: SOURCE_ERFTLW,
      page: 60,
    },
  },
]
