const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  GEAR_LONGBOW,
} = require('./../../../../../gearIdList')
const {
  SPELL_ETHEREALNESS,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GREASE,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_POTION,
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_OATHBOW,
  MAGIC_ITEM_OIL_OF_ETHEREALNESS,
  MAGIC_ITEM_OIL_OF_SHARPNESS,
  MAGIC_ITEM_OIL_OF_SLIPPERINESS,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_OIL_OF_ETHEREALNESS,
    name: `Масло эфирности`,
    nameEn: `Oil of Etherealness`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `Крошечные капли этого серого масла очень быстро испаряются.

Маслом можно обмазать одно существо Среднего или меньшего размера, вместе с одеждой и переносимым им снаряжением (на каждую категорию размера выше Среднего необходим один дополнительный пузырёк масла). Нанесение масла занимает 10 минут.

Обмазанное существо получает на 1 час эффект заклинания [Эфирность](SPELL:${SPELL_ETHEREALNESS}).`,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_ETHEREALNESS],
    source: {
      id: SOURCE_DMG,
      page: 183,
    },
  },
  {
    id: MAGIC_ITEM_OIL_OF_SLIPPERINESS,
    name: `Масло скольжения`,
    nameEn: `Oil of Slipperiness`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Эта липкая, чёрная мазь, хранящаяся в толстостенном и тяжёлом контейнере, очень текуча.

Маслом можно обмазать одно существо Среднего или меньшего размера, вместе с одеждой и переносимым им снаряжением (на каждую категорию размера выше Среднего необходим один дополнительный пузырёк масла). Нанесение масла занимает 10 минут. Обмазанное существо получает на 8 часов эффект заклинания [Свобода перемещения](SPELL:${SPELL_FREEDOM_OF_MOVEMENT}).

В качестве альтернативы, можно действием разлить масло по полу, покрыв площадь 10×10 футов. Эффект будет такой же, как от заклинания [Скольжение](SPELL:${SPELL_GREASE}) длительностью 8 часов.`,
    genderId: GENDER_MIDDLE,
    spellIdList: [
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_GREASE,
    ],
    source: {
      id: SOURCE_DMG,
      page: 183,
    },
  },
  {
    id: MAGIC_ITEM_OIL_OF_SHARPNESS,
    name: `Масло остроты`,
    nameEn: `Oil of Sharpness`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: `Это прозрачное и густое масло сверкает от крошечных серебристых включений.

Масло может покрыть одно рубящее или колющее оружие или до 5 рубящих или колющих боеприпасов. Нанесение масла занимает 1 минуту. В течение часа покрытые предметы считаются магическими и предоставляют бонус +3 к броскам атаки и урона.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 182,
    },
  },
  {
    id: MAGIC_ITEM_OATHBOW,
    name: `Лук клятвы`,
    nameEn: `Oathbow`,
    type: MGC_TYPE_WEAPON,
    gearType: GEAR_LONGBOW,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Когда Вы натягиваете тетиву этого лука, он шепчет на Эльфийском языке: _«Несу смерть врагам»_. Если Вы используете это оружие для совершения дальнобойной атаки, Вы можете сказать командную фразу: _«Смерть презревшему меня»_. Цель Вашей атаки становится Вашим заклятым врагом, пока не умрёт, или пока не наступит рассвет по прошествии семи дней. У Вас может быть только один заклятый враг одновременно. Если Ваш заклятый враг умирает, Вы можете выбрать нового после следующего рассвета.

Если Вы совершаете бросок дальнобойной атаки по заклятому врагу, Вы совершаете его с преимуществом. Кроме того, цель не получает преимуществ от укрытия, кроме полного укрытия, и Вы не получаете штраф за совершение атаки на расстояние, превышающее обычную дистанцию. Если атака попадает, заклятый враг получает дополнительный колющий урон 3к6.

Пока Ваш заклятый враг жив, Вы совершаете с помехой броски атаки всем другим оружием.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 180,
    },
  },
]
