import {faHeadSide} from '@/fonts/fontawesome-pro-5.11.2-web/reworked/headSide'
import {faHelmetBattle} from '@/fonts/fontawesome-pro-5.11.2-web/reworked/helmetBattle'

import {
  faAngry,
  faBible,
  faBook,
  faCogs,
  faBookOpen,
  faBrain,
  faDice,
  faDiceD20,
  faGem,
  faGrimace,
  faMehBlank,
  faHatWizard,
  faLanguage,
  faMagic,
  faPastafarianism,
  faSchool,
  faShoppingBag,
  faSnowboarding,
  faTruckPickup,
  faUserTag,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFireAlt,
} from '@fortawesome/free-solid-svg-icons'

const mainPage = {
  content: '',
  icon: faDiceD20,
  title: 'На главную',
  url: '/',
}

export const generatorPageList = [
  {
    content: 'Персонажи',
    icon: faHeadSide,
    title: 'Генератор персонажей',
    url: '/generator-characters',
  },
  {
    content: 'Имена',
    icon: faUserTag,
    title: 'Генератор имён персонажей',
    url: '/generator-names',
  },
  {
    content: 'Лут',
    icon: faGem,
    title: 'Генератор лута',
    url: '/generator-loot',
  },
  {
    content: 'Дикая магия',
    icon: faFireAlt,
    title: 'Генератор дикой магии',
    url: '/generator-wild-magic',
  },
  {
    content: 'Безумие',
    icon: faAngry,
    title: 'Генератор эффектов безумия',
    url: '/generator-madness',
  },
  {
    content: 'Кубики',
    icon: faDice,
    title: 'Броски кубиков',
    url: '/roll',
  },
  {
    content: 'Разумные предметы',
    icon: faBrain,
    title: 'Генератор разумных магических предметов',
    url: '/generator-sentient-items',
  },
  {
    content: 'Книги заклинаний',
    icon: faBookOpen,
    title: 'Генератор книг заклинаний',
    url: '/generator-spellbook',
  },
  {
    content: 'Покупка магических предметов',
    icon: faShoppingBag,
    title: 'Время простоя: покупка магических предметов',
    url: '/downtime-buy-magic-items',
  },
  {
    content: 'Кампании',
    icon: faBook,
    title: 'Генераторы для кампаний (спойлеры)',
    url: '/campaigns',
  },
]

export const catalogPageList = [
  {
    content: 'Классы',
    icon: faHelmetBattle,
    title: 'Классы персонажей',
    url: '/pc-class-catalog',
  },
  {
    content: 'Расы',
    title: 'Игровые расы',
    icon: faGrimace,
    url: '/race-catalog',
  },
  {
    content: 'Предыстории',
    icon: faSchool,
    title: 'Предыстории персонажей',
    url: '/background-catalog',
  },
  {
    content: 'Существа',
    icon: faPastafarianism,
    title: 'Каталог существ',
    url: '/creature-catalog',
  },
  {
    content: 'Заклинания',
    icon: faHatWizard,
    title: 'Каталог заклинаний',
    url: '/spell-catalog',
  },
  {
    content: 'Снаряжение',
    icon: 'icon-shop',
    title: 'Каталог снаряжения',
    url: '/gear-catalog',
  },
  {
    content: 'Магические предметы',
    title: 'Магические предметы',
    icon: faMagic,
    url: '/magic-item-catalog',
  },
  {
    content: 'Техника',
    title: 'Виды техники и транспорта',
    icon: faTruckPickup,
    url: '/vehicle-catalog',
  },
  {
    content: 'Черты',
    title: 'Каталог черт',
    icon: faSnowboarding,
    url: '/feat-catalog',
  },
  {
    content: 'Воззвания',
    icon: faMehBlank,
    title: 'Таинственные воззвания',
    url: '/invocation-catalog',
  },
  {
    content: 'Инфузии',
    title: 'Инфузии изобретателя',
    icon: faCogs,
    url: '/infusion-catalog',
  },
  {
    content: 'Боги',
    icon: faBible,
    title: 'Боги Забытых Королевств',
    url: '/god-catalog',
  },
  {
    content: 'Языки',
    icon: faLanguage,
    title: 'Каталог языков',
    url: '/language-catalog',
  },
]

export const subPageList = [
  ...catalogPageList,
  ...generatorPageList,
]

export default [
  mainPage,
  ...subPageList,
]
