const {
  SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
} = require('./../../sourceList')
const {
  PC_CLASS_BARD,
  PC_CLASS_DRUID,
  PC_CLASS_SORCERER,
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} = require('./../../pcClassIdList')
const {
  PC_SUBCLASS_MAGE_OF_LOREHOLD_2021_06_08,
  PC_SUBCLASS_MAGE_OF_PRISMARI_2021_06_08,
  PC_SUBCLASS_MAGE_OF_QUANDRIX_2021_06_08,
  PC_SUBCLASS_MAGE_OF_SILVERQUILL_2021_06_08,
  PC_SUBCLASS_MAGE_OF_WITHERBLOOM_2021_06_08,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_MAGE_OF_LOREHOLD_2021_06_08,
    pcClassId: [
      PC_CLASS_BARD,
      PC_CLASS_WARLOCK,
      PC_CLASS_WIZARD,
    ],
    isReady: false,
    name: 'Маг Лорхолда',
    nameEn: 'Mage of Lorehold',
    source: {
      id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_MAGE_OF_QUANDRIX_2021_06_08,
    pcClassId: [
      PC_CLASS_SORCERER,
      PC_CLASS_WIZARD,
    ],
    isReady: false,
    name: 'Маг Квандрикса',
    nameEn: 'Mage of Quandrix',
    source: {
      id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
      page: 5,
    },
  },
  {
    id: PC_SUBCLASS_MAGE_OF_PRISMARI_2021_06_08,
    pcClassId: [
      PC_CLASS_DRUID,
      PC_CLASS_SORCERER,
      PC_CLASS_WIZARD,
    ],
    isReady: false,
    name: 'Маг Призмари',
    nameEn: 'Mage of Prismari',
    source: {
      id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
      page: 4,
    },
  },
  {
    id: PC_SUBCLASS_MAGE_OF_SILVERQUILL_2021_06_08,
    pcClassId: [
      PC_CLASS_BARD,
      PC_CLASS_WARLOCK,
      PC_CLASS_WIZARD,
    ],
    isReady: false,
    name: 'Маг Сильверквилла',
    nameEn: 'Mage of Silverquill',
    source: {
      id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
      page: 6,
    },
  },
  {
    id: PC_SUBCLASS_MAGE_OF_WITHERBLOOM_2021_06_08,
    pcClassId: [
      PC_CLASS_DRUID,
      PC_CLASS_WARLOCK,
    ],
    isReady: false,
    name: 'Маг Визерблума',
    nameEn: 'Mage of Witherbloom',
    source: {
      id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
      page: 8,
    },
  },
]
