const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_7,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_ABYSSAL,
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_CONFUSION,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_FAERIE_FIRE,
} = require('./../../../../spellIdList')
const {
  CAST_MATERIAL,
} = require('./../../../../castComponentList')
const {
  CREATURE_DRAEGLOTH,
  CREATURE_DROW,
  CREATURE_DROW_MATRON_MOTHER,
  CREATURE_DROW_PRIESTESS_OF_LOLTH,
  CREATURE_GLABREZU,
  CREATURE_OGRE,
} = require('./../../../../creatureIdList')
const {
  GOD_LOLTH,
} = require('./../../../../godIdList')
const {
  ABILITY_FEY_ANCESTRY,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  drowDescription,
} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Дрэглот',
  nameEn: 'Draegloth',
  id: CREATURE_DRAEGLOTH,
  description: [
    {
      header: 'Дрэглот',
      text: `Дрэглот — это демон полу-[дроу](CREATURE:${CREATURE_DROW}), полу-[глабрезу](CREATURE:${CREATURE_GLABREZU}), рождённый от [жрицы дроу](CREATURE:${CREATURE_DROW_PRIESTESS_OF_LOLTH}) через нечестивый и опасный ритуал. Одаренный врожденной магией и физической мощью, он обычно служит [матери дома](CREATURE:${CREATURE_DROW_MATRON_MOTHER}), используя свою жажду уничтожения в планах этого дома на победу над соперниками.

Дрэглот — это четырёхрукий гуманоид размером с [огра](CREATURE:${CREATURE_OGRE}) с пурпурно-чёрной кожей и жёлто-белыми волосами. Две его руки огромные и мускулистые и оканчиваются острыми когтями; другие две имеют размер и форму рук [дроу](CREATURE:${CREATURE_DROW}), и могут выполнять тонкую работу. Хоть существо и очень мускулистое, оно грациозное и тихое, подобно дроу. Его лицо демоническое, со звериными чертами, горящими красными глазами, длинной собачьей мордой и ртом, полным острых зубов.`,
      source: {
        id: SOURCE_VGTM,
        page: 152,
      },
    },
    {
      header: 'Благословение Дома',
      text: `Ритуал по созданию дрэглота заканчивается успехом очень редко, но если это происходит, то это великое событие, которое дома дроу воспринимают как знак благоволения повелительницы демонов [Ллос](GOD:${GOD_LOLTH}) — и знак недовольства противниками дома, которые не были одарены. Это рождение побуждает глав дома начать созидание планов ударов по противникам, когда дрэглот вырастет. В этих планах дрэглоту отводится важная роль, поскольку его способности могут повернуть ход битвы против того дома, у которого нет своего дрэглота.`,
      source: {
        id: SOURCE_VGTM,
        page: 152,
      },
    },
    ...drowDescription,
  ],
  note: {
    text: `Эти питомцы домов дроу так же грациозны и проворны как и сценические танцоры Уотердхавена. Вот только они убийцы и силовики, четырёхрукие звери со строением огра. Жизнь несправедлива.`,
    author: `Воло`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 152,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 18,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_ELVEN,
    LANG_UNDERCOMMON,
  ],
  cr: CR_7,
  featureList: [
    ABILITY_FEY_ANCESTRY,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DARKNESS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONFUSION,
          SPELL_DANCING_LIGHTS,
          SPELL_FAERIE_FIRE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: ` ★СУЩЕСТВО★ делает три атаки: одну — _Укусом_ и две — _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
  ],
}
