const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ELECTRICITY,
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  clockworkDescriptionList,
  clockworkNote,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_SPELL} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_BRONZE_SCOUT} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {LANG_ONE_OF_ITS_CREATOR} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Бронзовый скаут',
  nameEn: 'Bronze Scout',
  id: CREATURE_BRONZE_SCOUT,
  description: [
    `Бронзовый скаут редко появляется на поверхности. Благодаря своим глазам-телескопам он наблюдает за врагами вблизи, в то время как большая часть его сегментарного червеобразного тела остаётся под землёй. Если скаут обнаружен, он старается задержать преследователей, распространяя электрические разряды по земле, перед тем как отступить в безопасное место.`,
    ...clockworkDescriptionList,
  ],
  note: clockworkNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MTOF,
    page: 200,
  },
  armor: 13,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 11,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 1,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ONE_OF_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Земляная броня',
      description: `Бронзовый разведчик не провоцирует атак, когда закапывается.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3,
          },
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Электрический разряд',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Каждое существо, стоящее на земле в 15 фт. от бронзового разведчика должно пройти испытание Ловкости (Сл. 13), получая 14 (4к6) урон электричеством при провале, или половину этого урона при успехе.`,
    },
  ],
}
