const {
  FEATURE_BULWARK_OF_FORCE,
  FEATURE_GUARDED_MIND,
  FEATURE_PSIONIC_POWER,
  FEATURE_TELEKINETIC_ADEPT,
  FEATURE_TELEKINETIC_MASTER,
} = require('./../../../featureIdList')
const {PC_SUBCLASS_FIGHTER_PSI_WARRIOR} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {SPELL_TELEKINESIS} = require('./../../../spellIdList')

const psionicPower = require('./../../constants/psionicPower')

module.exports = [
  {
    id: FEATURE_PSIONIC_POWER,
    name: `Псионическая сила`,
    nameEn: `Psionic Power`,
    lvl: [3, 5, 11, 17],
    text: `${psionicPower}

# Защитное поле

Когда Вы (или другое видимое Вами в пределах 30 футов существо) получаете урон, Вы можете реакцией потратить одну кость Псионической силы и уменьшить получаемый урон на результат броска плюс Ваш модификатор Интеллекта (минимальное уменьшение на 1), создавая мгновенный щит телекинетической силы.

# Псионический удар

Вы можете управлять своим оружием псионической силой.

Один раз на каждом своём ходу, сразу после того, как Вы попали атакой оружием по цели в пределах 30 футов и нанесли ей урон, Вы можете потратить одну кость Псионической силы, бросив её и нанеся цели урон силовым полем, равный результату броска плюс Ваш модификатор Интеллекта.

# Телекинетическое движение

Вы можете перемещать предметы или существ силой мысли.

Вы действием нацеливаетесь на один свободный предмет Большого (или меньшего) размера или на одно согласное существо (кроме себя). 

Если Вы видите цель в пределах 30 футов, Вы можете переместить её на 30 футов в свободное видимое Вами пространство. Альтернативно, если это Крошечный предмет, Вы можете переместить его себе в руку или из руки. В любом случае Вы можете перемещать цель по горизонтали, вертикали или по обоим направлениям.

Использовав эту способность, Вы не сможете сделать это снова, пока не закончите короткий или длинный отдых, если только не потратите кость Псионической силы, чтобы сделать это снова.`,
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: FEATURE_TELEKINETIC_ADEPT,
    name: `Адепт телекинеза`,
    nameEn: `Telekinetic Adept`,
    lvl: 7,
    text: `Вы освоили новые способы применения своих телекинетических способностей, описанные ниже

# Пси-усиленный прыжок

Вы можете  бонусным действием передвигать своё тело с помощью мысли. До конца текущего хода Вы получаете скорость полёта, равную Вашей удвоенной скорости ходьбы. Использовав эту способность, Вы не сможете сделать это снова, пока не закончите короткий или длинный отдых, если только не потратите [кость Псионической силы](FEATURE:${FEATURE_PSIONIC_POWER}), чтобы сделать это снова.

# Телекинетический толчок

Когда Вы наносите цели урон [Псионическим ударом](FEATURE:${FEATURE_PSIONIC_POWER}), Вы можете заставить цель пройти испытание Силы (СЛ равна 8 + Ваш бонус мастерства + Ваш модификатор Интеллекта). При провале Вы сбиваете цель с ног или перемещаете её на расстояние до 10 футов в любом направлении по горизонтали.`,
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: FEATURE_GUARDED_MIND,
    name: `Защищённый разум`,
    nameEn: `Guarded Mind`,
    lvl: 10,
    text: `Текущая через Вас псионическая энергия укрепила Ваш разум.

Вы получаете сопротивление психическому урону.

Кроме того, если Вы начинаете свой ход Очарованным или Напуганным, Вы можете потратить [кость Псионической силы](FEATURE:${FEATURE_PSIONIC_POWER}) и прекратить на себе все такие эффекты.`,
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: FEATURE_BULWARK_OF_FORCE,
    name: `Пси-бастион`,
    nameEn: `Bulwark of Force`,
    lvl: 15,
    text: `Вы можете защитить себя и других телекинетической силой.

Вы можете бонусным действием выбрать существ (включая себя), видимых Вами в пределах 30 футов (числом не более Вашего модификатора Интеллекта, минимум одно существо). Каждое из выбранных существ теперь защищено укрытием на половину в течение 1 минуты или до тех пор, пока Вы не станете недееспособны.

Использовав эту способность, Вы не сможете сделать это снова, пока не закончите длинный отдых, если только не потратите [кость Псионической силы](FEATURE:${FEATURE_PSIONIC_POWER}), чтобы сделать это снова.`,
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: FEATURE_TELEKINETIC_MASTER,
    name: `Мастер телекинеза`,
    nameEn: `Telekinetic Master`,
    lvl: 18,
    text: `Немногие сравнятся с Вашим умением перемещать существ и предметы.

Вы можете использовать заклинание [Телекинез](SPELL:${SPELL_TELEKINESIS}), не требуя компонентов. Интеллект — Ваша заклинательная характеристика для него. В каждый свой ход, когда Вы концентрируетесь на заклинании, включая ход, когда Вы его сотворяете, Вы можете бонусным действием совершить одну атаку оружием.

Сотворяете заклинание этой способностью, Вы не сможете сделать это снова, пока не закончите длинный отдых, если только не потратите [кость Псионической силы](FEATURE:${FEATURE_PSIONIC_POWER}), чтобы снова его сотворить.`,
    spellIdList: [SPELL_TELEKINESIS],
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_PSI_WARRIOR,
  })
)
