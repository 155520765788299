const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_DERRO} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_DERRO,
  nameEn: 'Derro',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'дерро',
      genitive: 'дерро',
      dative: 'дерро',
      accusative: 'дерро',
      instrumental: 'дерро',
      prepositional: 'дерро',
    },
    plural: {
      nominative: 'дерро',
      genitive: 'дерро',
      dative: 'дерро',
      accusative: 'дерро',
      instrumental: 'дерро',
      prepositional: 'дерро',
    },
  },
}
