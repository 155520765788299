const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  CREATURE_BULETTE,
  CREATURE_CLOUD_GIANT,
  CREATURE_MUMMY_LORD,
  CREATURE_SHRIEKER,
  CREATURE_TREANT,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_BAG_OF_BEANS,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_BAG_OF_BEANS,
  name: `Сумка с бобами`,
  nameEn: `Bag of Beans`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  isConsumable: true,
  description: `Внутри этой тяжёлой тканой сумки находятся 3к4 сухих боба. Сумка весит ½ фунта, плюс ¼ фунта за каждый боб, находящийся внутри. Если Вы высыпаете содержимое сумки на землю, то они взрываются с радиусом 10 футов.

Все существа в этой зоне, включая Вас, должно пройти испытание Ловкости СЛ 15, получая урон огнём 5к4 при провале или половину этого урона при успехе. Этот огонь также поджигает горючие предметы в зоне поражения, которые никто не несёт и не носит.

Если Вы извлечёте боб из сумки, посадите его в землю или песок и затем польёте его, то боб спустя 1 минуту после того, как Вы посадили его, произведёт некоторый эффект. Мастер может сам выбрать эффект из таблицы, может определить эффект случайным образом, или же придумать свой вариант. 

| к100  | Эффект |
|-------|--------|
| 01    | Вырастают 5к4 поганок. Если существо съедает поганку, то необходимо бросить любую кость для определения эффекта. При выпадении нечётного числа существо должно пройти испытание Телосложения СЛ 15, иначе оно получит урон ядом 5к6 и становится отравленным на 1 час. Если же выпадет чётное число, то существо получает 5к6 временных хитов на 1 час.
| 2–10  | Появляется гейзер, фонтанирующий на высоту 30 футов потоками воды, пива, ягодного сока, чая, уксуса или масла (по выбору Мастера) в течение 1к12 раундов.
| 11–20 | Появляется [трент](CREATURE:${CREATURE_TREANT}). Существует 50-процентный шанс, что появившийся [трент](CREATURE:${CREATURE_TREANT}) будет обладать хаотично-злым мировоззрением и нападёт.
| 21–30 | Появляется ожившая, но неподвижная каменная статуя, похожая на Вас и выкрикивающая в Ваш адрес угрозы. Если Вы уходите от неё, а кто-то другой приближается к ней, статуя описывает Вас как самого гнусного злодея и побуждает новоприбывших найти и атаковать Вас. Если Вы находитесь с ней на одном плане существования, то статуя знает Ваше местоположение. По прошествии 24 часов статуя перестаёт быть ожившей.
| 31–40 | Появляется походный костёр с синими языками пламени, который горит в течение 24 часов (или пока не будет погашен).
| 41–50 | Вырастают 1к6 + 6 [визгунов](CREATURE:${CREATURE_SHRIEKER}).
| 51–60 | Наружу выползают 1к4 + 8 ярко-розовых жаб. Всякий раз, когда кто-либо прикасается к жабе, та превращается в чудовище (размером вплоть до Большого) по выбору Мастера. Это чудовище существует в течение минуты, после чего исчезает в клубах ярко-розового дыма.
| 61–70 | Из норы выползает голодная [панцирница](CREATURE:${CREATURE_BULETTE}) и немедленно нападает.
| 71–80 | Вырастает фруктовое дерево. На дереве растёт 1к10 + 20 фруктов, 1к8 из которых действуют как магические зелья (определяются случайным образом), в то время, как один из фруктов, будучи съеденным, действует как пищевой яд на выбор Мастера. Дерево исчезает спустя 1 час. Сорванные фрукты остаются, сохраняя свои магические свойства в течение 30 дней.
| 81–90 | Появляется гнездо, содержащее 1к4 + 3 яиц. Существа, съедающие одно из этих яиц, должно пройти испытание Телосложения СЛ 20. При успехе существо навсегда увеличивает на единицу значение своей самой низкой характеристики. Если две или более характеристики имеют минимальное значение, то случайно выбирается какая-либо одна. При провале существо получает урон силовым полем 10к6 от внутреннего взрыва магической природы.
| 91–99 | Вверх возносится пирамида с квадратным основанием 60×60 футов. Внутри неё находится саркофаг с покоящемся в нём [владыкой мумий](CREATURE:${CREATURE_MUMMY_LORD}). Эта пирамида считается логовом [владыки мумий](CREATURE:${CREATURE_MUMMY_LORD}), а в саркофаге находится сокровище на усмотрение Мастера.
| 00    | Вырастает гигантский бобовый стебель такой высоты, какой будет угодно Мастеру. Мастер также решает, что ждёт добравшегося до его вершины персонажа: ему откроется величественный вид, он окажется в замке [облачного великана](CREATURE:${CREATURE_CLOUD_GIANT}) или же вершина стебля приведёт его на другой план существования.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 205,
  },
}
