const {
  SPELL_BANE,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_DETECT_MAGIC,
  SPELL_GUIDANCE,
  SPELL_INFLICT_WOUNDS,
  SPELL_MENDING,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_RESISTANCE,
  SPELL_SILENCE,
  SPELL_THAUMATURGY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_INTIMIDATION,
  SKILL_MEDICINE,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  CREATURE_ORC_CLAW_OF_LUTHIC,
  CREATURE_ORC_EYE_OF_GRUUMSH,
  CREATURE_ORC_HAND_OF_YURTRUS,
} = require('./../../../../creatureIdList')
const {
  LANG_COMMON,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  orcDescription,
  orcNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_ORC,
} = require('./../../../../creatureTypeIdList')
const {
  GOD_GRUUMSH,
  GOD_YURTRUS,
} = require('./../../../../godIdList')
const {ABILITY_AGGRESSIVE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_NECROTIC} = require('./../../../../damageTypeList')
const {GEAR_HIDE_ARMOR} = require('./../../../../gearIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PC_RACE_ELF} = require('./../../../../pcRaceIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Орк рука Юртруса',
  nameEn: 'Orc hand of Yurtrus',
  id: CREATURE_ORC_HAND_OF_YURTRUS,
  description: [
    `[Юртрус](GOD:${GOD_YURTRUS}) — это орочий бог смерти и болезней. Он ужасен и мерзок, и покрыт гнилью и заражением, кроме своих превосходных, гладких белых рук.

Жрецы орков, которые прозревают сквозь линию между жизнью и смертью, известны всем в племени как руки Юртруса. Они проживают на границах логова орков, обычно общаясь с другими орками посредством [тех, кто почитает Лутик](CREATURE:${CREATURE_ORC_CLAW_OF_LUTHIC}). Руки Юртруса носят бледные перчатки, сделанные из отбеленной кожи других гуманоидов (предпочитают [эльфов](PC_RACE:${PC_RACE_ELF})), что символизирует их связь с [Юртрусом](GOD:${GOD_YURTRUS}), из-за чего их называют «белые руки».

Каждый орк знает, что руки Юртруса — это проводники к предкам племени. Орк, который погиб и хорошо служил племени, проходит ритуалы для того, чтобы попасть в мир [Груумша](GOD:${GOD_GRUUMSH}).

Руки Юртруса удаляют себе язык для подражания божеству, что подходит последователям немого божества, наподобие того как [глаз Груумша](CREATURE:${CREATURE_ORC_EYE_OF_GRUUMSH}) удаляет себе один глаз.`,
    ...orcDescription,
  ],
  note: orcNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ORC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 181,
  },
  armor: {
    ac: 12,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 16,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_MEDICINE]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: [
        LANG_COMMON,
        LANG_ORC,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AGGRESSIVE,
  ],
  spellCast: {
    spellCasterLevel: 4,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_BANE,
      SPELL_BLINDNESS_DEAFNESS,
      SPELL_DETECT_MAGIC,
      SPELL_GUIDANCE,
      SPELL_INFLICT_WOUNDS,
      SPELL_MENDING,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_RESISTANCE,
      SPELL_SILENCE,
      SPELL_THAUMATURGY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
    ],
  },
  actionList: [
    {
      name: 'Касание белой руки',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
  ],
}
