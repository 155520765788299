const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_HUMANOID} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_HUMANOID,
  nameEn: 'Humanoid',
  genderId: GENDER_MALE,
  notRealRace: true,
  description: {
    text: `Гуманоиды — это все основные народы мира D&D, и цивилизованные и дикие, они очень разнообразны по видам. Они обладают языком и культурой, некоторые имеют врождённые магические способности (хотя большинство гуманоидов может обучиться накладыванию заклинаний). Они двуноги. Наиболее распространённые гуманоидные расы доступны игроку при создании персонажа: это люди, дварфы, эльфы и полурослики. Почти столь же многочисленные, но гораздо более дикие и жестокие, и более злые, это раса гоблиноидов (гоблины, хобгоблины и медвежатники), орки, гноллы, людоящеры и кобольды.`,
    source: {
      id: SOURCE_MM,
      page: 8,
    },
  },
  name: {
    singular: {
      nominative: 'гуманоид',
      genitive: 'гуманоида',
      dative: 'гуманоиду',
      accusative: 'гуманоида',
      instrumental: 'гуманоидом',
      prepositional: 'гуманоиде',
    },
    plural: {
      nominative: 'гуманоиды',
      genitive: 'гуманоидов',
      dative: 'гуманоидам',
      accusative: 'гуманоидов',
      instrumental: 'гуманоидами',
      prepositional: 'гуманоидах',
    },
  },
}
