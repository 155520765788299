const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  PARAM_CON,
} = require('./../../../../../paramList')
const {
  DAMAGE_POISON,
} = require('./../../../../../damageTypeList')
const {
  SPELL_POISON_SPRAY,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_POISON_SPRAY,
  name: 'Ядовитые брызги',
  nameEn: 'Poison Spray',
  description: `Вы простираете руку к существу, видимому в пределах дистанции, и выпускаете из ладони клубы токсичного газа. Это существо должно пройти испытание Телосложения, иначе оно получит урон ядом 1к12.`,
  highLvlCast: `Урон этого заклинания увеличивается на 1к12, когда Вы достигаете 5 уровня (2к12), 11 уровня (3к12) и 17 уровня (4к12).`,
  lvl: 0,
  magicSchoolId: MAGIC_CONJURATION,
  range: 10,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_POISON,
      diceCount: 1,
      diceType: 12,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 290,
    },
    {
      id: SOURCE_SRD,
      page: 169,
    },
  ],
}
