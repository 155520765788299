const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  clockworkDescriptionList,
  clockworkNote,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_IRON_COBRA} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_ONE_OF_ITS_CREATOR} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Железная кобра',
  nameEn: 'Iron Cobra',
  id: CREATURE_IRON_COBRA,
  description: [
    `Железная кобра полностью соответствует своему названию: металлическая змея с ядовитыми клыками. Кроме обычного яда, гномы заряжают этот заводной механизм алхимическими зельями, способными парализовать существ или поселить панику в их разум.`,
    ...clockworkDescriptionList,
  ],
  note: clockworkNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MTOF,
    page: 201,
  },
  armor: 13,
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 14,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 1,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ONE_OF_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Если цель существо, она должна пройти испытание Телосложения СЛ 13 или получить один из случайных эффектов яда:

1. **Урон от яда:** Цель получает 13 (3к8) урона ядом.
2. **Смятение:** В свой следующий ход цель должна совершить одну атаку оружием против случайного существа, которое она может видеть в 30 фт. от себя, используя любое оружие, которое она держит в руках и передвигаясь, если требуется подойти на расстояние досягаемости оружия. Если оружия нет — цель совершает безоружный удар. Если в пределах 30 фт. от цели нет существ, цель делает Рывок, двигаясь к ближайшему существу.
3. **Паралич:** Цель становится Парализованной до конца своего следующего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
