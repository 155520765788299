const {
  MAGIC_ILLUSION,
} = require('./../../../../../magicList')
const {
  PARAM_DEX,
  PARAM_WIT,
} = require('./../../../../../paramList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_INCAPACITATED,
} = require('./../../../../../conditionList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_NATHAIR_S_MISCHIEF,
  SPELL_NATHAIR_S_MISCHIEF_2021_04_14,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_NATHAIR_S_MISCHIEF_2021_04_14,
  releasedAs: SPELL_NATHAIR_S_MISCHIEF,
  name: 'Натаирово озорство',
  nameEn: 'Nathair’s Mischief',
  description: `Вы заполняете фейской и драконьей магией 20-футовый куб с центром на выбранной в пределах дистанции точке.

Бросьте по таблице «Озорные всплески», чтобы определить случайный эффект.

В начале каждого Вашего хода Вы можете передвинуть куб на расстояние до 10 футов и перебросить эффект по таблице.

# Озорные всплески

| к4 | Эффект |
|-|-|
| 1 | Воздух наполняется запахом яблочного пирога. Все существа в области действия должны пройти испытание Мудрости или стать Очарованными Вами до начала Вашего следующего хода. |
| 2 | В воздухе появляются букеты цветов и все существа в области действия должны пройти испытание Ловкости или стать Ослеплёнными до начала Вашего следующего хода, потому что цветы прыскают им водой в глаза. |
| 3 | Все существа в области действия должны пройти испытание Мудрости или начать хихикать до начала Вашего следующего хода. Хихикающее существо Недееспособно и тратит всё своё передвижение на движение в случайном направлении. |
| 4 | Капли патоки появляются и парят в области действия, превращая её в труднопроходимую местность до начала Вашего следующего хода. |
`,
  lvl: 2,
  magicSchoolId: MAGIC_ILLUSION,
  range: 60,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кусочек корочки яблочного пирога`,
  hasCost: true,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: [
      PARAM_DEX,
      PARAM_WIT,
    ],
    condition: [
      CONDITION_BLINDED,
      CONDITION_CHARMED,
      CONDITION_INCAPACITATED,
    ],
  },
  source: {
    id: SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
    page: 6,
  },
}
