const listToCollectionById = require('./../utils/listToCollectionById')

const ARMOR_TYPE_BARDING_SCRAPS = 'BARDING_SCRAPS'
const ARMOR_TYPE_NATURAL = 'natural'
const ARMOR_TYPE_PATCHWORK = 'patchwork'
const ARMOR_TYPE_SCRAPS = 'scraps'
const ARMOR_TYPE_UNARMORED_DEFENSE = 'unarmored_defense'

const armorTypeList = [
  {
    id: ARMOR_TYPE_NATURAL,
    description: `У монстра, которое не носит доспех, может быть природный доспех, в случае чего его КД равен 10 + модификатор Ловкости + бонус его природного доспеха. Монстр с толстой шкурой обычно имеет бонус природного доспеха от +1 до +3. Этот бонус может быть выше, если существо очень хорошо защищено. Горгона, например, покрыта стальными пластинами и обладает природным доспехом +9`,
    name: 'природный доспех',
  },
  {
    id: ARMOR_TYPE_PATCHWORK,
    name: 'лоскутный доспех',
  },
  {
    id: ARMOR_TYPE_SCRAPS,
    name: 'обломки доспеха',
  },
  {
    id: ARMOR_TYPE_BARDING_SCRAPS,
    name: 'обломки доспеха для коня',
  },
  {
    id: ARMOR_TYPE_UNARMORED_DEFENSE,
    name: 'Защита без доспехов',
  },
]

module.exports = armorTypeList

module.exports.armorTypeCollection = listToCollectionById(armorTypeList)

module.exports.ARMOR_TYPE_BARDING_SCRAPS = ARMOR_TYPE_BARDING_SCRAPS
module.exports.ARMOR_TYPE_NATURAL = ARMOR_TYPE_NATURAL
module.exports.ARMOR_TYPE_PATCHWORK = ARMOR_TYPE_PATCHWORK
module.exports.ARMOR_TYPE_SCRAPS = ARMOR_TYPE_SCRAPS
module.exports.ARMOR_TYPE_UNARMORED_DEFENSE = ARMOR_TYPE_UNARMORED_DEFENSE
