const {
  CREATURE_DUERGAR,
  CREATURE_DUERGAR_KAVALRACHNI,
  CREATURE_STEEDER_FEMALE,
  CREATURE_STEEDER_MALE,
} = require('./../../creatureIdList')
const {
  SOURCE_MTOF,
  SOURCE_DND4_NCS,
} = require('./../../sourceList')

module.exports = [
  {
    header: 'Стидеры',
    text: `Гигантские охотничьи пауки, стидеры рыщут по Подземью. Со многими стидерами можно столкнуться вместе с группой [двергаров](CREATURE:${CREATURE_DUERGAR}).

**Превосходство самок.** [Самки стидеров](CREATURE:${CREATURE_STEEDER_FEMALE}) растут больше и сильнее [самцов](CREATURE:${CREATURE_STEEDER_MALE}), и часто пожирают своих самцов после спаривания. В неволе, самцов используют в качестве рабочего скота, тогда как самки служат скакунами в бою.

**Хищники-одиночки.** Стидеры считают других своих сородичей своими врагами и пытаются разорвать в клочья свою предполагаемую угрозу. Их [двергарские наездники](CREATURE:${CREATURE_DUERGAR_KAVALRACHNI}) стараются избегать прочих наездников и надевают им наглазники во время езды, чтобы стидеры не напали друг на друга.

**Хитрые бестии.** Стидеры достаточно разумны для обучения простым жестовым и голосовым командам, но даже одомашненный стидер может обратиться против своего дрессировщика. Дрессировка этих бестий требует особой связи наездника со стидером, такой процесс происходит сразу после того, как стидер вылупится из яйца. Наездник остаётся со стидером, пока тот не вырастет до своего полного размера, разбираясь в течении этого времени с инстинктами хищника у стидера.

**Смертоносные охотники.** Вместо того, чтобы плести сеть паутин, стидеры выделяют из своих ног вязкую субстанцию. Эта слизь позволяет им ползти по стенам и потолкам, чтобы схватывать свою жертву`,
    source: {
      id: SOURCE_MTOF,
      page: 230,
    },
  },
  {
    header: 'Стидеры',
    text: `Результат дьявольских генетических экспериментов, стидер похож на огромного паука, созданного пьяным богом.

У стидера выпученные гуманоидные глаза, огромные, слегка светящиеся красным жвалы, и множество покрытых тёмными волосами ног. Этих лап нечётное количество и все они разной длины. С их помощью всадник стидера может перемещаться по любым поверхностям: по стене, полу или потолку.

Чтобы удержаться на звере, двергары используют специальные сёдла.`,
    source: {
      id: SOURCE_DND4_NCS,
    },
  },
]
