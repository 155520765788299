const {CAT_EXPLOSIVES} = require('./../../../../../gearCategoryList')
const {GEAR_BULLETS} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_WDH} = require('./../../../../../sourceList')
const {SPELL_DISPEL_MAGIC} = require('./../../../../../spellIdList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    damageDiceCount,
    gearId,
    id,
    name,
    nameEn,
    namePrepositional,
    shotCount,
    storedAt,
  }
) => ({
    id,
    name,
    nameEn,
    gearId,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearCategory: CAT_EXPLOSIVES,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Дым-порох — это магическая взрывчатка, с помощью которой выпускают [пулю](GEAR:${GEAR_BULLETS}) из ствола огнестрельного оружия. Он хранится в ${storedAt}. Дым-пороха в ${namePrepositional} хватает на ${shotCount} выстрелов.

Если дым-порох поджечь, бросить или как-то резко на него воздействовать, он взорвётся и нанесёт ${damageDiceCount}к6 урона огнём всем существам и предметам в пределах 20 футов. Успешное испытание Ловкости СЛ 12 уменьшает урон вдвое.

Заклинание [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}), сотворённое на дым-порох, навсегда делает его безвредным.`,
    genderId: GENDER_MALE,
    siblingIdList,
    source: {
      id: SOURCE_WDH,
      page: 189,
    },
  }
)
