const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_AWAKENED_TREE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_PLANT} = require('./../../../../creatureTypeIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {LANG_ONE_OF_ITS_CREATOR} = require('./../../../../languageIdList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_AWAKEN} = require('./../../../../spellIdList')

module.exports = {
  name: 'Пробуждённое дерево',
  nameEn: 'Awakened Tree',
  id: CREATURE_AWAKENED_TREE,
  description: `Пробуждённое дерево это обычное дерево, получившее сознание и подвижность заклинанием [Пробуждение разума](SPELL:${SPELL_AWAKEN}) или подобной магией.`,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 337,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 6,
    [PARAM_CON]: 15,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  resistanceList: [
    DAMAGE_BLUDGEONING,
    DAMAGE_PIERCING,
  ],
  languageList: [
    LANG_ONE_OF_ITS_CREATOR,
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного дерева`,
    },
  ],
  actionList: [
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MIDDLE,
}
