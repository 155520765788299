const {artificerSpellCircleList} = require('./../../../spellCircleByCasterType')
const {extraAttackTemplate} = require('./../../featureCommonRawList')
const {PC_SUBCLASS_ARTIFICER_ARMORER} = require('./../../../pcSubClassIdList')
const {GEAR_SMITHS_TOOLS} = require('./../../../gearIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {
  SPELL_FIRE_SHIELD,
  SPELL_GREATER_INVISIBILITY,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGIC_MISSILE,
  SPELL_MIRROR_IMAGE,
  SPELL_PASSWALL,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_WALL_OF_FORCE,
} = require('./../../../spellIdList')

const {
  FEATURE_ARCANE_ARMOR,
  FEATURE_ARMOR_MODEL,
  FEATURE_ARMOR_MODIFICATIONS,
  FEATURE_ARMORER_SPELLS,
  FEATURE_EXTRA_ATTACK_ARTIFICER_ARMORER,
  FEATURE_INFUSE_ITEM,
  FEATURE_PERFECTED_ARMOR,
  FEATURE_TOOLS_OF_THE_TRADE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ARMORER_SPELLS,
    name: `Заклинания бронника`,
    nameEn: `Armorer Spells`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER,
    text: `У Вас всегда подготовлены некоторые заклинания, когда Вы достигаете определённого уровня в этом классе.

Они не учитываются в количестве заклинаний изобретателя, которые Вы можете подготавливать и для Вас считаются заклинаниями изобретателя.`,
    spellCircleLvl: artificerSpellCircleList,
    spellIdList: [
      SPELL_FIRE_SHIELD,
      SPELL_GREATER_INVISIBILITY,
      SPELL_HYPNOTIC_PATTERN,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGIC_MISSILE,
      SPELL_MIRROR_IMAGE,
      SPELL_PASSWALL,
      SPELL_SHATTER,
      SPELL_SHIELD,
      SPELL_WALL_OF_FORCE,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 15,
    },
  },
  {
    id: FEATURE_TOOLS_OF_THE_TRADE,
    name: `Снаряжение`,
    nameEn: `Tools of the Trade`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER,
    text: `Вы получаете владение тяжёлыми доспехами.

Кроме того, Вы получаете владение [инструментами кузнеца](GEAR:${GEAR_SMITHS_TOOLS}). Если Вы уже ими владеете, то получаете владение одним другим видом ремесленных инструментов по Вашему выбору.`,
    source: {
      id: SOURCE_TCoE,
      page: 15,
    },
  },
  {
    id: FEATURE_ARCANE_ARMOR,
    name: `Волшебная броня`,
    nameEn: `Arcane Armor`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER,
    text: `Ваши исследования в металлургии позволили делать доспех проводником своей магии изобретателя. Вы можете действием, держа в руках [инструменты кузнеца](GEAR:${GEAR_SMITHS_TOOLS}), превратить носимый Вами тяжёлый доспех в _Волшебную броню_.

Нося _Волшебную броню_, Вы получаете следующие преимущества:

* Если у доспеха есть требования к Силе, то _Волшебная броня_ их лишена.
* Вы можете использовать _Волшебную броню_ как заклинательную фокусировку своих заклинаний изобретателя.
* _Волшебная броня_ прикрепляется к Вам и не может быть снята против Вашей воли. Она также расширяется, покрывая всё Ваше тело, хотя Вы можете выдвигать или задвигать шлем бонусным действием. _Волшебная броня_ заменяет любые отсутствующие конечности, функционируя так же, как и заменяемая часть тела.
* Вы можете снять или надеть _Волшебную броню_ действием.

Доспех остаётся _Волшебной бронёй_ пока Вы не наденете другой доспех или не умрёте.`,
    source: {
      id: SOURCE_TCoE,
      page: 15,
    },
  },
  {
    id: FEATURE_ARMOR_MODEL,
    name: `Модели брони`,
    nameEn: `Armor Model`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER,
    text: `Вы можете настраивать свою [Волшебную броню](FEATURE:${FEATURE_ARCANE_ARMOR}). Выберите одну из следующих моделей: _Страж_ или _Лазутчик_. Выбранная модель даёт Вам особые преимущества при ношении.

Каждая модель включает в себя особое оружие. Когда Вы атакуете этим оружием, то можете использовать для бросков атаки и урона модификатор Интеллекта вместо Силы или Ловкости.

Вы можете изменить модель своей [Волшебной брони](FEATURE:${FEATURE_ARCANE_ARMOR}) после короткого или длинного отдыха, если держите в руке [инструменты кузнеца](GEAR:${GEAR_SMITHS_TOOLS}).

# Страж

Ваша [Волшебная броня](FEATURE:${FEATURE_ARCANE_ARMOR}) спроектирована для войны на передовой. Она получает следующие особенности:

## Гром-перчатки

Каждый Ваш бронированный кулак (пока Вы в нём ничего не держите) считается простым рукопашным оружием, и каждый из них наносит 1к8 урона звуком при попадании. Существо, по которому Вы попали ударом _Гром-перчатки_, получает до начала Вашего следующего хода помеху на броски атаки по всем целям, кроме Вас, так как [Волшебная броня](FEATURE:${FEATURE_ARCANE_ARMOR}) магически излучает отвлекающий импульс, когда это существо атакует кого-то ещё.

## Защитное поле

Вы можете каждый ход бонусным действием получать временные хиты, количество которых равно Вашему уровню в этом классе, заменяя любые временные хиты, которые у Вас уже есть. Вы теряете эти временные очки жизни, если снимаете [Волшебную броню](FEATURE:${FEATURE_ARCANE_ARMOR}). Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.

# Лазутчик

Вы проектируете [Волшебную броню](FEATURE:${FEATURE_ARCANE_ARMOR}) для аккуратных действий. Она получает следующие особенности:

## Молниемёт

На одном из бронированных кулаков или на груди Вашей [Волшебной брони](FEATURE:${FEATURE_ARCANE_ARMOR}) (на Ваш выбор) появляется сияющий камень. Оно считается простым дальнобойным оружием с дальностью 90/300 футов, наносящим 1к6 урона электричеством. Один раз на своём ходу, поражая им существо, Вы можете нанести этой цели дополнительно 1к6 урона электричеством.

## Усиленные ноги

Ваша скорость ходьбы увеличивается на 5 футов.

## Заглушающее поле

Вы получаете преимущество на проверки Ловкости (Скрытности). Если Ваша броня обычно накладывает помеху на эти проверки, то помеха и преимущество отменяют друг друга.`,
    source: {
      id: SOURCE_TCoE,
      page: 16,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_ARTIFICER_ARMORER,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER,
    source: {
      id: SOURCE_TCoE,
      page: 16,
    },
  },
  {
    id: FEATURE_ARMOR_MODIFICATIONS,
    name: `Модификация брони`,
    nameEn: `Armor Modifications`,
    lvl: 9,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER,
    text: `Вы узнаете, как использовать инфузии изобретателя, чтобы специально модифицировать свою [Волшебную броню](FEATURE:${FEATURE_ARCANE_ARMOR}).

Части [Волшебной брони](FEATURE:${FEATURE_ARCANE_ARMOR}) теперь считается отдельными предметами для Вашей способности [Инфузирование предметов](FEATURE:${FEATURE_INFUSE_ITEM}): 

* ботинки,
* [встроенное оружие](FEATURE:${FEATURE_ARMOR_MODEL}),
* нагрудник,
* шлем.

Каждая из этих отдельных частей может содержать одну из Ваших [Инфузий](FEATURE:${FEATURE_INFUSE_ITEM}). Инфузии сохраняются, если Вы меняете [модель брони](FEATURE:${FEATURE_ARMOR_MODEL}).

Кроме того, максимальное число предметов, которые Вы можете [инфузировать](FEATURE:${FEATURE_INFUSE_ITEM}) одновременно, увеличивается на 2, но эти дополнительные предметы должны быть частью Вашей [Волшебной брони](FEATURE:${FEATURE_ARCANE_ARMOR}).`,
    source: {
      id: SOURCE_TCoE,
      page: 17,
    },
  },
  {
    id: FEATURE_PERFECTED_ARMOR,
    name: `Идеальная броня`,
    nameEn: `Perfected Armor`,
    lvl: 15,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER,
    text: `Ваша [Волшебная броня](FEATURE:${FEATURE_ARCANE_ARMOR}) получает дополнительные преимущества в зависимости от модели, как показано ниже.

# Страж

Когда видимое Вами Огромное или меньшее существо заканчивает свой ход в пределах 30 футов от Вас, Вы можете реакцией заставить существо пройти испытание Силы, или оно будет притянуто в свободное пространство на 30 футов к Вам. Если Вы притянете его в пространство в пределах 5 футов от себя, Вы можете нанести по нему рукопашную атаку оружием как часть этой реакции.

Вы можете использовать эту реакцию число раз, равное Вашему бонусу мастерства. Вы восстанавливаете все израсходованные использования, когда заканчиваете длинный отдых.

# Лазутчик

Любое существо, которое получает урон электричеством от Вашего _Молниемёта_, светится до начала Вашего следующего хода. Оно излучает тусклый свет в радиусе 5 футов, и получает помеху на атаки по Вам, потому что его всё ещё немного трясёт, когда оно атакует Вас.

Кроме того, следующая атака по нему получает преимущество. Если эта атака попадает, она наносит дополнительно 1к6 урона электричеством.`,
    source: {
      id: SOURCE_TCoE,
      page: 17,
    },
  },
]
