const {CREATURE_TYPE_DRAGON_CHROMATIC} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_DRAGON_CHROMATIC,
  nameEn: 'Chromatic Dragon',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'цветной дракон',
        genitive: 'цветного дракона',
        dative: 'цветному дракону',
        accusative: 'цветного дракона',
        instrumental: 'цветным драконом',
        prepositional: 'цветном драконе',
      },
      [GENDER_FEMALE]: {
        nominative: 'цветная драконица',
        genitive: 'цветной драконицы',
        dative: 'цветной драконице',
        accusative: 'цветную драконицу',
        instrumental: 'цветной драконицей',
        prepositional: 'цветной драконице',
      },
    },
    plural: {
      nominative: 'цветные драконы',
      genitive: 'цветных драконов',
      dative: 'цветным драконам',
      accusative: 'цветных драконов',
      instrumental: 'цветными драконами',
      prepositional: 'цветных драконах',
    },
  },
}
