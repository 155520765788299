const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_PLANT,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_PRONE,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PSYCHIC,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_FALSE_APPEARANCE,
    ABILITY_REGENERATION,
  } = require('./../../../../creatureAbilityList'),
  {
    SPELL_REMOVE_CURSE,
  } = require('./../../../../spellIdList'),
  {
    yellowMuskDescription,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_YELLOW_MUSK_CREEPER,
    CREATURE_YELLOW_MUSK_ZOMBIE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Жёлтая мускусная лиана',
  nameEn: 'Yellow Musk Creeper',
  id: CREATURE_YELLOW_MUSK_CREEPER,
  description: yellowMuskDescription,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 224,
  },
  armor: 6,
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_CLIMB]: 5,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 3,
    [PARAM_CON]: 12,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_BLINDED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычной лозы`,
    },
    {
      id: ABILITY_REGENERATION,
      regen: 10,
      other: `. Если ★он★ получает урон огнём, некротической энергией или излучением, эта способность не работает в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только в том случае, если начинает свой ход с 0 хитами и не регенерирует`,
    },
  ],
  actionList: [
    {
      name: 'Касание',
      description: `Если цель — гуманоид и его хиты в результате этой атаки опускаются до 0, он умирает и ★СУЩЕСТВО★ помещает в его тело своё семя. Если семя не уничтожено, то по прошествии 24 часов труп оживает и превращается в [жёлтого мускусного зомби](CREATURE:${CREATURE_YELLOW_MUSK_ZOMBIE}).

Семя разрушается, если существо возвращают к жизни, прежде чем оно может превратиться в [жёлтого мускусного зомби](CREATURE:${CREATURE_YELLOW_MUSK_ZOMBIE}) или если на труп становится целью заклинания [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) или подобной магии, прежде чем он оживёт.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 3,
          diceType: 8,
        },
      },
    },
    {
      name: 'Жёлтый мускус',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `★Его★ цветы выпускают сильный мускус, который нацеливается на всех гуманоидов в пределах 30 футов от ★него★. Каждая цель должна пройти испытание Мудрости СЛ 11 или ★СУЩЕСТВО★ очаровывает её на 1 минуту. Существо, очарованное таким образом, ничего не делает на своём ходу, кроме приближения, как можно ближе, к ★СУЩЕСТВО★. Существо, которое ★очаровал★ ★СУЩЕСТВО★, может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
