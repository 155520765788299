const {
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')
const {
  CREATURE_GITHZERAI_MONK,
  CREATURE_GITHZERAI_ZERTH,
  CREATURE_MIND_FLAYER,
} = require('./../../creatureIdList')

module.exports = [
  {
    text: `Сосредоточенные философы и строгие аскеты, гитцераи следуют жёсткому жизненному укладу. Худощавые и жилистые, они носят одежду без изысков, держа своё мнение при себе и доверяя единицам чужаков.

Отвернувшиеся от своих воинственных собратьев гитьянок, гитцераи придерживаются монашеского уклада, проживая на островах порядка, что находятся в бескрайнем море хаоса на плане Лимбо.`,
    source: {
      id: SOURCE_MM,
      page: 56,
    },
  },
  {
    header: 'Эксперты псионики',
    text: `Предки гитцераев трансформировались под психическим воздействием своих хозяев [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}) и адаптировались к нему.

Следуя учению Зертимона, призвавшему свой народ отказаться от воинственных амбиций Гит, они фокусируют свою ментальную энергию, создавая физические и психические барьеры, защищающие от различных атак.

Для гитцерая, использующего свой разум, чтобы ошеломить и обезвредить соперника, сделав его уязвимым для физических атак, бой является чем-то личным.`,
    source: {
      id: SOURCE_MM,
      page: 56,
    },
  },
  {
    header: 'Порядок среди хаоса',
    text: `Гитцераи добровольно поселились в сердце абсолютного хаоса Лимбо — кружащегося, непостоянного плана, подчинить который способны лишь достаточно сильные разумом гитцераи. Лимбо — буря первородной материи и энергии. Его поверхность — камни и земля, несущиеся в потоках мутной жижи, постоянно находящиеся под ударами сильных ветров, огненных взрывов и сокрушающих стен льда.
  
При этом силы Лимбо реагируют на сознание. Используя силу своего разума, гитцераи управляют несущимися хаотическими элементами, заставляя их застывать в неподвижной и незыблемой форме, создавая оазисы и убежища среди бури.
  
Крепости-монастыри гитцераев стоят непоколебимо, вопреки кружащемуся вокруг хаосу, практически невосприимчивые ко всей этой неразберихе благодаря воле гитцераев. Каждый монастырь находится под контролем монахов, которые устанавливают строгое расписание хоралов, питание, обучение боевым искусствам, и молитвы согласно своей собственной философии. За своими стенами, усиленными псионикой, гитцераи погружены в думы, обучение, познание псионических способностей, порядок и дисциплину.
  
Социальная иерархия гитцераев основана на заслугах, а лидерами становятся мудрейшие учителя, наиболее опытные как в физическом бою, так и ментальном. Гитцераи чтят героев и учителей прошлого, подражая их добродетели в повседневности.`,
    source: {
      id: SOURCE_MM,
      page: 56,
    },
  },
  {
    header: 'Последователи Зертимона',
    text: `Гитцераи почитают Зертимона — основателя их расы. Хоть Гит и дала их людям свободу, Зертимон узрел её неспособность к руководству. Он верил, что из-за своей воинственности она станет тираном не лучше, чем [свежеватели разума](CREATURE:${CREATURE_MIND_FLAYER}).
  
Опытных [монахов](CREATURE:${CREATURE_GITHZERAI_MONK}) гитцераев, лучше всего воплощающих учения и принципы Зертимона, зовут [зертами](CREATURE:${CREATURE_GITHZERAI_ZERTH}). Эти могущественные и дисциплинированные монахи могут перемещать свои тела с одного плана на другой, используя лишь силу разума. За пределами Лимбо. Хоть гитцераи и редко взаимодействуют с планами за пределами Лимбо, опытные монахи других рас иногда ищут монастыри гитцераев в попытках стать учениками. В редких случаях мастера гитцераев основывают скрытые монастыри на Материальном плане, где обучают юных гитцераев или распространяют философию и учения Зертимона.
  
Но даже такие дисциплинированные существа как гитцераи не могут забыть долгого порабощения [свежевателями разума](CREATURE:${CREATURE_MIND_FLAYER}). Показывая собственную самоотверженность, они организуют _рраккмы_ — охоту на [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}) — на других планах, не возвращаясь в монастыри, пока не убьют [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}), как минимум, по числу охотников в группе.`,
    source: {
      id: SOURCE_MM,
      page: 56,
    },
  },
  {
    header: 'Гитцераи',
    text: `Гитцераи появились как раса в конце кровавого восстания гитов против свежевателей разума. Гит по имени Зертимон, нашедший множество последователей во время конфликта, бросил вызов планам Гит и её лидерству. _«Гит зла,_ — провозгласил он, — _и она приведёт наш народ во тьму и тиранию, не отличающиеся от навязанных нам иллитидами»_.

И теперь, едва разгромив своих заклятых врагов, гиты были ввергнуты в пучину гражданской войны. Как итог конфликта, Зертимон был убит, а его последователи, назвавшие себя гитцераями, ушли на план Лимбо.

Сегодня, ведомые Великим Гитцераем, Заэритом Меняр-Агом-Гитом, они продолжают вести борьбу с гитьянки и нести возмездие свежевателям разума. Посещая Материальный план и другие миры, они оказывают жёсткое противодействие планам своих врагов по мировому господству.`,
    source: {
      id: SOURCE_MTOF,
      page: 94,
    },
  },
  {
    header: 'Порядок в море хаоса',
    text: `Сильные разумом философы и суровые аскеты, гитцераи проводят свою жизнь в строгой дисциплине. Их общество основано на усилении потенциала разума через медитации, образование и физические испытания. Наиболее совершенные из них являются образцами монашеских принципов гитцераев, но даже те, кто исполняет мирские обязанности в общине, обладают значительной долей той же психической стойкости.

Жизнь в вечно меняющемся вихре Лимбо требует от гитцераев постоянного использования силы мысли, необходимого для противодействия и сдерживанию хаосу плана. Если бы они не были непреклонны в этом деле, потоки Лимбо сокрушили бы и уничтожили их.

Цели жизни гитцераем во многом едины и исходят из почитания своих великих героев и желания подражать добродетели этих личностей в своей повседневной жизни.`,
    source: {
      id: SOURCE_MTOF,
      page: 94,
    },
  },
  {
    header: 'Меняр-Аг, великий гитцерай',
    text: `Меняр-Аг привёл гитцераев в Лимбо в кульминационный момент конфликта между Гит и Зертимоном. Благодаря невообразимым магическим и псионическим силам, его жизнь длится намного дольше, чем обычная жизнь гитцерая. Однако время всё же берёт своё, и сегодня Меняр-Аг представляет собой дряхлую, больше похожую на труп сущность, способную на выдающиеся свершения в магии и псионике, но едва способную пошевелить пальцами или поднять веки. Множество слуг постоянно присутствуют подле Меняр-Ага, исполняя все его нужды.

И хотя Меняр-Аг больше не способен совершать физические действия без чьей-либо помощи, его разум всё так же активен и остр, как и всегда. Он никогда не спит, используя сменяющийся штат обслуживающего персонала, через который доводит свои команды и советы до всех гитцераев. Если нужно, он может призвать свою собственную психическую энергию, чтобы связаться со своими соотечественниками, даже находящимися на других планах.`,
    source: {
      id: SOURCE_MTOF,
      page: 95,
    },
  },
  {
    header: 'Анархи',
    text: `Общество гитцераев постоянно работает над поддержанием стабильной базы, защищённой от хаоса Лимбо. Ментальные силы всего общества, направленные на удержание сил хаоса, проходят через особенных гитцераев, известных как анархи. Один или несколько анархов поддерживают каждую общину, служа как вместилищем для психической силы других гитцераев, так и инструментом, с помощью которого эта сила может быть использована.

Анархи владеют особым даром к подчинению и контролированию хаоса Лимбо. В своих общинах в Лимбо они могут создавать энергию и материю из ничего лишь силой своей мысли, подчинять и изменять гравитацию по своей воле и изменять всё вокруг них.

Анархи чрезвычайно редки среди гитцераев. Когда гитцерай в уже существующей общине начинает демонстрировать навыки анарха, он или она может покинуть общину и основать свою собственную колонию или же остаться в нынешней, получив руководящую должность.`,
    source: {
      id: SOURCE_MTOF,
      page: 95,
    },
  },
  {
    header: 'Зерты',
    text: `Смертная оболочка Зертимона погибла в бою, однако его жертва освободила гитцерай от тёмной судьбы, предначертанной гитам. Теперь они верят, что Зертимон в иной, божественной форме, однажды вернётся и поведёт их в новую эру свободы. До той поры, пока это не случилось, гитцерай, известные как зерты, исполняют символическую роль Зертимона в обществе, как обладатели псионической силы, способные перемещать себя и остальных между планами.

Гитцерай верят, что когда Зертимон вернётся, он первым делом заберёт всех зертов в новый рай, чтобы подготовить их к грядущему. По своей сути зерты исполняют ту же роль, что и жрецы других рас, за тем исключением, что у гитцераев нет религии как таковой, помимо почитания Зертимона и Меняр-Ага.`,
    source: {
      id: SOURCE_MTOF,
      page: 95,
    },
  },
  {
    header: 'Города-крепости',
    text: `Монастыри гитцераев — это огромные острова стабильности, дрейфующие в хаосе Лимбо. Анархи гитцераев поддерживают стабильность крепостей и управляют их внутренним строением, лишь по мере нужды открывая порталы во внешний мир. Большинство крепостей дрейфуют по Лимбо наугад, но нельзя сказать ни об одной из них, что она изолирована. Когда Меняр-Аг взывает к ним, анархи мгновенно отвечают зову.

Не считая самих её обитателей, самые защищённые элементы крепости гитцераев — склады с едой. Поскольку постоянная жизнь в Лимбо невозможна, гитцераи сильно зависят от животных и растений, выращенных где-то ещё. Растения выращиваются на гидропонных фермах, а скот — в загонах, в которых хватает света, тепла и всего прочего для их роста.

Жизнь общины контролируется монахами, которые распределяют обязанности между жителями. Каждый из них принимает участие в тренировках монахов и непрерывном теоретическом обучении, и каждая крепость выделяет для этого необходимое ресурсы и учителей по мере необходимости. И хотя крепости не выходят из радиуса досягаемости психического контакта, каждая из них спроектирована так, чтобы быть самодостаточной.`,
    source: {
      id: SOURCE_MTOF,
      page: 95,
    },
  },
  {
    header: 'Шра’кт’лор',
    text: `Шра’кт’лор — это самый густонаселённый город-крепость гитцераев. Он выступает одновременно столицей цивилизации и штаб-квартирой воинских сил. Величайшие военачальники, волшебники и зерты расы встречаются здесь, чтобы создать или обсудить планы по сражению с гитьянки и свежевателями разума.

Шра’кт’лор — это самая защищённая из крепостей гитцераев; никто и ничто в Лимбо не способно хоть сколько-то угрожать городу или его обитателям. Телепортационные круги в крепости запрещены, за исключением постоянно охраняемых мест за внешней линией обороны города. Использующим магию перемещения между планами для того, чтобы попасть в город, или идущим в город через хаос Лимбо, не суждено попасть сюда без дозволения анарха.

За входом же в город ожидают шесть уровней защиты, каждый из которых представляет собой крепость, управляемую могущественным анархом, избираемым лично Заэритом Меняр-Агом-Гитом, восседающим в самом сердце города.`,
    source: {
      id: SOURCE_MTOF,
      page: 95,
    },
  },
  {
    header: 'Гитцераи в мире',
    text: `Нет ничего необычного в том, что гитцераи предпочитают оставаться в Лимбо. Они создали высокоорганизованную цивилизацию в мире, который они могут изменять по своему желанию. Когда они посещают другие миры, в том числе Материальный план, то чувствуют себя некомфортно в условиях неизменности окружения. Но несмотря на своё нежелание путешествовать, гитцераи регулярно посылают группы из Лимбо в другие миры, где те противодействуют силам гитьянок и свежевателей разума.`,
    source: {
      id: SOURCE_MTOF,
      page: 95,
    },
  },
  {
    header: 'Адамантовые цитадели',
    text: `Во время путешествий гитцераи иногда берут часть Лимбо с собой. Перед отправлением могущественный анарх создаёт адамантовую цитадель из материи хаоса вокруг себя. Внутри них находится энергия Лимбо в чистом виде, хранящаяся там, пока не понадобится.

Затем во вспышке псионики и магии, создать которую под силу только Меняр-Агу, цитадель и находящиеся в ней гитцераи перемещаются на другой план. Когда перемещение завершится, хотя бы один анарх должен всегда оставаться в цитадели, чтобы поддерживать её форму и состояние, а также использовать заключённую внутри материю хаоса.

После того, как цитадель будет установлена на другом плане, гитцераи создают телепортационные круг внутри крепости, создавая путь между этим планом и Лимбо.

Появление адамантовой цитадели на другом плане губительно отражается на живом мире. Жизнь, единственная вещь, которая не может быть спонтанно создана из материи Лимбо, бежит от цитадели, сломя голову. В зависимости от размера цитадели, зона поражения может иметь радиус от нескольких сотен футов до нескольких миль. Птицы стараются не подлетать к ней, животные убегают как можно дальше, а растения поблизости от неё увядают и умирают. Эти эффекты не действуют на разумных существ, но пребывание в радиусе воздействия крепости заставляет их нервничать и, если они попробуют узнать причину, то скоро поймут, что всё дело в цитадели. Тем не менее, гитцераи считают, что в их интересах не выдавать своё присутствие и как можно лучше скрывать цитадели. Меняр-Аг предпочитает посылать их в удалённые и редко посещаемые места, такие как безжизненные пустыни или пустые пещеры Подземья.

Основное назначение адамантовой цитадели — вести наблюдение за врагами гитцераев, такими как колонии свежевателей разума, или служить базой в грядущих операциях. Помимо этого, цитадели используются для сбора еды и других материальных благ, которые позже переправляют в Лимбо. Когда гитцераи заканчивают свои дела на плане, они опустошают цитадель и возвращаются в Лимбо. После того, как последний анарх покидает цитадель, она растворяется, оставляя после себя лишь покорёженную землю в том месте, где она стояла.`,
    source: {
      id: SOURCE_MTOF,
      page: 94,
    },
  },
  {
    header: 'Распространяя Слово',
    text: `Гитцераи видят в своём деле не что-то обыкновенное, а то, что они хотят нести другим. Руководствуясь этим, они взяли на себя инициативу проповедовать философию Зертимона и делиться с окружающими знания о том, как бороться с иллитидами и гитьянки.

С этой целью зерты иногда отправляются на другие планы с намерением основать новый монастырь или присоединиться к существующему. Эти «миссионеры» всегда ищут тех, у кого есть псионический потенциал, и кто может использовать эти силы против врагов гитцераев. Чаще всего они действуют тайно, преследуя свои цели и пытаясь пополнить ряды своих союзников.

Кто может точно сказать, сколько монастырей на самом деле являются секретными вербовочными базами гитцераев?`,
    source: {
      id: SOURCE_MTOF,
      page: 94,
    },
  },
  {
    header: 'Собираясь в атаку',
    text: `Гитцераи прекрасно понимают, что они не добьются успеха в войне против своих врагов, вечно находясь в своих крепостях в Лимбо. Для того, чтобы мешать иллитидам и гитьянкам и сокращать их численность, отряды гитцераев часто отправляются на другие планы, чтобы истреблять своих врагов.

# Гитьянки

Гитцераи редко сталкиваются с гитьянки в их доме на Астральном Плане, но на других планах гитцераи неустанно следят за гитьянки, всегда готовые нарушить их планы и уничтожить ясли. Во время таких миссий гитцераи стараются не подвергать опасности местных обитателей, но они никогда не пожертвуют спланированной атакой, чтобы защитить тех, кто встанет между ними и их врагами. В борьбе с гитьянки цель оправдывает средства.

Гитцераи иногда пользуются услугами наёмников с Материального плана, чтобы те помогли им в сражении с гитьянки, в основном руководствуясь целью уравнять баланс сил или обеспечить себе подкрепления. Тем, с кем они заключают подобные сделки, они предлагают часть богатой добычи, которой у гитьянки в достатке.

# Свежеватели разума

Хоть и большая часть усилий гитцераев направлена на постоянные военные кампании против гитьянки, их вражда со свежевателями разума куда древнее. Гитьянки и гитцераи мало в чём сходятся во мнениях, но их отношение к свежевателям разума схоже: они должны заплатить за то, что совершили с гитами в прошлом.

Когда дело доходит до мести свежевателям разума, гитцераи отправляют _рраккмы_, отрядов охотников на иллитидов, на другие планы чтобы там сражаться с любыми встреченными свежевателями разума. Именно в таких обстоятельствах обитатели планов чаще всего и встречают гитцераев вне их монастырей. Последние же, слишком сосредоточенные на миссии, не обращают на окружающих внимания до тех пор, пока их охоте не мешают.`,
    source: {
      id: SOURCE_MTOF,
      page: 94,
    },
  },
]
