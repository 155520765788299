const {PHB} = require('./../../../sourceList')
const {PC_SUBCLASS_BARD_COLLEGE_OF_VALOR} = require('./../../../pcSubClassIdList')
const {GEAR_SHIELD} = require('./../../../gearIdList')
const {extraAttackTemplate} = require('./../../featureCommonRawList')
const {
  FEATURE_BARDIC_INSPIRATION,
  FEATURE_BATTLE_MAGIC,
  FEATURE_BONUS_PROFICIENCIES_BARD_COLLEGE_OF_VALOR,
  FEATURE_COMBAT_INSPIRATION,
  FEATURE_EXTRA_ATTACK_BARD_COLLEGE_OF_VALOR,
} = require('./../../../featureIdList')


module.exports = [
  {
    id: FEATURE_BONUS_PROFICIENCIES_BARD_COLLEGE_OF_VALOR,
    name: `Дополнительные навыки`,
    nameEn: `Bonus Proficiencies`,
    lvl: 3,
    text: `Вы получаете владение средними доспехами, [щитами](GEAR:${GEAR_SHIELD}) и воинским оружием. `,
  },
  {
    id: FEATURE_COMBAT_INSPIRATION,
    name: `Боевое вдохновение`,
    nameEn: `Combat Inspiration`,
    lvl: 3,
    text: `Вы узнаёте, как вдохновлять других в бою.

Существо, получившее от Вас кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}), может бросить эту кость и добавить результат к своему броску урона оружием.

В качестве альтернативы, если существо атаковано, оно может реакцией совершить бросок [Кости вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и добавить результат к своему КД от этой атаки. Оно может сделать это после броска атаки, но до того, как узнает, попали ли по нему.`,
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_BARD_COLLEGE_OF_VALOR,
    lvl: 6,
  },
  {
    id: FEATURE_BATTLE_MAGIC,
    name: `Боевая магия`,
    nameEn: `Battle Magic`,
    lvl: 14,
    text: `Вы научились сплетать использование заклинаний и оружия в одно гармоничное действие.

Если Вы действием используете заклинание барда, то бонусным действием можете совершить одну атаку оружием. `,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_VALOR,
    source: {
      id: PHB,
      page: 55,
    },
  })
)

