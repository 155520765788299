const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  LANG_ABYSSAL,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPELL_CLOUDKILL,
  SPELL_DARKNESS,
  SPELL_DISPEL_MAGIC,
} = require('./../../../../spellIdList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_YUGOLOTH,
} = require('./../../../../creatureTypeIdList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_MEZZOLOTH} = require('./../../../../creatureIdList')
const {GEAR_TRIDENT} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {yugolothDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Меззолот',
  nameEn: 'Mezzoloth',
  id: CREATURE_MEZZOLOTH,
  description: [
    `Значительная часть популяции юголотов состоит из меззолотов, насекомоподобных существ размером с человека, покрытых хитиновыми пластинами. Меззолоты — пехотинцы армий юголотов; их широко посаженные глаза сияют красным светом, когда они бросаются на врагов.

Меззолотом движет жажда награды и насилия, и если могущественное существо предложит хотя бы что-то одно из двух, меззолота можно будет привлечь на службу. Хотя на всех четырёх руках у него смертоносные когти, обычно в двух из них меззолот держит трезубец. Если его окружат враги, меззолот может выдохнуть токсичные пары, которые вызывают удушье и могут убить целую группу существ.`,
    ...yugolothDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_YUGOLOTH,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 318,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 16,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 2,
          period: 'день',
        },
        list: [
          SPELL_DISPEL_MAGIC,
          SPELL_DARKNESS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CLOUDKILL,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Когтями_, и одну _Трезубцем_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
    {
      gearId: GEAR_TRIDENT,
    },
    {
      id: ABILITY_TELEPORT,
      range: 60,
    },
  ],
  genderId: GENDER_MALE,
}
