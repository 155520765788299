const generateRarityList = require('./../../../../../../utils/generateRarityList')

const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {MAGIC_ITEM_BLOODWELL_VIAL} = require('./../../../../../magicItemIdList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {GEAR_VIAL} = require('./../../../../../gearIdList')
const {FEATURE_SORCERY_POINTS} = require('./../../../../../featureIdList')
const {PC_CLASS_SORCERER} = require('./../../../../../pcClassIdList')

const siblingIdList = require('./siblingIdList')
const list = require('./list')

module.exports = {
  id: MAGIC_ITEM_BLOODWELL_VIAL,
  name: `Флакон кровавого колодца, +1, +2 или +3`,
  nameEn: `Bloodwell Vial, +1, +2 or +3`,
  rarity: generateRarityList(list),
  gearType: GEAR_VIAL,
  attunement: true,
  attunementComment: `чародеем`,
  type: MGC_TYPE_WONDROUS_ITEM,
  description: `Чтобы настроиться на этот флакон, Вы должны поместить в него несколько капель своей крови. _Флакон кровавого колодца_ невозможно открыть, пока Вы на него настроены. Если Ваша настройка завершается, кровь в нём обращается в пыль.

Держа или нося с собой _Флакон кровавого колодца_, Вы можете использовать его как заклинательную фокусировку и получаете бонус к броскам атаки заклинаниями и СЛ испытаний Ваших заклинаний [чародея](PC_CLASS:${PC_CLASS_SORCERER}). Размер бонуса определяется редкостью _Флакона_.

Когда Вы несёте _Флакон кровавого колодца_ и бросаете Кость хитов, чтобы восстановить хиты, Вы восстанавливаете 5 [Очков чародейства](FEATURE:${FEATURE_SORCERY_POINTS}). Эту способность _Флакона_, нельзя повторно использовать до следующего рассвета.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 176,
  },
}
