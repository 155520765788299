const arrify = require('arrify')

const {damageTypeCollection} = require('./../../../damageTypeList')

const generateDamageText = damageTypeIdList => arrify(damageTypeIdList)
  .map(
    (damageTypeId, i) => damageTypeCollection[damageTypeId][i ? 'name' : 'fullName'].toLowerCase()
  )
  .join(' или ')

module.exports = generateDamageText
