const {
  SPELL_BURNING_HANDS,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CONE_OF_COLD,
  SPELL_COUNTERSPELL,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_DIMENSION_DOOR,
  SPELL_FAERIE_FIRE,
  SPELL_FIREBALL,
  SPELL_GUST_OF_WIND,
  SPELL_HASTE,
  SPELL_INVISIBILITY,
  SPELL_LEVITATE,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MESSAGE,
  SPELL_MISTY_STEP,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_POISON_SPRAY,
  SPELL_RAY_OF_FROST,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ACROBATICS,
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_DROW_FAVORED_CONSORT,
  CREATURE_DROW_MATRON_MOTHER,
  CREATURE_DROW_PRIESTESS_OF_LOLTH,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  GEAR_CROSSBOW_HAND,
  GEAR_SCIMITAR,
} = require('./../../../../gearIdList')
const {
  drowDescription,
  drowMageNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_18} = require('./../../../../crList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дроу супруг-фаворит',
  nameAlt: 'Дроу благоприятный супруг',
  nameEn: 'Drow Favored Consort',
  id: CREATURE_DROW_FAVORED_CONSORT,
  description: [
    `Практически все [жрицы Ллос](CREATURE:${CREATURE_DROW_PRIESTESS_OF_LOLTH}), включая могущественных [верховных матерей](CREATURE:${CREATURE_DROW_MATRON_MOTHER}), выбирают привлекательных дроу в свои супруги. Зачастую такие личности бесполезны в чём-либо помимо принесения удовольствия, размножения, или и того, и другого. Но иногда супругу удаётся заслужить внимание своей жрицы и стать её полезным советником. Мало кто задерживается в статусе супруга надолго; жрицы печально известны своей переменчивостью в отношениях со своими вторыми половинками, среди которых они особенно рады заполучить тех, кто сочетает в себе красоту и магическую силу.`,
    ...drowDescription,
  ],
  note: drowMageNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 164,
  },
  armor: [
    15,
    {
      ac: 18,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 30,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 16,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ELVEN,
    LANG_UNDERCOMMON,
  ],
  cr: CR_18,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_SUNLIGHT_SENSITIVITY,
    {
      name: 'Боевая магия',
      description: `Когда дроу использует действие для наложения заклинания, он может бонусным действием совершить одну атаку оружием.`,
    },
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DANCING_LIGHTS,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_DARKNESS,
            SPELL_FAERIE_FIRE,
            {
              id: SPELL_LEVITATE,
              comment: 'только на себя',
            },
          ],
        },
      ],
    },
    {
      spellCasterLevel: 11,
      spellCasterClass: PC_CLASS_WIZARD,
      spellIdList: [
        SPELL_BURNING_HANDS,
        SPELL_CHAIN_LIGHTNING,
        SPELL_CONE_OF_COLD,
        SPELL_COUNTERSPELL,
        SPELL_DIMENSION_DOOR,
        SPELL_FIREBALL,
        SPELL_GUST_OF_WIND,
        SPELL_HASTE,
        SPELL_INVISIBILITY,
        SPELL_MAGE_ARMOR,
        SPELL_MAGE_HAND,
        SPELL_MAGIC_MISSILE,
        SPELL_MESSAGE,
        SPELL_MISTY_STEP,
        SPELL_OTILUKE_S_RESILIENT_SPHERE,
        SPELL_POISON_SPRAY,
        SPELL_RAY_OF_FROST,
        SPELL_SHATTER,
        SPELL_SHIELD,
        SPELL_SHOCKING_GRASP,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        2,
        1,
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Дроу совершает три атаки _Скимитаром_.`,
    },
    {
      gearId: GEAR_SCIMITAR,
      description: `Кроме того, цель получает Помеху на следующее испытание от заклинания, которое сотворит дроу до конца его следующего хода.`,
      attack: {
        hit: {
          type: DAMAGE_POISON,
          diceCount: 4,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
    {
      gearId: GEAR_CROSSBOW_HAND,
      description: `Цель должна пройти испытание Телосложения СЛ 13, или станет Отравленной на 1 час. Если испытание провалено на 5 и больше, то цель, помимо отравления, теряет сознание. К цели возвращается сознание, если она получила урон или её приводят в себя действием.`,
    },
  ],
}
