const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require ('./../../sourceList')
const {
  LANG_GURAN,
  LANG_RASHEMI,
  LANG_ROUSHOUM,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_GURAN,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Гуран',
    genitive: 'Гурана',
    instrumental: 'Гураном',
    prepositional: 'Гуране',
  },
  nameEn: 'Guran',
  dictionary: {
    list: [
      ['ведьма', 'викларан'],
    ],
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  typicalSpeakers: 'Гуры',
  description: [
    {
      text: `Гуран — смесь [Рушума](LANG:${LANG_ROUSHOUM}) и [Рашемийского](LANG:${LANG_RASHEMI}) языков.`,
      source: {
        id: SOURCE_SCAG,
        page: 113,
      },
    },
  ],
  isReady: true,
  isRealLang: true,
}
