const {
    CREATURE_DESPAIR_SHADOWSPAWN_SPIRIT,
    CREATURE_FURY_SHADOWSPAWN_SPIRIT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух тени ярости',
  nameEn: 'Fury Shadowspawn Spirit',
  id: CREATURE_FURY_SHADOWSPAWN_SPIRIT,
  parentId: CREATURE_DESPAIR_SHADOWSPAWN_SPIRIT,
  featureList: [
    {
      name: 'Ужасная ярость',
      description: `★СУЩЕСТВО★ атакует испуганных существ с преимуществом.`,
    },
  ],
}
