const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  oozeDescriptionList,
  oozeNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AMORPHOUS,
  ABILITY_FALSE_APPEARANCE,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_GRAY_OOZE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_OOZE} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Серая слизь`,
  nameEn: 'Gray Ooze',
  id: CREATURE_GRAY_OOZE,
  description: [
    `Серая слизь — это камень, ставший жидким из-за воздействия хаоса. Она движется как жидкая змея, приподнимаясь для атаки.`,
    ...oozeDescriptionList,
  ],
  note: oozeNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_OOZE,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 269,
  },
  armor: 8,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_CLIMB]: 10,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 6,
    [PARAM_CON]: 16,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 2,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_COLD,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_AMORPHOUS,
    {
      name: `Разъедание металла`,
      description: `Любое немагическое оружие, изготовленное из металла, попадающее по ★СУЩЕСТВО★, начинает разъедаться. После причинения урона оружие получает постоянный накопительный штраф −1 к броскам урона. Если этот штраф дойдёт до −5, оружие будет уничтожено. Немагические боеприпасы, изготовленные из металла или дерева, попадающие по ★СУЩЕСТВО★, уничтожаются после причинения урона. ★СУЩЕСТВО★ может разъесть 2 дюйма немагического металла за 1 раунд.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `маслянистой лужи или мокрого камня`,
    },
  ],
  actionList: [
    {
      name: `Ложноножка`,
      description: `Если цель носит немагический металлический доспех, он частично растворяется и получает постоянный накопительный штраф −1 к КД, который он предлагает. Доспех уничтожается, если штраф уменьшает его КД до 10.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 1,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
