const {SOURCE_MTOF} = require('./../../sourceList')

module.exports = [
  {
    header: 'Древние элементали',
    text: `На своих родных планах, эти элементали проносятся по чуждым и буйным землям. Некоторые из них обладают превосходящей силой, которую они получили, поглотив своих низших сородичей и заполучив их эссенцию, благодаря чему они стали необыкновенными сущностями. Будучи призванными, эти древние элементали демонстрируют свои катастрофические способности, знаменующие вокруг себя тотальное разрушение.`,
    source: {
      id: SOURCE_MTOF,
      page: 159,
    },
  },
  {
    header: 'Смертельно опасны, будучи призванными',
    text: `Способы призыва этих древних элементалей до сих пор скрыты в запретных книгах или выточены на стенах давно забытых и потерянных храмов, в которых почиталось Древнее Стихийное Око. Только у заклинателей с превосходными навыками может появиться хотя бы малый шанс призвать одного из этих чудовищ, но многие заклинатели, пытавшиеся сделать это, были уничтожены. Таким образом, только самые неуравновешенные и нигилистические члены культов Стихийного Зла могут попытаться сотворить этот призыв в надежде ускорить конец света всему миру.`,
    source: {
      id: SOURCE_MTOF,
      page: 159,
    },
  },
  {
    header: 'Стихийная природа',
    text: `Древний элементаль не нуждается в воздухе, пище, воде или сне.`,
    source: {
      id: SOURCE_MTOF,
      page: 159,
    },
  },
]
