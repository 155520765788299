const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_APE,
  CREATURE_GIRALLON,
} = require('./../../../../creatureIdList')
const {
  ABILITY_AGGRESSIVE,
  ABILITY_KEEN_SMELL,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Гираллон',
  nameEn: 'Girallon',
  id: CREATURE_GIRALLON,
  description: [
    `Гираллон выглядит как четырёхрукая горилла-переросток с серой кожей и белым мехом. От обычной обезьяны его также отличают клыки и когти, выдавая в нём чудовищного хищника.`,
    {
      header: 'Лесные охотники',
      text: `Гираллоны наиболее распространены в умеренных и тёплых лесных местностях, богатых жизнью. Они разделяют навыки умелого лазанья, которыми наделены обычные [обезьяны](CREATURE:${CREATURE_APE}), однако эти полутонные существа стараются не вскарабкиваться на деревья, которые не выдержат их веса. Вместо этого они рыщут по подлеску, скрываясь в узких оврагах или неглубоких пещерах, либо прячутся в каких-нибудь руинах, дожидаясь, пока поблизости пройдёт жертва. Для своего размера и с учётом отсутствия у него камуфляжа гираллон удивительно хорошо прячется.

Гираллоны собираются в небольшие стайки, состоящие из нескольких особей и детенышей, обычно возглавляемых доминимующим самцом, которым обычно бывает самый старший член группы. Охотясь вдали от логова, гираллоны общаются друг с другом на расстоянии с помощью рыков и языка тела. Обычно каждая особь охотится самостоятельно, находясь при этом достаточно далеко от других, чтобы каждому досталось достаточно пищи. Вожак может организовать членов стаи для совместной охоты, если добыча крупная. Если охота пройдет удачно, каждому в группе достанется его доля, а лучшие части полагаются матерям, заботящимся о детёнышах.`,
      source: [
        {
          id: SOURCE_VGTM,
          page: 140,
        },
        {
          id: SOURCE_TOA,
          page: 219,
        },
      ],
    },
    {
      header: 'Стенолазы',
      text: `Руины, где раньше жили гуманоиды, особенно находящиеся в лесной глуши или джунглях, похоже, привлекают гираллонов. Они без труда перемещаются по лестницам и балконам, а также наклонным крышам и балкам. Для гираллона здания в городе — просто другая разновидность леса, но, что хорошо, «ветви» этого леса могут выдержать вес существа. В такой среде гираллоны применяют свои навыки лазанья на полную. Существа могут легко взобраться на стену здания или защитные сооружения, а затем засесть на крыше или другой высокой точке обзора, чтобы следить за окружающей местностью.`,
      source: [
        {
          id: SOURCE_VGTM,
          page: 140,
        },
        {
          id: SOURCE_TOA,
          page: 219,
        },
      ],
    },
    {
      header: 'Магическое происхождение',
      text: `Социальные повадки диких гираллонов необычны для приматов, как и их инстинктивная тяга к гуманоидным постройкам. Эти обстоятельства вместе со внешностью гираллонов приводят ученых к выводу, что гираллоны были созданы магией, чтобы служить стражниками в какой-то забытой империи. Когда многие годы назад империя пала, гираллоны одичали и распространились по миру. За прошедшие с тех пор времена многие существа пытались приручить, подчинить или сосуществовать с гираллонами. Например, юань-ти порабощают гираллонов, превращая их в приграничных стражей в своих змеиных королевствах. Благодаря тому общеизвестному факту, что гираллоны между собой не враждуют, некоторые гуманоиды научились обращаться к вожаку стаи, преподнося пищу и другие подарки в надежде установить альянс с существами.

Если с гираллонами хорошо обращаться, они могут согласиться служить стражей, хотя у них не хватит интеллекта на задания более сложные, чем нападать на незнакомцев, вторгающихся в их владения. Воспитанный должным образом с юного возраста гираллон может в итоге оказаться в достаточно неожиданном месте, например его можно обнаружить охраняющим вход в городскую гильдию воров. Желающим содержать гираллона в качестве питомца следует быть осторожными, потому что существо может внезапно вернуться к своей хищнической природе в любой момент.`,
      source: [
        {
          id: SOURCE_VGTM,
          page: 140,
        },
        {
          id: SOURCE_TOA,
          page: 219,
        },
      ],
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: [
    {
      id: SOURCE_VGTM,
      page: 140,
    },
    {
      id: SOURCE_TOA,
      page: 219,
    },
  ],
  armor: 13,
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_AGGRESSIVE,
    ABILITY_KEEN_SMELL,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Гираллон совершает пять атак: одну _Укусом_ и четыре _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
}
