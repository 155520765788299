const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_PALADIN,
} = require('./../../../../../pcClassIdList')
const {
  MAGIC_ITEM_TALISMAN_OF_ULTIMATE_EVIL,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_TALISMAN_OF_ULTIMATE_EVIL,
  name: `Талисман абсолютного зла`,
  nameEn: `Talisman of Ultimate Evil`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `требуется настройка существом со злым мировоззрением`,
  isConsumable: true,
  description: `Этот предмет символизирует чистое зло. Существа, чьё мировоззрение и не доброе и не злое, получают урон некротической энергией 6к6, когда прикасаются к талисману. Доброе существо получает урон некротической энергией 8к6, когда прикасается к талисману. Все эти существа получают такой же урон каждый раз, когда оканчивают ход, держа или неся талисман.

Если Вы — злой [жрец](PC_CLASS:${PC_CLASS_CLERIC}) или [паладин](PC_CLASS:${PC_CLASS_PALADIN}), Вы можете использовать талисман как святой символ, и Вы получаете бонус +2 к броскам атаки заклинаний, пока носите или держите его.

У талисмана есть 6 зарядов. Если Вы носите или держите его, Вы можете действием потратить 1 заряд и выбрать одно существо, которое видите находящимся на полу в пределах 120 футов от Вас. Если у цели доброе мировоззрение, под ней откроется огненная расщелина. Цель должна пройти испытание Ловкости СЛ 20, иначе она упадёт в расщелину и уничтожится, не оставив после себя останков.

Расщелина после этого закрывается, не оставляя следов. Когда Вы тратите последний заряд, талисман растворяется дурно пахнущей слизью и уничтожается.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 208,
  },
}
