const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_4,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_SPELL,
  } = require('./../../../../attackTypeList'),
  {
    LANG_COMMON,
    LANG_ELVEN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_POISON,
    DAMAGE_THUNDER,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  } = require('./../../../../conditionList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    ABILITY_INCORPOREAL_MOVEMENT,
  } = require('./../../../../creatureAbilityList'),
  {
    PC_RACE_ELF,
  } = require('./../../../../pcRaceIdList'),
  {
    CREATURE_BANSHEE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Баньши',
  nameEn: 'Banshee',
  id: CREATURE_BANSHEE,
  description: `Когда опускается ночь, незадачливый путник может услышать отдалённые крики одинокого мертвеца. Это баньши, горестный дух, злобное создание, появившееся из духа эльфийки.

Баньши выглядит как светящаяся, тонкая форма, очертаниями смутно похожая на своё смертное тело. Лицо окутано всклокоченными волосами, а тело одето в просвечивающие лохмотья, которые колышутся вокруг баньши.

# Гнев богов

Баньши это нежить, воскресшая из останков [эльфиек](PC_RACE:${PC_RACE_ELF}). Тех [эльфиек](PC_RACE:${PC_RACE_ELF}), которые были благословлены великой красотой, но не смогли использовать этот дар, чтобы привнести в мир радость. Они использовали свою красоту для совращения и контролирования других. [Эльфийки](PC_RACE:${PC_RACE_ELF}), страдающие от проклятья баньши, не испытывают радости, и в присутствии живых испытывают только страдание. Когда проклятье овладевает ими полностью, их разум и тело начинают распадаться, пока не завершится окончательная трансформация в нежить.

# Границы скорби

Баньши навсегда привязаны к месту своей гибели, не в силах удалиться от него более чем на пять миль. Баньши вынуждены снова и снова переживать каждое мгновение своей жизни в идеальных воспоминаниях, но всегда отказываясь принимать ответственность за свою судьбу.

# Собиратели прекрасного

Тщеславие, являющееся основополагающей силой проклятья баньши, не пропадает после смерти. Эти существа жаждут красивых предметов: ювелирные украшения, картины, статуи и другие предметы искусства. В то же время баньши не переносят каких-либо зеркальных поверхностей, потому что не могут вынести ужаса своего внешнего вида. Одного беглого взгляда бывает достаточно, чтобы баньши впала в ярость.

# Природа нежити

Баньши не нуждается в воздухе, еде, питье и сне.`,
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 23,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 40,
      isFloating: true,
    },
  },
  hp: {
    diceCount: 6,
  },
  armor: 12,
  cr: CR_4,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_THUNDER,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ELVEN,
  ],
  featureList: [
    {
      name: 'Обнаружение жизни',
      description: `★СУЩЕСТВО★ магически чувствует присутствие существ на расстоянии 5 миль, не являющихся ни нежитью, ни конструктами. Она знает общее направление, но не точное местоположение.`,
    },
    ABILITY_INCORPOREAL_MOVEMENT,
  ],
  actionList: [
    {
      name: 'Разлагающее касание',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 3,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Ужасный облик',
      description: `Все существа, находящиеся в пределах 60 футов от ★СУЩЕСТВО★, не являющиеся нежитью и видящие ★его★, должны пройти испытание Мудрости СЛ 13, иначе они становятся испуганными на 1 минуту. Испуганная цель может повторять это испытание в конце каждого своего хода, с помехой, если видит ★СУЩЕСТВО★, оканчивая эффект на себе при успехе. Если цель успешно прошла испытание или действие эффекта закончилось, она получает иммунитет к _Ужасному облику_ ★этого★ ★СУЩЕСТВО★ на следующие 24 часа.`,
    },
    {
      name: 'Вопль',
      description: `Если ★СУЩЕСТВО★ не находится на солнечном свете, она может издать скорбный вопль. Этот вопль не оказывает эффект на конструктов и нежить. Все другие существа в пределах 30 футов от ★него★, услышавшие вопль, должны пройти испытание Телосложения СЛ 13. При провале хиты существа снижаются до 0. При успехе существо получает урон психической энергией 10 (3к6).`,
      limit: {
        count: 1,
        period: 'день',
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
