const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GOBLIN,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    LANG_COMMON,
    LANG_GOBLIN,
  } = require('./../../../../languageIdList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_CHAIN_SHIRT,
    GEAR_JAVELIN,
    GEAR_SCIMITAR,
    GEAR_SHIELD,
  } = require('./../../../../gearIdList'),
  {
    goblinDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_NIMBLE_ESCAPE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_GOBLIN_BOSS,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Босс гоблинов',
  nameEn: 'Goblin Boss',
  id: CREATURE_GOBLIN_BOSS,
  description: goblinDescriptionList,
  note: {
    text: `Брии-Ярк!`,
    author: `«Мы сдаёмся!» на гоблинском (ну, или, по крайней мере, так считается)`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_GOBLIN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 64,
  },
  armor: {
    ac: 17,
    gearId: [GEAR_CHAIN_SHIRT, GEAR_SHIELD],
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GOBLIN,
    LANG_COMMON,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_NIMBLE_ESCAPE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Скимитаром_. Вторая атака совершается с помехой.`,
    },
    {
      gearId: GEAR_SCIMITAR,
    },
    {
      gearId: GEAR_JAVELIN,
    },
  ],
  reactionList: [
    {
      name: 'Перенаправление атаки',
      description: `Если существо, которое видит ★СУЩЕСТВО★, нацеливается на ★него★ атакой, ★СУЩЕСТВО★ выбирает другого гоблина в пределах 5 футов от себя. Они меняются местами, и целью становится выбранный гоблин.`,
    },
  ],
  genderId: GENDER_MALE,
}
