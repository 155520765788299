const {
  CREATURE_APE,
  CREATURE_AXE_BEAK,
  CREATURE_BABOON,
  CREATURE_BADGER,
  CREATURE_BLACK_BEAR,
  CREATURE_BOAR,
  CREATURE_BROWN_BEAR,
  CREATURE_DIRE_WOLF,
  CREATURE_GIANT_BADGER,
  CREATURE_GIANT_BOAR,
  CREATURE_GIANT_ELK,
  CREATURE_GIANT_GOAT,
  CREATURE_GIANT_HYENA,
  CREATURE_GIANT_RAT,
  CREATURE_GIANT_WEASEL,
  CREATURE_GOAT,
  CREATURE_JACKAL,
  CREATURE_LION,
  CREATURE_MASTIFF,
  CREATURE_OWL,
  CREATURE_PANTHER,
  CREATURE_RAT,
  CREATURE_TIGER,
  CREATURE_WEASEL,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_ITEM_BAG_OF_TRICKS_GRAY,
  MAGIC_ITEM_BAG_OF_TRICKS_RUST,
  MAGIC_ITEM_BAG_OF_TRICKS_TAN,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_BAG_OF_TRICKS_TAN,
    name: `Сумка фокусов, коричневая`,
    nameReverse: `Коричневая сумка фокусов`,
    nameEn: `Bag of Tricks, Tan`,
    colorText: `коричневой`,
    creatureIdList: [
      CREATURE_JACKAL,
      CREATURE_APE,
      CREATURE_BABOON,
      CREATURE_AXE_BEAK,
      CREATURE_BLACK_BEAR,
      CREATURE_GIANT_WEASEL,
      CREATURE_GIANT_HYENA,
      CREATURE_TIGER,
    ],
  },
  {
    id: MAGIC_ITEM_BAG_OF_TRICKS_GRAY,
    name: `Сумка фокусов, серая`,
    nameReverse: `Серая сумка фокусов`,
    nameEn: `Bag of Tricks, Gray`,
    colorText: `серой`,
    creatureIdList: [
      CREATURE_BADGER,
      CREATURE_BOAR,
      CREATURE_DIRE_WOLF,
      CREATURE_GIANT_BADGER,
      CREATURE_GIANT_ELK,
      CREATURE_GIANT_RAT,
      CREATURE_PANTHER,
      CREATURE_WEASEL,
    ],
  },
  {
    id: MAGIC_ITEM_BAG_OF_TRICKS_RUST,
    name: `Сумка фокусов, рыжая`,
    nameReverse: `Рыжая сумка фокусов`,
    nameEn: `Bag of Tricks, Rust`,
    colorText: `рыжей`,
    creatureIdList: [
      CREATURE_BROWN_BEAR,
      CREATURE_GIANT_BOAR,
      CREATURE_GIANT_GOAT,
      CREATURE_GOAT,
      CREATURE_LION,
      CREATURE_MASTIFF,
      CREATURE_OWL,
      CREATURE_RAT,
    ],
  },
]
