const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_CHARGE,
  ABILITY_RELENTLESS,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_BOAR} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Кабан',
  nameEn: 'Boar',
  id: CREATURE_BOAR,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 330,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 12,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 5,
  },
  cr: CR_1_4,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Клыком',
      damageText: '3 (1к6) рубящего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 14, иначе будет сбита с ног.',
    },
    {
      id: ABILITY_RELENTLESS,
      damage: 7,
    },
  ],
  actionList: [
    {
      name: 'Клык',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
