const {PC_SUBCLASS_FIGHTER_ELDRITCH_KNIGHT} = require('./../../../pcSubClassIdList')
const {
  SOURCE_PHB,
  SOURCE_ERRATA_PHB,
} = require('./../../../sourceList')
const {thirdCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_CLASS_WIZARD} = require('./../../../pcClassIdList')
const {SPELL_SHIELD} = require('./../../../spellIdList')

const {
  FEATURE_ACTION_SURGE,
  FEATURE_ARCANE_CHARGE,
  FEATURE_ELDRITCH_STRIKE,
  FEATURE_IMPROVED_WAR_MAGIC,
  FEATURE_SPELLCASTING_ELDRITCH_KNIGHT,
  FEATURE_WAR_MAGIC,
  FEATURE_WEAPON_BOND,
  FEATURE_SPELLCASTING_ABILITY_ELDRITCH_KNIGHT,
  FEATURE_CANTRIPS_ELDRITCH_KNIGHT,
  FEATURE_SPELL_SLOTS_ELDRITCH_KNIGHT,
  FEATURE_SPELL_KNOWN_ELDRITCH_KNIGHT,
} = require('./../../../featureIdList')

const eldritchKnight_arcaneTrickster_spellIdList = require('./../../constants/eldritchKnight_arcaneTrickster_spellIdList')

module.exports = [
  {
    id: FEATURE_SPELLCASTING_ELDRITCH_KNIGHT,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 3,
    text: `К своим воинским талантам Вы добавляете возможность использовать заклинания.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 75
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_CANTRIPS_ELDRITCH_KNIGHT,
    name: `Заговоры`,
    nameEn: `Cantrips`,
    lvl: 3,
    text: `Вы изучаете 2 заговора на свой выбор из списка [волшебника](PC_CLASS:${PC_CLASS_WIZARD}).

Дополнительный заговор [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) Вы изучите на 10 уровне.

| Уровень воина | Заговоры |
|---------------|----------|
|  3–9          | 2        |
| 10–20         | 3        |
`,
    source: [
      {
        id: SOURCE_PHB,
        page: 75
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_SPELL_SLOTS_ELDRITCH_KNIGHT,
    name: `Ячейки заклинаний`,
    nameEn: `Spell Slots`,
    lvl: 3,
    text: `Таблица заклинаний мистического рыцаря содержит информацию о количестве и кругах доступных Вам ячеек заклинаний. Чтобы использовать заклинание определённого круга, Вы должны потратить ячейку того же круга или выше. Все потраченные ячейки заклинаний Вы восстанавливаете после окончания длинного отдыха.

Например, если Вы знаете заклинание 1 круга [Щит](SPELL:${SPELL_SHIELD}), и имеете ячейки 1 и 2 кругов, Вы можете использовать это заклинание, использовав любую из этих ячеек.

## Количество ячеек заклинаний

| Уровень воина | 1-й круг | 2-й круг | 3-й круг | 4-й круг | 
|---------------|----------|----------|----------|----------|
|  3            | 2        |          |          |          | 
|  4–6          | 3        |          |          |          | 
|  7–9          | 4        | 2        |          |          | 
| 10–12         | 4        | 3        |          |          | 
| 13–15         | 4        | 3        | 2        |          | 
| 16–18         | 4        | 3        | 3        |          | 
| 19–20         | 4        | 3        | 3        | 1        |
`,
    source: [
      {
        id: SOURCE_PHB,
        page: 75
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_SPELL_KNOWN_ELDRITCH_KNIGHT,
    name: `Известные заклинания`,
    nameEn: `Spell Known`,
    lvl: 3,
    text: `Вы знаете три заклинания 1 круга из списка [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) на свой выбор, два из которых должны принадлежать к школам Воплощения и Ограждения.

Колонка «Заклинания» в таблице показывает, когда Вы сможете выучить новые заклинания [волшебника](PC_CLASS:${PC_CLASS_WIZARD}). Все эти заклинания должны принадлежать школам Воплощения или Ограждения, и не должны превышать круга доступных Вам ячеек заклинаний. Например, при достижении 7 уровня в этом классе Вы можете выучить новое заклинание 1 или 2 круга.

Заклинания, которые Вы выучите на 8, 14 и 20 уровнях могут принадлежать к любой школе магии.

Каждый раз, получая уровень в этом классе, Вы можете заменить одно из заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), известное Вам, другим, на Ваш выбор. Новое заклинание должно быть из списка доступных волшебнику, у Вас должны быть ячейки соответствующего круга, и заклинание должно принадлежать школам Воплощения или Ограждения, кроме случаев, когда Вы заменяете заклинания любой школы магии, полученные на 3, 8, 14 или 20 уровнях.

## Количество известных заклинаний

| Уровень воина | Заклинания | Уровень воина | Заклинания |
|---------------|------------|---------------|------------|
|  3            |  3         | 13            |  9         | 
|  4–6          |  4         | 14–15         | 10         | 
|  7            |  5         | 16–18         | 11         | 
|  8–9          |  6         | 19            | 12         | 
| 10            |  7         | 20            | 13         | 
| 11–12         |  8         |               |            |
`,
    spellCircleLvl: thirdCasterSpellCircleList,
    spellIdList: eldritchKnight_arcaneTrickster_spellIdList,
    source: [
      {
        id: SOURCE_PHB,
        page: 75
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_ELDRITCH_KNIGHT,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 3,
    text: `Интеллект является базовой характеристикой для Ваших заклинаний, поскольку Вы узнаёте заклинания посредством изучения и запоминания.

Когда заклинание требует использовать заклинательную характеристику, Вы используете Интеллект. Кроме того, Вы используете модификатор Интеллекта при определении СЛ испытаний от Ваших заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), и при броске атаки заклинаниями.

**СЛ испытания = 8 + бонус мастерства + модификатор Интеллекта**

**Модификатор броска атаки = бонус мастерства + модификатор Интеллекта**`,
    spellCircleLvl: thirdCasterSpellCircleList,
    spellIdList: eldritchKnight_arcaneTrickster_spellIdList,
    source: [
      {
        id: SOURCE_PHB,
        page: 75
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_WEAPON_BOND,
    name: `Связь с оружием`,
    nameEn: `Weapon Bond`,
    lvl: 3,
    text: `Вы узнаёте ритуал, позволяющий создать магическую связь между Вами и одним оружием.

Вы выполняете ритуал в течение 1 часа, и он может быть совершён в течение короткого отдыха. Оружие во время проведения ритуала должно находиться на доступном расстоянии от Вас, и в конце Вы должны прикоснуться к нему и создать связь.

Как только Вы привязали к себе оружие, Вас нельзя обезоружить, пока Вы дееспособны. Если оружие находится на одном плане существования с Вами, Вы можете в свой ход бонусным действием призвать его, телепортируя себе в руку.

У Вас может быть не более двух привязанных оружий одновременно, и бонусным действием Вы призываете их по одному. Если Вы попытаетесь создать связь с третьим оружием, Вам придётся разорвать связь с одним из первых двух. `,
    source: {
      id: SOURCE_PHB,
      page: 76,
    },
  },
  {
    id: FEATURE_WAR_MAGIC,
    name: `Боевая магия`,
    nameEn: `War Magic`,
    lvl: 7,
    text: `Если Вы действием используете заговор, Вы можете бонусным действием совершить одну атаку оружием. `,
    source: {
      id: SOURCE_PHB,
      page: 76,
    },
  },
  {
    id: FEATURE_ELDRITCH_STRIKE,
    name: `Мистический удар`,
    nameEn: `Eldritch Strike`,
    lvl: 10,
    text: `Вы узнаёте, как с помощью удара оружием снизить сопротивляемость цели Вашим заклинаниям.

Если Вы попадаете по существу атакой оружием, это существо получает помеху к следующему испытанию от Вашего заклинания, использованному до конца Вашего следующего хода.`,
    source: {
      id: SOURCE_PHB,
      page: 76,
    },
  },
  {
    id: FEATURE_ARCANE_CHARGE,
    name: `Волшебный рывок`,
    nameEn: `Arcane Charge`,
    lvl: 15,
    text: `Вы получаете возможность телепортироваться в видимое Вами в пределах 30 футов свободное пространство, когда используете [Всплеск действий](FEATURE:${FEATURE_ACTION_SURGE}).

Вы можете телепортироваться до или после дополнительного действия.`,
    source: {
      id: SOURCE_PHB,
      page: 76,
    },
  },
  {
    id: FEATURE_IMPROVED_WAR_MAGIC,
    name: `Улучшенная боевая магия`,
    nameEn: `Improved War Magic`,
    lvl: 18,
    text: `Если Вы действием используете заклинание, Вы можете бонусным действием совершить одну атаку оружием.`,
    source: {
      id: SOURCE_PHB,
      page: 76,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_ELDRITCH_KNIGHT,
  })
)
