const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_FEY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_ELVEN,
    LANG_SYLVAN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_BARKSKIN,
    SPELL_DRUIDCRAFT,
    SPELL_ENTANGLE,
    SPELL_GOODBERRY,
    SPELL_PASS_WITHOUT_TRACE,
    SPELL_SHILLELAGH,
  } = require('./../../../../spellIdList'),
  {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    ABILITY_MAGIC_RESISTANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_DRUID,
    CREATURE_DRYAD,
    CREATURE_SATYR,
    CREATURE_TREANT,
    CREATURE_UNICORN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дриада',
  nameEn: 'Dryad',
  id: CREATURE_DRYAD,
  description: [
    {
      header: 'Дриады',
      text : `Путешественники, войдя в лес, могут увидеть мельком женственную фигуру, порхающую между деревьями. Тёплый смех, дребезжащий в воздухе, уводит тех, кто слышит его, вглубь, в изумрудные тени.`,
      source: {
        id: SOURCE_MM,
        page: 138,
      },
    },
    {
      header: 'Родство с деревьями',
      text : `Могущественные феи иногда привязывают более слабых фейских духов к деревьям, превращая их в дриад. Иногда это делается в наказание, когда фейский дух влюбляется в смертного, а такая любовь запретна.

Дриада может выйти из дерева и путешествовать по окрестным землям, но дерево остаётся её домом и привязывает её к миру. Пока дерево остаётся здоровым и невредимым, дриада остаётся вечно молодой и очаровательной. Она страдает, если дереву причинён вред. Если дерево будет уничтожено дриада погружается в безумие.`,
      source: {
        id: SOURCE_MM,
        page: 138,
      },
    },
    {
      header: 'Уединённые феи',
      text : `Дриады выступают опекуньями своих лесных владений. Застенчивые и отчуждённые, они наблюдают за нарушителями из деревьев. Дриады, поражённые красотой незнакомца, могут пожелать узнать его более тщательно, возможно даже постараются заманить странника подальше, чтобы очаровать.

Дриады работают сообща с другими лесными существами, чтобы защищать свои леса. [Единороги](CREATURE:${CREATURE_UNICORN}), [тренты](CREATURE:${CREATURE_TREANT}) и [сатиры](CREATURE:${CREATURE_SATYR}) живут неподалёку от них, так же как и [друиды](CREATURE:${CREATURE_DRUID}), разделяющим преданность дриад к лесу, который называют своим домом.`,
      source: {
        id: SOURCE_MM,
        page: 138,
      },
    },
    {
      header: 'Магия лесной природы',
      text : `Дриады могут говорить с растениями и животными. Они могут телепортироваться из одного дерева в другое, заманивая чужаков в рощи. Попав в опасную ситуацию, дриада может увлечь гуманоидов своими чарами, превращая врагов в друзей. Они также знают несколько полезных заклинаний.`,
      source: {
        id: SOURCE_MM,
        page: 138,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 138,
  },
  armor: [
    11,
    {
      ac: 16,
      comment: `с [Дубовой корой](SPELL:${SPELL_BARKSKIN})`,
    },
  ],
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 11,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 18,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_SYLVAN,
    LANG_ELVEN,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Общение со зверьми и растениями',
      description: `★СУЩЕСТВО★ может общаться со зверьми и растениями, как если бы у них был общий язык.`,
    },
    {
      name: 'Путешествие через деревья',
      description: `Один раз в свой ход ★СУЩЕСТВО★ может использовать 10 футов перемещения на то, чтобы магическим образом войти в живое дерево в пределах досягаемости и выйти из второго живого дерева в пределах 60 футов от первого, появляясь в свободном пространстве в пределах 5 футов от второго дерева. Оба дерева должны быть как минимум Большого размера.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DRUIDCRAFT,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_ENTANGLE,
          SPELL_GOODBERRY,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_PASS_WITHOUT_TRACE,
          SPELL_SHILLELAGH,
          SPELL_BARKSKIN,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Дубина',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
        },
      },
    },
    {
      name: `Дубина (с [Дубинкой](SPELL:${SPELL_SHILLELAGH}))`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Фейское очарование',
      description: `★СУЩЕСТВО★ нацеливается на одного гуманоида или зверя, которого видит в пределах 30 футов от себя. Если цель видит ★СУЩЕСТВО★, она должна пройти испытание Мудрости СЛ 14, иначе станет магическим образом очарованной. Очарованное существо считает ★СУЩЕСТВО★ верным другом, о котором нужно заботиться и которого нужно защищать. Несмотря на то, что цель не находится под контролем ★СУЩЕСТВО★, она выполняет ★его★ просьбы.

Каждый раз, когда ★СУЩЕСТВО★ или ★его★ союзники причиняют цели вред, та может повторить испытание, оканчивая эффект на себе при успехе. В противном случае эффект длится 24 часа, или пока ★СУЩЕСТВО★ не умрёт. Чтобы эффект не прерывался, ★СУЩЕСТВО★ ★должен★ находиться на одном плане с целью, и ★СУЩЕСТВО★ может ★сам★ окончить эффект бонусным действием. Если испытание цели было пройдено, цель получает иммунитет к _Фейскому очарованию_ ★этого★ ★СУЩЕСТВО★ на следующие 24 часа. ★СУЩЕСТВО★ одновременно может держать очарованными не более одного гуманоида и не более трёх зверей.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
