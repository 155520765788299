const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_BLOOD_FRENZY,
  ABILITY_LIMITED_AMPHIBIOUSNESS,
  ABILITY_SHARK_TELEPATHY,
} = require('./../../../../creatureAbilityList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  sahuaginDescriptionList,
  sahuaginNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_SAHUAGIN,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_SAHUAGIN} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_SAHUAGIN} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Сахуагин',
  nameAlt: 'Сахваджин',
  nameEn: 'Sahuagin',
  id: CREATURE_SAHUAGIN,
  description: sahuaginDescriptionList,
  note: sahuaginNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_SAHUAGIN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 257,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 12,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_SAHUAGIN,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_BLOOD_FRENZY,
    {
      id: ABILITY_LIMITED_AMPHIBIOUSNESS,
      time: `4 часа`,
    },
    ABILITY_SHARK_TELEPATHY,
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки: одну _Укусом_, и одну либо _Когтями_, либо _Копьём_.`,
    },
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      name: `Когти`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      name: `Копьё`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 6,
              diceBonus: 1,
            },
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 8,
              diceBonus: 1,
              use2Hand: true,
            },
          ],
        ],
      },
    },
    {
      name: `Копьё`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 3,
        range: {
          normal: 20,
          max: 60,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
