const {
  MGC_RARITY_COMMON,
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_SPELL_SCROLL_0,
  MAGIC_ITEM_SPELL_SCROLL_1,
  MAGIC_ITEM_SPELL_SCROLL_2,
  MAGIC_ITEM_SPELL_SCROLL_3,
  MAGIC_ITEM_SPELL_SCROLL_4,
  MAGIC_ITEM_SPELL_SCROLL_5,
  MAGIC_ITEM_SPELL_SCROLL_6,
  MAGIC_ITEM_SPELL_SCROLL_7,
  MAGIC_ITEM_SPELL_SCROLL_8,
  MAGIC_ITEM_SPELL_SCROLL_9,
} = require('./../../../../../magicItemIdList')

const list = [
  {
    attackBonus: 5,
    id: MAGIC_ITEM_SPELL_SCROLL_0,
    lvl: 0,
    rarity: MGC_RARITY_COMMON,
  },
  {
    attackBonus: 5,
    id: MAGIC_ITEM_SPELL_SCROLL_1,
    lvl: 1,
    rarity: MGC_RARITY_COMMON,
  },
  {
    attackBonus: 5,
    id: MAGIC_ITEM_SPELL_SCROLL_2,
    lvl: 2,
    rarity: MGC_RARITY_UNCOMMON,
  },
  {
    attackBonus: 7,
    id: MAGIC_ITEM_SPELL_SCROLL_3,
    lvl: 3,
    rarity: MGC_RARITY_UNCOMMON,
  },
  {
    attackBonus: 7,
    id: MAGIC_ITEM_SPELL_SCROLL_4,
    lvl: 4,
    rarity: MGC_RARITY_RARE,
  },
  {
    attackBonus: 9,
    id: MAGIC_ITEM_SPELL_SCROLL_5,
    lvl: 5,
    rarity: MGC_RARITY_RARE,
  },
  {
    attackBonus: 9,
    id: MAGIC_ITEM_SPELL_SCROLL_6,
    lvl: 6,
    rarity: MGC_RARITY_VERY_RARE,
  },
  {
    attackBonus: 10,
    id: MAGIC_ITEM_SPELL_SCROLL_7,
    lvl: 7,
    rarity: MGC_RARITY_VERY_RARE,
  },
  {
    attackBonus: 10,
    id: MAGIC_ITEM_SPELL_SCROLL_8,
    lvl: 8,
    rarity: MGC_RARITY_VERY_RARE,
  },
  {
    attackBonus: 11,
    id: MAGIC_ITEM_SPELL_SCROLL_9,
    lvl: 9,
    rarity: MGC_RARITY_LEGENDARY,
  },
]

module.exports = list
