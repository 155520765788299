const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_GNOLL,
  CREATURE_MAW_DEMON,
  CREATURE_YEENOGHU,
} = require('./../../../../creatureIdList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_RAMPAGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {LANG_ABYSSAL} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Утробный демон',
  nameEn: 'Maw Demon',
  id: CREATURE_MAW_DEMON,
  description: [
    `Утробные демоны разделяют нескончаемую жажду [Йеногу](CREATURE:${CREATURE_YEENOGHU}) к убийству и плоти смертных. После того, как утробный демон отдохнет восемь часов, всё, что он сожрал, переносится прямиком в желудок [Лорда Дикости](CREATURE:${CREATURE_YEENOGHU}).

Утробные демоны встречаются среди боевых банд [гноллов](CREATURE:${CREATURE_GNOLL}), обычно их вызывают как часть ритуальных подношений свежеубитых гуманоидов [Йеногу](CREATURE:${CREATURE_YEENOGHU}). [Гноллы](CREATURE:${CREATURE_GNOLL}) ими не командуют, но утробные демоны следуют за стаей и атакуют всех, на кого нападают [гноллы](CREATURE:${CREATURE_GNOLL}).`,
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 149,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 13,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 5,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ABYSSAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_1,
  featureList: [
    ABILITY_RAMPAGE,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
  ],
}
