const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_ALZHEDO} = require('./../../languageIdList')
const {PLACE_CALIMSHAN} = require('./../../placeIdList')

module.exports = {
  id: LANG_ALZHEDO,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Alzhedo',
  name: {
    nominative: 'Алжедо',
    genitive: 'Алжедо',
    instrumental: 'Алжедо',
    prepositional: 'Алжедо',
  },
  typicalSpeakers: 'Калишиты',
  spokenPlaceList: PLACE_CALIMSHAN,
  isReady: false,
  isRealLang: true,
}
