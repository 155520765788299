const {
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_CLOUD,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FIRE,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FROST,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_HILL,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STONE,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STORM,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_HILL,
    name: `Пояс силы Холмового великана`,
    nameEn: `Belt of Hill Giant Strength`,
    rarity: MGC_RARITY_RARE,
    str: 21,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STONE,
    name: `Пояс силы Каменного великана`,
    nameEn: `Belt of Stone Giant Strength`,
    rarity: MGC_RARITY_VERY_RARE,
    str: 23,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FROST,
    name: `Пояс силы Ледяного великана`,
    nameEn: `Belt of Frost Giant Strength`,
    rarity: MGC_RARITY_VERY_RARE,
    str: 23,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FIRE,
    name: `Пояс силы Огненного великана`,
    nameEn: `Belt of Fire Giant Strength`,
    rarity: MGC_RARITY_VERY_RARE,
    str: 25,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_CLOUD,
    name: `Пояс силы Облачного великана`,
    nameEn: `Belt of Cloud Giant Strength`,
    rarity: MGC_RARITY_LEGENDARY,
    str: 27,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STORM,
    name: `Пояс силы Штормового великана`,
    nameEn: `Belt of Storm Giant Strength`,
    rarity: MGC_RARITY_LEGENDARY,
    str: 29,
  },
]
