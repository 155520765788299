const {GOD_ASMODEUS} = require('./../../godIdList')
const {GEAR_HOLY_WATER} = require('./../../gearIdList')
const {FEATURE_DIVINE_INTERVENTION} = require('./../../featureIdList')
const {PC_CLASS_CLERIC} = require('./../../pcClassIdList')
const {
  CREATURE_AMNIZU,
  CREATURE_BARBED_DEVIL,
  CREATURE_BEARDED_DEVIL,
  CREATURE_BEL,
  CREATURE_BONE_DEVIL,
  CREATURE_CHAIN_DEVIL,
  CREATURE_ERINYES,
  CREATURE_GERYON,
  CREATURE_HORNED_DEVIL,
  CREATURE_ICE_DEVIL,
  CREATURE_IMP,
  CREATURE_LEMURE,
  CREATURE_MERREGON,
  CREATURE_MOLOCH,
  CREATURE_NARZUGON,
  CREATURE_NUPPERIBO,
  CREATURE_ORTHON,
  CREATURE_PIT_FIEND,
  CREATURE_SPINED_DEVIL,
  CREATURE_TIAMAT,
  CREATURE_ZARIEL,
} = require('./../../creatureIdList')
const {
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')

module.exports = [
  {
    header: 'Дьяволы',
    text: `Дьяволы — это тирания во плоти, с тоталитарным строем, посвящённым господству над смертными. Тень Девяти Преисподних Баатора простирается далеко в мультивселенной, а Асмодей, тёмный лорд Нессуса, хочет подчинить себе всё сущее, чтобы удовлетворить свою жажду власти. Для этого ему нужно постоянно множить инфернальные силы, отправляя слуг развращать души смертных, из которых потом порождаются дьяволы.`,
    source: {
      id: SOURCE_MM,
      page: 140,
    },
  },
  {
    header: 'Лорды тирании',
    text: `Дьяволы живут, чтобы покорять, порабощать и угнетать. Они испытывают порочное удовольствие, властвуя слабыми, а любое существо, подвергшее авторитет дьявола сомнению, ждёт быстрое и жестокое наказание. Каждое действие для них — возможность показать силу, и все дьяволы имеют врождённое знание, как эту силу применять.

Дьяволы знают слабости смертных, с их помощью они погружают их во тьму и искушение, превращая в рабов собственной испорченности. На Материальном плане дьяволы манипулируют правителями, нашёптывая мерзости, подогревая паранойю, и толкая к тирании.`,
    source: {
      id: SOURCE_MM,
      page: 140,
    },
  },
  {
    header: 'Послушание и амбиции',
    text: `В соответствии с их мировоззрением, дьяволы подчиняются, несмотря на зависть или ненависть к начальству, понимая, что послушание вознаградят. Иерархия Девяти Преисподних строится на непоколебимой верности, без которой план погрузился бы в анархию подобную Бездне.

В то же время, в природе дьяволов желание править затмевает желание подчиняться. Такая амбициозность ярче всего проявляется у архидьяволов, которых Асмодей поставил править слоями Адов. Они — единственные, кто попробовал на вкус истинную власть, которая для них как сладкая амброзия.`,
    source: {
      id: SOURCE_MM,
      page: 140,
    },
  },
  {
    header: 'Тёмные дельцы и торговцы душами',
    text: `Дьяволы существуют на Нижних Планах, но могут путешествовать через порталы или с помощью мощной магии призыва. Они любят заключать сделки со смертными, ищущими выгоды, но тут стоит быть осторожным. Дьяволы — лукавые дипломаты, и совершенно безжалостно приводят договор в исполнение. К тому же, договор даже с низшим дьяволом происходит по воле Асмодея. Смертный, разрывающий договор, сразу потеряет душу, которую утащат в Девять Преисподних.

Владение душой даёт полную власть над существом, и большинство дьяволов за свои услуги не берёт никакую другую плату, кроме душ. Право на владение душой теряется, если смертный умер своей смертью, дьяволы же бессмертны, и могут годами ожидать выполнение контракта. Если же контракт подразумевает владение душой смертного до его гибели, дьявол может сразу же вернуться обратно в Девять Преисподних с душой в распоряжении. Только [Божественное вмешательство](PC_CLASS:${PC_CLASS_CLERIC}#${FEATURE_DIVINE_INTERVENTION}) может освободить душу после заключения контракта.`,
    source: {
      id: SOURCE_MM,
      page: 140,
    },
  },
  {
    header: 'Девять Преисподних',
    text: `Девять Преисподних — единый план, состоящий из девяти отдельных слоёв. Первыми восемью слоями правят архидьяволы, подчиняющийся самому великому архидьяволу из всех: [Асмодею](GOD:${GOD_ASMODEUS}), Эрцгерцогу Нессуса, девятого слоя. Чтобы достичь самого глубокого из девяти слоёв, придётся пройти по порядку каждый из восьми расположенных над ним слоёв. Самый быстрый способ сделать это — проплыть по реке Стикс, которая уходит всё глубже, перетекая с одного слоя на другой. Только самые отважные искатели приключений смогут выдержать мучение и ужасы такого путешествия.`,
    source: {
      id: SOURCE_MM,
      page: 141,
    },
  },
  {
    header: 'Дьявольская иерархия',
    text: `Лорды Девяти устанавливают общие направления развития Преисподних, но именно рядовые дьяволы в своём бессчётном количестве воплощают их планы. Обитатели Материального плана взаимодействуют главным образом с дьяволами из нижних уровней иерархии.`,
    source: {
      id: SOURCE_MTOF,
      page: 19,
    },
  },
  {
    header: 'Инфернальная иерархия',
    text: `Девять кругов Ада имеет жёсткую иерархию, которая определяет все социальные аспекты. [Асмодей](GOD:${GOD_ASMODEUS}) является верховным правителем всех дьяволов, и он единственный обладает силой младших богов. На Материальном плане ему так и поклоняются. Асмодей вдохновляет злые дьявольские культы, взявшие его имя. В Девяти Преисподних он командует множеством [исчадий преисподних](CREATURE:${CREATURE_PIT_FIEND}), которые в свою очередь командуют легионами подчинённых.

Жесточайшей тиранией, блестящими уловками и мастерской хитростью, [Асмодей](GOD:${GOD_ASMODEUS}) защищает своё право на трон, держа друзей близко, а врагов ещё ближе. Он раздаёт большинство задач [исчадиям преисподних](CREATURE:${CREATURE_PIT_FIEND}) и младшим архидьяволам, которые составляют бюрократический аппарат Девяти Преисподних, несмотря на то, что знает, что все эти дьяволы хотят захватить Трон Баатора, с которого он правит. [Асмодей](GOD:${GOD_ASMODEUS}) назначает архидьяволов и, если захочет, может лишить любого члена адской иерархии его статуса и ранга.

Если дьявол умирает за пределами Девяти Преисподних, он исчезает в облаке серного дыма или растворяется ихором, мгновенно возвращаясь на родной план, где полностью восстанавливается. Дьяволы, погибшие в Девяти Преисподних, исчезают навеки, и этого страшится даже Асмодей.

# Архидьяволы

К архидьяволам относят всех текущих и бывших правителей Девяти Преисподних, а также герцогов и герцогинь, состоящих при их дворах, служащих им советниками, и мечтающих сместить их. Каждый архидьявол — уникальное существо, чья внешность отражает злую природу.

# Старшие дьяволы

Старшие дьяволы включают [исчадий преисподних](CREATURE:${CREATURE_PIT_FIEND}), [эриний](CREATURE:${CREATURE_ERINYES}), [рогатых дьяволов](CREATURE:${CREATURE_HORNED_DEVIL}) и [ледяных дьяволов](CREATURE:${CREATURE_ICE_DEVIL}), которые руководят младшими дьяволами и служат архидьяволам.

# Младшие дьяволы

Это множество видов исчадий, включая [бесов](CREATURE:${CREATURE_IMP}), [дьяволов цепей](CREATURE:${CREATURE_CHAIN_DEVIL}), [шипастых](CREATURE:${CREATURE_BARBED_DEVIL}) и [костяных дьяволов](CREATURE:${CREATURE_BONE_DEVIL}).

# [Лемуры](CREATURE:${CREATURE_LEMURE})

Низшая форма дьяволов — изувеченные и измученные души злых и развращённых смертных. Лемур, убитый в Девяти Преисподних, окончательно уничтожается только благословлённым оружием, или если на его бесформенный труп плеснуть [святой водой](GEAR:${GEAR_HOLY_WATER}).

# Повышение и понижение

Когда душа злого смертного погружается в Девять Преисподних, она превращается в убогого лемура. Архидьяволы и старшие дьяволы имеют право повысить лемуров до младших дьяволов. Архидьяволы могут повышать младших дьяволов до старших, и только [Асмодей](GOD:${GOD_ASMODEUS}) может повысить старшего дьявола до статуса архидьявола. Такие дьявольские изменения сопровождаются кратковременной болезненной трансформацией, но воспоминания дьявола без изменений переходят от одной формы к другой.

Низкоуровневые повышения, как правило, основаны на потребности, например, когда исчадию преисподней нужны невидимые шпионы, он превращает [лемуров](CREATURE:${CREATURE_LEMURE}) в [бесов](CREATURE:${CREATURE_IMP}).

Высокоуровневые повышения всегда происходят за заслуги, например, [костяной дьявол](CREATURE:${CREATURE_BONE_DEVIL}), который служит архидьяволу и отличается в бою, превращается в [рогатого дьявола](CREATURE:${CREATURE_HORNED_DEVIL}). Дьяволы редко повышаются в иерархии более чем на одну ступень за раз.

## Иерархия ада

1. [лемур](CREATURE:${CREATURE_LEMURE})

### Младшие дьяволы

2. [бес](CREATURE:${CREATURE_IMP})
3. [игольчатый дьявол](CREATURE:${CREATURE_SPINED_DEVIL})
4. [бородатый дьявол](CREATURE:${CREATURE_BEARDED_DEVIL})
5. [шипастый дьявол](CREATURE:${CREATURE_BARBED_DEVIL})
6. [дьявол цепей](CREATURE:${CREATURE_CHAIN_DEVIL})
7. [костяной дьявол](CREATURE:${CREATURE_BONE_DEVIL})

### Старшие дьяволы

8. [рогатый дьявол](CREATURE:${CREATURE_HORNED_DEVIL})
9. [эриния](CREATURE:${CREATURE_ERINYES})
10. [ледяной дьявол](CREATURE:${CREATURE_ICE_DEVIL})
11. [исчадие преисподней](CREATURE:${CREATURE_PIT_FIEND})

### Архидьяволы

12. герцог или герцогиня
13. эрцгерцог или эрцгерцогиня

Разжалование среди дьяволов является обычным наказанием за провал или неповиновение. Архидьяволы могут понизить младшего дьявола до [лемура](CREATURE:${CREATURE_LEMURE}), при этом он теряет память своей предыдущей формы. Любой архидьявол может понизить старшего дьявола до младшего дьявола, но при этом пониженный дьявол сохраняет свои воспоминания и может отомстить, если считает понижение несправедливым.

Никакой дьявол не может повысить или понизить другого дьявола, не давшего ему клятву верности, что не позволяет архидьяволам понижать самых мощных слуг друг друга. Так как все дьяволы клянутся в верности [Асмодею](GOD:${GOD_ASMODEUS}), он может легко понизить любого другого дьявола, придавая ему любую адскую форму, какую пожелает.`,
    source: {
      id: SOURCE_MM,
      page: 140,
    },
  },
  {
    header: 'Всем существам своё место',
    text: `Статус очень важен для дьяволов. Каждый дьявол знает своё место в иерархии, и у каждого дьявола есть своё уникальное имя, чтобы не возникало случаев ошибок с определением личности, когда надо привлечь дьявола к ответственности за его действия.

Форма дьявола обычно соответствует его статусу, но определённые обстоятельства допускают отклонения от этого правила. [Исчадие преисподней](CREATURE:${CREATURE_PIT_FIEND}), например, может принять форму [беса](CREATURE:${CREATURE_IMP}), чтобы лично проникнуть в королевство на Материальном плане.

В иерархии Девяти Преисподних имеется одиннадцать уровней рангов. Дьявол более высокого ранга потенциально может принудить нижестоящего дьявола подчиняться приказам, но он всё равно должен подчиняться законам, даже при использовании своей власти. В большинстве случаев дьявол может требовать повиновения другого дьявола, только если они находятся в иерархии одного и того же архидьявола. Например, дьявол на службе Диспатера не может отдавать команды нижестоящему дьяволу из армии Левистуса.

# Нижайшие

1. На самом дне иерархии находятся [лемуры](CREATURE:${CREATURE_LEMURE}) и [нупперибо](CREATURE:${CREATURE_NUPPERIBO}). Это существа, которых можно назвать дьяволами только с определённой натяжкой. Бесполезные поодиночке, они представляют эффективную боевую силу, когда собраны в огромную орду, которая, подобно потопу, заполняет поле боя.

# Младшие дьяволы

Следующие шесть более высоких уровней заняты младшими дьяволами:

2. [бесами](CREATURE:${CREATURE_IMP}),
3. [игольчатыми дьяволами](CREATURE:${CREATURE_SPINED_DEVIL}),
4. [бородатыми дьяволами](CREATURE:${CREATURE_BEARDED_DEVIL}) и [меррегонами](CREATURE:${CREATURE_MERREGON}),
5. [шипастыми дьяволами](CREATURE:${CREATURE_BARBED_DEVIL}),
6. [дьяволами цепей](CREATURE:${CREATURE_CHAIN_DEVIL}),
7. [костяными дьяволами](CREATURE:${CREATURE_BONE_DEVIL}).

Эти дьяволы – специалисты, которые обычно выполняют задачи, наилучшим образом соответствующие их возможностям. [Бесов](CREATURE:${CREATURE_IMP}) чаще используют в качестве гонцов и шпионов, нежели в качестве солдат. Из всех инфернальных агентов именно [бесов](CREATURE:${CREATURE_IMP}) проще всего встретить на Материальном плане. Малые дьяволы редко командуют другими дьяволами, если не считать особые краткосрочные задания, на время которых им делегируются полномочия.

# Старшие дьяволы

Старшие дьяволы занимают четыре ранга над младшими дьяволами:

8. [рогатые дьяволы](CREATURE:${CREATURE_HORNED_DEVIL}) и [ортоны](CREATURE:${CREATURE_ORTHON}),
9. [эринии](CREATURE:${CREATURE_ERINYES}),
10. [ледяные дьяволы](CREATURE:${CREATURE_ICE_DEVIL}) и [нарзугоны](CREATURE:${CREATURE_NARZUGON}),
11. [амнизу](CREATURE:${CREATURE_AMNIZU}) и [исчадия преисподней](CREATURE:${CREATURE_PIT_FIEND}).

Младшие дьяволы ежедневно сталкиваются с этими лидерами. [Ледяные дьяволы](CREATURE:${CREATURE_ICE_DEVIL}) – это боевые командиры, эквивалентные капитанам и полковникам, в то время как [исчадия преисподней](CREATURE:${CREATURE_PIT_FIEND}) – это генералы и знать.

# Архидьяволы

Наимогущественнейшие из всех – уникальные и исключительно сильные архидьяволы. 

12. Те из них, кто в настоящее время не служат лордами слоя имеют ранг Герцога или Герцогини (ранг 12).
13. На самом верху иерархии в ранге 13 находятся Лорды Девяти, выстроившиеся с верхнего слоя до нижнего и находящиеся под властью [Асмодея](GOD:${GOD_ASMODEUS}).`,
    source: {
      id: SOURCE_MTOF,
      page: 19,
    },
  },
  {
    header: 'Правила для всего',
    text: `Дьяволы по своей природе – злобные хитрецы, но им приходится действовать в рамках сложного кодекса законов Девяти Преисподних. Отношение дьявола к закону отчасти диктуется его характером, а отчасти конкретной ситуацией.

Например, действия участвующих в Войне Крови дьяволов диктуются военным уставом и приказами их офицеров. Они беспрекословно выполняют команды и участвуют в тренировках, когда не находятся непосредственно на службе, чтобы убедиться, что смогут с точностью исполнить отданные им приказы.

Некоторые другие дьяволы, особенно те, кто выше рангом или кто должен проникать на Материальный план, смотрят на закон как на головоломку, которую нужно разгадать, или как на препятствие, которое надо суметь обойти. Например, дьявол может быть обязан по закону никогда не отказывать в помощи командиру, кроме как в редких и конкретных случаях. Хитрый выскочка, который хочет уничтожить своего начальника, может манипулировать событиями, чтобы создать как раз такой случай, а затем смотреть, как гибнет командир, при этом цитируя закон, который «препятствует» оказанию помощи.`,
    source: {
      id: SOURCE_MTOF,
      page: 19,
    },
  },
  {
    header: 'Три пути к могуществу',
    text: `Дьяволы могут продвинуться в ранге тремя путями. В Девяти Преисподних есть сложная система правил, которая оценивает и признаёт деяния дьявола.

# Души

Каждый раз, когда дьявол подписывает контракт, по которому Девять Преисподних получают новую душу, этот дьявол получает отметку за такое достижение. Сильная душа, такая как могучий воин, который отставляет смертность, чтобы стать [ледяным дьяволом](CREATURE:${CREATURE_ICE_DEVIL}), стоит больше, чем простой крестьянин, который, вероятно, будет приговорён к существованию в виде [лемура](CREATURE:${CREATURE_LEMURE}).

# Доблесть

Все дьяволы обязаны участвовать в Войне Крови. Каждый дьявол низкого ранга проводит определённое время на линии фронта как часть легиона. Некоторые находят сражения захватывающими и вызываются добровольцами для дополнительных заданий. Другие же намерены отработать только минимум, требуемый для выполнения обязательств, но, участвуя в бою, сражаются не менее ожесточённо. Дьявол получает отметку за каждого убитого врага на основе силы противника, а также в зависимости от того, убил он врага сам или с чьей-то помощью.

# Предательство

Точно так же, как в законе прописаны правила повышения дьяволов в нормальных условиях, там содержатся правила заполнения внезапно освободившихся мест в чрезвычайных ситуациях. Занятие должности, на которой прежде был твой начальник, – это один из самых быстрых способов продвижения, доступных дьяволу. Дьявол, который успешно подстроит смерть своего командира, может немедленно занять освободившуюся роль, если он будет знать правила передачи должности и заблаговременно сделает себя «наследником».`,
    source: {
      id: SOURCE_MTOF,
      page: 19,
    },
  },
  {
    header: 'Истинные имена дьяволов и талисманы',
    text: `Хотя у всех дьяволов есть повседневные имена, у каждого дьявола рангом выше [лемура](CREATURE:${CREATURE_LEMURE}) также есть истинное имя, которое он держит в секрете. Дьявола можно заставить раскрыть своё истинное имя, если удастся очаровать его, и также существуют древние свитки и книги, в которых перечислены истинные имена некоторых дьяволов.

Если смертный узнает истинное имя дьявола, он сможет использовать мощную магию призыва, чтобы вызвать этого дьявола из Девяти Преисподних и заставить его служить себе. Это также можно сделать с помощью дьявольского талисмана. Каждая из этих древних реликвий несёт на себе истинное имя дьявола, которого она контролирует, и при создании она была омыта кровью достойной жертвы — обычно это кто-то, кого любил создатель талисмана.

Вне зависимости от того, как именно дьявол был призван на Материальный план, обычно он не горит желанием служить. Дьявол будет использовать каждую возможность совратить смертного призывателя, чтобы его душа в итоге попала в Девять Преисподних. Только [бесам](CREATURE:${CREATURE_IMP}) нравится, когда их призывают, и они легко соглашаются служить призывателю в качестве фамильяра, но при этом они всё равно изо всех сил будут стараться совратить того, кто их призывает.`,
    source: {
      id: SOURCE_MM,
      page: 141,
    },
  },
  {
    header: 'Дьяволы и пол',
    text: `Для дьяволов пол не важен. Дьяволы не могут создавать новую жизнь физиологическими способами; новый дьявол появляется на свет, только когда совращается или присваивается в результате сделки душа смертного, и пол смертного, предоставившего эту душу, не существенен. Общающиеся со смертными дьяволы, вероятно, примут такую внешность (включая пол), которая будет соответствовать тому, что эти смертные считают правдой. Пол (и предположения, которые, исходя из него, будут делать смертные) – это всего лишь ещё один инструмент для достижения дьяволом желаемого.

Дьяволы, которых смертные знают и которым они присваивают имена, часто принимают «назначенный» им смертными пол, но они вовсе не связаны таким ярлыком. Истории о Лордах Девяти, рассказываемые смертными, могут говорить о Гласии как дочери [Асмодея](GOD:${GOD_ASMODEUS}), а о Белиале как фаворите Фиерны, но такие термины не могут объять комплексную природу взаимоотношений, сформировавшихся между существами бессмертного зла.`,
    source: {
      id: SOURCE_MTOF,
      page: 16,
    },
  },
  {
    header: 'Слои и повелители Девяти Преисподних',
    text: `
| Слой | Название   | Эрцгерцог или эрцгерцогиня | Предыдущие правители | Основные обитатели                                           |
|------|------------|----------------------------|----------------------|--------------------------------------------------------------|
| 1    | Аверно    | [Зариэль](CREATURE:${CREATURE_ZARIEL})                    | [Бэл](CREATURE:${CREATURE_BEL}), [Тиамат](CREATURE:${CREATURE_TIAMAT})          | [Бесы](CREATURE:${CREATURE_IMP}), [игольчатые дьяволы](CREATURE:${CREATURE_SPINED_DEVIL}), [эринии](CREATURE:${CREATURE_ERINYES})                             |
| 2    | Дис        | Диспатер                   | —                    | [Бесы](CREATURE:${CREATURE_IMP}), [бородатые дьяволы](CREATURE:${CREATURE_BEARDED_DEVIL}), [игольчатые дьяволы](CREATURE:${CREATURE_SPINED_DEVIL}), [эринии](CREATURE:${CREATURE_ERINYES})          |
| 3    | Минаурос   | Маммон                     | —                    | [Бесы](CREATURE:${CREATURE_IMP}), [бородатые дьяволы](CREATURE:${CREATURE_BEARDED_DEVIL}), [дьяволы цепей](CREATURE:${CREATURE_CHAIN_DEVIL}), [игольчатые дьяволы](CREATURE:${CREATURE_SPINED_DEVIL})   |
| 4    | Флегетос   | Белиал и Фьёрна            | —                    | [Бесы](CREATURE:${CREATURE_IMP}), [игольчатые дьяволы](CREATURE:${CREATURE_SPINED_DEVIL}), [костяные дьяволы](CREATURE:${CREATURE_BONE_DEVIL}), [шипастые дьяволы](CREATURE:${CREATURE_BARBED_DEVIL}) |
| 5    | Стигия     | Левистус                   | [Герион](CREATURE:${CREATURE_GERYON})               | [Бесы](CREATURE:${CREATURE_IMP}), [костяные дьяволы](CREATURE:${CREATURE_BONE_DEVIL}), [ледяные дьяволы](CREATURE:${CREATURE_ICE_DEVIL}), [эринии](CREATURE:${CREATURE_ERINYES})              |
| 6    | Малболг    | Гласия                     | Малагард, [Молох](CREATURE:${CREATURE_MOLOCH})      | [Бесы](CREATURE:${CREATURE_IMP}), [костяные дьяволы](CREATURE:${CREATURE_BONE_DEVIL}), [рогатые дьяволы](CREATURE:${CREATURE_HORNED_DEVIL}), [шипастые дьяволы](CREATURE:${CREATURE_BARBED_DEVIL})    |
| 7    | Маладомини | Вельзевул                  | —                    | [Бесы](CREATURE:${CREATURE_IMP}), [костяные дьяволы](CREATURE:${CREATURE_BONE_DEVIL}), [рогатые дьяволы](CREATURE:${CREATURE_HORNED_DEVIL}), [шипастые дьяволы](CREATURE:${CREATURE_BARBED_DEVIL})     |
| 8    | Кания      | Мефистофель                | —                    | [Бесы](CREATURE:${CREATURE_IMP}), [исчадия преисподней](CREATURE:${CREATURE_PIT_FIEND}), [ледяные дьяволы](CREATURE:${CREATURE_ICE_DEVIL}), [рогатые дьяволы](CREATURE:${CREATURE_HORNED_DEVIL})  |
| 9    | Нессус     | [Асмодей](GOD:${GOD_ASMODEUS})                    | —                    | Все виды дьяволов                                            |
`,
    isOneColumn: true,
    source: {
      id: SOURCE_MM,
      page: 142,
    },
  },
]
