const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_DAMARAN} = require('./../../languageIdList')
const {
  ALPHABET_DETHEK,
  ALPHABET_THORASS,
} = require('./../../alphabetList')
const {
  PLACE_DAMARA,
  PLACE_GREAT_DALE,
  PLACE_IMPILTUR,
  PLACE_MOONSEA,
  PLACE_NARFELL,
  PLACE_THESK,
  PLACE_VAASA,
  PLACE_VAST,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_DAMARAN,
  alphabetId: [
    ALPHABET_DETHEK,
    ALPHABET_THORASS,
  ],
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Damaran',
  name: {
    nominative: 'Дамарский',
    genitive: 'Дамарского',
    instrumental: 'Дамарским',
    prepositional: 'Дамарском',
  },
  typicalSpeakers: 'Дамарцы, Нары',
  spokenPlaceList: [
    PLACE_DAMARA,
    PLACE_GREAT_DALE,
    PLACE_IMPILTUR,
    PLACE_MOONSEA,
    PLACE_NARFELL,
    PLACE_THESK,
    PLACE_VAASA,
    PLACE_VAST,
  ],
  isReady: false,
  isRealLang: true,
}
