const {PC_SUBCLASS_RANGER_HUNTER} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {SPELL_LIGHTNING_BOLT} = require('./../../../spellIdList')

const {
  FEATURE_DEFENSIVE_TACTICS,
  FEATURE_HUNTER_S_PREY,
  FEATURE_MULTIATTACK,
  FEATURE_SUPERIOR_HUNTER_S_DEFENSE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_HUNTER_S_PREY,
    name: `Добыча охотника`,
    nameEn: `Hunter’s Prey`,
    lvl: 3,
    text: `Вы получаете одно из следующих умений на свой выбор:

# Сокрушитель орд

Один раз в каждый свой ход, когда Вы совершаете атаку оружием, Вы можете совершить ещё одну атаку тем же оружием по другому существу, находящемуся в пределах 5 футов от первичной цели, и находящемуся в пределах досягаемости Вашего оружия.

# Убийца великанов

Если Большое или ещё большее существо в пределах 5 футов попадает или промахивается по Вам атакой, Вы можете реакцией атаковать это существо сразу после его атаки, при условии, что Вы можете видеть его.

# Убийца колоссов

Ваше упорство может повергнуть самых мощных врагов. Если Вы попадаете по существу атакой оружием, это существо получает дополнительный урон 1к8, если его хиты уже ниже максимума. Вы можете причинить этот дополнительный урон только один раз в ход.`,
  },
  {
    id: FEATURE_DEFENSIVE_TACTICS,
    name: `Оборонительная тактика`,
    nameEn: `Defensive Tactics`,
    lvl: 7,
    text: `Вы получаете одно из следующих умений на свой на выбор:

# Защита от мультиатаки

Если существо попадает по Вам атакой, Вы получаете бонус +4 к КД против всех последующих атак этого существа до конца хода.

# Побег от орды

Провоцированные атаки по Вам совершаются с помехой.

# Стальная воля

Вы совершаете с преимуществом испытания от испуга.`,
  },
  {
    id: FEATURE_MULTIATTACK,
    name: `Мультиатака`,
    nameEn: `Multiattack`,
    lvl: 11,
    text: `Вы получаете одно из следующих умений на свой выбор:

# Вихревая атака

Вы можете действием совершить рукопашные атаки по любому количеству существ в пределах 5 футов от себя, совершая отдельный бросок атаки по каждой цели.

# Залп

Вы можете действием совершить дальнобойные атаки по любому количеству видимых Вами существ, находящихся в пределах 10 футов от одной точки, и находящихся в пределах дистанции Вашего оружия. У Вас должны быть боеприпасы для каждой атаки, как обычно, и Вы должны совершить отдельный бросок атаки для каждой цели.`,
  },
  {
    id: FEATURE_SUPERIOR_HUNTER_S_DEFENSE,
    name: `Улучшенная защита охотника`,
    nameEn: `Superior Hunter’s Defense`,
    lvl: 15,
    text: `Вы получаете одно из следующих умений на свой выбор:

# Невероятное уклонение

Когда нападающий, которого Вы можете видеть, попадает по Вам атакой, Вы можете реакцией уменьшить вдвое урон, причиняемый Вам этой атакой.

# Стоять против течения

Если враждебное существо промахивается по Вам рукопашной атакой, Вы можете реакцией заставить его повторить эту атаку по другому существу (кроме него самого) на Ваш выбор.

# Увёртливость

Вы можете проворно уворачиваться от некоторых зональных эффектов, например, огненного дыхания красного дракона или заклинания [Молния](SPELL:${SPELL_LIGHTNING_BOLT}). Если Вы подвергаетесь эффекту, позволяющему пройти испытание Ловкости, чтобы получить только половину урона, Вы вместо этого не получаете урона при успешном испытании, или получаете лишь половину при провале.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_RANGER_HUNTER,
    source: {
      id: SOURCE_PHB,
      page: 93,
    },
  })
)
