const {
  MGC_RARITY_LEGENDARY,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MAGIC_ITEM_HORN_OF_VALHALLA_BRASS,
  MAGIC_ITEM_HORN_OF_VALHALLA_BRONZE,
  MAGIC_ITEM_HORN_OF_VALHALLA_IRON,
  MAGIC_ITEM_HORN_OF_VALHALLA_SILVER,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    berserkerCount: `2к4 + 2`,
    id: MAGIC_ITEM_HORN_OF_VALHALLA_SILVER,
    name: `Рог Валгаллы, Серебряный`,
    nameEn: `Horn of Valhalla, Silver`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
  },
  {
    berserkerCount: `3к4 + 3`,
    condition: `владея всеми видами простого оружия`,
    id: MAGIC_ITEM_HORN_OF_VALHALLA_BRASS,
    name: `Рог Валгаллы, Латунный`,
    nameEn: `Horn of Valhalla, Brass`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
  },
  {
    berserkerCount: `4к4 + 4`,
    condition: `владея всеми видами средних доспехов`,
    id: MAGIC_ITEM_HORN_OF_VALHALLA_BRONZE,
    name: `Рог Валгаллы, Бронзовый`,
    nameEn: `Horn of Valhalla, Bronze`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
  },
  {
    berserkerCount: `5к4 + 5`,
    condition: `владея всеми видами воинского оружия`,
    id: MAGIC_ITEM_HORN_OF_VALHALLA_IRON,
    name: `Рог Валгаллы, Железный`,
    nameEn: `Horn of Valhalla, Iron`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_LEGENDARY,
  },
]
