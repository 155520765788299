const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')

module.exports = [
  {
    header: 'Скелеты',
    text: `Скелеты восстают из мёртвых после воздействия тёмной магии. Они восстают либо после призыва заклинателя, который воззвал к тем, кто лежит в древних гробницах и на забытых полях сражений, либо возвращаются из могил по своему собственному желанию в местах, пропитанных смертью. Их пробуждает некротическая энергия или присутствие разлагающего зла.`,
    source: {
      id: SOURCE_MM,
      page: 261,
    },
  },
  {
    header: 'Скелеты',
    text: `Останки умерших, ожившие под действием тёмной магии — придя на зов заклинателя или восстав самостоятельно.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Восставшие из мёртвых',
    text: `Какая бы сила их не пробудила, кости скелетов полны магической силы, которая скрепляет их вместе и удерживает от распада на части. Эта энергия позволяет скелетам двигаться и думать, однако лишь в самой малой степени, жалкой тени по сравнению с разумом живых. Восставшие скелеты не сохраняют никакой привязанности к прошлому, но воскрешение скелета восстанавливает душу и тело, изгоняя духа, который владел телом.

Несмотря на то, что большинство восставших скелетов это останки людей и других гуманоидов, скелеты могут быть созданы из любого типа костей помимо гуманоидных, создавая страшные и уникальные формы тел.`,
    source: {
      id: SOURCE_MM,
      page: 261,
    },
  },
  {
    header: 'Послушные слуги',
    text: `Скелеты, поднятые заклинанием, подчинены воле заклинателя. Они слепо следуют приказам, никогда не сомневаясь в решениях господина, какими бы ни были последствия. Из-за того, что скелеты понимают все команды буквально и исполняют приказы без доли сомнения, они очень плохо адаптируются к меняющимся обстоятельствам. Они не могут читать, говорить, выражать эмоции и взаимодействовать каким-либо способом кроме кивков, покачивания головой и взмахов руками. Однако скелеты способны выполнять множество относительно сложных задач.

Скелет способен сражаться оружием, носить доспехи, может заряжать снаряды в катапульты или требушеты, и стрелять из них, может взбираться по осадной лестнице, формировать стену из щитов или поливать кипящим маслом. Однако он должен получить подробные инструкции, объясняющие, как поставленные задачи должны выполняться.

Несмотря на недостаток интеллекта в сравнении с тем, что был у них при жизни, скелеты не полностью лишены разума. Вместо того чтобы сломать конечности в попытке пробиться через железную дверь, скелет в первую очередь потянется к ручке. Если это не сработает, он будет искать другой способ пройти или обойти препятствие.`,
    source: {
      id: SOURCE_MM,
      page: 261,
    },
  },
  {
    header: 'Привычки',
    text: `Независимые скелеты, временно или навсегда вышедшие из-под контроля хозяина, иногда повторяют действия из своей прошлой жизни. Их кости всё ещё хранят поведение, привычное в течение жизни. Скелет-рудокоп может поднять кирку и начать крушить каменные стены. Скелет-охранник может отправиться на пост к ближайшей двери. Скелет-дракон может лечь на гору сокровищ, в то время как скелет-лошадь будет пастись на траве, которую не может есть. Оставшись наедине в просторном зале, бывшие представители знатного рода могут закружиться в бесконечном танце.

Когда скелеты встречают живых существ, движущая ими некротическая энергия заставляет их убивать живое, если только хозяин не остановит их. Они нападают беспощадно и сражаются до своего уничтожения. У скелетов практически нет чувства самоощущения, а самосохранения — и того меньше.`,
    source: {
      id: SOURCE_MM,
      page: 261,
    },
  },
  {
    header: 'Природа нежити',
    text: `Скелету не нужен воздух, еда, питьё и сон.`,
    source: {
      id: SOURCE_MM,
      page: 261,
    },
  },
]
