const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_LEMURE,
  CREATURE_NARZUGON,
  CREATURE_NIGHTMARE,
} = require('./../../../../creatureIdList')
const {
  devilDescriptionList,
  devilNote,
  killAsLemureText,
} = require('./../../../../textCommonParts')
const {
  GEAR_PLATE_ARMOR,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_13} = require('./../../../../crList')
const {MAGIC_ITEM_INFERNAL_TACK} = require('./../../../../magicItemIdList')
const {PC_CLASS_PALADIN} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {
  SOURCE_BGDIA,
  SOURCE_MTOF,
} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Нарзугон',
  nameEn: 'Narzugon',
  id: CREATURE_NARZUGON,
  description: [
    {
      header: 'Нарзугоны',
      text: `Эрцгерцоги Девяти Преисподних особенно ценят [паладинов](PC_CLASS:${PC_CLASS_PALADIN}), заключивших сделки с дьяволами и сохранивших после смерти искажённое представление о чести. Нарзугоны бродят по кругам ада и другим планам бытия, исполняя волю своих господ.

У каждого нарзугона есть свой скакун — [мара](CREATURE:${CREATURE_NIGHTMARE}). Мара укрощена [дьявольской сбруей](MAGIC_ITEM:${MAGIC_ITEM_INFERNAL_TACK}), она обязана являться на призыв носителя шпор и повиноваться его приказам.`,
      source: {
        id: SOURCE_BGDIA,
        page: 241,
      },
    },
    {
      header: 'Нарзугоны',
      text: `Паладины, заключающие сделки с дьяволами и сохраняющие извращённое чувство чести после своей смерти, особенно ценны для эрцгерцогов Девяти Преисподней, которые хотят, чтобы эти безукоризненные чемпионы возглавляли их легионы на войне. Нарзугоны, вооружённые адскими копьями, верхом на [кошмарных скакунах](CREATURE:${CREATURE_NIGHTMARE}) подобны ужасающе развращённым странствующим рыцарям. Они скитаются по инфернальным слоям и другим планам ради осуществления воли своих хозяев.`,
      source: {
        id: SOURCE_MTOF,
        page: 182,
      },
    },
    {
      header: 'Смерть в Аду',
      text: `Копья нарзугона выкованы в адском пламени. Душа любого, убитого таким копьём, перемещается в реку Стикс для возрождения в качестве [лемура](CREATURE:${CREATURE_LEMURE}). Каждое копьё уникально и отмечено знаками как самого нарзугона, так и его хозяина.`,
      source: {
        id: SOURCE_MTOF,
        page: 182,
      },
    },
    {
      header: 'Наездники на кошмарах',
      text: `У каждого нарзугона в качестве ездового животного выступает [кошмар](CREATURE:${CREATURE_NIGHTMARE}). Кошмар связан с нарзугоном с помощью [инфернальной сбруи](MAGIC_ITEM:${MAGIC_ITEM_INFERNAL_TACK}) и обязан появляться и выполнять приказы носителя специальных шпор.`,
      source: {
        id: SOURCE_MTOF,
        page: 182,
      },
    },
    ...devilDescriptionList,
  ],
  note: devilNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 182,
    },
    {
      id: SOURCE_BGDIA,
      page: 241,
    },
  ],
  armor: {
    ac: 20,
    gearId: [GEAR_PLATE_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_13,
  featureList: [
    {
      name: 'Дьявольское чутьё',
      description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Восприятие) для обнаружения существ с добрым мировоззрением.`,
    },
    {
      name: 'Инфернальная сбруя',
      description: `У ★СУЩЕСТВО★ на сапогах есть шпоры, являющиеся частью [инфернальной сбруи](MAGIC_ITEM:${MAGIC_ITEM_INFERNAL_TACK}), которые позволяют ★ему★ призывать своего [кошмара](CREATURE:${CREATURE_NIGHTMARE}).`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ применяет свои _Инфернальный приказ_ или _Ужасающий клич_. Также ★он★ совершает три атаки _Адским копьём_.`,
    },
    {
      name: 'Адское копьё',
      description: `Если _Адское копьё_ ${killAsLemureText}`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 12,
            diceBonus: 5,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 3,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Инфернальный приказ',
      description: `Каждое союзное ★СУЩЕСТВО★ существо в пределах 60 футов от ★него★ не может быть очаровано или испугано до конца следующего хода ★СУЩЕСТВО★.`,
    },
    {
      name: 'Ужасающий клич',
      description: `Каждое слышащее ★СУЩЕСТВО★ существо, не являющееся исчадием, в пределах 60 футов от ★него★, должно пройти испытание Харизмы СЛ 17, иначе это существо испугано ★СУЩЕСТВО★ на 1 минуту. Существо может повторять испытание в конце каждого своего хода, оканчивая эффект при успехе. Существо, успешно прошедшее испытание, приобретает иммунитет к _Ужасающему Кличу_ этого ★СУЩЕСТВО★ на 24 часа.`,
    },
    {
      name: 'Исцеление',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ или существо, которого он коснётся, восстанавливает до 100 хитов.`,
    },
  ],
}
