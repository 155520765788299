const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_HOLD_BREATH} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_HYDRA} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {GOD_TIAMAT} = require('./../../../../godIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Гидра',
  nameEn: 'Hydra',
  id: CREATURE_HYDRA,
  description: [
    {
      header: 'Гидра',
      text: `Гидра это ужасная рептилия с телом крокодила и несколькими головами на длинных змеиных шеях. Хоть головы и можно отрубить, гидра магическим образом их очень быстро отращивает. У типичного представителя этого вида пять голов.

На заре времён [Тиамат](GOD:${GOD_TIAMAT}), Королева Злых Драконов, убила конкурирующее божество по имени Лернея, и рассеяла её кровь по мультивселенной. Каждая упавшая на землю капля породила многоголовую гидру, мучимую таким же сильным голодом, как ненависть павшего божества. Великие герои не раз испытывали свою силу в бою с такими существами.`,
      source: {
        id: SOURCE_MM,
        page: 54,
      },
    },
    {
      header: 'Неутолимый голод',
      text: `Чревоугодливая и прожорливая гидра хватает и рвёт свою жертву на части в припадке жора. Когда гидра зачистит всю округу от съедобных обитателей и прогонит прочь существ, достаточно умных, чтобы её сторониться, она уходит искать еду куда-нибудь ещё. Голод гидры настолько велик, что она никогда не наедается, её головы от голода даже могут начать нападать друг на друга, и таким образом существо может начать есть само себя живьём.`,
      source: {
        id: SOURCE_MM,
        page: 54,
      },
    },
    {
      header: 'Водные обитатели',
      text: `Гидры — природные пловцы, обитающие в реках, у побережья озёр, на мелководьях в океане и даже в болотах. Гидре обычно не требуется убежище от стихий, поэтому и логова она не устраивает. Только в холодном климате гидры бывают вынуждены искать пристанища в пещерах или руинах.

Когда гидра спит, как минимум одна её голова бодрствует, так что это существо очень сложно застать врасплох.`,
      source: {
        id: SOURCE_MM,
        page: 54,
      },
    },
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 54,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 20,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_8,
  featureList: [
    {
      id: ABILITY_HOLD_BREATH,
      time: '1 час',
    },
    {
      name: 'Многочисленные головы',
      description: `У ★СУЩЕСТВО★ пять голов. Пока у ★СУЩЕСТВО★ больше одной головы, ★он★ с преимуществом проходит испытания от испуга, глухоты, лишения сознания, ослепления, очарования и ошеломления. Если ★СУЩЕСТВО★ за один ход получает урон 25 или больше, одна из ★его★ голов умирает. Если умрут все головы, умирает и ★он★. В конце своего хода ★он★ отращивает по две головы за каждую умершую голову с конца ★его★ предыдущего хода, если только ★он★ не получала за этот период урон огнём. За каждую отращённую голову ★СУЩЕСТВО★ восстанавливает по 10 хитов.`,
    },
    {
      name: 'Реагирующие головы',
      description: `За каждую голову после первой ★СУЩЕСТВО★ получает дополнительную реакцию, которую можно использовать только для совершения провоцированных атак.`,
    },
    {
      name: 'Неусыпный',
      description: `Пока гидра спит, как минимум одна её голова бодрствует`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает столько атак _Укусом_, сколько у ★него★ есть голов.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
