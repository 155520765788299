const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  PC_CLASS_WIZARD,
} = require('./../../../../pcClassIdList')
const {
  FEATURE_SPELLBOOK,
} = require('./../../../../featureIdList')
const {
  GEAR_SPELLBOOK,
} = require('./../../../../gearIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../magicItemRarityList')
const {
  SPELL_ENLARGE_REDUCE,
  SPELL_FEATHER_FALL,
  SPELL_FLESH_TO_STONE,
  SPELL_GASEOUS_FORM,
  SPELL_MAGIC_WEAPON,
  SPELL_POLYMORPH,
} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_ALCHEMICAL_COMPENDIUM,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_ALCHEMICAL_COMPENDIUM,
  name: `Алхимический справочник`,
  nameEn: `Alchemical Compendium`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_SPELLBOOK,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  attunementComment: `волшебником`,
  description: `Этот заляпаный тяжёлый том источает едкие запахи. Его металлическая отделка сделана из железа, свинца, серебра, золота и каких-то полупереходных сплавов этих металлов друг с другом.

_Алхимический справочник_ может использоваться как [книга заклинаний](FEATURE:${FEATURE_SPELLBOOK}) и уже содержит некоторые заклинания.

* [Газообразная форма](SPELL:${SPELL_GASEOUS_FORM})
* [Магическое оружие](SPELL:${SPELL_MAGIC_WEAPON})
* [Окаменение](SPELL:${SPELL_FLESH_TO_STONE})
* [Падение пёрышком](SPELL:${SPELL_FEATHER_FALL})
* [Превращение](SPELL:${SPELL_POLYMORPH})
* [Увеличение/Уменьшение](SPELL:${SPELL_ENLARGE_REDUCE})

Держа _Алхимический справочник_, его можно использовать как заклинательную фокусировку для Ваших заклинаний [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}).

У _Справочника_ есть 3 заряда и он восстанавливает 1к3 заряда на рассвете. Держа его, Вы можете использовать его заряды следующими способами.

* Если Вы потратите 1 минуту, изучая _Алхимический справочник_, то можете потратить 1 заряд чтобы заменить одно из Ваших подготовленных заклинаний [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}) на другое из _Справочника_. Новое заклинание должно принадлежать школе Преобразования.
* Вы можете действием коснуться немагического предмета, который никто не несёт и не носит. Вы тратите заряды _Алхимического справочника_, превращая этот предмет в другой. За 1 заряд размер выбранного предмета не может превышать 1 фута. Вы можете потратить несколько зарядов, увеличивая максимальный размер на 2 фута за заряд. Цена нового предмета в золоте не должна быть больше цены исходного предмета.`,
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_ENLARGE_REDUCE,
    SPELL_FEATHER_FALL,
    SPELL_FLESH_TO_STONE,
    SPELL_GASEOUS_FORM,
    SPELL_MAGIC_WEAPON,
    SPELL_POLYMORPH,
  ],
  source: {
    id: SOURCE_TCoE,
    page: 172,
  },
}
