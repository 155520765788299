module.exports = [
  require('./panther'),
  require('./pegasus'),
  require('./pentadrone'),
  require('./peryton'),
  require('./phase_spider'),
  require('./piercer'),
  require('./pirate'),
  require('./pit_fiend'),
  require('./pixie'),
  require('./planetar'),
  require('./plesiosaurus'),
  require('./poisonous_snake'),
  require('./polar_bear'),
  require('./poltergeist'),
  require('./pony'),
  require('./priest'),
  require('./pseudodragon'),
  require('./psychic_gray_ooze'),
  require('./pteranodon'),
  require('./purple_worm'),
]
