const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_PRIMAL_BOND,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_BEAST_OF_THE_LAND,
  CREATURE_BEAST_OF_THE_SEA,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Морской зверь',
  nameEn: 'Beast of the Sea',
  id: CREATURE_BEAST_OF_THE_SEA,
  parentId: CREATURE_BEAST_OF_THE_LAND,
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_SWIM]: 60,
  },
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_PRIMAL_BOND,
  ],
  actionList: [
    {
      name: 'Закус',
      description: 'Цель схвачена (СЛ побега — СЛ заклинаний следопыта). Пока захват не окончится, ★СУЩЕСТВО★ не может использовать _Закус_ по другой цели.',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 'бонус атаки заклинаниями следопыта',
        range: 5,
        hit: {
          typeList: [
            DAMAGE_PIERCING,
            DAMAGE_BLUDGEONING,
          ],
          diceCount: 1,
          diceType: 6,
          diceBonus: '2 + бонус мастерства следопыта',
          comment: ' (на выбор следопыта)',
        },
      },
    },
  ],
}
