const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../../genderList')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {
  VOLUME_UNIT_PINT,
} = require('./../../../volumeUnitList')
const {
  SOURCE_MM,
  SOURCE_PHB,
  SOURCE_TOA,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  CREATURE_FLYING_MONKEY,
  CREATURE_FLYING_SNAKE,
} = require('./../../../creatureIdList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../damageTypeList')
const {
  CAT_ANIMALS,
  CAT_CONTAINERS,
  CAT_EQUIPMENT,
  CAT_GOODS,
  CAT_MARTIAL_MELEE_WEAPON,
  CAT_MUSIC_TOOLS,
  CAT_TOOLS,
  CAT_TRANSPORT,
} = require('./../../../gearCategoryList')
const {
  GEAR_CARTOGRAPHER_S_TOOLS,
  GEAR_FEED,
  GEAR_FISHING_TACKLE,
  GEAR_FLAIL,
  GEAR_FLASK,
  GEAR_FLOUR,
  GEAR_FLUTE,
  GEAR_FLYING_MONKEY,
  GEAR_FLYING_SNAKE,
  GEAR_FORGERY_KIT,
  GEAR_INK,
  GEAR_INK_PEN,
  GEAR_PAPER,
  GEAR_PARCHMENT,
  GEAR_SEALING_WAX,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_FLAIL,
    genderId: GENDER_MALE,
    name: 'Цеп',
    nameByCase: {
      nominative: 'цеп',
      genitive: 'цепа',
      accusative: 'цеп',
      instrumental: 'цепом',
    },
    nameEn: 'Flail',
    damage: {
      diceType: 8,
      diceCount: 1,
    },
    cost: 1000,
    damageType: DAMAGE_BLUDGEONING,
    weight: 2,
    source: {
      id: SOURCE_PHB,
      page: 149,
    },
    category: CAT_MARTIAL_MELEE_WEAPON,
  },
  {
    id: GEAR_FISHING_TACKLE,
    genderId: GENDER_MALE,
    name: 'Рыболовные снасти',
    nameByCase: {
      nominative: 'рыболовные снасти',
      genitive: 'рыболовных снастей',
      accusative: 'рыболовные снасти',
      instrumental: 'рыболовными снастями',
    },
    nameAlt: 'Комплект для рыбалки',
    nameEn: 'Fishing Tackle',
    description: 'В этот набор входит удилище, шёлковая леска, пробковый поплавок, стальные крючки, свинцовые грузила, приманки из ниток и мелкоячеистая сеть.',
    cost: 100,
    weight: 4,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_FLASK,
    genderId: GENDER_FEMALE,
    name: 'Фляга',
    nameByCase: {
      nominative: 'фляга',
      genitive: 'фляги',
      accusative: 'флягу',
      instrumental: 'флягой',
    },
    nameAlt: 'Склянка',
    nameEn: 'Flask',
    cost: 2,
    weight: 1,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 1,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_FORGERY_KIT,
    genderId: GENDER_MALE,
    name: 'Набор для фальсификации',
    nameByCase: {
      nominative: 'набор для фальсификации',
      genitive: 'набора для фальсификации',
      accusative: 'набор для фальсификации',
      instrumental: 'набором для фальсификации',
    },
    nameEn: 'Forgery Kit',
    description: `В этой небольшой коробке лежат разные [бумаги](GEAR:${GEAR_PAPER}) и [пергаменты](GEAR:${GEAR_PARCHMENT}), [перья](GEAR:${GEAR_INK_PEN}) и [чернила](GEAR:${GEAR_INK}), печати и куски [воска](GEAR:${GEAR_SEALING_WAX}), золотая и серебряная фольга, и прочие припасы, необходимые для создания убедительных подделок документов. Владение этим набором позволяет добавлять бонус мастерства к проверкам характеристик, совершённым при создании поддельных документов.

Набор для фальсификации предназначен для копирования документов и упрощения копирования личной печати или подписи.

**Компоненты.** Набор для фальсификации включает в себя

* [чернила](GEAR:${GEAR_INK}) различных видов,
* [пергамент](GEAR:${GEAR_PARCHMENT}) различных видов,
* [бумагу](GEAR:${GEAR_PAPER}) различных видов,
* несколько [писчих перьев](GEAR:${GEAR_INK_PEN}),
* печати,
* [сургуч](GEAR:${GEAR_SEALING_WAX}),
* золотой лист,
* серебряный лист,
* небольшие инструменты для лепки из расплавленного воска имитации печати.

**Магия.** Набор для фальсификации может быть использован вместе с навыком Магия, чтобы определить, является магический предмет настоящим или поддельным.

**Обман.** Качественно выполненная подделка, такая как документ, подтверждающий вашу знатность или предписание, гарантирующее вам безопасный проезд, может придать достоверности вашей лжи.

**История.** Набор для фальсификации вместе с вашими познаниями в истории улучшает вашу способность подделывать исторические документы или определять их подлинность.

**Расследование.** Когда Вы осматриваете предмет, владение набором для фальсификации помогает определить, как предмет был сделан и подлинный ли он.

**Другие инструменты.** Умение использовать другие инструменты делает ваши подделки более правдоподобными. Например, Вы можете объединить владение набором для фальсификации и владение [инструментами картографа](GEAR:${GEAR_CARTOGRAPHER_S_TOOLS}), чтобы сделать поддельную карту.

**Быстрая подделка.** Как часть короткого отдыха Вы можете создать поддельный документ длиной не более одной страницы. Как часть длинного отдыха Вы можете создать документ длинной до четырёх страниц. Ваша проверка Интеллекта в использовании набора для фальсификации определяет СЛ проверки Интеллекта (Расследование) на определение того, что это подделка.

| Действие                    | СЛ |
|-----------------------------|----|
| Подделать подчерк           | 15 |
| Скопировать восковую печать | 20 |
`,
    cost: 1500,
    weight: 5,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 83,
      },
    ],
    category: CAT_TOOLS,
  },
  {
    id: GEAR_FLUTE,
    genderId: GENDER_FEMALE,
    name: 'Флейта',
    nameByCase: {
      nominative: 'флейта',
      genitive: 'флейты',
      accusative: 'флейту',
      instrumental: 'флейтой',
    },
    nameEn: 'Flute',
    description: musicalToolDescriptionList,
    cost: 200,
    weight: 1,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_FLYING_SNAKE,
    genderId: GENDER_FEMALE,
    name: 'Летающая змея',
    nameByCase: {
      nominative: 'летающая змея',
      genitive: 'летающей змеи',
      accusative: 'летающую змею',
      instrumental: 'летающей змеёй',
    },
    nameEn: 'Flying Snake',
    creatureId: CREATURE_FLYING_SNAKE,
    description: 'Скорость: 30 фт., летая 60 фт., плавая 30 фт. Грузоподъёмность 30 фунтов.',
    cost: 25000,
    source: [
      {
        id: SOURCE_TOA,
        page: 29,
      },
      {
        id: SOURCE_MM,
        page: 332,
      },
    ],
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_FLYING_MONKEY,
    genderId: GENDER_FEMALE,
    name: 'Летающая обезьяна',
    nameByCase: {
      nominative: 'летающая обезьяна',
      genitive: 'летающей обезьяны',
      accusative: 'летающую обезьяну',
      instrumental: 'летающей обезьяной',
    },
    nameEn: 'Flying Monkey',
    creatureId: CREATURE_FLYING_MONKEY,
    description: 'Скорость: 20 фт., лазая 20 фт., летая 30 фт. Грузоподъёмность 60 фунтов.',
    cost: 10000,
    source: [
      {
        id: SOURCE_TOA,
        page: 29,
      },
      {
        id: SOURCE_TOA,
        page: 232,
      },
    ],
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_FEED,
    genderId: GENDER_MALE,
    name: 'Корм',
    nameByCase: {
      nominative: 'корм',
      genitive: 'кормы',
      accusative: 'корм',
      instrumental: 'кормом',
    },
    nameEn: 'Feed',
    description: 'В день',
    cost: 5,
    weight: 10,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT,
  },
  {
    id: GEAR_FLOUR,
    genderId: GENDER_FEMALE,
    name: 'Мука',
    nameByCase: {
      nominative: 'мука',
      genitive: 'муки',
      accusative: 'муку',
      instrumental: 'мукой',
    },
    nameEn: 'Flour',
    cost: 2,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
]

module.exports = gearRawList
