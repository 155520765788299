const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_DRAGON_GREEN_ADULT,
  CREATURE_DRAGON_BLACK_ANCIENT,
  CREATURE_DRAGON_WHITE_ANCIENT,
  CREATURE_ETTERCAP,
  CREATURE_ETTIN,
  CREATURE_KOBOLD,
  CREATURE_DRAGON_GREEN_WYRMLING,
  CREATURE_ORC,
  CREATURE_YUAN_TI_ABOMINATION,
} = require('./../../creatureIdList')

const colorDragonDescriptionList = require('./dragonColor')

module.exports = [
  {
    header: 'Логово зелёного дракона',
    text: `Предпочитающие селиться в лесах, зелёные драконы иногда конфликтуют с [чёрными драконами](CREATURE:${CREATURE_DRAGON_BLACK_ANCIENT}) за заболоченные леса и с [белыми драконами](CREATURE:${CREATURE_DRAGON_WHITE_ANCIENT}) за ледяную тайгу. Лес, находящийся под контролем зелёного дракона, легко узнать. Постоянный туман укрывает лес легендарного зелёного дракона, донося едкий запах его ядовитого дыхания. Покрытые мхом деревья растут близко друг к другу везде, кроме извилистых троп, которые образуют лабиринт вокруг сердца леса. Свет, проникающий сквозь плотные кроны, окрашен изумрудно-зелёным цветом, а звуки кажутся приглушёнными.
    
В самом центре леса зелёный дракон выбирает пещеру в отвесной скале или на склоне, холма и располагает там своё скрытое от любопытных глаз логово. Некоторые из них прячут пещеры за водопадами или выбирают частично погружённые в воду, чтобы добраться внутрь можно было лишь вплавь. Другие скрывают вход в логово растительностью.
     
# Действия логова
      
При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов; дракон не может использовать один и тот же эффект два раунда подряд:

* Цепкие корни и плети пробиваются из земли в 20-футовом радиусе от точки, которую дракон видит в пределах 120 футов. Эта область становится труднопроходимой и все находящиеся в ней существа должны пройти испытание Силы СЛ 15, иначе обездвиживаются корнями и плетьми. Существо может освободиться, если оно или другое существо действием совершит проверку Силы СЛ 15 и преуспеет. Корни и лозы мгновенно увядают, когда дракон снова совершает это действие логова или когда погибает.
* Стена из колючего кустарника вырастает на твёрдой поверхности в пределах 120 футов от дракона. Стена может быть до 60 футов в длину, 10 футов в высоту, 5 футов в толщину и полностью блокирует обзор. Когда стена появляется, все существа в её области должны пройти испытание Ловкости СЛ 15. Провалившее испытание существо получает колющий урон 18 (4к8) и выталкивается из занимаемой стеною области на 5 футов по одну из сторон стены на свой выбор. Существо может проходить сквозь эту стену, но это болезненный и медленный процесс. За каждый 1 фут передвижения сквозь стену, оно должно затратить 4 фута передвижения. Кроме того, существо, находящееся в пространстве стены, должно проходить испытание Ловкости СЛ 15 каждый раунд, в котором оно взаимодействовало со стеной, получая колющий урон 18 (4к8) при провале, или половину этого урона при успехе. У каждой 10-футовой секции стены КД 5, 15 хитов, уязвимость к огню, сопротивление к дробящему и колющему урону, и иммунитет к урону психической энергией. Стена увядает, пропадая полностью, когда дракон снова совершает это действие логова или когда он погибает.
* Магический туман окутывает одно существо, которое дракон видит в пределах 120 футов. Существо должно пройти испытание Мудрости СЛ 15, иначе станет очарованным драконом до наступления значения инициативы «20» в следующем раунде.
       
# Местные эффекты
        
Регион, в котором есть логово легендарного зелёного дракона, искажается драконьей магией, которая создаёт один или несколько следующих эффектов:
         
* Заросли образуют подобные лабиринту проходы в пределах 1 мили от драконьего логова. Считайте заросли блокирующими обзор стенами 10 футов в высоту и толщину. Существа могут проходить сквозь заросли, тратя по 4 фута передвижения за каждый 1 фут передвижения сквозь стену. Также существо, находящееся в зарослях, должно проходить испытание Ловкости СЛ 15 каждый раунд, в котором оно взаимодействовало с зарослями, при провале получая колющий урон 3 (1к6).
* Каждый 10-футовый куб зарослей имеет КД 5, 30 хитов, сопротивление к дробящему и колющему урону, уязвимость к огню, и иммунитет к урону звуком и психической энергией.
* В пределах 1 мили от логова дракон не оставляет никаких следов, если только сам того не пожелает. Выследить его там невозможно ничем кроме магии. Кроме того, он игнорирует любые осложнения передвижения и урон от растений в этой области, включая заросли описанные выше, кроме магических растений и растений-существ. Растения сами отступают с драконьего пути.
* Грызуны и птицы в пределах 1 мили от логова служат дракону глазами и ушами. Олени же и другая крупная дичь странным образом отсутствуют, намекая на присутствие необычайно голодного хищника.
         
Если дракон умирает, грызуны и птицы теряют свою сверхъестественную связь с ним. Заросли остаются на месте, но через 1к10 дней теряют свои шипы, становясь обычной, но всё ещё труднопроходимой местностью.`,
    source: {
      id: SOURCE_MM,
      page: 109,
    },
  },
  {
    header: 'Зелёные драконы',
    text: `Наиболее изворотливые и коварные из истинных драконов, зелёные драконы используют хитрость и обман, чтобы получить преимущество над врагом. Вредные по натуре и злые до глубины души, они испытывают особое удовольствие, расстраивая планы и совращая добросердечных. В древних лесах, в которых они бродят, зелёные драконы демонстрируют агрессию скорее, чтобы заполучить больше власти и богатств с наименьшими усилиями, чем ради территории.

Зелёного дракона можно узнать по изогнутой линии челюсти и хохолку, начинающемуся у глаз и спускающемуся вдоль хребта и достигающего наибольшей высоты у основания черепа. У зелёного дракона нет внешних ушей, вместо которых у него располагаются кожистые пластины, спускающиеся по обеим сторонам к шее.

Тонкая чешуя [вирмлингов зелёных драконов](CREATURE:${CREATURE_DRAGON_GREEN_WYRMLING}) настолько тёмная, что кажется чёрной. С возрастом их чешуйки увеличиваются и светлеют, окрашиваясь в цвета леса, изумрудные и оливковые оттенки зелёного, что помогает им скрываться в окружающем лесу. Крылья их покрыты пятнистым узором, тёмным у передней кромки и более светлым у задней.

Лапы зелёных драконов длиннее, чем у других видов драконов, что позволяет им легко проходить над подлеском и тем, что лежит на земле. С помощью такой же длинной шеи более [старые зелёные драконы](CREATURE:${CREATURE_DRAGON_GREEN_ADULT}) могут смотреть над деревьями, не приподнимаясь на задних лапах.`,
    source: {
      id: SOURCE_MM,
      page: 109,
    },
  },
  {
    header: 'Прихотливые охотники',
    text: `Зелёные драконы охотятся, патрулируя территорию леса по воздуху и земле, пожирая всех увиденных существ. Они могут есть кустарники и маленькие деревца, если сильно проголодаются, но их излюбленная добыча — эльфы.

Зелёные драконы — законченные лжецы и мастера двусмысленных речей. Они предпочитают запугивать меньших существ, но действуют более тонко, общаясь с другими драконами. Зелёные драконы нападают на животных и чудовищ без повода, особенно когда речь идёт об угрозе его территории. Имея дело с разумными существами, зелёные драконы демонстрируют жажду власти, которая идёт вразрез с драконьей тягой к сокровищам, и всегда ищут существ, которые могут помочь им претворить их амбиции в жизнь.

Зелёный дракон преследует своих жертв, пока планирует своё нападение, иногда скрываясь по нескольку дней. Если цель достаточно слаба, дракон смакует ужас, который внушает его внешность, прежде чем наброситься. Он никогда не убивает всех своих жертв, предпочитая использовать запугивание, чтобы установить контроль над выжившими. Так он узнаёт всё, что может, о деятельности существ близ его территории, в особенности о сокровищах неподалёку. Зелёные драконы иногда отпускают своих пленников, если за них можно получить выкуп. В противном случае существо должно доказать свою повседневную ценность для дракона или стать его жертвой.`,
    source: {
      id: SOURCE_MM,
      page: 109,
    },
  },
  {
    header: 'Великие комбинаторы',
    text: `Коварные и подлые существа, зелёные драконы подчиняют других существ своей воле, узнавая их самые потаённые желания и позже пользуясь этим. Тот, кто достаточно глуп, чтобы взять зелёного дракона в подчинённые, рано или поздно узнает, что тот лишь притворялся слугой, чтобы узнать больше о своём «хозяине».

При манипулировании другими существами речи зелёных драконов сладки, гладки и изысканны. Среди своих собратьев же они горласты, грубы и нахальны, особенно когда общаются с драконами равного им возраста и статуса.`,
    source: {
      id: SOURCE_MM,
      page: 109,
    },
  },
  {
    header: 'Конфликт и развращение',
    text: `Зелёные драконы иногда конфликтуют с другими драконами за территорию там, где их леса заходят на другие местности. Тогда зелёный дракон обычно притворяется, что отступает, с тем лишь, чтобы понаблюдать и выждать — иногда несколько десятилетий — когда представится шанс убить другого дракона и завладеть его логовом и кладом.

Зелёные драконы приемлют услужение разумных существ, таких как гоблиноиды, [эттеркапы](CREATURE:${CREATURE_ETTERCAP}), [эттины](CREATURE:${CREATURE_ETTIN}), [кобольды](CREATURE:${CREATURE_KOBOLD}), [орки](CREATURE:${CREATURE_ORC}) и [юань-ти](CREATURE:${CREATURE_YUAN_TI_ABOMINATION}). Они также получают особое удовольствие, развращая и подчиняя эльфов своей воле. Зелёные драконы иногда с помощью страха подтачивают разум своих подручных до почти безумного состояния, распространяя туман из их кошмаров по всему лесу.`,
    source: {
      id: SOURCE_MM,
      page: 109,
    },
  },
  {
    header: 'Живые сокровища',
    text: `Излюбленные сокровища зелёного дракона — это разумные существа, подчинённые его воле, такие как прославленные герои, известные мудрецы и популярные барды. Из материальных сокровищ зелёные драконы предпочитают изумруды, резьбу по дереву, музыкальные инструменты и скульптуры гуманоидов.`,
    source: {
      id: SOURCE_MM,
      page: 109,
    },
  },
  ...colorDragonDescriptionList,
]
