const {ABILITY_LEGENDARY_RESISTANCE_2} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CREATURE_VANIFER} = require('./../../../../creatureIdList')
const {CR_9} = require('./../../../../crList')
const {GEAR_STUDDED_LEATHER_ARMOR} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {MAGIC_ITEM_TINDERSTRIKE} = require('./../../../../magicItemIdList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {eternalFlameDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_TIEFLING,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_PERFORMANCE,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPELL_BURNING_HANDS,
  SPELL_CHILL_TOUCH,
  SPELL_CHROMATIC_ORB,
  SPELL_COUNTERSPELL,
  SPELL_DARKNESS,
  SPELL_DETECT_THOUGHTS,
  SPELL_DOMINATE_PERSON,
  SPELL_FIREBALL,
  SPELL_FIRE_BOLT,
  SPELL_FRIENDS,
  SPELL_HELLISH_REBUKE,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_MISTY_STEP,
  SPELL_PRODUCE_FLAME,
  SPELL_SCORCHING_RAY,
  SPELL_SHIELD,
  SPELL_THAUMATURGY,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_IGNAN,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Вейнифер',
  nameEn: 'Vanifer',
  id: CREATURE_VANIFER,
  hasSpoilers: true,
  description: [
    {
      header: 'Вейнифер',
      text: `Тяжёлое детство Вейнифер ожесточило её сердце. Она считает, что мир вокруг неё порочен, мучителен и заслуживает сгинуть в пламени. На протяжении многих лет она была танцовщицей и наложницей при дворе паши в Калимшане, но, благодаря своему безжалостному честолюбию и умелому манипулированию людьми, сумела бежать из рабства. Эти же качества помогают ей управлять растущим культом и теперь, когда она нашла [Высекающий Искры](MAGIC_ITEM:${MAGIC_ITEM_TINDERSTRIKE}) и облачилась в мантию пророка огня.

Вейнифер предпочитает наблюдать за сражением издалека, бросая в противников разрушительные заклинания, а все, кто осмелился противостоять ей в ближнем бою, получают болезненный урок: Вейнифер вместе с [Высекающим Искры](MAGIC_ITEM:${MAGIC_ITEM_TINDERSTRIKE}) составляют грозный дуэт.`,
      source: {
        id: SOURCE_PotA,
        page: 208,
      },
    },
    {
      header: 'В узле огня',
      text: `Когда храму Вечного Пламени угрожает опасность, Вейнифер отступает к Рыдающему Колоссу, в узел огня. Здесь Вейнифер получает дополнительное использование своей способности «Легендарное сопротивление».`,
      source: {
        id: SOURCE_PotA,
        page: 208,
      },
    },
    {
      header: 'Действия логова',
      text: `Если Вейнифер находится в узле огня, без Имикса, она может использовать действия логова. При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) она может использовать действие логова, чтобы сотворить одно из своих заклинаний не выше 3 уровня без использования компонентов и ячеек заклинаний. Она не может использовать одно и то же заклинание два раунда подряд, хотя она может продолжить концентрироваться на заклинании, вызванном действием логова ранее. Вейнифер не может совершать иных действий логова, если она концентрируется на заклинании, вызванном действием логова. Её излюбленная тактика — использовать действие логова для сотворения [огненного шара](SPELL:${SPELL_FIREBALL}) или [гипнотического узора](SPELL:${SPELL_HYPNOTIC_PATTERN}).

Если Вейнифер тратит действие логова на заклинание [Туманный шаг](SPELL:${SPELL_MISTY_STEP}), она также вбирает в себя силу огненного узла, тем самым восстанавливая себе 15 (3к8 + 2) хитов.

Вейнифер в своём логове имеет показатель опасности 12 (8 400 опыта).`,
      source: {
        id: SOURCE_PotA,
        page: 208,
      },
    },
    eternalFlameDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_TIEFLING,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 208,
  },
  armor: {
    ac: 15,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 17,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 19,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERFORMANCE]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_IGNAN,
    LANG_INFERNAL,
  ],
  cr: CR_9,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_2,
    {
      name: 'Погребальный костер',
      description: `Когда хиты ★СУЩЕСТВО★ падают до 0, ★его★ тело исчезает во вспышке огня и дыма. Всё, что ★он★ ★нёс★ и ★носил★, остаётся лежать в кучке пепла.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 10,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_BURNING_HANDS,
      SPELL_CHILL_TOUCH,
      SPELL_CHROMATIC_ORB,
      SPELL_COUNTERSPELL,
      SPELL_DARKNESS,
      SPELL_DETECT_THOUGHTS,
      SPELL_DOMINATE_PERSON,
      SPELL_FIREBALL,
      SPELL_FIRE_BOLT,
      SPELL_FRIENDS,
      SPELL_HELLISH_REBUKE,
      SPELL_HYPNOTIC_PATTERN,
      SPELL_MAGE_HAND,
      SPELL_MESSAGE,
      SPELL_MISTY_STEP,
      SPELL_PRODUCE_FLAME,
      SPELL_SCORCHING_RAY,
      SPELL_SHIELD,
      SPELL_THAUMATURGY,
      SPELL_WALL_OF_FIRE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки.`,
    },
    {
      magiItemId: MAGIC_ITEM_TINDERSTRIKE,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 5,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
    {
      magiItemId: MAGIC_ITEM_TINDERSTRIKE,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 9,
        range: {
          normal: 20,
          max: 60,
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 5,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
