const {
  SOURCE_IDRotF,
  SOURCE_VGTM,
} = require('./../../sourceList')

module.exports = [
  {
    header: 'Голиафы',
    text: `На высоких горных пиках — выше склонов, на которых растут деревья, там, где разрежённый воздух и пронизывающие ветра — обособленно живут голиафы. Мало людей, которые заявляют, что видели голиафа, и ещё меньше тех, кто утверждает, что подружился с одним из них. Голиафы кочуют по суровому царству скал, ветра и холода. Их тела, выглядящие будто вырезаны из горного камня, обладают огромной физической силой. Их дух, влекомый блуждающим ветром, заставляет их кочевать от вершины к вершине. Их сердца, наполненные суровым почтением к их холодному миру, обязывают каждого голиафа заслужить своё место в племени или умереть пытаясь.`,
    source: {
      id: SOURCE_VGTM,
      page: 110,
    },
  },
  {
    header: 'Ведомые соперничеством ',
    text: `Каждый день бросает голиафу новый вызов. Еда, вода и убежище редко встречаются в высокогорье. Одна ошибка может принести гибель всему племени, в то время как героическое усилие одного может гарантировать выживание всей группе.

Голиафы таким образом полагаются на самодостаточность и собственные умения каждого. У них есть привычка вести счет, подсчитывая свой вклад во что– то и сравнивая его со вкладом других. Голиафы любят побеждать, но они рассматривают поражение как причину усовершенствовать свои навыки.

У этой приверженности соперничеству есть и темная сторона. Голиафы – свирепые конкуренты, но больше прочего их ведет желание превзойти свои прошлые деяния. Если голиаф убил дракона, он или она будет искать более крупного и более сильного дракона, чтобы сразить и его. Редкий голиаф – искатель приключений доживает до старости, поскольку большинство умирает, пытаясь превзойти собственные подвиги.`,
    source: {
      id: SOURCE_VGTM,
      page: 110,
    },
  },
  {
    header: 'Честная игра ',
    text: `Для голиафов соревнование существует только когда оно проходит в равных условиях. Соревнование определяет талантливость, преданность и целеустремленность. Это факторы позволяют выживать на их родине, не полагаясь на магические предметы, деньги или прочее, что может склонить чашу весов в одну или другую сторону. Голиафы с радостью используют такие преимущества, но они делают это с опаской, помня, что такое преимущество может в любой момент пропасть. Голиаф, который слишком сильно на них полагается, может стать самонадеянным, а в горах это верный путь к беде.

Эта черта проявляется наиболее сильно, когда голиафы взаимодействуют с другими народами. Отношения между крестьянами и знатью озадачивают голиафов. Если королю не хватает ума или лидерства, чтобы вести за собой, то очевидно, что его место должен занять самый талантливый человек в королевстве. Голиафы редко оставляют такие мнение при себе, и высмеивают людей, которые полагаются на общественные структуры или правила, чтобы сохранить власть.`,
    source: {
      id: SOURCE_VGTM,
      page: 110,
    },
  },
  {
    header: 'Естественный отбор',
    text: `Среди голиафов любой взрослый, который не приносит пользу племени, изгоняется. У одного голиафа мало шансов выжить, особенно у старого или слабого. У голиафов мало жалости ко взрослому, который не может позаботиться о себе, хотя больного или раненого лечат в следствии представлений голиафов о честной игре.

Травмированный на долго голиаф, как правило не хочет быть бременем для племени. Как правило, такой голиаф умирает, пытаясь работать как здоровый, или убегает ночью, чтобы встретить свою судьбу на холоде.

В некоторых случаях, стремление голиафа превзойти самого себя приводит его к неизбежному поражению и смерти. Для голиафа на много лучше умереть в бою, на пике силы и умения, чем долго и медленно умирать от старости. Крайне мало людей когда–либо встречало пожилого голиафа, и даже эти голиафы, которые покинули свое племя, борются с желанием расстаться с жизнью, поскольку их физические навыки приходят в упадок.

Из–за опасности их образа жизни, племена голиафа страдают от хронической нехватки опыта, из–за отсутствия старейшин. Они надеются на врожденную мудрость своих лидеров, поскольку редко могут рассчитывать на мудрость, обретенную с возрастом.`,
    source: {
      id: SOURCE_VGTM,
      page: 110,
    },
  },
  {
    header: 'Имена голиафов ',
    text: `У каждого голиафа есть три имени: имя от рождения, данное матерью и отцом новорожденного, прозвище, данное вождем племени, и название семьи или клана. Имя от рождения длиной до трех слогов. Название клана — пять и более слогов с гласной на конце.

Имена от рождения редко связано с полом. Голиафы видят женщин и мужчин как равных во всем, и общества с разделением ролей между полами либо озадачивают их, либо вызывают насмешку. Для голиафа именно тот, кто лучше делает какую то работу, должен её выполнять.

Прозвище голиафа — это характеристика, которая может измениться по прихоти вождя или старейшины племени. Оно описывает любой поступок, успех или провал, совершенный голиафом. Голиафы дают прозвища своим друзьям из других рас, и изменяют их, чтобы показать совершенные ими дела.

Голиафы представляются всеми тремя именами, перечисляя их в таком порядке: имя от рождения, прозвище, название клана. В обычной беседе они используют свое прозвище.

# Имя от рождения

Аукан, Эглат, Гаи–Ал, Гаусак, Иликан, Кеоти, Куори, Ло–Ка, Маннео, Мавеис, Нала, Орило, Пааву, Петани, Талай, Сасем, Юфал, Вауния, Вимак

# Прозвище

Убивший медведя, Взывающий к заре, Бесстрашный, Нашедший кремень, Вырезающий уступы, Остроглазый, Одинокий охотник, Далеко прыгающий, Сшибающий корни, Глядящий в небо, Твердая рука, Скручивающий нить , Дважды осиротевший, Вывихнутая конечность, Рисующий слова

# Название клана

Анакалатай, Эленитино, Гатаканаси, Каладжано, Кото–Олави, Колаи–Джилиана, Оголакану, Тулиаджа, Тунукаласи, Ваймей–Лага.`,
    source: {
      id: SOURCE_VGTM,
      page: 110,
    },
  },
  {
    header: 'Голиафы',
    text: `На высочайших горных пиках — далеко от покрытых растениями склонов, где воют ледяные ветра и воздух разрежен — живут затворники голиафы. Мало кто видел голиафа и ещё меньше тех, кто смог подружиться с одним из них.

Голиафы любят соревноваться и вести счёт, подсчитывая свои свершения и сравнивая эти деяния с чужими. Голиафы любят выигрывать, но поражение они рассматривают как стимул для улучшения своих навыков. Больше всего прочего каждый голиаф движим желанием превосходить свои же прошлые достижения, каждый раз рискуя всё больше и больше. Из-за этого очень мало голиафов доживает до преклонных лет.`,
    source: {
      id: SOURCE_IDRotF,
      page: 293,
    },
  },
  {
    header: 'Голиафы Хребта Мира',
    text: `Голиафы живут на Хребте Мира, где проверяют свою доблесть с помощью орков, огров, ледяных великанов, реморазов, ледяных троллей, молодых белых драконов, смертоносных скал и жестокого безразличия природы.`,
    source: {
      id: SOURCE_IDRotF,
      page: 293,
    },
  },
  {
    header: 'Жестокие соперники',
    text: `Оплот Небесной Башни и утёс Гибели Змеев — две населённых голиафами высокогорных крепости. Первая — дом клана Небесной Башни (Аканнати), вторая — клана Гибели Змеев (Туунлакалага). Эти две группы отказываются ладить и соперничают друг с другом свирепо и, зачастую, жестоко. Они следят друг за другом через горы и дерутся за всё, начиная с припасов и заканчивая территорией. Вероятность мира между ними крайне мала, потому что голиафы любят затаивать обиду.`,
    source: {
      id: SOURCE_IDRotF,
      page: 293,
    },
  },
]
