const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  PARAM_CON,
} = require('./../../../../../paramList')
const {
  DAMAGE_COLD,
} = require('./../../../../../damageTypeList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_FTD,
} = require('./../../../../../sourceList')
const {
  SPELL_RIME_S_BINDING_ICE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_RIME_S_BINDING_ICE,
  name: 'Инеев сковывающий лёд',
  nameEn: 'Rime’s Binding Ice',
  description: `Вы исторгаете взрыв замораживающего холода 30-футовым конусом.

Все существа в этой области должны пройти испытание Телосложения.

* При провале существо получает 3к8 урона холодом и удерживается ледяной коркой на одну минуту или пока какое-то существо в пределах его досягаемости не сколет лёд действием. Скорость удерживаемого ледяной коркой существа равна 0.
* При успехе существо получает только половину урона и не удерживается ледяной коркой.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше второго.`,
  lvl: 2,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  rangeComment: `30-футовый конус`,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
  materialText: `флакон талой воды`,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_COLD,
      diceCount: 3,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_FTD,
    page: 21,
  },
}
