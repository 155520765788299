const {SOURCE_EGTW} = require('./../../../sourceList')
const {PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC} = require('./../../../pcSubClassIdList')
const {dunamancySpellsTemplate} = require('./../../featureCommonRawList')
const {
  FEATURE_ARCANE_ABEYANCE,
  FEATURE_CHRONAL_SHIFT,
  FEATURE_CHRONURGY_SPELL_LIST,
  FEATURE_CONVERGENT_FUTURE,
  FEATURE_MOMENTARY_STASIS,
  FEATURE_TEMPORAL_AWARENESS,
} = require('./../../../featureIdList')

const dunamancyCommonSpellIdList = require('./../../constants/dunamancyCommonSpellIdList')
const chronurgyOnlySpellIdList = require('./../../constants/chronurgyOnlySpellIdList')

module.exports = [
  {
    ...dunamancySpellsTemplate,
    id: FEATURE_CHRONURGY_SPELL_LIST,
    spellIdList: [
      ...dunamancyCommonSpellIdList,
      ...chronurgyOnlySpellIdList,
    ],
  },
  {
    id: FEATURE_CHRONAL_SHIFT,
    name: `Хроносдвиг`,
    nameEn: `Chronal Shift`,
    lvl: 2,
    text: `Вы можете магически получить ограниченный контроль над потоком времени вокруг существа.

После того как Вы или видимое Вами в пределах 30 футов существо совершает бросок атаки, проверку способности или проходит испытание, Вы можете реакцией заставить то существо перебросить этот бросок. Вы принимаете это решение после того, как узнаёте, был бросок успешным или нет. Цель должна использовать результат нового броска.

Вы можете использовать эту способность дважды и восстанавливаете все использования, когда заканчиваете короткий или длинный отдых.`,
    source: {
      id: SOURCE_EGTW,
      page: 185,
    },
  },
  {
    id: FEATURE_TEMPORAL_AWARENESS,
    name: `Темпоральная осведомлённость`,
    nameEn: `Temporal Awareness`,
    lvl: 2,
    text: `Вы можете добавлять модификатор Интеллекта к броскам инициативы.`,
    source: {
      id: SOURCE_EGTW,
      page: 185,
    },
  },
  {
    id: FEATURE_MOMENTARY_STASIS,
    name: `Мгновенный статиз`,
    nameEn: `Momentary Stasis`,
    lvl: 6,
    text: `Вы можете действием магически вынудить видимое Вами в пределах 60 футов Большое или меньшее существо пройти испытание Телосложения. При провале существо заключается в поле магической энергии до конца Вашего следующего хода или пока оно не получит урон. Будучи заключённым таким образом, существо Недееспособно и его скорость равна 0.

Вы можете использовать эту способность число раз, равное Вашему модификатору Интеллекта (минимум однажды) и восстановите все использования, завершив длинный отдых.`,
    source: {
      id: SOURCE_EGTW,
      page: 185,
    },
  },
  {
    id: FEATURE_ARCANE_ABEYANCE,
    name: `Отложенное волшебство`,
    nameEn: `Arcane Abeyance`,
    lvl: 10,
    text: `Когда Вы сотворяете заклинание, используя ячейку 4 круга или ниже, Вы можете сжать это заклинание в пылинку. Заклинание замораживается во времени в момент сотворения и удерживается внутри серой бусинки на 1 час. Эта бусинка — Крохотный предмет с КД 15 и 1 хитом, у неё иммунитет к урону ядом и психическому урону. Когда время удержания заканчивается или при уничтожении бусин, она исчезает во вспышке света и заклинание теряется.

Существо, держащее бусину, может действием выпустить заключённое в ней заклинание. Бусина при этом пропадает. Заклинание использует Ваш бонус к атаки заклинаниями и СЛ испытаний от заклинаний, но для всего прочего считается что заклинание сотворило это существо.

Создав бусину этой способностью, Вы не можете сделать это снова, пока не закончите короткий или длинный отдых.`,
    source: {
      id: SOURCE_EGTW,
      page: 185,
    },
  },
  {
    id: FEATURE_CONVERGENT_FUTURE,
    name: `Слияние будущего`,
    nameEn: `Convergent Future`,
    lvl: 14,
    text: `Вы можете вглядываться в возможные будущие и магически вытягивать одно из них в окружающие Вас события, обеспечивая нужный исход.

После того как Вы или видимое Вами в пределах 60 футов существо совершает бросок атаки, проверку способности или проходит испытание, Вы можете реакцией отменить результат броска и решить — будет ли выброшен минимально необходимый для успеха результат, или же результат на единицу меньше (на Ваш выбор).

Использовав эту способность, Вы получаете один уровень Истощения. Снять уровни Истощения, полученные от использования этой способности, можно только при завершении длинного отдыха.`,
    source: {
      id: SOURCE_EGTW,
      page: 185,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC,
  })
)

