const {PC_SUBCLASS_WIZARD_SCHOOL_OF_ENCHANTMENT} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  FEATURE_ALTER_MEMORIES,
  FEATURE_ENCHANTMENT_SAVANT,
  FEATURE_HYPNOTIC_GAZE,
  FEATURE_INSTINCTIVE_CHARM,
  FEATURE_SPELLBOOK,
  FEATURE_SPLIT_ENCHANTMENT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ENCHANTMENT_SAVANT,
    name: `Мастер очарования`,
    nameEn: `Enchantment Savant`,
    lvl: 2,
    text: `Золото и время, которое Вы тратите на копирование заклинания Очарования в свою [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), уменьшаются вдвое.`,
  },
  {
    id: FEATURE_HYPNOTIC_GAZE,
    name: `Гипнотический взгляд`,
    nameEn: `Hypnotic Gaze`,
    lvl: 2,
    text: `Ваши тихие слова и завораживающий взгляд волшебным образом могут одурманить другое существо. Выберите действием одно видимое Вами существо в пределах 5 футов от себя. Если цель может видеть или слышать Вас, она должна пройти испытание Мудрости против СЛ Ваших заклинаний волшебника, иначе она будет очарована Вами до конца Вашего следующего хода. Скорость очарованного существа падает до 0, оно выводится из строя и выглядит потрясённым.

В следующие ходы Вы можете действием поддерживать этот эффект, продлевая его до конца своего следующего хода. Однако эффект прекращается, если Вы удалитесь от существа более чем на 5 футов, если существо не может больше ни видеть, ни слышать Вас, или если существо получает урон.
 
После окончания эффекта, или если существо успешно пройти испытание от него, Вы не можете использовать данное умение против этого существа, пока не завершите длинный отдых.`,
  },
  {
    id: FEATURE_INSTINCTIVE_CHARM,
    name: `Инстинктивное очарование`,
    nameEn: `Instinctive Charm`,
    lvl: 6,
    text: `Когда существо, которое Вы видите, и которое находится в пределах 30 футов от Вас, совершает бросок атаки по Вам, Вы можете реакцией перенаправить атаку, при условии, что есть другое существо, находящееся в пределах досягаемости этой атаки. Атакующий должен пройти испытание Мудрости против СЛ Вашего заклинания волшебника. При провале атакующий должен выбрать в качестве цели ближайшее существо (кроме Вас и себя). Если есть несколько потенциальных целей, атакующий сам выбирает из них цель. В случае успеха Вы не можете использовать данное умение против атакующего, пока не завершите длинный отдых.

Вы обязаны решить, будете ли использовать это умение, до того как будет известно, попала атака или промахнулась. Существа, которых нельзя очаровать, обладают иммунитетом к этому эффекту.`,
  },
  {
    id: FEATURE_SPLIT_ENCHANTMENT,
    name: `Усиленное очарование`,
    nameEn: `Split Enchantment`,
    lvl: 10,
    text: `При накладывании Вами заклинания школы Очарования 1 круга или выше, которое нацеливается только на одно существо, Вы можете сделать целью второе существо.`,
  },
  {
    id: FEATURE_ALTER_MEMORIES,
    name: `Ложные воспоминания`,
    nameEn: `Alter Memories`,
    lvl: 14,
    text: `Вы получаете способность заставить существо забыть о магическом воздействии, которое Вы на него оказали. При сотворении заклинания школы Очарования, которое очаровывает одно или несколько существ, Вы можете изменить восприятие и воспоминания одного из них так, что оно забудет о том, что было очаровано.

Кроме того, перед тем как заклинание развеется, Вы можете действием попытаться заставить выбранное существо забыть некоторые моменты его пребывания в зачарованном состоянии. Существо должно пройти испытание Интеллекта против СЛ Вашего заклинания волшебника, или оно потеряет воспоминания за промежуток времени, измеряемый в часах и равный 1 + Ваш модификатор Харизмы (минимум 1).

Вы можете заставить существо забыть меньшее количество времени. Количество забытого времени не может превышать продолжительность самого заклинания.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_SCHOOL_OF_ENCHANTMENT,
    source: {
      id: SOURCE_PHB,
      page: 119,
    },
  })
)
