const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_IOKHARIC} = require('./../../alphabetList')
const {LANG_LANTANESE} = require('./../../languageIdList')
const {
  PLACE_LANTAN,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_LANTANESE,
  alphabetId: ALPHABET_IOKHARIC,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Лантанез',
    genitive: 'Лантанеза',
    instrumental: 'Лантанезом',
    prepositional: 'Лантанезе',
  },
  nameEn: 'Lantanese',
  spokenPlaceList: PLACE_LANTAN,
  isReady: false,
  isRealLang: true,
}
