const {
  CREATURE_DIRE_WOLF,
  CREATURE_GRIFFON,
  CREATURE_PEGASUS,
  CREATURE_PERYTON,
  CREATURE_RHINOCEROS,
  CREATURE_SABER_TOOTHED_TIGER,
} = require('./../../../../../creatureIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_FIND_GREATER_STEED} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_FIND_GREATER_STEED,
  name: 'Поиск высшего скакуна',
  nameEn: 'Find Greater Steed',
  description: `Вы призываете духа, который принимает форму верного величественного скакуна. Появляясь в свободном пространстве в радиусе действия, дух принимает форму, которую Вы выбираете: [грифон](CREATURE:${CREATURE_GRIFFON}), [пегас](CREATURE:${CREATURE_PEGASUS}), [перитон](CREATURE:${CREATURE_PERYTON}), [лютый волк](CREATURE:${CREATURE_DIRE_WOLF}), [носорог](CREATURE:${CREATURE_RHINOCEROS}) или [саблезубый тигр](CREATURE:${CREATURE_SABER_TOOTHED_TIGER}).

Параметры существа соответствуют параметрам выбранной формы, представленным в Бестиарии, хотя на самом деле оно является небожителем, феей или исчадием (на Ваш выбор), а не его обычным существом. Кроме того, если у него Интеллект 5 или меньше, его Интеллект становится равен 6, и он получает способность понимать один язык на Ваш выбор, на котором Вы разговариваете.

Вы контролируете скакуна в бою. Пока он находится в пределах 1 мили от вас, Вы можете общаться с ним телепатически. Находясь верхом на скакуне, Вы можете делать так, что наложенные Вами заклинания, нацеливающиеся только на вас, будут нацелены ещё и на скакуна.

Когда хиты скакуна опускаются до 0, он исчезает, не оставляя физического тела. Вы можете также в любое время отпустить скакуна действием, заставляя его исчезнуть. В любом случае, повторное использование этого заклинания призывает этого же скакуна, восстанавливая хиты до максимума и снимая с него все состояния.

У Вас не может быть больше одного скакуна, призванного этим заклинанием, одновременно. Вы можете действием навсегда отпустить скакуна, освободив его от уз и заставляя исчезнуть.

Всякий раз, когда скакун исчезает, он оставляет предметы, которые нёс или которые были надеты на него.`,
  lvl: 4,
  magicSchoolId: MAGIC_CONJURATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  castTime: {timeId: TIME_MINUTE, count: 10},
  source: {
    id: SOURCE_XGTE,
    page: 162,
  },
}
