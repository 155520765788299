const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_GREATSWORD,
} = require('./../../../../../gearIdList')
const {
  SPELL_HASTE,
  SPELL_WISH,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_BLACKRAZOR,
  MAGIC_ITEM_MOONBLADE,
  MAGIC_ITEM_WHELM,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_BLACKRAZOR,
  name: `Чёрный клинок`,
  nameEn: `Blackrazor`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_GREATSWORD,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `существом с любым мировоззрением кроме принципиального`,
  isSentient: true,
  description: `Скрытый в подземельях горы Белый Шлейф, _Чёрный клинок_ сияет как кусочек ночного неба, заполненный звёздами. Его чёрные ножны украшены кусочками обсидиана.

Вы получаете бонус +3 к броскам атаки и урона, совершённым этим оружием. Оно обладает следующими дополнительными свойствами:

# Пожирание души

Каждый раз, когда Вы опускаете этим мечом хиты существа до 0, он убивает существо и пожирает его душу, если только это не конструкт и не нежить. Существо, чья душа пожрана _Чёрным клинком_, может вернуться к жизни только посредством заклинания [Исполнение желаний](SPELL:${SPELL_WISH}).

Пожрав душу, _Чёрный клинок_ дарует Вам временные хиты, равные максимуму хитов убитого существа. Эти хиты исчезнут через 24 часа. Пока у Вас есть эти временные хиты, и Вы держите _Чёрный клинок_ в руке, Вы совершаете с преимуществом броски атаки, испытания и проверки характеристик.

Если Вы попадаете этим оружием по нежити, Вы получаете урон некротической энергией 1к10, а цель восстанавливает 1к10 хитов. Если этот урон опускает ваши хиты до 0, _Чёрный клинок_ пожирает Вашу душу.

# Охотник на души

Пока Вы держите это оружие в руках, Вы знаете о присутствии существ с размером не меньше Крохотного в пределах 60 футов от Вас, не являющихся конструктами и нежитью. Кроме того, Вы не можете быть очарованными и испуганными.

_Чёрный клинок_ может один раз в день наложить на Вас заклинание [Ускорение](SPELL:${SPELL_HASTE}). Он сам решает, когда это сделать, и сам поддерживает концентрацию.

# Разум

_Чёрный клинок_ — разумное хаотично-нейтральное оружие с Интеллектом 17, Мудростью 10 и Харизмой 19. Он обладает слухом и тёмным зрением в пределах 120 футов.

Это оружие может понимать Общий язык, а также говорить и читать на нём и телепатически общаться с владельцем. У него низкий и раскатистый голос. Пока Вы настроены на _Чёрный клинок_, он также понимает все языки, известные Вам.

# Индивидуальность

_Чёрный клинок_ разговаривает повелительным тоном, словно привыкнув, что ему подчиняются.

Его предназначение в пожирании душ. Ему всё равно чью душу он пожрёт, даже если это будет владелец. Он считает, что все виды материи и энергии происходят от пустоты негативной энергии, и когда-нибудь туда вернутся. Сам же он лишь ускоряет этот процесс.

Несмотря на весь нигилизм, _Чёрный клинок_ чувствует странное родство с [Волной](MAGIC_ITEM:${MAGIC_ITEM_MOONBLADE}) и [Сокрушителем](MAGIC_ITEM:${MAGIC_ITEM_WHELM}), двумя другими оружиями, хранящимися под горой Белый Шлейф. Он хочет, чтобы эти три оружия были вместе и совместно использовались в сражениях, несмотря на то, что он не согласен с [Сокрушителем](MAGIC_ITEM:${MAGIC_ITEM_WHELM}) и считает [Волну](MAGIC_ITEM:${MAGIC_ITEM_MOONBLADE}) занудной.

Жажду душ _Чёрного клинка_ нужно постоянно удовлетворять. Если меч в течение трёх дней не пожрёт ничью душу, на следующем закате у него возникает конфликт с владельцем.`,
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_HASTE,
  ],
  source: {
    id: SOURCE_DMG,
    page: 219,
  },
}
