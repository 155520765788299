const {DAMAGE_FIRE} = require('./../../../damageTypeList')
const {GEAR_BOMB} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {PARAM_DEX} = require('./../../../paramList')
const {
  CAT_EJECTIVES,
  CAT_EXPLOSIVES,
  CAT_GUNPOWDER,
  CAT_TECH_RENAISSANCE,
} = require('./../../../gearCategoryList')
const {
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_BOMB,
  name: 'Бомба',
  nameEn: 'Bomb',
  nameByCase: {
    nominative: 'бомба',
    genitive: 'бомбы',
    accusative: 'бомбу',
    instrumental: 'бомбой',
  },
  description: {
    header: 'Бомба',
    text: `Персонаж может действием поджечь бомбу и бросить её на расстояние до 60 футов. Все существа в пределах 5 футов от этой точки должны пройти испытание Ловкости СЛ 12, иначе они получат 3к6 урона огнём.`,
    source: {
      id: SOURCE_DMG,
      page: 267,
    },
  },
  cost: 15000,
  weight: 1,
  damageType: DAMAGE_FIRE,
  damage: {
    diceCount: 3,
    diceType: 6,
  },
  range: 5,
  saveThrow: {
    type: PARAM_DEX,
    dc: 12,
  },
  genderId: GENDER_FEMALE,
  category: [
    CAT_EJECTIVES,
    CAT_EXPLOSIVES,
    CAT_GUNPOWDER,
    CAT_TECH_RENAISSANCE,
  ],
  weaponPropList: [
    WEAPON_THROWN,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 60,
        max: 60,
      },
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 267,
  },
}
