const {
  FEATURE_PSIONIC_POWER_ROGUE_SOUL_KNIFE,
  FEATURE_PSIONIC_VEIL,
  FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE,
  FEATURE_REND_MIND,
  FEATURE_SNEAK_ATTACK,
  FEATURE_SOUL_BLADES,
} = require('./../../../featureIdList')
const {PC_SUBCLASS_ROGUE_SOULKNIFE} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const psionicPower = require('./../../constants/psionicPower')

module.exports = [
  {
    id: FEATURE_PSIONIC_POWER_ROGUE_SOUL_KNIFE,
    name: `Псионическая сила`,
    nameEn: `Psionic Power`,
    lvl: 3,
    text: `${psionicPower}

## Пси-усиленная сноровка

Когда непсионические навыки Вас подводят, Вы можете помочь себе псионической силой. Провалив проверку характеристики, используя навык или инструмент, которым владеете, Вы можете бросить кость _Псионической силы_ и добавить выпавший результат к проверке, что потенциально может привести к её успеху. Вы тратите кость только при успехе.

## Психический шёпот

Вы можете установить телепатическое общение с другими, что идеально подходит для незаметного проникновения. Вы можете действием выбрать количество видимых Вами существ, не превышающее Вашего бонуса мастерства. Бросьте кость Псионической силы и в течение количества часов, выпавшего на кости, выбранные существа могут телепатически общаться с Вами, а Вы — с ними. Отправляя или получая сообщение (действий не требуется), Вы и другое существо должны находиться в пределах 1 мили друг от друга. Существо не может пользоваться этой телепатией, если не говорит на каких-либо языках. Существо может разорвать телепатическую в любой момент (действий не требуется). Вам и существу не нужно говорить на одном языке, чтобы понимать друг друга.

Первое использование этой способности после длинного отдыха не тратит кость Псионической силы. В прочих случаях кость тратится.`,
    source: {
      id: SOURCE_TCoE,
      page: 95,
    },
  },
  {
    id: FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE,
    name: `Психические клинки`,
    nameEn: `Psychic Blades`,
    lvl: 3,
    text: `Вы можете воплощать псионическую силу мерцающим клинком психической энергии.

Совершая действие Атака, Вы можете проявить психический клинок из свободной руки и атаковать им. Этот магический клинок — простое рукопашное оружие со свойствами «фехтовальное» и «метательное». Его нормальная дальность броска — 60 футов, а максимальной дальности у него нет. При попадании он наносит психический урон 1к6 плюс модификатор характеристики, который Вы использовали для совершения атаки. Клинок исчезает сразу после атаки и не оставляет на цели следов, если наносит урон.

После того, как Вы атаковали клинком, Вы можете на этом же ходу бонусным действием совершить рукопашную или дальнобойную атаку вторым психическим клинком, если Ваша вторая рука свободна для его создания. Кость урона этой атаки — 1к4 вместо 1к6.`,
    source: {
      id: SOURCE_TCoE,
      page: 96,
    },
  },
  {
    id: FEATURE_SOUL_BLADES,
    name: `Духовные клинки`,
    nameEn: `Soul Blades`,
    lvl: 9,
    text: `Ваши [Психические клинки](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE}) теперь отражают Вашу наполненную пси душу, расширяя их применения:

# Самонаводящиеся удары

Когда Вы совершаете атаку [Психическими клинками](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE}) и промахиваетесь, Вы можете бросить кость [Псионических сил](FEATURE:${FEATURE_PSIONIC_POWER_ROGUE_SOUL_KNIFE}) и добавить выпавший результат к броску атаки. Если после этого атака попадает, Ваша кость [Псионических сил](FEATURE:${FEATURE_PSIONIC_POWER_ROGUE_SOUL_KNIFE}) тратится.

# Психическая телепортация

Вы можете бонусным действием потратить и бросить кость [Псионических сил](FEATURE:${FEATURE_PSIONIC_POWER_ROGUE_SOUL_KNIFE}), создавая [Психический клинок](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE}) и метая его в видимое Вами свободное пространство на расстояние, не превышающее десятикратного результата броска кости [Псионических сил](FEATURE:${FEATURE_PSIONIC_POWER_ROGUE_SOUL_KNIFE}), в футах. После этого Вы телепортируетесь в это пространство и клинок исчезает.`,
    source: {
      id: SOURCE_TCoE,
      page: 96,
    },
  },
  {
    id: FEATURE_PSIONIC_VEIL,
    name: `Психическая вуаль`,
    nameEn: `Psionic Veil`,
    lvl: 13,
    text: `Вы можете соткать завесу психического шума и замаскироваться в ней.

Вы можете действием магически стать Невидимым вместе со всем, что несёте и носите, на 1 час или пока Вы не отмените этот эффект (действий не требуется). Эта невидимость заканчивается, если Вы наносите существу урон или заставляете его пройти испытание.

Вы не можете снова использовать эту способность, пока не завершите длинный отдых, если только не потратите для этого кость Псионических сил.`,
    source: {
      id: SOURCE_TCoE,
      page: 96,
    },
  },
  {
    id: FEATURE_REND_MIND,
    name: `Разрыв разума`,
    nameEn: `Rend Mind`,
    lvl: 17,
    text: `Вы можете пронзить [Психическими клинками](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE}) сам разум существа.

Когда Вы наносите по существу [Психическими клинками](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE}) урон от [Скрытой атаки](FEATURE:${FEATURE_SNEAK_ATTACK}), Вы можете заставить цель пройти испытание Мудрости (СЛ равна 8 + Ваш бонус мастерства + Ваш модификатор Ловкости). При провале цель Ошеломляется на 1 минуту. Ошеломлённая цель может повторять испытание в конце каждого своего хода, завершая эффект на себе при успехе.

Вы не можете снова использовать эту способность, пока не завершите длинный отдых, если только не потратите для этого три кости Псионических сил.`,
    source: {
      id: SOURCE_TCoE,
      page: 96,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_SOULKNIFE,
  })
)
