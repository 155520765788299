const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_ORC,
  CREATURE_ORC_WAR_CHIEF,
  CREATURE_OROG,
} = require('./../../../../creatureIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_ORC,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  orcDescription,
  orcNote,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  GEAR_GREATAXE,
  GEAR_JAVELIN,
  GEAR_PLATE_ARMOR,
} = require('./../../../../gearIdList')
const {ABILITY_AGGRESSIVE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Орог',
  nameEn: 'Orog',
  id: CREATURE_OROG,
  description: [
    {
      header: 'Ороги',
      text: `Ороги это орки, одарённые удивительным интеллектом, который [обычные орки](CREATURE:${CREATURE_ORC}) считают даром богини Лутик. Как и их богиня, ороги предпочитают жить под землёй, но нехватка пищи часто приводит их на поверхность для охоты. Орки уважают силу и хитрость орогов, и один орог может возглавить военную банду орков.`,
      source: {
        id: SOURCE_MM,
        page: 230,
      },
    },
    {
      header: 'Сильные и умные',
      text: `Ороги используют свою силу, чтобы запугивать других орков, и свой интеллект, чтобы удивлять врагов на поле боя. Многие самоуверенные командиры эльфов, людей и дварфов наблюдали как «простой» военачальник орков выполняет хитрый манёвр с фланга и уничтожает вражескую силу, и им невдомёк, что им противостоял орог.

Когда ороги встречаются в большом количестве, они собирают свои собственные отряды в составе большой орды. Они всегда на передовой линии атаки. Опираясь на превосходство в силе и знание тактики, ороги преодолевают всё, что встаёт на их пути.

Некоторые племена орков специально ищут орогов для усиления своих рядов. Превосходство орогов делает их идеальными лидерами и смертельными противниками для [боевых вождей](CREATURE:${CREATURE_ORC_WAR_CHIEF}), которые должны опасаться вероломства орогов.`,
      source: {
        id: SOURCE_MM,
        page: 230,
      },
    },
    {
      header: 'Отрешённые убийцы',
      text: `Не желая ничего кроме разрубания своих врагов на куски, своим присутствием ороги являют ужас на поле боя. У них нет привязанности к родителям, братьям и сёстрам, а также им чужды понятия любви и преданности. Они поклоняются орочьему пантеону богов — Груумшу и Лутик — потому что верят, что они имеют запредельную силу, а физическая мощь это всё, что они уважают.`,
      source: {
        id: SOURCE_MM,
        page: 230,
      },
    },
    {
      header: 'Слуги тьмы',
      text: `Не имеющие доверия среди орков, некоторые ороги образуют отряды наёмников, которые продают свои услуги тому, кто заплатит больше остальных. До тех пор, пока им платят, наёмники ороги служат элитными воинами и ударными отрядами для злых колдунов, порочных великанов и других злодеев.`,
      source: {
        id: SOURCE_MM,
        page: 230,
      },
    },

    ...orcDescription,
  ],
  note: orcNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ORC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 230,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 18,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_DOUBLE,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ORC,
    LANG_COMMON,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AGGRESSIVE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Секирой_.`,
    },
    {
      gearId: GEAR_GREATAXE,
    },
    {
      gearId: GEAR_JAVELIN,
    },
  ],
  genderId: GENDER_MALE,
}
