const {CAT_WEAPON} = require('./../../../../gearCategoryList')
const {CREATURE_GRIFFON} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {MAGIC_ITEM_STORM_BOOMERANG} = require('./../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_STORM_BOOMERANG,
  name: 'Грозовой бумеранг',
  nameEn: 'Storm Boomerang',
  type: MGC_TYPE_WEAPON,
  gearCategory: CAT_WEAPON,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Это дальнобойное оружие, сделанное из кости [грифона](CREATURE:${CREATURE_GRIFFON}), украшено символом стихийного воздуха. _Бумеранг_ имеет дальность броска 60/120 футов, и любое существо, владеющее метательным копьём, также владеет и этим оружием. При попадании _бумеранг_ наносит дробящий урон 1к4 и урон звуком 3к4. Кроме того, цель должна пройти испытание Телосложения СЛ 10, иначе станет ошеломлённой до конца своего следующего хода. В случае промаха _бумеранг_ возвращается в руку владельца.

После того, как _бумеранг_ нанесёт урон звуком, он теряет способность наносить урон этого типа, как и ошеломлять цель. Если _бумеранг_ перезарядить внутри стихийного узла воздуха, то утраченные свойства возвращаются к нему. Перезарядка занимает минимум 1 час.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PotA,
    page: 227,
  },
}
