const {
    SOURCE_TCoE,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    SPELL_SUMMON_UNDEAD,
  } = require('./../../../../spellIdList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_SKELETON,
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    LANG_ITS_SUMMONER,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_RANGE_SPELL,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    CREATURE_SKELETAL_UNDEAD_SPIRIT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух скелетной нежити',
  nameEn: 'Skeletal Undead Spirit',
  id: CREATURE_SKELETAL_UNDEAD_SPIRIT,
  createdBySpellId: SPELL_SUMMON_UNDEAD,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TCoE,
    page: 164,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `20 + 10-кратный круг заклинания выше 3-го`,
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_SUMMONER,
      onlyUnderstands: true,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания.`,
    },
    {
      name: 'Могильный снаряд',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 150,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
}
