import React from 'react'
import classNames from 'classnames'

import './AdsPlacerStyles.less'

const AdsPlacer = (
  {
    blockId,
    elementId,
    className = '',
    showSkeleton = false,
    ...rest
  }
) => (
  <div
    className={classNames(
      'AdsPlacer',
      `AdsPlacer-blockId_${blockId}`,
      `AdsPlacer-elementId_${elementId}`,
      {
        'AdsPlacer-skeleton': showSkeleton,
      },
      className,
    )}
    {...rest}
  >
    <div id={elementId}/>
  </div>
)

export default AdsPlacer
