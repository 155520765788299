const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {
  CREATURE_ELDER_BRAIN,
  CREATURE_GITHYANKI_WARRIOR,
  CREATURE_GITHZERAI_MONK,
  CREATURE_GRIMLOCK,
  CREATURE_INTELLECT_DEVOURER,
  CREATURE_KUO_TOA,
  CREATURE_OGRE,
  CREATURE_QUAGGOTH,
} = require('./../../creatureIdList')
const qualithDescriptionList = require('./qualith')

module.exports = [
  {
    header: `Свежеватели разума`,
    text: `Свежеватели разума, также называемые иллитидами, — настоящее бедствие для разумных существ бесчисленного множества миров. Это псионические мучители, работорговцы и межпространственные скитальцы. Эти коварные манипуляторы могут уничтожать целые расы ради своих извращённых целей. Четыре щупальца отходят от напоминающей осьминога голову, извиваясь в предвкушении трапезы, когда рядом есть разумное существо.

Тысячелетия назад иллитиды контролировали империи, которые простирались на множество миров. Они подчинили и исказили целые расы человекоподобных рабов, включая [гитьянок](CREATURE:${CREATURE_GITHYANKI_WARRIOR}) и [гитцераев](CREATURE:${CREATURE_GITHZERAI_MONK}), [гримлоков](CREATURE:${CREATURE_GRIMLOCK}) и [куо-тоа](CREATURE:${CREATURE_KUO_TOA}). Обладающие коллективным сознанием иллитиды строят настолько далеко идущие планы, насколько их непостижимые умы могут задумать.

После падения этих империй общества иллитидов Материального плана обосновались в Подземье.`,
    source: {
      id: SOURCE_MM,
      page: 259,
    },
  },
  {
    header: `Иллитиды`,
    text: `Псионические тираны, работорговцы и путешественники по измерениям, питающиеся мозгами живых существ.

Большая часть свежевателей разума, также известных как иллитиды, распределена по колониям, сплочённым вокруг [старшего мозга](CREATURE:${CREATURE_ELDER_BRAIN}), где они трудятся над реализацией Великого плана — вощродить свои империи, охватывавшие многие миры.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: `Псионические приказы`,
    text: `Свежеватели разума владеют псионической энергией в такой степени, что могут контролировать разумы существ, таких как [троглодиты](CREATURE:troglodyte), [гримлоки](CREATURE:${CREATURE_GRIMLOCK}), [квагготы](CREATURE:${CREATURE_QUAGGOTH}) и [огры](CREATURE:${CREATURE_OGRE}). Иллитиды предпочитают общаться посредством телепатии и используют телепатию для отдачи команд своим рабам.

Когда иллитид встречает сильное сопротивление, он пытается избежать боя, например, приказывая своим рабам атаковать. Словно физические продолжения мыслей иллитида, эти рабы вклиниваются между свежевателем разума и его врагами, жертвуя своими жизнями, чтобы их хозяин мог спастись.`,
    source: {
      id: SOURCE_MM,
      page: 259,
    },
  },
  {
    header: `Поселения коллективного разума`,
    text: `Одинокие свежеватели разума вероятнее всего являются бродягами или изгоями. Большинство иллитидов принадлежит к колонии свежевателей разума, преданных [старшему мозгу](CREATURE:${CREATURE_ELDER_BRAIN}) — огромному похожему на мозг существу, которое находится в солёном бассейне вблизи центра поселения свежевателей разума. Из этого бассейна старший мозг телепатически диктует свои желания каждому отдельному свежевателю разума на расстоянии до 5 миль и способен одновременно поддерживать несколько мысленных бесед.`,
    source: {
      id: SOURCE_MM,
      page: 259,
    },
  },
  {
    header: `Голод разума`,
    text: `Иллитиды существуют, питаясь мозгами гуманоидов. Мозги обеспечивают их энзимами, гормонами и психической энергией, необходимой для выживания. Здоровый иллитид на богатой мозгами диете выделяет тонкий слой слизи, который покрывает его светло-фиолетовую кожу.

Иллитид испытывает эйфорию, пожирая мозг гуманоида вместе с его воспоминаниями, его личностью, его внутренними страхами. Иногда они просто вынимают мозг, вместо того, чтобы съесть, и в дальнейшем используют его в своих странных экспериментах, или трансформируют его в [пожирателя интеллекта](CREATURE:${CREATURE_INTELLECT_DEVOURER}).`,
    source: {
      id: SOURCE_MM,
      page: 259,
    },
  },
  ...qualithDescriptionList,
]
