const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_BLINDHEIM} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_BLINDHEIM,
  nameEn: 'Blindheim',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'слепарь',
      genitive: 'слепаря',
      dative: 'слепарю',
      accusative: 'слепаря',
      instrumental: 'слепарём',
      prepositional: 'слепаре',
    },
    plural: {
      nominative: 'слепари',
      genitive: 'слепарей',
      dative: 'слепарям',
      accusative: 'слепарей',
      instrumental: 'слепарями',
      prepositional: 'слепарях',
    },
  },
}
