const {artificerSpellCircleList} = require('./../../../spellCircleByCasterType')
const {extraAttackTemplate} = require('./../../featureCommonRawList')
const {PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24} = require('./../../../pcSubClassIdList')
const {GEAR_SMITHS_TOOLS} = require('./../../../gearIdList')
const {SOURCE_UA_2020_02_24_SUBCLASSES_PART_3} = require('./../../../sourceList')
const {
  SPELL_FIRE_SHIELD,
  SPELL_GREATER_INVISIBILITY,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGIC_MISSILE,
  SPELL_MIRROR_IMAGE,
  SPELL_PASSWALL,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_WALL_OF_FORCE,
} = require('./../../../spellIdList')

const {
  FEATURE_ARMOR_MODEL_2020_02_24,
  FEATURE_ARMOR_MODIFICATIONS_2020_02_24,
  FEATURE_ARMORER_SPELLS_2020_02_24,
  FEATURE_EXTRA_ATTACK_ARTIFICER_ARMORER_2020_02_24,
  FEATURE_INFUSE_ITEM,
  FEATURE_PERFECTED_ARMOR_2020_02_24,
  FEATURE_POWER_ARMOR_2020_02_24,
  FEATURE_TOOLS_OF_THE_TRADE_2020_02_24,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ARMORER_SPELLS_2020_02_24,
    name: `Заклинания бронника`,
    nameEn: `Armorer Spells`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24,
    text: `У Вас всегда подготовлены некоторые заклинания, когда Вы достигаете определённого уровня в этом классе.

Они не учитываются в количестве заклинаний изобретателя, которые Вы можете подготавливать и для Вас считаются заклинаниями изобретателя.`,
    spellCircleLvl: artificerSpellCircleList,
    spellIdList: [
      SPELL_FIRE_SHIELD,
      SPELL_GREATER_INVISIBILITY,
      SPELL_HYPNOTIC_PATTERN,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGIC_MISSILE,
      SPELL_MIRROR_IMAGE,
      SPELL_PASSWALL,
      SPELL_SHATTER,
      SPELL_SHIELD,
      SPELL_WALL_OF_FORCE,
    ],
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 1,
    },
  },
  {
    id: FEATURE_TOOLS_OF_THE_TRADE_2020_02_24,
    name: `Снаряжение`,
    nameEn: `Tools of the Trade`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24,
    text: `Вы получаете владение тяжёлыми доспехами.

Кроме того, Вы получаете владение [инструментами кузнеца](GEAR:${GEAR_SMITHS_TOOLS}). Если Вы уже ими владеете, то получаете владение одним другим видом ремесленных инструментов по Вашему выбору.`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 1,
    },
  },
  {
    id: FEATURE_POWER_ARMOR_2020_02_24,
    name: `Силовая броня`,
    nameEn: `Power Armor`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24,
    text: `Ваши исследования в металлургии позволили делать доспех проводником своей магии изобретателя. Вы можете действием, держа в руках [инструменты кузнеца](GEAR:${GEAR_SMITHS_TOOLS}), превратить носимый Вами тяжёлый доспех в _Силовую броню_.

Нося _Силовую броню_, Вы получаете следующие преимущества:

* Если у доспеха есть требования к Силе, то _Силовая броня_ их лишена.
* Вы можете использовать _Силовую броню_ как заклинательную фокусировку своих заклинаний изобретателя.
* _Силовая броня_ прикрепляется к Вам и не может быть снята против Вашей воли. Она также расширяется покрывая всё Ваше тело, и заменяет любые отсутствующие конечности, функционируя так же, как и часть тела, которую заменяет.

Доспех остаётся _Силовой бронёй_ пока Вы его не снимете, не наденете другой доспех или не умрёте.`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 1,
    },
  },
  {
    id: FEATURE_ARMOR_MODEL_2020_02_24,
    name: `Модели брони`,
    nameEn: `Armor Model`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24,
    text: `Вы можете настраивать свою [Силовую броню](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}). Выберите одну из следующих моделей: _Страж_ или _Лазутчик_. Выбранная модель даёт Вам особые преимущества при ношении.

Каждая модель включает в себя особое оружие. Когда Вы атакуете этим оружием, то можете использовать для бросков атаки и урона модификатор Интеллекта вместо Силы или Ловкости.

Вы можете изменить модель своей силовой брони после короткого или длинного отдыха, если у Вас есть [инструменты кузнеца](GEAR:${GEAR_SMITHS_TOOLS}).

# Страж

Ваша _Силовая броня_ спроектирована для войны на передовой. Она получает следующие особенности:

## Гром-перчатки

Каждый Ваш бронированный кулак считается простым оружием ближнего боя, и каждый из них наносит 1к8 урона звуком при попадании. Существо, по которому Вы попали ударом _Гром-перчатки_, получает до начала Вашего следующего хода помеху на броски атаки по всем целям, кроме Вас, так как [Силовая броня](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}) магически излучает отвлекающий импульс, когда это существо атакует кого-то ещё.

## Защитное поле

Вы можете каждый ход бонусным действием получать временные хиты, количество которых равно Вашему уровню в этом классе, заменяя любые временные хиты, которые у Вас уже есть. Вы теряете эти временные очки жизни, если снимаете [Силовую броню](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}).

# Лазутчик

Вы проектируете [Силовую броню](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}) для аккуратных действий. Она получает следующие особенности:

## Молниемёт

На одном из бронированных кулаков или на груди Вашей [Силовой брони](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}) (на Ваш выбор) появляется сияющий камень. Оно считается простым дальнобойным оружием с дальностью 90/300 футов, наносящим 1к6 урона электричеством. Один раз на своём ход, поражая им существо, Вы можете нанести этой цели дополнительно 1к6 урона электричеством.

## Усиленные ноги

Ваша скорость ходьбы увеличивается на 5 футов.

## Вторая кожа

Вес [Силовой брони](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}) становится незначительным и она становятся настолько удобной, что её теперь можно носить под одеждой. Если броня обычно накладывает помеху на проверки Ловкости (Скрытности), то [Силовая броня](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}) — нет.`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 2,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_ARTIFICER_ARMORER_2020_02_24,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 2,
    },
  },
  {
    id: FEATURE_ARMOR_MODIFICATIONS_2020_02_24,
    name: `Модификация брони`,
    nameEn: `Armor Modifications`,
    lvl: 9,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24,
    text: `Вы узнаете, как использовать инфузии изобретателя, чтобы специально модифицировать свою [Силовую броню](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}).

Части [Силовой брони](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}) теперь считается отдельными предметами для Вашей способности _Инфузирование предметов_: 

* ботинки,
* нагрудник,
* наручи,
* оружие.

Каждая из этих отдельных частей может содержать одну из Ваших [Инфузий](FEATURE:${FEATURE_INFUSE_ITEM}). Кроме того, максимальное число предметов, которые Вы можете [инфузировать](FEATURE:${FEATURE_INFUSE_ITEM}) одновременно, увеличивается на 2, но эти дополнительные предметы должны быть частью Вашей [Силовой брони](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}).`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 2,
    },
  },
  {
    id: FEATURE_PERFECTED_ARMOR_2020_02_24,
    name: `Идеальная броня`,
    nameEn: `Perfected Armor`,
    lvl: 15,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARMORER_2020_02_24,
    text: `Ваша [Силовая броня](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}) получает дополнительные преимущества в зависимости от модели, как показано ниже.

# Страж

Работа над энергосистемой [Силовой брони](FEATURE:${FEATURE_POWER_ARMOR_2020_02_24}) приводит Вас к открытию мощной тянущей силы. Когда видимое Вами существо заканчивает свой ход в пределах 30 футов от Вас, Вы можете реакцией заставить существо пройти испытание Силы, или оно будет притянуто в свободное пространство на 30 футов к Вам. Если Вы притянете его в пространство в пределах 5 футов от себя, Вы можете нанести по нему рукопашную атаку оружием как часть этой реакции.

Вы можете использовать эту реакцию число раз, равное Вашему модификатору Интеллекта (минимум один раз). Вы восстанавливаете все израсходованные использования, когда заканчиваете длинный отдых.

# Лазутчик

Любое существо, которое получает урон электричеством от Вашего _Молниемёта_, светится до начала Вашего следующего хода. Оно излучает тусклый свет в радиусе 5 футов, и следующая атака другого существа (кроме Вас) по нему получает преимущество. Если эта атака попадает, она наносит дополнительно 1к6 урона электричеством.`,
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 2,
    },
  },
]
