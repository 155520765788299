const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_IOUN_STONE_ABSORPTION,
  MAGIC_ITEM_IOUN_STONE_AGILITY,
  MAGIC_ITEM_IOUN_STONE_AWARENESS,
  MAGIC_ITEM_IOUN_STONE_FORTITUDE,
  MAGIC_ITEM_IOUN_STONE_GREATER_ABSORPTION,
  MAGIC_ITEM_IOUN_STONE_INSIGHT,
  MAGIC_ITEM_IOUN_STONE_INTELLECT,
  MAGIC_ITEM_IOUN_STONE_LEADERSHIP,
  MAGIC_ITEM_IOUN_STONE_MASTERY,
  MAGIC_ITEM_IOUN_STONE_PROTECTION,
  MAGIC_ITEM_IOUN_STONE_REGENERATION,
  MAGIC_ITEM_IOUN_STONE_RESERVE,
  MAGIC_ITEM_IOUN_STONE_STRENGTH,
  MAGIC_ITEM_IOUN_STONE_SUSTENANCE,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_IOUN_STONE_GREATER_ABSORPTION,
    rarity: MGC_RARITY_LEGENDARY,
    stoneDescription: 'эллипсоид с зелёными и лавандовыми прожилками',
    genderId: GENDER_MALE,
    stoneTypeName: [`Большое поглощение`, 'Greater Absorption'],
    description: `Пока этот эллипсоид с зелёными и лавандовыми прожилками вращается вокруг Вашей головы, Вы можете реакцией отменить заклинание с уровнем не больше 8, наложенное видимым Вами существом и нацеленное только на Вас.

Как только камень отменит 50 уровней заклинаний, он выгорит и станет тускло-серым, потеряв всю магию. Если Вы становитесь целью заклинания, чей уровень настолько большой, что камень не может поглотить его целиком, это заклинание нельзя отменить.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_AWARENESS,
    rarity: MGC_RARITY_RARE,
    stoneDescription: 'тёмно-синий ромбоид',
    genderId: GENDER_MALE,
    stoneTypeName: [`Восприятие`, 'Awareness'],
    description: `Вы не можете быть захвачены врасплох, пока этот тёмно-синий ромбоид вращается вокруг Вашей головы.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_PROTECTION,
    rarity: MGC_RARITY_RARE,
    stoneDescription: 'серо-розовая призма',
    genderId: GENDER_FEMALE,
    stoneTypeName: [`Защита`, 'Protection'],
    description: `Вы получаете бонус +1 к КД, пока эта серо-розовая призма вращается вокруг Вашей головы.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_LEADERSHIP,
    rarity: MGC_RARITY_VERY_RARE,
    stoneDescription: 'сфера с розовыми и зелёными прожилками',
    genderId: GENDER_FEMALE,
    stoneTypeName: [`Лидерство`, 'Leadership'],
    description: `Ваше значение Харизмы увеличивается на 2, с максимумом 20, пока эта сфера с розовыми и зелёными прожилками вращается вокруг Вашей головы.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_MASTERY,
    rarity: MGC_RARITY_LEGENDARY,
    stoneDescription: 'бледно-зелёная призма',
    genderId: GENDER_FEMALE,
    stoneTypeName: [`Мастерство`, 'Mastery'],
    description: `Ваш бонус мастерства увеличивается на 1, пока эта бледно-зелёная призма вращается вокруг Вашей головы.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_SUSTENANCE,
    rarity: MGC_RARITY_RARE,
    stoneDescription: 'прозрачный веретенообразный камень',
    genderId: GENDER_MALE,
    stoneTypeName: [`Питание`, 'Sustenance'],
    description: `Вам не нужно ни есть и ни пить, пока этот прозрачный веретенообразный камень вращается вокруг Вашей головы. `,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_ABSORPTION,
    rarity: MGC_RARITY_VERY_RARE,
    stoneDescription: 'бледнолавандовый эллипсоид',
    genderId: GENDER_MALE,
    stoneTypeName: [`Поглощение`, 'Absorption'],
    description: `Пока этот бледнолавандовый эллипсоид вращается вокруг Вашей головы, Вы можете реакцией отменить заклинание с уровнем не больше 4, наложенное видимым Вами существом и нацеленное только на Вас.

Как только камень отменит 20 уровней заклинаний, он выгорит и станет тускло-серым, потеряв всю магию. Если Вы становитесь целью заклинания, чей уровень настолько большой, что камень не может поглотить его целиком, это заклинание нельзя отменить.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_AGILITY,
    rarity: MGC_RARITY_VERY_RARE,
    stoneDescription: 'тёмно-красная сфера',
    genderId: GENDER_FEMALE,
    stoneTypeName: [`Проворство`, 'Agility'],
    description: `Ваше значение Ловкости увеличивается на 2, с максимумом 20, пока эта тёмно-красная сфера вращается вокруг Вашей головы.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_INSIGHT,
    rarity: MGC_RARITY_VERY_RARE,
    stoneDescription: 'ярко-синяя сфера',
    genderId: GENDER_FEMALE,
    stoneTypeName: [`Проницательность`, 'Insight'],
    description: `Ваше значение Мудрости увеличивается на 2, с максимумом 20, пока эта ярко-синяя сфера вращается вокруг Вашей головы.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_INTELLECT,
    rarity: MGC_RARITY_VERY_RARE,
    stoneDescription: 'сфера с алыми и синими прожилками',
    genderId: GENDER_FEMALE,
    stoneTypeName: [`Рассудок`, 'Intellect'],
    description: `Ваше значение Интеллекта увеличивается на 2, с максимумом 20, пока эта сфера с алыми и синими прожилками вращается вокруг Вашей головы.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_REGENERATION,
    rarity: MGC_RARITY_LEGENDARY,
    stoneDescription: 'жемчужно-белый веретёнообразный камень',
    genderId: GENDER_MALE,
    stoneTypeName: [`Регенерация`, 'Regeneration'],
    description: `Вы восстанавливаете 15 хитов в конце каждого часа, в течение которого этот жемчужно-белый веретёнообразный камень вращается вокруг Вашей головы, при условии, что у Вас есть как минимум 1 хит.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_RESERVE,
    rarity: MGC_RARITY_RARE,
    stoneDescription: 'ярко-фиолетовая призма',
    genderId: GENDER_FEMALE,
    stoneTypeName: [`Резерв`, 'Reserve'],
    description: `Эта ярко-фиолетовая призма хранит заклинания, наложенные в неё, пока Вы не используете их. Этот камень может хранить до 3 уровней заклинаний одновременно. Когда его находят, он хранит 1к4−1 уровень заклинаний, выбранных Мастером.

Любое существо может заложить в камень заклинание с уровнем от 1 до 3, касаясь его при накладывании. Заклинание не вступает в силу, а просто помещается в камень. Если камень не может уместить заклинание, заклинание тратится безо всякого эффекта. Занимаемое число уровней определяется уровнем, с которым заклинание было наложено.

Пока этот камень вращается вокруг Вашей головы, Вы можете наложить заклинание, хранящееся в нём. Заклинание использует круг ячейки, СЛ испытания, бонус атаки заклинанием и базовую характеристику исходного заклинателя, но во всём остальном считается, что заклинание наложили Вы. Наложенное заклинание перестаёт храниться в камне, освобождая пространство.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_STRENGTH,
    rarity: MGC_RARITY_VERY_RARE,
    stoneDescription: 'бледно-синий ромбоид',
    genderId: GENDER_MALE,
    stoneTypeName: [`Сила`, 'Strength'],
    description: `Ваше значение Силы увеличивается на 2, с максимумом 20, пока этот бледно-синий ромбоид вращается вокруг Вашей головы.`,
  },
  {
    id: MAGIC_ITEM_IOUN_STONE_FORTITUDE,
    rarity: MGC_RARITY_VERY_RARE,
    stoneDescription: 'розовый ромбоид',
    genderId: GENDER_MALE,
    stoneTypeName: [`Стойкость`, 'Fortitude'],
    description: `Ваше значение Телосложения увеличивается на 2, с максимумом 20, пока этот розовый ромбоид вращается вокруг Вашей головы.`,
  },
]
