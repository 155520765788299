const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_11,
  } = require('./../../../../crList'),
  {
    SIZE_HUGE,
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_DRACONIC,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_ELECTRICITY,
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_BEHIR,
    CREATURE_STORM_GIANT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Бехир',
  nameEn: 'Behir',
  id: CREATURE_BEHIR,
  description: `Змееподобный бехир ползает по полу и лазает по стенам, чтобы схватить свою добычу. Его электрическое дыхание может сжечь большинство существ, а более сильных противников он сжимает, обернувшись вокруг них кольцами, и съедает заживо. Внешне бехир похож на смесь чудовищной многоножки и крокодила. Его чешуйчатая шкура переливается от ультрамарина до тёмно-синего цвета, а на брюхе она голубая.

# Пещерный хищник

Логова бехиров располагаются в недоступных другим существам местах, достичь которых можно только преодолев тяжёлый подъем. Глубокие норы, пещеры в отвесных стенах скал и каверны, попасть в которые можно только по узким извилистым туннелям — всё это идеальные места для засад бехиров. Дюжина ног бехира позволяет ему с лёгкостью добираться до своего логова. Когда он не лазает, он двигается ещё быстрее, сложив ноги вдоль туловища, и скользя как змея.

Проглотив свою жертву целиком, бехир впадает в дрёму, пока полностью не переварит свою жертву. В это время он выбирает тихое место, где вторгшиеся в его владения враги не найдут его.

# Враги драконов

Давным-давно великаны и драконы сошлись в, казалось бы, бесконечной войне. [Штормовые великаны](CREATURE:${CREATURE_STORM_GIANT}) создали первых бехиров как оружие против драконов, поэтому ненависть к драконам у бехиров в крови.

Бехир никогда не устроит логово там, где обитает дракон. Если дракон попытается устроить логово в нескольких дюжинах миль от логова бехира, тот постарается либо убить дракона, либо прогнать его подальше. Только если дракон окажется сильнее бехира, тот отступит, чтобы найти новое место для логова себе.`,
  note: {
    text: `Я уже съел сегодня три гигантских летучих мыши, шесть троглодитов и иллитида. Но это ничего. В моём брюхе найдётся место для тебя и твоих друзей`,
    author: `бехир Ллуд, столкнувшийся с искателями приключений в Затерянных Пещерах Тсойканта`,
  },
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 24,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  cr: CR_11,
  speed: {
    [SPEED_WALK]: 50,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 18,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 90,
    },
  ],
  languageList: [
    LANG_DRACONIC,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Сжиманием_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Сжимание',
      description: `Цель становится схваченной (СЛ высвобождения 16), если ★СУЩЕСТВО★ пока не сжимает никакое существо. Пока цель схвачена, она обездвижена.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            size: {
              max: SIZE_LARGE,
            },
          },
        },
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 10,
            diceBonus: 6,
          },
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 10,
            diceBonus: 6,
          },
        ],
      },
    },
    {
      name: 'Электрическое дыхание',
      description: `★СУЩЕСТВО★ выдыхает молнию длиной 20 футов и шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 16, получая урон электричеством 66 (12к10) при провале, или половину этого урона при успехе.`,
      retore: {
        min: 5,
        max: 6,
      },
    },
    {
      name: 'Проглатывание',
      description: `★СУЩЕСТВО★ совершает одну атаку _Укусом_ по существу с размером не больше Среднего, схваченному ★им★. Если эта атака попадает, существо становится проглоченным и перестаёт быть схваченным. Будучи проглоченным, существо ослеплено и обездвижено, и обладает полным укрытием от атак и прочих эффектов, исходящих снаружи ★СУЩЕСТВО★, и получает урон кислотой 21 (6к6) в начале каждого хода ★СУЩЕСТВО★. У ★СУЩЕСТВО★ может быть проглочено только одно существо одновременно.

Если ★СУЩЕСТВО★ получает за один ход 30 или больше урона от проглоченного существа, ★СУЩЕСТВО★ ★должен★ в конце этого хода пройти испытание Телосложения СЛ 14, иначе отрыгнёт существо, которое падает ничком в пространстве в пределах 10 футов от ★СУЩЕСТВО★. Если ★СУЩЕСТВО★ умирает, проглоченное существо перестаёт быть обездвиженым ★им★, и может высвободиться из трупа, потратив 15 футов перемещения, падая при выходе ничком.`,
    },
  ],
  genderId: GENDER_MALE,
}
