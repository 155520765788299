const {
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {MAGIC_DIVINATION} = require('./../../../../../magicList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_GIFT_OF_ALACRITY} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GIFT_OF_ALACRITY,
  name: 'Дар готовности',
  nameEn: 'Gift of Alacrity',
  description: `Вы касаетесь согласного существа.

На время действия заклинания цель может добавлять 1к8 к броскам инициативы.`,
  lvl: 1,
  magicSchoolId: MAGIC_DIVINATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  castTime: { timeId: TIME_MINUTE, count: 1 },
  duration: { timeId: TIME_HOUR, count: 8 },
  isDunamisBased: true,
  source: {
    id: SOURCE_EGTW,
    page: 187,
  },
}
