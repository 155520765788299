const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {ATTACK_RANGE_SPELL} = require('./../../../../../attackTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SOURCE_PHB} = require('./../../../../../sourceList')
const {SPELL_CHROMATIC_ORB} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_CHROMATIC_ORB,
  name: 'Цветной шарик',
  nameAlt: 'Хроматический шар',
  nameEn: `Chromatic Orb`,
  description: `Вы бросаете 4-дюймовую (10-сантиметровую) сферу энергии в существо, которое видите в пределах дистанции. Выберите звук, кислоту, огонь, холод, электричество или яд при создании сферы, а затем совершите по цели дальнобойную атаку заклинанием. Если атака попадает, существо получает урон 3к8 выбранного вида.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `алмаз, стоящий как минимум 50 зм`,
  effect: {
    attackType: ATTACK_RANGE_SPELL,
    damage: {
      type: [
        DAMAGE_THUNDER,
        DAMAGE_ACID,
        DAMAGE_FIRE,
        DAMAGE_COLD,
        DAMAGE_ELECTRICITY,
      ],
      diceCount: 3,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_PHB,
    page: 288,
  },
}
