module.exports = `
| Уровень |1|2|3|4|5|
|---------|-|-|-|-|-|
|  1      | | | | | |
|  2      |2| | | | |
|  3–4    |3| | | | |
|  5–6    |4|2| | | |
|  7–8    |4|3| | | |
|  9–10   |4|3|2| | |
| 11–12   |4|3|3| | |
| 13–14   |4|3|3|1| |
| 15–16   |4|3|3|2| |
| 17–18   |4|3|3|3|1|
| 19–20   |4|3|3|3|2|
`
