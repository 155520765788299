const {ALPHABET_DETHEK} = require('./../../alphabetList')
const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {primordialLanguageDescriptionList} = require('./../../textCommonParts')
const {SOURCE_DND3_5_MotP} = require('./../../sourceList')
const {
  LANG_PRIMORDIAL,
  LANG_TERRAN,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_TERRAN,
  alphabetId: ALPHABET_DETHEK,
  type: LANG_TYPE_EXOTIC,
  dialectOf: LANG_PRIMORDIAL,
  name: {
    nominative: 'Терран',
    genitive: 'Террана',
    instrumental: 'Терраном',
    prepositional: 'Терране',
  },
  nameEn: 'Terran',
  typicalSpeakers: 'Обитатели Стихийного Плана Земли',
  description: [
    {
      text: `Родной язык для Стихийного Плана Земли — Терран, низкий гулкий язык, от которого слушателя трясёт небольшой дрожью.`,
      source: {
        id: SOURCE_DND3_5_MotP,
        page: 71,
      },
    },
    ...primordialLanguageDescriptionList,
  ],
  isRealLang: true,
  isReady: true,
}
