const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_PEACE_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {
  SPELL_AID,
  SPELL_AURA_OF_PURITY,
  SPELL_BEACON_OF_HOPE,
  SPELL_GREATER_RESTORATION,
  SPELL_HEROISM,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_SANCTUARY,
  SPELL_SENDING,
  SPELL_WARDING_BOND,
} = require('./../../../spellIdList')
const {
  FEATURE_CHANNEL_DIVINITY_BALM_OF_PEACE,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_EMBOLDENING_BOND,
  FEATURE_EXPANSIVE_BOND,
  FEATURE_IMPLEMENT_OF_PEACE,
  FEATURE_PEACE_DOMAIN_SPELLS,
  FEATURE_POTENT_SPELLCASTING_PEACE_DOMAIN,
  FEATURE_PROTECTIVE_BOND,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_PEACE_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_AID,
      SPELL_AURA_OF_PURITY,
      SPELL_BEACON_OF_HOPE,
      SPELL_GREATER_RESTORATION,
      SPELL_HEROISM,
      SPELL_OTILUKE_S_RESILIENT_SPHERE,
      SPELL_RARY_S_TELEPATHIC_BOND,
      SPELL_SANCTUARY,
      SPELL_SENDING,
      SPELL_WARDING_BOND,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 45,
    },
  },
  {
    id: FEATURE_IMPLEMENT_OF_PEACE,
    name: `Орудие мира`,
    nameEn: `Implement of Peace`,
    lvl: 1,
    text: `Вы получаете владение Проницательностью, Выступлением или Убеждением (на Ваш выбор).`,
    source: {
      id: SOURCE_TCoE,
      page: 46,
    },
  },
  {
    id: FEATURE_EMBOLDENING_BOND,
    name: `Укрепляющая связь`,
    nameEn: `Emboldening Bond`,
    lvl: 1,
    text: `Вы можете создать укрепляющую связь между людьми, не враждебными друг другу.

Вы действием выбираете количество согласных существ в пределах 30 футов (включая Вас), равное Вашему бонусу мастерства. Вы создаёте между ними магическую связь на 10 минут или до тех пор, пока снова не используетесь эту способностью. Пока одно связанное существо находится в пределах 30 футов от другого, оно может бросить к4 и добавить выпавшее число к броску атаки, проверке способности или испытанию, которое совершает. Каждое существо может добавлять к4 не чаще одного раза за ход.

Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 46,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_BALM_OF_PEACE,
    name: `Божественный канал: Аромат умиротворения`,
    nameEn: `Channel Divinity: Balm of Peace`,
    lvl: 2,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы успокаивать других самим своим присутствие.

Вы можете действием переместиться на расстояние, не превышающее Вашу скорость, не провоцируя атаки. Когда Вы так перемещаетесь в пределах 5 футов от любого другого существа, Вы можете восстановить ему 2к6 + Ваш модификатор Мудрости хитов (минимум 1). Существо может получить это исцеление только один раз за одно использование _Аромата умиротворения_.`,
    source: {
      id: SOURCE_TCoE,
      page: 46,
    },
  },
  {
    id: FEATURE_PROTECTIVE_BOND,
    name: `Охраняющая связь`,
    nameEn: `Protective Bond`,
    lvl: 6,
    text: `Связь, которую Вы создаёте между людьми, помогает им защищать друг друга.

Когда существо, на которое действует Ваша способность [Укрепляющая связь](FEATURE:${FEATURE_EMBOLDENING_BOND}), вот-вот получит урон, второе связанное существо в пределах 30 футов от первого может реакцией телепортироваться в свободное пространство в пределах 5 футов от первого существа. После этого второе существо получает все повреждения.`,
    source: {
      id: SOURCE_TCoE,
      page: 46,
    },
  },
  {
    id: FEATURE_POTENT_SPELLCASTING_PEACE_DOMAIN,
    name: `Мощное заклинание`,
    nameEn: `Potent Spellcasting`,
    lvl: 8,
    text: `Добавьте свой модификатор Мудрости к урону, который Вы наносите с помощью любого заговора жреца.`,
    source: {
      id: SOURCE_TCoE,
      page: 46,
    },
  },
  {
    id: FEATURE_EXPANSIVE_BOND,
    name: `Обширная связь`,
    nameEn: `Expansive Bond`,
    lvl: 17,
    text: `Преимущества способностей [Укрепляющая связь](FEATURE:${FEATURE_EMBOLDENING_BOND}) и [Охраняющая связь](FEATURE:${FEATURE_PROTECTIVE_BOND}) теперь работают, когда существа находятся в пределах 60 футов друг от друга.

Кроме того, когда существо использует [Охраняющую связь](FEATURE:${FEATURE_PROTECTIVE_BOND}), чтобы принять чужой урон, оно получает сопротивление к этому урону.`,
    source: {
      id: SOURCE_TCoE,
      page: 46,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_PEACE_DOMAIN,
  })
)

