const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_GITHZERAI} = require('./../../creatureTypeIdList')
const {
  githzeraiPureDescription,
  gitzeraiPureNote,
} = require ('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_GITHZERAI,
  nameEn: 'Githzerai',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'гитцерай',
      genitive: 'гитцерая',
      dative: 'гитцераю',
      accusative: 'гитцерая',
      instrumental: 'гитцераем',
      prepositional: 'гитцерае',
    },
    plural: {
      nominative: 'гитцераи',
      genitive: 'гитцераев',
      dative: 'гитцераям',
      accusative: 'гитцераев',
      instrumental: 'гитцераями',
      prepositional: 'гитцераах',
    },
  },
  description: githzeraiPureDescription,
  note: gitzeraiPureNote,
}
