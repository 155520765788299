const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {GEAR_VIAL} = require('./../../../../../gearIdList')
const {PC_CLASS_SORCERER} = require('./../../../../../pcClassIdList')
const {FEATURE_SORCERY_POINTS} = require('./../../../../../featureIdList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    bonus,
    id,
    rarity,
  }
) => ({
  id,
  name: `Флакон кровавого колодца +${bonus}`,
  nameEn: `Bloodwell Vial +${bonus}`,
  rarity,
  gearType: GEAR_VIAL,
  attunement: true,
  attunementComment: `чародеем`,
  type: MGC_TYPE_WONDROUS_ITEM,
  description: `Чтобы настроиться на этот флакон, Вы должны поместить в него несколько капель своей крови. _Флакон кровавого колодца_ невозможно открыть, пока Вы на него настроены. Если Ваша настройка завершается, кровь в нём обращается в пыль.

Держа или нося с собой _Флакон кровавого колодца_, Вы можете использовать его как заклинательную фокусировку и получаете **+${bonus}** к броскам атаки заклинаниями и СЛ испытаний Ваших заклинаний [чародея](PC_CLASS:${PC_CLASS_SORCERER}).

Когда Вы несёте _Флакон кровавого колодца_ и бросаете Кость хитов, чтобы восстановить хиты, Вы восстанавливаете 5 [Очков чародейства](FEATURE:${FEATURE_SORCERY_POINTS}). Эту способность _Флакона_, нельзя повторно использовать до следующего рассвета.`,
  genderId: GENDER_MALE,
  siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 176,
  },
})
