const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_DRAGON_GOLD_ADULT,
  CREATURE_DRAGON_GOLD_WYRMLING,
} = require('./../../creatureIdList')

const metalDragonDescriptionList = require('./dragonMetallic')

module.exports = [
  {
    header: 'Логово золотого дракона',
    text: `Золотые драконы располагают свои логова в захолустных местах, где они могут расположиться как им захочется, не вызывая подозрений или страха. Большинство обитает вблизи идиллических рек и озёр, на островах, окутанных туманом, в пещерных комплексах, скрытых за игристыми водопадами или в древних руинах.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов; дракон не может воспользоваться одним и тем же эффектом два раунда подряд:

* Дракон заглядывает в будущее, поэтому совершает с преимуществом броски атаки, проверки характеристик и испытания до показателя инициативы «20» в следующем раунде. 
* Одно существо, которое дракон видит в пределах 120 футов, должно пройти испытание Харизмы СЛ 15, иначе будет изгнано на план снов, иной план существования, который дракон вообразил в реальность. Для того чтобы сбежать, существо должно действием совершить состязание своей проверки Харизмы с проверкой дракона. Если существо победит, оно вырывается из плана снов. В противном случае эффект заканчивается при показателе инициативы «20» в следующем раунде. Когда эффект заканчивается, существо появляется в том месте, откуда пропало, либо в ближайшем свободном пространстве, если первоначальное место занято.

# Местные эффекты

Регион, в котором есть логово легендарного золотого дракона, искажается драконьей магией, которая создаёт один или несколько следующих эффектов:

* Каждый раз, когда существо, способное понимать какой-нибудь язык, засыпает или входит в состояние транса или забытья в пределах 6 миль от логова дракона, дракон может установить телепатический контакт с этим существом и говорить с ним в его снах. Существо помнит разговор с драконом после пробуждения. 
* Скопления красивого, переливающегося тумана проявляются в пределах 6 миль от логова дракона. Туман ничего не скрывает. Он принимает жуткие формы, когда злые создания находятся рядом с драконом или другими незлыми существами в тумане, предупреждая таких существ об опасности. 
* Драгоценные камни и жемчужины в пределах 1 мили от драконьего логова искрятся и блестят, испуская тусклый свет в радиусе 5 футов.

Если дракон умирает, эти эффекты немедленно завершаются`,
    source: {
      id: SOURCE_MM,
      page: 126,
    },
  },
  {
    header: 'Золотые драконы',
    text: `Самые могущественные и величественные из металлических драконов, золотые драконы являются убеждёнными противниками зла.

На лице золотого дракона, окружённом гибкими шипами, напоминающими усы, всегда проницательное выражение. Его рога направлены назад, от его носа и лба, а за ними идёт двойной гребень, украшающий длинную драконью шею. Парусообразные крылья золотого дракона начинаются от плеч и идут вплоть до кончика хвоста, позволяя ему летать с характерным волнистым движением, как будто плавая по воздуху. Чешуя [вирмлинга золотого дракона](CREATURE:${CREATURE_DRAGON_GOLD_WYRMLING}) темно-жёлтая с металлическими пятнами. Эти пятна растут в размерах, пока дракон взрослеет. У [взрослого золотого дракона](CREATURE:${CREATURE_DRAGON_GOLD_ADULT}) зрачки исчезают и глаза становятся похожи на лужи расплавленного золота.

# Поедание богатств

Золотые драконы могут питаться чем угодно, но предпочитают рацион, состоящий из жемчуга и драгоценных камней. К счастью, золотой дракон не обязан насыщать себя только таким богатством, чтобы почувствовать себя удовлетворённым. Сокровища в дар, которые могут быть съедены, хорошо воспринимаются золотым драконом, если он не воспринимает их как взятку.

# Сдержанные перевёртыши

Золотые драконы уважают других металлических драконов за их мудрость и справедливость, но они являются самыми мрачными и отчуждёнными из добрых драконов. Они настолько ценят свою личную жизнь, что редко тесно общаются с другими драконами, исключая своих супругов и потомство.

Старые золотые драконы могут принимать обличья гуманоидов или животных. Редко происходит так, что золотой дракон в маскировке раскрывает свою истинную форму. Под видом разносчика он может регулярно посещать город, чтобы послушать местные слухи, поддержать честных предпринимателей и протянуть невидимую руку помощи. В облике животного дракон может подружиться с потерявшимся ребёнком, бродячим менестрелем или трактирщиком, выступая в качестве спутника несколько дней или недель подряд.

# Мастер накопительства

Золотой дракон копит свои сокровища в хорошо защищённом хранилище глубоко в логове. Волшебная защита, размещающаяся в логове, сделают практически невозможной любую попытку взять что-либо из сокровищ без ведома дракона.`,
    source: {
      id: SOURCE_MM,
      page: 125,
    },
  },
  ...metalDragonDescriptionList,
]
