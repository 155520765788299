const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ABILITY_REGENERATION,
  ABILITY_SPIDER_CLIMB,
} = require('./../../../../creatureAbilityList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {CREATURE_VAMPIRE_SPAWN} = require('./../../../../creatureIdList')
const {CR_5} = require('./../../../../crList')
const {GEAR_HOLY_WATER} = require('./../../../../gearIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')
const {vampireDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Порождение вампира',
  nameAlt: 'Отродье вампира',
  nameEn: 'Vampire Spawn',
  id: CREATURE_VAMPIRE_SPAWN,
  description: vampireDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 31,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  cr: CR_5,
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  featureList: [
    {
      id: ABILITY_REGENERATION,
      regen: 10,
      other: `, если у ★него★ есть хотя бы 1 хит, и ★он★ не находится ни под солнечными лучами, ни в текущей воде. Если ★СУЩЕСТВО★ получает урон излучением или урон от [святой воды](GEAR:${GEAR_HOLY_WATER}), эта особенность не действует в начале следующего ★его★ хода`,
    },
    ABILITY_SPIDER_CLIMB,
    {
      name: 'Слабости вампира',
      description: `★СУЩЕСТВО★ обладает следующими слабостями:
* _Запрет_. ★СУЩЕСТВО★ не может войти в жилище без приглашения одного из обитателей.
* _Урон от текущей воды_. ★СУЩЕСТВО★ получает урон кислотой 20, если оканчивает ход в текущей воде.
* _Кол в сердце_. Если колющее оружие, изготовленное из дерева, вонзить в сердце ★СУЩЕСТВО★, пока ★он★ ★недееспособен★ в своём месте отдыха, ★СУЩЕСТВО★ станет парализованным, пока кол не вынут.
* _Гиперчувствительность к солнечному свету_. ★СУЩЕСТВО★ получает урон излучением 20, если начинает ход под солнечным светом. Находясь на солнечном свету, ★он★ совершает с помехой броски атаки и проверки характеристик.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки, только одна из которых может быть _Укусом_.`,
    },
    {
      name: 'Когти',
      description: 'Вместо причинения урона ★СУЩЕСТВО★ может схватить цель (СЛ высвобождения 13).',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Укус',
      description: 'Максимум хитов цели уменьшается на количество, равное полученному урону некротической энергией, а ★СУЩЕСТВО★ восстанавливает такое же количество хитов. Уменьшение хитов длится до тех пор, пока цель не окончит длинный отдых. Цель умирает, если этот эффект уменьшает максимум её хитов до 0.',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: 'одно согласное существо или существо, которое ★схватил★ ★СУЩЕСТВО★, недееспособное или обездвиженое',
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MIDDLE,
}
