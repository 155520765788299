const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  starSpawnDescription,
  starSpawnNote,
} = require('./../../../../textCommonParts')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_STAR_SPAWN,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_CULTIST,
  CREATURE_STAR_SPAWN_MANGLER,
} = require('./../../../../creatureIdList')
const {ABILITY_SHADOW_STEALTH} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {LANG_DEEP_SPEECH} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Кромсатель звёздных порождений',
  nameEn: 'Star Spawn Mangler',
  id: CREATURE_STAR_SPAWN_MANGLER,
  description: [
    `Кромсатель — низкорослая, ползучая мерзость с несколькими длинными руками. Обычно у него имеется по шесть конечностей, но каждый кромсатель может иметь от четырёх до восьми. Кромсатели ползают по земле или стенам, скрываясь в тени, умудряясь укрываться от мест, что слишком малы или светлы для того, чтобы служить укрытием. Они кажутся значительно меньше, чем на самом деле, из-за своей сгорбленности и истощённого телосложения. [Культисты](CREATURE:${CREATURE_CULTIST}) призывают таких существ, чтобы те служили им в качестве надзирателей и наёмных убийц — задачи, с которыми они отлично справляются.`,
    ...starSpawnDescription,
  ],
  note: starSpawnNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_STAR_SPAWN,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 220,
  },
  armor: 14,
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
  ],
  immunityList: [
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Устроить засаду',
      description: `В первом раунде каждого боя Кромсатель совершает с преимуществом броски атаки против существ, которые ещё не начинали свой ход.`,
    },
    ABILITY_SHADOW_STEALTH,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Кромсатель совершает две атаки _Когтями_.`,
    },
    {
      name: 'Когти',
      description: `Если бросок атаки были совершён с преимуществом, то цель
получает дополнительных 7 (2к6) урона психической энергией.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Шквал когтей',
      restore: 4,
      description: `Кромсатель совершает шесть атак по одной цели. До или после этих атак он может бонусным действием передвигаться на расстояние, не превышающее его скорость, не вызывая провоцирования атак.`,
    },
  ],
}
