const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {
  CREATURE_DRUID,
  CREATURE_VAMPIRE,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Заразы',
    text: `Пробуждённые тьмой растения, наделённые разумом и возможностью передвижения, подобно чуме губят землю. Подпитываемые тьмой через почву, заразы несут в себе древнее зло и пытаются распространить его везде, где это возможно.`,
    source: {
      id: SOURCE_MM,
      page: 156,
    },
  },
  {
    header: 'Заразы',
    text: `Эти пробуждённые тьмой растения, наделённые разумом и способностью передвигаться, подобно чуму губят землю, заражая её тьмой.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Корни древа Галтиаса',
    text: `Легенды рассказывают о [вампире](CREATURE:${CREATURE_VAMPIRE}) по имени Галтиас, которые занимался ужасной магией и воздвиг мерзкую башню, которую навали Шпиль Ночного Когтя. Галтиас был умерщвлён, когда герой проткнул ему сердце деревянным колом, но когда вампир был уничтожен, его кровь проникла в кол, наполнив его ужасной силой. Через некоторое время этот кол дал побеги и стал деревом, полным злой сущности вампира.

Как говорят, безумный [друид](CREATURE:${CREATURE_DRUID}) нашёл тот побег и перенёс его в подземный грот, где он рос дальше. То древо Галтиаса дало семена, из которых появились первые заразы.`,
    source: {
      id: SOURCE_MM,
      page: 156,
    },
  },
  {
    header: 'Тёмное завоевание',
    text: `Везде, где дерево или растение может быть загрязнено злым разумом или силой, древо Галтиаса может прорасти и заразить весь окружающий лес. Это зло распространяется через корни и почву, заражая другие растения, которые гибнут или превращаться в заразы. Как только заразы начинают распространяться, они отравляют и уничтожают здоровые деревья, заменяя их колючими шипами, ядовитыми плющами и другими растениями в этом роде. Со временем заражение превращает любую землю и леса в места разложения.

В заражённых лесах деревья и растения разрастаются со сверхъестественной скоростью. Вьюны и подлесок быстро распространяются по зданиям, протоптанным тропинкам и дорогам. Целые деревни могут исчезнуть за несколько дней, если заразы убьют или прогонят жителей.`,
    source: {
      id: SOURCE_MM,
      page: 156,
    },
  },
  {
    header: 'Управляемые злом',
    text: `Заразы — независимые существа, но большинство действует под контролем древа Галтиаса, часто отражая привычки и черты сущности, которая породила их. Нападая на старых врагов своего предтечи или ища сокровища, ценные для него, они несут наследие давно утерянного зла.`,
    source: {
      id: SOURCE_MM,
      page: 156,
    },
  },
]
