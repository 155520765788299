const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_ILLUSION} = require('./../../../../../magicList')
const {SPELL_SILENT_IMAGE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_SILENT_IMAGE,
  name: 'Безмолвный образ',
  nameEn: `Silent Image`,
  description: `Вы создаёте образ предмета, существа или другого видимого явления, помещающийся в объёме в куб с длиной ребра 15 футов. Образ появляется в точке, которую Вы видите в пределах дистанции, и существует, пока активно заклинание. Это исключительно зрительная иллюзия, не сопровождаемая звуками, запахами и прочими сенсорными эффектами.

Вы можете действием заставить образ переместиться в любое место в пределах дистанции. Пока образ меняет местоположение, Вы можете изменять его внешность, чтобы перемещение выглядело естественным. Например, если Вы создаёте образ существа и перемещаете его, Вы можете изменить образ, чтобы казалось, что оно идёт.

Физическое взаимодействие с образом даёт понять, что это иллюзия, потому что сквозь него всё проходит. Существа, исследующие образ действием, могут определить, что это иллюзия, совершив успешную проверку Интеллекта (Анализ) против СЛ Ваших заклинаний. Если существо распознаёт иллюзию, оно может видеть сквозь неё.`,
  lvl: 1,
  magicSchoolId: MAGIC_ILLUSION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кусок овечьей шерсти`,
  duration: { timeId: TIME_MINUTE, count: 10 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 213,
    },
    {
      id: SOURCE_SRD,
      page: 179,
    },
  ],
}
