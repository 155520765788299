const {
  SPELL_CONJURE_ELEMENTAL,
  SPELL_DETECT_MAGIC,
  SPELL_ENLARGE_REDUCE,
  SPELL_GASEOUS_FORM,
  SPELL_INVISIBILITY,
  SPELL_MAJOR_IMAGE,
  SPELL_PLANE_SHIFT,
  SPELL_TONGUES,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_DAO,
  CREATURE_DJINNI,
  CREATURE_EFREETI,
  CREATURE_FIRE_ELEMENTAL,
  CREATURE_MARID,
} = require('./../../../../creatureIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FIRE,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_11} = require('./../../../../crList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {GEAR_SCIMITAR} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {geniusDescriptionList} = require('./../../../../textCommonParts')
const {LANG_IGNAN} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Ифрит',
  nameEn: 'Efreeti',
  id: CREATURE_EFREETI,
  description: [
    `Ифриты — громадные гении со Стихийного Плана Огня — повелевают пламенем, обладают иммунитетом к огню и способны создавать его по своему желанию. Тонкие шёлковые кафтаны и узорчатые одеяния покрывают их красную, как лава, или чёрную, как уголь, кожу. Ифриты украшают себя сверкающими ожерельями, цепями и кольцами с драгоценными камнями. Во время полёта нижняя часть тела ифрита выглядит как столб дыма и тлеющих углей.`,
    {
      header: 'Надменные и жестокие',
      text: `Ифриты коварны, хитры и жестоки, а порой беспощадны. Они не выносят вынужденной службы и безжалостны в своей мести тем, кто причинил им вред. Сами ифриты этого не осознают, и считают свою расу честной и спокойной, хотя и признают, что немного эгоистичны.`,
      source: {
        id: SOURCE_MM,
        page: 52,
      },
    },
    {
      header: 'Злобные рабовладельцы',
      text: `Ифриты расценивают все остальные расы как врагов или потенциальных рабов. За рабами они совершают набеги на Материальный и стихийные планы. Ифриты правят как деспоты и тираны, возвышая лишь жесточайших из рабов. Те становятся надсмотрщиками, которые при помощи кнута держат в узде остальных рабов.`,
      source: {
        id: SOURCE_MM,
        page: 52,
      },
    },
    {
      header: 'Планарные налётчики',
      text: `Большинство ифритов живёт на Стихийном Плане Огня, в больших увенчанных куполами крепостях из чёрного стекла и базальта, окружённых кипящими огненными озёрами, или в знаменитом Латунном городе. Кроме того, по многим планам разбросаны военные аванпосты ифритов, переполненные их приспешниками и рабами.

На Материальном плане ифриты обитают в жарких местах, таких как вулканы или пустыни. Их любовь к пустыням приводит к конфликтам с [джиннами](CREATURE:${CREATURE_DJINNI}), которые ездят на пустынных смерчах, и связанными с землёй [дао](CREATURE:${CREATURE_DAO}). Ифриты ненавидят [маридов](CREATURE:${CREATURE_MARID}), с которыми враждуют весь период существования обеих рас.`,
      source: {
        id: SOURCE_MM,
        page: 52,
      },
    },
    ...geniusDescriptionList,
  ],
  note: {
    text: `Армия Великого Султана усилена легионом дьяволов, его обитель защищена заклинаниями тысячи архимагов, его сокровища охраняют красные драконы и огненные элементали. Никто не смог ограбить легендарные чертоги ифритов и вернуться живым, чтобы рассказать об этом. Милостью тысячи ветров, ты можешь стать первым`,
    author: `джинн завлекает искателей приключений, чтобы освободить её халифа из волшебной лампы в Угольном Дворце Латунного города`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 52,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 24,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  immunityList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_IGNAN,
  ],
  cr: CR_11,
  featureList: [
    {
      name: 'Стихийная гибель',
      description: `Если ★СУЩЕСТВО★ умирает, ★его★ тело распадается во вспышке огня и клубах дыма, оставляя только снаряжение, которое ифрит ★носил★ и ★нёс★.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_ENLARGE_REDUCE,
          SPELL_TONGUES,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_GASEOUS_FORM,
          SPELL_INVISIBILITY,
          SPELL_MAJOR_IMAGE,
          SPELL_WALL_OF_FIRE,
          {
            id: SPELL_CONJURE_ELEMENTAL,
            comment: `только [огненный элементаль](CREATURE:${CREATURE_FIRE_ELEMENTAL})`,
          },
          SPELL_PLANE_SHIFT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Скимитаром_, либо два раза использует _Метание пламени_.`,
    },
    {
      name: 'Скимитар',
      // gearId: GEAR_SCIMITAR, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 6,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Метание пламени',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 7,
        range: 120,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 5,
          diceType: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
