const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  MAGIC_ITEM_ABSORBING_TATTOO_BLACK,
  MAGIC_ITEM_ABSORBING_TATTOO_BLUE,
  MAGIC_ITEM_ABSORBING_TATTOO_GOLD,
  MAGIC_ITEM_ABSORBING_TATTOO_GREEN,
  MAGIC_ITEM_ABSORBING_TATTOO_ORANGE,
  MAGIC_ITEM_ABSORBING_TATTOO_RED,
  MAGIC_ITEM_ABSORBING_TATTOO_SILVER,
  MAGIC_ITEM_ABSORBING_TATTOO_VIOLET,
  MAGIC_ITEM_ABSORBING_TATTOO_WHITE,
  MAGIC_ITEM_ABSORBING_TATTOO_YELLOW,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_BLACK,
    damageTypeId: DAMAGE_NECROTIC,
    colorNameEn: 'black',
    colorNameRuFemale: 'чёрная',
    colorNameRuMale: 'чёрного',
  },
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_BLUE,
    damageTypeId: DAMAGE_COLD,
    colorNameEn: 'blue',
    colorNameRuFemale: 'синяя',
    colorNameRuMale: 'синего',
  },
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_GOLD,
    damageTypeId: DAMAGE_RADIANT,
    colorNameEn: 'gold',
    colorNameRuFemale: 'золотая',
    colorNameRuMale: 'золотого',
  },
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_GREEN,
    damageTypeId: DAMAGE_ACID,
    colorNameEn: 'green',
    colorNameRuFemale: 'зелёная',
    colorNameRuMale: 'зелёного',
  },
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_ORANGE,
    damageTypeId: DAMAGE_THUNDER,
    colorNameEn: 'orange',
    colorNameRuFemale: 'оранжевая',
    colorNameRuMale: 'оранжевого',
  },
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_RED,
    damageTypeId: DAMAGE_FIRE,
    colorNameEn: 'red',
    colorNameRuFemale: 'красная',
    colorNameRuMale: 'красного',
  },
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_SILVER,
    damageTypeId: DAMAGE_PSYCHIC,
    colorNameEn: 'silver',
    colorNameRuFemale: 'серебряная',
    colorNameRuMale: 'серебряного',
  },
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_VIOLET,
    damageTypeId: DAMAGE_POISON,
    colorNameEn: 'violet',
    colorNameRuFemale: 'фиолетовая',
    colorNameRuMale: 'фиолетового',
  },
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_WHITE,
    damageTypeId: DAMAGE_FORCE,
    colorNameEn: 'white',
    colorNameRuFemale: 'белая',
    colorNameRuMale: 'белого',
  },
  {
    id: MAGIC_ITEM_ABSORBING_TATTOO_YELLOW,
    damageTypeId: DAMAGE_ELECTRICITY,
    colorNameEn: 'yellow',
    colorNameRuFemale: 'жёлтая',
    colorNameRuMale: 'жёлтого',
  },
]
