module.exports = [
  require('./ki_rin'),
  require('./kobold_dragonshield_black'),
  require('./kobold_dragonshield_blue'),
  require('./kobold_dragonshield_green'),
  require('./kobold_dragonshield_red'),
  require('./kobold_dragonshield_white'),
  require('./kobold_inventor'),
  require('./kobold_scale_sorcerer'),
  require('./korred'),
  require('./kraken_priest'),
]
