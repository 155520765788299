const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_SHIELD,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KOBOLD,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_KOBOLD,
  CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
} = require('./../../../../creatureIdList')
const {
  ABILITY_PACK_TACTICS,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  koboldDescriptionList,
  koboldNote,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_ACID,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_1} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Кобольд чёрный драконий щит',
  nameEn: 'Kobold Dragonshield (Black)',
  id: CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  description: [
    `Кобольды драконьи щиты — сильнейшие представители своей расы. Почти все драконьи щиты начинают жизнь как обычные [кобольды](CREATURE:${CREATURE_KOBOLD}) и впоследствии становятся избранными драконов, наделёнными большим могуществом для защиты драконьих яиц, но раз в несколько лет случается, что кобольд вылупляется со врождённой версией способностей драконьих щитов. Мастера ближнего боя, эти кобольды покрыты множеством шрамов, полученных в отчаянных схватках, и носят щиты, сделанные из сброшенных чешуек дракона.

**Редкая храбрость.** Драконьи щиты занимают почётные места в своих племенах, но, оставаясь в глубине души кобольдами, они чувствуют себя недостойными этого статуса, и поэтому отчаянно пытаются доказать, что заслуживают его. Природная кобольдская трусость всё-таки сохраняется в драконьем щите, и поэтому он всё же может сбежать от опасности. Но у него также есть и способность воспрянуть духом пред лицом верной смерти, вдохновив других кобольдов последовать за ним в атаку против вторгшихся в их норы врагов.`,
    ...koboldDescriptionList,
  ],
  note: koboldNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_KOBOLD,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 159,
  },
  armor: {
    ac: 15,
    gearId: [GEAR_LEATHER_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Сердце дракона',
      description: `Если кобольд испуган или парализован эффектом, требующим проходить испытание, он может повторять это испытание в начале каждого своего хода, при успехе оканчивая действие этого эффекта на себе и на всех кобольдах в радиусе 30 футов от него. Все кобольды, получающие пользу от этой особенности (включая самого драконьего щита) получают преимущество на свой следующий бросок атаки.`,
    },
    ABILITY_PACK_TACTICS,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Кобольд совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_SPEAR,
    },
  ],
}
