const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_BARBED_DEVIL,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гаматула',
  nameAlt: 'Шипастый дьявол',
  nameEn: 'Hamatula',
  nameEnAlt: 'Barbed Devil',
  id: CREATURE_BARBED_DEVIL,
  description: [
    `Существа с необузданными жадностью и желаниями, шипастые дьяволы служат охранниками для более сильных обитателей Девяти Преисподних и их сокровищниц. Светящиеся глаза этого похожего на высокого гуманоида, покрытого острыми колючками, шипами, и крючками, дьявола зорко выискивают предметы или существ, которые можно бы было прибрать к рукам. Эти исчадия готовы к любому шансу вступить в бой, если победа сулит награду.

Шипастые дьяволы известны своей бдительностью, и их трудно застать врасплох, а ещё они относятся к своим обязанностям без скуки и рассеянности. Они используют свои острые когти как оружие и кидают огненные шары в убегающих от них врагов.`,
    ...devilDescriptionList,
  ],
  note: devilNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 150,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 18,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Шипастая шкура',
      description: `В начале каждого своего хода ★СУЩЕСТВО★ причиняет колющий урон 5 (1к10) всем схватившим ★его★ существам.`,
    },
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три рукопашные атаки: одну _Хвостом_, и две _Когтями_. В качестве альтернативы, ★он★ может дважды использовать _Метание пламени_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Метание пламени',
      description: `Если цель — горючий предмет, который никто не несёт и не носит, она также загорается.`,
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 5,
        range: 150,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 3,
          diceType: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
