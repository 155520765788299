const {FEATURE_DEEP_STALKER_UA_2015_11_02_LIGHT_DARK_UNDERDARK_SPELLS} = require('./../../../featureIdList')
const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_DEEP_STALKER_2015_11_02} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK} = require('./../../../sourceList')
const {
  SPELL_DISGUISE_SELF,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREATER_INVISIBILITY,
  SPELL_ROPE_TRICK,
  SPELL_SEEMING,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_DEEP_STALKER_UA_2015_11_02_LIGHT_DARK_UNDERDARK_SPELLS,
    name: `Заклинания глубинных охотников`,
    nameEn: `Deep Stalker Spells`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    pcSubClassId: PC_SUBCLASS_RANGER_DEEP_STALKER_2015_11_02,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_DISGUISE_SELF,
      SPELL_ROPE_TRICK,
      SPELL_GLYPH_OF_WARDING,
      SPELL_GREATER_INVISIBILITY,
      SPELL_SEEMING,
    ],
    source: {
      id: SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK,
      page: 1,
    },
  },
]
