const {
  SPELL_CHARM_PERSON,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DISGUISE_SELF,
  SPELL_DOMINATE_PERSON,
  SPELL_FLY,
  SPELL_INVISIBILITY,
  SPELL_MAGE_HAND,
  SPELL_MAJOR_IMAGE,
  SPELL_MINOR_ILLUSION,
  SPELL_PLANE_SHIFT,
  SPELL_REMOVE_CURSE,
  SPELL_SUGGESTION,
  SPELL_TRUE_SEEING,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_MAGIC_PIERCING_GOOD,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
} = require('./../../../../skillList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_13} = require('./../../../../crList')
const {CREATURE_RAKSHASA} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_FIEND} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ракшас',
  nameAlt: 'Ракшаса',
  nameEn: 'Rakshasa',
  id: CREATURE_RAKSHASA,
  description: [
    {
      header: 'Ракшасы',
      text: `Ракшасы в своём стремлении к господству над другими используют слабости и обман. Мало кто когда-либо видел этих исчадий в истинном облике, ибо они могут принимать любое обличие, какое только захотят, хотя предпочитают маскироваться под кого-нибудь могущественного или влиятельного, например, дворянина, кардинала или богатого купца. Истинный облик ракшаса сочетает в себе черты человека и тигра с одним примечательным недостатком: их ладони вывернуты в другую сторону, в отличие от человеческих.`,
      source: {
        id: SOURCE_MM,
        page: 248,
      },
    },
    {
      header: 'Злые духи в смертной плоти',
      text: `Ракшасы возникли давным-давно в Девяти Преисподних, когда могущественные дьяволы провели тёмный ритуал для освобождения своих сущностей из тел, чтобы сбежать с Нижних Планов. Ракшасы вошли на Материальный план для утоления своей тяги к человеческой плоти и злодейским интригам. Они выбирают свою жертву с осторожностью, стараясь сохранить своё присутствие в этом мире в тайне.`,
      source: {
        id: SOURCE_MM,
        page: 248,
      },
    },
    {
      header: 'Зло возрождается',
      text: `Для ракшаса смерть на Материальном плане означает страшное и мучительное возвращение в Девять Преисподних, где его сущность остаётся в ловушке, пока его тело перерождается — процесс может занять месяцы или годы. Когда ракшас перерождается, он обладает всей памятью и знаниями своей прошлой жизни, и будет мстить тому, кто убил его. Если цели каким-то образом удалось избежать его хватки, ракшас может наказать семью убийцы, его друзей или потомков.

Как и дьяволы, ракшасы, убитые в Девяти Преисподних, уничтожаются навсегда.`,
      source: {
        id: SOURCE_MM,
        page: 248,
      },
    },
  ],
  note: {
    text: `Позор тебе, коль убьёшь меня однажды.

Позор мне, коль убьёшь меня дважды.`,
    author: `принцип Ракшас`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 248,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 18,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 20,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_MAGIC_PIERCING_GOOD,
  ],
  immunityList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    LANG_COMMON,
  ],
  cr: CR_13,
  featureList: [
    {
      name: `Ограниченный иммунитет к магии`,
      description: `На ★СУЩЕСТВО★ не действуют заклинания 6 уровня или ниже, и они же не могут ★его★ обнаружить, если только ★он★ сам не желает, чтобы они на ★него★ подействовали. ★Он★ с преимуществом проходит испытания от всех остальных заклинаний и магических эффектов.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_MAGE_HAND,
          SPELL_MINOR_ILLUSION,
          SPELL_DISGUISE_SELF,
          SPELL_DETECT_THOUGHTS,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_SUGGESTION,
          SPELL_INVISIBILITY,
          SPELL_DETECT_MAGIC,
          SPELL_MAJOR_IMAGE,
          SPELL_CHARM_PERSON,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_TRUE_SEEING,
          SPELL_DOMINATE_PERSON,
          SPELL_FLY,
          SPELL_PLANE_SHIFT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Когтем_.`,
    },
    {
      name: `Коготь`,
      description: `Если цель — существо, она становится проклятой. Магическое проклятье вступает в действие каждый раз, когда существо совершает короткий или длинный отдых, наполняя мысли цели ужасными образами и грёзами. Проклятая цель не получает преимуществ от окончания короткого и длинного отдыха. Проклятье длится до тех пор, пока не будет снято заклинанием [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) или подобной магией.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
