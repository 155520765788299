const {
  CREATURE_TYPE_DUERGAR,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_TRUE_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DUERGAR_RESILIENCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {GEAR_LEATHER_ARMOR} = require('./../../../../gearIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_DUERGAR_MIND_MASTER} = require('./../../../../creatureIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Двергар повелитель разума',
  nameAlt: 'Дуэргар повелитель разума',
  nameEn: 'Duergar Mind Master',
  id: CREATURE_DUERGAR_MIND_MASTER,
  description: [
    `Устрашающие двергары повелители разума обычно выполняют функции шпионов, как изнутри, так и снаружи двергарских крепостей. Псионические способности позволяют им без труда видеть иллюзии насквозь и сжиматься до крошечных размеров, чтобы шпионить за своей целью.`,
    ...duergarDescription,
  ],
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DWARF,
    CREATURE_TYPE_DUERGAR,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 173,
  },
  armor: {
    ac: 14,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 14,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
    {
      id: SENSE_TRUE_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_DUERGAR_RESILIENCE,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Двергар совершает две рукопашные атаки. Он может заменить одну из атак _Управлением разумом_.`,
    },
    {
      name: 'Отравляющий разум кинжал',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 3,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Отравляющий разум кинжал',
      comment: `в уменьшенном состоянии`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 0,
            diceType: 0,
            diceBonus: 1,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 4,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Управление разумом',
      description: `Двергар выбирает целью одно существо в пределах 60 фт. от себя, которое он может видеть. Цель должна пройти испытание Интеллекта СЛ 12, иначе он заставит её реакцией совершить одну атаку оружием по существу на выбор двергара, или же передвинуться на 10 фт. по направлению, выбранному двергаром. Существа, имеющие иммунитет к состоянию Очарован, также невосприимчивы к действию Управления разумом.`,
    },
    {
      name: 'Уменьшение',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `На 1 минуту двергар магическим образом уменьшается в размере вместе со всем, что несёт и носит. В уменьшенном состоянии двергар Крошечного размера, его урон оружием становится равен 1 (уже учтено), и он совершает с помехой атаки, основанные на Силе, проверки и испытания Силы. В то же время уменьшение в размерах даёт ему бонус +5 к проверкам Ловкости (Скрытность) и бонус +5 КД. Помимо прочего двергар может бонусным действием на каждом своём ходу использовать действие Засада.`,
    },
    {
      name: 'Невидимость',
      restore: 4,
      description: `Двергар магическим образом становится невидимым, и этот эффект будет продолжаться до 1 часа, или же до того момента, когда он атакует, прочитает заклинание, использует Уменьшение или потеряет концентрацию (как если бы он концентрировался на заклинании). Всё, что он несёт и носит, становится невидимым вместе с ним.`,
    },
  ],
}
