const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_SLAAD_BLUE,
  CREATURE_SLAAD_DEATH,
  CREATURE_GITHZERAI_MONK,
  CREATURE_SLAAD_GRAY,
  CREATURE_SLAAD_GREEN,
  CREATURE_MONODRONE,
  CREATURE_SLAAD_RED,
} = require('./../../creatureIdList')

module.exports = {
  header: 'Слаады',
  text: `В вечно бурлящем хаосе Лимбо куски лесов и лугов, руин замков и изолированных островов плывут через мешанину огня, воды, земли и ветра. Главные обитатели этого негостеприимного плана — слаады. У них нет дисциплины, и нет формальной иерархии, хотя более слабые слаады повинуются более сильным из страха уничтожения.

**Нерестильный камень.** Когда-то давно Прайм, повелитель модронов, создал гигантский геометрически сложный камень, насыщенный энергией порядка. Затем он запустил его в Лимбо, веря, что камень принесёт порядок в хаос этого плана и задержит распространение этого хаоса на другие планы. С ростом силы камня существа с упорядоченным разумом, такие как [модроны](CREATURE:${CREATURE_MONODRONE}) и [гитцераи](CREATURE:${CREATURE_GITHZERAI_MONK}), смогли создавать анклавы в Лимбо. Однако творение Прайма имело один непредвиденный побочный эффект: хаотичная энергия, вобранная в себя камнем, породила ужасы, которые впоследствии стали известны как слаады. Именно поэтому мудрецы назвали творение Прайма Нерестильным камнем.

Слаады уничтожили все анклавы модронов в Лимбо. Как существа полнейшего хаоса слаады ненавидят модронов и нападают на них при встрече. Тем не менее, Прайм не уничтожает своё творение, то ли не считая слаадов угрозой, то ли просто игнорируя их.

**Рождение и трансформация.** У слаадов ужасающий репродуктивный цикл. Они размножаются либо откладывая яйца в гуманоидных носителей, либо заражая их трансформирующей болезнью под названием «вирус хаоса». Слаады разного цвета воспроизводятся и трансформируются по-своему, при этом [красные слаады](CREATURE:${CREATURE_SLAAD_RED}) порождают [синих](CREATURE:${CREATURE_SLAAD_BLUE}) и [зелёных](CREATURE:${CREATURE_SLAAD_GREEN}), а синие — красных и зелёных. Зелёные слаады в течение жизни превращаются в более сильных [серых](CREATURE:${CREATURE_SLAAD_GRAY}) и [смертельных слаадов](CREATURE:${CREATURE_SLAAD_DEATH}). При каждой трансформации слаад сохраняет воспоминания.

**Перевёртыши.** Некоторые слаады могут превращаться в гуманоидных существ, из которых они появились. Такие слаады возвращаются на Материальный план, чтобы сеять раздор в своём бывшем облике.`,
  source: {
    id: SOURCE_MM,
    page: 263,
  },
}
