const {
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_FIREBALL,
  SPELL_HEAT_METAL,
  SPELL_LIGHTNING_BOLT,
} = require('./../../../../spellIdList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  GEAR_LONGSWORD,
  GEAR_PLATE_ARMOR,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  CREATURE_ANIMATED_ARMOR,
  CREATURE_HELMED_HORROR,
} = require('./../../../../creatureIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ITS_CREATOR} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Шлемоносный ужас`,
  nameEn: 'Helmed Horror',
  id: CREATURE_HELMED_HORROR,
  description: [
    `Этот конструкт обладает интеллектом, умениями рассуждать и приспосабливать свою тактику, и он отвечает преданностью своему создателю, которая остаётся даже после кончины последнего. Похожий на [оживлённые латы](CREATURE:${CREATURE_ANIMATED_ARMOR}) шлемоносный ужас служит без стремлений и эмоций.`,
    {
      header: 'Порождение магии',
      text: `Создание шлемоносного ужаса это сложный процесс, тут не отделаешься простым оживлённым [комплектом лат](GEAR:${GEAR_PLATE_ARMOR}), но зато его нужно меньше направлять и обслуживать, ведь он сам выполняет назначенные задания. Шлемоносный ужас исполняет приказы с максимальной верностью, и его разума достаточно, чтобы понять разницу между целью приказа и его точной формулировкой. В отличие от многих конструктов он скорее стремится следовать смыслу поручения, чем раболепно подчиняться формальному приказу.`,
      source: {
        id: SOURCE_MM,
        page: 299,
      },
    },
    {
      header: 'Тактическое коварство',
      text: `Шлемоносный ужас сражается с хитростью опытного воина, и, взлетая в воздух, он в первую очередь атакует слабых персонажей и заклинателей. Тем не менее, шлемоносному ужасу не хватает знаний для изменения своего окружения, чтобы укрепиться или иным образом улучшить свою оборонительную позицию.`,
      source: {
        id: SOURCE_MM,
        page: 299,
      },
    },
    {
      header: 'Природа конструкта',
      text: `Шлемоносному ужасу не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 299,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 299,
  },
  armor: {
    ac: 20,
    gearId: [GEAR_PLATE_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_FORCE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_PETRIFIED,
    CONDITION_BLINDED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_STUNNED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: `Иммунитет к заклинаниям`,
      description: `★СУЩЕСТВО★ обладает иммунитетом к трём заклинаниям, выбираемым его создателем. Обычно это [Молния](SPELL:${SPELL_LIGHTNING_BOLT}), [Огненный шар](SPELL:${SPELL_FIREBALL}) и [Раскалённый металл](SPELL:${SPELL_HEAT_METAL}).`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Длинным мечом_.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
  ],
  genderId: GENDER_MALE,
}
