const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_DIRE_CORBY,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ACROBATICS,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    LANG_UNDERCOMMON,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_FRIGHTENED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_SLASHING,
    DAMAGE_THUNDER,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_KEEN_HEARING,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_DIRE_CORBY,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Жутевран',
  nameEn: 'Dire Corby',
  id: CREATURE_DIRE_CORBY,
  description: [
    {
      header: 'Жутевраны',
      text: `Отмеченные зловещим, глубоким грохотом странного пения жутевранов, их огромные скопления простираются по Подземью, очищая от живых существ все соседние туннели и пещеры. Даже самые могущественные существа Подземья прячутся в укреплённых логовах или уходят с дороги стай жутевранов, зная, как причудливая песнь разрушает, дезориентирует и, в конечном счете, подавляет их добычу.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 7,
      },
    },
    {
      header: 'Роковые стаи',
      text: `Жутевраны бродят по Подземью огромными стаями по предсказуемым маршрутам, как перелётные птицы. Они очищают целые области Подземья от лишайников, грибов и паразитов, составляющих их рацион, а потом уходят к свежему корму. Путешественники Подземья высоко ценят точные графики движения этих стай, ведь следование позади стаи жутевранов практически гарантирует спокойное путешествие по туннелям, очищенным от чудовищ и других угроз.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 7,
      },
    },
    {
      header: 'Жуткие песни',
      text: `Жители Подземья слышат приближающуюся стаю жутевранов задолго до их появления. Путешествуя, они кричат, чирикают и воют в сводящей с ума какофонии. Их песнь содержит странный поток магии, заставляющий слушателей страдать от сильного головокружения, не дающего быстро передвигаться или лазать по стенам пещер. Разум заклинатели тоже плывёт, делая их неспособными использовать что-либо, кроме простейшей магии. Дезориентирующая песня жутевранов помешала убежать от их стай бесчисленным существам Подземья и путешественникам.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 7,
      },
    },
    {
      header: 'Странный эскорт',
      text: `Некоторые исследователи Подземья (по мнению одних — гении, а по другим мнению других — сумасшедшие) научились путешествовать среди жутевранов. Эти путешественники затыкают уши, избегая воздействия песни стаи, и пытаются подражать странным крикам и щебетанию жутевранов. Преуспеть в этом удивительно несложно, учитывая простые умы этих существ. Принятый в стаю, путешественник может пройти через Подземье в сопровождении сотен гуманоидных существ, которых все другие хищники стараются избегать.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 7,
      },
    },
    {
      header: 'Невольные исследователи',
      text: `По мере того, как старые пути становятся непроходимыми, или если стая несет большие потери в определенной области, жутевраны иногда намечают новый маршрут миграции. В зависимости от их нового маршрута толпа этих существ может появиться в подземелье, соединяющем Подземье и поверхностный мир. Пробираясь через такое подземелье, стая часто вынуждает живущих в нём чудовищ бежать в более безопасные логова. Когда они неизбежно выходят на поверхность, жадные аппетиты жутевранов заставляют их быстро сожрать всю жизнь в новом окружении, включая близлежащие поселения.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 7,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DIRE_CORBY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 7,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  vulnerabilityList: [
    DAMAGE_THUNDER,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Жуткая какофония',
      description: `Любое существо, кроме жутевранов, начинающее ход в пределах 60 футов от ★СУЩЕСТВО★ и слышащее ★его★, должно пройти испытание Мудрости СЛ 11. При провале до начала своего следующего хода это существо не может карабкаться, совершать Рывки, и сотворять заклинания сложнее заговоров.`,
    },
    ABILITY_KEEN_HEARING,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Когтем_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
  ],
}
