import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SourceInfo from '@/components/SourceInfo'
import HeaderBlock from '@/components/HeaderBlock'
import Markdown from '@/components/Markdown'

import generateTextLinks from '@/utils/generateTextLinks'

import './DescriptionStyles.less'

const DescriptionComponent = ({header, subHeader, upHeader, text, columnCount, source, className, isFlexible, ...rest}) => (
  <article
    className={classNames(
      `Description Description-columnCount_${columnCount} ${className}`,
      {
        'Description-isFlexible': isFlexible,
      }
    )}
    {...rest}
  >
    {upHeader
      ? <div className='Description_upHeader'>{upHeader}</div>
      : null}
    <HeaderBlock
      className='Description_header'
      subHeader={subHeader}
    >
      {header}
    </HeaderBlock>
    <Markdown className='Description_content'>
      {generateTextLinks(text)}
    </Markdown>
    {
      source
        ? (
          <SourceInfo
            className='Description_source'
            source={source}
            themeColumn
            useFullName
          />
        )
        : null
    }
  </article>
)

DescriptionComponent.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  text: PropTypes.string.isRequired,
  columnCount: PropTypes.number.isRequired,
  source: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

DescriptionComponent.defaultProps = {
  className: '',
  header: '',
  subHeader: '',
  source: null,
}

export default DescriptionComponent
