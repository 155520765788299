const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const axesDescription = require('./../../constants/axesDescription')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {
  VOLUME_UNIT_PINT,
} = require('./../../../volumeUnitList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_PALADIN,
} = require('./../../../pcClassIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_TOA,
  SOURCE_XGTE,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {
  DAMAGE_PIERCING,
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
} = require('./../../../damageTypeList')
const {
  CAT_ANIMALS,
  CAT_EJECTIVES,
  CAT_EQUIPMENT,
  CAT_MEDIUM_ARMOR,
  CAT_MUSIC_TOOLS,
  CAT_SIMPLE_MELEE_WEAPON,
  CAT_TOOLS,
} = require('./../../../gearCategoryList')
const {
  WEAPON_LIGHT,
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')

const {
  GEAR_ANTITOXIN,
  GEAR_FLASK,
  GEAR_HADROSAURUS,
  GEAR_HALF_PLATE,
  GEAR_HAMMER,
  GEAR_HAMMER_SLEDGE,
  GEAR_HANDAXE,
  GEAR_HEALERS_KIT,
  GEAR_HERBALISM_KIT,
  GEAR_HIDE_ARMOR,
  GEAR_HOLY_WATER,
  GEAR_HORN,
  GEAR_HOURGLASS,
  GEAR_HUNTING_TRAP,
  GEAR_POTION_OF_HEALING,
  GEAR_VIAL,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_HIDE_ARMOR,
    genderId: GENDER_MALE,
    name: 'Шкурный доспех',
    nameByCase: {
      nominative: 'шкурный доспех',
      genitive: 'шкурного доспеха',
      accusative: 'шкурный доспех',
      instrumental: 'шкурным доспехом',
    },
    nameAlt: 'Сыромятный доспех',
    nameEn: 'Hide Armor',
    description: `Этот грубый доспех состоит из толстых мехов и шкур. Обычно их носят племена варваров, злые гуманоиды и прочие народы, у которых нет инструментов и материалов для создания более качественных доспехов.`,
    cost: 1000,
    weight: 12,
    acChangeTo: 12,
    acUseDexMod: true,
    acDexModMax: 2,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_MEDIUM_ARMOR,
  },
  {
    id: GEAR_HALF_PLATE,
    genderId: GENDER_MULTIPLE,
    name: 'Полулаты',
    nameByCase: {
      nominative: 'полулаты',
      genitive: 'полулат',
      accusative: 'полулаты',
      instrumental: 'полулатами',
    },
    nameAlt: [
      'Кольчужно-латный доспех',
      'Неполные латы',
    ],
    nameEn: 'Half Plate',
    description: [
      `Полулаты состоят из сформированных металлических пластин, покрывающих большую часть тела владельца. В них не входит защита для ног кроме простых поножей, закреплённых кожаными ремнями.

Этот тип доспехов очень прочен, но у него есть открытые промежутки. Это слабые места и их может найти опытный противник.`,
      {
        header: 'Неполные латы',
        text: `Этот тяжёлый доспех состоит из стальных плит, закреплённых поверх стёганого гамбезона, защищающего солдата от паха до шеи.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    cost: 75000,
    weight: 40,
    hidingDisadvantage: true,
    acChangeTo: 15,
    acUseDexMod: true,
    acDexModMax: 2,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_MEDIUM_ARMOR,
  },
  {
    id: GEAR_HANDAXE,
    genderId: GENDER_MALE,
    name: 'Топорик',
    nameByCase: {
      nominative: 'топорик',
      genitive: 'топорика',
      accusative: 'топорик',
      instrumental: 'топориком',
    },
    nameAlt: 'Ручной топор',
    nameEn: 'Handaxe',
    description: axesDescription,
    damage: {
      diceType: 6,
      diceCount: 1,
    },
    cost: 500,
    damageType: DAMAGE_SLASHING,
    weight: 2,
    source: [
      {
        id: SOURCE_PHB,
        page: 149,
      },
      {
        id: SOURCE_AYAGWnW,
        page: 111,
      },
    ],
    weaponPropList: [
      WEAPON_LIGHT,
      WEAPON_THROWN,
      {
        id: WEAPON_RANGE,
        value: {
          normal: 20,
          max: 60,
        },
      },
    ],
    category: CAT_SIMPLE_MELEE_WEAPON,
  },
  {
    id: GEAR_HEALERS_KIT,
    genderId: GENDER_MALE,
    name: 'Набор лекаря',
    nameByCase: {
      nominative: 'набор лекаря',
      genitive: 'набора лекаря',
      accusative: 'набор лекаря',
      instrumental: 'набором лекаря',
    },
    nameAlt: 'Комплект целителя',
    nameEn: 'Healer’s Kit',
    description: `Это кожаный кошель с бинтами, мазями и шинами. Набор годится для 10 использований. Вы можете действием потратить одно использование набора для стабилизации существа, у которого 0 хитов, не совершая проверку Мудрости (Медицина).
    
Комплект целителя очень полезен, если Вы забыли взять целителя в отряд или если именно ваш целитель и ранен!`,
    cost: 500,
    weight: 3,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_HAMMER_SLEDGE,
    genderId: GENDER_MALE,
    name: 'Молот, кузнечный',
    nameByCase: {
      nominative: 'Молот, кузнечный',
      accusative: 'Молот, кузнечный',
      instrumental: 'Молотом, кузнечным',
    },
    nameEn: 'Hammer, sledge',
    cost: 200,
    weight: 10,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_HAMMER,
    genderId: GENDER_MALE,
    name: 'Молоток',
    nameByCase: {
      nominative: 'Молоток',
      accusative: 'Молоток',
      instrumental: 'Молотком',
    },
    nameEn: 'Hammer',
    cost: 100,
    weight: 3,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_HUNTING_TRAP,
    genderId: GENDER_MALE,
    name: 'Капкан',
    nameByCase: {
      nominative: 'Капкан',
      accusative: 'Капкан',
      instrumental: 'Капканом',
    },
    nameAlt: 'Охотничий капкан',
    nameEn: 'Hunting Trap',
    description: `Тяжёлая железная ловушка, которую можно установить на земле, чтобы ловить больших зверей (или неосторожных врагов).

Если Вы действием установите эту ловушку, она образует стальное кольцо с зазубренными краями, которая захлопывается, когда в её центр наступает существо. Капкан привязывается толстой цепью к неподвижному предмету, такому как дерево или колышек, вбитый в землю. Существо, наступившее в центр, должно пройти испытание Ловкости СЛ 13, иначе оно получает колющий урон 1к4 и прекращает перемещение. Впоследствии, пока существо не высвободится из ловушки, его перемещения ограничены длиной цепи (обычно 3 фута). Любое существо может действием совершить проверку Силы СЛ 13, чтобы высвободить себя или другое существо, находящееся в пределах досягаемости. Каждая проваленная проверка причиняет пойманному существу колющий урон 1.`,
    damage: {
      diceType: 4,
      diceCount: 1,
    },
    cost: 500,
    damageType: DAMAGE_PIERCING,
    weight: 25,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_HOURGLASS,
    genderId: GENDER_MULTIPLE,
    name: 'Песочные часы',
    nameByCase: {
      nominative: 'Песочные часы',
      accusative: 'Песочные часы',
      instrumental: 'Песочными часами',
    },
    nameEn: 'Hourglass',
    cost: 2500,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_HOLY_WATER,
    genderId: GENDER_FEMALE,
    name: 'Святая вода',
    nameByCase: {
      nominative: 'Святая вода',
      accusative: 'Святую воду',
      instrumental: 'Святой водой',
    },
    nameEn: 'Holy Water',
    description: [
      `Вы можете действием облить содержимым этой [фляги](GEAR:${GEAR_FLASK}) существо, находящееся в пределах 5 фт, или кинуть флягу на 20 фт, ломая при ударе. Совершите дальнобойную атаку по целевому существу, считая святую воду импровизированным оружием. Если цель — исчадие или нежить, она получает урон излучением 2к6.

[Жрец](PC_CLASS:${PC_CLASS_CLERIC}) или [паладин](PC_CLASS:${PC_CLASS_PALADIN}) может создать святую воду, исполнив особый ритуал. Этот ритуал исполняется 1 час, использует толчёное серебро на 25 зм и требует, чтобы заклинатель потратил ячейку заклинаний 1 уровня.`,
      {
        header: 'Святая вода',
        text: `Немного святости для всех нуждающихся.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    damage: {
      diceType: 6,
      diceCount: 2,
    },
    range: {
      normal: 20,
      max: 20,
    },
    cost: 2500,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 1,
    },
    damageType: DAMAGE_RADIANT,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EJECTIVES,
  },
  {
    id: GEAR_HERBALISM_KIT,
    genderId: GENDER_MALE,
    name: 'Набор травника',
    nameByCase: {
      nominative: 'набор травника',
      genitive: 'набора травника',
      accusative: 'набор травника',
      instrumental: 'набором травника',
    },
    nameEn: 'Herbalism Kit',
    description: `В этот набор входят разнообразные инструменты, такие как ножницы, ступка и пестик, а также мешочки и [флаконы](GEAR:${GEAR_VIAL}), используемые травниками при создании снадобий и зелий. Владение этим набором позволяет добавлять бонус мастерства к проверкам характеристик, совершённым при опознании и использовании трав. Кроме того, владение этим набором требуется для создания [противоядия](GEAR:${GEAR_ANTITOXIN}) и [зелья лечения](GEAR:${GEAR_POTION_OF_HEALING}).

Владение набором травника позволяет вам распознавать растения и надёжно сохранять их полезные части.

**Компоненты.** Набор травника включает в себя

* мешочки для хранения трав,
* секатор,
* кожаные перчатки для сбора растений,
* несколько стеклянных банок,
* ступку,
* пестик.

**Магия.** Ваши осведомлённость в природе и использовании растений может дать дополнительные знания к магическим исследованиям, когда дело касается растений и попыток опознать зелье.

**Расследование.** Когда Вы обследуете заросшую растениями зону, владение этим набором помогает вам находить детали и зацепки, которые другие бы проглядели.

**Медицина.** Ваше мастерство травника улучшает ваши способности лечить болезни и раны, расширяя доступные методы благодаря использованию лекарственных растений.

**Природа и Выживание.** Когда Вы путешествуете в дикой местности, ваши навыки травничества облегчают опознание растений и поиск источников еды, которые другие могут не заметить.

**Опознание растений.** Вы можете быстро распознать большинство растений по запаху и внешнему виду.

| Действие       | СЛ |
|----------------|----|
| Найти растения | 15 |
| Опознать яд    | 20 |
`,
    cost: 500,
    weight: 3,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 84,
      },
    ],
    category: CAT_TOOLS,
  },
  {
    id: GEAR_HORN,
    genderId: GENDER_MALE,
    name: 'Рожок',
    nameByCase: {
      nominative: 'рожок',
      genitive: 'рожка',
      accusative: 'рожок',
      instrumental: 'рожком',
    },
    nameEn: 'Horn',
    description: musicalToolDescriptionList,
    cost: 300,
    weight: 2,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_HADROSAURUS,
    genderId: GENDER_MALE,
    name: 'Гадрозавр',
    nameByCase: {
      nominative: 'гадрозавр',
      genitive: 'гадрозавра',
      accusative: 'гадрозавра',
      instrumental: 'гадрозавром',
    },
    nameEn: 'Hadrosaurus',
    description: 'Скорость: 40 фт. Грузоподъёмность 450 фунтов.',
    cost: 10000,
    source: [
      {
        id: SOURCE_TOA,
        page: 29,
      },
      {
        id: SOURCE_TOA,
        page: 218,
      },
    ],
    category: CAT_ANIMALS,
  },
]

module.exports = gearRawList
