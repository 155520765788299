const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1,
} = require('./../../../../crList')
const {
  SIZE_TINY,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_QUASIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Квазит',
  nameEn: 'Quasit',
  id: CREATURE_QUASIT,
  description: [
    `Квазиты водятся на Нижних Планах. Физически слабые, они держатся в тени, чтобы плести интриги и ложь. Более мощные демоны используют квазитов для почтовых пересылок и шпионажа, когда не пожирают их и не растаскивают их друг от друга, чтобы скоротать время.

Квазит может предстать в животном облике, но в истинном виде он выглядит как 2-футовый зелёный гуманоид с колючим хвостом и рогами. Когти на руках и ногах квазита содержат раздражающий яд. Нападая, он предпочитает быть невидимым.`,
    ...demonDescription,
  ],
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 91,
  },
  armor: 13,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 5,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 10,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием принять звериный облик, напоминающий летучую мышь (скорость 10 фт. летая 40 фт.), многоножку (40 фт., лазая 40 фт.), или жабу (40 фт., плавая 40 фт.), или принять свой истинный облик. Во всех обликах его характеристики остаются теми же самыми, исключая указанные изменения скорости. Всё несомое и носимое им снаряжение не превращается. Он принимает свой истинный облик, если умирает.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Когти',
      comment: 'в зверином облике Укус',
      description: 'Цель должна пройти испытание Телосложения СЛ 10, иначе получит урон ядом 5 (2к4) и станет отравленной на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Испуг',
      description: `Одно существо на выбор ★СУЩЕСТВО★ в пределах 20 футов от ★него★ должно пройти испытание Мудрости СЛ 10, или испугаться на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, с помехой, если она видит ★СУЩЕСТВО★, оканчивая эффект на себе при успехе.`,
      limit: {
        count: 1,
        period: 'день',
      },
    },
    {
      name: 'Невидимость',
      description: `★СУЩЕСТВО★ магическим образом становится невидимым, пока не атакует или не использует _Испуг_, или пока не окончится ★его★ концентрация (как при концентрации на заклинании). Всё снаряжение, которое ★СУЩЕСТВО★ несёт или носит, становится невидимым вместе с ★ним★.`,
    },
  ],
  genderId: GENDER_MALE,
}
