const {
  SPELL_GIFT_OF_ALACRITY,
  SPELL_REALITY_BREAK,
  SPELL_TEMPORAL_SHUNT,
  SPELL_TIME_RAVAGE,
} = require('./../../spellIdList')

module.exports = [
  SPELL_GIFT_OF_ALACRITY,
  SPELL_REALITY_BREAK,
  SPELL_TEMPORAL_SHUNT,
  SPELL_TIME_RAVAGE,
]
