const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_GREATAXE,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_ORC,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  orcDescription,
  orcNote,
} = require('./../../../../textCommonParts')
const {ABILITY_AGGRESSIVE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_ORC_WAR_CHIEF} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {GOD_GRUUMSH} = require('./../../../../godIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_INTIMIDATION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Боевой вождь орков',
  nameEn: 'Orc War Chief',
  id: CREATURE_ORC_WAR_CHIEF,
  description: [
    `Боевой вождь орков это самый сильный и коварный член племени. Его правление длится до тех пор, пока он поддерживает страх и уважение среди членов племени и регулярно удовлетворяет их кровожадность, иначе он будет казаться слабым.`,
    {
      header: `Наследники резни`,
      text: `[Груумш](GOD:${GOD_GRUUMSH}) дарует особые благословения вождям, которые постоянно проявляют себя в бою, и наполняет их частичкой своей дикости. Одарённый боевой вождь обнаруживает, что его оружие врезается глубже во врагов, позволяя ему устраивать большую бойню.`,
      source: {
        id: SOURCE_MM,
        page: 229,
      },
    },
    {
      header: `Король Обальд Многострел`,
      text: `Король Обальд из племени Многострелов — легенда среди боевых вождей Забытых Королевств, и самый знаменитый вождь орков в истории D&D.

Будучи умнее и интуитивнее, чем большинство орков, Обальд убил своего вождя, чтобы занять его место. Искусный в военном деле и известный своим буйным нравом, он прослыл свирепым противником в бою. На протяжении многих лет он присоединял другие племена к своему, пока не возглавил орду тысяч.

Обальд использовал свою силу и влияние, выкраивая себе царство на Хребте Мира — гряде гор, контролируемых многочисленными крепостями дварфов, людей и эльфов.

После годов кровавого конфликта с более цивилизованными соседями, Обальд сделал немыслимое, и заключил мирный договор с врагами. Этот договор смутил многих орков, находившихся под его командованием. Договор был либо хитрой уловкой Обальда, чтобы выиграть время для усиления армии и решающего натиска на Дикие Рубежи, либо он служил тревожным признаком того, что Обальд оставил пути [Груумша](GOD:${GOD_GRUUMSH}) и подлежит уничтожению.`,
      source: {
        id: SOURCE_MM,
        page: 229,
      },
    },
    ...orcDescription,
  ],
  note: [
    {
      text: `Он орудовал своим длинным зазубренным ножом варварски, разрывая горло короля под одобрительные вопли своего легиона. Свирепый орк не остановился на достигнутом, неумолимо водя нож вперед и назад до тех пор, пока голова короля дварфов не отделилась от плеч.`,
      author: `Отчёт о жестокости боевого вождя Хартуска после битвы при Хладной Долине`,
    },
    orcNote,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ORC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 229,
  },
  armor: {
    ac: 16,
    gearId: GEAR_CHAIN_MAIL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 18,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ORC,
    LANG_COMMON,
  ],
  cr: CR_4,
  featureList: [
    ABILITY_AGGRESSIVE,
    {
      name: 'Ярость Груумша',
      description: `★СУЩЕСТВО★ причиняет дополнительный урон 4 (1к8), когда попадает атакой оружием (уже учтено в атаках).`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Секирой_ или _Копьём_`,
    },
    {
      gearId: GEAR_GREATAXE,
      attack: {
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
        },
      },
    },
    {
      gearId: GEAR_SPEAR,
      attack: {
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
        },
      },
    },
    {
      name: 'Боевой клич',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Все существа на выбор ★СУЩЕСТВО★ в пределах 30 футов, слышащие его, и пока не находящиеся под действием _Боевого клича_, до начала следующего хода ★СУЩЕСТВО★ совершают броски атаки с преимуществом. После этого ★СУЩЕСТВО★ может бонусным действием совершить одну атаку.`,
    },
  ],
  genderId: GENDER_MALE,
}
