const {
  SOURCE_DND3_5_PHB,
  SOURCE_DND3_5_RoS,
  SOURCE_PHB,
} = require('./../../sourceList')
const {
  LANG_TYPE_STANDARD,
} = require('./../../languageTypeList')
const {
  PC_RACE_GNOME,
} = require('./../../pcRaceIdList')
const {
  ALPHABET_DETHEK,
} = require('./../../alphabetList')
const {
  LANG_DWARVISH,
  LANG_GNOMISH,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_GNOMISH,
  alphabetId: ALPHABET_DETHEK,
  type: LANG_TYPE_STANDARD,
  name: {
    nominative: 'Гним',
    genitive: 'Гнима',
    instrumental: 'Гнимом',
    prepositional: 'Гниме',
  },
  name5eOfficial: {
    nominative: 'Гномий',
    genitive: 'Гномьего',
    instrumental: 'Гномьим',
    prepositional: 'Гномьем',
  },
  nameAlt: 'Гномий',
  nameEn: 'Gnim',
  nameEnAlt: 'Gnomish',
  dictionary: {
    list: [
      [`алмаз`, `вэд`],
      [`белый`, `вэд`],
      [`благородный`, `херл`],
      [`благословлённый`, `ян`],
      [`бог`, `ян`],
      [`богиня`, `ян`],
      [`божественный`, `ян`],
      [`боль`, `йен`],
      [`быстрый`, `пин`],
      [`великан`, `эус`],
      [`великий`, `арум`],
      [`веселье`, `рэр`],
      [`весёлый`, `рэр`],
      [`ветер`, `пин`],
      [`воздух`, `пин`],
      [`волосатый`, `тикк`],
      [`вооружённый`, `уран`],
      [`вор`, `уанн`],
      [`враг`, `йен`],
      [`героический`, `арум`],
      [`герой`, `арум`],
      [`гибкий`, `оэн`],
      [`глубокий`, `сег`],
      [`голубой`, `фэр`],
      [`горячий`, `кэр`],
      [`грубый`, `микк`],
      [`дальность`, `сег`],
      [`далёкий`, `сег`],
      [`дворянин`, `херл`],
      [`дерево`, `бэр`],
      [`дитя`, `неб`],
      [`длинный`, `сег`],
      [`добродетель`, `херл`],
      [`добродетельный`, `херл`],
      [`доброжелательный`, `ов`],
      [`добрый`, `эрф`],
      [`доверенный`, `лен`],
      [`дом`, `чик`],
      [`достоинство`, `херл`],
      [`достойный`, `херл`],
      [`драгоценность`, `лен`],
      [`друг`, `эрф`],
      [`дружественный`, `каллад`],
      [`дружественный`, `эрф`],
      [`жена`, `энн`],
      [`животное`, `тикк`],
      [`жизнь`, `бэр`],
      [`забавный`, `ван`],
      [`загадка`, `хед`],
      [`замаскированный`, `бар`],
      [`защита`, `гэр`],
      [`защитный`, `гэр`],
      [`зелёный`, `рас`],
      [`земля`, `флан`],
      [`земляной`, `фен`],
      [`злой`, `йен`],
      [`знание`, `скор`],
      [`изогнутый`, `оэн`],
      [`изумруд`, `рас`],
      [`иллюзионист`, `уанн`],
      [`инструмент`, `дин`],
      [`искажённый`, `оэн`],
      [`искусство`, `скор`],
      [`каменный`, `флан`],
      [`компаньон`, `энн`],
      [`красивый`, `чик`],
      [`красный`, `кэр`],
      [`крепкий`, `фен`],
      [`крот`, `урд`],
      [`кузен`, `каллад`],
      [`лабиринт`, `оэн`],
      [`лес`, `бэр`],
      [`ложный`, `бар`],
      [`ложь`, `уанн`],
      [`лукавый`, `уанн`],
      [`любимый`, `ов`],
      [`любящий`, `ов`],
      [`лёд`, `фэр`],
      [`мать`, `гар`],
      [`механический`, `лун`],
      [`меховой`, `тикк`],
      [`меч`, `адд`],
      [`могучий`, `арум`],
      [`могущественный`, `вэд`],
      [`могущественный`, `эус`],
      [`молодой`, `неб`],
      [`мудрость`, `скор`],
      [`муж`, `энн`],
      [`мягкий`, `палл`],
      [`невинный`, `неб`],
      [`необходимый`, `энн`],
      [`новый`, `неб`],
      [`огонь`, `кэр`],
      [`оружие`, `дин`],
      [`острый`, `адд`],
      [`отец`, `гар`],
      [`открытый`, `бэр`],
      [`первый`, `гар`],
      [`питомец`, `тикк`],
      [`плащ`, `палл`],
      [`податливый`, `палл`],
      [`поддерживающий`, `уран`],
      [`полезный`, `дал`],
      [`полезный`, `дин`],
      [`правитель`, `херл`],
      [`праздник`, `рэр`],
      [`прохладный`, `фэр`],
      [`прочный`, `фен`],
      [`пустота`, `микк`],
      [`пустыня`, `микк`],
      [`путаный`, `хед`],
      [`пушистый`, `тикк`],
      [`рай`, `чик`],
      [`раненый`, `йен`],
      [`ребёнок`, `неб`],
      [`ремесленник`, `лун`],
      [`ремесло`, `скор`],
      [`ритуал`, `рэр`],
      [`родина`, `рас`],
      [`родня`, `каллад`],
      [`родственный`, `каллад`],
      [`рубин`, `кэр`],
      [`сапфир`, `фэр`],
      [`свобода`, `пин`],
      [`секрет`, `хед`],
      [`сильный`, `гэр`],
      [`синий`, `фэр`],
      [`скалистый`, `флан`],
      [`скрытый`, `бар`],
      [`слепой`, `урд`],
      [`сложный`, `лун`],
      [`смешной`, `ван`],
      [`сокровище`, `лен`],
      [`страж`, `уран`],
      [`суровый`, `микк`],
      [`тайна`, `хед`],
      [`твёрдый`, `вэд`],
      [`твёрдый`, `флан`],
      [`тень`, `бар`],
      [`ткань`, `палл`],
      [`топор`, `адд`],
      [`тёплый`, `ов`],
      [`уважаемый`, `гар`],
      [`узел`, `оэн`],
      [`умелый`, `дал`],
      [`умение`, `дал`],
      [`устройство`, `лун`],
      [`хитрый`, `уанн`],
      [`хозяин`, `дал`],
      [`хозяйка`, `дал`],
      [`холм`, `фен`],
      [`холмы`, `фен`],
      [`хороший`, `лен`],
      [`хранитель`, `уран`],
      [`ценный`, `дин`],
      [`честный`, `чик`],
      [`чёрный`, `урд`],
      [`шут`, `ван`],
      [`шутка`, `ван`],
      [`шутник`, `ван`],
      [`щит`, `гэр`],
      [`юный`, `неб`],
    ],
    source: {
      id: SOURCE_DND3_5_RoS,
      page: 49,
    },
  },
  description: [
    {
      text: `Гномий язык, использующий дварфийский алфавит, хорошо известен благодаря техническим трактатам и каталогам знаний об окружающем мире.`,
      source: {
        id: SOURCE_PHB,
        page: 38,
      },
    },
    {
      text: `Язык [гномов](PC_RACE:${PC_RACE_GNOME}) использует корни [языка дварфов](LANG:${LANG_DWARVISH}), но он обновлен благодаря трактатам о научных изобретениях, и каталогам с информацией про окружающий мир поверхности. Люди ботаники, натуралисты и инженеры, очень часто изучают гномий язык, для того что бы затем можно было прочесть множество потрясающих книг, технических тематик (механика, самоцветы), где гномы достигли высокого уровня развития.`,
      source: {
        id: SOURCE_DND3_5_PHB,
        page: 13,
      },
    },
    {
      header: 'Язык',
      text: `Гномий язык построен на адаптированной версии дварфийского алфавита. Это структурно очень простой язык, с плавными интонациями и широким использованием согласных.

Гномий редко можно услышать в повседневном использовании кем-то кроме самих [гномов](PC_RACE:${PC_RACE_GNOME}), потому что его огромный специализированный лексикон пугает всех, кто не говорит на нём с детства. [Гномы](PC_RACE:${PC_RACE_GNOME}) верят в важность тонких степеней отличия, и поэтому их язык помогает им выражать это внимание к деталям. Такое понятие как «любовь» может быть представлено семью или восемью разными словами, выражающими разные виды любви. То же касается таких понятий как «вода», «зелёный», «ветер» или почти любой силы или идеи, с которой [гномы](PC_RACE:${PC_RACE_GNOME}) встречались и у которой есть больше одного вида или применения.

Огромный словарь гномьего языка делает его сложным для изучения, но также это значит что он идеален для академического использования. Он достаточно понятен для использования в дискуссиях о чём угодно, от размеров шестерней до списков покупок на день и от анализа снов до философских дебатов. Гномий язык подходит для обсуждения математики, диалогов о науке или алхимии, теории магии и почти любой темы, которую можно назвать. Из-за своей гибкости он широко распространён в академических кругах по всему миру (и вне его). Научные труды, пьесы, дипломатические документы, магические книги и бессчётные академические работы были написаны на гномьем или переведены на него. Многие мудрецы считают гномий «языком учёных», что самим [гномам](PC_RACE:${PC_RACE_GNOME}) очень нравится.

Гномья литература очень обширна и включает почти все виды искусства и науки, существующие в мире. Любая крупная библиотека содержит хотя бы несколько текстов на гномьем в любом существующем разделе знаний и литературы, а по-настоящему крупные собрания может содержать пятьдесят, сотню, или даже больше книг.`,
      source: {
        id: SOURCE_DND3_5_RoS,
        page: 48,
      },
    },
    {
      header: 'Гномий разговорник',
      text: `_«Ни те фаркали, куо лун верра лу»_. Дословный перевод: _«Если ты это видишь, это неправильное „это“»_. Гномы часто используют эту фразу, напоминая себе что хорошие вещи часто скрыты и что первое впечатление бывает обманчивым. Подозрительный гном может даже полагать что первое впечатление часто бывает сознательно искажённым.

_«Мелчэн барук бар колен»_ — поговорка старых охотников, буквально значащая _«Выманивай барсучиху из норы»_. Гномы используют это для широкого описания преимуществ, получаемых от вытаскивания врага из его зоны комфорта.

_«Вел Карш!»_ — буквально это значит _«Руинь их!»_. Это типичный гномий боевой клич.

_«Ти фиралу!»_ — _«Я тебя надул!»_. Одно из самых распространённых гномьих восклицаний. Вы услышите _«ти фиралу»_ везде, где встречается гномья магия — от карточной игры до смертельной дуэли.

_«Шэл вук»_ — _«пустая коробка»_. Длинная последовательность гномьих загадок включает множество вопросов про то, что может быть в воображаемой коробке. Последняя загадка в цепочке — это обязательно загадка про пустую коробку. Очень разочаровывающе гадать и гадать что может быть в коробку только ради того чтобы узнать что она пуста. Соответственно, «пустая коробка» означает гномью обиду. _Шэл вук_’ом называется тот, кто разочаровывает, тратит чужое время или не имеет ничего ценного.`,
      source: {
        id: SOURCE_DND3_5_RoS,
        page: 48,
      },
    },
  ],
  typicalSpeakers: 'Гномы',
  isRealLang: true,
  isReady: true,
}
