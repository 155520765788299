const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_SUBGROUP_DRUIDIC} = require('./../../languageSubGroupList')
const {PC_CLASS_DRUID} = require('./../../pcClassIdList')
const {SOURCE_MGZN_DRGN_ANNUAL_1999} = require('./../../sourceList')
const {PLACE_MOONSHAES} = require('./../../placeIdList')
const {
  LANG_DAELIC,
  LANG_DRUIDIC,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_DAELIC,
  type: LANG_TYPE_HIDDEN,
  langSubGroupId: LANG_SUBGROUP_DRUIDIC,
  name: {
    nominative: 'Дэлик',
    genitive: 'Дэлика',
    instrumental: 'Дэликом',
    prepositional: 'Дэлике',
  },
  nameEn: 'Dælic',
  typicalSpeakers: 'Друиды островов Муншае',
  spokenPlaceList: PLACE_MOONSHAES,
  description: {
    header: `Дэлик`,
    text: `Язык Дэлик схож с [Друэданом](LANG:${LANG_DRUIDIC}), но это другой тайный язык [друидов](PC_CLASS:${PC_CLASS_DRUID}), поклоняющихся Матери-Земле на островах Муншае.`,
    source: {
      id: SOURCE_MGZN_DRGN_ANNUAL_1999,
      page: 30,
    },
  },
  isReady: true,
  isRealLang: true,
}
