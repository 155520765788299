const {
  CAST_MATERIAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../../attackTypeList')
const {DAMAGE_THUNDER} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {SPELL_BOOMING_BLADE} = require('./../../../../../spellIdList')
const {TIME_ROUND} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_BOOMING_BLADE,
  name: 'Громовой клинок',
  nameEn: 'Booming Blade',
  description: `Вы взмахиваете оружием, использованным при сотворении этого заклинания, и совершаете им рукопашную атаку по одному из существ в пределах дальности этого заклинания.

При попадании, цель получает обычные последствия от атаки и оказывается окутанной громовой энергией до начала Вашего следующего хода. Если цель по собственной воле передвинется на 5 футов до того момента, она получает 1к8 урона звуком, и заклинание заканчивается.`,
  highLvlCast: `Урон заклинания растёт с повышением Вашего уровня. На 5-м уровне рукопашная атака наносит дополнительно 1к8 урона звуком по цели и урон при передвижении возрастает до 2к8. Обе кости урона возрастают ещё на 1к8 на 11-м (2к8 и 3к8) и на 17-м (3к8 и 4к8) уровнях.`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 5,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'рукопашное оружие, стоимостью не менее 1 зм',
  duration: { timeId: TIME_ROUND, count: 1 },
  effect: {
    attackType: ATTACK_MELEE_WEAPON,
    damage: {
      type: DAMAGE_THUNDER,
      diceCount: 1,
      diceType: 8,
      diceBonus: 0,
    },
  },
  source: {
    id: SOURCE_TCoE,
    page: 153,
  },
}
