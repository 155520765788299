const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_10,
} = require('./../../../../crList')
const {
  GOD_LOLTH,
} = require('./../../../../godIdList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DETECT_THOUGHTS,
  SPELL_DOMINATE_PERSON,
  SPELL_WEB,
} = require('./../../../../spellIdList')
const {
  CAST_MATERIAL,
} = require('./../../../../castComponentList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_SPIDER_CLIMB,
  ABILITY_WEB_WALKER,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_YOCHLOL,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Йоклол',
  nameEn: 'Yochlol',
  id: CREATURE_YOCHLOL,
  description: [
    `Йоклол, Прислужницы [Ллос](GOD:${GOD_LOLTH}), действующие по воле Королевы Пауков, это её шпионы, надсмотрщики и агенты зла. Они всегда подле неё в Ямах Демонической Паутины, но иногда [Ллос](GOD:${GOD_LOLTH}) отправляет их на Материальный план для защиты храмов и помощи особо привилегированным жрицам. Йоклол не рождаются вне царства [Ллос](GOD:${GOD_LOLTH}), и не служат никаким демоническим повелителям кроме своей королевы.

Вне Бездны, чтобы скрыть демоническую натуру, йоклол может принять вид женщины дроу или чудовищного паука. Истинный её облик — столб жёлтой слизи с единственным злобным глазом. В истинном облике и в облике дроу касание йоклол жалит ядом так же, как и укус её паучьего облика.`,
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 90,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_INSIGHT]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_UNDERCOMMON,
    LANG_ELVEN,
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `Йоклол может действием принять облик, напоминающий женщину дроу или громадного паука, или принять свой истинный облик. Во всех обликах её статистика остаётся той же самой. Всё несомое и носимое ей снаряжение не превращается. Она принимает свой истинный облик, если умирает.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SPIDER_CLIMB,
    ABILITY_WEB_WALKER,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_THOUGHTS,
          SPELL_WEB,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DOMINATE_PERSON,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Удар (в облике демона)',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 2,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 6,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Удар (в облике дроу)',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 2,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 6,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Укус (в облике паука)',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 2,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 6,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Туманный облик',
      description: `★СУЩЕСТВО★ становится токсичным туманом или возвращается в свой истинный облик. Всё снаряжение, которое ★он★ несёт и носит, превращается вместе с ★ним★. ★Он★ принимает свой истинный облик, если умирает.

Находясь в туманном облике, ★СУЩЕСТВО★ ★недееспособен★, и не может говорить. ★Он★ обладает скоростью полёта 30 футов, может парить, и может проходить сквозь любое пространство, если оно не герметично. ★Он★ с преимуществом проходит испытания Силы, Ловкости и Телосложения, и обладает иммунитетом к немагическому урону.

Находясь в туманном облике, ★СУЩЕСТВО★ может входить в пространство существ и останавливаться там. Каждый раз, когда существо начинает ход, разделяя с ★СУЩЕСТВО★ пространство, оно должны пройти испытание Телосложения СЛ 14, иначе станет отравленным до начала своего следующего хода. Будучи отравленной таким образом, цель недееспособна.`,
    },
  ],
  genderId: GENDER_MALE,
}
