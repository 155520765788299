const {SOURCE_VGTM} = require ('./../../sourceList')
const {CREATURE_TYPE_FILBORG} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  PC_RACE_ELF,
  PC_RACE_HUMAN,
  PC_RACE_ORC,
} = require ('./../../pcRaceIdList')
const {
  PC_CLASS_DRUID,
} = require ('./../../pcClassIdList')


module.exports = {
  id: CREATURE_TYPE_FILBORG,
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'фирболг',
        genitive: 'фирболга',
        dative: 'фирболгу',
        accusative: 'фирболга',
        instrumental: 'фирболгом',
        prepositional: 'фирболге',
      },
      [GENDER_FEMALE]: {
        nominative: 'фирболгша',
        genitive: 'фирболгши',
        dative: 'фирболгше',
        accusative: 'фирболгшу',
        instrumental: 'фирболгшой',
        prepositional: 'фирболгше',
      },
    },
    plural: {
      nominative: 'фирболги',
      genitive: 'фирболгов',
      dative: 'фирболгам',
      accusative: 'фирболгов',
      instrumental: 'фирболгами',
      prepositional: 'фирболгах',
    },
  },
  nameEn: 'Firbolg',
  note: {
    text: `Мы провели три месяца, выслеживая зеленого дракона прежде, чем определили лес, в котором он устроил убежище. В наш второй день в лесу, проснувшись, мы обнаружили в центре нашего лагеря голову дракона. Совелисс сказал мне, что, должно быть, этот лес принадлежит фирболгам, и они хотят показать, что нам здесь делать больше нечего. Он уверил меня, что, если мы задержимся, наши головы могут быть следующими.`,
    author: 'Гимбл, Заметки охотника за сокровищам',
  },
  description: [
    {
      header: 'Фирболги',
      text: `Племена фирболгов, уединившиеся в отдаленных лесных крепостях, предпочитают проводить свои дни в безмолвной гармонии с лесом. Будучи спровоцированными, фирболги демонстрируют грозные навыки владения оружием и магией друидов.`,
      source: {
        id: SOURCE_VGTM,
        page: 108,
      },
    },
    {
      header: 'Скромные стражи ',
      text: `Ничего фирболги не любят больше, чем спокойный день, проведённый в окружении деревьев в старом лесу. Они рассматривают леса как священные места, символизирующие душу мира, и памятники стойкости живого.

Будучи хранителями природы, фирболги живут за счёт земли, стремясь соблюдать природный баланс. Их методы отражают бережливость и замечательную изобретательность в использовании природных ресурсов. На протяжении урожайного лета они откладывают лишние орехи, фрукты и ягоды. Когда наступает зима, они разбрасывают всё, что запасли, чтобы гарантировать, что лесные животные доживут до весны.

На взгляд фирболга, нет ничего хуже, чем жадность. Фирболги полагают, что мир остается здоровым только когда каждое существо берёт только то, в чем нуждается. Материальные блага, особенно драгоценные камни и золото, мало для них значат. Какой от них толк, когда долгой зимой кончилась еда?`,
      source: {
        id: SOURCE_VGTM,
        page: 108,
      },
    },
    {
      header: 'Прирожденные друиды ',
      text: `У фирболгов есть склонность к магии [друидов](PC_CLASS:${PC_CLASS_DRUID}). Почтительность их культуры к природе, объединённая с их силой и проницательным умом, делает инстинктивное постижение этой магии частью их развития. Почти каждый фирболг знает несколько заклинаний, в основном для маскировки своего присутствие, и многие продолжают постигать природную магию.

Фирболги, которые становятся [друидами](PC_CLASS:${PC_CLASS_DRUID}), служат лидерами крепости. Перед тем как племя предпримет какое–либо действие, [друиды](PC_CLASS:${PC_CLASS_DRUID}) взвешивают нужды своего племени и последствия, который это действие будет иметь для леса и остальной части мира природы. Племена фирболгов лучше будут голодать, чем перетруждать землю во время недорода.`,
      source: {
        id: SOURCE_VGTM,
        page: 108,
      },
    },
    {
      header: 'Незримые наблюдатели ',
      text: `Будучи хранителями природы, фирболги предпочитают не попадаться на глаза и не привлекать внимания. Они не пытаются подчинить себе природу, а скорее стремятся гарантировать ей процветание и выживание согласно её собственным законам.

Фирболги используют свою магию, чтобы сохранить своё присутствие в лесу в тайне. Такой подход позволяет им избегать политики и противоборства между [эльфами](PC_RACE:${PC_RACE_ELF}), [людьми](PC_RACE:${PC_RACE_HUMAN}) и [орками](PC_RACE:${PC_RACE_ORC}). Подобные события заботят фирболгов только когда они затрагивают лес.

Даже при угрозе вторжения, фирболги предпочитают воздействовать тихо и незаметно, предотвращая ущерб своим землям. Они используют свое волшебство, чтобы сделать лес непривлекательным для изучения, на время отводя ручьи, прогоняя дичь, крадя важные инструменты, и изменяя тропы, чтобы отряды охотников или лесорубов безнадежно заблудились. О присутствии фирболгов можно догадаться из–за отсутствия животных и странной тишины, как будто лес хочет избежать излишнего внимания. Чем быстрее путешественники решат идти дальше, тем лучше.

Если эта тактика проваливается, фирболги действуют более прямолинейно. Наблюдая за поселением, они решают, что делать дальше. Если чужаки выглядят миролюбиво, фирболги приходят к ним и вежливо просят их уйти, даже предлагая еду и другие припасы, чтобы поспособствовать их скорейшему отъезду.

Если они настаивают на том, чтобы остаться, уважая природу и беря только то, в чём нуждаются, и живя в гармонии с лесом, фирболги рассмотрят возможность подружиться с ними, если чужаки пообещают не вредить лесу. Если поселенцы явно демонстрируют недобрые намерения, фирболги прибегнут к грубой силе и магии для единственной сокрушающей атаки.`,
      source: {
        id: SOURCE_VGTM,
        page: 108,
      },
    },
    {
      header: 'Имена фирболгов ',
      text: `Фирболги берут себе эльфийские имена, когда имеют дело с чужаками, хотя сама идея имён кажется им странной. Они знают животных и растения леса без формальных названий, а вместо этого узнают детей леса по их делам, привычкам, и другим действиям.

Таким же образом, говоря о своём племени, они просто называют его домом. Имея дело с другими расами, фирболги называют свои земли тем же названием, какое используют окружающие народы, как знак тактичности и гостеприимства, но в среде своих сородичей они говорят просто «дом».

Иногда фирболги принимают прозвища или имена, которые дают им чужаки, считая, что те, кому нужны имена, могут называть их вообще как угодно.`,
      source: {
        id: SOURCE_VGTM,
        page: 109,
      },
    },
  ],
}
