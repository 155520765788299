const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_GUARD_DRAKE,
  CREATURE_GUARD_DRAKE_BLACK,
  CREATURE_GUARD_DRAKE_BLUE,
  CREATURE_GUARD_DRAKE_GREEN,
  CREATURE_GUARD_DRAKE_RED,
  CREATURE_GUARD_DRAKE_WHITE,
  CREATURE_TIAMAT,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Сторожевой дрейк',
    text: `[Сторожевой дрейк](CREATURE:${CREATURE_GUARD_DRAKE}) это рептилия, созданная из чешуи дракона с помощью странного и жуткого ритуала. После должного обучения дрейк становится послушным, верным и отличным сторожевым зверем, который может выполнять простые команды.

**Дары драконов.** Ритуал, для создания сторожевых дрейков был изначально разработан культом [Тиамат](CREATURE:${CREATURE_TIAMAT}), но получил свое распространение и среди других групп достаточно сведущих в магии и связанных с драконами. Помощь дракона необходима для успешного проведения ритуала и дракон, как правило, предоставляет свою помощь в качестве награды своим союзникам или почитателям.

Ритуал длится несколько дней и для него требуется 10 фунтов свежих чешуек дракона (подаренных драконом), большое количество свежего мяса и железный котел. Когда процесс завершен, яйцо размером с полурослика появляется из котла и вылупляется в течение нескольких часов.

**Стремящийся к обучению.** Только что вылупившиеся сторожевые дрейки запоминают первое существо которое его кормит (как правило, это тот, кто будет его обучать), создавая агрессивные, но доверительные отношения с ним. Сторожевые дрейки полностью вырастают в течении двух-трёх недель и могут быть обучены за этот срок. Они могут быть обучены точно тому же, что и сторожевые собаки.

Сторожевой дрейк напоминает дракона, из чешуи которого был создан, но он бескрылый, более приземистый и мускулистый. Дрейки не могут размножаться и их чешуя не может быть использована для изготовления новых сторожевых дрейков.`,
    source: {
      id: SOURCE_VGTM,
      page: 194,
    },
  },
  {
    header: 'Вариант: Цветные сторожевые дрейки',
    text: `Каждый тип чешуи и крови цветного дракона создает определённого сторожевого дрейка, который напоминает уменьшенного дракона этого типа, со своими уникальными способностями, связанными с этим типом. Каждый из них имеет характерные особенности, описанные ниже.

* **[Белый сторожевой дрейк](CREATURE:${CREATURE_GUARD_DRAKE_WHITE}).** Белый сторожевой дрейк имеет скорость копания 20 футов, скорость лазания 30 футов и сопротивление к урону холодом.
* **[Зелёный сторожевой дрейк](CREATURE:${CREATURE_GUARD_DRAKE_GREEN}).** Зелёный сторожевой дрейк — амфибия (может дышать как воздухом так и водой), его скорость плавания 30 футов и он имеет сопротивляемость к урону ядом.
* **[Красный сторожевой дрейк](CREATURE:${CREATURE_GUARD_DRAKE_RED}).** Красный сторожевой дрейк имеет скорость лазания 30 футов и сопротивление к урону огнём.
* **[Синий сторожевой дрейк](CREATURE:${CREATURE_GUARD_DRAKE_BLUE}).** Синий сторожевой дрейк имеет скорость копания 20 футов и сопротивление к урону электричеством.
* **[Чёрный сторожевой дрейк](CREATURE:${CREATURE_GUARD_DRAKE_BLACK}).** Черный сторожевой дрейк — амфибия (может дышать как воздухом так и водой), его скорость плавания 30 футов и он имеет сопротивляемость к урону кислотой.`,
    source: {
      id: SOURCE_VGTM,
      page: 194,
    },
  },
]
