const {PC_CLASS_ARTIFICER} = require('./../../pcClassIdList')
const PROGRESS_TABLE_ID = require('./../../PROGRESS_TABLE_ID')
const SPELL_SLOT_TABLE_ID = require('./../../SPELL_SLOT_TABLE_ID')
const {artificerSpellCircleList} = require('./../../spellCircleByCasterType')
const {
  GEAR_TINKERS_TOOLS,
  GEAR_THIEVES_TOOLS,
} = require('./../../gearIdList')
const {SOURCE_ERFTLW} = require('./../../sourceList')
const {SPELL_CURE_WOUNDS} = require('./../../spellIdList')
const {spellIdCollectionByPcClassId} = require('./../../spellIdPcClassIdBounds')

const {abilityScoreImprovementTemplate} = require('./../featureCommonRawList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_ARTIFICER,
  FEATURE_ARTIFICER_SPECIALIST,
  FEATURE_CANTRIPS_ARTIFICER,
  FEATURE_FLASH_OF_GENIUS,
  FEATURE_INFUSE_ITEM,
  FEATURE_INFUSING_AN_ITEM,
  FEATURE_INFUSIONS_KNOWN,
  FEATURE_MAGIC_ITEM_ADEPT,
  FEATURE_MAGIC_ITEM_MASTER,
  FEATURE_MAGIC_ITEM_SAVANT,
  FEATURE_MAGICAL_TINKERING,
  FEATURE_PREPARING_SPELLS_ARTIFICER,
  FEATURE_RITUAL_CASTING_ARTIFICER,
  FEATURE_SOUL_OF_ARTIFICE,
  FEATURE_SPELL_STORING_ITEM,
  FEATURE_SPELLCASTING_ABILITY_ARTIFICER,
  FEATURE_SPELLCASTING_ARTIFICER,
  FEATURE_THE_RIGHT_TOOL_FOR_THE_JOB,
  FEATURE_TOOL_EXPERTISE,
  FEATURE_TOOLS_REQUIRED,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_SPELLCASTING_ARTIFICER,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 1,
    text: `Вы изучили действие магии и способы направлять её в предметы.

В результате Вы научились сотворять заклинания. Со стороны Вы не выглядите как кто-то, кто творит магию. Всё выглядит так будто Вы создаёте чудеса с помощью обычных вещей и диковинных устройств.`,
    spellCircleLvl: artificerSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_ARTIFICER],
    source: {
      id: SOURCE_ERFTLW,
      page: 56,
    },
  },
  {
    id: FEATURE_CANTRIPS_ARTIFICER,
    name: `Заговоры`,
    nameEn: `Cantrips`,
    lvl: 1,
    text: `На 1 уровне Вы знаете два заговора по Вашему выбору из списка заклинаний изобретателя.

На последующих уровнях Вы узнаете дополнительные заговоры изобретателя по Вашему выбору, как показано в столбце «[Заговоры](PC_CLASS:${PC_CLASS_ARTIFICER}#${PROGRESS_TABLE_ID})» таблицы развития класса.

Когда Вы получаете уровень в этом классе, Вы можете заменить один известный Вам заговор изобретателя на другой из списка заклинаний изобретателя.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 56,
    },
  },
  {
    id: FEATURE_PREPARING_SPELLS_ARTIFICER,
    name: `Подготовка и сотворение заклинаний`,
    nameEn: `Preparing and Casting Spells`,
    lvl: 1,
    text: `Таблица «[Ячейки заклинаний изобретателя](PC_CLASS:${PC_CLASS_ARTIFICER}#${SPELL_SLOT_TABLE_ID})» показывает, сколько ячеек для сотворения заклинаний у Вас есть. Для сотворения одного из Ваших заклинаний изобретателя 1 круга или выше Вы должны потратить ячейку заклинаний того же круга или выше, что и само заклинание. Вы восстановите все потраченные ячейки, когда завершите длинный отдых.

Вы подготавливаете список заклинаний изобретателя, доступных для сотворения. При этом Вы выбираете число заклинаний, равное модификатору Интеллекта + половина уровня изобретателя, округляя в меньшую сторону (минимум одно заклинание). Круг заклинаний не должен превышать круга самой высокой имеющейся у Вас ячейки заклинаний.

Например, если Вы изобретатель 5 уровня, то у Вас есть четыре ячейки заклинаний 1 круга и две ячейки 2 круга. При Интеллекте 14 ваш список подготовленных заклинаний может включать в себя четыре заклинания 1 или 2 круга, в любой комбинации. Если Вы подготовили заклинание 1 круга [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}), Вы можете сотворить его, используя ячейку 1 или 2 круга. Сотворение заклинания не удаляет его из списка подготовленных заклинаний.

Вы можете изменить список подготовленных заклинаний, когда завершаете длинный отдых. Подготовка нового списка заклинаний изобретателя требует некоторой подготовки с инструментами: не менее 1 минуты за круг заклинания для каждого заклинания в Вашем списке.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 56,
    },
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_ARTIFICER,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 1,
    text: `Интеллект — Ваша заклинательная характеристика для заклинаний изобретателя. Понимание теории магии позволяет Вам освоить эти заклинания на потрясающем уровне.

Вы используете Интеллект в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете модификатор Интеллекта при определении СЛ испытаний от Ваших заклинаний изобретателя, и при броске атаки заклинаниями. 

**СЛ испытания = 8 + бонус мастерства + модификатор Интеллекта**

**Модификатор атаки заклинаниями = бонус мастерства + модификатор Интеллекта**`,
    source: {
      id: SOURCE_ERFTLW,
      page: 56,
    },
  },
  {
    id: FEATURE_RITUAL_CASTING_ARTIFICER,
    name: `Ритуальное колдовство`,
    nameEn: `Ritual Casting`,
    lvl: 1,
    text: `Вы можете сотворить заклинание изобретателя как ритуал, если у этого заклинания есть ключевое слово «ритуал», и оно подготовлено Вами.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 56,
    },
  },
  {
    id: FEATURE_TOOLS_REQUIRED,
    name: `Нужда в инструментах`,
    nameEn: `Tools Required`,
    lvl: 1,
    text: `Вы сотворяете заклинания изобретателя с помощью своих инструментов.

Вы обязаны держать в руке заклинательную фокусировку (которой для Вас являются [воровские](GEAR:${GEAR_THIEVES_TOOLS}) или ремесленные инструменты, которыми Вы владеете), когда сотворяете любое заклинание своей способностью [Использование заклинаний](FEATURE:${FEATURE_SPELLCASTING_ARTIFICER}).

После получения на 2 уровне способности [Инфузированные предметы](FEATURE:${FEATURE_INFUSE_ITEM}), Вы также можете использовать как фокусировку любой инфузированный предмет.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 56,
    },
  },
  {
    id: FEATURE_MAGICAL_TINKERING,
    name: `Магические поделки`,
    nameEn: `Magical Tinkering`,
    lvl: 1,
    text: `Вы изучаете как наделять искрой магии обычные предметы.

Для использования этой способности Вы должны держать в руке [инструменты ремонтника](GEAR:${GEAR_TINKERS_TOOLS}) или другие ремесленные инструменты. Вы действием касаетесь крошечного немагического предмета и наделяете его одним из следующих магических свойств по Вашему выбору:

* Предмет сияет ярким светом на 5 футов и тусклым светом ещё на 5 футов.
* Если существо тронет его, предмет озвучит записанное сообщение, которое будет слышно в пределах 10 футов. Вы произносите это сообщение, когда наделяете предмет этим свойством и запись не может быть дольше 6 секунд.
* Предмет постоянно источает выбраный Вами запах или невербальный звук (шум ветра, шум волн, чириканье и тому подобное). Выбраный феномен воспринимается в пределах 10 футов.
* Неподвижный визуальный эффект появляется на одной из поверхностей предмета. Эффект может быть картиной, текстом не длиннее 25 слов, линиями и фигурами или же смесью всех этих элементов.

Выбраное свойство длится вечно. Действием, Вы можете коснуться этого предмета и лишить его этого свойства.

Вы можете наделить магией несколько предметов, касаясь одного из них каждый раз, когда Вы применяете эту способность, но каждый предмет может иметь только одно из этих магических свойств одновременно. Максимальное число предметов, которое Вы можете наделять искрами магии равно Вашему модификатору Интеллекта (минимум один предмет). Если Вы попробуете превысить максимум, самое старое свойство немедленно исчезнет и после этого появится новое.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 56,
    },
  },
  {
    id: FEATURE_INFUSE_ITEM,
    name: `Инфузированные предметы`,
    nameEn: `Infuse item`,
    lvl: 2,
    text: `Вы получаете способность вливать в обычные вещи особые магические инфузии.

Созданные инфузиями магические предметы являются действующими прототипами постоянных предметов.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 58,
    },
  },
  {
    id: FEATURE_INFUSING_AN_ITEM,
    name: `Инфузирование предметов`,
    nameEn: `Infusing an Item`,
    lvl: 2,
    text: `Когда Вы завершаете длинный отдых, Вы можете коснуться немагического предмета и влить в него одну из Ваших инфузий изобретателя, превращая его в магический предмет. Инфузии работают только на определённые виды предметов, о чём говорится в описании каждой инфузии. Если предмет требует настройки, Вы можете, вливая инфузию, немедленно настроиться на него. Если Вы решите настроиться на него позже, то Вы должны будете делать это по обычным правилам настройки на магические предметы.

Ваша инфузия существует в предмете независимо, но, если Вы умрёте, она исчезнет через число дней, равное Вашему модификатору Интеллекта (минимум 1 день). Инфузия также исчезает, если Вы отказываетесь от неё, изучая другую инфузию вместо неё.

Вы можете влить инфузии сразу в несколько немагических предметов в конце длинного отдыха. Максимальное число предметов указано в столбце «[Инфузированные предметы](PC_CLASS:${PC_CLASS_ARTIFICER}#${PROGRESS_TABLE_ID})» в таблице развития класса. Вы должны коснуться каждого из предметов и каждая из Ваших инфузий может быть влита только в один предмет одновременно. Кроме того, один и тот же предмет не может содержать больше одной инфузии одновременно. Если Вы инфузируете предмет свыше максимального числа, то самая старая инфузия немедленно исчезнет, после чего применится новая.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 58,
    },
  },
  {
    id: FEATURE_INFUSIONS_KNOWN,
    name: `Известные инфузии`,
    nameEn: `Infusions Known`,
    lvl: 2,
    text: `Получая эту способность, выберите для изучения четыре инфузии изобретателя.

Вы сможете выбрать дополнительные инфузии, достигая определённых уровней в этом классе, как показано в столбце «[Известные инфузии](PC_CLASS:${PC_CLASS_ARTIFICER}#${PROGRESS_TABLE_ID})» в таблице развития класса.

Когда Вы получаете уровень в этом классе, Вы можете заменить одну известную Вам инфузию на новую.`,
    listAllInfusions: true,
    source: {
      id: SOURCE_ERFTLW,
      page: 58,
    },
  },
  {
    id: FEATURE_ARTIFICER_SPECIALIST ,
    name: `Специализация изобретателя`,
    nameEn: `Artificer Specialist`,
    lvl: [3, 5, 9, 15],
    text: `Вы выбираете свою специализацию.

Ваш выбор даёт Вам умения на 5, 9 и 15 уровнях.`,
    showSubClassList: true,
    source: {
      id: SOURCE_ERFTLW,
      page: 58,
    },
  },
  {
    id: FEATURE_THE_RIGHT_TOOL_FOR_THE_JOB,
    name: `Нужные инструменты`,
    nameEn: `The Right Tool for the Job`,
    lvl: 3,
    text: `Вы узнаёте как сделать именно те инструменты, которые Вам сейчас нужны.

С [инструментами ремонтника](GEAR:${GEAR_TINKERS_TOOLS}) в руке Вы можете магически создать один набор ремесленных инструментов в свободном пространстве в пределах 5 футов. Это займёт у Вас 1 час непрерывной работы, которую можно совмещать с коротким или длинным отдыхом.

Хотя инструменты создаются магией, они сами немагические. Они исчезнут, когда Вы снова используете это умение.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 58,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_ARTIFICER,
    source: {
      id: SOURCE_ERFTLW,
      page: 58,
    },
  },
  {
    id: FEATURE_TOOL_EXPERTISE,
    name: `Эксперт в инструментах`,
    nameEn: `Tool Expertise`,
    lvl: 6,
    text: `Ваш бонус мастерства удаивается для всех проверок характеристик, использующих владение инструментами.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 58,
    },
  },
  {
    id: FEATURE_FLASH_OF_GENIUS,
    name: `Вспышка гениальности`,
    nameEn: `Flash of Genius`,
    lvl: 7,
    text: `Вы получаете способность изобретать решения в сложных ситуациях.

Когда Вы или другое существо, видимое Вами в пределах 30 футов, совершает проверку характеристики или проходит испытание, Вы можете реакцией добавить свой модификатор Интеллекта к результату.

Вы можете использовать эту способность число раз, равное Вашему модификатору Интеллекта (минимум один раз). Вы восстанавливаете все потраченные использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 58,
    },
  },
  {
    id: FEATURE_MAGIC_ITEM_ADEPT,
    name: `Знаток магических предметов`,
    nameEn: `Magic Item Adept`,
    lvl: 10,
    text: `Вы достигаете глубокого понимания того, как именно нужно создавать и использовать магические предметы.

* Вы можете настраиваться на четыре магических предмета.
* Если Вы создаёте магический предмет обычной или необычной редкости, это занимает у Вас только четверь обычного времени и стоит половину обычных трат золота.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 58,
    },
  },
  {
    id: FEATURE_SPELL_STORING_ITEM,
    name: `Сохранение заклинаний в предметах`,
    nameEn: `Spell-Storing Item`,
    lvl: 11,
    text: `Вы узнаёте как можно в предмете сохранять заклинание.

Когда Вы заканчиваете длинный отдых, Вы можете коснуться одного простого или воинского оружия или одного предмета, который Вы используете как заклинательную фокусировку и сохранить в нём заклинание. Выберите для этого заклинание 1 или 2 круга из списка заклинаний изобретателя со временем сотворения 1 действие (Вам не нужно его подготавливать).

Пока существо держит предмет, оно действием может сотворить сохранённое в нём заклинание, используя Вашу заклинательную характеристику. Если заклинание требует концентрации, существо должно концентрироваться на нём. Заклинание сохраняется в предмете, пока оно не будет использовано число раз, равное Вашему двукратному модификатору Интеллекта (минимум дважды) или пока Вы снова не воспользуетесь этой способностью, стобы сохранить какое-либо другое заклинание.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 59,
    },
  },
  {
    id: FEATURE_MAGIC_ITEM_SAVANT,
    name: `Ценитель магических предметов`,
    nameEn: `Magic Item Savant`,
    lvl: 14,
    text: `Ваши навыки обращения с магическими предметами развиваются ещё больше.

* Вы можете настраиваться на пять магических предметов.
* Вы игнорируете все требования класса, расы, заклинаний и уровней при настройке или использовании магического предмета.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 59,
    },
  },
  {
    id: FEATURE_MAGIC_ITEM_MASTER,
    name: `Мастер магических предметов`,
    nameEn: `Magic Item Master`,
    lvl: 18,
    text: `Вы можете настраиваться на шесть магических предметов.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 59,
    },
  },
  {
    id: FEATURE_SOUL_OF_ARTIFICE,
    name: `Дух изобретательства`,
    nameEn: `Soul of Artifice`,
    lvl: 20,
    text: `Вы создаёте таинственную связь со своими магическими предметами, и можете использовать её для своей защиты.

* Вы получаете бонус +1 ко всем испытаниям за каждый магический предмет, на который Вы настроены.
* Если Ваши хиты опустились до 0, и Вы не умерли сразу, Вы можете реакцией развеять одну свою активную инфузию, вместо этого опуская свои хиты до 1.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 59,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_ARTIFICER,
  })
)
