const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_YUGOLOTH} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_YUGOLOTH,
  nameEn: 'Yugoloth',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'юголот',
      genitive: 'юголота',
      dative: 'юголоту',
      accusative: 'юголота',
      instrumental: 'юголотом',
      prepositional: 'юголоте',
    },
    plural: {
      nominative: 'юголоты',
      genitive: 'юголотов',
      dative: 'юголотам',
      accusative: 'юголотов',
      instrumental: 'юголотами',
      prepositional: 'юголотах',
    },
  },
}
