const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_SLAAD,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_8} = require('./../../../../crList')
const {CREATURE_SLAAD_TADPOLE} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_SLAAD} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {slaadDescription} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Слаад-головастик`,
  nameEn: 'Slaad Tadpole',
  id: CREATURE_SLAAD_TADPOLE,
  description: slaadDescription,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_SLAAD,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 266,
  },
  armor: 12,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 10,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 5,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_SLAAD,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
