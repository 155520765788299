const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_SHAPESHIFTER} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_SHAPESHIFTER,
  nameEn: 'Shapeshifter',
  genderId: GENDER_MALE,
  notRealRace: true,
  name: {
    singular: {
      nominative: 'перевёртыш',
      genitive: 'перевёртыша',
      dative: 'перевёртышу',
      accusative: 'перевёртыша',
      instrumental: 'перевёртышом',
      prepositional: 'перевёртыше',
    },
    plural: {
      nominative: 'перевёртыши',
      genitive: 'перевёртышей',
      dative: 'перевёртышам',
      accusative: 'перевёртышей',
      instrumental: 'перевёртышами',
      prepositional: 'перевёртышах',
    },
  },
}
