const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  CREATURE_TYPE_FEY,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_RADIANT,
} = require('./../../../../damageTypeList')
const {
  GENDER_MIDDLE,
} = require('./../../../../genderList')
const {
  CREATURE_REFLECTION,
  CREATURE_SHADOW,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Отражение',
  nameEn: 'Reflection',
  id: CREATURE_REFLECTION,
  parentId: CREATURE_SHADOW,
  description: {
    header: 'Отражения',
    text: `Отражения существ в зеркальной зоне области начинают действовать самостоятельно, выходят из зеркал и атакуют. Отражения являются двумерными переливающимися версиями созданий, которые их отбрасывали.`,
    source: {
      id: SOURCE_TCoE,
      page: 159,
    },
  },
  source: {
    id: SOURCE_TCoE,
    page: 159,
  },
  creatureTypeIdList: CREATURE_TYPE_FEY,
  genderId: GENDER_MIDDLE,

  editPropCollection: {
    vulnerabilityList: ({ vulnerabilityList = [] }) => [
      ...vulnerabilityList.filter(id => id !== DAMAGE_RADIANT),
      DAMAGE_BLUDGEONING,
    ],
  },
}
