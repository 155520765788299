const {
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CONFUSION,
  SPELL_COUNTERSPELL,
  SPELL_DETECT_MAGIC,
  SPELL_DISINTEGRATE,
  SPELL_DOMINATE_PERSON,
  SPELL_DREAM,
  SPELL_ETHEREALNESS,
  SPELL_FEEBLEMIND,
  SPELL_FIRE_BOLT,
  SPELL_FIREBALL,
  SPELL_FLY,
  SPELL_GEAS,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HOLD_PERSON,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_MINOR_ILLUSION,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_SUGGESTION,
  SPELL_WALL_OF_FIRE,
  SPELL_WITCH_BOLT,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_NAGPA,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ANY,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_17} = require('./../../../../crList')
const {CREATURE_NAGPA} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {ravenQueenAndNagpaDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Нагпа',
  nameEn: 'Nagpa',
  id: CREATURE_NAGPA,
  description: [
    ...ravenQueenAndNagpaDescriptionList,
    {
      header: 'Кукловоды',
      text: `Нагпы действуют в тени, управляя событиями, что приносят разрушение. Будучи умелыми пользователями магии, они могут пустить в ход ряд заклинаний, чтобы влиять на других представителей рас, воздействуя на их решения, и дёргать за ниточки, превращая их в невольных пособников, сеющих разрушение. У нагп развито большое терпение в выполнении своих замыслов, а также они выполняют сразу одновременно несколько планов, каждый на разной стадии выполнения, и поэтому, если с одним планом что-то идёт не так, они могут сконцентрироваться на другом. Обычно, нагпы появляются только тогда, когда их умысел почти выполнен, и они выходят из тени перед тем, как нанести заключительный удар, а затем они наслаждаются великим разрушением, которые принесли их замыслы.`,
      source: {
        id: SOURCE_MTOF,
        page: 212,
      },
    },
  ],
  note: {
    text: `Нагпы не могут изучить то, что ещё не разрушено. Представьте, каково им оказаться в ошеломительном и пугающем городе, полной жизни? Стоит ли удивляться тому, что они хотят уничтожить подобные места?`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_NAGPA,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 212,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 34,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 23,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 21,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
    {
      id: LANG_ANY,
      count: 5,
    },
  ],
  cr: CR_17,
  featureList: [
    {
      name: 'Развратить существо',
      description: `Нагпа бонусным действием выбирает целью существо, которое она может видеть в пределах 30 футов. Цель должна пройти испытание Харизмы СЛ 20. Злые существа проходят испытание с помехой. При провале цель становится очарованной нагпой до начала следующего хода нагпы. При успехе цель становится невосприимчивой к этому эффекту на следующие 24 часа.`,
    },
    {
      name: 'Паралич',
      restore: 6,
      description: `Нагпа бонусным действием заставляет всех существ в пределах 30 фт. от себя пройти испытание Мудрости СЛ 20, или стать парализоваными на 1 минуту. Парализованная цель может повторять испытание в конце каждого своего хода, завершая эффект при успехе. Нежить и конструкты невосприимчивы к этому эффекту.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 15,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_CHARM_PERSON,
      SPELL_CHILL_TOUCH,
      SPELL_CIRCLE_OF_DEATH,
      SPELL_CONFUSION,
      SPELL_COUNTERSPELL,
      SPELL_DETECT_MAGIC,
      SPELL_DISINTEGRATE,
      SPELL_DOMINATE_PERSON,
      SPELL_DREAM,
      SPELL_ETHEREALNESS,
      SPELL_FEEBLEMIND,
      SPELL_FIRE_BOLT,
      SPELL_FIREBALL,
      SPELL_FLY,
      SPELL_GEAS,
      SPELL_HALLUCINATORY_TERRAIN,
      SPELL_HOLD_PERSON,
      SPELL_MAGE_HAND,
      SPELL_MESSAGE,
      SPELL_MINOR_ILLUSION,
      SPELL_PRISMATIC_SPRAY,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_RAY_OF_ENFEEBLEMENT,
      SPELL_SUGGESTION,
      SPELL_WALL_OF_FIRE,
      SPELL_WITCH_BOLT,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
      1,
      1,
    ],
  },
  actionList: [
    {
      name: 'Посох',
      // gearId: GEAR_QUARTERSTAFF, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
