const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_WATER_BREATHING} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_SEA_HORSE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Морской конёк',
  nameEn: 'Sea Horse',
  id: CREATURE_SEA_HORSE,
  couldBeFamiliar: true,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 334,
  },
  armor: 11,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_SWIM]: 20,
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 8,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 2,
  },
  cr: CR_0,
  featureList: [
    ABILITY_WATER_BREATHING,
  ],
  genderId: GENDER_MALE,
}
