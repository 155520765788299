const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_HADOZEE} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_HADOZEE,
  nameEn: 'Hadozee',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'гадози',
      genitive: 'гадози',
      dative: 'гадози',
      accusative: 'гадози',
      instrumental: 'гадози',
      prepositional: 'гадози',
    },
    plural: {
      nominative: 'гадози',
      genitive: 'гадози',
      dative: 'гадози',
      accusative: 'гадози',
      instrumental: 'гадози',
      prepositional: 'гадози',
    },
  },
}
