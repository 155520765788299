const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    CR_4,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_KEEN_SMELL,
    ABILITY_POUNCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_DISPLACER_BEAST,
    CREATURE_KAMADAN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Камадан',
  nameEn: 'Kamadan',
  id: CREATURE_KAMADAN,
  description: `Камадан — кошкообразный хищник, похожий на леопарда с шестью змеями, растущими из плеч. Несмотря на то, что камадан имеет сходство с [ускользающим зверем](CREATURE:${CREATURE_DISPLACER_BEAST}), эти два существа не связаны (хотя некоторые мудрецы утверждают иное).

Камаданы обычно охотятся в одиночку или в парах. Они могут выдыхать облака сонного газа, которое обычно выпускается, прежде чем вступать в рукопашную схватку. Если у камадана в пределах дальности действия есть как враги, находящиеся в сознании, так и враги в отключке, он пытается убить врагов, находящихся в сознании прежде, чем прикончить всех спящих врагов.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 229,
  },
  armor: 13,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 14,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  cr: CR_4,
  featureList: [
    ABILITY_KEEN_SMELL,
    {
      id: ABILITY_POUNCE,
      range: 20,
      dc: 13,
      attack: 'две атаки: одну — _Укусом_ и одну — _Змеями_.',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну — _Укусом_ или _Когтями_ и одну — _Змеями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Змеи',
      description: `Цель должна пройти испытание Телосложения СЛ 12, получая 21 (6к6) урона ядом при провале, или половину этого урона при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Усыпляющее дыхание',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `Камадан выдыхает усыпляющий газ 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 12, иначе потеряют сознание на 10 минут. Этот эффект оканчивается для существа, если оно получает урон или кто-нибудь действием разбудит его.`,
    },
  ],
}
