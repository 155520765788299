const upLetter = require('./../../../../../../utils/upLetter')
const {damageTypeCollection} = require('./../../../../../damageTypeList')
const {GEAR_SCALE_MAIL} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    id,
    dragonTypeNameRu: {genitive: dragonName},
    dragonTypeNameEn,
    damageTypeId,
  }
) => ({
  id,
  name: `Доспех из чешуи ${dragonName} дракона`,
  nameEn: upLetter(`${dragonTypeNameEn} Dragon Scale Mail`),
  type: MGC_TYPE_ARMOR,
  gearType: GEAR_SCALE_MAIL,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `Этот доспех изготавливается из чешуи **${dragonName}** дракона. Иногда драконы сами собирают сброшенные чешуйки и дарят их гуманоидам. В других случаях успешные охотники тщательно выделывают и хранят шкуры убитых драконов. В любом случае, доспехи из чешуи драконов высоко ценятся.

Пока Вы носите этот доспех, Вы получаете бонус +1 к КД, получаете преимущество к испытаниям от _Ужасающего присутствия_ и _Оружия дыхания_ драконов, а также обладаете сопротивлением к одному виду урона: **${damageTypeCollection[damageTypeId].fullName}**.

Кроме того, Вы можете действием сосредоточиться, чтобы с помощью магии определить расстояние и направление до ближайшего **${dragonName}** дракона в пределах 30 миль. Это особое действие нельзя повторно совершать до следующего рассвета.`,
  genderId: GENDER_MALE,
  siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 159,
  },
})
