const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_UNARMORED_DEFENSE} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_SACRED_STONE_MONK} = require('./../../../../creatureIdList')
const {CR_1_2} = require('./../../../../crList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_VIBRATION_SENSE} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {blackEarthDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ACROBATICS,
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_PARRY,
  ABILITY_UNARMORED_MOVEMENT,
  ABILITY_UNARMORED_DEFENSE,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Монах священного камня',
  nameEn: 'Sacred Stone Monk',
  id: CREATURE_SACRED_STONE_MONK,
  description: [
    {
      header: 'Монахи священного камня',
      text: `Монахи священного камня изучают боевое искусство, в основу которого положена философия подражания силе скал и камней. Они носят маски, изображающие горгулий, и используют своё отточенное осязание, чтобы при контакте с землёй оценить окружающую обстановку. Монахи избегают разговоров или посторонних мыслей. Они живут только для того, чтобы закалить себя в лишениях и покарать тех, кто сомневается в силе стихийной земли.`,
      source: {
        id: SOURCE_PotA,
        page: 201,
      },
    },
    blackEarthDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_PotA,
    page: 201,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_UNARMORED_DEFENSE,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_VIBRATION_SENSE,
      value: 10,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_UNARMORED_DEFENSE,
    {
      id: ABILITY_UNARMORED_MOVEMENT,
      speed: 10,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Безоружный удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 2,
    },
  ],
}
