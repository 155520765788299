import React from 'react'

import AdsPlacer from '@/components/AdsPlacer'

import './AdsFooterStyles.less'

// Рекламный баннер РСЯ в футере
const AdsFooter = () => (
  <AdsPlacer
    blockId='R-A-2421058-1'
    className='AdsFooter'
    type='floorAd'
  />
)

export default AdsFooter
