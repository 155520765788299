const {ABILITY_HOLD_BREATH} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {howlingHatredDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_AERISI_KALINOTH,
  CREATURE_HOWLING_HATRED_INITIATE,
  CREATURE_HOWLING_HATRED_PRIEST,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ACROBATICS,
  SKILL_INTIMIDATION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  GEAR_STUDDED_LEATHER_ARMOR,
  GEAR_SCIMITAR,
  GEAR_DAGGER,
} = require('./../../../../gearIdList')
const {
  SPELL_BLADE_WARD,
  SPELL_GUST,
  SPELL_LIGHT,
  SPELL_PRESTIDIGITATION,
  SPELL_SHOCKING_GRASP,
  SPELL_FEATHER_FALL,
  SPELL_SHIELD,
  SPELL_WITCH_BOLT,
  SPELL_DUST_DEVIL,
  SPELL_GUST_OF_WIND,
  SPELL_GASEOUS_FORM,
} = require('./../../../../spellIdList')
const {
  LANG_AURAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Жрец Воющей Ненависти',
  nameEn: 'Howling Hatred Priest',
  id: CREATURE_HOWLING_HATRED_PRIEST,
  description: [
    {
      header: 'Жрец Воющей Ненависти',
      text: `Жрецы, как самые фанатичные из культистов воздуха, стремятся к физическим крайностям, чтобы уменьшить их плотность и массу, и стать лёгкими, словно листья на ветру. Измождённые и бледные, с вогнутыми животами, выпученными глазами и натянутой кожей, через которую просвечивают кости, эти живые скелеты поддерживают себя только воздухом и безграничной преданностью учениям культа. Сам ветер помогает им двигаться.

Жрецы Воющей Ненависти видят себя живыми продолжениями Ян-Си-Бина и помогают новобранцам сбросить оковы и принять «высшую цель». Как только рекруты готовы, жрецы решают, достойны ли они дальнейшего обучения в качестве [послушников](CREATURE:${CREATURE_HOWLING_HATRED_INITIATE}), или будут принесены в жертву. Жрецы защищают учение своего пророка — [Аэриси Калинос](CREATURE:${CREATURE_AERISI_KALINOTH}) — и жестоко пресекают любые попытки своих [послушников](CREATURE:${CREATURE_HOWLING_HATRED_INITIATE}) сбиться с верного пути.

Большинство культистов воздуха ненавидят жрецов, но никто не смеет выступить против них. Те, кто бросают им вызов, часто становятся объектами новых, ещё более страшных обрядов очищения.`,
      source: {
        id: SOURCE_PotA,
        page: 194,
      },
    },
    ...howlingHatredDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 194,
  },
  armor: {
    ac: 15,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 10,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  languageList: [
    LANG_AURAN,
    LANG_COMMON,
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_HOLD_BREATH,
      time: '30 минут',
    },
  ],
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_BLADE_WARD,
      SPELL_DUST_DEVIL,
      SPELL_FEATHER_FALL,
      SPELL_GASEOUS_FORM,
      SPELL_GUST,
      SPELL_GUST_OF_WIND,
      SPELL_LIGHT,
      SPELL_PRESTIDIGITATION,
      SPELL_SHIELD,
      SPELL_SHOCKING_GRASP,
      SPELL_WITCH_BOLT,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные, либо две дальнобойные атаки.`,
    },
    {
      gearId: GEAR_SCIMITAR,
    },
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
