module.exports = [
  ...require('./BGDIA'),
  ...require('./DDHC_MORD_01'),
  ...require('./DMG'),
  ...require('./FTD'),
  ...require('./magicItemRawList_ERFTLW'),
  ...require('./magicItemRawList_GOS'),
  ...require('./magicItemRawList_HOTDQ'),
  ...require('./magicItemRawList_MOOT'),
  ...require('./MTOF'),
  ...require('./PotA'),
  ...require('./TCOE'),
  ...require('./WDH'),
  ...require('./magicItemRawList_XGTE'),
  ...require('./magicItemRawList_UA_2018_08_10'),
]
