const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {DAMAGE_FIRE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_IMMOLATION} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_IMMOLATION,
  name: 'Испепеление',
  nameEn: 'Immolation',
  description: `Пламя обвивает одно существо, которое Вы видите в радиусе действия. Цель должна пройти испытание Ловкости. Существо получает урон огнём 8к6 при провале или половину этого урона при успехе. Также при провале испытания цель воспламеняется на время действия заклинания.

Горящая цель испускает яркий свет в радиусе 30 футов и тусклый свет в пределах еще 30 футов. В конце каждого своего хода цель повторяет испытание. При провале испытания цель получает урон огнём 4к6. При успехе заклинание оканчивается. Этот магический огонь не может быть погашен немагическими средствами.

Если урон от этого заклинания убивает цель, то цель превращается в пепел.`,
  lvl: 5,
  magicSchoolId: MAGIC_EVOCATION,
  range: 90,
  componentIdList: [CAST_VERBAL],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_FIRE,
      halfOnSuccess: true,
      diceCount: 8,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 154,
    },
    {
      id: SOURCE_EE,
      page: 17,
    },
  ],
}
