const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ACROBATICS,
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_DARKLING,
  CREATURE_TYPE_FEY,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_SHORTSWORD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_DARKLING_ELDER} = require('./../../../../creatureIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {darklingDescriptionList} = require('./../../../../textCommonParts')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_DARKNESS} = require('./../../../../spellIdList')

module.exports = {
  name: 'Старейшина дарклингов',
  nameEn: 'Darkling Elder',
  id: CREATURE_DARKLING_ELDER,
  description: darklingDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_DARKLING,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_VGTM,
    page: 147,
  },
  armor: {
    ac: 15,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ELVEN,
    LANG_SYLVAN,
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Ожог смерти',
      description: `Когда старейшина дарклингов умирает, магический свет испускается из него в радиусе 10 футов, пока его тело и пожитки, кроме металлических или магических предметов, сгорают дотла. Любое существо в этой области должно пройти испытание Телосложения СЛ 11. При провале существо получает 7 (2к6) урона излучением, и если оно может видеть свет, то слепнет до конца своего следующего хода. Если испытание пройдено успешно, то существо получает половину урона и не слепнет.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Старейшина дарклингов делает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_SHORTSWORD,
      description: `Если старейшина дарклингов атакует с преимуществом, то атака дополнительно наносит 10 (3к6) колющего урона.`,
    },
    {
      name: 'Тьма',
      comment: `Перезаряжается после короткого или длинного отдыха`,
      description: `Старейшина дарклингов сотворяет [Тьму](SPELL:${SPELL_DARKNESS}) без использования компонентов. Его заклинательная способность — Мудрость.`,
    },
  ],
}
