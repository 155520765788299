const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_TYPE_POTION} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {MAGIC_ITEM_POTION_OF_HEALING} = require('./../../../../../magicItemIdList')
const {magicItemRarityCollection} = require('./../../../../../magicItemRarityList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

const tableText = list.reduce(
  (text, {name, id, rarity, hits}) => {
    const {nameByGender} = magicItemRarityCollection[rarity]

    return `${text}
| [${name}](MAGIC_ITEM:${id}) | ${nameByGender[GENDER_MIDDLE]} | ${hits} |`
  },
  `
| Зелье | Редкость | Хитов восстанавливает |
|-------|----------|-----------------------|`
)

module.exports = {
  id: MAGIC_ITEM_POTION_OF_HEALING,
  name: `Зелье лечения`,
  nameEn: `Potion of Healing`,
  rarity: generateRarityList(list),
  type: MGC_TYPE_POTION,
  isConsumable: true,
  description: `Вы восстанавливаете хиты, когда выпиваете это зелье.
  
Количество восстанавливаемых хитов зависит от редкости зелья, как показано в таблице. 

Вне зависимости от силы зелья, оно представляет собой красную жидкость, сверкающую при встряхивании.

${tableText}`,
  genderId: GENDER_MIDDLE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 165,
  },
}
