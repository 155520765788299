const {
  SPELL_FLY,
  SPELL_INVISIBILITY,
  SPELL_LEVITATE,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
} = require('./../../../../../spellIdList')

module.exports = [
  SPELL_FLY,
  SPELL_INVISIBILITY,
  SPELL_LEVITATE,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
]
