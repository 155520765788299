const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ELECTRICITY,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_FLYBY,
  ABILITY_LEGENDARY_RESISTANCE_3,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ancientElementalDescriptionList} = require('./../../../../textCommonParts')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_23} = require('./../../../../crList')
const {CREATURE_ELDER_TEMPEST} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Древний шторм',
  nameEn: 'Elder Tempest',
  id: CREATURE_ELDER_TEMPEST,
  description: [
    `Ужасающие штормы проявляются в теле древнего шторма. Бытие, вырезанное из облаков, ветра, дождя и молний, древний шторм принимает форму скользящего по небу змея. Шторм топит сушу под собой дождями и бьёт по земле копьями молний. Суровые ветра воют вокруг него во время полёта подпитывая создаваемый им хаос.`,
    ...ancientElementalDescriptionList,
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MTOF,
    page: 159,
  },
  armor: 19,
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 120,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 28,
    [PARAM_CON]: 23,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 21,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
    DAMAGE_THUNDER,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_23,
  featureList: [
    {
      name: 'Воздушное тело',
      description: `Древний шторм может войти в пространство враждебного существа и оставаться там. Он может, не сжимаясь, проходить сквозь пространство, что меньше 1 дюйма.`,
    },
    ABILITY_FLYBY,
    ABILITY_LEGENDARY_RESISTANCE_3,
    {
      name: 'Живая гроза',
      description: `Древний шторм — эпицентр грозы с диаметром 1к6 + 4 миль. В этой зоне происходят обильные осадки в виде дождя или снега из-за чего местность становится едва видимой. Тяжёлый дождь также тушит огни и накладывает помехи на проверки Мудрости (Восприятие), зависящие от слуха.

Вдобавок, в местности бушуют сильные ветра. Ветра накладывают помехи на броски дальнобойных атак, и тушат огни и разгоняют туман.`,
    },
    {
      name: 'Осадное чудовище',
      description: `Древний шторм наносит двойные повреждения объектам и строениям.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Древний шторм совершает две атаки _Громовым ударом_.`,
    },
    {
      name: 'Громовой удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 20,
        hit: {
          type: DAMAGE_THUNDER,
          diceCount: 4,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Гроза',
      restore: 6,
      description: `Все существа, находящиеся на расстоянии 120 футов от Древнего шторма, должны пройти испытание Ловкости СЛ 20, получая 27 (6к8) урона электричеством при провале, или половину этого урона при успехе. Если существо проваливает испытание на 5 или больше, то оно ошеломляется до конца своего следующего хода.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Перемещение',
      description: `Древний шторм перемещается на расстояние, не превышающее его скорость.`,
    },
    {
      name: 'Удар молнией',
      cost: 2,
      description: `Древний шторм может вызвать молнию на любую точку поверхности, что находится под грозой. Каждое существо, находящееся в пределах 5 футов от места удара молнии, должно пройти испытание Ловкости СЛ 20, получая 16 (3к10) урона электричеством при провале, или половину этого урона при успехе.`,
    },
    {
      name: 'Вспышка бури',
      cost: 3,
      description: `Древний шторм испускает вспышку грома и ветра линией, длиной в 1 милю и шириной 20 футов. Объекты в зоне поражения этой способности получают 22 (4к10) урона звуком. Каждое существо должно пройти испытание Ловкости СЛ 21, иначе получит 22 (4к10) урона звуком и будет отброшено на 60 футов от стороны направления линии. Если отброшенная цель приземлится на неподвижный объект, вроде стены или пола, то получает 3 (1к6) дробящего урона за каждые 10 футов, что была отброшена. Если цель приземлится на другое существо, то другое существо должно пройти испытание Ловкости СЛ 19, иначе получит такой же урон и будет сбито с ног.`,
    },
  ],
}
