const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SPELL_MISTY_STEP} = require('./../../../../../spellIdList')
const {TIME_BONUS_ACTION} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MISTY_STEP,
  name: 'Туманный шаг',
  nameEn: 'Misty Step',
  description: `Окутавшись серебристым туманом, Вы телепортируетесь на 30 футов в свободное пространство, видимое Вами.`,
  lvl: 2,
  magicSchoolId: MAGIC_CONJURATION,
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  range: -1,
  componentIdList: [CAST_VERBAL],
  source: [
    {
      id: SOURCE_PHB,
      page: 285,
    },
    {
      id: SOURCE_SRD,
      page: 165,
    },
  ],
}
