const {
  SOURCE_UA_2017_04_03_STARTER_SPELLS,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {ATTACK_MELEE_SPELL} = require('./../../../../../attackTypeList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {DAMAGE_ACID} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_PRIMAL_SAVAGERY} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_PRIMAL_SAVAGERY,
  name: 'Первобытная дикость',
  nameEn: 'Primal Savagery',
  description: `Вы направляете первобытную магию, затачивающую Ваши зубы или ногти, и готовитесь к агрессивной атаке. Совершите рукопашную атаку заклинанием против одного существа в пределах 5 футов от вас. При попадании цель получает урон кислотой 1к10. После того, как Вы совершите атаку, Ваши зубы или ногти вернутся в нормальное состояние.`,
  highLvlCast: `Урон заклинания увеличивается на 1к10, когда Вы достигаете 5 уровня (2к10), 11 уровня (3к10) и 17 уровня (4к10).`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_SOMATIC],
  effect: {
    attackType: ATTACK_MELEE_SPELL,
    damage: {
      type: DAMAGE_ACID,
      diceCount: 1,
      diceType: 19,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 160,
    },
    {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 3,
    },
  ],
}
