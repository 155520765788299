const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_GIANT_OWL,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_FLYBY,
  ABILITY_KEEN_HEARING_AND_SIGHT,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_GIANT_OWL} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Гигантская сова',
  nameEn: 'Giant Owl',
  id: CREATURE_GIANT_OWL,
  description: `Гигантские совы часто дружат с феями и другими лесными существами, и являются защитниками лесного мира.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 324,
  },
  armor: 12,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_GIANT_OWL,
    {
      id: [
        LANG_COMMON,
        LANG_SYLVAN,
        LANG_ELVEN,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_FLYBY,
    ABILITY_KEEN_HEARING_AND_SIGHT,
  ],
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
