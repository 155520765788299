const {
  CREATURE_CHIMERA,
  CREATURE_GRIFFON,
  CREATURE_HILL_GIANT,
  CREATURE_LAMIA,
  CREATURE_MANTICORE,
  CREATURE_PERYTON,
  CREATURE_WYVERN,
} = require('./../../../../creatureIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Мантикора',
  nameEn: 'Manticore',
  description: `Мантикора — самый настоящий монстр. У неё львиное тело, крылья дракона, и голова, смутно напоминающая человеческую. Жёсткая грива тянется по её хребту, а длинный хвост заканчивается пучком смертоносных шипов, способных пронзать добычу с весьма почтительного расстояния.

**Злобные хищники.** Мантикоры — свирепые охотники с обширными охотничьими угодьями. Они могут объединяться с сородичами, чтобы убить какую-нибудь особенно крупную или опасную добычу, после чего пируют вместе на её останках. При нападении мантикора сперва атакует залпом хвостовых шипов, затем приземляется и пускает в ход зубы и когти. Если сражение происходит на открытом воздухе, и численный перевес не на стороне чудовищ, они предпочитают оставаться в воздухе как можно дольше и атаковать с расстояния до тех пор, пока не иссякнет запас хвостовых шипов.

Мантикоры не слишком умны, но способны разговаривать, и злобны по натуре. Они любят унижать свои жертвы, предлагая им быструю смерть, если те будут умолять о пощаде. Мантикора может также пощадить свою добычу, если для неё будет в этом какая-то выгода. В этом случае она потребует соответствующее вознаграждение за то, что останется голодной.

**Чудовищные взаимоотношения.** Мантикоры могут служить другим злым существам, если те в свою очередь хорошо к ним относятся и регулярно предоставляют добычу. Мантикора может прикрывать с воздуха орочью орду или армию хобгоблинов, может быть охотничьим спутником вождя [холмовых великанов](CREATURE:${CREATURE_HILL_GIANT}), или, допустим, может охранять вход в логово [ламии](CREATURE:${CREATURE_LAMIA}).

Самые серьёзные конкуренты мантикор — [виверны](CREATURE:${CREATURE_WYVERN}), [грифоны](CREATURE:${CREATURE_GRIFFON}), [перитоны](CREATURE:${CREATURE_PERYTON}) и [химеры](CREATURE:${CREATURE_CHIMERA}). Объединяясь в стаи во время охоты, мантикоры зачастую получают над ними численное преимущество. Помимо упомянутых существ, мантикоры также боятся драконов и стараются с ними не сталкиваться.`,
  id: CREATURE_MANTICORE,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 200,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 50,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 17,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 8,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Выращивание шипов на хвосте',
      description: `★СУЩЕСТВО★ имеет 24 шипа на хвосте. Использованные шипы отрастают, когда ★СУЩЕСТВО★ оканчивает длинный отдых.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: либо одну _Укусом_ и две _Когтями_, либо три атаки _Шипами с хвоста_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Шип с хвоста',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 5,
        range: {
          normal: 100,
          max: 200,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
