const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_ELEMENTAL_FIRE,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
  CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
  CREATURE_FIRE_ELEMENTAL_SPIRIT,
  CREATURE_FIRE_ELEMENTAL_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух огненного элементаля',
  nameEn: 'Fire Elemental Spirit',
  id: CREATURE_FIRE_ELEMENTAL_SPIRIT_2020_03_26,
  releasedAs: CREATURE_FIRE_ELEMENTAL_SPIRIT,
  parentId: CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_FIRE,
  ],
  speed: {
    [SPEED_WALK]: 40,
  },
  resistanceList: null,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 4,
          comment: `+ круг заклинания`,
        },
        range: 5,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 1,
          diceType: 10,
          diceBonus: 4,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  extendPropCollection: {
    immunityList: [
      DAMAGE_FIRE,
    ],
  },
}
