const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_GIANT_ELK,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_GIANT_ELK,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык гигантских лосей',
    genitive: 'Языка гигантских лосей',
    instrumental: 'Языком гигантских лосей',
    prepositional: 'Языке гигантских лосей',
  },
  nameEn: 'Giant Elk',
  isRealLang: true,
  isReady: false,
}
