const {
  CREATURE_DEVOURER,
  CREATURE_GHAST,
  CREATURE_GHOUL,
  CREATURE_ORCUS,
  CREATURE_SKELETON,
  CREATURE_WIGHT,
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_13} = require('./../../../../crList')
const {CREATURE_TYPE_FIEND} = require('./../../../../creatureTypeIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Пожиратель',
  nameEn: 'Devourer',
  id: CREATURE_DEVOURER,
  description: [
    `Из всех выпущенных на волю исчадий [Оркуса](CREATURE:${CREATURE_ORCUS}), пожирателей боятся больше всего. Эти высокие, похожие на мумий исчадия, которые бродят по планам, пожирая души и, например, распространяя вероучение Оркуса о замене всей жизни на бесконечную смерть.`,
    {
      header: 'Инструменты Оркуса',
      text: `Младший демон, который выслужился перед [Оркусом](CREATURE:${CREATURE_ORCUS}), может получить привилегию и стать пожирателем. Принц Нежити превращает такого демона в высушенного гуманоида 8 футов ростом с пустой грудной клеткой, а потом наполняет новое существо голодом к душам. Оркус дарует каждому новому пожирателю сущность менее удачливого демона, чтобы наделить силой для первой вылазки в планы. Большинство пожирателей остаются в Бездне или в Астральном или Эфирном Плане, реализуя планы и интересы [Оркуса](CREATURE:${CREATURE_ORCUS}) в этих мирах. Когда [Оркус](CREATURE:${CREATURE_ORCUS}) посылает пожирателей на Материальный план, он часто дает им задание создать, контролировать и вести чуму нежити. [Скелеты](CREATURE:${CREATURE_SKELETON}), [зомби](CREATURE:${CREATURE_ZOMBIE}), [упыри](CREATURE:${CREATURE_GHOUL}) и [вурдалаки](CREATURE:${CREATURE_GHAST}) и тени особенно привлекаются присутствием пожирателя.`,
      source: {
        id: SOURCE_VGTM,
        page: 185,
      },
    },
    {
      header: 'Мучители душ',
      text: `Пожиратели охотятся за гуманоидами, намереваясь поглотить их тело и душу. После того как пожиратель подводит цель к грани между жизнью и смертью, он берёт тело жертвы и засовывает его в свою грудную клетку. Пока жертва пытается оттянуть смерть (обычно безуспешно), пожиратель мучает душу телепатическими шумами. Когда жертва умирает, она проходит ужасную трансформацию, вырывается из тела пожирателя и начинает новое существование в качестве неживого слуги чудовища, которое его породило.`,
      source: {
        id: SOURCE_VGTM,
        page: 185,
      },
    },
    {
      header: 'Природа исчадия',
      text: `Пожирателю не требуется воздух, пища (кроме душ), питьё или сон.`,
      source: {
        id: SOURCE_VGTM,
        page: 185,
      },
    },
  ],
  note: {
    text: `Я слышал о «заедании ребер», но это просто смешно`,
    author: `Воло`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 185,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 20,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 16,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_13,
  actionList: [
    {
      name: 'Мультиатака',
      description: `Пожиратель делает две атаки _Когтями_ и может использовать _Заточение души_ или _Разрыв душ_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 5,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 6,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Заточение души',
      description: `Пожиратель выбирает живого гуманоида с 0 хитов, которого он видит не далее 30 футов от себя. Существо телепортируется внутрь грудной клетки пожирателя и заточается в ней. Заточённое таким образом существо получает помеху к испытаниям от смерти. Если оно умирает, будучи заточенным, то пожиратель восстанавливает 25 хитов, сразу же перезаряжает _Разрыв душ_ и получает дополнительное действие на свой следующий ход. Кроме того, в начале его следующего хода пожиратель извергает убитое существо бонусным действием и оно становится нежитью. Если у жертвы было 2 или менее Кости Хитов, то она становится [зомби](CREATURE:${CREATURE_ZOMBIE}). Если у неё было от 3 до 5 Костей Хитов, то она становится [упырём](CREATURE:${CREATURE_GHOUL}). В любом другом случае она становится [умертвием](CREATURE:${CREATURE_WIGHT}). Пожиратель может заточать одновременно лишь одно существо.`,
    },
    {
      name: 'Разрыв душ',
      restore: 6,
      description: `Пожиратель создаёт воронку энергии, высасывающей жизнь, с радиусом в 20 футов и центром в себе. Каждый гуманоид в этой области должен пройти испытание Телосложения СЛ 18 и получить 44 (8к10) некротического урона при провале или половину этого урона при успехе. Увеличьте урона на 10 за каждого живого гуманоида с 0 хитов в этой области.`,
    },
  ],
}
