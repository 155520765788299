const {
  SPELL_ACID_SPLASH,
  SPELL_BURNING_HANDS,
  SPELL_CHROMATIC_ORB,
  SPELL_INVISIBILITY,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_PRESTIDIGITATION,
  SPELL_RAY_OF_FROST,
  SPELL_SLEEP,
  SPELL_SPIDER_CLIMB,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_DERRO,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  derroDescription,
  derroNote,
} = require('./../../../../textCommonParts')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_QUARTERSTAFF,
} = require('./../../../../gearIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_DERRO_SAVANT} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дерро савант',
  nameEn: 'Derro Savant',
  id: CREATURE_DERRO_SAVANT,
  description: derroDescription,
  note: derroNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DERRO,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 158,
  },
  armor: {
    ac: 13,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 5,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_ACID_SPLASH,
      SPELL_BURNING_HANDS,
      SPELL_CHROMATIC_ORB,
      SPELL_INVISIBILITY,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGE_HAND,
      SPELL_MESSAGE,
      SPELL_PRESTIDIGITATION,
      SPELL_RAY_OF_FROST,
      SPELL_SLEEP,
      SPELL_SPIDER_CLIMB,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
  ],
}
