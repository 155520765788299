const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ELEMENTAL_EARTH} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ELEMENTAL_EARTH,
  nameEn: 'Earth Elemental',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  hideFromFilter: true,
  name: {
    singular: {
      nominative: 'земляной элементаль',
      genitive: 'земляного элементаля',
      dative: 'земляному элементалю',
      accusative: 'земляного элементаля',
      instrumental: 'земляным элементалем',
      prepositional: 'земляном элементале',
    },
    plural: {
      nominative: 'земляные элементали',
      genitive: 'земляных элементалей',
      dative: 'земляным элементалям',
      accusative: 'земляных элементалей',
      instrumental: 'земляными элементалями',
      prepositional: 'земляных элементалях',
    },
  },
}
