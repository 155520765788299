const {SOURCE_MM} = require('./../../sourceList')

module.exports = {
  header: 'Грибы',
  text: `В Подземье, с его небом из зазубренных камней и вечной ночью, произрастают самые разные грибы. Занявшие в местной подземной экосистеме место растений грибы жизненно важны для выживания многих местных видов, так как дают кров и пищу в беспощадной темноте.

Грибы заводятся на органическом веществе и впоследствии разлагают его и поглощают, питаясь гнилью и трупами. По мере вызревания грибы начинают выпускать споры, которые разносятся малейшим сквозняком и разрастаются в новые грибы.

Мутируя под воздействием магической среды Подземья, грибы часто начинают развивать защитные механизмы, а также способности к мимикрии и даже к нападению. Крупнейшие особи могут породить целые обширные подземные леса, в которых живут и находят пропитание бесчисленные существа.`,
  source: {
    id: SOURCE_MM,
    page: 73,
  },
}
