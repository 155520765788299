const { GENDER_FEMALE } = require('./../../../../../genderList')
const { MGC_RARITY_VERY_RARE } = require('./../../../../../magicItemRarityList')
const { MGC_TYPE_WONDROUS_ITEM } = require('./../../../../../magicItemTypeList')
const { SOURCE_PotA } = require('./../../../../../sourceList')
const {
  SPELL_DIVINATION,
  SPELL_IDENTIFY,
} = require('./../../../../../spellIdList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    detonationText,
    elementalName,
    elementalWeaponId,
    elementalWeaponName,
    id,
    masterGuideDescription,
    nameEn,
    oppositeElementalName,
    orbOfWhat,
    ritualName,
  },
) => ({
  id,
  name: `Сфера ${orbOfWhat}`,
  nameEn,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_VERY_RARE,
  isConsumable: true,
  description: [
    {
      header: `Сфера ${orbOfWhat}`,
      text: `_Сфера ${orbOfWhat}_ — это бомба, заряженная силой стихии ${elementalName}. Такие _Сферы_ создаются внутри стихийных узлов ${elementalName} с помощью особого ритуала «${ritualName}», в котором используется стихийное оружие [${elementalWeaponName}](MAGIC_ITEM:${elementalWeaponId}). Ритуал «${ritualName}» занимает 1 час и требует специальных компонентов стоимостью в 2000 зм, которые тратятся в процессе.

_Сфера ${orbOfWhat}_ имеет 12 дюймов в диаметре, весит 10 фунтов и обладает гладкой поверхностью. Она взрывается спустя 1к100 часов после создания, высвобождая содержащуюся в ней энергию стихии ${elementalName}. Внешний осмотр _Сферы_ никак не покажет, сколько времени осталось до взрыва, но это значение можно узнать с помощью заклинаний [Опознание](SPELL:${SPELL_IDENTIFY}) и [Предсказание](SPELL:${SPELL_DIVINATION}). _Сфера ${orbOfWhat}_ имеет КД 10, 15 хитов и обладает иммунитетом к яду и психическому урону. Если хиты сферы опускаются до 0, она взрывается мгновенно.`,
    },
    {
      header: 'Хранение Сферы',
      text: `Для безопасного хранения _Сферы ${orbOfWhat}_ можно создать специальный контейнер, на котором должны быть выгравированы символы стихии ${oppositeElementalName} (противоположной для стихии ${elementalName}). Пока _Сфера ${orbOfWhat}_ лежит в контейнере, она не может взорваться и издаёт гудящие звуки. Если _Сферу ${orbOfWhat}_, время до взрыва которой уже истекло, извлечь из контейнера, то она взорвётся спустя 1к6 ходов. Чтобы предотвратить взрыв, её можно снова положить в контейнер.`,
    },
    {
      header: 'Взрыв сферы',
      text: `Эффект взрыва _Сферы ${orbOfWhat}_ распространяется в пределах 1 мили. _Сфера ${orbOfWhat}_ является центром эффекта и уничтожается после одного использования.

${detonationText}`,
    },
    masterGuideDescription,
  ],
  genderId: GENDER_FEMALE,
  siblingIdList,
  source: {
    id: SOURCE_PotA,
    page: 228,
  },
})
