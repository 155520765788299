const {ABILITY_AURA_OF_MURDER} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CREATURE_NIGHT_BLADE} = require('./../../../../creatureIdList')
const {CR_1_4} = require('./../../../../crList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {bhaalFollowerList} = require('./../../../../textCommonParts')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_STEALTH,
} = require('./../../../../skillList')

module.exports = {
  name: 'Ночной клинок',
  nameEn: 'Night blade',
  id: CREATURE_NIGHT_BLADE,
  description: bhaalFollowerList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_BGDIA,
    page: 237,
  },
  armor: 12,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_AURA_OF_MURDER,
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
