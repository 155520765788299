const {LANG_TYPE_DEAD} = require('./../../languageTypeList')
const {LANG_TRUSKAN} = require('./../../languageIdList')
const {
  LANG_SUBGROUP_ILLUSKI,
} = require('./../../languageSubGroupList')

module.exports = {
  id: LANG_TRUSKAN,
  langSubGroupId: LANG_SUBGROUP_ILLUSKI,
  type: LANG_TYPE_DEAD,
  nameEn: 'Truskan',
  name: {
    nominative: 'Трусканский',
    genitive: 'Трусканского',
    instrumental: 'Трусканским',
    prepositional: 'Трусканском',
  },
  isReady: false,
  isRealLang: true,
}
