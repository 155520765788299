import React, { createElement } from 'react'
import PropTypes from 'prop-types';

import Description from '@/components/Description'

import { TYPE_ITEM } from '@/constants/itemTypes'

const DescriptionListComponent = ({list}) => (
  <>
    {
      list.map(
        ({ item, type }, i) => type === TYPE_ITEM
          ? (
            <Description
              key={i}
              {...item}
            />
          )
          : createElement(item, {key: i})
      )
    }
  </>
)

DescriptionListComponent.propTypes = {
  list: PropTypes.array,
}

DescriptionListComponent.defaultProps = {
  list: [],
}

export default DescriptionListComponent
