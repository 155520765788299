const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_WDH} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_FEATHER_FALL} = require('./../../../../spellIdList')
const {waterdeepCityGuardDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_GRIFFON,
  CREATURE_GRIFFON_CAVALRY_RIDER,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ANIMAL_HANDLING,
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  GEAR_CROSSBOW_LIGHT,
  GEAR_DAGGER,
  GEAR_HALF_PLATE,
} = require('./../../../../gearIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Всадник кавалерии грифонов',
  nameEn: 'Griffon Cavalry Rider',
  id: CREATURE_GRIFFON_CAVALRY_RIDER,
  description: waterdeepCityGuardDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ANY_RACE,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_WDH,
    page: 197,
  },
  armor: {
    ac: 17,
    gearId: GEAR_HALF_PLATE,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ANIMAL_HANDLING]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ATHLETICS]: PROF_NORMAL,
  },
  languageList: [
    LANG_ANY,
  ],
  cr: CR_2,
  actionList: [
    {
      name: 'Кавалерийская пика',
      comment: `с помехой, если цель в пределах 5 футов`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 10,
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 12,
              diceBonus: 2,
            },
            {
              comment: `, если ★СУЩЕСТВО★ сражается верхом`,
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 12,
              diceBonus: 5,
            },
          ],
        ],
      },
    },
    {
      gearId: GEAR_DAGGER,
    },
    {
      gearId: GEAR_CROSSBOW_LIGHT,
    },
  ],
  reactionList: [
    {
      name: 'Падение пёрышком',
      description: `★СУЩЕСТВО★ носит магическое кольцо, с помощью которого может реакцией при падении сотворить на себя заклинание [Падение пёрышком](SPELL:${SPELL_FEATHER_FALL}). После этого кольцо становится немагическим.`,
    },
  ],
}
