module.exports = [
  ...require('./pcClass/artificer'),
  ...require('./pcClass/barbarian'),
  ...require('./pcClass/bard'),
  ...require('./pcClass/cleric'),
  ...require('./pcClass/druid'),
  ...require('./pcClass/fighter'),
  ...require('./pcClass/monk'),
  ...require('./pcClass/paladin'),
  ...require('./pcClass/ranger'),
  ...require('./pcClass/rogue'),
  ...require('./pcClass/sorcerer'),
  ...require('./pcClass/warlock'),
  ...require('./pcClass/wizard'),

  ...require('./pcSubClass/multiClassed/mage_of_lorehold_2021_06_08'),
  ...require('./pcSubClass/multiClassed/mage_of_quandrix_2021_06_08'),
  ...require('./pcSubClass/multiClassed/mage_of_silverquill_2021_06_08'),
  ...require('./pcSubClass/multiClassed/mage_of_witherbloom_2021_06_08'),

  ...require('./pcSubClass/artificer/alchemist'),
  ...require('./pcSubClass/artificer/armorer'),
  ...require('./pcSubClass/artificer/armorer_2020_02_24'),
  ...require('./pcSubClass/artificer/artillerist'),
  ...require('./pcSubClass/artificer/battle_smith'),
  ...require('./pcSubClass/barbarian/path_of_the_ancestral_guardian'),
  ...require('./pcSubClass/barbarian/path_of_the_battlerager'),
  ...require('./pcSubClass/barbarian/path_of_the_beast'),
  ...require('./pcSubClass/barbarian/path_of_the_berserker'),
  ...require('./pcSubClass/barbarian/path_of_the_depths'),
  ...require('./pcSubClass/barbarian/path_of_the_storm_herald'),
  ...require('./pcSubClass/barbarian/path_of_the_totem_warrior'),
  ...require('./pcSubClass/barbarian/path_of_the_wild_soul'),
  ...require('./pcSubClass/barbarian/path_of_wild_magic'),
  ...require('./pcSubClass/barbarian/path_of_wild_magic_tcoe_pc_bpowm'),
  ...require('./pcSubClass/barbarian/path_of_the_zealot'),
  ...require('./pcSubClass/bard/college_of_creation'),
  ...require('./pcSubClass/bard/college_of_eloquence'),
  ...require('./pcSubClass/bard/college_of_eloquence_2019_09_18'),
  ...require('./pcSubClass/bard/college_of_glamour'),
  ...require('./pcSubClass/bard/college_of_glamour_2016_11_14'),
  ...require('./pcSubClass/bard/college_of_lore'),
  ...require('./pcSubClass/bard/college_of_satire_2016_01_04'),
  ...require('./pcSubClass/bard/college_of_spirits_2020_08_05'),
  ...require('./pcSubClass/bard/college_of_swords'),
  ...require('./pcSubClass/bard/college_of_valor'),
  ...require('./pcSubClass/bard/college_of_whispers'),
  ...require('./pcSubClass/cleric/arcana_domain'),
  ...require('./pcSubClass/cleric/death_domain'),
  ...require('./pcSubClass/cleric/forge_domain'),
  ...require('./pcSubClass/cleric/grave_domain'),
  ...require('./pcSubClass/cleric/knowledge_domain'),
  ...require('./pcSubClass/cleric/life_domain'),
  ...require('./pcSubClass/cleric/light_domain'),
  ...require('./pcSubClass/cleric/nature_domain'),
  ...require('./pcSubClass/cleric/order_domain'),
  ...require('./pcSubClass/cleric/peace_domain'),
  ...require('./pcSubClass/cleric/protection_domain_2016_11_21'),
  ...require('./pcSubClass/cleric/tempest_domain'),
  ...require('./pcSubClass/cleric/trickery_domain'),
  ...require('./pcSubClass/cleric/twilight_domain'),
  ...require('./pcSubClass/cleric/twilight_domain_2019_10_03'),
  ...require('./pcSubClass/cleric/unity_domain_2020_02_06'),
  ...require('./pcSubClass/cleric/war_domain'),
  ...require('./pcSubClass/druid/circle_of_dreams'),
  ...require('./pcSubClass/druid/circle_of_dreams_2016_11_28'),
  ...require('./pcSubClass/druid/circle_of_spores'),
  ...require('./pcSubClass/druid/circle_of_stars'),
  ...require('./pcSubClass/druid/circle_of_stars_2020_02_24'),
  ...require('./pcSubClass/druid/circle_of_the_land'),
  ...require('./pcSubClass/druid/circle_of_the_moon'),
  ...require('./pcSubClass/druid/circle_of_the_shepherd'),
  ...require('./pcSubClass/druid/circle_of_the_shepherd_2016_11_28'),
  ...require('./pcSubClass/druid/circle_of_twilight_2016_11_28'),
  ...require('./pcSubClass/druid/circle_of_wildfire'),
  ...require('./pcSubClass/druid/circle_of_wildfire_2019_10_03'),
  ...require('./pcSubClass/fighter/arcane_archer'),
  ...require('./pcSubClass/fighter/battle_master'),
  ...require('./pcSubClass/fighter/brute_2018_01_08'),
  ...require('./pcSubClass/fighter/cavalier'),
  ...require('./pcSubClass/fighter/champion'),
  ...require('./pcSubClass/fighter/echo_knight'),
  ...require('./pcSubClass/fighter/eldritch_knight'),
  ...require('./pcSubClass/fighter/monster_hunter_2016_04_04'),
  ...require('./pcSubClass/fighter/psi_warrior'),
  ...require('./pcSubClass/fighter/psychic_warrior_2019_11_25'),
  ...require('./pcSubClass/fighter/purple_dragon_knight'),
  ...require('./pcSubClass/fighter/rune_knight'),
  ...require('./pcSubClass/fighter/samurai'),
  ...require('./pcSubClass/fighter/sharpshooter_2016_12_05'),
  ...require('./pcSubClass/monk/way_of_the_mercy'),
  ...require('./pcSubClass/monk/way_of_shadow'),
  ...require('./pcSubClass/monk/way_of_the_astral_self'),
  ...require('./pcSubClass/monk/way_of_the_astral_self_2019_08_15'),
  ...require('./pcSubClass/monk/way_of_the_drunken_master'),
  ...require('./pcSubClass/monk/way_of_the_four_elements'),
  ...require('./pcSubClass/monk/way_of_the_kensei'),
  ...require('./pcSubClass/monk/way_of_the_long_death'),
  ...require('./pcSubClass/monk/way_of_the_open_hand'),
  ...require('./pcSubClass/monk/way_of_the_sun_soul'),
  ...require('./pcSubClass/monk/way_of_tranquility_2016_12_12'),
  ...require('./pcSubClass/paladin/oath_of_conquest'),
  ...require('./pcSubClass/paladin/oath_of_devotion'),
  ...require('./pcSubClass/paladin/oath_of_glory'),
  ...require('./pcSubClass/paladin/oath_of_heroism_2019_09_18'),
  ...require('./pcSubClass/paladin/oath_of_redemption'),
  ...require('./pcSubClass/paladin/oath_of_the_ancients'),
  ...require('./pcSubClass/paladin/oath_of_the_crown'),
  ...require('./pcSubClass/paladin/oath_of_the_watchers'),
  ...require('./pcSubClass/paladin/oath_of_the_watchers_2020_01_14'),
  ...require('./pcSubClass/paladin/oath_of_treachery_2016_12_19'),
  ...require('./pcSubClass/paladin/oath_of_vengeance'),
  ...require('./pcSubClass/paladin/oathbreaker'),
  ...require('./pcSubClass/ranger/beast_master'),
  ...require('./pcSubClass/ranger/deep_stalker_2015_11_02'),
  ...require('./pcSubClass/ranger/deep_stalker_conclave_2016_09_12'),
  ...require('./pcSubClass/ranger/drakewarden_2020_10_26'),
  ...require('./pcSubClass/ranger/fey_wanderer'),
  ...require('./pcSubClass/ranger/fey_wanderer_2020_02_24'),
  ...require('./pcSubClass/ranger/gloom_stalker'),
  ...require('./pcSubClass/ranger/horizon_walker'),
  ...require('./pcSubClass/ranger/horizon_walker_2017_01_16'),
  ...require('./pcSubClass/ranger/hunter'),
  ...require('./pcSubClass/ranger/monster_slayer'),
  ...require('./pcSubClass/ranger/primeval_guardian_2017_01_16'),
  ...require('./pcSubClass/ranger/swarmkeeper'),
  ...require('./pcSubClass/ranger/swarmkeeper_2019_10_17'),
  ...require('./pcSubClass/rogue/arcane_trickster'),
  ...require('./pcSubClass/rogue/assassin'),
  ...require('./pcSubClass/rogue/inquisitive'),
  ...require('./pcSubClass/rogue/mastermind'),
  ...require('./pcSubClass/rogue/phantom'),
  ...require('./pcSubClass/rogue/phantom_2020_05_12'),
  ...require('./pcSubClass/rogue/revived_2019_10_17'),
  ...require('./pcSubClass/rogue/scout'),
  ...require('./pcSubClass/rogue/soulknife'),
  ...require('./pcSubClass/rogue/soulknife_2020_04_14'),
  ...require('./pcSubClass/rogue/swashbuckler'),
  ...require('./pcSubClass/rogue/thief'),
  ...require('./pcSubClass/rogue/wild_card'),
  ...require('./pcSubClass/sorcerer/aberrant_mind_2019_05_09'),
  ...require('./pcSubClass/sorcerer/clockwork_soul_2020_02_06'),
  ...require('./pcSubClass/sorcerer/divine_soul'),
  ...require('./pcSubClass/sorcerer/draconic_bloodline'),
  ...require('./pcSubClass/sorcerer/giant_soul'),
  ...require('./pcSubClass/sorcerer/shadow_magic'),
  ...require('./pcSubClass/sorcerer/stone_sorcery_2017_02_06'),
  ...require('./pcSubClass/sorcerer/storm_sorcery'),
  ...require('./pcSubClass/sorcerer/wild_magic'),
  ...require('./pcSubClass/warlock/the_archfey'),
  ...require('./pcSubClass/warlock/the_celestial'),
  ...require('./pcSubClass/warlock/the_fiend'),
  ...require('./pcSubClass/warlock/the_genie'),
  ...require('./pcSubClass/warlock/the_genie_tcoe_pc_wtg'),
  ...require('./pcSubClass/warlock/the_great_old_one'),
  ...require('./pcSubClass/warlock/the_hexblade'),
  ...require('./pcSubClass/warlock/the_lurker_in_the_deep_2019_05_09'),
  ...require('./pcSubClass/warlock/the_noble_genie_2020_01_14'),
  ...require('./pcSubClass/warlock/the_raven_queen_2017_02_13'),
  ...require('./pcSubClass/warlock/the_seeker_2016_08_01'),
  ...require('./pcSubClass/warlock/the_undead_2020_08_05'),
  ...require('./pcSubClass/warlock/the_undying'),
  ...require('./pcSubClass/warlock/the_undying_light_2015_11_02'),
  ...require('./pcSubClass/wizard/bladesinging'),
  ...require('./pcSubClass/wizard/chronurgy_magic'),
  ...require('./pcSubClass/wizard/graviturgy_magic'),
  ...require('./pcSubClass/wizard/onomancy_2019_10_03'),
  ...require('./pcSubClass/wizard/order_of_scribes'),
  ...require('./pcSubClass/wizard/psionics_2019_11_25'),
  ...require('./pcSubClass/wizard/school_of_abjuration'),
  ...require('./pcSubClass/wizard/school_of_conjuration'),
  ...require('./pcSubClass/wizard/school_of_divination'),
  ...require('./pcSubClass/wizard/school_of_enchantment'),
  ...require('./pcSubClass/wizard/school_of_evocation'),
  ...require('./pcSubClass/wizard/school_of_illusion'),
  ...require('./pcSubClass/wizard/school_of_necromancy'),
  ...require('./pcSubClass/wizard/school_of_transmutation'),
  ...require('./pcSubClass/wizard/war_magic'),
]
