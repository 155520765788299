module.exports = [
  require('./list/albino_dwarf_spirit_warrior'),
  require('./list/albino_dwarf_warrior'),
  require('./list/aldani'),
  require('./list/almiraj'),
  require('./list/ankylosaurus_zombie'),
  require('./list/assassin_vine'),
  require('./list/chwinga'),
  require('./list/eblis'),
  require('./list/giant_four_armed_gargoyle'),
  require('./list/giant_snapping_turtle'),
  require('./list/girallon_zombie'),
  require('./list/flaming_fist'),
  require('./list/flaming_fist_gauntlet'),
  require('./list/flying_monkey'),
  require('./list/jaculi'),
  require('./list/kamadan'),
  require('./list/liara_portyr'),
  require('./list/mantrap'),
  require('./list/pterafolk'),
  require('./list/stone_juggernaut'),
  require('./list/su_monster'),
  require('./list/tabaxi_hunter'),
  require('./list/tabaxi_minstrel'),
  require('./list/tri_flower_frond'),
  require('./list/tyrannosaurus_zombie'),
  require('./list/yellow_musk_creeper'),
  require('./list/yellow_musk_zombie'),
  require('./list/zorbo'),
]
