const {ABILITY_HOLD_BREATH} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CREATURE_HOWLING_HATRED_INITIATE} = require('./../../../../creatureIdList')
const {CR_1_8} = require('./../../../../crList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {howlingHatredDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_RELIGION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_DAGGER,
  GEAR_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Послушник Воющей Ненависти',
  nameEn: 'Howling Hatred Initiate',
  id: CREATURE_HOWLING_HATRED_INITIATE,
  description: [
    {
      header: 'Послушники Воющей Ненависти',
      text: `Послушников Воющей Ненависти привлекает философия культа, проповедующая отказ от духовных привязанностей и материальных ценностей. При этом они не подозревают об истинной его природе. В лапы культа попадают все, кто разделяет подобные идеи: от мечтательных юношей, обладающих небольшим имуществом, до уставших от мира людей, жаждущих поклонения чему-то осязаемому и могущественному (например, силе стихий).

Послушники живут на одном воздухе в течение месяца, полагая, что такой режим избавит их от потребностей тела. На деле же из-за своей аскезы они впадают в ужасное истощение и потому становятся восприимчивыми к внушению. Вскоре они уже будут оправдывать гнусные поступки культа, повторяя заученные слова о том, что человеческая жертва — это не акт убийства, а освобождение души от ограничений физической оболочки, а вызов вихря для уничтожения еретиков — не террор, а демонстрация истинной веры.`,
      source: {
        id: SOURCE_PotA,
        page: 195,
      },
    },
    ...howlingHatredDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 195,
  },
  armor: {
    ac: 13,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 10,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1_8,
  featureList: [
    {
      id: ABILITY_HOLD_BREATH,
      time: '30 минут',
    },
    {
      name: 'Попутный ветер',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `★СУЩЕСТВО★ бонусным действием получает преимущество на следующую дальнобойную атаку до конца своего следующего хода.`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
