const {
  SPELL_ABSORB_ELEMENTS,
  SPELL_AID,
  SPELL_ALARM,
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ANIMAL_MESSENGER,
  SPELL_ASHARDALON_S_STRIDE,
  SPELL_BARKSKIN,
  SPELL_BEAST_BOND,
  SPELL_BEAST_SENSE,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_CONJURE_ANIMALS,
  SPELL_CONJURE_BARRAGE,
  SPELL_CONJURE_VOLLEY,
  SPELL_CONJURE_WOODLAND_BEINGS,
  SPELL_CORDON_OF_ARROWS,
  SPELL_CURE_WOUNDS,
  SPELL_DARKVISION,
  SPELL_DAYLIGHT,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DOMINATE_BEAST,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENSNARING_STRIKE,
  SPELL_ENTANGLE,
  SPELL_FIND_TRAPS,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_STRIDE_2021_04_14,
  SPELL_FOG_CLOUD,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GOODBERRY,
  SPELL_GRASPING_VINE,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_NATURE,
  SPELL_GUST_OF_WIND,
  SPELL_HAIL_OF_THORNS,
  SPELL_HEALING_SPIRIT,
  SPELL_HUNTERS_MARK,
  SPELL_JUMP,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHTNING_ARROW,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MAGIC_WEAPON,
  SPELL_MELD_INTO_STONE,
  SPELL_NONDETECTION,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_PLANT_GROWTH,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_REVIVIFY,
  SPELL_SEARING_SMITE,
  SPELL_SILENCE,
  SPELL_SNARE,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_SPIKE_GROWTH,
  SPELL_STEEL_WIND_STRIKE,
  SPELL_STONESKIN,
  SPELL_SUDDEN_AWAKENING_2017_04_03,
  SPELL_SUMMON_BEAST,
  SPELL_SUMMON_BESTIAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_ELEMENTAL,
  SPELL_SUMMON_FEY,
  SPELL_SWIFT_QUIVER,
  SPELL_TREE_STRIDE,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
  SPELL_WILD_CUNNING_2017_04_03,
  SPELL_WIND_WALL,
  SPELL_WRATH_OF_NATURE,
  SPELL_ZEPHYR_STRIKE,
} = require('./../../spellIdList')

module.exports = [
  SPELL_ABSORB_ELEMENTS,
  SPELL_AID,
  SPELL_ALARM,
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ANIMAL_MESSENGER,
  SPELL_ASHARDALON_S_STRIDE,
  SPELL_BARKSKIN,
  SPELL_BEAST_BOND,
  SPELL_BEAST_SENSE,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_CONJURE_ANIMALS,
  SPELL_CONJURE_BARRAGE,
  SPELL_CONJURE_VOLLEY,
  SPELL_CONJURE_WOODLAND_BEINGS,
  SPELL_CORDON_OF_ARROWS,
  SPELL_CURE_WOUNDS,
  SPELL_DARKVISION,
  SPELL_DAYLIGHT,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DOMINATE_BEAST,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENSNARING_STRIKE,
  SPELL_ENTANGLE,
  SPELL_FIND_TRAPS,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_STRIDE_2021_04_14,
  SPELL_FOG_CLOUD,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GOODBERRY,
  SPELL_GRASPING_VINE,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_NATURE,
  SPELL_GUST_OF_WIND,
  SPELL_HAIL_OF_THORNS,
  SPELL_HEALING_SPIRIT,
  SPELL_HUNTERS_MARK,
  SPELL_JUMP,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHTNING_ARROW,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MAGIC_WEAPON,
  SPELL_MELD_INTO_STONE,
  SPELL_NONDETECTION,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_PLANT_GROWTH,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_REVIVIFY,
  SPELL_SEARING_SMITE,
  SPELL_SILENCE,
  SPELL_SNARE,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_SPIKE_GROWTH,
  SPELL_STEEL_WIND_STRIKE,
  SPELL_STONESKIN,
  SPELL_SUDDEN_AWAKENING_2017_04_03,
  SPELL_SUMMON_BEAST,
  SPELL_SUMMON_BESTIAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_ELEMENTAL,
  SPELL_SUMMON_FEY,
  SPELL_SWIFT_QUIVER,
  SPELL_TREE_STRIDE,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
  SPELL_WILD_CUNNING_2017_04_03,
  SPELL_WIND_WALL,
  SPELL_WRATH_OF_NATURE,
  SPELL_ZEPHYR_STRIKE,
]
