module.exports = [
  ...require('./d'),
  ...require('./f'),
  ...require('./g'),
  ...require('./i'),
  ...require('./m'),
  ...require('./p'),
  ...require('./r'),
  ...require('./s'),
  ...require('./t'),
  ...require('./w'),
]
