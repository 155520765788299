const {
  SPELL_CLOUDKILL,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_FEAR,
  SPELL_FIREBALL,
  SPELL_FLY,
  SPELL_INVISIBILITY,
  SPELL_MAGE_HAND,
  SPELL_MAJOR_IMAGE,
  SPELL_PLANE_SHIFT,
  SPELL_TONGUES,
} = require('./../../../../spellIdList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_SLAAD_BLUE,
  CREATURE_SLAAD_DEATH,
  CREATURE_SLAAD_RED,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_SHAPESHIFTER,
  CREATURE_TYPE_SLAAD,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_SLAAD,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_ARCANA,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_10} = require('./../../../../crList')
const {GEAR_GREATSWORD} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {slaadDescription} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Смертельный слаад`,
  nameEn: 'Death Slaad',
  id: CREATURE_SLAAD_DEATH,
  description: [
    `Слаады смерти пропитаны энергией Плана Негативной Энергии и представляют собой злых развращённых существ, которым нравится вредить другим. Они плодят представителей своей расы, принуждая толпы [красных](CREATURE:${CREATURE_SLAAD_RED}) и [синих](CREATURE:${CREATURE_SLAAD_BLUE}) слаадов нападать на другие планы. Гуманоиды, пережившие вторжение, становятся инкубаторами для новых слаадов.`,
    slaadDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_SLAAD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 267,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 19,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_ARCANA]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_SLAAD,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_10,
  featureList: [
    {
      name: `Перевёртыш`,
      description: `★СУЩЕСТВО★ может действием превратиться в гуманоида Маленького или Среднего размера, или принять свой истинный облик. Все ★его★ статистики, кроме размера, одинаковы во всех обликах. Всё несомое и носимое ★им★ снаряжение не превращается. ★Он★ принимает свой истинный облик, если умирает.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    {
      name: `Регенерация`,
      description: `★СУЩЕСТВО★ восстанавливает 10 хитов в начале своего хода, если у него есть хотя бы 1 хит.`,
    },
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_MAGE_HAND,
          {
            id: SPELL_INVISIBILITY,
            comment: 'только на себя',
          },
          SPELL_DETECT_MAGIC,
          SPELL_DETECT_THOUGHTS,
          SPELL_MAJOR_IMAGE,
        ],
      },
      {
        limit: {
          count: 2,
          period: 'день',
        },
        list: [
          SPELL_FEAR,
          SPELL_FIREBALL,
          SPELL_FLY,
          SPELL_TONGUES,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_PLANE_SHIFT,
          SPELL_CLOUDKILL,
        ],
      },
    ],
  },
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, и две либо _Когтями_, либо _Двуручным мечом_.`,
    },
    {
      name: `Укус`,
      comment: `только в облике слаада`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 5,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: `Коготь`,
      comment: `только в облике слаада`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 1,
            diceType: 10,
            diceBonus: 5,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      gearId: GEAR_GREATSWORD,
      attack: {
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
