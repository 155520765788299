const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_MODRONE} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_MODRONE,
  nameEn: 'Modrone',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'модрон',
      genitive: 'модрона',
      dative: 'модрону',
      accusative: 'модрона',
      instrumental: 'модроном',
      prepositional: 'модроне',
    },
    plural: {
      nominative: 'модроны',
      genitive: 'модронов',
      dative: 'модронам',
      accusative: 'модронов',
      instrumental: 'модронами',
      prepositional: 'модронах',
    },
  },
}
