const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  oblexDescription,
  oblexNoteList,
} = require('./../../../../textCommonParts')
const {ABILITY_AMORPHOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_OBLEX_SPAWN} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_OOZE} = require('./../../../../creatureTypeIdList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Порождение облекса',
  nameEn: 'Oblex Spawn',
  id: CREATURE_OBLEX_SPAWN,
  description: [
    `Облекс поглощает воспоминания не только для того чтобы поддерживать своё существование, но и для порождения новых облексов. Каждый раз, когда он полностью высасывает воспоминания существа, облекс получает и личные качества, исковерканные отвратительной природой облекса. Чем больше воспоминаний облекс крадёт, тем больше он становится, до той поры, пока ему не приходится излить из себя накопленную часть, иначе оно тронется умом. Таким образом, появляется новый облекс.`,
    oblexDescription,
  ],
  note: oblexNoteList,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_OOZE,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 214,
  },
  armor: 13,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_CHA,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_AMORPHOUS,
    {
      name: 'Боязнь огня',
      description: `Если облекс получает огненный урон, то он, до конца своего следующего хода, совершает с помехой броски атаки и проверки характеристик.`,
    },
  ],
  actionList: [
    {
      name: 'Ложноножка',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 1,
            diceType: 4,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
}
