const {
  MAGIC_ABJURATION,
  MAGIC_ENCHANTMENT,
  MAGIC_EVOCATION,
} = require('./../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../castComponentList')
const {
  SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
  SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
} = require('./../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_HOUR,
  TIME_MINUTE,
  TIME_REACTION,
  TIME_ROUND,
} = require('./../../timePeriodList')
const {
  PARAM_DEX,
  PARAM_INT,
  PARAM_WIT,
} = require('./../../paramList')
const {
  DAMAGE_FORCE,
  DAMAGE_PSYCHIC,
} = require('./../../damageTypeList')
const {
  SPELL_EGO_WHIP_2019_11_25,
  SPELL_ID_INSINUATION_2019_11_25,
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2019_11_25,
  SPELL_MENTAL_BARRIER_2019_11_25,
  SPELL_MIND_THRUST_2019_11_25,
  SPELL_PSIONIC_BLAST_2019_11_25,
  SPELL_PSYCHIC_CRUSH_2019_11_25,
  SPELL_TASHA_S_MIND_WHIP,
  SPELL_THOUGHT_SHIELD_2019_11_25,
} = require('./../../spellIdList')
const {
  CONDITION_INCAPACITATED,
  CONDITION_PRONE,
  CONDITION_STUNNED,
} = require('./../../conditionList')

module.exports = [
  {
    id: SPELL_EGO_WHIP_2019_11_25,
    name: 'Эго-хлыст',
    nameEn: 'Ego Whip',
    description: `Вы бичуете разум одного существа, видимого вами в пределах дистанции, наполняя его отчаянием.

Цель должна пройти испытание Интеллекта. При провале она не может сотворять заклинания и получает помеху к атакам, проверкам характеристик и испытаниям.

В конце каждого своего хода цель может повторять испытание, завершая эффект при успехе.`,
    lvl: 4,
    magicSchoolId: MAGIC_ENCHANTMENT,
    needConcentration: true,
    range: 30,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_INT,
    },
    abandonedAt: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 1,
    },
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 7,
    },
  },
  {
    id: SPELL_ID_INSINUATION_2019_11_25,
    name: 'Инсинуация личности',
    nameEn: 'Id Insinuation',
    description: `Вы наполняете разум одного существа, видимого вами в пределах дистанции, ураганом противоречивых желаний, разрушая его способность принимать решения.

Цель должна пройти испытание Мудрости или стать недееспособной.

В конце каждого своего хода цель получает 1к12 психического урона и может повторить испытание, завершая эффект при успехе.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    needConcentration: true,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_INCAPACITATED,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 1,
        diceType: 12,
      },
    },
    abandonedAt: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 1,
    },
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 7,
    },
  },
  {
    id: SPELL_INTELLECT_FORTRESS_2019_11_25,
    releasedAs: SPELL_INTELLECT_FORTRESS,
    name: 'Крепость интеллекта',
    nameEn: 'Intellect Fortress',
    description: `Вы создаёте вокруг себя слегка подрагивающий барьер психической энергии.

Во время действия заклинания Вы получаете преимущество ко всем испытаниям.

Когда другое существо, которое Вы видите в пределах 30 футов, проваливает испытание, Вы можете реакцией позволить ему перебросить 
проваленное испытание. Оно должно использовать новый результат.`,
    lvl: 5,
    magicSchoolId: MAGIC_ABJURATION,
    needConcentration: true,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 7,
    },
  },
  {
    id: SPELL_MENTAL_BARRIER_2019_11_25,
    name: 'Ментальный барьер',
    nameEn: 'Mental Barrier',
    description: `Вы защищаете свой разум стеной зацикленных, повторяющихся мыслей.

До начала вашего следующего хода Вы получаете преимущество к испытаниям Интеллекта, Мудрости и Харизмы и сопротивление к психическому урону.`,
    lvl: 2,
    magicSchoolId: MAGIC_ABJURATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_ROUND, count: 1 },
    castTime: { timeId: TIME_REACTION, count: 1 },
    castTimeComment: `совершаемая вами, когда Вы проходите испытание Интеллекта, Мудрости или Харизмы`,
    abandonedAt: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 1,
    },
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 7,
    },
  },
  {
    id: SPELL_MIND_THRUST_2019_11_25,
    releasedAs: SPELL_TASHA_S_MIND_WHIP,
    name: 'Сотрясение разума',
    nameEn: 'Mind Thrust',
    description: `Вы вонзаете копьё псионического распада в разум одного существа, видимого вами в пределах дистанции.

Цель должна пройти испытание Интеллекта.

* При провале цель получает 3к6 психического урона и на своём следующем ходу может совершать только действия Рывок и Отход.
* При успехе цель получает половину этого урона и выбор её действий не ограничивается.`,
    highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку 3 круга или выше, Вы можете сделать целью дополнительно ещё одно существо за каждый круг ячейки выше 2. Существа должны находиться в пределах 30 футов друг от друга, когда Вы их выбираете.`,
    lvl: 2,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_ROUND, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    effect: {
      savethrowParam: PARAM_INT,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 3,
        diceType: 6,
        diceBonus: 0,
      },
    },
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 7,
    },
  },
  {
    id: SPELL_PSIONIC_BLAST_2019_11_25,
    name: 'Псионический взрыв',
    nameEn: 'Psionic Blast',
    description: `Вы испускаете волну ментальной энергии 30-футовым конусом.

Все существа в этой области должны пройти испытание Ловкости.

* При провале цель получает 5к8 урона силовым полем при провале, толкается на 20 футов от Вас и сбивается с ног.
* При успехе цель получает половину урона, не толкается и не сбивается с ног.`,
    highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку 4 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше 3.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: `30-футовый конус`,
    componentIdList: [CAST_VERBAL],
    effect: {
      savethrowParam: PARAM_DEX,
      condition: CONDITION_PRONE,
      damage: {
        type: DAMAGE_FORCE,
        diceCount: 5,
        diceType: 8,
      },
    },
    abandonedAt: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 1,
    },
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 8,
    },
  },
  {
    id: SPELL_PSYCHIC_CRUSH_2019_11_25,
    name: 'Психическое сокрушение',
    nameEn: 'Psychic Crush',
    description: `Вы перегружаете разум одного существа, видимого вами в пределах дистанции, заполняя его психику противоречивыми эмоциями.

Цель должна пройти испытание Интеллекта.

* При провале цель получает 12к6 психического урона и ошеломляется на 1 минуту.
* При успехе цель получает половину этого урона и не ошеломляется.

Ошеломлённая цель может повторять испытание в конце каждого своего хода, завершая эффект при успехе.`,
    lvl: 6,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_INT,
      condition: CONDITION_STUNNED,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 12,
        diceType: 6,
        diceBonus: 0,
      },
    },
    abandonedAt: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 1,
    },
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 8,
    },
  },
  {
    id: SPELL_THOUGHT_SHIELD_2019_11_25,
    name: 'Мысленный щит',
    nameEn: 'Thought Shield',
    description: `Вы касаетесь одного существа и сплетаете туманную завесу вокруг его разума.

На время действия заклинания разум цели не может быть обнаружен, а её мысли прочитаны. Другие существа не могут телепатически общаться с ней без её разрешения. Цель получает преимущество к испытаниям против эффектов, показывающих, говорит ли она правду.`,
    lvl: 5,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 8 },
    abandonedAt: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 1,
    },
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 8,
    },
  },
]
