const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GOBLINOID,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_NORKER,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ATHLETICS,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_NORKER_WAR_LEADER} = require('./../../../../creatureIdList')
const {GEAR_GREATAXE} = require('./../../../../gearIdList')
const {norkerDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MFFV_1_MMB} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Воевода норкеров',
  nameEn: 'Norker War Leader',
  id: CREATURE_NORKER_WAR_LEADER,
  description: norkerDescription,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_NORKER,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 17,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 15,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GOBLIN,
  ],
  cr: CR_3,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает одну атаку _Секирой_ и одну — _Укусом_, а потом использует _Деритесь, слизняки!_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_GREATAXE,
    },
    {
      name: 'Деритесь, слизняки!',
      description: '★СУЩЕСТВО★ выбирает до трёх видимых ★им★ в пределах 60 футов союзных гоблиноидов. Каждое из выбранных существ может реакцией совершить одну рукопашную атаку.',
    },
  ],
  reactionList: [
    {
      name: 'Защитное возмездие',
      description: `Если существо в пределах 5 футов от ★СУЩЕСТВО★ совершает по ★нему★ рукопашную атаку, ★СУЩЕСТВО★ может реакцией нанести ему 6 колющего урона.`,
    },
  ],
}
