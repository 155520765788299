const {CREATURE_TYPE_GIANT_HILL} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GIANT_HILL,
  nameEn: 'Hill Giant',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'холмовой великан',
        genitive: 'холмового великана',
        dative: 'холмовому великану',
        accusative: 'холмового великана',
        instrumental: 'холмовым великаном',
        prepositional: 'холмовом великане',
      },
      [GENDER_FEMALE]: {
        nominative: 'холмовая великанша',
        genitive: 'холмовой великанши',
        dative: 'холмовой великанше',
        accusative: 'холмовую великаншу',
        instrumental: 'холмовой великаншей',
        prepositional: 'холмовой великанше',
      },
    },
    plural: {
      nominative: 'холмовые великаны',
      genitive: 'холмовых великанов',
      dative: 'холмовым великанам',
      accusative: 'холмовых великанов',
      instrumental: 'холмовыми великанами',
      prepositional: 'холмовых великанах',
    },
  },
}
