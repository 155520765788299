const {
  SPELL_BLESS,
  SPELL_DETECT_MAGIC,
  SPELL_GUIDANCE,
  SPELL_GUIDING_BOLT,
  SPELL_HOLD_PERSON,
  SPELL_MASS_HEALING_WORD,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_THAUMATURGY,
  SPELL_TONGUES,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_BLOOD_FRENZY,
  ABILITY_LIMITED_AMPHIBIOUSNESS,
  ABILITY_SHARK_TELEPATHY,
} = require('./../../../../creatureAbilityList')
const {
  sahuaginDescriptionList,
  sahuaginNote,
} = require('./../../../../textCommonParts')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  SKILL_PERCEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_SAHUAGIN,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_SAHUAGIN_PRIESTESS} = require('./../../../../creatureIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_SAHUAGIN} = require('./../../../../languageIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Жрица сахуагинов',
  nameAlt: 'Жрица сахваджинов',
  nameEn: 'Sahuagin Priestess',
  id: CREATURE_SAHUAGIN_PRIESTESS,
  description: sahuaginDescriptionList,
  note: sahuaginNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_SAHUAGIN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 258,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 12,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_SAHUAGIN,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_BLOOD_FRENZY,
    {
      id: ABILITY_LIMITED_AMPHIBIOUSNESS,
      time: `4 часа`,
    },
    ABILITY_SHARK_TELEPATHY,
  ],
  spellCast: {
    spellCasterLevel: 6,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_BLESS,
      SPELL_DETECT_MAGIC,
      SPELL_GUIDANCE,
      SPELL_GUIDING_BOLT,
      SPELL_HOLD_PERSON,
      SPELL_MASS_HEALING_WORD,
      {
        id: SPELL_SPIRITUAL_WEAPON,
        comment: 'трезубец',
      },
      SPELL_THAUMATURGY,
      SPELL_TONGUES,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
    ],
  },
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Когтем_.`,
    },
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      name: `Когти`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
