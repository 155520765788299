const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_MORDENKAINEN_S_MAGNIFICENT_MANSION,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_MORDENKAINEN_S_MAGNIFICENT_MANSION,
  name: 'Великолепный особняк Морденкайнена',
  nameEn: `Mordenkainen’s Magnificent Mansion`,
  nameEnAlt: `Magnificent Mansion`,
  description: `Вы создаёте межпространственное жилище в пределах дистанции, существующее, пока заклинание активно. Вы выбираете, где будет находиться единственный вход. Вход слабо светится; он 5 футов в ширину и 10 футов в высоту. Вы и все существа, указанные Вами при накладывании заклинания, можете входить в это жилище, пока дверь открыта. Вы можете открывать и закрывать дверь, если находитесь в пределах 30 футов от неё. Дверь невидима, когда закрыта.

За дверью находится великолепная прихожая с многочисленными комнатами. Там свежий и тёплый воздух.

Вы можете создать любую планировку, но пространство не может превышать 50 кубов с длиной ребра в 10 футов. Это место украшено и обставлено мебелью так, как Вы пожелаете. Там достаточно еды для 100 человек с девятью переменами блюд. Вошедшим прислуживают 100 практически прозрачных слуг. Внешний облик и одежду этих слуг определяете вы. Они послушно исполняют все приказы. Каждый слуга может исполнять задачи, которые доступны любому человеческому слуге, но они не могут атаковать и совершать действия, причиняющие вред другому существу. Таким образом, слуги могут подавать вещи, чистить, штопать, гладить одежду, зажигать огни, подавать пищу, наливать вино и так далее. Слуги могут перемещаться по особняку, но не могут выходить из него. Мебель и другие предметы, созданные этим заклинанием, превращаются в дым, если их вынесут из особняка. Когда заклинание заканчивается, все существа, находящиеся в межпространстве, изгоняются в свободное пространство, ближайшее к входу.`,
  lvl: 7,
  magicSchoolId: MAGIC_CONJURATION,
  range: 300,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `миниатюрная дверь из слоновой кости, кусочек полированного мрамора и крошечная серебряная ложка; каждый предмет должен стоить как минимум 5 зм`,
  duration: { timeId: TIME_HOUR, count: 24 },
  castTime: { timeId: TIME_MINUTE, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 214,
    },
    {
      id: SOURCE_SRD,
      page: 162,
    },
  ],
}
