import React from 'react'
import classNames from 'classnames'

import './TipStyles.less'

class TipHead extends React.Component {
  state = {
    show: true,
  }

  toggle = () => {
    const { show } = this.state

    this.setState({
      show: !show,
    })
  }

  render() {
    const { show } = this.state
    const { children } = this.props

    return (
      <button
        className={classNames(
          'TipHead',
          {
            'TipHead-visible': show,
            'TipHead-hidden': !show,
          }
        )}
        onClick={this.toggle}
        type='button'
      >
        {children}
      </button>
    )
  }
}

export default TipHead
