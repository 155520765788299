const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_ELEMENTAL_WATER,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  DAMAGE_ACID,
} = require('./../../../../damageTypeList')
const {
  CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
  CREATURE_WATER_ELEMENTAL_SPIRIT,
  CREATURE_WATER_ELEMENTAL_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух водного элементаля',
  nameEn: 'Water Elemental Spirit',
  id: CREATURE_WATER_ELEMENTAL_SPIRIT_2020_03_26,
  releasedAs: CREATURE_WATER_ELEMENTAL_SPIRIT,
  parentId: CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_WATER,
  ],
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_SWIM]: 40,
  },
  resistanceList: [
    DAMAGE_ACID,
  ],
}
