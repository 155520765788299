module.exports.INFUSION_ARCANE_PROPULSION_ARMOR = 'arcane_propulsion_armor'
module.exports.INFUSION_ARMOR_OF_MAGICAL_STRENGTH = 'armor_of_magical_strength'
module.exports.INFUSION_ARMOR_OF_MAGICAL_STRENGTH_2020_02_24 = 'armor_of_magical_strength_2020_02_24'
module.exports.INFUSION_ARMOR_OF_TOOLS_2020_02_24 = 'armor_of_tools_2020_02_24'
module.exports.INFUSION_BOOTS_OF_THE_WINDING_PATH = 'boots_of_the_winding_path'
module.exports.INFUSION_ENHANCED_ARCANE_FOCUS = 'enhanced_arcane_focus'
module.exports.INFUSION_ENHANCED_DEFENSE = 'enhanced_defense'
module.exports.INFUSION_ENHANCED_WEAPON = 'enhanced_weapon'
module.exports.INFUSION_HELM_OF_AWARENESS = 'helm_of_awareness'
module.exports.INFUSION_HOMUNCULUS_SERVANT = 'homunculus_servant'
module.exports.INFUSION_MIND_SHARPENER = 'mind_sharpener'
module.exports.INFUSION_MIND_SHARPENER_2020_02_24 = 'mind_sharpener_2020_02_24'
module.exports.INFUSION_RADIANT_WEAPON = 'radiant_weapon'
module.exports.INFUSION_REPEATING_SHOT = 'repeating_shot'
module.exports.INFUSION_REPLICATE_MAGIC_ITEM = 'replicate_magic_item'
module.exports.INFUSION_REPULSION_SHIELD = 'repulsion_shield'
module.exports.INFUSION_RESISTANT_ARMOR = 'resistant_armor'
module.exports.INFUSION_RETURNING_WEAPON = 'returning_weapon'
module.exports.INFUSION_SPELL_REFUELING_RING = 'spell_refueling_ring'
module.exports.INFUSION_SPELL_REFUELING_RING_2020_02_24 = 'spell_refueling_ring_2020_02_24'
