const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_FEY_WANDERER} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {
  SPELL_CHARM_PERSON,
  SPELL_DIMENSION_DOOR,
  SPELL_DISPEL_MAGIC,
  SPELL_MISLEAD,
  SPELL_MISTY_STEP,
  SPELL_SUMMON_FEY,
} = require('./../../../spellIdList')
const {
  FEATURE_BEGUILING_TWIST,
  FEATURE_DREADFUL_STRIKES,
  FEATURE_FEY_REINFORCEMENTS,
  FEATURE_FEY_WANDERER_MAGIC,
  FEATURE_MISTY_WANDERER,
  FEATURE_OTHERWORLDLY_GLAMOUR,
} = require('./../../../featureIdList')


module.exports = [
  {
    id: FEATURE_FEY_WANDERER_MAGIC,
    name: `Магия фейского странника`,
    nameEn: `Fey Wanderer Magic`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.

Вы также обладаете сверхъестественным благословением от фейского союзника или места фейской силы. Выберите своё благословение из таблицы «Фейских даров» или определите его случайным образом.

# Фейские дары

| к6 | Подарок                                                                                          |
|----|--------------------------------------------------------------------------------------------------|
| 1  | Во время короткого или длинного отдыха вокруг Вас порхают иллюзорные бабочки                     |
| 2  | На каждом рассвете из Ваших волос прорастают 2 свежих сезонных цветка.                           |
| 3  | Вы слабо пахнете корицей, лавандой, мускатным орехом или другими приятными травами или специями. |
| 4  | Ваша тень танцует, пока никто не смотрит прямо на неё.                                           |
| 5  | Из Вашей головы растут рога.                                                                     |
| 6  | Ваша кожа и волосы каждый рассвет меняют цвет в соответствии со временем года.                   |
`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_CHARM_PERSON,
      SPELL_DIMENSION_DOOR,
      SPELL_DISPEL_MAGIC,
      SPELL_MISLEAD,
      SPELL_MISTY_STEP,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 86,
    },
  },
  {
    id: FEATURE_DREADFUL_STRIKES,
    name: `Жуткие удары`,
    nameEn: `Dreadful Strikes`,
    lvl: [3, 11],
    text: `Вы можете усилить удары своего оружия с помощью головоломной магии, взятой из мрачных лощин Страны фей.

Когда Вы попадаете по существу атакой оружием, Вы можете один раз в ход нанести ему дополнительно 1к4 психического урона.

Дополнительный урон увеличивается до 1к6, когда Вы достигаете 11 уровня в этом классе.`,
    source: {
      id: SOURCE_TCoE,
      page: 85,
    },
  },
  {
    id: FEATURE_OTHERWORLDLY_GLAMOUR,
    name: `Иномирное обаяние`,
    nameEn: `Otherworldly Glamour`,
    lvl: 3,
    text: `Ваши фейские особенности придают Вам сверхъестественное очарование.

Когда Вы совершаете проверку Харизмы, Вы получаете бонус к проверке, равный вашему модификатору Мудрости (минимум +1).

Кроме того, Вы получаете владение одним навыком по Вашему выбору

* Выступление,
* Обман,
* Убеждение.`,
    source: {
      id: SOURCE_TCoE,
      page: 85,
    },
  },
  {
    id: FEATURE_BEGUILING_TWIST,
    name: `Завораживающий переворот`,
    nameEn: `Beguiling Twist`,
    lvl: 7,
    text: `Магия Страны фей охраняет Ваш разум.

Вы получаете преимущество к испытаниям от Очарования или Испуга.

Кроме того, когда Вы или видимое Вами в пределах 120 футов существо преуспевает в испытании от Очарования или Испуга, Вы можете реакцией заставить другое видимое Вами в пределах 120 футов существо пройти испытание Мудрости.

При провале цель Очарована или Напугана Вами (на Ваш выбор) на 1 минуту. Цель может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    source: {
      id: SOURCE_TCoE,
      page: 85,
    },
  },
  {
    id: FEATURE_FEY_REINFORCEMENTS,
    name: `Фейские подкрепления`,
    nameEn: `Fey Reinforcements`,
    lvl: 11,
    text: `Королевские дворы Страны фей благословили Вас помощью фейских существ.

Вы узнаёте заклинание [Призыв духа феи](SPELL:${SPELL_SUMMON_FEY}). Это заклинание не учитывается при подсчёте числа заклинаний следопыта, которые Вы можете знать, и Вы можете сотворять его без материального компонента. Вы также можете разыграть его без траты ячейки заклинания, и сможете сотворить его снова, когда закончите долгий отдых.

Когда Вы начинаете сотворять это заклинание, Вы можете изменить его так, чтобы оно не требовало концентрации. Если Вы так сделаете, его длительность станет 1 минутой.`,
    spellIdList: [
      SPELL_SUMMON_FEY,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 85,
    },
  },
  {
    id: FEATURE_MISTY_WANDERER,
    name: `Странник туманов `,
    nameEn: `Misty Wanderer`,
    lvl: 15,
    text: `Вы можете проскальзывать в Страну фей и из неё, мгновенно передвигаясь.

Вы можете сотворять заклинание [Туманный шаг](SPELL:${SPELL_MISTY_STEP}), без траты ячейки заклинания. Вы можете сделать это число раз, равное Вашему модификатору Мудрости (минимум 1 раз), и восстановите все израсходованные использования, когда закончиваете длительный отдых.

Кроме того, всякий раз, когда Вы сотворяете [Туманный шаг](SPELL:${SPELL_MISTY_STEP}), Вы можете взять с собой одно согласное существо, видимое Вами в пределах 5 футов. Это существо телепортируется в любое свободное пространство по Вашему выбору в пределах 5 футов от места назначения.`,
    spellIdList: [
      SPELL_MISTY_STEP,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 85,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_RANGER_FEY_WANDERER,
  })
)
