const {
  SOURCE_MTOF,
} = require('./../../sourceList')
const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')
const {GOD_RAVEN_QUEEN} = require('./../../godIdList')
const {CREATURE_NAGPA} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Нагпы',
    text: `Давным-давно, [Королева Воронов](GOD:${GOD_RAVEN_QUEEN}) прокляла группу заговорщиков из тринадцати могущественных [волшебников](PC_CLASS:${PC_CLASS_WIZARD}) за вмешательство в ритуал, который помог бы предотвратить войну между богами. Она лишила их красоты, превратив в [отвратительных птицеподобных чудовищ](CREATURE:${CREATURE_NAGPA}). [Нагпы](CREATURE:${CREATURE_NAGPA}) и сейчас строят новые заговоры, как и раньше, но теперь они стремятся принести в мир ужасные, всесотрясающие бедствия, чтобы вырвать силу и знания из руин, что останутся после всего.

[Нагпы](CREATURE:${CREATURE_NAGPA}) боятся [Королеву Воронов](GOD:${GOD_RAVEN_QUEEN}) и делают всё возможное для избегания её и её приспешников. Если же им кажется, что невозможно убежать, они становятся трусливыми, заискивающими и угождающими перед теми, кто когда-то опустил их так низко. Все изначальные 13 [волшебников](PC_CLASS:${PC_CLASS_WIZARD}) до сих пор живы, благодаря хитрости и готовности делать что угодно ради выживания.`,
    source: {
      id: SOURCE_MTOF,
      page: 212,
    },
  },
  {
    header: 'Мародёры цивилизации',
    text: `Проклятье [Королевы Воронов](GOD:${GOD_RAVEN_QUEEN}), что было наложено на [нагп](CREATURE:${CREATURE_NAGPA}), ограничивает им способы получения новых знаний и магической силы, запрещая получать новые источники силы, за исключением руин, оставленных за падшими цивилизациями и велики катаклизмами. Поэтому [нагпы](CREATURE:${CREATURE_NAGPA}) прилагают все усилия, чтобы добраться до этих мест в надежде отыскать новые знания в древних библиотеках, разграбить хранилища и собрать секреты магических знаний остатков цивилизаций.`,
    source: {
      id: SOURCE_MTOF,
      page: 212,
    },
  },
]
