const {
  SOURCE_UA_2017_04_03_STARTER_SPELLS,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../../attackTypeList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_ZEPHYR_STRIKE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ZEPHYR_STRIKE,
  name: 'Удар Зефира',
  nameEn: 'Zephyr Strike',
  description: `Вы движетесь подобно ветру. Пока действует заклинание, Ваше движение не вызывает провоцированных атак.

Единожды до окончания действия заклинания Вы можете получить преимущество к одной атаке оружием в свой ход. Эта атака наносит дополнительные 1к8 урона силовым полем при попадании. Вне зависимости от того, попали Вы или промахнулись, Ваша пешая скорость передвижения увеличивается на 30 футов до конца этого хода.`,
  lvl: 1,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_VERBAL],
  duration: {timeId: TIME_MINUTE, count: 1},
  castTime: {timeId: TIME_BONUS_ACTION, count: 1},
  needConcentration: true,
  effect: {
    type: [
      ATTACK_MELEE_WEAPON,
      ATTACK_RANGE_WEAPON,
    ],
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 1,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 170,
    },
    {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 5,
    },
  ],
}
