const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_SLAAD} = require('./../../languageIdList')

module.exports = {
  id: LANG_SLAAD,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Слаадский',
    genitive: 'Слаадского',
    instrumental: 'Слаадским',
    prepositional: 'Слаадском',
  },
  isReady: false,
  isRealLang: true,
}
