const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_SHADAR_KAI} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_SHADAR_KAI,
  nameEn: 'Shadar-kai',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'шадар-кай',
      genitive: 'шадар-кая',
      dative: 'шадар-каю',
      accusative: 'шадар-кая',
      instrumental: 'шадар-каем',
      prepositional: 'шадар-кае',
    },
    plural: {
      nominative: 'шадар-каи',
      genitive: 'шадар-каев',
      dative: 'шадар-каям',
      accusative: 'шадар-каев',
      instrumental: 'шадар-каями',
      prepositional: 'шадар-каях',
    },
  },
}
