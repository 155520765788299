const {SOURCE_WDH} = require('./../../../../sourceList')
const {waterdeepCityGuardDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_GUARD,
  CREATURE_WATERDEEP_S_CITY_GUARD_ARMAR,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Армар гвардии Глубоководья',
  nameAlt: 'Сержант гвардии Глубоководья',
  nameEn: 'Waterdeep’s city guard, Armar',
  nameEnAlt: 'Waterdeep’s city guard, Sergeant',
  id: CREATURE_WATERDEEP_S_CITY_GUARD_ARMAR,
  parentId: CREATURE_GUARD,
  description: waterdeepCityGuardDescriptionList,
  source: {
    id: SOURCE_WDH,
    page: 197,
  },
}
