const {PC_SUBCLASS_MONK_WAY_OF_SHADOW} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')

const {
  SPELL_DARKNESS,
  SPELL_DARKVISION,
  SPELL_MINOR_ILLUSION,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_SILENCE,
} = require('./../../../spellIdList')
const {
  FEATURE_CLOAK_OF_SHADOWS,
  FEATURE_OPPORTUNIST,
  FEATURE_SHADOW_ARTS,
  FEATURE_SHADOW_STEP,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_SHADOW_ARTS,
    name: `Техника тени`,
    nameEn: `Shadow Arts`,
    lvl: 3,
    text: `Вы можете использовать свою Ци, чтобы дублировать эффекты некоторых заклинаний.

Вы можете действием использовать 2 очка Ци, чтобы сотворить [Бесследное передвижение](SPELL:${SPELL_PASS_WITHOUT_TRACE}), [Тёмное зрение](SPELL:${SPELL_DARKVISION}), [Тишину](SPELL:${SPELL_SILENCE}) или [Тьму](SPELL:${SPELL_DARKNESS}), не тратя материальные компоненты. Кроме того, Вы получаете заговор [Малая иллюзия](SPELL:${SPELL_MINOR_ILLUSION}), если Вы ещё не знаете его.`,
    spellIdList: [
      SPELL_DARKNESS,
      SPELL_DARKVISION,
      SPELL_MINOR_ILLUSION,
      SPELL_PASS_WITHOUT_TRACE,
      SPELL_SILENCE,
    ],
  },
  {
    id: FEATURE_SHADOW_STEP,
    name: `Шаг тени`,
    nameEn: `Shadow Step`,
    lvl: 6,
    text: `Вы получаете возможность шагнуть из одной тени в другую.

Если Вы находитесь в темноте или пространстве с тусклым освещением, Вы можете бонусным действием телепортироваться на 60 футов в другое видимое Вами тусклое или тёмное пространство. Свою первую рукопашную атаку до конца текущего хода Вы совершите с преимуществом.`,
  },
  {
    id: FEATURE_CLOAK_OF_SHADOWS,
    name: `Плащ теней`,
    nameEn: `Cloak of Shadows`,
    lvl: 11,
    text: `Вы узнаёте, как становиться единым с тенью.

Когда Вы находитесь в области с тусклом освещением или в темноте, Вы можете действием стать невидимым.

Вы остаётесь невидимыми до тех пор, пока не нападёте, не сотворите заклинание, или не окажетесь в области яркого света.`,
  },
  {
    id: FEATURE_OPPORTUNIST,
    name: `Оппортунист`,
    nameEn: `Opportunist`,
    lvl: 17,
    text: `Вы узнаёте, как использовать мгновенное отвлечение цели, по которой попала атака.

Если по существу в радиусе 5 футов от Вас попала атака, совершенная другим существом, но не Вами, Вы можете реакцией совершить по нему рукопашную атаку.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_SHADOW,
    source: {
      id: SOURCE_PHB,
      page: 81,
    },
  })
)
