module.exports = [
  require('./catoblepas'),
  require('./cave_fisher'),
  require('./champion'),
  require('./chitine'),
  require('./choldrith'),
  require('./cloud_giant_smiling_one'),
  require('./conjurer'),
  require('./cow'),
  require('./cranium_rat'),
]
