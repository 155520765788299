const upLetter = require('./../../../../utils/upLetter')

const {GEAR_ORB} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {MAGIC_ITEM_ORB_OF_SHIELDING} = require('./../../../magicItemIdList')
const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../magicItemTypeList')
const {
  SOURCE_ERFTLW,
  SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
} = require('./../../../sourceList')

const generateDamageText = require('./generateDamageText')
const orbOfShieldingList = require('./orbOfShieldingList')
const siblingIdList = require('./siblingIdList')

const tableText = orbOfShieldingList.reduce(
  (text, {name: {genitive: name}, damageTypeIdList}) => `${text}
| ${upLetter(name)} | ${generateDamageText(damageTypeIdList)} |`,
  `
| Планарный материал | Типы урона |
|--------------------|------------|`
)

module.exports = {
  id: MAGIC_ITEM_ORB_OF_SHIELDING,
  name: `Защитная сфера`,
  nameEn: `Orb of Shielding`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_COMMON,
  gearType: GEAR_ORB,
  attunement: true,
  description: `Это полированый сферический кусок кристалла или камня, настроенный на определённый план существования. Если Вы заклинатель, то можете использовать её как заклинательную фокусировку.

Если Вы держите _Защитную сферу_, когда получаете соответствующий её материалу урон, то можете реакцией уменьшить его на 1к4 (до минимума в 0 урона).

${tableText}`,
  genderId: GENDER_FEMALE,
  childIdList: siblingIdList,
  source: [
    {
      id: SOURCE_ERFTLW,
      page: 279,
    },
    {
      id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
      page: 1,
    },
  ],
}
