const {PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {SPELL_CONJURE_ANIMALS} = require('./../../../spellIdList')
const {
  FEATURE_FAITHFUL_SUMMONS,
  FEATURE_GUARDIAN_SPIRIT,
  FEATURE_MIGHTY_SUMMONER,
  FEATURE_SPEECH_OF_THE_WOODS,
  FEATURE_SPIRIT_TOTEM,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_SPEECH_OF_THE_WOODS,
    name: `Речь лесов`,
    nameEn: `Speech of the Woods`,
    lvl: 2,
    text: `Вы получили возможность говорить с животными и многими феями.

Вы научились говорить, читать и писать на Сильване.

Кроме того, звери могут понимать Вашу речь, а Вы можете расшифровывать звуки, которые они издают, и их движения. У большинства животных недостаточно интеллекта для передачи или понимания сложных концепций, но дружелюбный зверь может рассказать то, что он видел или слышал в недавнем прошлом. Эта способность не делает животных дружелюбными Вам, но Вы можете совместить её применение с подарками, чтобы наладить с ними контакт, как и с другими неигровыми персонажами.`,
    source: {
      id: SOURCE_XGTE,
      page: 27,
    },
  },
  {
    id: FEATURE_SPIRIT_TOTEM,
    name: `Тотем духов`,
    nameEn: `Spirit Totem`,
    lvl: 2,
    text: `Вы можете вызывать духов природы, чтобы влиять на окружающий Вас мир.

Вы можете бонусным действием магически вызвать бестелесный дух в видимой Вами в пределах 60 футов точке. Дух создаёт вокруг этой точки ауру радиусом 30 футов. Он не считается ни существом, ни предметом, хотя имеет призрачную форму существа, которое представляет.

Вы можете перемещать дух бонусным действием в видимую Вами точку в пределах 60 футов.

Дух существует 1 минуту или пока Вы не станете недееспособным. После использования этой способности Вы не сможете использовать её снова, пока не завершите короткий или длинный отдых.

Эффект ауры зависит от типа духа, из вариантов ниже.

# Дух медведя

Дух медведя даёт Вам и Вашим союзникам силу и выносливость.

Выбранные Вами существа, находившиеся в области ауры при появлении духа, получает временные хиты в количестве, равном 5 + Ваш уровень друида.

Кроме того, Вы и Ваши союзники получаете преимущество на проверки и испытания Силы, пока находитесь в ауре.

# Дух ястреба

Дух ястреба — непревзойдённый охотник, помогающий Вам и Вашим союзникам своим острым взглядом.

Когда существо совершает атаку по цели, находящейся в ауре духа, Вы можете реакцией дать преимущество на эту атаку.

Кроме того, Вы и Ваши союзники получаете преимущество на проверки Мудрости (Внимательность), пока находитесь в ауре.

# Дух единорога

Дух единорога защищает окружающих.

Вы и Ваши союзники получаете преимущество на все проверки способностей, совершаемые для обнаружения существ в ауре духа.

Кроме того, если Вы сотворяете восстанавливающее хиты заклинание, тратя ячейку заклинания, то находящиеся внутри ауры существа по Вашему выбору тоже восстанавливают хиты в количестве, равном Вашему уровню друида.`,
    source: {
      id: SOURCE_XGTE,
      page: 27,
    },
  },
  {
    id: FEATURE_MIGHTY_SUMMONER,
    name: `Могущественный призыватель`,
    nameEn: `Mighty Summoner`,
    lvl: 6,
    text: `Звери и феи, которых Вы вызываете, становятся более стойкими, чем обычно.

Любой зверь или фея, вызванные или созданные заклинанием, которое Вы сотворяете, получает следующие преимущества:

* Вызванное существо появляется с дополнительными хитами: за каждую Кость Хитов, которые есть у существа, прибавляется дополнительно 2 хита.
* Урон от его естественного оружия считается магическим при определении преодоления сопротивления и иммунитета к немагическим атакам и урону.`,
    source: {
      id: SOURCE_XGTE,
      page: 28,
    },
  },
  {
    id: FEATURE_GUARDIAN_SPIRIT,
    name: `Дух-защитник`,
    nameEn: `Guardian Spirit`,
    lvl: 10,
    text: `Ваш  [Тотем духов](FEATURE:${FEATURE_SPIRIT_TOTEM}) защищает призванных Вашей магией зверей и фей.

Когда зверь или фея, которых Вы вызвали или создали заклинанием, заканчивает свой ход в ауре Вашего [Тотема духов](FEATURE:${FEATURE_SPIRIT_TOTEM}), это существо восстанавливает хиты в количестве, равном половине Вашего уровня друида.`,
    source: {
      id: SOURCE_XGTE,
      page: 28,
    },
  },
  {
    id: FEATURE_FAITHFUL_SUMMONS,
    name: `Верные призванные`,
    nameEn: `Faithful Summons`,
    lvl: 14,
    text: `Духи природы, с которыми Вы общаетесь, защищают Вас, когда Вы беззащитны.

Если Ваши хиты упали до 0 или Вы стали недееспособны против Вашей воли, Вы можете немедленно получить эффект заклинания [Призыв животных](SPELL:${SPELL_CONJURE_ANIMALS}), как если бы оно было сотворено ячейкой 9 круга (заклинание призывает четырёх выбранных Вами зверей с ПО 2 или ниже). Призванные существа появляются в пределах 20 футов от Вас. Если они не получат от Вас никаких приказов, то они защищают Вас и нападают на Ваших противников. Заклинание не требует концентрации и длится 1 час или пока Вы не отмените его (действий не требуется).

Использовав эту способность, Вы не сможете использовать её снова, пока не закончите длинный отдых.`,
    spellIdList: SPELL_CONJURE_ANIMALS,
    source: {
      id: SOURCE_XGTE,
      page: 28,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_THE_SHEPHERD,
  })
)

