const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_IDRotF,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
  TIME_BONUS_ACTION,
} = require('./../../../../../timePeriodList')
const {
  DAMAGE_FORCE,
} = require('./../../../../../damageTypeList')
const {
  SPELL_BLADE_OF_DISASTER,
  SPELL_WALL_OF_FORCE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_BLADE_OF_DISASTER,
  name: 'Клинок разрушения',
  nameAlt: 'Клинок беды',
  nameEn: 'Blade of Disaster',
  description: `Вы создаёте планарный разлом в форме клинка длиной 3 фута в видимом Вами в пределах дальности заклинания свободном пространстве. Клинок существует всю длительность заклинания.

Сотворяя это заклинание, Вы можете совершить этим клинком до двух рукопашных атак заклинанием по существу, строению или предмету, который никто не несёт и не носит, находящимся в пределах 5 футов от клинка. При попадании существо получает 4к12 урона силовым полем. Эти атаки считаются критическими попаданиями при выпадении 18 и выше на к20. При критическом попадании, клинок наносит дополнительно 8к12 урона силовым полем (всего 12к12).

Вы можете бонусным действием на каждом своём ходу передвигать клинок в видимое Вами свободное пространство в пределах 30 футов и совершать этим клинком до двух рукопашных атак заклинанием.

Клинок может безвредно проходить через любое препятствие, включая [Силовую стену](SPELL:${SPELL_WALL_OF_FORCE}).`,
  lvl: 9,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  needConcentration: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 1 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  effect: {
    damage: [
      {
        type: DAMAGE_FORCE,
        diceCount: 4,
        diceType: 12,
      },
    ],
  },
  source: {
    id: SOURCE_IDRotF,
    page: 319,
  },
}
