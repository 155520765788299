module.exports = [
  require('./list/crag_cat'),
  require('./list/giant_crab'),
  require('./list/maegera_the_dawn_titan'),
  require('./list/purple_wormling'),
  require('./list/ogre_goblin_hucker'),
  require('./list/tressym'),
  require('./list/uthgardt_shaman'),
  require('./list/yakfolk_priest'),
  require('./list/yakfolk_warrior'),
]
