const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DETECT_MAGIC,
  SPELL_FEATHER_FALL,
  SPELL_FOG_CLOUD,
  SPELL_LIGHT,
  SPELL_MISTY_STEP,
} = require('./../../../../spellIdList')
const {
  CREATURE_CLOUD_GIANT,
  CREATURE_FOG_GIANT,
  CREATURE_ORC,
  CREATURE_SPY,
} = require('./../../../../creatureIdList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_GIANT_CLOUD,
  CREATURE_TYPE_GIANT,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_GREATSWORD,
} = require('./../../../../gearIdList')
const {ABILITY_KEEN_SMELL} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {cloudGiantDescriptionList} = require('./../../../../textCommonParts')
const {CR_11} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MFFV_1_MMB} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Туманный великан',
  nameEn: 'Fog Giant',
  id: CREATURE_FOG_GIANT,
  description: [
    {
      header: 'Туманные великаны',
      text: `Место [облачных великанов](CREATURE:${CREATURE_CLOUD_GIANT}) в Уложении (совокупности ценностей и ожиданий, определяющих их положение в великаньем обществе) определяется богатством. Те из [облачных великанов](CREATURE:${CREATURE_CLOUD_GIANT}), что глупы или недостаточно удачливы, чтобы позволить разграбить свои сокровища и упасть на дно Уложения, становятся изгоями, известными как туманные великаны. Отрезанные от надлежащего места в обществе, они становятся налётчиками и мародерами, которые стремятся вернуть свои сокровища или начать новый клад — любыми возможными способами.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 10,
      },
    },
    {
      header: 'Опустившиеся богачи',
      text: `Хотя они и живут как безжалостные грабители, туманные великаны сохраняют вкус и изысканность желаний. Они помнят своё прежнее богатство и власть с горькой смесью тоски, сожаления и стыда, стремясь заменить огромные сокровища, которыми они когда-то обладали. Монеты, самоцветы или товары никак не удовлетворяют жажды туманного великана. Вместо этого они ищут великие произведения искусства, чудесные украшения и красивые скульптуры.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 10,
      },
    },
    {
      header: 'Разумные предложения',
      text: `Туманные великаны — могущественные воины, но они предпочитают использовать угрозы и запугивание, чтобы добиться своего. Туманный великан ищет новости и слухи о сокровищах, которые достойны его чувства прекрасного, а затем выслеживает владельцев этих сокровищ и угрожает им демонстрацией силы. Открыть пинком ворота замка захолустного герцога, убить пару десятков охранников, а потом предложить переговоры — типичная стратегия туманного великана, за которой следует предложение оставить герцога в живых в обмен на парочку интересующих великана сокровищ.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 10,
      },
    },
    {
      header: 'Короли бандитов',
      text: `Вынужденные поселиться в изгнании на землях малого народа, многие туманные великаны проявляют интерес к этому самому народу. Сочетая угрозы с обещаниями огромной награды, как только он вернётся на своё исконное место, туманный великан заманивает отчаянных преступников, хитрых бандитов и других разбойников на службу в мрачных дебрях. Эти великаны предпочитают работать с амбициозными людьми, эльфами-предателями и жадными гномами — с каждым, кого они посчитают достаточно цивилизованными. Они рассматривают [орков](CREATURE:${CREATURE_ORC}), гоблиноидов и других «варварских» гуманоидов лишь как надоедливых паразитов, которых лучше всего убить или прогнать.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 10,
      },
    },
    {
      header: 'Мастера планирования',
      text: `Когда туманный великан набирает достаточно последователей, он ставит перед ними задачу восстановить свою коллекцию чудесных и ценных сокровищ. Его любимые слуги — цивилизованные личности, которые могут отираться среди богатых и утончённых. Эти агенты запоминают все ценности, которые могут заинтересовать туманного великана, который затем планирует грабежи, набеги и другие действия по добыванию выбранного приза. Великан может совершить тщательно спланированное ограбление, широко используя магию, чтобы скрыть своё присутствие. Или же он может участвовать в набеге с применением грубой силы, что включает в себя отрывание крыши дома купца, хватание того, что он ищет, и бегство до того, как городская стража успеет отреагировать.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 10,
      },
    },
    {
      header: 'Сложные сети',
      text: `Умные, амбициозные и жадные, многие туманные великаны создают целые сети бандитов, налётчиков, [шпионов](CREATURE:${CREATURE_SPY}) и преступников. В некоторых случаях такая сеть может стать достаточно большой, чтобы слуги низких рангов не знали об истинной природе своего лидера. Туманные великаны, которые создают такие организации, считают себя изгнанными дворянами и часто принимают такие причудливые титулы, как Герцог грабежа, Барон бандитов или Лорд воровства.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 10,
      },
    },
    ...cloudGiantDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_CLOUD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 10,
  },
  armor: {
    ac: 16,
    gearId: GEAR_CHAIN_MAIL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 27,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 22,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_11,
  featureList: [
    {
      name: 'Обитатель тумана',
      description: `На своём ходу ★СУЩЕСТВО★ игнорирует эффекты заклинания [Туманное облако](SPELL:${SPELL_FOG_CLOUD}), сотворённого ★им★ или ★его★ союзниками.`,
    },
    ABILITY_KEEN_SMELL,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
          SPELL_FOG_CLOUD,
          SPELL_LIGHT,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_FEATHER_FALL,
          SPELL_MISTY_STEP,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Двуручным мечом_ и сотворяет [Туманное облако](SPELL:${SPELL_FOG_CLOUD}).`,
    },
    {
      name: 'Двуручный меч',
      // gearId: GEAR_GREATSWORD, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 6,
          diceType: 6,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Скала',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 12,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Мелкий тиран',
      restore: 6,
      description: `★СУЩЕСТВО★ выкрикивает чудовищные угрозы прислуживающим ему существам. Все ★его★ средние или меньшие союзники в пределах 120 футов, которые могут его видеть или слышать, могут совершить рукопашную атаку реакцией.`,
    },
  ],
}
