const {damageTypeCollection} = require('./../../../../../damageTypeList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {MAGIC_ITEM_RING_OF_RESISTANCE} = require('./../../../../../magicItemIdList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

const tableText = list
  .sort(
    ({damageTypeId: damageTypeA}, {damageTypeId: damageTypeB}) => damageTypeCollection[damageTypeA].name > damageTypeCollection[damageTypeB].name
      ? 1
      : -1,
  )
  .reduce(
    (text, { damageTypeId, gemName }, i) => {
      const damage = damageTypeCollection[damageTypeId].name

      return `${text}
| ${i + 1} | ${damage} | ${gemName} |`
    },
    `
| к${list.length} | Вид урона | Камень |
|-----------------|-----------|--------|`
  )

module.exports = {
  id: MAGIC_ITEM_RING_OF_RESISTANCE,
  name: `Кольцо сопротивления`,
  nameEn: `Ring of Resistance`,
  type: MGC_TYPE_RING,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Вы обладаете сопротивлением к одному виду урона, пока носите это кольцо.

Драгоценный камень в кольце указывает на этот вид урона, который Мастер выбирает самостоятельно или с помощью таблицы.

${tableText}`,
  genderId: GENDER_MIDDLE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 177,
  },
}
