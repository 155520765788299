const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_GREATAXE,
  GEAR_HIDE_ARMOR,
} = require('./../../../../gearIdList')
const {ABILITY_RECKLESS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_ANY_CHAOTIC} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_BERSERKER} = require('./../../../../creatureIdList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Берсерк',
  nameEn: 'Berserker',
  id: CREATURE_BERSERKER,
  description: `Непредсказуемые берсерки из варварских земель объединяются в боевые отряды и ищут вооружённые конфликты везде, где могут.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY_CHAOTIC,
  source: {
    id: SOURCE_MM,
    page: 344,
  },
  armor: {
    ac: 13,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 17,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 9,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_RECKLESS,
  ],
  actionList: [
    {
      gearId: GEAR_GREATAXE,
    },
  ],
  genderId: GENDER_MALE,
}
