const {PC_SUBCLASS_ROGUE_SOULKNIFE_2020_04_14} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED} = require('./../../../sourceList')

const {
  FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14,
  FEATURE_PSIONIC_VEIL_2020_04_14,
  FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14,
  FEATURE_REND_MIND_2020_04_14,
  FEATURE_SNEAK_ATTACK,
  FEATURE_SOUL_BLADES_2020_04_14,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14,
    name: `Псионический талант`,
    nameEn: `Psionic Talent`,
    lvl: 3,
    text: `Вы содержите в себе источник псионической силы. Эта энергия прибывает и убывает, когда Вы её направляете. Вы получаете кость _Псионических талантов_, которая представляет Вашу псионическую силу. Начальный размер кости — **к6**.

# Варианты псионических талантов.

Вы можете использовать свою кость _Псионических талантов_ следующими способами

## Пси-усиленная сноровка

Когда непсионические навыки Вас подводят, Вы можете помочь себе псионической силой. Если Вы провалили проверку навыка или использования инструмента, которым владеете, Вы можете бросить кость _Псионических талантов_ и добавить выпавший результат к проверке, что потенциально может привести к её успеху.

## Психический шёпот

С помощью псионических способностей, Вы можете установить телепатическое общение с другими, что идеально подходит для незаметного проникновения. Вы можете действием дать себе и другим существам способность телепатически разговаривать друг с другом. Сделав так, бросьте кость Псионических талантов и выберите количество видимых Вами существ, не превышающее выпавший на кости результат. В течение 1 часа выбранные существа могут телепатически общаться с Вами, и Вы можете телепатически общаться с ними. Чтобы отправить или получить сообщение (действий не требуется), Вы и другое существо должны находиться в пределах 1 мили друг от друга. Существо не может пользоваться этой телепатией, если не говорит на каких-либо языках. Существо может разорвать телепатическую в любой момент (действий не требуется). Вам и существу не нужно говорить на одном языке, чтобы понимать друг друга.

## Изменение размера кости

Если Вы выбросили максимальное значение на кости _Псионических талантов_, после этого броска она уменьшается на один размер. Это представляет выгорание Вашей псионической силы. Например, если кость — к6 и на ней выпало 6, она становится к4. Если она к4 и на ней выпало 4, она становится недоступной до окончание длинного отдыха.

И наоборот, когда на кости _Псионических талантов_ выпадает 1 — после броска она увеличивается на один размер, вплоть до начального размера. Это представляет то, как Вы сохраняете псионическую энергию для дальнейшего использования. Например, если кость — к4 и на ней выпало 1, она становится к6.

Когда Вы завершаете длинный отдых, Ваша кость _Псионических талантов_ сбрасывается к начальному размеру. Когда Вы достигаете определённого уровня, начальный размер кости увеличивается: на 5 уровне (к8), 11 уровне (к10) и 17 уровне (к12).

# Восстановление пси

Бонусным действием Вы можете успокоить свой разум, восстановив свою кость _Псионических талантов_ до начального размера. Вы не можете снова использовать эту способность, пока не завершите длинный отдых.`,
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 4,
    },
  },
  {
    id: FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14,
    name: `Психические клинки`,
    nameEn: `Psychic Blades`,
    lvl: 3,
    text: `Псионической силой Вы можете воплотить мерцающий клинок психической энергии.

Когда Вы собираетесь совершить рукопашную или дальнобойную атаку по существу, Вы можете создать в свободной руке психический клинок и совершить им атаку. Этот магический клинок — простое фехтовальное метательное рукопашное оружие. Его нормальная дальность броска — 60 футов, а максимальной дальности у него нет. При попадании он наносит психический урон 1к6 плюс модификатор характеристики, который Вы использовали для совершения атаки. Клинок исчезает сразу после атаки и не оставляет на цели следов, если наносит урон.

После того, как Вы атаковали клинком, Вы можете на этом же ходу бонусным действием совершить рукопашную или дальнобойную атаку вторым психическим клинком, если Ваша вторая рука свободна для его создания. Кость урона этой атаки — 1к4 вместо 1к6.`,
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 4,
    },
  },
  {
    id: FEATURE_SOUL_BLADES_2020_04_14,
    name: `Духовные клинки`,
    nameEn: `Soul Blades`,
    lvl: 9,
    text: `Ваши [Пси-клинки](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14}) теперь отражают Вашу наполненную пси душу, расширяя их применения:

# Самонаводящиеся удары

Когда Вы совершаете атаку [Пси-клинками](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14}) и промахиваетесь, Вы можете бросить кость [Псионических талантов](FEATURE:${FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14}) и добавить выпавший результат к броску атаки. Если после этого атака попадает, Ваша кость [Псионических талантов](FEATURE:${FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14}) уменьшается на один размер, вне зависимости от результата броска.

# Пси-телепортация

Если Ваша кость [Псионических талантов](FEATURE:${FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14}) доступна, Вы можете метнуть [Пси-клинок](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14}), чтобы магически телепортироваться в другое свободное пространство. Вы бонусным действием создаёте один из [Пси-клинков](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14}) и метаете его в видимое Вами свободное пространство на расстояние, не превышающее пятикратного размера кости Псионических талантов.

После этого [Пси-клинок](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14}) исчезает, а кость [Псионических талантов](FEATURE:${FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14}) уменьшается на один размер.`,
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 4,
    },
  },
  {
    id: FEATURE_PSIONIC_VEIL_2020_04_14,
    name: `Пси-вуаль`,
    nameEn: `Psionic Veil`,
    lvl: 13,
    text: `Вы можете спрятаться в завесе психических помех.

Вы можете действием магически стать невидимым вместе со всем несомым и носимым снаряжением на 10 минут, или пока не отмените этот эффект (действий не требуется). Эта невидимость заканчивается, если Вы наносите существу урон или заставляете его пройти испытание.

Вы не можете снова использовать эту способность, пока не завершите длинный отдых, если только не уменьшите кость Псионических талантов на один размер, чтобы снова использовать эту способность.`,
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 5,
    },
  },
  {
    id: FEATURE_REND_MIND_2020_04_14,
    name: `Разрыв разума`,
    nameEn: `Rend Mind`,
    lvl: 17,
    text: `Вы можете пронзить [Пси-клинками](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14}) сам разум существа.

Когда Вы наносите по существу [Пси-клинками](FEATURE:${FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14}) урон от [Скрытой атаки](FEATURE:${FEATURE_SNEAK_ATTACK}), Вы можете заставить цель пройти испытание Мудрости (СЛ равна 8 + бонус мастерства + модификатор Ловкости). При провале цель Ошеломляется до конца Вашего следующего хода.

Вы не можете снова использовать эту способность, пока не завершите длинный отдых, если только не уменьшите кость Псионических талантов на один размер, чтобы снова использовать эту способность.`,
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 5,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_SOULKNIFE_2020_04_14,
  })
)
