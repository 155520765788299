const listToCollectionById = require('./../utils/listToCollectionById')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./paramList')

const SKILL_ACROBATICS = 'acrobatics'
const SKILL_ANIMAL_HANDLING = 'animal_handling'
const SKILL_ANY = 'any'
const SKILL_ARCANA = 'arcana'
const SKILL_ATHLETICS = 'athletics'
const SKILL_DECEPTION = 'deception'
const SKILL_HISTORY = 'history'
const SKILL_INSIGHT = 'insight'
const SKILL_INTIMIDATION = 'intimidation'
const SKILL_INVESTIGATION = 'investigation'
const SKILL_MEDICINE = 'medicine'
const SKILL_NATURE = 'nature'
const SKILL_PERCEPTION = 'perception'
const SKILL_PERFORMANCE = 'performance'
const SKILL_PERSUASION = 'persuasion'
const SKILL_RELIGION = 'religion'
const SKILL_SLEIGHT_OF_HAND = 'sleight_of_hand'
const SKILL_STEALTH = 'stealth'
const SKILL_SURVIVAL = 'survival'

const skillList = [
  {
    id: SKILL_ATHLETICS,
    paramId: PARAM_STR,
    name: 'Атлетика',
    description: `Проверки Силы (Атлетика) покрывают разнообразные ситуации, в которых Вы лазаете, прыгаете или плаваете. Примеры включают следующие действия:

• Вы пытаетесь взобраться на крутой утёс, избегая при этом опасностей, или попытки удержаться за что-то, когда вас пытаются стряхнуть.
• Вы пытаетесь прыгнуть на большое расстояние или совершить трюк в середине прыжка.
• Вы пытаетесь плыть или оставаться на плаву в бурных водах, во время шторма или среди зарослей водорослей. Другое существо может попытаться толкнуть или затянуть вас под воду, или как-то иным способом помешать вашему плаванию.`,
  },
  {
    id: SKILL_ACROBATICS,
    paramId: PARAM_DEX,
    name: 'Акробатика',
    description: ` Проверки Ловкости (Акробатика) охватывают попытки устоять на ногах в неудобной ситуации, например, когда Вы пытаетесь пробежаться по льду, балансируете на натянутом канате или стоите на палубе корабля в шторм. Мастер может также призвать к проверке Ловкости (Акробатика), чтобы увидеть, можете ли Вы выполнить акробатический трюк, включая ныряние, кувырки, сальто и перекаты.`,
  },
  {
    id: SKILL_SLEIGHT_OF_HAND,
    paramId: PARAM_DEX,
    name: 'Ловкость рук',
    description: `Если Вы пытаетесь жонглировать или сделать ловкий трюк, например, подбросить что-то другому или спрятать что-то на своём теле, совершите проверку Ловкости (Ловкость рук). Мастер может призвать совершить проверку Ловкости (Ловкость рук) чтобы определить, можете ли Вы срезать у ротозея кошелёк или подбросить чтото другому в карман `,
  },
  {
    id: SKILL_STEALTH,
    paramId: PARAM_DEX,
    name: 'Скрытность',
    description: `Совершите проверку Ловкости (Скрытность), когда пытаетесь спрятаться от врагов, прокрасться мимо стражи, сбежать незамеченным или незаметно и неслышно подобраться к кому-то.`,
  },
  {
    id: SKILL_ARCANA,
    paramId: PARAM_INT,
    name: 'Магия',
    description: `Проверка Интеллекта (Магия) определяет вашу способность вспомнить знания о заклинаниях, магических предметах, мистических символах, магических традициях, планах существования и обитателях этих планов.`,
  },
  {
    id: SKILL_HISTORY,
    paramId: PARAM_INT,
    name: 'История',
    description: `Проверка Интеллекта (История) определяет вашу способность вспомнить знания об исторических событиях, легендарных личностях, древних королевствах, былых спорах, недавних войнах и утерянных цивилизациях.`,
  },
  {
    id: SKILL_INVESTIGATION,
    paramId: PARAM_INT,
    name: 'Анализ',
    description: `Если Вы ищете подсказки и делаете на их основе выводы, Вы совершаете проверку Интеллекта (Анализ). Вы можете вычислить местоположение спрятанного предмета, понять по виду раны, каким оружием она была нанесена или определить самую слабую точку в туннеле, способную его обрушить. Рассматривание древних свитков в поисках тайного знания тоже может требовать совершения проверки Интеллекта (Анализ).`,
  },
  {
    id: SKILL_NATURE,
    paramId: PARAM_INT,
    name: 'Природа',
    description: `Проверка Интеллекта (Природа) определяет вашу способность вспомнить знания о местности, о растениях и животных, о погоде и естественных циклах.`,
  },
  {
    id: SKILL_RELIGION,
    paramId: PARAM_INT,
    name: 'Религия',
    description: `Проверка Интеллекта (Религия) определяет вашу способность вспомнить знания о божествах, ритуалах и молитвах, религиозных иерархиях, священных символах и практиках тайных культов.`,
  },
  {
    id: SKILL_ANIMAL_HANDLING,
    paramId: PARAM_WIT,
    name: 'Обращение с животными',
    description: `Если возникает вопрос, можете ли Вы успокоить одомашненное животное, удержать скакуна от того, чтобы он запаниковал, или почувствовать намерения животного, Мастер может призвать совершить проверку Мудрости (Уход за животными). Вы также совершаете проверку Мудрости (Уход за животными) для контролирования скакуна при совершении опасного манёвра.`,
  },
  {
    id: SKILL_INSIGHT,
    paramId: PARAM_WIT,
    name: 'Проницательность',
    description: `Ваша проверка Мудрости (Проницательность) определяет, можете ли Вы определить истинные намерения существа, например, при попытке распознать ложь или предсказать чей-то следующий шаг. При этом Вы совершаете догадки по языку тела, особенностям разговора и переменах в поведении. `,
  },
  {
    id: SKILL_MEDICINE,
    paramId: PARAM_WIT,
    name: 'Медицина',
    description: `Проверка Мудрости (Медицина) позволяет стабилизировать умирающего спутника или диагностировать болезнь.`,
  },
  {
    id: SKILL_PERCEPTION,
    paramId: PARAM_WIT,
    name: 'Внимательность',
    description: `Проверка Мудрости (Внимательность) позволяет вам увидеть, услышать или как-то иначе заметить присутствие чего-либо. Она измеряет вашу готовность к новым событиям и остроту ваших чувств. Например, Вы может подслушать разговор под дверью, подсмотреть в окно или услышать чудовищ, крадущихся по лесу. Или Вы можете попытаться заметить что-то, что скрыто или легко можно пропустить, будь то орки, лежащие в засаде у дороги, головорезы, затаившиеся в тенях аллеи или свет от лампы под закрытой потайной дверью.

# Поиски скрытых предметов

Если ваш персонаж ищет скрытый предмет, такой как потайная дверь или ловушка, Мастер обычно просит совершить проверку Мудрости (Внимательность). Такая проверка может использоваться для поиска скрытых деталей или другой информации и подсказок, которые Вы могли бы пропустить.

В большинстве случаев вам нужно описать, где Вы ищете, чтобы Мастер определил ваши шансы на успех. Например, если ключ спрятан под одеждой в верхнем ящике комода, и Вы говорите, что ищете по комнате, осматривая стены, Вы не найдёте ключ, каким бы ни был результат проверки Мудрости (Внимательность). Чтобы получить шанс на успех, Вам нужно заявить, что Вы открываете все ящики или ищете конкретно в этом комоде.`,
  },
  {
    id: SKILL_SURVIVAL,
    paramId: PARAM_WIT,
    name: 'Выживание',
    description: `Мастер может призвать совершить проверку Мудрости (Выживание) для выслеживания врагов, охоты на дичь, нахождения дороги в промёрзшей тундре, замечания признаков того, что рядом живут совомеды, предсказывания погоды и избегания зыбучих песков и прочих природных опасностей.`,
  },
  {
    id: SKILL_DECEPTION,
    paramId: PARAM_CHA,
    name: 'Обман',
    description: `Ваша проверка Харизмы (Обман) определяет, можете ли в ы намеренно утаить правду, хоть речами, хоть действиями. Такой обман охватывает всё, от направления других по неверному следу и двусмысленных речей до рассказывания откровенной лжи. Может применяться при отвлечении стражников, обмане торговцев, зарабатывании денег азартными играми, маскировке под других, отводе чужих подозрений ложными утверждениями и сохранении спокойного лица при рассказывании чудовищной лжи.`,
  },
  {
    id: SKILL_INTIMIDATION,
    paramId: PARAM_CHA,
    name: 'Запугивание',
    description: `Когда Вы пытаетесь воздействовать на других угрозами, враждебными действиями и физическим насилием, Мастер может попросить вас совершить проверку Харизмы (Запугивание). Примерами могут послужить добыча информации из пленника, убеждение головорезов отказаться от драки и угроза разбитой бутылкой ехидному визирю, чтобы тот изменил решение.`,
  },
  {
    id: SKILL_PERFORMANCE,
    paramId: PARAM_CHA,
    name: 'Выступление',
    description: `Ваша проверка Харизмы (Выступление) определяет, насколько хорошо Вы удовлетворили публику музыкой, танцем, актёрской игрой, рассказом или другим развлечением.`,
  },
  {
    id: SKILL_PERSUASION,
    paramId: PARAM_CHA,
    name: 'Убеждение',
    description: `Если Вы пытаетесь повлиять на существо или целую группу существ тактично, с уважением и добродушием, Мастер может попросить вас совершить проверку Харизмы (Убеждение). Обычно убеждение используется при добрых намерениях, при попытках подружиться, установить контакт или проявить этикет. Примеры убеждения других включают в себя попытки убедить дворецкого пропустить вас к королю, мирное урегулирование споров двух враждующих племён и воодушевление толпы горожан.`,
  },
  {
    id: SKILL_ANY,
    name: 'Любой навык',
    description: `Любой навык на Ваш выбор`,
  },
]

module.exports = skillList

module.exports.skillCollection = listToCollectionById(skillList)

module.exports.SKILL_ACROBATICS = SKILL_ACROBATICS
module.exports.SKILL_ANIMAL_HANDLING = SKILL_ANIMAL_HANDLING
module.exports.SKILL_ANY = SKILL_ANY
module.exports.SKILL_ARCANA = SKILL_ARCANA
module.exports.SKILL_ATHLETICS = SKILL_ATHLETICS
module.exports.SKILL_DECEPTION = SKILL_DECEPTION
module.exports.SKILL_HISTORY = SKILL_HISTORY
module.exports.SKILL_INSIGHT = SKILL_INSIGHT
module.exports.SKILL_INTIMIDATION = SKILL_INTIMIDATION
module.exports.SKILL_INVESTIGATION = SKILL_INVESTIGATION
module.exports.SKILL_MEDICINE = SKILL_MEDICINE
module.exports.SKILL_NATURE = SKILL_NATURE
module.exports.SKILL_PERCEPTION = SKILL_PERCEPTION
module.exports.SKILL_PERFORMANCE = SKILL_PERFORMANCE
module.exports.SKILL_PERSUASION = SKILL_PERSUASION
module.exports.SKILL_RELIGION = SKILL_RELIGION
module.exports.SKILL_SLEIGHT_OF_HAND = SKILL_SLEIGHT_OF_HAND
module.exports.SKILL_STEALTH = SKILL_STEALTH
module.exports.SKILL_SURVIVAL = SKILL_SURVIVAL
