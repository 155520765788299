const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_PETRIFYING_GAZE,
  } = require('./../../../../creatureAbilityList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CREATURE_BASILISK,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Василиск',
  nameEn: 'Basilisk',
  id: CREATURE_BASILISK,
  description: [
    {
      header: 'Василиск',
      text: `Путешественники иногда натыкаются в диких местах на предметы, которые выглядят как куски удивительно реалистичной резьбы по камню. Недостающие куски кажутся откушенными. Бывалые путешественники считают такие реликвии предупреждением о том, что где-то рядом находится василиск.`,
    },
    {
      header: 'Адаптирующийся хищник',
      text: `Василискам комфортно в засушливом, умеренном или тропическом климате. Его логово располагается в пещере или другом защищённом месте. Однако чаще василисков встречают под землёй.

Василиски, родившиеся и выращенные в неволе, могут быть одомашнены и обучены. Обученный василиск знает, как избегать взгляда с теми, кого хозяин хочет защитить, что делает его опасным охранником. Именно из-за этого яйца василиска высоко ценятся.`,
    },
    {
      header: 'Каменный взгляд',
      text: `Василиски слишком тяжеловесные для охоты, но им и не нужно преследовать добычу. Взгляда василиска может быть достаточно, чтобы быстро превратить жертву в пористый камень, а сильные челюсти василиска способны крошить камень. А уже в пищеводе камень снова принимает органическую форму.

Некоторые алхимики утверждают, что знают, как обработать пищевод василиска и жидкости, находящиеся в нем. Из обработанного должным образом пищевода можно выделить масло, которое будет обращать окаменение. К сожалению, если жертва потеряла какую-либо часть своего тела, пока находилась в каменном виде, при оживлении она не восстановится. Если в каменном виде существо потеряло жизненно важный орган, например, голову, вернуть существо в первоначальное состояние при помощи этого масла будет невозможно.`,
    },
  ],
  note: {
    text: `Никто не будет высекать статую испуганного воина. Если увидели такую, держите глаза закрытыми, а ухо востро`,
    author: `четвёртое правило выживания в подземельях Мистика Х`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 32,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  cr: CR_3,
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 15,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 7,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  featureList: [
    {
      id: ABILITY_PETRIFYING_GAZE,
      dc: 12,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
