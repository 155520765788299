module.exports = [
  require('./rakshasa'),
  require('./rat'),
  require('./raven'),
  require('./reef_shark'),
  require('./remorhaz'),
  require('./revenant'),
  require('./rhinoceros'),
  require('./riding_horse'),
  require('./roc'),
  require('./roper'),
  require('./rug_of_smothering'),
  require('./rust_monster'),
]
