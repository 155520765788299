const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  GEAR_DAGGER,
  GEAR_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  CR_1_2,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DARKLING,
  CREATURE_TYPE_FEY,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ACROBATICS,
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_CN,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_DARKLING,
} = require('./../../../../creatureIdList')
const {
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  darklingDescriptionList,
} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Дарклинг',
  nameEn: 'Darkling',
  id: CREATURE_DARKLING,
  description: darklingDescriptionList,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_DARKLING,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_VGTM,
    page: 147,
  },
  armor: {
    ac: 14,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ELVEN,
    LANG_SYLVAN,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_SUNLIGHT_SENSITIVITY,
    {
      name: 'Вспышка смерти',
      description: `Когда ★СУЩЕСТВО★ умирает, немагический свет испускается из ★него★ в радиусе 10 футов, пока его тело и пожитки, кроме металлических или магических предметов, сгорают дотла. Любое существо в этой области, видящее яркий свет, должно пройти испытание Телосложения СЛ 10 или ослепнуть до конца своего следующего хода.`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
      description: `Если ★СУЩЕСТВО★ атакует с преимуществом, то атака дополнительно наносит 7 (2к6) колющего урона.`,
    },
  ],
}
