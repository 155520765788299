const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_NEOGI,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  neogiDescription,
  neogiNote,
} = require('./../../../../textCommonParts')
const {ABILITY_SPIDER_CLIMB} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_8} = require('./../../../../crList')
const {CREATURE_NEOGI_HATCHLING} = require('./../../../../creatureIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Неоги-личинка',
  nameEn: 'Neogi Hatchling',
  id: CREATURE_NEOGI_HATCHLING,
  description: neogiDescription,
  note: neogiNote,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_NEOGI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 173,
  },
  armor: 11,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_CLIMB]: 20,
  },
  params: {
    [PARAM_STR]: 3,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 10,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_1_8,
  featureList: [
    {
      name: 'Ментальная стойкость',
      description: `Личинка имеет преимущество на испытания от очарования и испуга, и личинку нельзя усыпить магически.`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Цель должна успешно пройти испытание Телосложения СЛ 10 или стать отравленной на 1 минуту. Цель может повторять испытание в конце каждого своего хода и окончить этот эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 1,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
  ],
}
