const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_GRAVITY_SINKHOLE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GRAVITY_SINKHOLE,
  name: 'Гравитационная воронка',
  nameEn: 'Gravity Sinkhole',
  description: `В видимой Вами точке формируется сфера сокрушительной силы радиусом 20 футов и затягивает в себя существ.

Все существа внутри сферы должны пройти испытание Телосложения.

При провале существо получает 5к10 урона силовым полем и притягивается по прямой линии к центру сферы. Существо останавливается в свободном пространстве так близко к её центру, как только возможно (даже если при этом оно поднимется в воздух).

При успешном испытании существо получает половину урона и не притягивается.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, урон увеличивается на 1к10 за каждый круг ячейки выше четвёртого.`,
  lvl: 4,
  magicSchoolId: MAGIC_EVOCATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `чёрный мрамор`,
  isDunamisBased: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 5,
      diceType: 10,
    },
  },
  source: {
    id: SOURCE_EGTW,
    page: 188,
  },
}
