const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  ABILITY_CHARGE,
  ABILITY_HOLD_BREATH,
} = require('./../../../../creatureAbilityList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_1_8,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_DOLPHIN,
} = require('./../../../../creatureIdList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')

module.exports = {
  name: 'Дельфин',
  nameEn: 'Dolphin',
  id: CREATURE_DOLPHIN,
  description: `Дельфины — умные, социальные морские млекопитающие, которые питаются рыбой и кальмарами. Взрослый экземпляр от 5 до 6 футов в длину.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 210,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_SWIM]: 60,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 13,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
  ],
  cr: CR_1_8,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 30,
      attackName: 'Тараном',
      damageText: '3 (1к6) дробящего урона',
      afterEffectText: '',
    },
    {
      id: ABILITY_HOLD_BREATH,
      time: '20 минут',
    },
  ],
  actionList: [
    {
      name: 'Таран',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
