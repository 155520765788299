const {SOURCE_MTOF} = require('./../../sourceList')

module.exports = [
  {
    header: 'Эладрины',
    text: `Эладрины пребывают в зелёном великолепии Страны Фей. Они связаны с эльфами, живущими на Материальном плане, и напоминают их как в их любви к красоте, так и в ценности, которую они придают личной свободе. Но где другие эльфы могут укротить свою дикую натуру, эладрины — существа, управляемые эмоциями, и из-за их уникальной магической природы они подвергаются физическим изменениям, чтобы соответствовать изменчивостью их характера.
  
Эладрины провели столетия в Стране Фей, и в результате, большинство из них стали настоящими фейскими сущностями. Некоторые из них по-прежнему гуманоиды, похожие на их других родственников-эльфов. Представленные здесь эладрины относятся к разновидности фей.`,
    source: {
      id: SOURCE_MTOF,
      page: 243,
    },
  },
  {
    header: 'Существа страсти',
    text: `Магия, протекающая через эладринов, реагирует на их эмоциональное состояние, превращая их в разные сезонные формы, со своим уникальным поведением и способностями, которые меняются с их формами. Некоторые эладрины могут оставаться в определённом аспекте в течение многих лет, в то время как другие проходят через эмоциональный спектр каждую неделю.`,
    source: {
      id: SOURCE_MTOF,
      page: 243,
    },
  },
  {
    header: 'Влюблённые красотой',
    text: `Независимо от своего мировоззрения, эладрины любят красоту и окружают себя прекрасными вещами. Эладрины пытается обладать любыми объектами, которые они находят прекрасными. Они могут стремиться приобрести картину, статую или сверкающую драгоценность. Когда они сталкиваются с людьми с прекрасной фигурой или эмоциональным характером, они используют свою магию, чтобы радовать этих людей или, в случае злого эладрина, похищать их.`,
    source: {
      id: SOURCE_MTOF,
      page: 243,
    },
  },
  {
    header: 'Изменчивая природа',
    text: `Всякий раз, когда один из представленных здесь эладринов заканчивает длинный отдых, он может сменить свою форму, если дееспособен. Когда эладрин делает это изменение, он использует блок статистики новой формы, вместо старой. Любой ущерб, нанесённый эладрину в его первоначальной форме, относится к новой форме, как и любые условия или другие продолжающиеся эффекты, влияющие на неё.`,
    source: {
      id: SOURCE_MTOF,
      page: 243,
    },
  },
]
