const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {
  SPELL_AURA_OF_LIFE,
  SPELL_AURA_OF_VITALITY,
  SPELL_CIRCLE_OF_POWER,
  SPELL_FAERIE_FIRE,
  SPELL_GREATER_INVISIBILITY,
  SPELL_LEOMUND_S_TINY_HUT,
  SPELL_MISLEAD,
  SPELL_MOONBEAM,
  SPELL_SEE_INVISIBILITY,
  SPELL_SLEEP,
} = require('./../../../spellIdList')
const {
  FEATURE_BONUS_PROFICIENCIES_TWILIGHT_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_TWILIGHT_SANCTUARY,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_STRIKE_TWILIGHT_DOMAIN,
  FEATURE_EYES_OF_NIGHT,
  FEATURE_STEPS_OF_NIGHT,
  FEATURE_TWILIGHT_DOMAIN_SPELLS,
  FEATURE_TWILIGHT_SHROUD,
  FEATURE_VIGILANT_BLESSING,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_TWILIGHT_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_AURA_OF_LIFE,
      SPELL_AURA_OF_VITALITY,
      SPELL_CIRCLE_OF_POWER,
      SPELL_FAERIE_FIRE,
      SPELL_GREATER_INVISIBILITY,
      SPELL_LEOMUND_S_TINY_HUT,
      SPELL_MISLEAD,
      SPELL_MOONBEAM,
      SPELL_SEE_INVISIBILITY,
      SPELL_SLEEP,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 47,
    },
  },
  {
    id: FEATURE_BONUS_PROFICIENCIES_TWILIGHT_DOMAIN,
    name: `Бонусные владения`,
    nameEn: `Bonus Proficiencies`,
    lvl: 1,
    text: `Вы получаете владение тяжёлыми доспехами и воинским оружием.`,
    source: {
      id: SOURCE_TCoE,
      page: 48,
    },
  },
  {
    id: FEATURE_EYES_OF_NIGHT,
    name: `Глаза ночи`,
    nameEn: `Eyes of Night`,
    lvl: 1,
    text: `Вы можете видеть сквозь глубочайший мрак. У Вас есть тёмное зрение на расстоянии до 300 футов. В этом радиусе Вы можете видеть при тусклом свете, как если бы это был яркий свет, и в темноте, как если бы это был тусклый свет.

Вы можете действием магически  разделить тёмное зрение этой особенности с желающими существами, видимых Вами в пределах 10 футов, (число существ равно Вашему модификатору Мудрости, минимум одно). Общее тёмное зрение длится 1 час. После того, как Вы поделитесь им, Вы не сможете сделать это снова, пока не закончите длинный отдых, если только Вы не потратите ячейку заклинаний любого круга, чтобы поделиться им снова.`,
    source: {
      id: SOURCE_TCoE,
      page: 48,
    },
  },
  {
    id: FEATURE_VIGILANT_BLESSING,
    name: `Благословение бдительности`,
    nameEn: `Vigilant Blessing`,
    lvl: 1,
    text: `Ночь научила Вас бдительности.

Вы можете действием коснуться одного существа (включая себя) и дать ему преимущество на следующий бросок инициативы. Этот эффект заканчивается сразу после совершения броска или когда Вы снова используете эту способность.`,
    source: {
      id: SOURCE_TCoE,
      page: 48,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_TWILIGHT_SANCTUARY,
    name: `Божественный канал: Сумрачное святилище`,
    nameEn: `Channel Divinity: Twilight Sanctuary`,
    lvl: 2,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), успокаивая союзников мягким сумеречным светом.

Вы действием демонстрируете священный символ и от Вас исходит сфера полумрака радиусом 30 футов с центром на Вас. Сфера сузествует 1 минуту (или пока Вы не потеряете сознание или не умрёте) и двигается вместе с Вами. Когда существо (включая Вас) оканчивает свой ход внутри сферы, Вы можете дать ему одно из следующих преимуществ:

* Вы даёте ему 1к6 + Ваш уровень жреца временных хитов.
* Вы оканчиваете на нём один пугающий или очаровывающий его эффект.`,
    source: {
      id: SOURCE_TCoE,
      page: 48,
    },
  },
  {
    id: FEATURE_STEPS_OF_NIGHT,
    name: `Шаги ночи`,
    nameEn: `Steps of Night`,
    lvl: 6,
    text: `Используя мистическую силу ночи, Вы можете подниматься в воздух.

Находясь в тусклом свете или темноте, Вы можете бонусным действием магически дать себе на течение 1 минуты скорость полёта, равную скорости ходьбы.

Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все израсходованные использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 48,
    },
  },
  {
    id: FEATURE_DIVINE_STRIKE_TWILIGHT_DOMAIN,
    name: `Божественный удар`,
    nameEn: `Divine Strike`,
    lvl: [8, 14],
    text: `Вы получаете способность наполнять удары своего оружия божественной энергией.

Один раз в каждый свой ход, когда Вы попадаете по существу атакой оружием, Вы можете причинить цели дополнительный урон излучением 1к8.

Когда Вы достигаете 14 уровня, дополнительный урон возрастает до 2к8.`,
    source: {
      id: SOURCE_TCoE,
      page: 48,
    },
  },
  {
    id: FEATURE_TWILIGHT_SHROUD,
    name: `Покрывало сумрака`,
    nameEn: `Twilight Shroud`,
    lvl: 17,
    text: `Создаваемый Вами сумрак теперь может защищать.

Вы и Ваши союзники получаете половинное укрытия находясь внутри сферы Вашего [Сумрачного святилища](FEATURE:${FEATURE_CHANNEL_DIVINITY_TWILIGHT_SANCTUARY}).`,
    source: {
      id: SOURCE_TCoE,
      page: 49,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN,
  })
)

