const {SOURCE_VGTM} = require('./../../sourceList')
const {GOD_LOLTH} = require('./../../godIdList')
const {PC_CLASS_WARLOCK, PC_CLASS_WIZARD} = require('./../../pcClassIdList')
const {
  CREATURE_CAVE_FISHER,
  CREATURE_CHITINE,
  CREATURE_CHOLDRITH,
  CREATURE_DROW,
  CREATURE_DROW_PRIESTESS_OF_LOLTH,
  CREATURE_GIANT_SPIDER,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Хитины',
    text: `[Хитины](CREATURE:${CREATURE_CHITINE}) — это многорукие гуманоиды со свойствами арахнидов, которые служат [Ллос](GOD:${GOD_LOLTH}). Они живут в хорошо организованных колониях и показали себя эффективными бойцами в войне с врагами Демонической Королевы Пауков. Иногда, [Ллос](GOD:${GOD_LOLTH}) натравливает [хитин](CREATURE:${CREATURE_CHITINE}) на тёмных эльфов — несмотря на то, что и те и другие поклоняются ей — чтобы наказать дроу, которые создали хитин, но разгневали этим свою богиню.`,
    source: {
      id: SOURCE_VGTM,
      page: 198,
    },
  },
  {
    header: 'Неестественное происхождение',
    text: `Давным давно, [дроу](CREATURE:${CREATURE_DROW}) проводили эксперименты над пленными эльфами и с помощью ужасных ритуалов трансформировали заключённых в существ с чертами гуманоида и [паука](CREATURE:${CREATURE_CHOLDRITH}), а создатели нарекли их хитинами. Темные эльфы хотели сделать рабов преданных перво-наперво всем дроу, и в связи с ними, [Ллос](GOD:${GOD_LOLTH}). Как они позже выяснили, богиня нашла такой порядок вещей неприемлемым.

Процесс создания требовал кооперации между магическими дисциплинами. [Волшебники](PC_CLASS:${PC_CLASS_WIZARD}) и [колдуны](PC_CLASS:${PC_CLASS_WARLOCK}) дроу использовали тайную магию и демонические силы, а [жрицы дроу](CREATURE:${CREATURE_DROW_PRIESTESS_OF_LOLTH}) призывали помощь [Ллос](GOD:${GOD_LOLTH}) в виде божественной искры, нужной для выживания субъекта. [Ллос](GOD:${GOD_LOLTH}) наблюдала и ожидала увидеть, что в какой-то момент процесса эти новые мерзости будут посвящены ей, но такого ритуала проведено не было. В отместку за недостаток уважения, [Королева Пауков](GOD:${GOD_LOLTH}) извратила ритуалы создания дроу на службу своим целям.`,
    source: {
      id: SOURCE_VGTM,
      page: 198,
    },
  },
  {
    header: 'Месть Ллос',
    text: `Дроу продолжали выполнять ритуалы и процесс обычно превращал субъекта в тщедушное, чахлое существо, как они и ожидали. Хотя иногда, эльф превращался в чудовище, которое больше походило на паука, нежели на эльфа, напоминая [Ллос](GOD:${GOD_LOLTH}) в её паучьей форме, и более хитрое, чем [хитина](CREATURE:${CREATURE_CHITINE}), которое дроу прозвали [холдрита](CREATURE:${CREATURE_CHOLDRITH}).

Поначалу дроу не осознавали, что новые существа были знаком недовольства [Ллос](GOD:${GOD_LOLTH}). Напротив, они радовались, потому что холдриты могли откладывать яйца, из которых рождались новые хитины (и иногда холдриты), и управлять работой хитин. Но тёмные эльфы начали осознавать свою ошибку — холдриты принадлежали [Ллос](GOD:${GOD_LOLTH}), душой и телом. Они нашёптывали хитинам о поклонении Королеве Пауков и о вражде с дроу, семена восстания были посеяны и проросли. Хитины и холдриты поднялись против своих казалось бы хозяев; вскоре большинство освободилось, а те дроу, кто помогал выводить и приручать их, были убиты.

Теперь же дроу все ещё создают хитин при надобности. Вне присутствия холдриты, хитины хорошо работают на дроу и могут быть полезными, если дроу найдут независимую колонию хитин и захотят проникнуть в неё. Если в процессе создания рождается холдрита, то дроу тут же уничтожают существо.`,
    source: {
      id: SOURCE_VGTM,
      page: 198,
    },
  },
  {
    header: 'Избранные Ллос',
    text: `Холдриты рождаются с фанатичным поклонением [Ллос](GOD:${GOD_LOLTH}), что позволяет им развить некоторые навыки в божественной магии. Они проповедуют, что хитины — любимый народ [Ллос](GOD:${GOD_LOLTH}), а холдриты — законные представительницы Паучьей Королевы, посланные освободить хитин от рабства. Хоть у холдрит и хитин нет половых характеристик, и холдритам не нужен партнёр для откладывания яиц, эти существа выбрали себе пол своей богини. Холдриты также верят и учат, что паучья форма [Ллос](GOD:${GOD_LOLTH}), сильно напоминающая холдриту — её самая настоящая форма. Любой идол [Ллос](GOD:${GOD_LOLTH}) в колонии хитин изображает её в этом виде.

Будучи слугами [Ллос](GOD:${GOD_LOLTH}), холдриты и хитины любят [пауков](CREATURE:${CREATURE_GIANT_SPIDER}) и подобных существ. Они разводят пауков и похожих арахнидов, таких как [пещерные удильщики](CREATURE:${CREATURE_CAVE_FISHER}). Колонии хитин возводят храмы [Ллос](GOD:${GOD_LOLTH}), которые служат маяками, приманивают пауков и других злых, жестоких существ, которые служат ей. Любая область, в которой хитины организуют колонию, быстро превращается в покрытое паутиной, мрачное и коварное место.`,
    source: {
      id: SOURCE_VGTM,
      page: 198,
    },
  },
  {
    header: 'Общественные пауки',
    text: `Хитины и холдриты напоминают пауков, но ведут себя скорее как социальные насекомые, такие как муравьи. Хитины разделяются на касты рабочих и воинов, а холдриты занимают главенствующее положение в иерархии колонии. У каждой хитины есть социальное положение, которое сопряжено с обязанностями, положенными по рангу, а также все хитины должны по своей воле жертвовать собой для защиты холдриты. У каждой хитины есть прядильный орган, который медленно производит паутину, из которой строят полы, стены, здания, объекты и ловушки, которые развивают и защищают колонию. Воин ответственен за производство доспеха из паутины (который по прочности не уступает шкуре или коже), а группа рабочих может получить задание выкопать яму-ловушку и покрыть её хрупкой паутиной, прикрытой землей так, чтобы казалось что это твёрдая поверхность.

В колонии может быть бесчисленное множество [холдрит](CREATURE:${CREATURE_CHOLDRITH}), которые являются командирами, жрицами и руководителями. Холдриты всегда соперничают за выгодное положение, но редко конфликтуют друг с другом так, что колония оказывается в опасности. Колония управляется единственным совереном, которая определяет какие члены колонии выполняют конкретные задания, включая разрешение ей или любой другой холдрите отложить яйца. Если абсолютная правительница получает видение от [Ллос](GOD:${GOD_LOLTH}), то она может полностью поменять курс действий колонии. В такие времена, хитины появлялись из Подземья и селились в отдалённых, мрачных местах на поверхности, откуда они могли обрушиться войной на другие виды, особенно на дроу и эльфов.`,
    source: {
      id: SOURCE_VGTM,
      page: 198,
    },
  },
]
