const {
  CREATURE_GUARD_DRAKE,
  CREATURE_GUARD_DRAKE_BLUE,
} = require('./../../../../creatureIdList')
const {DAMAGE_ELECTRICITY} = require('./../../../../damageTypeList')
const {SPEED_DIG} = require('./../../../../speedList')

module.exports = {
  name: 'Синий сторожевой дрейк',
  nameEn: 'Blue Guard Drake',
  id: CREATURE_GUARD_DRAKE_BLUE,
  parentId: CREATURE_GUARD_DRAKE,
  extendPropCollection: {
    speed: {
      [SPEED_DIG]: 20,
    },
    resistanceList: [
      DAMAGE_ELECTRICITY,
    ],
  },
}
