const {
  CREATURE_GORISTRO,
  CREATURE_MINOTAUR,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_MAZE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_MAZE,
  name: 'Лабиринт',
  nameEn: `Maze`,
  description: `Вы изгоняете существо, видимое в пределах дистанции, в похожий на лабиринт демиплан. Цель остаётся там, пока заклинание активно, или пока она не найдёт выход.

Цель может действием пытаться найти выход. Для этого она совершает проверку Интеллекта СЛ 20. При успехе она находит выход, а заклинание оканчивается ([минотавры](CREATURE:${CREATURE_MINOTAUR}) и [демоны гористро](CREATURE:${CREATURE_GORISTRO}) преуспевают автоматически).

Когда заклинание заканчивается, цель возвращается в пространство, которое она покинула, или, если оно занято, в ближайшее свободное пространство.`,
  lvl: 8,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 10 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 235,
    },
    {
      id: SOURCE_SRD,
      page: 163,
    },
  ],
}
