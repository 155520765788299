const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {CONDITION_CHARMED} = require('./../../../../../conditionList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SOURCE_AI} = require('./../../../../../sourceList')
const {SPELL_FAST_FRIENDS} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_FAST_FRIENDS,
  name: 'Быстрая дружба',
  nameEn: 'Fast Friends',
  description: `Когда Вы быть уверенным, что что-то будет действительно сделано, Вы не можете полагаться на расплывчатые обещания, присяги или трудовые договоры.

Когда Вы сотворяете это заклинание, выберите одного видимого Вами в пределах дистанции гуманоида, который может видеть, слышать и понимать Вас. Это существо должно пройти испытание Мудрости или быть Очарованным Вами на время действия заклинания. Пока существо очаровано таким образом, оно обязано, в меру своих возможностей, выполнять любые просьбы и совершать действия, о которых Вы просите его в дружественной манере.

Вы можете просить его о новых заданиях, когда текущая задача будет выполнена, или если решите её прервать. Если оказываемая услуга или деятельность могут причинить вред этому существу или если она противоречит его нормальной деятельности или желаниям, то существо может пройти ещё одно испытание Мудрости, чтобы попытаться закончить эффект заклинания. Существо получает преимущество к этому испытанию, если Вы или Ваши спутники сражаетесь с ним. Если эта деятельность приводит к гибели существа, то заклинание заканчивается.

Когда заклинание заканчивается, существо знает, что оно было очаровано Вами.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше третьего.`,
  lvl: 3,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 30,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_HOUR, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
    condition: CONDITION_CHARMED,
  },
  source: {
    id: SOURCE_AI,
    page: 76,
  },
}
