const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_ICE_TOAD,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_ICE_TOAD,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык ледяных жаб',
    genitive: 'Языка ледяных жаб',
    instrumental: 'Языком ледяных жаб',
    prepositional: 'Языке ледяных жаб',
  },
  nameEn: 'Ice Toad',
  isReady: false,
  isRealLang: true,
}
