const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SPELL_FOG_CLOUD} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_FOG_CLOUD,
  name: 'Туманное облако',
  nameEn: 'Fog Cloud',
  description: `Вы создаете сферу с радиусом 20 фт. из тумана с центром на точке в пределах дистанции. Сфера обходит углы, и ее пространство — сильно заслоненная местность. Она существует до окончания действия заклинания, или пока ее не рассеет ветер (со скоростью как минимум 10 миль в час).`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, радиус тумана увеличивается на 20 фт. за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_CONJURATION,
  needConcentration: true,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_HOUR, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 284,
    },
    {
      id: SOURCE_SRD,
      page: 146,
    },
  ],
}
