module.exports = [
  require('./dragon_black_adult'),
  require('./dragon_black_ancient'),
  require('./dragon_black_young'),
  require('./dragon_black_wyrmling'),
  require('./dragon_blue_adult'),
  require('./dragon_blue_ancient'),
  require('./dragon_blue_wyrmling'),
  require('./dragon_blue_young'),
  require('./dragon_brass_adult'),
  require('./dragon_brass_ancient'),
  require('./dragon_brass_young'),
  require('./dragon_brass_wyrmling'),
  require('./dragon_bronze_adult'),
  require('./dragon_bronze_ancient'),
  require('./dragon_bronze_young'),
  require('./dragon_bronze_wyrmling'),
  require('./dragon_copper_adult'),
  require('./dragon_copper_ancient'),
  require('./dragon_copper_young'),
  require('./dragon_copper_wyrmling'),
  require('./dragon_gold_adult'),
  require('./dragon_gold_ancient'),
  require('./dragon_gold_young'),
  require('./dragon_gold_wyrmling'),
  require('./dragon_green_adult'),
  require('./dragon_green_ancient'),
  require('./dragon_green_wyrmling'),
  require('./dragon_green_young'),
  require('./dragon_red_adult'),
  require('./dragon_red_ancient'),
  require('./dragon_red_young'),
  require('./dragon_red_wyrmling'),
  require('./dragon_silver_adult'),
  require('./dragon_silver_ancient'),
  require('./dragon_silver_wyrmling'),
  require('./dragon_silver_young'),
  require('./dragon_turtle'),
  require('./dragon_white_adult'),
  require('./dragon_white_ancient'),
  require('./dragon_white_wyrmling'),
  require('./dragon_white_young'),
]
