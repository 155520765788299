const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_CROSSBOW_HEAVY,
  GEAR_GREATSWORD,
  GEAR_PLATE_ARMOR,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_BRAVE,
  ABILITY_PARRY,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_COMMONER,
  CREATURE_KNIGHT,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Рыцарь',
  nameEn: 'Knight',
  id: CREATURE_KNIGHT,
  description: `Рыцарь это воитель, поклявшийся служить правителю, религиозному ордену или благородному делу. Мировоззрение рыцаря определяет пределы его преданности. Во время выполнения задания или при обычном патрулировании королевства рыцаря часто сопровождает свита, включающая сквайров и наёмников, являющихся [обывателями](CREATURE:${CREATURE_COMMONER}).`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 349,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_3,
  featureList: [
    ABILITY_BRAVE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_GREATSWORD,
    },
    {
      gearId: GEAR_CROSSBOW_HEAVY,
    },
    {
      name: 'Лидерство',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `В течение 1 минуты ★СУЩЕСТВО★ может произносить особую команду или предостережение каждый раз, когда невраждебное существо, видимое ★им★ в пределах 30 футов, совершает бросок атаки или проходит испытание. Это существо может добавить к4 к броску, если может слышать ★СУЩЕСТВО★ и понимает ★его★. Существо может получать преимущество только от одного _Лидерства_ одновременно. Этот эффект оканчивается, если ★СУЩЕСТВО★ становится ★недееспособным★.`,
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 2,
    },
  ],
  genderId: GENDER_MALE,
}
