const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_GIANT_SCORPION} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Гигантский скорпион',
  nameEn: 'Giant Scorpion',
  id: CREATURE_GIANT_SCORPION,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 328,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 15,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 3,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
  ],
  cr: CR_3,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: две _Клешнями_, и одну _Жалом_.`,
    },
    {
      name: 'Клешня',
      description: `Цель становится схваченной (СЛ высвобождения 12). ★СУЩЕСТВО★ имеет две клешни, и в каждой может держать в захвате только по одной цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Жало',
      description: `Цель должна пройти испытание Телосложения СЛ 12, получая урон ядом 22 (4к10) при провале, или половину этого урона при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
