const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {MAGIC_DIVINATION} = require('./../../../../../magicList')
const {SPELL_BEAST_BOND} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_BEAST_BOND,
  name: 'Звериные узы',
  nameEn: 'Beast Bond',
  description: `Вы устанавливаете телепатическую связь с одним зверем, которого касаетесь, если он дружественен Вам или очарован вами. Заклинание терпит неудачу, если Интеллект зверя равен или выше 4. До тех пор, пока заклинание не закончится, связь активна, если Вы и зверь находитесь в пределах прямой видимости. Связь позволяет зверю понимать Ваши телепатические сообщения, и он может телепатически передавать простые эмоции и образы обратно вам. Пока связь активна, зверь получает преимущество от бросков атаки против любого существа в пределах 5 футов от вас, которое Вы можете видеть.`,
  lvl: 1,
  magicSchoolId: MAGIC_DIVINATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `немного меха завернутого в ткань`,
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  source: [
    {
      id: SOURCE_XGTE,
      page: 152,
    },
    {
      id: SOURCE_EE,
      page: 17,
    },
  ],
}
