const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_KNOWLEDGE_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')

const {
  SPELL_ARCANE_EYE,
  SPELL_AUGURY,
  SPELL_COMMAND,
  SPELL_CONFUSION,
  SPELL_IDENTIFY,
  SPELL_LEGEND_LORE,
  SPELL_NONDETECTION,
  SPELL_SCRYING,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SUGGESTION,
} = require('./../../../spellIdList')
const {
  FEATURE_BLESSINGS_OF_KNOWLEDGE,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_KNOWLEDGE_OF_THE_AGES,
  FEATURE_CHANNEL_DIVINITY_READ_THOUGHTS,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_KNOWLEDGE_DOMAIN_SPELLS,
  FEATURE_POTENT_SPELLCASTING_KNOWLEDGE_DOMAIN,
  FEATURE_VISIONS_OF_THE_PAST,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_KNOWLEDGE_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_IDENTIFY,
      SPELL_COMMAND,
      SPELL_SUGGESTION,
      SPELL_AUGURY,
      SPELL_NONDETECTION,
      SPELL_SPEAK_WITH_DEAD,
      SPELL_ARCANE_EYE,
      SPELL_CONFUSION,
      SPELL_LEGEND_LORE,
      SPELL_SCRYING,
    ],
  },
  {
    id: FEATURE_BLESSINGS_OF_KNOWLEDGE,
    name: `Благословение знаний`,
    nameEn: `Blessings of Knowledge`,
    lvl: 1,
    text: `Вы можете выучить два языка на свой выбор. Вы также получаете владение двумя навыками из следующего списка: История, Магия, Природа, Религия.

Ваш бонус мастерства удваивается для всех проверок характеристик при использовании этих навыков.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_KNOWLEDGE_OF_THE_AGES,
    name: `Божественный канал: Знания веков`,
    nameEn: `Channel Divinity: Knowledge of the Ages`,
    lvl: 2,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы получить доступ к источнику знаний.

Вы действием выбираете навык или инструмент. На 10 минут Вы осваиваете владение выбранным навыком или инструментом.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_READ_THOUGHTS,
    name: `Божественный канал: Чтение мыслей`,
    nameEn: `Channel Divinity: Read Thoughts`,
    lvl: 6,
    text: `Вы можете использовать свой [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы читать мысли существ. Затем Вы можете использовать доступ к разуму существа, чтобы командовать им.

Выберите действием одно существо, которое Вы можете видеть, находящееся в пределах 60 футов от Вас. Это существо должно пройти испытание Мудрости. Если существо преуспело, Вы не можете использовать это умение на нём, пока не завершите длинный отдых.

Если существо проваливает испытание, Вы можете прочитать его поверхностные мысли (то, что у него на уме, его текущие эмоции и то, о чём оно активно думает), когда оно находится в пределах 60 футов от Вас. Этот эффект длится в течение 1 минуты.

В течение этого времени Вы можете действием окончить этот эффект, накладывая на существо заклинание [Внушение](SPELL:${SPELL_SUGGESTION}), не тратя ячейку заклинания. Цель автоматически проваливает испытание от этого заклинания.`,
    spellIdList: SPELL_SUGGESTION,
  },
  {
    id: FEATURE_POTENT_SPELLCASTING_KNOWLEDGE_DOMAIN,
    name: `Мощное заклинание`,
    nameEn: `Potent Spellcasting`,
    lvl: 8,
    text: `Вы добавляете модификатор Мудрости к урону, который причиняете заговорами жреца.`,
  },
  {
    id: FEATURE_VISIONS_OF_THE_PAST,
    name: `Видения прошлого`,
    nameEn: `Visions of the Past`,
    lvl: 17,
    text: `Вы можете вызывать видения прошлого, связанные с предметом, который Вы держите, или находящимся вокруг Вас окружением. Вы проводите не менее 1 минуты медитируя и молясь, а затем получаете призрачные туманные видения последних событий. Вы можете медитировать таким образом количество минут, равное Вашему значению Мудрости, и должны поддерживать концентрацию в течение этого времени, как если бы Вы творили заклинание.

После того как Вы использовали это умение, Вы не можете его использовать повторно, пока не окончите короткий или длинный отдых.

# Чтение предмета

Удерживая предмет во время медитации, Вы получаете видение предыдущего владельца этого предмета. После медитации в течение 1 минуты Вы узнаёте, как владелец приобрёл и потерял предмет, а также самое последнее значимое событие с участием предмета и его владельца. Если предмет принадлежал другому существу в недавнем прошлом (в пределах количества дней, равного значению Вашей Мудрости), Вы можете потратить 1 дополнительную минуту на каждого владельца, чтобы узнать ту же информацию о нём.

# Чтение окрестностей

Пока Вы медитируете, Вы наблюдаете видения последних событий, произошедших в непосредственной близости (комната, улица, туннель, поляна и тому подобное в пределах куба с длиной ребра 50 футов), на протяжении количества прошедших дней, равного значению Вашей Мудрости.

За каждую минуту медитации Вы узнаёте об одном значимом событии, начиная с самого последнего. Значимые события обычно связаны с сильными эмоциями, например, битвами и предательствами, свадьбами и убийствами, родами и похоронами. Однако они могут также включать в себя более обыденные события, которые, тем не менее, важны в текущей ситуации.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_KNOWLEDGE_DOMAIN,
    source: {
      id: SOURCE_PHB,
      page: 62,
    },
  })
)
