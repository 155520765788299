const {
  CREATURE_AIR_ELEMENTAL,
  CREATURE_DRAGON_BRASS_ANCIENT,
  CREATURE_ANKHEG,
  CREATURE_ASSASSIN,
  CREATURE_GIANT_SCORPION,
  CREATURE_MAGE,
} = require('./../../creatureIdList')
const {
  SOURCE_MM,
} = require('./../../sourceList')

const colorDragonDescriptionList = require('./dragonColor')
const blueDragonLocalEffectText = require('./blueDragonLocalEffectText')

module.exports = [
  {
    header: 'Логово синего дракона',
    text: `Синие драконы обустраивают свои логова в пустынных местах. С помощью молниевого дыхания и способности копать они роют кристаллические тоннели и пещеры под песками.

Грозы бушуют рядом с логовами легендарных синих драконов, тонкие трубки из песчаного стекла обеспечивают вентиляцию, а спрятанные смертоносные ямы служат первой линией драконьей обороны.

Синий дракон может обрушить стены пещер, которые служат ему логовом, если посчитает, что логово захвачено. После этого дракон выкопается, а захватчики останутся задыхаться под завалом. Вернувшись позже, он соберёт не только свои сокровища, но и добро мёртвых захватчиков.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов; дракон не может использовать один и тот же эффект два раунда подряд:

* Часть потолка обваливается на одно существо, видимое драконом в пределах 120 футов. Существо должно пройти испытание Ловкости СЛ 15, иначе получит дробящий урон 10 (3к6) и будет сбито с ног и завалено. Заваленная цель обездвижена, не может дышать и вставать. Существо может действием совершить проверку Силы СЛ 10, переставая быть заваленным при успехе. 
* Туча песка поднимается, образуя сферу 20-футового радиуса с центром в точке, которую дракон видит в пределах 120 футов. Туча распространяется за углы. Все существа в туче должны пройти испытание Телосложения СЛ 15, иначе станут ослеплёнными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. 
* Между двумя твёрдыми поверхностями логова, которые дракон может видеть, образуется электрическая дуга линией с шириной 5 футов. Обе поверхности должны быть в пределах 120 футов друг от друга и от дракона. Все существа в этой линии должны пройти испытание Ловкости СЛ 15, иначе получат урон электричеством 10 (3к6).

# Местные эффекты

${blueDragonLocalEffectText}`,
    source: {
      id: SOURCE_MM,
      page: 116,
    },
  },
  {
    header: 'Синие драконы',
    text: `Тщеславные и территориальные, синие драконы парят в небесах над пустынями, охотясь на караваны, разоряя стада и поселения в плодородных землях за границами пустыни. Эти драконы также живут в сухих степях, раскалённых пустошах и на каменистых побережьях, и ревностно охраняют свои границы от возможных соперников, особенно от [латунных драконов](CREATURE:${CREATURE_DRAGON_BRASS_ANCIENT}).

Синего дракона можно узнать по крупным перепончатым ушам и массивному рогу, возвышающемуся на его сплюснутой голове. Шипы рядами поднимаются от его ноздрей к бровям и густо растут на его выдающейся нижней челюсти.

Чешуя синих драконов варьируется от светло-голубого до тёмно-синего, и обычно отполирована до блеска песками пустынь. По мере старения дракона, его чешуя становится толще и крепче, и его шкура гудит и потрескивает от статического электричества. Эти эффекты усиливаются, когда дракон становится злее или вот-вот набросится, создавая запах озона в пыльном воздухе.

# Тщеславные и смертоносные

Синий дракон не потерпит каких-либо замечаний или намёков на его слабость или унижающих его, получая огромное удовольствие от господства над низшими и человекоподобными созданиями.

Синий дракон — терпеливый и методичный боец. Сражаясь на своих условиях, он превращает бой в длительную дуэль, которая может затянуться на часы или даже дни, атакуя с расстояния залпами молний и улетая на значительное расстояние, лишь чтобы дождаться выгодного момента для новой атаки.

# Пустынные хищники

Синие драконы плотоядны, и хотя они могут есть кактусы и другие пустынные растения, чтобы утолить свой голод, они предпочитают лакомиться стадными животными, поджаривая их с помощью своего молниевого дыхания, прежде чем проглотить их. Гастрономические предпочтения и привычки синих драконов угрожают пустынным караванам и кочевым племенам, которых драконы рассматривают как удобные наборы еды и сокровищ.

Охотясь, синие драконы закапываются в песок так, что на поверхности остаётся только рог на его голове, выглядящий торчащим из песка камнем. Когда добыча подходит достаточно близко, дракон вырывается из своего укрытия в туче песка, который лавиной разлетается от его крыльев.

# Владыки и приспешники

Синие драконы стараются привлечь ценных и талантливых существ, чья служба укрепит их чувство превосходства. Барды, мудрецы, художники, [волшебники](CREATURE:${CREATURE_MAGE}) и [убийцы](CREATURE:${CREATURE_ASSASSIN}) становятся ценными агентами синих драконов, которые щедро вознаграждают за верную службу.

Синий дракон держит местонахождение своего логова в тайне, и хорошо охраняет его. Даже самые верные его слуги редко допускаются туда. Он позволяет [анхегам](CREATURE:${CREATURE_ANKHEG}), [гигантским скорпионам](CREATURE:${CREATURE_GIANT_SCORPION}) и другим пустынным существам обитать рядом со своим логовом для большей безопасности. Старшие синие драконы иногда привлекают к себе на службу [воздушных элементалей](CREATURE:${CREATURE_AIR_ELEMENTAL}) и других существ.

# Коллекционеры самоцветов

Синие драконы особенно ценят самоцветы, хотя и собирают всё, что кажется им ценным. Считая синий цвет наиболее благородным и прекрасным, они выше других камней ценят сапфиры и предпочитают ювелирные украшения и магические предметы, украшенные именно этими камнями. 

Синие драконы закапывают наиболее ценные из своих сокровищ глубоко в песок, а менее ценные украшения разбрасывают на видных местах, которые на самом деле являются скрытыми ямами, чтобы покарать и уничтожить возможных воров.`,
    source: {
      id: SOURCE_MM,
      page: 116,
    },
  },
  ...colorDragonDescriptionList,
]
