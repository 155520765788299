const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_SLAAD,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26,
  CREATURE_SLAADI_SPIRIT,
  CREATURE_SLAADI_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Дух слаада',
  nameEn: 'Slaadi Spirit',
  id: CREATURE_SLAADI_SPIRIT_2020_03_26,
  releasedAs: CREATURE_SLAADI_SPIRIT,
  parentId: CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_SLAAD,
  ],
  speed: {
    [SPEED_WALK]: 30,
  },
  featureList: [
    {
      id: ABILITY_REGENERATION,
      regen: 20,
      other: `, если у ★него★ есть хотя бы 1 хит`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Когти',
      description: `Если цель — существо, оно не может восстанавливать хиты до начала следующего хода духа слаада.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
}
