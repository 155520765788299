const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_RING_OF_EVASION,
  MAGIC_ITEM_RING_OF_FEATHER_FALLING,
  MAGIC_ITEM_RING_OF_FREE_ACTION,
  MAGIC_ITEM_RING_OF_INVISIBILITY,
  MAGIC_ITEM_RING_OF_MIND_SHIELDING,
  MAGIC_ITEM_RING_OF_PROTECTION,
  MAGIC_ITEM_RING_OF_REGENERATION,
  MAGIC_ITEM_RING_OF_SPELL_STORING,
  MAGIC_ITEM_RING_OF_SPELL_TURNING,
  MAGIC_ITEM_RING_OF_SWIMMING,
  MAGIC_ITEM_RING_OF_THE_RAM,
  MAGIC_ITEM_RING_OF_WARMTH,
  MAGIC_ITEM_RING_OF_WATER_WALKING,
  MAGIC_ITEM_RING_OF_X_RAY_VISION,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_RING_OF_SPELL_STORING,
    name: `Кольцо хранения заклинаний`,
    nameEn: `Ring of Spell Storing`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Это кольцо хранит заклинания, наложенные на него, позволяя использовать их владельцу, настроенному на кольцо. Кольцо может хранить до 5 уровней заклинаний одновременно. Когда кольцо находят, оно хранит 1к6 − 1 уровень заклинаний, которые выбирает Мастер.

Любое существо может наложить заклинание с уровнем от 1 до 5 на кольцо, касаясь его в процессе колдовства. Заклинание не оказывает эффекта сейчас, а просто сохраняется в кольце. Если кольцо не может удержать заклинание, заклинание тратится безо всякого эффекта. Занимаемое пространство определяется уровнем ячейки, использованной для накладывания заклинания.

Нося это кольцо, Вы можете сотворять любые хранящиеся в нём заклинания. Заклинание использует круг ячейки, СЛ испытания, бонус атаки и базовую характеристику исходного заклинателя, но во всём остальном считается, что заклинание наложили Вы. Наложенное кольцом заклинание больше не хранится в нём и освобождает пространство.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 178,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_WATER_WALKING,
    name: `Кольцо хождения по воде`,
    nameEn: `Ring of Water Walking`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Нося это кольцо, Вы можете стоять на жидких поверхностях и ходить по ним, как если бы они были твёрдыми.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 178,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_EVASION,
    name: `Кольцо уклонения`,
    nameEn: `Ring of Evasion`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `У этого кольца 3 заряда, и оно ежедневно восстанавливает 1к3 заряда на рассвете.

Если Вы проваливаете испытание Ловкости, когда носите его, Вы можете реакцией потратить 1 заряд, чтобы сделать испытание успешным.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 178,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_WARMTH,
    name: `Кольцо тепла`,
    nameEn: `Ring of Warmth`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Нося это кольцо, Вы обладаете сопротивлением к урону холодом. Кроме того, Вы и всё, что Вы несёте и носите, не получают вреда от температур не ниже −50 °F (−45 °C).`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 178,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_THE_RAM,
    name: `Кольцо тарана`,
    nameEn: `Ring of the Ram`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `У этого кольца 3 заряда, и оно ежедневно восстанавливает 1к3 заряда на рассвете. 

Нося это кольцо, Вы можете действием потратить от 1 до 3 зарядов, чтобы атаковать одно существо, видимое в пределах 60 футов. Кольцо создаёт призрачную голову барана и совершает атаку с бонусом +7. При попадании за каждый использованный Вами заряд цель получает урон силовым полем 2к10 и толкается на 5 футов от Вас.

В качестве альтернативы, Вы может действием потратить от 1 до 3 зарядов, чтобы попытаться сломать предмет, видимый в пределах 60 футов, который никто не несёт и не носит. Кольцо совершает проверку Силы с бонусом +5 за каждый использованный заряд.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 177,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_FREE_ACTION,
    name: `Кольцо свободных действий`,
    nameEn: `Ring of Free Action`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Если Вы носите это кольцо, труднопроходимая местность не заставляет Вас тратить дополнительное перемещение. Кроме того, магия не может ни уменьшить Вашу скорость, ни сделать Вас парализованным или обездвиженным.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 177,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_REGENERATION,
    name: `Кольцо регенерации`,
    nameEn: `Ring of Regeneration`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Нося это кольцо, Вы восстанавливаете 1к6 хитов каждые 10 минут, при условии, что у Вас есть хотя бы 1 хит. Если Вы теряете часть тела, кольцо отращивает её и возвращает функциональность через 1к6 + 1 день, если всё это время у Вас есть хотя бы 1 хит.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 177,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_X_RAY_VISION,
    name: `Кольцо проникающего зрения`,
    nameEn: `Ring of X-ray Vision`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Нося это кольцо, Вы можете действием произнести его командное слово. Сделав это, Вы сможете видеть через твёрдую материю в течение 1 минуты. Радиус этого зрения — 30 футов. Для Вас твёрдые предметы в этом радиусе выглядят прозрачными и не препятствуют проходить сквозь них свету. Это зрение может проникать сквозь 1 фут камня, 1 дюйм обычного металла или 3 фута дерева или земли. Более толстые препятствия, а также тонкий лист свинца блокируют обзор.

Каждый раз, когда Вы используете это кольцо повторно до совершения длинного отдыха, Вы должны пройти испытание Телосложения СЛ 15, иначе получите 1 уровень истощения.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 177,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_SWIMMING,
    name: `Кольцо плавания`,
    nameEn: `Ring of Swimming`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Вы получаете скорость плавания 40 футов, пока носите это кольцо.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 176,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_FEATHER_FALLING,
    name: `Кольцо падения пёрышком`,
    nameEn: `Ring of Feather Falling`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Если Вы падаете, нося это кольцо, Вы опускаетесь на 60 футов в раунд и не получаете урон от падения. `,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 176,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_SPELL_TURNING,
    name: `Кольцо отражения заклинаний`,
    nameEn: `Ring of Spell Turning`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Нося это кольцо, Вы получаете преимущество к испытаниям от заклинаний, нацеленных только на Вас (не обладающих зонами воздействия).

Кроме того, если у Вас выпадет «20» при испытании от заклинания с уровнем не больше 7, заклинание не оказывает на Вас никакого эффекта, и вместо этого нацеливается на заклинателя, используя круг ячейки, СЛ испытания, бонус атаки и базовую характеристику самого заклинателя.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 176,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_INVISIBILITY,
    name: `Кольцо невидимости`,
    nameEn: `Ring of Invisibility`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Нося это кольцо, Вы можете действием становиться невидимым. Всё, что Вы несёте и носите, становится невидимым вместе с Вами.

Вы остаётесь невидимым, пока не снимете кольцо, пока не атакуете или не наложите заклинание, или пока не станете снова видимым бонусным действием.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 176,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_MIND_SHIELDING,
    name: `Кольцо защиты разума`,
    nameEn: `Ring of Mind Shielding`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Нося это кольцо, Вы обладаете иммунитетом к магии, позволяющей другим существам читать Ваши мысли, определять, лжёте ли Вы, определять Ваше мировоззрение и Ваш вид существа. Существа могут телепатически общаться с Вами только если Вы позволяете это.

Вы можете действием сделать кольцо невидимым, пока вновь не сделаете его видимым другим действием, пока не снимете его, или пока не умрёте.

Если Вы умрёте, нося это кольцо, Ваша душа входит в него, если только оно уже не занято душой. Вы можете остаться в кольце, а можете отправиться навстречу посмертию. Пока Ваша душа находится в кольце, Вы можете телепатически общаться с любым существом, которое носит его. Владелец не может отказаться от этого телепатического общения.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 173,
    },
  },
  {
    id: MAGIC_ITEM_RING_OF_PROTECTION,
    name: `Кольцо защиты`,
    nameEn: `Ring of Protection`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Вы получаете бонус +1 к КД и испытаниям, пока носите это кольцо.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 173,
    },
  },
]
