const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  GOD_MAGLUBIYET,
} = require('./../../../../godIdList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_4,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_GOBLIN,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_CHARM_PERSON,
  SPELL_DIMENSION_DOOR,
  SPELL_FIREBALL,
  SPELL_LEVITATE,
  SPELL_MINOR_ILLUSION,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_SUGGESTION,
} = require('./../../../../spellIdList')
const {
  CAST_MATERIAL,
} = require('./../../../../castComponentList')
const {
  CREATURE_BARGHEST,
  CREATURE_GOBLIN,
  CREATURE_GOBLIN_BOSS,
} = require('./../../../../creatureIdList')
const {
  ABILITY_KEEN_SMELL,
} = require('./../../../../creatureAbilityList')
const {
  maglubiyetBarghestDescription,
} = require('./../../../../textCommonParts')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')

module.exports = {
  name: 'Баргест',
  nameEn: 'Barghest',
  id: CREATURE_BARGHEST,
  description: [
    maglubiyetBarghestDescription,
    {
      header: 'Поглотители душ',
      text: `Баргест рождается у родителей [гоблинов](CREATURE:${CREATURE_GOBLIN}), как и любое обычное потомство. Существо появляется в форме гоблина, а потом развивает в себе способность принимать настоящую форму: превращаться в большого демонического пса.

Задачей каждого баргеста, заложенной в него Генералом Геенны, является поглощение семнадцати душ гоблиноидов путем съедения тел тех, кого он убил. Души, поглощенные таким образом не могут присоединиться к силам [Маглубиета](GOD:${GOD_MAGLUBIYET}) в Ахероне. Почему семнадцать? Потому что [Маглубиет](GOD:${GOD_MAGLUBIYET}) нарушил всего семнадцать клятв в контракте с Генералом.

Баргест желает дождаться того дня, когда он завершит миссию, вернется в Геенну, и будет служить Генералу напрямую в его легионах юголотов, но он не убивает гоблиноидов без разбора. Поглощая души [лидеров гоблинов](CREATURE:${CREATURE_GOBLIN_BOSS}) и сильных личностей, в отличие от простых гоблинов, баргест получит более высокий статус в посмертии. Баргесты обычно держат свою истинную натуру в тайне, охотясь на одного или двух гоблинов при возможности, пока не достигают взрослого возраста и не становятся достаточно сильными, чтобы искать добычу посерьезнее. Когда гоблины обнаруживают, что среди них завелся баргест, то начинают пресмыкаться и подчиняться, и каждый член племени стремится доказать баргесту, что он недостоин быть поглощённым.`,
      source: {
        id: SOURCE_VGTM,
        page: 125,
      },
    },
    {
      header: 'Изгоняющий огонь',
      text: `Баргест избегает контактов с большими открытыми огнями. Любой костер, который больше его тела, действует как портал в Геенну и изгоняет исчадие на этот план, где его скорее всего убьют или сделают рабом юголотов за провал.`,
      source: {
        id: SOURCE_VGTM,
        page: 125,
      },
    },
    {
      header: 'Пожирание душ',
      text: `Баргест может сожрать тело гуманоида, которого он убил, и который мёртв менее 10 минут, и тем самым поглощая его плоть и душу. Пиршество длится по крайней мере 1 минуту и оно уничтожает тело жертвы. Душа жертвы заперта в баргесте на 24 часа, после чего она переваривается. Если баргеста убить до того как душа переварится, то душа отпускается.

Когда душа гуманоида заперта в баргесте, любая форма воскрешения имеет шанс только 50 процентов на то, чтобы оживить цель, освобождая душу из баргеста при успехе. Однако, после того как душа существа была переварена, никакая смертная магия не может вернуть гуманоида к жизни.`,
      source: {
        id: SOURCE_VGTM,
        page: 125,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 125,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: {
      value: 60,
      comment: `30 фт. в облике гоблина`,
    },
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_INFERNAL,
    LANG_COMMON,
    LANG_GOBLIN,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в Маленького гоблина или обратно в свою истинную форму. За исключением размера и скорости, ★его★ характеристики одинаковы в обеих формах. Любое его снаряжение не трансформируется вместе с ним. В случае смерти ★СУЩЕСТВО★ принимает свою истинную форму.`,
    },
    {
      name: 'Изгоняемый огнём',
      description: `Когда ★СУЩЕСТВО★ начинает свой ход, будучи ★объятым★ пламенем, высота или ширина которого составляет хотя бы 20 футов, ★он★ ★должен★ пройти испытание Харизмы СЛ 15, и при провале будет немедленно ★изгнан★ в Геенну. Вспышки огня с мгновенной длительностью (такие как дыхание дракона или заклинание [огненный шар](${SPELL_FIREBALL})) не провоцируют возникновение этого эффекта на ★нём★.`,
    },
    ABILITY_KEEN_SMELL,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_LEVITATE,
          SPELL_PASS_WITHOUT_TRACE,
          SPELL_MINOR_ILLUSION,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CHARM_PERSON,
          SPELL_DIMENSION_DOOR,
          SPELL_SUGGESTION,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
  ],
}
