const {
  SPELL_CONJURE_ELEMENTAL,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_GASEOUS_FORM,
  SPELL_INVISIBILITY,
  SPELL_MOVE_EARTH,
  SPELL_PASSWALL,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PLANE_SHIFT,
  SPELL_STONE_SHAPE,
  SPELL_TONGUES,
  SPELL_WALL_OF_STONE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_DAO,
  CREATURE_DJINNI,
  CREATURE_EARTH_ELEMENTAL,
  CREATURE_EFREETI,
  CREATURE_MARID,
} = require('./../../../../creatureIdList')
const {
  SPEED_DIG,
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_SURE_FOOTED} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_PETRIFIED} = require('./../../../../conditionList')
const {CR_11} = require('./../../../../crList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GEAR_MAUL} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {geniusDescriptionList} = require('./../../../../textCommonParts')
const {LANG_TERRAN} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Дао',
  nameEn: 'Dao',
  id: CREATURE_DAO,
  description: [
    {
      header: 'Дао',
      text: `Дао — жадные и злобные гении со Стихийного Плана Земли. Они носят ювелирные украшения из драгоценных камней и редких металлов. Во время полёта нижняя часть их тел становится столбом вращающегося песка. Дао не испытывает счастья, если ему не завидуют другие дао.`,
      source: {
        id: SOURCE_MM,
        page: 50,
      },
    },
    {
      header: 'Всё, что блестит',
      text: `Дао обитают на Стихийном Плане Земли в комплексах переплетающихся туннелей и пещер, пронизанных мерцающими рудами. Эти лабиринты постоянно расширяются, поскольку дао роют и перестраивают своё пространство. Дао нет дела до бедности и несчастий всех остальных. Подобно тому, как смертные используют пряности, дао могут посыпать свою пищу перемолотыми в пыль золотом и драгоценными камнями.`,
      source: {
        id: SOURCE_MM,
        page: 50,
      },
    },
    {
      header: 'Властители земли',
      text: `Дао никогда не помогает смертным, если только не может получить от них что-то взамен, желательно сокровище. Среди гениев дао общаются и торгуют с [ифритами](CREATURE:${CREATURE_EFREETI}), но презирают [джиннов](CREATURE:${CREATURE_DJINNI}) и [маридов](CREATURE:${CREATURE_MARID}). Другие расы, населяющие Стихийный План Земли, сторонятся дао, которые постоянно ищут новых рабов для разработки лабиринтов их парящих каменных островов.`,
      source: {
        id: SOURCE_MM,
        page: 50,
      },
    },
    {
      header: 'Гордые рабовладельцы',
      text: `Дао достают лучших рабов, которых только можно купить, и заставляют их работать в опасных подземных мирах, дрожащих от землетрясений.

Сколь сильно дао любят порабощать других, столь же сильно они ненавидят, когда порабощают их самих. Известно, что могущественные [волшебники](PC_CLASS:${PC_CLASS_WIZARD}) заманивают дао на Материальный план и заключают их в драгоценные камни или железные фляги. К несчастью для дао, их жадность позволяет магам довольно легко обманывать их и заставлять себе служить.`,
      source: {
        id: SOURCE_MM,
        page: 50,
      },
    },
    ...geniusDescriptionList,
  ],
  note: {
    text: `Добро пожаловать в Великую Мрачную Рытвину, жемчужину Внутренних Планов. Отныне вы — рабы могущественной и милосердной Хатун Зафара аль-Йиль джин Зарейн, королевы Пенистых Глубин, верховной жрицы Алмазного Храма, Защитницы Малахитового Скипетра, и прочая, и прочая`,
    author: `обычное приветствие горгульи-сенешаля`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 50,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 24,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  immunityConditionList: [
    CONDITION_PETRIFIED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_TERRAN,
  ],
  cr: CR_11,
  featureList: [
    {
      name: 'Скольжение сквозь землю',
      description: `★СУЩЕСТВО★ может перемещаться, копая, сквозь немагические и необработанные землю и камень. При этом ★СУЩЕСТВО★ не беспокоит материал, через который перемещается.`,
    },
    {
      name: 'Стихийная гибель',
      description: `Если ★СУЩЕСТВО★ умирает, его тело распадается на кристаллическую пыль, оставляя только снаряжение, которое ★СУЩЕСТВО★ ★носил★ и ★нёс★.`,
    },
    ABILITY_SURE_FOOTED,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_STONE_SHAPE,
          SPELL_DETECT_EVIL_AND_GOOD,
          SPELL_DETECT_MAGIC,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_MOVE_EARTH,
          SPELL_PASSWALL,
          SPELL_TONGUES,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_PHANTASMAL_KILLER,
          SPELL_GASEOUS_FORM,
          SPELL_WALL_OF_STONE,
          SPELL_INVISIBILITY,
          {
            id: SPELL_CONJURE_ELEMENTAL,
            comment: `только [земляной элементаль](CREATURE:${CREATURE_EARTH_ELEMENTAL})`,
          },
          SPELL_PLANE_SHIFT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Кулаком_, либо две атаки _Молотом_.`,
    },
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Двуручный молот',
      // gearId: GEAR_MAUL, // TODO: Найти способ показывать оружие с изменёнными костями
      description: `Если цель — существо с размером не больше Огромного, она должна преуспеть в проверке Силы СЛ 18, иначе будет сбита с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
