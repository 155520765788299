const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_SPIDER_CLIMB} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')

module.exports = {
  id: SPELL_SPIDER_CLIMB,
  name: 'Паук',
  nameEn: `Spider Climb`,
  description: `Пока заклинание активно, одно согласное существо, которого Вы касаетесь, получает возможность перемещаться вверх, вниз и вдоль вертикальных поверхностей, а также по потолкам, оставляя руки свободными. Цель также получает скорость лазания, равную своей скорости ходьбы.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `капля битума и паук`,
  duration: { timeId: TIME_HOUR, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 256,
    },
    {
      id: SOURCE_SRD,
      page: 182,
    },
  ],
}
