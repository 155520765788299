const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  CREATURE_AIR_ELEMENTAL_SPIRIT,
  CREATURE_EARTH_ELEMENTAL_SPIRIT,
  CREATURE_FIRE_ELEMENTAL_SPIRIT,
  CREATURE_WATER_ELEMENTAL_SPIRIT,
} = require('./../../../../../creatureIdList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_SUMMON_ELEMENTAL,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SUMMON_ELEMENTAL,
  name: 'Призыв духа стихии',
  nameAlt: 'Призыв духа элементаля',
  nameEn: 'Summon Elemental',
  description: `Вы призываете духа стихии. Дух проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите стихию — [воздух](CREATURE:${CREATURE_AIR_ELEMENTAL_SPIRIT}), [воду](CREATURE:${CREATURE_WATER_ELEMENTAL_SPIRIT}), [землю](CREATURE:${CREATURE_EARTH_ELEMENTAL_SPIRIT}) или [огонь](CREATURE:${CREATURE_FIRE_ELEMENTAL_SPIRIT}). Существо выглядит как двуногая фигура, сплетённая из выбранной стихии, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.
    
Существо дружественно к Вам и Вашим спутникам. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно совершает действие Уклонение и передвигается так, чтобы избегать опасности.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
  createdCreatureId: [
    CREATURE_AIR_ELEMENTAL_SPIRIT,
    CREATURE_EARTH_ELEMENTAL_SPIRIT,
    CREATURE_FIRE_ELEMENTAL_SPIRIT,
    CREATURE_WATER_ELEMENTAL_SPIRIT,
  ],
  lvl: 4,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `воздух, камешек, зола и вода внутри инкрустированного золотом флакона стоимостью не менее 400 золотых`,
  hasCost: true,
  duration: { timeId: TIME_HOUR, count:  1},
  needConcentration: true,
  source: {
    id: SOURCE_TCoE,
    page: 160,
  },
}
