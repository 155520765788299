const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_LICH,
  CREATURE_NOTHIC,
} = require('./../../../../creatureIdList')
const {ABILITY_KEEN_SIGHT} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_UNDERCOMMON} = require('./../../../../languageIdList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Нотик',
  nameEn: 'Nothic',
  id: CREATURE_NOTHIC,
  description: [
    {
      header: 'Нотики',
      text: `Зловещий глаз, смотрящий из тьмы, сверкает извращённым интеллектом и пугающей злобой. Большую часть времени нотики довольствуются подсматриванием, взвешиванием шансов и оценкой существ, с которыми они сталкиваются. Если же дело доходит до схватки, они пускают в ход свой ужасающий взгляд, от которого плоть их врагов сгнивает до костей.`,
      source: {
        id: SOURCE_MM,
        page: 221,
      },
    },
    {
      header: 'Проклятые маги',
      text: `Некоторые волшебники, посвятившие свои жизни открытиям магических секретов, вместо обретения искомого ими богоподобного превосходства, превращаются в жалких, измученных тёмным проклятьем чудовищ. Это проклятье оставил Векна, могучий [лич](CREATURE:${CREATURE_LICH}), который в некоторых мирах из нежити превратился в бога тайн. Нотики не сохраняют после превращения никакой памяти о том, кем они были при жизни, но рыщут в местах скопления магических знаний, куда их тянут смутные воспоминания и порывы, которые они не в состоянии понять до конца.`,
      source: {
        id: SOURCE_MM,
        page: 221,
      },
    },
    {
      header: 'Тёмные оракулы',
      text: `Нотики обладают странным даром волшебного прозрения, который позволяет им извлекать знания из памяти других существ. Это даёт им выходящие за всякие рамки знания секретов и тайн, которыми они с удовольствием поделятся за определённую цену. Обычно утолить жадность нотика можно подарком, вроде магического предмета.`,
      source: {
        id: SOURCE_MM,
        page: 221,
      },
    },
    {
      header: 'Обитатели магических мест',
      text: `Часто нотики проникают в академии волшебства и другие места, богатые магическими знаниями. Их ведёт призрачная надежда на то, что в таких местах найдётся метод вернуть им их прежнее тело. Это не осознанная цель, а скорее маниакальная тяга, витающая где-то на окраинах их сознания. Впрочем, некоторые нотики сохранили достаточно ума, чтобы осознать, что это всего лишь часть странного воздаяния за их безрассудство — ложная надежда, которая толкает их далее искать тайные знания.`,
      source: {
        id: SOURCE_MM,
        page: 221,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 221,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_KEEN_SIGHT,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Когтем_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Разлагающий взгляд',
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 30 футов от себя. Цель должна пройти испытание Телосложения СЛ 12 от этой магии, иначе получит урон некротической энергией 10 (3к6).`,
    },
    {
      name: 'Мистическая проницательность',
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 30 футов от себя. Цель должна противопоставить свою проверку Харизмы (Обман) проверке Мудрости (Проницательность) нотика. Если ★СУЩЕСТВО★ победит, ★он★ узнаёт один секрет или факт о цели. Цель автоматически побеждает, если обладает иммунитетом к очарованию.`,
    },
  ],
  genderId: GENDER_MALE,
}
