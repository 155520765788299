const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_FLIND,
  CREATURE_GNOLL,
  CREATURE_GNOLL_FANG_OF_YEENOGHU,
  CREATURE_SHOOSUVA,
} = require('./../../../../creatureIdList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  LANG_ABYSSAL,
  LANG_GNOLL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_RAMPAGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_8} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Шусува',
  nameEn: 'Shoosuva',
  id: CREATURE_SHOOSUVA,
  description: [
    `Шусува — это демон-гиена, которую дарит Йеногу особенно могущественному гноллу (обычно [клыку Йеногу](CREATURE:${CREATURE_GNOLL_FANG_OF_YEENOGHU})). Шусува появляется вскоре после того, как боевая банда одержит большую победу, выходя из клубящегося зловонного облака дыма, прибывая из Бездны. В битве демон хватает огромными челюстями одну жертву, тем временем втыкая ядовитое жало на своем хвосте в другую. Обездвиженное ядом существо становится лёгкой жертвой для любого [гнолла](CREATURE:${CREATURE_GNOLL}) поблизости.

Каждая шусува привязывается к конкретному [гноллу](CREATURE:${CREATURE_GNOLL}) и сражается бок о бок со своим хозяином. Гнолл, одаренный шусувой, по статусу в боевой банде уступает только [флинду](CREATURE:${CREATURE_FLIND}).`,
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 149,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 17,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 9,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_GNOLL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_8,
  featureList: [
    ABILITY_RAMPAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Шусува совершает две рукопашные атаки: одну _Укусом_ и одну _Хвостовым жалом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Хвостовое жало',
      description: `Цель должна пройти испытание Телосложения СЛ 14 или стать отравленной. Если цель отравлена, то она ещё и парализована. Цель может повторять испытание в конце каждого своего хода, оканчивая эффект при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 15,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
