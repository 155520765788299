const arrify = require('arrify')
const listToCollectionById = require('./../../utils/listToCollectionById')
const spellRawList = require('./../spellList/spellRawList')

const featureRawList = require('./featureRawList')

const featureList = featureRawList
  .map(
    feature => ({
      ...feature,
      isOptional: feature.isOptional || false,
      showSubClassList: feature.showSubClassList || false,
      pcClassId: feature.pcClassId || null,
      pcSubClassId: feature.pcSubClassId || null,
      spellIdList: arrify(feature.spellIdList || []),
    })
  )
  .reduce(
    (list, feature) => [
      ...list,
      ...feature.spellCircleLvl
        ? Object.keys(feature.spellCircleLvl).map(
          (spellLvl, i) => ({
            ...feature,
            id: i
              ? `${feature.id}_spellLvl_${spellLvl}`
              : feature.id,
            lvl: Math.max(feature.lvl || 0, feature.spellCircleLvl[spellLvl]),
            isSpellCircleBound: Boolean(i), // To hide it from feature lists on UI
            isHidden: Boolean(i), // To hide it from feature lists on UI
            spellIdList: feature.spellIdList.filter(
              spellId => spellRawList.find(({ id }) => id === spellId).lvl === i,
            ),
            spellIdListOriginal: i
              ? null
              : feature.spellIdList,
          }),
        )
        : 'spellIdPickByLvl' in feature
          ? Object.keys(feature.spellIdPickByLvl).map(
            (lvl) => ({
              ...feature,
              lvl,
              id: `${feature.id}_lvl_${lvl}`,
              spellIdList: arrify(feature.spellIdPickByLvl[lvl]),
            })
          )
          : [feature],
    ],
    [],
  )
  .sort(
    ({lvl: A}, {lvl: B}) => (
      Array.isArray(A) ? A[0] : A
    ) > (
      Array.isArray(B) ? B[0] : B
    )
      ? 1
      : -1
  )

const featureIdListBySpellId = featureList.reduce(
  (collection, feature) => feature.spellIdList.reduce(
    (obj, spellId) => ({
      ...obj,
      [spellId]: obj[spellId]
        ? [
          ...obj[spellId],
          feature.id,
        ]
        : [feature.id],
    }),
    collection
  ),
  {}
)

const featureIdListByPcSubClassId = featureList.reduce(
  (collection, feature) =>  ({
    ...collection,
    [feature.pcSubClassId]: collection[feature.pcSubClassId]
      ? [
        ...collection[feature.pcSubClassId],
        feature.id,
      ]
      : [feature.id],
  }),
  {}
)

const featureIdListByPcClassId = featureList.reduce(
  (collection, feature) => ({
    ...collection,
    [feature.pcClassId]: collection[feature.pcClassId]
      ? [
        ...collection[feature.pcClassId],
        feature.id,
      ]
      : [feature.id],
  }),
  {}
)

module.exports = featureList

module.exports.featureCollection = listToCollectionById(featureList)
module.exports.featureIdListBySpellId = featureIdListBySpellId
module.exports.featureIdListByPcSubClassId = featureIdListByPcSubClassId
module.exports.featureIdListByPcClassId = featureIdListByPcClassId
