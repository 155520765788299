const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {ABILITY_PACK_TACTICS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_FRIGHTENED} = require('./../../../../conditionList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_HOWLER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_FIEND} = require('./../../../../creatureTypeIdList')
const {LANG_ABYSSAL} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ревун',
  nameEn: 'Howler',
  id: CREATURE_HOWLER,
  description: [
    `Отдалённый вой предвещает появление ревуна. Услышав его вдалеке разум съёживается и наполняется ужасом от осознания того, что шум приближается. Когда ревуны выходят на охоту, недостаточно иметь мужество, чтобы противостоять им, ведь даже сам рассудок находится в большой опасности.`,
    {
      header: 'Бродяги из Пандемония',
      text: `Эти кошмарные создания, родом из Пандемония, могут также быть найдены в Нижних Планах, поскольку многие исчадия ловят их и дрессируют боевыми гончими. Ревунов можно, некоторым образом, приручить, но они реагируют только на грубые тренировки, где их заставляют признать дрессировщика как неоспоримого лидера стаи. Надрессированная стая затем без колебаний следует приказам своего лидера. Стая ревунов также служит злым смертным, у которых есть достаточно сил и свирепости, чтобы приказывать им.`,
      source: {
        id: SOURCE_MTOF,
        page: 225,
      },
    },
    {
      header: 'Безжалостные хищники',
      text: `Ревуны полагаются на свою скорость, количество и свой притупляющий разум вой, чтобы загнать в угол свою жертву перед тем, как разорвать её на части. Вой притупляет разум жертв, из-за чего им становится сложнее воспринимать окружающую обстановку. Они не способны ни на что иное, кроме как на испуганные метания в надежде найти укромное место. Любая задача, требующее больше усилий, чем это — за гранью их возможностей. Благодаря этому, исчадия особенно поощряют ревунов, поскольку в самые ключевые моменты сражения, их вой может нейтрализовать у врагов их способность сотворять заклинания или прочие способности.`,
      source: {
        id: SOURCE_MTOF,
        page: 225,
      },
    },
  ],
  note: {
    text: `Почему же ревуны воют? Из-за этого их добыча может убежать, а ведь их вой заставляет добычу убегать, и конечно же, скрытность помогла бы им лучше охотиться в завывающем Пандемоние. Существует только одно объяснение этому — это существо чует страх.`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 225,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 20,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ABYSSAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_8,
  featureList: [
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Ревун совершает две атаки _Раздирающим укусом_.`,
    },
    {
      name: 'Раздирающий укус',
      description: `Эта атака игнорирует сопротивление к урону.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 4,
            diceType: 10,
            diceBonus: 0,
            comment: `, если цель испугана`,
          },
        ],
      },
    },
    {
      name: 'Невыносимый вой',
      restore: 6,
      description: `Ревун начинает неустанно выть в 60-футовом конусе. Любое существо, оказавшееся в этой области и способное слышать вой, должно пройти испытание Мудрости СЛ 16, иначе станет испуганным до конца следующего хода ревуна. Пока цель испуганна подобным образом, её скорость снижается вдвое и она становится недееспособной. Цель, преуспевшая в испытании, становится невосприимчивой к _Невыносимому вою_ всех ревунов на следующие 24 часа.`,
    },
  ],
}
