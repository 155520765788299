const {CREATURE_TYPE_SIMIC_HYBRID} = require('./../../creatureTypeIdList')
const {SOURCE_GGTR} = require ('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_SIMIC_HYBRID,
  nameEn: 'Simic Hybrid',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'гибрид Симиков',
      genitive: 'гибрида Симиков',
      dative: 'гибриду Симиков',
      accusative: 'гибрида Симиков',
      instrumental: 'гибридом Симиков',
      prepositional: 'гибриде Симиков',
    },
    plural: {
      nominative: 'гибриды Симиков',
      genitive: 'гибридов Симиков',
      dative: 'гибридам Симиков',
      accusative: 'гибридов Симиков',
      instrumental: 'гибридами Симиков',
      prepositional: 'гибридах Симиков',
    },
  },
  description: [
    {
      header: 'Гибриды Симиков',
      text: `Объединение Симиков использует магию для слияния разных форм жизни вместе. В последние годы Объединение расширило эти исследования на гуманоидные образцы, магически передавая способности разных животных людям, эльфам и ведалкенам.

Цель проекта «Стражник» — создать Симикам армию солдат, превосходно адаптированных к различным боевым ситуациям. Эти гипер-развитые особи называются гибридами Симиков, хотя сами себя они иногда называют стражниками.`,
      source: {
        id: SOURCE_GGTR,
        page: 21,
      },
    },
    {
      header: 'Усиленная адаптация',
      text: `Биологические улучшения гибридов могут разительно менять их внешность, хотя большиснтво гибридов сохраняют свой основной физический облик. Все они улучшены способностями животных, обычно водных, рептилий или амфибий. Улучшения могут включать крабьи клешни, осьминожьи щупальца, крылья или скатоподобные плавники, прозрачную или маскировочного цвета кожу или акулью пасть, полную острых зубов.

Гибриды — продукт магии Симиков. Покинуть Объединение Симиков и вступить в другую гильдию для гибрида — не невозможно, но Симики будут считать такого индивида дезертиром. А новая гильдия может никогда полностью не доверять гибриду, который легко может оказаться шпионом Симиков.`,
      source: {
        id: SOURCE_GGTR,
        page: 21,
      },
    },
    {
      header: 'Имена гибридов Симиков',
      text: `Гибриды обычно используют имена, данные им их человеческими, эльфийскими или ведалкенскими родителями.

Некоторые гибриды принимают новое имя после своего превращение — выбранное ими самостоятельно или же тем, кто их превратил.`,
      source: {
        id: SOURCE_GGTR,
        page: 21,
      },
    },
  ],
}
