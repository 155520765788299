const {CAT_EQUIPMENT_KIT} = require('./../../../gearCategoryList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')

const {
  GEAR_BACKPACK,
  GEAR_CROWBAR,
  GEAR_DUNGEONEER_S_PACK,
  GEAR_HAMMER,
  GEAR_PITON,
  GEAR_RATIONS,
  GEAR_ROPE_HEMP,
  GEAR_TINDERBOX,
  GEAR_TORCH,
  GEAR_WATERSKIN,
} = require('./../../../gearIdList')

module.exports = {
  id: GEAR_DUNGEONEER_S_PACK,
  genderId: GENDER_MALE,
  name: 'Набор исследователя подземелий',
  nameByCase: {
    nominative: 'набор исследователя подземелий',
    genitive: 'набора исследователя подземелий',
    accusative: 'набор исследователя подземелий',
    instrumental: 'набором исследователя подземелий',
  },
  nameEn: 'Dungeoneer’s Pack',
  description: `Мы надеемся, что в ваших приключениях Вы встретите гораздо больше подземелий, чем драконов, а набор исследователя подземелий содержит основное снаряжение для погружения в темноту в поисках сокровищ, в том числе [рюкзак](GEAR:${GEAR_BACKPACK}), [трутницу](GEAR:${GEAR_TINDERBOX}), [верёвки](GEAR:${GEAR_ROPE_HEMP}), [шлямбуры](GEAR:${GEAR_PITON}), [печенье в жесткой коробке](GEAR:${GEAR_RATIONS}), [бурдюк](GEAR:${GEAR_WATERSKIN}), [ломик](GEAR:${GEAR_CROWBAR}) и [молоток](GEAR:${GEAR_HAMMER}).

Одним из самых важных предметов в вашем наборе подземелий является набор деревянных [факелов](GEAR:${GEAR_TORCH}). Подожгите один, и у вас будет свет, который поможет ориентироваться в ловушках и искать сокровища. Конечно, факелы могут также отдать ваше местоположение, если рядом окажется кто-то любопытный.

Включает
    
* [рюкзак](GEAR:${GEAR_BACKPACK}),
* [ломик](GEAR:${GEAR_CROWBAR}),
* [молоток](GEAR:${GEAR_HAMMER}),
* 10 [шлямбуров](GEAR:${GEAR_PITON}),
* 10 [факелов](GEAR:${GEAR_TORCH}),
* [трутницу](GEAR:${GEAR_TINDERBOX}),
* [рационы](GEAR:${GEAR_RATIONS}) на 10 дней
* [бурдюк](GEAR:${GEAR_WATERSKIN}).

В набор также входит 50-футовая [пеньковая верёвка](GEAR:${GEAR_ROPE_HEMP}), закреплённая сбоку.`,
  cost: 1200,
  weight: 43,
  source: {
    id: SOURCE_PHB,
    page: 151,
  },
  category: CAT_EQUIPMENT_KIT,
}
