const {SOURCE_XGTE} = require('./../../../sourceList')
const {PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS} = require('./../../../pcSubClassIdList')
const {
  FEATURE_BARDIC_INSPIRATION,
  FEATURE_MANTLE_OF_WHISPERS,
  FEATURE_PSYCHIC_BLADES,
  FEATURE_SHADOW_LORE,
  FEATURE_WORDS_OF_TERROR,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_PSYCHIC_BLADES,
    name: `Психические клинки`,
    nameEn: `Psychic Blades`,
    lvl: [3, 5, 10, 15],
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS,
    text: `Вы получаете возможность сделать свою атаку оружием магически токсичной для разума существа.

Когда Вы попадаете по существу атакой оружием, Вы можете потратить одно [Вдохновение барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и нанести цели дополнительные 2к6 психического урона. Вы можете использовать эту способность один раз за раунд в свой ход.

Психический урон увеличивается, когда Вы получаете определенный уровень в этом классе, увеличиваясь до 3к6 на 5 уровне, 5к6 на 10 уровне и 8к6 на 15 уровне.`,
    source: {
      id: SOURCE_XGTE,
      page: 14,
    },
  },
  {
    id: FEATURE_WORDS_OF_TERROR,
    name: `Слова ужаса`,
    nameEn: `Words of Terror`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS,
    text: `Вы учитесь наполнять кажущиеся безобидными слова коварной магией, которая вызывает ужас.

Если Вы разговариваете с гуманоидом наедине, в течение по крайней мере 1 минуты, то можете попытаться посеять семя паранойи в его разуме. В конце беседы цель должна пройти испытание Мудрости против Вашей СЛ заклинания или будет напугана Вами или другим существом по Вашему выбору. Цель напугана на 1 час таким образом, пока не будет атакована или не получит урон, или пока она не заметит, что её союзники атакованы или получили урон.

Если цель проходит испытание, то она не осознаёт, что Вы пытались её испугать.

Затем Вы должны окончить короткий или длинный отдых, чтобы использовать это умение вновь.`,
    source: {
      id: SOURCE_XGTE,
      page: 14,
    },
  },
  {
    id: FEATURE_MANTLE_OF_WHISPERS,
    name: `Мантия шёпотов`,
    nameEn: `Mantle of Whispers`,
    lvl: 6,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS,
    text: `Вы получаете возможность принимать личность гуманоида. Когда гуманоид умирает в пределах 30 футов от Вас, Вы реакцией можете магическим образом поймать его тень. Вы удерживаете эту тень, пока не завершите короткий или длинный отдых.

Действием Вы можете использовать тень. Когда Вы это делаете, она исчезает, магически превращаясь в маскировку, которая появляется на Вас. Теперь Вы выглядите как этот мертвый гуманоид, но кажетесь живым и здоровым. Маскировка длится 1 час или пока Вы не закончите её бонусным действием.

Находясь под этой маскировкой Вы получаете доступ ко всей информации, которой это существо могло бы свободно поделиться со случайным знакомым. Эта информация включает в себя общие данные о его биографии и личной жизни, но не его секреты. Этой информации достаточно для того, чтобы Вы смогли выдать себя за эту личность, используя его воспоминания.

Другие существа могут обнаружить Вашу истинную сущность, совершив проверку Мудрости (Проницательность) против Вашей проверки Харизмы (Обман), Вы получаете +5 бонус к своей проверке.

Когда Вы ловите тень этим умением, Вы не можете поймать другую тень, пока не завершите короткий или длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 14,
    },
  },
  {
    id: FEATURE_SHADOW_LORE,
    name: `Знание тени`,
    nameEn: `Shadow Lore`,
    lvl: 14,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_WHISPERS,
    text: `Вы получаете способность вплетать в свою речь тёмную магию и дергать за ниточки самых глубоких страхов существа.

Действием, Вы магическим образом нашёптываете фразу, которую слышит только одно существо по Вашему выбору в пределах 30 футов от Вас. Цель должна пройти испытание Мудрости против Вашей СЛ заклинания. Цель автоматически преуспевает в испытании, если она не разговаривает с Вами хотя бы на одном общем языке, или не может слышать Вас. В случае успешной проверки, Ваш шёпот звучит как неразборчивое бормотание и не имеет никакого эффекта.

Если цель проваливает испытание, то попадает под Ваше очарование на следующие 8 часов или пока Вы или Ваши союзники не атакуют её, не причинят ей вред или не заставят пройти испытание. Цель интерпретирует шёпот как описание самого страшного её секрета. Хотя Вы не узнаете этой тайны, цель убеждена в том, что Вы знаете её.

Очарованное таким образом существо подчиняется Вашим командам из страха, что Вы раскроете его тайну. Оно не будет рисковать своей жизнью ради Вас или сражаться за Вас, если уже не было склонно делать это. Оно оказывает Вам услуги и одаривает Вас, как если бы Вы были его близким другом.

Когда эффект заканчивается, существо не представляет, почему настолько Вас боялось.

Затем Вы должны окончить длинный отдых, чтобы использовать это умение вновь.`,
    source: {
      id: SOURCE_XGTE,
      page: 14,
    },
  },
]
