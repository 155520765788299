const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_TATTOO} = require('./../../../../../magicItemTypeList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {
  MAGIC_ITEM_ABSORBING_TATTOO,
  MAGIC_ITEM_ABSORBING_TATTOO_BLACK,
  MAGIC_ITEM_ABSORBING_TATTOO_BLUE,
  MAGIC_ITEM_ABSORBING_TATTOO_GOLD,
  MAGIC_ITEM_ABSORBING_TATTOO_GREEN,
  MAGIC_ITEM_ABSORBING_TATTOO_ORANGE,
  MAGIC_ITEM_ABSORBING_TATTOO_RED,
  MAGIC_ITEM_ABSORBING_TATTOO_SILVER,
  MAGIC_ITEM_ABSORBING_TATTOO_VIOLET,
  MAGIC_ITEM_ABSORBING_TATTOO_WHITE,
  MAGIC_ITEM_ABSORBING_TATTOO_YELLOW,
} = require('./../../../../../magicItemIdList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_ABSORBING_TATTOO,
  name: `Поглощающая татуировка`,
  nameEn: `Absorbing Tattoo`,
  type: MGC_TYPE_TATTOO,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `Эта магическая татуировка выглядит как изображение одного определённого цвета. Она наносится специальной иглой.

# Настройка на татуировку

Для настройки на _Поглощающую татуировку_, Вам нужно держать иглу у своей кожи в том месте, где Вы хотите нанести _Татуировку_, надавливая на иглу всё время настройки. Когда настройка завершится, игла превратится в чернила, которые станут _Татуировкой_ на коже.

Если Ваша настройка на _Татуировку_ завершится, она исчезнет, а игла снова появится в Вашем пространстве.

# Сопротивление урону

Пока _Татуировка_ на Вашей коже, Вы имеет сопротивление к типу урона, соответствующему её цвету, как показано в таблице ниже. Мастер выбирает её цвет или определяет его случайно.

|к10|Вид урона            | Цвет                                                        |к10|Вид урона          | Цвет                                                         |
|---|---------------------|-------------------------------------------------------------|---|-------------------|--------------------------------------------------------------|
|1  |Звук                 |[Оранжевый](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_ORANGE})|  6|Психическая энергия|[Серебряный](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_SILVER})|
|2  |Излучение            |[Золотой](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_GOLD})    |  7|Силовое поле       |[Белый](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_WHITE})      |
|3  |Кислота              |[Зелёный](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_GREEN})   |  8|Холод              |[Синий](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_BLUE})       |
|4  |Некротическая энергия|[Чёрный](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_BLACK})    |  9|Электричество      |[Жёлтый](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_YELLOW})    |
|5  |Огонь                |[Красный](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_RED})     | 10|Яд                 |[Фиолетовый](MAGIC_ITEM:${MAGIC_ITEM_ABSORBING_TATTOO_VIOLET})|

# Поглощение урона

Когда Вы получаете урон выбранного типа, Вы можете реакцией получить иммунитет к этой порции урона и восстанавливаете количество хитов, равное половине урона, который Вы должны были получить.

Использовав эту реакцию, Вы не сможете использовать её снова до следующего восхода.`,
  genderId: GENDER_FEMALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 171,
  },
}
