const upLetter = require('./../../../../../../utils/upLetter')

const {GENDER_MULTIPLE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')
const word = require('./word')

module.exports = (
  {
    creatureId,
    description: {
      alive,
      restore,
      image,
      properties = '',
    },
    genderId,
    id,
    name,
    nameEn,
    rarity,
    creatureName: {
      nominative,
      genitive,
      instrumental,
    },
  }
) => {
  const pronounFigureText = genderId === GENDER_MULTIPLE
    ? `Они небольшие и помещаются`
    : `Она небольшая и помещается`

  return {
    id,
    rarity,
    type: MGC_TYPE_WONDROUS_ITEM,
    name: `Статуэтка чудесной силы: ${name}`,
    nameEn: `Figurine of Wondrous Power: ${nameEn}`,
    description: `${image} ${pronounFigureText} в карман. Если Вы действием произнесёте командное слово и бросите статуэтку на расстояние до 60 футов, статуэтка станет ${word.alive[genderId]} [${instrumental}](CREATURE:${creatureId}).

Если пространство, в котором [${nominative}](CREATURE:${creatureId}) ${word.must[genderId]} появиться, занято другим существом или предметом, или если для [${genitive}](CREATURE:${creatureId}) просто нет пространства, статуэтка не становится [${instrumental}](CREATURE:${creatureId}).

[${upLetter(nominative)}](CREATURE:${creatureId}) ${word.friendly[genderId]} по отношению к Вам и Вашим спутникам. [${upLetter(nominative)}](CREATURE:${creatureId}) понимает Ваши языки и подчиняется устным командам. Если Вы не отдаёте команды, ${word.pronoun.nominative[genderId]} обороняется, но других действий не совершает.

[${upLetter(nominative)}](CREATURE:${creatureId}) существует **${alive}**. Статуэтку нельзя использовать повторно, пока не пройдёт **${restore}**. В конце этого периода ${word.pronoun.nominative[genderId]} вновь становится статуэткой. [${upLetter(nominative)}](CREATURE:${creatureId}) становится статуэткой преждевременно, если ${word.pronoun.genitive[genderId]} хиты опускаются до 0, или если Вы действием произнесёте командное слово, касаясь ${word.pronoun.genitive[genderId]}.

${properties}`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 203,
    },
  }
}
