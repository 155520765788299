const {CAT_SIMPLE_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_BLUDGEONING} = require('./../../../damageTypeList')
const {GEAR_QUARTERSTAFF} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {WEAPON_VERSATILE} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_QUARTERSTAFF,
  genderId: GENDER_MALE,
  name: 'Боевой посох',
  nameByCase: {
    nominative: 'боевой посох',
    genitive: 'боевого посоха',
    accusative: 'боевой посох',
    instrumental: 'боевым посохом',
  },
  nameEn: 'Quarterstaff',
  damage: {
    diceType: 6,
    diceCount: 1,
  },
  cost: 20,
  damageType: DAMAGE_BLUDGEONING,
  weight: 4,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    {
      id: WEAPON_VERSATILE,
      value: {
        diceCount: 1,
        diceType: 8,
      },
    },
  ],
  category: CAT_SIMPLE_MELEE_WEAPON,
}
