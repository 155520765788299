const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  DAMAGE_COLD,
  DAMAGE_FORCE,
  DAMAGE_PSYCHIC,
} = require('./../../../../../damageTypeList')
const {
  CONDITION_BLINDED,
  CONDITION_PRONE,
  CONDITION_STUNNED,
} = require('./../../../../../conditionList')
const {
  PARAM_DEX,
  PARAM_WIT,
} = require('./../../../../../paramList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_REALITY_BREAK} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_REALITY_BREAK,
  name: 'Разрыв реальности',
  nameEn: 'Reality Break',
  description: `Вы раскалываете барьер между реальностями и временами, повергая существо в безумие и помешательство. Цель должна пройти испытание Мудрости или не сможет совершать реакции на всё время действия заклинания. Кроме того, провалившая испытание, цель должна бросать к10 в начале каждого своего хода, определяя то, что с ней происходит по таблице «Эффекты разрыва реальности».

В конце каждого своего хода цель может повторять испытание, оканчивая эффект на себе при успехе.

# Эффекты разрыва реальности

| к10  | Эффект |
|------|--------|
| 1–2  | **Видение Дальнего Предела.** Цель получает 6к12 психического урона и ошеломляется до конца хода. |
| 3–5  | **Разрывающий разлом.** Цель должна пройти испытание Ловкости, получая 8к12 урона силовым полем при провале или половину при успехе. |
| 6–8  | **Червоточина.** Цель телепортируется вместе со всем, что несёт и носит, в видимое Вами в пределах 30 футов свободное пространство по Вашему выбору. Цель получает 10к12 урона силовым полем и падает навзничь. |
| 9–10 | **Стужа Тёмной Пустоты.** Цель получает 10к12 урона холодом и ослепляется до конца хода. |
`,
  lvl: 8,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `хрустальная призма`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  isDunamisBased: true,
  effect: {
    savethrowParam: [
      PARAM_WIT,
      PARAM_DEX,
    ],
    condition: [
      CONDITION_STUNNED,
      CONDITION_PRONE,
      CONDITION_BLINDED,
    ],
    damage: [
      {
        type: DAMAGE_PSYCHIC,
        diceCount: 6,
        diceType: 12,
      },
      {
        type: DAMAGE_FORCE,
        diceCount: 8,
        diceType: 12,
      },
      {
        type: DAMAGE_FORCE,
        diceCount: 10,
        diceType: 12,
      },
      {
        type: DAMAGE_COLD,
        diceCount: 10,
        diceType: 12,
      },
    ],
  },
  source: {
    id: SOURCE_EGTW,
    page: 190,
  },
}
