const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_2,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_FLYBY,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_SPINED_DEVIL,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Спинагон',
  nameAlt: 'Игольчатый дьявол',
  nameEn: 'Spinagon',
  nameEnAlt: 'Spined Devil',
  id: CREATURE_SPINED_DEVIL,
  description: `Спинагоны по размеру меньше большинства других дьяволов, и они служат шпионами и посредниками старших дьяволов и архидьяволов. Они — глаза и уши Девяти Преисподних, и даже те дьяволы, которые презирают физическую слабость спинагонов, относятся к ним, по крайней мере, с толикой уважения.

Тело и хвост спинагона усеяно иглами, а иглы с хвоста он может использовать как дистанционное оружие. При контакте с целью шипы воспламеняются. В свободное от доставки сообщений или сбора разведданных время игольчатые дьяволы служат в инфернальных легионах летучей артиллерией, компенсируя свою относительную слабость путём нападения толпой. Хоть игольчатые дьяволы и жаждут продвижения в ранге и власти, они по своей природе трусливы и быстро рассеются, если сражение пойдёт не в их пользу.`,
  note: {
    text: `Летите, мои хорошие, летите! Летите!`,
    author: `Фьёрна, эрцгерцогиня Флегетоса, командующая легионами игольчатых дьяволов`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 146,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 8,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_FLYBY,
    {
      name: 'Запас игл',
      description: `На хвосте ★СУЩЕСТВО★ двенадцать игл. Использованные иглы отрастают к моменту, когда ★СУЩЕСТВО★ оканчивает длинный отдых.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Вилами_, или же две атаки _Иглами на хвосте_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
        },
      },
    },
    {
      name: 'Вилы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
        },
      },
    },
    {
      name: 'Игла на хвосте',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 4,
        range: {
          normal: 20,
          max: 80,
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 2,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
