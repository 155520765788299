const {
  MAGIC_ENCHANTMENT,
} = require('./../../../../../magicList')
const {
  PARAM_INT,
} = require('./../../../../../paramList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  TIME_ROUND,
} = require('./../../../../../timePeriodList')
const {
  SPELL_TASHA_S_MIND_WHIP,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TASHA_S_MIND_WHIP,
  name: 'Ташина психическая плеть',
  nameAlt: [
    'Ташина плеть разума',
    'Плеть разума Таши',
    'Психическая плеть Таши',
  ],
  nameEn: 'Tasha’s Mind Whip',
  description: `Вы психически атакуете одно существо, видимое Вами в пределах дистанции.

Цель должна пройти испытание Интеллекта.

* **При провале** цель получает 3к6 психического урона и не может совершать реакции до начала своего следующего хода. Кроме того, на своём следующем ходу цель должна выбрать: передвигаться, совершать действие или бонусное действие. Она может совершить только что-то одно из этих трёх.
* **При успехе** цель получает половину этого урона и не страдает от других эффектов.`,
  highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку 3 круга или выше, Вы можете сделать целью дополнительно ещё одно существо за каждый круг ячейки выше 2. Существа должны находиться в пределах 30 футов друг от друга, когда Вы их выбираете.`,
  lvl: 2,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 90,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_ROUND, count: 1 },
  effect: {
    savethrowParam: PARAM_INT,
    damage: {
      type: DAMAGE_PSYCHIC,
      diceCount: 3,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_TCoE,
    page: 165,
  },
}
