const {
    CREATURE_FUMING_SPIRIT,
    CREATURE_MIRTHFUL_SPIRIT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух весёлой феи',
  nameEn: 'Mirthful Fey Spirit',
  id: CREATURE_MIRTHFUL_SPIRIT,
  parentId: CREATURE_FUMING_SPIRIT,
  featureList: [
    {
      name: 'Шаг весёлой феи',
      description: `★СУЩЕСТВО★ бонусным действием магически телепортируется в видимое ★им★ в пределах 30 футов свободное пространство. После этого ★он★ может заставить одно видимое ★им★ в пределах 10 футов существо пройти испытание Мудрости. При провале ★СУЩЕСТВО★ и ★его★ призыватель очаровывают цель на 1 минуту или пока она не получит урон.`,
    },
  ],
}
