const {SOURCE_VGTM} = require('./../../sourceList')
const {GOD_IMIX} = require('./../../godIdList')
const {
  CREATURE_FIRENEWT_WARRIOR,
  CREATURE_GIANT_STRIDER,
} = require('./../../creatureIdList')

module.exports = {
  header: 'Гигантские ходуны',
  text: `У [огненных тритонов](CREATURE:${CREATURE_FIRENEWT_WARRIOR}) близкие отношения с определенным типом чудовищного животного, который, как они верят, им ниспослал [Имикс](GOD:${GOD_IMIX}) в помощь, ввиду способности этого существа метать сгустки огня в дальних противников. Называемые [гигантскими ходунами](CREATURE:${CREATURE_GIANT_STRIDER}), эти существа напоминают и птиц, и рептилий, но не являются ни тем, ни другим. [Огненные тритоны](CREATURE:${CREATURE_FIRENEWT_WARRIOR}) дают корм, кров и место для размножения [гигантским ходунам](CREATURE:${CREATURE_GIANT_STRIDER}) в своих логовах, а те в ответ добровольно служат ездовыми животными [элитным воинам](CREATURE:${CREATURE_FIRENEWT_WARRIOR}).`,
  source: {
    id: SOURCE_VGTM,
    page: 177,
  },
}
