const {PC_SUBCLASS_DRUID_CIRCLE_OF_TWILIGHT_2016_11_28} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE} = require('./../../../sourceList')
const {
  SPELL_ETHEREALNESS,
  SPELL_SPEAK_WITH_DEAD,
} = require('./../../../spellIdList')
const {
  FEATURE_PATHS_OF_THE_DEAD,
  FEATURE_SPEECH_BEYOND_THE_GRAVE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_SPEECH_BEYOND_THE_GRAVE,
    name: `Голос из могилы`,
    nameEn: `Speech Beyond the Grave`,
    lvl: 6,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_TWILIGHT_2016_11_28,
    spellIdList: SPELL_SPEAK_WITH_DEAD,
    source: {
      id: SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE,
      page: 3,
    },
  },
  {
    id: FEATURE_PATHS_OF_THE_DEAD,
    name: `Пути мёртвых`,
    nameEn: `Paths of the Dead`,
    lvl: 14,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_TWILIGHT_2016_11_28,
    spellIdList: SPELL_ETHEREALNESS,
    source: {
      id: SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE,
      page: 3,
    },
  },
]
