const {
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  DAMAGE_COLD,
  DAMAGE_PIERCING,
} = require('./../../../../../damageTypeList')
const {ATTACK_RANGE_SPELL} = require('./../../../../../attackTypeList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_ICE_KNIFE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ICE_KNIFE,
  name: 'Ледяной кинжал',
  nameAlt: 'Ледяной нож',
  nameEn: 'Ice Knife',
  description: `Вы создаёте осколок льда и метаете его в одно существо, находящееся в пределах дистанции заклинания. Совершите по цели дальнобойную атаку заклинанием. При попадании цель получает колющий урон 1к10. После этого, вне зависимости от попадания или промаха, осколок взрывается. Цель и все существа в пределах 5 футов от места взрыва осколка должны пройти испытание Ловкости, иначе получат урон холодом 2к6.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 2 круга или выше, то урон холодом увеличивается на 1к6 за каждый круг ячейки выше 1.`,
  lvl: 1,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
  materialText: `капля воды или кусочек льда`,
  effect: [
    {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_PIERCING,
        diceCount: 1,
        diceType: 10,
      },
    },
    {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_COLD,
        diceCount: 2,
        diceType: 6,
      },
    },
  ],
  source: [
    {
      id: SOURCE_XGTE,
      page: 156,
    },
    {
      id: SOURCE_EE,
      page: 18,
    },
  ],
}
