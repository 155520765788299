const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_CELESTIAL} = require('./../../alphabetList')
const {LANG_MULHORANDI} = require('./../../languageIdList')
const {
  PLACE_MULHORAND,
  PLACE_MURGHOM,
  PLACE_SEMPHAR,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_MULHORANDI,
  alphabetId: ALPHABET_CELESTIAL,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Мулхорандский',
    genitive: 'Мулхорандского',
    instrumental: 'Мулхорандским',
    prepositional: 'Мулхорандском',
  },
  nameEn: 'Mulhorandi',
  spokenPlaceList: [
    PLACE_MULHORAND,
    PLACE_MURGHOM,
    PLACE_SEMPHAR,
  ],
  typicalSpeakers: 'Муланы',
  isReady: false,
  isRealLang: true,
}
