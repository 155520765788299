const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_MASTER_OF_SOULS} = require('./../../../../creatureIdList')
const {CR_4} = require('./../../../../crList')
const {GEAR_FLAIL} = require('./../../../../gearIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {myrkulFollowerList} = require('./../../../../textCommonParts')
const {
  SPELL_ANIMATE_DEAD,
  SPELL_BURNING_HANDS,
  SPELL_CHILL_TOUCH,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_FIREBALL,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_MISTY_STEP,
  SPELL_PRESTIDIGITATION,
  SPELL_RAY_OF_SICKNESS,
  SPELL_SCORCHING_RAY,
  SPELL_SHIELD,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NECROTIC,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_ABYSSAL,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Мастер душ',
  nameEn: 'Master of Souls',
  id: CREATURE_MASTER_OF_SOULS,
  description: myrkulFollowerList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_BGDIA,
    page: 238,
  },
  armor: 12,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 17,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL
  },
  languageList: [
    LANG_COMMON,
    LANG_ABYSSAL,
    LANG_INFERNAL,
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Сила могилы',
      description: `Когда ★СУЩЕСТВО★ творит заклинание, наносящее урон, он может изменить тип урона заклинания на некротический.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_ANIMATE_DEAD,
      SPELL_BURNING_HANDS,
      SPELL_CHILL_TOUCH,
      SPELL_DARKNESS,
      SPELL_DETECT_MAGIC,
      SPELL_FIREBALL,
      SPELL_MAGE_HAND,
      SPELL_MESSAGE,
      SPELL_MISTY_STEP,
      SPELL_PRESTIDIGITATION,
      SPELL_RAY_OF_SICKNESS,
      SPELL_SCORCHING_RAY,
      SPELL_SHIELD,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Цепом_.`,
    },
    {
      name: 'Цеп с посеребрённым черепом',
      gearId: GEAR_FLAIL,
      description: `Цель получает помеху ко всем испытаниям до конца следующего хода ★СУЩЕСТВО★.`,
      attack: {
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 4,
          diceType: 6,
        },
      },
    },
  ],
}
