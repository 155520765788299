const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  PARAM_DEX,
  PARAM_CON,
} = require('./../../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  CONDITION_DEAFENED,
} = require('./../../../../../conditionList')
const {
  SPELL_STORM_OF_VENGEANCE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_STORM_OF_VENGEANCE,
  name: 'Гроза гнева',
  nameEn: `Storm of Vengeance`,
  description: `Появляются клубящиеся облака с центром на точке, видимой Вами, распространяющиеся в радиусе 360 футов. В этой области бьют молнии, грохочет гром и дует сильный ветер. Все существа, находящиеся под облаками (на расстоянии не больше 5 000 футов под облаком), когда те появляются, должны пройти испытание Телосложения. При провале существо получает урон звуком 2к6 и становится оглохшим на 5 минут.

Каждый раунд, в котором Вы поддерживаете концентрацию на этом заклинании, гроза в Ваш ход создаёт другой эффект:

2. Раунд 2. Из облаков выпадает кислотный дождь. Все существа и предметы под облаками получают урон кислотой 1к6.
3. Раунд 3. Вы создаёте шесть разрядов молний из облаков, ударяющих в шесть существ или предметов на Ваш выбор под облаками. Существа и предметы не могут стать целью более одной молнии. Ударенное существо должно пройти испытание Ловкости. Существо получает урон электричеством 10к6 при провале, или половину этого урона при успехе.
4. Раунд 4. Из облаков выпадает град. Все существа под облаками получают дробящий урон 2к6.
5. Раунд 5–10. Под облаками бушует сильный ветер и идёт мокрый снег. Местность становится труднопроходимой и сильно заслонённой. Все находящиеся там существа получают урон холодом 1к6. Дальнобойные атаки оружием в этой местности невозможны. Ветер и дождь считаются сильным отвлекающим фактором при концентрации на заклинаниях. И наконец, порывы сильного ветра (скорость от 20 до 50 миль в час) автоматически рассеивают туман, испарения и подобные явления, как естественной, так и магической природы.`,
  lvl: 9,
  magicSchoolId: MAGIC_CONJURATION,
  range: 'Видимость',
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: [
    {
      savethrowParam: PARAM_CON,
      condition: CONDITION_DEAFENED,
      damage: {
        type: DAMAGE_THUNDER,
        diceCount: 2,
        diceType: 6,
      },
    },
    {
      damage: {
        type: DAMAGE_ACID,
        diceCount: 1,
        diceType: 6,
      },
    },
    {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_ELECTRICITY,
        diceCount: 2,
        diceType: 6,
      },
    },
    {
      damage: {
        type: DAMAGE_BLUDGEONING,
        diceCount: 1,
        diceType: 6,
      },
    },
    {
      damage: {
        type: DAMAGE_COLD,
        diceCount: 1,
        diceType: 6,
      },
    },
  ],
  source: [
    {
      id: SOURCE_PHB,
      page: 221,
    },
    {
      id: SOURCE_SRD,
      page: 183,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Эффекты теперь не накапливаются, а сменяют друг друга',
      page: 3,
    },
  ],
}
