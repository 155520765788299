const {CREATURE_DROW_PRIESTESS_OF_LOLTH} = require('./../../creatureIdList')
const {GOD_LOLTH} = require('./../../godIdList')
const {PC_RACE_ELF} = require('./../../pcRaceIdList')
const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Дети Ллос',
    text: `Дроу поклоняются [Ллос](GOD:${GOD_LOLTH}), богине из Бездны. Известная как Паучья Королева или Демоническая Королева Пауков, она — та фигура, вокруг которой тёмные эльфы построили свою подземную цивилизацию. Дроу выполняют всё, что бы она ни пожелала.

Безнравственных эльфов дроу редко видят на поверхности. Несмотря на это они больше не считают себя изгнанниками и помышляют уничтожить [эльфов](PC_RACE:${PC_RACE_ELF}), которые их изгнали. Они предназначены править миром тьмы, и когда [Ллос](GOD:${GOD_LOLTH}) повелит им восстать и уничтожить наземных родственников, они сделают это.`,
    source: {
      id: SOURCE_MM,
      page: 305,
    },
  },
  {
    header: 'Правила матриархата',
    text: `[Ллос](GOD:${GOD_LOLTH}), через своих верных [жриц](CREATURE:${CREATURE_DROW_PRIESTESS_OF_LOLTH}), диктует правила обществу дроу, передаёт приказы и осуществляет заговоры.

Поскольку [Ллос](GOD:${GOD_LOLTH}) часто появляется на Материальном плане и напрямую наказывает тех, кто не повинуются ей, дроу научились прислушиваться к её словам и действиям, как и к приказам [жриц](CREATURE:${CREATURE_DROW_PRIESTESS_OF_LOLTH}).

В обществе дроу мужчины прислуживают женщинам. Мужчина дроу может руководить патрулём в Подземье или вылазкой на поверхность, но он докладывает женщине дроу — либо матроне своего дома, либо одной из её подчинённых. Хотя мужчины дроу могут выполнять почти любую функцию в обществе дроу, они не могут быть жрецами, и не могут управлять домами.`,
    source: {
      id: SOURCE_MM,
      page: 305,
    },
  },
]
