const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_3,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ABILITY_SURPRISE_ATTACK,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_DOPPELGANGER,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Доппельгангер',
  nameEn: 'Doppelganger',
  id: CREATURE_DOPPELGANGER,
  description: `Доппельгангеры — хитрые перевёртыши, которые, принимая вид других гуманоидов, используют это для сбрасывания преследования и заманивая жертв в ловушки. Мало кто внушают страх, подозрения и использует ложь лучше, чем доппельгангеры. Распространившись по всем землям и цивилизациям, они могут принять вид любого существа любой расы.

# Кража секретов

Возможность принимать любой облик позволяет доппельгангеру влиться почти в любое общество, но эта способность не включает в себя знание языков, не даёт манеры, память и характер. Доппельгангеры нередко преследуют или похищают существ, в которых намерены превратиться, изучая их, и выведывая их секреты. Доппельгангеры способны читать поверхностные мысли существ, что позволяет им узнавать имена, желания, страхи существ, и даже немногие разрозненные воспоминания. Доппельгангер, имитирующий конкретное существо в течение долгого периода, может держать оригинал живым под рукой неделями, ежедневно изучая разум жертвы, чтобы достоверно узнать, как та говорит и поступает.

# Мошенники гедонисты

Доппельгангеры работают в одиночку или небольшими группами, в которых роли переходят от одного жулика к другому. Пока один доппельгангер занимает место убитого торговца или дворянина, остальные берут на себя роли личностей, в зависимости от обстоятельств, играя родственников или слуг, и так живут за счёт жертвы.

# Подкидыши

Доппельгангеры слишком ленивы и корыстны, чтобы воспитывать своё потомство. Они принимают облик притягательных мужчин, чтобы соблазнить женщин, а затем бросить воспитывать их детей. Ребёнок доппельгангер выглядит как нормальный представитель вида матери, пока не достигнет подросткового возраста, во время которого обнаруживает свою истинную природу и отправляется в путь, чтобы отыскать своих родичей и присоединиться к ним.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 98,
  },
  armor: 14,
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_INSIGHT]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_3,
  languageList: [
    LANG_COMMON,
  ],
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в Маленького или Среднего гуманоида, которого уже ★видел★, или принять свой истинный облик. Вся ★его★ статистика, кроме размера, одинаковая во всех обликах. Всё несомое и носимое им снаряжение не превращается. ★Он★ принимает свой истинный облик, если умирает.`,
    },
    {
      name: 'Мастер устраивать засады',
      description: `В первом раунде сражения ★СУЩЕСТВО★ совершает с преимуществом броски атаки по всем существам, которых он застал врасплох.`,
    },
    {
      id: ABILITY_SURPRISE_ATTACK,
      damage: `10 (3к6)`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Чтение мыслей',
      description: `★СУЩЕСТВО★ магическим образом читает поверхностные мысли одного существа в пределах 60 футов от себя. Эффект может проникать через препятствия, но 3 фута древесины или грунта, 2 фута камня, 2 дюйма металла или тонкий лист свинца блокируют его. Пока цель находится в пределах дистанции, ★СУЩЕСТВО★ может продолжать чтение мыслей, при условии, что ★его★ концентрация не прерывается (как при концентрации на заклинании). Читая мысли цели, ★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Проницательность) и Харизмы (Запугивание, Обман и Убеждение) против цели.`,
    },
  ],
  genderId: GENDER_MALE,
}
