const {
  CREATURE_SKELETON,
  CREATURE_ZOMBIE,
} = require('./../../../../../creatureIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_DANSE_MACABRE} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_DANSE_MACABRE,
  name: 'Пляска смерти',
  nameEn: 'Danse Macabre',
  description: `Нити темной силы прыгают с Ваших пальцев, чтобы пронзить до пяти Маленьких или Средних трупов, которые Вы можете видеть в пределах дистанции. Каждый труп немедленно встает и становится нежитью. Вы решаете, является ли он [зомби](CREATURE:${CREATURE_ZOMBIE}) или [скелетом](CREATURE:${CREATURE_SKELETON}), и он получает бонус к атаке и броскам урона, равный модификатору Вашей заклинательной характеристики.

Вы можете использовать бонусное действие, чтобы мысленно управлять существами, которых Вы создали этим заклинанием, выдавая одну и ту же команду каждому всем им. Чтобы получить команду, существо должно находиться в пределах 60 футов от вас. Вы решаете, какие действия предпримут существа и куда они будут двигаться в следующий ход, или Вы можете выдать команду общего характера, например, охранять помещение или проход от Ваших врагов.

Если Вы не выдаете никаких команд, существа ничего не предпринимают, кроме самозащиты от враждебных существ. После получения команды существа продолжают следовать им, пока их задача не будет выполнена.

Существа находятся под Вашим контролем до тех пор, пока заклинание не закончится, после чего они снова станут трупами.`,
  highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку заклинания 6 круга или выше, Вы можете оживить до двух дополнительных трупов для каждого уровня ячейки выше 5.`,
  lvl: 5,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 1},
  needConcentration: true,
  source: {
    id: SOURCE_XGTE,
    page: 161,
  },
}
