const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {
  SPELL_DISPEL_MAGIC,
  SPELL_TRUE_POLYMORPH,
} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_TRUE_POLYMORPH,
  name: 'Истинное превращение',
  nameEn: `True Polymorph`,
  description: `Выберите существо или немагический предмет, видимый в пределах дистанции. Вы превращаете существо в другое существо, существо в немагический предмет или предмет в существо (предмет не должно носить и нести другое существо). Превращение длится, пока активно заклинание, или пока хиты цели не опустятся до 0, или она не умрёт. Если Вы концентрируетесь на заклинании его полную длительность, превращение становится постоянным и длится, [пока не будет рассеяно](SPELL:${SPELL_DISPEL_MAGIC}).

Перевёртыши не попадают под действие этого заклинания. Несогласные существа могут пройти испытание Мудрости, и в случае успеха они не попадают под действие заклинания. Это заклинание не может подействовать на цель, у которой 0 хитов.

# Существо в существо

Если Вы превращаете существо в другое существо, новый облик может быть любым, лишь бы его показатель опасности не превышал ПО цели (или её уровня, если у цели нет показателя опасности). Игровые характеристики цели, включая значения ментальных характеристик, заменяются характеристиками нового облика. У цели остаётся мировоззрение и характер.

Цель принимает хиты нового облика. При возвращении в естественный облик количество его хитов будет как до превращения. Если оно возвращается в свой облик от того, что хиты опустились до 0, весь «лишний» урон перенесётся на естественный облик. Если «лишний» урон не опускает хиты естественного облика существа до 0, оно не теряет сознание.

Действия существа в новом облике ограничены теми, что доступны ей, и она не может говорить, сотворять заклинания и совершать другие действия, требующие рук или речи, если только новый облик не способен выполнять такие действия.

Снаряжение цели сливается с её новым обликом. Существо не может активировать, использовать, надевать или получать какие-то иные преимущества от своего снаряжения.

# Предмет в существо

Вы можете превратить предмет в любое существо, чей размер не превышает размер предмета, и чей показатель опасности не превышает 9. Существо будет дружелюбным по отношению к вам и Вашим спутникам. Оно будет действовать в Ваш ход. Вы сами решаете, какие действия оно будет совершать, и как будет перемещаться. Характеристики существа есть у Мастера, и он же решает, что получилось из его действий и перемещений.

Если заклинание становится постоянным, Вы перестаёте контролировать существо. Оно может остаться дружественным по отношению к вам, в зависимости от того, как Вы с ним обращались.

# Существо в предмет

Если Вы превращаете существо в предмет, оно превращается вместе с тем, что оно несёт и носит, если размер предмета не больше размера существа. Его параметрами становятся параметры предмета, и существо не помнит о времени, проведённом в этом облике, когда заклинание оканчивается и оно обретает свой естественный облик.`,
  lvl: 9,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `капля ртути, немного гуммиарабика и клуб дыма`,
  duration: { timeId: TIME_HOUR, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 233,
    },
    {
      id: SOURCE_SRD,
      page: 188,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Теперь нельзя превращать маленьких существ в большие предметы, да и в магические предметы тоже',
      page: 3,
    },
  ],
}
