const upLetter = require('./../../../../../../utils/upLetter')

const list = require('./list')

module.exports = list.reduce(
  (
    tableText,
    {
      golemId,
      dice,
      cost,
      time,
      nameRu: {
        singular: {
          nominative: singularNominative,
        },
      },
    }
  ) => `${tableText}|${dice}|[${upLetter(singularNominative)}](CREATURE:${golemId})|${time}|${cost} зм|\n`,
  ''
)
