const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_DECANTER_OF_ENDLESS_WATER} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_DECANTER_OF_ENDLESS_WATER,
  name: `Графин бесконечной воды`,
  nameEn: `Decanter of Endless Water`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Эта закупоренная ёмкость булькает, если её потрясти, как будто в ней находится вода. Весит графин 2 фунта.

Вы можете действием откупорить графин и произнести одно из трёх ключевых слов, после чего из графина выливается указанное количество пресной или солёной воды (на Ваш выбор). Вода прекращает литься в начале Вашего следующего хода.

У Вас есть следующие варианты:

# Ручей

Графин производит 1 галлон воды.

# Фонтан

Графин производит 5 галлонов воды.

# Гейзер

Графин производит 30 галлонов воды, которая вырывается струёй 30 футов длиной и 1 фут шириной.

Держа графин, Вы можете бонусным действием нацелиться графином на существо, которое видите в пределах 30 футов от себя. Цель должна пройти испытание Силы СЛ 13, иначе получит дробящий урон 1к4 и будет сбита с ног.

Вместо существа Вы можете нацелиться на предмет, который никто не несёт и не носит, и который весит не больше 200 фунтов. Предмет или падает или толкается на 15 футов от Вас.`,
  note: {
    text: 'Запомните: ни при каких обстоятельствах нельзя произносить слово „гейзер" в той же комнате, что и… ах, проклятье!',
    author: 'Биндельхайм, купец из Верхнего Города',
  },
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 158,
  },
}
