const {CREATURE_TYPE_INEVITABLE} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_INEVITABLE,
  nameEn: 'Inevitable',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'неизбежный',
        genitive: 'неизбежного',
        dative: 'неизбежному',
        accusative: 'неизбежного',
        instrumental: 'неизбежным',
        prepositional: 'неизбежном',
      },
      [GENDER_FEMALE]: {
        nominative: 'неизбежная',
        genitive: 'неизбежной',
        dative: 'неизбежной',
        accusative: 'неизбежную',
        instrumental: 'неизбежной',
        prepositional: 'неизбежной',
      },
    },
    plural: {
      nominative: 'неизбежные',
      genitive: 'неизбежных',
      dative: 'неизбежным',
      accusative: 'неизбежных',
      instrumental: 'неизбежными',
      prepositional: 'неизбежных',
    },
  },
}
