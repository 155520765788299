const {
  SPELL_AUGURY,
  SPELL_BLESS,
  SPELL_COMMAND,
  SPELL_GUIDANCE,
  SPELL_RESISTANCE,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_THAUMATURGY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  orcDescription,
  orcEyeOfGruumshDescription,
  orcNote,
} = require('./../../../../textCommonParts')
const {
  GEAR_RING_MAIL,
  GEAR_SHIELD,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_ORC,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_AGGRESSIVE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_ORC_EYE_OF_GRUUMSH} = require('./../../../../creatureIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Орк око Груумша',
  nameEn: 'Orc Eye of Gruumsh',
  id: CREATURE_ORC_EYE_OF_GRUUMSH,
  description: [
    orcEyeOfGruumshDescription,
    ...orcDescription,
  ],
  note: orcNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ORC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 230,
  },
  armor: {
    ac: 16,
    gearId: [GEAR_RING_MAIL, GEAR_SHIELD],
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 16,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ORC,
    LANG_COMMON,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AGGRESSIVE,
    {
      name: 'Ярость Груумша',
      description: `★СУЩЕСТВО★ причиняет дополнительный урон 4 (1к8), когда попадает атакой оружием (уже учтено в атаках).`,
    },
  ],
  spellCast: {
    spellCasterLevel: 3,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_AUGURY,
      SPELL_BLESS,
      SPELL_COMMAND,
      SPELL_GUIDANCE,
      SPELL_RESISTANCE,
      SPELL_THAUMATURGY,
      {
        id: SPELL_SPIRITUAL_WEAPON,
        comment: 'копьё',
      },
    ],
    slotCountList: [
      Infinity,
      4,
      2,
    ],
  },
  actionList: [
    {
      gearId: GEAR_SPEAR,
      attack: {
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
