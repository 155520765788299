const {
    SOURCE_MM,
    SOURCE_ERRATA_MONSTER_MANUAL,
  } = require('./../../../../sourceList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ABERRATION,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_GRELL,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_ELECTRICITY,
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    CONDITION_BLINDED,
    CONDITION_PRONE,
  } = require('./../../../../conditionList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_GRELL,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Грелл',
  nameEn: 'Grell',
  id: CREATURE_GRELL,
  description: `Грелл походит на летающий мозг с узким острым клювом. Под толстой волокнистой шкурой его трёхметровых щупалец скрываются сотни кольцевых мышц. Острые шипы на концах щупальцев впрыскивают парализующий яд. Грелл может частично скрывать эти шипы в щупальцах, чтобы взаимодействовать с объектами, которые не хочет проткнуть или разорвать.

У греллов нет глаз, а летают они с помощью своего рода левитации. У них острый слух, а кожа чувствительна к колебаниям воздуха и электрическим полям, благодаря чему они легко обнаруживают существ в непосредственной близости. Это существо чувствует и двигается благодаря своей способности к управлению электричеством, так что и молнию оно может поглотить без вреда.

Бывает, что греллы, одиночки по своей природе, собираются в маленькие группы, называемые шабашами.

# Парящие налётчики

Греллы предпочитают нападать либо на одиночек, либо на отстающих, зависая под потолком коридоров или пещер, пока удобная жертва не пройдёт снизу, где тут же будет обездвижена щупальцами. Парализованное существо грелл относит в своё логово.

# Инородные пожиратели

Греллы — иноземные хищники, делящие всех других существ на три категории: съедобное, несъедобное и Великие Едоки (те немногие существа, что могут охотиться на греллов). Их не мучает совесть от нападений на съедобных существ, в том числе и гуманоидов. Однако больших существ, которых тяжело нести, они избегают.

Иногда грелл может позволить искателям приключений сражаться с другими угрозами в подземелье, которое он зовёт домом. Держась в стороне, пока герои разбираются с большей угрозой, он ждёт, когда сможет нанести удар.`,
  note: {
    text: `Наша бесстрашная плутовка взобралась наверх по шахте, чтобы закрепить верёвку. Раздался судорожный вдох, а затем верёвка упала. Больше мы плутовку не видели`,
    author: `искатель приключений, свидетельствующий о нападении грелла в Хайбере, публикация в ХРОНИКАХ КОРРАНБЕРГА`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_NE,
  source: [
    {
      id: SOURCE_MM,
      page: 72,
    },
    {
      id: SOURCE_ERRATA_MONSTER_MANUAL,
      page: 1,
    },
  ],
  armor: 12,
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_ELECTRICITY,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  languageList: [
    LANG_GRELL,
  ],
  cr: CR_3,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Щупальцами_, и одну _Клювом_.`,
    },
    {
      name: 'Щупальца',
      description: `Цель должна пройти испытание Телосложения СЛ 11, иначе станет отравленной на 1 минуту. Отравленная цель парализована, и может повторять это испытание в конце каждого своего хода, оканчивая эффект при успехе.

Цель также становится схваченной (СЛ высвобождения равна 15). Если размер цели не больше Среднего, она также обездвиживается, пока не перестанет быть схваченной. Держа цель в захвате, ★СУЩЕСТВО★ совершает с преимуществом броски атаки по ней, и не может использовать эту атаку против других целей. Когда ★СУЩЕСТВО★ перемещается, все существа с размером не больше Среднего, схваченные ★им★, перемещаются вместе с ним.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
