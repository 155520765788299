import React from 'react'

import AdsPlacer from '@/components/AdsPlacer'

import './AdsAboveMenuStyles.less'

// Баннер РСЯ над меню
const AdsAboveMenu = () => (
  <AdsPlacer
    blockId='R-A-2421058-2'
    className='AdsAboveMenu'
  />
)

// Отключено из-за проблем с отображением
// export default AdsAboveMenu
export default () => null
