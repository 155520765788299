const {
  CREATURE_DRAGON_RED_ADULT,
  CREATURE_GOBLIN,
  CREATURE_VAMPIRE,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_ENCHANTMENT,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_DAY,
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  PARAM_WIT,
} = require('./../../../../../paramList')
const {
  CONDITION_FRIGHTENED,
} = require('./../../../../../conditionList')
const {
  SPELL_ANTIPATHY_SYMPATHY,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ANTIPATHY_SYMPATHY,
  name: 'Антипатия/Симпатия',
  nameEn: `Antipathy/Sympathy`,
  description: `Это заклинание привлекает или отталкивает выбранных Вами существ. Вы выбираете цель в пределах дистанции: либо предмет размером не больше Огромного, либо существо, либо область не больше куба с длиной ребра 200 футов. Затем укажите вид разумных существ, такой как [красные драконы](CREATURE:${CREATURE_DRAGON_RED_ADULT}), [гоблины](CREATURE:${CREATURE_GOBLIN}) или [вампиры](CREATURE:${CREATURE_VAMPIRE}). Вы наделяете цель аурой, которая в течение длительности либо привлекает, либо отталкивает указанных существ. Выберите либо эффект антипатии, либо эффект симпатии:

**Антипатия.** Эти чары вызывают у выбранных Вами существ непреодолимое желание покинуть область и избегать цель. Если такое существо видит цель и оказывается в пределах 60 футов от неё, оно должно пройти испытание Мудрости, иначе станет испуганным. Существо остаётся испуганным, пока видит цель или находится в пределах 60 футов от неё. Будучи испуганным целью, существо должно тратить своё перемещение на побег в ближайшее безопасное место, откуда оно уже не увидит цель. Если существо отдалится от цели более чем на 60 футов и не сможет её видеть, оно перестаёт быть испуганным, но становится испуганным вновь, если увидит цель или окажется в пределах 60 футов от неё.

**Симпатия.** Эти чары вызывают у выбранных Вами существ непреодолимое желание приблизиться к цели, если они находятся в пределах 60 футов от неё или видят её. Если такое существо видит цель или оказывается в пределах 60 футов от неё, оно должно пройти испытание Мудрости, иначе оно в каждом своём ходу тратит перемещение на то, чтобы войти в область или прикоснуться к цели. Сделав это, оно уже не может добровольно отойти от цели.

Если цель причиняет урон или как-то иначе вредит указанному существу, существо может пройти испытание Мудрости для окончания эффекта, как описано ниже.

**Окончание эффекта.** Если указанное существо окончит ход за пределами 60 футов от цели или не сможет её видеть, оно проходит испытание Мудрости. При успехе эффект на существе заканчивается и оно понимает, что тяга или отвращение были вызваны магией. Кроме того, попавшее под эффект заклинания существо может повторять испытание Мудрости каждые 24 часа, пока заклинание активно.

Успешно прошедшее испытание существо получает иммунитет к эффекту на 1 минуту, после чего снова попадает под его действие.`,
  lvl: 8,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кусок квасцов, пропитанный уксусом для эффекта антипатии или капля мёда для эффекта симпатии`,
  duration: { timeId: TIME_DAY, count: 10 },
  castTime: { timeId: TIME_HOUR, count: 1 },
  effect: {
    savethrowParam: PARAM_WIT,
    condition: CONDITION_FRIGHTENED,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 212,
    },
    {
      id: SOURCE_SRD,
      page: 117,
    },
  ],
}
