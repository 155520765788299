const declint = require('declint-ru')

const numberList = require('./../../nameGeneration/wordDicts/numberList')
const {LANG_ANY} = require('./../../languageIdList')

module.exports = {
  id: LANG_ANY,
  isReady: false,
  name: (count = 1) => {
    const {nominative, genitive, instrumental, prepositional} = numberList[count]

    return {
      nominative: declint(
        count,
        [
          `Любой ${nominative} язык`,
          `Любые ${nominative} языка`,
          `Любые ${nominative} языков`,
        ]
      ),
      genitive: declint(
        count,
        [
          `Любого ${genitive} языка`,
          `Любых ${genitive} языков`,
          `Любых ${genitive} языков`,
        ]
      ),
      instrumental: declint(
        count,
        [
          `Любым ${instrumental} языком`,
          `Любыми ${instrumental} языками`,
          `Любыми ${instrumental} языками`,
        ]
      ),
      prepositional: declint(
        count,
        [
          `Любом ${prepositional} языке`,
          `Любых ${prepositional} языках`,
          `Любых ${prepositional} языках`,
        ]
      ),
    }
  },
}
