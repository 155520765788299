const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_PLANT,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    SENSE_VIBRATION_SENSE,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_FALSE_APPEARANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_PRONE,
  } = require('./../../../../conditionList'),
  {
    CREATURE_MANTRAP,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Капкан',
  nameEn: 'Mantrap',
  id: CREATURE_MANTRAP,
  description: {
    header: 'Капкан',
    text: `Капкан — гигантское плотоядное растение, напоминающее огромный мухолов. Чтобы привлечь добычу, капкан выбрасывает пыльцу, которая магически привлекает к нему существ. Хотя оно и готово защёлкнуть лиственные челюсти вокруг любого теплокровного существа, которое находится в пределах его досягаемости, оно предпочитает гуманоидную добычу.`,
    source: {
      id: SOURCE_TOA,
      page: 230,
    },
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 230,
  },
  armor: 12,
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 5,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 2,
  },
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_BLINDED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_VIBRATION_SENSE,
      value: 30,
    },
  ],
  cr: CR_1,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного растения`,
    },
    {
      name: 'Привлекательная пыльца',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Когда ★СУЩЕСТВО★ обнаруживает любых существ поблизости, он может реакцией, выпустить пыльцу в пределах 30 футов. Любой зверь или гуманоид в этом районе должен пройти испытание Мудрости СЛ 11. При провале оно на своём ходу должно будет использовать всё своё передвижения, чтобы как можно ближе приблизиться к ★СУЩЕСТВО★. Цель, попавшая под влияние, может повторять испытание в конце каждого своего хода, оканчивая эффект при успехе.`,
    },
  ],
  actionList: [
    {
      name: 'Поглощение',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            size: {
              max: SIZE_MEDIUM,
            },
          },
        },
        hit: `Цель попадает внутрь лиственных челюстей ★СУЩЕСТВО★. При попадании в ловушку таким образом цель Ослеплена и Обездвижена, а также получает полное укрытие от атак и других эффектов извне ★СУЩЕСТВО★ и получает 14 (4к6) урона кислотой в начале каждого своего хода. Если ★СУЩЕСТВО★ умирает, существо внутри ★него★ больше не опутано. ★СУЩЕСТВО★ может поглотить только одно существо за раз.`,
      },
    },
  ],
}
