const {
  SPELL_ACID_SPLASH,
  SPELL_BLADE_WARD,
  SPELL_CHROMATIC_ORB,
  SPELL_COUNTERSPELL,
  SPELL_ERUPTING_EARTH,
  SPELL_FRIENDS,
  SPELL_LIGHT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGIC_MISSILE,
  SPELL_MAXIMILIAN_S_EARTHEN_GRASP,
  SPELL_MESSAGE,
  SPELL_MOLD_EARTH,
  SPELL_MOVE_EARTH,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_POLYMORPH,
  SPELL_SHATTER,
  SPELL_STONESKIN,
  SPELL_SUGGESTION,
  SPELL_WALL_OF_STONE,
} = require('./../../../../spellIdList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_GENASI_EARTH,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_PRIMORDIAL,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_MARLOS_URNRAYLE,
  CREATURE_MIRAJ_VIZANN,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_6} = require('./../../../../crList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {blackEarthDescription} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Мирадж Визанн',
  nameEn: 'Miraj Vizann',
  id: CREATURE_MIRAJ_VIZANN,
  hasSpoilers: true,
  description: [
    {
      header: 'Мирадж Визанн',
      text: `Генази земли из Калимшана по имени Мирадж Визанн отвечает в культе за прокладку тоннелей. Мирадж — заслуживший славу перфекциониста специалист по магии и алхимическим процессам, придающих камню ту или иную форму. Поскольку стихия воды тоже ему близка, он часто называет себя «магом грязи».

Хотя Мирадж по праву считается самым опытным заклинателем в культе Чёрной Земли, он не стремится сместить [Марлоса Урнрейла](CREATURE:${CREATURE_MARLOS_URNRAYLE}) с поста лидера культа. Он понимает, что [медуза](CREATURE:${CREATURE_MARLOS_URNRAYLE}) гораздо сильнее него, поэтому продолжает свои эксперименты, с радостью оставляя управление культом [Марлосу](CREATURE:${CREATURE_MARLOS_URNRAYLE}).`,
      source: {
        id: SOURCE_PotA,
        page: 203,
      },
    },
    blackEarthDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GENASI_EARTH,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 203,
  },
  armor: [
    10,
    {
      ac: 13,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 18,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_PRIMORDIAL,
  ],
  cr: CR_6,
  featureList: [
    {
      name: 'Хождение по земле',
      nameEn: 'Earth Walk',
      description: `★СУЩЕСТВО★ может перемещаться по труднопроходимой местности из земли или камня, не затрачивая дополнительное движение.`,
    },
  ],
  spellCast: [
    {
      baseStat: PARAM_CON,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_PASS_WITHOUT_TRACE,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 11,
      spellCasterClass: PC_CLASS_SORCERER,
      spellIdList: [
        SPELL_ACID_SPLASH,
        SPELL_BLADE_WARD,
        SPELL_CHROMATIC_ORB,
        SPELL_COUNTERSPELL,
        SPELL_ERUPTING_EARTH,
        SPELL_FRIENDS,
        SPELL_LIGHT,
        SPELL_MAGE_ARMOR,
        SPELL_MAGIC_MISSILE,
        SPELL_MAXIMILIAN_S_EARTHEN_GRASP,
        SPELL_MESSAGE,
        SPELL_MOLD_EARTH,
        SPELL_MOVE_EARTH,
        SPELL_POLYMORPH,
        SPELL_SHATTER,
        SPELL_STONESKIN,
        SPELL_SUGGESTION,
        SPELL_WALL_OF_STONE,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        2,
        1,
      ],
    },
  ],
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
  ],
}
