const {ABILITY_KEEN_SIGHT} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {CR_2} = require('./../../../../crList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_EAGLE,
  CREATURE_GRIFFON,
  CREATURE_HIPPOGRIFF,
  CREATURE_LION,
  CREATURE_RIDING_HORSE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Грифон',
  nameEn: 'Griffon',
  id: CREATURE_GRIFFON,
  description: [
    {
      header: 'Грифоны',
      text: `Грифоны — свирепые крылатые хищники с мускулистыми телами львов и головами, передними лапами и крыльями орлов. Когда они нападают, грифоны смертоносны и быстры, как [орлы](CREATURE:${CREATURE_EAGLE}), несмотря на то, что удары их мощны и грациозны, как у [львов](CREATURE:${CREATURE_LION}).`,
      source: {
        id: SOURCE_MM,
        page: 77,
      },
    },
    {
      header: 'Пожиратели лошадей',
      text: `Грифоны охотятся небольшими прайдами, летая над равнинами и лесами близ своих скал. Стадные животные и [лошади](CREATURE:${CREATURE_RIDING_HORSE}) — их любимая пища, однако они также охотятся и на [гиппогрифов](CREATURE:${CREATURE_HIPPOGRIFF}). Заметив лошадей, грифон кричит, чтобы предупредить своих гордых собратьев, которые мгновенно набрасываются на жертву.

[Лошади](CREATURE:${CREATURE_RIDING_HORSE}) страшатся пронзительного крика грифона, готовясь к кровавому бою, который неизбежно последует за ним. Грифон по возможности игнорирует всадника [лошади](CREATURE:${CREATURE_RIDING_HORSE}), и наездники, бросившие своего скакуна, или погонщик, отпустивший пару [лошадей](CREATURE:${CREATURE_RIDING_HORSE}), могут сбежать невредимыми, пока грифон атакует выбранную жертву. Всадник, который попытается защитить свою [лошадь](CREATURE:${CREATURE_RIDING_HORSE}), навлекает на себя всю ярость сражающегося грифона.`,
      source: {
        id: SOURCE_MM,
        page: 77,
      },
    },
    {
      header: 'Небожители',
      text: `Грифоны располагают свои логова на вершинах скал, строя гнезда из палок, листьев и костей своей добычи. После того как грифон обосновался, он остаётся на выбранной территории, пока не закончится продовольствие.

Агрессивные собственники, грифоны вступают в жестокий воздушный бой за свою территорию, раздирая крылья вторгшихся, чтоб сбросить их на землю. Существ, которые пытаются вскарабкаться к логову, они сдирают со скалы и съедают или сбивают с большой высоты, чтоб те падали и разбивались.`,
      source: {
        id: SOURCE_MM,
        page: 77,
      },
    },
    {
      header: 'Тренированные ездовые животные',
      text: `Грифона, взращённого из яйца, можно натренировать для службы в качестве ездового животного. Однако такая тренировка опасна и занимает много времени и затрат (в основном на необходимую пищу). Опытные тренеры хорошо знакомы с легендарной свирепостью грифонов и, как правило, только они в состоянии безопасно растить этих существ.

После обучения грифон становится яростным и верным ездовым животным. Он связывает себя с одним хозяином на всю жизнь, сражаясь за него до самой смерти. Грифон сохраняет свой ненасытный аппетит к конине, и мудрый хозяин обеспечит ему другую добычу при прохождении через цивилизованные страны.`,
      source: {
        id: SOURCE_MM,
        page: 77,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 77,
  },
  armor: 12,
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 80,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_KEEN_SIGHT,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Клювом_, и одну _Когтями_.`,
    },
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
}
