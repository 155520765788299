const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1_8,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  CREATURE_GHOUL,
  CREATURE_MANES,
  CREATURE_ORCUS,
  CREATURE_SHADOW,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Мэйн',
  nameEn: 'Manes',
  id: CREATURE_MANES,
  description: [
    `Души злых существ, попадающих на Нижние Планы, превращаются в мэйнов — низшую форму демонов. Эти жалкие исчадия нападают на всех кроме демонов, а на Материальный план их призывают те, кто хотят сеять смерть и хаос.

[Оркус, Повелитель Нежити](CREATURE:${CREATURE_ORCUS}), может преобразовывать мэйнов в нежить, чаще всего в [упырей](CREATURE:${CREATURE_GHOUL}) и [тени](CREATURE:${CREATURE_SHADOW}). Другие демонические повелители просто поедают мэйнов, полностью их уничтожая. В иных случаях умирающий мэйн превращается в зловонное облако, которое через сутки трансформируется в другого мэйна.`,
    ...demonDescription,
  ],
  note: {
    text: `Не испытывайте жалости к проклятому поганцу. Эта дрянь может вырасти и когда-нибудь стать демоническим повелителем`,
    author: `Эмирикол Хаотичный`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 94,
  },
  armor: 9,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 13,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 4,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
    CONDITION_FRIGHTENED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ABYSSAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_8,
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
