const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {gearCollection} = require('./../../../../../gearList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    bonus,
    gearId,
    id,
    rarity,
  }
) => {
  const {name, nameEn} = gearCollection[gearId]

  return {
    id,
    name: `${name} +${bonus}`,
    nameEn: `${nameEn} +${bonus}`,
    rarity,
    type: MGC_TYPE_WEAPON,
    gearType: gearId,
    description: `Вы получаете бонус +${bonus} к броскам атаки и урона, совершённым этим магическим оружием.`,
    genderId: GENDER_MIDDLE,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 187,
    },
  }
}

module.exports = generator
