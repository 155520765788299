const {
  CREATURE_TYPE_GOBLINOID,
  CREATURE_TYPE_HOBGOBLIN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_JAVELIN,
  GEAR_LONGSWORD,
  GEAR_PLATE_ARMOR,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  hobgoblinDescriptionList,
  hobgoblinNote,
} = require('./../../../../textCommonParts')
const {ABILITY_PARRY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_HOBGOBLIN_WARLORD} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Хобгоблин-военачальник`,
  nameEn: 'Hobgoblin Warlord',
  id: CREATURE_HOBGOBLIN_WARLORD,
  description: hobgoblinDescriptionList,
  note: hobgoblinNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_HOBGOBLIN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 295,
  },
  armor: {
    ac: 20,
    gearId: [GEAR_PLATE_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GOBLIN,
    LANG_COMMON,
  ],
  cr: CR_6,
  featureList: [
    {
      name: `Воинское превосходство`,
      description: `Один раз в ход ★СУЩЕСТВО★ может причинить дополнительный урон 14 (4к6) существу, по которому ★он★ ★попал★ атакой оружием, если это существо находится в пределах 5 футов от ★его★ дееспособного союзника хобгоблина.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает три рукопашные атаки. В качестве альтернативы, ★он★ может совершить две дальнобойные атаки _Метательными копьями_.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      name: `Удар щитом`,
      description: `Если размер цели не больше Большого, она должна пройти испытание Силы СЛ 14, иначе будет сбита с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_JAVELIN,
    },
    {
      name: `Лидерство`,
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `В течение 1 минуты ★СУЩЕСТВО★ может отдавать особые приказы и предупреждения, когда невраждебные существа, видимые им в пределах 30 футов, совершают бросок атаки или проходят испытание. Это существо может добавить к4 к своему броску, если слышит и понимает ★СУЩЕСТВО★. Существо может получать выгоду только от одного _Лидерства_ одновременно. Этот эффект оканчивается, если ★СУЩЕСТВО★ становится ★недееспособным★.`,
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 3,
    },
  ],
  genderId: GENDER_MALE,
}
