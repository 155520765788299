const arrify = require('arrify')

const reducePropIdList = (propName) => (list, item) => {
  arrify(item[propName]).forEach(
    itemProp => {
      const id = itemProp && itemProp.id || itemProp.timeId || itemProp

      if (!list.includes(id)) {
        list.push(id)
      }
    }
  )

  return list
}

module.exports = reducePropIdList
