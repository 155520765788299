const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  PC_CLASS_PALADIN,
} = require('./../../../../../pcClassIdList')
const {
  MAGIC_ITEM_HOLY_AVENGER,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_HOLY_AVENGER,
  name: `Святой мститель`,
  nameEn: `Holy Avenger`,
  type: MGC_TYPE_WEAPON,
  typeComment: `любой меч`,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `паладином`,
  description: `Вы получаете бонус +3 к броскам атаки и урона, совершённым этим магическим оружием. Если Вы попадаете им по исчадию или нежити, это существо получает дополнительный урон излучением 2к10.

Пока Вы держите в руке этот вынутый из ножен меч, он создаёт ауру с радиусом 10 футов вокруг Вас. Вы и все дружественные Вам существа в этой ауре получаете преимущество на все испытания от заклинаний и других магических эффектов. Если у Вас есть 17 уровней в классе [паладина](PC_CLASS:${PC_CLASS_PALADIN}), радиус ауры увеличивается до 30 футов.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 201,
  },
}
