const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_SKULL_LASHER_OF_MYRKUL} = require('./../../../../creatureIdList')
const {CR_1} = require('./../../../../crList')
const {GEAR_FLAIL} = require('./../../../../gearIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {myrkulFollowerList} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NECROTIC,
} = require('./../../../../damageTypeList')
const {
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_MISTY_STEP,
  SPELL_PRESTIDIGITATION,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_RAY_OF_SICKNESS,
  SPELL_SHIELD,
} = require('./../../../../spellIdList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Черепобо́ец Миркула',
  nameEn: 'Skull lasher of Myrkul',
  id: CREATURE_SKULL_LASHER_OF_MYRKUL,
  description: myrkulFollowerList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_BGDIA,
    page: 238,
  },
  armor: 12,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 15,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
    LANG_INFERNAL,
  ],
  cr: CR_1,
  spellCast: {
    spellCasterLevel: 3,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_DARKNESS,
      SPELL_DETECT_MAGIC,
      SPELL_MAGE_HAND,
      SPELL_MESSAGE,
      SPELL_MISTY_STEP,
      SPELL_PRESTIDIGITATION,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_RAY_OF_SICKNESS,
      SPELL_SHIELD,
    ],
    slotCountList: [
      Infinity,
      4,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Цепом_.`,
    },
    {
      name: 'Цеп с железным черепом',
      gearId: GEAR_FLAIL,
      attack: {
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 6,
        },
      },
    },
  ],
}
