const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_PLASMOID} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_PLASMOID,
  nameEn: 'Plasmoid',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'плазмоид',
      genitive: 'плазмоида',
      dative: 'плазмоиду',
      accusative: 'плазмоида',
      instrumental: 'плазмоидом',
      prepositional: 'плазмоиде',
    },
    plural: {
      nominative: 'плазмоиды',
      genitive: 'плазмоидов',
      dative: 'плазмоидам',
      accusative: 'плазмоидов',
      instrumental: 'плазмоидами',
      prepositional: 'плазмоидах',
    },
  },
}
