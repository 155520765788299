const {ALPHABET_BARAZHAD} = require('./../../alphabetList')
const {
  LANG_TYPE_EXOTIC,
} = require('./../../languageTypeList')
const {
  LANG_GNOLL,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_GNOLL,
  type: LANG_TYPE_EXOTIC,
  alphabetId: ALPHABET_BARAZHAD,
  name: {
    nominative: 'Гноллий',
    genitive: 'Гнолльего',
    instrumental: 'Гнолльим',
    prepositional: 'Гнолльем',
  },
  nameEn: 'Gnoll',
  isReady: false,
  isRealLang: true,
}
