const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_INVESTITURE_OF_WIND} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_INVESTITURE_OF_WIND,
  name: 'Облачение ветра',
  nameEn: 'Investiture of Wind',
  description: `Пока действует заклинание, Вас окружает ветер, и Вы получаете следующие преимущества:

* Дальнобойные атаки оружием по Вам совершаются с помехой.
* Вы получаете скорость полёта 60 футов. Если Вы находитесь в воздухе, когда заклинание заканчивается, то Вы падаете, если не можете каким-либо образом предотвратить это.
* Вы можете действием создать область ураганного ветра в 15-футовом кубе с центром в точке, которую Вы можете видеть в пределах 60 футов. Все существа в этой области должны пройти испытание Телосложения. При провале существо получает дробящий урон 2к10 или половину этого урона при успехе. Если существо с размером не больше Большого проваливает испытание, его отталкивает на 10 футов от центра куба.`,
  lvl: 6,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_BLUDGEONING,
      halfOnSuccess: true,
      diceCount: 2,
      diceType: 10,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 158,
    },
    {
      id: SOURCE_EE,
      page: 18,
    },
  ],
}
