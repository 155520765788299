const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_OIL,
} = require('./../../../../../gearIdList')
const {
  SPELL_WISH,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_LANTERN_OF_REVEALING,
  MAGIC_ITEM_LUCK_BLADE,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_LANTERN_OF_REVEALING,
    name: `Фонарь обнаружения`,
    nameEn: `Lantern of Revealing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Этот закрытый фонарь способен гореть в течение шести часов на одной пинте [масла](GEAR:${GEAR_OIL}), распространяя яркий свет в радиусе 30 футов и тусклый свет в радиусе ещё 30 футов.

Все невидимые предметы и существа становятся видимыми, если они находятся в ярком свете этого фонаря.

Вы можете действием опустить козырёк, уменьшив освещение до тусклого света в пределах 5 футов.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 211,
    },
  },
  {
    id: MAGIC_ITEM_LUCK_BLADE,
    name: `Клинок удачи`,
    nameEn: `Luck Blade`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч`,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Вы получаете бонус +1 к броскам атаки и урона, совершённым этим магическим оружием. Пока это оружие находится у Вас, Вы также получаете бонус +1 к испытаниям.

# Удача

Если этот меч находится у Вас, Вы можете воззвать к его удаче (действие не требуется), чтобы перебросить один бросок атаки, проверку характеристики или испытание, который Вам не понравился. Вы обязаны использовать результат второго броска. Это свойство нельзя использовать повторно до следующего рассвета.

# [Исполнение желаний](SPELL:${SPELL_WISH})

У меча есть 1к4 − 1 заряд. Если Вы держите его, Вы можете действием потратить 1 заряд, чтобы наложить им заклинание [Исполнение желаний](SPELL:${SPELL_WISH}). Это свойство нельзя использовать повторно до следующего рассвета. Меч теряет это свойство, когда у него не останется зарядов.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_WISH],
    source: {
      id: SOURCE_DMG,
      page: 170,
    },
  },
]
