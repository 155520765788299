const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_TROLL} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_TROLL,
  nameEn: 'Troll',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'тролль',
      genitive: 'тролля',
      dative: 'троллю',
      accusative: 'тролла',
      instrumental: 'троллем',
      prepositional: 'тролле',
    },
    plural: {
      nominative: 'тролли',
      genitive: 'троллей',
      dative: 'троллям',
      accusative: 'троллей',
      instrumental: 'троллями',
      prepositional: 'троллях',
    },
  },
}
