import React from 'react'

import AdsPlacer from '@/components/AdsPlacer'

import './AdsAtDescriptionStyles.less'

// Баннер РСЯ в описаниях
const AdsAtDescription = () => (
  <AdsPlacer
    blockId='R-A-2421058-5'
    className='AdsAtDescription'
    incrementIndex
  />
)

export default AdsAtDescription
