const {CREATURE_ICE_DEVIL} = require('./../../creatureIdList')

module.exports = {
  extendPropCollection: {
    actionList: [
      {
        name: 'Призыв дьявола',
        description: `Дьявол пытается призвать одного [ледяного дьявола](CREATURE:${CREATURE_ICE_DEVIL}) с шансом 60%.

Призванный дьявол появляется в свободном пространстве в пределах 60 футов от призывателя, и действует как его союзник, не имеющий способности призывать дьяволов. Он помогает в течение 1 минуты или пока призыватель не умрёт или действием его не отпустит.`,
        limit: {
          count: 1,
          period: 'день',
        },
      },
    ],
  },
}
