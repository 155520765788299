const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_15} = require('./../../../../crList')
const {CREATURE_NABASSU} = require('./../../../../creatureIdList')
const {demonDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPELL_WISH} = require('./../../../../spellIdList')

module.exports = {
  name: 'Набассу',
  nameEn: 'Nabassu',
  id: CREATURE_NABASSU,
  description: [
    `Неутолимые набассу скитаются по всей мультивселенной в поисках душ для утоления своей жажды. Если они посчитают, что смогут убить существо и поглотить его душу, то незамедлительно нападают на него, даже если это существо — демон, в том числе и на себе подобных набассу.`,
    {
      header: 'Ненавистные изгои',
      text: `У демонов есть немного правил, но на убийство одного из своих сородичей у этих исчадий вряд ли поднимется бровь. Однако, пожирание душ — это уже совсем другое. По этой причине, большинство демонов избегают набассу и принуждают их жить на окраинах Бездны. Там, набассу сгрызают слабых демонов, а если потребуется, они соберутся группой, чтобы завалить добычу побольше. Некоторые особо могучие набассу могут даже охотиться за амулетами лордов демонов.`,
      source: {
        id: SOURCE_MTOF,
        page: 135,
      },
    },
    {
      header: 'Демонические нарушители',
      text: `Всякий раз, как магия вытягивает демонов из Бездны в Материальный план, набассу стараются быть призванными, чтобы оттуда они смогли насладиться пиршеством душ. Если набассу призовётся, то он попытается высвободиться и поглотить душу призывателя, а затем приступает к поиску и пожиранию душ других существ, встретившихся у него на пути. Единственным способом избежать такой участи, призыватель должен регулярно обеспечивать поставки душ набассу, что позволит прожорливому демону быть более сговорчивым до тех пор, пока не закончатся запасы душ.`,
      source: {
        id: SOURCE_MTOF,
        page: 135,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 135,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 21,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_15,
  featureList: [
    {
      name: 'Демонические тени',
      description: `Набассу затеняет вокруг себя пространство радиусом 10 футов. Немагический свет не может осветить эту область с тусклым светом.`,
    },
    {
      name: 'Пожиратель души',
      description: `Набассу может может поглощать душу существа, убитого им в течение последнего часа, исключая такую возможность относительно нежити и конструктов. Поглощение требует нахождения набассу в пределах 5 фт. от трупа на протяжение 10 минут. После каждого такого поглощения, набассу получает количество Костей Хитов (к8), равное половине имеющихся Костей Хитов у существа. Совершите броски этих Костей, увеличивая значение количества хитов на выпавший результат. За каждые 4 Кости Хитов, использованных таким образом, набассу будет причинять дополнительно 3 (1к6) урона при попадании. Набассу сохраняет это преимущество на протяжении 6 дней. Существо, чья душа была поглощена набассу, может быть возвращено к жизни только заклинанием [исполнение желаний](SPELL:${SPELL_WISH}).`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Набассу использует _Крадущий душу взгляд_ и совершает две атаки: одну _Когтями_ и одну _Укусом_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 12,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Крадущий душу взгляд',
      description: `Набассу выбирает целью одно существо в пределах 30 фт. от себя, которое он может видеть. Если цель может видеть набассу и не является конструктом или нежитью, то она должна пройти испытание Харизмы СЛ 16, иначе её максимальное значение хитов будет понижено на 3 (2к12), после чего набассу получит временные хиты в количестве, равном выпавшему значению. Понижение хитов длится до совершения целью короткого или длинного отдыха. Цель умирает, если её максимальное значение хитов опустится до 0, и, если цель является гуманоидом, она немедленно восстаёт в виде упыря под контролем набассу`,
    },
  ],
}
