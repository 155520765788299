const {
  SPELL_CHARM_PERSON,
  SPELL_FRIENDS,
  SPELL_HEALING_WORD,
  SPELL_HEROISM,
  SPELL_INVISIBILITY,
  SPELL_MAGE_HAND,
  SPELL_SHATTER,
  SPELL_SLEEP,
  SPELL_THUNDERWAVE,
  SPELL_VICIOUS_MOCKERY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ACROBATICS,
  SKILL_PERCEPTION,
  SKILL_PERFORMANCE,
} = require('./../../../../skillList')
const {
  GEAR_CHAIN_SHIRT,
  GEAR_SHORTBOW,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_BARD} = require('./../../../../creatureIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_BARD} = require('./../../../../pcClassIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Бард',
  nameEn: 'Bard',
  id: CREATURE_BARD,
  description: `Барды — одаренные поэты, рассказчики и артисты, которые повсюду путешествуют, но чаще всего находятся в тавернах или в весёлых группах приключенцев, наемных бойцов и влиятельных покровителей.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 212,
  },
  armor: {
    ac: 15,
    gearId: GEAR_CHAIN_SHIRT,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_PERFORMANCE]: PROF_DOUBLE,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Песнь отдыха',
      description: `Бард может исполнить песню во время короткого отдыха. Любой союзник, который слышит песню восстанавливает дополнительные 1к6 хитов если потратит кость хитов для восстановления хитов в конце отдыха. Бард также может получить этот бонус.`,
    },
    {
      name: 'Насмешка',
      limit: {
        count: 2,
        period: 'день',
      },
      description: `Бард может бонусным действием в свой ход выбрать одно существо в пределах 30 футов. Если цель может слышать барда, то она должна пройти испытании Харизмы СЛ 12 или до конца следующего хода барда будет вынужден проходить с помехой все проверки, броски атак и испытания.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 4,
    spellCasterClass: PC_CLASS_BARD,
    spellIdList: [
      SPELL_CHARM_PERSON,
      SPELL_FRIENDS,
      SPELL_HEALING_WORD,
      SPELL_HEROISM,
      SPELL_INVISIBILITY,
      SPELL_MAGE_HAND,
      SPELL_SHATTER,
      SPELL_SLEEP,
      SPELL_THUNDERWAVE,
      SPELL_VICIOUS_MOCKERY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
    ],
  },
  actionList: [
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_SHORTBOW,
    },
  ],
}
