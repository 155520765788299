const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MAGIC_ITEM_SWORD_OF_SHARPNESS} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_SWORD_OF_SHARPNESS,
  name: `Меч остроты`,
  nameEn: `Sword of Sharpness`,
  type: MGC_TYPE_WEAPON,
  typeComment: `любой меч, причиняющий рубящий урон`,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `Если Вы атакуете этим магическим мечом предмет и попадаете, кости урона причиняют цели максимальный урон.

Если Вы атакуете этим оружием существо, и при броске атаки выпадает «20», эта цель получит дополнительный рубящий урон 14. После этого бросьте ещё к20. Если выпадет «20», Вы отрубаете одну из конечностей цели, а эффект этого определяет Мастер. Если у существа не было конечностей, Вы отрубаете часть его тела.

Кроме того, Вы можете произнести командное слово меча, чтобы клинок начал излучать яркий свет в радиусе 10 футов и тусклый свет в пределах ещё 10 футов. Повторное произнесение этого слова или убирание меча в ножны гасит свет.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 185,
  },
}
