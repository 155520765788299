const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {DAMAGE_PSYCHIC} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_MADDENING_DARKNESS} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MADDENING_DARKNESS,
  name: 'Одуряющая тьма',
  nameEn: 'Maddening Darkness',
  description: `Из точки, выбранной Вами в пределах дистанции, расползается и остаётся в течение времени действия заклинания магическая тьма сферой с радиусом 60 футов. Тьма огибает углы. Существа с тёмным зрением не могут видеть сквозь эту тьму. Немагический свет, а также свет, созданный заклинаниями 8-го уровня или ниже, не могут осветить эту область.

В этой сфере можно услышать крики, бормотание и безумный смех. Всякий раз, когда существо начинает свой ход в сфере, оно должно пройти испытание Мудрости. Существо получает 8к8 урона психической энергией при провале или половину этого урона при успехе.`,
  lvl: 8,
  magicSchoolId: MAGIC_EVOCATION,
  range: 150,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `капля смолы, смешанная с каплей ртути`,
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
    damage: {
      type: DAMAGE_PSYCHIC,
      halfOnSuccess: true,
      diceCount: 8,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 159,
  },
}
