const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_FEY,
  } = require('./../../../../creatureTypeIdList'),
  {
    LANG_SYLVAN,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_LE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_FLY,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    CREATURE_SCREAMING_DEVILKIN,
  } = require('./../../../../creatureIdList'),
  {
    PC_CLASS_BARD,
  } = require('./../../../../pcClassIdList')

module.exports = {
  name: 'Вопящий чертёнок',
  nameEn: 'Screaming Devilkin',
  id: CREATURE_SCREAMING_DEVILKIN,
  description: [
    {
      header: 'Вопящие чертята',
      text: `Свирепые вопящие чертята — причудливые фейские существа, порожденные подавляющими моментами паники. Несмотря на свою дьявольскую внешность и название, это жители Страны Фей. Мудрецы полагают, что они появились из первичного страха, вдохновленного исчадиями, а [барды](PC_CLASS:${PC_CLASS_BARD}) утверждают, что паника, будучи такой отталкивающей эмоцией, воплощена в настолько же уродливом существе.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 19,
      },
    },
    {
      header: 'Кричащие ужасы',
      text: `Вопящий чертёнок иногда возникает, когда существо в Стране Фей переполняется паникой до такой степени, что кричит, лепечет и иным образом теряет контроль над своими способностями. Когда такое событие происходит, крик существа эхом проникает через Страну Фей. Если в глухую ночь он отражается от скал под нужным углом, крик набирает силу и громкость, пока не достигнет пронзительного крещендо. В этот момент скала трескается и маленькая стая вопящих чертят вырываются из земли и поднимаются в воздух.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 19,
      },
    },
    {
      header: 'Злые мучители',
      text: `Как и положено существам, рождённым паникой, вопящие чертята любят распространять везде страх и растерянность. Они собираются небольшими стаями, прячась вдоль одиноких лесных дорог и горных перевалов. Когда их жертвы приближаются, чертята нападают, рубя их длинными колючими хвостами. Ужасный визг переполняет чувства, вызывая одновременно панику, перегрузки чувств и смятение, которые заставляют существ дрожать. Несмотря на свою злую натуру, вопящие чертята редко убивают своих жертв. Они предпочитают оставлять их сильно израненными и лишенными всякой надежды. Чертята крадут сокровища, оружие и припасы, обрекая экспедицию на медленную смерть. Они летают рядом с жертвами, дразня их вещами, которые украли. Хищники в этой области быстро выучивают, что визг чертёнка — верный признак того, что приближается лёгкая добыча.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 19,
      },
    },
    {
      header: 'Оппортунистические альянсы',
      text: `Стая вопящих чертят может оценить злую жестокость других существ. Если они видят такие признаки злого присутствия, как жестокий повелитель или злобный некромант, они могут предложить союз, если злодей предлагает им много возможностей для бессмысленного разрушения и мучений. Эти существа служат своего рода ужасными талисманами, напрашиваясь на задания своего нового хозяина и выполняя приказы мучить тех, кто недоволен их новым лидером.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 19,
      },
    },
  ],
  note: {
    text: `Бойтесь молчащего чертёнка. Эти ужасные создания замолкают в двух случаях. Они молчат, пока преследуют добычу, начиная вопить только тогда, когда собираются напасть. Они также прекращают крики в присутствии души, настолько извращённой, грязной и жестокой, что даже чертята замолкают от ужаса.

Вопящие чертята притягиваются к жестокому злу, как к маяку. Вы можете найти этих существ во дворе жестокого дворянина или грязного некроманта. Будьте осторожны, если мелкий главарь бандитов или служитель злого культа получит на службу этих существ. Когда чертята замечают такого злодея, эта душа отмечается тёмными поступками и злой судьбой.`,
    author: `Морденкайнен`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 19,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 13,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 11,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_SYLVAN,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Ловкий летун',
      description: `Провоцированные атаки против ★СУЩЕСТВО★ совершаются с помехой, пока ★он★ летает.`,
    },
    {
      name: 'Непрекращающийся крик',
      description: `Любое существо, кроме ★СУЩЕСТВО★, начинающее ход в пределах 30 футов от одного или более вопящих чертят, которое может их слышать, должно пройти испытание Мудрости СЛ 10. При провале существо становится недееспособным до начала своего следующего хода.`,
    },
    {
      name: 'Чахлые ноги',
      description: `Пока ★СУЩЕСТВО★ не летает, ★он★ получает помеху к броскам атаки и все броски атаки против ★него★ совершаются с перимуществом.`,
    },
  ],
  actionList: [
    {
      name: 'Шипастый хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
