const {CREATURE_TYPE_ELF} = require('./../../creatureTypeIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  PC_RACE_DWARF,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')

module.exports = {
  id: CREATURE_TYPE_ELF,
  nameEn: 'Elf',
  nameAlt: 'Тель’Кессир',
  nameEnAlt: 'Tel’Quessir',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'эльф',
        genitive: 'эльфа',
        dative: 'эльфу',
        accusative: 'эльфа',
        instrumental: 'эльфом',
        prepositional: 'эльфе',
      },
      [GENDER_FEMALE]: {
        nominative: 'эльфийка',
        genitive: 'эльфийки',
        dative: 'эльфийке',
        accusative: 'эльфийку',
        instrumental: 'эльфийкой',
        prepositional: 'эльфийке',
      },
    },
    plural: {
      nominative: 'эльфы',
      genitive: 'эльфов',
      dative: 'эльфам',
      accusative: 'эльфов',
      instrumental: 'эльфами',
      prepositional: 'эльфах',
    },
  },
  note: [
    {
      text: `— Могла ли я думать, что где-то на свете есть подобная красота!.. — тихо выговорила Золотая Луна. Дневной переход выдался нелёгким, зато награда, ожидавшая в конце, превосходила всякое вероятие. С высокого утёса взглядам путников открылся овеянный легендами город Квалиност.
  
  По углам города возносились, подобно сверкающим веретёнам, четыре высоких тонких шпиля, сложенных из белого камня, отделанного серебром. От шпиля к шпилю тянулись невесомые арки. Их некогда выковали искуснейшие мастера-гномы; каждая способна была выдержать вес марширующих армий, но казалось — сядет птица, и они рухнут. Арки обозначали границу города; Квалиност не прятался за стеной, но, наоборот, с любовью распахивал объятия лесу.`,
      author: `Маргарет Уэйс и Трейси Хикмэн, Драконы осенних сумерек`,
    },
    {
      text: `Когда-то мы следовали за Кореллоном, но сбились с этого пути. За всё время существования нашего рода, мы расплачивались за эту ошибку. Справедливо ли это? Пожалуй, что нет, но когда любовь была справедливой? Разве потери и стремления — это то, что решают судьи? Сердцу ведомо, чего желает душа.`,
      author: `Амлариль Лунноцветная, Последняя королева Эвермита`,
    },
    {
      text: `Эльфы часто воспринимаются как мастера магии из-за того, насколько легко они доходят до этого, но самыми могущественными из магов всегда являются те, кто сжигает свои амбиции. Путь к могущественности никогда не бывает гладким. Любой, кто говорит вам иначе, является недалёким или же врагом`,
      author: `Архимаг Морденкайнен`,
    },
    {
      text: `История, мои юные друзья? все потому что ваши жизни быстротечны, словно полёт колибри, но это же не причина говорить, что моя жизнь — это история. Она как переплетение вещей за гранью жизни, не для тех, кто все ещё находится в тени. Ещё я расскажу вам о своей жизни и жизни моего клана, поскольку песнь о нём не звучала вот уже столетиями. Грезя, Народ может узнать всё, что прошло для них и их предшественников. Сейчас, песнями, которые когда-то только исполнялись на торжестве, я могу рассказать вам о кратких мгновениях истории Народа и вашего места среди нас.`,
      author: `Кимбиир Хэволт, Хранитель Знаний дома Хэволт`,
    },
    {
      text: `Если вам понадобится узнать возраст эльфа, то создайте чрезвычайную ситуацию. Молодой эльф будет действовать опрометчиво, эльф средних лет – будет колебаться. Старый эльф просто уйдёт.`,
      author: `Архимаг Морденкайнен`,
    },
    {
      text: `Эльфы Орта – жалкий род, над которыми надругались их прошлые завоеватели, и зачастую находились в бегах и гораздо разделёнными друг от друга. Хоть я и находил доказательства существования великих эльфийских империй в других мирах, эти тоже оказались разрушенными. В столь многих мирах, подъём человечества приводило к падению эльфов. Что же последует, если человечество падёт? Орки. Свидетельство этому уже есть в мире Эберрона, месте, где человечество проиграло войну между собой.`,
      author: `Архимаг Морденкайнен`,
    },
    {
      text: `Эльфы часто воспринимаются как мастера магии из-за того, насколько легко они доходят до этого, но самыми могущественными из магов всегда являются те, кто сжигает свои амбиции. Путь к могущественности никогда не бывает гладким. Любой, кто говорит вам иначе, является недалёким или же врагом.`,
      author: `Архимаг Морденкайнен`,
    },
  ],
  description: [
    {
      text: `Эльфы это волшебный народ, обладающий неземным изяществом, живущий в мире, но не являющийся его частью. Они живут в местах, наполненных воздушной красотой, в глубинах древних лесов или в серебряных жилищах, увенчанных сверкающими шпилями и переливающихся волшебным светом. Там лёгкие дуновения ветра разносят обрывки тихих мелодий и нежные ароматы. Эльфы любят природу и магию, музыку и поэзию, и всё прекрасное, что есть в мире.`,
      source: {
        id: SOURCE_PHB,
        page: 22,
      },
    },
    {
      text: `Равно искусные как в магии, так и в ведении войны, _Тель’Кессир_ или _«Народ»_, как они сами себя называют, пришли в Фаэрун много веков назад, основав огромные могущественные империи задолго до появления людей. Но дни величия эльфийских держав давно в прошлом, и многие эльфы отгородились от мира, удалившись в свои обособленные лесные королевства, или отплыв по Бесследному морю на остров Эвермит.

В отличие от [дварфов](PC_RACE:${PC_RACE_DWARF}), подрасы которых выросли в этом мире, эльфы прибыли уже разделёнными и основывали свои королевства по своему типу. Существа огромных сил, первые эльфы исследовали и заселяли мир, принося с собой золотой век искусства, магии и цивилизации. На вершине своего могущества эльфы исполнили ритуал Высшей Магии, в надежде сотворить идеальную родину. Они преуспели, но заклятие, расколовшее твердь, вызвало ужасный катаклизм и подняло из морских глубин отдалённый остров Эвермит.

После пришли Войны Короны, череда конфликтов между великими эльфийскими королевствами продолжавшаяся три сотни лет. Эти конфликты разорили большую часть мира и привели к нисхождению тёмных эльфов в Подземье.

Подкошенные этими потрясениями, эльфийские империи мало-помалу приходили в упадок, и множество из них предприняло великий Исход в их убежище на Эвермит. В то время, когда эльфы всё больше уходили из мира, прочие расы и цивилизации укоренялись и обретали силу на Фаэруне.

Эльфийский язык, распространённый на просторах Фаэруна — сами эльфы иногда называют его _Истинная Речь_ — использует для письма изящные литеры алфавита Эспруар. Селдруин — древний язык эльфийской Высшей Магии, использовавший алфавит Хамерфэй, в наши дни почти забыт.`,
      source: {
        id: SOURCE_SCAG,
        page: 106,
      },
    },
    {
      header: `Стройные и изящные`,
      text: `Обладая неземным изяществом и тонкими чертами, эльфы кажутся людям и представителям других рас чересчур красивыми. В среднем, они немного ниже людей, их рост колеблется от 5 до 6 футов (от 150 до 185 сантиметров). Они стройнее людей, и весят от 100 до 145 фунтов (от 45 до 65 килограмм). Мужчины и женщины почти одинакового роста, и мужчины весят лишь незначительно больше.

Цвета кожи у эльфов включают в себя все человеческие оттенки, а также цвета с медным, бронзовым и голубовато-белым отливом. Волосыпомимо человеческих цветов могут быть зелёными или синими, а глаза приобретать цвет жидкого золота или серебра. У эльфов не растут волосы на лице, и почти отсутствуют волосы на теле. Они предпочитают элегантную одежду ярких цветов и простые, но красивые украшения.`,
      source: {
        id: SOURCE_PHB,
        page: 22,
      },
    },
    {
      header: `Неподвластный времени взгляд`,
      text: `Эльфы способны жить более 700 лет, что даёт им более широкий взгляд на проблемы, беспокоящие короткоживущие расы. События чаще кажутся им забавными, чем волнующими, и ими чаще движет любопытство, чем жадность. В случае мелких происшествий они чаще проявляют равнодушие и остаются в стороне. В случае же преследования цели, выполнения задания или изучения нового навыка эльфы остаются собранными и целеустремлёнными. Они не торопятся заводить друзей или врагов, а прощают ещё медленнее. На мелкие оскорбления они отвечают пренебрежением, на крупные же — местью.

Подобно молодым ветвям дерева эльфы проявляют гибкость перед лицом опасности. Они верят в дипломатию и предпочитают с помощью компромисса уладить разногласие прежде чем оно перешло в насилие. Они способны отступить перед лицом вторжения вглубь своих лесов, уверенные, что просто смогут подождать, пока захватчики уйдут. Но если придёт нужда, эльфы способны проявить свою воинскую сторону, продемонстрировав владение мечом, луком и стратегией.`,
      source: {
        id: SOURCE_PHB,
        page: 22,
      },
    },
    {
      header: `Скрытые лесные королевства`,
      text: `Большинство эльфов живёт в маленьких лесных деревнях, спрятанных среди деревьев. Эльфы охотятся на дичь, собирают пищу и растят овощи. Их навыки и магия позволяют им прокормить себя без вырубки леса и вспахивания земли. Они талантливы в ремёслах, изготавливают качественную одежду и предметы искусства. Их контакты с другими народами обычно ограничены, но некоторые эльфы всё же преуспели в торговле, выменивая свои товары на металлы, которые эльфы не любят добывать сами.

Эльфы, встречающиеся за пределами родных земель, чаще всего оказываются путешествующими менестрелями, артистами или мудрецами. Людские дворяне соревнуются за услуги эльфа-наставника, способного обучить их детей фехтованию или магии.`,
      source: {
        id: SOURCE_PHB,
        page: 23,
      },
    },
    {
      header: `Исследования и приключения`,
      text: `Эльфы берутся за приключения из страсти к путешествиям. Благодаря большому сроку жизни, они могут посвятить столетия изучению и исследованию. Им не нравится темп человеческого общества, упорядоченный изо дня в день, но полностью меняющийся за десятилетие, и они предпочитают найти себе занятие, позволяющее им часто путешествовать, устанавливая свой собственный темп жизни. Эльфам также нравится оттачивать своё воинское мастерство, или добиваться великой волшебной мощи, и приключения способствуют этому. Некоторые могут присоединиться к повстанцам, борющимся против угнетателей, а другие становятся борцами за моральные ценности.`,
      source: {
        id: SOURCE_PHB,
        page: 22,
      },
    },
    {
      header: `Эльфийские имена`,
      text: `Эльфы считаются детьми, пока они не объявят себя взрослыми, где-то вскоре после сотого дня рождения. До этого времени их называют детским именем.

Достигая зрелости, эльф выбирает себе новое, взрослое имя, хотя те, кто знал его под детским именем, могут продолжать пользоваться им. Имя каждого взрослого эльфа уникально, хотя может отражать имена уважаемых личностей или членов семьи. Мужские и женские имена различаются лишь незначительно, и чёткой границы тут нет. Также каждый эльф носит фамилию, обычно это сочетание нескольких эльфийских слов. Некоторые эльфы, путешествующие среди людей, переводят фамилию на Всеобщий, другие сохраняют эльфийскую версию.

# Детские имена

Ара, Брин, Валь, Дель, Иннил, Лаэль, Мелла, Наиль, Наэрис, Раэль, Ринн, Сай, Силлин, Тиа, Фанн, Фаэн, Эрин

# Мужские взрослые имена

Адран, Арамиль, Араннис, Ауст, Аэлар, Бейро, Берриан, Варис, Галинндан, Ивеллиос, Иммераль, Каррик, Куарион, Лаусиан, Миндартис, Паэлиас, Перен, Риардон, Ролен, Совелисс, Тамиорн, Таривол, Терен, Хадарай, Химо, Хэйян, Эниалис, Эрдан, Эреван

# Женские взрослые имена

Адрие, Альтеа, Анастрианна, Андрасте, Антинуа, Бетринна, Бирель, Вадания, Валанте, Джеленетт, Друсилиа, Йелениа, Каэлинн, Квеленна, Квиласи, Кейлет, Ксанафия, Лешанна, Лиа, Миали, Мэриэль, Найвара, Сариэль, Силакви, Теирастра, Тиа, Фелосиаль, Шава, Шанайра, Энна

# Фамилии (перевод на Всеобщий)

Амакиир (Сверкающий Цветок), Амастасия (Звёздный Цветок), Галанодель (Лунный Шёпот), Ильфелкиир (Сверкающий Бутон), Ксилосент (Золотой Лепесток), Лиадон (Серебряный Лист), Найло (Ночной Бриз), Сианодель (Лунный Ручей), Холимион (Алмазная Роса)`,
      source: {
        id: SOURCE_PHB,
        page: 23,
      },
    },
    {
      header: `Высокомерные, но любезные`,
      text: `Хотя эльфы могут быть высокомерными, они обычно любезны даже с теми, кто не оправдал их высоких ожиданий. Как правило, это все не-эльфы. Но всё же, они способны найти что-то хорошее почти в каждом.

# О [дварфах](PC_RACE:${PC_RACE_DWARF})

_«Дварфы скучные, неотёсанные болваны. Но свой недостаток чувства юмора, утончённости и манер они способны компенсировать отвагой. И смею заметить, изделия их лучших кузнецов способны сравниться с эльфийскими»._

# О [полуросликах](PC_RACE:${PC_RACE_HALFLING})

_«Полурослики — народ простых удовольствий, и это не повод их презирать. Они хороший народ, заботятся друг о друге и ухаживают за своими садами, и они доказали, что они намного крепче, чем это может показаться, когда возникает такая необходимость»._

# О [людях](PC_RACE:${PC_RACE_HUMAN})

_«Вся эта поспешность, их амбиции и стремление совершить что-нибудь, прежде чем окончатся их краткие жизни — всё это кажется иногда настолько бесполезным. Но взгляните на то, чего они добились, и Вы начнёте ценить их достижения. Если бы только они могли немного сбавить обороты и приобрести хоть толику изящества»._`,
      source: {
        id: SOURCE_PHB,
        page: 24,
      },
    },
    {
      header: `Редкие эльфийские подрасы`,
      text: `Представители некоторых эльфийских наций, когда-то прибывших на Фаэрун, сейчас настолько редки, что впору считать их легендой, и рассказы о них часто принимают за вымысел.

# Авариэль

_Ариль’Тель’Кессир_ или крылатые эльфы были одними из первых, кто заселил Фаэрун. Они обладали оперёнными крыльями и были способны летать. Давние конфликты с драконами почти уничтожили их расу, и в наши дни авариэль встречаются очень редко — если вообще встречаются.

# Литари

_Ли’Тель’Кессир_ обладают умением превращаться в волков. В отличие от вервольфов, литари не имеют гибридной формы и проклятье оборотней их не коснулось. Они предпочитают волчью форму и ведут скрытный образ жизни. Объединяясь в небольшие стаи, они живут вольно и свободно в самых глухих уголках мира.

# Морские эльфы

_Алу’Тель’Кессир_ («водные эльфы») — водная подраса эльфов, населяющих океанские глубины. Больше всего их у берегов Фаэруна и острова Эвермит. Вдоль линии Побережья Меча можно встретить сплочённые кочевые кланы морских эльфов, в других же морских районах они основывали свои королевства на залитом солнцем мелководье. На протяжении всей их истории морские эльфы были в состоянии войны с сахуагинами.

# Звёздные эльфы

_Руар’Тель’Кессир_ или звёздные эльфы внешне похожи на высоких лунных эльфов. Они обитают в экстрапланарном убежище Силдеюр, неподалёку от Страны Фей. Конфликт с Нилшаи — расой червеобразных колдунов с Эфирного Плана, вынудил часть звёздных эльфов оставить их дом и явиться на Фаэрун.

# Дикие эльфы

Многие эльфы считают _Си’Тель’Кессир_ наиболее странными своими сородичами, отринувшими или утратившими большую часть их древней культуры.`,
      source: {
        id: SOURCE_SCAG,
        page: 107,
      },
    },
  ],
}
