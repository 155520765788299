const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_SORROW_SWORN} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_SORROW_SWORN,
  nameEn: 'Sorrow Sworn',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'горезванный',
      genitive: 'горезванного',
      dative: 'горезванному',
      accusative: 'горезванного',
      instrumental: 'горезванным',
      prepositional: 'горезванном',
    },
    plural: {
      nominative: 'горезванные',
      genitive: 'горезванных',
      dative: 'горезванным',
      accusative: 'горезванных',
      instrumental: 'горезванными',
      prepositional: 'горезванных',
    },
  },
}
