const FILTER_TYPE_GROUP = 'group'
const FILTER_TYPE_INPUT = 'input'
const FILTER_TYPE_SELECT = 'select'

module.exports = [
  FILTER_TYPE_GROUP,
  FILTER_TYPE_INPUT,
  FILTER_TYPE_SELECT,
]

module.exports.FILTER_TYPE_GROUP = FILTER_TYPE_GROUP
module.exports.FILTER_TYPE_INPUT = FILTER_TYPE_INPUT
module.exports.FILTER_TYPE_SELECT = FILTER_TYPE_SELECT
