const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_0,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ANY_RACE,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_ANY,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_ANY,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    CREATURE_COMMONER,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Обыватель',
  nameAlt: 'Простолюдин',
  nameEn: 'Commoner',
  id: CREATURE_COMMONER,
  description: 'Обыватели включают в себя крестьян, крепостных, рабов, слуг, пилигримов, торговцев, ремесленников и отшельников.',
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 348,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  hp: {
    diceCount: 1,
  },
  armor: 10,
  cr: CR_0,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 10,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  actionList: [
    {
      name: 'Дубина',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
