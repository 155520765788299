const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_XGTE,
  SOURCE_ERRATA_XGTE,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {SPELL_HEALING_SPIRIT} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_HEALING_SPIRIT,
  name: 'Исцеляющий дух',
  nameEn: 'Healing Spirit',
  description: `Вы вызываете дух природы, чтобы унять боль раненых. Нематериальный дух появляется в пространстве, представляющее собой 5-футовый куб, которое Вы видите в радиусе действия. Дух выглядит как прозрачный зверь или фея (на Ваш выбор).

Пока заклинание активно, всякий раз, когда Вы или существо, которое Вы видите, впервые за ход попадают в пространство духа или начинают в нём свой ход, Вы можете заставить духа восстановить 1к6 хитов этому существу (никаких действий не требуется). Дух не может исцелять конструктов или нежить.

Дух может исцелять количество раз, равное 1 + модификатор Вашей заклинательной характеристики (минимум дважды). После этого числа исцелений дух исчезает.

Бонусным действием в свой ход Вы можете перемещать дух в видимое Вами в пределах 30 футов пространство.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку заклинания 3 круга или выше, исцеление увеличивается на 1к6 для каждого уровня ячейки выше 2.`,
  lvl: 2,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  castTime: {timeId: TIME_BONUS_ACTION, count: 1},
  needConcentration: true,
  source: [
    {
      id: SOURCE_XGTE,
      page: 154,
    },
    {
      id: SOURCE_ERRATA_XGTE,
      page: 1,
    },
  ],
}
