const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_GIANT_STRIDER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {giantStriderDescription} = require('./../../../../textCommonParts')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гигантский ходун',
  nameEn: 'Giant Strider',
  id: CREATURE_GIANT_STRIDER,
  description: giantStriderDescription,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 177,
    },
    {
      id: SOURCE_TOA,
      page: 219,
    },
  ],
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 14,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Впитывание огня',
      description: `Когда гигантский ходун должен получить урон огнем, он не получает урона, а восстанавливает хиты в количестве, равном половине этого урона огнем.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Огненный взрыв',
      restore: 5,
      description: `Гигантский ходун запускает сгусток огня в точку, которую может видеть не далее 60 футов от себя. Каждое существо в сфере радиусом 10 футов с центром в этой точке должно пройти испытание Ловкости СЛ 12 и получить 14 (4к6) урона при провале или половину этого урона при успехе. Огонь огибает углы и поджигает возгораемые объекты в этой области, которые ниникто не несёт и не носит.`,
    },
  ],
}
