module.exports = [
  require('./spirit_shroud'),
  require('./summon_aberration'),
  require('./summon_beast'),
  require('./summon_celestial'),
  require('./summon_construct'),
  require('./summon_elemental'),
  require('./summon_fey'),
  require('./summon_fiend'),
  require('./summon_shadowspawn'),
  require('./summon_undead'),
]
