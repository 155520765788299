const listToCollectionById = require('./../utils/listToCollectionById')

const TIME_ACTION = 'action'
const TIME_BONUS_ACTION = 'bonus_action'
const TIME_DAY = 'day'
const TIME_HOUR = 'hour'
const TIME_INSTANT = 'instant'
const TIME_INFINITY = Infinity
const TIME_MINUTE = 'minute'
const TIME_REACTION = 'reaction'
const TIME_ROUND = 'round'

const timePeriodList = [
  {
    id: TIME_INSTANT,
    weight: 1,
    name: 'мгновенно',
  },
  {
    id: TIME_REACTION,
    weight: 2,
    name: {
      singular: {
        nominative: 'реакция',
        genitive: 'реакции',
        accusative: 'реакцию',
      },
      plural: {
        nominative: 'реакции',
        genitive: 'реакций',
        accusative: 'реакции',
      },
    },
  },
  {
    id: TIME_BONUS_ACTION,
    weight: 3,
    name: {
      singular: {
        nominative: 'бонусное действие',
        genitive: 'бонусного действия',
        accusative: 'бонусное действие',
      },
      dual: {
        nominative: 'бонусных действия',
        genitive: 'бонусных действий',
        accusative: 'бонусных действия',
      },
      plural: {
        nominative: 'бонусные действия',
        genitive: 'бонусных действий',
        accusative: 'бонусные действия',
      },
    },
  },
  {
    id: TIME_ACTION,
    weight: 4,
    name: {
      singular: {
        nominative: 'действие',
        genitive: 'действия',
        accusative: 'действие',
      },
      plural: {
        nominative: 'действия',
        genitive: 'действий',
        accusative: 'действия',
      },
    },
  },
  {
    id: TIME_ROUND,
    weight: 5,
    name: {
      singular: {
        nominative: 'раунд',
        genitive: 'раунда',
        accusative: 'раунд',
      },
      dual: {
        nominative: 'раунда',
        genitive: 'раундов',
        accusative: 'раунда',
      },
      plural: {
        nominative: 'раунды',
        genitive: 'раундов',
        accusative: 'раунды',
      },
    },
  },
  {
    id: TIME_MINUTE,
    weight: 6,
    name: {
      singular: {
        nominative: 'минута',
        genitive: 'минуты',
        accusative: 'минуту',
      },
      plural: {
        nominative: 'минуты',
        genitive: 'минут',
        accusative: 'минуты',
      },
    },
  },
  {
    id: TIME_HOUR,
    weight: 7,
    name: {
      singular: {
        nominative: 'час',
        genitive: 'часа',
        accusative: 'час',
      },
      dual: {
        nominative: 'часа',
        genitive: 'часов',
        accusative: 'часа',
      },
      plural: {
        nominative: 'часы',
        genitive: 'часов',
        accusative: 'часы',
      },
    },
  },
  {
    id: TIME_DAY,
    weight: 8,
    name: {
      singular: {
        nominative: 'день',
        genitive: 'дня',
        accusative: 'день',
      },
      dual: {
        nominative: 'дня',
        genitive: 'дней',
        accusative: 'дня',
      },
      plural: {
        nominative: 'дни',
        genitive: 'дней',
        accusative: 'дни',
      },
    },
  },
  {
    id: TIME_INFINITY,
    weight: 9,
    name: 'Постоянно',
  },
]
  .map(
    timePeriod => ({
      ...timePeriod,
      name: typeof timePeriod.name === 'string'
        ? {
          singular: {
            nominative: timePeriod.name,
            genitive: timePeriod.name,
            accusative: timePeriod.name,
          },
          dual: {
            nominative: timePeriod.name,
            genitive: timePeriod.name,
            accusative: timePeriod.name,
          },
          plural: {
            nominative: timePeriod.name,
            genitive: timePeriod.name,
            accusative: timePeriod.name,
          },
        }
        : 'dual' in timePeriod.name
          ? timePeriod.name
          : {
            ...timePeriod.name,
            dual: timePeriod.name.plural,
          }
    })
  )

module.exports = timePeriodList

module.exports.timePeriodCollection = listToCollectionById(timePeriodList)

module.exports.TIME_ACTION = TIME_ACTION
module.exports.TIME_BONUS_ACTION = TIME_BONUS_ACTION
module.exports.TIME_DAY = TIME_DAY
module.exports.TIME_HOUR = TIME_HOUR
module.exports.TIME_INSTANT = TIME_INSTANT
module.exports.TIME_INFINITY = TIME_INFINITY
module.exports.TIME_MINUTE = TIME_MINUTE
module.exports.TIME_REACTION = TIME_REACTION
module.exports.TIME_ROUND = TIME_ROUND
