const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SPELL_SUMMON_CELESTIAL,
} = require('./../../../../spellIdList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CELESTIAL,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_CELESTIAL,
  LANG_ITS_SUMMONER,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_RADIANT,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_AVENGER_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух небожителя-мстителя',
  nameEn: 'Avenger Spirit',
  id: CREATURE_AVENGER_SPIRIT,
  createdBySpellId: SPELL_SUMMON_CELESTIAL,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: CREATURE_TYPE_CELESTIAL,
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TCoE,
    page: 158,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `40 + 10-кратный круг заклинания выше 5-го`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  resistanceList: [
    DAMAGE_RADIANT,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_CELESTIAL,
    {
      id: LANG_ITS_SUMMONER,
      onlyUnderstands: true,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Сияющий лук',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: {
          normal: 150,
          max: 600,
        },
        hit: {
          type: DAMAGE_RADIANT,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
          comment: ` + круг заклинания`,
        },
      },
    },
    {
      name: 'Целебное касание',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ касается другого существа. Цель магически восстанавливает 2к8 + круг заклинания хитов.`,
    },
  ],
}
