const {
  CREATURE_GUARD_DRAKE,
  CREATURE_GUARD_DRAKE_GREEN,
} = require('./../../../../creatureIdList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {DAMAGE_POISON} = require('./../../../../damageTypeList')
const {SPEED_SWIM} = require('./../../../../speedList')

module.exports = {
  name: 'Зелёный сторожевой дрейк',
  nameEn: 'Green Guard Drake',
  id: CREATURE_GUARD_DRAKE_GREEN,
  parentId: CREATURE_GUARD_DRAKE,
  extendPropCollection: {
    speed: {
      [SPEED_SWIM]: 30,
    },
    resistanceList: [
      DAMAGE_POISON,
    ],
    featureList: [
      ABILITY_AMPHIBIOUS,
    ],
  },
}
