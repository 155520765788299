const upLetter = require('./../../../../../utils/upLetter')
const measure = require('./../../../../measure')
const {
  MEASURE_GALLON,
  MEASURE_LITER,
  MEASURE_OUNCE,
  MEASURE_PINT,
  MEASURE_QUART,
} = require('./../../../../measure')

const measureList = [
  MEASURE_OUNCE,
  MEASURE_PINT,
  MEASURE_QUART,
  MEASURE_LITER,
  MEASURE_GALLON,
]

module.exports = [
  {
    name: `Вино`,
    maxVolume: measure[MEASURE_GALLON].volume, // 1 галлон
  },
  {
    name: `Вода, пресная`,
    maxVolume: 8 * measure[MEASURE_GALLON].volume, // 8 галлонов
  },
  {
    name: `Вода, солёная`,
    maxVolume: 12 * measure[MEASURE_GALLON].volume, // 12 галлонов
  },
  {
    name: `Кислота`,
    maxVolume: 8 * measure[MEASURE_OUNCE].volume, // 8 унций
  },
  {
    name: `Майонез`,
    maxVolume: 2 * measure[MEASURE_GALLON].volume, // 2 галлона
  },
  {
    name: `Масло`,
    maxVolume: measure[MEASURE_QUART].volume, // 1 кварта
  },
  {
    name: `Мёд`,
    maxVolume: measure[MEASURE_GALLON].volume, // 1 галлон
  },
  {
    name: `Пиво`,
    maxVolume: 4 * measure[MEASURE_GALLON].volume, // 4 галлона
  },
  {
    name: `Уксус`,
    maxVolume: 2 * measure[MEASURE_GALLON].volume, // 2 галлона
  },
  {
    name: `Яд`,
    maxVolume: 0.5 * measure[MEASURE_OUNCE].volume, // ½ унции
  },
].reduce(
  (text, { name, maxVolume }) => {
    const volumeCells = measureList.reduce(
      (row, measureId) => {
        const { volume } = measure[measureId]
        const count = Math.round(maxVolume * 100 / volume) / 100
        const countText = count === 0.5
          ? `½`
          : count === 0.25
            ? `¼`
            : count

        return `${row}${countText}|`
      },
      ``,
    )

    return `${text}\n|${name}|${volumeCells}`
  },
  `
| Жидкость     |${measureList.reduce((text, id) => `${text}${upLetter(measure[id].name.multiple)}|`, '')}
|--------------|--------------------|${measureList.reduce((t) => `${t}-|`, '')}`,
)
