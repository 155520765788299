module.exports = [
  require('./list/abyssal_chicken'),
  require('./list/bel'),
  require('./list/black_gauntlet_of_bane'),
  require('./list/bone_whelk'),
  require('./list/crokektoeck'),
  require('./list/death_s_head_of_bhaal'),
  require('./list/fiendish_flesh_golem'),
  require('./list/fist_of_bane'),
  require('./list/hellwasp'),
  require('./list/hollyphant'),
  require('./list/iron_consul'),
  require('./list/kostchtchie'),
  require('./list/master_of_souls'),
  require('./list/necromite_of_myrkul'),
  require('./list/night_blade'),
  require('./list/reaper_of_bhaal'),
  require('./list/skull_lasher_of_myrkul'),
]
