const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_STAR_SPAWN,
} = require('./../../../../creatureTypeIdList')
const {
  starSpawnDescription,
  starSpawnNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_STAR_SPAWN_GRUE} = require('./../../../../creatureIdList')
const {LANG_DEEP_SPEECH} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Чудище звёздных порождений',
  nameEn: 'Star Spawn Grue',
  id: CREATURE_STAR_SPAWN_GRUE,
  description: [
    `Клыкастое, безгубое, вечно ухмыляющееся и безумно пялящееся чудище скачет вокруг на тонких ногах и длинных руках. Щетина и шипы растут из странных пятен на его бледной коже, а длинные пальцы заканчиваются грязными обломанными ногтями. Чудища являются слабейшими из звёздных порождений. Множество корчащихся, карабкающихся чудищ обычно сопровождает более сильное порождение. Их постоянные визг и крик испускают психическую энергию, которая разрушает мыслительный процесс других существ. Разумные существа испытывают эпилепсию, галлюцинацию, дезориентацию, и волны безысходности, когда оказываются рядом с кучкой чудищ порождений звёзд.`,
    ...starSpawnDescription,
  ],
  note: starSpawnNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_STAR_SPAWN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 222,
  },
  armor: 11,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 10,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 6,
  },
  immunityList: [
    DAMAGE_PSYCHIC,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Аура безумия',
      description: `Существа в пределах 20 фт. от чудища, которые не являются аберрациями, с помехой проходят испытания и броски атаки против существ, отличных от чудища звёздных порождений.`,
    },
  ],
  actionList: [
    {
      name: 'Сбивающий с толку укус',
      description: `Цель должна пройти испытание Мудрости СЛ 10, иначе броски атаки против неё будут совершаться с преимуществом до начала следующего хода чудища.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
  ],
}
