const {LANG_DWARVISH} = require('./../../languageIdList')
const {LANG_TYPE_STANDARD} = require('./../../languageTypeList')
const {PC_RACE_DWARF} = require('./../../pcRaceIdList')
const {
  ALPHABET_DAVEK,
  ALPHABET_DETHEK,
} = require('./../../alphabetList')
const {
  SOURCE_MGZN_DRGN_281,
  SOURCE_DND3_5_PHB,
  SOURCE_DND3_5_RoS,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: LANG_DWARVISH,
  alphabetId: [
    ALPHABET_DETHEK,
    ALPHABET_DAVEK,
  ],
  type: LANG_TYPE_STANDARD,
  name: {
    nominative: 'Детек',
    genitive: 'Детека',
    instrumental: 'Детеком',
    prepositional: 'Детеке',
  },
  name5eOfficial: {
    nominative: 'Дварфийский',
    genitive: 'Дварфийского',
    instrumental: 'Дварфийским',
    prepositional: 'Дварфийском',
  },
  nameAlt: 'Дварфийский',
  nameEn: 'Dethek',
  nameEnAlt: 'Dwarvish',
  dictionary: [
    {
      list: [
        ['безумие', 'грэназандин'],
        ['битва', 'гед'],
        ['бог жизни', 'Морадин'],
        ['бог отец битвы', 'Клангеддин'],
        ['бог', 'дин'],
        ['броня болтовни', 'ремог'],
        ['видеть', 'кан'],
        ['власть', 'нарн'],
        ['выпечка', 'сэнклар'],
        ['гром', 'самгед'],
        ['двигающийся контейнер', 'аргос'],
        ['день', 'глос'],
        ['дневной бог', 'глосдин'],
        ['дыра от топора', 'нирборн'],
        ['дыра', 'борн'],
        ['жизнь', 'мора'],
        ['замешательство', 'мосрэммот'],
        ['зарубить', 'зарн'],
        ['змея', 'гарн'],
        ['золото', 'аур'],
        ['золотое серебро', 'ауранг'],
        ['идти', 'гос'],
        ['контейнер', 'ар'],
        ['круг', 'нэр'],
        ['ложь', 'аурто'],
        ['ложь', 'то'],
        ['луна', 'азандин'],
        ['лунный яд', 'грэназандин'],
        ['меч света', 'дарнус'],
        ['меч', 'нус'],
        ['молния', 'дарнус'],
        ['мощь', 'нарн'],
        ['не золото', 'аурто'],
        ['не золото', 'аурто'],
        ['не понимать', 'мосрэммот'],
        ['небесная битва', 'самгед'],
        ['небо', 'сам'],
        ['недалеко', 'ос'],
        ['нет', 'то'],
        ['ночной бог', 'азандин'],
        ['ночь', 'азан'],
        ['обида', 'роснак'],
        ['обман', 'аурто'],
        ['одобрять', 'лэнд'],
        ['острая выпечка', 'сэнклардэг'],
        ['отверстие', 'борн'],
        ['отец', 'кланг'],
        ['платина', 'ауранг'],
        ['повозка', 'аргос'],
        ['понимать', 'мот'],
        ['понимать', 'рэммот'],
        ['приятный', 'сэн'],
        ['проклинать', 'мун'],
        ['проклятье', 'мун'],
        ['пронзать', 'мот'],
        ['рана', 'нирборн'],
        ['рядом', 'ос'],
        ['свет', 'дар'],
        ['серебро', 'анг'],
        ['сладкий', 'сэн'],
        ['слово', 'нак'],
        ['сокровище', 'сэнклардэг'],
        ['солнце', 'глосдин'],
        ['тонкокожий', 'ремог'],
        ['топор', 'нир'],
        ['убить', 'зарн'],
        ['яд', 'грэн'],
      ],
      source: {
        id: SOURCE_MGZN_DRGN_281,
        page: 10,
      },
    },
    {
      list: [['идиоты топора', 'калджарх']], // axe idiot // kuldjargh
      source: {
        id: SOURCE_SCAG,
        page: 122,
      },
    },
    {
      list: [
        ['битва', 'вер-'],
        ['битва', 'вера-'],
        ['благородный', 'то-'],
        ['благородный', 'тор-'],
        ['блеск', 'глор-'],
        ['блеск', 'глори-'],
        ['блестящий', 'глор-'],
        ['блестящий', 'глори-'],
        ['божественный', 'хел-'],
        ['большой', 'о-'],
        ['большой', 'ол-'],
        ['великан', 'ан-'],
        ['великий', 'боф-'],
        ['верный', 'то-'],
        ['верный', 'тор-'],
        ['война', 'вер-'],
        ['война', 'вера-'],
        ['волк', 'дур-'],
        ['волк', 'дури-'],
        ['враг', 'ан-'],
        ['вредитель', 'рей-'],
        ['гнев', 'б-'],
        ['гнев', 'бал-'],
        ['гоблин', 'ел-'],
        ['гоблин', 'эл-'],
        ['гордый', 'кил-'],
        ['горящий', 'дар-'],
        ['горящий', 'даре-'],
        ['доброжелательный', 'гим-'],
        ['дракон', 'вал-'],
        ['древний', 'боф-'],
        ['душа', 'тор-'],
        ['душа', 'торе-'],
        ['железо', 'вур-'],
        ['жирный', 'о-'],
        ['жирный', 'ол-'],
        ['забытый', 'глан-'],
        ['забытый', 'глан-'],
        ['злость', 'б-'],
        ['злость', 'бал-'],
        ['золото', 'ма-'],
        ['золото', 'мар-'],
        ['золотой', 'ма-'],
        ['золотой', 'мар-'],
        ['из крепости', 'фар-'],
        ['клятва', 'т-'],
        ['клятва', 'тер-'],
        ['клятвенный', 'т-'],
        ['клятвенный', 'тер-'],
        ['красный', 'дор-'],
        ['красный', 'дора-'],
        ['крепкий', 'д-'],
        ['крепкий', 'дал-'],
        ['кровь', 'ар-'],
        ['кровь', 'ара-'],
        ['кузница', 'а-'],
        ['кузница', 'ал-'],
        ['лояльный', 'то-'],
        ['лояльный', 'тор-'],
        ['магический', 'вал-'],
        ['магия', 'вал-'],
        ['медведь', 'гар-'],
        ['медвежий', 'гар-'],
        ['меч', 'дол-'],
        ['мифрил', 'нор-'],
        ['мифрил', 'нора-'],
        ['могучий', 'бар-'],
        ['могучий', 'бари-'],
        ['могучий', 'кил-'],
        ['мудрый', 'ов-'],
        ['огонь', 'баз-'],
        ['огонь', 'гил-'],
        ['орк', 'яр-'],
        ['оружие', 'дол-'],
        ['первый', 'бел-'],
        ['пламенный', 'баз-'],
        ['потерянный', 'глан-'],
        ['почётный', 'нал-'],
        ['противник', 'ел-'],
        ['противник', 'эл-'],
        ['пылкий', 'гил-'],
        ['радушный', 'гим-'],
        ['руна', 'юр-'],
        ['самоцвет', 'ор-'],
        ['самоцвет', 'ори-'],
        ['свирепый', 'дв-'],
        ['свирепый', 'дво-'],
        ['сгоревший', 'дар-'],
        ['сгоревший', 'даре-'],
        ['секрет', 'бол-'],
        ['сердечный', 'хар-'],
        ['серебро', 'глор-'],
        ['серебро', 'глори-'],
        ['сила', 'фал-'],
        ['сила', 'фалл-'],
        ['сильнейший', 'фал-'],
        ['сильнейший', 'фалл-'],
        ['сильный', 'фал-'],
        ['сильный', 'фалл-'],
        ['скала', 'аз-'],
        ['скрытый', 'бол-'],
        ['славный', 'нал-'],
        ['смелый', 'мор-'],
        ['смелый', 'мори-'],
        ['смерть', 'ур-'],
        ['смерть', 'урни-'],
        ['сокрушительный', 'бар-'],
        ['сокрушительный', 'бари-'],
        ['сталь', 'дел-'],
        ['старейшина', 'ер-'],
        ['старейшина', 'ери-'],
        ['старейшина', 'эр-'],
        ['старейшина', 'эри-'],
        ['старый', 'ер-'],
        ['старый', 'ери-'],
        ['старый', 'эр-'],
        ['старый', 'эри-'],
        ['стойкий', 'хар-'],
        ['туннель', 'вон-'],
        ['тьма', 'двер-'],
        ['тьма', 'дуер-'],
        ['тяжёлый', 'д-'],
        ['тяжёлый', 'дал-'],
        ['тёмный', 'двер-'],
        ['тёмный', 'дуер-'],
        ['уверенный', 'нур-'],
        ['уверенный', 'нура-'],
        ['уродливый', 'яр-'],
        ['устойчивый', 'нур-'],
        ['устойчивый', 'нура-'],
        ['хитрый', 'ов-'],
        ['храбрый', 'мор-'],
        ['храбрый', 'мори-'],
        ['череп', 'ур-'],
        ['череп', 'урни-'],
        ['чёрный', 'тр-'],
        ['чёрный', 'тра-'],
      ],
      source: {
        id: SOURCE_DND3_5_RoS,
        page: 25,
      },
    },
    {
      list: [
        ['берсерк', '-урт'],
        ['боец', '-айн'],
        ['боец', '-арн'],
        ['борода', '-или'],
        ['борода', '-ли'],
        ['брат', '-и'],
        ['брат', '-ик'],
        ['броня', '-ринг'],
        ['бушующий в бою', '-урт'],
        ['вечный', '-грим'],
        ['воин', '-ел'],
        ['вор', '-гар'],
        ['вор', '-гат'],
        ['всадник', '-сан'],
        ['гора', '-ент'],
        ['гордость', '-или'],
        ['гордость', '-ли'],
        ['грабитель', '-лон'],
        ['грабитель', '-он'],
        ['грабёж', '-лон'],
        ['грабёж', '-он'],
        ['гробница', '-о'],
        ['гробница', '-оак'],
        ['даритель', '-инит'],
        ['даритель', '-ир'],
        ['дающий', '-инит'],
        ['дающий', '-ир'],
        ['дварф', '-ин'],
        ['дварф', '-рин'],
        ['дварфы', '-ин'],
        ['дварфы', '-рин'],
        ['дворянин', '-унт'],
        ['дворянин', '-ут'],
        ['дорожный знак', '-тур'],
        ['доспех', '-ринг'],
        ['друг', '-лонд'],
        ['дух', '-рал'],
        ['живот', '-рик'],
        ['загадка', '-ор'],
        ['загадка', '-рор'],
        ['законодатель', '-орик'],
        ['законодатель', '-орил'],
        ['земля', '-тен'],
        ['земляной', '-тен'],
        ['знать', '-унт'],
        ['знать', '-ут'],
        ['изгнанник', '-вар'],
        ['изгой', '-вар'],
        ['искатель', '-кас'],
        ['кабан', '-гук'],
        ['кабан', '-гур'],
        ['каменщик', '-ган'],
        ['камень', '-ген'],
        ['кирка', '-тал'],
        ['кожа', '-ринг'],
        ['копьё', '-рим'],
        ['король', '-им'],
        ['король', '-рим'],
        ['крушитель', '-рак'],
        ['крушитель', '-тал'],
        ['кузнец', '-дин'],
        ['кулак', '-айн'],
        ['кулак', '-арн'],
        ['мастер загадок', '-ор'],
        ['мастер загадок', '-рор'],
        ['мастер', '-рур'],
        ['мастер', '-ур'],
        ['мастеровой', '-рид'],
        ['могила', '-о'],
        ['могила', '-оак'],
        ['молот', '-рак'],
        ['монолит', '-ген'],
        ['надзиратель', '-бин'],
        ['надзиратель', '-бир'],
        ['наковальня', '-иас'],
        ['налёт', '-лон'],
        ['налёт', '-он'],
        ['налётчик', '-лон'],
        ['налётчик', '-он'],
        ['народ', '-ин'],
        ['народ', '-рин'],
        ['отец', '-ерл'],
        ['охотник', '-тур'],
        ['перчатка', '-бер'],
        ['перчатка', '-бере'],
        ['пить', '-дал'],
        ['победа', '-тик'],
        ['посланник', '-вал'],
        ['посыльный', '-вал'],
        ['пронзатель', '-рим'],
        ['прочный', '-грим'],
        ['путеводитель', '-тур'],
        ['пьющий', '-дал'],
        ['разведчик', '-кас'],
        ['ребёнок', '-тен'],
        ['рейд', '-лон'],
        ['рейд', '-он'],
        ['рейдер', '-лон'],
        ['рейдер', '-он'],
        ['ремесленник', '-рид'],
        ['ремесленник', '-рур'],
        ['ремесленник', '-ур'],
        ['родня', '-стер'],
        ['родня', '-стер'],
        ['родня', '-стили'],
        ['родня', '-стили'],
        ['рубака', '-ак'],
        ['рука', '-бер'],
        ['рука', '-бере'],
        ['сердце', '-рал'],
        ['скакун', '-гук'],
        ['скакун', '-гур'],
        ['смотритель', '-бин'],
        ['смотритель', '-бир'],
        ['союзник', '-и'],
        ['союзник', '-ик'],
        ['страж', '-аук'],
        ['страж', '-ок'],
        ['стражник', '-аук'],
        ['стражник', '-ок'],
        ['судья', '-орик'],
        ['судья', '-орил'],
        ['сын', '-тен'],
        ['таящийся', '-гар'],
        ['таящийся', '-гат'],
        ['топор', '-ак'],
        ['туловище', '-рик'],
        ['убийца', '-айм'],
        ['убийца', '-анд'],
        ['ударник', '-айн'],
        ['ударник', '-арн'],
        ['хранитель', '-бин'],
        ['хранитель', '-бир'],
        ['чемпион', '-тик'],
        ['шахта', '-дак'],
        ['шахта', '-дек'],
        ['шахтёр', '-дек'],
        ['шахтёр`', '-дак'],
        ['шкура', '-ринг'],
        ['щит', '-гал'],
        ['эль', '-дал'],
      ],
      source: {
        id: SOURCE_DND3_5_RoS,
        page: 26,
      },
    },
    {
      list: [
        ['белый', '-он'],
        ['белый', '-она'],
        ['благо', '-ала'],
        ['благо', '-ла'],
        ['благодать', '-винн'],
        ['благословение', '-ала'],
        ['благословение', '-ла'],
        ['близнец', '-или'],
        ['близнец', '-ли'],
        ['богатство', '-дис'],
        ['варево', '-ола'],
        ['вера', '-бена'],
        ['вера', '-бера'],
        ['воздух', '-ида'],
        ['воин', '-гунн'],
        ['волосы', '-бо'],
        ['воля', '-бена'],
        ['воля', '-бера'],
        ['героиня', '-трид'],
        ['глаз', '-ана'],
        ['глаза', '-ана'],
        ['голос', '-хильд'],
        ['госпожа', '-жа'],
        ['гость', '-я'],
        ['дар', '-дис'],
        ['дворянка', '-унн'],
        ['девушка', '-дред'],
        ['дерево', '-ре'],
        ['дерево', '-рра'],
        ['дитя', '-грет'],
        ['добродетель', '-серд'],
        ['доброта', '-илд'],
        ['достоинство', '-серд'],
        ['дочь', '-грет'],
        ['драгоценность', '-ифф'],
        ['дыхание', '-ида'],
        ['жена', '-алсия'],
        ['живой', '-рен'],
        ['жизнь', '-рен'],
        ['жрица', '-иа'],
        ['жрица', '-ия'],
        ['защитник', '-лидд'],
        ['защитник', '-оа'],
        ['защитник', '-ора'],
        ['знать', '-унн'],
        ['знахарка', '-брин'],
        ['искусство', '-ина'],
        ['кольцо', '-астр'],
        ['конвой', '-тра'],
        ['корень', '-ре'],
        ['корень', '-рра'],
        ['королева', '-идд'],
        ['красота', '-ифф'],
        ['лекарь', '-кара'],
        ['лиса', '-из'],
        ['луна', '-ша'],
        ['луна', '-шар'],
        ['любовь', '-дрис'],
        ['мастерица', '-ифра'],
        ['матриарх', '-есс'],
        ['матриарх', '-иесс'],
        ['матрона', '-тра'],
        ['мать', '-есс'],
        ['мать', '-иесс'],
        ['милость', '-винн'],
        ['мудрая женщина', '-брин'],
        ['мягкий', '-оа'],
        ['мягкий', '-ора'],
        ['навык', '-если'],
        ['невеста', '-бо'],
        ['нежный', '-оа'],
        ['нежный', '-ора'],
        ['носитель', '-ани'],
        ['обещание', '-ип'],
        ['обещание', '-иппа'],
        ['опора', '-ани'],
        ['партнёр', '-алсия'],
        ['певец', '-лин'],
        ['песня', '-лин'],
        ['пивовар', '-ола'],
        ['поборник', '-лидд'],
        ['повар', '-ифра'],
        ['подарок', '-дис'],
        ['портниха', '-ифра'],
        ['прелестный', '-дрис'],
        ['присяга', '-ип'],
        ['присяга', '-иппа'],
        ['провидец', '-моа'],
        ['провидец', '-мора'],
        ['пророк', '-моа'],
        ['пророк', '-мора'],
        ['пруд', '-тиа'],
        ['пряха', '-дрид'],
        ['радость', '-ила'],
        ['река', '-тиа'],
        ['ремесленник', '-ина'],
        ['ремесло', '-ина'],
        ['речь', '-хильд'],
        ['рождение', '-рен'],
        ['рука', '-если'],
        ['святая женщина', '-иа'],
        ['святая женщина', '-ия'],
        ['сердце', '-а'],
        ['сердце', '-дрис'],
        ['сердце', '-эд'],
        ['сестра', '-бела'],
        ['сестра', '-или'],
        ['сестра', '-ли'],
        ['сокровище', '-иси'],
        ['союзник', '-бела'],
        ['страж', '-дет'],
        ['стражник', '-дет'],
        ['счастье', '-ала'],
        ['счастье', '-ла'],
        ['ткач', '-дрид'],
        ['украшения', '-астр'],
        ['умение', '-если'],
        ['хозяин', '-я'],
        ['честность', '-илд'],
        ['чистый', '-он'],
        ['чистый', '-она'],
        ['эль', '-ола'],
        ['эскорт', '-тра'],
      ],
      source: {
        id: SOURCE_DND3_5_RoS,
        page: 26,
      },
    },
    {
      list: [
        ['мост', '-ак'],
        ['башня', '-арр'],
        ['башня', '-алн'],
        ['проход', '-бек'],
        ['брод', '-бек'],
        ['зал', '-крал'],
        ['очаг', '-дар'],
        ['очаг', '-данн'],
        ['стена', '-дукр'],
        ['дом', '-дуум'],
        ['поместье', '-дуум'],
        ['замок', '-ефт'],
      ],
      source: {
        id: SOURCE_DND3_5_RoS,
        page: 27,
      },
    },
  ],
  description: [
    {
      text: `Дварфийский язык состоит из твёрдых согласных и гортанных звуков, и этот акцент будет присутствовать в любом языке, на котором [дварф](PC_RACE:${PC_RACE_DWARF}) будет говорить.`,
      source: {
        id: SOURCE_PHB,
        page: 21,
      },
    },
    {
      text: `Дварфийский язык Фаэруна использует рунический алфавит Детек, знаки которого легко выгравировать на камне и металле, свидетельством чего являются рунические камни и путевые знаки, найденные в древних дварфийских тоннелях и шахтах.`,
      source: {
        id: SOURCE_SCAG,
        page: 104,
      },
    },
    {
      text: `Все дварфы общаются на своём родном языке, буквами которого являются руны. В литературе дварфов собрана обширная история королевств дварфов и их войн на протяжении тысячелетий.`,
      source: {
        id: SOURCE_DND3_5_PHB,
        page: 14,
      },
    },
    {
      text: `Дварфийский язык берёт своё начало в древности. Многие учёные-лингвисты считают равным по возрасту эльфийскому языку, или даже считают что дварфийский старше. Это делает дварфийский одним из самых старых письменных и устных языков в мире.`,
      source: {
        id: SOURCE_DND3_5_RoS,
        page: 24,
      },
    },
    {
      text: `Дварфийский — очень прагматичный язык. В нём мало слов для описания высоких идеалов или теорий, что делает его плохо подходящим для научных или философских дискуссий. Дварфы, которые занимаются столь необычной деятельностью, такие как мудрецы или волшебники, большую часть профессиональной терминологии берут из всеобщего или эльфийского языков. Дварфийские религиозные уроки проводятся скорее как рассказывания легенд или притч, скрывающих моральные наставления от слушателя, потому что сам язык плохо подходит для таких наставлений.

Дварфийский очень хорошо выражает эмоциональные состояния, давая дварфийским скальдам широкую многообразную палитру для устного изображения вербальных картин. Эта направленность на прагматичное и сиюминутное вместо неопределённого и вечного часто переходит и в языки с сильным влиянием дварфийского.

Где дварфийский сияет, так это в инженерии и проектировании. Язык позволяет точно определять углы между физическими предметами, различать схожие предметы по типам и описывать различные процессы. Многие учёные полагают что гномья страсть к описанию деталей может проистекать из этого аспекта их языка, чьи корни лежат в дварфийском. Хотя большинство основных слов в дварфийском коротки и конкретны, одно- или двухбуквенные добавления часто добавляются в начале или конце слова, уточняя его и добавляя деталей. Большинство длинных дварфийских слов — результат такой модификации и часто содержат удивительно глубокий уровень описательности.

Устный дварфийский часто звучит грубо для жителей поверхности. Он насыщен согласными и становится гортанным при резкой или гневной речи. Хотя он может быть очень мелодичным, ритмичным и иметь приятное для слуха гармоничное звучание.`,
      source: {
        id: SOURCE_DND3_5_RoS,
        page: 24,
      },
    },
  ],
  typicalSpeakers: 'Дварфы',
  isRealLang: true,
  isReady: true,
}
