const {
  SPELL_ANTIMAGIC_FIELD,
  SPELL_DISINTEGRATE,
  SPELL_DISPEL_MAGIC,
  SPELL_TEMPLE_OF_THE_GODS,
} = require('./../../../../../spellIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_CHA} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_TEMPLE_OF_THE_GODS,
  name: 'Храм богов',
  nameEn: 'Temple of the Gods',
  description: `Вы создаёте храм, появляющийся из мерцающего света на земле в том месте, которое Вы можете видеть в пределах дистанции. Храм должен умещаться в незанятом кубе с длиной ребра до 120 футов. Храм остаётся до тех пор, пока заклинание не закончится. Он посвящен любому богу, пантеону или философии, которые представлены священным символом, используемым при накладывании заклинания.

Вы сами решаете, как храм должен выглядеть. Внутреннее пространство храма ограничено полом, стенами и крышей. У храма одна входная дверь, но любое количество окон. Только Вы и те существа, которых Вы указываете, когда сотворяете заклинание, могут открыть или закрыть дверь.

Интерьер храма — это открытое пространство с идолом или алтарём у одной из стен. Вы определяете, освещается ли храм, и является ли этот свет ярким или тусклым. Внутри воздух наполнен запахом горящих благовоний, а температура комфортная.

Храм препятствует существам тех типов, которые Вы выбираете, когда сотворяете это заклинание. Выберите один или несколько из следующих типов: небожители, элементали, феи, исчадия или нежить. Если существо выбранного типа пытается войти в храм, оно должно пройти испытание Харизмы. При провале оно не может войти в храм в течение 24 часов. Даже если существо сумело войти в храм, магия этого места является для него помехой; всякий раз, когда оно совершает бросок атаки, проверку способности или проходит испытание внутри храма, существо должно бросить к4 и вычесть получившееся число из его броска к20.

Кроме того, сенсоры, созданные заклинаниями школы Прорицания, не могут появиться внутри храма, а существа внутри него не могут быть целями заклинаний этой школы.

Наконец, всякий раз, когда какое-либо существо в храме восстанавливает хиты заклинанием 1 круга или выше, оно дополнительно восстанавливает хиты в количестве, равном Вашему модификатору Мудрости (минимум 1 хит).

Храм сделан из светонепроницаемого магического поля, которое простирается на Эфирный План, тем самым блокируя межпланарное перемещение внутрь храма. Также ничто не может проникнуть в храм физическим путём. Он не может быть развеян [рассеиванием магии](SPELL:${SPELL_DISPEL_MAGIC}), и [преграда магии](SPELL:${SPELL_ANTIMAGIC_FIELD}) не оказывает на него никакого эффекта. Заклинание [распад](SPELL:${SPELL_DISINTEGRATE}) мгновенно разрушает храм.

Сотворение этого заклинания на одно и то же место каждый день в течение года делает этот эффект постоянным.`,
  lvl: 7,
  magicSchoolId: MAGIC_CONJURATION,
  range: 120,
  hasCost: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `святой символ, стоимостью не менее 5 зм`,
  duration: {timeId: TIME_HOUR, count: 24},
  castTime: {timeId: TIME_HOUR, count: 1},
  effect: {
    savethrowParam: PARAM_CHA,
  },
  source: {
    id: SOURCE_XGTE,
    page: 171,
  },
}
