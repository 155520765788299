const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_WEAPON_DARK,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SORROW_SWORN,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_THE_LOST} = require('./../../../../creatureIdList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_ATHLETICS} = require('./../../../../skillList')
const {sorrowSwornDescriptionList} = require('./../../../../textCommonParts')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Потерянный',
  nameAlt: 'Заблудший',
  nameEn: 'The Lost',
  id: CREATURE_THE_LOST,
  description: [
    `Царство Теней ведёт странников по кругу до той поры, пока они окончательно не заблудятся в однообразном ландшафте. Потерянные олицетворяют тревогу и страх, испытываемые людьми, когда они не могут найти верный путь. Эти горезванные выглядят отчаянными и беспокойными.

Потерянные пытаются обнять любое существо, которое смогут догнать, пытаясь найти облегчение. Помимо ужаса от объятий такого чудовища, жертва испытывает поток страха и паники из-за того, что её разум сжимается яростью напавшего на неё Потерянного. Чем усерднее союзники жертвы сражаются с Потерянными, тем хуже становится жертве.`,
      ...sorrowSwornDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SORROW_SWORN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 237,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 15,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_WEAPON_DARK,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_7,
  actionList: [
    {
      name: 'Мультиатака',
      description: `Заблудший совершает две атаки _Рукой-шипом_.`,
    },
    {
      name: 'Рука-шип',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Объятия',
      description: `Цель становится Схваченной (СЛ высвобождения 14), если она Среднего или меньшего размера. До завершения захвата цель испугана, и получает 27 (6к8) урона психической энергией в конце каждого своего хода. Потерянный может держать в _Объятиях_ только одну цель за раз.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
  ],
}
