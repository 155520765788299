const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ABILITY_KEEN_SIGHT,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_EAGLE,
    CREATURE_GRIFFON,
    CREATURE_HIPPOGRIFF,
    CREATURE_RIDING_HORSE,
    CREATURE_WYVERN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гиппогриф',
  nameEn: 'Hippogriff',
  id: CREATURE_HIPPOGRIFF,
  description: `Гиппогриф — это существо, тайна происхождения которого затерялась в древности. У него крылья и передние лапы [орла](CREATURE:${CREATURE_EAGLE}), задняя часть [лошади](CREATURE:${CREATURE_RIDING_HORSE}), и голова, сочетающая черты обоих животных.

Гиппогрифы всеядны, и ведут уединённый образ жизни, выбирая себе спутников раз и навсегда, и редко удаляясь от гнезда более чем на несколько миль. Защищая своих детей или партнёра, гиппогриф сражается насмерть. Гиппогрифы живородящие, яиц они не откладывают.

Драконам, [грифонам](CREATURE:${CREATURE_GRIFFON}) и [вивернам](CREATURE:${CREATURE_WYVERN}) нравится мясо гиппогрифов, поэтому они часто охотятся на этих существ.

# Летучие ездовые животные

Выращенный в неволе гиппогриф может стать верным спутником и ездовым животным. Из всех существ, которые могут служить летучим ездовым животным, гиппогрифов легче всего приручить, и они будут самыми верными животными из всех, если их правильно дрессировать.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 55,
  },
  armor: 11,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 13,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  cr: CR_1,
  featureList: [
    ABILITY_KEEN_SIGHT,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Клювом_, и одну _Когтями_.`,
    },
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
