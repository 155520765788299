const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {GEAR_AMULET} = require('./../../../../../gearIdList')
const {FEATURE_CHANNEL_DIVINITY_CLERIC} = require('./../../../../../featureIdList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    bonus,
    id,
    rarity,
  }
) => ({
  id,
  name: `Амулет благочестия +${bonus}`,
  nameEn: `Amulet of the Devout +${bonus}`,
  rarity,
  gearType: GEAR_AMULET,
  attunement: true,
  attunementComment: `жрецом или паладином`,
  type: MGC_TYPE_WONDROUS_ITEM,
  description: `На _Амулете благочестия_ изображён символ божества, инкрустированный драгоценными камнями или металлами.

Нося _Амулет благочестия_, Вы получаете бонус +${bonus} к броскам атаки заклинаниями и СЛ испытаний Ваших заклинаний.

Нося этот священный символ, Вы можете использовать свой [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}) без траты его использований. Использовав эту способность _Амулета благочестия_, Вы не можете повторно её использовать, пока не окончите длинный отдых.`,
  genderId: GENDER_MALE,
  siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 172,
  },
})
