const {PC_SUBCLASS_SORCERER_STORM_SORCERY} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  FEATURE_HEART_OF_THE_STORM,
  FEATURE_STORM_GUIDE,
  FEATURE_STORM_S_FURY,
  FEATURE_TEMPESTUOUS_MAGIC,
  FEATURE_WIND_SOUL,
  FEATURE_WIND_SPEAKER,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_WIND_SPEAKER,
    name: `Уста ветра`,
    nameEn: `Wind Speaker`,
    lvl: 1,
    text: `Тайная магия, которой Вы повелеваете, наполняется стихией воздуха.

Вы можете говорить, читать и писать на Первичном языке. Знание этого языка позволяет понимать и быть понятым теми, кто говорит на его диалектах: Акване, Ауране, Игнане и Терране.`,
  },
  {
    id: FEATURE_TEMPESTUOUS_MAGIC,
    name: `Бурная магия`,
    nameEn: `Tempestuous Magic`,
    lvl: 1,
    text: `Вы можете в свой ход бонусным действием, чтобы заставить завихряющиеся порывы стихийного ветра на мгновение окружить Вас прямо перед тем или прямо после того, как Вы сотворите заклинание 1 круга или выше.

Это позволяет Вам отлететь на расстояние до 10 футов, не провоцируя атак по возможности.`,
  },
  {
    id: FEATURE_HEART_OF_THE_STORM,
    name: `Сердце шторма`,
    nameEn: `Heart of the Storm`,
    lvl: 6,
    text: `Вы получаете сопротивление к электричеству и звуку.

Кроме того, всякий раз, когда Вы начинаете сотворять заклинание 1 круга или выше, которое наносит урон электричеством или звуком, из Вас выплёскивается магия шторма.

Этот всплеск направлен в выбранных Вами существ, видимых Вами в пределах 10 футов, и наносит урон электричеством или звуком (выберите каждый раз, когда эта способность активируется), равный половине Вашего уровня чародея.`,
  },
  {
    id: FEATURE_STORM_GUIDE,
    name: `Управление штормом`,
    nameEn: `Storm Guide`,
    lvl: 6,
    text: `Вы получаете возможность контролировать погоду вокруг себя.

Если идёт дождь, Вы можете действием прекратить его в сфере с радиусом 20 футов, с центром на Вас. Вы можете бонусным действием прекратить этот эффект.

Если ветрено, Вы можете каждый раунд бонусным действием выбрать его направление ветра в сфере с радиусом 100 футов, с центром на Вас. Ветер дует в этом направлении до конца Вашего следующего хода. Эта способность не изменяет скорость ветра.`,
  },
  {
    id: FEATURE_STORM_S_FURY,
    name: `Ярость шторма`,
    nameEn: `Storm’s Fury`,
    lvl: 14,
    text: `Когда по Вам попадают рукопашной атакой, Вы можете реакцией нанести атакующему урон электричеством, равный Вашему уровню чародея.

Атакующий также должен пройти испытание Силы против Вашей СЛ заклинаний. При провале атакующего отталкивают от Вас по прямой линии на расстояние до 20 футов.`,
  },
  {
    id: FEATURE_WIND_SOUL,
    name: `Душа ветра`,
    nameEn: `Wind Soul`,
    lvl: 18,
    text: `Вы получаете иммунитет к урону электричеством и звуком.

Вы также получаете магическую скорость полета 60 футов. Вы можете действием уменьшить скорость полета до 30 футов на 1 час и выбрать несколько (3 + Ваш модификатор Харизмы) существ в пределах 30 футов от себя. Выбранные существа получают магическую скорость полёта 30 футов на 1 час. Как только Вы уменьшите скорость полета таким образом, Вы не сможете сделать это снова, пока не закончите короткий или длинный отдых.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_SORCERER_STORM_SORCERY,
    source: [
      {
        id: SOURCE_XGTE,
        page: 62,
      },
    ],
  })
)

