const {SOURCE_EGTW} = require('./../sourceList')
const {fullCasterSpellCircleList} = require('./../spellCircleByCasterType')
const {
  PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC,
  PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC,
} = require('./../pcSubClassIdList')

const fightingStyleFeatureTemplate = {
  name: `Боевой стиль`,
  nameEn: `Fighting Style`,
  text: `Вы выбираете боевой стиль, соответствующий Вашей специализации. Выберите один из следующих вариантов. Вы не можете выбирать один и тот же вариант боевого стиля, даже если позже у Вас будет возможность выбрать ещё один стиль.`,
}

const abilityScoreImprovementTemplate = {
  name: `Увеличение характеристик`,
  nameEn: `Ability Score Improvement`,
  lvl: [4, 8, 12, 16, 19],
  text: `Вы можете повысить значение одной из Ваших характеристик на 2 или двух характеристик на 1. Как обычно, значение характеристики при этом не должно превысить 20.`,
}

const extraAttackTemplate = {
  name: `Дополнительная атака`,
  nameEn: `Extra Attack`,
  lvl: 5,
  text: `Если Вы в свой ход совершаете действие Атака, то можете совершить две атаки вместо одной.`,
}

const channelDivinityGuidedStrikeTemplate = {
  name: `Божественный канал: Направленный удар`,
  nameEn: `Channel Divinity: Guided Strike`,
  text: `Вы можете использовать _Божественный Канал_, чтобы наносить удары со сверхъестественной точностью.

Когда Вы совершаете атаку, Вы можете использовать получить бонус +10 к этому броску. Вы можете выбрать это уже после того, как увидите результат броска, но до того, как Мастер объявит о попадании или промахе атаки.`,
}

const dunamancySpellsTemplate = {
  name: `Заклинания динамантии`,
  nameEn: `Dunamancy Spells`,
  text: `Здесь представлены примеры заклинаний, разработанные с использованием _динамия_. Эти заклинания доступны как подклассам волшебника ([Гравитург](PC_SUBCLASS:${PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC}) и [Хронург](PC_SUBCLASS:${PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC})), так,  с одобрения Мастера, и другим классам заклинателей.`,
  spellCircleLvl: fullCasterSpellCircleList,
  source: {
    id: SOURCE_EGTW,
    page: 187,
  },
}

module.exports.abilityScoreImprovementTemplate = abilityScoreImprovementTemplate
module.exports.extraAttackTemplate = extraAttackTemplate
module.exports.fightingStyleFeatureTemplate = fightingStyleFeatureTemplate
module.exports.channelDivinityGuidedStrikeTemplate = channelDivinityGuidedStrikeTemplate
module.exports.dunamancySpellsTemplate = dunamancySpellsTemplate
