const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1_2,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  ABILITY_AMORPHOUS,
  ABILITY_SHADOW_STEALTH,
  ABILITY_SUNLIGHT_WEAKNESS,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_SHADOW,
} = require('./../../../../creatureIdList')

module.exports = {
  name: `Тень`,
  nameEn: 'Shadow',
  id: CREATURE_SHADOW,
  description: [
    {
      header: 'Тень',
      text: `Тень это нежить, напоминающая гипертрофированную человеческую тень.`,
      source: {
        id: SOURCE_MM,
        page: 282,
      },
    },
    {
      header: 'Тень',
      text: `Эти неживые твари напоминают гротескно увеличенные тёмные тени людей. Они питаются жизненной силой существ, которыми когда-то были сами.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: 'Тёмный нрав',
      text: `Тень тянется к жизненной силе живых существ из тьмы. Тени могут поглотить энергию любого существа, но их особенно тянет к незапятнанным злом созданиям. Существо, которое живёт добром и праведностью, вызывает у голодной тени самую сильную тягу. По мере того, как тень высасывает жизненную силу существа, тень жертвы становится темнее, и начинает двигаться по собственной воле. После смерти тень существа вырывается на свободу, становясь новой нежитью, стремящейся поглотить ещё чью-нибудь жизнь.

Если существо, из которого сформировалась новая тень, каким-то образом вернётся к жизни, его тень-нежить чувствует это возвращение. Тень может начать искать своего «родителя», чтобы убить. Вне зависимости от того, преследует ли тень свою живую часть, существо, из которого она была создана, больше не отбрасывает тень, пока это чудовище не будет уничтожено.`,
      source: {
        id: SOURCE_MM,
        page: 282,
      },
    },
    {
      header: 'Природа нежити',
      text: `Тени не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 282,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 282,
  },
  armor: 12,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_STEALTH]: {
      value: PROF_NORMAL,
      comment: `+6 при тусклом освещении и в темноте`,
    },
  },
  vulnerabilityList: [
    DAMAGE_RADIANT,
  ],
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_GRAPPLED,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_AMORPHOUS,
    ABILITY_SHADOW_STEALTH,
    ABILITY_SUNLIGHT_WEAKNESS,
  ],
  actionList: [
    {
      name: `Вытягивание силы`,
      description: `Цель умирает, если её Сила уменьшается до 0. Уменьшение длится до тех пор, пока цель не окончит короткий или длинный отдых.

Если от этой атаки умрёт гуманоид с незлым мировоззрением, через 1к4 часа из трупа выйдет ★новый★ ★СУЩЕСТВО★.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
          comment: `, и значение Силы цели уменьшается на 1к4`,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
