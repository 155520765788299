const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_DEAD,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_HEAL,
  SPELL_HOLD_MONSTER,
  SPELL_INVISIBILITY,
  SPELL_LIGHTNING_BOLT,
  SPELL_SUGGESTION,
  SPELL_SYMBOL,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  archDevilDescriptionList,
  archDevilNoteList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_REGENERATION,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_HUTIJIN,
  CREATURE_PIT_FIEND,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_21} = require('./../../../../crList')
const {GEAR_MACE} = require('./../../../../gearIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Хутиджин',
  nameEn: 'Hutijin',
  id: CREATURE_HUTIJIN,
  hasSpoilers: true,
  description: [
    `Плести интриги в Девяти Преисподних — достаточно скучное и предсказуемое дело. Сплошь и рядом образуются союзы, но большинство из них распадаются из-за измен и предательств. Несмотря на все эти предательства и клевету, дьяволы время от времени действительно проявляют верность, предлагая непоколебимую службу своим хозяевам. Одним из таких примеров, является Хутиджин, герцог Кании и верный слуга Мефистофеля.

Во всех Преисподнях, имя Хутиджина вселяет страх и неприязнь низшим дьяволам, поскольку в распоряжении этого герцога находятся два батальона [Исчадий Преисподней](CREATURE:${CREATURE_PIT_FIEND}), образующие высшее сословие в Кании. С такими солдатами под его командованием, Хутиджин может с лёгкостью сломить любого противника, стоящего у него на пути, и обеспечить высшую защиту Мефистофелю против армий, что могут попытаться оспорить его владычество. Хутиджин накопил достаточно сил, чтобы самому свергнуть правителя Кании, но он никогда не сомневался в поддержке своего хозяина, вероятно полагая, что у Мефистофеля есть некая власть над ним.

За пределами Девяти Преисподних, Хутиджин — относительно малоизвестная личность, которая известна только самым начитанным инфернальным исследователям. У него нет своего собственного культа, а число его слуг значительно мало. Причина проста: Хутиджин ненавидит смертных. Будучи призванным из Преисподней, он дарит инициатору лишь долгую и мучительную смерть.

Мефистофель запрещает Хутиджину часто выходить в Материальный план, поскольку отсутствие герцога, сделает его хозяина более уязвимой целью для своих конкурентов. Прочие архидьяволы знают, как сильно Хутиджин презирает смертных и в тайне распространили средства для его призыва из Девяти Преисподней в надежде отвлечь архидьявола на достаточно продолжительное время для них, чтобы попытаться избавиться от Мефистофеля. Хутиджин отправляет дьяволов в Материальный план, чтобы уничтожить любое его упоминание и убить любого, кто знает о его имени или других характеристиках, но призывы до сих пор случаются. Будучи призванным со своего поста, он старается как можно скорее договорится, закрывая сделку с мелкой ценой для самого призывателя. Однако, заключив сделку, Хутиджин отплачивает того смертью.`,
    ...archDevilDescriptionList,
  ],
  note: [
    {
      text: `Какова цена вашей жизни? А сколько дадите за свою душу? Заключите сделку с Хутиджином, и это будет стоить вам и того, и другого. Надеюсь, оно того стоило.`,
      author: `Архимаг Морденкайнен`,
    },
    ...archDevilNoteList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 195,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 27,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 25,
    [PARAM_INT]: 23,
    [PARAM_WIT]: 19,
    [PARAM_CHA]: 25,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_21,
  featureList: [
    {
      name: 'Инфернальное отчаяние',
      description: `Все существа в 15 фт. от Хутиджина, не являющиеся дьяволами проходят испытания с помехами.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      id: ABILITY_REGENERATION,
      regen: 20,
      other: `. Если ★он★ получает урон излучением, эта особенность не работает до начала ★его★ следующего хода. ★СУЩЕСТВО★ умирает только в случае, если начинает ход с 0 хитами и не может регенерировать`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_ANIMATE_DEAD,
          SPELL_DETECT_MAGIC,
          SPELL_HOLD_MONSTER,
          SPELL_LIGHTNING_BOLT,
          SPELL_SUGGESTION,
          SPELL_WALL_OF_FIRE,
          {
            id: SPELL_ALTER_SELF,
            comment: 'может стать Средним существом',
          },
          {
            id: SPELL_INVISIBILITY,
            comment: 'только на себя',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DISPEL_MAGIC,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_HEAL,
          {
            id: SPELL_SYMBOL,
            comment: 'только эффект _«Безнадёжности»_',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Хутиджин совершает четыре атаки: одну _Укусом_, одну _Когтями_, одну _Булавой_, и одну _Хвостом_.`,
    },
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения СЛ 22, иначе получит отравление. Будучи отравленной из-за этой атаки, цель не может восстанавливать хиты, и она получает 10 (3к6) урона ядом в начале своего каждого хода. Отравленная цель может повторять испытание в конце каждого своего хода, переставая быть отравленной при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Булава',
      // gearId: GEAR_MACE, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 8,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 15,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 8,
        },
      },
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  reactionList: [
    {
      name: 'Ужасающий голос',
      restore: 5,
      description: `В ответ на получение урона, Хутиджин изрекает ужасающее слово силы. Все существа в 30 футах то него и не являющиеся дьяволами должны пройти испытание Мудрости СЛ 22 или стать испуганными им в течение минуты. Испуганная цель может повторять испытание в конце каждого своего хода, переставая быть испуганной при успехе. Существо, преуспевшее в испытании, становится невосприимчивым к действие _Ужасающего голоса_ на дальнейшие 24 часа.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака',
      description: `Хутиджин совершает одну атаку _Булавой_.`,
    },
    {
      name: 'Буря',
      cost: 2,
      description: `Хутиджин выпускает вокруг себя заряды молний, поражающие всех в радиусе 20 футов от него. Все существа в зоне поражения должны пройти испытание Ловкости СЛ 22, получая 18 (4к8) урона электричеством при провале, или половину этого урона при успехе.`,
    },
    {
      name: 'Телепортация',
      description: `Хутиджин использует действие _Телепортация_.`,
    },
  ],
}
