const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_DEEP_SPEECH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_NEOGI,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  neogiDescription,
  neogiNote,
} = require('./../../../../textCommonParts')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {ABILITY_SPIDER_CLIMB} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_NEOGI} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Неоги',
  nameEn: 'Neogi',
  id: CREATURE_NEOGI,
  description: neogiDescription,
  note: neogiNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_NEOGI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 174,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 14,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Ментальная стойкость',
      description: `Неоги обладает преимуществом на испытания против очарования и испуга, и его невозможно магически усыпить.`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Неоги делает две атаки: одну _Укусом_ и одну _Когтями_.`,
    },
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения СЛ 12 или стать отравленной на 1 минуту. Цель может повторять испытание в конце каждого своего хода, окончивая этот эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 4,
            diceType: 6,
            diceBonus: 3,
          },
        ],
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Порабощение',
      comment: `перезаряжается после короткого или долгого отдыха`,
      description: `Неоги выбирает целью одно существо, которое может видеть, не далее 30 футов от себя. Цель должна пройти испытание Мудрости СЛ 14 или стать магически очарованной неоги на 1 день или пока неоги не умрёт или не окажется дальше, чем за 1 милю от цели. Очарованная цель подчиняется командам неоги и не может совершать реакции. Неоги и цель могут общаться телепатически друг с другом на расстоянии до 1 мили. Когда очарованная цель получает урон она повторяет испытание и оканчивает этот эффект на себе при успехе.`,
    },
  ],
}
