const {SOURCE_VGTM} = require ('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_TABAXI} = require('./../../creatureTypeIdList')
const {tabaxiDescriptionList} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_TABAXI,
  nameEn: 'Tabaxi',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'табакси',
      genitive: 'табакси',
      dative: 'табакси',
      accusative: 'табакси',
      instrumental: 'табакси',
      prepositional: 'табакси',
    },
    plural: {
      nominative: 'табакси',
      genitive: 'табакси',
      dative: 'табакси',
      accusative: 'табакси',
      instrumental: 'табакси',
      prepositional: 'табакси',
    },
  },
  note: {
    text: `Однажды у нас проездом останавливалась табакси, несколько зим назад. Она каждый вечер собирала полный зал желающих послушать её истории и провела большинство дней, дремля на стуле перед камином. Мы думали, что она очень ленивая, но когда Лайнин пришла, ища пропавшее шило, она вылетела из двери так, что я и глазом моргнуть не успел.`,
    author: 'Тоблен Стоунхилл, трактирщик',
  },
  description: [
    {
      header: 'Табакси',
      text: `Родом из странных и далёких земель, странствующие табакси — кошкоподобные гуманоиды, которых любопытство заставляет собирать интересные артефакты, записывать рассказы и истории, и осматривать все чудеса в мире.

Отъявленные путешественники, любознательные табакси редко на долго оседают на одном месте. Их врожденный характер толкает их раскрывать тайны и находить потерянные сокровища и легенды.`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
    {
      header: 'Странствующие бродяги',
      text: `Большинство табакси остается на своей далекой родине, живя маленькими, сплоченными кланами. Эти табакси охотятся, ремесленничают, и в значительной степени сами держатся в кругу себе подобных.

Однако, не все табакси удовлетворены такой жизнью. Кошачий Бог, божество, создавшее табакси, одарил каждое свое дитя одной типично кошачей чертой. Табакси, одаренные любопытством, стремятся странствовать повсюду. Они собирают истории, артефакты и знания. Те, кто пережил этот период страсти к путешествиям, возвращаются домой в своих зрелых годах, чтобы поделиться новостями о внешнем мире. Таким образом табакси остаются изолированными, но всегда осведомленными о мире за пределами их дома.`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
    {
      header: 'Обмен знаниями',
      text: `Табакси дорожат знаниями, а не материальными вещами. Сундук, заполненный золотыми монетами, может пригодится, чтобы купить еду или моток веревки, но он в сущности не интересен. В глазах табакси, накопление богатства похоже на сбор припасов для долгой поездки. Оно нужно, чтобы выжить в мире, но не стоит из–за него волноваться.

Вместо этого табакси ценят знания и новый опыт. Их уши навостряются в полной таверне, и они выспрашивают истории, предлагая еду, напитки и монеты. Табакси может уйти с пустым кошельком, но он обдумывает истории и слухи, которые он собрал, как скупердяй подсчитывает монеты.

Хотя материальное богатство мало значит для табакси, у них есть жажда находить и осматривать древние реликвии, волшебные предметы и другие редкие объекты. Помимо радости, которые могут дать такие предметы, табакси испытывают большую радость от распутывания историй их создания и использования.`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
    {
      header: 'Кошачий бог',
      text: `Божество табакси — переменчивая сущность, как и подобает покровителю кошек. Табакси полагают, что Кошачий Бог бродит по миру, следя за ними и вмешиваясь в их дела, когда это необходимо. Жрецы Кошачьего Бога редки и как правило получают доступ к домену Обмана`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
    {
      header: 'Мимолётные увлечения',
      text: `Странствующие табакси — существа непостоянные, обменивающие одну навязчивую идею или увлечение на другую по мимолетной прихоти. Желания табакси ярко вспыхивают, но в один момент исчезают, замещаемые новой навязчивой идеей. Цель захватывает их только пока в ней есть тайна.

Табакси–плут может с удовольствием провести месяцы, составляя план кражи необычного драгоценного камня у дворянина, а потом обменять его на билет на корабль или жилье спустя неделю после кражи. Табакси может сделать обширные заметки или запомнить каждую грань драгоценного камня прежде чем отдать его, но драгоценный камень перестает очаровывать его как только его тайны и природа были раскрыты.`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
    {
      header: 'Торговцы и менестрели',
      text: `Любопытство движет большинством табакси, встреченных за пределами их родины, но не все они становятся приключенцами. Табакси, ищущие более безопасный путь для удовлетворения своих навязчивых идей, становятся странствующими торговцами и менестрелями.

Эти табакси работают маленькими труппами, обычно состоящими из старшего, более опытного табакси, который ведет до четырех молодых, постигающих свое место в мире. Они путешествуют в маленьких, красочных фургонах, перемещающихся от поселения к поселению. Когда они прибывают, то устанавливают небольшую сцену на городской площади, где они поют, играют на инструментах, рассказывают истории и предлагают экзотические товары в обмен на предметы, которые разжигают их интерес. Табакси неохотно принимают золото, они больше предпочитают интересные предметы или знания в качестве оплаты.

Эти странники придерживаются цивилизованных королевств, предпочитая торговать вместо того, чтобы следовать более опасными путями удовлетворения своего любопытства. Однако, они не брезгуют небольшим осторожным воровством, чтобы получить особенно интересный предмет, который владелец отказывается продать или обменять.`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
    {
      header: 'Имена табакси',
      text: `У каждого табакси есть одно имя, данное кланом и основанное на сложной формуле, которая включает в себя астрологию, прорицание, историю клана и другие эзотерические факторы. Имена табакси могут относиться как к мужчинам, так и к женщинам, большинство использует прозвища, получаемые из полных имен или вдохновленные ими.

Названия клана обычно основаны на географической достопримечательности, расположенной на территории клана или около неё.

В приведенных примерах имён табакси, прозвища указаны в круглых скобках.

# Имена табакси

Облако На Горной Вершине (Облако), Пять Брёвен (Бревно), Нефритовый Башмак (Нефрит), Леворукая Колибри (Птица), Семь Грозовых туч (Гроза), Юбка Змеи (Змея), Туманное Зеркало (Туман)

# Кланы табакси

Яркие Утесы, Отдаленный Дождь, Горное Дерево, Грохочущая Река, Храпящая Гора`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
    {
      header: 'Индивидуальность табакси',
      text: `У табакси могут быть мотивы и причуды, очень отличающиеся от дварфа или эльфа с похожей предысторией. Вы можете использовать следующие таблицы, чтобы персонифицировать Вашего персонажа в дополнение к чертам характера, идеалам, привязанностям и слабостям Вашей предыстории.

Таблица «Навязчивые идеи табакси» может помочь откорректировать цели Вашего персонажа. Для дополнительного веселья, кидайте кубик раз в несколько игровых дней, чтобы определить новый результат, что отражает изменение Ваших интересов.

# Навязчивые идеи табакси

| к8 | Моё любопытство сейчас сосредоточено на… |
|----|------------------------------------------|
| 1  | Боге или планарном существе              |
| 2  | Монстре                                  |
| 3  | Потерянной цивилизации                   |
| 4  | Тайне волшебника                         |
| 5  | Обычном предмете                         |
| 6  | Магическом предмете                      |
| 7  | Месте                                    |
| 8  | Легенде или рассказе                     |

# Причуды табакси

| к10 | Причуда |
|-----|---------|
| 1 | Вы скучаете по своему тропическому дому и бесконечно жалуетесь на холодную погоду, даже летом. |
| 2 | Вы никогда не носите один наряд дважды без крайней необходимости. |
| 3 | У Вас небольшая боязнь воды и Вы ненавидите мокнуть. |
| 4 | Ваш хвост всегда выдает Ваши сокровенные мысли. |
| 5 | Вы громко мурлычете когда счастливы. |
| 6 | Вы держите маленький шарик пряжи в руке, который постоянно теребите. |
| 7 | Вы всегда в долгах, так как тратите свое золото на шикарные вечеринки и подарки для друзей. |
| 8 | Говоря о чем–то, чем увлечены, Вы начинаете говорить быстро и без пауз, так что другие не могут Вас понять. |
| 9 | Вы записываете всякие пустяки, знания и истории, которые обнаружили. |
| 10 | Вы не можете не прикарманить заинтересовавший Вас предмет. |`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
    {
      header: 'Табакси в Забытых Королевствах',
      text: `В Забытых Королевствах табакси родом из Мацтики, области, расположенной далеко за океаном к западу от Побережья Меча. Табакси Мацтики известны своей изолированностью, и до недавнего времени они никогда не рисковали покидать свою родину. Табакси мало говорят о том, почему они передумали, хотя слухи рассказывают о странных происшествиях в тех отдаленных землях.`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
    ...tabaxiDescriptionList,
  ],
}
