const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  orcDescription,
  orcNote,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_NECROTIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_ORC,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_AGGRESSIVE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {GOD_YURTRUS} = require('./../../../../godIdList')
const {
  CREATURE_ORC_HAND_OF_YURTRUS,
  CREATURE_ORC_NURTURED_ONE_OF_YURTRUS,
} = require('./../../../../creatureIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Орк вскормленный Юртруса',
  nameEn: 'Orc nurtured one of Yurtrus',
  id: CREATURE_ORC_NURTURED_ONE_OF_YURTRUS,
  description: [
    `Когда на племя обрушивается болезнь, [руки Юртруса](CREATURE:${CREATURE_ORC_HAND_OF_YURTRUS}) изолируют заражённых. После этого жрецы ухаживают за теми, кого можно спасти, но нельзя вылечить. [Руки](CREATURE:${CREATURE_ORC_HAND_OF_YURTRUS}) культивируют болезнь этих вскормышей, превращая их в инструменты защиты и орудия войны. Когда орки отправляются на битву, отряд вскормленных может броситься в бой первыми, чтобы ценой своей жизни ослабить врага, распространив по его рядам гнусное благословение [Юртруса](GOD:${GOD_YURTRUS}).`,
    ...orcDescription,
  ],
  note: orcNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ORC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 179,
  },
  armor: {
    ac: 9,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 16,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 7,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ORC,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_AGGRESSIVE,
    {
      name: 'Носитель заразы',
      description: `Когда хиты орка уменьшаются до 0, он взрывается и любое существо не далее 10 футов от него должно пройти испытание Телосложения СЛ 13. При провале существо получается 14 (4к6) урона ядом и становится отравленным. При успехе существо получается половину этого урона и не отравлено. Существо, отравленное этим эффектом, может повторять испытание в конце каждого хода и окончить этот эффект на себе при успехе. Отравленное этим эффектом существо не может восстановить хиты.`,
    },
    {
      name: 'Вскормленный Юртруса',
      description: `Орк имеет преимущество к испытаниям от яда и болезней.`,
    },
  ],
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 2,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 1,
            diceType: 4,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Извращенная месть',
      description: `Орк уменьшает свои хиты до 0 и активирует способность _Носитель Заразы_.`,
    },
  ],
}
