const {SOURCE_VGTM} = require('./../../sourceList')
const {GOD_MAGLUBIYET} = require('./../../godIdList')
const {CREATURE_BARGHEST} = require('./../../creatureIdList')

module.exports = {
  header: 'Баргесты',
  text: `Давным давно [Маглубиет](GOD:${GOD_MAGLUBIYET}), владыка богов гоблиноидов, заключил сделку с Генералом Геенны в обмен на помощь. Генерал предоставил тому юголотов, которые погибли, выполняя приказ бога гоблинов. Но когда пришло время платить по сделке, [Маглубиет](GOD:${GOD_MAGLUBIYET}) отступился от своего слова. В качестве мести Генерал Геенны создал поглощающих души [баргестов](CREATURE:${CREATURE_BARGHEST}), чтобы те поедали души гоблиноидов и лишали [Маглубиета](GOD:${GOD_MAGLUBIYET}) войск в посмертии.`,
  source: {
    id: SOURCE_VGTM,
    page: 125,
  },
}
