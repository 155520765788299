const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_LONGSWORD,
} = require('./../../../../../gearIdList')
const {
  SPELL_CALL_LIGHTNING,
  SPELL_DIVINE_WORD,
  SPELL_DOMINATE_MONSTER,
  SPELL_FINGER_OF_DEATH,
  SPELL_WISH,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_ARTIFACT,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_EYE_OF_VECNA,
  MAGIC_ITEM_HAND_OF_VECNA,
  MAGIC_ITEM_SWORD_OF_KAS,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_SWORD_OF_KAS,
  name: `Меч Каса`,
  nameEn: `Sword of Kas`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_LONGSWORD,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  isSentient: true,
  description: [
    `Когда Векна получил власть, он сделал злого и безжалостного полководца, Каса Кроваворукого, своим телохранителем и приближенным. Этот злодей был советником, военачальником и наёмным убийцей. За выдающиеся успехи он заслужил уважение Векны и награду: меч, с происхождением, таким же тёмным, как его хозяин.

Кас долго служил личу, но вместе с его собственной силой росла и его гордыня. Его меч убедил его уничтожить Векну, чтобы он сам мог править империей. Легенды гласят, что Векна был уничтожен рукой Каса, но и Векна уничтожил своего полководца, оставив один лишь только меч. После этого мир стал светлее.

_Меч Каса_ это магический, разумный [длинный меч](GEAR:${GEAR_LONGSWORD}), предоставляющий бонус +3 к броскам атаки и урона, совершённым им. Он совершает критическое попадание при выпадении «19–20», а нежити причиняет дополнительный урон 2к10.

Если меч не испробует крови через 1 минуту после того как его вынут из ножен, владелец проходит испытание Харизмы СЛ 15. В случае успеха он получает урон психической энергией 3к6. При провале владелец становится доминируемым мечом, как будто под заклинанием [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}), и _Меч_ требует, чтобы его омыли кровью. Эффект заклинания оканчивается, когда требование _Меча_ будет выполнено.`,
    {
      header: 'Случайные свойства',
      text: `_Меч Каса_ обладает несколькими свойствами, определяемыми случайным образом:

* 1 малое положительное свойство
* 1 основное положительное свойство
* 1 малое отрицательное свойство
* 1 основное отрицательное свойство`,
    },
    {
      header: 'Дух Каса',
      text: `Пока _Меч_ находится у Вас, Вы добавляете к10 к инициативе в начале каждого сражения. Кроме того, когда Вы действием атакуете _Мечом_, Вы можете передать весь его бонус атаки или его часть к Классу Доспеха. Изменённые бонусы остаются действовать до начала Вашего следующего хода.`,
    },
    {
      header: 'Заклинания',
      text: `Пока _Меч_ находится у Вас, Вы можете действием наложить им одно из следующих заклинаний (СЛ испытания 18): 

* [Божественное слово](SPELL:${SPELL_DIVINE_WORD}),
* [Перст смерти](SPELL:${SPELL_FINGER_OF_DEATH}),
* [Призыв молнии](SPELL:${SPELL_CALL_LIGHTNING}).

Использовав _Меч_ для накладывания заклинания, Вы до следующего рассвета не сможете повторно наложить им это же самое заклинание.`,
    },
    {
      header: 'Разум',
      text: `_Меч Каса_ — разумное хаотично-злое оружие с Интеллектом 15, Мудростью 13 и Харизмой 16. Он обладает слухом и тёмным зрением в пределах 120 футов.

Это оружие может понимать Общий язык, а также говорить и читать на нём и телепатически общаться с владельцем.`,
    },
    {
      header: 'Индивидуальность',
      text: `Предназначение _Меча_ в уничтожении Векны. Для этого нужно убивать последователей Векны, уничтожать плоды его деяний и срывать планы.

Меч Каса также стремится уничтожать тех, кто развращён [Глазом](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) и [Рукой Векны](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}). Одержимость _Меча_ этими артефактами, в конечном счете, передаётся и его владельцу.`,
    },
    {
      header: 'Уничтожение Меча',
      text: `Существо, настроенное на [Глаз Векны](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) и [Руку Векны](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}), может использовать свойство объединённых артефактов сотворять заклинание [Исполнение желаний](SPELL:${SPELL_WISH}), чтобы уничтожить _Меч Каса_. Существо должно наложить заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) и совершить проверку Харизмы, противопоставленную проверке Харизмы _Меча_. _Меч_ при этом должен находиться в пределах 30 футов, иначе заклинание будет потрачено впустую. Если состязание выиграет _Меч_, ничего не произойдёт, и заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) будет потрачено впустую. Если _Меч_ проиграет, он уничтожается.`,
    },
  ],
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_CALL_LIGHTNING,
    SPELL_DIVINE_WORD,
    SPELL_FINGER_OF_DEATH,
  ],
  source: {
    id: SOURCE_DMG,
    page: 225,
  },
}
