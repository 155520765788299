// Use “spellRawList”, bcz “spellList” already
// imported this file (pcSubClassList.js)
const spellRawList = require('./../constants/spellList/spellRawList')

const pickSpellIdListByLvl = spellLvl => spellRawList.reduce(
  (list, { id, lvl, isDunamisBased }) => (
    lvl === spellLvl
    && !isDunamisBased
  )
    ? [...list, id]
    : list,
  [],
)

module.exports = pickSpellIdListByLvl
