const {
  CREATURE_BALOR,
  CREATURE_BAPHOMET,
  CREATURE_BARLGURA,
  CREATURE_CHASME,
  CREATURE_DEMOGORGON,
  CREATURE_DRETCH,
  CREATURE_DROW,
  CREATURE_DROW_PRIESTESS_OF_LOLTH,
  CREATURE_FRAZ_URBLUU,
  CREATURE_GLABREZU,
  CREATURE_GNOLL,
  CREATURE_GORISTRO,
  CREATURE_GRAZZT,
  CREATURE_HEZROU,
  CREATURE_INCUBUS,
  CREATURE_JUIBLEX,
  CREATURE_MAGE,
  CREATURE_MANES,
  CREATURE_MARILITH,
  CREATURE_MINOTAUR,
  CREATURE_NALFESHNEE,
  CREATURE_ORCUS,
  CREATURE_QUASIT,
  CREATURE_SHADOW_DEMON,
  CREATURE_SUCCUBUS,
  CREATURE_VROCK,
  CREATURE_YEENOGHU,
  CREATURE_YOCHLOL,
  CREATURE_ZUGGTMOY,
} = require('./../../creatureIdList')
const {
  MAGIC_ITEM_BOOK_OF_VILE_DARKNESS,
} = require('./../../magicItemIdList')
const {
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')
const {
  GOD_CORELLON_LARETHIAN,
  GOD_LOLTH,
} = require('./../../godIdList')

module.exports = [
  {
    header: 'Демоны',
    text: `Порождения Бесконечных Слоёв Бездны, демоны — воплощения хаоса и зла, машины разрушения, которых едва сдерживает их чудовищная физическая оболочка. Лишённые всякого сострадания, эмпатии и милосердия, они существуют только чтобы уничтожать.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Порождения хаоса',
    text: `Бездна порождает демонов как продолжение самой себя, спонтанно формируя их из грязи и кровопролитий. Некоторые из них уникальны, а другие относятся к одному из видов, особи внутри которого между собой различаются очень слабо. Другие же демоны, такие как [мэйны](CREATURE:${CREATURE_MANES}), создаются из душ смертных, отверженных или проклятых богами, или иным образом заточённых в Бездне.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Капризное величие',
    text: `Демоны уважают силу и только силу. Сильнейший демон управляет визжащей толпой из младших демонов, поскольку он может уничтожить любого демона слабее, если тот осмелится противиться приказам. Статус демона растёт вместе с пролитой кровью; чем больше врагов падёт перед ним, тем мощнее он становится.

Демон может уродиться [мэйном](CREATURE:${CREATURE_MANES}), затем стать [дретчем](CREATURE:${CREATURE_DRETCH}), и в конечном итоге переродиться во [врока](CREATURE:${CREATURE_VROCK}) после несчётного времени, проведённого в боях и выживании в Бездне. Такие перерождения встречаются редко, поскольку большинство демонов уничтожается до того, как наберёт значительную силу. Самые мощные представители выживших составляют ряды демонических повелителей, которые норовят разорвать Бездну своей бесконечной враждой друг с другом.

Затратив значительную магическую энергию, демонический повелитель может превратить меньших демонов в старшие формы, хотя такое повышение никогда не происходит в связи с заслугами или достижениями младшего демона. Скорее демонический повелитель превратит мэйна в [квазита](CREATURE:${CREATURE_QUASIT}), если ему потребуется невидимый шпион, или перевоплотит армию [дретчей](CREATURE:${CREATURE_DRETCH}) в [хезроу](CREATURE:${CREATURE_HEZROU}), если собирается пойти войной на конкурирующего повелителя. Демонические повелители редко повышают других демонов до высших рангов, боясь создать потенциального конкурента самим себе.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Вторжения из Бездны',
    text: `Бродя по Бездне, демоны ищут порталы на другие планы. Они жаждут ускользнуть из родного плана и распространить своё тёмное влияние по мультивселенной, разрушая творения богов, уничтожая цивилизации, и неся в мир отчаяние и разрушения.

Некоторые из самых мрачных легенд среди смертных говорят как раз о разрушении, произошедшем, когда в мир пришли демоны. Поэтому даже государства, вовлечённые в самый непримиримый и кровопролитный конфликт, отставят свои разногласия, чтобы сообща сдержать нашествие демонов или запечатать врата в Бездну, пока эти исчадия не вырвались на свободу.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Проявления скверны',
    text: `Демоны разносят скверну, и само их присутствие меняет мир к худшему. Растения вянут и умирают там, где есть глубинные демонические разломы. Животные избегают мест, где демон совершил убийство. Места демонического заражения источают смрад, который никогда не стихает, или могут быть территориями мерзлоты или палящего зноя, или же вечной тени.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Вечное зло',
    text: `За пределами Бездны смерть является незначительным нюансом, и не страшит демонов. Обычное оружие не может остановить этих исчадий, и многие демоны устойчивы к энергии даже самых мощных заклинаний. Если удачливый герой сумеет победить демона в бою, тот растечётся отвратительной лужей ихора. Он мгновенно заново формируется в Бездне, а его разум и сущность уцелеют, как и его горящая ненависть. Единственный способ действительно уничтожить демона — найти его в Бездне и убить его там.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Защита сущности',
    text: `Мощный демон может принять меры для защиты своей сущности, используя тайные знании и металлы Бездны для создания амулета, в который передаётся её часть. Если тело демона когда-либо уничтожат, амулет позволяет исчадию переродиться в выбранном месте и времени.

Получение демонического амулета — опасная инициатива, и ищущий рискует привлечь внимание демона, который создал амулет. Существо, обладающее демоническим амулетом, может требовать услуги от демона, чья сущность удерживается амулетом — или причинить сильную боль, если исчадие отказывается. Если амулет разрушается, демон, который создал его, остаётся запертым в Бездне на год и один день.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Демонические культы',
    text: `Несмотря на тёмные последствия в сделках с исчадиями, мир смертных наполнен существами, которые жаждут демоническую силу. Демонические повелители манипулируют смертными путём развращения их, потешая свои амбиции в обмен на магию и другие дары. Демон сначала использует смертного как инструмент, а затем, угнетая волю, утаскивает их смертные души в Бездну.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Призыв демона',
    text: `Существует мало действий столь же опасных, как призыв демона, и даже [маги](CREATURE:${CREATURE_MAGE}), которые свободно заключают сделки с дьяволами, боятся исчадий Бездны. Хоть демоны и жаждут сеять хаос на Материальном плане, они не проявляют благодарности, когда их приводят туда, беснуясь в клетках и требуя освобождения.

Тот, кто рискнул призвать демона, может узнать нужную информацию, заставить служить или отправить демона на задание, которое может завершить только существо абсолютного зла. Подготовка является ключевым моментом, и опытные призыватели знают конкретные заклинания и магические предметы, которые могут заставить демона подчиниться чужой воле. Но если допустить хотя бы одну ошибку, демон не знает милосердия, и его призыватель становится первой жертвой.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Привязка демона',
    text: `[Книга Мерзкой Тьмы](MAGIC_ITEM:${MAGIC_ITEM_BOOK_OF_VILE_DARKNESS}), _Чёрные Свитки Ама_ и _Демономикон Иггвильв_ являются основными источниками информации о демонах. Эти древние фолианты описывают способы поимки сущности демона на Материальном плане, чтобы поместить её в оружие, идол или ювелирное изделие, и не дать исчадию вернуться в Бездну.

Предмет, к которому привязывается демон, должен быть специально подготовлен с помощью крови невинных и тёмного ритуала. Он излучает ощутимое зло, охлаждая и отравляя воздух вокруг себя. Существо, обладающее таким предметом, подвержено тревожным снам и испытывает странные желания, но зато может контролировать демона, чья сущность заточена в предмете. Если предмет уничтожить, то вырвавшийся демон немедленно мстит пленившему его.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Одержимость',
    text: `Вне зависимости от того, насколько крепки узы, мощный демон часто находит способ сбежать из предмета, который его удерживает. Когда демоническая сущность выходит из контейнера, она может завладеть смертным хозяином. Иногда исчадие ведёт себя скрытно, не раскрывая факт овладевания чужим телом. В других случаях он не прячется и вволю проявляет всю свою демоническую силу.

Пока тело хозяина одержимо демоном, душа его рискует быть утянутой в Бездну с демоном, когда того изгонят из плоти, или если тело умрёт. Если существо одержимо, а предмет, к которому привязан демон, уничтожается, одержимость длится до тех пор, пока мощная магия не изгонит демонический дух из тела хозяина.`,
    source: {
      id: SOURCE_MM,
      page: 80,
    },
  },
  {
    header: 'Демонические повелители',
    text: `Хаотическая сила Бездны вознаграждает демонов за выдающуюся безжалостность и изобретательность своим тёмным благословением, превращая их в уникальных исчадий, чья сила может посоперничать с силой богов. Эти демонические повелители правят с помощью хитрости и грубой силы, надеясь когда-нибудь заполучить абсолютный контроль над всей Бездной.
    
# Поощрение пришельцев

Хотя большинство демонических повелителей происходит из обширных и бессчётных полчищ демонов, населяющих Бездну, этот план также вознаграждает пришельцев, которые завоевали один из её бесчисленных слоёв. Эльфийская богиня [Ллос](GOD:${GOD_LOLTH}) стала демоническим повелителем после того, как [Кореллон Ларетиан](GOD:${GOD_CORELLON_LARETHIAN}) изгнал её в Бездну за то, что она предала эльфов. Мудрецы говорят, что [Тёмный Князь Граз’зт](CREATURE:${CREATURE_GRAZZT}) прибыл с какого-то другого плана, после чего похитил этот титул у другого давно забытого демонического повелителя.
    
# Власть и контроль

Наиболее очевидное проявление власти демонического повелителя — это его способность изменять форму своих владений. Слой Бездны, находящийся под контролем конкретного демонического повелителя, становится извращённым отражением его злобной личности, и демонические повелители редко оставляют своё царство из страха, что другое существо захватит его и переменит форму под себя.
    
Как и другие демоны, демонические повелители, погибшие на другом плане, возвращаются в Бездну, где их сущность воплощается заново в телесную форму. И точно так же, демонический повелитель, умерший в Бездне, погибает навсегда. Большинство демонических повелителей хранит часть своей сущности в тайном месте, чтобы предотвратить такую судьбу
     
# [Бафомет](CREATURE:${CREATURE_BAPHOMET})
      
Демонический повелитель Бафомет, известный как Рогатый Король и Князь Зверей — владыка [минотавров](CREATURE:${CREATURE_MINOTAUR}) и иных диких существ. Будь его воля, цивилизация бы исчезла, а все расы вернулись в животную дикость.
      
Князь Тварей выглядит как покрытый чёрной шерстью минотавр с железными рогами, красными глазами и испачканной кровью пастью. Его железная корона увенчана гниющими головами врагов, а тёмная броня щетинится шипами и выступами в виде черепов. Он носит огромную глефу Рассекатель Сердец, но зачастую врывается в бой, устрашая врага рогами и копытами.
       
# [Граз’зт](CREATURE:${CREATURE_GRAZZT})
        
Демонический повелитель Граз’зт — привлекательный мужчина девяти футов высотой. Те, кто считают Тёмного Князя одним из самых человечных повелителей, сильно недооценивают стремление его коварного сердца ко злу.
        
Граз’зт — потрясающе красивый мужчина, чью демоническую природу выдают эбеновая кожа, заострённые уши, жёлтые клыки, корона из рогов и шестипалые руки. Он любит роскошь и пышные зрелища, удовлетворяя свои падшие желания подобными предметами и спутниками, среди которых любимцами являются [инкубы](CREATURE:${CREATURE_INCUBUS}) и [суккубы](CREATURE:${CREATURE_SUCCUBUS}).
         
# [Демогоргон](CREATURE:${CREATURE_DEMOGORGON})
          
Демогоргон, Шипящий Зверь и самозваный Князь Демонов, желает ни много ни мало — сломать порядок мультивселенной. Из-за безумного внешнего вида и не менее безумных устремлений, внушает ужас даже другим демонам и демоническим повелителям.
          
Демогоргон в три раза выше человека, его тело по-змеиному гибкое, и сильное, как у крупного примата. Разделяющиеся щупальца заменяют ему руки. Нижняя часть ящерообразного тела заканчивается перепончатыми и когтистыми лапами, а плетевидные концы раздвоенного хвоста снабжены острыми лезвиями. У Князя Демонов две ужасных головы бабуина, и обе они безумны. Только распри между его двумя натурами держат амбиции этого демонического повелителя в узде.
           
# [Джуиблекс](CREATURE:${CREATURE_JUIBLEX})
            
Демонический повелитель Джуиблекс, покровитель слизей — смесь ядовитых жидкостей, скрывающаяся в глубинах Бездны. Гнусного Безликого Князя не заботят культисты и смертные слуги, его единственная цель — превратить всех в бесформенные ужасные копии самого себя.
            
Когда его не тревожат, Джуиблекс растекается вредоносной жижей, наполняя воздух нестерпимым зловонием. В те редкие моменты, когда ему бросают вызов, демонический повелитель поднимается дрожащим конусом слизи, пронизанным чёрными и зелёными венами. Полные злобы красные глаза перемещаются внутри аморфного тела, а подобные плетям ложноножки вырастают, чтобы с жадностью схватить того, кто подойдёт слишком близко.
             
# [Йеногу](CREATURE:${CREATURE_YEENOGHU})
              
Демонический повелитель Йеногу, известный как Лорд Гноллов и Зверь Резни, алчет побоищ и бессмысленного разрушения. [Гноллов](CREATURE:${CREATURE_GNOLL}), своих смертных орудий, он во славу свою толкает на ещё большие зверства. Погруженный в печаль и безнадёжность, Лорд Гноллов желает превратить мир в пустошь, где последние из выживших гноллов рвут друг другу глотки за право пировать мёртвыми.
              
Йеногу — огромный, покрытый шрамами гнолл, с чёрными шипами на загривке и горящими изумрудным пламенем глазами. Доспех его состоит из щитов и нагрудников, снятых с поверженных врагов, и украшен содранной с противников кожей. Йеногу может призвать тройной цеп Мясник, который он может использовать сам, а может действовать самостоятельно, пока лорд рвёт врагов клыками и когтями.
               
# [Ллос](GOD:${GOD_LOLTH})
                
Демоническая Королева Пауков — злая госпожа [дроу](CREATURE:${CREATURE_DROW}). Каждая её мысль сочится злобой, а глубина её порочности может удивить даже самых её верных [жриц](CREATURE:${CREATURE_DROW_PRIESTESS_OF_LOLTH}). Она направляет своих верующих, сквозь миры плетя интриги на Материальном плане, ожидая, когда её верные дроу отдадут в её власть эти миры.
                
Когда она предстаёт перед последователями мира смертных, что она делает с завидной частотой, [Ллос](GOD:${GOD_LOLTH}) выглядит как гибкая, властная женщина дроу. В бою, или когда последователям нужно внушить страх, нижняя часть её тела становится паучьей, и усеянные шипами ноги и сильные мандибулы рвут врагов в клочья.
                 
# [Оркус](CREATURE:${CREATURE_ORCUS})
                  
Известный как Демонический Повелитель Нежити и Кровавый Лорд, Оркус почитается нежитью и теми, кто пользуется силой нежити. Оркус — задумчивый нигилист, который хотел бы превратить мультивселенную в царство смерти и тьмы, которая никогда не меняется, кроме как по его воле.
                   
Демонический Повелитель Нежити — отвратительное тучное существо с торсом гуманоида, мощными козлиными ногами и разложившейся головой барана. Его покрытое язвами тело смердит от болезней, а гниющая голова и светящиеся красные глаза явно принадлежат уже мёртвому существу. Из спины растут огромные чёрные крылья летучей мыши, которые распространяют зловоние, когда он передвигается.
                   
Оркус носит зловещий артефакт, известный как _[Палочка Оркуса](/campaigns/oota)_ — похожий на булаву обсидиановый жезл, увенчанный черепом гуманоида. Он окружает себя нежитью, а живых существ, не находящихся под его контролем, терпеть не может.

# Прочие демонические повелители
                    
Никто не знает точного числа демонических повелителей, беснующихся в Бездне. Учитывая бесконечные глубины этого плана, мощные демоны часто становятся повелителями, но и исчезают так же быстро. Среди тех, чья власть продержалась столь долго, что демонологи запомнили их имена, можно выделить [Фраз-Урб’лу, Князя Обмана](CREATURE:${CREATURE_FRAZ_URBLUU}), Коштиктикая, Князя Гнева, Пазузу, Князя Нижних Воздушных Царств и [Заггтмой, Леди Грибов](CREATURE:${CREATURE_ZUGGTMOY}). `,
    source: {
      id: SOURCE_MM,
      page: 81,
    },
  },
  {
    header: 'Типы демонов',
    text: `Демонологи распределили демонов в зависимости от их силы в широкие категории, известные как «типы». Большинство демонов умещается в шесть больших типов, от самых слабых в первом типе, до сильных в шестом типе. За пределами данных типов остались незначительные по своей силе демоны и демонические повелители.
    
| Тип | Пример                        |
|-----|-------------------------------|
| 1   | [барлгура](CREATURE:${CREATURE_BARLGURA}), [врок](CREATURE:${CREATURE_VROCK}), [теневой демон](CREATURE:${CREATURE_SHADOW_DEMON}) |
| 2   | [хезроу](CREATURE:${CREATURE_HEZROU}), [чазм](CREATURE:${CREATURE_CHASME})                  |
| 3   | [глабрезу](CREATURE:${CREATURE_GLABREZU}), [йоклол](CREATURE:${CREATURE_YOCHLOL})              |
| 4   | [нальфешни](CREATURE:${CREATURE_NALFESHNEE})                     |
| 5   | [марилит](CREATURE:${CREATURE_MARILITH})                       |
| 6   | [балор](CREATURE:${CREATURE_BALOR}), [гористро](CREATURE:${CREATURE_GORISTRO})               |
`,
    source: {
      id: SOURCE_MM,
      page: 82,
    },
  },
  {
    header: 'Истинные имена демонов',
    text: `У каждого демона есть общее имя, но у демонических повелителей и всех демонов от 1 до 6 типа есть истинные имена, которые они держат в секрете. Очарованный демон может раскрыть своё истинное имя, а ещё истинные имена самых могущественных демонов можно найти в древних свитках и фолиантах.

Смертный, узнав истинное имя демона, может использовать мощную магию призыва, чтобы призвать его из Бездны и получить некоторый контроль над ним. Тем не менее, большинство демонов, вызванные на Материальный план таким образом, сделают всё, что в их силах, чтобы посеять хаос, раздор и смятение.`,
    source: {
      id: SOURCE_MM,
      page: 83,
    },
  },
  {
    header: 'Демоны',
    text: `Воплощения хаоса и зла, демоны могут различно выглядеть и бесчисленным множеством способов сеять разрушение в мультивселенной.`,
    source: {
      id: SOURCE_MTOF,
      page: 129,
    },
  },
]
