const {
  CREATURE_APE,
  CREATURE_BUGBEAR,
  CREATURE_DRAFT_HORSE,
  CREATURE_GOBLIN,
  CREATURE_HILL_GIANT,
  CREATURE_HOBGOBLIN,
  CREATURE_HOBGOBLIN_CAPTAIN,
  CREATURE_HOBGOBLIN_DEVASTATOR,
  CREATURE_HOBGOBLIN_IRON_SHADOW,
  CREATURE_HOBGOBLIN_WARLORD,
  CREATURE_OGRE,
  CREATURE_ORC,
  CREATURE_RAVEN,
  CREATURE_WOLF,
  CREATURE_WORG,
} = require('./../../creatureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const maglubiyetHobgoblin = require('./maglubiyetHobgoblin')
const goblinoidManual = require('./goblinoidManual')

module.exports = [
  {
    header: 'Хобгоблины',
    text: `Звук боевого рога, камни, летящие из катапульт, и звук тысячи обутых ног, маршем пересекающих поле, будут предвещать приход [хобгоблинов](CREATURE:${CREATURE_HOBGOBLIN}). Они нападают на границы цивилизованных земель, вынуждая поселенцев тех далёких мест быть всегда наготове к битве. Жажда завоевания хобгоблинов будет удовлетворена лишь тогда, когда захватывать будет уже нечего.

У хобгоблинов тёмно-оранжевая или красно-оранжевая кожа, а волосы имеют цвет от красно-коричневого до тёмно-серого. Жёлтые или тёмно-карие глаза выглядывают из-под их нависающих бровей, а их широкие рты щеголяют острыми и желтоватыми зубами. Мужчина хобгоблин может иметь большой синий или красный нос, который символизирует мужественность и власть среди гоблиноидов. Хобгоблины могут жить так же долго, как и люди, но из-за их любви к военному делу и битвам обычно живут немного меньше.`,
    source: {
      id: SOURCE_MM,
      page: 293,
    },
  },
  {
    header: 'Гоблиноиды',
    text: `Хобгоблины принадлежат к семейству существ, называемых гоблиноидами. Зачастую они командуют другими существами своего семейства, например, [гоблинами](CREATURE:${CREATURE_GOBLIN}) или свирепыми [медвежатниками](CREATURE:${CREATURE_BUGBEAR}).`,
    source: {
      id: SOURCE_MM,
      page: 293,
    },
  },
  {
    header: 'Боевая мощь',
    text: `Хобгоблин измеряет добродетель физической силой и военной доблестью, не заботясь ни о чём, кроме возможности продемонстрировать навык и хитрость в битве. Хобгоблин высшего военного ранга добивается своих позиций силой, а затем удерживает эти позиции, навязывая свой авторитет драконовскими мерами.

Хобгоблины обучены сражаться разнообразным оружием, и обладают выдающимися навыками в создании оружия, доспехов, осадных машин и других военных устройств. Организованные и дисциплинированные, они заботятся о доспехах, оружии и личном снаряжении. Они предпочитают дерзкие цвета, ассоциирующиеся с их племенами, и отделывают свою униформу кроваво-красным кантом и кожей, покрашенной в чёрный цвет.`,
    source: {
      id: SOURCE_MM,
      page: 293,
    },
  },
  {
    header: 'Военные легионы',
    text: `Хобгоблины организовываются в племенные банды, известные как легионы. В их милитаристском обществе у каждого хобгоблина есть ранг, от могущественных лидеров и чемпионов до неприметных пеших солдат и гоблинов, которых загоняют на фронтовую линию остриями копий. Легион возглавляется [военачальником](CREATURE:${CREATURE_HOBGOBLIN_WARLORD}) с несколькими [капитанами](CREATURE:${CREATURE_HOBGOBLIN_CAPTAIN}), служащими под его началом. Военачальник хобгоблин это безжалостный тиран, заинтересованный более стратегией, победой, славой, репутацией и властью, чем ведением отрядов в битву.

При этом такие же верные и дисциплинированные конкурирующие легионы постоянно соперничают, борясь за репутацию и статус. Встреча между легионами перерастает в насилие, если отряды никто не остановит, и только невероятно могучий лидер может заставить разные легионы сотрудничать на поле битвы.`,
    source: {
      id: SOURCE_MM,
      page: 293,
    },
  },
  {
    header: 'Стратегическое мышление',
    text: `Хобгоблины хорошо усвоили тактику и дисциплину, и могут выполнять хитрые боевые планы под управлением стратегически мыслящего лидера. Однако они ненавидят эльфов, и в сражении выбирают первой целью их, даже если это будет тактической ошибкой. Легионы часто пополняют свои ряды менее надёжными, но зато и менее ценными солдатами, набранными из [гоблинов](CREATURE:${CREATURE_GOBLIN}), [медвежатников](CREATURE:${CREATURE_BUGBEAR}), [орков](CREATURE:${CREATURE_ORC}), злых людей, [огров](CREATURE:${CREATURE_OGRE}) и [великанов](CREATURE:${CREATURE_HILL_GIANT}).`,
    source: {
      id: SOURCE_MM,
      page: 293,
    },
  },
  {
    header: 'Лучшие дрессировщики',
    text: `Хобгоблины с давних пор дрессируют животных для служения. Как и большинство цивилизованных рас, они используют рогатый скот и [лошадей](CREATURE:${CREATURE_DRAFT_HORSE}) для транспортировки грузов и вооружения на большие дистанции. Они общаются друг с другом, используя дрессированных [воронов](CREATURE:${CREATURE_RAVEN}), и держат злых [волков](CREATURE:${CREATURE_WOLF}) для охраны заключённых и защиты своих лагерей. Кавалерия хобгоблинов использует тренированных [варгов](CREATURE:${CREATURE_WORG}) как скакунов так же, как гоблины используют ездовых волков. Некоторые племена даже держат плотоядных [обезьян](CREATURE:${CREATURE_APE}) в качестве боевых тварей.`,
    source: {
      id: SOURCE_MM,
      page: 293,
    },
  },
  {
    header: 'Завоевание и контроль',
    text: `Хобгоблины претендуют на земли, изобилующие ресурсами, и их можно найти в лесах и горах, вблизи шахт и поселений гуманоидов и где угодно ещё, где можно найти древесину, металл и потенциальных рабов. Они строят и завоёвывают крепости в стратегически выгодных местах, которые они потом используют как плацдарм для расширения своей территории.

Военачальники хобгоблинов никогда не устают от сражений, но они не управляют своими армиями легкомысленно. Перед атакой хобгоблины проводят тщательную разведку, чтобы оценить сильные и слабые стороны противников. Когда они осаждают крепость, то вначале они её окружают, отрезая пути отступления и линии снабжения, и только тогда начинают медленно морить врагов голодом.

Хобгоблины укрепляют свои владения и поддерживают уже существующие укрепления улучшениями. Будь то берлога в системе пещер, подземелья, руины или леса, они защищают свои крепости, используя рвы, заборы, ворота, караульные башни, ямы с ловушками и грубые катапульты или баллисты.`,
    source: {
      id: SOURCE_MM,
      page: 293,
    },
  },
  maglubiyetHobgoblin,
  {
    header: 'Хобгоблины',
    text: `Наиболее жестокие по нраву хобгоблины получают доступ в элитные организации, которые предоставляют особое обучение сверх того, что может предложить военная служба. Академия [Опустошителей](CREATURE:${CREATURE_HOBGOBLIN_DEVASTATOR}) и [Железные тени](CREATURE:${CREATURE_HOBGOBLIN_IRON_SHADOW}) — две такие организации, чьих выпускников боятся другие гоблиноиды.`,
    source: {
      id: SOURCE_VGTM,
      page: 200,
    },
  },
  {
    header: 'Хобгоблины',
    text: `Раса известных своей жестокостью крупных гуманоидов, главное в жизни для которых — это ратные подвиги, война и слава.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  goblinoidManual,
]
