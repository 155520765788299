const {
  CREATURE_CHIMERA,
  CREATURE_GIANT_GOAT,
  CREATURE_GRIFFON,
  CREATURE_LION,
  CREATURE_MANTICORE,
  CREATURE_DEMOGORGON,
  CREATURE_PERYTON,
  CREATURE_WYVERN,
  CREATURE_DRAGON_RED_YOUNG,
} = require('./../../../../creatureIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_DRACONIC} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Химера`,
  nameEn: 'Chimera',
  id: CREATURE_CHIMERA,
  description: `Химеры были созданы после того как смертные призвали в мир [Демогоргона](CREATURE:${CREATURE_DEMOGORGON}). Князь Демонов, не впечатлённый окружающими его существами, превратил их в ужасных многоголовых чудовищ. Так появились первые химеры.

Наделённые демонической жестокостью, химеры служат ужасным напоминанием о том, что случается, когда князья демонов находят путь на Материальный план. Типичный образец выглядит следующим образом: задняя часть крупного [козла](CREATURE:${CREATURE_GIANT_GOAT}), передняя часть [льва](CREATURE:${CREATURE_LION}), кожистые крылья [дракона](CREATURE:${CREATURE_DRAGON_RED_YOUNG}), и головы всех трёх существ. Монстр атакует своих жертв внезапно, бросаясь с неба и окутывая жертву своим огненным дыханием, перед тем как приземлиться.

# Противоречивое существо

Химера сочетает худшие аспекты трёх разных существ. Голова дракона отвечает за набеги, грабежи и накопление значительных богатств. Львиная натура заставляет охотиться и убивать сильных существ, которые вторгаются на её территорию. Голова козла придаёт злобность и упрямство, заставляя сражаться до смерти.

Все эти мотивы движут химерой, считающей территорию более 10 миль в поперечнике своей территорией. Она охотится на дичь, присматриваясь к более могущественным созданиям как к соперникам, которых нужно победить и унизить. Их самыми опасными врагами являются [виверны](CREATURE:${CREATURE_WYVERN}), [грифоны](CREATURE:${CREATURE_GRIFFON}), драконы, [мантикоры](CREATURE:${CREATURE_MANTICORE}) и [перитоны](CREATURE:${CREATURE_PERYTON}).

Во время охоты химера отыскивает какие-нибудь несложные способы себя развлечь. Она наслаждается, пугая и мучая более слабых существ. Этот монстр часто играет со своей жертвой, неожиданно прекратив нападение, оставляя раненое и напуганное существо только для того, чтобы потом вернуться и прикончить его.

# Прислужник зла

Несмотря на то, что химеры далеки от хитрости, их драконье эго делает их восприимчивыми к лести и подаркам. Если предложить еду и сокровища, химера может пощадить путешественника. Злодей может привлечь химеру к себе на службу, если будет поддерживать её сокровищницу полной, а саму её хорошо накормленной.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 292,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 19,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_DRACONIC,
      doNotSpeak: true,
    },
  ],
  cr: CR_6,
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, одну _Рогами_, и одну _Когтями_. Если _Огненное дыхание_ доступно, ★он★ может использовать его вместо _Укуса_ или _Рогов_.`,
    },
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Рога`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 12,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Когти`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Огненное дыхание`,
      restore: 5,
      description: `Драконья голова выдыхает огонь 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 15, получая урон огнём 31 (7к8) при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
