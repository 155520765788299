const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_LONGBOW,
  GEAR_SHORTSWORD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ACROBATICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_ARCHER} = require('./../../../../creatureIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Лучник',
  nameEn: 'Archer',
  id: CREATURE_ARCHER,
  description: `Лучники защищают замки, охотятся на дичь на краю цивилизации, служат артиллерией в войсках и иногда хорошо зарабатывают как разбойники или охранники караванов.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 217,
  },
  armor: {
    ac: 16,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 16,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  languageList: [
    {
      id: LANG_ANY,
      comment: 'обычно Всеобщий',
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Глаз лучника',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `Бонусным действием лучник может добавить 1к10 к его следующему броску атаки или урона _Длинным луком_ или _Коротким луком_.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Лучник совершает две атаки _Длинным луком_.`,
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
}
