const {GENDER_MALE} = require('./../../../genderList')
const {MAGIC_ITEM_CAST_OFF_ARMOR} = require('./../../../magicItemIdList')
const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../magicItemTypeList')
const {SOURCE_XGTE} = require('./../../../sourceList')

const siblingIdList = require('./siblingIdList')
const gearCategoryList = require('./gearCategoryList')

module.exports = {
  id: MAGIC_ITEM_CAST_OFF_ARMOR,
  name: `Доспех быстрого снятия`,
  nameEn: `Cast-Off Armor`,
  type: MGC_TYPE_ARMOR,
  gearCategory: gearCategoryList,
  rarity: MGC_RARITY_COMMON,
  description: `Вы можете снять этот доспех действием.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_XGTE,
    page: 136,
  },
}
