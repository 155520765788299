const {
  SPELL_BANISHMENT,
  SPELL_COMMAND,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_FLAME_STRIKE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GREATER_RESTORATION,
  SPELL_HEROES_FEAST,
  SPELL_LESSER_RESTORATION,
  SPELL_SACRED_FLAME,
  SPELL_SPARE_THE_DYING,
  SPELL_THAUMATURGY,
  SPELL_TONGUES,
  SPELL_ZONE_OF_TRUTH,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_SPHINX,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  ABILITY_MAGIC_WEAPONS,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_17} = require('./../../../../crList')
const {CREATURE_ANDROSPHINX} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {sphinxDescription} = require('./../../../../textCommonParts')

module.exports = {
  name: `Андросфинкс`,
  nameEn: 'Androsphinx',
  id: CREATURE_ANDROSPHINX,
  description: [
    `Львиное тело андросфинкса венчает голова гуманоидного мужчины. Внешне угрюмый и расстроенный, он часто начинает разговор с оскорблений и язвительных замечаний. Но под грубой наружностью у андросфинкса скрывается благородное сердце. Он не склонен лгать или обманывать, но и информацией не делится так просто, он подбирает слова так же тщательно, как охраняет свои секреты.

Андросфинксы проверяют своих гостей на смелость и отвагу не только заставляя их выполнять задачи, но и своим ужасным рыком, который разносится эхом на мили окрест, вселяя страх и оглушая существ, которые находятся неподалёку. Те, кто справятся с проверками, могут быть вознаграждены [Пиром героев](SPELL:${SPELL_HEROES_FEAST}).`,
    ...sphinxDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 277,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 19,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 20,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 23,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_PSYCHIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_SPHINX,
  ],
  cr: CR_17,
  featureList: [
    {
      name: `Непостижимый`,
      description: `★СУЩЕСТВО★ обладает иммунитетом ко всем эффектам, которые чувствуют ★его★ эмоции или читают мысли, а также заклинаниям школы Прорицания, неугодным ★ему★. Проверки Мудрости (Проницательность), совершённые для определения ★его★ намерений и искренности, совершаются с помехой.`,
    },
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    spellCasterLevel: 12,
    spellCasterClass: PC_CLASS_CLERIC,
    componentExclude: CAST_MATERIAL,
    spellIdList: [
      SPELL_BANISHMENT,
      SPELL_COMMAND,
      SPELL_DETECT_EVIL_AND_GOOD,
      SPELL_DETECT_MAGIC,
      SPELL_DISPEL_MAGIC,
      SPELL_FLAME_STRIKE,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_GREATER_RESTORATION,
      SPELL_HEROES_FEAST,
      SPELL_LESSER_RESTORATION,
      SPELL_SACRED_FLAME,
      SPELL_SPARE_THE_DYING,
      SPELL_THAUMATURGY,
      SPELL_TONGUES,
      SPELL_ZONE_OF_TRUTH,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
    ],
  },
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Когтем_.`,
    },
    {
      name: `Коготь`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
    {
      name: `Рык`,
      limit: {
        count: 3,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ испускает магический рык. Каждый раз, когда ★он★ рычит до окончания длинного отдыха, рык звучит всё громче, и эффекты у него разные. Все существа в пределах 500 футов от ★него★, слышащие рык, должны пройти испытание.

1. **Первый рык.** Все существа, провалившие испытание Мудрости СЛ 18, становятся испуганными на 1 минуту. Испуганное существо может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
2. **Второй рык.** Все существа, провалившие испытание Мудрости СЛ 18, становятся оглохшими и испуганными на 1 минуту. Испуганное существо также парализовано и может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
3. **Третий рык.** Все существа проходят испытание Телосложения СЛ 18. При провале существо получает урон звуком 44 (8к10) и сбивается с ног. При успешном испытании существо получает половину этого урона и не сбивается с ног.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: `Атака когтем`,
      description: `★СУЩЕСТВО★ совершает одну атаку _Когтем_.`,
    },
    {
      id: ABILITY_TELEPORT,
      cost: 2,
      range: 120,
    },
    {
      name: `Накладывание заклинания`,
      cost: 3,
      description: `★СУЩЕСТВО★ сотворяет заклинание из списка подготовленных заклинаний, используя ячейку заклинаний, как обычно.`,
    },
  ],
  genderId: GENDER_MALE,
}
