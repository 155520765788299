const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DARKNESS,
  SPELL_DISPEL_MAGIC,
  SPELL_FEAR,
  SPELL_HEAT_METAL,
  SPELL_LEVITATE,
} = require('./../../../../spellIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_BABAU,
  CREATURE_GRAZZT,
} = require('./../../../../creatureIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_4} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {GEAR_SPEAR} = require('./../../../../gearIdList')
const {LANG_ABYSSAL} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Бабау',
  nameEn: 'Babau',
  id: CREATURE_BABAU,
  description: [
    `Демоны и дьяволы ведут вечный бой за контроль над Нижними Планами. В одной из таких битв сошлись легионы архидьявола Гласьи и вопиющие орды лорда демонов [Граз’зта](CREATURE:${CREATURE_GRAZZT}). Говорят, Гласья ранила [Граз’зта](CREATURE:${CREATURE_GRAZZT}) своим мечом, и в том месте, куда пала его кровь, появились первые бабау. Их внезапное появление помогло обратить Гласью в бегство и заслужило [Граз’зту](CREATURE:${CREATURE_GRAZZT}) место среди главных лордов демонов Бездны.

Бабау обладает хитростью дьявола и кровожадностью демона. Его кожистая шкура натянута поверх костлявого тела, а из задней части продолговатого черепа выступает изогнутые рог. Ужасный взгляд бабау может ослабить существо.`,
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 148,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
  ],
  cr: CR_4,
  spellCast: {
    baseStat: PARAM_WIT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DARKNESS,
          SPELL_DISPEL_MAGIC,
          SPELL_FEAR,
          SPELL_HEAT_METAL,
          SPELL_LEVITATE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Бабау совершает две рукопашные атаки. Он также может использовать _Ослабляющий взор_ перед тем, как совершать эти атаки, или после них.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      gearId: GEAR_SPEAR,
    },
    {
      name: 'Ослабляющий взор',
      description: `Бабау выбирает целью одно существо, которое видит в пределах 20 футов. Цель должна пройти испытание Телосложения СЛ 13. При провале цель в течение 1 минуты наносит лишь половину урона атаками, основанными на Силе. Цель может повторять испытание в конце каждого своего хода, оканчивая эффект при успехе.`,
    },
  ],
}
