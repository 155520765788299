const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_TROGLODYTE} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_TROGLODYTE,
  nameEn: 'Troglodyte',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'троглодит',
      genitive: 'троглодита',
      dative: 'троглодиту',
      accusative: 'троглодита',
      instrumental: 'троглодитом',
      prepositional: 'троглодите',
    },
    plural: {
      nominative: 'троглодиты',
      genitive: 'троглодитов',
      dative: 'троглодитам',
      accusative: 'троглодитов',
      instrumental: 'троглодитами',
      prepositional: 'троглодитах',
    },
  },
}
