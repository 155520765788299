const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  GEAR_MACE,
} = require('./../../../../../gearIdList')
const {
  CAT_SIMPLE_MELEE_WEAPON,
} = require('./../../../../../gearCategoryList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_MACE_OF_SMITING,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_MACE_OF_SMITING,
  name: `Булава кары`,
  nameEn: `Mace of Smiting`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_MACE,
  gearCategory: CAT_SIMPLE_MELEE_WEAPON,
  rarity: MGC_RARITY_RARE,
  description: `Вы получаете бонус +1 к броскам атаки и урона, совершённым этим магическим оружием. Бонус увеличивается до +3, если атакуется конструкт.

Если у Вас выпадает «20» при броске атаки этим оружием, цель получает дополнительный дробящий урон 7 или дополнительный дробящий урон 14, если это был конструкт. Если у конструкта осталось 25 или меньше хитов после получения этого урона, он уничтожается.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 152,
  },
}
