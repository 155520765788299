const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_SWIM,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_ABYSSAL,
    LANG_AQUAN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    ABILITY_AMPHIBIOUS,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_MERFOLK,
    CREATURE_MERROW,
    CREATURE_DEMOGORGON,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Водянник',
  nameEn: 'Merrow',
  id: CREATURE_MERROW,
  description: `Водянники часто посещают прибрежные воды, охотясь на рыбаков, [мерфолков](CREATURE:${CREATURE_MERFOLK}) и любых других съедобных существ, которые встретятся им на пути. Эти дикие чудовища хватают и пожирают неосторожную жертву, утягивая трупы в свои подводные гроты для пропитания.

# Превращённые мерфолки

Давным-давно племя [мерфолков](CREATURE:${CREATURE_MERFOLK}) нашло на дне моря идол [Демогоргона](CREATURE:${CREATURE_DEMOGORGON}). Не зная что это такое, они принесли артефакт своему королю. Каждого, кто коснулся идола, поразило безумие, в том числе и короля, который распорядился выполнить ритуал с жертвоприношением, чтобы открыть врата в Бездну. Океан стал красным от крови убитых мерфолков, но ритуал сработал, и король повёл выживших через подводные врата в Бездну на слой Демогоргона. Мерфолки остались здесь на несколько поколений, сражаясь за свои жизни, пока Бездна полностью не исказила их, превратив в нескладных отвратительных чудовищ. Так появились первые водянники.

# Прибрежные разбойники

Всякий раз, когда представляется возможность, Князь Демонов посылает водянников назад на Материальный план, чтобы учинить резню в океанах. Водянники — разбойники, нападающие на любых существ меньше и слабее себя.

Водянники обитают в подводных пещерах, наполненных сокровищами и трофеями, взятыми с затонувших кораблей или забранными у жертв. Они привязывают гниющие трупы мёртвых врагов и утонувших моряков, чтобы отметить границы своей территории.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 44,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 15,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AQUAN,
    LANG_ABYSSAL,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AMPHIBIOUS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну либо _Когтями_, либо _Гарпуном_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Гарпун',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Гарпун',
      description: `Если цель — существо с размером не больше Огромного, она должна преуспеть в состязании Силы с водянником, иначе её подтянет на 20 футов в сторону ★СУЩЕСТВО★.`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 6,
        range: {
          normal: 20,
          max: 60,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
