const {
    SOURCE_DMG,
  } = require('./../../../../sourceList'),
  {
    CR_0,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
    SENSE_TRUE_VISION,
  } = require('./../../../../senseList'),
  {
    LANG_ITS_SUMMONER,
  } = require('./../../../../languageIdList'),
  {
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_UNCONSCIOUS,
  } = require('./../../../../conditionList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_INCORPOREAL_MOVEMENT,
    ABILITY_TURN_IMMUNITY,
  } = require('./../../../../creatureAbilityList'),
  {
    MAGIC_ITEM_DECK_OF_MANY_THINGS,
  } = require('./../../../../magicItemIdList'),
  {
    CREATURE_AVATAR_OF_DEATH,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Образ смерти',
  nameEn: 'Avatar of Death',
  id: CREATURE_AVATAR_OF_DEATH,
  description: `Создаётся картой Череп из _[Колоды многих вещей](MAGIC_ITEM:${MAGIC_ITEM_DECK_OF_MANY_THINGS})_`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_DMG,
    page: 173,
  },
  armor: 20,
  hp: 'половина от максимума хитов вызвавшего его персонажа',
  speed: {
    [SPEED_WALK]: 60,
    [SPEED_FLY]: {
      value: 60,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 16,
  },
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_TRUE_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ITS_SUMMONER,
  ],
  cr: CR_0,
  featureList: [
    ABILITY_TURN_IMMUNITY,
    ABILITY_INCORPOREAL_MOVEMENT,
  ],
  actionList: [
    {
      name: 'Коса смерти',
      description: `★СУЩЕСТВО★ проводит призрачной косой сквозь существо, находящееся в пределах 5 футах от него, причиняя рубящий урон 7 (1к8 + 3) плюс урон некротической энергией 4 (1к8).`,
    },
  ],
}
