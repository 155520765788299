const {ABILITY_BRAVE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_4} = require('./../../../../crList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_TOA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {flamingFistDescriptionList} = require('./../../../../textCommonParts')
const {CREATURE_LIARA_PORTYR} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ATHLETICS,
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
} = require('./../../../../skillList')
const {
  GEAR_BATTLEAXE,
  GEAR_CROSSBOW_HEAVY,
  GEAR_SHIELD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
  LANG_DWARVISH,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Лиара Портир',
  nameEn: 'Liara Portyr',
  id: CREATURE_LIARA_PORTYR,
  hasSpoilers: true,
  description: [
    {
      header: 'Лиара Портир',
      text: `Как командир Форта Белуриан, Лиара проводит большую часть своего времени, беспокоясь о безопасности своей крепости, во время сделок с пиратами. В общем, у неё нет проблем с авантюристами, и она сделает все возможное, чтобы помочь им справиться со множеством угроз, скрывающихся в джунглях.

Лиара родом из влиятельной семьи из Врат Балдура и занимает пост этот в течение последних трёх лет. Для неё это назначение похоже на тест, но многие из её подчинённых в Форте Белуриан чувствует себя как изгнанники. Шпионы Лиары в Порту Нянзару снабжают её манифестами кораблей и графиками отплытия, которые она передаёт пиратам якорной стоянки Джахака в обмен на обещания никогда не атаковать корабли, плавающие под флагом Врат Балдура. Лиара также получает некую часть прибыли от пиратов, которая направлены на то, чтобы сохранить её гарнизон.`,
      source: {
        id: SOURCE_TOA,
        page: 230,
      },
    },
    {
      header: 'Лиара Портир',
      text: `Лиара Портир — главнокомандующий Форта Белуриан, а также обладатель самого высокого ранга среди всех представителей Пылающего Кулака на Чульте. Её звание соответствует рангу Пламя (майор). Она обладает свободой действий в Чульте, отчитываясь только перед герцогом Ульдером Рэйвенгардом из Врат Балдура.`,
      source: {
        id: SOURCE_TOA,
        page: 30,
      },
    },
    {
      header: 'Командующий Форта Белуриан',
      text: `Командующий Форта Белуриан — принципиально-злая пламя (майор) Пылающего Кулака по имени Лиара Портир, которая подчиняется непосредственно великому герцогу Ульдеру Рэйвенгарду Врат Балдура. Лиаре 47 лет, и она занимает свой пост в течение последних трёх лет. Для неё назначение в Чульт выглядит как проверка на стойкость.`,
      source: {
        id: SOURCE_TOA,
        page: 30,
      },
    },
    {
      header: 'Персонализация Лиары Портир',
      text: `# Идеал

_«Нужно соблюдать субординацию. Вы либо следуете моим приказам, либо умираете»._

# Привязанность

_«Я поклялась хранить и защищать Форт Белуриан во имя великого герцога Ульдера Рэйвенгарда из Врат Балдура. Пока я на посту, Форт не падёт!»_

# Слабость

_«Этот мир прогнил насквозь. У меня нет жалости к тем, кто не может защитить себя»_.`,
      source: {
        id: SOURCE_TOA,
        page: 230,
      },
    },
    ...flamingFistDescriptionList,
  ],
  note: {
    text: 'У нас не успеешь оглянуться, как попадешь на обед упырю. Хотите жить — слушайте мои приказы',
    author: 'Лиара Портир',
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_TOA,
    page: 227,
  },
  armor: {
    ac: 15,
    gearId: [GEAR_STUDDED_LEATHER_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 15,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_DRACONIC,
    LANG_DWARVISH,
  ],
  cr: CR_4,
  featureList: [
    ABILITY_BRAVE,
    {
      name: 'Пылающая ярость',
      nameEn: 'Flaming Fury',
      limit: {
        count: 1,
        period: 'ход',
      },
      description: `Когда ★СУЩЕСТВО★ попадает рукопашной атакой оружием по существу, ★он★ может магически испустить из своего оружия огонь, нанося цели дополнительно 3к6 урона огнём.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три рукопашные атаки.`,
    },
    {
      gearId: GEAR_BATTLEAXE,
    },
    {
      gearId: GEAR_CROSSBOW_HEAVY,
    },
  ],
  genderId: GENDER_FEMALE,
}
