module.exports = [
  require('./list/air_elemental_spirit'),
  require('./list/air_beast_spirit'),
  require('./list/avenger_spirit'),
  require('./list/beast_of_the_land'),
  require('./list/beast_of_the_sea'),
  require('./list/beast_of_the_sky'),
  require('./list/beholderkin_spirit'),
  require('./list/clay_construct_spirit'),
  require('./list/dancing_item'),
  require('./list/defender_spirit'),
  require('./list/demon_spirit'),
  require('./list/despair_shadowspawn_spirit'),
  require('./list/devil_spirit'),
  require('./list/earth_elemental_spirit'),
  require('./list/fear_shadowspawn_spirit'),
  require('./list/fire_elemental_spirit'),
  require('./list/fuming_spirit'),
  require('./list/fury_shadowspawn_spirit'),
  require('./list/ghostly_undead_spirit'),
  require('./list/juvenile_mimic'),
  require('./list/land_beast_spirit'),
  require('./list/metal_construct_spirit'),
  require('./list/reflection'),
  require('./list/stone_construct_spirit'),
  require('./list/mirthful_spirit'),
  require('./list/putrid_undead_spirit'),
  require('./list/skeletal_undead_spirit'),
  require('./list/slaadi_spirit'),
  require('./list/star_spawn_spirit'),
  require('./list/tricksy_spirit'),
  require('./list/water_beast_spirit'),
  require('./list/water_elemental_spirit'),
  require('./list/wildfire_spirit'),
  require('./list/yugoloth_spirit'),
]
