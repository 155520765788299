const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_BALHANNOTH,
  CREATURE_DROW,
} = require('./../../../../creatureIdList')
const {
  LANG_DEEP_SPEECH,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_LEGENDARY_RESISTANCE_2} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_BLINDED} = require('./../../../../conditionList')
const {CR_11} = require('./../../../../crList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Балханнот',
  nameEn: 'Balhannoth',
  id: CREATURE_BALHANNOTH,
  description: [
    `Родом из Царства Теней злобные, хищные балханноты меняют облик своего гнезда на место, привлекающее внимание путников. Как только они входят, на них набрасывается балханнот.`,
    {
      header: 'Ложная надежда',
      text: `Благодаря ограниченным возможностям телепатии, балханнот может учуять желания других существ и воображать места, которые те хотели бы встретить на своём пути. Затем балханнот искажает реальность, преобразовывая своё логово в то место, которое жертва желает найти. Балханноты не могут справляться с детализацией объектов, и поэтому многие несоответствия могут разоблачить иллюзию, но подделка достаточно хороша, чтобы обмануть отчаявшихся существ забрести в объятия чудища.`,
      source: {
        id: SOURCE_MTOF,
        page: 121,
      },
    },
    {
      header: 'Злобные существа',
      text: `Балханноты получают удовольствие, наживаясь на страхе и отчаянии страдающей жертвы. Оно запугивает свою добычу, пользуясь способностями, искажающими реальность, скрывая самого себя, пока не сцапает свою жертву. После чего, оно перемещается в другое место и пожирает свою добычу.`,
      source: {
        id: SOURCE_MTOF,
        page: 121,
      },
    },
    {
      header: 'Полезные рабы',
      text: `Охотничьи группы [дроу](CREATURE:${CREATURE_DROW}) и иные посетители Подземья, порой осмеливаются войти в Царство Теней чтобы изловить балханнота. Этих существ оставляют в качестве стражей, защищая проходы от вторжения врагов и отрезая им пути к отступлению, а также в качестве надсмотрщиков над рабами.`,
      source: {
        id: SOURCE_MTOF,
        page: 121,
      },
    },
    {
      header: 'Логово балханнота',
      text: `В Царстве теней, балханноты создают гнёзда рядом с местами проживания существ, на которых они ведут охоту. Чаще всего они обитают на уже проторённых дорогах и путях, похищая прохожих гуманоидов. Балханнот, что был схвачен и использован [дроу](CREATURE:${CREATURE_DROW}), может гнездиться в пещерах около переходов Подземья и сторожить проходы туда и обратно во владения дроу.

# Действия логова

Сражаясь внутри своего логова, балханнот может воспользоваться действиями гнезда. При значении инициативы «20» (в случае ничьей проигрываем всем оппонентам) балханнот может совершить действие логова, чтобы вызвать один следующих эффектов (балханнот не может использовать один и тот же эффект 2 раунда подряд):

* Балханнот искажает реальность вокруг себя на расстоянии 500 футов. Через 10 минут часть местной территории преобразуется в ту местность, о котором подумал разумный гуманоид, чьи мысли были прочитаны балханнотом (смотрите описанный раздел «Местные эффекты» ниже). Преобразования касаются только неживых объектов окружения, и не могут воссоздать движущиеся элементы и магические свойства. Любой объект, воссозданный на этой местности, при близком осмотре оказывается подделкой. К примеру, в книгах пустые страницы, а золотые предметы фальшивые и так далее. Преобразование длится до тех пор, пока балханнот не будет убит, либо ещё раз не воспользуется этим же действием логова. 
* Балханнот наводится на любое существо в пределах 500 футов от него. Существо должно успешно пройти испытание Мудрости СЛ 16, в противном случае, существо вместе с тем, во что он одет и носит, перемещается в любое свободное пространство по выбору балханнота в пределах 60 футов его видимости. 
* Балханнот выбирает целью любое существо в пределах 500 футов от него. Цель должна пройти испытание Мудрости СЛ 16, иначе балханнот становится невидимым для неё на 1 минуту. Эффект оканчивается, если балханнот проводит по цели атаку.

# Местные эффекты

Местность, в котором расположено логово балханнота искажается под воздействием сверхъестественных способностей существа, создающих один или более из следующих эффектов:

* Существа в пределах одной мили от логова балханнота испытывают ощущение, что они близки к тому месту, к которому желали больше всего попасть. Приближаясь к логову балханнота, это ощущение становится всё сильнее и сильнее. 
* Балханнот может чуять сильнейшие желания любого гуманоида в пределах 1 мили от него и извлекает оттуда любое место, с которым связано это желание; безопасное место для отдыха, храм, дом или любое другое место.

Если балханнот умирает, эти эффекты немедленно оканчиваются.`,
      source: {
        id: SOURCE_MTOF,
        page: 120,
      },
    },
  ],
  note: {
    text: `В Царстве Теней нет моральных ценностей. Даже мечта карается смертью в объятиях балханнота`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 121,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 25,
    [SPEED_CLIMB]: 25,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 18,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 8,
  },
  saveThrowList: [
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_BLINDED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 500,
      isBlindOutside: true,
    },
  ],
  languageList: [
    {
      id: LANG_DEEP_SPEECH,
      onlyUnderstands: true,
    },
    {
      id: LANG_TELEPATHY,
      range: '1 миля',
    },
  ],
  cr: CR_11,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_2,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Балханнот совершает одну атаку _Укусом_ и до двух атак _Щупальцем_, либо совершает до четырёх атак _Щупальцем_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Щупальце',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
      description: `Цель становится захваченной (СЛ высвобождения 15) и притягивается на 5 футов ближе к балханноту. Пока цель схвачена, она обездвижена и балханнот не может использовать это щупальце против другой цели. У балханнота 4 щупальца.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака укусом',
      description: `Балханнот совершает одну атаку _Укусом_ по существу, захваченному его щупальцем.`,
    },
    {
      name: 'Телепортация',
      description: `Балханнот магическим образом телепортируется вместе с захваченным существом, со всем своим несомым и носимым снаряжением, на расстояние до 60 футов в свободное пространство, которое он видит.`,
    },
    {
      name: 'Исчезновение',
      description: `Балханнот магическим образом становится невидимым до 10 минут, либо пока не совершит бросок атаки.`,
    },
  ],
}
