module.exports = [
  {
    text: `Иго свежевателей разума настолько травмировало гитьянки и гитцераев, что они забыли, что когда-то были одной, единой расой. Вырвав свою свободу, они начали войну друг против друга с ненавистью, которую никто не может осознать`,
    author: `Аристул Жёлтый, магистр планарных знаний`,
  },
  {
    text: `Гитцераи — шах гитьянкам и иллитидам, а гитьянки — шах иллитидам. И получается у нас, что эти три неравные силы образуют Баланс.`,
    author: `Архимаг Морденкайнен`,
  },
]
