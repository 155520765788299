const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
  SOURCE_ERRATA_MONSTER_MANUAL,
} = require('./../../../../sourceList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_KEEN_HEARING} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_HOOK_HORROR} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_HOOK_HORROR} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')

module.exports = {
  name: 'Крюкастый ужас',
  nameEn: 'Hook Horror',
  id: CREATURE_HOOK_HORROR,
  description: [
    {
      header: 'Крюкастый ужас',
      text: `Являясь яростным хищником Подземья, крюкастый ужас защищает свои охотничьи угодья. В подземных пещерах, где обитают эти существа, постоянное скрипучее эхо от крюков, которыми эти существа царапают стены.

У чудовищного крюкастого ужаса голова как у грифа, туловище огромного жука с панцирем из костяных наростов. Своё название он получил из-за длинных, крепко сложенных рук и ног, которые заканчиваются искривлёнными крючковатыми когтями.`,
      source: {
        id: SOURCE_MM,
        page: 181,
      },
    },
    {
      header: 'Эхо в темноте',
      text: `Крюкастые ужасы общаются путём ударов крючьев о панцирь или окружающую каменную поверхность. То, что звучит для других как случайные постукивания, на самом деле — сложный язык, который гулко разносится на многие мили по Подземью и понятен только крюкастым ужасам.`,
      source: {
        id: SOURCE_MM,
        page: 181,
      },
    },
    {
      header: 'Стайные хищники',
      text: `Всеядные крюкастые ужасы едят лишайники, грибы, растения и всех существ, которых могут поймать. Конечности крюкастого ужаса дают ему отличную устойчивость на поверхности скал. Они используют свои навыки лазания, чтобы устраивать засады на потолке. Крюкастые ужасы охотятся стаями, работая вместе против крупнейших и самых опасных противников. Если бой идёт не в его пользу, крюкастый ужас быстро поднимается на стену пещеры, чтобы сбежать.`,
      source: {
        id: SOURCE_MM,
        page: 181,
      },
    },
    {
      header: 'Обособленные кланы',
      text: `Крюкастые ужасы живут в обширных группах семей или кланов. Каждый клан управляется старшей женской особью, чей партнёр, как правило, отвечает за охотников клана. Крюкастые ужасы откладывают яйца, которые сгруппированы в центральной, хорошо защищённой пещере клана.`,
      source: {
        id: SOURCE_MM,
        page: 181,
      },
    },
    {
      header: 'Крюкастый ужас',
      text: `Крюкастый ужас — это двуногий подземный обитатель, похожий на грифа в хитиновом панцире и с острыми крючьями вместо передних лап.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_N,
  source: [
    {
      id: SOURCE_MM,
      page: 181,
    },
    {
      id: SOURCE_ERRATA_MONSTER_MANUAL,
      page: 1,
    },
  ],
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 15,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_HOOK_HORROR,
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Эхолокация',
      description: `★СУЩЕСТВО★ не может использовать слепое зрение, будучи оглохшим.`,
    },
    ABILITY_KEEN_HEARING,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Крюками_.`,
    },
    {
      name: 'Крюк',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
