const {PC_SUBCLASS_BARBARIAN_PATH_OF_THE_TOTEM_WARRIOR} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  SPELL_BEAST_SENSE,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_SPEAK_WITH_ANIMALS,
} = require('./../../../spellIdList')

const {
  FEATURE_ASPECT_OF_THE_BEAST,
  FEATURE_RAGE,
  FEATURE_SPIRIT_SEEKER,
  FEATURE_SPIRIT_WALKER,
  FEATURE_TOTEM_SPIRIT,
  FEATURE_TOTEMIC_ATTUNEMENT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_SPIRIT_SEEKER,
    name: `Искатель духов`,
    nameEn: `Spirit Seeker`,
    lvl: 3,
    text: `Ваш путь, тесно связанный с дикой природой, даёт Вам близость с животными.

Вы получаете возможность использовать заклинания [Животные чувства](SPELL:${SPELL_BEAST_SENSE}) и [Разговор с животными](SPELL:${SPELL_SPEAK_WITH_ANIMALS}), но только в виде ритуалов.`,
    spellIdList: [
      SPELL_BEAST_SENSE,
      SPELL_SPEAK_WITH_ANIMALS,
    ],
  },
  {
    id: FEATURE_TOTEM_SPIRIT,
    name: `Тотемный дух`,
    nameEn: `Totem Spirit`,
    lvl: 3,
    text: `Вы выбираете своего тотемного духа, и получаете его умения.

Вы должны сделать или приобрести тотем: физический предмет (амулет или похожее украшение), содержащий мех или кожу, перья, когти, зубы или кости тотемного животного. Если хотите, можете приобрести незначительные физические изменения, напоминающие Ваше тотемное животное. Например, если Вашим тотемным животным является медведь, Вы можете получить необычную волосатость и толстокожесть, а если это орёл, то Ваши глаза могут приобрести ярко-жёлтый цвет.

Ваше тотемное животное должно относиться к одному из перечисленных ниже, но быть естественным для Вашей родной местности. Например, вместо орла может быть ястреб или гриф.

# Волк

Пока Вы находитесь в состоянии [Ярости](FEATURE:${FEATURE_RAGE}), Ваши друзья совершают броски рукопашных атак по всем враждебным Вам существам, находящимся в пределах 5 футов от Вас, с преимуществом. Дух волка делает Вас вожаком стаи.

# Медведь

В состоянии [Ярости](FEATURE:${FEATURE_RAGE}) Вы получаете сопротивление ко всем видам урона, кроме урона психической энергией. Дух медведя делает Вас достаточно крепким, чтобы выдержать любое испытание.

# Орёл

Когда Вы находитесь в состоянии [Ярости](FEATURE:${FEATURE_RAGE}) и не носите тяжёлых доспехов, другие существа совершают провоцированные атаки по Вам с помехой. В свой ход Вы можете совершать Рывок бонусным действием. Дух орла превращает Вас в хищника, с лёгкостью носящегося по полю боя.`,
  },
  {
    id: FEATURE_ASPECT_OF_THE_BEAST,
    name: `Аспект зверя`,
    nameEn: `Aspect of the Beast`,
    lvl: 6,
    text: `Вы получаете магическую способность, зависящую от выбранного Вами тотемного животного. Вы можете выбрать то же животное, что и на 3 уровне, либо другое.

# Волк

Вы получаете чутьё охотящегося волка. Вы можете идти по следу существа, путешествуя в быстром темпе, или передвигаться скрытно, путешествуя в нормальном темпе.

# Медведь

Вы получаете мощь медведя. Ваша способность переносить тяжести (включая максимальный вес нагрузки и подъёма) удваивается, и Вы совершаете с преимуществом проверки Силы, совершённые, чтобы толкать, тянуть, поднимать или ломать предметы.

# Орёл

Вы получаете зоркость орла. Вы без затруднений можете видеть на расстоянии до одной мили, и способны различить мельчайшие детали, рассматривая что-то на расстоянии до 100 футов (30 метров). Кроме того, тусклое освещение более не накладывает помеху на проверки Мудрости (Внимательность).`,
  },
  {
    id: FEATURE_SPIRIT_WALKER,
    name: `Гуляющий с духами`,
    nameEn: `Spirit Walker`,
    lvl: 10,
    text: `Вы получаете способность использовать заклинание [Общение с природой](SPELL:${SPELL_COMMUNE_WITH_NATURE}) в качестве ритуала.

Когда Вы делаете это, призрачная версия одного из Ваших тотемных животных является Вам и сообщает необходимую информацию.`,
    spellIdList: SPELL_COMMUNE_WITH_NATURE,
  },
  {
    id: FEATURE_TOTEMIC_ATTUNEMENT,
    name: `Гармония тотема`,
    nameEn: `Totemic Attunement`,
    lvl: 14,
    text: `Вы получаете магическую способность, зависящую от выбранного Вами тотемного животного. Вы можете выбрать то же животное, что и ранее, либо другое.

# Волк

Пока Вы в состоянии [Ярости](FEATURE:${FEATURE_RAGE}), Вы можете в свой ход бонусным действием сбить с ног существо Большого или менее размера, когда попадаете по нему атакой рукопашным оружием.

# Медведь

Пока Вы в состоянии [Ярости](FEATURE:${FEATURE_RAGE}), все враждебные существа в пределах 5 футов от Вас совершают с помехой броски атак, нацеленных не на Вас и не на другое существо с таким же свойством. Противники получают иммунитет к этому эффекту, если не могут видеть или слышать Вас, либо если они не могут быть напуганы.

# Орёл

Находясь в состоянии [Ярости](FEATURE:${FEATURE_RAGE}), Вы приобретаете скорость полёта, равную Вашей скорости передвижения. Это преимущество работает только в коротком промежутке времени. Если Вы завершите свой ход в воздухе, где ничто не будет Вас поддерживать, Вы упадёте.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_TOTEM_WARRIOR,
    source: {
      id: SOURCE_PHB,
      page: 51,
    },
  })
)
