const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_MIMIC} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_MIMIC,
  nameEn: 'Mimic',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  description: {
    text: `Мимики — хищники, способные принимать форму неодушевлённых предметов, чтобы заманивать добычу в ловушку. В подземельях эти коварные существа, как правило, принимают вид дверей и сундуков, так как именно подобная форма привлекает к ним постоянный поток добычи.`,
    source: {
      id: SOURCE_MM,
      page: 210,
    },
  },
  name: {
    singular: {
      nominative: 'мимик',
      genitive: 'мимика',
      dative: 'мимику',
      accusative: 'мимика',
      instrumental: 'мимиком',
      prepositional: 'мимике',
    },
    plural: {
      nominative: 'мимики',
      genitive: 'мимиков',
      dative: 'мимикам',
      accusative: 'мимиков',
      instrumental: 'мимиками',
      prepositional: 'мимиках',
    },
  },
}
