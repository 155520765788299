const {
  LANG_TYPE_EXOTIC,
} = require('./../../languageTypeList')
const {
  LANG_INFERNAL,
} = require('./../../languageIdList')
const {
  ALPHABET_INFERNAL,
} = require('./../../alphabetList')

module.exports = {
  id: LANG_INFERNAL,
  alphabetId: ALPHABET_INFERNAL,
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Инфернальный',
    genitive: 'Инфернального',
    instrumental: 'Инфернальным',
    prepositional: 'Инфернальном',
  },
  nameEn: 'Infernal',
  nameAlt: 'Дьявольский',
  typicalSpeakers: 'Дьяволы',
  isRealLang: true,
  isReady: false,
}
