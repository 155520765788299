const {
  SPELL_CHILL_TOUCH,
  SPELL_CONFUSION,
  SPELL_COUNTERSPELL,
  SPELL_DANCING_LIGHTS,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DISGUISE_SELF,
  SPELL_DISINTEGRATE,
  SPELL_DOMINATE_MONSTER,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_FLY,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_INVISIBILITY,
  SPELL_LEVITATE,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MIRROR_IMAGE,
  SPELL_MODIFY_MEMORY,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PLANE_SHIFT,
  SPELL_PRESTIDIGITATION,
  SPELL_SCORCHING_RAY,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
  SPELL_WALL_OF_FORCE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_DEEP_SPEECH,
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_TURN_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  alhoonDescriptionList,
  illithidDescriptionList,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_ANY_EVIL} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_SPELL} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_ALHOON} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Алхун',
  nameEn: 'Alhoon',
  id: CREATURE_ALHOON,
  description: [
    ...alhoonDescriptionList,
    ...illithidDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_ANY_EVIL,
  source: {
    id: SOURCE_VGTM,
    page: 188,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 16,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NECROTIC,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_10,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_TURN_RESISTANCE,
  ],
  spellCast: [
    {
      comment: 'псионика',
      baseStat: PARAM_INT,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DETECT_THOUGHTS,
            SPELL_LEVITATE,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_DOMINATE_MONSTER,
            {
              id: SPELL_PLANE_SHIFT,
              comment: 'только на себя',
            },
          ],
        },
      ],
    },
    {
      spellCasterLevel: 12,
      spellCasterClass: PC_CLASS_WIZARD,
      spellIdList: [
        SPELL_CHILL_TOUCH,
        SPELL_CONFUSION,
        SPELL_COUNTERSPELL,
        SPELL_DANCING_LIGHTS,
        SPELL_DETECT_MAGIC,
        SPELL_DISGUISE_SELF,
        SPELL_DISINTEGRATE,
        SPELL_EVARD_S_BLACK_TENTACLES,
        SPELL_FLY,
        SPELL_GLOBE_OF_INVULNERABILITY,
        SPELL_INVISIBILITY,
        SPELL_LIGHTNING_BOLT,
        SPELL_MAGE_HAND,
        SPELL_MAGIC_MISSILE,
        SPELL_MIRROR_IMAGE,
        SPELL_MODIFY_MEMORY,
        SPELL_PHANTASMAL_KILLER,
        SPELL_PRESTIDIGITATION,
        SPELL_SCORCHING_RAY,
        SPELL_SHIELD,
        SPELL_SHOCKING_GRASP,
        SPELL_WALL_OF_FORCE,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        2,
        1,
      ],
    },
  ],
  actionList: [
    {
      name: 'Хладные объятия',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_COLD,
          diceCount: 3,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
    {
      name: 'Взрыв разума',
      restore: 5,
      description: `Алхун магически излучает психическую энергию в конусе в 60 футов. Каждое существо в этой области должно пройти испытание Интеллекта СЛ 16 или получить 22 (4к8+4) психического урона и стать ошелёмлённым на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, заканчивая эффект на себе при успехе.`,
    },
  ],
}
