const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {GENDER_FEMALE} = require('./../../genderList')
const {CREATURE_TYPE_ABERRATION} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ABERRATION,
  nameEn: 'Aberration',
  genderId: GENDER_FEMALE,
  description: [
    {
      text: `Аберрации — абсолютно чужеродные существа. Многие из них имеют врождённые магические способности, однако обусловлены они их чужеродным разумом, а не мистическими силами мира. Наиболее известные аберрации — аболеты, злобоглазы, свежеватели разума и слаады.`,
      source: {
        id: SOURCE_MM,
        page: 8,
      },
    },
    {
      header: 'Аберрации',
      text: `Искажённые, чуждые Фаэруну пришельцы из иных миров мультивселенной`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  name: {
    singular: {
      nominative: 'аберрация',
      genitive: 'аберрации',
      dative: 'аберрации',
      accusative: 'аберрацию',
      instrumental: 'аберрацией',
      prepositional: 'аберрации',
    },
    plural: {
      nominative: 'аберрации',
      genitive: 'аберраций',
      dative: 'аберрациям',
      accusative: 'аберраций',
      instrumental: 'аберрациями',
      prepositional: 'аберрациях',
    },
  },
}
