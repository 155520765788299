const {
  CREATURE_CAVE_BEAR,
  CREATURE_POLAR_BEAR,
} = require('./../../../../creatureIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')

module.exports = {
  name: 'Пещерный медведь',
  nameEn: 'Cave Bear',
  id: CREATURE_CAVE_BEAR,
  parentId: CREATURE_POLAR_BEAR,
  description: {
    header: 'Вариант: Пещерный медведь',
    text: `Некоторые медведи адаптировались к жизни под землёй, где они питаются подземными лишайниками и слепой рыбой. Известные как пещерные медведи, эти агрессивные чудища с грубой тёмной шерстью наделены тёмным зрением с радиусом 60 футов. В остальном у них статистика как у [белого медведя](CREATURE:${CREATURE_POLAR_BEAR}).`,
  },
  extendPropCollection: {
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
  },
}
