const listToCollectionById = require('./../utils/listToCollectionById')

const ATTACK_MELEE_SPELL = 'melee_spell'
const ATTACK_MELEE_WEAPON = 'melee_weapon'
const ATTACK_RANGE_SPELL = 'range_spell'
const ATTACK_RANGE_WEAPON = 'range_weapon'

const attackTypeList = [
  {
    id: ATTACK_MELEE_WEAPON,
    name: 'Рукопашная атака оружием',
  },
  {
    id: ATTACK_RANGE_WEAPON,
    name: 'Дальнобойная атака оружием',
  },
  {
    id: ATTACK_MELEE_SPELL,
    name: 'Рукопашная атака заклинанием',
  },
  {
    id: ATTACK_RANGE_SPELL,
    name: 'Дальнобойная атака заклинанием',
  },
]

module.exports = attackTypeList

module.exports.attackTypeCollection = listToCollectionById(attackTypeList)

module.exports.ATTACK_MELEE_SPELL = ATTACK_MELEE_SPELL
module.exports.ATTACK_MELEE_WEAPON = ATTACK_MELEE_WEAPON
module.exports.ATTACK_RANGE_SPELL = ATTACK_RANGE_SPELL
module.exports.ATTACK_RANGE_WEAPON = ATTACK_RANGE_WEAPON
