const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {PLACE_GREAT_GLACIER} = require('./../../placeIdList')
const {LANG_ULUIK} = require('./../../languageIdList')
const {LANG_SUBGROUP_HIGH_ULUTIM} = require('./../../languageSubGroupList')

module.exports = {
  id: LANG_ULUIK,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_HIGH_ULUTIM,
  nameEn: 'Uluik',
  name: {
    nominative: 'Улуик',
    genitive: 'Улуика',
    instrumental: 'Улуиком',
    prepositional: 'Улуике',
  },
  typicalSpeakers: 'Улутиуне',
  spokenPlaceList: PLACE_GREAT_GLACIER,
  isReady: false,
  isRealLang: true,
}
