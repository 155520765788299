const {
  SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CELESTIAL,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_CELESTIAL,
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NG,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_RADIANT,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  CREATURE_DEFENDER_SPIRIT,
  CREATURE_CELESTIAL_DEFENDER_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  SPELL_SUMMON_CELESTIAL_SPIRIT_2020_03_26,
} = require('./../../../../spellIdList')

module.exports = {
  name: 'Дух небожителя-защитника',
  nameEn: 'Celestial Defender Spirit',
  id: CREATURE_CELESTIAL_DEFENDER_SPIRIT_2020_03_26,
  releasedAs: CREATURE_DEFENDER_SPIRIT,
  createdBySpellId: SPELL_SUMMON_CELESTIAL_SPIRIT_2020_03_26,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_NG,
  source: {
    id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
    page: 4,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `модификатор Телосложения духа + модификатор заклинательной характеристики заклинателя + десятикратный круг заклинания`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  resistanceList: [
    DAMAGE_RADIANT,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_CELESTIAL,
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Светящаяся булава',
      description: '★СУЩЕСТВО★ может выбрать себя или другое существо, видимое им в пределах 10 футов от цели. Выбранное существо получает временные хиты в размере нанесённого урона, если у него ещё не было временных хитов.',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 5,
        hit: {
          type: DAMAGE_RADIANT,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
    {
      name: 'Целебное касание',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ прикасается к другому существу. Цель магическим образом восстанавливает 8 (2к8) + круг заклинания хитов.`,
    },
  ],
  genderId: GENDER_MALE,
}
