const PROGRESS_TABLE_ID = require('./../../PROGRESS_TABLE_ID')
const SPELL_SLOT_TABLE_ID = require('./../../SPELL_SLOT_TABLE_ID')
const {fullCasterSpellCircleList} = require('./../../spellCircleByCasterType')
const {PC_CLASS_CLERIC} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
} = require('./../../sourceList')
const {SPELL_CURE_WOUNDS} = require('./../../spellIdList')
const {spellIdCollectionByPcClassId} = require('./../../spellIdPcClassIdBounds')

const {abilityScoreImprovementTemplate} = require('./../featureCommonRawList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_CLERIC,
  FEATURE_BLESSED_STRIKES,
  FEATURE_CANTRIP_VERSATILITY_CLERIC,
  FEATURE_CANTRIPS_CLERIC,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_TURN_UNDEAD,
  FEATURE_DESTROY_UNDEAD,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_INTERVENTION,
  FEATURE_DOMAIN_SPELLS,
  FEATURE_HARNESS_DIVINE_POWER_CLERIC,
  FEATURE_PREPARING_SPELLS_CLERIC,
  FEATURE_RITUAL_CASTING_CLERIC,
  FEATURE_SPELLCASTING_ABILITY_CLERIC,
  FEATURE_SPELLCASTING_CLERIC,
  FEATURE_SPELLCASTING_FOCUS_CLERIC,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_SPELLCASTING_CLERIC,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 1,
    text: `Будучи проводником божественной силы, Вы можете творить заклинания жреца.`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_CLERIC],
    source: {
      id: SOURCE_PHB,
      page: 58,
    },
  },
  {
    id: FEATURE_CANTRIPS_CLERIC,
    name: `Заговоры`,
    nameEn: `Cantrips`,
    lvl: 1,
    text: `На 1 уровне Вы знаете три заговора на свой выбор из списка заклинаний жреца.

Вы узнаёте дополнительные заговоры жреца на более высоких уровнях, как показано в колонке _«[Заговоры](PC_CLASS:${PC_CLASS_CLERIC}#${PROGRESS_TABLE_ID})»_.`,
    source: {
      id: SOURCE_PHB,
      page: 58,
    },
  },
  {
    id: FEATURE_PREPARING_SPELLS_CLERIC,
    name: `Подготовка и сотворение заклинаний`,
    nameEn: `Preparing and Casting Spells`,
    lvl: 1,
    text: `Таблица «[Ячейки заклинаний жреца](PC_CLASS:${PC_CLASS_CLERIC}#${SPELL_SLOT_TABLE_ID})» показывает, какое количество ячеек для сотворения заклинаний у Вас есть на первом и более высоких уровнях. Для сотворения одного из этих заклинаний Вы должны потратить ячейку заклинаний того же круга или выше, что и само заклинание. Вы восстановите все потраченные ячейки, когда завершите длинный отдых.

Вы подготавливаете список заклинаний жреца, доступных для сотворения. При этом Вы выбираете число заклинаний жреца, равное модификатору Мудрости + уровень жреца (минимум одно заклинание). Круг заклинаний не должен превышать круга самой высокой имеющейся у Вас ячейки заклинаний.

Например, если Вы жрец 3 уровня, то у Вас есть четыре ячейки заклинаний 1 круга и две ячейки 2 круга. При Мудрости 16 Ваш список подготовленных заклинаний может включать в себя шесть заклинаний 1 или 2 круга, в любой комбинации. Если Вы подготовили заклинание 1 круга [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}), Вы можете сотворить его, используя ячейку 1 или 2 круга. Сотворение заклинания не удаляет его из списка подготовленных заклинаний.

Вы можете изменить список подготовленных заклинаний, когда завершаете длинный отдых. Подготовка нового списка заклинаний жреца требует времени, проведённого в молитвах и медитации: не менее 1 минуты за круг заклинания для каждого заклинания в Вашем списке.`,
    source: {
      id: SOURCE_PHB,
      page: 58,
    },
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_CLERIC,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 1,
    text: `При создании заклинаний жрец использует Мудрость. Сила Ваших заклинаний исходит от Вашей преданности своему божеству.

Вы используете Мудрость в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете модификатор Мудрости при определении СЛ испытаний от Ваших заклинаний жреца, и при броске атаки заклинаниями.

**СЛ испытания** = 8 + бонус мастерства + модификатор Мудрости

**Модификатор броска атаки** = бонус мастерства + модификатор Мудрости`,
    source: {
      id: SOURCE_PHB,
      page: 58,
    },
  },
  {
    id: FEATURE_RITUAL_CASTING_CLERIC,
    name: `Ритуальное колдовство`,
    nameEn: `Ritual Casting`,
    lvl: 1,
    text: `Вы можете сотворить заклинание жреца как ритуал, если у этого заклинания есть ключевое слово «ритуал», и оно Вами подготовлено.`,
    source: {
      id: SOURCE_PHB,
      page: 58,
    },
  },
  {
    id: FEATURE_SPELLCASTING_FOCUS_CLERIC,
    name: `Фокусировка заклинания`,
    nameEn: `Spellcasting Focus`,
    lvl: 1,
    text: `Вы можете использовать священный символ в качестве заклинательной фокусировки для заклинаний жреца.`,
    source: {
      id: SOURCE_PHB,
      page: 59,
    },
  },
  {
    id: FEATURE_DIVINE_DOMAIN,
    name: `Божественный домен`,
    nameEn: `Divine Domain`,
    lvl: [1, 2, 6, 8, 17],
    text: `Выберите один домен, связанный с Вашим божеством.

Ваш выбор даёт Вам [Заклинания домена](FEATURE:${FEATURE_DOMAIN_SPELLS}) и другие умения. Он также даёт Вам дополнительные способы использования [Божественного канала](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), когда Вы получаете это умение на 2 уровне, и дополнительные умения на 6, 8 и 17 уровнях.`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 59,
    },
  },
  {
    id: FEATURE_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `У каждого домена есть список заклинаний, которые Вы получаете на новых уровнях жреца. Как только Вы получаете заклинание домена, оно всегда считается подготовленным и не учитывается при подсчёте количества заклинаний, которые можно подготовить.

Если Вы получаете доступ к заклинанию, отсутствующему в списке заклинаний жреца, оно всё равно будет считаться для Вас заклинанием жреца.`,
    source: {
      id: SOURCE_PHB,
      page: 59,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_CLERIC,
    name: `Божественный канал`,
    nameEn: `Channel Divinity`,
    lvl: [2, 6, 18],
    text: `Вы получаете возможность направлять божественную энергию непосредственно от своего божества, используя её для подпитки магических эффектов. Вы начинаете с двумя такими эффектами: [Изгнание нежити](FEATURE:${FEATURE_CHANNEL_DIVINITY_TURN_UNDEAD}) и эффектом, определяемым Вашим доменом. Некоторые домены дают Вам дополнительные эффекты, как только Вы получите новые уровни.

Когда Вы используете _Божественный канал_, то выбираете, какой эффект создать. Затем Вы должны окончить короткий или длинный отдых, чтобы использовать _Божественный канал_ снова. Некоторые эффекты _Божественного канала_ требуют пройти испытание. Когда Вы используете эффекты такого рода, СЛ равна СЛ испытания от Ваших заклинаний жреца.

Начиная с 6 уровня Вы можете использовать _Божественный канал_ дважды перед отдыхом, а начиная с 18 уровня, Вы можете использовать его три раза перед отдыхом. По окончанию короткого или длинного отдыха Вы восстановите все потраченные использования.

| Уровень жреца | Использований _Божественного канала_ |
|---------------|--------------------------------------|
|  1            | 1                                    |
|  6            | 2                                    |
| 18            | 3                                    |
`,
    source: {
      id: SOURCE_PHB,
      page: 59,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_TURN_UNDEAD,
    name: `Божественный канал: Изгнание нежити`,
    nameEn: `Channel Divinity: Turn Undead`,
    lvl: 2,
    text: `Вы действием демонстрируете свой священный символ и читаете молитву, изгоняющую нежить.

Вся нежить, которая может видеть или слышать Вас в пределах 30 футов, должна пройти испытание Мудрости. Если существо провалило испытание, оно изгоняется на 1 минуту, или пока не получит урон.

Изгнанное существо должно тратить свои ходы, пытаясь уйти от Вас как можно дальше, и не может добровольно переместиться в пространство, находящееся в пределах 30 футов от Вас. Оно также не может совершать реакции. Действием существо может использовать только Рывок или пытаться освободиться от эффекта, препятствующего его передвижению. Если двигаться некуда, существо может использовать действие Уклонение.`,
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
  {
    id: FEATURE_HARNESS_DIVINE_POWER_CLERIC,
    name: `Усмирение божественной мощи`,
    nameEn: `Harness Divine Power`,
    lvl: [2, 6, 18],
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}) для подпитки своих заклинаний.

Вы бонусным действием касаетесь своего священного символа, произносите молитву и восстанавливаете одну израсходованную ячейку заклинаний, круг которой не превышает половину Вашего бонуса мастерства (округляя вверх).

Количество раз, которое Вы можете использовать эту способность, зависит от уровня в этом классе: один раз на 2 уровне; дважды на 6 уровне; и трижды на 18 уровне.

Вы восстанавливаете все израсходованные использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 40,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_CLERIC,
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
  {
    id: FEATURE_CANTRIP_VERSATILITY_CLERIC,
    name: `Гибкость заговоров`,
    nameEn: `Cantrip Versatility`,
    lvl: abilityScoreImprovementTemplate.lvl,
    text: `Всякий раз, достигая в этом классе нового уровня, на котором Вы получаете способность [Увеличения характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_CLERIC}), Вы можете заменить один заговор, который Вы выучили способностью [Заговоры](FEATURE:${FEATURE_SPELLCASTING_CLERIC}) этого класса, другим заговором из списка заклинаний жреца.`,
    source: {
      id: SOURCE_TCoE,
      page: 41,
    },
  },
  {
    id: FEATURE_DESTROY_UNDEAD,
    name: `Уничтожение нежити`,
    nameEn: `Destroy Undead`,
    lvl: 5,
    text: `Когда нежить проваливает испытание от Вашего умения [Изгнание нежити](FEATURE:${FEATURE_CHANNEL_DIVINITY_TURN_UNDEAD}), существо мгновенно уничтожается, если его показатель опасности не превышает значения, указанного в таблице:

| Уровень жреца | Уничтожается нежить с ПО |
|---------------|--------------------------|
|  5            | ½ или ниже               |
|  8            | 1 или ниже               |
| 11            | 2 или ниже               |
| 14            | 3 или ниже               |
| 17            | 4 или ниже               |
`,
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
  {
    id: FEATURE_BLESSED_STRIKES,
    name: `Благословлённые удары`,
    nameEn: `Blessed Strikes`,
    isOptional: true,
    lvl: 8,
    text: `_Опциональная способность. Вы можете заменить этой способностью способность «Божественный удар» или «Мощное заклинание» у подклассов жреца._

Вы благословлены божественной мощью в бою.

Когда существо получает урон от одного из Ваших заговоров или атак оружием, Вы можете нанести ему дополнительно 1к8 урона излучением.

Нанеся этот урон, Вы не сможете снова использовать эту способность до начала своего следующего хода.`,
    source: {
      id: SOURCE_TCoE,
      page: 41,
    },
  },
  {
    id: FEATURE_DIVINE_INTERVENTION,
    name: `Божественное вмешательство`,
    nameEn: `Divine Intervention`,
    lvl: [10, 20],
    text: `Вы можете воззвать к своему божеству о помощи, когда сильно в этом нуждаетесь.

Мольба о помощи совершается действием. Опишите помощь, которую Вы ждёте, и бросьте к100. Если выпадет число, не превышающее Ваш уровень жреца, Ваше божество вмешается. Мастер сам выбирает природу вмешательства; подойдёт эффект какого-либо заклинания жреца или заклинания домена.

Если божество вмешивается, Вы не можете использовать это умение в течение 7 дней. В противном случае Вы можете использовать это умение после длинного отдыха.

На 20 уровне Ваше воззвание автоматически успешно и не требует проверки.`,
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_CLERIC,
  })
)
