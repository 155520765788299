const arrify = require('arrify')
const uniq = require('lodash/uniq')

const SEARCH_PROP_NAME = require('./../SEARCH_PROP_NAME')
const { gearCategoryCollection } = require('./../gearCategoryList')
const { weaponPropCollection, WEAPON_RANGE } = require('./../weaponPropList')
const { GENDER_MALE } = require('./../genderList')

const listToCollectionById = require('./../../utils/listToCollectionById')
const prepareForSearch = require('./../../utils/prepareForSearch')
const checkIsFromUA = require('./../../utils/checkIsFromUA')
const getParentCatIdList = require('./../../utils/getParentCatIdList')

const gearRawList = require('./gearRawList')

const getCatName = catIdList => catIdList
  ? arrify(catIdList)
    .map(
      catId => gearCategoryCollection[catId].name,
    )
    .join('\n')
  : ''

const generateWeaponPropNameString = list => list
  .map(
    item => weaponPropCollection[item.id || item].name,
  )
  .join(' ')

const gearList = gearRawList
  .map(
    gear => {
      const catIdList = uniq(
        arrify(gear.category).reduce(
          (list, catId) => [
            ...list,
            catId,
            ...getParentCatIdList(
              gearCategoryCollection[catId],
              gearCategoryCollection
            ),
          ],
          []
        )
      )

      const range = gear.weaponPropList
        ? gear.weaponPropList.reduce(
          (range, {id, value}) => id === WEAPON_RANGE
            ? value
            : range,
          gear.range
        )
        : gear.range

      return {
        ...gear,
        category: catIdList,
        range,
        genderId: gear.genderId || GENDER_MALE,
        isUA: checkIsFromUA(gear.source),
        [SEARCH_PROP_NAME]: prepareForSearch(
          [
            gear.name,
            gear.nameAlt || '',
            gear.nameEn,
            gear.description,
            getCatName(catIdList),
            generateWeaponPropNameString(gear.weaponPropList || []),
          ]
            .filter(
              e => e,
            )
            .map(
              e => String(e).toLowerCase(),
            )
            .join(' '),
        ),
      }
    },
  )
  .sort(
    ({ name: A }, { name: B }) => A > B ? 1 : -1,
  )

module.exports = gearList

module.exports.gearCollection = listToCollectionById(gearList)

module.exports.gearByCatCollection = gearList.reduce(
  (collection, {category, id}) => {
    const categoryList = arrify(category)

    return categoryList.reduce(
      (obj, catId) => obj[catId]
        ? {
          ...obj,
          [catId]: [
            ...obj[catId],
            id,
          ]
        }
        : {
          ...obj,
          [catId]: [id]
        },
      collection
    )
  },
  {}
)
