const {
  CREATURE_ELK,
  CREATURE_FROST_GIANT,
  CREATURE_POLAR_BEAR,
} = require('./../../creatureIdList')
const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Реморазы',
    text: `Взрывая толщу снега и льда, в облаке пара на поверхность прорывается пульсирующее внутренним огнём тело ремораза. Расположенные за головой похожие на крылья плавники ярко пышут, а широкая пасть до краёв наполнена острыми зубами.`,
    source: {
      id: SOURCE_MM,
      page: 250,
    },
  },
  {
    header: 'Арктический охотник',
    text: `Ремораз живёт в арктическом климате, охотясь на [лосей](CREATURE:${CREATURE_ELK}), [белых медведей](CREATURE:${CREATURE_POLAR_BEAR}) и других существ, делящих с ним территорию обитания. Он не переносит тёплой погоды, приспособившись к холоду путём генерации внутри своего тела тепла, сопоставимого с жаром печи. Во время охоты ремораз зарывается глубоко под снег и лёд, ожидая малейших вибраций, создаваемых существами над ним. Находясь в засаде, он понижает температуру тела, чтобы не плавить слой снега над собой.`,
    source: {
      id: SOURCE_MM,
      page: 250,
    },
  },
  {
    header: 'Молодняк',
    text: `Охотники [ледяных великанов](CREATURE:${CREATURE_FROST_GIANT}) прочёсывают ледяные пустоши в поисках гнёзд и яиц реморазов. Молодой ремораз является трофеем для великанов, так как его можно научить исполнять команды и охранять чертоги своих хозяев. В отличие от взрослых особей, молодой ремораз вгрызается в свою жертву, а не проглатывает её целиком.`,
    source: {
      id: SOURCE_MM,
      page: 250,
    },
  },
]
