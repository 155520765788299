const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BAPHOMET,
  CREATURE_ORC,
  CREATURE_ORC_WAR_CHIEF,
  CREATURE_TANARUKK,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_ORC,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  orcDescription,
  orcNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AGGRESSIVE,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {GEAR_GREATSWORD} = require('./../../../../gearIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Танарукк',
  nameEn: 'Tanarukk',
  id: CREATURE_TANARUKK,
  description: [
    `Когда демоническое разложение пятнает [лидеров племени](CREATURE:${CREATURE_ORC_WAR_CHIEF}), орки могут обратиться к магии Бездны для того, чтобы создать танарукков. Злые люди, которые контролируют орков тоже используют такую силу, чтобы увеличить мощь своих последователей.

Повелитель демонов [Бафомет](CREATURE:${CREATURE_BAPHOMET}) радостно делится секретом создания танарукков с теми, кто умоляет его о силе. Процесс извращает нерождённого ребенка племени, превращая его при рождении в гораздо более дикое, чем [орк](CREATURE:${CREATURE_ORC}), существо.

Хоть танарукки и внушающие ужас бойцы, они являются угрозой союзникам вне поля боя. В логове племени танарукк разрушителен и взрывоопасен, и лучше держать его взаперти. Рано или поздно танарукк пронесётся по племени и попытается завладеть им силой. Большинство таких переворотов проваливается, но с большими потерями для племени. Если танарукк получает лидерство над племенем, то он неизбежно возьмет курс на безумную войну.

Если танарукк сможет заделать потомство, то его кровь запятнает бесчисленное количество последующих поколений, так что его отдаленные потомки-женщины могут случайно родить танарукка. Большинство племён предпочитает убить эту мерзость, а не воспитывать танарукка, рождённого естественным путем.`,
    ...orcDescription,
  ],
  note: orcNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_ORC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 182,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 20,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
    LANG_ORC,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_AGGRESSIVE,
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Танарукк делает две атаки: одну _Укусом_ и одну _Двуручным мечом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      gearId: GEAR_GREATSWORD,
    },
  ],
  reactionList: [
    {
      name: 'Необузданная ярость',
      description: `В ответ на попавшую по нему рукопашную атаку, танарукк может сделать по атакующему одну рукопашную атаку оружием с преимуществом.`,
    },
  ],
}
