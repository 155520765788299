const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_WIND_WALK} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_WIND_WALK,
  name: 'Хождение по ветру',
  nameAlt: 'Путешествие с ветром',
  nameEn: 'Wind Walk',
  description: `Вы и до десяти согласных существ, видимых Вами в пределах дистанции, на время длительности заклинания принимаете газообразную форму, становясь лёгкими облачками. Находясь в форме облака, существо получает скорость полёта 300 фт. и сопротивление к урону от немагического оружия. Единственное действие, которое существо может совершать в этой форме, это Рывок или возвращение в естественную форму. Возврат занимает 1 минуту, во время которой существо недееспособно и не может перемещаться. Пока заклинание активно, существо может вернуться в форму облака, для чего снова требуется преображение в течение 1 минуты.

Если существо находится в форме облака и летит, когда оканчивается эффект, существо в течение 1 минуты снижается на 60 фт. в раунд, пока не приземлится безопасно. Если через 1 минуту оно не сможет приземлиться, оно падает на оставшуюся дистанцию.`,
  lvl: 6,
  magicSchoolId: MAGIC_TRANSMUTATION,
  castTime: { timeId: TIME_MINUTE, count: 1 },
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'огонь и святая вода',
  source: [
    {
      id: SOURCE_PHB,
      page: 288,
    },
    {
      id: SOURCE_SRD,
      page: 192,
    },
  ],
}
