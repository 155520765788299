const {
  SPELL_CONFUSION,
  SPELL_HALLOW,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_CIRCLE,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  SPELL_VICIOUS_MOCKERY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BUGBEAR,
  CREATURE_HOBGOBLIN,
  CREATURE_GOBLIN,
  CREATURE_NILBOG,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_GOBLIN,
  CREATURE_TYPE_GOBLINOID,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_SHORTBOW,
} = require('./../../../../gearIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  GOD_KHURGORBAEYAG,
  GOD_MAGLUBIYET,
} = require('./../../../../godIdList')
const {ABILITY_NIMBLE_ESCAPE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_1} = require('./../../../../crList')
const {goblinDescriptionList} = require('./../../../../textCommonParts')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Нилбог',
  nameEn: 'Nilbog',
  id: CREATURE_NILBOG,
  description: [
    `Когда [Маглубиет](GOD:${GOD_MAGLUBIYET}) подчинил себе богов [гоблинов](CREATURE:${CREATURE_GOBLIN}), он намеревался оставить в живых только [Хургорбояга](GOD:${GOD_KHURGORBAEYAG}), как жестокого надзирателя, который держал бы гоблинов в строгом подчинении. Но в пантеоне гоблинов было и божество-обманщик, которое желало посмеяться последним. Хотя его естество было разрушено [Маглубиетом](GOD:${GOD_MAGLUBIYET}), бог-обманщик выжил и в разделённой форме, как дух, который овладевает телами, когда гоблины образуют войско, внося беспорядок в их ряды, если его не умиротворят. У гоблинов нет имени для этого божества и они не решаются дать ему какое-нибудь, дабы [Маглубиет](GOD:${GOD_MAGLUBIYET}) не использовал это имя, чтобы изловить и сокрушить его, как он сделал с остальными божествами. Они называют этот дух и гоблина, которым он завладел, нилбогом (слово «гоблин» произнесенное задом наперед), и наслаждаются страхом, который нилбог сеет в рядах [багбиров](CREATURE:${CREATURE_BUGBEAR}) и [хобгоблинов](CREATURE:${CREATURE_HOBGOBLIN}) в войске.`,
    {
      header: 'Месть гоблинов',
      text: `Когда гоблиноиды образуют армию, существует шанс того, что гоблин станет одержим нилбогом, особенно если с гоблином плохо обращаются вышестоящие. Эта одержимость превращает гоблина в шаловливое, бесноватое существо, не боящееся расправы. Она даёт гоблину странные способности, которые заставляют других делать противоположное тому, что они первоначально намеревались делать. Нападение на гоблина, одержимого нилбогом, безрассудно, а убийство существа всего лишь приведет к тому, что дух незамедлительно вселится в другого гоблина. Единственный способ не дать нилбогу сеять хаос — это хорошо с ним обращаться и выказывать уважение и похвалы.`,
      source: {
        id: SOURCE_VGTM,
        page: 176,
      },
    },
    {
      header: 'Совсем не шутки',
      text: `Возможное присутствие нилбога в армии послужило поводом для того, чтобы среди гоблиноидов в каждой армии был хотя бы один гоблин-шут. Шуту позволено ходить где вздумается и делать что угодно. Должность шута очень востребована среди гоблинов, потому что даже если он и не нилбог, [хобгоблины](CREATURE:${CREATURE_HOBGOBLIN}) и [багбиры](CREATURE:${CREATURE_BUGBEAR}) потворствуют его маниакальному поведению.`,
      source: {
        id: SOURCE_VGTM,
        page: 176,
      },
    },
    {
      header: 'Нилбогизм',
      text: `Нилбог — это невидимый дух, который вселяется только в [гоблинов]. Лишённый тела он может летать со скоростью 30 футов и не может разговаривать и его нельзя атаковать. Единственное действие, которое он может совершить — это овладеть гоблином в 5 футах от себя.

Гоблин, выбранный целью, должен успешно пройти испытание Харизмы СЛ 15 или стать одержимым. Пока он одержим духом, мировоззрение гоблина становится хаотично-злым, его Харизма становится 15 (если только она не была выше), он получает черты _Врожденное Колдовство_ и _Нилбогизм_, а так же реакцию _Переворот удачи_. Если испытание удачно, то дух не может овладеть гоблином в ближайшие 24 часа. Если тело убито или одержимость прервана заклинанием, таким как [Святилище](SPELL:${SPELL_HALLOW}), [Магический круг](SPELL:${SPELL_MAGIC_CIRCLE}), или [Защита от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}), то дух ищет другого гоблина, чтобы овладеть им. Дух может покинуть тело в любое время, но не будет делать этого по своей воле, если только поблизости нет другого потенциального тела. Гоблин, избавленный от духа нилбога, возвращается к своим обычным характеристикам и теряет все особенности, которые получил во время одержимости.`,
      source: {
        id: SOURCE_VGTM,
        page: 176,
      },
    },
    ...goblinDescriptionList,
  ],
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLIN,
    CREATURE_TYPE_GOBLINOID,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 176,
  },
  armor: {
    ac: 13,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GOBLIN,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Нилбогизм',
      description: `Любое существо, которое пытается нанести урон нилбогу, должно сначала пройти испытание Харизмы СЛ 12 или стать очарованным им до конца своего следующего хода. Очарованное таким образом существо должно использовать своё действие на восхваление нилбога.

Нилбог не может восстанавливать хиты, даже посредством магического лечения, кроме как своей реакцией _Переворот удачи_.`,
    },
    ABILITY_NIMBLE_ESCAPE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_MAGE_HAND,
          SPELL_TASHA_S_HIDEOUS_LAUGHTER,
          SPELL_VICIOUS_MOCKERY,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONFUSION,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Скипетр шута',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_SHORTBOW,
    },
  ],
  reactionList: [
    {
      name: 'Переворот удачи',
      description: `В ответ на нанесение урона нилбогу другим существом, нилбог уменьшает урон до 0 и восстанавливает 1к6 хитов.`,
    },
  ],
}
