const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_DEMON_LORD,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_CONFUSION,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_FEAR,
  SPELL_FEEBLEMIND,
  SPELL_MAJOR_IMAGE,
  SPELL_PROJECT_IMAGE,
  SPELL_TELEKINESIS,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_26} = require('./../../../../crList')
const {CREATURE_DEMOGORGON} = require('./../../../../creatureIdList')
const {demonDescription} = require('./../../../../textCommonParts')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Демогоргон',
  nameAlt: [
    'Принц Демонов',
    'Шипящая Тварь',
    'Владыка Спиральных Глубин',
  ],
  nameEn: 'Demogorgon',
  id: CREATURE_DEMOGORGON,
  hasSpoilers: true,
  description: [
    `Принц Демонов, Шипящая Тварь и Владыка Спиральных Глубин. Демогоргон — это воплощение хаоса, безумия и разрушения, жаждущее извратить всё хорошее и подорвать порядок мультивселенной и увидеть, как все сущее в агонии скатится в бесконечные глубины Бездны.

Лорд демонов представляет собой совокупность различных форм: нижняя часть тела напоминает когтистые ноги рептилии, лапы перепончатые, из плеч обезьяньего туловища растут щупальца с присосками, а две отвратительные обезьяньи головы, которых зовут Аамеул и Хетрадия, одинаково безумны. Их взгляд приносит безумие и смятение всем, кто с ним встретится.

Таким же образом, спиральная Y — символ культа Демогоргона — может довести до безумия тех, кто будет разглядывать ее слишком долго. Все последователи Принца Демонов рано или поздно сходят с ума.`,
    {
      header: 'Логово Демогоргона',
      text: `Демогоргон устроил себе логово в дворце под названием Пучина, расположенном на слое Бездны, известном как Зияющая Пасть. Логово Демогоргона — это обитель безумия и двойственности; та часть дворца, что находится над водой, представляет собой две похожие на змей башни, каждую из которых венчает по минарету в виде черепа. В нем головы Демогоргона изучают тайны магии, размышляя о том, как быстрее уничтожить своих врагов. Большая часть дворца находится глубоко под водой, в холодных и темных пещерах.

# Действия логова

По очереди инициативы 20 (проигрыш при ничье), Демогоргон может совершить действие в логове, чтобы создать один из следующих эффектов; он не может использовать один и тот же эффект два раунда подряд:

* Демогоргон создает своего иллюзорного двойника, появляющегося в занимаемом им пространстве и исчезающего в момент следующей очереди инициативы 20. В свой ход Демогоргон может перемещать иллюзорного двойника на расстояние, равное его скорости ходьбы (действия не требуется). Когда существо или предмет впервые физически взаимодействует с Демогоргоном (к примеру, атакует его), с шансом 50% этим окажется иллюзорный двойник, а не сам Демогоргон, и в этом случае иллюзия исчезнет. 
* Демогоргон читает заклинание [тьма](SPELL:${SPELL_DARKNESS}) самого низкого круга четыре раза, выбирая целью различные зоны. Демогоргону не нужно концентрироваться на этих заклинаниях, а их действие завершается на следующей очереди инициативы 20.

# Местные эффекты

Область, в которой расположено логово Демогоргона пронизана его магией, создающей в ней один или более из следующих эффектов:

* Зона в пределах 6 миль вокруг логова кишит ящерицами, ядовитыми змеями и другими ядовитыми зверями. 
* Звери в пределах 1 мили от логова, даже самые мирные их виды, жестокие и обезумевшие. 
* Если гуманоид проводит по крайней мере 1 час на расстоянии 1 мили от логова, то это существо должно пройти испытание Мудрости СЛ 23 или станет жертвой безумия, определяемого по таблице _«Безумие Демогоргона»_. Существо, успешно прошедшее испытание, не может стать жертвой этого регионального эффекта в течение следующих 24 часов.

Если Демогоргон умирает, эти эффекты прекращаются в течение 1к10 дней.`,
      source: {
        id: SOURCE_MTOF,
        page: 145,
      },
    },
    {
      header: 'Безумие Демогоргона',
      text: `Если существо становится безумным в логове Демогоргона или в поле зрения лорда демонов, совершите бросок по таблице Безумие Демогоргона, чтобы определить природу безумия, которое будет слабостью персонажа и будет действовать до тех пор, пока не будет излечено.

| к100  | Слабость (длится пока не будет вылечена) |
|-------|------------------------------------------|
| 01-20 | _«Кто-то собирается убить меня. Я должен напасть первым, чтобы остановить их!»_ |
| 21-40 | _«Есть лишь одно решение для моих проблем: убить их всех!»_ |
| 41-60 | _«В моей голове более одного разума»_ |
| 61-80 | _«Если ты не согласен со мной, я заставлю тебя согласиться силой»_ |
| 81-00 | _«Я не могу позволить кому-либо касаться того, что принадлежит мне. Они могут попытаться украсть это у меня!»_ |
`,
      source: {
        id: SOURCE_MTOF,
        page: 146,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_DEMON_LORD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 145,
  },
  armor: {
    ac: 22,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 28,
  },
  speed: {
    [SPEED_WALK]: 50,
    [SPEED_SWIM]: 50,
  },
  params: {
    [PARAM_STR]: 29,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 26,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 25,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_26,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Две головы',
      description: `Демогоргон с преимуществом проходит испытания против Слепоты, Глухоты, Ошеломления и Потери сознания.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
          SPELL_MAJOR_IMAGE,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DISPEL_MAGIC,
          SPELL_FEAR,
          SPELL_TELEKINESIS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_FEEBLEMIND,
          SPELL_PROJECT_IMAGE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Демогоргон совершает две атаки _Щупальцами_.`,
    },
    {
      name: 'Щупальце',
      description: `Если цель является существом, оно должно пройти испытание Телосложения СЛ 23 или его максимум хитов будет уменьшен на значение, равное полученному урону. Это уменьшение заканчивается после совершения целью длинного отдыха. Цель умирает если ее максимум хитов сокращается до 0.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 12,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Взор',
      description: `Демогоргон обращает магический взор на одно существо, которое он может видеть, и находящееся в пределах 120 футов от него. Это существо должно пройти испытание Мудрости СЛ 23. Кроме тех случаев, в которых цель является недееспособной, она может избежать взгляда и автоматически пройти испытание. Если цель делает так, то она не сможет видеть Демогоргона до начала своего следующего хода. Если существо в этот период посмотрит на Демогоргона, оно тут же проходит испытание.

Если цель проваливает испытание, то на нее воздействует один из эффектов, по выбору Демогоргона или случайно:

1. **Зачаровывающий взор.** Цель оглушена до начала следующего хода Демогоргона или до того момента, когда Демогоргон не пропадет из ее зоны видимости.
2. **Гипнотический взор.** Цель очарована Демогоргоном до начала следующего хода Демогоргона. Демогоргон решает, как цель будет использовать свои действия, реакции и передвижение. Демогоргон не сможет использовать легендарное действие _Сводящий с ума взор_ до начала своего следующего хода, потому что Гипнотический взор требует фокусировки обеих голов на цели.
3. **Взор безумия.** На цель воздействует эффект заклинания [Смятение](SPELL:${SPELL_CONFUSION}) без прохождения испытания. Действие эффекта прекращается в начале следующего хода Демогоргона. Демогоргону не требуется концентрироваться на этом заклинании.`,
    },
  ],
  legendaryPoints: 2,
  legendaryActionList: [
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 15,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 4,
            diceType: 10,
            diceBonus: 9,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 4,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Сводящий с ума взор',
      description: `Демогоргон использует свое действие Взор и должен выбрать из двух эффектов: _Гипнотический взор_ или _Взор безумия_.`,
    },
  ],
}
