const {
  SOURCE_ERFTLW,
} = require('./../../../../sourceList')
const {
  CR_1_4,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PC_RACE_HALFLING,
} = require('./../../../../pcRaceIdList')
const {
  dinoDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_FASTIETH,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Быстрозуб',
  nameEn: 'Fastieth',
  id: CREATURE_FASTIETH,
  description: [
    {
      header: 'Быстрозубы',
      text: `Быстрозубы — двуногие большеглазые ящеры с яркой узорчатой шкурой и мощными ногами. Это самые распространённые скакуны среди [полуросликов](PC_RACE:${PC_RACE_HALFLING}) Талентовых равнин, специально выведенные ими для быстроты.

Хотя быстрозубы слишком малы, чтобы носить больших всадников, один быстрозуб легко может нести худого полурослика с лёгким снаряжением и двигаться в хорошем темпе. Быстрозубы обычно очень упрямые создания, поэтому полурослики очень ценят тех из них, кто не столь норовист, как его сородичи.

Будучи травоядными, быстрозубы предпочитают убегать, а не драться. Однако, даже если они не собираются драться, они всё ещё могут очень больно кусаться.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 290,
      },
    },
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_ERFTLW,
    page: 290,
  },
  armor: 14,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 10,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 4,
  },
  cr: CR_1_4,
  featureList: [
    {
      name: 'Быстрота',
      restore: 5,
      description: `★СУЩЕСТВО★ может совершать Уклонение бонусным действием.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
  ],
}
