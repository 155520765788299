const {
  CONDITION_BLINDED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
} = require('./../../../../damageTypeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  CREATURE_LICH,
  CREATURE_SLITHERING_TRACKER,
} = require('./../../../../creatureIdList')
const {ABILITY_SPIDER_CLIMB} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_OOZE} = require('./../../../../creatureTypeIdList')
const {LANG_ALL_KNOWN_AT_PREVIOUS_FORM} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Склизкий преследователь',
  nameEn: 'Slithering Tracker',
  id: CREATURE_SLITHERING_TRACKER,
  description: [
    `Жажда мести иногда может привести кого-то к тому, что в результате ритуала он может превратиться в полужидкое сознательное существо, известное как склизкий преследователь. Безобидный и в то же время коварный, преследователь перетекает в места, куда нормальное существо попасть не может и ставит клеймо водяной смерти на свою намеченную жертву.`,
    {
      header: 'Месть любой ценой',
      text: `Ритуал создания склизкого преследователя известен каргам, [личам](CREATURE:${CREATURE_LICH}) и жрецам, поклоняющимся богам мести. Его можно выполнить только на желающем того существе, которое жаждет мести. Ритуал высасывает всю влагу из тела и убивает его. Но разум живет в луже жидкости, который выделяется из останков вместе с неутолимой жаждой возмездия.`,
      source: {
        id: SOURCE_VGTM,
        page: 193,
      },
    },
    {
      header: 'Скрытные ассассины',
      text: `Склизкий преследователь чувствует вкус земли по которой путешествует и ищет любой след своей добычи. Для убийства склизкий преследователь возвышается и окутывает существо, пытаясь утопить жертву и одновременно высосать всю кровь. Склизкого преследователя, который убил таким образом, временно гораздо проще обнаружить, так как его жидкая форма становится окрашенной кровью и его тело оставляет видимый след позади себя.`,
      source: {
        id: SOURCE_VGTM,
        page: 193,
      },
    },
    {
      header: 'Нисхождение в безумие',
      text: `Обрушение мести на свою цель ни прекращает существование склизкого преследователя, ни утоляет его голод крови. Некоторые склизкие преследователи продолжают осознавать свою цель и распространяют свою миссию на месть другим, таким как любой кто поддерживал или дружил с первоначальной целью. Однако, большую часть времени разум не может смириться с нахождением в жидкой форме, неспособностью общаться и ведомостью жаждой крови: после того как преследователь выполняет свой долг, безумие берет верх над существом и оно нападает на всех без разбора, пока его в конце концов не уничтожат.`,
      source: {
        id: SOURCE_VGTM,
        page: 193,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_OOZE,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 193,
  },
  armor: 14,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 15,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  vulnerabilityList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ALL_KNOWN_AT_PREVIOUS_FORM,
      doNotSpeak: true,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Сидящий в засаде',
      description: `В первом раунде боя склизкий преследователь имеет преимущество на атаки против любого существа, которое он застал врасплох.`,
    },
    {
      name: 'Передача урона',
      description: `Схватив существо, склизкий преследователь получает только половину нанесённого ему урона, а существо, которое он схватил, получает вторую половину.`,
    },
    {
      name: 'Ложная внешность',
      description: `Пока склизкий преследователь не двигается, его невозможно отличить от лужи, если только наблюдатель не преуспеет в проверке Интеллекта (Расследование) СЛ 18.`,
    },
    {
      name: 'Проницательный выслеживатель',
      description: `Склизкий преследователь имеет преимущество на проверки Мудрости при выслеживании добычи.`,
    },
    {
      name: 'Жидкая форма',
      description: `Склизкий преследователь может входить на место врага и останавливаться там. Он также может перемещаться сквозь пространство шириной в 1 дюйм без протискивания.`,
    },
    ABILITY_SPIDER_CLIMB,
    {
      name: 'Подводная скрытность',
      description: `Будучи под водой, склизкий преследователь получает преимущество на проверки Ловкости (Скрытность), сделанные, чтобы спрятаться, и он может делать действие Засада за бонусное действие.`,
    },
  ],
  actionList: [
    {
      name: 'Шлепок',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Высасывание жизни',
      description: `Одно Большое существо или меньше, которое склизкий преследователь может видеть в 5 футах от себя, должно успешно пройти испытание Ловкости СЛ 13 или стать захваченным (СЛ высвобождения 13). До окончания захвата цель обездвижена и не может дышать, если только она не может дышать водой. Кроме того, захваченная цель получает 16 (3к10) некротического урона в начале каждого своего хода. Склизкий преследователь может схватить только одну цель одновременно.`,
    },
  ],
}
