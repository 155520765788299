const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {SPELL_TRUE_RESURRECTION} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_TRUE_RESURRECTION,
  name: 'Истинное воскрешение',
  nameEn: `True Resurrection`,
  description: `Вы касаетесь существа, мёртвого не больше двух сотен лет, умершего от чего угодно кроме старости. Если душа цели свободна и согласна, цель возвращается к жизни с полными хитами.

Это заклинание закрывает все раны, нейтрализует все яды, исцеляет все болезни и снимает все проклятья, бывшие у существа в момент смерти. Заклинание заменяет повреждённые и отсутствующие органы и части тела. Если существо было нежитью, оно восстанавливается в своём не-неживом виде.

Это заклинание может даже воссоздать новое тело, если старого больше нет. В этом случае Вы должны произнести вслух имя существа. Это существо появляется в свободном пространстве, выбранном Вами в пределах 10 футов.`,
  lvl: 9,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  hasConsumable: true,
  materialText: `немного святой воды и бриллианты, стоящие как минимум 25 000 зм, расходуемые заклинанием`,
  castTime: { timeId: TIME_HOUR, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 232,
    },
    {
      id: SOURCE_SRD,
      page: 189,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Теперь воскрешает даже нежить',
      page: 3,
    },
  ],
}
