const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_TROLL,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_9} = require('./../../../../crList')
const {CREATURE_ROT_TROLL} = require('./../../../../creatureIdList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {trollDescription} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Гнилой тролль',
  nameEn: 'Rot Troll',
  id: CREATURE_ROT_TROLL,
  description: [
    `Рано или поздно, у гнилого тролля пропадает способность к регенерации, хотя заживление всё ещё проходит нормально. Из существа излучается некротическая энергия, текущая внутри его сморщенной туши. Просто находясь рядом с гнилым троллем, другие существа подвергаются его смертоносному излучению.`,
    ...trollDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_TROLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 232,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 22,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 4,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_NECROTIC,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_9,
  featureList: [
    {
      name: 'Гнилостное разложение',
      description: `В конце каждого хода тролля каждое существо в пределах 5 фт. от него получает 11 (2к10) урона некротической энергией, если тролль не получал огненного урона или кислотой на этом ходу.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Тролль совершает три атаки: одну _Укусом_ и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 3,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 1,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
}
