const {PC_SUBCLASS_WARLOCK_THE_ARCHFEY} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_BLINK,
  SPELL_CALM_EMOTIONS,
  SPELL_DOMINATE_BEAST,
  SPELL_DOMINATE_PERSON,
  SPELL_FAERIE_FIRE,
  SPELL_GREATER_INVISIBILITY,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PLANT_GROWTH,
  SPELL_SEEMING,
  SPELL_SLEEP,
} = require('./../../../spellIdList')
const {
  FEATURE_ARCHFEY_EXPANDED_SPELLS,
  FEATURE_BEGUILING_DEFENSES,
  FEATURE_DARK_DELIRIUM,
  FEATURE_FEY_PRESENCE,
  FEATURE_MISTY_ESCAPE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ARCHFEY_EXPANDED_SPELLS,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    text: `Архифея позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_BLINK,
      SPELL_CALM_EMOTIONS,
      SPELL_DOMINATE_BEAST,
      SPELL_DOMINATE_PERSON,
      SPELL_FAERIE_FIRE,
      SPELL_GREATER_INVISIBILITY,
      SPELL_PHANTASMAL_FORCE,
      SPELL_PLANT_GROWTH,
      SPELL_SEEMING,
      SPELL_SLEEP,
    ],
    source: {
      id: SOURCE_PHB,
      page: 109,
    },
  },
  {
    id: FEATURE_FEY_PRESENCE,
    name: `Фейская внешность`,
    nameEn: `Fey Presence`,
    lvl: 1,
    text: `Ваш покровитель дарует Вам возможность демонстрировать притягательную и пугающую фейскую внешность.

Вы действием можете заставить всех существ в 10-футовом кубе с центром на Вас пройти испытание Мудрости со СЛ Ваших заклинаний колдуна. Все существа, провалившие испытание, становятся до конца Вашего следующего хода очарованными или напуганными Вами (на Ваш выбор).

Использовав это умение, Вы не можете использовать его снова, не завершив короткого либо длинного отдыха.`,
    source: {
      id: SOURCE_PHB,
      page: 109,
    },
  },
  {
    id: FEATURE_MISTY_ESCAPE,
    name: `Туманное исчезновение`,
    nameEn: `Misty Escape`,
    lvl: 6,
    text: `Получив урон, Вы можете раствориться облачком тумана.

Если Вы получили урон, Вы можете реакцией стать невидимым и телепортироваться на расстоянии до 60 футов в видимое Вами свободное пространство. Вы остаётесь невидимым до начала своего следующего хода, или пока не используете атаку или заклинание.

Использовав это умение, Вы не можете использовать его снова, не завершив короткого либо длинного отдыха.`,
    source: {
      id: SOURCE_PHB,
      page: 110,
    },
  },
  {
    id: FEATURE_BEGUILING_DEFENSES,
    name: `Чарующая защита`,
    nameEn: `Beguiling Defenses`,
    lvl: 10,
    text: `Ваш покровитель обучает Вас, как обращать воздействующую на разум магию Ваших врагов против них самих.

Вы получаете иммунитет к состоянию очарованности, и, если другое существо пытается очаровать Вас, Вы можете реакцией попытаться повернуть очарование против его создателя. Это существо должно пройти испытание Мудрости со СЛ Ваших заклинаний колдуна, иначе оно станет очаровано Вами на 1 минуту, или пока не получит любой урон.`,
    source: {
      id: SOURCE_PHB,
      page: 110,
    },
  },
  {
    id: FEATURE_DARK_DELIRIUM,
    name: `Тёмное исступление`,
    nameEn: `Dark Delirium`,
    lvl: 14,
    text: `Вы можете погружать существ в иллюзорное пространство.

Выберите действием видимое Вами существо в пределах 60 футов от себя. Оно должно пройти испытание Мудрости со СЛ Ваших заклинаний колдуна. В случае провала оно очаровано или напугано Вами (на Ваш выбор) на протяжении 1 минуты, или пока Вы не потеряете концентрацию (как если бы концентрировались на заклинании). Эффект завершается преждевременно, если существо получает любой урон.

Покуда иллюзия длится, существо считает, что оно потерялось в туманном пространстве, образ которого выбираете вы. Существо может видеть и слышать только себя, Вас и иллюзию.

Использовав это умение, Вы не можете использовать его снова, не завершив короткого либо длинного отдыха.`,
    source: {
      id: SOURCE_PHB,
      page: 110,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_ARCHFEY,
  })
)
