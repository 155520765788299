const {CREATURE_TYPE_DHAMPIR} = require('./../../creatureTypeIdList')
const {SOURCE_VRGtR} = require('./../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_DHAMPIR,
  nameEn: 'Dhampir',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'дампир',
        genitive: 'дампира',
        dative: 'дампиру',
        accusative: 'дампира',
        instrumental: 'дампиром',
        prepositional: 'дампире',
      },
      [GENDER_FEMALE]: {
        nominative: 'дампирша',
        genitive: 'дампирши',
        dative: 'дампирше',
        accusative: 'дампиршу',
        instrumental: 'дампиршой',
        prepositional: 'дампирше',
      },
    },
    plural: {
      nominative: 'дампиры',
      genitive: 'дампиров',
      dative: 'дампирам',
      accusative: 'дампиров',
      instrumental: 'дампирами',
      prepositional: 'дампирах',
    },
  },
  description: [
    {
      header: 'Дампиры',
      text: `Находясь между мирами живых и мёртвых, дампиры сохраняют свою власть над жизнью, но подвергаются бесконечным испытаниям жестоким голодом. Их связи с нежитью дают дампирам возможность ощутить удаль бессмертного вампира в виде повышенной скорости, тёмного зрения и истощающего жизнь укуса. Обладая уникальным пониманием природы нежити, многие дампиры становятся приключенцами и охотниками на монстров. Их причины часто очень личные.

Некоторые ищут опасности, представляя что монстры — это олицетворения их собственного голода. Другие мстят тому, что превратило их в дампира. А третьи принимают уединение охоты, стремясь дистанцироваться от тех, кто искушает свой голод.`,
      source: {
        id: SOURCE_VRGtR,
        page: 15,
      },
    },
    {
      header: 'Голоды дампиров',
      text: `У каждого дампира есть жажда, которую могут утолить только живые. Те, кто злоупотребляет этим, рискуют потерять контроль и навсегда начать видеть в окружающих только добычу. Те же, кто сопротивляется, могут найти исключительные способы контролировать свои побуждения или подавлять их постоянным мучительным сдерживанием. В любом случае искушение преследует дампиров, и обстоятельства будто складываются так, чтобы дать им бесконечные поводы для насыщения.

Хотя многие дампиры жаждут крови, Ваш персонаж мог бы получать иное пропитание от живых. Определите Ваш голод по таблице «Голоды дампиров», или же самостоятельно выберите то, что заставляет Вашего персонажа кормиться.

# Голоды дампиров

| к6 | Голод |
|-|-|
| 1 | Кровь |
| 2 | Плоть или сырое мясо |
| 3 | Спинномозговая жидкость |
| 4 | Психическая энергия |
| 5 | Сны |
| 6 | Жизненная сила |
`,
      source: {
        id: SOURCE_VRGtR,
        page: 16,
      },
    },
    {
      header: 'Происхождения дампиров',
      text: `Дампиры часто возникают в результате встреч с вампирами, но всевозможные жуткие сделки, некромантские влияния и встречи с таинственными бессмертными могли изменить Вашего персонажа. В таблице «Происхождения дампиров» содержатся предположения о том, как Ваш персонаж получил своё наследие.

# Происхождения дампиров

| к8 | Происхождение |
|-|-|
| 1 | Вы — реинкарнация предка, который был вампиром-тираном. |
| 2 | Ваш договор с хищным божеством, исчадием, феей или духом заставляет Вас разделять их голод. |
| 3 | Вы пережили нападение вампира, но навсегда изменились. |
| 4 | Внутри Вас живет паразит. Вы утоляете его голод. |
| 5 | Трагедия прервала Ваше превращение в бессмертного. |
| 6 | Вы — уменьшенная форма потустороннего существа. Утоление голода ускоряет ваше обновление. |
| 7 | Один из Ваших родителей был вампиром. |
| 8 | Радикальный эксперимент изменил Ваше тело, заставив полагаться на других в получении жизненно важных жидкостей. |
`,
      source: {
        id: SOURCE_VRGtR,
        page: 16,
      },
    },
    {
      header: 'Дампиры в Доменах Ужаса',
      text: `Создавая дампира, спросите своего Мастера о том, имеет ли смысл создать для Вашего персонажа связь с одним из следующих Доменов Ужаса

# Баровия

В тени замка Равенлофт расцветают истории о тех, кто любит вампиров или произошёл от них. У Вас может быть такая связь, но бойтесь того, что произойдёт, если прочие члены Вашей изолированной общины об этом узнают.

# Даркон

Каргат, тайная полиция этого расколотого владения, якобы знает секрет бессмертия. Возможно, Вы вступили в неё и дослужились до ранга, на котором Вас наградили превращением в дампира.

# Ай’Кат

Ночью голодный город Ай’Кат наводняют вампирские цзянши. После встречи с одним из этих беспокойных предков Вы остались с холодным голодом внутри.`,
      source: {
        id: SOURCE_VRGtR,
        page: 17,
      },
    },
  ],
}
