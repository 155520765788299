const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  ABILITY_STANDING_LEAP,
  ABILITY_SURE_FOOTED,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_BULEZAU} = require('./../../../../creatureIdList')
const {demonDescription} = require('./../../../../textCommonParts')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Булезау',
  nameEn: 'Bulezau',
  id: CREATURE_BULEZAU,
  description: [
    `Болезненные проявления животной ярости, булезау воплощают жестокость природы. В Бездне, булезау скрываются в глубоких каньонах и среди высоких утёсов, также во множестве находя своё место среди армий демонических повелителей, служа там в качестве пехотинцев в бесконечной войне Бездны.`,
    {
      header: 'Кровожадность',
      text: `Булезау жаждут насилия. Их стремление убивать и желание умереть делают их неотъемлемой частью свиты любого демонического лорда. Когда их не подчиняют более крупные и сильные демоны, булезау собираются в блуждающие толпы, сражающиеся между собой, пока не появится лучшая цель или пока более сильный демон не подчинит их.`,
      source: {
        id: SOURCE_MTOF,
        page: 130,
      },
    },
    {
      header: 'Отталкивающее',
      text: `Отвратительные эпидемии болезней: гноящиеся глаза, личинки, извивающиеся в открытых язвах, и запах гнилого мяса, следуют за булезау, куда бы они ни пошли.`,
      source: {
        id: SOURCE_MTOF,
        page: 130,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 130,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 17,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 6,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Гнилостное присутствие',
      description: `Когда любое отличное от демона существо начинает ход в пределах 30 фт от одного или нескольких булезау, оно должно пройти испытание Телосложения СЛ 13 или получить 1к6 урона некротической энергией плюс 1 урона некротической энергией за каждого булезау в пределах 30 фт. от него.`,
    },
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 20,
      jumpHeight: 10,
    },
    ABILITY_SURE_FOOTED,
  ],
  actionList: [
    {
      name: 'Шипастый хвост',
      description: `Если цель является существом, она должно пройти испытание Телосложения СЛ 13 против болезни, или стать Отравленной до прекращения заболевания. Пока цель отравлена таким образом, она исторгает кипящий гной, кашляет мухами и сбрасывает куски кожи, а также совершает повторное испытание Телосложения СЛ 13 каждые 24 часа. При успехе, болезнь завершает действие. При провале, максимальное значение хитов цели понижается на 4 (1к8). Цель умирает, если максимальное количество хитов опускается до 0.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 12,
          diceBonus: 2,
        },
      },
    },
  ],
}
