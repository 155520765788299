const {SOURCE_MTOF} = require('./../../sourceList')

module.exports = {
  header: 'Призраки меча',
  text: `Когда воин, одержимый славой, без почтения погибает в битве, его доблестный дух может превратиться в призрака меча, что обитает на полях битвы.

**Погружённые в раздумья духи.** Зачастую, места, в которых можно встретить призраков меча — это места древних засад, поля битв, где от магии пали солдаты, не имеющие шансов дать ей отпор, и те места, где противники были окружены и беспощадно перебиты.

**Честь превыше всего.** Призраки меча впадают в ярость, если кто-либо сомневается в их доблести. В то же время, их можно с лёгкость задобрить, если хвалить. Высшее удовольствие для них, слушать баллады, исполняемые в их честь. Города, что расположены возле прошедших полей битв, проводят ежегодные фестивали в честь погибших воинов, чтобы сдерживать и успокаивать призраков меча.

**Природа нежити.** Призрак меча не нуждается в воздухе, пище, питье и сне.`,
  source: {
    id: SOURCE_MTOF,
    page: 224,
  },
}
