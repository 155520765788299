const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_CHAIN_SHIRT,
  GEAR_SCIMITAR,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  fireNewtDescriptionList,
  fireNewtNote,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_FIRE,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_FIRENEWT,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DRACONIC,
  LANG_IGNAN,
} = require('./../../../../languageIdList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_FIRENEWT_WARRIOR} = require('./../../../../creatureIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Огненный тритон воин',
  nameEn: 'Firenewt Warrior',
  id: CREATURE_FIRENEWT_WARRIOR,
  description: fireNewtDescriptionList,
  note: fireNewtNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_FIRENEWT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 178,
  },
  armor: {
    ac: 16,
    gearId: [GEAR_CHAIN_SHIRT, GEAR_SHIELD],
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 12,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 8,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_IGNAN,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_AMPHIBIOUS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Огненный тритон делает две атаки _Скимитаром_.`,
    },
    {
      gearId: GEAR_SCIMITAR,
    },
    {
      name: 'Огненный плевок',
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `Огненный тритон плюет огнём в существо не далее 10 футов от себя. Существо должно пройти испытание Ловкости СЛ 11 и получить 9 (2к8) урона огнём при провале или половину этого урона при успехе.`,
    },
  ],
}
