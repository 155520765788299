const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN} = require('./../../../sourceList')
const {PC_SUBCLASS_MAGE_OF_WITHERBLOOM_2021_06_08} = require('./../../../pcSubClassIdList')
const {
  FEATURE_WITHERBLOOM_SPELLS_2021_06_08,
} = require('./../../../featureIdList')
const {
  SPELL_ANTILIFE_SHELL,
  SPELL_BLIGHT,
  SPELL_CURE_WOUNDS,
  SPELL_GREATER_RESTORATION,
  SPELL_INFLICT_WOUNDS,
  SPELL_LESSER_RESTORATION,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_REVIVIFY,
  SPELL_SPARE_THE_DYING,
  SPELL_VAMPIRIC_TOUCH,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_WITHERBLOOM_SPELLS_2021_06_08,
    name: `Заклинания Визерблума`,
    nameEn: `Witherbloom Spells`,
    lvl: 1,
    text: `You learn the cantrip spare the dying and the 1st-level spells cure wounds and inflict wounds. You learn additional spells when you reach certain levels in this class, as shown on the Witherbloom Spells table.

Each of these spells count as a class spell for you, but it doesn’t count against the number of spells you know. If you are a druid, you always have the spells prepared, and they don’t count against the number of spells you can prepare each day.`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_ANTILIFE_SHELL,
      SPELL_BLIGHT,
      SPELL_CURE_WOUNDS,
      SPELL_GREATER_RESTORATION,
      SPELL_INFLICT_WOUNDS,
      SPELL_LESSER_RESTORATION,
      SPELL_MASS_CURE_WOUNDS,
      SPELL_RAY_OF_ENFEEBLEMENT,
      SPELL_REVIVIFY,
      SPELL_SPARE_THE_DYING,
      SPELL_VAMPIRIC_TOUCH,
    ],
    source: {
      id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
      page: 8,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MAGE_OF_WITHERBLOOM_2021_06_08,
  })
)

