const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_FIRENEWT} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_FIRENEWT,
  nameEn: 'Firenewt',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'огненный тритон',
      genitive: 'огненного тритона',
      dative: 'огненному тритону',
      accusative: 'огненного тритона',
      instrumental: 'огненным тритоном',
      prepositional: 'огненном тритоне',
    },
    plural: {
      nominative: 'огненные тритоны',
      genitive: 'огненных тритонов',
      dative: 'огненным тритонам',
      accusative: 'огненных тритонов',
      instrumental: 'огненными тритонами',
      prepositional: 'огненных тритонах',
    },
  },
}
