const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_DRAGON_COPPER_ANCIENT,
  CREATURE_TIAMAT,
} = require('./../../creatureIdList')

const colorDragonDescriptionList = require('./dragonColor')

module.exports = [
  {
    header: 'Логово красного дракона',
    text: `Красные драконы устраивают свои логова высоко в горах и холмах, обитая в пещерах под укрытыми снегом вершинами, в глубинах брошенных шахт и покинутых залах дварфийских твердынь. Пещеры с вулканической и геотермической активностью выше прочих ценятся красными драконами, так как создают опасную среду, которая мешает нарушителям, и в то же время позволяет спать среди обжигающего жара и вулканических газов.

Хорошо спрятав свой клад, красные драконы проводят почти столько же времени вне логова, как и внутри. Для красного дракона высоты мира служат троном, с которого он может обозревать не только всё подконтрольное ему, но и новые горизонты для захвата.

Тем временем, по всему логову его слуги воздвигают монументы драконьей мощи, рассказывающие о пугающих историях его жизни, поверженных им врагах и народах, порабощённых им.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов; дракон не может использовать один и тот же эффект два раунда подряд:

* Магма вырывается из точки на полу, видимой драконом в пределах 120 футов, создавая гейзер 20 футов высотой и 5 футов радиусом. Все существа в области гейзера должны пройти испытание Ловкости СЛ 15, получая урон огнём 21 (6к6) при провале, или половину этого урона при успехе.
* Логово вздрагивает от подземного толчка в радиусе 60 футов от дракона. Все существа, кроме дракона, стоящие на полу в этой области, должны пройти испытание Ловкости СЛ 15, иначе будут сбиты с ног.
* Вулканические газы образовывают сферическое облако 20-футового радиуса с центром на точке, видимой драконом в пределах 120 футов. Сфера огибает углы и является слабо заслоняющей видимостью. Облако, держится пока не наступит очередь инициативы «20» следующего раунда. Все существа, начинающие ход в этом облаке, должны пройти испытание Телосложения СЛ 13, иначе станут отравленными до конца своего хода. Отравленные таким образом существа недееспособны.

# Местные эффекты

Регион, в котором есть логово легендарного красного дракона, искажается драконьей магией, которая создаёт один или несколько следующих эффектов:

* В пределах 6 миль от драконьего логова часто происходят небольшие землетрясения.
* Водные источники в пределах 1 мили от логова становятся неестественно тёплыми и пахнут серой.
* Каменистые разломы в пределах 1 мили от логова образуют порталы на План Стихийного Огня, позволяя существам оттуда проникать в мир и обитать неподалёку.

Если дракон умирает, эти эффекты угасают за 1к10 дней.`,
    source: {
      id: SOURCE_MM,
      page: 113,
    },
  },
  {
    header: 'Красные драконы',
    text: `Наиболее алчные из истинных драконов, красные драконы безустанно ищут способ пополнить свои сокровищ. Они исключительно тщеславны, даже по драконьим меркам. Их самомнение отражается в надменном обращении и презрении к другим существам.

Запах серы и пемзы окутывает красного дракона, которого легко узнать по изогнутым назад рогам и перепончатому гребню вдоль хребта. Из его ноздрей на похожей на клюв морде постоянно исходит дым, а в глазах вспыхивают огоньки, когда он злится. Размах крыльев красных драконов больше, чем у любого другого вида цветных драконов, а цвет их переходит от красного к иссиня-чёрному цвету пережжённого металла у края крыльев.

Чешуя вирмлинга красного дракона блестящая и ярко-алая, но темнеет и тускнеет с возрастом, становясь всё толще и крепче. Также их зрачки с возрастом исчезают, и глаза старейших красных драконов становятся похожи на колодцы расплавленной лавы.`,
    source: {
      id: SOURCE_MM,
      page: 113,
    },
  },
  {
    header: 'Хозяева гор',
    text: `Красные драконы предпочитают селиться в гористой местности, каменистых пустошах и других местах, где они смогли бы забраться достаточно высоко, чтобы осматривать свои владения. Время от времени это приводит их к конфликту с обитающими на холмах [медными драконами](CREATURE:${CREATURE_DRAGON_COPPER_ANCIENT}).`,
    source: {
      id: SOURCE_MM,
      page: 113,
    },
  },
  {
    header: 'Высокомерные тираны',
    text: `Красные драконы часто впадают в разрушительную ярость и действуют импульсивно, если их разозлить. Многие культуры считают их классическими представителями злых драконов.

Ни один другой драконий вид даже отдалённо не сравнится с красными драконами по высокомерию. Эти существа считают себя не менее, чем королями и императорами, а другие драконьи виды ставят себе в подчинение. Веря в то, что они помазаны [Тиамат](CREATURE:${CREATURE_TIAMAT}) править от её имени, красные драконы считают всех существ в мире своими подчинёнными.`,
    source: {
      id: SOURCE_MM,
      page: 113,
    },
  },
  {
    header: 'Рабы и статус',
    text: `Красные драконы — изоляционисты, и яростно защищают свою территорию. Впрочем, они стремятся узнать о событиях в мире, используя низших существ в качестве информаторов, посыльных и шпионов. Они наиболее заинтересованы в новостях о других красных драконах, с которыми они постоянно соревнуются.

Когда им необходимы слуги, красные драконы требуют верности от хаотично-злых гуманоидов. Если те отказывают им в преданности, драконы убивают лидеров племени и заявляют свою власть над выжившими. Существа, служащие красным драконам, живут в постоянном страхе быть испепелёнными или сожранными за то, что не угодили дракону. Большую часть времени они проводят в попытках задобрить владыку, чтобы остаться в живых.`,
    source: {
      id: SOURCE_MM,
      page: 113,
    },
  },
  {
    header: 'Одержимые коллекционеры',
    text: `Красные драконы ценят богатство более всего, а их клады поистине легендарны. Они присваивают всё, что имеет какую-то ценность и часто могут на глаз определить ценность вещицы, ошибаясь лишь на пару медяков. Красные драконы особенно любят сокровища, отобранные у убитых ими могучих врагов, выставляя такие вещи напоказ, как доказательство своего превосходства.

Красный дракон отлично помнит ценность и происхождение каждого предмета в своей сокровищнице, также как и его точное место. Он может заметить пропажу одной монеты и впасть от этого в ярость, бросаясь в погоню за вором, чтобы безжалостно расправиться с ним. Не обнаружив вора, дракон будет бушевать, разрушая города и деревни в попытке утолить свой гнев.`,
    source: {
      id: SOURCE_MM,
      page: 113,
    },
  },
  ...colorDragonDescriptionList,
]
