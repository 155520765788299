const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_SHIELD_GUARDIAN} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ALL} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Щитостраж`,
  nameEn: 'Shield Guardian',
  id: CREATURE_SHIELD_GUARDIAN,
  description: [
    `[Волшебники](PC_CLASS:${PC_CLASS_WIZARD}) и другие заклинатели создают щитостражей для своей защиты. Щитостражи держатся рядом со своим хозяином, поглощая урон, чтобы создатель оставался в живых как можно дольше.`,
    {
      header: 'Амулет хозяина',
      text: `Для каждого щитостража есть амулет, с которым он магическим образом связан. Такой амулет всегда только один, и если он будет уничтожен, щитостраж станет недееспособным, пока амулет не будет заменён на исправный. Амулет щитостражей является самостоятельной целью для атаки, если его никто не несёт и не носит. КД амулета равен 10, у него 10 хитов, и он имеет иммунитет к урону психической энергией и ядом. Создание нового амулета занимает одну неделю и стоит 1000 золота за компоненты.

Главная задача щитостражей — защищать носителя амулета. Владелец амулета может приказывать щитостражу атаковать противников или защищать себя от атак. Если носитель амулета находится в опасности, то конструкция щитостража поглотит часть урона, даже на некотором расстоянии.

Заклинатель может хранить одно заклинание внутри щитостража, который может использовать это заклинание по команде или других условиях. Многие волшебники попадали в руки врага, но были спасены щитостражами, обрушившими на врагов могущественные силы.`,
      source: {
        id: SOURCE_MM,
        page: 300,
      },
    },
    {
      header: 'Великолепное сокровище',
      text: `Благодаря тому, что амулет щитостража можно передать другому существу, некоторые волшебники собирают огромные суммы денег с принцев, знатных особ, а также глав криминальных группировок на получение амулета. При этом щитостраж представляет огромную ценность для того, что кто сможет убить владельца амулета и забрать его себе.`,
      source: {
        id: SOURCE_MM,
        page: 300,
      },
    },
    {
      header: 'Природа конструкта',
      text: `Щитостражу не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 300,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 300,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 18,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 10,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ALL,
      onlyCommands: true,
    },
  ],
  cr: CR_7,
  featureList: [
    {
      name: `Привязка`,
      description: `★СУЩЕСТВО★ магическим образом привязан к амулету. Если ★СУЩЕСТВО★ и амулет находятся на одном плане существования, владелец амулета может телепатически позвать ★СУЩЕСТВО★ к себе, и ★СУЩЕСТВО★ знает направление и расстояние до амулета. Если ★СУЩЕСТВО★ находится в пределах 60 футов от владельца амулета, половина урона, получаемая владельцем (округляя в большую сторону), переносится на ★него★.`,
    },
    {
      name: `Регенерация`,
      description: `★СУЩЕСТВО★ восстанавливает 10 хитов в начале своего хода, если у него есть хотя бы 1 хит.`,
    },
    {
      name: `Хранение заклинаний`,
      description: `Заклинатель, носящий амулет ★СУЩЕСТВО★, может заставить ★его★ хранить одно заклинание с уровнем не выше 4. Для этого владелец должен наложить заклинание на ★СУЩЕСТВО★. Заклинание не производит эффект, а сохраняется в ★нём★. Когда владелец прикажет, или возникнет ситуация, определённая заклинателем заранее, ★СУЩЕСТВО★ сотворяетхранящееся заклинание со всеми параметрами, заданными исходным заклинателем, безо всяких компонентов. Когда заклинание накладывается или поступает для хранения новое заклинание, предыдущее заклинание теряется.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Кулаком_`,
    },
    {
      name: `Кулак`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  reactionList: [
    {
      name: `Щит`,
      description: `Если некое существо совершает атаку по владельцу амулета, ★СУЩЕСТВО★ дарует бонус +2 к КД владельца, если они находятся в пределах 5 футов друг от друга.`,
    },
  ],
  genderId: GENDER_MALE,
}
