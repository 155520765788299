const {SOURCE_MOOT} = require('./../../sourceList')
const {
  CREATURE_WINGED_BULL,
  CREATURE_WINGED_LION,
} = require('./../../creatureIdList')

module.exports = {
  header: 'Скакуны архонтов',
  text: `Архонты всегда врываются в бой верхом на грозных крылатых скакунах. Некоторые легенды предполагают, что их скакуны на самом деле являются физическим проявлением воли архонта, позволяя им действовать как единому целому. Двумя наиболее распространенными скакунами архонтов являются [крылатые быки](CREATURE:${CREATURE_WINGED_BULL}) и [крылатые львы](CREATURE:${CREATURE_WINGED_LION}).`,
  source: {
    id: SOURCE_MOOT,
    page: 215,
  },
}
