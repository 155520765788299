const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_2,
} = require('./../../../../crList')
const {
  SIZE_HUGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_GIANT_CONSTRICTOR_SNAKE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гигантский удав',
  nameEn: 'Giant Constrictor Snake',
  id: CREATURE_GIANT_CONSTRICTOR_SNAKE,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 329,
  },
  armor: 12,
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 10,
    },
  ],
  cr: CR_2,
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Сжимание',
      description: `Цель становится схваченной (СЛ высвобождения 16). Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может сжимать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
