const {
  SPELL_DAYLIGHT,
  SPELL_LIGHT,
} = require('./../../../../../spellIdList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROBE_OF_EYES} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ROBE_OF_EYES,
  name: `Мантия глаз`,
  nameEn: `Robe of Eyes`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Эта мантия украшена узором в виде глаз. Пока Вы носите её, Вы обладаете следующими преимуществами:

* Мантия позволяет Вам видеть во всех направлениях, и Вы совершаете с преимуществом проверки Мудрости (Внимательность), полагающиеся на зрение.
* Вы обладаете тёмным зрением в пределах 120 футов.
* Вы можете видеть невидимых существ и предметы, а также Ваше зрение простирается на 120 футов на Эфирный План.

Глаза на мантии нельзя закрыть или отвести в сторону. Вы можете закрыть или увести в сторону свои собственные глаза, но пока Вы носите эту мантию, считается, что Вы этого никогда не делаете.

Заклинание [Свет](SPELL:${SPELL_LIGHT}), наложенное на эту мантию, или заклинание [Дневной свет](SPELL:${SPELL_DAYLIGHT}), наложенное в пределах 5 футов от мантии, вызывает у Вас слепоту на 1 минуту. В конце каждого своего хода Вы можете проходить испытание Телосложения (СЛ 11 для [Света](SPELL:${SPELL_LIGHT}) или 15 для [Дневного света](SPELL:${SPELL_DAYLIGHT}), оканчивая слепоту при успехе.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 181,
  },
}
