const {LANG_TYPE_DEAD} = require('./../../languageTypeList')
const {LANG_RENGARDT} = require('./../../languageIdList')
const {LANG_SUBGROUP_LOW_ULUTIM} = require('./../../languageSubGroupList')

module.exports = {
  id: LANG_RENGARDT,
  type: LANG_TYPE_DEAD,
  langSubGroupId: LANG_SUBGROUP_LOW_ULUTIM,
  nameEn: 'Rengardt',
  name: {
    nominative: 'Рэнгардский',
    genitive: 'Рэнгардского',
    instrumental: 'Рэнгардским',
    prepositional: 'Рэнгардском',
  },
  isReady: false,
  isRealLang: true,
}
