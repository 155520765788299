const {
  SPELL_BANE,
  SPELL_GUIDANCE,
  SPELL_HEALING_WORD,
  SPELL_HOLD_PERSON,
  SPELL_MENDING,
  SPELL_RESISTANCE,
  SPELL_SANCTUARY,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_THAUMATURGY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_SPIDER_CLIMB,
  ABILITY_SUNLIGHT_SENSITIVITY,
  ABILITY_WEB_WALKER,
  ABILITY_WEB_SENSE,
} = require('./../../../../creatureAbilityList')
const {
  SKILL_ATHLETICS,
  SKILL_RELIGION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_DAGGER,
  GEAR_HIDE_ARMOR,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SIZE_LARGE,
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  chitineDescriptionList,
  chitineNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_CHOLDRITH} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_UNDERCOMMON} = require('./../../../../languageIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Холдрита',
  nameEn: 'Choldrith',
  id: CREATURE_CHOLDRITH,
  description: chitineDescriptionList,
  note: chitineNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 199,
  },
  armor: {
    ac: 14,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_DOUBLE,
    [SKILL_RELIGION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_SPIDER_CLIMB,
    ABILITY_SUNLIGHT_SENSITIVITY,
    ABILITY_WEB_SENSE,
    ABILITY_WEB_WALKER,
  ],
  spellCast: {
    spellCasterLevel: 4,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_GUIDANCE,
      SPELL_MENDING,
      SPELL_RESISTANCE,
      SPELL_THAUMATURGY,
      SPELL_BANE,
      SPELL_SANCTUARY,
      SPELL_HOLD_PERSON,
      SPELL_HEALING_WORD,
      SPELL_SHIELD_OF_FAITH,
      {
        id: SPELL_SPIRITUAL_WEAPON,
        comment: 'кинжал',
      },
    ],
    slotCountList: [
      Infinity,
      4,
      3,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
    {
      name: 'Паутина',
      restore: 5,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 5,
        range: {
          normal: 30,
          max: 60,
        },
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            size: {
              max: SIZE_LARGE,
            },
          },
        },
        hit: `Цель обездвижена паутиной. Действием обездвиженная цель может пройти проверку Силы СЛ 11 и вырваться из паутины при успехе. Паутину также можно атаковать и уничтожить (КД 10; 5 хитов; уязвимость к урону огнём; иммунитет к дробящему, психическому урону и яду).`,
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
