const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_CHAIN_SHIRT,
} = require('./../../../../../gearIdList')
const {
  SPELL_CHARM_PERSON,
  SPELL_WISH,
} = require('./../../../../../spellIdList')
const {
  CREATURE_EFREETI,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_ARMOR,
  MGC_TYPE_POTION,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_EFREETI_BOTTLE,
  MAGIC_ITEM_EFREETI_CHAIN,
  MAGIC_ITEM_ELIXIR_OF_HEALTH,
  MAGIC_ITEM_ELVEN_CHAIN,
  MAGIC_ITEM_EYES_OF_CHARMING,
  MAGIC_ITEM_EYES_OF_MINUTE_SEEING,
  MAGIC_ITEM_EYES_OF_THE_EAGLE,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_ELVEN_CHAIN,
    name: `Эльфийская кольчуга`,
    nameEn: `Elven Chain`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_CHAIN_SHIRT,
    rarity: MGC_RARITY_RARE,
    description: `Вы получаете бонус +1 к КД, когда носите этот доспех. Считается, что Вы владеете навыком ношения этого доспеха, даже если не владеете навыком ношения средних доспехов.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 214,
    },
  },
  {
    id: MAGIC_ITEM_ELIXIR_OF_HEALTH,
    name: `Эликсир здоровья`,
    nameEn: `Elixir of Health`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `Это прозрачная красная жидкость с пузырьками света в ней.

Если Вы выпьете это зелье, оно вылечит Вас от всех болезней, а также устраняет состояния «оглушён», «ослеплён», «отравлен» и «парализован».`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 214,
    },
  },
  {
    id: MAGIC_ITEM_EYES_OF_CHARMING,
    name: `Очки очарования`,
    nameEn: `Eyes of Charming`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Эти кристаллические линзы размещаются напротив глаз.

У них есть 3 заряда. Если Вы их носите, Вы можете действием потратить 1 заряд, чтобы наложить заклинание [Очарование личности](SPELL:${SPELL_CHARM_PERSON}) (СЛ испытания 13) на гуманоида в пределах 30 футов от себя, при условии, что Вы с ним видите друг друга.

Линзы восстанавливают ежедневно на рассвете все потраченные заряды. `,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_CHARM_PERSON],
    source: {
      id: SOURCE_DMG,
      page: 188,
    },
  },
  {
    id: MAGIC_ITEM_EYES_OF_THE_EAGLE,
    name: `Очки орлиного зрения`,
    nameEn: `Eyes of the Eagle`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Эти кристаллические линзы размещаются напротив глаз.

Пока Вы их носите, Вы совершаете с преимуществом проверки Мудрости (Внимательность), полагающиеся на зрение. В условиях хорошей видимости Вы можете разглядеть подробности даже у экстремально далёких существ и предметов, с размером не меньше 2 футов в поперечнике.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 188,
    },
  },
  {
    id: MAGIC_ITEM_EYES_OF_MINUTE_SEEING,
    name: `Очки детального зрения`,
    nameEn: `Eyes of Minute Seeing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Эти кристаллические линзы размещаются напротив глаз.

Пока Вы их носите, Вы видите лучше, чем обычно, в пределах 1 фута. Вы совершаете с преимуществом проверки Интеллекта (Анализ), полагающиеся на исследование местности или предмета в пределах 1 фута.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 188,
    },
  },
  {
    id: MAGIC_ITEM_EFREETI_CHAIN,
    name: `Кольчуга ифритов`,
    nameEn: `Efreeti Chain`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_CHAIN_MAIL,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Пока Вы носите этот доспех, Вы получаете бонус +3 к КД и иммунитет к урону огнём, а также можете говорить на Первичном и понимаете его.

Кроме того, Вы можете стоять на расплавленном камне и ходить по нему, как если бы он был твёрдой поверхностью.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 178,
    },
  },
  {
    id: MAGIC_ITEM_EFREETI_BOTTLE,
    name: `Бутылка с ифритом`,
    nameEn: `Efreeti Bottle`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    description: `Эта раскрашенная латунная бутылка весит 1 фунт. Если Вы действием вынете пробку, из бутылки вырвется густое облако дыма. В конце Вашего хода дым исчезает во вспышке безвредного огня, и в свободном пространстве в пределах 30 футов от Вас появляется [ифрит](CREATURE:${CREATURE_EFREETI}). 

Когда бутылку открывают в первый раз, Мастер определяет, что произойдёт дальше: 

| к100 | Эффект |
|------|--------|
| 01–10 | [Ифрит](CREATURE:${CREATURE_EFREETI}) нападёт на Вас. Через 5 раундов сражения [ифрит](CREATURE:${CREATURE_EFREETI}) исчезает, а бутылка теряет магию. |
| 11–90 | [Ифрит](CREATURE:${CREATURE_EFREETI}) служит Вам 1 час, выполняя все Ваши команды. После этого [ифрит](CREATURE:${CREATURE_EFREETI}) возвращается в бутылку, которая закрывается новой пробкой. Пробку нельзя вынуть в течение 24 часов. В последующие два открытия будет происходить то же самое. Если бутылку откроют в четвёртый раз, [ифрит](CREATURE:${CREATURE_EFREETI}) сбежит и исчезнет, а бутылка потеряет магию. |
| 91–00 | Ифрит может три раза наложить для Вас заклинание [Исполнение желаний](SPELL:${SPELL_WISH}). Он исчезает после того как исполнит последнее желание или спустя 1 час, а бутылка после этого теряет магию. |`,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_WISH],
    source: {
      id: SOURCE_DMG,
      page: 153,
    },
  },
]
