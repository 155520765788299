const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../sourceList')

const {
  CREATURE_DRAGON_WHITE_ADULT,
  CREATURE_HAWK,
  CREATURE_ORC,
  CREATURE_OWLBEAR,
} = require('./../../creatureIdList')

const {
  PARAM_STR,
  PARAM_DEX,
} = require('./../../paramList')

const {
  GEAR_ARROWS,
  GEAR_DUNGEONEER_S_PACK,
  GEAR_EXPLORER_S_PACK,
  GEAR_LEATHER_ARMOR,
  GEAR_LONGBOW,
  GEAR_QUIVER,
  GEAR_SCALE_MAIL,
  GEAR_SHORTSWORD,
} = require('./../../gearIdList')
const {halfCasterSpellSlotTable} = require('./../../spellSlotTable')
const {BACKGROUND_OUTLANDER} = require('./../../backgroundIdList')

const {
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_SHIELDS,
  CAT_SIMPLE_WEAPON,
  CAT_MARTIAL_WEAPON,
} = require('./../../gearCategoryList')

const {
  SKILL_ANIMAL_HANDLING,
  SKILL_ATHLETICS,
  SKILL_INSIGHT,
  SKILL_INVESTIGATION,
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../skillList')

const {
  PC_CLASS_RANGER,
} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_RANGER,
  name: {
    singular: {
      nominative: 'следопыт',
      genitive: 'следопыта',
      dative: 'следопыту',
      accusative: 'следопыта',
      instrumental: 'следопытом',
      prepositional: 'следопыте',
    },
    plural: {
      nominative: 'следопыты',
      genitive: 'следопытов',
      dative: 'следопытам',
      accusative: 'следопытов',
      instrumental: 'следопытами',
      prepositional: 'следопытах',
    },
  },
  nameEn: 'ranger',
  description: [
    `Грубо и дико выглядящий человек в одиночку следует в тенях деревьев за [орками](CREATURE:${CREATURE_ORC}), которые, как он знает, планируют напасть на расположенную рядом ферму. Зажав по [короткому мечу](GEAR:${GEAR_SHORTSWORD}) в каждой руке, он становится стальным вихрем, вырезающим одного врага за другим.

Увернувшись от конуса морозного воздуха, эльфийка встаёт на ноги и натягивает тетиву лука, чтобы пустить стрелу в [белого дракона](CREATURE:${CREATURE_DRAGON_WHITE_ADULT}). Невзирая на волну страха, которая исходит от дракона подобно холоду его дыхания, она посылает одну стрелу за другой, чтоб найти уязвимые места между толстыми чешуйками.

Подняв высоко руку, полуэльф свистит [ястребу](CREATURE:${CREATURE_HAWK}), кружащему высоко над ним, призывая птицу к себе. Нашёптывая указания на эльфийском, он показывает на выслеженного [совомеда](CREATURE:${CREATURE_OWLBEAR}) и посылает [ястреба](CREATURE:${CREATURE_HAWK}), чтобы отвлечь существо, пока он готовит свой лук.

Вдали от суеты городов и посёлков, за изгородями, которые защищают самые далёкие фермы от ужасов дикой природы, среди плотно стоящих деревьев, беспутья лесов и на просторах необъятных равнин следопыты несут свой бесконечный дозор.`,
    {
      header: `Смертоносные охотники`,
      text: `Бойцы диких земель, следопыты, специализируются на охоте на монстров, таких как неистовствующие звери, чудовищные создания, ужасающие великаны и смертоносные драконы, угрожающих нападением на цивилизованные земли гуманоидов. Они умеют выслеживать добычу подобно хищнику, скрытно передвигаясь через дебри, прячась среди кустов и камней. Тренируясь сражаться, следопыты делают упор боевой подготовки на сражениях с избранными врагами.

Благодаря близости с дикой природой, следопыты приобретают способность творить заклинания, взывая к силам природы подобно друидам. Их заклинания, как и боевые навыки, делают акцент на скорость, скрытность и охоту. Таланты и способности следопыта оттачиваются с концентрацией на защите пограничных земель. `,
      source: {
        id: SOURCE_PHB,
        page: 90,
      },
    },
    {
      header: `Независимые искатели приключений`,
      text: `Хотя следопыт может заработать на жизнь как охотник или проводник, его истинным призванием является защита приграничных земель от разрушительных действий чудовищ и гуманоидных орд, приходящих из диких земель. В некоторых местах следопыты собираются в тайные ордены или объединяют усилия с кругами друидов. Однако, многие следопыты независимы, так как знают, что если нападёт дракон или отряд [орков](CREATURE:${CREATURE_ORC}), они будут первой (и чаще всего последней) линией обороны.

Эта яростная независимость делает следопытов пригодными для приключений, так как они привыкли жить вдали от комфорта сухой постели и горячей ванны. Сталкиваясь с воспитанными в городе искателями приключений, которые ноют о тяготах жизни в дикой природе, следопыты реагируют на это с помесью веселья, разочарования и сочувствия. Но они понимают, что другие искатели приключений, привносящие свою лепту в дело борьбы с врагами цивилизации, стоят дополнительных усилий. Изнеженные горожане могут не знать, как прокормить себя или найти пресную воду в дикой природе, но они компенсируют это незнание своими навыками.`,
      source: {
        id: SOURCE_PHB,
        page: 90,
      },
    },
    {
      header: `Создание следопыта`,
      text: `Создавая следопыта, подумайте, какое обучение дало вам ваши выдающиеся возможности. Возможно, Вы обучались персональным наставником, блуждая по дебрям вместе с ним? Или Ваше обучение закончилось после смерти наставника, убитого чудовищем, все собратья которого стали для вас заклятыми врагами? Возможно, вас учили необходимым навыкам в группе следопытов, связанной с кругом друидов, постигшим мистические пути и тайны дикой природы. Вы можете быть самоучкой, отшельником, который познал боевые навыки, навыки выслеживания, и даже волшебную связь с природой через необходимость выживания в дикой местности.

Что является источником Вашей ненависти к определённого рода врагам? Может, чудовище убило того, кого Вы любите, или уничтожило вашу деревню? Или Вы видели слишком много разрушений, причинённых этими чудовищами, после которых Вы и посвятили себя обузданию их бесчинств? Является ли Ваша карьера продолжением Вашей работы по охране пограничных земель или это что-то совершенно другое? Что заставило вас объединиться с группой искателей приключений? Считаете ли Вы, что научить новых союзников пути дикой природы сложно, или Вы радуетесь освобождению от одиночества, которое они вам предлагают? `,
      source: {
        id: SOURCE_PHB,
        page: 91,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать следопыта, следуя этим рекомендациям.

1. Во-первых, у Вашей Ловкости должно быть наивысшее значение. Следующим по величине должно быть значение Мудрости. Следопыты, концентрирующиеся на сражении с двумя оружиями, делают наивысшей характеристикой Силу, а не Ловкость.
2. Во-вторых, выберите предысторию «[Чужеземец](BACKGROUND:${BACKGROUND_OUTLANDER})».`,
      source: {
        id: SOURCE_PHB,
        page: 91,
      },
    },
  ],
  note: {
    text: `Я провожу много времени вдали от цивилизации, держась на границе с ней, чтобы защищать её. Не думай, что из-за того, что я не преклоняю колен перед твоим королём, я не сделал больше для его защиты, чем все его рыцари`,
    author: `Совеллис`,
  },
  featureCollection: {
    hitDice: 10,
    proficiency: {
      armor: [
        CAT_LIGHT_ARMOR,
        CAT_MEDIUM_ARMOR,
        CAT_SHIELDS,
      ],
      weapon: {
        catList: [
          CAT_SIMPLE_WEAPON,
          CAT_MARTIAL_WEAPON,
        ],
      },
      savingThrow: [
        PARAM_DEX,
        PARAM_STR,
      ],
      skill: {
        toChoose: {
          count: 3,
          list: [
            SKILL_ANIMAL_HANDLING,
            SKILL_ATHLETICS,
            SKILL_INSIGHT,
            SKILL_INVESTIGATION,
            SKILL_NATURE,
            SKILL_PERCEPTION,
            SKILL_STEALTH,
            SKILL_SURVIVAL,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 5,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) [Чешуйчатый доспех](GEAR:${GEAR_SCALE_MAIL})
    * б) [Кожаный доспех](GEAR:${GEAR_LEATHER_ARMOR})
2. Выберите одно
    * а) Два [коротких меча](GEAR:${GEAR_SHORTSWORD})
    * б) Два простых рукопашных оружия
3. Выберите одно
    * а) [Набор исследователя подземелий](GEAR:${GEAR_DUNGEONEER_S_PACK})
    * б) [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})
4. [Длинный лук](GEAR:${GEAR_LONGBOW})
5. [Колчан](GEAR:${GEAR_QUIVER}) с 20 [стрелами](GEAR:${GEAR_ARROWS})`,
    },
  },
  spellSlotTable: halfCasterSpellSlotTable,
  source: [
    {
      id: SOURCE_PHB,
      page: 90,
    },
    {
      id: SOURCE_SRD,
      page: 36,
    },
  ],
}
