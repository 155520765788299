const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Оживлённые предметы',
    text: `Оживлённые предметы создаться мощной магией и следуют командам своих создателей. Не получая приказов, они следуют последней полученной команде, стараясь выполнить её изо всех сил. Они могут действовать самостоятельно, выполняя простые инструкции. Некоторые из оживлённых предметов (особенно те, что созданы в Стране Фей) могут свободно разговаривать или отыгрывать роль, но по большей части это простые автоматы.`,
    source: {
      id: SOURCE_MM,
      page: 224,
    },
  },
  {
    header: 'Природа конструкта',
    text: `Оживлённые предметы не нуждаются в воздухе, еде, питье и сне.

Магия, которая оживляет предмет, рассеивается, когда хиты предмета уменьшаются до 0. Оживлённый предмет с 0 хитов перестаёт быть живым и получает слишком много повреждений, чтобы его можно было использовать ещё раз, и он перестаёт представлять собой ценность.`,
    source: {
      id: SOURCE_MM,
      page: 224,
    },
  },
]
