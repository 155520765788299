const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    SPELL_BLUR,
    SPELL_HYPNOTIC_PATTERN,
    SPELL_MINOR_ILLUSION,
  } = require('./../../../../spellIdList'),
  {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    LANG_AURAN,
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_EBLIS,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Эблис',
  nameEn: 'Eblis',
  id: CREATURE_EBLIS,
  description: `Эблис считается реинкарнацией злых людей, которые были наказаны богами за воровство и похищения людей. Эти умные злые журавли достигают в высоту 8 футов и откладывают яйца, как обычные птицы. Они могут манипулировать предметами при помощи своих клювов и ног.

Эблисы из корыстных побуждений пытаются подчинять других существ, заставляя их выполнять свою волю. Они живут, чтобы уничтожать существ, которые их оскорбляют и восхищаются тем, что заманивают гуманоидов к неожиданной смерти. Эблисы также любят собирать сокровища, которыми они иногда награждают тех, кто им служит. Они строят примитивные хижины и гнезда из травы и камыша, где и прячут свои сокровища.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_TOA,
    page: 242,
  },
  armor: 13,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 12,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_AURAN,
    LANG_COMMON,
  ],
  cr: CR_1,
  spellCast: {
    baseStat: PARAM_INT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_BLUR,
          SPELL_HYPNOTIC_PATTERN,
          SPELL_MINOR_ILLUSION,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Клювом_.`,
    },
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
