const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NG} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_PSEUDODRAGON} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_DRAGON} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPELL_FIND_FAMILIAR} = require('./../../../../spellIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Псевдодракон',
  nameEn: 'Pseudodragon',
  id: CREATURE_PSEUDODRAGON,
  description: [
    {
      header: 'Псевдодракон',
      text: `Неуловимый псевдодракон обитает в тихих местах, устраивая свой дом в дуплах деревьев и небольших пещерах. С чешуёй красно-коричневого цвета, рогами и пастью, полной острых зубов, псевдодракон напоминает крошечного красного дракона, но он более предрасположен к играм.`,
      source: {
        id: SOURCE_MM,
        page: 245,
      },
    },
    {
      header: 'Тихий защитник',
      text: `Псевдодраконы мало интересуются другими созданиями, и по возможности избегают встречи с ними. Если же псевдодракон атакован, он обороняется ядовитым жалом, расположенным на кончике хвоста, один удар которого способен на несколько часов лишить сознания.`,
      source: {
        id: SOURCE_MM,
        page: 245,
      },
    },
    {
      header: 'Драконий фамильяр',
      text: `Маги часто стремятся заполучить псевдодраконов в [фамильяры](SPELL:${SPELL_FIND_FAMILIAR}), за их покладистый нрав, телепатические способности и сопротивление магии. Псевдодраконы избирательны в вопросах спутников, но иногда их удаётся расположить к себе подношениями в виде еды или сокровища. В случаях, когда спутники нашли друг друга, псевдодракон остаётся в компании, пока к нему относятся справедливо. Псевдодракон не терпит жестокого обращения и покидает манипулятора или злонамеренного спутника без предупреждения.`,
      source: {
        id: SOURCE_MM,
        page: 245,
      },
    },
    {
      header: 'Язык эмоций',
      text: `Псевдодраконы не умеют разговаривать, но могут общаться с помощью ограниченной формы телепатии, позволяющей им делиться общими идеями, такими как голод, любопытство или симпатия. Когда между ним и его спутником появляется связь, псевдодракон может передавать, что он видит и слышит даже на очень большое расстояние.

Псевдодраконы часто используют звуки животных. Хриплое мурлыканье означает удовольствие, шипение — неприятное удивление. Птичье щебетание показывает желание, а рык всегда демонстрирует гнев или неудовольствие.`,
      source: {
        id: SOURCE_MM,
        page: 245,
      },
    },
  ],
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
  ],
  alignmentId: ALIGNMENT_NG,
  source: {
    id: SOURCE_MM,
    page: 245,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 15,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 13,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 10,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: [LANG_DRACONIC, LANG_COMMON],
      doNotSpeak: true,
    },
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: `Острые чувства`,
      description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), полагающиеся на зрение, слух и обоняние.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    {
      name: `Ограниченная телепатия`,
      description: `★СУЩЕСТВО★ может магическим образом передавать простые мысли, эмоции и образы любому существу в пределах 100 футов, понимающему любой язык.`,
    },
  ],
  actionList: [
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: `Жало`,
      description: `Цель должна пройти испытание Телосложения СЛ 11, иначе станет отравленной на 1 час. Если испытание провалено на 5 или больше единиц, цель теряет сознание на этот же период, либо пока не получит урон, либо пока другое существо не разбудит её действием.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
