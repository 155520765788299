const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_GRAVE_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  SPELL_ANTILIFE_SHELL,
  SPELL_BANE,
  SPELL_BLIGHT,
  SPELL_DEATH_WARD,
  SPELL_FALSE_LIFE,
  SPELL_GENTLE_REPOSE,
  SPELL_RAISE_DEAD,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_REVIVIFY,
  SPELL_SPARE_THE_DYING,
  SPELL_VAMPIRIC_TOUCH,
} = require('./../../../spellIdList')
const {
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_PATH_TO_THE_GRAVE,
  FEATURE_CIRCLE_OF_MORTALITY,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_EYES_OF_THE_GRAVE,
  FEATURE_GRAVE_DOMAIN_SPELLS,
  FEATURE_KEEPER_OF_SOULS,
  FEATURE_POTENT_SPELLCASTING_GRAVE_DOMAIN,
  FEATURE_SENTINEL_AT_DEATH_S_DOOR,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_GRAVE_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_ANTILIFE_SHELL,
      SPELL_BANE,
      SPELL_BLIGHT,
      SPELL_DEATH_WARD,
      SPELL_FALSE_LIFE,
      SPELL_GENTLE_REPOSE,
      SPELL_RAISE_DEAD,
      SPELL_RAY_OF_ENFEEBLEMENT,
      SPELL_REVIVIFY,
      SPELL_VAMPIRIC_TOUCH,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 35,
    },
  },
  {
    id: FEATURE_CIRCLE_OF_MORTALITY,
    name: `Круг смерти`,
    nameEn: `Circle of Mortality`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
    text: `Вы получаете возможность управлять гранью между жизнью и смертью. Если обычно должны Вы бросить одну или несколько костей, чтобы заклинанием восстановить хиты существу с 0 хитов, то вместо этого Вы используете максимально возможное число для каждой кости.

Кроме того, Вы изучаете заговор [Уход за умирающим](SPELL:${SPELL_SPARE_THE_DYING}), который не учитывается при подсчете известных Вам заговоров жреца. Для Вас он имеет дистанцию 30 футов, и Вы можете сотворять его бонусным действием.`,
    spellIdList: SPELL_SPARE_THE_DYING,
    source: {
      id: SOURCE_XGTE,
      page: 36,
    },
  },
  {
    id: FEATURE_EYES_OF_THE_GRAVE,
    name: `Глаза могилы`,
    nameEn: `Eyes of the Grave`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
    text: `Вы получаете возможность иногда ощущать присутствие нежити, существование которых является оскорблением естественного цикла жизни.

Действием Вы можете открыть свой разум, чтобы магическим образом обнаружить нежить. До конца Вашего следующего хода Вы знаете местоположение любой нежити в пределах 60 футов от Вас, которая не находится за полным укрытием и не защищена от магии прорицания. Это чувство ничего не позволяет Вам узнать о возможностях существа или идентифицировать его.

Вы можете использовать эту особенность количество раз, равное Вашему модификатору Мудрости (минимум 1 раз). Вы восстанавливаете все потраченные применения, когда завершаете длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 36,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_PATH_TO_THE_GRAVE,
    name: `Божественный канал: Путь к могиле`,
    nameEn: `Channel Divinity: Path to the Grave`,
    lvl: 2,
    pcSubClassId: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
    text: `Вы можете использовать свой [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы пометить жизненную силу другого существа как подлежащую уничтожению.

Действием Вы выбираете одно существо, видимое Вами в пределах 30 футов, проклиная его до конца Вашего следующего хода. Когда Вы или союзник попадете по проклятому существу атакой, у существа будет уязвимость ко всему урону от атаки, а затем проклятие заканчивается.`,
    source: {
      id: SOURCE_XGTE,
      page: 36,
    },
  },
  {
    id: FEATURE_SENTINEL_AT_DEATH_S_DOOR,
    name: `Страж на пороге смерти`,
    nameEn: `Sentinel at Death’s Door`,
    lvl: 6,
    pcSubClassId: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
    text: `Вы получаете возможность препятствовать смерти. Реакцией, когда Вы или существо, которое Вы видите в пределах 30 футов от Вас, подвергаются критическому удару, Вы можете превратить этот удар в обычный удар. Любые эффекты, вызванные критическим ударом, будут отменены.

Вы можете использовать эту особенность количество раз, равное Вашему модификатору Мудрости (минимум 1 раз). Вы восстанавливаете все потраченные применения, когда завершаете длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 36,
    },
  },
  {
    id: FEATURE_POTENT_SPELLCASTING_GRAVE_DOMAIN,
    name: `Мощное заклинание`,
    nameEn: `Potent Spellcasting`,
    lvl: 8,
    pcSubClassId: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
    text: `Вы добавляете модификатор Мудрости к урону, который Вы наносите любым заговором жреца.`,
    source: {
      id: SOURCE_XGTE,
      page: 36,
    },
  },
  {
    id: FEATURE_KEEPER_OF_SOULS,
    name: `Хранитель душ`,
    nameEn: `Keeper of Souls`,
    lvl: 17,
    pcSubClassId: PC_SUBCLASS_CLERIC_GRAVE_DOMAIN,
    text: `Вы можете схватить остатки жизненной силы умирающего и использовать их, чтобы исцелить живых.

Когда враг, видимый Вами, умирает в пределах 60 футов от Вас, Вы или одно существо по Вашему выбору, находящееся в пределах 60 футов от Вас, восстанавливает количество хитов, равное числу Костей Хитов врага. Вы можете использовать эту способность, только если Вы дееспособны. 

Использовав эту способность, Вы не можете её использовать снова до начала Вашего следующего хода.`,
    source: {
      id: SOURCE_XGTE,
      page: 36,
    },
  },
]
