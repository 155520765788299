const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_BARKSKIN,
  SPELL_CURE_WOUNDS,
  SPELL_JUMP,
  SPELL_PLANT_GROWTH,
  SPELL_SPIKE_GROWTH,
} = require('./../../../../spellIdList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_GRUNG_POISON_SKIN,
  ABILITY_STANDING_LEAP,
} = require('./../../../../creatureAbilityList')
const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  GEAR_DAGGER,
  GEAR_SHORTBOW,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_GRUNG,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  grungDescriptionList,
  grungNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_GRUNG_WILDLING} = require('./../../../../creatureIdList')
const {LANG_GRUNG} = require('./../../../../languageIdList')
const {PC_CLASS_RANGER} = require('./../../../../pcClassIdList')
const {SIZE_SMALL} = require('./../../../../sizeList')

module.exports = {
  name: 'Дикий грунг',
  nameEn: 'Grung Wildling',
  id: CREATURE_GRUNG_WILDLING,
  description: grungDescriptionList,
  note: grungNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GRUNG,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 146,
    },
    {
      id: SOURCE_TOA,
      page: 220,
    },
  ],
  armor: [
    13,
    {
      ac: 16,
      comment: `с [Дубовой корой](SPELL:${SPELL_BARKSKIN})`,
    },
  ],
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 25,
    [SPEED_CLIMB]: 25,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_DEX,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  languageList: [
    LANG_GRUNG,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_GRUNG_POISON_SKIN,
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 25,
      jumpHeight: 15,
    },
  ],
  spellCast: {
    spellCasterLevel: 9,
    spellCasterClass: PC_CLASS_RANGER,
    spellIdList: [
      SPELL_CURE_WOUNDS,
      SPELL_BARKSKIN,
      SPELL_JUMP,
      SPELL_SPIKE_GROWTH,
      SPELL_PLANT_GROWTH,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
      description: `Цель должна пройти испытание Телосложения СЛ 12 или получить 5 (2к4) урона ядом.`,
    },
    {
      gearId: GEAR_SHORTBOW,
      description: `Цель должна пройти испытание Телосложения СЛ 12 или получить 5 (2к4) урона ядом.`,
    },
  ],
}
