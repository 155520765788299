const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ELEMENTAL,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CN,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_IGNAN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CREATURE_MAGMIN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Магмин',
  nameEn: 'Magmin',
  description: [
    {
      header: 'Магмины',
      text: `Магмины — зловредные существа с поганой ухмылкой, похожие на приземистого человека, сделанного из куска чёрной магмы. Даже когда они не горят в полную силу, распространяя вокруг себя жар, словно от большого костра, сквозь их пористую кожу пробиваются небольшие языки пламени.`,
      source: {
        id: SOURCE_MM,
        page: 199,
      },
    },
    {
      header: 'Призванные пироманы',
      text: `Магмины — стихийные духи огня, получившие физическое воплощение при помощи магии, поэтому они могут появиться на Материальном плане только тогда, когда их призывают. Все воспламеняющиеся предметы они воспринимают как потенциальную растопку для грандиозного пожара, и только магический контроль со стороны призвавшего сдерживает их от того, чтобы поджигать всё, к чему они прикасаются. Непредсказуемая огненная природа магминов делает их идеальными инструментами для желающих сеять хаос и разрушения. Группа магминов, призванных в стенах замка, может за считанные минуты превратить его в дымящиеся развалины.`,
      source: {
        id: SOURCE_MM,
        page: 199,
      },
    },
    {
      header: 'Разрушительное пламя',
      text: `Огонь магминов жарок, но его сдерживает твёрдый магматический панцирь, не позволяющий мгновенно воспламенять всё, что находится поблизости. При этом, подобно пламени, горящему у них внутри, магмины капризны и непредсказуемы. Более того, как довольно примитивные стихийные создания, они могут забывать о том, что их родная стихия может причинять вред существам Материального плана.

Если призванному магмину представится такая возможность, он постарается забраться туда, где пожарче — они любят лесные пожары и бурлящие озёра лавы в жерлах активных вулканов. Если такой возможности нет, магмин будет время от времени неосознанно высвобождать пламя из кончиков своих пальцев, утешаясь хотя бы возможностью что-нибудь поджечь.`,
      source: {
        id: SOURCE_MM,
        page: 199,
      },
    },
  ],
  id: CREATURE_MAGMIN,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 199,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_IGNAN,
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Предсмертная вспышка',
      description: `Когда ★СУЩЕСТВО★ умирает, ★он★ взрывается огнём и лавой. Все существа в пределах 10 футов от него должны пройти испытание Ловкости СЛ 11, получая урон огнём 7 (2к6) при провале, или половину этого урона при успехе. Горючие предметы, которые никто не несёт и не носит в этой области, воспламеняются.`,
    },
    {
      name: 'Свечение от горения',
      description: `★СУЩЕСТВО★ может бонусным действием вспыхнуть или погасить пламя на себе. Пока ★СУЩЕСТВО★ горит, ★он★ испускает яркий свет в радиусе 10 футов и тусклый свет в пределах ещё 10 футов.`,
    },
  ],
  actionList: [
    {
      name: 'Касание',
      description: `Если цель — существо или горючий предмет, она воспламеняется. Пока какое-нибудь существо не потушит огонь действием, цель получает урон огнём 3 (1к6) в конце каждого своего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 2,
          diceType: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
