const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_IOKHARIC} = require('./../../alphabetList')
const {PLACE_CHULT} = require('./../../placeIdList')
const {SOURCE_TOA} = require('./../../sourceList')
const {
  LANG_CHULT,
  LANG_COMMON,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_CHULT,
  alphabetId: ALPHABET_IOKHARIC,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Чультанский',
    genitive: 'Чультанского',
    instrumental: 'Чультанским',
    prepositional: 'Чультанским',
  },
  nameEn: 'Chult',
  nameAlt: 'Табакси',
  nameEnAlt: 'Tabaxi',
  spokenPlaceList: PLACE_CHULT,
  description: [
    {
      text: `Среди своих чульты разговаривают на племенных языках (смеси вдыхаемых и выдыхаемых согласных, гласных и щёлканья языка). Однако те, кто часто общаются с приезжими, свободно разговаривают на [Всеобщем](LANG:${LANG_COMMON}), но с сильным и характерным акцентом.`,
      source: {
        id: SOURCE_TOA,
        page: 13,
      },
    },
  ],
  isReady: true,
  isRealLang: true,
}
