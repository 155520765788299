const declint = require('declint-ru')

const {spellCollection} = require('./../../../../../spellList')

const generateSpellText = (spellList, isSingleRow = false) => spellList.reduce(
  (text, {id: spellId, chargeCost}, i) => {
    const { name: spellName } = spellCollection[spellId]
    const chargeText = declint(chargeCost, ['заряд', 'заряда', 'зарядов'])
    const rowPreText = isSingleRow ? '' : '    * '
    const row = `${rowPreText}[${spellName}](SPELL:${spellId}) (${chargeCost} ${chargeText})`
    const rowSeparator = isSingleRow
      ? i
        ? `, `
        : ''
      : '\n'

    return text
      ? `${text}${rowSeparator}${row}`
      : row
  },
  ''
)

module.exports = generateSpellText
