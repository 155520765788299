const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_MODRONE,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_DAGGER,
  GEAR_JAVELIN,
} = require('./../../../../gearIdList')
const {
  modronDescriptionList,
  modronNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AXIOMATIC_MIND,
  ABILITY_DISINTEGRATION,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_8} = require('./../../../../crList')
const {CREATURE_MONODRONE} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_MODRONE} = require('./../../../../languageIdList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Монодрон',
  nameEn: 'Monodrone',
  id: CREATURE_MONODRONE,
  description: [
    'Монодрон может выполнять одновременно не более одной простой задачи и передавать одно сообщение длиной не более сорока восьми слов.',
    ...modronDescriptionList,
  ],
  note: modronNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_MODRONE,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 212,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 12,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_MODRONE,
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_AXIOMATIC_MIND,
    ABILITY_DISINTEGRATION,
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
    {
      gearId: GEAR_JAVELIN,
    },
  ],
  genderId: GENDER_MALE,
}
