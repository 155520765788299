const {SOURCE_UA_2020_09_15_TCoE_PC_BPoWM} = require('./../../../sourceList')
const {PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC_TCoE_PC_BPoWM} = require('./../../../pcSubClassIdList')
const {
  CREATURE_FLUMPH,
  CREATURE_PIXIE,
} = require('./../../../creatureIdList')
const {
  FEATURE_BOLSTERING_MAGIC_TCoE_PC_BPoWM,
  FEATURE_CONTROLLED_SURGE_TCoE_PC_BPoWM,
  FEATURE_MAGIC_AWARENESS_TCoE_PC_BPoWM,
  FEATURE_RAGE,
  FEATURE_UNSTABLE_BACKLASH_TCoE_PC_BPoWM,
  FEATURE_WILD_SURGE_TCoE_PC_BPoWM,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_MAGIC_AWARENESS_TCoE_PC_BPoWM,
    name: `Чувство магии`,
    nameEn: `Magic Awareness`,
    lvl: 3,
    text: `Вы можете действием открыть свой разум присутствию концентрированной магии.

До конца своего следующего хода Вы знаете местоположение любого заклинания или магического предмета в пределах 60 футов, которые не находятся за полным укрытием. Ощущая заклинание, Вы узнаёте, к какой школе магии оно принадлежит.

Вы можете использовать эту способность количество раз, равное вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
  },
  {
    id: FEATURE_WILD_SURGE_TCoE_PC_BPoWM,
    name: `Дикий всплеск`,
    nameEn: `Wild Surge`,
    lvl: 3,
    text: `Бурлящая внутри вас магическая энергия иногда вырывается наружу.

Когда Вы впадаете в [Ярость](FEATURE:${FEATURE_RAGE}), совершите бросок по таблице _Дикой магии_, чтобы определить произошедший магический эффект.

Если эффект требует испытания, **СЛ равна 8 + Ваш бонус мастерства + Ваш модификатор Телосложения**.

# Дикая магия

| к8 | Магический эффект |
|----|-------------------|
| 1  | Каждое существо по Вашему выбору, видимое Вами в пределах 30 футов, должно пройти испытание Телосложения, иначе получит 1к12 урона некротической энергией. Вы получаете временные хиты, равные 1к12 плюс Ваш уровень варвара. |
| 2  | Вы телепортируетесь в свободное пространство, видимое Вами в пределах 30 футов. Пока Ваша [Ярость](FEATURE:${FEATURE_RAGE}) не закончится, Вы можете бонусныи действием снова применять этот эффект на каждом своём ходу. |
| 3  | Неосязаемый дух, который выглядит как [фламф](CREATURE:${CREATURE_FLUMPH}) или [пикси](CREATURE:${CREATURE_PIXIE}) (на Ваш выбор), появляется в пределах 5 футов от одного выбранного Вами существа, видимого Вами в пределах 30 футов. В конце этого хода дух взрывается, и каждое существо в пределах 5 футов от него должно пройти испытание Ловкости, иначе получит 1к6 урона силовым полем. Пока Ваша [Ярость](FEATURE:${FEATURE_RAGE}) не закончится, Вы можете каждый свой ход бонусныи действием снова применять этот эффект, призывая нового духа. |
| 4  | Световой снаряд вылетает из Вашей груди. Другое существо по Вашему выбору, видимое Вами в пределах 30 футов, должно пройти испытание Телосложения или получить 1к6 урона излучением и ослепнуть до начала Вашего следующего хода. Пока Ваша [Ярость](FEATURE:${FEATURE_RAGE}) не закончится, Вы можете бонусныи действием снова применять этот эффект на каждом своём ходу. |
| 5  | Каждый раз, когда существо попадает по Вам атакой до того, пока Ваша [Ярость](FEATURE:${FEATURE_RAGE}) не закончится, это существо получает 1к6 урона силовым полем, поскольку магия хлещет его в ответ. |
| 6  | Пока Ваша [Ярость](FEATURE:${FEATURE_RAGE}) не закончится, Вас окружают разноцветные защитные огни. Вы и союзники в пределах 10 футов от Вас получаете +1 к КД. |
| 7  | Вокруг Вас временно вырастают цветы и лозы. Пока Ваша [Ярость](FEATURE:${FEATURE_RAGE}) не закончится, земля в пределах 15 футов от Вас становится труднопроходимой местностью для Ваших врагов. |
| 8  | Магия наполняет одно оружие по Вашему выбору, которое Вы держите в руках. Пока Ваша [Ярость](FEATURE:${FEATURE_RAGE}) не закончится, тип урона этого оружия изменится на урон силовым полем, и оно приобретет свойсто Лёгкое и Метательные свойства с нормальной дальностью 20 футов и максимальной дальностью 60 футов. Если оружие покидает Вашу руку, оно снова появляется в Вашей руке в конце этого хода. |`,
  },
  {
    id: FEATURE_BOLSTERING_MAGIC_TCoE_PC_BPoWM,
    name: `Поддерживающая магия`,
    nameEn: `Bolstering Magic`,
    lvl: 6,
    text: `Вы можете использовать свою дикую магию, чтобы поддержать себя или союзника.

Вы можете действием коснуться одного существа (в том числе себя) и дать ему одно из следующих преимуществ по Вашему выбору:

* В течение 10 минут, когда существо совершает бросок атаки или проверку способности, оно может бросить к3 и прибавить результат к броску к20.
* Бросьте к3. Существо восстанавливает одну израсходованную ячейку заклинания, уровень которой равен или меньше выпавшего числа (по выбору существа). Получив это преимущество, существо не сможет получить его снова, пока не завершит длинный отдых.

Вы можете использовать эту способность количество раз, равное вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
  },
  {
    id: FEATURE_UNSTABLE_BACKLASH_TCoE_PC_BPoWM,
    name: `Нестабильный выплеск`,
    nameEn: `Unstable Backlash`,
    lvl: 10,
    text: `Когда Вы находитесь в опасности во время [Ярости](FEATURE:${FEATURE_RAGE}), магия внутри Вас может выплеснуться наружу.

Сразу после того, как Вы получили урон или провалили испытание во время [Ярости](FEATURE:${FEATURE_RAGE}), Вы можете реакцией совершить бросок по таблице дикой магии и немедленно воплотить полученный эффект.

Этот эффект заменит Ваш текущий эффект дикой магии.`,
  },
  {
    id: FEATURE_CONTROLLED_SURGE_TCoE_PC_BPoWM,
    name: `Управляемый всплеск`,
    nameEn: `Controlled Surge`,
    lvl: 14,
    text: `Каждый раз, когда Вы бросаете по [таблице всплесков дикой магии](FEATURE:${FEATURE_WILD_SURGE_TCoE_PC_BPoWM}), Вы можете бросить кость дважды и выбрать, какой из двух эффектов применять.

Если Вы выбросите одно и то же число на обоих костях, то можете проигнорировать его и выбрать любой эффект в таблице.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC_TCoE_PC_BPoWM,
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_BPoWM,
      page: 1,
    },
  })
)

