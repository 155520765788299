const {PC_SUBCLASS_MONK_WAY_OF_MERCY} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {GEAR_HERBALISM_KIT} = require('./../../../gearIdList')
const {
  FEATURE_FLURRY_OF_HEALING_AND_HARM,
  FEATURE_HAND_OF_HARM,
  FEATURE_HAND_OF_HEALING,
  FEATURE_HAND_OF_ULTIMATE_MERCY,
  FEATURE_IMPLEMENTS_OF_MERCY,
  FEATURE_KI,
  FEATURE_PHYSICIAN_S_TOUCH,
  FEATURE_MARTIAL_ARTS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_IMPLEMENTS_OF_MERCY,
    name: `Инструменты милосердия`,
    nameEn: `Implements of Mercy`,
    lvl: 3,
    text: `Вы получаете владение Проницательностью, Медициной и [набором травника](GEAR:${GEAR_HERBALISM_KIT}).

Кроме того, Вы получаете специальную маску, которую часто надеваете при использовании способностей этого подкласса. Вы определяете её внешний вид или можете выбрать его случайно.

# Маски милосердия

| к6 | Маска             |
|----|-------------------|
| 1  | Ворон             |
| 2  | Чёрно-белая маска |
| 3  | Плачущий лик      |
| 4  | Смеющийся лик     |
| 5  | Череп             |
| 6  | Бабочка           |
`,
    source: {
      id: SOURCE_TCoE,
      page: 70,
    },
  },
  {
    id: FEATURE_HAND_OF_HEALING,
    name: `Исцеляющая длань`,
    nameEn: `Hand of Healing`,
    lvl: 3,
    text: `Ваше прикосновение может закрывать раны.

Вы можете действием потратить 1 [очко Ци](FEATURE:${FEATURE_KI}), касаясь существа и восстанавливая ему хиты. Количество хитов равно броску Вашей [кости боевых искусств](FEATURE:${FEATURE_MARTIAL_ARTS}) + Вашему модификатору Мудрости.

Когда Вы используете [Шквал ударов](FEATURE:${FEATURE_KI}), Вы можете заменить одну безоружную атаку на использование этой способности без траты [очков Ци](FEATURE:${FEATURE_KI}) на лечение.`,
    source: {
      id: SOURCE_TCoE,
      page: 71,
    },
  },
  {
    id: FEATURE_HAND_OF_HARM,
    name: `Вредящая длань`,
    nameEn: `Hand of Harm`,
    lvl: 3,
    text: `Вы можете использовать [Ци](FEATURE:${FEATURE_KI}) для нанесения ран.

Когда Вы попадаете по существу безоружной атакой, Вы можете потратить 1 [очко Ци](FEATURE:${FEATURE_KI}), чтобы нанести дополнительный некротический урон, равный броску Вашей [кости боевых искусств](FEATURE:${FEATURE_MARTIAL_ARTS}) + Вашему модификатору Мудрости.

Вы можете использовать эту способность только один раз в ход.`,
    source: {
      id: SOURCE_TCoE,
      page: 71,
    },
  },
  {
    id: FEATURE_PHYSICIAN_S_TOUCH,
    name: `Касание целителя`,
    nameEn: `Physician’s Touch`,
    lvl: 6,
    text: `Вы можете ещё лучше исцелять одним касанием, а, если видите необходимость, можете использовать свои знания во вред.

Используя [Исцеляющую длань](FEATURE:${FEATURE_HAND_OF_HEALING}) на существе, Вы можете прекратить одну болезнь или одно из следующих его состояний: Ослепление, Глухоту, Парализацию, Отравление или Ошеломление.

Используя [Вредящую длань](FEATURE:${FEATURE_HAND_OF_HARM}) на существе, Вы можете Отравить его до конца своего следующего хода.`,
    source: {
      id: SOURCE_TCoE,
      page: 71,
    },
  },
  {
    id: FEATURE_FLURRY_OF_HEALING_AND_HARM,
    name: `Шквал исцеления и вреда`,
    nameEn: `Flurry of Healing and Harm`,
    lvl: 11,
    text: `Вы можете испустить шквал утешения и боли.

Используя [Шквал ударов](FEATURE:${FEATURE_KI}), Вы можете заменить каждый безоружный удар [Исцеляющей дланью](FEATURE:${FEATURE_HAND_OF_HEALING}), не тратя [очки Ци](FEATURE:${FEATURE_KI}) на исцеление.

Кроме того, нанося безоружный удар [Шквалом ударов](FEATURE:${FEATURE_KI}), Вы можете использовать [Вредящую длань](FEATURE:${FEATURE_HAND_OF_HARM}) с этим ударом, не тратя на неё [очков Ци](FEATURE:${FEATURE_KI}). Вы по-прежнему можете использовать [Вредящую длань](FEATURE:${FEATURE_HAND_OF_HARM}) только один раз в ход.`,
    source: {
      id: SOURCE_TCoE,
      page: 72,
    },
  },
  {
    id: FEATURE_HAND_OF_ULTIMATE_MERCY,
    name: `Длань абсолютного милосердия`,
    nameEn: `Hand of Ultimate Mercy`,
    lvl: 17,
    text: `Ваше владение жизненной силой открывает путь к абсолютному милосердию.

Вы можете действием коснуться трупа существа, умершего в течение последних 24 часов, и потратить 5 [очков Ци](FEATURE:${FEATURE_KI}). Существо возвращается к жизни, восстанавливая 4к10 + Ваш модификатор Мудрости хитов. Если существо умерло, находясь в одном из следующих условий, оно оживает без него: Ослепление, Глухота, Парализация, Отравление или Ошеломление.

Использовав эту способность, Вы не можете использовать его снова до завершения длинного отдыха.`,
    source: {
      id: SOURCE_TCoE,
      page: 72,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_MERCY,
  })
)
