const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_DRAGONFANG_BLACK,
  CREATURE_DRAGONFANG_BLUE,
  CREATURE_DRAGONFANG_GREEN,
  CREATURE_DRAGONFANG_RED,
  CREATURE_DRAGONFANG_WHITE,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ROT,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_SHORTSWORD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_DRAGON_FANATIC,
  ABILITY_FANATIC_ADVANTAGE,
  ABILITY_LIMITED_FLIGHT,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  cultOfDragonDescriptionList,
} = require('./../../../../textCommonParts')

module.exports = [
  {
    namePartRu: 'белый',
    namePartEn: 'White',
    id: CREATURE_DRAGONFANG_WHITE,
    damageType: DAMAGE_COLD,
  },
  {
    namePartRu: 'зелёный',
    namePartEn: 'Green',
    id: CREATURE_DRAGONFANG_GREEN,
    damageType: DAMAGE_POISON,
  },
  {
    namePartRu: 'красный',
    namePartEn: 'Red',
    id: CREATURE_DRAGONFANG_RED,
    damageType: DAMAGE_FIRE,
  },
  {
    namePartRu: 'синий',
    namePartEn: 'Blue',
    id: CREATURE_DRAGONFANG_BLUE,
    damageType: DAMAGE_ELECTRICITY,
  },
  {
    namePartRu: 'чёрный',
    namePartEn: 'Black',
    id: CREATURE_DRAGONFANG_BLACK,
    damageType: DAMAGE_ACID,
  },
]
  .map(
    (
      {
        namePartRu,
        namePartEn,
        id,
        damageType,
      }
    ) => ({
      name: `Драконий клык, ${namePartRu}`,
      nameEn: `Dragonfang, ${namePartEn}`,
      id,
      description: cultOfDragonDescriptionList,
      sizeType: SIZE_MEDIUM,
      creatureTypeIdList: [
        CREATURE_TYPE_HUMANOID,
        CREATURE_TYPE_HUMAN,
      ],
      alignmentId: ALIGNMENT_NE,
      source: {
        id: SOURCE_ROT,
        page: 89,
      },
      armor: {
        ac: 15,
        gearId: GEAR_STUDDED_LEATHER_ARMOR,
      },
      hp: {
        diceCount: 12,
      },
      speed: {
        [SPEED_WALK]: 30,
      },
      params: {
        [PARAM_STR]: 11,
        [PARAM_DEX]: 16,
        [PARAM_CON]: 14,
        [PARAM_INT]: 12,
        [PARAM_WIT]: 12,
        [PARAM_CHA]: 14,
      },
      saveThrowList: [
        PARAM_WIT,
      ],
      skillCollection: {
        [SKILL_DECEPTION]: PROF_NORMAL,
        [SKILL_STEALTH]: PROF_NORMAL,
      },
      resistanceList: [
        damageType,
      ],
      languageList: [
        LANG_COMMON,
        LANG_DRACONIC,
      ],
      cr: CR_5,
      featureList: [
        ABILITY_DRAGON_FANATIC,
        {
          id: ABILITY_FANATIC_ADVANTAGE,
          damage: '10 (3к6)',
        },
        ABILITY_LIMITED_FLIGHT,
        ABILITY_PACK_TACTICS,
      ],
      actionList: [
        {
          name: 'Мультиатака',
          description: `★СУЩЕСТВО★ совершает две атаки _Коротким мечом_.`,
        },
        {
          gearId: GEAR_SHORTSWORD,
          attack: {
            hit: {
              type: damageType,
              diceCount: 2,
              diceType: 6,
            },
          },
        },
        {
          name: 'Шар драконьего дыхания',
          limit: {
            count: 2,
            period: 'день',
          },
          attack: {
            type: ATTACK_RANGE_WEAPON,
            bonus: 5,
            range: 90,
            hit: {
              type: damageType,
              diceCount: 5,
              diceType: 8,
            },
          },
        },
      ],
    })
  )
