const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_KRUTHIK} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_KRUTHIK,
  nameEn: 'Kruthik',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'крутик',
      genitive: 'крутика',
      dative: 'крутику',
      accusative: 'крутика',
      instrumental: 'крутиком',
      prepositional: 'крутике',
    },
    plural: {
      nominative: 'крутики',
      genitive: 'крутиков',
      dative: 'крутикам',
      accusative: 'крутиков',
      instrumental: 'крутиками',
      prepositional: 'крутиках',
    },
  },
}
