const spellIdListByClassAndLvl = require('./../../spellIdListByClassAndLvl')
const { PC_CLASS_WIZARD } = require('./../../pcClassIdList')

const eldritchKnight_arcaneTrickster_spellIdList = [
  ...spellIdListByClassAndLvl[PC_CLASS_WIZARD][0],
  ...spellIdListByClassAndLvl[PC_CLASS_WIZARD][1],
  ...spellIdListByClassAndLvl[PC_CLASS_WIZARD][2],
  ...spellIdListByClassAndLvl[PC_CLASS_WIZARD][3],
  ...spellIdListByClassAndLvl[PC_CLASS_WIZARD][4],
]

module.exports = eldritchKnight_arcaneTrickster_spellIdList
