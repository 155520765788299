const {gearCollection} = require('./../../../gearList')
const {
  GEAR_GREATSWORD,
  GEAR_LONGSWORD,
  GEAR_SHORTSWORD,
} = require('./../../../gearIdList')

module.exports = [
  GEAR_GREATSWORD,
  GEAR_LONGSWORD,
  GEAR_SHORTSWORD,
]
  .map(
    (gearType) => {
      const {name, nameEn} = gearCollection[gearType]

      return {
        id: `moon_touched_${gearType}`,
        name: `Осенённый луной ${name.toLowerCase()}`,
        nameEn: `Moon-Touched ${nameEn}`,
        gearType,
      }
    }
  )
