module.exports = [
  {
    text: `Никогда не совершайте ошибку, полагая, что кобольды глупые и отсталые, потому что они маленького размера. Размер никак не влияет на их способности.`,
    author: `Воло`,
  },
  {
    text: `Кобольды становятся гораздо менее симпатичными, когда обучаются бросать огненные шары`,
    author: `Воло`,
  },
  `Тактика кобольдов редко учитывает такие мелочи, как дипломатия, милосердие или сдача`,
]
