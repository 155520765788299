const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_ACID,
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_COLD,
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_ELECTRICITY,
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_FIRE,
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_FORCE,
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_NECROTIC,
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_POISON,
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_PSYCHIC,
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_RADIANT,
  MAGIC_ITEM_POTION_OF_RESISTANCE_TO_THUNDER,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_ACID,
    damageTypeId: DAMAGE_ACID,
  },
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_COLD,
    damageTypeId: DAMAGE_COLD,
  },
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_FIRE,
    damageTypeId: DAMAGE_FIRE,
  },
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_FORCE,
    damageTypeId: DAMAGE_FORCE,
  },
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_ELECTRICITY,
    damageTypeId: DAMAGE_ELECTRICITY,
  },
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_NECROTIC,
    damageTypeId: DAMAGE_NECROTIC,
  },
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_POISON,
    damageTypeId: DAMAGE_POISON,
  },
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_PSYCHIC,
    damageTypeId: DAMAGE_PSYCHIC,
  },
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_RADIANT,
    damageTypeId: DAMAGE_RADIANT,
  },
  {
    id: MAGIC_ITEM_POTION_OF_RESISTANCE_TO_THUNDER,
    damageTypeId: DAMAGE_THUNDER,
  },
]
