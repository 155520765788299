const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_LIGHT_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')

const {
  SPELL_BURNING_HANDS,
  SPELL_DAYLIGHT,
  SPELL_FAERIE_FIRE,
  SPELL_FIREBALL,
  SPELL_FLAME_STRIKE,
  SPELL_FLAMING_SPHERE,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_LIGHT,
  SPELL_SCORCHING_RAY,
  SPELL_SCRYING,
  SPELL_WALL_OF_FIRE,
} = require('./../../../spellIdList')
const {
  FEATURE_BONUS_CANTRIP_LIGHT_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_RADIANCE_OF_THE_DAWN,
  FEATURE_CORONA_OF_LIGHT,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_IMPROVED_FLARE,
  FEATURE_LIGHT_DOMAIN_SPELLS,
  FEATURE_POTENT_SPELLCASTING_LIGHT_DOMAIN,
  FEATURE_WARDING_FLARE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_LIGHT_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_BURNING_HANDS,
      SPELL_DAYLIGHT,
      SPELL_FAERIE_FIRE,
      SPELL_FIREBALL,
      SPELL_FLAME_STRIKE,
      SPELL_FLAMING_SPHERE,
      SPELL_GUARDIAN_OF_FAITH,
      SPELL_SCORCHING_RAY,
      SPELL_SCRYING,
      SPELL_WALL_OF_FIRE,
    ],
  },
  {
    id: FEATURE_BONUS_CANTRIP_LIGHT_DOMAIN,
    name: `Дополнительный заговор`,
    nameEn: `Bonus Cantrip`,
    lvl: 1,
    text: `Вы получаете заговор [Свет](SPELL:${SPELL_LIGHT}), если ещё не имели его раньше.`,
    spellIdList: SPELL_LIGHT,
  },
  {
    id: FEATURE_WARDING_FLARE,
    name: `Защищающая вспышка`,
    nameEn: `Warding Flare`,
    lvl: 1,
    text: `Вы можете создать божественный свет между собой и атакующим противником. Если Вы атакованы видимым Вами существом, находящимся в пределах 30 футов, Вы можете реакцией создать помеху его броску атаки, вызывая перед атакующим вспышку света, до того как он попадёт или промажет. Существа, которые не могут быть ослеплены, обладают иммунитетом к этому умению.

Вы можете использовать это умение количество раз, равное Вашему модификатору Мудрости (минимум один раз). Вы восстанавливаете все потраченные использования, когда завершаете длинный отдых.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_RADIANCE_OF_THE_DAWN,
    name: `Божественный канал: Сияние рассвета`,
    nameEn: `Channel Divinity: Radiance of the Dawn`,
    lvl: 2,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы призывать солнечный свет, прогоняющий тьму и причиняющий урон излучением врагам.

Вы действием демонстрируете свой священный символ, и вся магическая тьма в пределах 30 футов от Вас рассеивается. Кроме того, все враждебные существа в пределах 30 футов от Вас должны пройти испытание Телосложения. Существа получают урон излучением, равный 2к10 + Ваш уровень жреца в случае провала, и половину этого урона в случае успешного испытания. Существа с полным укрытием от Вас не подвержены воздействию.`,
  },
  {
    id: FEATURE_IMPROVED_FLARE,
    name: `Улучшенная вспышка`,
    nameEn: `Improved Flare`,
    lvl: 6,
    text: `Вы можете использовать умение [Защищающая вспышка](FEATURE:${FEATURE_WARDING_FLARE}), когда существо, которое Вы можете видеть в пределах 30 футов от себя, атакует не Вас, а другое существо.`,
  },
  {
    id: FEATURE_POTENT_SPELLCASTING_LIGHT_DOMAIN,
    name: `Мощное заклинание`,
    nameEn: `Potent Spellcasting`,
    lvl: 8,
    text: `Вы добавляете модификатор Мудрости к урону, который причиняете заговорами жреца.`,
  },
  {
    id: FEATURE_CORONA_OF_LIGHT,
    name: `Корона света`,
    nameEn: `Corona of Light`,
    lvl: 17,
    text: `Вы можете действием создать ауру солнечного света, которая существует 1 минуту или пока Вы не рассеете её ещё одним действием.

Вы испускаете яркий свет в радиусе 60 футов и тусклый свет в радиусе ещё 30 футов. Ваши противники, находящиеся на ярком свету, совершают с помехой испытания от любых заклинаний, причиняющих урон огнём или излучением.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_LIGHT_DOMAIN,
    source: {
      id: SOURCE_PHB,
      page: 64,
    },
  })
)
