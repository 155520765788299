const {
  WEAPON_RANGE,
  WEAPON_THROWN,
  WEAPON_VERSATILE,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const throwWeaponDescription = require('./../../constants/throwWeaponDescription')
const {CAT_SIMPLE_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_SPEAR} = require('./../../../gearIdList')
const {GENDER_MIDDLE} = require('./../../../genderList')

module.exports = {
  id: GEAR_SPEAR,
  genderId: GENDER_MIDDLE,
  name: 'Копьё',
  nameByCase: {
    nominative: 'копьё',
    genitive: 'копья',
    accusative: 'копьё',
    instrumental: 'копьём',
  },
  nameEn: 'Spear',
  damage: {
    diceType: 6,
    diceCount: 1,
  },
  cost: 100,
  damageType: DAMAGE_PIERCING,
  weight: 3,
  description: throwWeaponDescription,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 114,
    },
  ],
  weaponPropList: [
    {
      id: WEAPON_VERSATILE,
      value: {
        diceCount: 1,
        diceType: 8,
      },
    },
    WEAPON_THROWN,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 20,
        max: 60,
      },
    },
  ],
  category: CAT_SIMPLE_MELEE_WEAPON,
}
