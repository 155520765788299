const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_MIDDLE,
} = require('./../../../../genderList')
const {
  MGC_TYPE_TATTOO,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
} = require('./../../../../magicItemRarityList')
const {
  MAGIC_ITEM_COILING_GRASP_TATTOO,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_COILING_GRASP_TATTOO,
  name: `Татуировка извивающейся хватки`,
  nameEn: `Coiling Grasp Tattoo`,
  type: MGC_TYPE_TATTOO,
  rarity: MGC_RARITY_UNCOMMON,
  attunement: true,
  description: `Эта магическая татуировка выглядит как длинные закрученные узоры. Она наносится специальной иглой.

# Настройка на татуировку

Для настройки на _Татуировку извивающейся хватки_, Вам нужно держать иглу у своей кожи в том месте, где Вы хотите нанести _Татуировку_, надавливая на иглу всё время настройки. Когда настройка завершится, игла превратится в чернила, которые станут _Татуировкой извивающейся хватки_ на коже.

Если Ваша настройка на _Татуировку_ завершится, она исчезнет, а игла снова появится в Вашем пространстве.

# Хватающие усики

Пока татуировка находится на Вашей коже, Вы можете действием заставить татуировку выдавить чернильные усики, которые достигнут видимого Вами в пределах 15 футов существа. Существо должно пройти испытание Силы СЛ 14, иначе получит 3к6 урона силовым полем и будет Схвачено Вами. Существо может выбраться из Захвата, действием совершив проверку Силы (Атлетика) или Ловкости (Акробатика) СЛ 14. Захват также заканчивается, если Вы его прекращаете (действий не требуется), если существо находится от Вас далее 15 футов, или если Вы используете эту татуировку на другом существе.`,
  genderId: GENDER_MIDDLE,
  source: {
    id: SOURCE_TCoE,
    page: 177,
  },
}
