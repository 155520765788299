const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_MELEE_SPELL,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_ARCHDEVIL,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {CR_26} = require('./../../../../crList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {
  SPELL_ALTER_SELF,
  SPELL_BLADE_BARRIER,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_FINGER_OF_DEATH,
  SPELL_FIREBALL,
  SPELL_INVISIBILITY,
  SPELL_MAJOR_IMAGE,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GEAR_JAVELIN,
  GEAR_LONGSWORD,
} = require('./../../../../gearIdList')
const {
  archDevilDescriptionList,
  archDevilNoteList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_REGENERATION,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_AMNIZU,
  CREATURE_BEL,
  CREATURE_BONE_DEVIL,
  CREATURE_LEMURE,
  CREATURE_ZARIEL,
} = require('./../../../../creatureIdList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Зариэль',
  nameEn: 'Zariel',
  id: CREATURE_ZARIEL,
  hasSpoilers: true,
  description: [
    `Зариэль правит Аверно, первым слоем Девяти Преисподней. Когда-то великому ангелу было поручено приглядывать за действиями в Войне Крови, но она поддалась извращённому влиянию плана и упала с небес. Она недавно вновь вернула себе свой статус архидьявола Аверно после того, как осмотрительный [Бэл](CREATURE:${CREATURE_BEL}) оказался недостаточно умелым в руководстве военными силами против вторгшихся демонов. Теперь [Бэл](CREATURE:${CREATURE_BEL}) даёт рекомендации и помогает ей руководить войной, хоть и ходят слухи, что её истинное намерение — отомстить Асмодею, а затем изгнать его из Девяти Преисподних.

Все, кто входит и выходит из Девяти Преисподних, должны пройти через Аверно, поэтому на этом слое мобилизуются инфернальные войска. Именно зедсь, в Аверно, [амнизу](CREATURE:${CREATURE_AMNIZU}) сторожат цитадели, возвышающиеся над рекой Стикс, происходят многие битвы Войны Крови, а также собираются дьяволы для вторжения в Бездну. Любой, кто надеется достичь нижних слоёв, должен сперва столкнуться с тьмой этого слоя и со множеством его опасностей. Всем этим промышляет Зариэль, и именно за ней остаётся последнее слово о том — кто сюда войдёт и кто отсюда выйдет.

Получив одну из ключевых ролей в Войне Крови, Зариэль горячо заинтересовалась собиранием душ великих воинов Материального плана и обеспечения их преданности. С ней сложно договориться, а смертных, что имеют с ней дело, ждёт ужасная участь, поскольку все козыри у неё на руках. Сделка с Зариэль вечна и очень мало шансов отвертеться от неё. Тем не менее, она действительно ожидает лучшего от своих прислуг, и поэтому она позволяет своим смертным последователям прожить свою жизнь, постоянно оттачивая свои способности, чтобы она смогла найти им наилучшее применение, когда придёт время возвращать долги. Поэтому слуги Зариэль невероятно эффективны, дисциплинированны и весьма опасны.`,
    {
      header: 'Зариэль',
      text: `Зариэль когда-то была ангелом, но её пылкий нрав и любовь к битвам привела её к падению. В прошлой жизни в её задачи входило следить за битвами на Аверно и отслеживать их исход. Наблюдая за ними, она стала одержима Войной Крови, и в ней выросла такая жажда битвы, что она же не могла её игнорировать. Со временем её разочаровало, что ей и другим ангелам приходится быть пассивными зрителями в битве демонов и дьяволов. Повелители Горы Селестия, думала она, могли бы спуститься на Аверно всей толпой и стереть обе орды зла с лица мультивселенной, если бы пожелали.

После того, как все её многочисленные запросы о присоединении к битве были отклонены, это разочарование взяло над ней верх, и она самостоятельно отправилась на Войну Крови. В компании толпы смертных последователей она прорубилась через легион дьяволов, прежде чем они одолели её числом. Позже Асмодей отправил делегацию [костяных дьяволов](CREATURE:${CREATURE_BONE_DEVIL}) на место, чтобы извлечь её бессознательное тело из-под небольшой горы её убитых врагов. Позволив ей восстановиться в глубинах Нессуса, Асмодей вернул её на Аверно, сделав своим чемпионом и повелителем этого слоя.`,
      source: {
        id: SOURCE_MTOF,
        page: 12,
      },
    },
    {
      header: 'Сражающийся генерал',
      text: `Когда Зариэль заместила исчадие преисподней Бела в роли правителя Аверно, эта перемена ознаменовала значительные перемены в тактике дьяволов. Пламенный темперамент Зариэль и смелые манёвры ярко контрастируют с подходом [Бэла](CREATURE:${CREATURE_BEL}), который полагался на свой опыт в логистике и оборонительные тактики, чтобы добиваться надёжных, но медленных продвижений. [Бэл](CREATURE:${CREATURE_BEL}) остаётся одним из её главных лейтенантов, и он не отговаривает её от применения рискованных тактик в надежде, что она измотает свои силы, что приведёт к её поражению и смещению. Но пока что дьяволы сражаются с яростью берсерков под её командованием. Зариэль лично возглавляет атаки, и её неумолимая решимость вкупе с боевыми навыками её приспешников почти полностью выгнали демонических вторженцев с Аверно.`,
      source: {
        id: SOURCE_MTOF,
        page: 12,
      },
    },
    {
      header: 'Генерал воинов',
      text: `Зариэль – не политик. Она живёт, чтобы сражаться, и она оценивает тех, кого встречает, по их боевым навыкам и желанию использовать эти навыки. Для Зариэль ярость и фанатизм так же важны в бою, как железная дисциплина и длительные тренировки.

Такие взгляды сделали её своего рода политическим изгоем, так как она отвергла все предложения альянсов от других Лордов Девяти и предпочла раздувать свою армию путём агрессивного привлечения в неё смертных душ. Её агенты предлагают дары отваги и боевого мастерства тем, кто захочет торговаться. Зариэль нужны души, ищущие возможностей проявить себя на поле боя.`,
      source: {
        id: SOURCE_MTOF,
        page: 13,
      },
    },
    {
      header: 'Госпожа руин',
      text: `Зариэль правит руинами, которыми стал Аверно. Когда-то это была процветающая земля с городами, торговыми постами и прочими ориентирами, но недавние боевые действия в Войне Крови сделали его изуродованной пустошью. Немногие оставшиеся строения – это цитадели, построенные дьяволами для отражения атак, которые перестраиваются каждый раз, как смещается линия фронта. В настоящий момент дьяволы контролируют Аверно, хотя сражения продолжаются (как и всегда) в изолированных местах на этом слое.

Искатели приключений, у которых получится не привлекать внимание местных обитателей, могут найти в некоторых руинах сокровища, хотя для этого им надо будет отправиться на окраины зоны активных боевых действий, чтобы найти ещё не разграбленные места.

Большинство активных цитаделей нависают над Рекой Стикс или окружают порталы на другие планы или на Дис. В каждом из таких мест руководит [амнизу](CREATURE:${CREATURE_AMNIZU}), командуя появляющимися из Стикса [лемурами](CREATURE:${CREATURE_LEMURE}), направляя войска против нападающих и пресекая неразрешённые путешествия.`,
      source: {
        id: SOURCE_MTOF,
        page: 13,
      },
    },
    {
      header: 'Логово Зариэль',
      text: `Логово Зариэль находится в базальтовой цитадели, что вознесена в Аверно. Почти в миле от цитадели, существо может услышать крики и стоны сожжённых жертв, прикованных к стенам цитадели, погибших из-за того, что разочаровали архидьяволицу. Цитадель, занимающая 5 квадратных миль, окружена стенами с высокими башнями. Дьяволы всех мастей кишат в этой цитадели, защищая крепость от посторонних.
        
# Действия логова

При значении инициативы «20» (проигрыш при ничье), Зариэль совершает действие логова, чтобы вызвать один из следующих эффектов; Зариэль не может использовать один и тот же эффект два раунда подряд:


* Зариэль сотворяет заклинание [Образ](SPELL:${SPELL_MAJOR_IMAGE}) 4 раза, с наименьшим для него уровнем. Зариэль любит создавать иллюзии близких злоумышленника, которые сжигаются заживо. Зариэль не нужно концентрироваться на заклинаниях, которые заканчиваются при значении инициативы «20» следующего раунда. Каждое существо, видящее эти иллюзии, должно пройти испытание Мудрости СЛ 26, иначе будет напугано иллюзией на 1 минуту. Испуганное существо может повторить испытание в конце каждого своего хода, закончив эффект при успехе. 
* Зариэль может сотворять своё врождённое заклинание [Огненный шар](SPELL:${SPELL_FIREBALL}).

# Местные эффекты

Местность, в которой есть логово Зариэль, искажается её магией, которая создаёт один или несколько следующих эффектов:

* Территория в пределах 9 миль от логова наполнена жуткими криками и зловонием от сгоревших трупов. 
* Каждые 60 футов в пределах 1 мили от логова, из земли вспыхивает большое пламя, высотой в 10 футов. Любое существо или объект, прикоснувшийся к пламени, получает урон огнём 7 (2к6), этот урон ему наносится не больше одного раза в раунд. 
* Территория в пределах 2 миль, но не ближе 500 футов от логова, наполнена дымом, сильно снижающий видимость. От дыма невозможно избавиться.

Если Зариэль умирает, эти эффекты исчезают через 1к10 дней.`,
    },
    ...archDevilDescriptionList,
  ],
  note: [
    {
      text: `Не жалейте падшего ангела. Падший ангел переживёт падение. Но сколько же душ потянула за собой Зариэль?`,
      author: `Архимаг Морденкайнен`,
    },
    {
      text: `Продолжайте тратить моё время своими бессмысленными словами и умирайте, либо присоединяйтесь к моему войску и живите. Выбирайте быстро. Мне ещё план надо завоевать`,
      author: `Зариэль, обращаясь к эмиссарам с Диса`,
    },
    ...archDevilNoteList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ARCHDEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 189,
  },
  armor: {
    ac: 21,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 40,
  },
  speed: {
    [SPEED_WALK]: 50,
    [SPEED_FLY]: 150,
  },
  params: {
    [PARAM_STR]: 27,
    [PARAM_DEX]: 24,
    [PARAM_CON]: 28,
    [PARAM_INT]: 26,
    [PARAM_WIT]: 27,
    [PARAM_CHA]: 30,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_RADIANT,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_26,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    {
      name: 'Огненное оружие',
      description: `Атаки оружием Зариэль являются магическими. Когда она ударяет любым оружием, оружие наносит дополнительныо 36 (8к8) огненного урона (уже учтено в атаках).`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    {
      id: ABILITY_REGENERATION,
      regen: 20,
      other: `. Если ★он★ получает урон излучением, эта способность не работает в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только в том случае, если в начале ★его★ хода у ★него★ 0 хитов, и ★он★ не может регенерировать`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_EVIL_AND_GOOD,
          SPELL_FIREBALL,
          SPELL_WALL_OF_FIRE,
          {
            id: SPELL_ALTER_SELF,
            comment: 'может стать Среднего размера',
          },
          {
            id: SPELL_INVISIBILITY,
            comment: 'только на себя',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_BLADE_BARRIER,
          SPELL_DISPEL_EVIL_AND_GOOD,
          SPELL_FINGER_OF_DEATH,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Зариэль атакует дважды _Длинным мечом_ или _Пилумами_. Она может заменить одну из атак на _Ужасное прикосновение_.`,
    },
    {
      name: 'Длинный меч',
      // gearId: GEAR_LONGSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 10,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 8,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 10,
              diceBonus: 8,
              use2Hand: true,
            },
          ],
          {
            type: DAMAGE_FIRE,
            diceCount: 8,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 10,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 8,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 8,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 16,
        range: {
          normal: 30,
          max: 120,
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 8,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 8,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Ужасное прикосновение',
      description: `Цель отравлена на 1 минуту. При отравлении таким образом цель также ослеплена и оглушена. Цель может повторять испытание в конце каждого своего хода, заканчивая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 16,
        range: 10,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 8,
          diceType: 10,
          diceBonus: 0,
        },
      },
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Жертвенный взор',
      cost: 1,
      description: `Зариэль обращает магический взгляд на существо, которое может видеть в пределах 120 фт. от себя и приказывает ему самовоспламениться. Цель должна пройти испытание Мудрости СЛ 26, иначе получит 22 (4к10) огненного урона.`,
    },
    {
      name: 'Телепортация',
      description: `Зариэль использует действие _Телепортация_.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
