const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_BELT_OF_GIANT_STRENGTH} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_CLOUD,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FIRE,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FROST,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_HILL,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STONE,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STORM,
} = require('./../../../../../magicItemIdList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH,
  name: `Пояс силы великана`,
  nameEn: `Belt of Giant Strength`,
  rarity: generateRarityList(list),
  type: MGC_TYPE_WONDROUS_ITEM,
  attunement: true,
  description: `Пока Вы носите этот пояс, значение Вашей Силы изменяется до значения, даруемого этим поясом. Если Ваша Сила без него и так уже равна или превышает силу пояса, тогда пояс не оказывает на Вас никакого эффекта.

Пояс бывает шести разновидностей, каждый со своей редкостью и с привязкой к своему виду великана. [Пояс силы каменного великана](MAGIC_ITEM:${MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STONE}) и [пояс силы ледяного великана](MAGIC_ITEM:${MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FROST}) выглядят по-разному, но оказывают одинаковый эффект. 

| Вид                                                                       | Сила | Редкость     |
|---------------------------------------------------------------------------|------|--------------|
| [Холмовой великан](MAGIC_ITEM:${MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_HILL})  | 21   | Редкий       |
| [Каменный](MAGIC_ITEM:${MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STONE})/[ледяной великан](MAGIC_ITEM:${MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FROST}) | 23   | Очень редкий |
| [Огненный великан](MAGIC_ITEM:${MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FIRE})  | 25   | Очень редкий |
| [Облачный великан](MAGIC_ITEM:${MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_CLOUD}) | 27   | Легендарный  |
| [Штормовой великан](MAGIC_ITEM:${MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STORM})| 29   | Легендарный  |
`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 197,
  },
}
