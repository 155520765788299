const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CONDITION_BLINDED} = require('./../../../../../conditionList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SPELL_WALL_OF_SAND} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_WALL_OF_SAND,
  name: 'Песчаная стена',
  nameEn: 'Wall of Sand',
  description: `Вы создаёте стену из кружащегося песка в видимой точке на горизонтальной поверхности в пределах дистанции заклинания. Точка должна располагаться на поверхности. Стена может быть длиной до 30 футов, высотой до 10 футов и толщиной до 10 футов. Она существует до тех пор, пока действует заклинание. Стена блокирует линию обзора, но не блокирует движение. Находясь внутри стены, существо считается ослеплённым, и тратит 3 фута перемещения за каждый пройдённый фут.`,
  lvl: 3,
  magicSchoolId: MAGIC_EVOCATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `горсть песка`,
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    condition: CONDITION_BLINDED,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 160,
    },
    {
      id: SOURCE_EE,
      page: 20,
    },
  ],
}
