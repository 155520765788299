const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
  TIME_INSTANT,
} = require('./../../../../../timePeriodList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_MOLD_EARTH} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_MOLD_EARTH,
  name: 'Лепка земли',
  nameEn: 'Mold Earth',
  description: `Выберите область земли или камня, видимую в пределах дистанции заклинания и помещающуюся в 5-футовый куб. Вы можете управлять ею одним из нижеперечисленных способов:

* Если Вы нацелились на область рыхлой земли, Вы можете мгновенно извлечь её и переместить на расстояние до 5 футов. Это перемещение не обладает достаточной силой, чтобы причинить урон.
* Вы можете создавать узоры или цвета на поверхности земли или камня для передачи слов, изображений или форм. Эффект действует 1 час.
* Если выбранная область находится на поверхности земли, Вы можете сделать её труднопроходимой. В качестве альтернативы Вы можете сделать труднопроходимую местность нормальной. Эффект действует 1 час.

Если Вы сотворяете это заклинание несколько раз, Вы можете поддерживать не более двух его немгновенных эффектов одновременно. Вы можете действием отменить один из действующих эффектов.`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  componentIdList: [CAST_SOMATIC],
  duration: [TIME_INSTANT, {timeId: TIME_HOUR, count: 1}],
  source: [
    {
      id: SOURCE_XGTE,
      page: 156,
    },
    {
      id: SOURCE_EE,
      page: 18,
    },
  ],
}
