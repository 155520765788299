const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_COLDLIGHT_WALKER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_IDRotF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_RAISE_DEAD} = require('./../../../../spellIdList')

module.exports = {
  name: 'Хладосветный ходок',
  nameAlt: 'Ходок холодного сияния',
  nameEn: 'Coldlight Walker',
  id: CREATURE_COLDLIGHT_WALKER,
  description: [
    `Духи некоторых погибших от жуткого холода гуманоидов увязают в мире смертных и становятся хладосветными ходоками. Они пылают от леденящей ярости от бессмысленности жизни. Их обмороженные трупы светятся призрачным светом, столь ярким, что смертные едва могут на них смотреть. Ходоки обычно носят ту же одежду, в которой умерли.`,
    {
      header: 'Порождённые богами ужасы',
      text: `Боги, представляющие зиму, создают хладосветных ходоков как воплощения её беспощадности. Эти полные ненависти духи не были отпущены в посмертие. Вместо этого их заточили в собственных телах, дабы напоминать смертным о хрупкости их живых оболочек.

Когда хладосветный ходок погибает, его свет гаснет,оставляя лишь мёрзлый неподвижный труп, который уже больше нельзя [оживить](SPELL:${SPELL_RAISE_DEAD}).`,
      source: {
        id: SOURCE_IDRotF,
        page: 285,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_IDRotF,
    page: 285,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  immunityList: [
    DAMAGE_COLD,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 10,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Слепящий свет',
      description: `★СУЩЕСТВО★ испускает яркий свет в пределах 20-футов и тусклый свет на дополнительные 20 футов.

Бонусным действием ★СУЩЕСТВО★ может выбрать целью одно видимое ★им★ существо в области яркого света и заставить его пройти испытание Телосложения СЛ 14 или Ослепнуть до начала следующего хода ★СУЩЕСТВО★.`,
    },
    {
      name: 'Леденящий рок',
      description: `Любое убитое ★СУЩЕСТВО★ существо замерзает на 9 дней, в течение которых его нельзя отогреть, повредить огнём, оживить или поднять как мертвеца.`,
    },
    {
      name: 'Необычная природа',
      description: `★СУЩЕСТВО★ не нужны воздух, еда, питьё или сон.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 2,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 4,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Хладный луч',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 3,
        range: 60,
        hit: {
          type: DAMAGE_COLD,
          diceCount: 4,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
  ],
}
