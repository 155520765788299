const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_MONSTER_SLAYER} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  SPELL_BANISHMENT,
  SPELL_HOLD_MONSTER,
  SPELL_MAGIC_CIRCLE,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_ZONE_OF_TRUTH,
} = require('./../../../spellIdList')
const {
  FEATURE_HUNTER_S_SENSE,
  FEATURE_MAGIC_USER_S_NEMESIS,
  FEATURE_MONSTER_SLAYER_SPELLS,
  FEATURE_SLAYER_S_COUNTER,
  FEATURE_SLAYER_S_PREY,
  FEATURE_SUPERNATURAL_DEFENSE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_MONSTER_SLAYER_SPELLS,
    name: `Заклинания Убийцы монстров`,
    nameEn: `Monster Slayer Spells`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигнете определённых уровней в этом классе.

Эти заклинания считается для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_BANISHMENT,
      SPELL_HOLD_MONSTER,
      SPELL_MAGIC_CIRCLE,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_ZONE_OF_TRUTH,
    ],
  },
  {
    id: FEATURE_HUNTER_S_SENSE,
    name: `Охотничьи чувства`,
    nameEn: `Hunter’s Sense`,
    lvl: 3,
    text: `Вы получаете способность оценить существо и определить, как лучше всего навредить ему. Действием выберите одно видимое Вами в пределах 60 футов существо. Вы сразу узнаёте, имеет ли оно какие-либо иммунитеты, сопротивления или уязвимости, и какие они. Если существо скрыто от магии прорицания, то Вы чувствуете, что у него нет иммунитетов, сопротивлений или уязвимостей.

Вы можете использовать это умение количество раз, равное модификатору Мудрости (минимум один раз). Потраченные использования этого умения восстанавливаются после короткого или длинного отдыха.`,
  },
  {
    id: FEATURE_SLAYER_S_PREY,
    name: `Добыча убийцы`,
    nameEn: `Slayer’s Prey`,
    lvl: 3,
    text: `Вы можете сфокусировать свой гнев на одном враге, увеличивая наносимый ему Вами урон.

Вы бонусным действием выбираете целью этой способности одно видимое Вами в пределах 60 футов существо. Первый раз на каждом ходу, когда Вы попадаете по ней атакой оружием, она получает дополнительно 1к6 урон от этого оружия.

Это преимущество действует до завершения короткого или длинного отдыха. Оно заканчивается раньше, если Вы выбираете другое существо.`,
  },
  {
    id: FEATURE_SUPERNATURAL_DEFENSE,
    name: `Сверхъестественная защита`,
    nameEn: `Supernatural Defense`,
    lvl: 7,
    text: `Вы получаете дополнительную устойчивость против нападений Вашей добычи на Ваш ум и тело.

Всякий раз, когда цель [Добычи убийцы](FEATURE:${FEATURE_SLAYER_S_PREY}) заставляет Вас пройти испытание или когда Вы делаете проверку характеристики для побега из захвата этой цели — добавьте к броску 1к6.`,
  },
  {
    id: FEATURE_MAGIC_USER_S_NEMESIS,
    name: `Враг заклинателя`,
    nameEn: `Magic-User’s Nemesis`,
    lvl: 11,
    text: `Вы получаете способность разрушать чужую магию.

Когда Вы видите существо, накладывающее заклинание или телепортирующееся в пределах 60 футов от Вас, Вы можете реакцией попытаться ей магически помешать.

Существо должно пройти испытание Мудрости против СЛ Ваших заклинаний, иначе его заклинание или телепортация проваливается.

Использовав эту способность, Вы не можете использовать его снова до завершения короткого или длинного отдыха.`,
  },
  {
    id: FEATURE_SLAYER_S_COUNTER,
    name: `Контратака убийцы`,
    nameEn: `Slayer’s Counter`,
    lvl: 15,
    text: `Вы получаете возможность контратаковать, когда Ваша жертва пытается Вам навредить.

Если цель [Добычи убийцы](FEATURE:${FEATURE_SLAYER_S_PREY}) заставляет Вас пройти испытание, то Вы можете реакцией совершить по ней одну атаку оружием. Вы совершаете эту атаку прямо перед прохождением испытания. Если атака попадает, то, кроме к обычных эффектов атаки, Вы автоматически преуспеваете в том испытании.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_RANGER_MONSTER_SLAYER,
    source: {
      id: SOURCE_XGTE,
      page: 57,
    },
  })
)

