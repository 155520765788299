const {
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_FIRE,
  } = require('./../../../../creatureTypeIdList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_FIRE,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    CREATURE_AIR_ELEMENTAL_SPIRIT,
    CREATURE_FIRE_ELEMENTAL_SPIRIT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух элементаля огня',
  nameEn: 'Fire Elemental Spirit',
  id: CREATURE_FIRE_ELEMENTAL_SPIRIT,
  parentId: CREATURE_AIR_ELEMENTAL_SPIRIT,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_FIRE,
  ],
  speed: {
    [SPEED_WALK]: 40,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_FIRE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 5,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 1,
          diceType: 10,
          diceBonus: 4,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
}
