const {
  GENDER_MALE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  VOLUME_UNIT_GALLON,
} = require('./../../../volumeUnitList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  CAT_ARTISAN_TOOLS,
  CAT_CONTAINERS,
} = require('./../../../gearCategoryList')
const {
  GEAR_JEWELERS_TOOLS,
  GEAR_JUG,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_JUG,
    genderId: GENDER_MALE,
    name: 'Кувшин',
    nameByCase: {
      nominative: 'кувшин',
      genitive: 'кувшина',
      accusative: 'кувшин',
      instrumental: 'кувшином',
    },
    nameEn: 'Jug',
    cost: 2,
    weight: 4,
    volume: {
      unitId: VOLUME_UNIT_GALLON,
      count: 1,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_JEWELERS_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты ювелира',
    nameByCase: {
      nominative: 'инструменты ювелира',
      genitive: 'инструментов ювелира',
      accusative: 'инструменты ювелира',
      instrumental: 'инструментами ювелира',
    },
    nameEn: 'Jeweler’s Tools',
    description: `Владение инструментами ювелира включает в себя знание основных способов работы с драгоценностями. Также оно позволяет опознавать драгоценные камни.

**Компоненты.** Инструменты ювелира включают в себя

* небольшую пилу
* молоточек,
* напильники,
* щипцы,
* пинцет.

**Магия.** Владение инструментами ювелира даёт вам знания об известных способах магического применения драгоценных камней. Эти знания будут кстати при проверках Магии, связанных с драгоценными камнями или инкрустированными ими предметами.

**Расследование.** При осмотре драгоценностей владение инструментами ювелира помогает вам находить зацепки, которые другие бы просмотрели.

**Опознание драгоценности.** Вы можете опознать драгоценность и на глаз определить её стоимость.

| Действие                           | СЛ |
|------------------------------------|----|
| Изменить внешний вид драгоценности | 15 |
| Определить прошлое драгоценности   | 20 |
`,
    cost: 2500,
    weight: 2,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 84,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
]

module.exports = gearRawList
