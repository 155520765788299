const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_THRI_KREEN,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_THRI_KREEN,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Три-кринский',
    genitive: 'Три-кринского',
    instrumental: 'Три-кринским',
    prepositional: 'Три-кринском',
  },
  nameEn: 'Thri-Kreen',
  isReady: false,
  isRealLang: true,
}
