const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ARCHON} = require('./../../creatureTypeIdList')
const {
  archonDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_ARCHON,
  nameEn: 'Archon',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  notRealRace: true,
  name: {
    singular: {
      nominative: 'архонт',
      genitive: 'архонта',
      dative: 'архонту',
      accusative: 'архонта',
      instrumental: 'архонтом',
      prepositional: 'архонте',
    },
    plural: {
      nominative: 'архонты',
      genitive: 'архонтов',
      dative: 'архонтам',
      accusative: 'архонтов',
      instrumental: 'архонтами',
      prepositional: 'архонтах',
    },
  },
  description: archonDescriptionList,
}
