const {
  SPELL_ASTRAL_PROJECTION,
  SPELL_BIGBY_S_HAND,
  SPELL_JUMP,
  SPELL_LEVITATE,
  SPELL_MAGE_HAND,
  SPELL_MASS_SUGGESTION,
  SPELL_MISTY_STEP,
  SPELL_NONDETECTION,
  SPELL_PLANE_SHIFT,
  SPELL_TELEKINESIS,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_DRAGON_RED_ADULT,
  CREATURE_GITHYANKI_KITHRAK,
  CREATURE_GITHYANKI_SUPREME_COMMANDER,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_GITH,
  CREATURE_TYPE_GITHYANKI,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  githyankiDescription,
  githyankiNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_14} = require('./../../../../crList')
const {GEAR_PLATE_ARMOR} = require('./../../../../gearIdList')
const {LANG_GITH} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гитьянки верховный главнокомандующий',
  nameEn: 'Githyanki Supreme Commander',
  id: CREATURE_GITHYANKI_SUPREME_COMMANDER,
  description: [
    `Верховные главнокомандующие ведут армии гитьянки, каждый приказывает десятерым [кит’ракам](CREATURE:${CREATURE_GITHYANKI_KITHRAK}), которые, в свою очередь, ведут остальные боевые силы. Многие верховные главнокомандующие летят по полю боя верхом на [красных драконах](CREATURE:${CREATURE_DRAGON_RED_ADULT}).`,
    ...githyankiDescription,
  ],
  note: githyankiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHYANKI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 126,
    },
    {
      url: `https://5e.tools/bestiary.html#githyanki%20supreme%20commander_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Верховного главнокомандующего гитьянки» сильно завышены статы. Например, у  него совершенно безумные для гуманоида 27 интеллекта.`,
    },
  ],
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 22,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 18,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_GITH,
  ],
  cr: CR_14,
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_MAGE_HAND,
            comment: 'рука невидима',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          {
            id: SPELL_LEVITATE,
            comment: 'только на себя',
          },
          {
            id: SPELL_NONDETECTION,
            comment: 'только на себя',
          },
          SPELL_JUMP,
          SPELL_MISTY_STEP,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_BIGBY_S_HAND,
          SPELL_MASS_SUGGESTION,
          SPELL_PLANE_SHIFT,
          SPELL_TELEKINESIS,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Двуручным мечом_.`,
    },
    {
      name: 'Серебряный двуручный меч',
      description: `При критическом попадании по цели, находящейся в астральном теле (как при использовании заклинания [Проекция в астрал](SPELL:${SPELL_ASTRAL_PROJECTION})), ★СУЩЕСТВО★ может перерезать серебряную нить, соединяющую цель с материальным телом, вместо причинения урона.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 7,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 5,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака',
      cost: 2,
      description: `★СУЩЕСТВО★ совершает атаку двуручным мечом.`,
    },
    {
      name: 'Приказ союзнику',
      description: `★СУЩЕСТВО★ выбирает одного союзника в пределах 30 футов. Если цель видит или слышит ★СУЩЕСТВО★, она может совершить одну рукопашную атаку оружием, используя свою реакцию и получая преимущество на бросок атаки.`,
    },
    {
      name: 'Телепортация',
      description: `★СУЩЕСТВО★ вместе со всем, что ★он★ несёт и носит, магически телепортируется в свободное пространство в пределах 30 футов и становится неосязаемым до начала своего следующего хода.

Будучи неосязаемым, ★он★ может проходить через других существ и объекты, как если бы они были труднопроходимой местностью. Если ★СУЩЕСТВО★ заканчивает ход в объекте, ★он★ получает 16 (3к10) урона силовым полем и выталкивается в ближайшее свободное пространство.`,
    },
  ],
}
