const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {LANG_AARAKOCRA} = require('./../../languageIdList')

module.exports = {
  id: LANG_AARAKOCRA,
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Язык ааракокр',
    genitive: 'Языка ааракокр',
    instrumental: 'Языком ааракокр',
    prepositional: 'Языке ааракокр',
  },
  typicalSpeakers: 'Ааракокры',
  isRealLang: true,
  isReady: false,
}
