const listToCollectionById = require('./../utils/listToCollectionById')
const {
  LANG_FAMILY_CHULTAN,
  LANG_FAMILY_FAERUNIAN,
  LANG_FAMILY_IMASKARI,
  LANG_FAMILY_RAURIC,
} = require('./languageFamilyList')

const LANG_GROUP_CHESSAN = 'chessan'
const LANG_GROUP_CHULTAN = 'chultan'
const LANG_GROUP_ILLUSKI = 'illuski'
const LANG_GROUP_IMASKARI = 'imaskari'
const LANG_GROUP_MULANI = 'mulani'
const LANG_GROUP_RAUMTHERAN = 'raumtheran'
const LANG_GROUP_THORASS = 'thorass'
const LANG_GROUP_ULOU = 'ulou'
const LANG_GROUP_UNTHERIC = 'untheric'
const LANG_GROUP_WAELAN = 'waelan'

const languageGroupList = [
  {
    id: LANG_GROUP_CHESSAN,
    langFamilyId: LANG_FAMILY_FAERUNIAN,
    name: 'Чессанская',
    nameEn: 'Chessan',
    similarRealLanguages: `греческие языки: эолийский диалект, кипрский диалект, современный греческий`,
  },
  {
    id: LANG_GROUP_CHULTAN,
    langFamilyId: LANG_FAMILY_CHULTAN,
    name: 'Чультаская',
    nameEn: 'Chultan',
    similarRealLanguages: `нигерские языки: акан, банда, зулу`,
  },
  {
    id: LANG_GROUP_ILLUSKI,
    langFamilyId: LANG_FAMILY_FAERUNIAN,
    name: 'Иллюсканская',
    nameEn: 'Illuski',
    similarRealLanguages: `германские языки: древнескандинавский, датский, норвежский`,
  },
  {
    id: LANG_GROUP_IMASKARI,
    langFamilyId: LANG_FAMILY_IMASKARI,
    name: 'Имаскарийская',
    nameEn: 'Imaskari',
    similarRealLanguages: `алтайские языки: азербайджанский, турецкий, монгольский`,
  },
  {
    id: LANG_GROUP_MULANI,
    langFamilyId: LANG_FAMILY_RAURIC,
    name: 'Муланская',
    nameEn: 'Mulani',
    similarRealLanguages: `хамитские языки: древнеегипетский, коптский, берберский`,
  },
  {
    id: LANG_GROUP_RAUMTHERAN,
    langFamilyId: LANG_FAMILY_FAERUNIAN,
    name: 'Ромтеранская',
    nameEn: 'Raumtheran',
    similarRealLanguages: `славянские языки: русский, болгарский, албанский`,
  },
  {
    id: LANG_GROUP_THORASS,
    langFamilyId: LANG_FAMILY_FAERUNIAN,
    name: 'Торасская',
    nameEn: 'Thorass',
    similarRealLanguages: `романские языки: латынь, итальянский, французский`,
  },
  {
    id: LANG_GROUP_ULOU,
    langFamilyId: LANG_FAMILY_FAERUNIAN,
    name: 'Улу',
    nameEn: 'Ulou',
    similarRealLanguages: `балтийские языки: прусский, латышский, литовский`,
  },
  {
    id: LANG_GROUP_UNTHERIC,
    langFamilyId: LANG_FAMILY_RAURIC,
    name: 'Унтерская',
    nameEn: 'Untheric',
    similarRealLanguages: `семитские языки: ассирийский, вавилонский, современный арабский`,
  },
  {
    id: LANG_GROUP_WAELAN,
    langFamilyId: LANG_FAMILY_FAERUNIAN,
    name: 'Ваэланская',
    nameEn: 'Waelan',
    similarRealLanguages: `кельтские языки: корнский, мэнский, валлийский `,
  },
]

module.exports = languageGroupList

module.exports.languageGroupCollection = listToCollectionById(languageGroupList)

module.exports.LANG_GROUP_CHESSAN = LANG_GROUP_CHESSAN
module.exports.LANG_GROUP_CHULTAN = LANG_GROUP_CHULTAN
module.exports.LANG_GROUP_ILLUSKI = LANG_GROUP_ILLUSKI
module.exports.LANG_GROUP_IMASKARI = LANG_GROUP_IMASKARI
module.exports.LANG_GROUP_MULANI = LANG_GROUP_MULANI
module.exports.LANG_GROUP_RAUMTHERAN = LANG_GROUP_RAUMTHERAN
module.exports.LANG_GROUP_THORASS = LANG_GROUP_THORASS
module.exports.LANG_GROUP_ULOU = LANG_GROUP_ULOU
module.exports.LANG_GROUP_UNTHERIC = LANG_GROUP_UNTHERIC
module.exports.LANG_GROUP_WAELAN = LANG_GROUP_WAELAN
