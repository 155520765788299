const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ARMOR_OF_RESISTANCE} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_HEAVY_ARMOR,
} = require('./../../../../../gearCategoryList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_ARMOR_OF_RESISTANCE,
  name: `Доспех сопротивления`,
  nameEn: `Armor of Resistance`,
  type: MGC_TYPE_ARMOR,
  gearCategory: [
    CAT_LIGHT_ARMOR,
    CAT_MEDIUM_ARMOR,
    CAT_HEAVY_ARMOR,
  ],
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Вы получаете сопротивление к одному из видов урона, пока носите этот доспех. Мастер выбирает вид урона сам или случайным образом, из представленных ниже вариантов.

| к10 | Вид урона             | к10 | Вид урона           |
|-----|-----------------------|-----|---------------------|
| 1   | Звук                  |  6  | Психическая энергия |
| 2   | Излучение             |  7  | Силовое поле        |
| 3   | Кислота               |  8  | Холод               |
| 4   | Некротическая энергия |  9  | Электричество       |
| 5   | Огонь                 | 10  | Яд                  |
`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 160,
  },
}
