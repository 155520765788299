const {
  CREATURE_GHAST,
  CREATURE_GHOUL,
  CREATURE_MUMMY,
  CREATURE_WIGHT,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_NECROMANCY,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_CREATE_UNDEAD,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_CREATE_UNDEAD,
  name: 'Сотворение нежити',
  nameEn: `Create Undead`,
  description: `Вы можете сотворять это заклинание только ночью. Выберите до трёх трупов гуманоидов Среднего или Маленького размера в пределах дистанции. Все трупы становятся [упырями](CREATURE:${CREATURE_GHOUL}) под Вашим контролем (параметры этих существ есть у Мастера).

В каждом своём ходу Вы можете бонусным действием мысленно приказать любому существу, оживлённому этим заклинанием, если оно находится в пределах 120 футов от Вас (если Вы контролируете сразу несколько существ, можете отдать одну и ту же команду сразу нескольким из них). Вы решаете, какое действие это существо совершит, и куда оно переместится в свой следующий ход, или же Вы можете отдать общий приказ, например, охранять конкретную комнату или коридор. Если Вы не отдадите команду, существо будет всего лишь защищаться от врагов. Получив приказ, существо продолжает его выполнять, пока задача не будет выполнена.

Существо находится под Вашим контролем 24 часа, после чего перестаёт слушаться команд. Для поддержания контроля ещё на 24 часа Вы должны активировать это заклинание на него ещё раз до окончания 24-часового периода. Такое использование заклинания только поддерживает контроль над уже созданными существами, количество которых не может быть больше трёх, и не оживляет новых.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 уровня, Вы оживляете или продлеваете контроль над четырьмя [упырями](CREATURE:${CREATURE_GHOUL}). Если Вы накладываете это заклинание, используя ячейку 8 уровня, Вы оживляете или продлеваете контроль над пятью упырями или двумя [вурдалаками](CREATURE:${CREATURE_GHAST}) или [умертвиями](CREATURE:${CREATURE_WIGHT}). Если Вы накладываете это заклинание, используя ячейку 9 уровня, Вы оживляете или продлеваете контроль над шестью упырями, тремя вурдалаками или умертвиями, или двумя [мумиями](CREATURE:${CREATURE_MUMMY}).`,
  lvl: 6,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 10,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `глиняный горшок с могильной землёй, глиняный горшок с солоноватой водой и по одному чёрному ониксу стоимостью 150 зм на каждый труп`,
  note: {
    text: `«Вы можете сотворять это заклинание только ночью». А если ты в Подземье, откуда тебе знать — ночь ли сейчас? А что делать заклинателям в демипланах? А в них вообще бывает ночь?..`,
    author: `Эспумизан Коликит, гоблин волшебник`,
  },
  castTime: { timeId: TIME_MINUTE, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 280,
    },
    {
      id: SOURCE_SRD,
      page: 132,
    },
  ],
}
