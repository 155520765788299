const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_STINKING_CLOUD} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')

module.exports = {
  id: SPELL_STINKING_CLOUD,
  name: 'Зловонное облако',
  nameEn: `Stinking Cloud`,
  description: `Вы создаёте сферу с радиусом 20 футов жёлтого тошнотворного газа с центром на точке в пределах дистанции. Это облако обходит углы, и его пространство — сильно заслонённая местность. Облако держится в воздухе, пока активно заклинание.

Все существа, находящиеся полностью в облаке в начале своего хода, должны пройти испытание Телосложения от яда. При провале существо теряет своё действие, так как его тошнит и его голова кружится. Существа, которые не обязаны дышать, а также обладающие иммунитетом к ядам, автоматически преуспевают в этом испытании.

Умеренный ветер (как минимум 10 миль в час) рассеивает облако через 4 раунда. Сильный ветер (как минимум 20 миль в час) рассеивает его через 1 раунд.`,
  lvl: 3,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `тухлое яйцо или несколько гнилых листьев капусты`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CON,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 229,
    },
    {
      id: SOURCE_SRD,
      page: 182,
    },
  ],
}
