const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  CREATURE_BARLGURA,
  CREATURE_SHADOW_DEMON,
} = require('./../../../../../creatureIdList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_CHA} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_SUMMON_GREATER_DEMON} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_SUMMON_GREATER_DEMON,
  name: 'Призыв высшего демона',
  nameEn: 'Summon Greater Demon',
  description: `Вы произносите инфернальные слова, призывая одного демона из хаоса Бездны. Вы выбираете тип демона, показатель опасности которого равен 5 или ниже, например, [теневой демон](CREATURE:${CREATURE_SHADOW_DEMON}) или [барлгура](CREATURE:${CREATURE_BARLGURA}). Демон появляется в незанятом пространстве, которое Вы можете видеть в пределах дистанции. Демон исчезает, когда его хиты опускаются до 0 или когда заклинание заканчивается.

Совершите проверку инициативы за демона, который будет совершать свои собственные ходы. Когда Вы призываете его и в каждый свой ход после этого, Вы можете дать вербальную команду (действий не требуется), сообщая ему, что он должен делать в свой следующий ход. Если Вы не даёте никаких команд, демон тратит свой ход, атакуя любое существо в пределах досягаемости, которое атаковало его.

В конце каждого своего хода демон проходит испытание Харизмы. Он получает помеху в этом испытании, если Вы назовёте его истинное имя. В случае провала демон продолжает подчиняться вам.

При успехе, демон освобождаете из-под Вашего контроля на всё оставшееся время и тратит свои последующие ходы, преследуя и атакуя ближайших не-демонов в меру своих способностей. Если Вы перестанете держать концентрацию на заклинании до того, как оно достигнет своей полной продолжительности, неконтролируемый демон не исчезнет в течение 1к6 раундов, если его хиты ещё не равны 0.

Как часть колдовства Вы можете начертить на земле круг кровью, используемой в качестве материального компонента. Круг должен быть достаточного размера, чтобы вместить вас. Пока заклинание длится, призванный демон не может пересечь круг или разрушить его, и он не может атаковать никого в нём. Если материальный компонент использован подобным образом, он расходуется при окончании заклинания.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку заклинания 5 круга или выше, показатель опасности увеличивается на 1 за каждый круг ячейки выше 4.`,
  lvl: 4,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `сосуд с кровью гуманоида, убитого за последние 24 часа`,
  note: {
    text: `Откуда в моём мешочке с компонентами появляется кровь гуманоида, убитого за последние 24 часа, если я никого не убивал? Я что-то не знаю о жизни своего мешочка с компонентами?`,
    author: `Эспумизан Коликит, гоблин волшебник`,
  },
  duration: {timeId: TIME_HOUR, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CHA,
  },
  source: {
    id: SOURCE_XGTE,
    page: 163,
  },
}
