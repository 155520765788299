const { spellCollection } = require('./../../../../../spellList')

module.exports = (spellIdList, showAsList) => spellIdList
  .map(
    item => {
      const { id, comment } = typeof item === 'string'
        ? { id: item, comment: '' }
        : item

      const commentText = comment
        ? ` (${comment})`
        : ``

      const linkText = `[${spellCollection[id].name}](SPELL:${id})${commentText}`

      return showAsList
        ? `* ${linkText}`
        : linkText
    },
  )
  .sort()
  .join(
    showAsList
      ? '\n'
      : ', '
  )
