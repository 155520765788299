const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_UA_2017_04_03_STARTER_SPELLS,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  GEAR_FLASK,
  GEAR_HOLY_WATER,
} = require('./../../../../../gearIdList')
const {
  SPELL_CEREMONY,
  SPELL_WISH,
} = require('./../../../../../spellIdList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_CEREMONY,
  name: 'Церемония',
  nameEn: 'Ceremony',
  description: `Вы совершаете особую религиозную церемонию, наполненную магией. Когда Вы произносите заклинание, выберите один из следующих обрядов, цель которого должна находиться в пределах 10 футов от Вас во время накладывания.

# Искупление

Вы касаетесь одного согласного существа, чьё мировоззрение было изменено, и совершаете проверку Мудрости (Проницательность) против СЛ 20. При успехе Вы возвращаете цели её изначальное мировоззрение.

# Благословение Воды

Вы прикасаетесь к одной [фляге](GEAR:${GEAR_FLASK}) воды и делаете ее [Святой Водой](GEAR:${GEAR_HOLY_WATER}).

# Становление

Вы касаетесь одного взрослого гуманоида, который достиг совершеннолетия. На следующие 24 часа, каждый раз, когда цель совершает проверку характеристики, она может бросить к4 и добавить выпавшее число к результату. Существо может получить преимущества от этого заклинания только единожды.

# Посвящение

Вы касаетесь одного гуманоида, который хочет посвятить себя служению Вашему богу. На следующие 24 часа, каждый раз, когда цель проходит испытание, она может бросить к4 и добавить выпавшее число к результату. Существо может получить преимущества от этого заклинания только единожды.

# Заупокойный обряд

Вы касаетесь одного трупа, и в течение следующих 7 дней цель не может стать нежитью, кроме как с помощью заклинания [исполнения желаний](SPELL:${SPELL_WISH}).

# Венчание

Вы касаетесь взрослых гуманоидов, желающих связать себя узами брака. В течение следующих 7 дней каждая цель получает бонус +2 к КД пока они находятся в пределах 30 футов друг от друга. Существо может получить преимущества от этого заклинания снова, только если оно овдовело.`,
  lvl: 1,
  magicSchoolId: MAGIC_ABJURATION,
  range: 0,
  isRitual: true,
  hasCost: true,
  hasConsumable: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `порошок серебра стоимостью 25 зм, расходуемый заклинанием`,
  castTime: {timeId: TIME_HOUR, count: 1},
  source: [
    {
      id: SOURCE_XGTE,
      page: 171,
    },
    {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 2,
    },
  ],
}
