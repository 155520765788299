const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  GEAR_PLATE_ARMOR,
  GEAR_SHIELD,
} = require('./../../../../../gearIdList')
const {
  SPELL_IDENTIFY,
  SPELL_PLANE_SHIFT,
  SPELL_REMOVE_CURSE,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_ARMOR,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_AMULET_OF_HEALTH,
  MAGIC_ITEM_AMULET_OF_PROOF_AGAINST_DETECTION_AND_LOCATION,
  MAGIC_ITEM_AMULET_OF_THE_PLANES,
  MAGIC_ITEM_ANIMATED_SHIELD,
  MAGIC_ITEM_APPARATUS_OF_KWALISH,
  MAGIC_ITEM_ARMOR_OF_INVULNERABILITY,
  MAGIC_ITEM_ARMOR_OF_VULNERABILITY,
  MAGIC_ITEM_ARROW_CATCHING_SHIELD,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_ARROW_CATCHING_SHIELD,
    name: `Ловящий стрелы щит`,
    nameEn: `Arrow-Catching Shield`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_SHIELD,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Вы получаете бонус +2 к КД от дальнобойных атак, пока носите этот щит. Этот бонус идёт в дополнение к обычному бонусу щита к КД.

Кроме того, каждый раз, когда кто-нибудь совершает дальнобойную атаку по цели, находящейся в пределах 5 футов от Вас, Вы можете реакцией вместо этого сделать целью атаки себя. `,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 180,
    },
  },
  {
    id: MAGIC_ITEM_ANIMATED_SHIELD,
    name: `Живой щит`,
    nameEn: `Animated Shield`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_SHIELD,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Если Вы держите этот щит, Вы можете бонусным действием произнести командное слово, чтобы он ожил. Щит поднимается в воздух и парит в Вашем пространстве, защищая так, как если бы Вы его использовали, но Ваши руки при этом свободны.

Щит остаётся живым 1 минуту, пока Вы не окончите эффект бонусным действием или пока Вы не станете недееспособным или не умрёте, после чего щит падает на землю или в Вашу руку, если она свободна.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 163,
    },
  },
  {
    id: MAGIC_ITEM_ARMOR_OF_VULNERABILITY,
    name: `Доспех уязвимости`,
    nameEn: `Armor of Vulnerability`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_PLATE_ARMOR,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Когда Вы надеваете этот доспех, Вы получаете сопротивление к одному из следующих видов урона: дробящий, колющий или рубящий. Мастер определяет вид урона самостоятельно или случайным образом.`,
    curse: `Этот доспех проклят, но это становится понятно только когда на него используется заклинание [Опознание](SPELL:${SPELL_IDENTIFY}) или когда Вы настраиваетесь на него. Настройка на доспех проклинает Вас до тех пор, пока Вы не станете целью заклинания [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) или похожей магии. Снятие доспеха не прекращает проклятье.

Пока Вы прокляты, Вы получаете уязвимость к двум оставшимся видам урона, исключая тот, к которому доспех даёт сопротивление.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 160,
    },
  },
  {
    id: MAGIC_ITEM_ARMOR_OF_INVULNERABILITY,
    name: `Доспех неуязвимости`,
    nameEn: `Armor of Invulnerability`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_PLATE_ARMOR,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Вы получаете сопротивление к немагическому урону, пока носите этот доспех. Кроме того, Вы можете действием получить иммунитет к немагическому урону на 10 минут или пока Вы не снимете этот доспех. Вы не можете повторно использовать это особое действие до следующего рассвета. `,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 160,
    },
  },
  {
    id: MAGIC_ITEM_AMULET_OF_PROOF_AGAINST_DETECTION_AND_LOCATION,
    name: `Амулет защиты от обнаружения и поиска`,
    nameEn: `Amulet of Proof against Detection and Location`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите этот амулет, Вы скрыты от магии школы Прорицания. Вы не можете быть целью подобной магии, и Вас не воспринимают магические сенсоры слежения.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 151,
    },
  },
  {
    id: MAGIC_ITEM_AMULET_OF_HEALTH,
    name: `Амулет здоровья`,
    nameEn: `Amulet of Health`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Пока Вы носите этот амулет, Ваше значение Телосложения равно 19. Если Ваше Телосложение без него уже 19 или выше, то _Амулет_ не оказывает на Вас никакого действия.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 151,
    },
  },
  {
    id: MAGIC_ITEM_AMULET_OF_THE_PLANES,
    name: `Амулет планов`,
    nameEn: `Amulet of the Planes`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Пока Вы носите этот амулет, Вы можете действием назвать хорошо знакомое Вам место на другом плане. После этого необходимо совершить проверку Интеллекта СЛ 15.

# Успех
Вы накладываете заклинание [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}).

# Провал

Вы, все существа и предметы в пределах 15 футов от Вас переноситесь в случайном направлении.

**Бросьте к100**

| Бросок | Результат                                                         |
|--------|-------------------------------------------------------------------| 
| 1–60   | Вы переноситесь в случайное место на названном Вами плане.        |
| 61–100 | Вы переноситесь в случайное место на текущем плане существования. |
`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_PLANE_SHIFT],
    source: {
      id: SOURCE_DMG,
      page: 151,
    },
  },
  {
    id: MAGIC_ITEM_APPARATUS_OF_KWALISH,
    name: `Аппарат Квалиша`,
    nameEn: `Apparatus of Kwalish`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_LEGENDARY,
    description: `На первый взгляд этот предмет выглядит как Большая запечатанная железная бочка, весящая 500 фунтов.

У бочки есть потайной затвор, который можно найти успешной проверкой Интеллекта (Анализ) СЛ 20. Этот затвор отпирает люк на одном конце бочки, позволяющий забраться внутрь двум существам Среднего или меньшего размера.

У дальнего конца торчат в ряд десять рычагов, все в нейтральной позиции, но их можно перевести или в верхнее или в нижнее положение. Если использовать определённые рычаги, аппарат трансформируется и станет напоминать гигантского лобстера.

# Рычаги аппарата Квалиша


| Рычаг | Вверх| Вниз |
|-------|------|------| 
| 1     | Ноги и хвост выдвигаются, позволяя аппарату ходить и плавать. | Ноги и хвост втягиваются, уменьшая скорость аппарата до 0 и лишая его способности получать преимущества от бонусов к скорости. |
| 2     | Заслонка переднего иллюминатора открывается. | Заслонка переднего иллюминатора закрывается. |
| 3     | Заслонки боковых иллюминаторов (по два на каждом борту) открываются. | Заслонки боковых иллюминаторов (по два на каждом борту) закрываются. |
| 4     | Из передней части аппарата выдвигаются два когтя.  | Когти убираются. |
| 5     | Каждый выдвинутый коготь совершает рукопашную атаку оружием: +8 к попаданию, досягаемость 5 футов, одна цель. Попадание: Дробящий урон 7 (2к6). | Каждый выдвинутый коготь совершает рукопашную атаку оружием: +8 к попаданию, досягаемость 5 футов, одна цель. Попадание: цель становится схваченной (СЛ высвобождения 15).  |
| 6     | Аппарат идёт или плывёт вперёд. | Аппарат идёт или плывёт назад.  |
| 7     | Аппарат поворачивает на 90 градусов налево.  | Аппарат поворачивает на 90 градусов направо.  |
| 8     | Похожие на глаза приспособления испускают яркий свет в пределах 30-футового радиуса и тусклый свет в пределах ещё 30 футов. | Свет тухнет. |
| 9     | Аппарат погружается в жидкость на глубину до 20 футов. | Аппарат всплывает в жидкости не более чем на 20 футов. |
| 10    | Задний люк отпирается и открывается.  | Задний люк закрывается и запирается. |

# Параметры

| Параметр          | Значение                                                               |
|-------------------|------------------------------------------------------------------------| 
| Размер            | Большой                                                                | 
| КД                | 20                                                                     | 
| Хиты              | 200                                                                    | 
| Скорость          | 30 футов, плавая 30 футов (или 0 футов если не выдвинуты ноги и хвост) | 
| Иммунитет к урону | психическая энергия, яд                                                |

Для того чтобы использовать аппарат в качестве транспортного средства, требуется один водитель. Пока люк заперт, аппарат водо- и воздухонепроницаем. Воздуха внутри хватит одному дышащему существу на 10 часов.

Аппарат держится на плаву. Он может погружаться на глубину до 900 футов. Глубже этой отметки аппарат получает дробящий урон 2к6 в минуту от давления.

Существо в аппарате может действием переместить от одного до двух рычагов вверх или вниз. После каждого использования рычаги сами возвращаются в нейтральное положение. Назначение десяти рычагов показано в вышеприведённой таблице. `,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 151,
    },
  },
]
