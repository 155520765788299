const {
  CREATURE_TYPE_DUERGAR,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_JAVELIN,
  GEAR_SHIELD,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DUERGAR_RESILIENCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_DUERGAR_STONE_GUARD} = require('./../../../../creatureIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Двергар каменный страж',
  nameAlt: 'Дуэргар каменный страж',
  nameEn: 'Duergar Stone Guard',
  id: CREATURE_DUERGAR_STONE_GUARD,
  description: [
    `Каменные стражи — элитные подразделение двергарских войск, развёрнутые в небольших количествах, чтобы укрепить военные ряды рядовых войск двергар или организовывать в элитные ударные силы для конкретных миссий.`,
    ...duergarDescription,
  ],
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DWARF,
    CREATURE_TYPE_DUERGAR,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 172,
  },
  armor: {
    ac: 18,
    gearId: [GEAR_CHAIN_MAIL, GEAR_SHIELD],
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_DUERGAR_RESILIENCE,
    {
      name: 'Строй фалангой',
      description: `Двергар обладает преимуществом на броски атаки и испытания Ловкости, стоя в пределах 5 футов от союзника-двергара, носящего щит.`,
    },
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Увеличение',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `На 1 минуту двергар магическим образом увеличивается в размере вместе со всем, что несёт и носит. В увеличенном состоянии двергар Большого размера, удваивает кости урона в атаках, основанных на Силе (уже учтено), и совершает с преимуществом проверки Силы. Если двергару не хватает места, чтобы стать Большим, он приобретает максимальный размер, допустимый имеющимся пространством.`,
    },
    {
      name: 'Невидимость',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Двергар магическим образом становится невидимым, и этот эффект будет продолжаться до 1 часа, или же до того момента, когда он атакует, прочитает заклинание, использует _Увеличение_ или потеряет концентрацию (как если бы он концентрировался на заклинании). Всё, что он несёт и носит, становится невидимым вместе с ним.`,
    },
    {
      name: 'Нож Короля',
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_JAVELIN,
    },
    {
      name: 'Нож Короля',
      comment: `короткий меч; в увеличенном состоянии`,
      // gearId: GEAR_SHORTSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      comment: `в увеличенном состоянии`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      comment: `в увеличенном состоянии`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 6,
        range: {
          normal: 30,
          max: 120,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
}
