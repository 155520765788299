const {
  SPELL_CALL_LIGHTNING,
  SPELL_COMMAND,
  SPELL_CONTROL_WATER,
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_DARKNESS,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_KRAKEN,
  CREATURE_KRAKEN_PRIEST,
} = require('./../../../../creatureIdList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {ATTACK_MELEE_SPELL} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_5} = require('./../../../../crList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Жрец кракена',
  nameEn: 'Kraken Priest',
  id: CREATURE_KRAKEN_PRIEST,
  description: `[Кракен](CREATURE:${CREATURE_KRAKEN}) может казаться богоподобным для народов, познавших его ярость. Те, кто принимает его силу за божественную мощь и те, кто стремится усмирить монстра почитанием, иногда получают силу, чтобы служить жрецами кракена.

[Кракен](CREATURE:${CREATURE_KRAKEN}) может смутно знать мысли жреца кракена, если они оба находятся на одном и том же плане существования, и может подавить личность жреца и контролировать его. Жрецы кракена могут, таким образом, быть глазами и ушами своего повелителя, и когда [кракену](CREATURE:${CREATURE_KRAKEN}) есть что сказать, жрец становится его рупором.

Каждый жрец кракена претерпевает изменение внешности, которое отражает влияние кракена, хотя у каждого они различны. У одного жреца кракена могут быть чернильно-чёрные глаза и щупальце с присосками вместо языка, в то время как у другого неприметное лицо, но тело покрыто глазами и ртами, сочащимися морской водой. Эти ужасающие проявления усиливаются, когда [кракен](CREATURE:${CREATURE_KRAKEN}) овладевает своим подчинённым, чтобы произнести свои страшные заявления.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 214,
  },
  armor: 10,
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_AMPHIBIOUS,
  ],
  spellCast: {
    baseStat: PARAM_WIT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_COMMAND,
          SPELL_CREATE_OR_DESTROY_WATER,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_CONTROL_WATER,
          SPELL_DARKNESS,
          SPELL_WATER_BREATHING,
          SPELL_WATER_WALK,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CALL_LIGHTNING,
          SPELL_EVARD_S_BLACK_TENTACLES,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Громовое касание',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_THUNDER,
          diceCount: 5,
          diceType: 10,
        },
      },
    },
    {
      name: 'Голос кракена',
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `Кракен громовым голосом говорит через жреца, слышимый в пределах 300 футов. Существа на выбор жреца, которые могут услышать слова кракена (которые понимают на языках Бездны, Инфернальном или Первичном) должны пройти испытание Харизмы СЛ 14 или будут напуганы на 1 минуту. Напуганная цель может повторять испытание в конце каждого своего хода, завершая эффект при успехе.`,
    },
  ],
}
