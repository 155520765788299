const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  SPELL_DIMENSION_DOOR,
  SPELL_FEAR,
  SPELL_PHANTASMAL_FORCE,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_INCORPOREAL_MOVEMENT,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_DYBBUK} = require('./../../../../creatureIdList')
const {demonDescription} = require('./../../../../textCommonParts')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Диббук',
  nameEn: 'Dybbuk',
  id: CREATURE_DYBBUK,
  description: [
    `Диббуки терроризируют смертных материального плана вселяясь в мёртвые тела и зарождая в них подобия жизни, после чего демоны используют их в своих мерзких делах.`,
    {
      header: 'Кукловоды',
      text: `В своей естественной форме, диббуки представляют из себя полупрозрачных, летающих медуз, волочащих за собой длинные нити. Однако они редко путешествуют таким образом. Вместо этого, диббук овладевает первым подходящим, найденным трупом, пробуждая тело от смерти, чтобы затем потакать своим отвратительным порокам.`,
      source: {
        id: SOURCE_MTOF,
        page: 132,
      },
    },
    {
      header: 'Тёмный маскарад',
      text: `Похищая воспоминания трупа и получая доступ к его возможностям, диббук может притворится живым существом. Но истина быстро становится очевидной для окружающих, потому что диббук с маниакальной целеустремлённостью предаётся своим порокам, которые выдают его истинную природу. Диббуки получают удовольствие запугивая других существ, заставляя захваченные тела вести себя ужасающим образом — выташнивая сгустки крови, изрыгая груды извивающихся личинок и изгибая свои конечности при беге невозможными способами.`,
      source: {
        id: SOURCE_MTOF,
        page: 132,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 132,
  },
  armor: 14,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 40,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 16,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_THUNDER,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_INCORPOREAL_MOVEMENT,
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Осквернить труп',
      description: `Диббук использует бонусное действие, когда он владеет трупом, чтобы он сделал что-то неестественное вроде рвоты кровью, поворота головы на 360 градусов, или заставить четвероногое существо двигаться на двух ногах. Каждый зверь или гуманоид, увидевший это должен пройти испытание Мудрости СЛ 12, или станет Испуганным диббуком на 1 минуту. Испуганное существо может повторять испытание в конце каждого своего хода, заканчивая эффект при успехе. Существо, успешно прошедшее испытание, становится иммунным к эффектам этого таланта на следующие 24 часа.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DIMENSION_DOOR,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_FEAR,
          SPELL_PHANTASMAL_FORCE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Усик',
      description: `Если цель является существом, его максимум хитов понижается на 3 (1к6). Понижение длится до совершения целью короткого или длинного отдыха. Цель погибает, если её максимальное значение хитов становится равно 0.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Одержимый труп',
      restore: 6,
      description: `Диббук вселяется в неповреждённый труп в пределах 5 фт. от себя, который он может видеть. Труп должен быть Большого или меньше размера, а также являться гуманоидом или зверем. С этого момента труп становится одержимым диббуком. Он становится Нежитью, оживает, и получает временные хиты, равные максимальному значению хитов трупа при жизни.

Пока диббук вселился в труп, он сохраняет свои хиты, мировоззрение, Интеллект, Мудрость, Харизму, телепатию; имеет иммунитет к урону ядом, а также состояниям Истощения, Очарования и Испуга. В противном случае он использует статистику одержимой цели, получая доступ к её знаниям и навыкам, но не к её функционалу класса, если таковой имелся.

Одержимость сохраняется, пока у трупа не кончатся временные хиты (в этот момент тело снова становится трупом), или диббук завершает одержимость Бонусным действием. Когда одержимость заканчивается, диббук появляется в незанятом пространстве в пределах 5 фт. от трупа.`,
    },
  ],
}
