const {CAT_EXPLOSIVES} = require('./../../../../../gearCategoryList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_WDH} = require('./../../../../../sourceList')
const {SPELL_DISPEL_MAGIC} = require('./../../../../../spellIdList')
const {
  MAGIC_ITEM_SMOKEPOWDER,
  MAGIC_ITEM_SMOKEPOWDER_KEG,
  MAGIC_ITEM_SMOKEPOWDER_PACKET,
} = require('./../../../../../magicItemIdList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_SMOKEPOWDER,
  name: 'Дым-порох',
  nameEn: 'Smokepowder',
  type: MGC_TYPE_WONDROUS_ITEM,
  gearCategory: CAT_EXPLOSIVES,
  rarity: MGC_RARITY_UNCOMMON,
  isConsumable: true,
  description: `Дым-порох — это магическая взрывчатка, с помощью которой выпускают пулю из ствола огнестрельного оружия. Его хранят в герметичных деревянных [бочонках](MAGIC_ITEM:${MAGIC_ITEM_SMOKEPOWDER_KEG}) или маленьких кожаных [мешочках](MAGIC_ITEM:${MAGIC_ITEM_SMOKEPOWDER_PACKET}). Дым-пороха в [мешочке](MAGIC_ITEM:${MAGIC_ITEM_SMOKEPOWDER_PACKET}) хватает на пять выстрелов, а в [бочонке](MAGIC_ITEM:${MAGIC_ITEM_SMOKEPOWDER_KEG}) — на пятьсот.

Если дым-порох поджечь, бросить или как-то резко на него воздействовать, он взорвётся и нанесёт урон огнём всем существам и предметам в пределах 20 футов: [мешочек](MAGIC_ITEM:${MAGIC_ITEM_SMOKEPOWDER_PACKET}) нанесёт 1к6 урона, [бочонок](MAGIC_ITEM:${MAGIC_ITEM_SMOKEPOWDER_KEG}) — 9к6. Успешное испытание Ловкости СЛ 12 уменьшает урон вдвое.

Заклинание [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}), сотворённое на дым-порох, навсегда делает его безвредным.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_WDH,
    page: 189,
  },
}
