const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_ORC_WAR_CHIEF,
  CREATURE_ORC_EYE_OF_GRUUMSH,
} = require('./../../creatureIdList')
const {
  PC_RACE_ELF,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')
const {
  GOD_CORELLON_LARETHIAN,
  GOD_GRUUMSH,
} = require('./../../godIdList')

module.exports = {
  header: 'Орк око Груумша',
  text: `Когда [орк](PC_RACE:${PC_RACE_ORC}) убивает [эльфа](PC_RACE:${PC_RACE_ELF}) во имя [Груумша](GOD:${GOD_GRUUMSH}) и преподносит ему тело врага как жертву, может появиться проявление бога. Он требует добавочной жертвы: один глаз [орка], как символ потери [Груумша](GOD:${GOD_GRUUMSH}) от рук его великого врага [Кореллона Ларетиана](GOD:${GOD_CORELLON_LARETHIAN}).

Если орк вырывает свой глаз, [Груумш](GOD:${GOD_GRUUMSH}) может одарить его способностью к заклинаниям и особой благосклонностью, также как и правом называть себя [Оком Груумша](CREATURE:${CREATURE_ORC_EYE_OF_GRUUMSH}). Этот дикий поборник бога резни даёт советы [вождю](CREATURE:${CREATURE_ORC_WAR_CHIEF}) предсказаниями, а в бою бросается первым, чтобы обагрить своё оружие кровью.`,
  source: {
    id: SOURCE_MM,
    page: 230,
  },
}
