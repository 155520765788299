const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_CAVE_FISHER,
  CREATURE_CHITINE,
  CREATURE_HOBGOBLIN,
} = require('./../../../../creatureIdList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ABILITY_SPIDER_CLIMB} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Пещерный удильщик',
  nameEn: 'Cave Fisher',
  id: CREATURE_CAVE_FISHER,
  description: `Пещерный удильщик — это подземный арахнид с длинным рыльцем с прядильными органами, которыми существо может производить липкое волокно, похожее на нити паутины пауков, и существо использует его, чтобы ловить жертв.

**Сидящие в засаде.** Пещерный удильщик обычно охотится на маленьких животных и любит летучих мышей, так что он растягивает своё волокно над проходами, через который такая добыча может перемещаться. Потом он забирается в потайное место, прикрепляется к поверхности, чтобы отдохнуть, и ждёт. Когда добыча впутывается в волокна, пещерный удильщик притягивает свою пищу. Группа пещерных удильщиков может работать сообща и покрыть волокном большую площадь, но как только один ловит потенциальную еду, каждый пещерный удильщик в области соревнуется за приз. Если жертва избегает начальную засаду, пещерный удильщик может вернуть себе добычу, выстрелив волокном в попытке снова её поймать.

**Перемещение на поверхность.** Дефицит пищи может выманить группу пещерных удильщиков на поверхность, в тенистый каньон или мрачный лес, в которых можно встретить и местных животных и существ, таких как исследователей или путешественников, которые иногда проходят в такой области.

Пещерный удильщик инстинктивно знает, что труднее побороть большие цели, таких как гуманоиды, так что он не решается нападать на такую добычу, если только не встречает одинокую цель. Они могут попробовать убить разведчика, который двигался впереди группы путешественников или отставшего, который плетётся в конце, а не привлекать внимание всей группы.

**Ценные внутренние органы.** Почти каждая часть пещерного удильщика полезна после разделки существа. Его кровь содержит алкоголь и по вкусу похожа на крепкий ликёр. Некоторые дварфийские спиртные напитки содержат кровь пещерного удильщика в рецепте, а некоторые дварфы, особенно берсерки, пьют кровь напрямую. Волокна пещерного удильщика, если собрать их после сплетения, можно связать в тонкую, прочную и почти невидимую верёвку. Мясо пещерного удильщика съедобно, и на вкус очень похоже на краба, приготовленного в крепком вине. Панцирь существа используется в производстве инструментов, доспехов и ювелирных украшений.

**Вынужденные слуги.** Некоторые охотятся на пещерных удильщиков и убивают их ради волокон и крови, а другие воруют яйца удильщика и выращивают потомство. У пещерных удильщиков естественная нелюбовь к огню, так как их кровь огнеопасна. Из-за этого [хитины](CREATURE:${CREATURE_CHITINE}) и [хобгоблины](CREATURE:${CREATURE_HOBGOBLIN}) иногда используют угрозы огнём для тренировки пещерных удильщиков, которых используют для охраны проходов или в качестве военных зверей.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 184,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_CLIMB]: 20,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 14,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Клейкая нить',
      description: `Пещерный удильщик может действием выпустить клейкую нить до 60 футов длиной и она прилипает ко всему, чего коснется. Существо, прилипшее к нити, схвачено пещерным удильщиком (СЛ высвобождения 13), а проверки способностей для освобождения от этого захвата делаются с помехой. Нить можно атаковать (КД 15; 5 хитов; иммунитет к психическому урону и яду), однако, оружие, которое не смогло её разорвать, прилипает к нити, и чтобы оторвать его нужно потратить действие и успешно пройти проверку Силы СЛ 13. Уничтожение нити не наносит урона пещерному удильщику, который может создать замену в свой следующий ход.`,
    },
    {
      name: 'Огнеопасная кровь',
      description: `Если хиты пещерного удильщика падают до половины или ниже, то он получает уязвимость к урону огнём.`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Пещерный удильщик делает две атаки _Когтями_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Нить',
      description: `Одно существо, схваченное клейкой нитью пещерного удильщика, должно пройти испытание Силы СЛ 13, если цель весит 200 фунтов или меньше. При провале цель подтягивается на незанятое место в 5 футах от пещерного удильщика и он бонусным действием делает по ней атаку _Когтями_. Подтягивание цели освобождает всех остальных, кто был приклеен к нити. До окончания захвата пещерный удильщик не может выпускать другую нить.`,
    },
  ],
}
