const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_DINOSAUR} = require('./../../creatureTypeIdList')
const {dinoDescriptionList} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_DINOSAUR,
  nameEn: 'Dinosaur',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'динозавр',
      genitive: 'динозавра',
      dative: 'динозавру',
      accusative: 'динозавра',
      instrumental: 'динозавром',
      prepositional: 'динозавре',
    },
    plural: {
      nominative: 'динозавры',
      genitive: 'динозавров',
      dative: 'динозаврам',
      accusative: 'динозавров',
      instrumental: 'динозаврами',
      prepositional: 'динозаврах',
    },
  },
  description: dinoDescriptionList,
}
