const {CREATURE_ANKYLOSAURUS} = require('./../../../creatureIdList')
const {POISON_TYPE_INGESTED} = require('./../../../poisonTypeList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  VOLUME_UNIT_GALLON,
  VOLUME_UNIT_OUNCE,
  VOLUME_UNIT_PINT,
} = require('./../../../volumeUnitList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_PALADIN,
} = require('./../../../pcClassIdList')
const {
  PARAM_DEX,
  PARAM_CON,
} = require('./../../../paramList')
const {
  SOURCE_DMG,
  SOURCE_GAME_BG_3,
  SOURCE_MM,
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_TOA,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  DAMAGE_ACID,
  DAMAGE_FIRE,
  DAMAGE_POISON,
} = require('./../../../damageTypeList')
const {
  CAT_AMMUNITION,
  CAT_ANIMALS,
  CAT_ARTISAN_TOOLS,
  CAT_EJECTIVES,
  CAT_EQUIPMENT,
  CAT_FOOD,
  CAT_HOLY_SYMBOL,
  CAT_POISONS,
} = require('./../../../gearCategoryList')
const {
  GEAR_ABACUS,
  GEAR_ACID,
  GEAR_ALCHEMIST_S_FIRE,
  GEAR_ALCHEMIST_S_SUPPLIES,
  GEAR_ALE_GALLON,
  GEAR_ALE_MUG,
  GEAR_AMULET,
  GEAR_ANKYLOSAURUS,
  GEAR_ANTITOXIN,
  GEAR_ARROWS,
  GEAR_ASSASSIN_S_BLOOD,
  GEAR_FLASK,
  GEAR_OIL,
  GEAR_PERFUME,
  GEAR_SALT,
  GEAR_SHIELD,
  GEAR_SOAP,
  GEAR_TANKARD,
  GEAR_VIAL,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_ABACUS,
    genderId: GENDER_MULTIPLE,
    name: 'Счёты',
    nameByCase: {
      nominative: 'счёты',
      genitive: 'счёт',
      accusative: 'счёты',
      instrumental: 'счётами',
    },
    nameAlt: 'Абак',
    nameEn: 'Abacus',
    cost: 200,
    weight: 2,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_ALCHEMIST_S_FIRE,
    genderId: GENDER_MALE,
    name: 'Алхимический огонь',
    nameByCase: {
      nominative: 'алхимический огонь',
      genitive: 'алхимического огня',
      accusative: 'алхимический огонь',
      instrumental: 'алхимическим огнём',
    },
    nameEn: 'Alchemist’s Fire',
    description: [
      `Эта вязкая и клейкая жидкость воспламеняется при контакте с воздухом. Вы можете действием метнуть [фляжку](GEAR:${GEAR_FLASK}) на расстояние до 20 фт, разбив её от удара.

Совершите дальнобойную атаку по существу или предмету, считая алхимический огонь импровизированным оружием. При попадании цель получает урон огнём 1к4 в начале каждого своего хода. Существо может окончить этот урон, потратив действие на тушение пламени, и совершив проверку Ловкости СЛ 10.`,
      {
        header: `Алхимический огонь`,
        text: `Янтарная жидкость в этом флаконе постоянно движется, складываясь в причудливые узоры.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    damage: {
      diceType: 4,
      diceCount: 1,
    },
    range: {
      normal: 20,
      max: 20,
    },
    cost: 5000,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 1,
    },
    damageType: DAMAGE_FIRE,
    saveThrow: {
      type: PARAM_DEX,
      dc: 10,
    },
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EJECTIVES,
  },
  {
    id: GEAR_ARROWS,
    genderId: GENDER_MULTIPLE,
    name: 'Стрелы',
    nameByCase: {
      nominative: 'стрелы',
      genitive: 'стрел',
      accusative: 'стрелы',
      instrumental: 'стрелами',
    },
    nameEn: 'Arrows',
    quantity: 20,
    cost: 100,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_AMMUNITION,
  },
  {
    id: GEAR_ACID,
    genderId: GENDER_FEMALE,
    name: 'Кислота',
    nameByCase: {
      nominative: 'кислота',
      genitive: 'кислоты',
      accusative: 'кислоту',
      instrumental: 'кислотой',
    },
    nameEn: 'Acid',
    description: `Вы можете действием выплеснуть содержимое этого [сосуда](GEAR:${GEAR_VIAL}) на существо, находящееся в пределах 5 фт от вас, или метнуть сосуд на расстояние до 20 фт, чтобы он разбился от удара. В любом случае совершите дальнобойную атаку против существа или предмета, считая кислоту импровизированным оружием. При попадании цель получает урон кислотой 2к6.`,
    damageType: DAMAGE_ACID,
    damage: {
      diceType: 6,
      diceCount: 2,
    },
    range: {
      normal: 20,
      max: 20,
    },
    cost: 2500,
    volume: {
      unitId: VOLUME_UNIT_OUNCE,
      count: 4,
    },
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EJECTIVES,
  },
  {
    id: GEAR_ANTITOXIN,
    genderId: GENDER_MIDDLE,
    name: 'Противоядие',
    nameByCase: {
      nominative: 'Противоядие',
      accusative: 'Противоядие',
      instrumental: 'Противоядием',
    },
    nameEn: 'Antitoxin',
    description: `Существо, выпившее жидкость из этого [флакона](GEAR:${GEAR_VIAL}), в течение часа проходит испытания от яда с преимуществом. Оно не предоставляет преимущества нежити и конструктам.`,
    cost: 5000,
    volume: {
      unitId: VOLUME_UNIT_OUNCE,
      count: 4,
    },
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_AMULET,
    genderId: GENDER_MALE,
    name: 'Амулет',
    nameByCase: {
      nominative: 'Амулет',
      accusative: 'Амулет',
      instrumental: 'Амулетом',
    },
    nameEn: 'Amulet',
    description: `Священный символ изображает божество или целый пантеон. Это амулет, изображающий символ божества. [Жрец](PC_CLASS:${PC_CLASS_CLERIC}) или [паладин](PC_CLASS:${PC_CLASS_PALADIN}) может использовать священный символ в качестве фокусировки для заклинаний. Для такого использования символа заклинатель должен держать его в руке, носить у всех на виду или нести на [щите](GEAR:${GEAR_SHIELD}).`,
    cost: 500,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_HOLY_SYMBOL,
  },
  {
    id: GEAR_ALCHEMIST_S_SUPPLIES,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты алхимика',
    nameByCase: {
      nominative: 'инструменты алхимика',
      genitive: 'инструментов алхимика',
      accusative: 'инструменты алхимика',
      instrumental: 'инструментами алхимика',
    },
    nameEn: 'Alchemist’s Supplies',
    description: `Инструменты алхимика позволяют персонажу создавать полезные варева, такие как [кислота](GEAR:${GEAR_ACID}) или [алхимический огонь](GEAR:${GEAR_ALCHEMIST_S_FIRE}).

**Компоненты.** Инструменты алхимика включают в себя

* две стеклянные мензурки,
* металлический каркас, удерживающий мензурку над открытым пламенем,
* стеклянную палочку для размешивания,
* небольшую ступку
* пестик,
* мешочек обычных алхимических ингредиентов, включающих в себя
  * [соль](GEAR:${GEAR_SALT}),
  * измельчённое железо,
  * дистиллированную воду.

**Магия.** Владение инструментами алхимика позволяет вам раскрыть больше информации при проверках Магии, связанных с зельями и подобными веществами.

**Расследование.** Когда Вы осматриваете область в поиске улик, владение инструментами алхимика даёт дополнительные знания о том, какие химикаты и прочие вещества использовались в области.

**Алхимическое ремесло.** Вы можете использовать владение инструментами для создания алхимических предметов. Персонаж может потратить деньги, чтобы собрать сырьё, которое весит 1 фунт за каждые использованные 50 зм. Мастер может позволить персонажу пройти проверку на опознание с преимуществом.

Как часть длинного отдыха Вы можете использовать инструменты алхимика, чтобы сделать одну порцию 

* [кислоты](GEAR:${GEAR_ACID}),
* [алхимического огня](GEAR:${GEAR_ALCHEMIST_S_FIRE}),
* [противоядия](GEAR:${GEAR_ANTITOXIN}),
* [масла](GEAR:${GEAR_OIL}),
* [духов](GEAR:${GEAR_PERFUME})
* [мыла](GEAR:${GEAR_SOAP}).

Вычтите половину стоимости созданного предмета из суммарной стоимости собранного вами сырья.

| Действие                   | СЛ |
|----------------------------|----|
| Создать клубы густого дыма | 10 |
| Опознать яд                | 10 |
| Опознать вещество          | 15 |
| Устроить поджог            | 15 |
| Нейтрализовать кислоту     | 20 |
`,
    cost: 5000,
    weight: 8,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 81,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_ANKYLOSAURUS,
    genderId: GENDER_MALE,
    name: 'Анкилозавр',
    nameByCase: {
      nominative: 'анкилозавр',
      genitive: 'анкилозавра',
      accusative: 'анкилозавра',
      instrumental: 'анкилозавром',
    },
    nameEn: 'Ankylosaurus',
    creatureId: CREATURE_ANKYLOSAURUS,
    description: 'Скорость: 30 фт. Грузоподъёмность 1140 фунтов.',
    cost: 25000,
    source: [
      {
        id: SOURCE_TOA,
        page: 29,
      },
      {
        id: SOURCE_MM,
        page: 96,
      },
    ],
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_ALE_MUG,
    genderId: GENDER_MIDDLE,
    name: 'Эль, кружка',
    nameByCase: {
      nominative: 'кружка эля',
      genitive: 'кружки эля',
      accusative: 'кружку эля',
      instrumental: 'кружкой эля',
    },
    nameAlt: 'Пиво, кружка',
    nameEn: 'Ale, mug',
    description: `[Кружка](GEAR:${GEAR_TANKARD}) эля`,
    cost: 4,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 1,
    },
    source: {
      id: SOURCE_PHB,
      page: 158,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_ALE_GALLON,
    genderId: GENDER_MULTIPLE,
    name: 'Эль, галлон',
    nameByCase: {
      nominative: 'галлон эля',
      genitive: 'галлона эля',
      accusative: 'галлон эля',
      instrumental: 'галлоном эля',
    },
    nameAlt: 'Пиво, галлон',
    nameEn: 'Ale, gallon',
    cost: 20,
    volume: {
      unitId: VOLUME_UNIT_GALLON,
      count: 1,
    },
    source: {
      id: SOURCE_PHB,
      page: 158,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_ASSASSIN_S_BLOOD,
    genderId: GENDER_FEMALE,
    name: 'Яд, кровь ассасина',
    nameByCase: {
      nominative: 'кровь ассасина',
      genitive: 'крови ассасина',
      accusative: 'кровь ассасина',
      instrumental: 'крови ассасина',
    },
    nameEn: 'Assassin’s Blood',
    description: `Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 10. При провале оно получает урон ядом 6 (1к12) и становится отравленным на 24 часа. При успехе существо получает половину урона и не становится отравленным.`,
    damage: {
      diceType: 12,
      diceCount: 1,
    },
    saveThrow: {
      type: PARAM_CON,
      dc: 10,
    },
    cost: 15000,
    damageType: DAMAGE_POISON,
    poisonType: POISON_TYPE_INGESTED,
    source: {
      id: SOURCE_DMG,
      page: 257,
    },
    category: CAT_POISONS,
  },
]

module.exports = gearRawList
