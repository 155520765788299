const {PC_SUBCLASS_SORCERER_DRACONIC_BLOODLINE} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  FEATURE_DRACONIC_PRESENCE,
  FEATURE_DRACONIC_RESILIENCE,
  FEATURE_DRAGON_ANCESTOR,
  FEATURE_DRAGON_WINGS,
  FEATURE_ELEMENTAL_AFFINITY,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_DRAGON_ANCESTOR,
    name: `Драконий предок`,
    nameEn: `Dragon Ancestor`,
    lvl: 1,
    text: `Вы выбираете вид Вашего дракона-предка. Связанный с ним вид урона используется в Ваших умениях.

# Драконий предок

| Дракон     | Вид урона     |
|------------|---------------|
| Белый      | Холод         |
| Бронзовый  | Электричество |
| Зелёный    | Яд            |
| Золотой    | Огонь         |
| Красный    | Огонь         |
| Латунный   | Огонь         |
| Медный     | Кислота       |
| Серебряный | Холод         |
| Синий      | Электричество |
| Чёрный     | Кислота       |

Вы можете говорить, читать и писать на Драконьем языке. Кроме того, когда Вы взаимодействуете с драконами и совершаете проверку Харизмы, Ваш бонус мастерства удваивается для этой проверки.`,
  },
  {
    id: FEATURE_DRACONIC_RESILIENCE,
    name: `Драконья устойчивость`,
    nameEn: `Draconic Resilience`,
    lvl: 1,
    text: `Магия, струящаяся через Ваше тело, проявляет физические черты Ваших предков драконов. Максимум Ваших хитов увеличивается на 1 на 1 уровне и на 1 на каждом уровне, полученном в данном классе.

Кроме того, некоторые участки Вашей кожи покрыты тонкой драконьей чешуёй. Если Вы не носите доспехов, Ваш **КД равен 13 + модификатор Ловкости**.`,
  },
  {
    id: FEATURE_ELEMENTAL_AFFINITY,
    name: `Родство со стихией`,
    nameEn: `Elemental Affinity`,
    lvl: 6,
    text: `Когда Вы накладываете заклинание, причиняющее урон вида, связанного с Вашим драконьим предком, Вы добавляете модификатор Харизмы к одному броску урона этого заклинания.

В это же самое время Вы можете потратить 1 единицу чародейства, чтобы получить сопротивление к этому виду урона на 1 час.`,
  },
  {
    id: FEATURE_DRAGON_WINGS,
    name: `Крылья дракона`,
    nameEn: `Dragon Wings`,
    lvl: 14,
    text: `Вы получаете способность расправить драконьи крылья у себя за спиной, получая при этом скорость полёта, равную Вашей текущей скорости. Вы можете создать их бонусным действием в свой ход. Крылья существуют, пока Вы не развеете их бонусным действием в свой ход.

Вы не можете призвать свои крылья, нося броню, если, конечно, броня не изготовлена специально для этого. Одежда, также не приспособленная под крылья, может быть уничтожена, когда Вы призываете их.`,
  },
  {
    id: FEATURE_DRACONIC_PRESENCE,
    name: `Образ дракона`,
    nameEn: `Draconic Presence`,
    lvl: 18,
    text: `Вы можете вызвать ужасный образ своего предка дракона, повергая им в ужас врагов.

Вы можете действием потратить 5 единиц чародейства, чтобы окружить себя аурой страха или трепета (на Ваш выбор), радиусом 60 футов.

В течение 1 минуты, или пока Вы не утратите концентрацию (как если бы Вы концентрировались на заклинании), все враждебные существа, начинающие ход в этой ауре, должны пройти испытание Мудрости, иначе они станут очарованными (если Вы выбрали трепет) или испуганными (если Вы выбрали страх) до окончания действия ауры. Существо, преуспевшее в испытании, получает иммунитет к Вашей ауре на 24 часа.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_SORCERER_DRACONIC_BLOODLINE,
    source: {
      id: SOURCE_PHB,
      page: 103,
    },
  })
)

