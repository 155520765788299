const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_16,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_CONSTRUCT,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_ITS_CREATOR,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    golemDescription,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_IMMUTABLE_FORM,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_IRON_GOLEM,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Железный голем',
  nameEn: 'Iron Golem',
  id: CREATURE_IRON_GOLEM,
  description: [
    `Железный голем — сильнейший из големов, массивный высокий великан из тяжёлого металла. Форма железного голема может быть любой, но большинство сделано в виде огромных живых доспехов. Его кулаки могут убить существо одним ударом, а его лязгающая походка сотрясает землю под ногами. Железные големы носят огромные мечи для увеличения досягаемости и могут изрыгать облака смертельного яда.

Тело железного голема выплавлено с добавлением редких примесей и добавок. Другие големы имеют уязвимость, заключающуюся в их материале или силе стихийного духа, связанного с ним, а железные големы были созданы быть практически неуязвимыми. Их металлические тела лишают свободы управляющих духов, и они восприимчивы только к оружию, наполненному магией или силой адамантина.`,
    golemDescription,
  ],
  note: {
    text: `За сломанными дверьми лежал большой зал, в конце которого возвышался каменный трон, на котором сидела железная статуя, выше и шире двух человек. В одной руке она сжимала железный меч, в другой — оперённый кнут. Нам тогда надо было повернуть назад`,
    author: `Архимаг Морденкайнен, ведущий хронику горестных изысканий своего отряда под Замком Мора`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 67,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 20,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_PSYCHIC,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_16,
  featureList: [
    {
      name: 'Поглощение огня',
      description: `Каждый раз, когда ★СУЩЕСТВО★ должен получить урон огнём, ★он★ не получает урон, и вместо этого восстанавливает число хитов, равное причиняемому урону огнём.`,
    },
    ABILITY_IMMUTABLE_FORM,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Меч',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Ядовитое дыхание',
      description: `★СУЩЕСТВО★ выдыхает ядовитый газ 15-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 19, получая урон ядом 45 (10к8) при провале, или половину этого урона при успехе.`,
      restore: 6,
    },
  ],
  genderId: GENDER_MALE,
}
