const {
  SPELL_AUGURY,
  SPELL_BANE,
  SPELL_BESTOW_CURSE,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CURE_WOUNDS,
  SPELL_GUIDANCE,
  SPELL_GUIDING_BOLT,
  SPELL_MENDING,
  SPELL_RESISTANCE,
  SPELL_THAUMATURGY,
  SPELL_WARDING_BOND,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_INTIMIDATION,
  SKILL_MEDICINE,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_ORC,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  orcDescription,
  orcNote,
} = require('./../../../../textCommonParts')
const {
  GOD_GRUUMSH,
  GOD_LUTHIC,
} = require('./../../../../godIdList')
const {ABILITY_AGGRESSIVE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {
  CREATURE_CAVE_BEAR,
  CREATURE_ORC_CLAW_OF_LUTHIC,
} = require('./../../../../creatureIdList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {GEAR_HIDE_ARMOR} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Орчиха коготь Лутик',
  nameEn: 'Orc claw of Luthic',
  id: CREATURE_ORC_CLAW_OF_LUTHIC,
  description: [
    `[Лутик](GOD:${GOD_LUTHIC}) — жена [Груумша](GOD:${GOD_GRUUMSH}) и образец материнства для всех орков. Она — Пещерная Мать, яростная обитательница темноты, которая помогает потомству орков стать злобными и сильными. Её символ — [пещерный медведь](CREATURE:${CREATURE_CAVE_BEAR}), и женщины орков выращивают таких медведей вместе с детьми орков. Женщины, особенно преданные [Лутик](GOD:${GOD_LUTHIC}), отращивают длинные ногти и лакируют их, учатся использовать ногти как оружие, так же как [Лутик](GOD:${GOD_LUTHIC}) использует свои.

Женщины орки, которые чтят [Лутик](GOD:${GOD_LUTHIC}), руководят укреплением и поддержкой орочьих цитаделей. Они помогают гарантировать выживание племени, и у них самые лучшие навыки в лечебном искусстве. Самые сильные среди последовательниц [Лутик](GOD:${GOD_LUTHIC}) — это когти [Лутик](GOD:${GOD_LUTHIC}), которые могут использовать магию Пещерной Матери для лечения, защиты и проклятия.`,
    ...orcDescription,
  ],
  note: orcNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ORC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 180,
  },
  armor: {
    ac: 14,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_MEDICINE]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ORC,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AGGRESSIVE,
  ],
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_AUGURY,
      SPELL_BANE,
      SPELL_BESTOW_CURSE,
      SPELL_CREATE_FOOD_AND_WATER,
      SPELL_CURE_WOUNDS,
      SPELL_GUIDANCE,
      SPELL_GUIDING_BOLT,
      SPELL_MENDING,
      SPELL_RESISTANCE,
      SPELL_THAUMATURGY,
      SPELL_WARDING_BOND,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Орчиха делает две атаки _Когтями_ или четыре атаки _Когтями_, если у неё осталось меньше половины хитов.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
