module.exports = [
  require('./wall_of_light'),
  require('./wall_of_sand'),
  require('./wall_of_water'),
  require('./warding_wind'),
  require('./watery_sphere'),
  require('./whirlwind'),
  require('./word_of_radiance'),
  require('./wrath_of_nature'),
]
