const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  SPELL_GLYPH_OF_WARDING,
  SPELL_SYMBOL,
} = require('./../../spellIdList')
const {
  PC_CLASS_WIZARD,
} = require('./../../pcClassIdList')
const {
  GEAR_BACKPACK,
  GEAR_CHAIN,
  GEAR_CHEST,
  GEAR_CROSSBOW_HEAVY,
  GEAR_GRAPPLING_HOOK,
  GEAR_JAVELIN,
  GEAR_LONGSWORD,
  GEAR_NET,
  GEAR_SLING,
} = require('./../../gearIdList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')
const {
  GOD_ANNAM,
  GOD_SKORAEUS_STONEBONES,
} = require('./../../godIdList')
const {
  CREATURE_BROWN_BEAR,
  CREATURE_CLOUD_GIANT,
  CREATURE_ELK,
  CREATURE_ETTIN,
  CREATURE_FIRE_GIANT,
  CREATURE_FOMORIAN,
  CREATURE_FROST_GIANT,
  CREATURE_HILL_GIANT,
  CREATURE_OGRE,
  CREATURE_OWLBEAR,
  CREATURE_OX,
  CREATURE_STONE_GIANT,
  CREATURE_STORM_GIANT,
  CREATURE_WARHORSE,
} = require('./../../creatureIdList')
const giantGodsAllDescriptionList = require('./giantGodsAll')
const giantLanguage = require('./giantLanguage')

module.exports = [
  {
    header: 'Великаны',
    subHeader: 'Гиганты',
    text: `Древние империи когда-то отбрасывали длинные тени на мир, который сотрясался под ногами великанов. В те потерянные дни эти величественные фигуры были драконоубийцами, мечтателями, ремесленниками и царями, но время славы их расы давно прошло. Тем не менее, даже разделённые на закрытые кланы и разбросанных по всему миру, великаны сохранили старые традиции и обычаи.`,
    source: {
      id: SOURCE_MM,
      page: 33,
    },
  },
  {
    header: 'Древние, как легенда',
    text: `В забытых уголках мира ещё остались уцелевшие руины, монолиты и статуи, напоминающие о некогда великой империи великанов, склонившей голову в забвении. По всей земле некогда простиралась их империя, теперь остались лишь разрозненные племена и кланы.

Великаны так же стары, как и драконы, которые были ещё юными, когда первые шаги великанов сотрясли основание мира. Расселяясь на новые земли, великаны вступили в жесточайшую войну с драконами, длившуюся много поколений, что привело обе расы на грань вымирания. Ни один из живущих ныне великанов не помнит, из-за чего началась война, но мифы и легенды о славе на заре их расы всё ещё звучат в их поселениях и крепостях, клеймя позором первых вирмов. Великаны и драконы всё ещё таят злобу друг на друга, и редко когда могут без боя находиться на одной территории.`,
    source: {
      id: SOURCE_MM,
      page: 33,
    },
  },
  {
    header: 'Уложение',
    text: `Все основные расы великанов — [каменные](CREATURE:${CREATURE_STONE_GIANT}), [ледяные](CREATURE:${CREATURE_FROST_GIANT}), [облачные](CREATURE:${CREATURE_CLOUD_GIANT}), [огненные](CREATURE:${CREATURE_FIRE_GIANT}), [холмовые](CREATURE:${CREATURE_HILL_GIANT}) и [штормовые](CREATURE:${CREATURE_STORM_GIANT})  — связаны общей историей, религией и культурой. Они все друг для друга родня, и при встрече отбрасывают прочь все амбиции и естественную территориальную враждебность.

У великанов есть кастовая структура, название которой _«Уложение»_. В этом уложении каждому великану присваивается его социальный ранг. Благодаря пониманию своего места в уложении, великан знает, какие другие великаны стоят ниже или выше его, так как нет двух великанов равных по положению. Каждый великан анализирует различные наборы навыков и качеств, для определения уложения. Целью своей жизни великаны определяют совершенствование этих навыков.

Расы великанов тоже в широком смысле имеют положение в уложении. Выше всех стоят штормовые великаны, за ними следуют облачные, огненные, ледяные, каменные и потом уже холмовые великаны. Последними идут их родичи, такие как [фоморы](CREATURE:${CREATURE_FOMORIAN}), [эттины](CREATURE:${CREATURE_ETTIN}) и [огры](CREATURE:${CREATURE_OGRE}).

Каким бы ни был ранг великана среди его расы, лидер племени холмовых великанов всегда будет ниже простого каменного великана. Любой низкоранговый великан стоящей выше расы будет иметь ранг выше, чем любой великан нижестоящей расы. При этом если не оказать уважение вышестоящему великану или даже предать его, это будет считаться не злым поступком, а просто неуважением.`,
    source: {
      id: SOURCE_MM,
      page: 33,
    },
  },
  {
    header: 'Великаны: Сотрясатели мира',
    text: `Сага великанского племени начинается на рассвете мира. Гром его поступи сотрясал мир уже тогда, когда драконы ещё не ведали о той славе и могуществе, которых они достигнут. Записи той ранней эпохи уже исчезли в тумане легенд, когда на сцене появилось человечество. Сейчас, даже сами великаны не знают всей правды о своем начале.

Все, что великаны и им подобные знают наверняка, — это что они принадлежат к родственным расам. Гуманоиды, такие как [эльфы](PC_RACE:${PC_RACE_ELF}), [люди](PC_RACE:${PC_RACE_HUMAN}) и [дварфы](PC_RACE:${PC_RACE_DWARF}), более схожи по размерам и виду, чем несходные виды великанов, но у их рас нет общего предка. Напротив, каждый истинный великан, в независимости от вида, может проследить свою родословную непосредственно до [Аннама Всеотца](GOD:${GOD_ANNAM}). Большинство великанов считает, что [Аннам](GOD:${GOD_ANNAM}) взял множество наложниц кроме своей супруги Отеи, породив таким образом видовое разнообразие с точки зрения внешности и способностей среди великанов.

Великаны и их родственные расы считаются одними из самых пугающих существ, буквально возвышаясь над другими более молодыми существами, которые населяют мир. Но все же в наши дни большинство великанов живут в изоляции или в далеких местах, не проявляя ничего из коллективной мощи и величия своих предков.`,
    source: {
      id: SOURCE_VGTM,
      page: 20,
    },
  },
  {
    header: 'Первые впечатления',
    text: `Встреча с великаном может стать пугающим и ошеломляющим опытом. Сначала нарастает ритмичное гудение, которое Вы скорее почувствуете, чем услышите, которое медленно превращается в звук шагов: великан близко! Отдельные камни вибрируют и падают со склона. Деревьев раскачиваются, а затем и вовсе сгибаются в сторону от появившегося колосса. Как что-то может быть настолько большим? Или это всего лишь обман зрения?

Когда великаны впервые предстают перед группой авантюристов, они демонстрируют качества, которые производят глубокое впечатление на смотрящих:

# Великаны огромные

Большинство великанов могут с легкостью заглянуть в окна третьего этажа. Самые большие даже будут для этого вынуждены пригнуться! Металлический молот великана может служить наковальней для человеческого кузнеца, а его щит больше и тяжелее нежели пиршественный стол.

# Великаны тяжёлые

Если великан сядет на фургон, то его колеса и оси сломаются как спички. Великан может раздавить дом или опрокинуть судно, просто небрежно переместив свой вес. [Вол](CREATURE:${CREATURE_OX}), находящийся слишком близко к спящему великану может быть раздавлен, если великан будет переворачиваться.

# Великаны громкие

Часто шаги великана принимают как раскаты грома, даже в ясный день. Звуки великана, бьющего оружием о свой щит и рёвом вызывающего врагов, способны стряхнуть посуду с полок и заставить двери дребезжать в проемах.

# Великаны сильные

Несущаяся на полном скаку [боевая лошадь](CREATURE:${CREATURE_WARHORSE}), способная раскидать колонну человеческих воинов, просто ушибется с разбегу о не шелохнувшегося от столкновения великана. Великан способен пнуть тележку с такой силой, что она пролетит через весь дом, а булава великана, размером чуть ли не с целое дерево, а то и представляющая собой как раз реальное дерево, может сравнять этот же дом с землёй с одного удара.`,
    source: {
      id: SOURCE_VGTM,
      page: 20,
    },
  },
  {
    header: 'Вонинхайм, Потерянная столица',
    text: `Вонинхайм («Дом титанов» на языке великанов) был столицей Остории тысячелетия. Это была впечатляющая конструкция из камня и железа, воздвигнутая магией и смертными руками. Некоторые приписывают его строительство непосредственно одному или нескольким из сыновей [Аннама](GOD:${GOD_ANNAM}), утверждая, что даже великаны не могли возвести такое монументальное сооружение. Дворец стоял твердо и незыблемо, пока ледники, которые могли сплющить горы, не одолели его и растеклись вокруг, и лишь его железные шпили не торчали над льдом как большие, серые клыки. В конце концов лёд поглотил его полностью, и Вонинхайм был оставлен. Многие великаны стремятся вновь открыть его местоположение: некоторые надеются вернуть утраченную славу Остории, но остальные хотят лишь найти мощное оружие, которое согласно легенде погребено в замороженных залах.`,
    source: {
      id: SOURCE_VGTM,
      page: 20,
    },
  },
  {
    header: 'Остория и другие миры',
    text: `Сказ об Остории взят из Забытых Королевств. Считайте его хорошим примером того, как великаны развились во многих других мирах, как может быть описан период их подъёма и падения в манере, которая может быть образцом для других сеттингов. В вашем мире Вы можете заменить Осторию другой великанской империей, адаптировать её или создать свою уникальную историю.`,
    source: {
      id: SOURCE_VGTM,
      page: 21,
    },
  },
  {
    header: 'Начало конца',
    text: `Говорят, что империя Остория владычествовала над миром в течение четырех тысячелетий, прежде чем прийти к упадку в борьбе на истребление против драконов, которая стала известна как Тысячелетняя война.

Драконы жили внутри и за пределами Остории в относительном мире с момента основания Остории. Конфликты между великанами и драконами в те дни носили личный характер и не выходили на уровень племени или области. Обычно они разгорались на почве хвастовства или территории для охоты. Различия устранялись в состязаниях силы, мастерства или ума. Эта ситуация тянулась тысячелетиями пока красный дракон Гарикс не распалил огонь зависти и жадности против собственности великанов в своих последователях, вследствие чего они и восстали.

По крайней мере, это именно та версия, которой верят великаны. На самом деле никто не знает, что же привело к войне. Но как только разразилась битва, мир между великанами и драконами рухнул повсюду. Недруги рвали глотки друг друга во всех частях империи. Там не было линии фронта или убежищ, только бесконечные засады, осада и зверства против великанов и драконов. В конце концов с обеих сторон не осталось в живых никого, кто видел начало войны. Возраст и насилие забрали их всех, и те немногие великаны и драконы, кто оставался в живых, прожили всю свою жизнь в войне. На самом деле Тысячелетняя война не закончилась, она просто истощилась.

Область, которая ещё может быть названа Осторией, сохранились лишь далеко на севере. Несколько застав и фрагменты королевств, такие как у [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) — Хеллигхайм, и [каменных великанов](CREATURE:${CREATURE_STONE_GIANT}) — Недехайм, цеплялись за жизнь в глубоких пещерах и скрытых долинах. В последующие тысячелетия даже эти места пали, и то, что осталось от территории Остории, стало бесплодной местностью, окутанной льдом толщиной с сами горы. С того времени многие меньшие расы достигли величия и сами пали в неизвестность. Лишь немногочисленные намёки на некогда великую империю великанов пережили беспощадное течение лет.

Но великаны помнят. Их империя, как и их единая цель, давно утрачены, но тоска по величию, которым они когда-то обладали, всё ещё горит в их воспоминаниях.`,
    source: {
      id: SOURCE_VGTM,
      page: 21,
    },
  },
  ...giantLanguage,
  {
    header: 'Великаны и магия',
    text: `Великаны имеют парадоксальные отношения с магией. Внешне наиболее магическими являются [облачные великаны](CREATURE:${CREATURE_CLOUD_GIANT}), следом идут [штормовые](CREATURE:${CREATURE_STORM_GIANT}). Оба типа имеют врожденную способность использовать некоторые формы магии, связанные с воздухом, погодными условиями, и силой тяжести. Очень немногие великаны, однако, изучают магию так, как [люди](PC_RACE:${PC_RACE_HUMAN}), [дварфы](PC_RACE:${PC_RACE_DWARF}) и [эльфы](PC_RACE:${PC_RACE_ELF}). Тайная наука сама по себе не признается в Уложении; она не _мауг_, но это и не _маат_. Овладение секретами магии требует определённой преданности, которая будет отвлекать великанов от занятий, которые ценятся в Уложении. Как следствие, это путь, редко принимается.

Исключение составляет магия рун. Великаны тянутся к основательности и незыблемости магических рун. [Каменные великаны](CREATURE:${CREATURE_STONE_GIANT}) очень искусны в резьбе рун, так как это требует артистизма и потому, что их окружение идеально подходит для его использования. По крайней мере несколько _скильтгравров_ («резчиков рун») можно найти среди различных типов великанов, даже не очень сообразительных [холмовых великанов](CREATURE:${CREATURE_HILL_GIANT}), которые оставляют огромные следы на склонах холмов или выдалбливают их в собственной плоти.

Изготовление такой формы магии невероятно медленное. Представьте [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), который изготавливает свиток, и который при этом воздерживается от удобства пергамента и чернил в пользу камня и зубила, ледника и топора, железа и горна.

Вырезание магической руны на предмете наделяет его силой. Как и любой другой магический предмет, он может быть использован для активации одного или более магических эффектов. Волшебная руна также может быть вписана на поверхность, чтобы создать эффекты, аналогичные заклинаниям [Охранной руны](SPELL:${SPELL_GLYPH_OF_WARDING}) или [Знака](SPELL:${SPELL_SYMBOL}). Сама руна определяет, какой вид магии несёт предмет или поверхность. Например, штормовая руна, высеченная в камне может позволить обладателю камня управлять погодой. Та же руна, высеченная в двери или сундуке может нанести урон звуком любому, кто открывает его.`,
    source: {
      id: SOURCE_VGTM,
      page: 22,
    },
  },
  {
    header: 'Сумка великана',
    text: `Великан в пути всегда носит мешок, перекинутый через плечо. Основное предназначение мешка великана — носить в нем запас еды. Чтобы набить такое огромное брюхо (особенно в случае [холмовых великанов](CREATURE:${CREATURE_HILL_GIANT})), всегда нужно иметь при себе пропитание.

Великаны также носят в мешках камни: несколько для битвы, несколько для охоты, и один или два из них для игр. Кроме того, в сумке может быть все что угодно: инструменты, сувениры, предметы для торговли, или просто милые сердцу великана вещички.

Возможное содержание сумки великана

* Живая свинья
* Три медвежьи шкуры
* [Длинный меч](GEAR:${GEAR_LONGSWORD}), завёрнутый в плащ, на котором запекшаяся кровь (используется как ножик)
* Бочка эля
* [Полурослик](PC_RACE:${PC_RACE_HALFLING}) в клетке (для развлечения)
* [Сундук](GEAR:${GEAR_CHEST}), полный разбитого оконного стекла
* Человеческий [рюкзак](GEAR:${GEAR_BACKPACK}), набитый монетами
* Череп [медвесыча](CREATURE:${CREATURE_OWLBEAR})
* Большая вязанка дров, обмотанная лозой
* Некогда прекрасный гобелен, теперь используемый в качестве полотенца и оттого растрепавшийся
* Четыре новые колеса для фургона
* Надгробная плита (пускать «лягушки» по воде)
`,
    source: {
      id: SOURCE_VGTM,
      page: 23,
    },
  },
  {
    header: 'Чемпионы по метанию камней',
    text: `Великаны пользуются заслуженной репутацией живых осадных машин — все они метко швыряют валуны на большие расстояния. Метание камней — для сражения, охоты и спорта — является традицией, идущей со времен древних великанов. Другие расы разработали [пращу](GEAR:${GEAR_SLING}), [метательные копья](GEAR:${GEAR_JAVELIN}) или лук, чтобы искусственно улучшить силу и точность дальних атак, но великаны никогда не чувствовали потребности во вспомогательных механизмах. Даже там, где великаны приспособили для боя луки или копья, они никогда не пренебрегали возможностью схватить обломок скалы и отправить его в полет. На самом деле лишь немногие действия доставляют им столько же удовольствия, сколько простой бросок камня.

Большинство игр, в которые играют великаны, включают в себя бросание камней, что помогает улучшить их боевые и охотничьи навыки. Одно из самых популярных состязаний, особенно среди [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}), заключается в том, чтобы просто по очереди пытаться свалить друг друга при помощи камней. [Ледяные великаны](CREATURE:${CREATURE_FROST_GIANT}) строят мишени из снега и льда и устраивают соревнование, чтобы определить, кто поразит больше целей одним броском. Популярная игра один на один начинается с того, что один из противников бросает камень как можно дальше от себя. Великан, которому бросили вызов, перемещается к месту падения камня и швыряет его назад в соперника. Претендент, если он сильнее, побеждает, так как обратный бросок не достигнет цели, а если он слабее, то спотыкаясь уходит залечивать свои раны, получив урок, что у высокомерия есть цена.

В сражении против мелких существ великаны используют камни, которые помещаются в одну руку. Когда великаны борются с огромными противниками (такими как драконы) или огромными целями (такими как замки), они предпочитают швырять камни столь большие, что даже великан должен использовать обе руки, чтобы поднять и бросить его. Великаны бросают обеими руками также точно, как одной, что большинство людей сочтет невозможным. По очевидным причинам, такая атака эффективна только на небольшом расстоянии.

Когда великаны охотятся, они используют маленькие камни размером с человеческую голову, который может убить [лося](CREATURE:${CREATURE_ELK}) или [медведя](CREATURE:${CREATURE_BROWN_BEAR}), не превратив его в месиво.`,
    source: {
      id: SOURCE_VGTM,
      page: 23,
    },
  },
  {
    header: 'Как побороть великана',
    text: `Армия, союзная с великанами, или ещё хуже, состоящая из великанов, — один из самых устрашающих противников на поле боя. Великаны могут обрушить каменный дождь на таком расстоянии, что только умелые лучники, тяжелое осадное оружие или заклинатели смогут нанести ответный удар.

На первый взгляд может показаться, что могучий [волшебник](PC_CLASS:${PC_CLASS_WIZARD}) является лучшим кандидатом на роль убийцы великанов, но лишь некоторые заклинатели смогут противостоять великану в прямом столкновении. Заклинатель сможет причинить урон великану, но есть неплохая вероятность, что тот переживет одно-два заклинания, прежде чем его огромная дубина или метко пущенный валун размажут врага.

Среди тех, у кого есть опыт сражений с великанами, [дварфы](PC_RACE:${PC_RACE_DWARF}) создали самую эффективную тактику. Чтобы победить великана, [дварфы](PC_RACE:${PC_RACE_DWARF}) полагаются на длительную, точную, массированную стрельбу (в основном из [тяжёлых арбалетов](GEAR:${GEAR_CROSSBOW_HEAVY}), созданных именно для этого), стремительную кавалерию, которая сможет завести великана в невыгодное положение, или армию фанатиков, вооруженных древковым оружием, веревками и [крюк-кошками](GEAR:${GEAR_GRAPPLING_HOOK}). Если великана смогут опрокинуть (предпочтительно на живот, чтобы он не смог защищаться), то на него накинут [сети](GEAR:${GEAR_NET}) и [цепи](GEAR:${GEAR_CHAIN}), и вырубят сконцентрированными атаками в голову и шею.

С другой стороны, великаны понимают, что маленькие противники будут целиться в ноги и нижнюю часть тела. Поэтому, отправляясь на битву с противником размером с человека, они надевают толстые ботинки, поножи, бронированные гульфики и тяжелые пояса из металла или шкур, чтобы защитить свои животы. Даже тупые [холмовые великаны](CREATURE:${CREATURE_HILL_GIANT}) счищают толстую кору с деревьев и обвязывают её вокруг ног и подвешивают брёвна или камни на пояс, чтобы враг рисковал, пытаясь атаковать их в ближнем бою.`,
    source: {
      id: SOURCE_VGTM,
      page: 24,
    },
  },
  {
    header: 'Жизнь великана',
    text: `Великаны живут исключительно долго по сравнению с [людьми](PC_RACE:${PC_RACE_HUMAN}), но не обладают бессмертием. Мирная смерть от старости — обычное явление среди [облачных](CREATURE:${CREATURE_CLOUD_GIANT}) и [штормовых великанов](CREATURE:${CREATURE_STORM_GIANT}), и не так уж необычна для [каменных](CREATURE:${CREATURE_STONE_GIANT}) и [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}). Исключением являются [холмовые](CREATURE:${CREATURE_HILL_GIANT}) и [ледяные великаны](CREATURE:${CREATURE_FROST_GIANT}), большинство из которых умирает в яростных схватках против людей, драконов, других монстров или представителей собственного вида.

Великаны живут в более медленном темпе, чем люди. За четыре обычных удара сердца человека большое сердце каменного великана ударит лишь один раз. Дети великанов остаются со своими матерями дольше, чем человеческие, и растут они медленнее. Семьи великанов малочисленные, у пары редко бывает больше, чем несколько детей, а у многих нет ни одного.

Продолжительности жизни различных видов великанов обычно соответствует их положению в Уложении; у низших великанов самые короткие жизни, а самые благородные живут намного дольше остальных. [Каменные великаны](CREATURE:${CREATURE_STONE_GIANT}) являются исключением. Из-за большой продолжительности жизни, несмотря на низкое положение в Уложении, другие великаны считают, что [каменные](CREATURE:${CREATURE_STONE_GIANT}) являются самыми мудрыми из них, поэтому [Скораус Камнекост](GOD:${GOD_SKORAEUS_STONEBONES}) часто рассматривается как самый мудрый из всех богов великанов.`,
    source: {
      id: SOURCE_VGTM,
      page: 24,
    },
  },
  {
    header: 'Продолжительность жизни великанов',
    text: `
| Вид великана | Продолжительность жизни |
|--------------|-------------------------|
| Холмовой     | 200 лет                 |
| Ледяной      | 250 лет                 |
| Огненный     | 350 лет                 |
| Облачный     | 400 лет                 |
| Штормовой    | 600 лет                 |
| Каменный     | 800 лет                 |
`,
    source: {
      id: SOURCE_VGTM,
      page: 24,
    },
  },
  {
    header: 'Отыгрыш великана',
    text: `Наделение великана личными чертами, идеалом, связью и недостатками поможет создать более яркого неигрового персонажа. Вы также можете дать великану предысторию. Предыстория дворянина, например, может применяться к [облачному великану](CREATURE:${CREATURE_CLOUD_GIANT}).`,
    source: {
      id: SOURCE_VGTM,
      page: 24,
    },
  },
  {
    header: 'Черты характера великанов',
    text: `
| к8 | Черта характера |
|-|-|
| 1 | Жестокость моих современников является реликтом ушедшей эпохи, который должен быть искоренён. Я ищу более просвещённый путь. |
| 2 | Являясь наиболее мощными существами из всех, мы обязаны использовать свои силы на всеобщее благо. |
| 3 | Я беру то, что хочу. Мне всё равно кто при этом пострадает. |
| 4 | Великан живет несколько столетий, но братство великанов вечно. Все, что я делаю, направлено на прославление наших предков, и чтобы мои потомки мною гордились. |
| 5 | Мои смертельные враги — драконы. Я делаю всё, чтобы их уничтожить. |
| 6 | Я оцениваю значимость существ по их размеру. Мелкие народы меня не волнуют. |
| 7 | Мелкие народы — вредители. Я наслаждаюсь, убивая и пытая их. |
| 8 | Хорошие они или плохие, сыновья [Аннама](GOD:${GOD_ANNAM}) представляют идеалы, которые мы, как великаны, должны стремиться поддерживать. |
`,
    source: {
      id: SOURCE_VGTM,
      page: 25,
    },
  },
  {
    header: 'Идеалы великанов',
    text: `
| к6 | Идеал |
|-|-|
| 1 | **Уложение.** [Аннам](GOD:${GOD_ANNAM}) создал Уложение во благо всех великанов, и наша задача поддерживать его любой ценой. (Принципиальный) |
| 2 | **Умения.** Что отличает мой клан, так это мастерство наших традиционных ремесел. (Добрый) |
| 3 | **Сила.** Ни одна другая раса не может сравниться с силой великанов, и никто не должен даже сметь пытаться. (Злой) |
| 4 | **Власть.** Великаны по праву являются правителями мира. Всё наладится, когда их империя будет восстановлена. (Нейтральный) |
| 5 | **Награда.** Малые расы обязаны не только уважать великанов, но и платить дань, а то, что они не платят охотно, мы возьмём силой. (Хаотичный) |
| 6 | **Религия.** Из многих сыновей [Аннама](GOD:${GOD_ANNAM}) самый великий — мой бог-покровитель. (Любой) |
`,
    source: {
      id: SOURCE_VGTM,
      page: 25,
    },
  },
  {
    header: 'Привязанности великанов',
    text: `
| к6 | Привязанность |
|-|-|
| 1 | Мой клан оказывает наибольшее влияние на мою жизнь. Наше общее место в Уложении зависит от нашей преданности друг другу. |
| 2 | Мои товарищи, которые служат в храмах нашего божества, — ближайшие товарищи, которые у меня когда-либо были и будут. |
| 3 | Мое место в Уложении предопределено нашим божеством-покровителем, и было бы кощунственным стремиться к чему-либо большему или меньшему. |
| 4 | Хотя я никогда не смогу подняться выше положения своего клана в Уложении, в своем клане я могу стать лидером. |
| 5 | Моё братство отвернулось от меня, отныне мой путь лежит среди меньших существ мира. |
| 6 | Люди доказали свою ценность в мире и заработали толику уважения великанов. |
`,
    source: {
      id: SOURCE_VGTM,
      page: 25,
    },
  },
  {
    header: 'Слабости великанов',
    text: `
| к6 | Слабость |
|-|-|
| 1 | Уложение слишком ограничивает таких, как я. |
| 2 | У меньших созданий нет души; они существуют лишь для удовлетворения амбиций и аппетита великанов. |
| 3 | Единство великанов — это миф; любой не из моего клана — подходящая цель для моего оружия. |
| 4 | Мне безразлично, что от меня ожидают другие, до такой степени, что я не могу не сделать обратное тому, что от меня просили. |
| 5 | Я прихожу в ужас от тайной магии и трепещу при её открытых проявлениях. |
| 6 | Древние драконы наводят на меня страх. В их присутствии у меня трясутся колени. |
`,
    source: {
      id: SOURCE_VGTM,
      page: 25,
    },
  },
  ...giantGodsAllDescriptionList,
]
