const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {
  SOURCE_MM,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TOA,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  CREATURE_GIANT_LIZARD,
  CREATURE_GOAT,
} = require('./../../../creatureIdList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../damageTypeList')
const {
  CAT_ANIMALS,
  CAT_ARTISAN_TOOLS,
  CAT_EQUIPMENT,
  CAT_GOODS,
  CAT_MUSIC_TOOLS,
  CAT_SIMPLE_MELEE_WEAPON,
  CAT_TRANSPORT_WATER,
} = require('./../../../gearCategoryList')
const {
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')

const {
  GEAR_GALLEY,
  GEAR_GIANT_LIZARD,
  GEAR_GINGER,
  GEAR_GLASSBLOWERS_TOOLS,
  GEAR_GLAUR,
  GEAR_GOAT,
  GEAR_GOLD,
  GEAR_GRAPPLING_HOOK,
  GEAR_GREATCLUB,
  GEAR_VIAL,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_GREATCLUB,
    genderId: GENDER_FEMALE,
    name: 'Дубина',
    nameByCase: {
      nominative: 'дубина',
      genitive: 'дубины',
      accusative: 'дубину',
      instrumental: 'дубиной',
    },
    nameAlt: 'Палица',
    nameEn: 'Greatclub',
    damage: {
      diceType: 8,
      diceCount: 1,
    },
    cost: 20,
    damageType: DAMAGE_BLUDGEONING,
    weight: 10,
    source: {
      id: SOURCE_PHB,
      page: 149,
    },
    weaponPropList: [
      WEAPON_TWO_HANDED,
    ],
    category: CAT_SIMPLE_MELEE_WEAPON,
  },
  {
    id: GEAR_GRAPPLING_HOOK,
    genderId: GENDER_MALE,
    name: 'Крюк-кошка',
    nameByCase: {
      nominative: 'крюк-кошка',
      genitive: 'крюка-кошки',
      accusative: 'крюк-кошка',
      instrumental: 'крюком-кошкой',
    },
    nameEn: 'Grappling hook',
    cost: 200,
    weight: 4,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_GLASSBLOWERS_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты стеклодува',
    nameByCase: {
      nominative: 'инструменты стеклодува',
      genitive: 'инструментов стеклодува',
      accusative: 'инструменты стеклодува',
      instrumental: 'инструментами стеклодува',
    },
    nameEn: 'Glassblower’s Tools',
    description: `Владеющий инструментами стеклодува не только умеет выдувать стекло, но также знает особые методики изготовления стеклянных предметов.

**Компоненты.** Инструменты стеклодува включают в себя

* трубку для выдувания,
* маленькую стеклодувную обкатку,
* катальник,
* развёртки
* щипцы.

Вам нужен источник тепла для работы со стеклом.

**Магия, История.** Ваши знания техник выдувания стекла помогают вам, когда Вы изучаете стеклянный предмет, как, например, [флакон](GEAR:${GEAR_VIAL}) с зельем или стеклянный предмет, найденный в сокровищах. Например, Вы можете изучить, как стеклянный флакон с зельем изменился под действием своего содержимого, что поможет определить эффект зелья. (Зелье может оставить осадок, деформировать стекло или окрасить его).

**Расследование.** Когда Вы изучаете область, ваши знания могут помочь вам, если улики включают в себя разбитые стекла или стеклянные предметы.

**Определение слабого места.** Потратив 1 минуту, Вы можете найти слабое место стеклянного предмета. Любой урон, наносимый предмету при атаке по слабому месту, удваивается.

| Действие                                             | СЛ |
|------------------------------------------------------|----|
| Определить происхождение стекла                      | 10 |
| Определить, что когда-то содержал стеклянный предмет | 20 |
`,
    cost: 3000,
    weight: 5,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 83,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_GLAUR,
    genderId: GENDER_MALE,
    name: 'Глор',
    nameEn: 'Glaur',
    nameByCase: {
      nominative: 'глор',
      genitive: 'глора',
      accusative: 'глор',
      instrumental: 'глором',
    },
    nameAlt: 'Глун',
    nameNameAlt: 'Gloon',
    description: [
      {
        header: 'Глор',
        text: `Короткий рог, изогнутый подобно рогу изобилия. При игре на нём с использованием клапанов звучание подобно трубе, а без клапанов (в таком случае его называют глун) инструмент обретает куда более скорбное звучание.`,
        source: {
          id: SOURCE_SCAG,
          page: 125,
        },
      },
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_GIANT_LIZARD,
    genderId: GENDER_FEMALE,
    name: 'Гигантская ящерица',
    nameByCase: {
      nominative: 'гигантская ящерица',
      genitive: 'гигантской ящерицы',
      accusative: 'гигантскую ящерицу',
      instrumental: 'гигантской ящерицей',
    },
    nameEn: 'Giant Lizard',
    creatureId: CREATURE_GIANT_LIZARD,
    description: 'Скорость: 30 фт., лазая 30 фт. Грузоподъёмность 450 фунтов.',
    cost: 10000,
    source: [
      {
        id: SOURCE_MM,
        page: 324,
      },
      {
        id: SOURCE_TOA,
        page: 29,
      },
    ],
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_GALLEY,
    genderId: GENDER_FEMALE,
    name: 'Галера',
    nameByCase: {
      nominative: 'галера',
      genitive: 'галеры',
      accusative: 'галеру',
      instrumental: 'галерой',
    },
    nameEn: 'Galley',
    description: 'Скорость: 4 узла',
    cost: 3000000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_WATER,
  },
  {
    id: GEAR_GINGER,
    genderId: GENDER_MALE,
    name: 'Имбирь',
    nameByCase: {
      nominative: 'имбирь',
      genitive: 'имбиря',
      accusative: 'имбирь',
      instrumental: 'имбирём',
    },
    nameEn: 'Ginger',
    cost: 100,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_GOAT,
    genderId: GENDER_MALE,
    creatureId: CREATURE_GOAT,
    name: 'Коза',
    nameByCase: {
      nominative: 'коза',
      genitive: 'козы',
      accusative: 'коза',
      instrumental: 'козой',
    },
    nameEn: 'Goat',
    cost: 100,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_GOLD,
    genderId: GENDER_MIDDLE,
    name: 'Золото',
    nameByCase: {
      nominative: 'золото',
      genitive: 'золота',
      accusative: 'золото',
      instrumental: 'золотом',
    },
    nameEn: 'Gold',
    cost: 5000,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
]

module.exports = gearRawList
