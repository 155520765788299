const {
  SPELL_ALTER_SELF,
  SPELL_BANISHMENT,
  SPELL_BURNING_HANDS,
  SPELL_ELDRITCH_BLAST,
  SPELL_FALSE_LIFE,
  SPELL_FEEBLEMIND,
  SPELL_FINGER_OF_DEATH,
  SPELL_FIRE_BOLT,
  SPELL_FLAME_STRIKE,
  SPELL_FRIENDS,
  SPELL_HELLISH_REBUKE,
  SPELL_LEVITATE,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_CIRCLE,
  SPELL_MINOR_ILLUSION,
  SPELL_PLANE_SHIFT,
  SPELL_PRESTIDIGITATION,
  SPELL_SCORCHING_RAY,
  SPELL_SCRYING,
  SPELL_SHOCKING_GRASP,
  SPELL_SILENT_IMAGE,
  SPELL_STINKING_CLOUD,
  SPELL_SUGGESTION,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_PERSUASION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  CREATURE_IMP,
  CREATURE_QUASIT,
  CREATURE_WARLOCK_OF_THE_FIEND,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_FIRE,
  DAMAGE_SLASHING_NONMAGIC_NONSILVER_WEAPON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_7} = require('./../../../../crList')
const {GEAR_MACE} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Колдун исчадия',
  nameEn: 'Warlock of the Fiend',
  id: CREATURE_WARLOCK_OF_THE_FIEND,
  description: `Колдуны исчадия получают свои силы от магического договора, заключённого с архиисчадием Нижних планов. Эти колдуны часто держат в качестве компаньонов [бесов](CREATURE:${CREATURE_IMP}) или [квазитов](CREATURE:${CREATURE_QUASIT}), и они склонны к крайностям в поведении: якшаются с культистами-демонопоклонниками или посвящают свои жизни уничтожению демонических культов.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 216,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 15,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_SLASHING_NONMAGIC_NONSILVER_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
      comment: 'обычно Бездны или Инфернальный',
    },
  ],
  cr: CR_7,
  featureList: [
    {
      name: 'Удача темнейшего',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `Когда колдун совершает проверку способностей или проходит испытание, он может добавить к10 к броску. Он может сделать это после того как бросок сделан, но до того, как эффект вступит в силу.`,
    },
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_ALTER_SELF,
            SPELL_FALSE_LIFE,
            SPELL_SILENT_IMAGE,
            {
              id: SPELL_LEVITATE,
              comment: 'только на себя',
            },
            {
              id: SPELL_MAGE_ARMOR,
              comment: 'только на себя',
            },
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_FEEBLEMIND,
            SPELL_FINGER_OF_DEATH,
            SPELL_PLANE_SHIFT,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 17,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_ELDRITCH_BLAST,
            SPELL_FIRE_BOLT,
            SPELL_FRIENDS,
            SPELL_MAGE_HAND,
            SPELL_MINOR_ILLUSION,
            SPELL_PRESTIDIGITATION,
            SPELL_SHOCKING_GRASP,
          ],
        },
        {
          preText: `1–5 уровни (4 ячейки 5-го уровня)`,
          list: [
            SPELL_BANISHMENT,
            SPELL_BURNING_HANDS,
            SPELL_FLAME_STRIKE,
            SPELL_HELLISH_REBUKE,
            SPELL_MAGIC_CIRCLE,
            SPELL_SCORCHING_RAY,
            SPELL_SCRYING,
            SPELL_STINKING_CLOUD,
            SPELL_SUGGESTION,
            SPELL_WALL_OF_FIRE,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      gearId: GEAR_MACE,
      attack: {
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 3,
          diceType: 6,
        },
      },
    },
  ],
}
