const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_STANDING_LEAP,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_GIANT_FROG} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Гигантская лягушка',
  nameEn: 'Giant Frog',
  id: CREATURE_GIANT_FROG,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 323,
  },
  armor: 11,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 11,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 20,
      jumpHeight: 10,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Цель становится схваченной (СЛ высвобождения 11). Пока цель схвачена, она обездвижена, а лягушка не может кусать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Проглатывание',
      description: `★СУЩЕСТВО★ совершает одну атаку _Укусом_ по цели с размером не больше Маленького, которую схватила. Если атака попадает, цель становится проглоченной, а захват оканчивается. Проглоченная цель ослеплена и обездвижена, и обладает полным укрытием от атак и прочих эффектов, исходящих снаружи, а также получает урон кислотой 5 (2к4) в начале каждого хода ★СУЩЕСТВО★. ★СУЩЕСТВО★ может иметь проглоченной только одну цель одновременно.

Если ★СУЩЕСТВО★ умирает, проглоченное существо перестаёт быть обездвиженым ★им★, и может высвободиться из трупа, потратив 5 футов перемещения, падая при выходе ничком.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
