const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_KOBOLD} = require('./../../creatureTypeIdList')
const {
  koboldDescriptionList,
  koboldNote,
} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_KOBOLD,
  nameEn: 'Kobold',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'кобольд',
      genitive: 'кобольда',
      dative: 'кобольду',
      accusative: 'кобольда',
      instrumental: 'кобольдом',
      prepositional: 'кобольде',
    },
    plural: {
      nominative: 'кобольды',
      genitive: 'кобольдов',
      dative: 'кобольдам',
      accusative: 'кобольдов',
      instrumental: 'кобольдами',
      prepositional: 'кобольдах',
    },
  },
  note: koboldNote,
  description: koboldDescriptionList,
}
