const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_OGRE,
  CREATURE_STAR_SPAWN_HULK,
  CREATURE_STAR_SPAWN_SEER,
} = require('./../../../../creatureIdList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  starSpawnDescription,
  starSpawnNote,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_STAR_SPAWN,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_10} = require('./../../../../crList')
const {LANG_DEEP_SPEECH} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Громила звёздных порождений',
  nameEn: 'Star Spawn Hulk',
  id: CREATURE_STAR_SPAWN_HULK,
  description: [
    `Громила является самым большим из известных звёздных порождений. Хоть его размеры и сопоставимы с размерами [огра](CREATURE:${CREATURE_OGRE}), в полупрозрачной, блестящей кожной ткани громилы виднеются мускулистые формы, лишённые огрового жира. Бледные и, лишённые век, глаза злобно смотрят с лица, искажённого слишком большим количеством зубов и едва заметным носом. Громилы редко встречаются без командующих ими [провидцев](CREATURE:${CREATURE_STAR_SPAWN_SEER}), обладая лишь одним единственным желанием — защищать своего хозяина.`,
    ...starSpawnDescription,
  ],
  note: starSpawnNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_STAR_SPAWN,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 220,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 21,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 9,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Психическое отражение',
      description: `Если Громила получает урона психической энергией, то каждое существо в пределах 10 футов от него получает этот урон вместо него, в то время как сам громила не получает никакого урона. Кроме того, мысли и местоположение громилы не может быть отслежено магией.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Громила совершает две атаки _Ударом_. Если обе атаки поражают одну и ту же цель, она получает дополнительно 9 (2к8) урона психической энергией и должна пройти испытание Телосложения СЛ 17, иначе станет Ошеломлённой до конца своего следующего хода.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Серия одиночных ударов',
      restore: 5,
      description: `Громила совершает одиночную атаку _Ударом_ по каждому существу в пределах 10 футов от себя. Каждое существо, поражённое такой атакой должно пройти испытание Ловкости СЛ 17, иначе будет сбито с ног.`,
    },
  ],
}
