const {CAT_MAGIC_FOCUS} = require('./../../../gearCategoryList')
const {GEAR_STAFF} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')

module.exports = {
  id: GEAR_STAFF,
  genderId: GENDER_MALE,
  name: 'Посох',
  nameByCase: {
    nominative: 'посох',
    genitive: 'посоха',
    accusative: 'посох',
    instrumental: 'посохом',
  },
  nameEn: 'Staff',
  description: 'Это особый предмет, созданный для проведения тайных заклинаний. Волшебники, колдуны и чародеи могут использовать эти предметы в качестве фокусировки для заклинаний.',
  cost: 500,
  weight: 4,
  source: {
    id: SOURCE_PHB,
    page: 150,
  },
  category: CAT_MAGIC_FOCUS,
}
