const {
  CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  CREATURE_KOBOLD_DRAGONSHIELD_RED,
} = require('./../../../../creatureIdList')
const {DAMAGE_FIRE} = require('./../../../../damageTypeList')

module.exports = {
  name: 'Кобольд красный драконий щит',
  nameEn: 'Kobold Dragonshield (Red)',
  id: CREATURE_KOBOLD_DRAGONSHIELD_RED,
  parentId: CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  resistanceList: [
    DAMAGE_FIRE,
  ],
}
