const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_HASTE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_HASTE,
  name: 'Ускорение',
  nameEn: `Haste`,
  description: `Выберите согласное существо, видимое в пределах дистанции. Пока заклинание активно, скорость цели удваивается, она получает бонус +2 к КД, она с преимуществом проходит испытания Ловкости, и на каждом своём ходу может совершать дополнительное действие. Этим действием может быть только Атака (одна атака оружием), Засада, Использование предмета, Отход или Рывок.

Когда заклинание оканчивается, цель не может перемещаться и совершать действия, пока не пропустит свой следующий ход, так как становится заторможенной.`,
  lvl: 3,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `стружка корня лакрицы`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 286,
    },
    {
      id: SOURCE_SRD,
      page: 153,
    },
  ],
}
