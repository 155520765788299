const {GEAR_WAND} = require('./../../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MGC_TYPE_WAND} = require('./../../../../../magicItemTypeList')
const {MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_1_2_OR_3} = require('./../../../../../magicItemIdList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const siblingIdList = require('./siblingIdList')
const list = require('./list')

module.exports = {
  id: MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_1_2_OR_3,
  name: `Волшебная палочка боевого мага, +1, +2 или +3`,
  nameEn: `Wand of the War Mage, +1, +2 or +3`,
  rarity: generateRarityList(list),
  type: MGC_TYPE_WAND,
  gearType: GEAR_WAND,
  attunementComment: `заклинателем`,
  attunement: true,
  description: `Держа эту палочку в руке, Вы получаете бонус к броскам атаки заклинаний, определяемый редкостью волшебной палочки.

Кроме того, Вы игнорируете укрытие наполовину, когда совершаете атаки заклинаниями.`,
  genderId: GENDER_FEMALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 154,
  },
}
