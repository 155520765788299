const {
  CAST_VERBAL,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SPELL_DARKNESS} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_DARKNESS,
  name: 'Тьма',
  nameEn: 'Darkness',
  description: `Из точки, выбранной Вами в пределах дистанции, расползается магическая тьма сферой с радиусом 15 футов. Тьма огибает углы. Существа с тёмным зрением не могут видеть сквозь эту тьму, и немагический свет не может её осветить.

Если выбранная Вами точка находится на предмете, который Вы держите, несёте или носите, тьма исходит из предмета и перемещается вместе с ним. Если полностью накрыть источник тьмы непрозрачным предметом, например, чашей или шлемом, тьма будет заблокирована.

Если часть зоны этого заклинания накрывает зону света, созданного заклинанием с уровнем не выше 2, заклинание, создавшее свет, рассеивается.`,
  lvl: 2,
  magicSchoolId: MAGIC_EVOCATION,
  range: 60,
  needConcentration: true,
  componentIdList: [CAST_VERBAL, CAST_MATERIAL],
  materialText: `мех летучей мыши и либо капля дёгтя, либо кусочек угля`,
  duration: { timeId: TIME_MINUTE, count: 10 },
  source: [
    {
      id: SOURCE_PHB,
      page: 285,
    },
    {
      id: SOURCE_SRD,
      page: 133,
    },
  ],
}
