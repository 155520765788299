const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
  DAMAGE_WEAPON_DARK,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SORROW_SWORN,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_11} = require('./../../../../crList')
const {CREATURE_THE_HUNGRY} = require('./../../../../creatureIdList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {sorrowSwornDescriptionList} = require('./../../../../textCommonParts')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Голодный',
  nameAlt: 'Голод',
  nameEn: 'The Hungry',
  id: CREATURE_THE_HUNGRY,
  description: [
    `Ужасные твари с хватающими когтями и зияющей пастью, Голодные делают всё для удовлетворения своего аппетита. Эти жадные пожиратели поглощают всю жизнь и энергию, с которой сталкиваются, набивая рты и упиваясь криками жертв. Когда они заканчивают, то отправляются на поиски новой пищи.`,
      ...sorrowSwornDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SORROW_SWORN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 236,
    },
    {
      url: 'https://5e.tools/bestiary.html#the%20hungry_mtf',
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru в способности Голода «Невыносимый голод» пропущено время её действия.`,
    },
  ],
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 30,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 6,
  },
  resistanceList: [
    DAMAGE_WEAPON_DARK,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_11,
  featureList: [
    {
      name: 'Невыносимый голод',
      description: `Если существо, которое Голодный может видеть, восстанавливает хиты, то он до конца своего следующего хода совершает с преимуществом броски атаки, а его _Укус_ наносит дополнительных 22 (4к10) урона некротической энергией.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Голодный совершает две атаки: одну _Укусом_ и одну _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 4,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 3,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Когти',
      description: `Если цель Среднего размера или меньше, она становится схваченной (СЛ высвобождения 16) и обездвиженной до завершения захвата. Пока существо схвачено, Голодный не может атаковать _Когтями_.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
}
