const {
  SOURCE_ERFTLW,
} = require('./../../../../sourceList')
const {
  PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
} = require('./../../../../pcSubClassIdList')
const {
  FEATURE_STEEL_DEFENDER,
} = require('./../../../../featureIdList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FORCE,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CR_0,
} = require('./../../../../crList')
const {
  CREATURE_STEEL_DEFENDER,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Стальной защитник',
  nameEn: 'Steel Defender',
  id: CREATURE_STEEL_DEFENDER,
  description: `Создаётся Изобретателем — [Боевым кузнецом](PC_SUBCLASS:${PC_SUBCLASS_ARTIFICER_BATTLE_SMITH}) с умением  _Стальной защитник_.`,
  createdByFeatureId: FEATURE_STEEL_DEFENDER,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_ERFTLW,
    page: 62,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: `модификатор Телосложения стального защитника + модификатор Интеллекта изобретателя + пятикратный уровень изобретателя`,
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 14,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_0,
  featureList: [
    {
      name: 'Мощь хозяина',
      description: `Следующие значения параметров стального защитника увеличиваются на 1, когда бонус мастерства изобретателя увеличивается на 1:

* Бонусы к навыкам
* Бонусы к испытаниям
* Бонусы к попаданию _Силовым ударом_
* Бонусы к урону _Силового удара_
* Количество хитов, восстанавливаемое _Самопочинкой_
`,
    },
    {
      name: 'Бдительный',
      description: `Стального защитника нельзя застать врасплох.`,
    },
  ],
  actionList: [
    {
      name: 'Силовой удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          comment: 'видимая изобретателем',
        },
        hit: {
          type: DAMAGE_FORCE,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Самопочинка',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `Магические механизмы внутри ★СУЩЕСТВО★ восстанавливают 2к8 + 2 хита ★ему★ или другому конструкту или предмету в пределах 5 футов.`,
    },
  ],
  reactionList: [
    {
      name: 'Отражение атаки',
      description: `★СУЩЕСТВО★ создаёт помеху к броску атаки одного существа, видимого ★им★ в пределах 5 футов, если это атака не по ★нему★ самому.`,
    },
  ],
}
