module.exports = list => list.reduce(
  (collection, item) => collection[item.id]
    ? {
      ...collection,
      [item.id]: Array.isArray(collection[item.id])
        ? [
          ...collection[item.id],
          item,
        ]
        : [
          collection[item.id],
          item,
        ],
    }
    : {
      ...collection,
      [item.id]: item,
    },
  {},
)
