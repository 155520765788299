const {
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {ATTACK_RANGE_SPELL} = require('./../../../../../attackTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SPELL_STORM_SPHERE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_STORM_SPHERE,
  name: 'Сфера бури',
  nameEn: 'Storm Sphere',
  description: `В указанной Вами точке в пределах дистанции заклинания возникает сфера завихряющегося воздуха радиусом 20 футов.

Сфера существует, пока действует заклинание. Все существа, оказавшееся внутри сферы в момент появления, или заканчивающие в ней ход, должны пройти испытание Силы или получить 2к6 дробящего урона. Пространство сферы считается труднопроходимой местностью.

Пока действует заклинание, Вы можете бонусным действием вызвать разряд молнии, который устремится из центра сферы к выбранному Вами существу в пределах 60 футов от центра сферы. Совершите дальнобойную атаку заклинанием. Атака совершается с преимуществом, если цель находится внутри сферы. При попадании цель получает 4к6 урона электричеством.

Существа в пределах 30 футов от сферы совершают с помехой проверки Мудрости (Восприятие), связанные со слухом.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 5 круга или выше, то урон каждого эффекта увеличивается на 1к6 за каждый круг ячейки выше 4.`,
  lvl: 4,
  magicSchoolId: MAGIC_EVOCATION,
  range: 150,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: [
    {
      savethrowParam: PARAM_STR,
      damage: {
        type: DAMAGE_BLUDGEONING,
        diceCount: 2,
        diceType: 6,
      },
    },
    {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_ELECTRICITY,
        diceCount: 4,
        diceType: 6,
      },
    },
  ],
  source: [
    {
      id: SOURCE_XGTE,
      page: 168,
    },
    {
      id: SOURCE_EE,
      page: 22,
    },
  ],
}
