const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_ARTIFICER,
  FEATURE_ARTIFICER_SPECIALIST,
  FEATURE_FLASH_OF_GENIUS,
  FEATURE_INFUSE_ITEM,
  FEATURE_MAGIC_ITEM_ADEPT,
  FEATURE_MAGIC_ITEM_MASTER,
  FEATURE_MAGIC_ITEM_SAVANT,
  FEATURE_MAGICAL_TINKERING,
  FEATURE_SPELL_STORING_ITEM,
  FEATURE_SPELLCASTING_ARTIFICER,
  FEATURE_THE_RIGHT_TOOL_FOR_THE_JOB,
  FEATURE_TOOL_EXPERTISE,
} = require('./../../featureIdList')
const {SOURCE_ERFTLW} = require('./../../sourceList')

const {
  PARAM_CON,
  PARAM_INT,
} = require('./../../paramList')

const {artificerSpellSlotTable} = require('./../../spellSlotTable')
const {BACKGROUND_GUILD_ARTISAN} = require('./../../backgroundIdList')

const {
  GEAR_ALCHEMIST_S_SUPPLIES,
  GEAR_CALLIGRAPHERS_SUPPLIES,
  GEAR_CROSSBOW_BOLTS,
  GEAR_CROSSBOW_LIGHT,
  GEAR_DUNGEONEER_S_PACK,
  GEAR_SCALE_MAIL,
  GEAR_STUDDED_LEATHER_ARMOR,
  GEAR_THIEVES_TOOLS,
  GEAR_TINKERS_TOOLS,
} = require('./../../gearIdList')

const {
  CAT_ARTISAN_TOOLS,
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_SHIELDS,
  CAT_SIMPLE_WEAPON,
} = require('./../../gearCategoryList')

const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_INVESTIGATION,
  SKILL_MEDICINE,
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_SLEIGHT_OF_HAND,
} = require('./../../skillList')

const {
  SPELL_CURE_WOUNDS,
  SPELL_HEAT_METAL,
  SPELL_POISON_SPRAY,
} = require('./../../spellIdList')

const {PC_CLASS_ARTIFICER} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_ARTIFICER,
  name: {
    singular: {
      nominative: 'изобретатель',
      genitive: 'изобретателя',
      dative: 'изобретателю',
      accusative: 'изобретателя',
      instrumental: 'изобретателем',
      prepositional: 'изобретателе',
    },
    plural: {
      nominative: 'изобретатели',
      genitive: 'изобретателей',
      dative: 'изобретателям',
      accusative: 'изобретателей',
      instrumental: 'изобретателями',
      prepositional: 'изобретателях',
    },
  },
  nameEn: 'artificer',
  description: [
    `Мастера раскрытия магии в обычных вещах, изобретатели — величайшие выдумщики. Они видят магию как сложную систему, которую нужно расшифровывать и контролировать. Направляя волшебную силу инструментами, изобретатели создают магические предметы. Творя заклинание, изобретатель может использовать [инструменты алхимика](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}) для создания мощного зелья, [инструменты каллиграфа](GEAR:${GEAR_CALLIGRAPHERS_SUPPLIES}) для написания знака силы на доспехах союзника или инструменты ремесленника для создания временного предмета. Магия изобретателей связана с их инструментами и талантами.`,
    {
      header: `Волшебная наука`,
      text: `На Эберроне тайная магия использовалась как форма науки и распространялась по всему обществу. Изобретатели отражают это развитие. Самые чудесные проекты Эберрона существуют именно из-за глубокого понимания изобретателями магических устройств и их таланта наполнять магией обычные предметы.

Во время Последней Войны изобретатели работали невероятными темпами. Благодаря одним устройствам отважных изобретателей многие жизни были спасены, а другие их творения причинили ужасающие разрушения и погубили бессчётное число жизней.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 55,
      },
    },
    {
      header: `Искатели новых знаний`,
      text: `Ничто так не волнует изобретателя, как открытие нового металла или источника стихийной энергии. В изобретательских кругах новые изобретения и необыкновенные открытия вызывают наибольшее возбуждение. Изобретатели, которые хотят оставить свой вклад, должны быть новаторами, создавать что-то новое, а не повторять уже существующие проекты.

Это стремление к новизне подталкивает изобретателей становиться приключенцами. Основные торговые маршруты и населенные районы Эберрона давно изучены. Поэтому изобретатели проводят свои магические исследования на самых границах цивилизации в надежде сделать следующее великое открытие.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 55,
      },
    },
    {
      header: `Создание изобретателя`,
      text: `Создавая персонажа-изобретателя, продумайте его взаимоотношения с ремесленником, научившим его своего ремеслу. Есть ли у него конкурент? Обсудите со своим Мастером то, как можно отыгрывать изобретателя в кампании и в каких организациях он может состоять.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 55,
      },
    },
    {
      header: `Изобретатели в других мирах`,
      text: `Эберрон — это самый подходящий для изобретателей мир, но этот класс можно найти и в других мирах мультивселенной D&D.

Например, в Забытых Королевствах остров Лантан — дом для многих изобретателей, а в мире Саги о Копье членами этого класса часто являются гномы-механики.

Странные технологии в Барьерных пиках мира Серого Ястреба вдохновили некоторых личностей идти по пути изобретателя, а в Мистаре многие народы используют изобретателей для поддержания работы дирижаблей и других чудесных устройств.

В городе Сигиле изобретатели делятся открытиями со всего космоса, и одна из них — гномка-изобретательница Ви — с тех пор, как покинула мир своего рождения, Эберрон, распространила свой бизнес на всю мультивселенную.

В мире-городе Равнике лига Иззетов готовит множество изобретателей, разрушительность которых не имеет аналогов в других мирах, за исключением, возможно, гномов-механиков Кринна.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 55,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать изобретателя, следуя этим рекомендациям.

1. Во-первых, у Вашего Интеллекта должно быть наивысшее значение. Следующей по величине характеристикой должно быть Телосложение или Ловкость.
2. Во-вторых, выберите предысторию «[Гильдейский ремесленник](BACKGROUND:${BACKGROUND_GUILD_ARTISAN})».`,
      source: {
        id: SOURCE_ERFTLW,
        page: 55,
      },
    },
    {
      header: `Магия изобретательства`,
      text: `Будучи изобретателем, Вы используете инструменты для сотворения заклинаний. Когда Вы описываете свои заклинания, подумайте о том, как именно Вы используете инструменты, чтобы достичь нужного эффекта заклинания. Например, сотворяя [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}) с помощью [инструментов алхимика](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}), Вы могли бы быстро создать целебную мазь. А сотворяя это же заклинание [инструментами ремонтника](GEAR:${GEAR_TINKERS_TOOLS}), Вы могли бы быстро зашить рану маленьким механическим паучком. Когда Вы сотворяете [Ядовитые брызги](SPELL:${SPELL_POISON_SPRAY}), то можете швыряться химикалиями или выпускать ядовитую струю из волшебной палочки. Эффект заклинания остаётся тем же, как и для всех прочих заклинателей, но метод сотворения заклинаний у изобретателя особенный.

Те же принципы применимы при подготовке Ваших заклинаний. Как изобретатель, Вы не зазубриваете заклинания из книги и не молитесь божеству для их подготовки. Вместо этого, Вы с помощью инструментов создаёте специализированные предметы, которыми и будете сотворять заклинания. Например, когда Вы заменяете [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}) на [Раскалённый металл](SPELL:${SPELL_HEAT_METAL}), Вы можете так изменить устройство, которое собирались использовать для лечения, чтобы оно теперь выделяло жар вместо целительной энергии.

Эти детали никак не ограничивают Вас в использовании заклинаний и не дают к ним никаких бонусов, кроме самих эффектов заклинаний. Вы не обязаны каждый раз определять то, как именно используете инструменты для того, чтобы сотворить заклинание. Но креативное описание магии — это забавный способ отличать изобретателя от прочих заклинателей.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 57,
      },
    },
  ],
  note: {
    text: `Изобретатели создают передовые проблемы, а потом пытаются решить их — шумно и часто с сопутствующим ущербом`,
    author: `Таша`,
  },
  featureCollection: {
    hitDice: 8,
    proficiency: {
      armor: [
        CAT_LIGHT_ARMOR,
        CAT_MEDIUM_ARMOR,
        CAT_SHIELDS,
      ],
      weapon: {
        catList: [
          CAT_SIMPLE_WEAPON,
        ],
      },
      tool: {
        list: [
          GEAR_THIEVES_TOOLS,
          GEAR_TINKERS_TOOLS,
        ],
        toChoose: {
          count: 1,
          limitCatList: [
            CAT_ARTISAN_TOOLS,
          ],
        },
      },
      savingThrow: [
        PARAM_INT,
        PARAM_CON,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_ARCANA,
            SKILL_HISTORY,
            SKILL_INVESTIGATION,
            SKILL_MEDICINE,
            SKILL_NATURE,
            SKILL_PERCEPTION,
            SKILL_SLEIGHT_OF_HAND,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 5,
        diceType: 4,
        times: 10,
      },
      text: `
1. Два любых простых оружия
2. [Лёгкий арбалет](GEAR:${GEAR_CROSSBOW_LIGHT})
3. 20 [арбалетных болтов](GEAR:${GEAR_CROSSBOW_BOLTS})
4. Выберите одно
    * а) [Клёпаный кожаный доспех](GEAR:${GEAR_STUDDED_LEATHER_ARMOR})
    * б) [Чешуйчатый доспех](GEAR:${GEAR_SCALE_MAIL})
5. [Воровские инструменты](GEAR:${GEAR_THIEVES_TOOLS})
6. [Набор исследователя подземелий](GEAR:${GEAR_DUNGEONEER_S_PACK})`,
    },
  },
  progressTable: `
| Уровень | Умения                                | Известные инфузии | Инфузированных предметов | Заговоры |
|---------|---------------------------------------|-------------------|--------------------------|----------|
|  1      | [Магические поделки](FEATURE:${FEATURE_MAGICAL_TINKERING}), [Использование заклинаний](FEATURE:${FEATURE_SPELLCASTING_ARTIFICER}) | | | 2 |
|  2      | [Инфузированные предметы](FEATURE:${FEATURE_INFUSE_ITEM})                          |  4 | 2 | 2 |   
|  3      | [Специализация изобретателя](FEATURE:${FEATURE_ARTIFICER_SPECIALIST}), [Нужные инструменты](FEATURE:${FEATURE_THE_RIGHT_TOOL_FOR_THE_JOB}) |4|2|2|
|  4      | [Увеличение характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_ARTIFICER}) |  4 | 2 | 2 |
|  5      | Умение специализации                                                               |  4 | 2 | 2 |
|  6      | [Эксперт в инструментах](FEATURE:${FEATURE_TOOL_EXPERTISE})                        |  6 | 3 | 2 |
|  7      | [Вспышка гениальности](FEATURE:${FEATURE_FLASH_OF_GENIUS})                         |  6 | 3 | 2 |
|  8      | Увеличение характеристик                                                           |  6 | 3 | 2 |
|  9      | Умение специализации                                                               |  6 | 3 | 2 |
| 10      | [Знаток магических предметов](FEATURE:${FEATURE_MAGIC_ITEM_ADEPT})                 |  8 | 4 | 3 |
| 11      | [Сохранение заклинаний в предметах](FEATURE:${FEATURE_SPELL_STORING_ITEM})         |  8 | 4 | 3 |
| 12      | Увеличение характеристик                                                           |  8 | 4 | 3 |
| 13      | —                                                                               |  8 | 4 | 3 |
| 14      | [Ценитель магических предметов](FEATURE:${FEATURE_MAGIC_ITEM_SAVANT})              | 10 | 5 | 4 |
| 15      | Умение специализации                                                               | 10 | 5 | 4 |
| 16      | Увеличение характеристик                                                           | 10 | 5 | 4 |
| 17      | —                                                                               | 10 | 5 | 4 |
| 18      | [Мастер магических предметов](FEATURE:${FEATURE_MAGIC_ITEM_MASTER})                | 12 | 6 | 4 |
| 19      | Увеличение характеристик                                                           | 12 | 6 | 4 |
| 20      | [Дух изобретательства](FEATURE:${FEATURE_MAGIC_ITEM_MASTER})                       | 12 | 6 | 4 |
`,
  spellSlotTable: artificerSpellSlotTable,
  source: {
    id: SOURCE_ERFTLW,
    page: 55,
  },
}
