const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_CHONDATHAN} = require('./../../languageIdList')
const {
  PLACE_AMN,
  PLACE_CHONDATH,
  PLACE_CORMYR,
  PLACE_DALELANDS,
  PLACE_DRAGON_COAST,
  PLACE_NORTH,
  PLACE_SEMBIA,
  PLACE_SILVER_MARCHES,
  PLACE_SWORD_COAST,
  PLACE_TETHYR,
  PLACE_WATERDEEP,
  PLACE_WESTERN_HEARTHLANDS,
  PLACE_VILHON_REACH,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_CHONDATHAN,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Чондатанский',
    genitive: 'Чондатанского',
    instrumental: 'Чондатанским',
    prepositional: 'Чондатанском',
  },
  nameEn: 'Chondathan',
  spokenPlaceList: [
    PLACE_AMN,
    PLACE_CHONDATH,
    PLACE_CORMYR,
    PLACE_DALELANDS,
    PLACE_DRAGON_COAST,
    PLACE_NORTH,
    PLACE_SEMBIA,
    PLACE_SILVER_MARCHES,
    PLACE_SWORD_COAST,
    PLACE_TETHYR,
    PLACE_WATERDEEP,
    PLACE_WESTERN_HEARTHLANDS,
    PLACE_VILHON_REACH,
  ],
  isReady: false,
  isRealLang: true,
}
