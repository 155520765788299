const {
  CREATURE_DRAGON_BLACK_ADULT,
  CREATURE_DRAGON_BLUE_ADULT,
  CREATURE_DRAGON_BRASS_ADULT,
  CREATURE_DRAGON_BRONZE_ADULT,
  CREATURE_DRAGON_COPPER_ADULT,
  CREATURE_DRAGON_GOLD_ADULT,
  CREATURE_DRAGON_GREEN_ADULT,
  CREATURE_DRAGON_RED_ADULT,
  CREATURE_DRAGON_SILVER_ADULT,
  CREATURE_DRAGON_WHITE_ADULT,
  CREATURE_DRAGON_BLACK_YOUNG,
  CREATURE_DRAGON_BLUE_YOUNG,
  CREATURE_DRAGON_BRASS_YOUNG,
  CREATURE_DRAGON_BRONZE_YOUNG,
  CREATURE_DRAGON_COPPER_YOUNG,
  CREATURE_DRAGON_GOLD_YOUNG,
  CREATURE_DRAGON_GREEN_YOUNG,
  CREATURE_DRAGON_RED_YOUNG,
  CREATURE_DRAGON_SILVER_YOUNG,
  CREATURE_DRAGON_WHITE_YOUNG,
  CREATURE_DRAGON_BLACK_WYRMLING,
  CREATURE_DRAGON_BLUE_WYRMLING,
  CREATURE_DRAGON_BRASS_WYRMLING,
  CREATURE_DRAGON_BRONZE_WYRMLING,
  CREATURE_DRAGON_COPPER_WYRMLING,
  CREATURE_DRAGON_GOLD_WYRMLING,
  CREATURE_DRAGON_GREEN_WYRMLING,
  CREATURE_DRAGON_RED_WYRMLING,
  CREATURE_DRAGON_SILVER_WYRMLING,
  CREATURE_DRAGON_WHITE_WYRMLING,
} = require('./../creatureIdList')

const dragonBreatheWeaponCollection = {
  [CREATURE_DRAGON_BLACK_ADULT]: {
    name: 'Кислотное дыхание',
    description: `★СУЩЕСТВО★ выдыхает кислоту 60-футовой линией шириной 5 футов. Все существа в этой области должны пройти испытание Ловкости СЛ 18, получая урон кислотой 54 (12к8) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BLUE_ADULT]: {
    name: 'Электрическое дыхание',
    description: `★СУЩЕСТВО★ выдыхает молнию 90-футовой линией шириной 5 футов. Все существа в этой области должны пройти испытание Ловкости СЛ 19, получая урон электричеством 66 (12к10) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BRASS_ADULT]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Огненное дыхание.** ★СУЩЕСТВО★ выдыхает огонь 60-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 18, получая урон огнём 45 (13к6) при провале, или половину этого урона при успехе.
* **Усыпляющее дыхание.** ★СУЩЕСТВО★ выдыхает усыпляющий газ 60-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 18, иначе потеряют сознание на 10 минут. Этот эффект оканчивается для существа, если оно получает урон или кто-нибудь действием разбудит его.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BRONZE_ADULT]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Электрическое дыхание.** ★СУЩЕСТВО★ выдыхает молнию 90-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 19, получая урон электричеством 66 (12к10) при провале, или половину этого урона при успехе.
* **Отталкивающее дыхание.** ★СУЩЕСТВО★ выдыхает отталкивающую энергию 30-футовым конусом. Все существа в этой области должны пройти испытание Силы СЛ 19. При провале существо толкается на 60 футов от дракона.`,
    restore: 5,
  },
  [CREATURE_DRAGON_COPPER_ADULT]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Кислотное дыхание.** ★СУЩЕСТВО★ выдыхает кислоту 60-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 22, получая урон кислотой 54 (12к8) при провале, или половину этого урона при успехе.
* **Замедляющее дыхание.** ★СУЩЕСТВО★ выдыхает газ 60-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 18. При провале существо не может использовать реакции, его скорость уменьшается вдвое, и оно не может совершать более одной атаки в ход. Кроме того, существо может в свой ход совершать либо действие, либо бонусное действие, но не то и другое. Эти эффекты длятся 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_GOLD_ADULT]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Огненное дыхание.** ★СУЩЕСТВО★ выдыхает огонь 60-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 21, получая урон огнём 66 (12к10) при провале, или половину этого урона при успехе.
* **Ослабляющее дыхание.** ★СУЩЕСТВО★ выдыхает газ 60-футовым конусом. Все существа в этой области должны пройти испытание Силы СЛ 21, иначе в течение 1 минуты будут совершать с помехой броски атаки, основанные на Силе, проверки Силы и испытания Силы. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_GREEN_ADULT]: {
    name: 'Ядовитое дыхание',
    description: `★СУЩЕСТВО★ выдыхает ядовитый газ 60-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 18, получая урон ядом 56 (16к6) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_RED_ADULT]: {
    name: 'Огненное дыхание',
    description: `★СУЩЕСТВО★ выдыхает огонь 60-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 21, получая урон огнём 63 (18к6) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_SILVER_ADULT]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Холодное дыхание.** ★СУЩЕСТВО★ выдыхает волну ледяного воздуха 60-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 20, получая урон холодом 58 (13к8) при провале, или половину этого урона при успехе.
* **Парализующее дыхание.** ★СУЩЕСТВО★ выдыхает парализующий газ 60-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 20, иначе станут парализованными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_WHITE_ADULT]: {
    name: 'Холодное дыхание',
    description: `★СУЩЕСТВО★ выдыхает волну ледяного воздуха 60-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 19, получая урон холодом 54 (12к8) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BLACK_YOUNG]: {
    name: 'Кислотное дыхание',
    description: `★СУЩЕСТВО★ выдыхает кислоту 30-футовой линией шириной 5 футов. Все существа в этой области должны пройти испытание Ловкости СЛ 14, получая урон кислотой 49 (11к8) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BLUE_YOUNG]: {
    name: 'Электрическое дыхание',
    description: `★СУЩЕСТВО★ выдыхает молнию 60-футовой линией шириной 5 футов. Все существа в этой области должны пройти испытание Ловкости СЛ 16, получая урон электричеством 55 (10к10) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BRASS_YOUNG]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Огненное дыхание.** ★СУЩЕСТВО★ выдыхает огонь 40-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 14, получая урон огнём 45 (12к6) при провале, или половину этого урона при успехе.
* **Усыпляющее дыхание.** ★СУЩЕСТВО★ выдыхает усыпляющий газ 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 14, иначе потеряют сознание на 5 минут. Этот эффект оканчивается для существа, если оно получает урон или кто-нибудь действием разбудит его.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BRONZE_YOUNG]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Электрическое дыхание.** ★СУЩЕСТВО★ выдыхает молнию 60-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 15, получая урон электричеством 55 (10к10) при провале, или половину этого урона при успехе.
* **Отталкивающее дыхание.** ★СУЩЕСТВО★ выдыхает отталкивающую энергию 30-футовым конусом. Все существа в этой области должны пройти испытание Силы СЛ 15. При провале существо толкается на 40 футов от дракона.`,
    restore: 5,
  },
  [CREATURE_DRAGON_COPPER_YOUNG]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Кислотное дыхание.** ★СУЩЕСТВО★ выдыхает кислоту 40-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 14, получая урон кислотой 40 (9к8) при провале, или половину этого урона при успехе.
* **Замедляющее дыхание.** ★СУЩЕСТВО★ выдыхает газ 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 14. При провале существо не может использовать реакции, его скорость уменьшается вдвое, и оно не может совершать более одной атаки в ход. Кроме того, существо может в свой ход совершать либо действие, либо бонусное действие, но не то и другое. Эти эффекты длятся 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_GOLD_YOUNG]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Огненное дыхание.** ★СУЩЕСТВО★ выдыхает огонь 30-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 17, получая урон огнём 55 (10к10) при провале, или половину этого урона при успехе.
* **Ослабляющее дыхание.** ★СУЩЕСТВО★ выдыхает газ 30-футовым конусом. Все существа в этой области должны пройти испытание Силы СЛ 17, иначе в течение 1 минуты будут совершать с помехой броски атаки, основанные на Силе, проверки Силы и испытания Силы. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_GREEN_YOUNG]: {
    name: 'Ядовитое дыхание',
    description: `★СУЩЕСТВО★ выдыхает ядовитый газ 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 14, получая урон ядом 42 (12к6) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_RED_YOUNG]: {
    name: 'Огненное дыхание',
    description: `★СУЩЕСТВО★ выдыхает огонь 30-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 17, получая урон огнём 56 (16к6) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_SILVER_YOUNG]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Холодное дыхание.** ★СУЩЕСТВО★ выдыхает волну ледяного воздуха 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 17, получая урон холодом 54 (12к8) при провале, или половину этого урона при успехе.
* **Парализующее дыхание.** ★СУЩЕСТВО★ выдыхает парализующий газ 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 17, иначе станут парализованными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_WHITE_YOUNG]: {
    name: 'Холодное дыхание',
    description: `★СУЩЕСТВО★ выдыхает волну ледяного воздуха 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 15, получая урон холодом 45 (10к8) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BLACK_WYRMLING]: {
    name: 'Кислотное дыхание',
    description: `★СУЩЕСТВО★ выдыхает кислоту 15-футовой линией шириной 5 футов. Все существа в этой области должны пройти испытание Ловкости СЛ 11, получая урон электричеством 22 (5к8) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BLUE_WYRMLING]: {
    name: 'Электрическое дыхание',
    description: `★СУЩЕСТВО★ выдыхает молнию 30-футовой линией шириной 5 футов. Все существа в этой области должны пройти испытание Ловкости СЛ 12, получая урон электричеством 22 (4к10) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BRASS_WYRMLING]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Огненное дыхание.** ★СУЩЕСТВО★ выдыхает огонь 20-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 11, получая урон огнём 14 (4к6) при провале, или половину этого урона при успехе.
* **Усыпляющее дыхание.** ★СУЩЕСТВО★ выдыхает усыпляющий газ 15-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 11, иначе потеряют сознание на 1 минуту. Этот эффект оканчивается для существа, если оно получает урон или кто-нибудь действием разбудит его.`,
    restore: 5,
  },
  [CREATURE_DRAGON_BRONZE_WYRMLING]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Электрическое дыхание.** ★СУЩЕСТВО★ выдыхает молнию 40-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 12, получая урон электричеством 16 (3к10) при провале, или половину этого урона при успехе.
* **Отталкивающее дыхание.** ★СУЩЕСТВО★ выдыхает отталкивающую энергию 30-футовым конусом. Все существа в этой области должны пройти испытание Силы СЛ 12. При провале существо толкается на 30 футов от дракона.`,
    restore: 5,
  },
  [CREATURE_DRAGON_COPPER_WYRMLING]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Кислотное дыхание.** ★СУЩЕСТВО★ выдыхает кислоту 20-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 11, получая урон кислотой 18 (4к8) при провале, или половину этого урона при успехе.
* **Замедляющее дыхание.** ★СУЩЕСТВО★ выдыхает газ 15-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 11. При провале существо не может использовать реакции, его скорость уменьшается вдвое, и оно не может совершать более одной атаки в ход. Кроме того, существо может в свой ход совершать либо действие, либо бонусное действие, но не то и другое. Эти эффекты длятся 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_GOLD_WYRMLING]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Огненное дыхание.** ★СУЩЕСТВО★ выдыхает огонь 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 13, получая урон огнём 22 (2к10) при провале, или половину этого урона при успехе.
* **Ослабляющее дыхание.** ★СУЩЕСТВО★ выдыхает газ 15-футовым конусом. Все существа в этой области должны пройти испытание Силы СЛ 13, иначе в течение 1 минуты будут совершать с помехой броски атаки, основанные на Силе, проверки Силы и испытания Силы. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_GREEN_WYRMLING]: {
    name: 'Ядовитое дыхание',
    description: `★СУЩЕСТВО★ выдыхает ядовитый газ 15-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 11, получая урон ядом 21 (6к6) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_RED_WYRMLING]: {
    name: 'Огненное дыхание',
    description: `★СУЩЕСТВО★ выдыхает огонь 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 13, получая урон огнём 24 (7к6) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_SILVER_WYRMLING]: {
    name: 'Оружие дыхания',
    description: `★СУЩЕСТВО★ использует один из следующих видов оружия дыхания.
* **Холодное дыхание.** ★СУЩЕСТВО★ выдыхает волну ледяного воздуха 15-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 13, получая урон холодом 18 (4к8) при провале, или половину этого урона при успехе.
* **Парализующее дыхание.** ★СУЩЕСТВО★ выдыхает парализующий газ 15-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 13, иначе станут парализованными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    restore: 5,
  },
  [CREATURE_DRAGON_WHITE_WYRMLING]: {
    name: 'Холодное дыхание',
    description: `★СУЩЕСТВО★ выдыхает волну града 15-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 12, получая урон холодом 22 (5к8) при провале, или половину этого урона при успехе.`,
    restore: 5,
  },
}

module.exports = dragonBreatheWeaponCollection
