const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {
  WEAPON_RANGE,
  WEAPON_SPECIAL,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')
const {CAT_MARTIAL_RANGE_WEAPON} = require('./../../../gearCategoryList')
const {GEAR_NET} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_NET,
  genderId: GENDER_FEMALE,
  name: 'Сеть',
  nameByCase: {
    nominative: 'сеть',
    genitive: 'сети',
    accusative: 'сеть',
    instrumental: 'сетью',
  },
  nameEn: 'Net',
  description: `Тяжёлые рыболовные сети могут быть брошены в противников, запутывая их и делая их уязвимыми для последующих атак.

**Особое:** Существа Большого и меньшего размеров, по которым попала атака сетью, становятся обездвижеными, пока не высвободятся. Сеть не оказывает эффекта на бесформенных существ и тех, чей размер Огромный или ещё больше. Существо может действием совершить проверку Силы СЛ 10, чтобы высвободиться самому или освободить другое существо, находящееся в пределах его досягаемости. Причинение сети 5 единиц рубящего урона (КД 10) тоже освобождает существо, не причиняя ему вреда, оканчивая эффект и уничтожая сеть.

Если Вы действием, бонусным действием или реакцией совершаете атаку сетью, Вы можете совершить только одну атаку, вне зависимости от количества положенных атак.`,
  cost: 100,
  weight: 3,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 117,
    },
  ],
  weaponPropList: [
    WEAPON_THROWN,
    WEAPON_SPECIAL,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 5,
        max: 15,
      },
    },
  ],
  category: CAT_MARTIAL_RANGE_WEAPON,
}
