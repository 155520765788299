const {
  MGC_RARITY_COMMON,
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_POTION_OF_GREATER_HEALING,
  MAGIC_ITEM_POTION_OF_HEALING_COMMON,
  MAGIC_ITEM_POTION_OF_SUPERIOR_HEALING,
  MAGIC_ITEM_POTION_OF_SUPREME_HEALING,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_POTION_OF_HEALING_COMMON,
    name: `Зелье лечения, простое`,
    nameEn: `Potion of Healing, Common`,
    rarity: MGC_RARITY_COMMON,
    hits: '2к4 + 2',
  },
  {
    id: MAGIC_ITEM_POTION_OF_GREATER_HEALING,
    name: `Зелье большого лечения`,
    nameEn: `Potion of Greater Healing`,
    rarity: MGC_RARITY_UNCOMMON,
    hits: '4к4 + 4',
  },
  {
    id: MAGIC_ITEM_POTION_OF_SUPERIOR_HEALING,
    name: `Зелье отличного лечения`,
    nameEn: `Potion of Superior Healing`,
    rarity: MGC_RARITY_RARE,
    hits: '8к4 + 8',
  },
  {
    id: MAGIC_ITEM_POTION_OF_SUPREME_HEALING,
    name: `Зелье превосходного лечения`,
    nameEn: `Potion of Supreme Healing`,
    rarity: MGC_RARITY_VERY_RARE,
    hits: '10к4 + 20',
  },
]
