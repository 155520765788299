const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_8,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_CHAIN_DEVIL,
  CREATURE_LEMURE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Китон',
  nameAlt: [
    'Цепной дьявол',
    'Дьявол цепей',
  ],
  nameEn: 'Kyton',
  nameEnAlt: 'Chain Devil',
  id: CREATURE_CHAIN_DEVIL,
  description: [
    `Это зловещее исчадие носит цепи как одежду. Распугивая других существ жутким взглядом, этот дьявол оживляет покрывающие его тело цепи, а также те цепи, что есть рядом. Ожившие цепи покрываются крюками, лезвиями и шипами, разрывающими врагов.

Дьяволы цепей выступают в роли тюремщиков-садистов, наслаждающихся болью и живущих только ради того, чтобы эту боль причинять. Это они пытают души смертных, запертые в Девяти Преисподних, вымещая свою садистскую ярость на жалких [лемурах](CREATURE:${CREATURE_LEMURE}), в облике которых эти души и появляются.`,
    ...devilDescriptionList,
  ],
  note: [
    'Для этих извергов нет музыки прекраснее, чем симфония грохочущих цепей и рвущейся плоти',
    ...devilNote,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 145,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 18,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_8,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Цепями_.`,
    },
    {
      name: 'Цепь',
      description: `Цель становится схваченной (СЛ высвобождения 14), если нет другого существа, которого ★схватил★ ★СУЩЕСТВО★. Пока цель схвачена, она обездвижена и получает колющий урон 7 (2к6) в начале каждого своего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Оживление цепей',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `До четырёх цепей, которые ★СУЩЕСТВО★ видит в пределах 60 футов, магическим образом обрастают острыми колючками и оживают, находясь под контролем ★СУЩЕСТВО★, при условии, что эти цепи никто не несёт и не носит.

У каждой ожившей цепи КД 20, 20 хитов, сопротивление к колющему урону и иммунитет к урону звуком и психической энергией. Если ★СУЩЕСТВО★ в свой ход использует _Мультиатаку_, ★он★ может каждой ожившей цепью совершить одну дополнительную атаку _Цепью_. Каждая ожившая цепь может схватить по одной цели, но не может совершать атаки, если есть цель, схваченная ей. Ожившая цепь возвращается в неживое состояние, если её хиты опускаются до 0, а также если дьявол становится недееспособным или умирает.`,
    },
  ],
  reactionList: [
    {
      name: 'Обескураживающая иллюзия',
      description: `Если существо, которое видит ★СУЩЕСТВО★, начинает ход в пределах 30 футов от ★него★, ★СУЩЕСТВО★ может создать иллюзию, чтобы выглядеть как мертвец, бывший этому существу родным, или наоборот, как его злейший враг. Если это существо видит ★СУЩЕСТВО★, оно должно пройти испытание Мудрости СЛ 14, иначе станет испуганным до конца своего хода.`,
    },
  ],
  genderId: GENDER_MALE,
}
