const {
  SOURCE_IDRotF,
  SOURCE_MTG_CLB,
} = require('./../../sourceList')
const {SPELL_DETECT_EVIL_AND_GOOD} = require('./../../spellIdList')
const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')

module.exports = [
  {
    header: 'Чардалин',
    text: `Более ста лет назад [волшебник](PC_CLASS:${PC_CLASS_WIZARD}) по имени Акар Кессел нашёл наполненный демонической магией артефакт под названием Креншинибон (более известный как Кристальный Осколок) и использовал его для создания громадной чёрной башни в Долине Ледяного Ветра. Когда башня была уничтожена, использованная для её создания магия пропитала окружающий лёд, сформировав то, что ныне известно как «чардалин» — немагическое кристаллическое вещество. Чардалин прочен как металл, но поддаётся обработке намного лучше стали.

За прошедшие годы по всей Долине Ледяного Ветра найдено множество залежей чардалина. Как и Кристальный Осколок, они обычно пропитаны демонической магией. Долгий контакт существ с пропитанным демонической магией чардалином может повлиять на их разу, вызывая безумие. Впрочем, оно обычно проходит сразу после прерывания контакта.

Чардалин холоден на ощупь и легко принимает в себя любые чары. Это делает его идеальным веществом для создания жезлов, посохов или иных магических предметов. Чардалиновый предмет, напитанный магией Верхних Планов считается освящённым, в то время как наполнение чардалинового предмета магией Нижних планов делает его осквернённым. Оба этих свойства легко обнаруживаются заклинанием [Обнаружение добра и зла](SPELL:${SPELL_DETECT_EVIL_AND_GOOD}) или подобной магией.

Задолго до того, как Акар Кессел оставил свой след в Долине Ледяного Ветра, нетерезские волшебники уже создавали свой собственный чардалин. В руинах их анклавов до сих пор можно найти множество предметов из этого тёмного кристаллического вещества.`,
    source: {
      id: SOURCE_IDRotF,
      page: 7,
    },
  },
  {
    header: 'Чардалины',
    text: `Чардалины впервые были найдены в древнем Нетериле. Эти камни поглощают и удерживают магическую энергию, позволяя наделять конструкции устрашающей силой и неестественной злобой.`,
    source: SOURCE_MTG_CLB,
  },
]
