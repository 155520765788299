module.exports.CREATURE_AARAKOCRA = 'aarakocra'
module.exports.CREATURE_ABISHAI_BLACK = 'abishai_black'
module.exports.CREATURE_ABISHAI_BLUE = 'abishai_blue'
module.exports.CREATURE_ABISHAI_GREEN = 'abishai_green'
module.exports.CREATURE_ABISHAI_RED = 'abishai_red'
module.exports.CREATURE_ABISHAI_WHITE = 'abishai_white'
module.exports.CREATURE_ABJURER = 'abjurer'
module.exports.CREATURE_ABOLETH = 'aboleth'
module.exports.CREATURE_ABOMINABLE_YETI = 'abominable_yeti'
module.exports.CREATURE_ABYSSAL_CHICKEN = 'abyssal_chicken'
module.exports.CREATURE_ABYSSAL_WRETCH = 'abyssal_wretch'
module.exports.CREATURE_ACOLYTE = 'acolyte'
module.exports.CREATURE_AERISI_KALINOTH = 'aerisi_kalinoth'
module.exports.CREATURE_AIR_BEAST_SPIRIT = 'air_beast_spirit'
module.exports.CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26 = 'air_bestial_spirit_2020_03_26'
module.exports.CREATURE_AIR_ELEMENTAL = 'air_elemental'
module.exports.CREATURE_AIR_ELEMENTAL_SPIRIT = 'air_elemental_spirit'
module.exports.CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26 = 'air_elemental_spirit_2020_03_26'
module.exports.CREATURE_ALBINO_DWARF_SPIRIT_WARRIOR = 'albino_dwarf_spirit_warrior'
module.exports.CREATURE_ALBINO_DWARF_WARRIOR = 'albino_dwarf_warrior'
module.exports.CREATURE_ALDANI = 'aldani'
module.exports.CREATURE_ALHOON = 'alhoon'
module.exports.CREATURE_ALKILITH = 'alkilith'
module.exports.CREATURE_ALLIP = 'allip'
module.exports.CREATURE_ALLOSAURUS = 'allosaurus'
module.exports.CREATURE_ALMIRAJ = 'almiraj'
module.exports.CREATURE_AMBUSH_DRAKE = 'ambush_drake'
module.exports.CREATURE_AMNIZU = 'amnizu'
module.exports.CREATURE_AMPHISBAENA = 'amphisbaena'
module.exports.CREATURE_ANCIENT_DEEP_CROW = 'ancient_deep_crow'
module.exports.CREATURE_ANDROSPHINX = 'androsphinx'
module.exports.CREATURE_ANIMATED_ARMOR = 'animated_armor'
module.exports.CREATURE_ANKHEG = 'ankheg'
module.exports.CREATURE_ANKYLOSAURUS = 'ankylosaurus'
module.exports.CREATURE_ANKYLOSAURUS_ZOMBIE = 'ankylosaurus_zombie'
module.exports.CREATURE_ANNIS_HAG = 'annis_hag'
module.exports.CREATURE_ANVILWROUGHT_RAPTOR = 'anvilwrought_raptor'
module.exports.CREATURE_APE = 'ape'
module.exports.CREATURE_APPRENTICE_WIZARD = 'apprentice_wizard'
module.exports.CREATURE_ARCANALOTH = 'arcanaloth'
module.exports.CREATURE_ARCHDRUID = 'archdruid'
module.exports.CREATURE_ARCHER = 'archer'
module.exports.CREATURE_ARCHMAGE = 'archmage'
module.exports.CREATURE_ARCHON_OF_FALLING_STARS = 'archon_of_falling_stars'
module.exports.CREATURE_ARMANITE = 'armanite'
module.exports.CREATURE_ASHEN_RIDER = 'ashen_rider'
module.exports.CREATURE_ASSASSIN = 'assassin'
module.exports.CREATURE_ASSASSIN_BUG = 'assassin_bug'
module.exports.CREATURE_ASSASSIN_VINE = 'assassin_vine'
module.exports.CREATURE_ASTRAL_DREADNOUGHT = 'astral_dreadnought'
module.exports.CREATURE_AUROCHS = 'aurochs'
module.exports.CREATURE_AVATAR_OF_DEATH = 'avatar_of_death'
module.exports.CREATURE_AVENGER_SPIRIT = 'avenger_spirit'
module.exports.CREATURE_AWAKENED_SHRUB = 'awakened_shrub'
module.exports.CREATURE_AWAKENED_TREE = 'awakened_tree'
module.exports.CREATURE_AXE_BEAK = 'axe_beak'
module.exports.CREATURE_AZER = 'azer'
module.exports.CREATURE_BABAU = 'babau'
module.exports.CREATURE_BABOON = 'baboon'
module.exports.CREATURE_BADGER = 'badger'
module.exports.CREATURE_BAEL = 'bael'
module.exports.CREATURE_BALHANNOTH = 'balhannoth'
module.exports.CREATURE_BALOR = 'balor'
module.exports.CREATURE_BANDERHOBB = 'banderhobb'
module.exports.CREATURE_BANDIT = 'bandit'
module.exports.CREATURE_BANDIT_CAPTAIN = 'bandit_captain'
module.exports.CREATURE_BANSHEE = 'banshee'
module.exports.CREATURE_BAPHOMET = 'baphomet'
module.exports.CREATURE_BARBED_DEVIL = 'barbed_devil'
module.exports.CREATURE_BARD = 'bard'
module.exports.CREATURE_BARGHEST = 'barghest'
module.exports.CREATURE_BARLGURA = 'barlgura'
module.exports.CREATURE_BASILISK = 'basilisk'
module.exports.CREATURE_BASTIAN_THERMANDAR = 'bastian_thermandar'
module.exports.CREATURE_BAT = 'bat'
module.exports.CREATURE_BEARDED_DEVIL = 'bearded_devil'
module.exports.CREATURE_BEAST_OF_THE_LAND = 'beast_of_the_land'
module.exports.CREATURE_BEAST_OF_THE_SEA = 'beast_of_the_sea'
module.exports.CREATURE_BEAST_OF_THE_SKY = 'beast_of_the_sky'
module.exports.CREATURE_BEHIR = 'behir'
module.exports.CREATURE_BEHOLDER = 'beholder'
module.exports.CREATURE_BEHOLDERKIN_SPIRIT = 'beholderkin_spirit'
module.exports.CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26 = 'beholderkin_spirit_2020_03_26'
module.exports.CREATURE_BEHOLDER_ZOMBIE = 'beholder_zombie'
module.exports.CREATURE_BEL = 'bel'
module.exports.CREATURE_BERBALANG = 'berbalang'
module.exports.CREATURE_BERSERKER = 'berserker'
module.exports.CREATURE_BHEUR_HAG = 'bheur_hag'
module.exports.CREATURE_BLACKGUARD = 'blackguard'
module.exports.CREATURE_BLACK_BEAR = 'black_bear'
module.exports.CREATURE_BLACK_EARTH_GUARD = 'black_earth_guard'
module.exports.CREATURE_BLACK_EARTH_PRIEST = 'black_earth_priest'
module.exports.CREATURE_BLACK_GAUNTLET_OF_BANE = 'black_gauntlet_of_bane'
module.exports.CREATURE_BLACK_PUDDING = 'black_pudding'
module.exports.CREATURE_BLINDHEIM = 'blindheim'
module.exports.CREATURE_BLINK_DOG = 'blink_dog'
module.exports.CREATURE_BLOOD_HAWK = 'blood_hawk'
module.exports.CREATURE_BOAR = 'boar'
module.exports.CREATURE_BODAK = 'bodak'
module.exports.CREATURE_BOGGLE = 'boggle'
module.exports.CREATURE_BONECLAW = 'boneclaw'
module.exports.CREATURE_BONE_DEVIL = 'bone_devil'
module.exports.CREATURE_BONE_NAGA_GUARDIAN = 'bone_naga_guardian'
module.exports.CREATURE_BONE_NAGA_SPIRIT = 'bone_naga_spirit'
module.exports.CREATURE_BONE_WHELK = 'bone_whelk'
module.exports.CREATURE_BRAIN_IN_A_JAR = 'brain_in_a_jar'
module.exports.CREATURE_BRONTOSAURUS = 'brontosaurus'
module.exports.CREATURE_BRONZE_SABLE = 'bronze_sable'
module.exports.CREATURE_BRONZE_SCOUT = 'bronze_scout'
module.exports.CREATURE_BROWN_BEAR = 'brown_bear'
module.exports.CREATURE_BUGBEAR = 'bugbear'
module.exports.CREATURE_BUGBEAR_CHIEF = 'bugbear_chief'
module.exports.CREATURE_BULETTE = 'bulette'
module.exports.CREATURE_BULEZAU = 'bulezau'
module.exports.CREATURE_BULLYWUG = 'bullywug'
module.exports.CREATURE_BURNISHED_HART = 'burnished_hart'
module.exports.CREATURE_BURROWSHARK = 'burrowshark'
module.exports.CREATURE_CADAVER_COLLECTOR = 'cadaver_collector'
module.exports.CREATURE_CAMBION = 'cambion'
module.exports.CREATURE_CAMEL = 'camel'
module.exports.CREATURE_CANOLOTH = 'canoloth'
module.exports.CREATURE_CARRION_CRAWLER = 'carrion_crawler'
module.exports.CREATURE_CAT = 'cat'
module.exports.CREATURE_CATOBLEPAS = 'catoblepas'
module.exports.CREATURE_CAVE_BEAR = 'cave_bear'
module.exports.CREATURE_CAVE_FISHER = 'cave_fisher'
module.exports.CREATURE_CELESTIAL_AVENGER_SPIRIT_2020_03_26 = 'celestial_avenger_spirit_2020_03_26'
module.exports.CREATURE_CELESTIAL_DEFENDER_SPIRIT_2020_03_26 = 'celestial_defender_spirit_2020_03_26'
module.exports.CREATURE_CENTAUR = 'centaur'
module.exports.CREATURE_CHAIN_DEVIL = 'chain_devil'
module.exports.CREATURE_CHAMPION = 'champion'
module.exports.CREATURE_CHAOS_QUADRAPOD = 'chaos_quadrapod'
module.exports.CREATURE_CHARDALYN_BERSERKER = 'chardalyn_berserker'
module.exports.CREATURE_CHARDALYN_DRAGON = 'chardalyn_dragon'
module.exports.CREATURE_CHASME = 'chasme'
module.exports.CREATURE_CHIMERA = 'chimera'
module.exports.CREATURE_CHITINE = 'chitine'
module.exports.CREATURE_CHOKER = 'choker'
module.exports.CREATURE_CHOLDRITH = 'choldrith'
module.exports.CREATURE_CHROMATIC_DRAGON_SPIRIT = 'chromatic_dragon_spirit'
module.exports.CREATURE_CHROMATIC_DRAGON_SPIRIT_2021_04_14 = 'chromatic_dragon_spirit_2021_04_14'
module.exports.CREATURE_CHUUL = 'chuul'
module.exports.CREATURE_CHWINGA = 'chwinga'
module.exports.CREATURE_CLAWFOOT = 'clawfoot'
module.exports.CREATURE_CLAWFOOT_RAPTOR = 'clawfoot_raptor'
module.exports.CREATURE_CLAY_CONSTRUCT_SPIRIT = 'clay_construct_spirit'
module.exports.CREATURE_CLAY_GOLEM = 'clay_golem'
module.exports.CREATURE_CLOACKER = 'cloacker'
module.exports.CREATURE_CLOCKWORK_DRAGON = 'clockwork_dragon'
module.exports.CREATURE_CLOUD_GIANT = 'cloud_giant'
module.exports.CREATURE_CLOUD_GIANT_SMILING_ONE = 'cloud_giant_smiling_one'
module.exports.CREATURE_COCKATRICE = 'cockatrice'
module.exports.CREATURE_COLDLIGHT_WALKER = 'coldlight_walker'
module.exports.CREATURE_COMMONER = 'commoner'
module.exports.CREATURE_CONJURER = 'conjurer'
module.exports.CREATURE_CONSTRICTOR_SNAKE = 'constrictor_snake'
module.exports.CREATURE_CORPSE_FLOWER = 'corpse_flower'
module.exports.CREATURE_COUATL = 'couatl'
module.exports.CREATURE_COW = 'cow'
module.exports.CREATURE_CRAB = 'crab'
module.exports.CREATURE_CRAB_FOLK = 'crab_folk'
module.exports.CREATURE_CRAG_CAT = 'crag_cat'
module.exports.CREATURE_CRANIUM_RAT = 'cranium_rat'
module.exports.CREATURE_CRAWLING_CLAW = 'crawling_claw'
module.exports.CREATURE_CROCODILE = 'crocodile'
module.exports.CREATURE_CROKEKTOECK = 'crokektoeck'
module.exports.CREATURE_CULTIST = 'cultist'
module.exports.CREATURE_CULT_FANATIC = 'cult_fanatic'
module.exports.CREATURE_CYCLOPS = 'cyclops'
module.exports.CREATURE_DANCING_ITEM = 'dancing_item'
module.exports.CREATURE_DANCING_ITEM_2020_02_06 = 'dancing_item_2020_02_06'
module.exports.CREATURE_DAO = 'dao'
module.exports.CREATURE_DARKLING = 'darkling'
module.exports.CREATURE_DARKLING_ELDER = 'darkling_elder'
module.exports.CREATURE_DARKMANTLE = 'darkmantle'
module.exports.CREATURE_DEATHLOCK = 'deathlock'
module.exports.CREATURE_DEATHLOCK_MASTERMIND = 'deathlock_mastermind'
module.exports.CREATURE_DEATHLOCK_WIGHT = 'deathlock_wight'
module.exports.CREATURE_DEATH_DOG = 'death_dog'
module.exports.CREATURE_DEATH_KISS = 'death_kiss'
module.exports.CREATURE_DEATH_KNIGHT = 'death_knight'
module.exports.CREATURE_DEATH_S_HEAD_OF_BHAAL = 'death_s_head_of_bhaal'
module.exports.CREATURE_DEATH_TYRANT = 'death_tyrant'
module.exports.CREATURE_DECEITFUL_FEY_SPIRIT_2020_03_26 = 'deceitful_fey_spirit_2020_03_26'
module.exports.CREATURE_DEEP_CROW = 'deep_crow'
module.exports.CREATURE_DEEP_ROTHE = 'deep_rothe'
module.exports.CREATURE_DEEP_SCION = 'deep_scion'
module.exports.CREATURE_DEER = 'deer'
module.exports.CREATURE_DEFENDER_SPIRIT = 'defender_spirit'
module.exports.CREATURE_DEINONYCHUS = 'deinonychus'
module.exports.CREATURE_DEMILICH = 'demilich'
module.exports.CREATURE_DEMOGORGON = 'demogorgon'
module.exports.CREATURE_DEMON_SPIRIT = 'demon_spirit'
module.exports.CREATURE_DEMON_SPIRIT_2020_03_26 = 'demon_spirit_2020_03_26'
module.exports.CREATURE_DEMOS_MAGEN = 'demos_magen'
module.exports.CREATURE_DERRO = 'derro'
module.exports.CREATURE_DERRO_SAVANT = 'derro_savant'
module.exports.CREATURE_DESPAIR_SHADOWSPAWN_SPIRIT = 'despair_shadowspawn_spirit'
module.exports.CREATURE_DESPAIR_SHADOW_SPIRIT_2020_03_26 = 'despair_shadow_spirit_2020_03_26'
module.exports.CREATURE_DEVA = 'deva'
module.exports.CREATURE_DEVIL_SPIRIT = 'devil_spirit'
module.exports.CREATURE_DEVIL_SPIRIT_2020_03_26 = 'devil_spirit_2020_03_26'
module.exports.CREATURE_DEVOURER = 'devourer'
module.exports.CREATURE_DHERGOLOTH = 'dhergoloth'
module.exports.CREATURE_DIATRYMA = 'diatryma'
module.exports.CREATURE_DIMETRODON = 'dimetrodon'
module.exports.CREATURE_DIRE_CORBY = 'dire_corby'
module.exports.CREATURE_DIRE_TROLL = 'dire_troll'
module.exports.CREATURE_DIRE_WOLF = 'dire_wolf'
module.exports.CREATURE_DISPLACER_BEAST = 'displacer_beast'
module.exports.CREATURE_DIVINER = 'diviner'
module.exports.CREATURE_DJINNI = 'djinni'
module.exports.CREATURE_DOLGAUNT = 'dolgaunt'
module.exports.CREATURE_DOLGRIM = 'dolgrim'
module.exports.CREATURE_DOLPHIN = 'dolphin'
module.exports.CREATURE_DONKEY = 'donkey'
module.exports.CREATURE_DOPPELGANGER = 'doppelganger'
module.exports.CREATURE_DRAEGLOTH = 'draegloth'
module.exports.CREATURE_DRAFT_HORSE = 'draft_horse'
module.exports.CREATURE_DRAGONCLAW = 'dragonclaw'
module.exports.CREATURE_DRAGONFANG_BLACK = 'dragonfang_black'
module.exports.CREATURE_DRAGONFANG_BLUE = 'dragonfang_blue'
module.exports.CREATURE_DRAGONFANG_GREEN = 'dragonfang_green'
module.exports.CREATURE_DRAGONFANG_RED = 'dragonfang_red'
module.exports.CREATURE_DRAGONFANG_WHITE = 'dragonfang_white'
module.exports.CREATURE_DRAGONSOUL_BLACK = 'dragonsoul_black'
module.exports.CREATURE_DRAGONSOUL_BLUE = 'dragonsoulgblue'
module.exports.CREATURE_DRAGONSOUL_GREEN = 'dragonsoul_green'
module.exports.CREATURE_DRAGONSOUL_RED = 'dragonsoulnged'
module.exports.CREATURE_DRAGONSOUL_WHITE = 'dragonsoul_white'
module.exports.CREATURE_DRAGONWING_BLACK = 'dragonwing_black'
module.exports.CREATURE_DRAGONWING_BLUE = 'dragonfwingblue'
module.exports.CREATURE_DRAGONWING_GREEN = 'dragonwing_green'
module.exports.CREATURE_DRAGONWING_RED = 'dragonfawinged'
module.exports.CREATURE_DRAGONWING_WHITE = 'dragonwing_white'
module.exports.CREATURE_DRAGON_BLACK_ADULT = 'dragon_black_adult'
module.exports.CREATURE_DRAGON_BLACK_ANCIENT = 'dragon_black_ancient'
module.exports.CREATURE_DRAGON_BLACK_WYRMLING = 'dragon_black_wyrmling'
module.exports.CREATURE_DRAGON_BLACK_YOUNG = 'dragon_black_young'
module.exports.CREATURE_DRAGON_BLUE_ADULT = 'dragon_blue_adult'
module.exports.CREATURE_DRAGON_BLUE_ANCIENT = 'dragon_blue_ancient'
module.exports.CREATURE_DRAGON_BLUE_WYRMLING = 'dragon_blue_wyrmling'
module.exports.CREATURE_DRAGON_BLUE_YOUNG = 'dragon_blue_young'
module.exports.CREATURE_DRAGON_BRASS_ADULT = 'dragon_brass_adult'
module.exports.CREATURE_DRAGON_BRASS_ANCIENT = 'dragon_brass_ancient'
module.exports.CREATURE_DRAGON_BRASS_WYRMLING = 'dragon_brass_wyrmling'
module.exports.CREATURE_DRAGON_BRASS_YOUNG = 'dragon_brass_young'
module.exports.CREATURE_DRAGON_BRONZE_ADULT = 'dragon_bronze_adult'
module.exports.CREATURE_DRAGON_BRONZE_ANCIENT = 'dragon_bronze_ancient'
module.exports.CREATURE_DRAGON_BRONZE_WYRMLING = 'dragon_bronze_wyrmling'
module.exports.CREATURE_DRAGON_BRONZE_YOUNG = 'dragon_bronze_young'
module.exports.CREATURE_DRAGON_COPPER_ADULT = 'dragon_copper_adult'
module.exports.CREATURE_DRAGON_COPPER_ANCIENT = 'dragon_copper_ancient'
module.exports.CREATURE_DRAGON_COPPER_WYRMLING = 'dragon_copper_wyrmling'
module.exports.CREATURE_DRAGON_COPPER_YOUNG = 'dragon_copper_young'
module.exports.CREATURE_DRAGON_CULT_FANATIC = 'dragon_cult_fanatic'
module.exports.CREATURE_DRAGON_CULT_INITIATE = 'dragon_cult_initiate'
module.exports.CREATURE_DRAGON_GOLD_ADULT = 'dragon_gold_adult'
module.exports.CREATURE_DRAGON_GOLD_ANCIENT = 'dragon_gold_ancient'
module.exports.CREATURE_DRAGON_GOLD_WYRMLING = 'dragon_gold_wyrmling'
module.exports.CREATURE_DRAGON_GOLD_YOUNG = 'dragon_gold_young'
module.exports.CREATURE_DRAGON_GREEN_ADULT = 'dragon_green_adult'
module.exports.CREATURE_DRAGON_GREEN_ANCIENT = 'dragon_green_ancient'
module.exports.CREATURE_DRAGON_GREEN_WYRMLING = 'dragon_green_wyrmling'
module.exports.CREATURE_DRAGON_GREEN_YOUNG = 'dragon_green_young'
module.exports.CREATURE_DRAGON_RED_ADULT = 'dragon_red_adult'
module.exports.CREATURE_DRAGON_RED_ANCIENT = 'dragon_red_ancient'
module.exports.CREATURE_DRAGON_RED_WYRMLING = 'dragon_red_wyrmling'
module.exports.CREATURE_DRAGON_RED_YOUNG = 'dragon_red_young'
module.exports.CREATURE_DRAGON_SAPPHIRE_ADULT = 'dragon_sapphire_adult'
module.exports.CREATURE_DRAGON_SILVER_ADULT = 'dragon_silver_adult'
module.exports.CREATURE_DRAGON_SILVER_ANCIENT = 'dragon_silver_ancient'
module.exports.CREATURE_DRAGON_SILVER_WYRMLING = 'dragon_silver_wyrmling'
module.exports.CREATURE_DRAGON_SILVER_YOUNG = 'dragon_silver_young'
module.exports.CREATURE_DRAGON_TURTLE = 'dragon_turtle'
module.exports.CREATURE_DRAGON_WHITE_ADULT = 'dragon_white_adult'
module.exports.CREATURE_DRAGON_WHITE_ANCIENT = 'dragon_white_ancient'
module.exports.CREATURE_DRAGON_WHITE_WYRMLING = 'dragon_white_wyrmling'
module.exports.CREATURE_DRAGON_WHITE_YOUNG = 'dragon_white_young'
module.exports.CREATURE_DRETCH = 'dretch'
module.exports.CREATURE_DRIDER = 'drider'
module.exports.CREATURE_DRIDER_SPELLCASTER = 'drider_spellcaster'
module.exports.CREATURE_DROW = 'drow'
module.exports.CREATURE_DROW_ARACHNOMANCER = 'drow_arachnomancer'
module.exports.CREATURE_DROW_ELITE_WARRIOR = 'drow_elite_warrior'
module.exports.CREATURE_DROW_FAVORED_CONSORT = 'drow_favored_consort'
module.exports.CREATURE_DROW_GUNSLINGER = 'drow_gunslinger'
module.exports.CREATURE_DROW_HOUSE_CAPTAIN = 'drow_house_captain'
module.exports.CREATURE_DROW_INQUISITOR = 'drow_inquisitor'
module.exports.CREATURE_DROW_MAGE = 'drow_mage'
module.exports.CREATURE_DROW_MATRON_MOTHER = 'drow_matron_mother'
module.exports.CREATURE_DROW_PRIESTESS_OF_LOLTH = 'drow_priestess_of_lolth'
module.exports.CREATURE_DROW_SHADOWBLADE = 'drow_shadowblade'
module.exports.CREATURE_DRUID = 'druid'
module.exports.CREATURE_DRYAD = 'dryad'
module.exports.CREATURE_DUERGAR = 'duergar'
module.exports.CREATURE_DUERGAR_DESPOT = 'duergar_despot'
module.exports.CREATURE_DUERGAR_HAMMERER = 'duergar_hammerer'
module.exports.CREATURE_DUERGAR_KAVALRACHNI = 'duergar_kavalrachni'
module.exports.CREATURE_DUERGAR_MIND_MASTER = 'duergar_mind_master'
module.exports.CREATURE_DUERGAR_SCREAMER = 'duergar_screamer'
module.exports.CREATURE_DUERGAR_SOULBLADE = 'duergar_soulblade'
module.exports.CREATURE_DUERGAR_STONE_GUARD = 'duergar_stone_guard'
module.exports.CREATURE_DUERGAR_WARLORD = 'duergar_warlord'
module.exports.CREATURE_DUERGAR_XARRORN = 'duergar_xarrorn'
module.exports.CREATURE_DUODRONE = 'duodrone'
module.exports.CREATURE_DUST_DEVIL = 'dust_devil'
module.exports.CREATURE_DUST_MEPHIT = 'dust_mephit'
module.exports.CREATURE_DYBBUK = 'dybbuk'
module.exports.CREATURE_EAGLE = 'eagle'
module.exports.CREATURE_EARTH_ELEMENTAL = 'earth_elemental'
module.exports.CREATURE_EARTH_ELEMENTAL_SPIRIT = 'earth_elemental_spirit'
module.exports.CREATURE_EARTH_ELEMENTAL_SPIRIT_2020_03_26 = 'earth_elemental_spirit_2020_03_26'
module.exports.CREATURE_EBLIS = 'eblis'
module.exports.CREATURE_EFREETI = 'efreeti'
module.exports.CREATURE_EIDOLON = 'eidolon'
module.exports.CREATURE_ELADRIN_AUTUMN = 'eladrin_autumn'
module.exports.CREATURE_ELADRIN_SPRING = 'eladrin_spring'
module.exports.CREATURE_ELADRIN_SUMMER = 'eladrin_summer'
module.exports.CREATURE_ELADRIN_WINTER = 'eladrin_winter'
module.exports.CREATURE_ELDER_BRAIN = 'elder_brain'
module.exports.CREATURE_ELDER_TEMPEST = 'elder_tempest'
module.exports.CREATURE_ELEMENTAL_MYRMIDON_AIR = 'elemental_myrmidon_air'
module.exports.CREATURE_ELEMENTAL_MYRMIDON_EARTH = 'elemental_myrmidon_earth'
module.exports.CREATURE_ELEMENTAL_MYRMIDON_FIRE = 'elemental_myrmidon_fire'
module.exports.CREATURE_ELEMENTAL_MYRMIDON_WATER = 'elemental_myrmidon_water'
module.exports.CREATURE_ELEPHANT = 'elephant'
module.exports.CREATURE_ELIZAR_DRYFLAGON = 'elizar_dryflagon'
module.exports.CREATURE_ELK = 'elk'
module.exports.CREATURE_EMPYREAN = 'empyrean'
module.exports.CREATURE_ENCHANTER = 'enchanter'
module.exports.CREATURE_ENNUISIS = 'ennuisis'
module.exports.CREATURE_ERINYES = 'erinyes'
module.exports.CREATURE_ETERNAL_FLAME_GUARDIAN = 'eternal_flame_guardian'
module.exports.CREATURE_ETERNAL_FLAME_PRIEST = 'eternal_flame_priest'
module.exports.CREATURE_ETTERCAP = 'ettercap'
module.exports.CREATURE_ETTIN = 'ettin'
module.exports.CREATURE_EVOKER = 'evoker'
module.exports.CREATURE_EXPEDITIOUS_MESSENGER = 'expeditious_messenger'
module.exports.CREATURE_EYE_OF_FEAR_AND_FLAME = 'eye_of_fear_and_flame'
module.exports.CREATURE_FAERIE_DRAGON_BLUE = 'faerie_dragon_blue'
module.exports.CREATURE_FAERIE_DRAGON_GREEN = 'faerie_dragon_green'
module.exports.CREATURE_FAERIE_DRAGON_LIGHT_BLUE = 'faerie_dragon_light_blue'
module.exports.CREATURE_FAERIE_DRAGON_ORANGE = 'faerie_dragon_orange'
module.exports.CREATURE_FAERIE_DRAGON_RED = 'faerie_dragon_red'
module.exports.CREATURE_FAERIE_DRAGON_VIOLET = 'faerie_dragon_violet'
module.exports.CREATURE_FAERIE_DRAGON_YELLOW = 'faerie_dragon_yellow'
module.exports.CREATURE_FASTIETH = 'fastieth'
module.exports.CREATURE_FEAR_SHADOWSPAWN_SPIRIT = 'fear_shadowspawn_spirit'
module.exports.CREATURE_FEAR_SHADOW_SPIRIT_2020_03_26 = 'fear_shadow_spirit_2020_03_26'
module.exports.CREATURE_FEATHERGALE_KNIGHT = 'feathergale_knight'
module.exports.CREATURE_FIENDISH_FLESH_GOLEM = 'fiendish_flesh_golem'
module.exports.CREATURE_FIRENEWT_WARLOCK_OF_IMIX = 'firenewt_warlock_of_imix'
module.exports.CREATURE_FIRENEWT_WARRIOR = 'firenewt_warrior'
module.exports.CREATURE_FIRE_ELEMENTAL = 'fire_elemental'
module.exports.CREATURE_FIRE_ELEMENTAL_SPIRIT = 'fire_elemental_spirit'
module.exports.CREATURE_FIRE_ELEMENTAL_SPIRIT_2020_03_26 = 'fire_elemental_spirit_2020_03_26'
module.exports.CREATURE_FIRE_GIANT = 'fire_giant'
module.exports.CREATURE_FIRE_GIANT_DREADNOUGHT = 'fire_giant_dreadnought'
module.exports.CREATURE_FIRE_SNAKE = 'fire_snake'
module.exports.CREATURE_FIST_OF_BANE = 'fist_of_bane'
module.exports.CREATURE_FLAIL_SNAIL = 'flail_snail'
module.exports.CREATURE_FLAMESKULL = 'flameskull'
module.exports.CREATURE_FLAMEWRATH = 'flamewrath'
module.exports.CREATURE_FLAMING_FIST = 'flaming_fist'
module.exports.CREATURE_FLAMING_FIST_GAUNTLET = 'flaming_fist_gauntlet'
module.exports.CREATURE_FLESH_GOLEM = 'flesh_golem'
module.exports.CREATURE_FLIND = 'flind'
module.exports.CREATURE_FLUMPH = 'flumph'
module.exports.CREATURE_FLYING_MONKEY = 'flying_monkey'
module.exports.CREATURE_FLYING_SNAKE = 'flying_snake'
module.exports.CREATURE_FLYING_SWORD = 'flying_sword'
module.exports.CREATURE_FOG_GIANT = 'fog_giant'
module.exports.CREATURE_FOMORIAN = 'fomorian'
module.exports.CREATURE_FORLARREN = 'forlarren'
module.exports.CREATURE_FOX = 'fox'
module.exports.CREATURE_FRAZ_URBLUU = 'fraz_urbluu'
module.exports.CREATURE_FROG = 'frog'
module.exports.CREATURE_FROGHEMOTH = 'froghemoth'
module.exports.CREATURE_FROST_DRUID = 'frost_druid'
module.exports.CREATURE_FROST_GIANT = 'frost_giant'
module.exports.CREATURE_FROST_GIANT_EVERLASTING_ONE = 'frost_giant_everlasting_one'
module.exports.CREATURE_FROST_GIANT_SKELETON = 'frost_giant_skeleton'
module.exports.CREATURE_FROST_SALAMANDER = 'frost_salamander'
module.exports.CREATURE_FUMING_SPIRIT = 'fuming_spirit'
module.exports.CREATURE_FURIOUS_FEY_SPIRIT_2020_03_26 = 'furious_fey_spirit_2020_03_26'
module.exports.CREATURE_FURY_SHADOWSPAWN_SPIRIT = 'fury_shadowspawn_spirit'
module.exports.CREATURE_FURY_SHADOW_SPIRIT_2020_03_26 = 'fury_shadow_spirit_2020_03_26'
module.exports.CREATURE_GALEB_DUHR = 'galeb_duhr'
module.exports.CREATURE_GALVAN_MAGEN = 'galvan_magen'
module.exports.CREATURE_GARGOYLE = 'gargoyle'
module.exports.CREATURE_GAS_SPORE = 'gas_spore'
module.exports.CREATURE_GAUTH = 'gauth'
module.exports.CREATURE_GAZER = 'gazer'
module.exports.CREATURE_GELATINOUS_CUBE = 'gelatinous_cube'
module.exports.CREATURE_GEM_DRAGON_SPIRIT = 'gem_dragon_spirit'
module.exports.CREATURE_GEM_DRAGON_SPIRIT_2021_04_14 = 'gem_dragon_spirit_2021_04_14'
module.exports.CREATURE_GERYON = 'geryon'
module.exports.CREATURE_GHAST = 'ghast'
module.exports.CREATURE_GHOST = 'ghost'
module.exports.CREATURE_GHOSTLY_UNDEAD_SPIRIT = 'ghostly_undead_spirit'
module.exports.CREATURE_GHOSTLY_UNDEAD_SPIRIT_2020_03_26 = 'ghostly_undead_spirit_2020_03_26'
module.exports.CREATURE_GHOUL = 'ghoul'
module.exports.CREATURE_GIANT_APE = 'giant_ape'
module.exports.CREATURE_GIANT_BADGER = 'giant_badger'
module.exports.CREATURE_GIANT_BAT = 'giant_bat'
module.exports.CREATURE_GIANT_BOAR = 'giant_boar'
module.exports.CREATURE_GIANT_CENTIPEDE = 'giant_centipede'
module.exports.CREATURE_GIANT_CONSTRICTOR_SNAKE = 'giant_constrictor_snake'
module.exports.CREATURE_GIANT_CRAB = 'giant_crab'
module.exports.CREATURE_GIANT_CROCODILE = 'giant_crocodile'
module.exports.CREATURE_GIANT_EAGLE = 'giant_eagle'
module.exports.CREATURE_GIANT_ELK = 'giant_elk'
module.exports.CREATURE_GIANT_FIRE_BEETLE = 'giant_fire_beetle'
module.exports.CREATURE_GIANT_FLY = 'giant_fly'
module.exports.CREATURE_GIANT_FOUR_ARMED_GARGOYLE = 'giant_four_armed_gargoyle'
module.exports.CREATURE_GIANT_FROG = 'giant_frog'
module.exports.CREATURE_GIANT_GOAT = 'giant_goat'
module.exports.CREATURE_GIANT_HYENA = 'giant_hyena'
module.exports.CREATURE_GIANT_LIZARD = 'giant_lizard'
module.exports.CREATURE_GIANT_OCTOPUS = 'giant_octopus'
module.exports.CREATURE_GIANT_OWL = 'giant_owl'
module.exports.CREATURE_GIANT_POISONOUS_SNAKE = 'giant_poisonous_snake'
module.exports.CREATURE_GIANT_RAT = 'giant_rat'
module.exports.CREATURE_GIANT_SCORPION = 'giant_scorpion'
module.exports.CREATURE_GIANT_SEA_HORSE = 'giant_sea_horse'
module.exports.CREATURE_GIANT_SHARK = 'giant_shark'
module.exports.CREATURE_GIANT_SNAPPING_TURTLE = 'giant_snapping_turtle'
module.exports.CREATURE_GIANT_SPIDER = 'giant_spider'
module.exports.CREATURE_GIANT_STRIDER = 'giant_strider'
module.exports.CREATURE_GIANT_TOAD = 'giant_toad'
module.exports.CREATURE_GIANT_VULTURE = 'giant_vulture'
module.exports.CREATURE_GIANT_WASP = 'giant_wasp'
module.exports.CREATURE_GIANT_WEASEL = 'giant_weasel'
module.exports.CREATURE_GIANT_WOLF_SPIDER = 'giant_wolf_spider'
module.exports.CREATURE_GIBBERING_MOUTHER = 'gibbering_mouther'
module.exports.CREATURE_GIFF = 'giff'
module.exports.CREATURE_GIRALLON = 'girallon'
module.exports.CREATURE_GIRALLON_ZOMBIE = 'girallon_zombie'
module.exports.CREATURE_GITHYANKI_GISH = 'githyanki_gish'
module.exports.CREATURE_GITHYANKI_KITHRAK = 'githyanki_kithrak'
module.exports.CREATURE_GITHYANKI_KNIGHT = 'githyanki_knight'
module.exports.CREATURE_GITHYANKI_SUPREME_COMMANDER = 'githyanki_supreme_commander'
module.exports.CREATURE_GITHYANKI_WARRIOR = 'githyanki_warrior'
module.exports.CREATURE_GITHZERAI_ANARCH = 'githzerai_anarch'
module.exports.CREATURE_GITHZERAI_ENLIGHTENED = 'githzerai_enlightened'
module.exports.CREATURE_GITHZERAI_MONK = 'githzerai_monk'
module.exports.CREATURE_GITHZERAI_ZERTH = 'githzerai_zerth'
module.exports.CREATURE_GLABREZU = 'glabrezu'
module.exports.CREATURE_GLADIATOR = 'gladiator'
module.exports.CREATURE_GLOOM_WEAVER = 'gloom_weaver'
module.exports.CREATURE_GNOLL = 'gnoll'
module.exports.CREATURE_GNOLL_FANG_OF_YEENOGHU = 'gnoll_fang_of_yeenoghu'
module.exports.CREATURE_GNOLL_FLESH_GNAWER = 'gnoll_flesh_gnawer'
module.exports.CREATURE_GNOLL_HUNTER = 'gnoll_hunter'
module.exports.CREATURE_GNOLL_PACK_LORD = 'gnoll_pack_lord'
module.exports.CREATURE_GNOLL_WITHERLING = 'gnoll_witherling'
module.exports.CREATURE_GNOME_DEEP = 'gnome_deep'
module.exports.CREATURE_GOAT = 'goat'
module.exports.CREATURE_GOBLIN = 'goblin'
module.exports.CREATURE_GOBLIN_BOSS = 'goblin_boss'
module.exports.CREATURE_GOLD_FORGED_SENTINEL = 'gold_forged_sentinel'
module.exports.CREATURE_GOLIATH_WARRIOR = 'goliath_warrior'
module.exports.CREATURE_GOLIATH_WEREBEAR = 'goliath_werebear'
module.exports.CREATURE_GORGON = 'gorgon'
module.exports.CREATURE_GORISTRO = 'goristro'
module.exports.CREATURE_GRAY_OOZE = 'gray_ooze'
module.exports.CREATURE_GRAY_RENDER = 'gray_render'
module.exports.CREATURE_GRAZZT = 'grazzt'
module.exports.CREATURE_GREEN_HAG = 'green_hag'
module.exports.CREATURE_GRELL = 'grell'
module.exports.CREATURE_GRICK = 'grick'
module.exports.CREATURE_GRICK_ALPHA = 'grick_alpha'
module.exports.CREATURE_GRIFFON = 'griffon'
module.exports.CREATURE_GRIFFON_CAVALRY_RIDER = 'griffon_cavalry_rider'
module.exports.CREATURE_GRIMLOCK = 'grimlock'
module.exports.CREATURE_GROVE_GUARDIAN = 'grove_guardian'
module.exports.CREATURE_GRUNG = 'grung'
module.exports.CREATURE_GRUNG_ELITE_WARRIOR = 'grung_elite_warrior'
module.exports.CREATURE_GRUNG_WILDLING = 'grung_wildling'
module.exports.CREATURE_GUARD = 'guard'
module.exports.CREATURE_GUARDIAN_NAGA = 'guardian_naga'
module.exports.CREATURE_GUARD_DRAKE = 'guard_drake'
module.exports.CREATURE_GUARD_DRAKE_BLACK = 'guard_drake_black'
module.exports.CREATURE_GUARD_DRAKE_BLUE = 'guard_drake_blue'
module.exports.CREATURE_GUARD_DRAKE_GREEN = 'guard_drake_green'
module.exports.CREATURE_GUARD_DRAKE_RED = 'guard_drake_red'
module.exports.CREATURE_GUARD_DRAKE_WHITE = 'guard_drake_white'
module.exports.CREATURE_GYNOSPHINX = 'gynosphinx'
module.exports.CREATURE_HADROSAURUS = 'hadrosaurus'
module.exports.CREATURE_HALF_OGRE = 'half_ogre'
module.exports.CREATURE_HARPY = 'harpy'
module.exports.CREATURE_HAWK = 'hawk'
module.exports.CREATURE_HELLENRAE = 'hellenrae'
module.exports.CREATURE_HELLFIRE_ENGINE = 'hellfire_engine'
module.exports.CREATURE_HELL_HOUND = 'hell_hound'
module.exports.CREATURE_HELL_WASP = 'hell_wasp'
module.exports.CREATURE_HELMED_HORROR = 'helmed_horror'
module.exports.CREATURE_HEZROU = 'hezrou'
module.exports.CREATURE_HILL_GIANT = 'hill_giant'
module.exports.CREATURE_HIPPOGRIFF = 'hippogriff'
module.exports.CREATURE_HOBGOBLIN = 'hobgoblin'
module.exports.CREATURE_HOBGOBLIN_CAPTAIN = 'hobgoblin_captain'
module.exports.CREATURE_HOBGOBLIN_DEVASTATOR = 'hobgoblin_devastator'
module.exports.CREATURE_HOBGOBLIN_IRON_SHADOW = 'hobgoblin_iron_shadow'
module.exports.CREATURE_HOBGOBLIN_WARLORD = 'hobgoblin_warlord'
module.exports.CREATURE_HOLLYPHANT = 'hollyphant'
module.exports.CREATURE_HOMUNCULUS = 'homunculus'
module.exports.CREATURE_HOMUNCULUS_SERVANT = 'homunculus_servant'
module.exports.CREATURE_HOOK_HORROR = 'hook_horror'
module.exports.CREATURE_HORNED_DEVIL = 'horned_devil'
module.exports.CREATURE_HOUND_OF_ILL_OMEN = 'hound_of_ill_omen'
module.exports.CREATURE_HOWLER = 'howler'
module.exports.CREATURE_HOWLING_HATRED_INITIATE = 'howling_hatred_initiate'
module.exports.CREATURE_HOWLING_HATRED_PRIEST = 'howling_hatred_priest'
module.exports.CREATURE_HULKING_CRAB = 'hulking_crab'
module.exports.CREATURE_HUNTER_SHARK = 'hunter_shark'
module.exports.CREATURE_HURRICANE = 'hurricane'
module.exports.CREATURE_HUTIJIN = 'hutijin'
module.exports.CREATURE_HYDRA = 'hydra'
module.exports.CREATURE_HYDROLOTH = 'hydroloth'
module.exports.CREATURE_HYENA = 'hyena'
module.exports.CREATURE_HYPNOS_MAGEN = 'hypnos_magen'
module.exports.CREATURE_ICE_DEVIL = 'ice_devil'
module.exports.CREATURE_ICE_MEPHIT = 'ice_mephit'
module.exports.CREATURE_ILLITHILICH = 'illithilich'
module.exports.CREATURE_ILLUSIONIST = 'illusionist'
module.exports.CREATURE_IMP = 'imp'
module.exports.CREATURE_INCUBUS = 'incubus'
module.exports.CREATURE_INTELLECT_DEVOURER = 'intellect_devourer'
module.exports.CREATURE_INVISIBLE_STALKER = 'invisible_stalker'
module.exports.CREATURE_IRON_COBRA = 'iron_cobra'
module.exports.CREATURE_IRON_CONSUL = 'iron_consul'
module.exports.CREATURE_IRON_DEFENDER = 'iron_defender'
module.exports.CREATURE_IRON_GOLEM = 'iron_golem'
module.exports.CREATURE_JACKAL = 'jackal'
module.exports.CREATURE_JACKALWERE = 'jackalwere'
module.exports.CREATURE_JACULI = 'jaculi'
module.exports.CREATURE_JERMLAINE = 'jermlaine'
module.exports.CREATURE_JOYFUL_FEY_SPIRIT_2020_03_26 = 'joyful_fey_spirit_2020_03_26'
module.exports.CREATURE_JUIBLEX = 'juiblex'
module.exports.CREATURE_JUVENILE_MIMIC = 'juvenile_mimic'
module.exports.CREATURE_KAMADAN = 'kamadan'
module.exports.CREATURE_KEG_ROBOT = 'keg_robot'
module.exports.CREATURE_KENKU = 'kenku'
module.exports.CREATURE_KHARGRA = 'khargra'
module.exports.CREATURE_KILLER_WHALE = 'killer_whale'
module.exports.CREATURE_KILLMOULIS = 'killmoulis'
module.exports.CREATURE_KI_RIN = 'ki_rin'
module.exports.CREATURE_KNIGHT = 'knight'
module.exports.CREATURE_KOBOLD = 'kobold'
module.exports.CREATURE_KOBOLD_DRAGONSHIELD_BLACK = 'kobold_dragonshield_black'
module.exports.CREATURE_KOBOLD_DRAGONSHIELD_BLUE = 'kobold_dragonshield_blue'
module.exports.CREATURE_KOBOLD_DRAGONSHIELD_GREEN = 'kobold_dragonshield_green'
module.exports.CREATURE_KOBOLD_DRAGONSHIELD_RED = 'kobold_dragonshield_red'
module.exports.CREATURE_KOBOLD_DRAGONSHIELD_WHITE = 'kobold_dragonshield_white'
module.exports.CREATURE_KOBOLD_INVENTOR = 'kobold_inventor'
module.exports.CREATURE_KOBOLD_SCALE_SORCERER = 'kobold_scale_sorcerer'
module.exports.CREATURE_KORRED = 'korred'
module.exports.CREATURE_KOSTCHTCHIE = 'kostchtchie'
module.exports.CREATURE_KRAKEN = 'kraken'
module.exports.CREATURE_KRAKEN_PRIEST = 'kraken_priest'
module.exports.CREATURE_KRUTHIK_ADULT = 'kruthik_adult'
module.exports.CREATURE_KRUTHIK_HIVE_LORD = 'kruthik_hive_lord'
module.exports.CREATURE_KRUTHIK_YOUNG = 'kruthik_young'
module.exports.CREATURE_KUO_TOA = 'kuo_toa'
module.exports.CREATURE_KUO_TOA_ARCHPRIEST = 'kuo_toa_archpriest'
module.exports.CREATURE_KUO_TOA_MONITOR = 'kuo_toa_monitor'
module.exports.CREATURE_KUO_TOA_WHIP = 'kuo_toa_whip'
module.exports.CREATURE_LAMIA = 'lamia'
module.exports.CREATURE_LAND_BEAST_SPIRIT = 'land_beast_spirit'
module.exports.CREATURE_LAND_BESTIAL_SPIRIT_2020_03_26 = 'land_bestial_spirit_2020_03_26'
module.exports.CREATURE_LARVA = 'larva'
module.exports.CREATURE_LEMURE = 'lemure'
module.exports.CREATURE_LEUCROTTA = 'leucrotta'
module.exports.CREATURE_LEVIATHAN = 'leviathan'
module.exports.CREATURE_LIARA_PORTYR = 'liara_portyr'
module.exports.CREATURE_LICH = 'lich'
module.exports.CREATURE_LION = 'lion'
module.exports.CREATURE_LIZARD = 'lizard'
module.exports.CREATURE_LIZARDFOLK = 'lizardfolk'
module.exports.CREATURE_LIZARDFOLK_KING_QUEEN = 'lizardfolk_king_queen'
module.exports.CREATURE_LIZARDFOLK_SHAMAN = 'lizardfolk_shaman'
module.exports.CREATURE_MAEGERA_THE_DAWN_TITAN = 'maegera_the_dawn_titan'
module.exports.CREATURE_MAGE = 'mage'
module.exports.CREATURE_MAGMA_MEPHIT = 'magma_mephit'
module.exports.CREATURE_MAGMIN = 'magmin'
module.exports.CREATURE_MAMMOTH = 'mammoth'
module.exports.CREATURE_MANES = 'manes'
module.exports.CREATURE_MANTICORE = 'manticore'
module.exports.CREATURE_MANTRAP = 'mantrap'
module.exports.CREATURE_MARID = 'marid'
module.exports.CREATURE_MARILITH = 'marilith'
module.exports.CREATURE_MARLOS_URNRAYLE = 'marlos_urnrayle'
module.exports.CREATURE_MARTIAL_ARTS_ADEPT = 'martial_arts_adept'
module.exports.CREATURE_MARUT = 'marut'
module.exports.CREATURE_MASTER_OF_SOULS = 'master_of_souls'
module.exports.CREATURE_MASTER_THIEF = 'master_thief'
module.exports.CREATURE_MASTIFF = 'mastiff'
module.exports.CREATURE_MAUREZHI = 'maurezhi'
module.exports.CREATURE_MAW_DEMON = 'maw_demon'
module.exports.CREATURE_MEAZEL = 'meazel'
module.exports.CREATURE_MEDUSA = 'medusa'
module.exports.CREATURE_MEENLOCK = 'meenlock'
module.exports.CREATURE_MERFOLK = 'merfolk'
module.exports.CREATURE_MERREGON = 'merregon'
module.exports.CREATURE_MERRENOLOTH = 'merrenoloth'
module.exports.CREATURE_MERROW = 'merrow'
module.exports.CREATURE_METALLIC_DRAGON_SPIRIT = 'metallic_dragon_spirit'
module.exports.CREATURE_METALLIC_DRAGON_SPIRIT_2021_04_14 = 'metallic_dragon_spirit_2021_04_14'
module.exports.CREATURE_METAL_CONSTRUCT_SPIRIT = 'metal_construct_spirit'
module.exports.CREATURE_MEZZOLOTH = 'mezzoloth'
module.exports.CREATURE_MIMIC = 'mimic'
module.exports.CREATURE_MINDWITNESS = 'mindwitness'
module.exports.CREATURE_MIND_FLAYER = 'mind_flayer'
module.exports.CREATURE_MINOTAUR = 'minotaur'
module.exports.CREATURE_MINOTAUR_SKELETON = 'minotaur_skeleton'
module.exports.CREATURE_MIRAJ_VIZANN = 'miraj_vizann'
module.exports.CREATURE_MIRTHFUL_SPIRIT = 'mirthful_spirit'
module.exports.CREATURE_MITE = 'mite'
module.exports.CREATURE_MOLOCH = 'moloch'
module.exports.CREATURE_MOLYDEUS = 'molydeus'
module.exports.CREATURE_MONODRONE = 'monodrone'
module.exports.CREATURE_MORKOTH = 'morkoth'
module.exports.CREATURE_MOUTH_OF_GROLANTOR = 'mouth_of_grolantor'
module.exports.CREATURE_MUD_MEPHIT = 'mud_mephit'
module.exports.CREATURE_MULE = 'mule'
module.exports.CREATURE_MUMMY = 'mummy'
module.exports.CREATURE_MUMMY_LORD = 'mummy_lord'
module.exports.CREATURE_MYCONID_ADULT = 'myconid_adult'
module.exports.CREATURE_MYCONID_SOVEREIGN = 'myconid_sovereign'
module.exports.CREATURE_MYCONID_SPROUT = 'myconid_sprout'
module.exports.CREATURE_NABASSU = 'nabassu'
module.exports.CREATURE_NAGPA = 'nagpa'
module.exports.CREATURE_NALFESHNEE = 'nalfeshnee'
module.exports.CREATURE_NARZUGON = 'narzugon'
module.exports.CREATURE_NECROMANCER = 'necromancer'
module.exports.CREATURE_NECROMITE_OF_MYRKUL = 'necromite_of_myrkul'
module.exports.CREATURE_NEEDLE_BLIGHT = 'needle_blight'
module.exports.CREATURE_NEEDLE_LORD = 'needle_lord'
module.exports.CREATURE_NEEDLE_SPAWN = 'needle_spawn'
module.exports.CREATURE_NEOGI = 'neogi'
module.exports.CREATURE_NEOGI_HATCHLING = 'neogi_hatchling'
module.exports.CREATURE_NEOGI_MASTER = 'neogi_master'
module.exports.CREATURE_NEOTHELID = 'neothelid'
module.exports.CREATURE_NIGHTMARE = 'nightmare'
module.exports.CREATURE_NIGHTWALKER = 'nightwalker'
module.exports.CREATURE_NIGHT_BLADE = 'night_blade'
module.exports.CREATURE_NIGHT_HAG = 'night_hag'
module.exports.CREATURE_NILBOG = 'nilbog'
module.exports.CREATURE_NIMBLEWRIGHT = 'nimblewright'
module.exports.CREATURE_NOBLE = 'noble'
module.exports.CREATURE_NORKER = 'norker'
module.exports.CREATURE_NORKER_WAR_LEADER = 'norker_war_leader'
module.exports.CREATURE_NOTHIC = 'nothic'
module.exports.CREATURE_NUPPERIBO = 'nupperibo'
module.exports.CREATURE_NYCALOTH = 'nycaloth'
module.exports.CREATURE_OAKEN_BOLTER = 'oaken_bolter'
module.exports.CREATURE_OBLEX_ADULT = 'oblex_adult'
module.exports.CREATURE_OBLEX_ELDER = 'oblex_elder'
module.exports.CREATURE_OBLEX_SPAWN = 'oblex_spawn'
module.exports.CREATURE_OCHRE_JELLY = 'ochre_jelly'
module.exports.CREATURE_OCTOPUS = 'octopus'
module.exports.CREATURE_OGRE = 'ogre'
module.exports.CREATURE_OGRE_BATTERING_RAM = 'ogre_battering_ram'
module.exports.CREATURE_OGRE_BOLT_LAUNCHER = 'ogre_bolt_launcher'
module.exports.CREATURE_OGRE_CHAIN_BRUTE = 'ogre_chain_brute'
module.exports.CREATURE_OGRE_GOBLIN_HUCKER = 'ogre_goblin_hucker'
module.exports.CREATURE_OGRE_HOWDAH = 'ogre_howdah'
module.exports.CREATURE_OGRE_ZOMBIE = 'ogre_zombie'
module.exports.CREATURE_OINOLOTH = 'oinoloth'
module.exports.CREATURE_ONI = 'oni'
module.exports.CREATURE_ORC = 'orc'
module.exports.CREATURE_ORCUS = 'orcus'
module.exports.CREATURE_ORC_BLADE_OF_ILNEVAL = 'orc_blade_of_ilneval'
module.exports.CREATURE_ORC_CLAW_OF_LUTHIC = 'orc_claw_of_luthic'
module.exports.CREATURE_ORC_EYE_OF_GRUUMSH = 'orc_eye_of_gruumsh'
module.exports.CREATURE_ORC_HAND_OF_YURTRUS = 'orc_hand_of_yurtrus'
module.exports.CREATURE_ORC_NURTURED_ONE_OF_YURTRUS = 'orc_nurtured_one_of_yurtrus'
module.exports.CREATURE_ORC_RED_FANG_OF_SHARGAAS = 'orc_red_fang_of_shargaas'
module.exports.CREATURE_ORC_WAR_CHIEF = 'orc_war_chief'
module.exports.CREATURE_OROG = 'orog'
module.exports.CREATURE_ORTHON = 'orthon'
module.exports.CREATURE_OTYUGH = 'otyugh'
module.exports.CREATURE_OWL = 'owl'
module.exports.CREATURE_OWLBEAR = 'owlbear'
module.exports.CREATURE_OX = 'ox'
module.exports.CREATURE_PANTHER = 'panther'
module.exports.CREATURE_PEGASUS = 'pegasus'
module.exports.CREATURE_PENTADRONE = 'pentadrone'
module.exports.CREATURE_PERYTON = 'peryton'
module.exports.CREATURE_PHASE_SPIDER = 'phase_spider'
module.exports.CREATURE_PHOENIX = 'phoenix'
module.exports.CREATURE_PIERCER = 'piercer'
module.exports.CREATURE_PIRATE = 'pirate'
module.exports.CREATURE_PIT_FIEND = 'pit_fiend'
module.exports.CREATURE_PIXIE = 'pixie'
module.exports.CREATURE_PLANETAR = 'planetar'
module.exports.CREATURE_PLESIOSAURUS = 'plesiosaurus'
module.exports.CREATURE_POISONOUS_SNAKE = 'poisonous_snake'
module.exports.CREATURE_POLAR_BEAR = 'polar_bear'
module.exports.CREATURE_POLTERGEIST = 'poltergeist'
module.exports.CREATURE_PONY = 'pony'
module.exports.CREATURE_PRIEST = 'priest'
module.exports.CREATURE_PSEUDODRAGON = 'pseudodragon'
module.exports.CREATURE_PSYCHIC_GRAY_OOZE = 'psychic_gray_ooze'
module.exports.CREATURE_PTERAFOLK = 'pterafolk'
module.exports.CREATURE_PTERANODON = 'pteranodon'
module.exports.CREATURE_PURPLE_WORM = 'purple_worm'
module.exports.CREATURE_PURPLE_WORMLING = 'purple_wormling'
module.exports.CREATURE_PUTRID_UNDEAD_SPIRIT = 'putrid_undead_spirit'
module.exports.CREATURE_PUTRID_UNDEAD_SPIRIT_2020_03_26 = 'putrid_undead_spirit_2020_03_26'
module.exports.CREATURE_QUADRONE = 'quadrone'
module.exports.CREATURE_QUAGGOTH = 'quaggoth'
module.exports.CREATURE_QUASIT = 'quasit'
module.exports.CREATURE_QUETZALCOATLUS = 'quetzalcoatlus'
module.exports.CREATURE_QUICKLING = 'quickling'
module.exports.CREATURE_QUIPPER = 'quipper'
module.exports.CREATURE_RAKSHASA = 'rakshasa'
module.exports.CREATURE_RAT = 'rat'
module.exports.CREATURE_RAVEN = 'raven'
module.exports.CREATURE_RAZERBLAST = 'razerblast'
module.exports.CREATURE_REAPER_OF_BHAAL = 'reaper_of_bhaal'
module.exports.CREATURE_REDCAP = 'redcap'
module.exports.CREATURE_REEF_SHARK = 'reef_shark'
module.exports.CREATURE_REFLECTION = 'reflection'
module.exports.CREATURE_REINDEER = 'reindeer'
module.exports.CREATURE_REMORHAZ = 'remorhaz'
module.exports.CREATURE_RETRIEVER = 'retriever'
module.exports.CREATURE_REVENANT = 'revenant'
module.exports.CREATURE_RHINOCEROS = 'rhinoceros'
module.exports.CREATURE_RIDING_HORSE = 'riding_horse'
module.exports.CREATURE_ROC = 'roc'
module.exports.CREATURE_ROPER = 'roper'
module.exports.CREATURE_ROTHE = 'rothe'
module.exports.CREATURE_ROT_TROLL = 'rot_troll'
module.exports.CREATURE_RUG_OF_SMOTHERING = 'rug_of_smothering'
module.exports.CREATURE_RUST_MONSTER = 'rust_monster'
module.exports.CREATURE_RUTTERKIN = 'rutterkin'
module.exports.CREATURE_SABER_TOOTHED_TIGER = 'saber_toothed_tiger'
module.exports.CREATURE_SACRED_STONE_MONK = 'sacred_stone_monk'
module.exports.CREATURE_SAHUAGIN = 'sahuagin'
module.exports.CREATURE_SAHUAGIN_BARON = 'sahuagin_baron'
module.exports.CREATURE_SAHUAGIN_PRIESTESS = 'sahuagin_priestess'
module.exports.CREATURE_SALAMANDER = 'salamander'
module.exports.CREATURE_SATYR = 'satyr'
module.exports.CREATURE_SCARECROW = 'scarecrow'
module.exports.CREATURE_SCORPION = 'scorpion'
module.exports.CREATURE_SCOUT = 'scout'
module.exports.CREATURE_SCRAG = 'scrag'
module.exports.CREATURE_SCREAMING_DEVILKIN = 'screaming_devilkin'
module.exports.CREATURE_SEA_HAG = 'sea_hag'
module.exports.CREATURE_SEA_HORSE = 'sea_horse'
module.exports.CREATURE_SEA_SPAWN = 'sea_spawn'
module.exports.CREATURE_SHADOW = 'shadow'
module.exports.CREATURE_SHADOW_DANCER = 'shadow_dancer'
module.exports.CREATURE_SHADOW_DEMON = 'shadow_demon'
module.exports.CREATURE_SHADOW_MASTIFF = 'shadow_mastiff'
module.exports.CREATURE_SHADOW_MASTIFF_ALPHA = 'shadow_mastiff_alpha'
module.exports.CREATURE_SHAMBLING_MOUND = 'shambling_mound'
module.exports.CREATURE_SHIELD_GUARDIAN = 'shield_guardian'
module.exports.CREATURE_SHOOSUVA = 'shoosuva'
module.exports.CREATURE_SHRIEKER = 'shrieker'
module.exports.CREATURE_SIBRIEX = 'sibriex'
module.exports.CREATURE_SKELETAL_UNDEAD_SPIRIT = 'skeletal_undead_spirit'
module.exports.CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26 = 'skeletal_undead_spirit_2020_03_26'
module.exports.CREATURE_SKELETON = 'skeleton'
module.exports.CREATURE_SKULK = 'skulk'
module.exports.CREATURE_SKULL_LASHER_OF_MYRKUL = 'skull_lasher_of_myrkul'
module.exports.CREATURE_SKULL_LORD = 'skull_lord'
module.exports.CREATURE_SKUNK = 'skunk'
module.exports.CREATURE_SKYWEAVER = 'skyweaver'
module.exports.CREATURE_SLAADI_SPIRIT = 'slaadi_spirit'
module.exports.CREATURE_SLAADI_SPIRIT_2020_03_26 = 'slaadi_spirit_2020_03_26'
module.exports.CREATURE_SLAAD_BLUE = 'slaad_blue'
module.exports.CREATURE_SLAAD_DEATH = 'slaad_death'
module.exports.CREATURE_SLAAD_GRAY = 'slaad_gray'
module.exports.CREATURE_SLAAD_GREEN = 'slaad_green'
module.exports.CREATURE_SLAAD_RED = 'slaad_red'
module.exports.CREATURE_SLAAD_TADPOLE = 'slaad_tadpole'
module.exports.CREATURE_SLITHERING_TRACKER = 'slithering_tracker'
module.exports.CREATURE_SMOKE_MEPHIT = 'smoke_mephit'
module.exports.CREATURE_SOLAR = 'solar'
module.exports.CREATURE_SOUL_MONGER = 'soul_monger'
module.exports.CREATURE_SPAWN_OF_KYUSS = 'spawn_of_kyuss'
module.exports.CREATURE_SPECTATOR = 'spectator'
module.exports.CREATURE_SPECTER = 'specter'
module.exports.CREATURE_SPIDER = 'spider'
module.exports.CREATURE_SPINED_DEVIL = 'spined_devil'
module.exports.CREATURE_SPIRIT_NAGA = 'spirit_naga'
module.exports.CREATURE_SPIRIT_TROLL = 'spirit_troll'
module.exports.CREATURE_SPRITE = 'sprite'
module.exports.CREATURE_SPY = 'spy'
module.exports.CREATURE_STAR_SPAWN_GRUE = 'star_spawn_grue'
module.exports.CREATURE_STAR_SPAWN_HULK = 'star_spawn_hulk'
module.exports.CREATURE_STAR_SPAWN_LARVA_MAGE = 'star_spawn_larva_mage'
module.exports.CREATURE_STAR_SPAWN_MANGLER = 'star_spawn_mangler'
module.exports.CREATURE_STAR_SPAWN_SEER = 'star_spawn_seer'
module.exports.CREATURE_STAR_SPAWN_SPIRIT = 'star_spawn_spirit'
module.exports.CREATURE_STAR_SPAWN_SPIRIT_2020_03_26 = 'star_spawn_spirit_2020_03_26'
module.exports.CREATURE_STEAM_MEPHIT = 'steam_mephit'
module.exports.CREATURE_STEEDER_FEMALE = 'steeder_female'
module.exports.CREATURE_STEEDER_MALE = 'steeder_male'
module.exports.CREATURE_STEEL_DEFENDER = 'steel_defender'
module.exports.CREATURE_STEEL_PREDATOR = 'steel_predator'
module.exports.CREATURE_STEGOSAURUS = 'stegosaurus'
module.exports.CREATURE_STENCH_KAW = 'stench_kaw'
module.exports.CREATURE_STIRGE = 'stirge'
module.exports.CREATURE_STONEMELDER = 'stonemelder'
module.exports.CREATURE_STONE_CONSTRUCT_SPIRIT = 'stone_construct_spirit'
module.exports.CREATURE_STONE_CURSED = 'stone_cursed'
module.exports.CREATURE_STONE_DEFENDER = 'stone_defender'
module.exports.CREATURE_STONE_GIANT = 'stone_giant'
module.exports.CREATURE_STONE_GIANT_DREAMWALKER = 'stone_giant_dreamwalker'
module.exports.CREATURE_STONE_GOLEM = 'stone_golem'
module.exports.CREATURE_STONE_JUGGERNAUT = 'stone_juggernaut'
module.exports.CREATURE_STORM_GIANT = 'storm_giant'
module.exports.CREATURE_STORM_GIANT_QUINTESSENT = 'storm_giant_quintessent'
module.exports.CREATURE_SUCCUBUS = 'succubus'
module.exports.CREATURE_SU_MONSTER = 'su_monster'
module.exports.CREATURE_SWARM_OF_BATS = 'swarm_of_bats'
module.exports.CREATURE_SWARM_OF_BUGS = 'swarm_of_bugs'
module.exports.CREATURE_SWARM_OF_CENTIPEDES = 'swarm_of_centipedes'
module.exports.CREATURE_SWARM_OF_CRANIUM_RATS = 'swarm_of_cranium_rats'
module.exports.CREATURE_SWARM_OF_INSECTS = 'swarm_of_insects'
module.exports.CREATURE_SWARM_OF_POISONOUS_SNAKES = 'swarm_of_poisonous_snakes'
module.exports.CREATURE_SWARM_OF_QUIPPERS = 'swarm_of_quippers'
module.exports.CREATURE_SWARM_OF_RATS = 'swarm_of_rats'
module.exports.CREATURE_SWARM_OF_RAVENS = 'swarm_of_ravens'
module.exports.CREATURE_SWARM_OF_ROT_GRUBS = 'swarm_of_rot_grubs'
module.exports.CREATURE_SWARM_OF_SPIDERS = 'swarm_of_spiders'
module.exports.CREATURE_SWARM_OF_WASPS = 'swarm_of_wasps'
module.exports.CREATURE_SWASHBUCKLER = 'swashbuckler'
module.exports.CREATURE_SWORD_WRAITH_COMMANDER = 'sword_wraith_commander'
module.exports.CREATURE_SWORD_WRAITH_WARRIOR = 'sword_wraith_warrior'
module.exports.CREATURE_TABAXI_HUNTER = 'tabaxi_hunter'
module.exports.CREATURE_TABAXI_MINSTREL = 'tabaxi_minstrel'
module.exports.CREATURE_TANARUKK = 'tanarukk'
module.exports.CREATURE_TARRASQUE = 'tarrasque'
module.exports.CREATURE_THE_ANGRY = 'the_angry'
module.exports.CREATURE_THE_HUNGRY = 'the_hungry'
module.exports.CREATURE_THE_LONELY = 'the_lonely'
module.exports.CREATURE_THE_LOST = 'the_lost'
module.exports.CREATURE_THE_WRETCHED = 'the_wretched'
module.exports.CREATURE_THORNY = 'thorny'
module.exports.CREATURE_THRI_KREEN = 'thri_kreen'
module.exports.CREATURE_THUG = 'thug'
module.exports.CREATURE_THURL_MEROSSKA = 'thurl_merosska'
module.exports.CREATURE_TIAMAT = 'tiamat'
module.exports.CREATURE_TIGER = 'tiger'
module.exports.CREATURE_TINY_SERVANT = 'tiny_servant'
module.exports.CREATURE_TITIVILUS = 'titivilus'
module.exports.CREATURE_TLINCALLI = 'tlincalli'
module.exports.CREATURE_TOAD = 'toad'
module.exports.CREATURE_TORTLE = 'tortle'
module.exports.CREATURE_TORTLE_DRUID = 'tortle_druid'
module.exports.CREATURE_TRANSMUTER = 'transmuter'
module.exports.CREATURE_TRAPPER = 'trapper'
module.exports.CREATURE_TREANT = 'treant'
module.exports.CREATURE_TRESSYM = 'tressym'
module.exports.CREATURE_TRIBAL_WARRIOR = 'tribal_warrior'
module.exports.CREATURE_TRICERATOPS = 'triceratops'
module.exports.CREATURE_TRICKSY_SPIRIT = 'tricksy_spirit'
module.exports.CREATURE_TRIDRONE = 'tridrone'
module.exports.CREATURE_TRI_FLOWER_FROND = 'tri_flower_frond'
module.exports.CREATURE_TROGLODYTE = 'troglodyte'
module.exports.CREATURE_TROLL = 'troll'
module.exports.CREATURE_TWIG_BLIGHT = 'twig_blight'
module.exports.CREATURE_TWO_HEADED_CERBERUS = 'two_headed_cerberus'
module.exports.CREATURE_TYRANNOSAURUS_REX = 'tyrannosaurus_rex'
module.exports.CREATURE_TYRANNOSAURUS_ZOMBIE = 'tyrannosaurus_zombie'
module.exports.CREATURE_ULITHARID = 'ulitharid'
module.exports.CREATURE_ULTROLOTH = 'ultroloth'
module.exports.CREATURE_UMBER_HULK = 'umber_hulk'
module.exports.CREATURE_UNICORN = 'unicorn'
module.exports.CREATURE_UTHGARDT_SHAMAN = 'uthgardt_shaman'
module.exports.CREATURE_VAJRA_SAFAHR = 'vajra_safahr'
module.exports.CREATURE_VAMPIRE = 'vampire'
module.exports.CREATURE_VAMPIRE_SPAWN = 'vampire_spawn'
module.exports.CREATURE_VAMPIRIC_MIST = 'vampiric_mist'
module.exports.CREATURE_VANIFER = 'vanifer'
module.exports.CREATURE_VARGOUILLE = 'vargouille'
module.exports.CREATURE_VEGEPYGMY = 'vegepygmy'
module.exports.CREATURE_VEGEPYGMY_CHIEF = 'vegepygmy_chief'
module.exports.CREATURE_VELOCIRAPTOR = 'velociraptor'
module.exports.CREATURE_VENOM_TROLL = 'venom_troll'
module.exports.CREATURE_VETERAN = 'veteran'
module.exports.CREATURE_VINE_BLIGHT = 'vine_blight'
module.exports.CREATURE_VIOLET_FUNGUS = 'violet_fungus'
module.exports.CREATURE_VROCK = 'vrock'
module.exports.CREATURE_VULTURE = 'vulture'
module.exports.CREATURE_WALKING_STATUE_OF_WATERDEEP = 'walking_statue_of_waterdeep'
module.exports.CREATURE_WARHORSE = 'warhorse'
module.exports.CREATURE_WARHORSE_SKELETON = 'warhorse_skeleton'
module.exports.CREATURE_WARLOCK_OF_THE_ARCHFEY = 'warlock_of_the_archfey'
module.exports.CREATURE_WARLOCK_OF_THE_FIEND = 'warlock_of_the_fiend'
module.exports.CREATURE_WARLOCK_OF_THE_GREAT_OLD_ONE = 'warlock_of_the_great_old_one'
module.exports.CREATURE_WARLORD = 'warlord'
module.exports.CREATURE_WAR_PRIEST = 'war_priest'
module.exports.CREATURE_WASTRILITH = 'wastrilith'
module.exports.CREATURE_WATERDEEP_S_CITY_GUARD_ARMAR = 'waterdeep_s_city_guard_armar'
module.exports.CREATURE_WATERDEEP_S_CITY_GUARD_CIVILAR = 'waterdeep_s_city_guard_civilar'
module.exports.CREATURE_WATERDEEP_S_CITY_GUARD_PRIVATE = 'waterdeep_s_city_guard_private'
module.exports.CREATURE_WATERDEEP_S_CITY_GUARD_SENIOR_CIVILAR = 'waterdeep_s_city_guard_senior_civilar'
module.exports.CREATURE_WATER_BEAST_SPIRIT = 'water_beast_spirit'
module.exports.CREATURE_WATER_BESTIAL_SPIRIT_2020_03_26 = 'water_bestial_spirit_2020_03_26'
module.exports.CREATURE_WATER_ELEMENTAL = 'water_elemental'
module.exports.CREATURE_WATER_ELEMENTAL_SPIRIT = 'water_elemental_spirit'
module.exports.CREATURE_WATER_ELEMENTAL_SPIRIT_2020_03_26 = 'water_elemental_spirit_2020_03_26'
module.exports.CREATURE_WATER_WEIRD = 'water_weird'
module.exports.CREATURE_WEASEL = 'weasel'
module.exports.CREATURE_WEREBEAR = 'werebear'
module.exports.CREATURE_WEREBOAR = 'wereboar'
module.exports.CREATURE_WERERAT = 'wererat'
module.exports.CREATURE_WERETIGER = 'weretiger'
module.exports.CREATURE_WEREWOLF = 'werewolf'
module.exports.CREATURE_WIGHT = 'wight'
module.exports.CREATURE_WILDFIRE_SPIRIT = 'wildfire_spirit'
module.exports.CREATURE_WILL_O_WISP = 'will_o_wisp'
module.exports.CREATURE_WINDHARROW = 'windharrow'
module.exports.CREATURE_WINGED_BULL = 'winged_bull'
module.exports.CREATURE_WINGED_KOBOLD = 'winged_kobold'
module.exports.CREATURE_WINGED_LION = 'winged_lion'
module.exports.CREATURE_WINTER_WOLF = 'winter_wolf'
module.exports.CREATURE_WOLF = 'wolf'
module.exports.CREATURE_WOOD_WOAD = 'wood_woad'
module.exports.CREATURE_WORG = 'worg'
module.exports.CREATURE_WRAITH = 'wraith'
module.exports.CREATURE_WYVERN = 'wyvern'
module.exports.CREATURE_XILL = 'xill'
module.exports.CREATURE_XORN = 'xorn'
module.exports.CREATURE_XVART = 'xvart'
module.exports.CREATURE_XVART_SPEAKER = 'xvart_speaker'
module.exports.CREATURE_XVART_WARLOCK_OF_RAXIVORT = 'xvart_warlock_of_raxivort'
module.exports.CREATURE_YAGNOLOTH = 'yagnoloth'
module.exports.CREATURE_YAKFOLK_PRIEST = 'yakfolk_priest'
module.exports.CREATURE_YAKFOLK_WARRIOR = 'yakfolk_warrior'
module.exports.CREATURE_YEENOGHU = 'yeenoghu'
module.exports.CREATURE_YELLOW_MUSK_CREEPER = 'yellow_musk_creeper'
module.exports.CREATURE_YELLOW_MUSK_ZOMBIE = 'yellow_musk_zombie'
module.exports.CREATURE_YETH_HOUND = 'yeth_hound'
module.exports.CREATURE_YETI = 'yeti'
module.exports.CREATURE_YGORL_LORD_OF_ENTROPY = 'ygorl_lord_of_entropy'
module.exports.CREATURE_YOCHLOL = 'yochlol'
module.exports.CREATURE_YOUNG_REMORHAZ = 'young_remorhaz'
module.exports.CREATURE_YUAN_TI_ABOMINATION = 'yuan_ti_abomination'
module.exports.CREATURE_YUAN_TI_ANATHEMA = 'yuan_ti_anathema'
module.exports.CREATURE_YUAN_TI_BROODGUARD = 'yuan_ti_broodguard'
module.exports.CREATURE_YUAN_TI_MALISON_1 = 'yuan_ti_malison_1'
module.exports.CREATURE_YUAN_TI_MALISON_2 = 'yuan_ti_malison_2'
module.exports.CREATURE_YUAN_TI_MALISON_3 = 'yuan_ti_malison_3'
module.exports.CREATURE_YUAN_TI_MIND_WHISPERER = 'yuan_ti_mind_whisperer'
module.exports.CREATURE_YUAN_TI_NIGHTMARE_SPEAKER = 'yuan_ti_nightmare_speaker'
module.exports.CREATURE_YUAN_TI_PIT_MASTER = 'yuan_ti_pit_master'
module.exports.CREATURE_YUAN_TI_PUREBLOOD = 'yuan_ti_pureblood'
module.exports.CREATURE_YUGOLOTH_SPIRIT = 'yugoloth_spirit'
module.exports.CREATURE_YUGOLOTH_SPIRIT_2020_03_26 = 'yugoloth_spirit_2020_03_26'
module.exports.CREATURE_ZARATAN = 'zaratan'
module.exports.CREATURE_ZARIEL = 'zariel'
module.exports.CREATURE_ZOMBIE = 'zombie'
module.exports.CREATURE_ZORBO = 'zorbo'
module.exports.CREATURE_ZUGGTMOY = 'zuggtmoy'
