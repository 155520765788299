const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_CHAIN_SHIRT,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  CREATURE_GUARD,
  CREATURE_NOBLE,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_1_8} = require('./../../../../crList')
const {GEAR_SPEAR} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Страж',
  nameAlt: 'Стражник',
  nameEn: 'Guard',
  id: CREATURE_GUARD,
  description: `Стражем может быть представитель городской стражи, караульный в цитадели или укреплённом городе, а также телохранитель торговца или [дворянина](CREATURE:${CREATURE_NOBLE}).`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 350,
  },
  armor: {
    ac: 16,
    gearId: [GEAR_CHAIN_SHIRT, GEAR_SHIELD],
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_1_8,
  actionList: [
    {
      gearId: GEAR_SPEAR,
    },
  ],
  genderId: GENDER_MALE,
}
