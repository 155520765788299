const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_YUAN_TI_MALISON_1,
  CREATURE_YUAN_TI_MALISON_2,
} = require('./../../../../creatureIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Юань-ти проклинатель, вид 2',
  nameEn: 'Yuan-ti Malison, Type 2',
  id: CREATURE_YUAN_TI_MALISON_2,
  parentId: CREATURE_YUAN_TI_MALISON_1,
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в облике юань-ти',
      description: `★СУЩЕСТВО★ совершает две атаки _Укусом_, используя свои змеиные руки.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
}
