const {CREATURE_BUGBEAR_CHIEF} = require('./../../creatureIdList')
const {GOD_HRUGGEK} = require('./../../godIdList')
const {SOURCE_MM} = require('./../../sourceList')
const {PC_RACE_BUGBEAR} = require('./../../pcRaceIdList')

module.exports = {
  header: 'Последователи Хруггека',
  text: `[Багбиры](PC_RACE:${PC_RACE_BUGBEAR}) поклоняются [Хруггеку](GOD:${GOD_HRUGGEK}), малому богу, который обитает в плане Ахерон.

В отсутствии их гоблиноидных родичей, [багбиры](PC_RACE:${PC_RACE_BUGBEAR}) сбиваются в разношёрстные банды, каждую из которых возглавляет [сильный вождь](CREATURE:${CREATURE_BUGBEAR_CHIEF}).

[Багбиры](PC_RACE:${PC_RACE_BUGBEAR}) верят, что когда они умирают, их души получают шанс сражаться на стороне Хруггека. Они пытаются доказать самим себе, что достойны этого, убивая как можно больше врагов.`,
  source: {
    id: SOURCE_MM,
    page: 201,
  },
}
