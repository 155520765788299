import { Link } from "gatsby"
import PropTypes from 'prop-types'
import React from 'react'

const GatsbyLink = ({ href, ...rest}) => (
  <Link
    to={href}
    {...rest}
  />
)

GatsbyLink.propTypes = {
  href: PropTypes.string.isRequired,
}

export default GatsbyLink
