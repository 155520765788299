const {creatureTypeCollection} = require('./../../../../../creatureTypeList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {languageCollection} = require('./../../../../../languageList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_DOMINATE_MONSTER} = require('./../../../../../spellIdList')

const generateKillBonusText = require('./generateKillBonusText')
const generateSpellText = require('./generateSpellText')
const siblingIdList = require('./siblingIdList')

const generator = (
  {
    additionalBonus,
    creatureId,
    creatureTypeId,
    id,
    killBonusList,
    langId,
    nameEn,
    planeName,
    spellList,
  }
) => {
  const {name} = creatureTypeCollection[creatureTypeId]
  const {plural: {instrumental: pluralName}} = name
  const {accusative: singularName} = name.singular[GENDER_MIDDLE]
  const {name: { prepositional: langName }} = languageCollection[langId]

  const spellText = generateSpellText(spellList)
  const spellIdList = spellList.map(({id}) => id)
  const killBonusText = generateKillBonusText(killBonusList, false, true)

  return {
    id,
    name: `Кольцо командования ${pluralName}`,
    nameEn: `Ring of Elemental Command, ${nameEn}`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Это кольцо связано со Стихийным **Планом ${planeName}**.

Нося это кольцо, Вы получаете преимущество к атакам по элементалям из **Плана ${planeName}**, а они совершают с помехой броски атаки по Вам.

У кольца есть 5 зарядов. Оно ежедневно восстанавливает 1к4 + 1 заряд на рассвете.

Вы можете потратить 2 заряда кольца, чтобы наложить [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}) на [**${singularName}**](CREATURE:${creatureId}). Кроме того, **${additionalBonus}**. Вы также можете говорить на **${langName}** и понимать его.

Если Вы поможете убить [**${singularName}**](CREATURE:${creatureId}), будучи настроенным на это кольцо, Вы получаете доступ к следующим свойствам:

${killBonusText}
* Вы можете сотворять кольцом заклинания (СЛ испытания 17), тратя соответствующее число зарядов.
${spellText}`,
    genderId: GENDER_MIDDLE,
    siblingIdList,
    spellIdList,
    source: {
      id: SOURCE_DMG,
      page: 175,
    },
  }
}

module.exports = generator
