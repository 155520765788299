const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GNOLL,
  CREATURE_GNOLL_WITHERLING,
  CREATURE_YEENOGHU,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {ABILITY_RAMPAGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {GEAR_CLUB} = require('./../../../../gearIdList')
const {gnollDescriptionList} = require('./../../../../textCommonParts')
const {LANG_GNOLL} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Иссохший гнолл',
  nameEn: 'Gnoll Witherling',
  id: CREATURE_GNOLL_WITHERLING,
  description: [
    `Иногда [гноллы](CREATURE:${CREATURE_GNOLL}) сражаются друг с другом, для определения лидера в банде или из-за сильного голода. Даже в обычных условиях гноллы, которые лишены жертв слишком долго, не могут контролировать свой голод и жажду убийства. В конце концов они начинают сражаться между собой.

Выжившие пожирают плоть своих убитых товарищей, но сохраняют их кости. Затем, с помощью тёмного ритуала [Йеногу](CREATURE:${CREATURE_YEENOGHU}), они возвращают останкам подобие жизни в виде иссохших гноллов.

Иссохшие действуют точно так же, как и при жизни, путешествуют со своими товарищами и убивают всех на своём пути. Они не едят и не испытывают голода, оставляя больше мяса для остальной банды. Иссохшие гноллы не способны использовать оружие сложнее простой [дубины](GEAR:${GEAR_CLUB}).`,
    {
      header: 'Природа нежити',
      text: `Как нежить, иссохшие гноллы, не нуждаются в воздухе, еде, воде и сне.`,
      source: {
        id: SOURCE_VGTM,
        page: 142,
      },
    },
    ...gnollDescriptionList,
  ],
  note: {
    text: `Я слышал, что колдун при помощи магии превращал последних иссохших из уничтоженной банды в свою ударную силу против врагов. И у него это хорошо получалось, пока Йеногу не послал за ним семь банд.`,
    author: `Воло`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 142,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 12,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 5,
    [PARAM_CHA]: 5,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_GNOLL,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_RAMPAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Иссохший делает две атаки: одну _Укусом_ и одну _Дубинкой_ или две атаки _Дубинкой_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_CLUB,
    },
  ],
  reactionList: [
    {
      name: 'Мстительный удар',
      description: `В ответ на то, что хиты какого-либо гнолла уменьшены до 0 не далее чем в 30 футах от иссохшего, он совершает рукопашную атаку.`,
    },
  ],
}
