const {SOURCE_DMG} = require('./../../../sourceList')
const {GENDER_MALE} = require('./../../../genderList')
const {WEAPON_RANGE} = require('./../../../weaponPropList')
const {
  GEAR_GRENADE_FRAGMENTATION,
  GEAR_GRENADE_LAUNCHER,
  GEAR_GRENADE_SMOKE,
} = require('./../../../gearIdList')
const {
  CAT_GRENADES,
  CAT_TECH_MODERN,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_GRENADE_LAUNCHER,
  name: 'Гранатомёт',
  nameEn: 'Grenade, launcher',
  nameByCase: {
    nominative: 'гранатомёт',
    genitive: 'гранатомёта',
    accusative: 'гранатомёт',
    instrumental: 'гранатомётом',
  },
  description: {
    header: 'Гранатомёт',
    text: `Персонаж может с помощью гранатомёта действием запустить гранату ([дымовую](GEAR:${GEAR_GRENADE_SMOKE}) или [осколочную](GEAR:${GEAR_GRENADE_FRAGMENTATION})) на расстояние до 120 футов.`,
    source: {
      id: SOURCE_DMG,
      page: 268,
    },
  },
  weight: 7,
  genderId: GENDER_MALE,
  range: 120,
  category: [
    CAT_GRENADES,
    CAT_TECH_MODERN,
  ],
  weaponPropList: [
    {
      id: WEAPON_RANGE,
      value: {
        normal: 120,
        max: 120,
      },
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
