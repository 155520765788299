const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_OTYUGH,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_OTYUGH,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Отиджский',
    genitive: 'Отиджского',
    instrumental: 'Отиджским',
    prepositional: 'Отиджском',
  },
  nameEn: 'Otyugh',
  isReady: false,
  isRealLang: true,
}
