const {
    SPELL_COLOR_SPRAY,
    SPELL_DANCING_LIGHTS,
    SPELL_HALLUCINATORY_TERRAIN,
    SPELL_MAGE_HAND,
    SPELL_MAJOR_IMAGE,
    SPELL_MINOR_ILLUSION,
    SPELL_MIRROR_IMAGE,
    SPELL_SUGGESTION,
  } = require('./../../../../spellIdList'),
  {
    CREATURE_FAERIE_DRAGON_BLUE,
    CREATURE_FAERIE_DRAGON_LIGHT_BLUE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Синий волшебный дракончик',
  nameEn: 'Blue Faerie Dragon',
  id: CREATURE_FAERIE_DRAGON_BLUE,
  parentId: CREATURE_FAERIE_DRAGON_LIGHT_BLUE,
  extendPropCollection: {
    spellCast: {
      spellIdByCountList: [
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_MAGE_HAND,
            SPELL_MINOR_ILLUSION,
            SPELL_DANCING_LIGHTS,
            SPELL_COLOR_SPRAY,
            SPELL_MIRROR_IMAGE,
            SPELL_SUGGESTION,
            SPELL_MAJOR_IMAGE,
            SPELL_HALLUCINATORY_TERRAIN,
          ],
        },
      ],
    },
  },
}
