const {
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../../attackTypeList')
const {DAMAGE_FIRE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {SPELL_GREEN_FLAME_BLADE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GREEN_FLAME_BLADE,
  name: 'Клинок зелёного пламени',
  nameEn: 'Green-Flame Blade',
  description: `Вы взмахиваете оружием, использованным при сотворении этого заклинания, и совершаете им рукопашную атаку по одному из существ в пределах дальности этого заклинания.

При попадании, цель получает обычные последствия от атаки и Вы можете заставить зелёный огонь прыгнуть с цели на другое существо по Вашему выбору, видимое Вами в пределах 5 футов от цели. Второе существо получает урон огнём, равный Вашему модификатору заклинательной характеристики.`,
  highLvlCast: `Урон заклинания растёт с повышением Вашего уровня. На 5-м уровне рукопашная атака наносит дополнительно 1к8 урона огнём по цели и урон по второму существу возрастает до 1к8 + модификатор заклинательной характеристики. Обе кости урона ещё возрастают на 1к8 на 11-м (2к8 и 2к8) и на 17-м (3к8 и 3к8) уровнях.`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 5,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'рукопашное оружие, стоимостью не менее 1 зм',
  effect: {
    attackType: ATTACK_MELEE_WEAPON,
    damage: {
      type: DAMAGE_FIRE,
      diceCount: 1,
      diceType: 8,
      diceBonus: 0,
    },
  },
  source: {
    id: SOURCE_TCoE,
    page: 154,
  },
}
