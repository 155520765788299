const {
  SPELL_CHILL_TOUCH,
  SPELL_CLOUDKILL,
  SPELL_CONE_OF_COLD,
  SPELL_DIMENSION_DOOR,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_EYEBITE,
  SPELL_FEAR,
  SPELL_FINGER_OF_DEATH,
  SPELL_FIRE_BOLT,
  SPELL_HASTE,
  SPELL_ICE_STORM,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MIRROR_IMAGE,
  SPELL_POISON_SPRAY,
  SPELL_RAY_OF_FROST,
  SPELL_SCORCHING_RAY,
  SPELL_SHOCKING_GRASP,
  SPELL_THUNDERWAVE,
} = require('./../../../../spellIdList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
  CONDITION_STUNNED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_SKELETON,
  CREATURE_SKULL_LORD,
  CREATURE_VAMPIRE,
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')
const {
  SKILL_ATHLETICS,
  SKILL_HISTORY,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_SKELETON,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_EVASION,
  ABILITY_LEGENDARY_RESISTANCE_3,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  MAGIC_ITEM_EYE_OF_VECNA,
  MAGIC_ITEM_HAND_OF_VECNA,
} = require('./../../../../magicItemIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_15} = require('./../../../../crList')
const {GEAR_PLATE_ARMOR} = require('./../../../../gearIdList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {PC_CLASS_SORCERER} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Лорд черепов',
  nameEn: 'Skull Lord',
  id: CREATURE_SKULL_LORD,
  description: [
    `Лорды черепов претендуют на правление обширными регионами Царства Теней. В этих мрачных землях они ведут войну против своих противников, командуя полчищами нежити в попытке установить господство. Однако лорды черепов всегда являются своими собственными злейшими врагами; рождённые объединением трёх ненавидящих друг друга личностей, они постоянно борются друг с другом.`,
    {
      header: 'Существа предательств',
      text: `Внутренние междоусобицы и предательства привели к существованию лордов черепов. Первые из них появились впоследствии попыток Векны завоевать мир Грейхока, после того как [вампир](CREATURE:${CREATURE_VAMPIRE}) Кас предал Векну и лишил того [глаза](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) и [руки](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}). В замешательстве, вызванном таким поворотом событий, военачальники Векны повернулись друг против друга, и планы Векны были разбиты. В ярости Векна собрал своих генералов и капитанов и связал их группами по три человека, сплавив их в нечестивых отродий, проклятых сражаться с самими собой до скончания веков. С тех пор как первые лорды черепов были сосланы в Царство Теней, многие другие присоединились к ним, обычно созданные из других лидеров, предавших своих хозяев.`,
      source: {
        id: SOURCE_MTOF,
        page: 208,
      },
    },
    {
      header: 'Природа нежити',
      text: `Лорд черепов не нуждается в воздухе, пище, питье или сне.`,
      source: {
        id: SOURCE_MTOF,
        page: 208,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 208,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 17,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 21,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
    CONDITION_STUNNED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_15,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    {
      name: 'Хозяин надгробья',
      description: `Вся нежить, союзная ★СУЩЕСТВО★, в пределах 30 фт. от ★него★, проходит испытания с преимуществом и восстанавливает 1к6 хитов каждый раз, когда начинает свой ход в этой области.`,
    },
    ABILITY_EVASION,
  ],
  spellCast: {
    spellCasterLevel: 13,
    spellCasterClass: PC_CLASS_SORCERER,
    spellIdList: [
      SPELL_CHILL_TOUCH,
      SPELL_CLOUDKILL,
      SPELL_CONE_OF_COLD,
      SPELL_DIMENSION_DOOR,
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_EYEBITE,
      SPELL_FEAR,
      SPELL_FINGER_OF_DEATH,
      SPELL_FIRE_BOLT,
      SPELL_HASTE,
      SPELL_ICE_STORM,
      SPELL_MAGE_HAND,
      SPELL_MAGIC_MISSILE,
      SPELL_MIRROR_IMAGE,
      SPELL_POISON_SPRAY,
      SPELL_RAY_OF_FROST,
      SPELL_SCORCHING_RAY,
      SPELL_SHOCKING_GRASP,
      SPELL_THUNDERWAVE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
      1,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки _Костяным посохом_.`,
    },
    {
      name: 'Костяной посох',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 3,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 4,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Костяной посох',
      cost: 2,
      description: `★СУЩЕСТВО★ совершает атаку _Костяным посохом_.`,
    },
    {
      name: 'Заговор',
      description: `★СУЩЕСТВО★ сотворяет заговор.`,
    },
    {
      name: 'Перемещение',
      description: `★СУЩЕСТВО★ перемещается на расстояние, не превышающее его скорость, не провоцируя атаки.`,
    },
    {
      name: 'Призыв нежити',
      description: `До пяти [скелетов](CREATURE:${CREATURE_SKELETON}) или [зомби](CREATURE:${CREATURE_ZOMBIE}) появляются в незанятом пространстве в пределах 30 футов от лорда черепов и существуют, пока не будут уничтожены. Нежить, призванная таким способом, определяет свою инициативу и действует в следующем доступном ходу`,
    },
  ],
}
