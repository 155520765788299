const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  TIME_REACTION,
  TIME_ROUND,
} = require('./../../../../../timePeriodList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../../attackTypeList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {SPELL_ABSORB_ELEMENTS} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ABSORB_ELEMENTS,
  name: 'Поглощение стихий',
  nameEn: 'Absorb Elements',
  description: `Заклинание поглощает часть энергии, направленной на вас, ослабляя эффект и запасая эту энергию для использования во время следующей рукопашной атаки. Вы получаете до начала своего следующего хода сопротивление к тому виду урона, который спровоцировал данное заклинание. Кроме того, когда Вы впервые попадаете рукопашной атакой в следующем ходу, цель получает дополнительный урон 1к6 этого вида, после чего заклинание заканчивается.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 2 круга или выше, то дополнительный урон увеличивается на 1к6 за каждый круг ячейки выше 1.`,
  lvl: 1,
  magicSchoolId: MAGIC_ABJURATION,
  range: -1,
  componentIdList: [CAST_SOMATIC],
  duration: {timeId: TIME_ROUND, count: 1},
  castTime: {timeId: TIME_REACTION, count: 1},
  castTimeComment: `совершаемая Вами в ответ на получение урона кислотой, холодом, огнем, электричеством или звуком.`,
  effect: {
    attackType: ATTACK_MELEE_WEAPON,
    damage: {
      type: [
        DAMAGE_ACID,
        DAMAGE_COLD,
        DAMAGE_FIRE,
        DAMAGE_ELECTRICITY,
        DAMAGE_THUNDER,
      ],
      diceCount: 1,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 161,
    },
    {
      id: SOURCE_EE,
      page: 21,
    },
  ],
}
