const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ACROBATICS,
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_CROSSBOW_LIGHT,
  GEAR_SHORTSWORD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  ABILITY_EVASION,
  ABILITY_SNEAK_ATTACK,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_ASSASSIN,
  CREATURE_NOBLE,
} = require('./../../../../creatureIdList')
const {
  LANG_ANY,
  LANG_THIEVES_CANT,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NOT_GOOD} = require('./../../../../aligmentList')
const {CR_8} = require('./../../../../crList')
const {DAMAGE_POISON} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Наёмный убийца',
  nameEn: 'Assassin',
  id: CREATURE_ASSASSIN,
  description: `Умеющие использовать яды наёмные убийцы безжалостно работают на [дворянство](CREATURE:${CREATURE_NOBLE}), глав гильдий, правителей, а впрочем, всех, кто может оплатить их услуги.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_NOT_GOOD,
  source: {
    id: SOURCE_MM,
    page: 347,
  },
  armor: {
    ac: 15,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_INT,
  ],
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  languageList: [
    LANG_THIEVES_CANT,
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_8,
  featureList: [
    {
      name: 'Убийство',
      description: `В своём первом ходу ★СУЩЕСТВО★ совершает с преимуществом броски атаки по существам, которые ещё не совершали ход. Все ★его★ попадания по захваченным врасплох существам являются критическими попаданиями.`,
    },
    ABILITY_EVASION,
    {
      id: ABILITY_SNEAK_ATTACK,
      damage: '14 (4к6)',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Коротким мечом_.`,
    },
    {
      gearId: GEAR_SHORTSWORD,
      description: `Цель должна пройти испытание Телосложения СЛ 15, получая урон ядом 24 (7к6) при провале, или половину этого урона при успехе.`,
    },
    {
      gearId: GEAR_CROSSBOW_LIGHT,
      description: `Цель должна пройти испытание Телосложения СЛ 15, получая урон ядом 24 (7к6) при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
