const listToCollectionById = require ('./../utils/listToCollectionById')

const WEAPON_AMMUNITION = 'ammunition'
const WEAPON_AMMUNITION_ENERGY_CELLS = 'ammunition_energy_cells'
const WEAPON_AMMUNITION_MODERN = 'ammunition_modern'
const WEAPON_AMMUNITION_RENAISSANCE = 'ammunition_renaissance'
const WEAPON_BURST_FIRE = 'burst_fire'
const WEAPON_FINESSE = 'finesse'
const WEAPON_HEAVY = 'heavy'
const WEAPON_LIGHT = 'light'
const WEAPON_RANGE = 'range'
const WEAPON_REACH = 'reach'
const WEAPON_RELOAD = 'reload'
const WEAPON_RELOADING = 'reloading'
const WEAPON_SPECIAL = 'special'
const WEAPON_THROWN = 'thrown'
const WEAPON_TWO_HANDED = 'two_handed'
const WEAPON_VERSATILE = 'versatile'

const weaponPropList = [
  {
    id: WEAPON_AMMUNITION,
    name: 'Боеприпас',
    nameEn: 'Ammunition',
    description: `Вы можете использовать оружие со свойством «боеприпас» для совершения дальнобойной атаки только если у вас есть боеприпасы для стрельбы. Каждый раз, когда Вы совершаете атаку с помощью этого оружия, Вы тратите один боеприпас. Вынимается боеприпас из колчана или другого контейнера частью атаки. Для зарядки одноручного оружия требуется одна свободная рука. В конце сражения Вы восстанавливаете половину использованных боеприпасов, потратив минуту на поиски на поле боя.

Если Вы используете оружие со свойством «боеприпас» для совершения рукопашной атаки, Вы считаете его импровизированным оружием. Праща при этом должна быть заряжена.`,
  },
  {
    id: WEAPON_AMMUNITION_MODERN,
    name: 'Боеприпас (патроны)',
    nameEn: 'Ammunition (Bullets)',
    description: `Боеприпасы огнестрельного оружия уничтожаются при использовании.

Современное оружие использует патроны.`,
  },
  {
    id: WEAPON_AMMUNITION_RENAISSANCE,
    name: 'Боеприпас (пули)',
    nameEn: 'Ammunition (Bullets)',
    description: `Боеприпасы огнестрельного оружия уничтожаются при использовании.

Оружие эпохи Возрождения использует пули.`,
  },
  {
    id: WEAPON_AMMUNITION_ENERGY_CELLS,
    name: 'Боеприпас (батареи)',
    nameEn: 'Ammunition (Energy Cells)',
    description: `Футуристическое оружие использует особые боеприпасы, называемые батареями.

В одной батарее достаточно энергии, чтобы сделать все выстрелы, которые способно совершить оружие.`,
  },
  {
    id: WEAPON_BURST_FIRE,
    name: 'Очередь',
    nameEn: 'Burst Fire',
    description: `Оружие с этим свойством может совершать как обычные атаки по одной цели, так и атаки по области 10-футового куба в пределах обычной дистанции.

Все существа в этой области должны пройти испытание Ловкости СЛ 15, иначе они получат обычный урон этого оружия. Такое действие использует десять боеприпасов.`,
  },
  {
    id: WEAPON_FINESSE,
    name: 'Фехтовальное',
    nameEn: 'Finesse',
    description: `При совершении атаки фехтовальным оружием Вы сами выбираете, какой модификатор использовать при совершении бросков атаки и урона — модификатор Силы или Ловкости. Для обоих бросков должен использоваться один и тот же модификатор.`,
  },
  {
    id: WEAPON_VERSATILE,
    name: 'Универсальное',
    nameEn: 'Versatile',
    description: `Это оружие можно использовать как одной, так и двумя руками. Урон, указанный в скобках после этого свойства, означает урон при совершении рукопашной атаки двумя руками.`,
  },
  {
    id: WEAPON_HEAVY,
    name: 'Тяжёлое',
    nameEn: 'Heavy',
    description: `Существа Маленького (или Крошечного) размера совершают броски атаки тяжёлым оружием с помехой. Из-за размера и веса такие существа не могут использовать такое оружие эффективно.`,
  },
  {
    id: WEAPON_RELOADING,
    name: 'Перезарядка',
    nameEn: 'Reloading',
    description: `Из-за долгой перезарядки этого оружия Вы можете выстрелить из него только один боеприпас одним действием, бонусным действием или реакцией, вне зависимости от количества положенных атак.`,
  },
  {
    id: WEAPON_RELOAD,
    name: 'Боекомплект',
    nameEn: 'Reload',
    description: `Из оружия с этим свойством можно совершить ограниченное число выстрелов. После этого Вы должны перезарядить его действием или бонусным действием (на свой выбор).`,
  },
  {
    id: WEAPON_SPECIAL,
    name: 'Особое',
    nameEn: 'Special',
    description: `Оружие со свойством «особое» используется по специальным правилам, указанным в описании этого оружия.`,
  },
  {
    id: WEAPON_THROWN,
    name: 'Метательное',
    nameEn: 'Thrown',
    description: `Если у оружия есть свойство «метательное», Вы можете совершать им дальнобойные атаки, метая его. Если это рукопашное оружие, Вы используете для бросков атаки и урона тот же модификатор характеристики, что и при совершении рукопашной атаки этим оружием. Например, если Вы метаете ручной топор, Вы используете Силу, а если метаете кинжал, то можете использовать либо Силу, либо Ловкость, так как у кинжала есть свойство «фехтовальное».`,
  },
  {
    id: WEAPON_LIGHT,
    name: 'Лёгкое',
    nameEn: 'Light',
    description: `Лёгкое оружие маленькое и удобное, и идеально подходит для сражения двумя оружиями.`,
  },
  {
    id: WEAPON_RANGE,
    name: 'Дистанция',
    nameEn: 'Range',
    description: `У оружия, с помощью которого могут совершаться дальнобойные атаки, указан диапазон в скобках после свойства «боеприпас» или «метательное». Диапазон состоит из двух чисел. Первое — это нормальная дистанция в футах, а второе указывает максимальную дистанцию оружия. При совершении атаки по цели, находящейся на расстоянии, превышающем нормальную дистанцию, бросок атаки совершается с помехой. Цель, находящуюся на расстоянии, превышающем максимальную дистанцию, атаковать нельзя.`,
  },
  {
    id: WEAPON_REACH,
    name: 'Досягаемость',
    nameEn: 'Reach',
    description: `Это оружие добавляет 5 футов к расстоянию, на котором Вы можете совершать этим оружием атаки, а также провоцированные атаки.`,
  },
  {
    id: WEAPON_TWO_HANDED,
    name: 'Двуручное',
    nameEn: 'Two-Handed',
    description: `Это оружие нужно держать двумя руками, когда Вы им атакуете.`,
  },
]

module.exports = weaponPropList

module.exports.weaponPropCollection = listToCollectionById(weaponPropList)

module.exports.WEAPON_AMMUNITION = WEAPON_AMMUNITION
module.exports.WEAPON_AMMUNITION_ENERGY_CELLS = WEAPON_AMMUNITION_ENERGY_CELLS
module.exports.WEAPON_AMMUNITION_RENAISSANCE = WEAPON_AMMUNITION_RENAISSANCE
module.exports.WEAPON_AMMUNITION_MODERN = WEAPON_AMMUNITION_MODERN
module.exports.WEAPON_BURST_FIRE = WEAPON_BURST_FIRE
module.exports.WEAPON_FINESSE = WEAPON_FINESSE
module.exports.WEAPON_HEAVY = WEAPON_HEAVY
module.exports.WEAPON_LIGHT = WEAPON_LIGHT
module.exports.WEAPON_RANGE = WEAPON_RANGE
module.exports.WEAPON_REACH = WEAPON_REACH
module.exports.WEAPON_RELOAD = WEAPON_RELOAD
module.exports.WEAPON_RELOADING = WEAPON_RELOADING
module.exports.WEAPON_SPECIAL = WEAPON_SPECIAL
module.exports.WEAPON_THROWN = WEAPON_THROWN
module.exports.WEAPON_TWO_HANDED = WEAPON_TWO_HANDED
module.exports.WEAPON_VERSATILE = WEAPON_VERSATILE
