const {SOURCE_SCAG} = require('./../../../sourceList')
const {GEAR_SPIKED_ARMOR} = require('./../../../gearIdList')
const {PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BATTLERAGER} = require('./../../../pcSubClassIdList')
const {
  FEATURE_BATTLERAGER_ARMOR,
  FEATURE_BATTLERAGER_CHARGE,
  FEATURE_RAGE,
  FEATURE_RECKLESS_ABANDON,
  FEATURE_RECKLESS_ATTACK,
  FEATURE_SPIKED_RETRIBUTION,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BATTLERAGER_ARMOR,
    name: `Доспех бушующего в бою`,
    nameEn: `Battlerager Armor`,
    lvl: 3,
    gearId: GEAR_SPIKED_ARMOR,
    text: `Вы получаете возможность использовать [шипованный доспех](GEAR:${GEAR_SPIKED_ARMOR}) в качестве оружия.

Пока Вы носите [шипованный доспех](GEAR:${GEAR_SPIKED_ARMOR}) и находитесь в [Ярости](FEATURE:${FEATURE_RAGE}), Вы можете бонусным действием совершить одну атаку рукопашным оружием по цели в 5 футах от Вас, используя шипы на броне. Если атака попадает, шипы наносят 1к4 колющего урона. Используйте модификатор Силы для совершения атаки и броска урона.

Кроме того, когда Вы используете действие Атака, чтобы схватить существо, цель получает 3 колющего урона, если проверка захвата была успешной.`,
  },
  {
    id: FEATURE_RECKLESS_ABANDON,
    name: `Полное безрассудство`,
    nameEn: `Reckless Abandon`,
    lvl: 6,
    text: `Когда Вы используете [Безрассудную атаку](FEATURE:${FEATURE_RECKLESS_ATTACK}) во время [Ярости](FEATURE:${FEATURE_RAGE}), Вы также получаете временные хиты равные модификатору Телосложения (минимум 1).

Оставшиеся после окончания [Ярости](FEATURE:${FEATURE_RAGE}) временные хиты исчезают.`,
  },
  {
    id: FEATURE_BATTLERAGER_CHARGE,
    name: `Налёт бушующего в бою`,
    nameEn: `Battlerager Charge`,
    lvl: 10,
    text: `Вы можете использовать Рывок бонусным действием, пока Вы в [Ярости](FEATURE:${FEATURE_RAGE}).`,
  },
  {
    id: FEATURE_SPIKED_RETRIBUTION,
    name: `Шипованная расплата`,
    nameEn: `Spiked Retribution`,
    lvl: 14,
    text: `Когда существо в 5 футах от Вас попадает по Вам рукопашной атакой, атакующий получает 3 колющего урона, если Вы в [Ярости](FEATURE:${FEATURE_RAGE}), дееспособны и одеты в [шипованный доспех](GEAR:${GEAR_SPIKED_ARMOR}).`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BATTLERAGER,
    source: {
      id: SOURCE_SCAG,
      page: 122,
    },
  })
)
