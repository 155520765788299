const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_BACKPACK,
} = require('./../../../../../gearIdList')
const {
  SPELL_GATE,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_BAG_OF_HOLDING,
  MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK,
  MAGIC_ITEM_PORTABLE_HOLE,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK,
  name: `Удобный рюкзак Хеварда`,
  nameEn: `Heward’s Handy Haversack`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_BACKPACK,
  rarity: MGC_RARITY_RARE,
  description: `У этого рюкзака есть центральный карман и два боковых кармана, и все они являются межпространственными местами. В каждом боковом кармане помещается по 20 фунтов материи, не превышающей в объёме 2 кубических фута. В центральном кармане помещается до 8 кубических футов материи, весящей не больше 80 фунтов. Этот рюкзак всегда весит 5 фунтов, что бы в нём ни хранилось.

Помещение предмета в рюкзак использует обычные правила взаимодействия с предметами. Достаются предметы из рюкзака действием. Если Вы достаёте из рюкзака конкретную вещь, она магическим образом всегда оказывается на самом верху.

У рюкзака есть несколько ограничений. Если он будет переполнен или если острый предмет проткнёт или разорвёт его, рюкзак разрывается и уничтожается. Если рюкзак уничтожается, всё его содержимое навсегда теряется, но артефакты появляются в новых местах. Если рюкзак вывернуть наизнанку, его содержимое выпадет наружу, но рюкзак придётся вывернуть обратно, чтобы его снова можно было использовать. Если в рюкзак поместить дышащее существо, оно может выжить 10 минут, после чего начнёт задыхаться.

Помещение рюкзака в межпространство, созданное [сумкой хранения](MAGIC_ITEM:${MAGIC_ITEM_BAG_OF_HOLDING}), [переносной дырой](MAGIC_ITEM:${MAGIC_ITEM_PORTABLE_HOLE}) или подобным предметом, мгновенно уничтожает оба предмета и открывает [Врата](SPELL:${SPELL_GATE}) в Астральный План. [Врата](SPELL:${SPELL_GATE}) появляются в месте, где один предмет пытались поместить в другой. Все существа в пределах 10 футов от [врат](SPELL:${SPELL_GATE}) затягиваются внутрь и помещаются в случайным образом определённое место на Астральном Плане. После этого [врата](SPELL:${SPELL_GATE}) закрываются. [Врата](SPELL:${SPELL_GATE}) односторонние, и повторно не открываются.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 210,
  },
}
