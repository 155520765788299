module.exports = [
  require('./maddening_darkness'),
  require('./maelstrom'),
  require('./magic_stone'),
  require('./mass_polymorph'),
  require('./maximilian_s_earthen_grasp'),
  require('./melf_s_minute_meteors'),
  require('./mental_prison'),
  require('./mighty_fortress'),
  require('./mind_spike'),
  require('./mold_earth'),
]
