module.exports.GOD_ABBATHOR = 'abbathor'
module.exports.GOD_AERDRIE_FAENYA = 'aerdrie_faenya'
module.exports.GOD_AKADI = 'akadi'
module.exports.GOD_AMAUNATOR = 'amaunator'
module.exports.GOD_ANGHARRADH = 'angharradh'
module.exports.GOD_ANNAM = 'annam'
module.exports.GOD_ARVOREEN = 'arvoreen'
module.exports.GOD_ASMODEUS = 'asmodeus'
module.exports.GOD_AURIL = 'auril'
module.exports.GOD_AZUTH = 'azuth'
module.exports.GOD_BAERVAN_WILDWANDERER = 'baervan_wildwanderer'
module.exports.GOD_BAHAMUT = 'bahamut'
module.exports.GOD_BAHGTRU = 'bahgtru'
module.exports.GOD_BANE = 'bane'
module.exports.GOD_BARAVAR_CLOAKSHADOW = 'baravar_cloakshadow'
module.exports.GOD_BERRONAR = 'berronar'
module.exports.GOD_BESHABA = 'beshaba'
module.exports.GOD_BHAAL = 'bhaal'
module.exports.GOD_BLIBDOOLPOOLP = 'blibdoolpoolp'
module.exports.GOD_BRANDOBARIS = 'brandobaris'
module.exports.GOD_CALLARDURAN_SMOOTHHANDS = 'callarduran_smoothhands'
module.exports.GOD_CHAUNTEA = 'chauntea'
module.exports.GOD_CLANGEDDIN = 'clangeddin'
module.exports.GOD_CORELLON_LARETHIAN = 'corellon_larethian'
module.exports.GOD_CYRIC = 'cyric'
module.exports.GOD_CYRROLLALEE = 'cyrrollalee'
module.exports.GOD_DEEP_DUERRA = 'deep_duerra'
module.exports.GOD_DEEP_SASHELAS = 'deep_sashelas'
module.exports.GOD_DENDAR = 'dendar'
module.exports.GOD_DENEIR = 'deneir'
module.exports.GOD_DIANCASTRA = 'diancastra'
module.exports.GOD_DUGMAREN_BRIGHTMANTLE = 'dugmaren_brightmantle'
module.exports.GOD_DUMATHOIN = 'dumathoin'
module.exports.GOD_EADRO = 'eadro'
module.exports.GOD_EILISTRAEE = 'eilistraee'
module.exports.GOD_ELDATH = 'eldath'
module.exports.GOD_EREVAN_ILESERE = 'erevan_ilesere'
module.exports.GOD_FENMAREL_MESTARINE = 'fenmarel_mestarine'
module.exports.GOD_FLANDAL_STEELSKIN = 'flandal_steelskin'
module.exports.GOD_GAERDAL_IRONHAND = 'gaerdal_ironhand'
module.exports.GOD_GARL_GLITTERGOLD = 'garl_glittergold'
module.exports.GOD_GHAUNADAUR = 'ghaunadaur'
module.exports.GOD_GOND = 'gond'
module.exports.GOD_GORM_GULTHYN = 'gorm_gulthyn'
module.exports.GOD_GROLANTOR = 'grolantor'
module.exports.GOD_GRUMBAR = 'grumbar'
module.exports.GOD_GRUUMSH = 'gruumsh'
module.exports.GOD_GWAERON_WINDSTROM = 'gwaeron_windstrom'
module.exports.GOD_HAELA = 'haela'
module.exports.GOD_HANALI_CELANIL = 'hanali_celanil'
module.exports.GOD_HELM = 'helm'
module.exports.GOD_HIATEA = 'hiatea'
module.exports.GOD_HOAR = 'hoar'
module.exports.GOD_HRUGGEK = 'hruggek'
module.exports.GOD_IALLANIS = 'iallanis'
module.exports.GOD_ILMATER = 'ilmater'
module.exports.GOD_ILNEVAL = 'ilneval'
module.exports.GOD_IMIX = 'imix'
module.exports.GOD_ISTISHIA = 'istishia'
module.exports.GOD_JERGAL = 'jergal'
module.exports.GOD_KARONTOR = 'karontor'
module.exports.GOD_KELEMVOR = 'kelemvor'
module.exports.GOD_KHURGORBAEYAG = 'khurgorbaeyag'
module.exports.GOD_KIARANSALEE = 'kiaransalee'
module.exports.GOD_KOSSUTH = 'kossuth'
module.exports.GOD_KURTULMAK = 'kurtulmak'
module.exports.GOD_LABELAS_ENORETH = 'labelas_enoreth'
module.exports.GOD_LADUGUER = 'laduguer'
module.exports.GOD_LAOGZED = 'laogzed'
module.exports.GOD_LATHANDER = 'lathander'
module.exports.GOD_LEIRA = 'leira'
module.exports.GOD_LLIIRA = 'lliira'
module.exports.GOD_LOLTH = 'lolth'
module.exports.GOD_LOVIATAR = 'loviatar'
module.exports.GOD_LUTHIC = 'luthic'
module.exports.GOD_MAGLUBIYET = 'maglubiyet'
module.exports.GOD_MALAR = 'malar'
module.exports.GOD_MARTHAMMOR = 'marthammor'
module.exports.GOD_MASK = 'mask'
module.exports.GOD_MEMNOR = 'memnor'
module.exports.GOD_MERRSHAULK = 'merrshaulk'
module.exports.GOD_MIELIKKI = 'mielikki'
module.exports.GOD_MILIL = 'milil'
module.exports.GOD_MORADIN = 'moradin'
module.exports.GOD_MYRKUL = 'myrkul'
module.exports.GOD_MYSTRA = 'mystra'
module.exports.GOD_NEBELUN = 'nebelun'
module.exports.GOD_OGHMA = 'oghma'
module.exports.GOD_RAVEN_QUEEN = 'raven_queen'
module.exports.GOD_RED_KNIGHT = 'red_knight'
module.exports.GOD_RILLIFANE_RALLATHIL = 'rillifane_rallathil'
module.exports.GOD_SAVRAS = 'savras'
module.exports.GOD_SEGOJAN_EARTHCALLER = 'segojan_earthcaller'
module.exports.GOD_SEHANINE_MOONBOW = 'sehanine_moonbow'
module.exports.GOD_SEKOLAH = 'sekolah'
module.exports.GOD_SELUNE = 'selune'
module.exports.GOD_SELVETARM = 'selvetarm'
module.exports.GOD_SEMUANYA = 'semuanya'
module.exports.GOD_SHAR = 'shar'
module.exports.GOD_SHARGAAS = 'shargaas'
module.exports.GOD_SHARINDLAR = 'sharindlar'
module.exports.GOD_SHEELA_PERYROYL = 'sheela_peryroyl'
module.exports.GOD_SHEVARASH = 'shevarash'
module.exports.GOD_SILVANUS = 'silvanus'
module.exports.GOD_SKERRIT = 'skerrit'
module.exports.GOD_SKORAEUS_STONEBONES = 'skoraeus_stonebones'
module.exports.GOD_SOLONOR_THELANDIRA = 'solonor_thelandira'
module.exports.GOD_SSETH = 'sseth'
module.exports.GOD_STRONMAUS = 'stronmaus'
module.exports.GOD_SUNE = 'sune'
module.exports.GOD_SURTUR = 'surtur'
module.exports.GOD_TALONA = 'talona'
module.exports.GOD_TALOS = 'talos'
module.exports.GOD_TEMPUS = 'tempus'
module.exports.GOD_THRYM = 'thrym'
module.exports.GOD_TIAMAT = 'tiamat'
module.exports.GOD_TORM = 'torm'
module.exports.GOD_TYMORA = 'tymora'
module.exports.GOD_TYR = 'tyr'
module.exports.GOD_UBTAO = 'ubtao'
module.exports.GOD_UMBERLEE = 'umberlee'
module.exports.GOD_URDLEN = 'urdlen'
module.exports.GOD_UROGALAN = 'urogalan'
module.exports.GOD_VALKUR = 'valkur'
module.exports.GOD_VAPRAK = 'vaprak'
module.exports.GOD_VERGADAIN = 'vergadain'
module.exports.GOD_VHAERAUN = 'vhaeraun'
module.exports.GOD_WAUKEEN = 'waukeen'
module.exports.GOD_YONDALLA = 'yondalla'
module.exports.GOD_YURTRUS = 'yurtrus'
