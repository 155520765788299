const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  GEAR_CHAIN_SHIRT,
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_SWORD_WRAITH_WARRIOR} = require('./../../../../creatureIdList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {swordWraithDescription} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Воин призраков меча',
  nameEn: 'Sword Wraith Warrior',
  id: CREATURE_SWORD_WRAITH_WARRIOR,
  description: swordWraithDescription,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 224,
  },
  armor: {
    ac: 16,
    gearId: [GEAR_CHAIN_SHIRT, GEAR_SHIELD],
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 17,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 10,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Смертельная ярость',
      description: `Призрак меча может совершить одну атаку оружием бонусным действием, причиняя ею дополнительно 9 (2к8) урона некротической энергией в случае попадания. Если призрак делает это, то все броски атак против него будут совершаться с преимуществом до начала его следующего хода.`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_LONGSWORD,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 1,
              diceType: 8,
              diceBonus: 4,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 1,
              diceType: 10,
              diceBonus: 4,
              use2Hand: true,
            },
          ],
        ],
      },
    },
    {
      gearId: GEAR_LONGBOW,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 3,
        range: {
          normal: 150,
          max: 600,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 1,
        },
      },
    },
  ],
}
