const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_CLERIC,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_DESTROY_UNDEAD,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_INTERVENTION,
  FEATURE_SPELLCASTING_CLERIC,
} = require('./../../featureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
} = require('./../../sourceList')
const {
  GOD_AKADI,
  GOD_ANGHARRADH,
  GOD_GRUMBAR,
  GOD_ISTISHIA,
  GOD_KOSSUTH,
} = require('./../../godIdList')
const { fullCasterSpellSlotTable } = require('./../../spellSlotTable')
const {CREATURE_ACOLYTE} = require('./../../backgroundIdList')
const {
  CREATURE_ORC,
  CREATURE_ZOMBIE,
} = require('./../../creatureIdList')
const {
  PARAM_CHA,
  PARAM_WIT,
} = require('./../../paramList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_CROSSBOW_BOLTS,
  GEAR_CROSSBOW_LIGHT,
  GEAR_EXPLORER_S_PACK,
  GEAR_LEATHER_ARMOR,
  GEAR_MACE,
  GEAR_PRIEST_S_PACK,
  GEAR_SCALE_MAIL,
  GEAR_SHIELD,
  GEAR_WARHAMMER,
} = require('./../../gearIdList')
const {
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_SHIELDS,
  CAT_SIMPLE_WEAPON,
} = require('./../../gearCategoryList')
const {
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_MEDICINE,
  SKILL_PERSUASION,
  SKILL_RELIGION,
} = require('./../../skillList')
const {PC_CLASS_CLERIC} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_CLERIC,
  name: {
    singular: {
      nominative: 'жрец',
      genitive: 'жреца',
      dative: 'жрецу',
      accusative: 'жреца',
      instrumental: 'жрецом',
      prepositional: 'жреце',
    },
    plural: {
      nominative: 'жрецы',
      genitive: 'жрецов',
      dative: 'жрецам',
      accusative: 'жрецов',
      instrumental: 'жрецами',
      prepositional: 'жрецах',
    },
  },
  nameEn: 'cleric',
  description: [
    `Воздев руки и глаза к небу и запев молитву, эльф начинает сиять внутренним светом, который проливается на боевых товарищей и лечит их.

Распевая песнь славы, дварф широко размахивает своим топором, прорубаясь через ряды [орков](CREATURE:${CREATURE_ORC}), выступивших против него, крича хвалу богам с каждым павшим врагом.

Посылая проклятье на силы нежити, женщина поднимает свой священный символ, из которого льётся свет, отбрасывающий [зомби](CREATURE:${CREATURE_ZOMBIE}), толпящихся около её спутников.

Жрецы являются посредниками между миром смертных и далёкими мирами богов. Настолько же разные, насколько боги, которым они служат, жрецы воплощают работу своих божеств. В отличие от обычного проповедника, жрец наделён божественной магией. `,
    {
      header: `Целители и воители`,
      text: `Божественная магия, как следует из названия, является силой богов, источающейся из них в мир. Жрецы являются проводниками этой энергии, проявляющейся в виде чудотворных эффектов. Боги не дают такую власть всем, кто обращается к ним, но только тем, кто избран выполнять высокое призвание.

Использующие божественную магию не полагаются на обучение или тренировки. Жрец может разучить шаблонные молитвы и древние ритуалы, но способность читать заклинания зависит от преданности и интуитивного ощущения воли божества.

Жрецы сочетают полезную магию исцеления и вдохновения союзников с заклинаниями, которые вредят и препятствуют врагам. Они могут вызвать страх и ужас, наложить проклятье или болезни, отравить, и даже призвать пламя с небес, чтобы уничтожить своих противников. Встретившись с грешниками, которым поможет только удар [булавой](GEAR:${GEAR_MACE}) по голове, жрецы полагаются на свою боевую подготовку, что позволяет им вступить в ближний бой с силами богов на своей стороне.`,
      source: {
        id: SOURCE_PHB,
        page: 57,
      },
    },
    {
      header: `Божественные посредники`,
      text: `Не каждый послушник или священнослужитель в храме или святилище является жрецом. Некоторые проповедники призваны к простой жизни служащего в храме, выполняя волю своих богов посредством молитвы и жертв, а не с помощью магии и силы оружия. В некоторых городах духовенство занимает политические должности, или рассматривается как трамплин для более высоких должностей, и вообще не общаются с богом. Истинные жрецы — редкость в большинстве иерархий.

Если жрец обращается к жизни искателя приключений, то, как правило, из-за того, что его бог требует этого. Преследуя цели богов, часто попадаешь в опасные ситуации вне стен цивилизаций, приходится карать зло и искать святые реликвии в древних гробницах. Многие жрецы также должны защищать последователей своих божеств, что может означать сражение с неистовыми [орками](CREATURE:${CREATURE_ORC}), ведение переговоров о мире между воюющими государствами, или закрытие портала, через который в мир может пройти князь демонов.

Большинство странствующих жрецов поддерживает некоторую связь с храмами и орденами, разделяющими их веру. Храм может попросить помощи у жреца, а высший священник вправе просто потребовать её.`,
      source: {
        id: SOURCE_PHB,
        page: 57,
      },
    },
    {
      header: `Жрецы`,
      text: `Боги проявляют своё влияние через своих избранных и священнослужителей, которые осуществляют планы богов на Материальном плане. Обычный фаэрунский жрец служит одному божественному покровителю, но некоторые чувствуют потребность в служении группе, например, элементальным богам — [Акади](GOD:${GOD_AKADI}), [Грумбару](GOD:${GOD_GRUMBAR}), [Коссуту](GOD:${GOD_KOSSUTH}) и [Истишии](GOD:${GOD_ISTISHIA}), в то время как другие служат божествам являющимся сплетением божественных сущностей, например, эльфийская [Ангаррад](GOD:${GOD_ANGHARRADH}).

Некоторые клерики Фаэруна входят в церковную иерархию, а некоторые — нет. Боги выбирают своих поборников, и иногда преданный верующий наделяется всеми способностями жреца, даже не являясь при этом священнослужителем. Такой жрец может быть созерцательным отшельником, странствующим пророком или просто благочестивым крестьянином. Религиозные ордена часто пытаются завербовать таких жрецов и привести их в лоно церкви, но не все подобные жрецы желают быть связаны иерархией.

И наоборот, не все, кто выбирает стезю религии, является по своей сути настоящим жрецом. Некоторые аколиты церкви избирают иной путь, отличный от стези жреца. Они служат своей вере иным образом, становясь священниками, церковными учёными и ремесленниками, а некоторые находят призвание в том, что не имеет ничего общего с религией. Другие же, отринув путь жреца, озлобились и стали искать благосклонности у зловещих запретных богов или заключили договор с иными могучими сущностями. Церковные учёные Королевств часто спорят: отвержение ли бога привело человека во тьму, или боги отвернулись от него, предвидя тёмные деяния в будущем? Боги в ответ только молчат.

Некоторые жрецы предпочитают одно место, где они служат своей общине верующих, но ищущие приключений жрецы обычно горят энтузиазмом вершить дела своего бога по всей земле. Такие дела могут включать как и служение в отдаленных общинах, так и поиск и уничтожение угроз цивилизованному миру.`,
      source: {
        id: SOURCE_SCAG,
        page: 126,
      },
    },
    {
      header: 'Жрец',
      text: `Жрецы — наместники богов. Они используют могущественную божественную магию, кто для добрых дел, а кто — во зло.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Создание жреца`,
      text: `Если Вы создаёте жреца, важно определить, какому божеству Вы служите, и какие принципы воплощает Ваш персонаж. Посоветуйтесь с Мастером, чтобы узнать, какие божества есть в Вашей кампании.

Как только Вы выбрали божество, определитесь с отношением вашего жреца с этим богом. Стали ли Вы его служителем добровольно? Или бог сам выбрал Вас, принуждая к служению и не считаясь с вашими пожеланиями? Как священники в храмах Вашей веры воспринимают Вас: как героя или нарушителя порядка? Каковы ваши конечные цели? Есть ли у вашего божества специальное поручение для Вас? Или Вы стремитесь доказать себе, что Вы достойны великих достижений?`,
      source: {
        id: SOURCE_PHB,
        page: 58,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать жреца, следуя этим рекомендациям.

1. Во-первых, у Вашей Мудрости должно быть наивысшее значение. Следующей по величине характеристикой должна быть Сила или Телосложение.
2. Во-вторых, выберите предысторию «[Прислужник](BACKGROUND:${CREATURE_ACOLYTE})». `,
      source: {
        id: SOURCE_PHB,
        page: 58,
      },
    },
  ],
  note: [
    {
      text: `Стать жрецом — стать посланником богов. Сила даруемая богами велика, но она всегда несёт огромную ответственность.`,
      author: `Патриарх Ригби`,
    },
    {
      text: `Я не понимаю привлекательности богов. Зачем поклоняться кому-то, кроме меня?`,
      author: `Занатар`,
    },
  ],
  featureCollection: {
    hitDice: 8,
    proficiency: {
      armor: [
        CAT_LIGHT_ARMOR,
        CAT_MEDIUM_ARMOR,
        CAT_SHIELDS,
      ],
      weapon: {
        catList: [
          CAT_SIMPLE_WEAPON,
        ],
      },
      savingThrow: [
        PARAM_WIT,
        PARAM_CHA,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_HISTORY,
            SKILL_INSIGHT,
            SKILL_MEDICINE,
            SKILL_PERSUASION,
            SKILL_RELIGION,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 5,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) [Булава](GEAR:${GEAR_MACE})
    * б) [Боевой молот](GEAR:${GEAR_WARHAMMER}) (если владеете) 
2. Выберите одно
    * а) [Чешуйчатый доспех](GEAR:${GEAR_SCALE_MAIL})
    * б) [Кожаный доспех](GEAR:${GEAR_LEATHER_ARMOR})
    * в) [Кольчуга](GEAR:${GEAR_CHAIN_MAIL}) (если владеете)
3. Выберите одно
    * а) [Лёгкий арбалет](GEAR:${GEAR_CROSSBOW_LIGHT}) и 20 [арбалетных болтов](GEAR:${GEAR_CROSSBOW_BOLTS})
    * б) Любое простое оружие
4. Выберите одно
    * а) [Набор священника](GEAR:${GEAR_PRIEST_S_PACK})
    * б) [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})
5. [Щит](GEAR:${GEAR_SHIELD})
6. Священный символ`,
    },
  },
  progressTable: `
| Уровень | Умения                                                                           | Заговоры |
|---------|----------------------------------------------------------------------------------|----------|
|  1      | [Использование заклинаний](FEATURE:${FEATURE_SPELLCASTING_CLERIC}), [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}) | 3 |
|  2      | [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}) (1/отдых), Умение божественного домена                        | 3        |
|  3      | —                                                                                | 3        |
|  4      | [Увеличение характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_CLERIC})  | 4        |
|  5      | [Уничтожение нежити](FEATURE:${FEATURE_DESTROY_UNDEAD}) (ПО ½)                   | 4        |
|  6      | Божественный канал (2/отдых), Умение божественного домена                        | 4        |
|  7      | —                                                                                | 4        |
|  8      | Увеличение характеристик, Уничтожение нежити (ПО 1), Умение божественного домена | 4        |
|  9      | —                                                                                | 4        |
| 10      | [Божественное вмешательство](FEATURE:${FEATURE_DIVINE_INTERVENTION})             | 5        |
| 11      | Уничтожение нежити (ПО 2)                                                        | 5        |
| 12      | Увеличение характеристик                                                         | 5        |
| 13      | —                                                                                | 5        |
| 15      | Уничтожение нежити (ПО 3)                                                        | 5        |
| 15      | —                                                                                | 5        |
| 16      | Увеличение характеристик                                                         | 5        |
| 17      | Уничтожение нежити (ПО 5), Умение божественного домена                           | 5        |
| 18      | Божественный канал (3/отдых)                                                     | 5        |
| 19      | Увеличение характеристик                                                         | 5        |
| 20      | Улучшение божественного вмешательства                                            | 5        |
`,
  spellSlotTable: fullCasterSpellSlotTable,
  source: [
    {
      id: SOURCE_PHB,
      page: 57,
    },
    {
      id: SOURCE_SRD,
      page: 15,
    },
  ],
}
