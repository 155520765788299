const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {PLACE_UNTHER} = require('./../../placeIdList')
const {LANG_UNTHERIC} = require('./../../languageIdList')
const {
  ALPHABET_DETHEK,
  ALPHABET_THORASS,
} = require('./../../alphabetList')

module.exports = {
  id: LANG_UNTHERIC,
  alphabetId: [
    ALPHABET_DETHEK,
    ALPHABET_THORASS,
  ],
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Untheric',
  name: {
    nominative: 'Унтерский',
    genitive: 'Унтерского',
    instrumental: 'Унтерским',
    prepositional: 'Унтерском',
  },
  typicalSpeakers: 'Муланы',
  spokenPlaceList: PLACE_UNTHER,
  isReady: false,
  isRealLang: true,
}
