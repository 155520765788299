const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PC_RACE_ELF,
  PC_RACE_DWARF,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
} = require('./../../../../pcRaceIdList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_STANDING_LEAP} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_BULETTE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')

module.exports = {
  name: 'Панцирница',
  nameAlt: [
    'Булей',
    'Буллет',
    'Земляная акула',
  ],
  nameEn: 'Bulette',
  id: CREATURE_BULETTE,
  description: [
    {
      header: 'Панцирница',
      text: `Панцирница — это огромный хищник, терроризирующий земли, в которых он обитает. Её также называют «земляной акулой», и её единственная цель — питаться. Раздражительные и хищные, панцирницы не боятся других существ, и нападают, не обращая внимания на численное преимущество и силу противника.`,
      source: {
        id: SOURCE_MM,
        page: 232,
      },
    },
    {
      header: 'Подземные охотники',
      text: `Панцирницы, используя свои мощные когти, прорываются сквозь землю во время охоты. Они не обращают внимания на препятствия, вырывая деревья с корнями, провоцируя оползни и оставляя воронки. Колебания почвы и камней предупреждают панцирницу о движении, и она выпрыгивает на поверхность, широко распахнув свои челюсти.`,
      source: {
        id: SOURCE_MM,
        page: 232,
      },
    },
    {
      header: 'Бродячее чудовище',
      text: `Панцирница скитается по землям, питаясь любыми животными и гуманоидами, попадающимися её на пути. Этим существам не нравится плоть [дварфов](PC_RACE:${PC_RACE_DWARF}) и [эльфов](PC_RACE:${PC_RACE_ELF}), хотя они часто убивают их, прежде чем поймут, кого они убили. Больше всего панцирницы любят мясо [полуросликов](PC_RACE:${PC_RACE_HALFLING}), и нет ничего лучше, чем погоня за пухлым [полуросликом](PC_RACE:${PC_RACE_HALFLING}) в открытом поле.

У панцирницы нет логова, она просто бродит по своим охотничьим угодьям до 30 миль в поперечнике. Единственным критерием для выбора территории является наличие пищи, и, истребив всех в округе, панцирница уходит. Часто их можно встретить у [людских](PC_RACE:${PC_RACE_HUMAN}) поселений, которые они терроризируют, пока жители не разбегутся в панике или пока панцирницу не убьют.

Все существа избегают панцирниц, которые пожирают всё, что движется — даже других хищников и панцирниц. Вместе панцирницы собираются только для спаривания, которое сопровождается кровавой дракой, и, как правило, заканчивается смертью самца и его поеданием.`,
      source: {
        id: SOURCE_MM,
        page: 232,
      },
    },
    {
      header: 'Магические создания',
      text: `Некоторые мудрецы считают, что панцирницы это результат эксперимента безумных магов по скрещиванию черепахи и броненосца с помощью инъекций ихора демона. Некоторое время панцирниц считали вымершими, но через несколько лет они снова появились. Так как молодняк панцирниц никто не видел, некоторые мудрецы полагают, что существуют тайные гнёзда, откуда взрослые особи выходят в мир.`,
      source: {
        id: SOURCE_MM,
        page: 232,
      },
    },
    {
      header: 'Булей',
      text: `Благодаря глубоким норам и мощным прыжкам, булей практически в любой местности становится сверххищником.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 232,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_DIG]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 21,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 30,
      jumpHeight: 15,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 12,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Смертельный прыжок',
      description: `Если ★СУЩЕСТВО★ прыгает как минимум на 15 футов частью перемещения, она может после этого действием приземлиться на лапы в пространстве, содержащем одно или несколько других существ. Каждое такое существо должно пройти испытание Силы или Ловкости СЛ 16 (на выбор цели) иначе будет сбито с ног и получит дробящий урон 14 (3к6+4) плюс рубящий урон 14 (3к6+4). При успешном испытании существо получает только половину урона, не сбивается с ног, и толкается на 5 футов из пространства ★СУЩЕСТВО★ в свободное пространство по своему выбору. Если в пределах дистанции нет свободного пространства, существо вместо этого сбивается с ног в пространстве панцирницы.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
