const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {
  SOURCE_IDRotF,
  SOURCE_MTOF,
} = require('./../../../../sourceList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_DUERGAR_HAMMERER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {GEAR_MAUL} = require('./../../../../gearIdList')
const {LANG_DWARVISH} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Двергарский молотобоец',
  nameAlt: 'Дуэргарский молотобоец',
  nameEn: 'Duergar Hammerer',
  id: CREATURE_DUERGAR_HAMMERER,
  description: [
    {
      header: 'Двергарский молотобоец',
      text: `Молотобоец — это землеройная машина с двергаром внутри… обычно это наказание для тех, кто плохо выполняет свои обязанности. Механизм машины трансформирует боль заточённого внутри двергара в энергию, питающее устройство, которое обычно используется для рытья туннелей и отражения атак захватчиков.`,
      source: {
        id: SOURCE_MTOF,
        page: 174,
      },
    },
    ...duergarDescription,
  ],
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 174,
    },
    {
      id: SOURCE_IDRotF,
      page: 287,
    },
  ],
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 7,
    [PARAM_CON]: 12,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 5,
    [PARAM_CHA]: 5,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_DWARVISH,
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Двигатель боли',
      description: `Раз в ход, существо, напавшее на крикуна может прицелиться в двергара, заточённого внутри механизма. Нападающий совершает броски атаки с помехой. Попав в цель, атака наносит дополнительных 5 (1к10) урона молотобойцу, и механизм может ответить реакцией, применив _Мультиатаку_.`,
    },
    {
      name: 'Осадное чудовище',
      description: `Молотобоец наносит двойное повреждение объектам и строениям.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Молотобоец совершает две атаки: одну _Когтями_ и одну _Молотом_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_MAUL,
    },
  ],
}
