const {
  SPELL_ACID_SPLASH,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_CLOUDKILL,
  SPELL_CONJURE_ELEMENTAL,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_FIREBALL,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MISTY_STEP,
  SPELL_POISON_SPRAY,
  SPELL_PRESTIDIGITATION,
  SPELL_STINKING_CLOUD,
  SPELL_STONESKIN,
  SPELL_UNSEEN_SERVANT,
  SPELL_WEB,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_CONJURER} = require('./../../../../creatureIdList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Призыватель',
  nameEn: 'Conjurer',
  id: CREATURE_CONJURER,
  description: `Призыватели — волшебники, специализирующиеся на призыве существ с других планов и созданием вещества из ничего. Некоторые призыватели используют свое волшебство для поддержки армии или уничтожения врагов на поле битвы, в то время как другие используют призванных существ, чтобы охранять свои жилища.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 220,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 4,
    },
  ],
  cr: CR_6,
  featureList: [
    {
      name: 'Безопасное перемещение',
      comment: `перезаряжается после сотворения призывателем заклинания Вызова уровня 1 или выше`,
      description: `Бонусным действием Призыватель телепортируется на расстояние до 30 фт. в видимое им незанятое пространство. Вместо этого он может выбрать пространство, занятое согласным Маленьким или Средним существом, и они оба телепортируются, меняясь местами.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 9,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      {
        id: SPELL_UNSEEN_SERVANT,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Вызова 1 уровня и выше, перезаряжающее особенность «Безопасное перемещение»',
      },
      {
        id: SPELL_WEB,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Вызова 1 уровня и выше, перезаряжающее особенность «Безопасное перемещение»',
      },
      {
        id: SPELL_CLOUD_OF_DAGGERS,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Вызова 1 уровня и выше, перезаряжающее особенность «Безопасное перемещение»',
      },
      {
        id: SPELL_MISTY_STEP,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Вызова 1 уровня и выше, перезаряжающее особенность «Безопасное перемещение»',
      },
      {
        id: SPELL_STINKING_CLOUD,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Вызова 1 уровня и выше, перезаряжающее особенность «Безопасное перемещение»',
      },
      {
        id: SPELL_EVARD_S_BLACK_TENTACLES,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Вызова 1 уровня и выше, перезаряжающее особенность «Безопасное перемещение»',
      },
      {
        id: SPELL_CLOUDKILL,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Вызова 1 уровня и выше, перезаряжающее особенность «Безопасное перемещение»',
      },
      {
        id: SPELL_CONJURE_ELEMENTAL,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Вызова 1 уровня и выше, перезаряжающее особенность «Безопасное перемещение»',
      },

      SPELL_ACID_SPLASH,
      SPELL_FIREBALL,
      SPELL_MAGE_ARMOR,
      SPELL_MAGE_HAND,
      SPELL_MAGIC_MISSILE,
      SPELL_POISON_SPRAY,
      SPELL_PRESTIDIGITATION,
      SPELL_STONESKIN,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
