module.exports = [
  require('./yeti'),
  require('./yochlol'),
  require('./young_remorhaz'),
  require('./yuan_ti_abomination'),
  require('./yuan_ti_malison_1'),
  require('./yuan_ti_malison_2'),
  require('./yuan_ti_malison_3'),
  require('./yuan_ti_pureblood'),
]
