const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../../attackTypeList')
const {CONDITION_EXHAUSTION} = require('./../../../../../conditionList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_TENSER_S_TRANSFORMATION} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_TENSER_S_TRANSFORMATION,
  name: 'Трансформация Тензера',
  nameEn: 'Tenser’s Transformation',
  description: `Вы наделяете себя выносливостью и воинской доблестью, подпитываемыми магией. Пока заклинание не закончится, Вы не можете сотворять заклинания, но получаете следующие преимущества:

* Вы получаете 50 временных хитов. Если какое-либо их количество остаётся, когда заклинание заканчивается, они теряются.
* Вы совершаете с преимуществом все броски атаки, которые Вы делаете простым или воинским оружием.
* Когда Вы попадаете по цели атакой оружием, она получает дополнительный урон силовым полем 2к12.
* Вы получаете владение всеми видами доспехов, щитов, простого и воинского оружия.
* Вы получаете владение испытаниями Силы и Телосложения.
* Если Вы в свой ход совершаете действие Атака, Вы можете совершить две атаки вместо одной. Вы игнорируете это преимущество, если у Вас уже есть подобное умение, которое даёт Вам дополнительные атаки (например, Дополнительная атака).

Сразу после того, как заклинание оканчивается, Вы должны пройти испытание Телосложения СЛ 15 или получаете один уровень истощения.`,
  lvl: 6,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `несколько волосков быка`,
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    type: [
      ATTACK_MELEE_WEAPON,
      ATTACK_RANGE_WEAPON,
    ],
    condition: CONDITION_EXHAUSTION,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 2,
      diceType: 12,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 169,
  },
}
