module.exports = [
  require('./aarakocra'),
  require('./aboleth'),
  require('./abominable_yeti'),
  require('./acolyte'),
  require('./air_elemental'),
  require('./allosaurus'),
  require('./androsphinx'),
  require('./animated_armor'),
  require('./ankheg'),
  require('./ankylosaurus'),
  require('./ape'),
  require('./arcanaloth'),
  require('./archmage'),
  require('./assassin'),
  require('./awakened_shrub'),
  require('./awakened_tree'),
  require('./axe_beak'),
  require('./azer'),
]
