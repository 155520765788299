const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_ALCHEMY_JUG,
} = require('./../../../../../magicItemIdList')

const liquidTextTable = require('./../../constants/liquidTextTable')

module.exports = {
  id: MAGIC_ITEM_ALCHEMY_JUG,
  name: `Алхимический сосуд`,
  nameEn: `Alchemy Jug`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Этот керамический кувшин, кажется способным вместить 1 галлон жидкости и весит 12 фунтов вне зависимости от того, полный он или пустой. Если его потрясти, то можно услышать звуки плескающийся жидкости, даже если кувшин пуст.

Вы можете действием назвать одну жидкость из приведённой ниже таблицы, отчего кувшин начнёт её производить. После этого Вы можете ещё одним действием откупорить кувшин и вылить эту жидкость из сосуда со скоростью до 2 галлонов в минуту. Максимальное количество жидкости, которое может произвести кувшин, зависит от вида жидкости, названной Вами.

После того, как кувшин начинает производить выбранную жидкость, он не может производить другую, или произвести названную жидкость в объёме больше её максимума, пока не наступит следующий рассвет. 

${liquidTextTable}
`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 151,
  },
}
