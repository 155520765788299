const {PC_SUBCLASS_WARLOCK_THE_FIEND} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BURNING_HANDS,
  SPELL_COMMAND,
  SPELL_FIRE_SHIELD,
  SPELL_FIREBALL,
  SPELL_FLAME_STRIKE,
  SPELL_HALLOW,
  SPELL_SCORCHING_RAY,
  SPELL_STINKING_CLOUD,
  SPELL_WALL_OF_FIRE,
} = require('./../../../spellIdList')

const {
  FEATURE_DARK_ONE_S_BLESSING,
  FEATURE_DARK_ONE_S_OWN_LUCK,
  FEATURE_EXPANDED_SPELLS_WARLOCK_THE_FIEND,
  FEATURE_FIENDISH_RESILIENCE,
  FEATURE_HURL_THROUGH_HELL,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_WARLOCK_THE_FIEND,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    text: `Исчадие позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_BLINDNESS_DEAFNESS,
      SPELL_BURNING_HANDS,
      SPELL_COMMAND,
      SPELL_FIRE_SHIELD,
      SPELL_FIREBALL,
      SPELL_FLAME_STRIKE,
      SPELL_HALLOW,
      SPELL_SCORCHING_RAY,
      SPELL_STINKING_CLOUD,
      SPELL_WALL_OF_FIRE,
    ],
  },
  {
    id: FEATURE_DARK_ONE_S_BLESSING,
    name: `Благословение темнейшего`,
    nameEn: `Dark One’s Blessing `,
    lvl: 1,
    text: `Когда Вы опускаете хиты враждебного существа до 0, Вы получаете временные хиты, равные Вашему модификатору Харизмы + Ваш уровень колдуна (минимум 1).`,
  },
  {
    id: FEATURE_DARK_ONE_S_OWN_LUCK,
    name: `Удача темнейшего`,
    nameEn: `Dark One's Own Luck`,
    lvl: 6,
    text: `Вы можете воззвать к своему покровителю, чтобы изменить судьбу в свою пользу. Когда Вы совершаете проверку характеристики или проходите испытание, Вы можете использовать это умение, чтобы добавить к10 к броску. Вы можете так сделать после основного броска, но до того, как его эффект вступит в силу.

Использовав это умение, Вы не можете использовать его снова до окончания короткого или длинного отдыха.`,
  },
  {
    id: FEATURE_FIENDISH_RESILIENCE,
    name: `Устойчивость исчадия`,
    nameEn: `Fiendish Resilience`,
    lvl: 10,
    text: `Вы можете выбрать один вид урона, когда заканчиваете короткий или длинный отдых.

Вы получаете сопротивление этому виду урона, пока Вы не выберете другой вид урона при помощи этого умения.

Урон от магического или серебряного оружия игнорирует это сопротивление.`,
  },
  {
    id: FEATURE_HURL_THROUGH_HELL,
    name: `Бросок сквозь Ад`,
    nameEn: `Hurl Through Hell`,
    lvl: 14,
    text: `Когда Вы попадаете по существу атакой, Вы можете использовать это умение, чтобы мгновенно переместить цель сквозь нижние планы. Существо исчезает и несётся через кошмарный ландшафт.

В конце Вашего следующего хода цель возвращается в пространство, которое занимало, или ближайшее свободное пространство. Если цель — не исчадие, она получает урон психической энергией 10к10 от испытанных жутких ощущений.

Использовав это умение, Вы не можете использовать его снова до окончания длинного отдыха.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_FIEND,
    source: {
      id: SOURCE_PHB,
      page: 110,
    },
  })
)
