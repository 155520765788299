const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_LIFE_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')

const {
  SPELL_BEACON_OF_HOPE,
  SPELL_BLESS,
  SPELL_CURE_WOUNDS,
  SPELL_DEATH_WARD,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_LESSER_RESTORATION,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_RAISE_DEAD,
  SPELL_REVIVIFY,
  SPELL_SPIRITUAL_WEAPON,
} = require('./../../../spellIdList')
const {
  FEATURE_BLESSED_HEALER,
  FEATURE_BONUS_PROFICIENCY_LIFE_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_PRESERVE_LIFE,
  FEATURE_DISCIPLE_OF_LIFE,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_STRIKE_LIFE_DOMAIN,
  FEATURE_LIFE_DOMAIN_SPELLS,
  FEATURE_SUPREME_HEALING,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_LIFE_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_BLESS,
      SPELL_CURE_WOUNDS,
      SPELL_SPIRITUAL_WEAPON,
      SPELL_LESSER_RESTORATION,
      SPELL_REVIVIFY,
      SPELL_BEACON_OF_HOPE,
      SPELL_DEATH_WARD,
      SPELL_GUARDIAN_OF_FAITH,
      SPELL_MASS_CURE_WOUNDS,
      SPELL_RAISE_DEAD,
    ],
    source: {
      id: SOURCE_PHB,
      page: 61,
    },
  },
  {
    id: FEATURE_BONUS_PROFICIENCY_LIFE_DOMAIN,
    name: `Бонусное владение`,
    nameEn: `Bonus Proficiency`,
    lvl: 1,
    text: `Вы осваиваете владение тяжёлыми доспехами.`,
    source: {
      id: SOURCE_PHB,
      page: 61,
    },
  },
  {
    id: FEATURE_DISCIPLE_OF_LIFE,
    name: `Поборник жизни`,
    nameEn: `Disciple of Life`,
    lvl: 1,
    text: `Ваши лечащие заклинания становятся более эффективными.

Каждый раз, когда Вы используете заклинание 1 круга или выше, восстанавливающее хиты существу, это существо восстанавливает дополнительно число хитов, равное 2 + круг заклинания.`,
    source: {
      id: SOURCE_PHB,
      page: 61,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_PRESERVE_LIFE,
    name: `Божественный канал: Сохранение жизни`,
    nameEn: `Channel Divinity: Preserve Life`,
    lvl: 2,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы лечить тяжёлые ранения.

Вы действием демонстрируете свой священный символ и призываете целительную энергию, которая может восстановить число хитов, равное Вашему уровню жреца, умноженному на пять. Выберите любых существ в пределах 30 футов от себя и распределите эти хиты между ними.

Это умение не может восстановить существу хиты выше половины от его максимума.

Вы не можете использовать это умение на нежити и конструктах.`,
    source: {
      id: SOURCE_PHB,
      page: 61,
    },
  },
  {
    id: FEATURE_BLESSED_HEALER,
    name: `Благословенный целитель`,
    nameEn: `Blessed Healer`,
    lvl: 6,
    text: `Заклинания лечения, которые Вы сотворяете на других, также лечат и Вас.

Когда Вы сотворяете заклинание 1 круга или выше, которое восстанавливает хиты другому существу, Вы восстанавливаете число хитов, равное 2 + круг заклинания.`,
    source: {
      id: SOURCE_PHB,
      page: 61,
    },
  },
  {
    id: FEATURE_DIVINE_STRIKE_LIFE_DOMAIN,
    name: `Божественный удар`,
    nameEn: `Divine Strike`,
    lvl: [8, 14],
    text: `Вы получаете способность наполнять удары своего оружия божественной энергией.

Один раз в каждый свой ход, когда Вы попадаете по существу атакой оружием, Вы можете причинить цели дополнительный урон излучением 1к8.

Когда Вы достигаете 14 уровня, дополнительный урон возрастает до 2к8.`,
    source: {
      id: SOURCE_PHB,
      page: 62,
    },
  },
  {
    id: FEATURE_SUPREME_HEALING,
    name: `Высшее исцеление`,
    nameEn: `Supreme Healing`,
    lvl: 17,
    text: `Если Вы должны бросить одну или несколько костей, чтобы восстановить хиты заклинанием, Вы для каждой кости используете максимальное значение. Например, вместо восстановления существу 2к6 хитов, Вы восстанавливаете 12. `,
    source: {
      id: SOURCE_PHB,
      page: 62,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_LIFE_DOMAIN,
  })
)
