const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_1_4,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_PLANT,
  CREATURE_TYPE_VEGEPYGMY,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_VEGEPYGMY,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_ELECTRICITY,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_VEGEPYGMY,
} = require('./../../../../creatureIdList')
const {
  GEAR_SLING,
} = require('./../../../../gearIdList')
const {
  ABILITY_PLANT_CAMOUFLAGE,
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  moldCreaturesDescriptionList,
  moldCreaturesNote,
} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Вегепигмей',
  nameAlt: 'Плесневик',
  nameEn: 'Vegepygmy',
  id: CREATURE_VEGEPYGMY,
  description: moldCreaturesDescriptionList,
  note: moldCreaturesNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
    CREATURE_TYPE_VEGEPYGMY,
  ],
  alignmentId: ALIGNMENT_N,
  source: [
    {
      id: SOURCE_VGTM,
      page: 132,
    },
    {
      id: SOURCE_TOA,
      page: 217,
    },
  ],
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_PIERCING,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_VEGEPYGMY,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_PLANT_CAMOUFLAGE,
    {
      id: ABILITY_REGENERATION,
      regen: 3,
      other: `. Если ★он★ получает урон холодом, огнём или некротический урон, то эта особенность не работает в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только если начинает ход с 0 хитов и не может регенерировать`,
    },
  ],
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_SLING,
    },
  ],
}
