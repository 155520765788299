module.exports = [
  {
    text: `Юань-ти отбросили человечность давным-давно, а вместе с ним и свое здравомыслие`,
    author: `Из книги «Владыки запретного города» Кодо Видака`,
  },
  {
    text: `У вас не получится вызвать ненависть или страх или разбудить любовь или дружбу у кого-либо из змеиного народа. Они могут изобразить таковые, чтобы вам было, комфортнее, но внутри они всегда холодно и спокойно расчётливы.`,
    author: `Эльминстер`,
  },
  {
    text: `Всегда спокойные, расчётливые махинаторы, врождённые обманщики, к тому же обладающие иммунитетом к ядам — юань-ти являются идеальными придворными. И, что ещё хуже для всех нас, такими же правителями.`,
    author: `Эльминстер`,
  },
  {
    text: `От Калимшана до Озера Пара, по торговым путям всего мира, повсюду из-под полы продаются яды и крепкие напитки юань–ти. Будьте осторожны! Некоторые из них медленно и незаметно ослабляют пьющего, открывая их и давая контроль над ними ближайшему же встречному чистокровному змеиному шпиону или проклинателю.`,
    author: `Эльминстер`,
  },
  {
    text: `Ходят легенды о том, что юань–ти проникли в человеческие города и создали ужасно тайные общества, которые торгуют наркотиками и свитками заклинаний, шантажируют купцов и имеют влияние на королей. Но из–за недостатка доказательств я не склонен верить этим сказкам.`,
    author: `Воло`,
  },
  {
    text: `А стоило бы`,
    author: `Эльминстер`,
  },
  {
    text: `Пирамиды, площади и фонтаны — всё создано из камня и украшено резными змеями. Всё там казалось старинным — таким же древним, как эльфийский город, запретный в своей странной красоте`,
    author: `Воло`,
  },
]
