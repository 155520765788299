const {gearByCatCollection} = require('./../../../gearList')

const gearCategoryList = require('./gearCategoryList')

module.exports = gearCategoryList.reduce(
  (listByCats, catId) => [
    ...listByCats,
    ...gearByCatCollection[catId].map(
      gearId => ({
        id: `living_${gearId}`,
        gearId,
      })
    )
  ],
  []
)
