module.exports = `
| Уровень |1|2|3|4|5|6|7|8|9| 
|---------|-|-|-|-|-|-|-|-|-| 
|  1      |2| | | | | | | | |  
|  2      |3| | | | | | | | |  
|  3      |4|2| | | | | | | |  
|  4      |4|3| | | | | | | |  
|  5      |4|3|2| | | | | | |  
|  6      |4|3|3| | | | | | |  
|  7      |4|3|3|1| | | | | |  
|  8      |4|3|3|2| | | | | |  
|  9      |4|3|3|3|1| | | | |  
| 10      |4|3|3|3|2| | | | |  
| 11–12   |4|3|3|3|2|1| | | |  
| 13–14   |4|3|3|3|2|1|1| | |  
| 15–16   |4|3|3|3|2|1|1|1| |  
| 17      |4|3|3|3|2|1|1|1|1|  
| 18      |4|3|3|3|3|1|1|1|1|  
| 19      |4|3|3|3|3|2|1|1|1|  
| 20      |4|3|3|3|3|2|2|1|1|  
`
