const {GEAR_CALLIGRAPHERS_SUPPLIES} = require('./../../../gearIdList')
const {PC_SUBCLASS_WIZARD_ONOMANCY_2019_10_03} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES} = require('./../../../sourceList')
const {
  SPELL_BANE,
  SPELL_BLESS,
} = require('./../../../spellIdList')
const {
  FEATURE_BONUS_PROFICIENCIES_WIZARD_ONOMANCY_2019_10_03,
  FEATURE_EXTRACT_NAME_2019_10_03,
  FEATURE_FATEFUL_NAMING_2019_10_03,
  FEATURE_INEXORABLE_PRONOUNCEMENT_2019_10_03,
  FEATURE_RELENTLESS_NAMING_2019_10_03,
  FEATURE_RESONANT_UTTERANCE_2019_10_03,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_FATEFUL_NAMING_2019_10_03,
    name: `Важное называние`,
    nameEn: `Fateful Naming`,
    lvl: 2,
    text: `Вы можете изменять магию, чтобы помогать или мешать существам силой их истинных имен, или даже использовать эти имена, чтобы влиять на окружающих. Вы добавляете заклинания [Порча](SPELL:${SPELL_BANE}) и [Благословение](SPELL:${SPELL_BLESS}) в свою книгу заклинаний и они считаются для Вас заклинаниями волшебника. Они всегда у вас подготовлены, но не учитываются в количестве заклинаний волшебника, которые Вы можете подготавливать.

Вы можете сотворить любое заклинание, не тратя ячейку заклинания, если Вы произносите истинное имя одной цели заклинания как часть его наложения. Вы можете сотворить заклинания таким образом количество раз, равное Вашему модификатору Интеллекта (минимум один раз), и Вы восстанавливаете все затраченные использования этого умения, когда заканчиваете длительный отдых.`,
    spellIdList: [
      SPELL_BANE,
      SPELL_BLESS,
    ],
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 4,
    },
  },
  {
    id: FEATURE_BONUS_PROFICIENCIES_WIZARD_ONOMANCY_2019_10_03,
    name: `Бонусные владения`,
    nameEn: `Bonus Proficiencies`,
    lvl: 2,
    text: `Вы изучаете один язык по Вашему выбору и получаете владение [инструментами каллиграфа](GEAR:${GEAR_CALLIGRAPHERS_SUPPLIES}).`,
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 4,
    },
  },
  {
    id: FEATURE_EXTRACT_NAME_2019_10_03,
    name: `Извлечение имени`,
    nameEn: `Extract Name`,
    lvl: 2,
    text: `Вы можете магически заставить существо раскрыть своё истинное имя.

Выберите бонусным действием видимое Вами существо в пределах 60 футов от себя. Оно должно пройти испытание Мудрости со СЛ Ваших заклинаний волшебника.

При успешном испытании Вы понимаете, что эта магия не удалась, и Вы больше не можете использовать эту способность на цели.

В случае провала оно очаровывается Вами до конца Вашего следующего хода, и Вы мысленно узнаете имя очарованной цели или тот факт, что у цели отсутствует имя.

Вы можете использовать эту особенность количество раз, равное Вашему модификатору Интеллекта (минимум один раз) и восстанавливаете все использования, когда завершаете длинный отдых.`,
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 4,
    },
  },
  {
    id: FEATURE_RESONANT_UTTERANCE_2019_10_03,
    name: `Произнесение резонантов`,
    nameEn: `Resonant Utterance`,
    lvl: 6,
    text: `Вы изучаете слова силы под названием «резонанты», которые позволяют адаптировать Ваши заклинания, используя истинное имя цели.

# Известные резонанты

Когда Вы получаете это умение, Вы изучаете два резонанта на Ваш выбор, которые подробно описаны в разделе _«Виды резонантов»_. Каждый раз, когда Вы получаете уровень в этом классе, Вы можете заменить один известный Вам резонант другим.

# Использование резонантов

Вы можете использовать один резонант, когда Вы сотворяете заклинание волшебника, затрачивая ячейку заклинания и произносите истинное имя одного существа, на которое нацелено заклинание. Вы произносите имя как часть наложения заклинания.

Вы можете использовать резонанты количество раз, равное половине Вашего уровня волшебника, и восстанавливаете все затраченные использование, когда заканчиваете длительный отдых.`,
    listAllResonants: true,
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 4,
    },
  },
  {
    id: FEATURE_INEXORABLE_PRONOUNCEMENT_2019_10_03,
    name: `Непреклонное произношение`,
    nameEn: `Inexorable Pronouncement`,
    lvl: 10,
    text: `Вы изучаете два дополнительных резонанта из способности [Произнесение резонантов](FEATURE:${FEATURE_RESONANT_UTTERANCE_2019_10_03}).`,
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 5,
    },
  },
  {
    id: FEATURE_RELENTLESS_NAMING_2019_10_03,
    name: `Непреклонное называние`,
    nameEn: `Relentless Naming`,
    lvl: 14,
    text: `Вы узнаёте, как обойти защиту названного существа от определенных видов урона.

Когда Вы сотворяете заклинание, которое наносит урон существу, чье настоящее имя Вы произносите как часть сотворения заклинания, Вы можете заставить это заклинание наносить существу урон силовым полем или психический вместо обычного типа урона заклинания.`,
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 5,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_ONOMANCY_2019_10_03,
  })
)
