const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_GREATSWORD,
  GEAR_PLATE_ARMOR,
  GEAR_SHORTBOW,
} = require('./../../../../gearIdList')
const {
  SKILL_ATHLETICS,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  SOURCE_ERRATA_VGTM,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_CHAMPION,
  CREATURE_NOBLE,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_9} = require('./../../../../crList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Чемпион',
  nameEn: 'Champion',
  id: CREATURE_CHAMPION,
  description: `Чемпионы — могущественные воины, которые оттачивали свои боевые навыки в войне или ямах гладиаторов. Будучи солдатами и другими людьми, которые сражаются за жизнь, чемпионы влиятельны как [дворяне](CREATURE:${CREATURE_NOBLE}), и их присутствие является показателем статуса правителей.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: [
    {
      id: SOURCE_VGTM,
      page: 222,
    },
    {
      id: SOURCE_ERRATA_VGTM,
      title: 'Лёгкий арбалет заменён на короткий лук',
      page: 1,
    },
  ],
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 22,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      comment: 'обычно — Всеобщий',
    },
  ],
  cr: CR_9,
  featureList: [
    {
      name: 'Упорный',
      limit: {
        count: 2,
        period: 'день',
      },
      description: `Чемпион может перебросить проваленное испытание.`,
    },
    {
      name: 'Второе дыхание',
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `Бонусным действием чемпион может восстановить 20 хитов.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Чемпион совершает три атаки _Двуручным мечом_ или _Лёгким арбалетом_.`,
    },
    {
      gearId: GEAR_GREATSWORD,
      attack: {
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          comment: ` если у чемпиона больше половины от максимума хитов`,
        },
      },
    },
    {
      gearId: GEAR_SHORTBOW,
      attack: {
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          comment: ` если у чемпиона больше половины от максимума хитов`,
        },
      },
    },
  ],
}
