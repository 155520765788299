const {
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_DRUIDCRAFT,
  SPELL_GUIDANCE,
  SPELL_LEOMUND_S_TINY_HUT,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_RESISTANCE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_WALK,
  SPEED_CLIMB,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  SKILL_ACROBATICS,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ABILITY_EVASION} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {CAST_SOMATIC} = require('./../../../../castComponentList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_CHWINGA} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {GOD_AURIL} = require('./../../../../godIdList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {
  SOURCE_IDRotF,
  SOURCE_TOA,
} = require('./../../../../sourceList')

module.exports = {
  name: 'Чвинга',
  nameEn: 'Chwinga',
  id: CREATURE_CHWINGA,
  description: [
    `Чвинга — это крошечный стихийный дух, который живёт в растениях, скалах и реках, далёких от цивилизации. Крайне застенчивые, чвинги предпочитают оставаться незаметными.

Если два или более чвинги живут в одном и том же районе, каждый из них будет скрываться в разных растениях, скале или пресной воде. Они напоминают 6-дюймовые анимированные куклы со странными масками, прямыми конечностями и дикими волосами, но при этом два чвинги не похожи друг на друга. Их появление иногда предвещается прохладным бризом, сладким запахом цветов и танцующими светлячками. Само их присутствие делает природную обстановку более пышной и яркой.`,
    {
      header: 'Гуманоидное увлечение',
      text: `Чвинги избегает большинства других существ, но находят атрибуты цивилизации увлекательными. Для них существа, которые носят доспехи, оружие, используют инструменты и готовят пищу — большая загадка. Когда чвинга сталкивается с одним или несколькими гуманоидами, любопытство иногда завладевает им, и он недолго следует за этими существами, чтобы наблюдать за ними. Если оно проявляет симпатию к конкретному гуманоиду, то чвинга может использовать свои заговоры, чтобы помочь ему, или даже дать волшебный дар перед тем как уйти. Особенности, которые привлекают чвингу к определённому гуманоиду, могут различаться. В некоторых случаях чвинге может просто нравиться то, как гуманоид гуляет, или то, как он расчёсывает волосы. В других случаях он может быть поражён способностью гуманоида играть музыку или есть обильное количество пищи.`,
      source: [
        {
          id: SOURCE_TOA,
          page: 240,
        },
        {
          id: SOURCE_IDRotF,
          page: 283,
        },
      ],
    },
    {
      header: 'Стихийная натура',
      text: `Чвинге не нужен воздух, еда, питьё и сон. У них нет имён и нет возможности говорить. Когда чвинга умирает, он превращается в горсть цветочных лепестков, облако пыльцы, каменную статуэтку, напоминающую его прежнего «я», гладкий каменный шарик или лужу пресной воды.`,
      source: {
        id: SOURCE_TOA,
        page: 240,
      },
    },
    {
      header: 'Чвинги в Долине Ледяного Ветра',
      text: `Чвинги — существа, изначально населяющие тропический полуостров Чульт. Но, будучи духами стихий, они распространились далеко оттуда. Большое их количество пришло в Долину Ледяного Ветра вместе с иммигрантами из Чульта и поселилось в и вокруг Десяти Городов. Те их них, кто поселился возле цивилизации, адоптировал для себя наряды их гуманоидных соседей, создавая себя небольшие плащи. Иногда их можно встретить едущими верхом на песцах или арктических зайцах. Другие же чвинги углубились в тундру, поменяв свой цвет для соответствия окружающей среде.

Проявления ночным сияниям [Ориль](GOD:${GOD_AURIL}) и создаваемые ею неестественно жестокие вьюги могут менять поведение чвинг, делая его ещё более непредсказуемым, даже безумным.`,
      source: {
        id: SOURCE_IDRotF,
        page: 284,
      },
    },
    {
      header: 'Новые дары чвинг',
      text: `Здесь описаны несколько новых волшебных даров, которые могут давать чвинги, живущие в Долине Ледяного Ветра или других холодных регионах.

# Дар кусачего холода

У этого дара есть 3 заряда. Вы можете бонусным действием потратить 1 заряд дара и на 1 минуту вплести кусачий холод в атаки вашего оружия. Пока эффект действует, вы наносите дополнительно 1к6 урона холодом, когда попадаете рукопашной или дальнобойной атакой оружием. Когда все заряды дара израсходуются, он исчезнет.

# Дар ледяного тролля

Когда Вы получаете урон холодом, Вы можете реакцией уменьшить этот урон до 0 и вместо него восстановить количество хитов, равное половине этого урона. Когда Вы так сделаете, дар исчезнет.

# Дар снежков

У этого дара есть 5 зарядов. Вы можете бонусным действием потратить 1 заряд дара, создавая в руке волшебный снежок и бросая его. Для этого у вас должна быть свободная рука, или заряд потратится впустую. Снежок — это магическое дальнобойное оружие, которым Вы владеете; его нормальная дальность 20 футов, а максимальная — 60 футов; при попадании снежок наносит 1к4 урона холодом; его критическими попаданиями считаются 19 и 20. Если Вы наносите снежком критический удар, цель Ослепляется до конца своего следующего хода. Снежок исчезает немедленно посте попадания или промаха, либо в конце Вашего хода, если Вы его не бросили. Когда все заряды дара израсходуются, он исчезнет.

# Дар снежного ходока

У этого дара есть 3 заряда. Вы можете действием потратить 1 заряд дара, получая следующие преимущества на 24 часа.

* Вы и Ваши союзники в пределах 15 футов игнорируют труднопроходимую местность, создаваемую снегом и льдом.
* Вы можете видеть сквозь сильно заслонённые снегом области в пределах 60 футов.
* Вы получаете иммунитет к эффектам экстремального холода (как указано в Руководстве Мастера), но не получаете защиты от урона холодом. 

Когда все заряды дара израсходуются, он исчезнет.

# Дар сопротивления холоду

Этот дар позволяет Вам действием дать себе сопротивление холоду. Это преимущество длится 24 часа. Когда все заряды дара израсходуются, он исчезнет.

# Дар убежища путников

У этого дара есть 3 заряда. Вы можете действием потратить 1 заряд дара для сотворения заклинания [Леомундова хижина](SPELL:${SPELL_LEOMUND_S_TINY_HUT}) без каких-либо компонентов. Когда все заряды дара израсходуются, он исчезнет.

# Дар щедрости

У этого дара есть 3 заряда. Вы можете действием потратить 1 заряд дара для сотворения заклинания [Сотворение пищи и воды](SPELL:${SPELL_CREATE_FOOD_AND_WATER}) без каких-либо компонентов. Когда все заряды дара израсходуются, он исчезнет.`,
      source: {
        id: SOURCE_IDRotF,
        page: 284,
      },
    },
  ],
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: [
    {
      id: SOURCE_TOA,
      page: 241,
    },
    {
      id: SOURCE_IDRotF,
      page: 283,
    },
  ],
  armor: 15,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_CLIMB]: 20,
    [SPEED_SWIM]: 20,
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 10,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_EVASION,
    {
      name: 'Необычная природа',
      description: `Чвинге не нужен воздух, еда, питьё и сон. Когда чвинга умирает, он превращается в горсть цветочных лепестков, облако пыльцы, каменную статуэтку, напоминающую его прежнего «я», гладкий каменный шарик или лужу пресной воды (на выбор Мастера).`,
    },
  ],
  spellCast: {
    baseStat: PARAM_WIT,
    componentOnly: CAST_SOMATIC,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DRUIDCRAFT,
          SPELL_GUIDANCE,
          SPELL_PASS_WITHOUT_TRACE,
          SPELL_RESISTANCE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Волшебный дар',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ нацеливается на видимого ★им★ в пределах 5 футов гуманоида. Цель получает сверхъестественный дар, по выбору Мастера.

Более подробную информацию о сверхъестественных дарах Вы можете найти в 7 главе Руководства Мастера.`,
    },
    {
      name: 'Естественное укрытие',
      description: `★СУЩЕСТВО★ волшебным образом укрывается внутри скалы, живого растения или природного источника пресной воды, находясь с ★ним★ в одном пространстве.

★СУЩЕСТВО★ не может быть целью какой-либо атаки, заклинания или другого эффекта, пока находится внутри этого убежища, а само убежище не мешает ★его★ слепому зрению. ★СУЩЕСТВО★ может выйти из укрытия действием. Если ★его★ убежище разрушено, ★СУЩЕСТВО★ принудительно вываливается наружу и появляется в пространстве, где раньше находилось укрытие, но при этом ★сам★ ★СУЩЕСТВО★ остаётся ★невредим★.`,
    },
  ],
}
