const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  GEAR_ARROWS,
} = require('./../../../../../gearIdList')
const {
  CAT_AMMUNITION,
} = require('./../../../../../gearCategoryList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_ARROW_OF_SLAYING,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_ARROW_OF_SLAYING,
  name: `Стрела убийства`,
  nameEn: `Arrow of Slaying`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_ARROWS,
  gearCategory: CAT_AMMUNITION,
  rarity: MGC_RARITY_VERY_RARE,
  isConsumable: true,
  description: `Стрела убийства это магическое оружие, предназначенное для убийства существ определённого вида. Область применения может быть как широкой, так и более конкретной; например, существуют и стрелы убийства драконов и стрелы убийства синих драконов. Если существо, принадлежащее к виду, расе или группе, связанной со стрелой убийства, получает от неё урон, оно должно пройти испытание Телосложения СЛ 17, получая дополнительный колющий урон 6к10 при провале или половину этого урона при успехе.

После того как стрела убийства причиняет существу дополнительный урон, она становится немагической стрелой.

Есть и другие разновидности боеприпасов с такой же магией, такие как _арбалетные болты убийства_, хотя стрелы встречаются чаще всего.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 205,
  },
}
