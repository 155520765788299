const {SOURCE_MM} = require('./../../sourceList')

module.exports = {
  header: 'Мимики',
  text: `Мимики — хищники, способные принимать форму неодушевлённых предметов, чтобы заманивать добычу в ловушку. В подземельях эти коварные существа, как правило, принимают вид дверей и сундуков, так как именно подобная форма привлекает к ним постоянный поток добычи.

**Хищники-имитаторы.** Мимики могут менять своё тело так, чтобы оно напоминало дерево, камень или другие простые материалы, а со временем они запоминают внешний вид тех предметов, с которыми чаще всего контактируют другие существа, и превращаются в эти предметы. Мимика, сменившего внешность, практически невозможно узнать, пока потенциальная жертва не приблизится достаточно близко — тогда мимик вытягивает свои ложноножки и атакует.

Меняя облик, мимик выделяет особое клейкое вещество, которое позволяет ему захватывать существ и предметы (оружие), к которым он прикасается. Мимик впитывает это вещество обратно, когда принимает свой аморфный облик, чтобы иметь возможность перемещаться.

**Умные охотники.** Мимики живут и охотятся в одиночку, хотя иногда и делят свои охотничьи угодья с другими существами. Большая часть мимиков по уровню интеллекта ничем не отличаются от животных, однако редкие экземпляры могут развить свой ум, и даже научиться разговаривать на Общем или Подземном. В этом случае с ними можно договориться о безопасном проходе по их территории, или полу- чить от них ценную информация в обмен на пищу.`,
  source: {
    id: SOURCE_MM,
    page: 210,
  },
}
