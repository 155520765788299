const {PC_SUBCLASS_FIGHTER_RUNE_KNIGHT} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {
  GEAR_SMITHS_TOOLS
} = require('./../../../gearIdList')
const {
  FEATURE_BONUS_PROFICIENCIES_RUNE_KNIGHT,
  FEATURE_GIANT_S_MIGHT,
  FEATURE_GREAT_STATURE,
  FEATURE_MASTER_OF_RUNES,
  FEATURE_RUNE_CARVER,
  FEATURE_RUNIC_JUGGERNAUT,
  FEATURE_RUNIC_SHIELD,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BONUS_PROFICIENCIES_RUNE_KNIGHT,
    name: `Дополнительные владения`,
    nameEn: `Bonus Proficiencies`,
    lvl: 3,
    text: `Вы получаете владение [инструментами кузнеца](GEAR:${GEAR_SMITHS_TOOLS}) и можете говорить, читать, и писать на великаньем.`,
    source: {
      id: SOURCE_TCoE,
      page: 63,
    },
  },
  {
    id: FEATURE_RUNE_CARVER,
    name: `Рунный резчик`,
    nameEn: `Rune Carver`,
    lvl: 3,
    text: `Вы можете использовать магические руны для улучшения своего снаряжения.

Вы изучаете две руны по своему выбору из числа описанных ниже рун, и каждый раз, повышая уровень в этом классе, можете заменить одну известную руну на другую. Достигая определённых уровней в этом классе, Вы изучите дополнительные руны.

# Известные руны

| Уровень воина | Число известных рун |
|---------------|---------------------|
|  3            | 2                   |
|  7            | 3                   |
| 10            | 4                   |
| 15            | 5                   |

Заканчивая длинный отдых, Вы можете прикоснуться к предметам (по количеству известных Вам рун), и начертить разные руны на каждом из них. Предметы должны быть оружием, доспехами, щитами, ювелирными украшениями или чем-то ещё, что Вы можете носить или держать в руке. Ваша руна остаётся на предмете, пока Вы не закончите новый длинный отдых, и предмет может содержать только одну Вашу руну одновременно.

Если в требованиях руны указан уровень, то Вы должны быть не ниже этого уровня в этом классе, чтобы её изучить. Если эффект руны требует испытания, Ваша СЛ рунической магии равна **8 + Ваш бонус мастерства + Ваш модификатор Телосложения**.`,
    listAllRunes: true,
    source: {
      id: SOURCE_TCoE,
      page: 63,
    },
  },
  {
    id: FEATURE_GIANT_S_MIGHT,
    name: `Великанья мощь`,
    nameEn: `Giant’s Might`,
    lvl: 3,
    text: `Вы научились наделить себя силой великанов.

Вы можете бонусным действием магически получить следующие преимущества, которые длятся 1 минуту:

* Если Вы меньше Большого размера, то Вы становитесь Большим вместе со всем, что Вы носите. Если Вам не хватает места, чтобы стать Большим, то Ваш размер не изменяется.
* Вы совершаете с преимуществом проверки и испытания Силы.
* Один раз на каждом Вашем ходу одна из Ваших атак оружием или безоружным ударом может нанести цели дополнительно 1к6 при попадании.

Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 65,
    },
  },
  {
    id: FEATURE_RUNIC_SHIELD,
    name: `Рунный щит`,
    nameEn: `Runic Shield`,
    lvl: 7,
    text: `Вы научились использовать руническую магию для защиты своих союзников.

Когда по другому видимому Вами в пределах 60 футов существу попадают атакой, Вы можете реакцией заставить атакующего перебросить к20 юроска атаки и использовать новый бросок.

Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 65,
    },
  },
  {
    id: FEATURE_GREAT_STATURE,
    name: `Огромный рост`,
    nameEn: `Great Stature`,
    lvl: 10,
    text: `Магия рун навсегда изменяет Вас.

Получив эту способность, Вы увеличиваете свой рост на 3к4 дюймов.

Дополнительный урон, наносимый Вашей [Великаньей мощью](FEATURE:${FEATURE_GIANT_S_MIGHT}), увеличивается до 1к8.`,
    source: {
      id: SOURCE_TCoE,
      page: 65,
    },
  },
  {
    id: FEATURE_MASTER_OF_RUNES,
    name: `Мастер рун`,
    nameEn: `Master of Runes`,
    lvl: 15,
    text: `Теперь Вы можете дважды активировать каждую руну, которую Вы узнали способностью [Рунный резчик](FEATURE:${FEATURE_RUNE_CARVER}), и восстанавливаете все использования, когда заканчиваете короткий или длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 65,
    },
  },
  {
    id: FEATURE_RUNIC_JUGGERNAUT,
    name: `Рунный джаггернаут`,
    nameEn: `Runic Juggernaut`,
    lvl: 18,
    text: `Вы узнаёте как усиливать свою рунную трансформацию.

Дополнительный урон, наносимый Вашей [Великаньей мощью](FEATURE:${FEATURE_GIANT_S_MIGHT}), увеличивается до 1к10.

Кроме того, когда Вы используете [Великанью мощь](FEATURE:${FEATURE_GIANT_S_MIGHT}), Ваш размер может увеличиться до огромного, и, пока Вы этого размера, Ваша дальность увеличивается на 5 футов.`,
    source: {
      id: SOURCE_TCoE,
      page: 66,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_RUNE_KNIGHT,
  })
)
