const generateRarityList = require('./../../../../../../utils/generateRarityList')

const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {MAGIC_ITEM_AMULET_OF_THE_DEVOUT} = require('./../../../../../magicItemIdList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {GEAR_AMULET} = require('./../../../../../gearIdList')
const {FEATURE_CHANNEL_DIVINITY_CLERIC} = require('./../../../../../featureIdList')

const siblingIdList = require('./siblingIdList')
const list = require('./list')

module.exports = {
  id: MAGIC_ITEM_AMULET_OF_THE_DEVOUT,
  name: `Амулет благочестия, +1, +2 или +3`,
  nameEn: `Amulet of the Devout, +1, +2 or +3`,
  rarity: generateRarityList(list),
  gearType: GEAR_AMULET,
  attunement: true,
  attunementComment: `жрецом или паладином`,
  type: MGC_TYPE_WONDROUS_ITEM,
  description: `На _Амулете благочестия_ изображён символ божества, инкрустированный драгоценными камнями или металлами. 

Нося _Амулет благочестия_, Вы получаете бонус к броскам атаки заклинаниями и СЛ испытаний Ваших заклинаний. Размер бонуса определяется редкостью _Амулета_.

Нося этот священный символ, Вы можете использовать свой [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}) без траты его использований. Использовав эту способность _Амулета благочестия_, Вы не можете повторно её использовать, пока не окончите длинный отдых.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 172,
  },
}
