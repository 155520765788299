const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  oozeDescriptionList,
  oozeNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_GELATINOUS_CUBE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_OOZE} = require('./../../../../creatureTypeIdList')
const {DAMAGE_ACID} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Студенистый куб`,
  nameAlt: `Желатиновый куб`,
  nameEn: 'Gelatinous Cube',
  id: CREATURE_GELATINOUS_CUBE,
  description: [
    `Студенистый куб прочёсывает проходы подземелья в тишине, действуя предсказуемо и оставляя идеально чистый след за собой. Он поглощает плоть, оставляя кости и твёрдые материалы нерастворёнными. Студенистый куб практически полностью прозрачен, что делает затруднительным его обнаружение до тех пор, пока он не атакует. Хорошо питающийся куб более заметен, так как останки его жертв, кости, монеты и другие объекты, находятся в его теле во взвешенном состоянии.`,
    ...oozeDescriptionList,
  ],
  note: oozeNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_OOZE,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 270,
  },
  armor: 6,
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 15,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 3,
    [PARAM_CON]: 20,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 1,
  },
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: `Куб слизи`,
      description: `★СУЩЕСТВО★ занимает всё своё пространство. Другие существа могут входить в его пространство, но делающие это существа подвергаются его _Поглощению_, и проходят испытание с помехой.

Существа внутри куба видны, но обладают полным укрытием.

Существо в пределах 5 футов от ★СУЩЕСТВО★ может действием вытянуть существо или предмет из ★него★. Для этого оно должно преуспеть в проверке Силы СЛ 12, и в любом случае оно получает урон кислотой 10 (3к6).

★СУЩЕСТВО★ может держать внутри себя одновременно только одно существо Большого размера или до четырёх существ с размером не больше Среднего.`,
    },
    {
      name: `Прозрачный`,
      description: `Даже если ★СУЩЕСТВО★ находится рядом, требуется проверка Мудрости (Внимательность) СЛ 15, чтобы обнаружить ★СУЩЕСТВО★, который не перемещается и не атакует. Существо, пытающееся войти в ★его★ пространство куба, не зная о ★его★ присутствии, захватывается ★им★ врасплох.`,
    },
  ],
  actionList: [
    {
      name: `Ложноножка`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_ACID,
          diceCount: 3,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
    {
      name: `Поглощение`,
      description: `★СУЩЕСТВО★ перемещается на расстояние, не превышающее его скорость. При этом ★он★ может входить в пространство существ с размером не больше Большого. Каждый раз, когда ★СУЩЕСТВО★ входит в пространство существа, это существо должно пройти испытание Ловкости СЛ 12.

При успешном испытании существо выбирает, оттолкнуться на 5 футов от ★СУЩЕСТВО★ или в сторону от ★него★. Если существо решило не отходить, оно получает последствия проваленного испытания.

При провале ★СУЩЕСТВО★ входит в пространство существа, которое получает урон кислотой 10 (3к6) и становится поглощённым. Поглощённое существо не может дышать, является обездвиженым, и получает урон кислотой 21 (6к6) в начале каждого хода ★СУЩЕСТВО★. Когда ★СУЩЕСТВО★ перемещается, поглощённое существо перемещается вместе с ★ним★.

Поглощённое существо может попытаться освободиться, совершая действием проверку Силы СЛ 12. При успехе существо освобождается и входит в пространство на свой выбор в пределах 5 футов от ★СУЩЕСТВО★.`,
    },
  ],
  genderId: GENDER_MALE,
}
