const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_AZER,
  CREATURE_EFREETI,
  CREATURE_FIRE_SNAKE,
  CREATURE_SALAMANDER,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Саламандры',
    text: `[Саламандры](CREATURE:${CREATURE_SALAMANDER}) живут в Море Пепла на Стихийном Плане Огня. У них гибкое тело и острые чешуйки. Невероятная жара омывает их тела, а их жёлтые глаза светятся словно свечи в глубоких глазницах на их ястребиных головах.

Саламандры очень любят власть, а также им нравится поджигать всякие предметы. Вне своего дома они часто становятся причинами крупных пожаров, наблюдая за горящими остовами деревьев. Они также любят спускаться к жерлу вулкана, наблюдая за игрой пламени и магматических скоплений.`,
    source: {
      id: SOURCE_MM,
      page: 254,
    },
  },
  {
    header: 'Огненные змеи',
    text: `Саламандры вылупляются из яиц диаметров в два фута, сделанных из обсидиана. Когда саламандра готова вылупиться, она пробивает себе путь через толстую оболочку яйца, вылезая наружу уже как [огненная змея](CREATURE:${CREATURE_FIRE_SNAKE}). Огненные змеи становятся саламандрами через год.`,
    source: {
      id: SOURCE_MM,
      page: 254,
    },
  },
  {
    header: 'Рабы ифритов',
    text: `Давным-давно [ифриты](CREATURE:${CREATURE_EFREETI}) нанял [эйзеров](CREATURE:${CREATURE_AZER}), чтобы построить легендарный Латунный город, но затем не смогли подчинить себе эту расу, когда эйзеры закончили свою работу. Намерившись отыграться на саламандрах, ифриты преуспели, и получил в своё пользование расу рабов, которые сеяли семена войны и несли разрушения по всем планам мироздания.

Саламандры презирают эйзеров, веря, что если бы они поддались чарам ифритов, то сейчас огненные змеи были бы свободны. Ифриты используют эту вражду, стравливая саламандр и эйзеров друг с другом.

Ифриты не выносят саламандр, служащих другим хозяевам. Когда ифриту попадается саламандра, служащая культам Стихийного Зла, он убивает её, а не захватывает в рабство.`,
    source: {
      id: SOURCE_MM,
      page: 254,
    },
  },
  {
    header: 'Доминирование дворян',
    text: `Хотя саламандры следуют деструктивным импульсам, присущим их пламенному характеру, рабская служба ифритам наложила отпечаток на культуру свободных саламандр. Они управляют своим собственным обществом, используя модель общества ифритов, в которой более крупные и сильные саламандры правят своими меньшими собратьями.

С возрастом саламандры растут в размере и статусе, достигая позиций жестоких дворян среди сородичей. Эти дворяне правят бродячими отрядами саламандр, перемещающимися по Стихийному Плану Огня как пустынные кочевники, и нападающими на разные поселения в целях грабежа.`,
    source: {
      id: SOURCE_MM,
      page: 254,
    },
  },
  {
    header: 'Живые кузницы',
    text: `Саламандры пышут сильным жаром, и в бою их раскалённое докрасна оружие обжигает тела врагов при контакте. Даже подходить к саламандре опасно, так как при приближении плоть покрывается волдырями и ожогами.

Этот природный жар хорошо сочетается с кузнечными навыками саламандр, позволяя им размягчать и деформировать железо и сталь голыми руками. Хотя они и не такие искусные как эйзеры, саламандр считают одними из лучших кузнецов на планах. Могущественные существа призывают их как воинов, но прочие используют их ремесленнические навыки или привязывают к печам и горнам, чтобы генерировать неугасимое пламя.`,
    source: {
      id: SOURCE_MM,
      page: 254,
    },
  },
]
