const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_ANTIMAGIC_FIELD,
  SPELL_ASTRAL_PROJECTION,
  SPELL_PLANE_SHIFT,
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_ASTRAL_DREADNOUGHT,
  CREATURE_GITHYANKI_WARRIOR,
} = require('./../../../../creatureIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_TITAN,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_21} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Астральный дредноут',
  nameEn: 'Astral Dreadnought',
  id: CREATURE_ASTRAL_DREADNOUGHT,
  description: [
    `Огромные и ужасающие чудовища, известные как астральные дредноуты, обитают в серебряной пустоте Астрального Плана, заставляя планарных путешественников трястись при одной мысли о них. Они скользят через астральные туманы с самого рассвета мультивселенной, поедая всех существ, что встречают на своём пути.

Дредноут размером с [древнего красного дракона](CREATURE:${CREATURE_DRAGON_RED_ANCIENT}) и от головы до хвоста покрыт толстыми шипастыми пластинами. На концах его двух искривлённых конечностей бритвенно-острые клешни. В его единственном глазу будто бы кружатся созвездия, а его змеиный бронированный хвост скрывается в серебряной пустоте.

Астральные дредноуты проводят жизнь в одиночестве. В редких случаях, когда два дредноута встречаются, они обычно сражаются до тех пор, пока один из них не вымотается и не уйдёт. У некоторых могущественных злодеев получалось поработить астральных дредноутов и найти их способностям ужасающее применение.`,
    {
      header: 'Антимагический глаз',
      text: `Астральные моряки утверждают, что любого, кто пристально посмотрит в глаз астрального дредноута, поглотит безумие. В отражении этой звёздной пустоты любой узрит ужасающее осознание собственной смерти. У заклинателей есть ещё больше причин бояться глаза, чем у остальных, поскольку глаз источает постоянное антимагическое поле. Дредноут может остановить этот эффект попросту закрыв глаз, хоть и совершает он это действие крайне редко.`,
      source: {
        id: SOURCE_MTOF,
        page: 119,
      },
    },
    {
      header: 'Астральный хищник',
      text: `Будучи беспощадным и неразборчивым охотником, астральный дредноут применяет ужасающую, хоть и простую, тактику. Он использует свои зубы и клешни, чтобы разорвать добычу на части. Инстинктивно осознавая опасность заклинателей, дредноут старается держать как можно больше противников в области своего антимагического взгляда.

У астрального дредноута нет ни пищевода, ни системы пищеварения. Все, что он поглощает, переносится в особенный демиплан — закрытое пространство, содержащее осколки самых разных времён вместе с останками планарных путешественников. В этом месте есть гравитация и пригодный для дыхания воздух, а органика там так же разлагается. Хоть побег из этого демиплана и возможен при помощи магии, большая часть существ оказываются здесь уже мёртвыми. Когда дредноут умирает — демиплан исчезает, и его содержимое оказывается на Астральном Плане.

Астральный дредноут не способен общаться. Он просто пожирает найденную добычу, а затем продолжает своё молчаливое патрулирование. Он не может уйти из Астрального Плана, но и не желает покидать его.`,
      source: {
        id: SOURCE_MTOF,
        page: 119,
      },
    },
    {
      header: 'Титаны Скованного Бога',
      text: `Тавридин, Скованный Бог, создал астральных дредноутов чтобы переваривать планарных путешественников, которые искали порталы, ведущие из Астрального Плана во Внешние. Порталы, которые они могли использовать чтобы взглянуть на своих богов и приобрести мечту о божественности.

Астральные дредноуты не размножаются, поэтому их популяция не может расти. Несмотря на то, что [гитьянки](CREATURE:${CREATURE_GITHYANKI_WARRIOR}) и другие астральные путешественники охотятся на этих существ, они редко преуспевают, так что в обозримом будущем вымирание дредноутов не коснётся.`,
      source: {
        id: SOURCE_MTOF,
        page: 119,
      },
    },
    {
      header: 'Титаническая природа',
      text: `Несмотря на то, что дредноут ест и спит, если того пожелает, он не нуждается в воздухе, еде, питьё и сне.`,
      source: {
        id: SOURCE_MTOF,
        page: 119,
      },
    },
  ],
  note: {
    text: `Астральные дредноуты существуют только по единственной причине — высокомерие. Не смертных, а богов, что считают себя слишком могущественными, чтобы к ним можно было приблизиться рассмотреть`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_TITAN,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MTOF,
    page: 119,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 15,
    [SPEED_FLY]: {
      value: 80,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 28,
    [PARAM_DEX]: 7,
    [PARAM_CON]: 25,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  cr: CR_21,
  featureList: [
    {
      name: 'Антимагический конус',
      description: `Открытый глаз астрального дредноута создаёт антимагическую зону, аналогичную заклинанию [преграда магии](SPELL:${SPELL_ANTIMAGIC_FIELD}), в пределах 150 фт. конуса. В начале каждого своего хода дредноут решает каким образом расположить этот конус. Конус не действует в то время как дредноут закрывает глаза или становится Ослеплённым.`,
    },
    {
      name: 'Астральная сущность',
      description: `Астральный дредноут не может покинуть Астральный План и не может быть изгнан или перемещён иными способами с Астрального Плана.`,
    },
    {
      name: 'Демипланарное подземелье',
      description: `Любое существо или объект, проглоченные астральным дредноутом, перемещаются на демиплан, в который нельзя попасть иначе, кроме как заклинанием «[Желание](SPELL:${SPELL_WISH})» или легендарной способностью астрального дредноута: _«Посещение Подземелья»_. Существо может покинуть этот демиплан только используя магию, которая позволяет перемещаться по планарным измерениям, к примеру, заклинание «[Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT})». Демиплан представляет из себя каменное пещероподобное помещение, радиусом 1000 фт. и высотой 30 фт. Подобно желудку он содержит то, что осталось от предыдущих трапез дредноута. Дредноуту нельзя причинить вред, находясь на этом демиплане. Если дредноут погибает, демиплан исчезает, а всё его содержимое появляется вокруг трупа дредноута. Иными способами такой демиплан уничтожить нельзя.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Разорвать серебряную нить',
      description: `Если астральный дредноут совершает критическое попадание по существу, путешествующему по Астральному Плану заклинанием [Астральная Проекция](SPELL:${SPELL_ASTRAL_PROJECTION}), астральный дредноут вместо причинения урона может выбрать перерезание серебряной нити цели.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Астральный дредноут совершает три атаки: одну _Укусом_ и две _Клешнями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 5,
          diceType: 10,
          diceBonus: 9,
        },
      },
      description: `Если цель является существом Огромного или менее размера и причинённый урон опускает хиты цели до 0 или до Недееспособности, астральный дредноут проглатывает её. Проглоченная цель со всем, что она носит и несёт перемещается в незанятое место Демипланарное Подземелье астрального дредноута.`,
    },
    {
      name: 'Клешни',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 20,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Клешни',
      description: `Астральный дредноут совершает одну атаку Клешнями.`,
    },
    {
      name: 'Посещение Подземелья',
      cost: 2,
      description: `Одно существо размером Огромный или меньше, которое астральный дредноут может видеть в пределах 60 фт. от себя должно пройти испытание Харизмы СЛ 19, иначе оно магическим образом переместится в Демипланарное Подземелье астрального дредноута. В конце своего следующего хода, существо появляется на своём прежнем месте, или в ближайшем к нему пространстве, если исходное уже занято.`,
    },
    {
      name: 'Психическая проекция',
      cost: 3,
      description: `Все существа в пределах 60 фт. от астрального дредноута должны пройти испытание Мудрости СЛ 19, получая 15 (2к10 + 4) урона психической энергией при провале, или половину этого урона при успехе`,
    },
  ],
}
