const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {gearCollection} = require('./../../../../../gearList')
const {SOURCE_BGDIA} = require('./../../../../../sourceList')
const {CREATURE_LEMURE} = require('./../../../../../creatureIdList')
const upLetter = require('./../../../../../../utils/upLetter')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../../../genderList')

const siblingIdList = require('./siblingIdList')

const dictCollection = {
  [GENDER_MALE]: {
    pronoun: 'этот',
    forged: 'выкован',
    tracedWith: 'нему',
    ofThat: 'этого',
  },
  [GENDER_FEMALE]: {
    pronoun: 'эта',
    forged: 'выкована',
    tracedWith: 'ней',
    ofThat: 'этой',
  },
  [GENDER_MIDDLE]: {
    pronoun: 'это',
    forged: 'выковано',
    tracedWith: 'нему',
    ofThat: 'этого',
  },
  [GENDER_MULTIPLE]: {
    pronoun: 'эти',
    forged: 'выкованы',
    tracedWith: 'ним',
    ofThat: 'этих',
  },
}

module.exports = (
  {
    gearId,
    id,
  }
) => {
  const {
    name,
    nameByCase: {
      genitive,
    },
    nameEn: gearNameEn,
    genderId,
  } = gearCollection[gearId]

  const {
    forged,
    ofThat,
    pronoun,
    tracedWith,
  } = dictCollection[genderId]

  return {
    id,
    name: `${name} пламени Преисподней`,
    nameEn: `Hellfire ${gearNameEn}`,
    type: MGC_TYPE_WEAPON,
    gearType: gearId,
    rarity: MGC_RARITY_UNCOMMON,
    description: `${upLetter(pronoun)} ${name.toLowerCase()} ${forged} из дьявольского железа. По ${tracedWith} тянутся прожилки пламени Преисподней, испуская тусклый свет в пределах 5 футов.

Душа гуманоида, убитого атакой ${ofThat} ${genitive}, направляется в реку Стикс, где немедленно переродится в [лемура](CREATURE:${CREATURE_LEMURE}).`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_BGDIA,
      page: 226,
    },
  }
}
