const {GEAR_SHIELD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_SHIELD_PLUS_1_2_3} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_SHIELD_PLUS_1_2_3,
  name: `Щит +1, +2 или +3`,
  nameEn: `Shield +1, +2, or +3`,
  type: MGC_TYPE_ARMOR,
  gearType: GEAR_SHIELD,
  rarity: generateRarityList(list),
  description: `Пока Вы держите этот щит, Вы получаете бонус к КД, зависящий от редкости щита.

Этот бонус идёт в дополнение к обычному бонусу щита к КД.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 214,
  },
}
