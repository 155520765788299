const {
  SOURCE_EGTW,
  SOURCE_LOR_DTOB,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2016_01_04_KITS_OF_OLD,
  SOURCE_UA_2016_04_04_GOTHIC_HEROES,
  SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
  SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
  SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
  SOURCE_UA_2018_01_08_3_SUBCLASSES_0108,
  SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
  SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
  SOURCE_XGTE,
} = require('./../../sourceList')

const {PC_CLASS_FIGHTER} = require('./../../pcClassIdList')
const {dunamisDescriptionList} = require('./../../textCommonParts')

const {
  PC_SUBCLASS_FIGHTER_ARCANE_ARCHER,
  PC_SUBCLASS_FIGHTER_ARCANE_ARCHER_2016_12_05,
  PC_SUBCLASS_FIGHTER_ARCANE_ARCHER_2017_05_01,
  PC_SUBCLASS_FIGHTER_BATTLE_MASTER,
  PC_SUBCLASS_FIGHTER_BRUTE_2018_01_08,
  PC_SUBCLASS_FIGHTER_CAVALIER,
  PC_SUBCLASS_FIGHTER_CAVALIER_2016_01_04,
  PC_SUBCLASS_FIGHTER_CAVALIER_2017_06_05,
  PC_SUBCLASS_FIGHTER_CHAMPION,
  PC_SUBCLASS_FIGHTER_ECHO_KNIGHT,
  PC_SUBCLASS_FIGHTER_ELDRITCH_KNIGHT,
  PC_SUBCLASS_FIGHTER_KNIGHT_2016_12_05,
  PC_SUBCLASS_FIGHTER_MONSTER_HUNTER_2016_04_04,
  PC_SUBCLASS_FIGHTER_PSI_KNIGHT_2020_04_14,
  PC_SUBCLASS_FIGHTER_PSI_WARRIOR,
  PC_SUBCLASS_FIGHTER_PSYCHIC_WARRIOR_2019_11_25,
  PC_SUBCLASS_FIGHTER_PURPLE_DRAGON_KNIGHT,
  PC_SUBCLASS_FIGHTER_RENEGADE,
  PC_SUBCLASS_FIGHTER_RUNE_KNIGHT,
  PC_SUBCLASS_FIGHTER_RUNE_KNIGHT_2019_11_25,
  PC_SUBCLASS_FIGHTER_SAMURAI,
  PC_SUBCLASS_FIGHTER_SAMURAI_2016_12_05,
  PC_SUBCLASS_FIGHTER_SCOUT_2016_01_04,
  PC_SUBCLASS_FIGHTER_SHARPSHOOTER_2016_12_05,
  PC_SUBCLASS_RANGER_MONSTER_SLAYER,
  PC_SUBCLASS_ROGUE_SCOUT,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Мистический лучник',
    nameEn: 'Arcane Archer',
    description: `Мистический Лучник изучает уникальный эльфийский метод стрельбы, который вплетает магию в выстрелы, чтобы произвести сверхъестественные эффекты.

Мистические Лучники — самые элитные воины среди эльфов. Они стоят на страже границ эльфийских королевств, зорко следят за нарушителями и используют наделенные магией стрелы, чтобы побеждать монстров и захватчиков до того как они достигнут эльфийских поселений.

За многие века методы этих эльфийских лучников были изучены представителями других рас, которые тоже могут балансировать мистические способности с мастерством лучника.`,
    note: {
      text: `Стрелы — хуже всего. Они летят дальше чем видит глаз. Вот почему я всегда нахожусь в помещении. Кроме того, красота неба явно переоценена.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 21,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER_2016_12_05,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER,
    isReady: false,
    name: 'Мистический лучник',
    nameEn: 'Arcane Archer',
    description: `An Arcane Archer studies a unique elven method of archery that weaves magic into attacks to produce supernatural effects. Among elves, Arcane Archers are some of their most elite warriors. These archers stand watch over the fringes of elven domains, keeping a keen eye out for trespassers and using magic-infused arrows to defeat monsters and invaders before they can reach elven settlements.Over the centuries, the methods of these elf archers have been learned by members of other races who can also balance arcane aptitude with archery.`,
    source: {
      id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER_2017_05_01,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_FIGHTER_ARCANE_ARCHER,
    isReady: false,
    name: 'Мистический лучник',
    nameEn: 'Arcane Archer',
    source: {
      id: SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_BATTLE_MASTER,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Мастер боевых искусств',
    nameAlt: 'Мастер битвы',
    nameEn: 'Battle Master',
    description: `Тот, кто выбрал архетип мастера боевых искусств, полагается на техники, выработанные поколениями бойцов.

Для такого воина сражение сродни академической задаче, и часто включает вещи, далёкие от боя, вроде кузнечного мастерства или каллиграфии. Не все воины способны впитать уроки истории, теорию и артистизм, отражённые в архетипе мастера боевых искусств, но те, кто смог сделать это, являются отлично подготовленными воинами, обладающими прекрасными навыками и знаниями.`,
    source: {
      id: SOURCE_PHB,
      page: 73,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_BRUTE_2018_01_08,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Громила',
    nameEn: 'Brute',
    description: `Громилы — простые воины, которые побеждают врагов мощными атаками и собственной крепкостью.

Некоторые громилы сочетают эту физическую мощь с тактической хитростью. Другие — просто бьют штуки, пока те не перестанут отбиваться.`,
    source: {
      id: SOURCE_UA_2018_01_08_3_SUBCLASSES_0108,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_CAVALIER,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Кавалерист',
    nameEn: 'Cavalier',
    description: `Архетип Кавалерист превосходен в конном бою. Обычно рожденный среди благородных и взращенный при дворе, Кавалерист одинаково хорошо себя чувствует ведя кавалерию в атаку и обмениваясь остротами за званым ужином. Кавалеристы также знают как оградить от опасности тех, за кого они отвечают, часто служат защитниками старших по званию и слабых. Принужденные устранять несправедливость или зарабатывать престиж, многие такие воины оставляют свою комфортную жизнь и отправляются на славное приключение.`,
    note: {
      text: `Так есть разные названия для разных видов тех, кто машет мечом? Почему? Давай я попробую: махатель большим мечом и махатель крошечным мечом. Нет, это звучит так, будто все дело в размере меча. Как насчет: большой мешок мяса с мечом и маленький мешок мяса с мечом!`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 20,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_CAVALIER_2016_01_04,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_FIGHTER_CAVALIER,
    isReady: false,
    name: 'Кавалерист',
    nameEn: 'Cavalier',
    source: {
      id: SOURCE_UA_2016_01_04_KITS_OF_OLD,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_CHAMPION,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Чемпион',
    nameEn: 'Champion',
    description: `Обычно чемпионы фокусируются на использовании чистой физической силы, отточенной до смертельного совершенства.

Выбравший для себя архетип чемпиона сочетает суровые тренировки с физическим совершенством, позволяющим наносить смертоносные удары.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 76,
      },
      {
        id: SOURCE_SRD,
        page: 25,
      },
    ],
  },
  {
    id: PC_SUBCLASS_FIGHTER_ECHO_KNIGHT,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Эхо-рыцарь',
    nameEn: 'Echo Knight',
    description: [
      `Загадочные и пугающие бойцы передней линии династии Крин, Эхо-рыцари овладели искусством использования _динамия_ для вызова исчезающих отголосков нереализованных временных линий на помощь себе в бою. Окруженные отражениями собственной мощи, они рвутся в битву ритмичной бурей теней и атак.`,
      ...dunamisDescriptionList,
    ],
    source: {
      id: SOURCE_EGTW,
      page: 184,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_ELDRITCH_KNIGHT,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Мистический рыцарь',
    nameEn: 'Eldritch Knight',
    description: `Архетип мистического рыцаря совмещает в себе воинское искусство и тщательное изучение волшебства.

Мистические рыцари используют приёмы, схожие с практиками волшебников. Но они сосредоточились на изучении двух из восьми школ магии: Воплощения и Ограждения.

Заклинания Ограждения обеспечивают мистическим рыцарям дополнительную защиту в бою, а Воплощения причиняют урон сразу нескольким противникам за раз, увеличивая возможности рыцаря в бою.

Эти рыцари владеют относительно небольшим количеством заклинаний, что позволяет постоянно держать их в памяти и не использовать книгу заклинаний.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_KNIGHT_2016_12_05,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_FIGHTER_CAVALIER,
    isReady: false,
    name: 'Рыцарь',
    nameEn: 'Knight',
    source: {
      id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_CAVALIER_2017_06_05,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_FIGHTER_CAVALIER,
    isReady: false,
    name: 'Кавалерист',
    nameEn: 'Cavalier',
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_PSYCHIC_WARRIOR_2019_11_25,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_FIGHTER_PSI_WARRIOR,
    isReady: false,
    name: 'Психический воин',
    nameEn: 'Psychic Warrior',
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_PSI_KNIGHT_2020_04_14,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_FIGHTER_PSI_WARRIOR,
    isReady: false,
    name: 'Пси-рыцарь',
    nameEn: 'Psi Knight',
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_PSI_WARRIOR,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Пси-воин',
    nameEn: 'Psi Warrior',
    description: `Пробудивший внутреннюю псионическую силу, пси-воин — это боец, усиливающий свою физическую мощь наполненными пси ударами оружием, телекинетическими ударами и барьерами ментальной силы. Многие гитьянки обучаются этому, как и некоторые из наиболее дисциплинированных высших эльфов. В мире Эберрона многие молодые калаштары мечтают стать пси-воинами.

Как пси-воин, Вы могли отточить свои псионические способности личной дисциплиной, разблокировать их под опекой мастера или усовершенствовать в академии, обучающей использованию разума вместо щита и оружия.`,
    note: {
      text: `И сила уму уступает? Разум силу победит? Эти хитрые воители справедливо спрашивают: „А почему не взять обоих?“`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_MONSTER_HUNTER_2016_04_04,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_RANGER_MONSTER_SLAYER,
    isReady: true,
    name: 'Охотник на монстров',
    nameEn: 'Monster Hunter',
    description: `Как типичный охотник на монстров, Вы — эксперт в борьбе со сверхъестественными угрозами. Обычно под наставничеством опытного охотника на монстров Вы учились преодолевать множество неестественных защит и атак, в том числе нежити, ликантропов и других ужасных существ.`,
    source: {
      id: SOURCE_UA_2016_04_04_GOTHIC_HEROES,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_PURPLE_DRAGON_KNIGHT,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Рыцарь Пурпурного Дракона',
    nameEn: 'Purple Dragon Knight',
    description: [
      `Рыцари Пурпурного Дракона — бойцы родом из королевства Кормир. Поклявшиеся защищать корону, они сражаются со злом за пределами границ их королевства.

Их задача — ходить по землям как странствующим рыцарям, опираясь на свое суждение, храбрость и верность кодексу рыцарства, которые направят их в борьбе со злодеями.

Рыцарь Пурпурного Дракона вдохновляет других, совершая в бою самые смелые поступки. Само присутствие в деревне рыцаря может заставить бандитов и мародёров искать себе добычу попроще. В одиночку рыцарь — умелый воин, но рыцарь, ведущий за собой группу союзников, может превратить даже самый бедно экипированный отряд милиции в свирепую боевую группу.

Рыцарь славен делами, а не словами. Когда атаку возглавляет рыцарь, его действия могут пробудить в союзниках те резервы мужества и рвения, о которых они даже не подозревали.`,
      {
        header: `Ограничение: Рыцарство`,
        text: `Рыцари Пурпурного Дракона принадлежат к определённому ордену кормирского рыцарства.

**Баннерет** — общее название для этого архетипа, если Вы используете его в другом сеттинге или для другой модели военачальника не относящегося к Рыцарям Пурпурного Дракона.`,
        source: {
          id: SOURCE_SCAG,
          page: 129,
        },
      },
    ],
    source: {
      id: SOURCE_SCAG,
      page: 129,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_RUNE_KNIGHT_2019_11_25,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_FIGHTER_RUNE_KNIGHT,
    isReady: false,
    name: 'Рунный рыцарь',
    nameEn: 'Rune Knight',
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_RUNE_KNIGHT,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Рунный рыцарь',
    nameEn: 'Rune Knight',
    description: `Рунные рыцари усиливают своё боевое мастерство, используя сверхъестественную силу рун — древнюю практику, восходящую к великанам. Резчиков рун можно найти среди любого семейства великанов, и Вы, вероятно, узнали свои методы из первых рук или из вторых рук у такого таинственного резчика.

Нашли ли Вы работу великана, вырезанную на холме или в пещере, узнали ли о рунах от мудреца или встретили великана лично, Вы изучили мастерство великанов и научились применять магические руны, усиливая своё снаряжение.`,
    note: {
      text: `Ты изучаешь древнее искусство и рисуешь руны. Выглядит так, будто ты хочешь стать ведьмой!`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 63,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_SAMURAI,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Самурай',
    nameEn: 'Samurai',
    description: `Самурай — воин, который полагается на свой суровый и непреклонный дух бойца, чтобы одолеть врагов.

Решимость его непоколебима, и у врагов, вставших на пути Самурая, два варианта: сдаться или умереть в бою.`,
    source: {
      id: SOURCE_XGTE,
      page: 23,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_SAMURAI_2016_12_05,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_FIGHTER_SAMURAI,
    isReady: false,
    name: 'Самурай',
    nameEn: 'Samurai',
    source: {
      id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_SCOUT_2016_01_04,
    pcClassId: PC_CLASS_FIGHTER,
    releasedAs: PC_SUBCLASS_ROGUE_SCOUT,
    isReady: false,
    name: 'Разведчик',
    nameEn: 'Scout',
    source: {
      id: SOURCE_UA_2016_01_04_KITS_OF_OLD,
      page: 4,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_SHARPSHOOTER_2016_12_05,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: true,
    name: 'Снайпер',
    nameEn: 'Sharpshooter',
    description: `Снайпер — мастер дальнего боя. Отличный стрелок и зоркий разведчик, этот воин — опасный противник, который может победить целый отряд издалека.`,
    source: {
      id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_FIGHTER_RENEGADE,
    pcClassId: PC_CLASS_FIGHTER,
    isReady: false,
    name: 'Ренегат',
    nameEn: 'Renegade',
    source: {
      id: SOURCE_LOR_DTOB,
      url: 'https://www.dndbeyond.com/sources/lrdtob/heroes-and-scoundrels-of-bilgewater#MartialArchetypeRenegade',
    },
  },
]
