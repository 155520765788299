const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {DAMAGE_ELECTRICITY} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SOURCE_SCAG} = require('./../../../../../sourceList')
const {SPELL_LIGHTNING_LURE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_LIGHTNING_LURE,
  name: 'Лассо молнии',
  nameEn: 'Lightning Lure',
  description: `Вы создаете хлыст молний, поражающий одно видимое Вами в пределах дистанции существо по Вашему выбору.

Цель должна пройти испытание Силы или будет притянута на расстояние до 10 футов по прямой линии к Вам, а также получит 1к8 урона электричеством, если таким образом окажется в пределах 5 футов от Вас.`,
  highLvlCast: `Урон заклинания возрастает на 1к8 на 5-м уровне (2к8), 11-м уровне (3к8) и на 17-м уровне (4к8).`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 15,
  componentIdList: [CAST_VERBAL],
  effect: {
    savethrowParam: PARAM_STR,
    damage: {
      type: DAMAGE_ELECTRICITY,
      diceCount: 1,
      diceType: 8,
      diceBonus: 0,
    },
  },
  source: {
    id: SOURCE_SCAG,
    page: 144,
  },
}
