const {
  SPELL_ACID_SPLASH,
  SPELL_FIRE_BOLT,
  SPELL_FIREBALL,
  SPELL_FLY,
  SPELL_FOG_CLOUD,
  SPELL_GUST_OF_WIND,
  SPELL_ICE_STORM,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGIC_MISSILE,
  SPELL_MELF_S_ACID_ARROW,
  SPELL_RAY_OF_FROST,
  SPELL_SCORCHING_RAY,
  SPELL_SHOCKING_GRASP,
  SPELL_THUNDERWAVE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GOBLINOID,
  CREATURE_TYPE_HOBGOBLIN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_QUARTERSTAFF,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  hobgoblinDescriptionList,
  hobgoblinNote,
} = require('./../../../../textCommonParts')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_HOBGOBLIN_DEVASTATOR} = require('./../../../../creatureIdList')
const {GOD_MAGLUBIYET} = require('./../../../../godIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_ARCANA} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Хобгоблин опустошитель',
  nameEn: 'Hobgoblin Devastator',
  id: CREATURE_HOBGOBLIN_DEVASTATOR,
  description: [
    {
      header: 'Хобгоблин опустошитель',
      text: `В обществе хобгоблинов Академия Опустошителей выявляет хобгоблинов с талантами к магии и подвергает их обучению в суровом тренировочном режиме, наделяющем их способностью метать [шары огня](SPELL:${SPELL_FIREBALL}) и другую разрушительную магию по приказу командования. На поле боя хобгоблин опустошитель — одновременно помощник своим союзникам и угроза для всех врагов вокруг.`,
      source: {
        id: SOURCE_VGTM,
        page: 201,
      },
    },
    {
      header: 'В битву',
      text: `Если в других культурах к волшебникам относятся как к академикам-затворникам, то хобгоблины от своих заклинателей ожидают участия в бою. Опустошители проходят обучение основам обращения с оружием и измеряют свой успех по количеству врагов, сражённых их магией. Опустошители пользуются уважением других членов войска и многие однополчане относятся к ним с повиновением. Способность нейтрализовать целый вражеский отряд одним заклинанием позволяет им заработать гораздо больше славы, чем простому воину. В других культурах такие способности могут считаться коротким путем к славе, но для хобгоблинов дар магии равноценен умелому владению оружием или тактическому гению. Все они — дары [Маглубиета](GOD:${GOD_MAGLUBIYET}), и их следует развивать и использовать против врагов.`,
      source: {
        id: SOURCE_VGTM,
        page: 201,
      },
    },
    {
      header: 'Важен только результат',
      text: `Опустошители изучают упрощенную форму магии воплощения. Их обучение упускает многие теоретические основы, которые изучают иные народы, что делает их умелыми в бою, но относительно безграмотными относительно того, как и почему работает их магия.

Академия Опустошения считает, что академический подход к магии — это знак слабости и неэффективности. Воину не требуется разбираться в металлургии, чтобы орудовать клинком, так почему же волшебника должно заботить то, откуда берётся магия? Опустошители любят доказывать свое боевое превосходство, выискивая в бою вражеских заклинателей и уничтожая их.`,
      source: {
        id: SOURCE_VGTM,
        page: 201,
      },
    },
    ...hobgoblinDescriptionList,
  ],
  note: hobgoblinNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_HOBGOBLIN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 201,
  },
  armor: {
    ac: 13,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 14,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GOBLIN,
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Магическое преимущество',
      description: `Один раз в ход ★СУЩЕСТВО★ может нанести 7 (2к6) дополнительного урона существу, по которому ★попал★ атакой заклинанием, наносящим урон, если цель находится в 5 футах от союзника ★СУЩЕСТВО★ и этот союзник дееспособен.`,
    },
    {
      name: 'Армейская магия',
      description: `Когда ★СУЩЕСТВО★ сотворяет заклинание, которое наносит урон или заставляет других существ пройти испытание, то ★он★ может выбрать себя и любое количество союзников и дать им иммунитет от урона заклинания или успех в требуемом испытании.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 7,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_ACID_SPLASH,
      SPELL_FIRE_BOLT,
      SPELL_RAY_OF_FROST,
      SPELL_SHOCKING_GRASP,
      SPELL_FOG_CLOUD,
      SPELL_MAGIC_MISSILE,
      SPELL_THUNDERWAVE,
      SPELL_GUST_OF_WIND,
      SPELL_MELF_S_ACID_ARROW,
      SPELL_SCORCHING_RAY,
      SPELL_FIREBALL,
      SPELL_FLY,
      SPELL_LIGHTNING_BOLT,
      SPELL_ICE_STORM,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
  ],
}
