const {
  SPELL_ABSORB_ELEMENTS,
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ANIMAL_MESSENGER,
  SPELL_ANIMAL_SHAPES,
  SPELL_ANTILIFE_SHELL,
  SPELL_ANTIPATHY_SYMPATHY,
  SPELL_AUGURY,
  SPELL_AURA_OF_VITALITY,
  SPELL_AWAKEN,
  SPELL_BARKSKIN,
  SPELL_BEAST_BOND,
  SPELL_BEAST_SENSE,
  SPELL_BLIGHT,
  SPELL_BONES_OF_THE_EARTH,
  SPELL_CALL_LIGHTNING,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_CONE_OF_COLD,
  SPELL_CONFUSION,
  SPELL_CONJURE_ANIMALS,
  SPELL_CONJURE_ELEMENTAL,
  SPELL_CONJURE_FEY,
  SPELL_CONJURE_MINOR_ELEMENTALS,
  SPELL_CONJURE_WOODLAND_BEINGS,
  SPELL_CONTAGION,
  SPELL_CONTINUAL_FLAME,
  SPELL_CONTROL_FLAMES,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WEATHER,
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_CURE_WOUNDS,
  SPELL_DARKVISION,
  SPELL_DAYLIGHT,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINATION,
  SPELL_DOMINATE_BEAST,
  SPELL_DRACONIC_TRANSFORMATION,
  SPELL_DRACONIC_TRANSFORMATION_2021_04_14,
  SPELL_DRUID_GROVE,
  SPELL_DRUIDCRAFT,
  SPELL_DUST_DEVIL,
  SPELL_EARTH_TREMOR,
  SPELL_EARTHBIND,
  SPELL_EARTHQUAKE,
  SPELL_ELEMENTAL_BANE,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_ENTANGLE,
  SPELL_ERUPTING_EARTH,
  SPELL_FAERIE_FIRE,
  SPELL_FEEBLEMIND,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_THE_PATH,
  SPELL_FIND_TRAPS,
  SPELL_FIRE_SHIELD,
  SPELL_FIRE_STORM,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_BLADE,
  SPELL_FLAMING_SPHERE,
  SPELL_FLESH_TO_STONE,
  SPELL_FOG_CLOUD,
  SPELL_FORESIGHT,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_FROSTBITE,
  SPELL_GEAS,
  SPELL_GIANT_INSECT,
  SPELL_GOODBERRY,
  SPELL_GRASPING_VINE,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_NATURE,
  SPELL_GUIDANCE,
  SPELL_GUIDING_HAND_2017_04_03,
  SPELL_GUST,
  SPELL_GUST_OF_WIND,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HEAL,
  SPELL_HEALING_SPIRIT,
  SPELL_HEALING_WORD,
  SPELL_HEAT_METAL,
  SPELL_HEROES_FEAST,
  SPELL_HOLD_PERSON,
  SPELL_ICE_KNIFE,
  SPELL_ICE_STORM,
  SPELL_INCENDIARY_CLOUD,
  SPELL_INFESTATION,
  SPELL_INSECT_PLAGUE,
  SPELL_INVESTITURE_OF_FLAME,
  SPELL_INVESTITURE_OF_ICE,
  SPELL_INVESTITURE_OF_STONE,
  SPELL_INVESTITURE_OF_WIND,
  SPELL_JUMP,
  SPELL_LESSER_RESTORATION,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MAELSTROM,
  SPELL_MAGIC_STONE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MELD_INTO_STONE,
  SPELL_MENDING,
  SPELL_MIRAGE_ARCANE,
  SPELL_MOLD_EARTH,
  SPELL_MOONBEAM,
  SPELL_MOVE_EARTH,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_PLANAR_BINDING,
  SPELL_PLANE_SHIFT,
  SPELL_PLANT_GROWTH,
  SPELL_POISON_SPRAY,
  SPELL_POLYMORPH,
  SPELL_PRIMAL_SAVAGERY,
  SPELL_PRIMORDIAL_WARD,
  SPELL_PRODUCE_FLAME,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_REGENERATE,
  SPELL_REINCARNATE,
  SPELL_RESISTANCE,
  SPELL_REVERSE_GRAVITY,
  SPELL_REVIVIFY,
  SPELL_SCRYING,
  SPELL_SHAPE_WATER,
  SPELL_SHAPECHANGE,
  SPELL_SHILLELAGH,
  SPELL_SKYWRITE,
  SPELL_SLEET_STORM,
  SPELL_SNARE,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_SPIKE_GROWTH,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_STORM_OF_VENGEANCE,
  SPELL_SUMMON_BEAST,
  SPELL_SUMMON_BESTIAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_DRACONIC_SPIRIT,
  SPELL_SUMMON_DRACONIC_SPIRIT_2021_04_14,
  SPELL_SUMMON_ELEMENTAL,
  SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_FEY,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SUNBEAM,
  SPELL_SUNBURST,
  SPELL_SYMBOL,
  SPELL_THORN_WHIP,
  SPELL_THUNDERCLAP,
  SPELL_THUNDERWAVE,
  SPELL_TIDAL_WAVE,
  SPELL_TRANSMUTE_ROCK,
  SPELL_TRANSPORT_VIA_PLANTS,
  SPELL_TREE_STRIDE,
  SPELL_TRUE_RESURRECTION,
  SPELL_TSUNAMI,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_STONE,
  SPELL_WALL_OF_THORNS,
  SPELL_WALL_OF_WATER,
  SPELL_WARDING_WIND,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
  SPELL_WATERY_SPHERE,
  SPELL_WHIRLWIND,
  SPELL_WILD_CUNNING_2017_04_03,
  SPELL_WIND_WALK,
  SPELL_WIND_WALL,
  SPELL_WRATH_OF_NATURE,
} = require('./../../spellIdList')

module.exports = [
  SPELL_ABSORB_ELEMENTS,
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ANIMAL_MESSENGER,
  SPELL_ANIMAL_SHAPES,
  SPELL_ANTILIFE_SHELL,
  SPELL_ANTIPATHY_SYMPATHY,
  SPELL_AUGURY,
  SPELL_AURA_OF_VITALITY,
  SPELL_AWAKEN,
  SPELL_BARKSKIN,
  SPELL_BEAST_BOND,
  SPELL_BEAST_SENSE,
  SPELL_BLIGHT,
  SPELL_BONES_OF_THE_EARTH,
  SPELL_CALL_LIGHTNING,
  SPELL_CHARM_MONSTER,
  SPELL_CHARM_PERSON,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_CONE_OF_COLD,
  SPELL_CONFUSION,
  SPELL_CONJURE_ANIMALS,
  SPELL_CONJURE_ELEMENTAL,
  SPELL_CONJURE_FEY,
  SPELL_CONJURE_MINOR_ELEMENTALS,
  SPELL_CONJURE_WOODLAND_BEINGS,
  SPELL_CONTAGION,
  SPELL_CONTINUAL_FLAME,
  SPELL_CONTROL_FLAMES,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WEATHER,
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_CURE_WOUNDS,
  SPELL_DARKVISION,
  SPELL_DAYLIGHT,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINATION,
  SPELL_DOMINATE_BEAST,
  SPELL_DRACONIC_TRANSFORMATION,
  SPELL_DRACONIC_TRANSFORMATION_2021_04_14,
  SPELL_DRUID_GROVE,
  SPELL_DRUIDCRAFT,
  SPELL_DUST_DEVIL,
  SPELL_EARTH_TREMOR,
  SPELL_EARTHBIND,
  SPELL_EARTHQUAKE,
  SPELL_ELEMENTAL_BANE,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENLARGE_REDUCE,
  SPELL_ENTANGLE,
  SPELL_ERUPTING_EARTH,
  SPELL_FAERIE_FIRE,
  SPELL_FEEBLEMIND,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_THE_PATH,
  SPELL_FIND_TRAPS,
  SPELL_FIRE_SHIELD,
  SPELL_FIRE_STORM,
  SPELL_FLAME_ARROWS,
  SPELL_FLAME_BLADE,
  SPELL_FLAMING_SPHERE,
  SPELL_FLESH_TO_STONE,
  SPELL_FOG_CLOUD,
  SPELL_FORESIGHT,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_FROSTBITE,
  SPELL_GEAS,
  SPELL_GIANT_INSECT,
  SPELL_GOODBERRY,
  SPELL_GRASPING_VINE,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_NATURE,
  SPELL_GUIDANCE,
  SPELL_GUIDING_HAND_2017_04_03,
  SPELL_GUST,
  SPELL_GUST_OF_WIND,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HEAL,
  SPELL_HEALING_SPIRIT,
  SPELL_HEALING_WORD,
  SPELL_HEAT_METAL,
  SPELL_HEROES_FEAST,
  SPELL_HOLD_PERSON,
  SPELL_ICE_KNIFE,
  SPELL_ICE_STORM,
  SPELL_INCENDIARY_CLOUD,
  SPELL_INFESTATION,
  SPELL_INSECT_PLAGUE,
  SPELL_INVESTITURE_OF_FLAME,
  SPELL_INVESTITURE_OF_ICE,
  SPELL_INVESTITURE_OF_STONE,
  SPELL_INVESTITURE_OF_WIND,
  SPELL_JUMP,
  SPELL_LESSER_RESTORATION,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MAELSTROM,
  SPELL_MAGIC_STONE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MELD_INTO_STONE,
  SPELL_MENDING,
  SPELL_MIRAGE_ARCANE,
  SPELL_MOLD_EARTH,
  SPELL_MOONBEAM,
  SPELL_MOVE_EARTH,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_PLANAR_BINDING,
  SPELL_PLANE_SHIFT,
  SPELL_PLANT_GROWTH,
  SPELL_POISON_SPRAY,
  SPELL_POLYMORPH,
  SPELL_PRIMAL_SAVAGERY,
  SPELL_PRIMORDIAL_WARD,
  SPELL_PRODUCE_FLAME,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_REGENERATE,
  SPELL_REINCARNATE,
  SPELL_RESISTANCE,
  SPELL_REVERSE_GRAVITY,
  SPELL_REVIVIFY,
  SPELL_SCRYING,
  SPELL_SHAPE_WATER,
  SPELL_SHAPECHANGE,
  SPELL_SHILLELAGH,
  SPELL_SKYWRITE,
  SPELL_SLEET_STORM,
  SPELL_SNARE,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_SPIKE_GROWTH,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_STORM_OF_VENGEANCE,
  SPELL_SUMMON_BEAST,
  SPELL_SUMMON_BESTIAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_DRACONIC_SPIRIT,
  SPELL_SUMMON_DRACONIC_SPIRIT_2021_04_14,
  SPELL_SUMMON_ELEMENTAL,
  SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_FEY,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SUNBEAM,
  SPELL_SUNBURST,
  SPELL_SYMBOL,
  SPELL_THORN_WHIP,
  SPELL_THUNDERCLAP,
  SPELL_THUNDERWAVE,
  SPELL_TIDAL_WAVE,
  SPELL_TRANSMUTE_ROCK,
  SPELL_TRANSPORT_VIA_PLANTS,
  SPELL_TREE_STRIDE,
  SPELL_TRUE_RESURRECTION,
  SPELL_TSUNAMI,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_STONE,
  SPELL_WALL_OF_THORNS,
  SPELL_WALL_OF_WATER,
  SPELL_WARDING_WIND,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
  SPELL_WATERY_SPHERE,
  SPELL_WHIRLWIND,
  SPELL_WILD_CUNNING_2017_04_03,
  SPELL_WIND_WALK,
  SPELL_WIND_WALL,
  SPELL_WRATH_OF_NATURE,
]
