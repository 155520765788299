const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GIANT_SHARK,
  CREATURE_HUNTER_SHARK,
  CREATURE_REEF_SHARK,
} = require('./../../../../creatureIdList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_PACK_TACTICS,
  ABILITY_WATER_BREATHING,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Рифовая акула',
  nameEn: 'Reef Shark',
  id: CREATURE_REEF_SHARK,
  description: `Менее крупная, чем [гигантская](CREATURE:${CREATURE_GIANT_SHARK}) и [охотничья акулы](CREATURE:${CREATURE_HUNTER_SHARK}), рифовая акула обитает у побережий и в коралловых рифах, собираясь для охоты в небольшие стаи. Взрослые особи достигают в длину от 6 до 10 футов.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 338,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 13,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 4,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_PACK_TACTICS,
    ABILITY_WATER_BREATHING,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
