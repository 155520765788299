const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  centaurDescriptionList,
  centaurNote,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../damageTypeList')
const {
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  GEAR_LONGBOW,
  GEAR_PIKE,
} = require('./../../../../gearIdList')
const {ABILITY_CHARGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NG} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_CENTAUR} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Кентавр',
  nameEn: 'Centaur',
  id: CREATURE_CENTAUR,
  description: centaurDescriptionList,
  note: centaurNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NG,
  source: {
    id: SOURCE_MM,
    page: 175,
  },
  armor: 12,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  languageList: [
    LANG_SYLVAN,
    LANG_ELVEN,
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 30,
      attackName: 'Пикой',
      damageText: '10 (3к6) колющего урона',
      afterEffectText: '',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Пикой_ и одну _Копытами_, либо две атаки _Длинным луком_.`,
    },
    {
      gearId: GEAR_PIKE,
    },
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
  genderId: GENDER_MALE,
}
