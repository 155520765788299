const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {ABILITY_SIEGE_MONSTER} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_EARTH_ELEMENTAL} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {elementalDescription} = require('./../../../../textCommonParts')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_TERRAN} = require('./../../../../languageIdList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Земляной элементаль`,
  nameEn: 'Earth Elemental',
  id: CREATURE_EARTH_ELEMENTAL,
  description: [
    `Земляной элементаль подобен движущемуся холму, упрямо идущему вперёд, с похожими на дубины руками, качающимися вдоль тела. Голова и тело его состоят из земли и камней, иногда попадаются вкрапления металла, самоцветов и ярких минералов.

Земляной элементаль может скользить сквозь скалы и землю, словно это жидкая вода. Ходящие по земле существа должны бояться земляного элементаля, поскольку он может определить местоположение любого врага, который стоит рядом на твёрдой поверхности.`,
    elementalDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 304,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 20,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  vulnerabilityList: [
    DAMAGE_THUNDER,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_UNCONSCIOUS,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  languageList: [
    LANG_TERRAN,
  ],
  cr: CR_5,
  featureList: [
    {
      name: `Скольжение сквозь землю`,
      description: `★СУЩЕСТВО★ может перемещаться, копая, сквозь немагические и необработанные землю и камень. При этом ★СУЩЕСТВО★ не беспокоит материал, через который перемещается.`,
    },
    ABILITY_SIEGE_MONSTER,
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает два _Удара_.`,
    },
    {
      name: `Удар`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
