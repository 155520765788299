const MEASURE_GALLON = 'gallon'
const MEASURE_LITER = 'liter'
const MEASURE_OUNCE = 'ounce'
const MEASURE_PINT = 'pint'
const MEASURE_QUART = 'quart'

module.exports = {
  [MEASURE_OUNCE]: {
    name: {
      singular: 'унция',
      dual: 'унции',
      plural: 'унций',
      multiple: 'унции',
    },
    volume: 1,
  },
  [MEASURE_PINT]: {
    name: {
      singular: 'пинта',
      dual: 'пинты',
      plural: 'пинт',
      multiple: 'пинты',
    },
    volume: 16,
  },
  [MEASURE_QUART]: {
    name: {
      singular: 'кварта',
      dual: 'кварты',
      plural: 'кварт',
      multiple: 'кварты',
    },
    volume: 16 * 2,
  },
  [MEASURE_GALLON]: {
    name: {
      singular: 'галлон',
      dual: 'галлона',
      plural: 'галлонов',
      multiple: 'галлоны',
    },
    volume: 16 * 2 * 4,
  },
  [MEASURE_LITER]: {
    name: {
      singular: 'литр',
      dual: 'литра',
      plural: 'литров',
      multiple: 'литры',
    },
    volume: 33.814021,
  },
}

module.exports.MEASURE_GALLON = MEASURE_GALLON
module.exports.MEASURE_LITER = MEASURE_LITER
module.exports.MEASURE_OUNCE = MEASURE_OUNCE
module.exports.MEASURE_PINT = MEASURE_PINT
module.exports.MEASURE_QUART = MEASURE_QUART
