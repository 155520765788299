const {SOURCE_MTOF} = require('./../../sourceList')
const {
  CREATURE_THE_ANGRY,
  CREATURE_THE_LONELY,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Горезванные',
    subHeader: 'Угнетённые',
    text: `Повсеместная тоска Царства Теней порой порождает странные воплощения мрачной сущности плана. Горезванные воплощают формы мучений, что свойственны в этой тёмной местности, а их внешность наводит ужас на тех, кто встретит их на своём пути.`,
    source: {
      id: SOURCE_MTOF,
      page: 236,
    },
  },
  {
    header: 'Мерзкие воплощение эмоций',
    text: `Каждый горезванный олицетворяет различные проявления отчаяния, либо страдания. Одни отражают [злость](CREATURE:${CREATURE_THE_ANGRY}), другие — принимают физическую форму [одиночества](CREATURE:${CREATURE_THE_LONELY}). Их природа служит подсказкой, как и к пониманию того, как они стали такими сильными, так и к их устранению. Потворствование негативным эмоциям, что воплощает каждый горезванный, только увеличит его силу и опасность. Если не поддаваться таким эмоциям, то сила горезванного слабеет, и можно с лёгкостью избавиться от них.`,
    source: {
      id: SOURCE_MTOF,
      page: 236,
    },
  },
]
