const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_STUNNED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_REGENERATION,
  ABILITY_SPIDER_CLIMB,
} = require('./../../../../creatureAbilityList')
const {
  SPELL_ACID_SPLASH,
  SPELL_BLIGHT,
  SPELL_CONTAGION,
  SPELL_DETECT_MAGIC,
  SPELL_GASEOUS_FORM,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_DEMON_LORD,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_JUIBLEX,
  CREATURE_ZUGGTMOY,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_23} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Джуиблекс',
  nameAlt: [
    'Безликий князь',
    'Слизевой голод',
  ],
  nameEn: 'Juiblex',
  id: CREATURE_JUIBLEX,
  hasSpoilers: true,
  description: [
    `В древних гримуарах его называют Безликим Лордом и Слизевым Голодом. Джуиблекс — лорд демонов, властвующий над слизью, всепожирающее существо, которое не заботят цели и планы других демонов. Он существует лишь для того, чтобы поглощать, переваривая и обращая живую материю в себе подобных.

Истинный ужас, Джуиблекс — это пузырящаяся слизевая масса, смесь черных и зеленых цветов, с красными глазами, плавающими по его телу. Он может достигать таких размеров, что становится похожим на двадцатифутовый холм, выбрасывающий из себя ложноножки, которыми хватает своих жертв и погружает их в свое тело. Те, кто был поглощен Джуиблексом, полностью уничтожаются.

Лишь истинные безумцы служат Джуиблексу и тянутся к его слизевым существам. Те, кто жертвуют себя этому лорду демонов, поглощаются им, после чего превращаются в смутно напоминающую гуманоидов живую слизь. Тела этих существ, некогда состоящих из плоти и крови, становятся частью физического тела Джуиблекса, в то время как лорд демонов медленно переваривает и смакует их души.`,
    {
      header: 'Логово Джуиблекса',
      text: `Главное логово Джуиблекса известно под названием Яма Слизи — земли, которые Джуиблекс делит с [Заггтмой](CREATURE:${CREATURE_ZUGGTMOY}). Этот слой Бездны, также известный как Шедакла, представляет собой пузырящееся мерзкое болото слизи и зловонных отходов. Его ландшафт покрывает едкая неразумная слизь, странные органические формы всплывают из океанов плесени и гнили по приказу Джуиблекса.

# Действия логова

По очереди инициативы 20 (проигрыш при ничье), Джуиблекс может совершить действие в логове, чтобы создать один из следующих эффектов; он не может использовать один и тот же эффект два раунда подряд:

* Джуиблекс покрывает слизью квадратную область на полу в своём логове, которую он может видеть. Сторона квадрата может достигать 10 футов. Слизь исчезает спустя 1 час или будучи сожжённой огнем. Когда слизь появляется, каждое существо, находящееся в её области, должно пройти испытание Силы СЛ 21 или станет обездвиженым ей. Когда существо входит в эту область в первый раз в свой ход или заканчивает свой ход в ней, ему необходимо пройти это же испытание. Обездвиженое существо прилипает на все время существования зоны слизи или пока не вырвется. Обездвиженое существо или другое существо, способное до него дотянуться, может потратить действие для того, чтобы освободить себя или другое существо, совершив успешную проверку Силы СЛ 21. Если слизь охватывает огонь, она сгорает спустя 1 раунд. Любое существо, начинающее ход в горящей слизи, получает огненный урон 22 (4к10). 
* Джуиблекс покрывает слизью квадратную область на полу в его логове, которую он может видеть. Сторона квадрата может достигать 10 футов. Слизь исчезает спустя 1 час или будучи сожжённой огнем. Когда слизь появляется, каждое существо, находящееся в её области, должно пройти испытание Ловкости СЛ 21 или упадет ничком и проскользит 10 футов в случайном направлении, определяемом броском к8. Когда существо входит в зону в первый раз в свой ход или заканчивает свой ход в ней, существу необходимо пройти это же испытание. Если слизь охватывает огонь, она сгорает спустя 1 раунд. Любое существо, начинающее ход в горящей слизи, получает огненный урон 22 (4к10). 
* Зелёная слизь (см. Руководство Мастера) появляется в точке на потолке, выбранной Джуиблексом в его логове. Слизь уничтожается спустя 1 час.

# Местные эффекты

Область, в которой расположено логово Джуиблекса, пронизана его магией, создающей в ней один или более из следующих эффектов:

* Небольшие источники воды, такие как пруды и колодцы, находящиеся на расстоянии до 1 мили от логова чрезвычайно кислотны и разъедают любой объект, который к ним притрагивается. 
* Поверхности, расположенные в пределах 6 миль от логова, часто бывают покрытыми тонкой и гладкой плёнкой слизи, прилипающей ко всему, к чему притронется. 
* Если гуманоид проводит по крайней мере 1 час на расстоянии 1 мили от логова, то он должен пройти испытание Мудрости СЛ 18 или станет жертвой безумия, определяемого по таблице _Безумие Джуиблекса_. Существо, успешно прошедшее испытание, не может стать жертвой этого регионального эффекта в течение следующих 24 часов.

Если Джуиблекс умирает, эти эффекты прекращаются в течение 1к10 дней.`,
      source: {
        id: SOURCE_MTOF,
        page: 147,
      },
    },
    {
      header: 'Безумие Джуиблекса',
      text: `Если существо становится безумным в логове Джуиблекса или в поле зрения лорда демонов, совершите бросок по таблице Безумие Джуиблекса, чтобы определить природу безумия, которое будет слабостью персонажа и будет действовать до тех пор, пока не будет излечено.
        
| к100 | Слабость (длится пока не будет вылечена) |
|------|------------------------------------------|
| 01-20 | _«Я должен поглотить все, что смогу!»_ |
| 21-40 | _«Я отказываюсь расставаться с любой из своих вещей»_ |
| 41-60 | _«Я сделаю все, что смогу, чтобы заставить других есть и пить сверх их меры»_ |
| 61-80 | _«Я должен завладеть столькими материальными благами, сколькими смогу»_ |
| 81-00 | _«Моя личность не имеет значения. Я — это то, что я поглощаю»_ |
`,
      source: {
        id: SOURCE_MTOF,
        page: 148,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_DEMON_LORD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 148,
    },
    {
      url: `https://5e.tools/bestiary.html#githyanki%20supreme%20commander_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Джуиблекса» пропущен иммунитет к состоянию «оглушённый».`,
    },
  ],
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 28,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 23,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 20,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
    CONDITION_STUNNED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_23,
  featureList: [
    {
      name: 'Порча',
      description: `Любое существо, не являющееся слизью, начинающее ход на расстоянии до 10 футов от Джуиблекса, должно пройти испытание Телосложения СЛ 21 или будет отравлено до начала следующего хода существа.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      id: ABILITY_REGENERATION,
      regen: 20,
      other: `. Если ★он★ получает урон от огня или излучения, эта черта не работает в начале его следующего хода. ★СУЩЕСТВО★ умирает только в том случае, если начинает свой ход с 0 хитами и не регенерирует`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_ACID_SPLASH,
            comment: '17-й уровень',
          },
          SPELL_DETECT_MAGIC,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_BLIGHT,
          SPELL_CONTAGION,
          SPELL_GASEOUS_FORM,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Джуиблекс совершает три атаки _Кислотной плетью_.`,
    },
    {
      name: 'Кислотная плеть',
      description: `Любое существо, убитое этой атакой, затягивается в тело Джуиблекса, и его труп растворяется спустя 1 минуту.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        hit: {
          type: DAMAGE_ACID,
          diceCount: 4,
          diceType: 6,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Выброс слизи',
      restore: 5,
      description: `Джуиблекс выплевывает едкую слизь, выбирая целью одно существо, которое он способен видеть на расстоянии до 60 футов от него. Цель должна пройти испытание Ловкости СЛ 21. При провале цель получает 55 (10к10) урона кислотой. За исключением тех случаев, когда цель избегает получения этого урона, любая металлическая броня, которую носила цель, навсегда получает штраф −1 к КД, а любое металлическое оружие, которое носила цель, навсегда получает штраф −1 к броскам урона. Штраф усиливается каждый раз, когда цель становится жертвой этого эффекта. Если штраф к объекту достиг значения −5, этот объект уничтожается.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Кислотные брызги',
      description: `Джуиблекс читает заклинание [Брызги кислоты](SPELL:${SPELL_ACID_SPLASH}).`,
    },
    {
      name: 'Атака',
      description: `Джуиблекс совершает одну атаку кислотной плетью.`,
    },
    {
      name: 'Оскверняющее касание',
      cost: 2,
      description: `Цель покрыта слизью. До тех пор, пока слизь не будет счищена действием, цель будет отравленной, а также любые существа, не являющиеся слизью, будут отравлены, если будут находиться на расстоянии до 10 футов от цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        hit: {
          type: DAMAGE_POISON,
          diceCount: 4,
          diceType: 6,
          diceBonus: 7,
        },
      },
    },
  ],
}
