const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_ILLUSKAN} = require('./../../languageIdList')
const {LANG_SUBGROUP_ILLUSKI} = require('./../../languageSubGroupList')
const {
  PLACE_LUSKAN,
  PLACE_MINTARN,
  PLACE_MOONSHAES,
  PLACE_SAVAGE_NORTH,
  PLACE_RUATHYM,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_ILLUSKAN,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_ILLUSKI,
  name: {
    nominative: 'Иллюсканский',
    genitive: 'Иллюсканского',
    instrumental: 'Иллюсканским',
    prepositional: 'Иллюсканском',
  },
  nameEn: 'Illuskan',
  typicalSpeakers: 'Иллюсканцы, утгардтские варвары',
  spokenPlaceList: [
    PLACE_LUSKAN,
    PLACE_MINTARN,
    PLACE_MOONSHAES,
    {
      id: PLACE_SAVAGE_NORTH,
      comment: '(нецивилизованные области)',
    },
    PLACE_RUATHYM,
  ],
  isReady: false,
  isRealLang: true,
}
