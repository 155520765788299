const {SOURCE_TOA} = require('./../../sourceList')
const {
  CREATURE_YELLOW_MUSK_CREEPER,
  CREATURE_YELLOW_MUSK_ZOMBIE,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Жёлтая мускусная лиана и зомби',
    text: `[Жёлтая мускусная лиана](CREATURE:${CREATURE_YELLOW_MUSK_CREEPER}) — это нечестивая лоза, цветы которой напоминают орхидеи. Яркие жёлтые с вкраплениями фиолетового цвета, эти цветы источают мускус, который привлекает добычу.

[Лиана](CREATURE:${CREATURE_YELLOW_MUSK_CREEPER}) цепляется за стены, колонны, надгробные плиты, дверные рамы или скульптуры в теневых местах, оставаясь неподвижной до тех пор, пока не настанет время для атаки.`,
    source: {
      id: SOURCE_TOA,
      page: 224,
    },
  },
  {
    header: 'Гуманоидные носители',
    text: `[Жёлтая мускусная лиана](CREATURE:${CREATURE_YELLOW_MUSK_CREEPER}) уничтожает разум гуманоидов, затем имплантирует своё семя в тех, кого она убивает. Через двадцать четыре часа после имплантации семя прорастает в ползущую лиану, которая волшебным образом оживляет труп хозяина, превращая его в [жёлтого мускусного зомби](CREATURE:${CREATURE_YELLOW_MUSK_ZOMBIE}) под контролем молодой лозы.

В дополнение к защите беззащитного растения [зомби](CREATURE:${CREATURE_YELLOW_MUSK_ZOMBIE}) действует как удобрение для молодой лозы, которая за семь дней вырастает до полного размера. После того, как она полностью вырастет, новая [жёлтая мускусная лиана](CREATURE:${CREATURE_YELLOW_MUSK_CREEPER}) становится самостоятельной и вылезает из своего зомби-носителя, после чего зомби разваливается на омертвевшие части. Если [зомби](CREATURE:${CREATURE_YELLOW_MUSK_ZOMBIE}) уничтожается до появления лианы, то лиана засыхает и умирает.`,
    source: {
      id: SOURCE_TOA,
      page: 224,
    },
  },
]
