const {FEATURE_PRIMEVAL_GUARDIAN_SPELLS} = require('./../../../featureIdList')
const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_PRIMEVAL_GUARDIAN_2017_01_16} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2017_01_16_RANGER_ROGUE} = require('./../../../sourceList')
const {
  SPELL_CONJURE_ANIMALS,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENTANGLE,
  SPELL_GIANT_INSECT,
  SPELL_INSECT_PLAGUE,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_PRIMEVAL_GUARDIAN_SPELLS,
    name: `Заклинания Первобытного стража`,
    nameEn: `Primeval Guardian Spells`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    pcSubClassId: PC_SUBCLASS_RANGER_PRIMEVAL_GUARDIAN_2017_01_16,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_CONJURE_ANIMALS,
      SPELL_ENHANCE_ABILITY,
      SPELL_ENTANGLE,
      SPELL_GIANT_INSECT,
      SPELL_INSECT_PLAGUE,
    ],
    source: {
      id: SOURCE_UA_2017_01_16_RANGER_ROGUE,
      page: 2,
    },
  },
]
