const {SOURCE_XGTE} = require('./../../../sourceList')
const {PC_SUBCLASS_BARBARIAN_PATH_OF_THE_STORM_HERALD} = require('./../../../pcSubClassIdList')

const {
  FEATURE_RAGE,
  FEATURE_RAGING_STORM,
  FEATURE_SHIELDING_STORM,
  FEATURE_STORM_AURA,
  FEATURE_STORM_SOUL,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_STORM_AURA,
    name: `Аура бури`,
    nameEn: `Storm Aura`,
    lvl: [3, 5, 10, 15, 20],
    text: `Вы излучаете штормовую магическую _Ауру_ во время [Ярости](FEATURE:${FEATURE_RAGE}). _Аура_ распространяется от Вас на 10 футов во всех направлениях, но не через полные укрытия.

У Вашей _Ауры_ есть эффект, который активируется когда Вы входите в [Ярость](FEATURE:${FEATURE_RAGE}), и Вы можете активировать эффект снова в каждый свой ход бонусным действием. Выберите пустыню, море или тундру. Ваш эффект _Ауры_ зависит от выбранной среды (как описано ниже). Вы можете сменить выбор окружающей среды когда получаете уровень в этом классе.

Если эффект Вашей _Ауры_ требует испытания, СЛ равна **8 + бонус мастерства + модификатор Телосложения**.

# Пустыня

Когда этот эффект активируется, все остальные существа в Вашей _Ауре_ получают 2 урона огнем. Урон увеличивается когда Вы достигаете определённых уровней в этом классе, увеличивается до 3 на 5 уровне, 4 на 10 уровне, 5 на 15 уровне и 6 на 20 уровне.

# Море

Когда этот эффект активируется, Вы можете выбрать любое другое существо, видимое Вами внутри Вашей _Ауры_. Цель должна пройти испытание Ловкости. Цель получает 1к6 урона электричеством при провале или половину урона при успехе. Урон увеличивается когда Вы достигаете определённых уровней в этом классе, увеличивается до 2к6 на 10 уровне, 3к6 на 15 уровне, и 4к6 на 20 уровне.

# Тундра

Когда активируется этот эффект, каждое существо по Вашему выбору внутри Вашей _Ауры_ получает 2 временных хита, так как ледяные духи делают их менее чувствительными к страданиям. Количество временных хитов увеличивается, когда Вы достигаете определённых уровней в этом классе, увеличивается до 3 на 5 уровне, 4 на 10 уровне, 5 на 15 уровне и 6 на 20 уровне.`,
    source: {
      id: SOURCE_XGTE,
      page: 16,
    },
  },
  {
    id: FEATURE_STORM_SOUL,
    name: `Душа бури`,
    nameEn: `Storm Soul`,
    lvl: 6,
    text: `Буря даёт Вам преимущества даже когда [Аура бури](FEATURE:${FEATURE_STORM_AURA}) не активна. Преимущества зависят от окружающей среды, которую Вы выбрали для Вашей [Ауры бури](FEATURE:${FEATURE_STORM_AURA}).

# Пустыня

Вы получаете сопротивление урону огнём и не страдаете от эффектов невероятной жары.

Кроме того, действием Вы можете дотронуться до горючего объекта, который никто не несёт и не носит, и поджечь его.

# Море

Вы получаете сопротивление урону электричеством и можете дышать под водой.

Вы также получаете скорость плавания 30 футов.

# Тундра

Вы получаете сопротивление урону холодом, и не страдаете от эффектов сильного холода.

Кроме того, Вы можете действием дотронуться до воды и превратить её в куб льда со стороной 5 футов, который растает через 1 минуту. Действие проваливается если в кубе находится существо.`,
    source: {
      id: SOURCE_XGTE,
      page: 17,
    },
  },
  {
    id: FEATURE_SHIELDING_STORM,
    name: `Ограждающая буря`,
    nameEn: `Shielding Storm`,
    lvl: 10,
    text: `Вы учитесь использовать своё мастерство бури, для защиты других.

Каждое существо по Вашему выбору получает сопротивление урону, которое Вы получили от способности [Душа бури](FEATURE:${FEATURE_STORM_SOUL}), пока оно находится внутри Вашей [Ауры бури](FEATURE:${FEATURE_STORM_AURA}).`,
    source: {
      id: SOURCE_XGTE,
      page: 17,
    },
  },
  {
    id: FEATURE_RAGING_STORM,
    name: `Неистовство бури`,
    nameEn: `Raging Storm`,
    lvl: 14,
    text: `Сила бури, которую Вы призываете становится могущественнее и обрушивается на Ваших врагов. Эффект основан на окружающей среде, которую Вы выбрали для Вашей [Ауры бури](FEATURE:${FEATURE_STORM_AURA}).

# Пустыня

Сразу же после того как существо в Вашей [Ауре бури](FEATURE:${FEATURE_STORM_AURA}) попадет по Вам атакой, Вы можете реакцией заставить его пройти испытание Ловкости. При провале, существо получает урон огнём равный половине Вашего уровня варвара.

# Море

Когда Вы попадаете по существу в Вашей [Ауре бури](FEATURE:${FEATURE_STORM_AURA}) атакой, Вы можете реакцией заставить существо пройти испытание Силы. При провале существо сбивается с ног, будто от удара волны.

# Тундра

Когда эффект Вашей [Ауры бури](FEATURE:${FEATURE_STORM_AURA}) активирован, Вы можете выбрать одно существо, видимое Вами внутри Вашей [Ауры бури](FEATURE:${FEATURE_STORM_AURA}). Оно должно пройти испытание Силы. При провале его скорость уменьшается до 0 до начала Вашего следующего хода так как его покрывает магический мороз.`,
    source: {
      id: SOURCE_XGTE,
      page: 17,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_STORM_HERALD,
  })
)

