const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_DETHEK} = require('./../../alphabetList')
const {LANG_TASHALAN} = require('./../../languageIdList')
const {
  PLACE_BLACK_JUNGLE,
  PLACE_MHAIR_JUNGLE,
  PLACE_SAMARACH,
  PLACE_TASHALAR,
  PLACE_THINDOL,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_TASHALAN,
  alphabetId: ALPHABET_DETHEK,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Ташаланский',
    genitive: 'Ташаланского',
    instrumental: 'Ташаланским',
    prepositional: 'Ташаланском',
  },
  nameEn: 'Tashalan',
  spokenPlaceList: [
    PLACE_BLACK_JUNGLE,
    PLACE_MHAIR_JUNGLE,
    PLACE_SAMARACH,
    PLACE_TASHALAR,
    PLACE_THINDOL,
  ],
  isReady: false,
  isRealLang: true,
}
