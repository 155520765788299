const {CREATURE_FIRE_ELEMENTAL} = require('./../../../../creatureIdList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_IGNAN} = require('./../../../../languageIdList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPELL_DOMINATE_MONSTER} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_DEVASTATION_ORB_FIRE,
  MAGIC_ITEM_TINDERSTRIKE,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_TINDERSTRIKE,
  name: 'Высекающий искры',
  nameEn: 'Tinderstrike',
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_DAGGER,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: [
    {
      header: 'Высекающий искры',
      text: `_Высекающий искры_ — это необычайно острый кремниевый кинжал. Если ударить им по чему-нибудь твёрдому, с его лезвия срывается сноп искр. Кинжал всегда тёплый на ощупь, а после того, как нанести им урон, его клинок будет тлеть на протяжении 1к4 минут. В нём содержится частичка силы Имикса, Принца Злого Огня.

Вы получаете +2 к броскам атаки и урона этим магическим оружием. При попадании цель получает дополнительный урон огнём 2к6.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Власть над огнём',
      text: `Если вы вооружены этим кинжалом, то получаете следующие преимущества:
      
* Вы свободно говорите на [Игнане](LANG:${LANG_IGNAN}).
* Вы обладаете сопротивлением урону огнём.
* Вы можете сотворить заклинание [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}) (СЛ 17) на [огненного элементаля](CREATURE:${CREATURE_FIRE_ELEMENTAL}). Если Вы сотворили это заклинание, то не сможете вновь использовать данную особенность до следующего рассвета.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Танец всепожирающего огня',
      text: `Находясь в стихийном узле огня, Вы, с помощью _Высекающего искры_, можете совершить ритуал под названием «Танец всепожирающего огня», который позволит вам создать [огненную сферу опустошения](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_FIRE}). После окончания ритуала вы не сможете повторить его до следующего рассвета.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Слабость',
      text: `_Высекающий искры_ делает своего владельца нетерпеливым и безрассудным. Пока существо настроено на это оружие, оно получает следующую слабость: _«Я действую спонтанно и постоянно рискую, не думая о последствиях»_.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
  ],
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PotA,
    page: 229,
  },
}
