const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../../attackTypeList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_GUARDIAN_OF_NATURE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GUARDIAN_OF_NATURE,
  name: 'Страж природы',
  nameEn: 'Guardian of Nature',
  description: `Дух природы отвечает на Ваш призыв и превращает Вас в могущественного стража. Превращение длится до тех пор, пока заклинание не закончится. Вы выбираете одну из следующих форм: Первобытный зверь или Великое древо.

# Первобытный зверь

Мягкий мех покрывает Ваше тело, Ваши черты лица становятся дикими, и Вы получаете следующие преимущества:

* Скорость ходьбы увеличивается на 10 футов.
* Вы получаете тёмное зрение в радиусе 120 футов.
* Вы совершаете броски атаки, основанные на Силе, с преимуществом.
* Рукопашные атаки оружием наносят дополнительный урон силовым полем 1к6.

# Великое древо

Ваша кожа покрывается корой, листья прорастают из Ваших волос, и Вы получаете следующие преимущества:

* Вы получаете 10 временных хитов.
* Вы с преимуществом проходите испытания Телосложения.
* Вы совершаете броски атаки, основанные на Ловкости и Мудрости, с преимуществом.
* Когда Вы находитесь на земле, земля в радиусе 15 футов от Вас становится труднопроходимой местностью для врагов.`,
  lvl: 4,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_VERBAL],
  duration: {timeId: TIME_MINUTE, count: 1},
  castTime: {timeId: TIME_BONUS_ACTION, count: 1},
  needConcentration: true,
  effect: {
    attackType: ATTACK_MELEE_WEAPON,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 168,
  },
}
