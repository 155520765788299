const {
  CREATURE_DIATRYMA,
  CREATURE_AXE_BEAK,
} = require('./../../../../creatureIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {MAGIC_ITEM_FEATHER_OF_DIATRYMA_SUMMONING} = require('./../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {SOURCE_FTD} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_FEATHER_OF_DIATRYMA_SUMMONING,
  name: 'Перо призыва диатримы',
  nameEn: 'Feather of Diatryma Summoning',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Этот пёстрый плюмаж сделан из пера [диатримы](CREATURE:${CREATURE_DIATRYMA}) — большой цветастой нелетучей птицы, обитающей в Подземье. Если Вы действием произнесёте командное слово и бросите перо в свободное пространство Большого размера на земле в пределах 5 футов, то перо превратится в живую диатриму на срок до 6 часов, после чего та снова превратится в перо.

Когда диатрима вновь превратится в перо, магию пера нельзя снова использовать, пока не пройдёт 7 дней.

У [диатримы](CREATURE:${CREATURE_DIATRYMA}) параметры [топороклюва](CREATURE:${CREATURE_AXE_BEAK}), но её клюв наносит колющий урон вместо режущего. Существо дружественно Вам и Вашим спутникам, и на нём можно ездить, как на скакуне. Оно понимает Ваши языки и подчиняется Вашим устным приказам. Если Вы не прикажете ничего, [диатрима](CREATURE:${CREATURE_DIATRYMA}) будет только защищаться, но не совершает иных действий.`,
  genderId: GENDER_MIDDLE,
  creatureId: CREATURE_DIATRYMA,
  source: {
    id: SOURCE_FTD,
    page: 192,
  },
}
