const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  PC_CLASS_WIZARD,
} = require('./../../../../pcClassIdList')
const {
  FEATURE_SPELLBOOK,
} = require('./../../../../featureIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../magicItemRarityList')
const {
  SPELL_AUGURY,
  SPELL_DIVINATION,
  SPELL_FIND_THE_PATH,
  SPELL_FORESIGHT,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_ASTROMANCY_ARCHIVE,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_ASTROMANCY_ARCHIVE,
  name: `Архив астромантии`,
  nameEn: `Astromancy Archive`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  attunementComment: `волшебником`,
  description: `Этот латунный диск состоит из сочленённых концентрических колец и может разворачиваться в армиллярную сферу.

Вы можете бонусным действием разворачивать диск в сферу и наоборот.

_Архив астромантии_ может использоваться как [книга заклинаний](FEATURE:${FEATURE_SPELLBOOK}), где заклинания закодированы на кольцах диска. Он уже содержит некоторые заклинания, которые считаются для Вас заклинаниями [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}), пока Вы на него настроены.

* [Гадание](SPELL:${SPELL_AUGURY})
* [Поиск предмета](SPELL:${SPELL_LOCATE_OBJECT})
* [Поиск пути](SPELL:${SPELL_FIND_THE_PATH})
* [Поиск существа](SPELL:${SPELL_LOCATE_CREATURE})
* [Предвидение](SPELL:${SPELL_FORESIGHT})
* [Предсказание](SPELL:${SPELL_DIVINATION})

Держа _Архив астромантии_, его можно использовать как заклинательную фокусировку для Ваших заклинаний [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}).

У _Архива_ есть 3 заряда и он восстанавливает 1к3 заряда на рассвете. Держа его, Вы можете использовать его заряды следующими способами.

* Если Вы потратите 1 минуту, изучая _Архив астромантии_, то можете потратить 1 заряд чтобы заменить одно из Ваших подготовленных заклинаний [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}) на другое из _Архива_. Новое заклинание должно принадлежать школе Прорицания.
* Когда видимое Вами в пределах 30 футов существо совершает бросок атаки, проверку характеристики или испытание, Вы можете реакцией потратить 1 заряд и заставить то существо добавить или вычесть (на Ваш выбор) к4 из исходного броска. Вы можете сделать это до или после броска, но до того, как эффекты броска вступят в силу.`,
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_AUGURY,
    SPELL_DIVINATION,
    SPELL_FIND_THE_PATH,
    SPELL_FORESIGHT,
    SPELL_LOCATE_CREATURE,
    SPELL_LOCATE_OBJECT,
  ],
  source: {
    id: SOURCE_TCoE,
    page: 173,
  },
}
