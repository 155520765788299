const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_MYRMIDON,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ONE_OF_ITS_CREATOR,
  LANG_TERRAN,
} = require('./../../../../languageIdList')
const {ABILITY_MAGIC_WEAPONS} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_ELEMENTAL_MYRMIDON_EARTH} = require('./../../../../creatureIdList')
const {elementalMyrmidonDescription} = require('./../../../../textCommonParts')
const {
  GEAR_MAUL,
  GEAR_PLATE_ARMOR,
} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Земляной элементальный мирмидон',
  nameEn: 'Earth Elemental Myrmidon',
  id: CREATURE_ELEMENTAL_MYRMIDON_EARTH,
  description: elementalMyrmidonDescription,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_MYRMIDON,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MTOF,
    page: 247,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_TERRAN,
    LANG_ONE_OF_ITS_CREATOR,
  ],
  cr: CR_7,
  featureList: [
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки _Кувалдой_.`,
    },
    {
      gearId: GEAR_MAUL,
    },
    {
      name: 'Громовой удар',
      restore: 6,
      description: `Мирмидон совершает одну атаку _Кувалдой_. При попадании цель получает дополнительно 16 (3к10) урона звуком и должна пройти испытание Силы СЛ 14. При провале цель Сбивается с ног.`,
    },
  ],
}
