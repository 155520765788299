const {
  CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  CREATURE_KOBOLD_DRAGONSHIELD_WHITE,
} = require('./../../../../creatureIdList')
const {DAMAGE_COLD} = require('./../../../../damageTypeList')

module.exports = {
  name: 'Кобольд белый драконий щит',
  nameEn: 'Kobold Dragonshield (White)',
  id: CREATURE_KOBOLD_DRAGONSHIELD_WHITE,
  parentId: CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  resistanceList: [
    DAMAGE_COLD,
  ],
}
