const {
  CREATURE_GUARD_DRAKE,
  CREATURE_GUARD_DRAKE_RED,
} = require('./../../../../creatureIdList')
const {DAMAGE_FIRE} = require('./../../../../damageTypeList')
const {SPEED_CLIMB} = require('./../../../../speedList')

module.exports = {
  name: 'Красный сторожевой дрейк',
  nameEn: 'Red Guard Drake',
  id: CREATURE_GUARD_DRAKE_RED,
  parentId: CREATURE_GUARD_DRAKE,
  extendPropCollection: {
    speed: {
      [SPEED_CLIMB]: 30,
    },
    resistanceList: [
      DAMAGE_FIRE,
    ],
  },
}
