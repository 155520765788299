const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_NEEDLEFOLK} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_NEEDLEFOLK,
  nameEn: 'Needlefolk',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'иглюд',
      genitive: 'иглюда',
      dative: 'иглюду',
      accusative: 'иглюда',
      instrumental: 'иглюдом',
      prepositional: 'иглюде',
    },
    plural: {
      nominative: 'иглюды',
      genitive: 'иглюдов',
      dative: 'иглюдам',
      accusative: 'иглюдов',
      instrumental: 'иглюдами',
      prepositional: 'иглюдах',
    },
  },
}
