module.exports = [
  require('./shadow_dancer'),
  require('./sibriex'),
  require('./skulk'),
  require('./skull_lord'),
  require('./spirit_troll'),
  require('./star_spawn_grue'),
  require('./star_spawn_hulk'),
  require('./star_spawn_larva_mage'),
  require('./star_spawn_mangler'),
  require('./star_spawn_seer'),
  require('./steeder_female'),
  require('./steeder_male'),
  require('./steel_predator'),
  require('./stone_cursed'),
  require('./stone_defender'),
  require('./sword_wraith_commander'),
  require('./sword_wraith_warrior'),
]
