const {
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DRAGON_METALLIC,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_CHROMATIC_DRAGON_SPIRIT_2021_04_14,
  CREATURE_METALLIC_DRAGON_SPIRIT,
  CREATURE_METALLIC_DRAGON_SPIRIT_2021_04_14,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух металлического дракона',
  nameEn: 'Metallic Draconic Spirit',
  id: CREATURE_METALLIC_DRAGON_SPIRIT_2021_04_14,
  releasedAs: CREATURE_METALLIC_DRAGON_SPIRIT,
  parentId: CREATURE_CHROMATIC_DRAGON_SPIRIT_2021_04_14,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAGON_METALLIC,
  ],
}
