const {
  SPELL_BLUR,
  SPELL_BURNING_HANDS,
  SPELL_CONE_OF_COLD,
  SPELL_CONTROL_WATER,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CREATION,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_FIRE_SHIELD,
  SPELL_FIREBALL,
  SPELL_FLAME_STRIKE,
  SPELL_FOG_CLOUD,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GUST_OF_WIND,
  SPELL_MELD_INTO_STONE,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_SANCTUARY,
  SPELL_SCORCHING_RAY,
  SPELL_SEEMING,
  SPELL_SLEET_STORM,
  SPELL_SPIKE_GROWTH,
  SPELL_STONE_SHAPE,
  SPELL_THUNDERWAVE,
  SPELL_WALL_OF_STONE,
  SPELL_WIND_WALL,
  SPELL_WISH,
} = require('./../../../spellIdList')
const {
  FEATURE_ELEMENTAL_GIFT,
  FEATURE_EXPANDED_SPELLS_THE_GENIE,
  FEATURE_EXPANDED_SPELLS_THE_GENIE_DAO,
  FEATURE_EXPANDED_SPELLS_THE_GENIE_DJINNI,
  FEATURE_EXPANDED_SPELLS_THE_GENIE_EFREETI,
  FEATURE_EXPANDED_SPELLS_THE_GENIE_MARID,
  FEATURE_GENIE_S_VESSEL,
  FEATURE_LIMITED_WISH,
  FEATURE_SANCTUARY_VESSEL,
} = require('./../../../featureIdList')
const {
  CREATURE_DAO,
  CREATURE_DJINNI,
  CREATURE_EFREETI,
  CREATURE_MARID,
} = require('./../../../creatureIdList')
const {PC_SUBCLASS_WARLOCK_THE_GENIE} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    text: `Гений позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В список доступных для изучения заклинаний колдуна для Вас добавляются следующие заклинания, вместе с заклинаниями, зависящими от вида Вашего покровителя: [дао](CREATURE:${CREATURE_DAO}), [джинн](CREATURE:${CREATURE_DJINNI}), [ифрит](CREATURE:${CREATURE_EFREETI}) или [марид](CREATURE:${CREATURE_MARID}).`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_CREATE_FOOD_AND_WATER,
      SPELL_CREATION,
      SPELL_DETECT_EVIL_AND_GOOD,
      SPELL_PHANTASMAL_FORCE,
      SPELL_PHANTASMAL_KILLER,
      SPELL_WISH,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 110,
    },
  },
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE_DAO,
    name: `Дополнительные заклинания дао`,
    nameEn: `Expanded Spells: Dao`,
    text: `Если Вы выбрали покровителем [дао](CREATURE:${CREATURE_DAO}), то в список доступных для изучения заклинаний колдуна для Вас дополнительно добавляются следующие заклинания.`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_MELD_INTO_STONE,
      SPELL_SANCTUARY,
      SPELL_SPIKE_GROWTH,
      SPELL_STONE_SHAPE,
      SPELL_WALL_OF_STONE,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 110,
    },
  },
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE_DJINNI,
    name: `Дополнительные заклинания джинна`,
    nameEn: `Expanded Spells: Djinni`,
    text: `Если Вы выбрали покровителем [джинна](CREATURE:${CREATURE_DJINNI}), то в список доступных для изучения заклинаний колдуна для Вас дополнительно добавляются следующие заклинания.`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_GREATER_INVISIBILITY,
      SPELL_GUST_OF_WIND,
      SPELL_SEEMING,
      SPELL_THUNDERWAVE,
      SPELL_WIND_WALL,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 110,
    },
  },
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE_EFREETI,
    name: `Дополнительные заклинания ифрита`,
    nameEn: `Expanded Spells: Efreeti`,
    text: `Если Вы выбрали покровителем [ифрита](CREATURE:${CREATURE_EFREETI}), то в список доступных для изучения заклинаний колдуна для Вас дополнительно добавляются следующие заклинания.`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_BURNING_HANDS,
      SPELL_FIRE_SHIELD,
      SPELL_FIREBALL,
      SPELL_FLAME_STRIKE,
      SPELL_SCORCHING_RAY,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 110,
    },
  },
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE_MARID,
    name: `Дополнительные заклинания марида`,
    nameEn: `Expanded Spells: Marid`,
    text: `Если Вы выбрали покровителем [марида](CREATURE:${CREATURE_MARID}), то в список доступных для изучения заклинаний колдуна для Вас дополнительно добавляются следующие заклинания.`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_BLUR,
      SPELL_CONE_OF_COLD,
      SPELL_CONTROL_WATER,
      SPELL_FOG_CLOUD,
      SPELL_SLEET_STORM,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 110,
    },
  },
  {
    id: FEATURE_GENIE_S_VESSEL,
    name: `Сосуд гения`,
    nameEn: `Genie’s Vessel`,
    lvl: 1,
    text: `Покровитель дарит Вам магический сосуд, который даёт Вам часть силы гения. Сосуд — это Крохотный предмет и Вы можете использовать его как магическую фокусировку для заклинаний колдуна. Вы решаете что это за предмет или определите его случайно по таблице «Сосуды гения».

# Сосуды гения

| к6 | Сосуд                 |
|----|-----------------------|
| 1  | Масляная лампа        |
| 2  | Урна                  |
| 3  | Кольцо с секретом     |
| 4  | Запечатанная бутыль   |
| 5  | Полая статуэтка       |
| 6  | Изысканный светильник |

КД Сосуда равен Вашей СЛ заклинаний. Его хиты равны Вашему уровню колдуна + Ваш бонус мастерства, и он иммунен к урону ядом и психическому урону.

Если Сосуд уничтожен или Вы его потеряли, Вы можете совершить часовую церемонию, чтобы получить замену от покровителя. Эту церемонию можно провести во время короткого или длинного отдыха и она уничтожает предыдущий Сосуд, если тот ещё существует. Если Вы умираете, Сосуд исчезает во вспышке стихийного пламени.

Касаясь Сосуда, Вы можете использовать его следующими способами.

# Передышка в бутылке

Вы можете действием магически исчезнуть и войти в свой Сосуд, который остаётся в покинутом Вами пространстве. Внутри Сосуда находится межпространственное место цилиндрической формы радиусом и глубиной 20 футов, напоминающее внутри Ваш Сосуд. Интерьер комфортно обставлен подушками и низкими столиками и имеет комфортную температуру. 

Будучи внутри, Вы слышите всё, что происходит снаружи, как если бы Вы сами там находились. Вы можете оставаться внутри количество часов, равное Вашему удвоенному бонусу мастерства. Вы покидаете Сосуд до этого, если выходите из него бонусным действием, умираете, или если Сосуд уничтожается. Когда Вы выходите из Сосуда, то появляетесь в ближайшем к нему свободном пространстве.

Все предметы, оставленные в Сосуде, остаются там, пока их оттуда не вынесут. Если Сосуд будет уничтожен, все хранящиеся там предметы невредимо появляются в свободном пространстве рядом с ним. 

Войдя в Сосуд, Вы не сможете войти в него снова, пока не завершите длинный отдых.

# Гнев гения

Один раз на каждом своём ходу, когда Вы попадаете броском атаки, Вы можете нанести цели дополнительный урон, равный Вашему бонусу мастерства. Тип урона определяется Вашим покровителем: дробящий ([дао](CREATURE:${CREATURE_DAO})), звуком ([джинн](CREATURE:${CREATURE_DJINNI})), огнём ([ифрит](CREATURE:${CREATURE_EFREETI})) или холодом ([марид](CREATURE:${CREATURE_MARID})).`,
    source: {
      id: SOURCE_TCoE,
      page: 110,
    },
  },
  {
    id: FEATURE_ELEMENTAL_GIFT,
    name: `Дар стихий`,
    nameEn: `Elemental Gift`,
    lvl: 6,
    text: `Вы начинаете получать черты вида своего покровителя.

Теперь у Вас есть сопротивление к урону, определяемому видом Вашего покровителя: дробящий ([дао](CREATURE:${CREATURE_DAO})), звуком ([джинн](CREATURE:${CREATURE_DJINNI})), огнём ([ифрит](CREATURE:${CREATURE_EFREETI})) или холодом ([марид](CREATURE:${CREATURE_MARID})).

Кроме этого, Вы можете бонусным действием дать себе на 10 минут скорость полёта 30 футов, при этом Вы парите. Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства и восстанавливаете все использования, завершив длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 111,
    },
  },
  {
    id: FEATURE_SANCTUARY_VESSEL,
    name: `Сосуд-убежище`,
    nameEn: `Sanctuary Vessel`,
    lvl: 10,
    text: `Теперь, входя в свой [Сосуд гения](FEATURE:${FEATURE_GENIE_S_VESSEL}) способностью _Передышка в бутылке_, Вы можете выбрать до пяти согласных существ, видимых Вами в пределах 30 футов и эти существа затягиваются в Сосуд вместе с Вами.

Бонусным действием Вы можете изгнать любое число существ из Сосуда и все они изгоняются отсюда, когда Вы покидаете Сосуд или когда он разрушается.

Кроме этого, все существа (включая Вас), находившиеся в Сосуде более 10 минут, получают преимущества короткого отдыха. Если существо тратило на этом коротком отдыхе кости хитов для восстановления хитов, то оно может добавить к восстанавливаемым хитам Ваш бонус мастерства.`,
    source: {
      id: SOURCE_TCoE,
      page: 111,
    },
  },
  {
    id: FEATURE_LIMITED_WISH,
    name: `Ограниченное желание`,
    nameEn: `Limited Wish`,
    lvl: 14,
    text: `Вы умоляете своего покровителя исполнить Вам небольшое желание.

Вы можете действием высказать своё желание в [Сосуд гения](FEATURE:${FEATURE_GENIE_S_VESSEL}), прося об эффекте одного заклинания 6 круга или ниже и имеющего время накладывания 1 действие. Заклинание может быть из списка заклинаний любого класса, и Вам не нужно соответствовать требованиям этого заклинания, включая дорогостоящие компоненты; эффект заклинания просто возникает как часть этого действия.

Использовав эту способность, Вы не сможете использовать её вновь, пока не завершите 1к4 длинных отдыха.`,
    source: {
      id: SOURCE_TCoE,
      page: 111,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_GENIE,
  })
)
