const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_LONGSWORD,
  GEAR_JAVELIN,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  orcDescription,
  orcNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_ORC,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  GOD_GRUUMSH,
  GOD_ILNEVAL,
} = require('./../../../../godIdList')
const {ABILITY_AGGRESSIVE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_ORC_BLADE_OF_ILNEVAL} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Орк клинок Илневала',
  nameEn: 'Orc blade of Ilneval',
  id: CREATURE_ORC_BLADE_OF_ILNEVAL,
  description: [
    `[Ильневал](GOD:${GOD_ILNEVAL}) — это боевой капитан [Груумша](GOD:${GOD_GRUUMSH}), коварный стратег, который смело направляет солдат [Груумша](GOD:${GOD_GRUUMSH}). Среди орков те воины, что чтят [Ильневала](GOD:${GOD_ILNEVAL}), подражают своему божеству. Такие орки учатся командовать своими товарищами так, чтобы это было неожиданно, но помогало обеспечить победу. Самые мудрые среди таких лидеров получают благословение [Ильневала](GOD:${GOD_ILNEVAL}) и становятся клинками, экспертами тактики, которые советуют своему вождю в случае войны.

Клинки командуют на фронте, бесстрашно бросаясь в бой, и кричат приказы младшим солдатам. Клинок знает, как использовать свирепость орков на пользу делу, и помогает обычным оркам сообща сражаться с противниками.`,
    ...orcDescription,
  ],
  note: orcNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ORC,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 179,
  },
  armor: {
    ac: 18,
    gearId: [GEAR_CHAIN_MAIL, GEAR_SHIELD],
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 17,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ORC,
  ],
  cr: CR_4,
  featureList: [
    ABILITY_AGGRESSIVE,
    {
      name: 'Каратель врагов Илневала',
      description: `Орк наносит дополнительную кость урона, если попадает атакой длинным мечом (уже включено в атаку).`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Орк делает две рукопашные атаки _Длинным мечом_ или две дальнобойные атаки _Пилумами_. Если _Приказ Илневала_ доступен для использования, то орк может использовать его после этих атак.`,
    },
    {
      name: 'Длинный меч',
      // gearId: GEAR_LONGSWORD, // TODO: Найти способ использовать gearId
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 3,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 10,
              diceBonus: 3,
              use2Hand: true,
            },
          ],
        ],
      },
    },
    {
      gearId: GEAR_JAVELIN,
    },
    {
      name: 'Приказ Илневала',
      restore: 4,
      description: `До трёх союзных орков не далее 120 футов от этого орка, которые могут его слышать, могут использовать свои реакции, чтобы сделать одну атаку оружием.`,
    },
  ],
}
