const {PC_SUBCLASS_FIGHTER_ECHO_KNIGHT} = require('./../../../pcSubClassIdList')
const {SOURCE_EGTW} = require('./../../../sourceList')
const {
  FEATURE_ECHO_AVATAR,
  FEATURE_LEGION_OF_ONE,
  FEATURE_MANIFEST_ECHO,
  FEATURE_RECLAIM_POTENTIAL,
  FEATURE_SHADOW_MARTYR,
  FEATURE_UNLEASH_INCARNATION,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_MANIFEST_ECHO,
    name: `Проявление Эха`,
    nameEn: `Manifest Echo`,
    lvl: 3,
    text: `Вы можете бонусным действием создать _Эхо_ самого себя в свободном пространстве в пределах 15 футов. _Эхо_ — магическое, полупрозрачно-серое изображение Вас. Оно существует, пока не будет уничтожено, пока Вы не развеете его бонусным действием, пока Вы не создадите другое _Эхо_, или пока Вы не потеряете сознание.

_Эхо_ имеет КД 14 + Ваш бонус мастерства, 1 хит и иммунитет ко всем состояниям. Если оно должно пройти испытание, то использует Ваши бонусы к испытаниям. Оно такого же размера, как и Вы и занимает своё пространство. На своём ходу Вы можете мысленно приказать _Эху_ переместиться на 30 футов в любом направлении (действие не требуется). Если Ваше _Эхо_ находится дальше 30 футов от Вас в конце Вашего хода, оно уничтожается.

Вы можете использовать _Эхо_ следующими способами:

* Бонусным действием Вы можете телепортироваться, магически меняясь местами с _Эхом_, тратя на это 15 футов передвижения, вне зависимости от разделяющей Вас дистанции.
* Когда на своём ходу Вы совершаете действие Атака, любые совершаемые Вами атаки могут исходить из пространства _Эха_. Вы делаете этот выбор для каждой атаки.
* Когда видимое Вами в пределах 5 футов от _Эха_ существо перемещается хотя бы на 5 футов от него, Вы можете реакцией совершить атаку по возможности по этому существу, как если бы Вы находились в пространстве _Эха_.`,
    source: {
      id: SOURCE_EGTW,
      page: 184,
    },
  },
  {
    id: FEATURE_UNLEASH_INCARNATION,
    name: `Ярость воплощения`,
    nameEn: `Unleash Incarnation`,
    lvl: 3,
    text: `Вы можете усилить ярость своего [Эха](FEATURE:${FEATURE_MANIFEST_ECHO}). Каждый раз, когда Вы совершаете действие Атака, Вы можете сделать ещё одну рукопашную атаку из пространства [Эха](FEATURE:${FEATURE_MANIFEST_ECHO}).

Вы можете использовать это умение количество раз, равное вашему модификатору Телосложения (минимум один раз). Вы восстанавливаете все затраченные использования, заканчивая длинный отдых.`,
    source: {
      id: SOURCE_EGTW,
      page: 184,
    },
  },
  {
    id: FEATURE_ECHO_AVATAR,
    name: `Эхо-аватар`,
    nameEn: `Echo Avatar`,
    lvl: 7,
    text: `Вы можете временно перенести своё сознание в [Эхо](FEATURE:${FEATURE_MANIFEST_ECHO}). Действием Вы можете начать смотреть глазами [Эха](FEATURE:${FEATURE_MANIFEST_ECHO}) и слышать его ушами. В это время Вы глухи и слепы. Вы можете поддерживать этот эффект до 10 минут, и можете остановить его в любое время (действие не требуется).

Пока [Эхо](FEATURE:${FEATURE_MANIFEST_ECHO}) используется таким образом, оно может находиться в пределах до 1000 футов от Вас, не уничтожаясь.`,
    source: {
      id: SOURCE_EGTW,
      page: 184,
    },
  },
  {
    id: FEATURE_SHADOW_MARTYR,
    name: `Теневой мученик`,
    nameEn: `Shadow Martyr`,
    lvl: 10,
    text: `Вы можете заставить [Эхо](FEATURE:${FEATURE_MANIFEST_ECHO}) броситься наперерез атаке, направленной на другое видимое Вами существо. Перед броском атаки Вы можете реакцией телепортировать [Эхо](FEATURE:${FEATURE_MANIFEST_ECHO}) в незанятое пространство в пределах 5 футов от цели. Эта атака перенаправляется на [Эхо](FEATURE:${FEATURE_MANIFEST_ECHO}).

Как только Вы используете это умение, Вы не сможете использовать его снова, пока не закончите короткий или длинный отдых.`,
    source: {
      id: SOURCE_EGTW,
      page: 184,
    },
  },
  {
    id: FEATURE_RECLAIM_POTENTIAL,
    name: `Восстановление потенциала`,
    nameEn: `Reclaim Potential`,
    lvl: 15,
    text: `Вы научились поглощать улетучивающуюся магию [Эха](FEATURE:${FEATURE_MANIFEST_ECHO}). Когда [Эхо](FEATURE:${FEATURE_MANIFEST_ECHO}) уничтожается при получении урона, Вы можете получить 2к6 + модификатор Телосложения временных хитов, если у Вас ещё нет временных хитов.

Вы можете использовать это умение количество раз, равное вашему модификатору Телосложения (минимум 1). Вы восстанавливаете все затраченные использования, заканчивая длинный отдых.`,
    source: {
      id: SOURCE_EGTW,
      page: 185,
    },
  },
  {
    id: FEATURE_LEGION_OF_ONE,
    name: `Легион из одного`,
    nameEn: `Legion of One`,
    lvl: 18,
    text: `Вы можете одним бонусным действием создать два [Эха](FEATURE:${FEATURE_MANIFEST_ECHO}), и эти [Эхи](FEATURE:${FEATURE_MANIFEST_ECHO}) могут сосуществовать. Если Вы попытаетесь создать третье [Эхо](FEATURE:${FEATURE_MANIFEST_ECHO}), предыдущие два [Эха](FEATURE:${FEATURE_MANIFEST_ECHO}) будут уничтожены. Всё, что Вы можете сделать из пространства одного [Эха](FEATURE:${FEATURE_MANIFEST_ECHO}), можно сделать и из пространства другого.

Кроме того, когда Вы бросаете инициативу и у Вас не осталось использований способности [Ярость воплощения](FEATURE:${FEATURE_UNLEASH_INCARNATION}), Вы восстанавливаете одно использование этой способности.`,
    source: {
      id: SOURCE_EGTW,
      page: 185,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_ECHO_KNIGHT,
  })
)
