const {
  CAST_VERBAL,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SPELL_GREEN_FLAME_BLADE,
  SPELL_GREEN_FLAME_BLADE_SCAG,
} = require('./../../../../../spellIdList')
const {ATTACK_MELEE_SPELL} = require('./../../../../../attackTypeList')
const {DAMAGE_FIRE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SOURCE_SCAG} = require('./../../../../../sourceList')

module.exports = {
  id: SPELL_GREEN_FLAME_BLADE_SCAG,
  releasedAs: SPELL_GREEN_FLAME_BLADE,
  name: 'Клинок зелёного пламени',
  nameEn: 'Green-Flame Blade',
  description: `Как часть действия, используемого для сотворения этого заклинания Вы должны совершить рукопашную атаку оружием по одному из существ в пределах дальности этого заклинания или заклинание провалится.

При попадании, цель получает нормальный урон от атаки и зелёный огонь прыгает с цели на другое существо по Вашему выбору, видимое Вами в пределах 5 футов от первого. Второе существо получает урон огнём, равный Вашему модификатору заклинательной характеристики.`,
  highLvlCast: `Урон заклинания растёт с повышением Вашего уровня. На 5-м уровне рукопашная атака наносит дополнительно 1к8 урона огнём по цели и урон по второму существу возрастает до 1к8 + модификатор заклинательной характеристики. Оба урона ещё возрастают на 1к8 на 11-м и на 17-м уровнях.`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 5,
  componentIdList: [CAST_VERBAL, CAST_MATERIAL],
  materialText: 'любое оружие',
  effect: {
    attackType: ATTACK_MELEE_SPELL,
    damage: {
      type: DAMAGE_FIRE,
      diceCount: 1,
      diceType: 8,
      diceBonus: 0,
    },
  },
  source: {
    id: SOURCE_SCAG,
    page: 144,
  },
}
