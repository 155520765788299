const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_ABI_DALZIM_S_HORRID_WILTING} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ABI_DALZIM_S_HORRID_WILTING,
  name: 'Ужасное увядание Аби-Далзима',
  nameEn: 'Abi-Dalzim’s Horrid Wilting',
  description: `Заклинание испаряет жидкость из тела каждого существа в 30-футовом кубе с центром в указанной Вами точке. Каждое существо в этой области должно пройти испытание Телосложения. На конструктов и нежить это заклинание не действует, а растения и водные элементали получают помеху на испытание. При провале существо получает урон некротической энергией 12к8 или половину этого урона при успехе.

Немагические растения в области действия заклинания, не являющиеся существами, такие как деревья и кусты, увядают и умирают мгновенно.`,
  lvl: 8,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 150,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кусочек губки`,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_NECROTIC,
      halfOnSuccess: true,
      diceCount: 12,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 170,
    },
    {
      id: SOURCE_EE,
      page: 23,
    },
  ],
}
