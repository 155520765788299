const {SOURCE_TOA} = require('./../../../../sourceList')
const {
  flamingFistDescriptionList,
  flamingFistNoteList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_FLAMING_FIST_GAUNTLET,
  CREATURE_VETERAN,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Перчатка Пылающего Кулака',
  nameAlt: 'Капрал Пылающего Кулака',
  nameEn: 'Flaming Fist Gauntlet',
  nameEnAlt: 'Flaming Fist Corporal',
  id: CREATURE_FLAMING_FIST_GAUNTLET,
  parentId: CREATURE_VETERAN,
  description: flamingFistDescriptionList,
  note: flamingFistNoteList,
  source: {
    id: SOURCE_TOA,
    page: 75,
  },
}
