const {
  SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_BEHOLDER_KEEN,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DEEP_SPEECH,
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  CREATURE_BEHOLDERKIN_SPIRIT,
  CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
} = require('./../../../../spellIdList')

module.exports = {
  name: 'Дух злобоглаза',
  nameEn: 'Beholdrekin Spirit',
  id: CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26,
  releasedAs: CREATURE_BEHOLDERKIN_SPIRIT,
  createdBySpellId: SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_BEHOLDER_KEEN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
    page: 3,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `модификатор Телосложения духа + модификатор заклинательной характеристики заклинателя + десятикратный круг заклинания`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 15,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  immunityList: [
    DAMAGE_PSYCHIC,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Луч из глаза',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 30,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
