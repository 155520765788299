const {
  LANG_ANY,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_HISTORY,
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_CHARM_PERSON,
  SPELL_COLOR_SPRAY,
  SPELL_DETECT_THOUGHTS,
  SPELL_GREATER_RESTORATION,
  SPELL_HEAL,
  SPELL_HOLD_PERSON,
} = require('./../../../../spellIdList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  oblexAdultAndElderDescription,
  oblexNoteList,
} = require('./../../../../textCommonParts')
const {ABILITY_AMORPHOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_OBLEX_ADULT} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_OOZE} = require('./../../../../creatureTypeIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Зрелый облекс',
  nameEn: 'Adult Oblex',
  id: CREATURE_OBLEX_ADULT,
  description: oblexAdultAndElderDescription,
  note: oblexNoteList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_OOZE,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 215,
  },
  armor: 14,
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 16,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    pickOne: {
      [SKILL_HISTORY]: PROF_NORMAL,
      [SKILL_ARCANA]: PROF_NORMAL,
      [SKILL_NATURE]: PROF_NORMAL,
      [SKILL_RELIGION]: PROF_NORMAL,
    },
  },
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  languageList: [
    LANG_COMMON,
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_AMORPHOUS,
    {
      name: 'Боязнь огня',
      description: `Если облекс получает огненный урон, то до конца своего следующего хода он совершает с помехой броски атаки и проверки характеристик`,
    },
    {
      name: 'Сернистое воплощение',
      description: `Облекс, бонусным действием, может вытянуть часть самого себя, что принимает вид одного из существ Среднего или меньшего размера, чьи воспоминания были поглощены. Появившаяся сущность выглядит, ощущается и звучит так же, как и существо, чьи воспоминания были поглощены облексом, но от неё исходит запах серы. Облекс может создать таким образом 1к4 + 1 различных существ, каждое из которых связано с его телом слизистым усиком, растягивающимся на дистанцию до 120 фт. Во всех практических отношениях, эта сущность является частью облекса, и таким образом облекс занимает своё пространство и пространство сущности. Слизистый усик невосприимчив к урону, но разрывается если нет щели хотя бы шириной 1 дюйм между основным телом облекса и сущностью. Сущность исчезает если усик будет разорван.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_INT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_COLOR_SPRAY,
          SPELL_DETECT_THOUGHTS,
          {
            id: SPELL_HOLD_PERSON,
            comment: 'как заклинание 3 уровня',
          },
          {
            id: SPELL_CHARM_PERSON,
            comment: 'как заклинание 5 уровня',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Облекс может один раз атаковать _Ложноножкой_ и использовать _Поглощение воспоминаний_.`,
    },
    {
      name: 'Ложноножка',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 2,
            diceType: 4,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Поглощение воспоминаний',
      description: `Облекс выбирает целью одно существо в пределах 5 фт. от себя, которое может видеть. Цель должна пройти испытание Мудрости (Сл 15), иначе получит 18 (4к8) урона психической энергией и облекс поглощает её память, пока цель не закончит короткий или длинный отдых, или не попадёт под воздействие заклинания [Высшего восстановления](SPELL:${SPELL_GREATER_RESTORATION}) или [Полного исцеления](SPELL:${SPELL_HEAL}). Конструкты, слизи, растения и нежить автоматически преуспевают в испытании.

Пока память цели поглощена облексом, цель должна делать бросок к4 и вычитать выпавшее значение из любой проверки характеристики или броска атаки. Каждое следующее поглощение памяти после первого усиливает эффект и увеличивает вычитаемую кость: к4 становится к6, к6 становится к8 и так далее, пока кость не станет к20, после чего цель теряет сознание на 1 час. Затем эффект заканчивается.

После того, как облекс поглотил память существа, он автоматически узнает все известные тому языки и получает все владения цели, кроме владения испытаниями.`,
    },
  ],
}
