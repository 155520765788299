const arrify = require('arrify')
const listToCollectionById = require('./../../utils/listToCollectionById')
const upLetter = require('./../../utils/upLetter')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
} = require('./../genderList')
const creatureTypeRawList = require('./creatureTypeRawList')

const creatureTypeList = creatureTypeRawList
  .map(
    creatureType => ({
      ...creatureType,
      hideFromStatBlock: Boolean(creatureType.hideFromStatBlock),
      hideFromFilter: Boolean(creatureType.hideFromFilter),
      notRealRace: Boolean(creatureType.notRealRace),
      description: creatureType.description
        ? arrify(creatureType.description).map(
          description => description.header
            ? description
            : {
              ...description,
              header: upLetter(creatureType.name.plural.nominative)
            }
        )
        : null,
      name: {
        ...creatureType.name,
        singular: creatureType.name.singular.nominative
          ? {
            [GENDER_MALE]: creatureType.name.singular,
            [GENDER_FEMALE]: creatureType.name.singular,
            [GENDER_MIDDLE]: creatureType.name.singular,
          }
          : (// Подменяем средний пол мужским, если средний пол пропущен
            creatureType.name.singular[GENDER_MALE] &&
            !creatureType.name.singular[GENDER_MIDDLE]
          )
            ? {
              ...creatureType.name.singular,
              [GENDER_MIDDLE]: creatureType.name.singular[GENDER_MALE],
            }
            : creatureType.name.singular,
      },
    }),
  )
  .sort(
    (
      {
        name: {
          singular: {
            [GENDER_MALE]: {
              nominative: A,
            },
          },
        },
      },
      {
        name: {
          singular: {
            [GENDER_MALE]: {
              nominative: B,
            },
          },
        },
      },
    ) => A > B ? 1 : -1,
  )

module.exports = creatureTypeList

module.exports.creatureTypeCollection = listToCollectionById(creatureTypeList)
