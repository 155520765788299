const {GENDER_MALE} = require('./../../../genderList')
const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../magicItemTypeList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {MAGIC_ITEM_MOON_TOUCHED_SWORD} = require('./../../../magicItemIdList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_MOON_TOUCHED_SWORD,
  name: `Осенённый луной меч`,
  nameEn: `Moon-Touched Sword`,
  type: MGC_TYPE_WEAPON,
  typeComment: `любой меч`,
  rarity: MGC_RARITY_COMMON,
  description: `В темноте обнажённое лезвие этого меча излучает лунный свет, создавая яркий свет в радиусе 15 футов и тусклый свет в радиусе ещё 15 футов.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_XGTE,
    page: 137,
  },
}
