const {
  SPELL_ALTER_SELF,
  SPELL_CLOUDKILL,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_FAERIE_FIRE,
  SPELL_FLY,
  SPELL_GREATER_INVISIBILITY,
  SPELL_LEVITATE,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MINOR_ILLUSION,
  SPELL_MISTY_STEP,
  SPELL_POISON_SPRAY,
  SPELL_RAY_OF_FROST,
  SPELL_SHIELD,
  SPELL_WEB,
  SPELL_WITCH_BOLT,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_DROW_MAGE,
  CREATURE_QUASIT,
  CREATURE_SHADOW_DEMON,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  drowDescription,
  drowMageNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_7} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Маг дроу`,
  nameEn: 'Drow Mage',
  id: CREATURE_DROW_MAGE,
  description: [
    `Привилегированные мужчины дроу, которым не хватает силы и боевой доблести для тренировок в качестве воителей, не имеют другого выбора, кроме как начать изучать магию. Для них это вопрос выживания. Женщины дроу с естественной склонностью к магии также могут становиться магами, хотя это и происходит гораздо реже.`,
    ...drowDescription,
  ],
  note: drowMageNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 308,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
    LANG_ELVEN,
  ],
  cr: CR_7,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DANCING_LIGHTS,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            {
              id: SPELL_LEVITATE,
              comment: 'только на себя',
            },
            SPELL_FAERIE_FIRE,
            SPELL_DARKNESS,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 10,
      spellCasterClass: PC_CLASS_WIZARD,
      spellIdList: [
        SPELL_ALTER_SELF,
        SPELL_CLOUDKILL,
        SPELL_EVARD_S_BLACK_TENTACLES,
        SPELL_FLY,
        SPELL_GREATER_INVISIBILITY,
        SPELL_LIGHTNING_BOLT,
        SPELL_MAGE_ARMOR,
        SPELL_MAGE_HAND,
        SPELL_MAGIC_MISSILE,
        SPELL_MINOR_ILLUSION,
        SPELL_MISTY_STEP,
        SPELL_POISON_SPRAY,
        SPELL_RAY_OF_FROST,
        SPELL_SHIELD,
        SPELL_WEB,
        SPELL_WITCH_BOLT,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        2,
      ],
    },
  ],
  actionList: [
    {
      name: `Посох`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 1,
              diceType: 6,
              diceBonus: -1,
            },
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 1,
              diceType: 8,
              diceBonus: -1,
              use2Hand: true,
            },
          ],
          {
            type: DAMAGE_POISON,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: `Призыв демона`,
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ магическим образом призывает [квазита](CREATURE:${CREATURE_QUASIT}), или пытается призвать [теневого демона](CREATURE:${CREATURE_SHADOW_DEMON}) с 50-процентным шансом успеха. Призванный демон появляется в свободном пространстве в пределах 60 футов от призывателя, действует как его союзник, и не может призывать других демонов. Он присутствует в течение 10 минут, пока он, либо призыватель, не умрут, или пока призыватель не отпустит его действием.`,
    },
  ],
  genderId: GENDER_MALE,
}
