const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
  CONDITION_BLINDED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
} = require('./../../../../conditionList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_SHAMBLING_MOUND} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_PLANT} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')

module.exports = {
  name: 'Ползающая насыпь',
  nameAlt: [
    'Ползун',
    'Ходячая куча',
  ],
  nameEn: 'Shambling Mound',
  nameEnAlt: 'Shambler',
  id: CREATURE_SHAMBLING_MOUND,
  description: [
    {
      header: 'Ползающая насыпь',
      text: `Ползающая насыпь (иногда её называют «ползуном») медленно пробивает себе путь через болота, мрачные джунгли, дождливые леса, поглощая любую органическую материю на своём пути. Эта сборная солянка из всего, что попадалось под руку, высотой с рост человека, оканчивающаяся безликой «головой» сверху.`,
      source: {
        id: SOURCE_MM,
        page: 238,
      },
    },
    {
      header: 'Ходячая куча',
      text: `Эти гниющие кучи ожившей растительности поглощают всю органическую материю, с которой соприкасаются. Она раздуваются от останков растений, животных и всего, что течёт в канализации.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: 'Всепоглощающие пожиратели',
      text: `Ползающая насыпь питается любой органикой, безостановочно поглощая растения, через которые передвигается, и животных, что не успели убежать. Лишь из-за своей редкости и медлительности блуждающие насыпи не губят целые экосистемы. Но даже так их присутствие разрушает естественную среду обитания растений и животных, а болота и леса, в которых обитают эти вечно голодные ужасы, пронизывает тревожная тишина.`,
      source: {
        id: SOURCE_MM,
        page: 238,
      },
    },
    {
      header: 'Незримые охотники',
      text: `Состоящие из увядающих листьев, лоз, корней и прочего естественного окружения болот и лесов, ползуны могут сливаться с окружением. Из-за своей медлительности они редко преследуют и ловят существ. Чаще всего они остаются на месте, поглощая питательные вещества вокруг себя, пока добыча сама не придёт к ним. Стоит существу пройти неподалёку или сесть на ползуна, чудовище оживает, захватывая и поглощая опрометчивую жертву.`,
      source: {
        id: SOURCE_MM,
        page: 238,
      },
    },
    {
      header: 'Рождённые молнией',
      text: `Ползающая насыпь — результат феномена, при котором молния или магия фей оживляет до того обычное болотное растение. Получив вторую жизнь, оно поглощает жизни растений и животных вокруг, удобряя их трупами свои корни. В конце концов эти корни отвергают почву, побуждая блуждающую насыпь к поиску новых источников еды.`,
      source: {
        id: SOURCE_MM,
        page: 238,
      },
    },
    {
      header: 'Сорняк, что ходит',
      text: `Инстинкт, движущий ползающей насыпью, находится в центре корневой системы, скрытый где-то внутри этой громадины. Остальная часть ползуна состоит из защищающего корень гниющего мусора, который он копит и поглощает. Из-за своей огромной массы ползающая насыпь не получает урон от обычного огня и холода, а молнии будут лишь ускорять её рост.

Несмотря на чудовищный облик, ползающая насыпь представляет собой живое растение, которое требует воздуха и пищи. Хотя она не спит, как животное, она может находиться в состоянии покоя в течение нескольких дней подряд, прежде чем перейти в фазу активности.`,
      source: {
        id: SOURCE_MM,
        page: 238,
      },
    },
    {
      header: 'Возрождающаяся угроза',
      text: `Если ползающая насыпь потерпит поражение от непреодолимого врага, корень может притвориться мёртвым, стряхивая остатки грязи. Если позже не убить корень, он врастёт в останки ползуна, медленно восстанавливая своё тело, после чего снова начнёт поглощать всё, что сможет. Таким образом, давно считавшаяся уничтоженной блуждающая насыпь будет подниматься снова и снова.`,
      source: {
        id: SOURCE_MM,
        page: 238,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 238,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 20,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 16,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
  ],
  immunityList: [
    DAMAGE_ELECTRICITY,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      name: `Поглощение электричества`,
      description: `Каждый раз, когда ★СУЩЕСТВО★ ★должен★ получить урон электричеством, ★он★ не получает урон, и вместо этого восстанавливает число хитов, равное причиняемому урону электричеством.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает два _Удара_. Если обе атаки попадают по цели с размером не больше Среднего, цель становится схваченной (СЛ высвобождения 14), а ★СУЩЕСТВО★ использует против неё _Поглощение_.`,
    },
    {
      name: `Удар`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Поглощение`,
      description: `★СУЩЕСТВО★ проглатывает схваченное существо с размером не больше Среднего. Проглоченное существо обездвижено, ослеплено, не может дышать, и должно преуспевать в испытании Телосложения СЛ 14 в начале каждого хода насыпи, иначе получает дробящий урон 13 (2к8+4). Если ★СУЩЕСТВО★ движется, проглоченная цель перемещается вместе с ★ним★. ★СУЩЕСТВО★ может одновременно иметь только одно проглоченное существо.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
