const {
  SPELL_CONFUSION,
  SPELL_FEEBLEMIND,
  SPELL_LEVITATE,
  SPELL_MIND_BLANK,
  SPELL_NONDETECTION,
  SPELL_TELEKINESIS,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_ILLITHID,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_ELDER_BRAIN,
  CREATURE_MIND_FLAYER,
  CREATURE_NEOTHELID,
} = require('./../../../../creatureIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_13} = require('./../../../../crList')
const {illithidDescriptionList} = require('./../../../../textCommonParts')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Неотелид',
  nameEn: 'Neothelid',
  id: CREATURE_NEOTHELID,
  description: [
    `Неотелид — это покрытый слизью червь невероятных размеров, который является результатом того, что цикл воспроизведения свежевателей разума пошёл совсем не так. В редких случаях колония [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}) рушится, обычно из-за внешнего нападения, а [старший мозг](CREATURE:${CREATURE_ELDER_BRAIN}) умирает. Когда это происходит, головастики колонии внезапно освобождаются от своей судьбы. Они больше не служат едой и их больше не кормят смотрители. Ведомые голодом, они начинают пожирать друг друга. Только один головастик из тысяч выживает в бассейне колонии и он становится неотелидом.`,
    {
      header: `Ненавистный иллитидам`,
      text: `Одно из самых строгих табу в сообществе [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}) — это идея позволить зрелому головастику выжить, не вживив его в мозг донора. При нормальных обстоятельствах любой головастик, который вырастает больше нескольких дюймов, убивается и поедается [старшим мозгом](CREATURE:${CREATURE_ELDER_BRAIN}) или менее зрелыми головастиками. Любой головастик, который пережил это состояние считает угрозой колонии и свежеватели разума создают группы охотников для уничтожения мерзости. У него не достает интеллекта для того, чтобы его заметил [старший мозг](CREATURE:${CREATURE_ELDER_BRAIN}) со способностью чувствовать мысли, так что неотелиды оправдывают такие предосторожности.`,
      source: {
        id: SOURCE_VGTM,
        page: 175,
      },
    },
    {
      header: `Дикое чудище`,
      text: `Будучи одичавшим, неотелид не знает ничего, кроме хищнического существования, которым жил до этого и пытается осознать свои новые псионические способности. Неотелиды рыскают по подземным проходам в поиске мозгов, чтобы утолить свой постоянный голод, и становятся всё более злобными. Эти существа могут выделять растворяющие ткани энзимы из каналов щупалец, превращая жертв в лужу слизи и оставляя в живых лишь пульсирующий мозг. Они не знают о своей связи с иллитидами, так что охотятся на них так же, как и на всех остальных.`,
      source: {
        id: SOURCE_VGTM,
        page: 175,
      },
    },
    ...illithidDescriptionList,
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_ILLITHID,
  ],
  alignmentId: ALIGNMENT_CE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 175,
    },
    {
      url: 'https://5e.tools/bestiary.html#neothelid_vgm',
      problemText: `В «Справочнике Воло по монстрам» группы переводов dungeonsanddragons.ru у «Неотелида» неверно указаны необходимые ему компоненты для заклинаний — «без материальных компонентов», вместо «не нужны никакие компоненты».`,
    },
  ],
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 21,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 27,
    [PARAM_DEX]: 7,
    [PARAM_CON]: 21,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
    },
  ],
  cr: CR_13,
  featureList: [
    {
      name: 'Чутьё существ',
      description: `Неотелид знает о присутствии существ в радиусе 1 миль от себя, чей Интеллект 4 или выше. Он знает расстояние и направление до каждого существа, а так же значение параметра Интеллект, но больше ничего не может почувствовать. Существо, защищенное заклинанием [Сокрытие разума](SPELL:${SPELL_MIND_BLANK}), [Необнаружимость](SPELL:${SPELL_NONDETECTION}) или похожей магией, он почувствовать не может.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_WIT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_LEVITATE,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONFUSION,
          SPELL_FEEBLEMIND,
          SPELL_TELEKINESIS,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Щупальца',
      description: `Если цель является Большим существом или меньше, то она должна пройти испытание Силы СЛ 18 или неотелид её проглотит. Проглоченное существо ослеплено и обездвижено, имеет полное укрытие от всех атак и других эффектов снаружи неотелида, и получает 35 (10к6) урона кислотой в начале каждого хода неотелида.

Если неотелид получает 30 урона или больше в один ход от существа внутри него, то он должен пройти испытание Телосложения СЛ 18 в конце этого хода или отрыгнуть всех проглоченных существ, которые падают сбитыми с ног в месте не далее 10 футов от неотелида. Если неотелид умирает, то проглоченные существа больше не обездвижены им и могут выбраться из тела, потратив 20 футов движения, и падая ничком на выходе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 15,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 3,
            diceType: 8,
            diceBonus: 8,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 3,
            diceType: 8,
          },
        ],
      },
    },
    {
      name: 'Кислотное дыхание',
      restore: 5,
      description: `Неотелид выдыхает кислоту 60-футовым конусом. Каждое существо в этой области должно пройти испытание Ловкости СЛ 18 и получить 35 (10к6) урона кислотой при провале или половину при успехе.`,
    },
  ],
}
