const {
    SOURCE_SKT,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_5,
  } = require('./../../../../crList'),
  {
    SIZE_HUGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BEAST,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_SWIM,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_AMPHIBIOUS,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_GIANT_CRAB,
    CREATURE_HULKING_CRAB,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Огромный краб',
  nameAlt: 'Гигантский краб',
  nameEn: 'Hulking Crab',
  id: CREATURE_HULKING_CRAB,
  description: `Превосходя в размерах [гигантского краба](CREATURE:${CREATURE_GIANT_CRAB}), Огромный краб обладает телом в 15–20 футов в диаметре.

Его панцирь часто покрыт кораллами, анемонами, обломками кораблей или других вещей, попавших или обитающих на дне океана.`,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_SKT,
    page: 244,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 16,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Камуфлирующий панцирь',
      description: `Пока краб не двигается, а его клешни и глаза располагаются близко к телу, он выглядит как естественное образование или куча обломков скал. Существо, подошедшее на 30 футов к крабу, может различить истинную природу краба успешной проверкой Интеллекта (Природа) СЛ 15.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: ` Краб делает две атаки _Клешнёй_.`,
    },
    {
      name: 'Клешня',
      description: `Цель Схвачена СЛ 15. У краба есть две клешни, в каждой из которых он может держать по одной цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
  ],
}
