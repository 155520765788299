const {CAT_MUSIC_TOOLS} = require('./../../../../../gearCategoryList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_INSTRUMENT_OF_THE_BARDS} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const commonSpellIdList = require('./commonSpellIdList')
const generateSpellList = require('./generateSpellList')
const list = require('./list')
const siblingIdList = require('./siblingIdList')

const spellList = list
  .sort(
    ({name: A}, {name: B}) => A > B
      ? 1
      : -1
  )
  .reduce(
    (text,{id, name, spellIdList}) => `${text}
| [${name}](MAGIC_ITEM:${id}) | ${generateSpellList(spellIdList)} |`,
    `
| Инструмент | Заклинания |
|------------|------------|
| Все        | ${generateSpellList(commonSpellIdList)} |`
  )

const spellFullIdList = list.reduce(
  (list,{spellIdList}) => [
    ...list,
    ...spellIdList,
  ],
  commonSpellIdList
)

module.exports = {
  id: MAGIC_ITEM_INSTRUMENT_OF_THE_BARDS,
  name: 'Инструмент бардов',
  nameEn: 'Instrument of the Bards',
  rarity: generateRarityList(list),
  gearCategory: CAT_MUSIC_TOOLS,
  type: MGC_TYPE_WONDROUS_ITEM,
  attunement: true,
  attunementComment: `бардом`,
  description: `_Инструмент бардов_ — это прекрасный образец музыкального инструмента, во всём превосходящий обычные аналоги. Есть семь разновидностей таких инструментов, каждый назван в честь одной из легендарных коллегий бардов. Существо, пытающееся играть на _Инструменте_, не будучи настроенным на него, должно пройти испытание Мудрости СЛ 15, иначе получит урон психической энергией 2к4.

Вы можете действием сыграть на _Инструменте_ и наложить одно из его заклинаний. После того как _Инструмент бардов_ использован для наложения заклинания, он не может повторно сотворять это заклинание до следующего рассвета. Заклинания используют Вашу базовую характеристику и СЛ испытаний от Ваших заклинаний.

Если Вы использовали _Инструмент_ для накладывания заклинания, которое делает цель очарованной при провале испытания, цель проходит это испытание с помехой. Этот эффект применяется вне зависимости от того, использовали ли Вы _Инструмент_ в качестве источника заклинания или в качестве заклинательной фокусировки.

# Заклинания

${spellList}`,
  spellIdList: spellFullIdList,
  genderId: GENDER_FEMALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 167,
  },
}
