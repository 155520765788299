const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SKILL_ATHLETICS,
  SKILL_INTIMIDATION,
} = require('./../../../../skillList')
const {
  GEAR_SHIELD,
  GEAR_STUDDED_LEATHER_ARMOR,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  ABILITY_BRAVE,
  ABILITY_PARRY,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_GLADIATOR} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Гладиатор',
  nameEn: 'Gladiator',
  id: CREATURE_GLADIATOR,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 344,
  },
  armor: {
    ac: 16,
    gearId: [GEAR_STUDDED_LEATHER_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_DOUBLE,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_BRAVE,
    {
      name: 'Жестокость',
      description: `Рукопашное оружие причиняет одну дополнительную кость своего урона, когда ★СУЩЕСТВО★ попадает им (уже включено в атаку).`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три рукопашные атаки или две дальнобойные атаки.`,
    },
    {
      name: 'Копьё',
      // gearId: GEAR_SPEAR, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 6,
              diceBonus: 4,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 4,
              use2Hand: true,
            },
          ],
        ],
      },
    },
    {
      name: 'Копьё',
      // gearId: GEAR_SPEAR, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 7,
        range: {
          normal: 20,
          max: 60,
        },
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Удар щитом',
      description: `Если цель — существо с размером не больше Среднего, она должна пройти испытание Силы СЛ 15, иначе будет сбита с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 3,
    },
  ],
  genderId: GENDER_MALE,
}
