const {SOURCE_WDH} = require('./../../sourceList')
const {
  CREATURE_GRIFFON,
  CREATURE_GRIFFON_CAVALRY_RIDER,
  CREATURE_WATERDEEP_S_CITY_GUARD_ARMAR,
  CREATURE_WATERDEEP_S_CITY_GUARD_CIVILAR,
  CREATURE_WATERDEEP_S_CITY_GUARD_PRIVATE,
  CREATURE_WATERDEEP_S_CITY_GUARD_SENIOR_CIVILAR,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Городская гвардия',
    text: `Городская гвардия — это армия Глубоководья. Её задача — защищать стены и ворота города, правительственные здания, гавань и чиновников. Гвардия также патрулирует дороги на Амфел, Золотые поля и Даггерфорд.`,
    source: {
      id: SOURCE_WDH,
      page: 197,
    },
  },
  {
    header: 'Звания городской гвардии',
    text: `У бойцов городской гвардии следующие звания, от низшего к высшему:

* [Рядовой](CREATURE:${CREATURE_WATERDEEP_S_CITY_GUARD_PRIVATE})
* [Сержант (армар)](CREATURE:${CREATURE_WATERDEEP_S_CITY_GUARD_ARMAR})
* [Лейтенант (цивилар)](CREATURE:${CREATURE_WATERDEEP_S_CITY_GUARD_CIVILAR})
* [Капитан (старший цивилар)](CREATURE:${CREATURE_WATERDEEP_S_CITY_GUARD_SENIOR_CIVILAR})
* Множество руководящих должностей, некоторые многолетние (сенешаль Замка Глубоководья, защитник порта, начальник северных башен, начальник южных башен, главный оружейник), некоторые заполняются только в военное время (десница лордов и чемпион лордов)
* Хранитель Глубоководья

В данный момент хранитель Глубоководья — Эльминстер, который подчиняется Явному лорду Лейраль Среброрукой.`,
    source: {
      id: SOURCE_WDH,
      page: 197,
    },
  },
  {
    header: 'Кавалерия грифонов',
    text: `[Кавалерия грифонов](CREATURE:${CREATURE_GRIFFON_CAVALRY_RIDER}) — особая ветвь городской гвардии, члены которой — солдаты-ветераны, обученные летать верхом на [грифонах](CREATURE:${CREATURE_GRIFFON}).`,
    source: {
      id: SOURCE_WDH,
      page: 197,
    },
  },
]
