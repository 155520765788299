const {CAT_SIMPLE_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_BLUDGEONING} = require('./../../../damageTypeList')
const {GEAR_CLUB} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {WEAPON_LIGHT} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_CLUB,
  genderId: GENDER_FEMALE,
  name: 'Дубинка',
  nameByCase: {
    nominative: 'дубинка',
    genitive: 'дубинки',
    accusative: 'дубинку',
    instrumental: 'дубинкой',
  },
  nameEn: 'Club',
  damage: {
    diceType: 4,
    diceCount: 1,
  },
  cost: 10,
  damageType: DAMAGE_BLUDGEONING,
  weight: 2,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    WEAPON_LIGHT,
  ],
  category: CAT_SIMPLE_MELEE_WEAPON,
}
