const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SHADOW_MASTIFF,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  DAMAGE_PIERCING,
  DAMAGE_WEAPON_DARK_NONMAGIC,
} = require('./../../../../damageTypeList')
const {
  ABILITY_ETHEREAL_AWARENESS,
  ABILITY_KEEN_HEARING_AND_SMELL,
  ABILITY_SHADOW_BLEND,
  ABILITY_SUNLIGHT_WEAKNESS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_SHADOW_MASTIFF,
  CREATURE_SHADOW_MASTIFF_ALPHA,
} = require('./../../../../creatureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {GOD_SHAR} = require('./../../../../godIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Теневой мастиф',
  nameEn: 'Shadow Mastiff',
  id: CREATURE_SHADOW_MASTIFF,
  description: [
    {
      header: 'Теневой мастиф',
      text: `Эти [чёрные псы](CREATURE:${CREATURE_SHADOW_MASTIFF}) из Царства Теней двигаются невидимо по теням и всегда охотятся. В мрачных местах, где завеса между Царством Теней и Материальным планом тонка, они могут пересекать её и попадать в тёмные уголки мира.`,
      source: {
        id: SOURCE_VGTM,
        page: 195,
      },
    },
    {
      header: 'Теневой мастиф',
      text: `Зачастую передвигаясь стаями, гончие Царства Теней ныряют з тени в тень, постоянно выслуживая дичь.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: 'Хищные соглядатаи',
      text: `Теневые мастифы охотятся стаями по Царству Теней, и когда один из них входит в разлом между планами, ещё несколько всегда последуют за ним. В каждой стае главенствует [альфа](CREATURE:${CREATURE_SHADOW_MASTIFF_ALPHA}) (самец или самка), самый умный и сильный в отряде. Альфа должен оставаться в форме, чтобы держать остальную стаю в подчинении, чтобы его не убили и не сменили.

Когда стая теневых мастифов голодна и чувствует добычу неподалёку, то альфа завывает так, что страх вселяется в сердца ближайших зверей и гуманоидов. Этот вой также является сигналом остальной стае выдвигаться и убивать. Мрак дает теневому мастифу сверхъестественную защиту, что дает ему сопротивление немагическому оружию, если он находится в тусклом свете или темноте. Теневые мастифы могут терпеть яркий свет, но избегают солнечного света.`,
      source: {
        id: SOURCE_VGTM,
        page: 195,
      },
    },
    {
      header: 'Призванные на службу',
      text: `Некоторые религии, посвященные божествам мрака и тьмы, таким как [Шар](GOD:${GOD_SHAR}) в Забытых Королевствах, совершают нечестивые обряды для призыва теневых мастифов из Царства Теней и назначают их стражами, телохранителями и карателями неверных, еретиков и вероотступников. Методы приведения теневых мастифов в мир также известны и другим индивидам с сильной волей и злым разумом, которые находят применение псам как стражам в своих цитаделях.`,
      source: {
        id: SOURCE_VGTM,
        page: 195,
      },
    },
    {
      header: 'Эфирное зрение',
      text: `В дополнение к своим другим способностям, теневой мастиф может видеть существ и объекты на Эфирном Плане. Экстрапланарное восприятие делает мастифа особенно даровитым стражем, особенно в ситуациях, когда возможно магическое или духовное вторжение.`,
      source: {
        id: SOURCE_VGTM,
        page: 195,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SHADOW_MASTIFF,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 195,
  },
  armor: 12,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_WEAPON_DARK_NONMAGIC,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_KEEN_HEARING_AND_SMELL,
    ABILITY_SUNLIGHT_WEAKNESS,
    ABILITY_ETHEREAL_AWARENESS,
    ABILITY_SHADOW_BLEND,
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Если цель — существо, оно должно пройти испытание Силы СЛ 13 или ★СУЩЕСТВО★ Собьёт его с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
}
