const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  GEAR_LONGSWORD,
  GEAR_MILITARY_SADDLE,
  GEAR_QUARTERSTAFF,
  GEAR_SCIMITAR,
  GEAR_SHIELD,
  GEAR_STAFF,
} = require('./../../../../../gearIdList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ARCANE_LOCK,
  SPELL_AWAKEN,
  SPELL_BANISHMENT,
  SPELL_BARKSKIN,
  SPELL_BURNING_HANDS,
  SPELL_CHARM_PERSON,
  SPELL_COMMAND,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_CONE_OF_COLD,
  SPELL_CONJURE_ELEMENTAL,
  SPELL_CURE_WOUNDS,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_ENLARGE_REDUCE,
  SPELL_FIREBALL,
  SPELL_FLAMING_SPHERE,
  SPELL_FOG_CLOUD,
  SPELL_GIANT_INSECT,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_HOLD_MONSTER,
  SPELL_ICE_STORM,
  SPELL_INSECT_PLAGUE,
  SPELL_INVISIBILITY,
  SPELL_KNOCK,
  SPELL_LESSER_RESTORATION,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_LIGHTNING_BOLT,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_PASSWALL,
  SPELL_PLANE_SHIFT,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_REMOVE_CURSE,
  SPELL_SENDING,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_TELEKINESIS,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_FORCE,
  SPELL_WALL_OF_ICE,
  SPELL_WALL_OF_THORNS,
  SPELL_WEB,
} = require('./../../../../../spellIdList')
const {
  CREATURE_EARTH_ELEMENTAL,
  CREATURE_GIANT_CONSTRICTOR_SNAKE,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_ARMOR,
  MGC_TYPE_STAFF,
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_SADDLE_OF_THE_CAVALIER,
  MAGIC_ITEM_SCARAB_OF_PROTECTION,
  MAGIC_ITEM_SCIMITAR_OF_SPEED,
  MAGIC_ITEM_SENDING_STONES,
  MAGIC_ITEM_SENTINEL_SHIELD,
  MAGIC_ITEM_SHIELD_OF_MISSILE_ATTRACTION,
  MAGIC_ITEM_SLIPPERS_OF_SPIDER_CLIMBING,
  MAGIC_ITEM_SPELLGUARD_SHIELD,
  MAGIC_ITEM_STAFF_OF_CHARMING,
  MAGIC_ITEM_STAFF_OF_FIRE,
  MAGIC_ITEM_STAFF_OF_FROST,
  MAGIC_ITEM_STAFF_OF_HEALING,
  MAGIC_ITEM_STAFF_OF_POWER,
  MAGIC_ITEM_STAFF_OF_STRIKING,
  MAGIC_ITEM_STAFF_OF_SWARMING_INSECTS,
  MAGIC_ITEM_STAFF_OF_THE_ADDER,
  MAGIC_ITEM_STAFF_OF_THE_MAGI,
  MAGIC_ITEM_STAFF_OF_THE_PYTHON,
  MAGIC_ITEM_STAFF_OF_THE_WOODLANDS,
  MAGIC_ITEM_STAFF_OF_THUNDER_AND_LIGHTNING,
  MAGIC_ITEM_STAFF_OF_WITHERING,
  MAGIC_ITEM_STONE_OF_CONTROLLING_EARTH_ELEMENTALS,
  MAGIC_ITEM_STONE_OF_GOOD_LUCK,
  MAGIC_ITEM_SUN_BLADE,
  MAGIC_ITEM_SWORD_OF_LIFE_STEALING,
  MAGIC_ITEM_SWORD_OF_SHARPNESS,
  MAGIC_ITEM_SWORD_OF_VENGEANCE,
  MAGIC_ITEM_SWORD_OF_WOUNDING,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_SENTINEL_SHIELD,
    name: `Щит часового`,
    nameEn: `Sentinel Shield`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_SHIELD,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Щит украшен символом глаза.

Пока Вы держите _Щит часового_, Вы получаете преимущество к проверкам инициативы и проверкам Мудрости (Внимательность).`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 214,
    },
  },
  {
    id: MAGIC_ITEM_SHIELD_OF_MISSILE_ATTRACTION,
    name: `Щит притягивания снарядов`,
    nameEn: `Shield of Missile Attraction`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_SHIELD,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Вы получаете сопротивление к урону от дальнобойных атак оружием, пока держите этот щит.`,
    curse: `Этот щит проклят. Если Вы настраиваетесь на него, Вы становитесь проклятым, пока не станете целью заклинания [снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) или другой подобной магии. Простое снятие щита не оканчивает проклятье на Вас. Каждый раз, когда по цели, находящейся в пределах 10 футов от Вас, совершается дальнобойная атака оружием, проклятье заставляет стать целью Вас.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 214,
    },
  },
  {
    id: MAGIC_ITEM_SPELLGUARD_SHIELD,
    name: `Щит от заклинаний`,
    nameEn: `Spellguard Shield`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_SHIELD,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Пока Вы держите этот щит, Вы получаете преимущество к испытаниям от заклинаний и других
магических эффектов, и броски атаки заклинаниями совершаются по Вам с помехой.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 214,
    },
  },
  {
    id: MAGIC_ITEM_SLIPPERS_OF_SPIDER_CLIMBING,
    name: `Туфли паука`,
    nameEn: `Slippers of Spider Climbing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите эту лёгкую обувь, Вы можете перемещаться по вертикальным поверхностям, а также вверх ногами по потолку, оставляя руки свободными.

Вы получаете скорость лазания, равную скорости хождения.

Однако туфли не позволяют перемещаться так по скользкой поверхности, например, покрытой маслом или льдом.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 210,
    },
  },
  {
    id: MAGIC_ITEM_SUN_BLADE,
    name: `Солнечный клинок`,
    nameEn: `Sun Blade`,
    type: MGC_TYPE_WEAPON,
    gearType: GEAR_LONGSWORD,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Этот предмет выглядит как рукоять [длинного меча](GEAR:${GEAR_LONGSWORD}). Держа эту рукоятку, Вы можете бонусным действием заставить появиться или исчезнуть клинок из чистого сияния. Пока клинок существует, этот магический длинный меч обладает свойством «фехтовальное». Если Вы владеете обращением с короткими или длинными клинками, то Вы владеете и обращением с солнечным клинком.

Вы получаете бонус +2 к броскам атаки и урона, совершённым этим оружием, и причиняете не рубящий урон, а урон излучением. Если Вы попадаете им по нежити, цель получает дополнительный урон излучением 1к8.

Клинок этого меча испускает яркий свет в радиусе 15 футов и тусклый свет в радиусе ещё 15 футов. Это солнечный свет. Пока клинок существует, Вы можете действием увеличить или уменьшить радиус и яркого и тусклого света на 5 футов каждый, с максимумом 30 футов и минимумом 10 футов для каждого.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 202,
    },
  },
  {
    id: MAGIC_ITEM_SCIMITAR_OF_SPEED,
    name: `Скимитар скорости`,
    nameEn: `Scimitar of Speed`,
    type: MGC_TYPE_WEAPON,
    gearType: GEAR_SCIMITAR,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Вы получаете бонус +2 к броскам атаки и урона, совершённым этим магическим оружием. Кроме того, в каждом своём ходу Вы можете совершить бонусным действием одну атаку им.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 201,
    },
  },
  {
    id: MAGIC_ITEM_SCARAB_OF_PROTECTION,
    name: `Скарабей защиты`,
    nameEn: `Scarab of Protection`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    isConsumable: true,
    description: `Если Вы держите в своей руке этот медальон в форме жука в течение 1 раунда, на его поверхности появятся письмена, открывающие его магическую сущность. Он предоставляет два преимущества, пока находится у Вас:

* Вы получаете преимущество к испытаниям от заклинаний.
* У скарабея есть 12 зарядов. Если Вы проваливаете испытание от заклинания школы Некромантии или вредоносного эффекта, исходящего от нежити, Вы можете реакцией потратить 1 заряд, чтобы превратить проваленное испытание в успешное. Скарабей рассыпается в порошок и уничтожается, когда тратится его последний заряд.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 201,
    },
  },
  {
    id: MAGIC_ITEM_SADDLE_OF_THE_CAVALIER,
    name: `Седло кавалериста`,
    nameEn: `Saddle of the Cavalier`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_MILITARY_SADDLE,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Если Вы находитесь в этом [седле](GEAR:${GEAR_MILITARY_SADDLE}) на ездовом животном, и Вы в сознании, Вы не можете быть спешены против Вашей воли, и броски атаки по Вашему ездовому животному совершаются с помехой.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 201,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_STRIKING,
    name: `Посох ударов`,
    nameEn: `Staff of Striking`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_QUARTERSTAFF,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Этот посох можно использовать как магический [боевой посох](GEAR:${GEAR_QUARTERSTAFF}), предоставляющий бонус +3 к броскам атаки и урона им.

У этого посоха есть 10 зарядов. Если Вы попадаете им рукопашной атакой, Вы можете потратить до 3 зарядов. За каждый потраченный заряд цель получает дополнительный урон силовым полем 1к6.

Посох ежедневно восстанавливает 1к6 + 4 заряда на рассвете. Если Вы истратили последний заряд, бросьте к20. Если выпадет «1», посох становится немагическим боевым посохом.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 197,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_POWER,
    name: `Посох силы`,
    nameAlt: `Посох могущества`,
    nameEn: `Staff of Power`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_QUARTERSTAFF,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    attunementComment: `волшебником, колдуном или чародеем`,
    description: `Этот посох можно использовать как магический [Боевой посох](GEAR:${GEAR_QUARTERSTAFF}), предоставляющий бонус +3 к броскам атаки и урона им. Держа его, Вы получаете бонус +2 к КД, испытаниям и броскам атаки заклинаниями.

У посоха есть 20 зарядов для описанных ниже свойств. Он ежедневно восстанавливает 2к8 + 4 заряда на рассвете. Если Вы истратили последний заряд, бросьте к20. Если выпадет «1», посох сохраняет бонус +2 к броскам атаки и урона, но теряет все остальные свойства. Если выпадет «20», посох восстанавливает 1к8 + 2 заряда.

# Силовой удар

Если Вы попадаете рукопашной атакой этим посохом, Вы можете потратить 1 заряд, чтобы причинить цели дополнительный урон силовым полем 1к6.

# Заклинания

Если Вы держите этот посох, Вы можете действием потратить часть зарядов, чтобы наложить им одно из следующих заклинаний, используя свою СЛ испытания от заклинания и бонус броска атаки заклинанием:

* [Волшебная стрела](SPELL:${SPELL_MAGIC_MISSILE}) (1 заряд), 
* [Конус холода](SPELL:${SPELL_CONE_OF_COLD}) (5 зарядов), 
* [Левитация](SPELL:${SPELL_LEVITATE}) (2 заряда), 
* [Луч слабости](SPELL:${SPELL_RAY_OF_ENFEEBLEMENT}) (1 заряд), 
* [Молния](SPELL:${SPELL_LIGHTNING_BOLT}) (версия 5 уровня, 5 зарядов), 
* [Огненный шар](SPELL:${SPELL_FIREBALL}) (версия 5 уровня, 5 зарядов), 
* [Силовая стена](SPELL:${SPELL_WALL_OF_FORCE}) (5 зарядов), 
* [Сфера неуязвимости](SPELL:${SPELL_GLOBE_OF_INVULNERABILITY}) (6 зарядов), 
* [Удержание чудовища](SPELL:${SPELL_HOLD_MONSTER}) (5 зарядов).

# Карающий удар

Вы можете действием сломать посох о колено или твёрдую поверхность, совершая, таким образом, карающий удар. Посох уничтожается, испуская оставшуюся в нём магию взрывом, заполняющим сферу радиусом 30 футов с центром на нём.

У Вас есть 50-процентный шанс мгновенно перенестись на случайный план существования, избежав взрыва. Если Вы его не избегаете, то получаете урон силовым полем, равный 16 × количество зарядов в посохе. Все другие существа в области взрыва должны пройти испытание Ловкости СЛ 17. При провале существо получает урон, зависящий от того, как далеко оно находится от исходной точки взрыва, как показано в таблице. При успехе существо получает половину этого урона.

| Расстояние от исходной точки | Урон                            |
|------------------------------|---------------------------------|
| 10 футов или ближе             | 8 × количество зарядов в посохе |
| от 11 до 20 футов              | 6 × количество зарядов в посохе |
| от 21 до 30 футов              | 4 × количество зарядов в посохе |
`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_CONE_OF_COLD,
      SPELL_FIREBALL,
      SPELL_GLOBE_OF_INVULNERABILITY,
      SPELL_HOLD_MONSTER,
      SPELL_LEVITATE,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGIC_MISSILE,
      SPELL_RAY_OF_ENFEEBLEMENT,
      SPELL_WALL_OF_FORCE,
    ],
    source: {
      id: SOURCE_DMG,
      page: 196,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_SWARMING_INSECTS,
    name: `Посох роя насекомых`,
    nameEn: `Staff of Swarming Insects`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_STAFF,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `бардом, волшебником, друидом, жрецом, колдуном или чародеем`,
    description: `У этого посоха 10 зарядов, и он ежедневно восстанавливает 1к6 + 4 заряда на рассвете. Если Вы истратили последний заряд, бросьте к20. Если выпадет «1», рой насекомых пожирает посох, уничтожая его, и рассеивается.

# Заклинания

Если Вы держите этот посох, Вы можете действием потратить часть зарядов, чтобы наложить им одно из следующих заклинаний, используя свою СЛ испытания от заклинания:
 
* [Гигантское насекомое](SPELL:${SPELL_GIANT_INSECT}) (4 заряда),
* [Нашествие насекомых](SPELL:${SPELL_INSECT_PLAGUE}) (5 зарядов).

# Облако насекомых

Если Вы держите посох, Вы можете действием потратить 1 заряд, чтобы окружить себя роем летучих насекомых с 30-футовым радиусом. Насекомые остаются на 10 минут, делая эту область сильно заслонённой для всех существ кроме Вас. Рой перемещается с Вами, оставаясь с центром на Вас. Ветер со скоростью как минимум 10 миль в час (16 километров в час) рассеивает рой и оканчивает этот эффект.`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_GIANT_INSECT,
      SPELL_INSECT_PLAGUE,
    ],
    source: {
      id: SOURCE_DMG,
      page: 196,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THE_PYTHON,
    name: `Посох питона`,
    nameEn: `Staff of the Python`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_STAFF,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    attunementComment: `друидом, жрецом ил колдуном`,
    description: `Вы можете действием произнести командное слово и бросить посох на пол в пределах 10 футов от себя. Посох становится [Гигантским удавом](CREATURE:${CREATURE_GIANT_CONSTRICTOR_SNAKE}) под Вашим контролем и у него есть своё место в порядке инициативы. Произнеся бонусным действием командное слово ещё раз, Вы возвращаете посоху его естественный облик, и он будет лежать в пространстве, ранее занимаемом удавом.

В свой ход Вы можете мысленно отдавать команды удаву, если он находится в пределах 60 футов от Вас и дееспособен. Вы определяете, какие действия ему совершать и куда перемещаться в следующем ходу, или же можете отдать общую команду, такую как _«нападай на врагов»_ или _«охраняй место»_.

Если хиты змеи опустятся до 0, она умирает и становится посохом. Посох при этом раскалывается на куски и уничтожается. Если змея становится посохом до того как потеряет все хиты, она их тут же восстанавливает.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 196,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_CHARMING,
    name: `Посох очарования`,
    nameEn: `Staff of Charming`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_QUARTERSTAFF,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `бардом, волшебником, друидом, жрецом, колдуном или чародеем`,
    description: `Держа этот посох, Вы можете действием потратить 1 из его 10 зарядов на то, чтобы наложить им следующие заклинания, используя свою СЛ испытания от заклинаний:

* [Очарование личности](SPELL:${SPELL_CHARM_PERSON}),
* [Понимание языков](SPELL:${SPELL_COMPREHEND_LANGUAGES}),
* [Приказ](SPELL:${SPELL_COMMAND}).

Этот посох также может использоваться как магический [боевой посох](GEAR:${GEAR_QUARTERSTAFF}).

Если Вы держите этот посох и проваливаете испытание от заклинания школы Очарования, нацеленного только на Вас, Вы можете превратить проваленное испытание в успешное. Вы не можете использовать это свойство повторно до следующего рассвета. Если Вы преуспели в испытании от заклинания, нацеленного только на Вас, вне зависимости от того, пришлось ли для этого прибегнуть к помощи посоха, Вы можете реакцией потратить 1 заряд из посоха и отразить заклинание в того, кто его наложил, как если бы Вы сами наложили это заклинание.

Посох ежедневно восстанавливает 1к8 + 2 заряда на рассвете. Если Вы истратили последний заряд, бросьте к20. Если выпадет «1», посох становится немагическим боевым посохом.`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_CHARM_PERSON,
      SPELL_COMPREHEND_LANGUAGES,
      SPELL_COMMAND,
    ],
    source: {
      id: SOURCE_DMG,
      page: 196,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_FIRE,
    name: `Посох огня`,
    nameEn: `Staff of Fire`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_STAFF,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    attunementComment: `волшебником, друидом, колдуном или чародеем`,
    description: `Вы получаете сопротивление к урону огнём, пока держите этот посох.

У этого посоха есть 10 зарядов. Держа его, Вы можете действием потратить часть зарядов и наложить им одно из следующих заклинаний, используя свою СЛ испытания от заклинаний:

* [Огненная стена](SPELL:${SPELL_WALL_OF_FIRE}) (4 заряда),
* [Огненные ладони](SPELL:${SPELL_BURNING_HANDS}) (1 заряд),
* [Огненный шар](SPELL:${SPELL_FIREBALL}) (3 заряда).

Посох ежедневно восстанавливает 1к6 + 4 заряда на рассвете. Если Вы истратили последний заряд, бросьте к20. Если выпадет «1», посох чернеет, разваливается на угли и уничтожается. `,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_WALL_OF_FIRE,
      SPELL_BURNING_HANDS,
      SPELL_FIREBALL,
    ],
    source: {
      id: SOURCE_DMG,
      page: 196,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_FROST,
    name: `Посох мороза`,
    nameEn: `Staff of Frost`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_STAFF,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    attunementComment: `волшебником, друидом, колдуном или чародеем`,
    description: `Вы получаете сопротивление к урону холодом, пока держите этот посох.

У этого посоха есть 10 зарядов. Держа его, Вы можете действием потратить часть зарядов и наложить им одно из следующих заклинаний, используя свою СЛ испытания от заклинаний:

* [Град](SPELL:${SPELL_ICE_STORM}) (4 заряда),
* [Конус холода](SPELL:${SPELL_CONE_OF_COLD}) (5 зарядов),
* [Ледяная стена](SPELL:${SPELL_WALL_OF_ICE}) (4 заряда),
* [Туманное облако](SPELL:${SPELL_FOG_CLOUD}) (1 заряд).

Посох ежедневно восстанавливает 1к6 + 4 заряда на рассвете. Если Вы истратили последний заряд, бросьте к20. Если выпадет «1», посох превращается в воду и уничтожается.`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_ICE_STORM,
      SPELL_CONE_OF_COLD,
      SPELL_WALL_OF_ICE,
      SPELL_FOG_CLOUD,
    ],
    source: {
      id: SOURCE_DMG,
      page: 195,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THE_MAGI,
    name: `Посох магов`,
    nameEn: `Staff of the Magi`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_QUARTERSTAFF,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    attunementComment: `волшебником, колдуном или чародеем`,
    description: `Этот посох можно использовать как магический [боевой посох](GEAR:${GEAR_QUARTERSTAFF}), предоставляющий бонус +2 к броскам атаки и урона им. Держа его, Вы получаете бонус +2 к броскам атаки заклинаниями.

У посоха есть 50 зарядов для описанных ниже свойств. Он ежедневно восстанавливает 4к6 + 2 заряда на рассвете. Если Вы истратили последний заряд, бросьте к20. Если выпадет «20», посох восстановит 1к12 + 1 заряд.

# Впитывание заклинаний

Пока Вы держите посох, Вы проходите испытания от заклинаний с преимуществом.

Кроме того, Вы можете реакцией, когда другое существо сотворяет заклинание, целью которого являетесь только Вы, впитать посохом магию этого заклинания, отменяя его эффект и восстанавливая количество зарядов, равное уровню поглощённого заклинания. Однако если из-за этого сумма зарядов в посохе превысит 50, посох взорвётся, как если бы Вы активировали его _«Карающий удар»_.

# Заклинания

Если Вы держите этот посох, Вы можете действием потратить часть зарядов, чтобы наложить им одно из следующих заклинаний (СЛ испытания 17):

* [Волшебная рука](SPELL:${SPELL_MAGE_HAND}) (не тратя зарядов),
* [Волшебный замок](SPELL:${SPELL_ARCANE_LOCK}) (не тратя зарядов),
* [Град](SPELL:${SPELL_ICE_STORM}) (4 заряда),
* [Защита от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}) (не тратя зарядов),
* [Молния](SPELL:${SPELL_LIGHTNING_BOLT}) (версия 7 уровня, 7 зарядов),
* [Невидимость](SPELL:${SPELL_INVISIBILITY}) (2 заряда), 
* [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}) (не тратя зарядов),
* [Огненная стена](SPELL:${SPELL_WALL_OF_FIRE}) (4 заряда), 
* [Огненный шар](SPELL:${SPELL_FIREBALL}) (версия 7 уровня, 7 зарядов), 
* [Открывание](SPELL:${SPELL_KNOCK}) (2 заряда), 
* [Паутина](SPELL:${SPELL_WEB}) (2 заряда), 
* [Призыв элементаля](SPELL:${SPELL_CONJURE_ELEMENTAL}) (7 зарядов), 
* [Пылающий шар](SPELL:${SPELL_FLAMING_SPHERE}) (2 заряда), 
* [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) (3 заряда), 
* [Свет](SPELL:${SPELL_LIGHT}) (не тратя зарядов),
* [Создание прохода](SPELL:${SPELL_PASSWALL}) (5 зарядов), 
* [Телекинез](SPELL:${SPELL_TELEKINESIS}) (5 зарядов),
* [Увеличение/Уменьшение](SPELL:${SPELL_ENLARGE_REDUCE}) (не тратя зарядов),
* [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}) (7 зарядов).

# Карающий удар

Вы можете действием сломать посох о колено или твёрдую поверхность, совершая, таким образом, карающий удар. Посох уничтожается, испуская оставшуюся в нём магию взрывом, заполняющим сферу радиусом 30 футов с центром на нём.

У Вас есть 50-процентный шанс мгновенно перенестись на случайный план существования, избежав взрыва. Если Вы его не избегаете, то получаете урон силовым полем, равный 16 × количество зарядов в посохе. Все другие существа в области взрыва должны пройти испытание Ловкости СЛ 17. При провале существо получает урон, зависящий от того, как далеко оно находится от исходной точки взрыва, как показано в таблице. При успехе существо получает половину этого урона.

| Расстояние от исходной точки | Урон                            |
|------------------------------|---------------------------------|
| 10 футов или ближе           | 8 × количество зарядов в посохе |
| от 11 до 20 футов            | 6 × количество зарядов в посохе |
| от 21 до 30 футов            | 4 × количество зарядов в посохе |
`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_ARCANE_LOCK,
      SPELL_CONJURE_ELEMENTAL,
      SPELL_DETECT_MAGIC,
      SPELL_DISPEL_MAGIC,
      SPELL_ENLARGE_REDUCE,
      SPELL_FIREBALL,
      SPELL_FLAMING_SPHERE,
      SPELL_ICE_STORM,
      SPELL_INVISIBILITY,
      SPELL_KNOCK,
      SPELL_LIGHT,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGE_HAND,
      SPELL_PASSWALL,
      SPELL_PLANE_SHIFT,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_TELEKINESIS,
      SPELL_WALL_OF_FIRE,
      SPELL_WEB,
    ],
    source: {
      id: SOURCE_DMG,
      page: 194,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_HEALING,
    name: `Посох лечения`,
    nameEn: `Staff of Healing`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_STAFF,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `бардом, друидом или жрецом`,
    description: `У этого посоха есть 10 зарядов. Держа его, Вы можете действием потратить часть зарядов и наложить им одно из следующих заклинаний, используя свою СЛ испытания от заклинания и базовую характеристику:

* [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}) (1 заряд за каждый круг заклинания, максимум 4 уровень),
* [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}) (2 заряда)
* [Множественное лечение ран](SPELL:${SPELL_MASS_CURE_WOUNDS}) (5 зарядов).

Посох ежедневно восстанавливает 1к6 + 4 заряда на рассвете. Если Вы истратили последний заряд, бросьте к20. Если выпадет «1», посох исчезает во вспышке света, теряясь навсегда.`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_CURE_WOUNDS,
      SPELL_LESSER_RESTORATION,
      SPELL_MASS_CURE_WOUNDS,
    ],
    source: {
      id: SOURCE_DMG,
      page: 194,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THE_WOODLANDS,
    name: `Посох леса`,
    nameEn: `Staff of the Woodlands`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_QUARTERSTAFF,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `друидом`,
    description: `Этот посох можно использовать как магический [боевой посох](GEAR:${GEAR_QUARTERSTAFF}), предоставляющий бонус +2 к броскам атаки и урона им. Держа его, Вы получаете бонус +2 к броскам атаки заклинаниями.

У посоха есть 10 зарядов для описанных ниже свойств. Он ежедневно восстанавливает 1к6 + 4 заряда на рассвете. Если Вы истратили последний заряд, бросьте к20. Если выпадет «1», посох теряет все свои свойства и становится немагическим [боевым посохом](GEAR:${GEAR_QUARTERSTAFF}).

# Заклинания

Вы можете действием потратить 1 или несколько зарядов посоха, чтобы наложить им одно из следующих заклинания, используя свою СЛ испытания от заклинаний:

* [Бесследное передвижение](SPELL:${SPELL_PASS_WITHOUT_TRACE}) (без траты зарядов),
* [Дружба с животными](SPELL:${SPELL_ANIMAL_FRIENDSHIP}) (1 заряд),
* [Дубовая кора](SPELL:${SPELL_BARKSKIN}) (2 заряда),
* [Поиск животных или растений](SPELL:${SPELL_LOCATE_ANIMALS_OR_PLANTS}) (2 заряда),
* [Пробуждение разума](SPELL:${SPELL_AWAKEN}) (5 зарядов),
* [Разговор с животными](SPELL:${SPELL_SPEAK_WITH_ANIMALS}) (1 заряд),
* [Разговор с растениями](SPELL:${SPELL_SPEAK_WITH_PLANTS}) (3 заряда),
* [Терновая стена](SPELL:${SPELL_WALL_OF_THORNS}) (6 зарядов).

# Древесный облик

Вы можете действием воткнуть посох в плодородную землю и потратить 1 заряд, чтобы превратить посох в здоровое дерево. Высота дерева 60 футов, диаметр ствола — 5 футов, а ветви на макушке раскинуты в радиусе 20 футов.

Это дерево выглядит обычным, но излучает слабую магию школы Преобразования, если становится целью обнаружения магии.

Прикоснувшись к дереву и произнеся действием командное слово, Вы возвращаете посоху его обычный облик. Все находящиеся на дереве существа при этом падают.`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_ANIMAL_FRIENDSHIP,
      SPELL_AWAKEN,
      SPELL_BARKSKIN,
      SPELL_LOCATE_ANIMALS_OR_PLANTS,
      SPELL_PASS_WITHOUT_TRACE,
      SPELL_SPEAK_WITH_ANIMALS,
      SPELL_SPEAK_WITH_PLANTS,
      SPELL_WALL_OF_THORNS,
    ],
    source: {
      id: SOURCE_DMG,
      page: 194,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_WITHERING,
    name: `Посох иссушения`,
    nameEn: `Staff of Withering`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_QUARTERSTAFF,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    attunementComment: `друидом, жрецом или колдуном`,
    description: `У этого посоха 3 заряда, и он ежедневно восстанавливает 1к3 заряда на рассвете.

Этот посох можно использовать как магический [боевой посох](GEAR:${GEAR_QUARTERSTAFF}). При попадании он причиняет урон как обычный [боевой посох](GEAR:${GEAR_QUARTERSTAFF}), и Вы можете потратить 1 заряд, чтобы причинить цели дополнительный урон некротической энергией 2к10. Кроме того, цель должна пройти испытание Телосложения СЛ 15, иначе она в течение 1 часа будет совершать с помехой проверки характеристик и испытания, использующие Силу или Телосложение.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 193,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THUNDER_AND_LIGHTNING,
    name: `Посох грома и молнии`,
    nameEn: `Staff of Thunder and Lightning`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_QUARTERSTAFF,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Этот посох можно использовать как магический [боевой посох](GEAR:${GEAR_QUARTERSTAFF}), предоставляющий бонус +2 к броскам атаки и урона им. Он также обладает описанными ниже дополнительными свойствами. Каждое из свойств не может быть повторно использовано до следующего рассвета.

# Молния

Если Вы попали рукопашной атакой, используя этот посох, Вы можете причинить цели дополнительный урон электричеством 2к6.

# Гром

Если Вы попали рукопашной атакой, используя этот посох, Вы можете заставить посох издать громовой рокот, слышный в пределах 300 футов. Цель, по которой Вы попали, должна пройти испытание Телосложения СЛ 17, иначе она станет ошеломлённой до конца Вашего следующего хода.

# Удар молнии

Вы можете действием выпустить из кончика посоха молнию линией шириной 5 футов и 120 футов длиной. Все существа в этой линии должны пройти испытание Ловкости СЛ 17, получая урон электричеством 9к6 при провале или половину этого урона при успехе.

# Удар грома

Вы можете действием заставить посох издать громовой гул, слышный в пределах 600 футов. Все существа в пределах 60 футов от Вас (исключая Вас) должны пройти испытание Телосложения СЛ 17. При провале существо получает урон звуком 2к6 и становится оглохшим на 1 минуту. При успехе существо получает половину урона и не становится оглохшим.

# Гром и молния

Вы можете действием использовать одновременно свойства «Удар молнии» и «Удар грома». Это не учитывается при подсчёте того, использовали ли Вы эти свойства в этот день, тратится использование лишь непосредственно этого свойства.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 193,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THE_ADDER,
    name: `Посох гадюки`,
    nameEn: `Staff of the Adder`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_STAFF,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    attunementComment: `друидом, жрецом или колдуном`,
    description: `Вы можете бонусным действием произнести командное слово [посоха](GEAR:${GEAR_STAFF}), чтобы сделать на 1 минуту его навершие головой ядовитой змеи. Произнеся бонусным действием командное слово ещё раз, Вы делаете [посох](GEAR:${GEAR_STAFF}) обычным.

Вы можете совершить рукопашную атаку головой змеи, у которой досягаемость 5 футов. К броску атаки применяется Ваш бонус мастерства. При попадании цель получает колющий урон 1к6 и должна пройти испытание Телосложения СЛ 15, иначе получит урон ядом 3к6.

Голову змеи можно атаковать, пока она жива. У неё КД 15 и 20 хитов. Если хиты головы уменьшатся до 0, посох уничтожается. Если посох пока не уничтожен, и Вы делаете его неоживлённым, он восстанавливает все свои хиты.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 193,
    },
  },
  {
    id: MAGIC_ITEM_SWORD_OF_WOUNDING,
    name: `Меч ранения`,
    nameEn: `Sword of Wounding`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч`,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Хиты, потерянные из-за урона этим оружием, могут восстановиться только за счёт короткого или длинного отдыха, а не за счёт регенерации, магии и других средств.

Один раз в ход, когда Вы попадаете по существу атакой, используя это магическое оружие, Вы можете ранить цель. В начале каждого своего хода раненое существо получает урон некротической энергией 1к4 за каждое ранение, а потом проходит испытание Телосложения СЛ 15, оканчивая эффекты всех таких ран на себе при успехе. В качестве альтернативы, раненое существо или любое существо в пределах 5 футов от неё может действием совершить проверку Мудрости (Медицина) СЛ 15, оканчивая эффект таких ран на нём при успехе.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 185,
    },
  },
  {
    id: MAGIC_ITEM_SWORD_OF_VENGEANCE,
    name: `Меч мести`,
    nameEn: `Sword of Vengeance`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч`,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Вы получаете бонус +1 к броскам атаки и урона этим магическим оружием.`,
    curse: `Этот меч проклят и одержим мстительным духом. Настроившись на него, Вы тоже становитесь проклятым. Пока Вы прокляты, Вы не желаете расставаться с этим мечом, и всегда держите его при себе. Пока Вы настроены на это оружие, Вы получаете помеху ко всем броскам атаки другим оружием.

Кроме того, пока меч находится у Вас, Вы должны проходить испытание Мудрости СЛ 15 каждый раз, когда получаете урон в сражении. При провале Вы должны атаковать существо, причинившее Вам урон, пока хиты одного из Вас не опустятся до 0, или пока Вы не сможете дотянуться до существа, чтобы совершить по нему рукопашную атаку.

Вы можете избавиться от проклятья как обычно. В качестве альтернативы, накладывание [Изгнания](SPELL:${SPELL_BANISHMENT}) на меч заставляет мстительный дух покинуть его. После этого меч становится обычным оружием +1 без особых свойств.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 184,
    },
  },
  {
    id: MAGIC_ITEM_SWORD_OF_LIFE_STEALING,
    name: `Меч кражи жизни`,
    nameEn: `Sword of Life Stealing`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч`,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Если Вы атакуете этим магическим оружием существо, и при броске атаки выпадает «20», эта цель получает дополнительный урон некротической энергией 10, если не является ни конструктом, ни нежитью. Вы также получаете 10 временных хитов.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 184,
    },
  },
  {
    id: MAGIC_ITEM_SENDING_STONES,
    name: `Камни послания`,
    nameEn: `Sending Stones`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Камни послания изготавливаются парами. На их гладкой поверхности есть парные символы, по которым их можно отличать от других таких камней. Пока Вы касаетесь одного камня, Вы можете действием наложить им заклинание [Послание](SPELL:${SPELL_SENDING}). Целью является владелец парного камня. Если второй камень никто не несёт, Вы это узнаёте в момент использования камня и заклинание не накладываете.

После того как камни наложат заклинание [Послание](SPELL:${SPELL_SENDING}), они не могут сделать это повторно до следующего утра. Если один из камней уничтожается, второй перестаёт быть магическим. `,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_SENDING],
    source: {
      id: SOURCE_DMG,
      page: 169,
    },
  },
  {
    id: MAGIC_ITEM_STONE_OF_GOOD_LUCK,
    name: `Камень удачи`,
    nameEn: `Stone of Good Luck`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока этот полированный агат находится у Вас, Вы получаете бонус +1 к проверкам характеристик и испытаниям.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 169,
    },
  },
  {
    id: MAGIC_ITEM_STONE_OF_CONTROLLING_EARTH_ELEMENTALS,
    name: `Камень контролирования земляных элементалей`,
    nameEn: `Stone of Controlling Earth Elementals`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Если этот камень касается пола, Вы можете действием произнести командное слово и призвать [земляного элементаля](CREATURE:${CREATURE_EARTH_ELEMENTAL}), как если бы наложили заклинание [Призыв элементаля](SPELL:${SPELL_CONJURE_ELEMENTAL}). После этого камень нельзя повторно использовать до следующего рассвета.

Весит камень 5 фунтов.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_CONJURE_ELEMENTAL],
    source: {
      id: SOURCE_DMG,
      page: 169,
    },
  },
]
