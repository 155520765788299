const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../../sourceList')
const {
  SPELL_DIVINATION,
  SPELL_EARTHQUAKE,
  SPELL_IDENTIFY,
} = require('./../../../../../spellIdList')
const {
  MAGIC_ITEM_DEVASTATION_ORB,
  MAGIC_ITEM_DEVASTATION_ORB_AIR,
  MAGIC_ITEM_DEVASTATION_ORB_EARTH,
  MAGIC_ITEM_DEVASTATION_ORB_FIRE,
  MAGIC_ITEM_DEVASTATION_ORB_WATER,
} = require('./../../../../../magicItemIdList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_DEVASTATION_ORB,
  name: 'Сфера опустошения',
  nameEn: 'Devastation Orb',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_VERY_RARE,
  isConsumable: true,
  description: [
    {
      header: 'Сфера опустошения',
      text: `Сфера опустошения — это бомба, заряженная силой стихий. Сферы создаются внутри стихийных узлов с помощью особых ритуалов, в которых используется стихийное оружие. Тип сферы зависит от типа узла: например, [воздушные сферы](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_AIR}) создаются в узле воздуха. Ритуал создания занимает 1 час и требует специальных компонентов стоимостью в 2000 зм, которые тратятся в процессе.

Сфера опустошения имеет 12 дюймов в диаметре, весит 10 фунтов и обладает гладкой поверхностью. Она взрывается спустя 1к100 часов после создания, высвобождая содержащуюся в ней энергию стихий. Внешний осмотр сферы никак не покажет, сколько времени осталось до взрыва, но это значение можно узнать с помощью заклинаний [Опознание](SPELL:${SPELL_IDENTIFY}) и [Предсказание](SPELL:${SPELL_DIVINATION}). Сфера имеет КД 10, 15 хитов и обладает иммунитетом к яду и психическому урону. Если хиты сферы опускаются до 0, она взрывается мгновенно.

Для безопасного хранения сферы можно создать специальный контейнер, на котором должны быть выгравированы символы противоположной стихии (например, в контейнере с символами земли можно хранить [воздушную сферу опустошения](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_AIR})). Пока сфера лежит в контейнере, она не может взорваться и издаёт гудящие звуки. Если сферу, время до взрыва которой уже истекло, извлечь из контейнера, то она взорвётся спустя 1к6 ходов. Чтобы предотвратить взрыв, её можно снова положить в контейнер.

Вне зависимости от типа сферы, её эффект распространяется на радиус в 1 милю. Сфера является центром эффекта и уничтожается после одного использования.`,
      source: {
        id: SOURCE_PotA,
        page: 228,
      },
    },
    {
      header: 'Воздушная сфера',
      text: `В момент взрыва [воздушная сфера](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_AIR}) создаёт сильную бурю, которая длится в течение 1 часа. На существ, которые заканчивают свой ход в зоне поражения сферы, обрушивается сильный ветер с осколками камней и прочим мусором, поэтому каждое такое существо должно пройти испытание Телосложения СЛ 18, иначе получит дробящий урон 1к4. Силы ветра хватает, чтобы вырывать с корнем чахлые деревья или уничтожать ветхие строения, если те находятся в области действия сферы как минимум 10 минут. В зоне действия сферы применяются правила по сильному ветру из главы 5 «Руководства Мастера».`,
      source: {
        id: SOURCE_PotA,
        page: 228,
      },
    },
    {
      header: 'Земляная сфера',
      text: `В момент взрыва [земляная сфера](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_EARTH}) создаёт в зоне поражения эффект, подобный заклинанию [Землетрясение](SPELL:${SPELL_EARTHQUAKE}), который длится 1 минуту. Эффект заклинания начинает действовать в тот же ход, что и взрыв сферы.`,
      source: {
        id: SOURCE_PotA,
        page: 228,
      },
    },
    {
      header: 'Огненная сфера',
      text: `В момент взрыва [огненная сфера](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_FIRE}) создаёт в зоне поражения волну невероятной жары, действие которой длится на протяжении 24 часов. В зоне действия сферы применяются правила по чрезвычайной жаре из главы 5 «Руководства Мастера». В конце каждого часа с вероятностью в 10% в случайном месте в пределах зоны действия сферы может начаться пожар, который мгновенно покрывает квадратную область со стороной в 10 футов, а затем каждый раунд распространяется на такую же область. Пожар будет бушевать до тех пор, пока не будет потушен или не выгорит полностью. Существа, которые проходят на расстоянии 10 футов от пожара или начинают там свой ход, получают урон огнём 3к6.`,
      source: {
        id: SOURCE_PotA,
        page: 228,
      },
    },
    {
      header: 'Водная сфера',
      text: `В момент взрыва [водная сфера](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_WATER}) создаёт в зоне поражения ужасный ливень, действие которого длится на протяжении 24 часов. В зоне действия сферы применяются правила по обильным осадкам из главы 5 «Руководства Мастера». Если в зоне действия сферы есть крупные водоёмы, они выходят из берегов через 2к10 часов с момента начала ливня. За это время уровень воды в них поднимается на 10 футов, затапливая окружающую местность. Наводнение движется со скоростью 100 футов в раунд, удаляясь от водоёма-источника до тех пор, пока не достигнет края области действия сферы: там вода уходит под землю (и, возможно, отступает назад к источнику). Поток воды сметает на своём пути лёгкие постройки и уносит существ, чей размер — Большой или меньше. Наводнение уничтожает возделанные поля и, в зависимости от ландшафта, может быть источником селей.`,
      source: {
        id: SOURCE_PotA,
        page: 228,
      },
    },
  ],
  genderId: GENDER_FEMALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_PotA,
    page: 228,
  },
}
