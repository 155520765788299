const {
  WEAPON_AMMUNITION,
  WEAPON_HEAVY,
  WEAPON_RANGE,
  WEAPON_RELOADING,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const crossbowsDescription = require('./../../constants/crossbowsDescription')
const {CAT_MARTIAL_RANGE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_CROSSBOW_HEAVY} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')

module.exports = {
  id: GEAR_CROSSBOW_HEAVY,
  genderId: GENDER_MALE,
  name: 'Тяжёлый арбалет',
  nameByCase: {
    nominative: 'тяжёлый арбалет',
    genitive: 'тяжёлого арбалета',
    accusative: 'тяжёлый арбалет',
    instrumental: 'тяжёлым арбалетом',
  },
  nameEn: 'Crossbow, heavy',
  description: crossbowsDescription,
  damage: {
    diceType: 10,
    diceCount: 1,
  },
  cost: 5000,
  damageType: DAMAGE_PIERCING,
  weight: 18,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    WEAPON_AMMUNITION,
    WEAPON_TWO_HANDED,
    WEAPON_RELOADING,
    WEAPON_HEAVY,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 100,
        max: 400,
      },
    },
  ],
  category: CAT_MARTIAL_RANGE_WEAPON,
}
