const {
  CREATURE_SWARM_OF_BUGS,
  CREATURE_SWARM_OF_INSECTS,
} = require('./../../../../creatureIdList')
const {insectSwarmDescription} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_DIG} = require('./../../../../speedList')

module.exports = {
  name: 'Рой жуков',
  id: CREATURE_SWARM_OF_BUGS,
  parentId: CREATURE_SWARM_OF_INSECTS,
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 340,
    }],
    description: insectSwarmDescription,
    speed: {
      [SPEED_DIG]: 5,
    },
  },
}
