const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  CREATURE_NECROMANCER,
  CREATURE_VAMPIRE,
  CREATURE_YETH_HOUND,
} = require('./../../../../creatureIdList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {ABILITY_KEEN_HEARING_AND_SMELL} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_TYPE_FEY} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Гончая йет',
  nameEn: 'Yeth Hound',
  id: CREATURE_YETH_HOUND,
  description: [
    `Даруемые могущественными феями личностям, которые им угодили, гончие йет служат своим злым мастерам как охотничьи псы. Гончие йет летают, преследуя добычу, и зачастую ждут пока она не выдохнется настолько, что сражаться уже не сможет. Только угроза рассвета заставляет стаю уйти и спрятаться.`,
    {
      header: `Слуги тёмного хозяина`,
      text: `Стая гончих йет может быть создана могущественной феей, такой как Королева Воздуха и Тьмы. Будучи созданной, стая должна иметь хозяина, обычно того, кого их создатель хочет вознаградить. Хозяин может телепатически общаться с гончими йет и давать им команды издалека. Если хозяин стаи убит, то гончие ищут и выбирают нового, обычно очень злого индивида, такого как [вампир](CREATURE:${CREATURE_VAMPIRE}), [некромант](CREATURE:${CREATURE_NECROMANCER}) или карга.

Гончая йет около 5 футов в высоту в холке и весит около 400 фунтов. Зачастую в темноте, из-за меха цвета ночной тьмы, можно увидеть лишь красное свечение её глаз. Голова гончей йет имеет лицо, похожее на человеческое, на более гибкой чем у собак шее. Запах этого существа похож на дым.`,
      source: {
        id: SOURCE_VGTM,
        page: 144,
      },
    },
    {
      header: `Звук смутной смерти`,
      text: `Гончие йет издают ужасный лающий звук, который слышно далеко вокруг. Существа, которые могут видеть гончую во время лая, наполняются сверхъестественным ужасом и обычно убегают в страхе. Когда жертва пытается сбежать, гончая наслаждается преследованием и муками добычи перед тем как закончить охоту.

Те же, кто решился стоять и защищаться, обнаруживают, что обычное оружие частично проходит сквозь гончую, как будто она сделана из тумана, однако магическое и серебряное оружие может им навредить.`,
      source: {
        id: SOURCE_VGTM,
        page: 144,
      },
    },
    {
      header: `Сбитые со следа солнечным светом`,
      text: `Гончие йет не выносят солнечного света. Стая никогда по своей воле не продолжит охоту после ночи и всегда хочет вернуться в свою тёмную берлогу до первых лучей рассвета. Никакое принуждение хозяина стаи не может изменить такое поведение. Если гончая йет окажется на естественном солнечном свету, то она растает и отправится в Эфирный План, откуда хозяин сможет забрать её только после захода солнца.`,
      source: {
        id: SOURCE_VGTM,
        page: 144,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 144,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: {
      value: 40,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 16,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  immunityList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: [
        LANG_COMMON,
        LANG_ELVEN,
        LANG_SYLVAN,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_KEEN_HEARING_AND_SMELL,
    {
      name: 'Изгнание солнечным светом',
      description: `Если гончая йет начинает свой ход в солнечном свете, то она переносится в Эфирный План. Пока солнечный свет светит на месте, из которого она пропала, гончая должна оставаться в Глубоком Эфире. После захода солнца она возвращается к Пограничному Эфиру в том же месте, после чего обычно отправляется искать свою стаю или хозяина. Гончая видима на Материальном плане, пока находится на Пограничном Эфире и наоборот, но не может воздействовать или находиться под воздействием чего угодно с другого плана. Когда она оказывается рядом с хозяином или собратом по стае, находящимся на Материальном плане, гончая йет может действием вернуться из Пограничного Эфира на Материальный план.`,
    },
    {
      name: 'Телепатическая связь',
      description: `Пока гончая йет находится на одном плане существования со своим хозяином, она может магически передавать то, что чувствует, хозяину, и они могут телепатически общаться друг с другом.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 4,
            diceType: 6,
            diceBonus: 0,
            comment: ` если цель испугана`,
          },
        ],
      },
    },
    {
      name: 'Зловещий лай',
      description: `Гончая йет магически лает. Каждый враг в радиусе 300 футов от гончей, который может её слышать, должен пройти испытание Мудрости СЛ 13 или стать испуганным до конца следующего хода гончей или пока гончая дееспособна. Испуганная цель, которая начинает ход не далее 30 футов от гончей, должна использовать всё свое движение в этот ход и уйти как можно дальше от гончей, должна закончить передвижение до совершения действия и должна выбирать самый прямой маршрут, даже если на пути есть опасности. Цель, успешно прошедшая испытание, получает иммунитет к лаю всех гончих йет на следующие 24 часа.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
