const {
  FEATURE_ARCHDRUID,
  FEATURE_BEAST_SPELLS,
  FEATURE_DRUID_CIRCLE,
  FEATURE_DRUIDIC,
  FEATURE_SPELLCASTING_DRUID,
  FEATURE_TIMELESS_BODY_DRUID,
  FEATURE_WILD_SHAPE,
} = require('./../../featureIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
} = require('./../../sourceList')
const {
  LANG_DRUIDIC,
} = require('./../../languageIdList')
const {
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND,
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON,
} = require('./../../pcSubClassIdList')
const {
  GOD_UMBERLEE,
  GOD_AURIL,
  GOD_CHAUNTEA,
  GOD_ELDATH,
  GOD_GRUMBAR,
  GOD_MALAR,
  GOD_MIELIKKI,
  GOD_SILVANUS,
  GOD_TALOS,
} = require('./../../godIdList')
const { fullCasterSpellSlotTable } = require('./../../spellSlotTable')
const {BACKGROUND_HERMIT} = require('./../../backgroundIdList')
const {PC_RACE_ELF} = require('./../../pcRaceIdList')

const {
  CREATURE_BEHOLDER,
  CREATURE_CULTIST,
  CREATURE_DIRE_WOLF,
  CREATURE_GIANT_EAGLE,
  CREATURE_MIND_FLAYER,
  CREATURE_ORC,
  CREATURE_SKELETON,
  CREATURE_VAMPIRE,
  CREATURE_ZOMBIE,
} = require('./../../creatureIdList')

const {
  PARAM_INT,
  PARAM_WIT,
} = require('./../../paramList')

const {
  GEAR_CLUB,
  GEAR_DAGGER,
  GEAR_DART,
  GEAR_EXPLORER_S_PACK,
  GEAR_HERBALISM_KIT,
  GEAR_JAVELIN,
  GEAR_LEATHER_ARMOR,
  GEAR_MACE,
  GEAR_QUARTERSTAFF,
  GEAR_SCIMITAR,
  GEAR_SHIELD,
  GEAR_SICKLE,
  GEAR_SLING,
  GEAR_SPEAR,
  GEAR_SPRIG_OF_MISTLETOE,
  GEAR_YEW_WAND,
} = require('./../../gearIdList')

const {
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_SHIELDS,
} = require('./../../gearCategoryList')

const {
  SKILL_ANIMAL_HANDLING,
  SKILL_ARCANA,
  SKILL_INSIGHT,
  SKILL_MEDICINE,
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
  SKILL_SURVIVAL,
} = require('./../../skillList')

const {
  SPELL_CALL_LIGHTNING,
  SPELL_COMMUNE,
  SPELL_LESSER_RESTORATION,
  SPELL_SCRYING,
} = require('./../../spellIdList')
const {
  PC_CLASS_BARBARIAN,
  PC_CLASS_BARD,
  PC_CLASS_DRUID,
  PC_CLASS_RANGER,
} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_DRUID,
  name: {
    singular: {
      nominative: 'друид',
      genitive: 'друида',
      dative: 'друиду',
      accusative: 'друида',
      instrumental: 'друидом',
      prepositional: 'друиде',
    },
    plural: {
      nominative: 'друиды',
      genitive: 'друидов',
      dative: 'друидам',
      accusative: 'друидов',
      instrumental: 'друидами',
      prepositional: 'друидах',
    },
  },
  nameEn: 'druid',
  description: [
    `Вскидывая вверх сучковатый посох из остролиста, эльф вызывает буйство небесной стихии и обрушивает [разряды молний](SPELL:${SPELL_CALL_LIGHTNING}) на [орков](CREATURE:${CREATURE_ORC}), посмевших угрожать его лесу огнём.

Затаившись высоко в кроне дерева, человек в облике леопарда смотрит из джунглей на чужеродную архитектуру храма Злой Стихии Воздуха и пристально наблюдает за действиями [культистов](CREATURE:${CREATURE_CULTIST}).

Рубя клинком из чистого пламени, полуэльф бросается в гущу армии [скелетов](CREATURE:${CREATURE_SKELETON}), чтобы уничтожить кощунственную магию, возвратившую им извращённое подобие жизни.

Призывая стихии или подражая животным, друиды воплощают незыблемость, приспособляемость и гнев природы. Они ни в коем случае не владыки природы — вместо этого друиды ощущают себя частью её неодолимой воли. `,
    {
      header: `Сила природы`,
      text: `Выше чего-либо другого друиды почитают природу. Именно она является источником всех их заклинаний и магических способностей, непсредственно или через природное божество. Многие предпочитают духовный путь конечного единения с природой, но есть и те, кто служат богам диких просторов, животных или стихий. Длящиеся испокон веков обычаи друидов также называют Старой Верой, противопоставляя их поклонению в храмах и у алтарей.

Заклинания друидов воздействуют через животных и окружающую природу. Это сила клыка и когтя, солнца и луны, огня и бури. Также друиды учатся принимать облик животных, и некоторые настолько углубляются в это умение, что родному облику предпочитают звериный. `,
      source: {
        id: SOURCE_PHB,
        page: 65,
      },
    },
    {
      header: `Сохранение равновесия`,
      text: `Для друидов природа представляет собой шаткое равновесие. Воздух, земля, огонь и вода — те четыре стихии, что составляют основу мира — должны уравновешивать друг друга. Если одна из стихий обретёт превосходство, сам мир может прекратить существование, притянутый стихийным измерением и разорванный на элементарные составляющие. Чтобы не допустить этого, друиды противостоят сектам Стихийного Зла и всем, кто стремится наделить любую из стихий превосходящей силой. Также друидов заботит тонкое экологическое равновесие, необходимое флоре и фауне, и готовность цивилизованных поселений жить в гармонии с природой, а не противопоставлять себя ей. Жестокость природы — часть естественного порядка вещей, однако друиды не терпят всё чужеродное, включая аберрации ([иллитиды](CREATURE:${CREATURE_MIND_FLAYER}) и [бехолдеры](CREATURE:${CREATURE_BEHOLDER})) и нежить ([зомби](CREATURE:${CREATURE_ZOMBIE}) и [вампиры](CREATURE:${CREATURE_VAMPIRE})). Иногда друиды совершают вылазки на таких существ, особенно если те приближаются к их территориям.

Священная земля и области нетронутой природы нередко охраняются друидом. Впрочем, при достаточной угрозе естественному равновесию или подзащитной земле друид может перейти к активной борьбе с напастью, ступив на путь искателя приключений.`,
      source: {
        id: SOURCE_PHB,
        page: 65,
      },
    },
    {
      header: `Друиды`,
      text: `Друиды Королевств почитают природу во всех её формах, а также богов Первого Круга — наиболее близких к силе и величию природы. Эта группа богов включает в себя [Чонтеи](GOD:${GOD_CHAUNTEA}), [Эльдат](GOD:${GOD_ELDATH}), [Майликки](GOD:${GOD_MIELIKKI}), [Сильвануса](GOD:${GOD_SILVANUS}) наряду с [Ориль](GOD:${GOD_AURIL}), [Маларом](GOD:${GOD_MALAR}), [Талосом](GOD:${GOD_TALOS}) и [Амберли](GOD:${GOD_UMBERLEE}), отвечающих за многогранность природы и её немилосердность.

В отличие от жрецов, которые, как правило, служат одному богу, друиды почитают всех богов Первого Круга, видя в них воплощения природы, движущейся по циклам: созидание и разрушение, произрастание и увядание, жизнь и смерть. Так [Грумбар](GOD:${GOD_GRUMBAR}) для друида не просто бог земли, он и плодородная почва, и каменистый холм. [Малар](GOD:${GOD_MALAR}) не только Лорд Зверей, но и голод, и инстинкт охотника у хищного зверя.

Хотя друиды сильнее всего привязаны к сильванским лесам, они заботятся обо всех аспектах местности, и о ледяных горах, о жарких пустынях, о каменистых холмах, так же, как и побережье. `,
      source: {
        id: SOURCE_SCAG,
        page: 127,
      },
    },
    {
      header: `Круги Друидов`,
      text: `Ритуалы друидов стары и в основном проводятся скрыто, подальше от глаз непосвященных. Во многих землях Старые Пути Первого Круга уступили место новым храмам и церквям, но друиды и их последователи, всё ещё собираются, чтобы почтить природные циклы и защитить природный баланс. Люди, живущие вблизи диких земель, обычно знают, что рядом действует круг друидов, и обычно ищут у него благословения перед охотой или работой на землях, которые он защищает.

Обычно друиды собираются на полянах, в лесистых рощах или рядом со священными прудами, в зависимости от традиций круга. В кругу все равны, но уважение зависит от возраста и достижений, все решения принимаются коллегиально. Те, кто не согласен, могут отстаивать свою точку зрения или даже покинуть круг, если пожелают, но круг старается достичь блага для всех. Друидические круги часто включают в себя союзников, не являющихся друидами, например, [следопытов](PC_CLASS:${PC_CLASS_RANGER}), лесных [эльфов](PC_RACE:${PC_RACE_ELF}) и фей принадлежащих той земле, где собираются круг — все получают право голоса.

Множество кругов рассеяно по Фаэруну, обычно состоящих из дюжины, или около того, друидов, и их союзников. К ним относятся Высший Танец, охраняющий вместе со своими союзниками феями Место Танца в высокогорье Грозовых Вершин. Хранители Севрельда встречаются в Старой Грибной Роще в Высоком лесу, к северо-востоку от Секомбера, и Звездноводный круг собирается вокруг одноименного пруда на севере леса Мир.

# Круг Мечей

Защитники Невервинтерского леса, Круг Мечей прогоняют из леса склонных к разрушению гуманоидов, таких как хобгоблины, багбиры и им подобные, в то же время защищая лес от эксплуатации со стороны цивилизованного люда, а древние руины и священные места от мародёров.

[Круг Луны](PC_SUBCLASS:${PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON}) является самым распространенным для друидов Круга Мечей, хотя некоторые принадлежат к [Кругу Земли](PC_SUBCLASS:${PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND}) (Лес).

# Изумрудный Анклав

Менее похожий на круг друидов, и более на содружество кругов и их союзников Изумрудный Анклав занимается защитой оплотов цивилизации Севера от разрушения. В ином месте в мире Изумрудный Анклав будет следовать пути баланса, но большинство диких земель Севера представляют для людей опасность гораздо большую, чем они для неё.

Основанный в Вилхонской Протоке более тысячи лет назад, Изумрудный Анклав распространился по большей части Фаэруна. Членами организации являются друиды, [следопыты](PC_CLASS:${PC_CLASS_RANGER}) и [варвары](PC_CLASS:${PC_CLASS_BARBARIAN}), так же, как и все те, кто живут в диких землях, знают и уважают её законы. Они носят изумрудно-зелёные одежды как символ принадлежности к организации, часто с использованием эмблемы в виде головы оленя.

В Изумрудном Анклаве в равной доле представлены и друиды [Круга Земли](PC_SUBCLASS:${PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND}), и друиды [Круга Луны](PC_SUBCLASS:${PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON}).

# Круги Муншае

Ффолки островов Муншае почитают землю как богиню, называемую Матерью-Землёй. Круги собираются вокруг священных прудов, называемых _лунными колодцами_, которые, служащие связью между миром природы и их богиней, и окруженные стоячими камнями, водруженными их предками.

Друиды островов Муншае чаще всего принадлежат к [Кругу Земли](PC_SUBCLASS:${PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND}) (Побережье, Лес и Горы).

## Лунные Колодцы

Вода из лунного колодца, если пить её сразу пригоршней, восстанавливает 1к8 хитов + модификатор Мудрости пьющего её (если он есть). Если до прошлого полнолуния пьющий воду нарушал баланс природы, вместо этого вода наносит 1к8 урона ядом. То же самое произойдёт, если пить из осквернённого лунного колодца. Эффект может сработать на пьющего один раз в день. В ночь полнолуния, вода может иметь особые свойства на усмотрение Мастера, например, эффект подобный заклинанию [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}).

Вода Лунного Колодца, помещенная в ёмкость или унесённая больше чем на 30 футов от колодца, теряет все свои свойства, становясь обычной водой.

В течение трёх ночей после полной луны, при собрании трёх или более друидов у лунного колодца, они один раз могут прочесть заклинания [Общение](SPELL:${SPELL_COMMUNE}) и [Наблюдение](SPELL:${SPELL_SCRYING}) без траты ячейки заклинаний и материальных компонентов, при условии, что один из друидов 9 уровня, а остальные не ниже 4 уровня. По желанию Мастера, в такие ночи друиды могут использовать лунный колодец, чтобы прочесть другие заклинания.`,
      source: {
        id: SOURCE_SCAG,
        page: 127,
      },
    },
    {
      header: `Арфисты и друиды`,
      text: `Круги друидов Севера часто вступают в союз с Арфистами, имеющими сходные с ними цели, а [барды](PC_CLASS:${PC_CLASS_BARD}) и [следопыты](PC_CLASS:${PC_CLASS_RANGER}) служат между ними посредниками. Одиночным Арфисты обычно могут предоставить кругу пищу и кров, а также возможность высказаться на собрании, если круг того пожелает.

Тем не менее, Арфисты не друидическая организация, и несмотря на мнение некоторых обычных людей, не каждый друид вступает в союз с Арфистами или даже благосклонно относится к их организации и целям. На самом деле, некоторые друиды рассматривают Арфистов как суетливых людей, которые угрожают природному балансу почти так же, как и зло, против которого они сражаются.`,
      source: {
        id: SOURCE_SCAG,
        page: 128,
      },
    },
    {
      header: `Создание друида`,
      text: `Создавая персонажа друида, подумайте о том, что сформировало его тесную связь с природой. Возможно, он родом из мест, где жива Старая Вера. Или же его, брошенного ребёнком в чаще леса, нашёл и вырастил друид. Возможно, персонаж испытал волнующий опыт встречи с духом природы — скажем, пережил нападение [гигантского орла](CREATURE:${CREATURE_GIANT_EAGLE}) или [лютого волка](CREATURE:${CREATURE_DIRE_WOLF}). Может, персонаж родился в разгар бури или во время извержения вулкана, что стало знаком уготованного ему пути друида.

Всегда ли он был странствующим друидом или же когда-то охранял рощу или ручей? Возможно, зло отравило его родину, и он отправился в большой мир в поисках нового смысла и надежды.`,
      source: {
        id: SOURCE_PHB,
        page: 66,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать друида, следуя этим рекомендациям.

1. Во-первых, у Вашей Мудрости должно быть наивысшее значение. Следующим по величине должно быть значение Телосложения.
2. Во-вторых, выберите предысторию «[Отшельник](BACKGROUND:${BACKGROUND_HERMIT})».`,
      source: {
        id: SOURCE_PHB,
        page: 66,
      },
    },
    {
      header: `Священные растения`,
      text: `Некоторые растения считаются священными у друидов. В частности, это берёза, бузина, дуб, ива, можжевельник, ольха, омела, орех, падуб (он же остролист), рябина, тис и ясень. Эти растения часто используются в изготовлении друидической фокусировки, которая может включать, например, ветви дуба или [тиса](GEAR:${GEAR_YEW_WAND}), [побеги омелы](GEAR:${GEAR_SPRIG_OF_MISTLETOE}).

Древесина подходит и для других изделий, включая оружие и щиты. Тис связан со смертью и перерождением, так что из него делают рукояти [скимитаров](GEAR:${GEAR_SCIMITAR}) и [серпов](GEAR:${GEAR_SICKLE}). Ясень символизирует жизнь, а дуб — силу: из них получаются замечательные древки, [дубины](GEAR:${GEAR_CLUB}), [боевые посохи](GEAR:${GEAR_QUARTERSTAFF}) и [щиты](GEAR:${GEAR_SHIELD}). Ольху считают растением воздуха и делают из неё метательное оружие, например, [дротики](GEAR:${GEAR_DART}) и [метательные копья](GEAR:${GEAR_JAVELIN}).

Друиды из мест, которым эти растения чужды, обращаются к местным видам. Например, пустынный друид может почитать юкку и кактус.`,
      source: {
        id: SOURCE_PHB,
        page: 67,
      },
    },
  ],
  note: [
    {
      text: `Даже в смерти каждое существо играет свою роль в поддержании Великого Баланса. Но теперь растет дисбаланс, сила, которая хочет повелевать природой. Это разрушительное поведение смертных рас. Чем дальше их уводят от природы их действия, тем более развращает их влияние. Как друиды мы стремимся в основном защищать и обучать, сохранять Великий Баланс, но бывают случаи, когда мы должны встать против опасности и искоренить её.`,
      author: `Сафран, архидруид`,
    },
    {
      text: `Мне всегда нравились друиды, потому что они изготовлены из натуральных ингредиентов. И я считаю, что каждый должен иметь такую здоровую диету`,
      author: `Занатар`,
    },
    {
      text: `Если бы я мог превратиться во что-то ещё, я бы этого не сделал. Потому что все остальные слабее меня.`,
      author: `Занатар`,
    },
  ],
  featureCollection: {
    hitDice: 8,
    proficiency: {
      armor: [
        CAT_LIGHT_ARMOR,
        CAT_MEDIUM_ARMOR,
        CAT_SHIELDS,
      ],
      armorComment: `друиды не носят доспехи и щиты из металла`,
      weapon: {
        gearList: [
          GEAR_CLUB,
          GEAR_DAGGER,
          GEAR_DART,
          GEAR_JAVELIN,
          GEAR_MACE,
          GEAR_QUARTERSTAFF,
          GEAR_SCIMITAR,
          GEAR_SICKLE,
          GEAR_SLING,
          GEAR_SPEAR,
        ],
      },
      tool: {
        list: [
          GEAR_HERBALISM_KIT,
        ],
      },
      savingThrow: [
        PARAM_WIT,
        PARAM_INT,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_ANIMAL_HANDLING,
            SKILL_ARCANA,
            SKILL_INSIGHT,
            SKILL_MEDICINE,
            SKILL_NATURE,
            SKILL_PERCEPTION,
            SKILL_RELIGION,
            SKILL_SURVIVAL,
          ],
        },
      },
      language: LANG_DRUIDIC,
    },
    equipment: {
      coins: {
        diceCount: 2,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) Деревянный [щит](GEAR:${GEAR_SHIELD})
    * б) Одно простое оружие
2. Выберите одно
    * а) [Скимитар](GEAR:${GEAR_SCIMITAR})
    * б) Простое рукопашное оружие
3. [Кожаный доспех](GEAR:${GEAR_LEATHER_ARMOR})
4. [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})
5. Фокусировка друидов`,
    },
  },
  progressTable: `
| Уровень | Умения                                          | Заговоры |
|---------|-------------------------------------------------|----------|
|  1      | [Друидический язык](FEATURE:${FEATURE_DRUIDIC}), [Использование заклинаний](FEATURE:${FEATURE_SPELLCASTING_DRUID}) | 2 |
|  2      | [Дикий облик](FEATURE:${FEATURE_WILD_SHAPE}), [Круг друидов](FEATURE:${FEATURE_DRUID_CIRCLE}) | 2 |
|  3      | —                                               | 2        |
|  4      | Улучшение дикой формы, Увеличение характеристик | 3        |
|  5      | —                                               | 3        |
|  6      | Умение круга друидов                            | 3        |
|  7      | —                                               | 3        |
|  8      | Улучшение дикой формы, Увеличение характеристик | 3        |
|  9      | —                                               | 3        |
| 10      | Умение круга друидов                            | 4        |
| 11      | —                                               | 4        |
| 12      | Увеличение характеристик                        | 4        |
| 13      | —                                               | 4        |
| 14      | Умение круга друидов                            | 4        |
| 15      | —                                               | 4        |
| 16      | Увеличение характеристик                        | 4        |
| 17      | —                                               | 4        |
| 18      | [Безвременное тело](FEATURE:${FEATURE_TIMELESS_BODY_DRUID}), [Заклинания зверя](FEATURE:${FEATURE_BEAST_SPELLS}) | 4 |
| 19      | Увеличение характеристик                        | 4        |
| 20      | [Архидруид](FEATURE:${FEATURE_ARCHDRUID}) | 4 |
`,
  spellSlotTable: fullCasterSpellSlotTable,
  source: [
    {
      id: SOURCE_PHB,
      page: 65,
    },
    {
      id: SOURCE_SRD,
      page: 19,
    },
  ],
}
