const PROGRESS_TABLE_ID = require('./../../PROGRESS_TABLE_ID')
const SPELL_SLOT_TABLE_ID = require('./../../SPELL_SLOT_TABLE_ID')
const {fullCasterSpellCircleList} = require('./../../spellCircleByCasterType')
const {LANG_DRUIDIC} = require('./../../languageIdList')
const {PC_CLASS_DRUID} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
} = require('./../../sourceList')
const {
  SPELL_CALL_LIGHTNING,
  SPELL_CURE_WOUNDS,
  SPELL_FIND_FAMILIAR,
} = require('./../../spellIdList')
const {spellIdCollectionByPcClassId} = require('./../../spellIdPcClassIdBounds')

const {abilityScoreImprovementTemplate} = require('./../featureCommonRawList')

const {
  CREATURE_CROCODILE,
  CREATURE_WOLF,
  CREATURE_GIANT_EAGLE,
} = require('./../../creatureIdList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_DRUID,
  FEATURE_ARCHDRUID,
  FEATURE_BEAST_SPELLS,
  FEATURE_CANTRIP_VERSATILITY_DRUID,
  FEATURE_CANTRIPS_DRUID,
  FEATURE_DRUID_CIRCLE,
  FEATURE_DRUIDIC,
  FEATURE_PREPARING_SPELLS_DRUID,
  FEATURE_RITUAL_CASTING_DRUID,
  FEATURE_SPELLCASTING_ABILITY_DRUID,
  FEATURE_SPELLCASTING_DRUID,
  FEATURE_SPELLCASTING_FOCUS_DRUID,
  FEATURE_TIMELESS_BODY_DRUID,
  FEATURE_WILD_COMPANION,
  FEATURE_WILD_SHAPE,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_DRUIDIC,
    name: `Друидический язык`,
    nameEn: `Druidic`,
    lvl: 1,
    text: `Вы знаете [Друидический язык](LANG:${LANG_DRUIDIC}) — тайный язык друидов.

Вы можете на нём говорить и оставлять тайные послания. Вы и все, кто знают этот язык, автоматически замечаете эти послания. Другие замечают присутствие послания при успешной проверке Мудрости (Внимательность) СЛ 15, но без помощи магии не могут расшифровать его.`,
    source: {
      id: SOURCE_PHB,
      page: 66,
    },
  },
  {
    id: FEATURE_SPELLCASTING_DRUID,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 1,
    text: `Для сотворения заклинаний друиды пользуются сакральной эссенцией самой природы, облачая в неё свою волю.`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_DRUID],
    source: {
      id: SOURCE_PHB,
      page: 67,
    },
  },
  {
    id: FEATURE_CANTRIPS_DRUID,
    name: `Заговоры`,
    nameEn: `Cantrips`,
    lvl: 1,
    text: `На 1 уровне Вы знаете два заговора на Ваш выбор из списка заклинаний друида.

Вы узнаёте дополнительные заговоры друида на более высоких уровнях, как показано в таблице _«[Развитие друида](PC_CLASS:${PC_CLASS_DRUID}#${PROGRESS_TABLE_ID})»_.`,
    source: {
      id: SOURCE_PHB,
      page: 67,
    },
  },
  {
    id: FEATURE_PREPARING_SPELLS_DRUID,
    name: `Подготовка и сотворение заклинаний`,
    nameEn: `Preparing and Casting Spells`,
    lvl: 1,
    text: `Таблица «[Ячейки заклинаний друида](PC_CLASS:${PC_CLASS_DRUID}#${SPELL_SLOT_TABLE_ID})» показывает, какое количество ячеек для сотворения заклинаний у Вас есть на первом и более высоких уровнях. Для сотворения одного из этих заклинаний Вы должны потратить ячейку заклинания того же круга или выше, что и само заклинание. Вы восстановите все потраченные ячейки, когда завершите длинный отдых.

Вы подготавливаете список заклинаний друида, доступных для сотворения. При этом Вы выбираете число заклинаний друида из списка заклинаний друида, равное модификатору Мудрости + уровень друида (минимум одно заклинание). Круг заклинаний не должен превышать круга самой высокой имеющейся у Вас ячейки заклинаний.

Например, если Вы друид 3 уровня, то у Вас есть четыре ячейки заклинаний 1 круга и две ячейки 2 круга. При Мудрости 16 Ваш список подготовленных заклинаний может включать в себя шесть заклинаний 1 или 2 круга, в любой комбинации. Если Вы подготовили заклинание 1 круга [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}), то можете сотворить его, используя ячейку 1 или 2 круга. Сотворение заклинания не удаляет его из списка подготовленных заклинаний.

Вы можете изменить список подготовленных заклинаний, когда завершаете длинный отдых. Подготовка нового списка заклинаний друида требует времени, проведённого в молитвах и медитации: не менее 1 минуты за круг заклинания для каждого заклинания в Вашем списке.`,
    source: {
      id: SOURCE_PHB,
      page: 67,
    },
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_DRUID,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 1,
    text: `Друидические заклинания творятся с помощью Мудрости, поскольку требуют веры и близости с природой.

Вы используете Мудрость в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете модификатор Мудрости при определении СЛ испытаний от Ваших заклинаний друида, и при броске атаки заклинаниями. 

**СЛ испытания** = 8 + бонус мастерства + модификатор Мудрости

**Модификатор броска атаки** = бонус мастерства + модификатор Мудрости`,
    source: {
      id: SOURCE_PHB,
      page: 67,
    },
  },
  {
    id: FEATURE_RITUAL_CASTING_DRUID,
    name: `Ритуальное колдовство`,
    nameEn: `Ritual Casting`,
    lvl: 1,
    text: `Вы можете сотворить заклинание друида как ритуал, если у этого заклинания есть ключевое слово «ритуал», и оно Вами подготовлено.`,
    source: {
      id: SOURCE_PHB,
      page: 67,
    },
  },
  {
    id: FEATURE_SPELLCASTING_FOCUS_DRUID,
    name: `Фокусировка заклинания`,
    nameEn: `Spellcasting Focus`,
    lvl: 1,
    text: `Вы можете использовать фокусировку друидов в качестве заклинательной фокусировки для заклинаний друида.`,
    source: {
      id: SOURCE_PHB,
      page: 67,
    },
  },
  {
    id: FEATURE_WILD_SHAPE,
    name: `Дикий облик`,
    nameEn: `Wild Shape`,
    lvl: 2,
    text: `Вы можете действием принять при помощи магии облик любого зверя, которого Вы видели.

Вы можете использовать это умение два раза, восстанавливая его после короткого или длинного отдыха. Уровень друида определяет, в каких зверей можно превращаться. Например, на 2 уровне можно превращаться в животное с показателем опасности не более ¼, без скорости полёта и плавания.
 
| Уровень | Макс. ПО | Ограничения                    | Пример                                              |
|---------|----------|--------------------------------|-----------------------------------------------------|
| 2       | ¼        | Без скорости плавания и полёта | [Волк](CREATURE:${CREATURE_WOLF})                   |
| 4       | ½        | Без скорости полёта            | [Крокодил](CREATURE:${CREATURE_CROCODILE})          |
| 8       | 1        | —                              | [Гигантский орёл](CREATURE:${CREATURE_GIANT_EAGLE}) |

В облике животного можно оставаться число часов, равное половине уровня друида (округляя в меньшую сторону). Затем друид возвращается в нормальный облик, если только не потратит ещё одно использование _Дикого облика_. Можно вернуться в нормальный облик досрочно, бонусным действием. Бессознательный, доведённый до 0 хитов или мёртвый друид сразу возвращается в нормальный облик.

Когда Вы превращены, действуют следующие правила:

* Все игровые параметры берутся из параметров зверя, но сохраняются мировоззрение, личность и значения Интеллекта, Мудрости и Харизмы. Также у Вас остаются владения навыками и испытаниями, в дополнение к таковым нового облика. Если квалификация есть и у Вас и у зверя, но у него бонус выше, используется бонус зверя. Легендарные действия и действия в логове недоступны.
* Когда Вы превращены, Вы принимаете хиты и Кость Хитов зверя. Вернувшись в нормальный облик, Ваши хиты будут такими же, как до превращения. Однако если Вы вернулись в свой облик из-за опускания хитов до 0, «лишний» урон переносится на нормальный облик. Например, если Вы в облике зверя получили урон 10, имея при этом 1 хит, то Вы возвращаетесь в нормальный облик и получаете 9 урона. Если этот урон не довёл хиты персонажа до 0, он не теряет сознание.
* Вы не можете сотворять заклинания, а речь и действия, требующие рук, могут быть ограничены видом зверя. Превращение не прерывает Вашу концентрацию на уже сотворённых заклинаниях и не мешает совершать действия, являющиеся частью заклинания, такие как в случае заклинания [Призыв молнии](SPELL:${SPELL_CALL_LIGHTNING}).
* Вы сохраняете преимущества от всех умений класса, расы и прочих источников, и можете пользоваться ими, если этому не препятствует новый физический облик. Однако недоступны особые чувства, такие как тёмное зрение, если только их нет у зверя.
* Вы сами определяете, какое снаряжение останется лежать на земле, какое сольётся с новым обликом, а какое будет надето. Мастер решает, какое снаряжение может носить животное, чтобы оно действовало как обычно. Снаряжение не меняет форму и размер под новый облик, и если не подходит новому облику, оно должно остаться на земле или слиться с новым обликом. Слившееся с обликом снаряжение не работает, пока Вы опять не примете свой облик.`,
    source: {
      id: SOURCE_PHB,
      page: 67,
    },
  },
  {
    id: FEATURE_WILD_COMPANION,
    name: `Дикий спутник`,
    nameEn: `Wild Companion`,
    lvl: 2,
    text: `Вы получаете возможность призывать духа в животном облике.
 
Вы можете действием использовать свою способность [Дикий облик](FEATURE:${FEATURE_WILD_SHAPE}), чтобы сотворить заклинание [Поиск фамильяра](SPELL:${SPELL_FIND_FAMILIAR}) без материальных компонентов.

Ваш фамильяр — фея, а не зверь. Он исчезает через количество часов, равное половине Вашего уровня друида.`,
    spellIdList: SPELL_FIND_FAMILIAR,
    source: {
      id: SOURCE_TCoE,
      page: 51,
    },
  },
  {
    id: FEATURE_DRUID_CIRCLE,
    name: `Круг друидов`,
    nameEn: `Druid Circle`,
    lvl: [2, 6, 10, 14],
    text: `Нужно выбрать, в каком круге друидов состоит персонаж.

Этот выбор даёт дополнительные умения на 2, 6, 10 и 14 уровнях.`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 68,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_DRUID,
    source: {
      id: SOURCE_PHB,
      page: 68,
    },
  },
  {
    id: FEATURE_CANTRIP_VERSATILITY_DRUID,
    name: `Гибкость заговоров`,
    nameEn: `Cantrip Versatility`,
    lvl: abilityScoreImprovementTemplate.lvl,
    text: `Всякий раз, достигая в этом классе нового уровня, на котором Вы получаете способность [Увеличения характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_DRUID}), Вы можете заменить один заговор, который Вы выучили способностью [Заговоры](FEATURE:${FEATURE_SPELLCASTING_DRUID}) этого класса, другим заговором из списка заклинаний друида.`,
    source: {
      id: SOURCE_TCoE,
      page: 51,
    },
  },
  {
    id: FEATURE_TIMELESS_BODY_DRUID,
    name: `Безвременное тело`,
    nameEn: `Timeless Body`,
    lvl: 18,
    text: `Пронизывающая Вас природная магия замедляет старение.

За каждые 10 лет Ваше тело стареет только на 1 год. `,
    source: {
      id: SOURCE_PHB,
      page: 68,
    },
  },
  {
    id: FEATURE_BEAST_SPELLS,
    name: `Заклинания зверя`,
    nameEn: `Beast Spells`,
    lvl: 18,
    text: `Персонаж может творить в [Диком облике](FEATURE:${FEATURE_WILD_SHAPE}) заклинания друида.

Будучи зверем, Вы можете исполнить соматический и вербальный компоненты заклинания друида, но не можете обеспечить материальные компоненты.`,
    source: {
      id: SOURCE_PHB,
      page: 68,
    },
  },
  {
    id: FEATURE_ARCHDRUID,
    name: `Архидруид`,
    nameEn: `Archdruid`,
    lvl: 20,
    text: `Число применений [Дикого облика](FEATURE:${FEATURE_WILD_SHAPE}) не ограничено.

Кроме того, Вы можете игнорировать соматический и вербальный компоненты заклинаний друида, а также материальные компоненты без указанной цены и не поглощаемые заклинанием. Это действует как в нормальном облике, так и в облике зверя.`,
    source: {
      id: SOURCE_PHB,
      page: 68,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_DRUID,
  })
)
