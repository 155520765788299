const {
  MAGIC_ENCHANTMENT,
} = require('./../../../../../magicList')
const {
  PARAM_INT,
} = require('./../../../../../paramList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
  SOURCE_UA_2019_09_05_ABERRANT_LURK,
  SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
  SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
} = require('./../../../../../sourceList')
const {
  TIME_ROUND,
} = require('./../../../../../timePeriodList')
const {
  SPELL_MIND_SLIVER,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_MIND_SLIVER,
  name: 'Расщепление разума',
  nameEn: 'Mind Sliver',
  description: `Вы вонзаете дезориентирующий шип психической энергии в разум одного существа, видимого Вами в пределах дистанции.

Цель должна пройти испытание Интеллекта. При провале цель получает 1к6 психического урона. Кроме того, когда она впервые проходит испытание до конца Вашего следующего хода, она должна совершить бросок 1к4 и вычесть выпавшее значение из этого испытания.`,
  highLvlCast: `Урон заклинания увеличивается на 1к6, когда Вы достигаете 5 уровня (2к6), 11 уровня (3к6) и 17 уровня (4к6).`,
  lvl: 0,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 60,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_ROUND, count: 1 },
  effect: {
    savethrowParam: PARAM_INT,
    damage: {
      type: DAMAGE_PSYCHIC,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_TCoE,
      page: 155,
    },
    {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 4,
    },
    {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 7,
    },
    {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 7,
    },
  ],
}
