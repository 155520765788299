const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_CURE_WOUNDS,
  SPELL_DARKVISION,
  SPELL_DRUIDCRAFT,
  SPELL_GUIDANCE,
  SPELL_HOLD_PERSON,
  SPELL_PRODUCE_FLAME,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_THUNDERWAVE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ANIMAL_HANDLING,
  SKILL_NATURE,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_TORTLE,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_AQUAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {ABILITY_HOLD_BREATH} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_TORTLE_DRUID} = require('./../../../../creatureIdList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {PC_CLASS_DRUID} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {tortleDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Тортл-друид',
  nameEn: 'Tortle Druid',
  id: CREATURE_TORTLE_DRUID,
  description: tortleDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_TORTLE,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MTOF,
    page: 231,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_ANIMAL_HANDLING]: PROF_NORMAL,
    [SKILL_NATURE]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  languageList: [
    LANG_AQUAN,
    LANG_COMMON,
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_HOLD_BREATH,
      time: '1 час',
    },
  ],
  spellCast: {
    spellCasterLevel: 4,
    spellCasterClass: PC_CLASS_DRUID,
    spellIdList: [
      SPELL_ANIMAL_FRIENDSHIP,
      SPELL_CURE_WOUNDS,
      SPELL_DARKVISION,
      SPELL_DRUIDCRAFT,
      SPELL_GUIDANCE,
      SPELL_HOLD_PERSON,
      SPELL_PRODUCE_FLAME,
      SPELL_SPEAK_WITH_ANIMALS,
      SPELL_THUNDERWAVE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
    ],
  },
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_QUARTERSTAFF,
    },
    {
      name: 'Панцирная защита',
      description: `Тортл втягивается в панцирь, когда испытывает угрозу. Это даёт ему бонус +4 КД и преимущество на испытания Силы и Телосложения. Пока он внутри панциря, тортл считается лежащим ничком, его скорость равна 0 фт. и не может быть увеличена, он с помехой проходит испытания Ловкости, не может использовать реакцию и единственным действием, которым он может пользоваться, является бонусное действие для вылезания из панциря.`,
    },
  ],
}
