const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {
  CREATURE_FLAMEWRATH,
  CREATURE_RAZERBLAST,
} = require('./../../../../creatureIdList')
const {CR_5} = require('./../../../../crList')
const {DAMAGE_FIRE} = require('./../../../../damageTypeList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {eternalFlameDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  GEAR_SPLINT_ARMOR,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  LANG_COMMON,
  LANG_IGNAN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Разербласт',
  nameEn: 'Razerblast',
  id: CREATURE_RAZERBLAST,
  description: [
    {
      header: 'Разербласт',
      text: `Разербласт бурлит ненавистью к врагам культа огня. Cтихийные повелители разербласта заменили его сердце огненным шаром, который взорвётся, если разербласт умрёт. Разербласты практически не помнят своей жизни до того, как сила cтихийного огня изменила их. Если [яростное пламя](CREATURE:${CREATURE_FLAMEWRATH}) или один из лидеров культа прикажет им вступить в бой, то они выполнят приказ без раздумий, поскольку знают: даже если они проиграют, то погибнут в ослепительном блеске славы.`,
      source: {
        id: SOURCE_PotA,
        page: 205,
      },
    },
    eternalFlameDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_PotA,
    page: 205,
  },
  armor: {
    ac: 17,
    gearId: GEAR_SPLINT_ARMOR,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 16,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  languageList: [
    LANG_COMMON,
    LANG_IGNAN,
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Осколочный взрыв',
      description: `Когда хиты ★СУЩЕСТВО★ опускаются до 0, огненный шар в ★его★ груди взрывается, разрушая ★его★ тело, а осколки ★его★ доспехов разлетаются во все стороны, поражая всех вокруг. Существа, оказавшиеся в пределах 10 футов от ★СУЩЕСТВО★ в момент взрыва, должны пройти испытание Ловкости СЛ 12, получая колющий урон 21 (6к6) при провале или половину этого урона при успехе.`,
    },
    {
      name: 'Обжигающие доспехи',
      description: `Доспехи ★СУЩЕСТВО★ раскалены. Любое существо, которое схватило ★СУЩЕСТВО★, или которое схватил ★он★ ★сам★, получает урон огнём 5 (1к10) в конце хода этого существа.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три рукопашные атаки.`,
    },
    {
      gearId: GEAR_SPEAR,
      attack: {
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 1,
          diceType: 6,
        },
      },
    },
  ],
}
