const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MAGIC_ITEM_POTION_OF_RESISTANCE} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_POTION} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {damageTypeCollection} = require('./../../../../../damageTypeList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

const tableText = list
  .sort(
    ({damageTypeId: damageTypeIdA}, {damageTypeId: damageTypeIdB}) => {
      const {name: A} = damageTypeCollection[damageTypeIdA]
      const {name: B} = damageTypeCollection[damageTypeIdB]

      return A > B
        ? 1
        : -1
    }
  )
  .reduce(
    (text, {damageTypeId, id}, i) => {
      const {name} = damageTypeCollection[damageTypeId]

      return `${text}
| ${i + 1} | ${name} |`
    },
    `
| к${list.length} | Тип урона |
|-----|-----------|`
  )

module.exports = {
  id: MAGIC_ITEM_POTION_OF_RESISTANCE,
  name: `Зелье сопротивления`,
  nameEn: `Potion of Resistance`,
  type: MGC_TYPE_POTION,
  rarity: MGC_RARITY_UNCOMMON,
  isConsumable: true,
  description: `Когда Вы выпиваете это зелье, Вы получаете сопротивление к одному виду урона на 1 час.

Мастер сам выбирает вид урона или определяет его случайным образом из приведённых ниже вариантов. 

${tableText}`,
  genderId: GENDER_MIDDLE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 166,
  },
}
