const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  CREATURE_FUMING_SPIRIT,
  CREATURE_MIRTHFUL_SPIRIT,
  CREATURE_TRICKSY_SPIRIT,
} = require('./../../../../../creatureIdList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_SUMMON_FEY,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SUMMON_FEY,
  name: 'Призыв духа феи',
  nameEn: 'Summon Fey',
  description: `Вы призываете духа феи. Дух проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите настроение — [вспыльчивое](CREATURE:${CREATURE_FUMING_SPIRIT}), [весёлое](CREATURE:${CREATURE_MIRTHFUL_SPIRIT}) или [игривое](CREATURE:${CREATURE_TRICKSY_SPIRIT}). Существо выглядит как двуногая фигура, сплетённая из выбранной стихии, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.
    
Существо дружественно к Вам и Вашим спутникам. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно совершает действие Уклонение и передвигается так, чтобы избегать опасности.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
  createdCreatureId: [
    CREATURE_FUMING_SPIRIT,
    CREATURE_MIRTHFUL_SPIRIT,
    CREATURE_TRICKSY_SPIRIT,
  ],
  lvl: 3,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `позолоченный цветок стоимостью не менее 300 золотых`,
  hasCost: true,
  duration: { timeId: TIME_HOUR, count:  1},
  needConcentration: true,
  source: {
    id: SOURCE_TCoE,
    page: 161,
  },
}
