const arrify = require ('arrify')

const getParentCatIdList = (cat, catCollection, log) => {
  let parentCatIdList = cat.parentCatId
    ? arrify(cat.parentCatId)
    : []

  if (cat.parentCatId) {
    const parentList = arrify(cat.parentCatId).map(
      parentCatId => catCollection[parentCatId]
    )

    parentCatIdList = parentCatIdList.concat(
      ...parentList.map(parentCat => getParentCatIdList(parentCat, catCollection, true))
    )
  }

  return parentCatIdList
}

module.exports = getParentCatIdList
