const {CAT_EQUIPMENT} = require('./../../../gearCategoryList')
const {DAMAGE_BLUDGEONING} = require('./../../../damageTypeList')
const {GEAR_SHATTERSTICK} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_BGDIA} = require('./../../../sourceList')

module.exports = {
  id: GEAR_SHATTERSTICK,
  genderId: GENDER_FEMALE,
  name: 'Дрожь-палка',
  nameByCase: {
    nominative: 'дрожь-палка',
    genitive: 'дрожь-палки',
    accusative: 'дрожь-палку',
    instrumental: 'дрожь-палкой',
  },
  nameEn: 'Shatterstick',
  description: `Эта немагическая палка длинной 1 фут и весом 4 фунта выкована из синеватого дьявольского железа на Кании, восьмом круге Девяти Преисподних.

Если погрузить её в землю или вбить в сплошной камень, палка будет 1 минуту испускать сейсмическую вибрацию в радиусе 20 футов вокруг себя, сотрясая землю в этой области.

По окончании эффекта дрожь-палка разломится и станет бесполезной, а все строения в пределах 20 футов от неё получат 35 (10к6) дробящего урона.`,
  weight: 4,
  length: 1,
  damageType: DAMAGE_BLUDGEONING,
  damage: {
    diceType: 6,
    diceCount: 10,
  },
  category: CAT_EQUIPMENT,
  source: {
    id: SOURCE_BGDIA,
    page: 99,
  },
}
