const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_10,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ABERRATION,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_LE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_SWIM,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_DEEP_SPEECH,
    LANG_TELEPATHY,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_HISTORY,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_PHANTASMAL_FORCE,
  } = require('./../../../../spellIdList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    ABILITY_AMPHIBIOUS,
    ABILITY_DETECT,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_ABOLETH,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Аболет',
  nameEn: 'Aboleth',
  id: CREATURE_ABOLETH,
  description: [
    `До прихода богов аболеты скрывались в первичных океанах и подземных озёрах. Они захватили контроль над развивающимися жизненными формами в царстве смертных, превратив их в своих рабов. Их власть уподобила их богам. Затем появились истинные боги, разбили империю аболетов и освободили их рабов.

Но аболеты этого никогда не забывали.`,
    {
      header: 'Вечная память',
      text: `У аболетов безупречная память.

Они передают знания и опыт из поколения в поколения. Поэтому обида за поражение от рук богов прекрасно сохранилась в их разуме. Разум аболетов это сокровищница древних знаний, отсылающих к доисторическим моментам, и помнят они это очень ясно. Они терпеливо и хитро плетут свои заговоры на протяжении эпох. Мало кто может постичь глубину этих планов кроме самих аболетов.`,
      source: {
        id: SOURCE_MM,
        page: 13,
      },
    },
    {
      header: 'Боги озёр',
      text: `Аболеты обустраиваются в водных средах, в том числе в безднах океанов, глубоких озёрах и Стихийном Плане Воды. В этих областях и землях, примыкающим к них, аболеты подобны богам, требующим поклонения и повиновения от своих подданных. Пожирая других существ, аболеты обогащаться опытом и знаниями своей добычи, которые они добавляют к вечным воспоминаниям. Аболеты используют свои телепатические способности, чтобы читать мысли существ и узнавать их желания. Они используют эти знания, чтобы обеспечить лояльность существ в обмен на выполнение этих желаний. В своём логове аболет может, с помощью своих сил, подменить чувства своих последователей, внушая им иллюзию обещанного вознаграждения.`,
      source: {
        id: SOURCE_MM,
        page: 13,
      },
    },
    {
      header: 'Враги богов',
      text: `Крушение власти аболетов навсегда осталось в их вечных воспоминаниях, так как аболеты никогда по-настоящему не умирают. Если организм аболета уничтожается, его дух возвращается на Стихийный План Воды, где в течение нескольких дней или месяцев для него создаётся новое тело. Главная цель аболетов — свергнуть богов и вернуть контроль над миром. Скрываясь на протяжении эпох, аболеты разрабатывают свой план для идеального исполнения.`,
      source: {
        id: SOURCE_MM,
        page: 13,
      },
    },
    {
      header: 'Логово аболета',
      text: `Логова аболетов обычно можно найти в подземных озёрах или скалистых глубинах океана, часто в окружении руин, оставшихся от древних городов аболетов. Большую часть времени аболеты проводят под водой, поднимаясь на поверхность, чтобы пообщаться с посетителями или безумными последователями.

# Действия логова

При сражении в своём логове аболет может воззвать к окружающей магии и использовать действие логова. При значении инициативы «20» (проигрывает при ничьей) аболет совершает действие логова, чтобы вызвать один из следующих эффектов

* Аболет сотворяет[воображаемую силу](SPELL:${SPELL_PHANTASMAL_FORCE}) (компоненты не нужны) на любое количество видимых им в пределах 60 футов существ. Пока аболет поддерживает концентрацию на эффекте, он не может совершать другие действия логова. Если цель успешно прошла испытание или эффект для неё закончился, цель получает иммунитет к этому действию логова этого аболета на ближайшие 24 часа, хотя может решить стать целью эффекта.
* В пределах 90 футов от аболета возникает водоворот. Все существа на земле в пределах 20 футов от такого водоворота должны пройти испытание Силы СЛ 14, иначе они будут утянуты на расстояние до 20 футов в воду и сбиты с ног. Аболет не может использовать это действие логова снова, пока не использует другое.
* Вода в логове аболета магическим образом становится проводником его ярости. Аболет может выбрать целью любое количество видимых им в воде в пределах 60 футов существ. Цель должна пройти испытание Мудрости СЛ 14, иначе получит урон психической энергией 7 (2к6). Аболет не может использовать это действие логова снова, пока не использует другое.

# Местные эффекты

Область вокруг логова аболета изменяется из-за присутствия этого существа. Обычно это один или несколько из следующих эффектов:

* Подземные поверхности в пределах 1 мили от логова становятся скользкими и влажными. Это труднопроходимая местность.
* Источники воды в пределах 1 мили от логова становятся загрязнёнными. Врагов аболета, выпивших такой воды, стошнит через нескольких минут.
* Аболет может действием создать иллюзорный образ самого себя в пределах 1 мили от логова. Копия может появиться в любой области, которую аболет видел ранее, или в области, которую видит очарованное аболетом существо. Созданный образ длится, пока аболет сохраняет концентрацию, как если бы он концентрировался на заклинании. Хотя образ нематериален, он выглядит, звучит и двигается как аболет. Аболет может чувствовать, говорить и использовать телепатию в точке, где находиться иллюзия, как если бы сам находился в этом месте. Если образ получает урон, он исчезает. Со смертью аболета первые два эффекта исчезают за 3к10 дней.`,
    },
  ],
  note: {
    text: `Аболеты могут быть старше богов. Получается, что до появления божеств эти кошмарные твари формировали вселенную? Страшно подумать`,
    author: `Вакир Зех’р, гитцерайский философ и автор книги «Дальний предел: реальный и нет»`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_LE,
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 15,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_HISTORY]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_10,
  source: {
    id: SOURCE_MM,
    page: 13,
  },
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Слизистое облако',
      description: 'Находясь под водой, ★СУЩЕСТВО★ ★окружён★ слизью. Существа, которые касаются ★СУЩЕСТВО★ или попадают по нему рукопашной атакой, находясь в пределах 5 футов от ★него★, должны пройти испытание Телосложения СЛ 14. При провале существо заболевает на 1к4 часа. Больные существа могут дышать только под водой.',
    },
    {
      name: 'Зондирующая телепатия',
      description: 'Если существо общается телепатически с ★СУЩЕСТВО★, и ★СУЩЕСТВО★ при этом видит это существо, он может узнать самые потаённые желания существа.',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: '★СУЩЕСТВО★ совершает три атаки _Щупальцем_.',
    },
    {
      name: 'Щупальце',
      description: `Если цель — существо, она должна пройти испытание Телосложения СЛ 14, иначе станет заболевшей. Заболевание не проявляется в течение 1 минуты, и может быть удалено любой магией, лечащей болезни. Через 1 минуту кожа больного существа становиться прозрачной и склизкой, существо не может восстанавливать хиты кроме как будучи под водой, и болезнь можно удалить только полным исцелением или другим лечащим болезни заклинанием как минимум 6 уровня. Если существо находится вне водоёма, оно получает урон кислотой 6 (1к12) каждые 10 минут, если вода не попала на кожу до истечения 10 минут.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Порабощение',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 30 футов от себя. Цель должна пройти испытание Мудрости СЛ 14, иначе она станет магически очарованной. Эффект оканчивается со смертью ★СУЩЕСТВО★, либо когда ★он★ перестанет быть на одном с целью плане существования. Очарованная цель находится под контролем ★СУЩЕСТВО★, и не может совершать реакции. Цель и ★СУЩЕСТВО★ могут общаться телепатически на любой дистанции.

Каждый раз, когда очарованная цель получает урон, она может повторить испытание. При успехе эффект заканчивается. Не более чем один раз в 24 часа цель может также повторить испытание, но при этом она должна находиться как минимум в 1 миле от аболета.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    ABILITY_DETECT,
    {
      name: 'Удар хвостом',
      description: '★СУЩЕСТВО★ совершает одну атаку _Хвостом_.',
    },
    {
      name: 'Психическое истощение',
      cost: 2,
      description: `Одно существо, которое ★очаровал★ ★СУЩЕСТВО★ получает урон психической энергией 10 (3к6), и ★СУЩЕСТВО★ восстанавливает хиты, равные урону, который получило существо.`,
    },
  ],
  genderId: GENDER_MALE,
}
