const {
  SOURCE_BGDIA,
} = require('./../../../../sourceList')
const {
  CR_8,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONADAMANTINE_NONSILVER_ATTACKS,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_BERSERK,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_IMMUTABLE_FORM,
} = require('./../../../../creatureAbilityList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_FIENDISH_FLESH_GOLEM,
  CREATURE_NIGHT_HAG,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Голем из исчадий',
  nameAlt: 'Мясной голем из бестий',
  nameEn: 'Fiendish Flesh Golem',
  id: CREATURE_FIENDISH_FLESH_GOLEM,
  description: {
    header: 'Мясной голем из бестий',
    text: `Этот крупный мясной голем сшит из частей тел дьяволов, демонов, юголотов и прочих исчадий. Поскольку эти части могли принадлежать разным существам и быть скомбинированными по-разному, не бывает двух одинаковых големов. [Ночные карги](CREATURE:${CREATURE_NIGHT_HAG}) владеют секретом создания таких големов и редко с кем им делятся.

У некоторых таких мясных големов есть крылья. Однако магия, создавшая их, позволяет летать и парить даже без крыльев.`,
    source: {
      id: SOURCE_BGDIA,
      page: 238,
    },
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_BGDIA,
    page: 238,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 20,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
  ],
  immunityList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_NONADAMANTINE_NONSILVER_ATTACKS,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_8,
  featureList: [
    {
      id: ABILITY_BERSERK,
      hp: 100,
      calmText: `Создатель ★СУЩЕСТВО★, находящийся в пределах 60 футов от ★ставшего★ берсерком ★СУЩЕСТВО★, может попытаться успокоить ★его★, отдавая чёткие команды убедительным голосом. ★СУЩЕСТВО★ должен слышать создателя, который действием совершает проверку Харизмы (Убеждение) СЛ 15. При успехе ★СУЩЕСТВО★ перестаёт быть берсерком. Если ★СУЩЕСТВО★ получает урон, когда у ★него★ ▲hp▲ или меньше хитов, ★он★ может снова стать берсерком.`,
    },
    ABILITY_MAGIC_WEAPONS,
    ABILITY_IMMUTABLE_FORM,
    {
      name: 'Поглощение электричества',
      description: `Каждый раз, когда ★СУЩЕСТВО★ ★должен★ получить урон электричеством, ★он★ не получает урон, и вместо этого восстанавливает число хитов, равное причиняемому урону электричеством.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает два _Удара_.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
  ],
}
