const {CONDITION_BLINDED} = require('./../../../../../conditionList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SOURCE_PHB} = require('./../../../../../sourceList')
const {SPELL_HUNGER_OF_HADAR} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')

module.exports = {
  id: SPELL_HUNGER_OF_HADAR,
  name: 'Голод Хадара',
  nameEn: `Hunger of Hadar`,
  description: `Вы открываете врата в межзвёздную тьму, область, населённую неизвестными кошмарами.

Появляется сфера тьмы и холода с радиусом 20 футов, с центром на точке в пределах дистанции, существующая, пока активно заклинание. Эта пустота заполнена какофонией тихих шёпотов и хлюпающих шумов, которые слышно в пределах 30 футов. Никакой свет, ни магический, ни обычный, не может осветить эту местность, и существа, полностью находящиеся в этой области, ослеплены.

Пустота создаёт искажения в ткани пространства, и её местность является труднопроходимой.

Все существа, начинающие ход в этой местности, получают урон холодом 2к6. Все существа, оканчивающие ход в этой местности, должны пройти испытание Ловкости, иначе получают урон кислотой 2к6, так как к ним прикасаются бледные потусторонние щупальца.`,
  lvl: 3,
  magicSchoolId: MAGIC_CONJURATION, //
  range: 150,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `маринованное щупальце осьминога`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: [
    {
      condition: CONDITION_BLINDED,
      damage: {
        type: DAMAGE_COLD,
        diceCount: 2,
        diceType: 6,
      },
    },
    {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_ACID,
        diceCount: 2,
        diceType: 6,
      },
    },
  ],
  source: {
    id: SOURCE_PHB,
    page: 221,
  },
}
