const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_ERUPTING_EARTH} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ERUPTING_EARTH,
  name: 'Извержение земли',
  nameEn: 'Erupting Earth',
  description: `Выберите точку на поверхности земли, видимую в пределах дистанции заклинания. Фонтан вывороченной земли и камней вырывается в 20-футовом кубе и центром в этой точке. Все существа в этой области должны пройти испытание Ловкости. При провале существо получает дробящий урон 3к12, а при успехе — половину урона.

Кроме того, земля в области действия заклинания становится труднопроходимой местностью, пока её не расчистят. Расчистка квадрата с длиной стороны 5 футов вручную займёт не менее 1 минуты.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, то урон увеличивается на 1к12 за каждый круг ячейки выше 3.`,
  lvl: 3,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кусочек обсидиана`,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_BLUDGEONING,
      halfOnSuccess: true,
      diceCount: 2,
      diceType: 12,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 153,
    },
    {
      id: SOURCE_EE,
      page: 17,
    },
  ],
}
