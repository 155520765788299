const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_UNARMORED_DEFENSE} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {DAMAGE_POISON} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {blackEarthDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_HELLENRAE,
  CREATURE_MARLOS_URNRAYLE,
} = require('./../../../../creatureIdList')
const {
  SKILL_ACROBATICS,
  SKILL_ATHLETICS,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_TERRAN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_EVASION,
  ABILITY_UNARMORED_DEFENSE,
  ABILITY_UNARMORED_MOVEMENT,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_BLINDED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')

module.exports = {
  name: 'Хеленрей',
  nameEn: 'Hellenrae',
  id: CREATURE_HELLENRAE,
  hasSpoilers: true,
  description: [
    {
      header: 'Хеленрей',
      text: `Первый заместитель [Марлоса Урнрейла](CREATURE:${CREATURE_MARLOS_URNRAYLE}), Хеленрей потеряла своё зрение, ещё будучи ребенком. Обозлённую на жизнь сироту приютил монастырский орден и обучил её использовать остальные чувства, чтобы компенсировать её слепоту. К несчастью, она довела учение монахов о самоотречении и полном очищении разума до крайности. Хеленрей начала искать способ наполнить себя дремлющей тёмной силой, которую чувствовала в земле и скалах вокруг.

Хеленрей выглядит как суровая, неулыбчивая женщина, которая обычно носит серые одежды и золотую маску горгульи без отверстий для глаз. Она презирает слабость в любой форме и поэтому ненавидит почти всех: даже остальных служителей Чёрной Земли она переносит с трудом.`,
      source: {
        id: SOURCE_PotA,
        page: 203,
      },
    },
    blackEarthDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_PotA,
    page: 203,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_UNARMORED_DEFENSE,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_TERRAN,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_EVASION,
    ABILITY_UNARMORED_DEFENSE,
    {
      id: ABILITY_UNARMORED_MOVEMENT,
      speed: 20,
    },
    {
      name: 'Ошеломляющий удар',
      nameEn: 'Stunning Strike',
      restore: 5,
      description: `Если ★СУЩЕСТВО★ попадает по другому существу рукопашной атакой оружием, цель должна пройти испытание Телосложения СЛ 13, иначе станет Ошеломлённой до конца следующего хода ★СУЩЕСТВО★.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три рукопашные атаки.`,
    },
    {
      name: 'Безоружный удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
