const {
    SOURCE_BGDIA,
  } = require('./../../../../sourceList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
  } = require('./../../../../targetList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_1_4,
  } = require('./../../../../crList'),
  {
    SIZE_TINY,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_FIEND,
  } = require('./../../../../creatureTypeIdList'),
  {
    LANG_ABYSSAL,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_FLY,
  } = require('./../../../../speedList'),
  {
    CONDITION_BLINDED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_PIERCING,
    DAMAGE_POISON,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_FIND_FAMILIAR,
  } = require('./../../../../spellIdList'),
  {
    CREATURE_ABYSSAL_CHICKEN,
    CREATURE_RAVEN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Демонская курица',
  nameAlt: 'Курица Бездны',
  nameEn: 'Abyssal Chicken',
  id: CREATURE_ABYSSAL_CHICKEN,
  description: `Демонские курицы плотоядные, норовистые и очень вкусные падальщики родом из Бездны. Хлопая кожаными крыльями, демонская курица одновременно отпугивает хищников и бегает быстрее. А на вкус она как жирная курица.

С разрешения Мастера, персонаж, использующий [Поиск фамильяра](SPELL:${SPELL_FIND_FAMILIAR}), может призвать демонскую курицу вместо [ворона](CREATURE:${CREATURE_RAVEN}).`,
  sizeType: SIZE_TINY,
  couldBeFamiliar: true,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_BGDIA,
    page: 97,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: {
      value: 30,
      comment: 'Плохая летунья',
    },
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 5,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
      isBlindOutside: true,
    },
  ],
  languageList: [
    {
      id: LANG_ABYSSAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Плохая летунья',
      description: `★СУЩЕСТВО★ падает в конце хода, если ★он★ летит и держится в воздухе только благодаря своей скорости полёта.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну — _Клювом_ и одну — _Когтями_.`,
    },
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
