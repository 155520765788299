module.exports = [
  {
    text: `Если услышите плач ребёнка в переулке, идите другим путём. Вот вам мой совет`,
    author: `Эндрот Нэг, капрал городской стражи Глубоководья`,
  },
  {
    text: `Карта, которую мы нашли, показала вход в тайник с сокровищами короля–священника прямо в середине разрушенной части города.

Мы без проблем приближались к нашей цели, но, когда мы достигли сгоревшего здания, внезапная какофония зазвучала вокруг нас. Птичий крик, кошачье шипение, собачий лай. Лидда погнала нас назад, к более безопасным улицам города.

Только когда мы вернулись в районы, патрулируемые стражей, она объяснила, что шум означал, что бескрылый народ занял этот район, и что нарушение границы повлечет смерть.`,
    author: 'Гимбл, Заметки Охотника за Сокровищами',
  }
]
