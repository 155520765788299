const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_FTD,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_FIZBAN_S_PLATINUM_SHIELD,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FIZBAN_S_PLATINUM_SHIELD,
  name: 'Физбанов платиновый щит',
  nameEn: 'Fizban’s Platinum Shield',
  description: `Вы создаёте поле серебристого света, окружающего выбранное Вами в пределах дистанции существо. Поле испускает тусклый свет в пределах 5 футов.

На последующих ходах Вы можете бонусным действием сдвигать поле на другое существо в пределах 60 футов от поля.

Существо, защищаемое полем, получает следующие преимущества.

# Укрытие

Существо получает укрытие на половину.

# Сопротивление урону

Существо получает сопротивление к урону кислотой, огнём, холодом, электричеством и ядом.

# Увёртливость

Если существо попадает под действие эффекта, требующего пройти испытание Ловкости, чтобы получить только половину урона, вместо этого при успехе существо не получает вовсе никакого урона, и получает только половину урона при провале.`,
  lvl: 6,
  magicSchoolId: MAGIC_ABJURATION,
  note: {
    text: `Учёные давно спорят о том, следует ли приписывать это заклинание мне. Честно говоря, я так много всего создал, что уже и не помню`,
    author: `Физбан`,
  },
  range: 60,
  componentIdList: [
    CAST_SOMATIC,
    CAST_MATERIAL,
    CAST_VERBAL,
  ],
  materialText: `чешуйка платинового дракона, стоимостью не менее 500 зм`,
  hasCost: true,
  duration: { timeId: TIME_MINUTE, count: 1 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  needConcentration: true,
  source: {
    id: SOURCE_FTD,
    page: 20,
  },
}
