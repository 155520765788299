const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {ABILITY_CHARGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_RHINOCEROS} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Носорог',
  nameEn: 'Rhinoceros',
  id: CREATURE_RHINOCEROS,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 335,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 15,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Боданием',
      damageText: '9 (2к8) дробящего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 15, иначе будет сбита с ног.',
    },
  ],
  actionList: [
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
