const {PC_CLASS_BARD} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_XGTE,
} = require('./../../sourceList')

const musicalToolDescriptionList = [
  {
    header: 'Музыкальные инструменты',
    text: `Если Вы владеете определённым музыкальным инструментом, Вы можете добавлять бонус мастерства к проверкам характеристик, совершённым во время игры на нём. [Бард](PC_CLASS:${PC_CLASS_BARD}) может использовать музыкальный инструмент в качестве фокусировки для заклинаний.`,
    source: {
      id: SOURCE_PHB,
      page: 154,
    },
  },
  {
    header: 'Музыкальные инструменты',
    text: `Владение музыкальным инструментом означает, что Вы знакомы с приёмами игры на нём. Вы также знаете несколько песен, как правило, исполняющихся на этом инструменте.

**История.** Ваш опыт помогает вам вспомнить исторические факты, связанные с вашим инструментом.

**Выступление.** Ваши постановки заметно улучшаются, когда Вы включаете в них игру на инструменте.

**Сочинение мелодии.** Как часть длинного отдыха Вы можете сочинить новую мелодию и слова для песни, исполняемой на вашем инструменте. Вы можете использовать эту способность, чтобы произвести впечатление на дворянина или распространять скандальные слухи с легко запоминающейся мелодией.

| Действие                 | СЛ |
|--------------------------|----|
| Опознать мелодию         | 10 |
| Сымпровизировать мелодию | 20 |
`,
    source: {
      id: SOURCE_XGTE,
      page: 85,
    },
  },
]

module.exports = musicalToolDescriptionList
