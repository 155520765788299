const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_VENGEANCE} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  SPELL_BANE,
  SPELL_BANISHMENT,
  SPELL_DIMENSION_DOOR,
  SPELL_HASTE,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HUNTERS_MARK,
  SPELL_MISTY_STEP,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_SCRYING,
} = require('./../../../spellIdList')
const {
  FEATURE_AVENGING_ANGEL,
  FEATURE_CHANNEL_DIVINITY_ABJURE_ENEMY,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CHANNEL_DIVINITY_VOW_OF_ENMITY,
  FEATURE_OATH_OF_VENGEANCE_SPELLS,
  FEATURE_RELENTLESS_AVENGER,
  FEATURE_SOUL_OF_VENGEANCE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_VENGEANCE_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    lvl: 3,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_BANE,
      SPELL_BANISHMENT,
      SPELL_DIMENSION_DOOR,
      SPELL_HASTE,
      SPELL_HOLD_MONSTER,
      SPELL_HOLD_PERSON,
      SPELL_HUNTERS_MARK,
      SPELL_MISTY_STEP,
      SPELL_PROTECTION_FROM_ENERGY,
      SPELL_SCRYING,
    ],
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_ABJURE_ENEMY,
    name: `Божественный канал: Порицание врага`,
    nameEn: `Channel Divinity: Abjure Enemy`,
    lvl: 3,
    text: `Вы действием демонстрируете свой священный символ и произносите обличающую молитву, используя [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}).

Выберите одно видимое Вами в пределах 60 футов существо. Оно должно пройти испытание Мудрости, если не обладает иммунитетом к испугу. Исчадия и нежить совершают это испытание с помехой.

При провале существо становится испуганным на 1 минуту, или пока не получит урон. Пока существо испугано, его скорость равна 0, и оно не получает никаких бонусов к скорости.

При успехе скорость существа на 1 минуту, или пока оно не получает урон, уменьшается вдвое.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_VOW_OF_ENMITY,
    name: `Божественный канал: Обет вражды`,
    nameEn: `Channel Divinity: Vow of Enmity`,
    lvl: 3,
    text: `Вы можете бонусным действием произнести слова обета вражды по отношению к видимому Вами в пределах 10 футов существу, используя [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}).

В течение 1 минуты, пока его хиты не опускаются до 0, или оно не потеряет сознание, Вы совершаете броски атаки по нему с превосходством. `,
  },
  {
    id: FEATURE_RELENTLESS_AVENGER,
    name: `Неумолимый мститель`,
    nameEn: `Relentless Avenger`,
    lvl: 7,
    text: `Ваше сверхъестественное внимание помогает Вам предотвратить бегство врага.

Если Вы попадаете по существу провоцированной атакой, Вы можете частью этой реакции переместиться на расстояние, равное половине своей скорости. Это перемещение не провоцирует атаки.`,
  },
  {
    id: FEATURE_SOUL_OF_VENGEANCE,
    name: `Душа мести`,
    nameEn: `Soul of Vengeance`,
    lvl: 15,
    text: `Суть вашего [Обета вражды](FEATURE:${FEATURE_CHANNEL_DIVINITY_VOW_OF_ENMITY}) даёт Вам большую власть над врагом.

Когда существо, находящееся под действием Вашего [Обета вражды](FEATURE:${FEATURE_CHANNEL_DIVINITY_VOW_OF_ENMITY}), совершает атаку, Вы можете реакцией совершить атаку рукопашным оружием по этому существу, если оно находится в пределах досягаемости.`,
  },
  {
    id: FEATURE_AVENGING_ANGEL,
    name: `Мстящий ангел`,
    nameEn: `AVENGING ANGEL`,
    lvl: 20,
    text: `Вы можете принять облик ангельского мстителя.

Вы проходите трансформацию действием, на 1 час получая следующие преимущества:

* Из вашей спины вырастают крылья, дающие Вам скорость полёта 60 футов.
* Вы излучаете ауру угрозы в радиусе 30 футов. Когда враг впервые входит в ауру или начинает в ней своей ход во время сражения, он должен пройти испытание Мудрости, иначе он станет испуганным на 1 минуту, или пока не получит урон. Броски атаки по этому испуганному существа совершаются с преимуществом.

Использовав эту способность, Вы не сможете использовать её вновь, пока не завершите длинный отдых.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_VENGEANCE,
    source: {
      id: SOURCE_PHB,
      page: 89,
    },
  })
)

