const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_0,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_PLANT,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SENSE_BLIND_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_BLINDED,
  CONDITION_DEAFENED,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  mushroomsDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FALSE_APPEARANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_SHRIEKER,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Визгун',
  nameEn: 'Shrieker',
  id: CREATURE_SHRIEKER,
  description: [
    `Визгуны — это грибы размером с человека, которые издают пронзительные вопли, отпугивая потревоживших их существ. Другие существа могут использовать их как сигнал о приближении потенциальной жертвы, а различные разумные расы Подземья разводят визгунов на окраинах своих поселений, чтобы отвадить непрошенных гостей.`,
    mushroomsDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 74,
  },
  armor: 5,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 0,
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 1,
    [PARAM_CON]: 10,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 3,
    [PARAM_CHA]: 1,
  },
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_BLINDED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
      isBlindOutside: true,
    },
  ],
  cr: CR_0,
  actionList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного гриба`,
    },
    {
      name: 'Визг',
      description: `Если в пределах 30 футов от ★СУЩЕСТВО★ появляется яркий свет или существо, ★СУЩЕСТВО★ испускает визг, слышимый на расстоянии 300 футов. ★СУЩЕСТВО★ продолжает кричать, пока не пройдёт 1к4 раунда после того, как источник беспокойства окажется за пределами ★его★ восприятия.`,
    },
  ],
  genderId: GENDER_MALE,
}
