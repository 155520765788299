const {
  SPELL_ALTER_SELF,
  SPELL_CLAIRVOYANCE,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISPEL_MAGIC,
  SPELL_FEAR,
  SPELL_FIRE_STORM,
  SPELL_INVISIBILITY,
  SPELL_MASS_SUGGESTION,
  SPELL_SUGGESTION,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_ABYSSAL,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_YUGOLOTH,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_13} = require('./../../../../crList')
const {CREATURE_ULTROLOTH} = require('./../../../../creatureIdList')
const {GEAR_LONGSWORD} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {yugolothDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Ультролот',
  nameEn: 'Ultroloth',
  id: CREATURE_ULTROLOTH,
  description: [
    `Ультролоты командуют армиями юголотов в Войне Крови. Ультролот выглядит как худощавый серокожий гуманоид с вытянутой головой. Он безлик, если не считать двух овальных глаз. Эти глаза могут становиться сверкающими резервуарами света, который сковывает других существ, заставляет трепетать и делает беспомощными.

Ультролоты постоянно разрабатывают планы по дальнейшему увеличению личной власти, и не прочь вцепиться друг другу в горло. В те моменты, когда они не наняты сражаться в Войне Крови, ультролоты во главе отрядов юголотов бродят по планам, действуя как криминальные главари или предводители злых наёмников.

Имея репутацию жестоких существ, ультролоты обычно командуют в бою своими подчинёнными, а сами при этом не сражаются. Младшие юголоты знают своё место, и когда их призывает юголот, они не требуют награды за службу.`,
    ...yugolothDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_YUGOLOTH,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 319,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 18,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 19,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
    CONDITION_FRIGHTENED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_13,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_ALTER_SELF,
          SPELL_CLAIRVOYANCE,
          SPELL_DARKNESS,
          SPELL_DETECT_MAGIC,
          SPELL_DETECT_THOUGHTS,
          SPELL_DISPEL_MAGIC,
          {
            id: SPELL_INVISIBILITY,
            comment: `только на себя`,
          },
          SPELL_SUGGESTION,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DIMENSION_DOOR,
          SPELL_FEAR,
          SPELL_WALL_OF_FIRE,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_FIRE_STORM,
          SPELL_MASS_SUGGESTION,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ может использовать _Гипнотический взгляд_ и совершает три рукопашные атаки.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      name: 'Гипнотический взгляд',
      description: `Глаза ★СУЩЕСТВО★ наливаются опалесцирующим светом, и ★он★ нацеливается на одно существо, которое видит в пределах 30 футов от себя. Если цель видит ★СУЩЕСТВО★, она должна пройти испытание Мудрости СЛ 17 от этой магии, иначе станет очарованной до конца следующего хода ★СУЩЕСТВО★. Очарованная цель является ошеломлённой. Если испытание пройдено, цель получает иммунитет к взгляду этого ★СУЩЕСТВО★ на следующие 24 часа.`,
    },
    {
      id: ABILITY_TELEPORT,
      range: 60,
    },
  ],
  genderId: GENDER_MALE,
}
