const {SOURCE_MTOF} = require('./../../sourceList')

module.exports = {
  header: 'Стихийные мирмидоны',
  text: `Стихийные мирмидоны — элементали, призванные и связанные магией в ритуально созданных пластинчатых доспехах. В такой форме они не помнят о своём прежнем существовании в форме свободного элементаля. Они существуют только для того, чтобы следовать приказам своих хозяев.`,
  source: {
    id: SOURCE_MTOF,
    page: 246,
  },
}
