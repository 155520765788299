const arrify = require('arrify')

const {damageTypeCollection} = require('./../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../magicItemTypeList')
const {gearCollection} = require('./../../../gearList')
const {
  SOURCE_ERFTLW,
  SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
} = require('./../../../sourceList')

const generateDamageRollText = require('./generateDamageRollText')
const siblingIdList = require('./siblingIdList')

const imbuedWoodFocusGenerator = (
  {
    damageTypeIdList,
    gearId,
    id,
    name: { genitive: name },
    nameEn,
  }
) => {
  const damageTypeText = generateDamageRollText(damageTypeIdList)
  const damageRollText = arrify(damageTypeIdList)
    .map(
      damageTypeId => damageTypeCollection[damageTypeId].fullNameByCase.genitive
    )
    .join(' или ')

  const {
    name: gearName,
    nameEn: gearNameEn,
    genderId: gearGenderId,
  } = gearCollection[gearId]

  const genderText = gearGenderId === GENDER_FEMALE ? 'её' : 'его'
  const cutText = gearGenderId === GENDER_FEMALE ? 'вырезанная' : 'вырезанный'

  return {
    id,
    name: `${gearName} из ${name}`,
    nameEn: `Imbued Wood Focus: ${nameEn} ${gearNameEn}`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    gearType: gearId,
    attunement: true,
    description: `Деревянная фокусировка из ${name} — это [${gearName}](GEAR:${gearId}), ${cutText} из ${name}, чья древесина пропиталась экстрапланарной энергией. Если Вы заклинатель, то можете использовать ${genderText} как заклинательную фокусировку.

Если Вы сотворяете заклинание, наносящее **${damageTypeText}**, используя ${genderText} как заклинательную фокусировку, то получаете бонус **+1 к одному броску ${damageRollText}** этого заклинания.`,
    genderId: GENDER_FEMALE,
    siblingIdList,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 277,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 1,
      },
    ],
  }
}

module.exports = imbuedWoodFocusGenerator
