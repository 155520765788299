const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {CONDITION_PRONE} = require('./../../../../../conditionList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SPELL_CONTROL_WINDS} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_CONTROL_WINDS,
  name: 'Власть над ветрами',
  nameEn: 'Control Winds',
  description: `Вы можете контролировать ветер в 100-футовом кубе, который Вы можете видеть в пределах дистанции. Выберите в момент произнесения заклинания один из нижеперечисленных эффектов. Этот эффект продолжается до тех пор, пока действует заклинание, если Вы действием не переключитесь на другой эффект. Вы также можете действием временно отменить эффект или вновь возобновить его.

# Шквал

Ветер усиливается, постоянно дуя горизонтально в направлении, которое Вы выбрали. Вы можете выбрать интенсивность ветра: спокойный, умеренный или сильный. Если ветер умеренный или сильный, то дальнобойные атаки оружием, траектория которых входит в куб, выходит из куба или проходит через него, совершаются с помехой. Все существа, идущие против сильного ветра, расходуют дополнительный фут передвижения за каждый пройдённый фут.

# Нисходящий поток

Вы создаёте постоянный поток сильного ветра, который дует вниз от верхней части куба. Атаки дальнобойным оружием совершаются с помехой, если их траектория проходит через область действия заклинания или целью является существо в кубе. Если летающее существо впервые за ход оказывается в этой области или начинает свой ход в ней, оно должно пройти испытание Силы, сбиваясь с ног при провале.

# Восходящий поток

Вы создаёте постоянный поток ветра, который дует от низа куба вверх. Существа, которые падают на землю внутри куба, получают лишь половину урона от падения. Если существо в кубе совершает вертикальный прыжок, высота прыжка увеличивается на 10 футов.`,
  lvl: 5,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 300,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_STR,
    condition: CONDITION_PRONE,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 149,
    },
    {
      id: SOURCE_EE,
      page: 15,
    },
  ],
}
