const {
  MAGIC_ENCHANTMENT,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  PARAM_WIT,
} = require('./../../../../../paramList')
const {
  CONDITION_INCAPACITATED,
  CONDITION_PRONE,
} = require('./../../../../../conditionList')
const {
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  name: 'Ташин жуткий смех',
  nameAlt: [
    'Жуткий смех Таши',
    'Отвратительный смех Таши',
  ],
  nameEn: `Tasha’s Hideous laughter`,
  nameEnAlt: `Hideous Laughter`,
  description: `Существо на Ваш выбор, видимое в пределах дистанции, воспринимает всё невероятно смешным, и корчится от смеха, если заклинание на него действует. Цель должна пройти испытание Мудрости, иначе она падает ничком, становится недееспособной, и в течение действия заклинания не может встать. Существа со значением Интеллекта 4 и меньше не попадают под действие этого заклинания.

В конце каждого своего хода, и каждый раз при получении урона цель может снова проходить испытание Мудрости. Испытание проходится с преимуществом, если он был вызван получением урона. При успехе заклинание оканчивается.`,
  lvl: 1,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `маленькие пирожные и перо, которым нужно махать в воздухе`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
    condition: [
      CONDITION_INCAPACITATED,
      CONDITION_PRONE,
    ],
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 225,
    },
    {
      id: SOURCE_SRD,
      page: 154,
    },
  ],
}
