const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_CLIMB,
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_9} = require('./../../../../crList')
const {CREATURE_FROST_SALAMANDER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_PRIMORDIAL} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Морозная саламандра',
  nameEn: 'Frost Salamander',
  id: CREATURE_FROST_SALAMANDER,
  description: `Морозные саламандры — обитатели Плана Льда, более известного как Царство Льда, что расположен между Планом Воздуха и Планом Воды. Морозные саламандры особенно любят охотиться на теплокровных существ. Иногда они посещают холодные климатические зоны Материального плана, проходя через планарные врата.

**Пожиратели тепла.** Агрессивное желание морозных саламандр поедать любой источник тепла побуждает их атаковать поселения, с которыми они сталкиваются. Кузнечный огонь или костёр ошибочно принятые морозными саламандрами за большую, вкусную еду, заставляет их атаковать экспедиции и поселения, которых избегали бы другие хищники. Иногда азеры отправляются в Царство Льда, где используют крупные источники огня, чтобы заманить морозных саламандр в ловушки, убить их и содрать шкуры и клыки для использования их в создании орудий и доспехов.

**Ложное убежище. Хотя морозные саламандры могут прокапывать путь в рыхлой почве, они предпочитают зарываться в лёд. Саламандры закапываются в груды сломанных кусков льда, позволяя льду царапать себе спину, пока они его размалывают. Эта привычка заставляет морозных саламандр создавать обширные сети ледяных пещер, становясь все крупнее что бы цепляться за свежие сколы льда на стенах их логова.

Морозная саламандра, которая некоторое время обитает в логове, создаёт достаточно места, чтобы небольшая армия могла обустроить лагерь внутри. Неопытные путешественники, которые сталкиваются с подобными пещерами, считают это отличной идеей, чтобы обустроить лагерь, хотя это совершенно не так. Морозные саламандры жадно пожирают любую добычу, которая достаточна безрассудна, чтобы спать в их логове.

В редких случаях, морозные гиганты всё же ловят и приручают этих существ, применяя их способность вкапываться в лёд для сооружения собственных аванпостов и укреплений.`,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MTOF,
    page: 211,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 60,
    [SPEED_DIG]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 18,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 7,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  immunityList: [
    DAMAGE_COLD,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  languageList: [
    LANG_PRIMORDIAL,
  ],
  cr: CR_9,
  featureList: [
    {
      name: 'Полыхающая ярость',
      description: `Когда саламандра получает огненный урон, её Замораживающее дыхание автоматически перезаряжается.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Саламандра совершает пять атак: четыре _Когтями_ и одну _Укусом_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 15,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 5,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 1,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Замораживающее дыхание',
      restore: 6,
      description: `Саламандра выдыхает ледяной ветер 60-футовым конусом. Каждое существо в этой области должно пройти испытание телосложения СЛ 17, получая 44 (8к10) урона холодом при провале и половину этого урона в случае успеха.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
