const {
  LANG_TYPE_EXOTIC,
} = require('./../../languageTypeList')
const {
  LANG_YIKARIA,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_YIKARIA,
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Йикария',
    genitive: 'Йикария',
    instrumental: 'Йикария',
    prepositional: 'Йикария',
  },
  nameAlt: 'Язык яколюдов',
  nameEn: 'Yikaria',
  nameEnAlt: 'Yakfolk Language',
  isRealLang: true,
  isReady: false,
}
