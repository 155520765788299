import React from "react"
import PropTypes from "prop-types"

import "./FilterStyles.less"

const FilterComponent = ({label, title, type, list, value, onChange, className, disabled}) => (
  <div
    className={`Filter ${className}`}
    title={title ? `${label}\n${title}` : ''}
  >
    {
      type === 'input'
        ? (
          <input
            className='Filter_input'
            onChange={onChange}
            type='search'
            placeholder={label}
            value={value}
            disabled={disabled}
            autoFocus
          />
        )
        : (
          <label className='Filter_label'>
            <select
              className='Filter_select'
              value={value}
              onChange={onChange}
              disabled={disabled}
            >
              {
                list.map(
                  ({text, value, title: itemTitle}) => (
                    <option
                      value={value}
                      key={value}
                      title={itemTitle}
                      className='Filter_option'
                    >
                      {
                        value === ''
                          ? `— ${label} —`
                          : text
                      }
                    </option>
                  )
                )
              }
            </select>
          </label>
        )
    }
  </div>
)

FilterComponent.defaultProps = {
  list: [],
  title: '',
  className: '',
}

FilterComponent.propTypes = {
  value: PropTypes.any.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.array,
}

export default FilterComponent
