const EMPTY = ''

class Description {
  constructor(description, header, subHeader, source) {
    switch (typeof description) {
      case 'string': {
        this.header = header
        this.subHeader = subHeader
        this.text = description
        this.source = source
        break;
      }

      case 'object': {
        const {
          header = EMPTY,
          subHeader = EMPTY,
          text = EMPTY,
          source = EMPTY,
        } = description

        this.header = header
        this.subHeader = subHeader
        this.text = text
        this.source = source
        break;
      }
    }
  }
}

module.exports = Description
