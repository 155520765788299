const {PC_SUBCLASS_ROGUE_SCOUT} = require('./../../../pcSubClassIdList')
const {
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  FEATURE_AMBUSH_MASTER,
  FEATURE_SKIRMISHER,
  FEATURE_SNEAK_ATTACK,
  FEATURE_SUDDEN_STRIKE,
  FEATURE_SUPERIOR_MOBILITY,
  FEATURE_SURVIVALIST,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_SKIRMISHER,
    name: `Зачинщик`,
    nameEn: `Skirmisher`,
    lvl: 3,
    text: `К Вам трудно подобраться во время боя. Вы можете в качестве реакции передвинуться на расстояние до половины вашей скорости, когда враг заканчивает свой ход в пределах 5 футов от Вас.

Это передвижение не вызывает провоцированной атаки по Вам.`,
  },
  {
    id: FEATURE_SURVIVALIST,
    name: `Выживальщик`,
    nameEn: `Survivalist`,
    lvl: 3,
    text: `Вы получаете владение навыками Природа и Выживание, если Вы ещё не владеете ими. Ваш бонус мастерства удваивается при любых проверках характеристик, совершённых с использованием любого из этих навыков.`,
  },
  {
    id: FEATURE_SUPERIOR_MOBILITY,
    name: `Превосходная мобильность`,
    nameEn: `Superior Mobility`,
    lvl: 9,
    text: `Ваша скорость увеличивается на 10 футов. Если у Вас есть скорость лазания или плавания, это увеличение также относится и к ним.`,
  },
  {
    id: FEATURE_AMBUSH_MASTER,
    name: `Мастер засад`,
    nameEn: `Ambush master`,
    lvl: 13,
    text: `Вы превосходно руководите засадами и действуете первым в бою.

Вы получаете преимущество на броски инициативы. В дополнение, по существу, по которому Вы по первому попадаете в первый раунд боя, становится легче попасть другим; броски атаки по такой цели совершаются с преимуществом до начала Вашего следующего хода.`,
  },
  {
    id: FEATURE_SUDDEN_STRIKE,
    name: `Внезапный удар`,
    nameEn: `Sudden Strike`,
    lvl: 17,
    text: `Вы можете наносить удары со смертоносной скоростью.

Если в свой ход Вы совершаете действие Атака, то можете сделать одну дополнительную атаку бонусным действием. К этой дополнительной атаке может применяться [Скрытая атака](FEATURE:${FEATURE_SNEAK_ATTACK}), даже если Вы уже использовали [Скрытую атаку](FEATURE:${FEATURE_SNEAK_ATTACK}) в этот ход, но Вы не можете применять [Скрытую атаку](FEATURE:${FEATURE_SNEAK_ATTACK}) против одной и той же цели более одного раза за ход.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_SCOUT,
    source: {
      id: SOURCE_XGTE,
      page: 53,
    },
  })
)

