const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_13} = require('./../../../../crList')
const {CREATURE_WASTRILITH} = require('./../../../../creatureIdList')
const {demonDescription} = require('./../../../../textCommonParts')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Вастрилит',
  nameEn: 'Wastrilith',
  id: CREATURE_WASTRILITH,
  description: [
    `Найденные в водах Бездны и других водоёмах, загрязнённых падшим влиянием плана, вастрилиты считают себя владыками глубин, и с жестокостью управляют своим владением.`,
    {
      header: 'Мародёры',
      text: `Вастрилиты загрязняют окружающие их воды. Их пагубное присутствие действует на близлежащие источники воды даже когда демон передвигается по суше. Осквернённая вода, хранящая в себе следы присутствия демонической сущности, отвечает на её команды… возможно затвердевая для того, чтобы не дать врагам убежать, либо извергаясь в потоке, что затягивает будущих жертв всё ближе.`,
      source: {
        id: SOURCE_MTOF,
        page: 131,
      },
    },
    {
      header: 'Бесшумные осквернители',
      text: `Создания, что испивают испорченной вастрилитами воды рискуют своими душами. Те, кто пьёт эту токсичную жидкость могут начать иссыхать пока не умрут, или останутся жить и станут пленниками зла и хаоса. Чтобы представить это осквернение, Вы можете использовать необязательное правило о «Развращение Бездной» во 2 главе Руководства Мастера (стр. 62), в результате чего отравленное существо будет развращено.`,
      source: {
        id: SOURCE_MTOF,
        page: 131,
      },
    },
    ...demonDescription,
  ],
  note: {
    text: `Осквернённая вода, оставленная вастрилитом во время его прибытия в мир, может остаться в таком состоянии даже спустя десятилетия. Если не избавиться от него, то рано или поздно, она может стать про- ходным мостом к Бездне.`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 131,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 80,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 21,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_13,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Осквернение воды',
      description: `В начале каждого хода вастрилита, открытая вода в пределах 30 футов от него, оскверняется. Под водой этот эффект слегка затемняет область, пока тот не очистит её. Вода в контейнерах остаётся испорченной до испарения.
        
Существо, что употребляет эту грязную воду или плавает в ней, должно пройти испытание Телосложения СЛ 18. При успехе, существо становится невосприимчиво к загрязнённой воде в течение 24 часов. При провале существо получает 14 (4к6) урона ядом и отравляется на 1 минуту. В конце этого времени отравленное существо должно повторить испытание. При провале существо получает 18 (4к8) урона ядом и отравляется до тех пор, пока не закончит длинный отдых.

Если другой демон действием выпьет грязную воду, он получит 11 (2к10) временных хитов.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Изменение течения',
      description: `Бонусным действием, когда Вастрилит находится под водой, это может привести к тому, что вся вода в 60 футах от него станет труднопроходимой местностью для других существ до начала следующего хода.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Вастрилит использует _Притягивающую струю_ и совершает три атаки: одну _Укусом_ и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 12,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Притягивающая струя',
      description: `Вастрилит волшебным образом запускает струю воды в одно существо, которое он может видеть в пределах 60 футов от него. Цель должна пройти испытание Силы СЛ 17, и, при этом, проходит это испытание с помехой, если она находится под водой. При провале она получает 22 (4к8 + 4) урона кислотой и подтягивается до 60 футов в направлении к вастрилиту. При успехе она получает вдвое меньше урона и не подтягивается.`,
    },
  ],
}
