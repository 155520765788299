const {PC_CLASS_RANGER} = require('./../../pcClassIdList')
const {
  SPELL_ARCANE_EYE,
  SPELL_GASEOUS_FORM,
} = require('./../../spellIdList')
const {
  SOURCE_FTD,
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK,
  SOURCE_UA_2016_09_12_THE_RANGER_REVISED,
  SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES,
  SOURCE_UA_2017_01_16_RANGER_ROGUE,
  SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
  SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
  SOURCE_UA_2020_10_26_SUBCLASSES_PART_5,
  SOURCE_XGTE,
} = require('./../../sourceList')
const {
  CREATURE_OGRE,
  CREATURE_ORC,
} = require('./../../creatureIdList')

const {
  PC_SUBCLASS_RANGER_BEAST_CONCLAVE_2016_09_12,
  PC_SUBCLASS_RANGER_BEAST_MASTER,
  PC_SUBCLASS_RANGER_DEEP_STALKER_2015_11_02,
  PC_SUBCLASS_RANGER_DEEP_STALKER_CONCLAVE_2016_09_12,
  PC_SUBCLASS_RANGER_DRAKEWARDEN,
  PC_SUBCLASS_RANGER_DRAKEWARDEN_2020_10_26,
  PC_SUBCLASS_RANGER_FEY_WANDERER,
  PC_SUBCLASS_RANGER_FEY_WANDERER_2020_02_24,
  PC_SUBCLASS_RANGER_GLOOM_STALKER,
  PC_SUBCLASS_RANGER_HORIZON_WALKER,
  PC_SUBCLASS_RANGER_HORIZON_WALKER_2017_01_16,
  PC_SUBCLASS_RANGER_HUNTER,
  PC_SUBCLASS_RANGER_HUNTER_CONCLAVE_2016_09_12,
  PC_SUBCLASS_RANGER_MONSTER_SLAYER,
  PC_SUBCLASS_RANGER_MONSTER_SLAYER_2017_03_27,
  PC_SUBCLASS_RANGER_PRIMEVAL_GUARDIAN_2017_01_16,
  PC_SUBCLASS_RANGER_SWARMKEEPER,
  PC_SUBCLASS_RANGER_SWARMKEEPER_2019_10_17,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_RANGER_BEAST_CONCLAVE_2016_09_12,
    pcClassId: PC_CLASS_RANGER,
    isReady: false,
    name: 'Конклав зверей',
    nameEn: 'Beast Conclave',
    source: {
      id: SOURCE_UA_2016_09_12_THE_RANGER_REVISED,
      page: 5,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_BEAST_MASTER,
    pcClassId: PC_CLASS_RANGER,
    isReady: true,
    name: 'Повелитель зверей',
    nameEn: 'Beast Master',
    description: `Архетип повелителя зверей воплощает в себе дружбу между цивилизацией и зверями дикого мира.

Объединяя усилия, зверь и следопыт работают как одно целое, борясь с чудовищными врагами, угрожающими цивилизации и дикой природе. Следование пути повелителя зверей означает посвящение себя этому идеалу, сотрудничество с животным в качестве спутника и друга.`,
    source: {
      id: SOURCE_PHB,
      page: 94,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_FEY_WANDERER,
    pcClassId: PC_CLASS_RANGER,
    isReady: true,
    name: 'Фейский странник',
    nameEn: 'Fey Wanderer',
    description: `Вас окружает фейская таинственность. Благодаря то ли дару архифеи, то ли съеденным Вами сияющим фруктам с говорящего дерева, то ли волшебному источнику, в котором Вы плавали, то ли другому благоприятному событию.

Как бы Вы ни приобрели свою фейскую магию, теперь Вы — фейский странник, следопыт, представляющий как мир смертных, так и Страну Фей. Странствуя по мультивселенной, Вы радостным смехом освещаете сердца угнетённых, а боевым мастерством наводите ужас на врагов, ибо веселье фей велико, а ярость их ужасна.`,
    note: {
      text: `Как ты думаешь, килт — необходимая часть экипировки фейского странника? И, если нет, то почему ты так неправ?`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 85,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_FEY_WANDERER_2020_02_24,
    pcClassId: PC_CLASS_RANGER,
    releasedAs: PC_SUBCLASS_RANGER_FEY_WANDERER,
    isReady: false,
    name: 'Фейский странник',
    nameEn: 'Fey Wanderer',
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 5,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_HORIZON_WALKER,
    pcClassId: PC_CLASS_RANGER,
    isReady: true,
    name: 'Странник горизонта',
    nameEn: 'Horizon Walker',
    description: `Странники Горизонта охраняют мир от угроз, которые исходят с других планов или которые стремятся разрушить мир с помощью потусторонней магии.

Они ищут планарные порталы и следят за ними, углубляясь во Внутренние и Внешние Планы по мере необходимости, чтобы преследовать своих врагов. Эти следопыты также являются друзьями любых сил в мультивселенной — особенно добрых драконов, фей и элементалей — которые работают, чтобы сохранить жизнь и порядок на Планах.`,
    note: {
      text: `Нельзя странствовать до горизонта, потому что он продолжает удаляться от тебя при этом. Бум! Взрыв мозга тебе устроил? Ведь устроил же?`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 55,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_HORIZON_WALKER_2017_01_16,
    pcClassId: PC_CLASS_RANGER,
    releasedAs: PC_SUBCLASS_RANGER_HORIZON_WALKER,
    isReady: false,
    name: 'Странник горизонта',
    nameEn: 'Horizon Walker',
    source: {
      id: SOURCE_UA_2017_01_16_RANGER_ROGUE,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_GLOOM_STALKER,
    pcClassId: PC_CLASS_RANGER,
    isReady: true,
    name: 'Сумрачный охотник',
    nameEn: 'Gloom Stalker',
    description: `Сумрачные охотники ощущают себя дома в самых тёмных местах: глубоко под землёй, в мрачных переулках, первобытных лесах и везде, где свет тускнеет.

Большинство людей попадая в такие места ощущают беспокойство, но Сумрачный охотник смело следует во тьму, стремясь устранить угрозы, прежде чем они попадут в открытый мир. Такие следопыты обычно встречаются в Подземье, но они последуют в любое место, где зло скрывается в тенях.`,
    note: {
      text: `Ты скрываешься в темноте? Ты же знаешь, что кроме людей практически все видят в темноте? Мы все тебя видим. Хождение на цыпочках не сделает тебя невидимым.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 56,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_DEEP_STALKER_CONCLAVE_2016_09_12,
    pcClassId: PC_CLASS_RANGER,
    releasedAs: PC_SUBCLASS_RANGER_GLOOM_STALKER,
    isReady: false,
    name: 'Конклав глубинных охотников',
    nameEn: 'Deep Stalker Conclave',
    source: {
      id: SOURCE_UA_2016_09_12_THE_RANGER_REVISED,
      page: 7,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_DEEP_STALKER_2015_11_02,
    pcClassId: PC_CLASS_RANGER,
    releasedAs: PC_SUBCLASS_RANGER_GLOOM_STALKER,
    isReady: true,
    name: 'Глубинный охотник',
    nameEn: 'Deep Stalker',
    description: `Архетип охотника означает признание своего места в качестве оплота между цивилизацией и ужасами диких земель.

Если Вы пошли по пути охотника, Вы обучаетесь специализированным техникам ведения боя с угрозами, встречаемыми вами, начиная неистовыми [ограми](CREATURE:${CREATURE_OGRE}) и ордами [орков](CREATURE:${CREATURE_ORC}), и заканчивая возвышающимися великанами и ужасающими драконами.`,
    source: {
      id: SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_HUNTER,
    pcClassId: PC_CLASS_RANGER,
    isReady: true,
    name: 'Охотник',
    nameEn: 'Hunter',
    description: `Архетип охотника означает признание своего места в качестве оплота между цивилизацией и ужасами диких земель.

Если Вы пошли по пути охотника, Вы обучаетесь специализированным техникам ведения боя с угрозами, встречаемыми Вами, начиная неистовыми ограми и ордами орков, и заканчивая возвышающимися великанами и ужасающими драконами.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 93,
      },
      {
        id: SOURCE_SRD,
        page: 37,
      },
    ],
  },
  {
    id: PC_SUBCLASS_RANGER_HUNTER_CONCLAVE_2016_09_12,
    pcClassId: PC_CLASS_RANGER,
    isReady: false,
    name: 'Конклав охотников',
    nameEn: 'Hunter Conclave',
    source: {
      id: SOURCE_UA_2016_09_12_THE_RANGER_REVISED,
      page: 7,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_MONSTER_SLAYER,
    pcClassId: PC_CLASS_RANGER,
    isReady: true,
    name: 'Убийца монстров',
    nameEn: 'Monster Slayer',
    description: `Вы посвятили себя охоте на существ ночи и обладателей мрачной магии.

Убийца монстров ищет вампиров, драконов, злых фей, исчадий и другие магические угрозы. Обученные сверхъестественным приемам побеждать таких монстров, убийцы монстров — эксперты в обнаружении и уничтожении могущественных мистических врагов.`,
    note: {
      text: `Я — монстр. Ты попытаешься убить меня? Не думаю. Иди поубивай каких-нибудь гоблинов или типа того. Хотя если подумать, гоблины — не монстры, они — народ. Так что, может быть, тебе стоит называть себя убийцей народов.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 57,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_MONSTER_SLAYER_2017_03_27,
    pcClassId: PC_CLASS_RANGER,
    releasedAs: PC_SUBCLASS_RANGER_MONSTER_SLAYER,
    isReady: false,
    name: 'Убийца монстров',
    nameEn: 'Monster Slayer',
    source: {
      id: SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_PRIMEVAL_GUARDIAN_2017_01_16,
    pcClassId: PC_CLASS_RANGER,
    isReady: false,
    name: 'Первобытный страж',
    nameEn: 'Primeval Guardian',
    source: {
      id: SOURCE_UA_2017_01_16_RANGER_ROGUE,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_SWARMKEEPER_2019_10_17,
    pcClassId: PC_CLASS_RANGER,
    releasedAs: PC_SUBCLASS_RANGER_SWARMKEEPER,
    isReady: false,
    name: 'Роевод',
    nameAlt: 'Хранитель роя',
    nameEn: 'Swarmkeeper',
    source: {
      id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_SWARMKEEPER,
    pcClassId: PC_CLASS_RANGER,
    isReady: true,
    name: 'Роевод',
    nameAlt: 'Хранитель роя',
    nameEn: 'Swarmkeeper',
    description: [
      `Чувствуя глубокую связь с окружающей средой, некоторые следопыты достигают магической связи с миром и связываются с роем духов природы. Рой становится как мощной силой в бою, так и полезным спутником для такого следопыта.

Некоторое роеводы — дикари или отшельники, и, вместо того чтобы беспокоиться о чужом дискомфорте, предпочитают просто держаться от других подальше. Другим же роеводам нравится создавать живые сообщества, где все работают на общую пользу роя или стаи.`,
      {
        header: 'Это Ваш рой',
        text: `Рой и заклинания роевода отражают связь персонажа с духами природы. Используйте возможность описывать рой и магию следопыта во время игры. Например, когда Ваш следопыт сотворяет заклинание [Газообразная форма](SPELL:${SPELL_GASEOUS_FORM}), он может слиться со своим роем, вместо того чтобы стать облаком или туманом; а заклинание [Магический глаз](SPELL:${SPELL_ARCANE_EYE}) может посылась шпионить часть Вашего роя. Такие описания не меняют эффекты заклинаний, вместо этого позволяя ярче описывать отличительные классовые особенности Вашего персонажа.

Кроме того, не забывайте что внешний вид роя определяется Вами. Не ограничивайте себя одним видом. Может быть, рой Вашего следопыта изменяется в зависимости от его настроения или времени года? Ваш решать!`,
        source: {
          id: SOURCE_TCoE,
          page: 88,
        },
      },
    ],
    note: [
      {
        text: `Мне нравятся насекомые — это организованные, беспощадные, узкоспециализированные маленькие чемпионы. Сравни их простой целеустремлённый разум со своим собственным: он прекрасен. Но держи их подальше от моей лаборатории.`,
        author: `Таша`,
      },
      `Когда ему угрожает опасность, лес шумит и воздух гудит от стрекозиных крыльев`,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 87,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_DRAKEWARDEN_2020_10_26,
    pcClassId: PC_CLASS_RANGER,
    releasedAs: PC_SUBCLASS_RANGER_DRAKEWARDEN,
    isReady: false,
    name: 'Дрейковод',
    nameEn: 'Drakewarden',
    source: {
      id: SOURCE_UA_2020_10_26_SUBCLASSES_PART_5,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_RANGER_DRAKEWARDEN,
    pcClassId: PC_CLASS_RANGER,
    isReady: false,
    name: 'Дрейковод',
    nameEn: 'Drakewarden',
    source: {
      id: SOURCE_FTD,
    },
  },
]
