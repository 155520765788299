const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_GIANT_HILL,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_HILL_GIANT,
  CREATURE_MOUTH_OF_GROLANTOR,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_FRIGHTENED} = require('./../../../../conditionList')
const {CR_6} = require('./../../../../crList')
const {hillGiantDescriptionList} = require('./../../../../textCommonParts')
const {GOD_GROLANTOR} = require('./../../../../godIdList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_CONFUSION} = require('./../../../../spellIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Пасть Гролантора',
  nameEn: 'Mouth of Grolantor',
  id: CREATURE_MOUTH_OF_GROLANTOR,
  description: [
    `[Холмовые великаны](CREATURE:${CREATURE_HILL_GIANT}) знают виды пищи, которые могут сделать их толще и понимают, что если слишком много напрягаться, то станешь худее. Чего ленивые грубияны не понимают, так это того, от чего они болеют. Они поедают испорченную еду и больные туши с таким же энтузиазмом, как дети поедают десерт. К счастью для холмовых великанов, у них конституция падальщика и они редко страдают от такого рациона. Так что им кажется очень загадочным, если один их собрат заболеет или не сможет удерживать в себе еду. [Холмовые великаны](CREATURE:${CREATURE_HILL_GIANT}), которых рвет, видятся им сосудами для послания [Гролантора](GOD:${GOD_GROLANTOR}).

Клан отделяет больного великана от остальных, зачастую сажает его в клетку или привязывает к столбу. Жрец [Гролантора](GOD:${GOD_GROLANTOR}) или вождь каждый день навещают голодного великана и пытаются читать предзнаменования в лужах рвоты, которые [холмовой великан](CREATURE:${CREATURE_HILL_GIANT}) изрыгает из себя. Если болезнь проходит, то [холмовой великан](CREATURE:${CREATURE_HILL_GIANT}) вскоре возвращается в общество. Если же нет, то вместо этого [холмовой великан](CREATURE:${CREATURE_HILL_GIANT}) сходит с ума от голода и голод [Гролантора](GOD:${GOD_GROLANTOR}) получает пасть в этом мире.`,
    {
      header: 'Голодный и безумный',
      text: `Пасть Гролантора настолько опозорен, что перестает быть индивидуумом и становится объектом. Парадоксально, но этот объект почитается как вместилище вечного, болезненного голода [Гролантора](GOD:${GOD_GROLANTOR}). В отличие от обычного толстого полусонного холмового великана, пасть Гролантора тощий как гончая, бдительный как птица и постоянно заглядывает во все углы.

Пасть Гролантора держат в постоянном заключении или в кандалах; если он вырвется на свободу, то точно убьет несколько [холмовых великанов](CREATURE:${CREATURE_HILL_GIANT}) до того, как его схватят или он убежит и продолжит убивать. Пасть Гролантора выпускают только во время войны, налёта на вражеское поселение или во время боя до последней капли крови при обороне поселения его племени. Когда пасть Гролантора убивает и досыта наедается врагами племени, он теряет сознание посреди кровавых останков своих жертв и его легко снова схватить.`,
      source: {
        id: SOURCE_VGTM,
        page: 137,
      },
    },
    ...hillGiantDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_HILL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 137,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 18,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_FRIGHTENED,
  ],
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_6,
  featureList: [
    {
      name: 'Пасть безумия',
      description: `Великан имеет иммунитет к заклинаниям [Смятения](SPELL:${SPELL_CONFUSION}) и похожей магии.

В каждый свой ход великан использует всё свое движение, чтобы подойти к ближайшему существу или тому, что он считает едой. Бросьте к10 в начале каждого хода великана, чтобы определить курс его действий:

* **1–3.** Великан делает три удара кулаками по одной случайной цели в области досягаемости. Если в досягаемости нет существ, то он впадает в ярость и получает преимущество на все атаки до конца своего следующего хода.
* **4–5.** Великан делает по одной атаке _Кулаком_ по каждому существу в области досягаемости. Если рядом никого нет, то великан делает одну атаку _Кулаком_ по себе.
* **6–7.** Великан делает одну атаку _Укусом_ по одной случайной цели в области досягаемости. Если таких существ нет, то его глаза стекленеют и он становится ошеломленным до начала своего следующего хода.
* **8–10.** Великан делает три атаки по одной случайной цели в области досягаемости: одну _Укусом_ и две _Кулаками_. Если в досягаемости нет существ, то он впадает в ярость и получает преимущество на все атаки до конца своего следующего хода.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      description: ` и великан магически восстанавливает столько хитов, сколько нанес урона`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
  ],
}
