const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_SHADOW_STEALTH,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_ABISHAI,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  abishaiDescriptionList,
  abishaiNote,
  devilDescriptionList,
} = require('./../../../../textCommonParts')
const {
  LANG_DRACONIC,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_ABISHAI_BLACK} = require('./../../../../creatureIdList')
const {GEAR_SCIMITAR} = require('./../../../../gearIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPELL_DARKNESS} = require('./../../../../spellIdList')

module.exports = {
  name: 'Чёрный абишай',
  nameEn: 'Black Abishai',
  id: CREATURE_ABISHAI_BLACK,
  description: [
    `Опытные убийцы и разведчики, чёрные абишаи сплетают тени, маскируя своё присутствие, чтобы добраться до места, из которого они могут нанести смертельный удар по своей цели.`,
    ...abishaiDescriptionList,
    ...devilDescriptionList,
  ],
  note: abishaiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ABISHAI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 178,
    },
    {
      url: `https://5e.tools/bestiary.html#black%20abishai_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Чёрного Абишая» скимитар внезапно стал наносить колющий урон.`,
    },
  ],
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 14,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_7,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    ABILITY_SHADOW_STEALTH,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Абишай совершает три атаки: две — _Скимитаром_ и одну _Укусом_.`,
    },
    {
      gearId: GEAR_SCIMITAR,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 10,
            diceBonus: 3,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 2,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Ползучая тьма',
      description: `Абишай сотворяет [тьму](SPELL:${SPELL_DARKNESS}) в точке в пределах 120 футов от него, не нуждаясь ни в каких компонентах. Мудрость — его заклинательная характеристика для накладывания этого заклинания. Пока заклинание активно, абишай может бонусным действием переместить область тьмы на расстояние до 60 футов.`,
    },
  ],
}
