module.exports.CREATURE_TYPE_AARAKOCRA = 'aarakocra'
module.exports.CREATURE_TYPE_AASIMAR = 'aasimar'
module.exports.CREATURE_TYPE_ABERRATION = 'aberration'
module.exports.CREATURE_TYPE_ABISHAI = 'abishai'
module.exports.CREATURE_TYPE_ANVILWROUGHT = 'anvilwrought'
module.exports.CREATURE_TYPE_ANY_RACE = 'any_race'
module.exports.CREATURE_TYPE_ARCHDEVIL = 'archdevil'
module.exports.CREATURE_TYPE_ARCHON = 'archon'
module.exports.CREATURE_TYPE_AUTOGNOME = 'autognome'
module.exports.CREATURE_TYPE_BEAST = 'beast'
module.exports.CREATURE_TYPE_BEHOLDER_KEEN = 'beholder_keen'
module.exports.CREATURE_TYPE_BLINDHEIM = 'blindheim'
module.exports.CREATURE_TYPE_BUGBEAR = 'bugbear'
module.exports.CREATURE_TYPE_BULLYWUG = 'bullywug'
module.exports.CREATURE_TYPE_CELESTIAL = 'celestial'
module.exports.CREATURE_TYPE_CENTAUR = 'centaur'
module.exports.CREATURE_TYPE_CERBERUS = 'cerberus'
module.exports.CREATURE_TYPE_CHANGELING = 'changeling'
module.exports.CREATURE_TYPE_CONSTRUCT = 'construct'
module.exports.CREATURE_TYPE_DARKLING = 'darkling'
module.exports.CREATURE_TYPE_DEMON = 'demon'
module.exports.CREATURE_TYPE_DEMON_LORD = 'demon_lord'
module.exports.CREATURE_TYPE_DERRO = 'derro'
module.exports.CREATURE_TYPE_DEVIL = 'devil'
module.exports.CREATURE_TYPE_DHAMPIR = 'dhampir'
module.exports.CREATURE_TYPE_DINOSAUR = 'dinosaur'
module.exports.CREATURE_TYPE_DIRE_CORBY = 'dire_corby'
module.exports.CREATURE_TYPE_DRAGON = 'dragon'
module.exports.CREATURE_TYPE_DRAGON_CHROMATIC = 'dragon_chromatic'
module.exports.CREATURE_TYPE_DRAGON_GEM = 'dragon_gem'
module.exports.CREATURE_TYPE_DRAGON_METALLIC = 'dragon_metallic'
module.exports.CREATURE_TYPE_DRAGON_TRUE = 'dragon_true'
module.exports.CREATURE_TYPE_DRAGONBORN = 'dragonborn'
module.exports.CREATURE_TYPE_DRAKE = 'drake'
module.exports.CREATURE_TYPE_DROW = 'drow'
module.exports.CREATURE_TYPE_DUERGAR = 'duergar'
module.exports.CREATURE_TYPE_DWARF = 'dwarf'
module.exports.CREATURE_TYPE_ELADRIN = 'eladrin'
module.exports.CREATURE_TYPE_ELEMENTAL = 'elemental'
module.exports.CREATURE_TYPE_ELEMENTAL_AIR = 'elemental_air'
module.exports.CREATURE_TYPE_ELEMENTAL_EARTH = 'elemental_earth'
module.exports.CREATURE_TYPE_ELEMENTAL_FIRE = 'elemental_fire'
module.exports.CREATURE_TYPE_ELEMENTAL_WATER = 'elemental_water'
module.exports.CREATURE_TYPE_ELF = 'elf'
module.exports.CREATURE_TYPE_FAIRY = 'fairy'
module.exports.CREATURE_TYPE_FEY = 'fey'
module.exports.CREATURE_TYPE_FIEND = 'fiend'
module.exports.CREATURE_TYPE_FILBORG = 'filborg'
module.exports.CREATURE_TYPE_FIRENEWT = 'firenewt'
module.exports.CREATURE_TYPE_GENASI_AIR = 'genasi_air'
module.exports.CREATURE_TYPE_GENASI_EARTH = 'genasi_earth'
module.exports.CREATURE_TYPE_GENASI_FIRE = 'genasi_fire'
module.exports.CREATURE_TYPE_GENASI_WATER = 'genasi_water'
module.exports.CREATURE_TYPE_GHOST = 'ghost'
module.exports.CREATURE_TYPE_GIANT = 'giant'
module.exports.CREATURE_TYPE_GIANT_CLOUD = 'giant_cloud'
module.exports.CREATURE_TYPE_GIANT_FIRE = 'giant_fire'
module.exports.CREATURE_TYPE_GIANT_FROST = 'giant_frost'
module.exports.CREATURE_TYPE_GIANT_HILL = 'giant_hill'
module.exports.CREATURE_TYPE_GIANT_STONE = 'stone_giant'
module.exports.CREATURE_TYPE_GIANT_STORM = 'giant_storm'
module.exports.CREATURE_TYPE_GIFF = 'giff'
module.exports.CREATURE_TYPE_GITH = 'gith'
module.exports.CREATURE_TYPE_GITHYANKI = 'githyanki'
module.exports.CREATURE_TYPE_GITHZERAI = 'githzerai'
module.exports.CREATURE_TYPE_GLITCHLING = 'glitchling'
module.exports.CREATURE_TYPE_GNOLL = 'gnoll'
module.exports.CREATURE_TYPE_GNOME = 'gnome'
module.exports.CREATURE_TYPE_GOBLIN = 'goblin'
module.exports.CREATURE_TYPE_GOBLINOID = 'goblinoid'
module.exports.CREATURE_TYPE_GOLIATH = 'goliath'
module.exports.CREATURE_TYPE_GRIMLOCK = 'grimlock'
module.exports.CREATURE_TYPE_GRUNG = 'grung'
module.exports.CREATURE_TYPE_HADOZEE = 'hadozee'
module.exports.CREATURE_TYPE_HAG = 'hag'
module.exports.CREATURE_TYPE_HALF_ELF = 'half_elf'
module.exports.CREATURE_TYPE_HALF_ORC = 'half_orc'
module.exports.CREATURE_TYPE_HALFLING = 'halfling'
module.exports.CREATURE_TYPE_HARENGON = 'harengon'
module.exports.CREATURE_TYPE_HEXBLOOD = 'hexblood'
module.exports.CREATURE_TYPE_HOBGOBLIN = 'hobgoblin'
module.exports.CREATURE_TYPE_HOMUNCULUS = 'homunculus'
module.exports.CREATURE_TYPE_HUMAN = 'human'
module.exports.CREATURE_TYPE_HUMANOID = 'humanoid'
module.exports.CREATURE_TYPE_ILLITHID = 'illithid'
module.exports.CREATURE_TYPE_INEVITABLE = 'inevitable'
module.exports.CREATURE_TYPE_JERMLAINE = 'jermlaine'
module.exports.CREATURE_TYPE_KALASHTAR = 'kalashtar'
module.exports.CREATURE_TYPE_KENDER = 'kender'
module.exports.CREATURE_TYPE_KENKU = 'kenku'
module.exports.CREATURE_TYPE_KOBOLD = 'kobold'
module.exports.CREATURE_TYPE_KRUTHIK = 'kruthik'
module.exports.CREATURE_TYPE_KUO_TOA = 'kuo_toa'
module.exports.CREATURE_TYPE_LEONIN = 'leonin'
module.exports.CREATURE_TYPE_LIZARDFOLK = 'lizardfolk'
module.exports.CREATURE_TYPE_LOCATHAH = 'locathah'
module.exports.CREATURE_TYPE_LOXODON = 'loxodon'
module.exports.CREATURE_TYPE_LYCANTHROPE = 'lycanthrope'
module.exports.CREATURE_TYPE_MAGEN = 'magen'
module.exports.CREATURE_TYPE_MEAZEL = 'meazel'
module.exports.CREATURE_TYPE_MEPHIT = 'mephit'
module.exports.CREATURE_TYPE_MERFOLK = 'merfolk'
module.exports.CREATURE_TYPE_MIMIC = 'mimic'
module.exports.CREATURE_TYPE_MINOTAUR = 'minotaur'
module.exports.CREATURE_TYPE_MODRONE = 'modrone'
module.exports.CREATURE_TYPE_MONSTROSITY = 'monstrosity'
module.exports.CREATURE_TYPE_MYCONID = 'myconid'
module.exports.CREATURE_TYPE_MYRMIDON = 'myrmidon'
module.exports.CREATURE_TYPE_NAGA = 'naga'
module.exports.CREATURE_TYPE_NAGPA = 'nagpa'
module.exports.CREATURE_TYPE_NEEDLEFOLK = 'needlefolk'
module.exports.CREATURE_TYPE_NEOGI = 'neogi'
module.exports.CREATURE_TYPE_NORKER = 'norker'
module.exports.CREATURE_TYPE_OGRE = 'ogre'
module.exports.CREATURE_TYPE_OOZE = 'ooze'
module.exports.CREATURE_TYPE_ORC = 'orc'
module.exports.CREATURE_TYPE_OWLIN = 'owlin'
module.exports.CREATURE_TYPE_PLANT = 'plant'
module.exports.CREATURE_TYPE_PLASMOID  = 'plasmoid'
module.exports.CREATURE_TYPE_QUAGGOTH = 'quaggoth'
module.exports.CREATURE_TYPE_REBORN = 'reborn'
module.exports.CREATURE_TYPE_SAHUAGIN = 'sahuagin'
module.exports.CREATURE_TYPE_SATYR = 'satyr'
module.exports.CREATURE_TYPE_SHADAR_KAI = 'shadar_kai'
module.exports.CREATURE_TYPE_SHADOW_MASTIFF = 'shadow_mastiff'
module.exports.CREATURE_TYPE_SHAPESHIFTER = 'shapeshifter'
module.exports.CREATURE_TYPE_SHIFTER = 'shifter'
module.exports.CREATURE_TYPE_SIMIC_HYBRID = 'simic_hybrid'
module.exports.CREATURE_TYPE_SKELETON = 'skeleton'
module.exports.CREATURE_TYPE_SLAAD = 'slaad'
module.exports.CREATURE_TYPE_SORROW_SWORN = 'sorrow_sworn'
module.exports.CREATURE_TYPE_STAR_SPAWN = 'star_spawn'
module.exports.CREATURE_TYPE_TABAXI = 'tabaxi'
module.exports.CREATURE_TYPE_THRI_KREEN = 'thri_kreen'
module.exports.CREATURE_TYPE_TIEFLING = 'tiefling'
module.exports.CREATURE_TYPE_TITAN = 'titan'
module.exports.CREATURE_TYPE_TORTLE = 'tortle'
module.exports.CREATURE_TYPE_TRITON = 'triton'
module.exports.CREATURE_TYPE_TROGLODYTE = 'troglodyte'
module.exports.CREATURE_TYPE_TROLL = 'troll'
module.exports.CREATURE_TYPE_UNDEAD = 'undead'
module.exports.CREATURE_TYPE_VEDALKEN = 'vedalken'
module.exports.CREATURE_TYPE_VEGEPYGMY = 'vegepygmy'
module.exports.CREATURE_TYPE_VERDAN = 'verdan'
module.exports.CREATURE_TYPE_WARFORGED = 'warforged'
module.exports.CREATURE_TYPE_XVART = 'xvart'
module.exports.CREATURE_TYPE_YAKFOLK = 'yakfolk'
module.exports.CREATURE_TYPE_YUAN_TI = 'yuan_ti'
module.exports.CREATURE_TYPE_YUGOLOTH = 'yugoloth'
