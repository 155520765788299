const {MGC_RARITY_VERY_RARE} = require('./../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../magicItemTypeList')
const {gearCollection} = require('./../../../gearList')
const {SOURCE_ERFTLW} = require('./../../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    gearId,
    id,
  }
) => {
  const {
    name,
    nameByCase: {
      accusative: nameAccusative,
    },
    nameEn: gearNameEn,
    genderId,
  } = gearCollection[gearId]

  const {
    adjDisgusting,
    adjective,
    adjectiveAccusative,
    canBeUnDon,
    consists,
    it,
    itDonOff,
    onIt,
    pronoun,
    toIt,
  } = {
    [GENDER_MALE]: {
      adjDisgusting: 'отвратительный',
      adjective: 'Живой',
      adjectiveAccusative: 'Живой',
      canBeUnDon: 'может быть снят',
      consists: 'состоит',
      it: 'его',
      itDonOff: 'он открепляется',
      onIt: 'него',
      pronoun: 'Этот',
      toIt: 'ему',
    },
    [GENDER_FEMALE]: {
      adjDisgusting: 'отвратительная',
      adjective: 'Живая',
      adjectiveAccusative: 'Живую',
      canBeUnDon: 'может быть снята',
      consists: 'состоит',
      it: 'её',
      itDonOff: 'она открепляется',
      onIt: 'неё',
      pronoun: 'Эта',
      toIt: 'ей',
    },
    [GENDER_MIDDLE]: {
      adjDisgusting: 'отвратительное',
      adjective: 'Живое',
      adjectiveAccusative: 'Живое',
      canBeUnDon: 'может быть снято',
      consists: 'состоит',
      it: 'его',
      itDonOff: 'оно открепляется',
      onIt: 'него',
      pronoun: 'Это',
      toIt: 'ему',
    },
    [GENDER_MULTIPLE]: {
      adjDisgusting: 'отвратительные',
      adjective: 'Живые',
      adjectiveAccusative: 'Живые',
      canBeUnDon: 'могут быть сняты',
      consists: 'состоят',
      it: 'их',
      itDonOff: 'они открепляются',
      onIt: 'них',
      pronoun: 'Эти',
      toIt: 'им',
    },
  }[genderId]

  const itemName = `${adjective} ${name.toLowerCase()}`
  const itemNameAccusative = `${adjectiveAccusative} ${nameAccusative.toLowerCase()}`

  return {
    id,
    name: itemName,
    nameEn: `Living ${gearNameEn}`,
    type: MGC_TYPE_ARMOR,
    gearType: gearId,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `${pronoun} ${adjDisgusting} ${name.toLowerCase()} ${consists} из чёрного хитина, под которым пульсируют вены и блестят красные сухожилия.

Для настройки на _${itemNameAccusative}_ Вы должны носить ${it} в течение всего периода настройки, пока усики на ${it} внутренней стороне врастают в Ваc.

Нося _${itemNameAccusative}_, Вы получаете +1 КД и получаете сопротивление к типам урона:

* Некротическому
* Психическому
* Ядом
`,
    symbiotic: `_${itemName}_ не ${canBeUnDon}, пока Вы на ${onIt} настроены и Вы не можете добровольно прекратить настройку на ${onIt}.

Если Вы становитесь целью заклинания, которое снимает проклятье, Ваша настройка на _${itemNameAccusative}_ оканчивается и ${itDonOff} от Вас.

_${itemNameAccusative}_ нужно питать свежей кровью. Сразу после того, как Вы завершаете длинный отдых, Вы должны либо скормить ${toIt} половину (округляя вверх) оставшихся у Вас костей хитов, либо получить 1 уровень истощения.`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_ERFTLW,
      page: 279,
    },
  }
}
