const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_FRIGHTFUL_PRESENCE,
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_SIEGE_MONSTER,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_TITAN,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_30} = require('./../../../../crList')
const {CREATURE_TARRASQUE} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_MAGIC_MISSILE} = require('./../../../../spellIdList')

module.exports = {
  name: `Тарраск`,
  nameAlt: `Тарраска`,
  nameEn: 'Tarrasque',
  hasSpoilers: true,
  id: CREATURE_TARRASQUE,
  description: [
    `Легендарный тарраск, возможно, — самое страшное чудовище Материального плана. Широко распространено мнение, что существует только одно такое существо, хотя никто не может предсказать, где и когда оно нападёт.

Чешуйчатый и двуногий тарраск достигает 50 футов в высоту, 70 футов в длину и весит сотни тонн. Он двигается словно хищная птица, наклонившись вперёд и используя свой мощный, хлещущий хвост для равновесия. Его пасть, напоминающая пещеру, распахивается достаточно широко, чтоб проглотить что угодно, кроме самых огромных существ. И голод его настолько велик, что он может сожрать население целого города.`,
    {
      header: 'Легендарный разрушитель',
      text: `Разрушительный потенциал тараска настолько велик, что в некоторых культурах он является частью религиозных учений, вплетающих его редкое появление в истории о божественном суде и гневе. Легенды гласят, что тарраск дремлет в своём тайном убежище под землёй, оставаясь в покое десятки и сотни лет. Просыпаясь в ответ на некий непостижимый космический зов, он восстаёт из глубин, чтобы уничтожить всё на своём пути.`,
      source: {
        id: SOURCE_MM,
        page: 279,
      },
    }
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_TITAN,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 279,
  },
  armor: {
    ac: 25,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 33,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 30,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 30,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
    },
  ],
  cr: CR_30,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    {
      name: `Отражающий панцирь`,
      description: `Каждый раз, когда ★СУЩЕСТВО★ становится целью заклинания [Волшебная стрела](SPELL:${SPELL_MAGIC_MISSILE}), заклинания с эффектом «линия», или заклинания, требующего бросок дальнобойной атаки, бросьте к6.

* При результате от «1» до «5» ★СУЩЕСТВО★ не попадает под эффект заклинания.
* При результате «6» ★СУЩЕСТВО★ не попадает под эффект заклинания, и эффект отражается в заклинателя, как если бы ★он★ исходил от ★СУЩЕСТВО★, делая целью заклинателя.`,
    },
    ABILITY_SIEGE_MONSTER,
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ может использовать _Ужасающее присутствие_. Затем ★он★ совершает пять атак: одну _Укусом_, две _Когтями_, одну _Рогами_, и одну _Хвостом_. Вместо _Укуса_ он может использовать _Проглатывание_.`,
    },
    {
      name: `Укус`,
      description: `Если цель — существо, она становится схваченной (СЛ высвобождения 20). Пока цель схвачена, она обездвижена, и тараск не может кусать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 12,
          diceBonus: 10,
        },
      },
    },
    {
      name: `Коготь`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 15,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 10,
        },
      },
    },
    {
      name: `Хвост`,
      description: `Если цель — существо, она должна пройти испытание Силы СЛ 20, иначе будет сбита с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 20,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 10,
        },
      },
    },
    {
      id: ABILITY_FRIGHTFUL_PRESENCE,
      range: 120,
      witSave_DC: 17,
    },
    {
      name: `Проглатывание`,
      description: `★СУЩЕСТВО★ совершает одну атаку _Укусом_ по схваченному существу с размером не больше Большого. Если эта атака попадает, цель получает урон от _Укуса_, становится проглоченной, и захват прекращается. Будучи проглоченным, существо является слепым и обездвиженым, обладает полным укрытием от атак и прочих эффектов, исходящих снаружи, и получает урон кислотой 56 (16к6) в начале каждого хода ★СУЩЕСТВО★.

Если ★СУЩЕСТВО★ получает в течение одного хода от находящегося внутри ★него★ существа урон 60 или больше, ★он★ должен в конце этого хода пройти испытание Телосложения СЛ 20, иначе изрыгнёт всех проглоченных существ, которые падают ничком в пространстве в пределах 10 футов от тараска.

Если ★СУЩЕСТВО★ умирает, проглоченное существо перестаёт быть обездвиженым ★им★, и может высвободиться из трупа, используя 30 футов перемещения, и на выходе падает ничком.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: `Атака`,
      description: `★СУЩЕСТВО★ совершает одну атаку _Когтем_ или атаку _Хвостом_.`,
    },
    {
      name: `Перемещение`,
      description: `★СУЩЕСТВО★ перемещается на расстояние, не превышающее половину своей скорости.`,
    },
    {
      name: `Жевание`,
      cost: 2,
      description: `★СУЩЕСТВО★ совершает одну атаку _Укусом_ или использует _Проглатывание_.`,
    },
  ],
  genderId: GENDER_MALE,
}
