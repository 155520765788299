const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SIZE_MEDIUM,
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  SPELL_ANTIMAGIC_FIELD,
  SPELL_DISPEL_MAGIC,
} = require('./../../../../spellIdList')
const {
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {animatedThingsDescriptionList} = require('./../../../../textCommonParts')
const {CR_2} = require('./../../../../crList')
const {CREATURE_RUG_OF_SMOTHERING} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Ковёр удушения',
  nameEn: 'Rug of Smothering',
  id: CREATURE_RUG_OF_SMOTHERING,
  description: [
    `Потенциальных воров и неосторожных героев, прибывших к порогу обители врага, глаза и уши предупреждают о ловушках. Но приключение может закончиться, не начавшись, из-за оживлённого ковра под их ногами, который задушит их до смерти.

Ковёр удушения может быть любой формы, от высококачественной ручной работы, достойной королевы, до грубой циновки в хижине крестьянина.

Существа со способностью чувствовать магию, обнаруживают ложную магическую ауру ковра душителя. В некоторых случаях ковёр удушения маскируется под ковёр-самолёт или другой полезный магический предмет. Однако персонаж, который стоит или сидит на ковре, или пытается произнести командные слова, быстро оказывается в ловушке ковра, который плотно обхватывает свою жертву.`,
    ...animatedThingsDescriptionList,
  ],
  note: {
    text: 'Да не соучастника убийства я ищу, а орудие',
    author: 'Наджа, дознавательница Пылающего Кулака',
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 224,
  },
  armor: 12,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 10,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 10,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 3,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_PSYCHIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_PETRIFIED,
    CONDITION_BLINDED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Восприимчивость к антимагии',
      description: `★СУЩЕСТВО★ ★недееспособен★, пока находится в пределах области [Преграды магии](SPELL:${SPELL_ANTIMAGIC_FIELD}). Став целью [Рассеивания магии](SPELL:${SPELL_DISPEL_MAGIC}), ★СУЩЕСТВО★ должен пройти испытание Телосложения СЛ испытания заклинателя, иначе потеряет сознание на 1 минуту.`,
    },
    {
      name: 'Передача урона',
      description: `Пока ★СУЩЕСТВО★ держит существо в захвате, ★он★ получает только половину урона, причиняемого ★ему★, а существо, которое схватил ★СУЩЕСТВО★, получает вторую половину.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного ковра`,
    },
  ],
  actionList: [
    {
      name: 'Удушение',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            size: {
              max: SIZE_MEDIUM,
            },
          },
        },
        hit: `Существо становится схваченным (СЛ высвобождения 13). Пока цель схвачена, она обездвижена, ослеплена, и рискует задохнуться, а ★СУЩЕСТВО★ не может душить другую цель. Кроме того, цель в начале каждого своего хода получает дробящий урон 10 (2к6+3)`,
      },
    },
  ],
  genderId: GENDER_MALE,
}
