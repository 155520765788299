const {MAGIC_ITEM_LIVING_ARMOR} = require('./../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../magicItemTypeList')
const {SOURCE_ERFTLW} = require('./../../../sourceList')

const siblingIdList = require('./siblingIdList')
const gearCategoryList = require('./gearCategoryList')

module.exports = {
  id: MAGIC_ITEM_LIVING_ARMOR,
  name: `Живой доспех`,
  nameEn: `Living Armor`,
  type: MGC_TYPE_ARMOR,
  gearCategory: gearCategoryList,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `Этот отвратительный доспех состоит из чёрного хитина, под которым пульсируют вены и блестят красные сухожилия.

Для настройки на _Живой доспех_ Вы должны носить его в течение всего периода настройки, пока усики на его внутренней стороне врастают в Ваc.

Нося этот доспех, Вы получаете +1 КД и получаете сопротивление к типам урона:

* Некротическому
* Психическому
* Ядом
`,
  symbiotic: `_Живой доспех_ не может быть снят, пока Вы на него настроены и Вы не можете добровольно прекратить настройку на него.

Если Вы становитесь целью заклинания, которое снимает проклятье, Ваша настройка на _Живой доспех_ оканчивается и он открепляется от Вас.

_Живой доспех_ нужно питать свежей кровью. Сразу после того, как Вы завершаете длинный отдых, Вы должны либо скормить _Живому доспеху_ половину (округляя вверх) оставшихся у Вас костей хитов, либо получить 1 уровень истощения.`,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_ERFTLW,
    page: 279,
  },
}
