module.exports = [
  require('./saber_toothed_tiger'),
  require('./sahuagin'),
  require('./sahuagin_baron'),
  require('./sahuagin_priestess'),
  require('./salamander'),
  require('./satyr'),
  require('./scarecrow'),
  require('./scorpion'),
  require('./scout'),
  require('./sea_hag'),
  require('./sea_horse'),
  require('./shadow'),
  require('./shadow_demon'),
  require('./shambling_mound'),
  require('./shield_guardian'),
  require('./shrieker'),
  require('./skeleton'),
  require('./slaad_blue'),
  require('./slaad_death'),
  require('./slaad_gray'),
  require('./slaad_green'),
  require('./slaad_red'),
  require('./slaad_tadpole'),
  require('./smoke_mephit'),
  require('./solar'),
  require('./spectator'),
  require('./specter'),
  require('./spider'),
  require('./spined_devil'),
  require('./spirit_naga'),
  require('./sprite'),
  require('./spy'),
  require('./steam_mephit'),
  require('./stirge'),
  require('./stone_giant'),
  require('./stone_golem'),
  require('./storm_giant'),
  require('./succubus'),
]
