const {
  ABILITY_AMPHIBIOUS,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  SOURCE_ROT,
} = require('./../../../../sourceList')
const {
  CREATURE_SCRAG,
  CREATURE_TROLL,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Скраг',
  nameEn: 'Scrag',
  id: CREATURE_SCRAG,
  parentId: CREATURE_TROLL,
  extendPropCollection: {
    speed: {
      [SPEED_SWIM]: 30,
    },
    source: {
      id: SOURCE_ROT,
      page: 27,
    },
    featureList: [
      ABILITY_AMPHIBIOUS,
    ],
  },
}
