const {
  SOURCE_MTOF,
} = require('./../../../../sourceList')
const {
  CR_16,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ARCHDEVIL,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_ALTER_SELF,
  SPELL_ANIMATE_DEAD,
  SPELL_BESTOW_CURSE,
  SPELL_CONFUSION,
  SPELL_FEEBLEMIND,
  SPELL_GREATER_INVISIBILITY,
  SPELL_MAJOR_IMAGE,
  SPELL_MISLEAD,
  SPELL_MODIFY_MEMORY,
  SPELL_NONDETECTION,
  SPELL_SENDING,
  SPELL_SUGGESTION,
  SPELL_SYMBOL,
} = require('./../../../../spellIdList')
const {
  CAST_MATERIAL,
} = require('./../../../../castComponentList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  archDevilDescriptionList,
  archDevilNoteList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_REGENERATION,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_GERYON,
  CREATURE_MOLOCH,
  CREATURE_TITIVILUS,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Титивилий',
  nameAlt: 'Титивилус',
  nameEn: 'Titivilus',
  id: CREATURE_TITIVILUS,
  hasSpoilers: true,
  description: [
    `Мрачный Повелитель Второго слоя, Диспатер, правит из своего железного дворца, скрываясь ото всех, в окружении своего дворца, запутанных коридоров, железных стен, дьявольских ловушек и своих чудовищных слуг. Его паранойя настолько усилилась, что теперь он перемещается не дальше расползающегося города, что находится снаружи его восхитительного дворца. Диспатеру известно, что у него враги отовсюду, способные сделать то же, что и сделали с [Герионом](CREATURE:${CREATURE_GERYON}), [Молохом](CREATURE:${CREATURE_MOLOCH}) и многими другими.

Диспатер по-настоящему боится, но истинная угроза исходит не от них. Большой ошибкой повелителя было позволение себе быть обманутым Титивилием, что был главным советником во дворце Диспатера.

Хоть он и уступает в физической силе и мощи, по сравнению с другими архидьяволами, Титивилий компенсирует всё это своей коварностью. Хитрый и расчётливый политикан, забравшийся на высший ранг, благодаря обманам и интригам, чтобы стать вторым по могущественности исчадием в Дисе. Обаятелен, приятен, и искусен в переговорах, Титивилий, способен искажать слова так, чтобы оставить своих жертв сбитыми с толку и верящими, что Титивилий оказался им другом. Благодаря таким навыкам, Титивилий смог повлиять на всех, кто стоял у него на пути к большей власти, либо завоевав их доверие, либо избавившись от них, как от своей угрозы.

Заполучив нужную ему позицию, Титивилий убедил Диспатера, что против него ведётся бесчисленное множество заговоров, и даже сам Асмодей желает избавиться от Диспатера. Следуя советам обманщика, Диспатер покинул свой дворец, оставил ежедневные обязательства Титивилию и уполномочил того отвечать и вести переговоры со смертными, что пытаются призвать Диспатера. Теперь, Титивилий выступает за своего мастера и говорит за него, а такое стечение обстоятельств привело к слухам, что, либо Титивилий — замаскированный Диспатер, либо Титивилий избавился от эрцгерцога и полностью заменил его.

Титивилий понимает, что его положение неизбежно шаткое. Всё-таки, одобрение Диспатером его замыслов и советов может длится лишь до тех пор, пока не объявится другой заговорщик и не расскажет тому правду. Чтобы этого не случилось, Титивилий уже начал нанимать чужаков для устранения проблемных дьяволов и для собственной защиты от критики в его сторону, и, прежде всего, для создания затруднений, которые он способен решить, заслужив большее доверие в глазах своего хозяина. Титивилий считает, что искатели приключений подходят для таких задач и нанимает их либо напрямую, либо через посредников, а затем избавляется от них, если так будет необходимо.`,
    ...archDevilDescriptionList,
  ],
  note: [
    {
      text: `На самом деле, я восхищён Титивилием. Если бы он не был настолько безжалостно злым, он бы был превосходным руководителем Баланса`,
      author: `Архимаг Морденкайнен`,
    },
    ...archDevilNoteList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ARCHDEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 194,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 22,
    [PARAM_CON]: 17,
    [PARAM_INT]: 24,
    [PARAM_WIT]: 22,
    [PARAM_CHA]: 26,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_16,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      id: ABILITY_REGENERATION,
      regen: 10,
      other: `. Если ★он★ получает урон излучением, эта особенность не работает до начала ★его★ следующего хода. ★СУЩЕСТВО★ умирает только в случае, если начинает ход с 0 хитами и не может регенерировать`,
    },
    {
      name: 'Чревовещание',
      description: `Когда бы ни говорил ★СУЩЕСТВО★, ★он★ может выбрать точку в пределах 60 футов от себя; ★его★ голос будет звучать из этой точки.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_ALTER_SELF,
          SPELL_ANIMATE_DEAD,
          SPELL_BESTOW_CURSE,
          SPELL_CONFUSION,
          SPELL_MAJOR_IMAGE,
          SPELL_MODIFY_MEMORY,
          SPELL_NONDETECTION,
          SPELL_SENDING,
          SPELL_SUGGESTION,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_MISLEAD,
          {
            id: SPELL_GREATER_INVISIBILITY,
            comment: 'только на себя',
          },
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_FEEBLEMIND,
          {
            id: SPELL_SYMBOL,
            comment: 'только эффект _«Разногласия»_ или _«Сна»_)',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает одну атаку _Серебряным мечом_ и один раз использует _Устрашающее слово_.`,
    },
    {
      name: 'Серебряный меч',
      description: `Если цель является существом, её максимальное значение хитов уменьшается на половину значения причинённого некротической энергией урона.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 1,
              diceType: 8,
              diceBonus: 4,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 1,
              diceType: 10,
              diceBonus: 4,
              use2Hand: true,
            },
          ],
          {
            type: DAMAGE_NECROTIC,
            diceCount: 3,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Устрашающее слово',
      description: `★СУЩЕСТВО★ выбирает целью видимое им в пределах 10 футов существо. Цель должна пройти испытание Мудрости СЛ 21, иначе станет Испуганной ★им★ на 1 минуту. Испуганное таким образом, существо должно использовать Рывок на каждом своём ходу, чтобы двигаться от ★СУЩЕСТВО★ как можно дальше максимально безопасным путём. Если двигаться некуда, то Рывок не используется. Цель может повторять испытание в конце каждого своего хода, завершая эффект в случае успеха.`,
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
    {
      name: 'Очаровывающее слово',
      description: `★СУЩЕСТВО★ выбирает целью существо в пределах 60 фт. от себя, которое он может видеть. Цель должна пройти испытание Харизмы СЛ 21, или будет Очарована ★им★ на 1 минуту. Очарованное существо может повторять испытание каждый раз, когда ★СУЩЕСТВО★ причиняет ему любой урон. Существо, преуспевшее в испытании, становится иммунным к _Очаровывающему слову_ на следующие 24 часа.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Штурм',
      cost: 2,
      description: `★СУЩЕСТВО★ атакует _Серебряным мечом_, или использует _Устрашающее слово_.`,
    },
    {
      name: 'Развращающее управление',
      description: `★СУЩЕСТВО★ использует _Очаровывающее слово_. Или же ★он★ выбирает очарованное ★им★ существо в пределах 60 футов; это существо должно пройти испытание Харизмы СЛ 21. При провале ★СУЩЕСТВО★ принимает решение, как существо будет действовать в свой следующий ход.`,
    },
    {
      name: 'Телепортация',
      description: `★СУЩЕСТВО★ использует действие _Телепортация_.`,
    },
  ],
}
