const {
  CREATURE_ORC,
  CREATURE_TROLL,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  PARAM_CHA,
} = require('./../../../../../paramList')
const {
  SPELL_HALLOW,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_HALLOW,
  name: 'Святилище',
  nameEn: `Hallow`,
  description: `Вы касаетесь точки и наполняете область вокруг неё святой (или нечестивой) силой. Радиус области может быть с радиусом до 60 футов, и заклинание проваливается, если в этот радиус попадает область, уже находящаяся под действием заклинания _святилище_. Наполненная область подвергается следующим эффектам:
    
1. Во-первых, исчадия, небожители, нежить, феи и элементали не могут войти в эту область, и не могут делать существ, находящихся внутри, очарованными, испуганными и одержимыми. Все существа, очарованные, испуганные или одержимые такими существами, перестают быть очарованными, испуганными и одержимыми, входя в эту область. Вы можете исключить из эффекта один или несколько видов существ.
2. Во-вторых, Вы можете привязать к области дополнительный эффект. Выберите один эффект из прилагающегося списка, или выберите эффект, предложенный Мастером. Некоторые из этих эффектов применяются к существам, находящимся в области; Вы можете указать, будет ли он применяться ко всем существам, существам, служащим определённому божеству или лидеру, или существам определённого вида, например, [оркам](CREATURE:${CREATURE_ORC}) и [троллям](CREATURE:${CREATURE_TROLL}). Когда существо, попадающее под действие эффекта, входит в область заклинания впервые за ход или начинает там ход, оно может пройти испытание Харизмы. При успехе существо игнорирует дополнительный эффект, пока не покинет эту область.
    * **Вечный покой.** Мёртвые тела, похороненные в области, не могут стать нежитью.
    * **Вмешательство в путешествия между измерениями.** Затронутые существа не могут перемещаться и путешествовать, используя телепортацию или перемещение между измерениями и планами.
    * **Дневной свет.** Область освещается ярким светом. Магическая тьма, созданная заклинаниями с уровнем меньше уровня ячейки, использованной Вами для накладывания этого заклинания, не может затмить свет.
    * **Защита от энергии.** Затронутые существа в области получают сопротивление к одному виду урона на Ваш выбор кроме дробящего, колющего и рубящего.
    * **Страх.** Затронутые существа испуганы, пока находятся в области.
    * **Темнота.** Область заполняется темнотой. Обычный свет, а также магический свет, созданный заклинаниями, с уровнем меньше уровня ячейки, использованной Вами для накладывания этого заклинания, не могут осветить область.
    * **Тишина.** Звуки не покидают границы области, и не проникают снаружи внутрь.
    * **Уязвимость энергии.** Затронутые существа в области получают уязвимость к одному виду урона на Ваш выбор кроме дробящего, колющего и рубящего.
    * **Храбрость.** Затронутые существа не могут быть испуганы, пока находятся в области.
    * **Языки.** Затронутые существа могут общаться с любыми другими существами, находящимися в области, даже если у них нет общего языка.`,
  lvl: 5,
  magicSchoolId: MAGIC_EVOCATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  hasConsumable: true,
  materialText: `травы, масла и благовония, стоящие как минимум 1 000 зм, расходуемые заклинанием`,
  duration: Infinity,
  castTime: { timeId: TIME_HOUR, count: 24 },
  effect: {
    savethrowParam: PARAM_CHA,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 276,
    },
    {
      id: SOURCE_SRD,
      page: 152,
    },
  ],
}
