const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_RADIANT,
} = require('./../../../../damageTypeList')
const {
  CREATURE_AVENGER_SPIRIT,
  CREATURE_DEFENDER_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух небожителя-защитника',
  nameEn: 'Defender Spirit',
  id: CREATURE_DEFENDER_SPIRIT,
  parentId: CREATURE_AVENGER_SPIRIT,
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Сияющая булава',
      description: `★СУЩЕСТВО★ может выбрать себя или другое видимое ★им★ в пределах 10 футов существо. Выбранное существо`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 5,
        hit: {
          type: DAMAGE_RADIANT,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
    {
      name: 'Целебное касание',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ касается другого существа. Цель магически восстанавливает 2к8 + круг заклинания хитов.`,
    },
  ],
}
