const {
  CREATURE_BANDIT,
  CREATURE_PIRATE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Пират',
  nameEn: 'Pirate',
  id: CREATURE_PIRATE,
  parentId: CREATURE_BANDIT,
}
