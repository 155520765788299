const {SOURCE_MOOT} = require('./../../sourceList')

module.exports = {
  header: 'Откованные',
  text: `Первые откованные были созданы богом-кузнецом Пирфором. Он дал своим самым преданным последователям секрет вдыхания жизни в подобные металлические существа, дабы они могли имитировать работы Пирфора и создавать в своих кузницах новые формы.

Одни откованные — неусыпные стражи святых мест, а другие служат фамильярами и посыльными. Некоторые откованные создаются для подражания красоте животных смертного мира. Каждый из них выказывает способности, соответствующие его назначению, при этом некоторые из них ведут себя как спутники или стойкие стражи.

Несколько чрезвычайно редких и ценных откованных были созданы рукой самого Пирфора. Некоторые из этих великолепных творений теперь являются реликвиями монархов, другие же потеряны в песках времени или охраняются древними монстрами.

# Природа конструктов

Откованным не нужно дышать, есть, пить и спать.`,
  source: {
    id: SOURCE_MOOT,
    page: 210,
  },
}
