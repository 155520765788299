const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {CREATURE_TYPE_WARFORGED} = require('./../../creatureTypeIdList')
const {SOURCE_ERFTLW} = require('./../../sourceList')

module.exports = {
  id: CREATURE_TYPE_WARFORGED,
  nameEn: 'Warforged',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'кованый',
        genitive: 'кованого',
        dative: 'кованому',
        accusative: 'кованого',
        instrumental: 'кованым',
        prepositional: 'кованом',
      },
      [GENDER_FEMALE]: {
        nominative: 'кованая',
        genitive: 'кованой',
        dative: 'кованой',
        accusative: 'кованую',
        instrumental: 'кованой',
        prepositional: 'кованой',
      },
    },
    plural: {
      nominative: 'кованые',
      genitive: 'кованых',
      dative: 'кованым',
      accusative: 'кованых',
      instrumental: 'коваными',
      prepositional: 'кованых',
    },
  },
  description: {
    header: `Кованые`,
    text: `Кованые были построены для битв Последней Войны. Хотя первые кованые были безмозглыми автоматами, дом Каннит выделил огромные ресурсы на улучшение этих стальных солдат.

Неожиданный прорыв позволил создавать разумных солдат, породив то, что многие стали неохотно считать новым видом. 

Кованые сделаны из дерева и металла, но они чувствуют боль и у них есть эмоции.

Построенные как оружие, они теперь ищут своё предназначение после войны. Кованый может быть верным союзником, хладнокровным убийцей или мечтателем, ищущим смысл.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 36,
    },
  },
}
