const {
  SPELL_DISPEL_MAGIC,
  SPELL_DRUID_GROVE,
  SPELL_ENTANGLE,
  SPELL_GUST_OF_WIND,
  SPELL_SPIKE_GROWTH,
  SPELL_WIND_WALL,
} = require('./../../../../../spellIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  CREATURE_AWAKENED_TREE,
  CREATURE_GROVE_GUARDIAN,
} = require('./../../../../../creatureIdList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')

module.exports = {
  id: SPELL_DRUID_GROVE,
  name: 'Роща друида',
  nameEn: 'Druid Grove',
  description: `Вы призываете духов природы для защиты области на открытом воздухе или под землей. Эта область может быть кубом с длиной ребра от 30 до 90 футов. Здания и другие сооружения исключаются из этой области. Если Вы сотворяете это заклинание в одном и том же месте каждый день в течение года, заклинание длится до тех пор, пока не будет рассеяно.

Заклинание создает следующие эффекты в пределах области. Когда Вы накладываете это заклинание, Вы можете указать существ как друзей, которые будут невосприимчивы к эффектам заклинания. Вы также можете указать кодовое слово, которое, при произношении, делает говорящего невосприимчивым к этим эффектам.

Вся защищённая область источает магию. [Рассеивания магии](SPELL:${SPELL_DISPEL_MAGIC}), наложенное на область, при успехе удаляет только один из нижеследующих эффектов, а не всю область. Заклинатель выбирает, какой эффект окончить. Только когда все эффекты окончены, заклинание рассеивается.

# Непроглядный туман

Вы можете заполнить любое количество 5-футовых квадратов на земле густым туманом, делая их сильно заслонённой областью. Туман достигает 10 футов в высоту. Кроме того, каждый фут движения через туман стоит 2 дополнительных фута. Для существа, невосприимчивого к данному эффекту, туман ничего не скрывает и выглядит как лёгкая дымка с песчинками зеленого света, плавающими в воздухе.

# Цепкий подлесок

Вы можете заполнить любое количество 5-футовых квадратов на земле, которые не заполнены туманом, цепкими сорняками и лозой, как если бы они были созданы заклинанием [Опутывание](SPELL:${SPELL_ENTANGLE}). Для существа, невосприимчивого к данному эффекту, сорняки и лоза ощущаются мягкими и меняют свою форму, чтобы служить временными сидениями или постелями.

# Хранители рощи

Вы можете [оживить до четырех деревьев](CREATURE:${CREATURE_GROVE_GUARDIAN}) в этой области, заставляя их выкорчевать себя из земли. Эти деревья имеют ту же статистику, что и [Пробуждённое дерево](CREATURE:${CREATURE_AWAKENED_TREE}), за исключением того, что они не могут говорить, и их кора покрыта друидическими символами. Если любое существо, не иммунное к этому эффекту, заходит в защищаемую область, хранители рощи сражаются, пока не прогонят или не убьют вторженцев. Хранители рощи также подчиняются Вашим устным командам (действие не требуется), которые Вы произносите в этой области. Если Вы не даёте им команд, и в области нет незваных гостей, хранители рощи бездействуют. Хранители рощи не могут покинуть охраняемую область. Когда заклинание оканчивается, магия, оживляющая их, исчезает, и деревья снова укореняются, если это возможно.

# Дополнительный эффект от заклинания

Вы можете выбрать один из следующих магических эффектов в защищенной области:

* Постоянный [порыв ветра](SPELL:${SPELL_GUST_OF_WIND}) в двух местах по Вашему выбору
* [Шипы](SPELL:${SPELL_SPIKE_GROWTH}) в одном месте по Вашему выбору
* [Стена ветров](SPELL:${SPELL_WIND_WALL}) в двух местах по Вашему выбору

Для существа, невосприимчивого к данному эффекту, ветры — это приятный легкий бриз, а шипы безвредны.`,
  lvl: 6,
  magicSchoolId: MAGIC_ABJURATION,
  range: 0,
  hasConsumable: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  createdCreatureId: CREATURE_GROVE_GUARDIAN,
  materialText: `омела, расходуемая заклинанием, которая была собрана золотым серпом под светом полной луны`,
  note: {
    text: `То есть каждому друиду в школе друидов выдают по золотому серпу? Или надо свой приносить?`,
    author: `Эспумизан Коликит, гоблин волшебник`,
  },
  duration: {timeId: TIME_HOUR, count: 24},
  castTime: {timeId: TIME_MINUTE, count: 10},
  source: {
    id: SOURCE_XGTE,
    page: 165,
  },
}
