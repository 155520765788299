const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_MIMIC,
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  mimicDescription,
  mimicNoteList,
} = require('./../../../../textCommonParts')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CONDITION_PRONE} = require('./../../../../conditionList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_MIMIC} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Мимик',
  nameEn: 'Mimic',
  id: CREATURE_MIMIC,
  note: mimicNoteList,
  description: mimicDescription,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_MIMIC,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 210,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 15,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 15,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_ACID,
  ],
  immunityConditionList: [
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в любой предмет, или принять свой истинный, бесформенный облик. Во всех обликах его характеристики остаются теми же самыми. Всё несомое и носимое им снаряжение не превращается. Он принимает свой истинный облик, если умирает.`,
    },
    {
      name: 'Клейкий',
      comment: 'только в облике предмета',
      description: `★СУЩЕСТВО★ приклеивается ко всему, что ★его★ касается. Существо с размером не больше Огромного, к которому приклеился ★СУЩЕСТВО★, становится схваченным ★им★ (СЛ высвобождения 13). Проверки характеристик для высвобождения из этого захвата совершаются с помехой.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      comment: 'только в облике предмета',
      appearance: `обычного предмета`,
    },
    {
      name: 'Борец',
      description: `★СУЩЕСТВО★ совершает с преимуществом броски атаки по всем существам, схваченным им.`,
    },
  ],
  actionList: [
    {
      name: 'Ложноножка',
      description: `Если ★СУЩЕСТВО★ находится в облике предмета, цель попадает под действие его особенности _Клейкий_.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 3,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 1,
            diceType: 8,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
