const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_KEEN_SMELL} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_BADGER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Барсук',
  nameEn: 'Badger',
  id: CREATURE_BADGER,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 320,
  },
  armor: 10,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_DIG]: 5,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 12,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 5,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_KEEN_SMELL,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 0,
          diceType: 0,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
