const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TIAMAT,
  CREATURE_WINGED_KOBOLD,
} = require('./../../../../creatureIdList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KOBOLD,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_PACK_TACTICS,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  koboldDescriptionList,
  koboldNote,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_1_4} = require('./../../../../crList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Урд',
  nameAlt: 'Крылатый кобольд',
  nameEn: 'Urd',
  nameEnAlt: 'Winged Kobold',
  id: CREATURE_WINGED_KOBOLD,
  description: [
    `Некоторые кобольды рождены с кожистыми крыльями и могут летать. Известные как урды, они любят таиться на высоких уступах и бросаться на прохожих. Хотя крылья урдов считаются подарком от [Тиамат, Королевы Драконов](CREATURE:${CREATURE_TIAMAT}), бескрылые кобольды завидуют этому подарку и не ладят с урдами.`,
    ...koboldDescriptionList,
  ],
  note: koboldNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_KOBOLD,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 177,
  },
  armor: 13,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 9,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 8,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_COMMON,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_SUNLIGHT_SENSITIVITY,
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
    {
      name: 'Брошенный камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 5,
        range: {
          normal: 30,
          max: 120,
        },
        target: {
          count: 1,
          comment: 'непосредственно под ★СУЩЕСТВО★',
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
