const {CREATURE_TYPE_JERMLAINE} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_JERMLAINE,
  nameEn: 'Jermlaine',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'гремлин',
        genitive: 'гремлина',
        dative: 'гремлину',
        accusative: 'гремлина',
        instrumental: 'гремлином',
        prepositional: 'гремлине',
      },
      [GENDER_FEMALE]: {
        nominative: 'гремлинша',
        genitive: 'гремлинши',
        dative: 'гремлинше',
        accusative: 'гремлиншу',
        instrumental: 'гремлиншой',
        prepositional: 'гремлинше',
      },
    },
    plural: {
      nominative: 'гремлины',
      genitive: 'гремлинов',
      dative: 'гремлинам',
      accusative: 'гремлинов',
      instrumental: 'гремлинами',
      prepositional: 'гремлинах',
    },
  },
}
