const {
  CREATURE_FLAMING_FIST,
  CREATURE_FLAMING_FIST_FIRE,
  CREATURE_FLAMING_FIST_GAUNTLET,
} = require('./../../creatureIdList')
const {
  SOURCE_BGDIA,
  SOURCE_TOA,
} = require('./../../sourceList')

module.exports = [
  {
    header: 'Пылающий Кулак',
    text: `Красно-золотой герб отряда наёмников под названием Пылающий Кулак стал одним из символов Врат Балдура. Совет Четырёх платит Пылающему Кулаку, используя его как городскую армию. Сейчас верховный герцог Ульдер Рэйвенгард — его единоличный глава.

Пылающий Кулак в основном патрулирует Нижний Город, но формально имеет полномочия и во Внешнем. Отряду хватает дел и внутри городских стен без того чтобы удаляться за ворота. Впрочем, порой Кулак нанимает сторонних бойцов, когда сил не хватает.

Сейчас в Пылающем Кулаке служат тысячи солдат в самих Вратах Балдура, в крепости Змиева Скала на реке Чионтар и в дальних аванпостах вроде форта Белуриан на Чульте. Пылающий Кулак предлагает работу и чувство товарищества всякому, кто способен держать меч и выполнять приказы. В рядах отряда встречаются коренные балдурцы, иммигранты, бывшие преступники и искатели приключений в отставке.`,
    source: {
      id: SOURCE_BGDIA,
      page: 162,
    },
  },
  {
    header: 'Ранги',
    text: `Пылающий Кулак признает только шесть рангов:

* [Кулак (рядовой)](CREATURE:${CREATURE_FLAMING_FIST}),
* [Перчатка (капрал)](CREATURE:${CREATURE_FLAMING_FIST_GAUNTLET}),
* Манип (сержант), 
* Огонь (лейтенант), 
* Пламя (майор), 
* Маршал (генерал).
`,
    source: {
      id: SOURCE_TOA,
      page: 30,
    },
  },
  {
    header: 'Пылающий Кулак',
    text: `Пылающий Кулак — это организация наёмников, чья штаб-квартира находится во Вратах Балдура (которым они безоговорочно верны). На Чульте Кулак расположился в крепости Форт Белуриан. Оттуда Пылающий Кулак посылает патрули в джунгли ради «усмирения» территории и захвата сокровищ.`,
    source: {
      id: SOURCE_TOA,
      page: 30,
    },
  },
]
