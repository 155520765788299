const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_MEPHIT,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_AURAN,
  LANG_IGNAN,
} = require('./../../../../languageIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_SMOKE_MEPHIT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {mephitDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPELL_DANCING_LIGHTS} = require('./../../../../spellIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Дымовой мефит',
  nameEn: 'Smoke Mephit',
  description: [
    `Это грубые, ленивые, сотворённые из воздуха и огня создания, которые постоянно дымятся. Они редко говорят правду и любят издеваться над другими существами, вводя их в заблуждение.`,
    mephitDescription,
  ],
  id: CREATURE_SMOKE_MEPHIT,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_MEPHIT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 204,
  },
  armor: 12,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AURAN,
    LANG_IGNAN,
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Предсмертная вспышка',
      description: `Когда ★СУЩЕСТВО★ умирает, ★он★ оставляет после себя облачко дыма, заполняющее сферу с радиусом 5 футов и центром на его пространстве. Пространство сферы сильно заслонено. Ветер рассеивает облачко, которое в противном случае висит 1 минуту.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DANCING_LIGHTS,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Пепельное дыхание',
      restore: 6,
      description: `★СУЩЕСТВО★ выдыхает горячий пепел 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 10, иначе станут ослеплёнными до конца следующего хода мефита.`,
    },
  ],
  genderId: GENDER_MALE,
}
