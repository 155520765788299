const { CREATURE_RAVEN } = require('./../../../../creatureIdList')
const { GENDER_MALE } = require('./../../../../genderList')
const { MAGIC_ITEM_CLOAK_OF_THE_RAVEN } = require('./../../../../magicItemIdList')
const { MGC_RARITY_RARE } = require('./../../../../magicItemRarityList')
const { MGC_TYPE_WONDROUS_ITEM } = require('./../../../../magicItemTypeList')
const { SOURCE_DDHC_MORD_01 } = require('./../../../../sourceList')
const { SPELL_POLYMORPH } = require('./../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_CLOAK_OF_THE_RAVEN,
  name: 'Плащ ворона',
  nameEn: 'Cloak of the Raven',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Этот плащ сделан из тысячи крупных чёрных перьев.

Нося этот плащ, Вы совершаете с преимуществом проверки Ловкости (Скрытность). В области тусклого света или тьмы Вы можете схватить края плаща обеими руками и использовать его для полёта со скоростью 40 футов. Если Вы отпустите плащ во время полёта или перестанете находиться в области тусклого света или тьмы, Вы теряете эту скорость полёта.

Если Вы носите этот плащ, и находитесь в области тусклого света или тьмы, Вы можете действием наложить на себя [Превращение](SPELL:${SPELL_POLYMORPH}) и стать [вороном](CREATURE:${CREATURE_RAVEN}). Пока Вы находитесь в облике [ворона](CREATURE:${CREATURE_RAVEN}), Вы сохраняете свои значения Интеллекта, Мудрости и Харизмы. Это свойство плаща нельзя использовать повторно до следующего рассвета.`,
  genderId: GENDER_MALE,
  spellIdList: [SPELL_POLYMORPH],
  source: {
    id: SOURCE_DDHC_MORD_01,
    page: 26,
  },
}