const {
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')
const {CAT_SIMPLE_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_YKLWA} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_TOA} = require('./../../../sourceList')

module.exports = {
  id: GEAR_YKLWA,
  genderId: GENDER_FEMALE,
  name: 'Иклва',
  nameByCase: {
    nominative: 'иклва',
    genitive: 'иклвы',
    accusative: 'иклву',
    instrumental: 'иклвой',
  },
  nameEn: 'Yklwa',
  description: 'Иклва — это традиционное оружие ближнего боя чультских воинов. Иклва состоит из 3-футовой деревянной рукояти со стальным или каменным лезвием длиной до 18 дюймов.',
  damage: {
    diceType: 8,
    diceCount: 1,
  },
  cost: 100,
  damageType: DAMAGE_PIERCING,
  source: {
    id: SOURCE_TOA,
    page: 32,
  },
  weaponPropList: [
    WEAPON_THROWN,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 10,
        max: 30,
      },
    },
  ],
  category: CAT_SIMPLE_MELEE_WEAPON,
}
