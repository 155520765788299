const {
    SOURCE_IDRotF,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_MAGEN,
  } = require('./../../../../creatureTypeIdList'),
  {
    SPELL_CREATE_MAGEN,
    SPELL_SUGGESTION,
  } = require('./../../../../spellIdList'),
  {
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    LANG_ITS_CREATOR,
    LANG_TELEPATHY,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_UNUSUAL_NATURE,
  } = require('./../../../../creatureAbilityList'),
  {
    magenDescription,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_HYPNOS_MAGEN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Маген-гипнос',
  nameEn: 'Hypnos Magen',
  id: CREATURE_HYPNOS_MAGEN,
  sizeType: SIZE_MEDIUM,
  createdBySpellId: SPELL_CREATE_MAGEN,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_MAGEN,
  ],
  description: [
    `Магены-гипносы — телепаты и используют свою силу внушения для управления другими. Создатели используют, чтобы заставлять врагов отступать или сдаваться.

Маген-гипнос не носит оружия, полагаясь на свои психические способности чтобы манипулировать другими существами и вредить им.`,
    magenDescription,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_IDRotF,
    page: 302,
  },
  armor: 12,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
    {
      id: LANG_TELEPATHY,
      range: 30,
    },
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Пламенная кончина',
      nameEn: 'Fiery End',
      description: `Если ★СУЩЕСТВО★ умирает, его тело распадается в безвредном хлопке огня и дыма, оставляя после себя всё, что ★носил★ и ★нёс★.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_UNUSUAL_NATURE,
  ],
  actionList: [
    {
      name: 'Пси-хлыст',
      description: `Когда ★СУЩЕСТВО★ нацеливается на одно видимое ★им★ в пределах 60 футов существо, ★его★ глаза вспыхивают серебром. Цель должна пройти испытание Мудрости СЛ 12 или получить 11 (2к10) психического урона.`,
    },
    {
      name: 'Внушение',
      description: `★СУЩЕСТВО★ сотворяет заклинание [Внушение](SPELL:${SPELL_SUGGESTION}) (СЛ испытания 12) без материальных компонентов. Целью должно быть существо, с которым ★СУЩЕСТВО★ может общаться телепатически. Цель, преуспевшая в испытании, становится невосприимчиваой к заклинанию [Внушение](SPELL:${SPELL_SUGGESTION}) этого магена на следующие 24 часа. Заклинательная способность магена — Интеллект.`,
    },
  ],
}
