const {CREATURE_UMBER_HULK} = require('./../../../../creatureIdList')
const {GENDER_MULTIPLE} = require('./../../../../genderList')
const {MAGIC_ITEM_CLAWS_OF_THE_UMBER_HULK} = require('./../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_CLAWS_OF_THE_UMBER_HULK,
  name: 'Когти бурого увальня',
  nameEn: 'Claws of the Umber Hulk',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Эти тяжёлые перчатки из бурого железа сделаны в форме когтей [бурого увальня](CREATURE:${CREATURE_UMBER_HULK}) и закрывают руки носителя вплоть до локтей. Если вы носите эти перчатки, то получаете скорость копания 20 фт. и можете раскапывать даже твёрдый камень со скоростью 1 фут в раунд.

Кроме того, нося когти, Вы можете использовать их в качестве рукопашного оружия. Считается, что Вы владеете этим оружием. При попадании когти наносят рубящий урон 1к8 (Ваш модификатор Силы добавляется к броскам атаки и урона в обычном порядке).

Нося когти, Вы не можете манипулировать предметами и использовать заклинания с соматическим компонентом.`,
  genderId: GENDER_MULTIPLE,
  source: {
    id: SOURCE_PotA,
    page: 227,
  },
}
