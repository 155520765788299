const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {SPELL_SYMBOL} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../../paramList')
const {
  CONDITION_FRIGHTENED,
  CONDITION_INCAPACITATED,
  CONDITION_STUNNED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../../conditionList')

module.exports = {
  id: SPELL_SYMBOL,
  name: 'Знак',
  nameEn: `Symbol`,
  description: `Когда Вы накладываете это заклинание, Вы рисуете губительный знак на любой поверхности (такой как пол, стена или стол) или внутри предмета, который можно закрыть, чтобы скрыть знак (например, в книге, свитке или сундуке). Если Вы выбрали поверхность, знак может покрыть площадь не больше 10 футов диаметром. Если Вы выбрали предмет, он должен оставаться на месте; если предмет перенесут более чем на 10 футов от того места, где было активировано это заклинание, знак будет сломан, и заклинание окончится, не сработав.

Знак практически невидим, для его обнаружения требуется успешная проверка Интеллекта (Анализ) против СЛ Ваших заклинаний.

При накладывании заклинания Вы определяете, что вызовет срабатывание знака. Для знаков, написанных на поверхности, чаще всего используют касание или наступание на знак, снятие другого предмета, закрывающего знак, приближение к нему на определённое расстояние, или манипулирование предметом, содержащим его. Для знаков, написанных внутри предмета, чаще всего используют открывание предмета, приближение к нему на определённое расстояние, осмотр или прочтение знака.

Вы можете уточнить условие, чтобы заклинание активировалось только при определённых условиях или в зависимости от физических характеристик существа (таких как рост и вес), или его вида (например, можно выставить защиту от карг или от перевёртышей). Вы можете также определить существ, которые не будут активировать знак, например, тех, кто произнесёт пароль.

Когда Вы рисуете знак, выберите из приведённого списка один эффект. Сработав, знак вспыхивает, заполняя на 10 минут сферу с радиусом 60 футов тусклым светом, после чего заклинание оканчивается. Все существа, находящиеся в сфере, когда знак активируется, становятся целью эффекта заклинания, так же как существа, впервые за ход входящие в сферу или оканчивающие в ней ход.

* **Безнадёжность.** Все цели должны пройти испытание Харизмы. При провале цель охватывается отчаянием на 1 минуту. В это время она не может атаковать и нацеливаться на существ вредоносными умениями, заклинаниями и прочими магическими эффектами.

* **Безумие.** Все цели должны пройти испытание Интеллекта. При провале цель становится безумной на 1 минуту. Безумное существо не может совершать действия, не понимает ничего, что говорят другие существа, не может читать, и произносит только неразборчивую чушь. Мастер контролирует его перемещения, которые весьма хаотичны.

* **Боль.** Все цели должны пройти испытание Телосложения, становясь недееспособными от мучительной боли на 1 минуту при провале.

* **Ошеломление.** Все цели должны пройти испытание Мудрости, становясь ошеломлёнными на 1 минуту при провале.

* **Разногласие.** Все цели должны пройти испытание Телосложения. При провале цель спорит и ругается с другими существами в течение 1 минуты. В это время она не способна осмысленно общаться и совершает броски атаки и проверки характеристик с помехой.

* **Смерть.** Все цели должны пройти испытание Телосложения, получая урон некротической энергией 10к10 при провале, или половину этого урона при успехе.

* **Сон.** Все цели должны пройти испытание Мудрости, теряя сознание на 10 минут при провале. Существо пробуждается, если получает урон или если кто-то действием потрясёт его или даст пощёчину.

* **Страх.** Все цели должны пройти испытание Мудрости, становясь испуганными на 1 минуту при провале. Будучи испуганной, цель бросает всё, что держит, и должна в каждом своём ходу перемещаться как минимум на 30 футов от знака, если это возможно.`,
  lvl: 7,
  magicSchoolId: MAGIC_ABJURATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  hasConsumable: true,
  materialText: `ртуть, фосфор и толчённые алмаз с опалом, общей стоимостью как минимум 1 000 зм, расходуемые заклинанием`,
  duration: [Infinity, 'пока не сработает'],
  castTime: { timeId: TIME_MINUTE, count: 1 },
  effect: [
    {
      savethrowParam: PARAM_CHA,
    },
    {
      savethrowParam: PARAM_INT,
    },
    {
      savethrowParam: PARAM_CON,
      condition: CONDITION_INCAPACITATED,
    },
    {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_STUNNED,
    },
    {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_NECROTIC,
        halfOnSuccess: true,
        diceCount: 10,
        diceType: 10,
      },
    },
    {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_UNCONSCIOUS,
    },
    {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_FRIGHTENED,
    },
  ],
  source: [
    {
      id: SOURCE_PHB,
      page: 229,
    },
    {
      id: SOURCE_SRD,
      page: 184,
    },
  ],
}
