const listToCollectionById = require('./../utils/listToCollectionById')
const sortByName = require('./../utils/sortByName')

const MAGIC_ABJURATION = 'abjuration'
const MAGIC_CONJURATION = 'conjuration'
const MAGIC_DIVINATION = 'divination'
const MAGIC_ENCHANTMENT = 'enchantment'
const MAGIC_EVOCATION = 'evocation'
const MAGIC_ILLUSION = 'illusion'
const MAGIC_NECROMANCY = 'necromancy'
const MAGIC_TRANSMUTATION = 'transmutation'

const magicList = [
  {
    id: MAGIC_ABJURATION,
    name: 'Ограждение',
    nameFull: 'Магия школы Ограждения',
    nameEn: 'Abjuration',
  },
  {
    id: MAGIC_CONJURATION,
    name: 'Вызов',
    nameFull: 'Магия школы Вызова',
    nameEn: 'Conjuration',
  },
  {
    id: MAGIC_DIVINATION,
    name: 'Прорицание',
    nameFull: 'Магия школы Прорицания',
    nameEn: 'Divination',
  },
  {
    id: MAGIC_ENCHANTMENT,
    name: 'Очарование',
    nameFull: 'Магия школы Очарования',
    nameEn: 'Enchantment',
  },
  {
    id: MAGIC_EVOCATION,
    name: 'Воплощение',
    nameFull: 'Магия школы Воплощения',
    nameEn: 'Evocation',
  },
  {
    id: MAGIC_ILLUSION,
    name: 'Иллюзия',
    nameFull: 'Магия школы Иллюзии',
    nameEn: 'Illusion',
  },
  {
    id: MAGIC_NECROMANCY,
    name: 'Некромантия',
    nameFull: 'Магия школы Некромантии',
    nameEn: 'Necromancy',
  },
  {
    id: MAGIC_TRANSMUTATION,
    name: 'Преобразование',
    nameFull: 'Магия школы Преобразования',
    nameEn: 'Transmutation',
  },
]
  .sort(sortByName)

module.exports = magicList

module.exports.dndMagicCollection = listToCollectionById(magicList)

module.exports.MAGIC_ABJURATION = MAGIC_ABJURATION
module.exports.MAGIC_CONJURATION = MAGIC_CONJURATION
module.exports.MAGIC_DIVINATION = MAGIC_DIVINATION
module.exports.MAGIC_ENCHANTMENT = MAGIC_ENCHANTMENT
module.exports.MAGIC_EVOCATION = MAGIC_EVOCATION
module.exports.MAGIC_ILLUSION = MAGIC_ILLUSION
module.exports.MAGIC_NECROMANCY = MAGIC_NECROMANCY
module.exports.MAGIC_TRANSMUTATION = MAGIC_TRANSMUTATION
