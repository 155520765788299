const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_AID,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_AID,
  name: 'Подмога',
  nameEn: `Aid`,
  description: `Ваше заклинание наделяет союзников стойкостью и решимостью.

Выберите до трёх существ в пределах дистанции. На время длительности максимум хитов и текущие хиты всех целей увеличиваются на 5.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, хиты целей увеличиваются ещё на 5 за каждый круг ячейки выше второго.`,
  lvl: 2,
  magicSchoolId: MAGIC_ABJURATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `полоска белой ткани`,
  duration: { timeId: TIME_HOUR, count: 8 },
  source: [
    {
      id: SOURCE_PHB,
      page: 258,
    },
    {
      id: SOURCE_SRD,
      page: 114,
    },
  ],
}
