const sortBy = require('lodash/sortBy')

const upLetter = require('./../../../../../../utils/upLetter')

const {creatureCollection} = require('./../../../../../creatureList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ELEMENTAL_GEM} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_CONJURE_ELEMENTAL} = require('./../../../../../spellIdList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

const elementalGemTable = sortBy(list, 'stoneAppearance').reduce(
  (text, {id, stoneAppearance, creatureId}) => `${text}
| [${upLetter(stoneAppearance)}](MAGIC_ITEM:${id}) | [${creatureCollection[creatureId].name}](CREATURE:${creatureId}) |`,
  `
| Камень | Призываемый элементаль |
|--------|------------------------|`,
)

module.exports = {
  id: MAGIC_ITEM_ELEMENTAL_GEM,
  name: `Камень элементаля`,
  nameEn: `Elemental Gem`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  isConsumable: true,
  description: `Этот камень содержит частичку стихийной энергии.

Если Вы действием разбиваете камень, призывается элементаль, как если бы Вы наложили заклинание [Призыв элементаля](SPELL:${SPELL_CONJURE_ELEMENTAL}), и магия, заключённая в камне, исчезает.

${elementalGemTable}`,
  spellIdList: [SPELL_CONJURE_ELEMENTAL],
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 169,
  },
}
