const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_SUBGROUP_D_TARIG} = require('./../../languageSubGroupList')
const {SOURCE_MGZN_DRGN_ANNUAL_1999} = require('./../../sourceList')
const {
  LANG_D_TARIG,
  LANG_DWARVISH,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_D_TARIG,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_D_TARIG,
  name: {
    nominative: 'Д’тариг',
    genitive: 'Д’тарига',
    instrumental: 'Д’таригом',
    prepositional: 'Д’тариге',
  },
  nameEn: 'D’tarig',
  description: {
    header: `Д’тариг`,
    text: `Д’тариг имеет глубокие корни в [Детеке, языке дварфов](LANG:${LANG_DWARVISH})`,
    source: {
      id: SOURCE_MGZN_DRGN_ANNUAL_1999,
      page: 30,
    },
  },
  isReady: true,
  isRealLang: true,
}
