const {
  LANG_TYPE_EXOTIC,
} = require('./../../languageTypeList')
const {
  ALPHABET_CELESTIAL,
} = require('./../../alphabetList')
const {
  LANG_CELESTIAL,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_CELESTIAL,
  alphabetId: ALPHABET_CELESTIAL,
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Небесный',
    genitive: 'Небесного',
    instrumental: 'Небесным',
    prepositional: 'Небесном',
  },
  nameEn: 'Celestial',
  typicalSpeakers: 'Небожители',
  isRealLang: true,
  isReady: false,
}
