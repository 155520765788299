const arrify = require('arrify')

const listToCollectionById = require('./../../utils/listToCollectionById')
const prepareForSearch = require('./../../utils/prepareForSearch')
const sortByName = require('./../../utils/sortByName')

const SEARCH_PROP_NAME = require('./../SEARCH_PROP_NAME')
const { GENDER_MALE } = require('./../genderList')
const { alphabetCollection } = require('./../alphabetList')
const { languageSubGroupCollection } = require('./../languageSubGroupList')
const { languageGroupCollection } = require('./../languageGroupList')

const languageRawList = require('./languageRawList')

const languageList = languageRawList
  .map(
    (
      {
        genderId = GENDER_MALE,
        id,
        dialectOf = null,
        dictionary = null,
        isReady = false,
        langFamilyId: langFamilyIdRaw = null,
        langGroupId: langGroupIdRaw = null,
        langSubGroupId = null,
        name,
        nameByCase,
        name5eOfficial = null,
        spokenPlaceList = [],
        ...rest
      },
      i,
      list,
    ) => {
      const dialectId = list.reduce(
        (dialectIdList, {id: dialectId, dialectOf}) => {
          if (dialectOf === id) {
            return dialectIdList
              ? [
                ...dialectIdList,
                dialectId,
              ]
              : [dialectId]
          } else {
            return dialectIdList
          }
        },
        null
      )

      const langGroupId = langGroupIdRaw || (
        langSubGroupId
          ? languageSubGroupCollection[langSubGroupId].langGroupId
          : null
      )

      const langFamilyId = langFamilyIdRaw || (
        langGroupId
          ? languageGroupCollection[langGroupId].langFamilyId
          : null
      )

      return {
        ...rest,
        genderId,
        id,
        langFamilyId,
        langGroupId,
        langSubGroupId,
        name: name.nominative,
        name5eOfficial: name5eOfficial || name,
        nameByCase: name,
        dialectOf,
        dialectId,
        isReady,
        spokenPlaceList: arrify(spokenPlaceList).map(
          place => typeof place === 'string'
            ? { id: place }
            : place,
        ),
        dictionary: dictionary
          ? arrify(dictionary).reduce(
            (obj, {list = [], source = null}) => ({
              ...obj,
              list: [
                ...(obj.list || []),
                ...list,
              ],
              source: [
                ...(obj.source || []),
                ...(
                  source
                    ? arrify(source)
                    : []
                ),
              ],
            }),
            {},
          )
          : null,
        [SEARCH_PROP_NAME]: prepareForSearch(
          [
            name.nominative,
            rest.nameAlt || '',
            rest.nameEn,
            rest.nameEnAlt,
          ]
            .filter(e => e)
            .map(
              e => String(e).toLowerCase(),
            )
            .join('\n'),
        ),
      }
    },
  )
  .sort(sortByName)

module.exports = languageList

module.exports.languageCollection = listToCollectionById(languageList)

module.exports.readyLanguagesAlphabetIdList = languageList
  .filter(
    ({ isReady, alphabetId }) => isReady && alphabetId,
  )
  .reduce(
    (list, { alphabetId }) => arrify(alphabetId).reduce(
      (list2, id) => list2.includes(id)
        ? list2
        : [
          ...list2,
          id,
        ],
      list,
    ),
    [],
  )
  .sort(
    (A, B) => {
      if (alphabetCollection[A] && alphabetCollection[B]) {
        return alphabetCollection[A].name > alphabetCollection[B].name
          ? 1
          : -1
      } else if (alphabetCollection[A] && !alphabetCollection[B]) {
        return 1
      } else if (!alphabetCollection[A] && alphabetCollection[B]) {
        return -1
      }
    },
    [],
  )
