const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_SCRYING} = require('./../../../../../spellIdList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    id,
    name,
    nameEn,
    descriptionEnd = '',
    rarity,
  }
) => ({
  id,
  name,
  nameEn,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity,
  attunement: true,
  description: `Хрустальный шар примерно 6 дюймов диаметром. Если Вы его касаетесь, Вы можете действием сотворять им заклинание [Наблюдение](SPELL:${SPELL_SCRYING}) (СЛ испытания 17).

${descriptionEnd}`,
  genderId: GENDER_MALE,
  siblingIdList,
  spellIdList: [SPELL_SCRYING],
  source: {
    id: SOURCE_DMG,
    page: 211,
  },
})
