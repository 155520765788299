const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {gearCollection} = require('./../../../../../gearList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {PARAM_STR} = require('./../../../../../paramList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_CHAIN_SHIRT,
  GEAR_BREASTPLATE,
} = require('./../../../../../gearIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../../../genderList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    gearId,
    id,
  }
) => {
  const {
    name,
    nameEn: gearNameEn,
    genderId,
    hidingDisadvantage,
    requirementList,
  } = gearCollection[gearId]

  const {
    mithral,
    castsText,
    hasText,
  } = {
    [GENDER_MALE]: {
      mithral: 'Мифрильный',
      pronoun: 'он',
      castsText: 'накладывает',
      hasText: 'имеет',
    },
    [GENDER_FEMALE]: {
      mithral: 'Мифрильная',
      pronoun: 'она',
      castsText: 'накладывает',
      hasText: 'имеет',
    },
    [GENDER_MIDDLE]: {
      mithral: 'Мифрильное',
      pronoun: 'оно',
      castsText: 'накладывает',
      hasText: 'имеет',
    },
    [GENDER_MULTIPLE]: {
      mithral: 'Мифрильные',
      pronoun: 'они',
      castsText: 'накладывают',
      hasText: 'имеют',
    },
  }[genderId]

  const nameLower = name.toLowerCase()
  const nameFull = `${mithral} ${nameLower}`

  const underWearText = (
    gearId === GEAR_CHAIN_SHIRT ||
    gearId === GEAR_BREASTPLATE
  )
    ? `*${nameFull}* может носиться под [обычной одеждой](GEAR:${GEAR_CLOTHES_COMMON}).`
    : ''

  const hasStrRequirement = requirementList && requirementList.find(
    ({ paramType }) => paramType === PARAM_STR
  )

  const advancesText = hidingDisadvantage && hasStrRequirement
    ? `*${nameFull}* не ${castsText} помеху на проверки Ловкости (Скрытность) и не ${hasText} требований к Силе.`
    : hidingDisadvantage
      ? `*${nameFull}* не ${castsText} помеху на проверки Ловкости (Скрытность)`
      : hasStrRequirement
        ? `*${nameFull}* не ${hasText} требований к Силе.`
        : ''

  return {
    id,
    name: nameFull,
    nameEn: `Mithral ${gearNameEn}`,
    type: MGC_TYPE_ARMOR,
    gearType: gearId,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Мифрил — это лёгкий и гибкий металл.

${underWearText}

${advancesText}`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 185,
    },
  }
}
