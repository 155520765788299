const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  POISON_TYPE_INGESTED,
  POISON_TYPE_INHALED,
} = require('./../../../poisonTypeList')
const {
  PARAM_CON,
} = require('./../../../paramList')
const {
  SOURCE_DMG,
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  CREATURE_MASTIFF,
  CREATURE_MULE,
} = require('./../../../creatureIdList')
const {
  DAMAGE_POISON,
} = require('./../../../damageTypeList')
const {
  CAT_ANIMALS,
  CAT_ARTISAN_TOOLS,
  CAT_EQUIPMENT,
  CAT_FOOD,
  CAT_POISONS,
  CAT_SADDLES,
} = require('./../../../gearCategoryList')
const {
  GEAR_HAMMER,
  GEAR_MAGNIFYING_GLASS,
  GEAR_MALICE,
  GEAR_MANACLES,
  GEAR_MASONS_TOOLS,
  GEAR_MASTIFF,
  GEAR_MEAT_CHUNK,
  GEAR_MESS_KIT,
  GEAR_MIDNIGHT_TEARS,
  GEAR_MILITARY_SADDLE,
  GEAR_MIRROR_STEEL,
  GEAR_MULE,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_MIRROR_STEEL,
    genderId: GENDER_MIDDLE,
    name: 'Зеркало, стальное',
    nameByCase: {
      nominative: 'стальное зеркало',
      genitive: 'стального зеркала',
      accusative: 'стальное зеркало',
      instrumental: 'стальным зеркалом',
    },
    nameEn: 'Mirror, steel',
    cost: 500,
    weight: 0.5,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_MANACLES,
    genderId: GENDER_MULTIPLE,
    name: 'Кандалы',
    nameByCase: {
      nominative: 'кандалы',
      genitive: 'кандалов',
      accusative: 'кандалы',
      instrumental: 'кандалами',
    },
    nameAlt: [
      'Наручники',
      'Оковы',
    ],
    nameEn: 'Manacles',
    description: 'Эти металлические оковы удерживают существ Маленького и Среднего размера. Для того чтобы сбежать из кандалов, требуется успешная проверка Ловкости СЛ 20. Для того чтобы их сломать, требуется проверка Силы СЛ 20. Каждый набор кандалов идёт с одним ключом. Без ключа существо, владеющее воровскими инструментами, может вскрыть замок кандалов успешной проверкой Ловкости СЛ 15. У кандалов 15 хитов.',
    cost: 200,
    weight: 6,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_MESS_KIT,
    genderId: GENDER_MALE,
    name: 'Столовый набор',
    nameByCase: {
      nominative: 'Столовый набор',
      accusative: 'Столовый набор',
      instrumental: 'Столовым набором',
    },
    nameEn: 'Mess Kit',
    description: 'В этой небольшой коробке находится чашка и простые столовые приборы. Коробка раскрывается, и одна сторона может использоваться как сковорода, а другая — как тарелка или неглубокая миска.',
    cost: 20,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_MAGNIFYING_GLASS,
    genderId: GENDER_MIDDLE,
    name: 'Увеличительное стекло',
    nameByCase: {
      nominative: 'Увеличительное стекло',
      accusative: 'Увеличительное стекло',
      instrumental: 'Увеличительным стеклом',
    },
    nameEn: 'Magnifying Glass',
    description: `Эта линза позволяет разглядывать маленькие предметы. Линзу также можно использовать для замены кремня и кресала.

Разжигание огня увеличительным стеклом требует света, яркого как свет солнца, трута для розжига и примерно 5 минут.

Увеличительное стекло позволяет совершать с преимуществом проверки характеристик, сделанных для оценки или исследования мелких и высокодетализированных предметов.`,
    cost: 10000,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_MASONS_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты каменщика',
    nameByCase: {
      nominative: 'инструменты каменщика',
      genitive: 'инструментов каменщика',
      accusative: 'инструменты каменщика',
      instrumental: 'инструментами каменщика',
    },
    nameEn: 'Mason’s Tools',
    description: `Инструменты каменщика позволяют вам возводить каменные строения, включая стены и здания из кирпича.

**Компоненты.** Инструменты каменщика включают в себя

* мастерок,
* [молоток](GEAR:${GEAR_HAMMER}),
* долото,
* щётки,
* угольник.

**История.** Ваш опыт помогает вам определять дату постройки и предназначение каменного здания, а также понять, кто мог его построить.

**Расследование.** Вы получаете дополнительные знания при обследовании помещений в каменном здании.

**Восприятие.** Вы замечаете неровности на каменных стенах и полах, что облегчает поиск ловушек и тайных ходов.

**Снос.** Ваши познания в каменном зодчестве позволяют вам найти уязвимые места в кладке каменных стен. Вы наносите таким строениям двойной урон своими атаками оружием.

| Действие                                                | СЛ |
|---------------------------------------------------------|----|
| Продолбить долотом небольшое отверстие в каменной стене | 10 |
| Найти слабое место в каменной стене                     | 15 |
`,
    cost: 1000,
    weight: 8,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_MASTIFF,
    genderId: GENDER_MALE,
    name: 'Мастиф',
    nameByCase: {
      nominative: 'мастиф',
      genitive: 'мастифа',
      accusative: 'мастифа',
      instrumental: 'мастифом',
    },
    nameEn: 'Mastiff',
    creatureId: CREATURE_MASTIFF,
    description: 'Скорость 40 фт. Грузоподъёмность 195 фунтов.',
    cost: 2500,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_MULE,
    genderId: GENDER_MALE,
    name: 'Мул',
    nameByCase: {
      nominative: 'мул',
      genitive: 'мула',
      accusative: 'мула',
      instrumental: 'мулом',
    },
    nameEn: 'Mule',
    creatureId: CREATURE_MULE,
    description: 'Скорость 40 фт. Грузоподъёмность 420 фунтов.',
    cost: 800,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_MILITARY_SADDLE,
    genderId: GENDER_MIDDLE,
    name: 'Кавалерийское седло',
    nameByCase: {
      nominative: 'кавалерийское седло',
      genitive: 'кавалерийского седла',
      accusative: 'кавалерийское седло',
      instrumental: 'кавалерийским седлом',
    },
    nameAlt: [
      'Военное седло',
      'Боевое седло',
    ],
    nameEn: 'Military Saddle',
    description: `Боевое седло охватывает всадника, помогая удержаться на нём в бою. Благодаря ему Вы совершаете с преимуществом проверки, совершённые чтобы остаться в седле.`,
    note: {
      text: `… а для моего космического хомяка мне понадобится столь же благородное седло, но размерами чуть поменьше`,
      author: `Минск, любимый следопыт`,
    },
    cost: 2000,
    weight: 30,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_SADDLES,
  },
  {
    id: GEAR_MEAT_CHUNK,
    genderId: GENDER_MALE,
    name: 'Мясо, кусок',
    nameByCase: {
      nominative: 'кусок мяса',
      genitive: 'куска мяса',
      accusative: 'кусок мяса',
      instrumental: 'куском мяса',
    },
    nameEn: 'Meat, chunk',
    cost: 300,
    source: {
      id: SOURCE_PHB,
      page: 158,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_MALICE,
    genderId: GENDER_FEMALE,
    name: 'Яд, злоба',
    nameByCase: {
      nominative: 'злоба',
      genitive: 'злобы',
      accusative: 'злобу',
      instrumental: 'злобой',
    },
    nameEn: 'Malice',
    description: `Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 15, иначе оно станет отравленным на 1 час. Существо ослеплено, пока отравлено.`,
    saveThrow: {
      type: PARAM_CON,
      dc: 15,
    },
    cost: 25000,
    poisonType: POISON_TYPE_INHALED,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
  {
    id: GEAR_MIDNIGHT_TEARS,
    genderId: GENDER_MULTIPLE,
    name: 'Яд, полуночные слёзы',
    nameByCase: {
      nominative: 'полуночные слёзы',
      genitive: 'полуночных слёзы',
      accusative: 'полуночные слёзы',
      instrumental: 'полуночными слезами',
    },
    nameEn: 'Midnight Tears',
    description: `Существо, проглотившее этот яд, до полуночи ни от чего не страдает. Если до этого момента яд не был нейтрализован, существо должно пройти испытание Телосложения СЛ 17, получая урон ядом 31 (9к6) при провале или половину этого урона при успехе.`,
    damage: {
      diceType: 6,
      diceCount: 9,
    },
    saveThrow: {
      type: PARAM_CON,
      dc: 17,
    },
    cost: 150000,
    damageType: DAMAGE_POISON,
    poisonType: POISON_TYPE_INGESTED,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
]

module.exports = gearRawList
