const {
  CREATURE_SKELETON,
  CREATURE_ZOMBIE,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_NECROMANCY,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_ANIMATE_DEAD,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ANIMATE_DEAD,
  name: 'Восставший труп',
  nameEn: 'Animate Dead',
  description: `Это заклинание создает прислуживающую нежить. Выберите груду костей или труп гуманоида Среднего или Маленького размера в пределах дистанции. Ваше заклинание наделяет цель мерзким подобием жизни, делая из него существо-нежить. Цель становится [скелетом](CREATURE:${CREATURE_SKELETON}), если Вы выбирали кости или [зомби](CREATURE:${CREATURE_ZOMBIE}), если Вы выбрали труп.

В каждый свой ход Вы можете бонусным действием мысленно приказать существу, созданному этим заклинанием, если оно находится в пределах 60 футов (если Вы контролируете несколько существ, Вы можете отдавать один и тот же приказ любому количеству из них одновременно). Вы решаете, какое действие совершит это существо, и куда оно переместится в следующем ходу, или Вы можете отдать общий приказ, например, охранять комнату или коридор. Если Вы не отдадите команду, существо будет всего лишь защищаться от врагов. Получив приказ, существо продолжает его выполнять, пока задача не будет выполнена.

Существо находится под Вашим контролем 24 часа, после чего перестает слушаться команд. Для поддержания контроля еще на 24 часа Вы должны наложить это заклинание на него ещё раз до окончания 24-часового периода. Такое использование заклинания только поддерживает контроль над уже созданными существами, количество которых не может быть больше четырёх, и не оживляет новых.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, Вы оживляете или продлеваете контроль над двумя дополнительными существами за каждый круг ячейки выше третьего. Для каждого создаваемого существа требуется отдельная куча костей или труп.`,
  lvl: 3,
  magicSchoolId: MAGIC_NECROMANCY,
  castTime: { timeId: TIME_MINUTE, count: 1 },
  range: 10,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'капля крови, кусочек плоти и щепотка костной пыли',
  source: [
    {
      id: SOURCE_PHB,
      page: 218,
    },
    {
      id: SOURCE_SRD,
      page: 115,
    },
  ],
}
