const {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    PARAM_WIT,
  } = require('./../../../../paramList'),
  {
    SPELL_HUNTERS_MARK,
    SPELL_JUMP,
    SPELL_PASS_WITHOUT_TRACE,
    SPELL_SPEAK_WITH_ANIMALS,
    SPELL_SPEAK_WITH_PLANTS,
  } = require('./../../../../spellIdList'),
  {
    albinoDwarfDescription,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_ALBINO_DWARF_SPIRIT_WARRIOR,
    CREATURE_ALBINO_DWARF_WARRIOR,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дварф-альбинос духовный воин',
  nameEn: 'Albino Dwarf Spirit Warrior',
  id: CREATURE_ALBINO_DWARF_SPIRIT_WARRIOR,
  parentId: CREATURE_ALBINO_DWARF_WARRIOR,
  description: [
    `Дварф-альбинос живущий в гармонии с землями Чульта, может привлечь внимание благожелательного духа природы, который наделяет дварфа мощными чарами, предоставляя ему способности к врождённому колдовству.

Эти дварфы духовные воины посвящают себя сохранению естественной красоты Чульта и борьбе с неестественными угрозами.`,
    albinoDwarfDescription,
  ],
  cr: CR_1,
  spellCast: {
    baseStat: PARAM_WIT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_HUNTERS_MARK,
          SPELL_JUMP,
          SPELL_PASS_WITHOUT_TRACE,
          SPELL_SPEAK_WITH_ANIMALS,
          SPELL_SPEAK_WITH_PLANTS,
        ],
      },
    ],
  },
}
