const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {DAMAGE_RADIANT} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_WORD_OF_RADIANCE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_WORD_OF_RADIANCE,
  name: 'Слово сияния',
  nameEn: 'Word of Radiance',
  description: `Вы произносите божественное слово и извергаете из себя обжигающее сияние. Каждое существо по Вашему выбору, которое Вы можете видеть в пределах дистанции, должно пройти испытание Телосложения или получит урон излучением 1к6.`,
  highLvlCast: `Урон заклинания увеличивается на 1к6, когда выдостигаете 5 уровня (2к6), 11 уровня (3к6), и 17 уровня (4к6).`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 5,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `священный символ`,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_RADIANT,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 166,
  },
}
