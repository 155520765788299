const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  GEAR_MACE,
} = require('./../../../../../gearIdList')
const {
  CAT_SIMPLE_MELEE_WEAPON,
} = require('./../../../../../gearCategoryList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_MACE_OF_DISRUPTION,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_MACE_OF_DISRUPTION,
  name: `Булава распада`,
  nameEn: `Mace of Disruption`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_MACE,
  gearCategory: CAT_SIMPLE_MELEE_WEAPON,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Если Вы попадаете этим магическим оружием по исчадию или нежити, это существо получает дополнительный урон излучением 2к6. Если у цели после получения этого урона 25 или меньше хитов, она должна пойти испытание Мудрости СЛ 15, иначе будет уничтожена. При успехе существо становится испуганным Вами до конца Вашего следующего хода.

Пока Вы держите это оружие, оно излучает яркий свет в радиусе 20 футов и тусклый свет в радиусе ещё 20 футов.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 153,
  },
}
