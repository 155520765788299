const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const gameSetDescription = require('./../../constants/gameSetDescription')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const {
  VOLUME_UNIT_FEET_CUBE,
  VOLUME_UNIT_GALLON,
  VOLUME_UNIT_OUNCE,
} = require('./../../../volumeUnitList')
const {
  POISON_TYPE_INGESTED,
  POISON_TYPE_INJURY,
} = require('./../../../poisonTypeList')
const {
  PARAM_STR,
  PARAM_CON,
} = require('./../../../paramList')
const {
  SOURCE_DMG,
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_XGTE,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {
  CREATURE_PONY,
  CREATURE_PURPLE_WORM,
} = require('./../../../creatureIdList')
const {DAMAGE_POISON} = require('./../../../damageTypeList')
const {
  CAT_ANIMALS,
  CAT_ARTISAN_TOOLS,
  CAT_CONTAINERS,
  CAT_EQUIPMENT,
  CAT_GAME_SET,
  CAT_GOODS,
  CAT_HEAVY_ARMOR,
  CAT_EQUIPMENT_KIT,
  CAT_LIGHT_ARMOR,
  CAT_MUSIC_TOOLS,
  CAT_POISONS,
  CAT_SADDLES,
  CAT_TOOLS,
} = require('./../../../gearCategoryList')

const {
  GEAR_BACKPACK,
  GEAR_BLANKET,
  GEAR_BLOWGUN,
  GEAR_CANDLE,
  GEAR_CANVAS,
  GEAR_FLUTE,
  GEAR_PACK_SADDLE,
  GEAR_PADDED_ARMOR,
  GEAR_PAINTERS_SUPPLIES,
  GEAR_PALE_TINCTURE,
  GEAR_PAN_FLUTE,
  GEAR_PAPER,
  GEAR_PARCHMENT,
  GEAR_PEPPER,
  GEAR_PERFUME,
  GEAR_SONGHORN,
  GEAR_PIG,
  GEAR_PITCHER,
  GEAR_PITON,
  GEAR_PLATE_ARMOR,
  GEAR_PLATINUM,
  GEAR_PLAYING_CARD_SET,
  GEAR_POISON_BASIC,
  GEAR_POISONERS_KIT,
  GEAR_POLE,
  GEAR_PONY,
  GEAR_POT_IRON,
  GEAR_POTION_OF_HEALING,
  GEAR_POTTERS_TOOLS,
  GEAR_POUCH,
  GEAR_PRIEST_S_PACK,
  GEAR_BIRDPIPES,
  GEAR_PURPLE_WORM_POISON,
  GEAR_RATIONS,
  GEAR_ROBES,
  GEAR_SLING_BULLETS,
  GEAR_TINDERBOX,
  GEAR_VIAL,
  GEAR_WATERSKIN,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_PADDED_ARMOR,
    genderId: GENDER_MALE,
    name: 'Стёганый доспех',
    nameByCase: {
      nominative: 'стёганый доспех',
      genitive: 'стёганого доспеха',
      accusative: 'стёганый доспех',
      instrumental: 'стёганым доспехом',
    },
    nameEn: 'Padded Armor',
    description: `Изготовленные из прошитых слоев ткани и ватина, стёганые доспехи легкие и дешёвые, но не очень прочны. Учтите: эта простая броня громоздка, и ваш персонаж прячется намного хуже, чем когда не носит её.

Не лучший выбор, если Вы хотите остаться незамеченным.`,
    cost: 500,
    weight: 8,
    hidingDisadvantage: true,
    acChangeTo: 11,
    acUseDexMod: true,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_LIGHT_ARMOR,
  },
  {
    id: GEAR_PLATE_ARMOR,
    genderId: GENDER_MULTIPLE,
    name: 'Латы',
    nameByCase: {
      nominative: 'латы',
      genitive: 'лат',
      accusative: 'латы',
      instrumental: 'латами',
    },
    nameAlt: 'Латный доспех',
    nameEn: 'Plate ',
    description: `Классический доспех рыцарей и искателей приключений, этот огромный металлический костюм предназначен для защиты от головы до ног.
    
Латы состоят из сформированных металлических пластин, покрывающих всё тело. В комплект лат входят рукавицы, тяжёлые кожаные сапоги, шлем с забралом, и толстый слой ватина. Ремешки и пряжки распределяют вес по всему телу.

Латы очень шумные, так что забудьте о подкрадывании к большинству существ.`,
    cost: 150000,
    weight: 65,
    hidingDisadvantage: true,
    acChangeTo: 18,
    requirementList: [
      {
        type: 'param',
        paramType: PARAM_STR,
        min: 15,
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_HEAVY_ARMOR,
  },
  {
    id: GEAR_PAPER,
    genderId: GENDER_FEMALE,
    name: 'Бумага',
    nameByCase: {
      nominative: 'бумага',
      genitive: 'бумаги',
      accusative: 'бумагу',
      instrumental: 'бумагой',
    },
    nameEn: 'Paper',
    description: '1 лист',
    cost: 20,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_POT_IRON,
    genderId: GENDER_MALE,
    name: 'Котелок, чугунный',
    nameByCase: {
      nominative: 'чугунный котелок',
      genitive: 'чугунного котелка',
      accusative: 'чугунный котелок',
      instrumental: 'чугунным котелком',
    },
    nameAlt: 'Горшок, железный',
    nameEn: 'Pot, iron',
    cost: 200,
    weight: 10,
    volume: {
      unitId: VOLUME_UNIT_GALLON,
      count: 1,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_PITCHER,
    genderId: GENDER_MALE,
    name: 'Кувшин',
    nameByCase: {
      nominative: 'кувшин',
      genitive: 'кувшина',
      accusative: 'кувшин',
      instrumental: 'кувшином',
    },
    nameAlt: 'Графин',
    nameEn: 'Pitcher',
    cost: 2,
    weight: 4,
    volume: {
      unitId: VOLUME_UNIT_GALLON,
      count: 1,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_PERFUME,
    genderId: GENDER_MULTIPLE,
    name: 'Духи',
    nameByCase: {
      nominative: 'духи',
      genitive: 'духов',
      accusative: 'духи',
      instrumental: 'духами',
    },
    nameEn: 'Perfume',
    description: `[Флакон](GEAR:${GEAR_VIAL}) духов`,
    cost: 500,
    volume: {
      unitId: VOLUME_UNIT_OUNCE,
      count: 4,
    },
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_POTION_OF_HEALING,
    genderId: GENDER_MIDDLE,
    name: 'Зелье лечения',
    nameByCase: {
      nominative: 'зелье лечения',
      genitive: 'зелья лечения',
      accusative: 'зелье лечения',
      instrumental: 'зельем лечения',
    },
    nameEn: 'Potion of Healing',
    description: [
      {
        header: 'Зелье лечения',
        text: 'Снадобье для лечения незначительных порезов и ушибов',
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
      {
        header: 'Зелье лечения',
        text: `Существо, выпившее магическую красную жидкость из этого [флакона](GEAR:${GEAR_VIAL}), восстанавливает 2к4 + 2 хита. Зелье выпивается или заливается в рот другому действием.`,
        source: {
          id: SOURCE_PHB,
          page: 150,
        },
      },
      {
        header: 'Зелье лечения',
        text: 'Никогда не выходите из дома без него!',
        source: {
          id: SOURCE_AYAGWnW,
        },
      },
    ],
    cost: 5000,
    volume: {
      unitId: VOLUME_UNIT_OUNCE,
      count: 4,
    },
    weight: 0.5,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_POUCH,
    genderId: GENDER_MALE,
    name: 'Кошель',
    nameByCase: {
      nominative: 'кошель',
      genitive: 'кошеля',
      accusative: 'кошель',
      instrumental: 'кошелём',
    },
    nameEn: 'Pouch',
    description: `0,2 фт³/6 фунтов. В кожаном или тканевом кошеле поместится 20 [снарядов для пращи](GEAR:${GEAR_SLING_BULLETS}) или 50 [иголок для духовой трубки](GEAR:${GEAR_BLOWGUN}), а также другие вещи.`,
    cost: 50,
    weight: 1,
    volume: {
      unitId: VOLUME_UNIT_FEET_CUBE,
      count: 0.2,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_PARCHMENT,
    genderId: GENDER_MALE,
    name: 'Пергамент',
    nameByCase: {
      nominative: 'Пергамент',
      accusative: 'Пергамент',
      instrumental: 'Пергаментом',
    },
    nameEn: 'Parchment',
    description: '1 лист',
    cost: 10,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_POLE,
    genderId: GENDER_MALE,
    name: 'Шест',
    nameByCase: {
      nominative: 'шест',
      genitive: 'шеста',
      accusative: 'шест',
      instrumental: 'шестом',
    },
    nameEn: 'Pole',
    cost: 5,
    weight: 7,
    length: 10,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_PITON,
    genderId: GENDER_MALE,
    name: 'Колышек',
    nameByCase: {
      nominative: 'колышек',
      genitive: 'колышка',
      accusative: 'колышек',
      instrumental: 'колышком',
    },
    nameAlt: 'Шлямбур',
    nameEn: 'Piton',
    cost: 5,
    weight: 0.25,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_POISON_BASIC,
    genderId: GENDER_MALE,
    name: 'Яд, простой',
    nameByCase: {
      nominative: 'яд',
      genitive: 'яда',
      accusative: 'яд',
      instrumental: 'ядом',
    },
    nameEn: 'Poison, Basic',
    description: `Вы можете покрыть ядом из этого [флакона](GEAR:${GEAR_VIAL}) одно рубящее или колющее оружие или три боеприпаса. Наносится яд одним действием. Существо, по которому попадёт отравленное оружие или боеприпас, должно пройти испытание Телосложения СЛ 10, получая в случае провала урон ядом 1к4. Нанесённый яд эффективен 1 минуту, после чего высыхает.`,
    damage: {
      diceType: 4,
      diceCount: 1,
    },
    saveThrow: {
      type: PARAM_CON,
      dc: 10,
    },
    cost: 10000,
    volume: {
      unitId: VOLUME_UNIT_OUNCE,
      count: 4,
    },
    damageType: DAMAGE_POISON,
    poisonType: POISON_TYPE_INJURY,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_POISONS,
  },
  {
    id: GEAR_PRIEST_S_PACK,
    genderId: GENDER_MALE,
    name: 'Набор священника',
    nameByCase: {
      nominative: 'набор священника',
      genitive: 'набора священника',
      accusative: 'набор священника',
      instrumental: 'набором священника',
    },
    nameEn: 'Priest’s Pack',
    description: `Включает
    
* [рюкзак](GEAR:${GEAR_BACKPACK}),
* [одеяло](GEAR:${GEAR_BLANKET}),
* 10 [свечек](GEAR:${GEAR_CANDLE}),
* [трутницу](GEAR:${GEAR_TINDERBOX}),
* коробку для пожертвований,
* 2 упаковки благовоний,
* кадило,
* [облачение](GEAR:${GEAR_ROBES}),
* [рационы](GEAR:${GEAR_RATIONS}) на 2 дня,
* [бурдюк](GEAR:${GEAR_WATERSKIN}).`,
    cost: 1900,
    weight: 22,
    source: {
      id: SOURCE_PHB,
      page: 151,
    },
    category: CAT_EQUIPMENT_KIT,
  },
  {
    id: GEAR_PLAYING_CARD_SET,
    genderId: GENDER_MULTIPLE,
    name: 'Карты',
    nameByCase: {
      nominative: 'карты',
      genitive: 'карт',
      accusative: 'карты',
      instrumental: 'картами',
    },
    nameEn: 'Playing Card Set',
    description: gameSetDescription,
    cost: 50,
    weight: 0,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 83,
      },
    ],
    category: CAT_GAME_SET,
  },
  {
    id: GEAR_POISONERS_KIT,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты отравителя',
    nameByCase: {
      nominative: 'инструменты отравителя',
      genitive: 'инструментов отравителя',
      accusative: 'инструменты отравителя',
      instrumental: 'инструментами отравителя',
    },
    nameEn: 'Poisoner’s Kit',
    description: `В набор отравителя входят [флаконы](GEAR:${GEAR_VIAL}), химикаты и прочее снаряжение, необходимое для создания ядов. Владение этим набором позволяет вам добавлять бонус мастерства к проверкам характеристик, совершённым для создания и использования ядов.

Инструменты отравителя — это излюбленное средство воров, убийц и прочих сомнительных личностей. Они позволяют вам использовать яды и создавать их из различных веществ. Ваши познания в ядах также помогают вам излечивать от них.

**Компоненты.** Инструменты отравителя включают в себя

* стеклянные склянки,
* ступку
* пестик,
* химические реагенты,
* стеклянную палочку для размешивания. 

**История.** Ваша подготовка в использовании ядов может помочь вам при попытках вспомнить факты о печально известных отравлениях.

**Расследование, Восприятие.** Ваши познания в ядах научили вас быть осторожным, имея дело с этими веществами, тем самым помогая вам при обследовании отравленного предмета или при получении зацепок о событиях, связанных с ядами.

**Медицина.** Когда Вы лечите жертву яда, ваша осведомлённость может дать вам дополнительные знания о том, как правильнее ухаживать за отравленным.

**Природа, Выживание.** Работа с ядами позволила вам получить знания о том, какие растения и животные ядовиты.

**Обращение с ядами.** Ваше владение позволяет вам аккуратно обращаться с ядами и использовать их без риска самому подвергнуться их действию.

| Действие                                                      | СЛ |
|---------------------------------------------------------------|----|
| Опознать отравленный предмет                                  | 10 |
| Определить эффект яда                                         | 20 |
`,
    cost: 5000,
    weight: 2,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_TOOLS,
  },
  {
    id: GEAR_POTTERS_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты гончара',
    nameByCase: {
      nominative: 'инструменты гончара',
      genitive: 'инструментов гончара',
      accusative: 'инструменты гончара',
      instrumental: 'инструментами гончара',
    },
    nameEn: 'Potter’s Tools',
    description: `Инструменты гончара используются для создания различных керамических предметов, наиболее распространённые из них — это горшки и подобные сосуды.

**Компоненты.** Инструменты гончара включают в себя

* гончарные иглы,
* цикли,
* скребки,
* нож,
* кронциркуль.

**История.** Ваши знания и опыт помогают вам опознавать керамические предметы, получая сведения о том, когда они были сделаны и из каких мест или какой культуры они происходят.

**Расследование, Восприятие.** Вы получаете дополнительные знания при осмотре керамики, находя зацепки, которые другие могут не заметить, на небольших неровностях и сколах.

**Воссоздание облика.** Рассмотрев керамические черепки, Вы можете определить происхождение предмета, его изначальную форму и возможное предназначение.

| Действие                                  | СЛ |
|-------------------------------------------|----|
| Определить, что было в сосуде             | 10 |
| Создать прочный горшок                    | 15 |
| Найти слабое место керамического предмета | 20 |
`,
    cost: 1000,
    weight: 3,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_PAINTERS_SUPPLIES,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты художника',
    nameByCase: {
      nominative: 'инструменты художника',
      genitive: 'инструментов художника',
      accusative: 'инструменты художника',
      instrumental: 'инструментами художника',
    },
    nameEn: 'Painter’s Supplies',
    description: `Владение инструментами художника позволяет вам писать красками и рисовать карандашом. Вы также разбираетесь в истории искусств, что может помочь вам при осмотре произведений искусства.

**Компоненты.** Инструменты художнику включают в себя

* мольберт,
* [холст](GEAR:${GEAR_CANVAS}),
* краски,
* кисти,
* угольные карандаши,
* палитру.

**Магия, История, Религия.** Ваш опыт помогает вам раскрыть знания любого рода, которые связаны с произведениями искусства, как, например, магические свойства картины или происхождение странной фрески, найденной в подземелье.

**Расследование, Восприятие.** Когда Вы осматриваете картину или подобное произведение изобразительного искусства, ваша осведомлённость о способах их создания может дать вам дополнительные знания.

**Написание и рисование.** Как часть короткого или длинного отдыха Вы можете создать простое произведение искусства. Хотя ваше творение и может быть недостаточно точным, Вы можете запечатлеть образ или сцену, или на скорую руку сделать копию виденного вами художественного произведения.

| Действие                             | СЛ |
|--------------------------------------|----|
| Написать точный портрет              | 10 |
| Создать картину со скрытым посланием | 20 |
`,
    cost: 1000,
    weight: 5,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_PAN_FLUTE,
    genderId: GENDER_FEMALE,
    name: 'Свирель',
    nameByCase: {
      nominative: 'свирель',
      genitive: 'свирели',
      accusative: 'свирель',
      instrumental: 'свирелью',
    },
    nameEn: 'Pan Flute',
    description: musicalToolDescriptionList,
    cost: 1200,
    weight: 2,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 85,
      },
    ],
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_BIRDPIPES,
    genderId: GENDER_MALE,
    name: 'Птичья свирель',
    nameEn: 'Birdpipes',
    nameByCase: {
      nominative: 'птичья свирель',
      genitive: 'птичьей свирели',
      accusative: 'птичью свирель',
      instrumental: 'птичьей свирелью',
    },
    nameAlt: [
      'Свирель панов',
      'Свирель сатиров',
      'Шэлм',
    ],
    nameEnAlt: [
      'Pan pipes',
      'Satyr pipes',
      'Shalm',
    ],
    description: [
      {
        header: 'Птичья свирель',
        text: `Свирель панов или свирель сатиров, также известная как шэлм, считается священной для последователей Ллииры и популярной у бардов лесных и диких эльфов.`,
        source: {
          id: SOURCE_SCAG,
          page: 125,
        },
      },
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_SONGHORN,
    genderId: GENDER_MALE,
    name: 'Песенный рог',
    nameEn: 'Songhorn',
    nameByCase: {
      nominative: 'песенный рог',
      genitive: 'песенного рога',
      accusative: 'песенный рог',
      instrumental: 'песенным рогом',
    },
    description: [
      `Упрощенный вариант [флейты](GEAR:${GEAR_FLUTE}), как правило, вырезанный из дерева.`,
      ...musicalToolDescriptionList,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 125,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_PONY,
    genderId: GENDER_FEMALE,
    name: 'Пони',
    nameByCase: {
      nominative: 'пони',
      genitive: 'пони',
      accusative: 'пони',
      instrumental: 'пони',
    },
    nameEn: 'Pony',
    creatureId: CREATURE_PONY,
    description: 'Скорость 40 фт. Грузоподъёмность 225 фунтов.',
    cost: 3000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_PACK_SADDLE,
    genderId: GENDER_MIDDLE,
    name: 'Вьючное седло',
    nameByCase: {
      nominative: 'вьючное седло',
      genitive: 'вьючного седла',
      accusative: 'вьючное седло',
      instrumental: 'вьючным седлом',
    },
    nameAlt: 'Грузовое седло',
    nameEn: 'Pack Saddle',
    cost: 500,
    weight: 15,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_SADDLES,
  },
  {
    id: GEAR_PEPPER,
    genderId: GENDER_MALE,
    name: 'Перец',
    nameByCase: {
      nominative: 'перец',
      genitive: 'перца',
      accusative: 'перец',
      instrumental: 'перцем',
    },
    nameEn: 'Pepper',
    cost: 200,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_PIG,
    genderId: GENDER_FEMALE,
    name: 'Свинья',
    nameByCase: {
      nominative: 'свинья',
      genitive: 'свиньи',
      accusative: 'свинью',
      instrumental: 'свиньёй',
    },
    nameEn: 'Pig',
    cost: 300,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_PLATINUM,
    genderId: GENDER_FEMALE,
    name: 'Платина',
    nameByCase: {
      nominative: 'платина',
      genitive: 'платины',
      accusative: 'платину',
      instrumental: 'платиной',
    },
    nameEn: 'Platinum',
    cost: 50000,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_PALE_TINCTURE,
    genderId: GENDER_FEMALE,
    name: 'Яд, бледная настойка',
    nameByCase: {
      nominative: 'бледная настойка',
      genitive: 'бледной настойки',
      accusative: 'бледную настойку',
      instrumental: 'бледной настойкой',
    },
    nameEn: 'Pale Tincture',
    description: `Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 16, иначе получит урон ядом 3 (1к6) и станет отравленным. Отравленное существо должно повторять испытание каждые 24 часа, получая урон ядом 3 (1к6) при провале. Пока яд действует, урон, причинённый им, ничем не может быть вылечен. После семи успешных испытаний эффект оканчивается, и существо может лечиться как обычно.`,
    damage: {
      diceType: 6,
      diceCount: 1,
    },
    saveThrow: {
      type: PARAM_CON,
      dc: 16,
    },
    cost: 25000,
    damageType: DAMAGE_POISON,
    poisonType: POISON_TYPE_INGESTED,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
  {
    id: GEAR_PURPLE_WORM_POISON,
    genderId: GENDER_MALE,
    name: 'Яд лилового червя',
    nameByCase: {
      nominative: 'яд лилового червя',
      genitive: 'яда лилового червя',
      accusative: 'яд лилового червя',
      instrumental: 'ядом лилового червя',
    },
    nameEn: 'Purple Worm Poison',
    description: `Этот яд собирают с мёртвого или недееспособного [лилового червя](CREATURE:${CREATURE_PURPLE_WORM}). Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 19, получая при провале урон ядом 42 (12к6), или половину этого урона при успехе.`,
    damage: {
      diceType: 6,
      diceCount: 12,
    },
    saveThrow: {
      type: PARAM_CON,
      dc: 19,
    },
    cost: 200000,
    damageType: DAMAGE_POISON,
    poisonType: POISON_TYPE_INJURY,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
]

module.exports = gearRawList
