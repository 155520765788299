const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_SWARMKEEPER} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {
  SPELL_FAERIE_FIRE,
  SPELL_GASEOUS_FORM,
  SPELL_ARCANE_EYE,
  SPELL_INSECT_PLAGUE,
  SPELL_MAGE_HAND,
  SPELL_WEB,
} = require('./../../../spellIdList')
const {
  FEATURE_GATHERED_SWARM,
  FEATURE_MIGHTY_SWARM,
  FEATURE_SWARMING_DISPERSAL,
  FEATURE_SWARMKEEPER_MAGIC,
  FEATURE_WRITHING_TIDE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_GATHERED_SWARM,
    name: `Собранный рой`,
    nameEn: `Gathered Swarm`,
    lvl: 3,
    text: `Рой неуловимых духов природы привязался к Вам и может помогать Вам в бою. Пока Вы не умрете, рой остается в Вашем пространстве, ползая по Вам или летая и носясь вокруг Вас.

Вы можете самостоятельно выбрать его внешний вид или определить его броском по таблице «Вид роя».

# Вид роя

| к4 | Вид                        |
|----|----------------------------|
| 1  | Роящиеся насекомые         |
| 2  | Крохотные ветвистые заразы |
| 3  | Порхающие птички           |
| 4  | Игривые пикси              |

Один раз на каждом своём ходу Вы можете заставить рой помочь Вам одним из следующих способов, сразу после того, как Вы попадаете атакой по существу.

* Цель атаки получает 1к6 колющего урона от роя.
* Цель атаки должна пройти испытание Силы или рой утащит её на расстояние до 15 футов по горизонтали в выбранном Вами направлении.
* Рой переносит Вас на 5 футов по горизонтали в выбранном Вами направлении.
`,
    source: {
      id: SOURCE_TCoE,
      page: 88,
    },
  },
  {
    id: FEATURE_SWARMKEEPER_MAGIC,
    name: `Магия роевода`,
    nameEn: `Swarmkeeper Magic`,
    lvl: 3,
    text: `Вы узнаёте заговор [Волшебная рука](SPELL:${SPELL_MAGE_HAND}), если ещё не знали его. Когда Вы его сотворяете, рука выглядит как [рой Ваших духов природы](FEATURE:${FEATURE_GATHERED_SWARM}).

Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_ARCANE_EYE,
      SPELL_FAERIE_FIRE,
      SPELL_GASEOUS_FORM,
      SPELL_INSECT_PLAGUE,
      SPELL_MAGE_HAND,
      SPELL_WEB,
    ],
    spellIdPickByLvl: {
      3: [
        SPELL_FAERIE_FIRE,
        SPELL_MAGE_HAND,
      ],
      5: [SPELL_WEB],
      9: [SPELL_GASEOUS_FORM],
      13: [SPELL_ARCANE_EYE],
      17: [SPELL_INSECT_PLAGUE],
    },
    source: {
      id: SOURCE_TCoE,
      page: 89,
    },
  },
  {
    id: FEATURE_WRITHING_TIDE,
    name: `Роящийся поток`,
    nameEn: `Writhing Tide`,
    lvl: 7,
    text: `Вы можете собрать часть своего [роя](FEATURE:${FEATURE_GATHERED_SWARM}) в плотную массу, которая поднимет Вас в воздух.

Вы бонусным действием получаете скорость полёта 10 футов и можете парить. Этот эффект длится 1 минуту, или пока Вы дееспособны.

Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 89,
    },
  },
  {
    id: FEATURE_MIGHTY_SWARM,
    name: `Могучий рой`,
    nameEn: `Mighty Swarm`,
    lvl: 11,
    text: `Ваш [Собранный рой](FEATURE:${FEATURE_GATHERED_SWARM}) становится сильнее:

* Урон [Собранного роя](FEATURE:${FEATURE_GATHERED_SWARM}) увеличивается до 1к8.
* Если существо проваливает испытание Силы от утаскивания [роем](FEATURE:${FEATURE_GATHERED_SWARM}), Вы можете также заставить [рой](FEATURE:${FEATURE_GATHERED_SWARM}) опрокинуть его.
* Когда [Собранный рой](FEATURE:${FEATURE_GATHERED_SWARM}) передвигает Вас, Вы получаете половинное укрытие до начала своего следующего хода.`,
    source: {
      id: SOURCE_TCoE,
      page: 89,
    },
  },
  {
    id: FEATURE_SWARMING_DISPERSAL,
    name: `Растворение в рое`,
    nameEn: `Swarming Dispersal`,
    lvl: 15,
    text: `Вы можете раствориться в своём рое, избегая опасности.

Когда Вы получаете урон, то можете реакцией дать себе сопротивление к этому урону. Вы растворяетесь в своём рое и после этого телепортируетесь в видимое Вами в пределах 30 футов свободное пространство, появляясь там вместе со своим роем.

Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 89,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_RANGER_SWARMKEEPER,
  })
)

