const {
  MAGIC_ABJURATION,
  MAGIC_CONJURATION,
  MAGIC_DIVINATION,
  MAGIC_ENCHANTMENT,
  MAGIC_ILLUSION,
  MAGIC_TRANSMUTATION,
} = require('./../../magicList')
const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../castComponentList')
const {
  SOURCE_UA_2017_04_03_STARTER_SPELLS,
} = require('./../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_HOUR,
  TIME_MINUTE,
  TIME_ROUND,
} = require('./../../timePeriodList')
const {
  PARAM_CON,
} = require('./../../paramList')
const {
  SPELL_GUIDING_HAND_2017_04_03,
  SPELL_HEALING_ELIXIR_2017_04_03,
  SPELL_PUPPET_2017_04_03,
  SPELL_SENSE_EMOTION_2017_04_03,
  SPELL_SUDDEN_AWAKENING_2017_04_03,
  SPELL_UNEARTHLY_CHORUS_2017_04_03,
  SPELL_VIRTUE_2017_04_03,
  SPELL_WILD_CUNNING_2017_04_03,
} = require('./../../spellIdList')

module.exports = [
  {
    id: SPELL_GUIDING_HAND_2017_04_03,
    name: 'Направляющая рука',
    nameEn: 'Guiding Hand',
    description: `Вы создаёте Крошечную руку из мерцающего света в видимом вами незанятом пространстве в пределах дистанции. Рука существует всю длительно заклинания, но исчезает, если Вы телепортируетесь или переходите на иной план существования.

Когда рука появляется, Вы называете один заметный ориентир, такой как город, гору, замок или поле боя на этом плане существования. Кто-то за всю историю должен был хотя бы раз посетить это место и нанести его на карту. Если это место не отмечено ни на одной существующей карте, заклинание проваливается. В противном случае, когда Вы двигаетесь к руке, она, отодвигается от вас в такой же скоростью, всегда двигаясь в сторону этого ориентира и держась в 5 футах от вас.

Если Вы не двигаетесь к руке, она остаётся на месте, ожидая вас и каждые 1к4 минуты подманивает вас за собой.`,
    lvl: 1,
    magicSchoolId: MAGIC_DIVINATION,
    range: 5,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 8 },
    isRitual: true,
    needConcentration: true,
    source: {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 3,
    },
  },
  {
    id: SPELL_HEALING_ELIXIR_2017_04_03,
    name: 'Целебный эликсир',
    nameEn: 'Healing Elixir',
    description: `Вы создаёте целебный эликсир в простом пузырьке. Он появляется в вашей руке. Эликсир сохраняет свои свойства всю длительность заклинания, или пока его не выпьют. При этом пузырёк исчезает.

Существо может действием выпить эликсир или напоить им другое существо. Выпившее эликсир существо восстанавливает 2к4 + 2 хитов.`,
    lvl: 1,
    magicSchoolId: MAGIC_CONJURATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `Инструменты алхимика`,
    duration: { timeId: TIME_HOUR, count: 24 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 3,
    },
  },
  {
    id: SPELL_PUPPET_2017_04_03,
    name: 'Марионетка',
    nameEn: 'Puppet',
    description: `Вы пытаетесь жестом заставить двигаться другого гуманоида.

Один видимый вами в пределах дистанции гуманоид должен пройти испытание Телосложения. При провале, цель должна передвигаться в вабранном вами направлении на расстояние, не превышающее своей скорости. Кроме того, Вы можете заставить цель бросить всё, что она носит. 

Заклинание не оказывает никакого эффекта на гуманоидов с иммунитетом к очарованию.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 120,
    componentIdList: [CAST_SOMATIC],
    effect: {
      savethrowParam: PARAM_CON,
    },
    source: {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 3,
    },
  },
  {
    id: SPELL_SENSE_EMOTION_2017_04_03,
    name: 'Определение эмоций',
    nameEn: 'Sense Emotion',
    description: `Вы настраиваете свои чувства на определение чужих эмоций в пределах дистанции.  

Сотворяя заклинание (а также в следующих ходах действием), Вы можете сфокусировать свои чувства на одном гуманоиде, видимом вами в пределах 30 футов. Вы немедленно узнаёте преобладающую эмоцию цели, будь то любовь, злость, боль, страх, спокойствие или что-то ещё.

Если цель не является гуманоидом или имунна к очарованию, Вы ощущаете у неё спокойствие.`,
    lvl: 1,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    needConcentration: true,
    componentIdList: [CAST_SOMATIC, CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 4,
    },
  },
  {
    id: SPELL_SUDDEN_AWAKENING_2017_04_03,
    name: 'Внезапное пробуждение',
    nameEn: 'Sudden Awakening',
    description: `Все спящие в пределах дистанции существа по вашему выбору немедленно пробуждаются и затем все лежащие существа в пределах дистанции могут встать без траты передвижения.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 10,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_BONUS_ACTION, count: 1 },
    source: {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 4,
    },
  },
  {
    id: SPELL_UNEARTHLY_CHORUS_2017_04_03,
    name: 'Неземной хор',
    nameEn: 'Unearthly Chorus',
    description: `Музыка выбранного Вами стиля звучит вокруг Вас в 30-футовом радиусе. Музыка огибает углы и слышна на расстоянии до 100 футов. Музыка перемещается вместе с Вами, с центром на Вас.

До окончания действия заклинания Вы можете соврешать все проверки Харизмы (Выступления) с преимуществом. Кроме того, бонусным действием на каждом своём ходу Вы можете развлекать одно слышашее музыку существо, видимое Вами в пределах 30 футов. Существо должно пройти испытание Харизмы. Если Вы или Ваши спутники сражаетесь с ним, существо автоматически преуспевает в этом испытании. При провале цель становится дружественна к вам, пока она может слышать музыку и в течение 1 часа после этого.

Вы получаете преимущество ко всем проверкам Харизмы (Обмана) и Харизмы (Убеждения) против существ, ставших дружественных Вам из-за действия этого заклинания.`,
    lvl: 1,
    magicSchoolId: MAGIC_ILLUSION,
    range: -1,
    rangeComment: `30-футовый радиус`,
    needConcentration: true,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 4,
    },
  },
  {
    id: SPELL_VIRTUE_2017_04_03,
    name: 'Добродетель',
    nameEn: 'Virtue',
    description: `Вы касаетесь одного существа, наполняя его жизненной силой.

Если у цели есть хотя бы 1 хит, она получает временные хиты в количестве 1к4 + модификатор Вашей заклинательной характеристики. Эти временные хиты пропадают, когда заклинание оканчивается.`,
    lvl: 0,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_ROUND, count: 1 },
    source: {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 5,
    },
  },
  {
    id: SPELL_WILD_CUNNING_2017_04_03,
    name: 'Знание дикости',
    nameEn: 'Wild Cunning',
    description: `Вы вызываете духов природы себе на помощь. Когда Вы сотворяете это заклинание, выберите один из следующих эффектов:

* Если в пределах дальности есть какие-то следы на земле, то Вы знаете где они и Вы получаете преимущество к проверкам Мудрости (Выживания) для выслеживания этих следов в течение 1 часа или до следующего сотворения этого заклинания.
* Если в пределах дальности можно найти еду, Вы узнаёте где она.
* Если в пределах дальности можно найти чистую питьевую воду, Вы узнаёте где она.
* Если в пределах дальности можно найти подходящее убежище, Вы узнаёте где оно.
* Послать духов собрать дров для костра и разбить в пределах дальности лагерь, используя ваши припасы. Духи сооружают костёр в круге камней, расставляют палатки, расстилают ваши спальники и достают ваши рационы и воду для еды.
* Приказать духам мгновенно разобрать лагерь, что включает тушение костра, сворачивание палаток, упаковку сумок и закапывание любого мусора.`,
    lvl: 1,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    isRitual: true,
    source: {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 5,
    },
  },
]
