import React from 'react';
import PropTypes from 'prop-types';

import DescriptionComponent from './DescriptionComponent';

const COLUMN_COEF = 1500;
const MAX_COLUMN = 3;
const MAX_LEN_TO_HANDLE = MAX_COLUMN * COLUMN_COEF;

const DescriptionContainer = ({ text, isOneColumn, columnCount: columnStrictCount, ...rest }) => {
  if (text) {
    const columnCount = columnStrictCount || (
      isOneColumn
        ? 1
        : text.length > MAX_LEN_TO_HANDLE
          ? MAX_COLUMN
          : Math.ceil(text.length / COLUMN_COEF)
    );

    return (
      <DescriptionComponent
        columnCount={columnCount}
        text={text}
        {...rest}
      />
    );
  }

  return null
};

DescriptionContainer.defaultProps = {
  columnCount: 0,
  text: '',
  isOneColumn: false,
}

DescriptionContainer.propTypes = {
  columnCount: PropTypes.number,
  text: PropTypes.string,
}

export default DescriptionContainer;
