const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SPELL_GREATER_RESTORATION,
  SPELL_TIME_RAVAGE,
  SPELL_WISH,
} = require('./../../../../../spellIdList')
const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')

module.exports = {
  id: SPELL_TIME_RAVAGE,
  name: 'Трата времени',
  nameEn: 'Time Ravage',
  description: `Вы выбираете существо, видимое Вами в пределах дистанции, заставляя его физическую форму пройти через разрушительный процесс быстрого старения.

Цель должна пройти испытание Телосложения, получая 10к12 урона некротической энергией при провале или половину при успехе.

При провале существо также стареет до возраста за 30 дней до смерти от старости. Постарев таким образом, цель получает помеху к броскам атаки, проверкам характеристик и испытаниям, а его скорость ходьбы уменьшается вдвое. Отменить этот эффект может только заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) или [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}), сотворённое ячейкой 9 круга.`,
  lvl: 9,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `песочные часы, заполненные алмазной пылью, стоимостью не менее 5 000 зм, расходуемые заклинанием`,
  hasCost: true,
  hasConsumable: true,
  isDunamisBased: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_NECROTIC,
      diceCount: 10,
      diceType: 12,
    },
  },
  source: {
    id: SOURCE_EGTW,
    page: 190,
  },
}
