const {CAT_DRUID_FOCUS} = require('./../../../gearCategoryList')
const {GEAR_YEW_SCEPTER} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')

module.exports = {
  id: GEAR_YEW_SCEPTER,
  genderId: GENDER_MALE,
  name: 'Тисовый скипетр',
  nameByCase: {
    nominative: 'тисовый скипетр',
    genitive: 'тисового скипетра',
    accusative: 'тисовый скипетр',
    instrumental: 'тисовым скипетром',
  },
  nameEn: 'Yew scepter',
  description: 'Скипетр из тиса или другого дерева. Друид может использовать этот предмет в качестве фокусировки для заклинаний.',
  cost: 1000,
  weight: 1,
  source: {
    id: SOURCE_PHB,
    page: 150,
  },
  category: CAT_DRUID_FOCUS,
}
