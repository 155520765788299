const {
  SPELL_CONE_OF_COLD,
  SPELL_COUNTERSPELL,
  SPELL_DETECT_MAGIC,
  SPELL_FIRE_BOLT,
  SPELL_FIREBALL,
  SPELL_FLY,
  SPELL_GREATER_INVISIBILITY,
  SPELL_ICE_STORM,
  SPELL_LIGHT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MISTY_STEP,
  SPELL_PRESTIDIGITATION,
  SPELL_SHIELD,
  SPELL_SUGGESTION,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_6} = require('./../../../../crList')
const {CREATURE_MAGE} = require('./../../../../creatureIdList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Маг',
  nameEn: 'Mage',
  id: CREATURE_MAGE,
  description: `Маги тратят жизнь на изучение и применение на практике магии. Добрые маги служат советниками при дворянах и правителях, а злые маги живут в изолированных местах и творят жуткие эксперименты.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 347,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 4,
    },
  ],
  cr: CR_6,
  spellCast: {
    spellCasterLevel: 9,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_CONE_OF_COLD,
      SPELL_COUNTERSPELL,
      SPELL_DETECT_MAGIC,
      SPELL_FIRE_BOLT,
      SPELL_FIREBALL,
      SPELL_FLY,
      SPELL_GREATER_INVISIBILITY,
      SPELL_ICE_STORM,
      SPELL_LIGHT,
      SPELL_MAGE_ARMOR,
      SPELL_MAGE_HAND,
      SPELL_MAGIC_MISSILE,
      SPELL_MISTY_STEP,
      SPELL_PRESTIDIGITATION,
      SPELL_SHIELD,
      SPELL_SUGGESTION,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
  ],
  genderId: GENDER_MALE,
}
