const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_ORCUS,
  CREATURE_WIGHT,
  CREATURE_WRAITH,
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')
const {ABILITY_SUNLIGHT_SENSITIVITY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Умертвие`,
  nameEn: 'Wight',
  id: CREATURE_WIGHT,
  description: [
    {
      header: 'Умертвие',
      text: `Умертвие это смертный, ведомый тёмными желаниями и большим тщеславием, впоследствии ставший злой нежитью. Когда смерть усмиряет сердце подобного существа и жизнь покидает его, дух сего создания взывает к демоническому повелителю [Оркусу](CREATURE:${CREATURE_ORCUS}) или другому отвратительному богу подземного мира для отсрочки: он просит возродиться в качестве нежити в обмен на вечную войну с живыми. Если тёмная сила отвечает на зов, сей дух становится нежитью, способной осуществлять свои собственные злые планы.

Умертвия обладают памятью и повадками своей былой жизни. Они будут прислушиваться к зову той тёмной сущности, что превратила их в нежить, но сохраняют при этом свою независимость. Не знающее усталости умертвие может преследовать свои цели безжалостно, и ни на что не отвлекаясь.`,
      source: {
        id: SOURCE_MM,
        page: 287,
      },
    },
    {
      header: 'Пожиратели жизни',
      text: `Не живое и при этом не мёртвое, умертвие существует в промежуточном состоянии между двух миров. Жизнерадостность, которой оно обладало при жизни, исчезает, и на её место приходит жажда поглощать всякую жизненную искру, которую оно найдёт на своём пути. Когда умертвие нападает, мрачные глаза, сего ожившего существа тлеют словно раскалённые добела горячие угольки, и холодное прикосновение умертвия может истощить жизненную силу сквозь плоть, одежду и доспехи.`,
      source: {
        id: SOURCE_MM,
        page: 287,
      },
    },
    {
      header: 'Могильная тень',
      text: `Умертвия избегают дневного мира и солнечного света, который они ненавидят. Они отступают в могильные курганы, склепы и усыпальницы, в которых они обитают. Их логова расположены в беззвучных пустынных местах, в окружении почерневших мёртвых растений, а звери и птицы сторонятся их.

Гуманоиды, убитые умертвием, могут подняться подконтрольными им [зомби](CREATURE:${CREATURE_ZOMBIE}). Движимые жаждой живых душ и тягой к силам, пробудивших их в посмертии, некоторые умертвия служат ударными войсками злых лидеров, таких как [призраки](CREATURE:${CREATURE_WRAITH}). Будучи солдатами, они способны планировать, но делают это редко, полагаясь на жажду разрушения при подавлении любых противников.`,
      source: {
        id: SOURCE_MM,
        page: 287,
      },
    },
    {
      header: 'Природа нежити',
      text: `Умертвию не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 287,
      },
    },
    {
      header: 'Умертвие',
      text: `Умертвие — это чистое порождение злобы, обретшее бесплотную форму, чтобы погубить всё живое. Даже небольной огонь может потухнуть, если всё тепло из него вытянет ужасающее присутствие умертвия.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 287,
  },
  armor: {
    ac: 14,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Длинным мечом_ или _Длинным луком_. Оно может использовать _Вытягивание жизни_ вместо одной атаки _Длинным мечом_.`,
    },
    {
      name: `Вытягивание жизни`,
      description: `Цель должна пройти испытание Телосложения СЛ 13, иначе максимум её хитов уменьшится на количество, равное полученному урону. Это уменьшение длится до тех пор, пока цель не окончит длинный отдых. Цель умирает, если этот эффект уменьшает максимум хитов до 0.

Гуманоид, убитый этой атакой, восстаёт через 24 часа в качестве [зомби](CREATURE:${CREATURE_ZOMBIE}) под контролем ★СУЩЕСТВО★, если этого гуманоида не воскресят, или его тело не уничтожат. ★СУЩЕСТВО★ может иметь не более двенадцати зомби под контролем одновременно.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
  genderId: GENDER_MIDDLE,
}
