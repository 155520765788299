const {
  WEAPON_AMMUNITION,
  WEAPON_HEAVY,
  WEAPON_RANGE,
  WEAPON_RELOADING,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {SOURCE_WDH} = require('./../../../sourceList')
const crossbowsDescription = require('./../../constants/crossbowsDescription')
const {CAT_MARTIAL_RANGE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_CROSSBOW_DOUBLE} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_CROSSBOW_DOUBLE,
  genderId: GENDER_MALE,
  name: 'Арбалет, двойной',
  nameByCase: {
    nominative: 'двойной арбалет',
    genitive: 'двойного арбалета',
    accusative: 'двойной арбалет',
    instrumental: 'двойным арбалетом',
  },
  nameEn: 'Crossbow, double',
  description: [
    `Это тяжёлый арбалет с уменьшенной дистанцией, который зато стреляет сразу двумя болтами.`,
    crossbowsDescription,
  ],
  damage: {
    diceType: 10,
    diceCount: 2,
  },
  damageType: DAMAGE_PIERCING,
  weight: 18,
  source: {
    id: SOURCE_WDH,
    page: 198,
  },
  weaponPropList: [
    WEAPON_AMMUNITION,
    WEAPON_TWO_HANDED,
    WEAPON_RELOADING,
    WEAPON_HEAVY,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 60,
        max: 240,
      },
    },
  ],
  category: CAT_MARTIAL_RANGE_WEAPON,
}
