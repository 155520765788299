const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ARMS_OF_HADAR,
  SPELL_DARKNESS,
  SPELL_ELDRITCH_BLAST,
  SPELL_FEAR,
  SPELL_FRIENDS,
  SPELL_GUIDANCE,
  SPELL_HEX,
  SPELL_HOLD_PERSON,
  SPELL_HUNGER_OF_HADAR,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_POISON_SPRAY,
  SPELL_SUGGESTION,
  SPELL_WITCH_BOLT,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SHAPESHIFTER,
  CREATURE_TYPE_YUAN_TI,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_YUAN_TI_MALISON_2,
  CREATURE_YUAN_TI_PIT_MASTER,
} = require('./../../../../creatureIdList')
const {
  yuantiDescriptionList,
  yuantiNoteList,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_5} = require('./../../../../crList')
const {GOD_MERRSHAULK} = require('./../../../../godIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Юань-ти хозяин ямы',
  nameEn: 'Yuan-ti Pit Master',
  id: CREATURE_YUAN_TI_PIT_MASTER,
  description: [
    `Хозяева ям — жрецы юань-ти, проклинатели, заключившие договор с богом [Мершолком](GOD:${GOD_MERRSHAULK}) и стремящиеся пробудить его от дремоты путем принесения гуманоидов ему в жертву. Среди юань-ти они наибольшие традиционалисты и считают, что лучше всего приспособлены для достижения целей своего общества. Хозяева ям глубоко вовлечены в долгосрочные планы своей расы по захвату власти в правительствах гуманоидов, также, как и в планы защиты городов от обнаружения и нападения со стороны врагов. Они выступают против безрассудного поведения и предлагают медленный, осторожный подход во всех вопросах.

[Этот тип проклинателей](CREATURE:${CREATURE_YUAN_TI_MALISON_2}) с человеческой головой и телом и змеями вместо рук.`,
    ...yuantiDescriptionList,
  ],
  note: [
    {
      text: `Я — исследователь, который любит путешествовать и проводить вечера в уютных гостиницах, а не продираться через джунгли или тащиться по пустыням через ослепляющий песок, чтобы узнать секреты змеиного народа. В своё время я встретил несколько чистокровных и стражей выводка, но если бы я встретился с юань-ти хозяином ямы, то, могу сказать с уверенностью, не был бы здесь, чтобы рассказать эту историю.`,
      author: `Воло`,
    },
    {
      text: `Почти наверняка не был бы`,
      author: `Эльминстер`,
    },
    ...yuantiNoteList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_YUAN_TI,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 207,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
      comment: 'прозревает магическую тьму',
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Перевёртыш',
      description: `Юань-ти может использовать действие и превратиться в Среднюю змею или обратно в свою истинную форму. Его характеристики одинаковы в обеих формах. Любое снаряжение, которое он несёт и носит, не превращается. Если он умирает, то остаётся в своей текущей форме.`,
    },
    {
      name: 'Адепт яда',
      limit: {
        count: 2,
        period: 'день',
      },
      description: `В первый раз, когда юань-ти попадает рукопашной атакой в свой ход, он может дополнительно нанести 16 (3к10) урона ядом цели.`,
    },
  ],
  spellCast: [
    {
      comment: 'только в форме юань-ти',
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            {
              id: SPELL_ANIMAL_FRIENDSHIP,
              comment: 'только змеи',
            },
          ],
        },
        {
          limit: {
            count: 3,
            period: 'день',
          },
          list: [
            SPELL_SUGGESTION,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 6,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            {
              id: SPELL_ELDRITCH_BLAST,
              comment: `дистанция 300 фт., +3 к каждому броску урона`,
            },
            SPELL_FRIENDS,
            SPELL_GUIDANCE,
            SPELL_MAGE_HAND,
            SPELL_MESSAGE,
            SPELL_POISON_SPRAY,
          ],
        },
        {
          preText: `1–3 уровни (2 ячейки 3-го уровня)`,
          list: [
            SPELL_ARMS_OF_HADAR,
            SPELL_DARKNESS,
            SPELL_FEAR,
            SPELL_HEX,
            SPELL_HOLD_PERSON,
            SPELL_HUNGER_OF_HADAR,
            SPELL_WITCH_BOLT,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в форме юань-ти',
      description: `юань-ти делает две атаки _Укусом_, используя змеиные руки.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Сон Мершолка',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Юань-то выбирает целями до 5 существ, которых может видеть не далее чем в 60 футах от себя. Каждая цель должна пройти испытание Телосложения СЛ 13 или упасть в магический сон и потерять сознание на 10 минут. Спящая цель просыпается, если получит урон или кто-то использует действие, чтобы потрясти или дать пощёчину и разбудить её. Этот магический сон не действует на существ с иммунитетом от очарования.`,
    },
  ],
}
