import reduceWithChild from './reduceWithChild';

const getWarningList = reduceWithChild((warningList, { type, list, value }) => {
  if (type !== 'select') {
    return warningList
  }

  const warningItem = list
    .filter(({ warning }) => warning)
    .find(item => String(item.value) === String(value))

  if (!warningItem) {
    return warningList
  }

  return [
    ...warningList,
    warningItem.warning,
  ]
})

export default getWarningList;
