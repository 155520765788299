const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  SPELL_WORD_OF_RECALL,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_WORD_OF_RECALL,
  name: 'Слово возврата',
  nameEn: `Word of Recall`,
  description: `Вы и до пяти согласных существ в пределах 5 футов от Вас мгновенно телепортируетесь в заранее подготовленное святилище. Вы и телепортирующиеся с Вами существа появляетесь в свободном пространстве, ближайшем к месту, указанному Вами при подготовке святилища (смотрите ниже). Если Вы активируете это заклинание, не подготовив святилище, заклинание не оказывает никакого эффекта.

Вы должны подготовить святилище, активировав это же заклинание в таком месте как храм, посвящённом или сильно связанном с Вашим божеством. Если Вы попытаетесь активировать это заклинание в месте, не связанном с Вашим божеством, заклинание не окажет никакого эффекта.`,
  lvl: 6,
  magicSchoolId: MAGIC_CONJURATION,
  range: 5,
  componentIdList: [CAST_VERBAL],
  source: [
    {
      id: SOURCE_PHB,
      page: 278,
    },
    {
      id: SOURCE_SRD,
      page: 193,
    },
  ],
}
