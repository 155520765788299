const listToCollectionById = require('./../utils/listToCollectionById')

const DOMAIN_ARCANA = 'arcana'
const DOMAIN_DEATH = 'death'
const DOMAIN_FATE = 'fate'
const DOMAIN_FORGE = 'forge'
const DOMAIN_GRAVE = 'grave'
const DOMAIN_KNOWLEDGE = 'knowledge'
const DOMAIN_LIFE = 'life'
const DOMAIN_LIGHT = 'light'
const DOMAIN_NATURE = 'nature'
const DOMAIN_ORDER = 'order'
const DOMAIN_PEACE = 'peace'
const DOMAIN_PROTECTION = 'protection'
const DOMAIN_TEMPEST = 'tempest'
const DOMAIN_TRICKERY = 'trickery'
const DOMAIN_TWILIGHT = 'twilight'
const DOMAIN_UNITY = 'unity'
const DOMAIN_WAR = 'war'

const domainList = [
  {
    id: DOMAIN_KNOWLEDGE,
    name: 'Знание',
    nameFull: 'Домен знания',
    nameEn: 'Knowledge Domain',
  },
  {
    id: DOMAIN_ARCANA,
    name: 'Магия',
    nameFull: 'Домен магии',
    nameEn: 'Arcana Domain',
  },
  {
    id: DOMAIN_DEATH,
    name: 'Смерть',
    nameFull: 'Домен смерти',
    nameEn: 'Death Domain',
  },
  {
    id: DOMAIN_TRICKERY,
    name: 'Обман',
    nameFull: 'Домен обмана',
    nameEn: 'Trickery Domain',
  },
  {
    id: DOMAIN_WAR,
    name: 'Война',
    nameFull: 'Домен войны',
    nameEn: 'War Domain',
  },
  {
    id: DOMAIN_LIFE,
    name: 'Жизнь',
    nameFull: 'Домен жизни',
    nameEn: 'Life Domain',
  },
  {
    id: DOMAIN_LIGHT,
    name: 'Свет',
    nameFull: 'Домен света',
    nameEn: 'Light Domain',
  },
  {
    id: DOMAIN_NATURE,
    name: 'Природа',
    nameFull: 'Домен природы',
    nameEn: 'Nature Domain',
  },
  {
    id: DOMAIN_FATE,
    name: 'Судьба',
    nameFull: 'Домен судьбы',
    nameEn: 'Fate Domain',
  },
  {
    id: DOMAIN_FORGE,
    name: 'Кузня',
    nameFull: 'Домен кузни',
    nameEn: 'Forge Domain',
  },
  {
    id: DOMAIN_TEMPEST,
    name: 'Буря',
    nameFull: 'Домен бури',
    nameEn: 'Tempest Domain',
  },
  {
    id: DOMAIN_GRAVE,
    name: 'Упокоение',
    nameFull: 'Домен упокоения',
    nameEn: 'Grave Domain',
  },
  {
    id: DOMAIN_ORDER,
    name: 'Порядок',
    nameFull: 'Домен порядка',
    nameEn: 'Order Domain',
  },
  {
    id: DOMAIN_PROTECTION,
    name: 'Защита',
    nameFull: 'Домен защиты',
    nameEn: 'Protection Domain',
  },
  {
    id: DOMAIN_TWILIGHT,
    name: 'Сумрак',
    nameFull: 'Домен сумрака',
    nameEn: 'Twilight Domain',
  },
  {
    id: DOMAIN_UNITY,
    name: 'Единство',
    nameFull: 'Домен единства',
    nameEn: 'Unity Domain',
  },
  {
    id: DOMAIN_PEACE,
    name: 'Мир',
    nameFull: 'Домен мира',
    nameEn: 'Peace Domain',
  },
]

module.exports = domainList

module.exports.domainCollection = listToCollectionById(domainList)

module.exports.DOMAIN_ARCANA = DOMAIN_ARCANA
module.exports.DOMAIN_DEATH = DOMAIN_DEATH
module.exports.DOMAIN_FATE = DOMAIN_FATE
module.exports.DOMAIN_FORGE = DOMAIN_FORGE
module.exports.DOMAIN_GRAVE = DOMAIN_GRAVE
module.exports.DOMAIN_KNOWLEDGE = DOMAIN_KNOWLEDGE
module.exports.DOMAIN_LIFE = DOMAIN_LIFE
module.exports.DOMAIN_LIGHT = DOMAIN_LIGHT
module.exports.DOMAIN_NATURE = DOMAIN_NATURE
module.exports.DOMAIN_ORDER = DOMAIN_ORDER
module.exports.DOMAIN_PEACE = DOMAIN_PEACE
module.exports.DOMAIN_PROTECTION = DOMAIN_PROTECTION
module.exports.DOMAIN_TEMPEST = DOMAIN_TEMPEST
module.exports.DOMAIN_TRICKERY = DOMAIN_TRICKERY
module.exports.DOMAIN_TRICKERY = DOMAIN_TRICKERY
module.exports.DOMAIN_TWILIGHT = DOMAIN_TWILIGHT
module.exports.DOMAIN_UNITY = DOMAIN_UNITY
module.exports.DOMAIN_WAR = DOMAIN_WAR
