const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_THRI_KREEN} = require('./../../creatureTypeIdList')
const {thriKreenDescriptionList} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_THRI_KREEN,
  nameEn: 'Thri-kreen',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'три-крин',
      genitive: 'три-крина',
      dative: 'три-крину',
      accusative: 'три-крина',
      instrumental: 'три-крином',
      prepositional: 'три-крине',
    },
    plural: {
      nominative: 'три-крины',
      genitive: 'три-кринов',
      dative: 'три-кринам',
      accusative: 'три-кринов',
      instrumental: 'три-кринами',
      prepositional: 'три-кринах',
    },
  },
  description: thriKreenDescriptionList,
}
