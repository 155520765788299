const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_UNUSUAL_NATURE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_MAGEN,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_CROSSBOW_LIGHT,
  GEAR_GREATSWORD,
} = require('./../../../../gearIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_DEMOS_MAGEN} = require('./../../../../creatureIdList')
const {DAMAGE_POISON} = require('./../../../../damageTypeList')
const {LANG_ITS_CREATOR} = require('./../../../../languageIdList')
const {magenDescription} = require('./../../../../textCommonParts')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_IDRotF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_CREATE_MAGEN} = require('./../../../../spellIdList')

module.exports = {
  name: 'Маген-демос',
  nameEn: 'Demos Magen',
  id: CREATURE_DEMOS_MAGEN,
  sizeType: SIZE_MEDIUM,
  createdBySpellId: SPELL_CREATE_MAGEN,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_MAGEN,
  ],
  description: [
    `Маген-демос носит доспех, оружие и обычно выполняют роль охраны.`,
    magenDescription,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_IDRotF,
    page: 301,
  },
  armor: 16,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Пламенная кончина',
      nameEn: 'Fiery End',
      description: `Если ★СУЩЕСТВО★ умирает, его тело распадается в безвредном хлопке огня и дыма, оставляя после себя всё, что ★носил★ и ★нёс★.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_UNUSUAL_NATURE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_GREATSWORD,
    },
    {
      gearId: GEAR_CROSSBOW_LIGHT,
    },
  ],
}
