const {
    SOURCE_SKT,
  } = require('./../../../../sourceList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_MEDICINE,
    SKILL_NATURE,
    SKILL_PERCEPTION,
    SKILL_SURVIVAL,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    SPELL_ANIMAL_MESSENGER,
    SPELL_ANIMATE_DEAD,
    SPELL_AUGURY,
    SPELL_BARKSKIN,
    SPELL_BEAST_SENSE,
    SPELL_BESTOW_CURSE,
    SPELL_CHILL_TOUCH,
    SPELL_CORDON_OF_ARROWS,
    SPELL_CRUSADER_S_MANTLE,
    SPELL_DANCING_LIGHTS,
    SPELL_DETECT_MAGIC,
    SPELL_DIVINE_FAVOR,
    SPELL_DRUIDCRAFT,
    SPELL_ENHANCE_ABILITY,
    SPELL_EXPEDITIOUS_RETREAT,
    SPELL_FEATHER_FALL,
    SPELL_FEIGN_DEATH,
    SPELL_FIND_STEED,
    SPELL_FLY,
    SPELL_HEROISM,
    SPELL_HEX,
    SPELL_HYPNOTIC_PATTERN,
    SPELL_JUMP,
    SPELL_LIGHTNING_BOLT,
    SPELL_MAGE_HAND,
    SPELL_MESSAGE,
    SPELL_MOONBEAM,
    SPELL_PASS_WITHOUT_TRACE,
    SPELL_PLANT_GROWTH,
    SPELL_POLYMORPH,
    SPELL_PRAYER_OF_HEALING,
    SPELL_REVIVIFY,
    SPELL_SPEAK_WITH_ANIMALS,
    SPELL_SPEAK_WITH_DEAD,
    SPELL_SPEAK_WITH_PLANTS,
    SPELL_SPIRIT_GUARDIANS,
    SPELL_STONESKIN,
    SPELL_THAUMATURGY,
  } = require('./../../../../spellIdList'),
  {
    LANG_BOTHII,
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GEAR_HIDE_ARMOR,
    GEAR_SHORTBOW,
    GEAR_SPEAR,
  } = require('./../../../../gearIdList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_DIRE_WOLF,
    CREATURE_ELK,
    CREATURE_GRIFFON,
    CREATURE_RAVEN,
    CREATURE_TIGER,
    CREATURE_UTHGARDT_SHAMAN,
    CREATURE_WOLF,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Утгардтский шаман',
  nameEn: 'Uthgardt Shaman',
  id: CREATURE_UTHGARDT_SHAMAN,
  description: [
    `Утгардские варвары подозрительно относятся к большинству видов магии. Они редко принимают решение быть шаманами. Вместо этого, они навязывают эту роль рожденным с сильной связью с Миром духом. Быть шаманом, это значит выделятся в племени: одной ногой стоять на земле живых, другой — на земле мертвых. Те, кто идут тёмным путем между двумя мирами, делают это потому, что духи мёртвых взывают к ним. Остальные утгардцы боятся и уважают силу шаманов.

Утгардские шаманы обладают священным свёртком для произнесения заклинаний. Священный сверток сделан из палок, костей, перьев, пучков меха и камней, «тронутыми» духами. Шаману требуется месяц, чтобы собрать священный сверток, и шаман может использовать одновременно только один такой сверток. Священный сверток помогает только шаману, создавшего его и не заменяет обычные компоненты для заклинаний.

В дополнению к заклинаниям, что умеют творить все утгардские шаманы, шаманы различных племён имеют дополнительные заклинания в зависимости от их принадлежности к племени (смотрите сноску «Племенные заклинания утгардских шаманов»).

Общаясь с духами предков, утгардские шаманы также могут выучить секретные ритуалы. Эти ритуалы почти всегда требуют кровавую жертву, и их эффекты обычно касаются трансформации. Как пример, некоторые шаманы Чёрного ворона знают ритуал, позволяющие им выращивать гигантских воронов из яиц обычных [воронов](CREATURE:${CREATURE_RAVEN}) или некоторые шаманы Племени грифона могут превращаться в [грифонов](CREATURE:${CREATURE_GRIFFON}) через ритуал, который требует выпить огромное количество лошадиной крови.`,
    {
      header: 'Племенные заклинания утгардтского шамана',
      text: `В зависимости от племени помимо основных заклинаний, утгардские шаманы получают дополнительные способности, которые они могут использовать раз в день.

* **Племя великого червя**
  * [Божественное благоволение](SPELL:${SPELL_DIVINE_FAVOR})
  * [Гипнотический узор](SPELL:${SPELL_HYPNOTIC_PATTERN})
  * [Мантия крестоносца](SPELL:${SPELL_CRUSADER_S_MANTLE})
* **Племя грифона**
  * [Падение пёрышком](SPELL:${SPELL_FEATHER_FALL})
  * [Полет](SPELL:${SPELL_FLY})
* **Племя громового зверя**
  * [Бесследное передвижение](SPELL:${SPELL_PASS_WITHOUT_TRACE})
  * [Каменная кожа](SPELL:${SPELL_STONESKIN})
  * [Улучшение характеристики](SPELL:${SPELL_ENHANCE_ABILITY}) (только _«бычья сила»_)
* **Племя дерева духов**
  * [Дубовая кора](SPELL:${SPELL_BARKSKIN})
  * [Искусство друидов](SPELL:${SPELL_DRUIDCRAFT})
  * [Разговор с растениями](SPELL:${SPELL_SPEAK_WITH_PLANTS})
  * [Рост растений](SPELL:${SPELL_PLANT_GROWTH})
* **Племя красного тигра**
  * [Животные чувства](SPELL:${SPELL_BEAST_SENSE}) (только [тигр](CREATURE:${CREATURE_TIGER})),
  * [Прыжок](SPELL:${SPELL_JUMP})
  * [Улучшение характеристики](SPELL:${SPELL_ENHANCE_ABILITY}) (только _«кошачья ловкость»_)
* **Племя лося**
  * [Поиск скакуна](SPELL:${SPELL_FIND_STEED}) (только [лось](CREATURE:${CREATURE_ELK})),
  * [Поспешное отступление](SPELL:${SPELL_EXPEDITIOUS_RETREAT})
* **Племя небесного пони**
  * [Божественное благоволение](SPELL:${SPELL_DIVINE_FAVOR})
  * [Молния](SPELL:${SPELL_LIGHTNING_BOLT})
  * [Падение пёрышком](SPELL:${SPELL_FEATHER_FALL})
* **Племя серого волка**
  * [Животные чувства](SPELL:${SPELL_BEAST_SENSE}) (только [волк](CREATURE:${CREATURE_WOLF}) или [лютоволк](CREATURE:${CREATURE_DIRE_WOLF})),
  * [Лунный луч](SPELL:${SPELL_MOONBEAM})
  * [Разговор с животными](SPELL:${SPELL_SPEAK_WITH_ANIMALS}) (только [волк](CREATURE:${CREATURE_WOLF}) или [лютоволк](CREATURE:${CREATURE_DIRE_WOLF}))
* **Племя синего медведя**
  * [Героизм](SPELL:${SPELL_HEROISM})
  * [Улучшение характеристики](SPELL:${SPELL_ENHANCE_ABILITY}) (только _«медвежья выносливость»_)
* **Племя чёрного ворона**
  * [Падение пёрышком](SPELL:${SPELL_FEATHER_FALL})
  * [Почтовое животное](SPELL:${SPELL_ANIMAL_MESSENGER}) (только [ворон](CREATURE:${CREATURE_RAVEN})),
  * [Превращение](SPELL:${SPELL_POLYMORPH}) (только в [ворона](CREATURE:${CREATURE_RAVEN}))
* **Племя чёрного льва**
  * [Возрождение](SPELL:${SPELL_REVIVIFY})
  * [Восставший труп](SPELL:${SPELL_ANIMATE_DEAD})
  * [Леденящее прикосновение](SPELL:${SPELL_CHILL_TOUCH})
  * [Притворная смерть](SPELL:${SPELL_FEIGN_DEATH})`,
      source: {
        id: SOURCE_SKT,
        page: 247,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_SKT,
    page: 247,
  },
  armor: {
    ac: 13,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_MEDICINE]: PROF_NORMAL,
    [SKILL_NATURE]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_DOUBLE,
  },
  languageList: [
    LANG_BOTHII,
    LANG_COMMON,
  ],
  cr: CR_2,
  spellCast: {
    baseStat: PARAM_CHA,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DANCING_LIGHTS,
          SPELL_MAGE_HAND,
          SPELL_MESSAGE,
          SPELL_THAUMATURGY,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_AUGURY,
          SPELL_BESTOW_CURSE,
          SPELL_CORDON_OF_ARROWS,
          SPELL_DETECT_MAGIC,
          SPELL_HEX,
          SPELL_PRAYER_OF_HEALING,
          SPELL_SPEAK_WITH_DEAD,
          SPELL_SPIRIT_GUARDIANS,
        ],
      },
    ],
  },
  actionList: [
    {
      gearId: GEAR_SPEAR,
    },
    {
      gearId: GEAR_SHORTBOW,
    },
  ],
}
