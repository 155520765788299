const {
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  ABILITY_IMMUTABLE_FORM,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_AMNIZU,
  CREATURE_HELLFIRE_ENGINE,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_16} = require('./../../../../crList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {killAsLemureText} = require('./../../../../textCommonParts')
const {LANG_INFERNAL} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Адское орудие',
  nameEn: 'Hellfire Engine',
  id: CREATURE_HELLFIRE_ENGINE,
  description: [
    `Орудия Ада являются полуавтономными разрушителями. [Амнизу](CREATURE:${CREATURE_AMNIZU}) и другие дьявольские генералы держат их в резерве, пока они не понадобятся, чтобы отразить вторжение демонов или смертных крестоносцев, но изредка какой-то из этих механомагических гибридов выходит из-под управления, ведомый только своей непреодолимой тягой к разрушению.`,
    {
      header: 'Множество форм, одна цель',
      text: `Адские орудия принимают разные формы, но все они имеют одну цель: крошить врагов толпами. Они неспособны на хитрость или обман, но их разрушительная мощь огромна.`,
      source: {
        id: SOURCE_MTOF,
        page: 179,
      },
    },
    {
      header: 'Заточение душ',
      text: `Смертные существа, убитые адскими орудиями, обречены присоединиться к адским легионам не более чем за час, если не вмешаются обладатели могущественной магии. Эрцгерцоги не хотели бы ничего лучше, чем так изменить эту магию, чтобы она работала и против демонов, но до сих пор это открытие от них ускользало.`,
      source: {
        id: SOURCE_MTOF,
        page: 179,
      },
    },
    {
      header: 'Природа конструкта',
      text: `Адское орудие не нуждается в воздухе, пище, питье или сне.`,
      source: {
        id: SOURCE_MTOF,
        page: 179,
      },
    },
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 179,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 24,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 1,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_PSYCHIC,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_INFERNAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_16,
  featureList: [
    ABILITY_IMMUTABLE_FORM,
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Сокрушающее передвижение',
      description: `Адское орудие движется вперёд на расстояние, не превышающее его скорость. Во время движения, оно может входить в пространство существ с размером не больше Большого. Существо, в чьё пространство вошло адское орудие, должно пройти испытание Ловкости СЛ 18, чтобы отпрыгнуть с пути адской машины на 5 фт. При провале, машина давит существо и наносит ему 28 (8к6) дробящего урона.

Если адское орудие остаётся на том же месте, где лежит существо, то существо обездвиживается, пока машина не продолжит свой путь и не освободит существу пространство. Пока существо обездвижено, оно или другое существо, находящееся рядом на расстоянии 5 фт, может совершить проверку Силы СЛ 18. При успехе, существо освобождается и перемещается на любое свободное пространство на расстоянии 5 фт от адского орудия, и прекращает быть обездвиженным.`,
    },
    {
      name: 'Адское вооружение',
      description: `Адское орудие использует одно из следующих орудий.

* **Костный распылитель.** Адское орудие выплёскивает разъедающее пламя в 60-футовом конусе. Каждое существо в этой области должно пройти испытание Ловкости СЛ 20. При провале, существа получают 11 (2к10) огненного урона плюс 18 (4к8) урона кислотой, а при успешном испытании — половину этого урона. Существа, провалившие испытание, заливаются обжигающей кислотой и получают 5 (1к10) огненного урона плюс 9 (2к8) урона кислотой в конце своего хода. Пострадавшее существо или существо, находящееся рядом с ним на расстоянии 5 фт., может потратить действие, чтобы выскребать эту горючую смесь.

* **Молниеносный цеп.** Рукопашная атака оружием: +11 к попаданию, досягаемость 15 фт., одно существо. Попадание: 18 (3к8 + 5) дробящего урона плюс 22 (5к8) урона электричеством. Также, адское орудие может выбрать до трёх существ, которых может видеть на расстоянии 30 фт. от первоначальной цели. Эти существа, должны пройти испытание Ловкости СЛ 20, при провале получая 22 (5к8) урона электричеством или половину этого урона при успехе.

* **Звуковая пушка.** Адское орудие прицеливается и стреляет в точку в пределах 120 футов своей видимости. Каждое существо, находящееся в пределах 30 футов от выбранной точки, должны пройти испытание Ловкости СЛ 20, получив, при провале, 27 (5к10) дробящего урона плюс 13 (2к12) урона звуком, а при успехе половину этого урона.

Если одно из выбранных орудий ${killAsLemureText}`,
    },
  ],
  genderId: GENDER_MIDDLE,
}
