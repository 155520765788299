const {
    CREATURE_TYPE_ELEMENTAL_EARTH,
    CREATURE_TYPE_ELEMENTAL,
  } = require('./../../../../creatureTypeIdList'),
  {
    SPEED_DIG,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    CREATURE_AIR_ELEMENTAL_SPIRIT,
    CREATURE_EARTH_ELEMENTAL_SPIRIT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух элементаля земли',
  nameEn: 'Earth Elemental Spirit',
  id: CREATURE_EARTH_ELEMENTAL_SPIRIT,
  parentId: CREATURE_AIR_ELEMENTAL_SPIRIT,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_EARTH,
  ],
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_DIG]: 40,
  },
  resistanceList: [
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  ],
  featureList: [],
}
