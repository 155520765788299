const {SOURCE_AI} = require('./../../sourceList')

module.exports = [
  {
    header: 'Глубинные вороны',
    text: `Некоторые приключенцы и мудрецы считают что глубинные вороны были созданы как фамильяры злых практиков тайной магии. Позже их бросили в подземных глубинах и оставили расти до невероятных размеров. Другие считают что эти существа раньше были обычными воронами, которых изменили злые эссенции и злые силы. Но все согласны насчёт того, что эти оказаться пищей этих чудищ — слишком реальная возможность для тех, кто вламывается в их смертоносные логова.

Хотя глубинная ворона явно представляет собой увеличенную форму одноимённой птицы, это существо — уникальный вид птичьего насекомого. При близком рассмотрении глубинную ворону легко отличить от других гигантских птиц по её насекомовидным жвалам, лапам о четырёх когтях и самой тревожной части её внешности — россыпи красных глаз.`,
    source: {
      id: SOURCE_AI,
      page: 212,
    },
  },
  {
    header: 'Ужас с небес',
    text: `Самый страшный удар глубинной вороны — укус её жвалами. Она может схватить и удерживать ими почти любое существо. Обычная её атака — выглядит как схватила-и-унесла, после чего спутники видят улетающую с их ещё живым товарищем  глубинную ворону. Это происходит так быстро, что они даже не успевают контратаковать. Если обречённая жертва не сопротивляется, ворона относит её в логово, чтобы сожрать попозже. Сопротивляющуюся добычу ворона обычно несколько раз сбрасывает с высоты на камни, чтобы её слегка подуспокоить.`,
    source: {
      id: SOURCE_AI,
      page: 212,
    },
  },
  {
    header: 'Семейные связи',
    text: `Глубинные вороны живут в одиночестве много лет, выбираясь из логова только для охоты, для устранения потенциальных угроз и для спаривания. Спаривание глубинных ворон происходит один раз в жизни и после него обе спарившиеся особи откладывают от четырёх до восьми яиц каждая. Яйца высиживаются целый год и ещё год юные глубинные воронята остаются со своим родителем. После этого они покидают мать и основывают собственные логова.`,
    source: {
      id: SOURCE_AI,
      page: 212,
    },
  },
  {
    header: 'Малоизученные и загадочные',
    text: `Образ мышления этих разумных, но чужеродных созданий — загадка, но те, кто с ними сталкивался, утверждают что глубинные вороны обидчивы и злопамятны. Они могут помнить обиду годами и десятилетиями. Что намного важнее, они могут привить те же обиды птенцам, а те — своим птенцам, из-за чего ненависть глубинных ворон к определённым врагам и их потокам может жить столетиями.`,
    source: {
      id: SOURCE_AI,
      page: 212,
    },
  },
]
