const {
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {CONDITION_PRONE} = require('./../../../../../conditionList')
const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_SAPPING_STING} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SAPPING_STING,
  name: 'Иссушающий укол',
  nameEn: 'Sapping Sting',
  description: `Вы иссушаете жизненную силу одного видимого Вами в пределах дистанции существа. Цель должна пройти испытание Телосложения или получить 1к4 урона некротической энергией и упасть навзничь.`,
  highLvlCast: `Урон заклинания увеличивается на 1к4, когда Вы достигаете 5 уровня (2к4), 11 уровня (3к4) и 17 уровня (4к4).`,
  lvl: 0,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  isDunamisBased: true,
  effect: {
    savethrowParam: PARAM_CON,
    condition: CONDITION_PRONE,
    damage: {
      type: DAMAGE_NECROTIC,
      diceCount: 1,
      diceType: 4,
    },
  },
  source: {
    id: SOURCE_EGTW,
    page: 190,
  },
}
