const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  SPELL_BLESS,
  SPELL_BRANDING_SMITE,
  SPELL_CURE_WOUNDS,
  SPELL_GREATER_RESTORATION,
  SPELL_LESSER_RESTORATION,
  SPELL_PLANAR_ALLY,
  SPELL_WIND_WALK,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_NECKLACE_OF_PRAYER_BEADS,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_NECKLACE_OF_PRAYER_BEADS,
  name: `Ожерелье молитвенных чёток`,
  nameEn: `Necklace of Prayer Beads`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  attunementComment: `друидом, жрецом или паладином`,
  description: `У этого ожерелья 1к4 + 2 магические бусины, изготовленные из аквамарина, чёрного жемчуга или топаза. На нём также много немагических бусин, изготовленных из янтаря, кровавика, цитрина, коралла, жадеита, жемчуга или кварца. Если магическую бусину снимут с ожерелья, она теряет свою магию.

Существует шесть видов магических бусин. Мастер сам решает вид каждой бусины или определяет их случайным образом. На ожерелье могут быть бусины одного и того же вида. Для того чтобы использовать одну из них, Вы должны носить это ожерелье. В каждой бусине находится заклинание, которое Вы можете наложить ей бонусным действием (при необходимости используйте свою СЛ испытания). После того как заклинание магической бусины наложено, эту бусину нельзя использовать повторно до следующего рассвета.

| к20   | Бусина…           | Заклинание                                                                                                          |
|-------|-------------------|---------------------------------------------------------------------------------------------------------------------|
| 1–6   | Благословения     | [Благословение](SPELL:${SPELL_BLESS})                                                                               |
| 7–12  | Лечения           | [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}) (2 уровень) или [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}) |
| 13–16 | Благоволения      | [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION})                                                         |
| 17–18 | Кары              | [Клеймящая кара](SPELL:${SPELL_BRANDING_SMITE})                                                                     |
| 19    | Призыва           | [Планарный союзник](SPELL:${SPELL_PLANAR_ALLY})                                                                     |
| 20    | Хождения по ветру | [Хождение по ветру](SPELL:${SPELL_WIND_WALK})                                                                       |
`,
  genderId: GENDER_MIDDLE,
  spellIdList: [
    SPELL_BLESS,
    SPELL_BRANDING_SMITE,
    SPELL_CURE_WOUNDS,
    SPELL_GREATER_RESTORATION,
    SPELL_LESSER_RESTORATION,
    SPELL_PLANAR_ALLY,
    SPELL_WIND_WALK,
  ],
  source: {
    id: SOURCE_DMG,
    page: 187,
  },
}
