const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {PC_RACE_DWARF} = require('./../../../pcRaceIdList')
const musicalToolDescriptionList = require('./../../constants/musicalToolDescriptionList')
const slingDescription = require('./../../constants/slingDescription')
const {
  VOLUME_UNIT_FEET_CUBE,
} = require('./../../../volumeUnitList')
const {
  POISON_TYPE_INJURY,
} = require('./../../../poisonTypeList')
const {
  PC_RACE_GNOME,
} = require('./../../../pcRaceIdList')
const {
  PARAM_STR,
  PARAM_CON,
} = require('./../../../paramList')
const {
  SOURCE_DMG,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_XGTE,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {
  CREATURE_GIANT_POISONOUS_SNAKE,
} = require('./../../../creatureIdList')
const {
  DAMAGE_POISON,
} = require('./../../../damageTypeList')
const {
  CAT_AMMUNITION,
  CAT_ANIMALS,
  CAT_ARTISAN_TOOLS,
  CAT_CONTAINERS,
  CAT_DRUID_FOCUS,
  CAT_EQUIPMENT,
  CAT_GOODS,
  CAT_HEAVY_ARMOR,
  CAT_EQUIPMENT_KIT,
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_MUSIC_TOOLS,
  CAT_POISONS,
  CAT_SHIELDS,
  CAT_TRANSPORT,
  CAT_TRANSPORT_GROUND,
  CAT_TRANSPORT_WATER,
} = require('./../../../gearCategoryList')

const {
  GEAR_BACKPACK,
  GEAR_BOOK,
  GEAR_HAMMER_SLEDGE,
  GEAR_INK,
  GEAR_INK_PEN,
  GEAR_LEATHER_ARMOR,
  GEAR_PARCHMENT,
  GEAR_SACK,
  GEAR_SADDLEBAGS,
  GEAR_SAFFRON,
  GEAR_SAILING_SHIP,
  GEAR_SALT,
  GEAR_SCALE_MAIL,
  GEAR_SCALE_MERCHANTS,
  GEAR_SCHOLAR_S_PACK,
  GEAR_SEALING_WAX,
  GEAR_SERPENT_VENOM,
  GEAR_SHAWM,
  GEAR_SHEEP,
  GEAR_SHIELD,
  GEAR_SHOVEL,
  GEAR_SIGNAL_WHISTLE,
  GEAR_SIGNET_RING,
  GEAR_SILK,
  GEAR_SILVER,
  GEAR_SLED,
  GEAR_SLING_BULLETS,
  GEAR_SMITHS_TOOLS,
  GEAR_SOAP,
  GEAR_SPELLBOOK,
  GEAR_SPIKED_ARMOR,
  GEAR_SPIKES_IRON,
  GEAR_SPLINT_ARMOR,
  GEAR_SPRIG_OF_HOLLY,
  GEAR_SPRIG_OF_MISTLETOE,
  GEAR_SPYGLASS,
  GEAR_STUDDED_LEATHER_ARMOR,
  GEAR_WHETSTONE,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_STUDDED_LEATHER_ARMOR,
    genderId: GENDER_MALE,
    name: 'Проклёпанный доспех',
    nameByCase: {
      nominative: 'проклёпанный доспех',
      genitive: 'проклёпанного доспеха',
      accusative: 'проклёпанный доспех',
      instrumental: 'проклёпанным доспехом',
    },
    nameAlt: [
      'Доспех из проклёпанной кожи',
      'Клёпаный кожаный доспех',
    ],
    nameEn: 'Studded Leather Armor',
    description: `Изготовленный из крепкой, но гибкой кожи проклёпанный доспех усилен тесно расположенными шипами или заклёпками.

Будьте осторожны — этот тип брони намного дороже, чем обычная [кожаная броня](GEAR:${GEAR_LEATHER_ARMOR}) и намного тяжелее. Вы не захотите носить её в длинном походе, если Вы недостаточно сильны.`,
    cost: 4500,
    weight: 13,
    acChangeTo: 12,
    acUseDexMod: true,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_LIGHT_ARMOR,
  },
  {
    id: GEAR_SCALE_MAIL,
    genderId: GENDER_MALE,
    name: 'Чешуйчатый доспех',
    nameByCase: {
      nominative: 'чешуйчатый доспех',
      genitive: 'чешуйчатого доспеха',
      accusative: 'чешуйчатый доспех',
      instrumental: 'чешуйчатый доспехом',
    },
    nameEn: 'Scale Mail',
    description: `Этот доспех состоит из кожаных куртки и поножей (а также, возможно, отдельной юбки), покрытых перекрывающимися кусочками металла, похожими на рыбную чешую. В комплект входят рукавицы.

Он обеспечивает полную защиту тела для бесстрашного искателя приключений.`,
    cost: 5000,
    weight: 45,
    hidingDisadvantage: true,
    acChangeTo: 14,
    acUseDexMod: true,
    acDexModMax: 2,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_MEDIUM_ARMOR,
  },
  {
    id: GEAR_SPLINT_ARMOR,
    genderId: GENDER_MALE,
    name: 'Пластинчатый доспех',
    nameByCase: {
      nominative: 'пластинчатый доспех',
      genitive: 'пластинчатого доспеха',
      accusative: 'пластинчатый доспех',
      instrumental: 'пластинчатым доспехом',
    },
    nameAlt: 'Наборный доспех',
    nameEn: 'Splint Armor',
    description: `Этот доспех состоит из узких вертикальных металлических пластин, приклёпанных к кожаной подложке, носимой поверх слоя ватина. Соединения защищаются кольчужным полотном.`,
    cost: 20000,
    weight: 60,
    hidingDisadvantage: true,
    acChangeTo: 17,
    requirementList: [
      {
        type: 'param',
        paramType: PARAM_STR,
        min: 15,
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_HEAVY_ARMOR,
  },
  {
    id: GEAR_SHIELD,
    genderId: GENDER_MALE,
    name: 'Щит',
    nameByCase: {
      nominative: 'щит',
      genitive: 'щита',
      accusative: 'щит',
      instrumental: 'щитом',
    },
    nameEn: 'Shield',
    description: `Щит изготавливается из дерева или металла, и несётся одной рукой. Использование щита увеличивает КД на 2. Вы получаете преимущество только от одного щита одновременно.`,
    cost: 1000,
    weight: 6,
    acBonus: 2,
    source: {
      id: SOURCE_PHB,
      page: 145,
    },
    category: CAT_SHIELDS,
  },
  {
    id: GEAR_SLING_BULLETS,
    genderId: GENDER_MULTIPLE,
    name: 'Ядра для пращи',
    nameByCase: {
      nominative: 'ядра для пращи',
      genitive: 'ядер для пращи',
      accusative: 'ядра для пращи',
      instrumental: 'ядрами для пращи',
    },
    nameAlt: 'Снаряды для пращи',
    nameEn: 'Sling Bullets',
    description: slingDescription,
    quantity: 20,
    cost: 4,
    weight: 1.5,
    source: [
      {
        id: SOURCE_PHB,
        page: 150,
      },
      {
        id: SOURCE_AYAGWnW,
        page: 114,
      },
    ],
    category: CAT_AMMUNITION,
  },
  {
    id: GEAR_SCALE_MERCHANTS,
    genderId: GENDER_MULTIPLE,
    name: 'Весы, торговые',
    nameByCase: {
      nominative: 'торговые весы',
      genitive: 'торговых весов',
      accusative: 'торговые весы',
      instrumental: 'торговыми весами',
    },
    nameEn: 'Scale, Merchant’s',
    description: 'В набор входят рычажные весы, чашки и набор грузиков на 2 фунта. С их помощью можно точно измерять вес небольших предметов, таких как драгоценные металлы или товары.',
    cost: 500,
    weight: 3,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_SEALING_WAX,
    genderId: GENDER_MALE,
    name: 'Сургуч',
    nameByCase: {
      nominative: 'сургуч',
      genitive: 'сургуча',
      accusative: 'сургуч',
      instrumental: 'сургучом',
    },
    nameAlt: 'Воск',
    nameEn: 'Sealing wax',
    cost: 50,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_SPELLBOOK,
    genderId: GENDER_FEMALE,
    name: 'Книга заклинаний',
    nameByCase: {
      nominative: 'книга заклинаний',
      genitive: 'книги заклинаний',
      accusative: 'книгу заклинаний',
      instrumental: 'книгой заклинаний',
    },
    nameEn: 'Spellbook',
    description: 'Книги заклинаний очень важны для волшебников. Это переплетённые кожей тома, содержащие 100 пустых пергаментных страниц, на которых можно записывать заклинания.',
    cost: 5000,
    weight: 3,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_SIGNET_RING,
    genderId: GENDER_MIDDLE,
    name: 'Перстень-печатка',
    nameByCase: {
      nominative: 'перстень-печатка',
      genitive: 'перстня-печатки',
      accusative: 'перстень-печатка',
      instrumental: 'перстнем-печаткой',
    },
    nameAlt: 'Кольцо-печатка',
    nameEn: 'Signet ring',
    cost: 500,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_SHOVEL,
    genderId: GENDER_FEMALE,
    name: 'Лопата',
    nameByCase: {
      nominative: 'лопата',
      genitive: 'лопаты',
      accusative: 'лопату',
      instrumental: 'лопатой',
    },
    nameEn: 'Shovel',
    cost: 200,
    weight: 5,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_SACK,
    genderId: GENDER_MALE,
    name: 'Мешок',
    nameByCase: {
      nominative: 'Мешок',
      accusative: 'Мешок',
      instrumental: 'Мешком',
    },
    nameEn: 'Sack',
    description: '1 фт³/30 фунтов',
    cost: 1,
    volume: {
      unitId: VOLUME_UNIT_FEET_CUBE,
      count: 1,
    },
    weight: 0.5,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_SOAP,
    genderId: GENDER_MIDDLE,
    name: 'Мыло',
    nameByCase: {
      nominative: 'Мыло',
      accusative: 'Мыло',
      instrumental: 'Мылом',
    },
    nameEn: 'Soap',
    cost: 2,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_SPYGLASS,
    genderId: GENDER_FEMALE,
    name: 'Подзорная труба',
    nameByCase: {
      nominative: 'Подзорная труба',
      accusative: 'Подзорную трубу',
      instrumental: 'Подзорной трубой',
    },
    nameEn: 'Spyglass',
    description: 'Предметы, на которые смотрят в подзорную трубу, увеличиваются в два раза.',
    cost: 100000,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_SIGNAL_WHISTLE,
    genderId: GENDER_MALE,
    name: 'Свисток',
    nameByCase: {
      nominative: 'Свисток',
      accusative: 'Свисток',
      instrumental: 'Свистком',
    },
    nameAlt: 'Сигнальный свисток',
    nameEn: 'Signal whistle',
    cost: 5,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_SPRIG_OF_MISTLETOE,
    genderId: GENDER_FEMALE,
    name: 'Веточка омелы',
    nameByCase: {
      nominative: 'веточка омелы',
      genitive: 'веточки омелы',
      accusative: 'веточку омелы',
      instrumental: 'веточкой омелы',
    },
    nameEn: 'Sprig of mistletoe',
    description: 'Друид может использовать этот предмет в качестве фокусировки для заклинаний.',
    cost: 100,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_DRUID_FOCUS,
  },
  {
    id: GEAR_SPRIG_OF_HOLLY,
    genderId: GENDER_FEMALE,
    name: 'Веточка падуба',
    nameByCase: {
      nominative: 'веточка падуба',
      genitive: 'веточки падуба',
      accusative: 'веточку падуба',
      instrumental: 'веточкой падуба',
    },
    nameEn: 'Sprig of holly',
    description: 'Друид может использовать этот предмет в качестве фокусировки для заклинаний.',
    cost: 100,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 154,
    },
    category: CAT_DRUID_FOCUS,
  },
  {
    id: GEAR_SPIKES_IRON,
    genderId: GENDER_MULTIPLE,
    name: 'Шипы железные',
    nameByCase: {
      nominative: 'железные шипы',
      genitive: 'железных шипов',
      accusative: 'железные шипы',
      instrumental: 'железными шипами',
    },
    nameEn: 'Spikes, iron',
    cost: 100,
    weight: 2,
    quantity: 20,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_SCHOLAR_S_PACK,
    genderId: GENDER_MALE,
    name: 'Набор учёного',
    nameByCase: {
      nominative: 'набор учёного',
      genitive: 'набора учёного',
      accusative: 'набор учёного',
      instrumental: 'набором учёного',
    },
    nameEn: 'Scholar’s Pack',
    description: `Включает
    
* [рюкзак](GEAR:${GEAR_BACKPACK}),
* [научную книгу](GEAR:${GEAR_BOOK}),
* [бутылочку чернил](GEAR:${GEAR_INK}),
* [писчее перо](GEAR:${GEAR_INK_PEN}),
* 10 листов [пергамента](GEAR:${GEAR_PARCHMENT}),
* небольшую сумочку с песком,
* небольшой нож.`,
    cost: 4000,
    weight: 11,
    source: {
      id: SOURCE_PHB,
      page: 151,
    },
    category: CAT_EQUIPMENT_KIT,
  },
  {
    id: GEAR_SMITHS_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты кузнеца',
    nameByCase: {
      nominative: 'инструменты кузнеца',
      genitive: 'инструментов кузнеца',
      accusative: 'инструменты кузнеца',
      instrumental: 'инструментами кузнеца',
    },
    nameEn: 'Smith’s Tools',
    description: `Инструменты кузнеца позволяют вам работать с металлом, нагревая его для изменения формы, восстанавливая повреждения или создавая из слитков полезные предметы.

**Компоненты.** Инструменты кузнеца включают в себя

* [молоты](GEAR:${GEAR_HAMMER_SLEDGE}),
* клещи,
* уголь,
* ветошь,
* [точильный камень](GEAR:${GEAR_WHETSTONE}).

**Магия и История.** Ваш опыт даёт вам дополнительные знания при обследовании металлических предметов, таких как, например, оружие.

**Расследование.** Вы можете обнаруживать зацепки, которые другие могут не заметить, когда расследование касается брони, оружия или прочих изделий металлообработки.

**Починка.** При наличии доступа к инструментам и открытому огню, жара которого достаточно, чтобы сделать металл пластичным, Вы можете восстановить по 10 хитов повреждённого металлического предмета за каждый час работы.

| Действие                                       | СЛ |
|------------------------------------------------|----|
| Наточить затупившийся клинок                   | 10 |
| Починить комплект доспехов                     | 15 |
| Разъединить немагический металлический предмет | 15 |
`,
    cost: 2000,
    weight: 8,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 86,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_SHAWM,
    genderId: GENDER_MALE,
    name: 'Шалмей',
    nameByCase: {
      nominative: 'шалмей',
      genitive: 'шалмея',
      accusative: 'шалмей',
      instrumental: 'шалмеем',
    },
    nameEn: 'Shawm',
    description: [
      {
        header: 'Шалмей',
        text: `Двухсекционный инструмент, похожий на гобой или фагот, популярен у [гномов](PC_RACE:${PC_RACE_GNOME}), которые разработали его некоторые работающие на мехах версии.`,
        source: {
          id: SOURCE_SCAG,
          page: 125,
        },
      },
      ...musicalToolDescriptionList,
    ],
    cost: 200,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 154,
    },
    category: CAT_MUSIC_TOOLS,
  },
  {
    id: GEAR_SLED,
    genderId: GENDER_MULTIPLE,
    name: 'Сани',
    nameByCase: {
      nominative: 'сани',
      genitive: 'саней',
      accusative: 'сани',
      instrumental: 'санями',
    },
    nameEn: 'Sled',
    cost: 2000,
    weight: 300,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_GROUND,
  },
  {
    id: GEAR_SADDLEBAGS,
    genderId: GENDER_MULTIPLE,
    name: 'Перемётные сумы',
    nameByCase: {
      nominative: 'перемётные сумы',
      genitive: 'перемётных сум',
      accusative: 'перемётные сумы',
      instrumental: 'перемётными сумами',
    },
    nameAlt: [
      'Перемётные сумки',
      'Седельные сумки',
    ],
    nameEn: 'Saddlebags',
    cost: 400,
    weight: 8,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT,
  },
  {
    id: GEAR_SAILING_SHIP,
    genderId: GENDER_MALE,
    name: 'Парусник',
    nameByCase: {
      nominative: 'парусник',
      genitive: 'парусника',
      accusative: 'парусник',
      instrumental: 'парусником',
    },
    nameAlt: 'Парусный корабль',
    nameEn: 'Sailing Ship',
    description: 'Скорость: 2 узла',
    cost: 1000000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_WATER,
  },
  {
    id: GEAR_SALT,
    genderId: GENDER_FEMALE,
    name: 'Соль',
    nameByCase: {
      nominative: 'соль',
      genitive: 'соли',
      accusative: 'соль',
      instrumental: 'солью',
    },
    nameEn: 'Salt',
    cost: 5,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_SHEEP,
    genderId: GENDER_FEMALE,
    name: 'Овца',
    nameByCase: {
      nominative: 'овца',
      genitive: 'овцы',
      accusative: 'овцу',
      instrumental: 'овцой',
    },
    nameEn: 'Sheep',
    cost: 200,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_SILVER,
    genderId: GENDER_MIDDLE,
    name: 'Серебро',
    nameByCase: {
      nominative: 'серебро',
      genitive: 'серебра',
      accusative: 'серебро',
      instrumental: 'серебром',
    },
    nameEn: 'Silver',
    cost: 500,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_SILK,
    genderId: GENDER_MALE,
    name: 'Шёлк',
    nameByCase: {
      nominative: 'шёлк',
      genitive: 'шёлка',
      accusative: 'шёлк',
      instrumental: 'шёлком',
    },
    nameEn: 'Silk',
    description: '10 фт.²',
    cost: 1000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_SAFFRON,
    genderId: GENDER_MALE,
    name: 'Шафран',
    nameByCase: {
      nominative: 'шафран',
      genitive: 'шафрана',
      accusative: 'шафран',
      instrumental: 'шафраном',
    },
    nameEn: 'Saffron',
    cost: 1500,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_SERPENT_VENOM,
    genderId: GENDER_MALE,
    name: 'Яд, змеиный',
    nameByCase: {
      nominative: 'змеиный яд',
      genitive: 'змеиного яда',
      accusative: 'змеиный яд',
      instrumental: 'змеиным ядом',
    },
    nameEn: 'Serpent Venom',
    description: `Этот яд собирают с мёртвой или недееспособной [гигантской ядовитой змеи](CREATURE:${CREATURE_GIANT_POISONOUS_SNAKE}). Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 11, получая урон ядом 10 (3к6) при провале, или половину этого урона при успехе.`,
    damage: {
      diceType: 6,
      diceCount: 3,
    },
    saveThrow: {
      type: PARAM_CON,
      dc: 11,
    },
    cost: 20000,
    damageType: DAMAGE_POISON,
    poisonType: POISON_TYPE_INJURY,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
  {
    id: GEAR_SPIKED_ARMOR,
    genderId: GENDER_MALE,
    name: 'Шипованный доспех',
    nameByCase: {
      nominative: 'шипованный доспех',
      genitive: 'шипованного доспеха',
      accusative: 'шипованный доспех',
      instrumental: 'шипованным доспехом',
    },
    nameEn: 'Spiked Armor',
    description: `Шипованный доспех — редкий вид среднего доспеха, изготавливаемого [дварфами](PC_RACE:${PC_RACE_DWARF}).

Он состоит из кожаной куртки и поножей, покрытых шипами, обычно сделанными из металла.`,
    cost: 7500,
    weight: 45,
    acChangeTo: 14,
    acUseDexMod: true,
    hidingDisadvantage: true,
    acDexModMax: 2,
    source: {
      id: SOURCE_SCAG,
      page: 122,
    },
    category: CAT_MEDIUM_ARMOR,
  },
]

module.exports = gearRawList
