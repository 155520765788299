const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SIZE_MEDIUM,
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  steederDescriptionList,
  steederNote,
} = require('./../../../../textCommonParts')
const {ABILITY_SPIDER_CLIMB} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_STEEDER_MALE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Самец стидера',
  nameEn: 'Male Steeder',
  id: CREATURE_STEEDER_MALE,
  description: steederDescriptionList,
  note: steederNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: [
    {
      id: SOURCE_MTOF,
      page: 230,
    },
    {
      url: `https://5e.tools/bestiary.html#female%20steeder_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Самца стидера» искажён текст особенности «Невероятный скачок» и завышен максимальный размер существа для действия «Липкие лапы».`,
    },
  ],
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 14,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_SPIDER_CLIMB,
    {
      name: 'Невероятный скачок',
      description: `Расстояние прыжка стидера утраивается. Каждый фут передвижения, затраченный на прыжок, даёт 3 фута дальности прыжка.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 2,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 1,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Липкие лапы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            size: {
              max: SIZE_SMALL,
            },
          },
        },
        hit: `Цель прилипает к лапе стидера и схвачена до тех пор, пока не освободится (СЛ высвобождения 12). Стидер может удерживать только одно существо за раз.`,
      },
    },
  ],
}
