const {FEATURE_HORIZON_WALKER_SPELLS_UA_2017_01_16_RANGER_ROGUE} = require('./../../../featureIdList')
const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_HORIZON_WALKER_2017_01_16} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2017_01_16_RANGER_ROGUE} = require('./../../../sourceList')
const {
  SPELL_ALTER_SELF,
  SPELL_BANISHMENT,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_TELEPORTATION_CIRCLE,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_HORIZON_WALKER_SPELLS_UA_2017_01_16_RANGER_ROGUE,
    name: `Магия Странников горизонта`,
    nameEn: `Horizon Walker Spells`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    pcSubClassId: PC_SUBCLASS_RANGER_HORIZON_WALKER_2017_01_16,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_ALTER_SELF,
      SPELL_BANISHMENT,
      SPELL_PROTECTION_FROM_ENERGY,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_TELEPORTATION_CIRCLE,
    ],
    source: {
      id: SOURCE_UA_2017_01_16_RANGER_ROGUE,
      page: 1,
    },
  },
]
