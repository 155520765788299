const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_OGRE,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_COMMON,
    LANG_GIANT,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_HIDE_ARMOR,
    GEAR_JAVELIN,
  } = require('./../../../../gearIdList'),
  {
    ogreDescription,
    ogreNote,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_OGRE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Огр',
  nameEn: 'Ogre',
  id: CREATURE_OGRE,
  description: ogreDescription,
  note: ogreNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_OGRE,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 222,
  },
  armor: {
    ac: 11,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 16,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 7,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
    LANG_COMMON,
  ],
  cr: CR_2,
  actionList: [
    {
      name: 'Палица',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 6,
        range: {
          normal: 30,
          max: 120,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
