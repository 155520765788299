const {SOURCE_PotA} = require('./../../sourceList')

module.exports = {
  header: 'Культисты Чёрной Земли',
  text: `Культисты Вечного Пламени Часто говорят, что огонь — это опасный слуга и страшный хозяин. Так же и с культистами Вечного Пламени: низшие члены культа упиваются разрушительной силой огня, а лидеры используют его, чтобы избавить своё окружение от всего, что сочтут нечистым, и от тех, кто не предан делу.

Возможно, слишком поверхностно утверждать, что те, кто следует за стихийным огнём, являются пироманьяками, одержимыми пламенем с детства, но в этом есть доля истины. Служители Вечного Пламени очарованы огнём всех видов и иногда смотрят в факел по несколько минут, внимательно изучая движение каждого язычка пламени. Многих из них тянет к экспериментам: они жаждут увидеть, что произойдёт, когда бочку смолы, охотничью хижину или надоедливого торговца — неважно, что именно — охватит огонь.

Подобно стихии, которой они поклоняются, служители Вечного Пламени действуют быстро. В бою они редко берут пленных или отступают; идя вперёд, они стремятся походить на постоянно продвигающийся лесной пожар. В целом они предпочитают прямой подход и простые планы замысловатым ухищрениям, и это одна из причин их ненависти к служителям стихийной воды с присущими им тонкостями и хитростями. Культисты огня уверены, что проще сначала сжечь свою цель, а потом просеять пепел.`,
  source: {
    id: SOURCE_PotA,
    page: 205,
  },
}
