const {LANG_GITH} = require('./../../languageIdList')
const {SOURCE_MTOF} = require('./../../sourceList')
const {PC_RACE_GITH} = require('./../../pcRaceIdList')

module.exports = {
  header: 'Алфавит гитов',
  text: `[Гиты](PC_RACE:${PC_RACE_GITH}) используют письменность, состоящую из буквенных символов, объединённых в круглые кластеры, называемые _тир’су_. Каждая «спица» такого колеса соответствует букве алфавита. Каждый кластер символов – это слово. Множество _тир’су_ формируют фразы и предложения.

И гитьянки, и гитцераи говорят на [гитском](LANG:${LANG_GITH}), но у каждой расы свои чётко отличимые диалект и акцент. Также у них различаются способы написания _тир’су_. Гитьянки пишут _тир’су_ по часовой стрелке, начиная с вершины. Гитцераи используют те же символы, но пишут свои _тир’су_ против часовой стрелки, начиная снизу.`,
  source: {
    id: SOURCE_MTOF,
    page: 92,
  },
}
