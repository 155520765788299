const {
  SPELL_CONE_OF_COLD,
  SPELL_CONTROL_WEATHER,
  SPELL_HOLD_PERSON,
  SPELL_ICE_STORM,
  SPELL_RAY_OF_FROST,
  SPELL_WALL_OF_ICE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  LANG_AURAN,
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_FEY,
  CREATURE_TYPE_HAG,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_BHEUR_HAG} = require('./../../../../creatureIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {hagsDescriptionList} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Карга бьёр',
  nameEn: 'Bheur Hag',
  id: CREATURE_BHEUR_HAG,
  description: [
    `Карги бьёр живут в холодных землях и предпочитают покрытые снегом горы. Они становятся более активными зимой, используют свою магию льда и погоды, чтобы сделать жизнь в близлежащих поселениях еще более жалкой.

У карги бьёр сине-белая кожа, как у того, кто замёрз насмерть. У неё бледно белые волосы и она тощая, как будто бы пережила зиму, питаясь корой и кожей. Глаза у неё бледные и окружены тёмной, цвета синяка, плотью. Бьёр ходит с искривленным серым деревянным посохом, на котором она может летать, как на метле. Ещё он увеличивает её магическую силу.`,
    {
      header: 'Ледяные сердца',
      text: `Карг бьёр привлекают эгоистические действия из холодного расчёта, такие как убийство путешественника ради зимнего плаща, вырубка рощи дриад ради дерева для костра и так далее. Такие дела особенно сладки для бьёр если они неоправданны, например, жадный торговец, который скопил на зиму больше еды, чем может съесть, пока остальные голодают. Бьёр обожают сеять такие идеи и мысли в головы смертных. С помощью способности манипулирования погодой они душат деревни снегом и сильным морозом в надежде поселить отчаяние в жителях и обратить их друг против друга.

Карга бьёр обожает наблюдать как неподготовленные люди страдают и умирают за свои ошибки зимой. Они наслаждается тем как смертные делают жалкие, мелкие попытки выжить, поедают ботинки и лоскуты кожи, когда не могут найти настоящей еды.`,
      source: {
        id: SOURCE_VGTM,
        page: 154,
      },
    },
    {
      header: 'Узрите ужас',
      text: `Когда карга бьёр полностью поглощена битвой и недавно убила одного из своих врагов, она зачастую временно не бьёт оставшихся, а вместо этого питается телом, разрывая его на части и отрывая мясо с костей. Одного вида этой дикости достаточно, чтобы временно свести наблюдателя с ума.`,
      source: {
        id: SOURCE_VGTM,
        page: 154,
      },
    },
    {
      header: 'Шабаши',
      text: `Карга бьёр, являющаяся частью шабаша имеет опасность 9 (5,000 опыта).`,
      source: {
        id: SOURCE_VGTM,
        page: 154,
      },
    },
    ...hagsDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_HAG,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 154,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 14,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_NATURE]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_COLD,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_AURAN,
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_7,
  featureList: [
    {
      name: 'Магия серого посоха',
      description: `У карги есть серый посох, ветвь серого дерева, которая служит фокусировкой её внутренней силы. Она может использовать посох, как если бы он был _Помелом полета_. Держа посох, она может сотворять дополнительные заклинания с помощью черты _Врожденное колдовство_ (эти заклинания отмечены звездочкой). Если посох утерян или уничтожен, то карга должна сделать другой, что занимает один год и один день. Только карга бьёр может использовать серый посох.`,
    },
    {
      name: 'Хождение по льду',
      description: `Карга может передвигаться и карабкаться по любым ледяным поверхностям без каких-либо проверок способностей. Кроме того, труднопроходимая местность из льда или снега не стоит ей дополнительных очков передвижения.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_RAY_OF_FROST,
          {
            id: SPELL_HOLD_PERSON,
            comment: '*',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          {
            id: SPELL_CONE_OF_COLD,
            comment: '*',
          },
          {
            id: SPELL_ICE_STORM,
            comment: '*',
          },
          {
            id: SPELL_WALL_OF_ICE,
            comment: '*',
          },
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONTROL_WEATHER,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 1,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Безумное пиршество',
      description: `Карга пирует трупом одного из врагов в 5 футах от неё, который умер в течение последней минуты. Каждое существо по выбору карги, которое находится не далее 60 футов от неё и может её видеть, должно пройти испытание Мудрости СЛ 15 или испугаться её на 1 минуту. Будучи испуганным таким образом существо недееспособно, не может понять что говорят другие, не может читать и может лишь мямлить. Мастер контролирует движение существа, которое становится беспорядочным. Существо может повторять испытание в конце каждого своего хода и закончить эффект при успехе. Если испытание существа успешен или эффект окончен, то оно получает иммунитет от _Безумного пиршества_ карги на следующие 24 часа.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
