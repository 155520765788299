const {SOURCE_MM} = require('./../../sourceList')
const {
  SPELL_GREATER_RESTORATION,
  SPELL_WISH,
} = require('./../../spellIdList')

module.exports = [
  {
    header: 'Сфинксы',
    text: `В священной изоляции сфинксы охраняют секреты и сокровища богов. Спокойствие и уважение, с которым они принимают новых посетителей, не вяжется с разбросанными по логову костями прошлых гостей и искателей приключений, которые не смогли пройти их испытания. Огромные крылья простираются с обеих сторон их рыжевато-бурого львиного тела, на котором поигрывают мускулы. Передние лапы их настолько мощные, что могут разорвать человека пополам.

**Божественные охранники.** Сфинксы испытывают тех, кто ищут сокровища богов, будь то забытые секреты или могучие заклятья, артефакты или волшебные проходы. Существа, идущие на испытания сфинкса, либо умирают, оказавшись недостойными, либо остаются в живых, доказав своё право на это.

Некоторые сфинксы являются верховными жрецами богов, которые их создали, но большинство из них — это воплощённые духи, пришедшие в земли смертных в ответ на истовую молитву или из-за прямого вмешательства высших сил. Сфинкс, которому не нужно ни есть, ни спать, неустанно несёт свой дозор. Они редко встречаются с другими сфинксами, и не ведают иной жизни кроме своей священной миссии.

**Волшебные испытания.** Секреты и сокровища, которые охраняет сфинкс, находятся под божественной защитой, и для всякого существа, не прошедшего испытания сфинкса, путь к предмету или знанию исчезает. Даже если напасть на сфинкса и одержать победу, искатель не только не получит того, что искал, но и обретёт врага в лице бога, который поставил сфинкса на стражу.

Божества добра иногда даруют сфинксам возможность удалять посетителей, не прошедших испытания, просто перемещая их подальше и делая так, чтобы они никогда более не вернулись. Впрочем, обычно те, кто не проходят испытания, встречают свой конец от его лап.

**Существа иных миров.** Смертные чаще всего встречают сфинксов в древних гробницах и руинах, но некоторым сфинксам доступны иные миры. Разговор со сфинксом, начавшийся среди обвалившихся колонн, может внезапно перенестись в странное место, вроде шахматной доски огромных размеров или устрашающей скалы, по которой придётся взбираться, пока вокруг бушует буря. Иногда сфинкса, находящегося в таком месте вне мира, необходимо призвать в пустующее логово. И лишь те, кого сфинкс сочтёт достойными, удостоятся его присутствия.

**Падшие сфинксы.** Будь то из-за груза лет, раскаяния за убийства невинных или жажды поклонения просителей, которые так хотят выторговать себе путь к знаниям, некоторые сфинксы освобождаются от оков божественного приказа. Впрочем, даже если их мировоззрение и верность и меняются, они никогда не покидают своего поста и не выдают секретов никому кроме достойных этого существ.`,
    source: {
      id: SOURCE_MM,
      page: 276,
    },
  },
  {
    header: `Логово сфинкса`,
    text: `Сфинкс обитает в старом храме, гробнице или склепе, в котором от смертных сокрыты запретные секреты и сокровища.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) сфинкс может совершить действие логова, чтобы вызвать один из описанных ниже эффектов. Сфинкс не может использовать эффект повторно, пока не окончит короткий или длинный отдых.


* Поток времени изменяется так, что все существа в логове должны повторно определить свою инициативу. Сфинкс может не перебрасывать. 
* Поток времени изменяется так, что все существа в логове должны пройти испытание Телосложения СЛ 15, иначе они становятся на 1к20 лет старше или моложе (на выбор сфинкса). Возраст существа не может стать меньше одного года. Заклинание [высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) может вернуть существу нормальный возраст. 
* Поток времени в логове изменяется так, что всё, что в нём находится, переносится на 10 лет вперёд или назад (на выбор сфинкса). Только сфинкс знает об этом изменении времени. Заклинание [исполнение желаний](SPELL:${SPELL_WISH}) может вернуть заклинателя и до семи других существ, выбранных им самим, в нормальное время. 
* Сфинкс переносит себя и до семи других видимых им существ на другой план существования. Находясь за пределами логова, он не может использовать действия логова, но может вернуться в свой ход бонусным действием, забрав с собой до семи существ.`,
    source: {
      id: SOURCE_MM,
      page: 276,
    },
  },
]
