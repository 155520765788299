const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_TORTLE,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_AQUAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  SKILL_ATHLETICS,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  GEAR_CROSSBOW_LIGHT,
  GEAR_QUARTERSTAFF,
} = require('./../../../../gearIdList')
const {ABILITY_HOLD_BREATH} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LG} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_TORTLE} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {tortleDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Тортл',
  nameEn: 'Tortle',
  id: CREATURE_TORTLE,
  description: tortleDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_TORTLE,
  ],
  alignmentId: ALIGNMENT_LG,
  source: {
    id: SOURCE_MTOF,
    page: 231,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 12,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  languageList: [
    LANG_AQUAN,
    LANG_COMMON,
  ],
  cr: CR_1_4,
  featureList: [
    {
      id: ABILITY_HOLD_BREATH,
      time: '1 час',
    },
  ],
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_QUARTERSTAFF,
    },
    {
      gearId: GEAR_CROSSBOW_LIGHT,
    },
    {
      name: 'Панцирная защита',
      description: `Тортл втягивается в панцирь, когда испытывает угрозу. Это даёт ему бонус +4 КД и преимущество на испытания Силы и Телосложения. Пока он внутри панциря, тортл считается лежащим ничком, его скорость равна 0 фт. и не может быть увеличена, он с помехой проходит испытания Ловкости, не может использовать реакцию и единственным действием, которым он может пользоваться, является бонусное действие для вылезания из панциря.`,
    },
  ],
}
