const {
  CREATURE_COW,
  CREATURE_OX,
} = require('./../../../../creatureIdList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {GENDER_MALE} = require('./../../../../genderList')

module.exports = {
  name: 'Вол',
  nameEn: 'Ox',
  id: CREATURE_OX,
  parentId: CREATURE_COW,
  description: `Вол, в основном, используется для тягловой работы, а не для мяса или молока.`,
  creatureId: GENDER_MALE,
  source: {
    id: SOURCE_VGTM,
    page: 210,
  },
  extendPropCollection: {
    featureList: [
      {
        name: 'Вьючное животное',
        description: `Вол считается Огромным при определении его грузоподъёмности.`,
      },
    ],
  },
}
