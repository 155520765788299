const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ATHLETICS,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  GEAR_GREATSWORD,
  GEAR_PLATE_ARMOR,
  GEAR_SHORTBOW,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_12} = require('./../../../../crList')
const {CREATURE_WARLORD} = require('./../../../../creatureIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Военачальник',
  nameEn: 'Warlord',
  id: CREATURE_WARLORD,
  description: `Военачальники — легендарные полевые командиры, чьи имена произносят с трепетом. После череды решительных побед, военачальник может с лёгкостью взять на себя роль монарха или генерала и привлечь последователей, готовых умереть за его или её знамя.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 213,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 27,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 18,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_12,
  featureList: [
    {
      name: 'Упорный',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `Военачальник может перебросить проваленное испытание. Он должен использовать новый результат.`,
    },
    {
      name: 'Уцелевший',
      description: `Военачальник восстанавливает 10 хитов в начале своего хода, если у него не меньше 1 хита и не больше чем половина максимального количества хитов.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Военачальник совершает две атаки оружием.`,
    },
    {
      gearId: GEAR_GREATSWORD,
    },
    {
      gearId: GEAR_SHORTBOW,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака оружием',
      description: `Военачальник совершает атаку оружием.`,
    },
    {
      name: 'Приказ союзникам',
      description: `Военачальник выбирает одного союзника в пределах 30 футов от него, которого он может видеть. Если цель может видеть и слышать военачальника, то может реакцией совершить одну атаку оружием с преимуществом.`,
    },
    {
      name: 'Испугать врага',
      cost: 2,
      description: `Военачальник выбирает одного врага в радиусе 30 футов от него, которого может видеть. Если цель может слышать и видеть военачальника, то должна пройти испытание Мудрости СЛ 16 или стать напуганной до конца следующего хода военачальника.`,
    },
  ],
}
