const {
  CREATURE_GOBLIN,
  CREATURE_OGRE_BATTERING_RAM,
  CREATURE_OGRE_BOLT_LAUNCHER,
  CREATURE_OGRE_CHAIN_BRUTE,
  CREATURE_OGRE_HOWDAH,
  CREATURE_ORC,
  CREATURE_TROLL,
} = require('./../../creatureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_HALFLING,
} = require('./../../pcRaceIdList')

module.exports = [
  {
    header: 'Огры',
    text: `Огры слабы умом, но зато сильны телом. Они живут, грабя, собирая отбросы и убивая ради пропитания и удовольствия. Средняя взрослая особь бывает ростом от 9 до 10 футов и весом около тысячи фунтов.`,
    source: {
      id: SOURCE_MM,
      page: 222,
    },
  },
  {
    header: 'Огры',
    text: `Огры слабы умом, невероятно прожорливы и славятся вспыльчивостью. Они живут за счёт набегов, питаются отбросами и убивают ради пропитания и удовольствия.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Вспыльчивый темперамент',
    text: `Огры печально известны своей вспыльчивостью, и выходят из себя при малейшем намёке на обиду. Оскорбления и насмешки мгновенно вызывают ярость огра, так же как и кража у него, тычок, пинок, затрещина, насмешка, кривляние или просто неосторожный взгляд. Впав в ярость, огр начинает рвать и метать в кровавом исступлении, пока не останется предметов или существ, которых можно сокрушить.`,
    source: {
      id: SOURCE_MM,
      page: 222,
    },
  },
  {
    header: 'Отвратительные обжоры',
    text: `Огры едят практически всё, но особенно им нравится вкус [дварфов](PC_RACE:${PC_RACE_DWARF}), [полуросликов](PC_RACE:${PC_RACE_HALFLING}) и [эльфов](PC_RACE:${PC_RACE_ELF}). Если предоставляется возможность, они совмещают ужин с развлечением, гоняясь за убегающими жертвами, прежде чем съесть их сырыми. Если от жертвы после утоления голода останется достаточно объедков, огр может сделать себе набедренную повязку из кожи жертвы, либо ожерелье из оставшихся костей. Такое жуткое рукоделие — вершина культуры огров.`,
    source: {
      id: SOURCE_MM,
      page: 222,
    },
  },
  {
    header: 'Алчные собиратели',
    text: `При виде чужого имущества глаза огра вспыхивают алчностью. В свои рейды огры прихватывают с собой мешки, которые они наполняют шикарными «сокровищами», отнятыми у жертв. Таким сокровищем может стать погнутый шлем, плесневелая головка сыра, грубые куски звериных шкур, надетые как плащ, или верещащая, покрытая грязью свинка. Огры очень радуются блеску золота и серебра, и готовы подраться между собой за небольшую пригоршню монет. Сообразительные существа могут заслужить доверие огра, предложив ему золото или оружие, выкованное под существо его размера.`,
    source: {
      id: SOURCE_MM,
      page: 222,
    },
  },
  {
    header: 'Легендарный идиотизм',
    text: `Немногие огры способны досчитать до десяти даже по пальцам. Большинство говорит на зачаточной версии языка Великанов и знает несколько слов на Всеобщем. Огры верят тому, что им говорят, и их легко одурачить или запутать, но всё непонятное они просто разбивают. Сладкоголосые прохвосты, которые решают испытать свои таланты на одном из этих дикарей, часто бывают вынуждены проглотить свои лживые слова, а затем бывают проглочены и сами.`,
    source: {
      id: SOURCE_MM,
      page: 222,
    },
  },
  {
    header: 'Первобытные бродяги',
    text: `Огры одеваются в звериные шкуры, и вырывают деревья, чтобы использовать их как грубые инструменты или оружие. Для охоты они мастерят метательные копья с каменными наконечниками. Когда они делают себе логово, они выбирают для этого место на границах цивилизованных земель, пользуясь плохой защищённостью скота, неохраняемыми хранилищами еды, и неосторожностью фермеров.
  
Огры спят в пещерах, на лежбищах животных или просто под деревьями, пока не находят и не присваивают одинокую хижину или ферму, перебив тамошних обитателей. Если огру становится скучно или голодно, он покидает своё логово и нападает на всё, что попадётся на пути. Только после того, как в окрестностях не останется еды, огр переходит на другое место.`,
    source: {
      id: SOURCE_MM,
      page: 222,
    },
  },
  {
    header: 'Банды огров',
    text: `Огры иногда собираются в небольшие кочевые группы, но настоящего племени организовать не могут. Когда две такие банды встречаются, одна может попытаться захватить членов другой, чтобы увеличить свою численность. При этом обмен членами между бандами может происходить и мирно, особенно если у принимающей банды временно есть избыток еды и оружия.
  
  При любой возможности огры стараются объединяться с другими чудовищами, чтобы запугивать и охотиться на существ слабее, чем они сами. Они часто сотрудничают с [гоблиноидами](CREATURE:${CREATURE_GOBLIN}), [орками](CREATURE:${CREATURE_ORC}) и [троллями](CREATURE:${CREATURE_TROLL}), и практически боготворят великанов. В комплексной социальной структуре великанов (известной как «Уложение») огры по статусу находятся под самыми нижайшими великанами. В результате огры делают практически всё, что говорят им великаны.`,
    source: {
      id: SOURCE_MM,
      page: 222,
    },
  },
  {
    header: 'Огры',
    text: `Огры до безобразия глупы, но при достаточном количестве времени и терпения, некоторые из них могут быть натренированы на выполнение специальных задач в бою. Прозвища, которые они получают ([таран](CREATURE:${CREATURE_OGRE_BATTERING_RAM}), [болтомёт](CREATURE:${CREATURE_OGRE_BOLT_LAUNCHER}), [цепной громила](CREATURE:${CREATURE_OGRE_CHAIN_BRUTE}), [паланкин](CREATURE:${CREATURE_OGRE_HOWDAH})) отражают их определённые функции. Такие задачи достаточно просты, но они преимущественно рассчитаны на силы огров.`,
    source: {
      id: SOURCE_MTOF,
      page: 217,
    },
  },
]
