const {SOURCE_MM} = require('./../../sourceList')
const {
  SPELL_CONTROL_WEATHER,
  SPELL_FOG_CLOUD,
} = require('./../../spellIdList')

const metalDragonDescriptionList = require('./dragonMetallic')

module.exports = [
  {
    header: 'Логово серебряного дракона',
    text: `Серебряные драконы обитают среди облаков, создавая свои логовища среди укромных вершин холодных гор. Хотя многим из них комфортно в естественных пещерных комплексах или заброшенных шахтах, серебряные драконы всё же предпочитаю заброшенные форпосты гуманоидов. Каждый серебряный дракон мечтает о логове в заброшенной на вершине горы цитадели или далёкой башне, созданной давно погибшим волшебником.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов:

* Дракон создаёт туман, как будто наложив заклинание [туманное облако](SPELL:${SPELL_FOG_CLOUD}). Туман существует до показателя инициативы «20» в следующем раунде. 
* Обжигающе холодные потоки воздуха дуют через логово в непосредственной близости от дракона. Все существа в пределах 120 футов от дракона должны пройти испытание Телосложении СЛ 15, иначе получат урон холодом 5 (1к10). Газы и испарения разносятся ветром, незащищённое пламя тухнет. Защищённый огонь, например фонари, потухнут с 50 процентной вероятностью.

# Местные эффекты

Регион, в котором есть логово легендарного серебряного дракона, искажается драконьей магией, которая создаёт один или несколько следующих эффектов:

* Один раз в день дракон может изменить погоду в пределах 6 миль с центром на своём логове. Дракон не обязан быть на открытом воздухе; во всём остальном эффект идентичен заклинанию [власть над погодой](SPELL:${SPELL_CONTROL_WEATHER}). 
* В пределах 1 мили от логова ветра поддерживают незлых существ, которые упали не из-за действий дракона или его союзников. Такие существа спускаются на 60 футов каждый раунд и не получают урон от падения. 
* За несколько дней дракон может превратить облака или туман в своём логове в прочные как камень структуры и объекты, по своему желанию.

Если дракон умирает, изменённая погода возвращается в норму, согласно описанию заклинания, а прочие эффекты исчезают через 1к10 дней.`,
    source: {
      id: SOURCE_MM,
      page: 135,
    },
  },
  {
    header: 'Серебряные драконы',
    text: `Дружелюбные и наиболее социальные из всех металлических драконов, серебряные драконы с радостью помогают добрым существа, которые нуждаются в этом.

Серебряный дракон переливается, как будто отлит из чистого металла, высокие глаза и шипастая окладистая борода делает его лицо благородным. Колючий венец поднимается высоко над головой, уходя вниз по шее до самого кончика хвоста. Сине-серая чешуя ящера переливаются серебряными бликами. С годами его цвет постепенно светлеет, до едва различимого серебряного оттенка. У старейших драконов совсем исчезают зрачки, а глаза становятся похожими на шары ртути.

# Драконья добродетель

Серебряные драконы считают, что нравственная жизнь предполагает совершение добрых дел и непричинение незаслуженного вреда другим разумным созданиям. Они не берут на себя ответственность искоренить зло, как это делают золотые и бронзовые драконы, но они с удовольствием выступают против существ, которые осмеливаются совершать дурные поступки или вредить невинным.

# Друзья маленьких народов

Серебряные драконы любят компанию других серебряных драконов. Но помимо сородичей, настоящие дружеские отношения развиваются и с гуманоидами, и многие серебряные драконы пребывают в гуманоидном облике столько же, сколько и в драконьем. Серебряный дракон принимает человеческое обличье мудрого старца или молодого странника, и с ним часто ходят смертные товарищи, с которыми он развил крепкие дружеские отношения.

Серебряные драконы вынуждены регулярно отходить от жизни среди людей, возвращаясь к своему истинному облику для спаривания и создания потомства, или же при необходимости защиты сокровищ и по личным делам. Поскольку многие теряют счёт времени, находясь вдали, они, по возвращению, могу обнаружить, что их друзья постарели или умерли. В итоге серебряные драконы часто поддерживают нескольких поколений гуманоидов в пределах одной семьи.

# Уважение к человечеству

Серебряные драконы дружны с гуманоидами всех рас, но те, что живут недолго, такие как люди, более интересны для них, чем долгоживущие эльфы и дварфы. У людей есть стремления и жизнерадостность, что серебряные драконы находят увлекательным.`,
    source: {
      id: SOURCE_MM,
      page: 134,
    },
  },
  ...metalDragonDescriptionList,
]
