const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_AURA_OF_PURITY,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_AURA_OF_PURITY,
  name: 'Аура очищения',
  nameEn: `Aura of Purity`,
  description: `От Вас исходит очищающая аура с радиусом 30 футов. Пока заклинание активно, аура перемещается вместе с Вами, оставаясь с центром на Вас. Все невраждебные существа в ауре (включая Вас) не могут заболеть, имеют сопротивление к урону ядом и с преимуществом проходят испытания от эффектов, вызывающих следующие состояния: глухота, испуг, ослепление, отравление, очарование, ошеломление и паралич.`,
  lvl: 4,
  magicSchoolId: MAGIC_ABJURATION,
  range: -1,
  rangeComment: `30-футовый радиус`,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_MINUTE, count: 10 },
  needConcentration: true,
  source: {
    id: SOURCE_PHB,
    page: 212,
  },
}
