const {LANG_ITS_CREATOR} = require('./../../languageIdList')

module.exports = {
  id: LANG_ITS_CREATOR,
  name: {
    nominative: 'Языки своего создателя',
    genitive: 'Языков своего создателя',
    instrumental: 'Языками своего создателя',
    prepositional: 'Языках своего создателя',
  },
  isReady: false,
  isRealLang: false,
}
