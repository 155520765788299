const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  SOURCE_MM,
  SOURCE_ERRATA_MONSTER_MANUAL,
} = require('./../../../../sourceList')
const {
  CREATURE_REVENANT,
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')
const {ABILITY_TURN_IMMUNITY} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {GEAR_LEATHER_ARMOR} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_WISH} = require('./../../../../spellIdList')

module.exports = {
  name: 'Ревенант',
  nameEn: 'Revenant',
  id: CREATURE_REVENANT,
  description: [
    `Ревенант появляется из души смертного, встретившего жестокую и незаслуженную судьбу. Он прорывается обратно в мир для мести своему обидчику. Ревенант вселяется в своё смертное тело и внешне похож на [зомби](CREATURE:${CREATURE_ZOMBIE}), но вместо безжизненной блеклости глаза ревенанта пылают решимостью и разгораются в присутствии врага. Если тело ревенанта разрушено или недостижимо, он вселяется в другой труп гуманоида. Вне зависимости от используемого вместилища, враг всегда узнаёт истинную сущность ревенанта.

# Жажда мести

Для осуществления мести у ревенанта имеется только один год. Если же враг погиб, или ревенант не может исполнить месть до назначенного срока, его тело рассыпается в пыль, а душа уходит в посмертие. В случае, когда враг ревенанта слишком могущественен, чтобы справится с ним в одиночку, он ищет достойных союзников для исполнения мести.

# Божественное правосудие

Никакая магия не может укрыть цель преследования ревенанта; он всегда знает направление и расстояние между ним и объектом мести. В случае, когда целей несколько, он выслеживает их по одному за раз, начиная с существа, нанёсшего ему смертельный удар. Если тело ревенанта уничтожено, его душа летит далее в поиске нового трупа, в котором он возобновит свою охоту.

# Природа нежити

Ревенанту не нужен воздух, еда, питьё и сон.`,
    {
      header: `Вариант: Ревенант с заклинаниями и оружием`,
      text: `Ревенант, бывший при жизни заклинателем, может сохранить часть или все свои колдовские способности. Точно так же, ревенант, владевший оружием и носивший доспехи при жизни, сохраняет эти навыки.`,
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_N,
  source: [
    {
      id: SOURCE_MM,
      page: 249,
    },
    {
      id: SOURCE_ERRATA_MONSTER_MANUAL,
      page: 1,
    },
  ],
  armor: {
    ac: 13,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_PSYCHIC,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_STUNNED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_5,
  featureList: [
    {
      name: `Регенерация`,
      description: `★СУЩЕСТВО★ восстанавливает 10 хитов в начале своего хода. Если ★СУЩЕСТВО★ получает урон излучением или огнём, эта особенность не действует в начале ★его★ следующего хода. ★Его★ тело уничтожается только если он начинает ход с 0 хитов и не может регенерировать.`,
    },
    {
      name: `Восстановление`,
      description: `Если тело ★СУЩЕСТВО★ уничтожается, ★его★ душа остаётся. Через 24 часа душа вселяется в другой труп гуманоида на том же плане существования и оживляет его, восстанавливая все хиты. Пока душа находится без тела, заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) может отправить душу в посмертие, не позволяя ей вернуться.`,
    },
    ABILITY_TURN_IMMUNITY,
    {
      name: `Мстительный следопыт`,
      description: `★СУЩЕСТВО★ знает направление и расстояние до любого существа, которому ★он★ хочет отомстить, даже если они находятся на разных планах существования. Если существо, которое ★выслеживал★ ★СУЩЕСТВО★, умирает, ★СУЩЕСТВО★ узнаёт об этом.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Кулаком_.`,
    },
    {
      name: `Кулак`,
      description: `Если цель — существо, которому ★СУЩЕСТВО★ ★поклялся★ отомстить, цель получает от атаки дополнительный дробящий урон 14 (4к6). Вместо причинения урона ревенант может схватить цель (СЛ высвобождения 14), если размер цели не больше Большого.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Мстительный взор`,
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 30 футов, которому ★он★ ★поклялся★ отомстить. Цель должна пройти испытание Мудрости СЛ 15. При провале цель становится парализованной, пока ★СУЩЕСТВО★ не причинит ей урон, или до конца следующего хода ★СУЩЕСТВО★. Когда паралич оканчивается, цель становится испуганной ★СУЩЕСТВО★ на 1 минуту. Испуганная цель может повторять это испытание в конце каждого своего хода, с помехой, если видит ★СУЩЕСТВО★, оканчивая испуг на себе при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
