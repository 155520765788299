const {
  CREATURE_SWARM_OF_BUGS,
  CREATURE_SWARM_OF_CENTIPEDES,
  CREATURE_SWARM_OF_SPIDERS,
  CREATURE_SWARM_OF_WASPS,
} = require('./../../creatureIdList')
const {
  SOURCE_MM,
} = require('./../../sourceList')

module.exports = {
  header: 'Вариант: Рои насекомых',
  text: `В рой могут собираться самые разные насекомые, и у каждого такого роя будут свои особые характеристики.

1. **[Рой жуков](CREATURE:${CREATURE_SWARM_OF_BUGS}).** Рой жуков получает скорость копания 5 футов.

2. **[Рой многоножек](CREATURE:${CREATURE_SWARM_OF_CENTIPEDES}).** Существо, хиты которого опускаются до 0 роем многоножек, остаётся стабилизированным, но отравленным на 1 час, даже если восстановит хиты, и пока оно отравлено из-за этого, оно ещё и парализовано.

3. **[Рой ос](CREATURE:${CREATURE_SWARM_OF_WASPS}).** Рой ос обладает скоростью ходьбы 5 футов, скоростью полёта 30 футов и не обладает скоростью лазания.

4. **[Рой пауков](CREATURE:${CREATURE_SWARM_OF_SPIDERS}).** Рой пауков обладает следующими особенностями:

**Паучье лазание.** Этот рой может лазать по сложным поверхностям, в том числе и вниз головой, по потолку, не совершая никаких проверок характеристик.

**Чувство паутины.** Находясь в контакте с паутиной, этот рой знает точное местоположение всех других существ, находящихся в контакте с той же паутиной.

**Хождение по паутине.** Этот рой игнорирует ограничения перемещения, вызванные паутиной.`,
  source: {
    id: SOURCE_MM,
    page: 340,
  },
}
