const {
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_HEAVY_ARMOR,
} = require('./../../../../../gearCategoryList')

module.exports = [
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_HEAVY_ARMOR,
]
