const {ABILITY_UNYIELDING} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {blackEarthDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  GEAR_PLATE_ARMOR,
  GEAR_MORNINGSTAR,
} = require('./../../../../gearIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BLACK_EARTH_GUARD,
  CREATURE_BLACK_EARTH_PRIEST,
  CREATURE_BURROWSHARK,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Страж Чёрной Земли',
  nameEn: 'Black Earth Guard',
  id: CREATURE_BLACK_EARTH_GUARD,
  description: [
    {
      header: 'Стражи Чёрной Земли',
      text: `Стражи Чёрной Земли выступают в качестве основной ударной силы культа. Это свирепые, тяжеловооружённые головорезы, слепо повинующиеся Огремоху. Их верность даровала им сверхъестественную способность не склоняться ни под какими ударами. Также они получили право носить специальные доспехи из стихийного камня, которые ломаются при смерти стража.

Стражи фанатично преданы [жрецам Чёрной Земли](CREATURE:${CREATURE_BLACK_EARTH_PRIEST}) и лидерам культа. Они будут сражаться до смерти, если им прикажут, а самые перспективные из них становятся [подземными акулами](CREATURE:${CREATURE_BURROWSHARK}).`,
      source: {
        id: SOURCE_PotA,
        page: 202,
      },
    },
    blackEarthDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 202,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_2,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_MORNINGSTAR,
    },
  ],
  reactionList: [
    ABILITY_UNYIELDING,
  ],
}
