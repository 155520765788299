const arrify = require('arrify')

const { sourceCollection } = require('./../constants/sourceList')

const checkIsFromUA = (source) => arrify(source).every(
  item => {
    const sourceInfo = sourceCollection[item.id || item]

    return sourceInfo && sourceInfo.isUA
  }
)

module.exports = checkIsFromUA
