const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_SPIDER_CLIMB,
  ABILITY_WEB_SENSE,
  ABILITY_WEB_WALKER,
} = require('./../../../../creatureAbilityList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  SIZE_MEDIUM,
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_ETTERCAP} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Эттеркап',
  nameEn: 'Ettercap',
  id: CREATURE_ETTERCAP,
  description: [
    `Эттеркапы похожи на человекоподобных пауков, которые, как правило, ухаживают за другими пауками, как пастух следит за стадом овец. Логова их расположены в глубинах тёмных лесов. Тонкие шёлковые нити, струящиеся из желёз в брюшной полости эттеркапа, позволяют ему ловить, обездвиживать и душить жертв. Также паутину используют для создания сетей и сложной системы ловушек, которые часто украшают их логова.`,
    {
      header: 'Тихие убийцы',
      text: `Когда путешественники и исследователи отваживаются зайти на территорию эттеркапа, тот начинает красться за ними. Некоторые встречают свой конец, слепо забредая в ловушки или участки леса, окутанные паутиной. Других эттеркапы душат нитями паутины или отравляют укусом.`,
      source: {
        id: SOURCE_MM,
        page: 310,
      },
    },
    {
      header: 'Лесные грабители',
      text: `Хоть эттеркапы и обитают в дикой местности, они не желают жить в гармонии с природой. Лес, кишащий эттеркапами, превращается в мрачное место, задушенное паутиной и кишащее гигантскими пауками, насекомыми и другими зловещими хищниками. Существа, которые забредают слишком далеко в лес, вскоре теряются в лабиринтах из паутины, на которой висят кости и потерянные сокровища жертв эттеркапов.`,
      source: {
        id: SOURCE_MM,
        page: 310,
      },
    },
    {
      header: 'Враждебность к феям',
      text: `Эттеркапы — природные враги фейских созданий. Мерзкие твари расставляют сети, чтобы ловить спрайтов и пикси, которых они пожирают, и опутывают нитями деревья дриад в тщетных попытках поймать одну из них. Иногда запуганные феи прибегают к помощи посторонних для борьбы с нашествием эттеркапов, так как сами они не способны бороться со злобными тварями.`,
      source: {
        id: SOURCE_MM,
        page: 310,
      },
    },
  ],
  note: {
    text: 'Грязные, липкие, мерзкие, ядовитые — а я ещё даже не начала описывать их паутину',
    author: 'Эринис, сумрачная охотница',
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 310,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 13,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 60,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_SPIDER_CLIMB,
    ABILITY_WEB_SENSE,
    ABILITY_WEB_WALKER,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Когтями_.`,
    },
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения СЛ 11, иначе станет отравленной на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 2,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 1,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Паутина',
      restore: 5,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 4,
        range: {
          normal: 30,
          max: 60,
        },
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            size: {
              max: SIZE_LARGE,
            },
          },
        },
        hit: `Существо обездвиживается паутиной. Обездвиженое существо может действием совершить проверку Силы СЛ 11, высвобождаясь из паутины при успехе. Эффект оканчивается, если паутина будет уничтожена. У паутины КД 10, 5 хитов, уязвимость к урону огнём, иммунитет к дробящему урону, урону психической энергией и ядом.`,
      },
    },
  ],
  genderId: GENDER_MALE,
}
