const {CREATURE_TYPE_DRAGON_GEM} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_DRAGON_GEM,
  nameEn: 'Gem Dragon',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'самоцветный дракон',
        genitive: 'самоцветного дракона',
        dative: 'самоцветному дракону',
        accusative: 'самоцветного дракона',
        instrumental: 'самоцветным драконом',
        prepositional: 'самоцветном драконе',
      },
      [GENDER_FEMALE]: {
        nominative: 'самоцветная драконица',
        genitive: 'самоцветной драконицы',
        dative: 'самоцветной драконице',
        accusative: 'самоцветную драконицу',
        instrumental: 'самоцветной драконицей',
        prepositional: 'самоцветной драконице',
      },
    },
    plural: {
      nominative: 'самоцветные драконы',
      genitive: 'самоцветных драконов',
      dative: 'самоцветным драконам',
      accusative: 'самоцветных драконов',
      instrumental: 'самоцветными драконами',
      prepositional: 'самоцветных драконах',
    },
  },
}
