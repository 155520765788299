const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_BEAST} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_BEAST,
  nameEn: 'Beast',
  genderId: GENDER_MALE,
  description: {
    text: `Звери — это негуманоидные существа, являющиеся фауной мира фэнтези. Некоторые из них имеют магические силы, но большая часть неразумна, не владеет языками и не имеет никакого общественного строя. Звери включают в себя всё многообразие животных, динозавров и гигантских версий животных.`,
    source: {
      id: SOURCE_MM,
      page: 8,
    },
  },
  name: {
    singular: {
      nominative: 'зверь',
      genitive: 'зверя',
      dative: 'зверю',
      accusative: 'зверя',
      instrumental: 'зверем',
      prepositional: 'звере',
    },
    plural: {
      nominative: 'звери',
      genitive: 'зверей',
      dative: 'зверям',
      accusative: 'зверей',
      instrumental: 'зверями',
      prepositional: 'зверях',
    },
  },
}
