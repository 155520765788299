const {SOURCE_ROT} = require('./../../sourceList')
const {GOD_TIAMAT} = require('./../../godIdList')
const {
  CREATURE_DRAGON_CULT_FANATIC,
  CREATURE_DRAGON_CULT_INITIATE,
  CREATURE_DRAGONCLAW,
  CREATURE_DRAGONFANG_WHITE,
  CREATURE_DRAGONSOUL_WHITE,
  CREATURE_DRAGONWING_WHITE,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Культ дракона',
    text: `Культ дракона существовал на протяжении веков. Его члены сосредоточены на создании и поклонении драколичам, полагаясь на пророчество, переведённое основателем культа Саммастером.`,
    source: {
      id: SOURCE_ROT,
      page: 9,
    },
  },
  {
    header: 'Структура культа',
    text: `В культе сложилась простая иерархическая структура, состоящая из новообращённых, культистов четырёх рангов и высочайшего ранга _Носящих пурпур_ — лордов, лидеров и владык культа в конкретных регионах. Культ управляется внутренней секретной группой Говорящих с драконами — так уважительно называют тех, чья связь с драконами сверхъестественно сильна.

Ранг в культе зависит от заслуг и фанатизма. Как новобранцы, новообращённые (представленные [культистами](CREATURE:${CREATURE_DRAGON_CULT_INITIATE}) и [фанатиками культа](CREATURE:${CREATURE_DRAGON_CULT_FANATIC})) не имеют ранга и выполняют приказы начальства. Только те новообращённые, которые доказали, что они достойны этого, могут получить повышение. Носящий пурпур может повысить новообращённого до [Драконьего когтя](CREATURE:${CREATURE_DRAGONCLAW}) (первый ранг), [Драконьего крыла](CREATURE:${CREATURE_DRAGONWING_WHITE}) (второй ранг), [Драконьего клыка](CREATURE:${CREATURE_DRAGONFANG_WHITE}) (третий ранг) и [Души дракона](CREATURE:${CREATURE_DRAGONSOUL_WHITE}) (четвёртый ранг).

Культ разделен на оперативные группировки, соответствующие возлагаемой на них задаче. Они разнообразны по размеру, от кучки низкоранговых культистов, действующих самостоятельно, до сотен культистов разных рангов под контролем нескольких Носящих пурпур`,
    source: {
      id: SOURCE_ROT,
      page: 10,
    },
  },
  {
    header: 'Повседневная деятельность',
    text: `Планы Культа Дракона хранятся в секрете, в отличие от факта его существования. Большинство людей на Побережье Мечей слышали о культе, но в основном только то, что его члены почитают злых драконов. Культ набирает последователей в больших городах и это быстро получает огласку. Более того, многие культисты занимаются легальным бизнесом или обычными академическими исследованиями. Только относительно небольшое их число являются ворами, убийцами и шпионами.

Символизм и иконография базирующиеся на изображении драконов пронизывает официальные регалии культа — чёрная одежда, в основном это чёрный плащ или накидка, скроенная так, чтобы она напоминала драконьи крылья и украшения с драгоценностями и драконьими образами, указывающими на любимый тип дракона носящего. На собраниях культа распространено ношение масок.

Культисты не носят эти регалии в общественных местах, но персонаж, который знает на что смотреть, зачастую может выявить драконьего культиста, скрывающегося в толпе. Культисты иногда приветствуют друг друга знаком Тиамат: вытянутой правой рукой, с вытянутой вперёд ладонью, с пятью пальцами растопыренными таким образом, что они напоминают 5 голов Тиамат.

Члены культа почитают драконов в качестве мощных образов и повелителей, однако главный объект их почитания — [Тиамат](GOD:${GOD_TIAMAT}). Они ожидают возвращения Королевы Драконов, веря, что она уничтожит цивилизацию смертных и приведёт к власти драконов, а с ними и тех, кто помогал ей.`,
    source: {
      id: SOURCE_ROT,
      page: 8,
    },
  },
]
