const {
    SOURCE_AI,
  } = require('./../../../../sourceList'),
  {
    CR_9,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    LANG_DEEP_CROW,
  } = require('./../../../../languageIdList'),
  {
    SENSE_BLIND_VISION,
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SHADOW_STEALTH,
    ABILITY_SUNLIGHT_SENSITIVITY,
  } = require('./../../../../creatureAbilityList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    deepCrowDescriptionList,
    deepCrowNote,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_DEEP_CROW,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Глубинная ворона',
  nameEn: 'Deep Crow',
  id: CREATURE_DEEP_CROW,
  description: deepCrowDescriptionList,
  note: deepCrowNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_AI,
    page: 211,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 80,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 18,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_CROW,
  ],
  cr: CR_9,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SHADOW_STEALTH,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну — _Жвалами_ и две — _Когтями_.`,
    },
    {
      name: 'Жвалы',
      description: `Цель схвачена (СЛ побега 17). Пока цель схвачена, она обездвижена и ★СУЩЕСТВО★ не может использовать _Жвалы_ против другой цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
  ],
}
