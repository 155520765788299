const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')

module.exports = [
  {
    header: 'Микониды',
    text: `Микониды — это разумные, прямоходящие грибы, живущие в Подземье, ищущие просветления и порицающие насилие. Если обратиться к ним с миром, микониды с радостью предоставят укрытие и позволят безопасно пройти сквозь свою колонию.`,
    source: {
      id: SOURCE_MM,
      page: 207,
    },
  },
  {
    header: 'Миконид',
    text: `Разумные грибы. Умеют двигаться, обитают в Подземье. Общаются и нападают с помощью спор, хоть и не слишком агрессивны.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Круги и слияния',
    text: `Самый крупный миконид в колонии является предводителем, возглавляющим одну или несколько социальных групп, называемых кругами. Круг состоит из двадцати и более миконидов, которые работают, живут, и сливаются вместе.

Слияние — это форма коллективной медитации, которая позволяет миконидам преодолевать унылое подземное существование. Споры взаимопонимания миконидов объединяют участников в групповое сознание. Затем галлюциногенные споры вызывают общие грёзы, обеспечивающие развлечение и социальное взаимодействие.

Микониды считают слияние целью своего существования. Они используют его в погоне за высшим сознанием, коллективным единением и духовным апофеозом. Микониды также используют свои споры взаимопонимания, чтобы телепатически общаться с другими разумными существами.`,
    source: {
      id: SOURCE_MM,
      page: 207,
    },
  },
  {
    header: 'Размножение миконидов',
    text: `Как и другие грибы, микониды размножаются спорами. Они тщательно контролируют выпуск своих спор, чтобы избежать перенаселения.`,
    source: {
      id: SOURCE_MM,
      page: 207,
    },
  },
]
