const {PC_SUBCLASS_FIGHTER_BRUTE_2018_01_08} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2018_01_08_3_SUBCLASSES_0108} = require('./../../../sourceList')
const {
  FIGHTING_STYLE_ARCHERY,
  FIGHTING_STYLE_BLIND_FIGHTING,
  FIGHTING_STYLE_DEFENSE,
  FIGHTING_STYLE_DUELING,
  FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
  FIGHTING_STYLE_INTERCEPTION,
  FIGHTING_STYLE_PROTECTION,
  FIGHTING_STYLE_SUPERIOR_TECHNIQUE,
  FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
  FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
  FIGHTING_STYLE_UNARMED_FIGHTING,
} = require('./../../../fightingStyleIdList')
const {
  FEATURE_ADDITIONAL_FIGHTING_STYLE_2018_01_08,
  FEATURE_BRUTE_FORCE_2018_01_08,
  FEATURE_BRUTISH_DURABILITY_2018_01_08,
  FEATURE_DEVASTATING_CRITICAL_2018_01_08,
  FEATURE_SURVIVOR_2018_01_08,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BRUTE_FORCE_2018_01_08,
    name: `Грубая сила`,
    nameEn: `Brute Force`,
    lvl: 3,
    text: `Вы способны наносить исключительно мощные удары оружием.

Когда Вы попадаете и наносите урон оружием, которым владеете, наносимый оружием урон увеличивается, согласно таблице.

| Уровень воина | Дополнительный урон |
|---------------|---------------------|
|  3            |                1к4  |
| 10            |                1к6  |
| 16            |                1к8  |
| 20            |                1к10 |
`,
  },
  {
    id: FEATURE_BRUTISH_DURABILITY_2018_01_08,
    name: `Грубая стойкость`,
    nameEn: `Brutish Durability`,
    lvl: 7,
    text: `Ваша стойкость позволяет Вам не бояться нападений, которые могли бы сокрушить других.

Каждый раз, когда Вы проходите испытание, добавьте 1к6 к его результату.

Если применение этого бонуса к испытанию от смерти увеличивает его результат до 20 или выше, Вы получаете выгоду от выпадения 20 на к20.`,
  },
  {
    id: FEATURE_ADDITIONAL_FIGHTING_STYLE_2018_01_08,
    name: `Дополнительный боевой стиль`,
    nameEn: `Additional Fighting Style`,
    lvl: 10,
    text: `Вы можете выбрать второй боевой стиль.`,
    fightingStyleIdList: [
      FIGHTING_STYLE_ARCHERY,
      FIGHTING_STYLE_BLIND_FIGHTING,
      FIGHTING_STYLE_DEFENSE,
      FIGHTING_STYLE_DUELING,
      FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
      FIGHTING_STYLE_INTERCEPTION,
      FIGHTING_STYLE_PROTECTION,
      FIGHTING_STYLE_SUPERIOR_TECHNIQUE,
      FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
      FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
      FIGHTING_STYLE_UNARMED_FIGHTING,
    ],
  },
  {
    id: FEATURE_DEVASTATING_CRITICAL_2018_01_08,
    name: `Разрушительный критический удар`,
    nameEn: `Devastating Critical`,
    lvl: 15,
    text: `Если Вы наносите критический удар атакой оружием, то получаете бонус к урону, равный Вашему уровню в этом классе.`,
  },
  {
    id: FEATURE_SURVIVOR_2018_01_08,
    name: `Уцелевший`,
    nameEn: `Survivor`,
    lvl: 18,
    text: `Вы достигли вершины стойкости в бою.

В начале каждого Вашего хода в битве Вы восстанавливаете количество хитов, равное 5 + модификатор Телосложения (минимум 1). Вы не получите этого преимущества, если у вас 0 хитов или если у Вас осталось больше половины хитов.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_BRUTE_2018_01_08,
    source: {
      id: SOURCE_UA_2018_01_08_3_SUBCLASSES_0108,
      page: 2,
    },
  })
)

