const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_DROW} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_DROW,
  nameEn: 'Drow',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'дроу',
      genitive: 'дроу',
      dative: 'дроу',
      accusative: 'дроу',
      instrumental: 'дроу',
      prepositional: 'дроу',
    },
    plural: {
      nominative: 'дроу',
      genitive: 'дроу',
      dative: 'дроу',
      accusative: 'дроу',
      instrumental: 'дроу',
      prepositional: 'дроу',
    },
  },
}
