const {
  PC_CLASS_CLERIC,
  PC_CLASS_PALADIN,
} = require('./../../../../../pcClassIdList')
const {GEAR_BOOK} = require('./../../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_BOOK_OF_EXALTED_DEEDS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_ARTIFACT} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_BOOK_OF_EXALTED_DEEDS,
  name: `Книга восторженных деяний`,
  nameEn: `Book of Exalted Deeds`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_BOOK,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  attunementComment: `существом с добрым мировоззрением`,
  description: [
    `Прославленная _Книга Восторженных Деяний_, трактат о добре в мультивселенной, занимает важное место во многих религиях. Это не просто письмена о конкретной вере, разнообразные авторы заполняли её страницы своим пониманием истинной добродетели и давали советы по борьбе со злом.

_Книга Восторженных Деяний_ редко надолго задерживается в одном месте. Когда её прочтут, она исчезает, чтобы появиться в другом уголке мультивселенной и нести там, в тёмный мир свои моральные нравоучения. Были попытки скопировать этот том, но они не увенчались успехом, так как не удаётся передать его магическую природу и его благословение целеустремлённым и чистым сердцем.

Книга запирается тяжёлой застёжкой в форме ангельских крыльев. Только существо с добрым мировоззрением, настроенное на эту книгу, может расстегнуть застёжку. Открыв книгу, настроенное существо должно потратить 80 часов на её чтение и изучение, чтобы получить преимущества от неё. Другие существа, смотрящие на открытые страницы, видят текст, но не понимают значения слов и не получают преимуществ. Злое существо, пытающееся читать эту книгу, получает урон излучением 24к6. Этот урон игнорирует сопротивления и иммунитеты, и его никак нельзя ни уменьшить, ни избежать. Существо, чьи хиты уменьшаются от этого урона до 0, исчезает в ослепительной вспышке, уничтоженное, но оставляет всё своё снаряжение.

Преимущества, даруемые _Книгой Восторженных Деяний_, длятся только пока Вы стремитесь к добру. Если Вы в течение 10 дней не совершаете ни одного доброго или благородного поступка, Вы теряете все преимущества, даруемые книгой.`,
    {
      header: 'Случайные свойства',
      text: `_Книга Восторженных Деяний_ обладает несколькими свойствами, определяемыми случайным образом:

* 2 малых положительных свойства
* 2 основных положительных свойства`,
    },
    {
      header: 'Увеличенная Мудрость',
      text: `После того как Вы потратите нужное время на чтение и изучение книги, ваша Мудрость увеличивается на 2, с максимумом 24. Вы не можете получить это преимущество из книги более одного раза.`,
    },
    {
      header: 'Просветлённая магия',
      text: `После того как Вы изучите книгу, все ячейки заклинаний, которые Вы тратите для накладывания заклинаний [жреца](PC_CLASS:${PC_CLASS_CLERIC}) или [паладина](PC_CLASS:${PC_CLASS_PALADIN}), считаются ячейками с уровнем на 1 выше.`,
    },
    {
      header: 'Ореол',
      text: `Изучив книгу, Вы начинаете излучать защитный ореол. Он испускает яркий свет в пределах 10 футов и тусклый свет в пределах ещё 10 футов. Вы можете развеять или вернуть ореол бонусным действием. Пока ореол существует, Вы совершаете с преимуществом проверки Харизмы (Убеждение), совершённые для взаимодействия с добрыми существами и проверки Харизмы (Запугивание), совершённые для взаимодействия со злыми существами. Кроме того, исчадия и нежить, находящиеся в пределах яркого света ореола, совершают по Вам броски атаки с помехой.`,
    },
    {
      header: 'Уничтожение Книги',
      text: `Ходят слухи, что _Книгу Восторженных Деяний_ нельзя уничтожить, пока в мультивселенной есть хоть частичка добра. Однако если её бросить в реку Стикс, её письмена и иллюстрации сотрутся, и на 1к100 лет книга станет бесполезной.`,
    },
  ],
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 223,
  },
}
