const {SOURCE_XGTE} = require('./../../../sourceList')
const {PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ANCESTRAL_GUARDIAN} = require('./../../../pcSubClassIdList')
const {
  SPELL_AUGURY,
  SPELL_CLAIRVOYANCE,
} = require('./../../../spellIdList')
const {
  FEATURE_ANCESTRAL_PROTECTORS,
  FEATURE_RAGE,
  FEATURE_CONSULT_THE_SPIRITS,
  FEATURE_SPIRIT_SHIELD,
  FEATURE_VENGEFUL_ANCESTORS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ANCESTRAL_PROTECTORS,
    name: `Древние защитники`,
    nameEn: `Ancestral Protectors`,
    lvl: 3,
    text: `Когда Вы впадаете в [Ярость](FEATURE:${FEATURE_RAGE}), появляются призрачные воины.

Пока Вы в [Ярости](FEATURE:${FEATURE_RAGE}), первое существо, по которому Вы попадаете атакой в свой ход, становится целью воинов, которые мешают его атакам.

До начала Вашего следующего хода цель получает помеху на все атаки, которые не направлены на Вас. А когда цель попадает по существу, отличному от Вас атакой, у того существа появляется сопротивление урону, наносимому атакой.

Эффект на цели заканчивается раньше, если заканчивается Ваша [Ярость](FEATURE:${FEATURE_RAGE}).`,
    source: {
      id: SOURCE_XGTE,
      page: 17,
    },
  },
  {
    id: FEATURE_SPIRIT_SHIELD,
    name: `Щит предков`,
    nameEn: `Spirit Shield`,
    lvl: [6, 10, 14],
    text: `Духи-защитники, которые Вам помогают, могут дать сверхъестественную защиту тем, кого Вы защищаете.

Если Вы в [Ярости](FEATURE:${FEATURE_RAGE}) и другое видимое Вами в пределах 30 футов существо получает урон, Вы можете реакцией уменьшить этот урон на 2к6.

При достижении определённых уровней в этом классе, Вы можете уменьшать урон сильнее: на 3к6 на 10 уровне и на 4к6 на 14 уровне.`,
    source: {
      id: SOURCE_XGTE,
      page: 17,
    },
  },
  {
    id: FEATURE_CONSULT_THE_SPIRITS,
    name: `Совет предков`,
    nameEn: `Consult the Spirits`,
    lvl: 10,
    text: `Вы получаете способность советоваться с духами предков. Когда Вы это делаете, Вы сотворяете заклинание [Гадание](SPELL:${SPELL_AUGURY}) или [Подсматривание](SPELL:${SPELL_CLAIRVOYANCE}) без использования ячейки заклинаний или материальных компонентов.

Вместо создания сферического сенсора, это использование [Подсматривания](SPELL:${SPELL_CLAIRVOYANCE}) невидимо призывает в выбранное место одного из духов Ваших предков. Мудрость — заклинательная способность для этих заклинаний.

Сотворив таким образом любое из этих заклинаний, Вы не можете снова использовать эту способность пока не завершите короткий или длинный отдых.`,
    spellIdList: [
      SPELL_AUGURY,
      SPELL_CLAIRVOYANCE,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 18,
    },
  },
  {
    id: FEATURE_VENGEFUL_ANCESTORS,
    name: `Мстительные предки`,
    nameEn: `Vengeful Ancestors`,
    lvl: 14,
    text: `Духи Ваших предков становятся достаточно могущественными, чтобы давать отпор.

Когда Вы используете [Щит предков](FEATURE:${FEATURE_SPIRIT_SHIELD}), чтобы уменьшить урон от атаки, атакующий получает урон силовым полем в количестве, равном тому урону, который отразил [Щит предков](FEATURE:${FEATURE_SPIRIT_SHIELD}).`,
    source: {
      id: SOURCE_XGTE,
      page: 17,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ANCESTRAL_GUARDIAN,
  })
)
