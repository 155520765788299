module.exports.MAGIC_ITEM_ABSORBING_TATTOO = 'absorbing_tattoo'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_BLACK = 'absorbing_tattoo_black'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_BLUE = 'absorbing_tattoo_blue'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_GOLD = 'absorbing_tattoo_gold'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_GREEN = 'absorbing_tattoo_green'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_ORANGE = 'absorbing_tattoo_orange'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_RED = 'absorbing_tattoo_red'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_SILVER = 'absorbing_tattoo_silver'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_VIOLET = 'absorbing_tattoo_violet'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_WHITE = 'absorbing_tattoo_white'
module.exports.MAGIC_ITEM_ABSORBING_TATTOO_YELLOW = 'absorbing_tattoo_yellow'
module.exports.MAGIC_ITEM_ADAMANTINE_ARMOR = 'adamantine_armor'
module.exports.MAGIC_ITEM_AKMON_HAMMER_OF_PURPHOROS = 'akmon_hammer_of_purphoros'
module.exports.MAGIC_ITEM_ALCHEMICAL_COMPENDIUM = 'alchemical_compendium'
module.exports.MAGIC_ITEM_ALCHEMY_JUG = 'alchemy_jug'
module.exports.MAGIC_ITEM_ALL_PURPOSE_TOOL = 'all_purpose_tool'
module.exports.MAGIC_ITEM_ALL_PURPOSE_TOOL_PLUS_1 = 'all_purpose_tool_plus_1'
module.exports.MAGIC_ITEM_ALL_PURPOSE_TOOL_PLUS_2 = 'all_purpose_tool_plus_2'
module.exports.MAGIC_ITEM_ALL_PURPOSE_TOOL_PLUS_3 = 'all_purpose_tool_plus_3'
module.exports.MAGIC_ITEM_AMMUNITION_PLUS_1 = 'ammunition_plus_1'
module.exports.MAGIC_ITEM_AMMUNITION_PLUS_1_PLUS_2_OR_PLUS_3 = 'ammunition_plus_1_plus_2_or_plus_3'
module.exports.MAGIC_ITEM_AMMUNITION_PLUS_2 = 'ammunition_plus_2'
module.exports.MAGIC_ITEM_AMMUNITION_PLUS_3 = 'ammunition_plus_3'
module.exports.MAGIC_ITEM_AMULET_OF_HEALTH = 'amulet_of_health'
module.exports.MAGIC_ITEM_AMULET_OF_PROOF_AGAINST_DETECTION_AND_LOCATION = 'amulet_of_proof_against_detection_and_location'
module.exports.MAGIC_ITEM_AMULET_OF_THE_DEVOUT = 'amulet_of_the_devout'
module.exports.MAGIC_ITEM_AMULET_OF_THE_DEVOUT_PLUS_1 = 'amulet_of_the_devout_plus_1'
module.exports.MAGIC_ITEM_AMULET_OF_THE_DEVOUT_PLUS_2 = 'amulet_of_the_devout_plus_2'
module.exports.MAGIC_ITEM_AMULET_OF_THE_DEVOUT_PLUS_3 = 'amulet_of_the_devout_plus_3'
module.exports.MAGIC_ITEM_AMULET_OF_THE_PLANES = 'amulet_of_the_planes'
module.exports.MAGIC_ITEM_ANIMATED_SHIELD = 'animated_shield'
module.exports.MAGIC_ITEM_ANSTRUTH_HARP = 'anstruth_harp'
module.exports.MAGIC_ITEM_APPARATUS_OF_KWALISH = 'apparatus_of_kwalish'
module.exports.MAGIC_ITEM_ARCANE_GRIMOIRE = 'arcane_grimoire'
module.exports.MAGIC_ITEM_ARCANE_GRIMOIRE_PLUS_1 = 'arcane_grimoire_plus_1'
module.exports.MAGIC_ITEM_ARCANE_GRIMOIRE_PLUS_2 = 'arcane_grimoire_plus_2'
module.exports.MAGIC_ITEM_ARCANE_GRIMOIRE_PLUS_3 = 'arcane_grimoire_plus_3'
module.exports.MAGIC_ITEM_ARCANE_PROPULSION_ARM = 'arcane_propulsion_arm'
module.exports.MAGIC_ITEM_ARMOR_OF_GLEAMING = 'armor_of_gleaming'
module.exports.MAGIC_ITEM_ARMOR_OF_INVULNERABILITY = 'armor_of_invulnerability'
module.exports.MAGIC_ITEM_ARMOR_OF_RESISTANCE = 'armor_of_resistance'
module.exports.MAGIC_ITEM_ARMOR_OF_VULNERABILITY = 'armor_of_vulnerability'
module.exports.MAGIC_ITEM_ARMOR_PLUS_1_2_OR_3 = 'armor_plus_1_2_or_3'
module.exports.MAGIC_ITEM_ARM_BLADE = 'arm_blade'
module.exports.MAGIC_ITEM_ARROW_CATCHING_SHIELD = 'arrow_catching_shield'
module.exports.MAGIC_ITEM_ARROW_OF_SLAYING = 'arrow_of_slaying'
module.exports.MAGIC_ITEM_ASTRAL_SHARD = 'astral_shard'
module.exports.MAGIC_ITEM_ASTROMANCY_ARCHIVE = 'astromancy_archive'
module.exports.MAGIC_ITEM_ATLAS_OF_ENDLESS_HORIZONS = 'atlas_of_endless_horizons'
module.exports.MAGIC_ITEM_AXE_OF_THE_DWARVISH_LORDS = 'axe_of_the_dwarvish_lords'
module.exports.MAGIC_ITEM_BABA_YAGA_S_MORTAR_AND_PESTLE = 'baba_yaga_s_mortar_and_pestle'
module.exports.MAGIC_ITEM_BAG_OF_BEANS = 'bag_of_beans'
module.exports.MAGIC_ITEM_BAG_OF_DEVOURING = 'bag_of_devouring'
module.exports.MAGIC_ITEM_BAG_OF_HOLDING = 'bag_of_holding'
module.exports.MAGIC_ITEM_BAG_OF_TRICKS = 'bag_of_tricks'
module.exports.MAGIC_ITEM_BAG_OF_TRICKS_GRAY = 'bag_of_tricks_gray'
module.exports.MAGIC_ITEM_BAG_OF_TRICKS_RUST = 'bag_of_tricks_rust'
module.exports.MAGIC_ITEM_BAG_OF_TRICKS_TAN = 'bag_of_tricks_tan'
module.exports.MAGIC_ITEM_BALLOON_PACK = 'balloon_pack'
module.exports.MAGIC_ITEM_BAND_OF_LOYALTY = 'band_of_loyalty'
module.exports.MAGIC_ITEM_BARRIER_TATTOO = 'barrier_tattoo'
module.exports.MAGIC_ITEM_BARRIER_TATTOO_12_PLUS = 'barrier_tattoo_12_plus'
module.exports.MAGIC_ITEM_BARRIER_TATTOO_15_PLUS = 'barrier_tattoo_15_plus'
module.exports.MAGIC_ITEM_BARRIER_TATTOO_18 = 'barrier_tattoo_18'
module.exports.MAGIC_ITEM_BEAD_OF_FORCE = 'bead_of_force'
module.exports.MAGIC_ITEM_BEAD_OF_NOURISHMENT = 'bead_of_nourishment'
module.exports.MAGIC_ITEM_BEAD_OF_REFRESHMENT = 'bead_of_refreshment'
module.exports.MAGIC_ITEM_BELASHYRRA_S_BEHOLDER_CROWN = 'belashyrra_s_beholder_crown'
module.exports.MAGIC_ITEM_BELL_BRANCH = 'bell_branch'
module.exports.MAGIC_ITEM_BELT_OF_DWARVENKIND = 'belt_of_dwarvenkind'
module.exports.MAGIC_ITEM_BELT_OF_GIANT_STRENGTH = 'belt_of_giant_strength'
module.exports.MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_CLOUD = 'belt_of_giant_strength_cloud'
module.exports.MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FIRE = 'belt_of_giant_strength_fire'
module.exports.MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FROST = 'belt_of_giant_strength_frost'
module.exports.MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_HILL = 'belt_of_giant_strength_hill'
module.exports.MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STONE = 'belt_of_giant_strength_stone'
module.exports.MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STORM = 'belt_of_giant_strength_storm'
module.exports.MAGIC_ITEM_BERSERKER_AXE = 'berserker_axe'
module.exports.MAGIC_ITEM_BLACKRAZOR = 'blackrazor'
module.exports.MAGIC_ITEM_BLACKSTAFF = 'blackstaff'
module.exports.MAGIC_ITEM_BLOODWELL_VIAL = 'bloodwell_vial'
module.exports.MAGIC_ITEM_BLOODWELL_VIAL_PLUS_1 = 'bloodwell_vial_plus_1'
module.exports.MAGIC_ITEM_BLOODWELL_VIAL_PLUS_2 = 'bloodwell_vial_plus_2'
module.exports.MAGIC_ITEM_BLOODWELL_VIAL_PLUS_3 = 'bloodwell_vial_plus_3'
module.exports.MAGIC_ITEM_BLOOD_FURY_TATTOO = 'blood_fury_tattoo'
module.exports.MAGIC_ITEM_BOOK_OF_EXALTED_DEEDS = 'book_of_exalted_deeds'
module.exports.MAGIC_ITEM_BOOK_OF_VILE_DARKNESS = 'book_of_vile_darkness'
module.exports.MAGIC_ITEM_BOOTS_OF_ELVENKIND = 'boots_of_elvenkind'
module.exports.MAGIC_ITEM_BOOTS_OF_FALSE_TRACKS = 'boots_of_false_tracks'
module.exports.MAGIC_ITEM_BOOTS_OF_LEVITATION = 'boots_of_levitation'
module.exports.MAGIC_ITEM_BOOTS_OF_SPEED = 'boots_of_speed'
module.exports.MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING = 'boots_of_striding_and_springing'
module.exports.MAGIC_ITEM_BOOTS_OF_THE_WINTERLANDS = 'boots_of_the_winterlands'
module.exports.MAGIC_ITEM_BOTTLED_BREATH = 'bottled_breath'
module.exports.MAGIC_ITEM_BOWL_OF_COMMANDING_WATER_ELEMENTALS = 'bowl_of_commanding_water_elementals'
module.exports.MAGIC_ITEM_BRACERS_OF_ARCHERY = 'bracers_of_archery'
module.exports.MAGIC_ITEM_BRACERS_OF_DEFENSE = 'bracers_of_defense'
module.exports.MAGIC_ITEM_BRACER_OF_FLYING_DAGGERS = 'bracer_of_flying_daggers'
module.exports.MAGIC_ITEM_BRAZIER_OF_COMMANDING_FIRE_ELEMENTALS = 'brazier_of_commanding_fire_elementals'
module.exports.MAGIC_ITEM_BROOCH_OF_SHIELDING = 'brooch_of_shielding'
module.exports.MAGIC_ITEM_BROOM_OF_FLYING = 'broom_of_flying'
module.exports.MAGIC_ITEM_CANAITH_MANDOLIN = 'canaith_mandolin'
module.exports.MAGIC_ITEM_CANDLE_OF_INVOCATION = 'candle_of_invocation'
module.exports.MAGIC_ITEM_CANDLE_OF_THE_DEEP = 'candle_of_the_deep'
module.exports.MAGIC_ITEM_CAPE_OF_THE_MOUNTEBANK = 'cape_of_the_mountebank'
module.exports.MAGIC_ITEM_CAP_OF_WATER_BREATHING = 'cap_of_water_breathing'
module.exports.MAGIC_ITEM_CARPET_OF_FLYING = 'carpet_of_flying'
module.exports.MAGIC_ITEM_CAST_OFF_ARMOR = 'cast_off_armor'
module.exports.MAGIC_ITEM_CAULDRON_OF_REBIRTH = 'cauldron_of_rebirth'
module.exports.MAGIC_ITEM_CENSER_OF_CONTROLLING_AIR_ELEMENTALS = 'censer_of_controlling_air_elementals'
module.exports.MAGIC_ITEM_CHARLATAN_S_DIE = 'charlatan_s_die'
module.exports.MAGIC_ITEM_CHARM_OF_PLANT_COMMAND = 'charm_of_plant_command'
module.exports.MAGIC_ITEM_CHIME_OF_OPENING = 'chime_of_opening'
module.exports.MAGIC_ITEM_CIRCLET_OF_BLASTING = 'circlet_of_blasting'
module.exports.MAGIC_ITEM_CLAWS_OF_THE_UMBER_HULK = 'claws_of_the_umber_hulk'
module.exports.MAGIC_ITEM_CLEANSING_STONE = 'cleansing_stone'
module.exports.MAGIC_ITEM_CLI_LYRE = 'cli_lyre'
module.exports.MAGIC_ITEM_CLOAK_OF_ARACHNIDA = 'cloak_of_arachnida'
module.exports.MAGIC_ITEM_CLOAK_OF_BILLOWING = 'cloak_of_billowing'
module.exports.MAGIC_ITEM_CLOAK_OF_DISPLACEMENT = 'cloak_of_displacement'
module.exports.MAGIC_ITEM_CLOAK_OF_ELVENKIND = 'cloak_of_elvenkind'
module.exports.MAGIC_ITEM_CLOAK_OF_INVISIBILITY = 'cloak_of_invisibility'
module.exports.MAGIC_ITEM_CLOAK_OF_MANY_FASHIONS = 'cloak_of_many_fashions'
module.exports.MAGIC_ITEM_CLOAK_OF_PROTECTION = 'cloak_of_protection'
module.exports.MAGIC_ITEM_CLOAK_OF_THE_BAT = 'cloak_of_the_bat'
module.exports.MAGIC_ITEM_CLOAK_OF_THE_MANTA_RAY = 'cloak_of_the_manta_ray'
module.exports.MAGIC_ITEM_CLOAK_OF_THE_RAVEN = 'cloak_of_the_raven'
module.exports.MAGIC_ITEM_CLOCKWORK_AMULET = 'clockwork_amulet'
module.exports.MAGIC_ITEM_CLOTHES_OF_MENDING = 'clothes_of_mending'
module.exports.MAGIC_ITEM_COILING_GRASP_TATTOO = 'coiling_grasp_tattoo'
module.exports.MAGIC_ITEM_CROOK_OF_RAO = 'crook_of_rao'
module.exports.MAGIC_ITEM_CRYSTALLINE_CHRONICLE = 'crystalline_chronicle'
module.exports.MAGIC_ITEM_CRYSTAL_BALL = 'crystal_ball'
module.exports.MAGIC_ITEM_CRYSTAL_BALL_OF_MIND_READING = 'crystal_ball_of_mind_reading'
module.exports.MAGIC_ITEM_CRYSTAL_BALL_OF_TELEPATHY = 'crystal_ball_of_telepathy'
module.exports.MAGIC_ITEM_CRYSTAL_BALL_OF_TRUE_SEEING = 'crystal_ball_of_true_seeing'
module.exports.MAGIC_ITEM_CRYSTAL_BALL_USUAL = 'crystal_ball_usual'
module.exports.MAGIC_ITEM_CUBE_OF_FORCE = 'cube_of_force'
module.exports.MAGIC_ITEM_CUBIC_GATE = 'cubic_gate'
module.exports.MAGIC_ITEM_CURSED_LUCKSTONE = 'cursed_luckstone'
module.exports.MAGIC_ITEM_DAERNS_INSTANT_FORTRESS = 'daerns_instant_fortress'
module.exports.MAGIC_ITEM_DAGGER_OF_VENOM = 'dagger_of_venom'
module.exports.MAGIC_ITEM_DANCING_SWORD = 'dancing_sword'
module.exports.MAGIC_ITEM_DARK_SHARD_AMULET = 'dark_shard_amulet'
module.exports.MAGIC_ITEM_DECANTER_OF_ENDLESS_WATER = 'decanter_of_endless_water'
module.exports.MAGIC_ITEM_DECK_OF_ILLUSIONS = 'deck_of_illusions'
module.exports.MAGIC_ITEM_DECK_OF_MANY_THINGS = 'deck_of_many_things'
module.exports.MAGIC_ITEM_DEFENDER = 'defender'
module.exports.MAGIC_ITEM_DEKELLA_BIDENT_OF_THASSA = 'dekella_bident_of_thassa'
module.exports.MAGIC_ITEM_DEMON_ARMOR = 'demon_armor'
module.exports.MAGIC_ITEM_DEVASTATION_ORB = 'devastation_orb'
module.exports.MAGIC_ITEM_DEVASTATION_ORB_AIR = 'devastation_orb_air'
module.exports.MAGIC_ITEM_DEVASTATION_ORB_EARTH = 'devastation_orb_earth'
module.exports.MAGIC_ITEM_DEVASTATION_ORB_FIRE = 'devastation_orb_fire'
module.exports.MAGIC_ITEM_DEVASTATION_ORB_WATER = 'devastation_orb_water'
module.exports.MAGIC_ITEM_DIMENSIONAL_SHACKLES = 'dimensional_shackles'
module.exports.MAGIC_ITEM_DOCENT = 'docent'
module.exports.MAGIC_ITEM_DOSS_LUTE = 'doss_lute'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL = 'dragon_scale_mail'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_BLACK = 'dragon_scale_mail_black'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_BLUE = 'dragon_scale_mail_blue'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_BRASS = 'dragon_scale_mail_brass'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_BRONZE = 'dragon_scale_mail_bronze'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_COPPER = 'dragon_scale_mail_copper'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_GOLD = 'dragon_scale_mail_gold'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_GREEN = 'dragon_scale_mail_green'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_RED = 'dragon_scale_mail_red'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_SILVER = 'dragon_scale_mail_silver'
module.exports.MAGIC_ITEM_DRAGON_SCALE_MAIL_WHITE = 'dragon_scale_mail_white'
module.exports.MAGIC_ITEM_DRAGON_SLAYER = 'dragon_slayer'
module.exports.MAGIC_ITEM_DREAD_HELM = 'dread_helm'
module.exports.MAGIC_ITEM_DRIFTGLOBE = 'driftglobe'
module.exports.MAGIC_ITEM_DROWN = 'drown'
module.exports.MAGIC_ITEM_DUST_OF_DISAPPEARANCE = 'dust_of_disappearance'
module.exports.MAGIC_ITEM_DUST_OF_DRYNESS = 'dust_of_dryness'
module.exports.MAGIC_ITEM_DUST_OF_SNEEZING_AND_CHOKING = 'dust_of_sneezing_and_choking'
module.exports.MAGIC_ITEM_DWARVEN_PLATE = 'dwarven_plate'
module.exports.MAGIC_ITEM_DWARVEN_THROWER = 'dwarven_thrower'
module.exports.MAGIC_ITEM_DYRRN_S_TENTACLE_WHIP = 'dyrrn_s_tentacle_whip'
module.exports.MAGIC_ITEM_EARWORM = 'earworm'
module.exports.MAGIC_ITEM_EAR_HORN_OF_HEARING = 'ear_horn_of_hearing'
module.exports.MAGIC_ITEM_EFREETI_BOTTLE = 'efreeti_bottle'
module.exports.MAGIC_ITEM_EFREETI_CHAIN = 'efreeti_chain'
module.exports.MAGIC_ITEM_ELEMENTAL_GEM = 'elemental_gem'
module.exports.MAGIC_ITEM_ELEMENTAL_GEM_AIR = 'elemental_gem_air'
module.exports.MAGIC_ITEM_ELEMENTAL_GEM_EARTH = 'elemental_gem_earth'
module.exports.MAGIC_ITEM_ELEMENTAL_GEM_FIRE = 'elemental_gem_fire'
module.exports.MAGIC_ITEM_ELEMENTAL_GEM_WATER = 'elemental_gem_water'
module.exports.MAGIC_ITEM_ELIXIR_OF_HEALTH = 'elixir_of_health'
module.exports.MAGIC_ITEM_ELVEN_CHAIN = 'elven_chain'
module.exports.MAGIC_ITEM_ENDURING_SPELLBOOK = 'enduring_spellbook'
module.exports.MAGIC_ITEM_EPHIXIS_BOW_OF_NYLEA = 'ephixis_bow_of_nylea'
module.exports.MAGIC_ITEM_ERSATZ_EYE = 'ersatz_eye'
module.exports.MAGIC_ITEM_EVERBRIGHT_LANTERN = 'everbright_lantern'
module.exports.MAGIC_ITEM_EVERSMOKING_BOTTLE = 'eversmoking_bottle'
module.exports.MAGIC_ITEM_EYES_OF_CHARMING = 'eyes_of_charming'
module.exports.MAGIC_ITEM_EYES_OF_MINUTE_SEEING = 'eyes_of_minute_seeing'
module.exports.MAGIC_ITEM_EYES_OF_THE_EAGLE = 'eyes_of_the_eagle'
module.exports.MAGIC_ITEM_EYE_OF_VECNA = 'eye_of_vecna'
module.exports.MAGIC_ITEM_FEATHER_OF_DIATRYMA_SUMMONING = 'feather_of_diatryma_summoning'
module.exports.MAGIC_ITEM_FEATHER_TOKEN = 'feather_token'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER = 'figurine_of_wondrous_power'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_BRONZE_GRIFFON = 'figurine_of_wondrous_power_bronze_griffon'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_EBONY_FLY = 'figurine_of_wondrous_power_ebony_fly'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_GOLDEN_LIONS = 'figurine_of_wondrous_power_golden_lions'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_IVORY_GOATS = 'figurine_of_wondrous_power_ivory_goats'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_MARBLE_ELEPHANT = 'figurine_of_wondrous_power_marble_elephant'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_OBSIDIAN_STEED = 'figurine_of_wondrous_power_obsidian_steed'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_ONYX_DOG = 'figurine_of_wondrous_power_onyx_dog'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SERPENTINE_OWL = 'figurine_of_wondrous_power_serpentine_owl'
module.exports.MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SILVER_RAVEN = 'figurine_of_wondrous_power_silver_raven'
module.exports.MAGIC_ITEM_FINDER_S_GOGGLES = 'finder_s_goggles'
module.exports.MAGIC_ITEM_FLAME_TONGUE = 'flame_tongue'
module.exports.MAGIC_ITEM_FLYING_CHARIOT = 'flying_chariot'
module.exports.MAGIC_ITEM_FOCHLUCAN_BANDORE = 'fochlucan_bandore'
module.exports.MAGIC_ITEM_FOLDING_BOAT = 'folding_boat'
module.exports.MAGIC_ITEM_FROST_BRAND = 'frost_brand'
module.exports.MAGIC_ITEM_GAUNTLETS_OF_OGRE_POWER = 'gauntlets_of_ogre_power'
module.exports.MAGIC_ITEM_GEM_OF_BRIGHTNESS = 'gem_of_brightness'
module.exports.MAGIC_ITEM_GEM_OF_SEEING = 'gem_of_seeing'
module.exports.MAGIC_ITEM_GIANT_SLAYER = 'giant_slayer'
module.exports.MAGIC_ITEM_GLAMERWEAVE_COMMON = 'glamerweave_common'
module.exports.MAGIC_ITEM_GLAMERWEAVE_UNCOMMON = 'glamerweave_uncommon'
module.exports.MAGIC_ITEM_GLAMOURED_STUDDED_LEATHER = 'glamoured_studded_leather'
module.exports.MAGIC_ITEM_GLOVES_OF_MISSILE_SNARING = 'gloves_of_missile_snaring'
module.exports.MAGIC_ITEM_GLOVES_OF_SWIMMING_AND_CLIMBING = 'gloves_of_swimming_and_climbing'
module.exports.MAGIC_ITEM_GLOVES_OF_THIEVERY = 'gloves_of_thievery'
module.exports.MAGIC_ITEM_GOGGLES_OF_NIGHT = 'goggles_of_night'
module.exports.MAGIC_ITEM_GWATHERN_FALN = 'gwathern_faln'
module.exports.MAGIC_ITEM_HAMMER_OF_THUNDERBOLTS = 'hammer_of_thunderbolts'
module.exports.MAGIC_ITEM_HAND_OF_VECNA = 'hand_of_vecna'
module.exports.MAGIC_ITEM_HAT_OF_DISGUISE = 'hat_of_disguise'
module.exports.MAGIC_ITEM_HAT_OF_VERMIN = 'hat_of_vermin'
module.exports.MAGIC_ITEM_HAT_OF_WIZARDRY = 'hat_of_wizardry'
module.exports.MAGIC_ITEM_HEADBAND_OF_INTELLECT = 'headband_of_intellect'
module.exports.MAGIC_ITEM_HELLFIRE_WEAPON = 'hellfire_weapon'
module.exports.MAGIC_ITEM_HELM_OF_BRILLIANCE = 'helm_of_brilliance'
module.exports.MAGIC_ITEM_HELM_OF_COMPREHENDING_LANGUAGES = 'helm_of_comprehending_languages'
module.exports.MAGIC_ITEM_HELM_OF_TELEPATHY = 'helm_of_telepathy'
module.exports.MAGIC_ITEM_HELM_OF_TELEPORTATION = 'helm_of_teleportation'
module.exports.MAGIC_ITEM_HELM_OF_THE_GODS = 'helm_of_the_gods'
module.exports.MAGIC_ITEM_HELM_OF_UNDERWATER_ACTION = 'helm_of_underwater_action'
module.exports.MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK = 'heward_s_handy_haversack'
module.exports.MAGIC_ITEM_HEWARD_S_HANDY_SPICE_POUCH = 'heward_s_handy_spice_pouch'
module.exports.MAGIC_ITEM_HOLY_AVENGER = 'holy_avenger'
module.exports.MAGIC_ITEM_HORN_OF_BLASTING = 'horn_of_blasting'
module.exports.MAGIC_ITEM_HORN_OF_SILENT_ALARM = 'horn_of_silent_alarm'
module.exports.MAGIC_ITEM_HORN_OF_VALHALLA = 'horn_of_valhalla'
module.exports.MAGIC_ITEM_HORN_OF_VALHALLA_BRASS = 'horn_of_valhalla_brass'
module.exports.MAGIC_ITEM_HORN_OF_VALHALLA_BRONZE = 'horn_of_valhalla_bronze'
module.exports.MAGIC_ITEM_HORN_OF_VALHALLA_IRON = 'horn_of_valhalla_iron'
module.exports.MAGIC_ITEM_HORN_OF_VALHALLA_SILVER = 'horn_of_valhalla_silver'
module.exports.MAGIC_ITEM_HORSESHOES_OF_A_ZEPHYR = 'horseshoes_of_a_zephyr'
module.exports.MAGIC_ITEM_HORSESHOES_OF_SPEED = 'horseshoes_of_speed'
module.exports.MAGIC_ITEM_IMBUED_WOOD_FOCUS = 'imbued_wood_focus'
module.exports.MAGIC_ITEM_IMMOVABLE_ROD = 'immovable_rod'
module.exports.MAGIC_ITEM_INFERNAL_TACK = 'infernal_tack'
module.exports.MAGIC_ITEM_INSTRUMENT_OF_ILLUSIONS = 'instrument_of_illusions'
module.exports.MAGIC_ITEM_INSTRUMENT_OF_SCRIBING = 'instrument_of_scribing'
module.exports.MAGIC_ITEM_INSTRUMENT_OF_THE_BARDS = 'instrument_of_the_bards'
module.exports.MAGIC_ITEM_IOUN_STONE = 'ioun_stone'
module.exports.MAGIC_ITEM_IOUN_STONE_ABSORPTION = 'ioun_stone_absorption'
module.exports.MAGIC_ITEM_IOUN_STONE_AGILITY = 'ioun_stone_agility'
module.exports.MAGIC_ITEM_IOUN_STONE_AWARENESS = 'ioun_stone_awareness'
module.exports.MAGIC_ITEM_IOUN_STONE_FORTITUDE = 'ioun_stone_fortitude'
module.exports.MAGIC_ITEM_IOUN_STONE_GREATER_ABSORPTION = 'ioun_stone_greater_absorption'
module.exports.MAGIC_ITEM_IOUN_STONE_INSIGHT = 'ioun_stone_insight'
module.exports.MAGIC_ITEM_IOUN_STONE_INTELLECT = 'ioun_stone_intellect'
module.exports.MAGIC_ITEM_IOUN_STONE_LEADERSHIP = 'ioun_stone_leadership'
module.exports.MAGIC_ITEM_IOUN_STONE_MASTERY = 'ioun_stone_mastery'
module.exports.MAGIC_ITEM_IOUN_STONE_PROTECTION = 'ioun_stone_protection'
module.exports.MAGIC_ITEM_IOUN_STONE_REGENERATION = 'ioun_stone_regeneration'
module.exports.MAGIC_ITEM_IOUN_STONE_RESERVE = 'ioun_stone_reserve'
module.exports.MAGIC_ITEM_IOUN_STONE_STRENGTH = 'ioun_stone_strength'
module.exports.MAGIC_ITEM_IOUN_STONE_SUSTENANCE = 'ioun_stone_sustenance'
module.exports.MAGIC_ITEM_IRONFANG = 'ironfang'
module.exports.MAGIC_ITEM_IRON_BANDS_OF_BILARRO = 'iron_bands_of_bilarro'
module.exports.MAGIC_ITEM_IRON_FLASK = 'iron_flask'
module.exports.MAGIC_ITEM_JAVELIN_OF_LIGHTNING = 'javelin_of_lightning'
module.exports.MAGIC_ITEM_KEOGHTOM_S_OINTMENT = 'keoghtom_s_ointment'
module.exports.MAGIC_ITEM_KEYCHARM = 'keycharm'
module.exports.MAGIC_ITEM_KNAVE_S_EYE_PATCH = 'knave_s_eye_patch'
module.exports.MAGIC_ITEM_KYRZIN_S_OOZE = 'kyrzin_s_ooze'
module.exports.MAGIC_ITEM_LANTERN_OF_REVEALING = 'lantern_of_revealing'
module.exports.MAGIC_ITEM_LIVING_ARMOR = 'living_armor'
module.exports.MAGIC_ITEM_LIVING_GLOVES = 'living_gloves'
module.exports.MAGIC_ITEM_LOCK_OF_TRICKERY = 'lock_of_trickery'
module.exports.MAGIC_ITEM_LOST_CROWN_OF_BESILMER = 'lost_crown_of_besilmer'
module.exports.MAGIC_ITEM_LUCK_BLADE = 'luck_blade'
module.exports.MAGIC_ITEM_MACE_OF_DISRUPTION = 'mace_of_disruption'
module.exports.MAGIC_ITEM_MACE_OF_SMITING = 'mace_of_smiting'
module.exports.MAGIC_ITEM_MACE_OF_TERROR = 'mace_of_terror'
module.exports.MAGIC_ITEM_MAC_FUIRMIDH_CITTERN = 'mac_fuirmidh_cittern'
module.exports.MAGIC_ITEM_MANTLE_OF_SPELL_RESISTANCE = 'mantle_of_spell_resistance'
module.exports.MAGIC_ITEM_MANUAL_OF_BODILY_HEALTH = 'manual_of_bodily_health'
module.exports.MAGIC_ITEM_MANUAL_OF_GAINFUL_EXERCISE = 'manual_of_gainful_exercise'
module.exports.MAGIC_ITEM_MANUAL_OF_GOLEMS = 'manual_of_golems'
module.exports.MAGIC_ITEM_MANUAL_OF_GOLEMS_CLAY = 'manual_of_golems_clay'
module.exports.MAGIC_ITEM_MANUAL_OF_GOLEMS_FLESH = 'manual_of_golems_flesh'
module.exports.MAGIC_ITEM_MANUAL_OF_GOLEMS_IRON = 'manual_of_golems_iron'
module.exports.MAGIC_ITEM_MANUAL_OF_GOLEMS_STONE = 'manual_of_golems_stone'
module.exports.MAGIC_ITEM_MANUAL_OF_QUICKNESS_OF_ACTION = 'manual_of_quickness_of_action'
module.exports.MAGIC_ITEM_MARINER_S_ARMOR = 'mariner_s_armor'
module.exports.MAGIC_ITEM_MATALOTOK = 'matalotok'
module.exports.MAGIC_ITEM_MEDALLION_OF_THOUGHTS = 'medallion_of_thoughts'
module.exports.MAGIC_ITEM_MIRROR_OF_LIFE_TRAPPING = 'mirror_of_life_trapping'
module.exports.MAGIC_ITEM_MITHRAL_ARMOR = 'mithral_armor'
module.exports.MAGIC_ITEM_MOLTEN_BRONZE_SKIN = 'molten_bronze_skin'
module.exports.MAGIC_ITEM_MOONBLADE = 'moonblade'
module.exports.MAGIC_ITEM_MOON_TOUCHED_SWORD = 'moon_touched_sword'
module.exports.MAGIC_ITEM_MYSTERY_KEY = 'mystery_key'
module.exports.MAGIC_ITEM_NECKLACE_OF_ADAPTATION = 'necklace_of_adaptation'
module.exports.MAGIC_ITEM_NECKLACE_OF_FIREBALLS = 'necklace_of_fireballs'
module.exports.MAGIC_ITEM_NECKLACE_OF_PRAYER_BEADS = 'necklace_of_prayer_beads'
module.exports.MAGIC_ITEM_NINE_LIVES_STEALER = 'nine_lives_stealer'
module.exports.MAGIC_ITEM_NOLZURS_MARVELOUS_PIGMENTS = 'nolzurs_marvelous_pigments'
module.exports.MAGIC_ITEM_OATHBOW = 'oathbow'
module.exports.MAGIC_ITEM_OIL_OF_ETHEREALNESS = 'oil_of_etherealness'
module.exports.MAGIC_ITEM_OIL_OF_SHARPNESS = 'oil_of_sharpness'
module.exports.MAGIC_ITEM_OIL_OF_SLIPPERINESS = 'oil_of_slipperiness'
module.exports.MAGIC_ITEM_OLLAMH_HARP = 'ollamh_harp'
module.exports.MAGIC_ITEM_ORB_OF_DIRECTION = 'orb_of_direction'
module.exports.MAGIC_ITEM_ORB_OF_DRAGONKIND = 'orb_of_dragonkind'
module.exports.MAGIC_ITEM_ORB_OF_SHIELDING = 'orb_of_shielding'
module.exports.MAGIC_ITEM_ORB_OF_SHIELDING_FERNIAN_BASALT = 'orb_of_shielding_fernian_basalt'
module.exports.MAGIC_ITEM_ORB_OF_SHIELDING_IRIAN_QUARTZ = 'orb_of_shielding_irian_quartz'
module.exports.MAGIC_ITEM_ORB_OF_SHIELDING_KYTHRIAN_SKARN = 'orb_of_shielding_kythrian_skarn'
module.exports.MAGIC_ITEM_ORB_OF_SHIELDING_LAMANNIAN_FLINT = 'orb_of_shielding_lamannian_flint'
module.exports.MAGIC_ITEM_ORB_OF_SHIELDING_MABARAN_OBSIDIAN = 'orb_of_shielding_mabaran_obsidian'
module.exports.MAGIC_ITEM_ORB_OF_SHIELDING_RISIAN_SHALE = 'orb_of_shielding_risian_shale'
module.exports.MAGIC_ITEM_ORB_OF_SHIELDING_SHAVARRAN_CHERT = 'orb_of_shielding_shavarran_chert'
module.exports.MAGIC_ITEM_ORB_OF_SHIELDING_XORIAN_MARBLE = 'orb_of_shielding_xorian_marble'
module.exports.MAGIC_ITEM_ORB_OF_TIME = 'orb_of_time'
module.exports.MAGIC_ITEM_ORCSPLITTER = 'orcsplitter'
module.exports.MAGIC_ITEM_PAPER_BIRD = 'paper_bird'
module.exports.MAGIC_ITEM_PEARL_OF_POWER = 'pearl_of_power'
module.exports.MAGIC_ITEM_PERFUME_OF_BEWITCHING = 'perfume_of_bewitching'
module.exports.MAGIC_ITEM_PERIAPT_OF_HEALTH = 'periapt_of_health'
module.exports.MAGIC_ITEM_PERIAPT_OF_PROOF_AGAINST_POISON = 'periapt_of_proof_against_poison'
module.exports.MAGIC_ITEM_PERIAPT_OF_WOUND_CLOSURE = 'periapt_of_wound_closure'
module.exports.MAGIC_ITEM_PHILTER_OF_LOVE = 'philter_of_love'
module.exports.MAGIC_ITEM_PIPES_OF_HAUNTING = 'pipes_of_haunting'
module.exports.MAGIC_ITEM_PIPES_OF_THE_SEWERS = 'pipes_of_the_sewers'
module.exports.MAGIC_ITEM_PIPE_OF_REMEMBRANCE = 'pipe_of_remembrance'
module.exports.MAGIC_ITEM_PIPE_OF_SMOKE_MONSTERS = 'pipe_of_smoke_monsters'
module.exports.MAGIC_ITEM_PLATE_ARMOR_OF_ETHEREALNESS = 'plate_armor_of_etherealness'
module.exports.MAGIC_ITEM_PLATINUM_SCARF = 'platinum_scarf'
module.exports.MAGIC_ITEM_POLE_OF_ANGLING = 'pole_of_angling'
module.exports.MAGIC_ITEM_POLE_OF_COLLAPSING = 'pole_of_collapsing'
module.exports.MAGIC_ITEM_PORTABLE_HOLE = 'portable_hole'
module.exports.MAGIC_ITEM_POTION_OF_ANIMAL_FRIENDSHIP = 'potion_of_animal_friendship'
module.exports.MAGIC_ITEM_POTION_OF_AQUEOUS_FORM = 'potion_of_aqueous_form'
module.exports.MAGIC_ITEM_POTION_OF_CLAIRVOYANCE = 'potion_of_clairvoyance'
module.exports.MAGIC_ITEM_POTION_OF_CLIMBING = 'potion_of_climbing'
module.exports.MAGIC_ITEM_POTION_OF_CLOUD_GIANT_STRENGTH = 'potion_of_cloud_giant_strength'
module.exports.MAGIC_ITEM_POTION_OF_DIMINUTION = 'potion_of_diminution'
module.exports.MAGIC_ITEM_POTION_OF_FIRE_BREATH = 'potion_of_fire_breath'
module.exports.MAGIC_ITEM_POTION_OF_FIRE_GIANT_STRENGTH = 'potion_of_fire_giant_strength'
module.exports.MAGIC_ITEM_POTION_OF_FLYING = 'potion_of_flying'
module.exports.MAGIC_ITEM_POTION_OF_FROST_GIANT_STRENGTH = 'potion_of_frost_giant_strength'
module.exports.MAGIC_ITEM_POTION_OF_GASEOUS_FORM = 'potion_of_gaseous_form'
module.exports.MAGIC_ITEM_POTION_OF_GIANT_STRENGTH = 'potion_of_giant_strength'
module.exports.MAGIC_ITEM_POTION_OF_GREATER_HEALING = 'potion_of_greater_healing'
module.exports.MAGIC_ITEM_POTION_OF_GROWTH = 'potion_of_growth'
module.exports.MAGIC_ITEM_POTION_OF_HEALING = 'potion_of_healing'
module.exports.MAGIC_ITEM_POTION_OF_HEALING_COMMON = 'potion_of_healing_common'
module.exports.MAGIC_ITEM_POTION_OF_HEROISM = 'potion_of_heroism'
module.exports.MAGIC_ITEM_POTION_OF_HILL_GIANT_STRENGTH = 'potion_of_hill_giant_strength'
module.exports.MAGIC_ITEM_POTION_OF_INVISIBILITY = 'potion_of_invisibility'
module.exports.MAGIC_ITEM_POTION_OF_INVULNERABILITY = 'potion_of_invulnerability'
module.exports.MAGIC_ITEM_POTION_OF_LONGEVITY = 'potion_of_longevity'
module.exports.MAGIC_ITEM_POTION_OF_MIND_READING = 'potion_of_mind_reading'
module.exports.MAGIC_ITEM_POTION_OF_POISON = 'potion_of_poison'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE = 'potion_of_resistance'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_ACID = 'potion_of_resistance_to_acid'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_COLD = 'potion_of_resistance_to_cold'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_ELECTRICITY = 'potion_of_resistance_to_electricity'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_FIRE = 'potion_of_resistance_to_fire'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_FORCE = 'potion_of_resistance_to_force'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_NECROTIC = 'potion_of_resistance_to_necrotic'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_POISON = 'potion_of_resistance_to_poison'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_PSYCHIC = 'potion_of_resistance_to_psychic'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_RADIANT = 'potion_of_resistance_to_radiant'
module.exports.MAGIC_ITEM_POTION_OF_RESISTANCE_TO_THUNDER = 'potion_of_resistance_to_thunder'
module.exports.MAGIC_ITEM_POTION_OF_SPEED = 'potion_of_speed'
module.exports.MAGIC_ITEM_POTION_OF_STONE_GIANT_STRENGTH = 'potion_of_stone_giant_strength'
module.exports.MAGIC_ITEM_POTION_OF_STORM_GIANT_STRENGTH = 'potion_of_storm_giant_strength'
module.exports.MAGIC_ITEM_POTION_OF_SUPERIOR_HEALING = 'potion_of_superior_healing'
module.exports.MAGIC_ITEM_POTION_OF_SUPREME_HEALING = 'potion_of_supreme_healing'
module.exports.MAGIC_ITEM_POTION_OF_VITALITY = 'potion_of_vitality'
module.exports.MAGIC_ITEM_POTION_OF_WATER_BREATHING = 'potion_of_water_breathing'
module.exports.MAGIC_ITEM_POT_OF_AWAKENING = 'pot_of_awakening'
module.exports.MAGIC_ITEM_PRESSURE_CAPSULE = 'pressure_capsule'
module.exports.MAGIC_ITEM_PROSTHETIC_LIMB = 'prosthetic_limb'
module.exports.MAGIC_ITEM_PYXIS_OF_PANDEMONIUM = 'pyxis_of_pandemonium'
module.exports.MAGIC_ITEM_QUAALS_FEATHER_TOKEN = 'quaals_feather_token'
module.exports.MAGIC_ITEM_QUAALS_FEATHER_TOKEN_ANCHOR = 'quaals_feather_token_anchor'
module.exports.MAGIC_ITEM_QUAALS_FEATHER_TOKEN_BIRD = 'quaals_feather_token_bird'
module.exports.MAGIC_ITEM_QUAALS_FEATHER_TOKEN_FAN = 'quaals_feather_token_fan'
module.exports.MAGIC_ITEM_QUAALS_FEATHER_TOKEN_SWAN_BOAT = 'quaals_feather_token_swan_boat'
module.exports.MAGIC_ITEM_QUAALS_FEATHER_TOKEN_TREE = 'quaals_feather_token_tree'
module.exports.MAGIC_ITEM_QUAALS_FEATHER_TOKEN_WHIP = 'quaals_feather_token_whip'
module.exports.MAGIC_ITEM_QUIVER_OF_EHLONNA = 'quiver_of_ehlonna'
module.exports.MAGIC_ITEM_RING_OF_ANIMAL_INFLUENCE = 'ring_of_animal_influence'
module.exports.MAGIC_ITEM_RING_OF_DJINNI_SUMMONING = 'ring_of_djinni_summoning'
module.exports.MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND = 'ring_of_elemental_command'
module.exports.MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_AIR = 'ring_of_elemental_command_air'
module.exports.MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_EARTH = 'ring_of_elemental_command_earth'
module.exports.MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_FIRE = 'ring_of_elemental_command_fire'
module.exports.MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_WATER = 'ring_of_elemental_command_water'
module.exports.MAGIC_ITEM_RING_OF_EVASION = 'ring_of_evasion'
module.exports.MAGIC_ITEM_RING_OF_FEATHER_FALLING = 'ring_of_feather_falling'
module.exports.MAGIC_ITEM_RING_OF_FREE_ACTION = 'ring_of_free_action'
module.exports.MAGIC_ITEM_RING_OF_INVISIBILITY = 'ring_of_invisibility'
module.exports.MAGIC_ITEM_RING_OF_JUMPING = 'ring_of_jumping'
module.exports.MAGIC_ITEM_RING_OF_MIND_SHIELDING = 'ring_of_mind_shielding'
module.exports.MAGIC_ITEM_RING_OF_PROTECTION = 'ring_of_protection'
module.exports.MAGIC_ITEM_RING_OF_REGENERATION = 'ring_of_regeneration'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE = 'ring_of_resistance'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_ACID = 'ring_of_resistance_acid'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_COLD = 'ring_of_resistance_cold'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_ELECTRICITY = 'ring_of_resistance_electricity'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_FIRE = 'ring_of_resistance_fire'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_FORCE = 'ring_of_resistance_force'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_NECROTIC = 'ring_of_resistance_necrotic'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_POISON = 'ring_of_resistance_poison'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_PSYCHIC = 'ring_of_resistance_psychic'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_RADIANT = 'ring_of_resistance_radiant'
module.exports.MAGIC_ITEM_RING_OF_RESISTANCE_THUNDER = 'ring_of_resistance_thunder'
module.exports.MAGIC_ITEM_RING_OF_SHOOTING_STARS = 'ring_of_shooting_stars'
module.exports.MAGIC_ITEM_RING_OF_SPELL_STORING = 'ring_of_spell_storing'
module.exports.MAGIC_ITEM_RING_OF_SPELL_TURNING = 'ring_of_spell_turning'
module.exports.MAGIC_ITEM_RING_OF_SWIMMING = 'ring_of_swimming'
module.exports.MAGIC_ITEM_RING_OF_TELEKINESIS = 'ring_of_telekinesis'
module.exports.MAGIC_ITEM_RING_OF_THE_RAM = 'ring_of_the_ram'
module.exports.MAGIC_ITEM_RING_OF_THREE_WISHES = 'ring_of_three_wishes'
module.exports.MAGIC_ITEM_RING_OF_TRUTH_TELLING = 'ring_of_truth_telling'
module.exports.MAGIC_ITEM_RING_OF_WARMTH = 'ring_of_warmth'
module.exports.MAGIC_ITEM_RING_OF_WATER_WALKING = 'ring_of_water_walking'
module.exports.MAGIC_ITEM_RING_OF_X_RAY_VISION = 'ring_of_x_ray_vision'
module.exports.MAGIC_ITEM_ROBE_OF_EYES = 'robe_of_eyes'
module.exports.MAGIC_ITEM_ROBE_OF_SCINTILLATING_COLORS = 'robe_of_scintillating_colors'
module.exports.MAGIC_ITEM_ROBE_OF_STARS = 'robe_of_stars'
module.exports.MAGIC_ITEM_ROBE_OF_THE_ARCHMAGI = 'robe_of_the_archmagi'
module.exports.MAGIC_ITEM_ROBE_OF_USEFUL_ITEMS = 'robe_of_useful_items'
module.exports.MAGIC_ITEM_ROD_OF_ABSORPTION = 'rod_of_absorption'
module.exports.MAGIC_ITEM_ROD_OF_ALERTNESS = 'rod_of_alertness'
module.exports.MAGIC_ITEM_ROD_OF_LORDLY_MIGHT = 'rod_of_lordly_might'
module.exports.MAGIC_ITEM_ROD_OF_RESURRECTION = 'rod_of_resurrection'
module.exports.MAGIC_ITEM_ROD_OF_RULERSHIP = 'rod_of_rulership'
module.exports.MAGIC_ITEM_ROD_OF_SECURITY = 'rod_of_security'
module.exports.MAGIC_ITEM_ROD_OF_THE_PACT_KEEPER = 'rod_of_the_pact_keeper'
module.exports.MAGIC_ITEM_ROD_OF_THE_PACT_KEEPER_PLUS_1 = 'rod_of_the_pact_keeper_plus_1'
module.exports.MAGIC_ITEM_ROD_OF_THE_PACT_KEEPER_PLUS_2 = 'rod_of_the_pact_keeper_plus_2'
module.exports.MAGIC_ITEM_ROD_OF_THE_PACT_KEEPER_PLUS_3 = 'rod_of_the_pact_keeper_plus_3'
module.exports.MAGIC_ITEM_ROPE_OF_CLIMBING = 'rope_of_climbing'
module.exports.MAGIC_ITEM_ROPE_OF_ENTANGLEMENT = 'rope_of_entanglement'
module.exports.MAGIC_ITEM_ROPE_OF_MENDING = 'rope_of_mending'
module.exports.MAGIC_ITEM_RUBY_OF_THE_WAR_MAGE = 'ruby_of_the_war_mage'
module.exports.MAGIC_ITEM_SADDLE_OF_THE_CAVALIER = 'saddle_of_the_cavalier'
module.exports.MAGIC_ITEM_SCARAB_OF_PROTECTION = 'scarab_of_protection'
module.exports.MAGIC_ITEM_SCIMITAR_OF_SPEED = 'scimitar_of_speed'
module.exports.MAGIC_ITEM_SCRIBE_S_PEN = 'scribe_s_pen'
module.exports.MAGIC_ITEM_SCROLL_OF_PROTECTION = 'scroll_of_protection'
module.exports.MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_ABERRATIONS = 'scroll_of_protection_from_aberrations'
module.exports.MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_BEASTS = 'scroll_of_protection_from_beasts'
module.exports.MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_CELESTIALS = 'scroll_of_protection_from_celestials'
module.exports.MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_ELEMENTALS = 'scroll_of_protection_from_elementals'
module.exports.MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_FEY = 'scroll_of_protection_from_fey'
module.exports.MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_FIENDS = 'scroll_of_protection_from_fiends'
module.exports.MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_PLANTS = 'scroll_of_protection_from_plants'
module.exports.MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_UNDEAD = 'scroll_of_protection_from_undead'
module.exports.MAGIC_ITEM_SEEKER_DART = 'seeker_dart'
module.exports.MAGIC_ITEM_SEKOLAHIAN_WORSHIPING_STATUETTE = 'sekolahian_worshiping_statuette'
module.exports.MAGIC_ITEM_SENDING_STONES = 'sending_stones'
module.exports.MAGIC_ITEM_SENTINEL_SHIELD = 'sentinel_shield'
module.exports.MAGIC_ITEM_SHIELD_1 = 'shield_1'
module.exports.MAGIC_ITEM_SHIELD_2 = 'shield_2'
module.exports.MAGIC_ITEM_SHIELD_3 = 'shield_3'
module.exports.MAGIC_ITEM_SHIELD_OF_EXPRESSION = 'shield_of_expression'
module.exports.MAGIC_ITEM_SHIELD_OF_MISSILE_ATTRACTION = 'shield_of_missile_attraction'
module.exports.MAGIC_ITEM_SHIELD_PLUS_1_2_3 = 'shield_plus_1_2_3'
module.exports.MAGIC_ITEM_SHIFTWEAVE = 'shiftweave'
module.exports.MAGIC_ITEM_SIREN_SONG_LYRE = 'siren_song_lyre'
module.exports.MAGIC_ITEM_SLING_BULLETS_OF_ALTHEMONE = 'sling_bullets_of_althemone'
module.exports.MAGIC_ITEM_SLIPPERS_OF_SPIDER_CLIMBING = 'slippers_of_spider_climbing'
module.exports.MAGIC_ITEM_SMOKEPOWDER = 'smokepowder'
module.exports.MAGIC_ITEM_SMOKEPOWDER_KEG = 'smokepowder_keg'
module.exports.MAGIC_ITEM_SMOKEPOWDER_PACKET = 'smokepowder_packet'
module.exports.MAGIC_ITEM_SMOLDERING_ARMOR = 'smoldering_armor'
module.exports.MAGIC_ITEM_SOVEREIGN_GLUE = 'sovereign_glue'
module.exports.MAGIC_ITEM_SPEAKING_STONE = 'speaking_stone'
module.exports.MAGIC_ITEM_SPELLGUARD_SHIELD = 'spellguard_shield'
module.exports.MAGIC_ITEM_SPELLSHARD = 'spellshard'
module.exports.MAGIC_ITEM_SPELL_SCROLL = 'spell_scroll'
module.exports.MAGIC_ITEM_SPELL_SCROLL_0 = 'spell_scroll_0'
module.exports.MAGIC_ITEM_SPELL_SCROLL_1 = 'spell_scroll_1'
module.exports.MAGIC_ITEM_SPELL_SCROLL_2 = 'spell_scroll_2'
module.exports.MAGIC_ITEM_SPELL_SCROLL_3 = 'spell_scroll_3'
module.exports.MAGIC_ITEM_SPELL_SCROLL_4 = 'spell_scroll_4'
module.exports.MAGIC_ITEM_SPELL_SCROLL_5 = 'spell_scroll_5'
module.exports.MAGIC_ITEM_SPELL_SCROLL_6 = 'spell_scroll_6'
module.exports.MAGIC_ITEM_SPELL_SCROLL_7 = 'spell_scroll_7'
module.exports.MAGIC_ITEM_SPELL_SCROLL_8 = 'spell_scroll_8'
module.exports.MAGIC_ITEM_SPELL_SCROLL_9 = 'spell_scroll_9'
module.exports.MAGIC_ITEM_SPHERE_OF_ANNIHILATION = 'sphere_of_annihilation'
module.exports.MAGIC_ITEM_STAFF_OF_ADORNMENT = 'staff_of_adornment'
module.exports.MAGIC_ITEM_STAFF_OF_BIRDCALLS = 'staff_of_birdcalls'
module.exports.MAGIC_ITEM_STAFF_OF_CHARMING = 'staff_of_charming'
module.exports.MAGIC_ITEM_STAFF_OF_FIRE = 'staff_of_fire'
module.exports.MAGIC_ITEM_STAFF_OF_FLOWERS = 'staff_of_flowers'
module.exports.MAGIC_ITEM_STAFF_OF_FROST = 'staff_of_frost'
module.exports.MAGIC_ITEM_STAFF_OF_HEALING = 'staff_of_healing'
module.exports.MAGIC_ITEM_STAFF_OF_POWER = 'staff_of_power'
module.exports.MAGIC_ITEM_STAFF_OF_STRIKING = 'staff_of_striking'
module.exports.MAGIC_ITEM_STAFF_OF_SWARMING_INSECTS = 'staff_of_swarming_insects'
module.exports.MAGIC_ITEM_STAFF_OF_THE_ADDER = 'staff_of_the_adder'
module.exports.MAGIC_ITEM_STAFF_OF_THE_MAGI = 'staff_of_the_magi'
module.exports.MAGIC_ITEM_STAFF_OF_THE_PYTHON = 'staff_of_the_python'
module.exports.MAGIC_ITEM_STAFF_OF_THE_WOODLANDS = 'staff_of_the_woodlands'
module.exports.MAGIC_ITEM_STAFF_OF_THUNDER_AND_LIGHTNING = 'staff_of_thunder_and_lightning'
module.exports.MAGIC_ITEM_STAFF_OF_WITHERING = 'staff_of_withering'
module.exports.MAGIC_ITEM_STONE_OF_CONTROLLING_EARTH_ELEMENTALS = 'stone_of_controlling_earth_elementals'
module.exports.MAGIC_ITEM_STONE_OF_GOOD_LUCK = 'stone_of_good_luck'
module.exports.MAGIC_ITEM_STORM_BOOMERANG = 'storm_boomerang'
module.exports.MAGIC_ITEM_SUN_BLADE = 'sun_blade'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING = 'sword_of_answering'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING_ANSWERER = 'sword_of_answering_answerer'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING_BACK_TALKER = 'sword_of_answering_back_talker'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING_CONCLUDER = 'sword_of_answering_concluder'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING_LAST_QUIP = 'sword_of_answering_last_quip'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING_REBUTTER = 'sword_of_answering_rebutter'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING_REPLIER = 'sword_of_answering_replier'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING_RETORTER = 'sword_of_answering_retorter'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING_SCATHER = 'sword_of_answering_scather'
module.exports.MAGIC_ITEM_SWORD_OF_ANSWERING_SQUELCHER = 'sword_of_answering_squelcher'
module.exports.MAGIC_ITEM_SWORD_OF_KAS = 'sword_of_kas'
module.exports.MAGIC_ITEM_SWORD_OF_LIFE_STEALING = 'sword_of_life_stealing'
module.exports.MAGIC_ITEM_SWORD_OF_SHARPNESS = 'sword_of_sharpness'
module.exports.MAGIC_ITEM_SWORD_OF_VENGEANCE = 'sword_of_vengeance'
module.exports.MAGIC_ITEM_SWORD_OF_WOUNDING = 'sword_of_wounding'
module.exports.MAGIC_ITEM_TALISMAN_OF_PURE_GOOD = 'talisman_of_pure_good'
module.exports.MAGIC_ITEM_TALISMAN_OF_THE_SPHERE = 'talisman_of_the_sphere'
module.exports.MAGIC_ITEM_TALISMAN_OF_ULTIMATE_EVIL = 'talisman_of_ultimate_evil'
module.exports.MAGIC_ITEM_TALKING_DOLL = 'talking_doll'
module.exports.MAGIC_ITEM_TANKARD_OF_SOBRIETY = 'tankard_of_sobriety'
module.exports.MAGIC_ITEM_TENTACLE_ROD = 'tentacle_rod'
module.exports.MAGIC_ITEM_TINDERSTRIKE = 'tinderstrike'
module.exports.MAGIC_ITEM_TOME_OF_CLEAR_THOUGHT = 'tome_of_clear_thought'
module.exports.MAGIC_ITEM_TOME_OF_LEADERSHIP_AND_INFLUENCE = 'tome_of_leadership_and_influence'
module.exports.MAGIC_ITEM_TOME_OF_THE_STILLED_TONGUE = 'tome_of_the_stilled_tongue'
module.exports.MAGIC_ITEM_TOME_OF_UNDERSTANDING = 'tome_of_understanding'
module.exports.MAGIC_ITEM_TRIDENT_OF_FISH_COMMAND = 'trident_of_fish_command'
module.exports.MAGIC_ITEM_TWO_BIRDS_SLING = 'two_birds_sling'
module.exports.MAGIC_ITEM_UNBREAKABLE_ARROW = 'unbreakable_arrow'
module.exports.MAGIC_ITEM_UNIVERSAL_SOLVENT = 'universal_solvent'
module.exports.MAGIC_ITEM_VENTILATING_LUNGS = 'ventilating_lungs'
module.exports.MAGIC_ITEM_VETERAN_S_CANE = 'veteran_s_cane'
module.exports.MAGIC_ITEM_VICIOUS_WEAPON = 'vicious_weapon'
module.exports.MAGIC_ITEM_VORPAL_SWORD = 'vorpal_sword'
module.exports.MAGIC_ITEM_WALLOPING_AMMUNITION = 'walloping_ammunition'
module.exports.MAGIC_ITEM_WAND_OF_BINDING = 'wand_of_binding'
module.exports.MAGIC_ITEM_WAND_OF_CONDUCTING = 'wand_of_conducting'
module.exports.MAGIC_ITEM_WAND_OF_ENEMY_DETECTION = 'wand_of_enemy_detection'
module.exports.MAGIC_ITEM_WAND_OF_FEAR = 'wand_of_fear'
module.exports.MAGIC_ITEM_WAND_OF_FIREBALLS = 'wand_of_fireballs'
module.exports.MAGIC_ITEM_WAND_OF_LIGHTNING_BOLTS = 'wand_of_lightning_bolts'
module.exports.MAGIC_ITEM_WAND_OF_MAGIC_DETECTION = 'wand_of_magic_detection'
module.exports.MAGIC_ITEM_WAND_OF_MAGIC_MISSILES = 'wand_of_magic_missiles'
module.exports.MAGIC_ITEM_WAND_OF_ORCUS = 'wand_of_orcus'
module.exports.MAGIC_ITEM_WAND_OF_PARALYSIS = 'wand_of_paralysis'
module.exports.MAGIC_ITEM_WAND_OF_POLYMORPH = 'wand_of_polymorph'
module.exports.MAGIC_ITEM_WAND_OF_PYROTECHNICS = 'wand_of_pyrotechnics'
module.exports.MAGIC_ITEM_WAND_OF_SCOWLS = 'wand_of_scowls'
module.exports.MAGIC_ITEM_WAND_OF_SECRETS = 'wand_of_secrets'
module.exports.MAGIC_ITEM_WAND_OF_SMILES = 'wand_of_smiles'
module.exports.MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_1 = 'wand_of_the_war_mage_plus_1'
module.exports.MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_1_2_OR_3 = 'wand_of_the_war_mage_1_2_or_3'
module.exports.MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_2 = 'wand_of_the_war_mage_plus_2'
module.exports.MAGIC_ITEM_WAND_OF_THE_WAR_MAGE_PLUS_3 = 'wand_of_the_war_mage_plus_3'
module.exports.MAGIC_ITEM_WAND_OF_WEB = 'wand_of_web'
module.exports.MAGIC_ITEM_WAND_OF_WINTER = 'wand_of_winter'
module.exports.MAGIC_ITEM_WAND_OF_WONDER = 'wand_of_wonder'
module.exports.MAGIC_ITEM_WAND_SHEATH = 'wand_sheath'
module.exports.MAGIC_ITEM_WAVE = 'wave'
module.exports.MAGIC_ITEM_WEAPON_OF_WARNING = 'weapon_of_warning'
module.exports.MAGIC_ITEM_WEAPON_PLUS_1_2_OR_3 = 'weapon_plus_1_2_or_3'
module.exports.MAGIC_ITEM_WEIRD_TANK = 'weird_tank'
module.exports.MAGIC_ITEM_WELL_OF_MANY_WORLDS = 'well_of_many_worlds'
module.exports.MAGIC_ITEM_WHEEL_OF_WIND_AND_WATER = 'wheel_of_wind_and_water'
module.exports.MAGIC_ITEM_WHELM = 'whelm'
module.exports.MAGIC_ITEM_WINDVANE = 'windvane'
module.exports.MAGIC_ITEM_WIND_FAN = 'wind_fan'
module.exports.MAGIC_ITEM_WINGED_BOOTS = 'winged_boots'
module.exports.MAGIC_ITEM_WINGS_OF_FLYING = 'wings_of_flying'
module.exports.MAGIC_ITEM_WINGWEAR = 'wingwear'
