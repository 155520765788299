const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SPELL_PLANE_SHIFT,
  SPELL_SPEAK_WITH_DEAD,
} = require('./../../../../spellIdList')
const {
  CREATURE_BERBALANG,
  CREATURE_GITHYANKI_WARRIOR,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {LANG_ALL} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Бербаланг',
  nameEn: 'Berbalang',
  id: CREATURE_BERBALANG,
  description: [
    `Бербаланги скитаются среди окаменевших останков мёртвых богов, дрейфующих в Астральном Плане. Будучи помешанными на собирании секретов, как из богов, в которых они живут, так и из костей мёртвых существ, они призывают духов умерших существ и заставляют тех раскрыть свои секреты.`,
    {
      header: 'Говорящие с мертвецами',
      text: `Бербаланги предпочитают разговаривать лишь с мертвецами, в частности с духами, которых они призывают в надежде добыть больше секретов. Они записывают выслушанные истории на костях своих давно умерших рассказчиков, сохраняя таким образом информацию, собранную ими.`,
      source: {
        id: SOURCE_MTOF,
        page: 122,
      },
    },
    {
      header: 'Призрачный наблюдатель',
      text: `Стремление получить больше знаний побуждает бербалангов во всех их деяниях. Впрочем, хоть и бербаланги получают секреты мертвецов, они не против последить за живыми существами, с целью получения больших знаний. Бербаланг может создать призрачного двойника самого себя и отправить его собирать информацию в других измерениях, следя за местоположением других богов и их прислужниках. Пока бербаланг в теле своего двойника, находясь в другом пространственном измерении, его настоящее тело находится в подсознательном состоянии и не может ни защитить, ни прокормить себя. Поэтому, бербаланг использует двойника лишь на короткий промежуток времени, чтобы вовремя вернуться в сознание к своему телу.`,
      source: {
        id: SOURCE_MTOF,
        page: 122,
      },
    },
    {
      header: 'Потусторонние оракулы',
      text: `Знания, что копят бербаланги, делают их прекрасными источниками информации для более могущественных гуманоидов, путешествующих по Планам. Бербаланги игнорируют попрошаек (petitioners), только если те не расскажут бербалангам свои секреты, или не принесут кости весьма занятных существ. [Гитьянки](CREATURE:${CREATURE_GITHYANKI_WARRIOR}) смогли найти способ сосуществовать с бербалангами, и иногда используют этих существ, чтобы проследить за своими врагами, либо присмотреть за своими детьми на Материальном плане.`,
      source: {
        id: SOURCE_MTOF,
        page: 122,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 122,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 9,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_INT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ALL,
      butRarelySpeaks: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Призрачный двойник',
      comment: 'перезарядка после короткого или длинного отдыха',
      description: `Бербаланг бонусным действием может создать одного призрачного двойника самого себя на любом свободном пространстве на расстоянии 60 футов в пределах своей видимости. Пока существует двойник, бербаланг находится в подсознательном состоянии. Бербаланг может создать только одного двойника за раз. Двойник исчезает сразу же как бербаланг достигает 0 хитов, либо, когда бербаланг отстраняет его (не требует действия).

У двойника такие же характеристики и сведения, и поэтому вся накопленная информация двойника переходит обратно к оригиналу. Двойник наносит урон психическими атаками.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_INT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_SPEAK_WITH_DEAD,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          {
            id: SPELL_PLANE_SHIFT,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Бербаланг совершает две атаки: одну _Укусом_ и одну _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
