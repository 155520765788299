const {GEAR_DART} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {MAGIC_ITEM_SEEKER_DART} = require('./../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_SEEKER_DART,
  name: 'Дротик-искатель',
  nameEn: 'Seeker Dart',
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_DART,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Этот дротик украшен узорами в виде воздушных спиралей, расположенных по всей его длине.

Перед броском этого дротика Вы можете прошептать слово «ищи», и он начнёт лететь по направлению к выбранной Вами цели, которая находится в пределах 120 футов. Вы должны знать, как выглядит цель, хотя Вам не обязательно видеть её в момент броска. Если цель находится за пределами дальности дротика или к ней нет беспрепятственного пути, дротик падает на землю, а магия из него исчезает. В противном случае, стихия ветра мгновенно направляет дротик прямиком к цели. Дротик может проходить через отверстия шириной в 1 дюйм, и менять направление полёта, огибая углы.

Когда дротик достигает своей цели, та должна пройти испытание Ловкости СЛ 16, иначе получит колющий урон 1к4 и урон электричеством 3к4. После этого из дротика исчезает магия, и он становится обычным оружием.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PotA,
    page: 227,
  },
}
