const searchByFilterList = ({ name, value, filterList }) => {
  const updateValue = filterItem => filterItem.propName === name
    ? {
      ...filterItem,
      value
    }
    : filterItem

  return filterList.map(
    filter => filter.children
      ? {
        ...filter,
        children: filter.children.map(updateValue)
      }
      : updateValue(filter)
  )
};

export default searchByFilterList;
