const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1_4,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  dinoDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FLYBY,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_PTERANODON,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Птеранодон',
  nameEn: 'Pteranodon',
  id: CREATURE_PTERANODON,
  description: [
    `Эти крылатые рептилии с размахом крыльев 15–20 футов обычно ныряют в воду за мелкой добычей, но не гнушаются возможностью атаковать любое подходящее существо. У птеранодона нет зубов, однако есть острый клюв для той добычи, которую он не может проглотить за один раз.`,
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 97,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 10,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  cr: CR_1_4,
  featureList: [
    ABILITY_FLYBY,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
