const arrify = require('arrify')

const joinLastItem = (listRaw = [], joinerCommon = ', ', joinerLast = ' и ') => {
  const list = arrify(listRaw)

  switch (list.length) {
    case 0:
      return ''

    case 1:
      return list[0]

    default:
      return [
        list
          .slice(0, -1)
          .join(joinerCommon),
        list.slice(-1)[0],
      ]
        .join(joinerLast)
  }
}

module.exports = joinLastItem
