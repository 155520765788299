const {
    SOURCE_VGTM,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_5,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ABILITY_SHADOW_STEALTH,
  } = require('./../../../../creatureAbilityList'),
  {
    SKILL_ATHLETICS,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    LANG_COMMON,
    LANG_ITS_CREATOR,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_NECROTIC,
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_BANDERHOBB,
    CREATURE_NIGHT_HAG,
    CREATURE_OGRE,
    CREATURE_TOAD,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Бандерхобб',
  nameEn: 'Banderhobb',
  id: CREATURE_BANDERHOBB,
  description: [
    {
      header: `Бандерхобб`,
      text: `Бандерхобб — гибрид тени и плоти. Благодаря тёмной магии эти ингредиенты обретают форму огромной мерзкой человекоподобной двуногой [жабы](CREATURE:${CREATURE_TOAD}). В этой форме бандерхоббы временно служат своему создателю в качестве головореза, вора и похитителя.`,
      source: {
        id: SOURCE_VGTM,
        page: 124,
      },
    },
    {
      header: `Рождённый каргами`,
      text: `В ранние дни мировой истории шабаш [ночных карг](CREATURE:${CREATURE_NIGHT_HAG}) придумал ритуал, создавший первого бандерхобба. Карга, знающая ритуал, может научить ему за подходящую цену. Некоторые другие тёмные феи и могущественные исчадия также знают этот процесс, как и несколько смертных магов. Инструкции могут также быть найдены в книге, посвящённой извращенному волшебству.`,
      source: {
        id: SOURCE_VGTM,
        page: 124,
      },
    },
    {
      header: `Бесшумные и смертоносные`,
      text: `Когда ритуал создания бандерхобба завершен, плоть, дух и тень соединяются в существо размером с [огра](CREATURE:${CREATURE_OGRE}). Только что сформировавшийся монстр имеет длинные и тонкие конечности, в которых скрывается огромная сила. В его широкой пасти длинный язык и ряд клыков, которыми бандерхобб хватает и проглатывает существо или предмет, который он собирается украсть. Несмотря на размеры, бандерхобб производит мало шума, перемещаясь подобно тени, из которой он состоит. Бандерхобб не может говорить, но понимает приказы, данные ему создателем и общается с сородичами при помощи телепатии.`,
      source: {
        id: SOURCE_VGTM,
        page: 124,
      },
    },
    {
      header: `Агенты зла`,
      text: `Во время своего недолгого существования бандерхоббы пытаются выполнять то, ради чего были рождены. Он доводит свою миссию до конца, не беспокоясь о вреде, который он приносит или которому сам подвергнется. Его единственное желание — служить и добиться успеха. Бандерхобб, которому поручено разыскать цель, становится особенно эффективен, если ему предоставить локон волос, личную вещь или другой объект, связанный с разыскиваемым. Владение такой вещью позволяет ему ощущать местоположение существа на расстоянии вплоть до мили.

Бандерхобб выполняет свои обязанности до тех пор, пока не исчезнет. Когда это произойдет, обычно спустя несколько дней после рождения, он оставляет после себя только мазутоподобную жижу и обрывки теней. Легенды рассказывают о тёмной башне в Царстве Теней, где тени иногда вновь формируются в бандерхобба, который начинает бродить вокруг.`,
      source: {
        id: SOURCE_VGTM,
        page: 124,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 124,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 20,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: [
        LANG_COMMON,
        LANG_ITS_CREATOR,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Резонансная связь',
      description: `Если у ★СУЩЕСТВО★ среди имущества есть хотя бы крошечный кусочек существа или предмета, например клок волос или щепка дерева, ★он★ знает наиболее прямой путь к этому существу или предмету, если те находятся в пределах 1 мили от ★него★.`,
    },
    ABILITY_SHADOW_STEALTH,
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Цель становится схваченной (СЛ высвобождения 15) если размер существа Большой или меньше. Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может использовать атаки _Укусом_ и _Языком_ по другому существу.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 5,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Язык',
      description: `Цель должна пройти испытание Силы СЛ 15. При провале цель притягивается в пространство в пределах 5 футов от ★СУЩЕСТВО★, и ★он★ сможет совершить по ней атаку _Укусом_ бонусным действием.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 15,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 3,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
    {
      name: 'Проглатывание',
      description: `★СУЩЕСТВО★ совершает атаку _Укусом_ против схваченного им существа Среднего или менее размера. Если атака успешна, существо проглатывается ★им★, и захват оканчивается. Проглоченное существо Ослеплено и Обездвижено, получает полное укрытие от атак и прочих эффектов извне ★СУЩЕСТВО★, а также получает некротический урон 10 (3к6) в начале каждого хода ★СУЩЕСТВО★. Существо, хиты которого таким образом опускаются до 0, перестаёт получать некротический урон и становится стабилизированным.

★СУЩЕСТВО★ может проглотить не более одного существа одновременно. Пока ★СУЩЕСТВО★ ★Дееспособен★, ★он★ может изрыгнуть существо в любой момент (действия не требуется) в свободное пространство в пределах 5 футов. Если ★СУЩЕСТВО★ умирает, ★он★ изрыгает существо вышеописанным образом.`,
    },
    {
      name: 'Шаг тени',
      description: `★СУЩЕСТВО★ магически телепортируется на расстояние до 30 футов в видимое ★им★ свободное пространство, находящееся в области тусклого освещения или в темноте. Перед телепортацией или после неё, ★он★ может совершить атаку _Языком_ или _Укусом_.`,
    },
  ],
}
