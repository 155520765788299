const {aligmentCollection} = require('./../../../../../aligmentList')
const {GEAR_LONGSWORD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    alignmentId,
    gemName,
    id,
    name,
    nameEn,
  }
) => {
  const {instrumental, nominative} = aligmentCollection[alignmentId].name[GENDER_MALE]

  return {
    id,
    name,
    nameAlt: `Меч ответа: ${name}`,
    nameEn,
    nameEnAlt: `Sword of Answering: ${nameEn}`,
    type: MGC_TYPE_WEAPON,
    gearType: GEAR_LONGSWORD,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    attunementComment: `существом с ${instrumental} мировоззрением`,
    description: `_${name}_ — **${nominative}** [длинный меч](GEAR:${GEAR_LONGSWORD}), навершие которого украшает **${gemName}**.

Вы получаете бонус +3 к броскам атаки и урона, совершаемым этим мечом. Кроме того, пока Вы держите _${name}_, Вы можете реакцией совершать им одну рукопашную атаку по существу, находящемуся в пределах Вашей досягаемости, которое причиняет Вам урон. Вы совершаете этот бросок атаки с преимуществом, и урон, причиняемый этой особой атакой, игнорирует все сопротивления к урону и иммунитеты, которые могут быть у цели.`,
    genderId: GENDER_MALE,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 185,
    },
  }
}

module.exports = generator
