const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PC_RACE_ELF,
  PC_RACE_HALF_ELF,
  PC_RACE_HUMAN,
} = require('./../../../../pcRaceIdList')
const {
  ABILITY_DIVE_ATTACK,
  ABILITY_FLYBY,
  ABILITY_KEEN_SIGHT_AND_SMELL,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_COMMON,
  LANG_ELVEN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_PERYTON} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PC_CLASS_BARD} = require('./../../../../pcClassIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Перитон',
  nameEn: 'Peryton',
  id: CREATURE_PERYTON,
  description: [
    {
      header: 'Перитоны',
      text: `Хотя это плотоядное чудовище может питаться любыми существами, оно предпочитает гуманоидов, особенно [эльфов](PC_RACE:${PC_RACE_ELF}), [полуэльфов](PC_RACE:${PC_RACE_HALF_ELF}) и [людей](PC_RACE:${PC_RACE_HUMAN}). Когда перитон убивает гуманоида, он вырывает сердце у своей жертвы и относит его в гнездо для поедания.

Перитон — это причудливое существо, представляющее собою смесь крыльев и тела хищной птицы с головой оленя. Странной особенностью является тень существа: она не соответствует физическому облику его тела, но выглядит как гуманоид. Мудрецы утверждают, что первыми перитонами были [люди](PC_RACE:${PC_RACE_HUMAN}), изменённые не то отвратительным проклятьем, не то магическим экспериментом. Но [барды](PC_CLASS:${PC_CLASS_BARD}) повествуют о другой истории, о мужчине, неверность которого вынудила его жену вырезать сердце у более красивой и молодой соперницы и пожрать его в ритуале, призванном вернуть расположение мужа навсегда. Ритуал удался, но только до тех пор, пока злодеяние женщины не было раскрыто. Она была повешена за своё преступление, но магия, сохранившаяся от нечистого ритуала, превратила падальщиков, слетевшихся на её труп, в первых перитонов.`,
      source: {
        id: SOURCE_MM,
        page: 234,
      },
    },
    {
      header: 'Неестественный голод',
      text: `Репродуктивный цикл перитона зависит от сердца недавно убитого гуманоида. Орган должен быть поглощён самкой перитона, и только после этого она сможет размножаться. Когда сердце поглощено, тень перитона на время изменится, отобразив настоящее чудовищное тело.

При нападении на гуманоида перитон целеустремлён и безжалостен, он будет атаковать до тех пор, пока не погибнет сам или его жертва. Если перитон каким-либо способом отогнан, он будет следить за жертвой издалека, атакуя при появившейся возможности.`,
      source: {
        id: SOURCE_MM,
        page: 234,
      },
    },
    {
      header: 'Проклятье гор',
      text: `Перитоны устраивают насесты на вершинах горных хребтов и логова в высокорасположенных пещерах. Они охотятся на существ, живущих и блуждающих в долинах у подножия гор, а путешественникам на одиноких горных тропах нужно научиться посматривать на небеса. Так как обычное оружие не действенно против перитонов, горные народы предпочитают любыми способами избегать с ними встречи.

Поселения привлекательны для перитонов как возобновляемый источник пищи. Это вынуждает советы поселений или местную знать нанимать искателей приключений для устранения гнездовий перитонов.`,
      source: {
        id: SOURCE_MM,
        page: 234,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 234,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  languageList: [
    {
      id: [
        LANG_COMMON,
        LANG_ELVEN,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_DIVE_ATTACK,
      damageStr: '9 (2к8)',
    },
    ABILITY_FLYBY,
    ABILITY_KEEN_SIGHT_AND_SMELL,
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает одну атаку _Боданием_, и одну атаку _Когтем_.`,
    },
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
