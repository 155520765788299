const {PC_CLASS_MONK} = require('./../../pcClassIdList')
const {
  SOURCE_FTD,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2016_12_12_MONK_MONASTIC_TRADITIONS,
  SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES,
  SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
  SOURCE_UA_2019_08_15_WILD_ASTRAL,
  SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
  SOURCE_UA_2020_10_26_SUBCLASSES_PART_5,
  SOURCE_XGTE,
} = require('./../../sourceList')
const {
  GOD_ELDATH,
  GOD_LATHANDER,
  GOD_SELUNE,
  GOD_SUNE,
} = require('./../../godIdList')

const {
  PC_SUBCLASS_MONK_WAY_OF_ASCENDANT_DRAGON,
  PC_SUBCLASS_MONK_WAY_OF_ASCENDANT_DRAGON_2020_10_26,
  PC_SUBCLASS_MONK_WAY_OF_MERCY,
  PC_SUBCLASS_MONK_WAY_OF_MERCY_2020_01_14,
  PC_SUBCLASS_MONK_WAY_OF_SHADOW,
  PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF,
  PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF_2019_08_15,
  PC_SUBCLASS_MONK_WAY_OF_THE_DRUNKEN_MASTER,
  PC_SUBCLASS_MONK_WAY_OF_THE_DRUNKEN_MASTER_2017_03_27,
  PC_SUBCLASS_MONK_WAY_OF_THE_FOUR_ELEMENTS,
  PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI,
  PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI_2016_12_12,
  PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI_2017_05_01,
  PC_SUBCLASS_MONK_WAY_OF_THE_LONG_DEATH,
  PC_SUBCLASS_MONK_WAY_OF_THE_OPEN_HAND,
  PC_SUBCLASS_MONK_WAY_OF_THE_SUN_SOUL,
  PC_SUBCLASS_MONK_WAY_OF_TRANQUILITY_2016_12_12,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_MONK_WAY_OF_MERCY,
    pcClassId: PC_CLASS_MONK,
    isReady: true,
    name: 'Путь милосердия',
    nameEn: 'Way of Mercy',
    description: `Монахи пути милосердия учатся манипулировать жизненной силой других, чтобы помочь нуждающимся.

Они — странствующие целители бедных и больных. Тем же, кому они не могут помочь, они приносят быструю милосердную смерть.

Те, кто следуют Пути милосердия, могут быть членами религиозного ордена, помогая нуждающимся и делая мрачные выборы, основанные на реальности, а не на идеалах. Некоторые из них могут быть целителями с мягким голосом, любимыми их общинами, в то время как другие могут быть замаскированными носителями мрачное милосердие.

Монахи этого пути обычно надевают мантии с глубокими капюшонами и часто закрывают лица масками, представляя себя безликими служителя жизни и смерти.`,
    note: {
      text: `Чумной доктор. Некоторые образы никогда не выходят из моды.`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 70,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_MERCY_2020_01_14,
    pcClassId: PC_CLASS_MONK,
    releasedAs: PC_SUBCLASS_MONK_WAY_OF_MERCY,
    isReady: false,
    name: 'Путь милосердия',
    nameEn: 'Way of Mercy',
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_SHADOW,
    pcClassId: PC_CLASS_MONK,
    isReady: true,
    name: 'Путь тени',
    nameEn: 'Way of Shadow',
    description: `Монахи, следующие по Пути тени, ценят хитрость и уловки.

Эти монахи, которых называют ниндзями или теневыми танцорами, служат шпионами и наёмными убийцами. Иногда члены такого монастыря, являясь одной семьёй, образуют кланы и хранят в тайне своё искусство и цели. Другие монастыри больше похожи на гильдии воров, предоставляя свои услуги знати, богатым купцам, и всем, кто может заплатить.

Вне зависимости от методов, руководители этих монастырей ожидают беспрекословного подчинения от своих учеников.`,
    source: {
      id: SOURCE_PHB,
      page: 80,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF_2019_08_15,
    pcClassId: PC_CLASS_MONK,
    releasedAs: PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF,
    isReady: true,
    name: 'Путь астрального Я',
    nameEn: 'Way of the Astral Self',
    description: `Монахи Пути астрального Я ведут внутреннюю борьбу со своим Ци. Они видят свою мистическую силу как воплощение своей истинной формы, астрального «Я». 

Эта форма может быть как силой созидания, так и разрушения. Одни монастыри учат послушников сдерживать свою суть, другие же — принимать её.

# Формы Вашего астрального Я

Астральное Я выглядит как полупрозрачное воплощение духа и души монаха. Форма астрального Я отражает разум проявляющего его монаха. 

Ваше астральное Я может быть гуманоидным рыцарем с лицом в шлеме, с большими мускулистыми руками, или же оно может быть золотой металлической фигурой с тонкими руками, как у модрона. При выборе этого пути учитывайте особенности Вашего монаха. Одержим ли он чем-то? Движимы ли Вы справедливостью или эгоистичным желанием? Любая из этих мотиваций может влиять на форму Вашего астрального Я.`,
    source: {
      id: SOURCE_UA_2019_08_15_WILD_ASTRAL,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF,
    pcClassId: PC_CLASS_MONK,
    isReady: true,
    name: 'Путь астрального Я',
    nameEn: 'Way of the Astral Self',
    description: [
      `Монахи Пути астрального Я считают своё тело иллюзией. Они рассматривают своё Ци как представление собственной истинной формы — астрального Я.

Астральное Я может быть как силой порядка, так и хаоса. Некоторые монастыри тренируют послушников использовать свои силы для защиты слабых. Другие же обучают проявлять истинное Я для служения сильным.`,
      {
        header: `Формы Вашего астрального Я`,
        text: `Астральное Я выглядит как полупрозрачное воплощение души монаха. Поэтому оно может отражать аспекты происхождения монаха, его идеалы, привязанности или слабости.

Астральное Я совершенно необязательно должно выглядеть схоже с самим монахом. Например, астральное Я худого человека может напоминать могучего минотавра — силу, которую он ощущает внутри. А орк-монах может проявлять астральное Я в виде тонких рук и изящного лика, проявляя мягкую красоту своей орочьей души. Каждое астральное Я уникально и некоторые монахи этого пути известны именно своим астральным Я, а не физической формой.

Выбирая этот путь, учтите особенности, определяющие Вашего монаха. Одержимы ли Вы чем-то? Движимы ли Вы справедливостью или же эгоистичными порывами? Любая из этих мотиваций может проявляться в форме Вашего астрального Я.`,
      },
    ],
    note: {
      text: `Себе на заметку: создать заклинание, которое позволяет избивать людей собственным призраком`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 72,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_DRUNKEN_MASTER,
    pcClassId: PC_CLASS_MONK,
    isReady: true,
    name: 'Путь пьяного мастера',
    nameEn: 'Way of the Drunken Master',
    description: `Путь пьяного мастера учит студентов двигаться отрывисто, непредсказуемо, как это делают пьяницы. Пьяный мастер качается, шатаясь на подгибающихся ногах, пытаясь казаться неумелым бойцом, с которым на деле оказывается нелегко справиться. Беспорядочные спотыкания пьяного мастера являются ничем иным, как прекрасно исполненным танцем блокирующих ударов, парирований, выпадов, атак и отступлений.

Пьяный мастер часто любит прикидываться дурачком, чтобы принести радость горюющим или чтобы продемонстрировать покорность высокомерным, но вступив в битву, пьяный мастер может быть безумным виртуозным врагом.`,
    source: {
      id: SOURCE_XGTE,
      page: 44,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_DRUNKEN_MASTER_2017_03_27,
    releasedAs: PC_SUBCLASS_MONK_WAY_OF_THE_DRUNKEN_MASTER,
    pcClassId: PC_CLASS_MONK,
    isReady: false,
    name: 'Путь пьяного мастера',
    nameEn: 'Way of the Drunken Master',
    source: {
      id: SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_FOUR_ELEMENTS,
    pcClassId: PC_CLASS_MONK,
    isReady: true,
    name: 'Путь четырёх стихий',
    nameEn: 'Way of the Four Elements',
    description: `Вы следуете монастырской традиции, учащей управлять четырьмя стихиями. Сфокусировав свою Ци, Вы можете настроиться на силы мироздания и подчинить четыре стихии своей воле, используя их как продолжение собственного тела.

Некоторые члены этой традиции связывают себя с одним элементом, другие же соединяют их. Многие монахи этой традиции покрывают свои тела татуировками, отражающими их энергию Ци. Обычно это извивающиеся драконы, но встречаются также фениксы, рыбы, растения, горы и пенящиеся волны.`,
    source: {
      id: SOURCE_PHB,
      page: 81,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI,
    pcClassId: PC_CLASS_MONK,
    isReady: true,
    name: 'Путь кенсэя',
    nameEn: 'Way of the Kensei',
    description: `Монахи, следующие Пути кенсэя, беспрестанно улучшают свои навыки владения оружием, достигая того, что оружие становится продолжением их тела. Основанная на мастерстве боя мечом, традиция расширилась и включает в себя множество разнообразного оружия.

Для кенсэя оружие — то же самое, что кисточка для художника или перо и чернила для писателя. Меч ли, лук ли — всё это лишь инструменты для выражения красоты и изящности боевых искусств. Мастерство делает кенсэя несравненным воителем, но при этом требует изнурительных тренировок, практики и обучения.`,
    note: {
      text: `Почему самурай пересек дорогу?… Теперь спроси „Почему?“… Хорошо, вот тут шутка: Я кенсэй. Понял? Что? Почему ты не смеёшься? Шутка просто отличная, а подача великолепна. Может ты не понял, потому что тупой.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 43,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI_2016_12_12,
    pcClassId: PC_CLASS_MONK,
    releasedAs: PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI,
    isReady: false,
    name: 'Путь кенсэя',
    nameEn: 'Way of the Kensei',
    source: {
      id: SOURCE_UA_2016_12_12_MONK_MONASTIC_TRADITIONS,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI_2017_05_01,
    pcClassId: PC_CLASS_MONK,
    releasedAs: PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI,
    isReady: false,
    name: 'Путь кенсэя',
    nameEn: 'Way of the Kensei',
    source: {
      id: SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
      page: 4,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_LONG_DEATH,
    pcClassId: PC_CLASS_MONK,
    isReady: true,
    name: 'Путь долгой смерти',
    nameEn: 'Way of the Long Death',
    description: [
      `Монахи Пути долгой смерти одержимы смыслом и процессом смерти.

Они ловят существ и готовят тщательно продуманные эксперименты, чтобы увидеть, записать и осмыслить моменты их гибели. Потом они применяют эти знания к боевым искусствам, создавая смертельно опасный боевой стиль.`,
      {
        header: 'Монахи долгой смерти',
        text: `Последователи Пути долгой смерти поклоняются аспекту смерти больше, чем любому из богов смерти.

Они ищут секреты жизни, изучая саму смерть. Их волнует само состояние смерти, а не то, что находится за её пределами. Посмертие их мало интересует. Их монастыри заполнены разлагающимися, мёртвыми и умирающими животными и растительными образцами, которые монахи изучают с холодным интересом. Они часто покупают редкие образцы, те, которые не могут достать сами, у искателей приключений и торговцев.

Но такие исследования лишь часть жизни монахов: они стремятся понять сам процесс смерти, особенно у разумных существ, поэтому охотно привечают больных и умирающих, чтобы пронаблюдать и описать их смерти. Если кто-то из несчастных попросит смерти, для освобождения его от боли, монахи удовлетворят просьбу. Они рассматривают смерть как дар, который они дают тем, кто к нему готов. Определение этой готовности сильно разнится от секты к секте (и даже от монаха к монаху).

Моральные терзания монахов не мучают, смерть для них самая естественная вещь в мире, и, следуя их принципам, смерть же и является самым священным опытом на которое может надеяться живое существо. По этой же причине сами монахи смерти не боятся.

Большинство членов ордена — либо учёные разделяющие интерес к смерти и её процессу, либо служители одного из богов смерти. Некоторые монахи даже считают себя пророками, чьи работы приведут к лучшему будущему для всего Фаэруна. Когда смерть будет постигнута, она может быть использована как инструмент для улучшения всего сущего, так и для самопознания.`,
        source: {
          id: SOURCE_SCAG,
          page: 131,
        },
      },
    ],
    source: {
      id: SOURCE_SCAG,
      page: 131,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_OPEN_HAND,
    pcClassId: PC_CLASS_MONK,
    isReady: true,
    name: 'Путь открытой ладони',
    nameEn: 'Way of the Open Hand',
    description: [
  `Монахи, избравшие этот путь, являются непревзойдёнными мастерами боевых искусств, вне зависимости от того, вооружены ли они.

Они изучают техники, позволяющие толкать и опрокидывать противника, а также лечить себя, манипулируя энергией ци. Используемые ими медитации позволяют защититься от получаемого вреда.`,
      {
        header: 'Путь гибкости',
        text: `Орден монахов [Эльдат](GOD:${GOD_ELDATH}) следует Пути гибкости, иногда называемому Братьями и Сёстрами Открытой Ладони. Они охраняют многие священные места, где живут священники, и путешествуют по деревням, собирая информацию для изолированных рощ и крепостей. Они никогда не провоцируют насилие, но смертельно опасны в защите себя, своих целей и святынь.`,
        source: {
          id: SOURCE_SCAG,
          page: 131,
        },
      },
    ],
    source: [
      {
        id: SOURCE_PHB,
        page: 80,
      },
      {
        id: SOURCE_SRD,
        page: 28,
      },
    ],
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_THE_SUN_SOUL,
    pcClassId: PC_CLASS_MONK,
    isReady: true,
    name: 'Путь солнечной души',
    nameEn: 'Way of the Sun Soul',
    description: [
      `Монахи Пути солнечной души учатся преображать собственную жизненную энергию в обжигающие лучи света. Они учат, что медитация может открыть неукротимый свет, скрытый в душе каждого живого существа.`,
      {
        header: 'Солнечная душа',
        text: `Монахи солнечной души следуют традиции, которая, как говорят, берёт начало в древней империи Нетерил. Согласно их философии, всё живое внутри содержит частицу мистической сущности солнца, как тело всегда отбрасывает тень, так и дух имеет внутри свет. Братья и сёстры Ордена солнечной души стараются соединиться со «светом внутри» и проявить его в виде сверхъестественных сил и стойкости.

Для соединения с внутренним светом монахи солнечной души следуют строгому кодексу аскетических правил, называемому «Раскалённые заветы». Они стоят на трёх столпах:

# Стремитесь к физическому совершенству

Чтобы открыть путь проявлению солнечной души, следует стремиться к красоте тела. Здоровый образ жизни, чистота и отточенная физическая форма создают чистое окно, сквозь которое будет сиять свет.

# Стремитесь к духовной добродетели

Ищите в других свет, а не только тьму. Даруйте и не упускайте новый шанс проявить добродетель.

# Сияйте светом во тьме

Делитесь светом души с миром. Озарите тёмные углы своим присутствием и прогоните тени.

Сходство заповедей с учениями некоторых религий, давно обеспечило Ордену солнечной души связь с храмами и верующими трех богов: [Сьюни](GOD:${GOD_SUNE}), [Селунэ](GOD:${GOD_SELUNE}) и [Латандера](GOD:${GOD_LATHANDER}). Стремление к совершенству тела и нахождению скрытых добродетелей соотносится с учением [Сьюни](GOD:${GOD_SUNE}) о физической и духовной красоте. Последователи [Селунэ](GOD:${GOD_SELUNE}) находят в заветах стремление своей богини к сражению с тьмой и проявлению добродетели. И, конечно, [Латандер](GOD:${GOD_LATHANDER}) ассоциируется с солнцем, что роднит его с философией солнечной души, но для последователей [Латандера](GOD:${GOD_LATHANDER}) важнее то, что дарить новый шанс и самому его не упускать, выражает аспект [Латандера](GOD:${GOD_LATHANDER}) как нового начала, возрождения.`,
        source: {
          id: SOURCE_SCAG,
          page: 131,
        },
      },
    ],
    note: {
      text: `Так у тебя значит есть эта душа, но её нельзя увидеть или потрогать. А когда ты умираешь эта невидимая штука покидает твоё тело и отправляется в место, которое нельзя увидеть. И вы, народ, думаете, что это я сумасшедший?`,
      author: `Занатар`,
    },
    source: [
      {
        id: SOURCE_SCAG,
        page: 132,
      },
      {
        id: SOURCE_XGTE,
        page: 45,
      },
    ],
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_TRANQUILITY_2016_12_12,
    pcClassId: PC_CLASS_MONK,
    isReady: false,
    name: 'Путь спокойствия',
    nameEn: 'Way of Tranquility',
    source: {
      id: SOURCE_UA_2016_12_12_MONK_MONASTIC_TRADITIONS,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_ASCENDANT_DRAGON_2020_10_26,
    releasedAs: PC_SUBCLASS_MONK_WAY_OF_ASCENDANT_DRAGON,
    pcClassId: PC_CLASS_MONK,
    isReady: false,
    name: 'Путь взлетающего дракона',
    nameEn: 'Way of Ascendant Dragon',
    description: `Монахи, следующие Пути восходящего дракона, почитают силу и величие драконов. Они изменяют свою собственную ци так, чтобы она резонировала с драконьей мощью. Они направляют ци, усиливая боевое мастерство, паря в воздухе и поддерживая своих союзников.

Как последователь этой монашеской традиции, Вы решаете, как разблокировать силу драконов с помощью ци. Таблица происхождения восходящего дракона предлагает некоторые возможности для этого.`,
    source: {
      id: SOURCE_UA_2020_10_26_SUBCLASSES_PART_5,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_MONK_WAY_OF_ASCENDANT_DRAGON,
    pcClassId: PC_CLASS_MONK,
    isReady: false,
    name: 'Путь взлетающего дракона',
    nameEn: 'Way of Ascendant Dragon',
    source: {
      id: SOURCE_FTD,
    },
  },
]
