const {SOURCE_UA_2018_06_11_GIANT_SOUL} = require('./../../../sourceList')
const {PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11} = require('./../../../pcSubClassIdList')
const {
  SPELL_ARMOR_OF_AGATHYS,
  SPELL_BURNING_HANDS,
  SPELL_ENLARGE_REDUCE,
  SPELL_ENTANGLE,
  SPELL_FIRE_BOLT,
  SPELL_FLAMING_SPHERE,
  SPELL_FOG_CLOUD,
  SPELL_GUST_OF_WIND,
  SPELL_HEROISM,
  SPELL_HOLD_PERSON,
  SPELL_INVISIBILITY,
  SPELL_MINOR_ILLUSION,
  SPELL_RAY_OF_FROST,
  SPELL_RESISTANCE,
  SPELL_SHILLELAGH,
  SPELL_SHOCKING_GRASP,
  SPELL_SPIKE_GROWTH,
  SPELL_THUNDERWAVE,
} = require('./../../../spellIdList')
const {
  FEATURE_MARK_OF_THE_ORDNING_CLOUD,
  FEATURE_MARK_OF_THE_ORDNING_FIRE,
  FEATURE_MARK_OF_THE_ORDNING_FROST,
  FEATURE_MARK_OF_THE_ORDNING_HILL,
  FEATURE_MARK_OF_THE_ORDNING_STONE,
  FEATURE_MARK_OF_THE_ORDNING_STORM,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_MARK_OF_THE_ORDNING_CLOUD,
    name: `Метка Уложения: Облачный великан`,
    nameEn: `Mark of the Ordning: Cloud giant`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11,
    spellIdPickByLvl: {
      1: [
        SPELL_FOG_CLOUD,
        SPELL_MINOR_ILLUSION,
      ],
      3: SPELL_INVISIBILITY,
    },
    source: {
      id: SOURCE_UA_2018_06_11_GIANT_SOUL,
      page: 1,
    },
  },
  {
    id: FEATURE_MARK_OF_THE_ORDNING_FIRE,
    name: `Метка Уложения: Огненный великан`,
    nameEn: `Mark of the Ordning: Fire giant`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11,
    spellIdPickByLvl: {
      1: [
        SPELL_BURNING_HANDS,
        SPELL_FIRE_BOLT,
      ],
      3: SPELL_FLAMING_SPHERE,
    },
    source: {
      id: SOURCE_UA_2018_06_11_GIANT_SOUL,
      page: 1,
    },
  },
  {
    id: FEATURE_MARK_OF_THE_ORDNING_FROST,
    name: `Метка Уложения: Ледяной великан`,
    nameEn: `Mark of the Ordning: Frost giant`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11,
    spellIdPickByLvl: {
      1: [
        SPELL_ARMOR_OF_AGATHYS,
        SPELL_RAY_OF_FROST,
      ],
      3: SPELL_HOLD_PERSON,
    },
    source: {
      id: SOURCE_UA_2018_06_11_GIANT_SOUL,
      page: 1,
    },
  },
  {
    id: FEATURE_MARK_OF_THE_ORDNING_HILL,
    name: `Метка Уложения: Холмовой великан`,
    nameEn: `Mark of the Ordning: Hill giant`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11,
    spellIdPickByLvl: {
      1: [
        SPELL_HEROISM,
        SPELL_SHILLELAGH,
      ],
      3: SPELL_ENLARGE_REDUCE,
    },
    source: {
      id: SOURCE_UA_2018_06_11_GIANT_SOUL,
      page: 1,
    },
  },
  {
    id: FEATURE_MARK_OF_THE_ORDNING_STONE,
    name: `Метка Уложения: Каменный великан`,
    nameEn: `Mark of the Ordning: Stone giant`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11,
    spellIdPickByLvl: {
      1: [
        SPELL_ENTANGLE,
        SPELL_RESISTANCE,
      ],
      3: SPELL_SPIKE_GROWTH,
    },
    source: {
      id: SOURCE_UA_2018_06_11_GIANT_SOUL,
      page: 1,
    },
  },
  {
    id: FEATURE_MARK_OF_THE_ORDNING_STORM,
    name: `Метка Уложения: Штормовой великан`,
    nameEn: `Mark of the Ordning: Storm giant`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_SORCERER_GIANT_SOUL_2018_06_11,
    spellIdPickByLvl: {
      1: [
        SPELL_SHOCKING_GRASP,
        SPELL_THUNDERWAVE,
      ],
      3: SPELL_GUST_OF_WIND,
    },
    source: {
      id: SOURCE_UA_2018_06_11_GIANT_SOUL,
      page: 1,
    },
  },
  // {
  //   id: FEATURE_,
  //   name: ``,
  //   nameEn: ``,
  //   lvl: ,
  //   pcSubClassId: PC_SUBCLASS_,
  //   text: ``,
  //   spellIdPickByLvl: ,
  //   spellCircleLvl: ,
  //   spellIdList: SPELL_,
  //   source: {
  //     id: SOURCE_,
  //     page: ,
  //   },
  // },
]
