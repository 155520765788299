module.exports = [
  require('./darkling'),
  require('./darkling_elder'),
  require('./death_kiss'),
  require('./deep_rothe'),
  require('./deep_scion'),
  require('./deinonychus'),
  require('./devourer'),
  require('./dimetrodon'),
  require('./diviner'),
  require('./dolphin'),
  require('./draegloth'),
]
