const {CREATURE_FIRE_GIANT} = require('./../../creatureIdList')
const {
  GOD_SURTUR,
  GOD_THRYM,
} = require('./../../godIdList')
const {SOURCE_VGTM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Очищающий огонь Сурта',
    text: `[Сурт](GOD:${GOD_SURTUR}) — главное божество [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}), как полагают, был рожден вместе с [Тримом](GOD:${GOD_THRYM}). Каждый из близнецов пытался первым закричать, первым научиться ходить и первым заговорить, и они соревнуются друг с другом до сих пор. Часто в легендах эти соревнования носят кровопролитный характер, но в некоторых сказаниях братья действуют бок о бок в грандиозных приключениях. [Сурт](GOD:${GOD_SURTUR}) показан как более умный из двоих, и [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) подражают его непревзойденному мастерству в создании вещей.

В мире [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}), огонь — сила, которая выжигает несовершенства в материале и оставляет только то, что достаточно сильно, чтобы выдержать высокую температуру, например, лучшая сталь из горна. Когда огонь находится под контролем, это самый мощный инструмент великанов; когда он бушует бесконтрольно, он может уничтожать леса и опустошать города.

Из-за разрушительной силы огня, поклонение [Сурту](GOD:${GOD_SURTUR}) окрашено апокалиптическими красками. Некоторые наблюдатели подозревают, что жрецы [Сурта](GOD:${GOD_SURTUR}) содержат подпольные мастерские и арсеналы в рамках подготовки к финальной всеобщей битве, что решит судьбу мира. Если подозрения верны, эти места искусно скрыты и держатся в секрете даже от большинства [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}).`,
    source: {
      id: SOURCE_VGTM,
      page: 27,
    },
  },
]
