const {
  MAGIC_EVOCATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  ATTACK_RANGE_SPELL,
} = require('./../../../../../attackTypeList')
const {
  DAMAGE_ACID,
} = require('./../../../../../damageTypeList')
const {
  SPELL_MELF_S_ACID_ARROW,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_MELF_S_ACID_ARROW,
  name: 'Мельфова кислотная стрела',
  nameEn: 'Melf’s Acid Arrow',
  nameEnAlt: 'Acid Arrow',
  description: `К цели, находящейся в пределах дистанции, устремляется мерцающая зелёная стрела, разлетающаяся брызгами кислоты. Совершите по цели дальнобойную атаку заклинанием. При попадании цель получает единовременно урон кислотой 4к4 и урон кислотой 2к4 в конце своего следующего хода. При промахе стрела немного задевает цель, причиняет половину единовременного урона и в конце следующего хода цель урона не получает.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, урон (и единовременный и последующий) увеличивается на 1к4 за каждый круг ячейки выше второго.`,
  lvl: 2,
  magicSchoolId: MAGIC_EVOCATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `порошок из листьев ревеня и желудок гадюки`,
  effect: {
    attackType: ATTACK_RANGE_SPELL,
    damage: {
      type: DAMAGE_ACID,
      halfOnSuccess: true,
      diceCount: 4,
      diceType: 4,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 238,
    },
    {
      id: SOURCE_SRD,
      page: 114,
    },
  ],
}
