const {magicItemCollection} = require('./../constants/magicItemList')

const generateMagicItemTableText = ({ header, idList }) => idList
  .map(
    (id) => {
      const { name, attunement } = magicItemCollection[id]
      const attunementText = attunement
        ? 'Да'
        : 'Нет'

      return `|[${name}](MAGIC_ITEM:${id})|${attunementText}|`
    }
  )
  .sort()
  .reduce(
    (text, row) => `${text}
${row}`,
    `
## ${header}

| Магический предмет | Настройка |
|--------------------|-----------|`
  )

module.exports = generateMagicItemTableText
