const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_GRELL,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_GRELL,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Грелльский',
    genitive: 'Грелльского',
    instrumental: 'Грелльским',
    prepositional: 'Грелльском',
  },
  nameEn: 'Grell',
  isReady: false,
  isRealLang: true,
}
