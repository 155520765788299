const {
    SOURCE_AI,
  } = require('./../../../../sourceList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_CONSTRUCT,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_ACID,
    DAMAGE_BLUDGEONING,
    DAMAGE_NONMAGICAL_ATTACKS,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  } = require('./../../../../damageTypeList'),
  {
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_KEG_ROBOT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Кегобот',
  nameEn: 'Keg Robot',
  id: CREATURE_KEG_ROBOT,
  description: `Кегобот — это прочный конструкт из дерева и металла, смутно напоминающий дварфа с кегом вместо туловища и рогатым шлемом на голове. Это очередное достижение отдела жестоких естественно скрытых тайн (ЖЕСТь) Корпорации Приобретений. Эти устройства имеют иного практических целей, в том числе возможность наполнения разнообразными веществами на банкетах и вечеринках. Конкретные модели называют по их основной функции:  «амбулаторный ферментатор», «мобильный алхимический раздатчик» и т. п. Но их самое популярное использование в качестве мобильных пивных бочек прочно ввело в обиход название «кегобот».

Кегобот может содержать внутри до трёх различных видов жидкости: от воды и напитков до алхимических веществ и лампового масла. Соседние отсеки иногда могут протекать, обеспечивая напиткам уникальный, но в основном безопасный аромат. Эти конструкты обладают простеньким интеллектом, который позволяет им действовать независимо и отличать друзей от врагов, и иногда проявлять грубое чувство юмора, которое никогда полностью никем не было объяснено.`,
  note: `Это пиво очень вкусное. А кран обязательно должен быть именно в этом месте?`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_AI,
    page: 213,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGICAL_ATTACKS,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_COMMON,
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Настраиваемый отсек',
      description: `★СУЩЕСТВО★ может внутри себя до трёх типов жидкости общим объёмом до 12 галлонов. Заполненный ★СУЩЕСТВО★ может совершать одну атаку жидкостью в ход, тратя на это один галлон жидкости, которую нужно будет восполнить позже. Восполнение 1 галлона жидкости занимает 2 раунда. Внутри ★СУЩЕСТВО★ могут содержаться другие жидкости, что изменит ★его★ атаки.`,
    },
  ],
  actionList: [
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Струя кислоты',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 5,
        range: {
          normal: 20,
          max: 40,
        },
        hit: {
          type: DAMAGE_ACID,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Пивной душ',
      description: `★СУЩЕСТВО★ распыляет неестественно крепкое пиво 15-футовым конусом или 30-футовой линией шириной в 5 футов. Все существа в этой области должны пройти испытание Телосложения СЛ 13, или стать Отравленными. Пока существо Отравлено таким образом, его скорость уменьшена вдвое из-за крепкости распылённого напитка. Отравленное существо может повторять испытание в конце каждого своего хода, завершая эффект при успехе.
        
Кроме того, _Пивной душ_ тушит любой огонь или открытое пламя в области действия.`,
    },
    {
      name: 'Раскалённое масло',
      restore: 5,
      description: `★СУЩЕСТВО★ распыляет раскалённое масло 15-футовым конусом или 30-футовой линией шириной в 5 футов. Все существа в этой области должны пройти испытание Телосложения СЛ 13, или получить 7 (1к8 + 3) урона огнём и Распластаться. При успехе существо получает только половину урона и не Распластывается.

Если существо, покрытое раскалённым маслом, получит огненный урон прежде чем масло высохнет (в течение 1 минуты), то оно получит дополнительно 1к6 урона огнём и масло сгорает. Если масло, оставшееся в области распыления, загорается, оно горит 1к4 раунда и наносит 3 (1к6) урона огнём всем существам, впервые на своём ходу входящим в эту область или заканчивающим там ход.`,
    },
  ],
}
