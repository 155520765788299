const {
    ABILITY_SHADOW_STEALTH,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_DESPAIR_SHADOWSPAWN_SPIRIT,
    CREATURE_FEAR_SHADOWSPAWN_SPIRIT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух тени страха',
  nameEn: 'Fear Shadowspawn Spirit',
  id: CREATURE_FEAR_SHADOWSPAWN_SPIRIT,
  parentId: CREATURE_DESPAIR_SHADOWSPAWN_SPIRIT,
  featureList: [
    ABILITY_SHADOW_STEALTH,
  ],
}
