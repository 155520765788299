const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ABILITY_IMMUTABLE_FORM,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_SIEGE_MONSTER,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {chardalynDescriptionList} = require('./../../../../textCommonParts')
const {CR_11} = require('./../../../../crList')
const {CREATURE_CHARDALYN_DRAGON} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {LANG_ITS_CREATOR} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_ERFTLW} = require('./../../../../sourceList')

module.exports = {
  name: 'Чардалиновый дракон',
  nameEn: 'Chardalyn Dragon',
  id: CREATURE_CHARDALYN_DRAGON,
  description: [
    `Этот конструкт в форме дракона сделан из кусков чардалина. Единственные части дракона, не выкованные из чардалина — это крылья, из тугой, как резина, масляной плёнки и его сердце — сфера пульсирующей лучистой энергии. Пропитавшая чардалин демоническая магия наполняет конструкта злобой, приносящей удовлетворение от творимых им ужасов. Этот эффект простирается даже вовне, подчиняя существ, оказавшихся рядом со злобным конструктом. Подчинённые драконом существа направляют все свои усилия на помощь дракону причинять страдания другим.

Чардалиновый дракон служит тиранической воле своего злого создателя и его неустанная верность сильнее даже его собственного инстинкта самосохранения. Поэтому, выполняя желания мастера, дракон может даже уничтожить сам себя, если это будет необходимо. Дракону нравится рвать врагов на куски, выбивать двери и разрушать здания. Если он не может достать до врагов, то выпускает из печеобразной пасти в их сторону поток разрушительного излучения.`,
    ...chardalynDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_ERFTLW,
    page: 282,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 90,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 19,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
  ],
  resistanceList: [
    DAMAGE_RADIANT,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ITS_CREATOR,
  ],
  cr: CR_11,
  featureList: [
    ABILITY_IMMUTABLE_FORM,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SIEGE_MONSTER,
    {
      name: 'Необычная природа',
      description: `★СУЩЕСТВО★ не нужны воздух, еда, питьё или сон и ★он★ не получает преимуществ от окончания короткого или длинного отдыха.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ использует _Злобное присутствие_. После этого ★он★ совершает три атаки: две —_Когтями_ и одну —_Хвостом_. Если дракон не летает, он может также совершить одну атаку _Крыльями_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Крылья',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Злобное присутствие',
      description: `Все существа с интеллектом 4 и выше, находящиеся в пределах 30 футов от ★СУЩЕСТВО★, должны пройти испытание Мудрости СЛ 16, иначе станут Очарованными ★им★ на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. Если испытание существа было успешным, или эффект на нём окончился, оно получает иммунитет к _Злобному присутствию_ ★этого★ ★СУЩЕСТВО★ на следующие 24 часа.

Существа, Очарованные таким способом, нацеливаются на другое существо или предмет, мысленно выбранные ★СУЩЕСТВО★, и должны на каждом своём ходу передвигаться как можно ближе к этой цели и использовать своё действие чтобы совершать по ней рукопашные атаки. Если дракон не выбирает цель, Очарованные существа действуют как обычно на этом ходу.`,
    },
    {
      name: 'Лучистое дыхание',
      restore: 5,
      description: `★СУЩЕСТВО★ выдыхает поток лучистой энергии 120-футовой линией шириной 5 футов. Все существа в этой области должны пройти испытание Ловкости СЛ 16, получая урон излучением 31 (7к8) при провале, или половину этого урона при успехе.`,
    },
  ],
}
