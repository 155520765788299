const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_INEVITABLE,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_FORCE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  ABILITY_IMMUTABLE_FORM,
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_25} = require('./../../../../crList')
const {CREATURE_MARUT} = require('./../../../../creatureIdList')
const {LANG_ALL} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPELL_PLANE_SHIFT} = require('./../../../../spellIdList')

module.exports = {
  name: 'Марут',
  nameEn: 'Marut',
  id: CREATURE_MARUT,
  description: [
    `Почти неостановимые сущности, которые служат лишь одной цели: они обеспечивают соблюдение договора в Зале Соглашений в городе Сигил. Прайм, лидер модронов, создал марутов и прочих существ, чтобы они обеспечивали порядок сделок между планарными жителями. Многие существа, включая юголотов, заключают контракт с марутами, если это потребуется.`,
    {
      header: 'Космические исполнители порядка',
      text: `Зал Соглашений является пристанищем чистого закона Сигила, Города Дверей. В зале две стороны взаимно соглашаются с определёнными условиями и те, кто платит необходимую дань Коляруту, механической машине абсолютного правоведения, могут высечь свой контракт на золотом листе, что расположен на груди марута. С этого момента, пока сделка не выполнится, марут обязан следить за условиями сделки и наказывать любую сторону которая попытается разорвать контракт. Марут использует летальные приёмы, только если этого потребует контракт, когда контракт полностью разорван, либо, если на марута нападут.`,
      source: {
        id: SOURCE_MTOF,
        page: 209,
      },
    },
    {
      header: 'Слово — закон',
      text: `Маруты безразличны к сути соглашения, они заботятся только о его содержании. Марут соблюдает только те условия, что были написаны в соглашении. Колярут отклоняет контракты, в которых содержатся расплывчатые, противоречивые, или неосуществимые условия. Помимо этого, его не волнует, что написано и о чём договорились стороны. Снаружи Зала Соглашений ждёт небольшая армия адвокатов, жаждущие продать свой опыт в мастерстве написания и проведения сделок.`,
      source: {
        id: SOURCE_MTOF,
        page: 209,
      },
    },
    {
      header: 'Природа конструкта',
      text: `Марут не нуждается в воздухе, пище, воде или сне.`,
      source: {
        id: SOURCE_MTOF,
        page: 209,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_INEVITABLE,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MTOF,
    page: 209,
  },
  armor: {
    ac: 22,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 32,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 28,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 26,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ALL,
      butRarelySpeaks: true,
    },
  ],
  cr: CR_25,
  featureList: [
    ABILITY_IMMUTABLE_FORM,
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_INT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_PLANE_SHIFT,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Марут совершает две атаки _Точным ударом_.`,
    },
    {
      name: 'Точный удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: Infinity,
        range: 5,
        hit: {
          type: DAMAGE_FORCE,
          diceCount: 0,
          diceType: 0,
          diceBonus: 60,
        },
      },
    },
    {
      name: 'Опаляющий указ',
      restore: 5,
      description: `Магическая энергия исходит из груди марута в виде куба со стороной в 60 футов. Все существа в зоне действия получают 45 урона излучением. Все существа, получившие этот урон, должны пройти испытание Мудрости СЛ 20 или стать Ошеломлёнными до конца следующего хода марута.`,
    },
    {
      name: 'Время суда',
      description: `Марут выбирает до двух целей, которых он способен видеть. Каждая цель должна пройти испытание Харизмы СЛ 20, иначе они будут телепортированными в телепортационный круг в Зале Соглашений в Сигиле. Цель автоматически проваливает испытание, если она недееспособна. Если цели телепортированы, то вместе с ними телепортируется и Марут.

После перемещения марут не может использовать эту способность до окончания короткого или длинного отдыха.`,
    },
  ],
}
