const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_SCRYING} = require('./../../../../../spellIdList')
const {
  MAGIC_ITEM_CRYSTAL_BALL,
  MAGIC_ITEM_CRYSTAL_BALL_USUAL,
} = require('./../../../../../magicItemIdList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

const descriptionEnd = list
  .filter(({id}) => id !== MAGIC_ITEM_CRYSTAL_BALL_USUAL)
  .reduce(
    (text, {name, id, descriptionEnd}) => `${text}

# [${name}](MAGIC_ITEM:${id})

${descriptionEnd}

`,
    ''
  )

module.exports = {
  id: MAGIC_ITEM_CRYSTAL_BALL,
  name: `Хрустальный шар`,
  nameEn: `Crystal Ball`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: generateRarityList(list),
  attunement: true,
  description: `[Простой хрустальный шар](MAGIC_ITEM:${MAGIC_ITEM_CRYSTAL_BALL_USUAL}) является очень редким предметом, примерно 6 дюймов диаметром. Если Вы его касаетесь, то можете действием сотворять им заклинание [Наблюдение](SPELL:${SPELL_SCRYING}) (СЛ испытания 17).

Описанные ниже разновидности являются легендарными предметами, обладающими дополнительными свойствами.

${descriptionEnd}`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  spellIdList: [SPELL_SCRYING],
  source: {
    id: SOURCE_DMG,
    page: 211,
  },
}
