const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  VOLUME_UNIT_GALLON,
  VOLUME_UNIT_PINT,
} = require('./../../../volumeUnitList')
const {
  POISON_TYPE_INJURY,
} = require('./../../../poisonTypeList')
const {
  PARAM_CON,
} = require('./../../../paramList')
const {
  SOURCE_DMG,
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  CREATURE_WARHORSE,
  CREATURE_WYVERN,
} = require('./../../../creatureIdList')
const {
  DAMAGE_POISON,
} = require('./../../../damageTypeList')
const {
  CAT_ANIMALS,
  CAT_ARTISAN_TOOLS,
  CAT_CONTAINERS,
  CAT_DRUID_FOCUS,
  CAT_EQUIPMENT,
  CAT_FOOD,
  CAT_GOODS,
  CAT_EQUIPMENT_KIT,
  CAT_MAGIC_FOCUS,
  CAT_POISONS,
  CAT_TRANSPORT_GROUND,
  CAT_TRANSPORT_WATER,
} = require('./../../../gearCategoryList')

const {
  GEAR_ARROWS,
  GEAR_BACKPACK,
  GEAR_BALL_BEARINGS,
  GEAR_BEDROLL,
  GEAR_BELL,
  GEAR_BOTTLE_GLASS,
  GEAR_BURGLAR_S_PACK,
  GEAR_CANDLE,
  GEAR_CLOTHES_COSTUME,
  GEAR_COTTON_CLOTH,
  GEAR_CROWBAR,
  GEAR_DISGUISE_KIT,
  GEAR_ENTERTAINER_S_PACK,
  GEAR_EXPLORER_S_PACK,
  GEAR_HAMMER,
  GEAR_LANTERN_HOODED,
  GEAR_MESS_KIT,
  GEAR_OIL,
  GEAR_PITON,
  GEAR_RATIONS,
  GEAR_ROPE_HEMP,
  GEAR_TINDERBOX,
  GEAR_TORCH,
  GEAR_WAGON,
  GEAR_WAND,
  GEAR_WARHORSE,
  GEAR_WARSHIP,
  GEAR_WATERSKIN,
  GEAR_WEAVERS_TOOLS,
  GEAR_WHEAT,
  GEAR_WHETSTONE,
  GEAR_WINE_COMMON_PITCHER,
  GEAR_WINE_FINE_BOTTLE,
  GEAR_WOODCARVERS_TOOLS,
  GEAR_WOODEN_STAFF,
  GEAR_WYVERN_POISON,
} = require('./../../../gearIdList')

const gearRawList = [
  {
    id: GEAR_WATERSKIN,
    genderId: GENDER_MALE,
    name: 'Бурдюк',
    nameByCase: {
      nominative: 'бурдюк',
      genitive: 'бурдюка',
      accusative: 'бурдюк',
      instrumental: 'бурдюком',
    },
    nameEn: 'Waterskin',
    cost: 20,
    weight: 5,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 4,
    },
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_CONTAINERS,
  },
  {
    id: GEAR_WAND,
    genderId: GENDER_FEMALE,
    name: 'Волшебная палочка',
    nameByCase: {
      nominative: 'волшебная палочка',
      genitive: 'волшебной палочки',
      accusative: 'волшебную палочку',
      instrumental: 'волшебной палочкой',
    },
    nameAlt: 'Жезл',
    nameEn: 'Wand',
    description: 'Это особый предмет, созданный для проведения тайных заклинаний. Волшебники, колдуны и чародеи могут использовать эти предметы в качестве фокусировки для заклинаний.',
    cost: 1000,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_MAGIC_FOCUS,
  },
  {
    id: GEAR_WHETSTONE,
    genderId: GENDER_MALE,
    name: 'Точильный камень',
    nameByCase: {
      nominative: 'Точильный камень',
      accusative: 'Точильный камень',
      instrumental: 'Точильным камнем',
    },
    nameEn: 'Whetstone',
    cost: 1,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_EQUIPMENT,
  },
  {
    id: GEAR_WOODEN_STAFF,
    genderId: GENDER_MALE,
    name: 'Деревянный посох',
    nameByCase: {
      nominative: 'деревянный посох',
      genitive: 'деревянного посоха',
      accusative: 'деревянный посох',
      instrumental: 'деревянным посохом',
    },
    nameEn: 'Wooden staff',
    description: 'Посох, созданный из живого дерева. Друид может использовать этот предмет в качестве фокусировки для заклинаний.',
    cost: 500,
    weight: 4,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_DRUID_FOCUS,
  },
  {
    id: GEAR_ENTERTAINER_S_PACK,
    genderId: GENDER_MALE,
    name: 'Набор артиста',
    nameByCase: {
      nominative: 'набор артиста',
      genitive: 'набора артиста',
      accusative: 'набор артиста',
      instrumental: 'набором артиста',
    },
    nameEn: 'Entertainer’s Pack',
    description: `Включает
* [рюкзак](GEAR:${GEAR_BACKPACK}),
* [спальник](GEAR:${GEAR_BEDROLL}),
* 2 [костюма](GEAR:${GEAR_CLOTHES_COSTUME}),
* 5 [свечек](GEAR:${GEAR_CANDLE}),
* [рационы](GEAR:${GEAR_RATIONS}) на 5 дней,
* [бурдюк](GEAR:${GEAR_WATERSKIN})
* [набор для грима](GEAR:${GEAR_DISGUISE_KIT}).`,
    cost: 4000,
    weight: 35,
    source: {
      id: SOURCE_PHB,
      page: 151,
    },
    category: CAT_EQUIPMENT_KIT,
  },
  {
    id: GEAR_BURGLAR_S_PACK,
    genderId: GENDER_MALE,
    name: 'Набор взломщика',
    nameByCase: {
      nominative: 'набор взломщика',
      genitive: 'набора взломщика',
      accusative: 'набор взломщика',
      instrumental: 'набором взломщика',
    },
    nameEn: 'Burglar’s Pack',
    description: `Взломщики скажут вам, что то, что они делают — искусство; они не простые воры или грубые хулиганы. Они выбирают цель и идут тихо, беря только то, что им нужно, и покидают сцену как можно спокойнее.

В наборе взломщика содержится множество важных профессиональных инструментов, в том числе [фонарь](GEAR:${GEAR_LANTERN_HOODED}), который можно быстро закрыть, [колокольчик](GEAR:${GEAR_BELL}), который можно прикрепить к леске, чтобы предупредить о прохождении патрулей, много [веревки](GEAR:${GEAR_ROPE_HEMP}), немного [металлических шариков](GEAR:${GEAR_BALL_BEARINGS}), [ломик](GEAR:${GEAR_CROWBAR}) и [молоток](GEAR:${GEAR_HAMMER}) для взлома замков. Конечно, опытные грабители могут не использовать два последних, если знают, как управляться с набором отмычек.

Включает
    
* [рюкзак](GEAR:${GEAR_BACKPACK}),
* [сумку с 1 000 металлических шариков](GEAR:${GEAR_BALL_BEARINGS}),
* 10 футов лески,
* [колокольчик](GEAR:${GEAR_BELL}),
* 5 [свечек](GEAR:${GEAR_CANDLE}),
* [ломик](GEAR:${GEAR_CROWBAR}),
* [молоток](GEAR:${GEAR_HAMMER}),
* 10 [шлямбуров](GEAR:${GEAR_PITON}),
* [закрытый фонарь](GEAR:${GEAR_LANTERN_HOODED}),
* 2 [фляги масла](GEAR:${GEAR_OIL}),
* [рационы](GEAR:${GEAR_RATIONS}) на 5 дней,
* [трутницу](GEAR:${GEAR_TINDERBOX})
* [бурдюк](GEAR:${GEAR_WATERSKIN}).

В набор также входит 50-футовая [пеньковая верёвка](GEAR:${GEAR_ROPE_HEMP}), закреплённая сбоку.`,
    cost: 1600,
    weight: 39,
    source: {
      id: SOURCE_PHB,
      page: 151,
    },
    category: CAT_EQUIPMENT_KIT,
  },
  {
    id: GEAR_EXPLORER_S_PACK,
    genderId: GENDER_MALE,
    name: 'Набор путешественника',
    nameByCase: {
      nominative: 'набор путешественника',
      genitive: 'набора путешественника',
      accusative: 'набор путешественника',
      instrumental: 'набором путешественника',
    },
    nameEn: 'Explorer’s Pack',
    description: `Любой искатель приключений может быть призван пройти долгий путь, выполняя задание, но те, кто выбрал жизнь исследователя, особенно хорошо подготовлены к путешествию.

Набор путешественника включает в себя [рюкзак](GEAR:${GEAR_BACKPACK}), простыню, [факелы](GEAR:${GEAR_TORCH}), коробку для трубочек, [бурдюк](GEAR:${GEAR_WATERSKIN}) и немного [веревки](GEAR:${GEAR_ROPE_HEMP}), а также [столовый набор](GEAR:${GEAR_MESS_KIT}) для приготовления и приёма пищи, который обычно включает в себя квадратную жестяную банку, чашку и столовые приборы. Набор также включает в себя [пайки](GEAR:${GEAR_RATIONS}) на несколько дней.

Включает
    
* [рюкзак](GEAR:${GEAR_BACKPACK}),
* [спальник](GEAR:${GEAR_BEDROLL}),
* [столовый набор](GEAR:${GEAR_MESS_KIT}),
* [трутницу](GEAR:${GEAR_TINDERBOX}),
* 10 [факелов](GEAR:${GEAR_TORCH}),
* [рационы](GEAR:${GEAR_RATIONS}) на 10 дней
* [бурдюк](GEAR:${GEAR_WATERSKIN}).

В набор также входит 50-футовая [пеньковая верёвка](GEAR:${GEAR_ROPE_HEMP}), закреплённая сбоку.`,
    cost: 1000,
    weight: 59,
    source: {
      id: SOURCE_PHB,
      page: 151,
    },
    category: CAT_EQUIPMENT_KIT,
  },
  {
    id: GEAR_WOODCARVERS_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты резчика по дереву',
    nameByCase: {
      nominative: 'инструменты резчика по дереву',
      genitive: 'инструментов резчика по дереву',
      accusative: 'инструменты резчика по дереву',
      instrumental: 'инструментами резчика по дереву',
    },
    nameEn: 'Woodcarver’s Tools',
    description: `Инструменты резчика по дереву позволяют вам создавать сложные предметы из дерева, такие как деревянные фигурки или [стрелы](GEAR:${GEAR_ARROWS}).

**Компоненты.** Инструменты резчика по дереву включают в себя

* нож,
* стамеску,
* маленькую пилу.

**Магия, История.** Ваш опыт даёт вам дополнительные знания при осмотре деревянных предметов, таких как статуэтки или [стрелы](GEAR:${GEAR_ARROWS}).

**Природа.** Ваши познания о древесине дают вам дополнительные знания, когда Вы изучаете деревья.

**Починка.** Как часть короткого отдыха Вы можете починить один повреждённый деревянный предмет.

**Сделать стрелы.** Как часть короткого отдыха Вы можете сделать до пяти [стрел](GEAR:${GEAR_ARROWS}). Как часть длинного отдыха Вы можете сделать до двенадцати. Вы должны иметь под рукой древесину для изготовления [стрел](GEAR:${GEAR_ARROWS}).

| Действие                                | СЛ |
|-----------------------------------------|----|
| Сделать небольшую деревянную фигурку    | 10 |
| Вырезать замысловатый узор на древесине | 10 |
`,
    cost: 100,
    weight: 5,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 87,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_WEAVERS_TOOLS,
    genderId: GENDER_MULTIPLE,
    name: 'Инструменты ткача',
    nameByCase: {
      nominative: 'инструменты ткача',
      genitive: 'инструментов ткача',
      accusative: 'инструменты ткача',
      instrumental: 'инструментами ткача',
    },
    nameEn: 'Weaver’s Tools',
    description: `Инструменты ткача позволяют вам шить одежду и подгонять её по фигуре.

**Компоненты.** Инструменты ткача включают в себя

* нитки,
* иголки
* [куски ткани](GEAR:${GEAR_COTTON_CLOTH}).

Вы умеете пользоваться ткацким станком, но подобное оборудование слишком громоздкое для транспортировки.

**Магия, История.** Ваш опыт даёт вам дополнительные знания при осмотре предметов гардероба, включая плащи и прочие накидки.

**Расследование.** Используя свои знания о процессе пошива одежды, при обследовании гобеленов, обивки, одежды и прочих тканых предметов, Вы можете обнаруживать зацепки, которые другие могут не заметить.

**Починка.** Как часть короткого отдыха Вы можете починить один повреждённый предмет одежды.

**Пошив одежды.** Как часть длинного отдыха Вы можете создать одежду для существа при наличии достаточного количества ткани и ниток.

| Действие                       | СЛ |
|--------------------------------|----|
| Повторное использование ткани  | 10 |
| Заштопать дырку в ткани        | 10 |
| Подшить наряд                  | 15 |
`,
    cost: 100,
    weight: 5,
    source: [
      {
        id: SOURCE_PHB,
        page: 154,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_XGTE,
        page: 87,
      },
    ],
    category: CAT_ARTISAN_TOOLS,
  },
  {
    id: GEAR_WARHORSE,
    genderId: GENDER_MALE,
    name: 'Боевой конь',
    nameByCase: {
      nominative: 'боевой конь',
      genitive: 'боевого коня',
      accusative: 'боевого коня',
      instrumental: 'боевым конём',
    },
    nameEn: 'Warhorse',
    creatureId: CREATURE_WARHORSE,
    description: 'Скорость 60 фт. Грузоподъёмность 540 фунтов.',
    cost: 40000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_WAGON,
    genderId: GENDER_MALE,
    name: 'Телега',
    nameByCase: {
      nominative: 'телега',
      genitive: 'телеги',
      accusative: 'телега',
      instrumental: 'телегой',
    },
    nameAlt: 'Фургон',
    nameEn: 'Wagon',
    cost: 3500,
    weight: 400,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_GROUND,
  },
  {
    id: GEAR_WARSHIP,
    genderId: GENDER_MALE,
    name: 'Военный корабль',
    nameByCase: {
      nominative: 'военный корабль',
      genitive: 'военного корабля',
      accusative: 'военный корабль',
      instrumental: 'военным кораблём',
    },
    nameEn: 'Warship',
    description: 'Скорость: 2,5 узла',
    cost: 2500000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_TRANSPORT_WATER,
  },
  {
    id: GEAR_WHEAT,
    genderId: GENDER_FEMALE,
    name: 'Пшеница',
    nameByCase: {
      nominative: 'пшеница',
      genitive: 'пшеницы',
      accusative: 'пшеницу',
      instrumental: 'пшеницей',
    },
    nameEn: 'Wheat',
    cost: 1,
    weight: 1,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_GOODS,
  },
  {
    id: GEAR_WINE_COMMON_PITCHER,
    genderId: GENDER_MIDDLE,
    name: 'Вино, обычное (кувшин)',
    nameByCase: {
      nominative: 'кувшин обычного вина',
      genitive: 'кувшина обычного вина',
      accusative: 'кувшин обычного вина',
      instrumental: 'кувшином обычного вина',
    },
    nameEn: 'Wine, common (pitcher)',
    cost: 20,
    volume: {
      unitId: VOLUME_UNIT_GALLON,
      count: 1,
    },
    weight: 2,
    source: {
      id: SOURCE_PHB,
      page: 158,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_WINE_FINE_BOTTLE,
    genderId: GENDER_MIDDLE,
    name: 'Вино, изысканное (бутылка)',
    nameByCase: {
      nominative: 'бутылка изысканного вина',
      genitive: 'бутылки изысканного вина',
      accusative: 'бутылку изысканного вина',
      instrumental: 'бутылкой изысканного вина',
    },
    nameEn: 'Wine, fine (bottle)',
    description: `[Бутылка](GEAR:${GEAR_BOTTLE_GLASS}) изысканного вина`,
    cost: 1000,
    weight: 3,
    volume: {
      unitId: VOLUME_UNIT_PINT,
      count: 1.5,
    },
    source: {
      id: SOURCE_PHB,
      page: 158,
    },
    category: CAT_FOOD,
  },
  {
    id: GEAR_WYVERN_POISON,
    genderId: GENDER_MALE,
    name: 'Яд виверны',
    nameByCase: {
      nominative: 'яд виверны',
      genitive: 'яда виверны',
      accusative: 'яд виверны',
      instrumental: 'ядом виверны',
    },
    nameEn: 'Wyvern Poison',
    description: `Этот яд собирают с мёртвой или недееспособной [виверны](CREATURE:${CREATURE_WYVERN}). Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 15, получая при провале урон ядом 24 (7к6) или половину этого урона при успехе.`,
    damage: {
      diceType: 6,
      diceCount: 7,
    },
    saveThrow: {
      type: PARAM_CON,
      dc: 15,
    },
    cost: 120000,
    damageType: DAMAGE_POISON,
    poisonType: POISON_TYPE_INJURY,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
]

module.exports = gearRawList
