const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SIZE_MEDIUM,
  SIZE_TINY,
} = require('./../../../../sizeList')
const {
  ABILITY_KEEN_HEARING,
  ABILITY_SWARM,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_SWARM_OF_BATS} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {swarmDescription} = require('./../../../../textCommonParts')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Рой летучих мышей',
  nameAlt: 'Стая летучих мышей',
  nameEn: 'Swarm of Bats',
  id: CREATURE_SWARM_OF_BATS,
  description: swarmDescription,
  sizeType: SIZE_MEDIUM,
  swarmMemberSizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 339,
  },
  armor: 12,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 5,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 10,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 4,
  },
  resistanceList: [
    DAMAGE_BLUDGEONING,
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  ],
  immunityConditionList: [
    CONDITION_GRAPPLED,
    CONDITION_FRIGHTENED,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_CHARMED,
    CONDITION_STUNNED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Эхолокация',
      description: `Рой не может использовать слепое зрение, будучи оглохшим.`,
    },
    ABILITY_KEEN_HEARING,
    ABILITY_SWARM,
  ],
  actionList: [
    {
      name: 'Укусы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 0,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            comment: ` в пространстве ★СУЩЕСТВО★`,
          },
        },
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 2,
              diceType: 4,
              diceBonus: 0,
            },
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 4,
              diceBonus: 0,
              comment: `, если ★СУЩЕСТВО★ имеет половину хитов или меньше`,
            },
          ],
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
