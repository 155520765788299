const {
    SOURCE_MM
  } = require("./../../../../sourceList"),
  {
    CR_6
  } = require("./../../../../crList"),
  {
    SIZE_LARGE
  } = require("./../../../../sizeList"),
  {
    CREATURE_TYPE_DRAGON,
  } = require("./../../../../creatureTypeIdList"),
  {
    ALIGNMENT_NO
  } = require("./../../../../aligmentList"),
  {
    SPEED_FLY,
    SPEED_WALK
  } = require("./../../../../speedList"),
  {
    ATTACK_MELEE_WEAPON
  } = require("./../../../../attackTypeList"),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING
  } = require("./../../../../damageTypeList"),
  {
    SENSE_DARK_VISION,
  } = require("./../../../../senseList"),
  {
    SKILL_PERCEPTION,
  } = require("./../../../../skillList"),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA
  } = require("./../../../../paramList"),
  {
    GENDER_FEMALE
  } = require("./../../../../genderList"),
  {
    ARMOR_TYPE_NATURAL
  } = require("./../../../../armorTypeList"),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_WYVERN
  } = require("./../../../../creatureIdList");

module.exports = {
  name: "Виверна",
  nameEn: "Wyvern",
  id: CREATURE_WYVERN,
  description: `Путешественники порой видят в небесах тёмную крылатую фигуру виверны, несущей добычу. Это родственники великих драконов, и охотятся они в тех же дремучих лесах и пещерах. Их появление вызывает всплески тревоги на границах цивилизации.

У виверны есть две чешуйчатые лапы, кожистые крылья и мощный хвост, увенчанный самым мощным оружием виверны: ядовитым жалом. Яд жала виверны может убить существо в считанные секунды. Необычайно сильный яд в крови жертвы уничтожает вены и артерии по пути к её сердцу. Но, несмотря на смертоносность виверн, находятся охотники и искатели приключений, выслеживающие виверн ради их яда, который используется в алхимии и наносится на оружие.

# Воздушные охотники

Виверны не сражаются на земле кроме случаев, когда иными способами жертву не достать, или когда их заманили в положение, при котором сражение в воздухе не представляется возможным. Если вынудить её сражаться на земле, виверна припадает, шипя и рыча, при этом держа жало над головой.

# Агрессивные и безудержные

Виверны прекращают преследовать свою добычу только если получают серьёзную травму, или если жертве так долго удаётся ускользать, что легче становится поймать какую-нибудь другую добычу. Если убегающее существо забьётся куда-то, куда виверна не может пролезть из-за размера, она будет охранять выход из этого убежища, пытаясь при возможности достать жертву жалом.

Хотя виверны гораздо хитрее обычных животных, они далеко не такие умные, как их драконьи собратья. Поэтому если существо, за которым с воздуха охотится виверна, сохраняет самообладание, оно вполне может ускользнуть от виверны или провести её. Виверны следуют за жертвой кратчайшим путём, не раздумывая о возможной засаде.

# Приручённые виверны

Виверну можно приручить, чтобы использовать как ездовое животное, однако это трудная и опасная задача. Лучше всего выращивать виверн для этих целей с самого младенчества. Тем не менее, хищный темперамент виверн стоил жизни многим горе-дрессировщикам.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 42
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 80
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 16,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60
    },
  ],
  cr: CR_6,
  actionList: [
    {
      name: "Мультиатака",
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Жалом_. Во время полёта ★он★ может использовать _Когти_ вместо одной другой атаки.`
    },
    {
      name: "Укус",
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4
        }
      }
    },
    {
      name: "Когти",
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4
        }
      }
    },
    {
      name: "Жало",
      description: `Цель должна пройти испытание Телосложения СЛ 15, получая урон ядом 24 (7к6) при провале, или половину этого урона при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4
        }
      }
    }
  ],
  genderId: GENDER_FEMALE
};
