const {PC_SUBCLASS_ROGUE_SWASHBUCKLER} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  FEATURE_ELEGANT_MANEUVER,
  FEATURE_FANCY_FOOTWORK,
  FEATURE_MASTER_DUELIST,
  FEATURE_PANACHE,
  FEATURE_RAKISH_AUDACITY,
  FEATURE_SNEAK_ATTACK,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_FANCY_FOOTWORK,
    name: `Ловкое маневрирование`,
    nameEn: `Fancy Footwork`,
    lvl: 3,
    text: `Вы узнаёте, как наносить удары и сразу же ускользать от контратаки.

На протяжении Вашего хода, если Вы проводите рукопашную атаку в ближнем бою — до конца Вашего хода это существо не может совершать провоцированные атаки против Вас.`,
    source: {
      id: SOURCE_XGTE,
      page: 51,
    },
  },
  {
    id: FEATURE_RAKISH_AUDACITY,
    name: `Лихая удаль`,
    nameEn: `Rakish Audacity`,
    lvl: 3,
    text: `Вы бросаетесь в бой с смело и без колебаний.

Вы добавляете свой модификатор Харизмы к результату броска инициативы.

В добавок к этому, Вам не требуется более преимущество на броски атаки, для того, чтобы применять [Скрытую атаку](FEATURE:${FEATURE_SNEAK_ATTACK}), если в пределах пяти футов от Вас нет ни одного существа, кроме Вашей цели. Все прочие правила для классового умения [Скрытая атака](FEATURE:${FEATURE_SNEAK_ATTACK}) применяются к Вам как обычно.`,
    source: {
      id: SOURCE_XGTE,
      page: 51,
    },
  },
  {
    id: FEATURE_PANACHE,
    name: `Щегольство`,
    nameEn: `Panache`,
    lvl: 9,
    text: `Ваше обаяние становится необычайно привлекательным. Вы можете действием провести соревнование Вашей Харизмы (Убеждения) против Мудрости (Проницательности) существа. Существо должно слышать Вас и понимать Ваш язык.

Если Вы преуспеваете в этом соревновании и существо враждебно к Вам, оно получает помеху на броски атак по другим целям кроме Вас и не может проводить провоцированные атаки не по Вам. Этот эффект длится 1 минуту, до того, как Ваш союзник не атакует Вашу цель, не наложит на неё заклинание или цель не удалится от Вас на 60 или более футов.

Если Вы преуспеваете в этом соревновании и существо не враждебно к Вам, оно очаровано Вами на 1 минуту. Пока очаровано, оно считает Вас дружественным к себе. Этот эффект заканчивается немедленно, если Вы или Ваши союзники совершают что-то опасное для цели.`,
    source: {
      id: SOURCE_XGTE,
      page: 51,
    },
  },
  {
    id: FEATURE_ELEGANT_MANEUVER,
    name: `Элегантный маневр`,
    nameEn: `Elegant Maneuver`,
    lvl: 13,
    text: `В свой ход Вы можете бонусным действием получить преимущество на следующую проверку Ловкости (Акробатики) или Силы (Атлетики) на протяжении этого хода.`,
    source: {
      id: SOURCE_XGTE,
      page: 52,
    },
  },
  {
    id: FEATURE_MASTER_DUELIST,
    name: `Мастер дуэлянт`,
    nameEn: `Master Duelist`,
    lvl: 17,
    text: `Ваше мастерство фехтования позволяет Вам обратить провал в успех в бою.

Промахнувшись атакой, Вы можете выполнить бросок снова с преимуществом.

Сделав так, Вы сможете использовать эту черту снова только после короткого или длинного отдыха.`,
    source: {
      id: SOURCE_XGTE,
      page: 52,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_SWASHBUCKLER,
  })
)

