const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  GEAR_JAVELIN,
  GEAR_SCALE_MAIL,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DUERGAR_RESILIENCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_DUERGAR,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Двергар',
  nameAlt: 'Дуэргар',
  nameEn: 'Duergar',
  id: CREATURE_DUERGAR,
  description: duergarDescription,
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DWARF,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 139,
  },
  armor: {
    ac: 16,
    gearId: [GEAR_SCALE_MAIL, GEAR_SHIELD],
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_DUERGAR_RESILIENCE,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Увеличение',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `На 1 минуту ★СУЩЕСТВО★ магическим образом увеличивается в размере вместе со всем, что несёт и носит. В увеличенном состоянии ★СУЩЕСТВО★ Большого размера, удваивает кости урона в атаках, основанных на Силе (уже учтено), и совершает с преимуществом проверки Силы. Если ★СУЩЕСТВО★ не хватает места, чтобы стать Большим, ★он★ приобретает максимальный размер, допустимый имеющимся пространством.`,
    },
    {
      name: 'Боевая кирка',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Боевая кирка',
      comment: 'в увеличенной форме',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_JAVELIN,
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      comment: 'в увеличенной форме',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 4,
        range: {
          normal: 30,
          max: 120,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Невидимость',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `★СУЩЕСТВО★ магическим образом становится невидимым, пока не атакует, не наложит заклинание, не использует _Увеличение_, или пока не окончится ★его★ концентрация, но не более 1 часа (как при концентрации на заклинании). Всё снаряжение, которое ★СУЩЕСТВО★ несёт или носит, становится невидимым вместе с ★ним★.`,
    },
  ],
  genderId: GENDER_MALE,
}
