const {
  WEAPON_AMMUNITION,
  WEAPON_RANGE,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const bowsDescription = require('./../../constants/bowsDescription')
const {CAT_SIMPLE_RANGE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_SHORTBOW} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_SHORTBOW,
  genderId: GENDER_MALE,
  name: 'Короткий лук',
  nameByCase: {
    nominative: 'короткий лук',
    genitive: 'короткого лука',
    accusative: 'короткий лук',
    instrumental: 'коротким луком',
  },
  nameEn: 'Shortbow',
  description: bowsDescription,
  damage: {
    diceType: 6,
    diceCount: 1,
  },
  cost: 2500,
  damageType: DAMAGE_PIERCING,
  weight: 2,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 114,
    },
  ],
  weaponPropList: [
    WEAPON_AMMUNITION,
    WEAPON_TWO_HANDED,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 80,
        max: 320,
      },
    },
  ],
  category: CAT_SIMPLE_RANGE_WEAPON,
}
