const {
  SPELL_BLIGHT,
  SPELL_CHARM_PERSON,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DOMINATE_PERSON,
  SPELL_HOLD_PERSON,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_HAND,
  SPELL_MINOR_ILLUSION,
  SPELL_RAY_OF_FROST,
  SPELL_SLEEP,
  SPELL_WATER_BREATHING,
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_NAGA,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_SPIRIT_NAGA} = require('./../../../../creatureIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {nagaDescription} = require('./../../../../textCommonParts')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Духовная нага',
  nameEn: 'Spirit Naga',
  id: CREATURE_SPIRIT_NAGA,
  description: [
    `Духовные наги живут в злобе и безрадостности, постоянно планируя месть против тех, кто их предал, — или тех, кто, как они считают, их предал. Они устраивают логова в угрюмых пещерах и руинах, проводя всё своё время в изучении новых заклинаний и порабощении смертных, которыми они себя окружают. Духовные наги любят зачаровывать своих врагов, подтягивая их к себе поближе, чтобы можно было впиться ядовитыми клыками в их плоть.`,
    nagaDescription,
  ],
  note: {
    text: `Если даже ты уничтожишь меня, я всё равно вернусь, и все твои близкие поплатятся`,
    author: `Экспликтика Дефайлус, духовная нага`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_NAGA,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 218,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 14,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
  ],
  cr: CR_8,
  featureList: [
    {
      name: 'Восстановление',
      description: `Если ★СУЩЕСТВО★ умирает, ★он★ возвращается к жизни через 1к6 дней и восстанавливает все хиты. Только заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) может прекратить действие этой особенности.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 10,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_BLIGHT,
      SPELL_CHARM_PERSON,
      SPELL_DETECT_MAGIC,
      SPELL_DETECT_THOUGHTS,
      SPELL_DIMENSION_DOOR,
      SPELL_DOMINATE_PERSON,
      SPELL_HOLD_PERSON,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGE_HAND,
      SPELL_MINOR_ILLUSION,
      SPELL_RAY_OF_FROST,
      SPELL_SLEEP,
      SPELL_WATER_BREATHING,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения СЛ 13, получая урон ядом 31 (7к8) при провале, или половину этого урона при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
