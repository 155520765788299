const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_PISTOL} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  WEAPON_AMMUNITION_RENAISSANCE,
  WEAPON_RANGE,
  WEAPON_RELOADING,
} = require('./../../../weaponPropList')
const {
  CAT_FIREARMS,
  CAT_MARTIAL_RANGE_WEAPON,
  CAT_TECH_RENAISSANCE,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_PISTOL,
  name: 'Пистоль',
  nameEn: 'Pistol',
  nameByCase: {
    nominative: 'пистоль',
    genitive: 'пистоля',
    accusative: 'пистоль',
    instrumental: 'пистолем',
  },
  cost: 25000,
  weight: 3,
  damageType: DAMAGE_PIERCING,
  damage: {
    diceCount: 1,
    diceType: 10,
  },
  genderId: GENDER_MALE,
  category: [
    CAT_FIREARMS,
    CAT_MARTIAL_RANGE_WEAPON,
    CAT_TECH_RENAISSANCE,
  ],
  weaponPropList: [
    WEAPON_AMMUNITION_RENAISSANCE,
    WEAPON_RELOADING,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 30,
        max: 90,
      },
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
