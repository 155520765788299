const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BEAST,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ATHLETICS,
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING,
    MAGIC_ITEM_CLOAK_OF_INVISIBILITY,
  } = require('./../../../../magicItemIdList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_KEEN_SMELL,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_JACULI,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Джакули',
  nameEn: 'Jaculi',
  id: CREATURE_JACULI,
  description: `Джакули — это змея длиной 15 футов, которая может изменять цвет и текстуру своей чешуи для маскировки. Изменяя внешний вид, напоминающий дерево или камень, она обматывает колонны и стволы деревьев, с которых она прыгает как копьё, поражающее с большой силой и точностью.

Джакули обитают во влажном климате, и их предпочтительными местами обитания являются тропические леса и прохладные подземелья. Кожа джакули отлично подходит для использования в создании [сапог ходьбы и прыжков](MAGIC_ITEM:${MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING}) и [плащей невидимости](MAGIC_ITEM:${MAGIC_ITEM_CLOAK_OF_INVISIBILITY}).`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 222,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 20,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Камуфляж',
      description: `★СУЩЕСТВО★ совершает с преимуществом проверки Ловкости (Скрытность), когда пытается спрятаться.`,
    },
    ABILITY_KEEN_SMELL,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Скачок',
      description: `★СУЩЕСТВО★ прыгает на расстояние до 30 футов по прямой линии и совершает атаку _Укусом_ по цели в пределах досягаемости. Эта атака получает преимущество, если ★СУЩЕСТВО★ прыгает не менее чем на 10 футов. Если атака попадёт, _Укус_ наносит дополнительно 7 (2к6) колющего урона.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
