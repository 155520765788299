const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_SHADAR_KAI,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ELVEN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
} = require('./../../../../conditionList')
const {ABILITY_FEY_ANCESTRY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_SHADOW_DANCER} = require('./../../../../creatureIdList')
const {GEAR_STUDDED_LEATHER_ARMOR} = require('./../../../../gearIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {shadarKaiDescriptionList} = require('./../../../../textCommonParts')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Теневой танцор',
  nameEn: 'Shadow Dancer',
  id: CREATURE_SHADOW_DANCER,
  description: [
    `Те, кто сражался с теневыми танцорами, описывают это, как борьбу с живой тьмой. Каждая тусклая ниша и затенённый угол — места, откуда могут появиться гибкие и ловкие теневые танцоры, чтобы напасть на свою добычу. Используя подобную тактику, они нападают на врагов со всех сторон, используя шквал запутывающих цепей, которыми они быстро оплетают добычу и разъедают её плоть. Когда их добыча беспомощна, приближаются другие и убивают её. Затем они обыскивают труп в поисках безделушек, всего яркого и красочного, чтобы смотреть на эти вещи после возвращения во мрак Царства Теней.`,
    ...shadarKaiDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_SHADAR_KAI,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MTOF,
    page: 239,
  },
  armor: {
    ac: 15,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 13,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
  ],
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_NECROTIC,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ELVEN,
  ],
  cr: CR_7,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    {
      name: 'Теневой прыжок',
      description: `Теневой танцор бонусным действием может телепортироваться на расстояние до 30 фт. в незанятое пространство, которое он может видеть. Обе точки телепортации должны находиться в тусклом свете или во тьме. Теневой танцор может использовать эту способность между атаками оружием и другими совершаемыми им действиями.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Теневой танцор совершает три атаки _Цепью с шипами_.`,
    },
    {
      name: 'Цепь с шипами',
      description: `Цель должна пройти испытание Ловкости СЛ 14. В противном случае она страдает от одного дополнительного эффекта по выбору теневого танцора:

* Цель становится схваченной (СЛ высвобождения 14), если она среднего или меньшего размера. До завершения захвата, цель обездвижена и теневой танцор не может схватить другую цель.
* Цель сбивается с ног.
* Цель получает 22 (4к10) урона некротической энергией.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
}
