const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Кентавры',
    text: `Кентавры, одинокие странники и чтецы предзнаменований природы, избегают конфликтов, но будучи загнанными в угол, сражаются с яростью. Они селятся на огромных пустошах, держась подальше от границ, законов и общества других существ.`,
    source: {
      id: SOURCE_MM,
      page: 175,
    },
  },
  {
    header: 'Пустынные кочевники',
    text: `Племена кентавров широко распространены в землях с климатом от мягкого до жаркого, где кентавру достаточно только лёгкого меха, либо промасленной кожи, чтобы бороться с превратностями погоды. Они охотники-собиратели, редко возводят жилища, и даже палатки.

Миграции кентавров покрывают целые континенты и длятся десятки лет, так что племя может не возвращаться на прежний путь несколько поколений. Такие далёкие переселения могут привести к конфликтам с существами, которые могут возвести свои жилища на традиционных путях миграции кентавров.`,
    source: {
      id: SOURCE_MM,
      page: 175,
    },
  },
  {
    header: 'Вынужденные поселенцы',
    text: `Кентавр, который отстаёт от племени, остаётся позади. Некоторые из этих кентавров исчезают в дикой местности, и никто их больше не видит. Те, кто могут перенести потерю своего племени, селятся среди других рас. Пограничные поселения ценят знания своих сожителей кентавров. Многие из таких общин даже обязаны своим выживанием проницательности и сообразительности кентавров.

Несмотря на затворническую натуру, кентавры торгуют с эльфами и с караванами других дружественных гуманоидов, которых они встречают во время своих странствий. Торговец может спасти жизнь раненого или пожилого кентавра, неспособного для длинного путешествия, и сопроводить его в населённый пункт, где тот может спокойно прожить остаток своих дней.`,
    source: {
      id: SOURCE_MM,
      page: 175,
    },
  },
]
