const generateKillBonusText = (killBonusList, isSingleRow = false, showBold = false) => killBonusList.reduce(
  (text, row) => {
    const rowPreText = isSingleRow ? `` : '* '
    const rowText = showBold
      ? `${rowPreText}**${row}**`
      : `${rowPreText}${row}`
    const rowSeparator = isSingleRow
      ? ' '
      : '\n'

    return text
      ? `${text}${rowSeparator}${rowText}`
      : rowText
  },
  ''
)

module.exports = generateKillBonusText
