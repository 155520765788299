const {
  SPELL_ALARM,
  SPELL_ARCANE_LOCK,
  SPELL_BANISHMENT,
  SPELL_BLADE_WARD,
  SPELL_CONE_OF_COLD,
  SPELL_COUNTERSPELL,
  SPELL_DANCING_LIGHTS,
  SPELL_DISPEL_MAGIC,
  SPELL_FIREBALL,
  SPELL_FLESH_TO_STONE,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_INVISIBILITY,
  SPELL_MAGE_ARMOR,
  SPELL_MAGIC_MISSILE,
  SPELL_MENDING,
  SPELL_MESSAGE,
  SPELL_RAY_OF_FROST,
  SPELL_SHIELD,
  SPELL_STONESKIN,
  SPELL_SYMBOL,
  SPELL_TELEPORT,
  SPELL_WALL_OF_FORCE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_9} = require('./../../../../crList')
const {CREATURE_ABJURER} = require('./../../../../creatureIdList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Оградитель',
  nameEn: 'Abjurer',
  id: CREATURE_ABJURER,
  description: `Оградители — специализированные волшебники, которые чувствуют себя в безопасными, когда прикрыты слоями волшебной силы. Короли, знать и другие богатые люди обычно нанимают оградителей, чтобы наложить защитные заклинания на свои дома и хранилища.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 218,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 4,
    },
  ],
  cr: CR_9,
  featureList: [
    {
      name: 'Магическая защита',
      description: `У оградителя есть магическая защита, у которой есть 30 хитов. Всякий раз, когда оградитель получает урон, защита получает урон вместо него. Если хиты защиты падают до 0, оградитель получает оставшийся урон. Когда оградитель сотворяет заклинание Ограждения 1-го круга или выше, защита восстанавливает хиты, равные удвоенному уровню заклинания.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 13,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      {
        id: SPELL_ALARM,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },
      {
        id: SPELL_ARCANE_LOCK,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },
      {
        id: SPELL_BANISHMENT,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },
      {
        id: SPELL_COUNTERSPELL,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },
      {
        id: SPELL_DISPEL_MAGIC,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },
      {
        id: SPELL_GLOBE_OF_INVULNERABILITY,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },
      {
        id: SPELL_MAGE_ARMOR,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },
      {
        id: SPELL_SHIELD,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },
      {
        id: SPELL_STONESKIN,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },
      {
        id: SPELL_SYMBOL,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Ограждения 1 уровня и выше, восстанавливающее хиты «Магической защиты»',
      },

      SPELL_BLADE_WARD,
      SPELL_CONE_OF_COLD,
      SPELL_DANCING_LIGHTS,
      SPELL_FIREBALL,
      SPELL_FLESH_TO_STONE,
      SPELL_INVISIBILITY,
      SPELL_MAGIC_MISSILE,
      SPELL_MENDING,
      SPELL_MESSAGE,
      SPELL_RAY_OF_FROST,
      SPELL_TELEPORT,
      SPELL_WALL_OF_FORCE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
  ],
}
