const {SOURCE_VGTM} = require('./../../sourceList')
const {GOD_MEMNOR} = require('./../../godIdList')
const {
  PC_RACE_DWARF,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')
const {
  CREATURE_FIRE_GIANT,
  CREATURE_FROST_GIANT,
  CREATURE_HILL_GIANT,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Руны и резные сказания',
    text: `По большей части для письменных сообщений великаны используют модифицированную версию рунических букв, создание которых приписывают себе [дварфы](PC_RACE:${PC_RACE_DWARF}). Этот алфавит широко используется и сегодня, в том числе многими традиционными врагами [дварфов](PC_RACE:${PC_RACE_DWARF}), такими как [орки](PC_RACE:${PC_RACE_ORC}), великаны, и гоблиноиды. То обстоятельство, что великаны были первыми в мире, и потому являются создателями алфавита, великаны считают само собой разумеющимся фактом, который [дварфы](PC_RACE:${PC_RACE_DWARF}) горячо оспаривают.

Многие великаны почти или полностью безграмотны. В основном это [холмовые](CREATURE:${CREATURE_HILL_GIANT}), [ледяные](CREATURE:${CREATURE_FROST_GIANT}) и [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}), которые не считают образование ценным. Вместо того, чтобы писать историю в словах, они рассказывали её через пиктограммы на дереве, льду, камне и даже земле в случае [холмовых великанов](CREATURE:${CREATURE_HILL_GIANT}). Эта «сказочная резьба» рассказывает легенды или истории важных событий или встреч в манере очень сложной наскальной живописи. Часто они задействуют различные истории великанского пантеона. Например, голова или лицо [Мемнора](GOD:${GOD_MEMNOR}), парящая над плечами другого великана, указывает, что великан был лжецом и обманщиком; изображение того, как Ялланис ударяют в спину, означает предательство в любви. Такие символы — визуальные аллегории, которые хорошо понятны великанам, но совершенно ничего не говорят зрителю, не погрузившемуся в великанскую мифологию. Большинство не великанов сочтут сказочную резьбу такой же непонятной, какой великаны сочтут поэзию, написанную на Эльфийском.`,
    source: {
      id: SOURCE_VGTM,
      page: 22,
    },
  },
]
