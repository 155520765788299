const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {DAMAGE_COLD} = require('./../../../../damageTypeList')
const {
  goliathDescriptionList,
  goliathNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MOUNTAIN_BORN,
  ABILITY_POWERFUL_BUILD,
} = require('./../../../../creatureAbilityList')
const {
  GEAR_GREATAXE,
  GEAR_HIDE_ARMOR,
  GEAR_JAVELIN,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_GOLIATH,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_GOLIATH_WARRIOR} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_IDRotF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Голиаф-воин',
  nameEn: 'Goliath Warrior',
  id: CREATURE_GOLIATH_WARRIOR,
  note: goliathNote,
  description: goliathDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOLIATH,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_IDRotF,
    page: 293,
  },
  armor: {
    ac: 12,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
  ],
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_MOUNTAIN_BORN,
    ABILITY_POWERFUL_BUILD,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Секирой_ или два броска _Пилума_.`,
    },
    {
      gearId: GEAR_GREATAXE,
    },
    {
      gearId: GEAR_JAVELIN,
    },
  ],
  reactionList: [
    {
      name: 'Устойчивость камня',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Когда ★СУЩЕСТВО★ получает урон, ★он★ может уменьшить его на 9 (1к12 + 3).`,
    },
  ],
}
