const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1_4,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BULLYWUG,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_BULLYWUG,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../damageTypeList')
const {
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  GEAR_HIDE_ARMOR,
  GEAR_SHIELD,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_SPEAK_WITH_FROGS_AND_TOADS,
  ABILITY_STANDING_LEAP,
  ABILITY_SWAMP_CAMOUFLAGE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_BULLYWUG,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Жаболюд',
  nameEn: 'Bullywug',
  id: CREATURE_BULLYWUG,
  description: [
    {
      header: 'Жаболюды',
      text: `Жизнь жаболюда это опасность, грубость и влага. Эти земноводные гуманоиды должны постоянно находиться во влажных местах, устраивая себе жилища в дождливых лесах и сырых пещерах. Всегда голодные и невероятно злые, жаболюды при возможности берут своего противника числом, но отступают при серьёзной опасности в поисках более лёгкой добычи.

Кожа жаболюдов обычно зелёная или серая, иногда с жёлтыми пятнами, что позволяет им сливаться с окружающей средой. Они носят грубые доспехи и простое оружие, и к тому же они могут сильно укусить врага, который подошёл достаточно близко.`,
      source: {
        id: SOURCE_MM,
        page: 155,
      },
    },
    {
      header: 'Грязные аристократы',
      text: `Жаболюды считают себя правителями болот. Они следуют своего рода этикету, когда имеют дело с чужаками и друг с другом, исходя из капризов и фантазий своего лидера — самозваного владыки грязи. Жаболюды представляются звучными титулами, совершают сложные поклоны и унижаются перед владыками, бесконечно борясь за благосклонность правителей.

Для продвижения наверх среди сородичей у жаболюдов есть два пути. Можно убить своего соперника, хотя нужно постараться, чтобы сохранить свою причастность в секрете, или можно найти клад или магический предмет и принести его в дар своему господину. Жаболюд, который убивает своего соперника открыто, скорее всего, будет казнён. Поэтому для жаболюдов более характерны набеги на караваны или поселения с целью разжиться драгоценными безделушками, чтобы произвести впечатление на повелителя и завоевать его благосклонность. Безусловно, высококачественные товары, попавшие к жаболюдам, теряют свой вид. После того как подарок потерял блеск, лорд жаболюдов обычно требует, чтобы его подданные принесли ему ещё сокровищ в качестве дани.`,
      source: {
        id: SOURCE_MM,
        page: 155,
      },
    },
    {
      header: 'Буйная дипломатия',
      text: `Жаболюды ничего не ценят больше чем возможность править теми, кто проник на их территории. Их воины не просто пытаются убить злоумышленников, они пытаются пленить их.

Пленников доставляют к королю или королеве — необычайно большого размера жаболюду — и там они должны молить о пощаде. Взятки, сокровища и лесть помогут обмануть правителя жаболюдов, и пленнику позволят идти дальше, но сперва «гостя» попытаются впечатлить сокровищами и величием царства. Страдающие глубоким комплексом неполноценности владыки жаболюдов воображают себя королями и королевами, но отчаянно нуждаются в проявлении страха и уважения пришельцев извне.`,
      source: {
        id: SOURCE_MM,
        page: 155,
      },
    },
    {
      header: 'Амфибии союзники',
      text: `Жаболюды говорят на языке, похожем на кваканье лягушек, что позволяет им общаться на больших расстояниях. Новости о злоумышленниках или других событиях на болоте распространяются в течение нескольких минут с помощью это шумной системы связи.

Простые слова на этом языке понятны лягушкам и жабам. Жаболюды используют эту возможность, чтобы установить прочные связи с гигантскими лягушками, которых они используют как охранников и охотников. Большие особи иногда используются как ездовые животные. Способность лягушек заглатывать существ обеспечивает жаболюдам-охотникам лёгкий способ доставки добычи в деревню.`,
      source: {
        id: SOURCE_MM,
        page: 155,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_BULLYWUG,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 155,
  },
  armor: {
    ac: 15,
    gearId: [GEAR_HIDE_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  languageList: [
    LANG_BULLYWUG,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_SPEAK_WITH_FROGS_AND_TOADS,
    ABILITY_SWAMP_CAMOUFLAGE,
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 20,
      jumpHeight: 10,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки: одну _Укусом_, и одну _Копьём_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      gearId: GEAR_SPEAR,
    },
  ],
  genderId: GENDER_MALE,
}
