const {ALPHABET_DETHEK} = require('./../../alphabetList')
const {LANG_GOBLIN} = require('./../../languageIdList')
const {LANG_TYPE_STANDARD} = require('./../../languageTypeList')
const {
  SOURCE_ADND2_EE,
  SOURCE_BOOK_ABBEY_TNS,
  SOURCE_BOOK_SALVATORE_EXILE,
  SOURCE_BOOK_GREENWOOD_DOTD,
  SOURCE_BOOK_SALVATORE_POTWK,
  SOURCE_BOOK_SALVATORE_SN,
  SOURCE_DND3_PGtF,
  SOURCE_DND4_DMG,
  SOURCE_GAME_ICEWIND_DALE_2,
} = require('./../../sourceList')

module.exports = {
  id: LANG_GOBLIN,
  alphabetId: ALPHABET_DETHEK,
  type: LANG_TYPE_STANDARD,
  name: {
    nominative: 'Гукляк',
    genitive: 'Гукляка',
    instrumental: 'Гукляком',
    prepositional: 'Гукляке',
  },
  name5eOfficial: {
    nominative: 'Гоблинский',
    genitive: 'Гоблинского',
    instrumental: 'Гоблинским',
    prepositional: 'Гоблинском',
  },
  nameAlt: 'Гоблинский',
  nameEn: 'Ghukliak',
  nameEnAlt: 'Goblin',
  typicalSpeakers: 'Гоблиноиды',
  dictionary: [
    {
      list: [
        [`бить`, `жулик`],
        [`возглавлять`, `вазедра`],
        [`готовность`, `арага`],
        [`дракон`, `ардрак`],
        [`кровь`, `глат`],
        [`подарок`, `ниинг`],
      ],
      source: {
        id: SOURCE_BOOK_GREENWOOD_DOTD,
      },
    },
    {
      list: [[`гоблинская гора`, `арагуль`]],
      source: {
        id: SOURCE_ADND2_EE,
        page: 168,
      },
    },
    {
      list: [
        [`идти дальше`, `бинурк`],
        [`круг слева от него`, `гетсун иннк’с арр`],
      ],
      source: {
        id: SOURCE_BOOK_SALVATORE_POTWK,
      },
    },
    {
      list: [
        [`убивать`, `ваус-хак`],
        [`собачий зуб`, `вгхотан`],
        [`штурмовать стены`, `уж-келлор`],
      ],
      source: {
        id: SOURCE_GAME_ICEWIND_DALE_2,
      },
    },
    {
      list: [[`вождь`, `гистпок`]],
      source: {
        id: SOURCE_BOOK_ABBEY_TNS,
      },
    },
    {
      list: [
        [`сердце битвы`, `грашмог`],
        [`место`, `олох`],
      ],
      source: {
        id: SOURCE_DND3_PGtF,
        page: 148,
      },
    },
    {
      list: [
        [`отойди`, `гунд ха`],
        [`отпускать`, `вука`],
        [`пропусти (меня)`, `мога мога`],
      ],
      source: {
        id: SOURCE_BOOK_SALVATORE_SN,
      },
    },
  ],
  description: [
    {
      text: `Язык гоблинов гортанный, состоит из хрюканья и всхлипываний.`,
      source: {
        id: SOURCE_BOOK_SALVATORE_EXILE,
      },
    },
    {
      text: `Гоблинский — единственный из основных языков, который не имеет собственного письма, что отражает грубую и варварскую природу гоблинской расы.`,
      source: {
        id: SOURCE_DND4_DMG,
        page: 172,
      },
    },
  ],
  isRealLang: true,
  isReady: true,
}
