const {
  SOURCE_MTOF,
  SOURCE_TTP,
} = require('./../../sourceList')
const {
  GOD_ELDATH,
  GOD_GOND,
  GOD_LATHANDER,
  GOD_MORADIN,
  GOD_SAVRAS,
  GOD_SELUNE,
  GOD_TYMORA,
  GOD_YONDALLA,
} = require ('./../../godIdList')

module.exports = [
  {
    header: 'Тортлы',
    text: `Тортлы — всеядные, черепахообразные гуманоиды с панцирями, покрывающими большую часть их тел. У тортлов есть поговорка: _«Дома мы носим на спине»_. Следовательно, тортл не остаётся подолгу на одном месте.

Рост взрослого тортла около 6 футов, а его вес разнится от 450 до 500 фунтов. Самцы и самки практически одинаковы по размерам и внешнему виду.`,
    source: {
      id: SOURCE_MTOF,
      page: 231,
    },
  },
  {
    header: 'Временные города',
    text: `Поселения тортлов в основном используются как места собраний, где они могут общаться друг с другом и торговать с незнакомцами. Тортлы не считают эти поселения стоящими того, чтобы защищать их ценой своей жизни, и они их покидают, когда больше в них не нуждаются.`,
    source: {
      id: SOURCE_MTOF,
      page: 231,
    },
  },
  {
    header: 'Жизнь, полная странствий',
    text: `Большинство тортлов любят смотреть, как живут другие существа и открывать для себя новые обычаи. Желание размножаться у них не появляется до конца своей жизни, и тортл может провести десятилетия вдали от своей родной земли, не чувствуя тоски по дому.

Тортлы рассматривают мир как место, где каждый день можно встретить новое чудо. Они живут ради шанса услышать лёгкий ветерок, вьющийся по пальмам, понаблюдать за лягушкой, квакающей на листьях кувшинок, или же постоять на многолюдном рынке.`,
    source: {
      id: SOURCE_MTOF,
      page: 231,
    },
  },
  {
    header: 'Тортлы',
    text: `То, что тортлы считают обычной жизнью, другие могут назвать приключениями. Тортлы рождаются на песчаных побережьях, но научаясь ходить на двух ногах, они часто становятся кочевниками, путешествуя по диким местам, изучая их чудеса, оттачивая свои навыки и заводя новые знакомства.`,
    source: {
      id: SOURCE_TTP,
      page: 3,
    },
  },
  {
    header: 'Жизнь тортла',
    text: `Тортл вылупляется из яйца с толстой скорлупой и ползает первые несколько недель своей жизни. Его родители, старые и уже близкие к смерти, тратят немногое оставшееся у них время, рассказывая своему молодняку истории. Через год юный тортл становится сиротой, хотя и не раньше, чем научится говорить и выживать самостоятельно.

Юные тортлы наследуют все оставшиеся от родителей инструменты, оружие и дары. Ожидается, что каждый молодой тортл будет сам о себе заботиться. Он покидает место своего рождения и находит собственный уголок дикой природы, в котором можно охотиться, ловить рыбу и жить дальше. С каждым годом тортл оттачивает навыки выживания. Он заводит дружбу с соседями, уважая при этом их личную жизнь. В какой-то момент тортл испытывает почти непреодолимое желание уйти подальше от дома и увидеть мир побольше. Он собирает свои пожитки и отправляется в дикие земли, возвращаясь спустя месяцы или годы с рассказами о своих подвигах и новых навыках.

Когда тортл подходит к концу своей естественной жизни, он ищет себе пару и производит потомство. Тортлы откладывают яйца (от одного до дюжины) в укреплённом месте, окруженном каменными стенами, которые легко защищать. Если такого места рядом нет, они его строят. Родители проводят остаток своей жизни, охраняя территорию, защищая своё потомство и делясь с ним знаниями до самой смерти. Когда дети становятся достаточно взрослыми, чтобы покинуть территорию, они собирают всё оружие и инструменты, оставленные родителями, и отправляются в собственные путешествия.`,
    source: {
      id: SOURCE_TTP,
      page: 3,
    },
  },
  {
    header: 'Религия',
    text: `У тортлов нет собственного пантеона богов, но они часто поклоняются богам других рас. Часто тортл слышит истории или легенды, связанные с богом, и решает поклоняться этому божеству. В Забытых Королевствах тортлы особенно любят [Эльдат](GOD:${GOD_ELDATH}), [Гонда](GOD:${GOD_GOND}), [Латандера](GOD:${GOD_LATHANDER}), [Савраса](GOD:${GOD_SAVRAS}), [Селунэ](GOD:${GOD_SELUNE}) и [Тимору](GOD:${GOD_TYMORA}). В сеттинге «Грейхок» они тяготеют к Целестиану, Фарлангу, Пелору, Фолту и Святому Катберту. Тортлов часто привлекают Боги Добра в Драконьем Копье и Суверенное Воинство в Эберроне. Среди нечеловеческих божеств для тортлов больше всего подходят [Морадин](GOD:${GOD_MORADIN}) и [Йондалла](GOD:${GOD_YONDALLA}).
      
Тортлы верят, что день и ночь присматривают за ними и другими существами. Луна — это ночное око, которое наблюдает за ними в темноте, а солнце — не менее бдительное око дня. Тортлы чувствуют себя спокойнее всего, когда один или оба ока смотрят на них сверху вниз. А когда в небе не видно очей, тортлы начинают нервничать и беспокоиться. Особенно тортлы не любят находиться под землей, где нет ни солнца, ни луны.

Благословенны дни, когда на небе видны и солнце, и луна одновременно. Тортлы стараются выбирать именно такие дни, чтобы покидать свои дома и отправляться в путешествие по диким местам или выполнять другие опасные задания.`,
    source: {
      id: SOURCE_TTP,
      page: 3,
    },
  },
  {
    header: 'Приключенцы в душе',
    text: `У тортлов есть поговорка: _«Дома мы носим на спине»_. Панцири, которые они носят с собой, обеспечивают им достаточное убежище. Поэтому тортлы не чувствуют необходимости надолго укореняться на одном месте. Поселение тортлов в основном используется как своего рода учебный лагерь, где тортлы могут общаться друг с другом, делиться полезной информацией и торговать с незнакомцами, будучи в безопасности из-за их большого числа. Тортлы не считают эти поселения местом, которое стоит защищать ценой жизни, и покидают их, как только оно перестаёт служить их нуждам.

Большинству тортлов нравится смотреть, как живут другие существа, и открывать для себя новые обычаи и новые способы ведения дел. Стремление к размножению не проявляется до самого конца жизни тортла, и он может проводить десятилетия вдали от родины, не чувствуя тоски по дому.

Тортлы придерживаются простого взгляда на мир. Это место чудес, и тортлы видят красоту в обыденном. Они живут ради возможности почувствовать мягкий ветерок, дующий сквозь пальмы, посмотреть, как на кувшинке квакает лягушка, или постоять на людном рынке.

Тортлы любят осваивать новые навыки. Они создают свои собственные инструменты и оружие, и хорошо умеют строить постройки и укрепления. Они восхищаются творчеством других цивилизованных существ, в частности людей, и могут на долгие годы затеряться в городе, изучая его архитектурные чудеса и изучая навыки, которые они могут использовать при строительстве укреплений для содержания потомства.

Хотя они проводят значительную часть своей жизни в изоляции, тортлы — социальные существа, которые любят заводить значимые дружеские отношения. У них нет врождённой враждебности к другим расам. Фактически, тортл часто ищет дружбу с не-тортлами, чтобы узнать новые обычаи и новые точки зрения.`,
    source: {
      id: SOURCE_TTP,
      page: 3,
    },
  },
  {
    header: 'Имена тортлов',
    text: `Тортлы предпочитают простые имена без гендерных различий, обычно не длиннее двух слогов. Если тортлу по какой-то причине не нравится его имя, он может его изменить. За свою жизнь тортл иногда меняет до дюжины имён.

У тортлов нет фамилий или семейных имён.

# Мужские и женские имена

Бака, Вабу, Гар, Гура, Даму, Зельбук, Зопа, Ини, Йог, Кви, Квот, Кек, Кинлек, Крулл, Лим, Лоп, Нортл, Нулка, Оло, Плогват, Санни, Тибор, Убо, Ухок, Яппа.`,
    source: {
      id: SOURCE_TTP,
      page: 3,
    },
  },
]
