const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CONDITION_BLINDED,
  CONDITION_DEAFENED,
} = require('./../../../../conditionList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {blightDescriptionList} = require('./../../../../textCommonParts')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_NEEDLE_BLIGHT} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_PLANT} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Игольчатая зараза',
  nameEn: 'Needle Blight',
  id: CREATURE_NEEDLE_BLIGHT,
  description: [
    `В тени леса игольчатая зараза издалека может быть принята за сгорбившегося гуманоида, передвигающегося шаркающей походкой. Вблизи же эти существа выглядят как ужасные растения, чьи иглы растут по всему телу. Игольчатый сорняк выбрасывает эти игры в стороны или запускает их как стрелы, которые пробивают доспех и плоть.

Когда игольчатая зараза обнаруживает угрозу, она выбрасывает пыльцу, которая с ветром передвигается к другим сородичам по всему лесу. Предупреждённые о местонахождении своих врагов, игольчатые заразы сходятся со всех сторон, чтобы омыть свои корни кровью.`,
    ...blightDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 157,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 3,
  },
  immunityConditionList: [
    [CONDITION_DEAFENED],
    [CONDITION_BLINDED],
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  languageList: [
    {
      id: LANG_COMMON,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_4,
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Иглы',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 3,
        range: {
          normal: 30,
          max: 60,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
