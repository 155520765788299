const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_0,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_BLESS,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  GEAR_HOLY_WATER,
} = require('./../../../../gearIdList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_IMP,
  CREATURE_LEMURE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Лемур',
  nameEn: 'Lemure',
  id: CREATURE_LEMURE,
  description: [
    `Лемуры появляются когда душа смертного испорчена злом и сослана навечно в Девять Преисподних. Самая низшая разновидность дьяволов, лемуры — отвратительные, бесформенные существа, обречённые страдать и мучиться до тех пор, пока не продвинутся до более высокой формы дьявола, например, до [беса](CREATURE:${CREATURE_IMP}).

Лемуры напоминают собой расплавленную массу плоти с чем-то вроде головы и гуманоидного торса. Постоянная гримаса страдания искривляет лицо, его слабый рот постоянно двигается, несмотря на то, что он не может говорить.`,
    ...devilDescriptionList,
  ],
  note: [
    {
      text: `Худшее из худших. Буквально — худшее. Я ненавидел быть лемуром. Не буду врать: это отвратительно. Ужас.`,
      author: `Пипъяп`,
    },
    ...devilNote,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 150,
  },
  armor: 7,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 15,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 5,
    [PARAM_CON]: 11,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 3,
  },
  resistanceList: [
    DAMAGE_COLD,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_FRIGHTENED],
    [CONDITION_POISONED],
    [CONDITION_CHARMED],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_INFERNAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    {
      name: 'Адское возрождение',
      description: `★СУЩЕСТВО★, умерший в Девяти Преисподних, возвращается к жизни с полными хитами через 1к10 дней, если только не был убит существом с добрым мировоззрением и находящимся под действием заклинания [Благословение](SPELL:${SPELL_BLESS}), и его останки не поливали [святой водой](GEAR:${GEAR_HOLY_WATER}).`,
    },
  ],
  actionList: [
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
