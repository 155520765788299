const {CREATURE_RAVEN} = require('./../../../creatureIdList')
const {GOD_RAVEN_QUEEN} = require('./../../../godIdList')
const {PC_SUBCLASS_WARLOCK_THE_RAVEN_QUEEN_2017_02_13} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2017_02_13_WARLOCK_WIZARD} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_COMMUNE,
  SPELL_CONE_OF_COLD,
  SPELL_FALSE_LIFE,
  SPELL_FEIGN_DEATH,
  SPELL_FINGER_OF_DEATH,
  SPELL_ICE_STORM,
  SPELL_LOCATE_CREATURE,
  SPELL_SANCTUARY,
  SPELL_SILENCE,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPIRITUAL_WEAPON,
} = require('./../../../spellIdList')
const {
  FEATURE_EXPANDED_SPELLS_THE_RAVEN_QUEEN_2017_02_13,
  FEATURE_QUEEN_S_RIGHT_HAND_2017_02_13,
  FEATURE_RAVEN_S_SHIELD_2017_02_13,
  FEATURE_SENTINEL_RAVEN_2017_02_13,
  FEATURE_SOUL_OF_THE_RAVEN_2017_02_13,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_THE_RAVEN_QUEEN_2017_02_13,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    text: `[Королева Воронов](GOD:${GOD_RAVEN_QUEEN}) позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_COMMUNE,
      SPELL_CONE_OF_COLD,
      SPELL_FALSE_LIFE,
      SPELL_FEIGN_DEATH,
      SPELL_ICE_STORM,
      SPELL_LOCATE_CREATURE,
      SPELL_SANCTUARY,
      SPELL_SILENCE,
      SPELL_SPEAK_WITH_DEAD,
      SPELL_SPIRITUAL_WEAPON,
    ],
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 2,
    },
  },
  {
    id: FEATURE_SENTINEL_RAVEN_2017_02_13,
    name: `Ворон-страж`,
    nameEn: `Sentinel Raven`,
    lvl: 1,
    text: `Вы получаете в услужение духа, посланного [Королевой Воронов](GOD:${GOD_RAVEN_QUEEN}) присматривать за Вами. Дух принимает обличье [ворона](CREATURE:${CREATURE_RAVEN}) и его игровые параметры. Он всегда подчиняется Вашим командам, которые Вы можете отдавать телепатически, находясь в пределах 100 футов от него.

Пока ворон сидит на Вашем плече, Вы получаете тёмное зрение дальностью 30 футов и бонус к Вашей Внимательности, равный Вашему модификатору Харизмы. Сидя на Вашем плече, ворон не может стать целью атак и прочих вредоносных эффектов. Только Вы можете сотворять на него заклинания. Он недееспособен и не может получать урон.

Вы можете видеть глазами ворона и слышать его ушами, находясь в пределах 100 футов от него.

В бою Вы совершаете отдельную проверку инициативы за ворона и управляете его действиями. Если он убит существом, Вы получаете преимущество к броскам атаки по его убийце на ближайшие 24 часа. Ворону не нужно спать. Пока он находится в пределах 100 футов от Вас, он может разбудить Вас бонусным действием.

Ворон исчезает когда умирает, когда Вы умираете или когда между Вами больше 5 миль.

В конце короткого или длинного отдыха Вы можете снова призвать ворона к себе — и, вне зависимости от того, где он находится и умер ли он — он появляется в пределах 5 футов от Вас.`,
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 2,
    },
  },
  {
    id: FEATURE_SOUL_OF_THE_RAVEN_2017_02_13,
    name: `Душа ворона`,
    nameEn: `Soul of the Raven`,
    lvl: 6,
    text: `Вы получаете способность слиться с духом своего ворона.

Пока ворон сидит на Вашем плече, Вы можете бонусным действием слить своё тело с обличьем ворона. Будучи слитым с ним, Вы становитесь Крохотного размера, заменяете свою скорость полёта скоростью полёта ворона и можете совершать только действия

* Засада, 
* Отход,
* Поиск,
* Помощь, 
* Рывок.

Кроме того, сливаясь с вороном, Вы получаете те же преимущества, которые получали бы, если бы ворон сидел на Вашем плече.

Вы можете действием разделить обратно себя и ворона, вернув Вам обоим нормальный облик.`,
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 2,
    },
  },
  {
    id: FEATURE_RAVEN_S_SHIELD_2017_02_13,
    name: `Щит ворона`,
    nameEn: `Raven’s Shield`,
    lvl: 10,
    text: `[Королева Воронов](GOD:${GOD_RAVEN_QUEEN}) даёт Вам охраняющее благословение.

Вы получаете преимущество к испытаниям от смерти, иммунитет к испугу и сопротивление некротическому урону.`,
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 2,
    },
  },
  {
    id: FEATURE_QUEEN_S_RIGHT_HAND_2017_02_13,
    name: `Правая рука королевы`,
    nameEn: `Queen’s Right Hand`,
    lvl: 14,
    text: `Вы можете пропустить через себя силу [Королевы Воронов](GOD:${GOD_RAVEN_QUEEN}) чтобы убить существо.

Вы можете сотворять заклинание [Перст смерти](SPELL:${SPELL_FINGER_OF_DEATH}).

После сотворения этого заклинания Вы не сможете использовать эту способность снова, пока не закончите длинный отдых.`,
    spellIdList: SPELL_FINGER_OF_DEATH,
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_RAVEN_QUEEN_2017_02_13,
  })
)
