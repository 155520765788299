const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {
  MAGIC_ITEM_ROBE_OF_SCINTILLATING_COLORS,
  MAGIC_ITEM_SPELLGUARD_SHIELD,
} = require('./../../../../magicItemIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_FLAIL_SNAIL} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {GEAR_SHIELD} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_REGENERATE} = require('./../../../../spellIdList')

module.exports = {
  name: 'Цеповая улитка',
  nameEn: 'Flail Snail',
  id: CREATURE_FLAIL_SNAIL,
  description: [
    `Цеповая улитка — существо стихии земли, которая ценится за свою разноцветную раковину. При виде этого неторопливого, внешне безвредного существа, у многих охотников складывается ложное ощущение самоуверенности. Но если любое существо, достаточно большое, чтобы быть угрозой для цеповой улитки, приближается к ней слишком близко, улитка выдаёт вспышку света и атакует своими палицеподобными щупальцами.`,
    {
      header: 'След из сокровищ',
      text: `Если её не беспокоить, цеповая улитка медленно движется по земле, поглощая всё на ее поверхности, включая камни, песок и почву, и останавливаясь, чтобы полакомиться выростами кристаллов или залежами минералов. За собой она оставляет мерцающий след, который быстро застывает и превращается в тонкий слой почти прозрачной субстанции, которая несъедобна для улитки. Этот стекловидный материал может быть собран и разрезан на панели для окон различной степени прозрачности. Также он может быть нагрет и преобразован в другие стеклянные объекты. Некоторые гуманоиды зарабатывают на жизнь, преследуя цеповых улиток и собирая это стекло.`,
      source: {
        id: SOURCE_VGTM,
        page: 202,
      },
    },
    {
      header: 'Использование раковины цеповой улитки',
      text: `Раковина цеповой улитки, весящая около 250 фунтов, обладает множеством применений. Неповреждённая раковина может быть продана за 5 000 зм.

Охотники ценят раковину за её антимагические свойства. Умелый бронник может сделать три [щита](GEAR:${GEAR_SHIELD}) из одной раковины. На один месяц каждый щит даёт своему носителю особенность улитки _Антимагическая раковина_. Когда магия щита ослабевает, остается экзотический щит, идеальный для изготовления [Щита от заклинаний](MAGIC_ITEM:${MAGIC_ITEM_SPELLGUARD_SHIELD}).

Также из раковины цеповой улитки можно сделать [Мантию сияющих цветов](MAGIC_ITEM:${MAGIC_ITEM_ROBE_OF_SCINTILLATING_COLORS}). Раковину расталкивают в порошок, который добавляется в краску, которой красится одеяние. Порошок также служит материальным компонентом ритуала, которым зачаровывается мантия.`,
      source: {
        id: SOURCE_VGTM,
        page: 202,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 202,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 10,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 5,
    [PARAM_CON]: 20,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Антимагическая раковина',
      description: `Улитка имеет преимущество на испытания от заклинаний и любое существо, делающее атаку заклинанием по улитке, получает помеху на попадание. Если улитка успешно проходит испытание от заклинания или атака заклинанием промахивается по ней, то может возникнуть дополнительный эффект, определяемый броском ку6ика:

* **1–2.** Если заклинание воздействует на область или имеет несколько целей, то оно проваливается и не имеет эффекта. Если заклинание имеет целью только улитку, то оно не имеет эффекта на улитку и отбрасывается назад в заклинателя, используя круг ячейки, СЛ испытания, бонус к попаданию и заклинательную способность заклинателя.
* **3–4.** Никакого дополнительного эффекта.
* **5–6.** Раковина улитки преобразует часть энергии заклинания во взрыв разрушающей силы. Каждое существо не далее 30 футов от улитки должно пройти испытание Телосложения СЛ 15 и получить по 1к6 урона силой за каждый круг заклинания при провале или половину этого урона при успехе.`,
    },
    {
      name: 'Цеповые щупальца',
      description: `Цеповая улитка имеет пять цеповых щупалец. Если улитка получает 10 или более урона в один ход, то одно из её щупалец погибает. Даже если останется только одно щупальце, то улитка восстановит все погибшие за 1к4 дней. Если все щупальца умрут, то улитка втягивается в раковину, получает полное укрытие и начинает вопить. Этот вопль слышно в радиусе 600 футов. Остановится она только когда умрёт через 5к6 минут. Магия лечения, которая восстанавливает части тела, такая как заклинание [Регенерация](SPELL:${SPELL_REGENERATE}), может остановить процесс умирания.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Цеповая улитка делает столько атак _Цеповыми щупальцами_, сколько у неё их осталось, всеми по одной цели.`,
    },
    {
      name: 'Цеповое щупальце',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Сверкающая раковина',
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `Раковина улитки испускает ослепительный, цветной свет до конца следующего хода улитки. Раковина испускает яркий свет в радиусе 30 футов, и существа, которые могут видеть улитку, получают помеху на атаки по ней. Кроме того, любое существо в ярком свете и могущее видеть улитку, должно пройти испытание Мудрости СЛ 15 в момент активации _Сверкающей раковины_ или стать ошеломлённым до окончания эффекта.`,
    },
    {
      name: 'Защита в раковине',
      description: `Цеповая улитка отступает в свою раковину и получает бонус +4 к КД до того, как снова появится. Она может появиться из раковины бонусным действием в свой ход.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
