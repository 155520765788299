module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_ARTIFICER = 'ability_score_improvement_artificer'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_BARBARIAN = 'ability_score_improvement_barbarian'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_BARD = 'ability_score_improvement_bard'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_CLERIC = 'ability_score_improvement_cleric'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_DRUID = 'ability_score_improvement_druid'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_FIGHTER = 'ability_score_improvement_fighter'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_MONK = 'ability_score_improvement_monk'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_PALADIN = 'ability_score_improvement_paladin'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_RANGER = 'ability_score_improvement_ranger'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_ROGUE = 'ability_score_improvement_rogue'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_SORCERER = 'ability_score_improvement_sorcerer'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_WARLOCK = 'ability_score_improvement_warlock'
module.exports.FEATURE_ABILITY_SCORE_IMPROVEMENT_WIZARD = 'ability_score_improvement_wizard'
module.exports.FEATURE_ABJURATION_SAVANT = 'abjuration_savant'
module.exports.FEATURE_ACCURSED_SPECTER = 'accursed_specter'
module.exports.FEATURE_ACOLYTE_OF_NATURE = 'acolyte_of_nature'
module.exports.FEATURE_ACTION_SURGE = 'action_surge'
module.exports.FEATURE_ADDITIONAL_FIGHTING_STYLE = 'additional_fighting_style'
module.exports.FEATURE_ADDITIONAL_FIGHTING_STYLE_2018_01_08 = 'additional_fighting_style_2018_01_08'
module.exports.FEATURE_ADDITIONAL_MAGICAL_SECRETS = 'additional_magical_secrets'
module.exports.FEATURE_ADJUST_DENSITY = 'adjust_density'
module.exports.FEATURE_ALCHEMICAL_SAVANT = 'alchemical_savant'
module.exports.FEATURE_ALCHEMIST_SPELLS = 'alchemist_spells'
module.exports.FEATURE_ALTER_MEMORIES = 'alter_memories'
module.exports.FEATURE_AMBUSH_MASTER = 'ambush_master'
module.exports.FEATURE_AMONG_THE_DEAD = 'among_the_dead'
module.exports.FEATURE_ANCESTRAL_PROTECTORS = 'ancestral_protectors'
module.exports.FEATURE_ANIMATING_PERFORMANCE = 'animating_performance'
module.exports.FEATURE_ANIMATING_PERFORMANCE_2020_02_06 = 'animating_performance_2020_02_06'
module.exports.FEATURE_ARCANA_DOMAIN_SPELLS = 'arcana_domain_spells'
module.exports.FEATURE_ARCANE_ABEYANCE = 'arcane_abeyance'
module.exports.FEATURE_ARCANE_ARCHER_LORE = 'arcane_archer_lore'
module.exports.FEATURE_ARCANE_ARMOR = 'arcane_armor'
module.exports.FEATURE_ARCANE_CHARGE = 'arcane_charge'
module.exports.FEATURE_ARCANE_DEFLECTION = 'arcane_deflection'
module.exports.FEATURE_ARCANE_FIREARM = 'arcane_firearm'
module.exports.FEATURE_ARCANE_INITIATE = 'arcane_initiate'
module.exports.FEATURE_ARCANE_JOLT = 'arcane_jolt'
module.exports.FEATURE_ARCANE_MASTERY = 'arcane_mastery'
module.exports.FEATURE_ARCANE_RECOVERY = 'arcane_recovery'
module.exports.FEATURE_ARCANE_SHOT = 'arcane_shot'
module.exports.FEATURE_ARCANE_TRADITION = 'arcane_tradition'
module.exports.FEATURE_ARCANE_WARD = 'arcane_ward'
module.exports.FEATURE_ARCHDRUID = 'archdruid'
module.exports.FEATURE_ARCHFEY_EXPANDED_SPELLS = 'archfey_expanded_spells'
module.exports.FEATURE_ARMOR_MODEL = 'armor_model'
module.exports.FEATURE_ARMOR_MODEL_2020_02_24 = 'armor_model_2020_02_24'
module.exports.FEATURE_ARMOR_MODIFICATIONS = 'armor_modifications'
module.exports.FEATURE_ARMOR_MODIFICATIONS_2020_02_24 = 'armor_modifications_2020_02_24'
module.exports.FEATURE_ARMOR_OF_HEXES = 'armor_of_hexes'
module.exports.FEATURE_ARMORER_SPELLS = 'armorer_spells'
module.exports.FEATURE_ARMORER_SPELLS_2020_02_24 = 'armorer_spells_2020_02_24'
module.exports.FEATURE_ARMS_OF_THE_ASTRAL_SELF = 'arms_of_the_astral_self'
module.exports.FEATURE_ARMS_OF_THE_ASTRAL_SELF_2019_08_15 = 'arms_of_the_astral_self_2019_08_15'
module.exports.FEATURE_ARTIFICER_SPECIALIST = 'artificer_specialist'
module.exports.FEATURE_ARTILLERIST_SPELLS = 'artillerist_spells'
module.exports.FEATURE_ASPECT_OF_THE_BEAST = 'aspect_of_the_beast'
module.exports.FEATURE_ASSASSINATE = 'assassinate'
module.exports.FEATURE_ASTRAL_REFUGE = 'astral_refuge'
module.exports.FEATURE_ASTRAL_SEQUESTRATION = 'astral_sequestration'
module.exports.FEATURE_AUDIENCE_WITH_DEATH_2019_10_17 = 'audience_with_death_2019_10_17'
module.exports.FEATURE_AURA_OF_ALACRITY = 'aura_of_alacrity'
module.exports.FEATURE_AURA_OF_CONQUEST = 'aura_of_conquest'
module.exports.FEATURE_AURA_OF_COURAGE = 'aura_of_courage'
module.exports.FEATURE_AURA_OF_DEVOTION = 'aura_of_devotion'
module.exports.FEATURE_AURA_OF_HATE = 'aura_of_hate'
module.exports.FEATURE_AURA_OF_PROTECTION = 'aura_of_protection'
module.exports.FEATURE_AURA_OF_THE_GUARDIAN = 'aura_of_the_guardian'
module.exports.FEATURE_AURA_OF_THE_SENTINEL = 'aura_of_the_sentinel'
module.exports.FEATURE_AURA_OF_TREACHERY_2016_12_19 = 'aura_of_treachery_2016_12_19'
module.exports.FEATURE_AURA_OF_WARDING = 'aura_of_warding'
module.exports.FEATURE_AVATAR_OF_BATTLE = 'avatar_of_battle'
module.exports.FEATURE_AVENGING_ANGEL = 'avenging_angel'
module.exports.FEATURE_AWAKENED_MIND = 'awakened_mind'
module.exports.FEATURE_AWAKENED_SPELLBOOK = 'awakened_spellbook'
module.exports.FEATURE_AWAKENING_OF_THE_ASTRAL_SELF = 'awakening_of_the_astral_self'
module.exports.FEATURE_AWAKENING_OF_THE_ASTRAL_SELF_2019_08_15 = 'awakening_of_the_astral_self_2019_08_15'
module.exports.FEATURE_BALM_OF_THE_SUMMER_COURT = 'balm_of_the_summer_court'
module.exports.FEATURE_BARD_COLLEGE = 'bard_college'
module.exports.FEATURE_BARDIC_INSPIRATION = 'bardic_inspiration'
module.exports.FEATURE_BARDIC_VERSATILITY = 'bardic_versatility'
module.exports.FEATURE_BATTLE_MAGIC = 'battle_magic'
module.exports.FEATURE_BATTLE_READY = 'battle_ready'
module.exports.FEATURE_BATTLE_SMITH_SPELLS = 'battle_smith_spells'
module.exports.FEATURE_BATTLERAGER_ARMOR = 'battlerager_armor'
module.exports.FEATURE_BATTLERAGER_CHARGE = 'battlerager_charge'
module.exports.FEATURE_BEAST_SPELLS = 'beast_spells'
module.exports.FEATURE_BEGUILING_DEFENSES = 'beguiling_defenses'
module.exports.FEATURE_BEGUILING_TWIST = 'beguiling_twist'
module.exports.FEATURE_BEND_LUCK = 'bend_luck'
module.exports.FEATURE_BENIGN_TRANSPOSITION = 'benign_transposition'
module.exports.FEATURE_BESTIAL_FURY = 'bestial_fury'
module.exports.FEATURE_BESTIAL_SOUL = 'bestial_soul'
module.exports.FEATURE_BLACKGUARD_S_ESCAPE_2016_12_19 = 'blackguard_s_escape_2016_12_19'
module.exports.FEATURE_BLADE_FLOURISH = 'blade_flourish'
module.exports.FEATURE_BLADESONG = 'bladesong'
module.exports.FEATURE_BLAZING_REVIVAL = 'blazing_revival'
module.exports.FEATURE_BLESSED_HEALER = 'blessed_healer'
module.exports.FEATURE_BLESSED_STRIKES = 'blessed_strikes'
module.exports.FEATURE_BLESSING_OF_THE_FORGE = 'blessing_of_the_forge'
module.exports.FEATURE_BLESSING_OF_THE_TRICKSTER = 'blessing_of_the_trickster'
module.exports.FEATURE_BLESSINGS_OF_KNOWLEDGE = 'blessings_of_knowledge'
module.exports.FEATURE_BLINDSENSE = 'blindsense'
module.exports.FEATURE_BODY_OF_THE_ASTRAL_SELF = 'body_of_the_astral_self'
module.exports.FEATURE_BOLSTERING_MAGIC = 'bolstering_magic'
module.exports.FEATURE_BOLSTERING_MAGIC_TCoE_PC_BPoWM = 'bolstering_magic_tcoe_pc_bpowm'
module.exports.FEATURE_BOLTS_FROM_THE_GRAVE_2019_10_17 = 'bolts_from_the_grave_2019_10_17'
module.exports.FEATURE_BONUS_CANTRIP_CIRCLE_OF_THE_LAND = 'bonus_cantrip_circle_of_the_land'
module.exports.FEATURE_BONUS_CANTRIP_LIGHT_DOMAIN = 'bonus_cantrip_light_domain'
module.exports.FEATURE_BONUS_CANTRIPS_WARLOCK_THE_CELESTIAL = 'bonus_cantrips_warlock_the_celestial'
module.exports.FEATURE_BONUS_PROFICIENCIES_2016_04_04 = 'bonus_proficiencies_2016_04_04'
module.exports.FEATURE_BONUS_PROFICIENCIES_ASSASSIN = 'bonus_proficiencies_assassin'
module.exports.FEATURE_BONUS_PROFICIENCIES_BARD_COLLEGE_OF_LORE = 'bonus_proficiencies_bard_college_of_lore'
module.exports.FEATURE_BONUS_PROFICIENCIES_BARD_COLLEGE_OF_SWORDS = 'bonus_proficiencies_bard_college_of_swords'
module.exports.FEATURE_BONUS_PROFICIENCIES_BARD_COLLEGE_OF_VALOR = 'bonus_proficiencies_bard_college_of_valor'
module.exports.FEATURE_BONUS_PROFICIENCIES_COLLEGE_OF_SATIRE_2016_01_04 = 'bonus_proficiencies_college_of_satire_2016_01_04'
module.exports.FEATURE_BONUS_PROFICIENCIES_DRUNKEN_MASTER = 'bonus_proficiencies_drunken_master'
module.exports.FEATURE_BONUS_PROFICIENCIES_FORGE_DOMAIN = 'bonus_proficiencies_forge_domain'
module.exports.FEATURE_BONUS_PROFICIENCIES_RUNE_KNIGHT = 'bonus_proficiencies_rune_knight'
module.exports.FEATURE_BONUS_PROFICIENCIES_TEMPEST_DOMAIN = 'bonus_proficiencies_tempest_domain'
module.exports.FEATURE_BONUS_PROFICIENCIES_TWILIGHT_DOMAIN = 'bonus_proficiencies_twilight_domain'
module.exports.FEATURE_BONUS_PROFICIENCIES_WAR_DOMAIN = 'bonus_proficiencies_war_domain'
module.exports.FEATURE_BONUS_PROFICIENCIES_WIZARD_ONOMANCY_2019_10_03 = 'bonus_proficiencies_wizard_onomancy_2019_10_03'
module.exports.FEATURE_BONUS_PROFICIENCY_DEATH_DOMAIN = 'bonus_proficiency_death_domain'
module.exports.FEATURE_BONUS_PROFICIENCY_FIGHTER_CAVALIER = 'bonus_proficiency_fighter_cavalier'
module.exports.FEATURE_BONUS_PROFICIENCY_FIGHTER_SAMURAI = 'bonus_proficiency_fighter_samurai'
module.exports.FEATURE_BONUS_PROFICIENCY_LIFE_DOMAIN = 'bonus_proficiency_life_domain'
module.exports.FEATURE_BONUS_PROFICIENCY_NATURE_DOMAIN = 'bonus_proficiency_nature_domain'
module.exports.FEATURE_BONUS_PROFICIENCY_ORDER_DOMAIN = 'bonus_proficiency_order_domain'
module.exports.FEATURE_BORN_TO_THE_SADDLE = 'born_to_the_saddle'
module.exports.FEATURE_BREATH_OF_WINTER = 'breath_of_winter'
module.exports.FEATURE_BRUTAL_CRITICAL = 'brutal_critical'
module.exports.FEATURE_BRUTE_FORCE_2018_01_08 = 'brute_force_2018_01_08'
module.exports.FEATURE_BRUTISH_DURABILITY_2018_01_08 = 'brutish_durability_2018_01_08'
module.exports.FEATURE_BULWARK = 'bulwark'
module.exports.FEATURE_BULWARK_OF_FORCE = 'bulwark_of_force'
module.exports.FEATURE_CALL_THE_HUNT = 'call_the_hunt'
module.exports.FEATURE_CANTRIP_FORMULAS = 'cantrip_formulas'
module.exports.FEATURE_CANTRIP_VERSATILITY_CLERIC = 'cantrip_versatility_cleric'
module.exports.FEATURE_CANTRIP_VERSATILITY_DRUID = 'cantrip_versatility_druid'
module.exports.FEATURE_CANTRIPS_ARCANE_TRICKSTER = 'cantrips_arcane_trickster'
module.exports.FEATURE_CANTRIPS_ARTIFICER = 'cantrips_artificer'
module.exports.FEATURE_CANTRIPS_BARD = 'cantrips_bard'
module.exports.FEATURE_CANTRIPS_CLERIC = 'cantrips_cleric'
module.exports.FEATURE_CANTRIPS_DRUID = 'cantrips_druid'
module.exports.FEATURE_CANTRIPS_ELDRITCH_KNIGHT = 'cantrips_eldritch_knight'
module.exports.FEATURE_CANTRIPS_SORCERER = 'cantrips_sorcerer'
module.exports.FEATURE_CANTRIPS_WARLOCK = 'cantrips_warlock'
module.exports.FEATURE_CANTRIPS_WIZARD = 'cantrips_wizard'
module.exports.FEATURE_CAREFUL_EYES_2016_12_05 = 'careful_eyes_2016_12_05'
module.exports.FEATURE_CASTING_ELEMENTAL_SPELLS = 'casting_elemental_spells'
module.exports.FEATURE_CAUTERIZING_FLAMES = 'cauterizing_flames'
module.exports.FEATURE_CELESTIAL_RESILIENCE = 'celestial_resilience'
module.exports.FEATURE_CHANNEL_DIVINITY_ABJURE_ENEMY = 'channel_divinity_abjure_enemy'
module.exports.FEATURE_CHANNEL_DIVINITY_ABJURE_THE_EXTRAPLANAR = 'channel_divinity_abjure_the_extraplanar'
module.exports.FEATURE_CHANNEL_DIVINITY_ARCANE_ABJURATION = 'channel_divinity_arcane_abjuration'
module.exports.FEATURE_CHANNEL_DIVINITY_ARTISAN_S_BLESSING = 'channel_divinity_artisan_s_blessing'
module.exports.FEATURE_CHANNEL_DIVINITY_BALM_OF_PEACE = 'channel_divinity_balm_of_peace'
module.exports.FEATURE_CHANNEL_DIVINITY_CHAMPION_CHALLENGE = 'channel_divinity_champion_challenge'
module.exports.FEATURE_CHANNEL_DIVINITY_CHARM_ANIMALS_AND_PLANTS = 'channel_divinity_charm_animals_and_plants'
module.exports.FEATURE_CHANNEL_DIVINITY_CLERIC = 'channel_divinity_cleric'
module.exports.FEATURE_CHANNEL_DIVINITY_CLOAK_OF_SHADOWS = 'channel_divinity_cloak_of_shadows'
module.exports.FEATURE_CHANNEL_DIVINITY_CONJURE_DUPLICATE_2016_12_19 = 'channel_divinity_conjure_duplicate_2016_12_19'
module.exports.FEATURE_CHANNEL_DIVINITY_CONQUERING_PRESENCE = 'channel_divinity_conquering_presence'
module.exports.FEATURE_CHANNEL_DIVINITY_CONTROL_UNDEAD = 'channel_divinity_control_undead'
module.exports.FEATURE_CHANNEL_DIVINITY_DESTRUCTIVE_WRATH = 'channel_divinity_destructive_wrath'
module.exports.FEATURE_CHANNEL_DIVINITY_DREADFUL_ASPECT = 'channel_divinity_dreadful_aspect'
module.exports.FEATURE_CHANNEL_DIVINITY_EMISSARY_OF_PEACE = 'channel_divinity_emissary_of_peace'
module.exports.FEATURE_CHANNEL_DIVINITY_GUIDED_STRIKE_OATH_OF_CONQUEST = 'channel_divinity_guided_strike'
module.exports.FEATURE_CHANNEL_DIVINITY_GUIDED_STRIKE_WAR_DOMAIN = 'channel_divinity_guided_strike_war_domain'
module.exports.FEATURE_CHANNEL_DIVINITY_INSPIRING_SMITE = 'channel_divinity_inspiring_smite'
module.exports.FEATURE_CHANNEL_DIVINITY_INVOKE_DUPLICITY = 'channel_divinity_invoke_duplicity'
module.exports.FEATURE_CHANNEL_DIVINITY_KNOWLEDGE_OF_THE_AGES = 'channel_divinity_knowledge_of_the_ages'
module.exports.FEATURE_CHANNEL_DIVINITY_LEGENDARY_STRIKE_2019_09_18 = 'channel_divinity_legendary_strike_2019_09_18'
module.exports.FEATURE_CHANNEL_DIVINITY_NATURE_S_WRATH = 'channel_divinity_nature_s_wrath'
module.exports.FEATURE_CHANNEL_DIVINITY_ORDER_S_DEMAND = 'channel_divinity_order_s_demand'
module.exports.FEATURE_CHANNEL_DIVINITY_PALADIN = 'channel_divinity_paladin'
module.exports.FEATURE_CHANNEL_DIVINITY_PATH_TO_THE_GRAVE = 'channel_divinity_path_to_the_grave'
module.exports.FEATURE_CHANNEL_DIVINITY_PEERLESS_ATHLETE = 'channel_divinity_peerless_athlete'
module.exports.FEATURE_CHANNEL_DIVINITY_PEERLESS_ATHLETE_2019_09_18 = 'channel_divinity_peerless_athlete_2019_09_18'
module.exports.FEATURE_CHANNEL_DIVINITY_POISON_STRIKE_2016_12_19 = 'channel_divinity_poison_strike_2016_12_19'
module.exports.FEATURE_CHANNEL_DIVINITY_PRESERVE_LIFE = 'channel_divinity_preserve_life'
module.exports.FEATURE_CHANNEL_DIVINITY_RADIANCE_OF_THE_DAWN = 'channel_divinity_radiance_of_the_dawn'
module.exports.FEATURE_CHANNEL_DIVINITY_READ_THOUGHTS = 'channel_divinity_read_thoughts'
module.exports.FEATURE_CHANNEL_DIVINITY_REBUKE_THE_VIOLENT = 'channel_divinity_rebuke_the_violent'
module.exports.FEATURE_CHANNEL_DIVINITY_SACRED_WEAPON = 'channel_divinity_sacred_weapon'
module.exports.FEATURE_CHANNEL_DIVINITY_TOUCH_OF_DEATH = 'channel_divinity_touch_of_death'
module.exports.FEATURE_CHANNEL_DIVINITY_TURN_THE_FAITHLESS = 'channel_divinity_turn_the_faithless'
module.exports.FEATURE_CHANNEL_DIVINITY_TURN_THE_TIDE = 'channel_divinity_turn_the_tide'
module.exports.FEATURE_CHANNEL_DIVINITY_TURN_THE_UNHOLY = 'channel_divinity_turn_the_unholy'
module.exports.FEATURE_CHANNEL_DIVINITY_TURN_UNDEAD = 'channel_divinity_turn_undead'
module.exports.FEATURE_CHANNEL_DIVINITY_TWILIGHT_SANCTUARY = 'channel_divinity_twilight_sanctuary'
module.exports.FEATURE_CHANNEL_DIVINITY_VOW_OF_ENMITY = 'channel_divinity_vow_of_enmity'
module.exports.FEATURE_CHANNEL_DIVINITY_WAR_GOD_S_BLESSING = 'channel_divinity_war_god_s_blessing'
module.exports.FEATURE_CHANNEL_DIVINITY_WATCHER_S_WILL = 'channel_divinity_watcher_s_will'
module.exports.FEATURE_CHEMICAL_MASTERY = 'chemical_mastery'
module.exports.FEATURE_CHRONAL_SHIFT = 'chronal_shift'
module.exports.FEATURE_CHRONURGY_SPELL_LIST = 'chronurgy_spell_list'
module.exports.FEATURE_CIRCLE_FORMS = 'circle_forms'
module.exports.FEATURE_CIRCLE_OF_MORTALITY = 'circle_of_mortality'
module.exports.FEATURE_CIRCLE_OF_SPORES_SPELLS = 'circle_of_spores_spells'
module.exports.FEATURE_CIRCLE_OF_SPORES_SPELLS_spellLvl_0 = 'circle_of_spores_spells_spellLvl_0'
module.exports.FEATURE_CIRCLE_OF_THE_LAND_SPELLS_ARCTIC = 'circle_of_the_land_spells_arctic'
module.exports.FEATURE_CIRCLE_OF_THE_LAND_SPELLS_COAST = 'circle_of_the_land_spells_coast'
module.exports.FEATURE_CIRCLE_OF_THE_LAND_SPELLS_DESERT = 'circle_of_the_land_spells_desert'
module.exports.FEATURE_CIRCLE_OF_THE_LAND_SPELLS_FOREST = 'circle_of_the_land_spells_forest'
module.exports.FEATURE_CIRCLE_OF_THE_LAND_SPELLS_GRASSLAND = 'circle_of_the_land_spells_grassland'
module.exports.FEATURE_CIRCLE_OF_THE_LAND_SPELLS_MOUNTAIN = 'circle_of_the_land_spells_mountain'
module.exports.FEATURE_CIRCLE_OF_THE_LAND_SPELLS_SWAMP = 'circle_of_the_land_spells_swamp'
module.exports.FEATURE_CIRCLE_OF_WILDFIRE_SPELLS = 'circle_of_wildfire_spells'
module.exports.FEATURE_CIRCLE_OF_WILDFIRE_SPELLS_2019_10_03 = 'circle_of_wildfire_spells_2019_10_03'
module.exports.FEATURE_CIRCLE_OF_WILDFIRE_SPELLS_2019_10_03_spellLvl_0 = 'circle_of_wildfire_spells_spellLvl_0_2019_10_03'
module.exports.FEATURE_CLEANSING_TOUCH = 'cleansing_touch'
module.exports.FEATURE_CLENCH_OF_THE_NORTH_WIND = 'clench_of_the_north_wind'
module.exports.FEATURE_CLOAK_OF_SHADOWS = 'cloak_of_shadows'
module.exports.FEATURE_CLOCKWORK_SPELLS = 'clockwork_spells'
module.exports.FEATURE_CLOSE_QUARTERS_SHOOTING_2016_12_05 = 'close_quarters_shooting_2016_12_05'
module.exports.FEATURE_COLLECTOR_S_CALL_2020_01_14 = 'collector_s_call_2020_01_14'
module.exports.FEATURE_COLLECTOR_S_VESSEL_2020_01_14 = 'collector_s_vessel_2020_01_14'
module.exports.FEATURE_COMBAT_INSPIRATION = 'combat_inspiration'
module.exports.FEATURE_COMBAT_SUPERIORITY = 'combat_superiority'
module.exports.FEATURE_COMBAT_SUPERIORITY_2016_04_04 = 'combat_superiority_2016_04_04'
module.exports.FEATURE_COMBAT_WILD_SHAPE = 'combat_wild_shape'
module.exports.FEATURE_COMMAND_UNDEAD = 'command_undead'
module.exports.FEATURE_COMPLETE_ASTRAL_SELF_2019_08_15 = 'complete_astral_self_2019_08_15'
module.exports.FEATURE_CONJURATION_SAVANT = 'conjuration_savant'
module.exports.FEATURE_CONNECT_WITH_THE_DEAD_2019_10_17 = 'connect_with_the_dead_2019_10_17'
module.exports.FEATURE_CONSULT_THE_SPIRITS = 'consult_the_spirits'
module.exports.FEATURE_CONTROLLED_CHAOS = 'controlled_chaos'
module.exports.FEATURE_CONTROLLED_SURGE = 'controlled_surge'
module.exports.FEATURE_CONTROLLED_SURGE_TCoE_PC_BPoWM = 'controlled_surge_tcoe_pc_bpowm'
module.exports.FEATURE_CONVERGENT_FUTURE = 'convergent_future'
module.exports.FEATURE_CORONA_OF_LIGHT = 'corona_of_light'
module.exports.FEATURE_COSMIC_OMEN = 'cosmic_omen'
module.exports.FEATURE_COUNTERCHARM = 'countercharm'
module.exports.FEATURE_CREATE_THRALL = 'create_thrall'
module.exports.FEATURE_CREATIVE_CRESCENDO = 'creative_crescendo'
module.exports.FEATURE_CUNNING_ACTION = 'cunning_action'
module.exports.FEATURE_CURVING_SHOT = 'curving_shot'
module.exports.FEATURE_CUTTING_WORDS = 'cutting_words'
module.exports.FEATURE_DAMPEN_ELEMENTS = 'dampen_elements'
module.exports.FEATURE_DANGER_SENSE = 'danger_sense'
module.exports.FEATURE_DARK_DELIRIUM = 'dark_delirium'
module.exports.FEATURE_DARK_ONE_S_BLESSING = 'dark_one_s_blessing'
module.exports.FEATURE_DARK_ONE_S_OWN_LUCK = 'dark_one_s_own_luck'
module.exports.FEATURE_DEATH_DOMAIN_SPELLS = 'death_domain_spells'
module.exports.FEATURE_DEATH_KNELL_2020_05_12 = 'death_knell_2020_05_12'
module.exports.FEATURE_DEATH_S_FRIEND = 'death_s_friend'
module.exports.FEATURE_DEATH_STRIKE = 'death_strike'
module.exports.FEATURE_DEDICATED_WEAPON = 'dedicated_weapon'
module.exports.FEATURE_DEEP_STALKER_CONCLAVE_UA_2016_09_12_THE_RANGER_REVISED_SPELLS = 'deep_stalker_conclave_ua_2016_09_12_the_ranger_revised_spells'
module.exports.FEATURE_DEEP_STALKER_UA_2015_11_02_LIGHT_DARK_UNDERDARK_SPELLS = 'deep_stalker_ua_2015_11_02_light_dark_underdark_spells'
module.exports.FEATURE_DEFENSIVE_TACTICS = 'defensive_tactics'
module.exports.FEATURE_DEFLECT_MISSILES = 'deflect_missiles'
module.exports.FEATURE_DEFLECTING_SHROUD = 'deflecting_shroud'
module.exports.FEATURE_DEFT_EXPLORER = 'deft_explorer'
module.exports.FEATURE_DEFY_DEATH = 'defy_death'
module.exports.FEATURE_DESTROY_UNDEAD = 'destroy_undead'
module.exports.FEATURE_DETECT_PORTAL = 'detect_portal'
module.exports.FEATURE_DEVASTATING_CRITICAL_2018_01_08 = 'devastating_critical_2018_01_08'
module.exports.FEATURE_DEVOURING_MAW_2019_05_09 = 'devouring_maw_2019_05_09'
module.exports.FEATURE_DIAMOND_SOUL = 'diamond_soul'
module.exports.FEATURE_DISCIPLE_OF_LIFE = 'disciple_of_life'
module.exports.FEATURE_DISCIPLE_OF_THE_ELEMENTS = 'disciple_of_the_elements'
module.exports.FEATURE_DISTANT_STRIKE = 'distant_strike'
module.exports.FEATURE_DIVINATION_SAVANT = 'divination_savant'
module.exports.FEATURE_DIVINE_ALLEGIANCE = 'divine_allegiance'
module.exports.FEATURE_DIVINE_DOMAIN = 'divine_domain'
module.exports.FEATURE_DIVINE_FURY = 'divine_fury'
module.exports.FEATURE_DIVINE_HEALTH = 'divine_health'
module.exports.FEATURE_DIVINE_INTERVENTION = 'divine_intervention'
module.exports.FEATURE_DIVINE_MAGIC_SORCERER = 'divine_magic_sorcerer'
module.exports.FEATURE_DIVINE_MAGIC_SORCERER_CHAOS = 'divine_magic_sorcerer_chaos'
module.exports.FEATURE_DIVINE_MAGIC_SORCERER_EVIL = 'divine_magic_sorcerer_evil'
module.exports.FEATURE_DIVINE_MAGIC_SORCERER_GOOD = 'divine_magic_sorcerer_good'
module.exports.FEATURE_DIVINE_MAGIC_SORCERER_LAW = 'divine_magic_sorcerer_law'
module.exports.FEATURE_DIVINE_MAGIC_SORCERER_NEUTRAL = 'divine_magic_sorcerer_neutral'
module.exports.FEATURE_DIVINE_SENSE = 'divine_sense'
module.exports.FEATURE_DIVINE_SMITE = 'divine_smite'
module.exports.FEATURE_DIVINE_STRIKE_DEATH_DOMAIN = 'divine_strike_death_domain'
module.exports.FEATURE_DIVINE_STRIKE_FORGE_DOMAIN = 'divine_strike_forge_domain'
module.exports.FEATURE_DIVINE_STRIKE_LIFE_DOMAIN = 'divine_strike_life_domain'
module.exports.FEATURE_DIVINE_STRIKE_NATURE_DOMAIN = 'divine_strike_nature_domain'
module.exports.FEATURE_DIVINE_STRIKE_ORDER_DEMAND = 'divine_strike_order_demand'
module.exports.FEATURE_DIVINE_STRIKE_TEMPEST_DOMAIN = 'divine_strike_tempest_domain'
module.exports.FEATURE_DIVINE_STRIKE_TRICKERY_DOMAIN = 'divine_strike_trickery_domain'
module.exports.FEATURE_DIVINE_STRIKE_TWILIGHT_DOMAIN = 'divine_strike_twilight_domain'
module.exports.FEATURE_DIVINE_STRIKE_WAR_DOMAIN = 'divine_strike'
module.exports.FEATURE_DOMAIN_SPELLS = 'domain_spells'
module.exports.FEATURE_DRACONIC_GIFT_2020_10_26 = 'draconic_gift_2020_10_26'
module.exports.FEATURE_DRACONIC_PRESENCE = 'draconic_presence'
module.exports.FEATURE_DRACONIC_RESILIENCE = 'draconic_resilience'
module.exports.FEATURE_DRAGON_ANCESTOR = 'dragon_ancestor'
module.exports.FEATURE_DRAGON_WINGS = 'dragon_wings'
module.exports.FEATURE_DREAD_AMBUSHER = 'dread_ambusher'
module.exports.FEATURE_DREAD_LORD = 'dread_lord'
module.exports.FEATURE_DREADFUL_STRIKES = 'dreadful_strikes'
module.exports.FEATURE_DRUID_CIRCLE = 'druid_circle'
module.exports.FEATURE_DRUIDIC = 'druidic'
module.exports.FEATURE_DRUNKARD_S_LUCK = 'drunkard_s_luck'
module.exports.FEATURE_DRUNKEN_TECHNIQUE = 'drunken_technique'
module.exports.FEATURE_DURABLE_MAGIC = 'durable_magic'
module.exports.FEATURE_DURABLE_SUMMONS = 'durable_summons'
module.exports.FEATURE_EAR_FOR_DECEIT = 'ear_for_deceit'
module.exports.FEATURE_ECHO_AVATAR = 'echo_avatar'
module.exports.FEATURE_ELDER_CHAMPION = 'elder_champion'
module.exports.FEATURE_ELDRITCH_CANNON = 'eldritch_cannon'
module.exports.FEATURE_ELDRITCH_INVOCATIONS = 'eldritch_invocations'
module.exports.FEATURE_ELDRITCH_MASTER = 'eldritch_master'
module.exports.FEATURE_ELDRITCH_STRIKE = 'eldritch_strike'
module.exports.FEATURE_ELDRITCH_VERSATILITY = 'eldritch_versatility'
module.exports.FEATURE_ELEGANT_COURTIER = 'elegant_courtier'
module.exports.FEATURE_ELEGANT_MANEUVER = 'elegant_maneuver'
module.exports.FEATURE_ELEMENTAL_AFFINITY = 'elemental_affinity'
module.exports.FEATURE_ELEMENTAL_GIFT = 'elemental_gift'
module.exports.FEATURE_ELEMENTAL_GIFT_2020_05_12 = 'elemental_gift_2020_05_12'
module.exports.FEATURE_ELEMENTAL_RESISTANCE_2020_01_14 = 'elemental_resistance_2020_01_14'
module.exports.FEATURE_ELEMENTAL_WILD_SHAPE = 'elemental_wild_shape'
module.exports.FEATURE_ELOQUENT_APPRENTICE_2021_06_08 = 'eloquent_apprentice_2021_06_08'
module.exports.FEATURE_ELUSIVE = 'elusive'
module.exports.FEATURE_EMBODIMENT_OF_THE_LAW = 'embodiment_of_the_law'
module.exports.FEATURE_EMBOLDENING_BOND = 'emboldening_bond'
module.exports.FEATURE_EMISSARY_OF_REDEMPTION = 'emissary_of_redemption'
module.exports.FEATURE_EMPOWERED_EVOCATION = 'empowered_evocation'
module.exports.FEATURE_EMPOWERED_HEALING = 'empowered_healing'
module.exports.FEATURE_EMPTY_BODY = 'empty_body'
module.exports.FEATURE_ENCHANTMENT_SAVANT = 'enchantment_savant'
module.exports.FEATURE_ENHANCED_BOND = 'enhanced_bond'
module.exports.FEATURE_ENTHRALLING_PERFORMANCE = 'enthralling_performance'
module.exports.FEATURE_ENTROPIC_WARD = 'entropic_ward'
module.exports.FEATURE_ETERNAL_MOUNTAIN_DEFENSE = 'eternal_mountain_defense'
module.exports.FEATURE_ETHEREAL_JAUNT_2019_10_17 = 'ethereal_jaunt_2019_10_17'
module.exports.FEATURE_ETHEREAL_STEP = 'ethereal_step'
module.exports.FEATURE_EVASION_MONK = 'evasion'
module.exports.FEATURE_EVASION_ROGUE = 'evasion_rogue'
module.exports.FEATURE_EVENT_HORIZON = 'event_horizon'
module.exports.FEATURE_EVER_READY_SHOT = 'ever_ready_shot'
module.exports.FEATURE_EVOCATION_SAVANT = 'evocation_savant'
module.exports.FEATURE_EXALTED_CHAMPION = 'exalted_champion'
module.exports.FEATURE_EXCEPTIONAL_TRAINING = 'exceptional_training'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE = 'genie_expanded_spells'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE_2020_05_12 = 'genie_expanded_spells_2020_05_12'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE_DAO = 'dao_expanded_spells'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE_DAO_2020_05_12 = 'dao_expanded_spells_2020_05_12'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE_DJINNI = 'djinni_expanded_spells'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE_DJINNI_2020_05_12 = 'djinni_expanded_spells_2020_05_12'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE_EFREETI = 'efreeti_expanded_spells'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE_EFREETI_2020_05_12 = 'efreeti_expanded_spells_2020_05_12'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE_MARID = 'marid_expanded_spells'
module.exports.FEATURE_EXPANDED_SPELLS_THE_GENIE_MARID_2020_05_12 = 'marid_expanded_spells_2020_05_12'
module.exports.FEATURE_EXPANDED_SPELLS_THE_RAVEN_QUEEN_2017_02_13 = 'raven_queen_expanded_spells_2017_02_13'
module.exports.FEATURE_EXPANDED_SPELLS_WARLOCK_THE_CELESTIAL = 'expanded_spells_warlock_the_celestial'
module.exports.FEATURE_EXPANDED_SPELLS_WARLOCK_THE_FIEND = 'expanded_spells_warlock_the_fiend'
module.exports.FEATURE_EXPANDED_SPELLS_WARLOCK_THE_GREAT_OLD_ONE = 'expanded_spells_warlock_the_great_old_one'
module.exports.FEATURE_EXPANDED_SPELLS_WARLOCK_THE_HEXBLADE = 'expanded_spells_warlock_the_hexblade'
module.exports.FEATURE_EXPANDED_SPELLS_WARLOCK_THE_LURKER_IN_THE_DEEP_2019_05_09 = 'expanded_spells_warlock_the_lurker_in_the_deep_2019_05_09'
module.exports.FEATURE_EXPANDED_SPELLS_WARLOCK_THE_NOBLE_GENIE_2020_01_14 = 'expanded_spells_warlock_the_noble_genie_2020_01_14'
module.exports.FEATURE_EXPANDED_SPELLS_WARLOCK_THE_UNDEAD_2020_08_05 = 'expanded_spells_warlock_the_undead_2020_08_05'
module.exports.FEATURE_EXPANDED_SPELLS_WARLOCK_THE_UNDYING = 'expanded_spells_warlock_the_undying'
module.exports.FEATURE_EXPANSIVE_BOND = 'expansive_bond'
module.exports.FEATURE_EXPERIMENTAL_ELIXIR = 'experimental_elixir'
module.exports.FEATURE_EXPERT_DIVINATION = 'expert_divination'
module.exports.FEATURE_EXPERTISE_BARD = 'expertise'
module.exports.FEATURE_EXPERTISE_ROGUE = 'expertise_rogue'
module.exports.FEATURE_EXPLOSIVE_CANNON = 'explosive_cannon'
module.exports.FEATURE_EXTRA_ATTACK_ARTIFICER_ARMORER = 'extra_attack_artificer_armorer'
module.exports.FEATURE_EXTRA_ATTACK_ARTIFICER_ARMORER_2020_02_24 = 'extra_attack_artificer_armorer_2020_02_24'
module.exports.FEATURE_EXTRA_ATTACK_ARTIFICER_BATTLE_SMITH = 'extra_attack_artificer_battle_smith'
module.exports.FEATURE_EXTRA_ATTACK_BARBARIAN = 'extra_attack_barbarian'
module.exports.FEATURE_EXTRA_ATTACK_BARD_COLLEGE_OF_SWORDS = 'extra_attack_bard_college_of_swords'
module.exports.FEATURE_EXTRA_ATTACK_BARD_COLLEGE_OF_VALOR = 'extra_attack_bard_college_of_valor'
module.exports.FEATURE_EXTRA_ATTACK_FIGHTER = 'extra_attack_fighter'
module.exports.FEATURE_EXTRA_ATTACK_MONK = 'extra_attack_monk'
module.exports.FEATURE_EXTRA_ATTACK_PALADIN = 'extra_attack_paladin'
module.exports.FEATURE_EXTRA_ATTACK_RANGER = 'extra_attack_ranger'
module.exports.FEATURE_EXTRA_ATTACK_WIZARD_BLADESINGING = 'extra_attack_wizard_bladesinging'
module.exports.FEATURE_EXTRACT_NAME_2019_10_03 = 'extract_name_2019_10_03'
module.exports.FEATURE_EYE_FOR_DETAIL = 'eye_for_detail'
module.exports.FEATURE_EYE_FOR_WEAKNESS = 'eye_for_weakness'
module.exports.FEATURE_EYES_OF_NIGHT = 'eyes_of_night'
module.exports.FEATURE_EYES_OF_THE_DARK = 'eyes_of_the_dark'
module.exports.FEATURE_EYES_OF_THE_DEEP = 'eyes_of_the_deep'
module.exports.FEATURE_EYES_OF_THE_GRAVE = 'eyes_of_the_grave'
module.exports.FEATURE_FAITHFUL_SUMMONS = 'faithful_summons'
module.exports.FEATURE_FANATICAL_FOCUS = 'fanatical_focus'
module.exports.FEATURE_FANCY_FOOTWORK = 'fancy_footwork'
module.exports.FEATURE_FANGS_OF_THE_FIRE_SNAKE = 'fangs_of_the_fire_snake'
module.exports.FEATURE_FAR_WANDERER = 'far_wanderer'
module.exports.FEATURE_FAST_HANDS = 'fast_hands'
module.exports.FEATURE_FAST_MOVEMENT = 'fast_movement'
module.exports.FEATURE_FATEFUL_NAMING_2019_10_03 = 'fateful_naming_2019_10_03'
module.exports.FEATURE_FATHOMLESS_SOUL_2019_05_09 = 'fathomless_soul_2019_05_09'
module.exports.FEATURE_FAVORED_BY_THE_GODS = 'favored_by_the_gods'
module.exports.FEATURE_FAVORED_ENEMY = 'favored_enemy'
module.exports.FEATURE_FAVORED_FOE = 'favored_foe'
module.exports.FEATURE_FERAL_INSTINCT = 'feral_instinct'
module.exports.FEATURE_FERAL_SENSES = 'feral_senses'
module.exports.FEATURE_FEROCIOUS_CHARGER = 'ferocious_charger'
module.exports.FEATURE_FEY_PRESENCE = 'fey_presence'
module.exports.FEATURE_FEY_REINFORCEMENTS = 'fey_reinforcements'
module.exports.FEATURE_FEY_WANDERER_MAGIC = 'fey_wanderer_magic'
module.exports.FEATURE_FEY_WANDERER_MAGIC_2020_02_24 = 'fey_wanderer_magic_2020_02_24'
module.exports.FEATURE_FIENDISH_RESILIENCE = 'fiendish_resilience'
module.exports.FEATURE_FIGHTING_SPIRIT = 'fighting_spirit'
module.exports.FEATURE_FIGHTING_STYLE_BARD_COLLEGE_OF_SWORDS = 'fighting_style_bard_college_of_swords'
module.exports.FEATURE_FIGHTING_STYLE_FIGHTER = 'fighting_style_fighter'
module.exports.FEATURE_FIGHTING_STYLE_PALADIN = 'fighting_style_paladin'
module.exports.FEATURE_FIGHTING_STYLE_RANGER = 'fighting_style_ranger'
module.exports.FEATURE_FIST_OF_FOUR_THUNDERS = 'fist_of_four_thunders'
module.exports.FEATURE_FLAMES_OF_THE_PHOENIX = 'flames_of_the_phoenix'
module.exports.FEATURE_FLASH_OF_GENIUS = 'flash_of_genius'
module.exports.FEATURE_FLEXIBLE_CASTING = 'flexible_casting'
module.exports.FEATURE_FLURRY_OF_HEALING_AND_HARM = 'flurry_of_healing_and_harm'
module.exports.FEATURE_FOCUSED_AIM = 'focused_aim'
module.exports.FEATURE_FOCUSED_CONJURATION = 'focused_conjuration'
module.exports.FEATURE_FOE_SLAYER = 'foe_slayer'
module.exports.FEATURE_FONT_OF_INSPIRATION = 'font_of_inspiration'
module.exports.FEATURE_FONT_OF_MAGIC = 'font_of_magic'
module.exports.FEATURE_FOOL_S_INSIGHT_2016_01_04 = 'fool_s_insight_2016_01_04'
module.exports.FEATURE_FOOL_S_LUCK_2016_01_04 = 'fool_s_luck_2016_01_04'
module.exports.FEATURE_FORGE_DOMAIN_SPELLS = 'forge_domain_spells'
module.exports.FEATURE_FORM_OF_DREAD_2020_08_05 = 'form_of_dread_2020_08_05'
module.exports.FEATURE_FORM_OF_THE_BEAST = 'form_of_the_beast'
module.exports.FEATURE_FORTIFIED_POSITION = 'fortified_position'
module.exports.FEATURE_FRENZY = 'frenzy'
module.exports.FEATURE_FULL_OF_STARS = 'full_of_stars'
module.exports.FEATURE_FUNGAL_BODY = 'fungal_body'
module.exports.FEATURE_FUNGAL_INFESTATION = 'fungal_infestation'
module.exports.FEATURE_GATHERED_SWARM = 'gathered_swarm'
module.exports.FEATURE_GENIE_S_ENTERTAINMENT_2020_01_14 = 'genie_s_entertainment_2020_01_14'
module.exports.FEATURE_GENIE_S_VESSEL = 'genie_s_vessel'
module.exports.FEATURE_GENIE_S_VESSEL_2020_05_12 = 'genie_s_vessel_2020_05_12'
module.exports.FEATURE_GHOST_WALK = 'ghost_walk'
module.exports.FEATURE_GHOST_WALK_2020_05_12 = 'ghost_walk_2020_05_12'
module.exports.FEATURE_GIANT_S_MIGHT = 'giant_s_might'
module.exports.FEATURE_GLOOM_STALKER_SPELLS = 'gloom_stalker_spells'
module.exports.FEATURE_GLORIOUS_DEFENSE = 'glorious_defense'
module.exports.FEATURE_GLORIOUS_DEFENSE_2019_09_18 = 'glorious_defense_2019_09_18'
module.exports.FEATURE_GONG_OF_THE_SUMMIT = 'gong_of_the_summit'
module.exports.FEATURE_GRASP_OF_THE_DEEP_2019_05_09 = 'grasp_of_the_deep_2019_05_09'
module.exports.FEATURE_GRAVE_DOMAIN_SPELLS = 'grave_domain_spells'
module.exports.FEATURE_GRAVE_TOUCHED_2020_08_05 = 'grave_touched_2020_08_05'
module.exports.FEATURE_GRAVITURGY_SPELL_LIST = 'graviturgy_spell_list'
module.exports.FEATURE_GRAVITY_WELL = 'gravity_well'
module.exports.FEATURE_GREAT_STATURE = 'great_stature'
module.exports.FEATURE_GREATER_PORTENT = 'greater_portent'
module.exports.FEATURE_GRIM_HARVEST = 'grim_harvest'
module.exports.FEATURE_GUARDED_MIND = 'guarded_mind'
module.exports.FEATURE_GUARDIAN_GRASP_2019_05_09 = 'guardian_grasp_2019_05_09'
module.exports.FEATURE_GUARDIAN_SPIRIT = 'guardian_spirit'
module.exports.FEATURE_GUARDIAN_SPIRIT_2016_11_28 = 'guardian_spirit_2016_11_28'
module.exports.FEATURE_GUIDING_WHISPERS = 'guiding_whispers'
module.exports.FEATURE_HALO_OF_SPORES = 'halo_of_spores'
module.exports.FEATURE_HAND_OF_HARM = 'hand_of_harm'
module.exports.FEATURE_HAND_OF_HEALING = 'hand_of_healing'
module.exports.FEATURE_HAND_OF_ULTIMATE_MERCY = 'hand_of_ultimate_mercy'
module.exports.FEATURE_HARNESS_DIVINE_POWER_CLERIC = 'harness_divine_power_cleric'
module.exports.FEATURE_HARNESS_DIVINE_POWER_PALADIN = 'harness_divine_power_paladin'
module.exports.FEATURE_HEALING_LIGHT = 'healing_light'
module.exports.FEATURE_HEART_OF_THE_STORM = 'heart_of_the_storm'
module.exports.FEATURE_HEARTH_OF_MOONLIGHT_AND_SHADOW = 'hearth_of_moonlight_and_shadow'
module.exports.FEATURE_HEX_WARRIOR = 'hex_warrior'
module.exports.FEATURE_HEXBLADE_S_CURSE = 'hexblade_s_curse'
module.exports.FEATURE_HIDDEN_PATHS = 'hidden_paths'
module.exports.FEATURE_HIDE_IN_PLAIN_SIGHT = 'hide_in_plain_sight'
module.exports.FEATURE_HOLD_THE_LINE = 'hold_the_line'
module.exports.FEATURE_HOLY_NIMBUS = 'holy_nimbus'
module.exports.FEATURE_HORIZON_WALKER_SPELLS = 'horizon_walker_spells'
module.exports.FEATURE_HORIZON_WALKER_SPELLS_UA_2017_01_16_RANGER_ROGUE = 'horizon_walker_spells_ua_2017_01_16_ranger_rogue'
module.exports.FEATURE_HOUND_OF_ILL_OMEN = 'hound_of_ill_omen'
module.exports.FEATURE_HOUR_OF_REAPING = 'hour_of_reaping'
module.exports.FEATURE_HUNTER_S_PREY = 'hunter_s_prey'
module.exports.FEATURE_HUNTER_S_SENSE = 'hunter_s_sense'
module.exports.FEATURE_HUNTERS_MYSTICISM_2016_04_04 = 'hunters_mysticism_2016_04_04'
module.exports.FEATURE_HURL_THROUGH_HELL = 'hurl_through_hell'
module.exports.FEATURE_HYPNOTIC_GAZE = 'hypnotic_gaze'
module.exports.FEATURE_ICON_OF_DECEIT_2016_12_19 = 'icon_of_deceit_2016_12_19'
module.exports.FEATURE_ILLUSION_SAVANT = 'illusion_savant'
module.exports.FEATURE_ILLUSORY_REALITY = 'illusory_reality'
module.exports.FEATURE_ILLUSORY_SELF = 'illusory_self'
module.exports.FEATURE_IMPLEMENT_OF_PEACE = 'implement_of_peace'
module.exports.FEATURE_IMPLEMENTS_OF_MERCY = 'implements_of_mercy'
module.exports.FEATURE_IMPOSTOR = 'impostor'
module.exports.FEATURE_IMPROVED_ABJURATION = 'improved_abjuration'
module.exports.FEATURE_IMPROVED_COMBAT_SUPERIORITY = 'improved_combat_superiority'
module.exports.FEATURE_IMPROVED_COMBAT_SUPERIORITY_2016_04_04 = 'improved_combat_superiority_2016_04_04'
module.exports.FEATURE_IMPROVED_CRITICAL = 'improved_critical'
module.exports.FEATURE_IMPROVED_DEFENDER = 'improved_defender'
module.exports.FEATURE_IMPROVED_DIVINE_SMITE = 'improved_divine_smite'
module.exports.FEATURE_IMPROVED_DUPLICITY = 'improved_duplicity'
module.exports.FEATURE_IMPROVED_FLARE = 'improved_flare'
module.exports.FEATURE_IMPROVED_MINOR_ILLUSION = 'improved_minor_illusion'
module.exports.FEATURE_IMPROVED_REAPER = 'improved_reaper'
module.exports.FEATURE_IMPROVED_WAR_MAGIC = 'improved_war_magic'
module.exports.FEATURE_INDESTRUCTIBLE_LIFE = 'indestructible_life'
module.exports.FEATURE_INDOMITABLE = 'indomitable'
module.exports.FEATURE_INDOMITABLE_MIGHT = 'indomitable_might'
module.exports.FEATURE_INESCAPABLE_DESTRUCTION = 'inescapable_destruction'
module.exports.FEATURE_INEXORABLE_PRONOUNCEMENT_2019_10_03 = 'inexorable_pronouncement_2019_10_03'
module.exports.FEATURE_INFECTIOUS_FURY = 'infectious_fury'
module.exports.FEATURE_INFECTIOUS_INSPIRATION = 'infectious_inspiration'
module.exports.FEATURE_INFILTRATION_EXPERTISE = 'infiltration_expertise'
module.exports.FEATURE_INFUSE_ITEM = 'infuse_item'
module.exports.FEATURE_INFUSING_AN_ITEM = 'infusing_an_item'
module.exports.FEATURE_INFUSIONS_KNOWN = 'infusions_known'
module.exports.FEATURE_INSIGHTFUL_FIGHTING = 'insightful_fighting'
module.exports.FEATURE_INSIGHTFUL_MANIPULATOR = 'insightful_manipulator'
module.exports.FEATURE_INSPIRING_SURGE = 'inspiring_surge'
module.exports.FEATURE_INSTINCTIVE_CHARM = 'instinctive_charm'
module.exports.FEATURE_INSTINCTIVE_POUNCE = 'instinctive_pounce'
module.exports.FEATURE_INTIMIDATING_PRESENCE = 'intimidating_presence'
module.exports.FEATURE_INTOXICATED_FRENZY = 'intoxicated_frenzy'
module.exports.FEATURE_INURED_TO_UNDEATH = 'inured_to_undeath'
module.exports.FEATURE_INVINCIBLE_CONQUEROR = 'invincible_conqueror'
module.exports.FEATURE_IRON_MIND = 'iron_mind'
module.exports.FEATURE_JACK_OF_ALL_TRADES = 'jack_of_all_trades'
module.exports.FEATURE_KEEPER_OF_SOULS = 'keeper_of_souls'
module.exports.FEATURE_KI = 'ki'
module.exports.FEATURE_KI_EMPOWERED_STRIKES = 'ki_empowered_strikes'
module.exports.FEATURE_KI_FUELED_ATTACK = 'ki_fueled_attack'
module.exports.FEATURE_KNOW_YOUR_ENEMY = 'know_your_enemy'
module.exports.FEATURE_KNOWLEDGE_DOMAIN_SPELLS = 'knowledge_domain_spells'
module.exports.FEATURE_LAND_S_STRIDE = 'land_s_stride'
module.exports.FEATURE_LANDS_STRIDE = 'lands_stride'
module.exports.FEATURE_LAY_ON_HANDS = 'lay_on_hands'
module.exports.FEATURE_LEGION_OF_ONE = 'legion_of_one'
module.exports.FEATURE_LIFE_DOMAIN_SPELLS = 'life_domain_spells'
module.exports.FEATURE_LIGHT_DOMAIN_SPELLS = 'light_domain_spells'
module.exports.FEATURE_LIMITED_WISH = 'limited_wish'
module.exports.FEATURE_LIMITED_WISH_2020_05_12 = 'limited_wish_2020_05_12'
module.exports.FEATURE_LINGERING_MAGIC = 'lingering_magic'
module.exports.FEATURE_LIVING_LEGEND = 'living_legend'
module.exports.FEATURE_LIVING_MYTH_2019_09_18 = 'living_myth_2019_09_18'
module.exports.FEATURE_LOREHOLD_SPELLS_2021_06_08 = 'lorehold_spells_2021_06_08'
module.exports.FEATURE_MAGE_HAND_LEGERDEMAIN = 'mage_hand_legerdemain'
module.exports.FEATURE_MAGIC_ARROW = 'magic_arrow'
module.exports.FEATURE_MAGIC_AWARENESS = 'magic_awareness'
module.exports.FEATURE_MAGIC_AWARENESS_TCoE_PC_BPoWM = 'magic_awareness_tcoe_pc_bpowm'
module.exports.FEATURE_MAGIC_ITEM_ADEPT = 'magic_item_adept'
module.exports.FEATURE_MAGIC_ITEM_MASTER = 'magic_item_master'
module.exports.FEATURE_MAGIC_ITEM_SAVANT = 'magic_item_savant'
module.exports.FEATURE_MAGIC_USER_S_NEMESIS = 'magic_user_s_nemesis'
module.exports.FEATURE_MAGICAL_AMBUSH = 'magical_ambush'
module.exports.FEATURE_MAGICAL_GUIDANCE = 'magical_guidance'
module.exports.FEATURE_MAGICAL_INSPIRATION = 'magical_inspiration'
module.exports.FEATURE_MAGICAL_SECRETS = 'magical_secrets'
module.exports.FEATURE_MAGICAL_TINKERING = 'magical_tinkering'
module.exports.FEATURE_MALLEABLE_ILLUSIONS = 'malleable_illusions'
module.exports.FEATURE_MANIFEST_ECHO = 'manifest_echo'
module.exports.FEATURE_MANIFEST_MIND = 'manifest_mind'
module.exports.FEATURE_MANTLE_OF_INSPIRATION = 'mantle_of_inspiration'
module.exports.FEATURE_MANTLE_OF_MAJESTY = 'mantle_of_majesty'
module.exports.FEATURE_MANTLE_OF_MAJESTY_2016_11_14 = 'mantle_of_majesty_2016_11_14'
module.exports.FEATURE_MANTLE_OF_WHISPERS = 'mantle_of_whispers'
module.exports.FEATURE_MARK_OF_THE_ORDNING_CLOUD = 'mark_of_the_ordning_cloud'
module.exports.FEATURE_MARK_OF_THE_ORDNING_FIRE = 'mark_of_the_ordning_fire'
module.exports.FEATURE_MARK_OF_THE_ORDNING_FROST = 'mark_of_the_ordning_frost'
module.exports.FEATURE_MARK_OF_THE_ORDNING_HILL = 'mark_of_the_ordning_hill'
module.exports.FEATURE_MARK_OF_THE_ORDNING_STONE = 'mark_of_the_ordning_stone'
module.exports.FEATURE_MARK_OF_THE_ORDNING_STORM = 'mark_of_the_ordning_storm'
module.exports.FEATURE_MARTIAL_ARCHETYPE = 'martial_archetype'
module.exports.FEATURE_MARTIAL_ARTS = 'martial_arts'
module.exports.FEATURE_MARTIAL_VERSATILITY_FIGHTER = 'martial_versatility'
module.exports.FEATURE_MARTIAL_VERSATILITY_PALADIN = 'martial_versatility_paladin'
module.exports.FEATURE_MARTIAL_VERSATILITY_RANGER = 'martial_versatility_ranger'
module.exports.FEATURE_MASTER_DUELIST = 'master_duelist'
module.exports.FEATURE_MASTER_OF_HEXES = 'master_of_hexes'
module.exports.FEATURE_MASTER_OF_INTRIGUE = 'master_of_intrigue'
module.exports.FEATURE_MASTER_OF_NATURE = 'master_of_nature'
module.exports.FEATURE_MASTER_OF_RUNES = 'master_of_runes'
module.exports.FEATURE_MASTER_OF_TACTICS = 'master_of_tactics'
module.exports.FEATURE_MASTER_S_FLOURISH = 'master_s_flourish'
module.exports.FEATURE_MASTER_SCRIVENER = 'master_scrivener'
module.exports.FEATURE_MASTER_TRANSMUTER = 'master_transmuter'
module.exports.FEATURE_MASTERY_OF_DEATH = 'mastery_of_death'
module.exports.FEATURE_MENTAL_DISCIPLINE = 'mental_discipline'
module.exports.FEATURE_METAL_MAGIC = 'metal_magic'
module.exports.FEATURE_METAMAGIC = 'metamagic'
module.exports.FEATURE_MIGHTY_DEED_2019_09_18 = 'mighty_deed_2019_09_18'
module.exports.FEATURE_MIGHTY_SUMMONER = 'mighty_summoner'
module.exports.FEATURE_MIGHTY_SWARM = 'mighty_swarm'
module.exports.FEATURE_MINDLESS_RAGE = 'mindless_rage'
module.exports.FEATURE_MINOR_ALCHEMY = 'minor_alchemy'
module.exports.FEATURE_MINOR_CONJURATION = 'minor_conjuration'
module.exports.FEATURE_MISDIRECTION = 'misdirection'
module.exports.FEATURE_MIST_STANCE = 'mist_stance'
module.exports.FEATURE_MISTY_ESCAPE = 'misty_escape'
module.exports.FEATURE_MISTY_WANDERER = 'misty_wanderer'
module.exports.FEATURE_MOMENTARY_STASIS = 'momentary_stasis'
module.exports.FEATURE_MONASTIC_TRADITION = 'monastic_tradition'
module.exports.FEATURE_MONSTER_SLAYER_2016_04_04 = 'monster_slayer_2016_04_04'
module.exports.FEATURE_MONSTER_SLAYER_SPELLS = 'monster_slayer_spells'
module.exports.FEATURE_MORTAL_BULWARK = 'mortal_bulwark'
module.exports.FEATURE_MORTAL_HUSK_2020_08_05 = 'mortal_husk_2020_08_05'
module.exports.FEATURE_MOTE_OF_POTENTIAL = 'mote_of_potential'
module.exports.FEATURE_MULTIATTACK = 'multiattack'
module.exports.FEATURE_MYSTIC_ARCANUM = 'mystic_arcanum'
module.exports.FEATURE_NATURAL_EXPLORER = 'natural_explorer'
module.exports.FEATURE_NATURAL_RECOVERY = 'natural_recovery'
module.exports.FEATURE_NATURE_DOMAIN_SPELLS = 'nature_domain_spells'
module.exports.FEATURE_NATURE_S_SANCTUARY = 'nature_s_sanctuary'
module.exports.FEATURE_NATURE_S_VEIL = 'nature_s_veil'
module.exports.FEATURE_NATURE_S_WARD = 'nature_s_ward'
module.exports.FEATURE_NECROMANCY_SAVANT = 'necromancy_savant'
module.exports.FEATURE_OATH_OF_CONQUEST_SPELLS = 'oath_of_conquest_spells'
module.exports.FEATURE_OATH_OF_DEVOTION_SPELLS = 'oath_of_devotion_spells'
module.exports.FEATURE_OATH_OF_GLORY_SPELLS = 'oath_of_glory_spells'
module.exports.FEATURE_OATH_OF_HEROISM_SPELLS = 'oath_of_heroism_spells'
module.exports.FEATURE_OATH_OF_REDEMPTION_SPELLS = 'oath_of_redemption_spells'
module.exports.FEATURE_OATH_OF_THE_ANCIENTS_SPELLS = 'oath_of_the_ancients_spells'
module.exports.FEATURE_OATH_OF_THE_CROWN_SPELLS = 'oath_of_the_crown_spells'
module.exports.FEATURE_OATH_OF_THE_WATCHERS_SPELLS = 'oath_of_the_watchers_spells'
module.exports.FEATURE_OATH_OF_TREACHERY_SPELLS_2016_12_19 = 'oath_of_treachery_spells_2016_12_19'
module.exports.FEATURE_OATH_OF_VENGEANCE_SPELLS = 'oath_of_vengeance_spells'
module.exports.FEATURE_OATH_OF_WATCHERS_SPELLS = 'oath_of_watchers_spells'
module.exports.FEATURE_OATH_SPELLS = 'oath_spells'
module.exports.FEATURE_OATHBREAKER_SPELLS = 'oathbreaker_spells'
module.exports.FEATURE_ONE_WITH_THE_BLADE = 'one_with_the_blade'
module.exports.FEATURE_ONE_WITH_THE_WORD = 'one_with_the_word'
module.exports.FEATURE_OPEN_HAND_TECHNIQUE = 'open_hand_technique'
module.exports.FEATURE_OPPORTUNIST = 'opportunist'
module.exports.FEATURE_ORDER_DOMAIN_SPELLS = 'order_domain_spells'
module.exports.FEATURE_ORDER_S_WRATH = 'order_s_wrath'
module.exports.FEATURE_OTHERWORLDLY_GLAMOUR = 'otherworldly_glamour'
module.exports.FEATURE_OTHERWORLDLY_PATRON = 'otherworldly_patron'
module.exports.FEATURE_OTHERWORLDLY_WINGS = 'otherworldly_wings'
module.exports.FEATURE_OVERCHANNEL = 'overchannel'
module.exports.FEATURE_PACT_BOON = 'pact_boon'
module.exports.FEATURE_PACT_MAGIC = 'pact_magic'
module.exports.FEATURE_PACT_OF_THE_BLADE = 'pact_of_the_blade'
module.exports.FEATURE_PACT_OF_THE_CHAIN = 'pact_of_the_chain'
module.exports.FEATURE_PACT_OF_THE_STAR_CHAIN = 'pact_of_the_star_chain'
module.exports.FEATURE_PACT_OF_THE_TALISMAN = 'pact_of_the_talisman_2019_04_11'
module.exports.FEATURE_PACT_OF_THE_TOME = 'pact_of_the_tome'
module.exports.FEATURE_PANACHE = 'panache'
module.exports.FEATURE_PATH_OF_THE_KENSEI = 'path_of_the_kensei'
module.exports.FEATURE_PATH_OF_TRANQUILITY = 'path_of_tranquility'
module.exports.FEATURE_PATHS_OF_THE_DEAD = 'paths_of_the_dead'
module.exports.FEATURE_PEACE_DOMAIN_SPELLS = 'peace_domain_spells'
module.exports.FEATURE_PEERLESS_SKILL = 'peerless_skill'
module.exports.FEATURE_PERFECT_SELF = 'perfect_self'
module.exports.FEATURE_PERFECTED_ARMOR = 'perfected_armor'
module.exports.FEATURE_PERFECTED_ARMOR_2020_02_24 = 'perfected_armor_2020_02_24'
module.exports.FEATURE_PERFORMANCE_OF_CREATION = 'performance_of_creation'
module.exports.FEATURE_PERSISTENT_RAGE = 'persistent_rage'
module.exports.FEATURE_PHYSICIAN_S_TOUCH = 'physician_s_touch'
module.exports.FEATURE_PLANAR_WARRIOR = 'planar_warrior'
module.exports.FEATURE_PORTENT = 'portent'
module.exports.FEATURE_POTENT_CANTRIP = 'potent_cantrip'
module.exports.FEATURE_POTENT_SPELLCASTING_ARCANA_DOMAIN = 'potent_spellcasting_arcana_domain'
module.exports.FEATURE_POTENT_SPELLCASTING_GRAVE_DOMAIN = 'potent_spellcasting_grave_domain'
module.exports.FEATURE_POTENT_SPELLCASTING_KNOWLEDGE_DOMAIN = 'potent_spellcasting_knowledge_domain'
module.exports.FEATURE_POTENT_SPELLCASTING_LIGHT_DOMAIN = 'potent_spellcasting_light_domain'
module.exports.FEATURE_POTENT_SPELLCASTING_PEACE_DOMAIN = 'potent_spellcasting_peace_domain'
module.exports.FEATURE_POWER_ARMOR_2020_02_24 = 'power_armor_2020_02_24'
module.exports.FEATURE_POWER_SURGE = 'power_surge'
module.exports.FEATURE_PREPARING_SPELLS_ARTIFICER = 'preparing_spells_artificer'
module.exports.FEATURE_PREPARING_SPELLS_CLERIC = 'preparing_spells_cleric'
module.exports.FEATURE_PREPARING_SPELLS_DRUID = 'preparing_spells_druid'
module.exports.FEATURE_PREPARING_SPELLS_PALADIN = 'preparing_spells_paladin'
module.exports.FEATURE_PREPARING_SPELLS_WIZARD = 'preparing_spells_wizard'
module.exports.FEATURE_PRIMAL_AWARENESS = 'primal_awareness'
module.exports.FEATURE_PRIMAL_CHAMPION = 'primal_champion'
module.exports.FEATURE_PRIMAL_COMPANION = 'primal_companion'
module.exports.FEATURE_PRIMAL_KNOWLEDGE = 'primal_knowledge'
module.exports.FEATURE_PRIMAL_PATH = 'primal_path'
module.exports.FEATURE_PRIMAL_STRIKE = 'primal_strike'
module.exports.FEATURE_PRIMEVAL_AWARENESS = 'primeval_awareness'
module.exports.FEATURE_PRIMEVAL_GUARDIAN_SPELLS = 'primeval_guardian_spells'
module.exports.FEATURE_PROJECTED_WARD = 'projected_ward'
module.exports.FEATURE_PROTECTION_DOMAIN_SPELLS = 'protection_domain_spells'
module.exports.FEATURE_PROTECTIVE_BOND = 'protective_bond'
module.exports.FEATURE_PROTECTIVE_SPIRIT = 'protective_spirit'
module.exports.FEATURE_PROTECTIVE_WISH_2020_01_14 = 'protective_wish_2020_01_14'
module.exports.FEATURE_PSIONIC_DEVOTION = 'psionic_devotion'
module.exports.FEATURE_PSIONIC_POWER = 'psionic_power'
module.exports.FEATURE_PSIONIC_POWER_ROGUE_SOUL_KNIFE = 'psionic_talent_rogue_soul_knife'
module.exports.FEATURE_PSIONIC_SPELLS_ABERRANT_MIND = 'psionic_spells_aberrant_mind'
module.exports.FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14 = 'psionic_talent_2020_04_14'
module.exports.FEATURE_PSIONIC_VEIL = 'psionic_veil'
module.exports.FEATURE_PSIONIC_VEIL_2020_04_14 = 'psionic_veil_2020_04_14'
module.exports.FEATURE_PSYCHIC_BLADES = 'psychic_blades'
module.exports.FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE = 'psychic_blades_rogue_soul_knife'
module.exports.FEATURE_PSYCHIC_BLADES_ROGUE_SOUL_KNIFE_2020_04_14 = 'psychic_blades_2020_04_14'
module.exports.FEATURE_PURIFYING_LIGHT_2016_11_28 = 'purifying_light_2016_11_28'
module.exports.FEATURE_PURITY_OF_BODY = 'purity_of_body'
module.exports.FEATURE_PURITY_OF_SPIRIT = 'purity_of_spirit'
module.exports.FEATURE_QUANDRIX_SPELLS_2021_06_08 = 'quandrix_spells_2021_06_08'
module.exports.FEATURE_QUEEN_S_RIGHT_HAND_2017_02_13 = 'queen_s_right_hand_2017_02_13'
module.exports.FEATURE_QUICKENED_HEALING = 'quickened_healing'
module.exports.FEATURE_QUIVERING_PALM = 'quivering_palm'
module.exports.FEATURE_RADIANT_SOUL = 'radiant_soul'
module.exports.FEATURE_RADIANT_SUN_BOLT = 'radiant_sun_bolt'
module.exports.FEATURE_RAGE = 'rage'
module.exports.FEATURE_RAGE_BEYOND_DEATH = 'rage_beyond_death'
module.exports.FEATURE_RAGING_STORM = 'raging_storm'
module.exports.FEATURE_RAKISH_AUDACITY = 'rakish_audacity'
module.exports.FEATURE_RALLYING_CRY = 'rallying_cry'
module.exports.FEATURE_RANGER_ARCHETYPE = 'ranger_archetype'
module.exports.FEATURE_RANGER_S_COMPANION = 'ranger_s_companion'
module.exports.FEATURE_RAPID_STRIKE = 'rapid_strike'
module.exports.FEATURE_RAPID_STRIKE_2016_12_05 = 'rapid_strike_2016_12_05'
module.exports.FEATURE_RAVEN_S_SHIELD_2017_02_13 = 'raven_s_shield_2017_02_13'
module.exports.FEATURE_REAPER = 'reaper'
module.exports.FEATURE_RECKLESS_ABANDON = 'reckless_abandon'
module.exports.FEATURE_RECKLESS_ATTACK = 'reckless_attack'
module.exports.FEATURE_RECLAIM_POTENTIAL = 'reclaim_potential'
module.exports.FEATURE_RELENTLESS = 'relentless'
module.exports.FEATURE_RELENTLESS_2016_04_04 = 'relentless_2016_04_04'
module.exports.FEATURE_RELENTLESS_AVENGER = 'relentless_avenger'
module.exports.FEATURE_RELENTLESS_NAMING_2019_10_03 = 'relentless_naming_2019_10_03'
module.exports.FEATURE_RELENTLESS_RAGE = 'relentless_rage'
module.exports.FEATURE_RELIABLE_TALENT = 'reliable_talent'
module.exports.FEATURE_REMARKABLE_ATHLETE = 'remarkable_athlete'
module.exports.FEATURE_REND_MIND = 'rend_mind'
module.exports.FEATURE_REND_MIND_2020_04_14 = 'rend_mind_2020_04_14'
module.exports.FEATURE_RESONANT_UTTERANCE_2019_10_03 = 'resonant_utterance_2019_10_03'
module.exports.FEATURE_RESTORATIVE_REAGENTS = 'restorative_reagents'
module.exports.FEATURE_RETALIATION = 'retaliation'
module.exports.FEATURE_REVIVED_NATURE_2019_10_17 = 'revived_nature_2019_10_17'
module.exports.FEATURE_RIDE_THE_WIND = 'ride_the_wind'
module.exports.FEATURE_RITUAL_CASTING_ARTIFICER = 'ritual_casting_artificer'
module.exports.FEATURE_RITUAL_CASTING_BARD = 'ritual_casting_bard'
module.exports.FEATURE_RITUAL_CASTING_CLERIC = 'ritual_casting_cleric'
module.exports.FEATURE_RITUAL_CASTING_DRUID = 'ritual_casting_druid'
module.exports.FEATURE_RITUAL_CASTING_WIZARD = 'ritual_casting_wizard'
module.exports.FEATURE_RIVER_OF_HUNGRY_FLAME = 'river_of_hungry_flame'
module.exports.FEATURE_ROGUISH_ARCHETYPE = 'roguish_archetype'
module.exports.FEATURE_ROYAL_ENVOY = 'royal_envoy'
module.exports.FEATURE_RUNE_CARVER = 'rune_carver'
module.exports.FEATURE_RUNIC_JUGGERNAUT = 'runic_juggernaut'
module.exports.FEATURE_RUNIC_SHIELD = 'runic_shield'
module.exports.FEATURE_RUSH_OF_THE_GALE_SPIRITS = 'rush_of_the_gale_spirits'
module.exports.FEATURE_SACRED_OATH = 'sacred_oath'
module.exports.FEATURE_SAINT_OF_FORGE_AND_FIRE = 'saint_of_forge_and_fire'
module.exports.FEATURE_SANCTUARY_VESSEL = 'sanctuary_vessel'
module.exports.FEATURE_SANCTUARY_VESSEL_2020_05_12 = 'sanctuary_vessel_2020_05_12'
module.exports.FEATURE_SCION_OF_THE_DEEP_2019_05_09 = 'scion_of_the_deep_2019_05_09'
module.exports.FEATURE_SCORNFUL_REBUKE = 'scornful_rebuke'
module.exports.FEATURE_SCULPT_SPELLS = 'sculpt_spells'
module.exports.FEATURE_SEARING_ARC_STRIKE = 'searing_arc_strike'
module.exports.FEATURE_SEARING_SUNBURST = 'searing_sunburst'
module.exports.FEATURE_SEARING_VENGEANCE = 'searing_vengeance'
module.exports.FEATURE_SECOND_STORY_WORK = 'second_story_work'
module.exports.FEATURE_SECOND_WIND = 'second_wind'
module.exports.FEATURE_SEEKER_EXPANDED_SPELLS = 'seeker_expanded_spells'
module.exports.FEATURE_SENTINEL_AT_DEATH_S_DOOR = 'sentinel_at_death_s_door'
module.exports.FEATURE_SENTINEL_RAVEN_2017_02_13 = 'sentinel_raven_2017_02_13'
module.exports.FEATURE_SHADOW_ARTS = 'shadow_arts'
module.exports.FEATURE_SHADOW_LORE = 'shadow_lore'
module.exports.FEATURE_SHADOW_MARTYR = 'shadow_martyr'
module.exports.FEATURE_SHADOW_STEP = 'shadow_step'
module.exports.FEATURE_SHADOW_WALK = 'shadow_walk'
module.exports.FEATURE_SHADOWY_DODGE = 'shadowy_dodge'
module.exports.FEATURE_SHAPECHANGER = 'shapechanger'
module.exports.FEATURE_SHARE_SPELLS = 'share_spells'
module.exports.FEATURE_SHARPEN_THE_BLADE = 'sharpen_the_blade'
module.exports.FEATURE_SHIELDING_AURORA = 'shielding_aurora'
module.exports.FEATURE_SHIELDING_STORM = 'shielding_storm'
module.exports.FEATURE_SIGNATURE_SPELLS = 'signature_spells'
module.exports.FEATURE_SILVER_TONGUE = 'silver_tongue'
module.exports.FEATURE_SKIRMISHER = 'skirmisher'
module.exports.FEATURE_SLAYER_S_COUNTER = 'slayer_s_counter'
module.exports.FEATURE_SLAYER_S_PREY = 'slayer_s_prey'
module.exports.FEATURE_SLIPPERY_MIND = 'slippery_mind'
module.exports.FEATURE_SLOW_FALL = 'slow_fall'
module.exports.FEATURE_SNAP_SHOT_2016_12_05 = 'snap_shot_2016_12_05'
module.exports.FEATURE_SNEAK_ATTACK = 'sneak_attack'
module.exports.FEATURE_SONG_OF_DEFENSE = 'song_of_defense'
module.exports.FEATURE_SONG_OF_REST = 'song_of_rest'
module.exports.FEATURE_SONG_OF_VICTORY = 'song_of_victory'
module.exports.FEATURE_SOOTHING_WORDS_2019_09_18 = 'soothing_words'
module.exports.FEATURE_SORCEROUS_ORIGIN = 'sorcerous_origin'
module.exports.FEATURE_SORCEROUS_RESTORATION = 'sorcerous_restoration'
module.exports.FEATURE_SORCEROUS_VERSATILITY = 'sorcerous_versatility'
module.exports.FEATURE_SORCERY_POINTS = 'sorcery_points'
module.exports.FEATURE_SOUL_BLADES = 'soul_blades'
module.exports.FEATURE_SOUL_BLADES_2020_04_14 = 'soul_blades_2020_04_14'
module.exports.FEATURE_SOUL_OF_ARTIFICE = 'soul_of_artifice'
module.exports.FEATURE_SOUL_OF_DECEIT = 'soul_of_deceit'
module.exports.FEATURE_SOUL_OF_THE_FORGE = 'soul_of_the_forge'
module.exports.FEATURE_SOUL_OF_THE_RAVEN_2017_02_13 = 'soul_of_the_raven_2017_02_13'
module.exports.FEATURE_SOUL_OF_VENGEANCE = 'soul_of_vengeance'
module.exports.FEATURE_SPECTRAL_DEFENSE = 'spectral_defense'
module.exports.FEATURE_SPEECH_BEYOND_THE_GRAVE = 'speech_beyond_the_grave'
module.exports.FEATURE_SPEECH_OF_THE_WOODS = 'speech_of_the_woods'
module.exports.FEATURE_SPELL_BOMBARDMENT = 'spell_bombardment'
module.exports.FEATURE_SPELL_BREAKER = 'spell_breaker'
module.exports.FEATURE_SPELL_KNOWN_ARCANE_TRICKSTER = 'spells_known_arcane_trickster'
module.exports.FEATURE_SPELL_KNOWN_BARD = 'spells_known_bard'
module.exports.FEATURE_SPELL_KNOWN_ELDRITCH_KNIGHT = 'spells_known_eldritch_knight'
module.exports.FEATURE_SPELL_KNOWN_RANGER = 'spells_known_ranger'
module.exports.FEATURE_SPELL_KNOWN_SORCERER = 'spells_known_sorcerer'
module.exports.FEATURE_SPELL_KNOWN_WARLOCK = 'spells_known_warlock'
module.exports.FEATURE_SPELL_KNOWN_WIZARD = 'spells_known_wizard'
module.exports.FEATURE_SPELL_MASTERY = 'spell_mastery'
module.exports.FEATURE_SPELL_RESISTANCE = 'spell_resistance'
module.exports.FEATURE_SPELL_SLOTS_ARCANE_TRICKSTER = 'spell_slots_arcane_trickster'
module.exports.FEATURE_SPELL_SLOTS_BARD = 'spell_slots_bard'
module.exports.FEATURE_SPELL_SLOTS_ELDRITCH_KNIGHT = 'spell_slots_eldritch_knight'
module.exports.FEATURE_SPELL_SLOTS_RANGER = 'spell_slots_ranger'
module.exports.FEATURE_SPELL_SLOTS_SORCERER = 'spell_slots_sorcerer'
module.exports.FEATURE_SPELL_SLOTS_WARLOCK = 'spell_slots_warlock'
module.exports.FEATURE_SPELL_STORING_ITEM = 'spell_storing_item'
module.exports.FEATURE_SPELL_THIEF = 'spell_thief'
module.exports.FEATURE_SPELLBOOK = 'spellbook'
module.exports.FEATURE_SPELLCASTING_ABILITY_ARCANE_TRICKSTER = 'spellcasting_ability_arcane_trickster'
module.exports.FEATURE_SPELLCASTING_ABILITY_ARTIFICER = 'spellcasting_ability_artificer'
module.exports.FEATURE_SPELLCASTING_ABILITY_BARD = 'spellcasting_ability_bard'
module.exports.FEATURE_SPELLCASTING_ABILITY_CLERIC = 'spellcasting_ability_cleric'
module.exports.FEATURE_SPELLCASTING_ABILITY_DRUID = 'spellcasting_ability_druid'
module.exports.FEATURE_SPELLCASTING_ABILITY_ELDRITCH_KNIGHT = 'spellcasting_ability_eldritch_knight'
module.exports.FEATURE_SPELLCASTING_ABILITY_PALADIN = 'spellcasting_ability_paladin'
module.exports.FEATURE_SPELLCASTING_ABILITY_RANGER = 'spellcasting_ability_ranger'
module.exports.FEATURE_SPELLCASTING_ABILITY_SORCERER = 'spellcasting_ability_sorcerer'
module.exports.FEATURE_SPELLCASTING_ABILITY_WARLOCK = 'spellcasting_ability_warlock'
module.exports.FEATURE_SPELLCASTING_ABILITY_WIZARD = 'spellcasting_ability_wizard'
module.exports.FEATURE_SPELLCASTING_ARCANE_TRICKSTER = 'spellcasting_arcane_trickster'
module.exports.FEATURE_SPELLCASTING_ARTIFICER = 'spellcasting_artificer'
module.exports.FEATURE_SPELLCASTING_BARD = 'spellcasting_bard'
module.exports.FEATURE_SPELLCASTING_CLERIC = 'spellcasting_cleric'
module.exports.FEATURE_SPELLCASTING_DRUID = 'spellcasting_druid'
module.exports.FEATURE_SPELLCASTING_ELDRITCH_KNIGHT = 'spellcasting_eldritch_knight'
module.exports.FEATURE_SPELLCASTING_FOCUS = 'spellcasting_focus'
module.exports.FEATURE_SPELLCASTING_FOCUS_BARD = 'spellcasting_focus_bard'
module.exports.FEATURE_SPELLCASTING_FOCUS_CLERIC = 'spellcasting_focus_cleric'
module.exports.FEATURE_SPELLCASTING_FOCUS_DRUID = 'spellcasting_focus_druid'
module.exports.FEATURE_SPELLCASTING_FOCUS_PALADIN = 'spellcasting_focus_paladin'
module.exports.FEATURE_SPELLCASTING_FOCUS_SORCERER = 'spellcasting_focus_sorcerer'
module.exports.FEATURE_SPELLCASTING_FOCUS_WARLOCK = 'spellcasting_focus_warlock'
module.exports.FEATURE_SPELLCASTING_FOCUS_WIZARD = 'spellcasting_focus_wizard'
module.exports.FEATURE_SPELLCASTING_PALADIN = 'spellcasting_paladin'
module.exports.FEATURE_SPELLCASTING_RANGER = 'spellcasting_ranger'
module.exports.FEATURE_SPELLCASTING_SORCERER = 'spellcasting_sorcerer'
module.exports.FEATURE_SPELLCASTING_WIZARD = 'spellcasting_wizard'
module.exports.FEATURE_SPIKED_RETRIBUTION = 'spiked_retribution'
module.exports.FEATURE_SPIRIT_PROJECTION_2020_08_05 = 'spirit_projection_2020_08_05'
module.exports.FEATURE_SPIRIT_SEEKER = 'spirit_seeker'
module.exports.FEATURE_SPIRIT_SHIELD = 'spirit_shield'
module.exports.FEATURE_SPIRIT_TOTEM = 'spirit_totem'
module.exports.FEATURE_SPIRIT_WALKER = 'spirit_walker'
module.exports.FEATURE_SPLIT_ENCHANTMENT = 'split_enchantment'
module.exports.FEATURE_SPREADING_SPORES = 'spreading_spores'
module.exports.FEATURE_STALKER_S_FLURRY = 'stalker_s_flurry'
module.exports.FEATURE_STAR_MAP = 'star_map'
module.exports.FEATURE_STAR_MAP_2020_02_24 = 'star_map_2020_02_24'
module.exports.FEATURE_STARRY_FORM = 'starry_form'
module.exports.FEATURE_STEADY_AIM = 'steady_aim'
module.exports.FEATURE_STEADY_AIM_2016_12_05 = 'steady_aim_2016_12_05'
module.exports.FEATURE_STEADY_EYE = 'steady_eye'
module.exports.FEATURE_STEEL_DEFENDER = 'steel_defender'
module.exports.FEATURE_STEPS_OF_NIGHT = 'steps_of_night'
module.exports.FEATURE_STILLNESS_OF_MIND = 'stillness_of_mind'
module.exports.FEATURE_STORM_AURA = 'storm_aura'
module.exports.FEATURE_STORM_GUIDE = 'storm_guide'
module.exports.FEATURE_STORM_S_FURY = 'storm_s_fury'
module.exports.FEATURE_STORM_SOUL = 'storm_soul'
module.exports.FEATURE_STORMBORN = 'stormborn'
module.exports.FEATURE_STRENGTH_BEFORE_DEATH = 'strength_before_death'
module.exports.FEATURE_STRENGTH_OF_THE_GRAVE = 'strength_of_the_grave'
module.exports.FEATURE_STROKE_OF_LUCK = 'stroke_of_luck'
module.exports.FEATURE_STUDENT_OF_WAR = 'student_of_war'
module.exports.FEATURE_STUNNING_STRIKE = 'stunning_strike'
module.exports.FEATURE_SUDDEN_STRIKE = 'sudden_strike'
module.exports.FEATURE_SUMMON_WILDFIRE_SPIRIT = 'summon_wildfire_spirit'
module.exports.FEATURE_SUN_SHIELD = 'sun_shield'
module.exports.FEATURE_SUPERIOR_CRITICAL = 'superior_critical'
module.exports.FEATURE_SUPERIOR_HUNTER_S_DEFENSE = 'superior_hunter_s_defense'
module.exports.FEATURE_SUPERIOR_INSPIRATION = 'superior_inspiration'
module.exports.FEATURE_SUPERIOR_MOBILITY = 'superior_mobility'
module.exports.FEATURE_SUPERNATURAL_DEFENSE = 'supernatural_defense'
module.exports.FEATURE_SUPERNATURAL_RESISTANCE = 'supernatural_resistance'
module.exports.FEATURE_SUPREME_HEALING = 'supreme_healing'
module.exports.FEATURE_SUPREME_SNEAK = 'supreme_sneak'
module.exports.FEATURE_SURVIVALIST = 'survivalist'
module.exports.FEATURE_SURVIVOR = 'survivor'
module.exports.FEATURE_SURVIVOR_2018_01_08 = 'survivor_2018_01_08'
module.exports.FEATURE_SWARMING_DISPERSAL = 'swarming_dispersal'
module.exports.FEATURE_SWARMKEEPER_MAGIC = 'swarmkeeper_magic'
module.exports.FEATURE_SWARMKEEPER_SPELLS_2019_10_17 = 'swarmkeeper_spells_2019_10_17'
module.exports.FEATURE_SWEEPING_CINDER_STRIKE = 'sweeping_cinder_strike'
module.exports.FEATURE_SYMBIOTIC_ENTITY = 'symbiotic_entity'
module.exports.FEATURE_TACTICAL_WIT = 'tactical_wit'
module.exports.FEATURE_TELEKINETIC_ADEPT = 'telekinetic_adept'
module.exports.FEATURE_TELEKINETIC_HAND = 'telekinetic_hand'
module.exports.FEATURE_TELEKINETIC_MASTER = 'telekinetic_master'
module.exports.FEATURE_TEMPEST_DOMAIN_SPELLS = 'tempest_domain_spells'
module.exports.FEATURE_TEMPESTUOUS_MAGIC = 'tempestuous_magic'
module.exports.FEATURE_TEMPORAL_AWARENESS = 'temporal_awareness'
module.exports.FEATURE_THE_RIGHT_TOOL_FOR_THE_JOB = 'the_right_tool_for_the_job'
module.exports.FEATURE_THE_THIRD_EYE = 'the_third_eye'
module.exports.FEATURE_THIEF_S_REFLEXES = 'thief_s_reflexes'
module.exports.FEATURE_THIEVES_CANT = 'thieves_cant'
module.exports.FEATURE_THOUGHT_SHIELD = 'thought_shield'
module.exports.FEATURE_THOUSAND_FORMS = 'thousand_forms'
module.exports.FEATURE_THUNDERBOLT_STRIKE = 'thunderbolt_strike'
module.exports.FEATURE_TIDES_OF_CHAOS = 'tides_of_chaos'
module.exports.FEATURE_TIMELESS_BODY_DRUID = 'timeless_body_druid'
module.exports.FEATURE_TIMELESS_BODY_MONK = 'timeless_body_monk'
module.exports.FEATURE_TIPSY_SWAY = 'tipsy_sway'
module.exports.FEATURE_TIRELESS_SPIRIT = 'tireless_spirit'
module.exports.FEATURE_TOKENS_OF_PAST_LIVES_2019_10_17 = 'tokens_of_past_lives_2019_10_17'
module.exports.FEATURE_TOKENS_OF_THE_DEPARTED = 'tokens_of_the_departed'
module.exports.FEATURE_TOKENS_OF_THE_DEPARTED_2020_05_12 = 'tokens_of_the_departed_2020_05_12'
module.exports.FEATURE_TONGUE_OF_THE_SUN_AND_MOON = 'tongue_of_the_sun_and_moon'
module.exports.FEATURE_TOOL_EXPERTISE = 'tool_expertise'
module.exports.FEATURE_TOOL_PROFICIENCY_ALCHEMIST = 'tool_proficiency_alchemist'
module.exports.FEATURE_TOOL_PROFICIENCY_ARTILLERIST = 'tool_proficiency_artillerist'
module.exports.FEATURE_TOOL_PROFICIENCY_BATTLE_SMITH = 'tool_proficiency_battle_smith'
module.exports.FEATURE_TOOLS_OF_THE_TRADE = 'tools_of_the_trade'
module.exports.FEATURE_TOOLS_OF_THE_TRADE_2020_02_24 = 'tools_of_the_trade_2020_02_24'
module.exports.FEATURE_TOOLS_REQUIRED = 'tools_required'
module.exports.FEATURE_TOTEM_SPIRIT = 'totem_spirit'
module.exports.FEATURE_TOTEMIC_ATTUNEMENT = 'totemic_attunement'
module.exports.FEATURE_TOUCH_OF_DEATH = 'touch_of_death'
module.exports.FEATURE_TOUCH_OF_THE_LONG_DEATH = 'touch_of_the_long_death'
module.exports.FEATURE_TRAINING_IN_WAR_AND_SONG = 'training_in_war_and_song'
module.exports.FEATURE_TRANQUILITY = 'tranquility'
module.exports.FEATURE_TRANSMUTATION_SAVANT = 'transmutation_savant'
module.exports.FEATURE_TRANSMUTER_S_STONE = 'transmuter_s_stone'
module.exports.FEATURE_TRICKERY_DOMAIN_SPELLS = 'trickery_domain_spells'
module.exports.FEATURE_TRICKS_UP_THE_SLEEVE = 'tricks_up_the_sleeve'
module.exports.FEATURE_TUMBLING_FOOL_2016_01_04 = 'tumbling_fool_2016_01_04'
module.exports.FEATURE_TWILIGHT_DOMAIN_SPELLS = 'twilight_domain_spells'
module.exports.FEATURE_TWILIGHT_DOMAIN_SPELLS_2019_10_03 = 'twilight_domain_spells_2019_10_03'
module.exports.FEATURE_TWILIGHT_SHROUD = 'twilight_shroud'
module.exports.FEATURE_TWINKLING_CONSTELLATIONS = 'twinkling_constellations'
module.exports.FEATURE_UMBRAL_FORM = 'umbral_form'
module.exports.FEATURE_UMBRAL_SIGHT = 'umbral_sight'
module.exports.FEATURE_UNARMORED_DEFENSE_BARBARIAN = 'unarmored_defense_barbarian'
module.exports.FEATURE_UNARMORED_DEFENSE_MONK = 'unarmored_defense_monk'
module.exports.FEATURE_UNARMORED_MOVEMENT = 'unarmored_movement'
module.exports.FEATURE_UNBREAKABLE_MAJESTY = 'unbreakable_majesty'
module.exports.FEATURE_UNBREAKABLE_MAJESTY_2016_11_14 = 'unbreakable_majesty_2016_11_14'
module.exports.FEATURE_UNCANNY_DODGE = 'uncanny_dodge'
module.exports.FEATURE_UNDEAD_THRALLS = 'undead_thralls'
module.exports.FEATURE_UNDYING_LIGHT_EXPANDED_SPELLS = 'undying_light_expanded_spells'
module.exports.FEATURE_UNDYING_NATURE = 'undying_nature'
module.exports.FEATURE_UNDYING_SENTINEL = 'undying_sentinel'
module.exports.FEATURE_UNEARTHLY_RECOVERY = 'unearthly_recovery'
module.exports.FEATURE_UNERRING_ACCURACY = 'unerring_accuracy'
module.exports.FEATURE_UNERRING_EYE = 'unerring_eye'
module.exports.FEATURE_UNFAILING_INSPIRATION = 'unfailing_inspiration'
module.exports.FEATURE_UNITY_DOMAIN_SPELLS_2020_02_06 = 'unity_domain_spells_2020_02_06'
module.exports.FEATURE_UNIVERSAL_SPEECH = 'universal_speech'
module.exports.FEATURE_UNLEASH_INCARNATION = 'unleash_incarnation'
module.exports.FEATURE_UNLEASH_THE_DEPTHS_2019_05_09 = 'unleash_the_depths_2019_05_09'
module.exports.FEATURE_UNSETTLING_WORDS = 'unsettling_words'
module.exports.FEATURE_UNSTABLE_BACKLASH = 'unstable_backlash'
module.exports.FEATURE_UNSTABLE_BACKLASH_TCoE_PC_BPoWM = 'unstable_backlash_tcoe_pc_bpowm'
module.exports.FEATURE_UNWAVERING_MARK = 'unwavering_mark'
module.exports.FEATURE_UNYIELDING_SPIRIT = 'unyielding_spirit'
module.exports.FEATURE_USE_MAGIC_DEVICE = 'use_magic_device'
module.exports.FEATURE_VANISH = 'vanish'
module.exports.FEATURE_VENGEFUL_ANCESTORS = 'vengeful_ancestors'
module.exports.FEATURE_VERSATILE_TRICKSTER = 'versatile_trickster'
module.exports.FEATURE_VIGILANT_BLESSING = 'vigilant_blessing'
module.exports.FEATURE_VIGILANT_DEFENDER = 'vigilant_defender'
module.exports.FEATURE_VIGILANT_REBUKE = 'vigilant_rebuke'
module.exports.FEATURE_VIOLENT_ATTRACTION = 'violent_attraction'
module.exports.FEATURE_VISAGE_OF_THE_ASTRAL_SELF = 'visage_of_the_astral_self'
module.exports.FEATURE_VISAGE_OF_THE_ASTRAL_SELF_2019_08_15 = 'visage_of_the_astral_self_2019_08_15'
module.exports.FEATURE_VISIONS_OF_THE_PAST = 'visions_of_the_past'
module.exports.FEATURE_VOICE_OF_AUTHORITY = 'voice_of_authority'
module.exports.FEATURE_WAILS_FROM_THE_GRAVE = 'wails_from_the_grave'
module.exports.FEATURE_WAILS_FROM_THE_GRAVE_2020_05_12 = 'wails_from_the_grave_2020_05_12'
module.exports.FEATURE_WALKER_IN_DREAMS = 'walker_in_dreams'
module.exports.FEATURE_WAR_DOMAIN_SPELLS = 'war_domain_spells'
module.exports.FEATURE_WAR_MAGIC = 'war_magic'
module.exports.FEATURE_WAR_PRIEST = 'war_priest'
module.exports.FEATURE_WARDING_FLARE = 'warding_flare'
module.exports.FEATURE_WARDING_MANEUVER = 'warding_maneuver'
module.exports.FEATURE_WARRIOR_OF_THE_GODS = 'warrior_of_the_gods'
module.exports.FEATURE_WAVE_OF_ROLLING_EARTH = 'wave_of_rolling_earth'
module.exports.FEATURE_WEAPON_BOND = 'weapon_bond'
module.exports.FEATURE_WHISPERS_OF_THE_DEAD = 'whispers_of_the_dead'
module.exports.FEATURE_WHISPERS_OF_THE_DEAD_2020_05_12 = 'whispers_of_the_dead_2020_05_12'
module.exports.FEATURE_WHOLENESS_OF_BODY = 'wholeness_of_body'
module.exports.FEATURE_WILD_COMPANION = 'wild_companion'
module.exports.FEATURE_WILD_MAGIC_SURGE = 'wild_magic_surge'
module.exports.FEATURE_WILD_SHAPE = 'wild_shape'
module.exports.FEATURE_WILD_SURGE = 'wild_surge'
module.exports.FEATURE_WILD_SURGE_TCoE_PC_BPoWM = 'wild_surge_tcoe_pc_bpowm'
module.exports.FEATURE_WIND_SOUL = 'wind_soul'
module.exports.FEATURE_WIND_SPEAKER = 'wind_speaker'
module.exports.FEATURE_WITHERBLOOM_SPELLS_2021_06_08 = 'witherbloom_spells_2021_06_08'
module.exports.FEATURE_WIZARDLY_QUILL = 'wizardly_quill'
module.exports.FEATURE_WORDS_OF_TERROR = 'words_of_terror'
module.exports.FEATURE_WRATH_OF_THE_STORM = 'wrath_of_the_storm'
module.exports.FEATURE_WRITHING_TIDE = 'writhing_tide'
module.exports.FEATURE_ZEALOUS_PRESENCE = 'zealous_presence'
