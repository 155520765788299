const {
  CREATURE_CAMEL,
  CREATURE_ELK,
  CREATURE_MASTIFF,
  CREATURE_PONY,
  CREATURE_WARHORSE,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_FIND_STEED,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FIND_STEED,
  name: 'Поиск скакуна',
  nameEn: `Find Steed`,
  description: `Вы вызываете дух, принимающий облик невероятно умного, сильного и верного скакуна, создавая долгосрочную связь с ним.

Появляясь в свободном пространстве в пределах дистанции, скакун принимает выбранный Вами облик из списка: [боевой конь](CREATURE:${CREATURE_WARHORSE}), [пони](CREATURE:${CREATURE_PONY}), [верблюд](CREATURE:${CREATURE_CAMEL}), [лось](CREATURE:${CREATURE_ELK}) или [мастиф](CREATURE:${CREATURE_MASTIFF}) (Мастер может позволить призывать в качестве скакунов других животных). Скакун приобретает параметры выбранной формы, хотя на самом деле он является небожителем, феей или исчадием (на Ваш выбор). Кроме того, если у Вашего скакуна Интеллект 5 или меньше, его Интеллект становится равен 6, и он получает способность понимать один язык на Ваш выбор, на котором Вы разговариваете.

Ваш скакун служит Вам как в бою, так и вне его, и у Вас с ним есть инстинктивная связь, позволяющая сражаться как единое целое. Находясь верхом на скакуне, Вы можете делать так, что наложенные Вами заклинания, нацеливающиеся только на Вас, будут нацелены ещё и на скакуна.

Когда хиты скакуна опускаются до 0, он исчезает, не оставляя физического тела. Вы можете также в любое время отпустить скакуна действием, заставляя его исчезнуть. В любом случае, повторное использование этого заклинания призывает этого же скакуна, восстанавливая хиты до максимума.

Пока скакун находится в пределах 1 мили от Вас, Вы можете общаться друг с другом телепатически.

У Вас не может быть больше одного скакуна, призванного этим заклинанием, одновременно. Вы можете действием навсегда отпустить скакуна, освободив его от уз и заставляя исчезнуть.`,
  lvl: 2,
  magicSchoolId: MAGIC_CONJURATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  castTime: { timeId: TIME_MINUTE, count: 10 },
  source: [
    {
      id: SOURCE_PHB,
      page: 261,
    },
    {
      id: SOURCE_SRD,
      page: 143,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Теперь со скакуном можно общаться телепатически',
      page: 3,
    },
  ],
}
