const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_DEATHLOCK_WIGHT,
  CREATURE_NECROMANCER,
  CREATURE_WIGHT,
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_SPELL,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  SKILL_ARCANA,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {ABILITY_SUNLIGHT_SENSITIVITY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {deathlockDescription} = require('./../../../../textCommonParts')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_MAGE_ARMOR} = require('./../../../../spellIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Дэтлок умертвие',
  nameAlt: 'Колдовское умертвие',
  nameEn: 'Deathlock Wight',
  id: CREATURE_DEATHLOCK_WIGHT,
  description: [
    `Лишённый большей части своей магии дэтлок умертвие находится в состоянии между колдуном, которым он был, и [умертвием](CREATURE:${CREATURE_WIGHT}), — это особое наказание от некоторых покровителей и [некромантов](CREATURE:${CREATURE_NECROMANCER}).`,
    deathlockDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 198,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Умертвие совершает две атаки _Гиблыми снарядами_.`,
    },
    {
      name: 'Гиблые снаряды',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 5,
        range: 120,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Вытягивание жизни',
      description: `Цель должна пройти испытание Телосложения СЛ 13, иначе её максимальное значение хитов уменьшится на количество, равное полученному урону. Это уменьшение длится до тех пор, пока цель не закончит длинный отдых. Цель умирает, если этот эффект уменьшает её максимальное значение хитов опустится до 0.

Гуманоид, убитый этой атакой, спустя 24 часа восстаёт из мёртвых как [зомби](CREATURE:${CREATURE_ZOMBIE}) и находится под контролем умертвия, если только гуманоида не вернут к жизни или его тело не уничтожат. Умертвие не может одновременно держать под контролем не больше 12 [зомби](CREATURE:${CREATURE_ZOMBIE}).`,
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
