const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  SPELL_DISPEL_MAGIC,
  SPELL_FORCECAGE,
} = require('./../../../../../spellIdList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CHA} = require('./../../../../../paramList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_FORCECAGE,
  name: 'Узилище',
  nameEn: `Forcecage`,
  description: `Вокруг области, выбранной Вами в пределах дистанции, возникает неподвижная, невидимая тюрьма в форме куба, созданная из магической силы. Тюрьма может быть клеткой или сплошной коробкой, на Ваш выбор.

* Тюрьма в форме клетки может быть с длиной ребра до 20 футов, и состоит из сантиметровых прутьев (диаметр ½ дюйма), отстоящих друг от друга на 1 сантиметр (½ дюйма).
* Тюрьма в форме коробки может быть с длиной ребра до 10 футов, и она является сплошным барьером, останавливающим любую материю, и блокирующую заклинания, накладываемые как внутрь коробки, так и направленные из неё наружу.

Когда Вы накладываете это заклинание, под его действие попадают все существа, находящиеся целиком внутри области. Существа, находящиеся в области лишь частично, или слишком большие для заточения внутри, отталкиваются от центра области, пока не окажутся снаружи.

Существо, находящееся в узилище, не может покинуть его без помощи магии. Если оно пытается использовать телепортацию или перемещение между планами, оно должно вначале пройти испытание Харизмы. При успехе существо может использовать эту магию для выхода из узилища. При провале существо не может покинуть узилище, и тратит впустую использование заклинания или эффект. Узилище простирается и на Эфирный План, запрещая перемещение через него.

Это заклинание нельзя рассеять [рассеиванием магии](SPELL:${SPELL_DISPEL_MAGIC}).`,
  lvl: 7,
  magicSchoolId: MAGIC_EVOCATION,
  range: 100,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `порошок рубина, стоящий 1 500 зм`,
  duration: { timeId: TIME_HOUR, count: 1 },
  effect: {
    savethrowParam: PARAM_CHA,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 286,
    },
    {
      id: SOURCE_SRD,
      page: 147,
    },
  ],
}
