const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_PARRY,
} = require('./../../../../creatureAbilityList')
const {
  DAMAGE_NONMAGIC_WEAPON,
} = require('./../../../../damageTypeList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  SKILL_ACROBATICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  GEAR_DAGGER,
  GEAR_RAPIER,
} = require('./../../../../gearIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_NIMBLEWRIGHT} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {LANG_MAIN_LANGUAGE_OF_ITS_MASTER} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_WDH} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_MENDING} = require('./../../../../spellIdList')

module.exports = {
  name: 'Проворник',
  nameEn: 'Nimblewright',
  id: CREATURE_NIMBLEWRIGHT,
  description: [
    {
      header: 'Проворники',
      text: `Этих магических конструктов создают как стражей и убийц. Обычно их делают из лёгкого дерева и оживляют магией. Одетого проворника нетрудно принять за человека. Некоторые проворники одеваются просто, другие — вычурно. Проворники лишены эмоций, их лица навечно застыли с тем выражением, которое им задал их создатель.`,
      source: {
        id: SOURCE_WDH,
        page: 210,
      },
    },
    {
      header: 'Дуэлянты',
      text: `Проворники двигаются как танцоры и дерутся как сорвиголовы, уклоняясь и парируя удары и ловко поражая врагов.`,
      source: {
        id: SOURCE_WDH,
        page: 210,
      },
    },
    {
      header: 'Природа конструкта',
      text: `Проворнику не нужны воздух, еда, питьё и сон. Полученный им урон можно исправить заклинанием [Починка](SPELL:${SPELL_MENDING}), но, если хиты проворника снизятся до 0, он уничтожается.`,
      source: {
        id: SOURCE_WDH,
        page: 210,
      },
    },
  ],
  note: {
    text: 'Это, конечно, уникальный штрих, Ян, но ты уверен, что ему действительно нужны эти усы?',
    author: 'старший механик Андар Беч',
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_WDH,
    page: 210,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 60,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 17,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  saveThrowList: [
    PARAM_DEX,
  ],
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_EXHAUSTION,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_MAIN_LANGUAGE_OF_ITS_MASTER,
      doNotSpeak: true,
    },
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Крепконогий',
      description: `★СУЩЕСТВО★ получает преимущество к испытаниям Силы и Ловкостип против опрокидывающих его эффектов.`,
    },
    {
      name: 'Лёгкая починка',
      description: `Пока ★СУЩЕСТВО★ имеет хотя бы 1 хит, ★он★ восстанавливает 1 хит, если на него сотворить заклинание [Починка](SPELL:${SPELL_MENDING}).`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки — две _Рапирой_ и одну — _Кинжалом_.`,
    },
    {
      gearId: GEAR_RAPIER,
    },
    {
      gearId: GEAR_DAGGER,
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 2,
    },
  ],
}
