const {
  CREATURE_DRAGON_TURTLE,
  CREATURE_KRAKEN,
  CREATURE_KRAKEN_PRIEST,
  CREATURE_MARID,
  CREATURE_MERFOLK,
  CREATURE_MORKOTH,
  CREATURE_SEA_HAG,
  CREATURE_SEA_SPAWN,
  CREATURE_STORM_GIANT,
} = require('./../../../../creatureIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_AQUAN,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_TYPE_HUMANOID} = require('./../../../../creatureTypeIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Морское отродье',
  nameEn: 'Sea Spawn',
  id: CREATURE_SEA_SPAWN,
  description: [
    `Многие истории, которые поются морские песни или которые стали сказками в портовых тавернах, рассказывают о людях, потерявшихся в море, но не просто утонувших и пропавших. Этих несчастных забрал океан и они живут как морские отродья, населяющие волны как измученные отражения своих прежних сущностей. Их покрывают кораллы. Ракушки цепляются за их холодную кожу. Лёгкие, что когда-то наполнялись воздухом, теперь так же дышат водой.

Истории рассказывают о множестве причин таких странных превращений. _«Не стоит влюбляться в морскую эльфийку или [мерфолка](CREATURE:${CREATURE_MERFOLK})»_, — говорят некоторые. _«Возвращайся в порт до шторма, и не важно как соблазнителен улов»._ _«Чти морских богов так, как они требуют, но никогда не обещай им своё сердце»._ Такие рассказы о предосторожностях скрывают правду: под волнами рыскают твари, которые стремятся завладеть сердцами и умами обитателей суши.`,
    {
      header: 'Рабы глубин',
      text: `[Кракены](CREATURE:${CREATURE_KRAKEN}), [моркоты](CREATURE:${CREATURE_MORKOTH}), [морские карги](CREATURE:${CREATURE_SEA_HAG}), [мариды](CREATURE:${CREATURE_MARID}), [штормовые великаны](CREATURE:${CREATURE_STORM_GIANT}), [драконьи черепахи](CREATURE:${CREATURE_DRAGON_TURTLE}) — все эти и другие морские существа могут отметить смертных как свою собственность и заставить их быть слугами. Такие люди могут стать обязанными хозяину посредством мрачной сделки, или могут стать проклятыми этими существами. Однажды превращенный в рыбью форму, никто не может покидать море надолго, если не хочет умереть через несколько дней.`,
      source: {
        id: SOURCE_VGTM,
        page: 172,
      },
    },
    {
      header: 'Анатомическое разнообразие',
      text: `Морские отродья бывают многих форм. Индивид может иметь щупальца вместо рук, челюсти акулы, шипы морского ежа, плавник кита, глаза осьминога, волосы из морских водорослей или любую комбинацию таких свойств. Некоторые морские отродья имеют рыбные части тела, которые дают им особые возможности, недоступные обычным гуманоидам.`,
      source: {
        id: SOURCE_VGTM,
        page: 172,
      },
    },
    {
      header: 'Морское отродье с Сиреневых Скал',
      text: `Посетители вереницы островов под названием Сиреневые Скалы (в сеттинге Забытых Королевств) могут заметить один любопытный факт о людях, населяющих эти острова: среди них нет младенцев или стариков.

Все это потому, что дети, рождающиеся у островитян, выбрасываются в море и их получает [кракен](CREATURE:${CREATURE_KRAKEN}) по имени Сларкретел. В результате дети превращаются в фанатиков, посвятивших себя кракену.

Они выходят из моря как люди, но по достижении старого возраста, они превращаются в морских отродий и присоединяются к своему хозяину в глубинах вод.

Некоторые дети возвращаются с частичными трансформациями, что делает их полузверьми до достижения старого возраста и полного превращения. Таких уродов прячут до последнего изменения, чтобы сохранить секрет Сиреневых Скал.

[Жрецы кракена](CREATURE:${CREATURE_KRAKEN_PRIEST}) — это пастыри стада кракена. Большинство жрецов родом с острова, но некоторые из них [мерфолки], [водянники] или морские эльфы, которые живут в водах вокруг Сиреневых Скал.`,
      source: {
        id: SOURCE_VGTM,
        page: 172,
      },
    },
  ],
  note: {
    text: `Они трансформированы морем и порабощены силами глубин. А трансформация их больше чем просто плавники и щупальца; они начинают любить свое рабство. Бедняги`,
    author: `Эльминстер`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 172,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 15,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: [
        LANG_AQUAN,
        LANG_COMMON,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Ограниченная амфибия',
      description: `Морское отродье может дышать и на воздухе и под водой, но должен погружаться в море хотя бы раз в день в течение 1 минуты, чтобы не задохнуться.`,
    },
    {
      name: 'Рыбья анатомия',
      description: `Морское отродье дополнительно обладает одним или более вариантами атак, если имеет соответствующую анатомию.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Морское отродье делает три атаки: два _Безоружных удара_ и одну атаку, используя _Рыбью анатомию_.`,
    },
    {
      name: 'Безоружный удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Рыбья анатомия — Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Рыбья анатомия — Ядовитые иглы',
      description: `Цель должна пройти испытание Телосложения СЛ 12 или стать отравленной на 1 минуту. Цель может повторять испытание в конце каждого своего хода и окончить этот эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_POISON,
          diceCount: 1,
          diceType: 6,
        },
      },
    },
    {
      name: 'Рыбья анатомия — Щупальце',
      description: `Цель схвачена (СЛ высвобождения 12) если это существо Среднего размера или меньше. До окончания захвата морское отродье не может использовать это щупальце против другой цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
