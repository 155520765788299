const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_IDRotF,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  CREATURE_DEMOS_MAGEN,
  CREATURE_GALVAN_MAGEN,
  CREATURE_HYPNOS_MAGEN,
} = require('./../../../../../creatureIdList')
const {
  SPELL_CREATE_MAGEN,
  SPELL_WISH,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_CREATE_MAGEN,
  name: 'Создание магена',
  nameEn: 'Create Magen',
  description: `Сотворяя это заклинание, Вы помещаете флакон ртути в грудину полноразмерный куклы человека, набитой пылью или золой. После этого Вы зашиваете куклу и капаете на неё каплей своей крови. Завершая заклинание, Вы касаетесь куклы хрустальным жезлом, превращая её в магена, одетого в то, что носила кукла. Тип магена выбираете Вы во время сотворения заклинания.

Когда маген появляется, Ваш максимум хитов уменьшается на число, равное уровню опасности магена (минимум на 1). Только заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) может отменить это уменьшение максимального числа хитов.

Все созданные Вами этим заклинанием магены беспрекословно подчиняются Вашим командам.`,
  createdCreatureId: [
    CREATURE_DEMOS_MAGEN,
    CREATURE_GALVAN_MAGEN,
    CREATURE_HYPNOS_MAGEN,
  ],
  lvl: 7,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `флакон ртути стоимостью как минимум 500 зм и полноразмерная кукла человека, расходуемые заклинанием; изящный хрустальный жезл, стоимостью как минимум 1 500 зм, не расходуемый заклинанием`,
  castTime: { timeId: TIME_HOUR, count: 1 },
  source: {
    id: SOURCE_IDRotF,
    page: 319,
  },
}
