const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_ARMANITE} = require('./../../../../creatureIdList')
const {demonDescription} = require('./../../../../textCommonParts')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Арманит',
  nameEn: 'Armanite',
  id: CREATURE_ARMANITE,
  description: [
    `Огромные стада арманитов бегут по взрывающимся полям Бездны, обречённые на бойню и смерть, вызванные безудержной жаждой крови. Вне зависимости от того, контролируются ли они более могущественными демонами или рвутся в битву ради процесса, арманиты используют свои когти, копыта и длинные хвосты для раздирания врагов.`,
    {
      header: 'Жизнь ради войны',
      text: `В армиях повелителей демонов, арманиты выполняют роль тяжёлой кавалерии, возглавляя броски и атаки, прорубаясь во фланги противников. Арманиты всё время сражаются, даже друг с другом, если не могут найти других противников. Из них получаются идеальные ударные отряды, до глупости отважные и абсолютно дикие.`,
      source: {
        id: SOURCE_MTOF,
        page: 130,
      },
    },
    {
      header: 'Ходячий арсенал',
      text: `Одна из причин, делающих арманитов настолько бесстрашными, это количество оружия, находящегося в их распоряжении. У них острые копыта, лапы, что заканчиваются крючковатыми когтями и длинные, разделённые хвосты, которые способны сдирать кожу с несчастных. И всё это они используют, чтобы проходить через своих врагов. Против плотного строя они могут призвать свою внутреннюю магию, выпуская молнии и создавая брешь в рядах противника.`,
      source: {
        id: SOURCE_MTOF,
        page: 130,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 130,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 60,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 21,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 13,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_7,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Арманит совершает три атаки: одну _Копытами_, одну _Когтями_ и одну _Хвостом_.`,
    },
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Зазубренный хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Световое копьё',
      restore: 5,
      description: `Арманит высвобождает вспышку молнии прямой линией длиной в 60 футов и шириной 10 футов. Каждое существо, оказавшееся в этой линии, должно пройти испытание Ловкости СЛ 15, получая 27 (6к8) урон электричеством при провале, или половину этого урона при успехе.`,
    },
  ],
}
