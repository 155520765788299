const {PC_SUBCLASS_FIGHTER_PURPLE_DRAGON_KNIGHT} = require('./../../../pcSubClassIdList')
const {SOURCE_SCAG} = require('./../../../sourceList')
const {
  FEATURE_ACTION_SURGE,
  FEATURE_BULWARK,
  FEATURE_INDOMITABLE,
  FEATURE_INSPIRING_SURGE,
  FEATURE_RALLYING_CRY,
  FEATURE_ROYAL_ENVOY,
  FEATURE_SECOND_WIND,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_RALLYING_CRY,
    name: `Сплочающий крик`,
    nameEn: `Rallying Cry`,
    lvl: 3,
    text: `Вы изучаете приёмы, позволяющие вдохновить Ваших союзников на сражение, не смотря на былые ранения.

Когда Вы используете [Второе дыхание](FEATURE:${FEATURE_SECOND_WIND}), то можете выбрать до трёх союзных существ в пределах 60 футов. Каждый из них восстанавливает количество хитов, равное Вашему уровню воина, если может Вас видеть и слышать.`,
  },
  {
    id: FEATURE_ROYAL_ENVOY,
    name: `Посланник короны`,
    nameEn: `Royal Envoy`,
    lvl: 7,
    text: `Рыцари Пурпурного Дракона служат посланниками короны Кормира. Предполагается, что рыцари высокого положения будут вести себя достойно.

Вы получаете владение навыком Убеждения. Если Вы уже владеете этим навыком, выберите владение в одном из следующих навыков:

* Выступление,
* Запугивание,
* Проницательность,
* Уход за животными.

Ваш бонус владения удваивается при любой проверке характеристики, использующей Убеждение. Вы сохраняете выгоду от этой особенности, вне зависимости от навыка, которым Вы овладели, используя эту особенность.`,
  },
  {
    id: FEATURE_INSPIRING_SURGE,
    name: `Вдохновляющий Всплеск`,
    nameEn: `Inspiring Surge`,
    lvl: [10, 17],
    text: `При использовании [Всплеска действий](FEATURE:${FEATURE_ACTION_SURGE}), Вы можете выбрать одно союзное существо в пределах 60 футов. Это существо может реакцией совершить рукопашную или дальнобойную атаку оружием, если оно Вас слышит и видит.

Начиная с 17 уровня, Вы можете выбрать двух союзников в пределах 60 футов, вместо одного.`,
  },
  {
    id: FEATURE_BULWARK,
    name: `Оплот`,
    nameEn: `Bulwark`,
    lvl: 15,
    text: `Вы можете распространить выгоды от Вашего умения [Упорный](FEATURE:${FEATURE_INDOMITABLE}) на союзников.

Когда Вы решите использовать умение [Упорный](FEATURE:${FEATURE_INDOMITABLE}), чтобы перебросить испытание Интеллекта, Мудрости или Харизмы и Вы дееспособны, Вы можете выбрать союзника в пределах 60 футов от Вас, который тоже провалил испытание против этого эффекта. Если существо может видеть или слышать Вас, оно может перебросить испытание и обязано использовать новый результат.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_PURPLE_DRAGON_KNIGHT,
    source: {
      id: SOURCE_SCAG,
      page: 129,
    },
  })
)
