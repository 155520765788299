const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_16,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GEAR_LONGSWORD,
} = require('./../../../../gearIdList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_PARRY,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_MARILITH,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Марилит',
  nameEn: 'Marilith',
  id: CREATURE_MARILITH,
  description: [
    `Марилит выглядит пугающе — шестирукая женщина, нижняя часть тела которой заменена на хвост гигантской змеи.

Используя мечи в каждой из своих шести рук, марилит является опаснейшим врагом, с которым немногие могут сравниться. Эти демоны обладают острым умом и великолепным тактическим чутьём, и благодаря этому могут объединить других демонов. Марилит часто встречают в качестве офицеров во главе демонической орды, где они используют любую возможность ринуться в бой.`,
    ...demonDescription,
  ],
  note: {
    text: `Храм был усыпан кусками тел. Мы решили что культисты призвали мощного демона и умерли, не успев даже пожалеть об этом. Не желая показаться разорванными так же, мы, поджав хвост, вернулись в деревню Хоммлет. Уверен, Руфус и Бьёрн вдоволь бы над нами посмеялись`,
    author: `Нелюм, молодой волшебник полуэльф, описывая свой первый и последний поход в Храм Стихийного Зла`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 92,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 20,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 20,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_16,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Быстрая реакция',
      description: `★СУЩЕСТВО★ может совершать по одной реакции в каждом ходу сражения.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает семь атак: шесть _Длинными мечами_, и одну _Хвостом_.`,
    },
    {
      name: 'Длинный меч',
      // gearId: GEAR_LONGSWORD, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Хвост',
      description: 'Если размер цели не больше Среднего, она становится схваченной (СЛ высвобождения 19). Пока цель схвачена, она обездвижена, ★СУЩЕСТВО★ может автоматически попадать по цели хвостом, и ★СУЩЕСТВО★ не может совершать атаки хвостом по другим целям.',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 5,
    },
  ],
  genderId: GENDER_FEMALE,
}
