const {
  SOURCE_BGDIA,
  SOURCE_MTOF,
} = require('./../../../../sourceList')
const {
  CR_1_2,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MIDDLE,
} = require('./../../../../genderList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_NUPPERIBO,
  CREATURE_STIRGE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Нупперибо',
  nameEn: 'Nupperibo',
  id: CREATURE_NUPPERIBO,
  description: [
    {
      header: 'Нупперибо',
      text: `Ни одну душу не отвергнут в Девяти Преисподней. А самых жалких — тех, чьи злые деяния при жизни были хуже, чем чьи-либо ещё, из-за собственной небрежности и лени — их ждёт участь быть нупперибо. Эти ничтожные отребья бездумно волочатся по пустошам: слепые, распухшие из-за своего нескончаемого голода, и жрущие любую мерзость, которую они только смогут достать.`,
      source: {
        id: SOURCE_MTOF,
        page: 183,
      },
    },
    {
      header: 'Тошнотворные амбалы',
      text: `По отдельности, нупперибо — жалкие твари, но они редко ходят в одиночку и могут быть довольно опасными, если соберутся большой толпой. Они собираются вместе в скопление, способное перекрыть важный проход, либо через целое ущелье. Их окружает рой жалящих насекомых, [кровопийц](CREATURE:${CREATURE_STIRGE}) и прочих паразитов, которые наводят страх и зловоние на всех существ, кроме дьяволов, проходящих мимо них.`,
      source: {
        id: SOURCE_MTOF,
        page: 183,
      },
    },
    {
      header: 'Нескончаемый голод',
      text: `Нупперибо не знают ничего, кроме движущего ими голода. Они слепо ищут всё, что смогут поглотить. Заметив что-то вкусненькое, нупперибо неустанно устремляется к своей жертве, пока либо не сожрёт её, либо не будет убито, либо не встретит на своём пути другую более вкусную жертву, которая отвлечёт его внимание.`,
      source: {
        id: SOURCE_MTOF,
        page: 183,
      },
    },
    {
      header: 'Покорные рабы',
      text: `Не интересуясь ничем, кроме утоления голода, нупперибо бездумно повинуется любому приказу, который телепатически получит от любого дьявола. Такое слепое послушание делает их самыми простыми для управления из всех инфернальных войск. Однако их присутствие в рядах легиона вовсе не способствует повышению статуса его командира.`,
      source: {
        id: SOURCE_MTOF,
        page: 183,
      },
    },
    ...devilDescriptionList,
  ],
  note: [
    {
      text: `Лемур возникает из реки Стикс с полностью стёртой памятью. Но следы его злодеяний навечно оставляют отметины на его душе. Те из лемуров, кому не хватает сил и амбиций пробиться по иерархической лестнице Преисподней, становятся нупперибо.`,
      author: `Архимаг Морденкайнен`,
    },
    ...devilNote,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 183,
    },
    {
      id: SOURCE_BGDIA,
      page: 240,
    },
  ],
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 13,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 1,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
    CONDITION_BLINDED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 10,
      isBlindOutside: true,
    },
  ],
  languageList: [
    {
      id: LANG_INFERNAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Облако вредителей',
      description: `Каждое существо, отличное от дьявола, начинающее ход в пределах 20 футов от нупперибо, должно пройти испытание Телосложения СЛ 11. Если существо находится в двух и более _Облаках вредителей_, то испытание проходится с помехой. При провале существо получает 2 (1к4) колющего урона.`,
    },
    {
      name: 'Ведомый голодом',
      description: `В Девяти Преисподнях нупперибо может безошибочно выслеживать любое существо, которое в течение 24 последних часов было поражено _Облаком вредителей_ любого нупперибо.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MIDDLE,
}
