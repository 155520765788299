const {
  SPELL_ALTER_SELF,
  SPELL_BLINK,
  SPELL_CHROMATIC_ORB,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FIREBALL,
  SPELL_HOLD_PERSON,
  SPELL_KNOCK,
  SPELL_LIGHT,
  SPELL_MAGE_ARMOR,
  SPELL_MENDING,
  SPELL_POLYMORPH,
  SPELL_PRESTIDIGITATION,
  SPELL_RAY_OF_FROST,
  SPELL_SLOW,
  SPELL_STONESKIN,
  SPELL_TELEKINESIS,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_TRANSMUTER} = require('./../../../../creatureIdList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Преобразователь',
  nameEn: 'Transmuter',
  id: CREATURE_TRANSMUTER,
  description: `Преобразователи — специализированные [волшебники](PC_CLASS:${PC_CLASS_WIZARD}), которые используют школу Преобразования, чтобы изменить статус-кво, и рассматривают магическое превращение как путь к богатству или просвещению.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 219,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 4,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Камень преобразования',
      description: `Преобразователь носит созданный им магический камень, который оказывает на носителя один из следующих эффектов:

* Тёмное зрение в пределах 60 футов
* Увеличение скорости на 10 футов пока носитель не перегружен
* Владение испытаниями Телосложения
* Сопротивление урону звуком, кислотой, огнём, холодом или электричеством (по выбору преобразователя при выборе этого эффекта)

Если преобразователь носит этот камень и сотворяет заклинание Преобразования уровня 1 или выше, он может заменить эффект от камня.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 9,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      {
        id: SPELL_ALTER_SELF,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Преобразования 1 уровня и выше, позволяющее поменять эффект способности «Камень преобразования»',
      },
      {
        id: SPELL_EXPEDITIOUS_RETREAT,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Преобразования 1 уровня и выше, позволяющее поменять эффект способности «Камень преобразования»',
      },
      {
        id: SPELL_BLINK,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Преобразования 1 уровня и выше, позволяющее поменять эффект способности «Камень преобразования»',
      },
      {
        id: SPELL_SLOW,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Преобразования 1 уровня и выше, позволяющее поменять эффект способности «Камень преобразования»',
      },
      {
        id: SPELL_KNOCK,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Преобразования 1 уровня и выше, позволяющее поменять эффект способности «Камень преобразования»',
      },
      {
        id: SPELL_POLYMORPH,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Преобразования 1 уровня и выше, позволяющее поменять эффект способности «Камень преобразования»',
      },
      {
        id: SPELL_TELEKINESIS,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Преобразования 1 уровня и выше, позволяющее поменять эффект способности «Камень преобразования»',
      },

      SPELL_CHROMATIC_ORB,
      SPELL_FIREBALL,
      SPELL_HOLD_PERSON,
      SPELL_LIGHT,
      SPELL_MAGE_ARMOR,
      SPELL_MENDING,
      SPELL_PRESTIDIGITATION,
      SPELL_RAY_OF_FROST,
      SPELL_STONESKIN,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
  ],
}
