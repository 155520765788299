const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SPELL_DIVINE_FAVOR} = require('./../../../../../spellIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../../damageTypeList')

module.exports = {
  id: SPELL_DIVINE_FAVOR,
  name: 'Божественное благоволение',
  nameEn: `Divine Favor`,
  description: `Ваша молитва наполняет Вас божественной энергией. Пока заклинание активно, Ваши атаки оружием причиняют при попадании дополнительный урон 1к4.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 1 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  needConcentration: true,
  effect: {
    type: [
      ATTACK_RANGE_WEAPON,
      ATTACK_MELEE_WEAPON,
    ],
    damage: {
      type: [
        DAMAGE_BLUDGEONING,
        DAMAGE_PIERCING,
        DAMAGE_SLASHING,
      ],
      diceCount: 1,
      diceType: 4,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 213,
    },
    {
      id: SOURCE_SRD,
      page: 136,
    },
  ],
}
