const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_BATTLEAXE,
  GEAR_FLAIL,
  GEAR_HIDE_ARMOR,
  GEAR_JAVELIN,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {ABILITY_RECKLESS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {chardalynDescriptionList} = require('./../../../../textCommonParts')
const {CR_4} = require('./../../../../crList')
const {CREATURE_CHARDALYN_BERSERKER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_FIEND} = require('./../../../../creatureTypeIdList')
const {FEATURE_DIVINE_INTERVENTION} = require('./../../../../featureIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_SURVIVAL} = require('./../../../../skillList')
const {SOURCE_IDRotF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_WISH} = require('./../../../../spellIdList')

module.exports = {
  name: 'Чардалиновый берсерк',
  nameEn: 'Chardalyn Berserker',
  id: CREATURE_CHARDALYN_BERSERKER,
  description: [
    `Чардалиновые берсерки — это регхедские кочевники, вооружённые оружием из чардалина. Вернее, из чардалина, напитанного демонической магией.

Постоянный контакт с ним превратил этих кочевников в исчадий. Они избавляются от тёплой одежды и принимают ледяной холод, позволяя морозу разрушать открытые части своих тел, выживая только благодаря истовой верности Ледяной Деве.`,
    {
      header: 'Чардалиновое оружие',
      text: `Чардалиновый берсерк носит одно или несколько оружий, целиком или частично состоящих из напитанного демонической магией чардалина. Чаще всего они вооружены [боевыми топорами](GEAR:${GEAR_BATTLEAXE}), [цепами](GEAR:${GEAR_FLAIL}), [пилумами](GEAR:${GEAR_JAVELIN}) и [копьями](GEAR:${GEAR_SPEAR}), хотя у них встречаются почти любые виды оружия.

Персонаж, присвоивший себе одно или несколько таких оружий, может быть развращён им. Ежедневно на рассвете бросьте к6 для каждого чардалинового оружия во владении персонажа. При результате «1» персонаж должен пройти испытание Харизмы СЛ 13, иначе станет злым НИП под управлением мастера, если только он уже не был злым до этого. Это изменение мировоззрения может быть исцелено любой рассеивающей проклятья магией. Но через десять дней оно сможет быть отменено только заклинанием [исполнение желаний](SPELL:${SPELL_WISH}) или [божественным вмешательством](FEATURE:${FEATURE_DIVINE_INTERVENTION}). 

Существо, раненое одним из этих чардалиновых оружий, чувствует наползающую слабость, но это ощущение быстро проходит.`,
      source: {
        id: SOURCE_IDRotF,
        page: 218,
      },
    },
    {
      header: 'Чардалиновое безумие',
      text: `Длительный контакт с источаемой чардалином демонической магией наделяет берсерков особой формой безумия. Они начинают считать что их через своё оружие могут общаться с лордом демонов или иной тёмной силой и наполняться их яростью. Если безумие берсерка исцелить исцеляющей безумие магией, то он потеряем свою особенность «чардалиновое безумие», но сохранит хаотично-злое мировоззрение и прочие особенности. Хотя новый контакт с демонической магией вновь наделит его этим видом безумия.`,
      source: {
        id: SOURCE_IDRotF,
        page: 218,
      },
    },
    ...chardalynDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_IDRotF,
    page: 281,
  },
  armor: {
    ac: 13,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 17,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_SURVIVAL]: PROF_DOUBLE,
  },
  language: {
    id: LANG_ANY,
    comment: 'обычно — Всеобщий',
    count: 1,
  },
  cr: CR_4,
  featureList: [
    ABILITY_RECKLESS,
    {
      name: 'Чардалиновое безумие',
      description: `★СУЩЕСТВО★ ★должен★ бросать к6 в начале каждого своего хода. При результате «1» ★СУЩЕСТВО★ ничего не делает на своём ходу, а только разговаривает со своим несуществующим злым хозяином, которому ★служит★.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки рукопашным оружием.`,
    },
    {
      name: 'Чардалиновый цеп',
      gearId: GEAR_FLAIL,
      description: `Цель должна пройти испытание Телосложения СЛ 13, или будет Отравлена до конца своего следующего хода.`,
    },
    {
      name: 'Чардалиновый пилум',
      gearId: GEAR_JAVELIN,
      description: `Цель должна пройти испытание Телосложения СЛ 13, или будет Отравлена до конца своего следующего хода.`,
    },
  ],
}
