const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_COLD} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_SNILLOC_S_SNOWBALL_SWARM} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SNILLOC_S_SNOWBALL_SWARM,
  name: 'Снежный шквал Сниллока',
  nameEn: 'Snilloc’s Snowball Swarm',
  description: `На выбранную Вами точку в пределах дистанции заклинания обрушивается снежный шквал. Все существа в сфере с радиусом 5 футов и центром в выбранной точке должны пройти испытание Ловкости. При провале существо получает урон холодом 3к6, а при успехе — половину этого урона.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 3 круга или выше, то урон увеличивается на 1к6 за каждый круг ячейки выше 2.`,
  lvl: 2,
  magicSchoolId: MAGIC_EVOCATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кусок льда или маленький белый осколок камня`,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_COLD,
      halfOnSuccess: true,
      diceCount: 3,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 167,
    },
    {
      id: SOURCE_EE,
      page: 22,
    },
  ],
}
