const {gearByCatCollection} = require('./../../../../../gearList')
const {
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')

const gearCategoryList = require('./gearCategoryList')

module.exports = [
  {
    rarity: MGC_RARITY_RARE,
    bonus: 1,
  },
  {
    rarity: MGC_RARITY_VERY_RARE,
    bonus: 2,
  },
  {
    rarity: MGC_RARITY_LEGENDARY,
    bonus: 3,
  },
]
  .reduce(
    (
      list,
      {
        rarity,
        bonus,
      }
    ) => [
      ...list,
      ...gearCategoryList.reduce(
        (listByCats, catId) => [
          ...listByCats,
          ...gearByCatCollection[catId].map(
            gearId => ({
              id: `${gearId}_plus_${bonus}`,
              gearId,
              rarity,
              bonus,
            })
          )
        ],
        []
      )
    ],
    []
  )
