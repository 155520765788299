const {CREATURE_TYPE_DWARF} = require('./../../creatureTypeIdList')
const {GOD_MORADIN} = require('./../../godIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_DWARF,
  nameEn: 'Dwarf',
  nameAlt: 'Крепкий народ',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'дварф',
        genitive: 'дварфа',
        dative: 'дварфу',
        accusative: 'дварфа',
        instrumental: 'дварфом',
        prepositional: 'дварфе',
      },
      [GENDER_FEMALE]: {
        nominative: 'дварфийка',
        genitive: 'дварфийки',
        dative: 'дварфийке',
        accusative: 'дварфийку',
        instrumental: 'дварфийкой',
        prepositional: 'дварфийке',
      },
    },
    plural: {
      nominative: 'дварфы',
      genitive: 'дварфов',
      dative: 'дварфам',
      accusative: 'дварфов',
      instrumental: 'дварфами',
      prepositional: 'дварфах',
    },
  },
  note: [
    {
      text: `„Ты опоздал, эльф!“ — послышался знакомый грубый голос. Бруенор Батлхаммер встал на труп убитого им йети, не обращая внимания на то, что под ним корчился его эльфийский друг. Несмотря на испытываемые неудобства, Дриззт всё же был рад видеть этот большой нос и рыжую бороду. „Я так и знал, что ты вляпаешься в какую-нибудь историю и мне снова придётся тебя вытаскивать!“.`,
      author: `Р. А. Сальваторе, Магический кристалл`,
    },
    {
      text: `Золото — дар гор, а железо добывается кровью и костьми`,
      author: `Мрорская пословица`,
    },
  ],
  description: [
    {
      text: `Полные древнего величия королевства и вырезанные в толще гор чертоги, удары кирок и молотков, раздающиеся в глубоких шахтах и пылающий кузнечный горн, верность клану и традициям и пылающая ненависть к гоблинам и оркам — вот вещи, объединяющие всех дварфов.`,
      source: {
        id: SOURCE_PHB,
        page: 19,
      },
    },
    {
      text: `Стойкий и осмотрительный, Крепкий Народ издавна славится своими великими мастерами, строителями и воинами. Хотя былое величие их империй давно поблекло, дварфы по-прежнему придерживаются своих древних обычаев и традиций. Они упорно защищают то, что осталось от их старых владений, укрытых под холмами и горами, а некоторые — ищут возможность вернуть утраченное под натиском орков, гоблинов, и неумолимого хода времени.

Согласно их легендам, дварфы были созданы из железа, мифрила, земли и камня в Кузнице Душ [Морадина](GOD:${GOD_MORADIN}). После того, как Всеотец вдохнул в них жизнь в самом сердце мира, дварфы проложили себе путь на поверхность и оттуда распространились по всем континентам.

За тысячи лет расселения по миру дварфы разделились на подрасы: щитовые дварфы, наиболее распространённые на Севере и на Побережье Меча, золотые дварфы южных земель и серые дварфы или двергары — жители Подземья.

Дварфийский язык Фаэруна использует рунический алфавит Детек, знаки которого легко выгравировать на камне и металле, свидетельством чего являются рунические камни и путевые знаки, найденные в древних дварфийских тоннелях и шахтах.`,
      source: {
        id: SOURCE_SCAG,
        page: 104,
      },
    },
    {
      header: `Дварфийские кланы Севера`,
      text: `Все дварфы считают клановое наследие важной частью их родословной и личной идентичности. В одних городах доминирует лишь один клан (или даже он является единственным кланом), в других дварфийских общинах выстроена сложная система взаимоотношений между семьями, кланами и социумом в целом.

Далее приведены некоторые дварфийские кланы Севера: Арнскулл, Бэттлхаммер (Боевой Молот), Блэкбэннер (Чёрный Стяг), Блекхаммер (Чёрный Молот), Баклбар, Даркфэлл (Черношкурые), Дипэкс (Секиры Глубин), Дипделв (Глубокопы), Иглклэфт (Орлиная Долина), Фоухаммер (Вражий Молот), Гэллоугар, Хилсафар (Дальние Холмы), Хорн (Горн), Айроншилд (Железнощитые), Юндет, Нарлаг, Оротьяр, Куэримастер (Карьермастер), Рокфист (Каменный Кулак), Сстар, Стоуншафт (Каменная Шахта), Стоуншилд (Камнещит), Стоуншолдер (Камнеплеч), Труфорджер (Правдоков), Уотчэвер (Вечногляд), Уорлдтроун (Миротрон), Вирмслэйер (Змиегуб) и Юнд.

Некоторые дварфы, происходящие родом из семьи, которая основала данный клан или правила им, используют имя клана в качестве фамильного имени. Другие просто добавляют: _«из клана …»_, но при этом носят имя клана с не меньшей гордостью, чем собственную фамилию.`,
      source: {
        id: SOURCE_SCAG,
        page: 104,
      },
    },
    {
      header: `Невысокие и крепкие`,
      text: `Смелые и выносливые дварфы известны как опытные воины, шахтёры, камнетёсы и металлурги. Хотя они и не превышают 5 футов (152 сантиметра) в высоту, дварфы настолько широкоплечие и плотные, что весят столько же, сколько превосходящий их в росте на 2 фута (60 сантиметров) человек. Их отвага и выносливость также не уступает представителям более высоких народов.

Кожа у дварфов бывает от тёмно-коричневой до светлой, с красным оттенком. Наиболее распространённые оттенки — светло-коричневый или смуглый, как разные виды земли. Их волосы, которые они носят длинными, но собранными в простые причёски, обычно чёрного, серого или коричневого цвета, но у дварфов с бледной кожей часто встречаются рыжие волосы. Дварфы мужчины очень ценят свои бороды и тщательно за ними ухаживают. `,
      source: {
        id: SOURCE_PHB,
        page: 19,
      },
    },
    {
      header: `Долгая память, долгие обиды`,
      text: `Дварфы могут жить более 400 лет, и старейшие из них часто помнят этот мир сильно отличным от нынешнего. К примеру, некоторые из старейших гномов цитадели Фелбар (в мире Забытых Королевств) могут припомнить дни, когда более трёх веков назад орки завоевали их крепость и обрекли их на двухсот пятидесятилетние скитания. Такая долговечность даёт им особый взгляд на мир, которого лишены короткоживущие расы вроде людей и полуросликов.

Дварфы стойкие и выносливые, как горы, в которые они влюблены. Они стоически противостоят проходящим столетиям и почти не меняются. Они уважают традиции своих кланов, прослеживая их родословную до самого основания древнейших твердынь на рассвете молодого мира, и не отказываются от этих традиций с лёгкостью. Частью этих традиций является преданность богам дварфов, которые поощряют присущие дварфам идеалы: прилежный труд, сноровку в бою и любовь к кузнечному горну.

Дварфам присущи непреклонность и лояльность, верность своему слову, решительность в действиях, временами переходящие в упрямство. У многих дварфов сильно развито чувство справедливости, и они медленно прощают причинённое им зло. Зло причинённое одному дварфу, это зло, причинённое всему его клану. Таким образом, попытка отомстить одному дварфу может превратиться во вражду, захлестнувшую целый клан.`,
      source: {
        id: SOURCE_PHB,
        page: 19,
      },
    },
    {
      header: `Кланы и королевства`,
      text: `Королевства дварфов простираются глубоко под горами, где дварфы добывают драгоценные металлы и камни и куют чудесные вещи. Они любят красоту драгоценных металлов и ювелирных изделий, и у некоторых дварфов эта любовь может превратиться в алчность. Ценности, которые они не могут найти в своих горах, они получают благодаря торговле. Они не любят кораблей, и предприимчивые люди и полурослики часто ведут торговлю дварфийскими товарами через водные пути. Благонадёжным представителям этих рас всегда рады в дварфийских поселениях, хотя некоторые места там закрыты даже для них.

Главным элементом дварфийского общества является клан, и дварфы очень высоко ценят социальное положение. Даже дварфы, живущие вдалеке от своих королевств, хранят свою принадлежность к клану, узнают относящихся к нему соплеменников и взывают к именам предков, произнося клятвы и выкрикивая проклятья. Лишение клана это худшая судьба, способная выпасть на участь дварфа.

Дварфы в чужих землях обычно работают ремесленниками, чаще всего оружейниками или ювелирами. Некоторые становятся наёмниками или телохранителями, очень ценимыми за их храбрость и преданности.`,
      source: {
        id: SOURCE_PHB,
        page: 20,
      },
    },
    {
      header: `Боги, золото, клан`,
      text: `Дварф, выбравший путь искателя приключений, может руководствоваться жаждой сокровищ — для личного пользования, для достижения определённой цели или даже ради альтруистического желания помочь другим. Другими может двигать божественное озарение, прямой приказ, или желание принести славу одному из дварфийских божеств. Клан и предки тоже являются одними из сильнейших мотивов. Дварф может искать способ вернуть утерянную честь клана, отомстить древнему врагу или вновь обрести место в клане, откуда был изгнан. Или он может отправиться на поиски топора, утерянного предком на поле брани столетия назад.`,
      source: {
        id: SOURCE_PHB,
        page: 20,
      },
    },
    {
      header: `Недоверчивые`,
      text: `Дварфы вполне сносно ладят с представителями других рас. _«Разница между знакомым и другом — сто лет»_. Эта дварфийская поговорка возможно и преувеличивает, но легко даёт понять, как сложно людям, и другим короткоживущим расам завоевать доверие дварфов.

# Об эльфах

_«Неразумно полагаться на эльфов. Никогда не знаешь, что они способны сделать в следующий момент. Если дело доходит до драки, они могут как выхватить меч, так и спеть вам песенку. Они ветрены и легкомысленны, и только две вещи можно сказать наверняка. У них мало кузнецов, но те, что есть, отлично знают своё дело. И когда орки и гоблины устремляются со своих гор в очередной набег, лучше иметь эльфов в союзниках. Не так хорошо, как других дварфов, конечно, но никто не усомнится, что они ненавидят орков не меньше нашего»._

# О полуросликах

_«Они, конечно, милый народец. Но покажите мне полурослика-героя. Империю. Победоносную армию полуросликов. Да хотя бы приличное сокровище, сделанное их руками. Ничего! Как вообще можно воспринимать их серьёзно?»_

# О людях

_«Ты потратил время, чтобы узнать человека, а он взял и помер. Если повезёт, у него останется семья — дочка, или может внучка, или хоть кто-то, с руками и сердцем, достойными его. Как можно стать человеку другом и смотреть, как он умирает? Зато, если они что-то вбили себе в голову, они это получат, будь это сокровище дракона или же трон целой империи. Нельзя не восхищаться такой целеустремлённостью, даже несмотря на то, что чаще всего это приносит им проблемы»._`,
      source: {
        id: SOURCE_PHB,
        page: 20,
      },
    },
    {
      header: `Имена дварфов`,
      text: `Имя дварфу даётся старейшиной клана согласно традиции. Каждое подходящее имя используется из поколения в поколение. Имя дварфа принадлежит клану, а не отдельному дварфу. Дварф, опорочивший своё имя, лишается его, и согласно законам, ему запрещается использовать любое другое дварфийское имя.

# Мужские имена

Адрик, Альберих, Баренд, Баэрн, Броттор, Бруенор, Вондал, Вэйт, Гардаин, Даин, Даррак, Делг, Килдрак, Моргран, Орсик, Оскар, Рангрим, Рюрик, Таклинн, Торадин, Тордек, Торин, Травок, Траубон, Ульфгар, Фаргрим, Флинт, Харбек, Эберк, Эйнкиль

# Женские имена

Артин, Бардрин, Вистра, Гуннлода, Гурдис, Дагнал, Диеза, Илде, Катра, Кристид, Лифтраса, Мардред, Одхильд, Рисвин, Саннл, Торбера, Торгга, Фалкрунн, Финеллен, Хельджа, Хлин, Эльдет, Эмбер

# Названия кланов

Балдерк, Боевой Молот, Горунн, Данкил, Железный Кулак, Крепкая Наковальня, Ледяная Борода, Лодерр, Лютгер, Огненная Кузня, Рамнахейм, Стракелн, Торунн, Унгарт, Холдерхек`,
      source: {
        id: SOURCE_PHB,
        page: 21,
      },
    },
  ],
}
