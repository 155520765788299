const {CREATURE_DRAGON_BRASS_WYRMLING} = require('./../../creatureIdList')
const {SOURCE_MM} = require('./../../sourceList')

const metalDragonDescriptionList = require('./dragonMetallic')

module.exports = [
  {
    header: 'Логово латунного дракона',
    text: `Пустынное логово латунного дракона обычно представляет собой руины, каньон, или сеть пещер с потолочными отверстиями для пропускания солнечного света.

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) дракон совершает действие логова, чтобы вызвать один из следующих эффектов:

* Сильные порывы ветра свирепствуют вокруг дракона. Все существа в пределах 60 футов от дракона должны пройти испытание Силы СЛ 15, иначе они отталкивается на 15 футов от дракона и сбиваются с ног. Газы и испарения разносятся ветром, а незащищённое пламя гаснет. Защищённое пламя, такое как фонари, имеет 50% вероятность погаснуть 
* Облако песка начинает вращаться в сфере радиусом в 20 футов, с центром на точке, которую дракон видит в пределах 120 футов. Облако может огибать углы. Все находящиеся в нём существа должны пройти испытание Телосложения СЛ 15, иначе становятся ослеплёнными на 1 минуту. Существо может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.

# Местные эффекты

Регион, в котором есть логово легендарного латунного дракона, искажается драконьей магией, которая создаёт один или несколько следующих эффектов:

* На песке в пределах 6 миль от логова дракона появляются следы. Они ведут к безопасным убежищам и скрытым источникам воды, а также подальше от зон, которые дракон хотел бы оставить нетронутыми. 
* Образы Больших и меньших чудовищ появляются в пустынных песках в пределах 1 мили от драконьего логова. Эти иллюзии перемещаются, и выглядят настоящими, хотя они и не могут причинить никакого вреда. Существо, которое анализирует образ с расстояния, может сказать, что это иллюзия, если совершит успешную проверку Интеллекта (Анализ) СЛ 20. Любое физическое взаимодействие с образом даёт понять, что это иллюзия, потому что объекты проходят через него. 
* Каждый раз, когда существо с Интеллектом 3 или выше проходит в пределах 30 футов от источника воды в пределах 1 мили от драконьего логова, дракону становится известно о присутствии существа и его местоположении.

Если дракон умирает, следы пропадают за 1к10 дней, а остальные эффекты рассеиваются немедленно.`,
    source: {
      id: SOURCE_MM,
      page: 129,
    },
  },
  {
    header: 'Латунные драконы',
    text: `Самые общительные из истинных драконов, латунные драконы обожают разговоры, солнечный свет и горячий, сухой климат.

Голова латунного дракона окаймляется широкой защитной пластиной, отходящей от его лба и шипами, выступающими из его подбородка. Перепончатый гребень бежит вдоль всей его шеи, а его сужающиеся крылья простираются до самого кончика хвоста. Чешуя [вирмлингов латунных драконов](CREATURE:${CREATURE_DRAGON_BRASS_WYRMLING}) тускло-коричневая с пятнами. По мере роста дракона чешуйки приобретают тёплый оттенок и начинают блестеть, будто полированные. Его крылья и перепонки, покрытые зелёными точками на краях, темнеют с возрастом. Когда латунный дракон становится старше, его зрачки постепенно исчезают, пока глаза не становятся похожи на расплавленные металлические шары.

# Отчаянный болтун

Латунный дракон общается с тысячами существ на протяжении своей долгой жизни, накапливая полезную информацию, которой он с удовольствием поделится в обмен на подарок в сокровищницу. Если разумное существо попытается сбежать от латунного дракона, не вступая в диалог, дракон последует за ним. Если существо пытается сбежать, используя магию или силу, дракон в порыве досады может использовать усыпляющий газ, чтобы обездвижить существо. Когда оно просыпается, существо осознает себя прижатым к земле гигантскими когтями или закопанным в песок по шею, в то время пока дракон удовлетворяет свою жажду небольшой беседы.

Латунный дракон доверяет существам, которые наслаждаются беседой так же, как и он, но он достаточно умён, чтобы заметить, когда им пытаются манипулировать. Когда это происходит, дракон, как правило, отвечает по-доброму, расценивая попытки обмануть друг друга как игру.

# Ценные сокровища

Латунные драконы любят магические предметы, которые позволяют им общаться с интересными личностями. Разумное телепатическое оружие или волшебная лампа с джинном, заточённым внутри, являются одними из самых величайших сокровищ, которыми может обладать латунный дракон.

Латунные драконы скрывают свои сокровища под насыпями песка или в тайных закромах вдали от своего основного логова. У них не возникает никаких проблем с запоминанием места, где спрятаны их сокровища, а потому нет никакой необходимости в картах. Искатели приключений и странники должны быть осторожны, если они находят скрытый сундук в оазисе или сокровища, спрятанные в наполовину поглощённых пустыней руинах, ибо это может быть частью клада латунного дракона.`,
    source: {
      id: SOURCE_MM,
      page: 129,
    },
  },
  ...metalDragonDescriptionList,
]
