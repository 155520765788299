const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_21,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_ANY_EVIL,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_SPELL,
  } = require('./../../../../attackTypeList'),
  {
    LANG_ANY,
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_TRUE_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_ARCANA,
    SKILL_HISTORY,
    SKILL_INSIGHT,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    PC_CLASS_WIZARD,
  } = require('./../../../../pcClassIdList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_ANIMATE_DEAD,
    SPELL_BLIGHT,
    SPELL_CLOUDKILL,
    SPELL_COUNTERSPELL,
    SPELL_DETECT_MAGIC,
    SPELL_DETECT_THOUGHTS,
    SPELL_DIMENSION_DOOR,
    SPELL_DISINTEGRATE,
    SPELL_DISPEL_MAGIC,
    SPELL_DOMINATE_MONSTER,
    SPELL_FINGER_OF_DEATH,
    SPELL_FIREBALL,
    SPELL_GLOBE_OF_INVULNERABILITY,
    SPELL_INVISIBILITY,
    SPELL_MAGE_HAND,
    SPELL_MAGIC_MISSILE,
    SPELL_MELF_S_ACID_ARROW,
    SPELL_MIRROR_IMAGE,
    SPELL_PLANE_SHIFT,
    SPELL_POWER_WORD_KILL,
    SPELL_POWER_WORD_STUN,
    SPELL_PRESTIDIGITATION,
    SPELL_RAY_OF_FROST,
    SPELL_SCRYING,
    SPELL_SHIELD,
    SPELL_THUNDERWAVE,
    SPELL_IMPRISONMENT,
  } = require('./../../../../spellIdList'),
  {
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_TURN_RESISTANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_FLESH_GOLEM,
    CREATURE_LICH,
    CREATURE_ORCUS,
    CREATURE_SKELETON,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Лич',
  nameEn: 'Lich',
  description: [
    {
      header: `Лич`,
      text: `Личи — это бывшие великие волшебники, ставшие нежитью для сохранения самих себя. Они любой ценой копят силы, нисколько не заботясь делами смертных кроме тех случаев, когда они пересекаются с их собственными интересами. Коварные и безумные, они жаждут давно забытых знаний и ужасных секретов. Так как страх смерти не властен над ними, они могут задумывать планы, воплощение которых займёт года, десятки лет, а то и столетия.

Личи выглядят как худые скелетоподобные гуманоиды с высохшей кожей, обтягивающей их кости. Их глаза давным-давно сгнили, однако в пустых глазницах горят точечки света, подобные зрачкам. Они часто облачены в остатки хорошей одежды и ювелирные украшения, заношенные и выцветшие со временем.`,
      source: {
        id: SOURCE_MM,
        page: 195,
      },
    },
    {
      header: `Секреты нежити`,
      text: `Ни один волшебник не станет личом просто по своей прихоти. Процесс превращения в лича — это хорошо охраняемый секрет. Волшебникам, ищущим эти знания, необходимо заключать сделки с исчадиями, злыми божествами и другими отвратительными существами. Многие обращаются к [Оркусу, Демоническому Повелителю Нежити](CREATURE:${CREATURE_ORCUS}), чьими силами было создано бесчисленное множество личей. Однако те, кто контролируют эти знания, всегда требуют за них службы и верности.

Превращение в лича проходит благодаря ритуалу, в ходе которого душа волшебника заточается в филактерий. Его душа привязывается к миру смертных, что не даёт ей после смерти переходить на другие планы существования. Филактерий представляет собой, как правило, амулет в виде маленькой коробочки, но это может быть любой предмет с пустым пространством внутри, в котором серебром начертаны магические символы имени, связывания, бессмертия и тёмной волшбы.

Как только филактерий подготовлен, будущий лич должен выпить зелье трансформации — смесь ужасного яда с кровью разумного существа, чья душа тоже приносится в жертву. После этого волшебник умирает, а затем, когда его душа будет заключена в филактерий, восстаёт как лич.`,
      source: {
        id: SOURCE_MM,
        page: 195,
      },
    },
    {
      header: `Жертвоприношение душ`,
      text: `Лич должен периодически скармливать души своему филактерию, чтобы поддерживать магию, сохраняющую его тело и сознание. Он делает это, используя заклинание [Заточение](SPELL:${SPELL_IMPRISONMENT}). Вместо выбора одной из стандартных опций заклинания, лич использует его, чтобы заключить тело и душу жертвы внутри филактерия. Филактерий должен находиться на том же плане, что и лич, чтобы заклинание сработало. Филактерий лича может одновременно содержать только одно существо, а заклинание [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) 9 уровня, применённое на филактерий, освобождает заключённое в нём существо. Существо, заключённое в филактерии, через 24 часа поглощается им и исчезает без следа, после чего ничто, кроме божественного вмешательства, не может вернуть его к жизни.

Лич, который не смог или забыл поддерживать своё тело душами жертв, начинает физически разрушаться, и в конце концов может превратиться в демилича.`,
      source: {
        id: SOURCE_MM,
        page: 195,
      },
    },
    {
      header: `Смерть и восстановление`,
      text: `Когда тело лича случайно или намеренно разрушается, его воля и разум уходят из тела, оставляя позади лишь бездыханный труп. Через несколько дней подле филактерия лича из светящегося дыма формируется новое тело. Так как уничтожение филактерия есть шанс вечной смерти, личи обычно хранят свои филактерии в укрытом и хорошо охраняемом месте.

Уничтожение филактерия лича — нелёгкая задача, ведь часто для этого требуется особый ритуал, предмет или оружие. Каждый филактерий уникален, и секрет его уничтожения может стать целью целого приключения.`,
      source: {
        id: SOURCE_MM,
        page: 195,
      },
    },
    {
      header: `Одинокое существование`,
      text: `Время от времени лич может отвлечься от своей изоляционистской погони за мощью и поинтересоваться тем, что происходит в мире вокруг — такое происходит чаще всего во время великих потрясений, которые напоминают ему о жизни, которой он когда-то жил. В прочих случаях они живут в изоляции, вступая в контакт лишь с теми существами, чья служба помогает им поддерживать безопасность их логова.

Некоторые личи называют себя своими прежними именами и прозвищами, например, Чёрная Рука или Забытый Король.`,
      source: {
        id: SOURCE_MM,
        page: 195,
      },
    },
    {
      header: `Коллекционеры магии`,
      text: `Личи ищут и собирают свитки заклинаний и магические предметы. В дополнение к своим магическим способностям лич также может использовать и создавать зелья и свитки, имеет свою личную библиотеку с магическими книгами, и часто у лича есть один или два магических посоха или магические палочки. Если на лича нападут, то он не побоится их использовать.`,
      source: {
        id: SOURCE_MM,
        page: 195,
      },
    },
    {
      header: `Природа нежити`,
      text: `Личу не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 195,
      },
    },
    {
      header: `Логово лича`,
      text: `Лич большую часть времени находится в том месте, в котором он был при жизни. Это может быть дом с привидениями, руины башни или даже школа тёмной магии. Но некоторые личи могут построить себе собственное, секретное подземелье, о котором никто кроме них не будет знать. Эти подземелья, как правило, хорошо охраняются ловушками или стражами ([големы](CREATURE:${CREATURE_FLESH_GOLEM}), [скелеты](CREATURE:${CREATURE_SKELETON}) и другие чудовища). Логово лича отражает его высокий интеллект и ужасную хитрость, включая магические и обычные ловушки, которые обеспечивают его безопасность. Нежить, подвижные механизмы и демоны станут препятствием для тех, кто захочет нарушить покой лича.

У лича, встреченного в логове, показатель опасности 22 (41000 опыта).

# Действия логова

При значении инициативы «20» (в случае ничьей проигрывает всем конкурентам) лич может совершить действие логова, чтобы вызвать один из следующих эффектов (лич не может использовать один эффект два раунда подряд):

* Лич бросает к8 и восстанавливает одну ячейку заклинаний с уровнем не больше выпавшего результата. Если все такие ячейки доступны, то ничего не происходит. 
* Лич выбирает целью одно существо, которое видит в пределах 30 футов. Потрескивающая нить негативной энергии связывает лича и цель. После этого, когда лич получает урон, цель должна будет проходить испытание Телосложения СЛ 18. При провале лич получает лишь половину урона (округляя в меньшую сторону), а остальной урон получает цель. Эта связь держится до наступления инициативы «20» в следующем раунде, или пока лич или его цель не окажутся вне логова лича. 
* Лич вызывает духов существ, которые умерли в его логове. Эти духи материализуются и начинают атаковать одно существо, которые лич видит в пределах 60 футов. Цель должна пройти испытание Телосложения СЛ 18, иначе получит урон некротической энергией 52 (15к6) при провале, или половину этого урона при успехе. После этого духи исчезают.`,
      source: {
        id: SOURCE_MM,
        page: 195,
      },
    },
  ],
  id: CREATURE_LICH,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_ANY_EVIL,
  source: {
    id: SOURCE_MM,
    page: 195,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_DOUBLE,
    [SKILL_INSIGHT]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NECROTIC,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
    {
      id: LANG_ANY,
      count: 5,
    },
  ],
  cr: CR_21,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    {
      name: 'Восстановление',
      description: `Если ★СУЩЕСТВО★ имеет филактерий, то через 1к10 дней после уничтожения ★СУЩЕСТВО★ получает новое тело, восстанавливает все хиты и становится живым. Новое тело появляется в пределах 5 футов от филактерия.`,
    },
    ABILITY_TURN_RESISTANCE,
  ],
  spellCast: {
    spellCasterLevel: 18,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_ANIMATE_DEAD,
      SPELL_BLIGHT,
      SPELL_CLOUDKILL,
      SPELL_COUNTERSPELL,
      SPELL_DETECT_MAGIC,
      SPELL_DETECT_THOUGHTS,
      SPELL_DIMENSION_DOOR,
      SPELL_DISINTEGRATE,
      SPELL_DISPEL_MAGIC,
      SPELL_DOMINATE_MONSTER,
      SPELL_FINGER_OF_DEATH,
      SPELL_FIREBALL,
      SPELL_GLOBE_OF_INVULNERABILITY,
      SPELL_INVISIBILITY,
      SPELL_MAGE_HAND,
      SPELL_MAGIC_MISSILE,
      SPELL_MELF_S_ACID_ARROW,
      SPELL_MIRROR_IMAGE,
      SPELL_PLANE_SHIFT,
      SPELL_POWER_WORD_KILL,
      SPELL_POWER_WORD_STUN,
      SPELL_PRESTIDIGITATION,
      SPELL_RAY_OF_FROST,
      SPELL_SCRYING,
      SPELL_SHIELD,
      SPELL_THUNDERWAVE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      3,
      3,
      1,
      1,
      1,
      1,
    ],
  },
  actionList: [
    {
      name: 'Парализующее касание',
      description: `Цель должна пройти испытание Телосложения СЛ 18, иначе станут парализованной на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 12,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_COLD,
          diceCount: 3,
          diceType: 6,
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Заговор',
      description: `★СУЩЕСТВО★ сотворяет заговор.`,
    },
    {
      name: 'Парализующее касание',
      cost: 2,
      description: `★СУЩЕСТВО★ использует _Парализующее касание_.`,
    },
    {
      name: 'Пугающий взгляд',
      cost: 2,
      description: `★СУЩЕСТВО★ фиксирует взгляд на одном существе, видимом в пределах 10 футов. Цель должна пройти испытание Мудрости СЛ 18 от этой магии, иначе станет испуганной на 1 минуту. Испуганная цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. Если цель успешно прошла испытание или действие эффекта закончилось, она получает иммунитет к взгляду этого лича на следующие 24 часа.`,
    },
    {
      name: 'Разрушение жизни',
      cost: 3,
      description: `Все существа, не являющиеся нежитью, в пределах 20 футов от ★СУЩЕСТВО★ должны пройти испытание Телосложения СЛ 18 от этой магии, получая урон некротической энергией 21 (6к6) при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
