const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_INCORPOREAL_MOVEMENT,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_SPECTER,
  CREATURE_WRAITH,
} = require('./../../../../creatureIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_5} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Призрак',
  nameEn: 'Wraith',
  id: CREATURE_WRAITH,
  description: [
    {
      header: 'Призрак',
      text: `Призрак — это воплощение злобы, сконцентрированное в бестелесной форме, которое стремится истребить всё живое. Это существо наполнено отрицательной энергией, и одно лишь его присутствие иссушает окружающие растения. Животные бегут от него. Даже небольшие огни тухнут, поглощённые забвением ужасного существования призрака.`,
      source: {
        id: SOURCE_MM,
        page: 243,
      },
    },
    {
      header: 'Мерзкое забвение',
      text: `Если смертный гуманоид живёт порочной жизнью или заключает договор с исчадиями, он предаёт свою душу на вечное проклятье в Нижние Планы. Однако иногда душа настолько наполняется отрицательной энергией, что распадается и перестаёт существовать за миг до перехода в ужасное посмертие. Когда это происходит, дух становится бездушным призраком — злобной пустотой, запертой на плане, на котором он умер. Почти ничего прежнего от прошлой жизни призрака не сохраняется; в этой новой форме он существует только для уничтожения другой жизни.`,
      source: {
        id: SOURCE_MM,
        page: 243,
      },
    },
    {
      header: 'Отсутствие тела',
      text: `Призрак может перемещаться сквозь существ и твёрдые предметы так же легко, как смертное существо движется сквозь туман. Призрак может сохранить отдельные воспоминания о своей смертной жизни в виде туманных отголосков. Но даже самые важные события в жизни и самые сильные эмоции будут теперь лишь тусклым отпечатком в памяти, ускользающим как полузабытый сон. Призрак может на мгновение застыть, увидев что-то, что восхищало его при жизни, или умерить свою ярость, признав прошлую дружбу. Однако такие моменты бывают редко, потому что большинство призраков презирает былую жизнь, так как это прошлое напоминает им о том, чем они стали теперь.`,
      source: {
        id: SOURCE_MM,
        page: 243,
      },
    },
    {
      header: 'Командиры нежити',
      text: `Призраки могут создавать слуг-мертвецов из духов гуманоидных существ, которые недавно умерли насильственной смертью. Такой дух становится [спектром](CREATURE:${CREATURE_SPECTER}), ненавидящим всё живое. Призраки иногда управляют легионами мертвецов, замышляя уничтожение живых существ. Когда они появляются из своих гробниц, чтобы вступить в битву, жизнь и надежда содрогаются перед ними. Даже если армия призрака вынуждена отступить, земли, которые были заняты его силами, настолько опустошены и истощены, что живущие в них зачастую могут просто умереть от голода.`,
      source: {
        id: SOURCE_MM,
        page: 243,
      },
    },
    {
      header: 'Природа нежити',
      text: `Призраку не нужен воздух, еда, питьё и сон.`,
      source: {
        id: SOURCE_MM,
        page: 243,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 243,
  },
  armor: 13,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 60,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 16,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 15,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_GRAPPLED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_INCORPOREAL_MOVEMENT,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: `Вытягивание жизни`,
      description: `Цель должна пройти испытание Телосложения СЛ 14, иначе максимум её хитов уменьшится на количество, равное полученному урону. Это уменьшение длится до тех пор, пока цель не окончит длинный отдых. Цель умирает, если этот эффект уменьшает максимум её хитов до 0.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 4,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: `Создание спектра`,
      description: `★СУЩЕСТВО★ нацеливается на гуманоида в пределах 10 футов, который умер насильственной смертью не более 1 минуты назад. Дух цели восстаёт [спектром](CREATURE:${CREATURE_SPECTER}) в пространстве трупа или ближайшем свободном пространстве. [Спектр](CREATURE:${CREATURE_SPECTER}) находится под контролем у ★СУЩЕСТВО★. У ★СУЩЕСТВО★ может быть не более семи [спектров](CREATURE:${CREATURE_SPECTER}) под контролем одновременно.`,
    },
  ],
  genderId: GENDER_MALE,
}
