const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  CREATURE_TYPE_DRAGON_METALLIC,
} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_DRAGON_METALLIC,
  nameEn: 'Metallic Dragon',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'металлический дракон',
        genitive: 'металлического дракона',
        dative: 'металлическому дракону',
        accusative: 'металлического дракона',
        instrumental: 'металлическим драконом',
        prepositional: 'металлическом драконе',
      },
      [GENDER_FEMALE]: {
        nominative: 'металлическая драконица',
        genitive: 'металлической драконицы',
        dative: 'металлической драконице',
        accusative: 'металлическую драконицу',
        instrumental: 'металлической драконицей',
        prepositional: 'металлической драконице',
      },
    },
    plural: {
      nominative: 'металлические драконы',
      genitive: 'металлических драконов',
      dative: 'металлическим драконам',
      accusative: 'металлических драконов',
      instrumental: 'металлическими драконами',
      prepositional: 'металлических драконах',
    },
  },
}
