const {
  CREATURE_AIR_ELEMENTAL,
  CREATURE_DUST_DEVIL,
} = require('./../../../../creatureIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  PARAM_INT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {blueDragonLocalEffectText} = require('./../../../../textCommonParts')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Пыльный дьявол',
  nameEn: 'Dust Devil',
  id: CREATURE_DUST_DEVIL,
  parentId: CREATURE_AIR_ELEMENTAL,
  description: blueDragonLocalEffectText,
  source: {
    id: SOURCE_MM,
    page: 116,
  },
  extendPropCollection: {
    speed: {
      [SPEED_WALK]: 50,
      [SPEED_FLY]: null,
    },
    params: {
      [PARAM_INT]: 1,
      [PARAM_CHA]: 1,
    },
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
  },
}
