const {PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  GEAR_CALLIGRAPHERS_SUPPLIES,
  GEAR_LONGBOW,
  GEAR_PAINTERS_SUPPLIES,
} = require('./../../../gearIdList')
const {
  FEATURE_MARTIAL_ARTS,
  FEATURE_ONE_WITH_THE_BLADE,
  FEATURE_PATH_OF_THE_KENSEI,
  FEATURE_SHARPEN_THE_BLADE,
  FEATURE_UNERRING_ACCURACY,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_PATH_OF_THE_KENSEI,
    name: `Путь кенсэя`,
    nameEn: `Path of the Kensei`,
    lvl: [3, 6, 11, 17],
    text: `Ваше освоение боевых искусств ведёт Вас к мастерству использования определенных видов оружия. Этот путь также включает в себя владение ловкими мазками кисти в каллиграфии или живописи. Вы получаете следующие преимущества:

# Оружие кенсэя

Выберите два типа оружия в качестве своих оружий кенсэя: одно рукопашное и одно дальнобойное.

Любое из этих оружий может быть простым или воинским, у которого нет свойств _тяжёлое_ и _особое_. [Длинный лук](GEAR:${GEAR_LONGBOW}) тоже можно выбрать. Вы получаете владение этими оружием, если у Вас его нет. Оружия этих типов становятся монашеским оружием для Вас.

Многие способности этой традиции работают только с оружием кенсэя. Когда Вы достигаете 6, 11 и 17 уровня в этом классе, Вы можете выбрать ещё один тип оружия — рукопашное или дальнобойное Вашим оружием кенсэя, учитывая критерии, приведенные выше.

# Ловкое парирование

Если Вы совершаете безоружный удар как часть действия Атака в свой ход и держите рукопашное оружие кенсэя, Вы можете использовать его, чтобы защититься. Вы получаете бонус +2 к КД до начала Вашего следующего хода, если оружие находится в Вашей руке и Вы не недееспособны.

# Выстрел кенсэя

Бонусным действием в свой ход Вы можете сделать свою дальнобойную атаку оружием кенсэя более смертоносной. Когда Вы так делаете, любая цель, по которой Вы попали дальнобойной атакой, используя оружие кенсэя, получает дополнительно 1к4 урона такого же типа, как у оружия. Вы сохраняете это преимущество до конца текущего хода.

# Путь кисти

Вы получаете владение [инструментами каллиграфа](GEAR:${GEAR_CALLIGRAPHERS_SUPPLIES}) или [художника](GEAR:${GEAR_PAINTERS_SUPPLIES}) на Ваш выбор.`,
    source: {
      id: SOURCE_XGTE,
      page: 43,
    },
  },
  {
    id: FEATURE_ONE_WITH_THE_BLADE,
    name: `Единство с клинком`,
    nameEn: `One with the Blade`,
    lvl: 6,
    text: `Вы распространяете Вашу энергию ци на своё оружие, получая следующие бонусы:

# Магическое оружие кенсэя

Ваши атаки оружием кенсэя считаются магическими при определении преодоления сопротивления и иммунитета к немагическим атакам и урону.

# Ловкий удар

Когда Вы попадаете по цели оружием кенсэя, Вы можете потратить 1 очко ци, чтобы нанести цели дополнительный урон этим оружием, равный Вашей кости [Боевых искусств](FEATURE:${FEATURE_MARTIAL_ARTS}). Вы можете использовать это умение только один раз за каждый свой ход.`,
    source: {
      id: SOURCE_XGTE,
      page: 44,
    },
  },
  {
    id: FEATURE_SHARPEN_THE_BLADE,
    name: `Заостренный клинок`,
    nameEn: `Sharpen the Blade`,
    lvl: 11,
    text: `Вы получаете способность пропитывать Ваше оружие силой Ци.

Бонусным действием Вы можете потратить до 3 очков Ци для того, чтобы дать одному оружию кенсэя, которое Вы держите, бонус к броскам атаки и урона. Бонус равен количеству потраченных очков ци.

Бонус длится 1 минуту или до тех пор, пока Вы снова не используете эту способность. Эта способность не влияет на магическое оружие, у которого уже есть бонус к броскам атаки и урона.`,
    source: {
      id: SOURCE_XGTE,
      page: 44,
    },
  },
  {
    id: FEATURE_UNERRING_ACCURACY,
    name: `Безошибочная точность`,
    nameEn: `Unerring Accuracy`,
    lvl: 17,
    text: `Ваше мастерство владения оружием достигает таких высот, что Ваша точность становится просто невероятной.

Если Вы промахиваетесь атакой с использованием монашеского оружия в свой ход, то можете перебросить этот бросок. Вы можете использовать это умение только один раз за каждый свой ход.`,
    source: {
      id: SOURCE_XGTE,
      page: 44,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_THE_KENSEI,
  })
)
