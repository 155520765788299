const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Мумии',
    text: `Поднятые тёмными погребальными ритуалами мумии ковыляя выходят из мертвенно-неподвижного храма или гробницы. Пробуждённая от своего покоя мумия карает пришельцев нечестивым проклятьем.`,
    source: {
      id: SOURCE_MM,
      page: 215,
    },
  },
  {
    header: 'Забальзамированная ярость',
    text: `Длительные похоронные ритуалы, которыми сопровождается помещение мумии в гробницу, помогают сохранить её тело от разложения. В процессе бальзамирования органы свежеумершего существа вынимаются и помещаются в специальные сосуды, а тело обрабатывается консервирующими маслами, травами и обёртываниями. После подготовки тело обычно бинтуется льняными лентами.`,
    source: {
      id: SOURCE_MM,
      page: 215,
    },
  },
  {
    header: 'Воля тёмных богов',
    text: `Нежить из мумии получается, если подготовленное тело насыщает некромантской магией жрец бога смерти или другого тёмного божества. На льняные бинты мумии наносятся некромантские символы, а затем погребальный ритуал завершается воззванием к тьме. Подготовленная таким образом нежить пробуждается в ответ на выполнение заданных ритуалом условий. Обычно мумию заставляет подняться враждебное действие по отношению к её гробнице, сокровищам, землям или бывшим возлюбленным.`,
    source: {
      id: SOURCE_MM,
      page: 215,
    },
  },
  {
    header: 'Наказанные',
    text: `После смерти существо не может воспротивиться превращению в мумию. Некоторые из мумий при жизни были могущественными личностями, впавшими в немилость жреца или фараона, или теми, кто совершил преступление в виде предательства, адюльтера или убийства. В качестве наказания на них накладывается мощное проклятье вечного существования в виде нежити, а тело бальзамируется, мумифицируется и помещается в тайник. В иных случаях мумии, выполняющие роль стражей гробниц, могли быть созданы из рабов, убитых специально с этой целью.`,
    source: {
      id: SOURCE_MM,
      page: 215,
    },
  },
  {
    header: 'Созданные ритуалом',
    text: `Мумия подчиняется условиям и параметрам, заданным во время ритуала, создавшего её, и она хочет только покарать нарушителей. Ошеломляющий ужас, который предзнаменует нападение мумии, может парализовать жертву страхом. В последующие за прикосновением мумии дни тело жертвы начинает гнить изнутри, пока не останется только прах.`,
    source: {
      id: SOURCE_MM,
      page: 215,
    },
  },
  {
    header: 'Устранение проклятья мумии',
    text: `Редкой магией можно обратить вспять или развеять ритуал, создавший мумию, позволяя ей окончательно умереть. Обычно же мумия может быть отправлена обратно на вечный покой искуплением преступления, которое вызвало её создание. Священный идол может быть помещён обратно в нишу, украденное сокровище возвращено в гробницу, а храм очищен от осквернившего его кровопролития.

Более эфемерные или необратимые преступления, такие как раскрытие секрета, который хранила мумия, или убийство дорогого ей человека, нельзя исправить так легко. В таких случаях мумия может перебить всех ответственных, и даже это не утолит её жажду возмездия.`,
    source: {
      id: SOURCE_MM,
      page: 215,
    },
  },
  {
    header: 'Нежить-летописец',
    text: `Мумии умеют разговаривать, хоть и делают это крайне редко. Благодаря этому некоторые мумии могут служить хранителями древних знаний, и потомки тех, кто создал такую мумию, могут обращаться к ним за ответами. Индивидуумы, обладающие значительной силой, могут даже держать мумию у себя, чтобы консультироваться при необходимости.`,
    source: {
      id: SOURCE_MM,
      page: 215,
    },
  },
  {
    header: 'Природа нежити',
    text: `Мумии не нужен воздух, еда, питьё и сон.`,
    source: {
      id: SOURCE_MM,
      page: 215,
    },
  },
]
