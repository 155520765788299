const {CAT_AMMUNITION} = require('./../../../../../gearCategoryList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_AMMUNITION_PLUS_1_PLUS_2_OR_PLUS_3} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_AMMUNITION_PLUS_1_PLUS_2_OR_PLUS_3,
  name: `Боеприпасы, +1, +2 или +3`,
  nameEn: `Ammunition +1, +2 or +3`,
  type: MGC_TYPE_WEAPON,
  rarity: generateRarityList(list),
  gearCategory: CAT_AMMUNITION,
  description: `Вы получаете бонус к броскам атаки и урона, совершённым при использовании этих магических боеприпасов.

Размер бонуса определяется показателем редкости боеприпаса. Попав в цель, боеприпас перестаёт быть магическим.`,
  genderId: GENDER_MALE,
  isConsumable: true,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 152,
  },
}
