const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_CHA} = require('./../../../../../paramList')
const {SPELL_CALM_EMOTIONS} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_CALM_EMOTIONS,
  name: 'Умиротворение',
  nameEn: 'Calm Emotions',
  description: `Вы пытаетесь подавить сильные эмоции у группы существ. Все гуманоиды в сфере с радиусом 20 футов с центром в точке, выбранной в пределах дистанции, должны пройти испытание Харизмы; существо может добровольно провалить это испытание, если пожелает. Если существо проваливает испытание, выберите один из двух описанных ниже эффектов:

1. Вы можете подавить все эффекты, делающие цель очарованной или испуганной. Когда это заклинание оканчивается, все подавленные эффекты восстанавливаются, при условии, что за это время их действие не окончилось.
2. В качестве альтернативы, Вы можете сделать цель безразличной к выбранным Вами существам, к которым она относилась враждебно. Это безразличие оканчивается, если цель атакована или ей причинён вред заклинанием, или если она станет свидетелем того, как причиняют вред её друзьям. Когда заклинание оканчивается, существо становится вновь враждебным, если Мастер не решит по-другому.`,
  lvl: 2,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CHA,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 286,
    },
    {
      id: SOURCE_SRD,
      page: 123,
    },
  ],
}
