const {
  CREATURE_FUMING_SPIRIT,
  CREATURE_TRICKSY_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух игривой феи',
  nameEn: 'Tricksy Fey Spirit',
  id: CREATURE_TRICKSY_SPIRIT,
  parentId: CREATURE_FUMING_SPIRIT,
  featureList: [
    {
      name: 'Шаг игривой феи',
      description: `★СУЩЕСТВО★ бонусным действием магически телепортируется в видимое ★им★ в пределах 30 футов свободное пространство. После этого ★он★ может заполнить магической тьмой куб в пределах 5 футов со стороной 5 футов, длящейся до конца ★его★ следующего хода.`,
    },
  ],
}
