const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_COMMON,
  LANG_GIANT,
  LANG_ORC,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  GEAR_BATTLEAXE,
  GEAR_MORNINGSTAR,
} = require('./../../../../gearIdList')
const {
  CREATURE_ETTIN,
  CREATURE_DEMOGORGON,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_TYPE_GIANT} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PC_RACE_ORC} = require('./../../../../pcRaceIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Эттин',
  nameEn: 'Ettin',
  id: CREATURE_ETTIN,
  description: [
    `Эттины — сутулые двуглавые великаны с грубыми чертами [орков](PC_RACE:${PC_RACE_ORC}). Эттины никогда не моются, если могут избежать этого, поэтому их толстые шкуры обычно покрыты приличным слоем грязи и жира под вонючими мехами, в которые они одеваются. Длинные грязные патлы волос свисают на лица, а из полного кривых зубов и клыков рта смердит.`,
    {
      header: 'Раздвоение личности',
      text: `Две головы эттина представляют собой две запертые в одном гигантском теле личности. У каждой головы свой разум, характер, имя, свои предпочтения и вкусы. Привязанные друг к другу с рождения, они редко испытывают одиночество или уединённость. Этот постоянный контакт порождает неприязнь, поэтому эттин постоянно спорит и ругается сам с собой, а две головы постоянно бранят друг друга за малейшую оплошность.

Когда другие существа обращаются к эттину, они складывают оба имени каждой из голов, получая таким образом одно общее имя, относящееся ко всему существу в целом. Если одну голову эттина зовут Харгл, а вторую — Варгл, то всего эттина другие существа будут звать Харглваргл.`,
      source: {
        id: SOURCE_MM,
        page: 311,
      },
    },
    {
      header: 'Отшельники',
      text: `Конфликтующие сами с собой эттины нетерпимы и к другим эттинам, поскольку разговор между двумя эттинами почти всегда представляет собой ругающуюся толпу из четырёх воинственных голов. Из-за этого большинство эттинов живёт в одиночестве, терпя других только для размножения.

Две головы эттина всегда одного пола с телом. Среди эттинов доминирует женский пол, и именно они являются инициаторами брачных ритуалов. После нахождения подходящего логова, женщина охотится и завоёвывает мужчину, который кормит её и заботится о ней во время шестимесячной беременности. Как только рождается ребёнок, мужчина эттин освобождается от рабства. Когда ребёнок становится достаточно взрослым, чтобы охотиться для себя, мать посылает его подальше и покидает логово.`,
      source: {
        id: SOURCE_MM,
        page: 311,
      },
    },
    {
      header: 'Одна голова хорошо, а две — лучше',
      text: `Эттин может разрешить личностные разногласия и полностью посвятить себя задаче, когда сосредоточен на выгодной обоим головам цели или перед общей угрозой. Эттин воюет с оружием в каждой руке, что позволяет делать две атаки, направляемые соответствующей головой. Когда эттин спит, одна из его голов остаётся настороже, только так получая мгновения уединения, и высматривая любого, кто посмеет нарушить такое драгоценное одиночество.`,
      source: {
        id: SOURCE_MM,
        page: 311,
      },
    },
    {
      header: 'Связь с орками',
      text: `На древнем диалекте [Всеобщего](LANG:${LANG_COMMON}), слово _«эттин»_ означает _«уродливый великан»_. В [орочьих](PC_RACE:${PC_RACE_ORC}) легендах говорится, как однажды они наткнулись на храм [Демогоргона](CREATURE:${CREATURE_DEMOGORGON}), волшебство которого превратило их в гигантских пародий на двуглавого Князя Демонов. Доведённые до безумия, эти существа разбрелись по пустошам, и стали первыми эттинами.

Какой бы ни была правда о происхождении эттинов, [орки](PC_RACE:${PC_RACE_ORC}) считают их дальними родственниками, и орочьи племена нередко нанимают эттинов в качестве охранников, разведчиков и мародёров. Эттины недолюбливают [орочьи](PC_RACE:${PC_RACE_ORC}) порядки, но [орки](PC_RACE:${PC_RACE_ORC}) могут расположить их обещанием пищи и трофеев.`,
      source: {
        id: SOURCE_MM,
        page: 311,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 311,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 17,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
    LANG_ORC,
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Две головы',
      description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), а также испытания от глухоты, испуга, лишения сознания, ослепления, очарования и ошеломления.`,
    },
    {
      name: 'Неусыпный',
      description: `Когда одна голова ★СУЩЕСТВО★ спит, друга бодрствует.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Боевым топором_ и одну _Моргенштерном_.`,
    },
    {
      name: 'Боевой топор',
      // gearId: GEAR_BATTLEAXE, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Моргенштерн',
      // gearId: GEAR_MORNINGSTAR, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
