module.exports = [
  require('./maze'),
  require('./melfs_acid_arrow'),
  require('./minor_illusion'),
  require('./mislead'),
  require('./misty_step'),
  require('./moonbeam'),
  require('./mordenkainen_s_magnificent_mansion'),
  require('./mordenkainen_s_private_sanctum'),
]
