const {
  CREATURE_INCUBUS,
  CREATURE_SUCCUBUS,
} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')

module.exports = {
  name: 'Инкуб',
  nameEn: 'Incubus',
  id: CREATURE_INCUBUS,
  parentId: CREATURE_SUCCUBUS,
  genderId: GENDER_MALE,
}
