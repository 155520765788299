const listToCollectionById = require('./../utils/listToCollectionById')

const SOURCE_ADND2_AGAP = 'ADND2_AGAP'
const SOURCE_ADND2_EE = 'ADND2_EE'
const SOURCE_ADND2_FoEtF = 'ADND2_FoEtF'
const SOURCE_ADND2_MCSA = 'ADND2_MCSA'
const SOURCE_ADND2_MM = 'ADND2_MM'
const SOURCE_ADND2_SJCS = 'ADND2_SJCS'
const SOURCE_ADND2_TCDH = 'ADND2_TCDH'
const SOURCE_ADND_MM = 'ADND_MM'
const SOURCE_ADND_PHB = 'PHB_ADND'
const SOURCE_AI = 'AI'
const SOURCE_AYAGMnC = 'AYAGMnC'
const SOURCE_AYAGWnW = 'AYAGWnW'
const SOURCE_BASIC_RULES = 'BASIC_RULES'
const SOURCE_BGDIA = 'BGDIA'
const SOURCE_BOOK_ABBEY_TNS = 'book_abbey_tns'
const SOURCE_BOOK_GREENWOOD_DOTD = 'book_greenwood_dotd'
const SOURCE_BOOK_GREENWOOD_EIMD = 'book_greenwood_eimd'
const SOURCE_BOOK_LARIAN_AFBG3 = 'book_larian_afbg3'
const SOURCE_BOOK_LOWDER_PoL = 'book_lowder_pol'
const SOURCE_BOOK_SALVATORE_EXILE = 'book_salvatore_exile'
const SOURCE_BOOK_SALVATORE_POTWK = 'book_salvatore_potwk'
const SOURCE_BOOK_SALVATORE_SN = 'book_salvatore_sn'
const SOURCE_CM = 'CM'
const SOURCE_CoS = 'CoS'
const SOURCE_CRCotN = 'CRCotN'
const SOURCE_DDHC_MORD_01 = 'DDHC_MORD_01'
const SOURCE_DMG = 'DMG'
const SOURCE_DND1_CotR = 'CotR_1ED'
const SOURCE_DND3_5_DTBoD = 'DND3_5_DTBoD'
const SOURCE_DND3_5_DoF = 'DND3_5_DoF'
const SOURCE_DND3_5_MotP = 'DND3_5_MotP'
const SOURCE_DND3_5_PGTD = 'DND3_5_PGtD'
const SOURCE_DND3_5_PGTM = 'DND3_5_PGtM'
const SOURCE_DND3_5_PGTW = 'DND3_5_PGtW'
const SOURCE_DND3_5_PHB = 'DND3_5_PHB'
const SOURCE_DND3_5_RoS = 'DND3_5_RoS'
const SOURCE_DND3_5_SW = 'DND3_5_SW'
const SOURCE_DND3_DLCS = 'DND3_DLCS'
const SOURCE_DND3_FRCS = 'DND3_FRCS'
const SOURCE_DND3_FnP = 'DND3_FnP'
const SOURCE_DND3_PGtF = 'DND3_PGtF'
const SOURCE_DND3_UE = 'DND3_UE'
const SOURCE_DND4_DCD = 'DND4_DCD'
const SOURCE_DND4_DMG = 'DND4_DMG'
const SOURCE_DND4_FRPG = 'DND4_FRPG'
const SOURCE_DND4_MotP = 'DND4_MotP'
const SOURCE_DND4_NCS = 'NCS_4ED'
const SOURCE_DSotDQ = 'DSotDQ'
const SOURCE_DnD_SADS_CLE = 'DnD_SADS_CLE'
const SOURCE_DoD = 'DoD'
const SOURCE_DoMT = 'DoMT'
const SOURCE_EE = 'EE'
const SOURCE_EGTW = 'EGTW'
const SOURCE_ERFTLW = 'ERftLW'
const SOURCE_ERRATA_MONSTER_MANUAL = 'errata_monster_manual'
const SOURCE_ERRATA_PHB = 'errata_phb'
const SOURCE_ERRATA_VGTM = 'errata_vgtm'
const SOURCE_ERRATA_XGTE = 'errata_xgte'
const SOURCE_FTD = 'FTD'
const SOURCE_GAME_BG_3 = 'game_bg3'
const SOURCE_GAME_ICEWIND_DALE_2 = 'game_icewind_dale_2'
const SOURCE_GGTR = 'GGTR'
const SOURCE_GOS = 'GOS'
const SOURCE_GOTG = 'GOTG'
const SOURCE_GotSF = 'GotSF'
const SOURCE_HOTDQ = 'HOTDQ'
const SOURCE_IDRotF = 'IDRotF'
const SOURCE_JTtRC = 'JTtRC'
const SOURCE_KGV = 'KGVP13'
const SOURCE_LOR_DTOB = 'LOR_DTOB'
const SOURCE_LR = 'LR'
const SOURCE_MBJV = 'MBJV'
const SOURCE_MCV_1_SC = 'MCV_1_SC'
const SOURCE_MCV_2_DC = 'MCV_2_DC'
const SOURCE_MCV_3_MC = 'MCV_3_MC'
const SOURCE_MCV_4_EC = 'MCV_4_EC'
const SOURCE_MFFV_1_MMB = 'MFFV_1_MMB'
const SOURCE_MGZN_DNGN_100 = 'MGZN_DNGN_100'
const SOURCE_MGZN_DRGN_281 = 'MGZN_DRGN_281'
const SOURCE_MGZN_DRGN_378 = 'MGZN_DRGN_378'
const SOURCE_MGZN_DRGN_ANNUAL_1999 = 'MGZN_DRGN_ANNUAL_1999'
const SOURCE_MGZN_PLHDRN_159 = 'MGZN_PLHDRN_159'
const SOURCE_MM = 'MM'
const SOURCE_MOOT = 'MOoT'
const SOURCE_MPMotM = 'MPMotM'
const SOURCE_MTG_AFR = 'MTG_AFR'
const SOURCE_MTG_AHBG = 'MTG_AHBG'
const SOURCE_MTG_CLB = 'MTG_CLB'
const SOURCE_MTG_M20 = 'MTG_M20'
const SOURCE_MTG_THS = 'MTG_THS'
const SOURCE_MTOF = 'MToF'
const SOURCE_OGA = 'OGA'
const SOURCE_OotA = 'OotA'
const SOURCE_PAITM = 'PAITM'
const SOURCE_PHB = 'PHB'
const SOURCE_PaBtSO = 'PaBtSO'
const SOURCE_PotA = 'PotA'
const SOURCE_ROT = 'ROT'
const SOURCE_SaCoC = 'SaCoC'
const SOURCE_SAIS = 'SAiS'
const SOURCE_SCAG = 'SCAG'
const SOURCE_SJA = 'SJA'
const SOURCE_SKT = 'SKT'
const SOURCE_SRD = 'SRD'
const SOURCE_SS = 'SS'
const SOURCE_TBOMT = 'TBOMT'
const SOURCE_TCoE = 'TCoE'
const SOURCE_TOA = 'ToA'
const SOURCE_TTP = 'TTP'
const SOURCE_TYP = 'TYP'
const SOURCE_TWBTW = 'TWBTW'
const SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK = 'UA_2015_11_02_LIGHT_DARK_UNDERDARK'
const SOURCE_UA_2016_01_04_KITS_OF_OLD = 'UA_2016_01_04_KITS_OF_OLD'
const SOURCE_UA_2016_04_04_GOTHIC_HEROES = 'UA_2016_04_04_GOTHIC_HEROES'
const SOURCE_UA_2016_06_06_FEATS = 'UA_2016_06_06_FEATS'
const SOURCE_UA_2016_08_01_THE_FAITHFUL = 'UA_2016_08_01_THE_FAITHFUL'
const SOURCE_UA_2016_09_12_THE_RANGER_REVISED = 'UA_2016_09_12_THE_RANGER_REVISED'
const SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES = 'UA_2016_11_14_BARD_BARD_COLLEGES'
const SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS = 'UA_2016_11_21_CLERIC_DIVINE_DOMAINS'
const SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE = 'UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE'
const SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES = 'UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES'
const SOURCE_UA_2016_12_12_MONK_MONASTIC_TRADITIONS = 'UA_2016_12_12_MONK_MONASTIC_TRADITIONS'
const SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS = 'UA_2016_12_19_PALADIN_SACRED_OATHS'
const SOURCE_UA_2017_01_09_ARTIFICER = 'UA_2017_01_09_ARTIFICER'
const SOURCE_UA_2017_01_16_RANGER_ROGUE = 'UA_2017_01_16_RANGER_ROGUE'
const SOURCE_UA_2017_02_06_SORCERER = 'UA_2017_02_06_SORCERER'
const SOURCE_UA_2017_02_13_WARLOCK_WIZARD = 'UA_2017_02_13_WARLOCK_WIZARD'
const SOURCE_UA_2017_02_21_MASS_COMBAT = 'UA_2017_02_21_MASS_COMBAT'
const SOURCE_UA_2017_02_27_TRAPS_REVISITED = 'UA_2017_02_27_TRAPS_REVISITED'
const SOURCE_UA_2017_03_13_THE_MYSTIC_CLASS = 'UA_2017_03_13_THE_MYSTIC_CLASS'
const SOURCE_UA_2017_03_20_WIZARD_REVISITED = 'UA_2017_03_20_WIZARD_REVISITED'
const SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES = 'UA_2017_03_27_A_TRIO_OF_SUBCLASSES'
const SOURCE_UA_2017_04_03_STARTER_SPELLS = 'UA_2017_04_03_STARTER_SPELLS'
const SOURCE_UA_2017_04_10_DOWNTIME = 'UA_2017_04_10_DOWNTIME'
const SOURCE_UA_2017_04_17_FFS = 'UA_2017_04_17_FFS'
const SOURCE_UA_2017_04_24_FFR = 'UA_2017_04_24_FFR'
const SOURCE_UA_2017_05_01_REVISED_SUBCLASSES = 'UA_2017_05_01_REVISED_SUBCLASSES'
const SOURCE_UA_2017_05_25_UNEARTHED_ARCANA_UPDATE = 'UA_2017_05_25_UNEARTHED_ARCANA_UPDATE'
const SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS = 'UA_2017_06_05_REVISED_CLASS_OPTIONS'
const SOURCE_UA_2017_07_10_GREYHAWK_INITIATIVE = 'UA_2017_07_10_GREYHAWK_INITIATIVE'
const SOURCE_UA_2017_08_07_THREE_PILLAR_EXPERIENCE = 'UA_2017_08_07_THREE_PILLAR_EXPERIENCE'
const SOURCE_UA_2017_09_11_RACE_OPTIONS_ELADRIN_AND_GITH = 'UA_2017_09_11_RACE_OPTIONS_ELADRIN_AND_GITH'
const SOURCE_UA_2017_10_09_FIENDISH_OPTIONS = 'UA_2017_10_09_FIENDISH_OPTIONS'
const SOURCE_UA_2017_11_13_ELF_SUBRACES = 'UA_2017_11_13_ELF_SUBRACES'
const SOURCE_UA_2018_01_08_3_SUBCLASSES_0108 = 'UA_2018_01_08_3_SUBCLASSES_0108'
const SOURCE_UA_2018_02_12_INTO_THE_WILD = 'UA_2018_02_12_INTO_THE_WILD'
const SOURCE_UA_2018_04_09_ORDER_DOMAIN = 'UA_2018_04_09_ORDER_DOMAIN'
const SOURCE_UA_2018_05_14_CENTAURS_AND_MINOTAURS = 'UA_2018_05_14_CENTAURS_AND_MINOTAURS'
const SOURCE_UA_2018_06_11_GIANT_SOUL = 'UA_2018_06_11_GIANT_SOUL'
const SOURCE_UA_2018_07_23_RACES_OF_EBERRON = 'UA_2018_07_23_RACES_OF_EBERRON'
const SOURCE_UA_2018_08_13_RACES_OF_RAVNICA = 'UA_2018_08_13_RACES_OF_RAVNICA'
const SOURCE_UA_2018_09_10_DRAGONMARKS = 'UA_2018_09_10_DRAGONMARKS'
const SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON = 'UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON'
const SOURCE_UA_2018_11_12_OF_SHIPS_AND_THE_SEA = 'UA_2018_11_12_OF_SHIPS_AND_THE_SEA'
const SOURCE_UA_2018_12_17_SIDEKICKS = 'UA_2018_12_17_SIDEKICKS'
const SOURCE_UA_2019_02_28_ARTIFICER_REVISITED = 'UA_2019_02_28_ARTIFICER_REVISITED'
const SOURCE_UA_2019_05_14_ARTIFICER_RETURNS = 'UA_2019_05_14_ARTIFICER_RETURNS'
const SOURCE_UA_2019_08_15_WILD_ASTRAL = 'UA_2019_08_15_WILD_ASTRAL'
const SOURCE_UA_2019_09_05_ABERRANT_LURK = 'UA_2019_09_05_ABERRANT_LURK'
const SOURCE_UA_2019_09_18_ELOQUENT_HEROICS = 'UA_2019_09_18_ELOQUENT_HEROICS'
const SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES = 'UA_2019_10_03_TWILIGHT_FIRE_NAMES'
const SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED = 'UA_2019_10_17_RUNE_SWARM_REVIVED'
const SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS = 'UA_2019_11_04_CLASS_FEATURE_VARIANTS'
const SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS = 'UA_2019_11_25_PSYCHIC_SOUL_PSIONICS'
const SOURCE_UA_2020_01_14_SUBCLASSES_PART_1 = 'UA_2020_01_14_SUBCLASSES_PART_1'
const SOURCE_UA_2020_02_06_SUBCLASSES_PART_2 = 'UA_2020_02_06_SUBCLASSES_PART_2'
const SOURCE_UA_2020_02_24_SUBCLASSES_PART_3 = 'UA_2020_02_24_SUBCLASSES_PART_3'
const SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS  = 'UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS'
const SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED  = 'UA_2020_04_14_PSIONIC_OPTIONS_REVISITED'
const SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED  = 'UA_2020_05_12_SUBCLASSES_REVISITED'
const SOURCE_UA_2020_07_13_FEATS  = 'UA_2020_07_13_FEATS'
const SOURCE_UA_2020_08_05_SUBCLASSES_PART_4  = 'UA_2020_08_05_SUBCLASSES_PART_4'
const SOURCE_UA_2020_09_15_TCoE_PC_BPoWM = 'TCoE_PC_BPoWM'
const SOURCE_UA_2020_09_15_TCoE_PC_WtG = 'TCoE_PC_WtG'
const SOURCE_UA_2020_10_26_SUBCLASSES_PART_5  = 'UA_2020_10_26_SUBCLASSES_PART_5'
const SOURCE_UA_2021_01_26_GOTHIC_LINEAGES = 'UA_2021_01_26_GOTHIC_LINEAGES'
const SOURCE_UA_2021_03_11_FOLK_OF_THE_FEYWILD = 'UA_2021_03_11_FOLK_OF_THE_FEYWILD'
const SOURCE_UA_2021_04_14_DRACONIC_OPTIONS = 'UA_2021_04_14_DRACONIC_OPTIONS'
const SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN = 'UA_2021_06_08_MAGES_OF_STRIXHAVEN'
const SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE = 'UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE'
const SOURCE_UA_2022_03_08_HEROES_OF_KRYNN = 'UA_2022_03_08_HEROES_OF_KRYNN'
const SOURCE_UA_2022_04_25_HEROES_OF_KRYNN_REVISITED = 'UA_2022_04_25_HEROES_OF_KRYNN_REVISITED'
const SOURCE_UA_2022_05_22_GIANT_OPTIONS = 'UA_2022_05_22_GIANT_OPTIONS'
const SOURCE_UA_2022_07_18_WotM = 'UA_2022_07_18_WotM'
const SOURCE_UA_2022_08_18_ODnD_CO = 'UA_2022_08_18_ODnD_CO'
const SOURCE_VGTM = 'VGtM'
const SOURCE_VRGtR = 'VRGtR'
const SOURCE_WDH = 'WDH'
const SOURCE_WDMM = 'WDMM'
const SOURCE_WGtE = 'WGtE'
const SOURCE_XGTE = 'XGtE'

const sourceList = [
  // Старые редакции
  {
    id: SOURCE_DND1_CotR,
    name: 'Энциклопедия Королевств (D&D 1)',
    nameEn: 'Cyclopedia of the Realms (D&D 1)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 1; ЭК',
  },
  {
    id: SOURCE_ADND_MM,
    name: 'Чудовищный справочник (AD&D)',
    nameEn: 'Monstrous Manual (AD&D)',
    shortName: 'AD&D; ЧС',
  },
  {
    id: SOURCE_ADND_PHB,
    name: 'Книга Игрока (AD&D)',
    nameEn: 'AD&D 1e Players Handbook',
    shortName: 'AD&D; КИ',
  },
  {
    id: SOURCE_ADND2_AGAP,
    name: 'Справочник по Астральному плану (AD&D 2)',
    nameEn: 'A Guide to the Astral Plane (AD&D 2)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 2; СпАП',
  },
  {
    id: SOURCE_ADND2_EE,
    name: 'Экология Эльминстера (AD&D 2)',
    nameEn: 'Elminster’s Ecologies (AD&D 2)',
    translator: 'DM-Stuff.ru',
    shortName: 'AD&D 2; ЭЭ',
  },
  {
    id: SOURCE_ADND2_FoEtF,
    name: 'Лики зла: Исчадия (AD&D 2)',
    nameEn: 'Faces of Evil: The Fiends (AD&D 2)',
    translator: 'DM-Stuff.ru',
    shortName: 'AD&D 2; ЛЗИ',
  },
  {
    id: SOURCE_ADND2_MCSA,
    name: 'Чудовищный справочник монстров Спеллджаммера, приложение (AD&D 2)',
    nameEn: 'Monstrous Compendium Spelljammer Appendix (AD&D 2)',
    translator: 'DM-Stuff.ru',
    shortName: 'AD&D 2; ЧCМС,П',
  },
  {
    id: SOURCE_ADND2_MM,
    name: 'Чудовищный справочник (AD&D 2)',
    nameEn: 'Monstrous Manual (AD&D 2)',
    shortName: 'AD&D 2; ЧС',
  },
  {
    id: SOURCE_ADND2_SJCS,
    name: 'Сеттинг кампаний по Спеллджаммеру (AD&D 2)',
    nameEn: 'Spelljammer Campaign Setting (AD&D 2)',
    translator: 'DM-Stuff.ru',
    shortName: 'AD&D 2; СКпС',
  },
  {
    id: SOURCE_ADND2_TCDH,
    name: 'Полное Руководство Друида (AD&D 2)',
    nameEn: 'The Complete Druid’s Handbook (AD&D 2)',
    translator: 'Sir Valistan',
    shortName: 'AD&D 2; ПРД',
  },
  {
    id: SOURCE_DND3_FnP,
    name: 'Веры и пантеоны (D&D 3)',
    nameEn: 'Faiths and Pantheons (D&D 3)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 3; ВиП',
  },
  {
    id: SOURCE_DND3_FRCS,
    name: 'Сеттинг кампаний по Забытым Королевствам (D&D 3)',
    nameEn: 'Forgotten Realms Campaign Setting (D&D 3)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 3; СКпЗК',
  },
  {
    id: SOURCE_DND3_DLCS,
    name: 'Сеттинг кампаний по Саге о Копье (D&D 3)',
    nameEn: 'Dragonlance Campaign Setting (D&D 3)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 3; СКпСоК',
  },
  {
    id: SOURCE_DND3_PGtF,
    name: 'Справочник игрока по Фаэруну (D&D 3)',
    nameEn: 'Player’s Guide to Faerûn (D&D 3)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 3; СИпФ',
  },
  {
    id: SOURCE_DND3_UE,
    name: 'Недостижимый восток (D&D 3)',
    nameEn: 'Unapproachable East (D&D 3)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 3; НВ',
  },
  {
    id: SOURCE_DND3_5_DoF,
    name: 'Драконы Фаэруна (D&D 3.5)',
    nameEn: 'Dragons of Faerûn (D&D 3.5)',
    translator: 'LE_Ranger',
    shortName: 'D&D 3.5; ДФ',
  },
  {
    id: SOURCE_DND3_5_DTBoD,
    name: 'Дракономикон, Книга драконов (D&D 3.5)',
    nameEn: 'Draconomicon, The Book of Dragons (D&D 3.5)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 3.5; Д,КД',
  },
  {
    id: SOURCE_DND3_5_MotP,
    name: 'Руководство по Планам (D&D 3.5)',
    nameEn: 'Manual of the Planes (D&D 3.5)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 3.5; РпП',
  },
  {
    id: SOURCE_DND3_5_PGTD,
    name: 'Практическое руководство по драконам (D&D 3.5)',
    nameEn: 'A Practical Guide to Dragons (D&D 3.5)',
    shortName: 'D&D 3.5; ПРпД',
  },
  {
    id: SOURCE_DND3_5_PGTM,
    name: 'Практическое руководство по чудовищам (D&D 3.5)',
    nameEn: 'A Practical Guide to Monsters (D&D 3.5)',
    shortName: 'D&D 3.5; ПРпЧ',
  },
  {
    id: SOURCE_DND3_5_PGTW,
    name: 'Практическое руководство по волшебству (D&D 3.5)',
    nameEn: 'A Practical Guide to Wizardry (D&D 3.5)',
    shortName: 'D&D 3.5; ПРпВ',
  },
  {
    id: SOURCE_DND3_5_PHB,
    name: 'Книга игрока (D&D 3.5)',
    nameEn: 'Player’s Handbook (D&D 3.5)',
    translator: 'Студия Phantom',
    shortName: 'D&D 3.5; КИ',
  },
  {
    id: SOURCE_DND3_5_RoS,
    name: 'Расы камня (D&D 3.5)',
    nameEn: 'Races of Stone (D&D 3.5)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 3.5; РК',
  },
  {
    id: SOURCE_DND3_5_SW,
    name: 'Обломки кораблекрушений (D&D 3.5)',
    nameEn: 'Stormwrack (D&D 3.5)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 3.5; ОК',
  },
  {
    id: SOURCE_DND4_DMG,
    name: 'Руководство Мастера (D&D 4)',
    nameEn: 'Dungeon Master’s Guide (D&D 4)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 4; РМ',
  },
  {
    id: SOURCE_DND4_DCD,
    name: 'Дракономикон: Цветные драконы (D&D 4)',
    nameEn: 'Draconomicon – Chromatic Dragons (D&D 4)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 4; Д:ЦД',
  },
  {
    id: SOURCE_DND4_FRPG,
    name: 'Справочник игрока по Забытым Королевствам (D&D 4)',
    nameEn: 'Forgotten Realms Player’s Guide (D&D 4)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 4; СИпЗК',
  },
  {
    id: SOURCE_DND4_MotP,
    name: 'Руководство по Планам (D&D 4)',
    nameEn: 'Manual of the Planes (D&D 4)',
    translator: 'DM-Stuff.ru',
    shortName: 'D&D 4; РпП',
  },
  {
    id: SOURCE_DND4_NCS,
    name: 'Руководство по кампании в Невервинтере (D&D 4)',
    nameEn: 'Neverwinter Campaign Setting (D&D 4)',
    translator: 'Shadowdale.ru в сотрудничестве со студией Phantom',
    shortName: 'D&D 4; РпКвН',
  },

  // Журнал «Многогранник»
  {
    id: SOURCE_MGZN_PLHDRN_159,
    name: 'Журнал «Многогранник», выпуск 159',
    nameEn: 'Polyhedron Magazine 159',
    translator: 'DM-Stuff.ru',
    shortName: 'ЖМ:159',
  },

  // Журнал «Подземелье»
  {
    id: SOURCE_MGZN_DNGN_100,
    name: 'Журнал «Подземелье», выпуск 100',
    nameEn: 'Dungeon Magazine 100',
    translator: 'DM-Stuff.ru',
    shortName: 'ЖП:100',
  },

  // Драконий журнал
  {
    id: SOURCE_MGZN_DRGN_281,
    name: 'Журнал «Дракон», выпуск 281',
    nameEn: 'Dragon Magazine 281',
    translator: 'DM-Stuff.ru',
    shortName: 'ЖД:281',
  },
  {
    id: SOURCE_MGZN_DRGN_378,
    name: 'Журнал «Дракон», выпуск 378',
    nameEn: 'Dragon Magazine 378',
    translator: 'DM-Stuff.ru',
    shortName: 'ЖД:378',
  },
  {
    id: SOURCE_MGZN_DRGN_ANNUAL_1999,
    name: 'Журнал «Дракон», ежегодник 1999',
    nameEn: 'Dragon Magazine 1999 Annual',
    translator: 'DM-Stuff.ru',
    shortName: 'ЖДЕ:1999',
  },

  // Игры
  {
    id: SOURCE_GAME_ICEWIND_DALE_2,
    name: 'Игра «Долина ледяного ветра 2»',
    nameEn: 'Game „Icewind Dale 2“',
    translator: '1С',
    shortName: 'И:ДЛВ2',
  },
  {
    id: SOURCE_GAME_BG_3,
    name: 'Игра «Врата Балдура 3»',
    nameEn: 'Game „Baldur’s Gate 3“',
    translator: 'Larian Studios',
    shortName: 'И:ВБ3',
  },

  // Книги
  {
    id: SOURCE_BOOK_ABBEY_TNS,
    name: 'Линн Эбби, «Нетерильский свиток»',
    nameEn: 'Lynn Abbey, The Nether Scroll',
    translator: 'DM-Stuff.ru',
    shortName: 'ЛЭ:«НС»',
  },
  {
    id: SOURCE_BOOK_SALVATORE_EXILE,
    name: 'Роберт Сальваторе, «Изгнанник»',
    nameEn: 'Robert Salvatore “Exile”',
    translator: 'ООО «Золотой век», ТОО «Диамант», 1997',
    shortName: 'РС:«И»',
  },
  {
    id: SOURCE_BOOK_SALVATORE_POTWK,
    name: 'Роберт Сальваторе, «Обещание короля-чародея»',
    nameEn: 'Robert Salvatore “Promise of the Witch King”',
    translator: 'DM-Stuff.ru',
    shortName: 'РС:«ОКЧ»',
  },
  {
    id: SOURCE_BOOK_SALVATORE_SN,
    name: 'Роберт Сальваторе, «Беззвёздная ночь»',
    nameEn: 'Robert Salvatore “Starless Night”',
    shortName: 'РС:«БН»',
  },
  {
    id: SOURCE_BOOK_GREENWOOD_DOTD,
    name: 'Эд Гринвуд, «Смерть дракона»',
    nameEn: 'Ed Greenwood, Death of the Dragon',
    translator: 'DM-Stuff.ru',
    shortName: 'ЭГ:«СД»',
  },
  {
    id: SOURCE_BOOK_GREENWOOD_EIMD,
    name: 'Эд Гринвуд, «Эльминстер в Миф Дранноре»',
    nameEn: 'Ed Greenwood, Elminster in Myth Drannor',
    translator: 'Н. Баркова',
    shortName: 'ЭГ:«ЭвМД»',
  },
  {
    id: SOURCE_BOOK_LARIAN_AFBG3,
    name: 'Студия Лариан: Иллюстрации из «Врат Балдура 3»',
    date: '2023-08-03',
    nameEn: 'Larian Studios: Artwork from Baldur’s Gate III',
    translator: 'DM-Stuff.ru',
    shortName: 'СЛ:«ИиВБ3»',
  },
  {
    id: SOURCE_BOOK_LOWDER_PoL,
    name: 'Джеймс Лоудер, «Принц лжи»',
    date: '1993',
    nameEn: 'James Lowder, Prince of Lies',
    translator: 'Е. Коротнян',
    shortName: 'ДЛ:«ПЛ»',
  },

  // Тестовые материалы
  {
    id: SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK,
    date: '2015-11-02',
    isUA: true,
    name: 'Раскопанные тайны: Свет, тьма, Подземье!',
    nameEn: 'Unearthed Arcana: Light, Dark, Underdark!',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/light-dark-underdark`,
    shortName: 'РТ:СТП',
  },
  {
    id: SOURCE_UA_2016_01_04_KITS_OF_OLD,
    date: '2016-01-04',
    isUA: true,
    name: 'Раскопанные тайны: Старые наборы',
    nameEn: 'Unearthed Arcana: Kits of Old',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/kits-old`,
    shortName: 'РТ:СН',
  },
  {
    id: SOURCE_UA_2016_04_04_GOTHIC_HEROES ,
    date: '2016-04-04' ,
    isUA: true,
    name: 'Раскопанные тайны: Готические герои',
    nameEn: 'Unearthed Arcana: Gothic Heroes',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/features/gothic-heroes`,
    shortName: 'РТ:ГГ',
  },
  {
    id: SOURCE_UA_2016_06_06_FEATS,
    date: '2016-06-06',
    isUA: true,
    name: 'Раскопанные тайны: Черты',
    nameEn: 'Unearthed Arcana: Feats',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/features/feats`,
    shortName: 'РТ:Ч',
  },
  {
    id: SOURCE_UA_2016_08_01_THE_FAITHFUL ,
    date: '2016-08-01' ,
    isUA: true,
    name: 'Раскопанные тайны: Верный',
    nameEn: 'Unearthed Arcana: The Faithful',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/features/faithful`,
    shortName: 'РТ:В',
  },
  {
    id: SOURCE_UA_2016_09_12_THE_RANGER_REVISED,
    date: '2016-09-12',
    isUA: true,
    name: 'Раскопанные тайны: Переосмысление следопыта',
    nameEn: 'Unearthed Arcana: The Ranger, Revised',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/features/unearthed-arcana-ranger-revised`,
    shortName: 'РТ:ПС',
  },
  {
    id: SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES ,
    date: '2016-11-14' ,
    isUA: true,
    name: 'Раскопанные тайны: Бард — коллегии бардов',
    nameEn: 'Unearthed Arcana: Bard: Bard Colleges',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/bard-colleges`,
    shortName: 'РТ:БКБ',
  },
  {
    id: SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS,
    date: '2016-11-21',
    isUA: true,
    name: 'Раскопанные тайны: Жрец — божественные домены',
    nameEn: 'Unearthed Arcana: Cleric: Divine Domains',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/cleric-divine-domains`,
    shortName: 'РТ:ЖБД',
  },
  {
    id: SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE,
    date: '2016-11-28',
    isUA: true,
    name: 'Раскопанные тайны: Круги друидов и их дикие облики',
    nameEn: 'Unearthed Arcana: Druid Circles and Wild Shape',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/druid-circles-and-wild-shape`,
    shortName: 'РТ:КДиИДО',
  },
  {
    id: SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES,
    date: '2016-12-05',
    isUA: true,
    name: 'Раскопанные тайны: Воин — воинские архетипы',
    nameEn: 'Unearthed Arcana: Fighter: Martial Archetypes',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/fighter`,
    shortName: 'РТ:ВВА',
  },
  {
    id: SOURCE_UA_2016_12_12_MONK_MONASTIC_TRADITIONS,
    date: '2016-12-12',
    isUA: true,
    name: 'Раскопанные тайны: Монах — монастырские традиции',
    nameEn: 'Unearthed Arcana: Monk: Monastic Traditions',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/monk-monastic-traditions`,
    shortName: 'РТ:ММТ',
  },
  {
    id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
    date: '2016-12-19',
    isUA: true,
    name: 'Раскопанные тайны: Паладин — священные клятвы',
    nameEn: 'Unearthed Arcana: Paladin: Sacred Oaths',
    translator: 'DM-Stuff.ru',
    url: `https://media.wizards.com/2016/dnd/downloads/UAPaladin_SO_20161219_1.pdf`,
    shortName: 'РТ:ПСК',
  },
  {
    id: SOURCE_UA_2017_01_09_ARTIFICER,
    date: '2017-01-09',
    isUA: true,
    name: 'Раскопанные тайны: Изобретатель',
    nameEn: 'Unearthed Arcana: Artificer',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/artificer`,
    shortName: 'РТ:И',
  },
  {
    id: SOURCE_UA_2017_01_16_RANGER_ROGUE,
    date: '2017-01-16',
    isUA: true,
    name: 'Раскопанные тайны: Следопыт и Плут',
    nameEn: 'Unearthed Arcana: Ranger & Rogue',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/ranger-and-rogue`,
    shortName: 'РТ:СиП',
  },
  {
    id: SOURCE_UA_2017_02_06_SORCERER,
    date: '2017-02-06',
    isUA: true,
    name: 'Раскопанные тайны: Чародей',
    nameEn: 'Unearthed Arcana: Sorcerer',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/sorcerer`,
    shortName: 'РТ:Ч',
  },
  {
    id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
    date: '2017-02-13',
    isUA: true,
    name: 'Раскопанные тайны: Колдун и Волшебник',
    nameEn: 'Unearthed Arcana: Warlock & Wizard',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/warlock-and-wizard`,
    shortName: 'РТ:КиВ',
  },
  {
    id: SOURCE_UA_2017_02_21_MASS_COMBAT,
    date: '2017-02-21',
    isUA: true,
    name: 'Раскопанные тайны: Массовые сражения',
    nameEn: 'Unearthed Arcana: Mass Combat',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/mass-combat`,
    shortName: 'РТ:МС',
  },
  {
    id: SOURCE_UA_2017_02_27_TRAPS_REVISITED,
    date: '2017-02-27',
    isUA: true,
    name: 'Раскопанные тайны: Пересмотр ловушек',
    nameEn: 'Unearthed Arcana: Traps Revisited',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/traps-revisited`,
    shortName: 'РТ:ПЛ',
  },
  {
    id: SOURCE_UA_2017_03_13_THE_MYSTIC_CLASS,
    date: '2017-03-13',
    isUA: true,
    name: 'Раскопанные тайны: Класс Мистика',
    nameEn: 'Unearthed Arcana: The Mystic Class',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/mystic-class`,
    shortName: 'РТ:КМ',
  },
  {
    id: SOURCE_UA_2017_03_20_WIZARD_REVISITED,
    date: '2017-03-20',
    isUA: true,
    name: 'Раскопанные тайны: Переосмысление Волшебника',
    nameEn: 'Unearthed Arcana: Wizard Revisited',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/wizard-revisited`,
    shortName: 'РТ:ПВ',
  },
  {
    id: SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES,
    date: '2017-03-27',
    isUA: true,
    name: 'Раскопанные тайны: Трио подклассов',
    nameEn: 'Unearthed Arcana: A Trio of Subclasses',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/trio-subclasses`,
    shortName: 'РТ:ТП',
  },
  {
    id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
    date: '2017-04-03',
    isUA: true,
    name: 'Раскопанные тайны: Низкоуровневые заклинания',
    nameEn: 'Unearthed Arcana: Starter Spells',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/starter-spells`,
    shortName: 'РТ:НЗ',
  },
  {
    id: SOURCE_UA_2017_04_10_DOWNTIME,
    date: '2017-04-10',
    isUA: true,
    name: 'Раскопанные тайны: Простой',
    nameEn: 'Unearthed Arcana: Downtime',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/downtime`,
    shortName: 'РТ:П',
  },
  {
    id: SOURCE_UA_2017_04_17_FFS,
    date: '2017-04-17',
    isUA: true,
    name: 'Раскопанные тайны: Черты по навыкам',
    nameEn: 'Unearthed Arcana: Feats for Skills',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/feats-skills`,
    shortName: 'РТ:ЧпН',
  },
  {
    id: SOURCE_UA_2017_04_24_FFR,
    date: '2017-04-24',
    isUA: true,
    name: 'Раскопанные тайны: Черты по расам',
    nameEn: 'Unearthed Arcana: Feats for Races',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/feats-races`,
    shortName: 'РТ:ЧпР',
  },
  {
    id: SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
    date: '2017-05-01',
    isUA: true,
    name: 'Раскопанные тайны: Пересмотр подклассов',
    nameEn: 'Unearthed Arcana: Revised Subclasses',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/revised-subclasses`,
    shortName: 'РТ:ПП',
  },
  {
    id: SOURCE_UA_2017_05_25_UNEARTHED_ARCANA_UPDATE,
    date: '2017-05-25',
    isUA: true,
    name: 'Раскопанные тайны: Обновление раскопанных тайн',
    nameEn: 'Unearthed Arcana: Unearthed Arcana Update',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/unearthed-arcana-update`,
    shortName: 'РТ:ОРТ',
  },
  {
    id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    date: '2017-06-05',
    isUA: true,
    name: 'Раскопанные тайны: Пересмотр классовых опций',
    nameEn: 'Unearthed Arcana: Revised Class Options',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/revised-class-options`,
    shortName: 'РТ:ПКВ',
  },
  {
    id: SOURCE_UA_2017_07_10_GREYHAWK_INITIATIVE,
    date: '2017-07-10',
    isUA: true,
    name: 'Раскопанные тайны: Инициатива по-сероястребски',
    nameEn: 'Unearthed Arcana: Greyhawk Initiative',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/race-options-eladrin-and-gith`,
    shortName: 'РТ:ИПС',
  },
  {
    id: SOURCE_UA_2017_08_07_THREE_PILLAR_EXPERIENCE,
    date: '2017-08-07',
    isUA: true,
    name: 'Раскопанные тайны: Три столпа',
    nameEn: 'Unearthed Arcana: Three-Pillar Experience',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/race-options-eladrin-and-gith`,
    shortName: 'РТ:ТС',
  },
  {
    id: SOURCE_UA_2017_09_11_RACE_OPTIONS_ELADRIN_AND_GITH,
    date: '2017-09-11',
    isUA: true,
    name: 'Раскопанные тайны: Расовые варианты: эладрины и гиты',
    nameEn: 'Unearthed Arcana: Race Options: Eladrin and Gith',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/race-options-eladrin-and-gith`,
    shortName: 'РТ:РВЭиГ',
  },
  {
    id: SOURCE_UA_2017_10_09_FIENDISH_OPTIONS,
    date: '2017-10-09',
    isUA: true,
    name: 'Раскопанные тайны: Варианты исчадий',
    nameEn: 'Unearthed Arcana: Fiendish Options',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/fiendish-options`,
    shortName: 'РТ:ВИ',
  },
  {
    id: SOURCE_UA_2017_11_13_ELF_SUBRACES,
    date: '2017-11-13',
    isUA: true,
    name: 'Раскопанные тайны: Эльфийские подрасы',
    nameEn: 'Unearthed Arcana: Elf Subraces',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/elf-subraces`,
    shortName: 'РТ:ЭП',
  },
  {
    id: SOURCE_UA_2018_01_08_3_SUBCLASSES_0108,
    date: '2018-01-08',
    isUA: true,
    name: 'Раскопанные тайны: Три подкласса',
    nameEn: 'Unearthed Arcana: Three Subclasses',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/three-subclasses`,
    shortName: 'РТ:3П',
  },
  {
    id: SOURCE_UA_2018_02_12_INTO_THE_WILD,
    date: '2018-02-12',
    isUA: true,
    name: 'Раскопанные тайны: В глушь',
    nameEn: 'Unearthed Arcana: Into the Wild',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/wild`,
    shortName: 'РТ:ВГ',
  },
  {
    id: SOURCE_UA_2018_04_09_ORDER_DOMAIN,
    date: '2018-04-09',
    isUA: true,
    name: 'Раскопанные тайны: Домен порядка',
    nameEn: 'Unearthed Arcana: Order Domain',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/order-domain`,
    shortName: 'РТ:ДП',
  },
  {
    id: SOURCE_UA_2018_05_14_CENTAURS_AND_MINOTAURS,
    date: '2018-05-14',
    isUA: true,
    name: 'Раскопанные тайны: Кентавры и минотавры',
    nameEn: 'Unearthed Arcana: Centaurs and Minotaurs',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/centaurs-and-minotaurs`,
    shortName: 'РТ:КиМ',
  },
  {
    id: SOURCE_UA_2018_06_11_GIANT_SOUL,
    date: '2018-06-11',
    isUA: true,
    name: 'Раскопанные тайны: Великанья душа',
    nameEn: 'Unearthed Arcana: Giant Soul',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/giant-soul-sorcerer`,
    shortName: 'РТ:ВД',
  },
  {
    id: SOURCE_UA_2018_07_23_RACES_OF_EBERRON,
    date: '2018-07-23',
    isUA: true,
    name: 'Раскопанные тайны: Расы Эберрона',
    nameEn: 'Unearthed Arcana: Races of Eberron',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/races-eberron`,
    shortName: 'РТ:РЭ',
  },
  {
    id: SOURCE_UA_2018_08_13_RACES_OF_RAVNICA,
    date: '2018-08-13',
    isUA: true,
    name: 'Раскопанные тайны: Расы Равники',
    nameEn: 'Unearthed Arcana: Races of Ravnica',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/races-ravnica`,
    shortName: 'РТ:РР',
  },
  {
    id: SOURCE_UA_2018_09_10_DRAGONMARKS,
    date: '2018-09-10',
    isUA: true,
    name: 'Раскопанные тайны: Драконьи метки',
    nameEn: 'Unearthed Arcana: Dragonmarks',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/dragonmarks`,
    shortName: 'РТ:ДМ',
  },
  {
    id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
    date: '2018-10-08',
    isUA: true,
    name: 'Раскопанные тайны: Магические предметы Эберрона',
    nameEn: 'Unearthed Arcana: Magic Items of Eberron',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/magic-items-eberron`,
    shortName: 'РТ:МПЭ',
  },
  {
    id: SOURCE_UA_2018_11_12_OF_SHIPS_AND_THE_SEA,
    date: '2018-11-12',
    isUA: true,
    name: 'Раскопанные тайны: О кораблях и море',
    nameEn: 'Unearthed Arcana: Of Ships and the Sea',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/ships-and-sea`,
    shortName: 'РТ:ОКиМ',
  },
  {
    id: SOURCE_UA_2018_12_17_SIDEKICKS,
    date: '2018-12-17',
    isUA: true,
    name: 'Раскопанные тайны: Приспешники',
    nameEn: 'Unearthed Arcana: Sidekicks',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/sidekicks`,
    shortName: 'РТ:П',
  },
  {
    id: SOURCE_UA_2019_02_28_ARTIFICER_REVISITED,
    date: '2019-02-28',
    isUA: true,
    name: 'Раскопанные тайны: Пересмотр Изобретателя',
    nameEn: 'Unearthed Arcana: The Artificer Revisited',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/artificer-revisited`,
    shortName: 'РТ:ПИ',
  },
  {
    id: SOURCE_UA_2019_05_14_ARTIFICER_RETURNS,
    date: '2019-05-14',
    isUA: true,
    name: 'Раскопанные тайны: Возвращение Изобретателя',
    nameEn: 'Unearthed Arcana: The Artificer Returns ',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/artificer-returns`,
    shortName: 'РТ:ВИ',
  },
  {
    id: SOURCE_UA_2019_08_15_WILD_ASTRAL,
    date: '2019-08-15',
    isUA: true,
    name: 'Раскопанные тайны: Варвар и Монах',
    nameEn: 'Unearthed Arcana: Barbarian and Monk',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/barbarian-and-monk`,
    shortName: 'РТ:ВиМ',
  },
  {
    id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
    date: '2019-09-05',
    isUA: true,
    name: 'Раскопанные тайны: Чародей и Колдун',
    nameEn: 'Unearthed Arcana: Sorcerer and Warlock',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/sorcerer-and-warlock`,
    shortName: 'РТ:ЧиК',
  },
  {
    id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
    date: '2019-09-18',
    isUA: true,
    name: 'Раскопанные тайны: Бард и Паладин',
    nameEn: 'Unearthed Arcana: Bard and Paladin',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/bard-paladin`,
    shortName: 'РТ:БиП',
  },
  {
    id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
    date: '2019-10-03',
    isUA: true,
    name: 'Раскопанные тайны: Жрец, Друид и Волшебник',
    nameEn: 'Unearthed Arcana: Cleric, Druid, and Wizard',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/cleric-druid-wizard`,
    shortName: 'РТ:ЖДиВ',
  },
  {
    id: SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED,
    date: '2019-10-17',
    isUA: true,
    name: 'Раскопанные тайны: Воин, Следопыт и Плут',
    nameEn: 'Unearthed Arcana: Fighter, Ranger, and Rogue',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/fighter-ranger-rogue`,
    shortName: 'РТ:ВСиП',
  },
  {
    id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
    date: '2019-11-04',
    isUA: true,
    name: 'Раскопанные тайны: Варианты классовых способностей',
    nameEn: 'Unearthed Arcana: Class Feature Variants',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/class-feature-variants`,
    shortName: 'РТ:ВКС',
  },
  {
    id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
    date: '2019-11-25',
    isUA: true,
    name: 'Раскопанные тайны: Воин, Плут и Волшебник',
    nameEn: 'Unearthed Arcana: Fighter, Rogue and Wizard',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/unearthed-arcana-fighter-rogue-and-wizard`,
    shortName: 'РТ:ПВДНП',
  },
  {
    id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
    date: '2020-01-14',
    isUA: true,
    name: 'Раскопанные тайны: Подклассы, часть 1',
    nameEn: 'Unearthed Arcana: Subclasses, Part 1',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/subclasses1`,
    shortName: 'РТ:ПЧ 1',
  },
  {
    id: SOURCE_UA_2020_02_06_SUBCLASSES_PART_2,
    date: '2020-02-06',
    isUA: true,
    name: 'Раскопанные тайны: Подклассы, часть 2',
    nameEn: 'Unearthed Arcana: Subclasses, Part 2',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/subclasses_part2`,
    shortName: 'РТ:ПЧ 2',
  },
  {
    id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
    date: '2020-02-24',
    isUA: true,
    name: 'Раскопанные тайны: Подклассы, часть 3',
    nameEn: 'Unearthed Arcana: Subclasses, Part 3',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/subclasses_part3`,
    shortName: 'РТ:ПЧ 3',
  },
  {
    id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
    date: '2020-03-26',
    isUA: true,
    name: 'Раскопанные тайны: Заклинания и магические татуировки',
    nameEn: 'Unearthed Arcana: Spells and Magic Tattoos',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/spells-magic-tattoos`,
    shortName: 'РТ:ЗиМТ',
  },
  {
    id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
    date: '2020-04-14',
    isUA: true,
    name: 'Раскопанные тайны: Пересмотр псионики',
    nameEn: 'Unearthed Arcana: Psionic Options Revisited',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/psionic-options-revisited`,
    shortName: 'РТ:ПП',
  },
  {
    id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
    date: '2020-05-12',
    isUA: true,
    name: 'Раскопанные тайны: Пересмотр подклассов',
    nameEn: 'Unearthed Arcana: Subclasses Revisited ',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/subclasses-revisited`,
    shortName: 'РТ:ПП',
  },
  {
    id: SOURCE_UA_2020_07_13_FEATS,
    date: '2020-07-13',
    isUA: true,
    name: 'Раскопанные тайны: Черты',
    nameEn: 'Unearthed Arcana: Feats',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/feats`,
    shortName: 'РТ:Ч',
  },
  {
    id: SOURCE_UA_2020_08_05_SUBCLASSES_PART_4,
    date: '2020-08-05',
    isUA: true,
    name: 'Раскопанные тайны: Подклассы, часть 4',
    nameEn: 'Unearthed Arcana: Subclasses, Part 4',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/subclasses-part-4`,
    shortName: 'РТ:ПЧ 4',
  },
  {
    id: SOURCE_UA_2020_09_15_TCoE_PC_BPoWM,
    date: '2020-09-15',
    isUA: true,
    name: 'Ташин котёл всякой всячины, предпросмотр: Варвар Пути дикой магии',
    nameEn: 'Tasha’s Cauldron of Everything, Preview Content: Barbarian Path of Wild Magic',
    translator: 'DM-Stuff.ru',
    url: `https://dndcelebration2020.com/assets/downloads/TashasPreview_Barbarian.pdf`,
    shortName: 'ТКВВ,П:ВПДМ',
  },
  {
    id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
    date: '2020-09-15',
    isUA: true,
    name: 'Ташин котёл всякой всячины, предпросмотр: Колдун Гения',
    nameEn: 'Tasha’s Cauldron of Everything, Preview Content: Warlock The Genie',
    translator: 'DM-Stuff.ru',
    url: `https://dndcelebration2020.com/assets/downloads/TashasPreview_Warlock.pdf`,
    shortName: 'ТКВВ,П:КГ',
  },
  {
    id: SOURCE_UA_2020_10_26_SUBCLASSES_PART_5,
    date: '2020-10-26',
    isUA: true,
    name: 'Раскопанные тайны: Подклассы, часть 5',
    nameEn: 'Unearthed Arcana: Subclasses, Part 5',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/subclasses5`,
    shortName: 'РТ:ПЧ 5',
  },
  {
    id: SOURCE_UA_2021_03_11_FOLK_OF_THE_FEYWILD,
    date: '2021-03-11',
    isUA: true,
    name: 'Раскопанные тайны: Народ Страны Фей',
    nameEn: 'Unearthed Arcana: Folk of the Feywild ',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthedarcana/folk_feywild`,
    shortName: 'РТ:НСФ',
  },
  {
    id: SOURCE_UA_2021_01_26_GOTHIC_LINEAGES,
    date: '2021-01-26',
    isUA: true,
    name: 'Раскопанные тайны: Готические наследия',
    nameEn: 'Unearthed Arcana: Gothic Lineages',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/gothic-lineages`,
    shortName: 'РТ:ГН',
  },
  {
    id: SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
    date: '2021-04-14',
    isUA: true,
    name: 'Раскопанные тайны: Драконьи варианты',
    nameEn: 'Unearthed Arcana: Draconic Options ',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/draconic-options`,
    shortName: 'РТ:ДВ',
  },
  {
    id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
    date: '2021-06-08',
    isUA: true,
    name: 'Раскопанные тайны: Маги Стриксхейвена',
    nameEn: 'Unearthed Arcana: Mages of Strixhaven',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/strixhaven`,
    shortName: 'РТ:МС',
  },
  {
    id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
    date: '2021-10-08',
    isUA: true,
    name: 'Раскопанные тайны: Путешественники мультивселенной',
    nameEn: 'Unearthed Arcana: Travelers of the Multiverse',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/articles/unearthed-arcana/travelers-multiverse`,
    shortName: 'РТ:ПМ',
  },
  {
    id: SOURCE_UA_2022_03_08_HEROES_OF_KRYNN,
    date: '2022-03-08',
    isUA: true,
    name: 'Раскопанные тайны: Герои Кринна',
    nameEn: 'Unearthed Arcana: Heroes of Krynn',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/unearthed-arcana/heroes-krynn`,
    shortName: 'РТ:ГК',
  },
  {
    id: SOURCE_UA_2022_04_25_HEROES_OF_KRYNN_REVISITED,
    date: '2022-04-25',
    isUA: true,
    name: 'Раскопанные тайны: Переосмысление Героев Кринна',
    nameEn: 'Unearthed Arcana: Heroes of Krynn Revisited',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/unearthed-arcana/heroes-krynn-revisited`,
    shortName: 'РТ:ПГК',
  },
  {
    id: SOURCE_UA_2022_05_22_GIANT_OPTIONS,
    date: '2022-05-22',
    isUA: true,
    name: 'Раскопанные тайны: Великаньи варианты',
    nameEn: 'Unearthed Arcana: Giant Options',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/unearthed-arcana/giant-options`,
    shortName: 'РТ:ВВ',
  },
  {
    id: SOURCE_UA_2022_07_18_WotM,
    date: '2022-07-18',
    isUA: true,
    name: 'Раскопанные тайны: Чудеса мультивселенной',
    nameEn: 'Unearthed Arcana: Wonders of the Multiverse',
    translator: 'DM-Stuff.ru',
    url: `https://dnd.wizards.com/unearthed-arcana/wonders-multiverse`,
    shortName: 'РТ:ЧМ',
  },
  {
    id: SOURCE_UA_2022_08_18_ODnD_CO,
    date: '2022-08-18',
    isUA: true,
    name: 'Единое D&D: Создание персонажей',
    nameEn: 'One D&D: Character Origins',
    translator: 'DM-Stuff.ru',
    url: `https://www.dndbeyond.com/sources/one-dnd/character-origins`,
    shortName: 'OD&D:СП',
  },

  // Лига приключенцев
  {
    id: SOURCE_DDHC_MORD_01,
    date: '2018-05-23',
    name: 'Загадка Королевы Воронов',
    nameEn: 'Riddle of the Raven Queen',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    url: 'https://www.dmsguild.com/product/242611/Riddle-of-the-Raven-Queen',
    shortName: 'ЗКВ',
  },

  // Нормальные мануалы
  {
    id: SOURCE_AI,
    date: '2019-06-18',
    name: 'Корпорация приобретений',
    nameEn: 'Acquisitions Incorporated',
    translator: 'DM-Stuff.ru',
    shortName: 'КП',
  },
  {
    id: SOURCE_AYAGMnC,
    name: 'Библиотека юного искателя приключений: Чудовища и прочие существа',
    nameEn: 'A Young Adventurer’s Guide – Monsters & Creatures',
    translator: 'DM-Stuff.ru',
    shortName: 'БМИП:ЧиПС',
  },
  {
    id: SOURCE_AYAGWnW,
    name: 'Библиотека юного искателя приключений: Герои и их снаряжение',
    nameEn: 'A Young Adventurer’s Guide – Warriors & Weapons',
    translator: 'DM-Stuff.ru',
    shortName: 'БМИП:ГиИС',
  },
  {
    id: SOURCE_BASIC_RULES,
    name: 'Базовые правила',
    nameEn: 'Basic Rules',
    url: `https://dnd.wizards.com/articles/features/basicrules`,
    shortName: 'БП',
  },
  {
    id: SOURCE_BGDIA,
    date: '2019-09-17',
    name: 'Врата Балдура: Погружение в Аверно',
    nameEn: 'Baldur’s Gate: Descent into Avernus',
    translator: 'HobbyWorld / DM-stuff.ru',
    shortName: 'ВБПвА',
  },
  {
    id: SOURCE_CM,
    date: '2021-03-16',
    name: 'Тайны Кэндлкипа',
    nameEn: 'Candlekeep Mysteries',
    translator: 'DM-stuff.ru',
    shortName: 'ТК',
  },
  {
    id: SOURCE_CoS,
    date: '2016-03-15',
    name: 'Проклятье Страда',
    nameEn: 'Curse of Strahd',
    shortName: 'ПС',
  },
  {
    id: SOURCE_CRCotN,
    name: 'Критическая роль: Зов Заглубинья',
    nameEn: 'Critical Role: Call of the Netherdeep',
    translator: 'DM-Stuff.ru',
    shortName: 'КР:ЗЗ',
  },
  {
    id: SOURCE_DMG,
    name: 'Руководство мастера',
    nameEn: 'Dungeon Master’s Guide',
    translator: 'Студия Phantom',
    shortName: 'РМ',
  },
  {
    id: SOURCE_DnD_SADS_CLE,
    name: 'Набор костей сапфировой годовщины D&D: ограниченное коллекционное издание',
    nameEn: 'D&D Sapphire Anniversary Dice Set: Collector’s Limited Edition',
    translator: 'DM-Stuff.ru',
    shortName: 'НКСГ',
    url: `https://dnd.wizards.com/products/sapphire-anniversary`,
  },
  {
    id: SOURCE_DoD,
    date: '2021-09-21',
    name: 'Домены наслаждений',
    nameEn: 'Domains of Delight',
    translator: 'DM-Stuff.ru',
    shortName: 'ДН',
  },
  {
    id: SOURCE_DoMT,
    name: 'Колода многих вещей',
    nameEn: 'Deck of Many Things',
    translator: 'DM-Stuff.ru',
    shortName: 'КМВ',
  },
  {
    id: SOURCE_EE,
    date: '2015-03-10',
    name: 'Стихийное зло',
    nameEn: 'Elemental Evil',
    translator: 'r0ot35 и Студия Phantom',
    shortName: 'СЗ',
  },
  {
    id: SOURCE_EGTW,
    date: '2020-03-17',
    name: 'Путеводитель исследователя по Дикогорью',
    nameEn: 'Explorer’s Guide to Wildemount',
    translator: 'DM-Stuff.ru',
    shortName: 'ПИпД',
  },
  {
    id: SOURCE_ERFTLW,
    date: '2019-11-19',
    name: 'Восстановление Эберрона после Последней Войны',
    nameEn: 'Eberron Rising from the Last War',
    translator: 'DM-Stuff.ru',
    shortName: 'ВЭпПВ',
  },
  {
    id: SOURCE_GGTR,
    name: 'Справочник гильдмастера по Равнике',
    nameEn: 'Guildmaster’s Guide to Ravnica',
    translator: 'DM-Stuff.ru',
    shortName: 'СГпР',
  },
  {
    id: SOURCE_FTD,
    name: 'Физбанова сокровищница драконов',
    nameEn: 'Fizban’s Treasury of Dragons',
    translator: 'DM-Stuff.ru',
    shortName: 'ФСД',
  },
  {
    id: SOURCE_GOS,
    date: '2019-05-21',
    name: 'Призраки Солёного болота',
    nameEn: 'Ghosts of Saltmarsh',
    translator: 'DM-Stuff.ru',
    shortName: 'ПСБ',
  },
  {
    id: SOURCE_HOTDQ,
    date: '2014-08-18',
    name: 'Клад Королевы Драконов',
    nameEn: 'Hoard of the Dragon Queen',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'HotDQ',
  },
  {
    id: SOURCE_IDRotF,
    date: '2020-09-15',
    name: 'Долина Ледяного Ветра: Иней Морозной девы',
    nameEn: 'Icewind Dale: Rime of the Frostmaiden',
    translator: 'DM-Stuff.ru',
    shortName: 'ДЛВ:ИМД',
  },
  {
    id: SOURCE_JTtRC,
    date: '2022-07-19',
    name: 'Путешествия по Сияющей цитадели',
    nameEn: 'Journeys through the Radiant Citadel',
    translator: 'DM-Stuff.ru',
    shortName: 'ПпСЦ',
  },
  {
    id: SOURCE_LR,
    date: '2019-09-19',
    name: 'Восстание локат',
    nameEn: 'Locathah Rising',
    translator: 'DM-Stuff.ru',
    shortName: 'ЛР:ТВТ',
  },
  {
    id: SOURCE_MBJV,
    date: '2021-10-05',
    name: 'Журнал злодеев Минска и Бу',
    nameEn: 'Minsc and Boo’s Journal of Villainy',
    translator: 'DM-Stuff.ru',
    shortName: 'МиБ:ЖЗ',
  },
  {
    id: SOURCE_MFFV_1_MMB,
    date: '2019-11-12',
    name: 'Чудовищный фолиант Морденкайнена. Том 1. Чудища злобные и приветливые',
    nameEn: 'Mordenkainen’s Fiendish Folio. Volume 1. Monsters Malevolent and Benign',
    translator: 'DM-Stuff.ru',
    shortName: 'ЧФМ 1',
  },
  {
    id: SOURCE_MCV_1_SC,
    name: 'Чудовищный справочник. Том 1: Существа Спеллджаммера',
    nameEn: 'Monstrous Compendium Vol 1: Spelljammer Creatures',
    translator: 'DM-Stuff.ru',
    shortName: 'ЧС1:CC',
    url: `https://www.dndbeyond.com/sources/mcv1/spelljammer-creatures`,
  },
  {
    id: SOURCE_MCV_2_DC,
    name: 'Чудовищный справочник. Том 2: Существа Драконьего копья',
    nameEn: 'Monstrous Compendium Vol 2: Dragonlance Creatures',
    translator: 'DM-Stuff.ru',
    shortName: 'ЧС2:CДК',
    url: `https://www.dndbeyond.com/sources/mcv2/dragonlance-creatures`,
  },
  {
    id: SOURCE_MCV_3_MC,
    name: 'Чудовищный справочник. Том 3: Существа Майнкрафта',
    nameEn: 'Monstrous Compendium Vol 3: Minecraft Creatures',
    translator: 'DM-Stuff.ru',
    shortName: 'ЧС3:CМ',
    url: `https://www.dndbeyond.com/claim/source/minecraft-creatures-monstrous-compendium`,
  },
  {
    id: SOURCE_MCV_4_EC,
    name: 'Чудовищный справочник. Том 4: Существа Элдраина',
    nameEn: 'Monstrous Compendium Vol 4: Eldraine Creatures',
    translator: 'DM-Stuff.ru',
    shortName: 'ЧС4:CЭ',
    url: `https://www.dndbeyond.com/marketplace/sourcebooks/monstrous-compendium-vol-4-eldraine-creatures`,
  },
  {
    id: SOURCE_MM,
    name: 'Бестиарий',
    nameEn: 'Monster Manual',
    translator: 'Студия Phantom',
    shortName: 'Б',
  },
  {
    id: SOURCE_MOOT,
    date: '2020-06-02',
    name: 'Мифические одиссеи Тероса',
    nameEn: 'Mythical Odysseys of Theros',
    translator: 'DM-Stuff.ru',
    shortName: 'МОТ',
  },
  {
    id: SOURCE_MTOF,
    name: 'Том Морденкайнена о врагах',
    nameEn: 'Mordenkainen’s Tome of Foes',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'ТМоВ',
  },
  {
    id: SOURCE_MPMotM,
    date: '2022-05-16',
    name: 'Морденкайнен представляет: Монстры мультивселенной',
    nameEn: 'Mordenkainen Presents: Monsters of the Multiverse',
    translator: 'DM-Stuff.ru',
    shortName: 'МП:ММ',
  },
  {
    id: SOURCE_OGA,
    date: '2017-10-11',
    name: 'Там грунг наверху',
    nameEn: 'One Grung Above',
    translator: 'DM-Stuff.ru',
    shortName: 'ОГБ',
  },
  {
    id: SOURCE_OotA,
    date: '2015-09-15',
    name: 'Из Бездны',
    nameEn: 'Out of the Abyss',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'ИБ',
  },
  {
    id: SOURCE_PHB,
    name: 'Книга игрока',
    nameEn: 'Player’s Handbook',
    translator: 'Студия Phantom',
    shortName: 'КИ',
  },
  {
    id: SOURCE_PotA,
    date: '2015-04-07',
    name: 'Принцы апокалипсиса',
    nameEn: 'Princes of the Apocalypse',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'ПА',
  },
  {
    id: SOURCE_ROT,
    date: '2014-11-04',
    name: 'Пробуждение Тиамат',
    nameEn: 'Rise of Tiamat',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'ПТ',
  },
  {
    id: SOURCE_SaCoC,
    date: '2021-12-07',
    name: 'Стриксхейвен: Полугодие хаоса',
    nameEn: 'Strixhaven: A Curriculum of Chaos',
    translator: 'DM-Stuff.ru',
    shortName: 'СПХ',
  },
  {
    id: SOURCE_SCAG,
    name: 'Путеводитель приключенца по Побережью Меча',
    nameEn: 'Sword Coast Adventurer’s Guide',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'ППпПМ',
  },
  {
    id: SOURCE_SKT,
    name: 'Гром Штормового Короля',
    nameEn: 'Storm King’s Thunder',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'ГШК',
  },
  {
    id: SOURCE_SJA,
    name: 'Академия Спеллджаммера',
    nameEn: 'Spelljammer Academy',
    translator: 'DM-Stuff.ru',
    shortName: 'АСД',
  },
  {
    id: SOURCE_SRD,
    name: 'Системный справочный документ',
    nameEn: 'System Reference Document',
    shortName: 'ССД',
    url: `https://dnd.wizards.com/articles/features/systems-reference-document-srd`,
  },
  {
    id: SOURCE_SS,
    name: 'Стартовый набор. Книга правил',
    nameEn: 'Starters Set',
    translator: 'HobbyWorld',
    shortName: 'СНКП',
  },
  {
    id: SOURCE_TCoE,
    name: 'Ташин котёл всякой всячины',
    nameEn: 'Tasha’s Cauldron of Everything',
    translator: 'DM-stuff.ru, Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'ТКВВ',
  },
  {
    id: SOURCE_TOA,
    date: '2017-09-19',
    name: 'Гробница аннигиляции',
    nameEn: 'Tomb of Annihilation',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'ГА',
  },
  {
    id: SOURCE_TTP,
    date: '2017-09-17',
    name: 'Пачка тортлов',
    nameEn: 'The Tortle Package',
    translator: 'DM-Stuff.ru',
    shortName: 'ПТ',
  },
  {
    id: SOURCE_TYP,
    date: '2017-04-04',
    name: 'Истории из Зияющего портала',
    nameEn: 'Tales from the Yawning Portal',
    translator: 'DM-Stuff.ru',
    shortName: 'ИиЗП',
  },
  {
    id: SOURCE_TWBTW,
    date: '2021-09-21',
    name: 'Чащоба за Ведьминым светом',
    nameEn: 'The Wild Beyond the Witchlight',
    translator: 'DM-Stuff.ru',
    shortName: 'ЧзВС',
  },
  {
    id: SOURCE_VGTM,
    name: 'Справочник Воло по монстрам',
    nameEn: 'Volo’s Guide to Monsters',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'СВпМ',
  },
  {
    id: SOURCE_VRGtR,
    date: '2021-05-18',
    name: 'Справочник Ван Рихтена по Равенлофту',
    nameEn: 'Van Richten’s Guide to Ravenloft',
    translator: 'DM-Stuff.ru',
    shortName: 'СВРпР',
  },
  {
    id: SOURCE_WDH,
    date: '2018-09-18',
    name: 'Глубоководье: Драконий куш',
    nameEn: 'Waterdeep: Dragon Heist',
    translator: 'HobbyWorld',
    shortName: 'Г:ДК',
  },
  {
    id: SOURCE_WDMM,
    date: '2018-11-20',
    name: 'Глубоководье: Подземелье безумного мага',
    nameEn: 'Waterdeep: Dungeon of the Mad Mage',
    translator: 'DM-Stuff.ru',
    shortName: 'Г:ПБМ',
  },
  {
    id: SOURCE_WGtE,
    date: '2018-07-23',
    name: 'Справочник путешественника по Эберрону',
    nameEn: 'Wayfinder’s guide to Eberron',
    translator: 'DM-Stuff.ru',
    shortName: 'СПпЭ',
  },
  {
    id: SOURCE_XGTE,
    date: '2018-11-15',
    name: 'Руководство Занатара обо всём',
    nameEn: 'Xanathar’s Guide to Everything',
    translator: 'Dungeons.ru & DungeonsAndDragons.ru',
    shortName: 'РЗоВ',
  },
  {
    id: SOURCE_DSotDQ,
    date: '2022-12-06',
    name: 'Драконье Копьё: Тьма Королевы драконов',
    nameEn: 'Dragonlance: Shadow of the Dragon Queen',
    translator: 'DM-Stuff.ru',
    shortName: 'ДК:ТКД',
  },
  {
    id: SOURCE_SAIS,
    date: '2022-08-16',
    name: 'Спеллджаммер: Приключения в космосе',
    nameEn: 'Spelljammer: Adventures in Space',
    translator: 'DM-Stuff.ru',
    shortName: 'СД:ПвК',
    url: `https://www.dndbeyond.com/sources/sais`,
  },
  {
    id: SOURCE_KGV,
    date: '2023-02-21',
    name: 'Ключи от золотого хранилища',
    nameEn: 'Keys from the Golden Vault',
    translator: 'DM-Stuff.ru',
    shortName: 'КоЗХ:P13',
  },
  {
    id: SOURCE_PaBtSO,
    date: '2023-10-17',
    name: 'Фанделвер и дальше: Расколотый обелиск',
    nameEn: 'Phandelver and Below: The Shattered Obelisk',
    translator: 'DM-Stuff.ru',
    shortName: 'ФиД:PО',
    url: `https://www.dndbeyond.com/marketplace/adventures/phandelver-and-below-the-shattered-obelisk`,
  },
  {
    id: SOURCE_GotSF,
    name: 'Великаны звёздной кузни',
    nameEn: 'Giants of the Star Forge',
    translator: 'DM-Stuff.ru',
    shortName: 'ВЗК',
    url: `https://www.dndbeyond.com/sources/gotsf/giants-of-the-star-forge`,
  },
  {
    id: SOURCE_PAITM,
    name: 'Планскейп: Путешествия в Мультивселенной',
    nameEn: 'Planescape: Adventures in the Multiverse',
    translator: 'DM-Stuff.ru',
    shortName: 'ППвМ',
    url: `https://www.dndbeyond.com/marketplace/sourcebooks/planescape-adventures-in-the-multiverse-digital`,
  },
  {
    id: SOURCE_TBOMT,
    name: 'Книга многих вещей',
    nameEn: 'The Book of Many Things',
    translator: 'DM-Stuff.ru',
    shortName: 'КМВ',
    url: `https://www.dndbeyond.com/marketplace/sourcebooks/the-book-of-many-things-digital-pre-order`,
  },
  {
    id: SOURCE_GOTG,
    name: 'Бигби представляет: Слава великанов',
    nameEn: 'Bigby Presents: Glory of the Giants',
    translator: 'DM-Stuff.ru',
    shortName: 'БП:СВ',
    url: `https://www.dndbeyond.com/sources/gotg`,
  },

  // Неофициальный контент
  {
    id: SOURCE_LOR_DTOB,
    name: 'Легенды Руноземья: Тёмные волны Трюмоводья',
    nameEn: 'Legends of Runeterra: Dark Tides of Bilgewater',
    translator: 'DM-Stuff.ru',
    url: `https://www.dndbeyond.com/sources/lrdtob`,
    shortName: 'ЛР:ТВТ',
  },

  // Правки
  {
    id: SOURCE_ERRATA_MONSTER_MANUAL,
    name: 'Бестиарий: Правки',
    nameEn: 'Errata: Monster Manual',
    translator: 'DM-Stuff.ru',
    url: `https://media.wizards.com/2018/dnd/downloads/MM-Errata.pdf`,
    shortName: 'Б:П',
  },
  {
    id: SOURCE_ERRATA_PHB,
    name: 'Книга игрока: Правки',
    nameEn: 'Errata: Player’s Handbook',
    translator: 'DM-Stuff.ru',
    url: `https://media.wizards.com/2020/dnd/downloads/PH-Errata.pdf`,
    shortName: 'КИ:П',
  },
  {
    id: SOURCE_ERRATA_VGTM,
    name: 'Справочник Воло по монстрам: Правки',
    nameEn: 'Errata: Volo’s Guide to Monsters',
    shortName: 'СВпМ:П',
    translator: 'DM-Stuff.ru',
    url: `https://media.wizards.com/2020/dnd/downloads/VGtM-Errata.pdf`,
  },
  {
    id: SOURCE_ERRATA_XGTE,
    name: 'Руководство Занатара обо всём: Правки',
    nameEn: 'Errata: Xanathar’s Guide to Everything',
    translator: 'DM-Stuff.ru',
    url: `https://media.wizards.com/2020/dnd/downloads/XGtE-Errata.pdf`,
    shortName: 'РЗоВ:П',
  },

  // Другие продукты WotC
  {
    id: SOURCE_MTG_AFR,
    name: 'Сет MtG «D&D: Приключения в Забытых Королевствах»',
    nameEn: 'MtG expansion “Dungeons & Dragons: Adventures in the Forgotten Realms”',
    translator: 'Wizards of the Coast',
    shortName: 'MtG:ПвЗК',
  },
  {
    id: SOURCE_MTG_AHBG,
    date: '2022-07-07',
    name: 'Сет MtG «Горизонты Алхимии: Врата Балдура»',
    nameEn: 'MtG expansion “Alchemy Horizons: Baldur’s Gate”',
    translator: 'Wizards of the Coast',
    shortName: 'MtG:ГАВБ',
  },
  {
    id: SOURCE_MTG_CLB,
    name: 'Сет MtG «Легенды командира: Битва за Врата Балдура»',
    nameEn: 'MtG expansion “Commander Legends: Battle for Baldur’s Gate”',
    translator: 'Wizards of the Coast',
    shortName: 'MtG:ЛКБзВБ',
  },
  {
    id: SOURCE_MTG_M20,
    name: 'Сет MtG «Базовый выпуск 2020»',
    nameEn: 'MtG expansion “Core Set 2020”',
    translator: 'Wizards of the Coast',
    shortName: 'MtG:М20',
  },
  {
    id: SOURCE_MTG_THS,
    name: 'Сет MtG «Терос»',
    nameEn: 'MtG expansion “Theros”',
    translator: 'Wizards of the Coast',
    shortName: 'MtG: Терос',
  },
]
  .map(
    item => ({
      ...item,
      isUA: Boolean(item.isUA),
      date: item.date || null,
    })
  )

module.exports = sourceList

module.exports.sourceCollection = listToCollectionById(sourceList)

module.exports.SOURCE_ADND2_AGAP = SOURCE_ADND2_AGAP
module.exports.SOURCE_ADND2_EE = SOURCE_ADND2_EE
module.exports.SOURCE_ADND2_FoEtF = SOURCE_ADND2_FoEtF
module.exports.SOURCE_ADND2_MCSA = SOURCE_ADND2_MCSA
module.exports.SOURCE_ADND2_MM = SOURCE_ADND2_MM
module.exports.SOURCE_ADND2_SJCS = SOURCE_ADND2_SJCS
module.exports.SOURCE_ADND2_TCDH = SOURCE_ADND2_TCDH
module.exports.SOURCE_ADND_MM = SOURCE_ADND_MM
module.exports.SOURCE_ADND_PHB = SOURCE_ADND_PHB
module.exports.SOURCE_AI = SOURCE_AI
module.exports.SOURCE_AYAGMnC = SOURCE_AYAGMnC
module.exports.SOURCE_AYAGWnW = SOURCE_AYAGWnW
module.exports.SOURCE_BASIC_RULES = SOURCE_BASIC_RULES
module.exports.SOURCE_BGDIA = SOURCE_BGDIA
module.exports.SOURCE_BOOK_ABBEY_TNS = SOURCE_BOOK_ABBEY_TNS
module.exports.SOURCE_BOOK_GREENWOOD_DOTD = SOURCE_BOOK_GREENWOOD_DOTD
module.exports.SOURCE_BOOK_GREENWOOD_EIMD = SOURCE_BOOK_GREENWOOD_EIMD
module.exports.SOURCE_BOOK_LARIAN_AFBG3 = SOURCE_BOOK_LARIAN_AFBG3
module.exports.SOURCE_BOOK_LOWDER_PoL = SOURCE_BOOK_LOWDER_PoL
module.exports.SOURCE_BOOK_SALVATORE_EXILE = SOURCE_BOOK_SALVATORE_EXILE
module.exports.SOURCE_BOOK_SALVATORE_POTWK = SOURCE_BOOK_SALVATORE_POTWK
module.exports.SOURCE_BOOK_SALVATORE_SN = SOURCE_BOOK_SALVATORE_SN
module.exports.SOURCE_CM = SOURCE_CM
module.exports.SOURCE_CoS = SOURCE_CoS
module.exports.SOURCE_CRCotN = SOURCE_CRCotN
module.exports.SOURCE_DDHC_MORD_01 = SOURCE_DDHC_MORD_01
module.exports.SOURCE_DMG = SOURCE_DMG
module.exports.SOURCE_DND1_CotR = SOURCE_DND1_CotR
module.exports.SOURCE_DND3_5_DoF = SOURCE_DND3_5_DoF
module.exports.SOURCE_DND3_5_DTBoD = SOURCE_DND3_5_DTBoD
module.exports.SOURCE_DND3_5_MotP = SOURCE_DND3_5_MotP
module.exports.SOURCE_DND3_5_PGTD = SOURCE_DND3_5_PGTD
module.exports.SOURCE_DND3_5_PGTM = SOURCE_DND3_5_PGTM
module.exports.SOURCE_DND3_5_PGTW = SOURCE_DND3_5_PGTW
module.exports.SOURCE_DND3_5_PHB = SOURCE_DND3_5_PHB
module.exports.SOURCE_DND3_5_RoS = SOURCE_DND3_5_RoS
module.exports.SOURCE_DND3_5_SW = SOURCE_DND3_5_SW
module.exports.SOURCE_DND3_DLCS = SOURCE_DND3_DLCS
module.exports.SOURCE_DND3_FnP = SOURCE_DND3_FnP
module.exports.SOURCE_DND3_FRCS = SOURCE_DND3_FRCS
module.exports.SOURCE_DND3_PGtF = SOURCE_DND3_PGtF
module.exports.SOURCE_DND3_UE = SOURCE_DND3_UE
module.exports.SOURCE_DND4_DCD = SOURCE_DND4_DCD
module.exports.SOURCE_DND4_DMG = SOURCE_DND4_DMG
module.exports.SOURCE_DND4_FRPG = SOURCE_DND4_FRPG
module.exports.SOURCE_DND4_MotP = SOURCE_DND4_MotP
module.exports.SOURCE_DND4_NCS = SOURCE_DND4_NCS
module.exports.SOURCE_DnD_SADS_CLE = SOURCE_DnD_SADS_CLE
module.exports.SOURCE_DoD = SOURCE_DoD
module.exports.SOURCE_DoMT = SOURCE_DoMT
module.exports.SOURCE_DSotDQ = SOURCE_DSotDQ
module.exports.SOURCE_EE = SOURCE_EE
module.exports.SOURCE_EGTW = SOURCE_EGTW
module.exports.SOURCE_ERFTLW = SOURCE_ERFTLW
module.exports.SOURCE_ERRATA_MONSTER_MANUAL = SOURCE_ERRATA_MONSTER_MANUAL
module.exports.SOURCE_ERRATA_PHB = SOURCE_ERRATA_PHB
module.exports.SOURCE_ERRATA_VGTM = SOURCE_ERRATA_VGTM
module.exports.SOURCE_ERRATA_XGTE = SOURCE_ERRATA_XGTE
module.exports.SOURCE_FTD = SOURCE_FTD
module.exports.SOURCE_GAME_BG_3 = SOURCE_GAME_BG_3
module.exports.SOURCE_GAME_ICEWIND_DALE_2 = SOURCE_GAME_ICEWIND_DALE_2
module.exports.SOURCE_GGTR = SOURCE_GGTR
module.exports.SOURCE_GOS = SOURCE_GOS
module.exports.SOURCE_GOTG = SOURCE_GOTG
module.exports.SOURCE_GotSF = SOURCE_GotSF
module.exports.SOURCE_HOTDQ = SOURCE_HOTDQ
module.exports.SOURCE_IDRotF = SOURCE_IDRotF
module.exports.SOURCE_JTtRC = SOURCE_JTtRC
module.exports.SOURCE_KGV = SOURCE_KGV
module.exports.SOURCE_LOR_DTOB = SOURCE_LOR_DTOB
module.exports.SOURCE_LR = SOURCE_LR
module.exports.SOURCE_MBJV = SOURCE_MBJV
module.exports.SOURCE_MCV_1_SC = SOURCE_MCV_1_SC
module.exports.SOURCE_MCV_2_DC = SOURCE_MCV_2_DC
module.exports.SOURCE_MCV_3_MC = SOURCE_MCV_3_MC
module.exports.SOURCE_MCV_4_EC = SOURCE_MCV_4_EC
module.exports.SOURCE_MFFV_1_MMB = SOURCE_MFFV_1_MMB
module.exports.SOURCE_MGZN_DNGN_100 = SOURCE_MGZN_DNGN_100
module.exports.SOURCE_MGZN_DRGN_281 = SOURCE_MGZN_DRGN_281
module.exports.SOURCE_MGZN_DRGN_378 = SOURCE_MGZN_DRGN_378
module.exports.SOURCE_MGZN_DRGN_ANNUAL_1999 = SOURCE_MGZN_DRGN_ANNUAL_1999
module.exports.SOURCE_MGZN_PLHDRN_159 = SOURCE_MGZN_PLHDRN_159
module.exports.SOURCE_MM = SOURCE_MM
module.exports.SOURCE_MOOT = SOURCE_MOOT
module.exports.SOURCE_MPMotM = SOURCE_MPMotM
module.exports.SOURCE_MTG_AFR = SOURCE_MTG_AFR
module.exports.SOURCE_MTG_AHBG = SOURCE_MTG_AHBG
module.exports.SOURCE_MTG_CLB = SOURCE_MTG_CLB
module.exports.SOURCE_MTG_M20 = SOURCE_MTG_M20
module.exports.SOURCE_MTG_THS = SOURCE_MTG_THS
module.exports.SOURCE_MTOF = SOURCE_MTOF
module.exports.SOURCE_OGA = SOURCE_OGA
module.exports.SOURCE_OotA = SOURCE_OotA
module.exports.SOURCE_PAITM = SOURCE_PAITM
module.exports.SOURCE_PHB = SOURCE_PHB
module.exports.SOURCE_PaBtSO = SOURCE_PaBtSO
module.exports.SOURCE_PotA = SOURCE_PotA
module.exports.SOURCE_ROT = SOURCE_ROT
module.exports.SOURCE_SAIS = SOURCE_SAIS
module.exports.SOURCE_SCAG = SOURCE_SCAG
module.exports.SOURCE_SJA = SOURCE_SJA
module.exports.SOURCE_SKT = SOURCE_SKT
module.exports.SOURCE_SRD = SOURCE_SRD
module.exports.SOURCE_SS = SOURCE_SS
module.exports.SOURCE_SaCoC = SOURCE_SaCoC
module.exports.SOURCE_TBOMT = SOURCE_TBOMT
module.exports.SOURCE_TCoE = SOURCE_TCoE
module.exports.SOURCE_TOA = SOURCE_TOA
module.exports.SOURCE_TTP = SOURCE_TTP
module.exports.SOURCE_TYP = SOURCE_TYP
module.exports.SOURCE_TWBTW = SOURCE_TWBTW
module.exports.SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK = SOURCE_UA_2015_11_02_LIGHT_DARK_UNDERDARK
module.exports.SOURCE_UA_2016_01_04_KITS_OF_OLD = SOURCE_UA_2016_01_04_KITS_OF_OLD
module.exports.SOURCE_UA_2016_04_04_GOTHIC_HEROES = SOURCE_UA_2016_04_04_GOTHIC_HEROES
module.exports.SOURCE_UA_2016_06_06_FEATS = SOURCE_UA_2016_06_06_FEATS
module.exports.SOURCE_UA_2016_08_01_THE_FAITHFUL = SOURCE_UA_2016_08_01_THE_FAITHFUL
module.exports.SOURCE_UA_2016_09_12_THE_RANGER_REVISED = SOURCE_UA_2016_09_12_THE_RANGER_REVISED
module.exports.SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES = SOURCE_UA_2016_11_14_BARD_BARD_COLLEGES
module.exports.SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS = SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS
module.exports.SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE = SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE
module.exports.SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES = SOURCE_UA_2016_12_05_FIGHTER_MARTIAL_ARCHETYPES
module.exports.SOURCE_UA_2016_12_12_MONK_MONASTIC_TRADITIONS = SOURCE_UA_2016_12_12_MONK_MONASTIC_TRADITIONS
module.exports.SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS = SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS
module.exports.SOURCE_UA_2017_01_09_ARTIFICER = SOURCE_UA_2017_01_09_ARTIFICER
module.exports.SOURCE_UA_2017_01_16_RANGER_ROGUE = SOURCE_UA_2017_01_16_RANGER_ROGUE
module.exports.SOURCE_UA_2017_02_06_SORCERER = SOURCE_UA_2017_02_06_SORCERER
module.exports.SOURCE_UA_2017_02_13_WARLOCK_WIZARD = SOURCE_UA_2017_02_13_WARLOCK_WIZARD
module.exports.SOURCE_UA_2017_02_21_MASS_COMBAT = SOURCE_UA_2017_02_21_MASS_COMBAT
module.exports.SOURCE_UA_2017_02_27_TRAPS_REVISITED = SOURCE_UA_2017_02_27_TRAPS_REVISITED
module.exports.SOURCE_UA_2017_03_13_THE_MYSTIC_CLASS = SOURCE_UA_2017_03_13_THE_MYSTIC_CLASS
module.exports.SOURCE_UA_2017_03_20_WIZARD_REVISITED = SOURCE_UA_2017_03_20_WIZARD_REVISITED
module.exports.SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES = SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES
module.exports.SOURCE_UA_2017_04_03_STARTER_SPELLS = SOURCE_UA_2017_04_03_STARTER_SPELLS
module.exports.SOURCE_UA_2017_04_10_DOWNTIME = SOURCE_UA_2017_04_10_DOWNTIME
module.exports.SOURCE_UA_2017_04_17_FFS = SOURCE_UA_2017_04_17_FFS
module.exports.SOURCE_UA_2017_04_24_FFR = SOURCE_UA_2017_04_24_FFR
module.exports.SOURCE_UA_2017_05_01_REVISED_SUBCLASSES = SOURCE_UA_2017_05_01_REVISED_SUBCLASSES
module.exports.SOURCE_UA_2017_05_25_UNEARTHED_ARCANA_UPDATE = SOURCE_UA_2017_05_25_UNEARTHED_ARCANA_UPDATE
module.exports.SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS = SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS
module.exports.SOURCE_UA_2017_07_10_GREYHAWK_INITIATIVE = SOURCE_UA_2017_07_10_GREYHAWK_INITIATIVE
module.exports.SOURCE_UA_2017_08_07_THREE_PILLAR_EXPERIENCE = SOURCE_UA_2017_08_07_THREE_PILLAR_EXPERIENCE
module.exports.SOURCE_UA_2017_09_11_RACE_OPTIONS_ELADRIN_AND_GITH = SOURCE_UA_2017_09_11_RACE_OPTIONS_ELADRIN_AND_GITH
module.exports.SOURCE_UA_2017_10_09_FIENDISH_OPTIONS = SOURCE_UA_2017_10_09_FIENDISH_OPTIONS
module.exports.SOURCE_UA_2017_11_13_ELF_SUBRACES = SOURCE_UA_2017_11_13_ELF_SUBRACES
module.exports.SOURCE_UA_2018_01_08_3_SUBCLASSES_0108 = SOURCE_UA_2018_01_08_3_SUBCLASSES_0108
module.exports.SOURCE_UA_2018_02_12_INTO_THE_WILD = SOURCE_UA_2018_02_12_INTO_THE_WILD
module.exports.SOURCE_UA_2018_04_09_ORDER_DOMAIN = SOURCE_UA_2018_04_09_ORDER_DOMAIN
module.exports.SOURCE_UA_2018_05_14_CENTAURS_AND_MINOTAURS = SOURCE_UA_2018_05_14_CENTAURS_AND_MINOTAURS
module.exports.SOURCE_UA_2018_06_11_GIANT_SOUL = SOURCE_UA_2018_06_11_GIANT_SOUL
module.exports.SOURCE_UA_2018_07_23_RACES_OF_EBERRON = SOURCE_UA_2018_07_23_RACES_OF_EBERRON
module.exports.SOURCE_UA_2018_08_13_RACES_OF_RAVNICA = SOURCE_UA_2018_08_13_RACES_OF_RAVNICA
module.exports.SOURCE_UA_2018_09_10_DRAGONMARKS = SOURCE_UA_2018_09_10_DRAGONMARKS
module.exports.SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON = SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON
module.exports.SOURCE_UA_2018_11_12_OF_SHIPS_AND_THE_SEA = SOURCE_UA_2018_11_12_OF_SHIPS_AND_THE_SEA
module.exports.SOURCE_UA_2018_12_17_SIDEKICKS = SOURCE_UA_2018_12_17_SIDEKICKS
module.exports.SOURCE_UA_2019_02_28_ARTIFICER_REVISITED = SOURCE_UA_2019_02_28_ARTIFICER_REVISITED
module.exports.SOURCE_UA_2019_05_14_ARTIFICER_RETURNS = SOURCE_UA_2019_05_14_ARTIFICER_RETURNS
module.exports.SOURCE_UA_2019_08_15_WILD_ASTRAL = SOURCE_UA_2019_08_15_WILD_ASTRAL
module.exports.SOURCE_UA_2019_09_05_ABERRANT_LURK = SOURCE_UA_2019_09_05_ABERRANT_LURK
module.exports.SOURCE_UA_2019_09_18_ELOQUENT_HEROICS = SOURCE_UA_2019_09_18_ELOQUENT_HEROICS
module.exports.SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES = SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES
module.exports.SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED = SOURCE_UA_2019_10_17_RUNE_SWARM_REVIVED
module.exports.SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS = SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS
module.exports.SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS = SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS
module.exports.SOURCE_UA_2020_01_14_SUBCLASSES_PART_1 = SOURCE_UA_2020_01_14_SUBCLASSES_PART_1
module.exports.SOURCE_UA_2020_02_06_SUBCLASSES_PART_2 = SOURCE_UA_2020_02_06_SUBCLASSES_PART_2
module.exports.SOURCE_UA_2020_02_24_SUBCLASSES_PART_3 = SOURCE_UA_2020_02_24_SUBCLASSES_PART_3
module.exports.SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS = SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS
module.exports.SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED = SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED
module.exports.SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED = SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED
module.exports.SOURCE_UA_2020_07_13_FEATS = SOURCE_UA_2020_07_13_FEATS
module.exports.SOURCE_UA_2020_08_05_SUBCLASSES_PART_4 = SOURCE_UA_2020_08_05_SUBCLASSES_PART_4
module.exports.SOURCE_UA_2020_09_15_TCoE_PC_BPoWM = SOURCE_UA_2020_09_15_TCoE_PC_BPoWM
module.exports.SOURCE_UA_2020_09_15_TCoE_PC_WtG = SOURCE_UA_2020_09_15_TCoE_PC_WtG
module.exports.SOURCE_UA_2020_10_26_SUBCLASSES_PART_5 = SOURCE_UA_2020_10_26_SUBCLASSES_PART_5
module.exports.SOURCE_UA_2021_01_26_GOTHIC_LINEAGES = SOURCE_UA_2021_01_26_GOTHIC_LINEAGES
module.exports.SOURCE_UA_2021_03_11_FOLK_OF_THE_FEYWILD = SOURCE_UA_2021_03_11_FOLK_OF_THE_FEYWILD
module.exports.SOURCE_UA_2021_04_14_DRACONIC_OPTIONS = SOURCE_UA_2021_04_14_DRACONIC_OPTIONS
module.exports.SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN = SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN
module.exports.SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE = SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE
module.exports.SOURCE_UA_2022_03_08_HEROES_OF_KRYNN = SOURCE_UA_2022_03_08_HEROES_OF_KRYNN
module.exports.SOURCE_UA_2022_04_25_HEROES_OF_KRYNN_REVISITED = SOURCE_UA_2022_04_25_HEROES_OF_KRYNN_REVISITED
module.exports.SOURCE_UA_2022_05_22_GIANT_OPTIONS = SOURCE_UA_2022_05_22_GIANT_OPTIONS
module.exports.SOURCE_UA_2022_07_18_WotM = SOURCE_UA_2022_07_18_WotM
module.exports.SOURCE_UA_2022_08_18_ODnD_CO = SOURCE_UA_2022_08_18_ODnD_CO
module.exports.SOURCE_VGTM = SOURCE_VGTM
module.exports.SOURCE_VRGtR = SOURCE_VRGtR
module.exports.SOURCE_WDH = SOURCE_WDH
module.exports.SOURCE_WDMM = SOURCE_WDMM
module.exports.SOURCE_WGtE = SOURCE_WGtE
module.exports.SOURCE_XGTE = SOURCE_XGTE
