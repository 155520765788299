const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {CREATURE_TYPE_ANVILWROUGHT} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ANVILWROUGHT,
  nameEn: 'Anvilwrought',
  genderId: GENDER_MALE,
  notRealRace: true,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'откованный',
        genitive: 'откованного',
        dative: 'откованному',
        accusative: 'откованного',
        instrumental: 'откованным',
        prepositional: 'откованном',
      },
      [GENDER_FEMALE]: {
        nominative: 'откованная',
        genitive: 'откованной',
        dative: 'откованной',
        accusative: 'откованную',
        instrumental: 'откованной',
        prepositional: 'откованной',
      },
    },
    plural: {
      nominative: 'откованные',
      genitive: 'откованных',
      dative: 'откованным',
      accusative: 'откованных',
      instrumental: 'откованными',
      prepositional: 'откованных',
    },
  },
}
