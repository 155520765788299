const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_OGRE,
} = require('./../../../../creatureTypeIdList')
const {
  ogreDescription,
  ogreNote,
} = require('./../../../../textCommonParts')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_OGRE_BOLT_LAUNCHER} = require('./../../../../creatureIdList')
const {GEAR_HIDE_ARMOR} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Огр болтомёт',
  nameEn: 'Ogre Bolt Launcher',
  id: CREATURE_OGRE_BOLT_LAUNCHER,
  description: [
    `Огр болтомёт носит гигантский арбалет — орудие настолько огромное, что оно близко по размерам к баллисте. Огр может заряжать это огромное орудие и выстреливать из него смертельным снарядом также быстро, как дварф, хорошо владеющий своим арбалетом. Болты настолько велики, что некоторые огры способны нести с собой полдюжины за раз, однако, когда у них заканчиваются снаряды, они могут выкорчевать маленькие деревья, либо взять обломок сооружения и метаться им в своих врагов.`,
    ...ogreDescription,
  ],
  note: ogreNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_OGRE,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 217,
  },
  armor: {
    ac: 13,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 16,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 7,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_2,
  actionList: [
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Болтомёт',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 3,
        range: {
          normal: 120,
          max: 480,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 1,
        },
      },
    },
  ],
}
