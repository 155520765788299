const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_FROST_GIANT,
  CREATURE_FROST_GIANT_EVERLASTING_ONE,
  CREATURE_OGRE,
  CREATURE_TROLL,
} = require('./../../../../creatureIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  frostGiantDescriptionList,
  frostGiantEverlastingOneList,
  frostGiantNote,
} = require('./../../../../textCommonParts')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {ABILITY_REGENERATION} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_PATCHWORK} = require('./../../../../armorTypeList')
const {CR_12} = require('./../../../../crList')
const {CREATURE_TYPE_GIANT_FROST} = require('./../../../../creatureTypeIdList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Извечный ледяной великан',
  nameAlt: 'Извечный морозный гигант',
  nameEn: 'Frost Giant Everlasting One',
  id: CREATURE_FROST_GIANT_EVERLASTING_ONE,
  description: [
    ...frostGiantEverlastingOneList,
    ...frostGiantDescriptionList,
  ],
  note: frostGiantNote,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT_FROST,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 134,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_PATCHWORK,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 25,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 24,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_COLD,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_12,
  featureList: [
    {
      name: 'Дополнительные головы',
      description: `У великана есть шанс 25% на наличие дополнительной головы. Если у него больше одной головы, то он получает преимущество на проверки Мудрости (Внимательность) и на испытания от состояний:

* глухота,
* испуг,
* ослепление,
* очарование,
* ошеломление,
* потеря сознания.`,
    },
    {
      id: ABILITY_REGENERATION,
      regen: 10,
      other: `. Если ★СУЩЕСТВО★ получает урон кислотой или огнём, то эта особенность не работает в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только если начинает ход с 0 хитов и не может регенерировать`,
    },
    {
      name: 'Ярость Вапрака',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `Бонусным действием великан может впасть в ярость в начале своего хода. Ярость длится 1 минуту или пока великан не станет недееспособен. В ярости великан имеет следующие преимущества:

* Преимущество на проверки и испытания Силы
* Бонус +4 к урону рукопашных атак оружием.
* Сопротивление к дробящему, колющему и рубящему урону`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Великан делает две атаки _Секирой_.`,
    },
    {
      name: 'Секира',
      // gearId: GEAR_GREATAXE, // TODO: Найти способ использовать gearId
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 3,
              diceType: 12,
              diceBonus: 7,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 3,
              diceType: 12,
              diceBonus: 11,
              comment: ', когда в ярости',
            },
          ],
        ],
      },
    },
    {
      name: 'Камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 11,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 7,
        },
      },
    },
  ],
}
