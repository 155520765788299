const {ABILITY_FEY_ANCESTRY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {PC_CLASS_BARD} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {howlingHatredDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HALF_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ACROBATICS,
  SKILL_DECEPTION,
  SKILL_PERSUASION,
  SKILL_PERFORMANCE,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_RAPIER,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  SPELL_FRIENDS,
  SPELL_PRESTIDIGITATION,
  SPELL_VICIOUS_MOCKERY,
  SPELL_DISGUISE_SELF,
  SPELL_DISSONANT_WHISPERS,
  SPELL_THUNDERWAVE,
  SPELL_INVISIBILITY,
  SPELL_SHATTER,
  SPELL_SILENCE,
  SPELL_NONDETECTION,
  SPELL_SENDING,
  SPELL_TONGUES,
  SPELL_CONFUSION,
  SPELL_DIMENSION_DOOR,
} = require('./../../../../spellIdList')
const {
  LANG_AURAN,
  LANG_COMMON,
  LANG_ELVEN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_AERISI_KALINOTH,
  CREATURE_WINDHARROW,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Ветрорез',
  nameAlt: ['Виндхарроу', 'Харальд Серое Копьё'],
  nameEn: 'Windharrow',
  id: CREATURE_WINDHARROW,
  hasSpoilers: true,
  description: [
    {
      header: 'Ветрорез',
      text: `Ветрорез — меркантильный лунный полуэльф, бабник и бандит, прежде известный как Харальд Серое Копьё, получил своё новое имя от [Аэриси Калинос](CREATURE:${CREATURE_AERISI_KALINOTH}). Пока [Аэриси](CREATURE:${CREATURE_AERISI_KALINOTH}) ещё жила со своей семьей, Харальд очарованием и лестью заслужил её расположение, а когда она сбежала из дома, чтобы стать пророком воздуха, то взяла Ветрореза с собой.

По настоянию [Аэриси](CREATURE:${CREATURE_AERISI_KALINOTH}) Ветрорез набрал из служителей культа группу флейтистов, получившую название «Судьбы ветра». У большинства её участников нет вообще никакого таланта, и их музыка часто звучит как какофония. Из всех культистов воздуха «Судьбы ветра» наименее фанатичны и больше всех боятся умереть. [Аэриси](CREATURE:${CREATURE_AERISI_KALINOTH}) избавляется от менестрелей по своей прихоти, заменяя их новыми посвящёнными. Те, кто смог хоть как-то овладеть игрой на своём инструменте, живут подольше, но высокая плата за ошибку создаёт тяжёлую атмосферу вечного соревнования среди музыкантов.

Ветрорез предан [Аэриси Калинос](CREATURE:${CREATURE_AERISI_KALINOTH}) только потому, что боится её силы. Если его жизнь будет под угрозой или он получит более выгодное предложение, Ветрорез не раздумывая предаст культ.`,
      source: {
        id: SOURCE_PotA,
        page: 197,
      },
    },
    ...howlingHatredDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HALF_ELF,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 197,
  },
  armor: {
    ac: 15,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 12,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 17,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_PERFORMANCE]: PROF_DOUBLE,
    [SKILL_PERSUASION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_AURAN,
    LANG_ELVEN,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_FEY_ANCESTRY,
  ],
  spellCast: {
    spellCasterLevel: 8,
    spellCasterClass: PC_CLASS_BARD,
    spellIdList: [
      SPELL_CONFUSION,
      SPELL_DIMENSION_DOOR,
      SPELL_DISGUISE_SELF,
      SPELL_DISSONANT_WHISPERS,
      SPELL_FRIENDS,
      SPELL_INVISIBILITY,
      SPELL_NONDETECTION,
      SPELL_PRESTIDIGITATION,
      SPELL_SENDING,
      SPELL_SHATTER,
      SPELL_SILENCE,
      SPELL_THUNDERWAVE,
      SPELL_TONGUES,
      SPELL_VICIOUS_MOCKERY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_RAPIER,
    },
  ],
}
