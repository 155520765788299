const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_GLITCHLING} = require('./../../creatureTypeIdList')
const {SOURCE_UA_2022_07_18_WotM} = require('./../../sourceList')

module.exports = {
  id: CREATURE_TYPE_GLITCHLING,
  nameEn: 'Glitchling',
  nameAlt: [
    'Глючные',
    'Глюкеры',
    'Сбойники',
  ],
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'глюклинг',
      genitive: 'глюклинга',
      dative: 'глюклингу',
      accusative: 'глюклинга',
      instrumental: 'глюклингом',
      prepositional: 'глюклинге',
    },
    plural: {
      nominative: 'глюклинги',
      genitive: 'глюклингов',
      dative: 'глюклингам',
      accusative: 'глюклингов',
      instrumental: 'глюклингами',
      prepositional: 'глюклингах',
    },
  },
  description: [
    {
      text: `Созданные силами планарного порядка, глюклинги — это крылатые человекоподобные существа, слияния магии и машин. Эти исследователи стремятся узнать всё, что могут, о мультивселенной и населяющих её существах. Некоторые глюклинги затем приносят всё, что узнают, обратно на планы порядка (такие как Механус), информируя их о порядке работы мультивселенной. Других глюклингов очаровывают путешествия и встреченные личности, и они стремятся устроить собственную жизнь среди планов.

В начале своего существования личности глюклингов рудиментарны. По мере того, как они впитывают информацию и опыт, их личности растут, но многие из них имеют причуды, связанные с влиянием принципиальных планов их происхождения. Вы выбрать причуду из таблицы причуд жителей планов порядка или создать свою собственную.`,
      source: {
        id: SOURCE_UA_2022_07_18_WotM,
        page: 1,
      },
    },
    {
      header: 'Причуды жителей планов порядка',
      text: `
| к6 | Причуда |
|----|---------|
| 1  | Я коллекционирую вещи, например определенный вид предметов или вещи из определённого материала. |
| 2  | Мы думаем о себе как о части коллективных сил порядка мультивселенной и не относимся к себе как к отдельному существу. |
| 3  | Я ищу новые впечатления, чтобы лучше понять свободу своего существования. |
| 4  | Я предваряю то, что говорю, кратким описанием, например: _«наблюдение»_, _«восклицание»_ или _«вопрос»_. |
| 5  | Я подготавливаю тщательно продуманные ответы на каждый случай, и могу исполнить их в любой момент. |
| 6  | Я исследую намерения людей, стоящие за их действиями, пытаясь вычислить формулы, которыми они управляются. |
`,
      source: {
        id: SOURCE_UA_2022_07_18_WotM,
        page: 2,
      },
    },
  ],
}
