const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1_4,
  } = require('./../../../../crList'),
  {
    SIZE_TINY,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_FEY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NG,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    LANG_SYLVAN,
  } = require('./../../../../languageIdList'),
  {
  } = require('./../../../../senseList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_CONFUSION,
    SPELL_DANCING_LIGHTS,
    SPELL_DETECT_EVIL_AND_GOOD,
    SPELL_DETECT_THOUGHTS,
    SPELL_DISPEL_MAGIC,
    SPELL_DRUIDCRAFT,
    SPELL_ENTANGLE,
    SPELL_FLY,
    SPELL_PHANTASMAL_FORCE,
    SPELL_POLYMORPH,
    SPELL_SLEEP,
  } = require('./../../../../spellIdList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    ABILITY_MAGIC_RESISTANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PC_RACE_ELF,
    PC_RACE_GNOME,
  } = require('./../../../../pcRaceIdList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_CAT,
    CREATURE_PIXIE,
    CREATURE_SPRITE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Пикси',
  nameEn: 'Pixie',
  id: CREATURE_PIXIE,
  description: [
    {
      header: 'Пикси',
      text: `Имеющие рост около 30 сантиметров, пикси напоминают крохотных [эльфов](PC_RACE:${PC_RACE_ELF}) с тонкими крылышками как у стрекоз или бабочек, яркими, как ясный рассвет, и блестящими, как восход полной луны. Любопытные как [кошки](CREATURE:${CREATURE_CAT}) и пугливые как лань, пикси идут туда, куда им хочется. Им нравится шпионить за существами, и они с трудом сдерживают охватывающее их возбуждение. Они испытывают непреодолимое желание представиться и завязать дружбу; только страх быть атакованными или схваченными сдерживает их. Оказавшийся на поляне пикси незнакомец может никогда не увидеть их, но при этом слышать хихиканье, стоны и вздохи.

Пикси наряжаются как принцессы и принцы фей, одевая струящиеся платья и дублеты из шелка, которые искрятся как лунный свет на пруду. Некоторые одежды украшены желудями, листьями, корой и шкурками маленьких лесных зверей. Они гордятся своими регалиями и светятся от счастья, когда их туалет удосуживается похвалы.`,
      source: {
        id: SOURCE_MM,
        page: 235,
      },
    },
    {
      header: 'Волшебный народец',
      text: `С их врождённой способностью быть незаметными, пикси редко появляются на глаза, если только не захотят быть увиденными. В Стране Фей и на Материальном плане пикси рисуют морозные узоры на зимних прудах и пробуждают почки растений весной. Блеск цветов от летней росы и смена окраса листьев осенью — также работа их рук.`,
      source: {
        id: SOURCE_MM,
        page: 235,
      },
    },
    {
      header: 'Пыльца пикси',
      text: `Если пикси летят, будучи видимыми, за ними тянется шлейф блестящей пыльцы, подобный сверкающему хвосту метеора. Осыпание пыльцой пикси, как говорят, может даровать способность летать, безнадёжно запутать существо или усыпить врага волшебным сном. Только пикси могут использовать весь потенциал своей пыльцы, но за ними постоянно ведут охоту маги и чудовища, желающие изучить или заполучить их силу.`,
      source: {
        id: SOURCE_MM,
        page: 235,
      },
    },
    {
      header: 'Крошечные проказники',
      text: `Появление незнакомца возбуждает их любопытство, но пикси очень пугливы, чтобы обнаружить себя первыми. Они наблюдают за посетителем издалека, изучая его характер или устраивая безвредные пакости, дабы увидеть реакцию. Например, пикси могут связать ботинки [гнома](PC_RACE:${PC_RACE_GNOME}), создать иллюзию странного существа или сокровища, или использовать [Пляшущие огоньки](SPELL:${SPELL_DANCING_LIGHTS}) для введения чужака в заблуждение. Если визитёр отреагирует негативно, пикси посторонятся его. Если же он доброй натуры, то пикси станут смелее и доброжелательнее. Они даже могут раскрыть себя и показать своим «гостям» безопасный путь или же пригласят на маленький, но сытный пир, приготовленный в их честь.`,
      source: {
        id: SOURCE_MM,
        page: 235,
      },
    },
    {
      header: 'Противники насилия',
      text: `В отличие от их родственников, [спрайтов](CREATURE:${CREATURE_SPRITE}), пикси гнушаются оружия и скорее спасутся бегством, чем вступят в физическую схватку с врагом.`,
      source: {
        id: SOURCE_MM,
        page: 235,
      },
    },
  ],
  note: {
    text: `Платья из лепестков и шапочки из желудей вышли из моды прошлым летом!`,
    author: `Риверглим, пикси-модница`,
  },
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_NG,
  source: {
    id: SOURCE_MM,
    page: 235,
  },
  armor: 15,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 2,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 8,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  languageList: [
    LANG_SYLVAN,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentInstead: 'пыльцу пикси',
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DRUIDCRAFT,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONFUSION,
          SPELL_DANCING_LIGHTS,
          SPELL_DETECT_EVIL_AND_GOOD,
          SPELL_DETECT_THOUGHTS,
          SPELL_DISPEL_MAGIC,
          SPELL_ENTANGLE,
          SPELL_FLY,
          SPELL_PHANTASMAL_FORCE,
          SPELL_POLYMORPH,
          SPELL_SLEEP,
        ],
      },
    ],
  },
  actionList: [
    {
      name: `Превосходная невидимость`,
      description: `★СУЩЕСТВО★ магическим образом становится невидимым, пока не окончится концентрация (как при концентрации на заклинании). Всё снаряжение, которое ★СУЩЕСТВО★ несёт или носит, становится невидимым вместе с ★ним★.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
