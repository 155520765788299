const {SOURCE_VGTM} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_DRAKE} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_DRAKE,
  nameEn: 'Drake',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  description: {
    text: `Сторожевой дрейк это рептилия, созданная из чешуи дракона с помощью странного и жуткого ритуала. После должного обучения дрейк становится послушным, верным и отличным сторожевым зверем, который может выполнять простые команды.`,
    source: {
      id: SOURCE_VGTM,
      page: 194,
    },
  },
  name: {
    singular: {
      nominative: 'дрейк',
      genitive: 'дрейка',
      dative: 'дрейку',
      accusative: 'дрейка',
      instrumental: 'дрейком',
      prepositional: 'дрейке',
    },
    plural: {
      nominative: 'дрейки',
      genitive: 'дрейков',
      dative: 'дрейкам',
      accusative: 'дрейков',
      instrumental: 'дрейками',
      prepositional: 'дрейках',
    },
  },
}
