const {
  SPELL_AID,
  SPELL_ANIMATE_DEAD,
  SPELL_ANTIMAGIC_FIELD,
  SPELL_ASTRAL_PROJECTION,
  SPELL_AUGURY,
  SPELL_AURA_OF_LIFE,
  SPELL_AURA_OF_PURITY,
  SPELL_AURA_OF_VITALITY,
  SPELL_BANE,
  SPELL_BANISHMENT,
  SPELL_BEACON_OF_HOPE,
  SPELL_BESTOW_CURSE,
  SPELL_BLADE_BARRIER,
  SPELL_BLESS,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CALM_EMOTIONS,
  SPELL_CEREMONY,
  SPELL_CLAIRVOYANCE,
  SPELL_COMMAND,
  SPELL_COMMUNE,
  SPELL_CONJURE_CELESTIAL,
  SPELL_CONTAGION,
  SPELL_CONTINUAL_FLAME,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WEATHER,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_CREATE_UNDEAD,
  SPELL_CURE_WOUNDS,
  SPELL_DAWN,
  SPELL_DAYLIGHT,
  SPELL_DEATH_WARD,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINATION,
  SPELL_DIVINE_WORD,
  SPELL_EARTHQUAKE,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENHANCE_ABILITY,
  SPELL_ETHEREALNESS,
  SPELL_FAST_FRIENDS,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_THE_PATH,
  SPELL_FIND_TRAPS,
  SPELL_FIRE_STORM,
  SPELL_FLAME_STRIKE,
  SPELL_FORBIDDANCE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GATE,
  SPELL_GEAS,
  SPELL_GENTLE_REPOSE,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_GUIDANCE,
  SPELL_GUIDING_BOLT,
  SPELL_GUIDING_HAND_2017_04_03,
  SPELL_HALLOW,
  SPELL_HARM,
  SPELL_HEAL,
  SPELL_HEALING_WORD,
  SPELL_HEROES_FEAST,
  SPELL_HOLD_PERSON,
  SPELL_HOLY_AURA,
  SPELL_HOLY_WEAPON,
  SPELL_INCITE_GREED,
  SPELL_INFLICT_WOUNDS,
  SPELL_INSECT_PLAGUE,
  SPELL_LEGEND_LORE,
  SPELL_LESSER_RESTORATION,
  SPELL_LIFE_TRANSFERENCE,
  SPELL_LIGHT,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_MAGIC_CIRCLE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MASS_HEAL,
  SPELL_MASS_HEALING_WORD,
  SPELL_MELD_INTO_STONE,
  SPELL_MENDING,
  SPELL_MOTIVATIONAL_SPEECH,
  SPELL_OTHERWORLDLY_FORM_2020_03_26,
  SPELL_PLANAR_ALLY,
  SPELL_PLANAR_BINDING,
  SPELL_PLANE_SHIFT,
  SPELL_POWER_WORD_HEAL,
  SPELL_PRAYER_OF_HEALING,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_RAISE_DEAD,
  SPELL_REGENERATE,
  SPELL_REMOVE_CURSE,
  SPELL_RESISTANCE,
  SPELL_RESURRECTION,
  SPELL_REVIVIFY,
  SPELL_SACRED_FLAME,
  SPELL_SANCTUARY,
  SPELL_SCRYING,
  SPELL_SENDING,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SILENCE,
  SPELL_SPARE_THE_DYING,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_SPIRIT_SHROUD,
  SPELL_SPIRIT_SHROUD_2020_03_26,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_STONE_SHAPE,
  SPELL_SUMMON_CELESTIAL,
  SPELL_SUMMON_CELESTIAL_SPIRIT_2020_03_26,
  SPELL_SUNBEAM,
  SPELL_SUNBURST,
  SPELL_SYMBOL,
  SPELL_TEMPLE_OF_THE_GODS,
  SPELL_THAUMATURGY,
  SPELL_TOLL_THE_DEAD,
  SPELL_TONGUES,
  SPELL_TRUE_RESURRECTION,
  SPELL_TRUE_SEEING,
  SPELL_VIRTUE_2017_04_03,
  SPELL_WARDING_BOND,
  SPELL_WATER_WALK,
  SPELL_WORD_OF_RADIANCE,
  SPELL_WORD_OF_RECALL,
  SPELL_ZONE_OF_TRUTH,
} = require('./../../spellIdList')

module.exports = [
  SPELL_AID,
  SPELL_ANIMATE_DEAD,
  SPELL_ANTIMAGIC_FIELD,
  SPELL_ASTRAL_PROJECTION,
  SPELL_AUGURY,
  SPELL_AURA_OF_LIFE,
  SPELL_AURA_OF_PURITY,
  SPELL_AURA_OF_VITALITY,
  SPELL_BANE,
  SPELL_BANISHMENT,
  SPELL_BEACON_OF_HOPE,
  SPELL_BESTOW_CURSE,
  SPELL_BLADE_BARRIER,
  SPELL_BLESS,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CALM_EMOTIONS,
  SPELL_CEREMONY,
  SPELL_CLAIRVOYANCE,
  SPELL_COMMAND,
  SPELL_COMMUNE,
  SPELL_CONJURE_CELESTIAL,
  SPELL_CONTAGION,
  SPELL_CONTINUAL_FLAME,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WEATHER,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_CREATE_UNDEAD,
  SPELL_CURE_WOUNDS,
  SPELL_DAWN,
  SPELL_DAYLIGHT,
  SPELL_DEATH_WARD,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINATION,
  SPELL_DIVINE_WORD,
  SPELL_EARTHQUAKE,
  SPELL_ENCODE_THOUGHTS,
  SPELL_ENHANCE_ABILITY,
  SPELL_ETHEREALNESS,
  SPELL_FAST_FRIENDS,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_THE_PATH,
  SPELL_FIND_TRAPS,
  SPELL_FIRE_STORM,
  SPELL_FLAME_STRIKE,
  SPELL_FORBIDDANCE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GATE,
  SPELL_GEAS,
  SPELL_GENTLE_REPOSE,
  SPELL_GENTLE_REPOSE,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_GUIDANCE,
  SPELL_GUIDING_BOLT,
  SPELL_GUIDING_HAND_2017_04_03,
  SPELL_HALLOW,
  SPELL_HARM,
  SPELL_HEAL,
  SPELL_HEALING_WORD,
  SPELL_HEROES_FEAST,
  SPELL_HOLD_PERSON,
  SPELL_HOLY_AURA,
  SPELL_HOLY_WEAPON,
  SPELL_INCITE_GREED,
  SPELL_INFLICT_WOUNDS,
  SPELL_INSECT_PLAGUE,
  SPELL_LEGEND_LORE,
  SPELL_LESSER_RESTORATION,
  SPELL_LIFE_TRANSFERENCE,
  SPELL_LIGHT,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_MAGIC_CIRCLE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MASS_HEAL,
  SPELL_MASS_HEALING_WORD,
  SPELL_MELD_INTO_STONE,
  SPELL_MENDING,
  SPELL_MOTIVATIONAL_SPEECH,
  SPELL_OTHERWORLDLY_FORM_2020_03_26,
  SPELL_PLANAR_ALLY,
  SPELL_PLANAR_BINDING,
  SPELL_PLANE_SHIFT,
  SPELL_POWER_WORD_HEAL,
  SPELL_PRAYER_OF_HEALING,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_RAISE_DEAD,
  SPELL_REGENERATE,
  SPELL_REMOVE_CURSE,
  SPELL_RESISTANCE,
  SPELL_RESURRECTION,
  SPELL_REVIVIFY,
  SPELL_SACRED_FLAME,
  SPELL_SANCTUARY,
  SPELL_SCRYING,
  SPELL_SENDING,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SILENCE,
  SPELL_SPARE_THE_DYING,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_SPIRIT_SHROUD,
  SPELL_SPIRIT_SHROUD_2020_03_26,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_STONE_SHAPE,
  SPELL_SUMMON_CELESTIAL,
  SPELL_SUMMON_CELESTIAL_SPIRIT_2020_03_26,
  SPELL_SUNBEAM,
  SPELL_SUNBURST,
  SPELL_SYMBOL,
  SPELL_TEMPLE_OF_THE_GODS,
  SPELL_THAUMATURGY,
  SPELL_TOLL_THE_DEAD,
  SPELL_TONGUES,
  SPELL_TRUE_RESURRECTION,
  SPELL_TRUE_SEEING,
  SPELL_VIRTUE_2017_04_03,
  SPELL_WARDING_BOND,
  SPELL_WATER_WALK,
  SPELL_WORD_OF_RADIANCE,
  SPELL_WORD_OF_RECALL,
  SPELL_ZONE_OF_TRUTH,
]
