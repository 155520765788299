const {CAT_CONTAINERS} = require('./../../../../gearCategoryList')
const {CREATURE_WATER_WEIRD} = require('./../../../../creatureIdList')
const {GEAR_BACKPACK} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {MAGIC_ITEM_WEIRD_TANK} = require('./../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_WEIRD_TANK,
  name: 'Резервуар с аномалией',
  nameEn: 'Weird Tank',
  type: MGC_TYPE_WONDROUS_ITEM,
  gearCategory:  CAT_CONTAINERS,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Резервуар с аномалией — это сосуд объёмом 10 галлонов (около 38 литров), сделанный из дутого стекла c крышками из чеканной бронзы. К нему приделаны ремни из жёсткой кожи, благодаря чему его можно носить на спине как [рюкзак](GEAR:${GEAR_BACKPACK}). Внутри резервуара содержится [водная аномалия](CREATURE:${CREATURE_WATER_WEIRD}). Нося резервуар, Вы можете действием открыть его, позволив ей выбраться наружу. Она связана с резервуаром и будет действовать сразу после Вас в порядке инициативы.

Нося резервуар, Вы можете телепатически отдавать этой [водной аномалии](CREATURE:${CREATURE_WATER_WEIRD}) команды (действие не требуется). Вы можете действием закрыть резервуар, но только если до этого приказали [аномалии](CREATURE:${CREATURE_WATER_WEIRD}) вернуться в резервуар, иначе она погибнет.

Если [водная аномалия](CREATURE:${CREATURE_WATER_WEIRD}) умирает, резервуар теряет магические свойства до тех пор, пока будет перезаряжен внутри стихийного узла воды. Перезарядка занимает 24 часа, и после неё внутри резервуара появляется новая [водная аномалия](CREATURE:${CREATURE_WATER_WEIRD}).

Резервуар имеет КД 15, 50 хитов, уязвимость к дробящему урону и иммунитет к яду и психическому урону. Если хиты резервуара опустить до 0, он уничтожается вместе с [водной аномалией](CREATURE:${CREATURE_WATER_WEIRD}) внутри.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PotA,
    page: 227,
  },
}
