const {GENDER_FEMALE} = require('./../../genderList')
const {CREATURE_TYPE_NAGPA} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_NAGPA,
  nameEn: 'Nagpa',
  genderId: GENDER_FEMALE,
  name: {
    singular: {
      nominative: 'нагпа',
      genitive: 'нагпы',
      dative: 'нагпе',
      accusative: 'нагпу',
      instrumental: 'нагпой',
      prepositional: 'нагпе',
    },
    plural: {
      nominative: 'нагпы',
      genitive: 'нагп',
      dative: 'нагпам',
      accusative: 'нагп',
      instrumental: 'нагпами',
      prepositional: 'нагпах',
    },
  },
}
