const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../magicItemTypeList')
const {gearCollection} = require('./../../../gearList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    gearId,
    id,
  }
) => {
  const {
    name,
    nameEn: gearNameEn,
    genderId,
  } = gearCollection[gearId]

  const {
    adjective,
    pronoun,
    verb,
  } = {
    [GENDER_MALE]: {
      adjective: 'Сверкающий',
      pronoun: 'Этот',
      verb: 'покрывается',
    },
    [GENDER_FEMALE]: {
      adjective: 'Сверкающая',
      pronoun: 'Эта',
      verb: 'покрывается',
    },
    [GENDER_MIDDLE]: {
      adjective: 'Сверкающее',
      pronoun: 'Это',
      verb: 'покрывается',
    },
    [GENDER_MULTIPLE]: {
      adjective: 'Сверкающие',
      pronoun: 'Эти',
      verb: 'покрываются',
    },
  }[genderId]

  return {
    id,
    name: `${adjective} ${name.toLowerCase()}`,
    nameEn: `${gearNameEn} of Gleaming`,
    type: MGC_TYPE_ARMOR,
    gearType: gearId,
    rarity: MGC_RARITY_COMMON,
    description: `${pronoun} ${name.toLowerCase()} никогда не ${verb} грязью.`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  }
}
