const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {CONDITION_RESTRAINED} = require('./../../../../../conditionList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_RAVENOUS_VOID} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_RAVENOUS_VOID,
  name: 'Хищная пустота',
  nameEn: 'Ravenous Void',
  description: `Вы создаёте сферу разрушительной гравитации радиусом 20 футов с центром в точке, видимой Вами в пределах дальности. На всё время действия заклинания сфера и всё пространство в пределах 100 футов от неё становится труднопроходимой местностью и все немагические предметы, которые никто не несёт и не носит, целиком находящиеся внутри сферы, уничтожаются. 

Когда сфера появляется и в начале каждого Вашего хода, пока заклинание действует, все незакреплённые объекты в пределах 100 футов от сферы притягиваются к её центру, останавливаясь в свободном пространстве так близко к её центру, как только возможно. 

Существо, начинающее свой ход в пределах 100 футов от сферы, должно пройти испытание Силы и его притянет к центру сферы, останавливаясь в свободном пространстве так близко к её центру, как только возможно. Существо, попадающее в сферу впервые за ход или начинающее там свой ход получает 5к10 урона силовым полем и обездвиживается на всё время, пока находится в сфере. Существо может действием совершить проверку Силы, оканчивая обездвиженное состояние на себе или на другом существе в сфере, до которого может достать. Существо, чьи хиты опускаются до 0 этим заклинанием, уничтожается, вместе со всеми несомыми и носисыси им немагическими предметами.`,
  lvl: 9,
  magicSchoolId: MAGIC_EVOCATION,
  range: 1000,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `небольшая девятиконечная звезда из железа`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  isDunamisBased: true,
  effect: {
    savethrowParam: PARAM_STR,
    condition: CONDITION_RESTRAINED,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 5,
      diceType: 10,
    },
  },
  source: {
    id: SOURCE_EGTW,
    page: 189,
  },
}
