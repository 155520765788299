const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_XGTE,
  SOURCE_ERRATA_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {SPELL_LIFE_TRANSFERENCE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_LIFE_TRANSFERENCE,
  name: 'Передача жизни',
  nameEn: 'Life Transference',
  description: `Вы жертвуете своим здоровьем, чтобы излечить травмы другого существа. Вы получаете 4к8 некротического урона, который не может быть уменьшен никаким способом, и одно существо по Вашему выбору, которое Вы видите в пределах дистанции, восстанавливает хиты в количестве, равном удвоенному полученному Вами урону.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку заклинания 4 круга или выше, урон увеличивается на 1к8 для каждого уровня ячейки выше 3.`,
  lvl: 3,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    damage: {
      type: DAMAGE_NECROTIC,
      diceCount: 4,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 160,
    },
    {
      id: SOURCE_ERRATA_XGTE,
      page: 1,
    },
  ],
}
