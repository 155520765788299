const listToCollectionById = require('./../utils/listToCollectionById')

const POISON_TYPE_CONTACT = 'contact'
const POISON_TYPE_INGESTED = 'ingested'
const POISON_TYPE_INJURY = 'injury'
const POISON_TYPE_INHALED = 'inhaled'

const poisonTypeList = [
  {
    id: POISON_TYPE_CONTACT,
    name: 'Контактный',
    description: `Контактный яд можно нанести на предмет, и он остаётся активным, пока его не коснутся или не смоют. Существо, коснувшееся контактного яда голой кожей, подвергается его воздействию.`,
  },
  {
    id: POISON_TYPE_INGESTED,
    name: 'Поглощаемый',
    description: `Существо должно проглотить полную дозу поглощаемого яда, чтобы подвергнуться его воздействию. Эта доза может быть подмешана в еду или питьё. Вы можете решить, что частичная доза будет обладать ослабленным эффектом, например, позволит проходить испытание с преимуществом, или будет причинять только половину урона при провале испытания.`,
  },
  {
    id: POISON_TYPE_INJURY,
    name: 'Оружейный',
    description: `Оружейный яд наносится на оружие, боеприпасы, компоненты ловушек и другие предметы, причиняющие колющий или рубящий урон, и остаётся активным, пока не попадёт в рану или не будет смыт. Существо, получившее колющий или рубящий урон от оружия или боеприпаса, покрытого оружейным ядом, подвергается его воздействию.`,
  },
  {
    id: POISON_TYPE_INHALED,
    name: 'Вдыхаемый',
    description: `Эти яды — порошки или газы, подвергающие воздействию, когда их вдыхают. Выдувание порошка или выпускание газа подвергает действию яда существ, находящихся в 5-футовом кубе. Получившееся в результате облако мгновенно исчезает. Задержка дыхание не спасает от вдыхаемых ядов, так как они действуют на носовые пазухи, слёзные каналы и другие части тела.`,
  },
]

module.exports = poisonTypeList

module.exports.poisonTypeCollection = listToCollectionById(poisonTypeList)

module.exports.POISON_TYPE_CONTACT = POISON_TYPE_CONTACT
module.exports.POISON_TYPE_INGESTED = POISON_TYPE_INGESTED
module.exports.POISON_TYPE_INJURY = POISON_TYPE_INJURY
module.exports.POISON_TYPE_INHALED = POISON_TYPE_INHALED
