const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ACROBATICS,
  SKILL_INSIGHT,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ABILITY_UNARMORED_DEFENSE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_MARTIAL_ARTS_ADEPT} = require('./../../../../creatureIdList')
const {GEAR_DART} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Адепт боевых искусств',
  nameEn: 'Martial Arts Adept',
  id: CREATURE_MARTIAL_ARTS_ADEPT,
  description: `Адепты боевых искусств — дисциплинированные монахи, хорошо обученные рукопашному бою. Одни защищают монастыри; другие путешествуют по миру, ища просветление или новый вид мастерства в бою. Некоторые становятся телохранителями, обменивая своё боевое мастерство и лояльность на еду и жильё.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 211,
  },
  armor: 16,
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 13,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  languageList: [
    LANG_ANY,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_UNARMORED_DEFENSE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Адепт совершает три _Безоружных удара_ или три атаки _Дротиком_.`,
    },
    {
      name: 'Безоружный удар',
      description: `Если цель является существом, адепт может выбрать один из следующих дополнительных эффектов:

* Цель должна пройти испытание Силы СЛ 13, иначе она роняет один носимый предмет на выбор адепта.
* Цель должна пройти испытание Ловкости СЛ 13 или будет сбита с ног.
* Цель должна пройти испытание Телосложения СЛ 13, или станет ошеломлённой до конца следующего хода адепта.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_DART,
    },
  ],
  reactionList: [
    {
      name: 'Отражение снарядов',
      description: `В ответ на попадание по нему атакой дальнобойным оружием, адепт отражает снаряд. Урон от этой атаки уменьшается на 1к10 + 3. Если урон уменьшился до 0, адепт поймал снаряд, если он достаточно мал, чтобы держать его одной рукой, и у адепта есть свободная рука.`,
    },
  ],
}
