const {
  SOURCE_FTD,
} = require('./../../../../sourceList')
const {
  GEAR_LIGHT_HAMMER,
} = require('./../../../../gearIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../magicItemRarityList')
const {
  MAGIC_ITEM_PLATINUM_SCARF,
  MAGIC_ITEM_SHIELD_1,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_PLATINUM_SCARF,
  name: `Платиновый шарф`,
  nameEn: `Platinum Scarf`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  note: {
    text: `Если Вы окажетесь владельцем платинового шарфа, будьте готовы к побочным эффектам: поворотам голов, вздохам восхищения и множеству комплиментов`,
    author: `Физбан`,
  },
  description: `Этот шарф изготовлен из прочной ткани и покрыт чешуйками платинового цвета.

Вы можете действием вытащить из шарфа чешуйку и произнести командное слово. При этом выберите один из следующих эффектов:

# Дыхание жизни

Чешуйка исчезает, и Вы или существо, которого Вы касаетесь, восстанавливает 10к4 хитов.

# Платиновый щит

В течение 1 часа или до тех пор, пока Вы это не прекратите (действий не требуется), чешуйка становится [щитом +1](MAGIC_ITEM:${MAGIC_ITEM_SHIELD_1}), который можете использовать Вы или другие существа. Носящее щит существо получает иммунитет к урону излучением.

# Сияющий молот

На 1 час или до тех пор, пока Вы это не прекратите (действий не требуется), чешуйка становится магическим [лёгким молотом](GEAR:${GEAR_LIGHT_HAMMER}), который можете использовать Вы или другое существо. Сияющий молот наносит 2К4 урона излучением вместо обычного дробящего урона для [легкого молота](GEAR:${GEAR_LIGHT_HAMMER}). Он наносит цветным драконам дополнительно 2к4 урона излучением.

После того, как с шарфа были сняты три чешуйки, чешуйки нельзя будет снимать до следующего рассвета, когда все отсутствующие чешуйки отрастут снова. Если Вы снимаете чешуйку, но не произносите командного слова, она исчезает через 1 минуту.`,
  source: {
    id: SOURCE_FTD,
    page: 28,
  },
}
