const {
  CREATURE_DECEITFUL_FEY_SPIRIT_2020_03_26,
  CREATURE_JOYFUL_FEY_SPIRIT_2020_03_26,
  CREATURE_MIRTHFUL_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух радостной феи',
  nameEn: 'Joyful Fey Spirit',
  id: CREATURE_JOYFUL_FEY_SPIRIT_2020_03_26,
  releasedAs: CREATURE_MIRTHFUL_SPIRIT,
  parentId: CREATURE_DECEITFUL_FEY_SPIRIT_2020_03_26,
  featureList: [
    {
      name: 'Фейский шаг',
      description: `Бонусным действием ★СУЩЕСТВО★ может магически телепортироваться в свободное пространство, видимое им в пределах 30 футов.`,
    },
    {
      name: 'Восторженный шаг',
      description: `Немедленно после использования _Фейского шага_, ★СУЩЕСТВО★ может выбрать существо, видимое им в пределах 10 футов и заставить его пройти испытание Мудрости против сложности заклинаний заклинателя или стать очарованным на 1 минуту. Очарование заканчивается, если ★СУЩЕСТВО★ или его спутники наносят цели урон.`,
    },
  ],
}
