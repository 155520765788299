const {CREATURE_TYPE_HALF_ELF} = require('./../../creatureTypeIdList')
const {uncommonRaces} = require ('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  PC_RACE_ELF,
  PC_RACE_HUMAN,
} = require ('./../../pcRaceIdList')
const {
  SOURCE_MTOF,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../sourceList')
const {
  GOD_AURIL,
  GOD_AZUTH,
  GOD_CHAUNTEA,
  GOD_CORELLON_LARETHIAN,
  GOD_ELDATH,
  GOD_EREVAN_ILESERE,
  GOD_HANALI_CELANIL,
  GOD_ILMATER,
  GOD_MIELIKKI,
  GOD_MILIL,
  GOD_MYSTRA,
  GOD_RILLIFANE_RALLATHIL,
  GOD_SELUNE,
  GOD_SILVANUS,
  GOD_SOLONOR_THELANDIRA,
  GOD_SUNE,
} = require ('./../../godIdList')

module.exports = {
  id: CREATURE_TYPE_HALF_ELF,
  nameEn: 'Half-elf',
  note: [
    {
      text: `Флинт щурился, вглядываясь против света. Он увидел человека, шедшего к нему по склону. Поднявшись, Флинт отступил в тень высокой сосны, чтобы свет не так бил в глаза. Походка человека была упругой и лёгкой — на эльфийский лад, подумалось Флинту, — но тело выглядело мускулистым и крепким, — черта не вполне эльфийская. Зелёный капюшон мешал разглядеть лицо. Флинт видел только загорелые щёки и рыжевато-русую бороду, какие у эльфов не растут никогда. При бедре у человека висел меч, а на плече — длинный лук. На нём была одежда из мягкой кожи с тиснёным узором. Узор был эльфийским. Но борода? Погодите-ка…

— Танис? — окликнул Флинт неуверенно.

— А кто же ещё! — Бородатая физиономия расплылась в широченной улыбке. Танис сгрёб гнома в охапку, оторвав его от земли. Флинт ответил объятием на объятие, но тут же вспомнил о своём достоинстве и высвободился из рук полуэльфа`,
      author: 'Маргарет Уэйс и Трейси Хикмэн, Драконы осенних сумерек',
    },
    {
      text: `Я не полу-что-то там. Вы, люди, пришли из Сарлоны. Эльфы пришли из Эренала. А я? А я — истинный сын Хорвайра.`,
      author: 'Нандон Там, Хорвайрский архивариус',
    },
  ],
  description: [
    {
      header: 'Полуэльфы',
      text: `Бродящие по двум мирам, но в действительности, не принадлежащие ни одному из них.

Полуэльфы сочетают в себе, как некоторые говорят, лучшие качества обеих рас: [человеческие](PC_RACE:${PC_RACE_HUMAN}) любознательность, изобретательность и амбиции, приправленные изысканными чувствами, любовью к природе и ощущением прекрасного, свойственными [эльфам](PC_RACE:${PC_RACE_ELF}).

Некоторые полуэльфы живут среди [людей](PC_RACE:${PC_RACE_HUMAN}), отгороженные эмоциональными и физическими различиями, наблюдая за старением друзей и возлюбленных, лишь слегка тронутые временем.

Другие живут с [эльфами](PC_RACE:${PC_RACE_ELF}) в неподвластных времени эльфийских королевствах. Они стремительно растут, и достигают зрелости, пока их сверстники ещё остаются детьми.

Многие полуэльфы не способны ужиться ни в одном обществе, и выбирают жизнь одиноких странников или объединяются с другими изгнанниками и неудачниками, чтобы отправиться к приключениям.`,
      source: {
        id: SOURCE_PHB,
        page: 39,
      },
    },
    {
      header: 'Меж двух миров',
      text: `Для [людей](PC_RACE:${PC_RACE_HUMAN}) полуэльфы выглядят [эльфами](PC_RACE:${PC_RACE_ELF}), а для [эльфов](PC_RACE:${PC_RACE_ELF}) они — [люди](PC_RACE:${PC_RACE_HUMAN}).

Их рост примерно посередине между [людьми](PC_RACE:${PC_RACE_HUMAN}) и [эльфами](PC_RACE:${PC_RACE_ELF}), и составляет от 5 до 6 футов (от 155 до 183 сантиметров). Полуэльфы не такие стройные, как [эльфы](PC_RACE:${PC_RACE_ELF}), но и не такие широкоплечие как [люди](PC_RACE:${PC_RACE_HUMAN}). Их вес колеблется между 100 и 180 фунтами (45 и 80 килограммами), и мужчины лишь немного превосходят женщин в росте и весе.

У мужчин полуэльфов на лицах растут волосы, и некоторые отращивают бороды, чтобы скрыть своё эльфийское происхождение. Цвет волос и кожи у них примерно посередине между их людскими и эльфийскими родителями, и таким образом разнообразие даже выше, чем у исходных рас. Цвет глаз они обычно наследуют у эльфийских родителей.`,
      source: {
        id: SOURCE_PHB,
        page: 39,
      },
    },
    {
      header: 'Дипломаты или странники',
      text: `Полуэльфы не имеют собственных государств, но их радушно принимают в людских городах, и чуть менее радушно в эльфийских лесах. В крупных городах, в регионах, где [люди](PC_RACE:${PC_RACE_HUMAN}) и [эльфы](PC_RACE:${PC_RACE_ELF}) живут рядом, численность полуэльфов иногда возрастает настолько, что они организовывают свои собственные маленькие коммуны. Они рады обществу других полуэльфов, единственных существ, которые по-настоящему понимают, каково это, жить меж двух миров.

Впрочем, в большей части мира полуэльфы настолько редки, что могут годами не встречаться друг с другом. Некоторые полуэльфы предпочитают вообще избегать компании, и живут на природе как охотники, лесники или искатели приключений, посещая цивилизованные места лишь изредка. Как и [эльфы](PC_RACE:${PC_RACE_ELF}), благодаря своему долголетию они движимы тягой к перемене мест и нигде не остаются подолгу. Другие же наоборот, бросаются в гущу социальной жизни, используя свою харизму и социальные навыки на поприще дипломата или мошенника.`,
      source: {
        id: SOURCE_PHB,
        page: 39,
      },
    },
    {
      header: 'Превосходные послы',
      text: `Многие полуэльфы учатся ещё в раннем возрасте преодолевать враждебность и находить со всеми общий язык. Как раса они обладают эльфийской грацией без эльфийской отчуждённости, и человеческой энергичностью без человеческой грубости. Из них часто выходят превосходные послы и посредники (за исключением переговоров между людьми и эльфами, так как каждая сторона подозревает полуэльфа в приверженности другой).`,
      source: {
        id: SOURCE_PHB,
        page: 40,
      },
    },
    {
      header: 'Имена полуэльфов',
      text: `Полуэльфы используют и человеческие и эльфийские имена. Как бы подчёркивая, что они в действительности не принадлежат ни одному из этих обществ, полуэльфы, выросшие среди [людей](PC_RACE:${PC_RACE_HUMAN}), получают эльфийские имена, а те, кто вырос среди [эльфов](PC_RACE:${PC_RACE_ELF}), наоборот — берут человеческие. `,
      source: {
        id: SOURCE_PHB,
        page: 40,
      },
    },
    uncommonRaces,
    {
      header: 'Полуэльф, половина души?',
      text: `С эльфийской точки зрения, рождение полуэльфа представляет собой нарушение естественного порядка перерождения. Эльфы в разных сообществах и в разных мирах придерживаются различных идей о природе подобной «аномалии», так как боги так и не дали ответа, который оказался бы приемлемым для всех. Душа полуэльфа может быть эльфийской, чья связь с Селдарин была ослаблена, или она может быть истинно эльфийской, находящийся в ловушке тела полуэльфа до конца своих дней. Также душа, может оказаться «низшей» – например, человеческой.

Многие эльфы, особенно молодые, рассматривают существование полуэльфов не как угрозу, а как знак надежды, как пример того, как эльфийские души могут по-новому познать мир, не привязываясь лишь к одной физической форме или определённой философии.`,
      source: {
        id: SOURCE_MTOF,
        page: 40,
      },
    },
    {
      header: 'Полуэльфы',
      text: `[Эльф](PC_RACE:${PC_RACE_ELF}), смотрящий на полуэльфа, видит в нём [человека](PC_RACE:${PC_RACE_HUMAN}), [человек](PC_RACE:${PC_RACE_HUMAN}), взглянувший на того же полуэльфа, увидит [эльфа](PC_RACE:${PC_RACE_ELF}). И хотя эта характеристика сильно упрощена, она в точности показывает, что значит быть полуэльфом на Фаэруне.

Для [эльфов](PC_RACE:${PC_RACE_ELF}), которые придерживаются радикальной точки зрения на этот счёт, полуэльфы являются олицетворением упадка эльфийской цивилизации, разбавлением расового наследия и культуры, которое однажды приведёт к полному их исчезновению. Для [людей](PC_RACE:${PC_RACE_ELF}), с другой стороны, полуэльфы имеют бесчестное преимущество перед их человеческими сверстниками — полуэльфы всегда выглядят привилегированными и пользуются большими преимуществами, независимо от реальных обстоятельств их рождения.

Для большинства народов Фаэруна, эта проблема не стоит столь остро. Полуэльфы по большинству нормально приемлемы, куда бы они не отправились и где бы ни жили — с той оговоркой, что сообщества, которые не терпят [эльфов](PC_RACE:${PC_RACE_ELF}) или [людей](PC_RACE:${PC_RACE_HUMAN}), скорее всего будут относиться также и к тем, в ком течёт кровь обоих этих народов. В противовес логике, сообщества, которые высоко ценят [людей](PC_RACE:${PC_RACE_HUMAN}) или [эльфов](PC_RACE:${PC_RACE_ELF}) обычно не переносят такое же отношение на полуэльфов (но, впрочем, в то же время их и не отвергают).`,
      source: {
        id: SOURCE_SCAG,
        page: 117,
      },
    },
    {
      header: 'Молодая раса, старые корни',
      text: `В далёком прошлом, полуэльфы были редки из-за не частых контактов [эльфов](PC_RACE:${PC_RACE_ELF}) и [людей](PC_RACE:${PC_RACE_HUMAN}). Значительное число полуэльфов жило в древних эльфийских королевствах Кормантир и Миф Драннор. И только в последнюю тысячу лет или около того, так как расы начали смешиваться всё больше, количество полуэльфов увеличилось настолько, что их можно встретить по всему Фаэруну.

Не удивительно, что полуэльфы с удовольствием находятся в компании себе подобных — так где бы ни собралось двое полуэльфов, рано или поздно к ним обязательно присоединится третий. Большинство полуэльфов Севера и Побережья Мечей являются метисами лунных [эльфов](PC_RACE:${PC_RACE_ELF}) и иллюсканцев или тетирцев. В других частях Фаэруна полуэльфы располагаются значительными общинами по всему лесу Юир и Агларонду. Водные полуэльфы часто могут быть встречены вдоль побережий, таких как побережья близ Агларонда, Импилтура, Сембии, у Побережья Дракона и Вилхонской протоки. Полуэльфы дроу наиболее многочисленны в землях Дамбрата, которые были завоёваны тёмными эльфами несколько лет назад, и в Подземье, где Дом Оусстил из Мензоберранзана в частности печально известен своими союзами с [людьми](PC_RACE:${PC_RACE_HUMAN}).`,
      source: {
        id: SOURCE_SCAG,
        page: 117,
      },
    },
    {
      header: 'Смешанное наследие',
      text: `Полуэльфы сильно разнятся, учитывая количество разнообразных комбинаций эльфийских подрас и человеческих этносов среди них. Большинство из них чаще считают свою двойственную природу благословением, чем помехой, из-за того, что она открывает перед ними набор возможностей и перспектив в мире, которые не доступны чистокровным [людям](PC_RACE:${PC_RACE_HUMAN}) или [эльфам](PC_RACE:${PC_RACE_ELF}).

В тоже время, смешанное наследие полуэльфов диктует им необходимость усилий, чтобы вписаться в среду [людей](PC_RACE:${PC_RACE_HUMAN}) или [эльфов](PC_RACE:${PC_RACE_ELF}), когда это возможно. Например, полуэльфы рождённые и выросшие в человеческих селениях склонны к тому, чтобы иметь человеческие имена, в то время как полуэльфы в эльфийских обществах обычно имеют эльфийские имена. В некоторых местах детей полуэльфов называют в честь «другого» их родителя или смешанным именем, состоящим из человеческого и эльфийского имён, для того чтобы дистанцировать полуэльфов от остальной части их общины.

Полуэльфы владеют и Всеобщим, и Эльфийским языками. Дополнительно, полуэльфы леса Юир обычно говорят на Агларондском.

Полуэльфы Фаэруна имеют расовые черты полуэльфов из Книги Игрока, но также доступны и другие варианты.`,
      source: {
        id: SOURCE_SCAG,
        page: 117,
      },
    },
    {
      header: 'Варианты полуэльфов',
      text: `Некоторые полуэльфы Фаэруна имеют расовые особенности, заменяющие особенность «Универсальность навыков». Если ваш Мастер позволит, Ваш персонаж полуэльф может взять эльфийскую особенность «Острые чувства» или одну из особенностей ниже, основываясь на эльфийском наследии, вместо особенности «Универсальность навыков»:

* Полуэльф лесного происхождения может выбрать «Владение эльфийским оружием», «Быстрые ноги» или «Маска дикой природы».
* Полуэльф лунного или солнечного происхождения может выбрать «Владение эльфийским оружием» или «Заговор».
* Полуэльф происхождения дроу может выбрать «Магию дроу».
* Полуэльф водного происхождения может выбрать скорость плавания 30 футов.`,
      source: {
        id: SOURCE_SCAG,
        page: 117,
      },
    },
    {
      header: 'Боги двух народов',
      text: `У полуэльфов нет своих богов, потому они становятся последователями [людских](PC_RACE:${PC_RACE_HUMAN}) или [эльфийских](PC_RACE:${PC_RACE_ELF}) богов по собственному выбору — несмотря на это, множество полуэльфов верят, что их боги выбрали их. Полуэльфы часто поклоняются богам культуры, в которой они росли, но некоторые восстают против своего воспитания и ищут богов, соответствующих другим аспектам их наследия, почувствовав зов или просто необходимость сделать это.

Как множество людей, полуэльфы часто выбирают себе божество, основываясь на их призвании или профессии: [Кореллон Ларетиан](GOD:${GOD_CORELLON_LARETHIAN}), [Азут](GOD:${GOD_AZUTH}) или [Мистра](GOD:${GOD_MYSTRA}) для волшебников, [Солонор Теландира](GOD:${GOD_SOLONOR_THELANDIRA}) или [Майликки](GOD:${GOD_MIELIKKI}) для следопытов, [Милил](GOD:${GOD_MILIL}) или [Кореллон](GOD:${GOD_CORELLON_LARETHIAN}) для поэтов и бардов, и так далее.

Множество полуэльфов поклоняются [Сьюни](GOD:${GOD_SUNE}) или [Ханали Селанил](GOD:${GOD_HANALI_CELANIL}) отдавая дань уважения любви их родителей друг к другу, а эти два божества считаются добрыми подругами. Некоторых полуэльфов притягивают одиночки, такие как [Ориль](GOD:${GOD_AURIL}), [Эльдат](GOD:${GOD_ELDATH}), [Эреван Илисир](GOD:${GOD_EREVAN_ILESERE}) и [Ильматер](GOD:${GOD_ILMATER}) или боги природы, такие как [Майликки](GOD:${GOD_MIELIKKI}), [Риллифэйн Раллатил](GOD:${GOD_RILLIFANE_RALLATHIL}) или [Сильванус](GOD:${GOD_SILVANUS}). Полуэльфы из Агларонда часто избирают [Чонтею](GOD:${GOD_CHAUNTEA}), [Селунэ](GOD:${GOD_SELUNE}) или одного из Селдарин своим покровителем.`,
      source: {
        id: SOURCE_SCAG,
        page: 117,
      },
    },
  ],
  genderId: GENDER_MALE,
  notRealRace: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'полуэльф',
        genitive: 'полуэльфа',
        dative: 'полуэльфу',
        accusative: 'полуэльфа',
        instrumental: 'полуэльфом',
        prepositional: 'полуэльфе',
      },
      [GENDER_FEMALE]: {
        nominative: 'полуэльфийка',
        genitive: 'полуэльфийки',
        dative: 'полуэльфийке',
        accusative: 'полуэльфийку',
        instrumental: 'полуэльфийкой',
        prepositional: 'полуэльфийке',
      },
    },
    plural: {
      nominative: 'полуэльфы',
      genitive: 'полуэльфов',
      dative: 'полуэльфам',
      accusative: 'полуэльфов',
      instrumental: 'полуэльфами',
      prepositional: 'полуэльфах',
    },
  },
}
