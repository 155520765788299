const listToCollectionById = require('./../utils/listToCollectionById')

const SPEED_CLIMB = 'climb'
const SPEED_DIG = 'dig'
const SPEED_FLY = 'fly'
const SPEED_SWIM = 'swim'
const SPEED_WALK = 'walk'

const speedList = [
  {
    id: SPEED_CLIMB,
    name: 'лазая',
    titleName: 'Скорость лазания',
    description: `Чудовище, имеющее скорость лазания, может использовать часть или всё передвижение для перемещения по вертикальным поверхностям. Чудовищу нет необходимости тратить дополнительное движение для лазания.`,
  },
  {
    id: SPEED_DIG,
    name: 'копая',
    titleName: 'Скорость копания',
    description: `Чудовище, имеющее скорость копания, может использовать её для передвижения через песок, землю, грязь или лёд. Чудовище не может копать сквозь камень, если у него нет особого умения, позволяющего делать это.`,
  },
  {
    id: SPEED_FLY,
    name: 'летая',
    titleName: 'Скорость полёта',
    description: `Чудовище, имеющее скорость полёта, может использовать часть или всё передвижение для полёта. Некоторые чудовища могут парить, отчего их сложнее сбивать на землю. Такие чудовища прекращают парение, когда умирают.`,
  },
  {
    id: SPEED_SWIM,
    name: 'плавая',
    titleName: 'Скорость плавания',
    description: `Чудовище, имеющее скорость плавания, не тратит дополнительное движение при плавании.`,
  },
  {
    id: SPEED_WALK,
    name: '',
    titleName: 'Скорость ходьбы',
    description: `У всех существ есть скорость ходьбы, называемая просто скоростью чудовища. Существа, которые не имеют возможности передвигаться по земле, имеют скорость ходьбы 0 футов.`,
  },
]

module.exports = speedList

module.exports.speedCollection = listToCollectionById(speedList)

module.exports.SPEED_CLIMB = SPEED_CLIMB
module.exports.SPEED_DIG = SPEED_DIG
module.exports.SPEED_FLY = SPEED_FLY
module.exports.SPEED_SWIM = SPEED_SWIM
module.exports.SPEED_WALK = SPEED_WALK
