const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_DURPARI} = require('./../../languageIdList')
const {
  PLACE_DURPAR,
  PLACE_ESTAGUND,
  PLACE_VAR,
  PLACE_VELDORN,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_DURPARI,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Durpari',
  name: {
    nominative: 'Дюрпар',
    genitive: 'Дюрпара',
    instrumental: 'Дюрпаром',
    prepositional: 'Дюрпаре',
  },
  typicalSpeakers: 'Дюрпары',
  spokenPlaceList: [
    PLACE_DURPAR,
    PLACE_ESTAGUND,
    PLACE_VAR,
    PLACE_VELDORN,
  ],
  isReady: false,
  isRealLang: true,
}
