const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_20} = require('./../../../../crList')
const {CREATURE_NIGHTWALKER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPELL_WISH} = require('./../../../../spellIdList')

module.exports = {
  name: 'Ночной ходок',
  nameEn: 'Nightwalker',
  id: CREATURE_NIGHTWALKER,
  description: [
    `Отрицательный план — место, где правят тьма и смерть, погибель для всех живых существ. И тем не менее, есть те, кто готов принять эту мрачную силу, чтобы использовать её для своих зловещих целей. Зачастую подобные личности, приблизившись к полуночному царству, понимают, что задача им не по плечу. Те из них, кто не уничтожается сразу, иногда затягивается внутрь плана, а вместо них выходит ночной ходок — ужасающая нежить, пожирающая всю жизненную энергию, с которой только столкнётся.`,
    {
      header: 'Могучее отродье',
      text: `Попасть в Отрицательный план можно из Царства Теней, точно так же, как и попасть в само Царство Теней можно попасть из Материального плана в том месте, где грань между мирами тонка.

Переход в Отрицательный план равносилен самоубийству, так как в момент перехода из глупцов, осмелившихся на это, в мгновение ока высасывается вся жизнь и душа, после чего их тело уничтожается. Такую участь могут пережить лишь те, на чьей стороне удача, или кто обладает некой редкой формой магии, что защищает их от враждебной атмосферы плана. Тем не менее, вскоре они понимают, что не смогут выйти так же просто, как вошли. Поскольку за каждое существо, что смогло проникнуть в план, из плана вырывается ночной ходок, заменяющий это существо. Чтобы застрявшее существо смогло выйти, необходимо, чтобы кто-то или что-то завлекло вышедшего ходока обратно на Отрицательный План, заманивая его жизнью, которую он сможет пожрать. Если же ночной ходок будет уничтожен, то у застрявшего существа больше нет никаких шансов выбраться наружу.`,
      source: {
        id: SOURCE_MTOF,
        page: 213,
      },
    },
    {
      header: 'Воплощения анти-жизни',
      text: `Можно распознать природу существ, застрявших в Отрицательном Плане по месту обитания ночного ходока. Обычно, ночного ходока в Материальном плане тянет к тем местам, которые связанны с существом, по чьей вине вышел ночной ходок. Такой интерес у ночных ходоков не указывает на их волю взаимодействовать с миром, а наоборот, они существуют ради того, чтобы истребить саму жизнь и никогда не служить живым существам.`,
      source: {
        id: SOURCE_MTOF,
        page: 213,
      },
    },
    {
      header: 'Природа нежити',
      text: `Ночной ходок не нуждается в воздухе, пище, питье или сне.`,
      source: {
        id: SOURCE_MTOF,
        page: 213,
      },
    },
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 213,
  },
  armor: 14,
  hp: {
    diceCount: 22,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 24,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 8,
  },
  saveThrowList: [
    PARAM_CON,
  ],
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_THUNDER,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  cr: CR_20,
  featureList: [
    {
      name: 'Разрушительная аура',
      description: `Каждое существо, начинающее ход в пределах 30 футов от ночного ходока должно пройти испытание Телосложения СЛ 21, иначе оно получит 14 (4к6) урона некротической энергией и даёт ночному ходоку преимущество на атаки по себе до начала своего следующего хода. Нежить невосприимчива к этой ауре.`,
    },
    {
      name: 'Пожиратель жизни',
      description: `Существо, чьи хиты опускаются до 0 от нанесённого ночным ходоком урона, умирает и не может быть воскрешено никакими способами, кроме как заклинанием [Исполнение желаний](SPELL:${SPELL_WISH})`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Ночной ходок использует _Изнуряющую концентрацию_ дважды, или использует _Изнуряющую концентрацию_ и _Перст погибели_, если это возможно.`,
    },
    {
      name: 'Изнуряющая концентрация',
      description: `Цель должна пройти испытание Телосложения СЛ 21, иначе её максимум хитов понизится на количество, равное полученному урону. Это понижение длится до завершения длинного отдыха.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 15,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 5,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Перст погибели',
      restore: 6,
      description: `Ночной ходок выбирает целью одно существо, которое видит в пределах 300 футов. Цель должна пройти испытание Мудрости СЛ 21, иначе получит 26 (4к12) урона некротической энергией, а также станет испуганной до конца следующего хода ночного ходока. Пока цель испугана таким образом, она также парализована. При успешном испытании цель станет невосприимчивой к _Пальцу погибели_ этого ходока в течение 24 часа.`,
    },
  ],
}
