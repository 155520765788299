const {SOURCE_WDH} = require('./../../../../sourceList')
const {waterdeepCityGuardDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_VETERAN,
  CREATURE_WATERDEEP_S_CITY_GUARD_SENIOR_CIVILAR,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Старший цивилар гвардии Глубоководья',
  nameAlt: 'Капитан гвардии Глубоководья',
  nameEn: 'Waterdeep’s city guard, Senior Civilar',
  nameEnAlt: 'Waterdeep’s city guard, Captain',
  id: CREATURE_WATERDEEP_S_CITY_GUARD_SENIOR_CIVILAR,
  parentId: CREATURE_VETERAN,
  description: waterdeepCityGuardDescriptionList,
  source: {
    id: SOURCE_WDH,
    page: 197,
  },
}
