const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  CREATURE_PTERANODON,
  CREATURE_QUETZALCOATLUS,
} = require('./../../../../creatureIdList')
const {ABILITY_FLYBY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {dinoDescriptionList} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')

module.exports = {
  name: 'Кетцалькоатль',
  nameEn: 'Quetzalcoatlus',
  id: CREATURE_QUETZALCOATLUS,
  description: [
    `Этот гигантский родич [птеранодона](CREATURE:${CREATURE_PTERANODON}) имеет размах крыльев более 30 футов. Хотя он может передвигаться на четырёх ногах, гораздо увереннее он себя чувствует в воздухе.`,
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: [
    {
      id: SOURCE_VGTM,
      page: 151,
    },
    {
      id: SOURCE_TOA,
      page: 230,
    },
  ],
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 80,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 13,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  cr: CR_2,
  featureList: [
    {
      name: 'Нырок',
      description: `Если кетцалькоатль летит и ныряет вниз по крайней мере на 30 футов к цели, и потом попадает по ней атакой _Укусом_, то эта атака наносит дополнительно 10 (3к6) урона цели.`,
    },
    ABILITY_FLYBY,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
