const arrify = require('arrify')

const reduceSpellIdList = (list, {spellIdList}) => {
  if (spellIdList) {
    arrify(spellIdList).forEach(
      spell => {
        const id = typeof spell === 'string' ? spell : spell.id

        if (!list.includes(id)) {
          list.push(id)
        }
      }
    )
  }

  return list
}

module.exports = reduceSpellIdList
