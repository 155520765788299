const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_JAVELIN,
  GEAR_QUARTERSTAFF,
  GEAR_QUIVER,
  GEAR_SPEAR,
} = require('./../../../../../gearIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_QUIVER_OF_EHLONNA,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_QUIVER_OF_EHLONNA,
  name: `Колчан Элонны`,
  nameEn: `Quiver of Ehlonna`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_QUIVER,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Все три отделения этого колчана соединены с межпространственным местом, что позволяет ему вмещать множество предметов, но весит он всегда не более 2 фунтов. В самом коротком отделении может поместиться до шестидесяти стрел, арбалетных болтов или подобных предметов. В среднем отделении помещается до восемнадцати [метательных копий](GEAR:${GEAR_JAVELIN}) или подобных предметов. В самом длинном отделении помещается до шести длинных предметов, таких как луки, [боевые посохи](GEAR:${GEAR_QUARTERSTAFF}) и [копья](GEAR:${GEAR_SPEAR}).

Вы можете вынуть любой предмет из колчана, как если бы это был [обычный колчан](GEAR:${GEAR_QUIVER}) или ножны.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 173,
  },
}
