const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {ABILITY_INCORPOREAL_MOVEMENT} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_SPELL} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_ALLIP} = require('./../../../../creatureIdList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Аллип',
  nameEn: 'Allip',
  id: CREATURE_ALLIP,
  description: [
    `Когда кто-то узнаёт секрет, который был защищён могущественным существом при помощи проклятия, может появиться аллип. Секреты, защищённые таким образом, варьируются от истинного имени Лорда Демонов до скрытой сути космического порядка. Аллип знает секрет, но проклятие уничтожает его тело, оставляя только призрачное существо, сотканное из фрагментов души жертвы и переполняющей психической агонии.`,
    {
      header: 'Богохульные секреты',
      text: `Каждого аллипа разрушает ужасающая правда, которая мучает остатки его разума. В присутствии других существ аллип старается облегчить свою ношу и поделиться своим секретом. Аллип может поделиться только фрагментом рокового знания, но и этой части достаточно, чтобы заставить получателя на время обезуметь. Выжившие после нападения аллипа иногда подвергаются импульсу узнать больше о том, что сказало это чудовище. Загадочные фразы отдаются эхом в их сознаниях, и странные видения замещают их сны. Ощущение того, что какое-то колоссальное знание находится совсем рядом, но они просто не могут его вспомнить мучает их днями, месяцами и иногда даже годами после встречи.`,
      source: {
        id: SOURCE_MTOF,
        page: 118,
      },
    },
    {
      header: 'Природа нежити',
      text: `Аллипу не нужен воздух, еда, питьё или сон.`,
      source: {
        id: SOURCE_MTOF,
        page: 118,
      },
    },
    {
      header: 'Коварные знания',
      text: `Аллип может попытаться поделиться своим знанием, чтобы избавиться от проклятия и перейти в посмертие. Аллип может передать знание из своего разума другому существу, заставив его записать всё, что аллип знает. Процесс занимает дни и даже недели. Аллип может достигнуть своей задачи скрываясь в кабинете или мастерской учёного. Если аллип остаётся незамеченным, его жертва переполняется маниакальной энергией. Учёный, которого неожиданные прозрения заставляют работать день и ночь, создаёт целые манускрипты текста, не помня, о чём конкретно он писал. Если аллип преуспевает, то он уходит из мира, и его ужасающий секрет прячется где-то в глубине записей учёного, пока его не обнаружит следующая жертва.`,
      source: {
        id: SOURCE_MTOF,
        page: 118,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 118,
  },
  armor: 13,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 40,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 10,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_THUNDER,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_INCORPOREAL_MOVEMENT,
  ],
  actionList: [
    {
      name: 'Безумное касание',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 4,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Шёпот безумия',
      description: `Аллип выбирает до трёх существ которых он может видеть в пределах 60 футов от него. Каждая цель должна пройти испытание Мудрости СЛ 14 или получить 7 (1к8+3) урона психической энергией и совершить реакцией рукопашную атаку оружием по одному существу по выбору аллипа и которое он видит. Конструкты и нежить невосприимчивы к этому эффекту.`,
    },
    {
      name: 'Ошеломляющий вой',
      restore: 6,
      description: `Каждое существо в пределах 30 футов от аллипа, которое может услышать его, должно пройти испытание Мудрости СЛ 14. При провале испытания цель получает 12 (2к8 + 3) урона психической энергией, и она ошеломлена до конца следующего хода. При успешном испытании он получает вдвое меньше урона и не ошеломлён. Конструкты и нежить невосприимчивы к этому эффекту`,
    },
  ],
}
