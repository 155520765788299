const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  GEAR_BREASTPLATE,
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_SWORD_WRAITH_COMMANDER,
  CREATURE_SWORD_WRAITH_WARRIOR,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_8} = require('./../../../../crList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {swordWraithDescription} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Полководец призраков меча',
  nameEn: 'Sword Wraith Commander',
  id: CREATURE_SWORD_WRAITH_COMMANDER,
  description: swordWraithDescription,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 224,
  },
  armor: {
    ac: 18,
    gearId: [GEAR_BREASTPLATE, GEAR_SHIELD],
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_8,
  featureList: [
    {
      name: 'Смертельная ярость',
      description: `Призрак меча может совершить одну атаку оружием бонусным действием, причиняя ею дополнительно 9 (2к8) урона некротической энергией в случае попадания. Если призрак делает это, то все броски атак против него будут совершаться с преимуществом до начала его следующего хода.`,
    },
    {
      name: 'Изгоняющее неповиновение',
      description: `Полководец призраков меча, и все прочие призраки меча в 30 футов от него, с преимуществом проходят испытания против эффектов, изгоняющих нежить.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Призрак меча совершает две атаки оружием.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      gearId: GEAR_LONGBOW,
    },
    {
      name: 'Призыв к чести',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Для использования этой способности призрак меча должен получить урон в текущем бою. Если призрак меча использует эту способность, он получает преимущество на броски атак до конца своего следующего хода, и в незанятом пространстве в пределах 30 футов от него появляются  1к4 + 1 [воинов призраков меча](CREATURE:${CREATURE_SWORD_WRAITH_WARRIOR}). Воины существуют пока их хиты не опустятся до 0. Они действуют сразу после хода полководца, используя его значение инициативы.`,
    },
  ],
}
