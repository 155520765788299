const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  SPELL_COMPREHEND_LANGUAGES,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_HELM_OF_COMPREHENDING_LANGUAGES,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_HELM_OF_COMPREHENDING_LANGUAGES,
  name: `Шлем понимания языков`,
  nameEn: `Helm of Comprehending Languages`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Пока Вы носите этот шлем, Вы можете неограниченно действием сотворять заклинание [Понимание языков](SPELL:${SPELL_COMPREHEND_LANGUAGES}).`,
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_COMPREHEND_LANGUAGES,
  ],
  source: {
    id: SOURCE_DMG,
    page: 213,
  },
}
