const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_MYRMIDON} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_MYRMIDON,
  nameEn: 'Myrmidon',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'мирмидон',
      genitive: 'мирмидона',
      dative: 'мирмидону',
      accusative: 'мирмидона',
      instrumental: 'мирмидоном',
      prepositional: 'мирмидоне',
    },
    plural: {
      nominative: 'мирмидоны',
      genitive: 'мирмидонов',
      dative: 'мирмидонам',
      accusative: 'мирмидонов',
      instrumental: 'мирмидонами',
      prepositional: 'мирмидонах',
    },
  },
}
