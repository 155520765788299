const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {
  WEAPON_FINESSE,
  WEAPON_REACH,
} = require('./../../../weaponPropList')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_WHIP} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_WHIP,
  genderId: GENDER_MALE,
  name: 'Кнут',
  nameByCase: {
    nominative: 'кнут',
    genitive: 'кнута',
    accusative: 'кнут',
    instrumental: 'кнутом',
  },
  nameEn: 'Whip',
  description: `Традиционно используемые для управления скотом, кнуты всё же могут быть использованы как оружие тренированным профессионалом. Некоторые кнуты бьют на двадцать футов, давая большую дальность, чем большинство оружия ближнего боя!

Другие стили кнута имеют меньшую дальность, но другие преимущества. Плети — это кнуты с несколькими короткими хвостами, которые можно использовать для разоружения противника. Всем пучком можно хлестнуть одновременно и тогда он наносит жгучий удар. Цепные кнуты, сделанные из металлических стержней, соединенных цепями, менее гибкие, но они очень сильно бьют.`,
  damage: {
    diceType: 4,
    diceCount: 1,
  },
  cost: 200,
  damageType: DAMAGE_SLASHING,
  weight: 3,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 111,
    },
  ],
  weaponPropList: [
    WEAPON_REACH,
    WEAPON_FINESSE,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
