const {
  CREATURE_CLAY_CONSTRUCT_SPIRIT,
  CREATURE_STONE_CONSTRUCT_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух каменного конструкта',
  nameEn: 'Stone Construct Spirit',
  id: CREATURE_STONE_CONSTRUCT_SPIRIT,
  parentId: CREATURE_CLAY_CONSTRUCT_SPIRIT,
  featureList: [
    {
      name: 'Каменная неподвижность',
      description: `Когда существо, видимое ★СУЩЕСТВО★, начинает свой ход в пределах 10 футов от ★СУЩЕСТВО★, ★СУЩЕСТВО★ может заставить его пройти испытание Мудрости. При провале до начала следующего хода цели она не может совершать реакции и её скорость уменьшатся вдвое.`,
    },
  ],
  reactionList: [],
}
