const {
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_NECROTIC,
} = require('./../../../../damageTypeList')
const {
  CREATURE_GHOSTLY_UNDEAD_SPIRIT,
  CREATURE_GHOSTLY_UNDEAD_SPIRIT_2020_03_26,
  CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух призрачной нежити',
  nameEn: 'Ghostly Undead Spirit',
  id: CREATURE_GHOSTLY_UNDEAD_SPIRIT_2020_03_26,
  releasedAs: CREATURE_GHOSTLY_UNDEAD_SPIRIT,
  parentId: CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_GHOST,
  ],
  featureList: [
    {
      name: 'Бестелесное перемещение',
      description: `★СУЩЕСТВО★ может перемещаться сквозь других существ и предметы, как если бы они были труднопроходимой местностью. Если ★СУЩЕСТВО★ оканчивает ход внутри предмета, то выталкивается в ближайшее свободное пространство и получает урон силовым полем 5 (1к10) за каждые 5 футов перемещения.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Мертвенное касание',
      description: `Цель должна пройти испытание Мудрости против сложности заклинаний заклинателя или испугаться духа до конца своего следующего хода`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  extendPropCollection: {
    speed: {
      [SPEED_FLY]: {
        value: 40,
        isFloating: true,
      },
    },
  },
}
