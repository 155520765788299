const {PC_SUBCLASS_WIZARD_SCHOOL_OF_TRANSMUTATION} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {SPELL_POLYMORPH} = require('./../../../spellIdList')
const {
  FEATURE_MASTER_TRANSMUTER,
  FEATURE_MINOR_ALCHEMY,
  FEATURE_SHAPECHANGER,
  FEATURE_SPELLBOOK,
  FEATURE_TRANSMUTATION_SAVANT,
  FEATURE_TRANSMUTER_S_STONE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_TRANSMUTATION_SAVANT,
    name: `Мастер преобразования`,
    nameEn: `Transmutation Savant`,
    lvl: 2,
    text: `Золото и время, которое Вы тратите на копирование заклинания Преобразования в свою [Книгу заклинаний](FEATURE:${FEATURE_SPELLBOOK}), уменьшаются вдвое.`,
    source: {
      id: SOURCE_PHB,
      page: 119,
    },
  },
  {
    id: FEATURE_MINOR_ALCHEMY,
    name: `Малая алхимия`,
    nameEn: `Minor Alchemy`,
    lvl: 2,
    text: `Вы можете временно изменять физические свойства одного немагического предмета, превращая структуру вещества из одной в другую.

Вы совершаете специальную алхимическую процедуру на одном предмете, состоящем полностью из дерева, камня (но не драгоценного камня), железа, меди или серебра, превращая его в один из этих материалов.

За каждые 10 минут Вы можете преобразовать до 1 кубического фута материала.

Через 1 час, или когда Вы теряете концентрацию (как если бы Вы концентрировались на заклинании), материал возвратится в своё первоначальное состояние.`,
    source: {
      id: SOURCE_PHB,
      page: 120,
    },
  },
  {
    id: FEATURE_TRANSMUTER_S_STONE,
    name: `Камень преобразования`,
    nameEn: `Transmuter’s Stone`,
    lvl: 6,
    text: `Вы можете потратить 8 часов для создания _Камня преобразования_, хранящего магию преобразования.

Вы можете извлечь выгоду из _Камня_ самостоятельно, или передать его другому существу. Существо получает выгоду по Вашему выбору до тех пор, пока _Камень_ находится в его распоряжении. Когда Вы создаёте _Камень_, выберите создаваемое им преимущество из следующего списка:

* Тёмное зрение в пределах 60 футов.
* Увеличение скорости на 10 футов, пока существо не перегружено.
* Владение испытаниями Телосложения.
* Сопротивление звуку, кислоте, огню, холоду или электричеству (совершите выбор при сотворении камня).

Каждый раз, когда Вы накладываете заклинание преобразования 1 и выше уровней, Вы можете изменить эффект вашего _Камня_, если он находится у Вас.

Если Вы создаёте новый _Камень преобразования_, предыдущий перестаёт работать.`,
    source: {
      id: SOURCE_PHB,
      page: 120,
    },
  },
  {
    id: FEATURE_SHAPECHANGER,
    name: `Перевёртыш`,
    nameEn: `Shapechanger`,
    lvl: 10,
    text: `Вы добавляете заклинание [Превращение](SPELL:${SPELL_POLYMORPH}) в свою книгу заклинаний, если его ещё нет там. Вы можете сотворять [Превращение](SPELL:${SPELL_POLYMORPH}), не тратя ячейку заклинаний. Когда Вы так поступаете, Вы можете выбрать в качестве цели только себя и превратиться можете в зверя, ПО которого не больше 1.

Активировав превращение подобным путём один раз, Вы не можете делать это ещё раз до завершения короткого или длинного отдыха, хотя Вы можете сотворять это заклинание обычным способом, за ячейки заклинаний.`,
    spellIdList: SPELL_POLYMORPH,
    source: {
      id: SOURCE_PHB,
      page: 120,
    },
  },
  {
    id: FEATURE_MASTER_TRANSMUTER,
    name: `Гений преобразования`,
    nameEn: `Master Transmuter`,
    lvl: 14,
    text: `Вы можете действием потребить запас магии, хранящийся в Вашем [Камне преобразования](FEATURE:${FEATURE_TRANSMUTER_S_STONE}).

Когда Вы так поступаете, выберите один из следующих эффектов. Ваш [Камень преобразования](FEATURE:${FEATURE_TRANSMUTER_S_STONE}) уничтожается, и не может быть использован вновь, пока Вы не завершите длинный отдых.

# Большая трансформация

Вы можете преобразовать один немагический предмет объёмом не более 5 кубических футов в другой немагический предмет схожего размера и массы, и равного или меньшего объёма. Вы должны 10 минут держать предмет в руках для его трансформации.

# Возвращение к жизни

Вы накладываете заклинание оживление на существо, которого коснулись своим [Камнем преобразования](FEATURE:${FEATURE_TRANSMUTER_S_STONE}), не тратя ячейку заклинания и даже без необходимости иметь это заклинание в [Книге заклинаний](FEATURE:${FEATURE_SPELLBOOK}).

# Вернуть молодость

Вы касаетесь [Камнем преобразования](FEATURE:${FEATURE_TRANSMUTER_S_STONE}) согласное существо, и видимый возраст этого существа уменьшается на 3к10 лет до минимума в 13 лет. Этот эффект не увеличивает его срок жизни.

# Панацея

Вы снимаете все эффекты проклятий, болезней и ядов с существа, которого касаетесь [Камнем преобразования](FEATURE:${FEATURE_TRANSMUTER_S_STONE}). Кроме того, это существо восстанавливает все хиты.`,
    source: {
      id: SOURCE_PHB,
      page: 120,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_SCHOOL_OF_TRANSMUTATION,
  })
)
