const {CREATURE_TYPE_GNOME} = require('./../../creatureTypeIdList')
const {uncommonRaces} = require ('./../../textCommonParts')
const {
  PC_RACE_DWARF,
  PC_RACE_KOBOLD,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')
const {
  GOD_GARL_GLITTERGOLD,
  GOD_KURTULMAK,
} = require('./../../godIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GNOME,
  nameEn: 'Gnome',
  nameAlt: 'Забытая раса',
  nameEnAlt: 'Forgotten Folk',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'гном',
        genitive: 'гнома',
        dative: 'гному',
        accusative: 'гнома',
        instrumental: 'гномом',
        prepositional: 'гноме',
      },
      [GENDER_FEMALE]: {
        nominative: 'гномка',
        genitive: 'гномки',
        dative: 'гномке',
        accusative: 'гномку',
        instrumental: 'гномкой',
        prepositional: 'гномке',
      },
    },
    plural: {
      nominative: 'гномы',
      genitive: 'гномов',
      dative: 'гномам',
      accusative: 'гномов',
      instrumental: 'гномами',
      prepositional: 'гномах',
    },
  },
  note: {
    text: `Худощавый, с пшеничного цвета волосами, орехово-коричневой кожей и удивительно бирюзовыми глазами, Бюргел был вдвое ниже Аэрона, и должен был взобраться на табурет, чтобы посмотреть в глазок. Как большинство домов в Оубле, этот был построен для людей, и более мелкие жители справлялись с неудобствами, как могли.

Зато размеры апартаментов как раз позволяли Бюргелу разместить все его принадлежности гномьего размера. Передняя комната была его мастерской и вмещала невообразимое разнообразие инструментов: молотки, зубила, пилы, отмычки, разноцветные линзы, ювелирные лупы, банки с порошками и измельчёнными ингредиентами для сотворения заклинаний. Жирный серый кот, фамильяр мага, лежал, свернувшись на гримуаре. Он открыл жёлтые глаза, смерил Аэрона презрительным взглядом, затем снова заснул.`,
    author: `Ричард Ли Байерс, Чёрный букет`,
  },
  description: [
    {
      text: `Нескончаемый гул трудолюбия слышен там, где селятся сплочённые общества гномов. Гул пронзают и звуки погромче: то тут, то там раздаётся скрежет шестерней, отголоски взрыва, возгласы удивления или триумфа, и, особенно часто — звонкий смех. Гномы восторгаются жизнью, каждый миг наслаждаясь новым изобретением, открытием, исследованием, созиданием или шалостью.`,
      source: {
        id: SOURCE_PHB,
        page: 36,
      },
    },
    {
      header: `Гномы`,
      text: `Малые ростом и обитающие в уголках Фаэруна, скрытых от любопытных глаз, гномы одни из наименее многочисленных и влиятельных рас мира, называемые некоторыми «забытой расой». Это определение не тревожит гномов; в общем-то анонимность и защита предоставляемая им таким статусом приходится им на руку.

Согласно легенде, первые гномы Фаэруна возникли из таинственных драгоценных камней, покоящихся глубоко под землёй - событие, перекликающееся и с любовью гномов к драгоценным камням, и с уютными объятиями их подземных нор. Говорят, что те таинственные брильянты стали скальными гномами, изумруды стали лесными гномами, а рубины обернулись глубинными гномами. Со времён создания, гномы селились в укромных местах вдали от других рас, считая, что их образ жизни не переживёт более широкого представления миру. Гномы с удовольствием становятся частью социума и сотрудничают с людьми, эльфами и дварфами, но всегда помнят о том, что для других они - меньшая и относительно менее важная раса, и их интересы могут быть отодвинуты на второй план даже среди их союзников.

В самом деле, представители других рас иногда беспечно относятся к гномам, как к чему-то второсортному, возможно думая хорошо о собственных гномьих друзьях, но в тоже время редко отдавая должное гномам, как народу в целом. Гномы регулярно бывают недооценены, и они часто используют это и как защиту и для нападения, когда в этом есть необходимость.

Как и [дварфы](PC_RACE:${PC_RACE_DWARF}), гномы долго сражались за территории с [кобольдами](PC_RACE:${PC_RACE_KOBOLD}), гоблиноидами и [орками](PC_RACE:${PC_RACE_ORC}), но между гномами и [кобольдами](PC_RACE:${PC_RACE_KOBOLD}) существует особая неприязнь. Обе расы верят в легенду о том, что давным-давно [Гарл Златоблеск](GOD:${GOD_GARL_GLITTERGOLD}) обманул кобольдского бога [Куртулмака](GOD:${GOD_KURTULMAK}), обрушил землю и запер того в бесконечном подземном лабиринте, чем заработал его вечную вражду.`,
      source: {
        id: SOURCE_SCAG,
        page: 115,
      },
    },
    {
      header: `Дамы Золотых Холмов`,
      text: `Интересная деталь гномьей культуры — в их пантеоне нет ни одной женщины. Легенда по этому поводу гласит, что загадочные Дамы Золотых Холмов отправились вместе выполнять какую-то задачу в древние времена и не вернулись до сих пор. Истории разнятся, также, как и задача, стоящая перед Дамами, от поисков и сбора примеров всех чудес и богатств Торила, до тайного плана помешать всему злому в мире используя свою анонимность как защиту, которая привела к тому, что мир с течением времени забыл даже их имена и личности. Про гномов, которые путешествуют вдали от дома, говорят, что они _«отправились искать Дам»_.`,
      source: {
        id: SOURCE_SCAG,
        page: 115,
      },
    },
    {
      header: `Задорный вид`,
      text: `Гномы очень энергичны, и кажется, что каждый сантиметр их крошечного тела излучает энтузиазм и жизнелюбие. В среднем гномы чуть выше 3 футов (90 сантиметров), и весят от 40 до 45 фунтов (от 18 до 20 килограмм). Их смуглые или коричневые лица обычно украшены широкими улыбками (над которыми нависают их выдающиеся носы), и их светлые глаза светятся возбуждением. Их русые волосы обычно торчат в разные стороны, словно выражая неослабевающий интерес ко всему на свете. 

Индивидуальность гномов ярко выражается в их внешности. Гномы мужчины содержат свои бороды, в отличие от растрёпанных волос, аккуратно подстриженными, но часто расчёсывают их на несколько прядей, или придают забавную заострённую форму. Их одежда, обычно спокойных коричневых тонов, изящно украшена вышивкой, тиснением, или расшита драгоценными камнями.`,
      source: {
        id: SOURCE_PHB,
        page: 36,
      },
    },
    {
      header: `Приверженность восторженности`,
      text: `По мнению гномов, жизнь — замечательная штука, и они готовы до капли осушать такой источник наслаждения в течение трёх-пяти отмеренных им веков. Люди могут задаваться вопросом — как не умереть со скуки за столь длинную жизнь, эльфы долгие годы могут смаковать красоту окружающего их мира, и, похоже, лишь гномов беспокоит, что даже за столь щедро отпущенный срок им не удаётся переделать и пересмотреть всё, что хочется.

Разговаривают гномы так, будто их мысли не успевают выбираться из их голов. При этом даже когда они делятся идеями и мнениями на всевозможные темы, им удаётся внимательно выслушивать других, вставляя уместные возгласы удивления, и проявляя уважительные знаки внимания.`,
      source: {
        id: SOURCE_PHB,
        page: 36,
      },
    },
    {
      header: `Светлые норы`,
      text: `Гномы делают свои дома в холмистых, лесистых землях. Они живут под землёй, но больше любят свежий воздух, чем дварфы, наслаждаясь живой природой поверхности всякий раз, когда могут. Их дома хорошо скрыты с помощью хитроумных устройств и простых иллюзий. Желанные гости быстро оказываются в светлых тёплых норах. Те, кого не ждут, вряд ли смогут эти норы даже отыскать.

Гномы, поселившиеся в землях людей, как правило являются огранщиками драгоценных камней, инженерами, мудрецами или жестянщиками. Некоторые человеческие семьи держат гномов преподавателей, чтобы их воспитанники наслаждались сочетанием серьёзного обучения и радостного удовольствия. Гном может обучать несколько поколений одной и той же семьи в течение своей долгой жизни.

Хотя гномы любят шутки любого рода, особенно каламбуры и шалости, они также посвящают себя решению более серьёзных задач, которые они ставят перед собой. Многие гномы являются умелыми инженерами, алхимиками, жестянщиками и изобретателями. Они готовы к совершению ошибок и смеются над собой в процессе совершенствования своего дела, принимая смелые (иногда отчаянные) решения, и мечтая о большем.`,
      source: {
        id: SOURCE_PHB,
        page: 36,
      },
    },
    {
      header: `Имена гномов`,
      text: `Гномы любят имена, и большинство из них имеют по полдюжины имён или около того. Мать гнома, отец, старейшина клана, тёти и дяди: каждый даёт гному имя, а также различные прозвища, которые могут на долгое время прилипнуть, а могут и не прилипнуть. Имена гномов, как правило, являются вариациями имен предков или дальних родственников, хотя некоторые из них придумывают абсолютно новые. Когда гномы имеют дело с людьми и другими, кто «зажат» в именах, они используют не более трёх имен: личное имя, название клана и прозвище, выбирая среди них те, которые наиболее забавно звучат.

# Мужские имена

Алвин, Алстон, Боддинок, Брок, Бюргел, Варрин, Вренн, Гербо, Гимбл, Глим, Джебеддо, Димбл, Зук, Келлен, Намфудл, Оррин, Рундар, Сибо, Синдри, Фонкин, Фрюг, Элдон, Эрки

# Женские имена

Бимпноттин, Брина, Вейуокет, Донелла, Дувамил, Занна, Карамип, Карлин, Лилли, Лорилла, Лупмоттин, Маднаб, Никс, Нисса, Ода, Орла, Ройвин, Тана, Шамил, Эливик, Элиджобелл, Элла

# Названия кланов

Берен, Гаррик, Даергел, Мёрниг, Накл, Нингел, Раулнор, Тимберс, Турен, Фолькор, Шеппен

# Прозвища

Барсук, Босоног, Двазамок, Колотушка, Ку, Ним, Пеплосерд, Пивохлёб, Плащ, Пок, Самоцвет, Стамблдак, Фниппер`,
      source: {
        id: SOURCE_PHB,
        page: 37,
      },
    },
    {
      header: `Исследование мира`,
      text: `Любопытные и импульсивные гномы могут пуститься в приключения, чтобы увидеть мир или из любви к исследованиям. Будучи любителями драгоценных камней и других мелких предметов, некоторые гномы рассматривают приключения как быстрый, хоть и опасный, путь к богатству. Вне зависимости от того, что побудило их к приключениям, гномы, которые приняли этот образ жизни, получают от него столько удовольствия, сколько и от любой другой деятельности, которой они занимаются, иногда к великому неудовольствию своих товарищей по приключению.`,
      source: {
        id: SOURCE_PHB,
        page: 37,
      },
    },
    uncommonRaces,
    {
      header: `Всегда благодарные`,
      text: `Редко можно встретить гнома, который был бы враждебным или злым, если он не перенёс тяжёлую травму. Гномы знают, что большинство рас не разделяют их чувство юмора, но они наслаждаются любой компанией, как и всем, что они делают.`,
      source: {
        id: SOURCE_PHB,
        page: 37,
      },
    },
  ],
}
