const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_BULLYWUG,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_BULLYWUG,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Жаболюдский',
    genitive: 'Жаболюдского',
    instrumental: 'Жаболюдским',
    prepositional: 'Жаболюдском',
  },
  nameEn: 'Bullywug',
  isReady: false,
  isRealLang: true,
}
