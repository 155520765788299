const {
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DRAGON_GEM,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_CHROMATIC_DRAGON_SPIRIT_2021_04_14,
  CREATURE_GEM_DRAGON_SPIRIT,
  CREATURE_GEM_DRAGON_SPIRIT_2021_04_14,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух самоцветного дракона',
  nameEn: 'Gem Draconic Spirit',
  id: CREATURE_GEM_DRAGON_SPIRIT_2021_04_14,
  releasedAs: CREATURE_GEM_DRAGON_SPIRIT,
  parentId: CREATURE_CHROMATIC_DRAGON_SPIRIT_2021_04_14,
  resistanceList: [ // Gem only
    DAMAGE_FORCE,
    DAMAGE_NECROTIC,
    DAMAGE_PSYCHIC,
    DAMAGE_RADIANT,
    DAMAGE_THUNDER,
  ],
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAGON_GEM,
  ],
}
