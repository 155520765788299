const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_UA_2017_04_03_STARTER_SPELLS,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {DAMAGE_POISON} = require('./../../../../../damageTypeList')
const {SPELL_INFESTATION} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_INFESTATION,
  name: 'Нашествие',
  nameEn: 'Infestation',
  description: `Вы вызываете клещей, блох и других паразитов, которые мгновенно обволакивают одно существо, которое Вы можете видеть в пределах дистанции. Цель должна пройти испытание Телосложения, в противном случае она получает 1к6 урона ядом и перемещается на 5 футов в случайном направлении, если она может двигаться и её скорость составляет не менее 5 футов.

Бросьте к4 для определения направления:

1. Север
2. Юг
3. Восток
4. Запад

Это перемещение не провоцирует атаки и, если выпавшее направление заблокировано, цель не перемещается.`,
  highLvlCast: `Урон заклинания увеличивается на 1к6, когда Вы достигаете 5 уровня (2к6), 11 уровня (3к6) и 17 уровня (4к6).`,
  lvl: 0,
  magicSchoolId: MAGIC_CONJURATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `живая блоха`,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_POISON,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 158,
    },
    {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 3,
    },
  ],
}
