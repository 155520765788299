const {SOURCE_TCoE} = require('./../../../sourceList')
const {PC_SUBCLASS_BARD_COLLEGE_OF_CREATION} = require('./../../../pcSubClassIdList')
const {CREATURE_DANCING_ITEM} = require('./../../../creatureIdList')
const {
  FEATURE_ANIMATING_PERFORMANCE,
  FEATURE_BARDIC_INSPIRATION,
  FEATURE_CREATIVE_CRESCENDO,
  FEATURE_MOTE_OF_POTENTIAL,
  FEATURE_PERFORMANCE_OF_CREATION,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_MOTE_OF_POTENTIAL,
    name: `Пылинка потенциала`,
    nameEn: `Mote of Potential`,
    lvl: 3,
    text: `Каждый раз, давая существу кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}), Вы можете произнести ноту из Песни творения, чтобы создать Крошечную _Пылинку потенциала_, которая начнёт вращаться в пределах 5 футов от этого существа. _Пылинка_ неосязаема и неуязвима, и она существует до тех пор, пока не будет потеряна кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}). _Пылинка_ выглядит как музыкальная нота, звезда, цветок или другой символ искусства или жизни, который Вы выберете.

Когда существо использует кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}), _Пылинка_ даст дополнительный эффект в зависимости от того, использована ли кость при проверке характеристики, броске атаки или испытании.

# Проверка характеристики

Когда существо бросает кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}), чтобы добавить её к проверке характеристики, существо может снова бросить кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и выбрать, какой бросок использовать, поскольку _Пылинка_ лопается и на мгновение испускает красочные безобидные искры.

# Бросок атаки

Сразу после того, как существо бросает кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}), чтобы добавить её к броску атаки по цели, _Пылинка_ с громом взрывается. Цель и каждое видимое Вами в пределах 5 футов от неё существо, должны пройти испытание Телосложения против Вашей СЛ заклинаний, иначе получат урон звуком, равный числу на кости [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}).

# Испытание

Сразу после того, как существо бросает кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и добавляет её к ипытанию, _Пылинка_ исчезает с тихой мелодией, а существо получает временные хиты, равные числу на кости [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}), плюс Ваш модификатор Харизмы ( минимум 1 временный хит).`,
    source: {
      id: SOURCE_TCoE,
      page: 36,
    },
  },
  {
    id: FEATURE_PERFORMANCE_OF_CREATION,
    name: `Проявление творения`,
    nameEn: `Performance of Creation`,
    lvl: [3, 6, 14],
    text: `Вы можете действием направить магию Песни творения, создавая один немагический предмет по Вашему выбору в незанятом пространстве в пределах 10 футов от Вас. Предмет должен находиться на поверхности или в жидкости, способной его поддерживать. Ценность предмета не может превышать Ваш уровень барда более чем в 20 раз, и предмет должен быть Среднего (или меньшего) размера. Предмет мягко мерцает, и существо может слышать тихую мелодию, прикоснувшись к нему. Созданный предмет исчезает через количество часов, равное Вашему бонусу мастерства. Примеры предметов, которые Вы можете создать, смотрите в «[Снаряжении](/gear-catalog)».

Создав предмет этой способностью, Вы не сможете сделать это снова, пока не закончите длинный отдых, если только не потратите ячейку заклинания 2 круга или выше, чтобы снова воспользоваться этой способностью. Одновременно этой способностью можно создать только один предмет; если Вы используете _Проявление творения_ уже имея созданный ей предмет, то первый предмет немедленно исчезает.

Размер предмета, который Вы можете создать с помощью _Проявления творения_, увеличивается на одну категорию размера на 6 уровне (Большой) и 14 уровне (Огромный).`,
    source: {
      id: SOURCE_TCoE,
      page: 36,
    },
  },
  {
    id: FEATURE_ANIMATING_PERFORMANCE,
    name: `Оживляющее выступление`,
    nameEn: `Animating Performance`,
    creatureId: CREATURE_DANCING_ITEM,
    lvl: 6,
    text: `Вы можете действием выбрать видимый Вами в пределах 30 футов Большой (или меньший) немагический предмет и оживить его. Оживлённый предмет использует параметры [Пляшущего предмета](CREATURE:${CREATURE_DANCING_ITEM}). Предмет дружелюбен к Вам и Вашим спутникам и подчиняется Вашим командам. Он живёт 1 час, пока его хиты не уменьшатся до 0 или пока Вы не умрёте.

В бою предмет действует на Вашей инициативе, но его ход начинается сразу после Вашего. Он может двигаться и использовать свою реакцию самостоятельно, но единственное действие, которое он выполняет в свой ход — Уклонение, если только Вы бонусным действием на своём ходу не прикажете ему совершить другое действие. Это действие может быть как одним из действий в его статблоке, так и любым другим. Если Вы недееспособны, предмет может выполнять любые действие по своему выбору, а не только Уклоняться.

Когда Вы бонусным действием используете [Бардовское вдохновение](FEATURE:${FEATURE_BARDIC_INSPIRATION}), Вы можете управлять оживлённым предметом частью этого же бонусного действия.

После того, как Вы оживили предмет _Оживляющим выступлением_, Вы не сможете сделать это снова, пока не закончите длинный отдых, если только не потратите для этого ячейку заклинания 3 круга или выше. Одновременно _Оживляющим выступлением_ можно оживлять только один предмет; если Вы используете _Оживляющее выступление_ и у Вас уже есть [Пляшущий предмет](CREATURE:${CREATURE_DANCING_ITEM}), старый предмет сразу становится неодушевленным.`,
    source: {
      id: SOURCE_TCoE,
      page: 36,
    },
  },
  {
    id: FEATURE_CREATIVE_CRESCENDO,
    name: `Созидающее крещендо`,
    nameEn: `Creative Crescendo`,
    lvl: 14,
    text: `При использовании способности [Проявление творения](FEATURE:${FEATURE_PERFORMANCE_OF_CREATION}) Вы можете создать более одного предмета одновременно. Количество предметов равно Вашему модификатору Харизмы (минимум два предмета). Если Вы создаёте предметы свыше этого числа, то выбираете, какой из ранее созданных предметов исчезнет. Только один из этих предметов может иметь максимальный размер, который Вы можете создавать; остальные должны быть Маленькими или Крохотными.

Вы больше не ограничены стоимостью в золотых монетах при создании предметов [Проявлением творения](FEATURE:${FEATURE_PERFORMANCE_OF_CREATION}).`,
    source: {
      id: SOURCE_TCoE,
      page: 36,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_CREATION,
  })
)

