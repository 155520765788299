const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_DRAGON_BLACK_ANCIENT,
  CREATURE_DRAGON_BLUE_ANCIENT,
  CREATURE_DRAGON_GREEN_ANCIENT,
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_DRAGON_WHITE_ANCIENT,
} = require('./../../creatureIdList')

const dragonDescriptionList = require('./dragon')
const queenOfEvilDragonsDescription = require('./queen_of_evil_dragons')

module.exports = [
  {
    header: 'Цветные драконы',
    text: `[Белые](CREATURE:${CREATURE_DRAGON_WHITE_ANCIENT}), [зелёные](CREATURE:${CREATURE_DRAGON_GREEN_ANCIENT}), [красные](CREATURE:${CREATURE_DRAGON_RED_ANCIENT}), [синие](CREATURE:${CREATURE_DRAGON_BLUE_ANCIENT}) и [чёрные](CREATURE:${CREATURE_DRAGON_BLACK_ANCIENT}) драконы представляют злую сторону драконьего рода. Агрессивные, ненасытные и тщеславные, цветные драконы являются тёмными гениями и могучими тиранами, которых боятся другие существа, да и друг друга они тоже боятся.`,
    source: {
      id: SOURCE_MM,
      page: 102,
    },
  },
  {
    header: 'Ведомые жадностью',
    text: `Цветные драконы алчут сокровищ, и эта жажда красной линией проходит через все их интриги и планы. Они верят, что богатства мира принадлежат им по праву, и цветные драконы присваивают эти сокровища, невзирая на гуманоидов и других существ, которые, по мнению драконов, «украли» их. Горы монет, сверкающих самоцветов и магических вещей — драконьи клады — вошли в легенды. Однако цветные драконы не интересуются коммерцией, и накапливают добро лишь затем, чтобы обладать им.`,
    source: {
      id: SOURCE_MM,
      page: 102,
    },
  },
  {
    header: 'Эгоцентричные существа',
    text: `Цветных драконов объединяет их чувство превосходства. Они полагают себя самыми могучими и достойными из всех смертных существ. Они общаются с другими существами лишь чтобы продвинуть свои собственные интересы. Краеугольным камнем мировоззрения и личности каждого цветного дракона является твёрдая убеждённость в своём врождённом праве управлять. И пытаться смирить дракона, всё равно, что уговаривать ветер не дуть. Для них даже гуманоиды — всего лишь животные, подходящие на роль дичи для охоты и тягловых животных, кои никак не достойны уважения.`,
    source: {
      id: SOURCE_MM,
      page: 102,
    },
  },
  {
    header: 'Опасные логова',
    text: `Драконье логово служит оплотом его власти и хранилищем его сокровищ. Обладающие природной стойкостью и устойчивостью к тяжёлым условиям среды обитания, драконы выбирают и обустраивают логова не для укрытия, но для защиты, предпочитая множество входов и выходов, обеспечивающих безопасность клада.

Большинство логовищ цветных драконов скрыто в опасных и отдалённых местах, чтобы не дать никому, кроме самых дерзких смертных какой-либо возможности добраться до них. Чёрный дракон может устроить логово в сердце бескрайних болот, тогда как красный дракон может занять жерло действующего вулкана. А вдобавок к природным условиям, защищающим логово, могучие цветные драконы также используют магических стражей, ловушки и подневольных существ, чтобы защищать их сокровища.`,
    source: {
      id: SOURCE_MM,
      page: 102,
    },
  },
  queenOfEvilDragonsDescription,
  ...dragonDescriptionList,
]
