const {
    SOURCE_BGDIA,
    SOURCE_SKT,
  } = require('./../../../../sourceList'),
  {
    CR_0,
  } = require('./../../../../crList'),
  {
    SIZE_TINY,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BEAST,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_CN,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
    SPEED_FLY,
  } = require('./../../../../speedList'),
  {
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_POISON,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PC_CLASS_RANGER,
    PC_CLASS_WIZARD,
  } = require('./../../../../pcClassIdList'),
  {
    SPELL_FIND_FAMILIAR,
  } = require('./../../../../spellIdList'),
  {
    ABILITY_KEEN_SMELL,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_BAT,
    CREATURE_CAT,
    CREATURE_FAERIE_DRAGON_BLUE,
    CREATURE_MANTICORE,
    CREATURE_STIRGE,
    CREATURE_TRESSYM,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Трессим',
  nameEn: 'Tressym',
  id: CREATURE_TRESSYM,
  description: [
    `Трессим — озорная крылатая кошка, размером с домашнюю [кошку](CREATURE:${CREATURE_CAT}) и размахом крыльев в 3 фута.

Они редко охотятся и занимают логова вместе с другими существами их вида. Мирно сосуществуя с [летучими мышами](CREATURE:${CREATURE_BAT}), [волшебными дракончиками](CREATURE:${CREATURE_FAERIE_DRAGON_BLUE}), и другими похожими существами, они ненавидят [кровопийц](CREATURE:${CREATURE_STIRGE}) и злых летающих монстров вроде [мантикоры](CREATURE:${CREATURE_MANTICORE}). Также они любят дразнить собак.

Трессимы питаются маленькими грызунами, птичками и насекомыми, преследуя и атакуя добычу, как нормальные кошки, но пользуясь преимуществом полета. Трессимы, однако, не нападают на птенцов и не разоряют гнезда.

Трессимы могут спариваться с другими представителями своего вида, но не живут вместе. Трессимы могут также спариваться с [нормальными кошками](CREATURE:${CREATURE_CAT}), но только один на десять детей будет трессимом, остальным будут [нормальными кошками](CREATURE:${CREATURE_CAT}).

Трессимы обладают хорошей памятью, особенно когда дело доходит до опасностей. Как пример, трессимы увидев человека использующего палочку молний помнят опасность «деревянной палочки в руках человека» до конца жизни. Везучий и здоровый трессим может жить до 20 лет.

С разрешения Мастера, персонаж, использующий [Поиск фамильяра](SPELL:${SPELL_FIND_FAMILIAR}), может призвать трессима вместо [обычной кошки](CREATURE:${CREATURE_CAT}).`,
    {
      header: `Трессим`,
      text: `Трессимы считаются результатом магических экспериментов на [домашних кошках](CREATURE:${CREATURE_CAT}). Это умные кошачьи, которые известны тем, что образуют крепкие дружеские отношения с гуманоидами, особенно с [рейнджерами](PC_CLASS:${PC_CLASS_RANGER}) и [волшебниками](PC_CLASS:${PC_CLASS_WIZARD}).`,
      source: {
        id: SOURCE_BGDIA,
        page: 242,
      }
    },
  ],
  couldBeFamiliar: true,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_CN,
  source: [
    {
      id: SOURCE_SKT,
      page: 246,
    },
    {
      id: SOURCE_BGDIA,
      page: 242,
    },
  ],
  armor: 12,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 30,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 3,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 10,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_COMMON,
      doNotSpeak: true,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_KEEN_SMELL,
    {
      name: 'Обнаружение невидимости',
      description: `В пределах 60 футов от него, трессим видит всё, что пытаются скрыть от него магической невидимостью.`,
    },
    {
      name: 'Чувство яда',
      description: `Трессим может определить, отравлено ли вещество при прикосновении, понюхав или попробовав на вкус.`,
    },
  ],
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 0,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceBonus: 1,
        },
      },
    },
  ],
}
