const {PC_SUBCLASS_SORCERER_SHADOW_MAGIC} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {SPELL_DARKNESS} = require('./../../../spellIdList')
const {
  CREATURE_DIRE_WOLF,
  CREATURE_HOUND_OF_ILL_OMEN,
} = require('./../../../creatureIdList')
const {
  FEATURE_EYES_OF_THE_DARK,
  FEATURE_HOUND_OF_ILL_OMEN,
  FEATURE_SHADOW_WALK,
  FEATURE_SORCERY_POINTS,
  FEATURE_STRENGTH_OF_THE_GRAVE,
  FEATURE_UMBRAL_FORM,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EYES_OF_THE_DARK,
    name: `Глаза Тьмы`,
    nameEn: `Eyes of the Dark`,
    lvl: [1, 3],
    text: `Вы получаете тёмное зрение радиусом 120 футов.

Когда Вы достигаете 3 уровня в этом классе, Вы изучаете заклинание [Тьма](SPELL:${SPELL_DARKNESS}), которое не учитывается при подсчете известных Вам заклинаний чародея. Вы можете сотворить его, потратив 2 [Очка чародейства](FEATURE:${FEATURE_SORCERY_POINTS}) или потратив ячейку заклинания. Если Вы сотворяете его с использованием [Очков чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), Вы можете видеть сквозь созданную этим заклинанием тьму.`,
    spellIdPickByLvl: {
      3: SPELL_DARKNESS,
    },
    spellIdList: SPELL_DARKNESS,
    source: {
      id: SOURCE_XGTE,
      page: 60,
    },
  },
  {
    id: FEATURE_STRENGTH_OF_THE_GRAVE,
    name: `Сила могилы`,
    nameEn: `Strength of the Grave`,
    lvl: 1,
    text: `Ваше существование в сумеречном состоянии между жизнью и смертью затрудняет Ваше поражение.

Когда урон уменьшает Ваши хиты до 0, Вы можете пройти испытание Харизмы (СЛ 5 + полученный урон). При успехе вместо этого Ваши хиты меньшаются до 1 хита.

Вы не можете использовать эту способность, если Ваши хиты уменьшены до 0 от урона излучением или критическим ударом.

После успешного испытания Вы не сможете использовать эту способность ещё раз, пока не закончите длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 61,
    },
  },
  {
    id: FEATURE_HOUND_OF_ILL_OMEN,
    name: `Гончая дурного знамения`,
    nameEn: `Hound of Ill Omen`,
    lvl: 6,
    text: `Вы получаете возможность вызвать воющее существо тьмы, чтобы преследовать своих врагов.

Вы можете бонусным действием потратить 3 [Очка чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы магически вызвать гончую дурного знамения, нацеливая её на одно видимое Вами в пределах 120 футов существо. Гончая использует параметры [лютого волка](CREATURE:${CREATURE_DIRE_WOLF}), со следующими изменениями:

* Гончая Среднего размера, не Большого, и она считается монстром, а не зверем.
* Она появляется с количеством временных хитов, равных половине Вашего уровня чародея.
* Она может перемещаться через других существ и предметы, как если бы они были труднопроходимой местностью. Гончая получает 5 урона силовым полем, если завершает свой ход внутри предмета.
* В начале своего хода гончая автоматически узнаёт местоположение своей цели. Если она была спрятанной, то перестает быть спрятанной от гончей.

Гончая появляется в незанятом пространстве по Вашему выбору в радиусе 30 футов от цели. Сделайте проверку инициативы для гончей. В свой ход, она может двигаться только к своей цели по самому прямому маршруту, и она может использовать своё действие только для атаки своей цели. Гончая может совершать провоцированные атаки, но только против своей цели. Кроме того, в то время как гончая находится в пределах 5 футов от цели, цель имеет помеху на испытания против любого заклинания, которое Вы накладываете.

Гончая исчезает, если её хиты уменьшаются до 0 или спустя 5 минут.`,
    creatureId: CREATURE_HOUND_OF_ILL_OMEN,
    source: {
      id: SOURCE_XGTE,
      page: 61,
    },
  },
  {
    id: FEATURE_SHADOW_WALK,
    name: `Хождение по теням`,
    nameEn: `Shadow Walk`,
    lvl: 14,
    text: `Вы получаете способность переходить из одной тени в другую.

Когда Вы находитесь в области тусклого света или темноты, Вы можете бонусным действием магически телепортироваться в видимое Вами в пределах 120 футов свободное пространство, которое тоже находится в области тусклого света или темноты.`,
    source: {
      id: SOURCE_XGTE,
      page: 61,
    },
  },
  {
    id: FEATURE_UMBRAL_FORM,
    name: `Мрачная форма`,
    nameEn: `Umbral Form`,
    lvl: 18,
    text: `Вы можете бонусным действием потратить 6 [Очков чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), чтобы магически превратиться в свою теневую форму.

В этой форме Вы получаете сопротивление всему урону, кроме урона силовым полем и излучением, и можете перемещаться через других существ и предметы, как если бы они были труднопроходимой местностью. Вы получаете 5 урона силовым полем, если завершаете свой ход внутри предмета.

Вы остаётесь в этой форме на 1 минуту. Эффект заканчивается раньше, если Вы стали недееспособны, умерли или если отменили его бонусным действием.`,
    source: {
      id: SOURCE_XGTE,
      page: 61,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_SORCERER_SHADOW_MAGIC,
  })
)
