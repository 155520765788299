const {
  SOURCE_MOOT,
} = require('./../../../../sourceList')
const {
  CR_12,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ARCHON,
  CREATURE_TYPE_CELESTIAL,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CAST_MATERIAL,
} = require('./../../../../castComponentList')
const {
  GEAR_PLATE_ARMOR,
} = require('./../../../../gearIdList')
const {
  ALIGNMENT_LG,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPELL_COMMAND,
  SPELL_GUIDING_BOLT,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_CRUSADER_S_MANTLE,
  SPELL_SPARE_THE_DYING,
} = require('./../../../../spellIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_RADIANT,
} = require('./../../../../damageTypeList')
const {
  LANG_ALL,
} = require('./../../../../languageIdList')
const {
  SENSE_TRUE_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_COORDINATED_ASSAULT,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MOUNT,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  archonDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_ARCHON_OF_FALLING_STARS,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Архонт падающих звёзд',
  nameEn: 'Archon of Falling Stars',
  id: CREATURE_ARCHON_OF_FALLING_STARS,
  description: [
    {
      header: 'Архонт падающих звёзд',
      text: `Эпический сборник самых ранних мировых сказаний под названием _«Космогония»_ рассказывает о битве между группой чемпионов богов и могущественным архонтом, которая произошла на загадочном восточном краю мира. Побеждённый падающий архонт, как говорят, встретил восходящее солнце. Но Гелиод проявил милосердие к кающемуся архонту, поклявшемуся поддерживать справедливость и праведность в самых диких местах мира. В знак своего милосердия Гелиод подарил архонту копьё, которое по своему сиянию не уступало его собственному. Это был первый архонт падающих звезд.`,
      source: {
        id: SOURCE_MOOT,
        page: 213,
      },
    },
    ...archonDescriptionList,
  ],
  note: {
    text: `Архонт упал, подобно звезде с неба, встречая восходящее солнце на горизонте`,
    author: `«Космогония»`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ARCHON,
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_LG,
  source: {
    id: SOURCE_MOOT,
    page: 213,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 19,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 21,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_RADIANT,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
  ],
  cr: CR_12,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MOUNT,
    {
      name: 'Сияющее перерождение',
      comment: 'перезаряжается после длинного отдыха',
      description: `Если хиты ★СУЩЕСТВО★ опускаются до 0, ★он★ восстанавливает 30 хитов и вскакивает на ноги во взрыве света. Все существа по ★его★ выбору в пределах 30 футов должны пройти испытание Телосложения СЛ 16 или получить 13 (3к8) урона излучением и ослепляются до начала следующего хода ★СУЩЕСТВО★.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_WIT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_COMMAND,
          SPELL_GUIDING_BOLT,
          SPELL_SPARE_THE_DYING,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_SPIRIT_GUARDIANS,
          SPELL_CRUSADER_S_MANTLE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки _Сияющим копьём_`,
    },
    {
      name: 'Сияющее копьё',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 5,
          },
          {
            type: DAMAGE_RADIANT,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
     {
       name: 'Атака',
       description: `★СУЩЕСТВО★ совершает атаку _Сияющим копьём_ или сотворяет [Направленный снаряд](SPELL:${SPELL_GUIDING_BOLT})`,
     },
     {
       id: ABILITY_COORDINATED_ASSAULT,
       attackName: 'Сияющим копьём',
     },
     {
       name: 'Возвращение в Никс',
       cost: 3,
       description: `★СУЩЕСТВО★ заставляет видимый им в пределах 30 футов труп взорваться струями сияющими звёздами, не оставляя никаких следов его существования. Всё, что он носил и нёс, остаётся на месте. Когда труп взрывается, все существа в пределах 10 футов от него должны пройти испытание Ловкости или получить 22 (4к10) урона излучением.`,
     },
  ],
}
