const {DAMAGE_NECROTIC} = require('./../../../damageTypeList')
const {GEAR_ANTIMATTER_RIFLE} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  WEAPON_AMMUNITION_ENERGY_CELLS,
  WEAPON_RELOAD,
  WEAPON_RANGE,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  CAT_FIREARMS,
  CAT_MARTIAL_RANGE_WEAPON,
  CAT_TECH_FUTURISTIC,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_ANTIMATTER_RIFLE,
  name: 'Винтовка на антиматерии',
  nameEn: 'Antimatter Rifle',
  nameByCase: {
    nominative: 'винтовка на антиматерии',
    genitive: 'винтовки на антиматерии',
    accusative: 'винтовку на антиматерии',
    instrumental: 'винтовкой на антиматерии',
  },
  weight: 10,
  damageType: DAMAGE_NECROTIC,
  damage: {
    diceCount: 6,
    diceType: 8,
  },
  genderId: GENDER_FEMALE,
  category: [
    CAT_FIREARMS,
    CAT_MARTIAL_RANGE_WEAPON,
    CAT_TECH_FUTURISTIC,
  ],
  weaponPropList: [
    WEAPON_AMMUNITION_ENERGY_CELLS,
    WEAPON_TWO_HANDED,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 120,
        max: 360,
      },
    },
    {
      id: WEAPON_RELOAD,
      value: 2,
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
