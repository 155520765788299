const {PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF} = require('./../../../pcSubClassIdList')
const {SOURCE_TCoE} = require('./../../../sourceList')
const {
  FEATURE_ARMS_OF_THE_ASTRAL_SELF,
  FEATURE_AWAKENING_OF_THE_ASTRAL_SELF,
  FEATURE_BODY_OF_THE_ASTRAL_SELF,
  FEATURE_EXTRA_ATTACK_MONK,
  FEATURE_VISAGE_OF_THE_ASTRAL_SELF,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ARMS_OF_THE_ASTRAL_SELF,
    name: `Руки астрального Я`,
    nameEn: `Arms of the Astral Self`,
    lvl: [3, 11, 17],
    text: `Ваше владение Ци позволяет Вам вызывать часть своего астрального Я.

В свой ход Вы можете бонусным действием потратить 1 очко Ци, чтобы призвать _Руки своего астрального Я_. Все видимые Вами в пределах 10 футов существа по Вашему выбору должны пройти испытание Ловкости или получить урон силовым полем, равный двум броскам Вашей кости боевых искусств.

В течение на 10 минут эти призрачные руки парят у Ваших плеч или окружа.т Ваши руки (по Вашему выбору). Вы определяете их внешний вид и они исчезнут раньше, если Вы станете недееспособны или умрёте.

Пока _Руки_ существуют, Вы получаете следующие преимущества

* Вы можете использовать модификатор Мудрости вместо модификатора Силы при выполнении проверок и испытаний Силы.
* Вы можете совершать _Руками_ безоружные атаки.
* Когда Вы совершаете _Руками_ безоружную атаку на своём ходу, Ваша дальность на 5 футов больше обычной.
* Для безоружных атак _Руками_ Вы можете использовать модификатор Мудрости вместо модификатора Силы или Ловкости для бросков атаки и урона, и они наносят урон силовым полем.`,
    source: {
      id: SOURCE_TCoE,
      page: 73,
    },
  },
  {
    id: FEATURE_VISAGE_OF_THE_ASTRAL_SELF,
    name: `Лик астрального Я`,
    nameEn: `Visage of the Astral Self`,
    lvl: 6,
    text: `Вы можете вызвать _Лик своего астрального Я_.

В свой ход Вы можете бонусным действием (либо частью призыва [Рук](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF})) потратить 1 очко Ци, чтобы вызвать _Лик своего астрального Я_ на 10 минут. Он исчезнет раньше, если Вы станете недееспособны или умрёте.

Призрачный _Лик_ покрывает Ваше лицо, как шлем или маска. Вы определяете его внешний вид.

Пока _Лик_ существует, Вы получаете следующие преимущества

# Астральный взгляд

Вы можете нормально видеть в темноте, как магической, так и немагической, на расстоянии до 120 футов.

# Мудрость духа

Вы получаете преимущество в проверках Мудрости (Проницательности) и Харизмы (Запугивания).

# Духовные слова

Говоря, Вы можете направлять свои слова к видимому Вами в пределах 60 футов существу так, чтобы только оно могло Вас слышать. Либо же Вы можете усилить свой голос так, чтобы Вас могли слышать все существа в пределах 600 футов.`,
    source: {
      id: SOURCE_TCoE,
      page: 73,
    },
  },
  {
    id: FEATURE_BODY_OF_THE_ASTRAL_SELF,
    name: `Тело астрального Я`,
    nameEn: `Body of the Astral Self`,
    lvl: 11,
    text: `Когда вызваны и _Руки_ и _Лик_, Вы можете проявить _Тело_ своего астрального Я (действие не требуется). Призрачное _Тело_ покрывает Вашу физическую форму как доспехи, соединяясь с _Руками_ и _Ликом_. Вы определяете его внешний вид.

Пока призрачное _Тело_ вызвано, Вы получаете следующие преимущества

# Отклонение энергии

Когда Вы получаете урон кислотой, холодом, огнём, молнией, звуком или силовым полем, Вы можете отклонить его реакцией. При этом получаемый Вами урон уменьшается на 1к10 + модификатор Мудрости (минимум на 1).

# Усиленные [Руки](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF})

Один раз, когда на своём ходу Вы попадаете по цели атакой [Руками](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF}), Вы можете нанести ей дополнительный урон, равный Вашей кости боевых искусств.`,
    source: {
      id: SOURCE_TCoE,
      page: 73,
    },
  },
  {
    id: FEATURE_AWAKENING_OF_THE_ASTRAL_SELF,
    name: `Пробуждение астрального Я`,
    nameEn: `Awakening of the Astral Self`,
    lvl: 17,
    text: `Ваша связь с Вашим астральным Я завершена и Вы можете проявлять его полный потенциал.

Вы можете бонусным действием потратить 5 очков Ци, вызывая [Руки](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF}), [Лик](FEATURE:${FEATURE_VISAGE_OF_THE_ASTRAL_SELF}) и [Тело](FEATURE:${FEATURE_BODY_OF_THE_ASTRAL_SELF}) Вашего астрального Я и _пробудить_ его на 10 минут. _Пробуждение_ закончится раньше, если Вы станете недееспособны или умрёте.

Пока Ваше астральное Я _пробуждено_, Вы получаете следующие преимущества

# Доспех духа

Вы получаете +2 к КД.

# Астральный шквал

Когда Вы используете способность [Дополнительная атака](FEATURE:${FEATURE_EXTRA_ATTACK_MONK}), чтобы атаковать дважды, Вы можете вместо этого атаковать трижды, если Вы совершаете все атаки [Руками](FEATURE:${FEATURE_ARMS_OF_THE_ASTRAL_SELF}).`,
    source: {
      id: SOURCE_TCoE,
      page: 74,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF,
  })
)
