const {SPELL_WISH} = require('./../../spellIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {
  CREATURE_VAMPIRE,
  CREATURE_VAMPIRE_SPAWN,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Вампиры',
    text: `Пробуждённые в бесконечной ночи [вампиры](CREATURE:${CREATURE_VAMPIRE}) жаждут жизни, которую они потеряли, и утоляют эту жажду, выпивая кровь живых. Вампиры ненавидят солнечный свет, поскольку его прикосновение жжёт их. Они не отбрасывают ни теней, ни отражений, и всякий вампир, желающий ходить незамеченным среди живых, вынужден держаться в темноте и подальше от отражающих поверхностей.`,
    source: {
      id: SOURCE_MM,
      page: 28,
    },
  },
  {
    header: 'Тёмные желания',
    text: `Даже если вампир сохраняет какие-либо воспоминания о прежней жизни, его эмоциональные привязанности увядают, так как его прежние чистые чувства искажаются. Любовь превращается в голодную одержимость, а дружба становится лишь горькой ревностью. Вместо проявлений эмоций вампиры желают физического проявления своей страсти, поэтому вампир, который ищет любовь, может зациклиться на юной красавице. Ребёнок может стать объектом страсти вампира, одержимого молодостью и возможностями. Другие окружают себя искусством, книгами или зловещими предметами, такими как устройства пыток или трофеи существ, ими убитых.`,
    source: {
      id: SOURCE_MM,
      page: 28,
    },
  },
  {
    header: 'Рождённые смертью',
    text: `Большинство жертв вампира становятся [порождениями вампира](CREATURE:${CREATURE_VAMPIRE_SPAWN}) — хищными созданиями с вампирским голодом к крови, но находящимися под контролем вампира, который их создал. Если истинный вампир позволит порождению выпить кровь из своего тела, порождение превратится в истинного вампира, не подконтрольного своему хозяину. Немногие вампиры готовы отказаться от этого влияния. Порождение вампира обретает собственную волю, когда его создатель погибает.`,
    source: {
      id: SOURCE_MM,
      page: 28,
    },
  },
  {
    header: 'Прикованные к могиле',
    text: `Каждый вампир связан со своим гробом, склепом, или местом захоронения, в котором он должен покоиться днём. Если вампир не был официально похоронен, он должен находиться в земле на глубине в 1 фут, в месте его превращения в нежить. Вампир может перемещать своё место захоронения, транспортируя гроб, или достаточное количество могильной земли. Некоторые вампиры оборудуют несколько мест для своего отдыха.`,
    source: {
      id: SOURCE_MM,
      page: 28,
    },
  },
  {
    header: 'Природа нежити',
    text: `Ни вампир, ни порождение вампира не нуждаются в воздухе.`,
    source: {
      id: SOURCE_MM,
      page: 28,
    },
  },
  {
    header: 'Вампирское проклятье',
    text: `_«Внимайте мне и бойтесь вампира. Бойтесь его ледяной прелести. Страшитесь его очарования. Бегите от его проклятия. Но превыше всего берегитесь бледного лорда, ибо вампиры терпеть не могут принадлежать к простому народу._

_Как следует предохраняться от Зверя? Не выходите из укрытия в тёмные ночи, ибо вампиры люят такие ночи больше всего. Если идти необходимо — держитесь света луны и берегитесь. Всегда носите с собой благословение и знаки своего бога._

_Но помните: мой дом — моя крепость, если он хорошо защищён. Слыша стук в ночи, будьте осторожны. Не пускайте в дом незнакомцев. Если это друг — поглядите на него. Нет ли у него чрезмерной бледности и следов измождения? Нет лид каких=то следов на шее? Нет ли грязи на одеждах? Если нужда не слишком велика — откажите от дома всем, кроме тех, кому верите больше всего._

_И если Зверь всё же найдёт дорогу в ваш дом — бегите. Бросьте семью и любимых. Вы не спасёте их, если примете бой. Вы никогда их больше не увидите. Но они увидят вас, бледного и улыбающегося, зовущего их в ночи.»_

— Древний текст, записанный на высохшей, крошащейся под пальцами бумаге. На обложке изображены все священные символы, которые вы когда-либо видели… и ещё несколько таких, о которых вы знать не знали.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Страд фон Зарович',
    text: `Блистательный мыслитель и выдающийся воин при жизни, Страд фон Зарович сражался в бесчисленном множестве битв за свой народ. Когда войны и убийства наконец лишили его молодости и силы, он поселился в отдалённой долине Баровии и построил замок на возвышенности, с которой мог наблюдать за своими землями. Его брат Сергей прибыл, чтобы жить вместе с ним в замке Равенлофт, став советником и постоянным спутником Страда.

В своём брате Страд видел всё то, чего лишился сам. Сергей был молод и красив, в то время как Страд стал старым и покрытым шрамами. Обида изменила их отношения, со временем превратившись в ненависть. Возлюбленная Страда, Татьяна, отвергла его в пользу Сергея, за которого пообещала выйти замуж.

В отчаянной попытке завоевать сердце Татьяны, Страд заключил сделку с тёмными силами, сделавшую его бессмертным. На свадьбе Сергея и Татьяны он схватился со своим братом и убил его. Татьяна бежала и сбросилась со стен Равенлофта. Охрана Страда, увидев в нём чудовище, расстреляла его из луков. Но он не умер. Страд стал вампиром. Первым вампиром, согласно многочисленным сказаниям.

Спустя века после трансформации, жажда Страда к жизни только лишь возросла. Он блуждает по своему мрачному замку, проклиная живущих за кражу того, что у него было, и не признаёт свою вину в произошедшей из-за него трагедии.`,
    source: {
      id: SOURCE_MM,
      page: 28,
    },
  },
  {
    header: 'Вампиры как игровые персонажи',
    text: `Игровые параметры персонажа, ставшего [порождением вампира](CREATURE:${CREATURE_VAMPIRE_SPAWN}), а затем и настоящим [вампиром](CREATURE:${CREATURE_VAMPIRE}), не меняются, за исключением того, что его Сила, Ловкость и Телосложение теперь имеют значение 18, если до этого не были выше. Кроме того, персонаж получает вампирское сопротивление урону, тёмное зрение, особенности и действия. Броски атаки и урона вампира базируются на Силе. СЛ испытания Очарования равна 8 + бонус мастерства вампира + модификатор Харизмы вампира. Мировоззрение персонажа становится законно-злым, и Мастер может взять управление персонажем, пока вампиризм не пройдёт благодаря заклинанию [исполнение желаний](SPELL:${SPELL_WISH}), или пока персонажа не убьют с последующим воскрешением.`,
    source: {
      id: SOURCE_MM,
      page: 28,
    },
  },
]
