const {
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  GOD_ANNAM,
} = require('./../../godIdList')
const {
  PC_RACE_ELF,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')
const {
  CREATURE_CLOUD_GIANT,
  CREATURE_FIRE_GIANT,
  CREATURE_FROST_GIANT,
  CREATURE_HILL_GIANT,
  CREATURE_STONE_GIANT,
  CREATURE_STORM_GIANT,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Дети Всеотца',
    text: `Во времена до прихода [эльфов](PC_RACE:${PC_RACE_ELF}) и [людей](PC_RACE:${PC_RACE_HUMAN}), когда все драконы были молоды, [Аннам Всеотец](GOD:${GOD_ANNAM}) привёл первых великанов в мир. Эти великаны являлись отражениями его божественных потомков, а также детьми мира, рождёнными от сонности гор, вспыльчивости вулканов и дыхания ураганов.

По замыслу [Аннама](GOD:${GOD_ANNAM}) великаны должны были стать хозяевами мира. Он дал им громадный рост, дабы они свысока взирали на свои владения. Он создал иерархию среди своих детей — Уложении — так, что каждый знал свой статус, уважал статус другого, и понимал, кто стоит ближе всего к колену [Всеотца Аннама](GOD:${GOD_ANNAM}).

Объединённые одной целью, дети [Аннама](GOD:${GOD_ANNAM}) построили Осторию — легендарную империю великанов, в которой они жили согласно Уложению. [Штормовые великаны](CREATURE:${CREATURE_STORM_GIANT}) властвовали надо всеми под собой и над собой. Они правили океанами из подводных крепостей и управляли землёй из замков в небе. [Облачные великаны](CREATURE:${CREATURE_CLOUD_GIANT}) создали огромные парящие города и являлись надёжной правой рукой [штормовых великанов](CREATURE:${CREATURE_STORM_GIANT}). [Каменные](CREATURE:${CREATURE_STONE_GIANT}) и [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) селились на вершинах гор и в пещерах под ними, где они вырезали и выковывали величайшие произведения искусства и ремесла великанов. [Ледяные великаны](CREATURE:${CREATURE_FROST_GIANT}) защищали Осторию могуществом своих рук, не только на ледяных пиках и ледниках, но на каждой границе. [Холмовые великаны](CREATURE:${CREATURE_HILL_GIANT}) распространились по всем другим землям, подчинив меньших существ с помощью грубой силы.`,
    source: {
      id: SOURCE_VGTM,
      page: 20,
    },
  },
]
