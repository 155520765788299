const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_REGHEDJIC} = require('./../../languageIdList')
const {LANG_SUBGROUP_ILLUSKI} = require('./../../languageSubGroupList')

module.exports = {
  id: LANG_REGHEDJIC,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_ILLUSKI,
  name: {
    nominative: 'Регхедский',
    genitive: 'Регхедского',
    instrumental: 'Регхедским',
    prepositional: 'Регхедском',
  },
  nameEn: 'Reghedjic',
  isReady: false,
  isRealLang: true,
}
