const {
  WEAPON_SPECIAL,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_DOUBLE_BLADED_SCIMITAR} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_ERFTLW} = require('./../../../sourceList')

module.exports = {
  id: GEAR_DOUBLE_BLADED_SCIMITAR,
  genderId: GENDER_MALE,
  name: 'Двуклинковый скимитар',
  nameAlt: 'Валенарский клинок',
  nameByCase: {
    nominative: 'двуклинковый скимитар',
    genitive: 'двуклинкового скимитара',
    accusative: 'двуклинковый скимитар',
    instrumental: 'двуклинковым скимитаром',
  },
  nameEn: 'Double-bladed Scimitar',
  damage: {
    diceCount: 2,
    diceType: 4,
  },
  description: `Двуклинковый скимитар — знаковое оружие валенарских эльфов Эберрона. С обеих концов рукояти из ценных пород дерева крепится по длинному изогнутому клинку. Выкованные с помощью отточенных веками техник, эти лезвия прочны, остры и удивительно легки. Каждое такое оружие — шедевр оружейного дела, поэтому двуклинковый скимитар стоит дорого и мало кто может позволить обладать им. Обычно считается, что Валенарский клинок в руках неэльфа был украден или взят у павшего врага, и Валенарский эльф может потребовать его возвращения или бросить вызов обладателю клинка, чтобы тот доказал, что достоин им владеть.
     
**Особое.** Когда Вы атакуете двуклинковым скимитаром частью действия Атака на своём ходу, сразу после этого Вы можете бонусным действие совершить им ещё одну рукопашную атаку. Эта атака наносит не 2к4, а 1к4 рубящего урона.`,
  damageType: DAMAGE_SLASHING,
  cost: 10000,
  weight: 6,
  source: {
    id: SOURCE_ERFTLW,
    page: 22,
  },
  weaponPropList: [
    WEAPON_TWO_HANDED,
    WEAPON_SPECIAL,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
