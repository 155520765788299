const {
  WEAPON_HEAVY,
  WEAPON_REACH,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const polearmDescription = require('./../../constants/polearmDescription')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_HALBERD} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_HALBERD,
  genderId: GENDER_FEMALE,
  name: 'Алебарда',
  nameByCase: {
    nominative: 'алебарда',
    genitive: 'алебарды',
    accusative: 'алебарду',
    instrumental: 'алебардой',
  },
  nameEn: 'Halberd',
  description: `${polearmDescription}
  
Комбинируя досягаемость пики с лезвием топора, алебарда позволяет умелому бойцу использовать множество боевых приёмов. Хват у основания древка даст большую дальность ударов, а хват ближе к лезвию повысит точность атак.`,
  damage: {
    diceType: 10,
    diceCount: 1,
  },
  cost: 2000,
  damageType: DAMAGE_SLASHING,
  weight: 6,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 109,
    },
  ],
  weaponPropList: [
    WEAPON_TWO_HANDED,
    WEAPON_REACH,
    WEAPON_HEAVY,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
