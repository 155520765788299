const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS} = require('./../../../sourceList')

const {
  SPELL_CHARM_PERSON,
  SPELL_CONFUSION,
  SPELL_DOMINATE_PERSON,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_GASEOUS_FORM,
  SPELL_GREATER_INVISIBILITY,
  SPELL_HASTE,
  SPELL_INVISIBILITY,
  SPELL_MIRROR_IMAGE,
  SPELL_PASSWALL,
} = require('./../../../spellIdList')
const {
  FEATURE_AURA_OF_TREACHERY_2016_12_19,
  FEATURE_BLACKGUARD_S_ESCAPE_2016_12_19,
  FEATURE_CHANNEL_DIVINITY_CONJURE_DUPLICATE_2016_12_19,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CHANNEL_DIVINITY_POISON_STRIKE_2016_12_19,
  FEATURE_ICON_OF_DECEIT_2016_12_19,
  FEATURE_OATH_OF_TREACHERY_SPELLS_2016_12_19,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_TREACHERY_SPELLS_2016_12_19,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_CHARM_PERSON,
      SPELL_CONFUSION,
      SPELL_DOMINATE_PERSON,
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_GASEOUS_FORM,
      SPELL_GREATER_INVISIBILITY,
      SPELL_HASTE,
      SPELL_INVISIBILITY,
      SPELL_MIRROR_IMAGE,
      SPELL_PASSWALL,
    ],
    source: {
      id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
      page: 2,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_CONJURE_DUPLICATE_2016_12_19,
    name: `Божественный канал: Призыв дубликата`,
    nameEn: `Channel Divinity: Conjure Duplicate`,
    lvl: 3,
    text: `Вы действием создаёте визуальную иллюзию себя, которая существует 1 минуту или пока Вы не прервёте концентрацию (как при концентрации на заклинании). Иллюзия появляется в выбранном и видимом Вами в пределах 30 футов свободном пространстве. Иллюзия выглядит точно так же, как Вы; она вашего размера, не производит звуков, не занимает своё пространство, и на неё не действуют атаки и урон. Вы можете бонусным действием в свой ход переместить иллюзию в видимое Вами пространство на расстояние до 30 футов, но иллюзия должна оставаться в пределах 120 футов от Вас.

Пока дубликат существует, Вы можете сотворять заклинания, как если бы Вы находились в его пространстве, но Вы должны использовать свои собственные чувства. Кроме того, когда и Вы, и Ваша иллюзия находитесь в пределах 5 футов от существа, которое может видеть иллюзию, Вы получаете преимущество на броски атаки по этому существу, учитывая, насколько жуткая иллюзия.`,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19,
    source: {
      id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
      page: 2,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_POISON_STRIKE_2016_12_19,
    name: `Божественный канал: Ядовитый удар`,
    nameEn: `Channel Divinity: Poison Strike`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), делая оружие смертоноснее.

Бонусным действием Вы касаетесь одного оружия или боеприпасов и вызываете на них особый яд. Яд существует 1 минуту. В следующий раз, когда Вы попадёте по цели атакой этим оружием или боеприпасом, цель получит урон ядом сразу после атаки. Урон от яда равен 2к10 + Ваш уровень паладина или 20 + Ваш уровень паладина, если у Вас было преимущество на атаку.`,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19,
    source: {
      id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
      page: 2,
    },
  },
  {
    id: FEATURE_AURA_OF_TREACHERY_2016_12_19,
    name: `Аура вероломства`,
    nameEn: `Aura of Treachery`,
    lvl: 7,
    text: `Вы излучаете ауру раздора, которая дает Вам следующие преимущества.

# Проредить стадо

Вы получаете преимущество к рукопашным атакам по любому существу, в пределах 5 футов от которого находятся его союзник.

# Подлый удар

Если существо в пределах 5 футов от Вас промахивается по Вам рукопашной атакой, Вы можете реакцией заставить его перебросить эту атаку по другому существу по Вашему выбору, которое тоже находится в пределах 5 футов от атакующего. Способность проваливается и тратится впустую, если атакующий невосприимчив к очарованию. Вы можете использовать эту способность трижды и восстанавливаете все использования, когда завершаете короткий или длинный отдых.`,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19,
    source: {
      id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
      page: 2,
    },
  },
  {
    id: FEATURE_BLACKGUARD_S_ESCAPE_2016_12_19,
    name: `Трусливый побег`,
    nameEn: `Blackguard’s Escape`,
    lvl: 15,
    text: `У Вас есть способность ускользать от врагов.

Сразу после того, как по Вам попадают атакой, Вы можете реакцией стать невидимым и телепортироваться на расстояние до 60 футов в видимое Вами место. Вы остаетесь невидимым до конца своего следующего хода или до тех пор, пока не атакуете, не нанесете урон или не заставите существо пройти испытание.

Как только Вы воспользуетесь этой способностью, Вы должны завершить короткий или длинный отдых, прежде чем сможете использовать её снова.`,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19,
    source: {
      id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
      page: 2,
    },
  },
  {
    id: FEATURE_ICON_OF_DECEIT_2016_12_19,
    name: `Воплощение обмана`,
    nameEn: `Icon of Deceit`,
    lvl: 20,
    text: `Вы получаете способность источать чувство предательства.

Вы можете действием магическим образом стать воплощением обмана, получая на 1 минуту следующие преимущества:
    
* Вы невидимы.
* Если существо в свой ход наносит Вам урон, оно должно пройти испытание Мудрости (СЛ Ваших заклинаний) или Вы контролируете его следующее действие (если Вы дееспособны, когда оно его совершает). Существо автоматически преуспевает в этом испытании, если оно невосприимчиво к очарованию.
* Если у Вас есть преимущество к броску атаки, то Вы получаете бонус к урону, равный Вашему уровню паладина.

Как только Вы воспользуетесь этой способностью, то не сможете использовать её снова, пока не закончите длинный отдых.`,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19,
    source: {
      id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
      page: 2,
    },
  },
]
