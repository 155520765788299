const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_8} = require('./../../../../crList')
const {CREATURE_STIRGE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Кровопийца',
  nameEn: 'Stirge',
  id: CREATURE_STIRGE,
  description: `Это ужасное летающее существо выглядит как нечто среднее между крупной летучей мышью и гигантским комаром. Его ноги заканчиваются острыми клешнями, а длинный, похожий на иглу хоботок разрезает воздух в поисках пищи.

Кровопийцы питаются кровью живых существ, присасываясь и иссушая их очень медленно. Несмотря на то, что они не представляют большой опасности в небольших группах, целая стая кровопийц может быть страшной угрозой, перепрыгивая с одной цели на другую, тем самым ослабляя их.

**Кровосос.** Кровопийцы находят уязвимые места на теле жертвы, плотно закрепляются на ней при помощи сильных лап-клещей, а при помощи хоботка прокусывают тело жертвы. Как только кровопийца насыщается, он открепляется от тела и улетает прочь переваривать пищу.`,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 181,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 11,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 6,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_1_8,
  actionList: [
    {
      name: 'Высасывание крови',
      description: `Пока ★СУЩЕСТВО★ прикреплён, он не совершает атак. Вместо этого в начале каждого следующего хода ★СУЩЕСТВО★ цель теряет 5 (1к4+3) хитов из-за потери крови.

★СУЩЕСТВО★ может отцепиться, потратив 5 футов перемещения. ★Он★ делает это после того, как выпивает на 10 хитов крови из цели или если цель умирает. Любое существо, в том числе цель, может действием оторвать ★СУЩЕСТВО★.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
          comment: ', и ★СУЩЕСТВО★ прикрепляется к цели',
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
