module.exports = (text, charIndex = 0) => {
  if (text && typeof text === 'string') {
    const start = charIndex
      ? text.slice(0, charIndex)
      : ''
    const letter = text[charIndex].toUpperCase()
    const end = text.slice(charIndex + 1)

    return `${start}${letter}${end}`
  }

  return ''
}
