const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {MAGIC_ILLUSION} = require('./../../../../../magicList')
const {SOURCE_AI} = require('./../../../../../sourceList')
const {SPELL_DISTORT_VALUE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_DISTORT_VALUE,
  name: 'Искажение ценности',
  nameEn: 'Distort Value',
  description: `Вам нужно выжать ещё несколько золотых у торговца, когда Вы пытаетесь продать ему эту странную статую осьминога, освобождённую Вами из храма хаоса? Или же занизить ценность некоторых волшебных вещей во время визита сборщика налогов? Вас выручит Искажение ценности.

Вы можете сотворить это заклинание на предмет, помещающийся в куб со стороной не более 1 фута, добавляя ему иллюзорный лоск и блеск, что удваивает его воспринимаемую ценность.

Или же Вы можете, наоборот, ополовинить его воспринимаемую ценность с помощью иллюзорных царапин, вмятин и других неприглядных особенностей.

Любой, кто осматривает этот предмет, может выяснить его настоящую ценность успешной проверкой Интеллекта (Анализ).`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, максимальный размер предмета увеличивается на 1 фут за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_ILLUSION,
  range: 0,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_HOUR, count: 8 },
  castTime: { timeId: TIME_MINUTE, count: 1 },
  source: {
    id: SOURCE_AI,
    page: 76,
  },
}
