const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_OTYUGH} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_OTYUGH} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Отидж',
  nameEn: 'Otyugh',
  id: CREATURE_OTYUGH,
  description: [
    {
      header: 'Отидж',
      text: `Отидж — это гротескное существо с луковицеобразным телом на трёх крепких ногах, глаза и нос которого расположены на стебле, растущем из верхней части раздутого тела. Два упругих щупальца заканчиваются остроконечными листовидными придатками, которыми отидж загребает пищу в разинутую пасть.

Отидж зарывается под кучами отбросов и падали, оставляя на поверхности только сенсорный стебель. Когда съедобное существо проходит мимо, щупальца вырываются из под мусора и хватают добычу.

Отиджи пользуются всеми возможностями засады, чтобы можно было пожрать добычу. Они используют ограниченную форму телепатии для заманивания добычи к логову, иногда выдавая себя за кого-то другого.`,
      source: {
        id: SOURCE_MM,
        page: 231,
      },
    },
    {
      header: 'Живущий во тьме',
      text: `Отидж терпит свет только в случае, когда большие кучи падали и мусора находятся в пределах досягаемости. В пустошах они обитают в стоячих болотах, замусоренных прудах и влажных лесных лощинах. Запах могильников, городских канализационных коллекторов, деревенских навозных куч и скотных загонов привлекает их в цивилизованные области.

Поскольку отиджей интересует только пища, в их гнёздах иногда скапливаются различные сокровища от их жертв вперемешку с хламом.`,
      source: {
        id: SOURCE_MM,
        page: 231,
      },
    },
    {
      header: 'Симбиотические охранники',
      text: `Разумные обитающие под землёй существа сосуществуют с отиджами, используя их как утилизаторов мусора. На таком обильном питании отиджи жиреют в своих лежбищах, более не движимые другими желаниями и мотивами. Эта неподвижная прожорливость делает их надёжными охранниками. До тех пор, пока он накормлен, отидж воздерживается от нападения на других существ. Тем не менее, потенциальный хозяин отиджа может недооценить потребное количество отходов, падали и мяса, чтобы удержать его от блужданий в поисках еды.

Немало хозяев было сожрано «тренированными» отиджами после того, как пища заканчивалась в его лежбище.`,
      source: {
        id: SOURCE_MM,
        page: 231,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 231,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 19,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 6,
  },
  saveThrowList: [
    PARAM_CON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_OTYUGH,
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Ограниченная телепатия',
      description: `★СУЩЕСТВО★ может магическим образом передавать простые послания и образы любому существу в пределах 120 футов, понимающему любой язык. Эта телепатия не позволяет существу давать телепатический ответ.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, и две _Щупальцами_.`,
    },
    {
      name: 'Укус',
      description: `Если цель — существо, она должна пройти испытание Телосложения СЛ 15 от болезни, иначе станет отравленной, пока болезнь не будет вылечена. Через каждые прошедшие 24 часа цель должна повторять испытание, уменьшая максимум хитов на 5 (1к10) при провале. При успехе болезнь излечивается. Цель умирает, если болезнь уменьшает максимум её хитов до 0. Это уменьшение хитов длится до тех пор, пока болезнь не будет вылечена.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Щупальце',
      description: `Если размер цели не больше Среднего, она становится схваченной (СЛ высвобождения 13) и обездвиженой, пока не перестанет быть схваченной. ★СУЩЕСТВО★ имеет два щупальца, и каждое может схватить по одной цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 3,
          },
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
          },
        ],
      },
    },
    {
      name: 'Удар щупальцем',
      description: `★СУЩЕСТВО★ ударяет схваченных существ друг о друга или о твёрдую поверхность. Каждое существо должно пройти испытание Телосложения СЛ 14, иначе получит дробящий урон 10 (2к6+3) и станет ошеломлённым до конца следующего хода отиджа. При успешном испытании существо получает половину дробящего урона и не становится ошеломлённым.`,
    },
  ],
  genderId: GENDER_MALE,
}
