const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_MODRONE,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_BLUDGEONING,
} = require('./../../../../damageTypeList')
const {
  modronDescriptionList,
  modronNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AXIOMATIC_MIND,
  ABILITY_DISINTEGRATION,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_TRIDRONE} = require('./../../../../creatureIdList')
const {GEAR_JAVELIN} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_MODRONE} = require('./../../../../languageIdList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Тридрон',
  nameEn: 'Tridrone',
  id: CREATURE_TRIDRONE,
  description: [
    'Тридроны выглядят как перевёрнутые пирамиды. Они ведут младших модронов в бой.',
    ...modronDescriptionList,
  ],
  note: modronNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_MODRONE,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 213,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 12,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_MODRONE,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_AXIOMATIC_MIND,
    ABILITY_DISINTEGRATION,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки _Кулаком_ или три атаки _Метательным копьём_.`,
    },
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      gearId: GEAR_JAVELIN,
    },
  ],
  genderId: GENDER_MALE,
}
