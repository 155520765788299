const {SOURCE_ROT} = require('./../../../../sourceList')
const {cultOfDragonDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_CULTIST,
  CREATURE_DRAGON_CULT_INITIATE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Послушник Культа дракона',
  nameEn: 'Dragon Cult Initiate',
  id: CREATURE_DRAGON_CULT_INITIATE,
  parentId: CREATURE_CULTIST,
  description: cultOfDragonDescriptionList,
  source: {
    id: SOURCE_ROT,
    page: 10,
  },
}
