const {
  GEAR_GREATSWORD,
  GEAR_LONGSWORD,
  GEAR_SHIELD,
  GEAR_SHORTSWORD,
} = require('./../../../gearIdList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const swordDescription = require('./../../constants/swordDescription')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GENDER_MALE} = require('./../../../genderList')
const {WEAPON_VERSATILE} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_LONGSWORD,
  genderId: GENDER_MALE,
  name: 'Длинный меч',
  nameByCase: {
    nominative: 'длинный меч',
    genitive: 'длинного меча',
    accusative: 'длинный меч',
    instrumental: 'длинным мечом',
  },
  nameEn: 'Longsword',
  description: [
    {
      header: 'Длинный меч',
      text: `Универсальность длинного меча предлагает отличный баланс между скоростью [короткого меча](GEAR:${GEAR_SHORTSWORD}) и огромной силой [двуручного меча](GEAR:${GEAR_GREATSWORD}). Как одноручное оружие, он позволяет герою наносить быстрые, резкие удары, в то же время позволяя использовать [щит](GEAR:${GEAR_SHIELD}) в качестве дополнительной защиты. Используемые обеими руками, длинные мечи концентрируют в нанесении ударов всю силу бойца.

Длинный меч — отличный выбор, когда Вы не уверены, с какой опасностью столкнетесь. Независимо от того, связываетесь ли Вы с большим количеством мелких противников, которых нужно быстро уничтожить, или мощным зверем, которого можно победить только сильными ударами, длинный меч подойдёт вам всегда!`,
      source: {
        id: SOURCE_AYAGWnW,
        page: 106,
      },
    },
    swordDescription,
  ],
  damage: {
    diceType: 8,
    diceCount: 1,
  },
  cost: 1500,
  damageType: DAMAGE_SLASHING,
  weight: 3,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    {
      id: WEAPON_VERSATILE,
      value: {
        diceCount: 1,
        diceType: 10,
      },
    },
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
