const upLetter = require('./../../../../utils/upLetter')
const joinText = require('./../../../../utils/joinText')

const {gearCollection} = require('./../../../gearList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {MAGIC_ITEM_IMBUED_WOOD_FOCUS} = require('./../../../magicItemIdList')
const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../magicItemTypeList')
const {
  SOURCE_ERFTLW,
  SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
} = require('./../../../sourceList')

const gearIdList = require('./gearIdList')
const generateDamageRollText = require('./generateDamageRollText')
const siblingIdList = require('./siblingIdList')
const woodList = require('./woodList')

const gearText = joinText(
  gearIdList.map(
    id => `[${gearCollection[id].name}](GEAR:${id})`
  )
)

const tableText = woodList.reduce(
  (text, { name: {nominative: name}, damageTypeIdList }) => `${text}
| ${upLetter(name)} | ${upLetter(generateDamageRollText(damageTypeIdList))} |`,
  `
| Дерево | Тип урона |
|--------|-----------|`
)

module.exports = {
  id: MAGIC_ITEM_IMBUED_WOOD_FOCUS,
  name: 'Деревянная фокусировка',
  nameEn: 'Imbued Wood Focus',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_COMMON,
  gearType: gearIdList,
  attunement: true,
  description: `Деревянная фокусировка — это ${gearText} из дерева, чья древесина пропиталась экстрапланарной энергией. Если Вы — заклинатель, то можете использовать её как заклинательную фокусировку.

Если Вы сотворяете наносящее урон заклинание, используя _Деревянную фокусировку_ как заклинательную фокусировку, то получаете бонус +1 к одному броску урона этого заклинания, если оно наносит урон соответствующего этому дереву типа.

${tableText}`,
  genderId: GENDER_FEMALE,
  childIdList: siblingIdList,
  source: [
    {
      id: SOURCE_ERFTLW,
      page: 277,
    },
    {
      id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
      page: 1,
    },
  ],
}
