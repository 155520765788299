const textTransformRuleList = require ('./../constants/textTransformRuleList')
const { GENDER_MALE, GENDER_MULTIPLE } = require ('./../constants/genderList')

const transformText = (genderId = GENDER_MALE) =>
    text =>
      textTransformRuleList.reduce(
        (resultText, { text, to }) => resultText.replace(
          new RegExp(text, 'g'),
          to[genderId]
        ),
        text
      )

module.exports = transformText
