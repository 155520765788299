const {CAT_CLOTHES} = require('./../../../../gearCategoryList')
const {GENDER_MALE} = require('./../../../../genderList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {MAGIC_ITEM_BRACER_OF_FLYING_DAGGERS} = require('./../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {SOURCE_WDH} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_BRACER_OF_FLYING_DAGGERS,
  name: 'Наруч летающих кинжалов',
  nameEn: 'Bracer of Flying Daggers',
  type: MGC_TYPE_WONDROUS_ITEM,
  gearCategory: CAT_CLOTHES,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Этот браслет выглядит так, будто к нему прикреплены маленькие кинжальчики.

Вы можете действием вытянуть из наруча один или два магических [кинжала](GEAR:${GEAR_DAGGER}) и немедленно их метнуть, совершив каждым кинжалом дальнобойную атаку. [Кинжал](GEAR:${GEAR_DAGGER}) исчезает и при попадании, и при промахе, и если не метнуть его немедленно. [Кинжалы](GEAR:${GEAR_DAGGER}) в наруче не кончаются.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_WDH,
    page: 192,
  },
}
