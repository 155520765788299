const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_DEATH_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_DMG} = require('./../../../sourceList')
const necromancySpellIdListByLvlList = require('./../../../necromancySpellIdListByLvlList')

const {
  SPELL_ANIMATE_DEAD,
  SPELL_ANTILIFE_SHELL,
  SPELL_BLIGHT,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CLOUDKILL,
  SPELL_DEATH_WARD,
  SPELL_FALSE_LIFE,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_RAY_OF_SICKNESS,
  SPELL_VAMPIRIC_TOUCH,
} = require('./../../../spellIdList')
const {
  FEATURE_BONUS_PROFICIENCY_DEATH_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_TOUCH_OF_DEATH,
  FEATURE_DEATH_DOMAIN_SPELLS,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_STRIKE_DEATH_DOMAIN,
  FEATURE_IMPROVED_REAPER,
  FEATURE_INESCAPABLE_DESTRUCTION,
  FEATURE_REAPER,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_DEATH_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_ANIMATE_DEAD,
      SPELL_ANTILIFE_SHELL,
      SPELL_BLIGHT,
      SPELL_BLINDNESS_DEAFNESS,
      SPELL_CLOUDKILL,
      SPELL_DEATH_WARD,
      SPELL_FALSE_LIFE,
      SPELL_RAY_OF_ENFEEBLEMENT,
      SPELL_RAY_OF_SICKNESS,
      SPELL_VAMPIRIC_TOUCH,
    ],
    source: {
      id: SOURCE_DMG,
      page: 97,
    },
  },
  {
    id: FEATURE_BONUS_PROFICIENCY_DEATH_DOMAIN,
    name: `Бонусное владение`,
    nameEn: `Bonus Proficiency`,
    lvl: 1,
    text: `Жрец, выбравший этот домен, на 1 уровне осваивает владение воинским оружием.`,
    source: {
      id: SOURCE_DMG,
      page: 97,
    },
  },
  {
    id: FEATURE_REAPER,
    name: `Жнец`,
    nameEn: `Reaper`,
    lvl: 1,
    text: `Жрец изучает один заговор из школы Некромантии из списка любого класса.

Когда этот жрец сотворяет заговор школы Некромантии, в обычных условиях нацеливающийся только на одно существо, это заклинание может нацелиться на двух существ, находящихся в пределах дистанции и при этом в пределах 5 футов друг от друга.`,
    spellIdList: necromancySpellIdListByLvlList[0],
    source: {
      id: SOURCE_DMG,
      page: 97,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_TOUCH_OF_DEATH,
    name: `Божественный канал: Касание смерти`,
    nameEn: `Channel Divinity: Touch of Death`,
    lvl: 2,
    text: `Жрец может использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}) для уничтожения касанием чужой жизненной силы.

Когда жрец попадает по существу рукопашной атакой, он может использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы причинить цели дополнительный урон некротической энергией, равный 5 + его удвоенный уровень жреца.`,
    source: {
      id: SOURCE_DMG,
      page: 98,
    },
  },
  {
    id: FEATURE_INESCAPABLE_DESTRUCTION,
    name: `Неизбежное разрушение`,
    nameEn: `Inescapable Destruction`,
    lvl: 6,
    text: `Способность жреца проводить негативную энергию улучшается.

Урон некротической энергией, причиняемый заклинаниями жреца и [Касанием смерти](FEATURE:${FEATURE_CHANNEL_DIVINITY_TOUCH_OF_DEATH}), игнорируют сопротивление урону некротической энергией.`,
    source: {
      id: SOURCE_DMG,
      page: 98,
    },
  },
  {
    id: FEATURE_DIVINE_STRIKE_DEATH_DOMAIN,
    name: `Божественный удар`,
    nameEn: `Divine Strike`,
    lvl: 8,
    text: `Жрец получает способность наполнять удары своего оружия некротической энергией.

Один раз в каждый свой ход, когда жрец попадает по существу атакой оружием, он может причинить цели дополнительный урон некротической энергией 1к8.

Когда он достигает 14 уровня, дополнительный урон возрастает до 2к8.`,
    source: {
      id: SOURCE_DMG,
      page: 98,
    },
  },
  {
    id: FEATURE_IMPROVED_REAPER,
    name: `Улучшенный жнец`,
    nameEn: `Improved Reaper`,
    lvl: 17,
    text: `Когда жрец сотворяет заклинание школы Некромантии с 1 по 5 уровень, нацеливающееся только на одно существо, это заклинание может нацелиться на двух существ, находящихся в пределах дистанции и при этом в пределах 5 футов друг от друга.

Если заклинание уничтожает материальные компоненты, жрец должен предоставить по полному набору для каждой цели.`,
    source: {
      id: SOURCE_DMG,
      page: 98,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_DEATH_DOMAIN,
  })
)
