const {ABILITY_AURA_OF_MURDER} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CREATURE_REAPER_OF_BHAAL} = require('./../../../../creatureIdList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {bhaalFollowerList} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPELL_CHARM_PERSON,
  SPELL_DISGUISE_SELF,
  SPELL_SANCTUARY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')

module.exports = {
  name: 'Жнец Баала',
  nameEn: 'Reaper of Bhaal',
  id: CREATURE_REAPER_OF_BHAAL,
  description: bhaalFollowerList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_BGDIA,
    page: 237,
  },
  armor: 15,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 13,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AURA_OF_MURDER,
  ],
  spellCast: {
    baseStat: PARAM_WIT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CHARM_PERSON,
          SPELL_DISGUISE_SELF,
          SPELL_SANCTUARY,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Кинжалом_ и использует _Окутывание_.`,
    },
    {
      gearId: GEAR_DAGGER,
    },
    {
      name: 'Окутывание',
      description: `★СУЩЕСТВО★ магически становится ★невидимым★ до начала своего следующего хода. Невидимость прекращается, если ★СУЩЕСТВО★ совершит бросок атаки или урона или сотворит заклинание.`,
    },
  ],
}
