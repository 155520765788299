const {CREATURE_CULTIST} = require('./../../creatureIdList')
const {SOURCE_MTOF} = require('./../../sourceList')
const {GOD_DENDAR} = require('./../../godIdList')

module.exports = [
  {
    header: 'Порождения звёзд',
    text: `Материальный план представляет только небольшой кусочек мультивселенной. За пределами хорошо изученных планов существования лежат измерения, что смертельно опасны для смертных. Некоторые настолько враждебны, что даже мгновенного контакта с таким местом достаточно, чтобы свести смертного с ума. Но есть существа, для которых подобные измерения являются родными: существа, что вечно голодают, ищут, завывают, а иногда ещё и видят сновидения. Эти Древние старше всех прочих смертных рас и всегда внушают ужас разумным гуманоидам.

Однако каким бы сильным ни было желание вторгнуться и захватить Материальный план, Древние не могут или не хотят покидать свои измерения. Одни заточены в своих измерениях внешними силами, некоторые неразрывно связаны со своими домашними реальностями, а другие просто не могут найти даже подобие выхода из собственного измерения.

**Предвестники рока.** Существа известные как порождения звёзд — это предвестники, рядовые солдаты, или лейтенанты Древних, способные принимать форму гуманоидов, чтобы путешествовать по Материальному плану. Чаще всего они пребывает следом за кометой… или, возможно, этот феномен предупреждает о том, что поблизости есть порождение звёзд, и оно доступно для общения. Если знамения верны, то колдуны и [культисты](CREATURE:${CREATURE_CULTIST}) спешат собраться вместе, читают вслух мерзкие слова и проводят безумные ритуалы, что направляют порождение пылающих звёзд в этот мир.`,
    source: {
      id: SOURCE_MTOF,
      page: 220,
    },
  },
  {
    header: 'Древние',
    text: `Кто или что эти Древние, остаётся предметом споров между редкими обладателями знаний о них. Немногие существа из мультивселенной вообще осведомлены о существовании этих существ, и никто не может утверждать, что знает их всех.

Иногда Древних зовут богами, изначальными, или исчадиями. Тем не менее, некоторые учёные, изучающие эзотерические тайны, настаивают на том, что Они не соответствуют ни одному нынешнему объяснению, и, на самом деле, это сущности, стоящие в стороне от того, что простые смертные считают реальностью. Некоторые считают, что Древние — существа из Дальнего Предела, а другие полагают, что это существа, оказавшиеся в ловушке в особом плане или мире, или были взяты непонятным образом под контроль блуждающими звёздами, и заточены в бескрайней тьме ночного неба.

Эти жутковатые сущности имеют следующие довольно странные прозвища: Итьяк-Ортил, Пожиратель эльфов; [Дендар, Ночная Змея](GOD:${GOD_DENDAR}); Борем из Озера Кипящей Грязи; Кезеф, Гончая Хаоса; Заргон, Возвращающийся; Камнод, Незримый; Холашнер, Голодающий в недрах; Писцэтсис, Кровавая Королева; Шототугг, Пожиратель миров; Й’Чак, Лиловое Пламя; Болотамогг, Смотрящий Из Глубин Космоса; Харгут, Серая Зараза; Хааск, Голос Харгута; Рагнорра, Мать Чудовищ; Монолиты Зореты; Кайусс, Блуждающий Червь; Тариздун, Древнее Стихийное Око; Атропус, Мертворождённый мир; Пандорим, Абсолютное Уничтожение; Хэмнатуун, Владыка Крови; Маарам из Великого копья; Тирантраксус, Горящий; Безымянная Королева Хаоса; Отец Ллымик; Чужеродная Мысль Во Плоти.

Степень могущества, которым владеют эти сущности, изменяется за пределами их родной среды обитания. Однако, они все по-прежнему остаются силами зла и развращения. От них можно ничего хорошего не ждать. Никакая заключённая сделка не завершается ничем, кроме как безумием, чумой, смертью или ещё чем похуже.`,
    source: {
      id: SOURCE_MTOF,
      page: 223,
    },
  },
]
