const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_9,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_CONSTRUCT,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_ITS_CREATOR,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_ACID,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_GREATER_RESTORATION,
  } = require('./../../../../spellIdList'),
  {
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    golemDescription,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_BERSERK,
    ABILITY_IMMUTABLE_FORM,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_CLAY_GOLEM,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Глиняный голем',
  nameEn: 'Clay Golem',
  id: CREATURE_CLAY_GOLEM,
  description: [
    `Этот громоздкий, изваянный из глины голем ростом выше обычного человека. Он сделан в форме человека, но пропорции не соблюдены.

Глиняные големы часто одарены священной целью жрецами. Однако, глина — слабый сосуд для жизненной силы. Если голем повреждён, стихийный дух, привязанный к нему, может вырваться на свободу. Такие големы приходят в ярость, круша всё вокруг себя до тех пор, пока не будут уничтожены или полностью восстановлены.`,
    golemDescription,
  ],
  note: {
    text: `Чем твёрже физическая форма, тем крепче голем помнит свою цель. Глиняные могут быть немного нервными`,
    author: `предупреждение из СПРАВОЧНИКА ПО ГЛИНЯНЫМ ГОЛЕМАМ`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 66,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 18,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_PSYCHIC,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_9,
  featureList: [
    {
      name: 'Поглощение кислоты',
      description: `Каждый раз, когда ★СУЩЕСТВО★ ★должен★ получить урон кислотой, ★он★ не получает урон, и вместо этого восстанавливает число хитов, равное причиняемому урону кислотой.`,
    },
    {
      id: ABILITY_BERSERK,
      hp: 60,
    },
    ABILITY_IMMUTABLE_FORM,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает два _Удара_.`,
    },
    {
      name: 'Удар',
      description: `Если цель — существо, она должна пройти испытание Телосложения СЛ 15, иначе максимум её хитов уменьшится на количество, равное полученному урону. Цель умирает, если эта атака уменьшает максимум хитов до 0. Уменьшение длится до тех пор, пока не будет устранено заклинанием [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) или подобной магией.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Спешка',
      description: `До конца своего следующего хода ★СУЩЕСТВО★ магическим образом получает бонус +2 к КД, с преимуществом проходит испытания Ловкости, и может использовать атаку _Ударом_ бонусным действием.`,
      restore: 5,
    },
  ],
  genderId: GENDER_MALE,
}
