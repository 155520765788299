const {
  SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_YUGOLOTH,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ABYSSAL,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FIRE,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  CREATURE_YUGOLOTH_SPIRIT,
  CREATURE_YUGOLOTH_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  SPELL_SUMMON_FIENDISH_SPIRIT_2020_03_26,
} = require('./../../../../spellIdList')
const {
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Дух юголота',
  nameEn: 'Yugoloth Spirit',
  id: CREATURE_YUGOLOTH_SPIRIT_2020_03_26,
  releasedAs: CREATURE_YUGOLOTH_SPIRIT,
  createdBySpellId: SPELL_SUMMON_FIENDISH_SPIRIT_2020_03_26,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_YUGOLOTH,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
    page: 6,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `модификатор Телосложения духа + модификатор заклинательной характеристики заклинателя + десятикратный круг заклинания`,
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 16,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  resistanceList: [
    DAMAGE_FIRE,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Когти',
      description: `Сразу после того, как атака попала или промахнулась, ★СУЩЕСТВО★ может магически телепортироваться в свободное пространство, видимое им в пределах 30 футов.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
