const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {MAGIC_ITEM_ARMOR_PLUS_1_2_OR_3} = require('./../../../../../magicItemIdList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const gearCategoryList = require('./gearCategoryList')
const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_ARMOR_PLUS_1_2_OR_3,
  name: `Доспех +1, +2 или +3`,
  nameEn: `Armor +1, +2 or +3`,
  type: MGC_TYPE_ARMOR,
  gearCategory: gearCategoryList,
  rarity: generateRarityList(list),
  description: `Вы получаете бонус к КД, пока носите этот доспех.

Бонус определяется редкостью доспеха.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 159,
  },
}
