const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_5} = require('./../../../../crList')
const {PC_CLASS_DRUID} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {eternalFlameDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
} = require('./../../../../skillList')
const {
  GEAR_HIDE_ARMOR,
  GEAR_DAGGER,
} = require('./../../../../gearIdList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_BLIGHT,
  SPELL_CONJURE_MINOR_ELEMENTALS,
  SPELL_DISPEL_MAGIC,
  SPELL_DRUIDCRAFT,
  SPELL_FAERIE_FIRE,
  SPELL_FLAME_BLADE,
  SPELL_GUIDANCE,
  SPELL_HEALING_WORD,
  SPELL_JUMP,
  SPELL_POISON_SPRAY,
  SPELL_PRODUCE_FLAME,
  SPELL_SPIKE_GROWTH,
  SPELL_STINKING_CLOUD,
  SPELL_THUNDERWAVE,
  SPELL_WALL_OF_FIRE,
} = require('./../../../../spellIdList')
const {
  LANG_COMMON,
  LANG_DRUIDIC,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_ELIZAR_DRYFLAGON,
  CREATURE_SMOKE_MEPHIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Элизар Драйфлагон',
  nameEn: 'Elizar Dryflagon',
  id: CREATURE_ELIZAR_DRYFLAGON,
  hasSpoilers: true,
  description: [
    {
      header: 'Элизар',
      text: `[Друид](PC_CLASS:${PC_CLASS_DRUID}) по имени Элизар притворяется членом Круга Алой Луны. Однако на самом деле он служит Имиксу, а его способности [друида](PC_CLASS:${PC_CLASS_DRUID}) подпитывает связь со стихиями.`,
      source: {
        id: SOURCE_PotA,
        page: 207,
      },
    },
    eternalFlameDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 207,
  },
  armor: {
    ac: 14,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_DRUIDIC,
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Призыв мефитов',
      comment: 'перезаряжается после длинного отдыха',
      description: `Попыхивая своей трубкой, ★СУЩЕСТВО★ может действием применить заклинание [Призыв малых элементалей](SPELL:${SPELL_CONJURE_MINOR_ELEMENTALS}). Таким образом ★он★ вызывает четырех [дымовых мефитов](CREATURE:${CREATURE_SMOKE_MEPHIT}).`,
    },
  ],
  spellCast: {
    spellCasterLevel: 7,
    spellCasterClass: PC_CLASS_DRUID,
    spellIdList: [
      SPELL_ANIMAL_FRIENDSHIP,
      SPELL_BLIGHT,
      SPELL_DISPEL_MAGIC,
      SPELL_DRUIDCRAFT,
      SPELL_CONJURE_MINOR_ELEMENTALS,
      SPELL_FAERIE_FIRE,
      SPELL_FLAME_BLADE,
      SPELL_GUIDANCE,
      SPELL_HEALING_WORD,
      SPELL_JUMP,
      SPELL_POISON_SPRAY,
      SPELL_PRODUCE_FLAME,
      SPELL_SPIKE_GROWTH,
      SPELL_STINKING_CLOUD,
      SPELL_THUNDERWAVE,
      SPELL_WALL_OF_FIRE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      2,
    ],
  },
  actionList: [
    {
      magicItemId: `${GEAR_DAGGER}_plus_1`,
      gearId: GEAR_DAGGER,
    },
  ],
}
