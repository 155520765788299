module.exports = [
  require('./mace_of_disruption'),
  require('./mace_of_smiting'),
  require('./mace_of_terror'),
  require('./mantle_of_spell_resistance'),
  require('./manual_of_bodily_health'),
  require('./manual_of_gainful_exercise'),
  require('./manual_of_quickness_of_action'),
  require('./medallion_of_thoughts'),
  require('./mirror_of_life_trapping'),
  require('./moonblade'),
]
