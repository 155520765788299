const {PC_SUBCLASS_WARLOCK_THE_UNDYING,} = require('./../../../pcSubClassIdList')
const {SOURCE_SCAG,} = require('./../../../sourceList')
const {warlockSpellCircleList,} = require('./../../../spellCircleByCasterType')
const {
  SPELL_AURA_OF_LIFE,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_CONTAGION,
  SPELL_DEATH_WARD,
  SPELL_FALSE_LIFE,
  SPELL_FEIGN_DEATH,
  SPELL_FIREBALL,
  SPELL_LEGEND_LORE,
  SPELL_RAY_OF_SICKNESS,
  SPELL_SILENCE,
  SPELL_SPARE_THE_DYING,
  SPELL_SPEAK_WITH_DEAD,
} = require('./../../../spellIdList')
const {
  FEATURE_AMONG_THE_DEAD,
  FEATURE_DEFY_DEATH,
  FEATURE_EXPANDED_SPELLS_WARLOCK_THE_UNDYING,
  FEATURE_INDESTRUCTIBLE_LIFE,
  FEATURE_UNDYING_NATURE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_WARLOCK_THE_UNDYING,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    text: `Бессмертный позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_AURA_OF_LIFE,
      SPELL_BLINDNESS_DEAFNESS,
      SPELL_CONTAGION,
      SPELL_DEATH_WARD,
      SPELL_FALSE_LIFE,
      SPELL_FEIGN_DEATH,
      SPELL_LEGEND_LORE,
      SPELL_RAY_OF_SICKNESS,
      SPELL_SILENCE,
      SPELL_SPEAK_WITH_DEAD,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 140,
    },
  },
  {
    id: FEATURE_AMONG_THE_DEAD,
    name: `Среди мёртвых`,
    nameEn: `Among the Dead`,
    lvl: 1,
    text: `Вы изучаете заговор [Уход за умирающим](SPELL:${SPELL_SPARE_THE_DYING}), который считается для Вас заговором колдуна. Также, Вы получаете преимущество на испытания против любых болезней.

Вдобавок, нежити сложно вредить Вам. Если нежить целится непосредственно в Вас атакой или вредоносным заклятием, это существо должно пройти испытание Мудрости против Вашей СЛ заклинаний (нежить не обязана проходить это испытание при включении Вас в зону поражения заклятия, например, во взрыв [Огненного шара](SPELL:${SPELL_FIREBALL})).

При провале испытания, существо должно выбрать новую цель, отличную от Вас, или совсем провалить заклятие, если подходящих целей нет.

При успехе испытания, это существо иммунно к этому эффекту на 24 часа. Нежить также получает такой же иммунитет, если Вы целитесь в неё атакой или вредоносным заклятием.`,
    spellIdList: SPELL_SPARE_THE_DYING,
    source: {
      id: SOURCE_SCAG,
      page: 140,
    },
  },
  {
    id: FEATURE_DEFY_DEATH,
    name: `Отринув смерть`,
    nameEn: `Defy Death`,
    lvl: 6,
    text: `Вы можете получить жизненную силу, когда обманываете смерть сами или помогаете кому-то другому сделать это.

Вы можете восстановить 1к8 + модификатор Телосложения (минимум 1) хитов, когда преуспеваете в испытании от смерти или стабилизируете существо используя [Уход за умирающим](SPELL:${SPELL_SPARE_THE_DYING}).

Воспользовавшись этой способностью, Вы сможете использовать её снова лишь после длинного отдыха.`,
    source: {
      id: SOURCE_SCAG,
      page: 141,
    },
  },
  {
    id: FEATURE_UNDYING_NATURE,
    name: `Бессмертная природа`,
    nameEn: `Undying Nature`,
    lvl: 10,
    text: `Вы можете задерживать дыхание на неопределённо долгий срок, Вас не мучает жажда или голод и Вам не нужно спать, хотя Вам всё ещё необходимо отдыхать, чтобы снять истощение и получить пользу от короткого или длинного отдыха.

Вдобавок, Вы медленнее стареете. За каждые 10 лет, которые проходят, Ваше тело стареет лишь на 1 год, и Вы иммунны к магическому старению.`,
    source: {
      id: SOURCE_SCAG,
      page: 141,
    },
  },
  {
    id: FEATURE_INDESTRUCTIBLE_LIFE,
    name: `Нерушимая жизнь`,
    nameEn: `Indestructible Life`,
    lvl: 14,
    text: `Вас посвящают в некоторые настоящие секреты Бессмертных.

В свой ход Вы можете бонусным действием восстановить хиты равные 1к8 + Ваш уровень колдуна. Вдобавок, если, используя эту способность, Вы приставите свою отрубленную часть тела на место, она прирастёт на место.

Воспользовавшись этой способностью, Вы сможете использовать её снова лишь после короткого или длинного отдыха.`,
    source: {
      id: SOURCE_SCAG,
      page: 141,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_UNDYING,
  })
)
