const gearIdList = require('./gearIdList')
const woodList = require('./woodList')

const imbuedWoodFocusList = woodList.reduce(
  (list, item) => [
    ...list,
    ...gearIdList.reduce(
      (resultList, gearId) => [
        ...resultList,
        {
          ...item,
          id: `imbued_wood_focus_${item.idPart}_${gearId}`,
          gearId,
        },
      ],
      []
    )
  ],
  []
)

module.exports = imbuedWoodFocusList
