const {SOURCE_MM} = require('./../../sourceList')
const {GENDER_FEMALE} = require('./../../genderList')
const {LANG_TELEPATHY} = require('./../../languageIdList')
const {SPELL_ANTIMAGIC_FIELD} = require('./../../spellIdList')

module.exports = {
  id: LANG_TELEPATHY,
  name: {
    nominative: 'Телепатия',
    genitive: 'Телепатии',
    instrumental: 'Телепатией',
    prepositional: 'Телепатии',
  },
  nameEn: 'Telepathy',
  description: {
    header: `Телепатия`,
    text: `Телепатия это магическая способность, которая позволяет чудовищу мысленно общаться с другим существом в пределах указанного радиуса. Существо, с которым связываются, не обязательно должно иметь общий язык с чудовищем, но оно должно понимать, как минимум, один язык. Существо без телепатии может получать и отвечать на телепатические сообщения, но не может начинать или прекращать телепатический разговор.

Чудовищу с телепатией нет необходимости видеть существо, с которым оно связывается, и оно может закончить телепатический контакт в любое время. Контакт нарушается, как только кто-либо из существ выходит за пределы радиуса связи, или если чудовище с телепатией вступает в телепатический контакт с другим существом в радиусе. Чудовище с телепатией может начинать и обрывать телепатическую беседу без использования действия, но если чудовище Недееспособно, оно не может начинать контакт, а любая установленная телепатическая связь обрывается.

Существо в области заклинания [Преграда магии](SPELL:${SPELL_ANTIMAGIC_FIELD}) или в любой области, где магия не работает, не может отправлять и получать телепатические сообщения.`,
    source: {
      id: SOURCE_MM,
      page: 11,
    },
  },
  isRealLang: true,
  isReady: true,
  genderId: GENDER_FEMALE,
}
