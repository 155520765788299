const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_GOBLINOID} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_GOBLINOID,
  nameEn: 'Goblinoid',
  genderId: GENDER_MALE,
  notRealRace: true,
  name: {
    singular: {
      nominative: 'гоблиноид',
      genitive: 'гоблиноида',
      dative: 'гоблиноиду',
      accusative: 'гоблиноида',
      instrumental: 'гоблиноидом',
      prepositional: 'гоблиноиде',
    },
    plural: {
      nominative: 'гоблиноиды',
      genitive: 'гоблиноидов',
      dative: 'гоблиноидам',
      accusative: 'гоблиноидов',
      instrumental: 'гоблиноидами',
      prepositional: 'гоблиноидах',
    },
  },
}
