const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_SLEIGHT_OF_HAND,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_AQUAN,
  LANG_COMMON,
  LANG_THIEVES_CANT,
} = require('./../../../../languageIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_DEEP_SCION} = require('./../../../../creatureIdList')
const {GEAR_BATTLEAXE} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Отпрыск глубин',
  nameEn: 'Deep Scion',
  id: CREATURE_DEEP_SCION,
  description: [
    `Отпрыски глубин начинают свою жизнь как люди, которых похитили с берега или спасли с тонущего корабля, и которым подводные силы предложили ужасную сделку: сдаться душой и телом или утонуть. Подчинившиеся становятся субъектами древнего ритуала, широко распространенного среди злобных водных существ. Это очень болезненный метод и результат никогда не определен, но когда он срабатывает, магия трансформирует дышащее воздухом существо в перевёртыша, который может принимать свою водную форму под волнами.`,
    {
      header: 'Шпионы из моря',
      text: `Отпрыск глубин появляется из глубины и служит своему подводному господину, скорее всего [кракену] или другому древнему существу из глубин. Хотя он и носит тело и разум личности, которой он когда-то был, как маску, теперь существо помешано на выполнении желаний хозяина. Иногда отпрыск глубин возвращается в свой бывший дом и его приветствуют как героя — ведь он неожиданно оказывается жив, когда надежда уже была потеряна. В других случаях отпрыск глубин берет себе новую личность. В любом случае долг отпрыска глубин — внедриться в мир дышащих воздухом и докладывать своему хозяину. Получив задание, отпрыск глубин прогрызает себе путь в жизнь ничего не предполагающего врага в качестве лучшего друга, неотразимого любовника, превосходного кандидата на работу или какой-то другой роли, которая позволит слуге выполнять приказы хозяина.`,
      source: {
        id: SOURCE_VGTM,
        page: 183,
      },
    },
    {
      header: 'Хладнокровные убийцы',
      text: `Тренировки, которые проходит отпрыск глубин, избавляют его от эмпатии к тем, за кем он шпионит. Хотя он может вести себя глупо и влюблённо, смеяться над шуткой друга или казаться разгневанным несправедливостью, каждое такое действие искусственно для отпрыска глубин, это лишь средство для достижения цели. Он верит, что его настоящая форма — это та, которую он принимает, вернувшись в море, к которому он относится как к дому. По иронии судьбы, если отпрыск глубин убит в рыбной форме, то магия, которая избавила его от эмоций исчезает и остается лишь тело личности, которой отпрыск глубин когда-то был.`,
      source: {
        id: SOURCE_VGTM,
        page: 183,
      },
    },
  ],
  note: {
    text: `Если Вы встретили человека и в нём есть что-то рыбное, то это может быть отпрыск глубин. Или это быть жулик или торговец рыбой. Иногда рыбная вонь — это просто рыбная вонь.`,
    author: `Воло`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 183,
  },
  armor: 11,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: {
      value: 30,
      comment: `в гибридной форме: 20 фт., плавание 40 фт.`,
    },
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_SLEIGHT_OF_HAND]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_AQUAN,
    LANG_COMMON,
    LANG_THIEVES_CANT,
  ],
  cr: CR_3,
  featureList: [
    {
      id: ABILITY_AMPHIBIOUS,
      comment: `только в гибридной Форме`,
    },
    {
      name: 'Перевёртыш',
      description: `Отпрыск глубин может использовать действие и превратиться в гибридную форму рыбочеловека или обратно в свою истинную форму. Его характеристики, кроме скорости, одинаковы в обеих формах. Любое снаряжение, которое он несёт или носит, не трансформируется. Отпрыск глубин превращается в свою истинную форму, когда умирает.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: ` В форме гуманоида отпрыск глубин делает две рукопашные атаки. В гибридной форме он делает три атаки: один _Укус_ и два удара _Когтями_.`,
    },
    {
      gearId: GEAR_BATTLEAXE,
      comment: `только в форме гуманоида`,
    },
    {
      name: 'Укус',
      comment: `только в гибридной форме`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Когти',
      comment: `только в гибридной форме`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Психический визг',
      comment: `только в гибридной форме; перезаряжается после короткого или длинного отдыха`,
      description: `Отпрыск глубин испускает ужасный крик, слышимый на 300 футов. Существа не далее 30 футов от отпрыска глубин должны пройти испытание Мудрости СЛ 13 или стать ошеломлёнными до конца следующего хода отпрыска глубин. В воде психический визг также телепатически передает воспоминания глубинного отпрыска о последних 24 часах его хозяину вне зависимости от расстояния, пока он и его хозяин находятся в одном и том же водоёме.`,
    },
  ],
}
