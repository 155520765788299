const {CREATURE_TYPE_OGRE} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_OGRE,
  nameEn: 'Ogre',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'огр',
        genitive: 'огра',
        dative: 'огру',
        accusative: 'огра',
        instrumental: 'огром',
        prepositional: 'огре',
      },
      [GENDER_FEMALE]: {
        nominative: 'огриха',
        genitive: 'огрихи',
        dative: 'огрихе',
        accusative: 'огриху',
        instrumental: 'огрихой',
        prepositional: 'огрихе',
      },
    },
    plural: {
      nominative: 'огры',
      genitive: 'огров',
      dative: 'ограм',
      accusative: 'огров',
      instrumental: 'ограми',
      prepositional: 'ограх',
    },
  },
}
