const pickPcClassSpellIdListByLvl = require('./../utils/pickPcClassSpellIdListByLvl')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
  PC_CLASS_WIZARD,
} = require('./pcClassIdList')

const pickClericSpellIdListByLvl = pickPcClassSpellIdListByLvl(PC_CLASS_CLERIC)
const pickDruidSpellIdListByLvl = pickPcClassSpellIdListByLvl(PC_CLASS_DRUID)
const pickWizardSpellIdListByLvl = pickPcClassSpellIdListByLvl(PC_CLASS_WIZARD)

module.exports = {
  [PC_CLASS_CLERIC]: [
    pickClericSpellIdListByLvl(0),
    pickClericSpellIdListByLvl(1),
    pickClericSpellIdListByLvl(2),
    pickClericSpellIdListByLvl(3),
    pickClericSpellIdListByLvl(4),
    pickClericSpellIdListByLvl(5),
    pickClericSpellIdListByLvl(6),
    pickClericSpellIdListByLvl(7),
    pickClericSpellIdListByLvl(8),
    pickClericSpellIdListByLvl(9),
  ],
  [PC_CLASS_DRUID]: [
    pickDruidSpellIdListByLvl(0),
    pickDruidSpellIdListByLvl(1),
    pickDruidSpellIdListByLvl(2),
    pickDruidSpellIdListByLvl(3),
    pickDruidSpellIdListByLvl(4),
    pickDruidSpellIdListByLvl(5),
    pickDruidSpellIdListByLvl(6),
    pickDruidSpellIdListByLvl(7),
    pickDruidSpellIdListByLvl(8),
    pickDruidSpellIdListByLvl(9),
  ],
  [PC_CLASS_WIZARD]: [
    pickWizardSpellIdListByLvl(0),
    pickWizardSpellIdListByLvl(1),
    pickWizardSpellIdListByLvl(2),
    pickWizardSpellIdListByLvl(3),
    pickWizardSpellIdListByLvl(4),
    pickWizardSpellIdListByLvl(5),
    pickWizardSpellIdListByLvl(6),
    pickWizardSpellIdListByLvl(7),
    pickWizardSpellIdListByLvl(8),
    pickWizardSpellIdListByLvl(9),
  ],
}
