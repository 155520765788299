const {GENDER_MALE} = require('./../../../genderList')
const {MAGIC_ITEM_ARMOR_OF_GLEAMING} = require('./../../../magicItemIdList')
const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../magicItemTypeList')
const {SOURCE_XGTE} = require('./../../../sourceList')

const siblingIdList = require('./siblingIdList')
const gearCategoryList = require('./gearCategoryList')

module.exports = {
  id: MAGIC_ITEM_ARMOR_OF_GLEAMING,
  name: `Сверкающий доспех`,
  nameEn: `Armor of Gleaming`,
  type: MGC_TYPE_ARMOR,
  gearCategory: gearCategoryList,
  rarity: MGC_RARITY_COMMON,
  description: `Этот доспех никогда не покрывается грязью.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_XGTE,
    page: 138,
  },
}
