const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../../gearIdList')
const {
  CREATURE_ETTIN,
  CREATURE_TROLL,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_ARMOR,
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_GAUNTLETS_OF_OGRE_POWER,
  MAGIC_ITEM_GEM_OF_BRIGHTNESS,
  MAGIC_ITEM_GEM_OF_SEEING,
  MAGIC_ITEM_GIANT_SLAYER,
  MAGIC_ITEM_GLAMOURED_STUDDED_LEATHER,
  MAGIC_ITEM_GLOVES_OF_MISSILE_SNARING,
  MAGIC_ITEM_GLOVES_OF_SWIMMING_AND_CLIMBING,
  MAGIC_ITEM_GLOVES_OF_THIEVERY,
  MAGIC_ITEM_GOGGLES_OF_NIGHT,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_GIANT_SLAYER,
    name: `Убийца великанов`,
    nameEn: `Giant Slayer`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч или топор`,
    rarity: MGC_RARITY_RARE,
    description: `Вы получаете бонус +1 к броскам атаки и урона, совершённым этим магическим оружием.

Если Вы попадаете им по великану, великан получает дополнительный урон 2к6, соответствующий виду оружия, и должен пройти испытание Силы СЛ 15, иначе он будет сбит с ног. Для этого оружия «великан» означает любое существо с видом «великан», включая [эттинов](CREATURE:${CREATURE_ETTIN}) и [троллей](CREATURE:${CREATURE_TROLL}).`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 210,
    },
  },
  {
    id: MAGIC_ITEM_GAUNTLETS_OF_OGRE_POWER,
    name: `Рукавицы силы огра`,
    nameEn: `Gauntlets of Ogre Power`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Ваша Сила равна 19, когда Вы носите эти латные рукавицы. Они не оказывают на Вас эффект, если Ваша Сила без них уже 19 или выше.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 198,
    },
  },
  {
    id: MAGIC_ITEM_GLOVES_OF_SWIMMING_AND_CLIMBING,
    name: `Перчатки плавания и лазания`,
    nameEn: `Gloves of Swimming and Climbing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите эти перчатки, лазание и плавание не требует от Вас траты дополнительного перемещения, и Вы получаете бонус +5 к проверкам Силы (Атлетика), совершённым, чтобы лазать или плавать.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 190,
    },
  },
  {
    id: MAGIC_ITEM_GLOVES_OF_MISSILE_SNARING,
    name: `Перчатки ловли снарядов`,
    nameEn: `Gloves of Missile Snaring`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Эти перчатки практически сливаются с Вашими руками, когда Вы их надеваете.

Если по Вам попадает дальнобойная атака оружием, когда Вы их носите, Вы можете реакцией уменьшить урон на 1к10 + Ваш модификатор Ловкости, при условии, что у Вас есть одна свободная рука. Если Вы уменьшили урон до 0, Вы можете поймать снаряд, если он достаточно маленький, чтобы его можно было держать одной рукой.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 190,
    },
  },
  {
    id: MAGIC_ITEM_GLOVES_OF_THIEVERY,
    name: `Перчатки воровства`,
    nameEn: `Gloves of Thievery`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Эти перчатки невидимы, пока Вы носите их. Пока Вы носите их, Вы получаете бонус +5 к проверкам Ловкости (Ловкость рук) и проверкам Ловкости, совершённым для вскрывания замков.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 190,
    },
  },
  {
    id: MAGIC_ITEM_GOGGLES_OF_NIGHT,
    name: `Ночные очки`,
    nameEn: `Goggles of Night`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Пока Вы носите эти тёмные очки, Вы получаете тёмное зрение в радиусе 60 футов.

Если у Вас уже было тёмное зрение, очки увеличивают его радиус на 60 футов.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 186,
    },
  },
  {
    id: MAGIC_ITEM_GLAMOURED_STUDDED_LEATHER,
    name: `Красивый проклёпанный доспех`,
    nameEn: `Glamoured Studded Leather`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_STUDDED_LEATHER_ARMOR,
    rarity: MGC_RARITY_RARE,
    description: `Пока Вы носите этот доспех, Вы получаете бонус +1 к КД.

Вы также можете бонусным действием произнести его командное слово и заставить доспех принять облик [обычной одежды](GEAR:${GEAR_CLOTHES_COMMON}) или любого другого доспеха. Вы сами решаете, как он будет выглядеть, включая цвет, стиль и аксессуары, но доспех при этом сохраняет свой объём и вес. Иллюзорный облик длится до тех пор, пока Вы не используете это свойство повторно или не снимете доспех.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 178,
    },
  },
  {
    id: MAGIC_ITEM_GEM_OF_BRIGHTNESS,
    name: `Камень сияния`,
    nameEn: `Gem of Brightness`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `У этой призмы есть 50 зарядов. Если Вы её держите, Вы можете действием произнести одно из трёх командных слов, чтобы вызвать один из следующих эффектов:

1. Первое командное слово заставляет камень испускать яркий свет в радиусе 30 футов и тусклый свет в радиусе ещё 30 футов. Этот эффект не тратит заряд. Он длится, пока Вы не повторите командное слово бонусным действием, или пока не воспользуетесь другой функцией камня.
2. Второе командное слово тратит 1 заряд и заставляет камень испустить сверкающий луч в одно существо, которое Вы видите в пределах 60 футов. Существо должно пройти испытание Телосложения СЛ 15, иначе станет ослеплённым на 1 минуту. Существо может повторять испытание в конце каждого своего хода, оканчивая эффект при успехе. 
3. Третье командное слово тратит 5 зарядов и заставляет камень испускать ослепляющий свет 30-футовым конусом. Все существа в конусе должны пройти испытания, как если бы по ним попал луч, созданный вторым командным словом.

Когда все заряды этого камня кончатся, он становится немагической драгоценностью, стоящей 50 зм.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 169,
    },
  },
  {
    id: MAGIC_ITEM_GEM_OF_SEEING,
    name: `Камень зрения`,
    nameEn: `Gem of seeing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `У этого драгоценного камня есть 3 заряда. Вы можете действием произнести командное слово и потратить 1 заряд. В течение следующих 10 минут Вы обладаете истинным зрением в пределах 120 футов, если смотрите через этот драгоценный камень.

Камень ежедневно восстанавливает 1к3 заряда на рассвете.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 168,
    },
  },
]
