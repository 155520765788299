module.exports = [
  require('./sanctuary'),
  require('./shield'),
  require('./shield_of_faith'),
  require('./shillelagh'),
  require('./shocking_grasp'),
  require('./silence'),
  require('./silent_image'),
  require('./sleep'),
  require('./sleet_storm'),
  require('./spare_the_dying'),
  require('./spider_climb'),
  require('./storm_of_vengeance'),
  require('./spike_growth'),
  require('./spiritual_weapon'),
  require('./stinking_cloud'),
  require('./sunburst'),
  require('./swift_quiver'),
  require('./symbol'),
]
