const {
  SPELL_ARMS_OF_HADAR,
  SPELL_BLIGHT,
  SPELL_CHILL_TOUCH,
  SPELL_COUNTERSPELL,
  SPELL_CROWN_OF_MADNESS,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISPEL_MAGIC,
  SPELL_FLY,
  SPELL_HOLD_MONSTER,
  SPELL_INVISIBILITY,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MINOR_ILLUSION,
  SPELL_POISON_SPRAY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {ABILITY_TURN_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_DEATHLOCK_MASTERMIND} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {deathlockDescription} = require('./../../../../textCommonParts')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дэтлок манипулятор',
  nameEn: 'Deathlock Mastermind',
  id: CREATURE_DEATHLOCK_MASTERMIND,
  description: [
    `Хоть дэтлоки и существуют, чтобы служить своим покровителям, у них сохраняется некоторая свобода, когда речь идёт о продумывании тактики и выполнении планов. Могущественные дэтлоки собирают под своим началом слабых существ для помощи в выполнении задания, таким образом становясь инициаторами крупных заговоров и интриг и творя великое зло.`,
    deathlockDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 198,
  },
  armor: [
    13,
    {
      ac: 16,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 12,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
      comment: `прозревает магическую тьму`,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_8,
  featureList: [
    ABILITY_TURN_RESISTANCE,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DETECT_MAGIC,
            SPELL_DISGUISE_SELF,
            SPELL_MAGE_ARMOR,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 10,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_CHILL_TOUCH,
            SPELL_MAGE_HAND,
            SPELL_MINOR_ILLUSION,
            SPELL_POISON_SPRAY,
          ],
        },
        {
          preText: `1–5 уровни (2 ячейки 5-го уровня)`,
          list: [
            SPELL_ARMS_OF_HADAR,
            SPELL_BLIGHT,
            SPELL_COUNTERSPELL,
            SPELL_CROWN_OF_MADNESS,
            SPELL_DARKNESS,
            SPELL_DIMENSION_DOOR,
            SPELL_DISPEL_MAGIC,
            SPELL_FLY,
            SPELL_HOLD_MONSTER,
            SPELL_INVISIBILITY,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      name: 'Смертельные когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 3,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Гиблые снаряды',
      description: `Если цель Большого размера или меньше, то она должна пройти испытание Силы СЛ 16, иначе её обхватят теневые ростки, и она будет обездвижена на 1 минуту. Обездвиженная цель может действием повторить испытание, завершив эффект при успехе.`,
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 6,
        range: 120,
        target: [1, 2],
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 4,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
  ],
}
