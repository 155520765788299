const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ATHLETICS,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_CLIMB,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_SU_MONSTER,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Су-монстр',
  nameEn: 'Su-Monster',
  id: CREATURE_SU_MONSTER,
  description: {
    header: 'Су-монстр',
    text: `Су-монстры — хитрые, злые приматы, которые населяют заброшенные пустынные районы и пещеры. Взрослые особи достигают 5 футов в высоту и имеют длинные, цепкие хвосты. Хотя они могут держать инструменты и оружие своими хвостами или когтистыми лапами, су-монстры предпочитают разрывать добычу своими когтями. Взрослые су-монстры могут также проецировать взрывы псионической энергии, которые оставляют врагов ошеломлёнными и неспособными бежать или защищаться.

Су-монстр спит, вися вверх тормашками, зацепившись своим хвостом. Во время бодрствования, в этой позиции, он также может делать сокрушительные атаки, раздирая врагов всеми четырьмя когтями.

Су-монстры общаются молча, используя свои цепкие хвосты, чтобы делать жесты. Они обычно убивают больше, чем могут есть, хоронят остатки и отмечают своё местоположение имуществом жертвы.`,
    source: {
      id: SOURCE_TOA,
      page: 238,
    },
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_TOA,
    page: 238,
  },
  armor: 12,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  cr: CR_1,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну — _Укусом_ и одну — _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 4,
              diceBonus: 2,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 4,
              diceType: 4,
              diceBonus: 2,
              comment: `если су-монстр висит на хвосте, и все четыре его лапы свободны.`,
            },
          ],
        ],
      },
    },
    {
      name: 'Психическое сдавливание',
      restore: 5,
      description: `★СУЩЕСТВО★ нацеливается на одно видимое им в пределах 30 футов существо. Цель должна пройти испытание Мудрости СЛ 11. При провале существо получает 17 (5к6) психического урона и Ошеломляется на 1 минуту, при провале. Ошеломлённая цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
  ],
}
