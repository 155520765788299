const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_DAGGER,
  GEAR_MANACLES,
  GEAR_PLATE_ARMOR,
  GEAR_PLAYING_CARD_SET,
  GEAR_WARHAMMER,
} = require('./../../../../../gearIdList')
const {
  SPELL_DAYLIGHT,
  SPELL_IDENTIFY,
  SPELL_KNOCK,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHT,
  SPELL_REMOVE_CURSE,
  SPELL_WISH,
} = require('./../../../../../spellIdList')
const {
  CREATURE_ACOLYTE,
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_APPRENTICE_WIZARD,
  CREATURE_ARCHMAGE,
  CREATURE_ASSASSIN,
  CREATURE_BANDIT,
  CREATURE_BANDIT_CAPTAIN,
  CREATURE_BEHOLDER,
  CREATURE_BERSERKER,
  CREATURE_BUGBEAR,
  CREATURE_CLOUD_GIANT,
  CREATURE_DRAGON_TURTLE,
  CREATURE_DRUID,
  CREATURE_ERINYES,
  CREATURE_ETTIN,
  CREATURE_FIRE_GIANT,
  CREATURE_FROST_GIANT,
  CREATURE_GNOLL,
  CREATURE_GOBLIN,
  CREATURE_GUARD,
  CREATURE_HILL_GIANT,
  CREATURE_HOBGOBLIN,
  CREATURE_INCUBUS,
  CREATURE_IRON_GOLEM,
  CREATURE_KNIGHT,
  CREATURE_KOBOLD,
  CREATURE_LICH,
  CREATURE_MEDUSA,
  CREATURE_NIGHT_HAG,
  CREATURE_OGRE,
  CREATURE_ONI,
  CREATURE_ORC,
  CREATURE_PRIEST,
  CREATURE_SUCCUBUS,
  CREATURE_TROLL,
  CREATURE_VETERAN,
  CREATURE_WYVERN,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_ARMOR,
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_CHIME_OF_OPENING,
  MAGIC_ITEM_DAERNS_INSTANT_FORTRESS,
  MAGIC_ITEM_DAGGER_OF_VENOM,
  MAGIC_ITEM_DANCING_SWORD,
  MAGIC_ITEM_DECK_OF_ILLUSIONS,
  MAGIC_ITEM_DEFENDER,
  MAGIC_ITEM_DEMON_ARMOR,
  MAGIC_ITEM_DIMENSIONAL_SHACKLES,
  MAGIC_ITEM_DRAGON_SLAYER,
  MAGIC_ITEM_DRIFTGLOBE,
  MAGIC_ITEM_DUST_OF_DISAPPEARANCE,
  MAGIC_ITEM_DUST_OF_DRYNESS,
  MAGIC_ITEM_DUST_OF_SNEEZING_AND_CHOKING,
  MAGIC_ITEM_DWARVEN_PLATE,
  MAGIC_ITEM_DWARVEN_THROWER,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_DRAGON_SLAYER,
    name: `Убийца драконов`,
    nameEn: `Dragon Slayer`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч`,
    rarity: MGC_RARITY_RARE,
    description: `Вы получаете бонус +1 к броскам атаки и урона, совершённым этим магическим оружием.

Если Вы попадаете этим оружием по дракону, он получит дополнительный урон 3к6, соответствующий виду оружия. Для этого оружия «дракон» означает любое существо с видом «дракон», включая [виверн](CREATURE:${CREATURE_WYVERN}) и [дракочерепах](CREATURE:${CREATURE_DRAGON_TURTLE}).`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 210,
    },
  },
  {
    id: MAGIC_ITEM_DANCING_SWORD,
    name: `Танцующий меч`,
    nameEn: `Dancing Sword`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч`,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Вы можете бонусным действием бросить этот магический меч в воздух и произнести командное слово. После этого меч начнёт парить, пролетает до 30 футов и атакует одно существо на Ваш выбор в пределах 5 футов от него. Меч использует Ваш бросок атаки и Ваш модификатор характеристики для броска урона.

Пока меч парит, Вы можете бонусным действием заставить его перелететь на расстояние 30 футов в другое место, находящееся в пределах 30 футов от Вас. Частью этого же бонусного действия Вы можете заставить меч атаковать одно существо, находящееся в пределах 5 футов от него.

После того как _Парящий меч_ совершит четвёртую атаку, он пролетает до 30 футов и пытается вернуться в Вашу руку. Если у Вас нет свободных рук, он падает на землю у Ваших ног. Если у меча нет свободного пути до Вас, он перемещается максимально близко к Вам и потом падает на землю. Он перестаёт парить, если Вы хватаете его или перемещаетесь более чем на 30 футов от него.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 208,
    },
  },
  {
    id: MAGIC_ITEM_DUST_OF_SNEEZING_AND_CHOKING,
    name: `Порошок чихания и удушья`,
    nameEn: `Dust of Sneezing and Choking`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Этот порошок, хранящийся в маленьком контейнере, напоминает очень мелкий песок. Он похож на [Порошок исчезновения](MAGIC_ITEM:${MAGIC_ITEM_DUST_OF_DISAPPEARANCE}), и даже заклинание [Опознание](SPELL:${SPELL_IDENTIFY}) показывает, что он им является. Его количества достаточно для одного применения.

Если Вы действием бросаете горсть этого порошка в воздух, Вы и все дышащие существа в пределах 30 футов от Вас должны пройти испытание Телосложения СЛ 15, иначе они не смогут дышать и начнут безудержно чихать. Существо, попавшее под действие этого порошка, становится недееспособным и начинает задыхаться. Пока существо находится в сознании, оно может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. Заклинание [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}) тоже оканчивает этот эффект на существе. `,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 192,
    },
  },
  {
    id: MAGIC_ITEM_DUST_OF_DRYNESS,
    name: `Порошок сухости`,
    nameEn: `Dust of Dryness`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Этот небольшой мешочек содержит 1к6 + 4 щепоток порошка. Вы можете действием распылить щепотку этого порошка над водой. Порошок превращает куб воды с длиной ребра 15 футов в крошечную гранулу, которая плавает на поверхности воды, где был просыпан порошок, или лежит рядом с ней. Вес гранулы незначителен.

Впоследствии кто угодно может действием разбить гранулу об твёрдую поверхность, в результате чего она разрушается и высвобождает воду, заключённую в ней, после чего действие магии заканчивается.

Элементали, состоящие преимущественно из воды, и не защищённые от попадания этого порошка, должны пройти испытание Телосложения СЛ 13, получая урон некротической энергией 10к6 при провале или половину этого урона при успехе.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 192,
    },
  },
  {
    id: MAGIC_ITEM_DUST_OF_DISAPPEARANCE,
    name: `Порошок исчезновения`,
    nameEn: `Dust of Disappearance`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Найденный в маленьком мешочке, этот порошок выглядит как очень мелкий песок, но его количества будет достаточно для разового применения.

Если Вы действием развеете порошок в воздухе вокруг себя, то Вы и все существа, и предметы в пределах 10 футов от Вас становитесь невидимыми на 2к4 минуты. Эта продолжительность одинакова для всех существ и предметов. Как только магический эффект начинает действовать, порошок исчезает. Если существо, находящееся под действием порошка, совершает атаку или сотворяет заклинание, невидимость для него заканчивается.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 192,
    },
  },
  {
    id: MAGIC_ITEM_DRIFTGLOBE,
    name: `Парящая сфера`,
    nameEn: `Driftglobe`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Эта небольшая сфера толстого стекла весит 1 фунт. Находясь в пределах 60 футов от неё, Вы можете произнести командное слово и заставить сферу излучать свет, как, если бы было наложено заклинание [Свет](SPELL:${SPELL_LIGHT}) или [Дневной свет](SPELL:${SPELL_DAYLIGHT}). Эффект, дублирующий заклинание [Дневной свет](SPELL:${SPELL_DAYLIGHT}), нельзя использовать повторно до следующего рассвета.

Вы можете действием произнести второе командное слово, заставив светящуюся сферу подняться в воздух и парить на высоте не более 5 футов от земли. Сфера может парить пока Вы или другое существо не схватите её. Если Вы передвигаетесь более чем на 60 футов от парящей сферы, она следует за Вами, оставаясь в пределах 60 футов от Вас, выбирая при этом кратчайший маршрут. Если что-либо препятствует перемещению сферы, она мягко опускается на землю, становясь неактивной, и её свет тухнет.`,
    genderId: GENDER_FEMALE,
    spellIdList: [
      SPELL_LIGHT,
      SPELL_DAYLIGHT,
    ],
    source: {
      id: SOURCE_DMG,
      page: 188,
    },
  },
  {
    id: MAGIC_ITEM_DIMENSIONAL_SHACKLES,
    name: `Оковы измерений`,
    nameEn: `Dimensional Shackles`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_MANACLES,
    rarity: MGC_RARITY_RARE,
    description: `Вы можете действием надеть эти оковы на недееспособное существо. Они подходят для существ с размером от Маленького до Большого. Кроме того, что оковы служат как обычные [наручники](GEAR:${GEAR_MANACLES}), они не позволяют скованному существу никакие перемещения меж измерениями, включая телепортацию и путешествия на другие планы существования. Они не мешают существу проходить сквозь межпространственные порталы.

Вы и все существа, указанные Вами при надевании оков, можете действием снять их. Раз в 30 дней скованное существо может совершить проверку Силы (Атлетика) СЛ 30. При успехе существо ломает и уничтожает оковы.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 187,
    },
  },
  {
    id: MAGIC_ITEM_DAERNS_INSTANT_FORTRESS,
    name: `Мгновенная крепость Даэрна`,
    nameEn: `Daern’s Instant Fortress`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Вы можете действием поместить этот металлический кубик с длиной ребра 1 дюйм на пол и произнести командное слово. Куб быстро вырастет в крепость, которая остаётся, пока Вы не произнесёте действием командное слово, которое сработает только если крепость пуста.

Крепость является квадратной башней 20×20 футов и 30 футов высотой, с бойницами и зубчатой стеной сверху. Внутри она поделена на два этажа, соединённых лестницей, идущей вдоль одной из стен. Лестница заканчивается наверху люком. После активации в ближайшей к Вам стене будет небольшая дверь. Дверь открывается только по Вашей команде, которую Вы произносите бонусным действием. Она обладает иммунитетом к заклинанию [Открывание](SPELL:${SPELL_KNOCK}) и подобной магии, например, к [Колокольчику открывания](MAGIC_ITEM:${MAGIC_ITEM_CHIME_OF_OPENING}).

Все существа, находящиеся в области, в которой появляется крепость, должны пройти испытание Ловкости СЛ 15, получая дробящий урон 10к10 при провале или половину этого урона при успехе. В любом случае, такое существо выталкивается в ближайшее свободное пространство вне крепости. Предметы в этой области, которые никто не несёт и не носит, получают этот же урон и автоматически толкаются.

Башня изготовлена из адамантина, и магия не даёт её опрокинуть. У крыши, двери и стен по 100 хитов, иммунитет к урону от немагического оружия, исключая осадное оружие, и сопротивление ко всем остальным видам урона. Чинить крепость можно только заклинанием [Исполнение желаний](SPELL:${SPELL_WISH}) (это считается дублированием заклинания с уровнем ниже 9). Каждое накладывание этого заклинания восстанавливает крыше, двери или одной из стен 50 хитов.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 183,
    },
  },
  {
    id: MAGIC_ITEM_DWARVEN_PLATE,
    name: `Дварфийские латы`,
    nameAlt: `Латы дварфов`,
    nameEn: `Dwarven Plate`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_PLATE_ARMOR,
    rarity: MGC_RARITY_VERY_RARE,
    description: `Пока Вы носите этот доспех, Вы получаете бонус +2 к КД.

Кроме того, если некий эффект перемещает Вас против Вашей воли, а Вы стоите на твёрдой поверхности, Вы можете реакцией уменьшит расстояние, на которое Вас переместят, на расстояние до 10 футов.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 180,
    },
  },
  {
    id: MAGIC_ITEM_DECK_OF_ILLUSIONS,
    name: `Колода иллюзий`,
    nameEn: `Deck of Illusions`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_PLAYING_CARD_SET,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `В этой коробке находится набор пергаментных карт. В полной колоде 34 карты. В колоде, которую находят как сокровище, чаще всего не хватает 1к20−1 карт.

Магия колоды работает только если карту тянуть случайным образом (можете заранее приготовить модифицированную колоду игральных карт). Вы можете действием вынуть из колоды случайным образом выбранную карту и бросить её на расстояние до 30 футов.

Над брошенной картой появляется иллюзия одного или нескольких существ, присутствующая, пока не будет рассеяна. Иллюзорное существо выглядит реальным, у него соответствующий размер и ведёт себя оно как настоящее существо (как описано в Бестиарии), за исключением того, что оно не может причинять вред. Если Вы находитесь в пределах 120 футов от иллюзорного существа и видите его, Вы можете действием переместить его с помощью магии в любое место в пределах 30 футов от карты. Любое физическое взаимодействие с иллюзорным существом даст понять, что это иллюзия, потому что предметы проходят сквозь него. Тот, кто действием осмотрит существо, распознает в нём иллюзию при успешной проверке Интеллекта (Анализ) СЛ 15. После этого существо выглядит полупрозрачным.

Иллюзия существует, пока карту не переместят или иллюзию не рассеют. Когда иллюзия оканчивается, изображение на карте исчезает, и её уже больше нельзя использовать. 

| Карта         | Иллюзия                                                                                                 |
|---------------|---------------------------------------------------------------------------------------------------------|
| Туз червей    | [Красный дракон](CREATURE:${CREATURE_DRAGON_RED_ANCIENT})                                               |
| Король червей | [Рыцарь](CREATURE:${CREATURE_KNIGHT}) и [четыре Стражника](CREATURE:${CREATURE_GUARD})                  |
| Дама червей   | [Суккуб](CREATURE:${CREATURE_SUCCUBUS}) или [Инкуб](CREATURE:${CREATURE_INCUBUS})                       |
| Валет червей  | [Друид](CREATURE:${CREATURE_DRUID})                                                                     |
| 10 червей     | [Облачный великан](CREATURE:${CREATURE_CLOUD_GIANT})                                                    |
| 9 червей      | [Эттин](CREATURE:${CREATURE_ETTIN})                                                                     |
| 8 червей      | [Багбир](CREATURE:${CREATURE_BUGBEAR})                                                             |
| 2 червей      | [Гоблин](CREATURE:${CREATURE_GOBLIN})                                                                   |
| Туз бубен     | [Злобоглаз](CREATURE:${CREATURE_BEHOLDER})                                                              |
| Король бубен  | [Архимаг](CREATURE:${CREATURE_ARCHMAGE}) и [Ученик мага](CREATURE:${CREATURE_APPRENTICE_WIZARD})        |
| Дама бубен    | [Ночная карга](CREATURE:${CREATURE_NIGHT_HAG})                                                          |
| Валет бубен   | [Ассасин](CREATURE:${CREATURE_ASSASSIN})                                                                |
| 10 бубен      | [Огненный великан](CREATURE:${CREATURE_FIRE_GIANT})                                                     |
| 9 бубен       | [Огр-маг](CREATURE:${CREATURE_ONI})                                                                     |
| 8 бубен       | [Гнолл](CREATURE:${CREATURE_GNOLL})                                                                     |
| 2 бубен       | [Кобольд](CREATURE:${CREATURE_KOBOLD})                                                                  |
| Туз пик       | [Лич](CREATURE:${CREATURE_LICH})                                                                        |
| Король пик    | [Священник](CREATURE:${CREATURE_PRIEST}) и два [Послушника](CREATURE:${CREATURE_ACOLYTE})               |
| Дама пик      | [Медуза](CREATURE:${CREATURE_MEDUSA})                                                                   |
| Валет пик     | [Ветеран](CREATURE:${CREATURE_VETERAN})                                                                 |
| 10 пик        | [Ледяной великан](CREATURE:${CREATURE_FROST_GIANT})                                                     |
| 9 пик         | [Тролль](CREATURE:${CREATURE_TROLL})                                                                    |
| 8 пик         | [Хобгоблин](CREATURE:${CREATURE_HOBGOBLIN})                                                             |
| 2 пик         | [Гоблин](CREATURE:${CREATURE_GOBLIN})                                                                   |
| Туз треф      | [Железный голем](CREATURE:${CREATURE_IRON_GOLEM})                                                       |
| Король треф   | [Капитан разбойников](CREATURE:${CREATURE_BANDIT_CAPTAIN}) и три [Бандита](CREATURE:${CREATURE_BANDIT}) |
| Дама треф     | [Эриния](CREATURE:${CREATURE_ERINYES})                                                                  |
| Валет треф    | [Берсерк](CREATURE:${CREATURE_BERSERKER})                                                               |
| 10 треф       | [Холмовой великан](CREATURE:${CREATURE_HILL_GIANT})                                                     |
| 9 треф        | [Огр](CREATURE:${CREATURE_OGRE})                                                                        |
| 8 треф        | [Орк](CREATURE:${CREATURE_ORC})                                                                         |
| 2 треф        | [Кобольд](CREATURE:${CREATURE_KOBOLD})                                                                  |
| Джокеры (2)   | Вы (владелец колоды)              |
`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 170,
    },
  },
  {
    id: MAGIC_ITEM_DAGGER_OF_VENOM,
    name: `Кинжал яда`,
    nameEn: `Dagger of Venom`,
    type: MGC_TYPE_WEAPON,
    gearType: GEAR_DAGGER,
    rarity: MGC_RARITY_RARE,
    description: `Вы получаете бонус +1 к броскам атаки и урона, совершённым этим магическим оружием.

Вы можете действием заставить этот клинок покрыться густым чёрным ядом. Яд остаётся 1 минуту, или пока атака этим оружием не попадёт по существу. Цель должна пройти испытание Телосложения СЛ 15, иначе она получит урон ядом 2к10 и станет отравленной на 1 минуту. Вы не можете использовать повторно это свойство до следующего рассвета.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 170,
    },
  },
  {
    id: MAGIC_ITEM_DEFENDER,
    name: `Защитник`,
    nameEn: `Defender`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой меч`,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Вы получаете бонус +3 к броскам атаки и урона этим магическим оружием.

В первый раз в каждом своём ходу, когда Вы атакуете этим оружием, Вы можете перевести весь бонус или его часть в бонус к КД, а не броскам атаки и урона. Например, Вы можете уменьшить бонус к броскам атаки и урона до +1, но получить +2 к КД. Изменённые бонусы действуют до начала Вашего следующего хода, но Вы должны держать этот меч, чтобы получать от него бонус к КД. `,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_DEMON_ARMOR,
    name: `Демонический доспех`,
    nameEn: `Demon Armor`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_PLATE_ARMOR,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Пока Вы носите этот доспех, Вы получаете бонус +1 к КД, можете говорить на языке Бездны и понимаете этот язык.

Кроме того, латные рукавицы с шипами этого доспеха делают Ваши безоружные атаки руками магическим оружием, причиняющим рубящий урон с бонусом +1 к броскам атаки и урона и костью урона 1к8.`,
    curse: `Надев этот проклятый доспех, Вы не можете его снять, пока не станете целью заклинания [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) или подобной магии. Нося этот доспех, Вы совершаете с помехой броски атаки по демонам и испытания от заклинаний и особых умений демонов.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 159,
    },
  },
  {
    id: MAGIC_ITEM_DWARVEN_THROWER,
    name: `Дварфийский метатель`,
    nameEn: `Dwarven Thrower`,
    type: MGC_TYPE_WEAPON,
    gearType: GEAR_WARHAMMER,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    attunementComment: `дварфом`,
    description: `Вы получаете бонус +3 к броскам атаки и урона, совершённым этим магическим оружием. Оно обладает свойством «метательное» с нормальной дистанцией 20 футов и максимальной дистанцией 60 футов.

Если Вы попадёте дальнобойной атакой этим оружием, оно причиняет дополнительный урон 1к8 или, если цель — великан, то 2к8. Сразу же после атаки оружие возвращается в Вашу руку.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 159,
    },
  },
]
