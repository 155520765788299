const {
  SPELL_COLOR_SPRAY,
  SPELL_DANCING_LIGHTS,
  SPELL_DISGUISE_SELF,
  SPELL_INVISIBILITY,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MAJOR_IMAGE,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRROR_IMAGE,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PHANTOM_STEED,
  SPELL_POISON_SPRAY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_ILLUSIONIST} = require('./../../../../creatureIdList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Иллюзионист',
  nameEn: 'Illusionist',
  id: CREATURE_ILLUSIONIST,
  description: `Иллюзионисты — специализированные волшебники, которые искажают свет, звук, тень, и даже сознание, чтобы создать ложные и квазиреальные эффекты. Они могут быть броскими и используют свои силы явными и очевидными способами, или тайно и изящно, используя свою магию, чтобы скрыть правду.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 215,
  },
  armor: [
    12,
    {
      ac: 15,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 4,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Смещение',
      comment: `перезаряжается после сотворения Иллюзионистом заклинания Иллюзии уровня 1 или выше`,
      description: `Бонусным действием иллюзионист проецирует иллюзию себя, из-за чего он кажется находящимся в нескольких дюймах в стороне от его настоящего положения, заставляя любое существо с помехой совершать броски атак по иллюзионисту. Эффект прекращается, если он получает урон, его скорость становится 0 или он становится недееспособным.`,
    },
  ],
  spellCast: {
    spellCasterLevel: 7,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      {
        id: SPELL_COLOR_SPRAY,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Иллюзии 1 уровня и выше, перезаряжающее особенность «Смещение»',
      },
      {
        id: SPELL_DISGUISE_SELF,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Иллюзии 1 уровня и выше, перезаряжающее особенность «Смещение»',
      },
      {
        id: SPELL_INVISIBILITY,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Иллюзии 1 уровня и выше, перезаряжающее особенность «Смещение»',
      },
      {
        id: SPELL_PHANTASMAL_FORCE,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Иллюзии 1 уровня и выше, перезаряжающее особенность «Смещение»',
      },
      {
        id: SPELL_MIRROR_IMAGE,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Иллюзии 1 уровня и выше, перезаряжающее особенность «Смещение»',
      },
      {
        id: SPELL_MAJOR_IMAGE,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Иллюзии 1 уровня и выше, перезаряжающее особенность «Смещение»',
      },
      {
        id: SPELL_PHANTOM_STEED,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Иллюзии 1 уровня и выше, перезаряжающее особенность «Смещение»',
      },
      {
        id: SPELL_PHANTASMAL_KILLER,
        showMagicSchoolIcon: true,
        iconTitle: 'Заклинание школы Иллюзии 1 уровня и выше, перезаряжающее особенность «Смещение»',
      },

      SPELL_DANCING_LIGHTS,
      SPELL_MAGE_ARMOR,
      SPELL_MAGE_HAND,
      SPELL_MAGIC_MISSILE,
      SPELL_MINOR_ILLUSION,
      SPELL_POISON_SPRAY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_QUARTERSTAFF,
    },
  ],
}
