const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_DEVOTION} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  SPELL_BEACON_OF_HOPE,
  SPELL_COMMUNE,
  SPELL_DISPEL_MAGIC,
  SPELL_FLAME_STRIKE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_LESSER_RESTORATION,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_SANCTUARY,
  SPELL_ZONE_OF_TRUTH,
} = require('./../../../spellIdList')
const {
  FEATURE_AURA_OF_DEVOTION,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CHANNEL_DIVINITY_SACRED_WEAPON,
  FEATURE_CHANNEL_DIVINITY_TURN_THE_UNHOLY,
  FEATURE_HOLY_NIMBUS,
  FEATURE_OATH_OF_DEVOTION_SPELLS,
  FEATURE_PURITY_OF_SPIRIT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_DEVOTION_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_BEACON_OF_HOPE,
      SPELL_COMMUNE,
      SPELL_DISPEL_MAGIC,
      SPELL_FLAME_STRIKE,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_GUARDIAN_OF_FAITH,
      SPELL_LESSER_RESTORATION,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_SANCTUARY,
      SPELL_ZONE_OF_TRUTH,
    ],
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_SACRED_WEAPON,
    name: `Божественный канал: Священное оружие`,
    nameEn: `Channel Divinity: Sacred Weapon`,
    lvl: 3,
    text: `Вы можете действием наполнить одно оружие, которое Вы держите, позитивной энергией, используя [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}). На протяжении 1 минуты Вы добавляете свой модификатор Харизмы к броскам атаки, сделанным этим оружием (минимальный бонус +1). Также оружие испускает яркий свет в радиусе 20 футов и тусклый свет в пределах ещё 20 футов. Если оружие не является магическим, то оно считается магическим на время действия заклинания.

Вы можете закончить этот эффект в свой ход частью любого другого действия. Если Вы не держите или не несёте это оружие, или теряете сознание, то этот эффект заканчивается.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_TURN_THE_UNHOLY,
    name: `Божественный канал: Изгнать нечистого`,
    nameEn: `Channel Divinity: Turn the Unholy`,
    lvl: 3,
    text: `Вы действием показываете свой священный символ и произносите слова молитвы, осуждающей исчадия и нежить, используя [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}). Все исчадия и нежить, которые могут видеть или слышать Вас, находящиеся в пределах 30 футов от Вас, должны пройти испытание Мудрости. Если они проваливают испытание, то изгоняются на 1 минуту или до тех пор, пока не получат урон.

Изгнанные существа должны весь свой ход пытаться убежать от Вас так далеко, как только могут, и не могут добровольно переместиться в пространство, находящееся в пределах 30 футов от Вас. Также они не могут совершать реакции. Из всех действий они могут совершать только Рывок и попытки избежать эффекта, не дающего им перемещаться. Если же двигаться некуда, существа могут использовать действие Уклонение.`,
  },
  {
    id: FEATURE_AURA_OF_DEVOTION,
    name: `Аура преданности`,
    nameEn: `Aura of Devotion`,
    lvl: [7, 18],
    text: `Вы и дружественные Вам существа в пределах 10 футов от Вас не могут быть очарованы, пока Вы находитесь в сознании.

На 18 уровне радиус ауры увеличивается до 30 футов.`,
  },
  {
    id: FEATURE_PURITY_OF_SPIRIT,
    name: `Чистота духа`,
    nameEn: `Purity of Spirit`,
    lvl: 15,
    text: `Начиная с 15 уровня Вы всегда находитесь под эффектом заклинания [Защита от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}).`,
  },
  {
    id: FEATURE_HOLY_NIMBUS,
    name: `Святой нимб`,
    nameEn: `Holy Nimbus`,
    lvl: 20,
    text: `Вы можете действием создать ауру солнечного света. В течение 1 минуты от Вас исходит яркий свет в радиусе 30 футов, и тусклый свет в пределах ещё 30 футов.

Каждый раз, когда враждебное существо начинает ход в этом ярком свете, оно получает урон излучением 10.

Кроме того, в течение этого времени Вы совершаете с преимуществом испытания от заклинаний, сотворённых исчадиями и нежитью.

Один раз использовав это умение, Вы не можете использовать его повторно, пока не закончите длинный отдых.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_DEVOTION,
    source: {
      id: SOURCE_PHB,
      page: 87,
    },
  })
)

