const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_PROTECTION_DOMAIN_2016_11_21} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS} = require('./../../../sourceList')
const {
  SPELL_AID,
  SPELL_ANTILIFE_SHELL,
  SPELL_COMPELLED_DUEL,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_SLOW,
  SPELL_WALL_OF_FORCE,
} = require('./../../../spellIdList')
const {
  FEATURE_DIVINE_DOMAIN,
  FEATURE_PROTECTION_DOMAIN_SPELLS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_PROTECTION_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    pcSubClassId: PC_SUBCLASS_CLERIC_PROTECTION_DOMAIN_2016_11_21,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_AID,
      SPELL_ANTILIFE_SHELL,
      SPELL_COMPELLED_DUEL,
      SPELL_GUARDIAN_OF_FAITH,
      SPELL_OTILUKE_S_RESILIENT_SPHERE,
      SPELL_PROTECTION_FROM_ENERGY,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_PROTECTION_FROM_POISON,
      SPELL_SLOW,
      SPELL_WALL_OF_FORCE,
    ],
    source: {
      id: SOURCE_UA_2016_11_21_CLERIC_DIVINE_DOMAINS,
      page: 3,
    },
  },
]
