const {SOURCE_GAME_BG_3} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_YEENOGHU} = require('./../../creatureIdList')
const {CREATURE_TYPE_GNOLL} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_GNOLL,
  nameEn: 'Gnoll',
  description: {
    header: 'Гноллы',
    text: `Дикие, похожие на гиен гуманоиды, убивающие всех, кто им встречается, на потребу своему вечно голодному демоническому творцу, [Йеногу](CREATURE:${CREATURE_YEENOGHU}).`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'гнолл',
      genitive: 'гнолла',
      dative: 'гноллу',
      accusative: 'гнолла',
      instrumental: 'гноллом',
      prepositional: 'гнолле',
    },
    plural: {
      nominative: 'гноллы',
      genitive: 'гноллов',
      dative: 'гноллам',
      accusative: 'гноллов',
      instrumental: 'гноллами',
      prepositional: 'гноллах',
    },
  },
}
