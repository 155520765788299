const {
  GOD_GARL_GLITTERGOLD,
  GOD_KURTULMAK,
  GOD_TIAMAT,
} = require('./../../godIdList')
const {
  PC_RACE_GNOME,
  PC_RACE_KOBOLD,
} = require('./../../pcRaceIdList')
const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')

module.exports = [
  {
    header: `Куртулмак: Бог кобольдов`,
    text: `Бог [кобольдов](PC_RACE:${PC_RACE_KOBOLD}) был вассалом [Тиамат](GOD:${GOD_TIAMAT}). Когда бог [гномов](PC_RACE:${PC_RACE_GNOME}) [Гарл Златоблеск](GOD:${GOD_GARL_GLITTERGOLD}) украл сокровище из хранилища [Тиамат](GOD:${GOD_TIAMAT}), она послала Куртулмака вернуть его. [Гарл](GOD:${GOD_GARL_GLITTERGOLD}) заманил своего преследователя в пещеру лабиринт и завалил выход из нее, навечно заточив Куртулмака.
  
Куртулмак — жестокий бог, презирающий все живое за исключением [кобольдов](PC_RACE:${PC_RACE_KOBOLD}). Особенно он ненавидит [Гарла Златоблеска](GOD:${GOD_GARL_GLITTERGOLD}), [гномов](PC_RACE:${PC_RACE_GNOME}) и фей, обожающих розыгрыши. Он обучил первых [кобольдов](PC_RACE:${PC_RACE_KOBOLD}) шахтерскому ремеслу, скрытности и засадам. Он подвластен своим эмоциям, будучи умным, но не мудрым. Высокомерный и предрасположенный к злу, обидчивый и очень злопамятный, он проводит значительную часть времени разрабатывая изощренные планы мести для тех, кто проявил неуважение к нему.`,
    source: {
      id: SOURCE_VGTM,
      page: 66,
    },
  },
  {
    header: 'Потерянный бог',
    text: `В дополнение к драконам, которых они почитают, [кобольды](PC_RACE:${PC_RACE_KOBOLD}) поклоняются малому богу по имени [Куртулмак](GOD:${GOD_KURTULMAK}).
  
Легенды говорят о том, как [Куртулмак](GOD:${GOD_KURTULMAK}) был вассалом на службе у [Тиамат](GOD:${GOD_TIAMAT}) в Девяти Преисподних, пока [Гарл Златоблеск](GOD:${GOD_GARL_GLITTERGOLD}), бог [гномов](PC_RACE:${PC_RACE_GNOME}), не украл безделушку из сокровищ Королевы Драконов.

[Тиамат](GOD:${GOD_TIAMAT}) послала [Куртулмака](GOD:${GOD_KURTULMAK}), чтобы тот вернул сокровище, но [Гарл Златоблеск](GOD:${GOD_GARL_GLITTERGOLD}) разыграл его, обрушив почву и заманив бога [кобольдов](PC_RACE:${PC_RACE_KOBOLD}) в ловушку, в подземный лабиринт. Поэтому [кобольды](PC_RACE:${PC_RACE_KOBOLD}) ненавидят [гномов](PC_RACE:${PC_RACE_GNOME}) и розыгрыши любого вида. Самые преданные последователи [Куртулмака](GOD:${GOD_KURTULMAK}) посвящают себя поиску и освобождению своего потерянного бога из тюремного лабиринта.`,
    source: {
      id: SOURCE_MM,
      page: 177,
    },
  },
]
