const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_MORNINGSTAR} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')

module.exports = {
  id: GEAR_MORNINGSTAR,
  genderId: GENDER_MALE,
  name: 'Моргенштерн',
  nameByCase: {
    nominative: 'моргенштерн',
    genitive: 'моргенштерна',
    accusative: 'моргенштерн',
    instrumental: 'моргенштерном',
  },
  nameEn: 'Morningstar',
  damage: {
    diceType: 8,
    diceCount: 1,
  },
  cost: 1500,
  damageType: DAMAGE_PIERCING,
  weight: 4,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  category: CAT_MARTIAL_MELEE_WEAPON,
}
