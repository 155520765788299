module.exports = [
  ...require('./_magicItemRawList_DMG_r'),
  require('./ring_of_animal_influence'),
  require('./ring_of_djinni_summoning'),
  require('./ring_of_jumping'),
  require('./ring_of_shooting_stars'),
  require('./ring_of_telekinesis'),
  require('./ring_of_three_wishes'),
  require('./robe_of_eyes'),
  require('./robe_of_scintillating_colors'),
  require('./robe_of_stars'),
  require('./robe_of_the_archmagi'),
  require('./rod_of_absorption'),
  require('./rod_of_alertness'),
  require('./rod_of_lordly_might'),
  require('./rod_of_resurrection'),
  require('./rod_of_rulership'),
  require('./rod_of_security'),
  require('./rope_of_climbing'),
  require('./rope_of_entanglement'),
]
