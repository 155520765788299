const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_BOOK,
  GEAR_ROD,
  GEAR_SPELLBOOK,
  GEAR_TRIDENT,
} = require('./../../../../../gearIdList')
const {
  SPELL_DOMINATE_BEAST,
} = require('./../../../../../spellIdList')
const {
  MGC_TYPE_ROD,
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_SPHERE_OF_ANNIHILATION,
  MAGIC_ITEM_TALISMAN_OF_THE_SPHERE,
  MAGIC_ITEM_TENTACLE_ROD,
  MAGIC_ITEM_TOME_OF_CLEAR_THOUGHT,
  MAGIC_ITEM_TOME_OF_LEADERSHIP_AND_INFLUENCE,
  MAGIC_ITEM_TOME_OF_THE_STILLED_TONGUE,
  MAGIC_ITEM_TOME_OF_UNDERSTANDING,
  MAGIC_ITEM_TRIDENT_OF_FISH_COMMAND,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_TRIDENT_OF_FISH_COMMAND,
    name: `Трезубец командования рыбами`,
    nameEn: `Trident of Fish Command`,
    type: MGC_TYPE_WEAPON,
    gearType: GEAR_TRIDENT,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Этот трезубец — магическое оружие.

У него есть 3 заряда. Если Вы его держите, Вы можете действием потратить 1 заряд, чтобы наложить им заклинание [Подчинение зверя](SPELL:${SPELL_DOMINATE_BEAST}) (СЛ испытания 15) на зверя, у которого есть врождённая скорость плавания.

Трезубец ежедневно восстанавливает 1к3 заряда на рассвете.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_DOMINATE_BEAST],
    source: {
      id: SOURCE_DMG,
      page: 210,
    },
  },
  {
    id: MAGIC_ITEM_TOME_OF_UNDERSTANDING,
    name: `Том понимания`,
    nameEn: `Tome of Understanding`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_BOOK,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: `Эта книга содержит упражнения по тренировке интуиции и проницательности, и её слова наполнены магией.

Если Вы потратите 48 часов за 6 дней на изучение содержимого книги и применение его на практике, Ваша Мудрость, а также её максимум увеличатся на 2. После этого руководство теряет магию, но восстанавливает её через 100 лет.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 209,
    },
  },
  {
    id: MAGIC_ITEM_TOME_OF_CLEAR_THOUGHT,
    name: `Том чистых мыслей`,
    nameEn: `Tome of Clear Thought`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_BOOK,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: `Эта книга содержит упражнения по тренировке памяти и логики, и её слова наполнены магией.

Если Вы потратите 48 часов за 6 дней на изучение содержимого книги и применение его на практике, Ваш Интеллект, а также его максимум увеличатся на 2. После этого руководство теряет магию, но восстанавливает её через 100 лет.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 209,
    },
  },
  {
    id: MAGIC_ITEM_TOME_OF_THE_STILLED_TONGUE,
    name: `Том молчаливого языка`,
    nameEn: `Tome of the Stilled Tongue`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_SPELLBOOK,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    attunementComment: `требуется настройка волшебником`,
    description: `У этого толстого тома с кожаным переплётом к обложке пришит язык. Всего существует пять таких томов, и никто не может сказать, какой из них оригинальный. Жуткое украшение на обложке первого тома когда-то было языком вероломного слуги лича-бога Векны, хранителя тайн. Языки на обложках четырёх копий принадлежали заклинателям, предавшим Векну. Первые несколько страниц каждого тома исписаны непонятными каракулями. Оставшиеся страницы девственно чисты.

Если Вы можете настроиться на этот том, Вы можете использовать его в качестве магической фокусировки. Кроме того, держа этот том в руке, Вы можете бонусным действием наложить заклинание, которое Вы в него записали, не тратя ячейку заклинания и не используя ни вербальный, ни соматический компонент. Вы не можете повторно использовать это свойство тома до следующего рассвета.

Будучи настроенным на эту книгу, Вы можете оторвать язык с обложки. Если Вы это сделаете, все заклинания, записанные в ней, мгновенно стираются.

Векна следит за всеми, кто использует этот том. Он также записывает в книгу таинственные сообщения. Эти сообщения появляются в полночь и исчезают после прочтения.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 209,
    },
  },
  {
    id: MAGIC_ITEM_TOME_OF_LEADERSHIP_AND_INFLUENCE,
    name: `Том лидерства и влияния`,
    nameEn: `Tome of Leadership and Influence`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_BOOK,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    description: `Эта книга содержит руководства по оказанию влияния на других, и её слова наполнены магией.

Если Вы потратите 48 часов за 6 дней на изучение содержимого книги и применение его на практике, Ваша Харизма, а также её максимум увеличатся на 2. После этого руководство теряет магию, но восстанавливает её через 100 лет. `,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 209,
    },
  },
  {
    id: MAGIC_ITEM_TALISMAN_OF_THE_SPHERE,
    name: `Талисман Сферы`,
    nameEn: `Talisman of the Sphere`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Когда Вы совершаете проверку Интеллекта (Магия) для контроля [Сферы аннигиляции](MAGIC_ITEM:${MAGIC_ITEM_SPHERE_OF_ANNIHILATION}), держа этот талисман, Вы удваиваете бонус мастерства для этой проверки. Кроме того, если Вы начинаете ход, удерживая контроль над [сферой аннигиляции](MAGIC_ITEM:${MAGIC_ITEM_SPHERE_OF_ANNIHILATION}), Вы можете действием сделать так, что она переместится на 10 футов плюс количество футов, равное 10 × Ваш модификатор Интеллекта.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 208,
    },
  },
  {
    id: MAGIC_ITEM_TENTACLE_ROD,
    name: `Жезл щупалец`,
    nameEn: `Tentacle Rod`,
    type: MGC_TYPE_ROD,
    gearType: GEAR_ROD,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Этот изготовленный дроу жезл — магическое оружие, оканчивающееся тремя эластичными щупальцами.

Держа этот жезл в руке, Вы можете действием направить каждое щупальце, чтобы оно атаковало существо в пределах 15 футов от Вас. Каждое щупальце совершает бросок рукопашной атаки с бонусом +9. При попадании щупальце причиняет дробящий урон 1к6. Если Вы попали по одной цели всеми тремя щупальцами, она должна пройти испытание Телосложения СЛ 15. При провале на 1 минуту скорость существа уменьшается вдвое, оно получает помеху к испытаниям Ловкости и не может совершать реакции. Более того, в каждом своём ходу оно может или совершать действие или бонусное действие, но не то и другое одновременно. В конце каждого своего хода оно повторяет испытание, оканчивая эффект на себе при успехе.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 162,
    },
  },
]
