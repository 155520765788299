const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_ABOLETH,
  CREATURE_CHUUL,
} = require('./../../../../creatureIdList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_DEEP_SPEECH} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPELL_DETECT_MAGIC} = require('./../../../../spellIdList')

module.exports = {
  name: `Чууль`,
  nameEn: 'Chuul',
  id: CREATURE_CHUUL,
  description: `Ровесники древней империи [аболетов](CREATURE:${CREATURE_ABOLETH}), чуули — это ракообразные, видоизменённые [аболетами](CREATURE:${CREATURE_ABOLETH}) и получившие от них разум. Они следуют плотно засевшим в сознании командам создателей, полученным на заре времён.

# Первобытные реликвии

В ранних эпохах [аболеты](CREATURE:${CREATURE_ABOLETH}) правили обширной империей, охватывавшей все океаны мира. В те дни аболеты пользовались могущественной магией и подчиняли умы появляющихся существ смертных королевств. Однако они были привязаны к воде, и не могли навязывать свою волю за её пределами без слуг. По этой причине они создали чуулей.

Абсолютно послушные чуули собирали разумных существ и магию по приказам [аболетов](CREATURE:${CREATURE_ABOLETH}). Чуулей создали, чтобы они пережили эпохи, плодясь и размножаясь. Империя [аболетов](CREATURE:${CREATURE_ABOLETH}) рассыпалась с возвышением богов, а чуули были брошены на волю случая. Однако эти существа продолжили делать то, что они делали раньше для [аболетов](CREATURE:${CREATURE_ABOLETH}), медленно собирая гуманоидов, накапливая сокровища и магию, и укрепляясь в силе.

# Неутомимые стражи

Чуули по-прежнему охраняют руины древней империи [аболетов](CREATURE:${CREATURE_ABOLETH}). Они застыли в немом соблюдении команд, которым уже тысячи лет. Слухи и древние карты иногда приманивают искателей сокровищ к этим руинам, но награда за их дерзость — смерть.

Какие бы богатства исследователи не принесли с собой, они добавятся к сокровищам, которые охраняют чуули. Чуули могут чувствовать магию на расстоянии. Это чувство вместе с врождённой склонностью принуждает их убивать исследователей, брать их имущество и хоронить в секретном месте, продиктованном [аболетами](CREATURE:${CREATURE_ABOLETH}) эоны тому назад.

# Ожидающие прислужники

Хотя древняя империя [аболетов](CREATURE:${CREATURE_ABOLETH}) давно пала, духовные связи между ними и их создателями остались целыми. Чуули, которые входят в контакт с [аболетами](CREATURE:${CREATURE_ABOLETH}), немедленно принимают свои старые роли. Такие чуули направляют все свои усилия на служение зловещим [аболетам](CREATURE:${CREATURE_ABOLETH}).`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 297,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 16,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_DEEP_SPEECH,
      doNotSpeak: true,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: `Чувство магии`,
      description: `★СУЩЕСТВО★ чувствует магию в пределах 120 футов. Во всём остальном эта особенность действует как заклинание [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}), но не имеет магической природы.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Клешнями_. Если ★СУЩЕСТВО★ держит в захвате существо, ★он★ может также один раз использовать _Щупальца_.`,
    },
    {
      name: `Клешня`,
      description: `Цель становится схваченной (СЛ высвобождения 14), если у неё размер не больше Большого, и ★СУЩЕСТВО★ держит в захвате не более одного существа.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Щупальца`,
      description: `Одно существо, которое ★схватил★ ★СУЩЕСТВО★, должно пройти испытание Телосложения СЛ 13, иначе станет отравленным на 1 минуту. Пока цель отравлена, она парализована. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
