const {
  SOURCE_ERFTLW,
} = require('./../../../../sourceList')
const {
  CR_1_8,
} = require('./../../../../crList')
const {
  SIZE_TINY,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_HOMUNCULUS,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ACROBATICS,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_ONE_OF_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FLYBY,
  ABILITY_TELEPATHIC_BOND,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  INFUSION_HOMUNCULUS_SERVANT,
} = require('./../../../../infusionIdList')
const {
  SPELL_CREATE_HOMUNCULUS,
} = require('./../../../../spellIdList')
const {
  homunculiDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_EXPEDITIOUS_MESSENGER,
  CREATURE_SPRITE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Быстрый вестник',
  nameEn: 'Expeditious Messenger',
  id: CREATURE_EXPEDITIOUS_MESSENGER,
  createdBySpellId: SPELL_CREATE_HOMUNCULUS,
  createdByInfusionId: INFUSION_HOMUNCULUS_SERVANT,
  description: [
    `Быстрый вестник — скоростной летун, спроектированный для быстрой доставки сообщений своему создателю.

Вестники очень быстры и обычно обладают обтекаемой формой. Они довольно болтливы, и тараторят так же быстро, как и двигаются.

Вестники бывают множества форм, и часто выглядят как механические птицы или [спрайты](CREATURE:${CREATURE_SPRITE}).`,
    ...homunculiDescriptionList,
  ],
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_HOMUNCULUS,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_ERFTLW,
    page: 294,
  },
  armor: 13,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 25,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 13,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ONE_OF_ITS_CREATOR,
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_FLYBY,
    ABILITY_TELEPATHIC_BOND,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
