const {
  CREATURE_TYPE_ELEMENTAL_EARTH,
  CREATURE_TYPE_ELEMENTAL,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
  CREATURE_EARTH_ELEMENTAL_SPIRIT,
  CREATURE_EARTH_ELEMENTAL_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух земного элементаля',
  nameEn: 'Earth Elemental Spirit',
  id: CREATURE_EARTH_ELEMENTAL_SPIRIT_2020_03_26,
  releasedAs: CREATURE_EARTH_ELEMENTAL_SPIRIT,
  parentId: CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_EARTH,
  ],
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_DIG]: 40,
  },
  resistanceList: [
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  ],
  featureList: null,
}
