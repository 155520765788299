const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BLINK_DOG,
  CREATURE_CAT,
  CREATURE_DISPLACER_BEAST,
  CREATURE_PEGASUS,
  CREATURE_UNICORN,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Ускользающий зверь`,
  nameEn: 'Displacer Beast',
  id: CREATURE_DISPLACER_BEAST,
  description: [
    `Этот чудовищный хищник получил своё название от способности искривлять свет таким образом, что он кажется стоящим в нескольких футах от того места, где он находится на самом деле. Ускользающий зверь похож на большого [кота](CREATURE:${CREATURE_CAT}), покрытого гладким иссиня-чёрным мехом. Его чужеродное происхождение явно заметно по шести ногам и двум щупальцам, которые растут из его плеч, и заканчиваются подушечками с острыми шипами. Глаза зверя светятся ужасающей злобой, сохраняющейся даже после его смерти.`,
    {
      header: 'Неблагое происхождение',
      text: `Ускользающие звери веками бродили в сумеречных землях Страны Фей, пока не были порабощены и обучены Неблагим Двором. Воины Двора выводили зверей, стараясь усилить их свирепый и хищный нрав, используя их для охоты на [единорогов](CREATURE:${CREATURE_UNICORN}), [пегасов](CREATURE:${CREATURE_PEGASUS}) и других фейских зверей. Тем не менее, у ускользающий зверей ушло не так много времени, чтобы использовать свой злобный разум, и сбежать от своих хозяев.

Бежавшие и свободно размножающиеся в Стране Фей ускользающие звери вскоре привлекли внимание Благого Двора. С помощью [мерцающих псов](CREATURE:${CREATURE_BLINK_DOG}) фейские охотники отогнали этих хищников на окраину Страны Фей, где многие из них перешли на Материальный план. По сей день ускользающие звери и [мерцающие псы](CREATURE:${CREATURE_BLINK_DOG}) нападают друг на друга, едва завидев.`,
      source: {
        id: SOURCE_MM,
        page: 289,
      },
    },
    {
      header: 'Любовь к убийству',
      text: `Ускользающие звери убивают не только ради пропитания, но и просто ради забавы. Они ищут добычу даже когда не голодны, и часто играют с жертвой, чтобы развлечь себя, пока они не станут готовы приступить к трапезе. После убийства добычи щупальцами, ускользающий зверь утаскивает труп в тихое место, где ничто не отвлечёт его от насыщения.

Ускользающие звери охотятся в одиночку или небольшой стаей, демонстрируя мастерство в установке засады. Одинокий зверь ударит и исчезнет, заманивая добычу в дремучую чащу, где ждёт остальная стая. Стаи ускользающих зверей, охотящиеся вблизи торговых путей, запоминают частоту и расписание регулярных караванов, и устраивают засады на них.`,
      source: {
        id: SOURCE_MM,
        page: 289,
      },
    },
    {
      header: 'Ценные сторожевые и домашние животные',
      text: `Злобные разумные существа держат ускользающих зверей как питомцев, но последние вступают в такой союз только если сочтут его полезным. Ускользающий зверь может охранять жилище или выступать телохранителем у знатной личности.`,
      source: {
        id: SOURCE_MM,
        page: 289,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 289,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 8,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Избегание',
      description: `Если ★СУЩЕСТВО★ подвергается эффекту, позволяющему пройти испытание, чтобы получить только половину урона, ★он★ вообще не получает урон, если преуспеет, и получает лишь половину урона при провале.`,
    },
    {
      name: 'Ускользание',
      description: `★СУЩЕСТВО★ проецирует магическую иллюзию, из-за которой кажется, что ★он★ находится немного в стороне, и броски атаки по ★нему★ совершаются с помехой. Если по ★нему★ попадает атака, эта особенность перестаёт действовать до конца ★его★ следующего хода. Эта особенность также перестаёт действовать когда ★СУЩЕСТВО★ ★недееспособен★ или когда ★его★ скорость равна 0.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Щупальцам_.`,
    },
    {
      name: `Щупальце`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  genderId: GENDER_MALE,
}
