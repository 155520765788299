const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {DAMAGE_THUNDER} = require('./../../../../../damageTypeList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_THUNDER_STEP} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_THUNDER_STEP,
  name: 'Громовой шаг',
  nameEn: 'Thunder Step',
  description: `Вы телепортируете себя в незанятое пространство, которое Вы можете видеть в пределах дистанции. Сразу после того, как Вы исчезли, раздается раскат грома, и каждое существо в радиусе 10 футов от покинутого пространства должно пройти испытание Телосложения, получив 3к10 урона звуком при провали или половину этого урона при успехе. Гром слышен на расстоянии до 300 футов.

Вы можете взять с собой предметы, если их вес позволяет Вам их нести. Вы также можете взять с собой одно согласное существо, с размером не больше Вашего, которое тоже может нести дозволенный вес предметов. При накладывании заклинания существо должно находиться в пределах 5 футов от вас, также должно быть незанятое пространство в пределах 5 футов от Вашей точки назначения, где существо должно появиться; в противном случае существо не телепортируется.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 4 уровня или
выше, урон увеличивается на 1к10 за каждый круг ячейки выше 3.`,
  lvl: 3,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL],
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_THUNDER,
      halfOnSuccess: true,
      diceCount: 3,
      diceType: 10,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 151,
  },
}
