const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_ELADRIN,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_FEY,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
} = require('./../../../../gearIdList')
const {
  eladrinDescription,
  eladrinNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_PARRY,
} = require('./../../../../creatureAbilityList')
const {
  SKILL_ATHLETICS,
  SKILL_INTIMIDATION,
} = require('./../../../../skillList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_ELADRIN_SUMMER} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Летний эладрин',
  nameEn: 'Summer Eladrin',
  id: CREATURE_ELADRIN_SUMMER,
  description: [
    `Разгневанные эладрины входят в летнюю пору, горячее, бурное состояние, превращающее их в агрессивных воинов, желающих выразить свой гнев. Магия реагирует на их ярость, усиливая собственные боевые способности, что помогает им двигаться с удивительной быстротой и наносить удары со страшной силой.`,
    ...eladrinDescription,
  ],
  note: eladrinNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_ELADRIN,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MTOF,
    page: 244,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 21,
    [PARAM_CON]: 16,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 18,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ELVEN,
    LANG_SYLVAN,
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Фейский шаг',
      restore: 4,
      description: `Бонусным действием эладрин может телепортироваться на расстояние до 30 фт. в незанятое пространство, которое он может видеть.`,
    },
    {
      name: 'Пугающее присутствие',
      description: `Любое существо, не являющееся эладрином и начинающее свой ход в пределах 60 футов от летнего эладрина, должно пройти испытание Мудрости СЛ 16. При провале существо пугается на 1 минуту. Существо может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. При успехе существо становится невосприимчивым к _Пугающему присутствию_ всех эладринов на 24 часа.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Эладрин совершает две атаки оружием.`,
    },
    {
      name: 'Длинный меч',
      // gearId: GEAR_LONGSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 4,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 10,
              diceBonus: 4,
              use2Hand: true,
            },
          ],
          ,
        ],
      },
    },
    {
      name: 'Длинный лук',
      // gearId: GEAR_LONGBOW, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 9,
        range: {
          normal: 150,
          max: 600,
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 5,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  reactionList: [
    {
      id: ABILITY_PARRY,
      ac: 3,
    },
  ],
}
