const {GENDER_MALE} = require('./../../../genderList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const axesDescription = require('./../../constants/axesDescription')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_BATTLEAXE} = require('./../../../gearIdList')
const {WEAPON_VERSATILE} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_BATTLEAXE,
  genderId: GENDER_MALE,
  name: 'Боевой топор',
  nameByCase: {
    nominative: 'боевой топор',
    genitive: 'боевого топора',
    accusative: 'боевой топор',
    instrumental: 'боевым топором',
  },
  nameEn: 'Battleaxe',
  description: axesDescription,
  damage: {
    diceType: 8,
    diceCount: 1,
  },
  cost: 1000,
  damageType: DAMAGE_SLASHING,
  weight: 4,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 111,
    },
  ],
  weaponPropList: [
    {
      id: WEAPON_VERSATILE,
      value: {
        diceCount: 1,
        diceType: 10,
      },
    },
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
