const {SOURCE_MM} = require('./../../sourceList')

module.exports = {
  header: 'Фальшивое поклонение',
  text: `Жизни юань-ти вращается вокруг их храмов, однако юань-ти не любят богов, которым поклоняются. Вместо этого они рассматривают поклонение как способ достижения силы. Юань-ти верят, что индивид, у которого окажется достаточно силы, сможет поглотить и заменить одного из богов юань-ти. Юань-ти стремятся к вознесению, и готовы совершить самые ужасные злодейства, чтобы его достичь.`,
  source: {
    id: SOURCE_MM,
    page: 312,
  },
}
