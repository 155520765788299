const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} = require('./../../../../pcClassIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_TURN_IMMUNITY} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_CRAWLING_CLAW} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Ползающая рука',
  nameEn: 'Crawling Claw',
  id: CREATURE_CRAWLING_CLAW,
  description: `Ползающие руки — это отрубленные руки убийц, оживлённые тёмной магией, чтоб они могли продолжать убийства. [Волшебники](PC_CLASS:${PC_CLASS_WIZARD}) и [колдуны](PC_CLASS:${PC_CLASS_WARLOCK}) с тёмными наклонностями используют ползающие руки в качестве помощников в своём деле.

# Магическое происхождение

Путём темных ритуалов некромантии, жизненная сила убийцы связывается с его отрубленной рукой, оживляя её. Если дух убийцы уже существует как другая нежить, если убийца был по-настоящему оживлён, или если его душа уже отошла в другой план, ритуал не удаётся. Ритуал создания ползающей руки проходит лучше, если рука была отрублена от убийцы совсем недавно. Чтобы заполучить подходящие руки, проводящие ритуалы и их слуги посещают публичные казни или заключают сделки с наёмными убийцами и палачами.

# Контроль создателя

Ползающую руку нельзя изгнать или контролировать заклинанием, действующим на нежить. Эти отвратительные монстры полностью подчиняются воле своего создателя, которому необходимо лишь сконцентрироваться на руке, чтобы мысленно управлять каждым её действием. Если хозяин ползающей руки не даёт ей задание, рука продолжает изо всех сил следовать последней полученной команде.

Задачи для ползающей руки должны быть предельно просты. Руке нельзя приказать найти и убить кого-то конкретного — из-за её ограниченных чувств и интеллекта она не сможет выследить и выбрать определённую личность. Однако команда убить всех существ в определённом месте сработает. Ползающая рука с лёгкостью нащупывает ключи и дверные ручки, и прокрадываются из комнаты в комнату в слепой череде убийств.

# Злой разум

Ползающая рука содержит мало воспоминаний и мыслей того, чей частью она раньше была. Однако ненависть, зависть или жадность, что привела человека к убийству, остаётся, и даже усиливается. Оставленная на своё усмотрение ползающая рука повторяет акты убийства, совершённые ей при жизни.

# Живые руки

Если ползающая рука была сделана из отрубленной руки ещё живого убийцы, ритуал связывает её с душой убийцы. Отрубленная кисть может вернуться к своему бывшему владельцу, и её неживая плоть соединится с живой рукой, от которой была отрезана.

Ставший вновь целым, убийца живёт так, словно рука никогда не была отрублена, и ритуал никогда не проводился. Когда ползающая рука отделяется вновь, тело впадает в кому. Уничтожение ползающей руки, пока она отделена от тела, убивает носителя. Однако лишение жизни убийцы никак не влияет на ползающую руку.

# Природа нежити

Ползающая рука не нуждается в воздухе, еде, питье и сне.`,
  note: {
    text: `Ты, наверное, гадаешь, что можно сделать со всеми остальными частями убийцы, да?`,
    author: `Евангелиза Лавэйн, некромант`,
  },
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 239,
  },
  armor: 12,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_CLIMB]: 20,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 4,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
      isBlindOutside: true,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_TURN_IMMUNITY,
  ],
  actionList: [
    {
      name: `Коготь`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      name: `Коготь`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
