const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  devilDescriptionList,
  devilNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_TRUE_VISION,
} = require('./../../../../senseList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_ORTHON} = require('./../../../../creatureIdList')
const {GEAR_HALF_PLATE} = require('./../../../../gearIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Ортон',
  nameEn: 'Orthon',
  id: CREATURE_ORTHON,
  description: [
    `Когда эрцгерцогу Девяти Преисподней нужно выследить, отыскать существо, или и то, и другое, а может даже и схватить его, то он обращается к ортонам. Ортоны — инфернальные охотники за головами, неустанно охотящиеся за своей добычей по всей мультивселенной.`,
    {
      header: 'Невидимы и всевидящи',
      text: `Ортоны печально известны своим острым чутьём. Благодаря своей способности становится невидимым, ортону удаётся внезапно напасть на ничего не подозревающую добычу. Однако, если на него нападут, то ортон выходит из невидимости, и поэтому самая лучшая защита против ортона — это решительная контратака.`,
      source: {
        id: SOURCE_MTOF,
        page: 184,
      },
    },
    {
      header: 'Охотничий настрой',
      text: `Ортоны превыше всего ценят опасные погони и острые ощущения от боёв с глазу на глаз. Прежде всего, ортон остаётся верен своему эрцгерцогу, однако, если у него нет никаких срочный поручений, ортон может поработать на любого, кто предложит ему поймать перспективную добычу, которая смогла бы сражаться с ним на равных. Поскольку они путешествуют по многим местам, нет лучше проводников по слоям Девяти Преисподней, чем ортоны.`,
      source: {
        id: SOURCE_MTOF,
        page: 184,
      },
    },
    ...devilDescriptionList,
  ],
  note: devilNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 184,
  },
  armor: {
    ac: 17,
    gearId: GEAR_HALF_PLATE,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 21,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
    [SKILL_SURVIVAL]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
    {
      id: SENSE_TRUE_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Невидимое поле',
      description: `Ортон может бонусным действием стать невидимым. Любое снаряжение, которое он несёт и носит, также становится невидимым, пока ортон не решит снять тот или иной предмет со своей экипировки. Невидимость немедленно прекратится после того, как ортон совершит атаку или будет поражён атакой противника.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Инфернальный кинжал',
      description: `Цель должна пройти испытание Телосложения СЛ 17, получая 22 (4к10) урона ядом при провале, или половину этого урона при успехе. К тому же, провалив испытание, цель отравляется на 1 минуту. Отравленная цель может повторить испытание в конце своего хода и избавится от этого эффекта при успехе. `,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Латунный арбалет',
      description: `Ортон применяет один из следующих эффектов:

1. **Кислота.** Цель должна пройти испытание Телосложения СЛ 17, получая 17 (5к6) урона кислотой при провале, или половину этого урона при успехе.
2. **Слепота (1/день).** Цель получает 5 (1к10) урона излучением. Вдобавок, цель и все существа в пределах 20 футов от неё должны пройти испытание Ловкости СЛ 17, или ослепнуть до конца следующего хода ортона.
3. **Сотрясение.** Цель и все существа в пределах 20 футов от неё должны пройти испытание Телосложения СЛ 17, получая 13 (2к12) урона звуком при провале, или половину этого урона при успехе.
4. **Окутывание паутиной.** Цель должна пройти испытание Ловкости СЛ 17, иначе будет на 1 час обездвижена липкой паутиной. Обездвиженное паутиной существо может вырваться действием, если пройдёт испытание Силы или Ловкости СЛ 17. Любое существо, кроме ортона, которое попытается дотронуться до липкой паутины, должно пройти испытание Ловкости СЛ 17, иначе тоже будет обездвижено паутиной.
5. **Паралич (1/день).** Цель получает 22 (4к10) урона электричеством и должна пройти испытание Телосложения СЛ 17, иначе будет парализована на 1 минуту. Парализованное существо можно повторить испытание в конце своего хода, оканчивая эффект на себе при успехе.
6. **Слежка.** В последующие 24 часа, ортон знает местоположение и расстояние до своей цели, при условии, что она находится на этом плане существования. Если цель находится на другом плане, ортон знает — на каком именно, но не знает точного её местоположения.`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 7,
        range: {
          normal: 100,
          max: 400,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
  ],
  reactionList: [
    {
      name: 'Взрывное возмездие',
      description: `Когда хиты ортона опускаются 15 единиц или меньше, то он может взорваться. Все существа, находящиеся на расстоянии 30 футов от него, должны пройти испытание Ловкости СЛ 17, получая огненного урона 9 (2к8) + урона звуком 9 (2к8) при провале, и половину этого урона при успехе. Взрыв уничтожает как само тело ортона, так и его инфернальный кинжал, и латунный арбалет.`,
    },
  ],
}
