module.exports = [
  require('./scatter'),
  require('./shadow_blade'),
  require('./shadow_of_moil'),
  require('./shape_water'),
  require('./sickening_radiance'),
  require('./skill_empowerment'),
  require('./skywrite'),
  require('./snare'),
  require('./snilloc_s_snowball_swarm'),
  require('./soul_cage'),
  require('./steel_wind_strike'),
  require('./storm_sphere'),
  require('./summon_greater_demon'),
  require('./summon_lesser_demons'),
  require('./synaptic_static'),
]
