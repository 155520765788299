const {CREATURE_TYPE_DEVIL} = require('./../../creatureTypeIdList')
const {
  devilNote,
  devilDescriptionList,
} = require('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_DEVIL,
  nameEn: 'Devil',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'дьявол',
        genitive: 'дьявола',
        dative: 'дьяволу',
        accusative: 'дьявола',
        instrumental: 'дьяволом',
        prepositional: 'дьяволе',
      },
      [GENDER_FEMALE]: {
        nominative: 'дьяволица',
        genitive: 'дьяволицы',
        dative: 'дьяволице',
        accusative: 'дьяволицу',
        instrumental: 'дьяволицей',
        prepositional: 'дьяволице',
      },
    },
    plural: {
      nominative: 'дьяволы',
      genitive: 'дьяволов',
      dative: 'дьяволам',
      accusative: 'дьяволов',
      instrumental: 'дьяволами',
      prepositional: 'дьяволах',
    },
  },
  note: devilNote,
  description: devilDescriptionList,
}
