const {SOURCE_WDH} = require('./../../../../sourceList')
const {CR_13} = require('./../../../../crList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../../../skillList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {
  GEAR_PLATE_ARMOR,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {SPEED_WALK} = require('./../../../../speedList')
const {
  ATTACK_MELEE_SPELL,
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPELL_ANTIMAGIC_FIELD,
  SPELL_BANISHMENT,
  SPELL_BIGBY_S_HAND,
  SPELL_CHAIN_LIGHTNING,
  SPELL_COUNTERSPELL,
  SPELL_DETECT_MAGIC,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FLY,
  SPELL_FORCECAGE,
  SPELL_GEAS,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_IDENTIFY,
  SPELL_IMPRISONMENT,
  SPELL_INVISIBILITY,
  SPELL_LIGHT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_MISTY_STEP,
  SPELL_POWER_WORD_STUN,
  SPELL_PRESTIDIGITATION,
  SPELL_PRISMATIC_SPRAY,
  SPELL_SENDING,
  SPELL_STONESKIN,
  SPELL_TELEKINESIS,
  SPELL_THUNDERWAVE,
  SPELL_WEB,
} = require('./../../../../spellIdList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC,
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  GENDER_FEMALE,
  GENDER_MIDDLE,
} = require('./../../../../genderList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  LANG_GIANT,
  LANG_COMMON,
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
  LANG_HALFLING,
  LANG_ELVEN,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {MAGIC_ITEM_BLACKSTAFF} = require('./../../../../magicItemIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {CREATURE_VAJRA_SAFAHR} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Ваджра Сафар',
  nameAlt: 'Чёрный Посох',
  nameEn: 'Vajra Safahr',
  id: CREATURE_VAJRA_SAFAHR,
  hasSpoilers: true,
  description: {
    header: 'Ваджра Сафар',
    text: `Эта талантливая волшебница — самая молодая из тех, кто занимал должность Чёрного Посоха, ей ещё не исполнилось и сорока. Как верховная волшебница Глубоководья, она обязана использовать всю доступную ей магию для защиты города от угроз. На эту должность её выбрал Келбен Эрансан и она носит [Чёрный посох Келбена](MAGIC_ITEM:${MAGIC_ITEM_BLACKSTAFF}), от которого и происходит название должности. Ваджра не самая могущественная волшебница в городе, но способна постоять за себя. Несмотря на одарённость, она сомневается, что справится с тяготами своей роли. Она редко принимает решения, не посоветовавшись с Чёрным посохом, где заключены духи Келбена и прочих ееё предшественников на посту. Ей также доставляют сведения из разных источников как её собственная сеть лазутчиков, так и агенты Арфистов.

Ваджра возглавляет Академию Чёрного Посоха — школу магов в Замковом районе. Она также руководит Серым Отрядом, состоящим из опытных искателей приключений, которых может призывать на помощь городу при нужде. Ваджра постоянно ищет новых искателей приключений для Серого Отряда, особенно таких, кто владеет уникальными навыками, способностями или заклинаниями.

Многие старые и опытные волшебники Глубоководья считают Ваджру наглой выскочкой, но им хватает ума не конфликтовать с ней. Только Явный лорд Глубоководья (на сегодняшний день это Лейраль Среброрукая) может снять Ваджру с должности.`,
    source: {
      id: SOURCE_WDH,
      page: 195,
    },
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_WDH,
    page: 195,
  },
  armor: [
    14,
    {
      ac: 17,
      comment: `[Чёрный посох](MAGIC_ITEM:${MAGIC_ITEM_BLACKSTAFF}); с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 23,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
  },
  languageList: [
    LANG_GIANT,
    LANG_COMMON,
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
    LANG_HALFLING,
    LANG_ELVEN,
  ],
  cr: CR_13,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    spellCasterLevel: 18,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_ANTIMAGIC_FIELD,
      SPELL_BANISHMENT,
      SPELL_BIGBY_S_HAND,
      SPELL_CHAIN_LIGHTNING,
      SPELL_COUNTERSPELL,
      SPELL_DETECT_MAGIC,
      SPELL_FIRE_BOLT,
      SPELL_FIRE_SHIELD,
      SPELL_FLY,
      SPELL_FORCECAGE,
      SPELL_GEAS,
      SPELL_GLOBE_OF_INVULNERABILITY,
      SPELL_IDENTIFY,
      SPELL_IMPRISONMENT,
      SPELL_INVISIBILITY,
      SPELL_LIGHT,
      SPELL_MAGE_ARMOR,
      SPELL_MAGE_HAND,
      SPELL_MESSAGE,
      SPELL_MISTY_STEP,
      SPELL_POWER_WORD_STUN,
      SPELL_PRESTIDIGITATION,
      SPELL_PRISMATIC_SPRAY,
      SPELL_SENDING,
      SPELL_STONESKIN,
      SPELL_TELEKINESIS,
      SPELL_THUNDERWAVE,
      SPELL_WEB,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      3,
      1,
      1,
      1,
      1,
    ],
  },
  actionList: [
    {
      magicItemId: MAGIC_ITEM_BLACKSTAFF,
      description: `При попадании ★СУЩЕСТВО★ может потратить 1 заряд посоха, чтобы нанести дополнительно 3 (1к6) урона силовым полем.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 1,
              diceType: 6,
              diceBonus: 2,
            },
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 1,
              diceType: 8,
              diceBonus: 2,
              use2Hand: true,
            },
          ],
        ],
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
