const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_CELESTIAL,
  LANG_ELVEN,
  LANG_SYLVAN,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  ABILITY_CHARGE,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_CHARMED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_LG} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_TYPE_CELESTIAL} = require('./../../../../creatureTypeIdList')
const {CREATURE_UNICORN} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Единорог',
  nameEn: 'Unicorn',
  id: CREATURE_UNICORN,
  description: [
    `Единороги обитают в зачарованных лесах. Не являющиеся родственниками лошадей, на которых они так похожи, белые и сияющие, как звёзды, единороги — это небожители, оберегающие лесные царства. Изо лба единорога растёт один закрученный спиралью рог, прикосновение которого может лечить больных и раненых. Его уши ловят каждое слово и шёпот существ, которые обитают в его владениях, и он знает языки эльфов и лесных народов. Единороги позволяют добрым существам входить в свой лес для охоты и сбора еды и всегда держат злодеев подальше. Злые существа редко покидают владения единорогов живыми.`,
    {
      header: `Божественные охранники`,
      text: `Добрые божества отправляют единорогов на Материальный план, чтобы уберечь от зла, защитить и сохранить священные места. Большинство единорогов защищает определённую территорию, такую как зачарованный лес. Впрочем, боги иногда посылают единорогов охранять священные артефакты или защищать определённых существ. Когда силы тьмы преследуют кого-то, кого боги хотят защитить, они могут направить его в лес единорога, где злые существа найдут лишь свою погибель.

Единороги часто служат божествам лесов и дикой местности, включая богов добрых фей. Все единороги обладают природной способностью к врачеванию, но некоторым из них боги более благосклонны, и они способны творить чудеса, доступные обычно лишь высшим жрецам.`,
      source: {
        id: SOURCE_MM,
        page: 153,
      },
    },
    {
      header: `Владыки леса`,
      text: `Лес единорога — это святое царство, где под сенью древ ничто не пройдёт незамеченным. Единорог слышит каждую ноту, спетую эльфами в кронах деревьев. Он чувствует, как гусеницы ворочаются в своих коконах, и как качаются листья и ветки, когда на них опускаются бабочки, чтобы дать отдых своим крыльям.

Лес единорога пронизан чувством спокойствия. Все, от волков и лис до птиц, белок и маленьких жуков, во владениях единорога, кажется, обрели покой. Пикси, спрайты, сатиры, дриады и другие обычно переменчивые феи, которые живут под сенью его леса, верно служат единорогу. Под защитой единорога существа ощущают себя в безопасности от угроз подкрадывающейся цивилизации и распространяющегося зла и коварства.

Единороги постоянно обходят свои владения, двигаясь осторожно, чтобы не потревожить других обитателей. Чужак может лишь краем глаза заметить это движение, чтобы, обернувшись, не увидеть ничего кроме диких лесов.`,
      source: {
        id: SOURCE_MM,
        page: 153,
      },
    },
    {
      header: `Священный рог`,
      text: `Рог единорога фокусирует его мощь, являясь частичкой божественной магии, заключённой в спиральной кости. Волшебные палочки из такого рога создают мощную магию, а удары рога единорога обладают божественной силой. Волшебники могут использовать истолчённый рог единорога для создания мощных зелий, чернил для свитков и использовать его как компонент для сверхъестественных ритуалов. Но любое существо, которое примет участие, хотя бы и самое малое, в убийстве единорога, весьма вероятно подвергнется божественной каре.`,
      source: {
        id: SOURCE_MM,
        page: 153,
      },
    },
    {
      header: `Благословенные скакуны`,
      text: `Когда тьма и зло угрожают захватить мир смертных, боги иногда считают уместным свести единорога с достойным наездником-героем. Паладин верхом на единороге является знаком прямого божественного вмешательства в дела мира смертных. Это священный союз, созданный, чтобы рубить головы демонам и изгонять дьяволов обратно в Девять Преисподних.

В опасные времена, когда тьма подступает всё ближе, единорог остаётся со своим героем, а его рог сияет ярко, отгоняя ночь. Но если избранник богов отступает от своей благодати и отворачивается от дела добра, единорог покидает его, и никогда более не возвращается.`,
      source: {
        id: SOURCE_MM,
        page: 153,
      },
    },
    {
      header: `Логово единорога`,
      text: `Логово единорога может быть расположено в древних руинах, увитых плющом, на туманной поляне, в окружении могучих дубов, на покрытом цветами холме, покрытом живым ковром из бабочек, или где-либо ещё в таких же безмятежных лесных местах.

# Местные эффекты

Изменённые присутствием небожителя, владения единорога могут обладать любыми из следующих магических эффектов:

* Открытый огонь немагического происхождения тухнет во владениях единорога. Факелы и костры отказываются гореть, но закрытые фонари функционируют нормально. 
* Существам, для которых владения единорога — родные места, легче здесь прятаться. Они совершают с преимуществом проверки Ловкости (Скрытность) для того, чтобы спрятаться. 
* Когда существо с добрым мировоззрением сотворяет заклинание или использует магический эффект, восстанавливающий хиты другого доброго существа, цель восстанавливает максимум возможных хитов для этого заклинания или эффекта. 
* Проклятья, наложенные на существ с добрым мировоззрением, подавляются. Если единорог умирает, эти эффекты немедленно завершаются.`,
      source: {
        id: SOURCE_MM,
        page: 153,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_LG,
  source: {
    id: SOURCE_MM,
    page: 154,
  },
  armor: 12,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 15,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 16,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
    [CONDITION_CHARMED],
    [CONDITION_PARALYZED],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_CELESTIAL,
    LANG_SYLVAN,
    LANG_ELVEN,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Рогом',
      damageText: '9 (2к8) колющего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 15, иначе будет сбита с ног.',
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Копытами_, и одну _Рогом_.`,
    },
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Рог',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Целебное касание',
      description: `★СУЩЕСТВО★ касается другого существа своим рогом. Цель магическим образом восстанавливает 11 (2к8+2) хитов. Кроме того, это касание лечит все болезни и нейтрализует все яды, действующие на цель.`,
      limit: {
        count: 3,
        period: 'день',
      },
    },
    {
      name: 'Телепортация',
      description: `★СУЩЕСТВО★ магическим образом телепортирует себя и до трёх согласных существ, которых ★он★ видит в пределах 5 футов от себя, вместе со всем несомым и носимым ими снаряжением, в известное ★ему★ место, находящееся в пределах 1 мили.`,
      limit: {
        count: 1,
        period: 'день',
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Копыта',
      description: `★СУЩЕСТВО★ совершает одну атаку копытами.`,
      cost: 2,
    },
    {
      name: 'Мерцающий щит',
      description: `★СУЩЕСТВО★ создаёт мерцающее магическое поле вокруг себя или другого существа, видимого в пределах 60 футов. Цель получает до конца следующего хода единорога бонус +2 к КД.`,
      cost: 2,
    },
    {
      name: 'Самолечение',
      description: `★СУЩЕСТВО★ магическим образом восстанавливает 11 (2к8 + 2) хитов.`,
      cost: 3,
    },
  ],
  genderId: GENDER_MALE,
}
