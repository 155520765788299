const {
  SPELL_HEAL,
  SPELL_RESURRECTION,
} = require('./../../../../../spellIdList')
const {GEAR_ROD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROD_OF_RESURRECTION} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ROD} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ROD_OF_RESURRECTION,
  name: `Жезл воскрешения`,
  nameEn: `Rod of Resurrection`,
  type: MGC_TYPE_ROD,
  gearType: GEAR_ROD,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `друидом, жрецом или паладином`,
  description: `У этого жезла есть 5 зарядов. Если Вы держите его, Вы можете действием наложить им одно из следующих заклинаний: [Полное исцеление](SPELL:${SPELL_HEAL}) (тратит 1 заряд) или [Воскрешение](SPELL:${SPELL_RESURRECTION}) (тратит 5 зарядов).

Жезл ежедневно восстанавливает 1 заряд на рассвете. Если заряды жезла уменьшаются до 0, бросьте к20. Если выпадет «1», жезл исчезнет во вспышке света. `,
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_HEAL,
    SPELL_RESURRECTION,
  ],
  source: {
    id: SOURCE_DMG,
    page: 162,
  },
}
