const {DAMAGE_RADIANT} = require('./../../../damageTypeList')
const {GEAR_LASER_RIFLE} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  WEAPON_AMMUNITION_ENERGY_CELLS,
  WEAPON_RELOAD,
  WEAPON_RANGE,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  CAT_FIREARMS,
  CAT_MARTIAL_RANGE_WEAPON,
  CAT_TECH_FUTURISTIC,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_LASER_RIFLE,
  name: 'Лазерная винтовка',
  nameAlt: 'Лазган',
  nameEn: 'Laser Rifle',
  nameByCase: {
    nominative: 'лазерная винтовка',
    genitive: 'лазерной винтовки',
    accusative: 'лазерную винтовку',
    instrumental: 'лазерной винтовкой',
  },
  weight: 7,
  damageType: DAMAGE_RADIANT,
  damage: {
    diceCount: 3,
    diceType: 8,
  },
  genderId: GENDER_FEMALE,
  category: [
    CAT_FIREARMS,
    CAT_MARTIAL_RANGE_WEAPON,
    CAT_TECH_FUTURISTIC,
  ],
  weaponPropList: [
    WEAPON_AMMUNITION_ENERGY_CELLS,
    WEAPON_TWO_HANDED,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 100,
        max: 300,
      },
    },
    {
      id: WEAPON_RELOAD,
      value: 30,
    },
  ],
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
}
