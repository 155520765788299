const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_HIDE_ARMOR,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {CR_1_8} = require('./../../../../crList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {SPEED_WALK} = require('./../../../../speedList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {ABILITY_PACK_TACTICS} = require('./../../../../creatureAbilityList')
const {CREATURE_TRIBAL_WARRIOR} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Воитель племени',
  nameEn: 'Tribal Warrior',
  id: CREATURE_TRIBAL_WARRIOR,
  description: `Воители племени живут вне цивилизации, зачастую выживая за счёт рыбалки и охоты. Каждое племя беспрекословно подчиняется своему вождю, являющемуся самым старым воителем или представителем племени, благословенным богами.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 344,
  },
  armor: {
    ac: 12,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 12,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 8,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      gearId: GEAR_SPEAR,
    },
  ],
  genderId: GENDER_MALE,
}
