const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CONDITION_PRONE} = require('./../../../../../conditionList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_TIDAL_WAVE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TIDAL_WAVE,
  name: 'Приливная волна',
  nameEn: 'Tidal Wave',
  description: `Вы призываете волну, которая обрушивается на выбранную Вами область в пределах дистанции заклинания. Эта область может быть до 30 футов длиной, до 10 футов шириной и до 10 футов высотой. Все существа в этой области должны пройти испытание Ловкости. При провале существо получает дробящий урон 4к8 и сбивается с ног. При успехе оно получает половину урона и не сбивается с ног. После этого вода разливается во все стороны, гася любые незащищённые источники огня в области действия заклинания и в пределах 30 футов от неё, после чего исчезает.`,
  lvl: 3,
  magicSchoolId: MAGIC_CONJURATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `капля воды`,
  effect: {
    savethrowParam: PARAM_DEX,
    condition: CONDITION_PRONE,
    damage: {
      type: DAMAGE_BLUDGEONING,
      halfOnSuccess: true,
      diceCount: 4,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 164,
    },
    {
      id: SOURCE_EE,
      page: 21,
    },
  ],
}
