const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../damageTypeList')

module.exports = [
  {
    idPart: 'irian_rosewood' ,
    name: {
      nominative: 'айрианское розовое дерево',
      genitive: 'айрианского розового дерева',
    },
    nameEn: 'Irian Rosewood ',
    damageTypeIdList: DAMAGE_RADIANT,
  },
  {
    idPart: 'xorian_wenge',
    name: {
      nominative: 'зориатский венге',
      genitive: 'зориатского венге',
    },
    nameEn: 'Xorian Wenge',
    damageTypeIdList: DAMAGE_PSYCHIC,
  },
  {
    idPart: 'kythrian_manchineel',
    name: {
      nominative: 'китрийская манцинела',
      genitive: 'китрийской манцинелы',
    },
    nameEn: 'Kythrian Manchineel',
    damageTypeIdList: [
      DAMAGE_ACID,
      DAMAGE_POISON,
    ],
  },
  {
    idPart: 'lamannian_oak',
    name: {
      nominative: 'ламаннийский дуб',
      genitive: 'ламаннийского дуба',
    },
    nameEn: 'Lamannian Oak',
    damageTypeIdList: [
      DAMAGE_ELECTRICITY,
      DAMAGE_THUNDER,
    ],
  },
  {
    idPart: 'mabaran_ebony',
    name: {
      nominative: 'мабарский эбен',
      genitive: 'мабарского эбена',
    },
    nameEn: 'Mabaran Ebony',
    damageTypeIdList: DAMAGE_NECROTIC,
  },
  {
    idPart: 'risian_pine',
    name: {
      nominative: 'райсийская сосна',
      genitive: 'райсийской сосны',
    },
    nameEn: 'Risian Pine',
    damageTypeIdList: DAMAGE_COLD,
  },
  {
    idPart: 'fernian_ash',
    name: {
      nominative: 'фернийский ясень',
      genitive: 'фернийского ясеня',
    },
    nameEn: 'Fernian Ash',
    damageTypeIdList: DAMAGE_FIRE,
  },
  {
    idPart: 'shavarran_birch',
    name: {
      nominative: 'шаваразская берёза',
      genitive: 'шаваразской берёзы',
    },
    nameEn: 'Shavarran Birch',
    damageTypeIdList: DAMAGE_FORCE,
  },
]
