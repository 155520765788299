const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_GRUNG_POISON_SKIN,
  ABILITY_STANDING_LEAP,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_GRUNG,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  grungDescriptionList,
  grungNote,
} = require('./../../../../textCommonParts')
const {
  GEAR_DAGGER,
  GEAR_SHORTBOW,
} = require('./../../../../gearIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_GRUNG_ELITE_WARRIOR} = require('./../../../../creatureIdList')
const {LANG_GRUNG} = require('./../../../../languageIdList')
const {SIZE_SMALL} = require('./../../../../sizeList')

module.exports = {
  name: 'Элитный воин грунгов',
  nameEn: 'Grung Elite Warrior',
  id: CREATURE_GRUNG_ELITE_WARRIOR,
  description: grungDescriptionList,
  note: grungNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GRUNG,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 146,
    },
    {
      id: SOURCE_TOA,
      page: 221,
    },
  ],
  armor: 13,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 25,
    [SPEED_CLIMB]: 25,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_DEX,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  languageList: [
    LANG_GRUNG,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_GRUNG_POISON_SKIN,
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 25,
      jumpHeight: 15,
    },
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
      description: `Цель должна пройти испытание Телосложения СЛ 12 или получить 5 (2к4) урона ядом.`,
    },
    {
      gearId: GEAR_SHORTBOW,
      description: `Цель должна пройти испытание Телосложения СЛ 12 или получить 5 (2к4) урона ядом.`,
    },
    {
      name: 'Гипнотизирующий треск',
      restore: 5,
      description: `★СУЩЕСТВО★ издает стрекочущий шум, к которому грунги имеют иммунитет. Любой гуманоид или зверь не далее 15 футов от ★СУЩЕСТВО★ и способный слышать его, должен пройти испытание Мудрости СЛ 12 или стать ошеломлённым до конца следующего хода ★СУЩЕСТВО★.`,
    },
  ],
}
