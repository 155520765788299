const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_LYCANTHROPE,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_KEEN_SMELL} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NG} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_WEREBEAR} = require('./../../../../creatureIdList')
const {GEAR_GREATAXE} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {lycantropDescription} = require('./../../../../textCommonParts')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Вермедведь',
  nameEn: 'Werebear',
  description: [
    `Вермедведи — могучие ликантропы, наделённые способностью смирять свою звериную натуру и отвергать свои яростные порывы. В облике гуманоида они предстают большими, мускулистыми существами, покрытыми большим количеством волос, цвет которых совпадает с цветом меха их медвежьей формы. Эти оборотни — одиночки по натуре, опасающиеся того, что может произойти с невинными существами рядом, когда их звериная природа возьмёт верх.

Когда вермедведь превращается, он вырастает до неимоверных размеров, размахивая оружием или когтями. Он сражается с яростью медведя, но даже в своём зверином облике он старается никого не кусать, чтобы не передать своё проклятье. Обычно вермедведи передают свою ликантропию только избранным соратникам и ученикам, уделяя достаточное количество времени после этого тому, чтобы новообращённый ликантроп мог принять своё проклятье и научился его контролировать.`,
    ...lycantropDescription,
  ],
  id: CREATURE_WEREBEAR,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_LYCANTHROPE,
  ],
  alignmentId: ALIGNMENT_NG,
  source: {
    id: SOURCE_MM,
    page: 192,
  },
  armor: [
    {
      ac: 10,
      comment: 'в облике гуманоида',
    },
    {
      ac: 11,
      type: ARMOR_TYPE_NATURAL,
      comment: 'в облике медведя и гибридном облике',
    },
  ],
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: {
      value: 30,
      comment: '40 фт. в облике медведя',
    },
    [SPEED_CLIMB]: {
      value: 30,
      comment: 'в облике медведя и гибридном облике',
    },
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 17,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  languageList: [
    {
      id: LANG_COMMON,
      comment: 'не может говорить в облике медведя',
    },
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в гибрид медведя и гуманоида Большого размера или медведя Большого размера, или же принять свой истинный облик гуманоида. Все ★его★ статистики кроме размера и КД остаются одинаковыми во всех обликах. Всё несомое и носимое им снаряжение не превращается. ★Он★ принимает свой истинный облик, если умирает.`,
    },
    ABILITY_KEEN_SMELL,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `В облике медведя ★СУЩЕСТВО★ совершает две атаки _Когтями_. В облике гуманоида ★он★ совершает две атаки _Секирой_. В облике гибрида ★он★ может атаковать либо как медведь, либо как гуманоид.`,
    },
    {
      name: 'Укус',
      comment: 'только в облике медведя или гибрида',
      description: `Если цель — гуманоид, она должна пройти испытание Телосложения СЛ 14, иначе станет проклятой ликантропией вермедведя.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Коготь',
      comment: 'только в облике медведя или гибрида',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      gearId: GEAR_GREATAXE,
      comment: 'только в облике гуманоида или гибрида',
    },
  ],
  genderId: GENDER_MALE,
}
