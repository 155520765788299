const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_7,
  } = require('./../../../../crList'),
  {
    SIZE_HUGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_STONE,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_GIANT,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_ATHLETICS,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    giantStoneNote,
    stoneGiantDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_STONE_CAMOUFLAGE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_STONE_GIANT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Каменный великан',
  nameAlt: [
    'Каменный гигант',
    'Стейнъётун',
  ],
  nameEn: 'Stone Giant',
  id: CREATURE_STONE_GIANT,
  note: giantStoneNote,
  description: stoneGiantDescriptionList,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_STONE,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 38,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 20,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 9,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_7,
  featureList: [
    ABILITY_STONE_CAMOUFLAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Палицей_.`,
    },
    {
      name: 'Палица',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Камень',
      description: `Если цель — существо, она должны пройти испытание Телосложения СЛ 17, иначе будет сбита с ног.`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 9,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
  ],
  reactionList: [
    {
      name: 'Ловля камней',
      description: `Если в ★СУЩЕСТВО★ метнут камень или подобный предмет, ★СУЩЕСТВО★ может, если пройдёт испытание Ловкости СЛ 10, поймать снаряд и не получить от него дробящий урон.`,
    },
  ],
  genderId: GENDER_MALE,
}
