const {DAMAGE_NECROTIC} = require('./../../../../../damageTypeList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {SOURCE_PHB} = require('./../../../../../sourceList')
const {
  SPELL_HEX,
  SPELL_REMOVE_CURSE,
} = require('./../../../../../spellIdList')
const {
  TIME_BONUS_ACTION,
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  ATTACK_MELEE_SPELL,
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../../attackTypeList')

module.exports = {
  id: SPELL_HEX,
  name: 'Сглаз',
  nameEn: `Hex`,
  description: `Вы проклинаете существо, которое видите в пределах дистанции. Пока заклинание активно, Вы причиняете цели дополнительный урон некротической энергией 1к6 каждый раз, когда попадаете по ней атакой. Кроме того, выберите одну характеристику, когда накладываете заклинание. Цель совершает с помехой проверки выбранной характеристики.

Если хиты цели опускаются до 0, пока заклинание активно, Вы можете в свой следующий ход бонусным действием проклясть новое существо.

[Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}), наложенное на цель, оканчивает это заклинание преждевременно.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 или 4 уровня, Вы можете поддерживать концентрацию на нём до 8 часов. Если Вы используете ячейку заклинания 5 круга или выше, Вы можете поддерживать концентрацию на нём до 24 часов.`,
  lvl: 1,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `окаменевший глаз тритона`,
  duration: { timeId: TIME_HOUR, count: 1 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  needConcentration: true,
  effect: {
    type: [
      ATTACK_MELEE_SPELL,
      ATTACK_MELEE_WEAPON,
      ATTACK_RANGE_SPELL,
      ATTACK_RANGE_WEAPON,
    ],
    damage: {
      type: DAMAGE_NECROTIC,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_PHB,
    page: 276,
  },
}
