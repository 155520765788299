// Fork of remark-custom-blocks
// https://github.com/zestedesavoir/zmarkdown/blob/ec37155e746331c236e7d5af6a8c14619371a7cc/packages/remark-custom-blocks/src/index.js

function compilerFactory (nodeType) {
  let text
  let title

  return {
    blockHeading (node) {
      title = this.all(node).join('')
      return ''
    },
    blockBody (node) {
      text = this.all(node).map(s => s.replace(/\n/g, '\n| ')).join(' ')
      return text
    },
    block (node) {
      text = ''
      this.all(node)
      return `|${text}|${nodeType}:${title}|`
    },
  }
}

module.exports = function blockPlugin (availableBlocks = {}) {
  const pattern = Object
    .keys(availableBlocks)
    .join('|')

  if (!pattern) {
    throw new Error('remark-custom-blocks needs to be passed a configuration object as option')
  }

  const regex = new RegExp(`\\|(.*)\\|(${pattern}):(.*)\\|`)

  function blockTokenizer (eat, value, silent) {
    const now = eat.now()
    const keep = regex.exec(value)

    if (!keep) return

    const [eaten, blockTitle, blockType, contentString] = keep

    /* istanbul ignore if - never used (yet) */
    if (silent) return true

    if (!blockTitle || !blockType || !eaten) return

    const potentialBlock = availableBlocks[blockType]
    const titleAllowed = potentialBlock.title &&
      ['optional', 'required'].includes(potentialBlock.title)
    const titleRequired = potentialBlock.title && potentialBlock.title === 'required'

    if (titleRequired && !blockTitle) return
    if (!titleAllowed && blockTitle) return

    if (potentialBlock.details) {
      potentialBlock.containerElement = 'details'
      potentialBlock.titleElement = 'summary'
    }

    const contents = {
      type: `${blockType}CustomBlockBody`,
      data: {
        hName: potentialBlock.contentsElement ? potentialBlock.contentsElement : 'div',
        hProperties: {
          className: 'custom-block-body',
        },
      },
      children: [{
        type: 'text',
        value: contentString,
      }],
    }

    const textBefore = value.slice(0, keep.index)
    const textAfter = value.slice(keep.index + eaten.length)

    const blockChildren = [contents]

    if (titleAllowed && blockTitle) {
      const titleElement = potentialBlock.titleElement
        ? potentialBlock.titleElement
        : 'div'
      const titleNode = {
        type: `${blockType}CustomBlockHeading`,
        data: {
          hName: titleElement,
          hProperties: {
            className: 'custom-block-heading',
          },
        },
        children: [{
          type: 'text',
          value: blockTitle,
        }],
      }

      blockChildren.unshift(titleNode)
    }

    return eat(value)({
      type: 'text',
      children: [
        {
          type: 'text', // TODO: Возвращать тексты в рендер
          value: textBefore,
        },
        {
          type: `${blockType}CustomBlock`,
          children: blockChildren,
          data: {
            hName: potentialBlock.containerElement ? potentialBlock.containerElement : 'div',
            hProperties: {
              className: ['custom-block'],
            },
          },
        },
        {
          type: 'text', // TODO: Возвращать тексты в рендер
          value: textAfter,
        },
      ]
    })
  }

  const Parser = this.Parser

  // Inject blockTokenizer
  const blockTokenizers = Parser.prototype.blockTokenizers
  const blockMethods = Parser.prototype.blockMethods

  blockTokenizers.customBlocks = blockTokenizer
  blockMethods.splice(blockMethods.indexOf('fencedCode') + 1, 0, 'customBlocks')

  const Compiler = this.Compiler

  if (Compiler) {
    const visitors = Compiler.prototype.visitors

    if (!visitors) return

    Object.keys(availableBlocks).forEach(key => {
      const compiler = compilerFactory(key)

      visitors[`${key}CustomBlock`] = compiler.block
      visitors[`${key}CustomBlockHeading`] = compiler.blockHeading
      visitors[`${key}CustomBlockBody`] = compiler.blockBody
    })
  }

  // TODO: Это вообще нужно?
  // Inject into interrupt rules
  const interruptParagraph = Parser.prototype.interruptParagraph
  const interruptList = Parser.prototype.interruptList
  const interruptBlockquote = Parser.prototype.interruptBlockquote

  interruptParagraph.splice(interruptParagraph.indexOf('fencedCode') + 1, 0, ['customBlocks'])
  interruptList.splice(interruptList.indexOf('fencedCode') + 1, 0, ['customBlocks'])
  interruptBlockquote.splice(interruptBlockquote.indexOf('fencedCode') + 1, 0, ['customBlocks'])
}
