const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  PC_CLASS_WIZARD,
} = require('./../../../../pcClassIdList')
const {
  FEATURE_SPELLBOOK,
} = require('./../../../../featureIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_VERY_RARE,
} = require('./../../../../magicItemRarityList')
const {
  SPELL_DETECT_THOUGHTS,
  SPELL_INTELLECT_FORTRESS,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_MIND_SLIVER,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_SENDING,
  SPELL_TASHA_S_MIND_WHIP,
  SPELL_TELEKINESIS,
  SPELL_TENSER_S_FLOATING_DISK,
} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_CRYSTALLINE_CHRONICLE,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_CRYSTALLINE_CHRONICLE,
  name: `Кристаллическая хроника`,
  nameEn: `Crystalline Chronicle`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  attunementComment: `волшебником`,
  description: `Гравированный кристальный шар размером с грейпфрут слабо гудит и пульсирует неровным внутренним светом. 

Прикасаясь к _Кристаллической хронике_, Вы можете извлекать и сохранять информацию и заклинания внутри неё со скоростью чтения или письма.

Когда Вы находите _Хронику_, она содержит следующие заклинания:

* [Крепость интеллекта](SPELL:${SPELL_INTELLECT_FORTRESS})
* [Ментальная связь Рэри](SPELL:${SPELL_RARY_S_TELEPATHIC_BOND})
* [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS})
* [Послание](SPELL:${SPELL_SENDING})
* [Ташина психическая плеть](SPELL:${SPELL_TASHA_S_MIND_WHIP})
* [Телекинез](SPELL:${SPELL_TELEKINESIS})
* [Тензеров парящий диск](SPELL:${SPELL_TENSER_S_FLOATING_DISK})

Она действует для Вас как [книга заклинаний](FEATURE:${FEATURE_SPELLBOOK}), в которой физически закодированы заклинания и другие тексты.

Держа _Кристаллическую хронику_, Вы можете использовать её как заклинательную фокусировку для своих заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), и Вы знаете следующие заговоры (если Вы их ещё не знаете):

* [Волшебная рука](SPELL:${SPELL_MAGE_HAND})
* [Расщепление разума](SPELL:${SPELL_MIND_SLIVER})
* [Сообщение](SPELL:${SPELL_MESSAGE})

У _Кристаллической хроники_ 3 заряда, и она восстанавливает 1к3 израсходованных зарядов ежедневно на рассвете. Держа её, Вы можете использовать заряды следующими способами:

* Если Вы потратите 1 минуту на изучение информации внутри _Хроники_, Вы можете потратить 1 заряд, чтобы заменить одно из подготовленных Вами заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) другим заклинанием из книги.
* Сотворяя заклинание [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), Вы можете потратить 1 заряд на сотворение этого заклинания без словесных, соматических или материальных компонентов стоимостью не более 100 зм.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_TCoE,
    page: 179,
  },
}
