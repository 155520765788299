const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_DEEP_SPEECH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_CLOACKER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Плащевик',
  nameEn: 'Cloacker',
  id: CREATURE_CLOACKER,
  description: [
    {
      header: 'Плащевики',
      text: `Плащевики получили своё название за сходство с тёмными кожаными плащами. Скрываясь в дальних уголках пещер и подземелий, эти скрытные хищники поджидают одинокую или раненую добычу, неосторожно забредшую во тьму.`,
      source: {
        id: SOURCE_MM,
        page: 236,
      },
    },
    {
      header: 'Маскировка',
      text: `Тело плащевика, как и тело ската, состоит из хрящей и мышц. Когда его крылья раскрыты, он летит сквозь тьму и скрывается среди теней, как скат скользит в воде и прячется на дне океана. Параллельные ряды круглых чёрных глазных пятен расположены на его спине подобно пуговицам, а когти цвета слоновой кости на его капюшоне похожи на костяные застёжки.

Когда плащевик разворачивается и собирается напасть, он раскрывает своё уязвимое нутро, и его истинная природа становится очевидна. Красные глаза сверкают над рядами острых зубов, а позади вьётся длинный хлещущий хвост.`,
      source: {
        id: SOURCE_MM,
        page: 236,
      },
    },
    {
      header: 'Приспосабливающиеся хищники',
      text: `Охотясь, плащевики скользят в тенях на безопасном расстоянии от групп других существ передвигающихся по Подземью. Они следуют за группами гуманоидов, чтобы охотиться на раненых после боя или следуют по пятам за скоплениями тварей Подземья, нападая на слабых, больных и отставших.

Плащевики нападают быстро и поглощают свою добычу как можно скорее, окутывая и пожирая свои жертвы. Во время кормёжки, плащевик использует для обороны свой быстрый хлещущий хвост, хотя он редко противостоит опасным врагам или группам существ. В качестве дополнительной защиты плащевики могут создавать иллюзорных двойников самих себя.`,
      source: {
        id: SOURCE_MM,
        page: 236,
      },
    },
    {
      header: 'Жуткие стоны',
      text: `Мысли плащевиков чужды другим формам жизни, и они общаются друг с другом посредством инфразвуковых стонов, неразличимых для большинства существ. При высокой интенсивности стон становятся слышимым, вызывая ощущения страха и гибели у тех, кто его слышит.`,
      source: {
        id: SOURCE_MM,
        page: 236,
      },
    },
    {
      header: 'Конклавы плащевиков',
      text: `Плащевики предпочитают уединение, однако иногда они собираются для защиты или обмена информацией о новых опасностях, подходящих местах для охоты или событиях, которые могут повлиять на их среду обитания. Когда конклав закончен, плащевики снова разделяются.`,
      source: {
        id: SOURCE_MM,
        page: 236,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 236,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
    LANG_DEEP_SPEECH,
  ],
  cr: CR_8,
  featureList: [
    {
      name: `Передача урона`,
      description: `Пока ★СУЩЕСТВО★ ★прикреплён★ к существу, ★он★ получает только половину причиняемого ★ему★ урона (округляя в меньшую сторону), а оставшуюся половину получает существо.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `тёмного кожаного плаща`,
    },
    {
      name: `Чувствительность к свету`,
      description: `Находясь на ярком свету, ★СУЩЕСТВО★ совершает с помехой броски атаки и проверки Мудрости (Внимательность), полагающиеся на зрение.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Хвостом_.`,
    },
    {
      name: `Укус`,
      description: `Если ★СУЩЕСТВО★ совершал бросок атаки с преимуществом, он прикрепляется к голове цели, которая становится ослеплённой, и не может дышать, пока ★СУЩЕСТВО★ к ней прикреплён. Пока ★СУЩЕСТВО★ прикреплён, ★он★ может совершать эту атаку только по цели, и бросок атаки совершает с преимуществом. ★СУЩЕСТВО★ может отцепиться сам, потратив 5 футов перемещения. Любое существо, включая цель, может действием оторвать ★его★, преуспев в проверке Силы СЛ 16.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
          comment: `, и если размер цели не больше Большого, ★СУЩЕСТВО★ к ней прикрепляется`,
        },
      },
    },
    {
      name: `Хвост`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: `Стон`,
      description: `Все существа в пределах 60 футов от ★СУЩЕСТВО★, слышащие его стон, и не являющиеся аберрациями, должны пройти испытание Мудрости СЛ 13, иначе станут испуганными до конца следующего хода ★СУЩЕСТВО★. Если испытание существа было успешным, оно получает иммунитет к стону ★этого★ ★СУЩЕСТВО★ на следующие 24 часа.`,
    },
    {
      name: `Фантомы`,
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `★СУЩЕСТВО★ магическим образом создаёт три свои иллюзорные копии, если не находится на ярком свету. Копии перемещаются вместе с ★ним★ и подражают ★его★ действиям, постоянно перемешиваясь, чтобы невозможно было понять, кто из них ★настоящий★ ★СУЩЕСТВО★. Если ★СУЩЕСТВО★ окажется в области яркого света, копии исчезнут.

Каждый раз, когда какое-нибудь существо нацеливается на ★СУЩЕСТВО★ атакой или вредоносным заклинанием, пока есть копии, это существо случайным образом определяет, на ★СУЩЕСТВО★ или одну из копий оно нацелился. Существо не попадает под действие этого магического эффекта, если оно не видит или полагается на другие чувства, кроме зрения.

У копий КД и испытания как у ★самого★ ★СУЩЕСТВО★. Если атака попадает по копии, или копия проваливает испытание от эффекта, причиняющего урон, эта копия исчезает.`,
    },
  ],
  genderId: GENDER_MALE,
}
