const {ABILITY_TACTICAL_DISCIPLINE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CREATURE_FIST_OF_BANE} = require('./../../../../creatureIdList')
const {CR_1_2} = require('./../../../../crList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {baneFollowerList} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_LONGBOW,
  GEAR_MACE,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {CONDITION_FRIGHTENED} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Кулак Бэйна',
  nameAlt: 'Кулак Бейна',
  nameEn: 'Fist of Bane',
  id: CREATURE_FIST_OF_BANE,
  description: baneFollowerList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_BGDIA,
    page: 236,
  },
  armor: {
    ac: 18,
    gearId: [GEAR_CHAIN_MAIL, GEAR_SHIELD],
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 13,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  immunityConditionList: [
    CONDITION_FRIGHTENED,
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_TACTICAL_DISCIPLINE,
  ],
  actionList: [
    {
      gearId: GEAR_MACE,
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
}
