const {SOURCE_XGTE} = require('./../../../sourceList')
const {PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS} = require('./../../../pcSubClassIdList')
const {GEAR_SCIMITAR} = require('./../../../gearIdList')
const {fightingStyleFeatureTemplate} = require('./../../featureCommonRawList')
const {
  FIGHTING_STYLE_DUELING,
  FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
} = require('./../../../fightingStyleIdList')
const {
  FEATURE_BARDIC_INSPIRATION,
  FEATURE_BLADE_FLOURISH,
  FEATURE_BONUS_PROFICIENCIES_BARD_COLLEGE_OF_SWORDS,
  FEATURE_EXTRA_ATTACK_BARD_COLLEGE_OF_SWORDS,
  FEATURE_FIGHTING_STYLE_BARD_COLLEGE_OF_SWORDS,
  FEATURE_MASTER_S_FLOURISH,
} = require('./../../../featureIdList')

const {
  extraAttackTemplate,
} = require('./../../featureCommonRawList')

module.exports = [
  {
    id: FEATURE_FIGHTING_STYLE_BARD_COLLEGE_OF_SWORDS,
    ...fightingStyleFeatureTemplate,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS,
    text: `Вы выбираете стиль боя, соответствующий Вашей специализации.

Выберите один из следующих вариантов. Вы не можете выбрать _Боевой Стиль_ более одного раза, даже если что-либо в игре позволяет выбрать _Боевой Стиль_ снова.`,
    fightingStyleIdList: [
      FIGHTING_STYLE_DUELING,
      FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 12,
    },
  },
  {
    id: FEATURE_BONUS_PROFICIENCIES_BARD_COLLEGE_OF_SWORDS,
    name: `Дополнительные владения`,
    nameEn: `Bonus Proficiencies`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS,
    text: `Вы получаете владение средними доспехами и [скимитаром](GEAR:${GEAR_SCIMITAR}).

Если Вы владеете простым или воинским оружием ближнего боя, то можете использовать его в качестве фокусировки для Ваших заклинаний барда.`,
    source: {
      id: SOURCE_XGTE,
      page: 12,
    },
  },
  {
    id: FEATURE_BLADE_FLOURISH,
    name: `Цветение клинков`,
    nameEn: `Blade Flourish`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS,
    text: `Вы учитесь исполнять впечатляющие демонстрации боевого мастерства и проворства.

Когда Вы совершаете действие Атака в свой ход, Ваша скорость передвижения увеличивается на 10 футов до конца хода, а если атака оружием, которую Вы делаете в качестве части этого действия, попадает по существу, Вы можете использовать один из следующих вариантов _Цветения клинков_ по своему выбору. Вы можете использовать только один вариант _Цветения клинков_ за ход.

# Оборонительное цветение

Вы можете потратить одно своё [Вдохновение барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}), чтобы оружие нанесло дополнительный урон по цели. Урон равен числу, которое выпало при броске кости [Вдохновения Барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}). Вы также добавляете это число к своему КД до начала своего следующего хода.

# Режущее цветение

Вы можете потратить одно своё [Вдохновение барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}), чтобы оружие нанесло дополнительный урон по цели и по любому другому существу по Вашему выбору, видимому Вами в пределах 5 футов. Урон равен числу, которое выпало при броске кости [Вдохновения барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}).

# Мобильное цветение

Вы можете потратить одно своё [Вдохновение барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}), чтобы оружие нанесло дополнительный урон по цели. Урон равен числу, которое выпало при броске кости [Вдохновения барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}). Вы также можете оттолкнуть цель на 5 футов от себя, плюс на столько футов, сколько выпало на кости [Вдохновения барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}). После этого Вы незамедлительно можете использовать свою реакцию, чтобы передвинуться на расстояние не большее своей скорости, в незанятое место в 5 футах от цели.`,
    source: {
      id: SOURCE_XGTE,
      page: 12,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_BARD_COLLEGE_OF_SWORDS,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS,
    lvl: 6,
    source: {
      id: SOURCE_XGTE,
      page: 12,
    },
  },
  {
    id: FEATURE_MASTER_S_FLOURISH,
    name: `Мастерское цветение`,
    nameEn: `Master’s Flourish`,
    lvl: 14,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_SWORDS,
    text: `Когда Вы используете вариант [Цветения клинков](FEATURE:${FEATURE_BLADE_FLOURISH}), Вы можете сделать бросок к6 и использовать его вместо использования кости [Вдохновения барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}).`,
    source: {
      id: SOURCE_XGTE,
      page: 12,
    },
  },
]
