const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GOBLIN,
  CREATURE_GREEN_HAG,
  CREATURE_PIXIE,
  CREATURE_SPRITE,
} = require('./../../../../creatureIdList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_LONGSWORD,
  GEAR_SHORTBOW,
} = require('./../../../../gearIdList')
const {ALIGNMENT_NG} = require('./../../../../aligmentList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_TYPE_FEY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Спрайт`,
  nameEn: 'Sprite',
  id: CREATURE_SPRITE,
  description: [
    `В потаённых рощах и тенистых оврагах можно услышать стрёкот крылышек крошечных спрайтов. При всей их внешней красоте и блеске, спрайтам не хватает тепла и сострадания. Это агрессивные и суровые воители, способные пойти на жёсткие меры против незнакомцев, посягнувших на их дом. Чужаки, рискнувшие подойти к их владениям, чаще всего либо отпугиваются, либо усыпляются.`,
    {
      header: 'Защитники леса',
      text: `Спрайты строят свои небольшие деревни на ветвях деревьев и согласных трентов, а также на полянах, где полно зелени, цветов, мха и поганок. Дикие по натуре спрайты не любят чужаков. Когда чужаки попадаются им на глаза, спрайты предпочитают напугать гостей странными звуками и шевелением кустов. Если кто-то достаточно глуп, чтобы пройти дальше в их владения, ему будет грозить отравленная стрела и долгий сон. Пока гость отдыхает на травяной перине, спрайты перебираются в более безопасное место.`,
      source: {
        id: SOURCE_MM,
        page: 274,
      },
    },
    {
      header: 'Познающие сердца',
      text: `Спрайты могут почувствовать добро и зло в существе лишь по биению его сердца. Прислушавшись к сердцебиению, спрайты могут сказать, взволновано ли это существо от переполнивших его прекрасных чувств или же оно бежит от всепоглощающего ужаса. Они также могут сказать, скрывает ли существо внутри себя ненависть или жадность. Способность спрайтов читать чужие сердца непогрешима, потому что сердце не может врать.`,
      source: {
        id: SOURCE_MM,
        page: 274,
      },
    },
    {
      header: 'Изготовители ядов',
      text: `В своих лесных владениях спрайты изготавливают токсины, целебные мази, антидоты и яды, включая сонный яд, которым они смазывают свои стрелы. Они уходят далеко в лес, чтобы собрать редкие цветы, мох и грибы. Порой они заходят в опасные земли, чтобы добыть редкие ингредиенты. Находясь на пороге отчаянья, спрайты могут даже забраться на территорию, принадлежащую [каргам](CREATURE:${CREATURE_GREEN_HAG}), и украсть необходимое.`,
      source: {
        id: SOURCE_MM,
        page: 274,
      },
    },
    {
      header: 'Добродушие',
      text: `Из-за того, что спрайты могут читать чужие сердца и благосклонно относятся к добрым существам, они борются с силами злых фей и всячески срывают планы злых архифей. Если кто-то из путешественников появится в их лесу и расскажет, что пришёл бороться со злыми феями или [гоблинами](CREATURE:${CREATURE_GOBLIN}), спрайты сразу же окажут им поддержку и даже могут прийти на помощь, когда искатели приключений этого меньше всего ждут.

В отличие от [пикси](CREATURE:${CREATURE_PIXIE}), спрайты редко предаются легкомыслию и веселью. Они — истинные воители, защитники и судьи, из-за чего другие виды фей считают их чрезмерно суровыми и излишне серьёзными. Однако феи, уважающие территорию сородичей, могут найти в них надёжных союзников в тяжёлые времена.`,
      source: {
        id: SOURCE_MM,
        page: 274,
      },
    },
  ],
  note: {
    text: `В ветвях дерева можно было увидеть крошечную деревню, клянусь вам! Следующее, что я помню, это то, как я лежу лицом в грязи. Голова кружилась, а когда я поднялся и огляделся, на этом дереве уже не было деревни`,
    author: `рассказ полуорка-следопыта`,
  },
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_NG,
  source: {
    id: SOURCE_MM,
    page: 274,
  },
  armor: {
    ac: 15,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 3,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 10,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  languageList: [
    LANG_COMMON,
    LANG_ELVEN,
    LANG_SYLVAN,
  ],
  cr: CR_1_4,
  actionList: [
    {
      name: 'Длинный меч',
      // gearId: GEAR_LONGSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 0,
          diceType: 0,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Короткий лук',
      // gearId: GEAR_SHORTBOW, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      description: `Цель должна пройти испытание Телосложения СЛ 10, иначе станет отравленной на 1 минуту. Если результат испытания не превышает 5, отравленная цель теряет сознание на период отравления, или пока не получит урон, или пока другое существо не разбудит её действием.`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 6,
        range: {
          normal: 40,
          max: 160,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 0,
          diceType: 0,
          diceBonus: 1,
        },
      },
    },
    {
      name: `Рассматривание сердца`,
      description: `★СУЩЕСТВО★ касается существа и магическим образом узнаёт его текущее эмоциональное состояние. Если цель провалит испытание Харизмы СЛ 10, ★СУЩЕСТВО★ также узнаёт мировоззрение существа. Исчадия, небожители и нежить автоматически проваливают это испытание.`,
    },
    {
      name: `Невидимость`,
      description: `★СУЩЕСТВО★ магическим образом становится ★невидимым★, пока не атакует, не наложит заклинание, или пока не прервёт концентрацию (как при концентрации на заклинании). Всё снаряжение, которое ★СУЩЕСТВО★ несёт или носит, становится невидимым вместе с ★ним★.`,
    },
  ],
  genderId: GENDER_MALE,
}
