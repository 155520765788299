const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_BAT,
  CREATURE_DARKMANTLE,
} = require('./../../../../creatureIdList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Тёмная мантия`,
  nameEn: 'Darkmantle',
  id: CREATURE_DARKMANTLE,
  description: `Тёмная мантия цепляется к потолкам пещер, оставаясь совершенно неподвижной, пока под ней не пройдёт жертва. Издалека она может походить на сталактит или кусок камня. Потом она падает на добычу, раскрываясь и окружая себя магической тьмой, чтобы заглотить и смять жертву.

Тёмные мантии встречаются по всему Подземью, но в Царстве Теней они обитают не менее часто. Процветая в царстве мрака, они заняли экологическую нишу [летучих мышей](CREATURE:${CREATURE_BAT}) Материального плана. Разумные существа Царства Теней иногда воспитывают тёмных мантий как компаньонов или стражей.`,
  note: {
    text: `Напомните мне, зачем мы вообще взялись за это задание?`,
    author: `Воин Этельред, после первого столкновения с тёмными мантиями`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 281,
  },
  armor: 11,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: `Эхолокация`,
      description: `★СУЩЕСТВО★ не может использовать слепое зрение, будучи ★оглохшим★.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `пещерного образования, такого как сталактит или сталагмит`,
    },
  ],
  actionList: [
    {
      name: `Сдавливание`,
      description: `Если размер цели не больше Среднего, и ★СУЩЕСТВО★ ★совершал★ бросок атаки с преимуществом, ★он★ охватывает голову цели, и цель становится дополнительно ослеплённой и не может дышать, пока ★СУЩЕСТВО★ охватывает её.

Охватывая цель, ★СУЩЕСТВО★ может совершать атаки только по ней, но зато броски атаки совершает с преимуществом. Скорость тёмной мантии становится равной 0, она не получает бонусов к скорости, и перемещается вместе с целью.

Любое существо может оторвать ★СУЩЕСТВО★, совершив действием успешную проверку Силы СЛ 13. В свой ход ★СУЩЕСТВО★ может ★сам★ оторваться от цели, использовав 5 футов перемещения.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
          comment: `, и ★СУЩЕСТВО★ охватывает цель`,
        },
      },
    },
    {
      name: `Аура тьмы`,
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ испускает магическая тьма с радиусом 15 футов, перемещающаяся вместе с ★ним★, и огибающая углы. Тьма существует, пока ★СУЩЕСТВО★ поддерживает концентрацию, вплоть до 10 минут (как при концентрации на заклинании). Тёмное зрение не проникает через эту тьму, и никакой естественный свет не может её разогнать. Если часть этой тьмы пересечётся со светом, созданным заклинанием с уровнем не больше 2, заклинание, создавшее свет, рассеивается.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
