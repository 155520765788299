const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BEAST,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_COMMON,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_KEEN_SIGHT_AND_SMELL,
    ABILITY_PACK_TACTICS,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_GIANT_VULTURE,
    CREATURE_VULTURE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гигантский гриф',
  nameEn: 'Giant Vulture',
  id: CREATURE_GIANT_VULTURE,
  sizeType: SIZE_LARGE,
  description: `У гигантского грифа сильный интеллект и злобный характер. В отличие от [младших родственников](CREATURE:${CREATURE_VULTURE}), он может напасть на раненое существо, чтобы приблизить его конец. Гигантские грифы известны тем, что специально выискивают существ, страдающих от жажды или голода, и радуются их страданиям.`,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 325,
  },
  armor: 10,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 15,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_COMMON,
      doNotSpeak: true,
    },
  ],
  cr: CR_1,
  featureList: [
    ABILITY_KEEN_SIGHT_AND_SMELL,
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Клювом_, и одну _Когтями_.`,
    },
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
}
