const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_FLIND,
  CREATURE_GNOLL,
  CREATURE_YEENOGHU,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_FLAIL,
  GEAR_LONGBOW,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_GNOLL,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  LANG_ABYSSAL,
  LANG_GNOLL,
} = require('./../../../../languageIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_9} = require('./../../../../crList')
const {gnollDescriptionList} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Флинд',
  nameEn: 'Flind',
  id: CREATURE_FLIND,
  description: [
    `Флинд является исключительно сильным и злобным [гноллом](CREATURE:${CREATURE_GNOLL}), который командует и направляет банду, частью которой является. Он вооружен [цепом](GEAR:${GEAR_FLAIL}), наполненным мощной магией самого [Йеногу](CREATURE:${CREATURE_YEENOGHU}).

В банде может быть только один флинд и он определяет путь банды. Благодаря своей особой связи с [Йеногу](CREATURE:${CREATURE_YEENOGHU}) флинд использует божественные предзнаменования и демоническое провидение для того чтобы направить гноллов к слабой добыче и учинить резню.

В отличие от других гуманоидных лидеров, которые могут бездельничать за спинами своих приспешников, флинд возглавляет атаку. Его [цеп](GEAR:${GEAR_FLAIL}) причиняет ужасную боль, парализует и дезориентирует противника.`,
    ...gnollDescriptionList,
  ],
  note: {
    text: `Когда гноллы слабеют, они ищут изолированные поселения, калечат и пленят его обитателей, и пожирают их пока, не отдохнут и не наберутся сил.`,
    author: `Эльминстер`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GNOLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 143,
  },
  armor: {
    ac: 16,
    gearId: GEAR_CHAIN_MAIL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 19,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GNOLL,
    LANG_ABYSSAL,
  ],
  cr: CR_9,
  featureList: [
    {
      name: 'Аура жажды крови',
      description: `Пока флинд дееспособен, любое существо с особенностью _Буйство_ может совершать атаку _Укусом_ бонусным действием, если находится не далее 10 футов от флинда.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Флинд делает три атаки: по одному разу каждую из своих атак _Цепом_ или три атаки _Длинным луком_.`,
    },
    {
      name: 'Цеп безумия',
      description: `цель должна пройти испытание Мудрости СЛ 16. При провале цель должна в свой следующий ход совершить рукопашную атаку по случайной цели в пределах досягаемости. Если в зоне досягаемости нет целей даже после передвижения, то существо теряет своё действие в этот ход.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Цеп боли',
      description: ``,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 10,
            diceBonus: 5,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 4,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Цеп паралича',
      description: `Цель должна пройти испытание Телосложения СЛ 16 или стать парализованной до конца своего следующего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
}
