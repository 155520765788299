const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_13,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_TRUE_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_NALFESHNEE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Нальфешни',
  nameEn: 'Nalfeshnee',
  id: CREATURE_NALFESHNEE,
  description: [
    `Нальфешни является одним из самых гротескных демонов — это тучная пародия на обезьяну и кабана, в два раза выше человеческого роста, с перистыми крыльями, которые кажутся слишком маленькими для его раздутого тела. Эти звериные черты скрывают выдающийся интеллект и хитрость.

Нальфешни разрушительны в бою, они с помощью крыльев летят над первыми рядами своего войска и ищут уязвимых противников, которые могут быть уничтожены с небольшим усилием. Из гущи боя, они телепатическим рёвом командуют меньшими демонами, вместе с тем заставляя врагов бежать и рассеиваться.

Нальфешни могут питаться ненавистью и отчаянием, но они предпочитают плоть гуманоидов. Они держат свои кладовые заполненными гуманоидами, похищенными из Материального плана, чтобы пировать ещё живыми существами. Думая о себе как о развитых и культурных, нальфешни используют запятнанные ржавчиной столовые приборы во время своих трапез.`,
    ...demonDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 93,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 22,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_13,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ использует _Ореол ужаса_, если может. Затем ★он★ совершает три атаки: одну _Укусом_, и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 5,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Ореол ужаса',
      description: '★СУЩЕСТВО★ магическим образом излучает разноцветную ауру. Все существа в пределах 15 футов от ★него★, видящие этот свет, должны пройти испытание Мудрости СЛ 15, иначе станут испуганными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. Если испытание существа было успешным, или эффект на нём окончился, оно получает иммунитет к _Ореолу ужаса_ ★этого★ ★СУЩЕСТВО★ на следующие 24 часа.',
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  genderId: GENDER_MALE,
}
