const upLetter = require('./../../../../../../utils/upLetter')
const {damageTypeCollection} = require('./../../../../../damageTypeList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {gearCollection} = require('./../../../../../gearList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../../../genderList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    damageTypeId,
    gearId,
    id,
  }
) => {
  const {
    fullNameByCase: {dative: dmgFullName},
    nameByCase: {dative: dmgName},
    nameEn: dmgNameEn,
  } = damageTypeCollection[damageTypeId]
  const {
    nameByCase: {
      nominative: gearNameNominative,
      accusative: gearNameAccusative,
    },
    nameEn: gearNameEn,
    genderId,
  } = gearCollection[gearId]

  const pronounText = {
    [GENDER_MALE]: 'этот',
    [GENDER_FEMALE]: 'эту',
    [GENDER_MIDDLE]: 'это',
    [GENDER_MULTIPLE]: 'эти',
  }[genderId]

  return {
    id,
    name: `${gearNameNominative} сопротивления ${dmgName}`,
    nameEn: `${gearNameEn} of ${upLetter(dmgNameEn)} Resistance`,
    type: MGC_TYPE_ARMOR,
    gearType: gearId,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Вы получаете сопротивление к **${dmgFullName}**, пока носите ${pronounText} *${gearNameAccusative}*.`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 160,
    },
  }
}
