const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_STEGOSAURUS} = require('./../../../../creatureIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {dinoDescriptionList} = require('./../../../../textCommonParts')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Стегозавр',
  nameEn: 'Stegosaurus',
  id: CREATURE_STEGOSAURUS,
  description: [
    `Этот крепко сложенный динозавр имеет ряды пластин на спине и гибкий шипованый хвост, который он держит наготове, чтобы бить хищников. Они обычно путешествуют стадами с членами всех возрастов.`,
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 151,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 17,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 5,
  },
  cr: CR_4,
  actionList: [
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 6,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
  ],
}
