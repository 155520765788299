const {
  CREATURE_DEATH_KNIGHT,
  CREATURE_LICH,
  CREATURE_SOLAR,
} = require('./../../../../../creatureIdList')
const {GEAR_BOOK} = require('./../../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_BOOK_OF_VILE_DARKNESS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_ARTIFACT} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_DOMINATE_MONSTER} = require('./../../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_BOOK_OF_VILE_DARKNESS,
  name: 'Книга Мерзкой Тьмы',
  nameEn: 'Book of Vile Darkness',
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_BOOK,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  description: [
    `Содержимое этого отвратительного манускрипта — лакомый кусочек для тех, кто служит злу. Смертные не должны знать хранящиеся в нём тайны, они настолько ужасны, что один только взгляд на его страницы приводит к безумию.

Большинство считает, что авторство _Книги Мерзкой Тьмы_ принадлежит богу-личу Векне. На её страницах он записал все свои больные идеи, все безумные мысли и все примеры самой чёрной магии, известные ему. Векна раскрыл все мерзкие темы, сделав книгу жутким каталогом всех смертных грехов.

Книга была в руках и других злодеев, и многие дополняли её своими мыслями. Эти дополнения сразу видны, так как они либо вклеивали свои страницы, либо делали пометки на полях и между строк. Есть также места, где страницы вырваны или полностью залиты чернилами или кровью, так что оригинальный текст уже не разобрать.

Присутствие книги уничтожает природу. Обычные растения вянут при ней, животные отказываются к ней приближаться, книга постепенно уничтожает всё, к чему прикасается. Даже камень трескается и становится прахом, если книга долго лежит на нём.

Существо, настроенное на эту книгу, должно потратить 80 часов на чтение и изучение её содержимого, чтобы получить преимущества от неё. После этого существо может изменять содержимое книги, при условии, что эти модификации увеличивают или распространяют зло.

Если на _Книгу Мерзкой Тьмы_ настраивается не злое существо, оно проходит испытание Харизмы СЛ 17. При провале его мировоззрение становится нейтрально-злым.

_Книга Мерзкой Тьмы_ остаётся с Вами только пока Вы сеете зло в мире. Если Вы за 10 дней не совершили ни одного злого поступка, или добровольно совершаете добрый поступок, книга исчезает. Если Вы умираете, будучи настроенным на эту книгу, Вашу душу забирает сущность великого зла. Вы не можете вернуться к жизни никакими средствами, пока Ваша душа похищена.`,
    {
      header: 'Случайные свойства',
      text: `_Книга Мерзкой Тьмы_ обладает несколькими свойствами, определяемыми случайным образом:

* 3 малых положительных свойства
* 1 основное положительное свойство
* 3 малых отрицательных свойства
* 2 основных отрицательных свойства`,
    },
    {
      header: 'Изменение характеристик',
      text: `После того как Вы потратите нужное время на чтение и изучение книги, одна Ваша характеристика на Ваш выбор увеличивается на 2, с максимумом 24. Другая Ваша характеристика на Ваш выбор уменьшается на 2, с минимумом 3. Книга не может позже изменить Ваши характеристики повторно.`,
    },
    {
      header: 'Метка тьмы',
      text: `После того как Вы потратите нужное время на чтение и изучение книги, Вы получаете физическое уродство, отражающее Вашу преданность мерзкой тьме. На Вашем лице может появиться руна. Ваши глаза могут стать абсолютно чёрными, или же на лбу могут вырасти рога. Вы можете покрыться морщинами или язвами, можете потерять часть лица, можете получить раздвоенный язык или другую особенность, выбранную Мастером. Метка тьмы позволяет Вам совершать с преимуществом проверки Харизмы (Убеждение) при взаимодействии со злыми существами, а также проверки Харизмы (Запугивание) при взаимодействии с незлыми персонажами.`,
    },
    {
      header: 'Командование злом',
      text: `Если Вы настроены на книгу и держите её в руках, Вы можете действием наложить на злую цель заклинание [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}) (СЛ ипытания 18). Вы не сможете использовать это свойство повторно до следующего рассвета.`,
    },
    {
      header: 'Тёмные знания',
      text: `Вы можете обращаться к _Книге Мерзкой Тьмы_, если совершаете проверку Интеллекта для вспоминания информации о чём-то злом, например, о демонах. Если Вы это делаете, то Ваш бонус мастерства для этой проверки удваивается.`,
    },
    {
      header: 'Тёмная речь',
      text: `Если Вы несёте _Книгу Мерзкой Тьмы_ и настроены на неё, Вы можете действием прочесть вслух текст с её страниц на нечистом языке, известном как Тёмная речь. Каждый раз, когда Вы это делаете, Вы получаете урон психической энергией 1к12, а все незлые существа в пределах 15 футов от Вас получают урон психической энергией 3к6.`,
    },
    {
      header: 'Уничтожение книги',
      text: `Из _Книги Мерзкой Тьмы_ можно вырывать страницы, но зло, содержащееся на них, в конечном счете, возвращается в неё, обычно когда новый автор добавляет туда свои знания.

Если [солар](CREATURE:${CREATURE_SOLAR}) порвёт книгу на две части, она будет уничтожена на 1к100 лет, после чего формируется заново в тёмной части мультивселенной.

Существо, настроенное на книгу в течение ста лет, может найти фразу, скрытую среди оригинального текста, которая, если её перевести на Небесный и произнести вслух, уничтожит и книгу и того, кто её произнесёт, в яркой вспышке света. Однако если в мультивселенной остаётся зло, книга формируется заново через 1к10 × 100 лет. Если всё зло в мультивселенной будет уничтожено, книга будет уничтожена навсегда.`,
    },
    {
      header: 'Мерзкие знания',
      text: `_Книга Мерзкой Тьмы_ описывает всё зло, существующее во Вселенной. Персонаж может найти в ней такие ужасные тайны, что их не должен знать ни один смертный. Среди прочих тем встречаются следующие:

## Апофеоз греховности

Книга может содержать ритуал, позволяющий персонажу стать [личом](CREATURE:${CREATURE_LICH}) или [рыцарем смерти](CREATURE:${CREATURE_DEATH_KNIGHT}).

## Истинные имена

В книге могут быть истинные имена многочисленных исчадий.

## Тёмная магия

В книге могут быть ужасные и злые заклинания, созданные или отобранные Мастером. В заклинаниях могут быть проклятья, которые калечат, насылают боль, распространяют болезни или требуют жертвоприношений.`,
      source: {
        id: SOURCE_DMG,
        page: 224,
      },
    },
  ],
  genderId: GENDER_FEMALE,
  spellIdList: [SPELL_DOMINATE_MONSTER],
  source: {
    id: SOURCE_DMG,
    page: 223,
  },
}
