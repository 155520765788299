module.exports = [
  {
    text: `Вне сражений великаны обычно медлительны. «Скоро» для великана может растянуться на три-четыре человеческих года`,
    author: `Эльминстер`,
  },
  {
    text: `Если я что-то и узнал за свою жизнь, то это то, что любая возвысившаяся империя рухнет. Они все рушатся.`,
    author: `Воло`,
  },
]
