const {
  SPELL_BLESS,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CURE_WOUNDS,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_DREAM,
  SPELL_GREATER_RESTORATION,
  SPELL_LESSER_RESTORATION,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_SANCTUARY,
  SPELL_SCRYING,
  SPELL_SHIELD,
} = require('./../../../../spellIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_CHANGE_SHAPE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LG} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_VERBAL} = require('./../../../../castComponentList')
const {CR_4} = require('./../../../../crList')
const {CREATURE_COUATL} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CELESTIAL} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Коатль',
  nameEn: 'Couatl',
  id: CREATURE_COUATL,
  description: [
    {
      header: 'Коатли',
      text: `Коатль — доброжелательное змееподобное существо большого интеллекта и проницательности. Их ярко раскрашенные крылья и вежливые манеры говорят о небесном происхождении.`,
      source: {
        id: SOURCE_MM,
        page: 176,
      },
    },
    {
      header: 'Божественные посланники',
      text: `Коатли были созданы как стражи и посланники добрыми божествами древности, которым уже давно не поклоняются, и которые уже давно забыты всеми, кроме самих коатлей. Большинство божественных поручений, выданных коатлям, уже давно либо выполнены, либо провалены. Тем не менее, некоторые коатли по-прежнему наблюдают за древними силами, ожидают исполнения пророчеств, либо охраняют наследников существ, которых они когда-то охраняли и направляли. Вне зависимости от задания, коатли предпочитают не показываться, и раскрывают себя только если нет другого выхода.`,
      source: {
        id: SOURCE_MM,
        page: 176,
      },
    },
    {
      header: 'Правдолюб',
      text: `Коатль не может лгать, но может умалчивать информацию, отвечать туманно или позволять собеседнику прийти к неверным выводам, если это необходимо, чтоб защитить что-то, сдержать обещание или скрыть тайну своего существования.`,
      source: {
        id: SOURCE_MM,
        page: 176,
      },
    },
    {
      header: 'Древние и немногочисленные',
      text: `Коатли могут жить очень долго, обходясь без пищи, и даже без воздуха, но они могут умереть от болезни или старости. Коатль может предчувствовать свою смерть более чем за сто лет, но не может предвидеть, каким именно образом он умрёт.

Если коатль уже достиг поставленной перед ним задачи, он принимает свою судьбу. Но если неминуемая смерть ставит под угрозу достижение целей, он начинает активно искать другого коатля, чтобы произвести потомство.

Брачные ритуалы коатлей представляют собой прекрасные и замысловатые танцы магии и света, в результате которых появляется яйцо, похожее на драгоценный камень, из которого потом вылупляется новый коатль. Родитель, который искал пару, растит новорождённого коатля и рассказывает ему его обязанности, чтобы тот мог завершить задачу, которую его родитель оставит незаконченной.`,
      source: {
        id: SOURCE_MM,
        page: 176,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_LG,
  source: {
    id: SOURCE_MM,
    page: 176,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 90,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 17,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 20,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_RADIANT,
  ],
  immunityList: [
    DAMAGE_PSYCHIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Защищённое сознание',
      description: `★СУЩЕСТВО★ обладает иммунитетом к удалённому наблюдению, а также ко всем эффектам, чувствующим ★его★ эмоции, читающим ★его★ мысли и обнаруживающим ★его★ местонахождение.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentOnly: CAST_VERBAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_EVIL_AND_GOOD,
          SPELL_DETECT_MAGIC,
          SPELL_DETECT_THOUGHTS,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_BLESS,
          SPELL_CURE_WOUNDS,
          SPELL_PROTECTION_FROM_POISON,
          SPELL_LESSER_RESTORATION,
          SPELL_CREATE_FOOD_AND_WATER,
          SPELL_SANCTUARY,
          SPELL_SHIELD,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DREAM,
          SPELL_GREATER_RESTORATION,
          SPELL_SCRYING,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Телосложения СЛ 13, иначе станет отравленной на 24 часа. Пока цель отравлена, она лишена сознания. Другое существо может действием привести цель в чувство.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Сжимание',
      description: `Цель становится схваченной (СЛ высвобождения равна 15). Пока цель схвачена, она обездвижена, а коатль не может сжимать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            size: {
              max: SIZE_MEDIUM,
            },
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      id: ABILITY_CHANGE_SHAPE,
      nextForm: `гуманоида или зверя, чей показатель опасности не превышает его собственный`,
      gearMerge: `сливается с новым обликом или используется им (на ★его★ выбор)`,
      statChange: `В новом облике ★СУЩЕСТВО★ сохраняет свою игровую статистику и способность говорить, но КД, режимы перемещения, Сила, Ловкость и остальные действия заменяются теми, что есть у нового облика, и ★он★ получает все элементы статистики и умения (кроме классовых умений, легендарных действий и действий логова), которые есть у нового облика, но отсутствуют у ★него★. Если у нового облика есть атака _Укусом_, ★СУЩЕСТВО★ может использовать в этом облике свой _Укус_`,
    },
  ],
  genderId: GENDER_MALE,
}
