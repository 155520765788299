const {SOURCE_VGTM} = require('./../../sourceList')
const {LANG_COMMON} = require('./../../languageIdList')
const {
  CREATURE_DARKLING,
  CREATURE_DARKLING_ELDER,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Дарклинги',
    text: `Древние легенды рассказывают о фее Благого Двора, который предал Королеву Лета. Его настоящее имя было вычеркнуто из истории, но в рассказах его называют Даб Катха («Тёмный Ворон» на [всеобщем](LANG:${LANG_COMMON})). Гнев Королевы Лета был так велик, что она прокляла каждого члена его дома. Другие феи называют потомков дома Даб Катха — _даб ситх_, или на [всеобщем](LANG:${LANG_COMMON}), «дарклинги». [Дарклинги](CREATURE:${CREATURE_DARKLING}) очень часто селятся в изолированных пещерах и покоях под городами других существ. Из таких анклавов они тихо предлагают свои услуги в качестве воров и убийц.`,
    source: {
      id: SOURCE_VGTM,
      page: 147,
    },
  },
  {
    header: 'Убивающий свет',
    text: `Проклятие Королевы Лета заставляет тело дарклинга впитывать свет и при этом существо высыхает, что похоже на эффект быстрого старения. Поэтому дарклинги покрывают одеждой каждую часть своего тела, так как подверженность свету — большой риск. Свет, поглощённый дарклингом на протяжении жизни, вырывается наружу после его смерти, поджигая существо и большинство его вещей.`,
    source: {
      id: SOURCE_VGTM,
      page: 147,
    },
  },
  {
    header: 'Любовь к искусству',
    text: `Несмотря на проклятие, дарклинги сохранили любовь к красоте искусства. Дарклинг может рискнуть взглянуть на рассвет или зажечь крошечную свечу, чтобы увидеть цвета картины или блеск ювелирного украшения.`,
    source: {
      id: SOURCE_VGTM,
      page: 147,
    },
  },
  {
    header: 'Превращение в старейшину',
    text: `Мудрый и уважаемый дарклинг может отобраться и пройти ритуал, чтобы стать [старейшиной](CREATURE:${CREATURE_DARKLING_ELDER}). Другие старейшины отмечают просителя мерцающими татуировками, чтобы вывести поглощенный дарклингом свет из его тела. Если ритуал успешен, то дарклинг принимает высокую и красивую форму, похожую на серокожего эльфа. Дарклинг гибнет при провале ритуала.`,
    source: {
      id: SOURCE_VGTM,
      page: 147,
    },
  },
]
