const {
  SOURCE_BGDIA,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CELESTIAL,
} = require('./../../../../creatureTypeIdList')
const {
  CAST_NONE,
} = require('./../../../../castComponentList')
const {
  ALIGNMENT_LG,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPELL_BANISHMENT,
  SPELL_BLESS,
  SPELL_CURE_WOUNDS,
  SPELL_HEAL,
  SPELL_LIGHT,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_RAISE_DEAD,
  SPELL_SHAPECHANGE,
  SPELL_TELEPORT,
} = require('./../../../../spellIdList')
const {
  DAMAGE_NONMAGIC,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_CELESTIAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_HOLLYPHANT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Оллифант',
  nameAlt: 'Крылослон',
  nameEn: 'Hollyphant',
  id: CREATURE_HOLLYPHANT,
  description: [
    {
      header: 'Оллифант',
      text: `Эти милые и верные существа родом с Верхних планов. Добрые божества и ангелы используют их как гонцов и помощников. Оллифанты ценят дружбу и честность.
  
Оллифант похож на маленького слоника с блестящей золотистой шерстью и небольшими, быстро порхающими крылышками, которые не просто поддерживают его в воздухе, но и разгоняют до большой скорости. Оллифанты добродушны, но не выносят вида злых дел и стараются покарать злодея. Их жемчужные бивни нее так уж грозны, но они способны издавать хоботом трубный рёв, оглушающий существ или окутывающий злодеев сверкающими искрами позитивной энергии. Оллифанты также наделены сильной врождённой магией, помогающей бороться со злом и защищать друзей.`,
      source: {
        id: SOURCE_BGDIA,
        page: 237,
      },
    },
  ],
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_LG,
  source: {
    id: SOURCE_BGDIA,
    page: 237,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 120,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 21,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 19,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  languageList: [
    LANG_CELESTIAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Аура неуязвимости',
      description: `Невидимая аура образует сферу радиусом 1о футов вокруг ★СУЩЕСТВО★, пока ★он★ ★жив★. Любое заклинание 5-го круга или ниже, сотворённое за пределами барьера, не влияет на существ и объекты в пределах барьера, даже если оно сотворено с использованием ячейки более высокого круга. Такое заклинание может быть нацелено на существ и объекты под защитой барьера, но не подействует на них. Аналогично область в пределах барьера исключена из областей, на которые воздействуют заклинания извне. ★СУЩЕСТВО★ ★может★ действием подавить эту способность, поддерживая на этом концентрацию (как если бы ★концентрировался★ на заклинании). `,
    },
  ],
  spellCast: {
    baseStat: PARAM_WIT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_LIGHT,
        ],
      },
      {
        limit: {
          count: 2,
          period: 'день',
        },
        list: [
          SPELL_BLESS,
          SPELL_CURE_WOUNDS,
          SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_BANISHMENT,
          SPELL_HEAL,
          SPELL_RAISE_DEAD,
          {
            id: SPELL_SHAPECHANGE,
            comment: 'в золотистого мамонта с пернатыми крыльями и скоростью полёта 120 футов',
          },
          {
            id: SPELL_TELEPORT,
            comment: 'без шанса ошибки',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Бивни',
      description: `★СУЩЕСТВО★`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
        },
      },
    },
    {
      name: 'Труба',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ ★трубит★ в хобот, этот звук слышно в пределах 600 футов. Трубный звук создаёт 30-футовый конус энергии с одним эффектов по выбору ★СУЩЕСТВО★:

1. **Звучная труба.** Каждое существо в конусе должно пройти испытание Телосложения СЛ 14, при провале получая 17 (5к6) урона звуком и глохнет на 1 минуту. При успехе существо получает половину этого урона и не глохнет. Немагические предметы в конусе, которые никто не несёт и не носит, получают 35 (10к6) урона звуком.
2. **Искристая труба.** Все существа в конусе должны пройти испытание Телосложения СЛ 14. При провале существо получает 22 (4к8 + 4) урона излучением, при успехе — половину этого урона. Злые существа проходят испытание с помехой. Добрые существа в конусе не получают урона.`,
    },
  ],
}
