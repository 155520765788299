import React from 'react'

import AdsFooter from '@/ads/components/AdsFooter'
import GoogleAnalytics from '@/components/GoogleAnalytics'
import YandexMetrika from '@/components/YandexMetrika'

import './footer.css'

const Footer = () => (
  <footer className="footer">
    <GoogleAnalytics/>
    <YandexMetrika/>
    <AdsFooter/>
  </footer>
)

export default Footer
