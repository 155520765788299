const {
  CREATURE_TYPE_KRUTHIK,
  CREATURE_TYPE_MONSTROSITY,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_CLIMB,
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  kruthikDescription,
  kruthikNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_KEEN_SMELL,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_KRUTHIK_ADULT,
  CREATURE_KRUTHIK_HIVE_LORD,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_5} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {LANG_KRUTHIK} = require('./../../../../languageIdList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Крутик лорд улья',
  nameEn: 'Kruthik Hive Lord',
  id: CREATURE_KRUTHIK_HIVE_LORD,
  description: [
    `Лорд улья управляет каждым крутиком. Когда он погибает, выжившие члены улья покидают свою обитель в поисках нового лорда. Когда находится подходящее место, самый большой крутик в логове претерпевает метаморфозу, образуя вокруг себя кокон, появляясь через несколько дней уже лордом улья… более крупным и разумным крутиком, способным плевать желудочной кислотой из пасти. Лорд улья занимает самую большую пещеру в улье и держит нескольких [взрослых крутиков](CREATURE:${CREATURE_KRUTHIK_ADULT}) поблизости в качестве телохранителей.`,
    kruthikDescription,
  ],
  note: kruthikNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_KRUTHIK,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MTOF,
    page: 204,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_DIG]: 20,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 17,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 10,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  languageList: [
    LANG_KRUTHIK,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_KEEN_SMELL,
    ABILITY_PACK_TACTICS,
    {
      name: 'Прокладывание туннеля',
      description: `Крутик может копать сквозь сплошной камень со скоростью копания, уменьшенной вдвое, оставляя за собой туннель диаметром 10 футов.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Крутик совершает два атаки _Уколом_ или две атаки _Шипами_.`,
    },
    {
      name: 'Укол',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Шипы',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 6,
        range: {
          normal: 30,
          max: 120,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Кислотная струя',
      restore: 5,
      description: `Крутик выплёвывает кислоту 15-футовым конусом. Каждое существо в этой области должно пройти испытание Ловкости СЛ 14, получая 22 (4к10) урона кислотой при провале, или половину этого урона при успехе.`,
    },
  ],
}
