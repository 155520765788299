const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_INT} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_SYNAPTIC_STATIC} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SYNAPTIC_STATIC,
  name: 'Синоптический разряд',
  nameEn: 'Synaptic Static',
  description: `Вы выбираете точку в пределах дистанции и вызываете в ней взрыв психической энергии. Каждое существо в сфере с радиусом 20 футов с центром в этой точке должно пройти испытание Интеллекта. Существа, у которых Интеллект равен 2 или ниже, не попадают под действие этого заклинания. Цель получает 8к6 урона психической энергией при провале или половину этого урона при успехе.

При провале испытания цель начинает путаться в мыслях на протяжении 1 минуты. В течение этого времени она бросает к6 и вычитает получившееся число из всех его бросков атаки и проверок способности, а также из его испытаний Телосложения на сохранение концентрации. В конце каждого своего хода цель может проходить испытание Интеллекта. При успехе эффект для него оканчивается.`,
  lvl: 5,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_INT,
  },
  source: {
    id: SOURCE_XGTE,
    page: 166,
  },
}
