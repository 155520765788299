import React from 'react'
import PropTypes from 'prop-types'

import AdsAfterImages from '@/ads/components/AdsAfterImages'
import enrichWithAds from '@/utils/enrichWithAds'

import ImageListComponent from './ImageListComponent'

const ImageListContainer = ({ imageList, ...rest }) => {
  if (imageList) {
    const listWithAds = enrichWithAds(imageList, AdsAfterImages)

    if (listWithAds.length) {
      return (
        <ImageListComponent
          {...rest}
          list={listWithAds}
        />
      )
    }
  }

  return null
}

ImageListContainer.propTypes = {
  imageList: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object
  ]),
}

ImageListContainer.defaultProps = {
  imageList: [],
}

export default ImageListContainer
