const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_4,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_LYCANTHROPE,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  GEAR_LONGBOW,
  GEAR_SCIMITAR,
} = require('./../../../../gearIdList')
const {
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  lycantropDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_KEEN_HEARING_AND_SMELL,
  ABILITY_POUNCE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_WERETIGER,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Вертигр',
  nameEn: 'Weretiger',
  description: [
    `Вертигры — яростные охотники и воины с надменным и привередливым характером. Гибкие, с гладкой мускулатурой в гуманоидном облике, они имеют рост выше среднего и выглядят ухоженными. Вертигры вырастают до немыслимых размеров в зверином и гибридном облике, но чаще всего сражаются в гуманоидном обличье. Они не любят заражать проклятьем других, так как каждый новый вертигр означает конкуренцию за территорию и добычу.

Вертигры живут в джунглях на окраине цивилизации, и путешествуют в изолированные поселения для торговли или отдыха. Они живут и охотятся одни или в маленьком семейном кругу.`,
    ...lycantropDescription,
  ],
  id: CREATURE_WERETIGER,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_LYCANTHROPE,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 193,
  },
  armor: 12,
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: {
      value: 30,
      comment: '40 фт. в облике тигра',
    },
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_COMMON,
      comment: 'не может говорить в облике тигра',
    },
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в тигра, гибрид тигра и гуманоида, или же принять свой истинный облик гуманоида. Все его статистики кроме размера остаются одинаковыми во всех обликах. Всё несомое и носимое ★им★ снаряжение не превращается. ★Он★ принимает свой истинный облик, если умирает.`,
    },
    ABILITY_KEEN_HEARING_AND_SMELL,
    {
      id: ABILITY_POUNCE,
      comment: 'только в облике тигра или гибрида',
      range: 15,
      dc: 14,
      attack: 'одну атаку _Укусом_',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в облике гуманоида или гибрида',
      description: `В облике гуманоида ★СУЩЕСТВО★ совершает две атаки _Скимитаром_ или две атаки _Длинным луком_. В облике гибрида ★он★ может атаковать либо как тигр, либо как гуманоид.`,
    },
    {
      name: 'Укус',
      comment: 'только в облике тигра или гибрида',
      description: `Если цель — гуманоид, она должна пройти испытание Телосложения СЛ 13, иначе станет проклятой ликантропией вертигра.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Коготь',
      comment: 'только в облике тигра или гибрида',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_SCIMITAR,
      comment: 'только в облике гуманоида или гибрида',
    },
    {
      gearId: GEAR_LONGBOW,
      comment: 'только в облике гуманоида или гибрида',
    },
  ],
  genderId: GENDER_MALE,
}
