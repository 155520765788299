const {GENDER_FEMALE} = require('./../../genderList')
const {CREATURE_TYPE_NAGA} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_NAGA,
  nameEn: 'Naga',
  genderId: GENDER_FEMALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'нага',
      genitive: 'наги',
      dative: 'наге',
      accusative: 'нагу',
      instrumental: 'нагой',
      prepositional: 'наге',
    },
    plural: {
      nominative: 'наги',
      genitive: 'наг',
      dative: 'нагам',
      accusative: 'наг',
      instrumental: 'нагами',
      prepositional: 'нагах',
    },
  },
}
