const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_INT} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_ENEMIES_ABOUND} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_ENEMIES_ABOUND,
  name: 'Изобилие врагов',
  nameEn: 'Enemies Abound',
  description: `Вы достигаете сознания одного существа, которое Вы можете видеть, и заставляете его пройти испытание Интеллекта. Существо автоматически преуспевает, если оно невосприимчиво к испугу. При провале испытания цель теряет способность отличать друзей от врагов, относясь ко всем существам, которых она может видеть, как к врагам, пока заклинание не закончится. Каждый раз, когда цель получает урон, она может повторить испытание, окончив эффект на себе при успехе.

Всякий раз, когда существо под действием заклинания выбирает другое существо в качестве цели, оно должно выбирать цель случайным образом среди существ, которых она может видеть в пределах дистанции атаки, заклинания или других способностей, которые она использует. Если враг провоцирует атаку от существа под действием заклинания, существо должно совершить эту атаку, если это возможно.`,
  lvl: 3,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_INT,
  },
  source: {
    id: SOURCE_XGTE,
    page: 153,
  },
}
