const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_HEATED_BODY} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_11} = require('./../../../../crList')
const {CREATURE_REMORHAZ} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {remorhazDescriptionList} = require('./../../../../textCommonParts')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Ремораз',
  nameEn: 'Remorhaz',
  id: CREATURE_REMORHAZ,
  description: remorhazDescriptionList,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 250,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 20,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 21,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 5,
  },
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  cr: CR_11,
  featureList: [
    {
      id: ABILITY_HEATED_BODY,
      damage: `10 (3к6)`,
    },
  ],
  actionList: [
    {
      name: `Укус`,
      description: `Если цель — существо, она становится схваченной (СЛ высвобождения 17). Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может кусать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 6,
            diceType: 10,
            diceBonus: 7,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 3,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: `Проглатывание`,
      description: `★СУЩЕСТВО★ совершает одну атаку _Укусом_ по существу с размером не больше Среднего, схваченному ★им★. Если эта атака попадает, существо получает урон от _Укуса_, становится проглоченным и перестаёт быть схваченным. Будучи проглоченным, существо ослеплено и обездвижено, и обладает полным укрытием от атак и прочих эффектов, исходящих снаружи ★СУЩЕСТВО★, и получает урон кислотой 21 (6к6) в начале каждого хода ★СУЩЕСТВО★.

Если ★СУЩЕСТВО★ получает за один ход 30 или больше урона от существа, находящегося внутри, ★СУЩЕСТВО★ ★должен★ в конце этого хода пройти испытание Телосложения СЛ 15, иначе отрыгнёт всех проглоченных существ, которые падают ничком в пространстве в пределах 10 футов от ★него★. Если ★СУЩЕСТВО★ умирает, проглоченное существо перестаёт быть обездвиженым им, и может высвободиться из трупа, потратив 15 футов перемещения, падая при выходе ничком.`,
    },
  ],
  genderId: GENDER_MALE,
}
