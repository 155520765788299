const {CREATURE_TYPE_ORC} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  orcDescription,
  orcNote,
} = require ('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_ORC,
  nameEn: 'Orc',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'орк',
        genitive: 'орка',
        dative: 'орку',
        accusative: 'орка',
        instrumental: 'орком',
        prepositional: 'орке',
      },
      [GENDER_FEMALE]: {
        nominative: 'орчиха',
        genitive: 'орчихи',
        dative: 'орчихе',
        accusative: 'орчиху',
        instrumental: 'орчихой',
        prepositional: 'орчихе',
      },
    },
    plural: {
      nominative: 'орки',
      genitive: 'орков',
      dative: 'оркам',
      accusative: 'орков',
      instrumental: 'орками',
      prepositional: 'орках',
    },
  },
  note: orcNote,
  description: orcDescription,
}
