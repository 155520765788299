const {SOURCE_VGTM} = require('./../../sourceList')
const {CREATURE_HILL_GIANT} = require('./../../creatureIdList')
const {
  GOD_ANNAM,
  GOD_GROLANTOR,
} = require('./../../godIdList')

module.exports = [
  {
    header: 'Гролантор: вечно голодный, никогда не сытый',
    text: `[Гролантор](GOD:${GOD_GROLANTOR}) — божество, наиболее почитаемое [холмовыми великанами](CREATURE:${CREATURE_HILL_GIANT}). Наименьший из шести сыновей [Аннама](GOD:${GOD_ANNAM}), паршивая овца семейства, он презираем братьями и родителями. Большинство проблем [Гролантора](GOD:${GOD_GROLANTOR}), однако, возникали по его собственной вине.

Гордясь своей огромной силой (его единственное стоящее качество), [Гролантор](GOD:${GOD_GROLANTOR}) отказался признать превосходство своих старших, более умных и сильных братьев, и настаивал на том, чтобы к нему относились как к равному. Он постоянно жаловался на бесконечный голод, но вместо того, чтобы самому охотиться, он хватал пищу с тарелок своих братьев и родителей.

Такое поведение вызвало много драк между [Гролантором](GOD:${GOD_GROLANTOR}) и его братьями, большинство из которых [Гролантор](GOD:${GOD_GROLANTOR}) проигрывал. Рассказы о [Гроланторе](GOD:${GOD_GROLANTOR}) неизменно заканчиваются появлением очередного шрама на его спине, который он получил, спасаясь бегством от гнева члена семьи, которого он вывел из себя своим оскорбительным хвастовством и эгоизмом.`,
    source: {
      id: SOURCE_VGTM,
      page: 31,
    },
  },
]
