const {SOURCE_FTD} = require('./../../../../sourceList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../magicItemRarityList')
const {MAGIC_ITEM_PAPER_BIRD} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_PAPER_BIRD,
  name: 'Бумажная птица',
  nameEn: 'Paper Bird',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_UNCOMMON,
  isConsumable: true,
  description: `Написав сообщение не длиннее пятидесяти слов на этом магическом листе бумаги, произнесите имя существа. Лист магически сложится, превратившись в бумажную птичку Крохотного размера, и полетит к адресату, чьё имя Вы назвали. Адресат должен быть на одном плане бытия с Вами, иначе птица обратится в пепел, едва взлетев.

Птица — это предмет, у которого 1 хит, КД 13, скорость полёта 60 футов, Ловкость 16 (+3), и прочие характеристики 1 (−5). Она невосприимчива к урону ядом и психическому урону.

Птица полетит к адресату по самому прямому пути, остановится в пределах 5 футов от него и превратится в немагический неживой лист бумаги, который может развернуть только адресат. Если скорость или хиты птицы снижаются до 0, или её иным образом  лишат возможности двигаться, она обратится в пепел.

Бумажные птицы обычно можно найти в виде небольшой плоской коробки с 1к6+3 листами бумаги.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_FTD,
    page: 189,
  },
}
