const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1_8,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ANY_RACE,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NOT_LAWFUL,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    LANG_ANY,
  } = require('./../../../../languageIdList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_CROSSBOW_LIGHT,
    GEAR_LEATHER_ARMOR,
    GEAR_SCIMITAR,
  } = require('./../../../../gearIdList'),
  {
    CREATURE_BANDIT,
    CREATURE_MAGE,
    CREATURE_PIRATE,
    CREATURE_THUG,
    CREATURE_VETERAN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Разбойник',
  nameEn: 'Bandit',
  id: CREATURE_BANDIT,
  description: `Разбойники рыщут бандами, возглавляемыми [головорезами](CREATURE:${CREATURE_THUG}), [ветеранами](CREATURE:${CREATURE_VETERAN}) или [заклинателями](CREATURE:${CREATURE_MAGE}). Не все разбойники злы. Угнетения, засуха, болезни или голод могут подтолкнуть самых честных жителей на путь разбоя.

[Пираты](CREATURE:${CREATURE_PIRATE}) это [разбойники](CREATURE:${CREATURE_BANDIT}) открытых морей. Они могут быть флибустьерами, заинтересованными лишь в сокровищах и убийствах, или могут быть каперами, которым правитель разрешил нападать на суда вражеской страны с целью грабежа.`,
  alignmentId: ALIGNMENT_NOT_LAWFUL,
  source: {
    id: SOURCE_MM,
    page: 348,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  hp: {
    diceCount: 2,
  },
  armor: {
    ac: 12,
    gearId: GEAR_LEATHER_ARMOR,
  },
  cr: CR_1_8,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  actionList: [
    {
      gearId: GEAR_SCIMITAR,
    },
    {
      gearId: GEAR_CROSSBOW_LIGHT,
    },
  ],
  genderId: GENDER_MALE,
}
