const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_HORIZON_WALKER} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  SPELL_BANISHMENT,
  SPELL_ETHEREALNESS,
  SPELL_HASTE,
  SPELL_MISTY_STEP,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_TELEPORTATION_CIRCLE,
} = require('./../../../spellIdList')
const {
  FEATURE_DETECT_PORTAL,
  FEATURE_DISTANT_STRIKE,
  FEATURE_ETHEREAL_STEP,
  FEATURE_HORIZON_WALKER_SPELLS,
  FEATURE_PLANAR_WARRIOR,
  FEATURE_SPECTRAL_DEFENSE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_HORIZON_WALKER_SPELLS,
    name: `Магия Странников горизонта`,
    nameEn: `Horizon Walker Spells`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_BANISHMENT,
      SPELL_HASTE,
      SPELL_MISTY_STEP,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_TELEPORTATION_CIRCLE,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 55,
    },
  },
  {
    id: FEATURE_DETECT_PORTAL,
    name: `Обнаружить портал`,
    nameEn: `Detect Portal`,
    lvl: 3,
    text: `Вы получаете возможность волшебным образом ощутить присутствие планарного портала.

Вы можете действием определить расстояние и направление до ближайшего планарного портала в пределах 1 мили.

Использовав эту способность, Вы не можете использовать её снова, пока не завершите короткий или длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 55,
    },
  },
  {
    id: FEATURE_PLANAR_WARRIOR,
    name: `Планарный воин`,
    nameEn: `Planar Warrior`,
    lvl: [3, 11],
    text: `Вы учитесь использовать энергию мультивселенной, чтобы усиливать свои атаки.

Выберите бонусным действием одно видимое Вами в пределах 30 футов существо. В следующий раз, когда Вы попадёте по нему атакой оружием на этом ходу, весь урон от этой атаки становится уроном силовым полем, и существо получает дополнительно 1к8 урона силовым полем.

Когда Вы достигаете 11 уровня в этом классе, дополнительный урон увеличивается до 2к8.`,
    source: {
      id: SOURCE_XGTE,
      page: 55,
    },
  },
  {
    id: FEATURE_ETHEREAL_STEP,
    name: `Эфирный шаг`,
    nameEn: `Ethereal Step`,
    lvl: 7,
    text: `Вы осваиваете хождение по Эфирному Плану.

Вы можете бонусным действием сотворить заклинание [Эфирность](SPELL:${SPELL_ETHEREALNESS}), не тратя ячейку заклинания, но заклинание заканчивается в конце текущего хода.

Использовав эту способность, Вы не можете использовать её снова, пока не завершите короткий или длинный отдых.`,
    spellIdList: [SPELL_ETHEREALNESS],
    source: {
      id: SOURCE_XGTE,
      page: 56,
    },
  },
  {
    id: FEATURE_DISTANT_STRIKE,
    name: `Далёкий удар`,
    nameEn: `Distant Strike`,
    lvl: 11,
    text: `Вы получаете возможность проходить между планами в мгновение ока.

Когда Вы совершаете действие Атака, Вы можете телепортироваться не более чем на 10 футов перед каждой атакой в видимое Вами свободное пространство.

Если этим действием Атака Вы атаковали, по крайней мере, двух различных существ, то можете этим же действием Атака совершить ещё одну дополнительную атаку по третьему существу.`,
    source: {
      id: SOURCE_XGTE,
      page: 56,
    },
  },
  {
    id: FEATURE_SPECTRAL_DEFENSE,
    name: `Спектральная защита`,
    nameEn: `Spectral Defense`,
    lvl: 15,
    text: `Ваша способность перемещаться между планами позволяет скользить через планарные границы, чтобы уменьшать наносимый Вам в бою вред.

Когда Вы получаете урон от атаки, то можете реакцией дать себе на этот ход сопротивление ко всему урону этой атаки.`,
    source: {
      id: SOURCE_XGTE,
      page: 56,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_RANGER_HORIZON_WALKER,
  })
)

