const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_CULTIST,
  CREATURE_FLYING_SNAKE,
  CREATURE_TRIBAL_WARRIOR,
} = require('./../../../../creatureIdList')
const {
  SPEED_FLY,
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {ABILITY_FLYBY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_8} = require('./../../../../crList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Летающая змея',
  nameEn: 'Flying Snake',
  description: `Летающая змея это яркая змея с крыльями, обитающая в далёких джунглях. [Племенные народы](CREATURE:${CREATURE_TRIBAL_WARRIOR}), а также [культисты](CREATURE:${CREATURE_CULTIST}) иногда приручают летающих змей, делая из них посланников, переносящих в своих кольцах свитки.`,
  id: CREATURE_FLYING_SNAKE,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 332,
  },
  armor: 14,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 11,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 5,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 10,
    },
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_FLYBY,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 0,
            diceType: 0,
            diceBonus: 1,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 3,
            diceType: 4,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
