import React from 'react'
import classNames from 'classnames'

import checkIsProduction from '@/utils/checkIsProduction'

import './ScriptPlacerStyles.less'

const ScriptPlacer = (
  {
    className = '',
    onlyProd = false,
    scriptCode = '',
  }
) => {
  const IS_PRODUCTION = checkIsProduction()

  return (onlyProd ? IS_PRODUCTION : true)
    ? (
      <section
        className={classNames(
          className,
          'ScriptPlacer',
          {
            'ScriptPlacer-production': IS_PRODUCTION,
            'ScriptPlacer-development': !IS_PRODUCTION,
          }
        )}
        dangerouslySetInnerHTML={{
          __html: IS_PRODUCTION ? scriptCode : '',
        }}
      />
    )
    : null
}

export default ScriptPlacer
