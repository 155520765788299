module.exports = {
  text: `Меж ночью и днем меня духи гоняли,

По чистым сердцам они так тосковали,

И тут вдруг уверенность всю растеряли,

И в уши мне, тотчас, смеясь, закричали.



Не раз ты мне чашу наполни, а три

Пляшу я с графином — к устам он прилип.

Да скиньте же платья, незримые феи,

Пусть Похоти Царь собирает трофеи.



Поникнув ветвями вдаль тренты уходят,

Так шустро с девиц корсетики сходят.

Бриз песни моей их всех веселит,

А чаше в руках опустеть не грозит.



Солнце станет луной, а она солнцем станет.

Коротать так часы никогда не устану`,
  author: `сонет Озорного Сатира`,
}
