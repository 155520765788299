module.exports = [
  require('./list/air_bestial_spirit_2020_03_26'),
  require('./list/air_elemental_spirit_2020_03_26'),
  require('./list/beholderkin_spirit_2020_03_26'),
  require('./list/celestial_avenger_spirit_2020_03_26'),
  require('./list/celestial_defender_spirit_2020_03_26'),
  require('./list/deceitful_fey_spirit_2020_03_26'),
  require('./list/demon_spirit_2020_03_26'),
  require('./list/despair_shadow_spirit_2020_03_26'),
  require('./list/devil_spirit_2020_03_26'),
  require('./list/earth_elemental_spirit_2020_03_26'),
  require('./list/fear_shadow_spirit_2020_03_26'),
  require('./list/fire_elemental_spirit_2020_03_26'),
  require('./list/furious_fey_spirit_2020_03_26'),
  require('./list/fury_shadow_spirit_2020_03_26'),
  require('./list/ghostly_undead_spirit_2020_03_26'),
  require('./list/joyful_fey_spirit_2020_03_26'),
  require('./list/land_bestial_spirit_2020_03_26'),
  require('./list/putrid_undead_spirit_2020_03_26'),
  require('./list/skeletal_undead_spirit_2020_03_26'),
  require('./list/slaadi_spirit_2020_03_26'),
  require('./list/star_spawn_spirit_2020_03_26'),
  require('./list/water_bestial_spirit_2020_03_26'),
  require('./list/water_elemental_spirit_2020_03_26'),
  require('./list/yugoloth_spirit_2020_03_26'),
]
