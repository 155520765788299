const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SPELL_SEE_INVISIBILITY,
  SPELL_SOUL_CAGE,
  SPELL_TRUE_SEEING,
} = require('./../../../../../spellIdList')
const {
  TIME_HOUR,
  TIME_REACTION,
} = require('./../../../../../timePeriodList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')

module.exports = {
  id: SPELL_SOUL_CAGE,
  name: 'Клетка душ',
  nameEn: 'Soul Cage',
  description: `Это заклинание захватывает душу гуманоида, когда он умирает, и запирает её внутри крошечной клетки, которую Вы используете в качестве материального компонента. Украденная душа остается внутри клетки до тех пор, пока заклинание не закончится или пока Вы не уничтожите клетку, что также окончит заклинание. Пока у Вас есть душа внутри клетки, Вы можете использовать её любым из способов, описанных ниже. Вы можете использовать захваченную душу до шести раз.

Как только Вы используете душу в шестой раз, она высвобождается, и заклинание заканчивается. Пока душа находится в клетке, мёртвый гуманоид, которому она принадлежит, не может быть возрождён.

1. **Кража жизни.** Вы можете бонусным действием впитать жизненную силу души и восстановить себе 2к8 хитов.
2. **Вопрос душе.** Вы задаете душе вопрос (действие не требуется) и получаете короткий телепатический ответ, который Вы можете понять независимо от используемого языка. Душа знает только то, что она знала при жизни, но она должна отвечать Вам максимально правдиво, ничего не скрывая. Ответ должен состоять не более чем из одного-двух предложений и может звучать загадочно. 
3. **Заимствование опыта.** Бонусным действием Вы можете усилить себя жизненным опытом души, чтобы получить преимущество на следующий бросок атаки, проверку способности или испытание. Если Вы не используете это преимущество до начала своего следующего хода, оно будет потеряно.
4. **Глаза мертвеца.** Вы можете действием назвать место, которое гуманоид видел при жизни, что создаст в этом месте невидимый сенсор, если оно находится на одном плане существования с Вами. Сенсор остаётся до тех пор, пока Вы держите концентрацию, вплоть до 10 минут (как если бы Вы концентрировались на заклинании). Вы можете видеть и слышать с помощью этого сенсора, как будто бы Вы сами находитесь в том месте и используете свои собственные органы чувств. 

Существо, которое может видеть сенсор (например, если оно использует [Видение невидимого](SPELL:${SPELL_SEE_INVISIBILITY}) или [Истинное зрение](SPELL:${SPELL_TRUE_SEEING})), видит полупрозрачный образ истерзанного гуманоида, чью душу Вы заперли в клетке.`,
  lvl: 6,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 60,
  hasCost: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `крошечная серебряная клетка стоимостью 100 зм`,
  duration: {timeId: TIME_HOUR, count: 8},
  castTime: {timeId: TIME_REACTION, count: 1},
  castTimeComment: `которую Вы используете, когда умирает гуманоид, которого Вы можете видеть в пределах 60 футов от себя`,
  source: {
    id: SOURCE_XGTE,
    page: 154,
  },
}
