module.exports.LANG_AARAKOCRA = 'aarakocra'
module.exports.LANG_ABYSSAL = 'abyssal'
module.exports.LANG_AGLARONDAN = 'aglarondan'
module.exports.LANG_ALL = 'supernal'
module.exports.LANG_ALL_KNOWN_AT_LIFE = 'all_known_at_life'
module.exports.LANG_ALL_KNOWN_AT_PREVIOUS_FORM = 'all_known_at_previous_form'
module.exports.LANG_ALZHEDO = 'alzhedo'
module.exports.LANG_ANDT = 'andt'
module.exports.LANG_ANY = 'any'
module.exports.LANG_AQUAN = 'aquan'
module.exports.LANG_ARAGRAKH = 'aragrakh'
module.exports.LANG_AURAN = 'auran'
module.exports.LANG_BLINK_DOG = 'blink_dog'
module.exports.LANG_BOTHII = 'bothii'
module.exports.LANG_BULLYWUG = 'bullywug'
module.exports.LANG_CAN_TALK_WITH_SPIDERS = 'can_talk_with_spiders'
module.exports.LANG_CELESTIAL = 'celestial'
module.exports.LANG_CHARDIC = 'chardic'
module.exports.LANG_CHESSENTAN = 'chessentan'
module.exports.LANG_CHONDATHAN = 'chondathan'
module.exports.LANG_CHULT = 'chult'
module.exports.LANG_COMMON = 'common'
module.exports.LANG_COSH = 'cosh'
module.exports.LANG_D_TARIG = 'd_tarig'
module.exports.LANG_DAELIC = 'daelic'
module.exports.LANG_DAMARAN = 'damaran'
module.exports.LANG_DAMBRATHAN = 'dambrathan'
module.exports.LANG_DEEP_CROW = 'deep_crow'
module.exports.LANG_DEEP_SPEECH = 'deep_speech'
module.exports.LANG_DRACONIC = 'draconic'
module.exports.LANG_DRUIDIC = 'druidic'
module.exports.LANG_DURPARI = 'durpari'
module.exports.LANG_DWARVISH = 'dwarvish'
module.exports.LANG_EASTING = 'easting'
module.exports.LANG_ELVEN = 'elven'
module.exports.LANG_ERAKIC = 'erakic'
module.exports.LANG_GIANT = 'giant'
module.exports.LANG_GIANT_EAGLE = 'giant_eagle'
module.exports.LANG_GIANT_ELK = 'giant_elk'
module.exports.LANG_GIANT_OWL = 'giant_owl'
module.exports.LANG_GITH = 'gith'
module.exports.LANG_GNOLL = 'gnoll'
module.exports.LANG_GNOMISH = 'gnomish'
module.exports.LANG_GOBLIN = 'goblin'
module.exports.LANG_GRELL = 'grell'
module.exports.LANG_GRUNG = 'grung'
module.exports.LANG_GURAN = 'guran'
module.exports.LANG_HALFLING = 'halfling'
module.exports.LANG_HALRUAAN = 'halruaan'
module.exports.LANG_HOOK_HORROR = 'hook_horror'
module.exports.LANG_HULGORKYN = 'hulgorkyn'
module.exports.LANG_ICE_TOAD = 'ice_toad'
module.exports.LANG_IGNAN = 'ignan'
module.exports.LANG_ILLUSKAN = 'illuskan'
module.exports.LANG_INFERNAL = 'infernal'
module.exports.LANG_ITS_CREATOR = 'its_creator'
module.exports.LANG_ITS_SUMMONER = 'its_summoner'
module.exports.LANG_IXITXACHITL = 'ixitxachitl'
module.exports.LANG_KRUTHIK = 'kruthik'
module.exports.LANG_LANTANESE = 'lantanese'
module.exports.LANG_LOROSS = 'loross'
module.exports.LANG_LOXODON = 'loxodon'
module.exports.LANG_MAIN_LANGUAGE_OF_ITS_MASTER = 'main_language_of_its_master'
module.exports.LANG_MIDANI = 'midani'
module.exports.LANG_MODRONE = 'modrone'
module.exports.LANG_MOONSHAE = 'moonshae'
module.exports.LANG_MULHORANDI = 'mulhorandi'
module.exports.LANG_NARIC = 'naric'
module.exports.LANG_NETHERESE = 'netherese'
module.exports.LANG_NEXALAN = 'nexalan'
module.exports.LANG_OLD_OMUAN = 'old_omuan'
module.exports.LANG_ONE_OF_ITS_CREATOR = 'one_of_its_creator'
module.exports.LANG_ORC = 'orc'
module.exports.LANG_OTYUGH = 'otyugh'
module.exports.LANG_PRIMORDIAL = 'primordial'
module.exports.LANG_RASHEMI = 'rashemi'
module.exports.LANG_REGHEDJIC = 'reghedjic'
module.exports.LANG_RENGARDT = 'rengardt'
module.exports.LANG_ROUSHOUM = 'roushoum'
module.exports.LANG_RUATHEN = 'ruathen'
module.exports.LANG_RUATHLEK = 'ruathlek'
module.exports.LANG_SAHUAGIN = 'sahuagin'
module.exports.LANG_SELDRUIN = 'seldruin'
module.exports.LANG_SERUSAN = 'serusan'
module.exports.LANG_SHAARAN = 'shaaran'
module.exports.LANG_SHOU = 'shou'
module.exports.LANG_SLAAD = 'slaad'
module.exports.LANG_SPHINX = 'sphinx'
module.exports.LANG_SYLVAN = 'sylvan'
module.exports.LANG_TASHALAN = 'tashalan'
module.exports.LANG_TELEPATHY = 'telepathy'
module.exports.LANG_TERRAN = 'terran'
module.exports.LANG_THAYAN = 'thayan'
module.exports.LANG_THIEVES_CANT = 'thieves_cant'
module.exports.LANG_THORASS = 'thorass'
module.exports.LANG_THRI_KREEN = 'thri_kreen'
module.exports.LANG_TLINCALLI = 'tlincalli'
module.exports.LANG_TROGLODYTE = 'troglodyte'
module.exports.LANG_TRUE_LUSKAN = 'true_luskan'
module.exports.LANG_TRUSKAN = 'truskan'
module.exports.LANG_TUIGAN = 'tuigan'
module.exports.LANG_TURMIC = 'turmic'
module.exports.LANG_ULOUSHINN = 'uloushinn'
module.exports.LANG_ULUIK = 'uluik'
module.exports.LANG_ULUTIUN = 'ulutiun'
module.exports.LANG_UMBER_HULK = 'umber_hulk'
module.exports.LANG_UNDERCOMMON = 'undercommon'
module.exports.LANG_UNTHERIC = 'untheric'
module.exports.LANG_VEGEPYGMY = 'vegepygmy'
module.exports.LANG_WAELAN = 'waelan'
module.exports.LANG_WINTER_WOLF = 'winter_wolf'
module.exports.LANG_WORGEN = 'worgen'
module.exports.LANG_YETI = 'yeti'
module.exports.LANG_YIKARIA = 'yikaria'
