const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_MEAZEL} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_MEAZEL,
  nameEn: 'Meazel',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'мизель',
      genitive: 'мизеля',
      dative: 'мизелю',
      accusative: 'мизеля',
      instrumental: 'мизелем',
      prepositional: 'мизеле',
    },
    plural: {
      nominative: 'мизели',
      genitive: 'мизелей',
      dative: 'мизелям',
      accusative: 'мизелей',
      instrumental: 'мизелями',
      prepositional: 'мизелях',
    },
  },
}
