const {
  SOURCE_ROT,
} = require('./../../../../sourceList')
const {
  CR_1,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_LEATHER_ARMOR,
  GEAR_SCIMITAR,
} = require('./../../../../gearIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_DRAGON_FANATIC,
  ABILITY_FANATIC_ADVANTAGE,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  cultOfDragonDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_DRAGONCLAW,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Драконий коготь',
  nameEn: 'Dragonclaw',
  id: CREATURE_DRAGONCLAW,
  description: cultOfDragonDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_ROT,
    page: 91,
  },
  armor: {
    ac: 14,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 13,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_DRAGON_FANATIC,
    {
      id: ABILITY_FANATIC_ADVANTAGE,
      damage: '7 (2к6)',
    },
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Ятаганом_.`,
    },
    {
      name: 'Ятаган',
      gearId: GEAR_SCIMITAR,
    },
  ],
}
