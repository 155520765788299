const {CREATURE_TYPE_SATYR} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  satyrDescriptionList,
  satyrNote,
} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_SATYR,
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'сатир',
        genitive: 'сатира',
        dative: 'сатиру',
        accusative: 'сатира',
        instrumental: 'сатиром',
        prepositional: 'сатире',
      },
      [GENDER_FEMALE]: {
        nominative: 'сатирша',
        genitive: 'сатирши',
        dative: 'сатирше',
        accusative: 'сатиршу',
        instrumental: 'сатиршой',
        prepositional: 'сатирше',
      },
    },
    plural: {
      nominative: 'сатиры',
      genitive: 'сатиров',
      dative: 'сатирам',
      accusative: 'сатиров',
      instrumental: 'сатирами',
      prepositional: 'сатирах',
    },
  },
  nameEn: 'Satyr',
  note: satyrNote,
  description: satyrDescriptionList,
}
