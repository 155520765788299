const {
  GEAR_LONGBOW,
  GEAR_QUARTERSTAFF,
} = require('./../../../../gearIdList')
const {LANG_ELVEN} = require('./../../../../languageIdList')
const {MAGIC_ITEM_GWATHERN_FALN} = require('./../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../magicItemTypeList')
const {SOURCE_DDHC_MORD_01} = require('./../../../../sourceList')
const {SPELL_FAERIE_FIRE} = require('./../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_GWATHERN_FALN,
  name: 'Гва’терн Фалн',
  nameEn: 'Gwa’thern Faln',
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_LONGBOW,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Этот древний посох-лук вырезан из толстого куска тиса и отполирован до блеска. Это оружие (чьё имя на [эльфийском](LANG:${LANG_ELVEN}) значит «Убийца Теней») когда-то носил легендарный эльфийский воин.
  
Вы получаете бонус +1 к броскам атаки и урона этим магическим оружием, которое также можно использовать как [посох](GEAR:${GEAR_QUARTERSTAFF}). Прошептав имя лука и выстрелив стрелой в точку в пределах 60 футов, Вы можете действием наложить заклинание [Огонь фей](SPELL:${SPELL_FAERIE_FIRE}) (СЛ 15). Однажды использовав это свойство, Вы не можете снова использовать его до следующего рассвета.`,
  spellIdList: [SPELL_FAERIE_FIRE],
  source: {
    id: SOURCE_DDHC_MORD_01,
    page: 36,
  },
}
