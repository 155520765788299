const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_11,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ELEMENTAL,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CG,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    GEAR_SCIMITAR,
  } = require('./../../../../gearIdList'),
  {
    LANG_AURAN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_ELECTRICITY,
    DAMAGE_SLASHING,
    DAMAGE_THUNDER,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_CONJURE_ELEMENTAL,
    SPELL_CREATE_FOOD_AND_WATER,
    SPELL_CREATION,
    SPELL_DETECT_EVIL_AND_GOOD,
    SPELL_DETECT_MAGIC,
    SPELL_GASEOUS_FORM,
    SPELL_INVISIBILITY,
    SPELL_MAJOR_IMAGE,
    SPELL_PLANE_SHIFT,
    SPELL_THUNDERWAVE,
    SPELL_TONGUES,
    SPELL_WIND_WALK,
  } = require('./../../../../spellIdList'),
  {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    geniusDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    PC_CLASS_WIZARD,
  } = require('./../../../../pcClassIdList'),
  {
    CREATURE_AIR_ELEMENTAL,
    CREATURE_DAO,
    CREATURE_DJINNI,
    CREATURE_EFREETI,
    CREATURE_MARID,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Джинн',
  nameEn: 'Djinni',
  id: CREATURE_DJINNI,
  description: [
    `Гордые, эмоциональные гении со Стихийного Плана Воздуха. Джинны — это привлекательные, хорошо сложённые, высокие гуманоиды с синей кожей и тёмными глазами. Они одеваются в невесомые мерцающие шелка, которые не только удобны, но и хорошо подчёркивают их мускулатуру.

# Ветреные эстеты

Джинны правят облачными островами, покрытыми бесчисленными шатрами или увенчанными чудесными зданиями, фонтанами и садами. Джинны любят комфорт и удобство, наслаждаются сочными фруктами, острыми винами, ароматными благовониями и прекрасной музыкой.

Джинны известны своим озорством и благосклонным отношением к смертным. В среде гениев джинны прохладно относятся к [ифритам](CREATURE:${CREATURE_EFREETI}) и [маридам](CREATURE:${CREATURE_MARID}), которых считают высокомерными. Они открыто презирают [дао](CREATURE:${CREATURE_DAO}) и при малейшей провокации с их стороны отвечают им той же монетой.

# Властители ветров

Будучи повелителями воздуха, джинны ездят на мощных вихрях, которые сами же и создают развлечения ради. Они способны управлять ими, и даже перевозить на них пассажиров. Существа, противостоящие джинну, атакуются ветром и громом, и на этом же ветру джинн покинет поле боя, если враг окажется сильнее. Во время полёта нижняя часть тела джинна становится столбом вращающегося воздуха.

# Покорные слуги

Джинны верят, что подневольная служба — это проявление судьбы, и что никто не может противиться ей. Поэтому они единственные из всех гениев наиболее подходят для подобной службы, хотя она никогда не доставляет им радости. Своих рабов джинны воспринимают больше как слуг, заслуживающих доброты и защиты, и неохотно с ними расстаются.

Смертный, желающий получить услугу от джинна, может упросить его, преподнеся хорошие дары, или прибегнув к лести. Однако могущественные волшебники способны просто призвать джинна и заставить его служить, или могут поймать его, используя магию. Долговременная служба раздражает джинна, а заточение — непростительно. Джиннов возмущают жестокие [волшебники](PC_CLASS:${PC_CLASS_WIZARD}), заставляющие их столетиями томиться в бутылках, железных флягах и духовых инструментах. Предательство, особенно со стороны смертного, которому джинн доверял — крайне мерзкое деяние, которое можно искупить только смертью.`,
    ...geniusDescriptionList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_CG,
  source: {
    id: SOURCE_MM,
    page: 51,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 90,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 22,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 20,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
    PARAM_CHA,
  ],
  immunityList: [
    DAMAGE_THUNDER,
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_AURAN,
  ],
  cr: CR_11,
  featureList: [
    {
      name: 'Стихийная гибель',
      description: `Если ★СУЩЕСТВО★ умирает, ★его★ тело распадается на тёплый ветерок, оставляя только снаряжение, которое ★СУЩЕСТВО★ ★носил★ и ★нёс★.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_THUNDERWAVE,
          SPELL_DETECT_EVIL_AND_GOOD,
          SPELL_DETECT_MAGIC,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          {
            id: SPELL_CREATE_FOOD_AND_WATER,
            comment: 'может создавать вино вместо воды',
          },
          SPELL_WIND_WALK,
          SPELL_TONGUES,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_GASEOUS_FORM,
          SPELL_INVISIBILITY,
          SPELL_MAJOR_IMAGE,
          {
            id: SPELL_CONJURE_ELEMENTAL,
            comment: `только [воздушный элементаль](CREATURE:${CREATURE_AIR_ELEMENTAL})`,
          },
          SPELL_CREATION,
          SPELL_PLANE_SHIFT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки _Скимитаром_.`,
    },
    {
      name: 'Скимитар',
      // gearId: GEAR_SCIMITAR, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 5,
          },
          {
            typeList: [
              DAMAGE_THUNDER,
              DAMAGE_ELECTRICITY,
            ],
            diceCount: 1,
            diceType: 6,
            comment: ' (на выбор ★СУЩЕСТВО★)',
          },
        ],
      },
    },
    {
      name: 'Создание смерча',
      description: `В точке, которую ★СУЩЕСТВО★ видит в пределах 120 футов от себя, магическим образом появляется смерч в виде цилиндра с радиусом 5 футов и высотой 30 футов. Смерч существует, пока ★СУЩЕСТВО★ поддерживает концентрацию (как при концентрации на заклинании).

Все существа кроме ★СУЩЕСТВО★, входящие в смерч, должны пройти испытание Силы СЛ 18, иначе обездвиживаются им. ★СУЩЕСТВО★ может действием перемещать смерч на расстояние до 60 футов, и существа, обездвиженые смерчем, перемещаются вместе с ним. Смерч исчезает, если ★СУЩЕСТВО★ перестаёт его видеть.

Существо может действием освободить существо, обездвиженое смерчем, в том числе и себя, преуспев в проверке Силы СЛ 18. Если проверка успешна, существо перестаёт быть обездвиженым и перемещается в ближайшее пространство за пределами смерча.`,
    },
  ],
  genderId: GENDER_MALE,
}
