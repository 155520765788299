const {
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  TIME_REACTION,
  TIME_ROUND,
} = require('./../../../../../timePeriodList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_TEMPORAL_SHUNT} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TEMPORAL_SHUNT,
  name: 'Выворачивание времени',
  nameEn: 'Temporal Shunt',
  description: `Выбранное существо должно пройти испытание Мудрости или исчезнуть, будучи выброшенным в другую точку во времени, что заставляет атаку промахнуться, а заклинание провалиться. В начале своего следующего хода, цель возвращается обратно на своё место или в ближайшее к нему свободное пространство. Цель не помнит что Вы сотворяли это заклинание и что оно на неё воздействовало.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, Вы можете выбрать целью одно дополнительное существо за каждый круг ячейки выше пятого. Все цели должны находиться в пределах 30 футов друг от друга.`,
  lvl: 5,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  castTime: { timeId: TIME_REACTION, count: 1 },
  castTimeComment: `совершаемая, когда видимое Вами существо совершает бросок атаки или начинает сотворять заклинание`,
  isDunamisBased: true,
  duration: { timeId: TIME_ROUND, count: 1 },
  effect: {
    savethrowParam: PARAM_WIT,
  },
  source: {
    id: SOURCE_EGTW,
    page: 190,
  },
}
