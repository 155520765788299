const {SOURCE_GGTR} = require ('./../../sourceList')
const {CREATURE_TYPE_CENTAUR} = require('./../../creatureTypeIdList')
const {
  centaurDescriptionList,
  centaurNote,
} = require('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_CENTAUR,
  nameEn: 'Centaur',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'кентавр',
        genitive: 'кентавра',
        dative: 'кентавру',
        accusative: 'кентавра',
        instrumental: 'кентавром',
        prepositional: 'кентавре',
      },
      [GENDER_FEMALE]: {
        nominative: 'кентаврида',
        genitive: 'кентавриды',
        dative: 'кентавриде',
        accusative: 'кентавриду',
        instrumental: 'кентавридой',
        prepositional: 'кентавриде',
      },
    },
    plural: {
      nominative: 'кентавры',
      genitive: 'кентавров',
      dative: 'кентаврам',
      accusative: 'кентавров',
      instrumental: 'кентаврами',
      prepositional: 'кентаврах',
    },
  },
  note: centaurNote,
  description: [
    ...centaurDescriptionList,
    {
      header: 'Кентавры',
      text: `В Равнике, необъятном городе, где фраза «свободная дорога» кажется оксюмороном, а «открытое поле» звучит как полная чушь, кентавры всё же сохраняют любовь к простору и свободе путешествий.

Везде, где только это возможно, кентавры скачут по просторам площадей, раскидистым паркам и пустым развалинам. Они несутся во весь опор, гремя копытами и размахивая хвостами на ветру, пока на пути не вырастет очередная стена и не остановит их.`,
      source: {
        id: SOURCE_GGTR,
        page: 16,
      },
    },
    {
      header: 'Природная кавалерия',
      text: `Верхняя, вплоть до поясницы часть тела кентавра — мускулистый человеческий торс. Они демонстрируют всё разнообразие человеческих особенностей и цветов кожи. Уши кентавров слегка заострены, но лица шире и квадратнее эльфийских.

Ниже пояса у кентавров тела небольших лошадей, с таким же разнообразием расцеток — от каштановой и гнедой масти до мастей в яблоках или даже в полоску, как у зебр.

Большиснтво кентавро укладывает хвост и причёску в одном стиле. Кентавры Селезнии предпочитают длинные распущенные волосы, а кентавры Груулов стигут волосы в остром грубом стиле.

Верхняя часть туловища кентавра сравнима по размеру с человеческим торсом, а нижняя — с телом лошади четырёх футов в холке. Хотя кентавры меньше, чем человеческие всадники на лошадях, они могут выполнять схожие роли — кавалерии, посыльных, охранников или разщведчиков.`,
      source: {
        id: SOURCE_GGTR,
        page: 16,
      },
    },
    {
      header: 'Родство с природой',
      text: `У кентавров сильна тяга к природе. Из гильдий с подобной близостью кентавры предпочитают кольца руин кланов Груулов и широкие площади конклава Селезнии подземным туннелями Голгари и лабораториям Симиков.

Кентавры празднуют жизнь и рост, а рождение жеребёнка — всегда повод для праздника. В то же время они почитают традиции прошлого, и как для Груулов, так и для Селезнии именнокентавры — голоса памяти и истории, сохраняющими старые обычаи и поддерживающие легенды о героях предков. Они чувствуют близкое родство с дикими животными, возможно, из-за своего собственных лошадиных тел, и получают удовольствие от ощущения бега в стадах и стаях других зверей.`,
      source: {
        id: SOURCE_GGTR,
        page: 16,
      },
    },
    {
      header: 'Кланы и сообщество',
      text: `Кентавры чувствуют взаимосвязи природного мира. Таким образом, они прославляют семью и сообщество как микрокосмы этой великой связи. Среди кентавров Груулов сильна клановая идентичность, а кентавры Селезнии яростно преданы своим отдельным сообществам и гильдии в целом. Их любовь к истории и традициям также означает, что кентавры с большей вероятностью, чем большинство других жителей Равники, присоединятся к той же гильдии, что и их родители.`,
      source: {
        id: SOURCE_GGTR,
        page: 16,
      },
    },
    {
      header: 'Имена кентавров',
      text: `Кентавры передают имена внутри семьи. Имя, дарованное новому жеребенку, обычно является именем последнего умершего члена семьи того же пола. Это сохраняет память о нём — и, как верят кентавры, некую часть души умершего. Кентавры не используют фамилий, но носят символы, обозначающие принадлежность к своей семье. Эти символы могут быть графическими изображениями растений или животных, тексты девизов, косы и бусины в волосах и хвосте или даже особые узоры ткани.

# Мужские имена

Бонмод, Боруво, Влодим, Волим, Дрозан, Козим, Милош, Нинос, Олексий, Орвал, Радовас, Радом, Ростис, Светёс, Томис, Трижиро, Чоди, Ярог

# Женские имена

Галисня, Дайва, Дуня, Жендоя, Зоря, Ириня, Котяли, Лаля, Литися, Мадя, Мира, Нежа, Никя, Остани, Пиня, Рада, Райся, Стасоля, Татна, Эльная`,
      source: {
        id: SOURCE_GGTR,
        page: 16,
      },
    },
  ],
}
