const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_ANIMAL_SHAPES} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_ANIMAL_SHAPES,
  name: 'Превращение в животных',
  nameEn: `Animal Shapes`,
  description: `Ваша магия превращает существ в зверей. Выберите любое число согласных существ, которых видите в пределах дистанции. Вы меняете облик каждой цели на облик зверя Большого или меньшего размера с показателем опасности не больше 4. В последующие ходы Вы можете действием дать этим существам новый облик.

Превращение держится всю длительность заклинания, или пока хиты цели не опустятся до 0, или она не умрёт. У каждой цели может быть свой облик. Игровые параметры целей меняются на параметры выбранных зверей, но у них остаётся своё мировоззрение и значения Интеллекта, Мудрости и Харизмы. Цель принимает хиты нового облика, а по возвращении в свой облик у неё будут хиты как перед превращением. Если свой облик возвращается в результате опускания хитов до 0, весь излишний урон переносится на хиты естественного облика. Если излишний урон не опустил хиты естественного облика ниже 0, существо не теряет сознание. Действия существа ограничены теми, что доступны новому облику, и оно не может разговаривать и сотворять заклинания.

Снаряжение цели сливается с новым обликом. Цель не может активировать, использовать своё снаряжение и получать от него преимущества.`,
  lvl: 8,
  magicSchoolId: MAGIC_TRANSMUTATION,
  needConcentration: true,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_HOUR, count: 24 },
  source: [
    {
      id: SOURCE_PHB,
      page: 264,
    },
    {
      id: SOURCE_SRD,
      page: 115,
    },
  ],
}
