const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_BLINDED,
  CONDITION_DEAFENED,
} = require('./../../../../conditionList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {blightDescriptionList} = require('./../../../../textCommonParts')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_TYPE_PLANT} = require('./../../../../creatureTypeIdList')
const {CREATURE_VINE_BLIGHT} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Вьющаяся зараза',
  nameEn: 'Vine Blight',
  id: CREATURE_VINE_BLIGHT,
  description: [
    `Прикидываясь массой ползучих лиан, вьющаяся зараза скрывается в зарослях, ожидая приближающуюся добычу. Шевеля растения вокруг себя, эта зараза путает и сбивает с толку своих врагов, прежде чем атаковать.

Это единственная зараза, способная говорить. Благодаря связи со злым духом древа Галтиаса, которому он служит, зараза говорит надломленным голосом своего мёртвого господина, дразня своих жертв или ведя переговоры с более сильным противником.`,
    ...blightDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 157,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 10,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 14,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityConditionList: [
    [CONDITION_DEAFENED],
    [CONDITION_BLINDED],
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1_2,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `клубка лиан`,
    },
  ],
  actionList: [
    {
      name: 'Сжимание',
      description: `Если размер цели не больше Большого, она становится схваченной (СЛ высвобождения 12). Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может сжимать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Опутывающие растения',
      description: `Цепкие корни и плети вырастают в 15-футовом радиусе вокруг ★СУЩЕСТВО★, засыхая через 1 минуту. В течение этого времени эта область является труднопроходимой местностью для существ, не являющихся растениями. Кроме того, все существа на выбор ★СУЩЕСТВО★, находящиеся в этой местности, когда растения только появляются, должны пройти испытание Силы СЛ 12, иначе обездвиживаются. Любое существо может действием совершить проверку Силы СЛ 12, освобождая себя или другое обездвиженое существо при успехе.`,
      restore: 5,
    },
  ],
  genderId: GENDER_FEMALE,
}
