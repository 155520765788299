module.exports = [
  require('./hallow'),
  require('./haste'),
  require('./hellish_rebuke'),
  require('./hex'),
  require('./hold_person'),
  require('./hold_monster'),
  require('./holy_aura'),
  require('./hunger_of_hadar'),
]
