const {
  CREATURE_EXPEDITIOUS_MESSENGER,
  CREATURE_HOMUNCULUS,
  CREATURE_IRON_DEFENDER,
} = require('./../../../../../creatureIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_CREATE_HOMUNCULUS} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_CREATE_HOMUNCULUS,
  name: 'Сотворение гомункула',
  nameEn: 'Create Homunculus',
  description: `Произнося сложное заклинание, Вы режете себя кинжалом, украшенным драгоценными камнями, и получаете 2d4 колющего урона, который никоим образом не может быть уменьшен. Затем Вы капаете свою кровь на другие компоненты заклинания и прикасаетесь к ним, превращая их в особого конструкта, называемого [гомункулом](CREATURE:${CREATURE_HOMUNCULUS}).

Это Ваш преданный спутник, и он умирает, если умираете вы. Всякий раз, когда Вы заканчиваете длинный отдых, Вы можете потратить до половины своих Костей Хитов, если гомункул находится на том же плане существования, что и вы. Когда Вы это делаете, бросьте каждую Кость Хитов и добавьте к ней свой модификатор Телосложения.

Максимум Ваших хитов понижается на получившуюся сумму, а максимум хитов гомункула вместе с текущим количеством хитов увеличивается на это же значение. Этот процесс не может понизить Ваши хиты ниже 1, и этот обмен хитами между Вами и гомункулом заканчивается после того как Вы завершите следующий длинный отдых. До этого момента понижение максимума Ваших хитов не может быть отменено никаким другим образом, кроме смерти гомункула.

Вы можете иметь только одного гомункула в каждый момент времени. Если Вы произносите это заклинание, пока Ваш гомункул жив, заклинание терпит неудачу.`,
  lvl: 6, //
  magicSchoolId: MAGIC_TRANSMUTATION,
  createdCreatureId: [
    CREATURE_HOMUNCULUS,
    CREATURE_EXPEDITIOUS_MESSENGER,
    CREATURE_IRON_DEFENDER,
  ],
  range: 0,
  hasCost: true,
  hasConsumable: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `Глина, пепел и корень мандрагоры, расходуемые заклинанием, и кинжал с инкрустированными драгоценными камнями стоимостью не менее 1000 зм`,
  castTime: {timeId: TIME_HOUR, count: 1},
  source: {
    id: SOURCE_XGTE,
    page: 167,
  },
}
