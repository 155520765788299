const {
  MAGIC_ABJURATION,
  MAGIC_CONJURATION,
  MAGIC_DIVINATION,
  MAGIC_ENCHANTMENT,
  MAGIC_EVOCATION,
  MAGIC_ILLUSION,
  MAGIC_NECROMANCY,
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_ACTION,
  TIME_BONUS_ACTION,
  TIME_DAY,
  TIME_HOUR,
  TIME_MINUTE,
  TIME_REACTION,
  TIME_ROUND,
} = require('./../../../../../timePeriodList')
const {
  ATTACK_MELEE_SPELL,
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../../attackTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_INCAPACITATED,
  CONDITION_INVISIBLE,
  CONDITION_PETRIFIED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_STUNNED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../../conditionList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ANTILIFE_SHELL,
  SPELL_ANTIMAGIC_FIELD,
  SPELL_ARCANE_EYE,
  SPELL_ARCANE_GATE,
  SPELL_ARCANE_LOCK,
  SPELL_ARMOR_OF_AGATHYS,
  SPELL_ARMS_OF_HADAR,
  SPELL_ASTRAL_PROJECTION,
  SPELL_AUGURY,
  SPELL_BANE,
  SPELL_BANISHING_SMITE,
  SPELL_BANISHMENT,
  SPELL_BARKSKIN,
  SPELL_BEACON_OF_HOPE,
  SPELL_BEAST_SENSE,
  SPELL_BESTOW_CURSE,
  SPELL_BIGBY_S_HAND,
  SPELL_BLADE_BARRIER,
  SPELL_BLADE_WARD,
  SPELL_BLINDING_SMITE,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BLINK,
  SPELL_BLUR,
  SPELL_BRANDING_SMITE,
  SPELL_BURNING_HANDS,
  SPELL_CALL_LIGHTNING,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CIRCLE_OF_POWER,
  SPELL_CLAIRVOYANCE,
  SPELL_CLONE,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_CLOUDKILL,
  SPELL_COLOR_SPRAY,
  SPELL_COMMAND,
  SPELL_COMMUNE,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_COMPELLED_DUEL,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_COMPULSION,
  SPELL_CONE_OF_COLD,
  SPELL_CONJURE_ANIMALS,
  SPELL_CONJURE_BARRAGE,
  SPELL_CONJURE_CELESTIAL,
  SPELL_CONJURE_FEY,
  SPELL_CONJURE_MINOR_ELEMENTALS,
  SPELL_CONJURE_VOLLEY,
  SPELL_CONJURE_WOODLAND_BEINGS,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_CONTINGENCY,
  SPELL_CONTINUAL_FLAME,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WEATHER,
  SPELL_CORDON_OF_ARROWS,
  SPELL_COUNTERSPELL,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_CREATION,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CRUSADER_S_MANTLE,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKVISION,
  SPELL_DAYLIGHT,
  SPELL_DEATH_WARD,
  SPELL_DELAYED_BLAST_FIREBALL,
  SPELL_DEMIPLANE,
  SPELL_DESTRUCTIVE_WAVE,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISINTEGRATE,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_DISPEL_MAGIC,
  SPELL_DISSONANT_WHISPERS,
  SPELL_DIVINATION,
  SPELL_DIVINE_WORD,
  SPELL_DOMINATE_BEAST,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_DRAWMIJ_S_INSTANT_SUMMONS,
  SPELL_DREAM,
  SPELL_DRUIDCRAFT,
  SPELL_EARTHQUAKE,
  SPELL_ELDRITCH_BLAST,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENSNARING_STRIKE,
  SPELL_ENTANGLE,
  SPELL_ENTHRALL,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_EYEBITE,
  SPELL_FABRICATE,
  SPELL_FAERIE_FIRE,
  SPELL_FALSE_LIFE,
  SPELL_FEATHER_FALL,
  SPELL_FEEBLEMIND,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_THE_PATH,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FIRE_STORM,
  SPELL_FIREBALL,
  SPELL_FLAME_BLADE,
  SPELL_FLAME_STRIKE,
  SPELL_FLAMING_SPHERE,
  SPELL_FLESH_TO_STONE,
  SPELL_FLY,
  SPELL_FORESIGHT,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_FRIENDS,
  SPELL_GASEOUS_FORM,
  SPELL_GEAS,
  SPELL_GENTLE_REPOSE,
  SPELL_GLIBNESS,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_GUIDING_BOLT,
  SPELL_GUST_OF_WIND,
  SPELL_HAIL_OF_THORNS,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HARM,
  SPELL_HEAL,
  SPELL_HEALING_WORD,
  SPELL_HEAT_METAL,
  SPELL_HEROES_FEAST,
  SPELL_HEROISM,
  SPELL_HUNTERS_MARK,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_GREASE,
  SPELL_ICE_STORM,
  SPELL_IDENTIFY,
  SPELL_ILLUSORY_SCRIPT,
  SPELL_IMPRISONMENT,
  SPELL_INCENDIARY_CLOUD,
  SPELL_INFLICT_WOUNDS,
  SPELL_INSECT_PLAGUE,
  SPELL_INVISIBILITY,
  SPELL_JUMP,
  SPELL_KNOCK,
  SPELL_LEGEND_LORE,
  SPELL_LEOMUND_S_SECRET_CHEST,
  SPELL_LEOMUND_S_TINY_HUT,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHT,
  SPELL_LIGHTNING_ARROW,
  SPELL_LIGHTNING_BOLT,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_CIRCLE,
  SPELL_MAGIC_JAR,
  SPELL_MAGIC_MISSILE,
  SPELL_MAGIC_MOUTH,
  SPELL_MAGIC_WEAPON,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MASS_HEAL,
  SPELL_MASS_HEALING_WORD,
  SPELL_MASS_SUGGESTION,
  SPELL_MELD_INTO_STONE,
  SPELL_MENDING,
  SPELL_MESSAGE,
  SPELL_METEOR_SWARM,
  SPELL_MIND_BLANK,
  SPELL_MIRAGE_ARCANE,
  SPELL_MIRROR_IMAGE,
  SPELL_MODIFY_MEMORY,
  SPELL_MORDENKAINEN_S_FAITHFUL_HOUND,
  SPELL_MORDENKAINEN_S_SWORD,
  SPELL_MOVE_EARTH,
  SPELL_NONDETECTION,
  SPELL_NYSTUL_S_MAGIC_AURA,
  SPELL_OTILUKE_S_FREEZING_SPHERE,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_OTTO_S_IRRESISTIBLE_DANCE,
  SPELL_PASSWALL,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PHANTOM_STEED,
  SPELL_PLANAR_ALLY,
  SPELL_PLANAR_BINDING,
  SPELL_PLANT_GROWTH,
  SPELL_POLYMORPH,
  SPELL_POWER_WORD_HEAL,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_STUN,
  SPELL_PRAYER_OF_HEALING,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PRISMATIC_WALL,
  SPELL_PRODUCE_FLAME,
  SPELL_PROGRAMMED_ILLUSION,
  SPELL_PROJECT_IMAGE,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_RAISE_DEAD,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_RAY_OF_FROST,
  SPELL_REGENERATE,
  SPELL_REINCARNATE,
  SPELL_REMOVE_CURSE,
  SPELL_RESISTANCE,
  SPELL_RESURRECTION,
  SPELL_REVERSE_GRAVITY,
  SPELL_REVIVIFY,
  SPELL_ROPE_TRICK,
  SPELL_SACRED_FLAME,
  SPELL_SCORCHING_RAY,
  SPELL_SCRYING,
  SPELL_SEARING_SMITE,
  SPELL_SEE_INVISIBILITY,
  SPELL_SEEMING,
  SPELL_SENDING,
  SPELL_SEQUESTER,
  SPELL_SHAPECHANGE,
  SPELL_SHATTER,
  SPELL_SIMULACRUM,
  SPELL_SLOW,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_STAGGERING_SMITE,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_SUGGESTION,
  SPELL_SUNBEAM,
  SPELL_SYMBOL,
  SPELL_TELEKINESIS,
  SPELL_TELEPATHY,
  SPELL_TELEPORT,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_TENSER_S_FLOATING_DISK,
  SPELL_THUNDEROUS_SMITE,
  SPELL_THUNDERWAVE,
  SPELL_TIME_STOP,
  SPELL_TRANSPORT_VIA_PLANTS,
  SPELL_TREE_STRIDE,
  SPELL_TRUE_RESURRECTION,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_UNSEEN_SERVANT,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_VICIOUS_MOCKERY,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_FORCE,
  SPELL_WALL_OF_ICE,
  SPELL_WALL_OF_STONE,
  SPELL_WALL_OF_THORNS,
  SPELL_WARDING_BOND,
  SPELL_WATER_BREATHING,
  SPELL_WEB,
  SPELL_WIND_WALL,
  SPELL_WISH,
} = require('./../../../../../spellIdList')

module.exports = [
  {
    id: SPELL_DIVINE_WORD,
    name: 'Божественное слово',
    nameEn: `Divine Word`,
    description: `Вы произносите божественное слово, наделённое силой, сформировавшей мир на заре творения. Выберите любое количество существ, которых Вы видите в пределах дистанции. Все эти существа, слышащие Вас, должны пройти испытание Харизмы. При провале существо попадает под действие эффекта, основанного на текущем количестве хитов:

* 50 хитов или меньше: глухота на 1 минуту
* 40 хитов или меньше: глухота и слепота на 10 минут
* 30 хитов или меньше: слепота, глухота и ошеломление на 1 час
* 20 хитов или меньше: моментальная смерть

Вне зависимости от текущих хитов, исчадия, небожители, феи и элементали, провалившие испытание, изгоняются на свой родной план (если только они уже там не находятся), и не могут вернуться на Ваш текущий план в течение 24 часов кроме как посредством заклинания [исполнение желаний](SPELL:${SPELL_WISH}).`,
    lvl: 7,
    magicSchoolId: MAGIC_EVOCATION,
    range: 30,
    componentIdList: [CAST_VERBAL],
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    effect: {
      savethrowParam: PARAM_CHA,
      condition: [
        CONDITION_DEAFENED,
        CONDITION_BLINDED,
        CONDITION_STUNNED,
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 213,
      },
      {
        id: SOURCE_SRD,
        page: 137,
      },
    ],
  },
  {
    id: SPELL_MORDENKAINEN_S_FAITHFUL_HOUND,
    name: 'Верный пёс Морденкайнена',
    nameEn: `Mordenkainen’s Faithful Hound`,
    nameEnAlt: `Faithful Hound`,
    description: `Вы создаёте призрачного сторожевого пса в свободном пространстве, видимом в пределах дистанции, где он и остаётся на время длительности заклинания, пока Вы не отпустите его действием, или пока Вы не отойдёте от него более чем на 100 футов.

Пёс невидим для всех существо кроме Вас, и ему нельзя причинить вред. Если в пределах 30 футов от него оказывается существо с размером Маленькое или больше, не произнося при этом пароль, указанный Вами при накладывании этого заклинания, пёс начинает громко лаять. Пёс видит невидимых существ и его зрение простирается на Эфирный План. Он игнорирует иллюзии.

В начале каждого Вашего хода пёс пытается укусить одно существо в пределах 5 футов от себя, враждебное Вам. Бонус атаки пса равен Вашему модификатору базовой характеристики + Ваш бонус мастерства. При попадании он причиняет колющий урон 4к8.`,
    lvl: 4,
    magicSchoolId: MAGIC_CONJURATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `крошечный серебряный свисток, кусочек кости и нитка`,
    duration: { timeId: TIME_HOUR, count: 8 },
    effect: {
      attackType: ATTACK_MELEE_SPELL,
      damage: {
        type: DAMAGE_PIERCING,
        diceCount: 4,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 214,
      },
      {
        id: SOURCE_SRD,
        page: 142,
      },
    ],
  },
  {
    id: SPELL_CONTINUAL_FLAME,
    name: 'Вечный огонь',
    nameEn: `Continual Flame`,
    description: `Из предмета, которого Вы касаетесь, исходит пламя, эквивалентное по яркости пламени факела.

Эффект выглядит как обычное пламя, но не создаёт тепла и не использует кислород. Вечный огонь можно накрыть или спрятать, но не потушить.`,
    lvl: 2,
    magicSchoolId: MAGIC_EVOCATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `порошок рубина на 50 зм, расходуемый заклинанием`,
    duration: Infinity,
    source: [
      {
        id: SOURCE_PHB,
        page: 215,
      },
      {
        id: SOURCE_SRD,
        page: 130,
      },
    ],
  },
  {
    id: SPELL_DREAM,
    name: 'Вещий сон',
    nameEn: 'Dream',
    description: `Это заклинание формирует сон другого существа.

Выберите целью этого заклинания известное вам существо. Цель должна находиться на одном плане существования с Вами. Этим заклинанием нельзя связаться с существами, которые не спят, например, с эльфами. Вы или согласное существо, которого Вы касаетесь, входите в транс, становясь посыльным. Находясь в трансе, посыльный осознаёт своё окружение, но не может совершать действий и перемещаться.

Если цель спит, посыльный появляется в её сне и может беседовать с ней, пока та спит, и пока заклинание активно. Посыльный также может формировать окружение сна, создавая местность, предметы и прочие образы. Посыльный может в любое время выйти из транса, окончив эффект этого заклинания. После пробуждения цель прекрасно помнит сон. Если в момент накладывания заклинания цель бодрствует, посыльный это узнаёт, и может либо окончить транс (и заклинание), либо подождать, пока цель заснёт, и тогда он появится во сне цели.

Вы можете сделать посыльного кошмарным для цели. В этом случае посыльный может передать послание не длиннее десяти слов, и цель обязана пройти испытание Мудрости. При провале отголоски кошмара длятся весь сон, и цель не получает преимуществ от этого отдыха. Кроме того, когда цель просыпается, она получает урон психической энергией 3к6.

Если у Вас есть часть тела, клок волос, обрезки ногтей или другая подобная часть тела цели, она проходит испытание с помехой.`,
    lvl: 5,
    magicSchoolId: MAGIC_ILLUSION,
    range: 'Особая',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `горсть песка, капля чернил и перо, выдернутое у спящей птицы`,
    duration: { timeId: TIME_HOUR, count: 8 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 3,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 215,
      },
      {
        id: SOURCE_SRD,
        page: 138,
      },
    ],
  },
  {
    id: SPELL_SEE_INVISIBILITY,
    name: 'Видение невидимого',
    nameEn: 'See Invisibility',
    description: `Пока заклинание активно, Вы видите невидимых существ и предметы, как если бы они были видимы, и Ваше зрение простирается на Эфирный План.

Эфирные существа и предметы выглядят призрачными и полупрозрачными.`,
    lvl: 2,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'щепотка талька и толчёного серебра',
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 215,
      },
      {
        id: SOURCE_SRD,
        page: 177,
      },
    ],
  },
  {
    id: SPELL_CONTROL_WATER,
    name: 'Власть над водами',
    nameEn: 'Control Water',
    description: `Пока это заклинание активно, Вы контролируете свободно текущую или стоячую воду в кубе с длиной ребра 100 футов.

Вы можете выбрать один эффект из перечисленных ниже. Вы можете в свой ход действием повторить тот же эффект или выбрать другой.

# Водоворот

Для этого эффекта требуется объём воды в квадрате с длиной стороны 50 футов и глубиной 25 футов. В центре области Вы формируете водоворот. Водоворот создаёт воронку шириной 5 футов в основании, 50 футов в верхней части, и высотой 25 футов. Все существа и предметы, находящиеся в воде в пределах 25 футов от водоворота, подтягиваются к нему на 10 футов. Существа могут плыть прочь от водоворота, совершая проверку Силы (Атлетика).

Если существо впервые за раунд входит в пространство воронки или начинает в ней ход, оно должно пройти испытание Силы.

* При провале существо получает дробящий урон 2к8 и становится пойманным воронкой, пока заклинание активно.
* При успешном спасении существо получает половину урона, и не становится пойманным воронкой.

Пойманное воронкой существо может действием пытаться уплыть от водоворота, как описано выше, но проверка Силы (Атлетика) совершается с помехой.

В каждом ходу в первый раз, когда предмет оказывается в области воронки, он получает дробящий урон 2к8; этот же урон причиняется за каждый раунд, проведённый предметом в воронке.

# Наводнение

Вы увеличиваете уровень стоячей воды в области на высоту до 20 футов. Если в область попадает берег, вода выливается на землю.

Если Вы выбрали область посреди другого водного пространства, то Вы вместо этого создаёте волну высотой 20 футов, проходящую с одного края области до другого, где она и опадает. Все транспортные средства Огромного размера и меньше по ходу волны уносятся вместе с волной на противоположную сторону. Все транспортные средства Огромного размера и меньше, ударенные волной, получают 25 процентный шанс опрокинуться.

Уровень воды остаётся повышенным, пока заклинание не окончится или Вы не выберете другой эффект. Если этот эффект создал волну, волна будет повторяться в начале Вашего следующего хода, пока длится эффект «наводнение».

# Перенаправление потока

Вы заставляете текущую воду в области перемещаться в выбранном Вами направлении, даже если воде придётся течь через препятствия, вверх по стенам или в другом неугодном ей направлении. В области заклинания вода течёт так, как укажете Вы, но оказавшись за пределами зоны заклинания, она возвращается к естественному течению. Вода продолжает течь в указанном Вами направлении, пока заклинание активно или пока Вы не выберете другой эффект.

# Разделение воды

Вы заставляете воду в области разойтись в стороны, формируя проход. Проход простирается в области заклинания, а разделённые воды формируют стены с обеих сторон. Проход существует, пока заклинание активно, или пока Вы не выберете другой эффект. После этого вода в течение раунда медленно заполняет проход, восстанавливаясь до нормального уровня.`,
    lvl: 4,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 300,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `капля воды и щепотка пыли`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_STR,
      damage: {
        type: DAMAGE_BLUDGEONING,
        diceCount: 2,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 215,
      },
      {
        id: SOURCE_SRD,
        page: 130,
      },
    ],
  },
  {
    id: SPELL_CONTROL_WEATHER,
    name: 'Власть над погодой',
    nameEn: 'Control Weather',
    description: `Вы получаете на время длительности контроль над погодой в пределах 5 миль от себя. Для накладывания этого заклинания Вы должны находиться на открытом воздухе. Если Вы переместитесь в место, где над головой не будет неба, заклинание тут же закончится. Когда Вы накладываете это заклинание, Вы изменяете текущую погоду, которую определяет Мастер в зависимости от климата и времени года. Вы можете изменить осадки, температуру и ветер. Требуется 1к4 × 10 минут, чтобы новые условия вступили в силу. Когда новые условия вступят в силу, Вы можете создать новые условия. Когда заклинание оканчивается, погода постепенно возвращается к нормальной.

Изменяя погоду, найдите текущее состояние в таблицах и измените их на 1 позицию выше или ниже. Изменяя ветер, можете изменить его направление.

**Осадки**

1. Чистое небо
2. Небольшая облачность
3. Пасмурно или густой туман
4. Дождь, град или снег
5. Проливной дождь, сильный град или метель

**Температура**

1. Невыносимая жара
2. Жара
3. Тепло 
4. Прохлада
5. Холод
6. Арктическая стужа

**Ветер**

1. Штиль
2. Умеренный ветер
3. Сильный ветер
4. Буря
5. Шторм
`,
    lvl: 8,
    magicSchoolId: MAGIC_TRANSMUTATION,
    castTime: { timeId: TIME_MINUTE, count: 10 },
    range: -1,
    rangeComment: 'пятьмильный радиус',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'горящее благовоние и кусочки земли и дерева, смешанные с водой',
    duration: { timeId: TIME_HOUR, count: 8 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 216,
      },
      {
        id: SOURCE_SRD,
        page: 131,
      },
    ],
  },
  {
    id: SPELL_SUGGESTION,
    name: 'Внушение',
    nameEn: 'Suggestion',
    description: `Вы внушаете определенный курс действий (ограниченный одной-двумя фразами) существу, видимому в пределах дистанции, способному слышать и понимать Вас. Существа, которые не могут быть очарованы, обладают иммунитетом к этому эффекту.

Внушение должно быть сформировано так, чтобы действие звучало разумным. Просьбы ударить себя мечом, броситься на копье, сжечь себя, или как-то иначе причинить себе вред оканчивают заклинание.

Цель должна пройти испытание Мудрости. При провале она следует заданному курсу действий настолько хорошо, насколько способна. Внушенные действия могут продолжаться всю длительность заклинания. Если внушенную деятельность можно выполнить за меньший срок, заклинание оканчивается, когда субъект оканчивает порученную задачу.

Вы можете также указать условие, которое запустит особое действие во время действия заклинания. Например, Вы можете внушить рыцарю, чтобы она отдала своего боевого коня первому встреченному нищему.

Если до окончания действия условие не будет выполнено, деятельность не совершается. Если Вы или кто-то из Ваших спутников причиняете урон цели, заклинание оканчивается.`,
    lvl: 2,
    magicSchoolId: MAGIC_ENCHANTMENT,
    needConcentration: true,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_MATERIAL],
    materialText: 'язык змеи и либо кусочек медовых сот, либо капля сладкого масла',
    duration: { timeId: TIME_HOUR, count: 8 },
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 216,
      },
      {
        id: SOURCE_SRD,
        page: 183,
      },
    ],
  },
  {
    id: SPELL_REVIVIFY,
    name: 'Возрождение',
    nameEn: `Revivify`,
    description: `Вы касаетесь существа, мёртвого не больше минуты.

Это существо возвращается к жизни с 1 хитом.

Это заклинание не может оживить существ, умерших от старости, а также не восстанавливает отсутствующие части тел.`,
    lvl: 3,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `бриллиант, стоящий 300 зм, расходуемый заклинанием`,
    source: [
      {
        id: SOURCE_PHB,
        page: 216,
      },
      {
        id: SOURCE_SRD,
        page: 175,
      },
    ],
  },
  {
    id: SPELL_THUNDERWAVE,
    name: 'Волна грома',
    nameAlt: 'Громовая волна',
    nameEn: 'Thunderwave',
    description: `От Вас исходит волна громовой силы. Все существа в кубе с длиной ребра 15 фт., исходящего от Вас, должны пройти испытание Телосложения.

* При провале существо получает урон звуком 2к8 и толкается на 10 футов от Вас.
* При успехе существо получает половину урона и не толкается.

Кроме того, незакрепленные предметы, оказавшиеся полностью в области эффекта, автоматически толкаются на 10 футов от Вас эффектом заклинания, и заклинание издает громовой рокот, слышимый в пределах 300 футов.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: '15-фт. куб',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_THUNDER,
        halfOnSuccess: true,
        diceCount: 1,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 216,
      },
      {
        id: SOURCE_SRD,
        page: 187,
      },
    ],
  },
  {
    id: SPELL_MAGE_HAND,
    name: 'Волшебная рука',
    nameAlt: 'Магическая рука',
    nameEn: 'Mage Hand',
    description: `В точке, выбранной Вами в пределах дистанции, появляется призрачная парящая рука. Рука существует, пока заклинание активно, или пока Вы не отпустите ее действием. Рука исчезает, если окажется более чем в 30 футах от Вас, или если Вы повторно используете это заклинание.

Вы можете действием контролировать руку. Вы можете с ее помощью манипулировать предметами, открывать незапертые двери и контейнеры, убирать предметы в открытые контейнеры и доставать их оттуда, или выливать содержимое флаконов. При каждом использовании руки Вы можете переместить ее на 30 фт.

Рука не может совершать атаки, активировать магические предметы и переносить более 10 фунтов.`,
    lvl: 0,
    magicSchoolId: MAGIC_CONJURATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 216,
      },
      {
        id: SOURCE_SRD,
        page: 160,
      },
    ],
  },
  {
    id: SPELL_MAGIC_MISSILE,
    name: 'Волшебная стрела',
    nameEn: 'Magic Missile',
    description: `Вы создаёте три светящихся дротика из магической силы. Каждый дротик попадает в существо на Ваш выбор, видимое в пределах дистанции. Каждый дротик причиняет урон силовым полем 1к4 + 1. Все дротики атакуют одновременно, и Вы можете направить их как в одно существо, так и в разных.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, заклинание создаёт по одному дополнительному дротику за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      damage: {
        type: DAMAGE_FORCE,
        diceCount: 3,
        diceType: 4,
        diceBonus: 1,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 217,
      },
      {
        id: SOURCE_SRD,
        page: 161,
      },
    ],
  },
  {
    id: SPELL_MAGIC_MOUTH,
    name: 'Волшебные уста',
    nameEn: `Magic Mouth`,
    description: `Вы внедряете в предмет, находящийся в пределах дистанции, послание, которое будет произнесено, когда будут выполнены условия. Выберите видимый предмет, который не несёт и не носит другое существо. Произнесите послание, которое может состоять не более чем из 25 слов, хотя его можно произносить в течение 10 минут. Затем определите условие, выполнение которого заставит заклинание передать Ваше послание.

Когда условие будет выполнено, на предмете появляется магический рот, зачитывающий послание Вашим голосом с той же самой громкостью. Если у выбранного Вами предмета есть рот или что-то похожее на рот (например, рот статуи), магический рот появляется на его месте, и кажется, что слова исходят оттуда. При накладывании заклинания Вы можете сделать так, что заклинание окончится, передав сообщение, или же останется активным и будет повторять сообщение при каждом срабатывании условия.

Вызывающее срабатывание условие может быть как общим, так и детализированным, но должно основываться на зрительных или слышимых условиях, происходящих в пределах 30 футов от предмета. Например, Вы можете заставить рот говорить, когда в пределах 30 футов от него пройдёт существо, или когда в пределах 30 футов от него зазвенит серебряный колокольчик.`,
    lvl: 2,
    magicSchoolId: MAGIC_ILLUSION,
    range: 30,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `кусочек медовых сот и порошок нефрита, стоящий как минимум 10 зм, расходуемые заклинанием`,
    duration: Infinity,
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 217,
      },
      {
        id: SOURCE_SRD,
        page: 161,
      },
    ],
  },
  {
    id: SPELL_ARCANE_LOCK,
    name: 'Волшебный замок',
    nameEn: `Arcane Lock`,
    description: `Вы касаетесь закрытой двери, окна, ворот, сундука или другого входа, и он становится запертым.

Вы и указанные при накладывании этого заклинания существа можете открывать предмет как обычно. Вы можете также установить пароль, произношение которого в пределах 5 футов от предмета подавляет заклинание на 1 минуту. В противном случае предмет не открывается, пока его не сломают, или пока заклинание не рассеют или не подавят. Накладывание [открывания](SPELL:${SPELL_KNOCK}) на предмет подавляет _волшебный замок_ на 10 минут.

Находящийся под действием этого заклинания предмет гораздо сложнее взломать и открыть силой; СЛ выламывания и взлома замков увеличивается на 10.`,
    lvl: 2,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `золотая пыль, стоящая как минимум 25 зм, расходуемая заклинанием`,
    duration: Infinity,
    source: [
      {
        id: SOURCE_PHB,
        page: 217,
      },
      {
        id: SOURCE_SRD,
        page: 118,
      },
    ],
  },
  {
    id: SPELL_MAGIC_JAR,
    name: 'Волшебный сосуд',
    nameEn: `Magic Jar`,
    description: `Ваше тело впадает в кататоническое состояние, так как душа покидает его и входит в контейнер, выбранный материальным компонентом. Пока Ваша душа обитает в контейнере, Вы воспринимаете своё окружение, как если бы находились в пространстве контейнера. Вы не можете перемещаться и использовать реакции. Единственное действие, которое Вы можете совершать, это проецирование души на 100 футов от контейнера, чтобы либо вернуться в своё тело (окончив этим заклинание), либо попытаться овладеть телом другого гуманоида.

Вы можете попытаться овладеть гуманоидом, находящимся в пределах 100 футов от Вас и видимым Вами (нельзя овладевать существами, защищёнными заклинаниями [защита от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}) и [магический круг](SPELL:${SPELL_MAGIC_CIRCLE})). Цель должна пройти испытание Харизмы. При провале Ваша душа перемещается в тело цели, а её душа становится запертой в контейнере. При успехе цель сопротивляется Вашим попыткам овладеть ею, и Вы не можете повторно пытаться овладеть ею в течение 24 часов.

Овладев телом существа, Вы начинаете его контролировать. Ваши игровые параметры заменяются параметрами существа, но Вы сохраняете своё мировоззрение и значения Интеллекта, Мудрости и Харизмы. Вы сохраняете преимущества от своих классовых умений. Если у цели есть уровни в классах, Вы не можете использовать её классовые умения.

Тем временем душа одержимого существа может воспринимать своё окружение из контейнера при помощи своих чувств, но не может перемещаться и не может совершать никаких действий.

Захватив тело, Вы можете действием вернуться из него в контейнер, если он находится в пределах 100 футов, возвращая душу существа в его тело. Если тело носителя умирает, пока Вы в нём находитесь, существо умирает, а Вы должны пройти испытание Харизмы СЛ своего же заклинания. При успехе Вы возвращаетесь в контейнер, если он находится в пределах 100 футов. В противном случае Вы умираете.

Если контейнер уничтожается или заклинание оканчивается, Ваша душа мгновенно возвращается в Ваше тело. Если Ваше тело находится далее чем в 100 футах, или если Ваше тело мертво, когда Вы пытаетесь в него вернуться, Вы умираете. Если в контейнере, который был уничтожен, находилась душа другого существа, эта душа возвращается в своё тело, если оно живо и находится в пределах 100 футов. В противном случае это существо умирает.

Когда заклинание оканчивается, контейнер уничтожается.`,
    lvl: 6,
    magicSchoolId: MAGIC_NECROMANCY,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `драгоценный камень, кристалл, реликварий или другой украшенный контейнер, стоящий как минимум 500 зм`,
    duration: Infinity,
    castTime: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_CHA,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 217,
      },
      {
        id: SOURCE_SRD,
        page: 160,
      },
    ],
  },
  {
    id: SPELL_PHANTASMAL_FORCE,
    name: 'Воображаемая сила',
    nameEn: 'Phantasmal Force',
    description: `Вы создаёте иллюзию в сознании существа, видимого в пределах дистанции. Цель должна пройти испытание Интеллекта. При провале Вы создаёте воображаемый предмет, существо или другое видимое явление на свой выбор, помещающееся в куб с длиной ребра 10 футов, и воспринимаемое только целью, пока действует заклинание. Это заклинание не оказывает никакого эффекта на нежить и конструктов.

Иллюзия включает звуки, температуру и прочие раздражители, очевидные только для этого же существа.

Цель может действием исследовать иллюзию проверкой Интеллекта (Анализ) против СЛ Ваших заклинаний. Если проверка была успешной, цель понимает, что перед ней иллюзия, и заклинание оканчивается.

Пока цель находится под действием этого заклинания, она считает иллюзию настоящей. Она сама придумывает объяснения нелогичности взаимодействия с иллюзией. Например, попытавшись пройти по вымышленному мосту над провалом, она упадёт вниз. Если цель выживет, она всё равно будет считать, что мост существует, а пройти ей помешало что-то другое — её толкнули, она поскользнулась, или её сбил сильный порыв ветра.

Цель настолько уверена в существовании иллюзии, что даже будет получать от неё урон. Иллюзия в форме существа может атаковать цель. Иллюзия, выглядящая как огонь, бассейн с кислотой или лава, могут сжечь цель. Каждый раунд в Ваш ход иллюзия может причинить цели урон психической энергией 1к6, если та находится в области иллюзии или в пределах 5 футов от иллюзии существа или опасности, способной причинять урон, например, атаковав. Цель воспринимает урон того вида, что соответствует цели.`,
    lvl: 2,
    magicSchoolId: MAGIC_ILLUSION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'кусок овечьей шерсти',
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_INT,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 1,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 218,
    },
  },
  {
    id: SPELL_PHANTASMAL_KILLER,
    name: 'Воображаемый убийца',
    nameEn: 'Phantasmal Killer',
    description: `Вы заглядываете в кошмары существа, видимого в пределах дистанции, и создаете иллюзорное проявление его страхов, видимое только ему.

Цель должна пройти испытание Мудрости. При провале цель становится испуганной до конца действия заклинания. В конце каждого своего хода, пока заклинание активно, цель должна преуспевать в испытании Мудрости, иначе она получает урон психической энергией 4к10. При успехе заклинание заканчивается.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, урон увеличивается на 1к10 за каждый круг ячейки выше четвертого.`,
    lvl: 4,
    magicSchoolId: MAGIC_ILLUSION,
    needConcentration: true,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_FRIGHTENED,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 4,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 218,
      },
      {
        id: SOURCE_SRD,
        page: 167,
      },
    ],
  },
  {
    id: SPELL_RESURRECTION,
    name: 'Воскрешение',
    nameEn: 'Ressurrection',
    description: `Вы касаетесь существа, мёртвого не больше ста лет, умершего не от старости, и не являющегося нежитью. Если его душа свободна и согласна, цель возвращается к жизни с полными хитами.

Это заклинание нейтрализует все яды и исцеляет немагические болезни, бывшие у существа в момент смерти. Однако это заклинание не снимает магические болезни, проклятья и подобные эффекты; если их не снять до накладывания этого заклинания, они снова начнут действовать, когда существо оживёт.

Это заклинание исцеляет все раны и восстанавливает отсутствующие части тела.

Возвращение к жизни — тяжёлое испытание. Цель получает штраф −4 ко всем броскам атаки, испытаниям и проверкам характеристик. Каждый раз, когда цель заканчивает длительный отдых, штраф уменьшается на 1, пока не исчезнет полностью.

Накладывание этого заклинания для оживления существа, мёртвого больше года, сильно выматывает Вас. Пока Вы не окончите длинный отдых, Вы не можете сотворять заклинания, и совершаете с помехой все броски атаки, проверки характеристик и испытания.`,
    lvl: 7,
    magicSchoolId: MAGIC_NECROMANCY,
    castTime: { timeId: TIME_HOUR, count: 1 },
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: 'бриллиант, стоящий как минимум 1 000 зм, расходуемый заклинанием',
    source: [
      {
        id: SOURCE_PHB,
        page: 218,
      },
      {
        id: SOURCE_SRD,
        page: 175,
      },
    ],
  },
  {
    id: SPELL_INCENDIARY_CLOUD,
    name: 'Воспламеняющаяся туча',
    nameEn: `Incendiary Cloud`,
    description: `Кружащееся облако дыма с ярко-белыми огоньками появляется в сфере с радиусом 20 футов с центром на точке, находящейся в пределах дистанции. Облако огибает углы и является сильно заслонённой местностью. Оно существует, пока активно заклинание, или пока его не развеет умеренный или сильный ветер (как минимум 10 миль в час).

Когда облако появляется, все существа, находящиеся в нём, должны пройти испытание Ловкости. Существо получает урон огнём 10к8 при провале или половину урона при успехе. Существа должны также проходить это испытание, когда впервые за ход входят в область заклинания или оканчивают там ход.

В начале каждого Вашего хода облако перемещается на 10 футов прочь от Вас в направлении, выбираемом Вами.`,
    lvl: 8,
    magicSchoolId: MAGIC_CONJURATION,
    range: 150,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_FIRE,
        halfOnSuccess: true,
        diceCount: 10,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 218,
      },
      {
        id: SOURCE_SRD,
        page: 157,
      },
    ],
  },
  {
    id: SPELL_COMPELLED_DUEL,
    name: 'Вызов на дуэль',
    nameEn: 'Compelled Duel',
    description: `Вы пытаетесь вызвать существо на дуэль.

Одно существо, которое Вы видите в пределах дистанции, должно пройти испытание Мудрости. При провале существо притягивается к вам, следуя Вашему божественному требованию. Пока заклинание активно, это существо совершает с помехой броски атаки по всем существам кроме Вас, и должно проходить испытание Мудрости каждый раз, когда пытается переместиться в пространство, находящееся более чем в 30 футах от Вас. При успехе это заклинание не ограничивает перемещения цели в этот ход.

Заклинание оканчивается, если Вы атакуете другое существо, если Вы активируете заклинание, нацеленное на враждебное существо, но не на цель, если дружественное вам существо причиняет цели урон или сотворяетна неё вредоносное заклинание, или если Вы оканчиваете ход далее, чем в 30 футах от цели.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 30,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: {
      id: SOURCE_PHB,
      page: 219,
    },
  },
  {
    id: SPELL_GREATER_INVISIBILITY,
    name: 'Высшая невидимость',
    nameEn: 'Greater Invisibility',
    description: `Вы или существо, которого Вы касаетесь, становитесь невидимым, пока активно заклинание.

Всё, что цель носит и несёт, становится невидимым, пока находится у неё.`,
    lvl: 4,
    magicSchoolId: MAGIC_ILLUSION,
    needConcentration: true,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      condition: CONDITION_INVISIBLE,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 219,
      },
      {
        id: SOURCE_SRD,
        page: 150,
      },
    ],
  },
  {
    id: SPELL_GREATER_RESTORATION,
    name: 'Высшее восстановление',
    nameEn: 'Greater Restoration',
    description: `Вы наделяете существо, которого касаетесь, позитивной энергией, отменяющей ослабляющие эффекты. Вы можете уменьшить уровень истощения цели на 1, или окончить на ней один из следующих эффектов:

* Один эффект, делающий цель очарованной или окаменевшей
* Одно проклятье, включая настройку на проклятый магический предмет
* Любое уменьшение одной из характеристик цели
* Один эффект, уменьшающий максимум хитов цели`,
    lvl: 5,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `бриллиантовая пыль, стоящая как минимум 100 зм, расходуемая заклинанием`,
    source: [
      {
        id: SOURCE_PHB,
        page: 219,
      },
      {
        id: SOURCE_SRD,
        page: 150,
      },
    ],
  },
  {
    id: SPELL_AUGURY,
    name: 'Гадание',
    nameEn: 'Augury',
    description: `Подбрасывая инкрустированные камнями палочки, драконьи кости, раскладывая карты или используя другие инструменты для ворожбы, Вы получаете знамение от иномировой сущности о результатах особого действия, которое Вы планируете совершить в течение следующих 30 минут. Мастер выбирает одно из следующих возможных знамений:

* *Благо*, для хороших результатов
* *Горе*, для плохих результатов
* *Благо и горе*, для результатов, одновременно хороших и плохих
* *Ничто*, для результатов, которые и не хорошие и не плохие

Заклинание не принимает в расчёт обстоятельства, способные изменить исход, такие как накладывание дополнительных заклинаний или потерю или обретение спутников.

Если Вы накладываете это заклинание несколько раз до завершения длинного отдыха, существует накопительный шанс 25 процентов за каждое использование, начиная со второго, что Вы получите случайный ответ. Мастер совершает этот бросок скрытно.`,
    lvl: 2,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `особые палочки, костяшки или фигурки с отметинами, стоящие как минимум 25 зм`,
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 219,
      },
      {
        id: SOURCE_SRD,
        page: 120,
      },
    ],
  },
  {
    id: SPELL_GASEOUS_FORM,
    name: 'Газообразная форма',
    nameEn: 'Gaseous Form',
    description: `Вы превращаете на время действия заклинания согласное существо, которого касаетесь, а также все, что оно носит и несет, в туманное облако. Заклинание оканчивается, если хиты существа опускаются до 0. Бестелесные существа не попадают под действие этого заклинания.

Пока цель находится в этой форме, единственной её скоростью перемещения становится скорость полёта 10 футов. Цель может входить в пространство других существ и оставаться там.

Существо получает сопротивление к немагическому урону, и с преимуществом проходит испытания Силы, Ловкости и Телосложения. Цель может проходить через небольшие отверстия, узкие щели, но жидкости для нее считаются твердыми поверхностями. Цель не может упасть и остается парить, даже если становится ошеломленной или недееспособной.

Находясь в форме туманного облака, цель не может говорить и манипулировать предметами, и все предметы, которые она несет или носит, нельзя ронять, использовать и вообще как-либо с ними взаимодействовать. Цель не может атаковать и сотворять заклинания.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    needConcentration: true,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'кусок марли и клуб дыма',
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 220,
      },
      {
        id: SOURCE_SRD,
        page: 148,
      },
    ],
  },
  {
    id: SPELL_HEROISM,
    name: 'Героизм',
    nameEn: `Heroism`,
    description: `Согласное существо, которого Вы касаетесь, наполняется храбростью.

Пока заклинание активно, существо получает иммунитет к состоянию испуга, и в начале каждого своего хода получает временные хиты, равные модификатору Вашей заклинательной характеристики.

Когда это заклинание оканчивается, цель теряет все временные хиты, оставшиеся от этого заклинания.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 220,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
    ],
  },
  {
    id: SPELL_HYPNOTIC_PATTERN,
    name: 'Гипнотический узор',
    nameEn: `Hypnotic Pattern`,
    description: `Вы создаёте хитрый разноцветный узор, переплетающийся в воздухе внутри куба с длиной ребра 30 футов, находящегося в пределах дистанции. Узор появляется на краткий миг и исчезает.

Все существа в этой области, видящие узор, должны пройти испытание Мудрости. При провале существо становится очарованным на время действия заклинания. Будучи очарованным этим заклинанием, существо недееспособно, и его скорость равна 0.

Заклинание заканчивается для отдельного существа, если оно получает урон или если кто-то другой действием потрясёт его, чтобы вывезти из ступора.`,
    lvl: 3,
    magicSchoolId: MAGIC_ILLUSION,
    range: 120,
    componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
    materialText: `зажжённая палочка благовоний или хрустальный сосуд, заполненный фосфоресцирующим материалом`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
      condition: [
        CONDITION_CHARMED,
        CONDITION_INCAPACITATED,
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 220,
      },
      {
        id: SOURCE_SRD,
        page: 155,
      },
    ],
  },
  {
    id: SPELL_BLINDNESS_DEAFNESS,
    name: 'Глухота/Слепота',
    nameEn: 'Blindness/Deafness',
    description: `Вы можете ослепить врага или сделать его глухим.

Выберите одно существо, которое видите в пределах дистанции, которое тут же проходит испытание Телосложения.

При провале цель становится на время действия заклинания ослепшей или оглохшей (на Ваш выбор). В конце каждого своего хода цель может проходить испытание Телосложения. При успехе заклинание оканчивается.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше второго.`,
    lvl: 2,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 30,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_CON,
      condition: [
        CONDITION_DEAFENED,
        CONDITION_BLINDED,
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 220,
      },
      {
        id: SOURCE_SRD,
        page: 122,
      },
    ],
  },
  {
    id: SPELL_FLAME_BLADE,
    name: 'Горящий клинок',
    nameEn: `Flame Blade`,
    description: `Вы создаёте горящий клинок в свободной руке. Этот клинок похож размерами и формой на скимитар, и он существует, пока заклинание активно. Если Вы выпустите клинок из рук, он исчезает, но Вы можете вернуть его в руку бонусным действием.

Вы можете действием совершить рукопашную атаку заклинанием. При попадании цель получает урон огнём 3к6.

Горящий клинок испускает яркий свет в пределах 10 футов и тусклый свет в пределах ещё 10 футов.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, урон увеличивается на 1к6 за каждые два уровня ячейки выше 2.`,
    lvl: 2,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `лист сумаха`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_SPELL,
      damage: {
        type: DAMAGE_FIRE,
        diceCount: 3,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 221,
      },
      {
        id: SOURCE_SRD,
        page: 145,
      },
    ],
  },
  {
    id: SPELL_ICE_STORM,
    name: 'Град',
    nameEn: `Ice Storm`,
    description: `На землю в цилиндре с радиусом 20 футов и высотой 40 футов, с центром на точке в пределах дистанции, падают куски льда.

Все существа в цилиндре должны пройти испытание Ловкости. Существо получает дробящий урон 2к8 и урон холодом 4к6 при провале, или половину этого урона при успехе.

Град превращает область воздействия в труднопроходимую местность до конца Вашего следующего хода.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, дробящий урон увеличивается на 1к8 за каждый круг ячейки выше четвёртого.`,
    lvl: 4,
    magicSchoolId: MAGIC_EVOCATION,
    range: 300,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `щепотка пыли и несколько капель воды`,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: [
        {
          type: DAMAGE_BLUDGEONING,
          halfOnSuccess: true,
          diceCount: 2,
          diceType: 8,
        },
        {
          type: DAMAGE_COLD,
          halfOnSuccess: true,
          diceCount: 4,
          diceType: 6,
        },
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 221,
      },
      {
        id: SOURCE_SRD,
        page: 155,
      },
    ],
  },
  {
    id: SPELL_HAIL_OF_THORNS,
    name: 'Град шипов',
    nameEn: `Hail of Thorns`,
    description: `В следующий раз, когда Вы попадёте по существу дальнобойной атакой оружием, пока активно это заклинание, из дальнобойного оружия или боеприпаса вылетит град шипов.

В дополнение к обычному эффекту атаки, цель и все существа в пределах 5 футов от неё, должны пройти испытание Ловкости.

При провале существо получает колющий урон 1к10, или половину этого урона при успехе.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к10 за каждый круг ячейки выше первого (до максимума 6к10).`,
    lvl: 1,
    magicSchoolId: MAGIC_CONJURATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_RANGE_WEAPON,
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_PIERCING,
        halfOnSuccess: true,
        diceCount: 1,
        diceType: 10,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 221,
    },
  },
  {
    id: SPELL_THUNDEROUS_SMITE,
    name: 'Громовая кара',
    nameEn: `Thunderous Smite`,
    description: `В первый раз, когда Вы попадаете рукопашной атакой оружием, пока заклинание активно, Ваше оружие издаёт громовой рокот, слышимый с расстояния 300 футов, и атака причиняет цели дополнительный урон звуком 2к6.

Кроме того, если цель — существо, она должна пройти испытание Силы, иначе она будет оттолкнута на 10 футов от Вас и сбита с ног.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_WEAPON,
      savethrowParam: PARAM_STR,
      condition: CONDITION_PRONE,
      damage: {
        type: DAMAGE_THUNDER,
        diceCount: 2,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 222,
    },
  },
  {
    id: SPELL_MOVE_EARTH,
    name: 'Движение почвы',
    nameEn: 'Move Earth',
    description: `Выберите в пределах дистанции область с длиной стороны не больше 40 фт. Пока заклинание активно, Вы можете менять поверхность земли, песка или глины. Вы можете увеличить или уменьшить высоту, создать или засыпать ров, поднять или сравнять стены, и даже формировать колонны. Высота таких изменений не может превышать половины самой большой стороны области. Таким образом, если Вы воздействуете на квадрат с длиной стороны 40 фт., Вы можете создать колонну высотой до 20 фт., поднять или опустить землю на расстояние до 20 фт., выкопать траншею глубиной до 20 фт., и так далее. Изменения вступают в силу в течение 10 минут.

В конце каждых 10 минут концентрации на этом заклинании Вы можете выбрать новую область, чтобы подействовать на неё.

Из-за того, что трансформация местности происходит медленно, обычно, существа не могут попасть в плен или получить урон из-за движений почвы.

Это заклинание не может манипулировать естественным камнем и каменными строениями. Камни и строения сдвигаются, приспосабливаясь к новой местности. Если изменение местности сделает строение неустойчивым, оно может разрушиться.

Это заклинание также не действует на растения. Перемещаемая почва уносит с собой и растения.`,
    lvl: 6,
    magicSchoolId: MAGIC_TRANSMUTATION,
    needConcentration: true,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'железный клинок и небольшая сумка со смесью из глины, перегноя и песка',
    duration: { timeId: TIME_HOUR, count: 2 },
    source: [
      {
        id: SOURCE_PHB,
        page: 222,
      },
      {
        id: SOURCE_SRD,
        page: 166,
      },
    ],
  },
  {
    id: SPELL_CLONE,
    name: 'Клон',
    nameAlt: 'Двойник',
    nameEn: `Clone`,
    note: {
      text: `Дорогой, но надёжный способ пленить врага, который любит воскрешаться. Добыть дюйм плоти врага, создать его клона в кандалах с кляпом во рту, убить врага. При желании — повторить.`,
      author: `Эспумизан Коликит, гоблин волшебник`,
    },
    description: `Это заклинание выращивает инертную копию живого существа, которое станет гарантией бессмертия. Этот клон формируется в запечатанном сосуде и вырастает до полного размера за 120 дней; Вы можете сделать так, что двойник будет более молодой версией этого существа. Он остаётся инертным и будет оставаться таким сколько угодно, пока сосуд остаётся целым.

В любое время после того, как двойник будет готов, когда настоящее существо умирает, его душа переносится в двойника, при условии, что она свободна и хочет вернуться. Двойник физически идентичен оригиналу и обладает той же индивидуальностью, воспоминаниями и умениями, но у него нет снаряжения оригинала. Тело исходного существа, если оно ещё существует, остаётся инертным, и его впоследствии нельзя оживить, так как душа обитает в другом месте.`,
    lvl: 8,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `алмаз, стоящий как минимум 1 000 зм, и как минимум 1 кубический дюйм плоти существа, расходуемый заклинанием, а также сосуд, стоящий как минимум 2 000 зм с запираемой крышкой и достаточно большой, чтобы вместить существо Среднего размера, например, огромная урна, гроб, яма в земле или кристаллический контейнер, заполненный солёной водой`,
    castTime: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 222,
      },
      {
        id: SOURCE_SRD,
        page: 125,
      },
    ],
  },
  {
    id: SPELL_DEMIPLANE,
    name: 'Демиплан',
    nameEn: `Demiplane`,
    description: `Вы создаёте тёмную дверь на твёрдой плоской поверхности, которую Вы видите в пределах дистанции. Дверь достаточно большая, чтобы существа Среднего размера могли свободно проходить через неё. Если дверь открыть, она будет вести в демиплан, который выглядит как пустая комната по 30 футов во всех измерениях, построенная из дерева или камня. Когда заклинание заканчивается, дверь исчезает, а существа и предметы, находящиеся в демиплане, остаются запертыми там, так как дверь исчезает и с другой стороны тоже.

Каждый раз, когда Вы накладываете это заклинание, Вы либо создаёте новый демиплан, либо соединяете дверь с демипланом, созданным предыдущим накладыванием этого заклинания. Кроме того, если Вы знаете природу и содержимое демиплана, созданного этим заклинанием другого существа, Вы можете соединить свою дверь с этим демипланом.`,
    lvl: 8,
    magicSchoolId: MAGIC_CONJURATION,
    range: 60,
    componentIdList: [CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 222,
      },
      {
        id: SOURCE_SRD,
        page: 134,
      },
    ],
  },
  {
    id: SPELL_DISSONANT_WHISPERS,
    name: 'Диссонирующий шёпот',
    nameEn: `Dissonant Whispers`,
    description: `Вы шёпотом пропеваете нестройную мелодию, которую слышит только выбранное Вами существо в пределах дистанции, и которая причиняет ему жуткую боль.

Цель должна пройти испытание Мудрости.

При провале она получает урон психической энергией 3к6 и должна немедленно реакцией переместиться прочь от Вас на расстояние, равное своей скорости. Существо не будет входить в очевидно опасные места, такие как огонь или яма.

При успехе цель получает половину урона и не должна отходить.

Глухое существо автоматически преуспевает в испытании.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL],
    effect: {
      savethrowParam: PARAM_WIT,
      damage: {
        type: DAMAGE_PSYCHIC,
        halfOnSuccess: false,
        diceCount: 3,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 223,
    },
  },
  {
    id: SPELL_BIGBY_S_HAND,
    name: 'Длань Бигби',
    nameAlt: 'Рука Бигби',
    nameEn: `Bigby’s Hand`,
    nameEnAlt: `Arcane hand`,
    description: `Вы создаёте мерцающую руку Большого размера из полупрозрачного силового поля в свободном пространстве, которое видите в пределах дистанции. Рука существует, пока активно заклинание, и она перемещается по Вашей команде, подражая движениям Вашей собственной руки.

Эта рука является предметом, у которого КД 20, а количество хитов равно максимуму Ваших хитов. Когда хиты руки опускаются до 0, заклинание оканчивается. У руки Сила 26 (+8), а Ловкость 10 (+0). Рука не заполняет своё пространство. Когда Вы накладываете это заклинание, а также в последующие ходы, но уже бонусным действием, Вы можете перемещать руку на 60 футов и создавать ей один из следующих эффектов:

**Могучая длань.** Рука пытается толкнуть существо, находящееся в пределах 5 футов от неё, в выбранном Вами направлении. Совершите проверку Силы руки, противопоставленную проверке Силы (Атлетика) цели. Если цель Среднего размера или меньше, Вы совершаете проверку с преимуществом. При успехе рука толкает цель на 5 футов плюс количество футов, равное модификатору базовой характеристики, умноженному на 5. Рука перемещается вместе с целью, оставаясь в пределах 5 футов от неё.

**Преграждающая длань.** Рука встаёт между Вами и выбранным Вами существом, пока Вы не отдадите руке другую команду. Рука перемещается так, что находится между Вами и целью, предоставляя вам от цели укрытие на половину. Цель не может пройти сквозь пространство руки, если её значение Силы не больше значения Силы руки. Если значение Силы у цели больше, чем у руки, она может подойти к вам сквозь руку, но пространство руки для цели считается труднопроходимой местностью.

**Сжатый кулак.** Рука ударяет одно существо или предмет в пределах 5 футов от себя. Совершите рукопашную атаку заклинанием за руку, используя свои параметры. При попадании цель получает урон силовым полем 4к8.

**Сжимающая длань.** Рука пытается схватить существо с размером Огромное или меньше, находящееся в пределах 5 футов от неё. При проверке захвата Вы используете значение Силы руки. Если размер цели Средний или меньше, Вы совершаете проверку с преимуществом. Пока рука держит цель в захвате, Вы можете бонусным действием сдавливать её рукой. Цель при этом получает дробящий урон 2к6 + модификатор Вашей базовой характеристики.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, за каждый круг ячейки выше пятого урон от сжатого кулака увеличивается на 2к8, а урон от сжимающей длани увеличивается на 2к6.`,
    lvl: 5,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `яичная скорлупа и перчатка из змеиной кожи`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: [
      {
        attackType: ATTACK_MELEE_SPELL,
        damage: {
          type: DAMAGE_FORCE,
          diceCount: 4,
          diceType: 8,
        },
      },
      {
        damage: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
        },
        condition: [
          CONDITION_GRAPPLED,
        ],
      },
    ],
    source: [
      {
        id: SOURCE_PHB,
        page: 223,
      },
      {
        id: SOURCE_SRD,
        page: 118,
      },
    ],
  },
  {
    id: SPELL_DAYLIGHT,
    name: 'Дневной свет',
    nameEn: `Daylight`,
    description: `Из точки, выбранной Вами в пределах дистанции, распространяется сфера света с радиусом 60 футов. Сфера излучает яркий свет и тусклый свет в пределах ещё 60 футов.

Если выбранная Вами точка находится на предмете, который Вы держите или который никто не несёт и не носит, то свет исходит из предмета и перемещается вместе с ним. Если полностью накрыть источник света непрозрачным предметом, например, чашей или шлемом, свет будет заблокирован.

Если часть зоны этого заклинания накрывает зону тьмы, созданной заклинанием с уровнем не выше 3, заклинание, создавшее тьму, рассеивается.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 223,
      },
      {
        id: SOURCE_SRD,
        page: 133,
      },
    ],
  },
  {
    id: SPELL_ARMOR_OF_AGATHYS,
    name: 'Доспех Агатиса',
    nameEn: `Armor of Agathys`,
    description: `Вас окружает защитное магическое поле, проявляющееся в виде призрачной изморози, покрывшей Вас и Ваше снаряжение. Вы получаете 5 временных хитов на период действия заклинания. Если существо попадает по вам рукопашной атакой, пока у Вас есть эти хиты, оно получает урон холодом 5.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, и временные хиты и урон холодом увеличиваются на 5 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_ABJURATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `чашка воды`,
    duration: { timeId: TIME_HOUR, count: 1 },
    effect: {
      damage: {
        type: DAMAGE_COLD,
        diceBonus: 5,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 223,
    },
  },
  {
    id: SPELL_MAGE_ARMOR,
    name: 'Доспехи мага',
    nameAlt: 'Магический доспех',
    nameEn: 'Mage Armor',
    description: `Вы касаетесь согласного существа, не носящего доспех, и на время действия заклинания его окутывает защитное магическое поле. Базовый КД существа становится равен 13 + его модификатор Ловкости. Заклинание оканчивается, если цель надевает доспех или Вы оканчиваете его действием.`,
    lvl: 1,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек выделанной кожи`,
    duration: { timeId: TIME_HOUR, count: 8 },
    source: [
      {
        id: SOURCE_PHB,
        page: 223,
      },
      {
        id: SOURCE_SRD,
        page: 160,
      },
    ],
  },
  {
    id: SPELL_SHATTER,
    name: 'Дребезги',
    nameEn: `Shatter`,
    description: `Громкий звонкий звук исходит из выбранной Вами точки в пределах дистанции. Все существа в сфере с радиусом 10 футов с центром на этой точке должны пройти испытание Телосложения. При провале существо получает урон звуком 3к8, или половину этого урона при успехе. Существа, изготовленные из неорганической материи, такой как камень, кристалл или металл, проходят это испытание с помехой.

Немагические предметы, которые не несут и не носят, тоже получают урон, если находятся в области заклинания.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше второго.`,
    lvl: 2,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек слюды`,
    effect: {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_THUNDER,
        halfOnSuccess: true,
        diceCount: 3,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 224,
      },
      {
        id: SOURCE_SRD,
        page: 178,
      },
    ],
  },
  {
    id: SPELL_TREE_STRIDE,
    name: 'Древесный путь',
    nameEn: `Tree Stride`,
    description: `Вы получаете способность входить в дерево и перемещаться через него в другое дерево того же вида, находящееся в пределах 500 футов. Оба дерева должны быть живыми, и размер обоих должен быть не меньше Вашего. Вы должны использовать 5 футов перемещения, чтобы войти в дерево. Вы мгновенно узнаёте местоположение всех других деревьев этого вида в пределах 500 футов, и частью этого же перемещения можете либо перейти в одно из этих деревьев, либо выйти из дерева, в котором Вы находитесь. Вы появляетесь в пространстве на свой выбор в пределах 5 футов от текущего дерева, используя ещё 5 футов перемещения. Если у Вас не осталось перемещения, Вы появляетесь в пределах 5 футов от дерева, в которое входили.

Вы можете использовать эту перемещающую способность один раз в раунд, пока активно заклинание. Каждый ход Вы должны заканчивать вне дерева.`,
    lvl: 5,
    magicSchoolId: MAGIC_CONJURATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 224,
      },
      {
        id: SOURCE_SRD,
        page: 188,
      },
    ],
  },
  {
    id: SPELL_DRAWMIJ_S_INSTANT_SUMMONS,
    name: 'Дромиджево появление',
    nameEn: `Drawmij’s Instant Summons`,
    nameEnAlt: `Instant summons`,
    description: `Вы касаетесь предмета, весящего не более 10 фунтов, и достигающего в длину не более 6 футов. Заклинание оставляет невидимую метку на его поверхности и невидимое название предмета на сапфире, использованном в качестве материального компонента. При каждом использовании этого заклинания Вы должны использовать новый сапфир.

В любой момент впоследствии Вы можете действием произнести название предмета и раздавить сапфир. Предмет мгновенно появляется в Вашей руке, вне зависимости от расстояния и планов, разделяющих Вас, а заклинание оканчивается.

Если этот предмет несёт или держит другое существо, уничтожение сапфира не переносит предмет к вам, но Вы узнаёте, кто им сейчас владеет, и где это существо сейчас примерно находится.

[Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) и аналогичные эффекты, успешно наложенные на сапфир, оканчивают эффект заклинания.`,
    lvl: 6,
    magicSchoolId: MAGIC_CONJURATION,
    range: 0,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `сапфир стоимостью 1 000 зм`,
    duration: Infinity,
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 224,
      },
      {
        id: SOURCE_SRD,
        page: 157,
      },
    ],
  },
  {
    id: SPELL_FRIENDS,
    name: 'Дружба',
    nameEn: `Friends`,
    description: `Пока заклинание активно, Вы совершаете с преимуществом все проверки Харизмы, направленные на одно выбранное Вами существо, не враждебное по отношению к вам. Когда заклинание оканчивается, существо понимает, что Вы влияли на её отношение с помощью магии, и становится враждебным по отношению к вам. Существо, склонное к насилию, может напасть на Вас. Другие могут требовать другого возмездия (решает Мастер), в зависимости от отношений, сложившихся между Вами.`,
    lvl: 0,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: -1,
    componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
    materialText: `небольшое количество грима, наносимое на лицо при накладывании этого заклинания`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: {
      id: SOURCE_PHB,
      page: 225,
    },
  },
  {
    id: SPELL_ANIMAL_FRIENDSHIP,
    name: 'Дружба с животными',
    nameAlt: 'Общение с животными',
    nameEn: 'Animal Friendship',
    description: `Это заклинание позволяет убедить зверя, что Вы не намерены причинять ему вред.

Выберите зверя, которого Вы видите в пределах дистанции. Он должен видеть и слышать Вас. Если у зверя Интеллект 4 или больше, заклинание проваливается. В противном случае зверь должен пройти испытание Мудрости, иначе он станет очарованным на время действия заклинания.

Если Вы или один из Ваших спутников причинит цели вред, заклинание окончится.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы можете воздействовать на одного дополнительного зверя за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек пищи`,
    note: {
      text: `Забавно, что дружить с животными можно с 30 футов. При этом можно помахать кусочком пищи и это убедит зверя что ты — друг. Потом пищу можно спрятать и зверю не отдавать — заклинание совершенно не требует ей делиться.`,
      author: `Эспумизан Коликит, гоблин волшебник`,
    },
    duration: { timeId: TIME_HOUR, count: 24 },
    effect: {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_CHARMED,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 225,
      },
      {
        id: SOURCE_SRD,
        page: 115,
      },
    ],
  },
  {
    id: SPELL_BARKSKIN,
    name: 'Дубовая кора',
    nameEn: 'Barkskin',
    description: `Вы касаетесь согласного существа. Пока заклинание активно, кожа цели становится грубой и похожей внешне на кору дуба, а КД не может быть ниже 16, вне зависимости от надетых доспехов.`,
    lvl: 2,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `пригоршня дубовой коры`,
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 225,
      },
      {
        id: SOURCE_SRD,
        page: 121,
      },
    ],
  },
  {
    id: SPELL_SPIRIT_GUARDIANS,
    name: 'Духовные стражи',
    nameEn: 'Spirit Guardians',
    description: `Вы вызываете духов, защищающих Вас. Пока заклинание активно, они заполняют пространство в пределах 15 футов от Вас. Если Вы добрый или нейтральный, они могут выглядеть как ангелы или феи (на Ваш выбор). Если Вы злой, они могут выглядеть исчадиями.

Когда Вы активируете это заклинание, Вы можете указать любое количество существ, видимых Вами, на которых и будет действовать это заклинание. Скорость этих существ в этой области уменьшена вдвое, и когда такое существо впервые за ход входит в область или начинает там ход, оно должно пройти испытание Мудрости. При провале существо получает урон излучением 3к8 (если Вы добрый или нейтральный) или урон некротической энергией 3к8 (если Вы злой). При успехе существо получает половину урона.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше третьего.`,
    lvl: 3,
    magicSchoolId: MAGIC_CONJURATION,
    range: -1,
    rangeComment: '15-футовый радиус',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `символ веры`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
      damage: {
        type: [
          DAMAGE_NECROTIC,
          DAMAGE_RADIANT,
        ],
        halfOnSuccess: true,
        diceCount: 3,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 225,
      },
      {
        id: SOURCE_SRD,
        page: 182,
      },
    ],
  },
  {
    id: SPELL_BEAST_SENSE,
    name: 'Животные чувства',
    nameEn: `Beast Sense`,
    description: `Вы касаетесь согласного зверя. Пока заклинание активно, Вы можете действием посмотреть глазами этого зверя и услышать то, что слышит он. Эффект продолжается, пока Вы не переключитесь действием обратно на свои чувства. Воспринимая чувствами зверя, Вы пользуетесь преимуществами всех его особых чувств, хотя для своего окружения Вы считаетесь ослеплённым и оглохшим.`,
    lvl: 2,
    magicSchoolId: MAGIC_DIVINATION,
    range: 0,
    isRitual: true,
    componentIdList: [CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: {
      id: SOURCE_PHB,
      page: 225,
    },
  },
  {
    id: SPELL_CORDON_OF_ARROWS,
    name: 'Завеса стрел',
    nameEn: `Cordon of Arrows`,
    description: `Вы втыкаете четыре немагических боеприпаса — стрелы или арбалетные болты — в землю в пределах дистанции и накладываете на них заклинание, защищающее область. Пока заклинание активно, каждый раз, когда другое существо кроме Вас впервые за ход оказывается в пределах 30 футов от боеприпасов или оканчивает там ход, один боеприпас вылетает и атакует его. Существо должно пройти испытание Ловкости, иначе оно получит колющий урон 1к6. Боеприпас при этом уничтожается. Заклинание заканчивается, когда кончаются боеприпасы.

Накладывая это заклинание, Вы можете указать любых существ, которых это заклинание будет игнорировать.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, количество боеприпасов увеличивается на два за каждый круг ячейки выше второго.`,
    lvl: 2,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 5,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `как минимум четыре стрелы или арбалетных болта`,
    duration: { timeId: TIME_HOUR, count: 8 },
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_PIERCING,
        diceCount: 1,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 226,
    },
  },
  {
    id: SPELL_PROGRAMMED_ILLUSION,
    name: 'Заданная иллюзия',
    nameEn: `Programmed Illusion`,
    description: `Вы создаёте иллюзию предмета, существа или другого видимого явления в пределах дистанции, активирующуюся при выполнении особых условий. Пока условия не выполнены, иллюзия будет неразличима. Она должна помещаться в куб с длиной ребра 30 футов, и Вы при накладывании заклинания определяете, как иллюзия будет себя вести, и какие звуки она будет издавать. Заготовленные действия могут длиться до 5 минут.

Когда указанное Вами условие будет выполнено, иллюзия проявляется и выполняет описанные Вами действия. Как только иллюзия закончит действовать, она исчезает и остаётся спящей 10 минут. После этого иллюзия может быть снова активирована.

Условие может быть простым или замысловатым, но оно должно основываться на видимом или слышимом событии, способном произойти в пределах 30 футов от области. Например, Вы можете создать иллюзию себя, появляющегося и предупреждающего тех, кто попытается открыть дверь с ловушкой, или же иллюзия может проявляться только когда существо произносит правильное слово или фразу.

Физическое взаимодействие с образом даёт понять, что это иллюзия, потому что сквозь него всё проходит. Существа, исследующие образ действием, могут определить, что это иллюзия, совершив успешную проверку Интеллекта (Анализ) против СЛ Ваших заклинаний. Если существо распознаёт иллюзию, оно может видеть сквозь неё, и издаваемые ей звуки тоже становятся незначащими.`,
    lvl: 6,
    magicSchoolId: MAGIC_ILLUSION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `кусок овечьей шерсти и порошок нефрита, стоящий как минимум 25 зм`,
    duration: Infinity,
    source: [
      {
        id: SOURCE_PHB,
        page: 226,
      },
      {
        id: SOURCE_SRD,
        page: 172,
      },
    ],
  },
  {
    id: SPELL_SLOW,
    name: 'Замедление',
    nameEn: `Slow`,
    description: `Вы изменяете время для шести существ на Ваш выбор, находящихся в кубе с длиной ребра 40 футов в пределах дистанции. Все цели должны пройти испытание Мудрости, иначе они попадут под действие заклинания на время его длительности.

Скорость попавшего под действие существа уменьшатся вдвое, оно получает штраф −2 к КД и испытаниям Ловкости, и оно не может совершать реакции. В свой ход оно может использовать либо действие, либо бонусное действие, и не может использовать сразу оба. Вне зависимости от умений и магических предметов, оно не может в свой ход совершать более одной рукопашной или дальнобойной атаки.

Если существо попытается активировать заклинание со временем накладывания 1 действие, бросьте к20. Если выпадет «11» или больше, заклинание не вступит в силу до его следующего хода, и в том ходу оно должно будет использовать действие для завершения заклинания. Если оно этого не сделает, заклинание будет потрачено.

Существо, находящееся под действием этого заклинания, совершает новые испытания Мудрости в конце каждого своего хода. При успехе эффект для него оканчивается.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `капля патоки`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 226,
      },
      {
        id: SOURCE_SRD,
        page: 180,
      },
    ],
  },
  {
    id: SPELL_DELAYED_BLAST_FIREBALL,
    name: 'Замедленный огненный шар',
    nameEn: `Delayed Blast Fireball`,
    description: `Из Вашего указательного пальца вылетает луч жёлтого света, концентрирующийся в светящемся шарике в пределах дистанции. Когда заклинание оканчивается, либо из-за того, что Вы теряете концентрацию, либо когда Вы сами это решите, шарик взрывается с гулким рокотом вспышкой пламени, огибающего углы. Все существа в сфере с радиусом 20 футов с центром в этой точке должны пройти испытание Ловкости. При провале существо получает урон огнём, равный сумме накопленного урона, или половине этого урона в случае успеха.

Базовый урон этого заклинания 12к6. В конце каждого Вашего хода, пока шарик не сдетонировал, урон увеличивается на 1к6.

Если светящегося шарика кто-то коснётся до того как он взорвётся, коснувшееся существо должно пройти испытание Ловкости. При провале заклинание мгновенно оканчивается, а шарик взрывается. При успешном спасении существо может отбросить шарик на 40 футов. Ударившись о существо или твёрдый предмет, шарик взрывается, оканчивая заклинание.

Огонь причиняет урон предметам и воспламеняет горючие предметы, которые никто не несёт и не носит.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 8 круга или выше, базовый урон увеличивается на 1к6 за каждый круг ячейки выше седьмого.`,
    lvl: 7,
    magicSchoolId: MAGIC_EVOCATION,
    range: 150,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `крошечный шарик из гуано летучей мыши и серы`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_FIRE,
        halfOnSuccess: true,
        diceCount: 12,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 226,
      },
      {
        id: SOURCE_SRD,
        page: 133,
      },
    ],
  },
  {
    id: SPELL_IMPRISONMENT,
    name: 'Заточение',
    nameEn: `Imprisonment`,
    description: `Вы создаёте магические оковы, удерживающие существо, видимое Вами в пределах дистанции. Цель должна пройти испытание Мудрости, иначе она станет скованной заклинанием; при успехе цель получает иммунитет к этому заклинанию, если Вы будете сотворять его повторно. Находясь под действием этого заклинания, существо не обязано дышать, есть и пить, и оно не стареет. Заклинания школы Прорицания не могут найти или почувствовать цель.

# Виды заточения

При накладывании этого заклинания Вы выбираете один из следующих видов заточения:

## Захоронение
Цель становится заточённой глубоко под землёй в сфере из магической силы, достаточно большой, чтобы цель могла в ней поместиться. Ничто не может проникнуть сквозь эту сферу, и никакие существа не могут при помощи телепортации или путешествий между планами проникнуть внутрь или наружу.

Особым компонентом для этой версии заклинания является небольшая мифриловая сфера.

## Миниатюризация
Цель уменьшается до высоты 1 дюйм (2,5 сантиметра), и становится заточённой в драгоценном камне или подобном предмете. Свет проходит через камень как обычно (что позволяет цели видеть, а другим существам видеть её), но больше сквозь него ничто не проходит, даже за счёт телепортации и планарных путешествий. Пока заклинание активно, этот камень нельзя ни разрезать, ни сломать.\nОсобым компонентом для этой версии заклинания является большой прозрачный драгоценный камень, такой как корунд, алмаз или рубин.

## Ограждённая тюрьма
Заклинание переносит цель в крохотный демиплан, защищённый от телепортации и планарных путешествий. Это может быть лабиринт, клетка, башня, или другое подобное укреплённое строение или местность.

Особым компонентом для этой версии заклинания является миниатюрная копия тюрьмы, изготовленная из нефрита.

## Оцепенение
Цель засыпает, и её нельзя разбудить. Особым компонентом для этой версии заклинания являются редкие снотворные травы.

## Сковывание
Цель удерживают на месте толстые цепи, крепко связанные с землёй, удерживающие цель на месте. Цель обездвижена, пока заклинание активно, и в это время она не может перемещаться и каким-либо образом быть перемещённой.

Особым компонентом для этой версии заклинания является тонкая цепочка из драгоценного металла.

# Окончание заклинания

Во время накладывания этого заклинания, какую бы версию Вы не выбрали, Вы можете указать условие, оканчивающее заклинание и отпускающее цель. Условие может быть простым или замысловатым, но Мастер должен признать условие разумным и вероятным. Условия могут основываться на имени существа, его личности, или его божестве, но во всём остальном должны полагаться на обозримые действия или качества, и не должны основываться на таких неосязаемых факторах как уровень, класс или хиты.

Заклинание [рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) может окончить это заклинание только если будет наложено заклинанием 9 уровня, нацеливаясь либо на тюрьму, либо на особый компонент, использовавшийся для её создания.

Вы можете использовать конкретный особый компонент для создания только одной тюрьмы одновременно. Если Вы активируете это заклинание ещё раз, используя тот же компонент, предыдущая цель тут же освобождается.`,
    lvl: 9,
    magicSchoolId: MAGIC_ABJURATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `рисунок или резная статуэтка, изображающая цель, и особый компонент, зависящий от выбранной версии заклинания, стоящий как минимум 500 зм за каждую Кость Хитов цели`,
    duration: Infinity,
    castTime: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 227,
      },
      {
        id: SOURCE_SRD,
        page: 156,
      },
    ],
  },
  {
    id: SPELL_BLADE_WARD,
    name: 'Защита от оружия',
    nameEn: 'Blade Ward',
    description: `Вы протягиваете руку и рисуете в воздухе ограждающий знак. Вы получаете до конца своего следующего хода сопротивление дробящему, колющему и рубящему урону, причинённому атаками оружием.`,
    lvl: 0,
    magicSchoolId: MAGIC_ABJURATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_ROUND, count: 1 },
    source: {
      id: SOURCE_PHB,
      page: 228,
    },
  },
  {
    id: SPELL_DEATH_WARD,
    name: 'Защита от смерти',
    nameEn: `Death Ward`,
    description: `Вы касаетесь существа и даруете ему некоторую защиту от смерти.

Когда хиты цели впервые опускаются до 0 из-за получения урона, хиты цели опускаются всего лишь до 1, и заклинание заканчивается.

Если заклинание ещё действует, а цель попадает под действие эффекта, убивающего её мгновенно, не причиняя урона, то этот эффект игнорируется, и заклинание заканчивается.`,
    lvl: 4,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 8 },
    source: [
      {
        id: SOURCE_PHB,
        page: 228,
      },
      {
        id: SOURCE_SRD,
        page: 133,
      },
    ],
  },
  {
    id: SPELL_PROTECTION_FROM_ENERGY,
    name: 'Защита от энергии',
    nameEn: `Protection from Energy`,
    description: `Пока заклинание активно, согласное существо, которого Вы коснулись, получает сопротивление к одному виду урона на Ваш выбор: звук, кислота, огонь, холод или электричество.`,
    lvl: 3,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 228,
      },
      {
        id: SOURCE_SRD,
        page: 173,
      },
    ],
  },
  {
    id: SPELL_PROTECTION_FROM_POISON,
    name: 'Защита от яда',
    nameEn: 'Protection from Poison',
    description: `Вы касаетесь существа. Если оно отравлено, Вы нейтрализуете яд. Если на цель действуют несколько ядов, Вы нейтрализуете тот, о котором знаете, либо же один случайным образом выбранный яд.

Пока заклинание активно, цель с преимуществом проходит испытания от отравленного состояния и получает сопротивление к урону ядом.`,
    lvl: 2,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 228,
      },
      {
        id: SOURCE_SRD,
        page: 173,
      },
    ],
  },
  {
    id: SPELL_EARTHQUAKE,
    name: 'Землетрясение',
    nameEn: 'Earthquake',
    description: `Вы создаёте сейсмические возмущения в точке на земле, которую видите в пределах дистанции. Пока заклинание активно, на земле в круге с радиусом 100 футов от этой точки ощущаются интенсивные толчки, сотрясающие существ и строения, находящиеся там.

Местность в этой области становится труднопроходимой. Все существа, находящиеся на земле и концентрирующиеся при этом, должны пройти испытание Телосложения. При провале концентрация существа нарушается.

Когда Вы накладываете это заклинание, а также в конце каждого хода, когда Вы на нём концентрируетесь, все существа, находящиеся на земле в области заклинания, должны проходить испытания Ловкости. При провале существо сбивается с ног.

У этого заклинания могут быть другие эффекты, зависящие от местности, на усмотрение Мастера.

**Трещины.** В области заклинания в начале Вашего хода, следующего за тем, в котором Вы наложили это заклинание, появляются трещины. Появляется 1к6 трещин в местах, определённых Мастером. Каждая 1к10 × 10 футов глубиной, 10 футов в ширину, а длиной от одного края заклинания до другого. Существо, стоящее в месте, где должна появиться трещина, должно пройти испытание Ловкости, иначе упадёт внутрь. Успешно спасшееся существо перемещается вместе с одним из краёв трещины.

Трещины, открывающиеся под зданиями, заставляют их автоматически обрушиться (смотрите ниже).

**Здания.** Толчки причиняют дробящий урон 50 строениям, находящимся в контакте с землёй, когда Вы накладываете это заклинание, а также в начале каждого Вашего хода, пока заклинание активно. Если хиты здания опускаются до 0, оно обрушивается, и потенциально может причинить урон существам. Существо, находящееся в пределах половины высоты здания, должно пройти испытание Ловкости. При провале существо получает дробящий урон 5к6, сбивается с ног и погребается под завалами, после чего для высвобождения требуется проверка Силы (Атлетика) СЛ 20, совершённая действием. Мастер может корректировать Сл, в зависимости от характера обломков. При успешном спасении существо получает половину урона, не сбивается с ног и не погребается. `,
    lvl: 8,
    magicSchoolId: MAGIC_EVOCATION,
    range: 500,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `щепотка земли, кусочек камня и комочек глины`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
      condition: CONDITION_PRONE,
      damage: {
        type: DAMAGE_BLUDGEONING,
        halfOnSuccess: true,
        diceCount: 5,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 228,
      },
      {
        id: SOURCE_SRD,
        page: 139,
      },
    ],
  },
  {
    id: SPELL_VICIOUS_MOCKERY,
    name: 'Злая насмешка',
    nameEn: 'Vicious Mockery',
    description: `Вы испускаете на существо, видимое в пределах дистанции, поток оскорблений вперемешку с тонкой магией. Если цель слышит Вас (при этом она не обязана Вас понимать), она должна пройти испытание Мудрости, иначе получит урон психической энергией 1к4, и следующий бросок атаки до конца своего следующего хода совершит с помехой.`,
    highLvlCast: `Урон этого заклинания увеличивается на 1к4, когда Вы достигаете 5 уровня (2к4), 11 уровня (3к4) и 17 уровня (4к4).`,
    lvl: 0,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL],
    effect: {
      savethrowParam: PARAM_WIT,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 1,
        diceType: 4,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 229,
      },
      {
        id: SOURCE_SRD,
        page: 189,
      },
    ],
  },
  {
    id: SPELL_LEGEND_LORE,
    name: 'Знание легенд',
    nameEn: 'Legend Lore',
    description: `Назовите или опишите личность, место или вещь. Заклинание создаёт в Вашем сознании краткое описание важных знаний о данном предмете. Это могут быть текущие разговоры, забытые истории, или даже тайные знания, никогда не выходящие за рамки определённого круга посвящённых. Если названный предмет не обладает легендарной значимостью, Вы не получаете информацию. Чем больше информации о выбранном предмете у Вас уже есть, тем точнее и детальнее будет полученная Вами информация.

Полученная информация правдива, но может быть завуалирована. Например, если Вы нашли таинственный топор, заклинание может выдать такую информацию: «Горе злодею, коснувшемуся этого топора, ибо даже древко его способно отрубить руки негодяю. Только истинный Сын Камня, почитатель и любимец Морадина, может пробудить истинную силу этого топора, и то только если назовёт священное слово _Рудногг_».`,
    lvl: 5,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `благовония, стоящие как минимум 250 зм, расходуемые заклинанием, и четыре пластинки из слоновой кости, стоящие как минимум 50 зм каждая`,
    castTime: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 230,
      },
      {
        id: SOURCE_SRD,
        page: 158,
      },
    ],
  },
  {
    id: SPELL_BANISHMENT,
    name: 'Изгнание',
    nameEn: 'Banishment',
    description: `Вы пытаетесь послать одно существо, которое видите в пределах дистанции, на другой план существования. Цель должна пройти испытание Харизмы, иначе будет изгнана.

Если цель родом с того плана существования, на котором находитесь вы, Вы изгоняете её в безопасный демиплан. Находясь там, цель недееспособна. Она остаётся там, пока активно заклинание, после чего возвращается в пространство, из которого исчезла, или ближайшее свободное пространство, если то место занято.

Если цель родом не с того плана существования, на котором находитесь вы, цель изгоняется с негромким хлопком, возвращаясь на свой родной план. Если заклинание окончится раньше чем через 1 минуту, цель возвращается в пространство, из которого исчезла, или ближайшее свободное пространство, если то место занято. В противном случае цель не возвращается.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше четвёртого.`,
    lvl: 4,
    magicSchoolId: MAGIC_ABJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `неприятный для цели предмет`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_CHA,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 230,
      },
      {
        id: SOURCE_SRD,
        page: 120,
      },
    ],
  },
  {
    id: SPELL_BANISHING_SMITE,
    name: 'Изгоняющая кара',
    nameEn: `Banishing Smite`,
    description: `В следующий раз, когда Вы попадёте по существу атакой оружием, пока активно это заклинание, Ваше оружие покрывается силовым полем, и атака причиняет цели дополнительный урон силовым полем 5к10. Кроме того, если эта атака опустила хиты цели до 50 или ниже, Вы изгоняете её. Если цель родом не с того плана существования, на котором сейчас находитесь вы, цель исчезает, возвращаясь на свой родной план. Если цель родом с того плана, на котором находитесь вы, она исчезает в безопасном демиплане. Находясь там, цель недееспособна. Она остаётся там, пока активно заклинание, после чего возвращается в пространство, из которого исчезла, или ближайшее свободное пространство, если то место занято.`,
    lvl: 5,
    magicSchoolId: MAGIC_ABJURATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_WEAPON,
      damage: {
        type: DAMAGE_FORCE,
        diceCount: 5,
        diceType: 10,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 230,
    },
  },
  {
    id: SPELL_FABRICATE,
    name: 'Изготовление',
    nameEn: `Fabricate`,
    description: `Вы превращаете сырьё в готовое изделие из этого материала. Например, Вы можете создать деревянный мост из нескольких деревьев, верёвку из груды пеньки, или одежду изо льна или шерсти.

Выберите сырьё, которое видите в пределах дистанции. Вы можете создать предмет Большого или меньшего размера (помещающийся в куб с длиной ребра 10 футов, или восемь соединённых кубов с длиной ребра 5 футов), при условии, что сырья хватит. Если Вы работаете с металлом, камнем или другой минеральной материей, создаваемый предмет не может быть больше Среднего размера (должен помещаться в один куб с длиной ребра 5 футов). Качество предметов, изготавливаемых этим заклинанием, напрямую зависит от качества сырья.

Этим заклинанием нельзя создавать и изменять существ и магические предметы. Вы также не можете создавать с его помощью предметы, обычно требующие высокого мастерства, такие как ювелирные изделия, оружие, стекло или доспех, если только Вы не владеете ремесленным инструментом, необходимым для создания таких предметов`,
    lvl: 4,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    castTime: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 230,
      },
      {
        id: SOURCE_SRD,
        page: 141,
      },
    ],
  },
  {
    id: SPELL_MODIFY_MEMORY,
    name: 'Изменение памяти',
    nameEn: `Modify Memory`,
    description: `Вы пытаетесь изменить воспоминания другого существа. Одно существо, которое Вы видите, должно пройти испытание Мудрости. Если Вы с ним сражаетесь, оно проходит испытание с преимуществом. При провале цель становится очарованной Вами на время действия заклинания. Очарованная цель недееспособна и не осознаёт окружение, но всё ещё может слышать Вас. Если она получит урон или станет целью другого заклинания, это заклинание оканчивается, и воспоминания цели не изменяются.

Пока длится это очарование, Вы можете повлиять на воспоминание цели, произошедшее за последние 24 часа и длящееся не больше 10 минут. Вы можете полностью стереть память об этом событии, позволить цели вспомнить всё с потрясающей точностью, изменить детали этого события, или заменить это воспоминание на что-то совершенно иное.

Вы должны произнести цели её новые воспоминания, и она должна понимать Ваш язык, чтобы изменённые воспоминания заняли место. Её сознание само заполнит пробелы в деталях Вашего описания. Если заклинание оканчивается до того как Вы закончите описывать новые воспоминания, память существа не будет затронута. В противном случае изменённые воспоминания вступают в силу в конце действия заклинания.

Изменённая память не обязательно будет влиять на поведение существа, особенно если она противоречит его наклонностям, мировоззрению или вере. Нелогичная изменённая память, такая как внедрение воспоминаний о том, как существу нравится купаться в кислоте, отбрасываются, как дурной сон. Мастер может посчитать, что изменённая память слишком абсурдная, чтобы влиять на существо в значительной степени.

Заклинания [снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) и [высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}), наложенные на цель, восстанавливают её истинную память.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, Вы можете изменить воспоминания о событиях, произошедших до 7 дней назад (6 уровень), 30 дней назад (7 уровень), 1 года назад (8 уровень), или в любой момент в жизни существа (9 уровень).`,
    lvl: 5,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 231,
      },
      {
        id: SOURCE_SRD,
        page: 166,
      },
    ],
  },
  {
    id: SPELL_REVERSE_GRAVITY,
    name: 'Изменение тяготения',
    nameEn: `Reverse Gravity`,
    description: `Это заклинание обращает гравитацию в цилиндре с радиусом 50 футов и высотой 100 футов с центром на точке в пределах дистанции. Все существа и предметы, не прикреплённые к полу, падают вверх до верхушки цилиндра, когда Вы накладываете это заклинание. Существо может пройти испытание Ловкости, чтобы схватиться за закреплённый предмет, и тем самым избежать падения.

Если в процесс падения на пути встречается твёрдый предмет (такой как потолок), ударившиеся об него существа и предметы получают обычный урон как от падения вниз. Если существо или предмет достигают верха, ничего не встретив, они остаются там, немного покачиваясь, на период действия заклинания.

В конце длительности заклинания существа и предметы падают вниз.`,
    lvl: 7,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 100,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `магнетит и железные опилки`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 231,
      },
      {
        id: SOURCE_SRD,
        page: 175,
      },
    ],
  },
  {
    id: SPELL_STONE_SHAPE,
    name: 'Изменение формы камня',
    nameEn: 'Stone Shape',
    description: `Вы касаетесь каменного предмета с размером не больше Среднего, или камня, не превышающего ни по одному из измерений более 5 фт., и придаете ему любую форму. Так, например, Вы можете превратить большой камень в оружие, статую или гроб, или создать небольшой проход в стене, если эта стена толщиной меньше 5 фт. Вы можете также скорректировать каменную дверь или ее обрамление, чтобы запереть наглухо. У создаваемого Вами предмета могут быть две дверные петли и щеколда, но более мелкие механические детали невозможны.`,
    lvl: 4,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'мягкая глина, из которой делается грубая копия желаемого каменного предмета',
    source: [
      {
        id: SOURCE_PHB,
        page: 231,
      },
      {
        id: SOURCE_SRD,
        page: 183,
      },
    ],
  },
  {
    id: SPELL_SEQUESTER,
    name: 'Изоляция',
    nameEn: `Sequester`,
    description: `При помощи этого заклинания согласное существо или предмет можно защитить на время действия заклинания от обнаружения. Когда Вы накладываете это заклинание и касаетесь цели, она становится невидимой, и не может стать целью заклинаний школы Прорицания, а также не обнаруживается сенсорами, созданными заклинаниями школы Прорицания.

Если цель — существо, оно входит в состояние приостановленной жизнедеятельности. Время для него не течёт, и оно не стареет.

Вы можете определить условия, при которых заклинание окончится. Это может быть что угодно, но оно должно произойти в пределах 1 мили от цели. Примерами могут стать «через 1000 лет» и «когда проснётся тараск». Это заклинание также оканчивается, если цель получает урон.`,
    lvl: 7,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `смесь порошков из бриллианта, изумруда, рубина и сапфира, стоящая как минимум 5 000 зм, расходуемая заклинанием`,
    duration: Infinity,
    source: [
      {
        id: SOURCE_PHB,
        page: 231,
      },
      {
        id: SOURCE_SRD,
        page: 178,
      },
    ],
  },
  {
    id: SPELL_DRUIDCRAFT,
    name: 'Искусство друидов',
    nameEn: 'Druidcraft',
    description: `Пошептавшись с духами природы, Вы создаёте один из следующих эффектов в пределах дистанции:

* Вы создаёте крохотный безвредный ощутимый эффект, предсказывающий погоду в текущем месте в течение следующих 24 часов. Это может быть золотистый шарик, означающий ясную погоду, облачко, означающее дождь, снежинка, и так далее. Эффект длится 1 раунд.
* Вы мгновенно заставляете цветок распуститься, семечко прорасти, или почку раскрыться.
* Вы создаёте мгновенный безвредный ощутимый эффект, такой как падающие листья, порыв ветра, звук маленького животного, или слабый запах скунса. Эффект должен находиться в кубе с длиной ребра 5 футов.
* Вы мгновенно зажигаете или тушите свечу, факел или небольшой костёр.`,
    lvl: 0,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: [
      {
        id: SOURCE_PHB,
        page: 231,
      },
      {
        id: SOURCE_SRD,
        page: 138,
      },
    ],
  },
  {
    id: SPELL_TRUE_SEEING,
    name: 'Истинное зрение',
    nameEn: 'True Seeing',
    description: `Заклинание даёт согласному существу, которого Вы коснулись, способность видеть вещи такими, какими они являются на самом деле. Пока заклинание активно, это существо обладает истинным зрением, видит потайные двери, сокрытые магией, и его зрение простирается на Эфирный План, и всё это в пределах 120 футов.`,
    lvl: 6,
    magicSchoolId: MAGIC_DIVINATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `мазь для глаз, стоящая 25 зм; изготавливается из грибного порошка, шафрана и жира; расходуется заклинанием`,
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 232,
      },
      {
        id: SOURCE_SRD,
        page: 189,
      },
    ],
  },
  {
    id: SPELL_STONESKIN,
    name: 'Каменная кожа',
    nameEn: `Stoneskin`,
    description: `Это заклинание делает плоть согласного существа, которого Вы коснулись, твёрдой как камень. Пока заклинание активно, цель получает сопротивление к немагическому дробящему, колющему и рубящему урону.`,
    lvl: 4,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `пыль бриллианта, стоящего 100 зм, расходуемая заклинанием`,
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 233,
      },
      {
        id: SOURCE_SRD,
        page: 183,
      },
    ],
  },
  {
    id: SPELL_WALL_OF_STONE,
    name: 'Каменная стена',
    nameEn: 'Wall of Stone',
    description: `В точке, выбранной Вами в пределах дистанции, появляется немагическая стена из камня. Стена толщиной 6 дюймов (15 сантиметров) и составлена из десяти плит 10 × 10 фт. Каждая плита должна соседствовать как минимум с одной другой плитой. В качестве альтернативы, Вы можете создать плиты 10 × 20 фт., но толщиной только 3 дюйма (7,5 сантиметров).

Если стена при появлении проходит по пространству существа, это существо выталкивается по одну из сторон стены (на Ваш выбор). Если существо со всех сторон окружается стеной (или стеной и другой сплошной поверхностью), это существо может пройти испытание Ловкости. При успехе оно может реакцией переместиться на расстояние, равное своей скорости, чтобы не быть окружённым стеной.

Стена может иметь любую форму, хотя и не может занимать одно пространство с существами и предметами. Стена не обязана быть вертикальной, и не обязана целиком покоиться на твёрдом основании. Однако, она должна сливаться с существующим камнем, опираясь на него. Таким образом, Вы можете создать этим заклинанием мост через пропасть или наклонный спуск.

Если Вы создаёте пролёт длиной больше 20 фт., Вы должны уменьшить вдвое размер каждой плиты, чтобы создать поддержку. Вы можете придавать стене грубую форму, создавая бойницы, зубцы, и так далее.

Стена является предметом, изготовленным из камня, которому можно причинить урон и, тем самым, пробить. У каждой плиты КД 15 и по 30 хитов за каждый 1 дюйм (2,5 сантиметра) толщины. Уменьшение хитов плиты до 0 уничтожает её, и по решению Мастера, может вызвать обвал смежных плит.

Если Вы поддерживаете концентрацию на этом заклинании в течение всей длительности, стена становится постоянной, и с этого момента её уже нельзя будет рассеять. В противном случае стена исчезает, когда заклинание будет окончено.`,
    lvl: 5,
    magicSchoolId: MAGIC_EVOCATION,
    needConcentration: true,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'небольшой кусочек гранита',
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 233,
      },
      {
        id: SOURCE_SRD,
        page: 190,
      },
    ],
  },
  {
    id: SPELL_BRANDING_SMITE,
    name: 'Клеймящая кара',
    nameEn: `Branding Smite`,
    description: `Когда Вы в следующий раз попадёте по существу атакой рукопашным оружием, пока заклинание активно, Ваше оружие испускает астральное сияние. Атака причиняет цели дополнительный урон излучением 2к6. Цель становится видимой, если была невидима, начинает испускать тусклый свет в пределах 5 футов, и не может стать невидимой, пока активно это заклинание.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, дополнительный урон увеличивается на 1к6 за каждый круг ячейки выше второй.`,
    lvl: 2,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_WEAPON,
      damage: {
        type: DAMAGE_RADIANT,
        diceCount: 2,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 234,
      },
      {
        id: SOURCE_SRD,
        page: 123,
      },
    ],
  },
  {
    id: SPELL_COUNTERSPELL,
    name: 'Контрзаклинание',
    nameEn: 'Counterspell',
    description: `Вы пытаетесь прервать процесс накладывания заклинания. Если существо сотворяет заклинание 3 уровня или ниже, его заклинание проваливается, и не оказывает никакого эффекта. Если оно накладывало заклинание 4 круга или выше, совершите проверку базовой характеристики. СЛ равна 10 + круг заклинания. При успехе заклинание проваливается и не оказывает никакого эффекта. `,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, прерванное заклинание автоматически не оказывает эффекта, если его уровень не превышает уровень использованной Вами ячейки.`,
    lvl: 3,
    magicSchoolId: MAGIC_ABJURATION,
    range: 60,
    componentIdList: [CAST_SOMATIC],
    castTime: { timeId: TIME_REACTION, count: 1 },
    castTimeComment: `совершаемая Вами, когда Вы видите, как существо в пределах 60 футов от Вас сотворяет заклинание`,
    source: [
      {
        id: SOURCE_PHB,
        page: 234,
      },
      {
        id: SOURCE_SRD,
        page: 131,
      },
    ],
  },
  {
    id: SPELL_CONE_OF_COLD,
    name: 'Конус холода',
    nameEn: 'Cone of Cold',
    description: `Из Ваших рук вырывается волна холодного воздуха. Все существа в 60-футовом конусе должны пройти испытание Телосложения. Существа получают урон холодом 8к8 при провале, или половину этого урона при успехе. Существа, убитые этим заклинанием, становятся замороженными статуями, пока не растают.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше пятого.`,
    lvl: 5,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: '60-футовый конус',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `небольшой кристаллический или стеклянный конус`,
    effect: {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_COLD,
        halfOnSuccess: true,
        diceCount: 8,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 234,
      },
      {
        id: SOURCE_SRD,
        page: 127,
      },
    ],
  },
  {
    id: SPELL_CROWN_OF_MADNESS,
    name: 'Корона безумия',
    nameEn: `Crown of Madness`,
    description: `Один гуманоид на Ваш выбор, видимый Вами в пределах дистанции должен пройти испытание Мудрости, иначе он станет очарованным Вами на время действия заклинания. Пока цель очарована таким способом, на её голове появляется изогнутая железная корона, а в глазах светится безумие.

Очарованная цель должна в каждый свой ход перед перемещением совершать действием рукопашную атаку по любому существу кроме себя, которое Вы мысленно выбираете. Цель может действовать в свой ход как обычно, если Вы не выбрали существо или в пределах его досягаемости нет других существ.

В последующие ходы Вы должны действием поддерживать контроль над целью, иначе заклинание оканчивается. Кроме того, цель в конце каждого своего хода может проходить испытания Мудрости. При успехе заклинание оканчивается.`,
    lvl: 2,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: {
      id: SOURCE_PHB,
      page: 234,
    },
  },
  {
    id: SPELL_CIRCLE_OF_POWER,
    name: 'Круг силы',
    nameEn: `Circle of Power`,
    description: `От Вас исходит божественная энергия, искажающая и рассеивающая магическую энергию в пределах 30 футов от Вас. Пока заклинание активно, сфера перемещается вместе с Вами, оставаясь с центром на Вас. Все дружественные существа в этой области (включая Вас) получают преимущество на испытания от заклинаний и других магических эффектов. Кроме того, когда такое существо преуспевает в испытании от заклинания или магического эффекта, позволяющего пройти испытание для получения всего лишь половины урона, оно не получает урон вообще.`,
    lvl: 5,
    magicSchoolId: MAGIC_ABJURATION,
    range: -1,
    rangeComment: `30-футовый радиус`,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    source: {
      id: SOURCE_PHB,
      page: 234,
    },
  },
  {
    id: SPELL_CIRCLE_OF_DEATH,
    name: 'Круг смерти',
    nameEn: `Circle of Death`,
    description: `Из точки в пределах дистанции расходится сфера негативной энергии с радиусом 60 футов. Все существа в этой области должны пройти испытание Телосложения. Цель получает урон некротической энергией 8к6 при провале или половину этого урона в случае успеха.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, урон увеличивается на 2к6 за каждый круг ячейки выше шестого.`,
    lvl: 6,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 150,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `порошок растолчённой чёрной жемчужины, стоящей как минимум 500 зм`,
    effect: {
      damage: {
        type: DAMAGE_NECROTIC,
        halfOnSuccess: true,
        diceCount: 8,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 235,
      },
      {
        id: SOURCE_SRD,
        page: 124,
      },
    ],
  },
  {
    id: SPELL_TELEPORTATION_CIRCLE,
    name: 'Круг телепортации',
    nameEn: `Teleportation Circle`,
    description: `В процессе накладывания заклинания Вы рисуете на полу круг диаметром 10 футов, исписанный знаками, связывающими Ваше местоположение с постоянным кругом телепортации на Ваш выбор, чью последовательность знаков Вы знаете, и находящимся на том же плане существования, что и вы. В нарисованном круге появляется мерцающий портал, остающийся открытым до конца Вашего следующего хода. Все существа, входящие в портал, мгновенно появляются в пределах 5 футов от места назначения или в ближайшем свободном пространстве, если то место занято.

Во многих основных храмах, гильдиях и прочих важных местах есть постоянные круги телепортации, начертанные где-то на их территории. У каждого такого круга есть уникальная последовательность знаков — серия рун, начертанных в определённом узоре. Когда Вы впервые получаете возможность сотворять это заклинание, Вы изучаете последовательность знаков двух местонахождений на Материальном плане, выбранных Мастером. Вы можете узнать дополнительные последовательности знаков во время приключений. Вы можете запомнить новую последовательность, изучая её 1 минуту.

Вы можете создать постоянный круг телепортации, накладывая это заклинание в одном и том же месте в течение одного года. При этом Вы не обязаны тут же использовать свежесозданный круг.`,
    lvl: 5,
    magicSchoolId: MAGIC_CONJURATION,
    range: 10,
    componentIdList: [CAST_VERBAL, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `редкие мелки и чернила с основой из драгоценных камней стоимостью 50 зм, расходуемые заклинанием`,
    duration: { timeId: TIME_ROUND, count: 1 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 235,
      },
      {
        id: SOURCE_SRD,
        page: 185,
      },
    ],
  },
  {
    id: SPELL_CHILL_TOUCH,
    name: 'Леденящее прикосновение',
    nameEn: `Chill Touch`,
    description: `Вы создаёте призрачную руку скелета в пространстве существа, находящегося в пределах дистанции.

Совершите дальнобойную атаку заклинанием по существу, чтобы окутать его могильным холодом. При попадании цель получает урон некротической энергией 1к8, и не может восстанавливать хиты до начала Вашего следующего хода. Всё это время рука держится за цель.

Если Вы попадаете по нежити, то она, кроме этого, до конца Вашего следующего хода совершает по Вам броски атаки с помехой.`,
    highLvlCast: `Урон этого заклинания увеличивается на 1к8, когда Вы достигаете 5 уровня (2к8), 11 уровня (3к8) и 17 уровня (4к8).`,
    lvl: 0,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_ROUND, count: 1 },
    effect: {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_NECROTIC,
        diceCount: 1,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 235,
      },
      {
        id: SOURCE_SRD,
        page: 124,
      },
    ],
  },
  {
    id: SPELL_WALL_OF_ICE,
    name: 'Ледяная стена',
    nameEn: `Wall of Ice`,
    description: `Вы создаёте стену изо льда на твёрдой поверхности в пределах дистанции. Вы можете сформировать её в виде купола полусферы или сферы с радиусом до 10 футов, или же Вы можете создать плоскую поверхность, состоящую из десяти квадратных плит с длиной ребра 10 футов. Каждая плита должна быть смежна с другой плитой. Какая бы форма ни была у стены, у неё толщина 1 фут (30 сантиметров), и существует она пока активно заклинание.

Если стена при появлении проходит по пространству существа, это существо выталкивается по одну из сторон стены и проходит испытание Ловкости. При провале существо получает урон холодом 10к6, или половину этого урона при успехе.

Стена является предметом, которому можно причинить урон и, тем самым, пробить. У каждой 10-футовой секции КД 12 и по 30 хитов, а также уязвимость к урону огнём. Уменьшение хитов плиты до 0 уничтожает её, и оставляет в воздухе область холодного воздуха. Существо, впервые за ход перемещающееся через эту область холодного воздуха, должно пройти испытание Телосложения. Это существо получает урон холодом 5к6 при провале или половину этого урона при успехе.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, урон, причиняемый стеной при возникнове нии, увеличивается на 2к6, а урон от прохождения через область холодного воздуха увеличивается на 1к6 за каждый круг ячейки выше шестого.`,
    lvl: 6,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `небольшой кусочек кварца`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_COLD,
        halfOnSuccess: true,
        diceCount: 5,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 235,
      },
      {
        id: SOURCE_SRD,
        page: 190,
      },
    ],
  },
  {
    id: SPELL_LEOMUND_S_SECRET_CHEST,
    name: 'Леомундов потайной сундук',
    nameEn: `Leomund’s Secret Chest`,
    nameEnAlt: `Secret Chest`,
    description: `Вы прячете сундук вместе со всем его содержимым на Эфирном Плане. Вы должны касаться и сундука и его миниатюрной копии, служащей материальным компонентом для заклинания. Сундук может содержать до 12 кубических футов неживой материи (3×2×2 фута).

Пока сундук остаётся на Эфирном Плане, Вы можете действием прикоснуться к копии, чтобы вернуть сундук. Он появляется в свободном пространстве на полу в пределах 5 футов от Вас. Вы можете отослать сундук обратно на Эфирный План, прикоснувшись действием к сундуку и его копии.

Спустя 60 дней появляется накопительный шанс 5 процентов в день, что эффект заклинания окончится. Эффект оканчивается, если Вы активируете это заклинание ещё раз, если копия сундука будет уничтожена, или если Вы окончите это заклинание действием. Если заклинание оканчивается, а сундук находится на Эфирном Плане, он безвозвратно теряется.`,
    lvl: 4,
    magicSchoolId: MAGIC_CONJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `изысканный сундук, 90×60×60 сантиметров, созданный из редких материалов, и стоящий как минимум 5 000 зм, и его копия Крошечного размера, изготовленная из того же материала, стоящая как минимум 50 зм`,
    source: [
      {
        id: SOURCE_PHB,
        page: 236,
      },
      {
        id: SOURCE_SRD,
        page: 177,
      },
    ],
  },
  {
    id: SPELL_LEOMUND_S_TINY_HUT,
    name: 'Леомундова хижина',
    nameEn: `Leomund’s Tiny Hut`,
    nameEnAlt: `Tiny Hut`,
    description: `Над Вами и вокруг Вас появляется купол из силового поля радиусом 10 футов, остающийся неподвижным всё время существования. Заклинание оканчивается, если Вы покидаете его область.

Под куполом вместе с Вами могут поместиться девять существ с размером не больше Среднего. Заклинание срывается, если в его область попадает существо большего размера или более девяти существ. Существа и предметы, находившиеся под куполом, когда Вы наложили это заклинание, могут свободно проходить сквозь него. Все остальные существа и предметы не могут пройти сквозь него. Заклинания и прочие магические эффекты не могут простираться под купол и не могут быть наложены сквозь него. Атмосфера под ним сухая и комфортная, какой бы ни была погода снаружи.

Пока заклинание активно, Вы можете сделать пространство под ним тускло освещённым или погружённым во тьму. Купол снаружи матовый, любого цвета, но изнутри он прозрачный.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: `полусфера с радиусом 10 футов`,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `маленькая кристаллическая бусина`,
    duration: { timeId: TIME_HOUR, count: 8 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 236,
      },
      {
        id: SOURCE_SRD,
        page: 187,
      },
    ],
  },
  {
    id: SPELL_HEALING_WORD,
    name: 'Лечащее слово',
    nameEn: `Healing Word`,
    description: `Существо на Ваш выбор, видимое в пределах дистанции, восстанавливает количество хитов, равное 1к4 + Ваш модификатор базовой характеристики. Это заклинание не оказывает никакого эффекта на нежить и конструктов`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, количество восстанавливаемых хитов увеличивается на 1к4 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL],
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 236,
      },
      {
        id: SOURCE_SRD,
        page: 153,
      },
    ],
  },
  {
    id: SPELL_CURE_WOUNDS,
    name: 'Лечение ран',
    nameAlt: 'Исцеление ран',
    nameEn: 'Cure Wounds',
    description: `Существо, которого Вы касаетесь, восстанавливает количество хитов, равное 1к8 + Ваш модификатор базовой характеристики. Это заклинание не оказывает никакого эффекта на нежить и конструктов.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, лечение увеличивается на 1к8 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: [
      {
        id: SOURCE_PHB,
        page: 236,
      },
      {
        id: SOURCE_SRD,
        page: 132,
      },
    ],
  },
  {
    id: SPELL_RAY_OF_ENFEEBLEMENT,
    name: 'Луч слабости',
    nameEn: 'Ray of Enfeeblement',
    description: `Чёрный луч вылетает из Вашего пальца к существу, находящемуся в пределах дистанции. Совершите по цели дальнобойную атаку заклинанием. При попадании цель до конца действия заклинания причиняет только половину урона атаками оружием, использующими Силу.

В конце каждого своего хода цель может проходить испытание Телосложения от этого заклинания. При успехе заклинание оканчивается.`,
    lvl: 2,
    magicSchoolId: MAGIC_NECROMANCY,
    duration: { timeId: TIME_MINUTE, count: 1 },
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    needConcentration: true,
    effect: {
      attackType: ATTACK_RANGE_SPELL,
      savethrowParam: PARAM_CON,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 237,
      },
      {
        id: SOURCE_SRD,
        page: 174,
      },
    ],
  },
  {
    id: SPELL_RAY_OF_FROST,
    name: 'Луч холода',
    nameEn: 'Ray of Frost',
    description: `Холодный сине-белый луч устремляется к существу, находящемуся в пределах дистанции. Совершите по цели дальнобойную атаку заклинанием. При попадании она получает урон холодом 1к8, а скорость до начала Вашего следующего хода уменьшается на 10 фт.`,
    highLvlCast: `Урон от заклинания увеличивается на 1к8, когда Вы достигаете 5 уровня (2к8), 11 уровня (3к8) и 17 уровня (4к8).`,
    lvl: 0,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_COLD,
        halfOnSuccess: false,
        diceCount: 1,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 236,
      },
      {
        id: SOURCE_SRD,
        page: 174,
      },
    ],
  },
  {
    id: SPELL_ARCANE_GATE,
    name: 'Магические врата',
    nameEn: `Arcane Gate`,
    description: `Вы создаёте связанные порталы для телепортации, остающиеся открытыми на время длительности. Выберите две точки на земле, которые Вы видите, одну в пределах 10 футов от себя, и вторую в пределах 500 футов. В каждой точке появляется круглый портал диаметром 10 футов. Если портал должен открыться в пространстве, занятом существом, заклинание проваливается.

Порталы представляют собой двумерные светящиеся круги, заполненные туманом, парящие в паре сантиметров над землёй и перпендикулярные выбранной Вами точке. Круги видны только с одной стороны (на Ваш выбор), которая и функционирует как портал.

Все существа и предметы, входящие в портал, выходят из другого портала, как если бы они были смежны друг с другом; прохождение сквозь портал с неактивной стороны не оказывает никакого эффекта. Туман, заполняющий порталы, непрозрачный, и блокирует зрение. Вы в свой ход можете бонусным действием повернуть кольца, чтобы активные стороны поменялись местами с неактивными.`,
    lvl: 6,
    magicSchoolId: MAGIC_CONJURATION,
    range: 500,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    source: {
      id: SOURCE_PHB,
      page: 237,
    },
  },
  {
    id: SPELL_ARCANE_EYE,
    name: 'Магический глаз',
    nameEn: `Arcane Eye`,
    description: `Вы создаёте невидимый магический глаз в пределах дистанции, который парит в воздухе на время длительности.

Вы мысленно воспринимаете зрительную информацию с глаза, у которого обычное зрение и тёмное зрение в пределах 30 футов. Глаз смотрит во всех направлениях.

Вы можете действием переместить глаз на 30 футов в любом направлении. Глаз может удаляться от Вас на любое расстояние, но не может уходить на другие планы существования. Сплошные препятствия блокируют движение глаза, но он может проходить через отверстия диаметром в 1 дюйм (2,5 сантиметра).`,
    lvl: 4,
    magicSchoolId: MAGIC_DIVINATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `мех летучей мыши`,
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 237,
      },
      {
        id: SOURCE_SRD,
        page: 118,
      },
    ],
  },
  {
    id: SPELL_MAGIC_CIRCLE,
    name: 'Магический круг',
    nameEn: `Magic Circle`,
    description: `Вы создаёте из магической энергии цилиндр с радиусом 10 футов и высотой 20 футов с центром на точке на полу, видимой в пределах дистанции. Там, где цилиндр пересекает пол или другую поверхность, появляются светящиеся руны.

Выберите один или несколько видов существ из следующего списка: исчадия, небожители, нежить, феи или элементали. Круг действует на выбранных существ следующим образом:

* Существа не могут добровольно войти в цилиндр немагическими средствами. Если существо пытается использовать телепортацию или межпланарное путешествие, оно должно вначале пройти испытание Харизмы. 
* Существо совершает с помехой броски атаки по целям, находящимся в пределах цилиндра.
* Целей в цилиндре нельзя сделать очарованными, испуганными или одержимыми.

При накладывании этого заклинания Вы можете обратить его магию, чтобы существа выбранного вида не могли покинуть цилиндр, и защищены были цели, находящиеся снаружи.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, длительность увеличивается на 1 час за каждый круг ячейки выше третьего.`,
    lvl: 3,
    magicSchoolId: MAGIC_ABJURATION,
    range: 10,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `святая вода или порошок серебра и железа, стоящий как минимум 100 зм, расходуемые заклинанием`,
    duration: { timeId: TIME_HOUR, count: 1 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_CHA,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 237,
      },
      {
        id: SOURCE_SRD,
        page: 160,
      },
    ],
  },
  {
    id: SPELL_MAGIC_WEAPON,
    name: 'Магическое оружие',
    nameEn: 'Magic Weapon',
    description: `Вы касаетесь немагического оружия. Пока заклинание активно, это оружие становится магическим оружием с бонусом +1 к броскам атаки и урона.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, бонус увеличивается до +2. Если Вы используете ячейку заклинания 6 круга или выше, бонус увеличивается до +3.`,
    lvl: 2,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 238,
      },
      {
        id: SOURCE_SRD,
        page: 161,
      },
    ],
  },
  {
    id: SPELL_LESSER_RESTORATION,
    name: 'Малое восстановление',
    nameEn: 'Lesser Restoration',
    description: `Вы касаетесь существа и можете окончить одну болезнь или одно состояние, в котором оно находится. Этим состоянием может быть глухота, отравление, паралич или слепота.`,
    lvl: 2,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: [
      {
        id: SOURCE_PHB,
        page: 238,
      },
      {
        id: SOURCE_SRD,
        page: 158,
      },
    ],
  },
  {
    id: SPELL_CRUSADER_S_MANTLE,
    name: 'Мантия крестоносца',
    nameEn: `Crusader’s Mantle`,
    description: `От Вас начинает исходить святая сила аурой с радиусом 30 футов, пробуждающая в дружественных существах отвагу. Пока заклинание активно, аура перемещается вместе с Вами, оставаясь с центром на Вас. Находясь в ауре, все невраждебные существа (включая Вас) при попадании атакой оружием причиняют дополнительный урон 1к4.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    note: {
      text: `Кто такие эти «крестоносцы»?`,
      author: `Эспумизан Коликит, гоблин волшебник`,
    },
    effect: {
      attackType: ATTACK_MELEE_WEAPON,
      damage: {
        type: [
          DAMAGE_BLUDGEONING,
          DAMAGE_PIERCING,
          DAMAGE_SLASHING,
        ],
        diceCount: 1,
        diceType: 4,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 238,
    },
  },
  {
    id: SPELL_DISGUISE_SELF,
    name: 'Маскировка',
    nameAlt: 'Изменение облика',
    nameEn: 'Disguise Self',
    description: `Вы заставляете себя — включая одежду, доспехи, оружие и прочее имущество — выглядеть иначе, пока заклинание не окончится, или пока Вы не окончите его преждевременно, действием. Вы можете выглядеть на 1 фут (30 сантиметров) выше или ниже, а также стать худым, толстым или получить среднее телосложение. Вы не можете менять основу тела, поэтому у новой формы должен быть такой же набор конечностей. Во всём остальном характер иллюзии определяете вы.

Изменения, вызванные этим заклинанием, не выдерживают проверку физическим исследованием. Например, если Вы добавили к своему снаряжению шляпу, предметы будут проходить сквозь неё, и все, кто к ней прикоснутся, либо ничего не почувствуют, либо почувствуют Вашу голову и волосы. Если Вы становитесь худее, то рука того, кто к вам тянется, наткнётся на Вас на полпути, ещё в воздухе.

Для обнаружения того, что Вы замаскированы, существо должно действием обследовать Вашу внешность и преуспеть в проверке Интеллекта (Анализ) против Вашей СЛ заклинаний.`,
    lvl: 1,
    magicSchoolId: MAGIC_ILLUSION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 238,
      },
      {
        id: SOURCE_SRD,
        page: 135,
      },
    ],
  },
  {
    id: SPELL_BEACON_OF_HOPE,
    name: 'Маяк надежды',
    nameEn: `Beacon of Hope`,
    description: `Это заклинание дарует надежду и живучесть. Выберите любое количество существ в пределах дистанции. Пока заклинание активно, все цели проходят испытания Мудрости и испытания от смерти с преимуществом, а также восстанавливают от любого лечения максимально возможное количество хитов.`,
    lvl: 3,
    magicSchoolId: MAGIC_ABJURATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 238,
      },
      {
        id: SOURCE_SRD,
        page: 121,
      },
    ],
  },
  {
    id: SPELL_RARY_S_TELEPATHIC_BOND,
    name: 'Ментальная связь Рэри',
    nameEn: `Rary’s Telepathic Bond`,
    nameEnAlt: `Telepathic Bond`,
    description: `Вы создаёте телепатическую связь между восемью согласными существами на Ваш выбор в пределах досягаемости. Существа с Интеллектом 2 и ниже не попадают под действие этого заклинания.

Пока заклинание активно, цели могут телепатически общаться посредством этой связи, даже если у них нет общего языка. Общение возможно на любом расстоянии, но оно не может простираться на другие планы существования.`,
    lvl: 5,
    magicSchoolId: MAGIC_DIVINATION,
    range: 30,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочки яичной скорлупы двух разных видов существ`,
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 239,
      },
      {
        id: SOURCE_SRD,
        page: 185,
      },
    ],
  },
  {
    id: SPELL_BLINK,
    name: 'Мерцание',
    nameEn: `Blink`,
    description: `Пока заклинание активно, бросайте к20 в конце каждого своего хода. Если выпадет «11» или выше, Вы исчезаете на текущем плане существования и появляетесь на Эфирном Плане (заклинание проваливается, если Вы уже находитесь на этом плане). В начале своего следующего хода, а также если заклинание окончится, когда Вы будете на Эфирном Плане, Вы возвращаетесь в свободное пространство на свой выбор, которое Вы видите в пределах 10 футов от места, из которого Вы исчезли. Если в пределах дистанции нет свободного пространства, Вы появляетесь в ближайшем свободном пространстве (выбранном случайным образом, если есть несколько подходящих мест). Вы можете остановить заклинание действием.

Находясь на Эфирном Плане, Вы можете видеть и слышать на плане, с которого пришли, видя всё в оттенках серого и не видя того, что происходит за пределами 60 футов. Вы можете воздействовать только на существ, находящихся на Эфирном Плане, и только такие существа могут воздействовать на Вас. Существа, не находящиеся там, не могут Вас воспринимать и взаимодействовать с Вами, если у них нет таких возможностей.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 239,
      },
      {
        id: SOURCE_SRD,
        page: 122,
      },
    ],
  },
  {
    id: SPELL_METEOR_SWARM,
    name: 'Метеоритный дождь',
    nameEn: `Meteor Swarm`,
    description: `Пылающие шары ударяют о землю в четырёх разных точках, видимых в пределах дистанции. Все существа в сфере с радиусом 40 футов с центром на каждой из точек должны пройти испытание Ловкости. Сфера огибает углы. Существо получает при провале урон огнём 20к6 и дробящий урон 20к6, или половину урона при успехе.

Существо, находящееся в области двух взрывов получает урон только один раз. Заклинание причиняет урон предметам и воспламеняет горючие предметы, которые никто не несёт и не носит.`,
    lvl: 9,
    magicSchoolId: MAGIC_EVOCATION,
    range: '1 миля',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      savethrowParam: PARAM_DEX,
      damage: [
        {
          type: DAMAGE_FIRE,
          halfOnSuccess: true,
          diceCount: 20,
          diceType: 6,
        },
        {
          type: DAMAGE_BLUDGEONING,
          halfOnSuccess: true,
          diceCount: 20,
          diceType: 6,
        },
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 240,
      },
      {
        id: SOURCE_SRD,
        page: 164,
      },
    ],
  },
  {
    id: SPELL_HUNTERS_MARK,
    name: 'Метка охотника',
    nameEn: `Hunter’s Mark`,
    description: `Вы выбираете существо, видимое в пределах дистанции, и объявляете его своей добычей. Пока заклинание активно, Вы причиняете цели дополнительный урон 1к6 каждый раз, когда попадаете по ней атакой оружием, и Вы совершаете с преимуществом проверки Мудрости (Внимательность) и Мудрость (Выживание), совершённые для её поиска. Если хиты цели опускаются до 0, пока заклинание активно, Вы можете в свой следующий ход бонусным действием выбрать целью новое существо`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 или 4 уровня, Вы можете поддерживать концентрацию 8 часов. Если Вы используете ячейку заклинания 5 круга или выше, Вы можете поддерживать концентрацию 24 часа.`,
    lvl: 1,
    magicSchoolId: MAGIC_DIVINATION,
    range: 90,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_HOUR, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      type: [
        ATTACK_MELEE_WEAPON,
        ATTACK_RANGE_WEAPON,
      ],
      damage: {
        type: [
          DAMAGE_BLUDGEONING,
          DAMAGE_PIERCING,
          DAMAGE_SLASHING,
        ],
        diceCount: 1,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 240,
      },
      {
        id: SOURCE_SRD,
        page: 155,
      },
    ],
  },
  {
    id: SPELL_TRUE_STRIKE,
    name: 'Меткий удар',
    nameEn: `True Strike`,
    description: `Вы вытягиваете руку и указываете пальцем на цель, находящуюся в пределах дистанции. Ваша магия даёт краткое понимание защит цели. В своём следующем ходу Вы совершаете с преимуществом первый бросок атаки по цели, при условии, что заклинание к тому моменту не окончится.`,
    lvl: 0,
    magicSchoolId: MAGIC_DIVINATION,
    range: 30,
    componentIdList: [CAST_SOMATIC],
    duration: { timeId: TIME_ROUND, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 240,
      },
      {
        id: SOURCE_SRD,
        page: 189,
      },
    ],
  },
  {
    id: SPELL_MORDENKAINEN_S_SWORD,
    name: 'Меч Морденкайнена',
    nameEn: `Mordenkainen’s Sword`,
    nameEnAlt: `Arcane Sword`,
    description: `Вы создаёте сгусток силового поля в форме меча, парящий в пределах дистанции. Он существует, пока активно заклинание.

Когда меч появляется, Вы совершаете рукопашную атаку заклинанием по цели на Ваш выбор, находящейся в пределах 5 футов от меча. При попадании цель получает урон силовым полем 3к10. Пока заклинание активно, Вы можете в каждом своём ходу бонусным действием перемещать меч на 20 футов в видимое место, и повторять эту атаку по той же самой или другой цели.`,
    lvl: 7,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `миниатюрный платиновый меч с рукояткой и навершием из меди и цинка, стоящий 250 зм`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_SPELL,
      damage: {
        type: DAMAGE_FORCE,
        diceCount: 3,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 240,
      },
      {
        id: SOURCE_SRD,
        page: 119,
      },
    ],
  },
  {
    id: SPELL_HALLUCINATORY_TERRAIN,
    name: 'Мираж',
    nameEn: 'Hallucinatory Terrain',
    description: `Вы заставляете природную местность в пределах куба с длиной ребра 150 фт., находящегося в пределах дистанции, выглядеть, звучать и пахнуть как другая природная местность. Таким образом, поле или дорогу можно сделать похожей на болото, холм, расселину или другую труднопроходимую или непроходимую местность. Пруд можно сделать похожим на травяную лужайку, пропасть сделать пологим спуском, а каменистый овраг — широкой и ровной дорогой. Рукотворные строения, снаряжение и существа в области не меняют облик.

Тактильные ощущения местности не меняются, поэтому существа, входящие в нее, скорее всего, поймут обман. Если на прикосновение разница не очевидна, существо, тщательно исследующее иллюзию, может предпринять проверку Интеллекта (Анализ) против СЛ Ваших заклинаний, чтобы не поверить. Существо, способное распознавать иллюзии, видит полупрозрачный образ, наложенный на местность.`,
    lvl: 4,
    magicSchoolId: MAGIC_ILLUSION,
    range: 300,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'камень, ветка, и кусочек зеленого растения',
    duration: { timeId: TIME_HOUR, count: 24 },
    source: [
      {
        id: SOURCE_PHB,
        page: 240,
      },
      {
        id: SOURCE_SRD,
        page: 152,
      },
    ],
  },
  {
    id: SPELL_ELDRITCH_BLAST,
    name: 'Мистический заряд',
    nameAlt: 'Потусторонний заряд',
    nameEn: `Eldritch Blast`,
    description: `К существу, находящемуся в пределах дистанции, устремляется луч потрескивающей энергии. Совершите дальнобойную атаку заклинанием по цели. При попадании цель получает урон силовым полем 1к10.`,
    highLvlCast: `Заклинание создаёт ещё один луч, когда Вы достигаете больших уровней: два луча на 5 уровне, три луча на 11 уровне и четыре луча на 17 уровне. Вы можете направить лучи в одну цель или в разные. Для каждого луча совершите свой бросок атаки.`,
    lvl: 0,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_FORCE,
        diceCount: 1,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 240,
      },
      {
        id: SOURCE_SRD,
        page: 139,
      },
    ],
  },
  {
    id: SPELL_MASS_SUGGESTION,
    name: 'Множественное внушение',
    nameEn: `Mass Suggestion`,
    description: `Вы внушаете определённый курс действий (ограниченный одной-двумя фразами) и магически влияете на двенадцать существ на свой выбор, видимых в пределах дистанции, которые могут слышать и понимать Вас. Существа, которые не могут быть очарованы, обладают иммунитетом к этому эффекту. Внушение должно быть сформировано так, чтобы действие звучало разумным. Просьбы ударить себя мечом, броситься на копьё, сжечь себя, или как-то иначе причинить себе вред отменяют эффект заклинания.

Каждая цель должна пройти испытание Мудрости. При провале она следует заданному курсу действий. Внушённые действия могут продолжаться всю длительность заклинания. Если внушённую деятельность можно выполнить за меньший срок, заклинание оканчивается, когда субъект оканчивает порученную задачу.

Вы можете также указать условие, которое запустит особое действие во время действия заклинания. Например, Вы можете внушить группе солдат, чтобы они отдали все свои деньги первому встреченному нищему. Если до окончания действия условие не будет выполнено, деятельность не совершается.

Если Вы или кто-то из Ваших спутников причиняют урон существу, находящемуся под действием этого заклинания, для этого существа заклинание оканчивается.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 уровня, длительность равна 10 дням. Если Вы используете ячейку 8 уровня, длительность равна 30 дням. Если Вы используете ячейку 9 уровня, длительность равна 1 году и 1 дню.`,
    lvl: 6,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_MATERIAL],
    materialText: `язык змеи и либо кусочек медовых сот, либо капля сладкого масла`,
    duration: { timeId: TIME_HOUR, count: 24 },
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 241,
      },
      {
        id: SOURCE_SRD,
        page: 163,
      },
    ],
  },
  {
    id: SPELL_MASS_HEALING_WORD,
    name: 'Множественное лечащее слово',
    nameEn: 'Mass Healing Word',
    description: `Вы произносите слова восстановления, и до шести существ на Ваш выбор, видимых в пределах дистанции, восстанавливают хиты в количестве, равном 1к4 + Ваш модификатор базовой характеристики. Это заклинание не оказывает никакого эффекта на нежить и конструктов.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, восстанавливается дополнительно 1к4 хитов за каждый круг ячейки выше 3.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL],
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 241,
      },
      {
        id: SOURCE_SRD,
        page: 163,
      },
    ],
  },
  {
    id: SPELL_MASS_CURE_WOUNDS,
    name: 'Множественное лечение ран',
    nameEn: 'Mass Cure Wounds',
    description: `Волна целительной энергии исходит из точки, выбранной Вами в пределах дистанции. Выберите до шести существ в сфере с радиусом 30 футов с центром на этой точке. Все цели восстанавливают хиты в количестве, равном 3к8 + модификатор Вашей базовой характеристики. Это заклинание не оказывает никакого эффекта на нежить и конструктов.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, восстанавливается дополнительно 1к8 хитов за каждый круг ячейки выше пятого.`,
    lvl: 5,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: [
      {
        id: SOURCE_PHB,
        page: 241,
      },
      {
        id: SOURCE_SRD,
        page: 162,
      },
    ],
  },
  {
    id: SPELL_MASS_HEAL,
    name: 'Множественное полное исцеление',
    nameEn: `Mass Heal`,
    description: `Поток целительной энергии перетекает от Вас к раненым существам. Вы восстанавливаете до 700 хитов, разделённых на Ваш выбор между любым количеством существ, видимых Вами в пределах дистанции. Существа, вылеченные этим заклинанием, также исцеляются от всех эффектов, делающих их ослеплёнными или оглушёнными, а также от всех болезней. Это заклинание не оказывает никакого эффекта на нежить и конструктов.`,
    lvl: 9,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: [
      {
        id: SOURCE_PHB,
        page: 241,
      },
      {
        id: SOURCE_SRD,
        page: 163,
      },
    ],
  },
  {
    id: SPELL_PRAYER_OF_HEALING,
    name: 'Молебен лечения',
    nameEn: `Prayer of Healing`,
    description: `Каждое из шести существ на Ваш выбор, видимых в пределах дистанции, восстанавливает количество хитов, равное 2к8 + модификатор Вашей базовой характеристики. Это заклинание не оказывает никакого эффекта на нежить и конструктов.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, лечение увеличивается на 1к8 за каждый круг ячейки выше второго.`,
    lvl: 2,
    magicSchoolId: MAGIC_EVOCATION,
    range: 30,
    componentIdList: [CAST_VERBAL],
    castTime: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 241,
      },
      {
        id: SOURCE_SRD,
        page: 170,
      },
    ],
  },
  {
    id: SPELL_LIGHTNING_ARROW,
    name: 'Молниевая стрела',
    nameEn: `Lightning Arrow`,
    description: `В следующий раз, когда Вы совершите дальнобойную атаку оружием, пока активно это заклинание, Ваш боеприпас или само оружие, если это метательное оружие, превращается в разряд молнии. Совершите бросок атаки как обычно. Вместо обычного урона от оружия цель при попадании получает урон электричеством 4к8, или половину урона при промахе.

Вне зависимости от попадания или промаха, все существа в пределах 10 футов от цели должны пройти испытание Ловкости. При провале эти существа получают урон электричеством 2к8, или половину урона при успехе.

После этого боеприпас или оружие возвращается в обычную форму.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, урон от обоих эффектов увеличивается на 1к8 за каждый круг ячейки выше третьего.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_RANGE_WEAPON,
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_ELECTRICITY,
        halfOnSuccess: true,
        diceCount: 4,
        diceType: 8,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 241,
    },
  },
  {
    id: SPELL_LIGHTNING_BOLT,
    name: 'Молния',
    nameEn: 'Lightning Bolt',
    description: `Разряд молнии формирует линию длиной 100 футов и шириной 5 футов, вылетающую в выбранном Вами направлении. Все существа в линии должны пройти испытание Ловкости. При провале существо получает урон электричеством 8к6, или половину урона при успехе. Молния поджигает горючие предметы в поражаемой области, которые никто не несёт и не носит.`,
    note: [
      {
        text: 'Она что, не знает, что надо стучаться?!',
        author: 'Снерд, стражница Пылающего Кулака',
      },
      {
        text: 'Первые маги увидели ярость бушующей грозы и захотели ответить',
        author: 'Писания творцов бури',
      },
      {
        text: 'Просто шок',
        author: 'Баррин, архимаг Толарии',
      },
      'Электромаг пронзительно закричал, взывая к ярости бурь своей молодости. К его изумлению, небо ответило неистовым сгустком энергии, который он не ожидал увидеть снова.',
    ],
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше третьего.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: '100-футовая линия',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек меха и жезл из янтаря, кристалла или стекла`,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_ELECTRICITY,
        halfOnSuccess: true,
        diceCount: 8,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 242,
      },
      {
        id: SOURCE_SRD,
        page: 159,
      },
    ],
  },
  {
    id: SPELL_SCRYING,
    name: 'Наблюдение',
    nameEn: 'Scrying',
    description: `Вы можете видеть и слышать конкретное выбранное Вами существо, находящееся на том же плане существования, что и вы. Цель должна пройти испытание Мудрости, модифицируемый тем, как хорошо Вы знаете цель, и тем, какая связь с этим существом у Вас есть. Если цель знает, что Вы накладываете это заклинание, она может добровольно провалить испытание, если хочет, чтобы за ней следили.

При успехе цель не попадает под действие, и Вы не можете использовать это заклинание на ней в течение 24 часов.

При провале заклинание создаёт невидимый сенсор в пределах 10 футов от цели. Вы можете видеть и слышать через него, как если бы Вы там находились. Сенсор перемещается вместе с целью, оставаясь в пределах 10 футов от неё, пока активно заклинание. Существа, способные видеть невидимые предметы, видят сенсор как светящийся шарик размером с Ваш кулак.

Вместо того чтобы нацеливаться на существо, Вы можете выбрать целью место, уже видимое Вами. В этом случае сенсор появляется в этом месте и не перемещается.

| Знание                                       | Модификатор испытания  |
|----------------------------------------------|-------------------------|
| Через посредников (вы только слышали о цели) | +5                      |
| Из первых рук (вы встречались с целью)       | +0                      |
| Знакомые (вы хорошо знаете цель)             | −5                      |

| Связь                                         | Модификатор испытания |
|-----------------------------------------------|------------------------|
| Образ или рисунок                             |   −2                   |
| Вещь или одежда                               |   −4                   |
| Часть тела, прядь волос, кусок ногтя, и т. п. |   −10                  |`,
    lvl: 5,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `фокусировка, стоящая как минимум 1 000 зм, такая как хрустальный шар, серебряное зеркало или чаша со святой водой`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    castTime: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 242,
      },
      {
        id: SOURCE_SRD,
        page: 176,
      },
    ],
  },
  {
    id: SPELL_INFLICT_WOUNDS,
    name: 'Нанесение ран',
    nameEn: `Inflict Wounds`,
    description: `Совершите рукопашную атаку заклинанием по существу в пределах досягаемости. При попадании цель получает урон некротической энергией 3к10.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к10 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      attackType: ATTACK_MELEE_SPELL,
      damage: {
        type: DAMAGE_NECROTIC,
        diceCount: 3,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 243,
      },
      {
        id: SOURCE_SRD,
        page: 157,
      },
    ],
  },
  {
    id: SPELL_GUIDING_BOLT,
    name: 'Направленный снаряд',
    nameAlt: ['Направляющий луч', 'Направленный разряд'],
    nameEn: 'Guiding Bolt',
    description: `Вспышка света устремляется к выбранному Вами существу в пределах дистанции. Совершите дальнобойную атаку заклинанием по цели. При попадании цель получает урон излучением 4к6, и следующий бросок атаки по цели, совершённый до конца Вашего следующего хода, совершается с преимуществом, благодаря мистическому тусклому свету, остающемуся недолго на цели.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_ROUND, count: 1 },
    effect: {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_RADIANT,
        diceCount: 4,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 243,
      },
      {
        id: SOURCE_SRD,
        page: 151,
      },
    ],
  },
  {
    id: SPELL_GLIBNESS,
    name: 'Находчивость',
    nameEn: `Glibness`,
    description: `Пока заклинание не окончится, каждый раз, когда Вы совершаете проверку Харизмы, Вы можете заменить выпавший результат числом «15». Кроме того, что бы Вы ни говорили, магия, проверяющая, говорите ли Вы правду, показывает, что Вы искренни.`,
    lvl: 8,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 243,
      },
      {
        id: SOURCE_SRD,
        page: 149,
      },
    ],
  },
  {
    id: SPELL_INSECT_PLAGUE,
    name: 'Нашествие насекомых',
    nameEn: 'Insect Plague',
    description: `Рой кусачей саранчи заполняет сферу с радиусом 20 фт. с центром на точке, выбранной Вами в пределах дистанции. Сфера огибает углы. Сфера существует, пока активно заклинание, и её местность слабо заслонена. Пространство сферы является труднопроходимой местностью. Когда сфера появляется, все находящиеся в ней существа должны пройти испытание Телосложения. Существо получает колющий урон 4к10 при провале, или половину урона при успехе. Существа должны также проходить это испытание когда впервые за ход входят в область заклинания или оканчивают там ход.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, урон увеличивается на 1к10 за каждый круг ячейки выше пятого.`,
    lvl: 5,
    magicSchoolId: MAGIC_CONJURATION,
    range: 300,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'несколько крупинок сахара, немного зёрнышек и капля жира',
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_PIERCING,
        halfOnSuccess: true,
        diceCount: 4,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 243,
      },
      {
        id: SOURCE_SRD,
        page: 157,
      },
    ],
  },
  {
    id: SPELL_FLAME_STRIKE,
    name: 'Небесный огонь',
    nameEn: 'Flame Strike',
    description: `В указанном Вами месте возникает вертикальная колонна божественного пламени. Все существа в цилиндре с радиусом 10 фт. и высотой 40 фт. с центром на точке в пределах дистанции должны пройти испытание Ловкости. Существо получает урон излучением 4к6 и урон огнём 4к6 при провале и половину урона при успехе.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, урон излучением или урон огнём (на Ваш выбор) увеличивается на 1к6 за каждый круг ячейки выше пятого.`,
    lvl: 5,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'щепотка серы',
    effect: {
      savethrowParam: PARAM_DEX,
      damage: [
        {
          type: DAMAGE_RADIANT,
          halfOnSuccess: true,
          diceCount: 4,
          diceType: 6,
        },
        {
          type: DAMAGE_FIRE,
          halfOnSuccess: true,
          diceCount: 4,
          diceType: 6,
        },
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 243,
      },
      {
        id: SOURCE_SRD,
        page: 145,
      },
    ],
  },
  {
    id: SPELL_ILLUSORY_SCRIPT,
    name: 'Невидимое письмо',
    nameEn: `Illusory Script`,
    description: `Вы пишете на пергаменте, бумаге или другом подходящем материале, и накрываете его иллюзией, существующей, пока активно заклинание. Для Вас и всех существ, указанных Вами при накладывании заклинания, письмена кажутся обычными, написанными Вашей рукой, и передающими тот самый смысл, который вкладывали вы.

Для всех остальных письмена кажутся написанными на неизвестном или магическом языке, который невозможно прочитать. В качестве альтернативы Вы можете заставить письмена поменять смысл, или сделать так, чтобы они выглядели написанными другой рукой или на другом языке, но язык при этом должен быть тем, который Вы знаете.

Если заклинание рассеять, исчезает и исходное письмо и иллюзия.

Существо с истинным зрением может читать скрытое послание.`,
    lvl: 1,
    magicSchoolId: MAGIC_ILLUSION,
    range: 0,
    isRitual: true,
    componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `свинцовые чернила, стоящие как минимум 10 зм, расходуемые заклинанием`,
    duration: { timeId: TIME_DAY, count: 10 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 243,
      },
      {
        id: SOURCE_SRD,
        page: 156,
      },
    ],
  },
  {
    id: SPELL_INVISIBILITY,
    name: 'Невидимость',
    nameEn: 'Invisibility',
    description: `Существо, которого Вы касаетесь, становится невидимым до окончания действия заклинания. Все, что цель несет или носит, становится невидимым, пока остается у нее. Заклинание оканчивается на цели, если она совершает атаку или сотворяет заклинание.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше второго.`,
    lvl: 2,
    magicSchoolId: MAGIC_ILLUSION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'ресница в смоле',
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    effect: {
      condition: CONDITION_INVISIBLE,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 243,
      },
      {
        id: SOURCE_SRD,
        page: 157,
      },
    ],
  },
  {
    id: SPELL_UNSEEN_SERVANT,
    name: 'Невидимый слуга',
    nameEn: `Unseen Servant`,
    description: `Это заклинание создаёт невидимую, бессознательную и бесформенную силу Среднего размера, выполняющую отдаваемые Вами простые поручения. Слуга появляется в свободном пространстве на земле в пределах дистанции. У него КД 10, 1 хит, Сила 2, и он не может атаковать. Если его хиты опускаются до 0, заклинание оканчивается.

Один раз в каждом своём ходу Вы можете бонусным действием отдать команду слуге переместиться на 15 футов и совершить манипуляции с каким-нибудь предметом. Слуга может выполнять простые задачи, доступные человеку, такие как подношение предметов, чистка, починка и складывание одежды, разжигание костра, подача пищи и разливание вина. Получив команду, слуга начинает её выполнять, стараясь изо всех сил, пока не выполнит, после чего начинает ждать Вашу следующую команду.

Если Вы отдаёте слуге поручение, из-за которого тот окажется дальше чем в 60 футах от Вас, заклинание оканчивается.`,
    lvl: 1,
    magicSchoolId: MAGIC_CONJURATION,
    range: 60,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `часть тетивы и кусочек дерева`,
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 244,
      },
      {
        id: SOURCE_SRD,
        page: 189,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь Среднего размера',
        page: 3,
      },
    ],
  },
  {
    id: SPELL_NONDETECTION,
    name: 'Необнаружимость',
    nameEn: 'Nondetection',
    description: `Пока заклинание активно, цель, которой Вы коснулись, сокрыта от заклинаний школы Прорицания. Целью может быть согласное существо, место или предмет, не превышающий 10 фт. ни по одному из линейных размеров. Цель не может стать целью магии школы Прорицания, и ее не воспринимают магические наблюдательные сенсоры.`,
    lvl: 3,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: 'щепотка алмазной пыли, стоящая 25 зм, которой посыпается цель, расходуемая заклинанием',
    duration: { timeId: TIME_HOUR, count: 8 },
    source: [
      {
        id: SOURCE_PHB,
        page: 244,
      },
      {
        id: SOURCE_SRD,
        page: 167,
      },
    ],
  },
  {
    id: SPELL_GENTLE_REPOSE,
    name: 'Нетленные останки',
    nameEn: `Gentle Repose`,
    description: `Вы касаетесь трупа или других останков. Пока заклинание активно, цель защищена от разложения и не может стать нежитью.

Это заклинание также увеличивает ограничение по времени, в течение которого труп можно воскресить, так как дни, проведённые под воздействием этого заклинания, не учитываются при подсчёте времени со дня смерти для таких заклинаний как [оживление](SPELL:${SPELL_RAISE_DEAD}).`,
    lvl: 2,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 0,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `щепотка соли и по одной медной монетке на каждый глаз трупа, которые должны оставаться там всё время, пока активно заклинание`,
    duration: { timeId: TIME_DAY, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 244,
      },
      {
        id: SOURCE_SRD,
        page: 148,
      },
    ],
  },
  {
    id: SPELL_OTTO_S_IRRESISTIBLE_DANCE,
    name: 'Неудержимая пляска Отто',
    nameEn: `Otto’s Irresistible Dance`,
    nameEnAlt: `Irresistible Dance`,
    description: `Выберите одно существо, видимое в пределах дистанции. Цель начинает смешно танцевать на месте, шаркая ногами, притоптывая и подпрыгивая. Существа, которые не могут быть очарованы, обладают иммунитетом к этому заклинанию.

Танцующее существо должно использовать всё своё перемещение на танец, не покидая своё пространство и получая помеху на испытания Ловкости и броски атаки. Пока цель находится под действием этого заклинания, другие существа совершают с преимуществом броски атаки по нему. Танцующее существо может действием пройти испытание Мудрости, чтобы вернуть контроль над телом. При успехе заклинание заканчивается.`,
    lvl: 6,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 30,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 244,
      },
      {
        id: SOURCE_SRD,
        page: 158,
      },
    ],
  },
  {
    id: SPELL_NYSTUL_S_MAGIC_AURA,
    name: 'Нистулова ложная магия',
    nameEn: `Nystul’s Magic Aura`,
    nameEnAlt: `Arcanist’s Magic Aura`,
    description: `Вы помещаете иллюзию на существо или предмет, которого касаетесь, чтобы заклинания школы Прорицания получали о нём ложную информацию. Целью может быть согласное существо или предмет, не несомый и не носимый другим существом.

При накладывании этого заклинания выберите один из описанных ниже эффектов или сразу оба. Эффект длится, пока активно заклинание. Если Вы накладываете это заклинание на одно и то же существо или предмет каждый день в течение 30 дней, используя один и тот же эффект, иллюзия начинает длиться, пока не будет рассеяна.

# Ложная аура

Вы меняете то, как цель представляется для заклинаний и магических эффектов, таких как [обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}), сканирующих магическую ауру. Вы можете немагический предмет заставить выглядеть магическим, магический предмет заставить выглядеть немагическим, или изменить магическую ауру предмета, чтобы казалось, что находящаяся в нём магия принадлежит другой школе магии, выбранной Вами. Если Вы использовали этот эффект на предмете, можете сделать так, что новая магия будет сразу видна существу, держащему его в руках.

# Маскировка

Вы меняете то, как цель представляется для заклинаний и магических эффектов, определяющих вид существ, таких как Божественное чувство паладина и триггер заклинания [знак](SPELL:${SPELL_SYMBOL}). Вы выбираете вид существа, и все заклинания и магические эффекты будут считать, что цель принадлежит этому виду или имеет это мировоззрение.`,
    lvl: 2,
    magicSchoolId: MAGIC_ILLUSION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `небольшой лоскут шёлка`,
    duration: { timeId: TIME_HOUR, count: 24 },
    source: [
      {
        id: SOURCE_PHB,
        page: 244,
      },
      {
        id: SOURCE_SRD,
        page: 119,
      },
    ],
  },
  {
    id: SPELL_GEAS,
    name: 'Обет',
    nameAlt: 'Гейс',
    nameEn: 'Geas',
    description: `Вы отдаёте магическую команду существу, видимому в пределах дистанции, заставляя его оказать некую услугу или наоборот, не совершать чего-либо. Если существо может Вас понять, оно должно пройти испытание Мудрости, иначе оно станет очарованным Вами на время действия заклинания. Пока существо очаровано Вами, оно получает урон психической энергией 5к10 каждый раз, когда действует, противореча Вашим инструкциям, но не чаще одного раза за день. Существо, не способное Вас понять, не попадает под действие этого заклинания.

Вы можете отдать любую команду, кроме той, что однозначно будет самоубийственной. Если Вы отдадите такую команду, заклинание оканчивается.

Вы можете окончить заклинание преждевременно, потратив на это действие. [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}), [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) и [Исполнение желаний](SPELL:${SPELL_WISH}) тоже оканчивают его.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 или 8 уровня, длительность равна 1 году. Если Вы накладываете это заклинание, используя ячейку 9 уровня, заклинание длится, пока не будет окончено одним из упомянутых выше заклинаний.`,
    lvl: 5,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_DAY, count: 30 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_CHARMED,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 5,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 244,
      },
      {
        id: SOURCE_SRD,
        page: 148,
      },
    ],
  },
  {
    id: SPELL_CLOUD_OF_DAGGERS,
    name: 'Облако кинжалов',
    nameEn: `Cloud of Daggers`,
    description: `Вы заполняете воздух крутящимися кинжалами в кубе с длиной ребра 5 футов, с центром на точке, выбранной Вами в пределах дистанции. Существо получает рубящий урон 4к4, когда впервые за ход входит в область заклинания или начинает там ход.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, урон увеличивается на 2к4 за каждый круг ячейки выше второго.`,
    lvl: 2,
    magicSchoolId: MAGIC_CONJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `осколок стекла`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      damage: {
        type: DAMAGE_SLASHING,
        diceCount: 4,
        diceType: 4,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 245,
    },
  },
  {
    id: SPELL_CLOUDKILL,
    name: 'Облако смерти',
    nameEn: 'Cloudkill',
    description: `Вы создаёте сферу с радиусом 20 футов из ядовитого жёлто-зелёного тумана с центром на точке, выбранной Вами в пределах дистанции. Этот туман огибает углы. Он существует, пока активно заклинание, или пока сильный ветер его не развеет, окончив длительность. Его пространство — сильно заслонённая местность.

Когда существо впервые за ход входит в область заклинания или начинает там ход, оно должно пройти испытание Телосложения. Существо получает урон ядом 5к8 при провале или половину этого урона в случае успеха. Существо попадает под действие заклинания даже если задерживает дыхание или вообще не должно дышать.

Облако в начале каждого Вашего хода перемещается на 10 футов прочь от Вас, катясь по поверхности. Испарения, будучи тяжелее воздуха, стекаются вниз, и даже могут проникать в отверстия.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше пятого.`,
    lvl: 5,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_POISON,
        halfOnSuccess: true,
        diceCount: 5,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 245,
      },
      {
        id: SOURCE_SRD,
        page: 125,
      },
    ],
  },
  {
    id: SPELL_DETECT_POISON_AND_DISEASE,
    name: 'Обнаружение болезней и яда',
    nameEn: 'Detect Poison and Disease',
    description: `Пока заклинание активно, Вы можете ощущать присутствие и местонахождение ядов, ядовитых существ и болезней в пределах 30 футов от себя. Вы также распознаёте вид яда, ядовитого существа или болезни.

Заклинание проницает большую часть барьеров, но блокируется 1 футом камня, 1 дюймом обычного металла, тонким листом свинца или 3 футами дерева или земли.`,
    lvl: 1,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `лист тиса`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 245,
      },
      {
        id: SOURCE_SRD,
        page: 134,
      },
    ],
  },
  {
    id: SPELL_DETECT_EVIL_AND_GOOD,
    name: 'Обнаружение добра и зла',
    nameEn: 'Detect Evil and Good',
    description: `Пока заклинание активно, Вы знаете, есть ли в пределах 30 фт. от Вас аберрации, исчадия, небожители, нежить, феи или элементали, а также их местоположение. Кроме того, Вы знаете, есть ли в пределах 30 фт. от Вас место или предмет, который был магически освящён или осквернён.

Заклинание проницает большую часть барьеров, но блокируется 1 футом камня, 1 дюймом обычного металла, тонким листом свинца или 3 футами дерева или земли.`,
    lvl: 1,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 245,
      },
      {
        id: SOURCE_SRD,
        page: 134,
      },
    ],
  },
  {
    id: SPELL_DETECT_MAGIC,
    name: 'Обнаружение магии',
    nameEn: 'Detect Magic',
    description: `Пока заклинание активно, Вы чувствуете присутствие магии в пределах 30 фт. Если Вы почувствовали за счет этого заклинания присутствие магии, Вы можете действием увидеть слабую ауру вокруг видимого существа или предмета, несущего на себе магию, а также узнать школу этой магии, если она есть.

Заклинание проницает большую часть барьеров, но блокируется 1 футом камня, 1 дюймом обычного металла, тонким листом свинца или 3 футами дерева или земли.`,
    lvl: 1,
    magicSchoolId: MAGIC_DIVINATION,
    isRitual: true,
    needConcentration: true,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 245,
      },
      {
        id: SOURCE_SRD,
        page: 134,
      },
    ],
  },
  {
    id: SPELL_DETECT_THOUGHTS,
    name: 'Обнаружение мыслей',
    nameEn: 'Detect Thoughts',
    description: `Пока заклинание активно, Вы можете читать мысли некоторых существ. Если Вы сотворили это заклинание, и в свой ход совершаете действие, Вы можете сосредоточиться на одном существе, которое видите в пределах 30 фт. Если у выбранного существа Интеллект равен 3 или ниже, или если оно не может говорить ни на одном языке, оно не попадает под действие заклинания.

Вначале Вы знаете поверхностные мысли существа — то, что находится в его сознании на текущий момент. Вы можете действием либо перенести внимание на мысли другого существа, либо попытаться углубиться в мысли текущего существа. Если Вы погружаетесь глубже, цель должна пройти испытание Мудрости. В случае провала Вы получаете понимание ее мыслей (если есть), ее эмоционального состояния, и того, что ее больше всего заботит (а также то, что она любит и ненавидит). Если существо преуспеет, заклинание оканчивается. В любом случае, цель знает, что Вы прощупывали ее сознание, и если Вы не перевели внимание на мысли другого существа, предыдущее существо может в свой ход действием совершить проверку Интеллекта, противопоставленную Вашей проверке Интеллекта; в случае успеха заклинание оканчивается.

Вопросы, задаваемые устно цели, влияют на ход ее мыслей, поэтому это заклинание чрезвычайно эффективно во время допросов.

Вы можете также использовать это заклинание для обнаружения присутствия мыслящих существ, которых Вы не видите. Когда Вы накладываете это заклинание, или позже, пока оно активно, потратив действие, Вы можете поискать мысли существ в пределах 30 фт. Заклинание проницает большую часть барьеров, но блокируется 2 футами камня, 2 дюймами обычного металла или тонким листом свинца. Вы не можете обнаружить существ с Интеллектом 3 и ниже, а также тех, кто не говорят ни на одном языке.

Обнаружив таким методом присутствие существа, Вы можете до окончания действия заклинания читать его мысли, как описано выше, даже если Вы его не видите, но оно должно находиться в пределах дистанции.`,
    lvl: 2,
    magicSchoolId: MAGIC_DIVINATION,
    needConcentration: true,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'медная монетка',
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 246,
      },
      {
        id: SOURCE_SRD,
        page: 135,
      },
    ],
  },
  {
    id: SPELL_MAJOR_IMAGE,
    name: 'Образ',
    nameEn: 'Major Image',
    description: `Вы создаете образ предмета, существа или другого видимого явления, помещающийся в объеме в куб с длиной ребра 20 фт. Образ появляется в точке, которую Вы видите в пределах дистанции, и существует, пока активно заклинание. Он выглядит абсолютно реальным, включая звук, запах и температуру, соответствующую этому предмету. Вы не можете создать жар или холод, достаточный для получения урона, звук, громкость которого может причинить урон звуком или оглушить существо, или запах, способный вызвать тошноту (такую как вонь троглодита).

Пока Вы находитесь в пределах дистанции от иллюзии, Вы можете действием заставить образ переместиться в любое место в пределах дистанции. Пока образ меняет местоположение, Вы можете изменять его внешность, чтобы перемещение выглядело естественным. Например, если Вы создаете образ существа и перемещаете его, Вы можете изменить образ, чтобы казалось, что оно идет. Точно так же, Вы можете заставить иллюзию издавать в разное время разные звуки, и даже заставлять ее принимать участие в беседе, например.

Физическое взаимодействие с образом дает понять, что это иллюзия, потому что сквозь него все проходит. Существа, исследующие образ действием, могут определить, что это иллюзия, совершив успешную проверку Интеллекта (Анализ) против СЛ Ваших заклинаний. Если существо распознает иллюзию, оно может видеть сквозь нее, и все прочие сенсорные эффекты иллюзии тоже притупляются.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, оно длится, пока не рассеется, не требуя от Вас концентрацию`,
    lvl: 3,
    magicSchoolId: MAGIC_ILLUSION,
    needConcentration: true,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'кусок овечьей шерсти',
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 246,
      },
      {
        id: SOURCE_SRD,
        page: 162,
      },
    ],
  },
  {
    id: SPELL_COMMUNE,
    name: 'Общение',
    nameEn: 'Commune',
    description: `Вы связываетесь со своим божеством или божественным посредником и задаёте три вопроса, на которые можно ответить «да» или «нет». Вы должны задать вопросы пока заклинание активно. На каждый вопрос Вы получаете правильный ответ.

Божественные создания не всегда всеведущи, поэтому Вы можете получить ответ «неясно», если вопрос находится вне компетенции божества. Если односложный ответ может ввести в заблуждение или противоречит интересам божества, Мастер может выдать короткую фразу.

Если Вы накладываете это заклинание несколько раз до завершения длинного отдыха, существует накопительный шанс 25 процентов за каждое использование, начиная со второго, что Вы не получите ответ. Мастер совершает этот бросок скрытно.`,
    lvl: 5,
    magicSchoolId: MAGIC_DIVINATION,
    castTime: { timeId: TIME_MINUTE, count: 1 },
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'благовоние и флакон святой или нечестивой воды',
    duration: { timeId: TIME_MINUTE, count: 1 },
    isRitual: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 247,
      },
      {
        id: SOURCE_SRD,
        page: 126,
      },
    ],
  },
  {
    id: SPELL_COMMUNE_WITH_NATURE,
    name: 'Общение с природой',
    nameEn: `Commune with Nature`,
    description: `Вы ненадолго сливаетесь с природой и получаете знание об окружающей территории. На открытом воздухе заклинание даёт вам знание о землях в пределах 3 миль. В пещерах и естественном подземном окружении радиус 300 футов. Заклинание не действует там, где природа заменена конструкциями, например, в городах и подвалах.

Вы мгновенно получаете знание о трёх фактах на Ваш выбор о следующих особенностях местности:

* местность и водоёмы
* преобладающие растения, минералы, животные и разумные существа 
* мощные исчадия, небожители, нежить, феи и элементали
* влияние с других планов существования
* строения

Например, Вы можете определить местонахождение мощной нежити, местонахождение чистой воды для питья и местонахождение окрестных поселений.`,
    lvl: 5,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 247,
      },
      {
        id: SOURCE_SRD,
        page: 126,
      },
    ],
  },
  {
    id: SPELL_STAGGERING_SMITE,
    name: 'Оглушающая кара',
    nameEn: 'Staggering Smite',
    description: `В следующий раз, когда Вы попадёте по существу рукопашной атакой оружием, пока активно это заклинание, Ваша атака пронзает не только его тело, но и сознание, и атака причиняет цели дополнительный урон психической энергией 4к6. Цель должна пройти испытание Мудрости. При провале она до конца своего следующего хода совершает с помехой броски атаки и проверки характеристик, а также не может совершать реакции.`,
    lvl: 4,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_WEAPON,
      savethrowParam: PARAM_WIT,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 4,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 247,
    },
  },
  {
    id: SPELL_FIRE_STORM,
    name: 'Огненная буря',
    nameEn: 'Fire Storm',
    description: `В выбранном Вами в пределах дистанции пространстве начинается огненная буря. Область бури состоит из 1–10 кубов с длиной ребра 10 футов, которые Вы можете располагать как вам захочется. Каждый куб должен иметь хотя бы одну общую грань с другим кубом. Все существа в этом пространстве должны пройти испытание Ловкости. Они получают урон огнём 7к10 при провале, или половину этого урона при успехе.

Этот урон повреждает предметы и воспламеняет горючие предметы, которые никто не несёт и не носит. Если хотите, растения не будут затронуты этим заклинанием.`,
    lvl: 7,
    magicSchoolId: MAGIC_EVOCATION,
    range: 150,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_FIRE,
        halfOnSuccess: true,
        diceCount: 7,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 247,
      },
      {
        id: SOURCE_SRD,
        page: 145,
      },
    ],
  },
  {
    id: SPELL_WALL_OF_FIRE,
    name: 'Огненная стена',
    nameEn: 'Wall of Fire',
    description: `Вы создаёте стену из огня на твёрдой поверхности в пределах дистанции. Вы можете создать стену до 60 футов в длину, 20 футов в высоту и 1 фут толщиной, или замкнутой кольцом стеной до 20 футов диаметром, 20 футов высотой и толщиной 1 фут. Стена непрозрачная и существует, пока активно заклинание.

Когда стена появляется, все существа в её области должны пройти испытание Ловкости. При провале они получают урон огнём 5к8 или половину этого урона при успехе.

Одна сторона стены, выбранная при накладывании заклинания, причиняет урон огнём 5к8 всем существам, оканчивающим ход в пределах 10 футов от этой стороны или внутри стены. Существа получают такой же урон когда впервые за ход входят в стену или оканчивают там ход. Другая сторона стены не причиняет урон.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше четвёртого.`,
    lvl: 4,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    needConcentration: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'небольшой кусочек фосфора',
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_FIRE,
        halfOnSuccess: true,
        diceCount: 5,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 147,
      },
      {
        id: SOURCE_SRD,
        page: 190,
      },
    ],
  },
  {
    id: SPELL_BURNING_HANDS,
    name: 'Огненные ладони',
    nameEn: `Burning Hands`,
    description: `Соединив ладони и растопырив пальцы веером, Вы испускаете из них тонкие языки пламени. Все существа в пределах 15-футового конуса должны пройти испытания Ловкости. Существо получает урон огнём 3к6 в случае провала и половину этого урона в случае успеха.

Пламя поджигает все горючие предметы, никем не несомые и не носимые.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: `15-футовый конус`,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_FIRE,
        halfOnSuccess: true,
        diceCount: 3,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 249,
      },
      {
        id: SOURCE_SRD,
        page: 123,
      },
    ],
  },
  {
    id: SPELL_FIRE_BOLT,
    name: 'Огненный снаряд',
    nameEn: 'Fire Bolt',
    description: `Вы кидаете сгусток огня в существо или предмет в пределах дистанции. Совершите по цели дальнобойную атаку заклинанием. При попадании цель получает урон огнём 1к10. Горючие предметы, по которым попало это заклинание, воспламеняются, если их никто не несёт и не носит.`,
    highLvlCast: `Урон этого заклинания увеличивается на 1к10, когда Вы достигаете 5 уровня (2к10), 11 уровня (3к10), 17 уровня (4к10).`,
    lvl: 0,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_FIRE,
        diceCount: 1,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 249,
      },
      {
        id: SOURCE_SRD,
        page: 144,
      },
    ],
  },
  {
    id: SPELL_FIREBALL,
    name: 'Огненный шар',
    nameEn: 'Fireball',
    description: `Яркий луч вылетает из Вашего указательного пальца в точку, выбранную Вами в пределах дистанции, где и происходит взрыв пламени с гулким рёвом. Все существа в пределах сферы с радиусом 20 футов с центром в этой точке должны пройти испытание Ловкости. Цель получает урон огнём 8к6 при провале или половину этого урона при успехе. Этот огонь огибает углы. Он воспламеняет горючие предметы, которые никто не несёт и не носит.`,
    note: `Вскоре от фруктового сада запахло серой, дымом и палёными багберами, с лёгким намеком на персик.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше третьего.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: 150,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `крошечный шарик из гуано летучей мыши и серы`,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_FIRE,
        halfOnSuccess: true,
        diceCount: 8,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 249,
      },
      {
        id: SOURCE_SRD,
        page: 144,
      },
    ],
  },
  {
    id: SPELL_FIRE_SHIELD,
    name: 'Огненный щит',
    nameEn: `Fire Shield`,
    description: `На время длительности заклинания Ваше тело покрывается тонким слоем пламени, испускающим яркий свет в пределах 10-футового радиуса и тусклый свет в пределах ещё 10 футов. Вы можете досрочно окончить это заклинание действием. Это пламя предоставляет вам либо тёплый щит, либо прохладный щит, на Ваш выбор. Тёплый щит даёт вам сопротивление к урону холодом, а прохладный щит даёт сопротивление к урону огнём.

Кроме того, каждый раз, когда существо, находящееся в пределах 5 футов от Вас, попадает по вам рукопашной атакой, щит испускает пламя. Атакующий получает урон огнём 2к8 в случае тёплого щита или урон холодом 2к8 в случае прохладного щита.`,
    lvl: 4,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек фосфора или светлячок`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    effect: {
      damage: {
        type: [
          DAMAGE_FIRE,
          DAMAGE_COLD,
        ],
        diceCount: 2,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 249,
      },
      {
        id: SOURCE_SRD,
        page: 144,
      },
    ],
  },
  {
    id: SPELL_FAERIE_FIRE,
    name: 'Огонь фей',
    nameEn: 'Faerie Fire',
    description: `Все предметы в кубе с длиной ребра 20 футов в пределах дистанции подсвечиваются синим, зелёным или фиолетовым цветом (на Ваш выбор). Все существа в этой области тоже подсвечиваются, если проваливают испытание Ловкости. На время длительности заклинания подсвеченные предметы и существа испускают тусклый свет в радиусе 10 футов.

Все броски атаки по затронутым существам и предметам совершаются с преимуществом, если атакующий видит их, и эти существа и предметы не получают преимуществ от невидимости.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 250,
      },
      {
        id: SOURCE_SRD,
        page: 141,
      },
    ],
  },
  {
    id: SPELL_RAISE_DEAD,
    name: 'Оживление',
    nameAlt: 'Возвращение к жизни',
    nameEn: 'Raise Dead',
    description: `Вы возвращаете к жизни мёртвое существо, которого касаетесь, при условии, что оно мертво не более 10 дней. Если душа этого существа может воссоединиться с телом и хочет этого, существо воскрешается с 1 хитом.

Это заклинание также нейтрализует все яды и исцеляет немагические болезни, бывшие у существа в момент смерти. Однако это заклинание не снимает магические болезни, проклятья и подобные эффекты; если их не снять до накладывания этого заклинания, они снова начнут действовать, когда существо оживёт. Это заклинание не может оживить нежить.

Это заклинание исцеляет все раны, но не восстанавливает отсутствующие части тела. Если у существа отсутствуют жизненно важные части тела — например, голова — заклинание автоматически проваливается.

Возвращение к жизни — тяжёлое испытание. Цель получает штраф −4 ко всем броскам атаки, испытаниям и проверкам характеристик. Каждый раз, когда цель заканчивает длительный отдых, штраф уменьшается на 1, пока не исчезнет полностью.`,
    lvl: 5,
    magicSchoolId: MAGIC_NECROMANCY,
    castTime: { timeId: TIME_HOUR, count: 1 },
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: 'бриллиант, стоящий как минимум 500 зм, расходуемый заклинанием',
    source: [
      {
        id: SOURCE_PHB,
        page: 250,
      },
      {
        id: SOURCE_SRD,
        page: 173,
      },
    ],
  },
  {
    id: SPELL_ANIMATE_OBJECTS,
    name: 'Оживление вещей',
    nameEn: `Animate Objects`,
    description: `По Вашему приказу оживают предметы. Выберите до десяти немагических предмета в пределах дистанции, которые никто не несёт и не носит. Цели Среднего размера считаются за два предмета, Большие цели считаются за четыре предмета, Огромные цели считаются за восемь предметов. Оживлять предметы с размером больше Огромного нельзя. Все цели оживают и становятся существами под Вашим контролем до окончания действия заклинания или пока их хиты не уменьшатся до 0.

Вы можете бонусным действием мысленно отдать приказ существу, созданному этим заклинанием, если оно находятся в пределах 500 футов от Вас (если Вы контролируете несколько существ, Вы можете отдавать один и тот же приказ любому количеству из них одновременно). Вы решаете, какое действие совершит это существо, и куда оно переместится в следующем ходу, или Вы можете отдать общий приказ, например, охранять комнату или коридор. Если Вы не отдадите команду, существо будет всего лишь защищаться от врагов. Получив приказ, существо продолжает его выполнять, пока задача не будет выполнена.

Оживлённый предмет является конструктом с КД, хитами, атаками, Силой и Ловкостью, зависящими от его размера. Его Телосложение 10, Интеллект и Мудрость 3, а Харизма 1. Его скорость 30 футов; если у предмета нет ног или других придатков, которые можно использовать для перемещения, то он получает скорость полёта 30 футов и может парить. Если предмет прикреплён к поверхности или большему предмету, например, это цепь, прикреплённая к стене, его скорость равна 0. Он обладает слепым зрением в радиусе 30 футов и ничего не воспринимает за этими пределами. Когда хиты оживлённого предмета опускаются до 0, он возвращает себе свой обычный облик, а излишний урон переносится на оригинальный облик.

Если Вы приказываете предмету атаковать, он может совершить одну рукопашную атаку по существу в пределах 5 футов от него. Он совершает размашистую атаку с бонусом атаки и дробящим уроном, зависящим от его размера. Мастер может постановить, что этот предмет причиняет рубящий или колющий урон.

# Параметры оживлённого предмета

| Размер    | Хиты | КД | Атака                     | Сил | Лов |
|-----------|------|----|---------------------------|-----|-----|
| Крошечный | 20   | 18 | +8 к атаке, урон 1к4 + 4  | 4   | 18  |
| Маленький | 25   | 16 | +6 к атаке, урон 1к8 + 2  | 6   | 14  |
| Средний   | 40   | 13 | +5 к атаке, урон 2к6 + 1  | 10  | 12  |
| Большой   | 50   | 10 | +6 к атаке, урон 2к10 + 2 | 14  | 10  |
| Огромный  | 80   | 10 | +8 к атаке, урон 2к12 + 4 | 18  | 6   |`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, Вы можете оживить два дополнительных предмета за каждый круг ячейки выше пятого.`,
    lvl: 5,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 250,
      },
      {
        id: SOURCE_SRD,
        page: 116,
      },
    ],
  },
  {
    id: SPELL_FLESH_TO_STONE,
    name: 'Окаменение',
    nameEn: `Flesh to Stone`,
    description: `Вы пытаетесь сделать каменным одно существо, которое видите в пределах дистанции. Если тело цели состоит из плоти, она должна пройти испытание Телосложения. При провале она обездвиживается, так как плоть начитает отвердевать. При успехе существо не попадает под действие заклинания.

Существо, обездвиженое этим заклинанием, должно проходить испытание Телосложения в конце каждого своего хода. Если оно успешно спасается от этого заклинания три раза, оно оканчивается. Если оно провалит испытание три раза, оно превращается в камень и получает состояние «окаменевшее» на время длительности заклинания. Успехи и провалы не обязаны быть последовательными; отслеживайте их отдельно, пока не накопится три вида чего-то одного.

Если существо физически ломается, будучи окаменевшим, при возвращении в обычное тело оно сохраняет все полученные деформации.

Если Вы сохраняете концентрацию на этом заклинании до максимально возможного времени, существо становится окаменевшим до снятия эффекта.`,
    lvl: 6,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `щепотка извести, воды и земли`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_CON,
      condition: [
        CONDITION_RESTRAINED,
        CONDITION_PETRIFIED,
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 251,
      },
      {
        id: SOURCE_SRD,
        page: 145,
      },
    ],
  },
  {
    id: SPELL_IDENTIFY,
    name: 'Опознание',
    nameEn: 'Identify',
    description: `Вы выбираете один предмет, не переставая касаться его во время всего накладывания заклинания. Если это магический предмет, Вы узнаёте его свойства, способ их использования, требуется ли для использования настройка на него, и сколько осталось зарядов, если они есть. Вы узнаёте, действуют ли в данный момент заклинания на этот предмет, и что это за заклинания. Если предмет был создан заклинанием, Вы узнаёте, что это было за заклинание.

Если Вы вместо этого во время накладывания заклинания касаетесь существа, Вы узнаёте, какие заклинания в данный момент действуют на него.`,
    lvl: 1,
    magicSchoolId: MAGIC_DIVINATION,
    range: 0,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `жемчужина, стоящая как минимум 100 зм, и перо совы`,
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 251,
      },
      {
        id: SOURCE_SRD,
        page: 155,
      },
    ],
  },
  {
    id: SPELL_ENTANGLE,
    name: 'Опутывание',
    nameEn: 'Entangle',
    description: `Из точки в пределах дистанции на площади квадрата с длиной стороны в 20 футов вырастают цепкие травы и плети. Пока заклинание активно, эти растения превращают землю в труднопроходимую местность.

Существа, находящиеся в этой области, когда Вы накладываете заклинание, должны пройти испытание Силы, иначе они обездвиживаются растениями до окончания действия заклинания. Обездвиженое растениями существо может действием совершать проверки Силы против СЛ Ваших заклинаний. При успехе оно освобождается.

Когда заклинание оканчивается, вызванные растения увядают. `,
    lvl: 1,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    needConcentration: true,
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_STR,
      condition: CONDITION_RESTRAINED,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 251,
      },
      {
        id: SOURCE_SRD,
        page: 140,
      },
    ],
  },
  {
    id: SPELL_ENSNARING_STRIKE,
    name: 'Опутывающий удар',
    nameEn: `Ensnaring Strike`,
    description: `Когда Вы в следующий раз попадёте по существу атакой оружием, пока заклинание активно, в точке удара появляется извивающаяся масса растений с шипами, и цель должна пройти испытание Силы, иначе она обездвиживается магическими плетьми, пока заклинание активно. Существа с размером Большое и ещё больше проходят это испытание с преимуществом. Если цель преуспевает в испытании, плети усыхают.

Будучи обездвиженой этим заклинанием, цель получает колющий урон 1к6 в начале каждого своего хода. Обездвиженое существо или существо, способное коснуться его, может действием совершить проверку Силы против СЛ Ваших заклинаний. При успехе цель освобождается.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_CONJURATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_STR,
      condition: CONDITION_RESTRAINED,
    },
    source: {
      id: SOURCE_PHB,
      page: 251,
    },
  },
  {
    id: SPELL_BLINDING_SMITE,
    name: 'Ослепляющая кара',
    nameEn: `Blinding Smite`,
    description: `Когда Вы в следующий раз попадёте по существу атакой рукопашным оружием, пока заклинание активно, Ваше оружие вспыхивает ярким светом, и атака причиняет цели дополнительный урон излучением 3к8. Кроме того, цель должна пройти испытание Телосложения, иначе она станет ослеплённой до окончания заклинания. Ослеплённое этим заклинанием существо проходит испытания Телосложения в конце каждого своего хода. При успехе оно перестаёт быть ослеплённым.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_WEAPON,
      savethrowParam: PARAM_CON,
      condition: CONDITION_BLINDED,
      damage: {
        type: DAMAGE_RADIANT,
        diceCount: 3,
        diceType: 8,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 251,
    },
  },
  {
    id: SPELL_TIME_STOP,
    name: 'Остановка времени',
    nameEn: `Time Stop`,
    description: `Вы ненадолго останавливаете поток времени для всех кроме себя. Для всех остальных существ время перестаёт течь, а Вы получаете 1к4 + 1 ход подряд, во время которых можете совершать действия и перемещаться как обычно.

Это заклинание оканчивается, если одно из Ваших действий, совершённых за этот период, или любой из эффектов, созданных Вами за этот период, воздействует на других существ или предмет, несомый или носимый кем-то другим. Кроме того, заклинание оканчивается, если Вы перемещаетесь в место, находящееся более чем в 1 000 футов от того места, где Вы его активировали.`,
    lvl: 9,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    source: [
      {
        id: SOURCE_PHB,
        page: 251,
      },
      {
        id: SOURCE_SRD,
        page: 187,
      },
    ],
  },
  {
    id: SPELL_OTILUKE_S_FREEZING_SPHERE,
    name: 'Отилюков ледяной шар',
    nameEn: `Otiluke’s Freezing Sphere`,
    nameEnAlt: `Freezing Sphere`,
    description: `Шарик холодной энергии слетает с Ваших пальцев и устремляется к точке на Ваш выбор в пределах дистанции, где взрывается сферой с радиусом 60 футов. Все существа в этой области должны пройти испытание Телосложения. При провале существо получает урон холодом 10к6. При успехе оно получает половину урона.

Если шар попадает в воду или жидкость, состоящую в основном из воды (исключая водных существ), он замораживает жидкость на глубину 6 дюймов в пределах квадрата с длиной стороны 30 футов. Лёд существует 1 минуту. Существа, плававшие по поверхности замороженной воды, сковываются льдом. Скованное существо может действием совершать проверки Силы против СЛ Ваших заклинаний, чтобы высвободиться.

Если хотите, можете не метать шар после накладывания этого заклинания. В этом случае в Вашей руке появляется маленький прохладный шарик размером со снаряд для пращи. В любое время Вы или существо, которому Вы отдадите шарик, можете метнуть его рукой (дистанция 40 футов) или из пращи (обычная дистанция пращи).

При столкновении он взрывается с тем же эффектом, что и при обычном использовании этого заклинания. Вы можете уничтожить шарик, не взрывая его. Через 1 минуту, если шарик ещё не взорвался, он взрывается.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, урон увеличиваетс я на 1к6 за каждый круг ячейки выше шестого.`,
    lvl: 6,
    magicSchoolId: MAGIC_EVOCATION,
    range: 300,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `маленький хрустальный шар`,
    effect: {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_COLD,
        halfOnSuccess: true,
        diceCount: 10,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 252,
      },
      {
        id: SOURCE_SRD,
        page: 147,
      },
    ],
  },
  {
    id: SPELL_OTILUKE_S_RESILIENT_SPHERE,
    name: 'Отилюков упругий шар',
    nameEn: `Otiluke’s Resilient Sphere`,
    nameEnAlt: `Resilient Sphere`,
    description: `Сфера из мерцающей силы окружает находящееся в пределах дистанции существо или предмет с размером не больше Большого. Нежелающее существо должно пройти испытание Ловкости. При провале существо окружается сферой на время действия заклинания.

Ничто — ни физические предметы, ни энергия, ни эффекты прочих заклинаний — не могут проходить через этот барьер, как внутрь, так и наружу, хотя существо в сфере может дышать нормально. Сфера обладает иммунитетом ко всем видам урона, и находящееся внутри существо или предмет не может получать урон от атак и эффектов, исходящих снаружи, но и находящееся в сфере существо не может причинить урон ничему, что находится снаружи.

Сфера ничего не весит, и она достаточно велика, чтобы вместить существо или предмет. Окружённое существо может действием толкать стены сферы и таким образом перемещаться со скоростью, уменьшенной вдвое. Точно так же, сферу могут поднять и переносить другие существа.

Заклинание [распад](SPELL:${SPELL_DISINTEGRATE}), нацеленное на сферу, уничтожает её, не причиняя вреда тому, что находится внутри.`,
    lvl: 4,
    magicSchoolId: MAGIC_EVOCATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `полусферический кусочек чистого кристалла и такая же по размерам полусфера из гуммиарабика`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 252,
      },
      {
        id: SOURCE_SRD,
        page: 175,
      },
    ],
  },
  {
    id: SPELL_KNOCK,
    name: 'Открывание',
    nameEn: `Knock`,
    description: `Выберите предмет, видимый в пределах дистанции. Это может быть дверь, ящик, сундук, кандалы, навесной замок, или другой предмет, содержащий обычное или магическое средство, предотвращающее доступ.

Цель, запертая обычным замком, заклинившая или запертая на засов, становится незапертой, незаклинившей, а засовы сами открываются. Если на предмете было несколько замков, открывается только один из них.

Если Вы выбрали цель, запертую [волшебным замком](SPELL:${SPELL_ARCANE_LOCK}), это заклинание подавляется на 10 минут, во время которых цель может открываться и закрываться как обычно.

Когда Вы накладываете это заклинание, от цели исходит громкий стук, слышимый с расстояния в 300 футов.`,
    lvl: 2,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 60,
    componentIdList: [CAST_VERBAL],
    source: [
      {
        id: SOURCE_PHB,
        page: 253,
      },
      {
        id: SOURCE_SRD,
        page: 158,
      },
    ],
  },
  {
    id: SPELL_MIRROR_IMAGE,
    name: 'Отражения',
    nameEn: 'Mirror Image',
    description: `В Вашем пространстве появляются три Ваших иллюзорных копии. Пока заклинание активно, копии перемещаются вместе с Вами и подражают Вашим действиям, двигаясь так, что невозможно понять, кто из Вас настоящий. Вы можете действием распустить свои копии.

Каждый раз, когда существо нацеливается на Вас атакой, пока заклинание активно, бросайте к20, чтобы определить, не попала ли атака вместо Вас по одной из Ваших копий.

Если у Вас три копии, Вы должны выбросить «6» или больше, чтобы сделать целью копию. Если копий две, выбросить нужно «8» или больше. Если копия одна, Вы должны выбросить «11» или больше.

КД копии равен 10 + Ваш модификатор Ловкости. Если атака попала по копии, она уничтожается. Копию может уничтожить только атака, попавшая по ней. Она игнорирует остальной урон и эффекты. Заклинание оканчивается, если все три копии будут уничтожены.

Существо не попадает под действие этого заклинания, если не может видеть, если полагается на другие чувства кроме зрения, например, на слепое зрение, или если может видеть сквозь иллюзию, например, при помощи истинного зрения.`,
    lvl: 2,
    magicSchoolId: MAGIC_ILLUSION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 252,
      },
      {
        id: SOURCE_SRD,
        page: 165,
      },
    ],
  },
  {
    id: SPELL_GLYPH_OF_WARDING,
    name: 'Охранные руны',
    nameEn: `Glyph of Warding`,
    description: `Когда Вы накладываете это заклинание, Вы рисуете руну, причиняющую вред другим существам, либо на поверхности (такой как стол или часть пола или стены) либо в предмете, который можно закрыть (книга, свиток или сундук с сокровищами), спрятав, таким образом, руну. Руна может покрывать площадь диаметром не больше 10 футов. Если поверхность или предмет переместят более чем на 10 футов от места сотворения этого заклинание, то руна сломается, и заклинание окончится, не срабатывая.

Руна практически невидимая, и для её обнаружения требуется успешная проверка Интеллекта (Анализ) против СЛ Ваших заклинаний.

Вы решаете, что вызовет срабатывание руны, когда накладываете заклинание. Руны, написанные на поверхности, обычно срабатывают от касания, вставания на неё, снимания предмета, стоящего на руне, приближения на определённое расстояние, или манипулирования предметом, на котором начертана руна. Для рун, начертанных внутри предметов, чаще всего выбирают открывание, приближение на определённое расстояние, взгляд, брошенный на руну или попытку её прочтения. После того как руна сработает, заклинание оканчивается.

Вы можете внести уточнения, чтобы заклинание срабатывало только в определённых обстоятельствах или при соблюдении внешних признаков (таких как рост и вес), вид существа (например, руна может защищать только от аберраций и дроу), или мировоззрение. Вы можете также определить условия, при которых существо не будет активировать руну, например, произношение пароля.

Когда Вы наносите руну, выберите _взрывную руну_ или _заклинательную руну_.

# Взрывная руна

Когда эта руна срабатывает, она испускает магическую энергию сферой с радиусом 20 футов с центром на руне. Эта сфера огибает углы. Все существа в этой области должны пройти испытание Ловкости. Существо получает урон звуком, кислотой, огнём, холодом или электричеством 5к8 при провале (вид урона Вы выбираете при начертании руны), или половину этого урона при успехе.

# Заклинательная руна

Вы можете сохранить подготовленное заклинание с уровнем не выше 3 в руне, наложив его частью начертания руны. Заклинание должно нацеливаться на одно существо или область. При этом заклинание временно не вступает в силу. Когда руна срабатывает, хранящееся заклинание активируется. Если у заклинания есть цель, целью становится существо, вызвавшее срабатывание руны. Если заклинание действует на область, центр этой области будет на этом существе. Если заклинание призывает враждебных существ или создаёт вредоносные предметы или ловушки, они появляются как можно ближе к нарушителю и атакуют его. Если заклинание требует концентрации, оно длится до конца своей максимальной длительности`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, урон от _взрывной руны_ увеличивается на 1к8 за каждый круг ячейки выше третьего. Если Вы накладывали _заклинательную руну_, Вы можете хранить в ней заклинание с уровнем, равным уровню ячейки, использованной для _охранных рун_.`,
    lvl: 3,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `благовония и растолчённый бриллиант, стоящий как минимум 200 зм, расходуемый заклинанием`,
    duration: [Infinity, `пока не сработает`],
    castTime: { timeId: TIME_HOUR, count: 1 },
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: [
          DAMAGE_ACID,
          DAMAGE_COLD,
          DAMAGE_ELECTRICITY,
          DAMAGE_FIRE,
          DAMAGE_THUNDER,
        ],
        halfOnSuccess: true,
        diceCount: 5,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 253,
      },
      {
        id: SOURCE_ERRATA_PHB,
        title: 'Теперь и запрет на перемещение и ограничение размера руны касаются как предмета, так и поверхности.',
        page: 3,
      },
      {
        id: SOURCE_SRD,
        page: 149,
      },
    ],
  },
  {
    id: SPELL_WARDING_BOND,
    name: 'Охраняющая связь',
    nameEn: `Warding Bond`,
    description: `Это заклинание защищает согласное существо, которого Вы касаетесь, и создаёт мистическую связь между Вами. Пока цель находится в пределах 60 футов от Вас, она получает бонус +1 к КД и испытаниям, а также сопротивление ко всем видам урона. Кроме того, каждый раз, когда она получает урон, Вы получаете такое же количество урона.

Это заклинание оканчивается, если Ваши хиты опускаются до 0 или если расстояние между Вами и целью становится больше 60 футов. Оно также заканчивается, если это заклинание наложить повторно на одно из связанных существ. Вы также можете окончить это заклинание действием.`,
    lvl: 2,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `пара платиновых колец, стоящая как минимум 50 зм каждое, которые Вы с целью должны носить на время действия заклинания`,
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 253,
      },
      {
        id: SOURCE_SRD,
        page: 191,
      },
    ],
  },
  {
    id: SPELL_CHARM_PERSON,
    name: 'Очарование личности',
    nameAlt: 'Приворот гуманоида',
    nameEn: 'Charm Person',
    description: `Вы пытаетесь очаровать гуманоида, которого видите в пределах дистанции. Он должен пройти испытание Мудрости, с преимуществом, если Вы или Ваши спутники сражаетесь с ним. Если он проваливает испытание, он очарован Вами, пока заклинание активно или пока Вы или Ваши спутники не причините ему вред. Очарованное существо считает Вас своим другом. Когда заклинание оканчивается, существо знает, что было очаровано Вами.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше первого. Эти существа должны находиться в пределах 30 футов друг от друга, чтобы Вы могли на них нацелиться.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_CHARMED,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 254,
      },
      {
        id: SOURCE_SRD,
        page: 124,
      },
    ],
  },
  {
    id: SPELL_PURIFY_FOOD_AND_DRINK,
    name: 'Очищение пищи и питья',
    nameEn: 'Purify Food and Drink',
    description: `Вся немагическая еда и питьё в пределах сферы с радиусом 5 фт. с центром на точке, выбранной Вами в пределах дистанции, очищается и избавляется от ядов и болезней.`,
    lvl: 1,
    magicSchoolId: MAGIC_TRANSMUTATION,
    isRitual: true,
    range: 10,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: [
      {
        id: SOURCE_PHB,
        page: 254,
      },
      {
        id: SOURCE_SRD,
        page: 173,
      },
    ],
  },
  {
    id: SPELL_FEATHER_FALL,
    name: 'Падение пёрышком',
    nameAlt: 'Плавное падение',
    nameEn: 'Feather Fall',
    description: `Выберите до пяти падающих существ в пределах дистанции. До окончания действия заклинания их скорость падения уменьшается до 60 футов в раунд.

Если такое существо приземлится до окончания заклинания, оно не получает урон от падения и может приземлиться на ноги, и тогда заклинание оканчивает действие на него.`,
    castTime: { timeId: TIME_REACTION, count: 1 },
    castTimeComment: `совершаемая, когда Вы или существо в пределах 60 футов от Вас начинаете падать`,
    lvl: 1,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'небольшое перо или кусочек пуха',
    duration: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 254,
      },
      {
        id: SOURCE_SRD,
        page: 142,
      },
    ],
  },
  {
    id: SPELL_SEARING_SMITE,
    name: 'Палящая кара',
    nameEn: 'Searing Smite',
    description: `Когда Вы в следующий раз попадёте по существу рукопашной атакой оружием, пока заклинание активно, Ваше оружие вспыхивает ярким белым светом, и атака причиняет цели дополнительный урон огнём 1к6 и поджигает её. Пока заклинание активно, цель в начале каждого своего хода должна проходить испытание Телосложения. При провале она получает урон огнём 1к6. При успехе заклинание заканчивается. Если цель или существо, находящееся в пределах 5 футов от неё, потратит действие на тушение пламени, или если какой-то другой эффект потушит пламя (например, погружение в воду), заклинание тоже закончится.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, первичный дополнительный урон от атаки увеличивается на 1к6 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_WEAPON,
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_FIRE,
        diceCount: 1,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 256,
    },
  },
  {
    id: SPELL_SCORCHING_RAY,
    name: 'Палящий луч',
    nameEn: `Scorching Ray`,
    description: `Вы создаёте три огненных луча и направляете их на целей, находящихся в пределах дистанции. Это может быть одна или несколько целей.

Для каждого луча совершите дальнобойную атаку заклинанием. При попадании цель получает урон огнём 2к6.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, Вы создаёте один дополнительный луч за каждый круг ячейки выше второго.`,
    lvl: 2,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_FIRE,
        diceCount: 2,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 256,
      },
      {
        id: SOURCE_SRD,
        page: 176,
      },
    ],
  },
  {
    id: SPELL_WEB,
    name: 'Паутина',
    nameEn: 'Web',
    description: `Вы создаёте массу густой и клейкой паутины в точке на Ваш выбор в пределах дистанции. Паутина заполняет куб с длиной ребра 20 футов на время длительности заклинания. Паутина является труднопроходимой местностью и слабо заслоняет местность.

Если паутина не прикована к двум прочным структурам (таким как стены или деревья) и не разложена по полу, стене или потолку, созданная паутина опадает сама, и заклинание оканчивается в начале Вашего следующего хода. Паутина, разложенная по плоской поверхности, обладает толщиной 5 футов.

Все существа, начинающие ход в паутине, или входящие в неё в свой ход, должны проходить испытание Ловкости. При провале существо обездвиживается, пока остаётся в паутине, или пока не вырвется.

Существо, обездвиженое паутиной, может действием совершить проверку Силы против СЛ Ваших заклинаний. При успехе оно перестаёт быть обездвиженым.

Паутина огнеопасна. Любой 5-футовый куб паутины, соприкоснувшийся с огнём, сгорает за 1 раунд, причиняя урон огнём 2к4 всем существам, начинающим ход в огне.`,
    lvl: 2,
    magicSchoolId: MAGIC_CONJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек паутины`,
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
      condition: CONDITION_RESTRAINED,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 256,
      },
      {
        id: SOURCE_SRD,
        page: 192,
      },
    ],
  },
  {
    id: SPELL_DIMENSION_DOOR,
    name: 'Переносящая дверь',
    nameAlt: 'Дверь в пространстве',
    nameEn: 'Dimension Door',
    note: {
      text: `Зачем сражаться, когда можно поболтать? В случае проблем, можно просто исчезнуть через переносящую дверь.`,
      author: `Таша`,
    },
    description: `Вы телепортируетесь в другое пространство в пределах дистанции. Вы оказываетесь точно в выбранном месте. Это может быть место, которое Вы видели, которое Вы представляете, или которое Вы можете описать указаниями направления и расстояния, например, «200 футов подо мной» или «на северо-запад, вверх под углом 45 градусов, на 300 футов».

Вы можете взять с собой предметы, если их вес позволяет вам их нести. Вы также можете взять с собой одно согласное существо, с размером не больше Вашего, которое тоже может нести дозволенный вес предметов. При накладывании заклинания существо должно находиться в пределах 5 футов от Вас.

Если Вы должны оказаться в месте, уже занятом существом или предметом, Вы и все перемещающиеся с Вами существа получаете урон силовым полем 4к6, и заклинание Вас не телепортирует.`,
    lvl: 4,
    magicSchoolId: MAGIC_CONJURATION,
    range: 500,
    componentIdList: [CAST_VERBAL],
    effect: {
      damage: {
        type: DAMAGE_FORCE,
        diceCount: 4,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 256,
      },
      {
        id: SOURCE_SRD,
        page: 135,
      },
    ],
  },
  {
    id: SPELL_HEROES_FEAST,
    name: 'Пир героев',
    nameEn: 'Heroes Feast',
    description: `Вы организовываете шикарный пир, включая великолепные пищу и питьё. Пир совершается в течение 1 часа, и исчезает по прошествии этого времени, но положительные эффекты не вступают в силу, пока этот час не пройдёт. В пире могут принимать участие до двенадцати существ.

Существо, принявшее участие в пиршестве, получает несколько преимуществ. Оно излечивается от всех болезней и ядов, приобретает иммунитет к ядам и состоянию испуга, проходит испытания Мудрости с преимуществом. Максимум его хитов увеличивается на 2к10, и оно получает такое же количество хитов. Все эти преимущества длятся 24 часа.`,
    lvl: 6,
    magicSchoolId: MAGIC_CONJURATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `инкрустированная драгоценными камнями чаша, стоящая как минимум 1 000 зм, расходуемая заклинанием`,
    castTime: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 256,
      },
      {
        id: SOURCE_SRD,
        page: 154,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь сам заклинатель тоже может вкушать яства',
        page: 3,
      },
    ],
  },
  {
    id: SPELL_PLANAR_BINDING,
    name: 'Планарные узы',
    nameEn: `Planar Binding`,
    description: `При помощи этого заклинания Вы можете попытаться подчинить исчадие, небожителя, фею или элементаля. Существо должно находиться в пределах дистанции весь период накладывания заклинания (обычно для этого существо призывается в центр вывернутого [магического круга](SPELL:${SPELL_MAGIC_CIRCLE})). В конце накладывания заклинания цель должна пройти испытание Харизмы. При провале она должна служить вам, пока заклинание активно. Если существо было призвано или создано другим заклинанием, то длительность того заклинания увеличивается, чтобы совпадать с длительностью этого заклинания.

Подчинённое существо должно выполнять Ваши инструкции. Вы можете приказать ему сопровождать Вас в приключении, охранять место, или передать послание. Существо выполняет Ваши инструкции буквально, и если оно враждебно по отношению к Вам, оно будет искать возможность помешать Вам. Если существо выполняет Ваши инструкции до того как заклинание оканчивается, оно отправляется к Вам, чтобы сообщить об этом, если Вы с ним находитесь на одном плане существования. Если Вы находитесь на разных планах, оно возвращается к месту, где Вы подчинили его, и остаётся там, пока заклинание не окончится.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку большего уровня, длительность увеличивается до 10 дней при использовании ячейки 6 уровня, 30 дней при использовании ячейки 7 уровня, 180 дней при использовании ячейки 8 уровня, и года и одного дня при использовании ячейки 9 уровня.`,
    lvl: 5,
    magicSchoolId: MAGIC_ABJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `ювелирное украшение, стоящее как минимум 1 000 зм, расходуемое заклинанием`,
    duration: { timeId: TIME_HOUR, count: 24 },
    castTime: { timeId: TIME_HOUR, count: 1 },
    effect: {
      savethrowParam: PARAM_CHA,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 257,
      },
      {
        id: SOURCE_SRD,
        page: 168,
      },
    ],
  },
  {
    id: SPELL_PLANAR_ALLY,
    name: 'Планарный союзник',
    nameEn: `Planar Ally`,
    description: `Вы просите иномировую сущность оказать помощь.

Сущность должна быть известна Вам: это может быть бог, предтеча, демонический повелитель или другая сущность космического масштаба. Эта сущность посылает Вам своего верного небожителя, элементаля или исчадие, создавая его в свободном пространстве в пределах дистанции. Если Вы знаете имя конкретного существа, Вы можете произнести его в момент накладывания заклинания, чтобы попросить прислать его, но Вы всё равно можете получить другое существо (на выбор Мастера).

Когда существо появляется, оно не обязано что-либо делать. Вы можете просить его выполнить услугу в обмен на некую плату, но оно не обязано соглашаться. Услуга может быть как простой (перелёт над пропастью, помощь в сражении), так и комплексной (слежка за врагом, защита во время исследования подземелья). Для заключения сделки Вы должны иметь возможность как-то общаться с существом.

Плата может быть разной. Небожитель может потребовать пожертвования золота или магических предметов для храма, а исчадие может потребовать жертвоприношение существа или сокровища в дар. Некоторые существа могут обменивать свои услуги на Ваши услуги.

Обычно задание, длительность которого можно измерить минутами, стоит по 100 зм за минуту. Задание, измеряемое часами, требует 1 000 за час. Задача, измеряемая днями (не более 10 дней) требует 10 000 зм за каждый день. Мастер может корректировать размер оплаты, основываясь на обстоятельствах, в которых Вы используете это заклинание. Если задача связана с характером существа, оплата может быть уменьшена вдвое или вообще устранена. Неопасные задачи обычно требуют половину оплаты, а особо опасные поручения могут требовать увеличенной награды. Существа редко соглашаются на самоубийственные задания.

Выполнив поручение, или после оговорённого срока существо возвращается на свой домашний план после отчёта Вам, если это не противоречит задаче и вообще возможно. Если Вы не сошлись в цене, существо мгновенно возвращается на домашний план.

Существо, которому поручено войти в состав Вашего отряда, считается его членом, и получает свою долю опыта.`,
    lvl: 6,
    magicSchoolId: MAGIC_CONJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    castTime: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 257,
      },
      {
        id: SOURCE_SRD,
        page: 168,
      },
    ],
  },
  {
    id: SPELL_CHAIN_LIGHTNING,
    name: 'Пляшущая молния',
    nameAlt: 'Цепная молния',
    nameEn: `Chain Lightning`,
    description: `Вы создаёте разряд молнии, которая дугой бьёт цель, выбранную и видимую Вами в пределах дистанции. Затем три разряда перепрыгивают с неё на другие цели, которых может быть не больше трёх, каждая из которых должна находиться в пределах 30 футов от первой цели. Цель может быть существом или предметом, и может быть целью только одного разряда. Цель должна пройти испытание Ловкости.

Цель получает урон электричеством 10к8 при провале испытания или половину этого урона в случае успеха.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, из первой цели бьёт дополнительный разряд в другую цель за каждый круг ячейки выше шестого.`,
    lvl: 6,
    magicSchoolId: MAGIC_EVOCATION,
    range: 150,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек меха; кусок янтаря, стекла или кристаллический жезл; три серебряные булавки`,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_ELECTRICITY,
        halfOnSuccess: true,
        diceCount: 10,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 257,
      },
      {
        id: SOURCE_SRD,
        page: 124,
      },
    ],
  },
  {
    id: SPELL_DANCING_LIGHTS,
    name: 'Пляшущие огоньки',
    nameEn: 'Dancing Lights',
    description: `Вы создаете до четырех огоньков размером с факел в пределах дистанции, делая их похожими на факелы, фонари или светящиеся сферы, парящие в воздухе. Вы можете также объединить четыре огонька в одну светящуюся человекоподобную фигуру Среднего размера. Какую бы форму Вы не выбрали, каждый огонек излучает тусклый свет в радиусе 10 фт.

Вы можете бонусным действием в свой ход переместить огоньки на 60 фт. в новое место в пределах дистанции. Каждый огонек должен находиться в пределах 20 фт. от другого огонька, созданного этим заклинанием, и огонек тухнет, если оказывается за пределами дистанции заклинания.`,
    lvl: 0,
    magicSchoolId: MAGIC_EVOCATION,
    needConcentration: true,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'кусочек фосфора, гнилушка или светлячок',
    duration: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 258,
      },
      {
        id: SOURCE_SRD,
        page: 133,
      },
    ],
  },
  {
    id: SPELL_WATER_BREATHING,
    name: 'Подводное дыхание',
    nameEn: 'Water Breathing',
    description: `Это заклинание дарует на время своего действия до десяти существам, видимым Вами в пределах дистанции, способность дышать под водой. Эти существа сохраняют и обычное дыхание.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    isRitual: true,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'камыш или соломинка',
    duration: { timeId: TIME_HOUR, count: 24 },
    source: [
      {
        id: SOURCE_PHB,
        page: 258,
      },
      {
        id: SOURCE_SRD,
        page: 191,
      },
    ],
  },
  {
    id: SPELL_SIMULACRUM,
    name: 'Симулякр',
    nameAlt: 'Подобие',
    nameEn: `Simulacrum`,
    description: `Вы создаёте иллюзорную копию зверя или гуманоида, который находится в пределах дистанции всё время, пока активируется заклинание. Копия является существом, частично реальным, созданным изо льда или снега, и она может совершать действия и вести себя как настоящее существо. Оно выглядит так же, как оригинал, но у него только половина от максимума хитов, и оно создаётся без снаряжения. Во всём остальном иллюзия использует все параметры копируемого существа, кроме того, что она — конструкт.

Копия дружелюбна по отношению к Вам и указанным Вами существам. Она подчиняется Вашим устным командам, перемещается и действует согласно Вашей воле, и в сражении действует в Ваш ход. Копия не может обучаться и становиться сильнее, так что она никогда не повышает уровень и характеристики, и не может восстанавливать использованные ячейки заклинаний.

Если копия получает урон, Вы можете исправлять его в алхимической лаборатории, используя редкие травы и минералы стоимостью 100 за каждый восстанавливаемый хит. Копия существует, пока её хиты не опустятся до 0, после чего она превращается в снег и мгновенно тает.

Если Вы используете это заклинание ещё раз, существующие активные копии, созданные Вами этим заклинанием, мгновенно уничтожаются.`,
    lvl: 7,
    magicSchoolId: MAGIC_ILLUSION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `снег или лёд в количестве, достаточном для создания точной копии существа; немного волос, обрезков ногтей или других частей тела этого существа, помещаемые в снег или лёд; и толчёный рубин стоимостью 1 500 зм, которым посыпается копия, и который расходуется заклинанием`,
    duration: Infinity,
    castTime: { timeId: TIME_HOUR, count: 12 },
    source: [
      {
        id: SOURCE_PHB,
        page: 258,
      },
      {
        id: SOURCE_SRD,
        page: 180,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Копия теперь — конструкт, а не гуманоид',
        page: 3,
      },
    ],
  },
  {
    id: SPELL_CLAIRVOYANCE,
    name: 'Подсматривание',
    nameEn: 'Clairvoyance',
    description: `Вы создаёте невидимый сенсор в пределах дистанции в известном Вам месте (месте, которое Вы уже видели или посещали) или очевидном месте, незнакомом Вам (например, за дверью, за углом, или в роще деревьев). Сенсор остаётся на месте, и его нельзя атаковать или как-то с ним взаимодействовать.

Накладывая заклинание, Вы выбираете зрение или слух. Вы можете использовать выбранное чувство через сенсор, как если бы Вы находились на его месте. Вы можете действием переключаться между зрением и слухом.

Существо, способное увидеть сенсор (например, находящееся под заклинанием [Видение невидимого](SPELL:${SPELL_SEE_INVISIBILITY}) или с истинным зрением) видит светящийся нематериальный шарик размером с Ваш кулак.`,
    lvl: 3,
    magicSchoolId: MAGIC_DIVINATION,
    range: '1 миля',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `фокусировка, стоящая как минимум 100 зм, и либо украшенный камнями рог для подслушивания, либо стеклянный глаз для подсматривания`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    castTime: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 258,
      },
      {
        id: SOURCE_SRD,
        page: 124,
      },
    ],
  },
  {
    id: SPELL_DOMINATE_BEAST,
    name: 'Подчинение зверя',
    nameEn: `Dominate Beast`,
    description: `Вы пытаетесь взять под контроль зверя, которого видите в пределах дистанции. Зверь должен пройти испытание Мудрости, иначе он станет очарован Вами на время действия заклинания. Если Вы или дружественные вам существа сражаются с ним, он проходит испытание с преимуществом.

Пока зверь очарован, Вы обладаете телепатической связью с ним, пока Вы оба находитесь на одном плане существования. При помощи этой связи Вы можете отдавать ему команды, пока находитесь в сознании (действие не требуется), и он должен будет их выполнять. Вы можете указать общее действие, такое как _«атакуй вон то существо»_, «беги отсюда» или _«принеси вот это»_. Если существо выполняет приказ и не получает дальнейших указаний от Вас, оно в меру своих сил обороняется и защищает себя.

Вы можете действием захватить полный контроль над целью. До конца Вашего следующего хода существо совершает только выбранные Вами действия, и не делает ничего, что Вы ему не позволяете. В это время Вы можете заставлять существо совершать реакции, но при этом Вы тратите и свою реакцию тоже.

Каждый раз, когда цель получает урон, она повторяет испытание Мудрости от этого заклинания. При успехе заклинание оканчивается.`,
    highLvlCast: `Если Вы накладываете это заклинание с уровнем ячейки 5, концентрацию можно поддерживать до 10 минут. Если Вы накладываете это заклинание с уровнем ячейки 6, концентрацию можно поддерживать до 1 часа. Если Вы накладываете это заклинание с уровнем ячейки 7 или выше, концентрацию можно поддерживать до 8 часов.`,
    lvl: 4,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_CHARMED,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 258,
      },
      {
        id: SOURCE_SRD,
        page: 137,
      },
    ],
  },
  {
    id: SPELL_DOMINATE_PERSON,
    name: 'Подчинение личности',
    nameAlt: 'Подчинение гуманоида',
    nameEn: 'Dominate Person',
    description: `Вы пытаетесь взять под контроль гуманоида, которого видите в пределах дистанции. Он должен пройти испытание Мудрости, иначе станет очарованным Вами на время действия заклинания. Если Вы или дружественные Вам существа сражаются с ним, он проходит испытание с преимуществом.

Пока существо очаровано, Вы обладаете телепатической связью с ним, пока Вы оба находитесь на одном плане существования. При помощи этой связи Вы можете отдавать ему команды, пока находитесь в сознании (действие не требуется), и оно должно будет их выполнять. Вы можете указать общее действие, такое как _«атакуй вон то существо»_, _«беги отсюда»_ или _«принеси вот это»_. Если существо выполняет приказ и не получает дальнейших указаний от Вас, оно в меру своих сил обороняется и защищает себя.

Вы можете действием захватить полный контроль над целью. До конца Вашего следующего хода существо совершает только выбранные Вами действия, и не делает ничего, что Вы ему не позволяете. В это время Вы можете заставлять существо совершать реакции, но при этом Вы тратите и свою реакцию тоже.

Каждый раз, когда цель получает урон, она повторяет испытание Мудрости от этого заклинания. При успехе заклинание оканчивается.`,
    highLvlCast: `Если Вы накладываете это заклинание с уровнем ячейки 6, концентрацию можно поддерживать до 10 минут. Если Вы накладываете это заклинание с уровнем ячейки 7, концентрацию можно поддерживать до 1 часа. Если Вы накладываете это заклинание с уровнем ячейки 8 или выше, концентрацию можно поддерживать до 8 часов.`,
    lvl: 5,
    magicSchoolId: MAGIC_ENCHANTMENT,
    needConcentration: true,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_CHARMED,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 260,
      },
      {
        id: SOURCE_SRD,
        page: 138,
      },
    ],
  },
  {
    id: SPELL_DOMINATE_MONSTER,
    name: 'Подчинение чудовища',
    nameEn: 'Dominate Monster',
    description: `Вы пытаетесь взять под контроль существо, которого видите в пределах дистанции. Существо должно пройти испытание Мудрости, иначе оно станет очарованным Вами на время действия заклинания. Если Вы или дружественные Вам существа сражаются с ним, оно проходит испытание с преимуществом.

Пока существо очаровано, Вы обладаете телепатической связью с ним, пока Вы оба находитесь на одном плане существования. При помощи этой связи Вы можете отдавать ему команды, пока находитесь в сознании (действие не требуется), и оно должно будет их выполнять. Вы можете указать общее действие, такое как «атакуй вон то существо», «беги отсюда» или «принеси вот это». Если существо выполняет приказ и не получает дальнейших указаний от Вас, оно в меру своих сил обороняется и защищает себя.

Вы можете действием захватить полный контроль над целью. До конца Вашего следующего хода существо совершает только выбранные Вами действия, и не делает ничего, что Вы ему не позволяете. В это время Вы можете заставлять существо совершать реакции, но при этом Вы тратите и свою реакцию тоже.

Каждый раз, когда цель получает урон, она повторяет испытание Мудрости от этого заклинания. При успехе заклинание оканчивается.`,
    highLvlCast: `Если Вы накладываете это заклинание с уровнем ячейки 9, концентрацию можно поддерживать до 8 часов.`,
    lvl: 8,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
      condition: CONDITION_CHARMED,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 260,
      },
      {
        id: SOURCE_SRD,
        page: 137,
      },
    ],
  },
  {
    id: SPELL_LOCATE_ANIMALS_OR_PLANTS,
    name: 'Поиск животных или растений',
    nameEn: `Locate Animals or Plants`,
    description: `Назовите определённый вид зверя или растения.

Сконцентрировавшись на голосе природы в окружении, Вы узнаёте направление и расстояние до ближайшего существа или растения этого вида в пределах 5 миль, если они вообще есть.`,
    lvl: 2,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек меха ищейки`,
    source: [
      {
        id: SOURCE_PHB,
        page: 260,
      },
      {
        id: SOURCE_SRD,
        page: 159,
      },
    ],
  },
  {
    id: SPELL_LOCATE_OBJECT,
    name: 'Поиск предмета',
    nameEn: 'Locate Object',
    description: `Опишите или назовите известный Вам предмет. Вы чувствуете направление к этому предмету, пока он находится в пределах 1 000 футов от Вас. Если предмет перемещается, Вы узнаёте его направление.

Это заклинание может искать конкретный известный Вам предмет, если Вы хотя бы раз видели его вблизи (в пределах 30 футов). В качестве альтернативы, это заклинание может искать ближайший предмет определённого вида, например, особый предмет одежды, ювелирное украшение, мебель, инструмент или оружие.

Это заклинание не может обнаружить предмет, если прямой путь между Вами перерезан свинцом, пусть даже самым тонким листом.`,
    lvl: 2,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `раздвоенная веточка`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 260,
      },
      {
        id: SOURCE_SRD,
        page: 159,
      },
    ],
  },
  {
    id: SPELL_FIND_THE_PATH,
    name: 'Поиск пути',
    nameEn: `Find the Path`,
    description: `Это заклинание позволяет найти кратчайший и самый прямой путь к определённому месту, знакомому Вам, находящемуся на том же плане существования. Если искомое место находится на другом плане существования, если это место перемещается (например, это передвижная крепость), или это не конкретное место (например, «логово зелёного дракона»), заклинание проваливается.

Пока заклинание активно, и пока Вы находитесь на одном плане существования с искомым местом, Вы знаете, как далеко, и в каком направлении оно находится. Пока Вы направляетесь туда, каждый раз, когда у Вас появляются разные варианты направления, Вы автоматически определяете, какой путь самый короткий и прямой (но не обязательно самый безопасный) к искомому месту.`,
    lvl: 6,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `набор инструментов для ворожбы — такой как кости, палочки из слоновой кости, карты, зубы или резные руны — стоящий 100 зм, а также предмет из места, которое Вы хотите найти`,
    duration: { timeId: TIME_DAY, count: 1 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 261,
      },
      {
        id: SOURCE_SRD,
        page: 143,
      },
    ],
  },
  {
    id: SPELL_LOCATE_CREATURE,
    name: 'Поиск существа',
    nameEn: `Locate Creature`,
    description: `Опишите или назовите известное Вам существо. Вы чувствуете направление к этому существу, пока оно находится в пределах 1 000 футов от Вас. Если существо перемещается, Вы узнаёте, в какую сторону оно направляется.

Это заклинание может искать конкретное известное Вам существо, или ближайшее существо конкретного вида (например, человек или единорог), если Вы хотя бы раз видели такое существо вблизи (в пределах 30 футов). Если описанное или названное Вами существо находится в другом обличье, например, под эффектом заклинания [превращение](SPELL:${SPELL_POLYMORPH}), это заклинание не найдёт это существо.

Это заклинание не может обнаружить существ, если прямой путь между Вами перерезан текущей водой шириной как минимум 10 футов.`,
    lvl: 4,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек меха ищейки`,
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 261,
      },
      {
        id: SOURCE_SRD,
        page: 159,
      },
    ],
  },
  {
    id: SPELL_FLY,
    name: 'Полёт',
    nameEn: 'Fly',
    description: `Вы касаетесь согласного существа. Цель получает на время длительности заклинания скорость полета 60 фт. Когда заклинание оканчивается, цель падает, если все еще находится в полете и ничем не может остановить падение.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше 3.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    needConcentration: true,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'перо из крыла любой птицы',
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 262,
      },
      {
        id: SOURCE_SRD,
        page: 146,
      },
    ],
  },
  {
    id: SPELL_HEAL,
    name: 'Полное исцеление',
    nameAlt: 'Излечение',
    nameEn: 'Heal',
    description: `Выберите существо, видимое в пределах дистанции.

Поток положительной энергии омывает его, восстанавливая 70 хитов. Это заклинание также отменяет слепоту, глухоту и все болезни, действующие на цель. Это заклинание не оказывает эффекта на конструктов и нежить.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, количество восстанавливаемых хитов увеличивается на 10 за каждый круг ячейки выше шестого.`,
    lvl: 6,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: [
      {
        id: SOURCE_PHB,
        page: 262,
      },
      {
        id: SOURCE_SRD,
        page: 153,
      },
    ],
  },
  {
    id: SPELL_SHAPECHANGE,
    name: 'Полное превращение',
    nameAlt: 'Метаморфоза',
    nameEn: `Shapechange`,
    description: `Вы принимаете на время действия заклинания облик другого существа. Новый облик может принадлежать существу с показателем опасности не больше Вашего уровня. Это не может быть конструкт или нежить, и Вы должны хотя бы раз видеть такое существо. Вы превращаетесь в среднестатистического представителя таких существ, без уровней классов и умения Использование заклинаний.

Ваши игровые параметры заменяются параметрами выбранного существа, хотя у Вас остаётся мировоззрение и значения Интеллекта, Мудрости и Харизмы. Вы также сохраняете все владения навыками и испытаниями, но также получаете те, что есть у нового облика. Если у Вас и существа есть совпадающие владения, и указанный для него бонус выше Вашего, Вы используете бонус существа. Вы не можете использовать легендарные действия и действия в логове нового облика.

Вы принимаете хиты и Кость Хитов нового облика. Когда Вы возвращаете свой облик, Ваши хиты будут такими же, как до превращения. Если Вы возвращаете облик из-за того, что хиты опустились до 0, весь излишний урон переносится на Ваш облик. Если излишний урон не опустил Ваши хиты до 0, Вы не теряете сознание.

Вы сохраняете все преимущества от умений класса, расы и прочих источников, и можете использовать их, при условии, что новый облик физически на это способен. Вы не можете использовать особые чувства (например, тёмное зрение), если их нет и у нового облика. Вы можете говорить только если существо в естественных условиях может говорить.

Когда Вы превращаетесь, Вы выбираете, свалится ли Ваше снаряжение на землю, сольётся с новым обликом или окажется на него надетым. Носимое снаряжение функционирует как обычно. Мастер определяет, сможет ли новый облик использовать тот или иной предмет снаряжения, в зависимости от формы и размера существа. Ваше снаряжение не меняет форму и размер, и снаряжение, которое не может быть надето на новый облик, должно либо свалиться на землю, либо слиться с новым обликом. Слившееся снаряжение не оказывает никакого эффекта.

Пока это заклинание активно, Вы можете действием принимать новые облики, подчиняющиеся тем же самым ограничениям, с одним исключением: если у нового облика больше хитов чем у Вашего текущего, количество хитов остаётся прежним.`,
    lvl: 9,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `нефритовый обруч, стоящий как минимум 1 500 зм, который Вы должны надеть на голову перед накладыванием заклинания`,
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 262,
      },
      {
        id: SOURCE_SRD,
        page: 178,
      },
    ],
  },
  {
    id: SPELL_COMPREHEND_LANGUAGES,
    name: 'Понимание языков',
    nameEn: 'Сomprehend Languages',
    description: `Пока заклинание активно, Вы понимаете буквальный смысл всех услышанных речей на любых языках. Вы также понимаете письмена на любых языках, но Вы должны касаться поверхности, на которой записаны слова. Требуется примерно 1 минута для чтения одной страницы текста.

Это заклинание не расшифровывает тайные послания в текстах и символы, такие как магические знаки, не являющиеся частью текста.`,
    lvl: 1,
    magicSchoolId: MAGIC_DIVINATION,
    isRitual: true,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'щепотка сажи и соли',
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 262,
      },
      {
        id: SOURCE_SRD,
        page: 126,
      },
    ],
  },
  {
    id: SPELL_HARM,
    name: 'Поражение',
    nameAlt: 'Повреждение',
    nameEn: 'Harm',
    description: `Вы насылаете смертельную болезнь на существо, которое видите в пределах дистанции.

Цель должна пройти испытание Телосложения. В случае провала она получает урон некротической энергией 14к6, или половину этого урона в случае успеха. Этот урон не может опустить хиты цели ниже 1. Если цель проваливает испытание, максимум её хитов уменьшается на 1 час на количество, равное полученному урону некротической энергией. Все эффекты, лечащие болезнь, позволяют досрочно вернуть максимум хитов.`,
    lvl: 6,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_NECROTIC,
        halfOnSuccess: true,
        diceCount: 14,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 262,
      },
      {
        id: SOURCE_SRD,
        page: 153,
      },
    ],
  },
  {
    id: SPELL_BANE,
    name: 'Порча',
    nameEn: 'Bane',
    description: `До трёх существ в пределах дистанции, которых Вы видите, должны пройти испытания Харизмы.

Пока заклинание активно, каждый раз, когда цель, провалившая это испытание, совершает бросок атаки или проходит испытание, она должна бросать к4 и вычитать выпавший результат из броска атаки или испытания.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `капля крови`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_CHA,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 263,
      },
      {
        id: SOURCE_SRD,
        page: 120,
      },
    ],
  },
  {
    id: SPELL_GUST_OF_WIND,
    name: 'Порыв ветра',
    nameEn: 'Gust of Wind',
    description: `Порыв сильного ветра длиной 60 фт. и шириной 10 фт. исходит от Вас в выбранном направлении, пока заклинание активно. Все существа, начинающие ход в этой линии, должны пройти испытание Силы, иначе их оттолкнет на 15 фт. от Вас в направлении движения ветра.

Все существа в линии должны тратить 2 фута перемещения за каждый 1 фут, на который они перемещаются в Вашу сторону.

Ветер рассеивает газы и испарения, а также тушит свечи, факелы и другие незащищенные источники огня. Защищенный огонь, например, в фонарях, он заставляет трепетать, и существует 50% шанс, что потухнут и они.

Пока заклинание активно, Вы можете в каждом своем ходу бонусным действием менять направление, в котором дует ветер.`,
    lvl: 2,
    magicSchoolId: MAGIC_EVOCATION,
    needConcentration: true,
    range: -1,
    rangeComment: '60-футовая линия',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'семя гороха',
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_STR,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 263,
      },
      {
        id: SOURCE_SRD,
        page: 152,
      },
    ],
  },
  {
    id: SPELL_SENDING,
    name: 'Послание',
    nameEn: 'Sending',
    description: `Вы посылаете короткое сообщение длиной не больше 25 слов существу, известному Вам.

Это существо слышит послание в сознании, распознаёт Вас в качестве отправителя, если оно Вас знает, и может тут же ответить. Это заклинание позволяет существам с Интеллектом как минимум 1 понимать смысл Вашего послания.

Вы можете передать послание на любое расстояние, и даже на другие планы существования, но если цель находится на ином плане, существует 5-процентный шанс, что послание не будет доставлено.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: Infinity,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусок тонкой медной проволоки`,
    duration: { timeId: TIME_ROUND, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 263,
      },
      {
        id: SOURCE_SRD,
        page: 177,
      },
    ],
  },
  {
    id: SPELL_EXPEDITIOUS_RETREAT,
    name: 'Поспешное отступление',
    nameEn: `Expeditious Retreat`,
    description: `Это заклинание позволяет перемещаться с невероятной скоростью.

Когда Вы накладываете это заклинание (а также в последующие ходы — бонусным действием, пока активно заклинание), Вы можете совершать действие Рывок.`,
    lvl: 1,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 263,
      },
      {
        id: SOURCE_SRD,
        page: 141,
      },
    ],
  },
  {
    id: SPELL_MENDING,
    name: 'Починка',
    nameEn: 'Mending',
    description: `Это заклинание чинит одно повреждение или разрыв на предмете, которого касаетесь, например, разорванное звено цепи, две половинки сломанного ключа, порванный плащ или протекающий бурдюк. Если повреждение или разрыв не больше 1 фута в длину, Вы чините его, не оставляя следов.

Это заклинание может физически починить магический предмет или конструкта, но не может восстановить магию в таких предметах.`,
    lvl: 0,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `два магнетита`,
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 263,
      },
      {
        id: SOURCE_SRD,
        page: 164,
      },
    ],
  },
  {
    id: SPELL_POLYMORPH,
    name: 'Превращение',
    nameEn: 'Polymorph',
    description: `Это заклинание придает новый облик существу, которое Вы видите в пределах дистанции. Несогласное существо должно пройти испытание Мудрости, чтобы избежать эффекта. Перевертыши автоматически преуспевают в этом испытании. Это заклинание не может подействовать на цель, у которой 0 хитов.

Превращение длится, пока активно заклинание, или пока хиты цели не опустятся до 0, или она не умрет. Новый облик может принадлежать зверю, чей ПО не превышает ПО цели (или уровень цели, если у нее нет показателя опасности). Игровые характеристики цели, включая значения ментальных характеристик, заменяются характеристиками выбранного зверя. У цели остается мировоззрение и характер.

Цель принимает хиты нового облика. При возвращении в естественный облик количество ее хитов будет как до превращения. Если она возвращается в свой облик от того, что хиты опустились до 0, весь излишний урон перенесется на естественный облик. Если излишний урон не опускает хиты естественного облика существа до 0, оно не теряет сознание. 

Действия существа в новом облике ограничены теми, что доступны ей, и она не может говорить, сотворять заклинания и совершать другие действия, требующие рук или речи.

Снаряжение цели сливается с ее новым обликом. Существо не может активировать, использовать, надевать или получать какие-то иные преимущества от своего снаряжения.`,
    lvl: 4,
    magicSchoolId: MAGIC_TRANSMUTATION,
    needConcentration: true,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'кокон гусеницы',
    duration: { timeId: TIME_HOUR, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 264,
      },
      {
        id: SOURCE_SRD,
        page: 169,
      },
    ],
  },
  {
    id: SPELL_ANTILIFE_SHELL,
    name: 'Преграда жизни',
    nameEn: `Antilife Shell`,
    description: `От Вас исходит мерцающий барьер с радиусом 10 футов, перемещающийся вместе с Вами, остающийся с центром на Вас, и защищающий Вас от всех существ кроме нежити и конструктов. Барьер существует всё время, пока активно заклинание.

Этот барьер не даёт указанным существам проходить и тянуться через него. Указанные существа могут сотворять заклинания и совершать атаки сквозь преграду дальнобойным оружием и оружием со свойством «досягаемость».

Если Вы перемещаетесь так, что указанное существо будет вынуждено пройти сквозь барьер, заклинание оканчивается.`,
    lvl: 5,
    magicSchoolId: MAGIC_ABJURATION,
    range: -1,
    rangeComment: `10-футовый радиус`,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 264,
      },
      {
        id: SOURCE_SRD,
        page: 116,
      },
    ],
  },
  {
    id: SPELL_ANTIMAGIC_FIELD,
    name: 'Преграда магии',
    nameAlt: 'Антимагическое поле',
    nameEn: `Antimagic Field`,
    description: `Вас окружает невидимая сфера антимагии с радиусом 10 футов. Эта область отделена от магической энергии, пронизывающей мультивселенную. В пределах этой сферы нельзя сотворять заклинания, призванные существа исчезают, и даже магические предметы становятся обычными. Пока заклинание активно, сфера перемещается вместе с Вами, оставаясь с центром на Вас.

Заклинания и прочие магические эффекты, кроме тех, что созданы артефактами и божествами, в сфере подавляются, и не могут проникать в неё. Ячейка, потраченная на накладывание подавленного заклинания, всё равно тратится. Пока эффект подавлен, он не действует, но время в подавленном состоянии всё равно учитывается при определении его длительности.

**Эффекты с целью.** Заклинания и прочие магические эффекты, такие как [волшебная стрела](SPELL:${SPELL_MAGIC_MISSILE}) и [очарование личности](SPELL:${SPELL_CHARM_PERSON}), нацеленные на существо или предмет, находящиеся в сфере, не оказывают на цель никакого эффекта.

**Области магии.** Область магических эффектов и других заклинаний, таких как [огненный шар](SPELL:${SPELL_FIREBALL}), не может проникать в сферу. Если сфера оказывается в области магии, область, находящаяся в сфере, подавляется. Например, пламя, созданное [огненной стеной](SPELL:${SPELL_WALL_OF_FIRE}), подавляется внутри сферы, создавая брешь в стене, если позволяют габариты.

**Заклинания.** Все активные заклинания и прочие магические эффекты на существах и предметах подавляются, пока они находятся в сфере.

**Магические предметы.** Свойства и таланты магических предметов в сфере подавляются. Например, _длинный меч +1_ в сфере будет обычным длинным мечом.

Свойства и таланты магического оружия подавляются, если используются против цели в сфере или используются атакующим, находящимся в сфере. Если магическое оружие или боеприпас полностью покидает сферу (например, если Вы выстрелите магической стрелой или метнёте магическое копьё в цель, находящуюся вне сферы), магия предмета возвращается.

**Магическое перемещение.** В сфере не работают телепортация и перемещение между планами, вне зависимости от того, в сферу или из сферы пытаются перенестись. Порталы в другие места, миры и планы существования, а также входы в межпространство, такие как созданные заклинанием [Трюк с верёвкой](SPELL:${SPELL_ROPE_TRICK}), временно закрываются, пока они находятся в сфере.

**Существа и предметы.** Существа и предметы, призванные или созданные магией, временно прекращают существовать, пока они находятся в сфере. Существа возвращаются обратно, когда сфера покидает место, занимаемое ими до исчезновения.

**Рассеивание магии.** Заклинания и магические эффекты, такие как [рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}), не оказывают на сферу никакого воздействия. Сферы, созданные разными заклинаниями _преграда магии_, не отменяют друг друга.`,
    lvl: 8,
    magicSchoolId: MAGIC_ABJURATION,
    range: -1,
    rangeComment: 'сфера радиусом 10 футов',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `щепотка толчёного железа или железная стружка`,
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 264,
      },
      {
        id: SOURCE_SRD,
        page: 117,
      },
    ],
  },
  {
    id: SPELL_FORESIGHT,
    name: 'Предвидение',
    nameEn: `Foresight`,
    description: `Вы касаетесь согласного существа, даруя ограниченную способность предвидеть будущее. Пока заклинание активно, цель нельзя застать врасплох, и она совершает с преимуществом броски атаки, проверки характеристик и испытания. Кроме того, пока заклинание активно, другие существа совершают по цели броски атаки с помехой.

Это заклинание мгновенно оканчивается, если Вы активируете его ещё раз до окончания длительности.`,
    lvl: 9,
    magicSchoolId: MAGIC_DIVINATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `перо колибри`,
    duration: { timeId: TIME_HOUR, count: 8 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 265,
      },
      {
        id: SOURCE_SRD,
        page: 147,
      },
    ],
  },
  {
    id: SPELL_CONTINGENCY,
    name: 'Предосторожность',
    nameEn: `Contingency`,
    description: `Выберите заклинание 5 уровня или ниже, которое Вы можете сотворить, у которого время накладывания 1 действие, и которое может нацеливаться на Вас. Вы активируете это заклинание частью накладывания _предосторожности_, тратя ячейки заклинаний для обоих, но эффект заготовленного заклинания не проявляется. Этот эффект вступает в силу когда произойдёт определённое условие. Вы описываете это условие когда накладываете оба заклинания. Например, _предосторожность_, сотворённая в паре с [подводным дыханием](SPELL:${SPELL_WATER_BREATHING}), может сделать так, что подводное дыхание начнёт действовать когда Вы окажетесь в воде или подобной жидкости.

Заготовленное заклинание вступает в действие мгновенно после того как условие впервые будет выполнено, хотите Вы того или нет, и после этого предосторожность заканчивается.

Заготовленное заклинание оказывает эффект только на Вас, даже если в обычных обстоятельствах может нацеливаться на других. Вы можете использовать только одно заклинание _предосторожность_ одновременно. Если Вы накладываете это заклинание ещё раз, эффект предыдущей предосторожности оканчивается. Кроме того, _предосторожность_ заканчивается, если материальный компонент перестанет быть в Вашем распоряжении.`,
    lvl: 6,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `ваша статуэтка, вырезанная из слоновой кости, украшенная драгоценными камнями и стоящая как минимум 1 500 зм`,
    duration: { timeId: TIME_DAY, count: 10 },
    castTime: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 265,
      },
      {
        id: SOURCE_SRD,
        page: 130,
      },
    ],
  },
  {
    id: SPELL_DIVINATION,
    name: 'Предсказание',
    nameEn: 'Divination',
    description: `Ваша магия и жертвоприношение связывают Вас с богом или его слугой. Вы задаёте один вопрос о конкретной цели, событии, или деятельности, которая произойдёт в течение следующих 7 дней. Мастер даёт на это честный ответ. Это может быть короткая фраза, загадочный стих или знамение.

Это заклинание не учитывает всевозможные обстоятельства, способные изменить исход событий, такие как накладывание дополнительных заклинаний и потеря и приобретение спутников.

Если Вы накладываете это заклинание несколько раз до завершения длинного отдыха, существует накопительный шанс 25 процентов за каждое использование, начиная со второго, что Вы получите случайный ответ. Мастер совершает этот бросок скрытно.`,
    lvl: 4,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `благовония и жертвенное подношение, подходящее Вашей религии, стоящие вместе как минимум 25 зм, расходуемые заклинанием`,
    source: [
      {
        id: SOURCE_PHB,
        page: 265,
      },
      {
        id: SOURCE_SRD,
        page: 136,
      },
    ],
  },
  {
    id: SPELL_CONJURE_ANIMALS,
    name: 'Призыв животных',
    nameEn: 'Conjure Animals',
    description: `Вы вызываете фейских духов, принимающих облик зверей и появляющихся в свободном видимом Вами пространстве в пределах дистанции. Выберите один из вариантов того, кто появляется:

* Один зверь с показателем опасности 2 или ниже
* Два зверя с показателем опасности 1 или ниже
* Четыре зверя с показателем опасности ½ или ниже
* Восемь зверей с показателем опасности ¼ или ниже

Все звери считаются также феями, и они исчезают, когда их хиты опускаются до 0 или когда заклинание оканчивается.

Призванные существа дружественны к Вам и Вашим спутникам. Совершите проверку инициативы за призванных существ как за одну группу, и они будут совершать свои собственные ходы. Они подчиняются отданным Вами устно командам (действие не требуется). Если Вы не отдаёте команду, они защищаются от враждебных существ, но действий не совершают.

Параметры существ есть у Мастера.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейки большего уровня, Вы выбираете один из описанных выше вариантов, но появляется в два раза больше существ при ячейке 5 уровня, в три раза больше при ячейке 7 уровня, и в четыре раза больше при ячейке 9 уровня.`,
    lvl: 3,
    magicSchoolId: MAGIC_CONJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 265,
      },
      {
        id: SOURCE_SRD,
        page: 127,
      },
    ],
  },
  {
    id: SPELL_CONJURE_BARRAGE,
    name: 'Призыв обстрела',
    nameAlt: 'Призыв заграждения',
    nameEn: `Conjure Barrage`,
    description: `Вы метаете немагическое оружие или выстреливаете немагический боеприпас в воздух, создавая конус одинакового оружия, летящего вперёд и потом исчезающего. Все существа в 60-футовом конусе должны пройти испытание Ловкости. При провале существо получает урон 3к8, а при успехе половину этого урона. Вид урона такой же, как у оружия или боеприпаса, использованного в качестве компонента.`,
    lvl: 3,
    magicSchoolId: MAGIC_CONJURATION,
    range: -1,
    rangeComment: `60-футовый конус`,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `метательное оружие или один боеприпас`,
    effect: {
      attackType: ATTACK_RANGE_WEAPON,
      savethrowParam: PARAM_DEX,
      damage: {
        type: [
          DAMAGE_BLUDGEONING,
          DAMAGE_PIERCING,
          DAMAGE_SLASHING,
        ],
        halfOnSuccess: true,
        diceCount: 3,
        diceType: 8,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 266,
    },
  },
  {
    id: SPELL_CONJURE_VOLLEY,
    name: 'Призыв залпа',
    nameEn: `Conjure Volley`,
    description: `Вы метаете немагическое оружие или выстреливаете немагический боеприпас в воздух и выбираете точку в пределах дистанции.

Сотни экземпляров такого же оружия или боеприпаса падают залпом и потом исчезают. Все существа в цилиндре с радиусом 40 футов и высотой 20 футов должны пройти испытание Ловкости. При провале существо получает урон 8к8, а при успехе половину этого урона. Вид урона такой же, как у оружия или боеприпаса, использованного в качестве компонента.`,
    lvl: 5,
    magicSchoolId: MAGIC_CONJURATION,
    range: 150,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `метательное оружие или один боеприпас`,
    effect: {
      attackType: ATTACK_RANGE_WEAPON,
      savethrowParam: PARAM_DEX,
      damage: {
        type: [
          DAMAGE_BLUDGEONING,
          DAMAGE_PIERCING,
          DAMAGE_SLASHING,
        ],
        halfOnSuccess: true,
        diceCount: 8,
        diceType: 8,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 266,
    },
  },
  {
    id: SPELL_CONJURE_WOODLAND_BEINGS,
    name: 'Призыв лесных обитателей',
    nameEn: `Conjure Woodland Beings`,
    description: `Вы призываете фейских существ, которые появляются в свободном пространстве, которое Вы видите в пределах дистанции. Выберите один из вариантов того, кто появляется:
    
* Одно фейское существо с показателем опасности 2 или ниже
* Два фейских существа с показателем опасности 1 или ниже
* Четыре фейских существа с показателем опасности ½ или ниже
* Восемь фейских существ с показателем опасности ¼ или ниже.

Призванное существо исчезает, когда его хиты опускаются до 0, или когда заклинание оканчивается.

Призванные существа дружественны к Вам и Вашим спутникам. Совершите проверку инициативы за призванных существ как за одну группу, и они будут совершать свои собственные ходы. Они подчиняются отданным Вами устно командам (действие не требуется). Если Вы не отдаёте команду, они защищаются от враждебных существ, но действий не совершают.

Параметры существ есть у Мастера.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейки большего уровня, Вы выбираете один из описанных выше вариантов, но появляется в два раза больше существ при ячейке 6 уровня, и в три раза больше при ячейке 8 уровня.

Параметры существ есть у Мастера.`,
    lvl: 4,
    magicSchoolId: MAGIC_CONJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `по одной ягоде падуба за каждое призываемое существо`,
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 266,
      },
      {
        id: SOURCE_SRD,
        page: 129,
      },
    ],
  },
  {
    id: SPELL_CONJURE_MINOR_ELEMENTALS,
    name: 'Призыв малых элементалей',
    nameEn: `Conjure Minor Elementals`,
    description: `Вы призываете элементалей, которые появляются в свободном пространстве, которое Вы видите в пределах дистанции. Выберите один из вариантов того, кто появляется:

* Один элементаль с показателем опасности 2 или ниже
* Два элементаля с показателем опасности 1 или ниже
* Четыре элементаля с показателем опасности ½ или ниже
* Восемь элементалей с показателем опасности ¼ или ниже

Элементаль исчезает, когда его хиты опускаются до 0, или когда заклинание оканчивается.

Призванные существа дружественны к Вам и Вашим спутникам. Совершите проверку инициативы за призванных существ как за одну группу, и они будут совершать свои собственные ходы. Они подчиняются отданным Вами устно командам (действие не требуется). Если Вы не отдаёте команду, они защищаются от враждебных существ, но действий не совершают.

Параметры существ есть у Мастера.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейки большего уровня, Вы выбираете один из описанных выше вариантов, но появляется в два раза больше существ при ячейке 6 уровня, и в три раза больше при ячейке 8 уровня.`,
    lvl: 4,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 266,
      },
      {
        id: SOURCE_SRD,
        page: 128,
      },
    ],
  },
  {
    id: SPELL_CALL_LIGHTNING,
    name: 'Призыв молнии',
    nameEn: `Call Lightning`,
    description: `Появляется туча в форме цилиндра 10 футов в высоту и с радиусом 60 футов, с центром в видимой Вами над головой точке в пределах дистанции. Заклинание проваливается, если Вы не видите точку в воздухе, где должна появиться туча (например, если Вы находитесь в комнате, в которой туча не поместится).

Активировав заклинание, выберите видимую под тучей точку; из тучи в эту точку бьёт разряд молнии. Все существа в пределах 5 футов от этой точки должны пройти испытания Ловкости. Существо получает урон электричеством 3к10 при провале или половину этого урона в случае успешного испытания. Пока заклинание активно, Вы можете в каждый свой ход действием вызывать новую молнию, в ту же или другую точку.

Если в момент накладывания заклинания Вы находитесь на открытом воздухе во время грозы, заклинание не создаёт новую тучу, а даёт контроль над существующей. В такой ситуации урон от заклинания увеличивается на 1к10.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, урон увеличивается на 1к10 за каждый круг ячейки выше третьего.`,
    lvl: 3,
    magicSchoolId: MAGIC_CONJURATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_ELECTRICITY,
        halfOnSuccess: true,
        diceCount: 3,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 266,
      },
      {
        id: SOURCE_SRD,
        page: 123,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь тучу можно вызывать на выбранной высоте в пределах дистанции, а не строго в 100 футах, а молнией бить в любую точку под тучей',
        page: 123,
      },
    ],
  },
  {
    id: SPELL_CONJURE_CELESTIAL,
    name: 'Призыв небожителя',
    nameEn: `Conjure Celestial`,
    description: `Вы призываете небожителя с показателем опасности 4 или меньше, появляющегося в свободном месте, которое Вы видите в пределах дистанции. Небожитель исчезает, когда его хиты опускаются до 0, или когда заклинание оканчивается.

Небожитель дружественен к Вам и Вашим спутникам, пока заклинание активно. Совершите проверку инициативы за небожителя, и он будет совершать свои собственные ходы. Он подчиняется отданным Вами устно командам (действие не требуется), если они не противоречат его мировоззрению. Если Вы не отдаёте команду, он защищается от враждебных существ, но действий не совершает.

Параметры небожителя есть у Мастера.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 9 уровня, Вы призываете небожителя с показателем опасности 5 или ниже.`,
    lvl: 7,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 267,
      },
      {
        id: SOURCE_SRD,
        page: 127,
      },
    ],
  },
  {
    id: SPELL_CONJURE_FEY,
    name: 'Призыв феи',
    nameEn: `Conjure Fey`,
    description: `Вы призываете фейское существо с показателем опасности 6 или меньше, или фейского духа, который принимает облик зверя с показателем опасности 6 или меньше. Существо появляется в свободном месте, которое Вы видите в пределах дистанции. Оно исчезает, когда его хиты опускаются до 0, или когда заклинание оканчивается.

Фейское существо дружественно к Вам и Вашим спутникам, пока заклинание активно. Совершите проверку инициативы за существо, и оно будет совершать свои собственные ходы. Оно подчиняется отданным Вами устно командам (действие не требуется), если они не противоречат его мировоззрению. Если Вы не отдаёте команду, оно защищается от враждебных существ, но действий не совершает.

Если Ваша концентрация нарушена, существо не исчезает. Вместо этого Вы теряете над ним контроль, и оно становится враждебным к Вам и Вашим спутникам, и может напасть. Неуправляемое существо не может быть отпущено Вами, но он исчезает через 1 час после того, как оно было призвано.

Параметры фейского существа есть у Мастера.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, показатель опасности увеличивается на 1 за каждый круг ячейки выше шестого.`,
    lvl: 6,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 267,
      },
      {
        id: SOURCE_SRD,
        page: 128,
      },
    ],
  },
  {
    id: SPELL_COMMAND,
    name: 'Приказ',
    nameEn: 'Command',
    description: `Вы произносите команду из одного слова существу, которое видите в пределах дистанции. Цель должна пройти испытание Мудрости, иначе в свой следующий ход будет исполнять эту команду. Заклинание не оказывает эффект, если цель — нежить, если цель не понимает Ваш язык, или если Ваша команда ему напрямую вредит.

Ниже описаны самые типичные команды и их эффекты. Вы можете отдавать и другие команды. В этом случае поведение цели определяет Мастер. Если цель не может выполнить Вашу команду, заклинание оканчивается.

**Брось.** Цель роняет то, что держит, и оканчивает ход.

**Падай.** Цель падает ничком и оканчивает ход.

**Подойди.** Цель перемещается ближе к Вам по кратчайшему и самому прямому маршруту, оканчивая ход, если оказывается в пределах 5 футов от Вас.

**Стой.** Цель не перемещается и не совершает никаких действий. Летающее существо продолжает лететь, при условии, что может это делать. Если оно должно лететь, чтобы не упасть, то пролетает минимально необходимое расстояние.

**Убегай.** Цель тратит ход на то, что перемещается прочь от Вас самым быстрым способом.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы можете воздействовать на одно дополнительное существо за каждый круг ячейки выше первого. Существа должны находиться в пределах 30 футов друг от друга, чтобы стать целями.`,
    lvl: 1,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_ROUND, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 267,
      },
      {
        id: SOURCE_SRD,
        page: 125,
      },
    ],
  },
  {
    id: SPELL_VAMPIRIC_TOUCH,
    name: 'Прикосновение вампира',
    nameEn: `Vampiric Touch`,
    description: `Касание Вашей окутанной тенями рукой может вытягивать жизненные силы у других, залечивая Ваши раны.

Совершите рукопашную атаку заклинанием по существу в пределах Вашей досягаемости. При попадании цель получает урон некротической энергией 3к6, а Вы восстанавливаете количество хитов, равное половине причинённого урона некротической энергией. Пока заклинание активно, Вы в каждом ходу можете действием совершать эту атаку.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше третьего.`,
    lvl: 3,
    magicSchoolId: MAGIC_NECROMANCY,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_SPELL,
      damage: {
        type: DAMAGE_NECROTIC,
        diceCount: 3,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 268,
      },
      {
        id: SOURCE_SRD,
        page: 189,
      },
    ],
  },
  {
    id: SPELL_COMPULSION,
    name: 'Принуждение',
    nameEn: `Compulsion`,
    description: `Выбранные Вами существа, которых Вы видите в пределах дистанции, и которые слышат Вас, должны пройти испытание Мудрости. Цель автоматически преуспевает, если не может быть очарована. При провале испытания цель попадает под действие этого заклинания. Пока заклинание активно, Вы можете в каждом своём ходу бонусным действием указать направление, горизонтальное для Вас. Все попавшие под действие цели должны в свой следующий ход максимально возможно перемещаться в том направлении. Перед перемещением они могут совершить свои действия. После перемещения из-за заклинания они могут пройти ещё одно испытание Мудрости, чтобы окончить этот эффект.

Цель не обязана перемещаться в очевидно опасное место, такое как огонь или костёр, но она провоцирует атаки, когда идёт в указанном направлении.`,
    lvl: 4,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 268,
      },
      {
        id: SOURCE_SRD,
        page: 126,
      },
    ],
  },
  {
    id: SPELL_PHANTOM_STEED,
    name: 'Призрачный скакун',
    nameEn: `Phantom Steed`,
    description: `На полу в свободном пространстве на Ваш выбор в пределах дистанции появляется Большое полуреальное похожее на лошадь существо. Вы сами определяете его внешность, но у него есть седло, удила и уздечка. Всё снаряжение, созданное этим заклинанием, исчезает в клубах дыма, если его отнести на 10 футов от скакуна.

Пока заклинание активно, Вы или выбранное Вами существо можете ехать на скакуне верхом. Используются характеристики ездовой лошади, за исключением того, что скорость существа 100 футов, и оно может проскакать 10 миль за час или 13 миль в быстром темпе. Когда заклинание заканчивается, скакун медленно исчезает, давая ездоку 1 минуту на спешивание. Заклинание оканчивается, если Вы окончите его действием или если скакун получит какой-либо урон.`,
    lvl: 3,
    magicSchoolId: MAGIC_ILLUSION,
    range: 30,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 268,
      },
      {
        id: SOURCE_SRD,
        page: 167,
      },
    ],
  },
  {
    id: SPELL_FEIGN_DEATH,
    name: 'Притворная смерть',
    nameEn: `Feign Death`,
    description: `Вы касаетесь согласного существа и погружаете его в каталептическое состояние, не отличимое от смерти.

Пока заклинание активно, или пока Вы не коснётесь действием цели, снимая заклинание, цель выглядит мёртвой для любого обследования и заклинаний, определяющих состояние цели. Цель ослеплена и недееспособна, а скорость равна 0. Цель получает сопротивление всем видам урона кроме психической энергии. Если цель была больна или отравлена, когда Вы накладываете это заклинание, или становится больной или отравленной, находясь под его действием, болезни и яды не оказывают никакого эффекта, пока заклинание не окончится.`,
    lvl: 3,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 0,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `щепотка кладбищенской земли`,
    duration: { timeId: TIME_HOUR, count: 1 },
    source: {
      id: SOURCE_PHB,
      page: 268,
    },
  },
  {
    id: SPELL_SEEMING,
    name: 'Притворство',
    nameEn: `Seeming`,
    description: `Это заклинание позволяет Вам менять облик любого количества существ, видимых Вами в пределах дистанции. Вы даёте каждой выбранной цели новый, иллюзорный облик. Несогласная цель может пройти испытание Харизмы, и в случае успеха заклинание на неё не действует.

Это заклинание маскирует внешний облик, а также одежду, доспехи, оружие и снаряжение. Вы можете сделать каждое существо на 1 фут (30 сантиметров) ниже или выше, и сделать его худого, толстого или обычного сложения. Вы не можете менять вид тела цели, так что у неё должен быть такой же набор конечностей. Во всём остальном иллюзию определяете Вы. Вы можете закончить действие заклинания досрочно, потратив действие.

Изменения, внесённые этим заклинанием, не проходят проверку физическим исследованием. Например, если заклинание добавит шляпу к снаряжению персонажа, предметы будут проходить сквозь неё, и все, кто коснутся её, либо не почувствуют ничего, либо почувствуют голову и волосы. Если Вы сделаете себя более худым, то рука того, кто захочет к Вам прикоснуться, ударится о Вас, не дойдя до видимых очертаний.

Существо может действием осмотреть цель и совершить проверку Интеллекта (Анализ) против СЛ Ваших заклинаний. При успехе оно поймёт, что облик ненатурален.`,
    lvl: 5,
    magicSchoolId: MAGIC_ILLUSION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 8 },
    effect: {
      savethrowParam: PARAM_CHA,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 268,
      },
      {
        id: SOURCE_SRD,
        page: 177,
      },
    ],
  },
  {
    id: SPELL_PROJECT_IMAGE,
    name: 'Проекция',
    nameEn: `Project Image`,
    description: `Вы создаёте иллюзорную копию себя, существующую, пока активно заклинание. Копия может появиться в любом месте в пределах дистанции, которое Вы до этого видели, вне зависимости от всех возможных препятствий. Иллюзия выглядит и звучит как Вы, но неосязаема. Если иллюзия получает урон, она исчезает, а заклинание оканчивается.

Вы можете действием переместить эту иллюзию на расстояние, вдвое превышающее Вашу скорость, и заставить её жестикулировать, говорить и вообще, вести себя так, как решите Вы. Она идеально повторяет Ваши манеры.

Вы можете видеть её глазами и слышать её ушами, как если бы находились в её пространстве. Вы можете в свой ход бонусным действием переключиться с её чувств на свои или обратно. Пока Вы используете её чувства, Вы слепы и глухи для своего окружения.

Физическое взаимодействие с образом даёт понять, что это иллюзия, потому что сквозь него всё проходит. Существа, исследующие образ действием, могут определить, что это иллюзия, совершив успешную проверку Интеллекта (Анализ) против СЛ Ваших заклинаний. Если существо распознаёт иллюзию, оно может видеть сквозь неё, и издаваемые ей звуки тоже становятся незначащими.`,
    lvl: 7,
    magicSchoolId: MAGIC_ILLUSION,
    range: '500 миль',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    materialText: `ваша небольшая копия, изготовленная из материала, стоящего не менее 5 зм`,
    duration: { timeId: TIME_DAY, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 269,
      },
      {
        id: SOURCE_SRD,
        page: 172,
      },
    ],
  },
  {
    id: SPELL_ASTRAL_PROJECTION,
    name: 'Проекция в астрал',
    nameEn: `Astral Projection`,
    description: `Вы и до восьми согласных существ в пределах дистанции проецируете свои астральные тела в Астральный План (заклинание проваливается, если Вы уже находитесь на этом плане). Оставленные Вами материальные тела лежат без сознания в состоянии приостановленной жизнедеятельности; им не нужна пища, воздух, и они не стареют.

Ваше астральное тело во всём похоже на смертное тело, оно обладает теми же параметрами и снаряжением. Единственным отличием является серебряная нить, исходящая у Вас между лопаток и торчащая наружу, становясь невидимой через 1 фут. Эта нить связывает Вас с материальным телом. Пока эта нить цела, Вы можете найти путь домой. Если нить перерезать (это могут сделать только эффекты, явно указывающие, что они могут это) Ваши душа и тело разделяются, и Вы мгновенно умираете.

Ваше астральное тело может свободно ходить по Астральному Плану и проходить сквозь порталы, ведущие в другие планы. Если Вы входите в новый план или возвращаетесь в свой, Ваши тело и снаряжение переносятся по серебряной нити, позволяя вернуться в своё тело при входе на другой план.

Ваше астральное тело — отдельное воплощение. Урон и прочие эффекты, применимые к нему, не оказывают воздействие на физическое тело, и не остаются при Вашем возвращении в тело.

Заклинание оканчивается для Вас и Ваших спутников, если Вы оканчиваете его действием. При этом существа возвращаются в свои тела и просыпаются.

Заклинание может окончиться для отдельных существ преждевременно. Успешное заклинание [рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}), использованное либо на астральное, либо на физическое тело, оканчивает это заклинание для этого существа. Если хиты физического или астрального тела существа опускаются до 0, заклинание оканчивается для него. Если заклинание оканчивается, и серебряная нить нетронута, она возвращает астральное тело в физическое, оканчивая состояние приостановленной жизнедеятельности.

Если Вы вернулись в своё тело, а спутники остались в астральных телах, но хотят вернуться, они сами должны найти способ вернуться, что обычно происходит в результате опускания хитов до 0.`,
    lvl: 9,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 10,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `для каждого существа требуется по одному гиацинту, стоящему как минимум 1 000 зм, и по одному бруску серебра с орнаментом, стоящему как минимум 100 зм; все компоненты расходуются заклинанием`,
    duration: 'Особая',
    castTime: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 269,
      },
      {
        id: SOURCE_SRD,
        page: 119,
      },
    ],
  },
  {
    id: SPELL_BESTOW_CURSE,
    name: 'Проклятие',
    nameEn: 'Bestow Curse',
    description: `Вы касаетесь существа, и оно должно пройти испытание Мудрости, иначе станет проклятым на период действия заклинания. Накладывая это заклинание, выберите характер проклятья из следующего списка:

* Выберите одну характеристику. Будучи проклятой, цель совершает проверки и испытания этой характеристики с помехой.
* Будучи проклятой, цель совершает с помехой броски атаки по Вам.
* Будучи проклятой, цель должна в начале каждого своего хода проходить испытание Мудрости. В случае провала она впустую тратит свое действие.
* Пока цель проклята, Ваши атаки и заклинания причиняют ей дополнительный урон некротической энергией 1к8.

Заклинание снятие проклятья оканчивает этот эффект. С разрешения Мастера, Вы можете создать альтернативный эффект проклятья, но он не должен быть сильнее тех, что представлены выше. Финальное решение об эффекте проклятья остается за Мастером.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, длительность будет «Концентрация, вплоть до 10 минут». Если Вы использовали ячейку 5 круга или выше, длительность будет «8 часов». Если Вы использовали ячейку 7 круга или выше, длительность будет «24 часа». Если Вы использовали ячейку 9 уровня, заклинание действует, пока не рассеется. При использовании ячейки как минимум 5 уровня концентрация не требуется.`,
    lvl: 3,
    magicSchoolId: MAGIC_NECROMANCY,
    needConcentration: true,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
      damage: {
        type: DAMAGE_NECROTIC,
        diceCount: 1,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 269,
      },
      {
        id: SOURCE_SRD,
        page: 121,
      },
    ],
  },
  {
    id: SPELL_JUMP,
    name: 'Прыжок',
    nameAlt: 'Усиленный прыжок',
    nameEn: 'Jump',
    description: `Вы касаетесь существа. Пока заклинание активно, расстояние прыжка этого существа увеличивается в три раза.`,
    lvl: 1,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'задняя лапка кузнечика',
    duration: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 270,
      },
      {
        id: SOURCE_SRD,
        page: 158,
      },
    ],
  },
  {
    id: SPELL_FALSE_LIFE,
    name: 'Псевдожизнь',
    nameEn: `False Life`,
    description: `Укрепив себя некромантским подобием жизни, Вы получаете 1к4 + 4 временных хита на время длительности заклинания.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы получаете 5 дополнительных временных хитов за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_NECROMANCY,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `небольшое количество алкоголя или дистиллированного спирта`,
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 270,
      },
      {
        id: SOURCE_SRD,
        page: 142,
      },
    ],
  },
  {
    id: SPELL_TRANSPORT_VIA_PLANTS,
    name: 'Путешествие через растения',
    nameEn: `Transport via Plants`,
    description: `Это заклинание создаёт магическую связь между неоживлённым растением с размером не меньше Большого, находящимся в пределах дистанции, и другим растением, находящимся на любом расстоянии на том же плане существования. Вы должны до этого хотя бы раз видеть или касаться растения, служащего местом назначения. Пока заклинание активно, все существа могут входить в целевое растение и выходить из растения, служащего местом назначения, использовав всего 5 футов перемещения.`,
    lvl: 6,
    magicSchoolId: MAGIC_CONJURATION,
    range: 10,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_ROUND, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 270,
      },
      {
        id: SOURCE_SRD,
        page: 188,
      },
    ],
  },
  {
    id: SPELL_FLAMING_SPHERE,
    name: 'Пылающий шар',
    nameEn: 'Flaming Sphere',
    description: `В свободном пространстве на Ваш выбор в пределах дистанции появляется шар из огня с диаметром 5 футов, существующий, пока активно заклинание. Все существа, оканчивающие ход в пределах 5 футов от шара, должны проходить испытание Ловкости. Существа получают урон огнём 2к6 при провале или половину этого урона при успехе.

Вы можете бонусным действием переместить шар на 30 футов. Если Вы тараните шаром существо, это существо должно пройти испытание от урона шара, и шар останавливается до конца этого хода.

Когда Вы перемещаете шар, Вы можете поднимать его над препятствиями до 5 футов в высоту и перепрыгивать им разломы до 10 футов в длину. Шар поджигает горючие предметы, которые никто не несёт и не носит, и испускает яркий свет в пределах 20 футов и тусклый свет в пределах ещё 20 футов.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше второго.`,
    lvl: 2,
    magicSchoolId: MAGIC_CONJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусок сала, щепотка серы и толчёного железа`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_FIRE,
        halfOnSuccess: true,
        diceCount: 2,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 270,
      },
      {
        id: SOURCE_SRD,
        page: 145,
      },
    ],
  },
  {
    id: SPELL_PRISMATIC_WALL,
    name: 'Радужная стена',
    nameEn: `Prismatic Wall`,
    description: `Мерцающая разноцветная плоскость формирует вертикальную стену с длиной 90 футов, высотой 30 футов и толщиной 1 дюйм, с центром на точке, видимой в пределах дистанции. В качестве альтернативы, Вы можете свернуть стену в сферу 30-футового диаметра с центром на точке, видимой в пределах дистанции. Стена существует, пока активно заклинание. Если Вы размещаете стену так, что она пройдёт по пространству, занятому существом, заклинание проваливается, а Ваше действие и ячейка заклинания тратятся.

Стена испускает яркий свет в пределах 100 футов и тусклый свет в пределах ещё 100 футов. Вы и существа, указанные Вами при накладывании заклинания, можете без вреда проходить сквозь стену и оставаться возле неё. Если другое существо, способное видеть, перемещением оказывается в пределах 20 футов от стены или начинает там ход, оно должно пройти испытание Телосложения, иначе оно станет ослеплённым на 1 минуту.

Стена состоит из семи слоёв разного цвета. Когда существо пытается коснуться стены или пройти сквозь неё, оно проходит по одному слою за раз, пока не пройдёт сквозь все. Коснувшись очередного слоя, существо должно пройти испытание Ловкости, чтобы защититься от его эффектов, описанных ниже.

Стену можно уничтожить, по одному слою за раз, в порядке от красного к фиолетовому, средствами, специфичными для каждого слоя. Уничтоженный слой остаётся уничтоженным на период всего действия заклинания. _Жезл отмены_ уничтожает радужную стену, но [преграда магии](SPELL:${SPELL_ANTIMAGIC_FIELD}) не оказывает на неё эффекта.

1. **Красный.** Существо получает урон огнём 10к6 при провале или половину этого урона при успехе. Пока этот слой существует, немагические дальнобойные атаки не могут проходить сквозь стену. Этот слой можно уничтожить, причинив ему урон холодом 25.
2. **Оранжевый.** Существо получает урон кислотой 10к6 при провале или половину этого урона при успехе. Пока этот слой существует, магические дальнобойные атаки не могут проходить сквозь стену. Этот слой можно уничтожить сильным ветром.
3. **Жёлтый.** Существо получает урон электричеством 10к6 при провале или половину этого урона при успехе. Этот слой можно уничтожить, причинив ему урон силовым полем 60.
4. **Зелёный.** Существо получает урон ядом 10к6 при провале или половину этого урона при успехе. Заклинание [Создание прохода](SPELL:${SPELL_PASSWALL}), а также другие заклинания, с не меньшим кругом, открывающие проходы в твёрдой поверхности, уничтожают этот слой.
5. **Голубой.** Существо получает урон холодом 10к6 при провале или половину этого урона при успехе. Этот слой можно уничтожить, причинив ему урон огнём 25.
6. **Синий.** При провале существо Обездвиживается. После этого оно в конце каждого своего хода должно проходить испытание Телосложения. Если оно успешно спасётся три раза, заклинание оканчивается. Если оно провалит испытание три раза, оно превращается в камень и получает состояние «окаменевшее» на время длительности заклинания. Успехи и провалы не обязаны быть последовательными; отслеживайте их отдельно, пока не накопится три вида чего-то одного.
    Пока этот слой существует, сквозь стену нельзя сотворять заклинания. Этот слой уничтожается ярким светом от заклинания [дневной свет](SPELL:${SPELL_DAYLIGHT}) или подобного заклинания с не меньшим кругом.
7. **Фиолетовый.** При провале существо становится ослеплённым. После этого оно в начале Вашего следующего хода должно пройти испытание Мудрости. Успешное спасение оканчивает слепоту. При провале существо переносится на другой план, выбранный Мастером, и перестаёт быть ослеплённым (обычно существа на неродных планах изгоняются домой, а остальные существа обычно выбрасываются на Астральный или Эфирный планы). Этот слой уничтожается заклинанием [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) или подобными заклинаниями с неменьшим уровнем, оканчивающими заклинания и магические эффекты.`,
    lvl: 9,
    magicSchoolId: MAGIC_ABJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    effect: {
      savethrowParam: [
        PARAM_DEX,
        PARAM_CON,
        PARAM_WIT,
      ],
      condition: [
        CONDITION_RESTRAINED,
        CONDITION_PETRIFIED,
        CONDITION_BLINDED,
      ],
      damage: [
        {
          type: [
            DAMAGE_FIRE,
            DAMAGE_ACID,
            DAMAGE_ELECTRICITY,
            DAMAGE_POISON,
            DAMAGE_COLD,
          ],
          halfOnSuccess: true,
          diceCount: 10,
          diceType: 6,
        },
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 270,
      },
      {
        id: SOURCE_SRD,
        page: 171,
      },
    ],
  },
  {
    id: SPELL_PRISMATIC_SPRAY,
    name: 'Радужные брызги',
    nameEn: `Prismatic Spray`,
    description: `Восемь разноцветных лучей света вылетают из Вашей ладони. Каждый луч своего цвета, и у каждого свои особые свойства. Все существа в пределах 60-футового конуса должны пройти испытание Ловкости. Для каждой цели совершите бросок к8, чтобы определить, луч какого цвета её коснулся.

1. **Красный.** Цель получает урон огнём 10к6 при провале или половину этого урона при успехе.
2. **Оранжевый.** Цель получает урон кислотой 10к6 при провале или половину этого урона при успехе.
3. **Жёлтый.** Цель получает урон электричеством 10к6 при провале или половину этого урона при успехе.
4. **Зелёный.** Цель получает урон ядом 10к6 при провале или половину этого урона при успехе.
5. **Голубой.** Цель получает урон холодом 10к6 при провале или половину этого урона при успехе.
6. **Синий.** При провале цель обездвиживается. После этого цель в конце каждого своего хода проходит испытание Телосложения. Если она преуспеет в трёх испытаниях, заклинание оканчивается. Если накопится три провала, цель навсегда превращается в камень и становится окаменевшей. Успехи и провалы не обязаны быть последовательными; отслеживайте их отдельно друг от друга, пока не накопится три результата одного вида.
7. **Фиолетовый.** При провале цель становится ослепшей. В начале Вашего следующего хода она проходит испытание Мудрости. Успех оканчивает ослепление. При провале существо перемещается на другой план существования на выбор Мастера и перестаёт быть ослепшей (обычно существа на неродных планах изгоняются домой, а остальные существа обычно выбрасываются на Астральный или Эфирный планы).
8. **Особенность.** По цели попадают два луча. Совершите ещё два броска кости, перебрасывая выпавшие «8».`,
    lvl: 7,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: `60-футовый конус`,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    effect: {
      savethrowParam: [
        PARAM_DEX,
        PARAM_CON,
        PARAM_WIT,
      ],
      condition: [
        CONDITION_RESTRAINED,
        CONDITION_PETRIFIED,
        CONDITION_BLINDED,
      ],
      damage: [
        {
          type: [
            DAMAGE_FIRE,
            DAMAGE_ACID,
            DAMAGE_ELECTRICITY,
            DAMAGE_POISON,
            DAMAGE_COLD,
          ],
          halfOnSuccess: true,
          diceCount: 10,
          diceType: 6,
        },
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 272,
      },
      {
        id: SOURCE_SRD,
        page: 170,
      },
    ],
  },
  {
    id: SPELL_SPEAK_WITH_ANIMALS,
    name: 'Разговор с животными',
    nameEn: `Speak with Animals`,
    description: `Вы получаете на время действия заклинания возможность понимать зверей и общаться с ними устно.

Знание и сознание многих зверей ограничено их интеллектом, но они как минимум могут дать информацию о ближайших местах и чудовищах, включая тех, кого они видели за последний день.

На усмотрение Мастера, Вы можете попытаться убедить зверя оказать Вам небольшую помощь.`,
    lvl: 1,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 272,
      },
      {
        id: SOURCE_SRD,
        page: 181,
      },
    ],
  },
  {
    id: SPELL_SPEAK_WITH_DEAD,
    name: 'Разговор с мёртвыми',
    nameEn: `Speak with Dead`,
    description: `Вы даёте подобие жизни и сознания трупу, выбранному в пределах дистанции, позволяя ему отвечать на задаваемые Вами вопросы. У трупа должен быть рот, и он не может быть нежитью. Заклинание проваливается, если этот труп был целью этого заклинания в течение последних 10 дней.

Пока заклинание активно, Вы можете задать трупу пять вопросов. Труп знает только то, что знал при жизни, включая известные языки. Ответы обычно короткие, загадочные или невыразительные, и труп не обязан давать правдивый ответ, если Вы враждебны к нему или он распознает в Вас врага. Это заклинание не возвращает душу в тело существа, а только оживляет дух. Таким образом, труп не может узнавать новую информацию, не осознаёт того, что происходило после его смерти, и не может размышлять о будущих событиях.`,
    lvl: 3,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 10,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `горящие благовония`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 272,
      },
      {
        id: SOURCE_SRD,
        page: 181,
      },
    ],
  },
  {
    id: SPELL_SPEAK_WITH_PLANTS,
    name: 'Разговор с растениями',
    nameEn: `Speak with Plants`,
    description: `Вы наделяете растения в пределах 30 футов от себя ограниченным сознанием и подобием жизни, позволяя им общаться с Вами и выполнять Ваши простые инструкции. Вы можете спрашивать их о событиях, происходивших в области заклинания за последний день, получая информацию о проходивших существах, погоде и прочих обстоятельствах.

Вы можете также превратить на время действия заклинания труднопроходимую местность, вызванную растениями (кусты и заросли), в обычную местность. Вы можете превратить на время действия заклинания обычную местность в труднопроходимую, если там есть растения, например, заставляя ветки и лианы хватать преследователей.

На усмотрение Мастера, растения могут выполнять и другие задачи. Это заклинание не позволяет растениям выкапываться и перемещаться, но они могут свободно двигать ветками, ростками и стеблями.

Если в области заклинания есть растительное существо, Вы можете общаться с ним, как если бы у Вас был общий язык, но Вы не получаете магическую способность влиять на него.

Это заклинание может заставить растения, созданные заклинанием [Опутывание](SPELL:${SPELL_ENTANGLE}), отпустить существо.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: -1,
    rangeComment: `30-футовый радиус`,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 273,
      },
      {
        id: SOURCE_SRD,
        page: 181,
      },
    ],
  },
  {
    id: SPELL_BLUR,
    name: 'Размытый образ',
    nameEn: 'Blur',
    description: `Ваше тело становится размытым и колышущимся для всех, кто видит Вас.

Пока заклинание активно, все существа совершают по Вам броски атаки с помехой. Атакующий получает иммунитет к этому эффекту, если полагается не на зрение, а, например, на слепое зрение, или может видеть сквозь иллюзии, например, с помощью истинного зрения.`,
    lvl: 2,
    magicSchoolId: MAGIC_ILLUSION,
    range: -1,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 273,
      },
      {
        id: SOURCE_SRD,
        page: 123,
      },
    ],
  },
  {
    id: SPELL_DESTRUCTIVE_WAVE,
    name: 'Разрушительная волна',
    nameEn: 'Destructive Wave',
    description: `Вы топаете по полу, создавая исходящей от Вас волну божественной энергии.

Все существа, выбранные Вами в пределах 30 футов, должны пройти испытание Телосложения, иначе они получат урон звуком 5к6, а также урон 5к6 излучением или некротической энергией (на Ваш выбор), и будут сбиты с ног.

Существо, преуспевшее в испытании, получает половину урона и не сбивается с ног.`,
    lvl: 5,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: '30-футовый радиус',
    componentIdList: [CAST_VERBAL],
    effect: {
      savethrowParam: PARAM_CON,
      condition: CONDITION_PRONE,
      damage: {
        type: [
          DAMAGE_THUNDER,
          DAMAGE_RADIANT,
          DAMAGE_NECROTIC,
        ],
        halfOnSuccess: false,
        diceCount: 5,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 273,
    },
  },
  {
    id: SPELL_EYEBITE,
    name: 'Разящее око',
    nameEn: 'Eyebite',
    description: `Пока заклинание активно, Ваши глаза становятся абсолютно чёрными, наполнившись жуткой энергией. Одно существо на Ваш выбор в пределах 60 футов от Вас, которое Вы видите, должно пройти испытание Мудрости, иначе на время длительности заклинания оно попадает под действие одного из выбранных Вами эффектов. В каждый свой ход, пока заклинание активно, Вы можете действием нацелиться на другое существо, если только оно не прошло испытание от этого разящего ока.

**Паника.** Цель испугана Вами. В каждый свой ход испуганное существо должно совершать действие Рывок и перемещаться прочь от Вас по самому безопасному и короткому из возможных маршруту, если ей есть куда перемещаться. Если цель окажется на расстоянии как минимум 60 футов от Вас, в месте, в котором ей Вас не видно, этот эффект заканчивается.

**Слабость.** Цель совершает с помехой броски атаки и проверки характеристик. В конце каждого своего хода она сможет пройти ещё одно испытание Мудрости. При успехе эффект заканчивается.

**Сон.** Цель теряет сознание. Она просыпается, если получает урон или другое существо действием потрясёт его, чтобы разбудить.`,
    lvl: 6,
    magicSchoolId: MAGIC_NECROMANCY,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_WIT,
      condition: [
        CONDITION_FRIGHTENED,
        CONDITION_UNCONSCIOUS,
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 273,
      },
      {
        id: SOURCE_SRD,
        page: 141,
      },
    ],
  },
  {
    id: SPELL_HEAT_METAL,
    name: 'Раскалённый металл',
    nameEn: 'Heat Metal',
    description: `Выберите рукотворный металлический предмет, такой как металлическое оружие или комплект тяжёлого или среднего доспеха, видимый в пределах дистанции. Вы делаете его раскалённым докрасна. Все существа, находящиеся в физическом контакте с этим предметом, получают урон огнём 2к8, когда Вы накладываете это заклинание. Пока заклинание активно, Вы можете бонусным действием в каждом последующем ходу вновь причинять этот урон.

Если существо держит или носит предмет, и получает от него урон, оно должно пройти испытание Телосложения, иначе оно роняет этот предмет, если может. Если оно не может уронить предмет, то до начала Вашего следующего хода совершает с помехой броски атаки и проверки характеристик.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше второго.`,
    lvl: 2,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусок железа и пламя`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_CON,
      damage: {
        type: DAMAGE_FIRE,
        diceCount: 2,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 273,
      },
      {
        id: SOURCE_SRD,
        page: 153,
      },
    ],
  },
  {
    id: SPELL_DISINTEGRATE,
    name: 'Распад',
    nameEn: 'Disintegrate',
    description: `Тонкий зелёный луч вылетает из Вашего указательного пальца в цель, которую Вы видите в пределах дистанции. Цель может быть существом, предметом или творением из магической силы, например, стеной, созданной заклинанием [Силовая стена](SPELL:${SPELL_WALL_OF_FORCE}).

Существо, ставшее целью этого заклинания, должно пройти испытание Ловкости. При провале оно получает урон силовым полем 10к6 + 40. Если этот урон опускает хиты цели до 0, она распадается.

Распавшееся существо, а также всё, что оно несло и носило, кроме магических предметов, превращается в кучку мелкого серого порошка. Такое существо можно вернуть к жизни только посредством заклинания [Истинное воскрешение](SPELL:${SPELL_TRUE_RESURRECTION}) или [исполнение желаний](SPELL:${SPELL_WISH}).

Это заклинание вызывает автоматический распад немагических предметов и творений из магической силы с размером не больше Большого. Если цель — предмет или творение из магической силы с размером Огромное или больше, заклинание вызывает распад его части в кубе с длиной ребра 10 футов. На магические предметы это заклинание не действует.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, урон увеличивается на 3к6 за каждый круг ячейки выше шестого.`,
    lvl: 6,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `магнетит и щепотка пыли`,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_FORCE,
        halfOnSuccess: false,
        diceCount: 10,
        diceType: 6,
        diceBonus: 40,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 273,
      },
      {
        id: SOURCE_SRD,
        page: 135,
      },
    ],
  },
  {
    id: SPELL_DISPEL_EVIL_AND_GOOD,
    name: 'Рассеивание добра и зла',
    nameEn: 'Dispel Evil and Good',
    description: `Вас окружает мерцающая энергия, защищающая от фей, нежити и существ, происходящих не с Материального плана.

Пока заклинание активно, исчадия, небожители, нежить, феи и элементали совершают по вам броски атаки с помехой. Вы можете закончить заклинание преждевременно, выбрав один из представленных ниже вариантов:

**Изгнание.** Вы совершаете действием рукопашную атаку заклинанием по исчадию, небожителю, нежити, фее или элементалю в пределах досягаемости. При попадании Вы пытаетесь изгнать существо на его родной план. Существо должно пройти испытание Харизмы, иначе оно будет отправлено на свой родной план (если только оно уже не находится там). Нежить отправляется в Царство Теней, а феи — в Страну Фей.

**Поломка чар.** Вы касаетесь действием существа, до которого можете дотянуться, и которое очаровано, испугано или одержимо исчадием, небожителем, нежитью, феей или элементалем. Это существо перестаёт быть очарованным, испуганным или одержимым такими существами.`,
    lvl: 5,
    magicSchoolId: MAGIC_ABJURATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'святая вода или порошок серебра и железа',
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      attackType: ATTACK_MELEE_SPELL,
      savethrowParam: PARAM_CHA,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 274,
      },
      {
        id: SOURCE_SRD,
        page: 136,
      },
    ],
  },
  {
    id: SPELL_DISPEL_MAGIC,
    name: 'Рассеивание магии',
    nameEn: 'Dispel Magic',
    description: `Выберите одно существо, предмет или магический эффект в пределах дистанции. Все заклинания 3 уровня и ниже на цели оканчиваются. Для каждого заклинания с уровнем 4 или выше совершите проверку базовой характеристики. СЛ равна 10 + круг заклинания. При успехе это заклинание оканчивается.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, Вы автоматически заканчиваете эффекты заклинаний на цели, чей уровень не превышает уровень использованной ячейки заклинаний.`,
    lvl: 3,
    magicSchoolId: MAGIC_ABJURATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: [
      {
        id: SOURCE_PHB,
        page: 274,
      },
      {
        id: SOURCE_SRD,
        page: 136,
      },
    ],
  },
  {
    id: SPELL_REGENERATE,
    name: 'Регенерация',
    nameEn: `Regenerate`,
    description: `Вы касаетесь существа и активируете его врождённые способности к исцелению. Цель восстанавливает 4к8 + 15 хитов. Пока заклинание активно, цель восстанавливает 1 хит в начале каждого своего хода (10 хитов в минуту).

Отрубленные части тела цели (пальцы, ноги, хвосты, и так далее), если они были, восстанавливаются через 2 минуты. Если отрубленная часть тела есть в наличии, и её можно приставить к обрубку, заклинание мгновенно сращивает их.`,
    lvl: 7,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `молитвенный барабан и святая вода`,
    duration: { timeId: TIME_HOUR, count: 1 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 274,
      },
      {
        id: SOURCE_SRD,
        page: 174,
      },
    ],
  },
  {
    id: SPELL_REINCARNATE,
    name: 'Реинкарнация',
    nameEn: `Reincarnate`,
    description: `Вы касаетесь мёртвого гуманоида или части мёртвого гуманоида. Если это существо мертво не более 10 дней, заклинание формирует для него новое взрослое тело и зовёт душу занять его. Если душа цели не свободна или не хочет новое тело, заклинание проваливается.

Новое тело для существа создаёт магия, и раса существа может измениться. Мастер бросает к100 и сверяется с таблицей, чтобы определить, в каком теле будет жить существо, либо же выбирает тело самостоятельно.

Реинкарнировавшее существо помнит свою прошлую жизнь и переживания. Оно сохраняет все способности, бывшие в прежнем теле, но раса меняется на новую, изменяя расовые особенности.

| к100  | Раса                   |
|-------|------------------------|
| 01–04 | Гном, лесной           |
| 05–10 | Гном, скальный         |
| 11–18 | Дварф, горный          |
| 19–27 | Дварф, холмовой        |
| 28–31 | Драконорождённый       |
| 32–35 | Полуорк                |
| 36–43 | Полурослик, коренастый |
| 44–51 | Полурослик, легконогий |
| 52–55 | Полуэльф               |
| 56–59 | Тифлинг                |
| 60–79 | Человек                |
| 80–88 | Эльф, высший           |
| 89–96 | Эльф, лесной           |
| 97–00 | Эльф, тёмный           | `,
    lvl: 5,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: true,
    hasConsumable: true,
    materialText: `редкие масла и мази, стоящие как минимум 1 000 зм, расходуемые заклинанием`,
    note: {
      text: `А что происходит с уже созданным телом, которое не было занято душой? Друид делает труп за 1000 золотых?`,
      author: `Эспумизан Коликит, гоблин волшебник`,
    },
    castTime: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 274,
      },
      {
        id: SOURCE_SRD,
        page: 174,
      },
    ],
  },
  {
    id: SPELL_ENTHRALL,
    name: 'Речь златоуста',
    nameEn: `Enthrall`,
    description: `Вы произносите отвлекающую речь, заставляя выбранных Вами существ, которых Вы видите в пределах дистанции, и которые при этом могут слышать Вас, пройти испытание Мудрости. Существа, которые не могут быть очарованными, автоматически преуспевают в испытании, и если Вы или Ваши спутники сражаетесь с существом, оно проходит испытание с преимуществом. При провале цель получает помеху к проверкам Мудрости (Внимательность), совершённым для обнаружения других существ кроме Вас, пока заклинание активно или пока цель не перестанет Вас слышать. Заклинание оканчивается, если Вы становитесь недееспособны или теряете возможность говорить.`,
    lvl: 2,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_WIT,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 274,
      },
      {
        id: SOURCE_SRD,
        page: 140,
      },
    ],
  },
  {
    id: SPELL_PLANT_GROWTH,
    name: 'Рост растений',
    nameEn: 'Plant Growth',
    description: `Это заклинание вливает жизненные силы в растения, находящиеся в определённой области. Есть два варианта использования этого заклинания; одно даёт краткосрочный, другое — долговременный эффект.

# Краткосрочный эффект

Если Вы активируете это заклинание 1 действием, выберите точку в пределах дистанции. Все обычные растения в пределах 100-футового радиуса с центром на этой точке становятся густыми и рослыми. Существо, перемещающееся по этой местности, должно потратить 4 фута перемещения за каждый 1 пройдённый фут.

Вы можете исключить одну или несколько областей любого размера в пределах зоны действия заклинания.

# Долговременный эффект

Если Вы активируете это заклинание 8 часов, Вы делаете почву удобренной. Все растения в радиусе полумили с центром на точке в пределах дистанции становятся удобренными на 1 год. Эти растения приносят удвоенный урожай.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 150,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    castTime: [
      { timeId: TIME_ACTION, count: 1 },
      { timeId: TIME_HOUR, count: 8 },
    ],
    source: [
      {
        id: SOURCE_PHB,
        page: 275,
      },
      {
        id: SOURCE_SRD,
        page: 169,
      },
    ],
  },
  {
    id: SPELL_ARMS_OF_HADAR,
    name: 'Руки Хадара',
    nameEn: `Arms of Hadar`,
    description: `Вы взываете к мощи Хадара, Тёмного Алкателя. Из Вас вырываются щупальца из тёмной энергии, бьющие всех существ в пределах 10 футов. Все существа в этой области должны пройти испытание Силы. В случае провала цель получает урон некротической энергией 2к6 и в следующем ходу не может совершать реакции. При успехе существо получает половину урона, и других эффектов нет.`,
    note: `Медленно сгорая дотла, древнее зло Хадар наделяет чудовищной силой колдунов, готовых помочь утолить его темный голод.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к6 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_CONJURATION,
    range: -1,
    rangeComment: `10-футовый радиус`,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      savethrowParam: PARAM_STR,
      damage: {
        type: DAMAGE_NECROTIC,
        halfOnSuccess: true,
        diceCount: 2,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 275,
    },
  },
  {
    id: SPELL_COLOR_SPRAY,
    name: 'Сверкающие брызги',
    nameEn: 'Color Spray',
    description: `Из Вашей руки вылетают яркие разноцветные лучи.

Бросьте 6к10; результат покажет, сколько хитов существ попадает под эффект. Существа в 15-футовом конусе, исходящем от Вас, попадают под эффект в порядке увеличения текущих хитов (игнорируя тех, кто без сознания и не может видеть). Начиная с существа с наименьшим числом текущих хитов, все существа, попавшие под действие заклинания, становятся Ослеплёнными до конца Вашего следующего хода. Вычитайте из остатка хиты уже Ослеплённых существ и переходите к следующим. Хиты существа не должны превышать остаток, чтобы это существо попало под действие заклинания.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, бросайте дополнительно 2к10 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_ILLUSION,
    range: -1,
    rangeComment: '15-футовый конус',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'щепотка порошка или песка, окрашенного в красный, желтый и синий цвет',
    duration: { timeId: TIME_ROUND, count: 1 },
    effect: {
      condition: CONDITION_BLINDED,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 275,
      },
      {
        id: SOURCE_SRD,
        page: 125,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь Ослепление длится до конца следующего хода заклинателя',
        page: 3,
      },
    ],
  },
  {
    id: SPELL_LIGHT,
    name: 'Свет',
    nameEn: 'Light',
    description: `Вы касаетесь одного предмета, длина которого ни по одному из измерений не превышает 10 футов. Пока заклинание активно, предмет испускает яркий свет в радиусе 20 футов и тусклый свет в пределах ещё 20 футов. Свет может быть любого выбранного Вами цвета. Полное покрытие предмета чем-то непрозрачным блокирует свет. Заклинание оканчивается, если Вы наложите его ещё раз или окончите действием.

Если Вы нацелились на предмет, несомый или носимый враждебным существом, это существо должно пройти испытание Ловкости, чтобы увернуться от заклинания.`,
    lvl: 0,
    magicSchoolId: MAGIC_EVOCATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_MATERIAL],
    materialText: 'светлячок или фосфоресцирующий мох',
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 275,
      },
      {
        id: SOURCE_SRD,
        page: 159,
      },
    ],
  },
  {
    id: SPELL_FREEDOM_OF_MOVEMENT,
    name: 'Свобода перемещения',
    nameEn: 'Freedom of Movement',
    description: `Вы касаетесь согласного существа. Пока заклинание активно, на перемещения цели не влияет труднопроходимая местность, а заклинания и прочие магические эффекты не могут ни уменьшить её скорость, ни сделать её парализованной или обездвиженой.

Цель может также потратить 5 футов перемещения, чтобы автоматически высвободиться из немагических оков, таких как кандалы или захват другого существа. Более того, нахождение под водой не накладывает штрафы на перемещение и атаки цели. `,
    lvl: 4,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кожаный ремешок, обмотанный вокруг предплечья или аналогичной конечности`,
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 275,
      },
      {
        id: SOURCE_SRD,
        page: 147,
      },
    ],
  },
  {
    id: SPELL_CONTACT_OTHER_PLANE,
    name: 'Связь с иным миром',
    nameEn: 'Contact other Plane',
    description: `Вы мысленно связываетесь с полубогом, духом давно умершего мудреца или другой таинственной сущностью с другого плана существования. Контакт с иномировой сущностью может ослабить или даже повредить Ваш рассудок. Накладывая это заклинание, Вы проходите испытание Интеллекта СЛ 15. При провале Вы получаете урон психической энергией 6к6 и становитесь безумным до окончания длинного отдыха. Будучи безумным, Вы не можете совершать действия, не можете понимать речь других существ, не можете читать и можете только неразборчиво бормотать. Заклинание [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}), наложенное на Вас, оканчивает этот эффект.

При успешном спасении Вы можете задать сущности до пяти вопросов. Вы должны задать вопросы до того как окончится заклинание. На все вопросы Мастер отвечает односложно, например, «да», «нет», «может быть», «никогда» или «неясно» (если сущность не знает ответ). Если односложный ответ может ввести в заблуждение, Мастер может произнести короткую фразу.`,
    lvl: 5,
    magicSchoolId: MAGIC_DIVINATION,
    range: -1,
    isRitual: true,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_MINUTE, count: 1 },
    castTime: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_INT,
      damage: {
        type: DAMAGE_PSYCHIC,
        halfOnSuccess: false,
        diceCount: 6,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 275,
      },
      {
        id: SOURCE_SRD,
        page: 129,
      },
    ],
  },
  {
    id: SPELL_SACRED_FLAME,
    name: 'Священное пламя',
    nameEn: 'Sacred Flame',
    description: `Похожее на огонь сияние нисходит на существо, которое Вы видите в пределах дистанции. Цель должна пройти испытание Ловкости, иначе она получает урон излучением 1к8. Для этого испытания цель не получает преимуществ от укрытия.`,
    highLvlCast: `Урон этого заклинания увеличивается на 1к8, когда Вы достигаете 5 уровня (2к8), 11 уровня (3к8) и 17 уровня (4к8).`,
    lvl: 0,
    magicSchoolId: MAGIC_EVOCATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_RADIANT,
        diceCount: 1,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 276,
      },
      {
        id: SOURCE_SRD,
        page: 176,
      },
    ],
  },
  {
    id: SPELL_WALL_OF_FORCE,
    name: 'Силовая стена',
    nameEn: 'Wall of Force',
    description: `Невидимая стена из силового поля появляется в точке, выбранной Вами в пределах дистанции.

Стена появляется, будучи ориентированной в любом направлении: горизонтальном, вертикальном или наклонённом. Она может свободно парить, а может опираться на твёрдую поверхность.

Вы можете создать её полусферой-куполом или сферой с радиусом до 10 футов, или можете сформировать плоскую поверхность, состоящую из плит 10 × 10 футов, количество которых может доходить до десяти. Каждая плита должна соседствовать как минимум с одной другой плитой. Какая бы форма ни была у стены, её толщина всегда ¼ дюйма (6 миллиметров). Она существует, пока активно заклинание. Если стена при появлении проходит по пространству существа, это существо выталкивается по одну из сторон стены (на Ваш выбор).

Ничто не может физически пройти сквозь эту стену. Она обладает иммунитетом ко всем видам урона, и не может быть рассеяна [рассеиванием магии](SPELL:${SPELL_DISPEL_MAGIC}). Заклинание [распад](SPELL:${SPELL_DISINTEGRATE}) полностью уничтожает стену. Стена простирается и на Эфирный План, блокируя эфирные перемещения сквозь себя.`,
    lvl: 5,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: false,
    materialText: `щепотка порошка толчёного прозрачного драгоценного камня`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 277,
      },
      {
        id: SOURCE_SRD,
        page: 190,
      },
    ],
  },
  {
    id: SPELL_GREASE,
    name: 'Масло',
    nameAlt: 'Скольжение',
    nameEn: `Ice Glide`,
    nameEnAlt: `Grease`,
    description: `Скользкий жир покрывает пол в квадрате с длиной стороны 10 футов с центром на точке в пределах дистанции, делая эту местность труднопроходимой, пока заклинание активно.

Когда жир появляется, все существа, стоящие в этой области, должны пройти испытание Ловкости, иначе они падают ничком. Существа, входящие в эту область или оканчивающие там ход, тоже должны пройти испытание Ловкости, чтобы не упасть.`,
    lvl: 1,
    magicSchoolId: MAGIC_CONJURATION,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусок масла или свиного сала`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    effect: {
      savethrowParam: PARAM_DEX,
      condition: CONDITION_PRONE,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 277,
      },
      {
        id: SOURCE_SRD,
        page: 150,
      },
    ],
  },
  {
    id: SPELL_LONGSTRIDER,
    name: 'Скороход',
    nameEn: `Longstrider`,
    description: `Вы касаетесь существа. Пока заклинание активно, скорость существа увеличивается на 10 футов.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `щепотка земли`,
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 277,
      },
      {
        id: SOURCE_SRD,
        page: 160,
      },
    ],
  },
  {
    id: SPELL_FEEBLEMIND,
    name: 'Слабоумие',
    nameEn: `Feeblemind`,
    description: `Вы врываетесь в сознание существа, которое видите в пределах дистанции, пытаясь подавить его интеллект и сознание. Цель получает урон психической энергией 4к6 и должна пройти испытание Интеллекта.

В случае провала Интеллект и Харизма существа становятся равны 1. Оно не может сотворять заклинания, активировать магические предметы, понимать языки и вразумительно общаться. Однако существо признаёт друзей, следует за ними, и даже защищает их.

В конце каждых 30 дней существо может повторять испытание от этого заклинания. Если оно преуспевает, заклинание оканчивается.

Это заклинание также оканчивается заклинаниями [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}), [Исполнение желаний](SPELL:${SPELL_WISH}) и [Полное исцеление](SPELL:${SPELL_HEAL}).`,
    lvl: 8,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 150,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `пригоршня глиняных, кристаллических, стеклянных или минеральных шариков`,
    effect: {
      savethrowParam: PARAM_INT,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 4,
        diceType: 6,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 277,
      },
      {
        id: SOURCE_SRD,
        page: 142,
      },
    ],
  },
  {
    id: SPELL_MELD_INTO_STONE,
    name: 'Слияние с камнем',
    nameEn: `Meld into Stone`,
    description: `Вы входите в каменный предмет или поверхность, достаточно большую, чтобы там поместилось Ваше тело, и сливаетесь на время действия заклинания вместе со всем снаряжением с камнем. Используя перемещение, Вы выходите из камня в точке, которой можете коснуться. Ваше присутствие абсолютно незаметно, и без помощи магии обнаружить Вас невозможно.

Слившись с камнем, Вы не можете видеть, что происходит вокруг, и все проверки Мудрости (Внимательность), совершённые, чтобы слушать, совершаются с помехой. Вы осознаёте ход времени и можете в камне сотворять заклинания на себя. Вы можете перемещением выйти из камня в том месте, где Вы входили, что оканчивает это заклинание. Иначе Вы перемещаться не можете.

Небольшие физические повреждения камня не причиняют Вам вреда, но частичное разрушение или изменение его формы (так, что Вы больше внутри не поместитесь) изгоняют Вас и причиняют дробящий урон 6к6. Полное разрушение камня (или превращение его в другую субстанцию) изгоняет Вас и причиняет дробящий урон 50. Если Вас изгоняют, Вы падаете ничком в свободном пространстве, ближайшем к месту, в котором Вы входили в камень.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 8 },
    effect: {
      condition: CONDITION_PRONE,
      damage: {
        type: DAMAGE_BLUDGEONING,
        diceBonus: 50,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 277,
      },
      {
        id: SOURCE_SRD,
        page: 163,
      },
    ],
  },
  {
    id: SPELL_POWER_WORD_HEAL,
    name: 'Слово силы: исцеление',
    nameEn: `Power Word Heal`,
    description: `Волна целительной энергии окутывает существо, которого Вы касаетесь. Цель восстанавливает все свои хиты. Если существо испугано, очаровано, ошеломлено или парализовано, это состояние оканчивается. Если существо лежит ничком, оно может реакцией встать. Это заклинание не оказывает никакого эффекта на нежить и конструктов.`,
    lvl: 9,
    magicSchoolId: MAGIC_EVOCATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: {
      id: SOURCE_PHB,
      page: 278,
    },
  },
  {
    id: SPELL_POWER_WORD_STUN,
    name: 'Слово силы: оглушение',
    nameEn: 'Power Word Stun',
    description: `Вы произносите слово силы, способное захлестнуть сознание одного существа, видимого в пределах дистанции, делая его потрясённым. Если у существа не больше 150 хитов, оно становится ошеломлённым. В противном случае это заклинание не оказывает никакого эффекта.

Ошеломлённая цель должна в конце каждого своего хода проходить испытание Телосложения. При успехе ошеломление проходит.`,
    lvl: 8,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL],
    effect: {
      savethrowParam: PARAM_CON,
      condition: CONDITION_STUNNED,
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 278,
      },
      {
        id: SOURCE_SRD,
        page: 170,
      },
    ],
  },
  {
    id: SPELL_POWER_WORD_KILL,
    name: 'Слово силы: смерть',
    nameEn: 'Power Word Kill',
    description: `Вы произносите слово силы, заставляющее существо, которое Вы видите в пределах дистанции, мгновенно умереть. Если у выбранного Вами существа не больше 100 хитов, он умирает. В противном случае это заклинание не оказывает никакого эффекта.`,
    lvl: 9,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 60,
    componentIdList: [CAST_VERBAL],
    source: [
      {
        id: SOURCE_PHB,
        page: 278,
      },
      {
        id: SOURCE_SRD,
        page: 170,
      },
    ],
  },
  {
    id: SPELL_PASSWALL,
    name: 'Создание прохода',
    nameEn: 'Passwall',
    description: `В точке, которую Вы видите в пределах дистанции на деревянной, оштукатуренной или каменной поверхности (такой как стена, потолок или пол) появляется проход, существующий, пока активно заклинание. Вы сами определяете габариты прохода: до 5 фт. в ширину, до 8 фт. в высоту и до 20 фт. в глубину. Этот проход не дестабилизирует само строение.

Когда проход исчезает, все существа и предметы, всё ещё находящиеся в нём, безопасно выталкиваются в свободное пространство, ближайшее к поверхности, на которой Вы активировали это заклинание.`,
    lvl: 5,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'щепотка кунжутного семени',
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 279,
      },
      {
        id: SOURCE_SRD,
        page: 167,
      },
    ],
  },
  {
    id: SPELL_MIND_BLANK,
    name: 'Сокрытие разума',
    nameEn: `Mind Blank`,
    description: `Пока заклинание активно, одно согласное существо, которого Вы касаетесь, получает иммунитет к урону психической энергией, и всем эффектам, которые должны чувствовать его эмоции или читать мысли, заклинаниям школы Прорицания и состоянию очарованности. Это заклинание даже помогает от заклинания [Исполнение желаний](SPELL:${SPELL_WISH}), а также от заклинаний и эффектов аналогичной силы, использованных для воздействия на сознание цели или получение от неё информации.`,
    lvl: 8,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 24 },
    source: [
      {
        id: SOURCE_PHB,
        page: 279,
      },
      {
        id: SOURCE_SRD,
        page: 164,
      },
    ],
  },
  {
    id: SPELL_SUNBEAM,
    name: 'Солнечный луч',
    nameEn: `Sunbeam`,
    description: `Луч ярчайшего света исходит из Вашей ладони линией шириной 5 футов и длиной 60 футов. Все существа в этой линии должны пройти испытание Телосложения. При провале существо получает урон излучением 6к8 и становится ослеплённым до Вашего следующего хода. При успехе оно получает половину урона и не ослепляется. Нежить и слизи проходят это испытание с помехой.

Пока заклинание активно, Вы можете действием создавать новые линии света.

Пока заклинание активно, в Вашей руке светится яркая искра. Она испускает яркий свет в пределах 30 футов и тусклый свет в пределах ещё 30 футов. Это свет идентичен солнечному.`,
    lvl: 6,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: `60-футовая линия`,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `увеличительное стекло`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_CON,
      condition: CONDITION_BLINDED,
      damage: {
        type: DAMAGE_RADIANT,
        halfOnSuccess: true,
        diceCount: 6,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 279,
      },
      {
        id: SOURCE_SRD,
        page: 184,
      },
    ],
  },
  {
    id: SPELL_MESSAGE,
    name: 'Сообщение',
    nameEn: `Message`,
    description: `Вы указываете пальцем на существо, находящееся в пределах дистанции, и шепчете послание. Цель (и только цель) слышит его, и может ответить шёпотом, который услышите только вы.

Вы можете использовать это заклинание сквозь твёрдые препятствия, если Вы знакомы с целью и знаете, что она находится за барьером. Магическая тишина, 1 фут камня, 1 дюйм обычного металла, тонкий лист свинца или 3 фута дерева блокируют заклинание. Заклинание не обязано идти по прямой линии, и может огибать углы и проходить через отверстия.`,
    lvl: 0,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кусочек медной проволоки`,
    duration: { timeId: TIME_ROUND, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 279,
      },
      {
        id: SOURCE_SRD,
        page: 164,
      },
    ],
  },
  {
    id: SPELL_RESISTANCE,
    name: 'Сопротивление',
    nameEn: 'Resistance',
    description: `Вы касаетесь одного согласного существа. Пока заклинание активно, цель может один раз бросить к4 и добавить выпавшее число к одному испытанию на свой выбор. Кость можно кидать до или после испытания. После этого заклинание оканчивается.`,
    lvl: 0,
    magicSchoolId: MAGIC_ABJURATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `крошечный плащ`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 280,
      },
      {
        id: SOURCE_SRD,
        page: 175,
      },
    ],
  },
  {
    id: SPELL_CREATION,
    name: 'Сотворение',
    nameEn: 'Creation',
    description: `Вы зачерпываете теневую материю в Царстве Теней и создаёте в пределах дистанции неживой предмет из растительной материи: ткань, верёвку, дерево, или что-то подобное. Вы также можете этим заклинанием создавать минеральную материю, такую как камни, кристаллы и металлы. Создаваемый предмет должен помещаться в куб с длиной ребра 5 фт., и должен иметь форму и материю, которые Вы раньше видели.

Длительность зависит от создаваемого материала. Если предмет составлен из нескольких материалов, используется самая короткая длительность.

| Материал             | Длительность |
|----------------------|--------------|
| Драгоценные камни    | 10 минут     |
| Адамантин или мифрил | 1 минута     |
| Растительная материя | 1 день       |
| Камень или кристалл  | 12 часов     |
| Драгоценные металлы  | 1 час        |

Использование материи, созданной этим заклинанием, в качестве материального компонента для другого заклинания, вызывает провал того заклинания.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, длина ребра куба увеличивается на 5 фт. за каждый круг ячейки выше пятого. `,
    lvl: 5,
    magicSchoolId: MAGIC_ILLUSION,
    castTime: { timeId: TIME_MINUTE, count: 1 },
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'крошечный кусочек материи того же вида, из которого Вы планируете создать предмет',
    duration: 'Особая',
    source: [
      {
        id: SOURCE_PHB,
        page: 280,
      },
      {
        id: SOURCE_SRD,
        page: 132,
      },
    ],
  },
  {
    id: SPELL_CREATE_OR_DESTROY_WATER,
    name: 'Сотворение или уничтожение воды',
    nameEn: 'Create or Destroy Water',
    description: `Вы либо создаёте, либо уничтожаете воду.

**Сотворение воды**. Вы создаёте до 10 галлонов (40 литров) чистой воды в пределах дистанции в открытом контейнере. В качестве альтернативы, вода выпадает дождём в кубе с длиной ребра 30 фт. в пределах дальности, туша открытое пламя.

**Уничтожение воды**. Вы уничтожаете до 10 галлонов (40 литров) воды в открытом контейнере в пределах дистанции. В качестве альтернативы, Вы уничтожаете туман в кубе с длиной ребра 30 фт. в пределах дистанции.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы создаёте или уничтожаете 10 дополнительных галлонов (40 литров) воды, и длина ребра куба увеличивается на 5 фт. за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: 'капля воды, если вода создаётся, или несколько песчинок, если вода уничтожается',
    source: [
      {
        id: SOURCE_PHB,
        page: 280,
      },
      {
        id: SOURCE_SRD,
        page: 132,
      },
    ],
  },
  {
    id: SPELL_CREATE_FOOD_AND_WATER,
    name: 'Сотворение пищи и воды',
    nameEn: 'Create Food and Water',
    description: `Вы создаёте 45 фунтов еды и 30 галлонов (100 литров) воды на земле или в контейнере в пределах дистанции, которых достаточно для питания пятнадцати гуманоидов или пятерых скакунов на 24 часа. Еда безвкусная, но сытная, и портится, если её не съесть за 24 часа. Вода чистая, и она не портится.`,
    lvl: 3,
    magicSchoolId: MAGIC_CONJURATION,
    range: 30,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    source: [
      {
        id: SOURCE_PHB,
        page: 280,
      },
      {
        id: SOURCE_SRD,
        page: 131,
      },
    ],
  },
  {
    id: SPELL_PRODUCE_FLAME,
    name: 'Сотворение пламени',
    nameEn: 'Produce Flame',
    description: `В Вашей ладони появляется мерцающее пламя. Оно остаётся там, пока заклинание активно, и не вредит ни вам, ни Вашему снаряжению. Огонь испускает яркий свет в радиусе 10 футов и тусклый свет в пределах ещё 10 футов. Заклинание оканчивается, если Вы оканчиваете его действием или накладываете ещё раз.

Вы можете атаковать этим пламенем, но это тоже оканчивает заклинание. Когда Вы накладываете это заклинание, или другим действием в одном из последующих ходов Вы можете метнуть пламя в существо, находящееся в пределах 30 футов от Вас. Совершите дальнобойную атаку заклинанием. При попадании цель получает урон огнём 1к8.`,
    highLvlCast: `Урон этого заклинания увеличивается на 1к8, когда Вы достигаете 5 уровня (2к8), 11 уровня (3к8) и 17 уровня (4к8).`,
    lvl: 0,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    effect: {
      attackType: ATTACK_RANGE_SPELL,
      damage: {
        type: DAMAGE_FIRE,
        diceCount: 1,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 281,
      },
      {
        id: SOURCE_SRD,
        page: 172,
      },
    ],
  },
  {
    id: SPELL_WIND_WALL,
    name: 'Стена ветров',
    nameEn: `Wind Wall`,
    description: `В точке на земле, выбранной Вами в пределах дистанции, начинает дуть сильный ветер. Вы можете сделать стену длиной до 50 футов, высотой до 15 футов и толщиной до 1 фута. Вы можете как угодно изгибать стену, лишь бы это был непрерывный путь по земле. Стена существует, пока активно заклинание.

Когда стена появляется, все существа в её области должны пройти испытания Силы. Существо получает дробящий урон 3к8 при провале, или половину этого урона при успехе.

Сильный ветер сдерживает туман, дым и прочие газы. Маленькие и ещё меньшие летающие существа и предметы не могут пройти через эту стену. Лёгкие, сыпучие материалы, оказавшиеся в стене, взлетают вверх. Стрелы, болты и прочие обычные боеприпасы, выпущенные в цели, находящиеся за стеной, отклоняются вверх и автоматически промахиваются (валуны, брошенные великанами и осадными машинами, а также подобные боеприпасы, не отклоняются). Существа в газообразной форме не могут пройти через неё.`,
    lvl: 3,
    magicSchoolId: MAGIC_EVOCATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `крошечный веер и перо экзотического происхождения`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_STR,
      damage: {
        type: DAMAGE_BLUDGEONING,
        halfOnSuccess: true,
        diceCount: 3,
        diceType: 8,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 281,
      },
      {
        id: SOURCE_SRD,
        page: 192,
      },
    ],
  },
  {
    id: SPELL_BLADE_BARRIER,
    name: 'Стена клинков',
    nameEn: 'Blade Barrier',
    description: `Вы создаёте вертикальную стену из крутящихся бритвенно-острых клинков из магической энергии. Стена появляется в пределах дистанции и существует столько, сколько активно это заклинание. Вы можете создать прямую стену длиной 100 фт., высотой 20 фт. и толщиной 5 фт., или закольцованную стену диаметром 60 фт., высотой 20 фт. и толщиной 5 фт. Стена предоставляет существам, находящимся за ней, укрытие на три четверти, и её пространство является труднопроходимой местностью.

Когда существо впервые за ход входит в пространство стены или начинает там ход, оно должно пройти испытание Ловкости. При провале существо получает рубящий урон 6к10. При успешном испытании существо получает половину урона.`,
    lvl: 6,
    magicSchoolId: MAGIC_EVOCATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_SLASHING,
        halfOnSuccess: true,
        diceCount: 6,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 282,
      },
      {
        id: SOURCE_SRD,
        page: 122,
      },
    ],
  },
  {
    id: SPELL_ELEMENTAL_WEAPON,
    name: 'Стихийное оружие',
    nameEn: 'Elemental Weapon',
    description: `Немагическое оружие, которого Вы касаетесь, становится магическим. Выберите один из следующих видов урона: звук, кислота, огонь, холод или электричество. Пока заклинание активно, оружие получает бонус +1 к броскам атаки и причиняет при попадании дополнительный урон выбранного вида 1к4.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 или 6 уровня, бонус к броску атаки увеличивается до +2, а дополнительный урон увеличивается до 2к4. Если Вы используете ячейку заклинания 7 круга или выше, бонус увеличивается до +3, а дополнительный урон увеличивается до 3к4.`,
    lvl: 3,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_HOUR, count: 1 },
    needConcentration: true,
    effect: {
      type: [
        ATTACK_MELEE_WEAPON,
        ATTACK_RANGE_WEAPON,
      ],
      damage: {
        type: [
          DAMAGE_ACID,
          DAMAGE_COLD,
          DAMAGE_ELECTRICITY,
          DAMAGE_FIRE,
          DAMAGE_THUNDER,
        ],
        diceCount: 1,
        diceType: 4,
      },
    },
    source: {
      id: SOURCE_PHB,
      page: 281,
    },
  },
  {
    id: SPELL_GUARDIAN_OF_FAITH,
    name: 'Страж веры',
    nameEn: 'Guardian of Faith',
    description: `В выбранной и видимой Вами свободной клетке в пределах дистанции появляется и начинает парить призрачный страж. Страж занимает своё пространство, и его контуры плохо видны за исключением сверкающего меча и щита, украшенного символом Вашего божества.

Все существа, враждебные вам, перемещающиеся впервые за ход в пространство, находящееся в пределах 10 футов от стража, должны пройти испытание Ловкости. При провале такое существо получает урон излучением 20, или половину этого урона при успехе. Страж исчезает, когда причинит суммарно 60 урона.`,
    lvl: 4,
    magicSchoolId: MAGIC_CONJURATION,
    range: 30,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_HOUR, count: 8 },
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_RADIANT,
        halfOnSuccess: true,
        diceBonus: 20,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 281,
      },
      {
        id: SOURCE_SRD,
        page: 150,
      },
    ],
  },
  {
    id: SPELL_GLOBE_OF_INVULNERABILITY,
    name: 'Сфера неуязвимости',
    nameEn: 'Globe of Invulnerability',
    description: `Вокруг Вас возникает неподвижный слабо мерцающий барьер с радиусом 10 футов, существующий, пока активно заклинание.

Все заклинания 5 уровня и ниже, наложенные снаружи барьера, не могут подействовать на существ и предметы, находящиеся внутри, даже если заклинание наложено с помощью ячейки большего уровня. Такие заклинания могут быть нацелены на существ и предметы, находящиеся внутри барьера, но не будут на них воздействовать. Пространство, защищённое барьером, тоже не включается в область действия таких заклинаний.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, барьер защищает от заклинаний с уровнем на 1 больше за каждый круг ячейки выше шестого.`,
    lvl: 6,
    magicSchoolId: MAGIC_ABJURATION,
    range: -1,
    rangeComment: '10-футовый радиус',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    hasCost: false,
    hasConsumable: true,
    materialText: `стеклянная или кристаллическая бусина, рассыпающаяся, когда заклинание заканчивается`,
    duration: { timeId: TIME_MINUTE, count: 1 },
    needConcentration: true,
    source: [
      {
        id: SOURCE_PHB,
        page: 282,
      },
      {
        id: SOURCE_SRD,
        page: 149,
      },
    ],
  },
  {
    id: SPELL_MIRAGE_ARCANE,
    name: 'Таинственный мираж',
    nameEn: `Mirage Arcane`,
    description: `Вы заставляете местность в пределах 1 квадратной мили выглядеть, звучать, пахнуть и даже ощущаться как другая местность. Общая форма местности остаётся той же самой. Открытые поля и дороги можно сделать похожими на болото, холм, расселину или другую труднопроходимую или непроходимую местность. Пруд можно сделать похожим на травяную лужайку, пропасть сделать пологим склоном, а каменистый овраг — широкой и ровной дорогой.

Точно так же, Вы можете изменять внешний облик строений, или создавать их там, где их не было. Это заклинание не маскирует, не скрывает и не добавляет существ.

Иллюзия включает в себя слышимые, видимые, тактильные и обонятельные элементы, так что она может сделать обычную местность труднопроходимой (и наоборот), и как-то иначе препятствовать перемещению. Все части иллюзорной местности (такие как камни и палки), вынесенные за пределы области пространства, мгновенно исчезают.

Существа с истинным зрением могут видеть сквозь иллюзию настоящую местность; однако, все элементы иллюзии для него остаются, так что хоть оно и будет знать о наличии иллюзии, оно всё ещё может взаимодействовать с ней.`,
    lvl: 7,
    magicSchoolId: MAGIC_ILLUSION,
    range: 'Видимость',
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_DAY, count: 10 },
    castTime: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 282,
      },
      {
        id: SOURCE_SRD,
        page: 165,
      },
    ],
  },
  {
    id: SPELL_TELEKINESIS,
    name: 'Телекинез',
    nameEn: 'Telekinesis',
    description: `Вы получаете способность перемещать силой мысли существ и предметы, а также манипулировать ими. Когда Вы накладываете это заклинание, а также в каждом последующем раунде, новым действием, Вы можете воздействовать на одно существо или предмет, видимый в пределах дистанции, вызывая один из описанных ниже эффектов. Вы можете действовать на одну и ту же цель, а можете каждый раз выбирать новую. Если Вы меняете цель, предыдущая перестаёт быть под действием этого заклинания.

**Предмет.** Вы можете попытаться переместить предмет, весящий до 1 000 фунтов. Если предмет не несут и не носят, Вы автоматически перемещаете его на 30 фт. в любом направлении, но не за пределы дистанции заклинания.

Если предмет несёт или носит существо, Вы должны совершить проверку базовой характеристики, противопоставив её проверке Силы существа. Если Вы выиграете проверку, Вы вырываете предмет у этого существа, и можете переместить его на 30 фт. в любом направлении, но не за пределы дистанции заклинания.

Вы очень хорошо контролируете предмет телекинетической хваткой, так что можете манипулировать простыми инструментами, открывать двери и контейнеры, доставать и убирать предметы из открытых контейнеров, а также выливать содержимое сосудов.

**Существо.** Вы можете попытаться переместить существо с размером не больше Огромного. Совершите проверку своей базовой характеристики, противопоставив её проверке Силы существа. Если Вы выиграете проверку, Вы перемещаете существо на 30 фт. в любом направлении, включая вверх, но не за пределы дистанции заклинания. До конца Вашего следующего хода существо обездвиживается телекинетической хваткой. Существо, поднятое вверх, висит в воздухе.

В последующих раундах Вы можете действием пытаться поддерживать телекинетический захват существа, повторяя встречную проверку.`,
    lvl: 5,
    magicSchoolId: MAGIC_TRANSMUTATION,
    needConcentration: true,
    range: 60,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count: 10 },
    source: [
      {
        id: SOURCE_PHB,
        page: 282,
      },
      {
        id: SOURCE_SRD,
        page: 185,
      },
    ],
  },
  {
    id: SPELL_TELEPATHY,
    name: 'Телепатия',
    nameEn: `Telepathy`,
    description: `Вы создаёте телепатическую связь между собой и знакомым согласным существом. Это существо может находиться где угодно на том же плане существования, что и вы. Заклинание оканчивается, если Вы с целью перестанете находиться на одном плане.

Пока заклинание активно, Вы и цель можете мгновенно обмениваться словами, образами, звуками и прочими сенсорными посланиями, и цель воспринимает Вас как существо, с которым она общается. Это заклинание позволяет существу с Интеллектом как минимум 1 понимать смысл Ваших слов и воспринимать посылаемые Вами сенсорные послания. `,
    lvl: 8,
    magicSchoolId: MAGIC_EVOCATION,
    range: Infinity,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `два переплетённых серебряных кольца`,
    duration: { timeId: TIME_HOUR, count: 24 },
    source: {
      id: SOURCE_PHB,
      page: 283,
    },
  },
  {
    id: SPELL_TELEPORT,
    name: 'Телепортация',
    nameEn: 'Teleport',
    description: `Это заклинание мгновенно переносит Вас и до восьми согласных существ на Ваш выбор, видимых Вами и находящихся в пределах дистанции, или один предмет, видимый в пределах дистанции, в выбранное Вами место. Если целью является предмет, он должен полностью помещаться в куб с длиной ребра 10 футов, и его не может носить или нести несогласное существо.

Выбранное место назначения должно быть известно Вам, и оно должно находиться на одном плане существования с Вами. Ваша ознакомленность с местом назначения определяет, успешным ли будет перемещение. Мастер бросает к100 и сверяется с таблицей.

| Ознакомленность   | Неудача | Похожее место | Мимо цели | Точно в цель |
|-------------------|---------|---------------|-----------|--------------|
| Постоянный круг   | —       | —             | —         | 01–100       |
| Связанный предмет | —       | —             | —         | 01–100       |
| Очень знакомое    | 01–05   | 06–13         | 14–24     | 25–100       |
| Изредка видимое   | 01–33   | 34–43         | 44–53     | 54–100       |
| Виденное один раз | 01–43   | 44–53         | 54–73     | 74–100       |
| Описанное         | 01–43   | 44–53         | 54–73     | 74–100       |
| Ложно описанное   | 01–50   | 51–100        | —         | —            |

# Ознакомленность

## Постоянный круг

Означает постоянный [круг телепортации](SPELL:${SPELL_TELEPORTATION_CIRCLE}), последовательность знаков которого Вам известна.

## Связанный предмет

Означает, что у Вас есть предмет, взятый из места назначения не позднее шести месяцев назад, например, книга из библиотеки волшебника, постельное бельё из королевской спальни, или кусок мрамора из потайного склепа лича.

## Очень знакомое

Это место, в котором Вы часто бываете, место, которое Вам довелось изучить, или место, которое Вы видите в процессе накладывания заклинания.

## Изредка видимое

Это место, которое Вы видели несколько раз, но которое Вам не очень хорошо известно.

## Виденное один раз

Это место, которое Вы видели один раз, возможно, при помощи магии.

## Описанное

Это место, чьё местоположение и внешний облик Вам известно со слов других, возможно, по карте.

## Ложно описанное

Это место, которое не существует. Возможно, Вы пытались подсмотреть святилище врага, но на самом деле видели иллюзию, или Вы пытались телепортироваться в знакомое место, которое больше не существует.

# Результат

## Точно в цель

Вы и Ваша группа (или предмет) появляетесь там, где было задумано.

## Мимо цели

Вы и Ваша группа (или предмет) появляетесь на случайном расстоянии от места назначения в случайном направлении.

Расстояние от цели равно (1к10 × 1к10) процентов от расстояния, на которое Вы перемещались. Например, если Вы пытались перенестись на 120 миль, промахиваетесь мимо цели, и на двух 1к10 у Вас выпадает «5» и «3», то Вы промахиваетесь мимо цели на 15 процентов, то есть на 18 миль.

Мастер определяет направление случайным образом, бросая к8, где «1» означает север, «2» — северо-восток, «3» — восток, и так далее.

Например, если Вы телепортировались в город на побережье, и оказываетесь в море на расстоянии 18 миль, Вы окажетесь в беде.

## Похожее место

Вы и Ваша группа (или предмет) оказываетесь в другом месте, визуально или тематически похожем на место назначения.

Например, если Вы направлялись в свою лабораторию, Вы можете оказаться в лаборатории другого волшебника или алхимическом магазине с таким же оборудованием как в Вашей лаборатории. Обычно Вы появляетесь в ближайшем похожем месте, но так как у заклинания нет ограничений по дистанции, Вы можете оказаться где угодно на текущем плане.

## Неудача

Непредсказуемая магия заклинания делает перемещение опасным. Все телепортирующиеся существа (или предмет) получают урон силовым полем 3к10, и Мастер совершает новый бросок по таблице, чтобы определить место назначения (возможны несколько неудач, и каждая будет причинять урон).`,
    lvl: 7,
    magicSchoolId: MAGIC_CONJURATION,
    range: 10,
    componentIdList: [CAST_VERBAL],
    effect: {
      damage: {
        type: DAMAGE_FORCE,
        diceCount: 3,
        diceType: 10,
      },
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 283,
      },
      {
        id: SOURCE_SRD,
        page: 185,
      },
    ],
  },
  {
    id: SPELL_DARKVISION,
    name: 'Тёмное зрение',
    nameEn: `Darkvision`,
    description: `Вы касаетесь согласного существа и даруете ему возможность видеть в темноте. Пока заклинание активно, это существо обладает тёмным зрением в пределах 60 футов.`,
    lvl: 2,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: 0,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `щепотка сушёной морковки или агат`,
    duration: { timeId: TIME_HOUR, count: 8 },
    source: [
      {
        id: SOURCE_PHB,
        page: 283,
      },
      {
        id: SOURCE_SRD,
        page: 133,
      },
    ],
  },
  {
    id: SPELL_TENSER_S_FLOATING_DISK,
    name: 'Тензеров парящий диск',
    nameEn: `Tenser’s Floating Disk`,
    nameEnAlt: `Floating disk`,
    description: `Это заклинание создаёт круглый горизонтальный диск из силового поля диаметром 3 фута и толщиной 1 дюйм (2,5 см.), парящий в 3 футах над полом в свободном пространстве на Ваш выбор, видимом в пределах дистанции. Диск существует, пока заклинание активно, и может вынести на себе 500 фунтов. Если на него поместить больший вес, заклинание окончится, и всё, что было на диске, падает на пол.

Диск неподвижен, пока Вы находитесь в пределах 20 футов от него. Если Вы переместитесь от него на расстояние, превышающее 20 футов, диск следует за Вами, оставаясь в пределах 20 футов. Он может перелетать по неровной поверхности, перемещаться по лестницам, склонам и подобным препятствиям, но не может пересекать перепады по высоте в 10 футов и более. Например, диск не сможет перелететь над 10-футовой ямой, и не сможет из неё выбраться, если будет создан на её дне.

Если Вы переместитесь более чем на 100 футов от диска (например, если он из-за препятствия не сможет последовать за Вами), заклинание оканчивается.`,
    lvl: 1,
    magicSchoolId: MAGIC_CONJURATION,
    range: 30,
    isRitual: true,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `капля ртути`,
    duration: { timeId: TIME_HOUR, count: 1 },
    source: [
      {
        id: SOURCE_PHB,
        page: 284,
      },
      {
        id: SOURCE_SRD,
        page: 146,
      },
    ],
  },
  {
    id: SPELL_WALL_OF_THORNS,
    name: 'Терновая стена',
    nameEn: `Wall of Thorns`,
    description: `Вы создаёте стену из крепких, гибких, перевитых кустов с острыми как иглы шипами. Стена появляется в пределах дистанции на твёрдой поверхности и существует, пока активно заклинание. Вы можете сделать стену до 60 футов в длину, до 10 футов в высоту и толщиной 5 футов или кругом с диаметром 20 футов, высотой до 20 футов и толщиной 5 футов. Эта стена блокирует линию обзора.

Когда стена появляется, все существа, находящиеся в ней, должны пройти испытание Ловкости. При провале существо получает колющий урон 7к8, или половину этого урона при успехе.

Существа могут проходить сквозь стену, но медленно и болезненно. За каждый 1 фут перемещения через стену существо должно потратить 4 фута перемещения. Кроме того, в первый раз за ход, когда существо входит в стену или оканчивает там ход, оно должно проходить испытание Ловкости. Оно получает рубящий урон 7к8 при провале, или половину этого урона при успехе.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, оба вида урона увеличиваются на 1к8 за каждый круг ячейки выше шестого.`,
    lvl: 6,
    magicSchoolId: MAGIC_CONJURATION,
    range: 120,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `пригоршня шипов`,
    duration: { timeId: TIME_MINUTE, count: 10 },
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: [
        {
          type: DAMAGE_PIERCING,
          halfOnSuccess: true,
          diceCount: 7,
          diceType: 8,
        },
        {
          type: DAMAGE_SLASHING,
          halfOnSuccess: true,
          diceCount: 7,
          diceType: 8,
        },
      ],
    },
    source: [
      {
        id: SOURCE_PHB,
        page: 284,
      },
      {
        id: SOURCE_SRD,
        page: 191,
      },
    ],
  },
]
