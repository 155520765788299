const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_BATTLEAXE,
  GEAR_MACE,
  GEAR_RAM_PORTABLE,
  GEAR_SPEAR,
} = require('./../../../../../gearIdList')
const {
  MGC_TYPE_ROD,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_FLAME_TONGUE,
  MAGIC_ITEM_ROD_OF_LORDLY_MIGHT,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_ROD_OF_LORDLY_MIGHT,
  name: `Жезл величественной мощи`,
  nameEn: `Rod of Lordly Might`,
  type: MGC_TYPE_ROD,
  gearType: GEAR_MACE,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: `У этого жезла ребристое навершие, и он функционирует как магическая [булава](GEAR:${GEAR_MACE}), предоставляющая бонус +3 к броскам атаки и урона, совершённым им. У жезла есть свойства, связанные с шестью кнопками, расположенными вдоль ручки, а также три дополнительных свойства.

# Шесть кнопок

Вы можете нажать одну из шести кнопок бонусным действием. Эффект кнопки длится, пока Вы не нажмёте другую кнопку, или пока не нажмёте эту же кнопку ещё раз, что возвращает жезл в обычную форму.

1. Если Вы нажмёте кнопку 1, жезл становится [языком пламени](MAGIC_ITEM:${MAGIC_ITEM_FLAME_TONGUE}), так как из ручки появляется пламенный клинок.
2. Если Вы нажмёте кнопку 2, навершие разойдётся в стороны, становясь двумя полукружьями, а жезл становится магическим [боевым топором](GEAR:${GEAR_BATTLEAXE}), предоставляющим бонус +3 к броскам атаки и урона, совершённым им.
3. Если Вы нажмёте кнопку 3, навершие разойдётся в стороны, из него появится наконечник копья, а рукоятка удлиняется, становясь 6-футовым древком. Так жезл становится магическим [копьём](GEAR:${GEAR_SPEAR}), предоставляющим бонус +3 к броскам атаки и урона, совершённым им.
4. Если Вы нажмёте кнопку 4, жезл превратится в шест для лазания с длиной до 50 футов, на Ваш выбор. На поверхностях, твёрдых как гранит, в основании появляется шип, а на верхней части три крюка, надёжно закрепляющие шест. Через 1 фут в стороны от шеста отходят 3-дюймовые горизонтальные перекладины, превращая его в подобие лестницы. Шест может выдержать до 4000 фунтов. Больший вес или отсутствие надёжной опоры возвращают жезл в обычную форму.
5. Если Вы нажмёте кнопку 5, жезл превратится в [переносной таран](GEAR:${GEAR_RAM_PORTABLE}), предоставляющий использующему его бонус +10 к проверкам Силы, совершённым для выламывания дверей, разлома баррикад и прочих препятствий.
6. Если Вы нажмёте кнопку 6, жезл возвращается в обычную форму или же сохраняет её, но при этом начинает указывать на северный магнитный полюс. Ничего не произойдёт, если эту функцию использовать в месте, где нет магнитного полюса. Жезл также даёт Вам знание примерной глубины под землёй или же наоборот, высоты над землёй.

# Вытягивание жизни

Если Вы попадаете по существу рукопашной атакой, используя этот жезл, Вы можете заставить цель пройти испытание Телосложения СЛ 17. При провале цель получит дополнительный урон некротической энергией 4к6, а Вы восстановите количество хитов, равное половине этого урона некротической энергией. Это свойство нельзя использовать повторно до следующего рассвета.

# Паралич

Если Вы попадаете по существу рукопашной атакой, используя этот жезл, Вы можете заставить цель пройти испытание Силы СЛ 17. При провале цель становится парализованной на 1 минуту. Цель может повторять испытания в конце каждого своего хода, оканчивая эффект при успехе. Это свойство нельзя использовать повторно до следующего рассвета. 

# Испуг

Если Вы держите этот жезл, Вы можете действием заставить всех существ, которых видите в пределах 30 футов от себя, пройти испытание Мудрости СЛ 17. При провале цель становится испуганной Вами на 1 минуту. Испуганная цель может повторять испытания в конце каждого своего хода, оканчивая эффект на себе при успехе. Это свойство нельзя использовать повторно до следующего рассвета. `,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 160,
  },
}
