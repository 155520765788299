module.exports = [
  require('./list/brain_in_a_jar'),
  require('./list/chardalyn_berserker'),
  require('./list/chardalyn_dragon'),
  require('./list/coldlight_walker'),
  require('./list/demos_magen'),
  require('./list/fox'),
  require('./list/frost_druid'),
  require('./list/frost_giant_skeleton'),
  require('./list/galvan_magen'),
  require('./list/goliath_warrior'),
  require('./list/goliath_werebear'),
  require('./list/hypnos_magen'),
  require('./list/reindeer'),
]
