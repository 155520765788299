const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_FIRE} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_INVESTITURE_OF_FLAME} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_INVESTITURE_OF_FLAME,
  name: 'Облачение огня',
  nameEn: 'Investiture of Flame',
  description: `Пока действует заклинание, Ваше тело охватывают языки пламени, испускающие яркий свет в радиусе 30 футов и тусклый свет в пределах ещё 30 футов. Этот огонь не причиняет Вам никакого вреда, а Вы получаете следующие преимущества:

*  Вы получаете иммунитет к урону огнём, а также сопротивление к урону холодом.
* Все существа, впервые за ход перемещающиеся в пределах 5 футов от Вас или заканчивает там свой ход, получают урон огнём 1к10.
* Вы можете действием создать линию огня длиной 15 футов и шириной 5 футов, исходящую от Вас в выбранном направлении. Все существа в этой линии должны пройти испытание Ловкости. При провале они получают урон огнём 4к8 или половину этого урона при успехе.`,
  lvl: 6,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_FIRE,
      halfOnSuccess: true,
      diceCount: 4,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 159,
    },
    {
      id: SOURCE_EE,
      page: 19,
    },
  ],
}
