const {
  CREATURE_AUROCHS,
  CREATURE_OGRE,
  CREATURE_ORC_WAR_CHIEF,
  CREATURE_OROG,
  CREATURE_TANARUKK,
  CREATURE_TROLL,
} = require('./../../creatureIdList')
const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const gruumshDescriptionList = require('./gruumsh')
const luthicDescription = require('./luthic')

module.exports = [
  {
    text: `Орки — дикие грабители и налётчики; у них сутулая осанка, низкий лоб и свиноподобные лица с выступающими нижними клыками, напоминающими бивни.`,
    source: {
      id: SOURCE_MM,
      page: 227,
    },
  },
  ...gruumshDescriptionList,
  {
    header: 'Племена как чума',
    text: `Орки собираются в племена, которые распространяют свою власть и утоляют кровожадность, разграбляя деревни, пожирая или прогоняя бродячие стада, и убивая любых гуманоидов, которые осмелятся выступить против них. После уничтожения поселения орки дочиста разграбляют все сокровища и иные полезные вещи, которые можно использовать в своих землях. Остатки деревень или стоянок они сжигают, а затем уходят туда, откуда пришли, утолив свою жажду крови.`,
    source: {
      id: SOURCE_MM,
      page: 227,
    },
  },
  {
    header: 'Странствующие падальщики',
    text: `Жажда разрушения толкает орков селиться в пределах досягаемости новых целей. Они редко оседают на местах и не преобразовывают руины, пещерные комплексы и деревни побеждённых врагов в свои укреплённые лагеря и крепости. Орки строят только для защиты, не внося в постройки никаких новшеств или улучшений, ограничиваясь грубыми частоколами с насаженными телами своих врагов или траншеями и рвами с торчащими из них колами.

Когда на захваченной территории заканчивается пища, племя орков делится на бродячие банды, которые ищут новые земли для охоты. Когда они возвращаются с трофеями и новостями, выбирается следующая самая богатая цель. Затем племя движется всей массой, проделывая свой кровавый путь к новой территории.

В редких случаях лидер племени решает закрепиться в укреплённом логове на несколько десятков дней. Орки такого племени разбредаются по большой территории, чтобы прокормить себя.`,
    source: {
      id: SOURCE_MM,
      page: 227,
    },
  },
  {
    header: 'Лидерство и мощь',
    text: `Племена орков в основном патриархальны и щеголяют яркими и кричащими названиями, такими как Многострелы, Кричащий глаз, Губители эльфов. Время от времени могущественный [военный вождь](CREATURE:${CREATURE_ORC_WAR_CHIEF}) объединяет рассеянные племена орков в единую неистовую орду, которая попирает другие племена и поселения людей с позиции силы.

Сила и власть — величайшие добродетели орков, и они принимают в свои племена всех могучих существ. Отвергая представления о расовой чистоте, они с гордостью приветствуют [огров](CREATURE:${CREATURE_OGRE}), [троллей](CREATURE:${CREATURE_TROLL}), полуорков и [орогов](CREATURE:${CREATURE_OROG}) в своих рядах. Орки также уважают и боятся размера и силы злых великанов, и часто выступают в роли их охранников и солдат.`,
    source: {
      id: SOURCE_MM,
      page: 227,
    },
  },
  luthicDescription,
  {
    header: 'Орки',
    text: `Для большинства обывателей мира орк — это орк. Они знают, что любой из этих дикарей может разорвать обычного жителя на куски, так что никакого дальнейшего различия не требуется.

Для орков это не так. В племени есть разные группы орков, действия которых продиктованы божеством, которому они поклоняются. В дополнение к различным видам воинов, которые разбегаются и опустошают деревни вокруг, у каждого племени есть члены, которые остаются глубоко внутри логова, редко или даже никогда не видят что лежит за пределами тьмы их берлоги.

Кроме того, у орков особые отношения с двумя видами существ, которых иногда можно увидеть в их компании: [туры](CREATURE:${CREATURE_AUROCHS}), большие быки, которые служат ездовыми животными для воинов, почитающих Багтру, и [танарукки](CREATURE:${CREATURE_TANARUKK}), помесь демона и орка, такие развращенные и разрушительные, что даже орки желают их убить.`,
    source: {
      id: SOURCE_VGTM,
      page: 179,
    },
  },
]
