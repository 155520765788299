const arrify = require('arrify')

const extendCreature = require('./../../utils/extendCreature')
const generateTextLinks = require('./../../utils/generateTextLinks')
const generateListReleasedBeforeAs = require('./../../utils/generateListReleasedBeforeAs')
const prepareForSearch = require('./../../utils/prepareForSearch')
const sortByName = require('./../../utils/sortByName')
const extendCreatureFeatureListByAbilityConstants = require('./../../utils/extendCreatureFeatureListByAbilityConstants')
const checkIsFromUA = require('./../../utils/checkIsFromUA')
const nameListGenerator = require('./../../utils/nameListGenerator')

const SEARCH_PROP_NAME = require('./../SEARCH_PROP_NAME')

const creatureRawList = require('./creatureRawList')

const creatureRawCollection = creatureRawList.reduce(
  (collection, creature) => ({
    ...collection,
    [creature.id]: creature,
  }),
  {}
)

const handleListWithDescriptions = list => list
  ? list.map(
    action => action.description
      ? {
        ...action,
        description: generateTextLinks(action.description),
      }
      : action
  )
  : []

const extractAbilityIdList = list => list
  ? list.reduce(
    (resultList, ability) => ability.id
      ? [
        ...resultList,
        ability.id,
      ]
      : resultList,
    []
  )
  : []

function updateCreatureFromParent ({id: creatureId}) {
  const creature = creatureRawCollection[creatureId]

  if (creature.parentId) {
    const parent = updateCreatureFromParent({id: creature.parentId})

    delete creature.parentId

    const creatureFilled = extendCreature({creature, parent})

    creatureRawCollection[creature.id] = creatureFilled

    return creatureFilled
  }

  return creature
}

const creatureList = creatureRawList
  .map(updateCreatureFromParent)
  .map(extendCreatureFeatureListByAbilityConstants)
  .sort(sortByName)
  .map(
    (creature, i, list) => ({
      ...creature,
      abilityIdList: extractAbilityIdList(creature.featureList),
      actionList: handleListWithDescriptions(creature.actionList),
      couldBeFamiliar: Boolean(creature.couldBeFamiliar),
      createdBySpellId: creature.createdBySpellId || null,
      creatureTypeIdList: arrify(creature.creatureTypeIdList || []),
      featureList: handleListWithDescriptions(creature.featureList),
      hasLegendaryPoints: Boolean(creature.legendaryPoints),
      imageNameList: creature.imageNameList || [],
      isCreatedBySmth: Boolean(
        creature.createdBySpellId
        || creature.createdByFeatureId
        || creature.createdByInfusionId
      ),
      hasSpoilers: Boolean(creature.hasSpoilers),
      isReadyToExportIntoFoundryVTT: Boolean(creature.isReadyToExportIntoFoundryVTT),
      isReReleased: Boolean(creature.releasedAs),
      isUA: checkIsFromUA(creature.source),
      legendaryActionList: handleListWithDescriptions(creature.legendaryActionList),
      releasedAs: creature.releasedAs || null,
      releasedBeforeAsList: generateListReleasedBeforeAs(list, creature.id),
      [SEARCH_PROP_NAME]: prepareForSearch(
        [
          creature.name,
          creature.nameEn,
          arrify(creature.nameAlt).join(' ') || '',
          arrify(creature.nameEnAlt).join(' ') || '',
        ]
          .filter(e => e)
          .join('\n')
      ),
    })
  )

module.exports = creatureList

module.exports.creatureCollection = creatureList.reduce(
  (collection, creature) => ({
    ...collection,
    [creature.id]: creature,
  }),
  {}
)

module.exports.creatureNameList = creatureList.map(
  ({id, name, nameAlt, nameEn, nameEnAlt, description, ...rest}) => ({
    id,
    name,
    nameAlt,
    nameEn,
    nameEnAlt,
    description,
    [SEARCH_PROP_NAME]: rest[SEARCH_PROP_NAME],
  })
)

module.exports.dragonBreatheWeaponCollection = require('./dragonBreatheWeaponCollection')
module.exports.creatureNames = nameListGenerator(creatureList)
