const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ACROBATICS,
  SKILL_ATHLETICS,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_DAGGER,
  GEAR_LEATHER_ARMOR,
  GEAR_RAPIER,
} = require('./../../../../gearIdList')
const {ALIGNMENT_NOT_LAWFUL} = require('./../../../../aligmentList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_SWASHBUCKLER} = require('./../../../../creatureIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Рубака',
  nameAlt: 'Сорвиголова',
  nameEn: 'Swashbuckler',
  id: CREATURE_SWASHBUCKLER,
  description: `Рубаки — харизматичные авантюристы, которые живут по своему собственному кодексу чести. Они жаждут славы, балуются романтичными свиданиями, и часто живут жизнью пиратов и корсаров, редко оставаясь в одном месте надолго.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_NOT_LAWFUL,
  source: {
    id: SOURCE_VGTM,
    page: 221,
  },
  armor: {
    ac: 17,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 12,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 15,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_DOUBLE,
    [SKILL_ATHLETICS]: PROF_DOUBLE,
    [SKILL_PERSUASION]: PROF_DOUBLE,
  },
  languageList: [
    {
      id: LANG_ANY,
      comment: 'обычно — Всеобщий',
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Проворный',
      description: `★СУЩЕСТВО★ может каждый ход бонусным действием совершать Рывок или Отход.`,
    },
    {
      name: 'Вежливая защита',
      description: `Пока ★СУЩЕСТВО★ носит лёгкую броню или не носит броню и не носит щит, к ★его★ КД добавляется модификатор Харизмы.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Кинжалом_ и две _Рапирой_.`,
    },
    {
      gearId: GEAR_DAGGER,
    },
    {
      gearId: GEAR_RAPIER,
    },
  ],
}
