const {SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES} = require('./../../../sourceList')
const {druidCircleSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE_2019_10_03} = require('./../../../pcSubClassIdList')

const {
  SPELL_AURA_OF_LIFE,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FIREBALL,
  SPELL_FLAME_STRIKE,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_PLANT_GROWTH,
  SPELL_RAISE_DEAD,
  SPELL_SCORCHING_RAY,
} = require('./../../../spellIdList')

const {
  FEATURE_CIRCLE_OF_WILDFIRE_SPELLS_2019_10_03,
  FEATURE_CIRCLE_OF_WILDFIRE_SPELLS_2019_10_03_spellLvl_0,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_CIRCLE_OF_WILDFIRE_SPELLS_2019_10_03,
    name: `Заклинания круга лесного пожара`,
    nameEn: `Circle of Wildfire Spells`,
    lvl: 2,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE_2019_10_03,
    spellCircleLvl: druidCircleSpellCircleList,
    spellIdList: [
      SPELL_AURA_OF_LIFE,
      SPELL_FIRE_SHIELD,
      SPELL_FIREBALL,
      SPELL_FLAME_STRIKE,
      SPELL_LOCATE_ANIMALS_OR_PLANTS,
      SPELL_PLANT_GROWTH,
      SPELL_RAISE_DEAD,
      SPELL_SCORCHING_RAY,
    ],
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 2,
    },
  },
  {
    id: FEATURE_CIRCLE_OF_WILDFIRE_SPELLS_2019_10_03_spellLvl_0,
    name: `Заклинания круга лесного пожара`,
    nameEn: `Circle of Wildfire Spells`,
    lvl: 2,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE_2019_10_03,
    spellIdList: SPELL_FIRE_BOLT,
    source: {
      id: SOURCE_UA_2019_10_03_TWILIGHT_FIRE_NAMES,
      page: 2,
    },
  },
]
