module.exports = [
  require('./oaken_bolter'),
  require('./oblex_adult'),
  require('./oblex_elder'),
  require('./oblex_spawn'),
  require('./ogre_battering_ram'),
  require('./ogre_bolt_launcher'),
  require('./ogre_chain_brute'),
  require('./ogre_howdah'),
  require('./orcus'),
  require('./orthon'),
]
