const {SOURCE_WDH} = require('./../../../../sourceList')
const {waterdeepCityGuardDescriptionList} = require('./../../../../textCommonParts')
const {
  CREATURE_GUARD,
  CREATURE_WATERDEEP_S_CITY_GUARD_PRIVATE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Рядовой гвардии Глубоководья, ',
  nameEn: 'Waterdeep’s city guard, Private',
  id: CREATURE_WATERDEEP_S_CITY_GUARD_PRIVATE,
  parentId: CREATURE_GUARD,
  description: waterdeepCityGuardDescriptionList,
  source: {
    id: SOURCE_WDH,
    page: 197,
  },
}
