const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const commonSpellIdList = require('./commonSpellIdList')
const siblingIdList = require('./siblingIdList')
const generateSpellList = require('./generateSpellList')

module.exports = (
  {
    gearType,
    id,
    name,
    nameEn,
    rarity,
    spellIdList,
 }
) => {
  const spellIdFullList = [...spellIdList, ...commonSpellIdList]
  const spellList = generateSpellList(spellIdFullList, true)

  return {
    id,
    name,
    nameEn,
    rarity,
    gearType,
    type: MGC_TYPE_WONDROUS_ITEM,
    attunement: true,
    attunementComment: `бардом`,
    description: `${name} — инструмент бардов, прекрасный образец музыкального инструмента, во всём превосходящий обычные аналоги. Есть семь разновидностей таких инструментов, каждый назван в честь одной из легендарных коллегий бардов. Существо, пытающееся играть на инструменте, не будучи настроенным на него, должно пройти испытание Мудрости СЛ 15, иначе получит урон психической энергией 2к4.

# Заклинания

${spellList}

Вы можете действием сыграть на инструменте и наложить одно из его заклинаний. После того как ${name} использована для наложения заклинания, она не может повторно сотворять это заклинание до следующего рассвета. Заклинания используют Вашу базовую характеристику и СЛ испытаний от Ваших заклинаний.

Если Вы использовали инструмент для накладывания заклинания, которое делает цель очарованной при провале испытания, цель проходит это испытание с помехой. Этот эффект применяется вне зависимости от того, использовали ли Вы инструмент в качестве источника заклинания или в качестве заклинательной фокусировки.`,
    genderId: GENDER_FEMALE,
    siblingIdList,
    spellIdList: spellIdFullList,
    source: {
      id: SOURCE_DMG,
      page: 167,
    },
  }
}
