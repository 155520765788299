module.exports.FIGHTING_STYLE_ARCHERY = 'archery'
module.exports.FIGHTING_STYLE_BLESSED_WARRIOR = 'blessed_warrior'
module.exports.FIGHTING_STYLE_BLIND_FIGHTING = 'blind_fighting'
module.exports.FIGHTING_STYLE_DEFENSE = 'defense'
module.exports.FIGHTING_STYLE_DRUIDIC_WARRIOR = 'druidic_warrior'
module.exports.FIGHTING_STYLE_DUELING = 'dueling'
module.exports.FIGHTING_STYLE_GREAT_WEAPON_FIGHTING = 'great_weapon_fighting'
module.exports.FIGHTING_STYLE_INTERCEPTION = 'interception'
module.exports.FIGHTING_STYLE_PROTECTION = 'protection'
module.exports.FIGHTING_STYLE_SUPERIOR_TECHNIQUE = 'superior_technique'
module.exports.FIGHTING_STYLE_THROWN_WEAPON_FIGHTING = 'thrown_weapon_fighting'
module.exports.FIGHTING_STYLE_TWO_WEAPON_FIGHTING = 'two_weapon_fighting'
module.exports.FIGHTING_STYLE_UNARMED_FIGHTING = 'unarmed_fighting'
