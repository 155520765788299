const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {PLACE_TURMISH} = require('./../../placeIdList')
const {LANG_TURMIC} = require('./../../languageIdList')

module.exports = {
  id: LANG_TURMIC,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Turmic',
  name: {
    nominative: 'Тёрамийский',
    genitive: 'Тёрамийского',
    instrumental: 'Тёрамийским',
    prepositional: 'Тёрамийском',
  },
  typicalSpeakers: 'Тёрами',
  spokenPlaceList: PLACE_TURMISH,
  isReady: false,
  isRealLang: true,
}
