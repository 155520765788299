const {
    SOURCE_AI,
  } = require('./../../../../sourceList'),
  {
    CR_1,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_CONSTRUCT,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ACROBATICS,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  } = require('./../../../../damageTypeList'),
  {
    LANG_COMMON,
    LANG_DRACONIC,
  } = require('./../../../../languageIdList'),
  {
    SENSE_BLIND_VISION,
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_FALSE_APPEARANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_CLOCKWORK_DRAGON,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Заводной дракон',
  nameEn: 'Clockwork Dragon',
  id: CREATURE_CLOCKWORK_DRAGON ,
  description: `Эти замысловатые конструкции обычно создаются в виде металлических драконов. Покрытые латунью, бронзой, медью или фальшивым золотом и серебром, на первый взгляд они выглядят как прекрасные драконьи статуи. Заводной дракон — грозный стража или защитник. Его интеллект крайне продвинут, что позволяет ставить ему очень сложные задачи и нестандартные приказы, а его реакция не нестандартные угрозы крайне изобретательна.

Хотя оружие дыхания большинства заводных драконов — огненное, некоторые из них могут быть созданы для нанесения урона кислотой, холодом, или молнией — в зависимости от прихоти их создателей.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_AI,
    page: 210,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_1,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `металлической статуи`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Огненное дыхание',
      restore: 5,
      description: `★СУЩЕСТВО★ выдыхает огонь 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 11, получая урон огнём 14 (4к6) при провале, или половину этого урона при успехе.`,
    },
  ],
}
