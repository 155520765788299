const {
  SPELL_ALTER_SELF,
  SPELL_BANISHMENT,
  SPELL_DETECT_MAGIC,
  SPELL_DIVINE_WORD,
  SPELL_GEAS,
  SPELL_ICE_STORM,
  SPELL_INVISIBILITY,
  SPELL_LOCATE_OBJECT,
  SPELL_SUGGESTION,
  SPELL_SYMBOL,
  SPELL_WALL_OF_ICE,
} = require('./../../../../spellIdList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BAPHOMET,
  CREATURE_GERYON,
  CREATURE_ICE_DEVIL,
  CREATURE_MINOTAUR,
  CREATURE_ZARIEL,
} = require('./../../../../creatureIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_REGENERATION,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_ARCHDEVIL,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {archDevilNoteList} = require('./../../../../textCommonParts')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_22} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Герион',
  nameEn: 'Geryon',
  id: CREATURE_GERYON,
  hasSpoilers: true,
  description: [
    `Герион ведёт нескончаемую борьбу с Левистусом за контроль над Стигией. Оба уже веками сражаются друг с другом, вытесняя друг друга неисчислимое количество раз. В данный момент, Герион находится в выгодном положении в инфернальной иерархии. Хоть Левистус всё ещё правит над Стигией, он заточён в громадной глыбе льда по приказу Асмодея. В свою очередь, Герион мобилизует своих сторонников и строит коварные планы, чтобы подменить своего ненавистного соперника.

Среди архидьяволов, Герион и [Зариэль](CREATURE:${CREATURE_ZARIEL}) особенно прославились благодаря своему боевому мастерству. Он — свирепый охотник и упорный следопыт. Прочие дьяволы владеют легионами и отправляют своих последователей в бой против своих врагов, а Герион любит чувствовать плоть и сталь под своими когтями и вкушает кровь своих побеждённых врагов.

Его свирепость хорошо служит ему в ледяных пустошах Стигии, но также ограничивает его способность собирать души и укреплять эффективную иерархию. Мудрецы, исследующие Девять Преисподней, полагают, что битва за контроль над Стигией — всего лишь испытание, которое устроил Асмодей в надежде избавиться от наихудших побуждений Гериона и Левистуса, или с целью найти более подходящего кандидата вместо этих двоих.`,
    {
      header: 'Логово Гериона',
      text: `Герион недавно восстановил свою древнюю крепость, Холодную Сталь, разрастающийся комплекс, который поднимается изо льда и снега в центре Стигии. Он бродит по коридорам этого места, раскидывая в стороны [ледяных дьяволов](CREATURE:${CREATURE_ICE_DEVIL}) и рабов-[минотавров](CREATURE:${CREATURE_MINOTAUR}), которых он взял у [Бафомета](CREATURE:${CREATURE_BAPHOMET}), находясь в ярости от предательства Асмодея, изрыгая клятвы мести и вынашивая безумные планы того, как он вернёт себе своё положение у Левистуса.

# Действия логова

При значении инициативы «20» (проигрыш при ничье), Герион совершает действие логова, чтобы вызвать один из следующих эффектов; Герион не может использовать один и тот же эффект два раунда подряд:

* Герион создаёт взрыв стужи из-под земли в точке, которую он видит в пределах 120 фт. от себя. Стужа приобретает форму куба в центре этой точки по 10 фт. на каждой стороне. Каждое существо, находящееся в зоне куба, должно преуспеть в испытании Телосложения СЛ 21, иначе получит урон холодом 28 (8к6). 
* Герион нацеливается на одно существо, которое он может видеть в пределах 60 фт. от себя. Цель должна пройти испытание Мудрости СЛ 21, иначе будет обездвижено на 1 минуту. Цель может завершить на себе этот эффект, если она нанесёт урон одному или нескольким своим союзникам. 
* Герион сотворяет заклинание [изгнание](SPELL:${SPELL_BANISHMENT}).

# Местные эффекты

Местность, в которой расположено логово Гериона, искажается его магией, которая создаёт один или несколько следующих эффектов:

* Разумные существа в пределах 1 мили от логова Гериона склонны часто видеть мерцающие порталы, ведущие в места, которые они считают безопасными. Проход через такой портал всегда перемещает путника куда-нибудь в Стигии. 
* Вокруг логова в пределах 1 мили воют сильные морозные ветра. 
* Вой и крики слышны в пределах 1 мили от логова. Любое существо, что заканчивает короткий или длинный отдых в пределах этого расстояния должно пройти испытание Мудрости СЛ 21, иначе не получит никакого преимущества от отдыха.

Если Герион умрёт, эти эффекты исчезнут в течении 1к10 дней.`,
    },
  ],
  note: [
    {
      text: `Кто менее достоин: архидьявол, владеющий слоем, будучи взаперти в глыбе льда, или же тот, кто не способен перехитрить замороженного конкурента?`,
      author: `Архимаг Морденкайнен`,
    },
    ...archDevilNoteList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ARCHDEVIL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 188,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 24,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 50,
  },
  params: {
    [PARAM_STR]: 29,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 22,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 23,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_22,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      id: ABILITY_REGENERATION,
      regen: 20,
      other: `. Если ★он★ получает урон излучением, эта особенность не подействует в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только если начнёт свой ход с 0 хитами и не может регенерировать`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
          SPELL_GEAS,
          SPELL_ICE_STORM,
          SPELL_LOCATE_OBJECT,
          SPELL_SUGGESTION,
          SPELL_WALL_OF_ICE,
          {
            id: SPELL_ALTER_SELF,
            comment: 'может стать Среднего размера',
          },
          {
            id: SPELL_INVISIBILITY,
            comment: 'только на себя',
          },
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DIVINE_WORD,
          {
            id: SPELL_SYMBOL,
            comment: 'только эффект _«Боль»_',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: ` Герион совершает две атаки: одну  _Когтями_ и одну _Жалом_.`,
    },
    {
      name: 'Когти',
      description: `Если цель Большого размера или меньше, то она схвачена (СЛ высвобождения 24) и получает обездвижена, пока не высвободится. Герион держать только одно существо одновременно. Если цель уже схвачена Герионом, то она получает дополнительно 27 (6к8) рубящего урона.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 15,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Жало',
      description: `Цель должна пройти испытание Телосложения СЛ 21, иначе получит 13 (2к12) урона ядом и будет отравлена, пока не закончит короткий или длинный отдых. Максимум хитов цели уменьшается на количество, равное полученному урону. Если этот эффект уменьшает максимальное значение хитов существа до 0, это существо умирает. Этот эффект длится до тех пор, пока цель не перестанет быть отравленной.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 20,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 9,
        },
      },
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Инфернальный взгляд',
      description: `Герион нацеливается на одно существо, которое он видит в пределах 60 фт. от себя. Если цель может видеть Гериона, она должна пройти испытание Мудрости СЛ 23, иначе станет испуганной Герионом до конца его следующего хода.`,
    },
    {
      name: 'Быстрое жало',
      cost: 2,
      description: `Герион совершает атаку _Жалом_.`,
    },
    {
      name: 'Телепортация',
      description: `Герион использует действие _Телепортация_.`,
    },
  ],
}
