const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_THAUMATURGY,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_THAUMATURGY,
  name: 'Чудотворство',
  nameEn: 'Thaumaturgy',
  description: `Вы создаёте небольшое чудо, знак сверхъестественной силы. Вы создаёте один из следующих магических эффектов в пределах дистанции:

* Ваш голос в течение 1 минуты звучит в три раза громче.
* Вы заставляете пламя в течение 1 минуты мерцать, светить ярче или тусклее, или изменять цвет.
* Вы вызываете безвредную дрожь в полу в течение 1 минуты.
* Вы создаёте мгновенный звук, исходящий из выбранной Вами точки в пределах дистанции, такой как раскат грома, крик ворона или зловещий шёпот.
* Вы мгновенно заставляете незапертое окно или дверь распахнуться или захлопнуться.
* Вы на 1 минуту изменяете внешний вид своих глаз.

Если Вы накладываете это заклинание несколько раз, у Вас может быть до трёх активных эффектов с длительностью в 1 минуту, и Вы можете оканчивать такие эффекты действием.`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_MINUTE, count: 1 },
  isDurationUntil: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 289,
    },
    {
      id: SOURCE_SRD,
      page: 187,
    },
  ],
}
