const {gearCollection} = require('./../../../../../gearList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    bonus,
    gearId,
    id,
    rarity,
  }
) => {
  const { name, nameEn } = gearCollection[gearId]

  return {
    id,
    name: `${name} +${bonus}`,
    nameEn: `${nameEn} +${bonus}`,
    type: MGC_TYPE_ARMOR,
    gearType: gearId,
    rarity,
    description: `Вы получаете бонус +${bonus} к КД, пока носите этот доспех.`,
    genderId: GENDER_MALE,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 159,
    },
  }
}
