const {
  MAGIC_ABJURATION,
  MAGIC_CONJURATION,
  MAGIC_DIVINATION,
  MAGIC_ENCHANTMENT,
  MAGIC_EVOCATION,
  MAGIC_ILLUSION,
  MAGIC_NECROMANCY,
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_ACTION,
  TIME_BONUS_ACTION,
  TIME_DAY,
  TIME_HOUR,
  TIME_MINUTE,
  TIME_REACTION,
  TIME_ROUND,
} = require('./../../../../../timePeriodList')
const {
  ATTACK_MELEE_SPELL,
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../../attackTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_INCAPACITATED,
  CONDITION_INVISIBLE,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_STUNNED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../../conditionList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ANIMAL_SHAPES,
  SPELL_ANIMATE_OBJECTS,
  SPELL_ANTILIFE_SHELL,
  SPELL_ANTIMAGIC_FIELD,
  SPELL_ARCANE_EYE,
  SPELL_ARCANE_GATE,
  SPELL_ARCANE_LOCK,
  SPELL_ARMOR_OF_AGATHYS,
  SPELL_ARMS_OF_HADAR,
  SPELL_ASTRAL_PROJECTION,
  SPELL_AUGURY,
  SPELL_BANE,
  SPELL_BANISHING_SMITE,
  SPELL_BANISHMENT,
  SPELL_BARKSKIN,
  SPELL_BEACON_OF_HOPE,
  SPELL_BEAST_SENSE,
  SPELL_BESTOW_CURSE,
  SPELL_BIGBY_S_HAND,
  SPELL_BLADE_BARRIER,
  SPELL_BLADE_WARD,
  SPELL_BLESS,
  SPELL_BLINDING_SMITE,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BLINK,
  SPELL_BLUR,
  SPELL_BRANDING_SMITE,
  SPELL_BURNING_HANDS,
  SPELL_CALL_LIGHTNING,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CIRCLE_OF_POWER,
  SPELL_CLAIRVOYANCE,
  SPELL_CLONE,
  SPELL_CLOUD_OF_DAGGERS,
  SPELL_CLOUDKILL,
  SPELL_COLOR_SPRAY,
  SPELL_COMMAND,
  SPELL_COMMUNE,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_COMPELLED_DUEL,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_COMPULSION,
  SPELL_CONE_OF_COLD,
  SPELL_CONJURE_ANIMALS,
  SPELL_CONJURE_BARRAGE,
  SPELL_CONJURE_CELESTIAL,
  SPELL_CONJURE_FEY,
  SPELL_CONJURE_MINOR_ELEMENTALS,
  SPELL_CONJURE_VOLLEY,
  SPELL_CONJURE_WOODLAND_BEINGS,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_CONTINGENCY,
  SPELL_CONTINUAL_FLAME,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WEATHER,
  SPELL_CORDON_OF_ARROWS,
  SPELL_COUNTERSPELL,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_CREATION,
  SPELL_CROWN_OF_MADNESS,
  SPELL_CRUSADER_S_MANTLE,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_DARKVISION,
  SPELL_DAYLIGHT,
  SPELL_DEATH_WARD,
  SPELL_DELAYED_BLAST_FIREBALL,
  SPELL_DEMIPLANE,
  SPELL_DESTRUCTIVE_WAVE,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISINTEGRATE,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_DISPEL_MAGIC,
  SPELL_DISSONANT_WHISPERS,
  SPELL_DIVINATION,
  SPELL_DIVINE_FAVOR,
  SPELL_DIVINE_WORD,
  SPELL_DOMINATE_BEAST,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_DRAWMIJ_S_INSTANT_SUMMONS,
  SPELL_DREAM,
  SPELL_DRUIDCRAFT,
  SPELL_EARTHQUAKE,
  SPELL_ELDRITCH_BLAST,
  SPELL_ELEMENTAL_WEAPON,
  SPELL_ENSNARING_STRIKE,
  SPELL_ENTANGLE,
  SPELL_ENTHRALL,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_EYEBITE,
  SPELL_FABRICATE,
  SPELL_FAERIE_FIRE,
  SPELL_FALSE_LIFE,
  SPELL_FEATHER_FALL,
  SPELL_FEEBLEMIND,
  SPELL_FEIGN_DEATH,
  SPELL_FIND_THE_PATH,
  SPELL_FIRE_BOLT,
  SPELL_FIRE_SHIELD,
  SPELL_FIRE_STORM,
  SPELL_FIREBALL,
  SPELL_FLAME_BLADE,
  SPELL_FLAME_STRIKE,
  SPELL_FLAMING_SPHERE,
  SPELL_FLESH_TO_STONE,
  SPELL_FLY,
  SPELL_FOG_CLOUD,
  SPELL_FORESIGHT,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_FRIENDS,
  SPELL_GASEOUS_FORM,
  SPELL_GEAS,
  SPELL_GENTLE_REPOSE,
  SPELL_GLIBNESS,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_GLYPH_OF_WARDING,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GREATER_RESTORATION,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_GUIDING_BOLT,
  SPELL_GUST_OF_WIND,
  SPELL_HAIL_OF_THORNS,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_HARM,
  SPELL_HEAL,
  SPELL_HEALING_WORD,
  SPELL_HEAT_METAL,
  SPELL_HEROES_FEAST,
  SPELL_HEROISM,
  SPELL_HEX,
  SPELL_HOLD_PERSON,
  SPELL_HUNGER_OF_HADAR,
  SPELL_HUNTERS_MARK,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_GREASE,
  SPELL_ICE_STORM,
  SPELL_IDENTIFY,
  SPELL_ILLUSORY_SCRIPT,
  SPELL_IMPRISONMENT,
  SPELL_INCENDIARY_CLOUD,
  SPELL_INFLICT_WOUNDS,
  SPELL_INSECT_PLAGUE,
  SPELL_INVISIBILITY,
  SPELL_JUMP,
  SPELL_KNOCK,
  SPELL_LEGEND_LORE,
  SPELL_LEOMUND_S_SECRET_CHEST,
  SPELL_LEOMUND_S_TINY_HUT,
  SPELL_LESSER_RESTORATION,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_LIGHTNING_ARROW,
  SPELL_LIGHTNING_BOLT,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_LONGSTRIDER,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_CIRCLE,
  SPELL_MAGIC_JAR,
  SPELL_MAGIC_MISSILE,
  SPELL_MAGIC_MOUTH,
  SPELL_MAGIC_WEAPON,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MASS_HEAL,
  SPELL_MASS_HEALING_WORD,
  SPELL_MASS_SUGGESTION,
  SPELL_MELD_INTO_STONE,
  SPELL_MENDING,
  SPELL_MESSAGE,
  SPELL_METEOR_SWARM,
  SPELL_MIND_BLANK,
  SPELL_MIRAGE_ARCANE,
  SPELL_MIRROR_IMAGE,
  SPELL_MISTY_STEP,
  SPELL_MODIFY_MEMORY,
  SPELL_MOONBEAM,
  SPELL_MORDENKAINEN_S_FAITHFUL_HOUND,
  SPELL_MORDENKAINEN_S_SWORD,
  SPELL_MOVE_EARTH,
  SPELL_NONDETECTION,
  SPELL_NYSTUL_S_MAGIC_AURA,
  SPELL_OTILUKE_S_FREEZING_SPHERE,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_OTTO_S_IRRESISTIBLE_DANCE,
  SPELL_PASSWALL,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_PHANTOM_STEED,
  SPELL_PLANAR_ALLY,
  SPELL_PLANAR_BINDING,
  SPELL_PLANT_GROWTH,
  SPELL_POLYMORPH,
  SPELL_POWER_WORD_HEAL,
  SPELL_POWER_WORD_KILL,
  SPELL_POWER_WORD_STUN,
  SPELL_PRAYER_OF_HEALING,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PRISMATIC_WALL,
  SPELL_PRODUCE_FLAME,
  SPELL_PROGRAMMED_ILLUSION,
  SPELL_PROJECT_IMAGE,
  SPELL_PROTECTION_FROM_ENERGY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_PURIFY_FOOD_AND_DRINK,
  SPELL_RAISE_DEAD,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_RAY_OF_FROST,
  SPELL_RAY_OF_SICKNESS,
  SPELL_REGENERATE,
  SPELL_REINCARNATE,
  SPELL_REMOVE_CURSE,
  SPELL_RESISTANCE,
  SPELL_RESURRECTION,
  SPELL_REVERSE_GRAVITY,
  SPELL_REVIVIFY,
  SPELL_ROPE_TRICK,
  SPELL_SACRED_FLAME,
  SPELL_SANCTUARY,
  SPELL_SCORCHING_RAY,
  SPELL_SCRYING,
  SPELL_SEARING_SMITE,
  SPELL_SEE_INVISIBILITY,
  SPELL_SEEMING,
  SPELL_SENDING,
  SPELL_SEQUESTER,
  SPELL_SHAPECHANGE,
  SPELL_SHATTER,
  SPELL_SILENCE,
  SPELL_SIMULACRUM,
  SPELL_SLEET_STORM,
  SPELL_SLOW,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_SPIDER_CLIMB,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_STAGGERING_SMITE,
  SPELL_STINKING_CLOUD,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_SUGGESTION,
  SPELL_SUNBEAM,
  SPELL_SUNBURST,
  SPELL_SWIFT_QUIVER,
  SPELL_SYMBOL,
  SPELL_TELEKINESIS,
  SPELL_TELEPATHY,
  SPELL_TELEPORT,
  SPELL_TELEPORTATION_CIRCLE,
  SPELL_TENSER_S_FLOATING_DISK,
  SPELL_THORN_WHIP,
  SPELL_THUNDEROUS_SMITE,
  SPELL_THUNDERWAVE,
  SPELL_TIME_STOP,
  SPELL_TRANSPORT_VIA_PLANTS,
  SPELL_TREE_STRIDE,
  SPELL_TRUE_RESURRECTION,
  SPELL_TRUE_SEEING,
  SPELL_TRUE_STRIKE,
  SPELL_UNSEEN_SERVANT,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_VICIOUS_MOCKERY,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_FORCE,
  SPELL_WALL_OF_ICE,
  SPELL_WALL_OF_STONE,
  SPELL_WALL_OF_THORNS,
  SPELL_WARDING_BOND,
  SPELL_WATER_BREATHING,
  SPELL_WEB,
  SPELL_WIND_WALL,
  SPELL_WISH,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SANCTUARY,
  name: 'Убежище',
  nameEn: 'Sanctuary',
  description: `Вы защищаете одно существо в пределах дистанции от атак. Пока заклинание активно, все существа, нацеливающиеся на защищённое существо атаками или вредоносными заклинаниями, должны вначале пройти испытание Мудрости. При провале существо должно выбрать новую цель или потеряет атаку или заклинание. Это заклинание не защищает от эффектов, действующих по площади, таких как взрыв [Огненного шара](SPELL:${SPELL_FIREBALL}).

Если защищённое существо совершает атаку, сотворяет заклинание, оказывающее действие на противника, или наносит урон другому существу — это заклинание оканчивается.`,
  lvl: 1,
  magicSchoolId: MAGIC_ABJURATION,
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'маленькое серебряное зеркало',
  duration: { timeId: TIME_MINUTE, count: 1 },
  effect: {
    savethrowParam: PARAM_WIT,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 285,
    },
    {
      id: SOURCE_SRD,
      page: 176,
    },
    {
      id: SOURCE_ERRATA_PHB,
      problemText: 'Теперь вредить существам без атак и заклинаний тоже нельзя',
      page: 3,
    },
  ],
}
