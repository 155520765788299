module.exports = [
  require('./list/amphisbaena'),
  require('./list/anvilwrought_raptor'),
  require('./list/archon_of_falling_stars'),
  require('./list/ashen_rider'),
  require('./list/bronze_sable'),
  require('./list/burnished_hart'),
  require('./list/gold_forged_sentinel'),
  require('./list/two_headed_cerberus'),
  require('./list/winged_bull'),
  require('./list/winged_lion'),
]
