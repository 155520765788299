const {
  SPELL_CHARM_PERSON,
  SPELL_COMMAND,
  SPELL_COUNTERSPELL,
  SPELL_CROWN_OF_MADNESS,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_DISSONANT_WHISPERS,
  SPELL_DOMINATE_MONSTER,
  SPELL_DOMINATE_PERSON,
  SPELL_GREATER_INVISIBILITY,
  SPELL_SANCTUARY,
  SPELL_TELEKINESIS,
  SPELL_TELEPORT,
} = require('./../../../../spellIdList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_DEMON_LORD,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_24} = require('./../../../../crList')
const {CREATURE_GRAZZT} = require('./../../../../creatureIdList')
const {GEAR_GREATSWORD} = require('./../../../../gearIdList')
const {demonDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Граз’зт',
  nameAlt: [
    'Тёмный Князь Удовольствий',
  ],
  nameEn: 'Graz’zt',
  id: CREATURE_GRAZZT,
  hasSpoilers: true,
  description: [
    `Внешность Тёмного Князя говорит о том, что не все красивое можно назвать хорошим. Граз’зт, чей рост чуть ли не 9 футов (2,74 м) — это идеальное воплощение необузданного вожделения, и каждый сантиметр его тела, каждый взгляд его горящих глаз обещают наслаждение и боль. В его красоте наблюдаются лёгкие черты «неправильности», от жестоких черт до шестипалых рук и ног. Граз’зт способен по желанию принимать облик любого гуманоида, если она нравится ему или тем, кто на него смотрит, каждая из этих форм по-своему соблазнительна.

Граз’зт предпочитает быть окружённым лучшими благами и привлекательнейшими слугами, и украшает себя одеяниями из шёлка и кожи поразительного и пугающего качества. Его логово и логова его прислужников — это дворцы удовольствий, в которых нет запретов, кроме доброты и умеренности.

Для Тёмного Князя Удовольствий, единственный грех — это ограничения, и он всегда забирает то, что хочет. Культы, посвящённые ему, являются тайными сообществами, члены которых потакают любым своим желаниям, и часто развращают других через шантаж, манипуляцию и играя на зависимостях. Они часто носят алебастровые маски с выражениями экстаза на них, и надевают вызывающие наряды и украшения на свои сходки.

Несмотря на то, что Граз’зт предпочитает очарование и манипуляцию, он способен на ужасающую жестокость, если его спровоцировать. Он сражается двуручным мечом Ангдрельвом, Волной Скорби — волнистым и очень острым клинком, извергающим кислоту по его приказу.`,
    {
      header: 'Логово Граз’зта',
      text: `Главное логово Граз’зта — его Серебряный Дворец — грандиозное строение в городе Зелатаре, расположенном в домене Бездны Аззатаре. Безумное влияние Граз’зта волнами выплёскивается из него, искажая реальность в его окрестностях. Граз’зт способен исказить своим безумием конкретную область, затратив на это некоторое количество времени. Логово Граз’зта представляет собой притон роскоши и гедонизма. Оно наполнено нарядами и украшениями столь декадентскими, что даже богатейшие смертные покраснели бы от вида этого изобилия. В логове Граз’зта его последователи, рабы и подданные вынуждены утолять жажду зрелищ и удовольствий Граз’зта.

# Действия логова

При значении инициативы «20» (проигрыш при ничье), Граз’зт может совершить действие в логове, чтобы создать один из следующих эффектов; он не может использовать один и тот же эффект два раунда подряд:

* Граз’зт сотворяет заклинание [приказ](SPELL:${SPELL_COMMAND}) на каждое существо в логове по его выбору. Ему не обязательно видеть каждого, но ему необходимо знать о том, что конкретное существо находится в его логове, чтобы сделать его целью заклинания. Он даёт один и тот же приказ всем целям. 
* Гладкие поверхности в логове становятся зеркальными. До тех пор, пока не будет использовано другое действие в логове, существа, находящиеся в логове, будут испытывать помеху на проверки Ловкости (Скрытность) при попытках спрятаться.

# Местные эффекты

Область, в которой расположено логово Граз’зта, искажается его магией, и на этой территории появляется один или более из следующих эффектов:

* Каменные или металлические плоские поверхности, расположенные на расстоянии до 1 мили от логова, становятся крайне отражающими, как если бы были отполированы до блеска. Эти поверхности становятся неестественно зеркальными. 
* Дикие звери в пределах 6 миль от логова часто конфликтуют и сцепляются друг с другом, и их поведение соответствует брачному сезону. 
* Если гуманоид проводит по крайней мере 1 час на расстоянии 1 мили от логова, то это существо должно пройти испытание Мудрости СЛ 23 или станет жертвой безумия, определяемого по таблице Безумие Граз’зта. Существо, успешно прошедшее испытание, не может стать жертвой этого регионального эффекта в течение следующих 24 часов.

Если Граз’зт умирает, эти эффекты прекращаются в течение 1к10 дней.`,
      source: {
        id: SOURCE_MTOF,
        page: 143,
      },
    },
    {
      header: 'Безумие Граз’зта',
      text: `Если существо становится безумным в логове Граз’зта или в поле зрения лорда демонов, совершите бросок по таблице Безумие Граз’зта, чтобы определить природу безумия, которое будет слабостью персонажа и будет действовать до тех пор, пока не будет излечено.

| к100 | Слабость (длится пока не будет вылечена)       |
|------|------------------------------------------------|
| 01-20| _«В мире нет ничего важнее меня и моих желаний» |
| 21-40| _«Те, кто не исполняют моих приказаний в точности так, как я сказал, не заслуживают жить»_ |
| 41-60| _«Я — твой путь к спасению. Каждый, кто утверждает иначе, пытается тебя обмануть»_ |
| 61-80| _«Я не успокоюсь, пока не завладею кем-нибудь другим, и эта цель для меня гораздо важнее, чем моя собственная жизнь или чужие жизни»_ |
| 81-90| _«Моё удовольствие — это дело первостепенной важности. Всё остальное, включая общественное приличие, не имеет никакого значения»_ |
| 91-00| _«Всё, что доставляет мне радость, должно быть совершено незамедлительно. Нет смысла откладывать всё приятное»_ |
`,
      source: {
        id: SOURCE_MTOF,
        page: 144,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_DEMON_LORD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 144,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 33,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 21,
    [PARAM_INT]: 23,
    [PARAM_WIT]: 21,
    [PARAM_CHA]: 26,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_24,
  featureList: [
    {
      name: 'Меняющий форму',
      description: `Граз’зт может потратить действие для того, чтобы принять форму существа, похожего на гуманоида Среднего размера, или же для того, чтобы вернуться обратно в свою истинную форму. Кроме размера, он сохраняет все свои характеристики в любой форме. Всё, что он несёт и носит, не трансформируется.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_CHARM_PERSON,
          SPELL_CROWN_OF_MADNESS,
          SPELL_DISSONANT_WHISPERS,
          SPELL_DETECT_MAGIC,
          SPELL_DISPEL_MAGIC,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_COUNTERSPELL,
          SPELL_DARKNESS,
          SPELL_DOMINATE_PERSON,
          SPELL_SANCTUARY,
          SPELL_TELEKINESIS,
          SPELL_TELEPORT,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DOMINATE_MONSTER,
          SPELL_GREATER_INVISIBILITY,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Граз’зт дважды атакует _Волной скорби_.`,
    },
    {
      name: 'Волна скорби',
      gearId: GEAR_GREATSWORD,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 10,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 4,
            diceType: 6,
            diceBonus: 6,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 4,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      id: ABILITY_TELEPORT,
      range: 120,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака',
      description: `Граз’зт один раз атакует Волной Скорби.`,
    },
    {
      name: 'Танцуй, моя куколка!',
      description: `Одно существо, очарованное Граз’зтом, которое он может видеть, должно использовать свою реакцию на то, чтобы переместиться на свою скорость туда, куда укажет Граз’зт.`,
    },
    {
      name: 'Посеять раздор',
      description: `Граз’зт читает заклинание [Корона безумия](SPELL:${SPELL_CROWN_OF_MADNESS}) или [Диссонирующий шепот](SPELL:${SPELL_DISSONANT_WHISPERS}).`,
    },
    {
      name: 'Телепортация',
      description: `Граз’зт использует действие Телепортация.`,
    },
  ],
}
