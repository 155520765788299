const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_NEEDLEFOLK,
    CREATURE_TYPE_PLANT,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    LANG_SYLVAN,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    needleDescription,
    needleNote,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_NEEDLE_SPAWN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Иглюд',
  nameEn: 'Needle Spawn',
  id: CREATURE_NEEDLE_SPAWN,
  description: needleDescription,
  note: needleNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_NEEDLEFOLK,
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 16,
  },
  armor: 13,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 20,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 12,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_SYLVAN,
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Ближняя стрельба',
      description: `Пока ★СУЩЕСТВО★ ★дееспособен★, ★он★ не получает помеху к дальнобойным атакам, пока в пределах 5 футов от ★него★ находится видимое ★им★ враждебное существо, если цель атаки тоже находится в пределах 5 футов от ★него★.`,
    },
  ],
  actionList: [
    {
      name: 'Залп иглами',
      description: `★СУЩЕСТВО★ совершает до 1к6 атак _Иглами_, но не может атаковать одну и ту же цель больше чем два раза за ход.`,
    },
    {
      name: 'Клешня',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Иглы',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 60,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
