const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_1_8,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ELEMENTAL,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    LANG_TERRAN,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_FLY,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_EARTH_ELEMENTAL,
    CREATURE_KHARGRA,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Харгра',
  nameEn: 'Khargra',
  id: CREATURE_KHARGRA,
  description: `Причудливая харгра — летающее существо со Стихийного плана Земли, пожирающее любые металлы, с которыми сталкивается. Хотя обычно это существо питается рудными жилами, очищенные и обработанные металлы для него особенно привлекательны.

**Приставучие мусорщики.** Харгры родом со Стихийного плана Земли, где их считают раздражающими вредителями. Они попадают на Материальный план случайно, обычно следуя за [земляным элементалем](CREATURE:${CREATURE_EARTH_ELEMENTAL}) или другим существом, которого было призвали на Материальный план.

Харгры могут есть и переваривать большинство металлов, но предпочитают железо, медь и олово. Они считают золото мягким и приторным, похожим на кашу без запаха. Хотя харгра легко переваривают металлы, органика и самоцветы для них несъедобны. Если харгра съедает их по какой-либо причине, то они остаются в глотке на нескольких дней, а сама харгра страдает от несварения, пока не выкашляет беспокоящий её материал.

**Хищники поневоле.** Харгры находят живую плоть отвратительной на вкус, и нападают на существ только тогда, когда им движет голод и отсутствие более безопасных вариантов. Откусив кусок вкусного очищенного металла, который несет их жертва, они быстро убегают — и они скорее убегут, чем будут рисковать жизнью.

**Скрытные курьеры.** Некоторые кланы гномов, двергаров и азеров обучали харгр в качестве курьеров. Пользуясь необычными пищеварительными системами этих существ, их хозяева посыпают железной пылью свиток или другой органический предмет с посланием. Харгра пожирает послание, переваривая пыль, но оставляя послание в пищеварительном тракте. Долгие годы обучения и многочисленные угощения позволяют харгре запоминать, направление к различным подземным твердыням и форпостам. Харгра-курьер несет послание до пункта назначения, а затем выкашливает его по команде.`,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 12,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 14,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_TERRAN,
  ],
  cr: CR_1_8,
  featureList: [
    {
      name: 'Чутьё железа',
      description: `★СУЩЕСТВО★ может нюхом чуять расположение железосодержащих металлов в пределах 30 футов.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Если цель носит металлический доспех или несёт металлический щит, ★СУЩЕСТВО★ прикрепляется к этому доспеху или щиту. Пока ★он★ ★прикреплён★ таким образом, ★СУЩЕСТВО★ не может атаковать. Вместо этого, в начале каждого ★его★ хода бросьте к20. При результате 10 и выше доспех или щит получает постоянный накопительный штраф −1 КД. Доспех, чей КД падает до 10 или щит, чей КД падает до +0, уничтожаются.

★СУЩЕСТВО★ может открепиться, потратив 5 футов передвижения. Любое существо (включая цель) может открепить ★её★ действием.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Проницание земли',
      description: `До конца своего следующего хода ★СУЩЕСТВО★ может пролетать сквозь немагическую землю и камень. Пролетая через такой материал, ★он★ его не беспокоит. Она может заканчивать движение внутри земли или камня. Если ★СУЩЕСТВО★ остаётся в них, когда эта эффект этой способности прекращается, она получает 14 (4к6) урона силовым полем и немедленно выталкивается в ближайшее свободное пространство.`,
    },
  ],
  reactionList: [
    {
      name: 'Приспособленческий голод',
      description: `Когда существо, которое ★СУЩЕСТВО★ видит в пределах 5 футов, попадает по ★нему★ металлическим оружием, ★СУЩЕСТВО★ может совершить по этому существу атаку _Укусом_.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
