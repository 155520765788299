const {PC_SUBCLASS_MONK_WAY_OF_THE_SUN_SOUL} = require('./../../../pcSubClassIdList')
const {SPELL_BURNING_HANDS} = require('./../../../spellIdList')
const {
  SOURCE_SCAG,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  FEATURE_MARTIAL_ARTS,
  FEATURE_RADIANT_SUN_BOLT,
  FEATURE_SEARING_ARC_STRIKE,
  FEATURE_SEARING_SUNBURST,
  FEATURE_SUN_SHIELD,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_RADIANT_SUN_BOLT,
    name: `Луч сияющего солнца`,
    nameEn: `Radiant Sun Bolt`,
    lvl: 3,
    text: `Вы можете метнуть палящий заряд магического сияния.

Вы получаете дальнобойную атаку заклинанием, которую можете использовать действием «Атака». Эта атака имеет дальность 30 футов. Вы владеете этой атакой и добавляете свой модификатор Ловкости к броскам атаки и урона. Атака наносит урон излучением, её кость урона равна кости [Боевых искусств](FEATURE:${FEATURE_MARTIAL_ARTS}) в таблице развития монаха.

Когда Вы используете эту специальную атаку в свой ход действием Атака, Вы можете бонусным действием потратить 1 очко ци, чтобы дополнительно совершить ещё две такие атаки.`,
  },
  {
    id: FEATURE_SEARING_ARC_STRIKE,
    name: `Удар пылающей дуги`,
    nameEn: `Searing Arc Strike`,
    lvl: 6,
    text: `Вы получаете способность преобразовывать ци в волны пылающей энергии.

Сразу же после использования в свой ход действия Атака, Вы можете бонусным действием потратить 2 очка ци, чтобы сотворить заклинание 1 круга [Пылающие руки](SPELL:${SPELL_BURNING_HANDS}).

Вы можете потратить очко ци, чтобы сотворить [Пылающие руки](SPELL:${SPELL_BURNING_HANDS}) более высоким кругом заклинания. Каждое дополнительное очко ци поднимает круг заклинания на 1.

Максимальное количество очков ци (2 плюс дополнительные очки), которые могут быть на него потрачены, равно половине Вашего уровня монаха.`,
    spellIdList: SPELL_BURNING_HANDS,
  },
  {
    id: FEATURE_SEARING_SUNBURST,
    name: `Пылающий солнечный взрыв`,
    nameEn: `Searing Sunburst`,
    lvl: 11,
    text: `Вы получаете способность создавать шар света, извергающийся разрушительным взрывом.

Вы действием создаёте шар и метаете его в выбранную точку в пределах 150 футов, где он за короткое, но смертоносное мгновение взрывается сферой сияющего света.

Каждое существо, попавшее в 20-футовый радиус сферы, должно пройти испытание Телосложения или получить 2к6 урона излучением. Существу не нужно проходить испытание, если оно находится за непрозрачным полным укрытием.

Вы можете увеличить урон сферы, потратив очки ци. Каждое очко, до максимума в 3 очка, увеличивает урон на 2к6`,
  },
  {
    id: FEATURE_SUN_SHIELD,
    name: `Солнечный щит`,
    nameEn: `Sun Shield`,
    lvl: 17,
    text: `Вы можете окружить себя сияющей аурой.

Вы источаете яркий свет в радиусе 30 футов и тусклый свет ещё в 30 футах. Вы можете погасить или зажечь свет бонусным действием.

Если, пока этот свет сияет, существо попадает по Вам рукопашной атакой, Вы можете реакцией нанести ему урон излучением. Урон равен 5 + Ваш модификатор Мудрости.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MONK_WAY_OF_THE_SUN_SOUL,
    source: [
      {
        id: SOURCE_SCAG,
        page: 132,
      },
      {
        id: SOURCE_XGTE,
        page: 45,
      },
    ],
  })
)
