const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_MODRONE,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_MODRONE,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Модронский',
    genitive: 'Модронского',
    instrumental: 'Модронским',
    prepositional: 'Модронском',
  },
  nameEn: 'Modrone',
  isReady: false,
  isRealLang: true,
}
