const {
    SOURCE_MOOT,
  } = require('./../../../../sourceList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_SWIM,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_AMPHISBAENA,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Амфисбена',
  nameEn: 'Amphisbaena',
  id: CREATURE_AMPHISBAENA,
  description: `На обоих концах змеиного тела амфисбены есть по голове, каждая ихз которых вооружена ядовитыми клыками. Чтобы передвигаться, амфисбена хватает одной головой шею другой, образуя кольцо и катится так по земле.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MOOT,
    page: 209,
  },
  armor: 14,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 12,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 10,
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Две головы',
      description: `★СУЩЕСТВО★ совершает с преимуществом испытания от ослепления, очарования, глухоты, испуга, ошеломления, лишения сознания и опрокидывания.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Укусом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 4,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 1,
            diceType: 6,
            diceBonus: 1,
          },
        ],
      },
    },
  ],
}
