const {
  CREATURE_DROW_ELITE_WARRIOR,
  CREATURE_DROW_MAGE,
} = require('./../../creatureIdList')
const {GEAR_DROW_POISON} = require('./../../gearIdList')
const {SOURCE_MM} = require('./../../sourceList')

module.exports = {
  header: 'Склонность к ядам',
  text: `Дистиллированный яд пауков и растительности Подземья часто встречается среди дроу и играет большую роль в их культуре и политике. [Маги дроу](CREATURE:${CREATURE_DROW_MAGE}) придумали [вязкий токсин, лишающий врагов сознания](GEAR:${GEAR_DROW_POISON}). [Воины дроу](CREATURE:${CREATURE_DROW_ELITE_WARRIOR}) покрывают свои клинки и арбалетные болты этим ядом, рассчитывая после боя на допрос или пытки.`,
  source: {
    id: SOURCE_MM,
    page: 305,
  },
}
