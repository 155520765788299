const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {LANG_SYLVAN} = require('./../../languageIdList')
const {ALPHABET_ESPRUAR} = require('./../../alphabetList')
const {
  SOURCE_MGZN_DRGN_ANNUAL_1999,
  SOURCE_DND3_5_PHB,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  CREATURE_DRYAD,
  CREATURE_PIXIE,
} = require('./../../creatureIdList')

module.exports = {
  id: LANG_SYLVAN,
  alphabetId: ALPHABET_ESPRUAR,
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Сильван',
    genitive: 'Сильвана',
    instrumental: 'Сильваном',
    prepositional: 'Сильване',
  },
  nameAlt: 'Фейский',
  nameEn: 'Sylvan',
  nameEnAlt: 'Faerie',
  dictionary: [
    {
      list: [
        ['тёмный ворон', 'даб катха'],
        ['дарклинги', 'даб ситх'],
      ],
      source: {
        id: SOURCE_VGTM,
        page: 147,
      },
    },
  ],
  description: [
    {
      text: `Сильван, язык [дриад](CREATURE:${CREATURE_DRYAD}) и [пикси](CREATURE:${CREATURE_PIXIE}).`,
      source: {
        id: SOURCE_DND3_5_PHB,
        page: 18,
      },
    },
    {
      text: `Сильван — язык лесных существ.`,
      source: {
        id: SOURCE_DND3_5_PHB,
        page: 35,
      },
    },
    {
      text: `Язык всех добрых и нейтральных фей, держится в секрете от большинства не-фей`,
      source: {
        id: SOURCE_MGZN_DRGN_ANNUAL_1999,
        page: 31,
      },
    },
  ],
  typicalSpeakers: 'Фейские существа',
  isRealLang: true,
  isReady: true,
}
