const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CONDITION_RESTRAINED} = require('./../../../../../conditionList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_BONES_OF_THE_EARTH} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_BONES_OF_THE_EARTH,
  name: 'Кости земли',
  nameEn: 'Bones of the earth',
  description: `Вы вызываете до шести каменных столбов, вырывающихся из земли в тех местах, которые Вы можете видеть в пределах дистанции. Каждый столб представляет собой цилиндр диаметром 5 футов и высотой до 30 футов. Земля, на которой появляется столб, должна быть достаточно широкой для этого диаметра, и Вы можете сделать целью землю под существом, если это существо Среднего размера или меньше.

У каждого столба КД 5 и 30 хитов. При уменьшении хитов до 0 столб крошится на камни, что создает труднопроходимую местность в радиусе 10 футов. Местность остается труднопроходимой, пока камни не будут убраны. Для очистки вручную области диаметром 5 футов требуется не менее 1 минуты.

Если столб создан под существом, то это существо должно пройти испытание ловкости, или будет поднято столбом. Существо может добровольно провалить испытание.

Если столбу не удается достичь полной высоты из-за потолка или другого препятствия, существо на столбе получает 6к6 дробящего урона и обездвиживается, будучи зажатым между столбом и препятствием. Удерживаемое существо может использовать действие, чтобы сделать проверку силы или ловкости (на свой выбор) против СЛ испытания от Ваших заклинаний. При успехе существо перестаёт быть обездвиженым и должно либо слезть со столба, либо упасть с него.`,
  highLvlCast: `Когда Вы сотворяете это заклинание с помощью ячейки 7 круга или выше, Вы можете создать два дополнительных столба за каждый круг ячейки выше 6.`,
  lvl: 6,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_DEX,
    condition: CONDITION_RESTRAINED,
    damage: {
      type: DAMAGE_BLUDGEONING,
      diceCount: 6,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 155,
    },
    {
      id: SOURCE_EE,
      page: 18,
    },
  ],
}
