const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  CREATURE_BEHOLDERKIN_SPIRIT,
  CREATURE_SLAADI_SPIRIT,
  CREATURE_STAR_SPAWN_SPIRIT,
} = require('./../../../../../creatureIdList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_SUMMON_ABERRATION,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SUMMON_ABERRATION,
  name: 'Призыв духа аберрации',
  nameEn: 'Summon Aberration',
  description: `Вы призываете духа аберрации. Дух проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания. 

Когда Вы сотворяете это заклинание, выберите [Злобоглазов](CREATURE:${CREATURE_BEHOLDERKIN_SPIRIT}), [Слаадов](CREATURE:${CREATURE_SLAADI_SPIRIT}) или [Звёздных порождений](CREATURE:${CREATURE_STAR_SPAWN_SPIRIT}). Существо выглядит как аберрация выбранного вида, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.
    
Существо дружественно к Вам и Вашим спутникам. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно совершает действие Уклонение и передвигается так, чтобы избегать опасности.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
  createdCreatureId: [
    CREATURE_BEHOLDERKIN_SPIRIT,
    CREATURE_SLAADI_SPIRIT,
    CREATURE_STAR_SPAWN_SPIRIT,
  ],
  lvl: 4,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `маринованые щупальце и глазное яблоко в инкрустированной платиной бутылочке стоимостью не менее 400 золотых`,
  hasCost: true,
  duration: { timeId: TIME_HOUR, count:  1},
  needConcentration: true,
  source: {
    id: SOURCE_TCoE,
    page: 156,
  },
}
