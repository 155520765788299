const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_TYPE_POTION} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    id,
    name,
    nameEn,
    rarity,
    hits,
  }
) => ({
  id,
  name,
  nameEn,
  rarity,
  type: MGC_TYPE_POTION,
  isConsumable: true,
  description: `Зелья представляет собой красную жидкость, сверкающую при встряхивании.

Вы восстанавливаете **${hits}** хитов, когда выпиваете это зелье.`,
  genderId: GENDER_MIDDLE,
  siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 165,
  },
})
