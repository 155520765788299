import React from 'react'
import classNames from 'classnames'

import AdsPlacer from '@/components/AdsPlacer'

import './AdsAtCatalogStyles.less'

// Баннер РСЯ в каталогах
const AdsAtCatalog = ({className = ''}) => (
  <AdsPlacer
    blockId='R-A-2421058-9'
    className={classNames('AdsAtCatalog', className)}
    selfReload
  />
)

export default AdsAtCatalog