const {SOURCE_MM} = require('./../../sourceList')
const {GOD_MERRSHAULK} = require('./../../godIdList')

module.exports = [
  {
    header: 'Мершолк, Владыка Ямы',
    text: `[Мершолк](GOD:${GOD_MERRSHAULK}) это уже долгое время дремлющее верховное божество пантеона юань-ти. Когда почитание [Мершолка](GOD:${GOD_MERRSHAULK}) ослабло, он ушёл в спячку. Жрецы [Мершолка](GOD:${GOD_MERRSHAULK}), отродья юань-ти, поддерживают традиции живых жертвоприношений и причиняют страдания во имя бога. Они верят, что при совершении достаточного количества гнусных деяний [Мершолк](GOD:${GOD_MERRSHAULK}) пробудится и вернёт юань-ти на их законное место.`,
    source: {
      id: SOURCE_MM,
      page: 315,
    },
  },
]
