const {ATTACK_MELEE_SPELL} = require('./../../../../../attackTypeList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SPELL_SPIRITUAL_WEAPON} = require('./../../../../../spellIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_SPIRITUAL_WEAPON,
  name: 'Божественное оружие',
  nameEn: 'Spiritual Weapon',
  description: `Вы создаёте в пределах дистанции парящее призрачное оружие, существующее, пока заклинание активно, или пока Вы не накладываете это заклинание ещё раз. Когда Вы накладываете это заклинание, Вы можете совершить рукопашную атаку заклинанием по существу, находящемуся в пределах 5 футов от оружия. При попадании цель получает урон силовым полем, равный 1к8 + модификатор Вашей базовой характеристики.

Вы можете бонусным действием в свой ход переместить оружие на 20 футов и повторить атаку по существу, находящемуся в пределах 5 футов от него.

Оружие может быть любой формы. Жрецы божеств, связанных с конкретным оружием (святой Катберт известен своей булавой, а Тор — молотом), создают эффект в виде именно такого оружия.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, урон увеличивается на 1к8 за каждые две ячейки выше второго.`,
  lvl: 2,
  magicSchoolId: MAGIC_EVOCATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 1 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  effect: {
    attackType: ATTACK_MELEE_SPELL,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 1,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 213,
    },
    {
      id: SOURCE_SRD,
      page: 182,
    },
  ],
}
