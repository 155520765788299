const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_FIEND,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_LE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_INFERNAL,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_FIRE,
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    ABILITY_KEEN_HEARING_AND_SMELL,
    ABILITY_PACK_TACTICS,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_FIRE_GIANT,
    CREATURE_HELL_HOUND,
    CREATURE_PIT_FIEND,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Адская гончая',
  nameEn: 'Hell Hound',
  id: CREATURE_HELL_HOUND,
  description: `Чудовищные огнедышащие исчадия, принявшие облик сильных псов, адские гончие встречаются на полях Ахерона и на всех Нижних Планах. На Материальном плане адские гончие чаще всего встречаются в услужении у [дьяволов](CREATURE:${CREATURE_PIT_FIEND}), [огненных великанов](CREATURE:${CREATURE_FIRE_GIANT}) и других злых существ, которые используют их в качестве сторожевых животных и спутников.

# Жгучий голод

Адские гончие охотятся стаями, поедая любых существ, которые кажутся съедобными. Они избегают потенциально опасных противников, атакуя самую слабую добычу беспощадными укусами и своим огненным дыханием, и демонстрируют упорную решимость в преследовании добычи до её горького конца.

Когда адские гончие кормятся, плоть, которую они пожирают, обжаривается в адском пламени внутри них. Когда адская гончая умирает, этот огонь уничтожает её останки в клубах дыма и полыхающих углей, не оставляя ничего, кроме клочков обгоревшего чёрного меха.

# Злые до мозга костей

Адские гончие умнее, чем обычные звери, а их принципиальная сущность заставляет их хорошо выполнять команды. Однако злобный нрав адской гончей не позволяет псу обучаться командам ни у кого, кроме как у безжалостного убийцы. Если адской гончей не позволять баловать свой злобный голод, она быстро покидает хозяина или оборачивается против него.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
  ],
  alignmentId: ALIGNMENT_LE,
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 14,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_INFERNAL,
      doNotSpeak: true,
    },
  ],
  immunityList: [
    DAMAGE_FIRE,
  ],
  cr: CR_3,
  source: {
    id: SOURCE_MM,
    page: 15,
  },
  featureList: [
    ABILITY_KEEN_HEARING_AND_SMELL,
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 3,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Огненное дыхание',
      restore: 5,
      description: `★СУЩЕСТВО★ выдыхает огонь 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 12, получая урон огнём 21 (6к6) при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
