const {GEAR_BOOK} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_MANUAL_OF_GAINFUL_EXERCISE} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_MANUAL_OF_GAINFUL_EXERCISE,
  name: `Справочник полезных упражнений`,
  nameEn: `Manual of Gainful Exercise`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_BOOK,
  rarity: MGC_RARITY_VERY_RARE,
  isConsumable: true,
  description: `Эта книга описывает упражнения по физкультуре, и её слова наполнены магией.

Если Вы потратите 48 часов за 6 дней на изучение содержимого книги и применение его на практике, Ваша Сила, а также её максимум увеличатся на 2. После этого руководство теряет магию, но восстанавливает её через 100 лет.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 203,
  },
}
