module.exports = [
  require('./list/assassin_bug'),
  require('./list/blindheim'),
  require('./list/crab_folk'),
  require('./list/dire_corby'),
  require('./list/eye_of_fear_and_flame'),
  require('./list/fog_giant'),
  require('./list/forlarren'),
  require('./list/jermlaine'),
  require('./list/khargra'),
  require('./list/killmoulis'),
  require('./list/mite'),
  require('./list/needle_lord'),
  require('./list/needle_spawn'),
  require('./list/norker'),
  require('./list/norker_war_leader'),
  require('./list/screaming_devilkin'),
  require('./list/xill'),
  require('./list/ygorl_lord_of_entropy'),
]
