const {GEAR_ROD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROD_OF_THE_PACT_KEEPER} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_ROD} = require('./../../../../../magicItemTypeList')
const {PC_CLASS_WARLOCK} = require('./../../../../../pcClassIdList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_ROD_OF_THE_PACT_KEEPER,
  name: `Жезл хранителя договора`,
  nameEn: `Rod of the Pact Keeper`,
  type: MGC_TYPE_ROD,
  gearType: GEAR_ROD,
  rarity: generateRarityList(list),
  attunement: true,
  attunementComment: `колдуном`,
  description: `Держа этот жезл, Вы получаете бонус к броскам атаки и СЛ испытаний от Ваших заклинаний [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}). Размер бонуса определяется редкостью жезла.

Кроме того, Вы можете действием восстановить одну ячейку заклинания [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}), если держите этот жезл. Вы не можете использовать это свойство повторно, пока не окончите длинный отдых.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 162,
  },
}
