const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_GIANT_APE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Гигантская обезьяна',
  nameAlt: 'Гигантская человекообразная обезьяна',
  nameEn: 'Giant Ape',
  id: CREATURE_GIANT_APE,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 324,
  },
  armor: 12,
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  cr: CR_7,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Кулаком_.`,
    },
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 9,
        range: {
          normal: 50,
          max: 100,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 7,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
