const {
  SPELL_CHAIN_LIGHTNING,
  SPELL_CHARM_PERSON,
  SPELL_CHROMATIC_ORB,
  SPELL_CONE_OF_COLD,
  SPELL_DARKNESS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISPEL_MAGIC,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FEAR,
  SPELL_FRIENDS,
  SPELL_GREATER_INVISIBILITY,
  SPELL_ICE_STORM,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MESSAGE,
  SPELL_MINOR_ILLUSION,
  SPELL_MIRROR_IMAGE,
  SPELL_MISTY_STEP,
  SPELL_SHOCKING_GRASP,
  SPELL_TELEPORT,
  SPELL_THUNDERWAVE,
  SPELL_WALL_OF_FORCE,
} = require('./../../../../spellIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  abishaiDescriptionList,
  abishaiNote,
  devilDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_ABISHAI,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DRACONIC,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_17} = require('./../../../../crList')
const {CREATURE_ABISHAI_BLUE} = require('./../../../../creatureIdList')
const {GEAR_QUARTERSTAFF} = require('./../../../../gearIdList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_ARCANA} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Синий абишай',
  nameEn: 'Blue Abishai',
  id: CREATURE_ABISHAI_BLUE,
  description: [
    `Искатели забытых знаний и потерянных реликвий, синие абишаи — самые хитрые и образованные из своего рода. Их исследования оккультных предметов, почерпнутых из томов и гримуаров, украденных со всей мультивселенной, позволяют им стать искусными заклинателями. Они используют магию, чтобы сокрушать врагов своей госпожи.`,
    ...abishaiDescriptionList,
    ...devilDescriptionList,
  ],
  note: abishaiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ABISHAI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 177,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 26,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 50,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 17,
    [PARAM_INT]: 22,
    [PARAM_WIT]: 23,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_17,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    spellCasterLevel: 13,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_CHAIN_LIGHTNING,
      SPELL_CHARM_PERSON,
      SPELL_CHROMATIC_ORB,
      SPELL_CONE_OF_COLD,
      SPELL_DARKNESS,
      SPELL_DIMENSION_DOOR,
      SPELL_DISGUISE_SELF,
      SPELL_DISPEL_MAGIC,
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_FEAR,
      SPELL_FRIENDS,
      SPELL_GREATER_INVISIBILITY,
      SPELL_ICE_STORM,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGE_HAND,
      SPELL_MAGIC_MISSILE,
      SPELL_MESSAGE,
      SPELL_MINOR_ILLUSION,
      SPELL_MIRROR_IMAGE,
      SPELL_MISTY_STEP,
      SPELL_SHOCKING_GRASP,
      SPELL_TELEPORT,
      SPELL_THUNDERWAVE,
      SPELL_WALL_OF_FORCE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
      1,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Абишай совершает две атаки: одну _Боевым посохом_ и одну _Укусом_.`,
    },
    {
      gearId: GEAR_QUARTERSTAFF,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 10,
            diceBonus: 2,
          },
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 4,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
}
