const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {SPELL_PRIMORDIAL_WARD} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_PRIMORDIAL_WARD,
  name: 'Первородный страж',
  nameEn: 'Primordial Ward',
  description: `Вы получаете сопротивление к урону звуком, кислотой, огнём, холодом и электричеством на время действия заклинания.

Когда Вы получаете урон одного из этих видов, Вы можете реакцией получить иммунитет к этому виду урона, включая тот урон, который спровоцировал эту реакцию. В этом случае Вы теряете сопротивление к остальным видам урона, а иммунитет пропадает в конце Вашего следующего хода, после чего заклинание заканчивается.`,
  lvl: 6,
  magicSchoolId: MAGIC_ABJURATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  source: [
    {
      id: SOURCE_XGTE,
      page: 160,
    },
    {
      id: SOURCE_EE,
      page: 19,
    },
  ],
}
