const arrify = require('arrify')

const Description = require('./Description')

class DescriptionList extends Array {
  constructor(item) {
    super(
      ...arrify(item.description).map(
        description => new Description(description, item.name, item.nameEn, item.source)
      )
    )
  }
}

module.exports = DescriptionList
