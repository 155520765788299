const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CONDITION_RESTRAINED} = require('./../../../../../conditionList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SPELL_MAXIMILIAN_S_EARTHEN_GRASP} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MAXIMILIAN_S_EARTHEN_GRASP,
  name: 'Земляная хватка Максимилиана',
  nameEn: 'Maximilian’s Earthen Grasp',
  description: `Вы выбираете свободное пространство в пределах 5-футового квадрата, видимое в пределах дистанции. Область должна находиться на поверхности земли. В указанном месте вырастает рука Среднего размера, созданная из спрессованной земли. Рука настигает одно существо, которое Вы можете видеть, и которое находится в пределах 5 футов от руки. Цель должна пройти испытание Силы. При провале цель получает дробящий урон 2к6 и Обездвиживается на время действия заклинания.

Вы можете действием приказать руке сдавить Обездвиженную цель. В этом случае цель должна пройти испытание Силы. При провале она получает дробящий урон 2к6, а при успехе — половину урона.

Чтобы освободиться, цель должна действием совершить проверку Силы. При успехе цель освобождается и более не Обездвиживается рукой.

Вы можете действием приказать руке настигнуть другое существо или переместиться в любое свободное пространство в пределах дистанции. В этих случаях рука отпускает предыдущую цель.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `миниатюрная рука из глины`,
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_STR,
    condition: CONDITION_RESTRAINED,
    damage: {
      type: DAMAGE_BLUDGEONING,
      diceCount: 2,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 152,
    },
    {
      id: SOURCE_EE,
      page: 17,
    },
  ],
}
