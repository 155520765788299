const {CREATURE_TYPE_GIANT_FROST} = require('./../../creatureTypeIdList')
const {frostGiantDescriptionList} = require('./../../textCommonParts')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_GIANT_FROST,
  nameEn: 'Frost Giant',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'ледяный великан',
        genitive: 'ледяного великана',
        dative: 'ледяному великану',
        accusative: 'ледяного великана',
        instrumental: 'ледяным великаном',
        prepositional: 'ледяном великане',
      },
      [GENDER_FEMALE]: {
        nominative: 'ледяная великанша',
        genitive: 'ледяной великанши',
        dative: 'ледяной великанше',
        accusative: 'ледяную великаншу',
        instrumental: 'ледяной великаншей',
        prepositional: 'ледяной великанше',
      },
    },
    plural: {
      nominative: 'ледяные великаны',
      genitive: 'ледяных великанов',
      dative: 'ледяным великанам',
      accusative: 'ледяных великанов',
      instrumental: 'ледяными великанами',
      prepositional: 'ледяных великанах',
    },
  },
  description: frostGiantDescriptionList,
}
