const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_SKELETON} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_SKELETON,
  nameEn: 'Skeleton',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'скелет',
      genitive: 'скелета',
      dative: 'скелету',
      accusative: 'скелета',
      instrumental: 'скелетом',
      prepositional: 'скелете',
    },
    plural: {
      nominative: 'скелеты',
      genitive: 'скелетов',
      dative: 'скелетам',
      accusative: 'скелетов',
      instrumental: 'скелетами',
      prepositional: 'скелетах',
    },
  },
}
