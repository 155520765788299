const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {ATTACK_RANGE_WEAPON} = require('./../../../../../attackTypeList')
const {DAMAGE_FIRE} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_FLAME_ARROWS} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_FLAME_ARROWS,
  name: 'Пылающие стрелы',
  nameEn: 'Flame Arrows',
  description: `Вы касаетесь колчана, в котором находятся стрелы или болты. Когда дальнобойная атака оружием с использованием боеприпаса, вынутого из этого колчана, попадает по цели, цель получает дополнительный урон огнём 1к6.

Действие магии на боеприпасе заканчивается после попадания или промаха. Заклинание заканчивается, когда из колчана было извлечено 12 боеприпасов.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 4 круга или выше, количество боеприпасов, на которые действует заклинание, увеличивается на 2 за каждый круг ячейки выше 3.`,
  lvl: 3,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 1},
  needConcentration: true,
  effect: {
    attackType: ATTACK_RANGE_WEAPON,
    damage: {
      type: DAMAGE_FIRE,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 164,
    },
    {
      id: SOURCE_EE,
      page: 21,
    },
  ],
}
