const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  CREATURE_DEMON_SPIRIT,
  CREATURE_DEVIL_SPIRIT,
  CREATURE_YUGOLOTH_SPIRIT,
} = require('./../../../../../creatureIdList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_SUMMON_FIEND,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SUMMON_FIEND,
  name: 'Призыв духа исчадия',
  nameEn: 'Summon Fiend',
  description: `Вы призываете духа исчадия. Дух проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите [Демона](CREATURE:${CREATURE_DEMON_SPIRIT}), [Дьявола](CREATURE:${CREATURE_DEVIL_SPIRIT}) или [Юголота](CREATURE:${CREATURE_YUGOLOTH_SPIRIT}). Существо выглядит как исчадие выбранного вида, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.
    
Существо дружественно к Вам и Вашим спутникам. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно совершает действие Уклонение и передвигается так, чтобы избегать опасности.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
  createdCreatureId: [
    CREATURE_DEMON_SPIRIT,
    CREATURE_DEVIL_SPIRIT,
    CREATURE_YUGOLOTH_SPIRIT,
  ],
  lvl: 6,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кровь гуманоида в рубиновом флаконе стоимостью не менее 600 золотых`,
  hasCost: true,
  duration: { timeId: TIME_HOUR, count: 1},
  needConcentration: true,
  source: {
    id: SOURCE_TCoE,
    page: 162,
  },
}
