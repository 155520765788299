const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  fireGiantDescriptionList,
  fireGiantNote,
} = require('./../../../../textCommonParts')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_GIANT_FIRE,
  CREATURE_TYPE_GIANT,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_GREATSWORD,
  GEAR_PLATE_ARMOR,
} = require('./../../../../gearIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_9} = require('./../../../../crList')
const {CREATURE_FIRE_GIANT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Огненный великан',
  nameAlt: [
    'Огненный гигант',
    'Ильдъётун',
  ],
  nameEn: 'Fire Giant',
  id: CREATURE_FIRE_GIANT,
  description: fireGiantDescriptionList,
  note: fireGiantNote,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_FIRE,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 39,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 25,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 23,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_9,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Двуручным мечом_`,
    },
    {
      name: 'Двуручный меч',
      // gearId: GEAR_GREATSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 6,
          diceType: 6,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 11,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 7,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
