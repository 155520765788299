const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_MEAZEL,
} = require('./../../../../creatureTypeIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MTOF,
} = require('./../../../../sourceList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {ABILITY_SHADOW_STEALTH} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_MEAZEL} = require('./../../../../creatureIdList')
const {GEAR_SHORTSWORD} = require('./../../../../gearIdList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Мизель',
  nameEn: 'Meazel',
  id: CREATURE_MEAZEL,
  description: [
    `В местах, в которых Царство Теней омывает берега Материального плана, обитают мизели, ненавистные отшельники, оставившие позади свою прежнюю жизнь, чтобы созерцать свои невзгоды в тени. А теперь зло обжигает их сердца, и они негодуют, если потревожить их страдание.`,
    {
      header: 'Ненавистные отшельники',
      text: `Мизели — остатки тех, кто бежал в Царство Теней, чтобы выбраться из своего смертного существования. Там, тьма изменила их, а злоба сделала их извращёнными и жестокими. Отныне, они обитают возле пересечения Царства Теней и подстерегают путников, что забрели близ их логова.`,
      source: {
        id: SOURCE_MTOF,
        page: 210,
      },
    },
    {
      header: 'Мизель',
      text: `Жестокие, извращённые отшельники, несущие на себе печать Царства Теней. Пропитанные магией, они рыщут в поисках жертв.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: 'Разделяй и властвуй',
      text: `Благодаря тьме, на свет появились мизели, а вместе с ними и их магические способности, позволяющие им без труда перемещаться в тени. Едва шагнув в тень, мизель может переместиться в другую. Они используют данную способность, чтобы подкрадываться к ничего не подозревающим существам, ломать им шеи или душить их, а затем скрыться в тени. Помимо этого, мизели применяют эту способность, чтобы перенаправить своих жертв в отдалённые тёмные места и оставляют там этих несчастных на произвол судьбы.

Существа, которых мизели утащили во тьму, становятся жертвой проклятия. Проклятие действует в виде маячка; угнетённые, нежить, или прочая нечисть могут учуять жертву и её местоположение, и спуститься в пучину, чтобы разорвать её в клочья.`,
      source: {
        id: SOURCE_MTOF,
        page: 210,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_MEAZEL,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MTOF,
    page: 210,
  },
  armor: 13,
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 9,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_SHADOW_STEALTH,
  ],
  actionList: [
    {
      name: 'Гаррота',
      description: `Цель становится Схваченной (СЛ высвобождения 13 с помехой). До окончания захвата цель получает 10 (2к6 + 3) дробящего урона в начале каждого хода мизеля. Мизель не может использовать оружейные атаки, пока он кого-то схватил.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            size: {
              max: SIZE_MEDIUM,
            },
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_SHORTSWORD,
      attack: {
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 1,
          diceType: 6,
        },
      },
    },
    {
      name: 'Теневая телепортация',
      restore: 5,
      description: `Мизель вместе со всеми предметами, которые он несёт или носит, и любым существом, которое он схватил, телепортируются на незанятое пространство в пределах 500 фт.; начальная и конечная точка должны находиться в тусклом свете или во тьме. Конечной точкой должно быть место, которое мизель уже видел раньше, но оно не обязательно должно находиться на прямой линии видимости. Если конечная точка телепортации занята, то телепортация проходит в ближайшее к ней незанятое пространство.

Каждое существо, отличное от мизеля, которое он телепортировал таким образом, проклинается тенью на 1 час. До окончания проклятия любая нежить и любой обитатель Царства Теней могут ощущать проклятое существо в пределах 300 фт. от себя, как бы существо не пыталось скрыться.`,
    },
  ],
}
