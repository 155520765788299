const spellIdListByClassAndLvl = require('./../../../spellIdListByClassAndLvl')
const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_CLASS_WIZARD} = require('./../../../pcClassIdList')
const {PC_SUBCLASS_CLERIC_ARCANA_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_SCAG} = require('./../../../sourceList')
const {
  SPELL_ARCANE_EYE,
  SPELL_BANISHMENT,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_LEOMUND_S_SECRET_CHEST,
  SPELL_MAGIC_CIRCLE,
  SPELL_MAGIC_MISSILE,
  SPELL_MAGIC_WEAPON,
  SPELL_NYSTUL_S_MAGIC_AURA,
  SPELL_PLANAR_BINDING,
  SPELL_TELEPORTATION_CIRCLE,
} = require('./../../../spellIdList')

const {
  FEATURE_ARCANA_DOMAIN_SPELLS,
  FEATURE_ARCANE_INITIATE,
  FEATURE_ARCANE_MASTERY,
  FEATURE_CHANNEL_DIVINITY_ARCANE_ABJURATION,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_POTENT_SPELLCASTING_ARCANA_DOMAIN,
  FEATURE_SPELL_BREAKER,
} = require('./../../../featureIdList')

const wizardSpellIdListByLvl = spellIdListByClassAndLvl[PC_CLASS_WIZARD]

module.exports = [
  {
    id: FEATURE_ARCANA_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_ARCANE_EYE,
      SPELL_DETECT_MAGIC,
      SPELL_DISPEL_MAGIC,
      SPELL_LEOMUND_S_SECRET_CHEST,
      SPELL_MAGIC_CIRCLE,
      SPELL_MAGIC_MISSILE,
      SPELL_MAGIC_WEAPON,
      SPELL_NYSTUL_S_MAGIC_AURA,
      SPELL_PLANAR_BINDING,
      SPELL_TELEPORTATION_CIRCLE,
    ],
    source: {
      id: SOURCE_SCAG,
      page: 126,
    },
  },
  {
    id: FEATURE_ARCANE_INITIATE,
    name: `Начинающий маг`,
    nameEn: `Arcane Initiate`,
    lvl: 1,
    text: `Вы получаете владение навыком Магия и два заговора на Ваш выбор из списка заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}).

Для Вас эти заговоры считаются заговорами жреца.`,
    spellIdList: spellIdListByClassAndLvl[PC_CLASS_WIZARD][0],
    source: {
      id: SOURCE_SCAG,
      page: 126,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_ARCANE_ABJURATION,
    name: `Божественный канал: Ограждение магией`,
    nameEn: `Channel Divinity: Arcane Abjuration`,
    lvl: [2, 5, 8, 11, 14, 17],
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы оградиться от существ из иных миров.

Вы действием демонстрируете свой священный символ и один выбранный Вами небожитель, элементаль, фея или исчадие в 30 футах от Вас должен пройти испытание Мудрости, при условии, что существо Вас видит и слышит. Если существо проваливает испытание, оно изгоняется на 1 минуту или пока не получит любой урон.

Изгнанное существо должно тратить свои ходы, пытаясь уйти от Вас как можно дальше, и не может добровольно переместиться в пространство, находящееся в пределах 30 футов от Вас. Оно также не может совершать реакции. Действием существо может использовать только Рывок или пытаться освободиться от эффекта, препятствующее его передвижению. Если двигаться некуда, существо может использовать действие Уклонение.

После 5 уровня, провалив испытание против Вашей способности _Ограждения Магией_, существо изгоняется на 1 минуту (как заклинание [Изгнание](SPELL:${SPELL_BANISHMENT}), концентрация не требуется), если он не находится на своём родном плане и его показатель опасности равен или ниже порога указанного в таблице «Ограждения Магией».

# Ограждение Магией

| Уровень жреца | Изгоняется существо с ПО |
|---------------|--------------------------|
| 5             | ½ или ниже               |
| 8             | 1 или ниже               |
| 11            | 2 или ниже               |
| 14            | 3 или ниже               |
| 17            | 4 или ниже               |
`,
    source: {
      id: SOURCE_SCAG,
      page: 126,
    },
  },
  {
    id: FEATURE_SPELL_BREAKER,
    name: `Разрушитель заклинаний`,
    nameEn: `Spell Breaker`,
    lvl: 6,
    text: `При восстановлении хитов союзнику с помощью заклинания 1 круга или выше, Вы можете так же развеять одно выбранное Вами заклинание, наложенное на это существо.

Уровень прерываемого заклинания должен быть равен или ниже уровню ячейки целебного заклинания.`,
    source: {
      id: SOURCE_SCAG,
      page: 127,
    },
  },
  {
    id: FEATURE_POTENT_SPELLCASTING_ARCANA_DOMAIN,
    name: `Мощное колдовство`,
    nameEn: `Potent Spellcasting`,
    lvl: 8,
    text: `Добавьте свой модификатор Мудрости к урону, который Вы наносите с помощью любого заговора жреца.`,
    source: {
      id: SOURCE_SCAG,
      page: 127,
    },
  },
  {
    id: FEATURE_ARCANE_MASTERY,
    name: `Мастерство магии`,
    nameEn: `Arcane Mastery`,
    lvl: 17,
    text: `Вы выбираете четыре заклинания из списка заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), по одному следующих уровней: 6-й, 7-й, 8-й и 9-й. Добавьте их в список доменных заклинаний.

Как и все доменные заклинания, они всегда подготовлены и для Вас считаются заклинаниями жреца.`,
    spellIdList: [
      ...wizardSpellIdListByLvl[6],
      ...wizardSpellIdListByLvl[7],
      ...wizardSpellIdListByLvl[8],
      ...wizardSpellIdListByLvl[9],
    ],
    source: {
      id: SOURCE_SCAG,
      page: 127,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_ARCANA_DOMAIN,
  })
)
