module.exports = [
  {
    text: `Никогда не забывайте, что дьявол может соврать вам в любой момент, хотя он мог пообещать этого не делать. Только контракт с дьяволом, который он может попытаться заставить вас подписать, может заставить его говорить правду. И даже тогда его речь будет расплывчатой и двусмысленной, чтобы заставить вас прийти к неверным выводам.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Дьяволы заключают сделки со смертными, чтобы подорвать божественный порядок. Они претендуют на души, которые в ином случае пошли бы к богам или были бы унесены куда-то кроме Девяти Преисподних. Если Вы и так уже существо Закона и Зла, которое не поклоняется никому иному, то Ваш обречённый дух имеет невысокую цену.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Дьяволы видят в смертных овец, как и демоны, но дьяволы видят себя не волками, а пастырями. Пастухи стригут овец в сезон и забивают их на мясо по мере надобности. Пастух, скорее всего, постарается убивать волков, угрожающих его овцам. Но при этом пастух всегда ожидает потерь среди овец. Будь ты овцой, доверял бы ты своему пастуху?`,
    author: `Архимаг Морденкайнен`,
  },
]
