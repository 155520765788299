const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_PLANT,
  } = require('./../../../../creatureTypeIdList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_CLIMB,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_COLD,
    DAMAGE_FIRE,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_FALSE_APPEARANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_PRONE,
  } = require('./../../../../conditionList'),
  {
    CREATURE_ASSASSIN_VINE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Лоза-убийца',
  nameEn: 'Assassin Vine',
  id: CREATURE_ASSASSIN_VINE,
  description: [
    {
      header: 'Лоза-убийца',
      text: `Лоза-убийца — странствующее растение, которое собирает своё удобрение, путём поимки и измельчая добычи и хранения тел возле своих корней. Обычно оно остаётся на месте, если ему не нужно искать добычу. Взрослое растение в своей основе состоит из главной лозы, длинной около 20 футов. На основном стебле каждые 6 дюймов растут меньшие лозы длиной до 5 футов. В конце лета вторичные лозы производят грозди мелких фруктов, которые напоминают дикий виноград. Плод жёсткий и сытный, но имеет горький вкус.

Подземный вариант растёт вблизи горячих источников, вулканических отверстий и других источников тепла. Лоза-убийца, растущая под землёй, как правило, генерирует достаточно субпродуктов, чтобы поддержать процветающую колонию грибов и плесени, которые возникают вокруг растения и помогают скрыть его.`,
      source: {
        id: SOURCE_TOA,
        page: 233,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 233,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 5,
    [SPEED_CLIMB]: 5,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 16,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 1,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_BLINDED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного растения`,
    },
  ],
  actionList: [
    {
      name: 'Сжимание',
      description: `Цель становится схваченной (СЛ побега 14). Пока цель схвачена, она обездвижена и получает 21 (6к6) урона ядом в начале каждого своего хода. ★СУЩЕСТВО★  может сжимать только одну цель за раз.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 20,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Опутывающие лозы',
      description: `★СУЩЕСТВО★ может оживить обычные лозы и корни на земле в 15-футовом квадрате в пределах 30 футов от ★него★. Эти растения превращают землю в этой области в труднопроходимую местность.

Существо в этой области, в начале эффекта, должно пройти испытание Силы СЛ 13. При провале оно Схвачено опутывающими лозами и корнями. Существо, Схваченное растениями, может действием попытаться освободиться проверкой Силы (Атлетика) СЛ 13. Эффект заканчивается через 1 минуту или в тот момент, когда ★СУЩЕСТВО★ умирает или снова использует _Опутывающие лозы_.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
