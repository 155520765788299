const {
    SOURCE_XGTE,
  } = require('./../../sourceList'),
  {
    GENDER_FEMALE,
    GENDER_MALE,
  } = require('./../../genderList'),
  spellIdListByClassAndLvl = require('./../../spellIdListByClassAndLvl'),
  {
    CAT_AMMUNITION,
    CAT_MUSIC_TOOLS,
  } = require('./../../gearCategoryList'),
  {
    GEAR_ARROWS,
    GEAR_CANDLE,
    GEAR_CLOTHES_TRAVELERS,
    GEAR_DICE_SET,
    GEAR_FISHING_TACKLE,
    GEAR_HORN,
    GEAR_LOCK,
    GEAR_LONGSWORD,
    GEAR_PEPPER,
    GEAR_PERFUME,
    GEAR_POLE,
    GEAR_ROPE_HEMP,
    GEAR_SACK,
    GEAR_SALT,
    GEAR_SHIELD,
    GEAR_SPELLBOOK,
    GEAR_STAFF,
    GEAR_TANKARD,
    GEAR_TORCH,
    GEAR_WAND,
  } = require('./../../gearIdList'),
  {
    CREATURE_AWAKENED_SHRUB,
    CREATURE_BAT,
    CREATURE_FLUMPH,
    CREATURE_FROG,
    CREATURE_RAT,
  } = require('./../../creatureIdList'),
  {
    MGC_TYPE_ARMOR,
    MGC_TYPE_STAFF,
    MGC_TYPE_WAND,
    MGC_TYPE_WEAPON,
    MGC_TYPE_WONDROUS_ITEM,
  } = require('./../../magicItemTypeList'),
  {
    MGC_RARITY_COMMON,
  } = require('./../../magicItemRarityList'),
  {
    SPELL_ANTIMAGIC_FIELD,
    SPELL_DISPEL_MAGIC,
  } = require('./../../spellIdList'),
  {
    PC_CLASS_BARD,
    PC_CLASS_WARLOCK,
    PC_CLASS_WIZARD,
  } = require('./../../pcClassIdList'),
  armorOfGleaming = require('./../itemGroups/armorOfGleaming'),
  castOffArmor = require('./../itemGroups/castOffArmor'),
  moonTouchedSword = require('./../itemGroups/moonTouchedSword'),
  smolderingArmor = require('./../itemGroups/smolderingArmor'),
  {
    MAGIC_ITEM_BEAD_OF_NOURISHMENT,
    MAGIC_ITEM_BEAD_OF_REFRESHMENT,
    MAGIC_ITEM_BOOTS_OF_FALSE_TRACKS,
    MAGIC_ITEM_CANDLE_OF_THE_DEEP,
    MAGIC_ITEM_CHARLATAN_S_DIE,
    MAGIC_ITEM_CLOAK_OF_BILLOWING,
    MAGIC_ITEM_CLOAK_OF_MANY_FASHIONS,
    MAGIC_ITEM_CLOCKWORK_AMULET,
    MAGIC_ITEM_CLOTHES_OF_MENDING,
    MAGIC_ITEM_DARK_SHARD_AMULET,
    MAGIC_ITEM_DREAD_HELM,
    MAGIC_ITEM_EAR_HORN_OF_HEARING,
    MAGIC_ITEM_ENDURING_SPELLBOOK,
    MAGIC_ITEM_ERSATZ_EYE,
    MAGIC_ITEM_HAT_OF_VERMIN,
    MAGIC_ITEM_HAT_OF_WIZARDRY,
    MAGIC_ITEM_HEWARD_S_HANDY_SPICE_POUCH,
    MAGIC_ITEM_HORN_OF_SILENT_ALARM,
    MAGIC_ITEM_INSTRUMENT_OF_ILLUSIONS,
    MAGIC_ITEM_INSTRUMENT_OF_SCRIBING,
    MAGIC_ITEM_LOCK_OF_TRICKERY,
    MAGIC_ITEM_MYSTERY_KEY,
    MAGIC_ITEM_ORB_OF_DIRECTION,
    MAGIC_ITEM_ORB_OF_TIME,
    MAGIC_ITEM_PERFUME_OF_BEWITCHING,
    MAGIC_ITEM_PIPE_OF_SMOKE_MONSTERS,
    MAGIC_ITEM_POLE_OF_ANGLING,
    MAGIC_ITEM_POLE_OF_COLLAPSING,
    MAGIC_ITEM_POT_OF_AWAKENING,
    MAGIC_ITEM_ROPE_OF_MENDING,
    MAGIC_ITEM_RUBY_OF_THE_WAR_MAGE,
    MAGIC_ITEM_SHIELD_OF_EXPRESSION,
    MAGIC_ITEM_STAFF_OF_ADORNMENT,
    MAGIC_ITEM_STAFF_OF_BIRDCALLS,
    MAGIC_ITEM_STAFF_OF_FLOWERS,
    MAGIC_ITEM_TALKING_DOLL,
    MAGIC_ITEM_TANKARD_OF_SOBRIETY,
    MAGIC_ITEM_UNBREAKABLE_ARROW,
    MAGIC_ITEM_VETERAN_S_CANE,
    MAGIC_ITEM_WALLOPING_AMMUNITION,
    MAGIC_ITEM_WAND_OF_CONDUCTING,
    MAGIC_ITEM_WAND_OF_PYROTECHNICS,
    MAGIC_ITEM_WAND_OF_SCOWLS,
    MAGIC_ITEM_WAND_OF_SMILES,
  } = require('./../../magicItemIdList')

module.exports = [
  // {
  //   id: MAGIC_ITEM_,
  //   name: ``,
  //   nameEn: ``,
  //   type: MGC_TYPE_,
  //   typeComment: ``,
  //   gearCategory: ,
  //   gearType: GEAR_,
  //   rarity: MGC_RARITY_COMMON,
  //   isConsumable: ,
  //   attunement: ,
  //   attunementComment: ``,
  //   description: ``,
  //   genderId: GENDER_,
  //   source: {
  //     id: SOURCE_XGTE,
  //     page: 139,
  //   },
  // },
  {
    id: MAGIC_ITEM_SHIELD_OF_EXPRESSION,
    name: `Щит экспрессии`,
    nameEn: `Shield of Expression`,
    type: MGC_TYPE_ARMOR,
    gearType: GEAR_SHIELD,
    rarity: MGC_RARITY_COMMON,
    description: `Передняя сторона щита имеет форму лица.

Когда Вы носите щит, Вы можете бонусным действием поменять выражение лица.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 139,
    },
  },
  {
    id: MAGIC_ITEM_HAT_OF_VERMIN,
    name: `Шляпа вредителей`,
    nameEn: `Hat of Vermin`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `У этой шляпы три заряда. Держа шляпу, Вы можете действием потратить один заряд и произнести командное слово, которое по Вашему выбору вызовет [летучую мышь](CREATURE:${CREATURE_BAT}) [лягушку](CREATURE:${CREATURE_FROG}) или [крысу](CREATURE:${CREATURE_RAT}). Вызванное существо появляется в шляпе и пытается уйти от Вас с максимально возможной скоростью.

Существо ни дружественно, ни враждебно, и не находится под Вашим контролем. Оно ведёт себя как обычное существо своего вида и исчезает через 1 час или когда его здоровье падает до 0. Шляпа восстанавливает израсходованные заряды ежедневно на рассвете.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 139,
    },
  },
  {
    id: MAGIC_ITEM_HAT_OF_WIZARDRY,
    name: `Шляпа волшебства`,
    nameEn: `Hat of Wizardry`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    attunementComment: `волшебником`,
    description: `Эта старинная конусообразная шляпа украшена золотыми полумесяцами и звёздами. Пока она надета на Вас, Вы получаете следующие преимущества:

* Вы можете использовать эту шляпу как заклинательную фокусировку для своих заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}).
* Вы можете попытаться сотворить заговор, которого не знаете. Заговор должен присутствовать в списке заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), и Вы должны пройти проверку Интеллекта (Магия) СЛ 10. При успехе Вы творите заклинание. При провале и заклинание, и действие, которое было предназначено для сотворения заклинания, теряется. В любом случае Вы не можете снова использовать это свойство, пока не завершите длинный отдых.`,
    genderId: GENDER_FEMALE,
    spellIdList: spellIdListByClassAndLvl[PC_CLASS_WIZARD][0],
    source: {
      id: SOURCE_XGTE,
      page: 139,
    },
  },
  {
    id: MAGIC_ITEM_DREAD_HELM,
    name: `Шлем ужаса`,
    nameEn: `Dread Helm`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Этот жуткий стальной шлем заставляет Ваши глаза светиться красным, когда Вы его носите.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 139,
    },
  },
  {
    id: MAGIC_ITEM_POLE_OF_ANGLING,
    name: `Шест рыболовства`,
    nameEn: `Pole of Angling`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_POLE,
    rarity: MGC_RARITY_COMMON,
    description: `Держа этот 10-футовый шест, Вы можете произнести командное слово и трансформировать его в [рыболовную удочку с крючком, леской и поплавком](GEAR:${GEAR_FISHING_TACKLE}).

Повторное произнесение слова снова превращает его в 10-футовый шест.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_HEWARD_S_HANDY_SPICE_POUCH,
    name: `Удобный мешочек специй Хеварда`,
    nameEn: `Heward’s Handy Spice Pouch`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_SACK,
    rarity: MGC_RARITY_COMMON,
    description: `Этот мешочек пуст, и у него 10 зарядов.

Держа его в руке, Вы можете действием потратить один заряд, назвать любую немагическую пищевую приправу (например, [соль](GEAR:${GEAR_SALT}), [перец](GEAR:${GEAR_PEPPER}), шафран или кинза), и вытащить щепотку приправы из мешочка. Щепотки достаточно, чтобы приправить одну порцию еды.

Мешок ежедневно восстанавливает 1к6+4 потраченных зарядов на рассвете.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_PIPE_OF_SMOKE_MONSTERS,
    name: `Трубка дымных монстров`,
    nameEn: `Pipe of Smoke Monsters`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Куря эту трубку, Вы можете действием выдохнуть облако дыма, которое принимает форму одного существа, например, дракона, [фламфа](CREATURE:${CREATURE_FLUMPH}) или лягемота.

Эта форма должна быть достаточно маленькой, чтобы уместиться в 1-футовый куб, и теряет очертания спустя несколько секунд, превращаясь в обычное облако дыма.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_VETERAN_S_CANE,
    name: `Трость ветерана`,
    nameEn: `Veteran’s Cane`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Когда Вы перехватываете эту трость и бонусным действием произносите командное слово, трость превращается в обычный [длинный меч](GEAR:${GEAR_LONGSWORD}) и перестаёт быть магической.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_MYSTERY_KEY,
    name: `Таинственный ключ`,
    nameEn: `Mystery Key`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Головка этого ключа выполнена в виде вопросительного знака.

Существует шанс 5%, что ключ откроет любой [замок](GEAR:${GEAR_LOCK}), в который был вставлен.

Однажды открыв что либо, ключ пропадает.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_ORB_OF_DIRECTION,
    name: `Сфера направления`,
    nameEn: `Orb of Direction`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Держа эту сферу, Вы можете действием определить направление на север.

Это свойство работает только на Материальном плане.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_ORB_OF_TIME,
    name: `Сфера времени`,
    nameEn: `Orb of Time`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Держа эту сферу, Вы можете использовать действием определить время суток на улице (утро, день, вечер или ночь).

Это свойство работает только на Материальном плане.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_ROPE_OF_MENDING,
    name: `Срастающаяся верёвка`,
    nameEn: `Rope of Mending`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_ROPE_HEMP,
    rarity: MGC_RARITY_COMMON,
    description: `Вы можете разрезать этот 50-футовый моток верёвки на любое количество частей, а затем действием сказать командное слово и заставить их соединиться обратно.

Части должны соприкасаться друг с другом и не должны использоваться в данный момент. Срастающаяся верёвка навсегда укорачивается если её часть потеряна или уничтожена.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_EAR_HORN_OF_HEARING,
    name: `Слуховой рог`,
    nameEn: `Ear Horn of Hearing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_HORN,
    rarity: MGC_RARITY_COMMON,
    description: `Будучи поднесённым к уху, этот рог подавляет на Вас эффекты состояния оглохший и позволяет слышать нормально.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_POLE_OF_COLLAPSING,
    name: `Складной шест`,
    nameEn: `Pole of Collapsing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_POLE,
    rarity: MGC_RARITY_COMMON,
    description: `Держа этот 10-футовый шест, Вы можете действием произнести командное слово и сложить шест в 1-футовый жезл для удобства хранения. Вес шеста не меняется.

Вы можете действием произнести другое командное слово и заставить жезл снова превратиться в шест, однако шест удлинится только настолько, насколько позволяет окружающее пространство.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_CANDLE_OF_THE_DEEP,
    name: `Свеча глубин`,
    nameEn: `Candle of the Deep`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_CANDLE,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Пламя этой свечи не гаснет при погружении в воду.

Она излучает свет и тепло как обычная свеча.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_BOOTS_OF_FALSE_TRACKS,
    name: `Сапоги ложных следов`,
    nameEn: `Boots of False Tracks`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Только гуманоиды могут носить эти сапоги.

Когда Вы их носите, то можете их заставить оставлять следы других гуманоидов Вашего размера.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_CLOTHES_OF_MENDING,
    name: `Самочинящаяся одежда`,
    nameEn: `Clothes of Mending`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_CLOTHES_TRAVELERS,
    rarity: MGC_RARITY_COMMON,
    description: `Эта элегантная одежда путешественника волшебным образом восстанавливает износ и разрывы.

Куски ткани, которые были уничтожены, не могут самостоятельно восстановиться.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_RUBY_OF_THE_WAR_MAGE,
    name: `Рубин боевого мага`,
    nameEn: `Ruby of the War Mage`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    attunementComment: `заклинателем`,
    description: `Испещрённый магическими рунами, этот рубин диаметром 1 дюйм позволяет использовать простое или воинское оружие в качестве магической фокусировки.

Чтобы это сделать, Вы должны прикрепить рубин к оружию, прижимая его к оружию не менее 10 минут. После этого удалить рубин можно будет только действием или уничтожив оружие. Даже [Преграда магии](SPELL:${SPELL_ANTIMAGIC_FIELD}) не заставит его отсоединиться. Рубин падает с оружия, если Ваша настройка на него оканчивается.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_HORN_OF_SILENT_ALARM,
    name: `Рог беззвучного сигнала`,
    nameEn: `Horn of Silent Alarm`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_HORN,
    rarity: MGC_RARITY_COMMON,
    description: `У этого рога 4 заряда. Когда Вы используете действие, чтобы подуть в него, одно существо по Вашему выбору может слышать трубный звук рога, при условии, что оно находится не более, чем в 600 футах от рога, и не оглохло.

Никакие другие существа не слышат звук рога. Рог восстанавливает 1к4 потраченных зарядов ежедневно на рассвете.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_BILLOWING,
    name: `Развевающийся плащ`,
    nameEn: `Cloak of Billowing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Когда Вы носите этот плащ, Вы можете бонусным действием заставить его драматично развеваться.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 138,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_FLOWERS,
    name: `Посох цветов`,
    nameEn: `Staff of Flowers`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_STAFF,
    rarity: MGC_RARITY_COMMON,
    description: `У этого деревянного посоха 10 зарядов. Держа посох, Вы можете действием потратить один заряд. Пробуждённый силой посоха цветок вырастет на почве или грязи в пределах 5 футов от Вас или на самом посохе.

Если Вы не выбрали тип цветка, посох создаёт маргаритку с лёгким запахом. Цветок безвредный и немагический. Он растёт и увядает как обычный цветок.

Посох ежедневно восстанавливает 1к6 + 4 заряда на рассвете. Если Вы потратили последний заряд, бросьте к20. Если выпадет 1, посох превращается в лепестки цветов и пропадает навсегда.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_ADORNMENT,
    name: `Посох украшения`,
    nameEn: `Staff of Adornment`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_STAFF,
    rarity: MGC_RARITY_COMMON,
    description: `Если Вы размещаете объект, весящий не более 1 фунта (такой как осколок кристалла, яйцо или камень) выше навершия посоха, пока держите его, объект поднимается на дюйм от навершия посоха и остаётся там до тех пор, пока не будет перемещён или пока посох находится у Вас.

Посох может одновременно удерживать до трёх таких объектов плавающими над его навершием. Пока Вы держите посох, Вы можете заставить один из объектов медленно вращаться или изменить своё местоположение.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_STAFF_OF_BIRDCALLS,
    name: `Посох птичьего щебета`,
    nameEn: `Staff of Birdcalls`,
    type: MGC_TYPE_STAFF,
    gearType: GEAR_STAFF,
    rarity: MGC_RARITY_COMMON,
    description: `Этот деревянный посох украшен резьбой в виде птиц. У него 10 зарядов.

Держа посох, Вы можете действием потратить один заряд и создать на расстоянии до 60 футов один из следующих звуков:

* гогот гуся,
* карканье ворона,
* клич гагары,
* крик орла,
* кряканье утки,
* кудахтанье курицы,
* кулдыканье индюка,
* плач чайки,
* уханье совы,
* чириканье вьюрка.

Посох ежедневно восстанавливает 1к6 +4 заряда на рассвете. Если Вы потратили последний заряд, бросьте к20. Если выпадет 1, посох взрывается безвредным облаком птичьих перьев и пропадает навсегда.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_LOCK_OF_TRICKERY,
    name: `Плутовской замок`,
    nameEn: `Lock of Trickery`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_LOCK,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Этот замок выглядит как обычный [замок](GEAR:${GEAR_LOCK}), и к нему прилагается один ключ.

Внутренний механизм замка магическим образом двигается, чтобы предотвратить попытки взлома. Проверки Ловкости с целью вскрыть этот замок совершаются с помехой.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_MANY_FASHIONS,
    name: `Плащ множества стилей`,
    nameEn: `Cloak of Many Fashions`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Когда Вы носите этот плащ, Вы можете бонусным действием заставить его изменить стиль, цвет и видимое качество. Вес плаща не меняется. Независимо от внешнего вида, он может быть только плащом.

Хотя он может принимать вид других магических плащей, их свойств он не получает.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_PERFUME_OF_BEWITCHING,
    name: `Парфюм очарования`,
    nameEn: `Perfume of Bewitching`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_PERFUME,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Этот крошечный пузырёк содержит магический парфюм в количестве, достаточном для одного использования.

Действием Вы можете нанести парфюм на себя, и его эффект будет длиться один час. В течение этого времени Вы получаете преимущество ко всем проверкам Харизмы, направленным на гуманоидов с рейтингом опасности 1 и ниже. Те, на кого воздействует эффект парфюма, не понимают, что подверглись воздействию магии.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_SCOWLS,
    name: `Палочка хмурых взглядов`,
    nameEn: `Wand of Scowls`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_COMMON,
    description: `У этой волшебной палочки 3 заряда. Если Вы её держите, то можете действием потратить 1 заряд и выбрать целью гуманоида, видимого Вами в пределах 30 футов. Цель должна пройти испытание Харизмы СЛ 10, иначе она будет хмуриться 1 минуту.

Палочка восстанавливает все заряды на рассвете. Если Вы потратили последний заряд, бросьте к20. Если выпадет 1, палочка превращается в [Палочку улыбок](MAGIC_ITEM:${MAGIC_ITEM_WAND_OF_SMILES}).`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_SMILES,
    name: `Палочка улыбок`,
    nameEn: `Wand of Smiles`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_COMMON,
    description: `У этой волшебной палочки 3 заряда. Если Вы её держите, то можете действием потратить 1 заряд и выбрать целью гуманоида, видимого Вами в пределах 30 футов. Цель должна пройти испытание Харизмы СЛ 10, иначе она будет улыбаться 1 минуту.

Палочка восстанавливает все заряды на рассвете. Если Вы потратили последний заряд, бросьте к20. Если выпадет 1, палочка превращается в [Палочку хмурых взглядов](MAGIC_ITEM:${MAGIC_ITEM_WAND_OF_SCOWLS}).`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_PYROTECHNICS,
    name: `Палочка пиротехники`,
    nameEn: `Wand of Pyrotechnics`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_COMMON,
    description: `У этой волшебной палочки 7 зарядов. Если Вы её держите, Вы можете действием потратить 1 заряд, чтобы создать безвредную разноцветную вспышку света в точке в пределах 60 футов. Эта вспышка сопровождается потрескивающим шумом, слышимым на расстоянии 300 футов. Свет такой же яркий, как пламя [факела](GEAR:${GEAR_TORCH}), но длится всего секунду.

Палочка ежедневно восстанавливает все 1к6 + 1 заряд на рассвете. Если Вы потратили последний заряд, бросьте к20. Если выпадет 1, палочка взрывается безвредным пиротехническим всполохом и уничтожается.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_BEAD_OF_REFRESHMENT,
    name: `Освежающая бусина`,
    nameEn: `Bead of Refreshment`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Эта пористая безвкусная студенистая бусина растворяется в жидкости, превращая до пинты жидкости в свежую чистую холодную воду.

Бусина не оказывает эффект на магические жидкости или опасные вещества типа яда.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_WALLOPING_AMMUNITION,
    name: `Опрокидывающий боеприпас`,
    nameEn: `Walloping Ammunition`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой боеприпас`,
    gearCategory: CAT_AMMUNITION,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Существо, в которое попал такой боеприпас, должно пройти испытание Силы со СЛ 10, иначе будет сбито с ног.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 137,
    },
  },
  {
    id: MAGIC_ITEM_UNBREAKABLE_ARROW,
    name: `Несломимая стрела`,
    nameEn: `Unbreakable Arrow`,
    type: MGC_TYPE_WEAPON,
    gearType: GEAR_ARROWS,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Эта [стрела](GEAR:${GEAR_ARROWS}) не может быть сломана, кроме как в области антимагии.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  },
  {
    id: MAGIC_ITEM_CLOCKWORK_AMULET,
    name: `Механистический амулет`,
    nameEn: `Clockwork Amulet`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Этот медный амулет содержит очень маленькие шестерёнки, находящиеся в постоянном движении и заряженные магией Механуса — плана механической предопределённости. Существо, которое прикладывает амулет к уху, может услышать тихое тиканье и шуршание шестерёнок.

Когда Вы носите этот амулет и совершаете бросок атаки, Вы можете не бросать к20 и просто выбрать значение 10 на кости. Это свойство нельзя использовать повторно до следующего рассвета.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  },
  {
    id: MAGIC_ITEM_TANKARD_OF_SOBRIETY,
    name: `Кружка трезвости`,
    nameEn: `Tankard of Sobriety`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_TANKARD,
    rarity: MGC_RARITY_COMMON,
    description: `На одной стороне этой кружки изображено суровое лицо.

Вы можете пить из этой кружки эль, вино или любой другой немагический алкоголь и не пьянеть. Кружка не оказывает никакого эффекта на магический алкоголь и вредные вещества, такие как яд.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  },
  {
    id: MAGIC_ITEM_CHARLATAN_S_DIE,
    name: `Кость шарлатана`,
    nameEn: `Charlatan’s Die`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_DICE_SET,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    description: `Когда Вы бросаете эту 6-гранную кость, Вы можете выбрать, какой гранью она выпадет.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  },
  {
    id: MAGIC_ITEM_INSTRUMENT_OF_SCRIBING,
    name: `Инструмент надписей`,
    nameEn: `Instrument of Scribing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearCategory: CAT_MUSIC_TOOLS,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    description: `У этого инструмента 3 заряда. Играя на нём, Вы можете действием потратить один заряд инструмента, чтобы написать магическое послание на немагическом предмете или поверхности, видимых Вами в пределах 30 футов.

Послание не может превышать 6 слов и должно быть написано на известном Вам языке. Если Вы [бард](PC_CLASS:${PC_CLASS_BARD}), то можете увеличить длину сообщения ещё на 7 слов, и по Вашему выбору слова могут слабо светиться, что позволит прочесть их в немагической темноте.

Применение заклинания [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}) на слова стирает их. В противном случае сообщение пропадает через 24 часа.

Инструмент восстанавливает все потраченные заряды ежедневно на рассвете.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  },
  {
    id: MAGIC_ITEM_INSTRUMENT_OF_ILLUSIONS,
    name: `Инструмент иллюзий`,
    nameEn: `Instrument of Illusions`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearCategory: CAT_MUSIC_TOOLS,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    description: `Когда Вы играете на этом инструменте, Вы можете создавать безвредные иллюзорные визуальные эффекты в пределах сферы радиусом 5 футов с центром на инструменте. Если Вы [бард](PC_CLASS:${PC_CLASS_BARD}), радиус возрастает до 15 футов.

Примеры визуальных эффектов — светящиеся ноты в воздухе, призрачная танцовщица, бабочки или медленно падающие хлопья снега. Магические эффекты неосязаемы и не издают звуков, кроме того, их иллюзорность очевидна.

Эффекты заканчиваются, когда Вы перестаёте играть.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  },
  {
    id: MAGIC_ITEM_ENDURING_SPELLBOOK,
    name: `Износостойкая книга заклинаний`,
    nameEn: `Enduring Spellbook`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_SPELLBOOK,
    rarity: MGC_RARITY_COMMON,
    description: `Эта [книга заклинаний](GEAR:${GEAR_SPELLBOOK}), включая всё в ней записанное, не может быть повреждена огнём или погружением в воду.

Кроме того, она не портится со временем.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  },
  {
    id: MAGIC_ITEM_ERSATZ_EYE,
    name: `Запасной глаз`,
    nameEn: `Ersatz Eye`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    description: `Этот искусственный глаз заменяет настоящий, который был потерян или удалён.

Когда запасной глаз вставлен в глазницу, его не может удалить никто, кроме Вас, и Вы можете видеть сквозь этот крошечный шарик, как будто это обычный глаз`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_CONDUCTING,
    name: `Дирижёрская палочка`,
    nameEn: `Wand of Conducting`,
    type: MGC_TYPE_WAND,
    gearType: GEAR_WAND,
    rarity: MGC_RARITY_COMMON,
    description: `У этой волшебной палочки 3 заряда. Если Вы её держите, Вы можете действием потратить 1 заряд, чтобы создать оркестровую музыку, размахивая ею. Музыку слышно на расстоянии 60 футов, и она заканчивается, когда Вы перестанете размахивать палочкой.

Палочка ежедневно восстанавливает все заряды на рассвете. Если Вы потратили последний заряд, бросьте к20. Если выпадет 1, играет грустный тромбон, а палочка рассыпается в пыль и уничтожается.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  },
  {
    id: MAGIC_ITEM_POT_OF_AWAKENING,
    name: `Горшок пробуждения`,
    nameEn: `Pot of Awakening`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Если Вы посадите обычный куст в этот 10-фунтовый глиняный горшок и будете выращивать его в течение 30 дней, к концу этого времени куст магическим образом превратится в [Пробуждённый куст](CREATURE:${CREATURE_AWAKENED_SHRUB}).

При пробуждении куст ломает корнями горшок, уничтожая его. [Пробуждённый куст](CREATURE:${CREATURE_AWAKENED_SHRUB}) дружественен к Вам. Куст ничего не делает в отсутствие команд от Вас.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 135,
    },
  },
  {
    id: MAGIC_ITEM_TALKING_DOLL,
    name: `Говорящая кукла`,
    nameEn: `Talking Doll`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    description: `Пока кукла находится в пределах 5 футов от Вас, Вы можете во время короткого отдыха сказать ей до шести фраз, ни одна из которых не может быть длиннее шести слов, и установить условия, при которых кукла будет говорить каждую фразу.

Каким бы ни было условие, оно должно совершиться в пределах 5 футов от куклы, чтобы она заговорила. Например, когда кто-то поднимет куклу, она может сказать: _«Я хочу конфетку»_.

Фразы куклы теряются, когда Ваша настройка на неё заканчивается.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 135,
    },
  },
  {
    id: MAGIC_ITEM_BEAD_OF_NOURISHMENT,
    name: `Бусина насыщения`,
    nameEn: `Bead of Nourishment`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Эта пористая безвкусная студенистая бусина растворяется у Вас на языке и насыщает Вас на 1 день.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_XGTE,
      page: 135,
    },
  },
  {
    id: MAGIC_ITEM_DARK_SHARD_AMULET,
    name: `Амулет тёмного осколка`,
    nameEn: `Dark Shard Amulet`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    attunementComment: `колдуном`,
    description: `Этот амулет выполнен из цельного осколка упругого экстрапланарного материала, происходящего из царства покровителя Вашего [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}). Пока он надет на Вас, Вы получаете следующие преимущества:

* Вы можете использовать этот амулет как магическую фокусировку для своих заклинаний [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}).
* Вы можете попробовать сотворить заговор, которого не знаете. Этот заговор должен быть в списке заклинаний [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}), и Вы должны пройти проверку Интеллекта (Магия) СЛ 10. При успехе Вы сотворяете заклинание. При провале и заклинание, и действие, которое было предназначено для сотворения заклинания, теряется. В любом случае Вы не можете снова использовать это свойство, пока не завершите длинный отдых.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_XGTE,
      page: 135,
    },
  },
  ...armorOfGleaming,
  ...castOffArmor,
  ...moonTouchedSword,
  ...smolderingArmor,
]
