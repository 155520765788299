const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_KENKU} = require('./../../creatureTypeIdList')
const {
  kenkuDescriptionList,
  kenkuNote,
} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_KENKU,
  nameEn: 'Kenku',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'кенку',
      genitive: 'кенку',
      dative: 'кенку',
      accusative: 'кенку',
      instrumental: 'кенку',
      prepositional: 'кенку',
    },
    plural: {
      nominative: 'кенку',
      genitive: 'кенку',
      dative: 'кенку',
      accusative: 'кенку',
      instrumental: 'кенку',
      prepositional: 'кенку',
    },
  },
  note: kenkuNote,
  description: kenkuDescriptionList,
}
