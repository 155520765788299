const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_ROPE_TRICK} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_ROPE_TRICK,
  name: 'Трюк с верёвкой',
  nameEn: `Rope Trick`,
  description: `Вы касаетесь верёвки длиной до 60 футов. Один её конец поднимается в воздух, а остальная часть висит перпендикулярно полу. На верхнем конце верёвки появляется невидимый вход в межпространство, существующий, пока заклинание активно.

В межпространство можно попасть, забравшись по верёвке наверх. В этом межпространстве может поместиться восемь существ с размером не больше Среднего. Верёвку можно затянуть внутрь, после чего снаружи её не будет видно.

Атаки и заклинания не могут проходить через вход внутрь межпространства и наружу, но те, кто находятся внутри, могут всё видеть как через окно 3 × 5 футов с центром на верёвке.

Всё, что находится в межпространстве, вываливается наружу, когда заклинание заканчивается.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `экстракт зерна и петля из пергамента`,
  duration: { timeId: TIME_HOUR, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 284,
    },
    {
      id: SOURCE_SRD,
      page: 176,
    },
  ],
}
