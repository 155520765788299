const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_ALARM,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ALARM,
  name: 'Сигнал тревоги',
  nameEn: `Alarm`,
  description: `Вы устанавливаете сигнализацию на случай вторжения. Выберите дверь, окно или область в пределах дистанции не больше куба с длиной ребра 20 футов. До окончания действия заклинания тревога уведомляет Вас каждый раз, когда охраняемой области касается или входит в неё существо с размером не меньше Крошечного. При накладывании заклинания Вы можете указать существ, которые не будут вызывать срабатывание тревоги. Вы также выбираете, мысленной будет тревога или слышимой.

Мысленная тревога предупреждает Вас звоном в сознании, если Вы находитесь в пределах 1 мили от охраняемой области. Этот звон пробуждает Вас, если Вы спите.

Слышимая тревога издаёт звон колокольчика в течение 10 секунд в пределах 60 футов.`,
  lvl: 1,
  magicSchoolId: MAGIC_ABJURATION,
  range: 30,
  isRitual: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `колокольчик и серебряная проволочка`,
  duration: { timeId: TIME_HOUR, count: 8 },
  castTime: { timeId: TIME_MINUTE, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 276,
    },
    {
      id: SOURCE_SRD,
      page: 114,
    },
  ],
}
