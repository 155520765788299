const {
  WEAPON_HEAVY,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const axesDescription = require('./../../constants/axesDescription')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_GREATAXE} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_GREATAXE,
  genderId: GENDER_MALE,
  name: 'Двуручный топор',
  nameByCase: {
    nominative: 'двуручный топор',
    genitive: 'двуручного топора',
    accusative: 'двуручный топор',
    instrumental: 'двуручным топором',
  },
  nameAlt: 'Секира',
  nameEn: 'Greataxe',
  description: axesDescription,
  damage: {
    diceType: 12,
    diceCount: 1,
  },
  cost: 3000,
  damageType: DAMAGE_SLASHING,
  weight: 7,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 111,
    },
  ],
  weaponPropList: [
    WEAPON_TWO_HANDED,
    WEAPON_HEAVY,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
