const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_SUNLIGHT_SENSITIVITY,
  ABILITY_WEB_WALKER,
  ABILITY_WEB_SENSE,
} = require('./../../../../creatureAbilityList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_ATHLETICS,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  chitineDescriptionList,
  chitineNote,
} = require('./../../../../textCommonParts')
const {
  GEAR_DAGGER,
  GEAR_HIDE_ARMOR,
} = require('./../../../../gearIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_CHITINE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_UNDERCOMMON} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Хитина',
  nameEn: 'Chitine',
  id: CREATURE_CHITINE,
  description: chitineDescriptionList,
  note: chitineNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 198,
  },
  armor: {
    ac: 14,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_SUNLIGHT_SENSITIVITY,
    ABILITY_WEB_SENSE,
    ABILITY_WEB_WALKER,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Хитин делает три атаки _Кинжалами_.`,
    },
    {
      gearId: GEAR_DAGGER,
    },
  ],
  genderId: GENDER_FEMALE,
}
