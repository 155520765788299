const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  DAMAGE_FIRE,
} = require('./../../../../../damageTypeList')
const {
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_FTD,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_ASHARDALON_S_STRIDE,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_ASHARDALON_S_STRIDE,
  name: 'Ашардалонов путь',
  nameEn: 'Ashardalon’s Stride',
  description: `Клубы драконьего пламени покрывают Ваши ступни, придавая Вам взрывную скорость.

На время действия заклинания Ваша скорость увеличивается на 20 футов и Ваше передвижение не провоцирует атак по возможности.

Когда Вы передвигаетесь в пределах 5 футов от существа или предмета, который не несут и не носят, они получают 1к6 урона огнём от Ваших пышущих жаром следов. Существо или предмет могут получить этот урон только один раз в ход.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, за каждый круг ячейки выше третьего Ваша скорость увеличивается на 5 футов, а урон увеличивается на 1к6.`,
  lvl: 3,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: -1,
  componentIdList: [CAST_SOMATIC, CAST_VERBAL],
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    damage: {
      type: DAMAGE_FIRE,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: {
    id: SOURCE_FTD,
    page: 19,
  },
}
