const {
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')

module.exports = [
  {
    header: `Юголоты`,
    text: `Юголоты — вероломные исчадия, населяющие планы Ахерон, Геенна, Гадес и Карцери. Они служат в качестве наёмников и печально известны тем, что часто меняют сторону. Они — воплощение алчности. Перед тем, как встать под чьё-либо знамя, юголот обязательно задаст один-единственной вопрос: что мне лично это даст?`,
    source: {
      id: SOURCE_MM,
      page: 316,
    },
  },
  {
    header: `Порождения Геенны`,
    text: `Первые юголоты были созданы сестринской общиной ночных карг на Геенне. Широко распространено поверье, что работу по созданию юголотов заказал Асмодей, Лорд Девяти Преисподних, в надежде создать армию исчадий, не привязанных к Девяти Преисподним. В процессе создания этой новой армии карги изготовили четыре магических книги, в которых записали истинные имена каждого созданного юголота, кроме одного, Генерала Геенны. Эти тома были названы _Книгами Перечёта_. Поскольку знание истинного имени исчадия даёт власть над ним, карги использовали эти книги, чтобы заручиться верностью юголотов. Также они использовали эти книги, чтобы записать имена прочих исчадий, которые перешли им дорогу. По слухам, в _Книгах_ также содержатся истинные имена нескольких демонических повелителей и архидьяволов.

Мелкая зависть и постоянные склоки привели к распаду сестринской общины, и в процессе последовавшего за этим дележа власти _Книги Перечёта_ были утеряны или украдены. Юголоты, более не обязанные служить кому-либо, обрели независимость и теперь предлагают свои услуги тому, кто больше предложит.`,
    source: {
      id: SOURCE_MM,
      page: 316,
    },
  },
  {
    header: `Наёмники-исчадия`,
    text: `Призываемые юголоты требуют много за свои время и верность. Какое бы обещание ни дал юголот, оно будет нарушено, как только возникнет возможность получше. В отличие от демонов, с юголотами можно торговаться и вести переговоры, но в отличие от дьяволов они редко держат слово.

Юголотов можно найти где угодно, но высокая стоимость поддержания верности армии юголотов обычно не по карману никакому военачальнику на Материальном плане.

Будучи эгоистичными существами, юголоты постоянно ссорятся между собой. Армия юголотов более организованна, чем орда демонов, но гораздо менее упорядочена, чем легион дьяволов. Если отсутствует сильный лидер, который держит их в узде, юголоты сражаются просто из склонности к насилию, и только пока им это выгодно.`,
    source: {
      id: SOURCE_MM,
      page: 316,
    },
  },
  {
    header: `Обратно в Геенну`,
    text: `Когда юголот погибает, он растворяется лужей ихора и формируется заново с полными силами в Мрачной вечности Геенны. Только на своём родном плане юголот может быть уничтожен навсегда. Юголоты знают это, и действуют соответственно. Когда их призывают на другие планы, юголоты сражаются, особо не заботясь о своей безопасности. В Геенне же, если поражение и смерть начинают казаться неминуемыми, юголот с гораздо большей вероятностью станет просить о пощаде или попытается бежать.

Когда юголот уничтожается навсегда, его имя исчезает из всех _Книг Перечёта_. Если юголота создают заново в процессе нечестивого ритуала, требующего использования душ других существ, его имя заново появляется в книгах.`,
    source: {
      id: SOURCE_MM,
      page: 316,
    },
  },
  {
    header: `Книги Перечёта`,
    text: `Когда все четыре копии _Книг Перечёта_ исчезли, Асмодей и ночные карги потеряли контроль над созданными ими юголотами. Каждая из четырёх _Книг Перечёта_ всё ещё существует, переходя с плана на план, где смелые и глупые периодически натыкаются на них. Юголот, которого призвали с помощью истинного имени, содержащегося в Книге, вынужден покорно служить вызывателю. Юголоты ненавидят, когда их контролируют таким образом, и не стесняются дать знать о своём неудовольствии. Как непослушное дитя, юголоты будут следовать приказу буквально, при этом ища возможность трактовать его неверным образом.`,
    source: {
      id: SOURCE_MM,
      page: 316,
    },
  },
  {
    header: `Генерал Геенны`,
    text: `Где-то на серных равнинах Геенны бродит настолько могущественный ультролот, что никто не смеет бросить вызов его силе. Это Генерал Геенны. Многие юголоты ищут этого великого генерала в надежде поступить ему на службу. Они убеждены, что служба Генералу Геенны приносит силу и престиж среди обитателей нижних планов.

Как бы то ни было, ни одно исчадие не сможет найти Генерала Геенны, если он сам того не пожелает. Его истинное имя неизвестно, и даже _Книги Перечёта_ не содержат упоминания об этом могущественном воплощении истинного зла.`,
    source: {
      id: SOURCE_MM,
      page: 316,
    },
  },
  {
    header: `Юголоты`,
    text: `Наёмники, что занимаются своей торговлей на территории Нижних Планов и в других царствах, юголоты получили уважение за свою эффективность, которая соответствует только их стремлению к ещё большему богатству. Хоты юголоты не особо лояльны, и обычно используют любую потенциальную лазейку в контракте, они гарантируют выполнение любого дела, для которого они были наняты, какой бы презренной задача ни была.`,
    source: {
      id: SOURCE_MTOF,
      page: 316,
    },
  },
]
