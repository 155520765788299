const {
    SOURCE_AI,
  } = require('./../../../../sourceList'),
  {
    CR_4,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ABERRATION,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ACROBATICS,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_CLIMB,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_MAGIC_RESISTANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CREATURE_CHAOS_QUADRAPOD,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Четвероног хаоса',
  nameEn: 'Chaos Quadrapod',
  id: CREATURE_CHAOS_QUADRAPOD,
  description: `Скопление из четырёх выдвигающихся щупалец и пульсирующей массой эфирного света вместо центрального тела, четвероног хаоса — из Дальнего Предела. Он направляет хаотические силы этого плана, уничтожая всё на своем пути. Четвероног перемещается, с ужасным глухим звуком выкидывая вперёд покрытые слизью щупальца и неуклонно таща себя к добыче.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_AI,
    page: 210,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 15,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 4,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
      isBlindOutside: true,
    },
  ],
  cr: CR_4,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Щупальцами_.`,
    },
    {
      name: 'Щупальце',
      description: `Если цель — существо, оно Схвачено (СЛ побега 14). До окончания захвата его цель Обездвижена. ★СУЩЕСТВО★ может держать в захвате не более двух целей одновременно.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Облако хаоса',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `★СУЩЕСТВО★ выстреливает узел сверкающего эфирного света, которые взрываются в точке, видимой им в пределах 60 футов. Каждое существо в пределах сферы радиусом 20 футов вокруг этой точки должно пройти испытания Харизмы СЛ 14 или стать Ошеломлённым до конца своего следующего хода.`,
    },
  ],
}
