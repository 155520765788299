const {
  CREATURE_COW,
  CREATURE_STENCH_KAW,
} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Зловонный бык',
  nameEn: 'Stench Kaw',
  id: CREATURE_STENCH_KAW,
  parentId: CREATURE_COW,
  description: `Эти оранжево-зелёные уродливые бизоны родом с Нижних Планов.`,
  creatureId: GENDER_MALE,
  source: {
    id: SOURCE_VGTM,
    page: 210,
  },
  extendPropCollection: {
    featureList: [
      {
        name: 'Зловоние',
        description: `Любое существо, кроме зловонных быков, которое начинает свой ход в пределах 5 футов от зловонного быка, должно пройти испытание Телосложения СЛ 12, иначе будет отравлено до начала своего следующего хода. При успешном испытании существо получает имуннитет к зловонию всех зловонных быков на 1 час.`,
      },
    ],
  },
}
