const {
  SPELL_CHARM_PERSON,
  SPELL_CONE_OF_COLD,
  SPELL_DARKNESS,
  SPELL_GASEOUS_FORM,
  SPELL_INVISIBILITY,
  SPELL_SLEEP,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_DECEPTION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  ABILITY_CHANGE_SHAPE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {
  CREATURE_OGRE,
  CREATURE_ONI,
} = require('./../../../../creatureIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_GLAIVE,
} = require('./../../../../gearIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_TYPE_GIANT} = require('./../../../../creatureTypeIdList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Они',
  nameAlt: 'Огр-маг',
  nameEn: 'Oni',
  nameEnAlt: 'Ogre Mage',
  id: CREATURE_ONI,
  description: [
    {
      header: 'Они',
      text: `В детских песенках они — это жуткие чудовища, которые приходят в ночных кошмарах к детям и подросткам, но на самом деле они вполне реальны и всегда ужасно голодны. Человеческих детей они находят особенным лакомством. Они выглядят как демонические [огры](CREATURE:${CREATURE_OGRE}) со шкурой синего или зелёного цветов, тёмными волосами и парой коротких рогов цвета слоновой кости, торчащими из их лбов. У них чёрные глаза с ярко-белыми зрачками, а их зубы и клыки угольно-чёрного цвета.`,
      source: {
        id: SOURCE_MM,
        page: 226,
      },
    },
    {
      header: 'Обитатели ночи',
      text: `При свете дня они скрывают свой истинный облик, чтобы легче втираться в доверие к тому, кого они собираются сожрать под покровом тьмы. Эти существа могут менять свой облик и размер, появляясь под видом гуманоидов в населённых пунктах, где они выдают себя за путешественников, лесорубов или обитателей пограничья. В таком виде они заранее подбирают себе жертв из жителей поселений, а также измышляют различные пути и способы для их похищения и последующего пожирания.`,
      source: {
        id: SOURCE_MM,
        page: 226,
      },
    },
    {
      header: 'Магические огры',
      text: `Этих существ иногда называют «ограми магами» за врождённые магические способности. Хотя их связь с истинными [ограми](CREATURE:${CREATURE_OGRE}) весьма посредственна, они вполне разделяют привычки огров по объединению с другими злобными созданиями. Они охотно служат сильным хозяевам, особенно если эта служба сулит прибыль или предоставляет им роскошное, хорошо защищённое убежище. Они жадно ищут магию и работают на злых волшебников и ведьм в обмен на полезные магические предметы.`,
      source: {
        id: SOURCE_MM,
        page: 226,
      },
    },
  ],
  note: {
    text: `Затвори двери, задуй свечу;

Голодный они крадется ночью.

Прячься и бойся, маленький мой;

Повеселиться хочет они с тобой.



Слышен скрежет у дверей;

Видны тени средь щелей.

Солнышко выйдет над вершинами гор;

Улыбки они страшись до тех пор.`,
    author: `Детский стишок`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 226,
  },
  armor: {
    ac: 16,
    gearId: GEAR_CHAIN_MAIL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 16,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
    LANG_COMMON,
  ],
  cr: CR_7,
  featureList: [
    {
      id: ABILITY_REGENERATION,
      regen: 20,
      other: `, если у ★него★ есть хотя бы 1 хит`,
    },
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DARKNESS,
          SPELL_INVISIBILITY,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CHARM_PERSON,
          SPELL_CONE_OF_COLD,
          SPELL_GASEOUS_FORM,
          SPELL_SLEEP,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки, либо _Когтями_, либо _Глефой_.`,
    },
    {
      name: 'Коготь',
      comment: 'только в облике они',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      gearId: GEAR_GLAIVE,
      comment: 'в Маленьком или Среднем облике',
    },
    {
      name: 'Глефа',
      // gearId: GEAR_GLAIVE, // TODO: Найти способ показывать оружие с изменёнными костями
      comment: 'в Большом облике',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      id: ABILITY_CHANGE_SHAPE,
      nextForm: `Маленького или Среднего гуманоида, в Большого великана`,
      gearMerge: `не превращается. Единственное снаряжение, которое трансформируется вместе с ★ним★, это [глефа](GEAR:${GEAR_GLAIVE}), которая может уменьшаться, чтобы подходить гуманоидному облику. При ★его★ смерти [глефа](GEAR:${GEAR_GLAIVE}) принимает нормальный размер`,
      statChange: `За исключением размера, во всех обликах ★его★ характеристики остаются теми же самыми`,
    },
  ],
  genderId: GENDER_MALE,
}
