const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_GOBLIN,
  CREATURE_HOBGOBLIN,
  CREATURE_WORG,
} = require('./../../../../creatureIdList')
const {
  LANG_GOBLIN,
  LANG_WORGEN,
} = require('./../../../../languageIdList')
const {ABILITY_KEEN_HEARING_AND_SMELL} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ворг',
  nameEn: 'Worg',
  id: CREATURE_WORG,
  description: `Ворг это злой хищник, наслаждающийся охотой и пожиранием тех, кто слабее его. Хитрые и злобные ворги рыщут по дикой местности или выращиваются [гоблинами](CREATURE:${CREATURE_GOBLIN}) и [хобгоблинами](CREATURE:${CREATURE_HOBGOBLIN}), которые используют воргов в качестве скакунов, но ворг растерзает всадника, если почувствует голод или плохое обращение. Ворги говорят на своём собственном языке и Гоблинском, а некоторые из них говорят и на Всеобщем языке.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 321,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 13,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GOBLIN,
    LANG_WORGEN,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_KEEN_HEARING_AND_SMELL,
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Если цель — существо, она должна пройти испытание Силы СЛ 13, иначе будет сбита с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
