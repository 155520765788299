const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_MIND_BLANK,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SOURCE_MM,
  SOURCE_ERRATA_MONSTER_MANUAL,
} = require('./../../../../sourceList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_INTELLECT_DEVOURER,
  CREATURE_MIND_FLAYER,
} = require('./../../../../creatureIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CONDITION_BLINDED} = require('./../../../../conditionList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_DEEP_SPEECH} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Пожиратель интеллекта',
  nameEn: 'Intellect Devourer',
  id: CREATURE_INTELLECT_DEVOURER,
  description: [
    {
      header: 'Пожиратель интеллекта',
      text: `Пожиратель интеллекта похож на ходячий мозг, покрытый коркой, на когтистых звериных лапах. Эта мерзкая аберрация питается интеллектом разумных существ, получая контроль над телом жертвы в угоду своим хозяевам, [свежевателям разума](CREATURE:${CREATURE_MIND_FLAYER}).`,
      source: {
        id: SOURCE_MM,
        page: 237,
      },
    },
    {
      header: 'Создания иллитидов',
      text: `[Свежеватели разума](CREATURE:${CREATURE_MIND_FLAYER}) разводят пожирателей интеллекта, чтобы те служили охотниками в Подземье. Пожирателей интеллекта создают из мозга раба, подвергая его ужасному ритуалу. Мозг отращивает лапы, становясь разумным хищником, таким же извращённым и злым, как и его хозяева.`,
      source: {
        id: SOURCE_MM,
        page: 237,
      },
    },
    {
      header: 'Смертоносные кукловоды',
      text: `Пожиратель интеллекта поглощает разум существа и его воспоминания. Затем он обращает тело носителя в марионетку под своим контролем. Пожиратель интеллекта обычно использует свою марионетку-носителя, чтобы заманивать других во владения [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), чтобы их поработили или съели.`,
      source: {
        id: SOURCE_MM,
        page: 237,
      },
    },
  ],
  note: {
    text: `Не плачь. Мы не собираемся есть твой мозг. На самом деле, твой мозг скоро отправится в восхитительное путешествие!`,
    author: `Куорик, эль-Слуррк, свежеватель разума`,
  },
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_MM,
      page: 237,
    },
    {
      id: SOURCE_ERRATA_MONSTER_MANUAL,
      page: 1,
    },
  ],
  armor: 12,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  languageList: [
    {
      id: LANG_DEEP_SPEECH,
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      name: `Обнаружение сознания`,
      description: `★СУЩЕСТВО★ может чувствовать присутствие и местонахождение всех существ в пределах 300 футов, обладающих Интеллектом 3 и выше, какими бы барьерами они не были отгорожены, если только они не защищены заклинанием [Сокрытие разума](SPELL:${SPELL_MIND_BLANK}).`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает одну атаку _Когтями_ и использует _Пожирание интеллекта_.`,
    },
    {
      name: `Когти`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: `Пожирание интеллекта`,
      description: `★СУЩЕСТВО★ нацеливается на одно существо, видимое в пределах 10 футов и обладающее мозгом. Цель должна пройти испытание Интеллекта СЛ 12 от этой магии, иначе получит урон психической энергией 11 (2к10). Кроме того, при провале бросьте 3к6: если сумма превысит значение Интеллекта цели, эта характеристика уменьшается до 0. Цель после этого становится ошеломлённой, пока не восстановит хотя бы 1 единицу Интеллекта.`,
    },
    {
      name: `Кража тела`,
      description: `★СУЩЕСТВО★ начинает состязание Интеллекта с недееспособным гуманоидом, находящимся в пределах 5 футов от него, не защищённым [Защитой от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}). Если ★он★ выиграет состязание, то магическим образом пожирает мозг цели, телепортируется в череп цели и захватывает контроль над телом. Находясь внутри существа, ★СУЩЕСТВО★ обладает полным укрытием от атак и прочих эффектов, исходящих снаружи. ★СУЩЕСТВО★ сохраняет свои значения Интеллекта, Мудрости и Харизмы, а также знание Глубинной Речи, телепатию и свои особенности. Вся остальная статистика берётся у цели. ★Он★ знает всё, что знало существо, включая заклинания и языки.

Если тело носителя умирает, ★СУЩЕСТВО★ ★должен★ покинуть его. Заклинание [Защита от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}), наложенное на тело, тоже изгоняет ★СУЩЕСТВО★. ★СУЩЕСТВО★ также должен покинуть тело цели, если та восстановит съеденный мозг заклинанием [Исполнение желаний](SPELL:${SPELL_WISH}). Потратив 5 футов перемещения, ★СУЩЕСТВО★ может добровольно покинуть тело, телепортируясь в ближайшее свободное пространство в пределах 5 футов. После этого тело умирает, если его мозг не восстановится в течение 1 раунда.`,
    },
  ],
  genderId: GENDER_MALE,
}
