module.exports = [
  {
    text: `Худшие. Танцоры. В мире.`,
    author: `сатир Риддлфиддл об ограх`,
  },
  {
    text: `Будучи грозными и жестокими, огры оказываются послушными слугами для тех, кто не страшиться быть безжалостным хозяином. Впрочем, я предпочитаю великанов.`,
    author: `Архимаг Морденкайнен`,
  },
]
