const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_STANDING_LEAP,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_GIANT_TOAD} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Гигантская жаба',
  nameEn: 'Giant Toad',
  id: CREATURE_GIANT_TOAD,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 322,
  },
  armor: 11,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 13,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  cr: CR_1,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 20,
      jumpHeight: 10,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      description: `Цель становится схваченной (СЛ высвобождения 13). Пока цель схвачена, она обездвижена, а жаба не может кусать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 10,
            diceBonus: 2,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 1,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Проглатывание',
      description: `★СУЩЕСТВО★ совершает одну атаку _Укусом_ по цели с размером не больше Среднего, которую уже держит в захвате. Если эта атака попадает, цель становится проглоченной, а захват оканчивается. Проглоченная цель ослеплена и обездвижена, и обладает полным укрытием от атак и прочих эффектов, исходящих снаружи, а также получает урон кислотой 10 (3к6) в начале каждого хода ★СУЩЕСТВО★. ★СУЩЕСТВО★ может иметь проглоченной только одну цель одновременно.

Если ★СУЩЕСТВО★ умирает, проглоченное существо перестаёт быть обездвиженым ★им★, и может высвободиться из трупа, потратив 5 футов перемещения, падая при выходе ничком.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
