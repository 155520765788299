module.exports = [
  require('./temple_of_the_gods'),
  require('./tensers_transformation'),
  require('./thunder_step'),
  require('./thunderclap'),
  require('./tidal_wave'),
  require('./tiny_servant'),
  require('./toll_the_dead'),
  require('./transmute_rock'),
]
