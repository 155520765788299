const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_STAR_SPAWN,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_SPELL,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26,
  CREATURE_STAR_SPAWN_SPIRIT,
  CREATURE_STAR_SPAWN_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух звёздного порождения',
  nameEn: 'Star Spawn Spirit',
  id: CREATURE_STAR_SPAWN_SPIRIT_2020_03_26,
  releasedAs: CREATURE_STAR_SPAWN_SPIRIT,
  parentId: CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_STAR_SPAWN,
  ],
  speed: {
    [SPEED_WALK]: 30,
  },
  featureList: [
    {
      name: 'Аура шёпотов',
      description: `Если ★СУЩЕСТВО★ ★дееспособен★, то в начале каждого его хода все существа в пределах 5 фт. от него должны пройти испытание Мудрости против сложности заклинаний заклинателя или получить 3к6 психического урона.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Психический удар',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: {
          value: 3,
          comment: `+ круг заклинания`,
        },
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
}
