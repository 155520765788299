const {SOURCE_AI} = require('./../../sourceList')
const {CREATURE_TYPE_VERDAN} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_VERDAN,
  nameEn: 'Verdan',
  genderId: GENDER_FEMALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'вердан',
        genitive: 'вердана',
        dative: 'вердану',
        accusative: 'вердана',
        instrumental: 'верданом',
        prepositional: 'вердане',
      },
      [GENDER_FEMALE]: {
        nominative: 'вердана',
        genitive: 'верданы',
        dative: 'верданe',
        accusative: 'вердану',
        instrumental: 'верданой',
        prepositional: 'вердане',
      },
    },
    plural: {
      nominative: 'верданы',
      genitive: 'вердан',
      dative: 'верданам',
      accusative: 'вердан',
      instrumental: 'верданами',
      prepositional: 'верданах',
    },
  },
  note: {
    text: `Реальность это озарённый краткими вспышками порядка спуск в хаос, из которого мы и возникли и потому считаем его нормальнее, чем посчитал бы сторонний наблюдатель. Тот-Кто-Терпит сдержал энтропию на краткий миг и возникли Верданы. Извините, а что Вы там спрашивали?`,
    author: `К’срисс Дроу’б`,
  },
  description: [
    {
      header: 'Верданы',
      text: `The verdan owe their existence to chaos. Descended and transformed from a large clan of goblins and hobgoblins, those who became the verdan were simply living their lives, doing goblinoid things. But then passing through the shadow of That-Which-Endures changed them forever. Now the newest race to call Faerun home, the verdan do their best to find their way in an unfamiliar world.`,
      source: {
        id: SOURCE_AI,
        page: 73,
      },
    },
    {
      header: 'Порождённые хаосом',
      text: `When the power of That-Which-Endures mutated the verdan, their skin was turned the color of jade and their blood began to flow black. Their ears grew pointed, and they gained a limited form of telepathy- but at the cost of forgetting their history. The underground homes of the goblins were places of terror to the new creatures those goblins became. and the verdan quickly fled to the surface and into the sunlight.

When they arrived in the new lands of the surface, the verdan found that they were often mistaken for smaller, green-skinned half-elves. Attracting curiosity but no real questions, they have thus been able to make their way reasonably freely in the world.`,
      source: {
        id: SOURCE_AI,
        page: 74,
      },
    },
    {
      header: 'Продолжающиеся мутации',
      text: `Because the verdan have not dwelled long in the world, they are still discovering new things about themselves. Chief among these discoveries is that their physical forms change as they age, signifying that the mutative power of That-Which-Endures is not done with them. The first verdan emerged from the Underdark as creatures of goblin stature. But they soon learned that their kind were fated to eventually undergo a dramatic, painful, and random growth spurt that sees them transformed to hobgoblin size over a period of days.

At the same time, many verdan undergo changes in coloring as they age. Male verdan typically have little to no hair, while females sport shocks of wiry hair that they try to tame in a variety of styles. But the color of any verdan's hair, skin. and eyes can transform from their original jade tones to pale white, deep ebon, or any other shade in between. A verdan's ears also undergo numerous spontaneous alterations over their lifetime, from the typical range of point and peak seen among the elves, to huge ears that sweep back from the head like wings. and which are often pierced behind the head with a single ring to keep them from flapping about.

Changes in gender are also a known and accepted part of verdan life. These fluid aspects of form and identity are seen as blessings, allowing an individual to experience more of the world and grow in empathy and understanding. There is no pattern to any verdan's mutations, as verdan from the same family can shift in drastically different ways. The verdan assign no cultural or biological relevance to any particular coloration. physical features, or gender. `,
      source: {
        id: SOURCE_AI,
        page: 74,
      },
    },
    {
      header: 'Тот-Кто-Терпит',
      text: `That-Which-Endures is a nameless, faceless, mysterious entity, something between a multiverse-spanning primordial spirit and an over-god. It represents the amoral and all·encompassing power of entropy- the force that unknowingly and uncaringly changes order into chaos, and that breaks matter and time down into its component parts so that all can be rebuilt. Some who study this entity claim that it is clearly evil and destructive. Others call it simply a necessary part of the cycle of death and rebirth. In the end, though, That-Which-Endures shows precious little interest in what people say about it.`,
      source: {
        id: SOURCE_AI,
        page: 74,
      },
    },
    {
      header: 'Культурные хамелеоны',
      text: `The clan homes of the Underdark goblinoids who became the verdan covered an enormous area. As the verdan fled to the surface world, they emerged in culturally diverse locations. Some found themselves near dwarven strongholds. others near elven enclaves, and others near the widespread human settlements of Faerun.

Without a cultural identity or memory of their own. the verdan quickly adopted the cultural practices of the areas into which they migrated. Still, a sense of not truly belonging is felt even by verdan who have been welcomed into other cultures. They are often stricken with wanderlust, keeping their possessions limited and easily portable. It is not unusual to see individual verdan or whole families trooping along the roads of the world with their tents and belongings strapped to their backs.

Even when they are happily steeped in a culture, the verdan remain on the lookout for oppression and curtailment of freedoms. They understand the need for laws that protect, but they rail against laws that restrict and oppress-especially those designed to protect the power and wealth of the elite. When Jiving among enlightened folk, verdan are still the first to speak out against cultural restraints on individuals, particularly those based on physical characteristics such as gender, race, or appearance.`,
      source: {
        id: SOURCE_AI,
        page: 74,
      },
    },
    {
      header: 'Большеглазые и любопытные',
      text: `Verdan are hungry to undertake new challenges and absorb new experiences. When they meet other verdan who have traveled to different parts of the world, they drop everything to spend as long as possible sharing stories and observations from their respective travels. However, their inexperience in the world and their racial amnesia sometimes lends the verdan a kind of innocence that works against them. Some a re credulous and easily fooled by hucksters, but most verdan have an intuition bred by empathy that helps them eventually sort out those who are sincere from those who are not.`,
      source: {
        id: SOURCE_AI,
        page: 75,
      },
    },
    {
      header: 'Имена верданов',
      text: `The oldest verdan names spring from goblinoid traditions. but more recent names reflect the character of the different cultures these folk have encountered since coming out into the sunlight. When a group of verdan live near dwarves, they might take on more dwarven-sounding names, only to change those names when they wander into a human area. A verdan is also not hesitant to change their name as their physical appearance changes-or, indeed, whenever the mood strikes them.

Verdan make no differentiation between male, female, and family names, and often eschew family names altogether.

# Verdan Names

Bronn, Crahma, Dolar, Dreeda, Duglee, Gruvald, Hulm,Jeal, Kato, Klesh, Korm, Lathi, Ovlig, Paracii. Pits, Praet, Promul, Reezni, Rin, Shylk, Slyra, Soito, Stalsii, Stromvo, Stussa, Syrkart, Takat, Toit, Tubyna, Varr, Veriga, Wraq, Wural, Wurxee`,
      source: {
        id: SOURCE_AI,
        page: 75,
      },
    },
    {
      header: 'Внутренние улучшения',
      text: `When a verdan character gains an ability score improvement at certain levels, that increase can be tied to a physical mutation at the player's determination. A boost to Strength might be accompanied by a growth spurt and the development of rippling muscle, while an increase in Intelligence might produce a stylishly la rge forehead. `,
      source: {
        id: SOURCE_AI,
        page: 75,
      },
    },
  ],
}
