const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SPELL_SUMMON_CONSTRUCT,
} = require('./../../../../spellIdList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ITS_SUMMONER,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_INCAPACITATED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_CLAY_CONSTRUCT_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух глиняного конструкта',
  nameEn: 'Clay Construct Spirit',
  id: CREATURE_CLAY_CONSTRUCT_SPIRIT,
  createdBySpellId: SPELL_SUMMON_CONSTRUCT,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: CREATURE_TYPE_CONSTRUCT,
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TCoE,
    page: 160,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `40 + 15-кратный круг заклинания выше 3-го`,
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 18,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 5,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_INCAPACITATED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_SUMMONER,
      onlyUnderstands: true,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  reactionList: [
    {
      name: 'Рубка берсерка',
      description: `Когда ★СУЩЕСТВО★ получает урон, оно совершает атаку _Ударом_ по случайному существу в пределах 5 футов. Если так близко существ нет, то ★СУЩЕСТВО★ передвигается на расстояние до половины своей скорости к видимому ★им★ противнику, не провоцируя атак.`,
    },
  ],
}
