const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_CLERIC_TEMPEST_DOMAIN} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')

const {
  SPELL_CALL_LIGHTNING,
  SPELL_CONTROL_WATER,
  SPELL_DESTRUCTIVE_WAVE,
  SPELL_FOG_CLOUD,
  SPELL_GUST_OF_WIND,
  SPELL_ICE_STORM,
  SPELL_INSECT_PLAGUE,
  SPELL_SHATTER,
  SPELL_SLEET_STORM,
  SPELL_THUNDERWAVE,
} = require('./../../../spellIdList')
const {
  FEATURE_BONUS_PROFICIENCIES_TEMPEST_DOMAIN,
  FEATURE_CHANNEL_DIVINITY_CLERIC,
  FEATURE_CHANNEL_DIVINITY_DESTRUCTIVE_WRATH,
  FEATURE_DIVINE_DOMAIN,
  FEATURE_DIVINE_STRIKE_TEMPEST_DOMAIN,
  FEATURE_STORMBORN,
  FEATURE_TEMPEST_DOMAIN_SPELLS,
  FEATURE_THUNDERBOLT_STRIKE,
  FEATURE_WRATH_OF_THE_STORM,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_TEMPEST_DOMAIN_SPELLS,
    name: `Заклинания домена`,
    nameEn: `Domain Spells`,
    lvl: 1,
    text: `Вы получаете заклинания домена.

Как работают заклинания домена, сказано в описании классового умения [Божественный домен](FEATURE:${FEATURE_DIVINE_DOMAIN}).`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_CALL_LIGHTNING,
      SPELL_CONTROL_WATER,
      SPELL_DESTRUCTIVE_WAVE,
      SPELL_FOG_CLOUD,
      SPELL_GUST_OF_WIND,
      SPELL_ICE_STORM,
      SPELL_INSECT_PLAGUE,
      SPELL_SHATTER,
      SPELL_SLEET_STORM,
      SPELL_THUNDERWAVE,
    ],
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
  {
    id: FEATURE_BONUS_PROFICIENCIES_TEMPEST_DOMAIN,
    name: `Бонусное владение`,
    nameEn: `Bonus Proficiencies`,
    lvl: 1,
    text: `Вы осваиваете владение воинским оружием и тяжёлыми доспехами.`,
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
  {
    id: FEATURE_WRATH_OF_THE_STORM,
    name: `Гнев бури`,
    nameEn: `Wrath of the Storm`,
    lvl: 1,
    text: `Вы можете громогласно покарать атакующих. Если существо в пределах 5 футов от Вас, которое Вы можете видеть, успешно попадает по вам атакой, Вы можете реакцией заставить существо пройти испытание Ловкости.

Существо получает урона звуком или электричеством (по Вашему выбору) 2к8, если провалит испытание, и половину этого урона если преуспеет.

Вы можете использовать это умение количество раз, равное Вашему модификатору Мудрости (минимум 1 раз). Вы восстанавливаете все потраченные применения, когда завершаете длинный отдых.`,
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_DESTRUCTIVE_WRATH,
    name: `Божественный канал: Разрушительный гнев`,
    nameEn: `Channel Divinity: Destructive Wrath`,
    lvl: 2,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_CLERIC}), чтобы овладеть могуществом бури с необузданной свирепостью.

Когда Вы совершаете бросок урона звуком или электричеством, Вы можете использовать _Разрушительный гнев_, чтобы причинить максимальный урон вместо броска.`,
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
  {
    id: FEATURE_THUNDERBOLT_STRIKE,
    name: `Удар грома`,
    nameEn: `Thunderbolt Strike`,
    lvl: 6,
    text: `Когда Вы причиняете урон электричеством существу с размером Большое или меньше, Вы также можете оттолкнуть его на 10 футов от себя.`,
    source: {
      id: SOURCE_PHB,
      page: 60,
    },
  },
  {
    id: FEATURE_DIVINE_STRIKE_TEMPEST_DOMAIN,
    name: `Божественный удар`,
    nameEn: `Divine Strike`,
    lvl: [8, 14],
    text: `Вы получаете способность наполнять удары своего оружия божественной энергией.

Один раз в каждый свой ход, когда Вы попадаете по существу атакой оружием, Вы можете причинить цели дополнительный урон звуком 1к8.

Когда Вы достигаете 14 уровня, дополнительный урон возрастает до 2к8.`,
    source: {
      id: SOURCE_PHB,
      page: 61,
    },
  },
  {
    id: FEATURE_STORMBORN,
    name: `Рождённый бурей`,
    nameEn: `Stormborn`,
    lvl: 17,
    text: `У Вас появляется скорость полёта, равная Вашей текущей наземной скорости передвижения, когда Вы не под землёй и не в помещении.`,
    source: {
      id: SOURCE_PHB,
      page: 61,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_CLERIC_TEMPEST_DOMAIN,
  })
)
