const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SPELL_DUST_DEVIL} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_DUST_DEVIL,
  name: 'Пылевой вихрь',
  nameEn: 'Dust Devil',
  description: `Выберите свободный 5-футовый куб воздуха, который Вы можете видеть в пределах дистанции заклинания. На время действия заклинания в этой области возникает стихийная сила, похожая на пылевой вихрь.

Все существа, которые заканчивают свой ход в пределах 5 футов от пылевого вихря, должны пройти испытание Силы. При провале существо получает дробящий урон 1к8 и отталкивается на 10 футов от пылевого вихря. При успешном испытании существо получает половину урона и не отталкивается.

Вы можете бонусным действием переместить пылевой вихрь на расстояние до 30 футов в любом направлении. Если он перемещается по песку, пыли, рыхлой грязи или гравию, то он всасывает часть вещества, создавая вокруг себя облако мусора радиусом 10 футов до начала Вашего следующего хода. Облако является сильно заслонённой местностью.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 3 круга или выше, то урон увеличивается на 1к8 за каждый круг ячейки выше 2.`,
  lvl: 2,
  magicSchoolId: MAGIC_CONJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `щепотка пыли`,
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_STR,
    damage: {
      type: DAMAGE_BLUDGEONING,
      halfOnSuccess: true,
      diceCount: 1,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 164,
    },
    {
      id: SOURCE_EE,
      page: 22,
    },
  ],
}
