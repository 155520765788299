const {GEAR_WAND} = require('./../../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MGC_TYPE_WAND} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    bonus,
    id,
    rarity,
  }
) => ({
  id,
  name: `Волшебная палочка боевого мага +${bonus}`,
  nameEn: `Wand of the War Mage +${bonus}`,
  rarity,
  type: MGC_TYPE_WAND,
  gearType: GEAR_WAND,
  attunementComment: `заклинателем`,
  attunement: true,
  description: `Держа эту палочку в руке, Вы получаете бонус +${bonus} к броскам атаки заклинаниями.

Кроме того, Вы игнорируете укрытие наполовину, когда совершаете атаки заклинаниями.`,
  genderId: GENDER_FEMALE,
  siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 154,
  },
})

module.exports = generator
