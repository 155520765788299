const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_CONTROL_WEATHER,
  SPELL_DETECT_MAGIC,
  SPELL_FEATHER_FALL,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_WATER_BREATHING,
} = require('./../../../../spellIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SKILL_ARCANA,
  SKILL_ATHLETICS,
  SKILL_HISTORY,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_GIANT_STORM,
} = require('./../../../../creatureTypeIdList')
const {
  giantStormNote,
  stormGiantDescriptionList,
} = require('./../../../../textCommonParts')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {
  GEAR_GREATSWORD,
  GEAR_SCALE_MAIL,
} = require('./../../../../gearIdList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CG} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_13} = require('./../../../../crList')
const {CREATURE_STORM_GIANT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Штормовой великан',
  nameAlt: [
    'Штормовой гигант',
    'Уваръётун',
  ],
  nameEn: 'Storm Giant',
  id: CREATURE_STORM_GIANT,
  note: giantStormNote,
  description: stormGiantDescriptionList,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_STORM,
  ],
  alignmentId: ALIGNMENT_CG,
  source: {
    id: SOURCE_MM,
    page: 40,
  },
  armor: {
    ac: 16,
    gearId: GEAR_SCALE_MAIL,
  },
  hp: {
    diceCount: 20,
  },
  speed: {
    [SPEED_WALK]: 50,
    [SPEED_SWIM]: 50,
  },
  params: {
    [PARAM_STR]: 29,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 20,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
  ],
  immunityList: [
    DAMAGE_THUNDER,
    DAMAGE_ELECTRICITY,
  ],
  languageList: [
    LANG_GIANT,
    LANG_COMMON,
  ],
  cr: CR_13,
  featureList: [
    ABILITY_AMPHIBIOUS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_LEVITATE,
          SPELL_DETECT_MAGIC,
          SPELL_FEATHER_FALL,
          SPELL_LIGHT,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_CONTROL_WEATHER,
          SPELL_WATER_BREATHING,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Двуручным мечом_.`,
    },
    {
      name: 'Двуручный меч',
      // gearId: GEAR_GREATSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 6,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 14,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 12,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Удар молнии',
      description: `★СУЩЕСТВО★ вызывает магическую молнию в точку, которую видит в пределах 500 футов. Все существа в пределах 10 футов от этой точки должны пройти испытание Ловкости СЛ 17, получая урон электричеством 54 (12к8) при провале, или половину этого урона при успехе.`,
      restore: 5,
    },
  ],
  genderId: GENDER_MALE,
}
