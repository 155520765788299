const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {ATTACK_RANGE_SPELL} = require('./../../../../../attackTypeList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {GEAR_SLING} = require('./../../../../../gearIdList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_MAGIC_STONE} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_MAGIC_STONE,
  name: 'Волшебный камень',
  nameEn: 'Magic Stone',
  description: `Вы касаетесь от 1 до 3 камней и наделяете их магической силой.

Вы или кто-либо ещё можете совершить дальнобойную атаку заклинанием, кинув один из этих камней или запустив его [пращёй](GEAR:${GEAR_SLING}). Дальность броска рукой составляет 60 футов. Если кто-либо другой атакует этим камнем, он использует для броска атаки Ваш модификатор заклинательной характеристики вместо своего.

При попадании цель получает дробящий урон, равный 1к6 + модификатор Вашей заклинательной характеристики. Вне зависимости от того, попал камень или нет, это заклинание перестаёт на него действовать.

Если Вы сотворяете это заклинание ещё раз, его эффект преждевременно заканчивается на тех камнях, что были зачарованы прежде.`,
  lvl: 0,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_MINUTE, count: 1},
  castTime: {timeId: TIME_BONUS_ACTION, count: 1},
  effect: {
    attackType: ATTACK_RANGE_SPELL,
    damage: {
      type: DAMAGE_BLUDGEONING,
      diceCount: 1,
      diceType: 6,
      diceBonus: 0,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 150,
    },
    {
      id: SOURCE_EE,
      page: 16,
    },
  ],
}
