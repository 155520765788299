const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_STR} = require('./../../../../../paramList')
const {SPELL_EARTHBIND} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_EARTHBIND,
  name: 'Узы земли',
  nameEn: 'Earthbind',
  description: `Выберите одно существо, видимое в пределах дистанции заклинания. Жёлтые полосы магической энергии обвиваются вокруг этого существа. Цель должна пройти испытание Силы, иначе его скорость полёта (если есть) будет снижена до 0 на время действия заклинания. Существо, которое находится в воздухе, снижается со скоростью 60 футов за раунд, пока не окажется на земле или не закончится заклинание.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 300,
  componentIdList: [CAST_VERBAL],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_STR,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 170,
    },
    {
      id: SOURCE_EE,
      page: 23,
    },
  ],
}
