const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_DUERGAR_SCREAMER} = require('./../../../../creatureIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {LANG_DWARVISH} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Двергарский крикун',
  nameAlt: 'Дуэргарский крикун',
  nameEn: 'Duergar Screamer',
  id: CREATURE_DUERGAR_SCREAMER,
  description: [
    `Двергарский крикун — конструкт, использующий энергию звуковой волны, способный стирать камни в порошок. Двергара, которого обвинили в распространении слухов или умыслов о заговоре своего начальства, лишают бороды и заключают в одно из этих приспособлений, а их продолжающиеся страдания преобразуются в псионическую энергию, которой подпитывается крикун.`,
    ...duergarDescription,
  ],
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 174,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 7,
    [PARAM_CON]: 12,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 5,
    [PARAM_CHA]: 5,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_DWARVISH,
      doNotSpeak: true,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Двигатель боли',
      description: `Раз в ход, существо, напавшее на крикуна может прицелиться в двергара, заточённого внутри механизма. Нападающий совершает броски атаки с помехой. Попав в цель, атака наносит дополнительных 11 (2к10) урона крикуну, и механизм может ответить реакцией, применив _Мультиатаку_.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Крикун совершает одну атаку _Сверлом_ и применяет _Пронзительный вопль_.`,
    },
    {
      name: 'Сверло',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 12,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Пронзительный вопль',
      description: `Крикун испускает разрушительную энергию в кубе со стороной 15 фт. Каждое существо, оказавшееся в этой зоне, должно пройти испытание Силы СЛ 11, или получить 7 (2к6) урона звуком и упасть ничком.`,
    },
  ],
}
