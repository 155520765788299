const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_MUMMY,
  CREATURE_MUMMY_LORD,
} = require('./../../../../creatureIdList')
const {
  mummyDescriptionList,
  mummyNote,
} = require('./../../../../textCommonParts')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_REMOVE_CURSE} = require('./../../../../spellIdList')

module.exports = {
  name: 'Мумия',
  nameEn: 'Mummy',
  id: CREATURE_MUMMY,
  description: mummyDescriptionList,
  note: mummyNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 216,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 15,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_3,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ может использовать _Ужасающий взгляд_ и совершить одну атаку _Разлагающим кулаком_.`,
    },
    {
      name: 'Разлагающий кулак',
      description: `Если цель — существо, она должна пройти испытание Телосложения СЛ 12, иначе станет проклятой гнилью мумии. Проклятая цель не может восстанавливать хиты, и её максимум хитов уменьшается на 10 (3к6) за каждые прошедшие 24 часа. Если проклятье уменьшает максимум хитов цели до 0, цель умирает, а её тело становится прахом. Проклятье длится до тех пор, пока не будет снято заклинанием [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) или другой магией.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Ужасающий взгляд',
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 60 футов. Если цель видит ★СУЩЕСТВО★, она должна пройти испытание Мудрости СЛ 11 от этой магии, иначе станет испуганной до конца следующего хода мумии. Если цель проваливает испытание на 5 и более единиц, она также становится парализованной на тот же период. Цель, преуспевшая в испытании, получает иммунитет к _Ужасающему взгляду_ всех мумий (но не [лорд-мумий](CREATURE:${CREATURE_MUMMY_LORD})) на следующие 24 часа.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
