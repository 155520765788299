const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {DAMAGE_FIRE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_MELF_S_MINUTE_METEORS} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MELF_S_MINUTE_METEORS,
  name: 'Мельфовы маленькие метеоры',
  nameEn: 'Melf’s Minute Meteors',
  description: `Вы создаёте вокруг себя шесть маленьких метеоров. Они парят в воздухе и вращаются вокруг вас, пока действует заклинание. Когда Вы сотворяете заклинание (а также в следующих ходах бонусным действием) Вы можете отправить один или два метеора в точки, выбранные в пределах 120 футов от вас. Долетев до цели или столкнувшись с твёрдой поверхностью, метеор взрывается. Все существа в пределах 5 футов от точки взрыва должны пройти испытание Ловкости. При провале существо получает урон огнём 2к6, а при успехе — половину урона.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 4 круга или выше, число призываемых метеоров увеличивается на 2 за каждый круг ячейки выше 3.`,
  lvl: 3,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `селитра, сера и шарик сосновой смолы`,
  duration: {timeId: TIME_MINUTE, count: 10},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_FIRE,
      halfOnSuccess: true,
      diceCount: 2,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 156,
    },
    {
      id: SOURCE_EE,
      page: 18,
    },
  ],
}
