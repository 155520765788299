module.exports = [
  ...require('./a'),
  ...require('./b'),
  ...require('./c'),
  ...require('./d'),
  ...require('./e'),
  ...require('./f'),
  ...require('./g'),
  ...require('./h'),
  ...require('./i'),
  ...require('./l'),
  ...require('./m'),
  ...require('./n'),
  ...require('./p'),
  ...require('./s'),
  ...require('./t'),
  ...require('./v'),
  ...require('./w'),
  ...require('./z'),
]
