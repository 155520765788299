const {
  SOURCE_GAME_BG_3,
  SOURCE_MTOF,
} = require('./../../sourceList')
const raven_queen_and_shadarKai = require('./raven_queen_and_shadarKai')

module.exports = [
  {
    header: 'Проклятые эльфы',
    text: `Шадар-каи когда-то были эльфами, но целые эпохи воздействия изнурительной среды Царства Теней сделали их печальными и угрюмыми созданиями. В этом измерении они имеют вид высохших эльфов: светлые волосы, морщинистая серая кожа и опухшие суставы дают им трупоподобный внешний вид. На других планах они выглядят помоложе, но их кожа всегда сохраняет свою мертвецкую бледноту. Шадар-каи носят тёмные плащи и тяжёлые маски, ненавидят зеркала и избегают тех вещей, что напоминают им о собственном возрасте.`,
    source: {
      id: SOURCE_MTOF,
      page: 239,
    },
  },
  {
    header: 'Шадар-каи',
    text: `Царство Теней изменило этих эльфов так, что они превратились в совершенно непохожих на себя безрадостных и угрюмых созданий, влачащих жалкое существование между жизнью и смертью.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  ...raven_queen_and_shadarKai,
]
