const {
  SPELL_BURNING_HANDS,
  SPELL_FIRE_BOLT,
  SPELL_FLAMING_SPHERE,
  SPELL_GUIDANCE,
  SPELL_HELLISH_REBUKE,
  SPELL_LIGHT,
  SPELL_MAGE_ARMOR,
  SPELL_MAGE_HAND,
  SPELL_PRESTIDIGITATION,
  SPELL_SCORCHING_RAY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_FIRENEWT,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DRACONIC,
  LANG_IGNAN,
} = require('./../../../../languageIdList')
const {
  fireNewtDescriptionList,
  fireNewtNote,
} = require('./../../../../textCommonParts')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_FIRENEWT_WARLOCK_OF_IMIX} = require('./../../../../creatureIdList')
const {GEAR_MORNINGSTAR} = require('./../../../../gearIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Огненный тритон колдун Имикса',
  nameEn: 'Firenewt Warlock of Imix',
  id: CREATURE_FIRENEWT_WARLOCK_OF_IMIX,
  description: fireNewtDescriptionList,
  note: fireNewtNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_FIRENEWT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 178,
  },
  armor: [
    10,
    {
      ac: 13,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 12,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 14,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
      comment: 'прозревает магическую тьму',
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_IGNAN,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Благословение Имикса',
      description: `Когда огненный тритон уменьшает хиты врага до 0, он получает 5 временных хитов.`,
    },
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            {
              id: SPELL_MAGE_ARMOR,
              comment: 'только на себя',
            },
          ],
        },
      ],
    },
    {
      spellCasterLevel: 3,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_FIRE_BOLT,
            SPELL_GUIDANCE,
            SPELL_LIGHT,
            SPELL_MAGE_HAND,
            SPELL_PRESTIDIGITATION,
          ],
        },
        {
          preText: `1–2 уровни (2 ячейки 2-го уровня)`,
          list: [
            SPELL_BURNING_HANDS,
            SPELL_FLAMING_SPHERE,
            SPELL_HELLISH_REBUKE,
            SPELL_SCORCHING_RAY,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      gearId: GEAR_MORNINGSTAR,
    },
  ],
}
