const {
    SOURCE_HOTDQ,
  } = require('./../../../../sourceList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAKE,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    LANG_DRACONIC,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_PACK_TACTICS,
    ABILITY_SURPRISE_ATTACK,
  } = require('./../../../../creatureAbilityList'),
  {
    guardDrakeDescription,
  } = require('./../../../../textCommonParts'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_AMBUSH_DRAKE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Охотничий Дрейк',
  nameEn: 'Ambush Drake',
  id: CREATURE_AMBUSH_DRAKE,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
    CREATURE_TYPE_DRAKE,
  ],
  description: guardDrakeDescription,
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_HOTDQ,
    page: 98,
  },
  armor: 13,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_DRACONIC,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_PACK_TACTICS,
    {
      id: ABILITY_SURPRISE_ATTACK,
      damage: `7 (2к6)`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
  ],
}
