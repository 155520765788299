module.exports = [
  {
    text: `Культисты, порицающие реальность, взывая к Древнему Злу, зачастую говорят о Дальнем Пределе, как о месте, откуда прибыли эти сущности. По правде говоря, нет ни места, ни пространства, из которого они появились. Есть мультивселенная вещей, что существует, а есть мультивселенная вещей, которая не должна была существовать.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Не звёзды породили этих созданий. Незачем обвинять столь восхитительные небесные светила за столь мерзких отродий.`,
    author: `Архимаг Морденкайнен`,
  },
]
