const {GOD_GRUUMSH} = require('./../../godIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  PC_RACE_HALF_ORC,
  PC_RACE_HUMAN,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')

module.exports = {
  header: 'Метка Груумша',
  text: `Одноглазый бог [Груумш](GOD:${GOD_GRUUMSH}) создал [орков](PC_RACE:${PC_RACE_ORC}), и даже те [орки](PC_RACE:${PC_RACE_ORC}), кто отвернулся от поклонения ему, не могут полностью избежать его влияния. То же самое можно сказать и про [полуорков](PC_RACE:${PC_RACE_HALF_ORC}), хотя их [человеческая](PC_RACE:${PC_RACE_HUMAN}) кровь и снижает воздействие орочьего наследия. Некоторые [полуорки](PC_RACE:${PC_RACE_HALF_ORC}) слышат шёпот [Груумша](GOD:${GOD_GRUUMSH}) в своих снах, взывающий высвободить ярость, что вскипает в них. Другие чувствуют ликование [Груумша](GOD:${GOD_GRUUMSH}) когда вступают в бой и либо ликуют вместе с ним, либо трясутся от страха и ненависти. [Полуорки](PC_RACE:${PC_RACE_HALF_ORC}) по природе своей не являются злыми, но зло скрывается в них, принимают ли они его или сопротивляются ему.

В ярости [Груумша](GOD:${GOD_GRUUMSH}) [полуорки](PC_RACE:${PC_RACE_HALF_ORC}) чувствуют сильное возбуждение. Ярость не только ускоряет их пульс, она заставляет их тела пылать. Колкие оскорбления действуют на них как яд, и печаль истощает их силы. Но когда они смеются, то делают это громко и от души, и простые физические наслаждения — пиры, выпивка, борьба, барабанный бой и дикие пляски — наполняют их сердца радостью. Они, как правило, вспыльчивы, иногда угрюмы, более склонны к действию, чем к созерцанию и к борьбе, чем к спору. Наиболее культурными [полуорками](PC_RACE:${PC_RACE_HALF_ORC}) являются те, у кого достаточно самоконтроля, чтобы проживать в цивилизованных землях.`,
  source: {
    id: SOURCE_PHB,
    page: 41,
  },
}
