module.exports = [
  require('./warhorse'),
  require('./warhorse_skeleton'),
  require('./water_elemental'),
  require('./water_weird'),
  require('./weasel'),
  require('./werebear'),
  require('./wereboar'),
  require('./wererat'),
  require('./weretiger'),
  require('./werewolf'),
  require('./wight'),
  require('./will_o_wisp'),
  require('./winged_kobold'),
  require('./winter_wolf'),
  require('./wolf'),
  require('./worg'),
  require('./wraith'),
  require('./wyvern'),
]
