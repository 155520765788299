const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_POISON,
} = require('./../../../../../damageTypeList')
const {
  MAGIC_ITEM_DRAGON_SCALE_MAIL_BLACK,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_BLUE,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_BRASS,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_BRONZE,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_COPPER,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_GOLD,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_GREEN,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_RED,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_SILVER,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_WHITE,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_WHITE,
    dragonTypeNameRu: {
      nominative: 'белый',
      genitive: 'белого',
    },
    dragonTypeNameEn: 'white',
    damageTypeId: DAMAGE_COLD,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_BRONZE,
    dragonTypeNameRu: {
      nominative: 'бронзовый',
      genitive: 'бронзового',
    },
    dragonTypeNameEn: 'bronze',
    damageTypeId: DAMAGE_ELECTRICITY,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_GREEN,
    dragonTypeNameRu: {
      nominative: 'зелёный',
      genitive: 'зелёного',
    },
    dragonTypeNameEn: 'green',
    damageTypeId: DAMAGE_POISON,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_GOLD,
    dragonTypeNameRu: {
      nominative: 'золотой',
      genitive: 'золотого',
    },
    dragonTypeNameEn: 'gold',
    damageTypeId: DAMAGE_FIRE,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_RED,
    dragonTypeNameRu: {
      nominative: 'красный',
      genitive: 'красного',
    },
    dragonTypeNameEn: 'red',
    damageTypeId: DAMAGE_FIRE,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_BRASS,
    dragonTypeNameRu: {
      nominative: 'латунный',
      genitive: 'латунного',
    },
    dragonTypeNameEn: 'brass',
    damageTypeId: DAMAGE_FIRE,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_COPPER,
    dragonTypeNameRu: {
      nominative: 'медный',
      genitive: 'медного',
    },
    dragonTypeNameEn: 'copper',
    damageTypeId: DAMAGE_ACID,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_SILVER,
    dragonTypeNameRu: {
      nominative: 'серебряный',
      genitive: 'серебряного',
    },
    dragonTypeNameEn: 'silver',
    damageTypeId: DAMAGE_COLD,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_BLUE,
    dragonTypeNameRu: {
      nominative: 'синий',
      genitive: 'синего',
    },
    dragonTypeNameEn: 'blue',
    damageTypeId: DAMAGE_ELECTRICITY,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_BLACK,
    dragonTypeNameRu: {
      nominative: 'чёрный',
      genitive: 'чёрного',
    },
    dragonTypeNameEn: 'black',
    damageTypeId: DAMAGE_ACID,
  },
]
