const {PC_SUBCLASS_WIZARD_PSIONICS_2019_11_25} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS} = require('./../../../sourceList')
const {
  FEATURE_MENTAL_DISCIPLINE,
  FEATURE_PSIONIC_DEVOTION,
} = require('./../../../featureIdList')
const {
  SPELL_DOMINATE_PERSON,
  SPELL_FRIENDS,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_SCRYING,
  SPELL_TELEKINESIS,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_PSIONIC_DEVOTION,
    name: `Приверженность псионике`,
    nameEn: `Psionic Devotion`,
    lvl: 2,
    pcSubClassId: PC_SUBCLASS_WIZARD_PSIONICS_2019_11_25,
    spellIdList: [
      SPELL_FRIENDS,
      SPELL_MAGE_HAND,
      SPELL_MESSAGE,
    ],
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 4,
    },
  },
  {
    id: FEATURE_MENTAL_DISCIPLINE,
    name: `Дисциплина ума`,
    nameEn: `Mental Discipline`,
    lvl: 10,
    pcSubClassId: PC_SUBCLASS_WIZARD_PSIONICS_2019_11_25,
    spellIdList: [
      SPELL_DOMINATE_PERSON,
      SPELL_SCRYING,
      SPELL_TELEKINESIS,
    ],
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 4,
    },
  },
]
