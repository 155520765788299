module.exports = [
  {
    text: `Свежеватели разума проводили всевозможные отвратительные эксперименты на разных планах существования, не особо задумываясь о последствиях. Здесь, я полагаю, вмешалась другая сущность — Джуиблекс`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Облекс желает воспоминаний, но не преследует цель создавать их. Облексы жаждут воспоминаний и личностных черт, потому что они пусты без подобной пищи. Таким образом, они служат своим создателям — иллитидам. Облекс, прислуживая старшему мозгу, предоставляет всё необходимое для свежевателей разума, чтобы найти жертв себе по вкусу`,
    author: `Архимаг Морденкайнен`,
  },
]
