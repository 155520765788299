const {
    SOURCE_DMG,
  } = require('./../../../../sourceList'),
  {
    CR_0,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BEAST,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_EBONY_FLY,
  } = require('./../../../../magicItemIdList'),
  {
    CREATURE_GIANT_FLY,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Гигантская муха',
  nameEn: 'Giant Fly',
  description: `Превращается из _[Статуэтки чудесной силы: Эбеновая муха](MAGIC_ITEM:${MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_EBONY_FLY})_`,
  id: CREATURE_GIANT_FLY,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_DMG,
    page: 204,
  },
  armor: 11,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 13,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 3,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_0,
}
