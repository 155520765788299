const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_HUGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_UNDEAD_FORTITUDE,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_ANKYLOSAURUS_ZOMBIE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Анкилозавр-зомби',
  nameEn: 'Ankylosaurus Zombie',
  id: CREATURE_ANKYLOSAURUS_ZOMBIE,
  description: `Это существо медлительно и едва осознает своё окружение.`,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 227,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 15,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 3,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      id: ABILITY_UNDEAD_FORTITUDE,
      damageType: `излучением`,
    },
  ],
  actionList: [
    {
      name: 'Хвост',
      description: `Если цель — существо, она должна пройти испытание Силы СЛ 14, иначе будет сбита с ног.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
}
