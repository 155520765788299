const {
  CREATURE_TYPE_KRUTHIK,
  CREATURE_TYPE_MONSTROSITY,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_CLIMB,
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  kruthikDescription,
  kruthikNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_KEEN_SMELL,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_KRUTHIK_ADULT,
  CREATURE_KRUTHIK_YOUNG,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_2} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {LANG_KRUTHIK} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Взрослый крутик',
  nameEn: 'Adult Kruthik',
  id: CREATURE_KRUTHIK_ADULT,
  description: [
    `Требуется шесть месяцев постоянного питания для того, чтобы [молодой крутик](CREATURE:${CREATURE_KRUTHIK_YOUNG}) достиг взрослых размеров. Естественная продолжительность жизни взрослого крутика составляет примерно семь лет.
      У взрослых крутиков на ножках вырастают остроконечные выступы, которые они могут метать во врагов в пределах досягаемости собственных когтей.`,
    kruthikDescription,
  ],
  note: kruthikNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_KRUTHIK,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MTOF,
    page: 204,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_DIG]: 20,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 8,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  languageList: [
    LANG_KRUTHIK,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_KEEN_SMELL,
    ABILITY_PACK_TACTICS,
    {
      name: 'Прокладывание туннеля',
      description: `Крутик может копать сквозь сплошной камень со скоростью копания, уменьшенной вдвое, оставляя за собой туннель диаметром 5 футов.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Крутик совершает два атаки _Уколом_ или две атаки _Шипами_.`,
    },
    {
      name: 'Укол',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Шипы',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 5,
        range: {
          normal: 20,
          max: 60,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
