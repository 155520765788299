import PropTypes from 'prop-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import remark2react from 'remark-react'

import GatsbyLink from '@/components/GatsbyLink'
import { TipBlock, TipHead, TipBody } from '@/components/Tip'
import {conditionCollection} from '@/constants/conditionList'
import mdCustomBlock from '@/utils/mdCustomBlock'
import arrify from 'arrify'

const tipOptions = {
  title: 'optional',
}

// Формат использования
// |невидимым|COND:${CONDITION_INVISIBLE}|

const ConditionTip = ({children}) => {
  if (
    children
    && children[0]
    && children[0].props
    && children[0].props.value
  ) {
    const conditionId = String(children[0].props.value).trim()

    if (conditionId) {
      const condition = conditionCollection[conditionId]

      if (condition) {
        const {name, description} = condition

        return (
          <TipBody
            name={name}
            description={description}
          />
        )
      }
    }
  }

  return null
}

const MarkdownComponent = ({ children, ...rest}) => (
  <>
    {
      arrify(children).map(
        (child, i) => typeof child === 'object'
          ? child
          : (
            <ReactMarkdown
              key={`Markdown_${i}`}
              plugins={[
                remark2react,
                [
                  mdCustomBlock,
                  {
                    COND: tipOptions,
                  }
                ]
              ]}
              renderers={{
                link: GatsbyLink,
                CONDCustomBlock: TipBlock,
                CONDCustomBlockHeading: TipHead,
                CONDCustomBlockBody: ConditionTip,
              }}
              {...rest}
            >
              {child}
            </ReactMarkdown>
          )
      )
    }
  </>
)

MarkdownComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
}

export default MarkdownComponent
