const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_6,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  GEAR_HOLY_WATER,
} = require('./../../../../gearIdList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  demonDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_VROCK,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Врок',
  nameAlt: 'Врак',
  nameEn: 'Vrock',
  id: CREATURE_VROCK,
  description: [
    `Вроки — тупые, капризные исчадия, живущие только ради боли и резни. Врок напоминает гигантскую помесь гуманоида и стервятника, он скрючен, его чудовищное тело и огромные крылья воняют падалью.

Вроки пожирают плоть гуманоидов каждый раз, как только представляется такая возможность, ошеломляя потенциальную добычу оглушительным визгом, а затем бросаются вниз, чтобы атаковать клювом и когтями. Вроки могут взмахивать крыльями, выпуская в воздух облака токсичных спор.

Красивые вещи заставляют вроков драться друг с другом за обладание дешёвыми украшениями и поделочными камнями. Однако, не смотря на любовь к сокровищам, вроков трудно подкупить. Они не видят смысла с кем-либо торговаться, если можно забрать то, что хочется, с трупа торгующегося.`,
    ...demonDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 87,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 18,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 8,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_6,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Клювом_, и одну _Когтями_.`,
    },
    {
      name: 'Клюв',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Споры',
      description: `★СУЩЕСТВО★ испускает облако токсичных спор с радиусом 15 футов. Споры огибают углы. Все существа в этой области должны пройти испытание Телосложения СЛ 14, иначе станут отравленными. Будучи отравленной таким образом, цель получает урон ядом 5 (1к10) в начале каждого своего хода. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. Если на цель вылить флакон [святой воды](GEAR:${GEAR_HOLY_WATER}), эффект на ней тоже закончится.`,
      restore: 6,
    },
    {
      name: 'Ошеломляющий вопль',
      description: `★СУЩЕСТВО★ испускает жуткий вопль. Все существа в пределах 20 футов от него, слышащие ★его★, и не являющиеся демонами, должны пройти испытание Телосложения СЛ 14, иначе станут ошеломлёнными до конца следующего хода ★СУЩЕСТВО★.`,
      limit: {
        count: 1,
        period: 'день',
      },
    },
  ],
  genderId: GENDER_MALE,
}
