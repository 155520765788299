const {
  SPELL_CONJURE_ANIMALS,
  SPELL_DRUIDCRAFT,
  SPELL_ENTANGLE,
  SPELL_FOG_CLOUD,
  SPELL_HEAT_METAL,
  SPELL_PLANT_GROWTH,
  SPELL_PRODUCE_FLAME,
  SPELL_SPIKE_GROWTH,
  SPELL_THORN_WHIP,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_CROCODILE,
  CREATURE_LIZARDFOLK_SHAMAN,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_LIZARDFOLK,
} = require('./../../../../creatureTypeIdList')
const {
  lizardfolkNotes,
  lizardfolkDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_CHANGE_SHAPE,
  ABILITY_HOLD_BREATH,
} = require('./../../../../creatureAbilityList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_2} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_DRACONIC} = require('./../../../../languageIdList')
const {PC_CLASS_DRUID} = require('./../../../../pcClassIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Людоящер шаман',
  nameEn: 'Lizardfolk Shaman',
  note: lizardfolkNotes,
  description: lizardfolkDescriptionList,
  id: CREATURE_LIZARDFOLK_SHAMAN,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_LIZARDFOLK,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 198,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 13,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  languageList: [
    LANG_DRACONIC,
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_HOLD_BREATH,
      time: '15 минут',
    },
  ],
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_DRUID,
    spellIdList: [
      SPELL_DRUIDCRAFT,
      SPELL_ENTANGLE,
      SPELL_FOG_CLOUD,
      SPELL_HEAT_METAL,
      SPELL_SPIKE_GROWTH,
      SPELL_PRODUCE_FLAME,
      SPELL_THORN_WHIP,
      SPELL_PLANT_GROWTH,
      {
        id: SPELL_CONJURE_ANIMALS,
        comment: 'только рептилии',
      },
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в форме людоящера',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Когтями_.`,
    },
    {
      name: 'Укус',
      description: `Если ★СУЩЕСТВО★ находится в форме [крокодила](CREATURE:${CREATURE_CROCODILE}), а цель — существо с размером не больше Большого, то цель становится схваченной (СЛ высвобождения 12). Пока цель схвачена, она обездвижена, и ★СУЩЕСТВО★ не может кусать другую цель. Если ★СУЩЕСТВО★ принимает свою истинную форму, захват оканчивается.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 6,
              diceBonus: 2,
            },
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 10,
              diceBonus: 2,
              comment: ` в форме [крокодила](CREATURE:${CREATURE_CROCODILE})`,
            },
          ],
        ],
      },
    },
    {
      name: 'Когти',
      comment: 'только в форме людоящера',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      id: ABILITY_CHANGE_SHAPE,
      nextForm: `[крокодила](CREATURE:${CREATURE_CROCODILE}), оставаясь в этой форме до 1 часа`,
      gearMerge: `не превращается`,
      statChange: `Он может вернуть свою истинную форму бонусным действием. Все его статистики, кроме размера, остаются одинаковыми во всех формах`,
    },
  ],
  genderId: GENDER_MALE,
}
