const {SOURCE_UA_2019_08_15_WILD_ASTRAL} = require('./../../../sourceList')
const {SPELL_DETECT_MAGIC} = require('./../../../spellIdList')
const {PC_SUBCLASS_BARBARIAN_PATH_OF_THE_WILD_SOUL_2019_08_15} = require('./../../../pcSubClassIdList')

const {
  FEATURE_LINGERING_MAGIC,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_LINGERING_MAGIC,
    name: `Давняя магия`,
    nameEn: `Lingering Magic`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_WILD_SOUL_2019_08_15,
    text: `At 3rd level, your body reacts to the presence of magic. You can cast the Detect magic spell without using a spell slot or components. Constitution is your spellcasting ability for this spell. You faintly glow a color corresponding to the school of magic you detect (you choose the colors).

You can use this feature a number of times equal to your Constitution modifier (minimum of once). You regain all expended uses when you finish a long rest.`,
    spellIdList: SPELL_DETECT_MAGIC,
    source: {
      id: SOURCE_UA_2019_08_15_WILD_ASTRAL,
      page: 1,
    },
  },
  // {
  //   id: FEATURE_,
  //   name: ``,
  //   nameEn: ``,
  //   lvl: ,
  //   pcSubClassId: PC_SUBCLASS_,
  //   text: ``,
  //   spellIdPickByLvl: ,
  //   spellCircleLvl: ,
  //   spellIdList: SPELL_,
  //   source: {
  //     id: SOURCE_,
  //     page: ,
  //   },
  // },
]
