const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_BEHOLDER_KEEN,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  CREATURE_BEHOLDER,
  CREATURE_GAZER,
} = require('./../../../../creatureIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ABILITY_AGGRESSIVE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {beholderLikeDescription} = require('./../../../../textCommonParts')
const {CONDITION_PRONE} = require('./../../../../conditionList')
const {CR_1_2} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Смотрящий',
  nameEn: 'Gazer',
  id: CREATURE_GAZER,
  description: [
    {
      header: 'Смотрящий',
      text: `Смотрящий — это крошечное проявление снов [бехолдеров](CREATURE:${CREATURE_BEHOLDER}). Он напоминает бехолдера, который вызвал его существование, но его тело всего 8 дюймов в ширину и имеет только четыре глазных стебелька. Он следует за создателем как преданная агрессивная собачонка и иногда небольшие своры эти существ патрулируют логово своего хозяина, убивают вредителей и распугивая одиноких существ.`,
      source: {
        id: SOURCE_VGTM,
        page: 128,
      },
    },
    {
      header: 'Неприятный питомец',
      text: `Смотрящий не может говорить ни на одном языке, но может примерно мимикрировать слова и предложения писклявой пародией. Бехолдеры считают смотряших забавными и терпят их присутствие как испорченных питомцев. Смотрящего не может приручить никто кроме его создателя, за исключением использования магии или связывания с заклинателем. Некоторые бехолдеры с волшебниками-приспешниками настаивают, чтобы волшебник взял смотрящего в качестве фамильяра, потому что могут видеть глазами этих существ.`,
      source: {
        id: SOURCE_VGTM,
        page: 128,
      },
    },
    {
      header: 'Агрессивный поедатель вредителей',
      text: `Дикий смотрящий (живущий отдельно от бехолдера) является территориальным существом, ест жуков и маленьких животных и известно, что любит играть с едой. Одинокий смотрящий избегает драк со Средними или еще более крупными существами, но их свора может позариться и на большую добычу. Смотрящий может следовать за гуманоидом на своей территории, шумно подражая их речи и в общем ведя себя неприятно, но сбегает, если на него нападает кто-то, кого он не может убить.`,
      source: {
        id: SOURCE_VGTM,
        page: 128,
      },
    },
    beholderLikeDescription,
  ],
  note: {
    text: `В Зияющем Портале я повстречал волшебницу, у которой был питомец — крошечный бехолдер. Она называла его Глазун. Когда я подобрался поближе, чтобы его потрогать, то существо ударило меня своим лучом из глаз и я ударился о стену с такой силой, что чуть не пролил свой эль.`,
    author: `Воло`,
  },
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_BEHOLDER_KEEN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 128,
  },
  armor: 13,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 3,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 14,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_AGGRESSIVE,
    {
      name: 'Мимикрия',
      description: `★СУЩЕСТВО★ может подражать звукам речи, которую когда-либо слышал, на любом языке. Существо, которое слышит эти звуки, может определить, что это имитация при успешной проверке Мудрости (Проницательности) СЛ 10.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 0,
          diceType: 0,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Лучи из глаз',
      description: `★СУЩЕСТВО★ испускает два магических луча из глаз, выбранных случайным образом (перебрасывая повторяющиеся результаты), выбирая одну или две цели, видимых в пределах 60 футов:

1. **Изумляющий луч.** Цель должна пройти испытание Мудрости СЛ 12 или стать Очарованной до начала следующего хода ★СУЩЕСТВО★. Пока цель очарована таким образом, её скорость уменьшена вдвое, и она получает помеху на броски атаки.
2. **Луч страха.** Цель должна пройти испытание Мудрости СЛ 12 или стать Испуганной до начала следующего хода смотрящего.
3. **Морозный луч.** Цель должна пройти испытание Ловкости СЛ 12 или получить 10 (3к6) урона холодом.
4. **Телекинетический луч.** Если цель — существо Среднего размера или меньше, то она должна пройти испытание Силы СЛ 12 или сдвинется на 30 футов по прямой от ★СУЩЕСТВО★. Если цель — предмет весом менее 10 фунтов, который никто не несёт и не носит, то ★СУЩЕСТВО★ может сдвинуть его на 30 футов в любом направлении. ★СУЩЕСТВО★ также может управлять объектами таким лучом, например пользоваться простым инструментом или открыть контейнер.`,
    },
  ],
}
