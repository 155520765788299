const {
  CREATURE_GITHYANKI_WARRIOR,
  CREATURE_GITHZERAI_MONK,
  CREATURE_MIND_FLAYER,
} = require('./../../creatureIdList')
const {
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')
const tirsuDescription = require('./tirsu')

module.exports = [
  {
    header: 'Гиты',
    text: `Воинственные [гитьянки](CREATURE:${CREATURE_GITHYANKI_WARRIOR}) и задумчивые [гитцераи](CREATURE:${CREATURE_GITHZERAI_MONK}) это разделившийся народ — две культуры, презирающие друг друга. До того, как они разделились, эти существа были одной расой, порабощённой свежевателями разума. Хотя они не раз пробовали свергнуть своих хозяев, их восстания быстро подавлялись, пока не появилась великая предводительница по имени Гит.

Пролив немало крови, Гит и её последователи сбросили с себя оковы ига [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}), но после боя возник другой лидер по имени Зертимон. Он поставил под сомнение мотивы Гит, утверждая, что её прямое военное руководство и жажда мести — все равно, что рабство для её народа. Меж последователями двух лидеров возник раскол, так они и стали двумя расами, чья вражда не кончается по сей день.

Никто уже не сможет сказать были ли эти высокие, худощавые существа миролюбивыми или свирепыми, культурными или примитивными до того, как их поработили и изменили свежеватели разума. Даже изначальное название их расы забылось с тех далёких времён.`,
    source: {
      id: SOURCE_MM,
      page: 56,
    },
  },
  {
    header: 'Гиты',
    text: `Потомки древних людей — настолько древних, что их истинное имя было утеряно. Они обратились друг против друга и стали заклятыми врагами, что разделились порознь ради гибели, целей и махинацией, проделанных их собственными лидерами.

Воинственные [гитьянки](CREATURE:${CREATURE_GITHYANKI_WARRIOR}) держат в страхе весь Астральный План, совершая набеги на другие миры ради кражи богатств и магии мультивселенной.

[Гитцераи](CREATURE:${CREATURE_GITHZERAI_MONK}) живут вдали от остального космоса, находясь в собственных крепостях, парящих по беспорядочным краям Лимбо.

Хоть две группы гитов и презирают друг друга, у них всё же есть общий ненавистный враг — [свежеватели разума](CREATURE:${CREATURE_MIND_FLAYER}), существа, от которых они когда-то сбежали, и теперь гитцераи и гитьянки посвятили свою жизнь поимке своих исконных врагов.`,
    source: {
      id: SOURCE_MTOF,
      page: 124,
    },
  },
  {
    header: 'Гиты и их бесконечная война',
    text: `История гитов уходит корнями в жесточайший поворот судьбы космоса. Вдохновлённые великим вождём, которая и дала имя расе, гиты восстали, чтобы свергнуть поработивших их [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}).

Однако, завоевав свободу, две фракции гитов не смогли прийти к согласию о том, какую цивилизацию они будут строить. Это разногласие быстро перешло в открытую враждебность, и две группы отдалились друг от друга, чтобы преследовать собственные цели. Сейчас они остаются жесточайшими врагами, и каждая сторона готова сражаться до смерти, если их пути пересекутся.

Гитьянки были движимы местью и убеждением, что имеют право забирать всё, что хотят, с миров, по которым пройдутся. Из титанического города Ту’нарат, который находится на Астральном плане, их рейдеры отправляются на разграбление Материального плана и иных миров, принося сокровища и рабов в своё неподвластное времени государство. В то же время они охотятся на [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), по возможности убивая их в качестве мести за то, что [иллитиды](CREATURE:${CREATURE_MIND_FLAYER}) сделали с ними в прошлом.

Гитцераи верят, что путь просвещённой цивилизации лежит в уединении, а не в конфликте. Их преданность принципам порядка настолько сильна, что они могут манипулировать хаосом используя его в своих интересах; так они вырезали себе полностью изолированные крепости на плане Лимбо. Несмотря на то, что гитцераи по своей натуре пацифисты, они разделяют расовую ненависть гитьянки к [свежевателям разума](CREATURE:${CREATURE_MIND_FLAYER}) и иногда отправляют отряды для уничтожения форпостов [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}).

Если эти две расы объединятся против [иллитидов](CREATURE:${CREATURE_MIND_FLAYER}), объединённая сила гитов могла бы склонить чашу весов в их пользу. Но до тех пор, пока гитьянки и гитцераи не отпустят глотки друг друга, достижение окончательной победы над изначальным общим врагом не представляется возможным.`,
    source: {
      id: SOURCE_MTOF,
      page: 87,
    },
  },
  tirsuDescription,
  {
    header: 'Ша’сал Кхоу',
    text: `_Ша’сал Кхоу_ – это организация радикалов из гитцераев и гитьянки, ставящих своей целью объединение их народов. Они хотят положить конец войне и объединить их в новую, единую нацию гитов. Члены _Ша’сал Кхоу_ тайно работают в своих обществах, тонко препятствуя нападениям на другую расу гитов и разыскивая единомышленников, которые могут быть завербованы в их ряды. У группировки также есть скрытые опорные пункты на Материальном плане.

Цель организации состоит в том, чтобы собрать достаточную армию и создать укреплённый анклав, где дети их будут расти, называя себя просто «гитами». Самый высокопоставленный из представителей группы в иерархии гитьянки – могущественный военачальник по имени Зетчерр. Он тайно поддерживает объединение гитьянки и гитцераев и работает за кулисами, чтобы направить окружающих к этой цели.`,
    source: {
      id: SOURCE_MTOF,
      page: 99,
    },
  },
]
