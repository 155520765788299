const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ELEMENTAL,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_TERRAN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  ABILITY_STONE_CAMOUFLAGE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_XORN,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Зорн',
  nameEn: 'Xorn',
  id: CREATURE_XORN,
  description: [
    {
      header: 'Зорны',
      text: `Странные существа со Стихийного Плана Земли, зорны вынюхивают драгоценные камни и металлы, после чего пробираются через землю и скалы, чтоб поглотить эти сокровища. На Материальном плане зорну приходится много перемещаться по Подземью, чтоб прокормить себя, поэтому, если в их рационе не хватает ценных минералов, они становятся агрессивными к шахтёрам и искателям сокровищ.

На неестественное происхождение зорнов намекают их необычайно тяжёлое тело и огромный мощный рот, расположенный на вершине головы. Три его длинные руки заканчиваются острыми когтями, а его три больших глаза с каменными веками смотрят во всех направлениях.`,
      source: {
        id: SOURCE_MM,
        page: 165,
      },
    },
    {
      header: 'Стихийные путешественники',
      text: `Обладающие силой стихийной земли зорны скользят сквозь камень и грязь так же легко, как рыба плавает в воде. При движении они не перемещают землю или камни, а сливаются с ней и проходят, не оставляя ни туннелей, ни дыр, ни намёка на своё присутствие.

Зорны предпочитают не покидать свой родной план, где они с лёгкостью могут поедать запасы драгоценных камней и металлов. Когда зорн попадает на Материальный план, будь то случайно или из любопытства, он в равной мере ищет пропитание и путь домой.`,
      source: {
        id: SOURCE_MM,
        page: 165,
      },
    },
    {
      header: 'Воры и попрошайки',
      text: `Зорны блуждают в недрах земли в поисках драгоценных металлов и камней. Из- за неспособности поглощать органическую материю, они игнорируют прочих существ. Однако умение зорна унюхать металлы и камни часто привлекает его внимание к искателям приключений, имеющим при себе монеты и драгоценные камни. Так как зорны не злые, они выпрашивают или торгуются, предлагая информацию, полученную ими в путешествии, в обмен на сокровища. При отказе зорны переходят к угрозам и запугиванию. Голодающие или разозлённые они прибегают к силе.`,
      source: {
        id: SOURCE_MM,
        page: 165,
      },
    },
  ],
  note: {
    text: `Всегда храни в кармане парочку драгоценных камней. Голодный зорн — полезный зорн`,
    author: `шестое правило выживания в подземельях Мистика Х`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 165,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_DIG]: 20,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 22,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  languageList: [
    LANG_TERRAN,
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Скольжение сквозь землю',
      description: `★СУЩЕСТВО★ может перемещаться, копая, сквозь немагические и необработанные землю и камень. При этом ★СУЩЕСТВО★ не беспокоит материал, через который перемещается.`,
    },
    ABILITY_STONE_CAMOUFLAGE,
    {
      name: 'Нюх на сокровища',
      description: `★СУЩЕСТВО★ может определять по запаху местонахождение драгоценных металлов и камней, таких как монеты и огранённые драгоценные камни, в пределах 60 футов от себя.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки _Когтем_ и одну атаку _Укусом_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
