const {
  SOURCE_MOOT,
} = require('./../../../../sourceList')
const {
  CR_2,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_CERBERUS,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_AGGRESSIVE,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  cerberiDescription,
} = require('./../../../../textCommonParts')
const {
  CREATURE_TWO_HEADED_CERBERUS,
  CREATURE_WOLF,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Двуглавый цербер',
  nameEn: 'Two-Headed Cerberus',
  id: CREATURE_TWO_HEADED_CERBERUS,
  description: [
    {
      header: 'Двуглавый цербер',
      text: `Двуглавые церберы считаются малой породой церберов, скрещивавшихся со смертными [волками](CREATURE:${CREATURE_WOLF}).

Они обычно бродят по смертной стороне реки Тартикс. Там они как правило игнорируют — или лишь слегка угрожают — душам умерших. Однако это не относится к смертным, и двуглавые церберы охотно нападают на тех, кто подходит слишком близко к границам Подземного царства.`,
      source: {
        id: SOURCE_MOOT,
        page: 215,
      },
    },
    cerberiDescription,
  ],
  note: `Левая голова держит правую впроголодь, чтобы та охотнее выслеживала новую добычу`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_CERBERUS,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MOOT,
    page: 216,
  },
  armor: 12,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_NECROTIC,
  ],
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AGGRESSIVE,
    ABILITY_PACK_TACTICS,
    {
      name: 'Многоглавость',
      description: `★СУЩЕСТВО★ не может быть ★застан★ врасплох и получает преимущество на испытания от Потери сознания.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Укусом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 2,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 4,
          },
        ],
      },
    },
    {
      name: 'Оружие дыхания',
      restore: 5,
      description: `★СУЩЕСТВО★ выдыхает поток расплавленного камня 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 12, получая 10 (3к6) урона гнём при провале или половину этого урона при успехе. При провале существо также Обездвиживается твердеющим камнем. Существо может действием совершить проверку Силы (Атлетика), при успехе освобождаясь из твердеющего камня или освобождая существо в пределах досягаемости. Камень имеет КД 17, 10 хитов, иммунитет к огню, яду и психическому урону.`,
    },
  ],
}
