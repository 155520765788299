const {LANG_ONE_OF_ITS_CREATOR} = require('./../../languageIdList')

module.exports = {
  id: LANG_ONE_OF_ITS_CREATOR,
  name: {
    nominative: 'Один из языков создателя',
    genitive: 'Одного из языков создателя',
    instrumental: 'Одним из языков создателя',
    prepositional: 'Одном из языков создателя',
  },
  isReady: false,
  isRealLang: false,
}
