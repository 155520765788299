module.exports = [
  require('./acid_splash'),
  require('./aid'),
  require('./alarm'),
  require('./alter_self'),
  require('./animal_messenger'),
  require('./animal_shapes'),
  require('./animate_dead'),
  require('./antipathy_sympathy'),
  require('./aura_of_life'),
  require('./aura_of_purity'),
  require('./aura_of_vitality'),
  require('./awaken'),
]
