const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  MGC_TYPE_TATTOO,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../magicItemRarityList')
const {
  MAGIC_ITEM_BLOOD_FURY_TATTOO,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_BLOOD_FURY_TATTOO,
  name: `Татуировка кровавой ярости`,
  nameEn: `Blood Fury Tattoo`,
  type: MGC_TYPE_TATTOO,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: `Эта магическая татуировка пробуждает ярость своими цветами и формами. Она наносится специальной иглой.

# Настройка на татуировку

Для настройки на _Татуировку кровавой ярости_, Вам нужно держать иглу у своей кожи в том месте, где Вы хотите нанести _Татуировку_, надавливая на иглу всё время настройки. Когда настройка завершится, игла превратится в чернила, которые станут _Татуировкой кровавой ярости_ на коже.

Если Ваша настройка на _Татуировку_ завершится, она исчезнет, а игла снова появится в Вашем пространстве.

# Кровожадные удары

_Татуировка_ содержит 10 зарядов и восстанавливает все потраченные заряды на рассвете. Пока она на Вашей коже, Вы получаете следующие преимущества.

* Когда Вы попадаете по существу атакой оружием, Вы можете потратить 1 заряд, чтобы нанести цели дополнительно 4к6 некротического урона и Вы восстанавливаете такое же число хитов.
* Когда видимое Вами существо наносит Вам урон, Вы можете потратить 1 заряд и реакцией соврешить рукопашную атаку по нему, с преимуществом к броску атаки.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_TCoE,
    page: 176,
  },
}
