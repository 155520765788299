const {GOD_LOLTH} = require('./../../godIdList')
const {
  CREATURE_DROW,
  CREATURE_GIANT_SPIDER,
} = require('./../../creatureIdList')
const {
  SOURCE_MM,
  SOURCE_MTOF,
} = require('./../../sourceList')
const lolthDescriptionList = require('./lolth')
const drowPoison = require('./drowPoison')

module.exports = [
  {
    header: 'Дроу',
    text: `Десятки тысяч лет назад эльфы были разделены; доброжелательные по характеру выступили против тех, что были эгоистичны и жестоки. Война между эльфами закончилась, когда добрые эльфы изгнали своих недоброжелательных родственников в подземные глубины. Здесь, в пещерах без света, в бесконечных лабиринтах извилистых проходов тёмные эльфы — [дроу](CREATURE:${CREATURE_DROW}) — нашли убежище. Они также обнаружили [эльфийскую богиню](GOD:${GOD_LOLTH}), которая не оставила их. По её приказу тёмные эльфы построили в Подземье целую империю.`,
    source: {
      id: SOURCE_MM,
      page: 305,
    },
  },
  {
    header: 'Создания тьмы',
    text: `Дроу прожили под землёй так долго, что приспособились к окружению и могут видеть в темноте. Однако теперь они не могут переносить солнечный свет. Когда в Подземье заканчиваются рабы, дроу посылают под покровом ночи отряды для захвата гуманоидов, и те приводят новых несчастных томиться в подчинении. За исключением этих редких вылазок дроу предпочитают оставаться в своих подземных королевствах, где у них всё под контролем и они чувствуют себя в безопасности.`,
    source: {
      id: SOURCE_MM,
      page: 305,
    },
  },
  {
    header: 'Города Подземья',
    text: `Тёмные эльфы построили фантастические города в огромных пещерах, где вода и еда в изобилии. В умении обрабатывать камень они конкурируют с большинством ремесленников дварфов, но их изделия сохраняют тонкий эльфийский вкус. Изысканные поселения дроу прочные и удивительно стойкие. Дроу любят выдалбливать огромные сталагмиты и сталактиты, создавая густонаселённые острые шпили, возвышающиеся до самого потолка.

Город дроу это мегаполис, окружённый высокими стенами. Посетители не-дроу обязаны осуществлять свою деятельность за пределами стен под бдительным присмотром. Дроу выращивают и содержат [гигантских пауков](CREATURE:${CREATURE_GIANT_SPIDER}) для защиты своих городов от незваных гостей, и поэтому их города увешаны тонкими, красивыми лентами, создающими воздушную ловушку, призванную поймать летающих врагов, которые могут парить над стенами.`,
    source: {
      id: SOURCE_MM,
      page: 305,
    },
  },
  {
    header: 'Магия дроу',
    text: `Дроу приспособились к жизни под землёй, и то же самое сделала их магия. Кроме использования этой магии для вырезания городов из камня, ею они наделяют своё оружие, создают новые опасные магические предметы и вызывают демонов из Бездны. Заклинатели дроу в высшей степени высокомерны, и никогда не стесняются использовать свою магию в самых отвратительных формах.`,
    source: {
      id: SOURCE_MM,
      page: 305,
    },
  },
  {
    header: 'Оружие и доспехи',
    text: `Дроу делают оружие из адамантина, тёмного и сверхъестественно твёрдого металла. Ремесленники дроу украшают своё оружие и доспехи узорами с мотивами паутины и пауков, а маги порой наделяют эти предметы магией, чтоб увеличить их эффективность. Однако такая магия рассеивается от воздействия солнечного света, так что оружие и доспехи дроу редко сохраняют свои бонусы улучшения и волшебные свойства, когда выносятся на поверхность.`,
    source: {
      id: SOURCE_MM,
      page: 305,
    },
  },
  {
    header: 'Беспощадные политики',
    text: `Политика дроу беспощадна и кишит интригами. Обычно дроу работают вместе, чтобы уничтожить общего врага или обеспечить собственное выживание. Такие союзы длятся недолго и таят в себе опасность.

Общество дроу делится на благородные дома, каждым из которых правит матрона, жаждущая увеличить престиж и мощь своего дома. Другими высокопоставленными членами дома являются кровные родственники, в то время как средний слой состоит из дроу из более слабых семей, которые поклялись в верности великим домам. В конце социальной лестницы дома находятся рабы, которыми становятся дроу низкого происхождения и иногда пленники, которые не являются дроу.`,
    source: {
      id: SOURCE_MM,
      page: 305,
    },
  },
  drowPoison,
  ...lolthDescriptionList,
  {
    header: 'Дроу',
    text: `Не важно, найдены ли они в городах Подземья, в тёмных переходах между ними или в ужасных демонических логовах, дроу остаются одной из самых коварных угроз для жителей поверхности. Преданные [Ллос](GOD:${GOD_LOLTH}), тёмные эльфы подчиняются её постоянным противоречивым приказам и живут в страхе перед её гневом. Некоторые достигают великой силы и влияния через хитрость, талант или уловки, вызывая страх у прислуживающих им [низших дроу](CREATURE:${CREATURE_DROW}).`,
    source: {
      id: SOURCE_MTOF,
      page: 163,
    },
  },
]
