const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_MANTLE_OF_SPELL_RESISTANCE} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_MANTLE_OF_SPELL_RESISTANCE,
  name: `Мантия сопротивления заклинаниям`,
  nameEn: `Mantle of Spell Resistance`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Вы получаете преимущество к испытаниям от заклинаний, пока носите этот плащ.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 182,
  },
}
