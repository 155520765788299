const {
  ABILITY_SPIDER_CLIMB,
  ABILITY_WEB_SENSE,
  ABILITY_WEB_WALKER,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_SWARM_OF_SPIDERS,
  CREATURE_SWARM_OF_INSECTS,
} = require('./../../../../creatureIdList')
const {insectSwarmDescription} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Рой пауков',
  id: CREATURE_SWARM_OF_SPIDERS,
  parentId: CREATURE_SWARM_OF_INSECTS,
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 340,
    }],
    description: insectSwarmDescription,
    featureList: [
      ABILITY_SPIDER_CLIMB,
      ABILITY_WEB_SENSE,
      ABILITY_WEB_WALKER,
    ],
  },
}
