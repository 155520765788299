module.exports = [
  require('./deathlock'),
  require('./deathlock_mastermind'),
  require('./deathlock_wight'),
  require('./demogorgon'),
  require('./derro'),
  require('./derro_savant'),
  require('./dire_troll'),
  require('./drow_arachnomancer'),
  require('./drow_favored_consort'),
  require('./drow_house_captain'),
  require('./drow_inquisitor'),
  require('./drow_matron_mother'),
  require('./drow_shadowblade'),
  require('./duergar_despot'),
  require('./duergar_hammerer'),
  require('./duergar_kavalrachni'),
  require('./duergar_mind_master'),
  require('./duergar_screamer'),
  require('./duergar_soulblade'),
  require('./duergar_stone_guard'),
  require('./duergar_warlord'),
  require('./duergar_xarrorn'),
  require('./dybbuk'),
]
