const {PC_SUBCLASS_ROGUE_MASTERMIND} = require('./../../../pcSubClassIdList')
const {
  GEAR_DISGUISE_KIT,
  GEAR_FORGERY_KIT,
} = require('./../../../gearIdList')
const {
  SOURCE_SCAG,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  FEATURE_INSIGHTFUL_MANIPULATOR,
  FEATURE_MASTER_OF_INTRIGUE,
  FEATURE_MASTER_OF_TACTICS,
  FEATURE_MISDIRECTION,
  FEATURE_SOUL_OF_DECEIT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_MASTER_OF_INTRIGUE,
    name: `Интриган`,
    nameEn: `Master of Intrigue`,
    lvl: 3,
    text: `Вы получаете владение [набором маскировки](GEAR:${GEAR_DISGUISE_KIT}), [набором для подделки документов](GEAR:${GEAR_FORGERY_KIT}) и одним игровым набором на свой выбор. Вы также получаете два языка на свой выбор.

Дополнительно, Вы можете безошибочно подражать манере и акценту речи существа, разговор которого Вы слышите как минимум одну минуту, что позволяет Вам представиться носителем речи из определённой земли, если Вы знаете язык.`,
  },
  {
    id: FEATURE_MASTER_OF_TACTICS,
    name: `Мастер тактики`,
    nameEn: `Master of Tactics`,
    lvl: 3,
    text: `Вы можете бонусным действием совершать действие Помощь. Когда Вы используете Помощь союзнику при атаке существа, цель той атаки может быть в 30 футах от Вас, а не в 5 футах, если цель может Вас видеть или слышать.`,
  },
  {
    id: FEATURE_INSIGHTFUL_MANIPULATOR,
    name: `Проницательный манипулятор`,
    nameEn: `Insightful Manipulator`,
    lvl: 9,
    text: `Если Вы потратите как минимум 1 минуту наблюдая или взаимодействуя с другим существом вне боя, Вы можете точно определить соотношение его возможностей и Ваших. Мастер ответит, является ли существо равным, превосходящим или уступающим Вам по двум из характеристик на Ваш выбор:

* Интеллект
* Мудрость
* Харизма
* Уровень в классе (если таковой имеется)

По усмотрению Мастера, Вы также можете раскрыть для себя часть истории существа или одну из его черт характера, если таковые имеются.`,
  },
  {
    id: FEATURE_MISDIRECTION,
    name: `Подмена`,
    nameEn: `Misdirection`,
    lvl: 13,
    text: `Вы можете заставить другое существо получить атаку, предназначенную Вам.

Когда Вы являетесь целью атаки, и в радиусе 5 футов от Вас есть существо, дающее Вам укрытие от атаки, Вы можете реакцией перенаправить атаку с Вас на это существо.`,
  },
  {
    id: FEATURE_SOUL_OF_DECEIT,
    name: `Душа обмана`,
    nameEn: `Soul of Deceit`,
    lvl: 17,
    text: `Ваши мысли нельзя прочитать телепатией или другими средствами, если Вы не позволите.

Вы можете предоставить ложные мысли, сделав проверку Харизмы (Обмана) против проверки Мудрости (Проницательности) чтеца мыслей. Неважно, что Вы говорите, магия, определяющая ложь, покажет, что Вы правдивы, если Вы того захотите, и теперь Вас нельзя принудить сказать правду с помощью магии.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_MASTERMIND,
    source: [
      {
        id: SOURCE_SCAG,
        page: 136,
      },
      {
        id: SOURCE_XGTE,
        page: 52,
      },
    ],
  })
)

