module.exports = [
  ...require('./a'),
  ...require('./b'),
  ...require('./e'),
  ...require('./f'),
  ...require('./h'),
  ...require('./m'),
  ...require('./r'),
  ...require('./s'),
  ...require('./t'),
  ...require('./v'),
  ...require('./w'),
]
