const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_REACTION,
  TIME_ROUND,
} = require('./../../../../../timePeriodList')
const {
  SPELL_MAGIC_MISSILE,
  SPELL_SHIELD,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SHIELD,
  name: 'Щит',
  nameEn: 'Shield',
  description: `Невидимый барьер из магической силы появляется, защищая Вас. Вы получаете до начала своего следующего хода бонус +5 к КД, в том числе и против вызвавшей срабатывание атаки, и Вы не получаете урон от [Волшебной стрелы](SPELL:${SPELL_MAGIC_MISSILE}).`,
  lvl: 1,
  magicSchoolId: MAGIC_ABJURATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_ROUND, count: 1 },
  castTime: { timeId: TIME_REACTION, count: 1 },
  castTimeComment: `совершаемая, когда по Вам попадает атака или Вы становитесь целью [Волшебной стрелы](SPELL:${SPELL_MAGIC_MISSILE})`,
  source: [
    {
      id: SOURCE_PHB,
      page: 289,
    },
    {
      id: SOURCE_SRD,
      page: 179,
    },
  ],
}
