const {
    SOURCE_SKT,
  } = require('./../../../../sourceList'),
  {
    CR_23,
  } = require('./../../../../crList'),
  {
    SIZE_GARGANTUA,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ELEMENTAL,
  } = require('./../../../../creatureTypeIdList'),
  {
    LANG_IGNAN,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_CN,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_FIREBALL,
  } = require('./../../../../spellIdList'),
  {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    ABILITY_MAGIC_RESISTANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_FIRE_ELEMENTAL,
    CREATURE_MAEGERA_THE_DAWN_TITAN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Маегера, Титан Рассвета',
  nameEn: 'Maegera the Dawn Titan',
  id: CREATURE_MAEGERA_THE_DAWN_TITAN ,
  hasSpoilers: true,
  description: `Маегера, могучий элементаль, который веками был заперт в печах Гонтлгрима. Примерно пятьдесят лет назад, Маегера ненадолго сбежал, что привело к извержению вулкана Горы Хотеноу. Лава из вулкана пошла по направлению к побережью, неся разрушения в Невервинтер. Город до сих пор оправляется от последствий той катастрофы.

Маегера выглядит как огненный зверь 50-футового роста, у которого множество конечностей и дымящиеся провалы черноты вместо глаз.`,
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_SKT,
    page: 245,
  },
  armor: 16,
  hp: {
    diceCount: 22,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 22,
    [PARAM_CON]: 20,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_IGNAN,
  ],
  cr: CR_23,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Усиленные атаки',
      description: `★Его★ _Удар_ считается магическим в целях преодоления сопротивления или иммунитета к урону от немагических атак.`,
    },
    {
      name: 'Огненная аура',
      description: `В начале каждого ★eго★ хода все существа в пределах 30 футов получают урон огнём 35 (10к6), а горючие предметы, которые никто не несёт и не носит, загораются. Также существо получает 35 (10к6) урона огнём, касаясь ★СУЩЕСТВО★ или попадая по ★нему★ рукопашной атакой, находясь в пределах 10 футов. Кроме того, существа получают такой урон, когда ★СУЩЕСТВО★ входит в их пространство в первый раз за ход. Если попасть по ★СУЩЕСТВО★ не магическим оружием, то оно будет уничтожено огнём сразу после того как нанесёт урон.`,
    },
    {
      name: 'Форма пламени',
      description: `★СУЩЕСТВО★ может входить в пространство враждебных существ и останавливаться там. Он может перемещаться сквозь пространство шириной в 1 дюйм без протискивания, если через него может проходить огонь.`,
    },
    {
      name: 'Освещение',
      description: `★СУЩЕСТВО★ распространяет яркий свет на 120 футов и тусклый ещё на 120 футов.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_FIREBALL,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ трижды атакует _Ударом_.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 15,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 3,
            diceType: 6,
            diceBonus: 5,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 10,
            diceType: 6,
          },
        ],
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Тушение магии',
      description: `★СУЩЕСТВО★ выбирает целью одно видимое ★им★ в пределах 60 футов существо. Любое сопротивление или иммунитет к огненному урону, данные цели заклинанием или волшебным предметом, подавляются. Этот эффект длится до конца следующего хода ★СУЩЕСТВО★.`,
    },
    {
      name: 'Облако дыма',
      cost: 2,
      description: `★СУЩЕСТВО★ выдыхает облако горячего дыма и пепла, которое заполняет 60-футовый куб. Все существа в этой области получают 11 (2к10) урона огнем. Это облако остаётся до конца следующего хода ★СУЩЕСТВО★. Существа в облаке Ослеплены и их нельзя увидеть.`,
    },
    {
      name: 'Создание огненного элементаля',
      cost: 3,
      description: `Хиты ★СУЩЕСТВО★ снижаются на 50, так как ★его★ часть отделяется и становится [огненным элементалем](CREATURE:${CREATURE_FIRE_ELEMENTAL}) со 102 хитами. [Огненный элементаль](CREATURE:${CREATURE_FIRE_ELEMENTAL}) появляется в незанятом пространстве в 15 футах от ★СУЩЕСТВО★ и действует на ★его★ инициативе. ★СУЩЕСТВО★ не может использовать это действие, если у него 50 хитов или меньше. [Огненный элементаль](CREATURE:${CREATURE_FIRE_ELEMENTAL}) подчиняется командам ★СУЩЕСТВО★ и дерётся пока не будет уничтожен.`,
    },
  ],
}
