// [male, female, middle, plural]
module.exports = [
  ['активным', 'активной', 'активным', 'активными'],
  ['атакован', 'атакована', 'атаковано', 'атакованы'],
  ['бежал', 'бежала', 'бежало', 'бежали'],
  ['беззащитен', 'беззащитна', 'беззащитно', 'беззащитны'],
  ['безмятежен', 'безмятежна', 'безмятежно', 'безмятежны'],
  ['безрассудным', 'безрассудной', 'безрассудным', 'безрассудными'],
  ['близкий', 'близкая', 'близкое', 'близкие'],
  ['боится', 'боится', 'боится', 'боятся'],
  ['болтливый', 'болтливая', 'болтливое', 'болтливые'],
  ['бормочет', 'бормочет', 'бормочет', 'бормочут'],
  ['боролся', 'боролась', 'боролось', 'боролись'],
  ['брезгливый', 'брезгливая', 'брезгливое', 'брезгливые'],
  ['бродил', 'бродила', 'бродило', 'бродили'],
  ['бросил', 'бросила', 'бросило', 'бросили'],
  ['бросился', 'бросилась', 'бросилось', 'бросились'],
  ['будет', 'будет', 'будет', 'будут'],
  ['бывший', 'бывшая', 'бывшее', 'бывшие'],
  ['был', 'была', 'было', 'были'],
  ['вежлив', 'вежлива', 'вежливо', 'вежливы'],
  ['вежливый', 'вежливая', 'вежливое', 'вежливые'],
  ['верен', 'верна', 'верно', 'верны'],
  ['верит', 'верит', 'верит', 'верят'],
  ['весит', 'весит', 'весит', 'весят'],
  ['весёлый', 'весёлая', 'весёлое', 'весёлые'],
  ['вздорный', 'вздорная', 'вздорное', 'вздорные'],
  ['взрослый', 'взрослая', 'взрослое', 'взрослые'],
  ['взрывающегося', 'взрывающейся', 'взрывающегося', 'взрывающихся'],
  ['взял', 'взяла', 'взяло', 'взяли'],
  ['видел', 'видела', 'видело', 'видели'],
  ['виден', 'видна', 'видно', 'видны'],
  ['виноват', 'виновата', 'виновато', 'виноваты'],
  ['виновен', 'виновна', 'виновно', 'виновны'],
  ['виновным', 'виновной', 'виновным', 'виновными'],
  ['владел', 'владела', 'владело', 'владели'],
  ['влюбился', 'влюбилась', 'влюбилось', 'влюбились'],
  ['влюблён', 'влюблена', 'влюблено', 'влюблены'],
  ['внимательный', 'внимательная', 'внимательное', 'внимательные'],
  ['вовлечён', 'вовлечена', 'вовлечено', 'вовлечены'],
  ['возлюбленный', 'возлюбленная', 'возлюбленное', 'возлюбленные'],
  ['волевой', 'волевая', 'волевое', 'волевые'],
  ['ворчит', 'ворчит', 'ворчит', 'ворчат'],
  ['восстановился', 'восстановилась', 'восстановилось', 'восстановились'],
  ['встретил', 'встретила', 'встретило', 'встретили'],
  ['встречался', 'встречалась', 'встречалось', 'встречались'],
  ['вступил', 'вступила', 'вступило', 'вступили'],
  ['выбран', 'выбрана', 'выбрано', 'выбраны'],
  ['выглядит', 'выглядит', 'выглядит', 'выглядят'],
  ['выговаривает', 'выговаривает', 'выговаривает', 'выговаривают'],
  ['выжил', 'выжила', 'выжило', 'выжили'],
  ['выздоровел', 'выздоровела', 'выздоровело', 'выздоровели'],
  ['выкрикивает', 'выкрикивает', 'выкрикивает', 'выкрикивают'],
  ['выпил', 'выпила', 'выпило', 'выпили'],
  ['выполнен', 'выполнена', 'выполнено', 'выполнены'],
  ['вырос', 'выросла', 'выросло', 'выросли'],
  ['выслеживал', 'выслеживала', 'выслеживало', 'выслеживали'],
  ['высмеял', 'высмеяла', 'высмеяло', 'высмеяли'],
  ['героем', 'героиней', 'героем', 'героями'],
  ['героя', 'героиню', 'героя', 'героев'],
  ['глупый', 'глупая', 'глупое', 'глупые'],
  ['говорит', 'говорит', 'говорит', 'говорят'],
  ['готов', 'готова', 'готово', 'готовы'],
  ['груб', 'груба', 'грубо', 'грубы'],
  ['даёт', 'даёт', 'даёт', 'дают'],
  ['дееспособен', 'дееспособна', 'дееспособно', 'дееспособны'],
  ['дезертировал', 'дезертировала', 'дезертировало', 'дезертировали'],
  ['делал', 'делала', 'делало', 'делали'],
  ['держится', 'держится', 'держится', 'держатся'],
  ['должен', 'должна', 'должно', 'должны'],
  ['достиг', 'достигла', 'достигло', 'достигли'],
  ['друг', 'подруга', 'друг', 'друзья'],
  ['друга', 'подруги', 'друга', 'друзей'],
  ['дёргается', 'дёргается', 'дёргается', 'дёргаются'],
  ['его', 'её', 'его', 'их'],
  ['ему', 'ей', 'ему', 'им'],
  ['еретиком', 'еретичкой', 'еретиком', 'еретиками'],
  ['жаден', 'жадна', 'жадно', 'жадны'],
  ['жив', 'жива', 'живо', 'живы'],
  ['жил', 'жила', 'жило', 'жили'],
  ['заблудился', 'заблудилась', 'заблудилось', 'заблудились'],
  ['забывчивый', 'забывчивая', 'забывчивое', 'забывчивые'],
  ['заикается', 'заикается', 'заикается', 'заикаются'],
  ['заключён', 'заключена', 'заключено', 'заключены'],
  ['заложником', 'заложницей', 'заложником', 'заложниками'],
  ['занудный', 'занудная', 'занудное', 'занудные'],
  ['заполненный', 'заполненная', 'заполненное', 'заполненные'],
  ['заразился', 'заразилась', 'заразилось', 'заразились'],
  ['застан', 'застана', 'застано', 'застаны'],
  ['защитником', 'защитницей', 'защитником', 'защитниками'],
  ['злопамятный', 'злопамятная', 'злопамятное', 'злопамятные'],
  ['знает', 'знает', 'знает', 'знают'],
  ['знал', 'знала', 'знало', 'знали'],
  ['знаменитым', 'знаменитой', 'знаменитым', 'знаменитыми'],
  ['игнорирует', 'игнорирует', 'игнорирует', 'игнорируют'],
  ['избежал', 'избежала', 'избежало', 'избежали'],
  ['избран', 'избрана', 'избрано', 'избраны'],
  ['изгнан', 'изгнана', 'изгнано', 'изгнаны'],
  ['излечился', 'излечилась', 'излечилось', 'излечились'],
  ['изменился', 'изменилась', 'изменилось', 'изменились'],
  ['изменяет', 'изменяет', 'изменяет', 'изменяют'],
  ['изучил', 'изучила', 'изучило', 'изучили'],
  ['им', 'ей', 'им', 'ими'],
  ['имел', 'имела', 'имело', 'имели'],
  ['инкрустирован', 'инкрустирована', 'инкрустировано', 'инкрустированы'],
  ['искажает', 'искажает', 'искажает', 'искажают'],
  ['искал', 'искала', 'искало', 'искали'],
  ['использовался', 'использовалась', 'использовалось', 'использовались'],
  ['использует', 'использует', 'использует', 'использует'],
  ['используется', 'используется', 'используется', 'используются'],
  ['испугался', 'испугалась', 'испугалось', 'испугались'],
  ['испуган', 'испугана', 'испугано', 'испуганы'],
  ['испытывал', 'испытывала', 'испытывало', 'испытывали'],
  ['испытываю', 'испытываю', 'испытываю', 'испытываем'],
  ['исследовал', 'исследовала', 'исследовало', 'исследовали'],
  ['ищет', 'ищет', 'ищет', 'ищут'],
  ['кажется', 'кажется', 'кажется', 'кажутся'],
  ['казнён', 'казнена', 'казнено', 'казнены'],
  ['каламбурит', 'каламбурит', 'каламбурит', 'каламбурят'],
  ['категоричен', 'категорична', 'категорично', 'категоричны'],
  ['клянётся', 'клянётся', 'клянётся', 'клянутся'],
  ['концентрировался', 'концентрировалась', 'концентрировалось', 'концентрировались'],
  ['который', 'которая', 'которое', 'которые'],
  ['ленивый', 'ленивая', 'ленивое', 'ленивые'],
  ['лишённым', 'лишённой', 'лишённым', 'лишёнными'],
  ['ловким', 'ловкой', 'ловким', 'ловкими'],
  ['лучший', 'лучшая', 'лучшее', 'лучшие'],
  ['любил', 'любила', 'любило', 'любили'],
  ['любознательный', 'любознательная', 'любознательное', 'любознательные'],
  ['меняет', 'меняет', 'меняет', 'меняют'],
  ['мог', 'могла', 'могло', 'могли'],
  ['могу', 'могу', 'могу', 'можем'],
  ['могучим', 'могучей', 'могучим', 'могучими'],
  ['может', 'может', 'может', 'могут'],
  ['молился', 'молилась', 'молилось', 'молились'],
  ['молодым', 'молодой', 'молодым', 'молодыми'],
  ['надевается', 'надевается', 'надевается', 'надеваются'],
  ['надменный', 'надменная', 'надменное', 'надменные'],
  ['нажил', 'нажила', 'нажило', 'нажили'],
  ['намерен', 'намерена', 'намерено', 'намерены'],
  ['напевает', 'напевает', 'напевает', 'напевают'],
  ['наполнен', 'наполнена', 'наполнено', 'наполнены'],
  ['настоящий', 'настоящая', 'настоящее', 'настоящие'],
  ['наткнулся', 'наткнулась', 'наткнулось', 'наткнулись'],
  ['научился', 'научилась', 'научилось', 'научились'],
  ['начал', 'начала', 'начало', 'начали'],
  ['начинает', 'начинает', 'начинает', 'начинают'],
  ['начнёт', 'начнёт', 'начнёт', 'начнут'],
  ['нашёл', 'нашла', 'нашло', 'нашли'],
  ['невидимым', 'невидимой', 'невидимым', 'невидимыми'],
  ['невиновным', 'невиновной', 'невиновным', 'невиновными'],
  ['невоспитан', 'невоспитанна', 'невоспитанно', 'невоспитанны'],
  ['невосприимчив', 'невосприимчива', 'невосприимчиво', 'невосприимчивы'],
  ['невредим', 'невредима', 'невредимо', 'невредимы'],
  ['невредимым', 'невредимой', 'невредимым', 'невредимыми'],
  ['него', 'неё', 'него', 'них'],
  ['недалёкий', 'недалёкая', 'недалёкое', 'недалёкие'],
  ['недееспособен', 'недееспособна', 'недееспособно', 'недееспособны'],
  ['недееспособным', 'недееспособной', 'недееспособным', 'недееспособными'],
  ['недисциплинированным', 'недисциплинированной', 'недисциплинированным', 'недисциплинированными'],
  ['нему', 'ней', 'нему', 'ним'],
  ['неотличим', 'неотличима', 'неотличимо', 'неотличимы'],
  ['неподвижен', 'неподвижна', 'неподвижно', 'неподвижны'],
  ['неправ', 'неправа', 'неправо', 'неправы'],
  ['непреклонен', 'непреклонна', 'непреклонно', 'непреклонны'],
  ['нетерпим', 'нетерпима', 'нетерпимо', 'нетерпимы'],
  ['ним', 'ней', 'ним', 'ними'],
  ['новый', 'новая', 'новое', 'новые'],
  ['новым', 'новой', 'новым', 'новыми'],
  ['носил', 'носила', 'носило', 'носили'],
  ['носит', 'носит', 'носит', 'носят'],
  ['носится', 'носится', 'носится', 'носятся'],
  ['нём', 'ней', 'нём', 'них'],
  ['нёс', 'несла', 'несло', 'несли'],
  ['обвинён', 'обвинена', 'обвинено', 'обвинены'],
  ['обездвижен', 'обездвижена', 'обездвижено', 'обездвижены'],
  ['обидел', 'обидела', 'обидело', 'обидели'],
  ['обладает', 'обладает', 'обладает', 'обладают'],
  ['обнаружен', 'обнаружена', 'обнаружено', 'обнаружены'],
  ['обнаружил', 'обнаружила', 'обнаружило', 'обнаружили'],
  ['обожает', 'обожает', 'обожает', 'обожают'],
  ['образованный', 'образованная', 'образованное', 'образованные'],
  ['обратился', 'обратилась', 'обратилось', 'обратились'],
  ['обращён', 'обращена', 'обращено', 'обращены'],
  ['обрёл', 'обрела', 'обрело', 'обрели'],
  ['обучился', 'обучилась', 'обучилось', 'обучились'],
  ['общается', 'общается', 'общается', 'общаются'],
  ['объятым', 'объятой', 'объятым', 'объятыми'],
  ['обязан', 'обязана', 'обязано', 'обязаны'],
  ['оглохшим', 'оглохшей', 'оглохшим', 'оглохшими'],
  ['одержим', 'одержима', 'одержимо', 'одержимы'],
  ['одержимым', 'одержимой', 'одержимым', 'одержимыми'],
  ['озорным', 'озорной', 'озорным', 'озорными'],
  ['оказался', 'оказалась', 'оказалось', 'оказались'],
  ['окаменевшим', 'окаменевшей', 'окаменевшим', 'окаменевшими'],
  ['околдован', 'околдована', 'околдовано', 'околдованы'],
  ['окружён', 'окружена', 'окружено', 'окружены'],
  ['он', 'она', 'оно', 'они'],
  ['опасным', 'опасной', 'опасным', 'опасными'],
  ['опутан', 'опутана', 'опутано', 'опутаны'],
  ['освобождён', 'освобождена', 'освобождено', 'освобождены'],
  ['освоил', 'освоила', 'освоило', 'освоили'],
  ['оскорбил', 'оскорбила', 'оскорбило', 'оскорбили'],
  ['осознал', 'осознала', 'осознало', 'осознали'],
  ['оставался', 'оставалась', 'оставалось', 'оставались'],
  ['оставил', 'оставила', 'оставило', 'оставили'],
  ['оставлен', 'оставлена', 'оставлено', 'оставлены'],
  ['остался', 'осталась', 'осталось', 'остались'],
  ['осуждён', 'осуждена', 'осуждено', 'осуждены'],
  ['отбыл', 'отбыла', 'отбыло', 'отбыли'],
  ['отважный', 'отважная', 'отважное', 'отважные'],
  ['отправился', 'отправилась', 'отправилось', 'отправились'],
  ['отравлен', 'отравлена', 'отравлено', 'отравлены'],
  ['отталкивающим', 'отталкивающей', 'отталкивающим', 'отталкивающими'],
  ['оттачивал', 'оттачивала', 'оттачивало', 'оттачивали'],
  ['охвачен', 'охвачена', 'охвачено', 'охвачены'],
  ['очаровал', 'очаровала', 'очаровало', 'очаровали'],
  ['очарован', 'очарована', 'очаровано', 'очарованы'],
  ['ошеломлён', 'ошеломлена', 'ошеломлено', 'ошеломлены'],
  ['ощущал', 'ощущала', 'ощущало', 'ощущали'],
  ['парализованным', 'парализованной', 'парализованным', 'парализованными'],
  ['перевёрнут', 'перевёрнута', 'перевёрнуто', 'перевёрнуты'],
  ['переживает', 'переживает', 'переживает', 'переживают'],
  ['пережил', 'пережила', 'пережило', 'пережили'],
  ['перешёл', 'перешла', 'перешло', 'перешли'],
  ['перфекционист', 'перфекционистка', 'перфекционист', 'перфекционисты'],
  ['пленён', 'пленена', 'пленено', 'пленены'],
  ['повстречался', 'повстречалась', 'повстречалось', 'повстречались'],
  ['погиб', 'погибла', 'погибло', 'погибли'],
  ['погибший', 'погибшая', 'погибшее', 'погибшие'],
  ['погрузился', 'погрузилась', 'погрузилось', 'погрузились'],
  ['подружился', 'подружилась', 'подружилось', 'подружились'],
  ['подстрекает', 'подстрекает', 'подстрекает', 'подстрекают'],
  ['поженился', 'вышла замуж', 'поженилось', 'поженились'],
  ['позволяет', 'позволяет', 'позволяет', 'позволяют'],
  ['пойман', 'поймана', 'поймано', 'пойманы'],
  ['показал', 'показала', 'показало', 'показали'],
  ['поклялся', 'поклялась', 'поклялось', 'поклялись'],
  ['получает', 'получает', 'получает', 'получают'],
  ['получил', 'получила', 'получило', 'получили'],
  ['получит', 'получит', 'получит', 'получат'],
  ['полым', 'полой', 'полым', 'полыми'],
  ['помог', 'помогла', 'помогло', 'помогли'],
  ['понимает', 'понимает', 'понимает', 'понимают'],
  ['понимал', 'понимала', 'понимало', 'понимали'],
  ['поношенным', 'поношенной', 'поношенным', 'поношенными'],
  ['понял', 'поняла', 'поняло', 'поняли'],
  ['попал', 'попала', 'попало', 'попали'],
  ['попытался', 'попыталась', 'попыталось', 'попытались'],
  ['порабощён', 'порабощена', 'порабощено', 'порабощены'],
  ['поражён', 'поражена', 'поражено', 'поражены'],
  ['посетил', 'посетила', 'посетило', 'посетили'],
  ['посещён', 'посещена', 'посещено', 'посещены'],
  ['поскрипывает', 'поскрипывает', 'поскрипывает', 'поскрипывают'],
  ['последний', 'последняя', 'последнее', 'последние'],
  ['посредником', 'посредницей', 'посредником', 'посредниками'],
  ['поступил', 'поступила', 'поступило', 'поступили'],
  ['посчитал', 'посчитала', 'посчитало', 'посчитали'],
  ['потерял', 'потеряла', 'потеряло', 'потеряли'],
  ['почтителен', 'почтительна', 'почтительно', 'почтительны'],
  ['почувствовал', 'почувствовала', 'почувствовало', 'почувствовали'],
  ['пошёл', 'пошла', 'пошло', 'пошли'],
  ['предан', 'предана', 'предано', 'преданы'],
  ['предпочитает', 'предпочитает', 'предпочитает', 'предпочитают'],
  ['предрекает', 'предрекает', 'предрекает', 'предрекают'],
  ['предусмотрительный', 'предусмотрительная', 'предусмотрительное', 'предусмотрительные'],
  ['привлёк', 'привлекла', 'привлекло', 'привлекли'],
  ['привык', 'привыкла', 'привыкло', 'привыкли'],
  ['привычен', 'привычна', 'привычно', 'привычны'],
  ['привязан', 'привязана', 'привязано', 'привязаны'],
  ['придумает', 'придумает', 'придумает', 'придумают'],
  ['прикреплён', 'прикреплена', 'прикреплено', 'прикреплены'],
  ['принесён', 'принесена', 'принесено', 'принесены'],
  ['принялся', 'принялась', 'принялось', 'принялись'],
  ['прислуживал', 'прислуживала', 'прислуживало', 'прислуживали'],
  ['присоединился', 'присоединилась', 'присоединилось', 'присоединились'],
  ['пристрастен', 'пристрастна', 'пристрастно', 'пристрастны'],
  ['пришёл', 'пришла', 'пришло', 'пришли'],
  ['пробежал', 'пробежала', 'пробежало', 'пробежали'],
  ['пробудил', 'пробудила', 'пробудило', 'пробудили'],
  ['пробыл', 'пробыла', 'пробыло', 'пробыли'],
  ['провёл', 'провела', 'провело', 'провели'],
  ['проглатывает', 'проглатывает', 'проглатывает', 'проглатывают'],
  ['проглочен', 'проглочена', 'проглочено', 'проглочены'],
  ['проговаривает', 'проговаривает', 'проговаривает', 'проговаривают'],
  ['продал', 'продала', 'продало', 'продали'],
  ['прожил', 'прожила', 'прожило', 'прожили'],
  ['прозрачен', 'прозрачна', 'прозрачно', 'прозрачны'],
  ['произносит', 'произносит', 'произносит', 'произносят'],
  ['проникся', 'прониклась', 'прониклось', 'прониклись'],
  ['проницательный', 'проницательная', 'проницательное', 'проницательные'],
  ['пропал', 'пропала', 'пропало', 'пропали'],
  ['прославился', 'прославилась', 'прославилось', 'прославились'],
  ['проснулся', 'проснулась', 'проснулось', 'проснулись'],
  ['прочен', 'прочна', 'прочно', 'прочны'],
  ['прочитал', 'прочитала', 'прочитало', 'прочитали'],
  ['прошляпил', 'прошляпила', 'прошляпило', 'прошляпили'],
  ['прошёл', 'прошла', 'прошло', 'прошли'],
  ['пустил', 'пустила', 'пустило', 'пустили'],
  ['раб', 'рабыня', 'раб', 'рабы'],
  ['разговаривает', 'разговаривает', 'разговаривает', 'разговаривают'],
  ['разорван', 'разорвана', 'разорвано', 'разорваны'],
  ['разумный', 'разумная', 'разумное', 'разумные'],
  ['разыскал', 'разыскала', 'разыскало', 'разыскали'],
  ['ранен', 'ранена', 'ранено', 'ранены'],
  ['распадётся', 'распадётся', 'распадётся', 'распадутся'],
  ['распознал', 'распознала', 'распознало', 'распознали'],
  ['рассеянный', 'рассеянная', 'рассеянное', 'рассеянные'],
  ['религиозен', 'религиозна', 'религиозно', 'религиозны'],
  ['решил', 'решила', 'решило', 'решили'],
  ['рисковал', 'рисковала', 'рисковало', 'рисковали'],
  ['родился', 'родилась', 'родилось', 'родились'],
  ['романтичен', 'романтична', 'романтично', 'романтичны'],
  ['рос', 'росла', 'росло', 'росли'],
  ['рушащегося', 'рушащейся', 'рушащегося', 'рушащихся'],
  ['сам', 'сама', 'само', 'сами'],
  ['самого', 'самой', 'самого', 'самих'],
  ['самоуверенный', 'самоуверенная', 'самоуверенное', 'самоуверенные'],
  ['сбежал', 'сбежала', 'сбежало', 'сбежали'],
  ['сбит', 'сбита', 'сбито', 'сбиты'],
  ['свидетелем', 'свидетелем', 'свидетелем', 'свидетелями'],
  ['своим', 'своей', 'своим', 'своими'],
  ['связался', 'связалась', 'связалось', 'связались'],
  ['связан', 'связана', 'связано', 'связаны'],
  ['сделал', 'сделала', 'сделало', 'сделали'],
  ['сделан', 'сделана', 'сделано', 'сделаны'],
  ['сильным', 'сильной', 'сильным', 'сильными'],
  ['скитался', 'скиталась', 'скиталось', 'скитались'],
  ['склонен', 'склонна', 'склонно', 'склонны'],
  ['скучный', 'скучная', 'скучное', 'скучные'],
  ['сломан', 'сломана', 'сломано', 'сломаны'],
  ['служил', 'служила', 'служило', 'служили'],
  ['служит', 'служит', 'служит', 'служат'],
  ['слышал', 'слышала', 'слышало', 'слышали'],
  ['смелый', 'смелая', 'смелое', 'смелые'],
  ['смог', 'смогла', 'смогло', 'смогли'],
  ['смотрящий', 'смотрящая', 'смотрящее', 'смотрящие'],
  ['сноб', 'снобка', 'сноб', 'снобы'],
  ['соблазнён', 'соблазнён', 'соблазнён', 'соблазнён'],
  ['совершал', 'совершала', 'совершало', 'совершали'],
  ['совершил', 'совершила', 'совершило', 'совершили'],
  ['содержит', 'содержит', 'содержит', 'содержат'],
  ['сожран', 'сожрана', 'сожрано', 'сожраны'],
  ['создал', 'создала', 'создало', 'создали'],
  ['создан', 'создана', 'создано', 'созданы'],
  ['сообразительным', 'сообразительной', 'сообразительным', 'сообразительными'],
  ['спас', 'спасла', 'спасло', 'спасли'],
  ['спрашивал', 'спрашивала', 'спрашивало', 'спрашивали'],
  ['сражался', 'сражалась', 'сражалось', 'сражались'],
  ['ставшего', 'ставшей', 'ставшего', 'ставших'],
  ['стал', 'стала', 'стало', 'стали'],
  ['станет', 'станет', 'станет', 'станут'],
  ['столкнулся', 'столкнулась', 'столкнулось', 'столкнулись'],
  ['стоящим', 'стоящей', 'стоящим', 'стоящими'],
  ['страдает', 'страдает', 'страдает', 'страдают'],
  ['страдал', 'страдала', 'страдало', 'страдали'],
  ['стремится', 'стремится', 'стремится', 'стремятся'],
  ['сумел', 'сумела', 'сумело', 'сумели'],
  ['супруг', 'супруга', 'супруг', 'супруги'],
  ['суров', 'сурова', 'сурово', 'суровы'],
  ['схватил', 'схватила', 'схватило', 'схватили'],
  ['схвачен', 'схвачена', 'схвачено', 'схвачены'],
  ['телепортирован', 'телепортирована', 'телепортировано', 'телепортированы'],
  ['тем', 'той', 'тем', 'теми'],
  ['терпим', 'терпима', 'терпимо', 'терпимы'],
  ['требует', 'требует', 'требует', 'требуют'],
  ['трубит', 'трубит', 'трубит', 'трубят'],
  ['трусливый', 'трусливая', 'трусливое', 'трусливые'],
  ['тёплый', 'тёплая', 'тёплое', 'тёплые'],
  ['убедительный', 'убедительная', 'убедительное', 'убедительные'],
  ['убеждён', 'убеждена', 'убеждено', 'убеждены'],
  ['убит', 'убита', 'убито', 'убиты'],
  ['уверен', 'уверена', 'уверено', 'уверены'],
  ['ударяется', 'ударяется', 'ударяется', 'ударяются'],
  ['удостоен', 'удостоена', 'удостоено', 'удостоены'],
  ['уединился', 'уединилась', 'уединилось', 'уединились'],
  ['украл', 'украла', 'украло', 'украли'],
  ['украшен', 'украшена', 'украшено', 'украшены'],
  ['умер', 'умерла', 'умерло', 'умерли'],
  ['умирающего', 'умирающей', 'умирающего', 'умирающих'],
  ['умный', 'умная', 'умное', 'умные'],
  ['умён', 'умна', 'умно', 'умны'],
  ['уничтожен', 'уничтожена', 'уничтожено', 'уничтожены'],
  ['уничтоженный', 'уничтоженная', 'уничтоженное', 'уничтоженные'],
  ['уничтожил', 'уничтожила', 'уничтожило', 'уничтожили'],
  ['учился', 'училась', 'училось', 'учились'],
  ['ушёл', 'ушла', 'ушло', 'ушли'],
  ['фигурирует', 'фигурирует', 'фигурирует', 'фигурируют'],
  ['хамоватый', 'хамоватая', 'хамоватое', 'хамоватые'],
  ['хладнокровен', 'хладнокровна', 'хладнокровно', 'хладнокровны'],
  ['холодным', 'холодной', 'холодным', 'холодными'],
  ['хотел', 'хотела', 'хотело', 'хотели'],
  ['хочет', 'хочет', 'хочет', 'хотят'],
  ['цел', 'цела', 'цело', 'целы'],
  ['чувствовал', 'чувствовала', 'чувствовало', 'чувствовали'],
  ['чёрный', 'чёрная', 'чёрное', 'чёрные'],
  ['шевелится', 'шевелится', 'шевелится', 'шевелятся'],
  ['шепелявит', 'шепелявит', 'шепелявит', 'шепелявят'],
  ['шутит', 'шутит', 'шутит', 'шутят'],
  ['этого', 'этой', 'этого', 'этих'],
  ['этом', 'этой', 'этом', 'этих'],
  ['этот', 'эта', 'это', 'эти'],
]
