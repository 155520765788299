const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_SHIFTER} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_SHIFTER,
  nameEn: 'Shifter',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'оборотень',
      genitive: 'оборотня',
      dative: 'оборотню',
      accusative: 'оборотня',
      instrumental: 'оборотнем',
      prepositional: 'оборотне',
    },
    plural: {
      nominative: 'оборотни',
      genitive: 'оборотней',
      dative: 'оборотням',
      accusative: 'оборотней',
      instrumental: 'оборотнями',
      prepositional: 'оборотнях',
    },
  },
}
