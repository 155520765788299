const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {CR_5} = require('./../../../../crList')
const {GOD_RAVEN_QUEEN} = require('./../../../../godIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_DDHC_MORD_01} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGICAL_ATTACKS,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_ETHEREAL_AWARENESS,
  ABILITY_KEEN_HEARING_AND_SMELL,
  ABILITY_SHADOW_BLEND,
  ABILITY_SUNLIGHT_WEAKNESS,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_ENNUISIS,
  CREATURE_SHADOW_MASTIFF,
  CREATURE_SHADOW_MASTIFF_ALPHA,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Эннуис',
  nameEn: 'Ennuisis',
  id: CREATURE_ENNUISIS,
  description: [
    {
      header: 'Эннуисы',
      text: `Стаи [теневых мастифов](CREATURE:${CREATURE_SHADOW_MASTIFF}) хорошо известны тем, кто проходит через Царство Теней. Некоторых из них захватывают служащие [Королеве Воронов](GOD:${GOD_RAVEN_QUEEN}) шадар-каи, превращая их в нечто большее.`,
      source: {
        id: SOURCE_DDHC_MORD_01,
        page: 31,
      },
    },
    {
      header: 'Управляемая эволюция',
      text: `Когда шадар-каю нужна помощь в поиске воспоминаний для [Королевы](GOD:${GOD_RAVEN_QUEEN}), они ловят щенка [альфа-самца теневого мастифа](CREATURE:${CREATURE_SHADOW_MASTIFF_ALPHA}) и заставляют его съесть маяк. Такие щенки становятся взрослыми эннуисами в течение двух недель.`,
      source: {
        id: SOURCE_DDHC_MORD_01,
        page: 31,
      },
    },
    {
      header: 'Охотники за страстями',
      text: `Эннуисы тянутся к сильным эмоциям и чувствуют их запах так хорошо, что могут отследить его через огромные расстояния. Лишь те, кто мертвы внутри, не оставляют следов.`,
      source: {
        id: SOURCE_DDHC_MORD_01,
        page: 31,
      },
    },
    {
      header: 'Укус забвения',
      text: `Укус эннуиса временно высасывает все мысли и воспоминания, заставляя жертву неподвижно стоять, словно пустой сосуд, которым она после этого и является. Шадар-каи называют этот укус «иссушающей печалью» и считают, что все воспоминания идут к [Королеве Воронов](GOD:${GOD_RAVEN_QUEEN}), а те, что не поддаются, считаются недостойными предлагать воспоминания Королеве Воронов.`,
      source: {
        id: SOURCE_DDHC_MORD_01,
        page: 31,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_DDHC_MORD_01,
    page: 31,
  },
  armor: 14,
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 14,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    {
      id: DAMAGE_NONMAGICAL_ATTACKS,
      comment: 'пока эннуис находится на тусклом свете или в темноте',
    },
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_KEEN_HEARING_AND_SMELL,
    ABILITY_ETHEREAL_AWARENESS,
    ABILITY_SUNLIGHT_WEAKNESS,
    ABILITY_SHADOW_BLEND,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Укусом_.`,
    },
    {
      name: 'Укус',
      description: `Если цель не является конструктом или нежитью, она должна пройти испытание Мудрости СЛ 15, иначе станет Очарованной. Очарованная таким образом цель Недееспособна, и её скорость снижена до 0. В конце каждого своего хода, цель может пройти ещё одно испытание Мудрости, заканчивая эффект при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
}
