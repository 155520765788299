const {
  SPEED_CLIMB,
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_SWARM_OF_INSECTS,
  CREATURE_SWARM_OF_WASPS,
} = require('./../../../../creatureIdList')
const {insectSwarmDescription} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Рой ос',
  id: CREATURE_SWARM_OF_WASPS,
  parentId: CREATURE_SWARM_OF_INSECTS,
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 340,
    }],
    description: insectSwarmDescription,
    speed: {
      [SPEED_WALK]: 5,
      [SPEED_FLY]: 5,
      [SPEED_CLIMB]: 0,
    },
  },
}
