const {
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {CONDITION_UNCONSCIOUS} = require('./../../../../../conditionList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_CATNAP} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_CATNAP,
  name: 'Дрёма',
  nameEn: 'Catnap',
  description: `Вы делаете успокаивающий жест, и до трех согласных существ по Вашему выбору, которых Вы можете видеть в пределах дистанции, падают без сознания на время действия заклинания. Заклинание заканчивается на цели раньше, если она получает урон или кто-то использует действие, чтобы потрясти её или дать ей пощёчину. Если цель остается без сознания в течение всего времени действия заклинания, она получает все преимущества короткого отдыха, и не может быть подвержена этому заклинанию снова, пока не закончит длинный отдых.`,
  highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку 4 круга или выше, Вы можете сделать целью дополнительно ещё одно существо за каждый круг ячейки выше 3.`,
  lvl: 3,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 30,
  componentIdList: [CAST_SOMATIC, CAST_MATERIAL],
  materialText: `щепотка песка`,
  duration: {timeId: TIME_MINUTE, count: 10},
  effect: {
    condition: CONDITION_UNCONSCIOUS,
  },
  source: {
    id: SOURCE_XGTE,
    page: 151,
  },
}
