const {PC_SUBCLASS_ROGUE_PHANTOM_2020_05_12} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED} = require('./../../../sourceList')
const {
  FEATURE_DEATH_KNELL_2020_05_12,
  FEATURE_GHOST_WALK_2020_05_12,
  FEATURE_SNEAK_ATTACK,
  FEATURE_TOKENS_OF_THE_DEPARTED_2020_05_12,
  FEATURE_WAILS_FROM_THE_GRAVE_2020_05_12,
  FEATURE_WHISPERS_OF_THE_DEAD_2020_05_12,
} = require('./../../../featureIdList')


module.exports = [
  {
    id: FEATURE_WHISPERS_OF_THE_DEAD_2020_05_12,
    name: `Шёпоты смерти`,
    nameEn: `Whispers of the Dead`,
    lvl: 3,
    text: `К Вам начинают льнуть отголоски умерших.

Заканчивая короткий или длинный отдых, Вы можете получить один навык или владение инструментом по своему выбору, поскольку умершие нашёптывают Вам свои знания.

Это владение длится, пока Вы снова не воспользуетесь этой способностью.`,
    source: {
      id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
      page: 1,
    },
  },
  {
    id: FEATURE_WAILS_FROM_THE_GRAVE_2020_05_12,
    name: `Стоны из могилы`,
    nameEn: `Wails from the Grave`,
    lvl: 3,
    text: `Подталкивая кого-то ближе к могиле, Вы можете вызвать смертельные стоны рядом с ним.

Немедленно после того, как Вы в свой ход нанесете урон [Скрытой атакой](FEATURE:${FEATURE_SNEAK_ATTACK}) существу, Вы можете выбрать целью второе существо, видимое Вами в пределах 30 футов от первого. Бросьте половину количества костей [Скрытой атаки](FEATURE:${FEATURE_SNEAK_ATTACK}) (округлённое вверх), и второе существо получит психический урон, равный сумме броска, поскольку на мгновение вокруг него раздаются вопли мертвых.

Вы можете использовать эту способность количество раз, равное своему бонусу мастерства, и Вы восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
      page: 1,
    },
  },
  {
    id: FEATURE_TOKENS_OF_THE_DEPARTED_2020_05_12,
    name: `Части усопших`,
    nameEn: `Tokens of the Departed`,
    lvl: 9,
    text: `Когда жизнь обрывается в Вашем присутствии, Вы можете вырвать часть уходящей души, кусочек её жизненной сущности, принимающий физическую форму.

Реакцией, когда умирает видимое Вами в пределах 30 футов существо, Вы раскрываете свободная ладонь, и в ней появляется Крошечная безделушка, безделушка души. Мастер выбирает форму безделушки или просит Вас бросить по таблице «Безделушки» в Книге Игрока, чтобы определить её вид.

Пока безделушка души находится у Вас, Вы совершаете с преимуществом испытания от смерти и испытания Телосложения, так как Ваша жизнеспособность увеличивается за счёт жизненной сущности внутри объекта.

Количество безделушек души у Вас не может превышать Ваш бонуса мастерства, и Вы не можете создать их, пока используете максимум.

Вы можете действием уничтожить одну из своих безделушек души, где бы она ни находилась. Сделав это, Вы можете задать духу, связанному с этой безделушкой, один вопрос. Дух является Вам и отвечает на языке, который он знал при жизни. Он не обязан быть правдивым и отвечает как можно более кратко, стремясь освободиться.`,
    source: {
      id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
      page: 2,
    },
  },
  {
    id: FEATURE_GHOST_WALK_2020_05_12,
    name: `Призрачная походка`,
    nameEn: `Ghost Walk`,
    lvl: 13,
    text: `Теперь Вы можете частично смещаться в цартсво мёртвых, выглядя при этом как призрак.

Бонусным действием Вы принимаете призрачную форму. Будучи в этой форме, Вы получаете скорость полёта 10 футов, можете парить и все атаки по Вам совершаются с помехами. Также Вы можете двигаться сквозь существ и предметы, как если бы они были труднопроходимой местностью. Вы получаете 1к10 урона силовым полем, если оканчиваете ход внутри существа или предмета.

Вы остаётесь в этой форме до 10 минут или пока Вы не окончите её бонусным действием.

Использовав эту способность, Вы не можете использовать её снова, пока не закончите длинный отдых, или пока не уничтожите одну из своих безделушек души тем же бонусным действием, которым снова принимаете Призрачную походку.`,
    source: {
      id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
      page: 2,
    },
  },
  {
    id: FEATURE_DEATH_KNELL_2020_05_12,
    name: `Звон смерти`,
    nameEn: `Death Knell`,
    lvl: 17,
    text: `Когда Вы используете способность [Стоны из могилы](FEATURE:${FEATURE_WAILS_FROM_THE_GRAVE_2020_05_12}), Вы можете наносить психический урон обоим существам.`,
    source: {
      id: SOURCE_UA_2020_05_12_SUBCLASSES_REVISITED,
      page: 2,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_PHANTOM_2020_05_12,
  })
)

