const {PC_SUBCLASS_FIGHTER_SAMURAI} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  FEATURE_BONUS_PROFICIENCY_FIGHTER_SAMURAI,
  FEATURE_ELEGANT_COURTIER,
  FEATURE_FIGHTING_SPIRIT,
  FEATURE_RAPID_STRIKE,
  FEATURE_STRENGTH_BEFORE_DEATH,
  FEATURE_TIRELESS_SPIRIT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BONUS_PROFICIENCY_FIGHTER_SAMURAI,
    name: `Дополнительные владения`,
    nameEn: `Bonus Proficiency`,
    lvl: 3,
    text: `Вы получаете владение одним из следующих навыков по своему выбору:

* Выступление,
* История,
* Проницательность,
* Убеждение.

В качестве альтернативы Вы можете выучить один язык по своему выбору`,
  },
  {
    id: FEATURE_FIGHTING_SPIRIT,
    name: `Боевой дух`,
    nameEn: `Fighting Spirit`,
    lvl: [3, 10, 15],
    text: `Ваша стремительность в битве может помочь Вам защититься и нанести точный удар.

Бонусным действием Вы можете дать себе преимущество на броски атаки до конца текущего хода. При этом Вы также получаете 5 дополнительных временных хитов. Количество временных хитов увеличивается, когда Вы достигаете определённых уровней в этом классе: 10 на 10 уровне и 15 на 15 уровне.

Вы можете использовать это умение три раза, и восстанавливаете все потраченные использования по завершении длинного отдыха.`,
  },
  {
    id: FEATURE_ELEGANT_COURTIER,
    name: `Элегантный придворный`,
    nameEn: `Elegant Courtier`,
    lvl: 7,
    text: `Ваши воинская дисциплина и внимание к деталям позволяют Вам преуспеть в социальных взаимодействиях.

Всякий раз, когда Вы совершаете проверку Харизмы (Убеждение), Вы получаете бонус к проверке, равный Вашему модификатору Мудрости.

К тому же Ваш самоконтроль позволил Вам получить владением испытанием Мудрости. Если у Вас уже есть владение испытанием Мудрости, Вы получаете владение испытанием Интеллекта или Харизмы (на Ваш выбор).`,
  },
  {
    id: FEATURE_TIRELESS_SPIRIT,
    name: `Неутомимая душа`,
    nameEn: `Tireless Spirit`,
    lvl: 10,
    text: `Когда Вы совершаете бросок инициативы, и у Вас не осталось не потраченных использований способности [Боевой дух](FEATURE:${FEATURE_FIGHTING_SPIRIT}), Вы восстанавливаете одно использование.`,
  },
  {
    id: FEATURE_RAPID_STRIKE,
    name: `Стремительный удар`,
    nameEn: `Rapid Strike`,
    lvl: 15,
    text: `Вы научились жертвовать точностью для совершения более быстрых ударов.

Если в свой ход Вы совершаете атаку, и у Вас есть преимущество на бросок против одной из целей, Вы можете отказаться от преимущества и совершить ещё одну атаку оружием по этой цели как часть того же действия.

Вы можете делать это не более одного раза за ход.`,
  },
  {
    id: FEATURE_STRENGTH_BEFORE_DEATH,
    name: `Стойкость перед смертью`,
    nameEn: `Strength Before Death`,
    lvl: 18,
    text: `Ваш боевой дух может задержать смерть.

Если урон опускает Ваши хиты до 0, но не убивает Вас, Вы можете потратить реакцию, чтобы задержать потерю сознания, и можете немедленно сделать дополнительный ход, прервав текущий ход. Если Вы получаете урон во время этого хода, когда у Вас уже 0 хитов, Вы получаете проваленное испытание от смерти. В случае трёх провалов Вы умираете. Если к окончанию хода у Вас все ещё 0 хитов, Вы теряете сознание.

Использовав эту способность, Вы должны завершить длинный отдых, чтобы использовать её снова.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_SAMURAI,
    source: {
      id: SOURCE_XGTE,
      page: 23,
    },
  })
)
