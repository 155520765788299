const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_9,
} = require('./../../../../crList')
const {
  SIZE_HUGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_MONSTROSITY,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_YETI,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  ABILITY_FEAR_OF_FIRE,
  ABILITY_KEEN_SMELL,
  ABILITY_SNOW_CAMOUFLAGE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_ABOMINABLE_YETI,
  CREATURE_YETI,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Отвратительный йети',
  nameEn: 'Abominable Yeti',
  id: CREATURE_ABOMINABLE_YETI,
  description: `Отвратительный йети крупнее обычного [йети](CREATURE:${CREATURE_YETI}), и в стоячем виде втрое выше человека. Как правило, они живут и охотятся в одиночку, хотя пара отвратительных йети может прожить вместе достаточно долго, чтобы вырастить потомство. Эти огромные йети территориальные и дикие, они нападают на любых теплокровных существ, с которыми они сталкиваются, и пожирают их, а потом разбрасывают их кости по льду и снегу.`,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 167,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 40,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 22,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_COLD,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_YETI,
  ],
  cr: CR_9,
  featureList: [
    ABILITY_FEAR_OF_FIRE,
    ABILITY_KEEN_SMELL,
    ABILITY_SNOW_CAMOUFLAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ может использовать _Леденящий взгляд_ и совершает две атаки _Когтем_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 7,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Леденящий взгляд',
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 30 футов от себя. Если цель видит йети, она должна пройти испытание Телосложения СЛ 18 от этой магии, иначе получит урон холодом 21 (6к6) и станет парализованной на 1 минуту, если только не обладает иммунитетом к холоду. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. Если испытание цели было пройдено, или эффект на ней закончился, цель получает иммунитет к _Леденящему взгляду_ ★СУЩЕСТВО★ на 1 час.`,
    },
    {
      name: 'Холодное дыхание',
      description: `★СУЩЕСТВО★ выдыхает холодный воздух 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 18, получая урон холодом 45 (10к8) при провале, или половину этого урона при успехе.`,
      restore: 6,
    },
  ],
  genderId: GENDER_MALE,
}
