const {
  SPELL_FINGER_OF_DEATH,
  SPELL_SLEEP,
  SPELL_SLOW,
  SPELL_SUGGESTION,
  SPELL_TELEPORT,
} = require('./../../../../../spellIdList')
const {
  MAGIC_ITEM_EYE_OF_VECNA,
  MAGIC_ITEM_HAND_OF_VECNA,
} = require('./../../../../../magicItemIdList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MGC_RARITY_ARTIFACT} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const eyeAndHandOfVecna = require('./../../constants/eyeAndHandOfVecna')

module.exports = {
  id: MAGIC_ITEM_HAND_OF_VECNA,
  name: `Рука Векны`,
  nameEn: `Hand of Vecna`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  description: [
    `${eyeAndHandOfVecna.preText}

[Глаз Векны](MAGIC_ITEM:${MAGIC_ITEM_EYE_OF_VECNA}) и _Руку Векны_ можно найти вместе, а можно по отдельности. [Рука](MAGIC_ITEM:${MAGIC_ITEM_HAND_OF_VECNA}) высохшая и мумифицированная.

Для того чтобы настроиться на _Руку_, Вы должны отрубить левую руку по запястью и приложить к обрубку артефакт. _Рука_ сама прирастёт и станет полностью функционирующей. Если _Руку_ когда-нибудь отнимут, Вы умрёте.`,
    {
      header: 'Случайные свойства',
      text: `_Рука Векны_ обладает несколькими свойствами, определяемыми случайным образом:

* 1 малое положительное свойство
* 1 основное положительное свойство
* 1 малое отрицательное свойство`,
    },
    {
      header: 'Свойства Руки',
      text: `Ваше мировоззрение становится нейтрально-злым, и Вы получаете следующие преимущества:

* Ваша Сила становится равной 20, если только она не была больше 20.
* Все рукопашные заклинания, которые Вы накладываете этой рукой, и все рукопашные атаки оружием, совершённые оружием, которое Вы держите в этой руке, причиняют при попадании дополнительный урон холодом 2к8.
* У руки есть 8 зарядов. Вы можете действием потратить 1 или несколько зарядов, чтобы наложить ей одно из следующих заклинаний (СЛ испытания 18):
  * [Замедление](SPELL:${SPELL_SLOW}) (2 заряда),
  * [Перст смерти](SPELL:${SPELL_FINGER_OF_DEATH}) (5 зарядов),
  * [Телепортация](SPELL:${SPELL_TELEPORT}) (3 заряда),
  * [Усыпление](SPELL:${SPELL_SLEEP}) (1 заряд).

Рука каждый день восстанавливает 1к4 + 4 заряда на рассвете. Каждый раз, когда Вы накладываете ей заклинание, она сотворяет на Вас заклинание [Внушение](SPELL:${SPELL_SUGGESTION}) (СЛ испытания 18), требуя, чтобы Вы совершили злой поступок. Она может требовать конкретное деяние, а может оставить выбор действия за Вами.`,
    },
    {
      header: 'Свойства Глаза и Руки',
      text: eyeAndHandOfVecna.postText,
    },
  ],
  genderId: GENDER_FEMALE,
  spellIdList: [
    SPELL_FINGER_OF_DEATH,
    SPELL_SLEEP,
    SPELL_SLOW,
    SPELL_TELEPORT,
  ],
  source: {
    id: SOURCE_DMG,
    page: 222,
  },
}
