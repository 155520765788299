const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_BANE,
  SPELL_SACRED_FLAME,
  SPELL_SHIELD_OF_FAITH,
  SPELL_THAUMATURGY,
} = require('./../../../../spellIdList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_OTHERWORLDLY_PERCEPTION,
  ABILITY_SLIPPERY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KUO_TOA,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  kuoToaDescriptionList,
  kuoToaNote,
} = require('./../../../../textCommonParts')
const {
  SKILL_PERCEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_KUO_TOA_WHIP} = require('./../../../../creatureIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_UNDERCOMMON} = require('./../../../../languageIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Куо-тоа кнут',
  nameEn: 'Kuo-toa Whip',
  id: CREATURE_KUO_TOA_WHIP,
  note: kuoToaNote,
  description: kuoToaDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_KUO_TOA,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 185,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 14,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_RELIGION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_OTHERWORLDLY_PERCEPTION,
    ABILITY_SLIPPERY,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    spellCasterLevel: 2,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_SACRED_FLAME,
      SPELL_THAUMATURGY,
      SPELL_BANE,
      SPELL_SHIELD_OF_FAITH,
    ],
    slotCountList: [
      Infinity,
      3,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_ и одну _Клешнепосохом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Клешнепосох',
      description: `Если цель — существо с размером не больше Среднего, она становится схваченной (СЛ высвобождения 14). Пока захват не окончится, ★СУЩЕСТВО★ не может использовать _Клешнепосох_ на другой цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
