import React from "react"

import AdsAboveMenu from "@/ads/components/AdsAboveMenu"

import Menu from "./../Menu"

import "./HeaderStyles.css"

const HeaderComponent = () => (
  <header className="header">
    <AdsAboveMenu/>
    <Menu/>
  </header>
)

export default HeaderComponent
