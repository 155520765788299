const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_6,
} = require('./../../../../crList')
const {
  GOD_LOLTH,
} = require('./../../../../godIdList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_MONSTROSITY,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_FAERIE_FIRE,
} = require('./../../../../spellIdList')
const {
  CAST_MATERIAL,
} = require('./../../../../castComponentList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
} = require('./../../../../gearIdList')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_SPIDER_CLIMB,
  ABILITY_SUNLIGHT_SENSITIVITY,
  ABILITY_WEB_WALKER,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_DRIDER,
  CREATURE_DROW,
  CREATURE_GIANT_SPIDER,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Драук',
  nameEn: 'Drider',
  id: CREATURE_DRIDER,
  description: [
    `Когда дроу подаёт большие надежды, [Ллос](GOD:${GOD_LOLTH}) призывает его в Ямы Демонической Паутины, чтобы испытать его веру и силу. Те, кто проходят испытание, возвышаются пред [Ллос](GOD:${GOD_LOLTH}). Те же, кто не преуспеет, превращаются в драуков — ужасных гибридов [дроу](CREATURE:${CREATURE_DROW}) и [гигантских пауков](CREATURE:${CREATURE_GIANT_SPIDER}), которые служат живым напоминанием мощи [Ллос](GOD:${GOD_LOLTH}). Лишь дроу может быть обращён в драука, и секретом такого превращения владеет только [Ллос](GOD:${GOD_LOLTH}).`,
    {
      header: 'Пожизненная метка',
      text: `Дроу, превращённый в драука, возвращается на Материальный план извращённым и униженным существом. Ведомые своим сумасшествием, они растворяются в Подземье, становясь отшельниками и охотниками, одиночками или вожаками стай гигантских пауков.

В редких исключениях, драуки возвращаются на окраины общества дроу, невзирая на своё проклятье, чаще всего, чтобы воплотить в жизнь давнюю клятву или месть из прошлой жизни. Дроу боятся и сторонятся драуков, ставя их даже ниже рабов. Но они терпят их присутствие, так как эти существа являются живым воплощением воли [Ллос](GOD:${GOD_LOLTH}) и напоминанием о судьбе, которая ожидает всех, кто подведёт Паучью Королеву.`,
      source: {
        id: SOURCE_MM,
        page: 137,
      },
    },
  ],
  note: {
    text: `Однажды я подвела Королеву Пауков. Более никогда`,
    author: `Пелланистра, драук`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 137,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 18,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
    LANG_ELVEN,
  ],
  cr: CR_6,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_SPIDER_CLIMB,
    ABILITY_SUNLIGHT_SENSITIVITY,
    ABILITY_WEB_WALKER,
  ],
  spellCast: {
    baseStat: PARAM_WIT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DANCING_LIGHTS,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_FAERIE_FIRE,
          SPELL_DARKNESS,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки, либо _Длинным мечом_, либо _Длинным луком_. ★Он★ может заменить одну из этих атак атакой _Укусом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 2,
            diceType: 8,
          },
        ],
      },
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
  genderId: GENDER_MALE,
}
