const {CREATURE_TROLL} = require('./../../creatureIdList')
const {GOD_VAPRAK} = require('./../../godIdList')
const {SOURCE_MTOF} = require('./../../sourceList')

module.exports = {
  header: 'Вапрак Разрушитель',
  text: `Хоть [тролли](CREATURE:${CREATURE_TROLL}) не шибко набожны и редко задаются духовными вопросами, они боятся и поклоняются сущности, известной как [Вапрак Разрушитель](GOD:${GOD_VAPRAK}). Как и у множества низших божеств, истинная природа [Вапрака](GOD:${GOD_VAPRAK}) довольно загадочна, но он всегда изображается как ужасное, деформированное зеленоватое существо, сильно напоминающее [тролля](CREATURE:${CREATURE_TROLL}). У него случаются приступы бездумного разрушения и постоянные бредни о планах и замыслах прочих божеств.
    
[Тролли](CREATURE:${CREATURE_TROLL}) считают, что [Вапрак](GOD:${GOD_VAPRAK}) пожирает тех [троллей](CREATURE:${CREATURE_TROLL}), кто находится при смерти, но только если [тролль](CREATURE:${CREATURE_TROLL}) уже приготовлен или переварен (убит огнём или кислотой). В ином случае божество возвращает душу обратно в мир, регенерировать себе новое тело, неважно, на какие бы мелкие куски ни было разделено прежнее. Таким образом, только [тролли](CREATURE:${CREATURE_TROLL}), убитые кислотой или огнём, остаются навеки мёртвыми, потому что их пожирает [Вапрак](GOD:${GOD_VAPRAK}).`,
  source: {
    id: SOURCE_MTOF,
    page: 232,
  },
}
