const {
  SOURCE_UA_2017_04_03_STARTER_SPELLS,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {CONDITION_FRIGHTENED} = require('./../../../../../conditionList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SPELL_CAUSE_FEAR} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_CAUSE_FEAR,
  name: 'Вызов страха',
  nameEn: 'Cause Fear',
  description: `Вы пробуждаете ощущение смертности у одного существа, которое можете видеть в пределах дистанции. Конструкт или нежить невосприимчивы к этому эффекту. Цель должна пройти испытание по Мудрости или становится напуганной Вами пока заклинание не окончится. Напуганное существо может повторять испытания в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
  highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку 2 круга или выше, Вы можете сделать целью дополнительно ещё одно существо за каждый круг ячейки выше 1. Существа должны находиться в пределах 30 футов друг от друга, когда Вы выбираете их.`,
  lvl: 1,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 60,
  componentIdList: [CAST_VERBAL],
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
    condition: CONDITION_FRIGHTENED,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 150,
    },
    {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 2,
    },
  ],
}
