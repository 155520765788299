const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_13,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_LE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_ALL_KNOWN_AT_LIFE,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_HOLY_WATER,
  } = require('./../../../../gearIdList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    vampireDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_REGENERATION,
    ABILITY_SPIDER_CLIMB,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_BAT,
    CREATURE_RAT,
    CREATURE_SWARM_OF_BATS,
    CREATURE_SWARM_OF_RATS,
    CREATURE_VAMPIRE,
    CREATURE_VAMPIRE_SPAWN,
    CREATURE_WOLF,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Вампир',
  nameEn: 'Vampire',
  id: CREATURE_VAMPIRE,
  description: [
    ...vampireDescriptionList,
    {
      header: 'Логово вампира',
      text: `Вампир выбирает величественное и защищённое место для своего логова, такое как замок, укреплённое поместье или окружённое стенами аббатство. Он прячет свой гроб в подземном склепе или хранилище, охраняемом [порождениями вампира](CREATURE:${CREATURE_VAMPIRE_SPAWN}) или другими верными существами ночи.
        
# Местные эффекты

Область вокруг логова вампира изменяется из-за присутствия этого существа, создавая один или несколько из следующих эффектов:

* В области заметно увеличивается популяции [летучих мышей](CREATURE:${CREATURE_BAT}), [крыс](CREATURE:${CREATURE_RAT}) и [волков](CREATURE:${CREATURE_WOLF}).
* Растения в пределах 500 футов от логова увядают, а их стволы и ветви становятся скрюченными и шипастыми.
* Тени в пределах 500 футов от логова кажутся ненормально длинными, и иногда движутся как живые.
* Ползучий туман распространяется по земле в пределах 500 футов от логова вампира. Иногда туман принимает жуткие формы, такие как хватающие когти и извивающиеся змеи.

Если вампир уничтожен, эти эффекты прекращаются через 2к6 дней.`,
      source: {
        id: SOURCE_MM,
        page: 29,
      },
    },
  ],
  note: {
    text: `Я — древность, я — сама земля. Мои начала теряются во тьме прошлого. Я был воином, я был добр и справедлив. Я гремел по всей земле, словно гнев бога, но годы войны и убийств сточили мою душу, как ветер стачивает камни в песок`,
    author: `граф Страд фон Зарович`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_SHAPESHIFTER,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 30,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  cr: CR_13,
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 18,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  featureList: [
    {
      name: 'Перевёртыш',
      description: `Если ★СУЩЕСТВО★ не находится под солнечным светом и не стоит в текущей воде, ★он★ может действием превратиться в Крошечную летучую мышь или Среднее облако тумана, или же принять свой истинный облик.

Находясь в облике летучей мыши, ★СУЩЕСТВО★ не может говорить, ★его★ скорость ходьбы равна 5 футам, и у ★него★ скорость полёта 30 футов. Вся ★его★ статистика за исключением размера и скорости остаётся той же самой. Всё, что ★он★ носит, превращается вместе с ним, а то, что ★он★ несёт — нет. ★Он★ принимает свой истинный облик, если умирает.

Находясь в облике тумана, ★СУЩЕСТВО★ не может совершать действий, говорить и манипулировать предметами. ★Он★ ничего не весит, обладает скоростью полёта 20 футов, может парить и может входить в пространство враждебных существ и останавливаться там. Кроме того, если через некое пространство может проходить воздух, то это же может сделать и туман, без протискивания, хотя и не может проходить сквозь воду. ★Он★ с преимуществом проходит испытания Силы, Ловкости и Телосложения, и обладает иммунитетом к немагическому урону, за исключением урона, который ★он★ получает, находясь под солнечными лучами.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    {
      name: 'Туманный побег',
      description: `Если хиты ★СУЩЕСТВО★ опускаются до 0 за пределами места отдыха, ★он★ вместо того, чтобы потерять сознание, превращается в туманное облако (как сказано в описании особенности _Перевёртыш_), при условии, что ★он★ не находится под солнечными лучами или в текущей воде. Если ★он★ не может превратиться, ★он★ уничтожается.

Пока у ★СУЩЕСТВО★ в туманном облике 0 хитов, ★он★ не может возвращаться в облик вампира, и обязан вернуться в место отдыха в течение 2 часов, иначе будет уничтожен. Достигнув места отдыха, ★он★ принимает облик вампира. После этого ★он★ становится ★парализованным★, пока не восстановит хотя бы 1 хит. Проведя 1 час в месте отдыха с 0 хитов, ★он★ восстанавливает 1 хит.`,
    },
    {
      id: ABILITY_REGENERATION,
      regen: 20,
      other: `, если у ★него★ есть хотя бы 1 хит, и ★он★ не находится ни под солнечными лучами, ни в текущей воде. Если ★СУЩЕСТВО★ получает урон излучением или урон от [святой воды](GEAR:${GEAR_HOLY_WATER}), эта особенность не действует в начале следующего ★его★ хода`,
    },
    ABILITY_SPIDER_CLIMB,
    {
      name: 'Слабости вампира',
      description: `★СУЩЕСТВО★ обладает следующими слабостями:
* _Запрет_. ★СУЩЕСТВО★ не может войти в жилище без приглашения одного из обитателей.
* _Урон от текущей воды_. ★СУЩЕСТВО★ получает урон кислотой 20, если оканчивает ход в текущей воде.
* _Кол в сердце_. Если колющее оружие, изготовленное из дерева, вонзить в сердце ★СУЩЕСТВО★, пока ★он★ ★недееспособен★ в своём месте отдыха, ★СУЩЕСТВО★ станет парализованным, пока кол не вынут.
* _Гиперчувствительность к солнечному свету_. ★СУЩЕСТВО★ получает урон излучением 20, если начинает ход под солнечным светом. Находясь на солнечном свету, ★он★ совершает с помехой броски атаки и проверки характеристик.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака (только в облике вампира)',
      description: `★СУЩЕСТВО★ совершает две атаки, только одна из которых может быть _Укусом_.`,
    },
    {
      name: 'Безоружный удар (только в облике вампира)',
      description: 'Вместо причинения урона ★СУЩЕСТВО★ может схватить цель (СЛ высвобождения 18).',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Укус (только в облике вампира или летучей мыши)',
      description: `Максимум хитов цели уменьшается на количество, равное полученному урону некротической энергией, а ★СУЩЕСТВО★ восстанавливает такое же количество хитов. Уменьшение хитов длится до тех пор, пока цель не окончит длинный отдых. Цель умирает, если этот эффект уменьшает максимум её хитов до 0. Гуманоид, убитый этим способом, а после закопанный в землю, восстаёт следующей ночью [порождением вампира](CREATURE:${CREATURE_VAMPIRE_SPAWN}), находящимся под контролем ★СУЩЕСТВО★. `,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        target: 'одно согласное существо или существо, схваченное ★СУЩЕСТВО★, недееспособное или обездвиженое',
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 4,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 3,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Очарование',
      description: `★СУЩЕСТВО★ нацеливается на одного гуманоида, которого видит в пределах 30 футов. Если цель видит ★СУЩЕСТВО★, она должна пройти испытание Мудрости СЛ 17 от этой магии, иначе станет очарованной ★им★. Очарованная цель считает ★СУЩЕСТВО★ верным другом, о котором нужно заботиться, и которого нужно защищать. Несмотря на то, что цель не находится под контролем ★СУЩЕСТВО★, она выполняет ★его★ просьбы и искренне прилагает все нужные усилия, и будет согласной целью для укуса вампира.

Каждый раз, когда ★СУЩЕСТВО★ или ★его★ спутники делают цели что-нибудь плохое, цель может повторить испытание, оканчивая эффект на себе при успехе. В противном случае эффект длится 24 часа, пока ★СУЩЕСТВО★ не будет ★уничтожен★, пока ★СУЩЕСТВО★ с целью не окажутся на разных планах существования, или пока ★СУЩЕСТВО★ не окончит эффект бонусным действием.`,
    },
    {
      name: 'Дети ночи',
      description: `★СУЩЕСТВО★ магическим образом призывает 2к4 [роя крыс](CREATURE:${CREATURE_SWARM_OF_RATS}) или [летучих мышей](CREATURE:${CREATURE_SWARM_OF_BATS}), при условии, что на небе нет солнца. Находясь на открытом воздухе, ★СУЩЕСТВО★ может вместо этого призвать 3к6 [волков](CREATURE:${CREATURE_WOLF}). Вызванные существа приходят через 1к4 раунда, действуют как союзники ★СУЩЕСТВО★ и подчиняются ★его★ устным командам. Звери остаются на 1 час, пока ★СУЩЕСТВО★ не умрёт, или пока ★СУЩЕСТВО★ не отпустит их бонусным действием.`,
      limit: {
        count: 1,
        period: 'день',
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Перемещение',
      description: '★СУЩЕСТВО★ перемещается на расстояние, не превышающее ★его★ скорость, не провоцируя атаки.',
    },
    {
      name: 'Безоружный удар',
      description: '★СУЩЕСТВО★ совершает один _Безоружный удар_.',
    },
    {
      name: 'Укус',
      cost: 2,
      description: `★СУЩЕСТВО★ совершает одну атаку _Укусом_.`,
    },
  ],
  genderId: GENDER_MALE,
}
