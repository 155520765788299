const {
  WEAPON_FINESSE,
  WEAPON_LIGHT,
  WEAPON_RANGE,
  WEAPON_THROWN,
} = require('./../../../weaponPropList')
const {
  SOURCE_AYAGWnW,
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
} = require('./../../../sourceList')
const throwWeaponDescription = require('./../../constants/throwWeaponDescription')
const {CAT_SIMPLE_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_DAGGER} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_DAGGER,
  genderId: GENDER_MALE,
  name: 'Кинжал',
  nameByCase: {
    nominative: 'кинжал',
    genitive: 'кинжала',
    accusative: 'кинжал',
    instrumental: 'кинжалом',
  },
  nameEn: 'Dagger',
  description: [
    {
      header: 'Кинжал',
      text: 'Любимая игрушка вора',
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    throwWeaponDescription
  ],
  damage: {
    diceType: 4,
    diceCount: 1,
  },
  cost: 200,
  damageType: DAMAGE_PIERCING,
  weight: 1,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 114,
    },
  ],
  weaponPropList: [
    WEAPON_LIGHT,
    WEAPON_FINESSE,
    WEAPON_THROWN,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 20,
        max: 60,
      },
    },
  ],
  category: CAT_SIMPLE_MELEE_WEAPON,
}
