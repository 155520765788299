const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_2,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  dinoDescriptionList,
} = require('./../../../../textCommonParts')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_PLESIOSAURUS,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Плезиозавр',
  nameEn: 'Plesiosaurus',
  id: CREATURE_PLESIOSAURUS,
  description: [
    `Плезиозавр это водный динозавр с компактным телом и мощными плавниками. Хищный и агрессивный, он нападает на любое встреченное им существо. Его гибкая шея, составляющая треть от общей длины, извивается во все стороны и позволяет совершать мощные укусы.`,
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 97,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 5,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  cr: CR_2,
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
