const {SOURCE_MM} = require('./../../sourceList')
const {CREATURE_TYPE_FEY} = require('./../../creatureTypeIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_FEY,
  nameEn: 'Fey',
  genderId: GENDER_FEMALE,
  description: {
    text: `Феи — магические существа, тесно связанные с силами природы. Они живут в сумрачных рощах и туманных лесах. В некоторых мирах они тесно связаны со Страной Фей, которую также называют Планом Фей. Некоторые из них встречаются во Внешних Планах, в частности, на Арборее или в Звериных Землях. К феям относятся дриады, пикси и сатиры.`,
    source: {
      id: SOURCE_MM,
      page: 9,
    },
  },
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'фей',
        genitive: 'фея',
        dative: 'фею',
        accusative: 'фея',
        instrumental: 'феем',
        prepositional: 'фее',
      },
      [GENDER_FEMALE]: {
        nominative: 'фея',
        genitive: 'феи',
        dative: 'фее',
        accusative: 'фею',
        instrumental: 'феей',
        prepositional: 'фее',
      },
    },
    plural: {
      nominative: 'феи',
      genitive: 'фей',
      dative: 'феям',
      accusative: 'фей',
      instrumental: 'феями',
      prepositional: 'феях',
    },
  },
}
