const { GENDER_FEMALE, GENDER_MALE, GENDER_MIDDLE, GENDER_MULTIPLE } = require ('./genderList')
const preparedTextList = require ('./preparedTextList')

const upLetter = require ('./../utils/upLetter')

const textTransformRuleList = preparedTextList.reduce(
  (arr, [male, female, middle, plural]) => {
    const item = {
      text: `★${male}★`,
      to: {
        [GENDER_MALE]: male,
        [GENDER_FEMALE]: female,
        [GENDER_MIDDLE]: middle,
        [GENDER_MULTIPLE]: plural,
      },
    }

    return [
      ...arr,
      item,
      {
        ...item,
        text: upLetter(item.text, 1),
        to: {
          [GENDER_MALE]: upLetter(item.to[GENDER_MALE]),
          [GENDER_FEMALE]: upLetter(item.to[GENDER_FEMALE]),
          [GENDER_MIDDLE]: upLetter(item.to[GENDER_MIDDLE]),
          [GENDER_MULTIPLE]: upLetter(item.to[GENDER_MULTIPLE]),
        },
      },
    ]
  },
  []
)

module.exports = textTransformRuleList
