const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_IOUN_STONE} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const generateRarityList = require('./../../../../../../utils/generateRarityList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_IOUN_STONE,
  name: `Камень Йоун`,
  nameEn: `Ioun stone`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: generateRarityList(list),
  attunement: true,
  description: `_Камень Йоун_ назван в честь богини знаний и пророчеств, почитаемой в нескольких мирах. Существует много разновидностей _камней Йоун_, все они отличаются по форме и цвету.

Если Вы действием подбрасываете один из этих _камней_ в воздух, он начинает вращаться вокруг Вашей головы на расстоянии 1к3 футов, предоставляя Вам постоянный бонус. Впоследствии другое существо может действием схватить руками или чем-то иным камень, забирая его себе, если совершит успешный бросок атаки по КД 24 или совершит успешную проверку Ловкости (Акробатика) СЛ 24. Вы сами можете действием схватить и убрать камень, оканчивая его эффект.

У камня КД 24, 10 хитов и сопротивление ко всем видам урона. Пока он вращается вокруг Вашей головы, он считается носимым.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 168,
  },
}
