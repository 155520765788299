const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_CHOKER,
  CREATURE_DROW,
  CREATURE_DUERGAR,
  CREATURE_GOBLIN,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {ABILITY_SPIDER_CLIMB} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_TYPE_ABERRATION} = require('./../../../../creatureTypeIdList')
const {LANG_DEEP_SPEECH} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Душитель',
  nameEn: 'Choker',
  id: CREATURE_CHOKER,
  description: [
    `Душитель — это подземный хищник, гораздо более опасный, чем можно предположить, судя по его небольшому размеру и длинным, тонким, болтающимся конечностям.

Скелеты душителей состоят не из костей, а из хрящей. Такая гибкая внутренняя структура позволяет им легко пролезать через трещины и ниши в стенах их домашних пещер. Они скрываются в этих точках, тихие и незамеченные, в ожидании появления добычи.`,
    {
      header: 'Хитрые охотники',
      text: `Обычно душители охотятся, вывешивая труп последней своей жертвы снаружи своего укрытия. Когда они чувствуют голод, они отрывают куски плоти от тела, чтобы прокормить себя. В то же время труп служит приманкой любопытным гуманоидам — исследователям, [дроу](CREATURE:${CREATURE_DROW}), [двергарам](CREATURE:${CREATURE_DUERGAR}) или любимым их жертвам, [гоблинам](CREATURE:${CREATURE_GOBLIN}) — что решают приблизиться.

Когда цель показывается, лапы душителя выскакивают из укрытия, обхватывая шею цели и прижимает несчастное существо к стенам пещеры, высасывая из него жизнь. Из-за длины своих рук душители могут оставаться в глубине своего укрытия, оставаясь вне зоны поражения обычного оружия.`,
      source: {
        id: SOURCE_MTOF,
        page: 169,
      },
    },
    {
      header: 'Одинокие охотники',
      text: `Душители склонны устраивать свои засады в одиночестве нежели работать в коллективе, но стоит быть уверенным, что если есть одна такая тварь, то поблизости есть и другие. Они общаются жуткими, громкими завываниями, что проходят большое расстояние и которые, при этом, с трудом различимы в обычной, переполненной эхом, пещере.`,
      source: {
        id: SOURCE_MTOF,
        page: 169,
      },
    },
  ],
  note: {
    text: `Душители — это трусливые и глупые существа, бесполезные в качестве охранников — и ужасно исполняющие роль слуг. И всё же волшебники низшего статуса используют их в качестве фамильяров, чтобы не нуждаться в лестницах`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 169,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Искажённое проворство',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Душитель совершает дополнительное Действие в свой ход.`,
    },
    {
      name: 'Бесхребетность',
      description: `Душитель может перемещаться сквозь пространство шириной в 4 дюйма без протискивания и занимать это пространство.`,
    },
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Душитель совершает две атаки _Щупальцем_.`,
    },
    {
      name: 'Щупальце',
      description: `И если цель Большого или меньшего размера, она становится схваченной (сложность освобождения 15). Пока цель схвачена, она становится обездвижена, и душитель не может использовать это щупальце против другой цели. У душителя два щупальца. Если эта критическая атака, цель вдобавок не может дышать и говорить до окончания захвата.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 10,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3,
          },
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
}
