const {
  SPELL_BANISHMENT,
  SPELL_BEACON_OF_HOPE,
  SPELL_CRUSADER_S_MANTLE,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINE_FAVOR,
  SPELL_FLAME_STRIKE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_GUIDING_BOLT,
  SPELL_HEALING_WORD,
  SPELL_HOLD_MONSTER,
  SPELL_LESSER_RESTORATION,
  SPELL_LIGHT,
  SPELL_MAGIC_WEAPON,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MENDING,
  SPELL_PRAYER_OF_HEALING,
  SPELL_REVIVIFY,
  SPELL_SACRED_FLAME,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SILENCE,
  SPELL_SPARE_THE_DYING,
  SPELL_SPIRIT_GUARDIANS,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_STONESKIN,
  SPELL_WATER_WALK,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GEAR_MAUL,
  GEAR_PLATE_ARMOR,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_9} = require('./../../../../crList')
const {CREATURE_WAR_PRIEST} = require('./../../../../creatureIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Боевой священник',
  nameEn: 'War Priest',
  id: CREATURE_WAR_PRIEST,
  description: `Боевые священники поклоняются божеству войны и битвы. Они разрабатывают тактику, ведут солдат в сражение, противостоят вражеским заклинателям и приносят жертвы. Боевой священник может командовать армией или служить правой рукой военачальника на поле битвы.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 212,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_9,
  spellCast: {
    spellCasterLevel: 9,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_BANISHMENT,
      SPELL_BEACON_OF_HOPE,
      SPELL_CRUSADER_S_MANTLE,
      SPELL_DISPEL_MAGIC,
      SPELL_DIVINE_FAVOR,
      SPELL_FLAME_STRIKE,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_GUARDIAN_OF_FAITH,
      SPELL_GUIDING_BOLT,
      SPELL_HEALING_WORD,
      SPELL_HOLD_MONSTER,
      SPELL_LESSER_RESTORATION,
      SPELL_LIGHT,
      SPELL_MAGIC_WEAPON,
      SPELL_MASS_CURE_WOUNDS,
      SPELL_MENDING,
      SPELL_PRAYER_OF_HEALING,
      SPELL_REVIVIFY,
      SPELL_SACRED_FLAME,
      SPELL_SHIELD_OF_FAITH,
      SPELL_SILENCE,
      SPELL_SPARE_THE_DYING,
      SPELL_SPIRIT_GUARDIANS,
      SPELL_SPIRITUAL_WEAPON,
      SPELL_STONESKIN,
      SPELL_WATER_WALK,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_MAUL,
    },
  ],
  reactionList: [
    {
      name: 'Направленный удар',
      comment: 'перезаряжается после короткого или длинного отдыха',
      description: `★СУЩЕСТВО★ ★даёт★ бонус +10 к броску атаки, совершаемому ★им★ или другим существом в пределах 30 футов. ★СУЩЕСТВО★ ★может★ сделать этот выбор после того, как бросок сделан, но прежде, чем выяснится — попала атака или нет.`,
    },
  ],
}
