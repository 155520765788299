const {
  PC_CLASS_ARTIFICER,
  PC_CLASS_BARD,
  PC_CLASS_DRUID,
  PC_CLASS_PALADIN,
  PC_CLASS_CLERIC,
  PC_CLASS_RANGER,
  PC_CLASS_SORCERER,
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} = require('./../pcClassIdList')

const artificerSpellIdList = require('./bounds/artificer')
const bardSpellIdList = require('./bounds/bard')
const clericSpellIdList = require('./bounds/cleric')
const druidSpellIdList = require('./bounds/druid')
const paladinSpellIdList = require('./bounds/paladin')
const rangerSpellIdList = require('./bounds/ranger')
const sorcererSpellIdList = require('./bounds/sorcerer')
const warlockSpellIdList = require('./bounds/warlock')
const wizardSpellIdList = require('./bounds/wizard')

module.exports = {
  [PC_CLASS_ARTIFICER]: artificerSpellIdList,
  [PC_CLASS_BARD]: bardSpellIdList,
  [PC_CLASS_CLERIC]: clericSpellIdList,
  [PC_CLASS_DRUID]: druidSpellIdList,
  [PC_CLASS_PALADIN]: paladinSpellIdList,
  [PC_CLASS_RANGER]: rangerSpellIdList,
  [PC_CLASS_SORCERER]: sorcererSpellIdList,
  [PC_CLASS_WARLOCK]: warlockSpellIdList,
  [PC_CLASS_WIZARD]: wizardSpellIdList,
}
