const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {CONDITION_PARALYZED} = require('./../../../../../conditionList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SPELL_HOLD_MONSTER} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_HOLD_MONSTER,
  name: 'Удержание чудовища',
  nameEn: 'Hold Monster',
  description: `Выберите существо, которое видите в пределах дистанции. Цель должна пройти испытание Мудрости, иначе станет парализованной на период действия заклинания. Это заклинание не оказывает эффекта на нежить. В конце каждого своего хода цель может совершать новые испытания Мудрости. При успехе заклинание на этой цели оканчивается.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше пятого. Эти существа должны находиться в пределах 30 футов друг от друга, когда Вы нацеливаетесь на них.`,
  lvl: 5,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `небольшой прямой кусочек железа`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
    condition: CONDITION_PARALYZED,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 285,
    },
    {
      id: SOURCE_SRD,
      page: 154,
    },
  ],
}
