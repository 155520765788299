const {
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    CREATURE_PUTRID_UNDEAD_SPIRIT,
    CREATURE_SKELETAL_UNDEAD_SPIRIT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух разлагающейся нежити',
  nameEn: 'Putrid Undead Spirit',
  id: CREATURE_PUTRID_UNDEAD_SPIRIT,
  parentId: CREATURE_SKELETAL_UNDEAD_SPIRIT,
  creatureTypeIdList: CREATURE_TYPE_UNDEAD,
  hp: `30 + 10-кратный круг заклинания выше 3-го`,
  featureList: [
    {
      name: 'Тлетворная аура',
      description: `Все существа (кроме заклинателя), начинающие свой ход в пределах 5 футов от ★СУЩЕСТВО★, должно пройти испытание Телосложения, при провале отравляясь до начала своего следующего хода.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания.`,
    },
    {
      name: 'Разлагающая клешня',
      description: `Если цель отравлена, то она должна пройти испытание Телосложения или её парализует до конца её следующего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
}
