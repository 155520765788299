const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ACROBATICS,
  SKILL_PERCEPTION,
  SKILL_SLEIGHT_OF_HAND,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ABILITY_EVASION} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_QUICKLING} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_FEY} = require('./../../../../creatureTypeIdList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Квиклинг',
  nameEn: 'Quickling',
  id: CREATURE_QUICKLING,
  description: `Квиклинги носятся по странным, искорёженным лесам, где обитают феи неблагого двора, как в Стране Фей, так и на Материальном плане. Они носятся так быстро, что едва заметны для глаз, и каждый кажется скорее размытым колебанием в воздухе.

Квиклинг — это маленькая, стройная фея, похожая на миниатюрного эльфа с острыми, звериными чертами. Их холодные, жестокие глаза мерцают как драгоценные камни.

**Живи быстро, умри молодым.** Квиклинги обязаны своим существованием и состоянием Королеве Воздуха и Тьмы, ужасной правительнице Сумеречного Двора. Они когда-то были расой ленивых и эгоистичных фей, и слишком часто опаздывали на зов королевы. Чтобы ускорить их поступь и проучить за невыполнение её воли, королева уменьшила их и ускорила их внутренние часы. Проклятье королевы дало квиклингам их невероятную скорость, но также и ускорило их жизнь — ни один квиклинг не живёт дольше пятнадцати лет.

**Слишком быстрые для слов.** Мир смертных — это скучное место для глаза квиклинга: ураган медленно ползёт по небу, поток водяных капель плывёт к земле как ленивые снежинки, молнии крадутся извилистым путем от облака к облаку. Медленный и скучный мир кажется населен вялыми существами, и их низкой, мычащей речи недостает смысла.

Другим существам квиклинги кажутся ослепительно быстрыми, пропадающими в неразличимой размытой дымке при движении. Их жестокий смех похож на взрыв звуков стаккато, а речь на пронзительный визг. Только когда квиклинг умышленно замедляется, чего он предпочитает не делать, остальные существа начинают слышать, видеть и понимать его. Они никогда по-настоящему не отдыхают, «стационарный» квиклинг постоянно перемещается взад-вперед, как будто ему не терпится снова отправиться в путь.

**Озорники, а не убийцы.** У квиклингов капризная природа, которая хорошо сочетается с их энергичностью: они думают так же быстро как и бегают, и всегда планируют что-то сделать. Квиклинг проводит большую часть жизни, проказничая над медленными существами. Они редко упускают возможность связать шнурки чьих-нибудь ботинок, передвинуть стул перед тем, как кто-то на него сядет или расстегнуть седло, пока никто не видит.

Но проделки такого сорта, к сожалению, не предел их изысканной зловредности. Они не совершают прямых убийств, но могут разрушить жизнь кучей других способов: украсть важное письмо, украсть монеты собранные для бедных, подложить украденный предмет в чью-нибудь сумку. Квиклингам нравится приносить страдания, которые превосходят простое несчастье, особенно когда вина за их действия ложится на других существ и создаёт раздор.`,
  note: {
    text: `Они загоняют себя до смерти, а планы составляют с той же скоростью что и бегут.`,
    author: `Эльминстер`,
  },
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 156,
  },
  armor: 16,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 120,
  },
  params: {
    [PARAM_STR]: 4,
    [PARAM_DEX]: 23,
    [PARAM_CON]: 13,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_SLEIGHT_OF_HAND]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_SYLVAN,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Размытое движение',
      description: `Броски на попадание по квиклингам делаются с помехой, если только квиклинг не ★недееспособен★ или ★опутан★.`,
    },
    ABILITY_EVASION,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Квиклинг делает три атаки _Кинжалом_.`,
    },
    {
      gearId: GEAR_DAGGER,
    },
  ],
}
