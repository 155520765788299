const {
  CREATURE_DECEITFUL_FEY_SPIRIT_2020_03_26,
  CREATURE_FUMING_SPIRIT,
  CREATURE_FURIOUS_FEY_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух яростной феи',
  nameEn: 'Furious Fey Spirit',
  id: CREATURE_FURIOUS_FEY_SPIRIT_2020_03_26,
  releasedAs: CREATURE_FUMING_SPIRIT,
  parentId: CREATURE_DECEITFUL_FEY_SPIRIT_2020_03_26,
  featureList: [
    {
      name: 'Фейский шаг',
      description: `Бонусным действием ★СУЩЕСТВО★ может магически телепортироваться в свободное пространство, видимое им в пределах 30 футов.`,
    },
    {
      name: 'Пылкий шаг',
      description: `Немедленно после использования _Фейского шага_, ★СУЩЕСТВО★ получает преимущество к следующему броску атаки до конца своего хода.`,
    },
  ],
}
