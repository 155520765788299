module.exports.PLACE_AGLAROND = 'aglarond'
module.exports.PLACE_ALTUMBEL = 'altumbel'
module.exports.PLACE_AMN = 'amn'
module.exports.PLACE_BLACK_JUNGLE = 'black_jungle'
module.exports.PLACE_CALIMSHAN = 'calimshan'
module.exports.PLACE_CHESSENTA = 'chessenta'
module.exports.PLACE_CHONDATH = 'chondath'
module.exports.PLACE_CHULT = 'chult'
module.exports.PLACE_CORMYR = 'cormyr'
module.exports.PLACE_DALELANDS = 'dalelands'
module.exports.PLACE_DAMARA = 'damara'
module.exports.PLACE_DAMBRATH = 'dambrath'
module.exports.PLACE_DRAGON_COAST = 'dragon_coast'
module.exports.PLACE_DURPAR = 'durpar'
module.exports.PLACE_ESTAGUND = 'estagund'
module.exports.PLACE_FAERUN = 'faerun'
module.exports.PLACE_GREAT_DALE = 'great_dale'
module.exports.PLACE_GREAT_GLACIER = 'great_glacier'
module.exports.PLACE_HALRUAA = 'halruaa'
module.exports.PLACE_HORDELANDS = 'hordelands'
module.exports.PLACE_IMPILTUR = 'impiltur'
module.exports.PLACE_INNER_SEA = 'inner_sea'
module.exports.PLACE_KARA_TUR = 'kara_tur'
module.exports.PLACE_LAKE_OF_STEAM = 'lake_of_steam'
module.exports.PLACE_LANTAN = 'lantan'
module.exports.PLACE_LAPALIIYA = 'lapaliiya'
module.exports.PLACE_LUIREN = 'luiren'
module.exports.PLACE_LUSKAN = 'luskan'
module.exports.PLACE_MAZTICA = 'maztica'
module.exports.PLACE_MHAIR_JUNGLE = 'mhair_jungle'
module.exports.PLACE_MINTARN = 'mintarn'
module.exports.PLACE_MOONSEA = 'moonsea'
module.exports.PLACE_MOONSHAES = 'moonshaes'
module.exports.PLACE_MULHORAND = 'mulhorand'
module.exports.PLACE_MURGHOM = 'murghom'
module.exports.PLACE_NARFELL = 'narfell'
module.exports.PLACE_NELANTHER_ISLES = 'nelanther_isles'
module.exports.PLACE_NETHERIL = 'netheril'
module.exports.PLACE_NIMBRAL = 'nimbral'
module.exports.PLACE_NORTH = 'north'
module.exports.PLACE_OMU = 'omu'
module.exports.PLACE_RASHEMEN = 'rashemen'
module.exports.PLACE_RUATHYM = 'ruathym'
module.exports.PLACE_SAMARACH = 'samarach'
module.exports.PLACE_SAVAGE_NORTH = 'savage_north'
module.exports.PLACE_SEMBIA = 'sembia'
module.exports.PLACE_SEMPHAR = 'semphar'
module.exports.PLACE_SESPECH = 'sespech'
module.exports.PLACE_SHAAR = 'shaar'
module.exports.PLACE_SILVER_MARCHES = 'silver_marches'
module.exports.PLACE_SWORD_COAST = 'sword_coast'
module.exports.PLACE_TASHALAR = 'tashalar'
module.exports.PLACE_TETHYR = 'tethyr'
module.exports.PLACE_THAY = 'thay'
module.exports.PLACE_THESK = 'thesk'
module.exports.PLACE_THINDOL = 'thindol'
module.exports.PLACE_TURMISH = 'turmish'
module.exports.PLACE_UNDERDARK = 'underdark'
module.exports.PLACE_UNTHER = 'unther'
module.exports.PLACE_VAASA = 'vaasa'
module.exports.PLACE_VAR = 'var'
module.exports.PLACE_VAST = 'vast'
module.exports.PLACE_VELDORN = 'veldorn'
module.exports.PLACE_VILHON_REACH = 'vilhon_reach'
module.exports.PLACE_WATERDEEP = 'waterdeep'
module.exports.PLACE_WESTERN_HEARTHLANDS = 'western_hearthlands'
module.exports.PLACE_ZAKHARA = 'zakhara'
