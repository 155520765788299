const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_CATAPULT} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_CATAPULT,
  name: 'Катапульта',
  nameEn: 'Catapult',
  description: `Выберите один предмет весом от 1 до 5 фунтов в пределах дистанции, ни на кого не надетый и никем не переносимый. Предмет пролетает до 90 футов по прямой в выбранном Вами направлении, прежде чем упасть на землю, и останавливается раньше, если ударится о твердую поверхность.

Если предмет ударяется о существо, это существо должно пройти испытание по Ловкости. При неудаче предмет наносит урон цели и останавливается. Когда предмет ударяется обо что-то, и предмет, и то, во что он ударился, получают 3к8 дробящего урона.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 2 круга или выше, максимальный вес предметов, которые Вы можете использовать, увеличивается на 5 фунтов, а урон увеличивается на 1к8 за каждый круг ячейки выше 1.`,
  lvl: 1,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 60,
  componentIdList: [CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_DEX,
    damage: {
      type: DAMAGE_BLUDGEONING,
      diceCount: 3,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 154,
    },
    {
      id: SOURCE_EE,
      page: 17,
    },
  ],
}
