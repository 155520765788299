const {
  SOURCE_MOOT,
} = require('./../../../../sourceList')
const {
  CR_16,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ARCHON,
  CREATURE_TYPE_CELESTIAL,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CAST_MATERIAL,
} = require('./../../../../castComponentList')
const {
  GEAR_PLATE_ARMOR,
} = require('./../../../../gearIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  SPELL_BANISHMENT,
  SPELL_BLADE_BARRIER,
  SPELL_COMMAND,
  SPELL_COMPELLED_DUEL,
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  LANG_ALL,
} = require('./../../../../languageIdList')
const {
  SENSE_TRUE_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_COORDINATED_ASSAULT,
  ABILITY_MOUNT,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  archonDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_ASHEN_RIDER,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Пепельная наездница',
  nameEn: 'Ashen Rider',
  id: CREATURE_ASHEN_RIDER,
  description: [
    {
      header: 'Пепельная наездница',
      text: `Падение древних империй архонтов вызвало у некоторых архонтов горечь и недовольство. Стремясь избежать распространения человеческой цивилизации, эти архонты отправились в Подземное царство. Ужасы этого места сломили их умы, тела и души и превратили их в ужасающих архонтов, известных как пепельные наездники. Когда они едут в мир смертных, испуганные смертные совершают подношения в отчаянной попытке умилостивить их, но пепельные всадники не милосердны и наслаждаются превращением образцов мира смертных в пепел.`,
      source: {
        id: SOURCE_MOOT,
        page: 214,
      },
    },
    ...archonDescriptionList,
  ],
  note: 'Одно подношение — чтобы умилостивить её, когда она появляется. Второе — чтобы отпраздновать, когда она ушла',
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ARCHON,
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MOOT,
    page: 214,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 21,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 19,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 21,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_THUNDER,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
  ],
  cr: CR_16,
  featureList: [
    ABILITY_MOUNT,
    {
      name: 'Аура тишины',
      nameEn: 'Aura of Silence',
      description: `Когда существо начинает свой ход в пределах 30 футов от ★СУЩЕСТВО★, ★СУЩЕСТВО★ может заставить то существо пройти испытание Мудрости СЛ 18, если видит его. При успехе существо получает иммунитет к этой ауре на следующие 24 часа. При провале существо не может говорить и глохнет до начала своего следующего хода.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_WIT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_COMMAND,
          SPELL_COMPELLED_DUEL,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_BANISHMENT,
          SPELL_BLADE_BARRIER,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки _Пепельным клинком_ или две атаки _Снарядом пепла_.`,
    },
    {
      name: 'Пепельный клинок',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 5,
          },
          {
            type: DAMAGE_RADIANT,
            diceCount: 2,
            diceType: 12,
          },
        ],
      },
    },
    {
      name: 'Снаряд пепла',
      description: `Цель не может восстанавливать хиты до начала следующего хода ★СУЩЕСТВО★`,
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 10,
        range: 120,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 4,
          diceType: 10,
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака',
      description: `★СУЩЕСТВО★ совершает атаку _Пепельным клинком_ или _Снарядом пепла_.`,
    },
    {
      id: ABILITY_COORDINATED_ASSAULT,
      attackName: 'Пепельным клинком',
    },
    {
      name: 'Обратить в пепел',
      cost: 3,
      description: `★СУЩЕСТВО★ выбирает целью существо, видимое ★им★ в пределах 60 футов. Цель должна пройти испытание Телосложения СЛ 18, при провале получая 27 (5к10) некротического урона и её максимум хитов понизится на количество, равное полученному урону. Это уменьшение длится, пока цель не закончит длинный отдых. Если максимум хитов цели уменьшается до 0, её тело и всё что она несёт и носит, кроме магических предметов, рассыпается в пепел. Рассыпавшееся в пепел существо может быть воскрешено только заклинанием [Исполнение желаний](SPELL:${SPELL_WISH}).`,
    },
  ],
  genderId: GENDER_FEMALE,
}
