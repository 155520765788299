const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  clockworkDescriptionList,
  clockworkNote,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_OAKEN_BOLTER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {LANG_ONE_OF_ITS_CREATOR} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Дубовый стрелок',
  nameEn: 'Oaken Bolter',
  id: CREATURE_OAKEN_BOLTER,
  description: [
    `Не совсем обычная баллиста, Дубовый Стрелок — это механизм, способный поражать противника на большой дистанции. Его снаряды способны разрывать плоть, пробивать броню, или сталкивать врага к ловушкам или другим механизмам, ориентированным на ближний бой — а на коротких дистанциях, уничтожать мощным взрывом.`,
    ...clockworkDescriptionList,
  ],
  note: clockworkNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MTOF,
    page: 200,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 15,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ONE_OF_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Дубовый стрелок совершает два _Пронзающих выстрела_ или один _Пронзающий выстрел_ и одну атаку _Гарпуном_.`,
    },
    {
      name: 'Пронзающий выстрел',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Пронзающий выстрел',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 7,
        range: {
          normal: 100,
          max: 400,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Гарпун',
      description: `Цель Схвачена (СЛ высвобождения 12). Скорость схваченного этой атакой существа не снижается, но оно не может удаляться от Дубового стрелка. Существо получает 5 (1к10) рубящего урона если оно высвобождается из захвата или если пытается, но терпит неудачу. Бонусным действиям Дубовый стрелок может притянуть схваченное им существо на 20 фт. к себе. Дубовый стрелок может удерживать _Гарпуном_ только одно существо одновременно.`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 7,
        range: {
          normal: 50,
          max: 200,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Взрывной снаряд',
      restore: 5,
      description: `Дубовый стрелок выпускает взрывной снаряд в точку в пределах 120 фт. Каждое существо в пределах 20 фт. от этой точки должно пройти испытание Ловкости (Сл. 15), получая 17 (5к6) огненного урона при провале, или половину этого урона при успехе.`,
    },
  ],
}
