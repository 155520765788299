const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_MASS_POLYMORPH} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MASS_POLYMORPH,
  name: 'Множественное превращение',
  nameEn: 'Mass Polymorph',
  description: `Вы трансформируете до десяти существ по Вашему выбору, которых Вы можете видеть в пределах дистанции. Несогласная цель должна пройти испытание Мудрости, чтобы противостоять трансформации. Несогласный перевёртыш по своему желанию автоматически преуспевает в испытании.

Каждая цель принимает звериный облик по Вашему выбору, Вы можете выбрать один и тот же облик или разные для каждой цели. Новый облик может представлять собой любого зверя, которого Вы видели, чей показатель опасности равен или меньше показателя опасности цели (или половины уровня цели, если у неё нет показателя опасности). Игровые параметры цели, включая ментальные способности, заменяются параметрами выбранного зверя, но цель сохраняет хиты, мировоззрение и личность.

Каждая цель получает временные хиты в количестве, равном количеству хитов её нового облика. Эти временные хиты не могут быть замещены временными хитами, полученными из другого источника. Цель возвращается к своему нормальному облику, когда у неё больше нет временных хитов или когда она умирает. Если заклинание оканчивается раньше этого момента, существо теряет все свои временные хиты и возвращается к нормальному облику.

Существо ограничено в действиях, которые оно может выполнять, природой своего нового облика. Оно не может говорить, колдовать заклинания или делать что-либо еще, требующее рук или речи.

Экипировка цели сливается с новым обликом. Цель не может активировать, использовать, держать или иным образом извлекать выгоду из любого своего снаряжения.`,
  lvl: 9,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кокон гусеницы`,
  duration: {timeId: TIME_HOUR, count: 1},
  needConcentration: true,
  source: {
    id: SOURCE_XGTE,
    page: 157,
  },
}
