const {
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {DAMAGE_COLD} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_IDRotF} = require('./../../../../../sourceList')
const {SPELL_FROST_FINGERS} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FROST_FINGERS,
  name: 'Ледяные пальцы',
  nameEn: 'Frost Fingers',
  description: `Взрыв леденящего холода срывается с кончиков Ваших пальцец 15-футовым конусом.

Все существа в этой области должны пройти испытание Телосложения, получая 2к8 уровна холодом при провале или половину этого урона при успехе.

Холод замораживает немагические жидкости в этой области, которые никто не несёт и не носит.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 2 круга или выше, то урон увеличивается на 1к8 за каждый круг ячейки выше 1.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  rangeComment: `15-футовый конус`,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: [
      PARAM_CON,
    ],
    damage: [
      {
        type: DAMAGE_COLD,
        diceCount: 2,
        diceType: 8,
      },
    ],
  },
  source: {
    id: SOURCE_IDRotF,
    page: 319,
  },
}
