const listToCollectionById = require('./../utils/listToCollectionById')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
  SOURCE_ERRATA_PHB,
  SOURCE_UA_2016_04_04_GOTHIC_HEROES,
  SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
} = require('./sourceList')

const MANEUVER_AMBUSH = 'ambush'
const MANEUVER_BAIT_AND_SWITCH = 'bait_and_switch'
const MANEUVER_BRACE = 'brace'
const MANEUVER_COMMANDER_S_STRIKE = 'commander_s_strike'
const MANEUVER_COMMANDING_PRESENCE = 'commanding_presence'
const MANEUVER_DETECT_HIDDEN_AND_LIES_2016_04_04 = 'detect_hidden_and_lies_2016_04_04'
const MANEUVER_DISARMING_ATTACK = 'disarming_attack'
const MANEUVER_DISTRACTING_STRIKE = 'distracting_strike'
const MANEUVER_EVASIVE_FOOTWORK = 'evasive_footwork'
const MANEUVER_FEINTING_ATTACK = 'feinting_attack'
const MANEUVER_GOADING_ATTACK = 'goading_attack'
const MANEUVER_GRAPPLING_STRIKE = 'grappling_strike'
const MANEUVER_INT_WIS_CHA_SAVING_THROW_2016_04_04 = 'int_wis_cha_saving_throw_2016_04_04'
const MANEUVER_LUNGING_ATTACK = 'lunging_attack'
const MANEUVER_MAINTAIN_CONCENTRATION_DISADVANTAGE_2016_04_04 = 'maintain_concentration_disadvantage_2016_04_04'
const MANEUVER_MANEUVERING_ATTACK = 'maneuvering_attack'
const MANEUVER_MENACING_ATTACK = 'menacing_attack'
const MANEUVER_PARRY = 'parry'
const MANEUVER_PRECISION_ATTACK = 'precision_attack'
const MANEUVER_PUSHING_ATTACK = 'pushing_attack'
const MANEUVER_QUICK_TOSS = 'quick_toss'
const MANEUVER_RALLY = 'rally'
const MANEUVER_RESTRAINING_STRIKE_2019_04_11 = 'restraining_strike_2019_04_11'
const MANEUVER_RIPOSTE = 'riposte'
const MANEUVER_SNIPE_2019_04_11 = 'snipe_2019_04_11'
const MANEUVER_SWEEPING_ATTACK = 'sweeping_attack'
const MANEUVER_TACTICAL_ASSESSMENT = 'tactical_assessment'
const MANEUVER_TRIP_ATTACK = 'trip_attack'

const maneuverList = [
  {
    id: MANEUVER_EVASIVE_FOOTWORK,
    name: `Активное уклонение`,
    nameEn: `Evasive Footwork`,
    description: `При перемещении Вы можете потратить одну _Кость превосходства_, совершить её бросок и добавить выпавшее значение к КД, пока не прекратите перемещение.`,
    source: {
      id: SOURCE_PHB,
      page: 74,
    },
  },
  {
    id: MANEUVER_LUNGING_ATTACK,
    name: `Атака с выпадом`,
    nameEn: `Lunging Attack`,
    description: `Если Вы в свой ход совершаете рукопашную атаку оружием, Вы можете потратить одну _Кость превосходства_, чтобы увеличить досягаемость этой атаки на 5 футов. При попадании Вы добавляете _Кость превосходства_ к броску урона этой атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 74,
    },
  },
  {
    id: MANEUVER_MANEUVERING_ATTACK,
    name: `Атака с манёвром`,
    nameEn: `Maneuvering Attack`,
    description: `Если Вы попадаете по существу атакой оружием, Вы можете потратить одну _Кость превосходства_, чтобы один из Ваших товарищей смог переместиться в более выгодное положение. Вы добавляете _Кость превосходства_ к броску урона этой атаки и выбираете дружественное существо, которое может видеть или слышать Вас. Это существо может реакцией переместиться на расстояние до половины своей скорости, не провоцируя при этом атаки от цели Вашей атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 74,
    },
  },
  {
    id: MANEUVER_MENACING_ATTACK,
    name: `Атака с угрозой`,
    nameEn: `Menacing Attack`,
    description: `Если Вы попадаете по существу атакой оружием, Вы можете потратить одну _Кость превосходства_, чтобы попытаться напугать цель. Вы добавляете _Кость превосходства_ к броску урона этой атаки, а цель должна пройти испытание Мудрости. При провале цель испугана Вами до конца Вашего следующего хода.`,
    source: {
      id: SOURCE_PHB,
      page: 74,
    },
  },
  {
    id: MANEUVER_FEINTING_ATTACK,
    name: `Атака с финтом`,
    nameEn: `Feinting Attack`,
    description: `Вы можете в свой ход потратить одну _Кость превосходства_ и бонусным действием совершить финт, выбрав в качестве цели одно существо в пределах 5 футов. Следующий бросок атаки по этому существу на этом ходу Вы совершаете с преимуществом. Если эта атака попадает, добавьте _Кость превосходства_ к броску урона.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 75,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Эффекты теперь работают только на этом ходу',
        page: 1,
      },
    ],
  },
  {
    id: MANEUVER_DISARMING_ATTACK,
    name: `Обезоруживающая атака`,
    nameEn: `Disarming Attack`,
    description: `Если Вы попадаете по существу атакой оружием, Вы можете потратить одну _Кость превосходства_, чтобы попытаться обезоружить противника, заставляя его выронить один предмет по Вашему выбору, который он держит. Вы добавляете _Кость превосходства_ к броску урона атаки, а цель должна пройти испытание Силы. В случае провала она роняет выбранный Вами предмет. Предмет падает у её ног.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_TRIP_ATTACK,
    name: `Опрокидывающая атака`,
    nameEn: `Trip Attack`,
    description: `Если Вы попадаете по существу атакой оружием, Вы можете потратить одну _Кость превосходства_, чтобы попытаться сбить цель с ног. Вы добавляете _Кость превосходства_ к броску урона атаки, и, если размер цели Большой или меньше, она должна пройти испытание Силы. В случае провала Вы сбиваете цель с ног.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_RIPOSTE,
    name: `Ответный удар`,
    nameEn: `Riposte`,
    description: `Если существо промахивается по Вам рукопашной атакой, Вы можете реакцией потратить одну _Кость превосходства_, чтобы совершить рукопашную атаку оружием по этому существу. Если Вы попадаете, Вы добавляете _Кость превосходства_ к броску урона этой атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_DISTRACTING_STRIKE,
    name: `Отвлекающий удар`,
    nameEn: `Distracting Strike`,
    description: `Если Вы попадаете по существу атакой оружием, Вы можете потратить одну _Кость превосходства_, чтобы отвлечь существо, открывая его для Ваших союзников. Вы добавляете _Кость превосходства_ к броску урона этой атаки. Следующий бросок атаки по этой цели любого существа кроме Вас совершается с преимуществом, если атака совершается до начала Вашего следующего хода.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_PARRY,
    name: `Парирование`,
    nameEn: `Parry`,
    description: `Если другое существо причиняет Вам урон рукопашной атакой, Вы можете реакцией потратить одну _Кость превосходства_, чтобы уменьшить урон на величину, равную броску _Кости превосходства_ + Ваш модификатор Ловкости.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_GOADING_ATTACK,
    name: `Провоцирующая атака`,
    nameEn: `Goading Attack`,
    description: `Если Вы попадаете по существу атакой оружием, Вы можете потратить одну _Кость превосходства_, чтобы попытаться спровоцировать противника атаковать Вас. Вы добавляете _Кость превосходства_ к броску урона этой атаки, а цель должна пройти испытание Мудрости. При провале цель до конца Вашего следующего хода совершает с помехой броски атаки по всем целям, кроме Вас.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_RALLY,
    name: `Сплочение`,
    nameEn: `Rally`,
    description: `Вы можете в свой ход бонусным действием потратить одну _Кость превосходства_, чтобы поддержать решимость одного из Ваших спутников. Если Вы это сделаете, выберите дружественное существо, которое может видеть или слышать Вас. Это существо получает временные хиты, равные броску кости превосходства + Ваш модификатор Харизмы.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_PUSHING_ATTACK,
    name: `Толкающая атака`,
    nameEn: `Pushing Attack`,
    description: `Если Вы попадаете по существу атакой оружием, Вы можете потратить одну _Кость превосходства_, чтобы попытаться оттолкнуть цель. Вы добавляете _Кость превосходства_ к броску урона атаки, и, если размер цели Большой или меньше, она должна пройти испытание Силы. При провале Вы толкаете цель на расстояние до 15 футов от себя.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_PRECISION_ATTACK,
    name: `Точная атака`,
    nameEn: `Precision Attack`,
    description: `Если Вы совершаете бросок атаки оружием по существу, Вы можете потратить одну _Кость превосходства_, чтобы добавить её к броску. Вы можете использовать этот приём до или после совершения броска атаки, но до применения эффектов атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_COMMANDER_S_STRIKE,
    name: `Удар командующего`,
    nameEn: `Commander’s Strike`,
    description: `Если Вы совершаете в свой ход действие Атака, Вы можете отказаться от одной из Ваших атак и бонусным действием направить удар одного из Ваших соратников. Если Вы это сделаете, выберите дружественное существо, которое может видеть или слышать Вас и потратьте одну _Кость превосходства_. Это существо может немедленно совершить реакцией одну атаку оружием, добавив _Кость превосходства_ к броску урона этой атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_SWEEPING_ATTACK,
    name: `Широкая атака`,
    nameEn: `Sweeping Attack`,
    description: `Если Вы попадаете по существу атакой рукопашным оружием, Вы можете потратить одну _Кость превосходства_, чтобы попытаться причинить урон другому существу этой же атакой. Выберите другое существо в пределах 5 футов от первоначальной цели и в пределах Вашей досягаемости. Если исходный бросок атаки попал бы по второму существу, оно получает урон, равный броску _Кости превосходства_. Урон того же вида, что и для исходной атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 75,
    },
  },
  {
    id: MANEUVER_AMBUSH,
    name: `Засада`,
    nameEn: `Ambush`,
    description: `Когда Вы совершаете проверку Ловкости (Скрытность) или бросок инициативы, то можете добавить _Кость превосходства_ к броску, если Вы дееспособны.`,
    source: {
      id: SOURCE_TCoE,
      page: 60,
    },
  },
  {
    id: MANEUVER_BAIT_AND_SWITCH,
    name: `Заманить и подменить`,
    nameEn: `Bait and Switch`,
    description: `Когда Вы находитесь в пределах 5 футов от союзника на Вашем ходу, то можете потратить _Кость превосходства_ и поменяться с ним местами, потратив на это 5 футов передвижения. Это передвижение не провоцирует атак по возможности.

До начала Вашего следующего хода Вы можете добавить результат броска _Кости превосходства_ к КД (своему или того существа, по Вашему выбору).`,
    source: {
      id: SOURCE_TCoE,
      page: 60,
    },
  },
  {
    id: MANEUVER_BRACE,
    name: `Подсечка`,
    nameEn: `Brace`,
    description: `Когда видимый Вами противник передвигается в пределах дальности носимого Вами рукопашного оружия, Вы можете реакцией потратить _Кость превосходства_ и совершить по нему одну атаку этим оружием.

Если атака попадает, добавьте _Кость превосходства_ к урону этой атаки.`,
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: MANEUVER_RESTRAINING_STRIKE_2019_04_11,
    name: `Связывающий удар`,
    nameEn: `Restraining Strike`,
    description: `Сразу после того, как Вы на своём ходу попали по существу рукопашной атакой оружием, Вы можете бонусным действием потратить _Кость превосходства_ и попытаться схватить цель. Добавьте _Кость превосходства_ к Вашей проверке Силы (Атлетика). Пока цель схвачена Вами таким образом, она обездвижена.`,
    source: {
      id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
      page: 5,
    },
  },
  {
    id: MANEUVER_GRAPPLING_STRIKE,
    name: `Захватывающий удар`,
    nameEn: `Grappling Strike`,
    description: `Сразу после того, как Вы на своём ходу попали по существу рукопашной атакой, Вы можете бонусным действием потратить _Кость превосходства_ и попытаться схватить цель. Добавьте _Кость превосходства_ к Вашей проверке Силы (Атлетика).`,
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: MANEUVER_COMMANDING_PRESENCE,
    name: `Командирский напор`,
    nameEn: `Commanding Presence`,
    description: `Когда Вы совершаете проверку Харизмы (Обмана), (Выступления) или (Убеждения), то можете добавить _Кость превосходства_ к броску.`,
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: MANEUVER_QUICK_TOSS,
    name: `Быстрый бросок`,
    nameEn: `Quick Toss`,
    description: `Вы можете бонусным действием потратить _Кость превосходства_ и совершить дальнобойную атаку оружием, имеющим свойство «Метательное». Вы можете вынуть оружие частью этой атаки. Если Вы попали, добавьте _Кость превосходства_ к урону этой атаки.`,
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: MANEUVER_SNIPE_2019_04_11,
    name: `Выстрел`,
    nameEn: `Snipe`,
    description: `Вы можете бонусным действием потратить _Кость превосходства_ и совершить дальнобойную атаку оружием. Вы можете вынуть оружие частью этой атаки. Если Вы попали, добавьте _Кость превосходства_ к урону этой атаки.`,
    source: {
      id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
      page: 5,
    },
  },
  {
    id: MANEUVER_TACTICAL_ASSESSMENT,
    name: `Тактическая оценка`,
    nameEn: `Tactical Assessment`,
    description: `Когда Вы совершаете проверку Интеллекта (Анализ), (Истории) или Мудрости (Проницательности), то можете добавить _Кость превосходства_ к броску.`,
    source: {
      id: SOURCE_TCoE,
      page: 61,
    },
  },
  {
    id: MANEUVER_DETECT_HIDDEN_AND_LIES_2016_04_04,
    name: `Обнаружение скрытых и лжецов`,
    nameEn: `Detect Hidden and Lies`,
    description: `Когда Вы совершаете проверку Мудрости (Внимательности) для обнаружения скрытых существ или предметов или Мудрости (Проницательность), чтобы понять, не врут ли вам, то можете добавить _Кость превосходства_ к броску. Вы можете использовать этот приём до или после броска, но до применения эффектов.`,
    source: {
      id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
      page: 5,
    },
  },
  {
    id: MANEUVER_INT_WIS_CHA_SAVING_THROW_2016_04_04,
    name: `Испытание Интеллекта, Мудрости или Харизмы`,
    nameEn: `Intelligence, Wisdom, or Charisma Saving Throw`,
    description: `Когда Вы совершаете испытание Интеллекта, Мудрости или Харизмы, то можете добавить _Кость превосходства_ к броску. Вы можете использовать этот приём до или после броска, но до применения эффектов.`,
    source: {
      id: SOURCE_UA_2016_04_04_GOTHIC_HEROES,
      page: 2,
    },
  },
  {
    id: MANEUVER_MAINTAIN_CONCENTRATION_DISADVANTAGE_2016_04_04,
    name: `Помеха на поддерждание концентрации`,
    nameEn: `Maintain Concentration Disadvantage`,
    description: `Если Вы попадаете по существу атакой оружием, Вы можете потратить одну _Кость превосходства_, чтобы добавить её к броску урона этой атаки.

Если эта атака заставляет цель должна совершить испытание Телосложения для поддержания концентрации, она получает помеху на это испытание.`,
    source: {
      id: SOURCE_UA_2016_04_04_GOTHIC_HEROES,
      page: 2,
    },
  },
]

module.exports = maneuverList

module.exports.maneuverCollection = listToCollectionById(maneuverList)

module.exports.MANEUVER_AMBUSH = MANEUVER_AMBUSH
module.exports.MANEUVER_BAIT_AND_SWITCH = MANEUVER_BAIT_AND_SWITCH
module.exports.MANEUVER_BRACE = MANEUVER_BRACE
module.exports.MANEUVER_COMMANDER_S_STRIKE = MANEUVER_COMMANDER_S_STRIKE
module.exports.MANEUVER_COMMANDING_PRESENCE = MANEUVER_COMMANDING_PRESENCE
module.exports.MANEUVER_DETECT_HIDDEN_AND_LIES_2016_04_04 = MANEUVER_DETECT_HIDDEN_AND_LIES_2016_04_04
module.exports.MANEUVER_DISARMING_ATTACK = MANEUVER_DISARMING_ATTACK
module.exports.MANEUVER_DISTRACTING_STRIKE = MANEUVER_DISTRACTING_STRIKE
module.exports.MANEUVER_EVASIVE_FOOTWORK = MANEUVER_EVASIVE_FOOTWORK
module.exports.MANEUVER_FEINTING_ATTACK = MANEUVER_FEINTING_ATTACK
module.exports.MANEUVER_GOADING_ATTACK = MANEUVER_GOADING_ATTACK
module.exports.MANEUVER_GRAPPLING_STRIKE = MANEUVER_GRAPPLING_STRIKE
module.exports.MANEUVER_INT_WIS_CHA_SAVING_THROW_2016_04_04 = MANEUVER_INT_WIS_CHA_SAVING_THROW_2016_04_04
module.exports.MANEUVER_LUNGING_ATTACK = MANEUVER_LUNGING_ATTACK
module.exports.MANEUVER_MAINTAIN_CONCENTRATION_DISADVANTAGE_2016_04_04 = MANEUVER_MAINTAIN_CONCENTRATION_DISADVANTAGE_2016_04_04
module.exports.MANEUVER_MANEUVERING_ATTACK = MANEUVER_MANEUVERING_ATTACK
module.exports.MANEUVER_MENACING_ATTACK = MANEUVER_MENACING_ATTACK
module.exports.MANEUVER_PARRY = MANEUVER_PARRY
module.exports.MANEUVER_PRECISION_ATTACK = MANEUVER_PRECISION_ATTACK
module.exports.MANEUVER_PUSHING_ATTACK = MANEUVER_PUSHING_ATTACK
module.exports.MANEUVER_QUICK_TOSS = MANEUVER_QUICK_TOSS
module.exports.MANEUVER_RALLY = MANEUVER_RALLY
module.exports.MANEUVER_RESTRAINING_STRIKE_2019_04_11 = MANEUVER_RESTRAINING_STRIKE_2019_04_11
module.exports.MANEUVER_RIPOSTE = MANEUVER_RIPOSTE
module.exports.MANEUVER_SNIPE_2019_04_11 = MANEUVER_SNIPE_2019_04_11
module.exports.MANEUVER_SWEEPING_ATTACK = MANEUVER_SWEEPING_ATTACK
module.exports.MANEUVER_TACTICAL_ASSESSMENT = MANEUVER_TACTICAL_ASSESSMENT
module.exports.MANEUVER_TRIP_ATTACK = MANEUVER_TRIP_ATTACK
