const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {DAMAGE_FORCE} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_DARK_STAR} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_DARK_STAR,
  name: 'Тёмная звезда',
  nameEn: 'Dark Star',
  description: `Заклинание создаёт сферу с центром на точке, выбранной Вами в пределах дальности. Сфера может иметь радиус до 40 футов. Область внутри сферы заполнена магической тьмой и сокрушительной гравитацией.

На всё время действия заклинания область внутри сферы считается труднопроходимой местностью. Существа с тёмным зрением не могут видеть сквозь эту магическую тьму и немагический свет не может её осветить. Звуки не могут быть созданы внутри неё и не могут проходить сквозь неё. Все существа внутри сферы глухи, а все существа и предметы, находящиеся целиком внутри неё, получают иммунитет к урону звуком. Сотворение заклинаний с вербальным компонентом внутри сферы невозможно.

Все существа, впервые за ход входящие в сферу или начинающие там ход, должны пройти испытание Телосложения, получая 8к10 урона силовым полем при провале или половину при успехе. Существо, чьи хиты опускаются до 0 внутри сферы, распадается. Распавшееся существо и всё, что оно несло и носило, кроме магических предметов, превращается в кучку мелкой серой пыли.`,
  lvl: 8,
  magicSchoolId: MAGIC_EVOCATION,
  range: 150,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `осколок оникса и капля крови заклинателя; оба компонента поглощаются заклинанием`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  isDunamisBased: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_FORCE,
      diceCount: 8,
      diceType: 10,
    },
  },
  source: {
    id: SOURCE_EGTW,
    page: 187,
  },
}
