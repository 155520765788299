const {SOURCE_TCoE} = require('./../../../sourceList')
const {PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST} = require('./../../../pcSubClassIdList')
const {
  FEATURE_FORM_OF_THE_BEAST,
  FEATURE_BESTIAL_SOUL,
  FEATURE_INFECTIOUS_FURY,
  FEATURE_CALL_THE_HUNT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_FORM_OF_THE_BEAST,
    name: `Форма зверя`,
    nameEn: `Form of the Beast`,
    lvl: 3,
    text: `Входя в ярость, Вы можете трансформироваться, раскрывая свою внутреннюю звериную мощь. Пока ярость не закончится, Вы проявляете своё природное оружие. Оно считается для Вас простым рукопашным оружием, и Вы добавляете свой модификатор Силы к его броскам атаки и урона.

Вы выбираете форму оружия каждый раз, когда впадаете в ярость:

# Укус

Ваш рот превращается в звериную морду или огромные челюсти (на Ваш выбор). Ваш _Укус_ теперь наносит 1к8 колющего урона при попадании. Один раз на каждом своём ходу, когда Вы наносите урон существу _Укусом_, то восстанавливаете количество хитов, равное Вашему бонусу мастерства, если у вас меньше половины хитов в момент удара.

# Клешни

Каждая из Ваших рук превращается в клешню, которую можно использовать как оружие (пока Вы в ней ничего не держите). _Клешня_ наносит 1к6 рубящего урона при попадании. Один раз на каждом своём ходу, когда Вы атакуете _Клешнёй_ действием Атака, Вы можете частью этого же действия совершить ещё одну атаку _Клешнёй_.

# Хвост

У Вас отрастает шипастый хвост, который наносит 1к8 колющего урона при попадании и имеет свойство «Досягаемость». Если видимое Вами в пределах 10 футов существо попадает по Вам атакой, Вы можете реакцией взмахнуть _Хвостом_, и добавить 1к8 к Вашему КД, что потенциально может привести к промаху.`,
    source: {
      id: SOURCE_TCoE,
      page: 30,
    },
  },
  {
    id: FEATURE_BESTIAL_SOUL,
    name: `Звериная душа`,
    nameEn: `Bestial Soul`,
    lvl: 6,
    text: `Дикая мощь внутри Вас растёт, делая природное оружие [Формы зверя](FEATURE:${FEATURE_FORM_OF_THE_BEAST}) магическим в вопросе преодоления сопротивления и иммунитета к немагическим атакам и урону.

Вы также можете изменить свой облик, адаптируясь к окружающей среде. Когда Вы заканчиваете короткий или длинный отдых, выберите одно из следующих преимуществ, которое сохраняется до тех пор, пока Вы не закончите следующий короткий или длинный отдых:

* Вы получаете скорость плавания, равную скорости ходьбы, и можете дышать под водой.
* Вы получаете скорость лазания, равную скорости ходьбы, и можете лазать по сложным поверхностям, включая потолки, без совершения проверок характеристик.
* Прыгая, Вы можете совершить проверку Силы (Атлетика) и увеличить прыжок на количество футов, равное результату проверки. Вы можете совершить эту специальную проверку только один раз в ход.`,
    source: {
      id: SOURCE_TCoE,
      page: 30,
    },
  },
  {
    id: FEATURE_INFECTIOUS_FURY,
    name: `Заразительная ярость`,
    nameEn: `Infectious Fury`,
    lvl: 10,
    text: `Когда Вы попадаете по существу своим природным оружием во время ярости, Ваш внутренний зверь может проклясть Вашу цель неистовым бешенством. Цель должна пройти испытание Мудрости (СЛ = 8 + Ваш можификатор Телосложения + Ваш бонус мастерства) или получит один из следующих эффектов (на Ваш выбор):

* Цель должна реакцией совершить рукопашную атаку по другому видимому Вами существу по Вашему выбору.
* Цель получает 2к12 психического урона. 

Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 31,
    },
  },
  {
    id: FEATURE_CALL_THE_HUNT,
    name: `Зов охоты`,
    nameEn: `Call the Hunt`,
    lvl: 14,
    text: `Внутренний зверь становится настолько могучим, что теперь Вы можете распространять его свирепость на других и получать стойкость, когда они присоединятся к Вашей охоте.

Впав в ярость, Вы можете выбрать других видимых Вами в пределах 30 футов согласных существ, в количестверавном Вашему модификатору Телосложения (минимум одно). Вы получаете по 5 временных хитов за каждое существо, согласившееся на _Зов охоты_. Пока ярость не закончится, эти существа могут один раз в свой ход, поразив цель атакой и нанеся ей урон, добавить к урону к6.

Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 31,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST,
  })
)
