const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_MYCONID,
  CREATURE_TYPE_PLANT,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_MYCONID_ADULT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {myconidDescriptionList} = require('./../../../../textCommonParts')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Взрослый миконид',
  nameEn: 'Myconid Adult',
  id: CREATURE_MYCONID_ADULT,
  description: myconidDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
    CREATURE_TYPE_MYCONID,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 207,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 7,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Споры страдания',
      description: `Если ★СУЩЕСТВО★ получает урон, все остальные микониды в пределах 240 футов чувствуют ★его★ боль.`,
    },
    {
      name: 'Слабость на солнце',
      description: `Находясь на солнечном свету, ★СУЩЕСТВО★ совершает с помехой проверки характеристик, броски атаки и испытания. ★СУЩЕСТВО★ умирает, если проводит больше 1 часа под прямыми солнечными лучами.`,
    },
  ],
  actionList: [
    {
      name: 'Кулак',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 4,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 2,
            diceType: 4,
          },
        ],
      },
    },
    {
      name: 'Усмиряющие споры',
      limit: {
        count: 3,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ выпускает споры на одно существо, которое видит в пределах 5 футов. Цель должна пройти испытание Телосложения СЛ 11, иначе станет ошеломлённой на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
    },
    {
      name: 'Споры взаимопонимания',
      description: `★СУЩЕСТВО★ выпускает споры в радиусе 20 футов. Эти споры обходят углы и воздействуют только на существ с Интеллектом 2 и выше, которые не являются нежитью, конструктами и элементалями. Попавшие под действие существа могут телепатически общаться друг с другом, если находятся в пределах 30 футов друг от друга. Эффект длится 1 час.`,
    },
  ],
  genderId: GENDER_MALE,
}
