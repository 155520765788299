const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MGC_TYPE_TATTOO} = require('./../../../../../magicItemTypeList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {GEAR_SHIELD} = require('./../../../../../gearIdList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    id,
    rarity,
    ac,
    acTitle,
  }
) => ({
  id,
  name: `Защитная татуировка, ${acTitle} КД`,
  nameEn: `Barrier Tattoo, ${acTitle} AC`,
  type: MGC_TYPE_TATTOO,
  rarity,
  attunement: true,
  description: `Эта магическая татуировка выглядит как защитные изображения и использует чернила, выглядящие как жидкий металл. Она наносится специальной иглой.

# Настройка на татуировку

Для настройки на _Защитную татуировку_, Вам нужно держать иглу у своей кожи в том месте, где Вы хотите нанести _Татуировку_, надавливая на иглу всё время настройки. Когда настройка завершится, игла превратится в чернила, которые станут _Защитной татуировкой_ на коже.

Если Ваша настройка на _Татуировку_ завершится, она исчезнет, а игла снова появится в Вашем пространстве.

# Защита

Пока Вы не носите доспехов, _Защитная Татуировка_ даёт Вам КД **${ac}**. Вы можете использовать [щит](GEAR:${GEAR_SHIELD}) и всё равно получать это преимущество.`,
  genderId: GENDER_FEMALE,
  siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 176,
  },
})
