const {CREATURE_BEL} = require('./../../creatureIdList')
const {PC_CLASS_PALADIN} = require('./../../pcClassIdList')
const {
  CREATURE_GRAZZT,
  CREATURE_ORCUS,
} = require('./../../creatureIdList')
const {
  SOURCE_DMG,
  SOURCE_GAME_BG_3,
  SOURCE_MOOT,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
  SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES,
  SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
  SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
  SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
  SOURCE_XGTE,
} = require('./../../sourceList')

const {
  PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST,
  PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST_2016_12_19,
  PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST_2017_06_05,
  PC_SUBCLASS_PALADIN_OATH_OF_DEVOTION,
  PC_SUBCLASS_PALADIN_OATH_OF_GLORY,
  PC_SUBCLASS_PALADIN_OATH_OF_HEROISM_2019_09_18,
  PC_SUBCLASS_PALADIN_OATH_OF_REDEMPTION,
  PC_SUBCLASS_PALADIN_OATH_OF_REDEMPTION_2017_03_27,
  PC_SUBCLASS_PALADIN_OATH_OF_THE_ANCIENTS,
  PC_SUBCLASS_PALADIN_OATH_OF_THE_CROWN,
  PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS,
  PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS_2020_01_14,
  PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19,
  PC_SUBCLASS_PALADIN_OATH_OF_VENGEANCE,
  PC_SUBCLASS_PALADIN_OATHBREAKER,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятва покорения',
    nameEn: 'Oath of Conquest',
    description: [
      `Клятва покорения взывает к паладинам, которые ищут славы в бою и покорения своих врагов.

Для этих паладинов недостаточно установить порядок. Они должны уничтожить силы хаоса. Иногда, принёсших эту клятву называют рыцарями-тиранами или железными воителями. Они часто объединяются в беспощадные ордена, которые служат богам или философии войны и хорошо организованной мощи.

Некоторые из паладинов заходят так далеко, что связываются с силами Девяти Преисподен, оценивая главенство закона над торжеством милосердия. У архидьявола [Бэла](CREATURE:${CREATURE_BEL}), военачальника Аверно, есть множество таких паладинов — названных адскими рыцарями, коих он считает своей наиболее верной опорой. Адские рыцари покрывают свои доспехи трофеями, взятыми у падших врагов — зловещее предупреждение любому, кто отважится выступить против них и декретов их владык. Другие паладины этой клятвы часто яростно выступают против них, полагая, что адские рыцари зашли слишком далеко во тьму`,
      {
        header: `Догматы покорения`,
        text: `Паладин, который приносит эту клятву, выжигает догматы покорения на своём плече.

# Тушите огонь надежды

Недостаточно просто победить врага в бою. Ваша победа должна быть настолько всеобъемлющей, что воля врагов, сражающихся против Вас должна быть уничтожена навсегда. От клинка может пасть жизнь. От страха может пасть империя.

# Правь железным кулаком

Как только Вы покорили что-то, не терпите никакого инакомыслия. Ваше слово — закон. Те, кто подчиняются ему, будут вознаграждены. Ослушавшиеся будут наказаны в пример тем, кто может за ними последовать.

# Сила превыше всего

Вы будете править, пока не явится кто-то более сильный. Тогда Вы должны стать могущественней и принять вызов или пасть.`,
        source: {
          id: SOURCE_XGTE,
          page: 48,
        },
      },
    ],
    note: {
      text: `Покорение звучит заманчиво. А для этого обязательно выходить из дома? Ведь у меня есть рыбка по имени Сильгар, и ей станет грустно, если я уйду надолго.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 48,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST_2016_12_19,
    pcClassId: PC_CLASS_PALADIN,
    releasedAs: PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST,
    isReady: false,
    name: 'Клятва покорения',
    nameEn: 'Oath of Conquest',
    source: {
      id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST_2017_06_05,
    pcClassId: PC_CLASS_PALADIN,
    releasedAs: PC_SUBCLASS_PALADIN_OATH_OF_CONQUEST,
    isReady: false,
    name: 'Клятва покорения',
    nameEn: 'Oath of Conquest',
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_DEVOTION,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятва преданности',
    nameAlt: 'Клятва верности',
    nameEn: 'Oath of Devotion',
    description: [
      `Клятва паладина связывает его с возвышенными идеалами справедливости, добродетели и порядка.

Иногда называемые кавалерами, белыми рыцарями или святыми воителями, эти паладины подходят под идеал рыцаря в сияющих доспехах, действуя с честью в стремлении к справедливости и высшему благу. Они поддерживают самые высокие нормы поведения, и некоторые, к лучшему или худшему, приводят мир к тем же стандартам.

Многие давшие эту клятву, почитают богов добра и закона, и следуют их догматам в качестве доказательства своей преданности. Они почитают ангелов — совершенных слуг добра — за свои идеалы, и изображают ангельские крылья на шлемах и гербах.`,
      {
        header: `Клятва верности`,
        text: `Следуя идеалам рыцарей в сияющих доспехах, Вы действуете с честью и добродетелью во имя высшего блага, защищая слабых.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
      {
        header: `Догматы преданности`,
        text: `Хотя точные слова и смысл клятвы преданности варьируются, её паладины разделяют следующие принципы:

# Честность

Не лгите и не обманывайте. Да будет слово Ваше незыблемо.

# Отвага

Никогда не бойтесь действовать, хотя и проявляя мудрость в осторожности.

# Сострадание

Помогайте другим, защищайте слабых и карайте тех, кто угрожает им. Проявляйте милосердие к врагам, но смягчайтесь мудро.

# Честь

Относитесь к людям по справедливости, и пусть Ваши благородные дела будут примером для них. Делайте как можно больше добра, и как можно меньше дурного.

# Долг

Отвечайте за свои действия и их последствия, защищая тех, кого оберегаете, и подчиняйтесь тем, кто имеет власть над Вами. `,
        source: {
          id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
          page: 2,
        },
      },
      {
        header: `Догмы Клятвы верности`,
        text: `Паладины Клятвы верности исповедуют следующие догмы

# Отвага

Смело бросайся в бой.

# Сочувствие

Проявляй милосердие там, где это разумно и помогай тем, кто в этом нуждается.

# Долг

Делай то, что должен, подчиняйся справедливым законам и поддерживай тех, кто доверился тебе.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
      {
        header: `Клятва преданности`,
        text: `Ты утираешь слезу, задержавшуюся на измученной щеке. Ты обнимаешь их, шепчешь слова утешения — и поднимаешься во весь рост, заслоняя их от мучителей. Чтобы слабые стали сильными, паладин, они должны увидеть образец верности. Образец силы. Тебя.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: [
      {
        id: SOURCE_PHB,
        page: 86,
      },
      {
        id: SOURCE_SRD,
        page: 32,
      },
    ],
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_GLORY,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятва славы',
    nameEn: 'Oath of Glory',
    description: [
      `Паладины, приносящие Клятву Славы, верят что им и их спутникам суждено прославиться героическими подвигами. Они усердно тренируются и ободряют своих товарищей, чтобы быть готовыми, когда судьба позовет.`,
      {
        header: `Догматы славы`,
        text: `Догматы славы ведут паладина к героическим свершениям, что однажды станут легендами.

# Действия важнее слов

Стремитесь прославиться делами, а не словами.

# Трудности — всего лишь испытания

Каждое препятствие помогает проверять Ваши способности и закалять решимость.

# Совершенствуй тело

Над телом, как над неотёсаным камнем, нужно трудиться, чтобы раскрыть его потенциал.

# Крепи дух

Лишь самодисциплина позволит преодолеть слабости, которые иначе омрачат Вашу славу и славу Ваших друзей.`,
        source: [
          {
            id: SOURCE_MOOT,
            page: 30,
          },
          {
            id: SOURCE_TCoE,
            page: 77,
          },
        ],
      },
    ],
    note: {
      text: `Ты. Да, ты. Ты — победитель в космической лотерее удачи. Ах, ты ещё и собираешься абсолютно всем об этом рассказать во всех подробностях? Потрясающе.`,
      author: `Таша`,
    },
    source: [
      {
        id: SOURCE_MOOT,
        page: 30,
      },
      {
        id: SOURCE_TCoE,
        page: 77,
      },
    ],
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_HEROISM_2019_09_18,
    pcClassId: PC_CLASS_PALADIN,
    releasedAs: PC_SUBCLASS_PALADIN_OATH_OF_GLORY,
    isReady: true,
    name: 'Клятва героизма',
    nameEn: 'Oath of Heroism',
    description: [
      `Клятва героизма — это обязательство пройти предопределенный путь, проложенный для Вас богами.

По какой-то причине бог или группа богов включили Вас в свои дела. Вы не вынужденный герой, но тот, кто глубоко убеждён что великие свершения — это именно то, чем Вам нужно заниматься. Вы усердно тренируетесь, совершенствуя тело и оттачивая навыки. И когда судьба зовёт — Вы уже готовы.`,
      {
        header: `Догматы героизма`,
        text: `Догматы Клятвы героизма отражают стремление паладина выполнить своё призвание героя, достойного легенд.

# Действия важнее слов

Стремитесь прославиться делами, а не словами.

# Трудности — всего лишь испытания

Каждое препятствие помогает проверять Ваши способности и закалять решимость.

# Прими судьбу

Вы не выбирали этот путь, но Вам по нему идти. И он приведёт Вас к легенде.

# Совершенствуй тело

Над телом, как над неотёсаным камнем, нужно трудиться, чтобы раскрыть его потенциал.`,
        source: {
          id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
          page: 2,
        },
      },
    ],
    source: {
      id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_REDEMPTION,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятва искупления',
    nameEn: 'Oath of Redemption',
    description: [
      `Клятва Искупления ставит паладина на тяжёлый путь, требующий от святого воителя использовать силу лишь как самую крайнюю меру. Выбравшие следовать этой клятве верят, что каждый может быть спасён и что путь благожелательности и правосудия доступен каждому. Эти паладины ищут злых существ с надеждой обратить их к свету, а противников убивают только если это обязательно спасёт чужие жизни. Паладины, идущие этим путём известны как искупители.

Хоть искупители и идеалисты, они не дураки. Искупители знают, что нежить, демоны, дьяволы и другие сверхъестественные угрозы могут быть злыми по своей сути. Против таких врагов паладины, принесшие эту клятву, используют всю ярость своего оружия и заклинаний. И всё же искупители молятся, чтобы однажды даже злобные существа нашли своё искупление.`,
      {
        header: `Догматы искупления`,
        text: `Догматы Клятвы Искупления ведут паладина дорогой высоких идеалов мира и правосудия.

# Мир

Насилие — это оружие, которое следует применять в крайнем случае. Дипломатия и понимание — путь к долговременному миру.

# Невинность

Все приходят в этот мир невинными, и только окружающая среда или влияние темных сил приводит их ко злу. Подав правильный пример и залечив раны глубоко израненного мира, можно вернуть всех на праведный путь.

# Терпение

Изменения требуют времени. Тем, кто ходил по пути зла, надо напоминать о честности и истине. Посадив семя праведности в существе, нужно день за днём работать, чтобы оно выжило и проросло.

# Мудрость

Твоё сердце и разум должны быть чисты, ибо в конце концов тебе придется признать поражение. И хотя каждое существо можно спасти, некоторые зашли так далеко по пути зла, что тебе не остается ничего иного, кроме как закончить их жизнь ради большего блага. Любое такое действие нужно тщательно взвешивать и полностью понимать последствия, но приняв решение, нужно следовать ему, будучи уверенным в справедливости своего пути.`,
        source: {
          id: SOURCE_XGTE,
          page: 47,
        },
      },
    ],
    note: {
      text: `Искупление. Так тебе плохо потому, что ты кое-что сделал, и ты должен сделать что-то, чтобы тебе стало лучше? Почему бы тебе не убить кого-нибудь и не съесть? Мне от этого всегда становится лучше.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 47,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_REDEMPTION_2017_03_27,
    releasedAs: PC_SUBCLASS_PALADIN_OATH_OF_REDEMPTION,
    pcClassId: PC_CLASS_PALADIN,
    isReady: false,
    name: 'Клятва искупления',
    nameEn: 'Oath of Redemption',
    source: {
      id: SOURCE_UA_2017_03_27_A_TRIO_OF_SUBCLASSES,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_THE_ANCIENTS,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятва древних',
    nameEn: 'Oath of the Ancients',
    description: [
      `Клятва Древних так же стара, как раса эльфов и ритуалы друидов. Иногда называемые фейскими рыцарями, зелёными рыцарями, или рогатыми рыцарями, паладины, которые дали эту клятву, связывают свою судьбу со стороной света в мировой борьбе против тьмы, потому что они любят красивые и животворные вещи в мире, а не потому, что верят в принципы чести, отваги и справедливости. Они украшают свои доспехи и одежду изображениями растительности, рогов и цветов, чтобы отразить свою приверженность сохранению в мире света и жизни.`,
      {
        header: `Клятва древних`,
        text: `Вы выступаете на стороне света в извечной борьбе с тьмой, чтобы хранить ценность жизни и красоту природы.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
      {
        header: `Догматы древних`,
        text: `Догматы клятвы Древних сохранялись в течение бессчётных веков. Эта клятва подчёркивает главенство принципов добра над проблемами порядка или хаоса. Её основные четыре принципа просты:

# Разожги свет

Делами милосердия, доброты и прощения разжигай свет надежды в мире, повергая ниц отчаяние.

# Сохраняй свет

Там, где есть добро, красота, любовь и смех в мире, стой против бесчестья, чтобы побороть его. Там, где процветает жизнь, стой против сил, что хотят погубить её.

# Храни свет в себе

Восторгайся песней и смехом, красотой и искусством. Если позволишь свету умереть в своём сердце, то не сможешь сохранить его в мире.

# Будь светом

Будь маяком великолепия для тех, кто живёт в отчаянии. Пусть свет твоей отваги и мужества сияет в твоих делах.`,
        source: {
          id: SOURCE_PHB,
          page: 87,
        },
      },
      {
        header: `Догмы древних`,
        text: `Паладины Клятвы древних исповедуют следующие заповеди

# Разжигай свет

Творя добро, зажигай свет надежды даже в глубочайших безднах отчаяния.

# Защищай свет

Везде, где есть любовь. защищай её от готовой вырвать её с корнем злобы.

# Поддерживай свой свет

Находя удовольствие в песнях, танцах и маленьких радостях, поддерживай свет в собственном сердце.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
      {
        header: `Клятва древних`,
        text: `Утренняя роса сверкает на луговой траве. Птица кричит, приветствуя восзодящее солнце. Отблески рассвета играют на катящихся к берегу волнах. Этот мир прекрасен, и твой идеальный долг — защищать его. Сохрани в сердце восторг существования и блаженную славу творения. Никогда не позволяй им ускользнуть, паладин. Никому не позволяй забрать их у тебя.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 87,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_THE_CROWN,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятва Короны',
    nameEn: 'Oath of the Crown',
    description: [
      `Клятва Короны — это присяга идеалам цивилизации, будь то дух нации, верность государю или служение божеству права и законности.

Паладины, которые дают эту клятву, посвящают себя служению обществу, и в частности, справедливым законам, удерживающим общество от распада. Эти паладины — бдительные стражи на стенах, противостоящие волнам варварства и хаоса, угрожающим уничтожить все достижения цивилизации, и широко известные примеры для подражания, хранители или часовые.

Часто, паладины, которые дают данную клятву, являются членами рыцарского ордена на службе короля или народа, и рассматривают принятие клятвы как часть церемонии посвящения в ряды ордена.`,
      {
        header: `Принципы Короны`,
        text: `Принципы Клятвы Короны часто разрабатываются властителями, которым дается клятва, но в целом они опирается на несколько общих доктрин:

# Закон

Закон превыше всего. Закон — это раствор, скрепляющий камни цивилизации, и его следует уважать.

# Преданность

Ваше слово ваши узы. Без преданности, клятвы и законы бессмысленны.

# Мужество

Вы должны быть готовы сделать то, что должно быть сделано ради порядка, даже перед лицом превосходящих сил. Если Вы не сделаете этого, то кто ещё сможет?

# Обязанности

Вы должны понимать и принимать последствия своих действий, и Вы несете ответственность за выполнение своих обязанностей и обязательств.`,
        source: {
          id: SOURCE_SCAG,
          page: 133,
        },
      },
    ],
    source: {
      id: SOURCE_SCAG,
      page: 133,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятва стражей',
    nameEn: 'Oath of the Watchers',
    description: [
      `Клятва стражей обязывает паладинов защищать царства смертных от хищных внешнепланарных существ, многие из которых могут выкашивать смертных солдат. Таким образом, стражи тренируют свой разум, дух и тело, чтобы стать лучшим оружием против таких угроз.

Паладины, которые следуют клятве стражей, всегда бдительны в обнаружении влияния внешнепланарных сил, часто создавая свои сети шпионов и информаторов для сбора информации о предполагаемых культах. Для паладина клятвы стражей так же естественно сохранять здоровую подозрительность и осознавать своё окружение, как носить доспехи в бою.`,
      {
        header: `Догматы стражей`,
        text: `Паладин, принимающий клятву стражей, клянётся охранять царства смертных от иномирных угроз.

# Бдительность

Угрозы, с которыми Вы сталкиваетесь, хитры, мощны и разрушительны. Будьте всегда внимательны к их порче.

# Верность

Никогда не принимайте дары или услуги в обмен на что-то. Будьте верны своему ордену, товарищам, и долгу.

# Дисциплина

Вы — щит от бесконечных ужасов, живущих среди звёзд. Ваш клинок всегда должен быть остёр, а разум — мочь пережить то, что находится по ту сторону.`,
        source: {
          id: SOURCE_TCoE,
          page: 79,
        },
      },
    ],
    note: {
      text: `Эти паладины даже близко не такие, как я ожидала. Что ещё хуже, они отправят по домам лучших гостей вашей вечеринки.`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 79,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS_2020_01_14,
    pcClassId: PC_CLASS_PALADIN,
    releasedAs: PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS,
    isReady: false,
    name: 'Клятва Стражей',
    nameEn: 'Oath of the Watchers',
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 3,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_TREACHERY_2016_12_19,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятва вероломства',
    nameEn: 'Oath of Treachery',
    description: [
      `Клятва вероломства — это путь паладинов, отрёкшихся от других клятв или заботящихся только о своей силе и выживании. Эти нечестивые воины, которых обычно называют подлецами, верны только себе. Любой, кто достаточно безрассуден, чтобы следовать за одним из этих паладинов, делает это, потому что, хотя они и лживы, эти паладины обладают огромной силой. Те, кто следуют за ними, не становясь жертвой их подлости, надеются предаться бессмысленному насилию и накопить огромные сокровища.

Многие из этих паладинов отдают дань уважения повелителям демонов, особенно [Граз’зту](CREATURE:${CREATURE_GRAZZT}) и [Оркусу](CREATURE:${CREATURE_ORCUS}). Даже Повелители Ада не хотят вступать в союз с этими чемпионами хаоса, но Вельзевул и Гласия иногда находят родственный дух в склонности этих мерзавцев к двуличию и предательству.`,
      {
        header: `Догматы вероломства`,
        text: `Паладин, принимающий клятву вероломства, никому не обязан быть верным. В этой клятве нет никаких догматов, потому что она ничем не наполнена. Те, кому посчастливилось иметь тесный контакт с этими подлецами, заметили, что основная их забота — это сила и безопасность, особенно если и то и другое можно получить за чужой счёт.`,
        source: {
          id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
          page: 2,
        },
      },
      {
        header: `Падшие паладины`,
        text: `Клятва вероломства — это выбор паладина, который отрёкся от другой священной клятвы или отказался от традиционной жизни паладина. Эта опция существует вместе с [Клятвопреступником](PC_SUBCLASS:${PC_SUBCLASS_PALADIN_OATHBREAKER}). Мастер может использовать любой вариант для моделирования злодейских или падших паладинов.

Если Вы переключитесь на эту присягу с другой, замените все способности предыдущей клятвы на способности этой, а если Вы отказываетесь от этой клятвы, замените её способности на способности новой.`,
        source: {
          id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
          page: 2,
        },
      },
    ],
    source: {
      id: SOURCE_UA_2016_12_19_PALADIN_SACRED_OATHS,
      page: 2,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATH_OF_VENGEANCE,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятва мести',
    nameAlt: 'Клятва возмездия',
    nameEn: 'Oath of Vengeance',
    description: [
      `Давая клятву мести, Вы берёте на себя торжественное обязательство карать тех, кто совершил тяжкий грех.

Когда силы зла вырезают беспомощных селян, когда целый народ отворачивается от воли богов, когда гильдия воров становится слишком жестокой и сильной, когда дракон терроризирует сельскую местность — в такие времена появляются паладины, давшие клятву мести, чтобы установить, что правильно, а что ошибочно.

Для этих паладинов, иногда называемых мстителями или тёмными рыцарями, собственная чистота не так важна, как сотворение правосудия.`,
      {
        header: `Клятва возмездия`,
        text: `Отбросив даже собственную чистоту, дабы исправить несправедливость, Вы вершите правосудие над теми, кто совершил самые тяжкие грехи.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
      {
        header: `Догматы мести`,
        text: `Догматы клятвы мести разнятся от паладина к паладину, но все принципы сводятся к наказанию преступников, любой ценой. Паладины, отстаивающие эти принципы, готовы пожертвовать даже собственной праведностью, чтобы совершить правосудие над теми, кто творит зло, и такие паладины часто нейтральны или принципиально-нейтральны. Основные принципы догматов жестоки, но просты:

# Сражайся с большим злом

При выборе между борьбой с заклятыми врагами и боем с меньшим злом, я выбираю большее зло.

# Не щадить зло

Обычные враги могут рассчитывать на пощаду, но только не мои заклятые враги.

# Любой ценой

Мои угрызения совести не могут встать на пути истребления моих врагов.

# Возмещение

Если мои враги сеют разруху в мире, то это потому, что мне не удалось их остановить. Я должен помочь тем, кто пострадал от их злодеяний.`,
        source: {
          id: SOURCE_SCAG,
          page: 133,
        },
      },
      {
        header: `Догмы Клятвы возмездия`,
        text: `Паладины Клятвы возмездия исповедуют следующие заповеди

# Борись с большим злом

Будь мудр и по всякой битве различай кто более прав, чтобы сражаться на его стороне. 

# Нет пощады негодяям

Карай творящих злодеяния, беспощадно изничтожая их пагубу.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
      {
        header: `Клятва возмездия`,
        text: `Злодеи. Предатели. Бесчестные изверги. Они таятся за каждым углом, они охотятся на уязвимых и весь мир перекраивают под себя. Твой меч должен пронзить их сердца. Все до единого. Забудь о милосердии, паладин. Твой гнев не должен успокоиться — ведь нечестивые покоя не знают.`,
        source: {
          id: SOURCE_GAME_BG_3,
        },
      },
    ],
    source: {
      id: SOURCE_PHB,
      page: 88,
    },
  },
  {
    id: PC_SUBCLASS_PALADIN_OATHBREAKER,
    pcClassId: PC_CLASS_PALADIN,
    isReady: true,
    name: 'Клятвопреступник',
    nameEn: 'Oathbreaker',
    description: [
      `Клятвопреступник это паладин, нарушивший Священную клятву и заимевший тёмные желания или ушедший на службу силам зла. Свет, когда-то горевший в его сердце, тухнет. Остаётся одна лишь тьма.

Чтобы стать Клятвопреступником, паладин должен быть злым и должен быть как минимум 3 уровня. Такой паладин заменяет умения, специфичные для его Священной клятвы умениями Клятвопреступника.`,
      {
        header: `Искупление клятвопреступника`,
        text: `Если Вы позволяете игроку выбрать опцию Клятвопреступник, Вы можете позже позволить паладину искупить свою вину и снова стать настоящим паладином.

Паладин, желающий искупления, должен вначале избавиться от злого мировоззрения и продемонстрировать изменившееся мировоззрение словами и действиями. Сделав это, паладин теряет все умения Клятвопреступника и должен выбрать божество и священную клятву (с Вашего разрешения, игрок может выбрать божество или священную клятву, отличающиеся от имеющихся изначально). Однако, паладин не получает классовые умения от священной клятвы, пока не выполнит опасное задание или испытание, назначенное Мастером.

Паладин, нарушивший священную клятву второй раз, может снова стать Клятвопреступником, но уже не может получить искупление.`,
        source: {
          id: SOURCE_DMG,
          page: 98,
        },
      },
    ],
    source: {
      id: SOURCE_DMG,
      page: 98,
    },
  },
]
