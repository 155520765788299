const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_BASILISK,
  CREATURE_COCKATRICE,
  CREATURE_STONE_CURSED,
} = require('./../../../../creatureIdList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {LANG_ALL_KNOWN_AT_LIFE} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Окаменевший',
  nameAlt: 'Камнеклятый',
  nameEn: 'Stone Cursed',
  id: CREATURE_STONE_CURSED,
  description: [
    `Окаменевший создаётся отвратительным алхимическим ритуалом, выполненным на окаменевшем гуманоиде. Ритуал, требующий смеси из крови [василиска](CREATURE:${CREATURE_BASILISK}) и пепла сожжённых перьев [куролиска](CREATURE:${CREATURE_COCKATRICE}), пробуждает эхо духа окаменевшей жертвы, оживляя статую и превращая её в полезного хранителя.`,
    {
      header: 'Томящиеся духи',
      text: `Окаменевшего влечёт злобное желание убивать всё живое, но они полностью верны тому, кто совершил обряд по оживлению их, и они подчиняются приказам этой личности в меру своих возможностей и способностей.

В бою, окаменевший выпускает из пальцев каменные когти, с которых капает густой серый осадок. Этот алхимический осадок превращает в статую любого, кого ранят эти когти.`,
      source: {
        id: SOURCE_MTOF,
        page: 219,
      },
    },
    {
      header: 'Странный урожай',
      text: `Как часть ритуала, приводящего к созданию окаменевшего, в торсе существа формируется обсидиановый череп размером с кулак. Череп не виден, пока окаменевший жив, но, когда убит, статуя разрушается и этот череп выпадает. Из-за связывающей алхимической нити, с помощью которой создавались эти существа, в черепе откликается слабое эхо воспоминаний первоначальной жертву ритуала. Умелый заклинатель может попытаться извлечь эти воспоминания и получить информацию о прошлом жертвы, либо найти знания, что каким-то образом оказались утерянными.`,
      source: {
        id: SOURCE_MTOF,
        page: 219,
      },
    },
    {
      header: 'Загадочный шёпот',
      text: `Несмотря на то, что существа, превращённые в окаменевших, уже давно мертвы, внутри обсидиановых черепов слышен расплывчатый шёпот их воспоминаний. В конце короткого отдыха, персонаж может совершить проверку Интеллекта (Магия) с СЛ 20, чтобы попытаться извлечь воспоминания из черепа воспоминания, которые являются вербальным ответом на заданный персонажем черепу вопрос. Как только эта проверка совершена, независимо, удачно или нет, череп больше невозможно использовать подобным образом.`,
      source: {
        id: SOURCE_MTOF,
        page: 219,
      },
    },
    {
      header: 'Природа конструкта',
      text: `Окаменевший не нуждается в воздухе, пище, питье или сне.`,
      source: {
        id: SOURCE_MTOF,
        page: 219,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 219,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 10,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 5,
    [PARAM_CON]: 14,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 7,
  },
  vulnerabilityList: {
    DAMAGE_BLUDGEONING,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Хитрый оппортунист',
      description: `Окаменевший совершает с преимуществом атаки по возможности.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычной статуи`,
    },
  ],
  actionList: [
    {
      name: 'Окаменяющие когти',
      description: `Если цель — существо, оно должно пройти испытание Телосложения СЛ 12, иначе начнёт обращаться в камень, обездвиживаясь до конца своего следующего хода, во время которого она должна повторить испытание. Эффект заканчивается в случае успешного испытания; в противном случае существо превращается в камень на следующие 24 часа.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 1,
              diceType: 10,
              diceBonus: 3,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 10,
              diceBonus: 3,
              comment: `, если броски атаки были совершены с преимуществом`,
            },
          ],
        ],
      },
    },
  ],
}
