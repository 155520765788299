const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_CAN_TALK_WITH_SPIDERS} = require('./../../languageIdList')

module.exports = {
  id: LANG_CAN_TALK_WITH_SPIDERS,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'может общаться с пауками',
    genitive: 'может общаться с пауками',
    instrumental: 'может общаться с пауками',
    prepositional: 'может общаться с пауками',
  },
  isReady: false,
  isRealLang: false,
}
