const {
  CREATURE_TYPE_CELESTIAL,
  CREATURE_TYPE_TITAN,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CG,
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SKILL_INSIGHT,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_COMMUNE,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_EARTHQUAKE,
  SPELL_FIRE_STORM,
  SPELL_GREATER_RESTORATION,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_PLANE_SHIFT,
  SPELL_WATER_BREATHING,
  SPELL_WATER_WALK,
} = require('./../../../../spellIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_23} = require('./../../../../crList')
const {CREATURE_EMPYREAN} = require('./../../../../creatureIdList')
const {GEAR_MAUL} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ALL} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Эмпирей',
  nameEn: 'Empyrean',
  id: CREATURE_EMPYREAN,
  description: [
    `Эмпиреи — это дети-небожители богов с Верхних Планов. Они невероятно красивы, статны и самоуверенны.`,
    {
      header: 'Проявление эмоций',
      text: `Эмпиреи могут испытывать приступы божественного спокойствия или ярости. Их настроение может влиять на окружающую среду. Когда эмпирей несчастен, тучи могут заплакать солёной водой, полевые цветы окрестных лугов завянут, к берегу озера может прибить мёртвую рыбу, а деревья в ближайших лесах начнут сбрасывать листву. Когда эмпирей радуется, за ним повсюду следует солнечный свет, маленькие животные резвятся в его следах, а птицы наполняют небо радостными песнями.`,
      source: {
        id: SOURCE_MM,
        page: 309,
      },
    },
    {
      header: 'Злые эмпиреи',
      text: `Некоторые эмпиреи обратились ко злу, после того как спустились на Нижние Планы, или из-за проклятия злых богов. Злой эмпирей не может долго существовать на Верхних Планах и, как правило, сбегает на Материальный план, где он может властвовать над царством смертных, как неукротимый тиран.`,
      source: {
        id: SOURCE_MM,
        page: 309,
      },
    },
    {
      header: 'Бессмертные титаны',
      text: `Эмпиреи не стареют, но могут быть убиты. Немногие эмпиреи могут представить свою смерть, поэтому в бою они сражаются бесстрашно, отказываясь верить в свой конец, даже находясь на пороге смерти. Когда эмпирей умирает, его дух возвращается на свой родной план, где один из его родителей воскрешает его, если только у родителя нет веских причин не делать этого.`,
      source: {
        id: SOURCE_MM,
        page: 309,
      },
    },
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
    CREATURE_TYPE_TITAN,
  ],
  alignmentId: [
    {
      id: ALIGNMENT_CG,
      comment: '75%',
    },
    {
      id: ALIGNMENT_NE,
      comment: '25%',
    },
  ],
  source: {
    id: SOURCE_MM,
    page: 309,
  },
  armor: {
    ac: 22,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 19,
  },
  speed: {
    [SPEED_WALK]: 50,
    [SPEED_FLY]: 50,
    [SPEED_SWIM]: 50,
  },
  params: {
    [PARAM_STR]: 30,
    [PARAM_DEX]: 21,
    [PARAM_CON]: 30,
    [PARAM_INT]: 21,
    [PARAM_WIT]: 22,
    [PARAM_CHA]: 27,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
  ],
  cr: CR_23,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_GREATER_RESTORATION,
          SPELL_PASS_WITHOUT_TRACE,
          SPELL_WATER_BREATHING,
          SPELL_WATER_WALK,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_COMMUNE,
          SPELL_DISPEL_EVIL_AND_GOOD,
          SPELL_EARTHQUAKE,
          SPELL_FIRE_STORM,
          {
            id: SPELL_PLANE_SHIFT,
            comment: `только на себя`,
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Двуручный молот',
      // gearId: GEAR_MAUL, // TODO: Найти способ показывать оружие с изменёнными костями
      description: ` Если цель — существо, она должна пройти испытание Телосложения СЛ 15, иначе станет ошеломлённой до конца следующего хода ★СУЩЕСТВО★.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 6,
          diceType: 6,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Снаряд',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 15,
        range: 600,
        hit: {
          typeList: [
            DAMAGE_THUNDER,
            DAMAGE_RADIANT,
            DAMAGE_ACID,
            DAMAGE_FIRE,
            DAMAGE_FORCE,
            DAMAGE_COLD,
            DAMAGE_ELECTRICITY,
          ],
          diceCount: 7,
          diceType: 6,
          diceBonus: 0,
          comment: ' (на выбор ★СУЩЕСТВО★)',
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака',
      description: `★СУЩЕСТВО★ совершает одну атаку.`,
    },
    {
      name: 'Поддержка',
      description: `★СУЩЕСТВО★ приободряет всех невраждебных существ в пределах 120 футов от себя до концов их следующих ходов. Приободрённые существа до конца следующего хода ★СУЩЕСТВО★ не могут быть очарованными и напуганными, а также совершают с преимуществом проверки характеристик и испытания.`,
    },
    {
      name: 'Сотрясающий удар',
      cost: 2,
      description: `★СУЩЕСТВО★ бьёт молотом по полу, вызывая сотрясение. Все остальные существа, находящиеся в контакте с полом, и находящиеся в пределах 60 футов от ★СУЩЕСТВО★, должны пройти испытание Силы СЛ 25, иначе будут сбиты с ног.`,
    },
  ],
  genderId: GENDER_MALE,
}
