const {
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_10,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_BEHOLDER_KEEN,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  LANG_DEEP_SPEECH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_ELECTRICITY,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BEHOLDER,
  CREATURE_DEATH_KISS,
  CREATURE_RAT,
} = require('./../../../../creatureIdList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  beholderLikeDescription,
} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Поцелуй смерти',
  nameEn: 'Death Kiss',
  id: CREATURE_DEATH_KISS,
  description: [
    {
      header: 'Поцелуй смерти',
      text: `Поцелуй смерти — это младший бехолдер, который может появиться во время того, как [бехолдеру](CREATURE:${CREATURE_BEHOLDER}) снится яркий кошмар о потере крови. Вместо магических лучей из глаз у него десять длинных щупалец, на конце каждого рот полный зубов. По цвету и форме он похож на бехолдера, который вызвал его существование во сне, но оттенки более блеклые.`,
      source: {
        id: SOURCE_VGTM,
        page: 126,
      },
    },
    {
      header: 'Кровопийца',
      text: `Поцелуй смерти живет только за счет поглощения крови, которую использует для создания электрической энергии в своем теле. У него паранойя о смерти от голода и он одержимо осушает даже маленьких существ, таких как [крысы](CREATURE:${CREATURE_RAT}), пытаясь отодвинуть такую судьбу как можно дальше. После осушения добычи он отдает тело падальщикам. Поцелуй смерти предпочитает охотиться в одиночку. Если он встречает другого поцелуя смерти, то они могут сразиться, разбежаться или объединиться в зависимости от состояния здоровья и гордости. Под землей он использует щупальца как зонды, прощупывая и оценивая окружающую среду во всех направлениях. На поверхности, при охоте, он обычно притягивает щупальца к себе, а потом бросается и растягивает их на всю длину, заставая врагов врасплох.`,
      source: {
        id: SOURCE_VGTM,
        page: 126,
      },
    },
    {
      header: 'Ложный тиран',
      text: `В плохом освещении и при вытянутых щупальцах, поцелуя смерти часто путают с истинным [бехолдером](CREATURE:${CREATURE_BEHOLDER}). Он может умышленно представляться бехолдером невежественным существам, но такое поведение редко, так как он обычно сосредоточен на охоте и ему не хватает самомнения и паранойи истинного бехолдера. Он может говорить сквозь любую из глоток щупалец, а его голос звучит гнусаво и пискляво. Истинному бехолдеру нечего опасаться поцелуя смерти, так как он может легко убить или подчинить его задолго до того как тот подберется на дистанцию ближнего боя. Таким образом в целях самосохранения поцелуй смерти обычно подчиняется правлению бехолдера, которого встречает, хотя может попытаться сбежать если хозяин чем-то занят.`,
      source: {
        id: SOURCE_VGTM,
        page: 126,
      },
    },
    {
      header: 'Простая тактика',
      text: `Поцелую смерти не хватает боевого мастерства и интеллекта бехолдера. Он может попытаться сделать неожиданный манёвр для контроля своей жертвы (например взлететь, захватив кого-то), но в большинстве случаев он прикрепляет одно или несколько щупалец к существу и высасывает кровь пока добыча не теряет сознание. Если он находится в позиции силы и оппонент не представляет угрозы, он может играть с едой, медленно сжимая и высасывая жизнь из существа.`,
      source: {
        id: SOURCE_VGTM,
        page: 126,
      },
    },
    beholderLikeDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_BEHOLDER_KEEN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 127,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 18,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_ELECTRICITY,
  ],
  immunityConditionList: [
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Электрическая кровь',
      description: `Существо в 5 футах от ★СУЩЕСТВО★ получает 5 (1к10) урона электричеством, если попадает по ★СУЩЕСТВО★ рукопашной атакой, которая наносит колющий или рубящий урон.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки _Щупальцем_. Каждая из них может быть заменена _Высасыванием крови_, на каждую замену должно быть по схваченному щупальцами существу.`,
    },
    {
      name: 'Щупальце',
      description: `Существо Схвачено (СЛ 14 высвобождения), если оно Огромное или меньше. До окончания захвата цель обездвижена, и ★СУЩЕСТВО★ не может использовать это щупальце для атаки другого существа. У ★СУЩЕСТВО★ десять щупалец.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 20,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Высасывание крови',
      description: `Одно существо схваченное щупальцем ★СУЩЕСТВО★ должно пройти испытание Телосложения СЛ 16. При провале существо получает 22 (4к10) урона электричеством, и ★СУЩЕСТВО★ восстанавливает количество хитов, равное половине от нанесенного урона.`,
    },
  ],
}
