const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_6,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ELEMENTAL,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_TERRAN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
    SENSE_VIBRATION_SENSE,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    ABILITY_FALSE_APPEARANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PC_CLASS_WIZARD,
  } = require('./../../../../pcClassIdList'),
  {
    CREATURE_DRUID,
    CREATURE_GALEB_DUHR,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Галеб дур',
  nameEn: 'Galeb Duhr',
  id: CREATURE_GALEB_DUHR,
  description: `Галеб дур — существо, похожее на валун с массивными выступами, которые используются как руки и ноги. Он способен оживлять находящиеся рядом с ним камни, и обычно встречается в горной местности.

Могущественная магия позволяет заклинателям призывать галеб дура с Плана Земли, хотя некоторые из них могут появиться и без помощи магии — в тех местах, что соприкасаются с этим планом. По сравнению с другими элементалями, галеб дур обладает большим интеллектом, что позволяет ему лучше оценивать степень угрозы и вступать в диалог с существами, зашедшими на охраняемую им территорию.

# Каменный страж

Галеб дур не стареет, не ест и не пьёт, что делает его великолепным охранником. Могущественный [друид](CREATURE:${CREATURE_DRUID}) может поручить ему охрану каменного круга или священного холма. Другой галеб дур может быть создан для охраны подземной гробницы или башни [волшебника](PC_CLASS:${PC_CLASS_WIZARD}). По своему усмотрению он может принять облик обычного валуна и находиться в нём годами.

Галеб дур навечно связан с Материальным планом, поэтому после смерти не возвращается на План Земли. У него великолепная память, и он всегда рад поделиться информацией о местности с существами, которых не расценивает как угрозу.

# Связь с камнем

Галеб дур способен слиться воедино с землёй вокруг себя, что позволяет ему наполнить близлежащие камни и валуны неким подобием жизни. Он использует оживлённые камни, чтобы отпугивать чужаков и защищать то, что ему поручено. Чтобы приблизиться к нарушителям, он прижимает конечности к телу и с бешеной скоростью катится вперёд.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 46,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: {
      value: 15,
      comment: '30 фт. когда катится, 60 фт. когда катится по склону',
    },
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 20,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 11,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  languageList: [
    LANG_TERRAN,
  ],
  cr: CR_6,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного валуна`,
    },
    {
      name: 'Катящаяся атака в броске',
      description: `Если ★СУЩЕСТВО★ прокатится как минимум 20 футов по направлению к цели, а затем в том же ходу попадёт по ней атакой размашистым ударом, цель получает от атаки дополнительный дробящий урон 7 (2к6). Если цель — существо, она должна пройти испытание Силы СЛ 16, иначе будет сбита с ног.`,
    },
  ],
  actionList: [
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Оживление валунов',
      description: `★СУЩЕСТВО★ магическим образом оживляет до двух валунов, которые видит в пределах 60 футов от себя. Валун имеет такие же параметры, как ★СУЩЕСТВО★, кроме того, что у него Интеллект 1 и Харизма 1, его нельзя очаровать и испугать, и у него нет действия _Оживление валунов_. Валун остаётся живым, пока ★СУЩЕСТВО★ сохраняет концентрацию, вплоть до 1 минуты (как при концентрации на заклинании).`,
      limit: {
        count: 1,
        period: 'день',
      },
    },
  ],
  genderId: GENDER_MALE,
}
