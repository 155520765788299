const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {MAGIC_ITEM_VICIOUS_WEAPON} = require('./../../../../../magicItemIdList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_VICIOUS_WEAPON,
  name: `Жестокое оружие`,
  nameEn: `Vicious Weapon`,
  rarity: MGC_RARITY_RARE,
  type: MGC_TYPE_WEAPON,
  description: `Если у Вас при броске атаки этим магическим оружием выпадает «20», цель получает дополнительный урон 7 того вида, который причиняет это оружие. `,
  typeComment: `любое`,
  genderId: GENDER_MIDDLE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 163,
  },
}
