const artificerSpellSlotTable = require('./artificerSpellSlotTable')
const fullCasterSpellSlotTable = require('./fullCasterSpellSlotTable')
const halfCasterSpellSlotTable = require('./halfCasterSpellSlotTable')
const warlockSpellSlotTable = require('./warlockSpellSlotTable')

module.exports = {
  artificerSpellSlotTable,
  fullCasterSpellSlotTable,
  halfCasterSpellSlotTable,
  warlockSpellSlotTable,
}
