const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATHBREAKER} = require('./../../../pcSubClassIdList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  SPELL_ANIMATE_DEAD,
  SPELL_BESTOW_CURSE,
  SPELL_BLIGHT,
  SPELL_CONFUSION,
  SPELL_CONTAGION,
  SPELL_CROWN_OF_MADNESS,
  SPELL_DARKNESS,
  SPELL_DOMINATE_PERSON,
  SPELL_HELLISH_REBUKE,
  SPELL_INFLICT_WOUNDS,
} = require('./../../../spellIdList')
const {
  FEATURE_AURA_OF_HATE,
  FEATURE_CHANNEL_DIVINITY_CONTROL_UNDEAD,
  FEATURE_CHANNEL_DIVINITY_DREADFUL_ASPECT,
  FEATURE_DREAD_LORD,
  FEATURE_OATHBREAKER_SPELLS,
  FEATURE_SUPERNATURAL_RESISTANCE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATHBREAKER_SPELLS,
    name: `Заклинания Клятвопреступника`,
    nameEn: `Oathbreaker Spells`,
    lvl: 3,
    text: `Клятвопреступник теряет ранее полученные заклинания клятвы, но на указанных уровнях паладина
получает следующие заклинания.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_ANIMATE_DEAD,
      SPELL_BESTOW_CURSE,
      SPELL_BLIGHT,
      SPELL_CONFUSION,
      SPELL_CONTAGION,
      SPELL_CROWN_OF_MADNESS,
      SPELL_DARKNESS,
      SPELL_DOMINATE_PERSON,
      SPELL_HELLISH_REBUKE,
      SPELL_INFLICT_WOUNDS,
    ],
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_CONTROL_UNDEAD,
    name: `Божественный канал: Контроль нежити`,
    nameEn: `Channel Divinity: Control Undead`,
    lvl: 3,
    text: `Паладин может действием выбрать целью одно существо-нежить, видимое им в пределах 30 футов.

Цель должна пройти испытание Мудрости. При провале цель должна повиноваться командам паладина в течение следующих 24 часов, или пока паладин не использует _Контроль нежити_ ещё раз. Нежить, чей показатель опасности равен уровню паладина или превышает его, обладает иммунитетом к этому эффекту.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_DREADFUL_ASPECT,
    name: `Божественный канал: Жуткий вид`,
    nameEn: `Channel Divinity: Dreadful Aspect`,
    lvl: 3,
    text: `Паладин может действием собрать все свои самые тёмные эмоции и выдать их вспышкой.

Все существа на выбор паладина, находящиеся в пределах 30 футов от него, должны пройти испытание Мудрости, если они видят паладина.

При провале цель становится испуганной паладином на 1 минуту. Если существо, испуганное этим эффектом, оканчивает ход более чем в 30 футах от паладина, оно может совершить ещё одно испытание Мудрости, окончивая эффект при успехе.`,
  },
  {
    id: FEATURE_AURA_OF_HATE,
    name: `Аура ненависти`,
    nameEn: `Aura of Hate`,
    lvl: [7, 18],
    text: `Паладин, а также все исчадия и нежить в пределах 10 футов от него, получают бонус к броскам урона рукопашным оружием, равный модификатору Харизмы паладина (минимум +1). Существо может получать преимущество только одного такого умения за раз.

На 18 уровне радиус ауры увеличивается до 30 футов.`,
  },
  {
    id: FEATURE_SUPERNATURAL_RESISTANCE,
    name: `Сверхъестественное сопротивление`,
    nameEn: `Supernatural Resistance`,
    lvl: 15,
    text: `Паладин получает сопротивление дробящему, колющему и рубящему урону от немагического оружия. `,
  },
  {
    id: FEATURE_DREAD_LORD,
    name: `Жуткий лорд`,
    nameEn: `Dread Lord`,
    lvl: 20,
    text: `Паладин может действием окружить себя аурой мрака, существующей 1 минуту.

Эта аура уменьшает яркий свет в пределах 30 футов от паладина до тусклого света. Каждый раз, когда враг, испуганный паладином, начинает ход в этой ауре, он получает урон психической энергией 4к10. Кроме того, паладин и выбранные им существа, находящиеся в ауре, окутываются плотными тенями. Существа, полагающиеся на зрение, совершают с помехой броски атаки по существам, окутанным тенью.

Пока аура активна, паладин может в свой ход бонусным действием заставить теней в ауре атаковать одно существо. Паладин совершает по цели рукопашную атаку заклинанием. Ели атака попадает, цель получает урон некротической энергией 3к10 + модификатор Харизмы паладина.

Активировав ауру, паладин не может этого сделать ещё раз, пока не окончит длинный отдых.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATHBREAKER,
    source: {
      id: SOURCE_DMG,
      page: 98,
    },
  })
)
