const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {CREATURE_TYPE_YAKFOLK} = require('./../../creatureTypeIdList')
const {yakfolkDescriptionList} = require ('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_YAKFOLK,
  nameEn: 'Yakfolk',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'яколюд',
        genitive: 'яколюда',
        dative: 'яколюду',
        accusative: 'яколюда',
        instrumental: 'яколюдом',
        prepositional: 'яколюде',
      },
      [GENDER_FEMALE]: {
        nominative: 'яколюдка',
        genitive: 'яколюдки',
        dative: 'яколюдке',
        accusative: 'яколюдку',
        instrumental: 'яколюдкой',
        prepositional: 'яколюдке',
      },
    },
    plural: {
      nominative: 'яколюды',
      genitive: 'яколюдов',
      dative: 'яколюдам',
      accusative: 'яколюдов',
      instrumental: 'яколюдами',
      prepositional: 'яколюдах',
    },
  },
  description: yakfolkDescriptionList,
}
