const {
  MAGIC_CONJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_TCoE,
} = require('./../../../../../sourceList')
const {
  CREATURE_CLAY_CONSTRUCT_SPIRIT,
  CREATURE_METAL_CONSTRUCT_SPIRIT,
  CREATURE_STONE_CONSTRUCT_SPIRIT,
} = require('./../../../../../creatureIdList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_SUMMON_CONSTRUCT,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SUMMON_CONSTRUCT,
  name: 'Призыв духа конструкта',
  nameEn: 'Summon Construct',
  note: {
    text: `А давно у конструктов появились духи?`,
    author: `Эспумизан Коликит, гоблин волшебник`,
  },
  description: `Вы призываете дух конструкта. Дух проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите материал — [глину](CREATURE:${CREATURE_CLAY_CONSTRUCT_SPIRIT}), [металл](CREATURE:${CREATURE_METAL_CONSTRUCT_SPIRIT}), или [камень](CREATURE:${CREATURE_STONE_CONSTRUCT_SPIRIT}). Существо выглядит как голем или модрон (на Ваш выбор) из выбранного материала, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.
    
Существо дружественно к Вам и Вашим спутникам. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно совершает действие Уклонение и передвигается так, чтобы избегать опасности.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
  createdCreatureId: [
    CREATURE_CLAY_CONSTRUCT_SPIRIT,
    CREATURE_METAL_CONSTRUCT_SPIRIT,
    CREATURE_STONE_CONSTRUCT_SPIRIT,
  ],
  lvl: 4,
  magicSchoolId: MAGIC_CONJURATION,
  range: 90,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `украшенный камень и металлический ларец стоимостью не менее 400 золотых`,
  hasCost: true,
  duration: { timeId: TIME_HOUR, count:  1},
  needConcentration: true,
  source: {
    id: SOURCE_TCoE,
    page: 159,
  },
}
