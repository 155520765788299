const {
    SOURCE_SKT,
  } = require('./../../../../sourceList'),
  {
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    ogreDescription,
  } = require('./../../../../textCommonParts'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_GOBLIN,
    CREATURE_OGRE,
    CREATURE_OGRE_GOBLIN_HUCKER,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Огр метатель гоблинов',
  nameEn: 'Ogre Goblin Hucker',
  id: CREATURE_OGRE_GOBLIN_HUCKER,
  parentId: CREATURE_OGRE,
  description: [
    {
      header: 'Метатели гоблинов',
      text: `Хобгоблинские изобретения под названием «метатели гоблинов». Каждое из этих приспособлений по сути является портативным требушетом, пристегнутым к кожаной упряжи. Приспособление держится на плечах и спине огра, и несмотря на внешне тяжёлый вид, не мешает манёвренности или боеспособности носителя. Чтобы кто-то ещё одел или снял эту сложную упряжь, требуется около 10 минут, а если огр пытается сделать это самостоятельно, потребуется 1к6 часов. Приспособление создано специально под огров. Дварф или другой гуманоид схожей комплекции, магически увеличенный до большого размера, может надеть или снять _метателя гоблинов_ за 10 минут.

Чаша требушета достаточно большая, чтобы метать камни, зажжённый бочонок смолы или другой снаряд подобного размера, но она была сделана специально для метания [гоблинов](CREATURE:${CREATURE_GOBLIN}). Используемый как снаряд [гоблин](CREATURE:${CREATURE_GOBLIN}) обычно одевает шипованный шлем, чтобы при ударе наносить колющие повреждения. Гоблин редко переживает такой запуск.

Носитель метателя гоблинов запускает свою ношу по высокой дуге, так что она попадает по целям за укрытием. Чтобы зарядить устройство потребуется действие, которое должен выполнить кто-то ещё, помимо носителя. [Гоблин](CREATURE:${CREATURE_GOBLIN}), желающий служить снарядом, может при необходимости сам зарядить собой метатель. Огр после этого должен потратить действие, чтобы нацелить орудие и потянуть за шнур, который совершает выстрел и запускает снаряд в полёт.`,
      source: {
        id: SOURCE_SKT,
        page: 54,
      },
    },
    ...ogreDescription,
  ],
  source: {
    id: SOURCE_SKT,
    page: 54,
  },
  actionList: [
    {
      name: 'Гоблин-снаряд',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 3,
        range: {
          min: 30,
          normal: 150,
          max: 600,
        },
        hit: [
          [
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 2,
              diceType: 4,
            },
            {
              type: DAMAGE_PIERCING,
              diceCount: 4,
              diceType: 4,
              comment: ', если снаряд одел шипованный шлем',
            },
          ],
        ],
        hitOrMiss: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 6,
          preText: 'гоблин-снаряд получает ',
          comment: ' за каждые 10 футов полёта, максимум 20к6',
        },
      },
    },
  ],
}
