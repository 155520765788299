const {
  SPELL_ARCANE_EYE,
  SPELL_ARCANE_GATE,
  SPELL_ARMOR_OF_AGATHYS,
  SPELL_BANE,
  SPELL_BLIGHT,
  SPELL_CHILL_TOUCH,
  SPELL_COMPULSION,
  SPELL_CONFUSION,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_DARKNESS,
  SPELL_DREAM,
  SPELL_ELDRITCH_BLAST,
  SPELL_FEAR,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_INVISIBILITY,
  SPELL_MAGE_ARMOR,
  SPELL_MAJOR_IMAGE,
  SPELL_MINOR_ILLUSION,
  SPELL_PRESTIDIGITATION,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_TRUE_SEEING,
  SPELL_VAMPIRIC_TOUCH,
  SPELL_WITCH_BOLT,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_SHADAR_KAI,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_ELVEN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
} = require('./../../../../conditionList')
const {ABILITY_FEY_ANCESTRY} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_9} = require('./../../../../crList')
const {CREATURE_GLOOM_WEAVER} = require('./../../../../creatureIdList')
const {GOD_RAVEN_QUEEN} = require('./../../../../godIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {shadarKaiDescriptionList} = require('./../../../../textCommonParts')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ткач мрака',
  nameEn: 'Gloom Weaver',
  id: CREATURE_GLOOM_WEAVER,
  description: [
    {
      header: 'Ткач мрака',
      text: `Несмотря на то, что он сильный боец, ткач мрака часто просто скрывается в тени, пристально наблюдая и влияя на жертв самим своим присутствием. Его тёмная энергия укореняется в сердцах, заставляя тех, кто находится в его гнетущей ауре, чувствовать приближение смерти. Одного этого мучения хватает чтоб доставить удовольствие его хозяйке, [Королеве Воронов](GOD:${GOD_RAVEN_QUEEN}). Если же его кто-то обнаружит, то ткач мрака магией сделает из него покойника.`,
      source: {
        id: SOURCE_MTOF,
        page: 239,
      },
    },
    ...shadarKaiDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_SHADAR_KAI,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MTOF,
    page: 240,
  },
  armor: [
    14,
    {
      ac: 17,
      comment: `с [Доспехами мага](SPELL:${SPELL_MAGE_ARMOR})`,
    },
  ],
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 14,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ELVEN,
  ],
  cr: CR_9,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    {
      name: 'Бремя времени',
      description: `Звери и гуманоиды, отличные от шадар-каев, с помехой проходят испытания, когда находятся в пределах 10 фт. от ткача мрака.`,
    },
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_ARCANE_EYE,
            SPELL_MAGE_ARMOR,
            SPELL_SPEAK_WITH_DEAD,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_ARCANE_GATE,
            SPELL_BANE,
            SPELL_COMPULSION,
            SPELL_CONFUSION,
            SPELL_TRUE_SEEING,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 12,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_CHILL_TOUCH,
            {
              id: SPELL_ELDRITCH_BLAST,
              comment: '3 заряда, +4 к каждому броску урона',
            },
            SPELL_MINOR_ILLUSION,
            SPELL_PRESTIDIGITATION,
          ],
        },
        {
          preText: `1–5 круг (3 ячейки 5 круга)`,
          list: [
            SPELL_ARMOR_OF_AGATHYS,
            SPELL_BLIGHT,
            SPELL_DARKNESS,
            SPELL_DREAM,
            SPELL_INVISIBILITY,
            SPELL_FEAR,
            SPELL_HYPNOTIC_PATTERN,
            SPELL_MAJOR_IMAGE,
            SPELL_CONTACT_OTHER_PLANE,
            SPELL_VAMPIRIC_TOUCH,
            SPELL_WITCH_BOLT,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Ткач мрака совершает две атаки _Теневым копьём_ и сотворяет одно заклинание, со временем накладывания «1 действие».`,
    },
    {
      name: 'Теневое копьё',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 6,
              diceBonus: 4,
            },
            {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 8,
              diceBonus: 4,
              use2Hand: true,
            },
          ],
          {
            type: DAMAGE_NECROTIC,
            diceCount: 4,
            diceType: 12,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  reactionList: [
    {
      name: 'Туманное бегство',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Когда ткач мрака получает урон, он становится невидимым и телепортируется на расстояние до 60 фт. в незанятое пространство, которое может видеть. Невидимость сохраняется до начала его следующего хода, или до момента, когда он атакует или сотворяет заклинание.`,
    },
  ],
}
