const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_MAIN_LANGUAGE_OF_ITS_MASTER} = require('./../../languageIdList')

module.exports = {
  id: LANG_MAIN_LANGUAGE_OF_ITS_MASTER,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'основной язык хозяина',
    genitive: 'основного языком хозяина',
    instrumental: 'основным языком хозяина',
    prepositional: 'основном языке хозяина',
  },
  isReady: false,
  isRealLang: false,
}
