const {PC_SUBCLASS_WARLOCK_THE_LURKER_IN_THE_DEEP_2019_05_09} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_09_05_ABERRANT_LURK} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_CONE_OF_COLD,
  SPELL_CONTROL_WATER,
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_GUST_OF_WIND,
  SPELL_LIGHTNING_BOLT,
  SPELL_SILENCE,
  SPELL_SLEET_STORM,
  SPELL_THUNDERWAVE,
} = require('./../../../spellIdList')
const {
  FEATURE_DEVOURING_MAW_2019_05_09,
  FEATURE_EXPANDED_SPELLS_WARLOCK_THE_LURKER_IN_THE_DEEP_2019_05_09,
  FEATURE_FATHOMLESS_SOUL_2019_05_09,
  FEATURE_GRASP_OF_THE_DEEP_2019_05_09,
  FEATURE_GUARDIAN_GRASP_2019_05_09,
  FEATURE_SCION_OF_THE_DEEP_2019_05_09,
  FEATURE_UNLEASH_THE_DEPTHS_2019_05_09,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_WARLOCK_THE_LURKER_IN_THE_DEEP_2019_05_09,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    text: `Таящийся в глубине позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_COMMUNE_WITH_NATURE,
      SPELL_CONE_OF_COLD,
      SPELL_CONTROL_WATER,
      SPELL_CREATE_OR_DESTROY_WATER,
      SPELL_EVARD_S_BLACK_TENTACLES,
      SPELL_GUST_OF_WIND,
      SPELL_LIGHTNING_BOLT,
      SPELL_SILENCE,
      SPELL_SLEET_STORM,
      SPELL_THUNDERWAVE,
    ],
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 2,
    },
  },
  {
    id: FEATURE_GRASP_OF_THE_DEEP_2019_05_09,
    name: `Хватка глубин`,
    nameEn: `Grasp of the Deep`,
    lvl: 1,
    text: `Вы получаете способность магически вызвать щупальце, что бьёт Ваших врагов.

Бонусным действием Вы создаёте щупальце 10-футовой длины в видимой Вами в пределах 60 футов точке. Щупальце существует 1 минуту или пока Вы снова не используете эту способсность, призывая другое щупальце.

Создавая щупальце, Вы можете совершить рукопашную атаку заклинанием по существу в пределах 10 футов от него. При попадании цель получает 1к8 урона холодом или электричеством (на Ваш выбор) и его скорость уменьшается на 10 футов до конца Вашего следующего хода. Когда Вы достигаете 10 уровня в этом классе, урон щупальца увеличивается до 2к8.

Бонусным действием на своём ходу Вы можете переместить щупальце на расстояние до 30 футов и повторить атаку.

Вы можете призвать щупальце число раз, равное Вашему модификатору Харизмы (минимум один раз) и восстанавливаете все использования, завершив длинный отдых.`,
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 3,
    },
  },
  {
    id: FEATURE_SCION_OF_THE_DEEP_2019_05_09,
    name: `Потомок глубин`,
    nameEn: `Scion of the Deep`,
    lvl: 1,
    text: `Ваш покровитель принимает Вас во внутренний клуг прислужников.

Вы можете телепатически общаться с любой аберрацией, зверем, элементалем или монстром, имеющим родную скорость плавания, пока он находится в пределах 120 футов от Вас. Существо может понимать Вас и отвечать Вам телепатически.`,
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 3,
    },
  },
  {
    id: FEATURE_FATHOMLESS_SOUL_2019_05_09,
    name: `Душа глубин`,
    nameEn: `Fathomless Soul`,
    lvl: 6,
    text: `Ваш покровитель даёт Вам великие способности. Вы получаете следующие преимущества.

* Вы можете дышать как воздухом, так и водой.
* Вы получаете скорость плавания, равную Вашей скорости ходьбы.  
* Вы получаете сопротивление урону холодом.
`,
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 3,
    },
  },
  {
    id: FEATURE_GUARDIAN_GRASP_2019_05_09,
    name: `Защитная хватка`,
    nameEn: `Guardian Grasp`,
    lvl: 6,
    text: `Щупальце, созданное Вашей способностью [Хватка глубин](FEATURE:${FEATURE_GRASP_OF_THE_DEEP_2019_05_09}), может защищать Вас и других.

Когда Вы или видимое Вами в пределах 10 футов от щупальца существо получает урон, Вы можете реакцией выбрать одно из этих существ и уменьшить наносимый ему урон удвое. После этого щупальце исчезает.`,
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 3,
    },
  },
  {
    id: FEATURE_DEVOURING_MAW_2019_05_09,
    name: `Пожирающая пасть`,
    nameEn: `Devouring Maw`,
    lvl: 10,
    text: `Вы магически призываете проявление ненасытного голода своего покровителя.

Действием выберите точку, видимую Вами в пределах 60 футов. На 1 минуту там появляется полупрозрачная пасть радиусом 10 футов с центром на этой точке.

Когда пасть появляется, все существа в этой области должны пройти испытание Силы или будут обездвижены. Любое существо, начинающее свой ход в области пасти, получает 3к6 урова холодом или электричеством (на Ваш выбор). Обездвиженное существо может действием повторить испытание, оканчивая обездвиженность при успехе. В начале Вашего хода, если в области пасти есть существа, Вы получаете временные хиты в количестве, равном Вашему уровню колдуна.

Использовав эту способность, Вы не сможете использовать её вновь, пока не завершите короткий или длинный отдых.`,
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 3,
    },
  },
  {
    id: FEATURE_UNLEASH_THE_DEPTHS_2019_05_09,
    name: `Мощь глубин`,
    nameEn: `Unleash the Depths`,
    lvl: 14,
    text: `Вы получаете способность позвать своего покровителя на помощь.

Действием выберите точку проявления в пределах 30 футов, где Ваш покровитель прорвётся сквозь реальность, показывая степень своего океанического величия. Выберите один из следующих эффектов, создаваемых в месте его прорыва.

Выбрав один из эффектов, Вы не сможете использовать эту способность вновь, пока не завершите длинный отдых.

# Перенос

Вы и до пяти согласных существ по Вашему выбору, видимых Вами в пределах 30 футов от точки проявления хватают призрачные щупальца и затягивают в царство Вашего покровителя. Щупальцы телепортируют Вас и выбранных существ в место по Вашему выбору в пределах 100 миль, которое Вы посещали в последние 24 часа. Затем щупальца исчезают.

# Ярость

Вы можете направить шквал призрачных щупалец и поразить до пяти существ, видимых Вами в пределах 30 футов от точки проявления. Каждая цель должна пройти испытание Ловкости. При провале существо получает 6к10 урона холодом или электричеством (на Ваш выбор) и сбивается с ног. При успехе оно получает вдвое меньше урона и не сбивается с ног. Затем щупальца исчезают.`,
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 4,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_LURKER_IN_THE_DEEP_2019_05_09,
  })
)

