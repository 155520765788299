const {
  SPELL_ALARM,
  SPELL_AUGURY,
  SPELL_AURA_OF_PURITY,
  SPELL_BANISHMENT,
  SPELL_CHROMATIC_ORB,
  SPELL_COUNTERSPELL,
  SPELL_HALLOW,
  SPELL_HOLD_MONSTER,
  SPELL_MOONBEAM,
  SPELL_NONDETECTION,
} = require('./../../../spellIdList')
const {FEATURE_OATH_OF_THE_WATCHERS_SPELLS} = require('./../../../featureIdList')
const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS_2020_01_14} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_01_14_SUBCLASSES_PART_1} = require('./../../../sourceList')

module.exports = [
  {
    id: FEATURE_OATH_OF_THE_WATCHERS_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_THE_WATCHERS_2020_01_14,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_ALARM,
      SPELL_AUGURY,
      SPELL_AURA_OF_PURITY,
      SPELL_BANISHMENT,
      SPELL_CHROMATIC_ORB,
      SPELL_COUNTERSPELL,
      SPELL_HALLOW,
      SPELL_HOLD_MONSTER,
      SPELL_MOONBEAM,
      SPELL_NONDETECTION,
    ],
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 3,
    },
  },
]
