const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_TITAN} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_TITAN,
  nameEn: 'Titan',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'титан',
      genitive: 'титана',
      dative: 'титану',
      accusative: 'титана',
      instrumental: 'титаном',
      prepositional: 'титане',
    },
    plural: {
      nominative: 'титаны',
      genitive: 'титанов',
      dative: 'титанам',
      accusative: 'титанов',
      instrumental: 'титанами',
      prepositional: 'титанах',
    },
  },
}
