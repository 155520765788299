const {
  CREATURE_COW,
  CREATURE_DEEP_ROTHE,
  CREATURE_ROTHE,
} = require('./../../../../creatureIdList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PARAM_CHA} = require('./../../../../paramList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPELL_DANCING_LIGHTS} = require('./../../../../spellIdList')

module.exports = {
  name: 'Глубинный роф',
  nameEn: 'Deep Rothe',
  id: CREATURE_DEEP_ROTHE,
  parentId: CREATURE_COW,
  description: `Глубинные рофы — чахлая разновидность [рофов](CREATURE:${CREATURE_ROTHE}) из Подземья. Они общаются друг с другом, используя магические сигнальные огни.`,
  creatureId: GENDER_MALE,
  sizeType: SIZE_MEDIUM,
  source: {
    id: SOURCE_VGTM,
    page: 210,
  },
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DANCING_LIGHTS,
        ],
      },
    ],
  },
  extendPropCollection: {
    hp: {
      diceType: 8,
    },
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
  },
}
