const {CREATURE_TYPE_KALASHTAR} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_KALASHTAR,
  nameEn: 'Kalashtar',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'калаштар',
        genitive: 'калаштара',
        dative: 'калаштару',
        accusative: 'калаштара',
        instrumental: 'калаштаром',
        prepositional: 'калаштаре',
      },
      [GENDER_FEMALE]: {
        nominative: 'калаштарка',
        genitive: 'калаштарки',
        dative: 'калаштарке',
        accusative: 'калаштарку',
        instrumental: 'калаштаркой',
        prepositional: 'калаштарке',
      },
    },
    plural: {
      nominative: 'калаштары',
      genitive: 'калаштаров',
      dative: 'калаштарам',
      accusative: 'калаштаров',
      instrumental: 'калаштарами',
      prepositional: 'калаштарах',
    },
  },
}
