const {SOURCE_VGTM} = require('./../../sourceList')
const {giantLanguageWithoutVoloDictDescriptionList} = require('./../../textCommonParts')
const {LANG_GIANT} = require('./../../languageIdList')
const {LANG_TYPE_STANDARD} = require('./../../languageTypeList')
const {
  ALPHABET_DAVEK,
  ALPHABET_DETHEK,
  ALPHABET_GIANT_RUNES,
} = require('./../../alphabetList')

module.exports = {
  id: LANG_GIANT,
  alphabetId: [
    ALPHABET_GIANT_RUNES,
    ALPHABET_DETHEK,
    ALPHABET_DAVEK,
  ],
  type: LANG_TYPE_STANDARD,
  name: {
    nominative: 'Йотун',
    genitive: 'Йотуна',
    instrumental: 'Йотуном',
    prepositional: 'Йотуне',
  },
  name5eOfficial: {
    nominative: 'Великаний',
    genitive: 'Великаньего',
    instrumental: 'Великаньим',
    prepositional: 'Великаньем',
  },
  nameAlt: 'Великаний',
  nameEn: 'Jotun',
  nameEnAlt: 'Giant',
  dictionary: {
    list: [
      ['белый', 'квит'],
      ['битва', 'слаг'],
      ['броня', 'харбунад'],
      ['верх', 'опп'],
      ['ветер', 'винд'],
      ['вождь', 'фёрер'],
      ['воин', 'кригга'],
      ['враг', 'увен'],
      ['вторженец', 'убуден'],
      ['дварф', 'дверг'],
      ['добрый', 'маат'],
      ['благочестивый', 'маат'],
      ['святой', 'маат'],
      ['дом', 'хайм'],
      ['злой', 'мауг'],
      ['бесчестный', 'мауг'],
      ['богопротивный', 'мауг'],
      ['золото', 'гил'],
      ['зубы', 'теннер'],
      ['каменный великан', 'стейнъётун'],
      ['корова', 'куэ'],
      ['король', 'кёнг'],
      ['красный', 'род'],
      ['крепость', 'фестинг'],
      ['мать', 'хильд'],
      ['ледяной великан', 'исаётун'],
      ['мясо', 'кйотт'],
      ['облачный великан', 'скьеётун'],
      ['огненный великан', 'ильдъётун'],
      ['опасность', 'фарэ'],
      ['племя', 'стомм'],
      ['приветствие', 'хельс'],
      ['приветствие', 'хельсинген'],
      ['путешествие', 'ферд'],
      ['свет', 'стиг'],
      ['серебро', 'сольв'],
      ['смерть', 'дод'],
      ['стрела', 'пит'],
      ['холмовой великан', 'хаугъётун'],
      ['храбрость', 'практ'],
      ['храм', 'бапарт'],
      ['человек', 'ван'],
      ['чёрный', 'сорт'],
      ['честь', 'ранг'],
      ['штормовой великан', 'уваръётун'],
      ['щит', 'скольд'],
      ['эльф', 'альв'],
    ],
    source: {
      id: SOURCE_VGTM,
      page: 22,
    },
  },
  description: giantLanguageWithoutVoloDictDescriptionList,
  typicalSpeakers: 'Великаны, огры',
  isRealLang: true,
  isReady: true,
}
