const {PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE_2019_09_18} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_09_18_ELOQUENT_HEROICS} = require('./../../../sourceList')
const {SPELL_CALM_EMOTIONS} = require('./../../../spellIdList')
const {
  FEATURE_SOOTHING_WORDS_2019_09_18,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_SOOTHING_WORDS_2019_09_18,
    name: `Успокаивающие слова`,
    nameEn: `Soothing Words`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE_2019_09_18,
    text: `You can now cast Calm emotions without expending a spell slot. You can use this feature a number of times equal to your Charisma modifier. You regain all expended uses of this feature when you finish a long rest.`,
    spellIdList: SPELL_CALM_EMOTIONS,
    source: {
      id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
      page: 1,
    },
  },
]
