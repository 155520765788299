const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BROWN_BEAR,
  CREATURE_FROST_GIANT,
  CREATURE_GIANT_OWL,
  CREATURE_HILL_GIANT,
  CREATURE_OWLBEAR,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  PC_RACE_ELF,
  PC_RACE_HOBGOBLIN,
} = require('./../../../../pcRaceIdList')
const {ABILITY_KEEN_SIGHT_AND_SMELL} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Совомед`,
  nameAlt: ['Медвесова', 'Медвесыч'],
  nameEn: 'Owlbear',
  id: CREATURE_OWLBEAR,
  description: [
    `Крик совомеда эхом разносится по тёмным рощам и укрытым ночью лесам, разрывая тишину и предвещая смерть жертвам этого зверя. Перья покрывают крепкую, косматую шкуру его медвежьего тела, а огромные, как блюдца, круглые зрачки гневно сверкают на совиной голове.`,
    {
      header: 'Смертоносная свирепость',
      text: `Репутация совомедов, как свирепых, агрессивных, упрямых существ с абсолютно дурным нравом, делает их хищниками, которых боятся более всего в диких местах. Существует очень мало вещей, которых испугался бы голодный совомед. Даже чудовища, превосходящие совомедов по силе и размеру, избегают связываться с ними, так как эти существа не обращают внимания на превосходство противника и атакуют без предупреждения.`,
      source: {
        id: SOURCE_MM,
        page: 272,
      },
    },
    {
      header: 'Совершенные хищники',
      text: `Совершенные хищникиСовомеды покидают свои логова на закате и охотятся в самые тёмные ночные часы, ухая и крича, чтобы обозначить свою территорию, призывая партнёра или чтобы загнать жертву в свои охотничьи угодья. Обычно это лесистая местность, знакомая совомеду, и достаточно густая, чтобы уменьшить шансы побега жертвы.

Совомеды обустраивают свои логова в пещерах или руинах, которые вскоре устилаются костями их добычи. Недоеденную добычу они тянут в логово, складируя её части среди камней, кустов и деревьев. Тяжёлый запах крови и разлагающейся плоти разносится близ логова совомеда, привлекая падальщиков, которые становятся новой добычей.

Совомеды охотятся поодиночке или парами. Если добычи достаточно, семья совомедов может остаться вместе на более долгий срок, чем необходимо, чтобы завести потомство. В противном случае их пути расходятся, как только молодняк научится охотиться самостоятельно.`,
      source: {
        id: SOURCE_MM,
        page: 272,
      },
    },
    {
      header: 'Дикие спутники',
      text: `Хотя совомеды превосходят интеллектом других животных, их очень сложно приручить. Впрочем, обладая достаточным количеством времени, еды и удачи, разумное существо может научить совомеда узнавать в нём хозяина, получая тем самым неустрашимого охранника или быстрое и крепкое верховое животное. Народы отдалённых поселений даже устраивают скачки на совомедах. Но публика также часто ставит на то, что совомед нападёт на своего наездника.

[Эльфийские](PC_RACE:${PC_RACE_ELF}) поселения поощряют совомедов, которые устраивают логова под их деревнями в кронах деревьев, используя зверей в качестве естественной защиты в ночное время. [Хобгоблины](PC_RACE:${PC_RACE_HOBGOBLIN}) предпочитают использовать совомедов в качестве боевых животных, а [холмовые](CREATURE:${CREATURE_HILL_GIANT}) и [ледяные великаны](CREATURE:${CREATURE_FROST_GIANT}) держат их как питомцев. Бывает, что оголодавшего совомеда используют на гладиаторской арене, где зверь беспощадно терзает и пожирает противников на потеху жадной до крови публики.`,
      source: {
        id: SOURCE_MM,
        page: 272,
      },
    },
    {
      header: 'Происхождение совомедов',
      text: `Долгое время в учёном сообществе длится спор о происхождении совомедов. Наиболее расхожая теория гласит, что первую особь создал лишившийся разума волшебник, скрестив [гигантскую сову](CREATURE:${CREATURE_GIANT_OWL}) с [медведем](CREATURE:${CREATURE_BROWN_BEAR}). Однако [эльфы](PC_RACE:${PC_RACE_ELF}) c почтенным возрастом заявляют, что знают об этих существах уже тысячи лет, а некоторые феи настаивают на том, что совомеды всегда существовали в Стране Фей.`,
      source: {
        id: SOURCE_MM,
        page: 272,
      },
    },
  ],
  note: {
    text: `Единственное, что хорошо в совомедах, это то, что волшебник, их создавший, скорее всего, уже мертв`,
    author: `Заршель Теневран, гномий профессор трансмутационной науки в университете Моргрейва`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 272,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 7,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 17,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_3,
  featureList: [
    ABILITY_KEEN_SIGHT_AND_SMELL,
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Клювом_, и одну _Когтями_.`,
    },
    {
      name: `Клюв`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: `Когти`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
