const {GEAR_JAVELIN} = require('./../../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_JAVELIN_OF_LIGHTNING} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_JAVELIN_OF_LIGHTNING,
  name: 'Пилум молнии',
  nameAlt: 'Метательное копьё молнии',
  nameEn: 'Javelin of Lightning',
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_JAVELIN,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Этот пилум — магическое оружие. Если Вы метнёте его и произнесёте его командное слово, он превратится в молнию, формируя линию шириной 5 футов и простирающуюся от Вас до цели, находящейся в пределах 120 футов. Все существа в этой линии, исключая Вас и цель, должны пройти испытание Ловкости СЛ 13, получая урон электричеством 4к6 при провале или половину этого урона при успехе.

Молния снова становится пилумом, когда достигает цели. Совершите дальнобойную атаку оружием по цели. При попадании цель получает урон от пилума плюс урон электричеством 4к6. 

Это свойство пилума нельзя использовать повторно до следующего рассвета. Однако он всё равно может использоваться как магическое оружие.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 184,
  },
}
