const {
  gearByCatCollection,
  gearCollection,
} = require('./../../../../../gearList')
const {CAT_WEAPON} = require('./../../../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../../../damageTypeList')

const list = gearByCatCollection[CAT_WEAPON]
  .filter(
    gearId => gearCollection[gearId].damageType === DAMAGE_SLASHING
  )
  .map(
    gearId => ({
      id: `vorpal_${gearId}`,
      gearId,
    })
  )

module.exports = list
