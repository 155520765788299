const {PC_SUBCLASS_FIGHTER_MONSTER_HUNTER_2016_04_04} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2016_04_04_GOTHIC_HEROES} = require('./../../../sourceList')
const {
  SPELL_DETECT_MAGIC,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
} = require('./../../../spellIdList')
const {
  MANEUVER_DETECT_HIDDEN_AND_LIES_2016_04_04,
  MANEUVER_INT_WIS_CHA_SAVING_THROW_2016_04_04,
  MANEUVER_MAINTAIN_CONCENTRATION_DISADVANTAGE_2016_04_04,
  MANEUVER_PRECISION_ATTACK,
} = require('./../../../maneuverList')

const {
  FEATURE_BONUS_PROFICIENCIES_2016_04_04,
  FEATURE_COMBAT_SUPERIORITY_2016_04_04,
  FEATURE_HUNTERS_MYSTICISM_2016_04_04,
  FEATURE_IMPROVED_COMBAT_SUPERIORITY_2016_04_04,
  FEATURE_MONSTER_SLAYER_2016_04_04,
  FEATURE_RELENTLESS_2016_04_04,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_HUNTERS_MYSTICISM_2016_04_04,
    name: `Мистицизм охотника`,
    nameEn: `Hunter’s Mysticism`,
    lvl: 3,
    text: `Ваше изучение сверхъестественного дало Вам ограниченные способности использования магии.

Вы можете сотворять заклинание [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}) ритуалом. Вы можете сотворять заклинание [Защита от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}), но не сможете повторно сотворять его этой способностью, пока не закончите длинный отдых. Мудрость — Ваша заклинательная способность для этих заклинаний.

Кроме того, Вы получаете способность говорить на одном из следующих языков

* Бездны
* Небесный
* Инфернальный`,
    spellIdList: [
      SPELL_DETECT_MAGIC,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
    ],
  },
  {
    id: FEATURE_BONUS_PROFICIENCIES_2016_04_04,
    name: `Дополнительные владения`,
    nameEn: `Bonus Proficiencies`,
    lvl: 3,
    text: `Вы получаете владение двумя навыками по Вашему выбору из следующего списка

* Анализ
* Внимательность
* История
* Магия
* Природа
* Проницательность

Вы можете выбрать владение инструментами по Вашему выбору вместо одного из этих навыков.`,
  },
  {
    id: FEATURE_COMBAT_SUPERIORITY_2016_04_04,
    name: `Боевое превосходство`,
    nameEn: `Combat Superiority`,
    lvl: [3, 7, 15],
    text: `Вы изучаете приёмы, использующие специальные кости, называемые _Костями превосходства_.
    
# Кости превосходства

У Вас есть четыре _Кости превосходства_. Это кости к8. _Кости превосходства_ тратятся при использовании. Вы восполняете все потраченные кости в конце короткого или длинного отдыха. 

Вы получаете ещё по одной _Кости превосходства_ на 7 и 15 уровнях.

# Приёмы

Вы изучаете четыре следующих приёма.`,
    maneuverIdList: [
      MANEUVER_DETECT_HIDDEN_AND_LIES_2016_04_04,
      MANEUVER_INT_WIS_CHA_SAVING_THROW_2016_04_04,
      MANEUVER_MAINTAIN_CONCENTRATION_DISADVANTAGE_2016_04_04,
      MANEUVER_PRECISION_ATTACK,
    ],
  },
  {
    id: FEATURE_MONSTER_SLAYER_2016_04_04,
    name: `Убийца чудищ`,
    nameEn: `Monster Slayer`,
    lvl: 7,
    text: `Когда Вы тратите Кость превосходства и добавляете её к урону, то можете потратить вместо этого сразу две Кости превосходства, добавляя к урону обе из них. Обе Кости тратятся как обычно.

Если цель атаки — аберрация, фея, исчадие или нежить, то Вы не бросаете эти кости, а выбираете их максимальные значения вместо этого`,
  },
  {
    id: FEATURE_IMPROVED_COMBAT_SUPERIORITY_2016_04_04,
    name: `Улучшенное боевое превосходство`,
    nameEn: `Improved Combat Superiority`,
    lvl: [10, 18],
    text: `Ваша [Кость превосходства](FEATURE:${FEATURE_COMBAT_SUPERIORITY_2016_04_04}) увеличивается до к10.

На 18 уровне — до к12.`,
  },
  {
    id: FEATURE_RELENTLESS_2016_04_04,
    name: `Неослабевающий`,
    nameEn: `Relentless`,
    lvl: 15,
    text: `Если Вы совершаете бросок инициативы, не имея [Костей превосходства](FEATURE:${FEATURE_COMBAT_SUPERIORITY_2016_04_04}), Вы получаете одну.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_FIGHTER_MONSTER_HUNTER_2016_04_04,
    source: {
      id: SOURCE_UA_2016_04_04_GOTHIC_HEROES,
      page: 2,
    },
  })
)
