const {
  SOURCE_SKT,
} = require('./../../sourceList')
const {LANG_SUBGROUP_ILLUSKI} = require('./../../languageSubGroupList')
const {
  LANG_TYPE_ETHNIC,
} = require('./../../languageTypeList')
const {
  LANG_BOTHII,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_BOTHII,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_ILLUSKI,
  alphabetId: null,
  name: {
    nominative: 'Ботия',
    genitive: 'Ботии',
    instrumental: 'Ботией',
    prepositional: 'Ботии',
  },
  nameEn: 'Bothii',
  nameAlt: 'Утгардский язык',
  nameEnAlt: 'Uthgardt Language',
  dictionary: {
    list: [
      ['дочь титана', 'вонинсдоттир'],
      ['кровь змея', 'вормблод'],
    ],
    source: {
      id: SOURCE_SKT,
      page: 70,
    },
  },
  description: [
    {
      text: 'Варвары-утгардтцы говорят на собственном языке, называемым «Ботия», в котором нет алфавита',
      source: {
        id: SOURCE_SKT,
        page: 70,
      },
    },
  ],
  isRealLang: true,
  isReady: true,
}
