module.exports = [
  require('./babau'),
  require('./banderhobb'),
  require('./bard'),
  require('./barghest'),
  require('./bheur_hag'),
  require('./blackguard'),
  require('./bodak'),
  require('./boggle'),
  require('./brontosaurus'),
]
