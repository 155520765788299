const {FEATURE_PURIFYING_LIGHT_2016_11_28} = require('./../../../featureIdList')
const {PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS_2016_11_28} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE} = require('./../../../sourceList')
const {SPELL_DISPEL_MAGIC} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_PURIFYING_LIGHT_2016_11_28,
    name: `Очищающий свет`,
    nameEn: `Purifying Light`,
    lvl: 14,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS_2016_11_28,
    spellIdList: [
      SPELL_DISPEL_MAGIC,
    ],
    source: {
      id: SOURCE_UA_2016_11_28_DRUID_CIRCLES_AND_WILD_SHAPE,
      page: 1,
    },
  },
  // {
  //   id: FEATURE_,
  //   name: ``,
  //   nameEn: ``,
  //   lvl: ,
  //   pcSubClassId: PC_SUBCLASS_,
  //   text: ``,
  //   spellIdPickByLvl: ,
  //   spellCircleLvl: ,
  //   spellIdList: SPELL_,
  //   source: {
  //     id: SOURCE_,
  //     page: ,
  //   },
  // },
]
