const {
  SPELL_COMMAND,
  SPELL_HOLD_PERSON,
  SPELL_INFLICT_WOUNDS,
  SPELL_LIGHT,
  SPELL_SACRED_FLAME,
  SPELL_SHIELD_OF_FAITH,
  SPELL_SPIRITUAL_WEAPON,
  SPELL_THAUMATURGY,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_DECEPTION,
  SKILL_PERSUASION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_DAGGER,
  GEAR_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {ABILITY_DARK_DEVOTION} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NOT_GOOD} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_CULT_FANATIC} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Фанатик культа',
  nameEn: 'Cult Fanatic',
  id: CREATURE_CULT_FANATIC,
  description: `Фанатики часто являются лидерами культов. Они используют харизму и догматы для влияния на других и ломку чужой воли. Многие из них стараются получить власть любой ценой.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_NOT_GOOD,
  source: {
    id: SOURCE_MM,
    page: 350,
  },
  armor: {
    ac: 13,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 14,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_DARK_DEVOTION,
  ],
  spellCast: {
    spellCasterLevel: 4,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_COMMAND,
      SPELL_HOLD_PERSON,
      SPELL_INFLICT_WOUNDS,
      SPELL_LIGHT,
      SPELL_SACRED_FLAME,
      SPELL_SHIELD_OF_FAITH,
      SPELL_SPIRITUAL_WEAPON,
      SPELL_THAUMATURGY,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_DAGGER,
    },
  ],
  genderId: GENDER_MALE,
}
