const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_BARRIER_TATTOO_12_PLUS,
  MAGIC_ITEM_BARRIER_TATTOO_15_PLUS,
  MAGIC_ITEM_BARRIER_TATTOO_18,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_BARRIER_TATTOO_12_PLUS,
    rarity: MGC_RARITY_UNCOMMON,
    ac: '12 + модификатор Ловкости',
    acTitle: '12+',
  },
  {
    id: MAGIC_ITEM_BARRIER_TATTOO_15_PLUS,
    rarity: MGC_RARITY_RARE,
    ac: '15 + модификатор Ловкости (максимум +2)',
    acTitle: '15+',
  },
  {
    id: MAGIC_ITEM_BARRIER_TATTOO_18,
    rarity: MGC_RARITY_VERY_RARE,
    ac: 18,
    acTitle: '18',
  },
]
