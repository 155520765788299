const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {ABILITY_KEEN_SMELL} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_CATOBLEPAS} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Катоблепас',
  nameEn: 'Catoblepas',
  id: CREATURE_CATOBLEPAS,
  description: `Катоблепас так же отвратителен как и гнусные болота, в которых он живёт. Как и его место обитания, это скопление частей раздутого буйвола, динозавра, бородавочника и гиппопотама не может похвастаться широким набором положительных качеств. Немногие путешественники по своей воле идут по территории катоблепаса.

**Естество животного.** Несмотря на свою несуразную физиологию, катоблепасы напоминают обычных животных. Катоблепас и ведёт себя очень похоже на животное, гуляет по своей территории в болотах, жуёт траву, иногда ест мертвечину и валяется в топях. Катоблепас может жить с одним партнером, которого выбирает на всю жизнь и иногда у них рождается ребёнок. Он особенно охраняет потомство и нападает на любого, кто подойдёт слишком близко.

**Запах смерти.** Вонь катоблепаса, запах смерти смешанный с болотным газом и вонючим мускусом, выдаёт, что он более страшен, чем можно судить по его внешнему виду. При нападении катоблепас раскрывает пределы своей ужасной натуры. Змеиная шея существа с трудом поднимает его голову, но один взгляд из его налитых кровью глаз может заставлять плоть гнить. На конце его хвоста есть дубинка, которой он может разбить тело и душу при хорошем ударе, что сделает жертву неспособной на действия. Если цель его атаки умирает, то катоблепас питается останками.

**Отравленная территория.** Катоблепас по своей природе — это существо болезни и гниения, и он делает болота вокруг своего жилища такими же. Эта территория становится мрачной, запутанной и более зловонной, чем была до этого. Все хорошие свойства среды, такие как лечебные травы или чистая вода, уменьшаются, если рядом живет катоблепас. Болотные газы выдают влияние катоблепаса на них. Животные в этой области более агрессивны и подвержены болезням. Дегенеративные существа обычно селятся рядом с территорией катоблепаса, так как хотят избежать лишних взглядов.

**Зловещий фольклор.** Обычный народ редко видит катоблепаса, но у существа такая страшная репутация, что истории о нём укоренились в популярной культуре. Любой слух о поселившемся рядом катоблепасе считается дурным знамением, даже если он ложный. Силуэт катоблепаса с поднятым вверх хвостом и низко наклонённой головой — это угрожающий геральдический рисунок, обозначающий смерть или злой рок.

Мудрецы говорят, что боги мора и гниения создали катоблепасов как воплощение своего влияния. Какой бы ни была первопричина, истории связывают катоблепасов с бедой, и во многих из этих сказок есть толика правды. Некоторые такие рассказы утверждают, что карги держат катоблепасов как скот, и что болото с катоблепасом может быть домом карги, которая пьет молоко чудовища. Хотя конкретный катоблепас может и не быть связан с каргой, шабащ карг может держать одного или несколько таких зверей как стражников или питомцев. Другие легенды говорят, что те, кто не чист сердцем, могут приручить катоблепаса. На самом деле некоторые истории рассказывают о злобных колдунах и тёмных рыцарях, которые узнали как приручать этих зверей и использовать из как ездовых.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_VGTM,
    page: 155,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 21,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 8,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_KEEN_SMELL,
    {
      name: 'Вонь',
      description: `Любое существо, кроме катоблепаса, которое начинает свой ход не далее 10 футов от него, должно пройти испытание Телосложения СЛ 16 или стать отравленным до начала своего следующего хода. При успешном испытании существо получает иммунитет к _Вони_ любого катоблепаса на 1 час.`,
    },
  ],
  actionList: [
    {
      name: 'Хвост',
      description: `Цель должна пройти испытание Телосложения СЛ 16 или стать ошеломлённой до начала следующего хода катоблепаса.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 5,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Луч смерти',
      restore: 5,
      description: `Катоблепас выбирает целью существо, которое может видеть, не далее 30 футов от себя. Цель должна пройти испытание Телосложения СЛ 16 и получить 36 (8к8) некротического урона при провале или половину этого урона при успехе. Если испытание провалено на 5 или больше, то вместо этого цель получает 64 некротического урона. Цель умирает, если её хиты уменьшены до 0 этим лучом.`,
    },
  ],
}
