const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_JUMP,
  SPELL_MAGE_HAND,
  SPELL_MISTY_STEP,
  SPELL_NONDETECTION,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_GITH,
  CREATURE_TYPE_GITHYANKI,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_GITHYANKI_WARRIOR,
  CREATURE_MIND_FLAYER,
} = require('./../../../../creatureIdList')
const {
  githyankiDescription,
  githyankiNote,
} = require('./../../../../textCommonParts')
const {
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  GEAR_GREATSWORD,
  GEAR_HALF_PLATE,
} = require('./../../../../gearIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_3} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_GITH} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гитьянки воитель',
  nameEn: 'Githyanki Warrior',
  id: CREATURE_GITHYANKI_WARRIOR,
  description: [
    `Гитьянки грабят бесчисленные миры с палуб своих астральных кораблей и спин [красных драконов](CREATURE:${CREATURE_DRAGON_RED_ANCIENT}). Перья, бисер, драгоценные камни и металлы украшают их доспехи и оружие — легендарные серебряные мечи с которыми они прорубаются с боем через своих врагов. Вырвав свободу у [свежевателей разума](CREATURE:${CREATURE_MIND_FLAYER}), гитьянки стали безжалостными завоевателями под руководством ужасной королевы-лича Влаакит.`,
    ...githyankiDescription,
  ],
  note: githyankiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHYANKI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 59,
  },
  armor: {
    ac: 17,
    gearId: GEAR_HALF_PLATE,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 10,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  languageList: [
    LANG_GITH,
  ],
  cr: CR_3,
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_CHA,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_MAGE_HAND,
            comment: 'рука невидима',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          {
            id: SPELL_NONDETECTION,
            comment: 'только на себя',
          },
          SPELL_JUMP,
          SPELL_MISTY_STEP,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Двуручным мечом_.`,
    },
    {
      gearId: GEAR_GREATSWORD,
      attack: {
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 2,
          diceType: 6,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
