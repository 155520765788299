const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_BAT,
  CREATURE_GIANT_BAT,
  CREATURE_GIANT_RAT,
  CREATURE_GRAZZT,
  CREATURE_RAT,
  CREATURE_WERERAT,
  CREATURE_XVART,
  CREATURE_XVART_SPEAKER,
  CREATURE_XVART_WARLOCK_OF_RAXIVORT,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Ксварты',
    text: `[Ксварты](CREATURE:${CREATURE_XVART}) — жестокие, трусливые гуманоиды, порожденные трусливым полубогом–ренегатом. У них синяя кожа, яркие оранжевые глаза, реденькие волосы, всё это подражает внешности их создателя. Их рост примерно 3 фута.

Ксварты живут в отдаленных холмах, лесах и пещерах. Каждое племя управляется [спикером](CREATURE:${CREATURE_XVART_SPEAKER}), обычно умнейшим из них. [Спикер](CREATURE:${CREATURE_XVART_SPEAKER}) служит послом племени и часто одевает короткие деревянные ходули и тяжёлые робы, чтобы выглядеть выше и более властно. Остальное племя добывает пищу, грабя урожай и скот с окрестных ферм, если охота не удалась. Ксварты не очень большая угроза для цивилизованных мест, потому что они боятся людей, дварфов и эльфов.

**Предательство Раксиворта.** Все ксварты — это вырожденное потомство существа по имени Раксиворт, которое когда-то служило [Тёмному Принцу Граз’зту](CREATURE:${CREATURE_GRAZZT}) казначеем. Раксиворт провел многие столетия, наблюдая за сокровищами, и спустя года начал жаждать богатств своего хозяина. Однажды он осмелился ограбить хранилище сокровищ и сбежал на Материальный план. Одним из сокровищ, что он украл, было Веретено Бесконечности, кристаллический осколок, который мог превратить даже такое незначительное существо как Раксиворт в полубога.

После становления божеством Раксиворт создал мир под названием Черные Стоки, внутри Пандасмоса, самого верхнего слоя Пандемония. Однако, он наслаждался своей божественностью довольно недолго, а потом Граз’зт обрушил на него свое возмездие. Принцу демонов не нужно было возвращать Веретено Бесконечности, так как он уже обладал мощью большей, чем оно может дать. Вместо этого, он разослал агентов во все стороны света с вестью о том, что может сделать веретено и о тщедушном, жалком существе, что завладело им. Достаточно скоро Раксиворта стало преследовать множество врагов, жаждущих присвоить Веретено себе.

Перед лицом неминуемого уничтожения Раксиворт создал план. Он сбежал на Материальный план, путешествовал по множеству миров и создавал существ, которые были его точной копией. Так и получились ксварты, которые не только выглядят так же как Раксиворт, но и срывают любую магию, которая используется для его выслеживания. Заклинания, ритуалы и другие эффекты, которые могут указать местонахождение Раксиворта, вместо этого показывают ближайшего ксварта.

Хотя изначальный ажиотаж врагов по отношению к нему утих, Раксиворт знает, что планарные существа терпеливы. Он продолжает прятаться, несчастный полубог, который только и может что перемещаться между планами, рождая еще ксвартов для обеспечения своей безопасности.

**Жадные Головорезы.** Ксварты имеют все недостатки своего создателя и мало искупляющих свойств. Им не хватает физического оснащения для репродукции, а так же и склонности к этому. Они жадные, потакающие и одержимые желанием обрести ценности — чем более богато украшенные или причудливые, тем лучше. Они знают, что испорченны, и эта низкая самооценка только усиливает их остальные недостатки. Они ненавидят почти любое существо, которое считают лучше себя, что включает практически всех, но им большую часть времени не хватает храбрости или необходимых средств, чтобы потакать своей ненависти. Этот страх заставляет их прозябать в мрачных местах на дальних задворках цивилизованных земель или в областях, брошенных или забытых более сильными существами. Другими словами, ксварты живут в таких местах, где обычно процветают паразиты и вредители.

Несмотря на свою спутанную природу все ксварты непоколебимо верят в Раксиворта. Желание угодить Раксиворту оказывает огромное давление на все их решения. Когда все становится для них плохо, ксварты естественно предполагают, что Раксиворт разгневан. Для умиротворения своего беспокойного повелителя они организовывают похищения. Они приспосабливают сети для ловли своих врагов, которых тащат в логово и приносят в жертву на импровизированном алтаре. Раксиворт может слышать их мольбы, но почти всегда слишком боится выходить из укрытия. Иногда он появляется перед племенем поклонников в виде 9-футового ксварта, несущего пустой мешок. В каждом таком случае Раксиворт забирает все накопленные племенем сокровища, укладывает в мешок и исчезает, не оставив ничего в качестве компенсации.

**Повелители Вредителей.** [Крыс](CREATURE:${CREATURE_RAT}) и [летучих мышей](CREATURE:${CREATURE_BAT}) (включая [гигантских особей](CREATURE:${CREATURE_GIANT_BAT})) естественным образом тянет к ксвартам и ксварты выращивают их для пропитания и сражений. Ксварты также заключают союзы с [веркрысами](CREATURE:${CREATURE_WERERAT}), хотя ликантропы доминируют в любом таком соглашении. Эти отношения берут начало из божественного естества Раксиворта. Хоть ксварты и унаследовали жадность и трусость Раксиворта, они получили способность устанавливать связи с такими существами.

**[Ксварты-колдуны](CREATURE:${CREATURE_XVART_WARLOCK_OF_RAXIVORT}).** Ксварт может заключить пакт с Раксивортом, украв предмет такой большой ценности, что сам полубог появляется перед ксвартом, чтобы забрать его. После передачи предмета Раксиворту, ксварт просит о магической силе, чтобы он мог найти и преподнести Раксиворту ещё более великие сокровища. Если полубог расположен к этому, то он наделяет ксварта большей мудростью и харизмой, а также дает ему заклинательные способности колдуна до того как вернуться в воющий хаос Пандемония. Колдунов ксвартов уважают и боятся в их сообществе, но они мало озабочены политической властью. Они рыскают по глуши, старым руинам и подземельям в поисках сокровищ, зачастую с горсткой ксвартов-лизоблюдов и [гигантских крыс](CREATURE:${CREATURE_GIANT_RAT}) телохранителей в свите.`,
    source: {
      id: SOURCE_VGTM,
      page: 165,
    },
  },
]
