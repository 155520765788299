const {GENDER_FEMALE} = require('./../../../../genderList')
const {MAGIC_ITEM_LOST_CROWN_OF_BESILMER} = require('./../../../../magicItemIdList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_LOST_CROWN_OF_BESILMER,
  name: 'Утерянная корона Безилмеров',
  nameEn: 'Lost Сrown of Besilmer',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: `Эта дварфийская реликвия представляет собой стальной боевой шлем без забрала, лоб которого украшен золотым венцом. На венце выступают вверх семь маленьких золотых шипов. Нося эту корону, Вы получаете следующие преимущества:

* Вы получаете сопротивление психическому урону.
* Вы получаете преимуществом на испытания против направленных на Вас эффектов очарования.
* Вы можете бонусным действием воодушевить одно существо в 60 футах, которое может Вас видеть и слышать. Один раз до конца Вашего следующего хода, это существо может сделать бросок к6 и добавить результат броска к одной своей проверке характеристики, одному своему броску атаки или испытанию. Воодушевление тратит один заряд короны. В короне есть 3 заряда, и она восстанавливает 1к3 заряда каждый день на рассвете.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_PotA,
    page: 229,
  },
}
