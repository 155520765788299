const {FEATURE_TELEKINETIC_HAND} = require('./../../../featureIdList')
const {PC_SUBCLASS_FIGHTER_PSYCHIC_WARRIOR_2019_11_25} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS} = require('./../../../sourceList')
const {SPELL_MAGE_HAND} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_TELEKINETIC_HAND,
    name: `Телекинетическая рука`,
    nameEn: `Telekinetic Hand`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_FIGHTER_PSYCHIC_WARRIOR_2019_11_25,
    spellIdList: SPELL_MAGE_HAND,
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 1,
    },
  },
]
