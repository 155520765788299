const {SOURCE_EGTW} = require('./../../sourceList')

module.exports = [
  {
    header: `Динамий`,
    text: `_Динамий_ — это первородная магическая энергия возможности и реальности, предтеча волшебных сил, помогающих формировать мультивселенную. _Динамий_ вполне может быть тем самым, что скрепляет её части вместе, подобно паутине невидимых связей. _Динамий_ проявляется как серая полупрозрачная энергия. Когда его собирают, он мерцает и течёт как лёгкая дымка, а, накапливаясь для действия или призыва, _динамий_ темнеет, дрожа и сгущаясь, и манипулирует фундаментальными аспектами реальности. Те, кто учится управлять и использовать эту почти невидимую энергию, могут тонко изменять поток времени и пространства. Они могут управлять силами местной гравитации; вглядываться в возможные временные линии, меняя будущее себе на пользу; лишать врагов возможностей, рассеивая их возможностную энергию.`,
    source: {
      id: SOURCE_EGTW,
      page: 183,
    },
  },
  {
    header: `Динамантия`,
    text: `Динамантия — древнее эзотерическое учение о магии, почти неизвестное за пределами Эксандрии. Частички динамантии постепенно перетекли в практики более обычного заклинательства как малозаметные отблески с той стороны завесы творения. Маги, изучающие эту странную и сложную дисциплину, зовут себя динамантами. Их интерес к изменению ткани гравитации, потенциала и времени часто сопутствует жажде постижения старейших тайн космоса.`,
    source: {
      id: SOURCE_EGTW,
      page: 183,
    },
  },
  {
    header: `Динамий в военном применении`,
    text: `Жизнь — череда выборов. Каждый перекрёсток предлагает путь к разным возможностям. Реальность всех возможных выборов начинает сливаться, когда Вы подходите к моменту принятия решения. Множество временных линий поют возможностями. Как только решение принято, один путь начинать воплощается в жизнь и продолжается, а прочие угасают, распыляя энергию своих возможностей по мультивселенной.

Очень мало кто научается вызывать и обуздывать этот выпущенный в пылу битвы _динамий_ для усиления собственных боевых способностей. И таких воителей страшатся повсеместно.`,
    source: {
      id: SOURCE_EGTW,
      page: 184,
    },
  },
  {
    header: `Помимо династии Крин`,
    text: `Хотя династия Крин отвечает за тщательно охраняемое развитие и выверку динамантии, тайны этого магического искусства давно распространились за границы династии. Тайные секреты овладения динамием в конце концов оказались в руках Общества Цербера и теперь продолжают медленно распространяться по всему Дикогорью.

Если Вы задумываете персонажа, чей путь связан с манипуляциями _динамием_, то можете легко привязать его к династии Крин и предоставляемому ей готовому доступу к динамантии. Или Вы можете учесть, где в мире динамантия может быть распространена и как её тайны повлияли на путь Вашего персонажа. Например, Вы могли наткнуться на клику беглых выходцев из Крина, которые научили Вас своему подходу. Или Вам могли доверить изучение таких секретов, дабы бороться с Крином его же оружием. Проработайте это с Мастером и придумайте интересную и логичную причину того, что пути Вашего персонажа и этой загадочной силы пересеклись.

В кампаниях вне Дикогорья нет фракционного контроля за динамантией, поэтому использование этого учения полностью открыто. Поговорите с Мастером о том, как динамантия впишется в его кампанию и как её можно вплести в историю Вашего персонажа.`,
    source: {
      id: SOURCE_EGTW,
      page: 183,
    },
  },
]
