const {gearByCatCollection} = require('./../../../../../gearList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')

const gearCategoryList = require('./gearCategoryList')

module.exports = [
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_RADIANT,
  DAMAGE_THUNDER,
]
  .reduce(
    (list, damageTypeId) => [
      ...list,
      ...gearCategoryList.reduce(
        (listByCats, catId) => [
          ...listByCats,
          ...gearByCatCollection[catId].map(
            gearId => ({
              id: `${gearId}_of_${damageTypeId}_resistance`,
              gearId,
              damageTypeId,
            })
          )
        ],
        []
      )
    ],
    []
  )
