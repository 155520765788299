const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_ABISHAI_BLACK,
  CREATURE_ABISHAI_BLUE,
  CREATURE_ABISHAI_GREEN,
  CREATURE_ABISHAI_RED,
  CREATURE_ABISHAI_WHITE,
} = require('./../../../../creatureIdList')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_RECKLESS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_ABISHAI,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DRACONIC,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  abishaiDescriptionList,
  abishaiNote,
  devilDescriptionList,
} = require('./../../../../textCommonParts')
const {
  SOURCE_BGDIA,
  SOURCE_MTOF,
} = require('./../../../../sourceList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_6} = require('./../../../../crList')
const {GEAR_LONGSWORD} = require('./../../../../gearIdList')
const {GOD_TIAMAT} = require('./../../../../godIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')

module.exports = {
  name: 'Белый абишай',
  nameEn: 'White Abishai',
  id: CREATURE_ABISHAI_WHITE,
  description: [
    `Хоть они и являются слабейшими из своего вида, белые абишаи сражаются с безрассудной яростью, что делает их идеально подходящими для укрепления рядов армий [Тиамат](GOD:${GOD_TIAMAT}). Белые абишаи сражаются бесстрашно, становясь вихрем разрушения на поле битвы.`,
    {
      header: 'Белые абишаи',
      text: `Абишаи не входят в обычную иерархию Девяти Преисподней, имея свою собственную цепь командования, беспрекословно подчиняясь [Тиамат](GOD:${GOD_TIAMAT}). Другие архидьяволы могут приказывать абишаям работать на них, но большинство из них редко делает это, так как никогда не ясна степень влияния Тиамат на этих существ.

Есть пять видов абишаев, соответствующих каждой из голов [Тиамат](GOD:${GOD_TIAMAT}) (белые, [зелёные](CREATURE:${CREATURE_ABISHAI_GREEN}), [красные](CREATURE:${CREATURE_ABISHAI_RED}), [синие](CREATURE:${CREATURE_ABISHAI_BLUE}) и [чёрные](CREATURE:${CREATURE_ABISHAI_BLACK})). Белые абишаи слабейшие. Они бьются на поле боя с жестокой яростью.`,
      source: {
        id: SOURCE_BGDIA,
        page: 242,
      },
    },
    ...abishaiDescriptionList,
    ...devilDescriptionList,
  ],
  note: abishaiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ABISHAI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 175,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 18,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_6,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    ABILITY_RECKLESS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Абишай совершает две атаки: одну _Длинным мечом_ и одну _Когтями_.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  reactionList: [
    {
      name: 'Жестокое возмездие',
      description: `В ответ на полученный урон, абишай совершает атаку _Укусом_ по случайному существу в пределах 5 футов от себя. Если ни одного существа нет в этой зоне досягаемости, абишай перемещается к противнику, которого он может видеть, на расстояние равное половине своей скорости, не вызывая при этом атак по возможности у других существ.`,
    },
  ],
}
