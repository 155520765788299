const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ACROBATICS,
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_SLEIGHT_OF_HAND,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ABILITY_CUNNING_ACTION,
  ABILITY_EVASION,
  ABILITY_SNEAK_ATTACK,
} = require('./../../../../creatureAbilityList')
const {
  GEAR_CROSSBOW_LIGHT,
  GEAR_SHORTSWORD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ANY,
  LANG_THIEVES_CANT,
} = require('./../../../../languageIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_MASTER_THIEF} = require('./../../../../creatureIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Мастер-вор',
  nameEn: 'Master Thief',
  id: CREATURE_MASTER_THIEF,
  description: `Мастера-воры известны тем, что совершили отчаянные грабежи. Они склонны создавать себе репутацию и культ личности. Мастер-вор может «уйти на покой» от практической работы, чтобы управлять гильдией воров, возглавить некоторое тайное предприятие, или жить спокойной и роскошной жизнью.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 217,
  },
  armor: {
    ac: 16,
    gearId: GEAR_STUDDED_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_INT,
  ],
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SLEIGHT_OF_HAND]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      comment: 'обычно — Всеобщий',
    },
    LANG_THIEVES_CANT,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_CUNNING_ACTION,
    ABILITY_EVASION,
    {
      id: ABILITY_SNEAK_ATTACK,
      damage: '14 (4к6)',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Вор совершает три атаки _Коротким мечом_.`,
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_CROSSBOW_LIGHT,
    },
  ],
  reactionList: [
    {
      name: 'Невероятное уклонение',
      description: `Вор уменьшает вдвое урон от попавшей по нему атаки. Вор должен видеть нападающего.`,
    },
  ],
}
