const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_LYCANTHROPE} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_LYCANTHROPE,
  nameEn: 'Lycanthrope',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'ликантроп',
      genitive: 'ликантропа',
      dative: 'ликантропу',
      accusative: 'ликантропа',
      instrumental: 'ликантропом',
      prepositional: 'ликантропе',
    },
    plural: {
      nominative: 'ликантропы',
      genitive: 'ликантропов',
      dative: 'ликантропам',
      accusative: 'ликантропов',
      instrumental: 'ликантропами',
      prepositional: 'ликантропах',
    },
  },
}
