const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  CREATURE_CADAVER_COLLECTOR,
  CREATURE_HOBGOBLIN_WARLORD,
  CREATURE_NECROMANCER,
  CREATURE_SPECTER,
} = require('./../../../../creatureIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_14} = require('./../../../../crList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {LANG_ALL} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Собиратель трупов',
  nameEn: 'Cadaver Collector',
  id: CREATURE_CADAVER_COLLECTOR,
  description: [
    `Древние боевые машины, известные как собиратели трупов бесцельно блуждают по обожжённым пустошам Ахерона, пока их не призовёт [некромант](CREATURE:${CREATURE_NECROMANCER}), [хобгоблин-генерал](CREATURE:${CREATURE_HOBGOBLIN_WARLORD}) или другой злой правитель чтобы увеличить размеры завоевательной армии. Эти бесстрашные конструкты подчиняются своим призывателям пока их не отправят обратно в Ахерон, но если призыватель погибает, то собиратель трупов может блуждать по Материальному плану веками, собирая трупы, пока ищет путь домой.`,
    {
      header: 'Подчищая мёртвых',
      text: `Собиратели трупов отвечают на призыв смертного только, если их призывают на место поля битвы: там, где битва уже в самом разгаре, или в месте неминуемой битвы, либо в месте, где уже произошла грандиозная битва. Они заключают себя в доспехи и орудия павших воинов и насаживают их трупы на копья и другие виды оружия, торчащие из их добытой брони.`,
      source: {
        id: SOURCE_MTOF,
        page: 228,
      },
    },
    {
      header: 'Призванные берсерки',
      text: `Трупы, которые собираются на корпусе конструкта — это не просто мерзкие боевые трофеи. Собиратель трупов может призвать духов из этих трупов, чтобы те присоединились к битве с его противниками и парализовали их для неизбежного насаживания. Хоть эти духи и слабы по отдельности, собиратель трупов может призвать неисчислимое количество, если дать ему время.`,
      source: {
        id: SOURCE_MTOF,
        page: 228,
      },
    },
    {
      header: 'Природа конструкта',
      text: `Собирателю не нуждается в воздухе, еде, питье или сне.`,
      source: {
        id: SOURCE_MTOF,
        page: 228,
      },
    },
  ],
  note: {
    text: `Собиратели трупов не могут призвать духов тех, кого они ещё не убили, а уничтоженные духи не возвращаются обратно. Лишите его жертв и не атакуйте, пока не будете уверены, что можете его уничтожить. К примеру, заклинание „Метеоритный дождь“ прекрасно работает против них.`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 228,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 21,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 20,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 8,
  },
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ALL,
      doNotSpeak: true,
    },
  ],
  cr: CR_14,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Призыв спектров',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Бонусным действием собиратель трупов взывает к порабощённым душам убитых им и 1к6 [спектров](CREATURE:${CREATURE_SPECTER}) (без чувствительности к солнечному свету) появляются в незанятом пространстве в пределах 15 футов от собирателя трупов. Спектры действуют сразу после собирателя трупов, используя значение его Инициативы и сражаются до того, пока не будут уничтожены. Но они исчезают, если будет уничтожен собиратель трупов.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Собиратель трупов совершает две атаки _Ударом_.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 3,
            diceType: 8,
            diceBonus: 5,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 3,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Парализующее дыхание',
      restore: 5,
      description: `Собиратель трупов выпускает парализующий газ в 30-футовом конусе пред собой. Каждое существо, попавшее в эту область, должно пройти испытание Телосложения СЛ 18 или стать парализованным на 1 минуту. Парализованное существо может повторять это испытание в конце каждого своего хода, завершая эффект при успехе.`,
    },
  ],
}
