const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_CHARM_PERSON,
  SPELL_CROWN_OF_MADNESS,
  SPELL_DETECT_THOUGHTS,
  SPELL_ELDRITCH_BLAST,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FLY,
  SPELL_FRIENDS,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_ILLUSORY_SCRIPT,
  SPELL_MESSAGE,
  SPELL_MINOR_ILLUSION,
  SPELL_POISON_SPRAY,
  SPELL_PRESTIDIGITATION,
  SPELL_SUGGESTION,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_SHAPESHIFTER,
  CREATURE_TYPE_YUAN_TI,
} = require('./../../../../creatureTypeIdList')
const {
  yuantiDescriptionList,
  yuantiNoteList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_YUAN_TI_MALISON_1,
  CREATURE_YUAN_TI_MIND_WHISPERER,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ERRATA_VGTM,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  SKILL_DECEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_5} = require('./../../../../crList')
const {GEAR_SCIMITAR} = require('./../../../../gearIdList')
const {GOD_SSETH} = require('./../../../../godIdList')
const {PC_CLASS_WARLOCK} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Юань-ти шепчущий в мыслях',
  nameEn: 'Yuan-ti Mind Whisperer',
  id: CREATURE_YUAN_TI_MIND_WHISPERER,
  description: [
    `Шепчущие в мыслях — проклинатели-заклинатели, которые заключили пакт с змеиным божеством [Ссетом, Шипящей Смертью](GOD:${GOD_SSETH}). Они используют свои способности, чтоб обратить других в свою веру, увеличить личную мощь и одурачить разум своих врагов.

Шепчущий в мыслях изворотлив, манипулятивен, непредсказуем, готов обмануть или убить товарищей и соперников, если это принесет ему выгоду. Поклоняющиеся [Ссету](GOD:${GOD_SSETH}) запустили руки в много «схем», часто лавируя между двумя фракциями и тратя много энергии, чтоб ни один из их союзников не узнал про многочисленные противоречивые связи. Даже среди юань-ти шепчущие в мыслях известны как самовлюбленные, скрытные, и склонные к бегству при первых признаках неприятностей особи.

[Этот тип проклинателей](CREATURE:${CREATURE_YUAN_TI_MALISON_1}) имеет человеческое тело и голову змеи.`,
    ...yuantiDescriptionList,
  ],
  note: yuantiNoteList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_YUAN_TI,
  ],
  alignmentId: ALIGNMENT_NE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 208,
    },
    {
      id: SOURCE_ERRATA_VGTM,
      title: 'Мудрость повышена до 14',
      page: 1,
    },
  ],
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
      comment: 'прозревает магическую тьму',
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_5,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Перевёртыш',
      description: `Юань-ти может использовать действие и превратиться в Среднюю змею или обратно в свою истинную форму. Его характеристики одинаковы в обеих формах. Любое снаряжение, которое он несёт и носит, не превращается. Если он умирает, то остаётся в своей текущей форме.`,
    },
    {
      name: 'Клыки разума',
      limit: {
        count: 2,
        period: 'день',
      },
      description: `В первый раз, когда юань-ти попадает рукопашной атакой в свой ход, он может дополнительно нанести 16 (3к10) психического урона цели.`,
    },
    {
      name: 'Благословение Ссета',
      description: `Когда юань-ти уменьшает хиты врага до 0, он получает 9 временных хитов.`,
    },
  ],
  spellCast: [
    {
      comment: 'только в форме юань-ти',
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            {
              id: SPELL_ANIMAL_FRIENDSHIP,
              comment: 'только змеи',
            },
          ],
        },
        {
          limit: {
            count: 3,
            period: 'день',
          },
          list: [
            SPELL_SUGGESTION,
          ],
        },
      ],
    },
    {
      comment: 'только в форме юань-ти',
      spellCasterLevel: 6,
      spellCasterClass: PC_CLASS_WARLOCK,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            {
              id: SPELL_ELDRITCH_BLAST,
              comment: `дистанция 300 фт., +3 к каждому броску урона`,
            },
            SPELL_FRIENDS,
            SPELL_MESSAGE,
            SPELL_MINOR_ILLUSION,
            SPELL_POISON_SPRAY,
            SPELL_PRESTIDIGITATION,
          ],
        },
        {
          preText: `1–3 уровни (2 ячейки 3-го уровня)`,
          list: [
            SPELL_CHARM_PERSON,
            SPELL_CROWN_OF_MADNESS,
            SPELL_DETECT_THOUGHTS,
            SPELL_EXPEDITIOUS_RETREAT,
            SPELL_FLY,
            SPELL_HYPNOTIC_PATTERN,
            SPELL_ILLUSORY_SCRIPT,
          ],
        },
      ],
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в форме юань-ти',
      description: `Юань-ти совершает одну атаку _Укусом_ и одну атаку _Скимитаром_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 3,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 2,
            diceType: 6,
          },
        ],
      },
    },
    {
      gearId: GEAR_SCIMITAR,
      comment: 'только в форме юань-ти',
    },
  ],
}
