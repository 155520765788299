const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_LYCANTHROPE,
  CREATURE_TYPE_SHAPESHIFTER,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SOURCE_MM,
  SOURCE_ERRATA_MONSTER_MANUAL,
} = require('./../../../../sourceList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ABILITY_KEEN_HEARING_AND_SMELL} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_3} = require('./../../../../crList')
const {CREATURE_WEREWOLF} = require('./../../../../creatureIdList')
const {GEAR_SPEAR} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {lycantropDescription} = require('./../../../../textCommonParts')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Вервольф',
  nameEn: 'Werewolf',
  description: [
    `Вервольф — это дикий хищник. В гуманоидном облике у такого оборотня обострённые чувства, вспыльчивый характер, а также страсть к сырому мясу. Если в зверином облике оборотень выступает в роли хищника, то в гибридном он выглядит очень пугающе — развитое мускулистое тело, покрытое шерстью, увенчано головой волка. Вервольф может использовать оружие в гибридном облике, однако предпочитает разрывать противников на части при помощи когтей и клыков.

Большинство вервольфов начинает сторониться цивилизованных земель практически сразу же после превращения. Те, кто борются с проклятьем, опасаются за своих родных и близких, так как не уверены в своём контроле. Те, кто приняли проклятье, боятся своих действий и жажды убийств. В дикой среде вервольфы образуют стаи, в которых можно встретить обычных и лютых волков.`,
    ...lycantropDescription,
  ],
  note: {
    text: `Содружество Чёрной Луны — когда-то они были искателями приключений, преданными своей стране. Теперь они бродят по лесу как стая вервольфов. Король пообещал землю, титулы и золото любому, кто сможет снять с них проклятье. Меня, к примеру, не интересует такая награда`,
    author: `Торнстаф, эльф друид`,
  },
  id: CREATURE_WEREWOLF,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_LYCANTHROPE,
  ],
  alignmentId: ALIGNMENT_CE,
  source: [
    {
      id: SOURCE_MM,
      page: 190,
    },
    {
      id: SOURCE_ERRATA_MONSTER_MANUAL,
      page: 1,
    },
  ],
  armor: [
    {
      ac: 11,
      comment: 'в облике гуманоида',
    },
    {
      ac: 12,
      type: ARMOR_TYPE_NATURAL,
      comment: 'в облике волка и гибридном облике',
    },
  ],
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: {
      value: 30,
      comment: '40 фт. в облике волка',
    },
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 14,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  languageList: [
    {
      id: LANG_COMMON,
      comment: 'не может говорить в облике волка',
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Перевёртыш',
      description: `★СУЩЕСТВО★ может действием превратиться в волка, гибрид волка и гуманоида, или же принять свой истинный облик гуманоида. Все его статистики кроме КД остаются одинаковыми во всех обликах. Всё несомое и носимое им снаряжение не превращается. Он принимает свой истинный облик, если умирает.`,
    },
    ABILITY_KEEN_HEARING_AND_SMELL,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в облике гуманоида или гибрида',
      description: `★СУЩЕСТВО★ совершает две атаки: две _Копьём_ (только в облике гуманоида), или одну _Укусом_, и одну _Когтями_ (только в гибридном облике).`,
    },
    {
      name: 'Укус',
      comment: 'только в облике волка или гибрида',
      description: `Если цель — гуманоид, она должна пройти испытание Телосложения СЛ 12, иначе станет проклятой ликантропией вервольфа.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Когти',
      comment: 'только в гибридном облике',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
    {
      gearId: GEAR_SPEAR,
      comment: 'только в облике гуманоида',
    },
  ],
  genderId: GENDER_MALE,
}
