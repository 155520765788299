const {
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_SEE_INVISIBILITY,
} = require('./../../../../../spellIdList')
const {GEAR_ROD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROD_OF_ALERTNESS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ROD} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ROD_OF_ALERTNESS,
  name: `Жезл бдительности`,
  nameEn: `Rod of Alertness`,
  type: MGC_TYPE_ROD,
  gearType: GEAR_ROD,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: [
    {
      header: 'Жезл бдительности',
      text: `У этого жезла ребристое навершие.`,
      source: {
        id: SOURCE_DMG,
        page: 160,
      },
    },
    {
      header: 'Бдительность',
      text: `Пока Вы держите жезл, Вы совершаете с преимуществом проверки Мудрости (Внимательность) и проверки инициативы.`,
      source: {
        id: SOURCE_DMG,
        page: 160,
      },
    },
    {
      header: 'Заклинания',
      text: `Пока Вы держите этот жезл, Вы можете действием наложить им одно из следующих заклинаний: 

* [Видение невидимого](SPELL:${SPELL_SEE_INVISIBILITY}),
* [Обнаружение болезней и яда](SPELL:${SPELL_DETECT_POISON_AND_DISEASE}) или
* [Обнаружение добра и зла](SPELL:${SPELL_DETECT_EVIL_AND_GOOD}). `,
      source: {
        id: SOURCE_DMG,
        page: 160,
      },
    },
    {
      header: 'Защитная аура',
      text: `Вы можете действием воткнуть рукоятку жезла в землю, после чего навершие будет испускать яркий свет в пределах 60 футов и тусклый свет в пределах ещё 60 футов. Находясь в области яркого света, Вы и все дружественные Вам существа получаете бонус +1 к КД и испытаниям, а также можете чувствовать местоположение всех невидимых враждебных существ, тоже находящихся в области яркого света.

Навершие жезла перестаёт светиться, оканчивая эффект, через 10 минут, или когда какое-нибудь существо действием выдернет его из земли. Это свойство нельзя использовать повторно до следующего рассвета.`,
      source: {
        id: SOURCE_DMG,
        page: 160,
      },
    },
  ],
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_DETECT_EVIL_AND_GOOD,
    SPELL_DETECT_POISON_AND_DISEASE,
    SPELL_SEE_INVISIBILITY,
  ],
  source: {
    id: SOURCE_DMG,
    page: 160,
  },
}
