const {LANG_TYPE_DEAD} = require('./../../languageTypeList')
const {SOURCE_DND3_FRCS} = require('./../../sourceList')
const {ALPHABET_IOKHARIC} = require('./../../alphabetList')
const {PLACE_NETHERIL} = require('./../../placeIdList')
const {LANG_SUBGROUP_NETHERESE} = require('./../../languageSubGroupList')
const {
  LANG_HALRUAAN,
  LANG_NETHERESE,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_NETHERESE,
  type: LANG_TYPE_DEAD,
  alphabetId: ALPHABET_IOKHARIC,
  langSubGroupId: LANG_SUBGROUP_NETHERESE,
  name: {
    nominative: 'Нетерезский',
    genitive: 'Нетерезского',
    instrumental: 'Нетерезским',
    prepositional: 'Нетерезском',
  },
  nameEn: 'Netherese',
  description: [
    {
      text: `Предок [Халруанского языка](LANG:${LANG_HALRUAAN})`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
  ],
  spokenPlaceList: PLACE_NETHERIL,
  isRealLang: true,
  isReady: true,
}
