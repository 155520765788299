const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_CHARM_PERSON,
  SPELL_COMMAND,
  SPELL_DISPEL_MAGIC,
  SPELL_FEEBLEMIND,
  SPELL_HOLD_MONSTER,
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_ABYSSAL_WRETCH,
  CREATURE_RUTTERKIN,
  CREATURE_SIBRIEX,
} = require('./../../../../creatureIdList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_18} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {
  SOURCE_BGDIA,
  SOURCE_MTOF,
} = require('./../../../../sourceList')

module.exports = {
  name: 'Сибриекс',
  nameEn: 'Sibriex',
  id: CREATURE_SIBRIEX,
  description: [
    `Считающиеся древними, как сама Бездна, сибриексы обитают на отдалённых частях плана, где они применяют свои мерзкие способности для порождения новых ужасов и постижения запретных знаний. Из тела сибриекса сочатся потоки крови и желчи. Куда бы не направились эти токсичные желеобразные твари, они постоянно оставляют за собой гадкий след.`,
    {
      header: 'Хранители запретных знаний',
      text: `Сибриексы потратили целую вечность, собирая информацию по всему плану, накапливая свои знания, которые, возможно, пригодятся им в будущем. Из-за таких невероятных умственных способностей, многие и разыскивают их, включая тех же лордов демонов. Некоторые сибриексы служат в качестве советников и провидцев, манипулируя демонами до истечения срока их службы, в то время, как другие сибриексы хранят свои секреты и делятся ими только в том случае, если это поможет дальнейшей реализации их планов.`,
      source: {
        id: SOURCE_MTOF,
        page: 138,
      },
    },
    {
      header: 'Создатели демонов',
      text: `Сибриексы могут направить силу Бездны для порождения новых демонов из других существ. За несколько дней они могут породить огромное количество [руттеркинов](CREATURE:${CREATURE_RUTTERKIN}) для защиты своих земель и обеспечения изобилия разрушительных монстров по всему плану. Некоторые демоны молят сибриексов о физическом даре, и, если сибриексы того захотят, они могут пересадить новые части тела, чтобы придать демонам больше силы, зрения, или даже выносливости. Сибриексы никогда не помогают за просто так; взамен на преобразование своего тела, они требуют оказания услуги, либо сокровищ.`,
      source: {
        id: SOURCE_MTOF,
        page: 138,
      },
    },
    {
      header: 'Вариант: Искривление плоти',
      text: `Существа, столкнувшиеся с сибриексом, могут быть скрючены до неузнаваемости. Всякий раз, когда существо проваливает испытание от эффекта способности _Искривление существа_, Вы можете бросить процентную кость и ознакомиться с выпавшим результатом в таблице «Искривление плоти» и определить дополнительный эффект, который исчезает, когда _Искривление существа_ прекращает своё действие. Если существо преобразуется в [тварь Бездны](CREATURE:${CREATURE_ABYSSAL_WRETCH}), то эффект становится постоянной особенностью этого тела.

| к100  | Эффект |
|-------|--------|
| 01-05 | Цвет волос, глаз и кожи цели становится синим, красным, жёлтым или узорчатым. |
| 06-10 | Глаза цели вываливаются из её головы и повисают на зрительных нервах. |
| 11-15 | На руках цели вырастают когти, которыми можно пользоваться как кинжалами. |
| 16-20 | Одна из ног цели становится длиннее другой, уменьшая скорость ходьбы цели на 10 фт. |
| 21-25 | Глаза цели превращаются в маяки, распространяя конус тусклого света диапазоном в 15 фт., когда они открыты. |
| 26-30 | Пара крыльев, перьевых или кожаных, вырастают из спины цели, даруя ей скорость полёта 30 фт. |
| 31-35 | Уши цели отрываются от головы и стремительно убегают от неё; цель становится Оглохшей. |
| 36-40 | Два зуба цели превращаются в клыки. |
| 41-45 | Кожа цели покрывается струпьями, наделяя её бонусом +1 КД, но понижая её же Харизму на 2 (минимум до 1). |
| 46-50 | Руки и ноги цели меняются местами, не давая передвигаться иначе как ползком. |
| 51-55 | Руки цели превращаются в щупальца с пальцами на концах, увеличивая её досягаемость на 5 фт. |
| 55-60 | Ноги цели вырастают до невероятной длины и упругости, увеличивая её скорость ходьбы на 10 фт. |
| 61-65 | У цели отрастает плетеподобный хвост, которым она может пользоваться как кнутом. |
| 66-70 | Глаза цели становятся чёрными, и она получает Тёмное зрение в 120 фт. |
| 71-75 | Цель толстеет, утраивая свой вес. |
| 76-80 | Цель становится худой, кожа да кости, теряя половину своего веса. |
| 81-85 | Голова цели удваивается в размере. |
| 86-90 | Уши цели превращаются в крылья, давая ей скорость полёта 5 футов. |
| 91-95 | Тело цели становится необычно хрупким, заставляя её получить Уязвимость к дробящим, колющим и рубящим повреждениям. |
| 96-00 | Цель отращивает другую голову, с преимуществом проходя испытания против Очарования, Испуга и Ошеломления. |
`,
      source: {
        id: SOURCE_MTOF,
        page: 138,
      },
      isOneColumn: true,
    },
    ...demonDescription,
  ],
  note: [
    {
      text: `Ни одно существо не олицетворяет хаотичную природу Бездны настолько хорошо, как сибриексы. Хоть это царство демонов и является местом бесконечных ужасов, сибриексы, по какой-то причине, делают этот мир ещё более чудовищным местом.`,
      author: `Архимаг Морденкайнен`,
    },
    {
      text: `Сибриекс плодит ужас и копит секреты`,
      author: `Картограф`,
    },
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 137,
    },
    {
      id: SOURCE_BGDIA,
      page: 117,
    },
  ],
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 20,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 3,
    [PARAM_CON]: 23,
    [PARAM_INT]: 25,
    [PARAM_WIT]: 24,
    [PARAM_CHA]: 25,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_18,
  featureList: [
    {
      name: 'Загрязнение',
      description: `Сибриекс истощает ауру разложения в 30 фт. от себя по всем направлениям. Растения, не являющиеся существами, в этой ауре немедленно чахнут, а земля становится труднопроходимой местностью для прочих существ. Каждое существо, начинающее ход в этой области, должно пройти испытание Телосложения СЛ 20, иначе получит 14 (4к6) урона ядом. Существо, преуспевшее в испытании, становится невосприимчивым к действию этой особенности на следующие 24 часа.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_CHARM_PERSON,
          SPELL_COMMAND,
          SPELL_DISPEL_MAGIC,
          SPELL_HOLD_MONSTER,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_FEEBLEMIND,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Сибриекс использует _Струю желчи_ один раз и совершает три атаки, используя _Цепь_, _Укус_, или оба из них.`,
    },
    {
      name: 'Цепь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 15,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 12,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 0,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 2,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Струя желчи',
      description: `Сибриекс выбирает целью одно существо, которое он может видеть, в пределах 120 фт от себя. Цель должна пройти испытание Ловкости СЛ 20, или получит 35 (10к6) урона кислотой.`,
    },
    {
      name: 'Искривление существа',
      description: `Сибриекс выбирает целями трёх существ, которых он может видеть, в пределах 120 фт. от себя. Каждая цель должна пройти испытание Телосложения СЛ 20.

При успехе: цель становится невосприимчивой к _Искривлению существа_ этого сибриекса.

При провале цель становится Отравленной и получает 1 уровень истощения. Отравленная таким образом цель может повторять испытание в начале каждого своего хода, завершая эффект в случае трёх успехов, переставая быть Отравленной, а также теряя все уровни Истощения, полученные ей от этого _искривления существа_. В то же время каждое проваленное испытание будет увеличивать уровень Истощения цели на 1. Если цель получит 6 уровней Истощения, она погибнет и немедленно превратится в [тварь Бездны](CREATURE:${CREATURE_ABYSSAL_WRETCH}) под контролем сибриекса. Трансформация в тварь бездны может быть обращена вспять только заклинанием [исполнение желаний](SPELL:${SPELL_WISH}).`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Наложение заклинания',
      description: `Сибриекс применяет одно из заклинаний с особенности «Врождённое колдовство». `,
    },
    {
      name: 'Брызги желчи',
      description: `Сибриекс использует _Струю желчи_.`,
    },
    {
      name: 'Искажение',
      cost: 2,
      description: `Сибриекс использует действие Искривление существа.`,
    },
  ],
}
