const {
  SOURCE_MM,
} = require('./../../sourceList')
const {
  PC_RACE_ELF,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')

module.exports = [
  {
    header: 'Три-крины',
    text: `Три-крины бродят по пустыням и саваннам, избегая других рас.`,
    source: {
      id: SOURCE_MM,
      page: 284,
    },
  },
  {
    header: 'Общение три-кринов',
    text: `Три-крины используют язык без слов. Для передачи эмоций и реакций три-крины щёлкают жвалами и шевелят усиками, давая понять другим три-кринам, что именно они думают или ощущают. Другие существа считают такой способ коммуникации сложным для понимания и невозможным для копирования.

Когда три-крины вынуждены контактировать с представителями других разумных рас, они пытаются прибегать к альтернативным методам коммуникации, таким как рисунки, сделанные на песке, или выполненные из веточек и травинок.`,
    source: {
      id: SOURCE_MM,
      page: 284,
    },
  },
  {
    header: 'Ограниченные эмоции',
    text: `Три-крины способны испытывать весь спектр эмоций, но они не столь склонны к эмоциональным вспышкам как люди. Три-крины с псионическими способностями часто демонстрируют более широкий диапазон эмоций, особенно если они часто контактируют либо живут рядом с [людьми](PC_RACE:${PC_RACE_HUMAN}) или другими очень эмоциональными существами.`,
    source: {
      id: SOURCE_MM,
      page: 284,
    },
  },
  {
    header: 'Изоляционисты и странники',
    text: `Три-крины рассматривают всех окружающих существ как потенциальную еду, и, в частности, любят вкус [эльфийской](PC_RACE:${PC_RACE_ELF}) плоти. Если существо может быть полезно им в ином, нежели еда, качестве, три-крины вряд ли нападут на него сразу же. Три-крины убивают ради выживания и никогда — ради развлечения.`,
    source: {
      id: SOURCE_MM,
      page: 284,
    },
  },
  {
    header: 'Неспящие',
    text: `Три-кринам не требуется сон, и они могут отдыхать, всегда оставаясь начеку, будучи способными выполнять несложные задачи. Возможно, отсутствие сна является одной из основных причин крайне короткого срока их жизней, ведь средняя продолжительность жизни три-крина составляет всего лишь около тридцати лет.`,
    source: {
      id: SOURCE_MM,
      page: 284,
    },
  },
]
