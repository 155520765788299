const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_MONSTROSITY,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_PETRIFIED,
} = require('./../../../../conditionList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  ABILITY_TRAMPLING_CHARGE,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_GORGON,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Горгона',
  nameEn: 'Gorgon',
  id: CREATURE_GORGON,
  description: `Немногие остаются в живых после встречи с горгоной и могут рассказать об этом. Тело этого существа покрыто металлическими пластинами, а из ноздрей валит зелёный пар.

# Жуткое строение

Окрас железных пластин горгон варьируется от воронёно-чёрного до серебристо-белого, но эта естественная броня никоим образом не сковывает движение и не ограничивает мобильность. Их тела выделяют жир, смазывающий пластины. Больные или обездвиженные горгоны, как лишаем или плесенью, покрываются ржавчиной. Когда заржавевшая горгона двигается, пластины трутся друг об друга, издавая визг и скрежет.

# Чудовищный хищник

Когда горгона замечает свою потенциальную жертву, она нападает с устрашающим металлическим звоном. Попав, она сбивает врага и опрокидывает его навзничь, а затем затаптывает до смерти своими ужасными копытами. Окружённая противниками, горгона выдыхает смертоносные испарения, чтобы поразить существ, обратив их в камень. Проголодавшись, она разбивает окаменевшую добычу на куски и, используя свои крепкие зубы, перемалывает камни в песок, которым питается. Целая сеть пробитых в чаще троп и поваленных деревьев окружает логово горгоны, заваленное не съеденными обломками поверженных врагов.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 70,
  },
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 18,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_PETRIFIED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      id: ABILITY_TRAMPLING_CHARGE,
      attackName_1: 'Боданием',
      attackName_2: 'Копытами',
      strSave_DC: 16,
    },
  ],
  actionList: [
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 12,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Окаменяющее дыхание',
      description: `★СУЩЕСТВО★ выдыхает окаменяющий газ 30-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 13. При провале цель начинает превращаться в камень и обездвиживается. Обездвиженая цель должна повторить испытание в конце своего следующего хода. При успехе эффект на этой цели оканчивается. При провале цель становится окаменевшей, пока не будет вылечена заклинанием _Высшее восстановление_ или подобной магией.`,
      restore: 5,
    },
  ],
  genderId: GENDER_FEMALE,
}
