const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_SCARECROW} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {LANG_ITS_CREATOR} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Пугало',
  nameEn: 'Scarecrow',
  id: CREATURE_SCARECROW,
  description: `Во время сбора урожая, когда смерть снова навещает увядающий мир, а летние цветы склоняют свои засохшие головы, жуткие пугала безмолвно маячат на пустых полях. С бесконечным терпением они стойко стерегут свои посты, несмотря на ветра, шторма и наводнения, следуя указаниям своего хозяина. Они наводят ужас на жертв своим внешним видом и раздирают их острыми как бритва когтями.

# Конструкты, движимые духом

Пугало оживает за счёт привязанного к нему духа убитого злого существа, наделяющего его стремлениями и способностью двигаться. Именно благодаря жуткому внешнему виду пугало зарождает страх в тех, на кого падает его взгляд. Карги и ведьмы часто связывают пугала с душами демонов, но подойдёт любой злой дух. Заточённый в пугале не помнит своей жизни, а его воля сосредоточена исключительно на служении создателю. Но даже при этом некоторые черты характера могут просачиваться наружу. В случае смерти создателя дух, заточённый в пугале, продолжает следовать последним командам создателя, пытается отомстить за его смерть или уничтожить себя.

# Природа конструкта

Пугалу не нужен воздух, еда, питьё и сон.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 246,
  },
  armor: 11,
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 11,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 13,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_UNCONSCIOUS,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_1,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного, неоживлённого пугала`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Когтем_.`,
    },
    {
      name: `Коготь`,
      description: `Если цель — существо, она должна пройти испытание Мудрости СЛ 11, иначе станет испуганной до конца следующего хода ★СУЩЕСТВО★.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      name: `Ужасающий взор`,
      description: `★СУЩЕСТВО★ нацеливается на одно существо, которое видит в пределах 30 футов от себя. Если цель видит ★СУЩЕСТВО★, она должна пройти испытание Мудрости СЛ 11, иначе станет магическим образом напуганной до конца следующего хода ★СУЩЕСТВО★. Пока цель испугана, она также парализована.`,
    },
  ],
  genderId: GENDER_MIDDLE,
}
