module.exports = [
  {
    text: `Что стало бы с этой мультивселенной, если бы гитьянки не охраняли Астральный План от иллитидской напасти? Какой стала бы реальность, если бы существа, способные управлять силой мысли, правили бы планом мыслей?`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Убивать во имя её — наше высшее служение. Умереть во имя её — наш последний акт почтения.`,
    author: `Мелда, рыцарь гитьянки`,
  },
  {
    text: `Представьте, что у Вас нет никаких понятий о семье. Вам всё время твердят, что другие расы хуже вашей и что только вера в Почитаемую Королеву хранит Вас от вероломных гитцераев и поедающих мозг иллитидов. Вы проходите годы тяжёлого труда и обучения, затем отправляетесь на опасную охоту за иллитидами. Провал — это смерть, но, если у Вас получится, Вы попадаете в Астрал, где будете приняты в общество, состоящее из подобных Вам.

Нечего сказать, Влаакит умеет воспитывать фанатиков. Но, увы, они бесполезны в начинаниях с долгосрочными последствиями.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Под властью иллитидов мы и гитьянки сражались и умирали в тысяче миров за безжалостных господ. Под властью Влаакит наши собратья сражаются и умирают в тысяче миров за безжалостную госпожу. И они называют это освобождением?`,
    author: `Адака Беспощадная Ладонь, гитцерай монах`,
  },
  {
    text: `Серебряный меч в движении — льющаяся субстанция, настолько же оружие разума, насколько кусок металла в руке.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `Я — проявление её воли.

Я — её обнажённый меч.

Я — повелитель драконов.

Я — участь всех миров.

Я — рыцарь Влаакит.

Да правит она вечно.`,
    author: `Боевой гимн рыцарей гитьянок`,
  },
  {
    text: `Мы восславили Пелора, когда поняли, что фигуры в небе над нами не были драконами. Мы прокляли его, когда поняли, что это воздушные корабли гитьянок.`,
    author: `Лорд Кендрек Тороден, Маршал Восточного Предела`,
  },
  {
    text: `Они называют его городом смерти. Я бы высмеял такое избитое название, но не буду. Если оно подходит, зачем спорить?`,
    author: `Гимбл, гном бард`,
  },
  {
    text: `Я был в Ту’нарате. О нём говорят, что это рай для гитьянки, но это не так. Их апатия и чувство разочарования проявляется в видимом тумане, который проясняется, только когда гитьянки готовятся к войне.`,
    author: `Архимаг Морденкайнен`,
  },
  {
    text: `«Влаакит’ка свим храт краш’хт». Лишь во Влаакит обретаем мы свет. Это были первые слова, которые я прочла на скрижали тир’су. И это не афоризм. Это закон, наше кредо — тот корень, из которого вырастают все десять тысяч статей устава Влаакит. «Пренебреги одной лишь статьёй — и ты предашь Влаакит. Предай Влаакит — и ты станешь кровью и мясом, что насытят её драконов».`,
    author: `Лаэзель, воительница гитьянки`,
  },
]
