const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_THOUGHTS,
  SPELL_FEAR,
  SPELL_FIREBALL,
  SPELL_INVISIBILITY,
  SPELL_MAGE_HAND,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_SHAPESHIFTER,
  CREATURE_TYPE_SLAAD,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  CREATURE_SLAAD_GRAY,
  CREATURE_SLAAD_GREEN,
} = require('./../../../../creatureIdList')
const {
  LANG_SLAAD,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_ARCANA,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_8} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {slaadDescription} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Зелёный слаад`,
  nameEn: 'Green Slaad',
  id: CREATURE_SLAAD_GREEN,
  description: [
    `Зелёные слаады удивительно интеллектуальны и обладают врождёнными способностями к колдовству. Зелёный слаад может изменить свой облик и предстать в виде гуманоида. Если он был рождён от носителя-гуманоида, то часто принимает его облик. В какой-то непредвиденный момент существования зелёный слаад магически, мгновенно и навсегда превращается в [серого слаада](CREATURE:${CREATURE_SLAAD_GRAY}). Изучение тайны этого превращения может занять года и даже десятилетия.`,
    slaadDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_SLAAD,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 265,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_ARCANA]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_SLAAD,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_8,
  featureList: [
    {
      name: `Перевёртыш`,
      description: `★СУЩЕСТВО★ может действием превратиться в гуманоида Маленького или Среднего размера, или принять свой истинный облик. Все ★его★ статистики, кроме размера, одинаковы во всех обликах. Всё несомое и носимое им снаряжение не превращается. Он принимает свой истинный облик, если умирает.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    {
      name: `Регенерация`,
      description: `★СУЩЕСТВО★ восстанавливает 10 хитов в начале своего хода, если у него есть хотя бы 1 хит.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_MAGE_HAND,
          SPELL_DETECT_MAGIC,
          SPELL_DETECT_THOUGHTS,
        ],
      },
      {
        limit: {
          count: 2,
          period: 'день',
        },
        list: [
          {
            id: SPELL_INVISIBILITY,
            comment: 'только на себя',
          },
          SPELL_FEAR,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_FIREBALL,
        ],
      },
    ],
  },
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, и две либо _Когтями_, либо _Посохом_. В качестве альтернативы, он два раза использует _Метание пламени_.`,
    },
    {
      name: `Укус`,
      comment: `только в облике слаада`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Коготь`,
      comment: `только в облике слаада`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Посох`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: `Метание пламени`,
      description: `Этот огонь воспламеняет горючие предметы, которые никто не несёт и не носит.`,
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 4,
        range: 60,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 3,
          diceType: 6,
          diceBonus: 0,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
