const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_3,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_BEHOLDER_KEEN,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_LN,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_DEEP_SPEECH,
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  beholderLikeDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_SPELL_REFLECTION,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
} = require('./../../../../proficiencyList')
const {
  CREATURE_SPECTATOR,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Наблюдатель',
  nameEn: 'Spectator',
  id: CREATURE_SPECTATOR,
  description: [
    `Наблюдатель это малый злобоглаз, который призван магическим ритуалом из другого плана существования. Компоненты этого ритуала включают четыре глаза на стебельках злобоглаза. Соответственно, у наблюдателя есть четыре глаза, расположенные парами с каждой стороны от широкого глаза в центре его четырёхфутового шаровидного тела.`,
    {
      header: 'Магический страж',
      text: `Призванный наблюдатель охраняет местность или сокровища по выбору того, кто его призвал, на протяжении 101 года, не позволяя никому, кроме призывателя, заходить в эту зону или получать доступ к сокровищам, при условии, что призыватель не приказал обратного. Если вещь похищена или уничтожена до срока, призванный наблюдатель исчезает. В любом другом случае он никогда не оставит своего поста.`,
      source: {
        id: SOURCE_MM,
        page: 162,
      },
    },
    {
      header: 'Проблески безумия',
      text: `Несмотря на то, что наблюдатель умеет говорить, он предпочитает общаться телепатически. Пока он на страже, он вежлив, свободно говорит о своих приказах и своём призывателе. Тем не менее, даже короткого разговора с наблюдателем хватит, чтобы понять причуды его личности, вызванные долгими годами изоляции. Он может придумать воображаемых врагов, говорить о себе в третьем лице или пытаться подражать голосу своего призывателя.

Как и любой злобоглаз, наблюдатель считает себя воплощением всего своего рода и люто ненавидит других наблюдателей. Если два наблюдателя сталкиваются, они почти всегда сражаются до смерти.`,
      source: {
        id: SOURCE_MM,
        page: 162,
      },
    },
    {
      header: 'Освобождение от службы',
      text: `Когда наблюдатель заканчивает свою службу, он предоставляется сам себе. Многие поселяются в тех же местах, которые охраняли, особенно если их призыватели умерли. С потерей цели, безумие наблюдателя, лишь мелькавшее во время его службы, проявляется в полной мере.`,
      source: {
        id: SOURCE_MM,
        page: 162,
      },
    },
    beholderLikeDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_BEHOLDER_KEEN,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 162,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  immunityConditionList: [
    [CONDITION_PRONE],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_3,
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 1,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: -1,
        },
      },
    },
    {
      name: 'Лучи из глаз',
      description: `★СУЩЕСТВО★ испускает один или два магических луча из глаз в одно или два существа, видимых в пределах 90 футов. Каждый луч используется только один раз в ход.

1. _Луч замешательства._ Цель должна пройти испытание Мудрости СЛ 13, иначе она не сможет использовать реакции до конца своего следующего хода. Во время своего хода цель не может перемещаться, и обязана действием совершить рукопашную или дальнобойную атаку по случайному существу в пределах досягаемости. Если цель не может атаковать, она в своём ходу ничего не делает.
2. _Парализующий луч._ Цель должна пройти испытание Телосложения СЛ 13, иначе станет парализованной на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
3. _Луч страха._ Цель должна пройти испытание Мудрости СЛ 13, иначе станет испуганной на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, с помехой, если ★СУЩЕСТВО★ ей ★виден★, оканчивая эффект на себе при успехе.
4. _Ранящий луч._ Цель должна пройти испытание Телосложения СЛ 13, получая урон некротической энергией 16 (3к10) при провале, или половину этого урона при успехе.`,
    },
    {
      name: 'Создание еды и воды',
      description: `★СУЩЕСТВО★ магическим образом создаёт достаточное количество пищи и воды, чтобы поддерживать себя в течение 24 часов.`,
    },
  ],
  reactionList: [
    {
      id: ABILITY_SPELL_REFLECTION,
      range: 30
    },
  ],
  genderId: GENDER_MALE,
}
