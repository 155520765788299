const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {CREATURE_THRI_KREEN} = require('./../../../creatureIdList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_GYTHKA} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_MM} = require('./../../../sourceList')
const {WEAPON_TWO_HANDED} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_GYTHKA,
  genderId: GENDER_FEMALE,
  name: 'Гитка',
  nameByCase: {
    nominative: 'гитка',
    genitive: 'гитки',
    accusative: 'гитку',
    instrumental: 'гиткой',
  },
  nameEn: 'Gythka',
  description: `Гитка это особое двуручное древковое оружие [три-кринов](CREATURE:${CREATURE_THRI_KREEN}) с клинками на каждом конце.`,
  damage: {
    diceCount: 1,
    diceType: 8,
  },
  damageType: DAMAGE_SLASHING,
  source: {
    id: SOURCE_MM,
    page: 284,
  },
  weaponPropList: [
    WEAPON_TWO_HANDED,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
