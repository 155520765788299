const {
  SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEAST,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  CREATURE_AIR_BEAST_SPIRIT,
  CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  SPELL_SUMMON_BESTIAL_SPIRIT_2020_03_26,
} = require('./../../../../spellIdList')
const {
  ABILITY_FLYBY,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Дух воздушного зверя',
  nameEn: 'Air Bestial Spirit',
  id: CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26,
  releasedAs: CREATURE_AIR_BEAST_SPIRIT,
  createdBySpellId: SPELL_SUMMON_BESTIAL_SPIRIT_2020_03_26,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
    page: 4,
  },
  armor: {
    ac: 11,
    type: ARMOR_TYPE_NATURAL,
    comment: `+ круг заклинания`,
  },
  hp: `модификатор Телосложения духа + модификатор заклинательной характеристики заклинателя + десятикратный круг заклинания`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 16,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 5,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  featureList: [
    ABILITY_FLYBY,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания`,
    },
    {
      name: 'Пасть',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: {
          value: 4,
          comment: `+ круг заклинания`,
        },
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 4,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
