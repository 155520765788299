const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  GEAR_FLASK,
} = require('./../../../../../gearIdList')
const {
  SPELL_IDENTIFY,
} = require('./../../../../../spellIdList')
const {
  CREATURE_ARCANALOTH,
  CREATURE_CAMBION,
  CREATURE_DAO,
  CREATURE_DEVA,
  CREATURE_DJINNI,
  CREATURE_EFREETI,
  CREATURE_GITHYANKI_KNIGHT,
  CREATURE_GITHZERAI_ZERTH,
  CREATURE_INCUBUS,
  CREATURE_INVISIBLE_STALKER,
  CREATURE_MARID,
  CREATURE_MEZZOLOTH,
  CREATURE_NIGHT_HAG,
  CREATURE_NYCALOTH,
  CREATURE_PLANETAR,
  CREATURE_SALAMANDER,
  CREATURE_SOLAR,
  CREATURE_SUCCUBUS,
  CREATURE_ULTROLOTH,
  CREATURE_XORN,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_IRON_FLASK,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_IRON_FLASK,
  name: `Железная фляга`,
  nameEn: `Iron Flask`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_LEGENDARY,
  gearType: GEAR_FLASK,
  description: `У этой железной бутылки латунная пробка. Вы можете действием произнести командное слово фляги и выбрать целью существо, которое видите в пределах 60 футов от себя. Если цель родом не с того плана существования, на котором Вы находитесь, она должна пройти испытание Мудрости СЛ 17, иначе будет заточена во фляге. Если цель раньше уже была заточена такой флягой, испытание она проходит с преимуществом. Заточённое существо остаётся во фляге, пока не будет выпущено. Во фляге может одновременно находиться только одно существо. Заточённое существо не обязано ни дышать, ни есть, ни спать, и оно не стареет.

Вы можете действием вынуть пробку фляги и выпустить существо, находящееся в ней. Оно будет дружественно относиться к Вам и ВАШИМ спутникам в течение 1 часа и повинуется Вашим командам в течение этого времени. Если Вы не отдаёте ему команд или отдаёте команду, которая весьма вероятно закончится его смертью, оно будет обороняться, но не будет совершать других действий. В конце этого периода существо действует согласно своему обычному характеру и мировоззрению.

Заклинание [Опознание](SPELL:${SPELL_IDENTIFY}) показывает, находится ли во фляге существо, но единственный способ определить вид существа это вскрытие фляги. В найденной фляге уже может находиться существо, определённое Мастером самостоятельно или случайным образом.

| к100  | Содержимое       | к100  | Содержимое |
|-------|------------------|-------|------------|
| 01–50 | Пустая           | 77–78 | Элементаль (любой) |
| 51    | [Арканалот](CREATURE:${CREATURE_ARCANALOTH})        | 79    | [Гитьянки рыцарь](CREATURE:${CREATURE_GITHYANKI_KNIGHT}) |
| 52    | [Камбион](CREATURE:${CREATURE_CAMBION})          | 80    | [Гитцерай зерт](CREATURE:${CREATURE_GITHZERAI_ZERTH}) |
| 53–54 | [Дао](CREATURE:${CREATURE_DAO}) | 81–82 | [Невидимый охотник](CREATURE:${CREATURE_INVISIBLE_STALKER}) |
| 55–57 | Демон (вид 1)    | 83–84 | [Марид](CREATURE:${CREATURE_MARID}) |
| 58–60 | Демон (вид 2)    | 85–86 | [Меззолот](CREATURE:${CREATURE_MEZZOLOTH}) |
| 61–62 | Демон (вид 3)    | 87–88 | [Ночная карга](CREATURE:${CREATURE_NIGHT_HAG}) |
| 63–64 | Демон (вид 4)    | 89–90 | [Никалот](CREATURE:${CREATURE_NYCALOTH}) |
| 65    | Демон (вид 5)    | 91    | [Планетар](CREATURE:${CREATURE_PLANETAR}) |
| 66    | Демон (вид 6)    | 92–93 | [Саламандра](CREATURE:${CREATURE_SALAMANDER}) |
| 67    | [Дэв](CREATURE:${CREATURE_DEVA})              | 94–95 | Слаад (любой) |
| 68–69 | Дьявол (старший) | 96    | [Солар](CREATURE:${CREATURE_SOLAR}) |
| 70–72 | Дьявол (младший) | 97–98 | [Суккуб](CREATURE:${CREATURE_SUCCUBUS})/[Инкуб](CREATURE:${CREATURE_INCUBUS}) |
| 73–74 | [Джинн](CREATURE:${CREATURE_DJINNI})            | 99    | [Ультролот](CREATURE:${CREATURE_ULTROLOTH}) |
| 75–76 | [Ифрит](CREATURE:${CREATURE_EFREETI})            | 00    | [Зорн](CREATURE:${CREATURE_XORN})  |
`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 162,
  },
}
