const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {
  WEAPON_HEAVY,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const swordDescription = require('./../../constants/swordDescription')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_GREATSWORD} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_GREATSWORD,
  genderId: GENDER_MALE,
  name: 'Двуручный меч',
  nameByCase: {
    nominative: 'двуручный меч',
    genitive: 'двуручного меча',
    accusative: 'двуручный меч',
    instrumental: 'двуручным мечом',
  },
  nameEn: 'Greatsword',
  description: [
    {
      header: 'Двуручный меч',
      text: `Двуручный меч — это мощное оружие, требующее огромной силы и умения для эффективного использования. Любой неподготовленный герой может ранить себя больше, чем противника, если попытается его использовать!

Будучи двуручным оружием, мечи могут наносить мощные удары, убивая небольших существ с одного попадания. Из-за своих размеров и цены, двуручные мечи ассоциируются с паладинами, воинами и варварами, хотя любой обученный боец с достаточной силой может использовать такой меч, получив мощного союзника в бою!`,
      source: {
        id: SOURCE_AYAGWnW,
        page: 106,
      },
    },
    swordDescription,
  ],
  damage: {
    diceType: 6,
    diceCount: 2,
  },
  cost: 5000,
  damageType: DAMAGE_SLASHING,
  weight: 6,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    WEAPON_TWO_HANDED,
    WEAPON_HEAVY,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
