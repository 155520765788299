module.exports = [
  ...require('./_gearRawList_g'),
  require('./glaive'),
  require('./greataxe'),
  require('./greatsword'),
  require('./grenade_fragmentation'),
  require('./grenade_launcher'),
  require('./grenade_smoke'),
  require('./gunpowder_keg'),
  require('./gunpowder_powder_horn'),
  require('./gythka'),
]
