const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {
  CREATURE_BUGBEAR,
  CREATURE_BUGBEAR_CHIEF,
  CREATURE_GOBLIN_BOSS,
  CREATURE_HOBGOBLIN,
  CREATURE_HOBGOBLIN_WARLORD,
  CREATURE_RAT,
  CREATURE_WOLF,
} = require('./../../creatureIdList')
const maglubiyetGoblinDescription = require('./maglubiyetGoblin')
const goblinoidManual = require('./goblinoidManual')

module.exports = [
  {
    header: 'Гоблины',
    text: `Гоблины — это маленькие, эгоистичные существа с чёрными сердцами, живущие в пещерах, брошенных рудниках, разграбленных подземельях и прочих неприглядных местах. Слабые по отдельности, гоблины собираются в большие — зачастую несметные — группы. Они жадны до власти и часто злоупотребляют теми полномочиями, которые у них уже есть.`,
    source: {
      id: SOURCE_MM,
      page: 63,
    },
  },
  {
    header: 'Гоблины',
    text: `Слабые, думающие только о себе гуманоиды. Хотя их зачастую можно видеть в услужении более могущественным расам, на самом деле гоблины обожают господствовать над другими — и горе тем, кто оказался в их власти.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Злорадство',
    text: `Ведомые жадностью и злобой, гоблины не способны на помощь, но всегда рады нечастым моментам своего триумфа. Они прыгают и пляшут, радуясь, когда победа остаётся за ними. А когда заканчивается празднество, они упиваются пытками других существ и прочими пакостями.`,
    source: {
      id: SOURCE_MM,
      page: 63,
    },
  },
  {
    header: 'Гоблиноиды',
    text: `Гоблины принадлежат к семейству существ, называемых гоблиноидами. Их большие родственники, [хобгоблины](CREATURE:${CREATURE_HOBGOBLIN}) и [медвежатники](CREATURE:${CREATURE_BUGBEAR}), любят подчинять себе гоблинов путём запугивания. Гоблины ленивые и расхлябанные, что делает их плохими слугами, рабочими или охраной.`,
    source: {
      id: SOURCE_MM,
      page: 63,
    },
  },
  {
    header: 'Лидеры и последователи',
    text: `Гоблинами правят самые сильные и умные среди них. [Гоблинский босс](CREATURE:${CREATURE_GOBLIN_BOSS}) может командовать отдельным логовом, тогда как гоблинский король или королева (которые являются не более чем теми же прославленными гоблинскими боссами) управляет сотнями гоблинов, живущих во множестве логовищ, что гарантирует выживание племени. Гоблинские боссы часто меняются, а племена захватываются [хобгоблинскими воеводами](CREATURE:${CREATURE_HOBGOBLIN_WARLORD}) или [вождями медвежатников](CREATURE:${CREATURE_BUGBEAR_CHIEF}).`,
    source: {
      id: SOURCE_MM,
      page: 63,
    },
  },
  {
    header: 'Замысловатые логова',
    text: `Гоблины увешивают свои логова сигнализацией, которая оповещает о вторжении. Их логова пронизаны запутанными узкими туннелями и норами, через которые человек не сможет пролезть. Гоблины же могут с их помощью сбежать или неожиданно обойти противника с тыла.`,
    source: {
      id: SOURCE_MM,
      page: 63,
    },
  },
  {
    header: 'Крысоводы и наездники на волках',
    text: `Гоблинов тянет к [крысам](CREATURE:${CREATURE_RAT}) и [волкам](CREATURE:${CREATURE_WOLF}), которых они взращивают своими спутниками и верховыми животными, соответственно. Подобно крысам, гоблины избегают солнечного света и днём спят под землёй. Как волки, они стайные охотники, которые становятся тем смелее, чем больше их число. Охотясь на спинах волков, они изматывают врагов, часто нападая и тут же отступая.`,
    source: {
      id: SOURCE_MM,
      page: 63,
    },
  },
  maglubiyetGoblinDescription,
  goblinoidManual,
]
