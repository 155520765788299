const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PC_RACE_DWARF,
  PC_RACE_ORC,
} = require('./../../../../pcRaceIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_RUST_MONSTER} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GEAR_SHIELD} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Ржавник',
  nameEn: 'Rust Monster',
  id: CREATURE_RUST_MONSTER,
  description: `Многие [дварфы](PC_RACE:${PC_RACE_DWARF}) предпочтут встретить отряд [орков](PC_RACE:${PC_RACE_ORC}), чем противостоять одному ржавнику. Это странное, обычно кроткое существо, подвергает коррозии содержащие железо металлы, а затем пожирает образовавшуюся ржавчину. Так оно разрушило доспехи, щиты и оружие бесчисленного количества искателей приключений.

Тело ржавника покрыто толстым слоем бугристых пластин, длинный хвост заканчивается костяным выступом, а из насекомообразной головы растут два мохнатых уса.

# Подземные мусорщики

Ржавники бродят по подземным проходам в поисках металлов для пищи, таких как железо, сталь, адамантин и мифрил. Они безразличны к существам, не имеющих металлы, но проявляют агрессию к владельцам стальных доспехов и оружия. Ржавник чует свою пищу на расстоянии, сразу же устремляясь к источнику запаха, чтобы покрыть его ржавчиной и поглотить.

Ржавнику нет разницы, с чего пожирать ржавчину, с гвоздя или меча. Искатели приключений могут отвлечь его, бросая вещи из содержащих железо металлов позади себя.

# Подземные скитальцы

Ржавники редко встречаются в больших количествах, предпочитая охотиться поодиночке или малыми группами. Они плутают вдоль туннелей, от пещеры к пещере, безустанно ища чёрные металлы себе в пищу. Их скитания часто приводят их к другим обитателям Подземья, которые находят их безвредными или не аппетитными. Таким образом, их можно обнаружить рядом с другими подземными чудовищами. Если за ними хорошо ухаживать и кормить, они также могут стать дружественными спутниками или питомцами.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 251,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 13,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 6,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: `Нюх на металл`,
      description: `★СУЩЕСТВО★ может определить по запаху местонахождение содержащего железо металла в пределах 30 футов.`,
    },
    {
      name: `Коррозия металла`,
      description: `Любое немагическое оружие, изготовленное из металла, попадающее по ★СУЩЕСТВО★, начинает разъедаться. После причинения урона оружие получает постоянный накопительный штраф −1 к броскам урона. Если этот штраф дойдёт до −5, оружие будет уничтожено. Немагические боеприпасы, изготовленные из металла, попадающие по ржавнику, уничтожаются после причинения урона.`,
    },
  ],
  actionList: [
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 1,
        },
      },
    },
    {
      name: `Антенны`,
      description: `★СУЩЕСТВО★ разъедает немагический железосодержащий металлический предмет, видимый в пределах 5 футов. Если предмет никто не несёт и не носит, это касание уничтожает 1 кубический фут его.

Если этот предмет несёт или носит другое существо, оно может пройти испытание Ловкости СЛ 11, чтобы избежать прикосновения ★СУЩЕСТВО★.

Если предмет — носимый или несомый металлический доспех или [щит](GEAR:${GEAR_SHIELD}), он получает постоянный накопительный штраф −1 к КД, который он предлагает. Доспех уничтожается, если штраф уменьшает его КД до 10. Щит уничтожается, если штраф уменьшает его бонус до +0. Если предмет — металлическое оружие, оно ржавеет, как описано в особенности _Коррозия металла_.`,
    },
  ],
  genderId: GENDER_MALE,
}
