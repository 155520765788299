const {
  SPELL_ANIMATE_DEAD,
  SPELL_BLIGHT,
  SPELL_CHILL_TOUCH,
  SPELL_CIRCLE_OF_DEATH,
  SPELL_CREATE_UNDEAD,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_FINGER_OF_DEATH,
  SPELL_POWER_WORD_KILL,
  SPELL_SPEAK_WITH_DEAD,
  SPELL_TIME_STOP,
} = require('./../../../../spellIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  CREATURE_CULTIST,
  CREATURE_GHOUL,
  CREATURE_NECROMANCER,
  CREATURE_ORCUS,
  CREATURE_SKELETON,
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_DEMON_LORD,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SKILL_ARCANA,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_26} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Оркус',
  nameAlt: [
    'Повелитель Нежити',
    'Кровавый Лорд',
  ],
  nameEn: 'Orcus',
  id: CREATURE_ORCUS,
  hasSpoilers: true,
  description: [
    `Оркус — Демонический Принц Нежити, известный как Кровавый Лорд. Он получает некоторое удовольствие от страданий живых, но гораздо больше предпочитает компанию и услуги нежити. Он желает узреть, как вся жизнь угаснет, а мультивселенная обратится в необъятный некрополь, населенный нежитью, которой он будет править. Оркус награждает тех, кто несёт смерть во славу его, и дарит им крупицы своей мощи. Слабейшие из становятся [упырями](CREATURE:${CREATURE_GHOUL}) и [зомби](CREATURE:${CREATURE_ZOMBIE}), служащими в его легионе, но излюбленными слугами являются [культисты](CREATURE:${CREATURE_CULTIST}) и [некроманты](CREATURE:${CREATURE_NECROMANCER}), убивающие живых и властвующие над трупами, подражая своему грозному повелителю.

Оркус — это зверское существо, источающее скверну, с больной, разлагающейся внешностью. Нижняя часть его тела козлиная, а верхняя часть тела с животом, опухшим от гнили, напоминает человеческую. Из его плеч прорастают большие крылья летучей мыши, а на голове, напоминающей череп козла, плоть настолько сгнила, что её почти не осталось. В одной из рук он держит легендарный [Жезл Оркуса](/campaigns/oota).`,
    {
      header: 'Логово Оркуса',
      text: `Логово Оркуса расположено в городе-крепости Наратире, что находится на Танатосе — слое Бездны, которым он правит, в окружении рва, питаемого рекой Стикс. Наратир — устрашающе тихий и холодный город, его улицы часто пустуют по несколько часов к ряду. В центральном  костяном замке стены сделаны из плоти, а ковры сплетены из волос. В городе живет блуждающая нежить, и многие из ее представителей заняты постоянными битвами друг с другом.

# Действия логова

По очереди инициативы 20 (при равной инициативе персонаж совершает действие прежде действия логова), Оркус может совершить действие в логове, чтобы создать один из следующих магических эффектов; он не может использовать один и тот же эффект два раунда подряд:

* Голос Оркуса громом проносится по логову. Из-за произнесенных им слов одно из существ по его выбору становится целью заклинания [Слово силы: смерть](SPELL:${SPELL_POWER_WORD_KILL}). Оркусу нет нужды видеть существо, но он должен знать о том, что оно находится в логове.
* Оркус заставляет шесть трупов, находящихся в логове, восстать в виде [скелетов](CREATURE:${CREATURE_SKELETON}), [зомби](CREATURE:${CREATURE_ZOMBIE}) и [упырей](CREATURE:${CREATURE_GHOUL}). Эта нежить подчиняется его телепатическим приказам, которые преодолевают любое расстояние на территории логова.
* Оркус заставляет костяные руки подняться из земли в квадратной области со стороной 20 футов в длину, которую он может видеть. Они пропадают в момент следующей очереди инициативы 20. Каждое существо, находящееся в этой области в тот момент, когда появляются руки, должно пройти испытание Силы СЛ 23 или будет удерживаемым до тех пор, пока руки не исчезнут, или пока Оркус не отпустит их жертву (действия не требуется).

# Местные эффекты

Область, в которой расположено логово Оркуса пронизана его магией, создающей в ней один или более из следующих эффектов:

* Мёртвые звери периодически восстают в виде нежити, похожей на извращенные образы того, как они выглядели при жизни.
* Воздух наполняется вонью разлагающейся плоти, и в этой области нарастает жужжащий гул мух, даже если поблизости не видно ни одного насекомого.
* Если гуманоид проводит по крайней мере 1 час на расстоянии 1 мили от логова, то это существо должно пройти испытание Мудрости СЛ 23 или станет жертвой безумия, определяемого по таблице Безумия Оркуса. Существо, успешно прошедшее испытание, не может стать жертвой этого регионального эффекта в течение следующих 24 часов.

Если Оркус умирает, эти эффекты прекращаются в течение 1к10 дней.`,
      source: {
        id: SOURCE_MTOF,
        page: 153,
      },
    },
    {
      header: 'Безумие Оркуса',
      text: `Если существо становится безумным в логове Оркуса или в поле зрения лорда демонов, совершите бросок по таблице Безумия Оркуса, чтобы определить природу безумия, которое будет слабостью персонажа и будет действовать до тех пор, пока не будет излечено.

| к100  | Слабость (длится пока не будет вылечена) |
|-------|------------------------------------------|
| 01-20 | _«Я часто бываю задумчивым и мрачным, размышляя о невыносимости жизни»_ |
| 21-40 | _«Я вынужден заставлять слабых страдать»_ |
| 41-60 | _«Меня не терзают угрызения совести, когда я беспокою мертвых, пытаясь лучше понять смерть»_ |
| 61-80 | _«Я стремлюсь к тому, чтобы обрести вечное существование, став нежитью»_ |
| 81-00 | _«Я тону в осознании бессмысленности жизни»_ |
`,
      source: {
        id: SOURCE_MTOF,
        page: 154,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_DEMON_LORD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 154,
  },
  armor: [
    {
      ac: 17,
      type: ARMOR_TYPE_NATURAL,
    },
    {
      ac: 20,
      comment: `с _[ Жезлом Оркуса](/campaigns/oota)_`,
    },
  ],
  hp: {
    diceCount: 30,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 27,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 25,
    [PARAM_INT]: 20,
    [PARAM_WIT]: 20,
    [PARAM_CHA]: 25,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_NECROTIC,
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_26,
  featureList: [
    {
      name: 'Жезл Оркуса',
      description: `У жезла 7 зарядов, и любое из его свойств, требующее испытания, имеет СЛ 18.

Держа его, Оркус может потратить действие для того, чтобы прочитать заклинания:

* [Восставший труп](SPELL:${SPELL_ANIMATE_DEAD}),
* [Усыхание](SPELL:${SPELL_BLIGHT})
* [Разговор с мёртвыми](SPELL:${SPELL_SPEAK_WITH_DEAD}).

В качестве альтернативы этим заклинаниям он может потратить 1 или более зарядов жезла, чтобы прочитать с его помощью одно из его заклинаний:

* [Круг смерти](SPELL:${SPELL_CIRCLE_OF_DEATH}) (1 заряд),
* [Перст смерти](SPELL:${SPELL_FINGER_OF_DEATH}) (1 заряд),
* [Слово силы: смерть](SPELL:${SPELL_POWER_WORD_KILL}) (2 заряда).

Жезл восстанавливает 1к4 + 3 заряда ежедневно на рассвете.

Держа жезл, Оркус может действием [призвать нежить](/campaigns/oota), чьи хиты в сумме не превышают 500. Эта нежить магически поднимается из земли или каким-либо другим образом возникает в незанятом месте в пределах 300 футов от Оркуса и подчиняется его приказам до тех пор, пока не будет уничтожена или до тех пор, пока он не отзовет её действием. Как только это свойство жезла будет использовано, его нельзя будет использовать снова до следующего рассвета.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Повелитель нежити',
      description: `Когда Оркус читает заклинание [Восставший труп](SPELL:${SPELL_ANIMATE_DEAD}) или [Сотворение нежити](SPELL:${SPELL_CREATE_UNDEAD}), он выбирает круг, из ячейки которого заклинание будет считаться сотворённым, и созданные им существа будут оставаться под его контролем неограниченное время. В дополнение к этому он может читать заклинание [Сотворение нежити](SPELL:${SPELL_CREATE_UNDEAD}) даже не ночью.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
          {
            id: SPELL_CHILL_TOUCH,
            comment: '17-й уровень',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_CREATE_UNDEAD,
          SPELL_DISPEL_MAGIC,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_TIME_STOP,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Оркус совершает две атаки _Жезлом Оркуса_.`,
    },
    {
      name: 'Жезл Оркуса',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 19,
        range: 10,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 3,
            diceType: 8,
            diceBonus: 8,
          },
          {
            type: DAMAGE_NECROTIC,
            diceCount: 2,
            diceType: 12,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 10,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 3,
            diceType: 8,
            diceBonus: 8,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 4,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Хвост',
      description: `Оркус совершает одну атаку хвостом.`,
    },
    {
      name: 'Вкус мертвой жизни',
      description: `Оркус сотворяет заклинание [Ледяное прикосновение](SPELL:${SPELL_CHILL_TOUCH}) (17-й уровень).`,
    },
    {
      name: 'Крадущаяся смерть',
      cost: 2,
      description: `Оркус выбирает точку на земле, которую может видеть на расстоянии до 100 футов от него. Цилиндрический вихрь некротической энергии высотой 60 футов и радиусом 10 футов поднимается из этой точки и остается до конца следующего хода Оркуса. Существа, находящиеся в этой зоне, уязвимы к некротическому урону.`,
    },
  ],
}
