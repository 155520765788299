const {
    SOURCE_ERFTLW,
    SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
  } = require('./../../sourceList'),
  {
    GENDER_FEMALE,
  } = require('./../../genderList'),
  {
    CREATURE_BLACK_PUDDING,
  } = require('./../../creatureIdList'),
  {
    GEAR_LANTERN_BULLSEYE,
    GEAR_SPELLBOOK,
    GEAR_THIEVES_TOOLS,
    GEAR_WAND,
    GEAR_WHIP,
  } = require('./../../gearIdList'),
  {
    SPELL_ALARM,
    SPELL_ANTIMAGIC_FIELD,
    SPELL_ARCANE_LOCK,
    SPELL_CHARM_PERSON,
    SPELL_CLOUDKILL,
    SPELL_CONTINUAL_FLAME,
    SPELL_DETECT_EVIL_AND_GOOD,
    SPELL_DETECT_MAGIC,
    SPELL_DETECT_THOUGHTS,
    SPELL_DISINTEGRATE,
    SPELL_DISSONANT_WHISPERS,
    SPELL_FEAR,
    SPELL_FINGER_OF_DEATH,
    SPELL_FLESH_TO_STONE,
    SPELL_GLYPH_OF_WARDING,
    SPELL_GUST_OF_WIND,
    SPELL_HOLD_PERSON,
    SPELL_LOCATE_CREATURE,
    SPELL_RAY_OF_ENFEEBLEMENT,
    SPELL_SENDING,
    SPELL_SLEEP,
    SPELL_SLOW,
    SPELL_STINKING_CLOUD,
    SPELL_TELEKINESIS,
  } = require('./../../spellIdList'),
  {
    MGC_TYPE_WEAPON,
    MGC_TYPE_WONDROUS_ITEM,
  } = require('./../../magicItemTypeList'),
  {
    MGC_RARITY_COMMON,
    MGC_RARITY_UNCOMMON,
    MGC_RARITY_RARE,
    MGC_RARITY_VERY_RARE,
    MGC_RARITY_LEGENDARY,
  } = require('./../../magicItemRarityList'),
  {
    MAGIC_ITEM_ARCANE_PROPULSION_ARM,
    MAGIC_ITEM_ARM_BLADE,
    MAGIC_ITEM_BELASHYRRA_S_BEHOLDER_CROWN,
    MAGIC_ITEM_CLEANSING_STONE,
    MAGIC_ITEM_DOCENT,
    MAGIC_ITEM_DYRRN_S_TENTACLE_WHIP,
    MAGIC_ITEM_EARWORM,
    MAGIC_ITEM_EVERBRIGHT_LANTERN,
    MAGIC_ITEM_FEATHER_TOKEN,
    MAGIC_ITEM_FINDER_S_GOGGLES,
    MAGIC_ITEM_GLAMERWEAVE_COMMON,
    MAGIC_ITEM_GLAMERWEAVE_UNCOMMON,
    MAGIC_ITEM_KEYCHARM,
    MAGIC_ITEM_KYRZIN_S_OOZE,
    MAGIC_ITEM_LIVING_GLOVES,
    MAGIC_ITEM_PROSTHETIC_LIMB,
    MAGIC_ITEM_SCRIBE_S_PEN,
    MAGIC_ITEM_SHIFTWEAVE,
    MAGIC_ITEM_SPEAKING_STONE,
    MAGIC_ITEM_SPELLSHARD,
    MAGIC_ITEM_VENTILATING_LUNGS,
    MAGIC_ITEM_WAND_SHEATH,
    MAGIC_ITEM_WHEEL_OF_WIND_AND_WATER,
  } = require('./../../magicItemIdList'),
  imbuedWoodFocus = require('./../itemGroups/imbuedWoodFocus'),
  livingArmor = require('./../itemGroups/livingArmor'),
  orbOfShielding = require('./../itemGroups/orbOfShielding')

module.exports = [
  {
    id: MAGIC_ITEM_ARCANE_PROPULSION_ARM,
    name: `Волшебная отстреливающаяся рука`,
    nameEn: `Arcane Propulsion Arm`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    attunementComment: `существом, потерявшим руку или ладонь`,
    description: `Этот протез руки был разработан изобретателями Дома Каннит. Для настройки Вы должны прикрепить его к руке на запястье, локте или плече, и протез магически преобразуется в копию заменямой конечности.

Прикреплённый протез даёт Вам следующие преимущества

* Протез — это полноценная часть Вашего тела.
* Вы можете действием открепить протез. Он сам открепляется, если Ваша настройка на него заканчивается. Его нельзя открепить против Вашей воли.
* Протез — это магическое рукопашное оружие, которым Вы владеете. Он наносит 1к8 урона силовым полем при попадании и имеет свойство «Метательное», с нормальной дальностью 20 футов и максимальной — 60 футов. При атаке протез отстреливается и летит в цель, затем немедленно возвращается к Вам и прикрепляется обратно.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_ERFTLW,
      page: 277,
    },
  },
  {
    id: MAGIC_ITEM_ARM_BLADE,
    name: `Рука-клинок`,
    nameEn: `Arm Blade`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любое одноручное оружие`,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    attunementComment: `кованым`,
    description: `Рука-клинок — это магическое оружие, которое прикрепляется к Вашей руке и становится неотделимо от Вас, пока Вы на него настроены. Чтобы настроиться на _Руку-клинок_, Вы должны прижимать её к предплечью всё время настройки.

Бонусным действием Вы можете выдвигать _Руку-клинок_ из предплечья или втягивать её обратно. Пока она выдвинута, Вы можете использовать это оружие, как если бы Вы держали его в руке, и не можете использовать эту руку для чего-то ещё.`,
    genderId: GENDER_FEMALE,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 277,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 3,
      },
    ],
  },
  {
    id: MAGIC_ITEM_BELASHYRRA_S_BEHOLDER_CROWN,
    name: `Корона бехолдеров Белаширры`,
    nameEn: `Belashyrra’s Beholder Crown`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: [
      `Эта симбиотическая корона вырезана из тёмно-фиолетового с розовато-лиловыми прожилкам камня. Её десять шипов вырезаны в виде глазные стеблей бехолдера и украшены драгоценными камнями.

Для настройки на _Корону бехолдеров Белаширры_, Вы должны носить её на голове в течение всего периода настройки, пока скрытые усики короны врастают в кожу Вашей головы, соединяясь с черепом.

Нося _Корону бехолдеров Белаширры_, Вы можете нормально видеть в темноте, как магической, так и немагической, на 120 футов.

Дэлькир Белаширра создала эти короны. Находясь на том же плане существования, что и _Корона_, Белаширра может видеть через её глазные стебли.`,
      {
        header: 'Заклинания',
        text: `У _Короны_ есть 10 зарядов. Нося её, Вы можете действием потратить 1 или несколько зарядов, чтобы наложить ей одно из следующих заклинаний (СЛ испытания 16).

* [Замедление](SPELL:${SPELL_SLOW}) (3 заряда),
* [Луч слабости](SPELL:${SPELL_RAY_OF_ENFEEBLEMENT}) (2 заряда),
* [Окаменение](SPELL:${SPELL_FLESH_TO_STONE}) (6 зарядов),
* [Очарование личности](SPELL:${SPELL_CHARM_PERSON}) (1 заряд),
* [Перст смерти](SPELL:${SPELL_FINGER_OF_DEATH}) (7 зарядов),
* [Распад](SPELL:${SPELL_DISINTEGRATE}) (6 зарядов),
* [Телекинез](SPELL:${SPELL_TELEKINESIS}) (5 зарядов),
* [Удержание личности](SPELL:${SPELL_HOLD_PERSON}) (2 заряда),
* [Ужас](SPELL:${SPELL_FEAR}) (3 заряда),
* [Усыпление](SPELL:${SPELL_SLEEP}) (1 заряд).

Корона восстанавливает 1к6 + 3 заряда на рассвете.`,
      }
    ],
    symbiotic: `_Корона_ не может быть снята, пока Вы на неё настроены и Вы не можете добровольно прекратить настройку на неё.

Если Вы становитесь целью заклинания, которое снимает проклятье, Ваша настройка на _Корону_ оканчивается и становится возможным снять её с Вас.`,
    genderId: GENDER_FEMALE,
    spellIdList: [
      SPELL_CHARM_PERSON,
      SPELL_DISINTEGRATE,
      SPELL_FEAR,
      SPELL_FINGER_OF_DEATH,
      SPELL_FLESH_TO_STONE,
      SPELL_HOLD_PERSON,
      SPELL_RAY_OF_ENFEEBLEMENT,
      SPELL_SLEEP,
      SPELL_SLOW,
      SPELL_TELEKINESIS,
    ],
    source: {
      id: SOURCE_ERFTLW,
      page: 277,
    },
  },
  {
    id: MAGIC_ITEM_CLEANSING_STONE,
    name: `Очищающий камень`,
    nameEn: `Cleansing Stone`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Очищающий камень — это сфера диаметром 1 фут, на которой выгравированы мистические знаки. Прикоснувшись к камню, Вы можете действием активировать его и счистить грязь и сажу с себя и своей одежды.

Такие камни часто встраивают в пьедесталы на публичных площадях Аундара и в элитных гостиницах Галланда.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 277,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 2,
      },
    ],
  },
  {
    id: MAGIC_ITEM_DOCENT,
    name: `Доцент`,
    nameEn: `Docent`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    isSentient: true,
    attunement: true,
    attunementComment: `кованым`,
    description: `_Доцент_ — это небольшая металлическая сфера, около 2 дюймов в диаметре, усыпанная драконьими осколками.

Для настройки на _Доцента_, Вы должны прикрепить его куда-нибудь на своём теле, например, на грудь или в глазницу.

# Разум

_Доцент_ — это разумный предмет любого мировоззрения с Интеллектом 16, Мудростью 14 и Харизмой 14. Он воспринимает мир через Ваши чувства. Он телепатически общается с Вами и может говорить, читать и понимать любой язык, который знает.

# Поддержание жизни

Каждый раз, когда Вы заканчиваете свой ход с 0 хитов, _Доцент_ может совершить проверку Мудрости (Медицина) с бонусом +6. При успехе _Доцент_ Вас стабилизирует.

# Случайные свойства

_Доцент_ имеет следующие свойства

## Языки

_Доцент_ владеет Общим, Великаньим и 1к4 дополнительными языками, выбранными Мастером. Если _Доцент_ знает менее шести языков, он может выучить новый язык после того, как услышит или прочитает его через Ваши органы чувств.

## Навыки и умения

_Доцент_ имеет бонус +7 к одному из следующих навыков (бросьте к4):

1. Анализ
2. История
3. Магия
4. Природа

## Заклинания

_Доцент_ знает одно из следующих заклинаний и может сотворять его по желанию, без компонентов (бросьте к6):

* (1-2) [Обнаружение добра и зла](SPELL:${SPELL_DETECT_EVIL_AND_GOOD})
* (3-6) [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC})

_Доцент_ сам решает, когда сотворять заклинание.

# Личность

_Доцент_ предназначен для того, чтобы давать советы и помогать кованым, к которым он прикреплён. Одна из основных функций Доцент_а_ — быть переводчиком. Способности _Доцента_ находятся под его собственным контролем, и если у Вас плохие отношения с _Доцентом_, он может отказать Вам в помощи.`,
    spellIdList: [
      SPELL_DETECT_EVIL_AND_GOOD,
      SPELL_DETECT_MAGIC,
    ],
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 277,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 3,
      },
    ],
  },
  {
    id: MAGIC_ITEM_DYRRN_S_TENTACLE_WHIP,
    name: `Щупальцевый кнут Дирна`,
    nameEn: `Dyrrn’s Tentacle Whip`,
    type: MGC_TYPE_WEAPON,
    gearType: GEAR_WHIP,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Это длинная, похожая на кнут прядь крепких мышц с острым жалом на конце.

Для настройки на это симбиотическое оружие, Вам нужно обернуть _Щупальцевый кнут Дирна_ вокруг запястья на всё время настройки, пока он болезненно проращивает свои усики в Вашу руку.

Вы получаете бонус +2 к броскам атаки и урона, совершённым этим магическим оружием, но помеху на броски атаки, совершённые им против аберраций. Существо, поражённое этим оружием, получает дополнительно 1к6 психического урона. Если Вы выбросили 20 на к20 при броске атаки _Щупальцевым кнутом_, цель оглушается до конца своего следующего хода.

Вы можете бонусным действием убрать _Кнут_, втянув его в руку или, наоборот, вытащить его из руки.`,
    symbiotic: `_Щупальцевый кнут Дирна_ нельзя открепить от Вас, пока Вы на него настроены и Вы не можете добровольно прекратить настройку на него.

Если Вы становитесь целью заклинания, которое снимает проклятье, Ваша настройка на _Кнут_ оканчивается и он отклепляется от Вас.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 277,
    },
  },
  {
    id: MAGIC_ITEM_EARWORM,
    name: `Ушной червь`,
    nameEn: `Earworm`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Чтобы настроиться на этого симбионта, Вы должны держать его у кожи за ухом всё время настройки, пока он зарывается в Вашу голову и прикрепляется к Вашему черепу.

Пока _Ушной червь_ находится внутри Вас, Вы можете говорить, читать и писать на Глубинной речи.

# Заклинания

У _Червя_ есть 10 зарядов. Вы можете действием потратить 1 или несколько зарядов, чтобы наложить им одно из следующих заклинаний (СЛ испытания 15).

* [Диссонирующий шёпот](SPELL:${SPELL_DISSONANT_WHISPERS}) (1 заряд)
* [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) (2 заряда)

Каждый раз, когда Вы сотворяете _Ушным червём_ заклинание [Обнаружения мыслей](SPELL:${SPELL_DETECT_THOUGHTS}), он посылает собранную информацию ближайшему дэлькиру или ближайшему _Ушному червю_, пока она не достигнет дэлькира.

_Ушной червь_ восстанавливает 1к4 заряда на рассвете.`,
    symbiotic: `_Ушного червя_ нельзя убрать, пока Вы на него настроены. Вы не можете добровольно прекратить настройку на него.

Если Вы становитесь целью заклинания, которое снимает проклятье, Ваша настройка на _Ушного червя_ оканчивается и он покидает от Вас.`,
    spellIdList: [
      SPELL_DISSONANT_WHISPERS,
      SPELL_DETECT_THOUGHTS,
    ],
    source: {
      id: SOURCE_ERFTLW,
      page: 278,
    },
  },
  {
    id: MAGIC_ITEM_EVERBRIGHT_LANTERN,
    name: `Вечный фонарь`,
    nameEn: `Everbright Lantern`,
    type: MGC_TYPE_WONDROUS_ITEM,
    gearType: GEAR_LANTERN_BULLSEYE,
    rarity: MGC_RARITY_COMMON,
    description: `Этот направленный фонарь содержит драконий осколок Эберрона, излучающий свет, сравнимый с светом заклинания [Вечный огонь](SPELL:${SPELL_CONTINUAL_FLAME}).

_Вечный фонарь_ излучает свет 120-футовым конусом. Ближайшие 60 футов — это яркий свет, а дальние 60 футов — тусклый свет.`,
    spellIdList: [SPELL_CONTINUAL_FLAME],
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 278,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 2,
      },
    ],
  },
  {
    id: MAGIC_ITEM_FEATHER_TOKEN,
    name: `Жетон пера`,
    nameEn: `Feather Token`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `На этом небольшом металлическом диске начертано изображение пера.

Когда Вы падаете с высоты не менее 20 футов, имея с собой _Жетон пера_, Вы опускаетесь на 60 футов за раунд и не получаете урона от падения.

Магия _Жетона_ расходуется после того, как Вы приземляетесь, после чего диск становится немагическим.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 278,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 2,
      },
    ],
  },
  {
    id: MAGIC_ITEM_FINDER_S_GOGGLES,
    name: `Очки искателя`,
    nameEn: `Finder’s Goggles`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    attunementComment: `существом с Меткой Поиска`,
    description: `Линзы этих цветастых очков вырезаны из драконьих осколков Сибериса.

Используя эти очки, Вы получаете следующие преимущества:

* Когда Вы совершаете проверку Мудрости (Проницательность), Вы можете бросить к4 и добавить выпавшее число к результату.
* Вы можете действием исследовать объект, чтобы определить ауру последнего существа, которое его коснулось. Совершите проверку Мудрости (Проницательность) против СЛ 13 + количество дней с момента последнего контакта. При успехе Вы узнаёте тип существа и можете немедленно использовать очки, чтобы сотворить [Поиск существа](SPELL:${SPELL_LOCATE_CREATURE}), чтобы найти это существо. Это свойство нельзя использовать снова до следующего рассвета.`,
    spellIdList: [SPELL_LOCATE_CREATURE],
    source: {
      id: SOURCE_ERFTLW,
      page: 278,
    },
  },
  {
    id: MAGIC_ITEM_GLAMERWEAVE_COMMON,
    name: `Чароткань, обычная`,
    nameEn: `Glamerweave, Common`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `_Чароткань_ — это одежда, пропитанная безобидной иллюзорной магией.

Вы можете бонусным действием создать движущийся иллюзорный узор на ткани.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 278,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 2,
      },
    ],
  },
  {
    id: MAGIC_ITEM_GLAMERWEAVE_UNCOMMON,
    name: `Чароткань, необычная`,
    nameEn: `Glamerweave, Uncommon`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Чароткань — это одежда, пропитанная безобидной иллюзорной магией.

_Чароткань_ может иметь узор, исходящий от ткани. Например, мантия из _Чароткани_ может быть охвачено безвредным иллюзорным пламенем, а вокруг шляпы из _Чароткани_ могут порхать иллюзорные бабочки.

Когда Вы совершаете проверку Харизмы (Выступление или Убеждение), надев _Чароткань_, Вы можете бросить к4 и добавить выпавшее число к проверке. После того, как Вы воспользуетесь этой способностью, его нельзя будет использовать снова до следующего рассвета.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 278,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 2,
      },
    ],
  },
  {
    id: MAGIC_ITEM_KEYCHARM,
    name: `Брелок`,
    nameEn: `Keycharm`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    attunementComment: `существом с Меткой Охраны`,
    description: `Этот маленький стилизованный ключ играет жизненно важную роль в работе Дома Кундарак.

Если Вы сотворяете [Сигнал тревоги](SPELL:${SPELL_ALARM}), [Волшебный замок](SPELL:${SPELL_ARCANE_LOCK}) или [Охранные руны](SPELL:${SPELL_GLYPH_OF_WARDING}), то можете привязать эффект этих заклинаний к _Брелку_ так, что его носитель получит предупреждение от [Сигнала тревоги](SPELL:${SPELL_ALARM}), игнорирует [Волшебный замок](SPELL:${SPELL_ARCANE_LOCK}) или избегает активации [Охранных рун](SPELL:${SPELL_GLYPH_OF_WARDING}).

Кроме того, носитель (которому не надо настраиваться на _Брелок_) может действием прекратить одно из привязанных к _Брелоку_ заклинаний, если он знает командное слово, которое Вы для этого задали.

К одному _Брелоку_ можно привязать до трёх заклинаний одновременно.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 278,
    },
  },
  {
    id: MAGIC_ITEM_KYRZIN_S_OOZE,
    name: `Кирзинова жижа`,
    nameEn: `Kyrzin’s Ooze`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Эта опалесцирующая симбиотическая слизь запечатана в банке. Она медленно движется и перемещается, будто бы бесконечно исследуя внутренности своей банки.

Для настройки на _Кирзинову жижу_, Вам нужно выпить содержимое банки, получая новые способности.

# Сопротивление

Пока Вы настроены на _Кирзинову жижу_, Вы получаете сопротивление к урону ядом и кислотой и иммунитет к отравленному состоянию.

# Аморфность

Вы можете действием произнести командное слово и придать своему телу аморфность слизи. В течение следующей минуты Вы (со всем что несёте и носите) можете перемещаться сквозь пространство шириной в 1 дюйм без протискивания.

Использовав эту способность, Вы не можете использовать её снова до следующего рассвета.

# Кислотное дыхание

Вы можете действием выдохнуть кислоту 30-футовой линией шириной 5 футов.

Все существа в этой области должны пройти испытание Ловкости СЛ 15, получая 36 (8к8) урона кислотой при провале, или половину этого урона при успехе.

Использовав эту способность, Вы не можете использовать её снова до следующего рассвета.`,
    symbiotic: `_Кирзинова жижа_ не может быть отделена от Вас, пока Вы на неё настроены и Вы не можете добровольно прекратить настройку на неё.

Если Вы становитесь целью заклинания, которое снимает проклятье, Ваша настройка на _Кирзинову жижу_ оканчивается и она вытекает из Вас.

Если Вы умираете с _Кирзиновой жижей_ внутри, она выплёскивается и поглощает Вас, превращая Ваш труп в [Чёрную слизь](CREATURE:${CREATURE_BLACK_PUDDING}), дружественную дэлькиру.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 279,
    },
  },
  {
    id: MAGIC_ITEM_LIVING_GLOVES,
    name: `Живые перчатки`,
    nameEn: `Living Gloves`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Эти симбиотические перчатки, сделаные из тонкого хитина и сухожилий, живут собственной жизнью.

Чтобы настроиться на них, Вы должны носить их в течение всего периода настройки, пока перчатки прикрепляются к коже.

Настроившись на эти перчатки, Вы получаете одно из следующих владений (сделайте выбор при настройке на перчатки)

* [Воровские инструменты](GEAR:${GEAR_THIEVES_TOOLS})
* Ловкость рук
* Один вид ремесленных инструментов по Вашему выбору
* Один вид музыкальных инструментов по Вашему выбору

Когда Вы совершаете проверку способности с использованием выбранного владения, то добавляете к проверке удвоенный бонус мастерства.`,
    symbiotic: `_Живые перчатки_ не могут быть сняты, пока Вы на них настроены и Вы не можете добровольно прекратить настройку на них.

Если Вы становитесь целью заклинания, которое снимает проклятье, Ваша настройка на _Живые перчатки_ оканчивается и становится возможным снять их с Вас.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 279,
    },
  },
  {
    id: MAGIC_ITEM_PROSTHETIC_LIMB,
    name: `Протез`,
    nameEn: `Prosthetic Limb`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    attunementComment: `существом, потерявшим одну или несколько конечностей`,
    description: `Этот протез заменяет ладонь, руку, ступню, ногу или аналогичную конечность, которая была утеряна или удалена.

Пока протез прикреплён и настроен на Вас, он работает так же, как и часть тела, которую заменяет. Вы можете открепить или снова прикрепить его действием, и никто другой не может его удалить.

Если у Вас несколько протезов, они считаются одним магическим предметом для учёта магических предметов, на которых Вы можете настроиться.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 277,
    },
  },
  {
    id: MAGIC_ITEM_SCRIBE_S_PEN,
    name: `Писчее перо`,
    nameEn: `Scribe’s Pen`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    attunementComment: `существом с Меткой Письма`,
    description: `Вы можете писать этим пером на любой поверхности. Вы решаете — будут ли эти письмена видимыми или невидимыми, но они всегда видны для обладателя Метки Письма.

Любое существо с Меткой Письма может действием коснуться таких невидимых письмён и сделать их видимыми для всех.

Если Вы используете _Писчее перо_ для письма на существе, которое не является конструктом, письмена исчезнут через 7 дней.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 280,
    },
  },
  {
    id: MAGIC_ITEM_SHIFTWEAVE,
    name: `Переверткань`,
    nameEn: `Shiftweave`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    description: `При создании костюма из _Переверткани_ в него можно вложить до пяти других нарядов.

Нося _Переверткань_, Вы можете действием произнести её командное слово, превращая её в другой наряд из тех, что она содержит.

Вне зависимости от того, как наряд выглядит, он не может быть ничем, кроме одежды. Хотя наряды _Переверткани_ и могут повторять облик другой магической одежды, они не получают её магические свойства.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 280,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 2,
      },
    ],
  },
  {
    id: MAGIC_ITEM_SPELLSHARD,
    name: `Осколок заклинаний`,
    nameEn: `Spellshard`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Этот полированный драконий осколок Эберрона помещается в ладонь и хранит информацию подобно книге.

Осколок может содержать не более 230 страниц информации. _Осколок_ может быть создан пустым или уже заполненным информацией. Когда осколок создаётся, создать может установить пароль, который нужно произнести для получения доступа к хранящейся в _Осколке_ информации.

Держа осколок, Вы можете действием открыть ему свой разум, видя в своём разуме его содержание. На последующих раундах, чтение текста или записывание нового текста на пустых «страницах» требует поддержания концентрации (как при концентрации на заклинании) и зщанимает столько же времени, как если бы Вы читали и писали нормально. Подумав о конкретной фразе или теме, Вы увидите первый упоминающий её раздел _Осколка_.

Волшебник может использовать _Осколок заклинаний_ как [книгу заклинаний](GEAR:${GEAR_SPELLBOOK}), тратя обычное количество золота для записи заклинаний в него.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 280,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 3,
      },
    ],
  },
  {
    id: MAGIC_ITEM_SPEAKING_STONE,
    name: `Камень общения`,
    nameEn: `Speaking Stone`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    description: `Сеть сообщательных станций дома Сивис — ключ к дальней, почти мгновенной связи по всему Хорвэру.

Каждая станция содержит хотя бы один _Камень общения_, вырезанный из драконьего осколка Сибериса и расчерченный волшебными знаками, которые уникально его обозначают.

Если Вы — гном с Меткой Письма, Вы можете коснуться камня и действием сотворить с его помощью заклинание [Послание](SPELL:${SPELL_SENDING}). Цель этого заклинания — другой _Камень общения_, чьи местоположение или уникальную последовательность знаков Вы знаете. Существо в пределах 5 футов от того _Камня_ слышит сообщение, как если бы оно было целью.

На посту у _Камня общения_ сообщательной станции Сивис всегда стоит гном, слушающий приходящие сообщения и записывающий их для доставки адресатам.`,
    spellIdList: [SPELL_SENDING],
    source: {
      id: SOURCE_ERFTLW,
      page: 280,
    },
  },
  {
    id: MAGIC_ITEM_VENTILATING_LUNGS,
    name: `Вентилируемые лёгкие`,
    nameEn: `Ventilating Lungs`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Эти металлические железы были созданы в ответ на использование ядовитых газов на полях сражений Последней Войны.

Когда Вы настраиваетесь на _Вентилируемые лёгкие_, они заменяют Ваши собственные лёгкие, которые исчезают. _Вентилируемые лёгкие_ позволяют нормально дышать даже в [Преграде магии](SPELL:${SPELL_ANTIMAGIC_FIELD}), и их дыхательная функция не может быть подавлена магией.

Вне [Преграды магии](SPELL:${SPELL_ANTIMAGIC_FIELD}) или любого другого подавляющего магию эффекта, _Вентилируемые лёгкие_ позволяют Вам нормально дышать в любой среде (включая вакуум), и Вы получаете преимущество к испытаниям от вредных газов, таких как те, что создаются заклинаниями [Зловонное облако](SPELL:${SPELL_STINKING_CLOUD}), [Облаком смерти](SPELL:${SPELL_CLOUDKILL}), вдыхаемых ядов и газового Оружия дыхания.

Вы можете действием выдохнуть _Вентилируемыми лёгкими_ порыв ветра, как если бы Вы сотворили заклинание [Порыв ветра](SPELL:${SPELL_GUST_OF_WIND}) (СЛ испытания 15) без каких-либо компонентов. Эту способность лёгких нельзя использовать снова до следующего рассвета.

Если Ваша настройка на _Вентилируемые лёгкие_ заканчивается, Ваши собственные лёгкие снова появляются.`,
    spellIdList: [SPELL_GUST_OF_WIND],
    source: {
      id: SOURCE_ERFTLW,
      page: 280,
    },
  },
  {
    id: MAGIC_ITEM_WAND_SHEATH,
    name: `Держатель палочек`,
    nameEn: `Wand Sheath`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    attunementComment: `кованым`,
    description: `_Держатель палочек_ крепится на руку и даёт следующие преимущества:

* _Держатель палочек_ нельзя открепить от Вас, пока Вы на него настроены.
* Вы можете действием вставить в него [волшебную палочку](GEAR:${GEAR_WAND}). В _Держателе_ одновременно помещается только одна [волшебная палочка](GEAR:${GEAR_WAND}).
* Вы можете бонусным действием выдвигать [волшебную палочку](GEAR:${GEAR_WAND}) из _Держателя_ или втягивать её обратно. Пока [волшебная палочка](GEAR:${GEAR_WAND}) выдвинута, Вы можете использовать её, как если бы Вы её держали, но при этом Ваша рука остаётся свободной.

Если [волшебная палочка](GEAR:${GEAR_WAND}) в _Держателе_ требует настройки, Вы должны настроиться на неё, прежде чем сможете её использовать. Тем не менее, _Держатель палочек_ и прикреплённая к нему [палочка](GEAR:${GEAR_WAND}) считаются одним магическим предметом для учёта магических предметов, на которых Вы можете настроиться. Если Вы вынете [волшебную палочку](GEAR:${GEAR_WAND}) из _Держателя_, Ваша настройка на неё закончится.`,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 280,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 3,
      },
    ],
  },
  {
    id: MAGIC_ITEM_WHEEL_OF_WIND_AND_WATER,
    name: `Штурвал ветра и воды`,
    nameEn: `Wheel of Wind and Water`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Будучи установленным в рубку стихийного галеона или воздушного корабля, этот штурвал позволяет обладателям Метки Бури телепатически контролировать элементаля, запертого в этой технике.

Если _Штурвал ветра и воды_ установлен на обычное парусное судно, то использующее его существо с Меткой Бури, может создать область идеальной погоды вокруг судна, увеличивая его скорость на 5 узлов.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 281,
    },
  },
  ...imbuedWoodFocus,
  ...livingArmor,
  ...orbOfShielding,
]
