const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_BULLYWUG} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_BULLYWUG,
  nameEn: 'Bullywug',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'жаболюд',
      genitive: 'жаболюда',
      dative: 'жаболюду',
      accusative: 'жаболюда',
      instrumental: 'жаболюдом',
      prepositional: 'жаболюде',
    },
    plural: {
      nominative: 'жаболюды',
      genitive: 'жаболюдов',
      dative: 'жаболюдам',
      accusative: 'жаболюдов',
      instrumental: 'жаболюдами',
      prepositional: 'жаболюдах',
    },
  },
}
