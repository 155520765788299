const {GEAR_ROD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_ROD} = require('./../../../../../magicItemTypeList')
const {PC_CLASS_WARLOCK} = require('./../../../../../pcClassIdList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    bonus,
    id,
    rarity,
  }
) => ({
  id,
  name: `Жезл хранителя договора +${bonus}`,
  nameEn: `Rod of the Pact Keeper +${bonus}`,
  type: MGC_TYPE_ROD,
  gearType: GEAR_ROD,
  rarity,
  attunement: true,
  attunementComment: `колдуном`,
  description: `Держа этот жезл, Вы получаете бонус **+${bonus}** к броскам атаки заклинаниями и СЛ испытаний от Ваших заклинаний [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}).

Кроме того, Вы можете действием восстановить одну ячейку заклинания [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}), если держите этот жезл. Вы не можете использовать это свойство повторно, пока не окончите длинный отдых.`,
  genderId: GENDER_MALE,
  siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 162,
  },
})

module.exports = generator
