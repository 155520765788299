const reducePropIdList = require('./../../../utils/reducePropIdList')
const {timePeriodCollection} = require('./../../timePeriodList')

const reduceByTimeProp = (spellList, propName) => spellList
  .reduce(reducePropIdList(propName), [])
  .sort(
    (A, B) => (
      timePeriodCollection[A]
      && timePeriodCollection[B]
      && 'weight' in timePeriodCollection[A]
      && 'weight' in timePeriodCollection[B]
    )
      ? timePeriodCollection[A].weight > timePeriodCollection[B].weight
        ? 1
        : -1
      : A > B
        ? 1
        : -1
  )

module.exports = reduceByTimeProp
