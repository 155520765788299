const _ = require('lodash')

const listToCollectionById = require('./../utils/listToCollectionById')
const { GENDER_MALE, GENDER_FEMALE, GENDER_MIDDLE, GENDER_ANY } = require('./../constants/genderList')

const ALIGNMENT_NO = 'no'
const ALIGNMENT_ANY = 'any'
const ALIGNMENT_ANY_GOOD = 'any_good'
const ALIGNMENT_ANY_EVIL = 'any_evil'
const ALIGNMENT_ANY_LAWFUL = 'any_lawful'
const ALIGNMENT_ANY_CHAOTIC = 'any_chaotic'
const ALIGNMENT_NOT_NEUTRAL = 'not_neutral'
const ALIGNMENT_NOT_GOOD = 'not_good'
const ALIGNMENT_NOT_EVIL = 'not_evil'
const ALIGNMENT_NOT_LAWFUL = 'not_lawful'
const ALIGNMENT_NOT_LN = 'not_ln'
const ALIGNMENT_NOT_CHAOTIC = 'not_chaotic'
const ALIGNMENT_ANY_NEUTRAL = 'any_neutral'
const ALIGNMENT_LG = 'lg'
const ALIGNMENT_NG = 'ng'
const ALIGNMENT_CG = 'cg'
const ALIGNMENT_LN = 'ln'
const ALIGNMENT_N = 'n'
const ALIGNMENT_CN = 'cn'
const ALIGNMENT_LE = 'le'
const ALIGNMENT_NE = 'ne'
const ALIGNMENT_CE = 'ce'

const aligmentRawList = [
  {
    id: ALIGNMENT_NO,
    showInList: true,
    oppositeId: null,
    name: {
      [GENDER_ANY]: {
        nominative: 'без мировоззрения',
        genitive: 'без мировоззрения',
        dative: 'без мировоззрения',
        accusative: 'без мировоззрения',
        instrumental: 'без мировоззрения',
        prepositional: 'без мировоззрения',
      },
    },
  },
  {
    id: ALIGNMENT_ANY,
    name: {
      [GENDER_ANY]: {
        nominative: 'любое мировоззрение',
        genitive: 'любого мировоззрения',
        dative: 'любому мировоззрению',
        accusative: 'любое мировоззрение',
        instrumental: 'любым мировоззрением',
        prepositional: 'любом мировоззрении',
      },
    },
    children: [
      ALIGNMENT_ANY_GOOD,
      ALIGNMENT_ANY_EVIL,
      ALIGNMENT_ANY_LAWFUL,
      ALIGNMENT_ANY_CHAOTIC,
      ALIGNMENT_NOT_NEUTRAL,
    ],
  },
  {
    id: ALIGNMENT_ANY_GOOD,
    showInList: true,
    oppositeId: ALIGNMENT_ANY_EVIL,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое доброе',
        genitive: 'любого доброго',
        dative: 'любому доброму',
        accusative: 'любое доброе',
        instrumental: 'любым добрым',
        prepositional: 'любом добром',
      },
      [GENDER_MALE]: {
        nominative: 'любой добрый',
        genitive: 'любого доброго',
        dative: 'любому доброму',
        accusative: 'любого доброго',
        instrumental: 'любым добрым',
        prepositional: 'любом добром',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая добрая',
        genitive: 'любой доброй',
        dative: 'любой доброй',
        accusative: 'любую добрую',
        instrumental: 'любой доброй',
        prepositional: 'любой доброй',
      },
    },
    children: [
      ALIGNMENT_LG,
      ALIGNMENT_NG,
      ALIGNMENT_CG,
    ],
  },
  {
    id: ALIGNMENT_ANY_EVIL,
    showInList: true,
    oppositeId: ALIGNMENT_ANY_GOOD,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое злое',
        genitive: 'любого злого',
        dative: 'любому злому',
        accusative: 'любое злое',
        instrumental: 'любым злым',
        prepositional: 'любом злом',
      },
      [GENDER_MALE]: {
        nominative: 'любой злой',
        genitive: 'любого злого',
        dative: 'любому злому',
        accusative: 'любого злого',
        instrumental: 'любым злым',
        prepositional: 'любом злом',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая злая',
        genitive: 'любой злой',
        dative: 'любой злой',
        accusative: 'любую злую',
        instrumental: 'любой злой',
        prepositional: 'любой злой',
      },
    },
    children: [
      ALIGNMENT_LE,
      ALIGNMENT_NE,
      ALIGNMENT_CE,
    ],
  },
  {
    id: ALIGNMENT_ANY_LAWFUL,
    showInList: true,
    oppositeId: ALIGNMENT_ANY_CHAOTIC,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое принципиальное',
        genitive: 'любого принципиального',
        dative: 'любому принципиальному',
        accusative: 'любое принципиальное',
        instrumental: 'любым принципиальным',
        prepositional: 'любом принципиальном',
      },
      [GENDER_MALE]: {
        nominative: 'любой принципиальный',
        genitive: 'любого принципиального',
        dative: 'любому принципиальному',
        accusative: 'любого принципиального',
        instrumental: 'любым принципиальным',
        prepositional: 'любом принципиальном',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая принципиальная',
        genitive: 'любой принципиальной',
        dative: 'любой принципиальной',
        accusative: 'любую принципиальную',
        instrumental: 'любой принципиальной',
        prepositional: 'любой принципиальной',
      },
    },
    children: [
      ALIGNMENT_LG,
      ALIGNMENT_LN,
      ALIGNMENT_LE,
    ],
  },
  {
    id: ALIGNMENT_ANY_CHAOTIC,
    showInList: true,
    oppositeId: ALIGNMENT_ANY_LAWFUL,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое хаотичное',
        genitive: 'любого хаотичного',
        dative: 'любому хаотичному',
        accusative: 'любое хаотичное',
        instrumental: 'любым хаотичным',
        prepositional: 'любом хаотичном',
      },
      [GENDER_MALE]: {
        nominative: 'любой хаотичный',
        genitive: 'любого хаотичного',
        dative: 'любому хаотичному',
        accusative: 'любого хаотичного',
        instrumental: 'любым хаотичным',
        prepositional: 'любом хаотичном',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая хаотичная',
        genitive: 'любой хаотичной',
        dative: 'любой хаотичной',
        accusative: 'любую хаотичную',
        instrumental: 'любой хаотичной',
        prepositional: 'любой хаотичной',
      },
    },
    children: [
      ALIGNMENT_CG,
      ALIGNMENT_CN,
      ALIGNMENT_CE,
    ],
  },
  {
    id: ALIGNMENT_NOT_NEUTRAL,
    showInList: true,
    oppositeId: ALIGNMENT_NOT_NEUTRAL,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое не нейтральное',
        genitive: 'любого не нейтрального',
        dative: 'любому не нейтральному',
        accusative: 'любое не нейтральное',
        instrumental: 'любым не нейтральным',
        prepositional: 'любом не нейтральном',
      },
      [GENDER_MALE]: {
        nominative: 'любой не нейтральный',
        genitive: 'любого не нейтрального',
        dative: 'любому не нейтральному',
        accusative: 'любого не нейтрального',
        instrumental: 'любым не нейтральным',
        prepositional: 'любом не нейтральном',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая не нейтральная',
        genitive: 'любой не нейтральной',
        dative: 'любой не нейтральной',
        accusative: 'любую не нейтральную',
        instrumental: 'любой не нейтральной',
        prepositional: 'любой не нейтральной',
      },
    },
    children: [
      ALIGNMENT_LG,
      ALIGNMENT_LE,
      ALIGNMENT_CG,
      ALIGNMENT_CE,
    ],
  },
  {
    id: ALIGNMENT_NOT_GOOD,
    showInList: true,
    oppositeId: ALIGNMENT_NOT_EVIL,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое недоброе',
        genitive: 'любого недоброго',
        dative: 'любому недоброму',
        accusative: 'любое недоброе',
        instrumental: 'любым недобрым',
        prepositional: 'любом недобром',
      },
      [GENDER_MALE]: {
        nominative: 'любой недобрый',
        genitive: 'любого недоброго',
        dative: 'любому недоброму',
        accusative: 'любого недоброго',
        instrumental: 'любым недобрым',
        prepositional: 'любом недобром',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая недобрая',
        genitive: 'любой недоброй',
        dative: 'любой недоброй',
        accusative: 'любую недобрую',
        instrumental: 'любой недоброй',
        prepositional: 'любой недоброй',
      },
    },
    children: [
      ALIGNMENT_LN,
      ALIGNMENT_N,
      ALIGNMENT_CN,
      ALIGNMENT_ANY_EVIL,
    ],
  },
  {
    id: ALIGNMENT_NOT_EVIL,
    showInList: true,
    oppositeId: ALIGNMENT_NOT_GOOD,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое незлое',
        genitive: 'любого незлого',
        dative: 'любому незлому',
        accusative: 'любое незлое',
        instrumental: 'любым незлым',
        prepositional: 'любом незлом',
      },
      [GENDER_MALE]: {
        nominative: 'любой незлой',
        genitive: 'любого незлого',
        dative: 'любому незлому',
        accusative: 'любого незлого',
        instrumental: 'любым незлым',
        prepositional: 'любом незлом',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая незлая',
        genitive: 'любой незлой',
        dative: 'любой незлой',
        accusative: 'любую незлую',
        instrumental: 'любой незлой',
        prepositional: 'любой незлой',
      },
    },
    children: [
      ALIGNMENT_LN,
      ALIGNMENT_N,
      ALIGNMENT_CN,
      ALIGNMENT_ANY_GOOD,
    ],
  },
  {
    id: ALIGNMENT_NOT_LAWFUL,
    showInList: true,
    oppositeId: ALIGNMENT_NOT_CHAOTIC,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое непринципиальное',
        genitive: 'любого непринципиального',
        dative: 'любому непринципиальному',
        accusative: 'любое непринципиальное',
        instrumental: 'любым непринципиальным',
        prepositional: 'любом непринципиальном',
      },
      [GENDER_MALE]: {
        nominative: 'любой непринципиальный',
        genitive: 'любого непринципиального',
        dative: 'любому непринципиальному',
        accusative: 'любого непринципиального',
        instrumental: 'любым непринципиальным',
        prepositional: 'любом непринципиальном',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая непринципиальная',
        genitive: 'любой непринципиальной',
        dative: 'любой непринципиальной',
        accusative: 'любую непринципиальную',
        instrumental: 'любой непринципиальной',
        prepositional: 'любой непринципиальной',
      },
    },
    children: [
      ALIGNMENT_NG,
      ALIGNMENT_N,
      ALIGNMENT_NE,
      ALIGNMENT_ANY_CHAOTIC,
    ],
  },
  {
    id: ALIGNMENT_NOT_CHAOTIC,
    showInList: true,
    oppositeId: ALIGNMENT_NOT_LAWFUL,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое нехаотичное',
        genitive: 'любого нехаотичного',
        dative: 'любому нехаотичному',
        accusative: 'любое нехаотичное',
        instrumental: 'любым нехаотичным',
        prepositional: 'любом нехаотичном',
      },
      [GENDER_MALE]: {
        nominative: 'любой нехаотичный',
        genitive: 'любого нехаотичного',
        dative: 'любому нехаотичному',
        accusative: 'любого нехаотичного',
        instrumental: 'любым нехаотичным',
        prepositional: 'любом нехаотичном',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая нехаотичная',
        genitive: 'любой нехаотичной',
        dative: 'любой нехаотичной',
        accusative: 'любую нехаотичную',
        instrumental: 'любой нехаотичной',
        prepositional: 'любой нехаотичной',
      },
    },
    children: [
      ALIGNMENT_NG,
      ALIGNMENT_N,
      ALIGNMENT_NE,
      ALIGNMENT_ANY_LAWFUL,
    ],
  },
  {
    id: ALIGNMENT_ANY_NEUTRAL,
    showInList: true,
    oppositeId: ALIGNMENT_NOT_NEUTRAL,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'любое нейтральное',
        genitive: 'любого нейтрального',
        dative: 'любому нейтральному',
        accusative: 'любое нейтральное',
        instrumental: 'любым нейтральным',
        prepositional: 'любом нейтральном',
      },
      [GENDER_MALE]: {
        nominative: 'любой нейтральный',
        genitive: 'любого нейтрального',
        dative: 'любому нейтральному',
        accusative: 'любого нейтрального',
        instrumental: 'любым нейтральным',
        prepositional: 'любом нейтральном',
      },
      [GENDER_FEMALE]: {
        nominative: 'любая нейтральная',
        genitive: 'любой нейтральной',
        dative: 'любой нейтральной',
        accusative: 'любую нейтральную',
        instrumental: 'любой нейтральной',
        prepositional: 'любой нейтральной',
      },
    },
    children: [
      ALIGNMENT_NG,
      ALIGNMENT_LN,
      ALIGNMENT_N,
      ALIGNMENT_CN,
      ALIGNMENT_NE,
    ],
  },
  {
    id: ALIGNMENT_LG,
    showInList: true,
    oppositeId: ALIGNMENT_CE,
    description: `Принципиально-добрые (ПД) существа совершают поступки, считающиеся в обществе правильными. Золотые драконы, паладины и большинство дварфов являются принципиально-добрыми.`,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'принципиально-доброе',
        genitive: 'принципиально-доброго',
        dative: 'принципиально-доброму',
        accusative: 'принципиально-доброе',
        instrumental: 'принципиально-добрым',
        prepositional: 'принципиально-добром',
      },
      [GENDER_MALE]: {
        nominative: 'принципиально-добрый',
        genitive: 'принципиально-доброго',
        dative: 'принципиально-доброму',
        accusative: 'принципиально-доброго',
        instrumental: 'принципиально-добрым',
        prepositional: 'принципиально-добром',
      },
      [GENDER_FEMALE]: {
        nominative: 'принципиально-добрая',
        genitive: 'принципиально-доброй',
        dative: 'принципиально-доброй',
        accusative: 'принципиально-добрую',
        instrumental: 'принципиально-доброй',
        prepositional: 'принципиально-доброй',
      },
    },
  },
  {
    id: ALIGNMENT_NG,
    showInList: true,
    oppositeId: ALIGNMENT_NE,
    description: `Нейтрально-добрые (НД) стараются сделать всё, от них зависящее, чтобы помочь другим. Многие небожители, некоторые облачные великаны и большинство гномов нейтрально-добрые.`,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'нейтрально-доброе',
        genitive: 'нейтрально-доброго',
        dative: 'нейтрально-доброму',
        accusative: 'нейтрально-доброе',
        instrumental: 'нейтрально-добрым',
        prepositional: 'нейтрально-добром',
      },
      [GENDER_MALE]: {
        nominative: 'нейтрально-добрый',
        genitive: 'нейтрально-доброго',
        dative: 'нейтрально-доброму',
        accusative: 'нейтрально-доброго',
        instrumental: 'нейтрально-добрым',
        prepositional: 'нейтрально-добром',
      },
      [GENDER_FEMALE]: {
        nominative: 'нейтрально-добрая',
        genitive: 'нейтрально-доброй',
        dative: 'нейтрально-доброй',
        accusative: 'нейтрально-добрую',
        instrumental: 'нейтрально-доброй',
        prepositional: 'нейтрально-доброй',
      },
    },
  },
  {
    id: ALIGNMENT_CG,
    showInList: true,
    oppositeId: ALIGNMENT_LE,
    description: `Хаотично-добрые (ХД) существа действуют по собственной совести, вне зависимости от того, что думают другие. Медные драконы, многие эльфы и единороги хаотично-добрые.`,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'хаотично-доброе',
        genitive: 'хаотично-доброго',
        dative: 'хаотично-доброму',
        accusative: 'хаотично-доброе',
        instrumental: 'хаотично-добрым',
        prepositional: 'хаотично-добром',
      },
      [GENDER_MALE]: {
        nominative: 'хаотично-добрый',
        genitive: 'хаотично-доброго',
        dative: 'хаотично-доброму',
        accusative: 'хаотично-доброго',
        instrumental: 'хаотично-добрым',
        prepositional: 'хаотично-добром',
      },
      [GENDER_FEMALE]: {
        nominative: 'хаотично-добрая',
        genitive: 'хаотично-доброй',
        dative: 'хаотично-доброй',
        accusative: 'хаотично-добрую',
        instrumental: 'хаотично-доброй',
        prepositional: 'хаотично-доброй',
      },
    },
  },
  {
    id: ALIGNMENT_LN,
    showInList: true,
    oppositeId: ALIGNMENT_CN,
    description: `Принципиально-нейтральные (ПН) индивидуумы действуют в соответствии с законом, традицией, или личным кодексом. Многие монахи и некоторые волшебники являются принципиально-нейтральными.`,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'принципиально-нейтральное',
        genitive: 'принципиально-нейтрального',
        dative: 'принципиально-нейтральному',
        accusative: 'принципиально-нейтральное',
        instrumental: 'принципиально-нейтральным',
        prepositional: 'принципиально-нейтральном',
      },
      [GENDER_MALE]: {
        nominative: 'принципиально-нейтральный',
        genitive: 'принципиально-нейтрального',
        dative: 'принципиально-нейтральному',
        accusative: 'принципиально-нейтрального',
        instrumental: 'принципиально-нейтральным',
        prepositional: 'принципиально-нейтральном',
      },
      [GENDER_FEMALE]: {
        nominative: 'принципиально-нейтральная',
        genitive: 'принципиально-нейтральной',
        dative: 'принципиально-нейтральной',
        accusative: 'принципиально-нейтральную',
        instrumental: 'принципиально-нейтральной',
        prepositional: 'принципиально-нейтральной',
      },
    },
  },
  {
    id: ALIGNMENT_N,
    showInList: true,
    oppositeId: null,
    description: `Нейтральное (Н) мировоззрение у тех, кто предпочитает держаться подальше от нравственных вопросов и не принимать ничью сторону, делая то, что кажется лучшим в данное время. Людоящеры, большинство друидов и многие люди являются нейтральными.`,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'нейтральное',
        genitive: 'нейтрального',
        dative: 'нейтральному',
        accusative: 'нейтральное',
        instrumental: 'нейтральным',
        prepositional: 'нейтральном',
      },
      [GENDER_MALE]: {
        nominative: 'нейтральный',
        genitive: 'нейтрального',
        dative: 'нейтральному',
        accusative: 'нейтрального',
        instrumental: 'нейтральным',
        prepositional: 'нейтральном',
      },
      [GENDER_FEMALE]: {
        nominative: 'нейтральная',
        genitive: 'нейтральной',
        dative: 'нейтральной',
        accusative: 'нейтральную',
        instrumental: 'нейтральной',
        prepositional: 'нейтральной',
      },
    },
  },
  {
    id: ALIGNMENT_CN,
    showInList: true,
    oppositeId: ALIGNMENT_LN,
    description: `Хаотично-нейтральные (ХН) существа следуют своим прихотям, держа свою личную свободу превыше всего. Многие варвары и разбойники, а также некоторые барды хаотично-нейтральны.`,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'хаотично-нейтральное',
        genitive: 'хаотично-нейтрального',
        dative: 'хаотично-нейтральному',
        accusative: 'хаотично-нейтральное',
        instrumental: 'хаотично-нейтральным',
        prepositional: 'хаотично-нейтральном',
      },
      [GENDER_MALE]: {
        nominative: 'хаотично-нейтральный',
        genitive: 'хаотично-нейтрального',
        dative: 'хаотично-нейтральному',
        accusative: 'хаотично-нейтрального',
        instrumental: 'хаотично-нейтральным',
        prepositional: 'хаотично-нейтральном',
      },
      [GENDER_FEMALE]: {
        nominative: 'хаотично-нейтральная',
        genitive: 'хаотично-нейтральной',
        dative: 'хаотично-нейтральной',
        accusative: 'хаотично-нейтральную',
        instrumental: 'хаотично-нейтральной',
        prepositional: 'хаотично-нейтральной',
      },
    },
  },
  {
    id: ALIGNMENT_LE,
    showInList: true,
    oppositeId: ALIGNMENT_CG,
    description: `Принципиально-злые (ПЗ) существа методично берут то, что хотят, в рамках кодекса традиции, верности, или порядка. Дьяволы, синие драконы и хобгоблины принципиально-злые.`,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'принципиально-злое',
        genitive: 'принципиально-злого',
        dative: 'принципиально-злому',
        accusative: 'принципиально-злое',
        instrumental: 'принципиально-злым',
        prepositional: 'принципиально-злом',
      },
      [GENDER_MALE]: {
        nominative: 'принципиально-злой',
        genitive: 'принципиально-злого',
        dative: 'принципиально-злому',
        accusative: 'принципиально-злого',
        instrumental: 'принципиально-злым',
        prepositional: 'принципиально-злом',
      },
      [GENDER_FEMALE]: {
        nominative: 'принципиально-злая',
        genitive: 'принципиально-злой',
        dative: 'принципиально-злой',
        accusative: 'принципиально-злую',
        instrumental: 'принципиально-злой',
        prepositional: 'принципиально-злой',
      },
    },
  },
  {
    id: ALIGNMENT_NE,
    showInList: true,
    oppositeId: ALIGNMENT_NG,
    description: `Нейтрально-злое (НЗ) мировоззрение у тех, кто делает всё, что может сойти им с рук, без сострадания и угрызений совести. Многие дроу, некоторые облачные великаны и юголоты нейтрально-злы.`,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'нейтрально-злое',
        genitive: 'нейтрально-злого',
        dative: 'нейтрально-злому',
        accusative: 'нейтрально-злое',
        instrumental: 'нейтрально-злым',
        prepositional: 'нейтрально-злом',
      },
      [GENDER_MALE]: {
        nominative: 'нейтрально-злой',
        genitive: 'нейтрально-злого',
        dative: 'нейтрально-злому',
        accusative: 'нейтрально-злого',
        instrumental: 'нейтрально-злым',
        prepositional: 'нейтрально-злом',
      },
      [GENDER_FEMALE]: {
        nominative: 'нейтрально-злая',
        genitive: 'нейтрально-злой',
        dative: 'нейтрально-злой',
        accusative: 'нейтрально-злую',
        instrumental: 'нейтрально-злой',
        prepositional: 'нейтрально-злой',
      },
    },
  },
  {
    id: ALIGNMENT_CE,
    showInList: true,
    oppositeId: ALIGNMENT_LG,
    description: `Хаотично-злые (ХЗ) существа действуют со спонтанной жестокостью, вызванной их жадностью, ненавистью или жаждой крови. Демоны, красные драконы и орки хаотично-злы.`,
    name: {
      [GENDER_MIDDLE]: {
        nominative: 'хаотично-злое',
        genitive: 'хаотично-злого',
        dative: 'хаотично-злому',
        accusative: 'хаотично-злое',
        instrumental: 'хаотично-злым',
        prepositional: 'хаотично-злом',
      },
      [GENDER_MALE]: {
        nominative: 'хаотично-злой',
        genitive: 'хаотично-злого',
        dative: 'хаотично-злому',
        accusative: 'хаотично-злого',
        instrumental: 'хаотично-злым',
        prepositional: 'хаотично-злом',
      },
      [GENDER_FEMALE]: {
        nominative: 'хаотично-злая',
        genitive: 'хаотично-злой',
        dative: 'хаотично-злой',
        accusative: 'хаотично-злую',
        instrumental: 'хаотично-злой',
        prepositional: 'хаотично-злой',
      },
    },
  },
  {
    id: ALIGNMENT_NOT_LN,
    oppositeId: null,
    name: {
      [GENDER_ANY]: {
        nominative: 'любое мировоззрение, кроме принципиально-нейтрального',
        genitive: 'любого мировоззрения, кроме принципиально-нейтрального',
        dative: 'любому мировоззрению, кроме принципиально-нейтрального',
        accusative: 'любое мировоззрение, кроме принципиально-нейтрального',
        instrumental: 'любым мировоззрением, кроме принципиально-нейтрального',
        prepositional: 'любом мировоззрении, кроме принципиально-нейтрального',
      },
    },
    children: [
      ALIGNMENT_ANY_GOOD,
      ALIGNMENT_ANY_EVIL,
      ALIGNMENT_ANY_CHAOTIC,
      ALIGNMENT_N,
    ],
  },
]

const aligmentRawCollection = listToCollectionById(aligmentRawList)

function concatChildAligmentIds({children}) {
  return _.uniq(
    _.flatten(
      children && children.length
        ? children.reduce(
          (list, id) => [
            ...list,
            ...concatChildAligmentIds(aligmentRawCollection[id]),
          ],
          [children],
        )
        : []
    )
  )
}

const aligmentList = aligmentRawList.reduce(
  (list, aligment) => [
    ...list,
    {
      ...aligment,
      children: concatChildAligmentIds(aligment),
    },
  ],
  [],
)

module.exports = aligmentList

module.exports.aligmentCollection = listToCollectionById(aligmentList)

module.exports.ALIGNMENT_NO = ALIGNMENT_NO
module.exports.ALIGNMENT_ANY = ALIGNMENT_ANY
module.exports.ALIGNMENT_ANY_GOOD = ALIGNMENT_ANY_GOOD
module.exports.ALIGNMENT_ANY_EVIL = ALIGNMENT_ANY_EVIL
module.exports.ALIGNMENT_ANY_LAWFUL = ALIGNMENT_ANY_LAWFUL
module.exports.ALIGNMENT_ANY_CHAOTIC = ALIGNMENT_ANY_CHAOTIC
module.exports.ALIGNMENT_NOT_LN = ALIGNMENT_NOT_LN
module.exports.ALIGNMENT_NOT_NEUTRAL = ALIGNMENT_NOT_NEUTRAL
module.exports.ALIGNMENT_NOT_GOOD = ALIGNMENT_NOT_GOOD
module.exports.ALIGNMENT_NOT_EVIL = ALIGNMENT_NOT_EVIL
module.exports.ALIGNMENT_NOT_LAWFUL = ALIGNMENT_NOT_LAWFUL
module.exports.ALIGNMENT_NOT_CHAOTIC = ALIGNMENT_NOT_CHAOTIC
module.exports.ALIGNMENT_ANY_NEUTRAL = ALIGNMENT_ANY_NEUTRAL
module.exports.ALIGNMENT_LG = ALIGNMENT_LG
module.exports.ALIGNMENT_NG = ALIGNMENT_NG
module.exports.ALIGNMENT_CG = ALIGNMENT_CG
module.exports.ALIGNMENT_LN = ALIGNMENT_LN
module.exports.ALIGNMENT_N = ALIGNMENT_N
module.exports.ALIGNMENT_CN = ALIGNMENT_CN
module.exports.ALIGNMENT_LE = ALIGNMENT_LE
module.exports.ALIGNMENT_NE = ALIGNMENT_NE
module.exports.ALIGNMENT_CE = ALIGNMENT_CE
