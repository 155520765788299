const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {SPELL_PASS_WITHOUT_TRACE} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_PASS_WITHOUT_TRACE,
  name: 'Бесследное передвижение',
  nameEn: 'Pass Without Trace',
  description: `От Вас начинает исходить покров теней и тишины, скрывающий Вас и Ваших спутников от обнаружения. Пока заклинание активно, все существа, выбранные Вами в пределах 30 футов (включая Вас) получают бонус +10 к проверкам Ловкости (Скрытность), и их нельзя выследить без помощи магии. Существо, получившее этот бонус, не оставляет за собой следов.`,
  lvl: 2,
  magicSchoolId: MAGIC_ABJURATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `пепел от сожжённого листа омелы и еловая ветка`,
  duration: { timeId: TIME_HOUR, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 213,
    },
    {
      id: SOURCE_SRD,
      page: 167,
    },
  ],
}
