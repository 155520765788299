const {GENDER_MIDDLE} = require('./../../genderList')
const {MAGIC_ITEM_BAND_OF_LOYALTY} = require('./../../magicItemIdList')
const {MGC_RARITY_COMMON} = require('./../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../magicItemTypeList')
const {SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON} = require('./../../sourceList')

module.exports = [
  {
    id: MAGIC_ITEM_BAND_OF_LOYALTY,
    name: `Кольцо верности`,
    nameEn: `Band of Loyalty`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    attunement: true,
    description: `Если Ваши хиты опустились до 0, пока Вы носите это кольцо, Вы немедленно умираете.

Такие кольца предпочитают шпионы, которые не могут позволить себе попасть в руки врага.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
      page: 2,
    },
  },
]
