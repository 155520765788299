const {
  WEAPON_REACH,
  WEAPON_SPECIAL,
} = require('./../../../weaponPropList')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_LANCE} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_PHB} = require('./../../../sourceList')

module.exports = {
  id: GEAR_LANCE,
  genderId: GENDER_FEMALE,
  name: 'Кавалерийская пика',
  nameByCase: {
    nominative: 'кавалерийская пика',
    genitive: 'кавалерийской пики',
    accusative: 'кавалерийскую пику',
    instrumental: 'кавалерийской пикой',
  },
  nameAlt: 'Длинное копьё',
  nameEn: 'Lance',
  description: 'Вы совершаете с помехой атаки кавалерийской пикой по существам в пределах 5 футов. Кроме того, если Вы не находитесь верхом, кавалерийская пика используется двумя руками.',
  damage: {
    diceType: 12,
    diceCount: 1,
  },
  cost: 1000,
  damageType: DAMAGE_PIERCING,
  weight: 6,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    WEAPON_REACH,
    WEAPON_SPECIAL,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
