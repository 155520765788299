const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_BUGBEAR,
  CREATURE_TYPE_GOBLINOID,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  GEAR_HIDE_ARMOR,
  GEAR_JAVELIN,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ABILITY_SURPRISE_ATTACK} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {bugbearsDescriptionList} = require('./../../../../textCommonParts')
const {CR_1} = require('./../../../../crList')
const {CREATURE_BUGBEAR} = require('./../../../../creatureIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Багбир',
  nameAlt: [
    'Багбер',
    'Медвежатник',
  ],
  nameEn: 'Bugbear',
  description: bugbearsDescriptionList,
  id: CREATURE_BUGBEAR,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_BUGBEAR,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 201,
  },
  armor: {
    ac: 16,
    gearId: [GEAR_HIDE_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_DOUBLE,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GOBLIN,
    LANG_COMMON,
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Жестокость',
      description: `Рукопашное оружие причиняет одну дополнительную кость своего урона, когда ★СУЩЕСТВО★ попадает им (уже включено в атаку).`,
    },
    {
      id: ABILITY_SURPRISE_ATTACK,
      damage: `7 (2к6)`,
    },
  ],
  actionList: [
    {
      name: 'Моргентшерн',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 4,
        range: {
          normal: 30,
          max: 120,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
