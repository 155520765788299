const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_3,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CREATURE_DEATH_KNIGHT,
  CREATURE_LICH,
  CREATURE_NIGHT_HAG,
  CREATURE_NIGHTMARE,
  CREATURE_PEGASUS,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Кошмар',
  nameAlt: [
    'Мара',
    'Демоническая лошадь',
    'Адская лошадь',
  ],
  nameEn: 'Nightmare',
  nameEnAlt: [
    'Demon Horse',
    'Hell Horse',
  ],
  id: CREATURE_NIGHTMARE,
  description: [
    {
      header: 'Кошмар',
      text: `Кошмар появляется в облаке дыма, его грива, хвост и копыта объяты огнём. Это жуткое чёрное существо двигается со сверхъестественной скоростью, исчезая в облаке серы так же быстро, как появляясь.`,
      source: {
        id: SOURCE_MM,
        page: 178,
      },
    },
    {
      header: 'Скакун ужаса',
      text: `Кошмар, которого также называют «демонической» или «адской» лошадью, служит ездовым животным существам невероятного зла, перевозя на себе демонов, дьяволов, [рыцарей смерти](CREATURE:${CREATURE_DEATH_KNIGHT}), [личей](CREATURE:${CREATURE_LICH}), [ночных карг](CREATURE:${CREATURE_NIGHT_HAG}) и других злых чудовищ. Он похож на лошадь, но его огненно-красные глаза выдают в нём его недобрый интеллект.

Кошмара можно призвать из Нижних Планов, но если ему не принесена жертва в качестве еды при появлении, кошмар не демонстрирует особенной верности существу, которому служит.`,
      source: {
        id: SOURCE_MM,
        page: 178,
      },
    },
    {
      header: 'Создание кошмара',
      text: `Кошмар не появляется на свет естественным путём. Их делают из [пегасов](CREATURE:${CREATURE_PEGASUS}). Ритуал для создания кошмара включает в себя мучительное лишение [пегаса](CREATURE:${CREATURE_PEGASUS}) крыльев, и при этом их подвергают воздействию тёмной магии.`,
      source: {
        id: SOURCE_MM,
        page: 178,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 178,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 60,
    [SPEED_FLY]: 90,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 15,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  languageList: [
    {
      id: [LANG_INFERNAL, LANG_COMMON, LANG_ABYSSAL],
      doNotSpeak: true,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Дарование сопротивления к огню',
      description: `★СУЩЕСТВО★ может предоставить сопротивление к урону огнём всем, кто на нём едет.`,
    },
    {
      name: 'Свечение',
      description: `★СУЩЕСТВО★ испускает яркий свет в радиусе 10 футов и тусклый свет в пределах ещё 10 футов.`,
    },
  ],
  actionList: [
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Эфирный шаг',
      description: `★СУЩЕСТВО★, а также до трёх согласных существ в пределах 5 футов от него, магическим образом переходят на Эфирный План с Материального плана, или наоборот.`,
    },
  ],
  genderId: GENDER_MALE,
}
