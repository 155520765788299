const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_ENHANCE_ABILITY} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_ENHANCE_ABILITY,
  name: 'Улучшение характеристики',
  nameEn: `Enhance Ability`,
  description: `Вы касаетесь существа и улучшаете его при помощи магии. Выберите один из следующих эффектов; цель получает этот эффект, пока заклинание активно.

* **Бычья сила.** Цель совершает с преимуществом проверки Силы, а грузоподъёмность удваивается.
* **Кошачья ловкость.** Цель совершает с преимуществом проверки Ловкости. Кроме того, она не получает урон за падение с высоты 20 футов или меньше, если она дееспособна.
* **Лисья хитрость.** Цель совершает с преимуществом проверки Интеллекта.
* **Медвежья выносливость.** Цель совершает с преимуществом проверки Телосложения. Она также получает 2к6 временных хитов, которые исчезают в конце заклинания.
* **Орлиное великолепие.** Цель совершает с преимуществом проверки Харизмы.
* **Совиная мудрость.** Цель совершает с преимуществом проверки Мудрости.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше второго.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `мех или перо зверя`,
  duration: { timeId: TIME_HOUR, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 286,
    },
    {
      id: SOURCE_SRD,
      page: 139,
    },
  ],
}
