import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import searchByFilterList from '@/utils/searchByFilterList';
import trackAnalyticEvent from '@/utils/trackAnalyticEvent'

import TIME_LAG from './constants/TIME_LAG';
import ItemList from './components/ItemList'

import getWarningList from './utils/getWarningList';
import groupListByName from './utils/groupListByName';
import itemListFiltrator from './utils/itemListFiltrator';

import CatalogComponent from './CatalogComponent';

class CatalogContainer extends Component {
  static propTypes = {
    itemList: PropTypes.array,
    itemNameList: PropTypes.array,
    filterListOriginal: PropTypes.array,
    List: PropTypes.element,
    pageTitle: PropTypes.string,
    pageUrlGenerator: PropTypes.func,
  };

  static defaultProps = {
    itemList: [],
    itemNameList: [],
    filterListOriginal: [],
    List: ItemList,
    pageTitle: '',
    pageUrlGenerator: _.noop,
    Table: null,
  };

  state = {
    count: 0,
    filteredItemCollection: {},
    filteredItemList: [],
    filterList: this.props.filterListOriginal,
    hasWarnings: this.props.filterListOriginal.some(
      (filter) => (filter.children || [filter]).some(
        ({ list }) => list && list.some(
          ({ warning }) => warning
        )
      )
    ),
    isLoading: true,
    useTable: false,
    warningList: [],
  };

  componentDidMount() {
    this.updateList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterList } = this.state;

    if (filterList !== prevState.filterList) {
      this.doFilterList(false);
    }
  }

  updateWarningList = () => {
    const { filterList, hasWarnings } = this.state;

    if (hasWarnings) {
      this.setState({
        warningList: getWarningList(filterList),
      });
    }
  }

  onSearch = ({ name, value }) => {
    const { filterList } = this.state;

    const filterListUpdated = searchByFilterList({ name, value, filterList })

    this.setState({
      filterList: filterListUpdated,
    });
  };

  updateList = () => {
    const { filterList, isLoading } = this.state;
    const { itemList } = this.props;

    const filteredItemList = itemListFiltrator(itemList)(filterList)
    const filteredItemCollection = groupListByName(filteredItemList);

    this.updateWarningList();

    if (!filteredItemList.length) {
      trackAnalyticEvent('empty_search')
    }

    this.setState({
      filteredItemCollection,
      filteredItemList,
      count: filteredItemList.length,
      isLoading: isLoading && !filteredItemList.length,
    });
  };

  toggleUseTable = () => {
    const { useTable } = this.state;

    this.setState({ useTable: !useTable});
  };

  doFilterList = _.debounce(this.updateList, TIME_LAG);

  render() {
    const { pageTitle, pageUrlGenerator, List, Table, ...rest } = this.props;
    const { filteredItemCollection, filteredItemList, filterList, count, useTable, isLoading, warningList } = this.state;

    return (
      <CatalogComponent
        {...rest}
        filterList={filterList}
        onSearch={this.onSearch}
        itemCollection={filteredItemCollection}
        itemNotGroupedList={filteredItemList}
        List={List}
        pageUrlGenerator={pageUrlGenerator}
        pageTitle={pageTitle}
        count={count}
        toggleUseTable={this.toggleUseTable}
        useTable={useTable}
        Table={Table}
        isLoading={isLoading}
        warningList={warningList}
      />
    );
  }
}

export default CatalogContainer;
