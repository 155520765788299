const {gearByCatCollection} = require('./../../../../../gearList')

const gearCategoryList = require('./gearCategoryList')
const gearTypeExclude = require('./gearTypeExclude')

module.exports = gearCategoryList.reduce(
  (listByCats, catId) => [
    ...listByCats,
    ...gearByCatCollection[catId]
      .filter(
        gearId => gearId !== gearTypeExclude
      )
      .map(
        gearId => ({
          id: `adamantine_${gearId}`,
          gearId,
        })
      )
  ],
  []
)
