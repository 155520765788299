const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_GIFF} = require('./../../creatureTypeIdList')
const {giffDescriptionList} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_GIFF,
  nameEn: 'Giff',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'гифф',
      genitive: 'гиффа',
      dative: 'гиффу',
      accusative: 'гиффа',
      instrumental: 'гиффом',
      prepositional: 'гиффе',
    },
    plural: {
      nominative: 'гиффы',
      genitive: 'гиффов',
      dative: 'гиффам',
      accusative: 'гиффов',
      instrumental: 'гиффами',
      prepositional: 'гиффах',
    },
  },
  description: giffDescriptionList,
}
