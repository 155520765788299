const {
  SPELL_CURE_WOUNDS,
  SPELL_DAYLIGHT,
  SPELL_DEATH_WARD,
  SPELL_DETECT_MAGIC,
  SPELL_DISINTEGRATE,
  SPELL_SCRYING,
  SPELL_SUGGESTION,
} = require('./../../../../../spellIdList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {GOD_TIAMAT} = require('./../../../../../godIdList')
const {MAGIC_ITEM_ORB_OF_DRAGONKIND} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_ARTIFACT} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ORB_OF_DRAGONKIND,
  name: 'Око Дракона',
  nameEn: 'Orb of Dragonkind',
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_ARTIFACT,
  attunement: true,
  description: [
    `Много столетий назад, в мире Кринн эльфы и люди развязали ужасную войну со злыми драконами. Когда мир был уже практически обречён, волшебники Башен Высшего Волшебства объединились и с помощью самой могущественной магии создали пять _Очей Драконов_, чтобы победить своих врагов. Каждой из башен досталось своё _Око_, и с их помощью удалось одержать победу над драконами. Волшебники приманивали к себе драконов и уничтожали их мощной магией.

Когда позже Башни Высшего Волшебства пали, Очи либо были уничтожены, либо пропали, и считается, что осталось только три таких _Ока_. Их магия за столетия изменилась, и, несмотря на то, что они по-прежнему могут приманивать драконов, теперь они могут оказывать и другое воздействие.

Каждое _Око_ содержит сущность злого дракона, которая сопротивляется попыткам использовать её магию. Те, кто слабы духом, рискуют стать рабами _Ока_.

_Око_ это гравированный хрустальный шар примерно 25 сантиметров диаметром. Когда его используют, он увеличивается диаметром до полуметра и в нём начинает клубиться туман.

Будучи настроенным на _Око_, Вы можете действием заглянуть в его глубины и произнести командное слово. Затем Вы совершаете проверку Харизмы СЛ 15. В случае успеха Вы контролируете _Око_ всё время, пока настроены на него. При провале Вы становитесь очарованы _Оком_, пока Вы настроены на него.

Пока Вы очарованы _Оком_, Вы не можете добровольно окончить настройку на него, и _Око_ может неограниченно сотворять на Вас [Внушение](SPELL:${SPELL_SUGGESTION}) (СЛ испытания 18), побуждая Вас совершать злые поступки. Сущность дракона в Оке может желать разного: уничтожения конкретного народа, освобождения из _Ока_, распространения страданий по миру, распространения поклонения Такхизис (имя [Тиамат](GOD:${GOD_TIAMAT}) на Кринне), или чего-то ещё, на усмотрение Мастера.`,
    {
      header: 'Случайные свойства',
      text: `_Око Дракона_ обладает несколькими свойствами, определяемыми случайным образом:

* 2 малых положительных свойства
* 1 малое отрицательное свойство
* 1 основное отрицательное свойство`,
    },
    {
      header: 'Заклинания',
      text: `У Ока есть 7 зарядов, и оно восстанавливает 1к4 + 3 заряда на рассвете. Если Вы контролируете _Око_, Вы можете действием потратить 1 или несколько зарядов, чтобы наложить им одно из следующих заклинаний (СЛ испытания 18):

* [Дневной свет](SPELL:${SPELL_DAYLIGHT}) (1 заряд),
* [Защита от смерти](SPELL:${SPELL_DEATH_WARD}) (2 заряда),
* [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}) (версия 5 уровня, 3 заряда),
* [Наблюдение](SPELL:${SPELL_SCRYING}) (3 заряда),
* [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}) (не тратя заряды).`,
    },
    {
      header: 'Зов драконов',
      text: `Если Вы контролируете _Око_, Вы можете действием заставить артефакт испустить телепатический зов, распространяющийся на 40 миль. Злые драконы в пределах этой дистанции вынуждены лететь к _Оку_ кратчайшим маршрутом. Божества-драконы, такие как [Тиамат](GOD:${GOD_TIAMAT}), не попадают под действие этого зова. Прилетевшие драконы могут быть враждебны к Вам, за то, что Вы принудили их. Использовав это свойство, Вы не можете повторно использовать его 1 час.`,
    },
    {
      header: 'Уничтожение Ока',
      text: `_Око Дракона выглядит_ хрупким, но оно не подвержено практически никакому урону, включая атаки и дыхание драконов. Однако _Око_ можно уничтожить заклинанием [Распад](SPELL:${SPELL_DISINTEGRATE}) или одним попаданием магического оружия +3.`,
    },
  ],
  genderId: GENDER_MIDDLE,
  spellIdList: [
    SPELL_CURE_WOUNDS,
    SPELL_DAYLIGHT,
    SPELL_DEATH_WARD,
    SPELL_DETECT_MAGIC,
    SPELL_SCRYING,
  ],
  source: {
    id: SOURCE_DMG,
    page: 226,
  },
}
