const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_11,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ELEMENTAL,
  } = require('./../../../../creatureTypeIdList'),
  {
    GEAR_TRIDENT,
  } = require('./../../../../gearIdList'),
  {
    ALIGNMENT_CN,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_SWIM,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_AQUAN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_CONJURE_ELEMENTAL,
    SPELL_CONTROL_WEATHER,
    SPELL_CREATE_OR_DESTROY_WATER,
    SPELL_DETECT_EVIL_AND_GOOD,
    SPELL_DETECT_MAGIC,
    SPELL_FOG_CLOUD,
    SPELL_GASEOUS_FORM,
    SPELL_INVISIBILITY,
    SPELL_PLANE_SHIFT,
    SPELL_PURIFY_FOOD_AND_DRINK,
    SPELL_TONGUES,
    SPELL_WATER_BREATHING,
    SPELL_WATER_WALK,
  } = require('./../../../../spellIdList'),
  {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    geniusDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_AMPHIBIOUS,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_DAO,
    CREATURE_DJINNI,
    CREATURE_EFREETI,
    CREATURE_MARID,
    CREATURE_WATER_ELEMENTAL,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Марид',
  nameEn: 'Marid',
  id: CREATURE_MARID,
  description: [
    {
      header: 'Мариды',
      text: `Живущие на Стихийном Плане Воды мариды — наиболее удивительные из гениев. Несмотря на то, что все гении обладают великой силой, даже слабейший из маридов считает себя сильнее ветреного [джинна](CREATURE:${CREATURE_DJINNI}), приземлённого [дао](CREATURE:${CREATURE_DAO}) и дымящегося [ифрита](CREATURE:${CREATURE_EFREETI}).

Огромные и рыбоподобные, мариды представляют собой странное зрелище в своих жилетах и ярко окрашенных шароварах. Их голоса бывают то мягкими, как дыхание морского бриза, то неистовыми и громоподобными, как штормовые волны, разбивающиеся о скалы. В полёте их тела обращаются в столбы пенистой воды.`,
      source: {
        id: SOURCE_MM,
        page: 53,
      },
    },
    {
      header: 'Повелители воды',
      text: `Вода — родная стихия маридов, и эти гении властны творить с ней всё, что пожелают. Марид может ходить по воде и дышать, находясь под водой. Он может создать воду или сформировать облако пара или туман из влаги, присутствующей в воздухе. Он может даже сам превратиться в туман или обратить мощь воды против своих врагов.`,
      source: {
        id: SOURCE_MM,
        page: 53,
      },
    },
    {
      header: 'Дома маридов',
      text: `Мариды редки на Материальном плане. Они обитают в могучих магических коралловых крепостях в Стихийном Плане Воды. Их цитадели дрейфуют в глубинах вод, но в них есть отдельные залы с воздухом, где могут разместиться рабы или гости.

Мариды не требуют многого от своих рабов, но желают обладать ими просто ради положения в обществе. Мариды покидают свои пределы, дабы захватить людей, искусных в ремёслах или искусствах, шутов или рассказчиков, и обращают их в рабов при своём дворе.`,
      source: {
        id: SOURCE_MM,
        page: 53,
      },
    },
    {
      header: 'Иерархия тщеславия',
      text: `Все мариды претендуют на благородные титулы, и меж собой в высоте звания соревнуются шахи, султаны, муфтии и хедивы. Обычно титулы маридов не значат ничего, кроме степени высокомерия носящих их.

Мариды свысока смотрят на всех прочих — даже на других гениев — как на низших в различной степени существ, от «бедных родственников» до тех, от кого можно ждать лишь беды. Они терпят джиннов, не любят дао, и ненавидят ифритов.

Гуманоиды — это последние из существ, которых мариды готовы терпеть, но иногда они согласны заключать сделки с могучими волшебниками и властителями почти как с равными. Иногда это бывает ошибкой, ведь волшебники заключают маридов в морские раковины, кувшины и фляги. Сладкие речи и богатые дары — вот каким языком следует обращаться к мариду ничтожным смертным, знающим своё место.`,
      source: {
        id: SOURCE_MM,
        page: 53,
      },
    },
    {
      header: 'Своенравные рассказчики',
      text: `Мариды — непревзойдённые рассказчики, чьи излюбленные легенды прославляют доблесть всего рода маридов и, разумеется, самого рассказчика. Прихотливые и чудаковатые, они лгут часто и искусно. Они не всегда намеренно лгут, зачастую они лишь желают приукрасить свой рассказ. Мариды сочтут преступлением попытку низшего существа прервать их рассказ, а оскорбление марида — это верный способ обратить его гнев против себя.`,
      source: {
        id: SOURCE_MM,
        page: 53,
      },
    },
    ...geniusDescriptionList,
  ],
  note: {
    text: `Марид вытек из фляги, как вода, и сказал: „Твоё желание — закон для меня“. Полурослик, вне себя от радости, пожелал бессмертия, и марид превратил его в рыбу, а несчастный полурослик трепыхался на потеху мариду, пока не задохнулся. Эта поучительная история пережила века, так что, возможно, желание того полурослика всё же исполнилось`,
    author: `Кесто Ясноглазый, владелец книжного магазина «Приоткрытая пелена» в Сигиле`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 53,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 60,
    [SPEED_SWIM]: 90,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 26,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_AQUAN,
  ],
  cr: CR_11,
  featureList: [
    ABILITY_AMPHIBIOUS,
    {
      name: 'Стихийная гибель',
      description: `Если ★СУЩЕСТВО★ умирает, ★его★ тело распадается во взрыве воды и пены, оставляя только снаряжение, которое ★СУЩЕСТВО★ ★носил★ и ★нёс★.`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_EVIL_AND_GOOD,
          SPELL_DETECT_MAGIC,
          SPELL_PURIFY_FOOD_AND_DRINK,
          SPELL_CREATE_OR_DESTROY_WATER,
          SPELL_FOG_CLOUD,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_WATER_BREATHING,
          SPELL_WATER_WALK,
          SPELL_TONGUES,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONTROL_WEATHER,
          SPELL_GASEOUS_FORM,
          SPELL_INVISIBILITY,
          {
            id: SPELL_CONJURE_ELEMENTAL,
            comment: `только [водный элементаль](CREATURE:${CREATURE_WATER_ELEMENTAL})`,
          },
          SPELL_PLANE_SHIFT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Трезубцем_.`,
    },
    {
      name: 'Трезубец',
      // gearId: GEAR_TRIDENT, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_PIERCING,
              diceCount: 2,
              diceType: 6,
              diceBonus: 6,
            },
            {
              type: DAMAGE_PIERCING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 6,
              use2Hand: true,
            },
          ],
        ],
      },
    },
    {
      name: 'Трезубец',
      // gearId: GEAR_TRIDENT, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 10,
        range: {
          normal: 20,
          max: 60,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Струя воды',
      description: `★СУЩЕСТВО★ магическим образом испускает струю воды 60-футовой линией шириной 5 футов. Все существа в этой линии должны пройти испытание Ловкости СЛ 16. При провале цель получает дробящий урон 21 (6к6) и, если её размер не больше Огромного, толкается на расстояние до 20 футов от ★СУЩЕСТВО★ и сбивается с ног. При успехе цель получает половину дробящего урона, но не толкается и не сбивается с ног.`,
    },
  ],
  genderId: GENDER_MALE,
}
