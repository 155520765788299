const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_XVART} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_XVART,
  nameEn: 'Xvart',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'ксварт',
      genitive: 'ксварта',
      dative: 'ксварту',
      accusative: 'ксварта',
      instrumental: 'ксвартом',
      prepositional: 'ксварте',
    },
    plural: {
      nominative: 'ксварты',
      genitive: 'ксвартов',
      dative: 'ксвартам',
      accusative: 'ксвартов',
      instrumental: 'ксвартами',
      prepositional: 'ксвартах',
    },
  },
}
