const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_GHAST,
  CREATURE_GHOUL,
  CREATURE_ORCUS,
} = require('./../../../../creatureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../../../sourceList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_TYPE_UNDEAD} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: `Вурдалак`,
  nameEn: 'Ghast',
  id: CREATURE_GHAST,
  description: [
    {
      header: 'Вурдалаки',
      text: `[Оркус](CREATURE:${CREATURE_ORCUS}) иногда вливает в [упыря](CREATURE:${CREATURE_GHOUL}) больше энергии Бездны, превращая его в [вурдалака](CREATURE:${CREATURE_GHAST}). [Упыри](CREATURE:${CREATURE_GHOUL}) немногим умнее диких зверей, а [вурдалак](CREATURE:${CREATURE_GHAST}) хитёр и может заставить группу [упырей](CREATURE:${CREATURE_GHOUL}) выполнять его команды.`,
      source: {
        id: SOURCE_MM,
        page: 288,
      },
    },
    {
      header: 'Вурдалак',
      text: `[Оркус](CREATURE:${CREATURE_ORCUS}), бог смерти, вливает в самолично отобранных [упырей](CREATURE:${CREATURE_GHOUL}) смертельную дозу своей демонической энергии, и так получаются вурдалаки. Если [упыри](CREATURE:${CREATURE_GHOUL}) — это не более чем свирепые дикие звери, то вурдалаки хитры, и один вурдалак способен подчинить своей воле целую стаю [упырей](CREATURE:${CREATURE_GHOUL}).`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 288,
  },
  armor: 13,
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 10,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  resistanceList: [
    DAMAGE_NECROTIC,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_POISONED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_2,
  featureList: [
    {
      name: `Вонь`,
      description: `Все существа, начинающие ход в пределах 5 футов от ★СУЩЕСТВО★, должны пройти испытание Телосложения СЛ 10, иначе станут отравленными до начала своего следующего хода. При успешном испытании существо получает иммунитет к _Вони_ ★этого★ ★СУЩЕСТВО★ на 24 часа.`,
    },
    {
      name: `Отрицание изгнания`,
      description: `★СУЩЕСТВО★ и все [упыри](CREATURE:${CREATURE_GHOUL}) в пределах 30 футов от него с преимуществом проходят испытания от эффектов, изгоняющих нежить.`,
    },
  ],
  actionList: [
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: `Когти`,
      description: `Если цель — существо, не являющееся нежитью, она должна пройти испытание Телосложения СЛ 10, иначе станет парализованной на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
