const {SOURCE_VGTM} = require ('./../../sourceList')
const {CREATURE_SPAWN_OF_KYUSS} = require ('./../../creatureIdList')
const {SPELL_POLYMORPH} = require ('./../../spellIdList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GOBLIN,
  PC_RACE_HOBGOBLIN,
  PC_RACE_HUMAN,
  PC_RACE_KOBOLD,
  PC_RACE_ORC,
  PC_RACE_TIEFLING,
} = require ('./../../pcRaceIdList')
const {
  PC_CLASS_WIZARD,
  PC_CLASS_WARLOCK,
  PC_CLASS_PALADIN,
  PC_CLASS_SORCERER,
  PC_CLASS_RANGER,
} = require ('./../../pcClassIdList')

module.exports = [
  {
    header: 'Монстры как искатели приключений',
    text: `В некоторых кампаниях гуманоиды, обычно рассматривающиеся как злая угроза, могут отправиться в приключения вместе бок о бок с любыми другими стандартными расами.
  
Этот раздел нацелен на Мастеров, которые хотят расширить выбор рас для их кампаний за пределы обычного набора народов D&D.

# Почему персонаж-монстр?

Создание персонажа — существа, которое обычно используют в качестве злодеев, предоставляет некоторые интересные возможности для отыгрыша. Отыгрываемый ли для комедийного эффекта, или как трагическая история предательства и утраты, или как антигерой, персонаж-монстр дает игроку шанс поставить перед собой необычную задачу в кампании.

Прежде, чем разрешить персонажей-монстров в Вашей кампании, рассмотрите следующие три вопроса.

# Редкость или обыденность?

Решите, насколько обычны [орки](PC_RACE:${PC_RACE_ORC}), [гоблины](PC_RACE:${PC_RACE_GOBLIN}), и им подобные в качестве приключенцев в Вашем сеттинге. Расцениваются ли они как не более необычные, чем [эльфы](PC_RACE:${PC_RACE_ELF}) или [дварфы](PC_RACE:${PC_RACE_DWARF})? Встречают ли их с подозрением? Роль, которую эта раса играет в Вашем сеттинге, должна определить реакцию, с которой встречают таких персонажей.

Не бойтесь доводить до крайности. Персонажу-[орку](PC_RACE:${PC_RACE_ORC}), возможно, придется замаскироваться в городе или остаться в дикой местности, из страха перед заключением в темнице или яростью толпы. Обязательно поговорите с группой о том, какой реакции такие персонажи могут ожидать от мира. Некоторым игрокам нравится играть изгоями, но, если Вы договорились с игроком об одном, не подсовывайте ему другого.

Вы можете решить, что в Вашей кампании существа монстры — это просто ещё одна культура, у которой есть как союзы, так и соперничество с [людьми](PC_RACE:${PC_RACE_HUMAN}), [эльфами](PC_RACE:${PC_RACE_ELF}) и [дварфами](PC_RACE:${PC_RACE_DWARF}). Королевство [хобгоблинов](PC_RACE:${PC_RACE_HOBGOBLIN}) может быть буфером между королевством людей и зараженной областью, захваченной [порождениями Кьюсса](CREATURE:${CREATURE_SPAWN_OF_KYUSS}). [Кобольды](PC_RACE:${PC_RACE_KOBOLD}) могут быть строителями городов, архитекторами хорошо укрепленных, грандиозных сооружений, в которых за плату живут другие народы.

Культурные примечания — стандартное для D&D описание этих существ, но это не значит, что их надо неукоснительно придерживаться в Вашей кампании. Используйте их в качестве отправной точки для ваших собственных идей.

# Изгой или посол?

Рассмотрите, как родная культура персонажа–монстра относится к нему. Персонаж — изгой, шпион, посол, или что-то ещё? Поговорите с игроком, чтобы определить, как персонаж пришел к тому чтобы стать приключенцем.

Привязанности персонажа — хорошая отправная точка для рассмотрения этого вопроса. Как привязанность сподвигла персонажа на приключение? Особенности персонажа, его слабости и идеалы также могут также играть роль в придумывании его истории.

# Друзья или враги?

Придумайте, что особенное связывает персонажа с другими членами отряда. [Орочий](PC_RACE:${PC_RACE_ORC}) [колдун](PC_CLASS:${PC_CLASS_WARLOCK}) мог бы быть заклятым врагом [следопыта](PC_CLASS:${PC_CLASS_RANGER})-[дварфа](PC_RACE:${PC_RACE_DWARF}), но эти двое вынуждены сотрудничать, чтобы победить общего противника. Возможно, [кобольд](PC_RACE:${PC_RACE_KOBOLD})-[чародей](PC_CLASS:${PC_CLASS_SORCERER}) был фамильяром [тифлинга](PC_RACE:${PC_RACE_TIEFLING})-[волшебника](PC_CLASS:${PC_CLASS_WIZARD}), и был превращен разозлившимся архимагом в отместку за мелкое оскорбление. [Паладин](PC_CLASS:${PC_CLASS_PALADIN})-[хобгоблин](PC_RACE:${PC_RACE_HOBGOBLIN}), возможно, был [человеком](PC_RACE:${PC_RACE_HUMAN}), но однажды перешел дорогу злой ведьме и был проклят, получив ужасный облик.

Креативная связь между персонажем-монстром и остальными членами отряда помогает сделать компанию незабываемой.`,
    source: {
      id: SOURCE_VGTM,
      page: 120,
    },
  },
  {
    header: 'Происхождение монстра',
    text: `Таблица предлагает несколько идей для персонажа-монстра, добавляемого в кампанию.

| к8 | Происхождение |
|-|-|
| 1  | Вы — шпион, посланный, чтобы уничтожить врагов изнутри. |
| 2  | Вы — жертва проклятия или заклинания [Превращение](SPELL:${SPELL_POLYMORPH}). |
| 3  | Вы были воспитаны [людьми](PC_RACE:${PC_RACE_HUMAN}), [эльфами](PC_RACE:${PC_RACE_ELF}), или [дварфами](PC_RACE:${PC_RACE_DWARF}) и приняли их культуру. |
| 4  | В юном возрасте Вы приняли религию [людей](PC_RACE:${PC_RACE_HUMAN}), и теперь искренне ей служите. |
| 5  | Вы получили божественное видение, которое направило Вас на этот путь, и иногда Вы получаете новые видения, которые Вас направляют. |
| 6  | Ваш заклятый враг — союзник Вашего народа, это вынудило Вас оставить Ваше племя ради мести. |
| 7  | Злая сущность развратила общество Вашего народа. |
| 8  | Травма или необычное событие привели к полной потере памяти о своём прошлом, но иногда к Вам приходят обрывки памяти. |
`,
    source: {
      id: SOURCE_VGTM,
      page: 120,
    },
  },
]
