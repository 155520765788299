const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ANIMAL_MESSENGER,
  SPELL_AWAKEN,
  SPELL_CONJURE_ANIMALS,
  SPELL_DRUIDCRAFT,
  SPELL_FOG_CLOUD,
  SPELL_GUIDANCE,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_ICE_STORM,
  SPELL_MOONBEAM,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_RESISTANCE,
  SPELL_SLEET_STORM,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_WIND_WALL,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_FLY,
  SPEED_WALK,
  SPEED_DIG,
  SPEED_CLIMB,
} = require('./../../../../speedList')
const {
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  DAMAGE_COLD,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_AWAKENED_SHRUB,
  CREATURE_AWAKENED_TREE,
  CREATURE_FOX,
  CREATURE_FROST_DRUID,
  CREATURE_GOAT,
  CREATURE_OWL,
  CREATURE_POLAR_BEAR,
  CREATURE_REINDEER,
  CREATURE_WOLF,
} = require('./../../../../creatureIdList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_COMMON,
  LANG_DRUIDIC,
} = require('./../../../../languageIdList')
const {
  GEAR_HIDE_ARMOR,
  GEAR_SICKLE,
} = require('./../../../../gearIdList')
const {ABILITY_CHANGE_SHAPE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {PC_CLASS_DRUID} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_IDRotF} = require('./../../../../sourceList')

module.exports = {
  name: 'Друид мороза',
  nameAlt: 'Морозный друид',
  nameEn: 'Frost Druid',
  id: CREATURE_FROST_DRUID,
  description: [
    `Друиды мороза — одинокие защитники природы и природных врагов цивилизации на Севере. Они ищут способы сохранить северную глушь, уничтожая встречных чужаков. Они обходят свою территорию в облике [песца](CREATURE:${CREATURE_FOX}), [горной козы](CREATURE:${CREATURE_GOAT}), [полярной совы](CREATURE:${CREATURE_OWL}) или [волка](CREATURE:${CREATURE_WOLF}), обращаясь в человеческий облик только при нападении. Друиды мороза мастерски устраивают засады заклинанием [Мираж](SPELL:${SPELL_HALLUCINATORY_TERRAIN}). Им они создают иллюзорные сугробы и прячутся в них, и скрывают покрытые тонким льдом реки, заманивая своих жертв на хрупкий лёд.`,
    {
      header: 'Пробуждённые спутники',
      text: `Друида мороза часто сопровождают один или несколько пробуждённых им заклинанием [Пробуждение разума](SPELL:${SPELL_AWAKEN}) зверей, [кустов](CREATURE:${CREATURE_AWAKENED_SHRUB}) или [хвойных деревьев](CREATURE:${CREATURE_AWAKENED_TREE}). Особенно друиды предпочитают выбирать спутниками [белых медведей](CREATURE:${CREATURE_POLAR_BEAR}) и [северных оленей](CREATURE:${CREATURE_REINDEER}), и эти звери обычно разделяют планы друида.`,
      source: {
        id: SOURCE_IDRotF,
        page: 290,
      },
    },
    {
      header: 'Ледяной серп',
      text: `Друид мороза может вырезать себе серп изо льда, потратив на эту работу 24 часа. В его руках это оружие наполняется кусачим холодом. Если друид умирает, его серп растекается талой водой. В остальном такой серп идентичен [обычному серпу](GEAR:${GEAR_SICKLE}).`,
      source: {
        id: SOURCE_IDRotF,
        page: 290,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_IDRotF,
    page: 289,
  },
  armor: {
    ac: 13,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: {
      value: 30,
      comment: '40 фт. в облике волка',
    },
    [SPEED_DIG]: {
      value: 5,
      comment: 'только в облике песца',
    },
    [SPEED_CLIMB]: {
      value: 30,
      comment: 'только в облике козы',
    },
    [SPEED_FLY]: {
      value: 60,
      comment: 'только в облике совы',
    },
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 9,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_NATURE]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
      comment: 'только в облике зверя',
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DRUIDIC,
  ],
  cr: CR_5,
  spellCast: {
    comment: 'только в облике гуманоида',
    spellCasterLevel: 9,
    spellCasterClass: PC_CLASS_DRUID,
    spellIdList: [
      SPELL_ANIMAL_FRIENDSHIP,
      SPELL_ANIMAL_MESSENGER,
      SPELL_AWAKEN,
      SPELL_CONJURE_ANIMALS,
      SPELL_DRUIDCRAFT,
      SPELL_FOG_CLOUD,
      SPELL_GUIDANCE,
      SPELL_HALLUCINATORY_TERRAIN,
      SPELL_ICE_STORM,
      SPELL_MOONBEAM,
      SPELL_PASS_WITHOUT_TRACE,
      SPELL_RESISTANCE,
      SPELL_SLEET_STORM,
      SPELL_SPEAK_WITH_ANIMALS,
      SPELL_WIND_WALL,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      1,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашных атаки.`,
    },
    {
      name: 'Ледяной серп',
      gearId: GEAR_SICKLE,
      comment: 'только в облике гуманоида',
      attack: {
        hit: {
          type: DAMAGE_COLD,
          diceCount: 2,
          diceType: 4,
        },
      },
    },
    {
      name: 'Терзание',
      comment: 'только в облике зверя',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      id: ABILITY_CHANGE_SHAPE,
      nextForm: `зверя — песца, горную козу, сову или волка`,
      gearMerge: `сливается с новым обликом или переносится им (на ★его★ выбор)`,
      statChange: `★Его★ статистика одинакова во всех обликах, кроме указанных в этом статблоке различий`,
    },
  ],
}
