const {SOURCE_MM} = require('./../../sourceList')
const {
  GOD_MERRSHAULK,
  GOD_SSETH,
} = require('./../../godIdList')

module.exports = [
  {
    header: 'Ссет, Шипящая Смерть',
    text: `[Ссет](GOD:${GOD_SSETH}) явился к юань-ти древности в форме крылатого юань-ти, который называл себя воплощением [Мершолка](GOD:${GOD_MERRSHAULK}). Говоря голосом [Мершолка](GOD:${GOD_MERRSHAULK}), [Ссет](GOD:${GOD_SSETH}) поклялся вытащить юань-ти из упадка и построить новую империю. Многие поклонявшиеся [Мершолку](GOD:${GOD_MERRSHAULK}) после этого стали последователями Ссета.

Некоторые юань-ти уже давно подозревают, что [Ссет](GOD:${GOD_SSETH}) — узурпатор, который воспользовался сном [Мершолка](GOD:${GOD_MERRSHAULK}), чтобы сделать себя богом. Они думают, что [Ссет](GOD:${GOD_SSETH}) мог даже поглотить [Мершолка](GOD:${GOD_MERRSHAULK}), и теперь он отвечает на молитвы последователей [Мершолка](GOD:${GOD_MERRSHAULK}), а его жрецы переманивают в свою веру или пожирают наиболее упорных поклонников старого бога.`,
    source: {
      id: SOURCE_MM,
      page: 315,
    },
  },
]
