const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  CREATURE_DRAGONSOUL_BLACK,
  CREATURE_DRAGONSOUL_BLUE,
  CREATURE_DRAGONSOUL_GREEN,
  CREATURE_DRAGONSOUL_RED,
  CREATURE_DRAGONSOUL_WHITE,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ROT,
} = require('./../../../../sourceList')
const {
  CR_7,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_DECEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  GEAR_SHORTSWORD,
  GEAR_STUDDED_LEATHER_ARMOR,
} = require('./../../../../gearIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_DRAGON_FANATIC,
  ABILITY_FANATIC_ADVANTAGE,
  ABILITY_LIMITED_FLIGHT,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  cultOfDragonDescriptionList,
} = require('./../../../../textCommonParts')

module.exports = [
  {
    namePartRu: 'белая',
    namePartEn: 'White',
    id: CREATURE_DRAGONSOUL_WHITE,
    damageType: DAMAGE_COLD,
  },
  {
    namePartRu: 'зелёная',
    namePartEn: 'Green',
    id: CREATURE_DRAGONSOUL_GREEN,
    damageType: DAMAGE_POISON,
  },
  {
    namePartRu: 'красная',
    namePartEn: 'Red',
    id: CREATURE_DRAGONSOUL_RED,
    damageType: DAMAGE_FIRE,
  },
  {
    namePartRu: 'синяя',
    namePartEn: 'Blue',
    id: CREATURE_DRAGONSOUL_BLUE,
    damageType: DAMAGE_ELECTRICITY,
  },
  {
    namePartRu: 'чёрная',
    namePartEn: 'Black',
    id: CREATURE_DRAGONSOUL_BLACK,
    damageType: DAMAGE_ACID,
  },
]
  .map(
    (
      {
        namePartRu,
        namePartEn,
        id,
        damageType,
      }
    ) => ({
      name: `Душа дракона, ${namePartRu}`,
      nameEn: `Dragonsoul, ${namePartEn}`,
      id,
      description: cultOfDragonDescriptionList,
      sizeType: SIZE_MEDIUM,
      creatureTypeIdList: [
        CREATURE_TYPE_HUMANOID,
        CREATURE_TYPE_HUMAN,
      ],
      alignmentId: ALIGNMENT_NE,
      source: {
        id: SOURCE_ROT,
        page: 92,
      },
      armor: {
        ac: 16,
        gearId: GEAR_STUDDED_LEATHER_ARMOR,
      },
      hp: {
        diceCount: 17,
      },
      speed: {
        [SPEED_WALK]: 30,
      },
      params: {
        [PARAM_STR]: 11,
        [PARAM_DEX]: 18,
        [PARAM_CON]: 14,
        [PARAM_INT]: 13,
        [PARAM_WIT]: 12,
        [PARAM_CHA]: 16,
      },
      saveThrowList: [
        PARAM_WIT,
      ],
      skillCollection: {
        [SKILL_DECEPTION]: PROF_NORMAL,
        [SKILL_STEALTH]: PROF_NORMAL,
      },
      resistanceList: [
        damageType,
      ],
      languageList: [
        LANG_COMMON,
        LANG_DRACONIC,
        LANG_INFERNAL,
      ],
      cr: CR_7,
      featureList: [
        ABILITY_DRAGON_FANATIC,
        {
          id: ABILITY_FANATIC_ADVANTAGE,
          damage: '10 (3к6)',
        },
        ABILITY_LIMITED_FLIGHT,
        ABILITY_PACK_TACTICS,
      ],
      actionList: [
        {
          name: 'Мультиатака',
          description: `★СУЩЕСТВО★ совершает две атаки _Коротким мечом_.`,
        },
        {
          gearId: GEAR_SHORTSWORD,
          attack: {
            hit: {
              type: damageType,
              diceCount: 3,
              diceType: 6,
            },
          },
        },
        {
          name: 'Шар драконьего дыхания',
          limit: {
            count: 3,
            period: 'день',
          },
          attack: {
            type: ATTACK_RANGE_WEAPON,
            bonus: 5,
            range: 90,
            hit: {
              type: damageType,
              diceCount: 6,
              diceType: 8,
            },
          },
        },
      ],
      genderId: GENDER_FEMALE,
    })
  )
