const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_DINOSAUR,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ABILITY_PACK_TACTICS} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_VELOCIRAPTOR} = require('./../../../../creatureIdList')
const {dinoDescriptionList} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Велоцираптор',
  nameEn: 'Velociraptor',
  id: CREATURE_VELOCIRAPTOR,
  description: [
    `Этот пернатый динозавр размером с большую индейку. Это агрессивный хищник и часто охотится стаями на большую добычу.`,
    ...dinoDescriptionList,
  ],
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
    CREATURE_TYPE_DINOSAUR,
  ],
  alignmentId: ALIGNMENT_NO,
  source: [
    {
      id: SOURCE_VGTM,
      page: 150,
    },
    {
      id: SOURCE_TOA,
      page: 217,
    },
  ],
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 6,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  cr: CR_1_4,
  featureList: [
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Велоцираптор делает две атаки: одну _Укусом_ и одну _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
}
