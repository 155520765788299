const {
  CREATURE_GUARD_DRAKE,
  CREATURE_GUARD_DRAKE_BLACK,
} = require('./../../../../creatureIdList')
const {ABILITY_AMPHIBIOUS} = require('./../../../../creatureAbilityList')
const {DAMAGE_ACID} = require('./../../../../damageTypeList')
const {SPEED_SWIM} = require('./../../../../speedList')

module.exports = {
  name: 'Чёрный сторожевой дрейк',
  nameEn: 'Black Guard Drake',
  id: CREATURE_GUARD_DRAKE_BLACK,
  parentId: CREATURE_GUARD_DRAKE,
  extendPropCollection: {
    speed: {
      [SPEED_SWIM]: 30,
    },
    resistanceList: [
      DAMAGE_ACID,
    ],
    featureList: [
      ABILITY_AMPHIBIOUS,
    ],
  },
}
