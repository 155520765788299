module.exports = [
  {
    text: `Постарайтесь не обидеться, когда дракон сочтёт Вас неуместным. Помните, что Вы, возможно, имеете дело с существом, которое родилось три цивилизации назад. И не у каждого дракона хватит мудрости увидеть, как одинокие герои могут изменить ход истории.`,
    author: `Физбан`,
  },
  {
    text: `Чтобы изобразить убедительного человека, нужно пробудить в себе жадность, эгоистичность и бдительность. Чтобы изобразить убедительного дракона, нужно просто расслабиться.`,
    author: `Физбан`,
  },
  `Помните, что драконы не как люди. Даже самые добрые из них могут быть самодовольными, самовлюблёнными, высокомерными, надменными и склонными к насилию… Ладно, возможно они прямо как люди`,
  {
    text: `Драконы — как ящерицы. Они существовали задолго до гуманоидов и будут существовать ещё долго после них. Они могут манипулировать тканью реальности и меняют законы природы! Ах нет, не как ящерицы… Как магия.`,
    author: `Физбан`,
  },
  {
    text: `Большинство драконов сложно назвать набожными. Ведь, для почитания религии, нужно считать что тебе нужна помощь`,
    author: `Физбан`,
  },
  {
    text: `Драконы не живут в Вашем мире. Вы живёте в их.`,
    author: `Физбан`,
  },
]
