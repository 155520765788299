const {
  GOD_THRYM,
  GOD_VAPRAK,
} = require('./../../godIdList')
const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')
const {
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  GEAR_GREATSWORD,
  GEAR_STAFF,
} = require('./../../gearIdList')
const {
  CREATURE_FROST_GIANT_EVERLASTING_ONE,
  CREATURE_GRIFFON,
  CREATURE_MAMMOTH,
  CREATURE_MANTICORE,
  CREATURE_OGRE,
  CREATURE_POLAR_BEAR,
  CREATURE_REMORHAZ,
  CREATURE_TROLL,
  CREATURE_WINTER_WOLF,
  CREATURE_YOUNG_REMORHAZ,
} = require('./../../creatureIdList')
const giantDescriptionList = require('./giant')
const godThrymOnlyDescriptionList = require('./godThrymOnly')

module.exports = [
  {
    header: 'Ледяные великаны',
    text: `Ледяные великаны — громадные грабители с морозных земель, что лежат за гранью цивилизации — это жестокие, выносливые воины, живущие за счёт добычи с набегов и грабежей. Они уважают лишь грубую силу и боевые навыки, демонстрируя их шрамами и отвратительными трофеями, полученными от врагов.`,
    source: {
      id: SOURCE_MM,
      page: 34,
    },
  },
  {
    header: 'Ледяные сердца',
    text: `Ледяные великаны это существа льда и снега. Их волосы и бороды бледно-белые или светло-голубые, скованные морозом и гремящие сосульками. Их плоть синяя, словно ледник.

Ледяные великаны живут на высоких пиках и в ледяных ущельях, где золотая голова солнца скрыта зимой. Зерновые не прорастают в их морозных селениях, а скота, по сравнению с тем, что захватывают в налётах на цивилизованные земли, держат мало. Они охотятся за дичью в тундре и горах, но не готовят её, так как мясо только что убитой добычи слишком горячо на их вкус.`,
    source: {
      id: SOURCE_MM,
      page: 34,
    },
  },
  {
    header: 'Грабители во вьюге',
    text: `Боевые рога ледяных великанов ревут, когда они выдвигаются из своих ледяных крепостей и ледниковых разломов во время завывающей бури. Когда же буря утихает, деревни и фермы лежат в руинах, а вороны слетаются пировать на трупах тех, кто был достаточно глуп или не везуч, чтобы встать на пути великанов.

Постоялые дворы и таверны больше всего страдают от причинённого урона — их подвалы пусты, и бочек эля и мёда больше нет. Кузнецы тоже готовы закрыться — железо и сталь забрали. К удивлению, не потревоженными остаются дома ростовщиков и богатых горожан — для этих грабителей мало проку от монет и безделушек. Ледяные великаны ценят лишь драгоценности и украшения, достаточно большие, чтобы их можно было носить или чтобы их хотя бы было видно. Однако даже такие сокровища чаще всего сохраняют для торга с другими великанами, более искусными в создании металлического оружия и доспехов.`,
    source: {
      id: SOURCE_MM,
      page: 34,
    },
  },
  {
    header: 'Правящие силой',
    text: `Ледяные великаны уважают грубую силу больше чего-либо ещё, и место в иерархии ледяных великанов основывается на признаках физической мощи, таких как превосходная мускулатура, шрамы из знаменитых сражений или трофеи из тел поверженных врагов. Задания, вроде охоты, ухода за детьми и ремёсла, дают великанам сообразно их силе и выносливости.

Если встречаются ледяные великаны различных кланов, и статус их неясен, они борются за господство. Такие встречи похожи на фестивали, где великаны восхваляют своих героев, бесстыдно хвастаются и бросают друг другу вызовы. Бывает и так, что неофициальная церемония становится хаотичной схваткой, в которой оба клана дерутся, валя деревья, разбивая лёд на замёрзших озёрах и вызывая лавины на заснеженных горах.`,
    source: {
      id: SOURCE_MM,
      page: 34,
    },
  },
  {
    header: 'Воюй, не производи',
    text: `Хоть ледяные великаны и считают недостойным рабское ремесло, они высоко ценят навыки резьбы и обработки кожи. Они создают свою одежду из шкур и костей зверей, а из обычных или слоновьих костей вырезают драгоценности и рукояти для оружия и инструментов. Они используют одежду и броню своих врагов, связывая щиты в кольчугу и приматывая клинки мечей к деревянным рукояткам, создавая гигантские копья. С поверженных драконов можно добыть лучшие боевые трофеи, и величайшие ярлы ледяных великанов носят доспехи из драконьей чешуи или владеют кирками или булавами из драконьих зубов и когтей.`,
    source: {
      id: SOURCE_MM,
      page: 34,
    },
  },
  {
    header: 'Ледяные великаны',
    text: `Ледяные великаны обитают в отдалённых, самых замороженных местах мира. Всё, что теплее, чем мясо недавно убитых лосей для них как пламя. Как моряк боится завывания ветра, предвещающего бурю, обитатели вершин ледяных гор и северных степей до дрожи боятся военных рогов, которые предвещают приход синекожих обледенелых детей [Трима](GOD:${GOD_THRYM}).`,
    source: {
      id: SOURCE_VGTM,
      page: 28,
    },
  },
  {
    header: 'Уложение силы',
    text: `Положение ледяных великанов в Уложении определяется чистой, грубой силой. Ледяные великаны знают, что те, кто используют хитрость, ловкость, и волшебство, являются опасными врагами и иногда могут победить чистую силу, но не прямолинейно и честно; враги, которые действуют таким образом являются _маугами_, и только сила — _маат_.

Сомнения и разногласия между ледяными великанами относительно того, кто из них самый сильный, решаются соревнованиями в силе. Такое состязание, как правило, включает в себя борьбу, но также может быть метанием камней, охотой, или боем один-на-один.

Как демонстрацию доказательства своего превосходства ледяные великаны хранят трофеи своих жертв. Бивни [мамонта](CREATURE:${CREATURE_MAMMOTH}), клювы [грифона](CREATURE:${CREATURE_GRIFFON}) и хвосты [мантикоры](CREATURE:${CREATURE_MANTICORE}) украшают стены великанских берлог. Грозные гуманоидные враги также увековечены в трофеях, но великаны редко сохраняют их головы или тела. В таких случаях [двуручный меч](GEAR:${GEAR_GREATSWORD}) человеческого героя или [посох](GEAR:${GEAR_STAFF}) [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) будут более подобающим трофеем. Доспехи и оружие ледяных великанов являются таким же отчётом о его боевых наградах, как и коллекция трофеев, для тех, кто умеет читать знаки. Зарубки, вырезанные в рукоятке оружия, показывают количество и тип врагов, которых оно повергло. Рожки, перья, когти и клыки, прикрепляемые к шлемам и броне, служат в качестве украшений, посвященных памяти величайших подвигов силы великана.

Позиция в Уложении определяется силой и только силой, и среди ледяных великанов нет никакой разницы в физической мощи между полами (большинство обязанностей по воспитанию детей выполняются пожилыми великанами обоих полов, а не только женщинами). Считается очень _мауг_ атаковать или бросать вызов беременной женщине, даже чтобы повысить собственный статус, это будет всё равно что атаковать спящего ледяного великана.

Ледяной великан, который с рождения слабее своих соплеменников, имеет низкий ранг в Уложении, и практически не имеет шансов возвыситься. Время от времени, когда великан сильно разочаровывается этой ситуацией, он обращается к подпольному поклонению [Вапраку](GOD:${GOD_VAPRAK}), божеству [троллей](CREATURE:${CREATURE_TROLL}) и [огров](CREATURE:${CREATURE_OGRE}). Великан, осенённый милостью [Вапрака](GOD:${GOD_VAPRAK}), трансформируется в [извечного](CREATURE:${CREATURE_FROST_GIANT_EVERLASTING_ONE}) — великана с достаточной силой, чтобы конкурировать с лидерами клана, хотя ему и суждено быть изгнанным или уничтоженным, если станет известно, кому он поклоняется.

Поскольку сила является единственным мерилом для ледяных великанов, более вероятно, что они примут невеликана в свою группу, чем другие великаны. Мощь человека, который охотится на [белых медведей](CREATURE:${CREATURE_POLAR_BEAR}) голыми руками, как это делают ледяные великаны, или кто поборет ледяного великана, не может быть не замечена. Такой человек никогда не может стать вождем племени, но может заработать почетное место и получить благословение [Трима](GOD:${GOD_THRYM}).`,
    source: {
      id: SOURCE_VGTM,
      page: 29,
    },
  },
  {
    header: 'Безжалостные рейдеры',
    text: `Общество ледяных великанов не имеет промышленности. Великан заберёт то, что ему нужно, у других, а если он что-то не может забрать, значит ему это просто не нужно. Ледяные великаны тем не менее изготавливают кожу, одежду, костяные орудия и украшения из животных, на которых охотятся, но эти виды деятельности составляют почти всё их ремесло. Когда ледяные великаны планируют налёт на соседнее поселение или крепость, они ждут, чтобы он прошёл под прикрытием снежной бури, полагая, что это знак [Трима](GOD:${GOD_THRYM}), что слабокостные гуманоиды готовы быть разграблены, как фермер может ожидать грозы, как знамение от бога урожая, призывающего к началу посева.

Ледяные великаны признают два вида добычи: _род_ и _квит_. _Род_ (красный) состоит из живых существ — скота или рабов. _Квит_ относится к материальным благам, наиболее ценными являются вещи из стали, алкоголь и крупные самоцветы. Ледяные великаны любят захватывать драгоценные камни для украшения одежды, хотя обычную валюту во время налета они обычно не берут. Крошечные круглые монеты просто не имеют никакой ценности для ледяных великанов.

Из-за того, что ледяные великаны не могут выдержать жар горна, они никогда не добывают собственный металл и не куют собственное оружие и броню. Поэтому кованые изделия из стали и железа очень высоко ценятся, и надеваются и носятся так, словно они сделаны из золота. Великаны всегда в поисках такой добычи во время своих набегов, но им нечасто попадаются орудия, достаточно большие для них. Многие из великанов в племени хвалятся оружием и доспехами своих предков; остальные вынуждены сооружать орудия из более мелких частей. Щиты, созданные под размер человека, могут быть объединены в грубо сделанную броню; наковальня, приделанная к бревну, служит молотом.`,
    source: {
      id: SOURCE_VGTM,
      page: 29,
    },
  },
  {
    header: 'Повелители зверей',
    text: `Ледяные великаны доминируют над дикими существами и просто демонстрируя свою силу, и чтобы использовать их как охотничьих компаньонов. Однако, они не особо преуспевают в животноводстве, так что их «домашние животные» скорее больше избиваются до покорного состояния, чем дрессируются. Когда ледяной великан командует животным атаковать, это скорее не команда, а подтверждения для существа, что великан не убьёт его для удовлетворения своего голода. Существо, которое сопротивляется «обучению» в конечном итоге обречено попасть на обеденный стол великана.

Перечень существ в логове ледяных великанов может включать в себя [полярных медведей](CREATURE:${CREATURE_POLAR_BEAR}), [волков](CREATURE:${CREATURE_WINTER_WOLF}) и [мамонтов](CREATURE:${CREATURE_MAMMOTH}), но наиболее ценные для великанов — [реморазы](CREATURE:${CREATURE_REMORHAZ}). Взрослые [реморазы](CREATURE:${CREATURE_REMORHAZ}) совершенно необучаемы ничем, кроме мощного магического принуждения, но, если его воспитывать с состояния яйца, он может быть обучен. На самом деле [личинки реморазов](CREATURE:${CREATURE_YOUNG_REMORHAZ}) удивительно податливые к избивающей манере обучения ледяных великанов.`,
    source: {
      id: SOURCE_VGTM,
      page: 30,
    },
  },
  ...godThrymOnlyDescriptionList,
  ...giantDescriptionList,
]
