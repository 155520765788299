const {SOURCE_MM} = require('./../../sourceList')

const bahamutDescriptionList = require('./bahamut')
const dragonDescriptionList = require('./dragon')

module.exports = [
  {
    header: 'Металлические драконы',
    text: `Металлические драконы ставят своей целью сохранение и защиту, и считают себя одной из могучих рас среди множества прочих, у которых также есть своё место в мире.`,
    source: {
      id: SOURCE_MM,
      page: 120,
    },
  },
  {
    header: 'Благородное любопытство',
    text: `Металлические драконы жадны до сокровищ так же, как их цветные родственники, но ими движет не столько жадность в стремлении к богатству. Скорее металлическими драконами движет стремление к исследованию и собирательству, охоте за бесхозными реликтами и хранению их у себя в логове. Сокровища металлического дракона содержат вещи, отражающие их характер, повествующих их историю и напоминающих им о каких-то моментах. Металлические драконы также стремятся защитить других существ от опасной магии и, как следствие, в сокровищницах металлических драконов временами попадаются могущественные магические вещи, и даже злые артефакты.

Металлического дракона можно убедить расстаться с вещью из сокровищницы ради хорошего дела. Тем не менее, необходимость какого-то существа в вещи может быть совершенно неясна для дракона. Чтобы дракон расстаться с этой вещью, его нужно подкупить или убедить другим образом.`,
    source: {
      id: SOURCE_MM,
      page: 120,
    },
  },
  {
    header: 'Перевёртыши-одиночки',
    text: `В какой-то момент своей долгой жизни металлические драконы получают магическую способность принимать облик гуманоидов и зверей. Когда дракон узнаёт, как замаскировать себя, он может на время с головой окунуться в другие культуры. Некоторые драконы слишком застенчивы или параноидальны, чтобы отходить далеко от своего логова и сокровищ, но смелые драконы отправляются бродить по улицам городов в гуманоидных обликах, привлечённые местной культурой и кухней, развлекая себя изучением жизни и быта малых рас.

Некоторые металлические драконы предпочитают оставаться вдали от цивилизации, насколько это возможно, чтобы не привлечь врагов. Однако это также означает, что они не знакомы с текущими событиями.`,
    source: {
      id: SOURCE_MM,
      page: 120,
    },
  },
  {
    header: 'Инерция памяти',
    text: `Металлические драконы имеют долгую память, и они формируют мнение о гуманоидах на основе предыдущего контакта с их представителями. Добрые драконы могут распознать родословную гуманоида по запаху, обнюхивая каждого человека, которого встречают, и вспоминая всех его родственников, с которыми они вступали в контакт на протяжении долгих лет. Золотой дракон никогда не заподозрит в двуличии хитрого злодея, считая, что у него такие же ум и сердце, как у его доброй и благородной бабушки. С другой стороны, дракон может выказать возмущение благородному паладину, чей предок выкрал серебряную статую из сокровищницы дракона три столетия назад.`,
    source: {
      id: SOURCE_MM,
      page: 120,
    },
  },
  ...bahamutDescriptionList,
  ...dragonDescriptionList,
]
