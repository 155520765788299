const {
  CREATURE_TYPE_DROW,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ELVEN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_CONJURE_ANIMALS,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINATION,
  SPELL_FAERIE_FIRE,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GUIDANCE,
  SPELL_INSECT_PLAGUE,
  SPELL_LESSER_RESTORATION,
  SPELL_LEVITATE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_POISON_SPRAY,
  SPELL_PROTECTION_FROM_POISON,
  SPELL_RAY_OF_SICKNESS,
  SPELL_RESISTANCE,
  SPELL_SPARE_THE_DYING,
  SPELL_THAUMATURGY,
  SPELL_WEB,
} = require('./../../../../spellIdList')
const {
  drowDescription,
  drowNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FEY_ANCESTRY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_DROW_PRIESTESS_OF_LOLTH,
  CREATURE_GIANT_SPIDER,
  CREATURE_YOCHLOL,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_8} = require('./../../../../crList')
const {GEAR_SCALE_MAIL} = require('./../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {GOD_LOLTH} = require('./../../../../godIdList')
const {PC_CLASS_CLERIC} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Дроу жрица Ллос`,
  nameAlt: `Дроу жрица Лолс`,
  nameEn: 'Drow Priestess of Lolth',
  id: CREATURE_DROW_PRIESTESS_OF_LOLTH,
  description: [
    `Женщины дроу родом из благородного дома с рождения воспитываются и обучаются с единой целью — стать жрицами [Ллос](GOD:${GOD_LOLTH}). [Паучья Королева](GOD:${GOD_LOLTH}) не позволяет мужчинам дроу занимать такие должности.

Эти жрицы исполняют волю [Паучьей Королевы](GOD:${GOD_LOLTH}), и потому обладают огромной силой и влиянием в обществе дроу. Матроны, которые правят домами дроу, — самые могущественные из жриц [Ллос](GOD:${GOD_LOLTH}), но при этом они обязаны поддерживать баланс между преданностью [Паучьей Королеве](GOD:${GOD_LOLTH}) и преданностью своей семье.`,
    ...drowDescription,
  ],
  note: drowNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_DROW,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 307,
  },
  armor: {
    ac: 16,
    gearId: GEAR_SCALE_MAIL,
  },
  hp: {
    diceCount: 13,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
    LANG_ELVEN,
  ],
  cr: CR_8,
  featureList: [
    ABILITY_FEY_ANCESTRY,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: [
    {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DANCING_LIGHTS,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            {
              id: SPELL_LEVITATE,
              comment: 'только на себя',
            },
            SPELL_FAERIE_FIRE,
            SPELL_DARKNESS,
          ],
        },
      ],
    },
    {
      spellCasterLevel: 10,
      spellCasterClass: PC_CLASS_CLERIC,
      spellIdList: [
        SPELL_ANIMAL_FRIENDSHIP,
        {
          id: SPELL_CONJURE_ANIMALS,
          comment: `два [гигантских паука](CREATURE:${CREATURE_GIANT_SPIDER})`,
        },
        SPELL_CURE_WOUNDS,
        SPELL_DETECT_POISON_AND_DISEASE,
        SPELL_DISPEL_MAGIC,
        SPELL_DIVINATION,
        SPELL_FREEDOM_OF_MOVEMENT,
        SPELL_GUIDANCE,
        SPELL_INSECT_PLAGUE,
        SPELL_LESSER_RESTORATION,
        SPELL_MASS_CURE_WOUNDS,
        SPELL_POISON_SPRAY,
        SPELL_PROTECTION_FROM_POISON,
        SPELL_RAY_OF_SICKNESS,
        SPELL_RESISTANCE,
        SPELL_SPARE_THE_DYING,
        SPELL_THAUMATURGY,
        SPELL_WEB,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        3,
        2,
      ],
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `Дроу совершает две атаки _Плетью_.`,
    },
    {
      name: `Плеть`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 6,
            diceBonus: 2,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 5,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: `Призыв демона`,
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ пытается магическим образом призвать [Йоклол](CREATURE:${CREATURE_YOCHLOL}) с 30-процентным шансом успеха. При провале попытки ★СУЩЕСТВО★ получает урон психической энергией 5 (1к10). В противном случае призванный демон появляется в свободном пространстве в пределах 60 футов от призывателя, действует как его союзник, и не может призывать других демонов. Он присутствует в течение 10 минут, пока он, либо призыватель, не умрут, или пока призыватель не отпустит его действием.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
