import arrify from 'arrify'

import prepareForSearch from '@/utils/prepareForSearch'
import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'

import reduceWithChild from './reduceWithChild';

const itemListFiltrator = itemList => reduceWithChild(
  (currentFilteredItemList, {propName, type, value, filterHandler}) => (
    typeof value === 'undefined'
    || value === null
  )
    ? currentFilteredItemList
    : currentFilteredItemList.filter(
      filterHandler
        ? filterHandler(value)
        : (
          item => {
            const propToCheck = type === 'input'
              ? prepareForSearch(item[propName])
              : arrify(item[propName]).map(prepareForSearch);

            return arrify(value).some(
              valueItem => valueItem === DEFAULT_FILTER_VALUE || propToCheck.includes(prepareForSearch(valueItem))
            )
          }
        )
    ),
  itemList
)

export default itemListFiltrator;
