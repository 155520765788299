const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_ESPRUAR} = require('./../../alphabetList')
const {LANG_DAMBRATHAN} = require('./../../languageIdList')
const {PLACE_DAMBRATH} = require('./../../placeIdList')

module.exports = {
  id: LANG_DAMBRATHAN,
  alphabetId: ALPHABET_ESPRUAR,
  type: LANG_TYPE_ETHNIC,
  nameEn: 'Dambrathan',
  name: {
    nominative: 'Дамбратан',
    genitive: 'Дамбратана',
    instrumental: 'Дамбратаном',
    prepositional: 'Дамбратане',
  },
  typicalSpeakers: 'Аркаюны',
  spokenPlaceList: PLACE_DAMBRATH,
  isReady: false,
  isRealLang: true,
}
