const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_DIG,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_ACID,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
    SENSE_VIBRATION_SENSE,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CREATURE_ANKHEG,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Анхег',
  nameEn: 'Ankheg',
  id: CREATURE_ANKHEG,
  description: [
    {
      header: 'Анхег',
      text: `Анхег похож на огромное насекомое с множеством лап. Его длинные усики подёргиваются, реагируя на любое движение вокруг. На концах его лап огромные крюки, пригодные как для рытья, так и для того, чтобы хватать добычу. Его мощные челюсти способны перекусить небольшое дерево пополам.`,
      source: {
        id: SOURCE_MM,
        page: 22,
      },
    },
    {
      header: 'Скрывающиеся в земле',
      text: `Своими мощными челюстями анхег роет извилистые туннели глубоко под землёй. Когда анхег охотится, он роет вверх, ожидая чуть ниже поверхности земли, пока его усики не уловят движение сверху. Затем он выпрыгивает из-под земли, хватая жертву челюстями. Он дробит и измельчает жертву, одновременно выделяет кислотные ферменты, которые помогают растворить жертву для облегчения переваривания. Также анхег может плеваться кислотой в своих врагов.`,
      source: {
        id: SOURCE_MM,
        page: 22,
      },
    },
    {
      header: 'Проклятье лесов и полей',
      text: `Несмотря на то, что анхеги получают часть питательных вещей из почвы, в которой они прячутся, они должны дополнять свой рацион свежим мясом. Пастбища, изобилующие пасущимся скотом, и леса, полные дичи, это основные охотничьи угодья анхегов. Поэтому анхеги это проклятье фермеров и охотников.`,
      source: {
        id: SOURCE_MM,
        page: 22,
      },
    },
    {
      header: 'Земляные туннели',
      text: `Когда анхег копает под землёй, он оставляет за собой узкий, частично обвалившийся туннель. В этих туннелях можно обнаружить частички сброшенного хитина, остатки яиц анхегов или кошмарные останки жертв анхегов, в том числе монеты и другие сокровища, разбросанные во время нападения.`,
      source: {
        id: SOURCE_MM,
        page: 22,
      },
    },
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 22,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 10,
  },
  hp: {
    diceCount: 6,
  },
  armor: [
    {
      ac: 14,
      type: ARMOR_TYPE_NATURAL,
    },
    {
      ac: 11,
      comment: 'когда сбит с ног',
    },
  ],
  cr: CR_2,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 13,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 6,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      description: 'Если цель — существо с размером не больше Большого, она становится схваченной (СЛ высвобождения 13). Пока цель схвачена, ★СУЩЕСТВО★ может кусать только её, но зато атаки совершает с преимуществом.',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 3,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
    {
      name: 'Кислотная струя',
      description: '★СУЩЕСТВО★ плюёт кислотой линией длиной 30 футов и шириной 5 футов, при условии, что ★он★ не держит в захвате существа. Все существа в этой линии должны пройти испытание Ловкости СЛ 13, получая урон кислотой 10 (3к6) при провале, или половину этого урона при успехе.',
      restore: 6,
    },
  ],
  genderId: GENDER_MALE,
}
