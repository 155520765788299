const {
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26,
  CREATURE_WATER_BEAST_SPIRIT,
  CREATURE_WATER_BESTIAL_SPIRIT_2020_03_26,
} = require('./../../../../creatureIdList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Дух водного зверя',
  nameEn: 'Water Bestial Spirit',
  id: CREATURE_WATER_BESTIAL_SPIRIT_2020_03_26,
  releasedAs: CREATURE_WATER_BEAST_SPIRIT,
  parentId: CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_PACK_TACTICS,
  ],
}
