const upLetter = require('./../../../../../../utils/upLetter')

const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {languageCollection} = require('./../../../../../languageList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../../magicItemRarityList')
const {MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND} = require('./../../../../../magicItemIdList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_DOMINATE_MONSTER} = require('./../../../../../spellIdList')

const generateKillBonusText = require('./generateKillBonusText')
const generateSpellText = require('./generateSpellText')
const list = require('./list')
const siblingIdList = require('./siblingIdList')

const spellIdList = list.reduce(
  ( list, {spellList}) => [
    ...list,
    ...spellList.map(({id}) => id),
  ],
  []
)

const tableText = list
  .sort(
    ({planeName: A}, {planeName: B}) => A > B
      ? 1
      : -1,
  )
  .reduce(
    (
      text,
      {
        additionalBonus,
        killBonusList,
        langId,
        planeName,
        spellList,
      }
    ) => {
      const spellText = generateSpellText(spellList, true)
      const killBonusText = generateKillBonusText(killBonusList, true)
      const {name: { nominative: langName }} = languageCollection[langId]

      return `${text}
|${planeName}|${langName}|${spellText}|${upLetter(additionalBonus)}|${killBonusText}|`
    },
    `
| План | Язык  | Заклинания кольца | Особые свойства | Дополнительные свойства при убийстве |
|------|-------|-------------------|-----------------|--------------------------------------|`
  )

module.exports = {
  id: MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND,
  name: `Кольцо командования элементалями`,
  nameEn: `Ring of Elemental Command`,
  type: MGC_TYPE_RING,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: `Это кольцо связано с одним из четырёх Стихийных Планов. Мастер выбирает один такой план самостоятельно или случайным образом.

Нося это кольцо, Вы получаете преимущество к атакам по элементалям из связанного плана, а они совершают с помехой броски атаки по Вам.

У кольца есть 5 зарядов. Оно ежедневно восстанавливает 1к4 + 1 заряд на рассвете.

Вы можете потратить 2 заряда кольца, чтобы наложить [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}) на элементаля из связанного плана.

Кольцо также имеет особые свойства и заклинания, зависящие от выбранного плана.

Если Вы поможете убить элементаля из связанного плана, будучи настроенным на это кольцо, то получаете доступ к его дополнительным свойствам.

${tableText}`,
  genderId: GENDER_MIDDLE,
  childIdList: siblingIdList,
  spellIdList,
  source: {
    id: SOURCE_DMG,
    page: 175,
  },
}
