const {
  SOURCE_MM,
  SOURCE_ERRATA_MONSTER_MANUAL,
} = require('./../../../../sourceList')
const {
  CR_1,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_THRI_KREEN,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_CN,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_THRI_KREEN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  thriKreenDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_STANDING_LEAP,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_THRI_KREEN,
} = require('./../../../../creatureIdList')

module.exports = {
  name: `Три-крин`,
  nameAlt: `Три-кин`,
  nameEn: 'Thri-Kreen',
  id: CREATURE_THRI_KREEN,
  description: thriKreenDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_THRI_KREEN,
  ],
  alignmentId: ALIGNMENT_CN,
  source: [
    {
      id: SOURCE_MM,
      page: 284,
    },
    {
      id: SOURCE_ERRATA_MONSTER_MANUAL,
      page: 1,
    },
  ],
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 13,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_THRI_KREEN,
  ],
  cr: CR_1,
  featureList: [
    {
      name: `Панцирь хамелеона`,
      description: `★СУЩЕСТВО★ может менять цвет панциря, чтобы сливаться с окружением. Благодаря этому он совершает с преимуществом проверки Ловкость (Скрытность), совершённые чтобы спрятаться.`,
    },
    {
      id: ABILITY_STANDING_LEAP,
      jumpLength: 30,
      jumpHeight: 15,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Когтями_.`,
    },
    {
      name: `Укус`,
      description: `Цель должна пройти испытание Телосложения СЛ 11, иначе станет отравленной на 1 минуту. Если испытание провалено на 5 или больше единиц, цель также парализована, пока отравлена таким образом. Отравленная цель может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
    {
      name: `Когти`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
