const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {SPELL_SPARE_THE_DYING} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SPARE_THE_DYING,
  name: 'Уход за умирающим',
  nameEn: 'Spare the Dying',
  description: `Вы касаетесь живого существа, у которого 0 хитов. Оно становится стабилизированным. Это заклинание не оказывает никакого эффекта на нежить и конструктов.`,
  lvl: 0,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  source: [
    {
      id: SOURCE_PHB,
      page: 287,
    },
    {
      id: SOURCE_SRD,
      page: 181,
    },
  ],
}
