const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ABILITY_POUNCE,
  ABILITY_SPELL_TURNING,
} = require('./../../../../creatureAbilityList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SOURCE_IDRotF,
  SOURCE_SKT,
} = require('./../../../../sourceList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_CRAG_CAT} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {PC_RACE_HUMAN} = require('./../../../../pcRaceIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Скальная кошка',
  nameEn: 'Crag Cat',
  id: CREATURE_CRAG_CAT,
  description: `Существо, упомянутое в преданиях северян, как _Охотник на людей_, безжалостный хищник, которого не найти нигде, кроме густого леса, предпочитающий выступы и утесы в горах. Его голос напоминает человеческий крик ужаса. Такой же звук он часто вызывает у своих жертв, ибо он предпочитает [человечину](PC_RACE:${PC_RACE_HUMAN}) из всех своих жертв.

Скальные кошки сливаются с природным окружением. Зимой их мех становится белым, незаметным на снегу. В другие времена года он имеет серый окрас, что позволяет кошкам с легкостью прятаться среди скал.

Скальный кот знает свою территорию и часто нападает, когда жертва спит, устала или как-то ослаблена. Хотя скальные коты, как правило одиночки, их можно увидеть в семейных группах из двух родителей и 1к4 маленьких невинных детенышей весной, или в голодных стаях в тяжёлую зимнюю пору.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_N,
  source: [
    {
      id: SOURCE_SKT,
      page: 246,
    },
    {
      id: SOURCE_IDRotF,
      page: 286,
    },
  ],
  armor: 13,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 16,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_1,
  featureList: [
    {
      name: 'Необнаружимость',
      description: `★СУЩЕСТВО★ не может быть целью какой-либо магии прорицания или ★обнаружен★ с помощью магических сенсоров.`,
    },
    {
      id: ABILITY_POUNCE,
      range: 20,
      dc: 13,
      attack: 'одну атаку _Укусом_',
    },
    {
      id: ABILITY_SPELL_TURNING,
      spellLvl: 7,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
