const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_BLINDHEIM,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ATHLETICS,
    SKILL_PERCEPTION,
  } = require('./../../../../skillList'),
  {
    LANG_UNDERCOMMON,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_CN,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_BLINDHEIM,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Слепарь',
  nameEn: 'Blindheim',
  id: CREATURE_BLINDHEIM,
  description: `Эти лягушкоподобные гуманоиды обладают способностью излучать ослепительно яркий свет из глаз. Обитающие на окраинах Подземья, слепари предпочитают устраивать засады на свою добычу. Слепарей можно склонить на свою сторону, предложив им свежие продукты и металлическое оружие. Сияющие глаза слепарей делают этих существ уникальным источником света в глубине Подземья. Их свет достаточно ярок, чтобы как досаждать существам, не переносящим яркий свет, так и уничтожать врагов слепарей.

**Обжигающие глаза.** Слепарь проецирует конус жгучего света из огромных выпученных глаз. Интенсивность этого света равна нормальному солнечному свету и может беспокоить или повреждать существ, привычных ко мраку тёмных глубин. Если слепарь сфокусирует свой взгляд, то этот луч света может сжечь даже плоть и кости.

**Беспринципные наёмники.** Слепарей завораживают рассказы и предметы из Верхнего мира, особенно растения и другими природные предметы. Слепари часто собираются в глубине Подземья возле маршрутов, по которым ходят торговцы и другие отважные путешественники. Как правило, они осторожно подходят к более сильным существам, говоря на медленном, каркающем Подземном и предлагают свои услуги в обмен на свежие продукты, металлическое оружие и безделушки из Верхнего мира. Но когда слепари значительно превосходят их числом или эти существа кажутся им слабыми, то слепари устроят им засаду, чтобы забрать всё, что хотят.

**Странные изгои.** Слепые говорят о древней эпохе, когда их народы обитали на поверхности. Согласно их рассказам, их предки украли кусок Солнца, а затем убежали в Подземье, прежде чем сожрать его и обрести способность излучать свет из глаз.

Слепари боятся, что когда-нибудь Солнце уйдёт под землю и отомстит им. Имея дело с поверхностными обитателями, они задают бесконечные вопросы о действиях Солнца, его гневе, его отношении к слепарям. Они настаивают на том, чтобы те поклялись никогда не рассказывать Солнцу о слепарях. Если поверхностники пытаются развеять страхи слепарей, то это сильно их оскорбляет. В сознании слепарей Солнце не смогло забыть их великую и героическую кражу, поэтому их гнев вызывают все попытки развеять их веру в то, что они всё ещё ужасны и пугающи для поверхностников.

**Жалкие рабы.** Некоторые существа Подземья, особенно дроу и куо-тоа, совершают набеги на поселения слепарей в поисках рабов. Хотя свет слепарских глаз очень опасен для этих налётчиков, захваченный слепарь — полезное живое оружие.`,
  note: {
    text: `Путешествуя по Подземью, я всегда беру с собой священный символ Пелора на случай встречи с этими существами. Они бывают полезны, если Вы не против некоторое время поизображать из себя мстительного жреца солнечного бога`,
    author: `Морденкайнен`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_BLINDHEIM,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 5,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Светящиеся глаза',
      description: `Пока ★его★ глаза открыты, они излучают яркий свет в 60-футовом конусе и тусклый свет ещё на 60 футов. В начале каждого своего хода ★СУЩЕСТВО★ решает, в какую сторону направлен конус. Все видящие слепаря существа получают помеху к броскам атаки, пока они находятся в области яркого света в пределах 15 футов от слепаря. Существа с особенностью _Чувствительность к солнечному свету_, видящие ★его★, получают помеху к броскам атаки в области яркого света.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Взрыв света',
      description: `★СУЩЕСТВО★ испускает свет из глаз 15-футовым конусом. Каждое существо в этой области должно пройти испытание Телосложения СЛ 12 или получить 7 (2к6) урона излучением и Ослепнуть до конца следующего хода ★СУЩЕСТВО★. Существа с особенностью _Чувствительность к солнечному свету_ получают помеху к этому испытанию.`,
    },
  ],
}
