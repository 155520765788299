const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BUGBEAR,
  CREATURE_HALF_OGRE,
  CREATURE_HOBGOBLIN,
  CREATURE_OGRE,
  CREATURE_ORC,
} = require('./../../../../creatureIdList')
const {
  GEAR_BATTLEAXE,
  GEAR_HIDE_ARMOR,
  GEAR_JAVELIN,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {ALIGNMENT_ANY_CHAOTIC} = require('./../../../../aligmentList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_TYPE_GIANT} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Полуогр',
  nameAlt: 'Огриллон',
  nameEn: 'Half-Ogre',
  nameEnAlt: 'Ogrillon',
  id: CREATURE_HALF_OGRE,
  description: `Когда [огры](CREATURE:${CREATURE_OGRE}) спариваются с людьми, [хобгоблинами](CREATURE:${CREATURE_HOBGOBLIN}), [медвежатниками](CREATURE:${CREATURE_BUGBEAR}) или [орками](CREATURE:${CREATURE_ORC}), результатом всегда становится полуогр (огры не скрещиваются с дварфами, полуросликами или эльфами; они съедают их). Человеческие матери редко переживают рождение такого отпрыска.

Полуогрский потомок огра и орка также известен как «огриллон». Взрослый полуогр или огриллон в среднем достигает восьми футов роста и весит около 450 фунтов.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
  ],
  alignmentId: ALIGNMENT_ANY_CHAOTIC,
  source: {
    id: SOURCE_MM,
    page: 223,
  },
  armor: {
    ac: 12,
    gearId: GEAR_HIDE_ARMOR,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 14,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 10,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
    LANG_COMMON,
  ],
  cr: CR_1,
  actionList: [
    {
      name: 'Боевой топор',
      // gearId: GEAR_BATTLEAXE, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 3,
            },
            {
              type: DAMAGE_SLASHING,
              diceCount: 2,
              diceType: 10,
              diceBonus: 3,
              use2Hand: true,
            },
          ],
        ],
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Пилум',
      // gearId: GEAR_JAVELIN, // TODO: Найти способ показывать оружие с изменёнными костями
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 5,
        range: {
          normal: 30,
          max: 120,
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
