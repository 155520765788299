const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_1_4,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_PLANT,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_NECROTIC,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_BLINDED,
  CONDITION_DEAFENED,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  TARGET_CREATURE,
} = require('./../../../../targetList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  mushroomsDescription,
} = require('./../../../../textCommonParts')
const {
  ABILITY_FALSE_APPEARANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_VIOLET_FUNGUS,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Лиловый гриб',
  nameEn: 'Violet Fungus',
  id: CREATURE_VIOLET_FUNGUS,
  description: [
    `Этот гриб фиолетового оттенка использует корнеобразные отростки в своём основании, чтобы передвигаться по поверхности пещеры. Четыре побега в середине ствола гриба используются, чтобы хлестать жертву, вызывая гниение при малейшем прикосновении. Любое убитое лиловым грибом существо очень быстро разлагается. Из трупа вырастает новый фиолетовый гриб, который достигает зрелости через 2к6 дней.`,
    mushroomsDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 74,
  },
  armor: 5,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 5,
  },
  params: {
    [PARAM_STR]: 3,
    [PARAM_DEX]: 1,
    [PARAM_CON]: 10,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 3,
    [PARAM_CHA]: 1,
  },
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_BLINDED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
      isBlindOutside: true,
    },
  ],
  cr: CR_1_4,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного гриба`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает 1к4 атаки _Разлагающим касанием_.`,
    },
    {
      name: 'Разлагающее касание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 1,
          diceType: 8,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
