const {SOURCE_EGTW} = require('./../../sourceList')
const {CREATURE_ACOLYTE} = require('./../../creatureIdList')
const {PC_CLASS_CLERIC} = require('./../../pcClassIdList')
const {
  PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC,
  PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC,
} = require('./../../pcSubClassIdList')
const {
  MAGIC_ITEM_SPELL_SCROLL,
} = require('./../../magicItemIdList')

module.exports = {
  header: `Динамантия для не-динамантов`,
  text: `Эзотерические силы, разработанные и освоенные благодаря использования _динамия_, всё ещё остаются очень редким и тщательно оберегаемым секретом династии Крин в Дикогорье. Тем не менее, такое волшебство трудно скрыть, особенно во время войны. Шпионы и перебежчики давно выкрадывают тайны этой сложной магической практики и даже сейчас она медленно распространяется вовне Хорхаса.

Заклинания динамантии доступны для подклассов волшебника ([Гравитург](PC_SUBCLASS:${PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC}) и [Хронург](PC_SUBCLASS:${PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC})) и не должны быть просто добавлены в список заклинаний других классов заклинателей. Тем не менее, Мастер может рассмотреть возможность разрешить другим заклинателей в течение кампании изучать и осваивать заклинания динамантии как награду. Персонажи могут найти схрон магической контрабанды, среди которой будет несколько [свитков заклинаний](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL}). Или же странствующий [прислужник](CREATURE:${CREATURE_ACOLYTE}) проведёт время простоя с дружественным ему персонажем-[жрецом](PC_CLASS:${PC_CLASS_CLERIC}) и откроет тому свой разум, позволяя постичь некоторые тайны вселенной, которые могут включать одно-два заклинания. Есть множество уникальных способов добавить эти заклинания в Вашу игру, не требуя наличия каких-либо связанных с _динамием_ подклассов в Вашей группе приключенцев.`,
  source: {
    id: SOURCE_EGTW,
    page: 187,
  },
}
