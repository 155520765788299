const arrify = require('arrify')

module.exports = list => list.map(
  ({ name, nameAlt, nameEn }) => ({
    nameEn,
    name: nameAlt
      ? [name, ...arrify(nameAlt)]
      : name,
  }),
)
