module.exports.PC_CLASS_ARTIFICER = 'artificer'
module.exports.PC_CLASS_BARBARIAN = 'barbarian'
module.exports.PC_CLASS_BARD = 'bard'
module.exports.PC_CLASS_CLERIC = 'cleric'
module.exports.PC_CLASS_DRUID = 'druid'
module.exports.PC_CLASS_FIGHTER = 'fighter'
module.exports.PC_CLASS_MONK = 'monk'
module.exports.PC_CLASS_PALADIN = 'paladin'
module.exports.PC_CLASS_RANGER = 'ranger'
module.exports.PC_CLASS_ROGUE = 'rogue'
module.exports.PC_CLASS_SORCERER = 'sorcerer'
module.exports.PC_CLASS_WARLOCK = 'warlock'
module.exports.PC_CLASS_WIZARD = 'wizard'
