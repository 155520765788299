const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_MARINER_S_ARMOR} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')
const gearCategoryList = require('./gearCategoryList')

module.exports = {
  id: MAGIC_ITEM_MARINER_S_ARMOR,
  name: `Доспех моряка`,
  nameEn: `Mariner’s Armor`,
  type: MGC_TYPE_ARMOR,
  gearCategory: gearCategoryList,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Этот доспех искусно декорирован различными мотивами с изображениями рыб и морских раковин.

Пока Вы носите этот доспех, Вы обладаете скоростью плавания, равной скорости ходьбы. Кроме того, каждый раз, когда Вы начинаете ход, находясь под водой и с 0 хитов, Вы поднимаетесь к поверхности на 60 футов.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 159,
  },
}
