const {
  MAGIC_ABJURATION,
  MAGIC_ENCHANTMENT,
} = require('./../../magicList')
const {
  CAST_VERBAL,
} = require('./../../castComponentList')
const {
  SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
} = require('./../../sourceList')
const {
  TIME_HOUR,
  TIME_ROUND,
} = require('./../../timePeriodList')
const {
  PARAM_INT,
} = require('./../../paramList')
const {
  DAMAGE_PSYCHIC,
} = require('./../../damageTypeList')
const {
  SPELL_INTELLECT_FORTRESS,
  SPELL_INTELLECT_FORTRESS_2020_04_14,
  SPELL_TASHA_S_MIND_WHIP,
  SPELL_MIND_THRUST_2020_04_14,
} = require('./../../spellIdList')

module.exports = [
  {
    id: SPELL_INTELLECT_FORTRESS_2020_04_14,
    releasedAs: SPELL_INTELLECT_FORTRESS,
    name: 'Крепость интеллекта',
    nameEn: 'Intellect Fortress',
    description: `На время действия заклинания Вы (или одно видимое Вами в пределах дальности заклинания согласное существо) получаете сопротивление психическому урону и преимущество к испытаниям Интеллекта, Мудрости и Харизмы.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше четвёртого. Существа должны находиться в пределах 30 футов друг от друга, когда Вы выбираете их целями.`,
    lvl: 4,
    magicSchoolId: MAGIC_ABJURATION,
    needConcentration: true,
    range: 30,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_HOUR, count: 1 },
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 7,
    },
  },
  {
    id: SPELL_MIND_THRUST_2020_04_14,
    releasedAs: SPELL_TASHA_S_MIND_WHIP,
    name: 'Сотрясение разума',
    nameEn: 'Mind Thrust',
    description: `Вы вонзаете копьё псионического распада в разум одного существа, видимого вами в пределах дистанции.

Цель должна пройти испытание Интеллекта.

* При провале цель получает 3к6 психического урона и не может совершать реакции до начала своего следующего хода. Кроме того, на своём следующем ходу цель должна выбрать: передвигаться, совершать действие или бонусное действие. Она может совершить только что-то одно из этих трёх.
* При успехе цель получает половину этого урона и не страдает от других эффектов.`,
    highLvlCast: `Когда Вы сотворяете это заклинание, используя ячейку 3 круга или выше, Вы можете сделать целью дополнительно ещё одно существо за каждый круг ячейки выше 2. Существа должны находиться в пределах 30 футов друг от друга, когда Вы их выбираете.`,
    lvl: 2,
    magicSchoolId: MAGIC_ENCHANTMENT,
    range: 90,
    componentIdList: [CAST_VERBAL],
    duration: { timeId: TIME_ROUND, count: 1 },
    effect: {
      savethrowParam: PARAM_INT,
      damage: {
        type: DAMAGE_PSYCHIC,
        diceCount: 3,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 7,
    },
  },
]
