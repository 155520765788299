const {
  SOURCE_MM,
} = require('./../../../../sourceList')
const {
  CR_5,
} = require('./../../../../crList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_BEHOLDER_KEEN,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NE,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_DEEP_SPEECH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_POISONED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  zombieDesciptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_UNDEAD_FORTITUDE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_BEHOLDER_ZOMBIE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Злобоглаз-зомби',
  nameAlt: 'Бехолдер-зомби',
  nameEn: 'Beholder Zombie',
  id: CREATURE_BEHOLDER_ZOMBIE,
  description: zombieDesciptionList,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_BEHOLDER_KEEN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 164,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 20,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 16,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 5,
  },
  saveThrowList: [
    PARAM_WIT,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    [CONDITION_POISONED],
    [CONDITION_PRONE],
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: [LANG_DEEP_SPEECH, LANG_UNDERCOMMON],
      doNotSpeak: true,
    },
  ],
  cr: CR_5,
  featureList: [
    {
      id: ABILITY_UNDEAD_FORTITUDE,
      damageType: `излучением`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 6,
        },
      },
    },
    {
      name: 'Луч из глаз',
      description: `★СУЩЕСТВО★ использует случайным образом выбранный магический луч из глаз, выбирая цель, видимую в пределах 60 футов.

1. _Парализующий луч._ Целевое существо должно пройти испытание Телосложения СЛ 14, иначе станет парализованным на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
2. _Луч страха._ Целевое существо должно пройти испытание Мудрости СЛ 14, иначе станет испуганным на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.
3. _Луч бессилия._ Целевое существо должно пройти испытание Телосложения СЛ 14, получая урон некротической энергией 36 (8к8) при провале, или половину этого урона при успехе.
4. _Луч расщепления._ 
    * Если цель — существо, оно должно пройти испытание Ловкости СЛ 14, иначе получит урон силовым полем 45 (10к8). Если этот урон снижает хиты существа до 0, его тело становится кучкой серой пыли.
    * Если цель — немагический предмет или творение магической силы с размером не больше Большого, она расщепляется без испытания.
    * Если цель — немагический предмет или творение магической силы с размером Огромный или больше, этот луч расщепляет объём куба с длиной ребра 10 футов.`,
    },
  ],
  genderId: GENDER_MALE,
}
