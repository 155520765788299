const {
  WEAPON_AMMUNITION,
  WEAPON_HEAVY,
  WEAPON_RANGE,
  WEAPON_TWO_HANDED,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const bowsDescription = require('./../../constants/bowsDescription')
const {CAT_MARTIAL_RANGE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_LONGBOW} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_LONGBOW,
  genderId: GENDER_MALE,
  name: 'Длинный лук',
  nameByCase: {
    nominative: 'длинный лук',
    genitive: 'длинного лука',
    accusative: 'длинный лук',
    instrumental: 'длинным луком',
  },
  nameEn: 'Longbow',
  description: bowsDescription,
  damage: {
    diceType: 8,
    diceCount: 1,
  },
  cost: 5000,
  damageType: DAMAGE_PIERCING,
  weight: 2,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 114,
    },
  ],
  weaponPropList: [
    WEAPON_AMMUNITION,
    WEAPON_TWO_HANDED,
    WEAPON_HEAVY,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 150,
        max: 600,
      },
    },
  ],
  category: CAT_MARTIAL_RANGE_WEAPON,
}
