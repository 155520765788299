const {
  WEAPON_RANGE,
  WEAPON_THROWN,
  WEAPON_VERSATILE,
} = require('./../../../weaponPropList')
const {
  SOURCE_AYAGWnW,
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
} = require('./../../../sourceList')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_TRIDENT} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {GOD_UMBERLEE} = require('./../../../godIdList')
const {PC_RACE_TRITON} = require ('./../../../pcRaceIdList')

module.exports = {
  id: GEAR_TRIDENT,
  genderId: GENDER_MALE,
  name: 'Трезубец',
  nameByCase: {
    nominative: 'трезубец',
    genitive: 'трезубца',
    accusative: 'трезубец',
    instrumental: 'трезубцем',
  },
  nameEn: 'Trident',
  description: [
    {
      header: 'Трезубец',
      text: `Копьё с тремя зубцами, предназначенное для подводной рыбалки. Трезубец можно использовать как метательное или колющее оружие.`,
      source: {
        id: SOURCE_AYAGWnW,
        page: 117,
      },
    },
    {
      header: 'Трезубец',
      text: `Говорят, трезубцы впервые появились на суше после того, как [Амберли](GOD:${GOD_UMBERLEE}) выбросила на побережье Фаэруна невежливого царевича-[тритона](PC_RACE:${PC_RACE_TRITON}).`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  damage: {
    diceType: 6,
    diceCount: 1,
  },
  cost: 500,
  damageType: DAMAGE_PIERCING,
  weight: 4,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    WEAPON_THROWN,
    {
      id: WEAPON_VERSATILE,
      value: {
        diceCount: 1,
        diceType: 8,
      },
    },
    {
      id: WEAPON_RANGE,
      value: {
        normal: 20,
        max: 60,
      },
    },
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
