const {
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_WATER,
  } = require('./../../../../creatureTypeIdList'),
  {
    SPEED_SWIM,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    DAMAGE_ACID,
  } = require('./../../../../damageTypeList'),
  {
    CREATURE_AIR_ELEMENTAL_SPIRIT,
    CREATURE_WATER_ELEMENTAL_SPIRIT,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух элементаля воды',
  nameEn: 'Water Elemental Spirit',
  id: CREATURE_WATER_ELEMENTAL_SPIRIT,
  parentId: CREATURE_AIR_ELEMENTAL_SPIRIT,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_WATER,
  ],
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_SWIM]: 40,
  },
  resistanceList: [
    DAMAGE_ACID,
  ],
}
