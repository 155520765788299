const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_DRUID,
  CREATURE_MUMMY_LORD,
  CREATURE_NIGHT_HAG,
  CREATURE_SWARM_OF_BATS,
  CREATURE_SWARM_OF_INSECTS,
  CREATURE_SWARM_OF_POISONOUS_SNAKES,
  CREATURE_SWARM_OF_RATS,
  CREATURE_SWARM_OF_RAVENS,
  CREATURE_VAMPIRE,
  CREATURE_YUAN_TI_ABOMINATION,
} = require('./../../creatureIdList')

module.exports = {
  header: `Природа роёв`,
  text: `Рои — это не просто обычные скопления крошечных существ. Они формируются в результате какого-то зловещего или нездорового воздействия. [Вампир](CREATURE:${CREATURE_VAMPIRE}) может призывать [рои летучих мышей](CREATURE:${CREATURE_SWARM_OF_BATS}) или [крыс](CREATURE:${CREATURE_SWARM_OF_RATS}) из темнейших уголков ночи, а одно лишь присутствие [лорд-мумии](CREATURE:${CREATURE_MUMMY_LORD}) может заставить [скарабеев](CREATURE:${CREATURE_SWARM_OF_INSECTS}) массово выкопаться из песчаных глубин её гробницы. У [карги](CREATURE:${CREATURE_NIGHT_HAG}) может быть способность использовать [рой воронов](CREATURE:${CREATURE_SWARM_OF_RAVENS}) против своих врагов, а за [отродьем юань-ти](CREATURE:${CREATURE_YUAN_TI_ABOMINATION}) может по пятам ползти несколько [роёв ядовитых змей](CREATURE:${CREATURE_SWARM_OF_POISONOUS_SNAKES}). Даже [друиды](CREATURE:${CREATURE_DRUID}) не могут успокоить такие рои, а их агрессивность практически противоестественна.`,
  source: {
    id: SOURCE_MM,
    page: 338,
  },
}
