const {
  SPELL_DARK_STAR,
  SPELL_GRAVITY_FISSURE,
  SPELL_GRAVITY_SINKHOLE,
  SPELL_IMMOVABLE_OBJECT,
  SPELL_MAGNIFY_GRAVITY,
  SPELL_RAVENOUS_VOID,
} = require('./../../spellIdList')

module.exports = [
  SPELL_DARK_STAR,
  SPELL_GRAVITY_FISSURE,
  SPELL_GRAVITY_SINKHOLE,
  SPELL_IMMOVABLE_OBJECT,
  SPELL_MAGNIFY_GRAVITY,
  SPELL_RAVENOUS_VOID,
]
