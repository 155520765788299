const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_IXITXACHITL,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_IXITXACHITL,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык икситксачитлов',
    genitive: 'Языка икситксачитлов',
    instrumental: 'Языком икситксачитлов',
    prepositional: 'Языке икситксачитлов',
  },
  nameEn: 'Ixitxachitl',
  isReady: false,
  isRealLang: true,
}
