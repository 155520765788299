const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GOBLINOID,
  CREATURE_TYPE_HOBGOBLIN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  GEAR_CHAIN_MAIL,
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  hobgoblinDescriptionList,
  hobgoblinNote,
} = require('./../../../../textCommonParts')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_HOBGOBLIN} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Хобгоблин`,
  nameEn: 'Hobgoblin',
  id: CREATURE_HOBGOBLIN,
  description: hobgoblinDescriptionList,
  note: hobgoblinNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GOBLINOID,
    CREATURE_TYPE_HOBGOBLIN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 294,
  },
  armor: {
    ac: 18,
    gearId: [GEAR_CHAIN_MAIL, GEAR_SHIELD],
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GOBLIN,
    LANG_COMMON,
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: `Воинское превосходство`,
      description: `Один раз в ход ★СУЩЕСТВО★ может причинить дополнительный урон 7 (2к6) существу, по которому ★он★ ★попал★ атакой оружием, если это существо находится в пределах 5 футов от ★его★ дееспособного союзника.`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
  genderId: GENDER_MALE,
}
