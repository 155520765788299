const {
  SOURCE_TOA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  GEAR_ACID,
  GEAR_ALCHEMIST_S_FIRE,
  GEAR_DAGGER,
  GEAR_SLING,
} = require('./../../../../gearIdList')
const {
  CR_1_4,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KOBOLD,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_KOBOLD_INVENTOR,
  CREATURE_SKUNK,
  CREATURE_SWARM_OF_CENTIPEDES,
  CREATURE_SWARM_OF_ROT_GRUBS,
  CREATURE_SWARM_OF_WASPS,
} = require('./../../../../creatureIdList')
const {
  ABILITY_PACK_TACTICS,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  koboldDescriptionList,
  koboldNote,
} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Кобольд изобретатель',
  nameEn: 'Kobold Inventor',
  id: CREATURE_KOBOLD_INVENTOR,
  description: [
    {
      header: 'Кобольды изобретатели',
      text: `У мастеровитых кобольдов-изобретателей ловкие руки, которыми они конструируют импровизированное оружие, которое, как они надеются, даст им преимущество в бою. Изобретатель ловит жуков, наскребает экзотическую слизь в подземелье и превращает в ингредиенты лучшую часть украденных вещей. Их творения часто выглядят комично, но, как и кобольдские ловушки, работают гораздо лучше, чем можно от штук, сделаных из такого барахла.`,
      source: [
        {
          id: SOURCE_VGTM,
          page: 160,
        },
        {
          id: SOURCE_TOA,
          page: 231,
        },
      ],
    },
    {
      header: 'Хороши, пока действуют',
      text: `Новые орудия кобольдов-изобретателей обычно выдерживают одну-две атаки перед тем, как сломаться, но зато при нанесении этих атак они удивительно действенны. Большинство изобретателей достаточно умелы, чтобы их импровизированные орудия не наносили вреда им самим, однако другие пользователи таких предметов могут быть менее удачливы. Эти оружия не обязательно смертоносны — во многих случаях их предназначение — отвлечь, испугать или запутать существо на время, достаточно долгое, чтобы другие кобольды успели убить врага. В каждом конкретном столкновении у изобретателя обычно есть при себе одно или два изобретения. `,
      source: [
        {
          id: SOURCE_VGTM,
          page: 160,
        },
        {
          id: SOURCE_TOA,
          page: 231,
        },
      ],
    },
    ...koboldDescriptionList,
  ],
  note: koboldNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_KOBOLD,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_VGTM,
      page: 160,
    },
    {
      id: SOURCE_TOA,
      page: 231,
    },
  ],
  armor: 12,
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 12,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_PACK_TACTICS,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
    {
      gearId: GEAR_SLING,
    },
    {
      name: 'Изобретение оружия',
      description: `★СУЩЕСТВО★ использует один из следующих вариантов (бросьте к8 или выберите один); ★СУЩЕСТВО★ может использовать не больше одного варианта один раз в день:

1. **Кислота.** ★СУЩЕСТВО★ бросает [флакон кислоты](GEAR:${GEAR_ACID}). Дальнобойная атака оружием: +4 к попаданию, дистанция 5/20 фт., одна цель. Попадание: 7 (2к6) урона кислотой.
2. **Алхимический огонь.** ★СУЩЕСТВО★ бросает [флакон алхимического огня](GEAR:${GEAR_ALCHEMIST_S_FIRE}). Дальнобойная атака оружием: +4 к попаданию, дистанция 5/20 фт., одна цель. Попадание: 2 (1к4) урона огнём в начале каждого хода цели. Существо может прекратить этот эффект, действием сделав проверку Ловкости СЛ 10 для того, чтобы затушить пламя.
3. **Корзина многоножек.** ★СУЩЕСТВО★ бросает небольшую корзинку в пространство 5 на 5 футов не далее 20 футов от себя. [Рой многоножек](CREATURE:${CREATURE_SWARM_OF_CENTIPEDES}) с 11 хитами появляется из корзины и делает бросок инициативы. В конце каждого хода роя существует шанс 50%, что он разбежится.
4. **Кувшин Зеленой Слизи.** ★СУЩЕСТВО★ кидает глиняный кувшин _зелёной слизи_ в цель и он разбивается при столкновении. Дальнобойная атака оружием: +4 к попаданию, дистанция 5/20 фт., одна цель. Попадание: Цель покрывается пятном _зелёной слизи_ (смотри главу 5 в Руководстве Мастера). Промах: Пятно _зелёной слизи_ покрывает случайное место 5 на 5 футов на стене или полу в 5 футах от цели.
5. **Кувшин трупных личинок.** ★СУЩЕСТВО★ кидает глиняный кувшин в пространство 5 на 5 футов не далее 20 футов от себя и разбивается при столкновении. [Рой трупных личинок](CREATURE:${CREATURE_SWARM_OF_ROT_GRUBS}) появляется из разбитого кувшина и является угрозой в этом месте.
6. **Скорпион на палочке.** ★СУЩЕСТВО★ совершает рукопашную атаку скорпионом, привязанным к концу 5-футовой палки. Рукопашная атака оружием: +4 к попаданию, досягаемость 5 фт., одна цель. Попадание: 1 колющего урона и цель должна пройти испытание Телосложения СЛ 9, получив 4 (1к8) урона ядом при провале или половину этого урона при успехе.
7. **Скунс в клетке.** ★СУЩЕСТВО★ выпускает [скунса](CREATURE:${CREATURE_SKUNK}) в свободное пространство в пределах 5 футов. У скунса скорость 20 футов, КД 10, 1 хит и нет эффективных атак. Он совершает бросок инициативы и в свой ход он использует действие _Струя мускуса_ в случайное существо в пределах 5 футов от себя. Цель должна пройти испытание Телосложения СЛ 9. При провале цель начинает рвать и она не может совершать действия 1 минуту. Цель может повторять испытание в конце каждого своего хода и окончить эффект при успехе. Существо, которому не требуется дышать или имеет иммунитет к яду, автоматически преуспевает в испытании. Когда скунс использовал _Струю мускуса_, он не может сделать это снова, не завершив короткий или длительный отдых.
8. **Осиное гнездо в сумке.** ★СУЩЕСТВО★ бросает маленькую сумку в пространство 5 на 5 футов не далее 20 футов от себя. [Рой ос](CREATURE:${CREATURE_SWARM_OF_WASPS}) с 11 хитами появляется из сумки и совершает бросок инициативы. В конце каждого хода стаи существует шанс 50%, что она разлетится.`,
    },
  ],
}
