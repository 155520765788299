const listToCollectionById = require('./../utils/listToCollectionById')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./genderList')

const MGC_RARITY_COMMON = 'common'
const MGC_RARITY_UNCOMMON = 'uncommon'
const MGC_RARITY_RARE = 'rare'
const MGC_RARITY_VERY_RARE = 'very_rare'
const MGC_RARITY_LEGENDARY = 'legendary'
const MGC_RARITY_ARTIFACT = 'artifact'

const magicItemRarityList = [
  {
    id: MGC_RARITY_COMMON,
    nameByGender: {
      [GENDER_FEMALE]: 'Обычная',
      [GENDER_MALE]: 'Обычный',
      [GENDER_MIDDLE]: 'Обычное',
    },
    nameEn: 'Common',
    pcLvl: 1,
    price: {
      min: 5000,
      max: 10000,
    },
  },
  {
    id: MGC_RARITY_UNCOMMON,
    nameByGender: {
      [GENDER_FEMALE]: 'Необычная',
      [GENDER_MALE]: 'Необычный',
      [GENDER_MIDDLE]: 'Необычное',
    },
    nameEn: 'Uncommon',
    pcLvl: 1,
    price: {
      min: 10100,
      max: 50000,
    },
  },
  {
    id: MGC_RARITY_RARE,
    nameByGender: {
      [GENDER_FEMALE]: 'Редкая',
      [GENDER_MALE]: 'Редкий',
      [GENDER_MIDDLE]: 'Редкое',
    },
    nameEn: 'Rare',
    pcLvl: 5,
    price: {
      min: 50100,
      max: 500000,
    },
  },
  {
    id: MGC_RARITY_VERY_RARE,
    nameByGender: {
      [GENDER_FEMALE]: 'Очень редкая',
      [GENDER_MALE]: 'Очень редкий',
      [GENDER_MIDDLE]: 'Очень редкое',
    },
    nameEn: 'Very rare',
    pcLvl: 11,
    price: {
      min: 500100,
      max: 5000000,
    },
  },
  {
    id: MGC_RARITY_LEGENDARY,
    nameByGender: {
      [GENDER_FEMALE]: 'Легендарная',
      [GENDER_MALE]: 'Легендарный',
      [GENDER_MIDDLE]: 'Легендарное',
    },
    nameEn: 'Legendary',
    pcLvl: 17,
    price: {
      min: 5000000,
      max: Infinity,
    },
  },
  {
    id: MGC_RARITY_ARTIFACT,
    nameByGender: {
      [GENDER_FEMALE]: 'Артефакт',
      [GENDER_MALE]: 'Артефакт',
      [GENDER_MIDDLE]: 'Артефакт',
    },
    nameEn: 'Artifact',
    pcLvl: Infinity,
    price: null,
  },
].map(
  magicItemRarity => ({
    ...magicItemRarity,
    name: magicItemRarity.nameByGender[GENDER_FEMALE],
  })
)

module.exports = magicItemRarityList
module.exports.magicItemRarityCollection = listToCollectionById(magicItemRarityList)

module.exports.MGC_RARITY_COMMON = MGC_RARITY_COMMON
module.exports.MGC_RARITY_UNCOMMON = MGC_RARITY_UNCOMMON
module.exports.MGC_RARITY_RARE = MGC_RARITY_RARE
module.exports.MGC_RARITY_VERY_RARE = MGC_RARITY_VERY_RARE
module.exports.MGC_RARITY_LEGENDARY = MGC_RARITY_LEGENDARY
module.exports.MGC_RARITY_ARTIFACT = MGC_RARITY_ARTIFACT
