const {SOURCE_EGTW} = require('./../../../sourceList')
const {PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC} = require('./../../../pcSubClassIdList')
const {dunamancySpellsTemplate} = require('./../../featureCommonRawList')
const {
  FEATURE_ADJUST_DENSITY,
  FEATURE_EVENT_HORIZON,
  FEATURE_GRAVITURGY_SPELL_LIST,
  FEATURE_GRAVITY_WELL,
  FEATURE_VIOLENT_ATTRACTION,
} = require('./../../../featureIdList')

const dunamancyCommonSpellIdList = require('./../../constants/dunamancyCommonSpellIdList')
const graviturgyOnlySpellIdList = require('./../../constants/graviturgyOnlySpellIdList')

module.exports = [
  {
    ...dunamancySpellsTemplate,
    id: FEATURE_GRAVITURGY_SPELL_LIST,
    spellIdList: [
      ...dunamancyCommonSpellIdList,
      ...graviturgyOnlySpellIdList,
    ],
  },
  {
    id: FEATURE_ADJUST_DENSITY,
    name: `Изменение плотности`,
    nameEn: `Adjust Density`,
    lvl: [2, 10],
    text: `Вы можете действием магически изменить вес одного видимого Вами в пределах 30 футов предмета или существа. Предмет или существо должно быть Большим или меньше. Вес цели ополовинивается или удваивается до 1 минуты или пока Ваша концентрация не закончится (как при концентрации на заклинании).

* Пока вес существа ополовинен этим эффектом, его скорость увеличивается на 10 футов, оно может прыгать вдвое дальше обычного и получает помеху на проверки и испытания Силы.
* Пока вес существа удвоен этим эффектом, его скорость уменьшается на 10 футов и оно получает преимущество на проверки и испытания Силы.

Достигнув 10 уровня в этом классе, Вы сможете выбрать целью этой способности предмет или существо Огромного размера или меньше.`,
    source: {
      id: SOURCE_EGTW,
      page: 186,
    },
  },
  {
    id: FEATURE_GRAVITY_WELL,
    name: `Гравитационный колодец`,
    nameEn: `Gravity Well`,
    lvl: 6,
    text: `Вы узнали как манипулировать гравитацией вокруг живых созданий.

Когда Вы сотворяете заклинание на существо, Вы можете передвинуть цель на 5 футов в свободное пространство по Вашему выбору, если цель согласна туда передвинуться, или если заклинание попадает по ней атакой, или если она проваливает испытание от этого заклинания.`,
    source: {
      id: SOURCE_EGTW,
      page: 186,
    },
  },
  {
    id: FEATURE_VIOLENT_ATTRACTION,
    name: `Бессердечная гравитация`,
    nameEn: `Violent Attraction`,
    lvl: 10,
    text: `Когда по другому видимому Вами в пределах 60 футов существу попадают атакой оружием, Вы можете реакцией увеличить скорость этой атаки, причиняя цели атаки дополнительно 1к10 урона такого же типа, что и урон оружия. 

Альтернативно, если существо в пределах 60 футов получает урон от падения, Вы можете реакцией увеличить урон от падения на 2к10.

Вы можете использовать эту способность число раз, равное Вашему модификатору Интеллекта (минимум однажды) и восстановите все использования, завершив длинный отдых.`,
    source: {
      id: SOURCE_EGTW,
      page: 186,
    },
  },
  {
    id: FEATURE_EVENT_HORIZON,
    name: `Горизонт событий`,
    nameEn: `Event Horizon`,
    lvl: 14,
    text: `Вы можете действием магически испустить мощное гравитационное поле, которое будет притягивать других существ до 1 минуты или пока Ваша концентрация не закончится (как при концентрации на заклинании). Всё это время, когда враждебное Вам существо начинает свой ход в пределах 30 футов от Вас, оно должно пройти испытание Силы.

* При провале, оно получает 2к10 урона силовым полем и его скорость уменьшается до 0 до начала его следующего хода.
* При успехе оно получает половину урона и за каждый фут передвижения на этом ходу оно должно тратить 2 дополнительных фута передвижения.

Использовав эту способность, Вы не можете сделать это снова, пока не закончите длинный отдых или не потратите на это ячейку заклинания 3 круга или выше.`,
    source: {
      id: SOURCE_EGTW,
      page: 186,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC,
  })
)
