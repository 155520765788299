const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  GEAR_WARHAMMER,
} = require('./../../../../../gearIdList')
const {
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_LOCATE_OBJECT,
} = require('./../../../../../spellIdList')
const {
  CREATURE_VAMPIRE,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WEAPON,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_WHELM,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_WHELM,
  name: `Сокрушитель`,
  nameEn: `Whelm`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_WARHAMMER,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `дварфом`,
  isSentient: true,
  description: `_Сокрушитель_ это мощный боевой молот, выкованный дварфами и утерянный в подземельях горы Белый Шлейф.

Вы получаете бонус +3 к броскам атаки и урона, совершённым этим магическим оружием. На рассвете следующего дня, после того как Вы впервые совершите бросок атаки _Сокрушителем_, у Вас развивается боязнь открытого пространства, которая остаётся, пока Вы настроены на это оружие. Из-за неё Вы совершаете с помехой броски атаки, испытания и проверки характеристик, если видите дневное небо.

# Метательное оружие

У _Сокрушителя_ есть свойство «метательное», его нормальная дистанция — 20 футов, а максимальная дистанция — 60 футов. Если Вы попадаете дальнобойной атакой оружием, используя его, цель получает дополнительный дробящий урон 1к8 или 2к8, если цель — великан. Каждый раз, когда Вы метаете это оружие, оно после атаки возвращается в Вашу руку. Если у Вас нет свободной руки, оружие падает у Ваших ног.

# Ударная волна

Вы можете действием ударить _Сокрушителем_ о землю, посылая ударную волну. Все существа на Ваш выбор, находящиеся в пределах 60 футов от точки удара, должны пройти испытание Телосложения СЛ 15, иначе они станут ошеломлёнными на 1 минуту. Существо может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. После использования, это свойство недоступно до следующего рассвета.

# Сверхъестественная бдительность

Пока Вы держите это оружие, оно предупреждает Вас о местонахождении всех потайных и скрытых дверей в пределах 30 футов от Вас. Кроме того, Вы можете действием наложить оружием заклинание [Обнаружение добра и зла](SPELL:${SPELL_DETECT_EVIL_AND_GOOD}) или [Поиск предмета](SPELL:${SPELL_LOCATE_OBJECT}). Сотворив заклинание, Вы до следующего рассвета больше не можете сотворять его этим оружием.

# Разум

_Сокрушитель_ — разумное принципиально-нейтральное оружие с Интеллектом 15, Мудростью 12 и Харизмой 15. Он обладает слухом и тёмным зрением в пределах 120 футов.

Это оружие может понимать Великаний, Гоблинский и Дварфийский языки, а также говорить и читать на них и телепатически общаться с владельцем. При использовании в сражении он издаёт боевые кличи на Дварфийском.

# Индивидуальность

Предназначение _Сокрушителя_ в уничтожении великанов и гоблиноидов. Он также стремится защищать дварфов от всех врагов. Если владелец отказывается уничтожать гоблинов и великанов или же защищать дварфов, возникает конфликт.

_Сокрушитель_ связан с создавшим его кланом Данкил (клан Могучего Молота). Он хочет вернуться в этот клан. Для защиты этих дварфов он готов вообще на всё.

У молота есть своя тайна. Несколько веков тому назад им владел дварф по имени Ктенмиир, ставший позже [вампиром](CREATURE:${CREATURE_VAMPIRE}). Его воля была такой сильной, что он использовал _Сокрушитель_ в злых целях, и даже убивал им членов своего клана.`,
  genderId: GENDER_MALE,
  spellIdList: [
    SPELL_DETECT_EVIL_AND_GOOD,
    SPELL_LOCATE_OBJECT,
  ],
  source: {
    id: SOURCE_DMG,
    page: 219,
  },
}
