const {CREATURE_TYPE_OWLIN} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_OWLIN,
  nameEn: 'Owlin',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'совин',
        genitive: 'совина',
        dative: 'совину',
        accusative: 'совина',
        instrumental: 'совином',
        prepositional: 'совине',
      },
      [GENDER_FEMALE]: {
        nominative: 'совина',
        genitive: 'совины',
        dative: 'совине',
        accusative: 'совину',
        instrumental: 'совиной',
        prepositional: 'совине',
      },
    },
    plural: {
      nominative: 'совины',
      genitive: 'совинов',
      dative: 'совинам',
      accusative: 'совинов',
      instrumental: 'совинами',
      prepositional: 'совинах',
    },
  },
}
