const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_DIRE_CORBY} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_DIRE_CORBY,
  nameEn: 'Dire_corby',
  genderId: GENDER_MALE,
  name: {
    singular: {
      nominative: 'жутевран',
      genitive: 'жутеврана',
      dative: 'жутеврану',
      accusative: 'жутеврана',
      instrumental: 'жутевраном',
      prepositional: 'жутевране',
    },
    plural: {
      nominative: 'жутевраны',
      genitive: 'жутевранов',
      dative: 'жутевранам',
      accusative: 'жутевранов',
      instrumental: 'жутевранами',
      prepositional: 'жутевранах',
    },
  },
}
