const {GENDER_MULTIPLE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {GEAR_BULLETS} = require('./../../../gearIdList')
const {
  CAT_AMMUNITION_FIREARM,
  CAT_EQUIPMENT,
  CAT_FIREARMS,
  CAT_TECH_RENAISSANCE,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_BULLETS,
  genderId: GENDER_MULTIPLE,
  name: 'Пули, 10 штук',
  nameEn: 'Bullets (10)',
  nameByCase: {
    nominative: 'пули',
    genitive: 'пуль',
    accusative: 'пули',
    instrumental: 'пулями',
  },
  description: {
    header: 'Боеприпасы',
    subheader: 'Ammunition',
    text: `Боеприпасы огнестрельного оружия уничтожаются при использовании.

Оружие эпохи Возрождения использует пули.`,
    source: {
      id: SOURCE_DMG,
      page: 267,
    },
  },
  cost: 300,
  weight: 2,
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
  category: [
    CAT_AMMUNITION_FIREARM,
    CAT_EQUIPMENT,
    CAT_FIREARMS,
    CAT_TECH_RENAISSANCE,
  ],
}
