const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_HOLY_AURA} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_HOLY_AURA,
  name: 'Аура святости',
  nameEn: `Holy Aura`,
  description: `Божественный свет исходит от Вас мягким сиянием в радиусе 30 футов. Пока заклинание активно, существа в этом радиусе, выбранные Вами при накладывании этого заклинания, испускают тусклый свет в радиусе 5 футов и проходит все испытания с преимуществом, а остальные существа совершают с помехой броски атаки по ним. Кроме того, если исчадие или нежить попадает по такому существу рукопашной атакой, аура вспыхивает ярким светом. Атакующий должен пройти испытание Телосложения, иначе станет ослеплённым, пока не окончится это заклинание.`,
  lvl: 8,
  magicSchoolId: MAGIC_ABJURATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `крошечный контейнер, стоящий как минимум 1 000 зм, хранящий священную реликвию, такую как лоскут ткани с мантии святого или кусочек пергамента с религиозным текстом`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CON,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 212,
    },
    {
      id: SOURCE_SRD,
      page: 155,
    },
  ],
}
