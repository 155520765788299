const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ABISHAI} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ABISHAI,
  nameEn: 'Abishai',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'абишай',
      genitive: 'абишая',
      dative: 'абишаю',
      accusative: 'абишая',
      instrumental: 'абишаем',
      prepositional: 'абишае',
    },
    plural: {
      nominative: 'абишаи',
      genitive: 'абишаев',
      dative: 'абишаям',
      accusative: 'абишаев',
      instrumental: 'абишаями',
      prepositional: 'абишаях',
    },
  },
}
