const {
    SOURCE_MOOT,
  } = require('./../../sourceList'),
  {
    GENDER_FEMALE,
    GENDER_MALE,
    GENDER_MIDDLE,
  } = require('./../../genderList'),
  {
    MGC_TYPE_ARMOR,
    MGC_TYPE_POTION,
    MGC_TYPE_WONDROUS_ITEM,
    MGC_TYPE_WEAPON,
  } = require('./../../magicItemTypeList'),
  {
    GEAR_ARROWS,
    GEAR_BREASTPLATE,
    GEAR_CHARIOT,
    GEAR_HALF_PLATE,
    GEAR_LYRE,
    GEAR_PLATE_ARMOR,
    GEAR_QUIVER,
    GEAR_SHORTBOW,
    GEAR_SLING,
    GEAR_SLING_BULLETS,
    GEAR_SMITHS_TOOLS,
    GEAR_TRIDENT,
    GEAR_WARHAMMER,
  } = require('./../../gearIdList'),
  {
    CREATURE_DRAGON_RED_ANCIENT,
    CREATURE_HYDRA,
    CREATURE_KRAKEN,
    CREATURE_SHADOW_DEMON,
    CREATURE_TIGER,
  } = require('./../../creatureIdList'),
  {
    SPELL_ANIMAL_FRIENDSHIP,
    SPELL_ANIMATE_OBJECTS,
    SPELL_AWAKEN,
    SPELL_BANISHMENT,
    SPELL_BLESS,
    SPELL_CHARM_PERSON,
    SPELL_DISSONANT_WHISPERS,
    SPELL_DOMINATE_MONSTER,
    SPELL_ENTANGLE,
    SPELL_ENTHRALL,
    SPELL_FABRICATE,
    SPELL_FAERIE_FIRE,
    SPELL_FLESH_TO_STONE,
    SPELL_GOODBERRY,
    SPELL_GUIDING_BOLT,
    SPELL_HARM,
    SPELL_HEAT_METAL,
    SPELL_HELLISH_REBUKE,
    SPELL_HEROISM,
    SPELL_ICE_STORM,
    SPELL_IDENTIFY,
    SPELL_INFLICT_WOUNDS,
    SPELL_INSECT_PLAGUE,
    SPELL_LESSER_RESTORATION,
    SPELL_MAGIC_WEAPON,
    SPELL_MAZE,
    SPELL_MENDING,
    SPELL_OTTO_S_IRRESISTIBLE_DANCE,
    SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
    SPELL_SANCTUARY,
    SPELL_SEARING_SMITE,
    SPELL_SHATTER,
    SPELL_SUGGESTION,
    SPELL_THUNDEROUS_SMITE,
    SPELL_TRUE_POLYMORPH,
    SPELL_TSUNAMI,
    SPELL_WALL_OF_THORNS,
  } = require('./../../spellIdList'),
  {
    MGC_RARITY_ARTIFACT,
    MGC_RARITY_RARE,
    MGC_RARITY_LEGENDARY,
    MGC_RARITY_VERY_RARE,
  } = require('./../../magicItemRarityList'),
  {
    MAGIC_ITEM_AKMON_HAMMER_OF_PURPHOROS,
    MAGIC_ITEM_DEKELLA_BIDENT_OF_THASSA,
    MAGIC_ITEM_EPHIXIS_BOW_OF_NYLEA,
    MAGIC_ITEM_FLYING_CHARIOT,
    MAGIC_ITEM_HELM_OF_THE_GODS,
    MAGIC_ITEM_MOLTEN_BRONZE_SKIN,
    MAGIC_ITEM_POTION_OF_AQUEOUS_FORM,
    MAGIC_ITEM_PYXIS_OF_PANDEMONIUM,
    MAGIC_ITEM_SIREN_SONG_LYRE,
    MAGIC_ITEM_SLING_BULLETS_OF_ALTHEMONE,
    MAGIC_ITEM_TWO_BIRDS_SLING,
  } = require('./../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_EPHIXIS_BOW_OF_NYLEA,
    name: 'Эфизис, лук Нилеи',
    nameEn: 'Ephixis, Bow of Nylea',
    type: MGC_TYPE_WEAPON,
    rarity: MGC_RARITY_ARTIFACT,
    attunement: true,
    gearType: GEAR_SHORTBOW,
    description: [
      `Нилея носит короткий лук _Эфизис_, чьи стрелы объявляют смену сезонов года. Нилея легко позволяет своим избранным последователям одалживать её лук, особенно когда они собираются использовать его для свершения великих деяний, положить конец злобным существам или свергать высокомерных.`,
      {
        header: 'Лук дикости',
        text: `Это божественное оружие включает в себя [короткий лук](GEAR:${GEAR_SHORTBOW}) и [колчан](GEAR:${GEAR_QUIVER}) с четырьмя [стрелами](GEAR:${GEAR_ARROWS}), каждая из которых связана с определённым временем года.

_Эфизис_ имеет бонус +3 к броскам атаки и урона и Вы не получаете помеху к атакам из-за превышения нормальной дистанции. Кроме того, _Эфизис_ совершает критическое попадание при выпадении «19» или «20» на к20.`,
      },
      {
        header: 'Благословение дикости',
        text: `Если Вы поклоняетесь Нилее, то получаете все следующие преимущества, для которых Вам хватит набожности.

## Набожность 10+

_Эфизис_ имеет 1 случайное малое положительное свойство.

## Набожность 25+

_Эфизис_ имеет 1 случайное основное положительное свойство.

## Нет поклонения

Если Вы не поклоняетесь Нилее, _Эфизис_ имеет 1 случайное основное отрицательное свойство.`,
      },
      {
        header: 'Стрелы сезонов',
        text: `Четыре стрелы — каждая из которых связана с определённых временем года. Они сопутствуют _Эфизису_ и могут быть выпущены только из него. Каждая из стрел исчезает сразу после использования и снова появляется в колчане на закате. СЛ испытания от заклинаний стрел равна 18.

## Весна

Вы можете действием выстрелить этой стрелой, целясь в видимых Вами в пределах 320 футов зверя или существо-растение. При попадании стрела не наносит урона, а цель получит преимущества заклинания [Пробуждение разума](SPELL:${SPELL_AWAKEN}) на 8 часов.

## Лето

Вы можете действием выстрелить этой стрелой в видимое Вами в пределах 320 футов свободное пространство на земле, без броска атаки. В это место призывается никсорождённая рысь ([тигр](CREATURE:${CREATURE_TIGER}) с особенностями никсорождённого существа). Рысь понимает Ваши голосовые команды и подчиняется им так хорошо, как только может и совершает свой ход сразу после Вашего. Эмиссар остаётся на 1 час, после чего исчезает.

## Осень

Вы можете действием выстрелить этой стрелой в видимое Вами в пределах 320 футов свободное пространство на земле, без броска атаки. Там сотворяется заклинание [Терновая стена](SPELL:${SPELL_WALL_OF_THORNS}).

## Зима

Вы можете действием выстрелить этой стрелой в видимое Вами в пределах 320 футов свободное пространство на земле, без броска атаки. Там сотворяется заклинание [Град](SPELL:${SPELL_ICE_STORM}).`,
      },
      {
        header: 'Разрушение Эфизиса',
        text: `Лук Нилеи не может быть уничтожен, пока целы его стрелы.

Стрелу зимы нужно скормить церберу в день зимнего солнцестояния. Стрелу весны нужно посадить в основание Чёрного Дуба из Асфодели в день весеннего равноденствия. Летнюю стрелу должен сломать морской великан в день летнего солнцестояния. А осеннюю стрелу нужно выстрелить в труп [гидры](CREATURE:${CREATURE_HYDRA}) в день осеннего равноденствия.

Когда все четыре стрелы будут уничтожены, лук и колчан рассыпаются в прах.`,
      },
    ],
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_AWAKEN,
      SPELL_WALL_OF_THORNS,
      SPELL_ICE_STORM,
    ],
    source: {
      id: SOURCE_MOOT,
      page: 201,
    },
  },
  {
    id: MAGIC_ITEM_DEKELLA_BIDENT_OF_THASSA,
    name: `Декелла, двузубец Тассы`,
    nameEn: `Dekella, Bident of Thassa`,
    type: MGC_TYPE_WEAPON,
    rarity: MGC_RARITY_ARTIFACT,
    attunement: true,
    gearType: GEAR_TRIDENT,
    description: [
      `Тасса владеет _Декеллой_, двузубым оружием, подаренным ей Пирфором. Когда богиня моря дарует своё оружие смертным, то обычно для того чтобы они несли её волю вдаль от океана; чтобы исправили что-то, затрагивающее тех, кого она предпочла бы оставить невредимыми; или чтобы дать смертным проблеск своих огромных обязанностей.`,
      {
        header: 'Двузубец глубин',
        text: `Знаковое оружие Тассы шумит ледяными течениями глубин океана.

Это оружие действует как магический [трезубец](GEAR:${GEAR_TRIDENT}) и имеет бонус +3 к броскам атаки и урона. Когда Вы попадаете им атакой, цель получает дополнительно 2к10 урона холодом.`,
      },
      {
        header: 'Благословение глубин',
        text: `Если Вы поклоняетесь Тассе, то получаете все следующие преимущества, для которых Вам хватит набожности.

## Набожность 10+

Вы можете дышать под водой и получаете скорость плавания 60 футов

## Набожность 25+

_Декелла_ имеет 1 случайное малое положительное свойство.

## Набожность 50+

_Декелла_ имеет 1 случайное основное положительное свойство.

## Нет поклонения

Если Вы не поклоняетесь Тассе, _Двузубец_ имеет 1 случайное малое и 1 случайное основное отрицательные свойства.`,
      },
      {
        header: 'Командование глубинами',
        text: `_Декелла_ содержит мощь командовать волнами и их жителями.

Вы можете действием изменять состояние моря в пределах 1 мили, создавая сильный ветер и дождь, нагнетающие огромные волны, или же, наоборот, успокаивая бурю. В обоих случаях неестественная погода сохраняется на 1 час, после чего она возвращается к нормальной. Использовав _Двузубец_ для этого, Вы не сможете использовать эту его способность снова до следующего заката.

Кроме того, Вы можете сотворять _Декеллой_ заклинание [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}) (СЛ испытания 18), но можете выбирать целью только зверей и монстров, у которых есть врождённая скорость плавания. Использовав _Двузубец_ для этого, Вы не сможете использовать эту его способность снова до следующего заката.`,
      },
      {
        header: 'Водная метаморфоза',
        text: `Вы можете сотворять _Декеллой_ заклинание [Истинное превращение](SPELL:${SPELL_TRUE_POLYMORPH}) (СЛ испытания 18), но можете только превращать существо в существо с врождённое скоростью плавания. Использовав _Двузубец_ для этого, Вы не сможете использовать эту его способность снова до следующего заката.`,
      },
      {
        header: 'Разрушение Декеллы',
        text: `Чтобы уничтожить _Двузубец Тассы_, его нужно раскалить дыханием [древнего красного дракона](CREATURE:${CREATURE_DRAGON_RED_ANCIENT}) и потом, пока он ещё раскалён, погрузить его в реку Тартикс.`,
      },
    ],
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_DOMINATE_MONSTER,
      SPELL_TRUE_POLYMORPH,
    ],
    source: {
      id: SOURCE_MOOT,
      page: 200,
    },
  },
  {
    id: MAGIC_ITEM_AKMON_HAMMER_OF_PURPHOROS,
    name: `Акмон, молот Пирфора`,
    nameEn: `Akmon, Hammer of Purphoros`,
    type: MGC_TYPE_WEAPON,
    rarity: MGC_RARITY_ARTIFACT,
    attunement: true,
    gearType: GEAR_WARHAMMER,
    description: [
      `_Молот Пирфора_ творит как чудеса, так и бедствия. Бог кузницы использует его в равной мере для создания новаторских чудес и опасных изобретений. Пирфор редко дарует _Акмон_ смертным, но иногда оставляет его без присмотра в своей кузнице на горе Велус. Когда Пирфор позволяет смертному использовать _Молот_, обычно это делается для того, чтобы тот мог проделать важную работу, сокрушить разрушительную силу или выковать что-то выдающееся там, где богу не рады.`,
      {
        header: 'Молот кузницы',
        text: `Этот магический [боевой молот](GEAR:${GEAR_WARHAMMER}) имеет бонус +3 к броскам атаки и урона. Когда Вы попадаете им атакой, цель получает дополнительно 3к10 урона огнём.`,
      },
      {
        header: 'Благословение кузницы',
        text: `Если Вы поклоняетесь Пирфору, то получаете все следующие преимущества, для которых Вам хватит набожности.

## Набожность 10+

_Акмон_ имеет 1 случайное малое положительное свойство.

## Набожность 25+

_Акмон_ имеет 1 случайное основное положительное свойство.

## Набожность 50+

_Акмон_ имеет 1 дополнительное случайное основное положительное свойство.

## Нет поклонения

Если Вы не поклоняетесь Пирфору, _Акмон_ имеет 2 случайных малых отрицательных свойства.`,
      },
      {
        header: 'Перекованный',
        text: `Держа _Молот Пирфора_, Вы получаете сопротивление огню и иммунитет к истощению.

Кроме того, Вы владеете [инструментами кузнеца](GEAR:${GEAR_SMITHS_TOOLS}) и получаете преимущество ко всем проверкам характеристик, совершённых с ними.`,
      },
      {
        header: 'Заклинания',
        text: `Держа _Акмон_, Вы можете действием сотворить одно из заклинаний ниже (СЛ испытания 18).

* [Дребезги](SPELL:${SPELL_SHATTER}),
* [Изготовление](SPELL:${SPELL_FABRICATE}),
* [Магическое оружие](SPELL:${SPELL_MAGIC_WEAPON}),
* [Оживление вещей](SPELL:${SPELL_ANIMATE_OBJECTS}),
* [Починка](SPELL:${SPELL_MENDING}),
* [Раскалённый металл](SPELL:${SPELL_HEAT_METAL}).

Использовав _Молот Пирфора_ для сотворения заклинания, Вы не сможете использовать его снова для сотворения этого же заклинания до следующего заката.`,
      },
      {
        header: 'Разрушение Акмона',
        text: `Чтобы уничтожить _Молот Пирфора_, его нужно перенести в Тизерий, что в Подземном царстве. Там он должен быть покрыт глиной из Трясины Наказания. Жар _Молота_ обожжёт глину, которая через месяц сольётся с ним. После полного затвердевания покрытый глиной _Акмон_ должен проглотить и переварить [кракен](CREATURE:${CREATURE_KRAKEN}).`,
      },
    ],
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_ANIMATE_OBJECTS,
      SPELL_FABRICATE,
      SPELL_HEAT_METAL,
      SPELL_MAGIC_WEAPON,
      SPELL_MENDING,
      SPELL_SHATTER,
    ],
    source: {
      id: SOURCE_MOOT,
      page: 199,
    },
  },
  {
    id: MAGIC_ITEM_TWO_BIRDS_SLING,
    name: `Двузайцевая праща`,
    nameEn: `Two-Birds Sling`,
    type: MGC_TYPE_WEAPON,
    rarity: MGC_RARITY_RARE,
    gearType: GEAR_SLING,
    description: `Это оружие имеет бонус +1 к броскам атаки и урона.

Когда Вы совершаете дальнобойную атаку _Двузайцевой пращёй_ и попадаете по цели, Вы можете заставить снаряд отскочить во вторую цель в пределах 10 футов от первой. Совершите дальнобойную атаку по второй цели.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_MOOT,
      page: 199,
    },
  },
  {
    id: MAGIC_ITEM_SLING_BULLETS_OF_ALTHEMONE,
    name: `Ядра для пращи Альтемоны`,
    nameEn: `Sling Bullets of Althemone`,
    type: MGC_TYPE_WEAPON,
    rarity: MGC_RARITY_VERY_RARE,
    isConsumable: true,
    gearType: GEAR_SLING_BULLETS,
    description: `_Ядра для пращи Альтемоны_ находятся в кошеле, содержащем 1к4 + 4 ядер. Бросьте по таблице ниже для каждого ядра, чтобы определить его магическую способность.

Эти магические ядра имеют бонус +2 к броскам атаки и урона.

Если ядро промахивается по цели, оно телепортируется обратно в кошель. Как только ядро попадает в цель, оно теряет свою магию.

| к4 | Ядро |
|----|------|
| 1  | **Изгнание.** Существо, получившее урон от этого ядра, должно пройти испытание Харизмы СЛ 15 или будет изгнано, как если бы на него подействовал эффект заклинания [Изгнание](SPELL:${SPELL_BANISHMENT}) |
| 2  | **Фульгурация.** При попадании это ядро наносит цели дополнительно 2к8 урона электричеством. Все другие существа в пределах 10 футов от цели должны пройти испытание Телосложения СЛ 15 или получить 1к8 урона звуком. |
| 3  | **Оглушение.** При попадании это ядро наносит цели дополнительно 1к10 урона силовым полем и цель ошеломляется до конца Вашего следующего хода. |
| 4  | **Выслеживание.** Существо, по которому попало ядро, в месте удара помечается светящейся руной. Эта метка существует 24 часа. Пока существо помечено, Вы всегда знаете направление до него. |`,
    spellIdList: [SPELL_BANISHMENT],
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_MOOT,
      page: 199,
    },
  },
  {
    id: MAGIC_ITEM_SIREN_SONG_LYRE,
    name: `Лира песни сирены`,
    nameEn: `Siren Song Lyre`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    gearType: GEAR_LYRE,
    attunement: true,
    description: `Вы можете действием сыграть на _Лире песни сирены_, сотворяя одно из её заклинаний (СЛ испытания 13).

* [Внушение](SPELL:${SPELL_SUGGESTION})
* [Дружба с животными](SPELL:${SPELL_ANIMAL_FRIENDSHIP})
* [Очарование личности](SPELL:${SPELL_CHARM_PERSON})
* [Речь златоуста](SPELL:${SPELL_ENTHRALL})

Использовав _Лиру песни сирены_ для сотворения заклинания, Вы не сможете использовать её снова для сотворения этого же заклинания до следующего рассвета.`,
    spellIdList: [
      SPELL_ANIMAL_FRIENDSHIP,
      SPELL_CHARM_PERSON,
      SPELL_ENTHRALL,
      SPELL_SUGGESTION,
    ],
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_MOOT,
      page: 199,
    },
  },
  {
    id: MAGIC_ITEM_PYXIS_OF_PANDEMONIUM,
    name: `Пиксида Пандемония`,
    nameEn: `Pyxis of Pandemonium`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_LEGENDARY,
    description: `Существо, касающееся в течение 1 минуты этого разукрашенного деревянного сосуда, получает преимущества короткого отдыха. Это существо также получает эффект заклинания [Благословение](SPELL:${SPELL_BLESS}), пока не завершит короткий или длинный отдых. Это существо не может снова получить эти преимущества, пока не завершит длинный отдых.

Если _Пиксиду Пандемония_ открыть, бросьте по таблице ниже, чтобы определить — что именно проихойдёт. Все заклинания _Пиксиды_ имеют СЛ 17. Через минуту после открытия _Пиксида Пандемония_ исчезает. Она появляется снова через 24 часа в случайном месте на том же плане существования.

| к8 | Беда |
|----|------|
| 1  | **Андрофагия.** Каждое существо в пределах 60 футов от _Пиксиды_ должно пройти испытание Мудрости СЛ 17, иначе станет берсерком на 1 минуту. Существо-берсерк должно начать свой ход с действия Атака, совершая одну рукопашную или дальнобойную атаку (по его выбору) по ближайшему к нему существу. Существо-берсерк может повторять испытание в конце своего хода, оканчивая эффект при успехе. |
| 2  | **Желчная отрава.** _Пиксида Пандемония_ сотворяет заклинание [Поражение](SPELL:${SPELL_HARM}) на всех существ в пределах 30 футов. |
| 3  | **Потоп.** _Пиксида Пандемония_ сотворяет заклинание [Цунами](SPELL:${SPELL_TSUNAMI}) в точке по выбору Мастера в пределах 120 футов. |
| 4  | **Вгляд Медузы.** _Пиксида Пандемония_ сотворяет заклинание [Окаменение](SPELL:${SPELL_FLESH_TO_STONE}) на всех существ в пределах 30 футов. |
| 5  | **Лабиринт.** _Пиксида Пандемония_ сотворяет заклинание [Лабиринт](SPELL:${SPELL_MAZE}) на всех существ в пределах 30 футов. |
| 6  | **Кошмар.** Сгустки тьмы вырываются из _Пиксиды_, формируя 1к4 враждебных [Теневых демонов](CREATURE:${CREATURE_SHADOW_DEMON}) в свободных пространствах в пределах 30 футов. |
| 7  | **Рой насекомых.** _Пиксида Пандемония_ сотворяет заклинание [Нашествие насекомых](SPELL:${SPELL_INSECT_PLAGUE}) с  радиусом 30 футов и центром на себе. |
| 8  | **Буйное веселье.** _Пиксида Пандемония_ сотворяет заклинание [Неудержимая пляска Отто](SPELL:${SPELL_OTTO_S_IRRESISTIBLE_DANCE}) на всех существ в пределах 30 футов. |

`,
    curse: `Существо, получившее от _Пиксиды Пандемония_ преимущества короткого отдыха, слышит издаваемый ей надоедливый телепатический шёпот.

Это существо должно пройти испытание Мудрости СЛ 17, при провале очаровываясь _Пиксидой_. Очарованое _Пиксидой Пандемония_ существо делает всё возможное, чтобы как можно скорее открыть _Пиксиду_. При успехе существо становится невосприимчивым к шёпоту _Пиксиды_ на 24 часа.`,
    spellIdList: [
      SPELL_FLESH_TO_STONE,
      SPELL_HARM,
      SPELL_INSECT_PLAGUE,
      SPELL_MAZE,
      SPELL_OTTO_S_IRRESISTIBLE_DANCE,
      SPELL_TSUNAMI,
    ],
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_MOOT,
      page: 198,
    },
  },
  {
    id: MAGIC_ITEM_POTION_OF_AQUEOUS_FORM,
    name: `Зелье водной формы`,
    nameEn: `Potion of Aqueous Form`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_RARE,
    isConsumable: true,
    description: `Выпив это зелье, Вы превратитесь в лужу воды. Вы вернётесь в истинную форму через 10 минут или если станете недееспособны или умрёте.

Будучи в водной форме, Вы получаете нижеперечисленные эффекты.

# Жидкое передвижение

Вы получаете скорость плавания 30 футов.

Вы можете двигаться поверх или сквозь другие жидкости.

Вы можете входить в и занимать пространство другого существа.

Вы можете вырастать до своего обычного роста и можете проходить сквозь даже крохотные отверстия.

Вы тушите немагический огонь в пространстве, куда входите.

# Водяная гибкость

Вы получаете сопротивление к немагическому урону.

Вы также получаете преимущество к испытаниям Силы, Ловкости и Телосложения.

# Ограничения

Вы не можете говорить, атаковать, сотворять заклинания и активировать магические предметы.

Все предметы, которые Вы несли и носили, растворяются в Вашей новой форме и теперь недоступны, хотя Вы получаете все преимущества от носимых предметов, таких как доспехи.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_MOOT,
      page: 198,
    },
  },
  {
    id: MAGIC_ITEM_MOLTEN_BRONZE_SKIN,
    name: `Кожа из расплавленной бронзы`,
    nameEn: `Molten Bronze Skin`,
    type: MGC_TYPE_ARMOR,
    rarity: MGC_RARITY_RARE,
    gearType: [
      GEAR_BREASTPLATE,
      GEAR_PLATE_ARMOR,
      GEAR_HALF_PLATE,
    ],
    attunement: true,
    description: `Этот магический доспех выглядит как кувшин с расплавленной бронзой.

Когда Вы настраиваетесь на него, расплавленная бронза покрывает Вашу кожу, повторяя контуры тела. Этот доспех можно носить под обычной одеждой и он никак не мешает нормальному функционированию Вашего тела. После того, как Вы его наденете, его нельзя будет снять с Вас, если Вы только Вы сами не решите это сделать.

Пока Вы носите этот доспех, Вы получаете сопротивление к урону от огня. Доспех также не накладывает помеху к Вашим проверкам Ловкости (Скрытность).`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_MOOT,
      page: 198,
    },
  },
  {
    id: MAGIC_ITEM_HELM_OF_THE_GODS,
    name: `Шлем богов`,
    nameEn: `Helm of the Gods`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Нося _Шлем богов_, Вы знаете — есть ли в пределах 30 футов исчадие или небожитель и где это существо находится, если оно не за полным укрытием.

Когда Вы завершаете длинный отдых, нося этот _Шлем_, Вы можете помолиться одному из богов, перечисленных в таблице ниже и сохранить в _Шлеме_ соответствующее заклинание, заменяя любое заклинание, сохранённое там раньше.

У _Шлема богов_ есть 3 заряда. Вы можете потратить 1 заряд для сотворения хранящегося в нём заклинания (СЛ испытания 13). _Шлем_ восстанавливает 1к3 заряда на рассвете.

| Бог       | Заклинание |
|-----------|------------|
| Атрей     | [Защита от добра и зла](SPELL:${SPELL_PROTECTION_FROM_EVIL_AND_GOOD}) |
| Гелиод    | [Направленный снаряд](SPELL:${SPELL_GUIDING_BOLT }) |
| Ирой      | [Героизм](SPELL:${SPELL_HEROISM}) |
| Караметра | [Чудо-ягоды](SPELL:${SPELL_GOODBERRY}) |
| Керан     | [Громовая кара](SPELL:${SPELL_THUNDEROUS_SMITE}) |
| Клотис    | [Опутывание](SPELL:${SPELL_ENTANGLE}) |
| Круфикс   | [Диссонирующий шёпот](SPELL:${SPELL_DISSONANT_WHISPERS}) |
| Могий     | [Адское возмездие](SPELL:${SPELL_HELLISH_REBUKE}) |
| Нилея     | [Огонь фей](SPELL:${SPELL_FAERIE_FIRE }) |
| Пирфор    | [Палящая кара](SPELL:${SPELL_SEARING_SMITE}) |
| Тасса     | [Опознание](SPELL:${SPELL_IDENTIFY}) |
| Фарика    | [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}) |
| Фенакс    | [Очарование личности](SPELL:${SPELL_CHARM_PERSON }) |
| Эреб      | [Нанесение ран](SPELL:${SPELL_INFLICT_WOUNDS }) |
| Эфара     | [Убежище](SPELL:${SPELL_SANCTUARY}) |
`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_CHARM_PERSON,
      SPELL_DISSONANT_WHISPERS,
      SPELL_ENTANGLE,
      SPELL_FAERIE_FIRE,
      SPELL_GOODBERRY,
      SPELL_GUIDING_BOLT,
      SPELL_HELLISH_REBUKE,
      SPELL_HEROISM,
      SPELL_IDENTIFY,
      SPELL_INFLICT_WOUNDS,
      SPELL_LESSER_RESTORATION,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_SANCTUARY,
      SPELL_SEARING_SMITE,
      SPELL_THUNDEROUS_SMITE,
    ],
    source: {
      id: SOURCE_MOOT,
      page: 197,
    },
  },
  {
    id: MAGIC_ITEM_FLYING_CHARIOT,
    name: `Летающая колесница`,
    nameEn: `Flying Chariot`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    gearType: GEAR_CHARIOT,
    description: `Возница, пассажиры колесницы и тянущие её существа получают +1 КД.

Если эту магическую колесницу тянут одно или больше летающих существ, она тоже может летать.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_MOOT,
      page: 197,
    },
  },
]
