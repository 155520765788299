const {
  CAST_MATERIAL,
  CAST_SOMATIC,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {MAGIC_DIVINATION} = require('./../../../../../magicList')
const {SOURCE_EGTW} = require('./../../../../../sourceList')
const {SPELL_FORTUNE_S_FAVOR} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FORTUNE_S_FAVOR,
  name: 'Благосклонность судьбы',
  nameEn: 'Fortune’s Favor',
  description: `Вы наделяете скрытой удачей себя или одно согласное существо, видимое Вами в пределах дистанции.

Когда, пока заклинание действует, выбранное существо совершает атаку, проверку способности или проходит испытание, оно может прекратить действие этого заклинание на себе, чтобы бросить дополнительный к20 и выбрать, какой из к20 использовать.

Альтернативно, когда по выбранному существу совершается атака, оно может прекратить действие этого заклинание на себе, чтобы бросить к20 и выбрать, какой из к20 использовать — свой к20 или к20 атакующего.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, Вы можете выбрать целью одно дополнительное существо за каждый круг ячейки выше второго.`,
  lvl: 2,
  magicSchoolId: MAGIC_DIVINATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `белая жемчужина стоимостью не менее 100 зм, расходуемые заклинанием`,
  hasCost: true,
  hasConsumable: true,
  isDunamisBased: true,
  castTime: { timeId: TIME_MINUTE, count: 1 },
  duration: { timeId: TIME_HOUR, count: 1 },
  source: {
    id: SOURCE_EGTW,
    page: 187,
  },
}
