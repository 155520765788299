const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_SKYWRITE} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_SKYWRITE,
  name: 'Небесные письмена',
  nameEn: 'Skywrite',
  description: `В указанной Вами части неба, которую Вы можете видеть, возникает до десяти слов. Слова выглядят так, словно состоят из облаков, и остаются на одном месте, пока действует заклинание, после чего рассеиваются. Сильный ветер может рассеять их и досрочно закончить заклинание.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: `Видимость`,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 1},
  isRitual: true,
  needConcentration: true,
  source: [
    {
      id: SOURCE_XGTE,
      page: 158,
    },
    {
      id: SOURCE_EE,
      page: 18,
    },
  ],
}
