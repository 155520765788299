const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_FIRE_ELEMENTAL} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {elementalDescription} = require('./../../../../textCommonParts')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_IGNAN} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Огненный элементаль`,
  nameEn: 'Fire Elemental',
  id: CREATURE_FIRE_ELEMENTAL,
  description: [
    `В середине этого дикого, ревущего пламени можно разглядеть человекоподобную фигуру. Огненный элементаль это чистая мощь разрушения. Куда бы он не двигался, всё вокруг полыхает, мир превращается в пепел, дым и тлеющие угли. Только вода прекратит разрушения, заставляя элементаля шипя и пуская клубы дыма отступить в боли и ярости.`,
    elementalDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 304,
  },
  armor: 13,
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 16,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_UNCONSCIOUS,
    CONDITION_GRAPPLED,
    CONDITION_EXHAUSTION,
    CONDITION_PETRIFIED,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_IGNAN,
  ],
  cr: CR_5,
  featureList: [
    {
      name: `Огненное тело`,
      description: `★СУЩЕСТВО★ может перемещаться сквозь пространство шириной в 1 дюйм без протискивания. Существо, касающееся ★СУЩЕСТВО★ или попадающее по ★нему★ рукопашной атакой, находясь в пределах 5 футов от него, получает урон огнём 5 (1к10). Кроме того, ★СУЩЕСТВО★ может входить в пространство враждебных существ и останавливаться там. Когда ★он★ впервые за ход входит в пространство существа, это существо получает урон огнём 5 (1к10) и загорается; пока кто-нибудь не потушит огонь действием, это существо получает урон огнём 5 (1к10) в начале каждого своего хода.`,
    },
    {
      name: `Свечение`,
      description: `★СУЩЕСТВО★ испускает яркий свет в радиусе 30 футов и тусклый свет в пределах ещё 30 футов.`,
    },
    {
      name: `Восприимчивость к воде`,
      description: `За каждые 5 футов перемещения в воде, или за каждый вылитый на ★него★ галлон воды, ★СУЩЕСТВО★ получает урон холодом 1.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Касанием_.`,
    },
    {
      name: `Касание`,
      description: `Если цель — существо или горючий предмет, она вспыхивает. Пока какое-нибудь существо не потушит огонь действием, цель получает урон огнём 5 (1к10) в начале каждого своего хода.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
