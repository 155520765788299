const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ELEMENTAL,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_N,
  } = require('./../../../../aligmentList'),
  {
    SPEED_SWIM,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_AQUAN,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_PURIFY_FOOD_AND_DRINK,
  } = require('./../../../../spellIdList'),
  {
    CONDITION_EXHAUSTION,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
    CONDITION_UNCONSCIOUS,
  } = require('./../../../../conditionList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_FEMALE,
  } = require('./../../../../genderList'),
  {
    CREATURE_WATER_WEIRD,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Водная аномалия',
  nameEn: 'Water Weird',
  id: CREATURE_WATER_WEIRD,
  description: `Водная аномалия является элементалем-защитником, связанным с определённым местом, заполненным водой, таким как бассейн или фонтан. Её змеевидное тело, невидимое, пока погружено в воду, становится заметным только когда она появляется, чтобы напасть, обвиваясь вокруг любого существа, кроме её призывателя и тех, кому разрешит находиться призыватель. Когда водная аномалия повержена, она становится неживой лужей воды.

# Добрые и злые аномалии

Как у большинства элементалей, у водной аномалии нет понятия добра или зла. Однако аномалия, связанная со священным или осквернённым источником воды, начинает черпать природу того места, становясь нейтрально-доброй или нейтрально-злой.

Нейтрально-добрая водная аномалия старается отпугнуть незваных гостей, а не убить их, в то время как нейтрально-злая с удовольствием убьёт их, и даже может предать своего призывателя. Водная аномалия теряет своё злое мировоззрение, если её источник очистить при помощи заклинания [Очищение пищи и питья](SPELL:${SPELL_PURIFY_FOOD_AND_DRINK}).

# Стихийная натура

Водной аномалии не нужен воздух, еда, питьё и сон.`,
  note: {
    text: `Прежде чем попить из фонтана или бассейна, бросьте в него медную монету. Это маленькая цена за вашу жизнь`,
    author: `второе правило выживания в подземельях Мистика Х`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MM,
    page: 43,
  },
  armor: 13,
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_SWIM]: 60,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 13,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_UNCONSCIOUS,
    CONDITION_GRAPPLED,
    CONDITION_EXHAUSTION,
    CONDITION_RESTRAINED,
    CONDITION_POISONED,
    CONDITION_PARALYZED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  languageList: [
    {
      id: LANG_AQUAN,
      doNotSpeak: true,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Невидимость в воде',
      description: `★СУЩЕСТВО★ невидима, если полностью погружена в воду.`,
    },
    {
      name: 'Привязка к воде',
      description: `★СУЩЕСТВО★ умирает, если покидает воду, к которой ★привязан★, или если эта вода уничтожается.`,
    },
  ],
  actionList: [
    {
      name: 'Сжимание',
      description: `Если размер цели не больше Среднего, она становится схваченной (СЛ высвобождения 13) и подтягивается на 5 футов к ★СУЩЕСТВО★. Пока цель схвачена, она обездвижена, ★СУЩЕСТВО★ пытается её утопить, и ★СУЩЕСТВО★ не может сжимать другую цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
