module.exports = [
  require('./tarrasque'),
  require('./thri_kreen'),
  require('./thug'),
  require('./tiger'),
  require('./toad'),
  require('./treant'),
  require('./tribal_warrior'),
  require('./triceratops'),
  require('./tridrone'),
  require('./troglodyte'),
  require('./troll'),
  require('./twig_blight'),
  require('./tyrannosaurus_rex'),
]
