const {SOURCE_MTOF} = require('./../../sourceList')

module.exports = [
  {
    header: 'Крутики',
    text: `Крутики — это покрытые хитином рептилии, которые охотятся в стаях и гнездятся в размашистых подземных сетевых туннелях. Они тянутся к источникам тепла, таким как дварфийские кузницы и лавовые озёра, и роют свои логова максимально близко к таким местам. Закопавшись в землю, они живут там, в туннелях (что часто являющееся подсказкой, что поблизости обитают крутики). Крутики используют существовавшие ранее подземные проходы, и иногда сооружают в них свои логова.

Крутики общаются между собой через серию шипений и щёлкающих звуков. Эти звуки, чаще всего, можно услышать заранее, до нападения крутиков. Когда в их логово вторгаются, сторожащие крутики посылают, своего рода, предупреждения, стремительно постукивая по каменной поверхности своими острыми ножками.

**Острое чутьё.** Помимо своего обострённого обоняния, крутики могут видеть в темноте и распознавать различные колебания вокруг них. Запах трупов их сородичей они воспринимают как предостережение, и избегают те места, в которых погибло множество других крутиков. Убийство большого количества крутиков в одной зоне может заставить оставшуюся часть улья переместиться в другую территорию.

**Но конечности острее.** Хоть и питаются падалью, крутики больше предпочитают живую добычу. Они убивают своих противников, пронзая их острыми конечностями, и измельчая плоть и кости челюстями, способными пережевать даже камень. Если несколько крутиков соберутся против одного противника, они становятся намного более яростными и смертельно опасными.

**Общее логово.** Крутики могут сосуществовать с конструктами, элементалями, слизями, и нежитью, и подобные существа помогают им сторожить свой улей. Крутики достаточно умны, чтобы заградить одни туннели и выкопать другие для защиты своего потомства подальше от соседей.`,
    source: {
      id: SOURCE_MTOF,
      page: 203,
    },
  },
]
