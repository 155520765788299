const {
    SOURCE_BGDIA,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_1_4,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_CLIMB,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_SPIDER_CLIMB,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_BONE_WHELK,
    CREATURE_VROCK,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Костяной слизень',
  nameAlt: 'Костень',
  nameEn: 'Bone Whelk',
  id: CREATURE_BONE_WHELK,
  description: `Костни — это крупные моллюски, которые выделяют слизь для приклеивания костей, черепов и других осколков к своим телам для защиты. Эти слизняки питаются, очищая останки от органических веществ. Они залазят на развалившиеся участки стен, полы и потолки, укрываясь от жгучих ветров Аверно и голодных [вроков](CREATURE:${CREATURE_VROCK}).`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_BGDIA,
    page: 119,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 15,
    [SPEED_CLIMB]: 15,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 5,
    [PARAM_CON]: 11,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 3,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_SPIDER_CLIMB,
    {
      name: 'Клей',
      description: `★СУЩЕСТВО★ может приклеить к себе предмет среднего или меньшего размера. Существо среднего или меньшего размера, касающееся ★СУЩЕСТВО★, Захватывется им (СЛ побега 10).`,
    },
    {
      name: 'Предсмертный визг',
      description: `Когда ★СУЩЕСТВО★ умирает, ★он★ испускает душераздирающий вопль, слышный на 120 футов. Его визг вызывает гниение немагической органики в пределах 10 футов. Каждое существо в пределах 10 футов от ★умирающего★ ★СУЩЕСТВО★ получает 9 (2к8) некротического урона.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
        },
      },
    },
  ],
}
