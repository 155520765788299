const {
    SOURCE_IDRotF,
  } = require('./../../../../sourceList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_MAGEN,
  } = require('./../../../../creatureTypeIdList'),
  {
    SPELL_CREATE_MAGEN,
  } = require('./../../../../spellIdList'),
  {
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  } = require('./../../../../conditionList'),
  {
    LANG_ITS_CREATOR,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_SPELL,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_UNUSUAL_NATURE,
  } = require('./../../../../creatureAbilityList'),
  {
    magenDescription,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_GALVAN_MAGEN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Маген-гальван',
  nameEn: 'Galvan Magen',
  id: CREATURE_GALVAN_MAGEN,
  sizeType: SIZE_MEDIUM,
  createdBySpellId: SPELL_CREATE_MAGEN,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
    CREATURE_TYPE_MAGEN,
  ],
  description: [
    `Маген-гальван может летать. Они также накапливают статическое электричество и могут разряжать его молниями.`,
    magenDescription,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_IDRotF,
    page: 302,
  },
  armor: 14,
  hp: {
    diceCount: 8,
  },
  speed: {
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 18,
    [PARAM_CON]: 18,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 7,
  },
  immunityList: [
    DAMAGE_ELECTRICITY,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Пламенная кончина',
      nameEn: 'Fiery End',
      description: `Если ★СУЩЕСТВО★ умирает, его тело распадается в безвредном хлопке огня и дыма, оставляя после себя всё, что ★носил★ и ★нёс★.`,
    },
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_UNUSUAL_NATURE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки Электрокасанием.`,
    },
    {
      name: 'Электрокасание',
      attack: {
        type: ATTACK_MELEE_SPELL,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          comment: '(★СУЩЕСТВО★ атакует с преимуществом, если цель носит доспех из металла)',
        },
        hit: {
          type: DAMAGE_ELECTRICITY,
          diceCount: 1,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Статический разряд',
      restore: 5,
      description: `★СУЩЕСТВО★ разряжает молнию 60-футовой линией шириной. Все существа в этой области должны пройти испытание Ловкости СЛ 14 (с помехой, если она носит металлический доспех), получая 22 (4к10) урона электричеством при провале или половину этого урона при успехе.`,
    },
  ],
}
