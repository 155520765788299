const {
  SPELL_DETECT_THOUGHTS,
  SPELL_DOMINATE_MONSTER,
  SPELL_IMPRISONMENT,
  SPELL_LEVITATE,
  SPELL_PLANE_SHIFT,
} = require('./../../../../spellIdList')
const {
  LANG_DEEP_SPEECH,
  LANG_TELEPATHY,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  CREATURE_ILLITHILICH,
  CREATURE_LICH,
  CREATURE_MIND_FLAYER,
} = require('./../../../../creatureIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  alhoonDescriptionList,
  illithidDescriptionList,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CONDITION_INCAPACITATED} = require('./../../../../conditionList')
const {CR_22} = require('./../../../../crList')
const {CREATURE_TYPE_HUMANOID} = require('./../../../../creatureTypeIdList')
const {PARAM_INT} = require('./../../../../paramList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Иллитилич',
  nameAlt: 'Лич свежеватель разума',
  nameEn: 'Illithilich',
  id: CREATURE_ILLITHILICH,
  parentId: CREATURE_LICH,
  description: [
    `Становление [истинным личом](CREATURE:${CREATURE_LICH}) — этого могут добиться только самые могущественные маги [свежеватели разума](CREATURE:${CREATURE_MIND_FLAYER}), так как это требует способности изготовить филактерий и сотворить заклинание [заточение](SPELL:${SPELL_IMPRISONMENT}).

Пока лич свежеватель разума скармливает пойманные души своему филактерию, он сохраняет мускульную силу своих щупалец и способность извлекать мозги.`,
    ...alhoonDescriptionList,
    ...illithidDescriptionList,
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_22,
  legendaryActionList: [
    {
      name: 'Щупальца',
      description: `Лич делает одну атаку _Щупальцами_.`,
    },
    {
      name: 'Извлечение мозга',
      cost: 2,
      description: `Лич использует _Извлечение мозга_.`,
    },
    {
      name: 'Взрыв разума',
      cost: 3,
      description: `Лич перезаряжает способность _Взрыв разума_ и использует её.`,
    },
    {
      name: 'Сотворить заклинание',
      comment: 'стоит 1–3 действия',
      description: `Лич использует ячейку заклинания и сотворяет приготовленное заклинание 1, 2 или 3 уровня. Это действие стоит 1 легендарное действие за круг заклинания.`,
    },
  ],
  extendPropCollection: {
    source: [{
      id: SOURCE_VGTM,
      page: 188,
    }],
    featureList: [
      ABILITY_MAGIC_RESISTANCE,
    ],
    spellCast: [
      {
        comment: 'псионика',
        baseStat: PARAM_INT,
        componentExclude: CAST_NONE,
        spellIdByCountList: [
          {
            limit: Infinity,
            list: [
              SPELL_DETECT_THOUGHTS,
              SPELL_LEVITATE,
            ],
          },
          {
            limit: {
              count: 1,
              period: 'день',
            },
            list: [
              SPELL_DOMINATE_MONSTER,
              {
                id: SPELL_PLANE_SHIFT,
                comment: 'только на себя',
              },
            ],
          },
        ],
      },
    ],
    actionList: [
      {
        name: 'Щупальца',
        description: `Если цель Большая или меньше, то она схвачена (Сл освобождения 15) и должна пройти испытание Интеллекта СЛ 20 или будет ошеломлена, пока не окончится захват.`,
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 12,
          range: 5,
          target: {
            count: 1,
            limit: {
              type: TARGET_CREATURE,
            },
          },
          hit: {
            type: DAMAGE_PSYCHIC,
            diceCount: 3,
            diceType: 10,
            diceBonus: 5,
          },
        },
      },
      {
        name: 'Извлечение мозга',
        description: `Если этот урон уменьшает хиты цели до 0, то лич убивает цель, извлекает и пожирает её мозг.`,
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 12,
          range: 5,
          target: {
            count: 1,
            comment: `, схваченный личом`,
            limit: {
              creatureType: CREATURE_TYPE_HUMANOID,
              condition: CONDITION_INCAPACITATED,
            },
          },
          hit: {
            type: DAMAGE_PIERCING,
            diceCount: 10,
            diceType: 10,
            diceBonus: 0,
          },
        },
      },
      {
        name: 'Взрыв разума',
        restore: 5,
        description: `Лич магически излучает психическую энергию в конусе в 60 футов. Каждое существо в этой области должно пройти испытание Интеллекта СЛ 18 или получить 27 (5к8 + 5) психического урона и цель ошеломлена на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, заканчивая эффект на себе при успехе.`,
      },
    ],
  },
}
