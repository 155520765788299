const {GENDER_FEMALE} = require('./../../../genderList')
const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../magicItemTypeList')
const {GEAR_ORB} = require('./../../../gearIdList')
const {
  SOURCE_ERFTLW,
  SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
} = require('./../../../sourceList')

const generateDamageText = require('./generateDamageText')
const siblingIdList = require('./siblingIdList')

const orbOfShieldingGenerator = (
  {
    damageTypeIdList,
    id,
    name: {nominative: name},
    nameEn,
    planeName,
  }
) => {
  const damageTypeText = generateDamageText(damageTypeIdList)

  return {
    id,
    name: `Защитная сфера из ${name}`,
    nameEn: `Orb of Shielding, ${nameEn}`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    gearType: GEAR_ORB,
    attunement: true,
    description: `Это полированый сферический кусок **${name}**, настроенный на **${planeName}**. Если Вы заклинатель, то можете использовать её как заклинательную фокусировку.

Если Вы держите _Защитную сферу из ${name}_, когда получаете **${damageTypeText}**, то можете реакцией уменьшить его на 1к4 (до минимума в 0 урона).`,
    genderId: GENDER_FEMALE,
    siblingIdList,
    source: [
      {
        id: SOURCE_ERFTLW,
        page: 279,
      },
      {
        id: SOURCE_UA_2018_10_08_MAGIC_ITEMS_OF_EBERRON,
        page: 1,
      },
    ],
  }
}

module.exports = orbOfShieldingGenerator
