const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_SUBGROUP_DRUIDIC} = require('./../../languageSubGroupList')
const {GOD_SILVANUS} = require('./../../godIdList')
const {LANG_DRUIDIC} = require('./../../languageIdList')
const {PC_CLASS_DRUID} = require('./../../pcClassIdList')
const {
  SOURCE_ADND2_TCDH,
  SOURCE_MGZN_DRGN_ANNUAL_1999,
  SOURCE_DND3_5_PHB,
  SOURCE_PHB,
} = require('./../../sourceList')

module.exports = {
  id: LANG_DRUIDIC,
  type: LANG_TYPE_HIDDEN,
  langSubGroupId: LANG_SUBGROUP_DRUIDIC,
  name: {
    nominative: 'Друэдан',
    genitive: 'Друэдана',
    instrumental: 'Друэданом',
    prepositional: 'Друэдане',
  },
  name5eOfficial: {
    nominative: 'Друидический',
    genitive: 'Друидического',
    instrumental: 'Друидическим',
    prepositional: 'Друидическом',
  },
  nameEn: 'Drueidan',
  nameAlt: 'Друидический',
  nameEnAlt: 'Druidic',
  typicalSpeakers: 'Друиды',
  description: [
    {
      header: `Друидический язык`,
      text: `Тайный язык друидов.

На нём можно говорить и оставлять тайные послания. Те, кто знают этот язык, автоматически замечают эти послания. Другие замечают присутствие послания при успешной проверке Мудрости (Внимательность) СЛ 15, но без помощи магии не могут расшифровать его.`,
      source: {
        id: SOURCE_PHB,
        page: 66,
      },
    },
    {
      header: `Язык друидов`,
      text: `Он секретный, и им владеют лишь друиды, обучение не друидов запрещено под страхом смерти. Этот язык имеет свой собственный алфавит`,
      source: {
        id: SOURCE_DND3_5_PHB,
        page: 35,
      },
    },
    {
      header: `Друэдан`,
      text: `Друэдан — тайный язык [друидов](PC_CLASS:${PC_CLASS_DRUID}). Его разработала могущественная секта [друидов](PC_CLASS:${PC_CLASS_DRUID}), служащая [Сильванусу](GOD:${GOD_SILVANUS}).

Однако, божества природы Фаэруна договорились распространить этот язык среди большинства [друидов](PC_CLASS:${PC_CLASS_DRUID}) Королевств, вне зависимости от божеств, которым те поклоняются. В разных регионах и у почитателей разных божеств сформировались свои диалекты.`,
      source: {
        id: SOURCE_MGZN_DRGN_ANNUAL_1999,
        page: 30,
      },
    },
    {
      header: `Секретный язык`,
      text: `Друиды могут использовать свой тайный язык не только для передачи паролей, но и для того, чтобы ввести в заблуждение посторонних подслушивателей. Также он является точнейшим инструментом для обсуждения Природы; друид может выразить фразу _«густой старый сосновый лес»_ в одном слове.

Секретный язык имеет специализированный и подробный словарный состав, ограниченный рассмотрением Природы и природных явлений, вне этой сферы он имеет базовый характер. Друид может воспользоваться секретным языком, чтобы поговорить о здоровье человека, животного или растения; обсудить погоду или указать точное направление в дикой местности. Язык также может описывать друидические заклинания, церемонии, способности любых естественных и сверхъестественных существ, известных друидам. Однако в нем нет слов для сложных человеческих эмоций, для большинства инструментов или артефактов (кроме тех, которые используются для охоты или сельского хозяйства), или для оружия и доспехов (кроме тех, которые применяются друидами). Этот язык так же содержит несколько слов, которые имеют отношение к цивилизованным существам, как-то: собственность, правосудие, воровство или война. Временные различия размазаны в этом секретном языке; обычно понятия, которые выражают друиды, носят либо вневременной характер.

Наконец, секретный язык друидов остается чисто разговорным языком. Несколько простых рун или меток (символизирующих опасность, безопасную воду, безопасную тропу и т. д.) существуют для обозначения троп и оставления сообщений, но язык не может передавать настоящие предложения и комплексные идеи в письменном виде.

Приведем пример того, как секретный язык работает на практике. Предположим, что два друида обсуждают магический предмет и хотят беседовать чисто на секретном языке, не используя слов, заимствованных из других языков. Один друид желает сказать:

_«Этот длинный магический меч был подарен Мелинде, жене короля Руперта, от его придворного колдуна Друфуса. Маг вложил в меч силу стрелять зарядами молний. Но затем Руперт стал завидовать Мелинде. Он казнил ее и забрал клинок себе. Вскоре Руперт умер, а клинок остался погребенным в подземельях его замка.»_

На секретном языке эта история выглядит следующим образом:

_«Этот магический ятаган был для Высокой Золотистой Женщины, самке Мужчины Вожака, от Держателя магии из Долины Белых Орлов. Он вложил в него силу вызывать молнии. Но Мужчина Вожак захотел его. Он убил Высокую Золотистую женщину и взял его себе. Он умер. Ятаган остался в пещере под его большой каменной человеческой берлогой.»_

Увидели разницу? В нем нет слова, обозначающего длинный меч, поэтому наш друид заменил его на _«ятаган»_ (всё друидическое оружие имеет свои названия). Идея подарка описывается в более простых понятиях. Кроме того, в этом языке Природной направленности нет идеи именования; люди и существа узнаются по описанию, статусу или месту происхождения.

Колдун стал более обобщённым _«держателем магии»_. Молния, являющаяся природным феноменом, имеет эквивалент в секретном языке. Но секретный язык не может передавать человеческие эмоции, такие как зависть Руперта, поэтому друид воспользовался менее точной фразой. Также тайный язык не содержит понятия казни, поэтому друид использовал более обогащенный термин _«убил»_. Наконец, там нет понятий, обозначающих замок или подземелье, поэтому, чтобы передать этот образ, друид пользуется другими словами — _«пещера под его большой каменной человеческой берлогой»_. Конечно, друид не опасающийся, что его кто-то подслушивает, может смешивать слова секретного языка и нормальной речи в одном и том же предложении.

Секретный язык помогает связывать мировой друидический орден вместе. Все друиды из разных кругов или ветвей, разговаривают на одном и том же секретном языке. Хотя у них могут выработаться свои собственные региональные акценты или диалекты. Это может помочь слушателю определить регион друида или дать ключ к разгадке ветви говорящего.

В компаниях Spelljammer или Planescape, а так же посредством определённых заклинаний и магических предметов, друиды из разных миров могут встретиться. Мастер должен будет решить: достаточно ли их секретные языки похожи друг на друга, чтобы позволять свободное общение?

Наконец, необходимо отметить, что секретный язык, будучи тайным, не является сверхъестественным, — теоретически любой может изучить его. Однако так как именно на этом языке друиды составляют свои кодовые фразы или пароли, они просто не будут обучать языку не друидов. Великий друид региона жестоко накажет любого, кто нарушит эту традицию.`,
      source: {
        id: SOURCE_ADND2_TCDH,
        page: 8,
      },
    },
  ],
  isReady: true,
  isRealLang: true,
}
