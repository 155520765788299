const {GEAR_ENERGY_CELL} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {
  CAT_AMMUNITION_ENERGY_CELLS,
  CAT_EQUIPMENT,
  CAT_FIREARMS,
  CAT_TECH_FUTURISTIC,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_ENERGY_CELL,
  genderId: GENDER_FEMALE,
  name: 'Батарея',
  nameEn: 'Energy Cell',
  nameByCase: {
    nominative: 'батарея',
    genitive: 'батареи',
    accusative: 'батарею',
    instrumental: 'батареей',
  },
  description: {
    header: 'Боеприпасы',
    subheader: 'Ammunition',
    text: `Футуристическое оружие использует особые боеприпасы, называемые батареями. В одной батарее достаточно энергии, чтобы сделать все выстрелы, которые способно совершить оружие.`,
    source: {
      id: SOURCE_DMG,
      page: 267,
    },
  },
  weight: 5/16, // 5 унций
  source: {
    id: SOURCE_DMG,
    page: 268,
  },
  category: [
    CAT_AMMUNITION_ENERGY_CELLS,
    CAT_EQUIPMENT,
    CAT_FIREARMS,
    CAT_TECH_FUTURISTIC,
  ],
}
