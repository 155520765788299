const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {SPELL_BLESS} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')

module.exports = {
  id: SPELL_BLESS,
  name: 'Благословение',
  nameEn: 'Bless',
  description: `Вы благословляете до трёх существ на свой выбор в пределах дистанции. Каждый раз, когда до окончания заклинания цель совершает бросок атаки или проходит испытание, она может бросить к4 и добавить выпавшее число к результату.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, Вы можете сделать целью одно дополнительное существо за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'капля святой воды',
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 213,
    },
    {
      id: SOURCE_SRD,
      page: 122,
    },
  ],
}
