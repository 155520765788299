const {GEAR_BOOK} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    golemId,
    id,
    time,
    cost,
    nameEn,
    nameRu: {
      singular: {
        nominative: singularNominative,
        genitive: singularGenitive,
      },
      plural: {
        dative: dativeGenitive,
      },
    },
  }
) => ({
  id,
  name: `Справочник по ${dativeGenitive} големам`,
  nameEn: `Manual of ${nameEn} Golems`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_BOOK,
  rarity: MGC_RARITY_VERY_RARE,
  isConsumable: true,
  description: `В этом томе находится информация и чары, необходимые для создания [**${singularGenitive}** голема](CREATURE:${golemId}). Для того чтобы расшифровать и использовать справочник, Вы должны быть заклинателем с как минимум двумя ячейками заклинаний 5 уровня. Существо, которое не может использовать справочник по големам, но читает его, получает урон психической энергией 6к6.

Для того чтобы создать [**${singularGenitive}** голема](CREATURE:${golemId}), Вы должны потратить **${time}**, работая без перерывов со _Справочником_ в руке, отдыхая не более 8 часов в день. Вы также должны потратить **${cost} золотых**, приобретая расходные материалы.

После того как [**${singularNominative}** голем](CREATURE:${golemId}) создан, книга исчезает в магическом пламени. Голем оживает, когда его натрут пеплом справочника. Он находится под Вашим контролем, понимает Ваши устные команды и выполняет их.`,
  genderId: GENDER_MALE,
  siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 202,
  },
})
