const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {
  CREATURE_HYENA,
  CREATURE_YEENOGHU,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Гноллы',
    text: `Гноллы это человекоподобные дикари, что без предупреждения нападают на поселения на окраинах цивилизации, убивая своих жертв и пожирая их плоть.`,
    source: {
      id: SOURCE_MM,
      page: 60,
    },
  },
  {
    header: 'Гноллы',
    text: `Дикие, похожие на гиен гуманоиды, убивающие всех, кто им встречается, на потребу своему вечно голодному демоническому творцу, [Йеногу](CREATURE:${CREATURE_YEENOGHU}).`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Демоническое происхождение',
    text: `Происхождение гноллов восходит ко времени, когда демонический повелитель [Йеногу](CREATURE:${CREATURE_YEENOGHU}) проник в Материальный план и устроил в нём буйство. Стаи обычных [гиен](CREATURE:${CREATURE_HYENA}) следовали по его следу, подъедая убитых им жертв. Эти гиены были превращены в первых гноллов, и они продолжали следовать за Йеногу, пока его не изгнали обратно в Бездну. Гноллы же распространились по всему мира, став тяжелейшим напоминанием о демонической силе.`,
    source: {
      id: SOURCE_MM,
      page: 60,
    },
  },
  {
    header: 'Кочевники-налётчики',
    text: `Гноллы опасны ещё и потому, что нападают внезапно. Они приходят из пустоши, грабят и убивают, а затем идут дальше. Они налетают подобно саранче, разоряя поселения, и оставляют после себя лишь разрушенные здания, обглоданные трупы и осквернённую землю. Обычно гноллы выбирают лёгкие цели для своих набегов, но даже закованные в доспехи воины предпочитают отсидеться в замках, пока неистовствующая орда гноллов безнаказанно жжёт фермы, деревни и города, а людей убивают и пожирают.

Гноллы редко строят капитальные здания, и ремесло у них не ценится. Они не делают оружие и доспехи, но собирают их с трупов павших врагов. Они вещают уши, скальпы и другие трофеи своих противников на свои ободранные доспехи.`,
    source: {
      id: SOURCE_MM,
      page: 60,
    },
  },
  {
    header: 'Жажда крови',
    text: `Вы не найдёте ни добра, ни сострадания в сердце гнолла. Подобно демонам, им чужды угрызения совести, и их нельзя приручить или принудительно лишить желания разрушать. Безумная жажда крови гноллов делает их агрессивными ко всему живому, а когда у них нет общего врага, они грызутся между собой. Даже самые свирепые орки избегают объединяться с гноллами.`,
    source: {
      id: SOURCE_MM,
      page: 60,
    },
  },
]
