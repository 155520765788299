const listToCollectionById = require('./../utils/listToCollectionById')
const {
  SOURCE_DND1_CotR,
  SOURCE_DND3_5_PHB,
  SOURCE_DND3_5_RoS,
  SOURCE_DND3_FRCS,
  SOURCE_DND4_DCD,
  SOURCE_DND4_DMG,
  SOURCE_DND4_FRPG,
  SOURCE_SCAG,
  SOURCE_TOA,
} = require('./sourceList')
const {
  PC_RACE_DRAGONBORN,
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_KOBOLD,
  PC_RACE_YUAN_TI_PUREBLOOD,
} = require('./pcRaceIdList')
const {
  LANG_ELVEN,
  LANG_COMMON,
  LANG_DEEP_SPEECH,
  LANG_SYLVAN,
  LANG_THORASS,
} = require('./languageIdList')
const {
  giantRunesDescriptionList,
  qualithDescriptionList,
  tirsuDescription,
} = require('./textCommonParts')
const {
  CREATURE_DRYAD,
  CREATURE_PIXIE,
} = require('./creatureIdList')

const ALPHABET_BARAZHAD = 'barazhad'
const ALPHABET_CELESTIAL = 'celestial'
const ALPHABET_DAVEK = 'davek'
const ALPHABET_DETHEK = 'dethek'
const ALPHABET_ESPRUAR = 'espruar'
const ALPHABET_GIANT_RUNES = 'giant_runes'
const ALPHABET_HAMARFAE = 'hamarfae'
const ALPHABET_IMASKARI = 'imaskari'
const ALPHABET_INFERNAL = 'infernal'
const ALPHABET_IOKHARIC = 'iokharic'
const ALPHABET_QUALITH = 'qualith'
const ALPHABET_OLD_OMUAN = 'old_omuan'
const ALPHABET_PRIMORDIAL = 'primordial'
const ALPHABET_RELLANIC = 'rellanic'
const ALPHABET_RUATHLEK = 'ruathlek'
const ALPHABET_SUPERNAL = 'supernal'
const ALPHABET_THORASS = 'thorass'
const ALPHABET_TIRSU = 'tirsu'

// Fonts: https://www.pixelsagas.com/?s=Dungeons+and+Dragons

const alphabetList = [
  {
    id: ALPHABET_BARAZHAD,
    name: 'Баражад',
    nameEn: 'Barazhad',
    description: {
      text: `Баражад — письмо элементалей, ифритов, архонов, демонов, гноллов и сахуагинов`,
      source: {
        id: SOURCE_DND4_FRPG,
        page: 157,
      },
    },
  },
  {
    id: ALPHABET_CELESTIAL,
    name: 'Небесное письмо',
    nameEn: 'Celestial Script',
    description: [
      {
        text: `Небесное письмо принесено в Фаэрун очень давно через контакты с добрыми жителями других планов`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
      {
        text: `Небесное письмо красиво, но чуждо, потому как предназначено для нужд сущностей, чьё мышление сильно отличается от гуманоидного. Тем не менее, люди с древней и сильной культурной связью с внешнепланарными сущностями иногда адаптируют его для записи человеческих языков в дополнение к языкам, для которых оно предназначено.`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
    ],
  },
  {
    id: ALPHABET_DAVEK,
    name: 'Давек',
    nameEn: 'Davek',
    description: [
      {
        text: `Руны Давека`,
        source: {
          id: SOURCE_DND4_DMG,
          page: 172,
        },
      },
      {
        text: `Великаны используют дварфийские руны Давек из-за того, что [дварфы](PC_RACE:${PC_RACE_DWARF}) долго были у них в услужении.`,
        source: {
          id: SOURCE_DND4_DMG,
          page: 172,
        },
      }
    ],
  },
  {
    id: ALPHABET_DETHEK,
    name: 'Детек',
    nameEn: 'Dethek',
    description: [
      {
        text: `Детек — руны, созданные дварфами`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
      {
        text: `Дварфийский язык Фаэруна использует рунический алфавит Детек, знаки которого легко выгравировать на камне и металле, свидетельством чего являются рунические камни и путевые знаки, найденные в древних дварфийских тоннелях и шахтах.`,
        source: {
          id: SOURCE_SCAG,
          page: 103,
        },
      },
      {
        text: `Детек — дварфийское руническое письмо, обычно начертанное на металлических листах или вырезанное в камне. Оно характерно своими прямыми линиями и отсутствием пунктуации (кроме пробелов между словами и косых черт между предложениями). СЛова выделяются красной краской. Детек часто используется для письма великанами, орками и ограми.`,
        source: {
          id: SOURCE_DND4_FRPG,
          page: 157,
        },
      },
      {
        text: `Алфавит [дварфов](PC_RACE:${PC_RACE_DWARF}) используется (с небольшими изменениями) в языках гномов, великанов, гоблинов, орков, а также в некоторых глубинных языках.`,
        source: {
          id: SOURCE_DND3_5_PHB,
          page: 14,
        },
      },
      {
        text: `Детек — дварфийское рунное письмо. Дварфы редко пишут на том, что легко может испортиться. Они вырезают руны на металлических листах или высекают их в камне. Все линии знаков Детека прямые, чтобы их легче было вырезать в камне. В Детеке отсутствует какая-либо пунктуация, кроме пробелов между словами и косых черт между предложениями. Если какие-то из знаком раскрашивают для контраста или выделения, то имена сущностей и мест выделяют красной краской, а остальное текст — чёрной краской, либо оставляют нераскрашенным.`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
      {
        header: 'Руны Детека',
        text: `[Дварфы](PC_RACE:${PC_RACE_DWARF}) редко пишут на том, что может испортиться. Они иногда штампуют или чертят руны на металлических листах и делают из таких листов книги, связывая их вместе. Но их обычным материалом для записей является камень: каменные стены в пещерах, каменные здания, столбы или стоящие камни, даже пирамиды. Чаще всего они пишут на табличках, которые на [Всеобщем](LANG:${LANG_COMMON}) языке называют руническими камнями.

Типичный рунический камень — плоский и ромбовидный камень из очень твёрдой породы, толщиной около дюйма. На его лицевой стороне начертаны руны Детека кольцом или по спирали вдоль краёв, а в центре должно быть изображение. Некоторые рунические камни имеют рельефные изображения и используются как печати или могут быть вдавлены во влажную грязь, чтобы служить временными указателями подземных следов.

Для [дварфа](PC_RACE:${PC_RACE_DWARF}) все рунические камни несут какое-то послание. Большинство из них покрыто руническим шрифтом, наиболее известным из которых является Детек, который напрямую транслируется на [Всеобщий язык](LANG:${LANG_COMMON}). Руны этого письма просты и состоят из прямых линий, чтобы их было легче вырезать в камне. В Детеке нельзя использовать знаки препинания, но предложения обычно разделяются поперечными линиями шрифта; слова разделяются пробелами; над заглавными буквами начерчена линия. Числа, заключенные в прямоугольники (внутри рамок) — это даты, в записи которых дни указываются перед годами (по соглашению). Существуют собирательные символы или символы для обозначения народов (кланов или племён) или рас. Если руны раскрашиваются, то имена существ и мест обычно выделяются красным цветом, а остальной текст окрашивается в чёрный цвет или оставляется в виде нераскрашенных бороздок.

Рунические камни обычно читаются от внешнего края к центру; письмо образует спираль, в которой заключён центральный рисунок.`,
        source: {
          id: SOURCE_DND1_CotR,
          page: 11,
        },
      },

      {
        text: `Дварфийский язык один из самых старых письменных языков в мире.`,
        source: {
          id: SOURCE_DND3_5_RoS,
          page: 24,
        },
      },
      {
        text: `Алфавит дварфийского — один из самых первых алфавитов. Он широко распространён среди многих других культур и используется великаньим, гоблинским, гномьим, орочьим языками и терраном. Хотя письменность многих из этих языков сильно отклонилась от оригинальной дварфийской, их руны всё ещё узнаваемы, хоть и с небольшим усилием.`,
        source: {
          id: SOURCE_DND3_5_RoS,
          page: 24,
        },
      },
      {
        text: `Дварфийская литература очень скудна. Устная традиция высоко ценится среди дварфийских кланов, мало что оставляя писцам, кроме учётных записей. Хотя большинство дварфов грамотны, очень мало кто из них читает ради удовольствия. В культуре ценящей физическую активность и социальное взаимодействие очень мало сторонников такого занятия, как уединённое чтения для удовольствия. Большинство дварфийских легенд и религиозных притч записываются для потомков, но изучаются они именно в устной форме, а не по записям. Печать или создание книг у дварфов не распространены, хотя тем, кто делает бумагу, чернила и письменные принадлежности, иногда удаётся занять небольшую торговую нишу.`,
        source: {
          id: SOURCE_DND3_5_RoS,
          page: 24,
        },
      },
    ],
  },
  {
    id: ALPHABET_GIANT_RUNES,
    name: 'Великаньи руны',
    nameEn: 'Giant Runes',
    description: giantRunesDescriptionList,
  },
  {
    id: ALPHABET_ESPRUAR,
    name: 'Эспруар',
    nameEn: 'Espruar',
    description: [
      {
        text: `Изящные литеры алфавита Эспруар.`,
        source: {
          id: SOURCE_SCAG,
          page: 105,
        },
      },
      {
        text: `Эспруар — эладринский алфавит лунных эльфов, принятый золотыми эльфами, дроу и другими эльфами и эладринами тысячи лет назад. Его прекрасное витиеватое письмо течёт по украшениям, монументам и магическим предметам. Также он используется как алфавит для записи [Глубинной речи](LANG:${LANG_DEEP_SPEECH}).`,
        source: {
          id: SOURCE_DND4_FRPG,
          page: 157,
        },
      },
      {
        text: `Эспруар — письмо, изобретённое эльфами`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
      {
        header: 'Эспруар',
        text: `Эспруар — Серебряный эльфийский алфавит, которым большинство [эльфов](PC_RACE:${PC_RACE_ELF}) Королевств пишут послания, как на [Всеобщем](LANG:${LANG_COMMON}), так и на своём [родном языке](LANG:${LANG_ELVEN}).`,
        source: {
          id: SOURCE_DND1_CotR,
          page: 11,
        },
      },
      {
        text: `Эльфийское письмо так же замысловато, как и [устный язык](LANG:${LANG_ELVEN}). Оно используется также [Сильваном](LANG:${LANG_SYLVAN}), языком [дриад](CREATURE:${CREATURE_DRYAD}) и [пикси](CREATURE:${CREATURE_PIXIE}).`,
        source: {
          id: SOURCE_DND3_5_PHB,
          page: 18,
        },
      },
      {
        text: `Эспруар — лунный эльфийский алфавит. Он был принят солнечными эльфами, дроу и другими эльфийскими народами тысячи лет назад. Его прекрасное плавное письмо течёт по украшениям, памятникам и магическим предметам.`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
    ],
  },
  {
    id: ALPHABET_HAMARFAE,
    name: 'Хамерфэй',
    nameEn: 'Hamarfae',
    description: {
      text: `Алфавит Хамерфэй больше не используется`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
  },
  {
    id: ALPHABET_IMASKARI,
    name: 'Имаскари',
    nameEn: 'Imaskari',
    description: {
      text: `Алфавит Имаскари больше не используется`,
      source: {
        id: SOURCE_DND3_FRCS,
        page: 86,
      },
    },
  },
  {
    id: ALPHABET_INFERNAL,
    name: 'Инфернальное письмо',
    nameEn: 'Infernal Script',
    description: [
      {
        text: `Инфернальное письмо принесено в Фаэрун чужаками с Нижних планов`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
      {
        text: `Инфернальное письмо красиво, но чуждо, потому как предназначено для нужд сущностей, чьё мышление сильно отличается от гуманоидного. Тем не менее, люди с древней и сильной культурной связью с внешнепланарными сущностями иногда адаптируют его для записи человеческих языков в дополнение к языкам, для которых оно предназначено.`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
    ],
  },
  {
    id: ALPHABET_IOKHARIC,
    name: 'Иохарик',
    nameAlt: 'Драконье письмо',
    nameEn: 'Iokharic',
    nameEnAlt: 'Draconic Script',
    description: [
      {
        text: `Иохарик (также известный как драконье письмо из-за самого известного использующего его языка) — это письмо, используемое драконами, [драконорождёнными](PC_RACE:${PC_RACE_DRAGONBORN}), [кобольдами](PC_RACE:${PC_RACE_KOBOLD}) и многими драконообразными, рептилиями и [змеелюдьми](PC_RACE:${PC_RACE_YUAN_TI_PUREBLOOD}).`,
        source: {
          id: SOURCE_DND4_FRPG,
          page: 157,
        },
      },
      {
        text: `Ио создал драконье письмо, Иохарик, чтобы его смертные дети могли записывать свои впечатления от мира, который, как он надеялся, они унаследуют. И драконий язык, и его письмо сохранились до наших дней.`,
        source: {
          id: SOURCE_DND4_DCD,
          page: 25,
        },
      },
      {
        text: `При письме Иохариком важные слова выделяются особым символом из пяти линий, расходящихся наружу, как лучи звезды или как головы Тиамат.`,
        source: {
          id: SOURCE_DND4_DCD,
          page: 26,
        },
      },
      {
        text: `Драконье письмо красиво, но чуждо, потому как предназначено для нужд сущностей, чьё мышление сильно отличается от гуманоидного. Тем не менее, люди с древней и сильной культурной связью с драконами (и их магией) иногда адаптируют его для записи человеческих языков в дополнение к языкам, для которых оно предназначено.`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
    ],
  },
  {
    id: ALPHABET_QUALITH,
    name: 'Квалит',
    nameEn: 'Qualith',
    description: qualithDescriptionList,
  },
  {
    id: ALPHABET_OLD_OMUAN,
    name: 'Древнеомуанская клинопись',
    nameEn: 'Old Omuan',
    description: [
      {
        header: 'Древнеомуанский',
        text: `Омуанцы писали на Древнеомуанском — клинопись, которая мало похожа на любой другой алфавит.`,
        source: {
          id: SOURCE_TOA,
          page: 95,
        },
      },
    ],
  },
  {
    id: ALPHABET_PRIMORDIAL,
    name: 'Первородное письмо',
    nameEn: 'Primordial Script',
    description: [
      {
        text: `Первородный язык имеет собственное письмо`,
        source: {
          id: SOURCE_DND4_DMG,
          page: 172,
        },
      },
    ],
  },
  {
    id: ALPHABET_RELLANIC,
    name: 'Релланик',
    nameEn: 'Rellanic Script',
  },
  {
    id: ALPHABET_RUATHLEK,
    name: 'Руатлек',
    nameEn: 'Ruathlek',
    description: {
      header: `Руатлек`,
      text: `Этот тайный язык, или магическое письмо иллюзионистов редко встречается в Королевствах.

Иллюзионисты сами довольно редки на Севере, но известно что в Глубоководье хранится одна или больше книг на этом защищённом заклинаниями языке.

Предполагается, что этот секретный письменный язык иллюзионистов произошёл от использования магических рун.`,
      source: {
        id: SOURCE_DND1_CotR,
        page: 10,
      },
    },
  },
  {
    id: ALPHABET_SUPERNAL,
    name: 'Божественное письмо',
    nameEn: 'Supernal Script',
    description: [
      {
        text: `Божественный язык имеет собственное письмо`,
        source: {
          id: SOURCE_DND4_DMG,
          page: 172,
        },
      },
    ],
  },
  {
    id: ALPHABET_THORASS,
    name: 'Торасс',
    nameEn: 'Thorass',
    description: [
      {
        text: `Торасс — алфавит [всеобщего](LANG:${LANG_COMMON}), чондатанского, унтерского. Многие другие языки тоже стали его использовать.`,
        source: {
          id: SOURCE_DND4_FRPG,
          page: 157,
        },
      },
      {
        header: `Современный Всеобщий`,
        text: `Письменный [Всеобщий](LANG:${LANG_COMMON}), который представлен в этих текстах русским (и английским) языком — наследник [Торасса](LANG:${LANG_THORASS}), исходного торгового языка.`,
        source: {
          id: SOURCE_DND1_CotR,
          page: 10,
        },
      },
      {
        text: `Торасс — человеческий набор символов для письма.`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
      {
        text: `Письменность, чьё название уже потеряно для истории, ввела набор символов, составляющих алфавит Торасса. [Торасс](LANG:${LANG_THORASS}) — прямой предок современного [Всеобщего](LANG:${LANG_COMMON}) устного языка. Хотя никто больше не говорит на [Торассе](LANG:${LANG_THORASS}), его алфавит выжил как алфавит [Всеобщего](LANG:${LANG_COMMON}) и многих других языков.`,
        source: {
          id: SOURCE_DND3_FRCS,
          page: 86,
        },
      },
      {
        header: `Торасс (старовсеобщий)`,
        text: `Торасс — это письменный торговый и универсальный язык старых Королевств. Он часто встречается в гробницах, подземных проходах и обиталищах.`,
        source: {
          id: SOURCE_DND1_CotR,
          page: 10,
        },
      },
      {
        text: `Тревожные времена на Севере (когда земли Внутреннего моря были очень малонаселёнными) привели к резкому падению грамотности среди молодёжи и использование рун или символов на некоторое время заменило Торасс.

Надписи на Торассе напрямую транслируются на [Всеобщий](LANG:${LANG_COMMON}), хотя и будут содержать неестественную и архаичную форму фраз и лексики.`,
        source: {
          id: SOURCE_DND1_CotR,
          page: 10,
        },
      },
    ],
  },
  {
    id: ALPHABET_TIRSU,
    name: 'Тир’су',
    nameEn: 'Tir’su',
    description: tirsuDescription,
  },
]

module.exports = alphabetList

module.exports.alphabetCollection = listToCollectionById(alphabetList)

module.exports.ALPHABET_BARAZHAD = ALPHABET_BARAZHAD
module.exports.ALPHABET_CELESTIAL = ALPHABET_CELESTIAL
module.exports.ALPHABET_DAVEK = ALPHABET_DAVEK
module.exports.ALPHABET_DETHEK = ALPHABET_DETHEK
module.exports.ALPHABET_ESPRUAR = ALPHABET_ESPRUAR
module.exports.ALPHABET_GIANT_RUNES = ALPHABET_GIANT_RUNES
module.exports.ALPHABET_HAMARFAE = ALPHABET_HAMARFAE
module.exports.ALPHABET_INFERNAL = ALPHABET_INFERNAL
module.exports.ALPHABET_IMASKARI = ALPHABET_IMASKARI
module.exports.ALPHABET_IOKHARIC = ALPHABET_IOKHARIC
module.exports.ALPHABET_QUALITH = ALPHABET_QUALITH
module.exports.ALPHABET_OLD_OMUAN = ALPHABET_OLD_OMUAN
module.exports.ALPHABET_PRIMORDIAL = ALPHABET_PRIMORDIAL
module.exports.ALPHABET_RELLANIC = ALPHABET_RELLANIC
module.exports.ALPHABET_RUATHLEK = ALPHABET_RUATHLEK
module.exports.ALPHABET_SUPERNAL = ALPHABET_SUPERNAL
module.exports.ALPHABET_THORASS = ALPHABET_THORASS
module.exports.ALPHABET_TIRSU = ALPHABET_TIRSU
