const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {CONDITION_FRIGHTENED} = require('./../../../../../conditionList')
const {MAGIC_ILLUSION} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SPELL_FEAR} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_FEAR,
  name: 'Ужас',
  nameEn: 'Fear',
  description: `Вы проецируете призрачное изображение худших кошмаров других существ. Все существа в 30-футовом конусе должны пройти испытание Мудрости, иначе они бросают всё, что держат в руках, и становятся испуганными на время действия заклинания.

Будучи испуганным этим заклинанием, существо должно каждый свой ход совершать действие Рывок, и перемещаться прочь от Вас по самому безопасному маршруту, пока у неё есть куда перемещаться. Если существо оканчивает ход в месте, откуда у него нет линии обзора до Вас, оно может пройти испытание Мудрости. В случае спасения заклинание перестаёт действовать на это существо.`,
  lvl: 3,
  magicSchoolId: MAGIC_ILLUSION,
  range: -1,
  rangeComment: '30-футовый конус',
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: false,
  materialText: `белое перо или сердце курицы`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
    condition: CONDITION_FRIGHTENED,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 285,
    },
    {
      id: SOURCE_SRD,
      page: 142,
    },
  ],
}
