const {SOURCE_XGTE} = require('./../../../sourceList')
const {PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ZEALOT} = require('./../../../pcSubClassIdList')
const {SPELL_RAISE_DEAD} = require('./../../../spellIdList')
const {
  FEATURE_DIVINE_FURY,
  FEATURE_FANATICAL_FOCUS,
  FEATURE_RAGE,
  FEATURE_RAGE_BEYOND_DEATH,
  FEATURE_WARRIOR_OF_THE_GODS,
  FEATURE_ZEALOUS_PRESENCE,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_DIVINE_FURY,
    name: `Божественный гнев`,
    nameEn: `Divine Fury`,
    lvl: 3,
    text: `Вы можете вкладывать божественный гнев в удары своего оружия.

Пока Вы в [Ярости](FEATURE:${FEATURE_RAGE}), первое существо, по которому Вы на каждом своём ходу попадаете атакой оружием, получает дополнительный урон, равный 1к6 + половина Вашего уровня варвара.

Урон наносится некротической энергией или излучением; тип урона Вы выбираете при получении этого умения.`,
  },
  {
    id: FEATURE_WARRIOR_OF_THE_GODS,
    name: `Воин богов`,
    nameEn: `Warrior of the Gods`,
    lvl: 3,
    text: `Ваша душа отмечена для вечной битвы.

Если заклинание (такое как [Оживление](SPELL:${SPELL_RAISE_DEAD})) имеет единственный эффект — вернуть Вас к жизни (но не нежитью), то заклинателю не требуются материальные компоненты, чтобы сотворить его на Вас.`,
  },
  {
    id: FEATURE_FANATICAL_FOCUS,
    name: `Концентрация фанатика`,
    nameEn: `Fanatical Focus`,
    lvl: 6,
    text: `Божественная сила, что питает Вашу [Ярость](FEATURE:${FEATURE_RAGE}), может защитить Вас от вреда.

Если Вы провалили испытание, пребывая в [Ярости](FEATURE:${FEATURE_RAGE}), Вы перебросить его и использовать новое значение. Вы можете использовать эту способность только один раз в течение одной [Ярости](FEATURE:${FEATURE_RAGE}).`,
  },
  {
    id: FEATURE_ZEALOUS_PRESENCE,
    name: `Фанатичное присутствие`,
    nameEn: `Zealous Presence`,
    lvl: 10,
    text: `Вы обучаетесь использовать божественную силу, чтобы вдохновлять на фанатизм других.

Бонусным действием Вы испускаете боевой клич, наполненный божественной энергией. До 10 существ по Вашему выбору в пределах 60 футов, которые могут Вас слышать, получают преимущество на броски атак и на испытания до начала Вашего следующего хода.

Использовав эту способность, Вы не можете использовать её снова, пока не завершите длинный отдых.`,
  },
  {
    id: FEATURE_RAGE_BEYOND_DEATH,
    name: `Ярость превыше смерти`,
    nameEn: `Rage Beyond Death`,
    lvl: 14,
    text: `Божественная сила, что питает Вашу [Ярость](FEATURE:${FEATURE_RAGE}), позволяет Вам игнорировать смертельные удары.

Пока Вы в [Ярости](FEATURE:${FEATURE_RAGE}), опускание хитов до 0 не заставляет Вас потерять сознание. Вы по-прежнему должны проходить испытания от смерти, и наносимый Вам урон производит обычные эффекты урона, наносимого персонажу, находящемуся в 0 хитов. Но даже если Вы должны умереть от провала испытаний от смерти, Вы не умрёте, пока не закончится Ваша [Ярость](FEATURE:${FEATURE_RAGE}) и в таком случае умрёте только если у Вас всё ещё будет 0 хитов.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ZEALOT,
    source: {
      id: SOURCE_XGTE,
      page: 18,
    },
  })
)
