const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {MAGIC_ILLUSION} = require('./../../../../../magicList')
const {SPELL_MISLEAD} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_MISLEAD,
  name: 'Фальшивый двойник',
  nameEn: `Mislead`,
  description: `Вы становитесь невидимым, и в то же время в том месте, где Вы были, появляется Ваш иллюзорный двойник. Двойник существует, пока заклинание активно, но невидимость оканчивается, если Вы атакуете или накладываете заклинание.

Вы можете действием переместить своего иллюзорного двойника на расстояние, вдвое превышающее Вашу скорость, и заставить его жестикулировать, говорить и вести себя так, как Вам хочется.

Вы можете видеть его глазами и слышать его ушами, как если бы Вы находились там же, где он. В каждом своём ходу Вы можете бонусным действием переключиться с его чувств на свои, или обратно. Пока Вы используете его чувства, Вы слепы и глухи для своего окружения.`,
  lvl: 5,
  magicSchoolId: MAGIC_ILLUSION,
  range: -1,
  componentIdList: [CAST_SOMATIC],
  duration: { timeId: TIME_HOUR, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 287,
    },
    {
      id: SOURCE_SRD,
      page: 165,
    },
  ],
}
