const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {DAMAGE_RADIANT} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_DAWN} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_DAWN,
  name: 'Рассвет',
  nameEn: 'Dawn',
  description: `Рассветные лучи освещают место, которое Вы указали в пределах дистанции. Пока заклинание активно, в этом месте ярким светом мерцает цилиндр с радиусом 30 футов и высотой 40 футов. Этот свет считается солнечным.

Когда цилиндр появляется, каждое существо в нём должно пройти испытание Телосложения, получая 4к10 урона излучением при провале или половину этого урона при успехе. Существо также должно проходить это испытание, каждый раз, когда заканчивает свой ход в границах цилиндра.

Если Вы находитесь в пределах 60 футов от цилиндра, в свой ход Вы можете бонусным действием переместить его на расстояние до 60 футов.`,
  lvl: 5,
  magicSchoolId: MAGIC_EVOCATION,
  range: 60,
  hasCost: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `кулон солнечных лучей, стоимостью не менее 100 зм`,
  duration: {timeId: TIME_MINUTE, count: 1},
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_RADIANT,
      halfOnSuccess: true,
      diceCount: 4,
      diceType: 10,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 165,
  },
}
