const {ALPHABET_RUATHLEK} = require('./../../alphabetList')
const {GOD_LEIRA} = require('./../../godIdList')
const {LANG_RUATHLEK} = require('./../../languageIdList')
const {LANG_SUBGROUP_NETHERESE} = require('./../../languageSubGroupList')
const {PLACE_NIMBRAL} = require('./../../placeIdList')
const {SOURCE_MGZN_DRGN_ANNUAL_1999} = require('./../../sourceList')
const {
  LANG_TYPE_ETHNIC,
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')

module.exports = {
  id: LANG_RUATHLEK,
  alphabetId: ALPHABET_RUATHLEK,
  type: [
    LANG_TYPE_ETHNIC,
    LANG_TYPE_HIDDEN,
  ],
  langSubGroupId: LANG_SUBGROUP_NETHERESE,
  nameEn: 'Ruathlek',
  name: {
    nominative: 'Руатлек',
    genitive: 'Руатлека',
    instrumental: 'Руатлеком',
    prepositional: 'Руатлеке',
  },
  typicalSpeakers: 'иллюзионисты',
  description: {
    header: `Руатлек`,
    text: `Руатлек — магический язык иллюзионистов. Говорят, что богиня обмана и иллюзий [Лейра](GOD:${GOD_LEIRA}) разработала этот язык вместе с несколькими наследниками Нетерила, волшебниками Нимбрала. Однако, в отличие от большинства иллюзионистов, народ Нимбрала стал использовать Руатлек и как магический, и как бытовой язык.`,
    source: {
      id: SOURCE_MGZN_DRGN_ANNUAL_1999,
      page: 30,
    },
  },
  spokenPlaceList: PLACE_NIMBRAL,
  isReady: true,
  isRealLang: true,
}
