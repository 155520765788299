const {SOURCE_PotA} = require('./../../../../sourceList')
const {GENDER_MIDDLE} = require('./../../../../genderList')
const {GEAR_SPEAR} = require('./../../../../gearIdList')
const {MGC_TYPE_WEAPON} = require('./../../../../magicItemTypeList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../magicItemRarityList')
const {LANG_AURAN} = require('./../../../../languageIdList')
const {CREATURE_AIR_ELEMENTAL} = require('./../../../../creatureIdList')
const {SPELL_DOMINATE_MONSTER} = require('./../../../../spellIdList')
const {elementalWeaponDescription} = require('./../../../../textCommonParts')
const {
  MAGIC_ITEM_DEVASTATION_ORB_AIR,
  MAGIC_ITEM_WINDVANE,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_WINDVANE,
  name: 'Перст ветра',
  nameEn: 'Windvane',
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_SPEAR,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: [
    {
      header: 'Перст ветра',
      text: `Перст ветра — это серебряное копьё, отполированный наконечник которого украшен тёмными сапфирами. Его древко абсолютно неосязаемо: кажется, будто держишь в руке не копьё, а лёгкий прохладный ветерок, принявший его форму. В этом копье содержится частичка силы Ян-Си-Бина, Принца Злого Воздуха.

Вы получаете +2 к броскам атаки и урона этим магическим оружием. При попадании цель получает дополнительный урон электричеством 1к6.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Власть над воздухом',
      text: `Если вы вооружены _Перстом ветра_, то получаете следующие преимущества:

* Вы свободно говорите на [Ауране](LANG:${LANG_AURAN}).
* Вы обладаете сопротивлением урону электричеством.
* Вы можете сотворить заклинание [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}) (СЛ 17) на [воздушного элементаля](CREATURE:${CREATURE_AIR_ELEMENTAL}). Если вы сотворили это заклинание, то не сможете вновь использовать данную особенность до следующего рассвета.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Песнь четырёх ветров',
      text: `Находясь в стихийном узле воздуха, Вы, с помощью _Перста ветра_, можете совершить ритуал под названием _«Песнь четырёх ветров»_, который позволит Вам создать [воздушную сферу опустошения](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_AIR}). После окончания ритуала вы не сможете повторить его до следующего рассвета.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Слабость',
      text: `_Перст ветра_ делает своего хозяина непостоянным и ненадёжным. Пока существо настроено на это оружие, оно получает следующую слабость: _«Я нарушаю клятвы и планы. Долг и честь не значат для меня ничего»_.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    elementalWeaponDescription,
  ],
  genderId: GENDER_MIDDLE,
  source: {
    id: SOURCE_PotA,
    page: 2,
  },
}
