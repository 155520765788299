const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_BLOOD_FRENZY,
  ABILITY_WATER_BREATHING,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_QUIPPER,
  CREATURE_SWARM_OF_QUIPPERS,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_0} = require('./../../../../crList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_TINY} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Квиппер',
  nameAlt: 'Пиранья',
  nameEn: 'Quipper',
  id: CREATURE_QUIPPER,
  description: `Квиппер это плотоядная рыба с острыми зубами. Квипперы могут адаптироваться к любой водной среде, включая холодные подземные озёра. Они часто собираются в [рои](CREATURE:${CREATURE_SWARM_OF_QUIPPERS}).`,
  couldBeFamiliar: true,
  sizeType: SIZE_TINY,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 330,
  },
  armor: 13,
  hp: {
    diceCount: 1,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 2,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 9,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 2,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_0,
  featureList: [
    ABILITY_BLOOD_FRENZY,
    ABILITY_WATER_BREATHING,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 0,
          diceType: 0,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
