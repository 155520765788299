const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_INVESTIGATION,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
  SKILL_STEALTH,
  SKILL_SLEIGHT_OF_HAND,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_CROSSBOW_HAND,
  GEAR_SHORTSWORD,
} = require('./../../../../gearIdList')
const {
  ABILITY_CUNNING_ACTION,
  ABILITY_SNEAK_ATTACK,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  CREATURE_NOBLE,
  CREATURE_SPY,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {CR_1} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Шпион',
  nameEn: 'Spy',
  id: CREATURE_SPY,
  description: `Правители, [дворяне](CREATURE:${CREATURE_NOBLE}), торговцы, главы гильдий и прочие богатые индивидуумы используют шпионов, чтобы выжить в беспощадном мире политики. Шпион обучен тайно собирать информацию. Верный шпион скорее умрёт, чем выдаст информацию, компрометирующую его или его нанимателя.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 351,
  },
  armor: 12,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 10,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_INVESTIGATION]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_DOUBLE,
    [SKILL_PERSUASION]: PROF_NORMAL,
    [SKILL_SLEIGHT_OF_HAND]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_1,
  featureList: [
    ABILITY_CUNNING_ACTION,
    {
      id: ABILITY_SNEAK_ATTACK,
      damage: '7 (2к6)',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      gearId: GEAR_SHORTSWORD,
    },
    {
      gearId: GEAR_CROSSBOW_HAND,
    },
  ],
  genderId: GENDER_MALE,
}
