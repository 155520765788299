const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_COUNTERSPELL,
  SPELL_MAGE_HAND,
  SPELL_MINOR_ILLUSION,
  SPELL_MISTY_STEP,
  SPELL_STINKING_CLOUD,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  CREATURE_TYPE_DUERGAR,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_12} = require('./../../../../crList')
const {CREATURE_DUERGAR_DESPOT} = require('./../../../../creatureIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Двергар деспот',
  nameAlt: 'Дуэргар деспот',
  nameEn: 'Duergar Despot',
  id: CREATURE_DUERGAR_DESPOT,
  description: [
    `Двергары Деспоты заменяют части своих тел на механические, контролируя их с помощью псионических способностей.`,
    ...duergarDescription,
  ],
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DWARF,
    CREATURE_TYPE_DUERGAR,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 171,
  },
  armor: {
    ac: 21,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 20,
    [PARAM_DEX]: 5,
    [PARAM_CON]: 19,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_12,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Психический источник энергии',
      description: `Когда двергар деспот получает критический урон или его здоровье опускается до 0, сдерживаемая психическая энергия вырывается наружу, нанося 14 (4к6) урона психической энергией всем существам в радиусе 5 фт. от него.`,
    },
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_MAGE_HAND,
          SPELL_MINOR_ILLUSION,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_COUNTERSPELL,
          SPELL_MISTY_STEP,
          SPELL_STINKING_CLOUD,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Деспот делает две атаки _Железным кулаком_ и две атаки _Сильной стопой_. Вместо любых из этих атак (до 4) он может использовать _Струю пламени_.`,
    },
    {
      name: 'Железный кулак',
      description: `Если цель — существо Большого или менее размера, она должна пройти испытание Силы СЛ 17, при провале она будет откинута на 30 фт. по прямой линии. После приземления цель падает ничком и получает 10 (3к6) дробящего урона.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 5,
        },
      },
    },
    {
      name: 'Сильная стопа',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 1,
              diceType: 8,
              diceBonus: 5,
            },
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 3,
              diceType: 8,
              diceBonus: 5,
              comment: `, если цель Лежит ничком`,
            },
          ],
        ],
      },
    },
    {
      name: 'Струя пламени',
      description: `Двергар изрыгает струю пламени длиной 100 фт. и шириной 5 фт. Каждое существо, оказавшееся в этой области, должно пройти испытание Ловкости СЛ 16, получая 18 (4к8) огненного урона при провале или половину урона при успехе.`,
    },
  ],
}
