const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_FORCE,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_THUNDER,
} = require('./../../../../../damageTypeList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_UA_2017_04_03_STARTER_SPELLS,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {ATTACK_RANGE_SPELL} = require('./../../../../../attackTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SPELL_CHAOS_BOLT} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_CHAOS_BOLT,
  name: 'Снаряд Хаоса',
  nameEn: 'Chaos Bolt',
  description: `Вы бросаете волнистую, трепещущую массу хаотической энергии в одно существо в радиусе действия. Совершите дальнобойную атаку заклинанием по существу. При попадании цель получает 2к8 + 1к6 урона. Выберите одно из к8. Выпавшее число определяет тип урона атаки, как показано ниже.

| к8 | Тип урона           |
|----|---------------------|
| 1  | Кислота             |
| 2  | Холод               |
| 3  | Огонь               |
| 4  | Силовое поле        |
| 5  | Электричество       |
| 6  | Яд                  |
| 7  | Психическая энергия |
| 8  | Звук                |

Если Вы выбрасываете одинаковое значение на обоих к8, хаотическая энергия прыгает с цели на другое существо по Вашему выбору в пределах 30 футов от него. Совершите новую атаку против новой цели и сделайте новый бросок урона, который может привести к тому, что хаотическая энергия снова перескочит.

Существо может быть целью только один раз за каждое произношение этого заклинания.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 2 круга или выше, каждая цель получает дополнительно 1к6 урона за каждый круг ячейки выше 1.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    attackType: ATTACK_RANGE_SPELL,
    damage: {
      type: [
        DAMAGE_ACID,
        DAMAGE_COLD,
        DAMAGE_FIRE,
        DAMAGE_FORCE,
        DAMAGE_ELECTRICITY,
        DAMAGE_POISON,
        DAMAGE_PSYCHIC,
        DAMAGE_THUNDER,
      ],
      diceCount: 2,
      diceType: 8,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 167,
    },
    {
      id: SOURCE_UA_2017_04_03_STARTER_SPELLS,
      page: 2,
    },
  ],
}
