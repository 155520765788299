const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GNOLL,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_GNOLL,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_CHAIN_SHIRT,
    GEAR_GLAIVE,
    GEAR_LONGBOW,
  } = require('./../../../../gearIdList'),
  {
    gnollDescriptionList,
  } = require('./../../../../textCommonParts'),
  {
    ABILITY_RAMPAGE,
  } = require('./../../../../creatureAbilityList'),
  {
    CREATURE_GNOLL,
    CREATURE_GNOLL_PACK_LORD,
    CREATURE_YEENOGHU,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Вожак стаи гноллов',
  nameEn: 'Gnoll Pack Lord',
  id: CREATURE_GNOLL_PACK_LORD,
  description: [
    `Стая [гноллов](CREATURE:${CREATURE_GNOLL}) возглавляется вожаком, правящим силой и хитростью. Лучшее из награбленного отходит к нему, будь то еда, ценные безделушки или магические предметы. В надеждах получить от [Йеногу](CREATURE:${CREATURE_YEENOGHU}) неуязвимость, он покрывает своё тело пирсингом, нелепыми трофеями и наносит на шерсть демонические печати.`,
    ...gnollDescriptionList,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GNOLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MM,
    page: 61,
  },
  armor: {
    ac: 15,
    gearId: GEAR_CHAIN_SHIRT,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 13,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 9,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GNOLL,
  ],
  cr: CR_2,
  featureList: [
    ABILITY_RAMPAGE,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки, либо _Глефой_, либо _Длинным луком_, и использует _Разжигание буйства_, если может.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      gearId: GEAR_GLAIVE,
    },
    {
      gearId: GEAR_LONGBOW,
    },
    {
      name: 'Разжигание буйства',
      description: `Одно существо, которое ★СУЩЕСТВО★ видит в пределах 30 футов от себя, может реакцией совершить рукопашную атаку, если оно слышит ★СУЩЕСТВО★ и обладает особенностью _Буйство_.`,
      restore: 5,
    },
  ],
  genderId: GENDER_MALE,
}
