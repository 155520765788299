const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {CONDITION_CHARMED} = require('./../../../../../conditionList')
const {MAGIC_ENCHANTMENT} = require('./../../../../../magicList')
const {PARAM_WIT} = require('./../../../../../paramList')
const {SOURCE_AI} = require('./../../../../../sourceList')
const {SPELL_INCITE_GREED} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_INCITE_GREED ,
  name: 'Пробуждение жадности',
  nameEn: 'Incite Greed',
  description: `Сотворяя это заклинание, Вы демонстрируете драгоценный камень, используемый для материального компонента, и выбираете любое количество видимых Вами существ в пределах дистанции. Каждая цель должна пройти испытание Мудрости или быть Очарованной Вами до тех пор, пока заклинание не закончится или пока Вы или Ваши спутники не причине ей вред.

Будучи Очарованным таким образом, существо не может делать ничего, кроме как использовать всё своё передвижение, чтобы приблизиться к Вам по безопасному пути. Пока Очарованное существо находится в пределах 5 футах от Вас, оно не может двигаться, а может только жадно смотреть на демонстрируемый Вами камень.

В конце каждого хода Очарованное существо может пройти испытание Мудрости. При успехе эффект на нём заканчивается.`,
  lvl: 3,
  magicSchoolId: MAGIC_ENCHANTMENT,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `драгоценный камень, стоящий как минимум 50 зм`,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  effect: {
    savethrowParam: PARAM_WIT,
    condition: CONDITION_CHARMED,
  },
  source: {
    id: SOURCE_AI,
    page: 77,
  },
}
