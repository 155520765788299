const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Сатиры',
    text: `Сатиры — шумные феи, которые резвятся в диких лесах, движимые любопытством и гедонизмом в равной мере.

Сатиры напоминают крепких мужчин с мохнатой нижней половиной тела и раздвоенными козьими копытами. На их головах растут рога, имеющие размер от пары небольших шишечек до крупных витых бараньих рогов. Они, как правило, отращивают бороду.`,
    source: {
      id: SOURCE_MM,
      page: 256,
    },
  },
  {
    header: 'Гедонистические гуляки',
    text: `Сатиры жаждут крепчайших напитков, ароматнейших специй и головокружительных танцев. Сатир чувствует голод, когда он не может побаловать себя, и он может пройти большие расстояния, чтобы удовлетворить свои желания. Он может похитить хорошего менестреля, чтобы услышать прекрасные песни, проскользнуть в хорошо защищённый сад, чтобы созерцать красивого паренька или девушку, или может проникнуть во дворец, чтобы попробовать лучшие блюда. Сатиры не позволяют веселью пройти мимо них. Они примут участие в любом празднике, про который услышали. В мире достаточно праздников и святых дней, чтобы оправдать бесконечное празднование.

Опьянённые напитками и удовольствиями, сатиры не думают о последствиях гедонизма, к которому они подталкивают других. После них такие существа сами удивляются своему поведению. Таким гулякам, возможно, придётся выпрашивать извинения, чтобы оправдать своё хаотичное поведение перед родителями, нанимателями, семьёй или друзьями.`,
    source: {
      id: SOURCE_MM,
      page: 256,
    },
  },
]
