const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_DRAGON_BLACK_ANCIENT,
  CREATURE_DRAGON_BLUE_ANCIENT,
  CREATURE_DRAGON_BRASS_ANCIENT,
  CREATURE_DRAGON_BRONZE_ANCIENT,
  CREATURE_DRAGON_COPPER_ANCIENT,
  CREATURE_DRAGON_GOLD_ANCIENT,
  CREATURE_DRAGON_GREEN_ANCIENT,
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_DRAGON_SILVER_ANCIENT,
  CREATURE_DRAGON_WHITE_ANCIENT,
  CREATURE_DRAGON_TURTLE,
  CREATURE_WYVERN,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Драконы',
    text: `Крылатые рептилии древнего рода и ужасающей силы — вот кто такие истинные драконы. Они известны, и их боятся за хищную хитрость и жадность, а древнейшие из драконов считаются одними из наиболее могучих существ в мире. Драконы это ещё и магические существа, чьи врождённые способности питают их смертоносное дыхание и другие необычные возможности.

В жилах многих существ, включая [виверн](CREATURE:${CREATURE_WYVERN}) и [дракочерепах](CREATURE:${CREATURE_DRAGON_TURTLE}), течёт драконья кровь. Истинные же драконы делятся на две крупных группы — цветных и металлических драконов. [Белые](CREATURE:${CREATURE_DRAGON_WHITE_ANCIENT}), [зелёные](CREATURE:${CREATURE_DRAGON_GREEN_ANCIENT}), [красные](CREATURE:${CREATURE_DRAGON_RED_ANCIENT}), [синие](CREATURE:${CREATURE_DRAGON_BLUE_ANCIENT}) и [чёрные](CREATURE:${CREATURE_DRAGON_BLACK_ANCIENT}) драконы эгоистичны, злы, и их боятся. [Бронзовые](CREATURE:${CREATURE_DRAGON_BRONZE_ANCIENT}), [золотые](CREATURE:${CREATURE_DRAGON_GOLD_ANCIENT}), [латунные](CREATURE:${CREATURE_DRAGON_BRASS_ANCIENT}), [медные](CREATURE:${CREATURE_DRAGON_COPPER_ANCIENT}) и [серебряные](CREATURE:${CREATURE_DRAGON_SILVER_ANCIENT}) драконы благородны, добры, и их уважают за мудрость.

Цели и идеалы истинных драконов невероятно разнятся, но все они жаждут богатств, и накапливают клады монет, самоцветов, ювелирных изделий и магических предметов. Драконы, обладающие крупными кладами, не любят оставлять их надолго, и покидают их лишь для патрулирования местности и поиска пропитания.

Истинные драконы проходят через четыре отчётливых этапа жизни, от жалких вирмлингов до древних драконов, которые прожили тысячи лет. К этому возрасту их мощь может стать непревзойдённой, а их кладам не будет цены.

## Категории драконьих возрастов

| Категория | Размер    | Возраст         |
|-----------|-----------|-----------------|
| Вирмлинг  | Средний   | 5 лет и менее   |
| Молодой   | Большой   | 6–100 лет       |
| Взрослый  | Огромный  | 101–800 лет     |
| Древний   | Громадный | 801 год и более |
`,
    source: {
      id: SOURCE_MM,
      page: 102,
    },
  },
  {
    header: 'Вариант: Драконы и врождённое колдовство',
    text: `Драконы обладают врождёнными способностями к колдовству, и могут с возрастом осваивать новые заклинания при использовании этого опционального правила.

Молодые и более старые драконы могут сотворять количество заклинаний, равное их модификатору Харизмы. Каждое заклинание может быть наложено лишь один раз за день, не требуя материальных компонентов, и круг заклинания не может превышать трети показателя опасности дракона (округляя в меньшую сторону). Бонус к попаданию атак заклинаниями дракона равен его бонусу мастерства + его бонус Харизмы. СЛ испытаний от заклинаний дракона равна 8 + его бонус мастерства + его модификатор Харизмы.`,
    source: {
      id: SOURCE_MM,
      page: 102,
    },
  },
]
