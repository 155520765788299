const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_DUERGAR,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  GEAR_CROSSBOW_HEAVY,
  GEAR_SCALE_MAIL,
  GEAR_SHIELD,
  GEAR_WAR_PICK,
} = require('./../../../../gearIdList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CREATURE_DUERGAR_KAVALRACHNI,
  CREATURE_STEEDER_FEMALE,
} = require('./../../../../creatureIdList')
const {
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DUERGAR_RESILIENCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_2} = require('./../../../../crList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Двергар кавалрахни',
  nameAlt: 'Дуэргар кавалрахни',
  nameEn: 'Duergar Kavalrachni',
  id: CREATURE_DUERGAR_KAVALRACHNI,
  description: [
    `Кавалрахни — это двергарская кавалерия, обученная сражаться верхом на [стидерах](CREATURE:${CREATURE_STEEDER_FEMALE}).`,
    ...duergarDescription,
  ],
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DWARF,
    CREATURE_TYPE_DUERGAR,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 171,
  },
  armor: {
    ac: 16,
    gearId: [GEAR_SCALE_MAIL, GEAR_SHIELD],
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 13,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_2,
  featureList: [
    {
      name: 'Кавалерийское обучение',
      description: `Когда двергар поражает цель рукопашной атакой будучи наездником на [самке стидера](CREATURE:${CREATURE_STEEDER_FEMALE}), стидер может совершить рукопашную атаку против этой же цели, используя реакцию.`,
    },
    ABILITY_DUERGAR_RESILIENCE,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Двергар совершает две атаки _Боевой киркой_.`,
    },
    {
      gearId: GEAR_WAR_PICK,
    },
    {
      gearId: GEAR_CROSSBOW_HEAVY,
    },
    {
      name: 'Общая невидимость',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Двергар магическим образом становится невидимым, пока не атакует, не наложит заклинание, или пока не окончится его концентрация, но не более 1 часа (как при концентрации на заклинании). Всё снаряжение, которое двергар несёт или носит, становится невидимым вместе с ним. Если невидимый двергар сидит верхом на [стидере](CREATURE:${CREATURE_STEEDER_FEMALE}), [стидер](CREATURE:${CREATURE_STEEDER_FEMALE}) тоже становится невидимым. Невидимость на [стидере](CREATURE:${CREATURE_STEEDER_FEMALE}) прекращается, как только он атакует.`,
    },
  ],
}
