const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_EARTH_ELEMENTAL,
  CREATURE_GALEB_DUHR,
  CREATURE_GARGOYLE,
  CREATURE_KORRED,
  CREATURE_XORN,
} = require('./../../../../creatureIdList')
const {
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_CONJURE_ELEMENTAL,
  SPELL_MELD_INTO_STONE,
  SPELL_OTTO_S_IRRESISTIBLE_DANCE,
  SPELL_STONE_SHAPE,
} = require('./../../../../spellIdList')
const {
  LANG_DWARVISH,
  LANG_GNOMISH,
  LANG_SYLVAN,
  LANG_TERRAN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {ABILITY_STONE_CAMOUFLAGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_7} = require('./../../../../crList')
const {CREATURE_TYPE_FEY} = require('./../../../../creatureTypeIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Корред',
  nameEn: 'Korred',
  id: CREATURE_KORRED,
  description: `Корреды — непредсказуемые скрытные феи с сильной связью с землёй и камнем. Из-за своих магических волос и мистического понимания минералов они часто становятся объектом охоты искателей сокровищ, дварфов и прочих личностей, желающих заполучить богатства глубин.

**Земляные феи.** Корреды предпочитают водиться с себе подобными и иногда общаются с существами стихии земли, такими, как [галеб дары](CREATURE:${CREATURE_GALEB_DUHR}). Племя корредов еженедельно встречается, чтобы поучаствовать в церемониальных танцах, отбивая ритмы в камне своими дубинками и копытами. В глубинах Материального плана корреды обычно спасаются бегством от других существ, но становятся агрессивными, если их оскорбить или надоедать им звуками стука кирки.

**Каменная эмпатия.** Никто не знает камни и землю лучше, чем корреды. Кажется, что корреды нюхом чуют жилы металлов или драгоценных камней. На поверхности корреды могут чувствовать подъёмы и спуски каменных пластов, формирующие пещеры под землёй, а в Подземье корреды знают путь через камни на мили вокруг. Для корредов секретные двери в камнях очевидны, как окна.

Корреды могут швырять валуны гораздо большего размера, чем, казалось бы, должны мочь, вылепливать камни, как будто это глина, плыть сквозь камень и вызывать [земляных элементалей](CREATURE:${CREATURE_EARTH_ELEMENTAL}) и других существ. Также они получают сверхъестественную силу, просто стоя на земле.

**Зачарованные волосы.** У корредов есть волосы по всему телу, но только растущие на голове волосы — магические. Если их отстричь, то они превращаются в тот материал, которым их отстригли. Корреды отстригают пряди своих волос железными ножницами, а затем свивают их в железные верёвки, которыми манипулируют, оживляя их, чтобы те обвивались, как змеи, вокруг существ и предметов. Корреды очень гордятся своими волосами, и очень оскорбляются, когда кто-то пытается обстричь их без разрешения.`,
  note: {
    text: `Существует легенда о торговце, который хотел срезать волосы корреда золотыми ножницами. Корред засунул ему эти ножницы в глотку и вынул через задницу`,
    author: `Воло`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_VGTM,
    page: 162,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 30,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 20,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 120,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_GNOMISH,
    LANG_SYLVAN,
    LANG_TERRAN,
    LANG_UNDERCOMMON,
  ],
  cr: CR_7,
  featureList: [
    {
      name: 'Приказ волосам',
      description: `У корреда есть по крайней мере одна 50-футовая веревка, сплетённая из его волос. Бонусным действием корред приказывает одной такой веревке, находящейся на расстоянии до 30 футов от него, переместиться на 20 футов и опутать видимое им существо Большого или меньшего размера. Цель должна пройти испытание Ловкости СЛ 13 или будет схваченной верёвкой (СЛ высвобождения 13). Пока захват не закончится, существо обездвижено. Корред может использовать бонусное действие для того, чтобы освободить цель, которая также освободится если корред умрёт или станет недееспособным.

У веревки из волос корреда КД 20 и 20 хитов. Она восстанавливает 1 хит в начале каждого хода корреда, пока у нее остается хотя бы 1 хит и пока корред жив. Если хиты веревки снижаются до 0, она уничтожается.`,
    },
    ABILITY_STONE_CAMOUFLAGE,
    {
      name: 'Сила камня',
      description: `Находясь на земле, корред наносит дополнительно 2 кубика урона любой атакой оружием (учтено в его атаках).`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_COMMUNE_WITH_NATURE,
          SPELL_MELD_INTO_STONE,
          SPELL_STONE_SHAPE,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_OTTO_S_IRRESISTIBLE_DANCE,
          {
            id: SPELL_CONJURE_ELEMENTAL,
            comment: `как заклинание 6 уровня, только [галеб дур](CREATURE:${CREATURE_GALEB_DUHR}), [гаргулья](CREATURE:${CREATURE_GARGOYLE}), [элементаль земли](CREATURE:${CREATURE_EARTH_ELEMENTAL})или [зорн](CREATURE:${CREATURE_XORN})`,
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Корред совершает две атаки _Палицей_ или метает два _Камня_.`,
    },
    {
      name: 'Палица',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 9,
        range: 5,
        hit: [
          [
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 1,
              diceType: 8,
              diceBonus: 6,
            },
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 3,
              diceType: 8,
              diceBonus: 6,
              comment: ` если корред находится на земле`,
            },
          ],
        ],
      },
    },
    {
      name: 'Камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 9,
        range: {
          normal: 60,
          max: 120,
        },
        hit: [
          [
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 6,
            },
            {
              type: DAMAGE_BLUDGEONING,
              diceCount: 4,
              diceType: 8,
              diceBonus: 6,
              comment: ` если корред находится на земле`,
            },
          ],
        ],
      },
    },
  ],
}
