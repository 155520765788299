const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_GIANT_ELK,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {ABILITY_CHARGE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_PRONE} = require('./../../../../conditionList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_GIANT_ELK} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_BEAST} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Гигантский лось',
  nameEn: 'Giant Elk',
  id: CREATURE_GIANT_ELK,
  description: `Величественные гигантские лоси встречаются так редко, что считаются предвестниками важных событий, таких как рождение короля.

Легенды гласят, что боги, посещающие Материальный план, принимают облик гигантских лосей. Во многих культурах считается, что охота на этих существ навлекает гнев богов.`,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_BEAST,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 326,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 60,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 14,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_GIANT_ELK,
    {
      id: [
        LANG_COMMON,
        LANG_SYLVAN,
        LANG_ELVEN,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Тараном',
      damageText: '7 (2к6) дробящего урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 14, иначе будет сбита с ног.',
    },
  ],
  actionList: [
    {
      name: 'Таран',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            condition: CONDITION_PRONE,
          },
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
