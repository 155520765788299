const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_NEEDLEFOLK,
    CREATURE_TYPE_PLANT,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ATHLETICS,
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    LANG_SYLVAN,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_CE,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    needleDescription,
    needleNote,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_NEEDLE_LORD,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Иглорд',
  nameEn: 'Needle Lord',
  id: CREATURE_NEEDLE_LORD,
  description: needleDescription,
  note: needleNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
    CREATURE_TYPE_NEEDLEFOLK,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 16,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 14,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 12,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
    CONDITION_EXHAUSTION,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_SYLVAN,
  ],
  cr: CR_3,
  featureList: [
    {
      name: '',
      description: `Каждый раз, когда существо совершает рукопашную атаку против иглорда, оно получает 2 колющего урона. Существо может выбрать атаковать с помехой, чтобы избежать этого урона.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Цепкой лозой_.`,
    },
    {
      name: 'Залп иглами',
      description: `★СУЩЕСТВО★ совершает до 1к6 атак _Иглами_, но не может атаковать одну и ту же цель больше чем два раза за ход.`,
    },
    {
      name: 'Цепкая лоза',
      description: `Цель схвачена (СЛ высвобождения 14). До окончания захвата цель получает 11 колющего урона в начале каждого своего хода. У ★СУЩЕСТВО★ есть две цепкие лозы и каждая может держать одну цель.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Иглы',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 60,
        range: 2,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
        },
      },
    },
  ],
}
