const {LANG_SUBGROUP_ILLUSKI} = require('./../../languageSubGroupList')
const {PLACE_LUSKAN} = require('./../../placeIdList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {
  LANG_ILLUSKAN,
  LANG_TRUE_LUSKAN,
} = require('./../../languageIdList')
const {
  LANG_TYPE_DIALECT,
  LANG_TYPE_ETHNIC,
} = require('./../../languageTypeList')

module.exports = {
  id: LANG_TRUE_LUSKAN,
  type: [
    LANG_TYPE_DIALECT,
    LANG_TYPE_ETHNIC,
  ],
  alphabetId: ALPHABET_THORASS,
  langSubGroupId: LANG_SUBGROUP_ILLUSKI,
  dialectOf: LANG_ILLUSKAN,
  name: {
    nominative: 'Нормальный лусканский',
    genitive: 'Нормального лусканского',
    instrumental: 'Нормальным лусканским',
    prepositional: 'Нормальном лусканском',
  },
  nameEn: 'True Luskan',
  spokenPlaceList: PLACE_LUSKAN,
  isReady: false,
  isRealLang: true,
}
