const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  SPELL_PLANE_SHIFT,
  SPELL_TELEPORTATION_CIRCLE,
} = require('./../../../../../spellIdList')
const {ATTACK_MELEE_SPELL} = require('./../../../../../attackTypeList')
const {MAGIC_CONJURATION} = require('./../../../../../magicList')
const {PARAM_CHA} = require('./../../../../../paramList')

module.exports = {
  id: SPELL_PLANE_SHIFT,
  name: 'Уход в иной мир',
  nameEn: 'Plane Shift',
  description: `Вы и до восьми согласных существ, взявшиеся за руки кругом, перемещаетесь на другой план существования. Вы можете указать в общих чертах точку прибытия, например, Медный город на Стихийном Плане Огня или дворец Диспатера на втором уровне Девяти Преисподних, и Вы появитесь возле этого места. Например, если Вы хотели оказаться в Медном городе, Вы можете прибыть на Стальную улицу, перед Вратами Пепла, или оказаться посреди Моря Огня, на выбор Мастера.

В качестве альтернативы, если Вы знаете последовательность знаков [телепортационного круга](SPELL:${SPELL_TELEPORTATION_CIRCLE}) на другом плане существования, это заклинание может перенести Вас в этот круг. Если круг телепортации слишком мал для переносимого количества существ, они появляются в ближайшем свободном пространстве рядом с кругом.

Вы можете использовать это заклинание, чтобы изгонять несогласных существ на другой план. Выберите существо в пределах досягаемости и совершите по нему рукопашную атаку заклинанием. При попадании существо должно пройти испытание Харизмы. Если существо его проваливает, оно переносится в случайным образом выбранное место на выбранном Вами плане существования. Перенесённое таким образом существо должно будет самостоятельно найти дорогу на Ваш текущий план существования.`,
  lvl: 7,
  magicSchoolId: MAGIC_CONJURATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: 'раздвоенный металлический прут, стоящий как минимум 250 зм, настроенный на конкретный план существования',
  effect: {
    attackType: ATTACK_MELEE_SPELL,
    savethrowParam: PARAM_CHA,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 287,
    },
    {
      id: SOURCE_SRD,
      page: 168,
    },
  ],
}
