const {
  CREATURE_CLAY_GOLEM,
  CREATURE_FLESH_GOLEM,
  CREATURE_IRON_GOLEM,
  CREATURE_STONE_GOLEM,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_ITEM_MANUAL_OF_GOLEMS_CLAY,
  MAGIC_ITEM_MANUAL_OF_GOLEMS_IRON,
  MAGIC_ITEM_MANUAL_OF_GOLEMS_STONE,
  MAGIC_ITEM_MANUAL_OF_GOLEMS_FLESH,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_MANUAL_OF_GOLEMS_CLAY,
    dice: '1–5',
    golemId: CREATURE_CLAY_GOLEM,
    time: '30 дней',
    cost: 65000,
    nameEn: 'Clay',
    nameRu: {
      singular: {
        nominative: 'глиняный',
        genitive: 'глиняного',
      },
      plural: {
        dative: 'глиняным',
      },
    },
  },
  {
    id: MAGIC_ITEM_MANUAL_OF_GOLEMS_IRON,
    dice: '6',
    golemId: CREATURE_IRON_GOLEM,
    time: '120 дней',
    cost: 100000,
    nameEn: 'Iron',
    nameRu: {
      singular: {
        nominative: 'железный',
        genitive: 'железного',
      },
      plural: {
        dative: 'железным',
      },
    },
  },
  {
    id: MAGIC_ITEM_MANUAL_OF_GOLEMS_STONE,
    dice: '7–8',
    golemId: CREATURE_STONE_GOLEM,
    time: '90 дней',
    cost: 80000,
    nameEn: 'Stone',
    nameRu: {
      singular: {
        nominative: 'каменный',
        genitive: 'каменного',
      },
      plural: {
        dative: 'каменным',
      },
    },
  },
  {
    id: MAGIC_ITEM_MANUAL_OF_GOLEMS_FLESH,
    dice: '9–20',
    golemId: CREATURE_FLESH_GOLEM,
    time: '60 дней ',
    cost: 50000,
    nameEn: 'Flesh',
    nameRu: {
      singular: {
        nominative: 'мясной',
        genitive: 'мясного',
      },
      plural: {
        dative: 'мясным',
      },
    },
  },
]
