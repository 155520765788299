const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_CLIMB,
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  SKILL_DECEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_SYLVAN,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_MITE} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_FEY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GEAR_DAGGER} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MFFV_1_MMB} = require('./../../../../sourceList')

module.exports = {
  name: 'Зудень',
  nameEn: 'Mite',
  id: CREATURE_MITE,
  description: [
    {
      header: 'Зудни',
      text: `Зудни — это неприятные феи, которые наслаждаются тем, что причиняют мелкие разрушения и разыгрывают раздражающие шалости, делая даже самых крутых и опытных искателей приключений склонными ко вспышкам разрушительного отчаяния. Когда возникают проблемы с зуднями, даже самая простая задача может обернуться катастрофой, а лёгкое несогласие может быстро перерасти в драку.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 14,
      },
    },
    {
      header: 'Вестники разочарования',
      text: `Зудни — уроженцы Страны Фей, которые возникают, когда существо становится настолько раздраженным ситуацией или событием, что оно вырывается насилием. В следующий раз, когда это существо спит, оно видит странные сны, затем, не просыпаясь, идёт к ближайшему дереву или другому крупному растению, роет маленькую ямку и кричит туда, наполняя её своим раздражением. Когда оно просыпается на следующее утро, то чувствует себя странно обновлённым. Затем, в следующее новолуние из той ямки выкапывается множество зудней. Рождённые из разочарования и гнева, эти существа существуют только для того, чтобы вызывать в других создавшие их эмоции.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 14,
      },
    },
    {
      header: 'Раздражающие проказники',
      text: `Зудни предпочитают жить под землей, разыскивая подземелья и пещеры. Они роют сеть скрытых нор, которые окружают существующие лазы и каверны. Они используют эти укрытия, чтобы наблюдать за приходом и уходом монстров и приключенцев, расставляя ловушки, чтобы досаждать и раздражать, когда возможно. Поперёк лестничного пролёта может быть натянута струна, о которую все проходящие будут спотыкаться и скатываться вниз. Замки могут быть забиты мусором, драгоценные камни заменены подделками, а ценные сокровища украдены. Зудни стремятся убивать только если им угрожает прямое насилие. Они получают радость  иным способом, наблюдая, как простые обыденные задачи становятся разочаровывающим, болезненным опытом.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 14,
      },
    },
    {
      header: 'Влияние фей',
      text: `Когда вокруг зудни, все становятся раздражительными и легко ожесточаются. Даже незначительная неудача ощущается грубейшим промахом. Простые задачи становятся сложными. Тех, кто их выполняют, копят гнев и разочарования, которые требуют выхода — обычно через насилие и разрушения. Влияние зудней может превратить даже самую организованный отряд в толпу ругающихся забияк. Даже в самой сплочённой группе приключенцев резко падает боевой дух, споры перерастают в драки, а дисциплина становится в лучшем случае непостоянной.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 14,
      },
    },
    {
      header: 'Поля хаотических битв',
      text: `Те подземелья, в которых зудни живут достаточно долго, превращаются в поля хаотических битв. Тогда даже члены фракций с сильными лидерами  иногда оборачиваются друг против друга, из чувства разочарования и антипатии. Когда приключенцы входят в такое подземелье, зудни используют этот дополнительный хаос против них. Они магией втягивают посетителей в свою сеть гнева и разочарования, заманивая исследователей вглубь и ставят их в ситуации и места, которые приносят ещё больше разрушений.`,
      source: {
        id: SOURCE_MFFV_1_MMB,
        page: 14,
      },
    },
  ],
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 14,
  },
  armor: 12,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 10,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 11,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_SYLVAN,
    LANG_UNDERCOMMON,
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Вредительское присутствие',
      description: `Все не-фейские существа, которых ★СУЩЕСТВО★ видит в пределах 30 футов, получают помеху к проверкам и испытаниям Ловкости.`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
    {
      name: 'Проклятье кипящей крови',
      restore: 6,
      description: `★СУЩЕСТВО★ на 1 минуту проклинает существо, видимое ★им★ в пределах 60 футов. Если ★СУЩЕСТВО★ скрыт, использование этой способности не выдаёт его позицию. Пока существо проклято, оно вычитает к6 из всех бросков атаки, проверок способностей и испытаний. На любом ходу, пока существо страдает от эффекта проклятья, оно может реакцией атаковать союзника, игнорируя эффект проклятья для этой атаки. После этого существо игнорирует эффект проклятья до конца своего хода.`,
    },
  ],
}
