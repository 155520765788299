const {SOURCE_DMG} = require('./../../../../../sourceList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  SPELL_CONJURE_ELEMENTAL,
  SPELL_DIMENSION_DOOR,
  SPELL_DISINTEGRATE,
  SPELL_GATE,
  SPELL_PASSWALL,
  SPELL_PLANE_SHIFT,
  SPELL_POLYMORPH,
  SPELL_PRISMATIC_SPRAY,
  SPELL_SCORCHING_RAY,
  SPELL_WALL_OF_FIRE,
  SPELL_WEB,
} = require('./../../../../../spellIdList')
const {
  CREATURE_AIR_ELEMENTAL,
  CREATURE_BAT,
} = require('./../../../../../creatureIdList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_CAP_OF_WATER_BREATHING,
  MAGIC_ITEM_CAPE_OF_THE_MOUNTEBANK,
  MAGIC_ITEM_CARPET_OF_FLYING,
  MAGIC_ITEM_CENSER_OF_CONTROLLING_AIR_ELEMENTALS,
  MAGIC_ITEM_CHIME_OF_OPENING,
  MAGIC_ITEM_CIRCLET_OF_BLASTING,
  MAGIC_ITEM_CLOAK_OF_ARACHNIDA,
  MAGIC_ITEM_CLOAK_OF_DISPLACEMENT,
  MAGIC_ITEM_CLOAK_OF_INVISIBILITY,
  MAGIC_ITEM_CLOAK_OF_PROTECTION,
  MAGIC_ITEM_CLOAK_OF_THE_BAT,
  MAGIC_ITEM_CLOAK_OF_THE_MANTA_RAY,
  MAGIC_ITEM_CUBE_OF_FORCE,
  MAGIC_ITEM_CUBIC_GATE,
  MAGIC_ITEM_HORN_OF_BLASTING,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_CAP_OF_WATER_BREATHING,
    name: `Шапка подводного дыхания`,
    nameEn: `Cap of Water Breathing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Если Вы находитесь под водой и на Вас надета эта шапка, Вы можете действием произнести командное слово, после чего вокруг Вашей головы возникнет воздушный пузырь. Это позволит Вам свободно дышать под водой. Пузырь остаётся до тех пор, пока Вы не произнесёте командное слово ещё раз, или не выберетесь из-под воды.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 212,
    },
  },
  {
    id: MAGIC_ITEM_CAPE_OF_THE_MOUNTEBANK,
    name: `Плащ шарлатана`,
    nameEn: `Cape of the Mountebank`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Этот плащ слабо пахнет серой. Пока Вы его носите, Вы можете действием наложить заклинание [Переносящая дверь](SPELL:${SPELL_DIMENSION_DOOR}). Это свойство плаща нельзя использовать повторно до следующего рассвета.

Когда Вы исчезаете, Вы оставляете после себя облачко дыма, и в пункте назначения появляетесь тоже в клубах дыма. Дым слабо заслоняет покинутое и новое пространство и исчезает в конце Вашего следующего хода. Лёгкий или более сильный ветер рассеивает этот дым.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_DIMENSION_DOOR],
    source: {
      id: SOURCE_DMG,
      page: 191,
    },
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_DISPLACEMENT,
    name: `Плащ ускользания`,
    nameEn: `Cloak of Displacement`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Пока Вы носите этот плащ, он создаёт иллюзию, что Вы находитесь немного в стороне от настоящего местонахождения, отчего существа совершают броски атаки по Вам с помехой.

Если Вы получаете урон, это свойство перестаёт действовать до начала Вашего следующего хода. Это свойство подавляется, пока Вы недееспособны, обездвижены или по другой причине не можете перемещаться.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 191,
    },
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_THE_MANTA_RAY,
    name: `Плащ ската`,
    nameEn: `Cloak of the Manta Ray`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Пока Вы носите этот плащ с накинутым капюшоном, Вы можете дышать под водой и у Вас появляется скорость плавания 60 футов.

Накидывание и снятие капюшона совершается действием.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 191,
    },
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_ARACHNIDA,
    name: `Плащ паука`,
    nameEn: `Cloak of Arachnida`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    attunement: true,
    description: `Этот прекрасный плащ сделан из чёрного шёлка, переплетённого тонкими серебристыми нитями. Пока Вы его носите, Вы обладаете следующими преимуществами:

* Вы получаете сопротивление к урону ядом.
* Вы получаете скорость лазания, равную скорость ходьбы.
* Вы можете перемещаться вверх, вниз и вдоль вертикальных поверхностей, а также вверх ногами по потолку, оставляя руки свободными.
* Вы не можете запутаться ни в какой паутине, и можете перемещаться сквозь паутину как если бы она была просто труднопроходимой местностью.
* Вы можете действием наложить заклинание [Паутина](SPELL:${SPELL_WEB}) (СЛ испытания 13). Паутина, создаваемая этим заклинанием, заполняет в два раза большую, чем обычно, площадь. Это свойство нельзя использовать повторно до следующего рассвета.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_WEB],
    source: {
      id: SOURCE_DMG,
      page: 191,
    },
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_INVISIBILITY,
    name: `Плащ невидимости`,
    nameEn: `Cloak of Invisibility`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_LEGENDARY,
    attunement: true,
    description: `Если Вы носите этот плащ, Вы можете надеть на голову капюшон и стать невидимым. Пока Вы невидимы, всё, что Вы несёте и носите, становится невидимым вместе с Вами. Вы видимы, если не надеваете капюшон. Капюшон надевается и снимается действием.

Суммируйте время, в течение которого Вы остаётесь невидимы, порциями по 1 минуте. После 2 накопленных часов невидимости плащ перестаёт действовать. За каждые 12 часов, пока плащ не используется, он восстанавливает 1 час использования.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 190,
    },
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_THE_BAT,
    name: `Плащ летучей мыши`,
    nameEn: `Cloak of the Bat`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Пока Вы носите этот плащ, Вы совершаете с преимуществом проверки Ловкости (Скрытность). В области тусклого света или тьмы Вы можете схватить края плаща обеими руками и использовать его для полёта со скоростью 40 футов. Если Вы отпустите плащ во время полёта или перестанете находиться в области тусклого света или тьмы, Вы теряете эту скорость полёта.

Если Вы носите этот плащ, и находитесь в области тусклого света или тьмы, Вы можете действием наложить на себя [Превращение](SPELL:${SPELL_POLYMORPH}) и стать [летучей мышью](CREATURE:${CREATURE_BAT}). Пока Вы находитесь в облике [летучей мыши](CREATURE:${CREATURE_BAT}), Вы сохраняете свои значения Интеллекта, Мудрости и Харизмы. Это свойство плаща нельзя использовать повторно до следующего рассвета.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_POLYMORPH],
    source: {
      id: SOURCE_DMG,
      page: 190,
    },
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_PROTECTION,
    name: `Плащ защиты`,
    nameEn: `Cloak of Protection`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Вы получаете бонус +1 к КД и к испытаниям, пока носите этот плащ.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 190,
    },
  },
  {
    id: MAGIC_ITEM_CIRCLET_OF_BLASTING,
    name: `Обруч сжигания`,
    nameEn: `Circlet of Blasting`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Пока Вы носите этот обруч, Вы можете действием наложить им заклинание [Палящий луч](SPELL:${SPELL_SCORCHING_RAY}). Вы совершает бросок атаки этого заклинания с бонусом +5.

Обруч не может использоваться повторно до следующего рассвета.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_SCORCHING_RAY],
    source: {
      id: SOURCE_DMG,
      page: 186,
    },
  },
  {
    id: MAGIC_ITEM_CUBE_OF_FORCE,
    name: `Куб силового поля`,
    nameEn: `Cube of Force`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Это кубик с длиной ребра около дюйма. На каждой грани есть характерная метка, на которую можно нажать. Вначале у куба 36 зарядов, и он ежедневно восстанавливает 1к20 зарядов на рассвете.

Вы можете действием нажать на одну из граней куба, тратя при этом указанное в таблице число зарядов. У каждой грани свой эффект. Если в кубе не осталось достаточного числа зарядов, ничего не произойдёт. В противном случае возникает барьер из невидимого силового поля, формирующий куб с длиной ребра 15 футов. Барьер создаётся с центром на Вас, перемещается вместе с Вами и существует 1 минуту, пока Вы не нажмёте действием на шестую грань куба или пока в кубе не кончатся заряды. Вы можете изменить эффект барьера, нажав на другую грань куба и потратив нужное количество зарядов, сбрасывая при этом счётчик длительности.

Если из-за перемещения барьер вступает в контакт с твёрдым предметом, который не может пройти сквозь поле, Вы не сможете приблизиться к этому предмету, пока поле существует. 

# Грани куба силового поля

| Грань | Заряды | Эффект                                                                                                  |
|-------|--------|---------------------------------------------------------------------------------------------------------|
| 1     | 1      | Через барьер не проходят газы, ветер и туман.                                                           |
| 2     | 2      | Через барьер не проходит неживая материя. Стены, пол и потолок могут проходить, если Вы того пожелаете. |
| 3     | 3      | Через барьер не проходит живая материя.                                                                 |
| 4     | 4      | Через барьер не проходят эффекты заклинаний.                                                            |
| 5     | 5      | Через барьер не проходит ничего. Стены, пол и потолок могут проходить, если Вы того пожелаете.          |
| 6     | 0      | Барьер исчезает.                                                                                        |
# Потеря зарядов

Куб теряет заряды, когда барьер становится целью определённых заклинаний или вступает во взаимодействие с эффектами определённых заклинаний или магических предметов, как показано ниже.

| Заклинание или предмет                                  | Потеря зарядов |
|---------------------------------------------------------|----------------|
| [Огненная стена](SPELL:${SPELL_WALL_OF_FIRE})           | 1к4            |
| [Радужные брызги](SPELL:${SPELL_PRISMATIC_SPRAY})       | 1к20           |
| [Распад](SPELL:${SPELL_DISINTEGRATE})                   | 1к12           |
| [Рог взрыва](MAGIC_ITEM:${MAGIC_ITEM_HORN_OF_BLASTING}) | 1к10           |
| [Создание прохода](SPELL:${SPELL_PASSWALL})             | 1к6            |
`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 179,
    },
  },
  {
    id: MAGIC_ITEM_CUBIC_GATE,
    name: `Куб врат`,
    nameEn: `Cubic Gate`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_LEGENDARY,
    description: `Это куб с длиной ребра 3 дюйма, излучающий ощутимую на ощупь магическую энергию. Все шесть граней этого куба связаны с разными планами существования, причём один из них — Материальный план. Остальные планы определяет Мастер.

Вы можете действием нажать на одну из граней куба, чтобы наложить им заклинание [Врата](SPELL:${SPELL_GATE}), создающее портал на план, связанный с этой гранью. В качестве альтернативы, если Вы действием нажмёте на одну грань дважды, Вы можете наложить кубом заклинание [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}) (СЛ испытания 17), перенося целей на план, связанный с этой гранью.

У этого куба есть 3 заряда. Каждое использование куба тратит 1 заряд. Куб ежедневно восстанавливает 1к3 заряда на рассвете.`,
    genderId: GENDER_MALE,
    spellIdList: [
      SPELL_GATE,
      SPELL_PLANE_SHIFT,
    ],
    source: {
      id: SOURCE_DMG,
      page: 179,
    },
  },
  {
    id: MAGIC_ITEM_CHIME_OF_OPENING,
    name: `Колокольчик открывания`,
    nameEn: `Chime of Opening`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Это полая металлическая трубка длинной 1 фут весит 1 фунт. Вы можете действием ударить по ней и направить на предмет, находящийся в пределах 120 футов от Вас, который можно открыть, например, дверь, люк или замок. Трубка издаёт чистый звук, и один замок или засов на предмете отпирается, за исключением тех случаев, когда звук не может достигнуть цели. Если на предмете не остаётся запирающих элементов, то предмет открывается сам по себе.

Этот предмет может быть использован десять раз. После десятого использования он трескается и становится непригодным для использования.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 173,
    },
  },
  {
    id: MAGIC_ITEM_CARPET_OF_FLYING,
    name: `Ковёр-самолёт`,
    nameEn: `Carpet of Flying`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    description: `Вы можете произнести действием командное слово, чтобы ковёр смог парить и летать. Он перемещается в сказанных Вами вслух направлениях, при условии, что Вы находитесь в пределах 30 футов от него.

Существует четыре версии такого ковра. Мастер сам выбирает размер ковра или определяет его случайным образом.

| к100   | Размер      | Грузоподъёмность | Скорость полёта |
|--------|-------------|------------------|-----------------|
| 01–20  | 3 × 5 футов | 200 фунтов       | 80 футов        |
| 21–55  | 4 × 6 футов | 400 фунтов       | 60 футов        |
| 56–80  | 5 × 7 футов | 600 фунтов       | 40 футов        |
| 81–100 | 6 × 9 футов | 800 фунтов       | 30 футов        |

Ковёр может поднять в два раза больший вес, чем тот, что указан в таблице, но при нагрузке больше расчётной его скорость уменьшается вдвое.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 170,
    },
  },
  {
    id: MAGIC_ITEM_CENSER_OF_CONTROLLING_AIR_ELEMENTALS,
    name: `Кадило контролирования воздушных элементалей`,
    nameEn: `Censer of Controlling Air Elementals`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Если в этом кадиле горит благовоние, Вы можете действием произнести командное слово и призвать [воздушного элементаля](CREATURE:${CREATURE_AIR_ELEMENTAL}), как если бы наложили заклинание [Призыв элементаля](SPELL:${SPELL_CONJURE_ELEMENTAL}). Кадило нельзя использовать повторно, пока не наступит следующий рассвет.

Этот сосуд 6 дюймов шириной и 1 фут высотой напоминает чашу с декорированной крышкой. Весит кадило 1 фунт.`,
    genderId: GENDER_MIDDLE,
    spellIdList: [SPELL_CONJURE_ELEMENTAL],
    source: {
      id: SOURCE_DMG,
      page: 168,
    },
  },
]
