const {
  SPELL_FORTUNE_S_FAVOR,
  SPELL_PULSE_WAVE,
  SPELL_SAPPING_STING,
  SPELL_TETHER_ESSENCE,
  SPELL_WRISTPOCKET,
} = require('./../../spellIdList')

module.exports = [
  SPELL_FORTUNE_S_FAVOR,
  SPELL_PULSE_WAVE,
  SPELL_SAPPING_STING,
  SPELL_TETHER_ESSENCE,
  SPELL_WRISTPOCKET,
]
