module.exports = [
  ...require('./a'),
  ...require('./b'),
  ...require('./c'),
  ...require('./d'),
  ...require('./e'),
  ...require('./f'),
  ...require('./g'),
  ...require('./h'),
  ...require('./i'),
  ...require('./k'),
  ...require('./l'),
  ...require('./m'),
  ...require('./n'),
  ...require('./o'),
  ...require('./q'),
  ...require('./r'),
  ...require('./s'),
  ...require('./t'),
  ...require('./u'),
  ...require('./v'),
  ...require('./w'),
  ...require('./x'),
  ...require('./y'),
]
