const arrify = require('arrify')

const SEARCH_PROP_NAME = require('./../SEARCH_PROP_NAME')
const { dunamancyDescription } = require('./../textCommonParts')

const listToCollectionById = require('./../../utils/listToCollectionById')
const prepareForSearch = require('./../../utils/prepareForSearch')
const sortByName = require('./../../utils/sortByName')
const generateListReleasedBeforeAs = require('./../../utils/generateListReleasedBeforeAs')
const generateTextLinks = require('./../../utils/generateTextLinks')
const reduceSourceList = require('./../../utils/reduceSourceList')
const checkIsFromUA = require('./../../utils/checkIsFromUA')
const nameListGenerator = require('./../../utils/nameListGenerator')

const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../castComponentList')

const {
  TIME_ACTION,
  TIME_INSTANT,
} = require('./../timePeriodList')

const spellRawList = require('./spellRawList')
const {pcClassIdCollectionBySpellId} = require('./../spellIdPcClassIdBounds')

const extractEffectProp = require('./utils/extractEffectProp')
const generateSpellPossibleRangeList = require('./utils/generateSpellPossibleRangeList')
const reduceByTimeProp = require('./utils/reduceByTimeProp')

const defaultCastTime = {timeId: TIME_ACTION, count: 1,}
const defaultDuration = TIME_INSTANT

const spellList = spellRawList
  .sort(sortByName)
  .map(
    (spell, i, list) => {
      const {effect} = spell
      const attackTypeList = extractEffectProp('attackType', effect)
      const conditionList = extractEffectProp('condition', effect)
      const saveThrowParamList = extractEffectProp('savethrowParam', effect)

      const damageTypeList = effect
        ? arrify(effect).reduce(
          (resultList, effectItem) => (
            effectItem.damage
              ? arrify(effectItem.damage).reduce(
                (damageList, damageItem) => damageItem.type
                  ? arrify(damageItem.type).reduce(
                    (damageTypeList, damageType) => damageTypeList.includes(damageType)
                      ? damageTypeList
                      : [
                        ...damageTypeList,
                        damageType
                      ],
                    damageList || []
                  )
                  : damageList,
                resultList || []
              )
              : resultList
          ),
          null
        )
        : null
      const castTime = spell.castTime || defaultCastTime
      const duration = spell.duration || defaultDuration

      return {
        ...spell,
        attackTypeList,
        castTime,
        conditionList,
        duration,
        saveThrowParamList,
        damageTypeList,
        releasedBeforeAsList: generateListReleasedBeforeAs(list, spell.id),
        inflictDamage: Boolean(damageTypeList),
        isUA: checkIsFromUA(spell.source),
        useSaveThrow: Boolean(saveThrowParamList),
        castTimeId: castTime.timeId,
        durationTimeId: duration.timeId || duration,
        description: arrify(spell.description)
          .map(
            item => typeof item === 'string'
              ? generateTextLinks(item)
              : {
                ...item,
                text: generateTextLinks(item.text),
              },
          )
          .concat(
            spell.isDunamisBased
              ? [dunamancyDescription]
              : []
          ),
        castTimeComment: spell.castTimeComment
          ? generateTextLinks(spell.castTimeComment)
          : '',
        needConcentration: Boolean(spell.needConcentration),
        hasCost: Boolean(spell.hasCost || spell.royaltyCost),
        canBeEmpowered: Boolean(spell.highLvlCast),
        hasConsumable: Boolean(spell.hasConsumable || spell.royaltyCost),
        hasVerbalComponent: spell.componentIdList.includes(CAST_VERBAL),
        hasSomaticComponent: spell.componentIdList.includes(CAST_SOMATIC),
        hasMaterialComponent: spell.componentIdList.includes(CAST_MATERIAL),
        isRitual: Boolean(spell.isRitual),
        isDunamisBased: Boolean(spell.isDunamisBased),
        isAbandoned: Boolean(spell.abandonedAt),
        isReReleased: Boolean(spell.releasedAs),
        isDurationUntil: Boolean(spell.isDurationUntil || spell.needConcentration),
        pcClassId: pcClassIdCollectionBySpellId[spell.id] || [],
        createdCreatureId: spell.createdCreatureId || [],
        releasedAs: spell.releasedAs || null,
        spellSourceIdList: arrify(spell.source).map(({id}) => id),
        [SEARCH_PROP_NAME]: prepareForSearch(
          [
            spell.name,
            spell.nameEn,
            arrify(spell.nameAlt).join(' ') || '',
            arrify(spell.nameEnAlt).join(' ') || '',
          ]
            .filter(e => e)
            .join('\n')
        ),
      }
    },
  )

module.exports = spellList

module.exports.spellCollection = listToCollectionById(spellList)
module.exports.castTimeIdList = reduceByTimeProp(spellList, 'castTimeId')
module.exports.durationIdList = reduceByTimeProp(spellList, 'durationTimeId')
module.exports.spellSourceIdList = spellList.reduce(reduceSourceList, [])
module.exports.spellNames = nameListGenerator(spellList)
module.exports.spellPossibleRangeList = generateSpellPossibleRangeList(spellList)
