const {GEAR_GREATAXE} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {MAGIC_ITEM_ORCSPLITTER} = require('./../../../../magicItemIdList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ORCSPLITTER,
  name: 'Рассекатель орков',
  nameEn: 'Orcsplitter',
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_GREATAXE,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `добрым дварфом, воином или паладином`,
  description: [
    {
      header: 'Рассекатель орков',
      text: `Этот, на первый взгляд заурядный и отслуживший своё топор, является не чем иным, как _Рассекателем орков_ — легендарным оружием короля дварфов Торхильда Огнеязыкого. На его обухе на дварфийском вырезано слово «орк», но между рунами есть промежуток, из-за которого кажется, что слово как бы рассечено надвое.

Вы получаете следующие преимущества, когда используете это магическое оружие:

* Вы получаете +2 к броскам атаки и урона этим оружием.
* Когда Вы выбрасываете «20» на броске атаки, направленной против орка, этот орк должен пройти испытание Телосложения СЛ 17. В случае провала его хиты падают до 0.
* Вы знаете о присутствии орков в пределах 120 футов, если те не обладают полным укрытием.
* Пока Вы в сознании, орки не могут застать Вас врасплох.
* Пока Вы в сознании, Вы и все Ваши союзники в пределах 30 футов не могут быть напуганы.`,
      source: {
        id: SOURCE_PotA,
        page: 228,
      },
    },
    {
      header: 'Сознание',
      text: `_Рассекатель орков_ — это разумное принципиально-доброе оружие с Интеллектом 6, Мудростью 15 и Харизмой 10. Он может видеть и слышать в пределах 120 футов и имеет тёмное зрение. Он общается, передавая эмоции своему владельцу, хотя в редких случаях он использует ограниченную форму телепатии, чтобы послать в разум владельца куплет или строфу древних дварфийских стихов.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Личность',
      text: `_Рассекатель орков_ мрачен, молчалив и непреклонен. Всё, чего он хочет — биться с орками и служить храброму воину. Он презирает трусов и любые проявления двуличия, не терпит обмана или неверности. Топор стремится защищать дварфов и служить символом их решимости. Он ненавидит заклятых врагов дварфов — великанов, гоблинов и, более всего, орков — и терпеливо ждёт, когда его владелец встретится с ними в бою.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
  ],
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PotA,
    page: 228,
  },
}
