const listToCollectionById = require('./../utils/listToCollectionById')
const { GEAR_SHIELD } = require('./gearIdList')
const { SPELL_GREATER_RESTORATION } = require('./spellIdList')
const {
  CREATURE_FROG,
  CREATURE_TOAD,
} = require('./creatureIdList')

const ABILITY_AGGRESSIVE = 'aggressive'
const ABILITY_AMORPHOUS = 'amorphous'
const ABILITY_AMPHIBIOUS = 'amphibious'
const ABILITY_AURA_OF_MURDER = 'aura_of_murder'
const ABILITY_AURA_OF_TERROR = 'aura_of_terror'
const ABILITY_AXIOMATIC_MIND = 'axiomatic_mind'
const ABILITY_BERSERK = 'berserk'
const ABILITY_BLOOD_FRENZY = 'blood_frenzy'
const ABILITY_BRAVE = 'brave'
const ABILITY_CHANGE_SHAPE = 'change_shape'
const ABILITY_CHARGE = 'charge'
const ABILITY_COORDINATED_ASSAULT = 'coordinated_assault'
const ABILITY_CUNNING_ACTION = 'cunning_action'
const ABILITY_DARK_DEVOTION = 'dark_devotion'
const ABILITY_DETECT = 'detect'
const ABILITY_DEVIL_S_SIGHT = 'devil_s_sight'
const ABILITY_DISINTEGRATION = 'disintegration'
const ABILITY_DIVE_ATTACK = 'dive_attack'
const ABILITY_DIVINE_AWARENES = 'divine_awarenes'
const ABILITY_DRAGON_FANATIC = 'dragon_fanatic'
const ABILITY_DUERGAR_RESILIENCE = 'duergar_resilience'
const ABILITY_DWARVEN_RESILIENCE = 'dwarven_resilience'
const ABILITY_ETHEREAL_AWARENESS = 'ethereal_awareness'
const ABILITY_ETHEREAL_JAUNT = 'ethereal_jaunt'
const ABILITY_EVASION = 'evasion'
const ABILITY_FALSE_APPEARANCE = 'false_appearance'
const ABILITY_FANATIC_ADVANTAGE = 'fanatic_advantage'
const ABILITY_FEAR_OF_FIRE = 'fear_of_fire'
const ABILITY_FEY_ANCESTRY = 'fey_ancestry'
const ABILITY_FLYBY = 'flyby'
const ABILITY_FRIGHTFUL_PRESENCE = 'frightful_presence'
const ABILITY_GNOME_CUNNING = 'gnome_cunning'
const ABILITY_GRUNG_POISON_SKIN = 'grung_poison_skin'
const ABILITY_HALFLING_NIMBLENESS = 'halfling_nimbleness'
const ABILITY_HEATED_BODY = 'heated_body'
const ABILITY_HOLD_BREATH = 'hold_breath'
const ABILITY_IMMUTABLE_FORM = 'immutable_form'
const ABILITY_INCORPOREAL_MOVEMENT = 'incorporeal_movement'
const ABILITY_INCORPOREAL_PASSAGE = 'incorporeal_passage'
const ABILITY_KEEN_HEARING = 'keen_hearing.'
const ABILITY_KEEN_HEARING_AND_SIGHT = 'keen_hearing_and_sight.'
const ABILITY_KEEN_HEARING_AND_SMELL = 'keen_hearing_and_smell'
const ABILITY_KEEN_SIGHT = 'keen_sight'
const ABILITY_KEEN_SIGHT_AND_SMELL = 'keen_sight_and_smell'
const ABILITY_KEEN_SMELL = 'keen_smell'
const ABILITY_LABYRINTHINE_RECALL = 'labyrinthine_recall'
const ABILITY_LEGENDARY_RESISTANCE_2 = 'legendary_resistance_2'
const ABILITY_LEGENDARY_RESISTANCE_3 = 'legendary_resistance_3'
const ABILITY_LEGENDARY_RESISTANCE_5 = 'legendary_resistance_5'
const ABILITY_LIMITED_AMPHIBIOUSNESS = 'limited_amphibiousness'
const ABILITY_LIMITED_FLIGHT = 'limited_flight'
const ABILITY_LOW_CUNNING = 'low_cunning'
const ABILITY_LUCKY = 'lucky'
const ABILITY_MAGIC_RESISTANCE = 'magic_resistance'
const ABILITY_MAGIC_WEAPONS = 'magic_weapons'
const ABILITY_MOUNT = 'mount'
const ABILITY_MOUNTAIN_BORN = 'mountain_born'
const ABILITY_NIMBLE_ESCAPE = 'nimble_escape'
const ABILITY_OTHERWORLDLY_PERCEPTION = 'otherworldly_perception'
const ABILITY_PACK_TACTICS = 'pack_tactics'
const ABILITY_PARRY = 'parry'
const ABILITY_PETRIFYING_GAZE = 'petrifying_gaze'
const ABILITY_PLANT_CAMOUFLAGE = 'plant_camouflage'
const ABILITY_POUNCE = 'pounce'
const ABILITY_POWERFUL_BUILD = 'powerful_build'
const ABILITY_PRIMAL_BOND = 'primal_bond'
const ABILITY_RAMPAGE = 'rampage'
const ABILITY_RECKLESS = 'reckless'
const ABILITY_REGENERATION = 'regeneration'
const ABILITY_RELENTLESS = 'relentless'
const ABILITY_SHADOW_BLEND = 'shadow_blend'
const ABILITY_SHADOW_STEALTH = 'shadow_stealth'
const ABILITY_SHARK_TELEPATHY = 'shark_telepathy'
const ABILITY_SIEGE_MONSTER = 'siege_monster'
const ABILITY_SLIPPERY = 'slippery'
const ABILITY_SNEAK_ATTACK = 'sneak_attack'
const ABILITY_SNOW_CAMOUFLAGE = 'snow_camouflage'
const ABILITY_SPEAK_WITH_FROGS_AND_TOADS = 'speak_with_frogs_and_toads'
const ABILITY_SPELL_REFLECTION = 'spell_reflection'
const ABILITY_SPELL_TURNING = 'spell_turning'
const ABILITY_SPIDER_CLIMB = 'spider_climb'
const ABILITY_STANDING_LEAP = 'standing_leap'
const ABILITY_STONE_CAMOUFLAGE = 'stone_camouflage'
const ABILITY_SUNLIGHT_SENSITIVITY = 'sunlight_sensitivity'
const ABILITY_SUNLIGHT_WEAKNESS = 'sunlight_weakness'
const ABILITY_SURE_FOOTED = 'sure_footed'
const ABILITY_SURPRISE_ATTACK = 'surprise_attack'
const ABILITY_SWAMP_CAMOUFLAGE = 'swamp_camouflage'
const ABILITY_SWARM = 'swarm'
const ABILITY_TACTICAL_DISCIPLINE = 'tactical_discipline'
const ABILITY_TAIL_ATTACK = 'tail_attack'
const ABILITY_TELEPATHIC_BOND = 'telepathic_bond'
const ABILITY_TELEPORT = 'teleport'
const ABILITY_TRAMPLING_CHARGE = 'trampling_charge'
const ABILITY_TURN_IMMUNITY = 'turn_immunity'
const ABILITY_TURN_RESISTANCE = 'turn_resistance'
const ABILITY_UNARMORED_DEFENSE = 'unarmored_defense'
const ABILITY_UNARMORED_MOVEMENT = 'unarmored_movement'
const ABILITY_UNDEAD_FORTITUDE = 'undead_fortitude'
const ABILITY_UNDERWATER_CAMOUFLAGE = 'underwater_camouflage'
const ABILITY_UNUSUAL_NATURE = 'unusual_nature'
const ABILITY_UNYIELDING = 'unyielding'
const ABILITY_WATER_BREATHING = 'water_breathing'
const ABILITY_WEB_SENSE = 'web_sense'
const ABILITY_WEB_WALKER = 'web_walker'
const ABILITY_WING_ATTACK = 'wing_attack'

const legendaryResistanceGenerator = (id, count) => ({
  id,
  name: 'Легендарное сопротивление',
  fullName: `Легендарное сопротивление (${count})`,
  limit: {
    count,
    period: 'день',
  },
  description: `Если ★СУЩЕСТВО★ проваливает испытание, ★он★ может вместо этого сделать испытание успешным.`,
})

const creatureAbilityList = [
  {
    id: ABILITY_MAGIC_RESISTANCE,
    name: 'Сопротивление магии',
    nameEn: 'Magic Resistance',
    description: `★СУЩЕСТВО★ с преимуществом проходит испытания от заклинаний и прочих магических эффектов.`,
  },
  {
    id: ABILITY_UNARMORED_DEFENSE,
    name: 'Защита без доспехов',
    nameEn: 'Unarmored Defense',
    description: `Пока ★СУЩЕСТВО★ не ★носит★ доспехов и [щита](GEAR:${GEAR_SHIELD}), к ★его★ КД добавляется ★его★ модификатор Мудрости.`,
  },
  {
    id: ABILITY_UNARMORED_MOVEMENT,
    name: 'Движение без доспехов',
    nameEn: 'Unarmored Movement',
    description: `Пока ★СУЩЕСТВО★ не ★носит★ доспехов и [щита](GEAR:${GEAR_SHIELD}), ★его★ скорость передвижения увеличивается на ▲speed▲ футов (уже включены в ★его★ скорость).`,
  },
  {
    id: ABILITY_DRAGON_FANATIC,
    name: 'Драконий фанатик',
    nameEn: 'Dragon Fanatic',
    description: `★СУЩЕСТВО★ проходит испытания от Испуга и Очарования с преимуществом. Пока ★СУЩЕСТВО★ видит дружественного дракона или представителя Культа дракона более высокого ранга, ★он★ игнорирует эффекты состояний Испуганный и Очарованный.`,
  },
  {
    id: ABILITY_FANATIC_ADVANTAGE,
    name: 'Преимущество фанатика',
    nameEn: 'Fanatic Advantage',
    description: `Один раз за раунд, если ★СУЩЕСТВО★ совершает атаку оружием с преимуществом и попадает, ★он★ наносит дополнительный урон ▲damage▲.`,
  },
  {
    id: ABILITY_LIMITED_FLIGHT,
    name: 'Ограниченный полёт',
    nameEn: 'Limited Flight',
    description: `★СУЩЕСТВО★ может бонусным действием получить скорость полёта 30 футов до конца своего хода.`,
  },
  {
    id: ABILITY_AMORPHOUS,
    name: 'Аморфная форма',
    nameEn: 'Amorphous',
    description: `★СУЩЕСТВО★ может перемещаться сквозь пространство шириной в 1 дюйм без протискивания.`,
  },
  {
    id: ABILITY_FLYBY,
    name: 'Облёт',
    nameEn: 'Flyby',
    description: '★СУЩЕСТВО★ не провоцирует атаки, когда вылетает из досягаемости противника.',
  },
  {
    id: ABILITY_KEEN_HEARING_AND_SIGHT,
    name: 'Острые зрение и слух',
    nameEn: 'Keen Hearing and Sight',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), полагающиеся на зрение или слух.`,
  },
  {
    id: ABILITY_KEEN_SIGHT_AND_SMELL,
    name: 'Острое зрение и тонкий нюх',
    nameEn: 'Keen Sight and Smell',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), полагающиеся на зрение или обоняние.`,
  },
  {
    id: ABILITY_KEEN_SIGHT,
    name: 'Острое зрение',
    nameEn: 'Keen Sight',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), полагающиеся на зрение.`,
  },
  {
    id: ABILITY_FEY_ANCESTRY,
    name: 'Наследие фей',
    nameEn: 'Fey Ancestry',
    description: `★СУЩЕСТВО★ с преимуществом проходит испытания от очарования, и магия не может ★его★ усыпить.`,
  },
  {
    id: ABILITY_SUNLIGHT_SENSITIVITY,
    name: 'Чувствительность к солнечному свету',
    nameEn: 'Sunlight Sensitivity',
    description: `Находясь на солнечном свету, ★СУЩЕСТВО★ совершает с помехой броски атаки, а также проверки Мудрости (Внимательность), полагающиеся на зрение.`,
  },
  {
    id: ABILITY_SUNLIGHT_WEAKNESS,
    name: 'Слабость на солнечному свету',
    nameEn: 'Sunlight Weakness',
    description: `Находясь на солнечном свету, ★СУЩЕСТВО★ совершает с помехой атаки, проверки способностей и испытания.`,
  },
  {
    id: ABILITY_MAGIC_WEAPONS,
    name: 'Магическое оружие',
    nameEn: 'Magic Weapons',
    description: `Атаки ★его★ оружием являются магическими.`,
  },
  {
    id: ABILITY_TURN_RESISTANCE,
    name: 'Сопротивление изгнанию',
    nameEn: 'Turning Resistance',
    description: `★СУЩЕСТВО★ с преимуществом проходит испытания от всех эффектов, изгоняющих нежить.`,
  },
  {
    id: ABILITY_TURN_IMMUNITY,
    name: 'Иммунитет к изгнанию',
    nameEn: 'Turning Immunity',
    description: `★СУЩЕСТВО★ обладает иммунитетом к эффектам, изгоняющим нежить.`,
  },
  {
    id: ABILITY_AMPHIBIOUS,
    name: 'Амфибия',
    nameEn: 'Amphibious',
    description: '★СУЩЕСТВО★ может дышать и воздухом и под водой.',
  },
  {
    id: ABILITY_PACK_TACTICS,
    name: 'Тактика стаи',
    nameEn: 'Pack Tactics',
    description: `★СУЩЕСТВО★ совершает с преимуществом броски атаки по существу, если в пределах 5 футов от этого существа находится как минимум один ★его★ дееспособный союзник.`,
  },
  {
    id: ABILITY_KEEN_HEARING_AND_SMELL,
    name: 'Острый слух и тонкий нюх',
    nameEn: 'Keen Hearing and Smell',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), полагающиеся на слух и обоняние.`,
  },
  {
    id: ABILITY_KEEN_HEARING,
    name: 'Острый слух',
    nameEn: 'Keen Hearing',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), полагающиеся на слух.`,
  },
  {
    id: ABILITY_KEEN_SMELL,
    name: 'Тонкий нюх',
    nameEn: 'Keen Smell',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Мудрости (Внимательность), полагающиеся на нюх.`,
  },
  {
    id: ABILITY_BRAVE,
    name: 'Храбрый',
    nameEn: 'Brave',
    description: `★СУЩЕСТВО★ с преимуществом проходит испытания от испуга.`,
  },
  {
    id: ABILITY_DARK_DEVOTION,
    name: 'Тёмная преданность',
    description: `★СУЩЕСТВО★ с преимуществом проходит испытания от испуга и очарования.`,
  },
  {
    id: ABILITY_DIVE_ATTACK,
    name: 'Пикирующая атака',
    nameEn: 'Dive Attack',
    description: `Если ★СУЩЕСТВО★ летит и пикирует как минимум на 30 футов по прямой к цели, а затем попадает по ней рукопашной атакой оружием, атака причиняет цели дополнительный урон ▲damageStr▲.`,
  },
  {
    id: ABILITY_INCORPOREAL_MOVEMENT,
    name: 'Бестелесное перемещение',
    nameEn: 'Incorporeal Movement',
    description: `★СУЩЕСТВО★ может перемещаться сквозь других существ и предметы, как если бы они были труднопроходимой местностью. ★Он★ получает урон силовым полем 5 (1к10), если оканчивает ход внутри предмета.`,
  },
  {
    id: ABILITY_INCORPOREAL_PASSAGE,
    name: 'Бестелесное перемещение',
    nameEn: 'Incorporeal Passage',
    description: `★СУЩЕСТВО★ может перемещаться сквозь других существ и предметы, как если бы они были труднопроходимой местностью. Если ★он★ оканчивает ход внутри предмета, то выталкивается в ближайшее свободное пространство и получает урон силовым полем 5 (1к10) за каждые 5 футов перемещения.`,
  },
  {
    id: ABILITY_AGGRESSIVE,
    name: 'Агрессивность',
    nameEn: 'Aggressive',
    description: `★СУЩЕСТВО★ может бонусным действием переместиться на расстояние, не превышающее ★его★ скорость, в сторону видимого ★им★ враждебного существа.`,
  },
  {
    id: ABILITY_RAMPAGE,
    name: 'Буйство',
    nameEn: 'Rampage',
    description: `Если ★СУЩЕСТВО★ в свой ход уменьшает рукопашной атакой хиты существа до 0, ★он★ может бонусным действием переместиться на расстояние до половины своей скорости и совершить атаку _Укусом_.`,
  },
  {
    id: ABILITY_AURA_OF_MURDER,
    name: 'Аура убийства',
    nameEn: 'Aura of Murder',
    description: `Пока ★СУЩЕСТВО★ ★дееспособен★, враждебные существа в пределах 5 футов от него получают уязвимость к колющему урону, если только у них нет устойчивости или иммунитета к такому урону.`,
  },
  {
    id: ABILITY_AURA_OF_TERROR,
    name: 'Аура ужаса',
    nameEn: 'Aura of Terror',
    description: `Враждебные к ★СУЩЕСТВО★ существа в пределах 5 футов от ★него★ получают помехи к броскам атаки и испытаниям.`,
  },
  {
    id: ABILITY_GNOME_CUNNING,
    name: 'Гномья хитрость',
    nameEn: 'Gnome Cunning',
    description: `★СУЩЕСТВО★ с преимуществом проходит испытания Интеллекта, Мудрости и Харизмы от магии.`,
  },
  {
    id: ABILITY_GRUNG_POISON_SKIN,
    name: 'Ядовитая кожа',
    nameEn: 'Poison Skin',
    description: `Любое существо, которое берет грунга в захват или как-то еще входит в прямой контакт с его кожей, должно пройти испытание Телосложения СЛ 12 или стать отравленным на 1 минуту. Отравленное существо, которое не находится в прямом контакте с грунгом, может повторять испытание в конце каждого своего хода, прекратив эффект при успехе.`,
  },
  {
    id: ABILITY_IMMUTABLE_FORM,
    name: 'Неизменяемая форма',
    nameEn: 'Immutable Form',
    description: `★СУЩЕСТВО★ обладает иммунитетом ко всем заклинаниям и эффектам, изменяющим ★его★ форму.`,
  },
  {
    id: ABILITY_STANDING_LEAP,
    name: 'Прыжок с места',
    nameEn: 'Standing Leap',
    description: `★СУЩЕСТВО★ прыгает в длину на расстояние до ▲jumpLength▲ футов и в высоту до ▲jumpHeight▲, с разбега или без.`,
  },
  legendaryResistanceGenerator(ABILITY_LEGENDARY_RESISTANCE_2, 2),
  legendaryResistanceGenerator(ABILITY_LEGENDARY_RESISTANCE_3, 3),
  legendaryResistanceGenerator(ABILITY_LEGENDARY_RESISTANCE_5, 5),
  {
    id: ABILITY_POUNCE,
    name: 'Наскок',
    nameEn: 'Pounce',
    description: `Если ★СУЩЕСТВО★ переместится как минимум на ▲range▲ футов по прямой к существу, а затем в том же ходу попадёт по нему атакой _Когтем_, эта цель должна пройти испытание Силы СЛ ▲dc▲, иначе будет сбита с ног. Если цель сбита с ног, ★СУЩЕСТВО★ может бонусным действием совершить по ней ▲attack▲.`,
  },
  {
    id: ABILITY_CHARGE,
    name: 'Атака в броске',
    nameEn: 'Charge',
    description: `Если ★СУЩЕСТВО★ переместится как минимум на ▲range▲ футов по прямой к цели, а затем в том же ходу попадёт по ней атакой _▲attackName▲_, цель получает от атаки дополнительно ▲damageText▲. ▲afterEffectText▲`,
  },
  {
    id: ABILITY_EVASION,
    name: 'Увёртливость',
    nameEn: 'Evasion',
    description: `Если ★СУЩЕСТВО★ подвергается эффекту, который позволяет пройти испытание Ловкости и получить только половину урона, то вместо этого ★он★ не получает урона при успешном испытании и только половину при провале. ▲commentText▲`,
  },
  {
    id: ABILITY_STONE_CAMOUFLAGE,
    name: 'Каменный камуфляж',
    nameEn: 'Stone Camouflage',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Ловкости (Скрытность), когда пытается спрятаться на каменистой местности.`,
  },
  {
    id: ABILITY_PLANT_CAMOUFLAGE,
    name: 'Растительный камуфляж',
    nameEn: 'Plant Camouflage',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Ловкости (Скрытность), когда пытается спрятаться в любой местности, достаточно укрытой растительностью.`,
  },
  {
    id: ABILITY_SPIDER_CLIMB,
    name: 'Паучье лазание',
    nameEn: 'Spider Climb',
    description: `★СУЩЕСТВО★ может лазать по сложным поверхностям, включая потолки, без совершения проверок характеристик.`,
  },
  {
    id: ABILITY_NIMBLE_ESCAPE,
    name: 'Ловкий побег',
    nameEn: 'Nimble Escape',
    description: `★СУЩЕСТВО★ может в каждом своём ходу бонусным действием совершать действие Засада или Отход.`,
  },
  {
    id: ABILITY_LOW_CUNNING,
    name: 'Низкое коварство',
    nameEn: 'Low Cunning',
    description: `★СУЩЕСТВО★ может в каждом своём ходу бонусным действием совершать действие Отход.`,
  },
  {
    id: ABILITY_WEB_WALKER,
    name: 'Хождение по паутине',
    nameEn: 'Web Walker',
    description: `★СУЩЕСТВО★ игнорирует ограничения перемещения, вызванные паутиной.`,
  },
  {
    id: ABILITY_WEB_SENSE,
    name: 'Чувство паутины',
    nameEn: 'Web Sense',
    description: `Находясь в контакте с паутиной, ★СУЩЕСТВО★ знает точное местоположение всех других существ, находящихся в контакте с той же паутиной.`,
  },
  {
    id: ABILITY_SWARM,
    name: 'Рой',
    nameEn: 'Swarm',
    description: `★СУЩЕСТВО★ может занимать пространство другого существа, и наоборот, и может перемещаться через любой проход, достаточный для Крохотных существ. ★СУЩЕСТВО★ не может восстанавливать хиты и получать временные хиты.`,
  },
  {
    id: ABILITY_COORDINATED_ASSAULT,
    name: 'Скоординированная атака',
    nameEn: 'Coordinated Assault',
    cost: 2,
    description: `★СУЩЕСТВО★ совершает атаку _▲attackName▲_, затем ★его★ скакун может реакцией совершить рукопашную атаку.`,
  },
  {
    id: ABILITY_CUNNING_ACTION,
    name: 'Хитрое действие',
    nameEn: 'Cunning Action',
    description: `Каждый ход ★СУЩЕСТВО★ может бонусным действием совершить Рывок, Отход или Засаду.`,
  },
  {
    id: ABILITY_TRAMPLING_CHARGE,
    name: 'Растаптывающий рывок',
    nameEn: 'Trampling Charge',
    description: `Если ★СУЩЕСТВО★ переместится как минимум на 20 футов по прямой к существу, а затем в том же ходу попадёт по нему атакой _▲attackName_1▲_, цель должна пройти испытание Силы СЛ ▲strSave_DC▲, иначе будет сбита с ног. Если цель сбита с ног, ★СУЩЕСТВО★ может бонусным действием совершить по ней одну атаку _▲attackName_2▲_.`,
  },
  {
    id: ABILITY_RECKLESS,
    name: 'Безрассудство',
    nameEn: 'Reckless',
    description: `В начале своего хода ★СУЩЕСТВО★ может получить преимущество на все броски атаки рукопашными оружием во время этого хода, но атаки по ★нему★ тоже получают преимущество до начала ★его★ следующего хода.`,
  },
  {
    id: ABILITY_DUERGAR_RESILIENCE,
    name: 'Двергарская устойчивость',
    nameEn: 'Duergar Resilience',
    description: `★СУЩЕСТВО★ с преимуществом проходит испытания от яда, заклинаний и иллюзий, а также испытания для сопротивления _Очарованию_ и _Параличу_.`,
  },
  {
    id: ABILITY_DWARVEN_RESILIENCE,
    name: 'Дварфийская устойчивость',
    nameEn: 'Dwarven Resilience',
    description: `★СУЩЕСТВО★ с преимуществом проходит испытания от яда, а также получает сопротивление к урону ядом.`,
  },
  {
    id: ABILITY_ETHEREAL_JAUNT,
    name: 'Эфирный скачок',
    nameEn: 'Ethereal Jaunt',
    description: `★СУЩЕСТВО★ может бонусным действием магически переместиться с Материального плана на Эфирный План, или наоборот.`,
  },
  {
    id: ABILITY_LUCKY,
    name: 'Везучий',
    nameEn: 'Lucky',
    description: `Если при броске атаки, проверке характеристики или испытании выпало «1», ★СУЩЕСТВО★ может перебросить кость, и ★должен★ использовать новый результат.`,
  },
  {
    id: ABILITY_HALFLING_NIMBLENESS,
    name: 'Проворство полуросликов',
    nameEn: 'Halfling Nimbleness',
    description: `★СУЩЕСТВО★ может проходить сквозь пространство, занятое существами большего размера.`,
  },
  {
    id: ABILITY_HEATED_BODY,
    name: 'Раскалённое тело',
    nameEn: 'Heated Body',
    description: `Существо, которое касается ★СУЩЕСТВО★ или попадает по ★нему★ рукопашной атакой, находясь в пределах 5 футов, получает ▲damage▲ урона огнём.`,
  },
  {
    id: ABILITY_SHADOW_STEALTH,
    name: 'Скрытность в тени',
    nameEn: 'Shadow Stealth',
    description: `Находясь в области тусклого света или тьмы, ★СУЩЕСТВО★ может совершать действие Засада бонусным действием.`,
  },
  {
    id: ABILITY_UNDEAD_FORTITUDE,
    name: 'Стойкость нежити',
    nameEn: 'Undead Fortitude',
    description: `Если урон опускает хиты ★СУЩЕСТВО★ до 0, ★он★ проходит испытание Телосложения СЛ 5 + полученный урон, если только это не был урон ▲damageType▲ или урон от критического попадания. При успехе ★его★ хиты опускаются до 1.`,
  },
  {
    id: ABILITY_SPEAK_WITH_FROGS_AND_TOADS,
    name: 'Общение с лягушками и жабами',
    nameEn: 'Speak with Frogs and Toads',
    description: `★СУЩЕСТВО★ может обмениваться простыми понятиями с [лягушками](CREATURE:${CREATURE_FROG}) и [жабами](CREATURE:${CREATURE_TOAD}), когда говорит на языке Жаболюдов.`,
  },
  {
    id: ABILITY_SPELL_TURNING,
    name: 'Разворот заклинаний',
    nameEn: 'Spell Turning',
    description: `★СУЩЕСТВО★ имеет преимущество на испытания против любого заклинания, которое воздействует только на ★него★ (не на область). Если ★его★ испытание против заклинания ▲spellLvl▲-го круга или ниже успешно, то заклинание не имеет никакого эффекта на ★СУЩЕСТВО★ и перенацеливается на самого заклинателя.`,
  },
  {
    id: ABILITY_SPELL_REFLECTION,
    name: 'Отражение заклинаний',
    nameEn: 'Spell Reflection',
    description: `Если ★СУЩЕСТВО★ проходит испытание от заклинания, или атака заклинанием промахивается по нему, то ★СУЩЕСТВО★ может выбрать другое видимое в пределах ▲range▲ футов существо (включая самого заклинателя). Заклинание нацеливается на выбранное существо вместо ★СУЩЕСТВО★. Если заклинание заставляет пройти испытание, то его проходит выбранное существо. Если это была атака заклинанием, то бросок атаки перебрасывается по выбранному существу.`,
  },
  {
    id: ABILITY_SWAMP_CAMOUFLAGE,
    name: 'Болотный камуфляж',
    nameEn: 'Swamp Camouflage',
    description: `★СУЩЕСТВО★ получает преимущество к проверкам Ловкости (Скрытность), когда пытается спрятаться на болотистой местности.`,
  },
  {
    id: ABILITY_DIVINE_AWARENES,
    name: 'Божественная осведомлённость',
    nameEn: 'Divine Awareness',
    description: `★СУЩЕСТВО★ знает, когда слышит ложь.`,
  },
  {
    id: ABILITY_TELEPORT,
    name: 'Телепортация',
    nameEn: 'Teleport',
    description: `★СУЩЕСТВО★ магическим образом телепортируется вместе со всем несомым и носимым снаряжением в видимое ★им★ свободное пространство в пределах 120 футов.`,
  },
  {
    id: ABILITY_FRIGHTFUL_PRESENCE,
    name: 'Ужасающее присутствие',
    nameEn: 'Frightful Presence',
    description: `Все существа на выбор ★СУЩЕСТВО★, находящиеся в пределах ▲range▲ футов от ★него★ и знающие о ★его★ присутствии, должны пройти испытание Мудрости СЛ ▲witSave_DC▲, иначе станут испуганными на 1 минуту. Существо может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. Если испытание существа было успешным, или эффект на нём окончился, оно получает иммунитет к _Ужасающему присутствию_ ★этого★ ★СУЩЕСТВО★ на следующие 24 часа.`,
  },
  {
    id: ABILITY_WING_ATTACK,
    name: 'Атака крыльями',
    nameEn: 'Wing Attack',
    description: `★СУЩЕСТВО★ бьёт крыльями. Все существа в пределах ▲range▲ футов от ★СУЩЕСТВО★ должны пройти испытание Ловкости СЛ ▲dexSave_DC▲, иначе получат дробящий урон ▲damage▲ и будут сбиты с ног. После этого ★СУЩЕСТВО★ может пролететь на расстояние, не превышающее половину скорости полёта.`,
  },
  {
    id: ABILITY_DETECT,
    name: 'Обнаружение',
    nameEn: 'Detect',
    description: `★СУЩЕСТВО★ совершает проверку Мудрости (Внимательность).`,
  },
  {
    id: ABILITY_TAIL_ATTACK,
    name: 'Атака хвостом',
    nameEn: 'Tail Attack',
    description: `★СУЩЕСТВО★ совершает атаку _Хвостом_.`,
  },
  {
    id: ABILITY_TACTICAL_DISCIPLINE,
    name: 'Тактическая дисциплина',
    nameEn: 'Tactical Discipline',
    description: `★СУЩЕСТВО★ получает преимущество ко всем проверкам характеристик и испытаниям, совершаемым в бою.`,
  },
  {
    id: ABILITY_TELEPATHIC_BOND,
    name: 'Телепатическая связь',
    nameEn: 'Telepathic Bond',
    description: `Если ★СУЩЕСТВО★ и ★его★ хозяин находятся на одном плане существования, ★СУЩЕСТВО★ может магическим образом передавать всё, что ощущает, своему хозяину, и они могут общаться между собой телепатически.`,
  },
  {
    id: ABILITY_LABYRINTHINE_RECALL,
    name: 'Запоминание пути',
    nameEn: 'Labyrinthine Recall',
    description: `★СУЩЕСТВО★ может с лёгкостью вспомнить любой пройдённый путь.`,
  },
  {
    id: ABILITY_CHANGE_SHAPE,
    name: 'Смена формы',
    nameEn: 'Change Shape',
    description: `▲actionType▲ ★СУЩЕСТВО★ магическим образом превращается в ▲nextForm▲, или принимает свой истинный облик. Всё снаряжение, которое ★он★ несёт или носит, ▲gearMerge▲.

▲statChange▲. ★Он★ принимает свой истинный облик, если умирает.`,
  },
  {
    id: ABILITY_DEVIL_S_SIGHT,
    name: 'Дьявольское зрение',
    nameEn: 'Devil’s Sight',
    description: `Магическая тьма не мешает ★его★ тёмному зрению.`,
  },
  {
    id: ABILITY_HOLD_BREATH,
    nameEn: 'Hold Breath',
    name: 'Задержка дыхания',
    description: `▲condition▲ ★СУЩЕСТВО★ может задержать дыхание на ▲time▲.`,
  },
  {
    id: ABILITY_FALSE_APPEARANCE,
    name: 'Обманчивая внешность',
    nameEn: 'False Appearance',
    description: `Пока ★СУЩЕСТВО★ ★неподвижен★ ▲place▲ — ★он★ ★неотличим★ от ▲appearance▲.`,
  },
  {
    id: ABILITY_AXIOMATIC_MIND,
    name: 'Косное сознание',
    nameEn: 'Axiomatic Mind',
    description: `★СУЩЕСТВО★ нельзя заставить действовать так, как противоречит ★его★ природе или инструкциям.`,
  },
  {
    id: ABILITY_DISINTEGRATION,
    name: 'Распад',
    nameEn: 'Disintegration',
    description: `Если ★СУЩЕСТВО★ умирает, ★его★ тело рассыпается в пыль, оставляя лишь оружие и то, что ★он★ ★нёс★.`,
  },
  {
    id: ABILITY_BERSERK,
    name: 'Берсерк',
    nameEn: 'Berserk',
    description: `Каждый раз, когда ★СУЩЕСТВО★ начинает ход с ▲hp▲ или меньше хитами, бросайте к6. Если выпадет «6», голем становится берсерком. Будучи берсерком, ★он★ в каждом своём ходу атакует ближайшее видимое существо. Если поблизости нет существ, к которым можно подойти и атаковать, ★СУЩЕСТВО★ атакует предмет, предпочитая предметы с размером меньше своего. Став берсерком, ★СУЩЕСТВО★ остаётся им, пока ★его★ не уничтожат или пока ★он★ не восстановит все свои хиты. ▲calmText▲`,
  },
  {
    id: ABILITY_BLOOD_FRENZY,
    name: 'Кровавое бешенство',
    nameEn: 'Blood Frenzy',
    description: `★СУЩЕСТВО★ совершает с преимуществом броски рукопашных атак по существам, у которых хиты ниже максимума.`,
  },
  {
    id: ABILITY_SHARK_TELEPATHY,
    name: 'Акулья телепатия',
    nameEn: 'Shark Telepathy',
    description: `★СУЩЕСТВО★ может магическим образом командовать всеми акулами, находящимися в пределах 120 футов от ★него★, при помощи ограниченной телепатии.`,
  },
  {
    id: ABILITY_SLIPPERY,
    nameEn: 'Slippery',
    name: 'Скользкий',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки характеристик и испытания, совершённые чтобы высвободиться из захвата.`,
  },
  {
    id: ABILITY_OTHERWORLDLY_PERCEPTION,
    name: 'Сверхъестественное восприятие',
    nameEn: 'Otherworldly Perception',
    description: `★СУЩЕСТВО★ может чувствовать в пределах 30 футов присутствие невидимых существ и существ, находящихся на Эфирном Плане. ★Он★ может узнать точное местонахождение этих существ, если они будут перемещаться.`,
  },
  {
    id: ABILITY_LIMITED_AMPHIBIOUSNESS,
    name: 'Ограниченная амфибийность',
    nameEn: 'Limited Amphibiousness',
    description: `★СУЩЕСТВО★ может дышать и воздухом и под водой, но чтобы не задохнуться, ★ему★ нужно погружаться в воду хотя бы раз каждые ▲time▲.`,
  },
  {
    id: ABILITY_WATER_BREATHING,
    name: 'Подводное дыхание',
    nameEn: 'Water Breathing',
    description: `★СУЩЕСТВО★ может дышать только под водой.`,
  },
  {
    id: ABILITY_UNDERWATER_CAMOUFLAGE,
    name: 'Подводный камуфляж',
    nameEn: 'Underwater Camouflage',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Ловкости (Скрытность), когда находится под водой.`,
  },
  {
    id: ABILITY_SNOW_CAMOUFLAGE,
    name: 'Снежный камуфляж',
    nameEn: 'Snow Camouflage',
    description: `★СУЩЕСТВО★ совершает с преимуществом проверки Ловкости (Скрытность), когда пытается спрятаться на снежной местности.`,
  },
  {
    id: ABILITY_FEAR_OF_FIRE,
    name: 'Страх огня',
    nameEn: 'Fear of Fire',
    description: `Если ★СУЩЕСТВО★ получает урон огнём, ★он★ до конца своего следующего хода совершает с помехой броски атаки и проверки характеристик.`,
  },
  {
    id: ABILITY_RELENTLESS,
    name: 'Неумолимый',
    nameEn: 'Relentless ',
    comment: 'перезаряжается после короткого или длинного отдыха',
    description: `Если ★СУЩЕСТВО★ получает урон не больше ▲damage▲, уменьшающий его хиты до 0, его хиты вместо этого уменьшаются лишь до 1.`,
  },
  {
    id: ABILITY_SURE_FOOTED,
    name: 'Устойчивый',
    nameEn: 'Sure-Footed',
    description: `★СУЩЕСТВО★ с преимуществом проходит испытания Силы и Ловкости против эффектов, сбивающих ★его★ с ног.`,
  },
  {
    id: ABILITY_REGENERATION,
    name: 'Регенерация',
    nameEn: 'Regeneration',
    description: `★СУЩЕСТВО★ восстанавливает ▲regen▲ хитов в начале своего хода▲other▲.`,
  },
  {
    id: ABILITY_SURPRISE_ATTACK,
    name: 'Внезапная атака',
    nameEn: 'Surprise Attack',
    description: `Если ★СУЩЕСТВО★ застаёт врасплох существо, и попадает по нему атакой в первом раунде сражения, цель получает от атаки дополнительный урон ▲damage▲.`,
  },
  {
    id: ABILITY_SNEAK_ATTACK,
    name: 'Скрытая атака',
    nameEn: 'Sneak Attack',
    limit: {
      count: 1,
      period: 'ход',
    },
    description: `★СУЩЕСТВО★ причиняет дополнительный урон ▲damage▲, если попадает по цели атакой оружием, совершённой с преимуществом к броску атаки, или же если цель находится в пределах 5 футов от ★его★ дееспособного союзника, и ★СУЩЕСТВО★ ★совершал★ бросок атаки без помехи.`,
  },
  {
    id: ABILITY_UNUSUAL_NATURE,
    name: 'Неестественная природа',
    nameEn: 'Unusual Nature',
    description: `★СУЩЕСТВО★ не нуждается во сне, воздухе, пище и воде.`,
  },
  {
    id: ABILITY_UNYIELDING,
    name: 'Непоколебимость',
    nameEn: 'Unyielding',
    description: `Когда на ★СУЩЕСТВО★ воздействует эффект, который должен ★его★ передвинуть, сбить с ног или сделать и то, и другое, ★он★ может реакцией крепко устоять на ногах и не сдвигаться.`,
  },
  {
    id: ABILITY_MOUNT,
    name: 'Скакун',
    nameEn: 'Mount',
    description: `Если ★СУЩЕСТВО★ не сидит верхом на скакуне, ★он★ может бонусным действием магически телепортироваться на существо, служащее ★ему★ скакуном, если они находятся на одном плане существования. Телепортируясь, ★СУЩЕСТВО★ появляется верхом на скакуне со всем снаряжением, которое ★он★ несёт и носит.

Пока ★СУЩЕСТВО★ сидит на скакуне и ★дееспособен★, ★он★ не может быть ★застан★ врасплох и они оба — ★СУЩЕСТВО★ и ★его★ скакун получают преимущество на испытания Ловкости. Если хиты ★СУЩЕСТВО★ опускаются до 0, пока ★он★ сидит на скакуне, хиты скакуна тоже опускаются до 0.`,
  },
  {
    id: ABILITY_MOUNTAIN_BORN,
    name: 'Рождённый в горах',
    nameEn: 'Mountain Born',
    description: `★СУЩЕСТВО★ ★привычен★ к большой высоте, включая высоту больше 20 000 футов.`,
  },
  {
    id: ABILITY_PARRY,
    name: 'Парирование',
    nameEn: 'Parry',
    description: `★СУЩЕСТВО★ реакцией добавляет ▲ac▲ к КД против одной рукопашной атаки, которая должна попасть по ★нему★. Для этого ★СУЩЕСТВО★ ★должен★ видеть атакующего и ★должен★ использовать рукопашное оружие.`,
  },
  {
    id: ABILITY_PETRIFYING_GAZE,
    name: 'Окаменяющий взгляд',
    description: `Если существо, видящее глаза ★СУЩЕСТВО★, начинает ход в пределах 30 футов от ★него★ и ★СУЩЕСТВО★ ★дееспособен★ и видит это существо, ★СУЩЕСТВО★ может заставить его пройти испытание Телосложения СЛ ▲dc▲.

Если испытание провалено на 5 или больше, существо Окаменевает мгновенно.

В противном случае провалившее испытание существо начинает превращаться в камень и Обездвиживается. Обездвиженное существо должно повторить испытание в конце своего следующего хода, становясь Окаменевшим при провале или оканчивая эффект при успехе.

Окаменение длится до тех пор, пока не будет использовано заклинание [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) или подобная магия.

Если существо не захвачено врасплох, оно может отвести взгляд, чтобы не проходить испытание в начале хода. В этом случае отводящее взгляд существо до начала своего следующего хода не видит ★СУЩЕСТВО★. Если существо в этот период посмотрит на ★СУЩЕСТВО★, оно тут же проходит испытание.

Если ★СУЩЕСТВО★ видит своё отражение в полированной поверхности, находящейся в пределах 30 футов от ★него★ в ярко освещённом месте, то из-за проклятья подвергается действию своего собственного взгляда.`,
  },
  {
    id: ABILITY_POWERFUL_BUILD,
    name: 'Мощное телосложение',
    nameEn: 'Powerful Build',
    description: `★СУЩЕСТВО★ считается на один размер больше для определения ★его★ грузоподъемности и веса, который ★он★ может толкать, тянуть или поднимать от земли.`,
  },
  {
    id: ABILITY_PRIMAL_BOND,
    name: 'Первобытная связь',
    nameEn: 'Primal Bond',
    description: `Следопыт может добавлять свой бонус мастерства ко всем проверкам характеристик или испытаниям, которые совершает ★СУЩЕСТВО★.`,
  },
  {
    id: ABILITY_SIEGE_MONSTER,
    name: `Осадное чудовище`,
    nameEn: 'Siege Monster',
    description: `★СУЩЕСТВО★ причиняет двойной урон предметам и строениям.`,
  },
  {
    id: ABILITY_ETHEREAL_AWARENESS,
    name: 'Эфирное восприятие',
    nameEn: 'Ethereal Awareness',
    description: `★СУЩЕСТВО★ может видеть эфирных существ и предметы.`,
  },
  {
    id: ABILITY_SHADOW_BLEND,
    name: 'Затеряться в тенях',
    nameEn: 'Shadow Blend',
    description: `Будучи в тусклом свете или темноте, ★СУЩЕСТВО★ может бонусным действием стать ★невидимым★, вместе со всем, что ★он★ несёт и носит. Невидимость длится, пока ★СУЩЕСТВО★ не окончит её бонусным действием, атакует, окажется на ярком свету или станет ★недееспособным★.`,
  },
].map(
  ability => ({
    ...ability,
    fullName: ability.fullName || ability.name
  })
)

module.exports = creatureAbilityList

module.exports.creatureAbilityCollection = listToCollectionById(creatureAbilityList)

module.exports.ABILITY_AGGRESSIVE = ABILITY_AGGRESSIVE
module.exports.ABILITY_AMORPHOUS = ABILITY_AMORPHOUS
module.exports.ABILITY_AMPHIBIOUS = ABILITY_AMPHIBIOUS
module.exports.ABILITY_AURA_OF_MURDER = ABILITY_AURA_OF_MURDER
module.exports.ABILITY_AURA_OF_TERROR = ABILITY_AURA_OF_TERROR
module.exports.ABILITY_AXIOMATIC_MIND = ABILITY_AXIOMATIC_MIND
module.exports.ABILITY_BERSERK = ABILITY_BERSERK
module.exports.ABILITY_BLOOD_FRENZY = ABILITY_BLOOD_FRENZY
module.exports.ABILITY_BRAVE = ABILITY_BRAVE
module.exports.ABILITY_CHANGE_SHAPE = ABILITY_CHANGE_SHAPE
module.exports.ABILITY_CHARGE = ABILITY_CHARGE
module.exports.ABILITY_COORDINATED_ASSAULT = ABILITY_COORDINATED_ASSAULT
module.exports.ABILITY_CUNNING_ACTION = ABILITY_CUNNING_ACTION
module.exports.ABILITY_DARK_DEVOTION = ABILITY_DARK_DEVOTION
module.exports.ABILITY_DETECT = ABILITY_DETECT
module.exports.ABILITY_DEVIL_S_SIGHT = ABILITY_DEVIL_S_SIGHT
module.exports.ABILITY_DISINTEGRATION = ABILITY_DISINTEGRATION
module.exports.ABILITY_DIVE_ATTACK = ABILITY_DIVE_ATTACK
module.exports.ABILITY_DIVINE_AWARENES = ABILITY_DIVINE_AWARENES
module.exports.ABILITY_DRAGON_FANATIC = ABILITY_DRAGON_FANATIC
module.exports.ABILITY_DUERGAR_RESILIENCE = ABILITY_DUERGAR_RESILIENCE
module.exports.ABILITY_DWARVEN_RESILIENCE = ABILITY_DWARVEN_RESILIENCE
module.exports.ABILITY_ETHEREAL_AWARENESS = ABILITY_ETHEREAL_AWARENESS
module.exports.ABILITY_ETHEREAL_JAUNT = ABILITY_ETHEREAL_JAUNT
module.exports.ABILITY_EVASION = ABILITY_EVASION
module.exports.ABILITY_FALSE_APPEARANCE = ABILITY_FALSE_APPEARANCE
module.exports.ABILITY_FANATIC_ADVANTAGE = ABILITY_FANATIC_ADVANTAGE
module.exports.ABILITY_FEAR_OF_FIRE = ABILITY_FEAR_OF_FIRE
module.exports.ABILITY_FEY_ANCESTRY = ABILITY_FEY_ANCESTRY
module.exports.ABILITY_FLYBY = ABILITY_FLYBY
module.exports.ABILITY_FRIGHTFUL_PRESENCE = ABILITY_FRIGHTFUL_PRESENCE
module.exports.ABILITY_GNOME_CUNNING = ABILITY_GNOME_CUNNING
module.exports.ABILITY_GRUNG_POISON_SKIN = ABILITY_GRUNG_POISON_SKIN
module.exports.ABILITY_HALFLING_NIMBLENESS = ABILITY_HALFLING_NIMBLENESS
module.exports.ABILITY_HEATED_BODY = ABILITY_HEATED_BODY
module.exports.ABILITY_HOLD_BREATH = ABILITY_HOLD_BREATH
module.exports.ABILITY_IMMUTABLE_FORM = ABILITY_IMMUTABLE_FORM
module.exports.ABILITY_INCORPOREAL_MOVEMENT = ABILITY_INCORPOREAL_MOVEMENT
module.exports.ABILITY_INCORPOREAL_PASSAGE = ABILITY_INCORPOREAL_PASSAGE
module.exports.ABILITY_KEEN_HEARING = ABILITY_KEEN_HEARING
module.exports.ABILITY_KEEN_HEARING_AND_SIGHT = ABILITY_KEEN_HEARING_AND_SIGHT
module.exports.ABILITY_KEEN_HEARING_AND_SMELL = ABILITY_KEEN_HEARING_AND_SMELL
module.exports.ABILITY_KEEN_SIGHT = ABILITY_KEEN_SIGHT
module.exports.ABILITY_KEEN_SIGHT_AND_SMELL = ABILITY_KEEN_SIGHT_AND_SMELL
module.exports.ABILITY_KEEN_SMELL = ABILITY_KEEN_SMELL
module.exports.ABILITY_LABYRINTHINE_RECALL = ABILITY_LABYRINTHINE_RECALL
module.exports.ABILITY_LEGENDARY_RESISTANCE_2 = ABILITY_LEGENDARY_RESISTANCE_2
module.exports.ABILITY_LEGENDARY_RESISTANCE_3 = ABILITY_LEGENDARY_RESISTANCE_3
module.exports.ABILITY_LEGENDARY_RESISTANCE_5 = ABILITY_LEGENDARY_RESISTANCE_5
module.exports.ABILITY_LIMITED_AMPHIBIOUSNESS = ABILITY_LIMITED_AMPHIBIOUSNESS
module.exports.ABILITY_LIMITED_FLIGHT = ABILITY_LIMITED_FLIGHT
module.exports.ABILITY_LOW_CUNNING = ABILITY_LOW_CUNNING
module.exports.ABILITY_LUCKY = ABILITY_LUCKY
module.exports.ABILITY_MAGIC_RESISTANCE = ABILITY_MAGIC_RESISTANCE
module.exports.ABILITY_MAGIC_WEAPONS = ABILITY_MAGIC_WEAPONS
module.exports.ABILITY_MOUNT = ABILITY_MOUNT
module.exports.ABILITY_MOUNTAIN_BORN = ABILITY_MOUNTAIN_BORN
module.exports.ABILITY_NIMBLE_ESCAPE = ABILITY_NIMBLE_ESCAPE
module.exports.ABILITY_OTHERWORLDLY_PERCEPTION = ABILITY_OTHERWORLDLY_PERCEPTION
module.exports.ABILITY_PACK_TACTICS = ABILITY_PACK_TACTICS
module.exports.ABILITY_PARRY = ABILITY_PARRY
module.exports.ABILITY_PETRIFYING_GAZE = ABILITY_PETRIFYING_GAZE
module.exports.ABILITY_PLANT_CAMOUFLAGE = ABILITY_PLANT_CAMOUFLAGE
module.exports.ABILITY_POUNCE = ABILITY_POUNCE
module.exports.ABILITY_POWERFUL_BUILD = ABILITY_POWERFUL_BUILD
module.exports.ABILITY_PRIMAL_BOND = ABILITY_PRIMAL_BOND
module.exports.ABILITY_RAMPAGE = ABILITY_RAMPAGE
module.exports.ABILITY_RECKLESS = ABILITY_RECKLESS
module.exports.ABILITY_REGENERATION = ABILITY_REGENERATION
module.exports.ABILITY_RELENTLESS = ABILITY_RELENTLESS
module.exports.ABILITY_SHADOW_BLEND = ABILITY_SHADOW_BLEND
module.exports.ABILITY_SHADOW_STEALTH = ABILITY_SHADOW_STEALTH
module.exports.ABILITY_SHARK_TELEPATHY = ABILITY_SHARK_TELEPATHY
module.exports.ABILITY_SIEGE_MONSTER = ABILITY_SIEGE_MONSTER
module.exports.ABILITY_SLIPPERY = ABILITY_SLIPPERY
module.exports.ABILITY_SNEAK_ATTACK = ABILITY_SNEAK_ATTACK
module.exports.ABILITY_SNOW_CAMOUFLAGE = ABILITY_SNOW_CAMOUFLAGE
module.exports.ABILITY_SPEAK_WITH_FROGS_AND_TOADS = ABILITY_SPEAK_WITH_FROGS_AND_TOADS
module.exports.ABILITY_SPELL_REFLECTION = ABILITY_SPELL_REFLECTION
module.exports.ABILITY_SPELL_TURNING = ABILITY_SPELL_TURNING
module.exports.ABILITY_SPIDER_CLIMB = ABILITY_SPIDER_CLIMB
module.exports.ABILITY_STANDING_LEAP = ABILITY_STANDING_LEAP
module.exports.ABILITY_STONE_CAMOUFLAGE = ABILITY_STONE_CAMOUFLAGE
module.exports.ABILITY_SUNLIGHT_SENSITIVITY = ABILITY_SUNLIGHT_SENSITIVITY
module.exports.ABILITY_SUNLIGHT_WEAKNESS = ABILITY_SUNLIGHT_WEAKNESS
module.exports.ABILITY_SURE_FOOTED = ABILITY_SURE_FOOTED
module.exports.ABILITY_SURPRISE_ATTACK = ABILITY_SURPRISE_ATTACK
module.exports.ABILITY_SWAMP_CAMOUFLAGE = ABILITY_SWAMP_CAMOUFLAGE
module.exports.ABILITY_SWARM = ABILITY_SWARM
module.exports.ABILITY_TAIL_ATTACK = ABILITY_TAIL_ATTACK
module.exports.ABILITY_TACTICAL_DISCIPLINE = ABILITY_TACTICAL_DISCIPLINE
module.exports.ABILITY_TELEPATHIC_BOND = ABILITY_TELEPATHIC_BOND
module.exports.ABILITY_TELEPORT = ABILITY_TELEPORT
module.exports.ABILITY_TRAMPLING_CHARGE = ABILITY_TRAMPLING_CHARGE
module.exports.ABILITY_TURN_IMMUNITY = ABILITY_TURN_IMMUNITY
module.exports.ABILITY_TURN_RESISTANCE = ABILITY_TURN_RESISTANCE
module.exports.ABILITY_UNARMORED_DEFENSE = ABILITY_UNARMORED_DEFENSE
module.exports.ABILITY_UNARMORED_MOVEMENT = ABILITY_UNARMORED_MOVEMENT
module.exports.ABILITY_UNDEAD_FORTITUDE = ABILITY_UNDEAD_FORTITUDE
module.exports.ABILITY_UNDERWATER_CAMOUFLAGE = ABILITY_UNDERWATER_CAMOUFLAGE
module.exports.ABILITY_UNUSUAL_NATURE = ABILITY_UNUSUAL_NATURE
module.exports.ABILITY_UNYIELDING = ABILITY_UNYIELDING
module.exports.ABILITY_WATER_BREATHING = ABILITY_WATER_BREATHING
module.exports.ABILITY_WEB_SENSE = ABILITY_WEB_SENSE
module.exports.ABILITY_WEB_WALKER = ABILITY_WEB_WALKER
module.exports.ABILITY_WING_ATTACK = ABILITY_WING_ATTACK
