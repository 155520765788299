const {GEAR_ROPE_SILK} = require('./../../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROPE_OF_ENTANGLEMENT} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ROPE_OF_ENTANGLEMENT,
  name: `Верёвка опутывания`,
  nameEn: `Rope of Entanglement`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_ROPE_SILK,
  rarity: MGC_RARITY_RARE,
  description: `Это 30-футовая [верёвка](GEAR:${GEAR_ROPE_SILK}), весящая 3 фунта. Если Вы держите один конец верёвки и действием произносите командное слово, другой конец устремляется вперёд, чтобы обмотаться вокруг видимого Вами в пределах 20 футов существа. Цель должна пройти испытание Ловкости СЛ 15, иначе станет обездвиженной.

Вы можете отпустить существо, произнеся бонусным действием второе командное слово. Цель, обездвиженная верёвкой, может действием совершать проверку Силы или Ловкости (на свой выбор) СЛ 15. При успехе существо перестаёт быть обездвиженным верёвкой.

У верёвки КД 20 и 20 хитов. Она восстанавливает 1 хит каждые 5 минут, если у неё есть хотя бы 1 хит. Если хиты верёвки опускаются до 0, она уничтожается.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 154,
  },
}
