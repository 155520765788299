const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_ENLARGE_REDUCE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_ENLARGE_REDUCE,
  name: 'Увеличение/Уменьшение',
  nameEn: 'Enlarge/Reduce',
  description: `Вы увеличиваете или уменьшаете существо или предмет, видимый Вами в пределах дистанции, на время действия заклинания. Выберите или существо или предмет, который никто не несет и не носит. Если цель хочет, она может пройти испытание Телосложения. При успехе заклинание не оказывает на неё никакого влияния. Если цель — существо, всё, что она носит и несет, изменяет размер вместе с ней. Всё, что это существо бросит, тут же обретает свой естественный размер.

# Увеличение

Размеры цели удваиваются по всем измерениям, а вес увеличивается в восемь раз. Это увеличивает размер на одну категорию — от Среднего до Большого, например. Если для цели не хватает пространства, она приобретает максимально возможный размер. Пока заклинание активно, цель совершает с преимуществом проверки и испытания Силы. Оружие цели тоже увеличивается. Атаки увеличенным оружием причиняют дополнительный урон 1к4.

# Уменьшение

Размеры цели уменьшаются вдвое по всем измерениям, а вес уменьшается до одной восьмой от обычного. Это уменьшает размер на одну категорию — от Среднего до Маленького, например. Пока заклинание активно, цель совершает с помехой проверки и испытания Силы. Оружие цели тоже уменьшается. Атаки уменьшенным оружием причиняют на 1к4 меньше урона (урон не может быть меньше 1).`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  needConcentration: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'щепотка толченого железа',
  duration: { timeId: TIME_MINUTE, count: 1 },
  effect: {
    savethrowParam: PARAM_CON,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 285,
    },
    {
      id: SOURCE_SRD,
      page: 140,
    },
  ],
}
