const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BEHOLDER,
  CREATURE_GAUTH,
  CREATURE_SPECTATOR,
} = require('./../../../../creatureIdList')
const {
  SPEED_WALK,
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_BEHOLDER_KEEN,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DEEP_SPEECH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {beholderLikeDescription} = require('./../../../../textCommonParts')
const {CONDITION_PRONE} = require('./../../../../conditionList')
const {CR_6} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Гаут',
  nameEn: 'Gauth',
  id: CREATURE_GAUTH,
  description: [
    {
      header: 'Гаут',
      text: `Гаут это голодное тираническое бехолдеро-подобное существо, которое поедает магию и старается собирать дань со всех слабее себя. Его тело около 4 футов в диаметре, с шестью глазными стебельками, центральным глазом (иногда окруженные множеством глаз поменьше) и четырьмя небольшими хватающими щупальцами около рта. Его цвет и текстура варьируется примерно так же как у [истинного бехолдера](CREATURE:${CREATURE_BEHOLDER}).`,
      source: {
        id: SOURCE_VGTM,
        page: 126,
      },
    },
    {
      header: 'Магический метаболизм',
      text: `Гаут может питаться мясом, но предпочитает поддерживать себя силой, вытягиваемой из магических объектов. Если он не питался магией несколько недель, то его силой вырывает на домашний план, так что он постоянно ищет новые предметы для поедания. Гаут может нанимать существ на службу, чтобы те приносили ему предметы для пропитания.`,
      source: {
        id: SOURCE_VGTM,
        page: 126,
      },
    },
    {
      header: 'Случайный призыв',
      text: `Когда ритуал призыва [наблюдателя](CREATURE:${CREATURE_SPECTATOR}) проваливается, гаут может броситься через испорченное соединение, прибывая сразу же или несколько минут спустя. Он может представиться [бехолдером](CREATURE:${CREATURE_BEHOLDER}) невежественным существам в попытке запугать их или [наблюдателем](CREATURE:${CREATURE_SPECTATOR}) своему призывателю, чтобы высосать магию из предметов, которые он должен охранять.`,
      source: {
        id: SOURCE_VGTM,
        page: 126,
      },
    },
    {
      header: 'Младший тиран',
      text: `[Бехолдер](CREATURE:${CREATURE_BEHOLDER}) обычно прогоняет или убивает любых гаутов, которые попадают на его территорию, но он может решить взять их в рабство и использовать как лейтенантов. Гауты менее ксенофобны чем бехолдеры, так что они могут формировать маленькие кластеры и работать вместе, хотя они так же могут полностью игнорировать друг друга.`,
      source: {
        id: SOURCE_VGTM,
        page: 126,
      },
    },
    beholderLikeDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_BEHOLDER_KEEN,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_VGTM,
    page: 126,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 9,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 20,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 15,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_SPEECH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_6,
  featureList: [
    {
      name: 'Ошеломляющий взгляд',
      description: `Когда существо, которое может видеть центральный глаз ★СУЩЕСТВО★, начинает свой ход в пределах 30 футов, ★СУЩЕСТВО★ может заставить то существо пройти испытание Мудрости СЛ 14, если ★СУЩЕСТВО★ ★дееспособен★. При провале существо Ошеломлено до начала своего следующего хода.

Если оно не застано врасплох, то существо может отвести взгляд в начале своего хода, чтобы избежать испытания. В таком случае оно не может видеть ★СУЩЕСТВО★ до начала своего следующего хода, когда оно снова может выбрать отвод взгляда. Если существо смотрит на ★СУЩЕСТВО★ до начала своего следующего хода, оно должно немедленно пройти испытание.`,
    },
    {
      name: 'Предсмертная боль',
      description: `Когда ★СУЩЕСТВО★ умирает, магическая энергия в нём взрывается, и каждое существо на расстоянии до 10 футов от него должно пройти испытание Ловкости СЛ 14, получая 13 (3к8) урона силовым полем при провале, или половину урона при успехе.`,
    },
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 0,
        },
      },
    },
    {
      name: 'Лучи из глаз',
      description: `★СУЩЕСТВО★ испускает три магических луча из глаз, выбранных случайным образом (перебрасывая повторяющиеся результаты), выбирая от одной до трёх целей, видимых в пределах 120 футов:

1. **Антимагический луч.** Цель должна пройти испытание Ловкости СЛ 14, иначе один из её магических предметов теряет все свои магические свойства до начала следующего хода ★СУЩЕСТВО★. Если предмет заряжаемый, он также теряет 1к4 зарядов. Предмет определяется случайным образом, одноразовые предметы, вроде зелий и свитков, игнорируются.
2. **Ослабляющий луч.** Цель должна пройти испытание Телосложения СЛ 14, получая 18 (4к8) некротического урона при провале, или половину урона при успехе.
3. **Толкающий луч.** Цель должна пройти испытание Силы СЛ 14, иначе сдвигается на 15 футов от ★СУЩЕСТВО★. Скорость цели уменьшается вдвое до начала следующего хода ★СУЩЕСТВО★.
4. **Огненный луч.** Цель должна пройти испытание Ловкости СЛ 14, или получить 22 (4к10) урона огнём.
5. **Парализующий луч.** Цель должна пройти испытание Телосложения СЛ 14, иначе она Парализована на 1 минуту. Цель может повторять испытание в конце каждого своего следующего хода, оканчивая паралич при успехе.
6. **Усыпляющий луч.** Цель должна пройти испытание Мудрости СЛ 14, иначе она засыпает, теряя сознание на 1 минуту. Цель просыпается, если получает урон или если другое существо действием будит его. Этот луч не оказывает воздействия на конструктов и нежить.`,
    },
  ],
}
