const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGICAL_ATTACKS,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  LANG_MODRONE,
  LANG_ONE_OF_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  SPELL_DIMENSION_DOOR,
  SPELL_PLANE_SHIFT,
} = require('./../../../../spellIdList')
const {
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_16} = require('./../../../../crList')
const {CREATURE_STEEL_PREDATOR} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Стальной хищник',
  nameEn: 'Steel Predator',
  id: CREATURE_STEEL_PREDATOR,
  description: [
    `Стальной хищник — беспощадная машина с одной единственной задачей: обнаружить и убить свою цель, независимо от расстояния и препятствий.`,
    {
      header: 'Технология модронов',
      text: `Стальные хищники созданы определённым гекстоном модронов, при помощи машины, расположенной в Сигиле. Однако она не всегда была расположена в Городе Дверей. В своём первоначальном доме — план Механуса, гениального гекстона восхваляли за его изобретения — пока он не обратил свои творения против своих вышестоящих лиц. Стальные хищники разрушали иерархию модронов до тех пор, пока гекстон-отступник не был пойман и не изгнан. Теперь он владеет магазином в Сигиле, где за высокую цену каждый может заказать себе стального хищника.`,
      source: {
        id: SOURCE_MTOF,
        page: 229,
      },
    },
    {
      header: 'Убийцы по заказу',
      text: `Чтобы создать стального хищника, машине гекстона нужно скормить то, что идентифицирует цель хищника, например, прядь волос, хорошо изношенную перчатку или многократно используемое оружие. Как только появляется новый изготовленный хищник, он сразу уходит в поисках своей добычи. Он чует местоположение своей цели даже через межпланарные границы, но такое обнаружение является точным только на расстоянии в тысячу ярдов; на более близкой дистанции стальной хищник ищет добычу при помощи зрения и обоняния.

Вступив в сражение, хищник игнорирует любую другую угрозу и атакует свою добычу, пока другие существа не мешают ему достигнуть цели. В противном случае он делает то, что нужно, чтобы выполнить свою миссию.`,
      source: {
        id: SOURCE_MTOF,
        page: 229,
      },
    },
    {
      header: 'Неразборчивые убийцы',
      text: `Если всё идёт по плану и стальной хищник убивает свою цель, а затем добровольно возвращается в Сигил, где он разбирается на части, которые можно использовать в другом стальном хищнике. Однако, урон полученный в битве может привести к тому, что этот инстинкт откажет, заставив стального хищника задержаться в этой области, охотясь и убивая других существ, которые напоминают его добычу, соответствуют общему описанию цели или просто живут поблизости. Такие бродячие механизмы становятся самыми опасными из хищников.`,
      source: {
        id: SOURCE_MTOF,
        page: 229,
      },
    },
    {
      header: 'Природа конструкта',
      text: `Стальной хищник не нуждается в воздухе, пище, питье или сне.`,
      source: {
        id: SOURCE_MTOF,
        page: 229,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 229,
  },
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 22,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 6,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
    [SKILL_SURVIVAL]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_NECROTIC,
    DAMAGE_THUNDER,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
    DAMAGE_NONMAGICAL_ATTACKS,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: [
        LANG_MODRONE,
        LANG_ONE_OF_ITS_CREATOR,
      ],
      doNotSpeak: true,
    },
  ],
  cr: CR_16,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_INT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          {
            id: SPELL_DIMENSION_DOOR,
            comment: 'только на себя',
          },
          {
            id: SPELL_PLANE_SHIFT,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Стальной хищник совершает три атаки: одну _Укусом_ и две _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Ошеломляющий рык',
      restore: 5,
      description: `Стальной хищник издаёт рык 60-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 19. При провале существо получает 27 (5к10) урона звуком, роняет всё, что держит, и становится ошеломлённым на 1 минуту. При успехе, существо получает половину урона. Ошеломлённое существо должно повторять испытание в конце каждого своего хода. При успехе эффект на цели заканчивается.`,
    },
  ],
}
