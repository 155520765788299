const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_SLAAD,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_SLAAD,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_SLAAD_BLUE,
  CREATURE_SLAAD_GREEN,
  CREATURE_SLAAD_RED,
  CREATURE_SLAAD_TADPOLE,
} = require('./../../../../creatureIdList')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_5} = require('./../../../../crList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {slaadDescription} = require('./../../../../textCommonParts')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Красный слаад`,
  nameEn: 'Red Slaad',
  id: CREATURE_SLAAD_RED,
  description: [
    `Когда красный слаад впивается в гуманоидное существо когтями, он может ввести в него яйцо из железы под одним из своих когтей. Яйцо проникает в носителя и начинает развиваться, со временем превращаясь в [головастика](CREATURE:${CREATURE_SLAAD_TADPOLE}). Этот головастик начинает прогрызать тело носителя, потом питается его останками, а потом начинает искать любое другое свежее мясо. Головастик превращается в полностью выросшего [синего слаада](CREATURE:${CREATURE_SLAAD_BLUE}) (либо [зелёного слаада](CREATURE:${CREATURE_SLAAD_GREEN}), если носитель мог сотворять заклинания 3 уровня и выше) через 2к12 часов.`,
    slaadDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_ABERRATION,
    CREATURE_TYPE_SLAAD,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MM,
    page: 265,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 16,
    [PARAM_INT]: 6,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_THUNDER,
    DAMAGE_ACID,
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_SLAAD,
    {
      id: LANG_TELEPATHY,
      range: 60,
    },
  ],
  cr: CR_5,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: `Регенерация`,
      description: `★СУЩЕСТВО★ восстанавливает 10 хитов в начале своего хода, если у него есть хотя бы 1 хит.`,
    },
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, и две _Когтями_.`,
    },
    {
      name: `Укус`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
    {
      name: `Коготь`,
      description: `Если цель — гуманоид, она должна пройти испытание Телосложения СЛ 14, иначе заразится болезнью — крошечным яйцом слаада.

В гуманоиде одновременно может находиться только одно яйцо. Через три месяца яйцо перемещается в грудную клетку, созревает и формирует [головастика слаада](CREATURE:${CREATURE_SLAAD_TADPOLE}). За 24 часа до появления головастика на свет носитель начинает чувствовать себя нехорошо, его скорость уменьшается вдвое, и он совершает с помехой броски атаки, проверки характеристик и проходит испытания. При рождении головастик проедает свой путь через внутренние органы и выходит из грудной клетки носителя за 1 раунд, убивая тем самым носителя.

Если болезнь вылечить до рождения головастика, слаад рассосётся внутри носителя.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
