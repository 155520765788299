const {
  MAGIC_CONJURATION,
  MAGIC_EVOCATION,
  MAGIC_NECROMANCY,
  MAGIC_TRANSMUTATION,
} = require('./../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../castComponentList')
const {
  SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
} = require('./../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../timePeriodList')
const {
  PARAM_DEX,
} = require('./../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_NECROTIC,
  DAMAGE_RADIANT,
} = require('./../../damageTypeList')
const {
  SPELL_ACID_STREAM_2020_03_26,
  SPELL_OTHERWORLDLY_FORM_2020_03_26,
  SPELL_SPIRIT_SHROUD,
  SPELL_SPIRIT_SHROUD_2020_03_26,
  SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
  SPELL_SUMMON_ABERRATION,
  SPELL_SUMMON_BEAST,
  SPELL_SUMMON_BESTIAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_CELESTIAL,
  SPELL_SUMMON_CELESTIAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_ELEMENTAL,
  SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
  SPELL_SUMMON_FEY,
  SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
  SPELL_SUMMON_FIEND,
  SPELL_SUMMON_FIENDISH_SPIRIT_2020_03_26,
  SPELL_SUMMON_SHADOWSPAWN,
  SPELL_SUMMON_SHADOW_SPIRIT_2020_03_26,
  SPELL_SUMMON_UNDEAD,
  SPELL_SUMMON_UNDEAD_SPIRIT_2020_03_26,
  SPELL_TASHA_S_CAUSTIC_BREW,
  SPELL_TASHA_S_OTHERWORLDLY_GUISE,
} = require('./../../spellIdList')
const {
  CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26,
  CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
  CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26,
  CREATURE_CELESTIAL_AVENGER_SPIRIT_2020_03_26,
  CREATURE_CELESTIAL_DEFENDER_SPIRIT_2020_03_26,
  CREATURE_DECEITFUL_FEY_SPIRIT_2020_03_26,
  CREATURE_DEMON_SPIRIT_2020_03_26,
  CREATURE_DESPAIR_SHADOW_SPIRIT_2020_03_26,
  CREATURE_DEVIL_SPIRIT_2020_03_26,
  CREATURE_EARTH_ELEMENTAL_SPIRIT_2020_03_26,
  CREATURE_FEAR_SHADOW_SPIRIT_2020_03_26,
  CREATURE_FIRE_ELEMENTAL_SPIRIT_2020_03_26,
  CREATURE_FURIOUS_FEY_SPIRIT_2020_03_26,
  CREATURE_FURY_SHADOW_SPIRIT_2020_03_26,
  CREATURE_GHOSTLY_UNDEAD_SPIRIT_2020_03_26,
  CREATURE_JOYFUL_FEY_SPIRIT_2020_03_26,
  CREATURE_LAND_BESTIAL_SPIRIT_2020_03_26,
  CREATURE_PUTRID_UNDEAD_SPIRIT_2020_03_26,
  CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26,
  CREATURE_SLAADI_SPIRIT_2020_03_26,
  CREATURE_STAR_SPAWN_SPIRIT_2020_03_26,
  CREATURE_WATER_BESTIAL_SPIRIT_2020_03_26,
  CREATURE_WATER_ELEMENTAL_SPIRIT_2020_03_26,
  CREATURE_YUGOLOTH_SPIRIT_2020_03_26,
} = require('./../../creatureIdList')

module.exports = [
  {
    id: SPELL_ACID_STREAM_2020_03_26,
    releasedAs: SPELL_TASHA_S_CAUSTIC_BREW,
    name: 'Поток кислоты',
    nameEn: 'Acid Stream',
    description: `Вы испускаете поток кислоты 30-футовой линией шириной 5 футов в выбранном Вами направлении. Все существа в этой линии должны пройти испытание Ловкости.

При провале цель покрывается кислотой на всё время действия заклинания, или пока она сама или другое существо действием не очистит её от кислоты. Пока цель покрыта кислотой, она получает 6 (3к4) урона кислотой в начале каждого своего хода.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к4 за каждый круг ячейки выше первого.`,
    lvl: 1,
    magicSchoolId: MAGIC_EVOCATION,
    range: -1,
    rangeComment: `30-футовая линия`,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `немного испорченной еды`,
    duration: { timeId: TIME_MINUTE, count:  1},
    needConcentration: true,
    effect: {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_ACID,
        diceCount: 3,
        diceType: 4,
      },
    },
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 2,
    },
  },
  {
    id: SPELL_OTHERWORLDLY_FORM_2020_03_26,
    releasedAs: SPELL_TASHA_S_OTHERWORLDLY_GUISE,
    name: 'Форма иного мира',
    nameEn: 'Otherworldly Form',
    description: `Произнеся магические слова, Вы преобразуете себя магией Верхних или Нижних планов (по Вашему выбору).

* Вы получаете иммунитет к излучению и некротической энергии (Верхние планы) или к огню и яду (Нижние планы).  
* Вы получаете иммунитет к  Очарованию (Верхние планы) или Отравлению (Нижние планы)
* За спиной у Вас появляются призрачные крылья, дающие Вам скорость полёта 40 футов. 
* Вы получаете +2 к КД.
* Все Ваши атаки оружием становятся магическими и, когда Вы совершаете атаку оружием, Вы можете использовать для бросков атаки и урона свою заклинательную характеристику вместо Силы или Ловкости.
* Когда Вы в свой ход совершаете действие Атака, Вы можете совершить две атаки вместо одной. Вы игнорируете это преимущество, если уже имеете способность, дающую Вам дополнительную атаку, такую как Дополнительная атака.`,
    lvl: 6,
    magicSchoolId: MAGIC_TRANSMUTATION,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `предмет с вырезанным на нём символом Внешних планов, стоимостью не менее 500 зм`,
    hasCost: true,
    duration: { timeId: TIME_MINUTE, count:  1},
    needConcentration: true,
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 2,
    },
  },
  {
    id: SPELL_SPIRIT_SHROUD_2020_03_26,
    releasedAs: SPELL_SPIRIT_SHROUD,
    name: 'Духовный саван',
    nameAlt: 'Покров духа',
    nameEn: 'Spirit Shroud',
    description: `Вы вызываете добрых или злых (на Ваш выбор) духов мёртвых, которые летают вокруг Вас всё время действия заклинания. Духи неосязаемы и неуязвимы.

Пока заклинание действует, все совершаемые Вами атаки наносят дополнительно 1к8 урона, если Вы попадаете по существу в пределах 10 футов. Это урон излучением для добрых духов или некротический — для злых. Существо, получившее этот урон, не может восстанавливать хиты до начала Вашего следующего хода.

Кроме того, скорость любых видимых Вами существ по Вашему выбору, начинающих свой ход в пределах 10 футов от Вас, уменьшается на 10 футов до начала Вашего следующего хода.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, дополнительный урон увеличивается на 1к8 за каждый круг ячейки выше третьего.`,
    lvl: 3,
    magicSchoolId: MAGIC_NECROMANCY,
    range: -1,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC],
    duration: { timeId: TIME_MINUTE, count:  1},
    castTime: { timeId: TIME_BONUS_ACTION, count:  1},
    needConcentration: true,
    effect: {
      damage: {
        type: [
          DAMAGE_RADIANT,
          DAMAGE_NECROTIC,
        ],
        diceCount: 1,
        diceType: 8,
      },
    },
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 2,
    },
  },
  {
    id: SPELL_SUMMON_ABERRANT_SPIRIT_2020_03_26,
    releasedAs: SPELL_SUMMON_ABERRATION,
    name: 'Призыв духа аберрации',
    nameEn: 'Summon Aberrant Spirit',
    description: `Вы призываете духа из Дальнего Предела или иного чужеродного царства безумия. Дух физически проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания. 

Когда Вы сотворяете это заклинание, выберите [Злобоглазов](CREATURE:${CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26}), [Слаадов](CREATURE:${CREATURE_SLAADI_SPIRIT_2020_03_26}) или [Звёздных порождений](CREATURE:${CREATURE_STAR_SPAWN_SPIRIT_2020_03_26}). Существо выглядит как представитель выбранного Вами вида, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.
    
Существо дружественно к Вам и Вашим спутникам всю длительность заклинания. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно защищает себя, но не предпринимает других действий.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
    createdCreatureId: [
      CREATURE_BEHOLDERKIN_SPIRIT_2020_03_26,
      CREATURE_SLAADI_SPIRIT_2020_03_26,
      CREATURE_STAR_SPAWN_SPIRIT_2020_03_26,
    ],
    lvl: 4,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `маринованые щупальце и глазное яблоко в хрустальной бутылочке стоимостью не менее 400 золотых`,
    hasCost: true,
    duration: { timeId: TIME_HOUR, count:  1},
    needConcentration: true,
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 3,
    },
  },
  {
    id: SPELL_SUMMON_BESTIAL_SPIRIT_2020_03_26,
    releasedAs: SPELL_SUMMON_BEAST,
    name: 'Призыв духа зверя',
    nameEn: 'Summon Bestial Spirit',
    description: `Вы призываете духа зверя. Дух физически проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания. 

Когда Вы сотворяете это заклинание, выберите среду обитания: [Воздух](CREATURE:${CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26}), [Воду](CREATURE:${CREATURE_WATER_BESTIAL_SPIRIT_2020_03_26}) или [Землю](CREATURE:${CREATURE_LAND_BESTIAL_SPIRIT_2020_03_26}). Существо выглядит как животное, обитающее в выбранной Вами среде обитания, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.
    
Существо дружественно к Вам и Вашим спутникам всю длительность заклинания. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно защищает себя, но не предпринимает других действий.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 3 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
    createdCreatureId: [
      CREATURE_AIR_BESTIAL_SPIRIT_2020_03_26,
      CREATURE_LAND_BESTIAL_SPIRIT_2020_03_26,
      CREATURE_WATER_BESTIAL_SPIRIT_2020_03_26,
    ],
    lvl: 2,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `перо, пучок меха и рыбный хвост внутри позолоченного жёлудя стоимостью не менее 200 золотых`,
    hasCost: true,
    duration: { timeId: TIME_HOUR, count:  1},
    needConcentration: true,
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 3,
    },
  },
  {
    id: SPELL_SUMMON_CELESTIAL_SPIRIT_2020_03_26,
    releasedAs: SPELL_SUMMON_CELESTIAL,
    name: 'Призыв духа небожителя',
    nameEn: 'Summon Celestial Spirit',
    description: `Вы призываете духа с Верхних Планов. Дух физически проявляется в форме ангела в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите [Мстителя](CREATURE:${CREATURE_CELESTIAL_AVENGER_SPIRIT_2020_03_26}) или [Защитника](CREATURE:${CREATURE_CELESTIAL_DEFENDER_SPIRIT_2020_03_26}). Ваш выбор определяет некоторые способности призванного существа. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.

Существо дружественно к Вам и Вашим спутникам всю длительность заклинания. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно защищает себя, но не предпринимает других действий.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 6 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
    createdCreatureId: [
      CREATURE_CELESTIAL_AVENGER_SPIRIT_2020_03_26,
      CREATURE_CELESTIAL_DEFENDER_SPIRIT_2020_03_26,
    ],
    lvl: 5,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `золочёный реликварий стоимостью не менее 500 золотых`,
    hasCost: true,
    duration: { timeId: TIME_HOUR, count:  1},
    needConcentration: true,
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 4,
    },
  },
  {
    id: SPELL_SUMMON_ELEMENTAL_SPIRIT_2020_03_26,
    releasedAs: SPELL_SUMMON_ELEMENTAL,
    name: 'Призыв духа стихии',
    nameAlt: 'Призыв духа элементаля',
    nameEn: 'Summon Elemental Spirit',
    description: `Вы призываете духа со Стихийных планов. Дух физически проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите элемент: [Воздух](CREATURE:${CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26}), [Воду](CREATURE:${CREATURE_WATER_ELEMENTAL_SPIRIT_2020_03_26}), [Землю](CREATURE:${CREATURE_EARTH_ELEMENTAL_SPIRIT_2020_03_26}) или [Огонь](CREATURE:${CREATURE_FIRE_ELEMENTAL_SPIRIT_2020_03_26}). Существо представляет собой размытую гуманоидную фигуру, состоящую из выбранного Вами элемента, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.

Существо дружественно к Вам и Вашим спутникам всю длительность заклинания. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно защищает себя, но не предпринимает других действий.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 5 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
    createdCreatureId: [
      CREATURE_AIR_ELEMENTAL_SPIRIT_2020_03_26,
      CREATURE_EARTH_ELEMENTAL_SPIRIT_2020_03_26,
      CREATURE_WATER_ELEMENTAL_SPIRIT_2020_03_26,
      CREATURE_FIRE_ELEMENTAL_SPIRIT_2020_03_26,
    ],
    lvl: 4,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `воздух, галька, пепел и вода внутри хрустального флакона стоимостью не менее 400 золотых`,
    hasCost: true,
    duration: { timeId: TIME_HOUR, count:  1},
    needConcentration: true,
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 4,
    },
  },
  {
    id: SPELL_SUMMON_FEY_SPIRIT_2020_03_26,
    releasedAs: SPELL_SUMMON_FEY,
    name: 'Призыв духа феи',
    nameEn: 'Summon Fey Spirit',
    description: `Вы призываете духа из Страны Фей. Дух физически проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите настроение: [Радостное](CREATURE:${CREATURE_JOYFUL_FEY_SPIRIT_2020_03_26}), [Лживое](CREATURE:${CREATURE_DECEITFUL_FEY_SPIRIT_2020_03_26}) или [Яростное](CREATURE:${CREATURE_FURIOUS_FEY_SPIRIT_2020_03_26}). Существо выглядит как дриада, сатир или эльф (на Ваш выбор) в выбранном Вами настроении, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.

Существо дружественно к Вам и Вашим спутникам всю длительность заклинания. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно защищает себя, но не предпринимает других действий.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
    createdCreatureId: [
      CREATURE_DECEITFUL_FEY_SPIRIT_2020_03_26,
      CREATURE_FURIOUS_FEY_SPIRIT_2020_03_26,
      CREATURE_JOYFUL_FEY_SPIRIT_2020_03_26,
    ],
    lvl: 3,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `позолоченый цветок стоимостью не менее 300 золотых`,
    hasCost: true,
    duration: { timeId: TIME_HOUR, count:  1},
    needConcentration: true,
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 5,
    },
  },
  {
    id: SPELL_SUMMON_FIENDISH_SPIRIT_2020_03_26,
    releasedAs: SPELL_SUMMON_FIEND,
    name: 'Призыв духа исчадия',
    nameEn: 'Summon Fiendish Spirit',
    description: `Вы призываете духа исчадия из Нижних планов. Дух физически проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите [Демона](CREATURE:${CREATURE_DEMON_SPIRIT_2020_03_26}), [Дьявола](CREATURE:${CREATURE_DEVIL_SPIRIT_2020_03_26}) или [Юголота](CREATURE:${CREATURE_YUGOLOTH_SPIRIT_2020_03_26}). Существо выглядит как исчадие выбранного вида, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.

Существо дружественно к Вам и Вашим спутникам всю длительность заклинания. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно защищает себя, но не предпринимает других действий.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 7 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
    createdCreatureId: [
      CREATURE_DEMON_SPIRIT_2020_03_26,
      CREATURE_DEVIL_SPIRIT_2020_03_26,
      CREATURE_YUGOLOTH_SPIRIT_2020_03_26,
    ],
    lvl: 6,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `кровь гуманоида внутри хрустального флакона стоимостью не менее 600 золотых`,
    hasCost: true,
    duration: { timeId: TIME_HOUR, count:  1},
    needConcentration: true,
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 6,
    },
  },
  {
    id: SPELL_SUMMON_SHADOW_SPIRIT_2020_03_26,
    releasedAs: SPELL_SUMMON_SHADOWSPAWN,
    name: 'Призыв теневого духа',
    nameEn: 'Summon Shadow Spirit',
    description: `Вы призываете теневого духа из Царства Теней. Дух физически проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите эмоцию [Отчаяние](CREATURE:${CREATURE_DESPAIR_SHADOW_SPIRIT_2020_03_26}), [Страх](CREATURE:${CREATURE_FEAR_SHADOW_SPIRIT_2020_03_26}) или [Ярость](CREATURE:${CREATURE_FURY_SHADOW_SPIRIT_2020_03_26}). Существо выглядит как искривлённый гуманоид, явно проявляющего выбранную эмоцию, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.

Существо дружественно к Вам и Вашим спутникам всю длительность заклинания. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно защищает себя, но не предпринимает других действий.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
    createdCreatureId: [
      CREATURE_DESPAIR_SHADOW_SPIRIT_2020_03_26,
      CREATURE_FEAR_SHADOW_SPIRIT_2020_03_26,
      CREATURE_FURY_SHADOW_SPIRIT_2020_03_26,
    ],
    lvl: 3,
    magicSchoolId: MAGIC_CONJURATION,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `слеза внутри хрустального флакона стоимостью не менее 300 золотых`,
    hasCost: true,
    duration: { timeId: TIME_HOUR, count:  1},
    needConcentration: true,
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 7,
    },
  },
  {
    id: SPELL_SUMMON_UNDEAD_SPIRIT_2020_03_26,
    releasedAs: SPELL_SUMMON_UNDEAD,
    name: 'Призыв духа нежити',
    nameEn: 'Summon Undead Spirit',
    description: `Вы призываете неупокоенного духа из могилы. Дух физически проявляется в свободном пространстве, видимом Вами в пределах дальности заклинания.

Когда Вы сотворяете это заклинание, выберите состояние существа: [Разлагающееся](CREATURE:${CREATURE_PUTRID_UNDEAD_SPIRIT_2020_03_26}), [Призрак](CREATURE:${CREATURE_GHOSTLY_UNDEAD_SPIRIT_2020_03_26}) или [Скелет](CREATURE:${CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26}). Существо выглядит как гуманоид в выбранном состоянии, что определяет некоторые его способности. Существо исчезает, когда его хиты опускаются до 0 или когда заклинание оканчивается.

Существо дружественно к Вам и Вашим спутникам всю длительность заклинания. В бою существо действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Оно подчиняется Вашим устным командам (действие не требуется). Если Вы не даёте ему приказов, оно защищает себя, но не предпринимает других действий.`,
    highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 4 круга или выше, вызванное существо соответственно усиливает свои способности, если это указано в его статблоке.`,
    createdCreatureId: [
      CREATURE_GHOSTLY_UNDEAD_SPIRIT_2020_03_26,
      CREATURE_PUTRID_UNDEAD_SPIRIT_2020_03_26,
      CREATURE_SKELETAL_UNDEAD_SPIRIT_2020_03_26,
    ],
    lvl: 3,
    magicSchoolId: MAGIC_NECROMANCY,
    range: 90,
    componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
    materialText: `позолоченый череп гуманоида стоимостью не менее 300 золотых`,
    hasCost: true,
    duration: { timeId: TIME_HOUR, count:  1},
    needConcentration: true,
    source: {
      id: SOURCE_UA_2020_03_26_SPELLS_AND_MAGIC_TATTOOS,
      page: 7,
    },
  },
]
