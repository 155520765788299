const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SOURCE_MM,
  SOURCE_ERRATA_MONSTER_MANUAL,
} = require('./../../../../sourceList')
const {
  SENSE_BLIND_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_15} = require('./../../../../crList')
const {CREATURE_PURPLE_WORM} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Лиловый червь',
  nameEn: 'Purple Worm',
  description: [
    `Массивное роющее чудовище, известное как лиловый червь, терроризирует существ Подземья, прогрызаясь через каменные породы в погоне за добычей. Будучи тупой, хищной силой природы, это существо считает едой всё, что видит.`,
    {
      header: 'Прожорливые хищники',
      text: `Громкие звуки привлекают лиловых червей, известных своим вторжением в подземные сражения и проникновением в подземные города в поисках добычи. Подземные цивилизации дроу, двергаров и иллитидов поддерживают специальные меры защиты вокруг своих поселений, чтоб защититься от этих чудовищ.

Хотя лиловые черви наиболее распространены в Подземье, они часто встречаются на поверхности в скальных и горных областях. Пасть лилового червя достаточно велика, чтоб проглотить целиком лошадь, и ни одно существо не застраховано от его голода. Червь бросается вперёд, ритмично сжимая и расширяя своё тело, заставая врасплох других обитателей Подземья, которые не ожидают от него такой скорости.`,
      source: {
        id: SOURCE_MM,
        page: 194,
      },
    },
    {
      header: 'Польза червей',
      text: `Когда лиловый червь прогрызается через землю, он поглощает и тут же выделяет землю и камни, которые только что разрушил. Таким образом, в телах лиловых червей можно найти драгоценные металлы и камни, за которыми и охотятся особо отважные и отчаянные охотники за сокровищами.

Роющий лиловый червь постоянно создаёт новые туннели через Подземье, которыми другие существа вскоре начинают пользоваться в качестве коридоров и магистралей. Из-за того, что лиловые черви редко возвращаются в свои же туннели, такие проходы — хорошее место, чтоб избежать этих чудовищ. Богатые добычей районы быстро заполняются сложной системой туннелей в результате совместной охоты сразу нескольких червей.`,
      source: {
        id: SOURCE_MM,
        page: 194,
      },
    },
  ],
  id: CREATURE_PURPLE_WORM,
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: [
    {
      id: SOURCE_MM,
      page: 194,
    },
    {
      id: SOURCE_ERRATA_MONSTER_MANUAL,
      page: 1,
    },
  ],
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 50,
    [SPEED_DIG]: 30,
  },
  params: {
    [PARAM_STR]: 28,
    [PARAM_DEX]: 7,
    [PARAM_CON]: 22,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 4,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  cr: CR_15,
  featureList: [
    {
      name: 'Прокладывание туннеля',
      description: `★СУЩЕСТВО★ может копать сквозь сплошной камень со скоростью копания, уменьшенной вдвое, оставляя за собой туннель диаметром 10 футов.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну _Укусом_, и одну _Жалом_.`,
    },
    {
      name: 'Укус',
      description: `Если цель — существо с размером не больше Большого, она должна пройти испытание Ловкости СЛ 19, иначе будет проглочена. Проглоченное существо ослеплено и обездвижено, у него полное укрытие от атак и прочих эффектов, происходящих извне ★СУЩЕСТВО★, и оно получает урон кислотой 21 (6к6) в начале каждого хода ★СУЩЕСТВО★.

Если ★СУЩЕСТВО★ получает 30 или больше урона за один ход от существа, находящегося внутри его, ★СУЩЕСТВО★ ★должен★ в конце этого хода пройти испытание Телосложения СЛ 21, иначе он отрыгнёт всех проглоченных существ, которые падают ничком в пространстве в пределах 10 футов от червя. Если ★СУЩЕСТВО★ умирает, проглоченное существо перестаёт быть обездвиженым ★им★, и может высвободиться из трупа, потратив 20 футов перемещения, падая при выходе ничком.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 9,
        },
      },
    },
    {
      name: 'Жало на хвосте',
      description: `Цель должна пройти испытание Телосложения СЛ 19, получая урон ядом 42 (12к6) при провале, или половину этого урона при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 14,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 9,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
