const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const {GENDER_MIDDLE} = require('./../../genderList')
const {CREATURE_TYPE_FIEND} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_FIEND,
  nameEn: 'Fiend',
  nameAlt: 'Бестия',
  genderId: GENDER_MIDDLE,
  notRealRace: true,
  description: [
    {
      text: `Исчадия — злобные существа, обитающие на Нижних Планах. Некоторые из них служит богам, но многие в подчинении у архидьяволов и демонических повелителей. Злые жрецы и маги иногда призывают исчадий в материальный мир, чтобы те выполнили их желания. Если среди небожителей зло — это редкость, то встретить доброе исчадие практически невозможно. К исчадиям относятся демоны, дьяволы, адские гончие, ракшасы и юголоты.`,
      source: {
        id: SOURCE_MM,
        page: 8,
      },
    },
    {
      header: `Бестии`,
      text: `Злобные, свирепые создания с Нижних планов под властью принципиальных архидьяволов или хаотичных принцев демонов.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  name: {
    singular: {
      nominative: 'исчадие',
      genitive: 'исчадия',
      dative: 'исчадию',
      accusative: 'исчадие',
      instrumental: 'исчадием',
      prepositional: 'исчадии',
    },
    plural: {
      nominative: 'исчадия',
      genitive: 'исчадий',
      dative: 'исчадиям',
      accusative: 'исчадий',
      instrumental: 'исчадиями',
      prepositional: 'исчадиях',
    },
  },
}
