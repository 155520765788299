const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CREATURE_NECROMITE_OF_MYRKUL} = require('./../../../../creatureIdList')
const {CR_1_2} = require('./../../../../crList')
const {GEAR_FLAIL} = require('./../../../../gearIdList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_BGDIA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {myrkulFollowerList} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ARCANA,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_NECROTIC,
} = require('./../../../../damageTypeList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_INFERNAL,
} = require('./../../../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')

module.exports = {
  name: 'Некромайт Миркула',
  nameEn: 'Necromite of Myrkul',
  id: CREATURE_NECROMITE_OF_MYRKUL,
  description: myrkulFollowerList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_HUMAN,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_BGDIA,
    page: 238,
  },
  armor: 11,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 15,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
    LANG_ABYSSAL,
    LANG_INFERNAL,
  ],
  cr: CR_1_2,
  actionList: [
    {
      name: 'Цеп с черепом',
      gearId: GEAR_FLAIL,
    },
    {
      name: 'Когти могилы',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 5,
        range: 90,
        hit: {
          type: DAMAGE_NECROTIC,
          diceCount: 2,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
