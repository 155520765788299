const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_WAR_PICK} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_WAR_PICK,
  genderId: GENDER_MALE,
  name: 'Клевец',
  nameByCase: {
    nominative: 'клевец',
    genitive: 'клевца',
    accusative: 'клевец',
    instrumental: 'клевцом',
  },
  nameAlt: 'Боевая кирка',
  nameEn: 'War Pick',
  description: `Маленький, острый шип с рукоятью, традиционно используемый для разрушения льда или камня. Кирку легко спрятать и она эффективен в ближнем бою.`,
  damage: {
    diceType: 8,
    diceCount: 1,
  },
  cost: 500,
  damageType: DAMAGE_PIERCING,
  weight: 2,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 117,
    },
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
