const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_GRUNG,
  CREATURE_GRUNG_ELITE_WARRIOR,
  CREATURE_GRUNG_WILDLING,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Грунги',
    text: `Грунги — это агрессивные жабоподобные гуманоиды, которых можно встретиться в дождливых лесах и тропических джунглях. Они яростно защищают территорию и мнят себя превосходящими остальных существ.`,
    source: {
      id: SOURCE_VGTM,
      page: 145,
    },
  },
  {
    header: 'Живущие на деревьях амфибии',
    text: `Грунги живут в деревьях и предпочитают тень. Инкубатор грунгов хорошо охраняется и находится в прудах на уровне земли. Спустя примерно три месяца после вылупления головастик грунга принимает форму взрослого. Ещё от шести до девяти месяцев занимает у юного грунга процесс взросления.`,
    source: {
      id: SOURCE_VGTM,
      page: 145,
    },
  },
  {
    header: 'Касты и цвета',
    text: `Общество грунгов основано на системе каст. Каждая каста откладывает яйца в отдельный пруд инкубатор и юные грунги присоединяются к своей касте после появления из инкубатора. Все грунги тускло-зелёно-серые при рождении, но каждый индивид, взрослея, принимает цвет своей касты.

[Зелёные грунги](CREATURE:${CREATURE_GRUNG}) — это воины, охотники и рабочие племени, а [синие](CREATURE:${CREATURE_GRUNG}) работают ремесленниками и занимаются домашними делами. Надзирают и направляют обе группы [фиолетовые грунги](CREATURE:${CREATURE_GRUNG}), которые служат администраторами и командирами.

[Красные грунги](CREATURE:${CREATURE_GRUNG_WILDLING}) — это ученые племени, использующие магию. Они стоят выше [фиолетовых](CREATURE:${CREATURE_GRUNG}), [синих](CREATURE:${CREATURE_GRUNG}) и [зелёных](CREATURE:${CREATURE_GRUNG}) грунгов и им отдают уважение даже грунги более высокого статуса.

Высшие касты включают в себя [оранжевых грунгов](CREATURE:${CREATURE_GRUNG_ELITE_WARRIOR}), элитных воинов, которые имеют власть над всеми меньшими грунгами, и [золотых грунгов](CREATURE:${CREATURE_GRUNG_ELITE_WARRIOR}), которые занимают высшие лидерские позиции. Правителем племени всегда является [золотой грунг](CREATURE:${CREATURE_GRUNG_ELITE_WARRIOR}).

Грунг обычно остаётся в своей касте на всю жизнь. В редких случаях, индивид, который отличился великими свершениями, может заработать приглашение на вступление в более высокую касту. Посредством смеси тонизирующих трав и ритуальной магии, возвышающийся грунг меняет цвет и принимается в новую касту так же как и любой юный грунг. С этого момента, грунг и его потомство являются членами более высокой касты.`,
    source: {
      id: SOURCE_VGTM,
      page: 145,
    },
  },
  {
    header: 'Ядовитые от природы',
    text: `Все грунги выделяют субстанцию, которая безвредна для них, но ядовита для других существ. Грунги используют яд для отравления своего оружия.`,
    source: {
      id: SOURCE_VGTM,
      page: 145,
    },
  },
  {
    header: 'Работорговцы',
    text: `Грунги всегда выискивают существ, которых можно схватить и сделать рабами. Грунги используют рабов для любых типов работ, но в основном они просто любят ими помыкать.

Рабов кормят слегка отравленной едой, чтобы они оставались апатичными и податливыми. Существо, подверженное этому в течение долгого периода времени, становится лишь оболочкой прежнего себя и может вернуться в нормальное состояние только посредством магии.`,
    source: {
      id: SOURCE_VGTM,
      page: 145,
    },
  },
  {
    header: 'Зависимость от воды',
    text: `Грунг, который не погрузился в воду хотя бы на 1 час в течение дня, получает один уровень истощения в конце этого дня. Грунг может избавиться от этого истощения только магией или погрузившись в воду по крайней мере на 1 час.`,
    source: {
      id: SOURCE_VGTM,
      page: 145,
    },
  },
  {
    header: 'Вариант: яд грунга',
    text: `Яд грунга теряет свои свойства через 1 минуту после отделения от него. Тот же процесс происходит и после смерти грунга.

Существо, отравленное грунгом, страдает от дополнительного эффекта в зависимости от цвета грунга. Эффект длится до окончания действия яда грунга.

# Зеленый

Отравленное существо не может двигаться, а только карабкаться или прыгать без разбега, с места. Если существо летает, то не может совершать действия или реакции пока не приземлится.

# Синий

Отравленное существо громко кричит или издает громкие звуки в начале и конце своего хода.

# Фиолетовый

Отравленное существо испытывает отчаянную жажду покрыть себя жидкостью или грязью. Оно не может совершать реакции или как-то  двигаться, кроме как для того чтобы пытаться покрыть себя жидкостью или грязью.

# Красный

Отравленное существо должно использовать действие, чтобы съесть еду, если она есть в пределах досягаемости.

# Оранжевый

Отравленное существо боится своих союзников.

# Золотой

Отравленное существо Очаровано и может говорить на Грунгском.`,
    columnCount: 2,
    source: {
      id: SOURCE_VGTM,
      page: 145,
    },
  },
]
