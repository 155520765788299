const {ALPHABET_ESPRUAR} = require('./../../alphabetList')
const {LANG_ELVEN} = require('./../../languageIdList')
const {LANG_TYPE_STANDARD} = require('./../../languageTypeList')
const {PC_CLASS_BARD} = require('./../../pcClassIdList')
const {PC_RACE_ELF} = require('./../../pcRaceIdList')
const {
  SOURCE_BOOK_GREENWOOD_EIMD,
  SOURCE_DDHC_MORD_01,
  SOURCE_DND1_CotR,
  SOURCE_DND3_5_PHB,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: LANG_ELVEN,
  alphabetId: ALPHABET_ESPRUAR,
  type: LANG_TYPE_STANDARD,
  name: {
    nominative: 'Эльфийский',
    genitive: 'Эльфийского',
    instrumental: 'Эльфийским',
    prepositional: 'Эльфийском',
  },
  nameAlt: [
    'Эспруар',
    'Истинная Речь',
  ],
  nameEn: 'Elven',
  nameEnAlt: 'Espuar',
  dictionary: [
    {
      list: [
        ['алмазная роса', 'холимион'],
        ['звёздный цветок', 'амастасия'],
        ['золотой лепесток', 'ксилосент'],
        ['лунный ручей', 'сианодель'],
        ['лунный шёпот', 'галанодель'],
        ['ночной бриз', 'найло'],
        ['сверкающий бутон', 'ильфелкиир'],
        ['сверкающий цветок', 'амакиир'],
        ['серебряный лист', 'лиадон'],
      ],
      source: {
        id: SOURCE_PHB,
        page: 23,
      },
    },
    {
      list: [
        ['крепость-дом', 'эвереска'],
        ['лунные звёзды', 'Тель’Теукиира'],
        ['народ', 'Тель’Кессир'],
        ['не-народ', 'Н’Тель’Кесс'],
      ],
      source: [
        {
          id: SOURCE_DND1_CotR,
          page: 44,
        },
        {
          id: SOURCE_SCAG,
          page: 125,
        },
      ],
    },
    {
      list: [
        ['неугомонная молодёжь', 'ардаванши'],
      ],
      source: {
        id: SOURCE_BOOK_GREENWOOD_EIMD,
        page: 485,
      },
    },
    {
      list: [
        ['убийца теней', 'гва’терн фалн'], // gwa’thern faln
      ],
      source: {
        id: SOURCE_DDHC_MORD_01,
        page: 36,
      },
    },
  ],
  description: [
    {
      text: `Эльфийский язык текучий, с утончёнными интонациями и сложной грамматикой. Эльфийская литература богата и разнообразна, а стихи и песни известны среди представителей других рас. Многие [барды](PC_CLASS:${PC_CLASS_BARD}) учат эльфийский язык, чтоб добавить песни в свой репертуар.`,
      source: {
        id: SOURCE_PHB,
        page: 24,
      },
    },
    {
      text: `Эльфийский язык, распространённый на просторах Фаэруна — сами [эльфы](PC_RACE:${PC_RACE_ELF}) иногда называют его _Истинная Речь_ — использует для письма изящные литеры алфавита Эспруар.`,
      source: {
        id: SOURCE_SCAG,
        page: 105,
      },
    },
    {
      text: `Эльфы общаются на плавно текущем языке с утончённой интонацией и сложной грамматикой. Хотя эльфийская литература богата и разнообразна, устные поэмы и песни более известны. Множество бардов изучает эльфийский язык, чтобы можно было добавить в свой репертуар эльфийские баллады. Другие просто запоминают эльфийские песни на слух.`,
      source: {
        id: SOURCE_DND3_5_PHB,
        page: 18,
      },
    },
  ],
  typicalSpeakers: 'Эльфы',
  isRealLang: true,
  isReady: true,
}
