const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_ELEMENTAL_WATER} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_ELEMENTAL_WATER,
  nameEn: 'Water Elemental',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  hideFromFilter: true,
  name: {
    singular: {
      nominative: 'водяной элементаль',
      genitive: 'водяного элементаля',
      dative: 'водяному элементалю',
      accusative: 'водяного элементаля',
      instrumental: 'водяным элементалем',
      prepositional: 'водяном элементале',
    },
    plural: {
      nominative: 'водяные элементали',
      genitive: 'водяных элементалей',
      dative: 'водяным элементалям',
      accusative: 'водяных элементалей',
      instrumental: 'водяными элементалями',
      prepositional: 'водяных элементалях',
    },
  },
}
