const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SPELL_ANTIMAGIC_FIELD,
  SPELL_DISPEL_MAGIC,
} = require('./../../../../spellIdList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {animatedThingsDescriptionList} = require('./../../../../textCommonParts')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_FLYING_SWORD} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {GEAR_LONGSWORD} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Летающий меч',
  nameEn: 'Flying Sword',
  id: CREATURE_FLYING_SWORD,
  description: [
    `Летающий меч танцует в воздухе с уверенностью воина, которого нельзя ранить. Мечи — наиболее распространённое оружие, которое оживляют при помощи магии. Топоры, дубины, кинжалы, булавы, копья и даже самозарядные арбалеты тоже можно встретить в качестве оживлённого предмета.`,
    ...animatedThingsDescriptionList,
  ],
  note: {
    text: `Рядом с сундуком лежали кости капитана Скорнблейда, всё ещё сжимавшего свой ржавый меч. Представьте моё удивление, когда клинок сам по себе вылетел из его костяной руки! Шрам остался до сих пор`,
    author: `Левити Квикстич, плут полурослик`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 225,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 0,
    [SPEED_FLY]: {
      value: 50,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 11,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 5,
    [PARAM_CHA]: 1,
  },
  saveThrowList: [
    PARAM_DEX,
  ],
  immunityList: [
    DAMAGE_PSYCHIC,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_PETRIFIED,
    CONDITION_BLINDED,
    CONDITION_POISONED,
    CONDITION_CHARMED,
    CONDITION_PARALYZED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Восприимчивость к антимагии',
      description: `★СУЩЕСТВО★ ★недееспособен★, пока находится в пределах области [Преграды магии](SPELL:${SPELL_ANTIMAGIC_FIELD}). Став целью [Рассеивания магии](SPELL:${SPELL_DISPEL_MAGIC}), ★СУЩЕСТВО★ ★должен★ пройти испытание Телосложения СЛ испытания заклинателя, иначе потеряет сознание на 1 минуту.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного меча`,
    },
  ],
  actionList: [
    {
      gearId: GEAR_LONGSWORD,
    },
  ],
  genderId: GENDER_MALE,
}
