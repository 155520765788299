const upLetter = require('./../../../../../../utils/upLetter')
const {aligmentCollection} = require('./../../../../../aligmentList')
const {GEAR_LONGSWORD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {MAGIC_ITEM_SWORD_OF_ANSWERING} = require('./../../../../../magicItemIdList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

const tableText = list.reduce(
  (text, {name, gemName, alignmentId, id}) => {
    const { nominative: alignment } = aligmentCollection[alignmentId].name[GENDER_MALE]

    return `${text}
| [${name}](MAGIC_ITEM:${id}) | ${upLetter(alignment)} | ${gemName} |`
  },
  `
| Название | Мировоззрение | Камень |
|----------|---------------|--------|`
)

module.exports = {
  id: MAGIC_ITEM_SWORD_OF_ANSWERING,
  name: `Меч ответа`,
  nameEn: `Sword of Answering`,
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_LONGSWORD,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `существом с тем же мировоззрение, что у меча`,
  description: `_В мире Серого Ястреба известны девять таких мечей. Все они изготовлены по образу легендарного меча, Фрагараха, чьё название часто переводят как «Последнее слово». У каждого из девяти мечей есть своё название и своё мировоззрение, и у всех у них в навершии использованы разные драгоценные камни._

${tableText}

Вы получаете бонус +3 к броскам атаки и урона, совершаемым этим мечом. Кроме того, пока Вы держите этот меч, Вы можете реакцией совершать им одну рукопашную атаку по существу, находящемуся в пределах Вашей досягаемости, которое причиняет Вам урон. Вы совершаете этот бросок атаки с преимуществом, и урон, причиняемый этой особой атакой, игнорирует все сопротивления к урону и иммунитеты, которые могут быть у цели.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 185,
  },
}
