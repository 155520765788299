const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ancientElementalDescriptionList} = require('./../../../../textCommonParts')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_22} = require('./../../../../crList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {CREATURE_ZARATAN} = require('./../../../../creatureIdList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Заратан',
  nameEn: 'Zaratan',
  id: CREATURE_ZARATAN,
  description: [
    `Когда Заратан призывается со Стихийного плана Земли, из-под земли возвышается силуэт, подобный громадной, броненосной рептилии, на чьём панцире образуется островок из того места, откуда оно появилось. Когда Заратан перемещается по суше, от каждого его шага по земле исходят ударные волны, способные разрушать сооружения. Заратан, будучи глупым существом, покачивается вперёд, выражая свою ярость через свои рычания и периодически изрыгая камни или прочие объедки из своей огромной пасти. Если он будет серьёзно ранен, Заратан медленно втягивает свои конечности и голову, и получает укрытие в своём непроницаемом панцире, выжидая подходящего момента, чтобы вылезти из укрытия, пока не залечатся его раны.`,
    ...ancientElementalDescriptionList,
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MTOF,
    page: 160,
  },
  armor: {
    ac: 21,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 30,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 30,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 21,
    [PARAM_CHA]: 18,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  vulnerabilityList: [
    DAMAGE_THUNDER,
  ],
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  cr: CR_22,
  featureList: [
    {
      name: 'Сотрясающее движение',
      description: `Бонусным действием после передвижение на как минимум 10 футов по земле Заратан может послать ударную волну по земле на 120 футов вокруг себя. Зона становится труднопроходимой местностью на 1 минуту. Каждое существо, находящееся на земле должно пройти испытание Телосложения СЛ 25 или потерять концентрацию.

Ударная волна наносит 100 урона звуком всем строениями, находящиеся в контакте с землёй и в зоне поражения. Если существо находится рядом с обрушивающимся сооружением, оно может быть погребено под обломками; существо, находящееся от здания на расстоянии, равном менее половины его высоты должно пройти испытание Ловкости СЛ 25. При провале цель получает 17 (5к6) дробящего урона, лежит ничком, и завалена обломками. Заваленное существо является обездвиженым, и может действием выбраться, преуспев в проверке Силы (Атлетика) СЛ 20. Другое существо в пределах 5 футов от погребённого существа может действием расчистить обломки, давая погребённому существу преимущество. Если три существа тратят действия на расчистку завала, проверка преуспевает автоматически. При успехе существо получает половину урона, не падает ничком и не заваливается обломками.`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_WEAPONS,
    {
      name: 'Осадное чудовище',
      description: `Элементаль наносит двойное повреждение объектам и строениям (включая _Сотрясающее движение_).`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Заратан совершает две атаки: одну _Укусом_ и одну _Топотом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 20,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 4,
          diceType: 8,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Топот',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 20,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Плевок камнем',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 17,
        range: {
          normal: 120,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 6,
          diceType: 8,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Брызги земли',
      restore: 5,
      description: `Заратан выплёвывает каменные в область куба с гранью 90 футов. Каждое существо в области должно пройти испытание Ловкости Сл. 25. Существа получают 33 (6к10) дробящего урона при провале, или половину этого урона при успехе. Существо провалившее бросок на 5 или больше падает ничком.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Топот',
      description: `Заратан делает одну атаку _Топотом_.`,
    },
    {
      name: 'Движение',
      description: `Заратан перемещается на расстояние, не превышающее его скорость`,
    },
    {
      name: 'Плевок',
      cost: 2,
      description: `Заратан использует _Плевок камнем_.`,
    },
    {
      name: 'Втягивание',
      cost: 2,
      description: `Заратан прячется в свой панцирь. Пока он не использует _Появление_, он получает сопротивление всему урону и обездвиживается. Следующим его легендарным действием должно быть _Исцеление_ или _Появление_.`,
    },
    {
      name: 'Исцеление',
      cost: 2,
      description: `Это действие можно делать только если Заратан скрылся в панцире. Он восстанавливает 52 (5к20) хита. В следующий раз, когда он будет использовать легендарное действие, этим действием должно быть _Появление_.`,
    },
    {
      name: 'Появление',
      cost: 2,
      description: `Заратан вылезает из своего панциря и использует _Плевок камнем_. Это действие можно сделать только если он скрывался в своём панцире.`,
    },
  ],
}
