const arrify = require('arrify')

const prepareForSearch = require('./../../utils/prepareForSearch')
const listToCollectionById = require('./../../utils/listToCollectionById')
const sortByName = require('./../../utils/sortByName')
const reduceSourceList = require('./../../utils/reduceSourceList')
const reduceSpellIdList = require('./../../utils/reduceSpellIdList')
const checkIsFromUA = require('./../../utils/checkIsFromUA')
const DescriptionList = require('./../../constructors/DescriptionList')

const SEARCH_PROP_NAME = require('./../SEARCH_PROP_NAME')
const {GENDER_MALE}  = require('./../genderList')

const magicItemRawList = require('./magicItemRawList')

const magicItemList = magicItemRawList
  .map(
    item => {
      const description = new DescriptionList(item)

      return {
        ...item,
        description,
        hasCurse: Boolean(item.curse),
        genderId: item.genderId || GENDER_MALE,
        isSentient: Boolean(item.isSentient),
        isSymbiotic: Boolean(item.symbiotic),
        isTemplate: Boolean(item.childIdList),
        isChild: Boolean(item.siblingIdList),
        isConsumable: Boolean(item.isConsumable),
        isUA: checkIsFromUA(item.source),
        attunement: Boolean(item.attunement),
        sourceIdList: arrify(item.source).map(({id}) => id),
        [SEARCH_PROP_NAME]: prepareForSearch(
          [
            item.name,
            item.nameAlt || '',
            item.nameEn,
            description,
          ]
            .filter(e => e)
            .map(
              e => String(e).toLowerCase(),
            )
            .join('\n'),
        ),
      }
    },
  )
  .sort(sortByName)

module.exports = magicItemList

module.exports.magicItemCollection = listToCollectionById(magicItemList)
module.exports.magicItemSourceIdList = magicItemList.reduce(reduceSourceList, [])
module.exports.magicItemSpellIdList = magicItemList.reduce(reduceSpellIdList, [])

/*

Таблица А — A — Незначительные предметы (встречаются в 4 раза чаще)
Таблица Б — B — Незначительные предметы (встречаются в 4 раза чаще)
Таблица В — C — Незначительные предметы (встречаются в 4 раза чаще)
Таблица Г — D — Незначительные предметы (встречаются в 4 раза чаще)
Таблица Д — E — Незначительные предметы (встречаются в 4 раза чаще)
Таблица Е — F — Значимые предметы
Таблица Ё — G — Значимые предметы
Таблица Ж — H — Значимые предметы
Таблица З — I — Значимые предметы
*/
