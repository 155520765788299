const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_BLADE_WARD,
  SPELL_HUNTERS_MARK,
  SPELL_JUMP,
  SPELL_TRUE_STRIKE,
} = require('./../../../../spellIdList')
const {
  CREATURE_TYPE_DUERGAR,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_DWARVISH,
  LANG_UNDERCOMMON,
} = require('./../../../../languageIdList')
const {
  duergarDescription,
  duergarNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DUERGAR_RESILIENCE,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  DAMAGE_FORCE,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_1} = require('./../../../../crList')
const {CREATURE_DUERGAR_SOULBLADE} = require('./../../../../creatureIdList')
const {GEAR_LEATHER_ARMOR} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Двергар клинок души',
  nameAlt: 'Дуэргар клинок души',
  nameEn: 'Duergar Soulblade',
  id: CREATURE_DUERGAR_SOULBLADE,
  description: [
    `Клинки душ — двергарские воины, чьё псионическое мастерство позволяет им проявлять вокруг своих клинков поле псионической энергии, способное расчленять своих противников.`,
    ...duergarDescription,
  ],
  note: duergarNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_DWARF,
    CREATURE_TYPE_DUERGAR,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 172,
  },
  armor: {
    ac: 14,
    gearId: GEAR_LEATHER_ARMOR,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 10,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 12,
  },
  resistanceList: [
    DAMAGE_POISON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DWARVISH,
    LANG_UNDERCOMMON,
  ],
  cr: CR_1,
  featureList: [
    ABILITY_DUERGAR_RESILIENCE,
    {
      name: 'Создать клинок души',
      description: `Бонусным действием двергар может создать клинок видимой псионической энергии размером с короткий меч. Оружие появляется в руке двергара и исчезает, если покидает её, двергар умирает или становится недееспособным.`,
    },
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_BLADE_WARD,
          SPELL_TRUE_STRIKE,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_JUMP,
          SPELL_HUNTERS_MARK,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Клинок души',
      description: `Если двергар атакует с преимуществом, то атака причиняет дополнительно 3 (1к6) урона силовым полем.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_FORCE,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Клинок души',
      comment: `в увеличенном состоянии`,
      description: `Если двергар атакует с преимуществом, то атака причиняет дополнительно 3 (1к6) урона силовым полем.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_FORCE,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Увеличение',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `На 1 минуту двергар магическим образом увеличивается в размере вместе со всем, что несёт и носит. В увеличенном состоянии двергар Большого размера, удваивает кости урона в атаках, основанных на Силе (уже учтено), и совершает с преимуществом проверки Силы. Если двергару не хватает места, чтобы стать Большим, он приобретает максимальный размер, допустимый имеющимся пространством.`,
    },
    {
      name: 'Невидимость',
      comment: `перезарядка после короткого или длинного отдыха`,
      description: `Двергар магическим образом становится невидимым, и этот эффект будет продолжаться до 1 часа, или же до того момента, когда он атакует, прочитает заклинание, использует _Увеличение_ или потеряет концентрацию (как если бы он концентрировался на заклинании). Всё, что он несёт и носит, становится невидимым вместе с ним.`,
    },
  ],
}
