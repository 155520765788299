const {CREATURE_TROGLODYTE} = require('./../../creatureIdList')
const {GOD_LAOGZED} = require('./../../godIdList')
const {SOURCE_MM} = require('./../../sourceList')

module.exports = {
  header: 'Последователи Лаогзеда',
  text: `Некоторые [троглодиты](GOD:${CREATURE_TROGLODYTE}) поклоняются [Лаогзеду](GOD:${GOD_LAOGZED}), демоническому ужасно жирному жабоящеру, который спит в Бездне. В ответ [Лаогзед](GOD:${GOD_LAOGZED}) не предоставляет троглодитам ничего кроме примера для подражания, ведь каждый из них мечтает стать таким же толстым, откормленным и праздным, каким кажется он.`,
  source: {
    id: SOURCE_MM,
    page: 285,
  },
}
