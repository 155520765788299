const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ATHLETICS,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
    SPEED_FLY,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_MAGIC_RESISTANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_ZORBO,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Зорбо',
  nameEn: 'Zorbo',
  id: CREATURE_ZORBO,
  description: `Зорбо — свирепые, всеядные, похожие по размеру и внешнему виду на коалу.

Живя в деревьях и пещерах, у него длинные когти, суровый нрав и любовь к гуманоидной плоти. Зорбо изменяет свою природный доспех, чтобы соответствовать своему окружению, и он может ослаблять и уничтожить доспехи, щиты и защитные магические предметы своими острыми когтями.`,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 228,
  },
  armor: {
    ac: 10,
    type: ARMOR_TYPE_NATURAL,
    comment: '(смотри особенность «Природный доспех»)',
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 13,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 7,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
  },
  cr: CR_1_2,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Природный доспех',
      description: `Зорбо магическим образом поглощает природную силу своего окружения, корректируя свой КД, основываясь на материале, на котором он стоит или взбирается:

* КД 15 для дерева или кости,
* КД 17 для земли или камня
* КД 19 для металла.

Если зорбо не контактирует ни с одним из этих веществ, его КД составляет 10.`,
    },
  ],
  actionList: [
    {
      name: 'Разрушительные когти',
      description: `Если цель — существо в доспехах, носяшее щит или обладающее магическим предметом, улучшающим его КД, оно должно пройти испытание Ловкости СЛ 11.

При провале один из таких предметов, которым владеет или держит в руках существо (по выбору цели), ухудшается магическим образом, получая постоянный кумулятивный штраф −1 к КД, а зорбо получает +1 бонус к КД до начала его следующего хода. Доспех, КД которого уменьшился до 10, щит или волшебный предмет, бонус КД, который падает до 0, разрушается.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
  ],
}
