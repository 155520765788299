const listToCollectionById = require('./../utils/listToCollectionById')

const SENSE_PASSIVE_PERCEPTION = 'passive_perception'
const SENSE_BLIND_VISION = 'blind_vision'
const SENSE_DARK_VISION = 'dark_vision'
const SENSE_TRUE_VISION = 'true_vision'
const SENSE_VIBRATION_SENSE = 'vibration_sense'

const senseList = [
  {
    id: SENSE_PASSIVE_PERCEPTION,
    name: 'пассивная внимательность',
    description: `Пассивная проверка — это особая проверка характеристики, в которой кости не кидаются. Такая проверка может отражать средний результат для задачи, выполняющейся с регулярной периодичностью, например, поиски потайных дверей. Мастер может использовать её для тайного определения без бросков костей того, преуспеют ли персонажи в чём-либо, например, заметят ли персонажи скрывшееся чудовище.

Результат пассивной проверки персонажа определяется так: «10 + все модификаторы, обычно применимые к проверке». Если персонаж совершает проверку с преимуществом, добавьте 5. В случае помехи вычтите 5. Результат пассивной проверки в игре называется значением.

Например, если у персонажа 1 уровня Мудрость 15, и он владеет Внимательностью, его пассивное значение Мудрости (Внимательность) равно 14

Если вы прячетесь, есть шанс, что вас заметят даже без активных поисков. Для определения того, заметило ли вас существо, Мастер сравнивает результат вашей проверки Ловкости (Скрытность) с пассивным значением Мудрости (Внимательность), которое равно 10 + модификатор Мудрости существа, плюс все уместные бонусы и штрафы. Если у существа есть преимущество, добавьте 5. Если есть помеха, вычтите 5.

Например, если персонаж 1 уровня (с бонусом мастерства +2) обладает Мудростью 15 (модификатор +2) и владеет Внимательностью, его пассивное значение Мудрости (Внимательность) равно 14.`,
  },
  {
    id: SENSE_BLIND_VISION,
    name: 'слепое зрение',
    description: `Чудовище со слепым зрением воспринимает окружение в определённом радиусе, не полагаясь на зрение. Как правило, это особое чувство есть у существ без глаз, таких как гримлоки и серая слизь, а также у существ с эхолокацией или обострённым восприятием, таких как летучие мыши и истинные драконы. Если чудовище слепо от природы, это указывается в скобках наряду с радиусом, за пределами которого существо слепо.`,
  },
  {
    id: SENSE_DARK_VISION,
    name: 'тёмное зрение',
    description: `Чудовище с тёмным зрением может видеть в темноте в определённом радиусе. Чудовище может видеть при тусклом освещении в этом радиусе, как если бы это было нормальное освещение, и в темноте, как при тусклом освещении. Чудовище не может различать цвета в темноте, только оттенки серого. Многие существа, живущие под землёй, обладают этим чувством.`,
  },
  {
    id: SENSE_TRUE_VISION,
    name: 'истинное зрение',
    description: `Чудовище с истинным зрением в определённом радиусе видит в обычной и магической тьме, видит невидимых существ, автоматически распознаёт визуальные иллюзии и преуспевает в испытаниях от них, и видит истинную форму перевёртышей и существ, превращённых магией. Кроме того, зрение этого чудовища простирается на Эфирный План в том же радиусе.`,
  },
  {
    id: SENSE_VIBRATION_SENSE,
    name: 'чувство вибрации',
    description: `Чудовища с чувством вибрации могут обнаруживать и определять источник колебаний в пределах определённого радиуса при условии, что чудовище и источник колебаний находятся в контакте с одной и той же поверхностью или веществом. Чувство вибрации не может быть использовано для обнаружения летающих или бесплотных существ. Многие роющие существа, такие как анхеги или бурые увальни, обладают этим чувством.`,
  },
]

module.exports = senseList

module.exports.senseCollection = listToCollectionById(senseList)

module.exports.SENSE_PASSIVE_PERCEPTION = SENSE_PASSIVE_PERCEPTION
module.exports.SENSE_BLIND_VISION = SENSE_BLIND_VISION
module.exports.SENSE_DARK_VISION = SENSE_DARK_VISION
module.exports.SENSE_TRUE_VISION = SENSE_TRUE_VISION
module.exports.SENSE_VIBRATION_SENSE = SENSE_VIBRATION_SENSE
