const {
  SOURCE_MM,
  SOURCE_ERRATA_MONSTER_MANUAL,
} = require('./../../../../sourceList')
const {
  CR_17,
} = require('./../../../../crList')
const {
  SIZE_GARGANTUA,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_DRAGON,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_AQUAN,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  GENDER_FEMALE,
} = require('./../../../../genderList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  ABILITY_AMPHIBIOUS,
} = require('./../../../../creatureAbilityList')
const {
  dragonTurtlesNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_DRAGON_TURTLE,
  CREATURE_MARID,
  CREATURE_SAHUAGIN,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дракочерепаха',
  nameEn: 'Dragon Turtle',
  id: CREATURE_DRAGON_TURTLE,
  description: [
    {
      header: 'Дракочерепаха',
      text: `Дракочерепахи являются одними из самых страшных существ в океане. Настолько же большие и прожорливые, как и их наземные родичи драконы, дракочерепахи сокрушают своими смертельными челюстями, паровым дыханием и сокрушительным хвостом.

Грубый панцирь дракочерепахи имеет такой же тёмно-зелёный цвет, как и глубокая вода, в которой обитает это чудовище. Серебряные прожилки, подчёркивающие панцирь, напоминают танец света на открытой водной глади, а всплывшую дракочерепаху иногда принимают за отражение солнца или луны на волнах.`,
      source: {
        id: SOURCE_MM,
        page: 136,
      },
    },
    {
      header: 'Драконы глубин',
      text: `Как и истинные драконы, дракочерепахи собирают сокровища, сначала топя корабли, и затем просеивая обломки в поисках монет и других драгоценных предметов. Дракочерепахи заглатывают сокровища для транспортировки, а затем срыгивают их, когда добираются до логова.

Дракочерепахи обитают в пещерах, скрытых в коралловых рифах или под морским дном, либо вдоль скалистых участков береговой линии. Если приглянувшаяся пещера уже заселена, дракочерепаха нападает на её нынешних обитателей в попытке захватить пещеру.`,
      source: {
        id: SOURCE_MM,
        page: 136,
      },
    },
    {
      header: 'Наёмные чудовища',
      text: `Дракочерепахи достаточно умны, чтобы их можно было подкупить, и пираты, ходящие под парусом по морям, патрулируемым этими существами, быстро учатся предлагать им сокровища в обмен на безопасный проход. Умные [сахуагины](CREATURE:${CREATURE_SAHUAGIN}) иногда объединяются с дракочерепахами, соблазняя их сокровищами, чтобы использовать их обжигающее дыхание в качестве оружия в набегах [сахуагинов](CREATURE:${CREATURE_SAHUAGIN}) на корабли и прибрежные поселения.`,
      source: {
        id: SOURCE_MM,
        page: 136,
      },
    },
    {
      header: 'Стихийная мощь',
      text: `Дракочерепахи иногда проходят через подводные разрывы в планах на Стихийный План Воды. Эти чудовищные экземпляры можно найти на службе [маридов](CREATURE:${CREATURE_MARID}), которые крепят на спинах дракочерепах величественные коралловые троны и ездят на них, как на ездовых животных.`,
      source: {
        id: SOURCE_MM,
        page: 136,
      },
    },
  ],
  note: dragonTurtlesNote,
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_DRAGON,
  ],
  alignmentId: ALIGNMENT_NO,
  source: [
    {
      id: SOURCE_MM,
      page: 136,
    },
    {
      id: SOURCE_ERRATA_MONSTER_MANUAL,
      page: 1,
    },
  ],
  armor: {
    ac: 20,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 22,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_SWIM]: 40,
  },
  params: {
    [PARAM_STR]: 25,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 20,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  resistanceList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_AQUAN,
  ],
  cr: CR_17,
  featureList: [
    ABILITY_AMPHIBIOUS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну _Укусом_, и две _Когтями_. ★Он★ может совершить одну атаку _Хвостом_ вместо двух атак _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 15,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 12,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Хвост',
      description: `Если цель — существо, она должна пройти испытание Силы СЛ 20, иначе будет оттолкнута на 10 футов от ★СУЩЕСТВО★ и _Сбита с ног_.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 13,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 12,
          diceBonus: 7,
        },
      },
    },
    {
      name: 'Паровое дыхание',
      description: `★СУЩЕСТВО★ выдыхает обжигающий пар 60-футовым конусом. Все существа в этой области должны пройти испытание Телосложения СЛ 18, получая урон огнём 52 (15к6) при провале, или половину этого урона при успехе. Нахождение под водой не даёт сопротивления к этому урону.`,
      restore: 5,
    },
  ],
  genderId: GENDER_FEMALE,
}
