const {
  SOURCE_DMG,
  SOURCE_GAME_BG_3,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  GEAR_BACKPACK,
  GEAR_BATTLEAXE,
  GEAR_BOOK,
  GEAR_CANDLE,
  GEAR_CHAIN_MAIL,
  GEAR_CHAIN_SHIRT,
  GEAR_CLOTHES_COMMON,
  GEAR_DAGGER,
  GEAR_JAVELIN,
  GEAR_LADDER,
  GEAR_LANTERN_BULLSEYE,
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_MACE,
  GEAR_MANACLES,
  GEAR_MAUL,
  GEAR_MILITARY_SADDLE,
  GEAR_MIRROR_STEEL,
  GEAR_OIL,
  GEAR_PLATE_ARMOR,
  GEAR_PLAYING_CARD_SET,
  GEAR_POLE,
  GEAR_QUARTERSTAFF,
  GEAR_QUIVER,
  GEAR_RAM_PORTABLE,
  GEAR_ROD,
  GEAR_ROPE_HEMP,
  GEAR_ROPE_SILK,
  GEAR_ROWBOAT,
  GEAR_SACK,
  GEAR_SADDLEBAGS,
  GEAR_SCIMITAR,
  GEAR_SHIELD,
  GEAR_SHORTBOW,
  GEAR_SPEAR,
  GEAR_SPELLBOOK,
  GEAR_STAFF,
  GEAR_STUDDED_LEATHER_ARMOR,
  GEAR_TRIDENT,
  GEAR_WAND,
  GEAR_WARHAMMER,
} = require('./../../../../../gearIdList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_ARCANE_LOCK,
  SPELL_AWAKEN,
  SPELL_BANISHMENT,
  SPELL_BARKSKIN,
  SPELL_BLESS,
  SPELL_BRANDING_SMITE,
  SPELL_BURNING_HANDS,
  SPELL_CHARM_PERSON,
  SPELL_CLAIRVOYANCE,
  SPELL_CLOUDKILL,
  SPELL_COMMAND,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_CONE_OF_COLD,
  SPELL_CONJURE_ELEMENTAL,
  SPELL_CURE_WOUNDS,
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_DAYLIGHT,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DETECT_THOUGHTS,
  SPELL_DIMENSION_DOOR,
  SPELL_DISGUISE_SELF,
  SPELL_DISINTEGRATE,
  SPELL_DISPEL_MAGIC,
  SPELL_DOMINATE_BEAST,
  SPELL_ENLARGE_REDUCE,
  SPELL_ETHEREALNESS,
  SPELL_FAERIE_FIRE,
  SPELL_FEAR,
  SPELL_FIREBALL,
  SPELL_FLAMING_SPHERE,
  SPELL_FOG_CLOUD,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GASEOUS_FORM,
  SPELL_GATE,
  SPELL_GIANT_INSECT,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_GREASE,
  SPELL_GREATER_RESTORATION,
  SPELL_GUST_OF_WIND,
  SPELL_HASTE,
  SPELL_HEAL,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_ICE_STORM,
  SPELL_IDENTIFY,
  SPELL_INSECT_PLAGUE,
  SPELL_INVISIBILITY,
  SPELL_JUMP,
  SPELL_KNOCK,
  SPELL_LESSER_RESTORATION,
  SPELL_LEVITATE,
  SPELL_LIGHT,
  SPELL_LIGHTNING_BOLT,
  SPELL_LOCATE_ANIMALS_OR_PLANTS,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_MISSILE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_PASSWALL,
  SPELL_PLANAR_ALLY,
  SPELL_PLANE_SHIFT,
  SPELL_POLYMORPH,
  SPELL_PRISMATIC_SPRAY,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_REMOVE_CURSE,
  SPELL_RESURRECTION,
  SPELL_SCORCHING_RAY,
  SPELL_SEE_INVISIBILITY,
  SPELL_SENDING,
  SPELL_SLOW,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_SPEAK_WITH_PLANTS,
  SPELL_STINKING_CLOUD,
  SPELL_SUGGESTION,
  SPELL_TELEKINESIS,
  SPELL_TELEPORT,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_FORCE,
  SPELL_WALL_OF_ICE,
  SPELL_WALL_OF_THORNS,
  SPELL_WEB,
  SPELL_WIND_WALK,
  SPELL_WISH,
} = require('./../../../../../spellIdList')
const {
  CREATURE_ACOLYTE,
  CREATURE_AIR_ELEMENTAL,
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_APPRENTICE_WIZARD,
  CREATURE_ARCANALOTH,
  CREATURE_ARCHMAGE,
  CREATURE_ASSASSIN,
  CREATURE_AVATAR_OF_DEATH,
  CREATURE_BANDIT,
  CREATURE_BANDIT_CAPTAIN,
  CREATURE_BAT,
  CREATURE_BEHOLDER,
  CREATURE_BERSERKER,
  CREATURE_BUGBEAR,
  CREATURE_BULETTE,
  CREATURE_CAMBION,
  CREATURE_CLOUD_GIANT,
  CREATURE_DAO,
  CREATURE_DEVA,
  CREATURE_DJINNI,
  CREATURE_DRAGON_TURTLE,
  CREATURE_DRUID,
  CREATURE_EARTH_ELEMENTAL,
  CREATURE_EFREETI,
  CREATURE_ELEPHANT,
  CREATURE_ERINYES,
  CREATURE_ETTIN,
  CREATURE_FIRE_ELEMENTAL,
  CREATURE_FIRE_GIANT,
  CREATURE_FROST_GIANT,
  CREATURE_GIANT_CONSTRICTOR_SNAKE,
  CREATURE_GIANT_RAT,
  CREATURE_GITHYANKI_KNIGHT,
  CREATURE_GITHZERAI_ZERTH,
  CREATURE_GNOLL,
  CREATURE_GOBLIN,
  CREATURE_GUARD,
  CREATURE_HILL_GIANT,
  CREATURE_HOBGOBLIN,
  CREATURE_INCUBUS,
  CREATURE_INVISIBLE_STALKER,
  CREATURE_IRON_GOLEM,
  CREATURE_KNIGHT,
  CREATURE_KOBOLD,
  CREATURE_LICH,
  CREATURE_MARID,
  CREATURE_MASTIFF,
  CREATURE_MEDUSA,
  CREATURE_MEZZOLOTH,
  CREATURE_MUMMY_LORD,
  CREATURE_NIGHT_HAG,
  CREATURE_NYCALOTH,
  CREATURE_OGRE,
  CREATURE_ONI,
  CREATURE_ORC,
  CREATURE_PLANETAR,
  CREATURE_PRIEST,
  CREATURE_RAT,
  CREATURE_RHINOCEROS,
  CREATURE_RIDING_HORSE,
  CREATURE_SALAMANDER,
  CREATURE_SHRIEKER,
  CREATURE_SOLAR,
  CREATURE_SUCCUBUS,
  CREATURE_SWARM_OF_RATS,
  CREATURE_TREANT,
  CREATURE_TROLL,
  CREATURE_ULTROLOTH,
  CREATURE_VETERAN,
  CREATURE_WATER_ELEMENTAL,
  CREATURE_WYVERN,
  CREATURE_XORN,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_ARMOR,
  MGC_TYPE_POTION,
  MGC_TYPE_RING,
  MGC_TYPE_ROD,
  MGC_TYPE_STAFF,
  MGC_TYPE_WAND,
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_COMMON,
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
} = require('./../../../../../pcClassIdList')
const {
  FEATURE_DIVINE_INTERVENTION,
} = require('./../../../../../featureIdList')
const {
  MAGIC_ITEM_AMULET_OF_HEALTH,
  MAGIC_ITEM_AMULET_OF_PROOF_AGAINST_DETECTION_AND_LOCATION,
  MAGIC_ITEM_AMULET_OF_THE_PLANES,
  MAGIC_ITEM_ANIMATED_SHIELD,
  MAGIC_ITEM_APPARATUS_OF_KWALISH,
  MAGIC_ITEM_ARMOR_OF_INVULNERABILITY,
  MAGIC_ITEM_ARMOR_OF_VULNERABILITY,
  MAGIC_ITEM_ARROW_CATCHING_SHIELD,
  MAGIC_ITEM_BAG_OF_BEANS,
  MAGIC_ITEM_BAG_OF_DEVOURING,
  MAGIC_ITEM_BAG_OF_HOLDING,
  MAGIC_ITEM_BEAD_OF_FORCE,
  MAGIC_ITEM_BELT_OF_DWARVENKIND,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH,
  MAGIC_ITEM_BERSERKER_AXE,
  MAGIC_ITEM_BOOTS_OF_ELVENKIND,
  MAGIC_ITEM_BOOTS_OF_LEVITATION,
  MAGIC_ITEM_BOOTS_OF_SPEED,
  MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING,
  MAGIC_ITEM_BOOTS_OF_THE_WINTERLANDS,
  MAGIC_ITEM_BOWL_OF_COMMANDING_WATER_ELEMENTALS,
  MAGIC_ITEM_BRACERS_OF_ARCHERY,
  MAGIC_ITEM_BRACERS_OF_DEFENSE,
  MAGIC_ITEM_BRAZIER_OF_COMMANDING_FIRE_ELEMENTALS,
  MAGIC_ITEM_BROOCH_OF_SHIELDING,
  MAGIC_ITEM_BROOM_OF_FLYING,
  MAGIC_ITEM_CANDLE_OF_INVOCATION,
  MAGIC_ITEM_CAP_OF_WATER_BREATHING,
  MAGIC_ITEM_CAPE_OF_THE_MOUNTEBANK,
  MAGIC_ITEM_CARPET_OF_FLYING,
  MAGIC_ITEM_CENSER_OF_CONTROLLING_AIR_ELEMENTALS,
  MAGIC_ITEM_CHIME_OF_OPENING,
  MAGIC_ITEM_CIRCLET_OF_BLASTING,
  MAGIC_ITEM_CLOAK_OF_ARACHNIDA,
  MAGIC_ITEM_CLOAK_OF_DISPLACEMENT,
  MAGIC_ITEM_CLOAK_OF_ELVENKIND,
  MAGIC_ITEM_CLOAK_OF_INVISIBILITY,
  MAGIC_ITEM_CLOAK_OF_PROTECTION,
  MAGIC_ITEM_CLOAK_OF_THE_BAT,
  MAGIC_ITEM_CLOAK_OF_THE_MANTA_RAY,
  MAGIC_ITEM_CUBE_OF_FORCE,
  MAGIC_ITEM_CUBIC_GATE,
  MAGIC_ITEM_DAERNS_INSTANT_FORTRESS,
  MAGIC_ITEM_DAGGER_OF_VENOM,
  MAGIC_ITEM_DANCING_SWORD,
  MAGIC_ITEM_DECANTER_OF_ENDLESS_WATER,
  MAGIC_ITEM_DECK_OF_ILLUSIONS,
  MAGIC_ITEM_DECK_OF_MANY_THINGS,
  MAGIC_ITEM_DEFENDER,
  MAGIC_ITEM_DEMON_ARMOR,
  MAGIC_ITEM_DIMENSIONAL_SHACKLES,
  MAGIC_ITEM_DRAGON_SLAYER,
  MAGIC_ITEM_DRIFTGLOBE,
  MAGIC_ITEM_DUST_OF_DISAPPEARANCE,
  MAGIC_ITEM_DUST_OF_DRYNESS,
  MAGIC_ITEM_DUST_OF_SNEEZING_AND_CHOKING,
  MAGIC_ITEM_DWARVEN_PLATE,
  MAGIC_ITEM_DWARVEN_THROWER,
  MAGIC_ITEM_EFREETI_BOTTLE,
  MAGIC_ITEM_EFREETI_CHAIN,
  MAGIC_ITEM_ELIXIR_OF_HEALTH,
  MAGIC_ITEM_ELVEN_CHAIN,
  MAGIC_ITEM_EVERSMOKING_BOTTLE,
  MAGIC_ITEM_EYES_OF_CHARMING,
  MAGIC_ITEM_EYES_OF_MINUTE_SEEING,
  MAGIC_ITEM_EYES_OF_THE_EAGLE,
  MAGIC_ITEM_FLAME_TONGUE,
  MAGIC_ITEM_FOLDING_BOAT,
  MAGIC_ITEM_FROST_BRAND,
  MAGIC_ITEM_GAUNTLETS_OF_OGRE_POWER,
  MAGIC_ITEM_GEM_OF_BRIGHTNESS,
  MAGIC_ITEM_GEM_OF_SEEING,
  MAGIC_ITEM_GIANT_SLAYER,
  MAGIC_ITEM_GLAMOURED_STUDDED_LEATHER,
  MAGIC_ITEM_GLOVES_OF_MISSILE_SNARING,
  MAGIC_ITEM_GLOVES_OF_SWIMMING_AND_CLIMBING,
  MAGIC_ITEM_GLOVES_OF_THIEVERY,
  MAGIC_ITEM_GOGGLES_OF_NIGHT,
  MAGIC_ITEM_HAMMER_OF_THUNDERBOLTS,
  MAGIC_ITEM_HAT_OF_DISGUISE,
  MAGIC_ITEM_HEADBAND_OF_INTELLECT,
  MAGIC_ITEM_HELM_OF_BRILLIANCE,
  MAGIC_ITEM_HELM_OF_COMPREHENDING_LANGUAGES,
  MAGIC_ITEM_HELM_OF_TELEPATHY,
  MAGIC_ITEM_HELM_OF_TELEPORTATION,
  MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK,
  MAGIC_ITEM_HORN_OF_BLASTING,
  MAGIC_ITEM_HORSESHOES_OF_A_ZEPHYR,
  MAGIC_ITEM_HORSESHOES_OF_SPEED,
  MAGIC_ITEM_IMMOVABLE_ROD,
  MAGIC_ITEM_IRON_BANDS_OF_BILARRO,
  MAGIC_ITEM_IRON_FLASK,
  MAGIC_ITEM_JAVELIN_OF_LIGHTNING,
  MAGIC_ITEM_KEOGHTOM_S_OINTMENT,
  MAGIC_ITEM_LANTERN_OF_REVEALING,
  MAGIC_ITEM_LUCK_BLADE,
  MAGIC_ITEM_MANTLE_OF_SPELL_RESISTANCE,
  MAGIC_ITEM_MANUAL_OF_BODILY_HEALTH,
  MAGIC_ITEM_MANUAL_OF_GAINFUL_EXERCISE,
  MAGIC_ITEM_MANUAL_OF_QUICKNESS_OF_ACTION,
  MAGIC_ITEM_MEDALLION_OF_THOUGHTS,
  MAGIC_ITEM_MIRROR_OF_LIFE_TRAPPING,
  MAGIC_ITEM_NECKLACE_OF_ADAPTATION,
  MAGIC_ITEM_NECKLACE_OF_FIREBALLS,
  MAGIC_ITEM_NECKLACE_OF_PRAYER_BEADS,
  MAGIC_ITEM_NINE_LIVES_STEALER,
  MAGIC_ITEM_NOLZURS_MARVELOUS_PIGMENTS,
  MAGIC_ITEM_OATHBOW,
  MAGIC_ITEM_OIL_OF_ETHEREALNESS,
  MAGIC_ITEM_OIL_OF_SHARPNESS,
  MAGIC_ITEM_OIL_OF_SLIPPERINESS,
  MAGIC_ITEM_PEARL_OF_POWER,
  MAGIC_ITEM_PERIAPT_OF_HEALTH,
  MAGIC_ITEM_PERIAPT_OF_PROOF_AGAINST_POISON,
  MAGIC_ITEM_PERIAPT_OF_WOUND_CLOSURE,
  MAGIC_ITEM_PHILTER_OF_LOVE,
  MAGIC_ITEM_PIPES_OF_HAUNTING,
  MAGIC_ITEM_PIPES_OF_THE_SEWERS,
  MAGIC_ITEM_PLATE_ARMOR_OF_ETHEREALNESS,
  MAGIC_ITEM_PORTABLE_HOLE,
  MAGIC_ITEM_POTION_OF_ANIMAL_FRIENDSHIP,
  MAGIC_ITEM_POTION_OF_CLAIRVOYANCE,
  MAGIC_ITEM_POTION_OF_CLIMBING,
  MAGIC_ITEM_POTION_OF_DIMINUTION,
  MAGIC_ITEM_POTION_OF_FIRE_BREATH,
  MAGIC_ITEM_POTION_OF_FLYING,
  MAGIC_ITEM_POTION_OF_GASEOUS_FORM,
  MAGIC_ITEM_POTION_OF_GROWTH,
  MAGIC_ITEM_POTION_OF_HEALING_COMMON,
  MAGIC_ITEM_POTION_OF_HEROISM,
  MAGIC_ITEM_POTION_OF_INVISIBILITY,
  MAGIC_ITEM_POTION_OF_INVULNERABILITY,
  MAGIC_ITEM_POTION_OF_LONGEVITY,
  MAGIC_ITEM_POTION_OF_MIND_READING,
  MAGIC_ITEM_POTION_OF_POISON,
  MAGIC_ITEM_POTION_OF_SPEED,
  MAGIC_ITEM_POTION_OF_VITALITY,
  MAGIC_ITEM_POTION_OF_WATER_BREATHING,
  MAGIC_ITEM_QUIVER_OF_EHLONNA,
  MAGIC_ITEM_RING_OF_ANIMAL_INFLUENCE,
  MAGIC_ITEM_RING_OF_DJINNI_SUMMONING,
  MAGIC_ITEM_RING_OF_EVASION,
  MAGIC_ITEM_RING_OF_FEATHER_FALLING,
  MAGIC_ITEM_RING_OF_FREE_ACTION,
  MAGIC_ITEM_RING_OF_INVISIBILITY,
  MAGIC_ITEM_RING_OF_JUMPING,
  MAGIC_ITEM_RING_OF_MIND_SHIELDING,
  MAGIC_ITEM_RING_OF_PROTECTION,
  MAGIC_ITEM_RING_OF_REGENERATION,
  MAGIC_ITEM_RING_OF_SHOOTING_STARS,
  MAGIC_ITEM_RING_OF_SPELL_STORING,
  MAGIC_ITEM_RING_OF_SPELL_TURNING,
  MAGIC_ITEM_RING_OF_SWIMMING,
  MAGIC_ITEM_RING_OF_TELEKINESIS,
  MAGIC_ITEM_RING_OF_THE_RAM,
  MAGIC_ITEM_RING_OF_THREE_WISHES,
  MAGIC_ITEM_RING_OF_WARMTH,
  MAGIC_ITEM_RING_OF_WATER_WALKING,
  MAGIC_ITEM_RING_OF_X_RAY_VISION,
  MAGIC_ITEM_ROBE_OF_EYES,
  MAGIC_ITEM_ROBE_OF_SCINTILLATING_COLORS,
  MAGIC_ITEM_ROBE_OF_STARS,
  MAGIC_ITEM_ROBE_OF_THE_ARCHMAGI,
  MAGIC_ITEM_ROBE_OF_USEFUL_ITEMS,
  MAGIC_ITEM_ROD_OF_ABSORPTION,
  MAGIC_ITEM_ROD_OF_ALERTNESS,
  MAGIC_ITEM_ROD_OF_LORDLY_MIGHT,
  MAGIC_ITEM_ROD_OF_RESURRECTION,
  MAGIC_ITEM_ROD_OF_RULERSHIP,
  MAGIC_ITEM_ROD_OF_SECURITY,
  MAGIC_ITEM_ROPE_OF_CLIMBING,
  MAGIC_ITEM_ROPE_OF_ENTANGLEMENT,
  MAGIC_ITEM_SADDLE_OF_THE_CAVALIER,
  MAGIC_ITEM_SCARAB_OF_PROTECTION,
  MAGIC_ITEM_SCIMITAR_OF_SPEED,
  MAGIC_ITEM_SENDING_STONES,
  MAGIC_ITEM_SENTINEL_SHIELD,
  MAGIC_ITEM_SHIELD_OF_MISSILE_ATTRACTION,
  MAGIC_ITEM_SLIPPERS_OF_SPIDER_CLIMBING,
  MAGIC_ITEM_SOVEREIGN_GLUE,
  MAGIC_ITEM_SPELLGUARD_SHIELD,
  MAGIC_ITEM_SPHERE_OF_ANNIHILATION,
  MAGIC_ITEM_STAFF_OF_CHARMING,
  MAGIC_ITEM_STAFF_OF_FIRE,
  MAGIC_ITEM_STAFF_OF_FROST,
  MAGIC_ITEM_STAFF_OF_HEALING,
  MAGIC_ITEM_STAFF_OF_POWER,
  MAGIC_ITEM_STAFF_OF_STRIKING,
  MAGIC_ITEM_STAFF_OF_SWARMING_INSECTS,
  MAGIC_ITEM_STAFF_OF_THE_ADDER,
  MAGIC_ITEM_STAFF_OF_THE_MAGI,
  MAGIC_ITEM_STAFF_OF_THE_PYTHON,
  MAGIC_ITEM_STAFF_OF_THE_WOODLANDS,
  MAGIC_ITEM_STAFF_OF_THUNDER_AND_LIGHTNING,
  MAGIC_ITEM_STAFF_OF_WITHERING,
  MAGIC_ITEM_STONE_OF_CONTROLLING_EARTH_ELEMENTALS,
  MAGIC_ITEM_STONE_OF_GOOD_LUCK,
  MAGIC_ITEM_SUN_BLADE,
  MAGIC_ITEM_SWORD_OF_LIFE_STEALING,
  MAGIC_ITEM_SWORD_OF_SHARPNESS,
  MAGIC_ITEM_SWORD_OF_VENGEANCE,
  MAGIC_ITEM_SWORD_OF_WOUNDING,
  MAGIC_ITEM_TALISMAN_OF_THE_SPHERE,
  MAGIC_ITEM_TENTACLE_ROD,
  MAGIC_ITEM_TOME_OF_CLEAR_THOUGHT,
  MAGIC_ITEM_TOME_OF_LEADERSHIP_AND_INFLUENCE,
  MAGIC_ITEM_TOME_OF_THE_STILLED_TONGUE,
  MAGIC_ITEM_TOME_OF_UNDERSTANDING,
  MAGIC_ITEM_TRIDENT_OF_FISH_COMMAND,
  MAGIC_ITEM_UNIVERSAL_SOLVENT,
  MAGIC_ITEM_VICIOUS_WEAPON,
  MAGIC_ITEM_VORPAL_SWORD,
  MAGIC_ITEM_WAND_OF_BINDING,
  MAGIC_ITEM_WAND_OF_ENEMY_DETECTION,
  MAGIC_ITEM_WAND_OF_FEAR,
  MAGIC_ITEM_WAND_OF_FIREBALLS,
  MAGIC_ITEM_WAND_OF_LIGHTNING_BOLTS,
  MAGIC_ITEM_WAND_OF_MAGIC_DETECTION,
  MAGIC_ITEM_WAND_OF_MAGIC_MISSILES,
  MAGIC_ITEM_WAND_OF_PARALYSIS,
  MAGIC_ITEM_WAND_OF_POLYMORPH,
  MAGIC_ITEM_WAND_OF_SECRETS,
  MAGIC_ITEM_WAND_OF_WEB,
  MAGIC_ITEM_WAND_OF_WONDER,
  MAGIC_ITEM_WEAPON_OF_WARNING,
  MAGIC_ITEM_WELL_OF_MANY_WORLDS,
  MAGIC_ITEM_WIND_FAN,
  MAGIC_ITEM_WINGED_BOOTS,
  MAGIC_ITEM_WINGS_OF_FLYING,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_FLAME_TONGUE,
  name: `Язык пламени`,
  nameEn: `Flame Tongue`,
  type: MGC_TYPE_WEAPON,
  typeComment: `любой меч`,
  rarity: MGC_RARITY_RARE,
  attunement: true,
  description: `Вы можете бонусным действием произнести командное слово этого магического меча, отчего из него вырвутся языки пламени. Это пламя испускает яркий свет в радиусе 40 футов и тусклый свет в радиусе ещё 40 футов.

Пока меч пылает, он причиняет дополнительный урон огнём 2к6 целям, по которым попадает.

Пламя остаётся, пока Вы не произнесёте ещё раз командное слово бонусным действием, или пока не уберёте меч в ножны.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 215,
  },
}
