const {SOURCE_VGTM} = require('./../../sourceList')
const {GOD_MAGLUBIYET} = require('./../../godIdList')
const {
  CREATURE_GOBLIN,
  CREATURE_HOBGOBLIN,
  CREATURE_HOBGOBLIN_WARLORD,
} = require('./../../creatureIdList')

module.exports = {
  header: 'Норкеры',
  text: `Жестокие, злобные и ленивые даже по меркам гоблиноидов, норкеры — это жесткие гуманоиды, чьи длинные острые клыки и толстые костные экзоскелеты делают их хорошо подходящими для рядов армий [хобгоблинов](CREATURE:${CREATURE_HOBGOBLIN}). К несчастью для своих будущих хозяев, норкеры неуправляемые, наглые и ленивые, если за ними не наблюдают проницательным взглядом и рукой, которая быстро щёлкает кнутом.

**Жители глубин земли.** В древние времена норкеры жили глубоко под землей. Их всегда было немного, а их бог был жестоким беспощадным тираном, который держал своих детей близко, а внешний мир — на расстоянии. Этот давно потерянный бог, возможно, был первым завоеванием [Маглубиета](GOD:${GOD_MAGLUBIYET}), а его последователи были первыми, недобровольными призывниками в крестовом походе [Маглубиета](GOD:${GOD_MAGLUBIYET}). Сегодня [хобгоблинцы](CREATURE:${CREATURE_HOBGOBLIN}) раздражаются из-за предположения, что такие ленивые дикари, как норкеры, могут претендовать на такую ​​честь. Это единственная причина, почему [командиры хобгоблинов](CREATURE:${CREATURE_HOBGOBLIN_WARLORD}) не хотят использовать этих существ, предпочитая держать их в качестве расходных рабочих в шахтах и ​​карьерах.

**Мерзкие, мелкие и тупые.** Даже [гоблины](CREATURE:${CREATURE_GOBLIN}) задирают и издеваются над норкерами, поскольку [хобгоблины](CREATURE:${CREATURE_HOBGOBLIN}) ставят норкеров на дно гоблиноидных рангов, но свирепость норкеров, их прочные шкуры и острые клыки позволяют им подниматься и побеждать тех гоблиноидов, которые недооценивают силу и свирепость норкеров. По этой причине норкеры, вероятно, часто встречаются отдельно от своих братьев. Естественная броня и оружие делают их опасными противниками, даже когда они отправляются на чёрную работу. Только предписания [Маглубиета](GOD:${GOD_MAGLUBIYET}) не позволили [хобгоблинам](CREATURE:${CREATURE_HOBGOBLIN}) выслеживать и истреблять этих существ.

**Наследие ненависти.** Учитывая их древнее поражение и ужасное обращение со стороны других гоблиноидов, норкеры создали особую секту поклоняющихся [Маглубиету](GOD:${GOD_MAGLUBIYET}). Они называют его Великим Бичом и верят, что смертная жизнь — жестокое испытание. Те норкеры, чья кожа может противостоять любому удару и чьи клыки глубоко проникают в врагов, будут отправлены в рай. А врагами для норкеров являются все остальные существа. Сильные враги могут быть смягчены прислуживанием и угодливостью, а более слабые становятся жертвами их клыков и булав.`,
  source: {
    id: SOURCE_VGTM,
    page: 198,
  },
}
