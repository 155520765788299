const {ALPHABET_IOKHARIC} = require('./../../alphabetList')
const {LANG_DRACONIC} = require('./../../languageIdList')
const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {
  PC_RACE_KOBOLD,
  PC_RACE_DRAGONBORN,
} = require('./../../pcRaceIdList')
const {
  SOURCE_DND4_DCD,
  SOURCE_PHB,
  SOURCE_VGTM,
} = require('./../../sourceList')

module.exports = {
  id: LANG_DRACONIC,
  alphabetId: ALPHABET_IOKHARIC,
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Драконик',
    genitive: 'Драконика',
    instrumental: 'Дракоником',
    prepositional: 'Драконике',
  },
  nameAlt: 'Драконий',
  nameEn: 'Draconic',
  dictionary: [
    {
      list: [
        ['броня', 'литрикс'],
        ['война', 'арит'],
        ['глаз', 'саурив'],
        ['гора', 'вертича'],
        ['демон', 'котар'],
        ['доспех', 'литрикс'],
        ['дракон', 'дарастрикс'],
        ['железо', 'уск'],
        ['животное', 'бэшра'],
        ['зелёный', 'ачуак'],
        ['маленький', 'косч'],
        ['много', 'тродэн'],
        ['молот', 'чанк'],
        ['небольшой', 'косч'],
        ['ночь', 'туркер'],
        ['ожог', 'велигнат'],
        ['опасность', 'корт'],
        ['песня', 'миирик'],
        ['самоцвет', 'кетенд'],
        ['сражение', 'варгач'],
        ['сталь', 'вит'],
        ['тайна', 'ирхтос'],
        ['топор', 'гарурт'],
        ['ум', 'отокент'],
        ['умный', 'отокент'],
        ['чёрный', 'вута'],
        ['шторм', 'кепеск'],
      ],
      source: {
        id: SOURCE_VGTM,
        page: 114,
      },
    },
    {
      list: [
        ['белый', 'оссир'],
        ['богатство', 'ноач'],
        ['божество', 'юратэр'],
        ['большой', 'туралиж'],
        ['брать', 'клакс'],
        ['бронза', 'ожир'],
        ['в', 'персвек'],
        ['внутри', 'персвек'],
        ['век, столетие', 'йерик'],
        ['взлетать', 'хисвэр'],
        ['видеть', 'окюр'],
        ['внизу', 'вир'],
        ['вода', 'хежинг'],
        ['воздух', 'трэ'],
        ['возле', 'лэйрит'],
        ['волшебник', 'лэвэтикс'],
        ['вор', 'вирлим'],
        ['враг', 'ирлим'],
        ['глупый', 'поток'],
        ['гном', 'терунт'],
        ['говорить', 'рентиш'],
        ['год', 'иорик'],
        ['гореть', 'валигнат'],
        ['грабёж', 'тадарш'],
        ['да', 'аксун'],
        ['давать', 'мажак'],
        ['далёкий', 'кариф'],
        ['дварф', 'тундар'],
        ['день', 'кэр'],
        ['дерево', 'гровисв'],
        ['для', 'их'],
        ['до, перед', 'горос'],
        ['дождь', 'опосс'],
        ['долина', 'арукс'],
        ['дом', 'окартел'],
        ['друг', 'тарарл'],
        ['дружелюбный', 'тарарл'],
        ['еда', 'актенд'],
        ['если', 'жжек'],
        ['ещё', 'жжерит'],
        ['женский', 'эстир'],
        ['жрец', 'сунатэр'],
        ['жёлтый', 'ирев'],
        ['завтра', 'иаренк'],
        ['зачарованый', 'лэвэкс'],
        ['звезда', 'иск'],
        ['земля', 'эдар'],
        ['злой', 'малсвир'],
        ['золотой', 'орикс'],
        ['зуб', 'от'],
        ['и', 'вур'],
        ['идти', 'гетриш'],
        ['изувеченный', 'тургикс'],
        ['или', 'усв'],
        ['имя', 'оминак'],
        ['к', 'экесс'],
        ['камень', 'тэрнэж'],
        ['кишки', 'гиксустрат'],
        ['потрошить', 'гиксустрат'],
        ['коготь', 'гикс'],
        ['кожа', 'молик'],
        ['шкура', 'молик'],
        ['копьё', 'нэр'],
        ['корова', 'ривос'],
        ['красивый', 'ворел'],
        ['красный', 'чарир'],
        ['крепость', 'хурти'],
        ['кровоточить', 'валеж'],
        ['кровь', 'йежир'],
        ['кроме', 'ансинти'],
        ['лес', 'кэзин'],
        ['лететь', 'устрат'],
        ['лечить', 'айрисви'],
        ['лидер', 'мэкрикс'],
        ['лук', 'вэкс'],
        ['магия', 'арканисс'],
        ['медь', 'рач'],
        ['меч', 'кэкс'],
        ['мужчина', 'штир'],
        ['мы', 'ут'],
        ['мясо', 'раякс'],
        ['мёртвый', 'локс'],
        ['на', 'шафэр'],
        ['наверху', 'сверн'],
        ['насквозь', 'эрекесс'],
        ['небесный', 'атэр'],
        ['нежить', 'кэрго'],
        ['немного', 'лот'],
        ['ненависть', 'дартак'],
        ['ненавидеть', 'дартак'],
        ['нет', 'трик'],
        ['ни', 'тур'],
        ['но', 'шар'],
        ['ночное зрение', 'сверак'],
        ['огонь', 'иксен'],
        ['огр', 'гонтикс'],
        ['один', 'ир'],
        ['орк', 'гик'],
        ['остановиться', 'пок'],
        ['отдых', 'ссифисв'],
        ['пепел', 'вигнар'],
        ['пещера', 'вэре'],
        ['платина', 'акс'],
        ['победа', 'вивекс'],
        ['под', 'онурет'],
        ['подкупать', 'дурах'],
        ['позади', 'зара'],
        ['покой', 'мартивир'],
        ['полурослик', 'рохисс'],
        ['после', 'гент'],
        ['путешествовать', 'оссалур'],
        ['разговаривать', 'укрис'],
        ['резня', 'курик'],
        ['с', 'мрит'],
        ['вместе', 'мрит'],
        ['свиток', 'жжир'],
        ['секрет', 'иртос'],
        ['серебро', 'орн'],
        ['сильный', 'версвеш'],
        ['синий', 'алхар'],
        ['сокровище', 'расвим'],
        ['спариваться', 'морг'],
        ['стрела', 'свент'],
        ['судьба', 'хаурак'],
        ['сумка', 'вэс'],
        ['так что', 'зяк'],
        ['танец', 'вэри'],
        ['тень', 'жжач'],
        ['трус', 'фэсси'],
        ['ты', 'вукс'],
        ['убегать', 'освит'],
        ['убить', 'свент'],
        ['умереть', 'лорэт'],
        ['уродливый', 'нурх'],
        ['хороший', 'бенсвелк'],
        ['хотеть', 'туор'],
        ['храбрость', 'свэргис'],
        ['чародей', 'ворастрикс'],
        ['человек', 'мантрек'],
        ['эльф', 'вэкэсин'],
      ],
      source:{
        id: SOURCE_DND4_DCD,
        page: 26,
      },
    },
    {
      list: [['хранители яиц', 'хисташии']],
      source: {
        id: SOURCE_VGTM,
        page: 206,
      },
    },
  ],
  description: [
    {
      text: `Драконик считается одним из старейших и часто используется во время изучения магии. Этот язык звучит грубо для большинства других существ, и содержит много твёрдых согласных и шипящих звуков.`,
      source: {
        id: SOURCE_PHB,
        page: 35,
      },
    },
    {
      header: 'Язык',
      text: `Драконы, одна из древнейших живущих в мире рас, говорят на одном из самых древних смертных языков. Когда смертные расы видели богов, каждая из них слышала Божественный язык по-своему, в зависимости от его формы и манеры. Так возникли основные мировые языки, включая Драконик.

Ио создал драконье письмо, Иохарик, чтобы его смертные дети могли записывать свои впечатления от мира, который, как он надеялся, они унаследуют. И драконий язык, и его письмо сохранились до наших дней.

Расы, связанные с драконами, в том числе [драконорождённые](PC_RACE:${PC_RACE_DRAGONBORN}) и [кобольды](PC_RACE:${PC_RACE_KOBOLD}), тоже говорят на Драконике и используют Иохарик. Некоторые представители этих рас очень гордятся тем, что используют тот же язык, который грохочет из чешуйчатых губ древних змиев, в то время как другие проклинают свою родословную. Однако большинство из них редко об этом задумывается.

Как и во всех языках, в Драконике изобилуют вариации и диалекты, зависящие от рода или расы говорящего. Те, кто изучает диалекты, по языку потенциально может даже определить разновидность дракона, не видя его чешуи. Хотя акценты иногда бывают сильными, они редко мешают общению. Языки, произошедшие от Божественного, достаточно устойчивы, чтобы противостоять естественным изменениям, из-за которых далёкие группы носителей перестают понимать друг друга.`,
      source: {
        id: SOURCE_DND4_DCD,
        page: 25,
      },
    },
    {
      header: 'Описания языка',
      text: `Звучание Драконика режет уши других существ, потому что включает много шипящих и твёрдых согласных. Часть его звуков описывается людьми как шипение (_«ж»_, _«ш»_ и _«св»_) и звук _«ах»_, звучащий так, будто зверь прочищает горло.

В словах Драконика ударение ставится на первый слог. Говорящие на Драконике могут выделять важную идею, ставя ударение и на первый и на последний слог слова. Драконы могут использовать эту форму выделения, когда обращаются к самим себе, а также когда приказывают, угрожают, предупреждают или подчёркивают что-то.`,
      source: {
        id: SOURCE_DND4_DCD,
        page: 26,
      },
    },
  ],
  typicalSpeakers: 'Драконы, драконорождённые',
  isRealLang: true,
  isReady: true,
}
