const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  LANG_COMMON,
  LANG_GIANT,
} = require('./../../../../languageIdList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  giantStoneNote,
  stoneGiantDescriptionList,
} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_GIANT_STONE,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_STONE_GIANT_DREAMWALKER} = require('./../../../../creatureIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {SPELL_GREATER_RESTORATION} = require('./../../../../spellIdList')

module.exports = {
  name: 'Каменный великан сноходец',
  nameAlt: 'Каменный гигант сноходец',
  nameEn: 'Stone Giant Dreamwalker',
  id: CREATURE_STONE_GIANT_DREAMWALKER,
  note: giantStoneNote,
  description: [
    {
      text: `Мир поверхности — это чужой мир для каменных великанов: переменчивый, временный, подверженный дующим ветрам и внезапным дождям. Он так же дико меняется как и сон и они так к нему и относятся. Ничто там не постоянно, а, значит, ничто не реально. То, что происходит на поверхности, не имеет никакого значения. Обещания и сделки, сделанные там, чтить не обязательно. Жизнь и даже искусство там менее ценны.`,
      source: {
        id: SOURCE_VGTM,
        page: 136,
      },
    },
    {
      header: 'Обитатели сна',
      text: `Каменные великаны иногда отправляются на задания в мир поверхности, ищут вдохновение для своего искусства, прерывают десятилетнюю скуку или просто из любопытства. Некоторые из тех, кто отправляется на задание позволяют себе заблудиться во сне. Других каменных великанов отправляют на поверхность в наказание. Вне зависимости от причин, если они не укроются под камнем, то такие индивиды могут стать сноходцами.

Сноходцы занимают странную нишу уважения вне уклада каменных великанов. Их считают изгоями, но их знакомство с миром поверхности делает их ценными гидами, а их проницательность может помочь другим каменным великанам осознать опасности жизни во сне.`,
      source: {
        id: SOURCE_VGTM,
        page: 136,
      },
    },
    {
      header: 'Безумные скитальцы',
      text: `Сноходцы сходят с ума от изоляции, стыда и постоянного чужеродного окружения, и это безумие впивается в мир вокруг них, воздействуя на существ, подошедших слишком близко. Они верят, что живут во сне и у их действий нет никаких реальных последствий, так что сноходцы действуют так, как им хочется, становясь силами хаоса. Во время путешествия по миру они собирают объекты и существ, которые кажутся особенно значимыми для их помутившихся разумов. Со временем собранные вещи врастают в их тело и заключаются в камне.`,
      source: {
        id: SOURCE_VGTM,
        page: 136,
      },
    },
    ...stoneGiantDescriptionList,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_GIANT_STONE,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_VGTM,
    page: 136,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 14,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 21,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 12,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_GIANT,
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Шарм сноходца',
      description: `Враг, который начинает ход не далее 30 футов от ★СУЩЕСТВО★, должен пройти испытание Харизмы СЛ 13, если ★СУЩЕСТВО★ ★Дееспособен★. При провале враг Очарован ★СУЩЕСТВО★. Существо, очарованное таким образом, может повторять испытание в конце каждого своего хода и окончить эффект при успехе. При успехе существо получает иммунитет к _Шарму сноходца_ этого великана на 24 часа.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Палицей_.`,
    },
    {
      name: 'Палица',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 15,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 8,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Касание окаменения',
      description: `Великан касается одного Среднего или меньше существа в 10 футах от себя, которое им очаровано. Цель должна пройти испытание Телосложения СЛ 17. При провале цель окаменевает и великан может приклеить её к своему каменному телу. Заклинание [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) и другая магия, которая может снять окаменение, не работает на этом существе, прикрепленном к великану, если только великан не умер. В таком случае магию работает нормально и освобождает окаменевшее существо от этого состояния.`,
    },
    {
      name: 'Камень',
      description: `Если цель является существом, то она должна успешно пройти испытание Силы СЛ 17 или быть Сбитой с ног.`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 10,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
  ],
}
