const {
  CREATURE_GOBLIN,
  CREATURE_HOBGOBLIN,
} = require('./../../creatureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_MM,
} = require('./../../sourceList')
const hruggekDescription = require('./hruggek')
const goblinoidManual = require('./goblinoidManual')

module.exports = [
  {
    text: `Багбиры рождены для битвы и хаоса. Выживая набегами и охотой, они запугивают слабых и подавляют их, но при этом они готовы сражаться на стороне сильных вождей, если это сулит им кровопролитие и сокровища.`,
    source: {
      id: SOURCE_MM,
      page: 201,
    },
  },
  {
    header: 'Багбир',
    text: `Раса шерстяных гуманоидов, которых влечёт кровопролитие и нажива.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Гоблиноиды',
    text: `Багбиров часто можно встретить в компании своих близких родственников, [хобгоблинов](CREATURE:${CREATURE_HOBGOBLIN}) и [гоблинов](CREATURE:${CREATURE_GOBLIN}). Обычно багбиры порабощают гоблинов, с которыми столкнулись, а хобгоблинов они запугивают, суля им золото и пищу в обмен на работу в качестве разведчиков и ударных войск. Даже платящие багбиры — не самые надёжные союзники, но гоблины и хобгоблины понимают, что как бы багбиры не истощали ресурсы племени, они остаются могучей силой.`,
    source: {
      id: SOURCE_MM,
      page: 201,
    },
  },
  hruggekDescription,
  {
    header: 'Продажные любители засад',
    text: `Несмотря на громоздкое телосложение, багбиры двигаются с удивительной скрытностью. Они любят устраивать засады, но отступают, когда сталкиваются с ожесточённым сопротивлением. Это надёжные наёмники, пока их обеспечивают едой, питьём и золотом, но они забывают свои клятвы, когда их жизнь висит на волоске. Раненного члена группы багбиры могут бросить, чтобы задержать преследователей, а если преследователи сохранят жизнь отставшему багбиру, то он поможет выследить бросивших его соплеменников.`,
    source: {
      id: SOURCE_MM,
      page: 201,
    },
  },
  goblinoidManual,
]
