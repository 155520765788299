const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  FEATURE_SUMMON_WILDFIRE_SPIRIT,
} = require('./../../../../featureIdList')
const {
  PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE,
} = require('./../../../../pcSubClassIdList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_ELEMENTAL_FIRE,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_WILDFIRE_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух лесного пожара',
  nameEn: 'Wildfire Spirit',
  id: CREATURE_WILDFIRE_SPIRIT,
  description: `Создан [Призывом духа лесного пожара](FEATURE:${FEATURE_SUMMON_WILDFIRE_SPIRIT}) друида [круга лесного пожара](PC_SUBCLASS:${PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE})`,
  createdByFeatureId: FEATURE_SUMMON_WILDFIRE_SPIRIT,
  note: {
    text: `Я даже не смогу сосчитать, сколько раз я сжигала всё дотла и начинала заново`,
    author: `Таша`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_ELEMENTAL_FIRE,
  ],
  source: {
    id: SOURCE_TCoE,
    page: 57,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: `5 + пятикратный уровень друида`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 11,
  },
  immunityList: [
    DAMAGE_FIRE,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_FRIGHTENED,
    CONDITION_GRAPPLED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    {
      id: LANG_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  actionList: [
    {
      name: 'Пламенное семечко',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 'бонус атаки заклинаниями друида',
        range: 60,
        target: {
          count: 1,
          comment: 'видимая друидом',
        },
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 1,
          diceType: 6,
          diceBonus: 'бонус мастерства друида',
        },
      },
    },
    {
      name: 'Пылкая телепортация',
      description: `★СУЩЕСТВО★ и все согласные существа по выбору друида в пределах 5 футов от ★СУЩЕСТВО★ телепортируются на расстояние до 15 футов в видимое друидом свободное пространство. После этого все существа в пределах 5 футов от места отправления должны пройти испытание Ловкости против СЛ заклинаний друида или получить 1к6 + бонус мастерства друида урона огнём.`,
    },
  ],
}
