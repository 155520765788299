const {BACKGROUND_OUTLANDER} = require('./../../backgroundIdList')
const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_BARBARIAN,
  FEATURE_BRUTAL_CRITICAL,
  FEATURE_DANGER_SENSE,
  FEATURE_EXTRA_ATTACK_BARBARIAN,
  FEATURE_FAST_MOVEMENT,
  FEATURE_FERAL_INSTINCT,
  FEATURE_INDOMITABLE_MIGHT,
  FEATURE_PERSISTENT_RAGE,
  FEATURE_PRIMAL_CHAMPION,
  FEATURE_PRIMAL_PATH,
  FEATURE_RAGE,
  FEATURE_RECKLESS_ATTACK,
  FEATURE_RELENTLESS_RAGE,
  FEATURE_UNARMORED_DEFENSE_BARBARIAN,
} = require('./../../featureIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
} = require('./../../sourceList')
const {
  GEAR_EXPLORER_S_PACK,
  GEAR_GREATAXE,
  GEAR_HANDAXE,
  GEAR_JAVELIN,
} = require('./../../gearIdList')
const {
  CREATURE_ELK,
  CREATURE_FROST_GIANT,
} = require('./../../creatureIdList')
const {
  PARAM_CON,
  PARAM_STR,
} = require('./../../paramList')
const {
  CAT_LIGHT_ARMOR,
  CAT_MEDIUM_ARMOR,
  CAT_SHIELDS,
  CAT_SIMPLE_WEAPON,
  CAT_MARTIAL_WEAPON,
} = require('./../../gearCategoryList')
const {
  SKILL_ANIMAL_HANDLING,
  SKILL_ATHLETICS,
  SKILL_INTIMIDATION,
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_SURVIVAL,
} = require('./../../skillList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_HUMAN,
  PC_RACE_TIEFLING,
} = require('./../../pcRaceIdList')
const {
  PC_CLASS_BARBARIAN,
  PC_CLASS_FIGHTER,
} = require('./../../pcClassIdList')

module.exports = {
  id: PC_CLASS_BARBARIAN,
  name: {
    singular: {
      nominative: 'варвар',
      genitive: 'варвара',
      dative: 'варвару',
      accusative: 'варвара',
      instrumental: 'варваром',
      prepositional: 'варваре',
    },
    plural: {
      nominative: 'варвары',
      genitive: 'варваров',
      dative: 'варварам',
      accusative: 'варваров',
      instrumental: 'варварами',
      prepositional: 'варварах',
    },
  },
  nameEn: 'barbarian',
  description: [
    `Высокий, закутанный в шкуры дикарь пробирается сквозь метель, сжимая в руке свой топор. Он со смехом бросается на [ледяного великана](CREATURE:${CREATURE_FROST_GIANT}), который посмел убить [лося](CREATURE:${CREATURE_ELK}) в стаде его народа.

Полуорк рычит на храбреца, осмелившегося посягнуть на его первенство в племени, готовый свернуть ему шею голыми руками, как он поступил с предыдущими шестью соперниками.

С пеной у рта дварф швыряет свой шлем в лицо напавшему дроу, и резко разворачивается, впечатав бронированный локоть в живот второму.

Несмотря на разнообразие, всех варваров объединяет одно — их ярость. Необузданный, неугасимый и бездумный гнев. Не просто эмоция, их ярость как свирепость загнанного в угол хищника, как безжалостный удар урагана, как штормовые валы океана.

Ярость некоторых из них проистекает из общения со свирепыми духами животных. Другие черпают её из злости на полную боли и страдания действительность. Но для каждого варвара ярость — это источник не только боевого безумия, но и невероятных рефлексов, стойкости, а также непревзойдённой силы.`,
    {
      header: `Первобытные инстинкты`,
      text: `Жители посёлков и городов настолько гордятся своей цивилизованностью, отличающей их от животных, словно отрицание собственной природы подчёркивает их превосходство. Варвары же, напротив, считают цивилизованность проявлением слабости. Связь между их животными инстинктами, первобытной энергетикой и свирепой яростью очень сильна. Варвары чувствуют себя неуютно в окружении стен или в толпе, но раскрываются в родных диких просторах, в тундре, джунглях или степях, где их племена живут и охотятся.

Лучше всего варвары проявляют себя посреди хаоса битвы. Они могут впасть в состояние берсерка, утратив контроль над собственной яростью, и получая взамен нечеловеческую силу и стойкость. Варвар может лишь несколько раз воспользоваться резервами собственного гнева, прежде чем ему потребуется отдых, но обычно этих нескольких раз хватает, чтобы справиться с любой угрозой, встреченной на пути.`,
      source: {
        id: SOURCE_PHB,
        page: 47,
      },
    },
    {
      header: `Жизнь, полная опасностей`,
      text: `Не все члены племён, которых в цивилизованном обществе называют варварами, имеют класс «варвар». Настоящий варвар среди этих людей так же редок, как опытный [воин](PC_CLASS:${PC_CLASS_FIGHTER}) в городе, и он исполняет схожую роль защитника людей и военного лидера. Жизнь в диких местах таит в себе опасность: соперничающие племена, смертельно опасная погода и ужасные чудовища. И варвар бросается в борьбу с этими опасностями, защищая своих людей.

Смелость перед лицом опасности делает варвара превосходным кандидатом в искатели приключений. Кочевой образ жизни часто привычен для примитивных племён, и непоседливая жизнь авантюристов не составляет трудности для варвара. Некоторые варвары скучают по сплочённому семейному укладу своих племён, но в конце концов находят замену в узах, связывающих членов отряда.`,
      source: {
        id: SOURCE_PHB,
        page: 47,
      },
    },
    {
      header: `Варвары`,
      text: `Большинство местности на Побережье меча и Севере — дикие земли, где идёт ежедневная борьба за выживание. Эти земли породили выносливые племена и свирепых воинов, таких как регхедские и утгардские варвары Севера и моряки-северяне с островов Муншае и крайних северных границ Побережья меча.

Среди варваров этих земель наиболее распространены [люди](PC_RACE:${PC_RACE_HUMAN}) и [полуорки](PC_RACE:${PC_RACE_HALF_ORC}), реже встречаются [полуэльфы](PC_RACE:${PC_RACE_HALF_ELF}), рождённые в результате контактов диких человеческих племён с [эльфами](PC_RACE:${PC_RACE_ELF}) Севера и Западного Сердцеземья или [тифлинги](PC_RACE:${PC_RACE_TIEFLING}) из племён, скрещивающих кровь с исчадиями. [Дварфийские](PC_RACE:${PC_RACE_DWARF}) варвары — уважаемые и внушающие страх воины, живущие в гордых кланах, занимающихся восстановлением территорий подобных Мифриловому Залу или Гонтлгриму. Варвары иных рас обычно родом из более южных и тёплых земель, а не с Дикого Севера, хотя иногда южане-подкидыши находят приют на Севере и вырастают среди местных племён.`,
      source: {
        id: SOURCE_SCAG,
        page: 122,
      },
    },
    {
      header: `Создание варвара`,
      text: `Когда Вы создаёте персонажа варвара, подумайте о том, откуда Ваш персонаж прибыл, и о том, какое место в мире он занимает. Поговорите с вашим Мастером о подходящем происхождении вашего варвара. Пришли ли Вы из далёких земель, став чужаком на новой территории? Или кампания происходит в жестоких землях, где варвары являются обычным делом?

Что заставило Вас вести жизнь искателя приключений? Вы соблазнились переселением на новые земли обещаниями богатства? Вы присоединились к солдатам этих земель перед лицом общей угрозы? Может, чудовища или вторжение орды изгнали вас из Вашей родины, сделав безродным беженцем? Может быть, Вы были пленником на войне, приведённым в цепях в «цивилизованные» земли и только там завоевавшим свою свободу? Или, возможно, Вы были изгнаны своим народом из-за совершённого вами преступления, нарушения табу, или в результате переворота, после которого Вы были свергнуты со своего места у власти.`,
      source: {
        id: SOURCE_PHB,
        page: 48,
      },
    },
    {
      header: `Быстрое создание`,
      text: `Вы можете быстро создать варвара, следуя этим рекомендациям.

1. Во-первых, у Вашей Силы должно быть наивысшее значение. Следующим по величине должно быть Телосложение.
2. Во-вторых, выберите предысторию «[Чужеземец](BACKGROUND:${BACKGROUND_OUTLANDER})».`,
      source: {
        id: SOURCE_PHB,
        page: 48,
      },
    },
  ],
  note: [
    {
      text: `Пвент находился ближе всех к тёмным эльфам. Он наклонил голову и пронзил грудь одного из дроу шипом на шлеме, даже кольчуга не спасла несчастного. Второй дроу отразил нападение другого берсерка, отведя шип его шлема скрещенными мечами. Но зато он получил страшный удар в горло кулаком, одетым в рукавицу с острыми зазубренными лезвиями на сгибе, от которого под подбородком дроу осталась жуткая кровавая дыра. Задыхаясь, он всё же нанес пару ударов по спине дварфа, но тот, объятый безумной боевой яростью, даже не заметил их.`,
      author: `Р. Э. Сальваторе, «Нашествие Тьмы» (перевод Евгения Фурсикова)`,
    },
    {
      text: `Я видел неукротимость варваров на поле боя, и это заставляет меня задуматься, какая же сила является источником их ярости`,
      author: `Серет, архимаг`,
    },
    {
      text: `Раар! Я очень зол! Забавно, я не чувствую себя сильнее. Может я всегда в наилучшей форме, потому что всегда зол. Звучит логично.`,
      author: `Занатар`,
    },
  ],
  featureCollection: {
    hitDice: 12,
    proficiency: {
      armor: [
        CAT_LIGHT_ARMOR,
        CAT_MEDIUM_ARMOR,
        CAT_SHIELDS,
      ],
      weapon: {
        catList: [
          CAT_SIMPLE_WEAPON,
          CAT_MARTIAL_WEAPON,
        ],
      },
      savingThrow: [
        PARAM_STR,
        PARAM_CON,
      ],
      skill: {
        toChoose: {
          count: 2,
          list: [
            SKILL_ANIMAL_HANDLING,
            SKILL_ATHLETICS,
            SKILL_INTIMIDATION,
            SKILL_NATURE,
            SKILL_PERCEPTION,
            SKILL_SURVIVAL,
          ],
        },
      },
    },
    equipment: {
      coins: {
        diceCount: 2,
        diceType: 4,
        times: 10,
      },
      text: `
1. Выберите одно
    * а) [Секира](GEAR:${GEAR_GREATAXE})
    * б) Любое воинское рукопашное оружие
2. Выберите одно
    * а) Два [ручных топора](GEAR:${GEAR_HANDAXE})
    * б) Любое простое оружие
3. [Набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK})
4. Четыре [пилума](GEAR:${GEAR_JAVELIN})`,
    },
  },
  progressTable: `
| Уровень | Умения                                     | Ярость | Урон ярости |
|---------|--------------------------------------------|--------|-------------|
|  1      | [Защита без доспехов](FEATURE:${FEATURE_UNARMORED_DEFENSE_BARBARIAN}), [Ярость](FEATURE:${FEATURE_RAGE})       | 2 | +2 | 
|  2      | [Безрассудная атака](FEATURE:${FEATURE_RECKLESS_ATTACK}), [Чувство опасности](FEATURE:${FEATURE_DANGER_SENSE}) | 2 | +2 | 
|  3      | [Путь дикости](FEATURE:${FEATURE_PRIMAL_PATH})                                     | 3 | +2 | 
|  4      | [Увеличение характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_BARBARIAN}) | 3 | +2 | 
|  5      | [Быстрое передвижение](FEATURE:${FEATURE_FAST_MOVEMENT}), [Дополнительная атака](FEATURE:${FEATURE_EXTRA_ATTACK_BARBARIAN}) | 3 | +2 | 
|  6      | Умение пути                                                                        | 4 | +2 | 
|  7      | [Дикий инстинкт](FEATURE:${FEATURE_FERAL_INSTINCT})                                | 4 | +2 | 
|  8      | Увеличение характеристик                                                           | 4 | +2 | 
|  9      | [Сильный критический удар](FEATURE:${FEATURE_BRUTAL_CRITICAL}) (1 кость)           | 4 | +3 | 
| 10      | Умение пути                                                                        | 4 | +3 | 
| 11      | [Непреклонная ярость](FEATURE:${FEATURE_RELENTLESS_RAGE})                          | 4 | +3 | 
| 12      | Увеличение характеристик                                                           | 5 | +3 | 
| 13      | Сильный критический удар (2 кости)                                                 | 5 | +3 | 
| 14      | Умение пути                                                                        | 5 | +3 | 
| 15      | [Непрерывная ярость](FEATURE:${FEATURE_PERSISTENT_RAGE})                           | 5 | +3 | 
| 16      | Увеличение характеристик                                                           | 5 | +4 | 
| 17      | Сильный критический удар (3 кости)                                                 | 6 | +4 | 
| 18      | [Неукротимая мощь](FEATURE:${FEATURE_INDOMITABLE_MIGHT})                           | 6 | +4 | 
| 19      | Увеличение характеристик                                                           | 6 | +4 | 
| 20      | [Дикий чемпион](FEATURE:${FEATURE_PRIMAL_CHAMPION})                                | ∞ | +4 | 
`,
  source: [
    {
      id: SOURCE_PHB,
      page: 47,
    },
    {
      id: SOURCE_SRD,
      page: 8,
    },
  ],
}
