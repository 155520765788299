const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CONDITION_BLINDED} = require('./../../../../../conditionList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_PYROTECHNICS} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_PYROTECHNICS,
  name: 'Пиротехника',
  nameEn: 'Pyrotechnics',
  description: `Выберите область немагического огня в 5-футовом кубе, которую Вы видите в пределах дистанции заклинания. Вы можете потушить огонь в этой области и при этом сотворить фейерверки или дымовую завесу.

**Дымовая завеса.** Густой чёрный дым расползается в радиусе 20 футов, огибая углы. Область становится сильно заслонённой. Дым остаётся на месте 1 минуту, или пока сильный ветер не развеет его.

**Фейерверки.** Область взрывается ослепительной разноцветной вспышкой. Все существа в пределах 10 футов от области должны пройти испытание Телосложения, иначе станут ослеплёнными до конца Вашего следующего хода.`,
  lvl: 2,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_CON,
    condition: CONDITION_BLINDED,
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 160,
    },
    {
      id: SOURCE_EE,
      page: 20,
    },
  ],
}
