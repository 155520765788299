const {CREATURE_MAGE} = require('./../../creatureIdList')
const {SOURCE_MM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Гении',
    text: `Гении — редкие стихийные существа из сказок и легенд. На Материальном плане гениев — единицы. Большая их часть обитает в роскошных дворцах на Стихийных Планах и правит боготворящими их рабами.

Гении крайне умны, могучи, горды и величественны. Они высокомерны и эгоистичны, поскольку знают, что лишь немногие, за исключением богов и других гениев, способны превзойти их могущество.`,
    source: {
      id: SOURCE_MM,
      page: 48,
    },
  },
  {
    header: 'Создания стихий',
    text: `Гений рождается при слиянии души разумного живого существа с первозданной материей стихийного плана, но это происходит очень редко.

Гении обычно не сохраняют связи с душой, даровавшей им жизнь. Её жизненная сила — это строительный блок, определяющий облик гения, его мнимый пол, а также одно-два основных персональных качества. Несмотря на то, что они похожи на гуманоидов, гении — духи стихий, имеющие физический облик. Они не спариваются с другими гениями и не производят потомство — все новые гении также рождаются от слияния энергий души и стихии. Гении, обладающие сильной связью со своей смертной душой, могут попробовать зачать ребёнка со смертными, хотя от таких союзов редко рождаются дети.

После смерти гения остаётся лишь его одежда и предметы, а также небольшой след его родной стихии: горстка пепла, порыв ветра, вспышка огня и дыма или лужица воды с пеной.`,
    source: {
      id: SOURCE_MM,
      page: 48,
    },
  },
  {
    header: 'Правь или подчиняйся',
    text: `Количество смертных рабов подчёркивает мощь гения и его высокое самомнение. Сотня подобострастных голосов — музыка для ушей гения, а двести смертных, распростёртых у его ног — доказательство его величия. Гении считают рабов живым имуществом, а на гения без имущества смотрят свысока. Поэтому многие гении дорожат рабами и считают их важными членами своих домов. Злые гении зачастую скверно обращаются с рабами, но не до такой степени, чтобы те стали бесполезны.

Сколь сильно гении любят рабов, столь же сильно они ненавидят служить. Гений подчиняется чужой воле лишь когда он подкуплен или связан магией. Все гении управляют силами своей стихии, но некоторые из них также способны исполнять желания. И то и другое является причиной, по которой смертные [маги](CREATURE:${CREATURE_MAGE}) стремятся подчинить их своей воле.`,
    source: {
      id: SOURCE_MM,
      page: 48,
    },
  },
  {
    header: 'Испорченная аристократия',
    text: `Благородные гении крайне редки. Они привыкли получать то, что хотят, и научились использовать способность исполнять желания, чтобы получить объект своих желаний. Потворство своим желаниям испортило их, а безграничная власть сделала их надменными и высокомерными. Их огромные дворцы переполнены чудесами и источниками наслаждений, которые даже невозможно представить.

Подчёркивая при каждом удобном случае своё превосходство, благородные гении вызывают ревность и зависть других гениев. Они уважают влиятельность благородных гениев, понимая, что неразумно перечить существу, которое способно по своей прихоти менять реальность. Однако время от времени какой-нибудь гений, ничем не обязанный кому-либо из благородных, всё же отваживается воспротивиться их воле, несмотря на последствия.`,
    source: {
      id: SOURCE_MM,
      page: 48,
    },
  },
  {
    header: 'Сила поклонения',
    text: `Гении считаются с могуществом богов, но не ищут их расположения и не поклоняются им. Бесконечное раболепие и бормотание верующих им кажется утомительными, за исключением тех случаев, когда оно направлено на самих гениев со стороны боготворящих их рабов.

Обладая чудесными силами, роскошными жилищами и множеством рабов, некоторые гении ошибочно считают себя равными богам. Некоторые заходят настолько далеко, что требуют, чтобы перед ними склонились смертные других реальностей, а то и целые континенты или миры.`,
    source: {
      id: SOURCE_MM,
      page: 48,
    },
  },
]
