const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_ELADRIN,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_FEY,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_COLD,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPELL_CONE_OF_COLD,
  SPELL_FOG_CLOUD,
  SPELL_GUST_OF_WIND,
  SPELL_ICE_STORM,
} = require('./../../../../spellIdList')
const {
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
} = require('./../../../../gearIdList')
const {
  eladrinDescription,
  eladrinNote,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_10} = require('./../../../../crList')
const {CREATURE_ELADRIN_WINTER} = require('./../../../../creatureIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Зимний эладрин',
  nameEn: 'Winter Eladrin',
  id: CREATURE_ELADRIN_WINTER,
  description: [
    `Когда эладрины опечалены, они входят в зимнюю пору, приобретая меланхоличный и грустный настрой. Замёрзшие слёзы падают с щёк, а осязаемая печаль вытекает из них горьким холодом.`,
    ...eladrinDescription,
  ],
  note: eladrinNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
    CREATURE_TYPE_ELF,
    CREATURE_TYPE_ELADRIN,
  ],
  alignmentId: ALIGNMENT_CN,
  source: [
    {
      id: SOURCE_MTOF,
      page: 244,
    },
    {
      url: `https://5e.tools/bestiary.html#soul%20monger_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Зимнего эладрина» пропущено сопротивление к холоду.`,
    },
  ],
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 11,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 16,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 13,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_ELVEN,
    LANG_SYLVAN,
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Фейский шаг',
      restore: 4,
      description: `Бонусным действием эладрин может телепортироваться на расстояние до 30 фт. в незанятое пространство, которое он может видеть.`,
    },
    {
      name: 'Скорбное присутствие',
      description: `Любое существо, не являющееся эладрином и начинающее свой ход в пределах 60 футов от зимнего эладрина, должно пройти испытание Мудрости СЛ 13. При провале существо очаровывается эладрином на 1 минуту. Пока существо очаровано таким образом, оно получает помехи на проверки способностей и испытания. Существо может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе. При успехе существо становится невосприимчивым к _Скорбному присутствию_ всех эладринов на 24 часа.

Каждый раз, когда эладрин причиняет урон очарованному существу, оно может повторить испытание, оканчивая эффект при успехе.`,
    },
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_INT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_FOG_CLOUD,
          SPELL_GUST_OF_WIND,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONE_OF_COLD,
          SPELL_ICE_STORM,
        ],
      },
    ],
  },
  actionList: [
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      gearId: GEAR_LONGBOW,
    },
  ],
  reactionList: [
    {
      name: 'Ледяное возмездие',
      description: `Когда эладрин получает урон от существа, которое он может видеть, находящегося в пределах 60 футов от него, он может заставить это существо пройти испытание Телосложения СЛ 16. При провале существо получит 11 (2к10) урона холодом.`,
    },
  ],
}
