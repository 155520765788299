const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {
  PARAM_STR,
  PARAM_DEX,
} = require('./../../../../../paramList')
const {CONDITION_RESTRAINED} = require('./../../../../../conditionList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_TRANSMUTATION} = require('./../../../../../magicList')
const {SPELL_TRANSMUTE_ROCK} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_TRANSMUTE_ROCK,
  name: 'Преобразование камня',
  nameEn: 'Transmute Rock',
  description: `Вы выбираете область камня или грязи в 40-футовом кубе, которую Вы можете видеть в пределах дистанции заклинания, а затем выбираете один из следующих эффектов:

## Преобразование камня в грязь

Любой камень немагического происхождения в области превращается в равный объём густой, текучей грязи, которая остаётся в таком состоянии, пока действует заклинание. Если Вы сотворяете это заклинание на поверхность земли, она становится достаточно зыбкой, чтобы существа могли в ней утонуть. Каждый фут передвижения в этой области обходится в 4 фута перемещения. Все существа, стоявшие в этой области в момент накладывания заклинания, должны пройти испытание Силы. Существа также должны проходить это испытание, когда впервые за ход оказываются в этой области или заканчивают свой ход в ней. При провале существо погружается в грязь и считается обездвиженым, но может действием освободиться и перестать быть обездвиженым. Если Вы сотворяете это заклинание на потолок, грязь обваливается вниз. Все существа, оказавшиеся внизу, должны пройти испытание Ловкости. При провале они получают дробящий урон 4к8, а при успехе — половину этого урона.

## Преобразование грязи в камень

Любая грязь или зыбучий песок немагического происхождения глубиной не более 10 футов превращаются в мягкий камень на время действия заклинания. Все существа, находившиеся в грязи в момент накладывания заклинания, должны пройти испытание Ловкости. При провале застывший камень делает существо обездвиженым. Обездвиженое существо может действием попытаться освободиться, если совершит успешную проверку Силы (Сл 20) или причинит 25 урона камню вокруг себя. В случае успешного испытания существо выбирается из камня в ближайшее свободное пространство. Обездвиженое существо или иное существо в пределах досягаемости могут использовать действие, чтобы попытаться разломать камень, совершив успешную проверку Силы СЛ 20, или нанося камню урон. У камня 25 хитов и 15 КД, а также иммунитет к урону психической энергией и урону ядом.`,
  lvl: 5,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 120,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `глина и вода`,
  duration: Infinity,
  effect: [
    {
      savethrowParam: PARAM_STR,
      condition: CONDITION_RESTRAINED,
    },
    {
      savethrowParam: PARAM_DEX,
      condition: CONDITION_RESTRAINED,
    },
    {
      savethrowParam: PARAM_DEX,
      damage: {
        type: DAMAGE_BLUDGEONING,
        halfOnSuccess: true,
        diceCount: 4,
        diceType: 8,
      },
    },
  ],
  source: [
    {
      id: SOURCE_XGTE,
      page: 163,
    },
    {
      id: SOURCE_EE,
      page: 21,
    },
  ],
}
