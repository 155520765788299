const sortByName = require('./../../../../../../utils/sortByName')
const {creatureCollection} = require('./../../../../../creatureList')

const generateCreatureTable = (idList) => idList
  .map(
    creatureId => ({
      creatureId,
      name: creatureCollection[creatureId].name,
    })
  )
  .sort(sortByName)
  .reduce(
    (tableText, { creatureId, name }, i) => `${tableText}| ${i + 1} | [${name}](CREATURE:${creatureId}) |\n`,
    `| к8 | Существо |
|----|----------|
`
  )

module.exports = generateCreatureTable
