const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  VOLUME_UNIT_OUNCE,
} = require('./../../../volumeUnitList')
const {
  SOURCE_PHB,
} = require('./../../../sourceList')
const {
  CAT_CONTAINERS,
} = require('./../../../gearCategoryList')
const {
  GEAR_VIAL,
} = require('./../../../gearIdList')

module.exports = {
  id: GEAR_VIAL,
  genderId: GENDER_MALE,
  name: 'Пузырёк',
  nameByCase: {
    nominative: 'Пузырёк',
    accusative: 'Пузырёк',
    instrumental: 'Пузырьком',
  },
  nameAlt: 'Флакон',
  nameEn: 'Vial',
  cost: 100,
  weight: 0,
  volume: {
    unitId: VOLUME_UNIT_OUNCE,
    count: 4,
  },
  source: {
    id: SOURCE_PHB,
    page: 150,
  },
  category: CAT_CONTAINERS,
}
