const {GEAR_SPELLBOOK} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_SCROLL} = require('./../../../../../magicItemTypeList')
const {PC_CLASS_WIZARD} = require('./../../../../../pcClassIdList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {
  MAGIC_ITEM_SPELL_SCROLL_0,
  MAGIC_ITEM_SPELL_SCROLL_1,
  MAGIC_ITEM_SPELL_SCROLL_2,
  MAGIC_ITEM_SPELL_SCROLL_3,
  MAGIC_ITEM_SPELL_SCROLL_4,
  MAGIC_ITEM_SPELL_SCROLL_5,
  MAGIC_ITEM_SPELL_SCROLL_6,
  MAGIC_ITEM_SPELL_SCROLL_7,
  MAGIC_ITEM_SPELL_SCROLL_8,
  MAGIC_ITEM_SPELL_SCROLL_9,
} = require('./../../../../../magicItemIdList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    attackBonus,
    id,
    lvl,
    rarity,
    saveThrowDc,
  }
) => ({
  id,
  name: lvl
    ? `Свиток заклинания ${lvl} круга`
    : `Свиток заговора`,
  nameEn: lvl
    ? `Spell Scroll, ${lvl} level`
    : `Spell Scroll, Cantrip`,
  type: MGC_TYPE_SCROLL,
  rarity,
  isConsumable: true,
  description: `Свиток заклинания несёт на себе слова одного заклинания ${lvl} круга, написанные таинственным шифром. Если это заклинание есть в списке заклинаний Вашего класса, Вы можете прочитать свиток и наложить его заклинание, не предоставляя материальные компоненты. В противном случае свиток непонятен для Вас. Накладывание заклинания свитком занимает столько же времени, сколько обычное накладывание заклинания. После того, как заклинание наложено, слова на свитке исчезают, а сам свиток рассыпается в прах. Если накладывание прервано, свиток остаётся целым.

Если заклинание есть в списке заклинаний Вашего класса, но имеет более высокий круг, чем те, что Вы способны сотворять, Вы должны совершить проверку базовой характеристики СЛ **1${lvl}**, чтобы определить, удалось ли Вам справиться с задачей. При провале заклинание просто исчезает со свитка, не произведя никаких эффектов.

СЛ испытания от этого заклинания **${saveThrowDc}**, а его бонус атаки **+${attackBonus}**. 

Если свиток содержит заклинание из списка заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), то оно может быть скопировано в [книгу заклинаний](GEAR:${GEAR_SPELLBOOK}). Для этого надо преуспеть в проверке Интеллекта (Магия) СЛ **1${lvl}**. Если проверка успешна, заклинание удаётся скопировать. Вне зависимости от исхода проверки свиток после этого уничтожается. 

# Свитки заклинаний

| Круг заклинания                                            | Редкость     | СЛ испытания | Бонус атаки |
|------------------------------------------------------------|--------------|--------------|-------------|
| [Свиток заговора](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_0}) | Обычная      | 13           | +5          |
| [Свиток 1 круга](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_1})  | Обычная      | 13           | +5          |
| [Свиток 2 круга](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_2})  | Необычная    | 13           | +5          |
| [Свиток 3 круга](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_3})  | Необычная    | 15           | +7          |
| [Свиток 4 круга](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_4})  | Редкая       | 15           | +7          |
| [Свиток 5 круга](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_5})  | Редкая       | 17           | +9          |
| [Свиток 6 круга](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_6})  | Очень редкая | 17           | +9          |
| [Свиток 7 круга](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_7})  | Очень редкая | 18           | +10         |
| [Свиток 8 круга](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_8})  | Очень редкая | 18           | +10         |
| [Свиток 9 круга](MAGIC_ITEM:${MAGIC_ITEM_SPELL_SCROLL_9})  | Легендарная  | 19           | +11         |
`,
  genderId: GENDER_MALE,
  siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 200,
  },
})

module.exports = generator
