const {FEATURE_TRICKS_UP_THE_SLEEVE} = require('./../../../featureIdList')
const {PC_SUBCLASS_ROGUE_WILD_CARD} = require('./../../../pcSubClassIdList')
const {SOURCE_LOR_DTOB} = require('./../../../sourceList')
const {SPELL_GUIDANCE} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_TRICKS_UP_THE_SLEEVE,
    name: `Козырь в рукаве`,
    nameEn: `Tricks Up the Sleeve`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ROGUE_WILD_CARD,
    spellIdList: SPELL_GUIDANCE,
    source: {
      id: SOURCE_LOR_DTOB,
      url: 'https://www.dndbeyond.com/sources/lrdtob/heroes-and-scoundrels-of-bilgewater#TricksUptheSleeve',
    },
  },
]
