const {SOURCE_MM} = require('./../../sourceList')
const {CREATURE_TYPE_DRAGON} = require('./../../creatureTypeIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_DRAGON,
  nameEn: 'Dragon',
  genderId: GENDER_MALE,
  description: {
    text: `Драконы — это большие рептилиеподобные существа древнего происхождения и огромной силы. Истинные драконы, в том числе и добрые металлические драконы, и злые цветные драконы, очень умны и обладают врождённой магией. Также к этой категории относятся существа, отдалённо связанные с истинными драконами, менее сильные и не наделённые магией, такие как виверны и псевдодраконы.`,
    source: {
      id: SOURCE_MM,
      page: 8,
    },
  },
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'дракон',
        genitive: 'дракона',
        dative: 'дракону',
        accusative: 'дракона',
        instrumental: 'драконом',
        prepositional: 'драконе',
      },
      [GENDER_FEMALE]: {
        nominative: 'драконица',
        genitive: 'драконицы',
        dative: 'драконице',
        accusative: 'драконицу',
        instrumental: 'драконицей',
        prepositional: 'драконице',
      },
    },
    plural: {
      nominative: 'драконы',
      genitive: 'драконов',
      dative: 'драконам',
      accusative: 'драконов',
      instrumental: 'драконами',
      prepositional: 'драконах',
    },
  },
}
