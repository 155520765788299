const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_IGNAN,
  LANG_TERRAN,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_MEPHIT,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_FALSE_APPEARANCE} = require('./../../../../creatureAbilityList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_1_2} = require('./../../../../crList')
const {CREATURE_MAGMA_MEPHIT} = require('./../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {mephitDescription} = require('./../../../../textCommonParts')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SKILL_STEALTH} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPELL_HEAT_METAL} = require('./../../../../spellIdList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Магмовый мефит',
  nameEn: 'Magma Mephit',
  description: [
    `Состоящие из земли и огня, магмовые мефиты светятся тусклым красным светом, так как их кожа покрыта каплями расплавленной лавы. Они тугодумы, и с трудом понимают значение слов или действий других существ.`,
    mephitDescription,
  ],
  id: CREATURE_MAGMA_MEPHIT,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
    CREATURE_TYPE_MEPHIT,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 205,
  },
  armor: 11,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 30,
  },
  params: {
    [PARAM_STR]: 8,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 12,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 10,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_COLD,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_IGNAN,
    LANG_TERRAN,
  ],
  cr: CR_1_2,
  featureList: [
    {
      name: 'Предсмертная вспышка',
      description: `Когда ★СУЩЕСТВО★ умирает, ★он★ разлетается во все стороны потоками лавы. Все существа в пределах 5 футов от него должны пройти испытание Ловкости СЛ 11, получая урон огнём 7 (2к6) при провале, или половину этого урона при успехе.`,
    },
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного наплыва магмы`,
    },
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_HEAT_METAL,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 1,
            diceType: 4,
            diceBonus: 1,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 4,
          },
        ],
      },
    },
    {
      name: 'Огненное дыхание',
      restore: 6,
      description: `★СУЩЕСТВО★ выдыхает пламя 15-футовым конусом. Все существа в этой области должны пройти испытание Ловкости СЛ 11, получая урон огнём 7 (2к6) при провале, или половину этого урона при успехе.`,
    },
  ],
  genderId: GENDER_MALE,
}
