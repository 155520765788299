const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CREATURE_TYPE_CONSTRUCT} = require('./../../../../creatureTypeIdList')
const {
  PC_RACE_AARAKOCRA,
  PC_RACE_ELF,
} = require('./../../../../pcRaceIdList')
const {
  CREATURE_GRIFFON,
  CREATURE_GRIFFON_CAVALRY_RIDER,
  CREATURE_STORM_GIANT,
  CREATURE_VAJRA_SAFAHR,
  CREATURE_WALKING_STATUE_OF_WATERDEEP,
} = require('./../../../../creatureIdList')
const {CR_18} = require('./../../../../crList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {
  MAGIC_ITEM_BLACKSTAFF,
  MAGIC_ITEM_VETERAN_S_CANE,
} = require('./../../../../magicItemIdList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_WDH} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_SIEGE_MONSTER,
  ABILITY_IMMUTABLE_FORM,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')

module.exports = {
  name: 'Ходячая статуя Глубоководья',
  nameAlt: 'Ходячая статуя Вотердипа',
  nameEn: 'Walking Statue of Waterdeep',
  id: CREATURE_WALKING_STATUE_OF_WATERDEEP,
  hasSpoilers: true,
  description: [
    {
      header: 'Ходячие статуя Глубоководья',
      text: `По Глубоководью разбросаны восемь исполинских статуй, призванных защитить город в час великой угрозы. Они настолько разрушительны, что их применяют только против вражеских армий и почти непобедимых врагов.

У каждой статуи своё название и уникальный облик, но параметры у них одинаковы. Статуя Девы Меча разрушена и не подлежит оживлению, а остальные семь может оживить лишь обладатель [Чёрного посоха](MAGIC_ITEM:${MAGIC_ITEM_BLACKSTAFF}).`,
      source: {
        id: SOURCE_WDH,
        page: 218,
      },
    },
    {
      header: 'Достопримечательности',
      text: `За долгие годы глубоководчане понастроили зданий вокруг статуй и прямо на них. В неживом состоянии статуи и правда неопасны — но соединённые с ними строения рухнут, как только статуи вновь оживут.`,
      source: {
        id: SOURCE_WDH,
        page: 218,
      },
    },
    {
      header: 'Природа конструкта',
      text: 'Ходячей статуи не нужны воздух, еда, питьё и сон.',
      source: {
        id: SOURCE_WDH,
        page: 218,
      },
    },
    {
      header: 'Ходячие статуя',
      text: `Сто с лишним лет назад лишь одна из этих исполинских статуй была видимой и стояла у северного подножия горы Глубоководье, на уступе Прыжка Чайки. Было в ней девяносто футов высоты, и она напоминала лысого человека, смотрящего в море. После известных событий она превратилась в ту, что ныне зовётся Смирный Сахуагин.

Когда в 1385 году ЛД на Глубоководье обрушилась Чума Заклинаний, в городе внезапно появилось ещё шесть ходячих статуй, которые стали бродить и крушить всё вокруг, и лишь Смирный Сахуагин остался неподвижен. Власти и горожане сумели остановить три новые статуи, разрушив Деву Меча и Человека-ястреба и утопив ногу Ловца Богов в землю. А затем все статуи прекратили атаку так жк внезапно и необъяснимо, как начали. Царра Чаадрен, тогдашний [Чёрный Посох](CREATURE:${CREATURE_VAJRA_SAFAHR}), так и не смогла вернуть их обратно в прежнее укрытие на Эфирном Плане. В итоге город отремонтировали и выстроили заново вокруг них. Много позже, в 1479 году ЛД, с Эфирного плана явилась восьмая статуя, Грифон, чтобы защитить от вторжения Башню Агерона. Какое-то время она сидела там, а потом переместилась на своё нынешнее место — у Гнезда на горе Глубоководье. И опять действия статуи оказались неподвластны [Чёрному Посоху](CREATURE:${CREATURE_VAJRA_SAFAHR}). К счастью, с тех пор ходячие статуи оставались неподвижны уже более десятилетия и служат лишь исполинскими памятниками прошлому Глубоководья.`,
      source: {
        id: SOURCE_WDH,
        page: 182,
      },
    },
    {
      header: 'Ловец богов',
      text: `Эта ходячая статуя, пожалуй, самая знаменитая в городе — благодаря драматичной позе, близости к Рынку и явно магическому происхождению. Она изображает мускулистого и бесстрастного человеческого мужчину, левая нога которого по бедро погрузилась в улицу — результат заклинания [Чёрного Посоха](CREATURE:${CREATURE_VAJRA_SAFAHR}). Левой рукой и правой ногой он опирается на землю, будто пытается выбраться. Правую руку он поднял к небесам, и над его распахнутой ладонью парит каменная сфера. Взгляд его направлен на сферу, а следы птичьего помёта вокруг глаз напоминают слёзы.

Прямо вокруг статуи, опираясь на её грудь, колено и плечи, ст оит доходный дом под названием «Ловец Богов». Хозяйка дома — Ондра Блэклок, необщительная чародейка, которую редко видят в городе. Лишь иногда она появляется из двери, вырезанной в парящей сфере, которая служит ей домом. В тех редких случаях, когда ей нужно встретиться с горожанами (обычно чтобы купить редкие ингредиенты для магических целей), она внезапно появляется на балконах или крышах после заката. Впрочем, общается она вежливо и платит щедро. Но она никому не доверяет и не говорит о своих делах — а если кто, кроме неё, и побывал в сферическом доме, то об этом публично не рассказывает.`,
      source: {
        id: SOURCE_WDH,
        page: 182,
      },
    },
    {
      header: 'Грифон',
      text: `Эта статуя зовётся Грифоном, потому что имеет форму [этого зверя](CREATURE:${CREATURE_GRIFFON}). Хоть она и стоит на четвереньках, но возвышается на целых двадцать фунтов от земли — она могла бы послужить скакуном [штормовому великану](CREATURE:${CREATURE_STORM_GIANT}). И хотя статуя доказала, что способна летать, как птица, раскинув гранитные крылья, сейчас Грифон просто замер в величественной позе у Гнезда на вершине горы Глубоководья и смотрит на юго-восток, на район доков. Приезжие часто принимают статую за памятник в четь [кавалерии грифонов](CREATURE:${CREATURE_GRIFFON_CAVALRY_RIDER}), но глубоководчане знают правду.`,
      source: {
        id: SOURCE_WDH,
        page: 183,
      },
    },
    {
      header: 'Смирный сахуагин',
      text: `Многие годы единственную видимую ходячую статую Глубоководья так и называли — «ходячая статуя». Она стояла у подножья горы Глубоководье в начале улицы Джальтун Затем она сыграла ключевую роль в защите города от вторжения сахуагинов в 1370 году ЛД, и Келбен Чёрный Посох придал ей облик сахуагина. Теперь она преклонила колено перед Домом Героев, выражая покорность городу и кланяясь тем, кто пожертвовал собой, защищая город в той войне.`,
      source: {
        id: SOURCE_WDH,
        page: 183,
      },
    },
    {
      header: 'Великий пьяница',
      text: `Эта ходячая статуя прекратила разрушения, когда дошла до Рынка, а затем опрокинулась назад и села на крышу здания. Руки её обвисли по бокам, а голова склонилась на грудь, будто статуя уснула. Её исполинский боевой топор из камня стоит поодаль: рукоять торчит вертикально, а лезвие наполовину погружено в мостовую. Из обломков разрушенного им здания выстроили широкую каменную лестницу (с перилами и пандусом, по которому часто скидывают пьяниц), ведущую с мостовой на колени статуи. На этих коленях теперь стоит двухэтажная таверна «Кружка Гралкина», выстроенная  из тех же обломков. Поза статуи и таверна на её коленях не оставили иного выбора — статую прозвали «Великий пьяница».`,
      source: {
        id: SOURCE_WDH,
        page: 183,
      },
    },
    {
      header: 'Спящая дама',
      text: `Эта прекрасная дама в своё время устроила настоящий хаос. Статуя выглядит как [эльфийка](PC_RACE:${PC_RACE_ELF}), чьи волосы и одежды будто развевались, когда она ходила по городу в дни Чумы Заклинаний. Когда статуи остановились, эта упала набок и теперь выглядит как титаническая скульптура благородной дамы, уснувшей у себя в саду.`,
      source: {
        id: SOURCE_WDH,
        page: 183,
      },
    },
    {
      header: 'Доблестный рыцарь',
      text: `Статуя воина-мужчины в латном доспехе со щитом м длинным мечом. Когда статуи остановились, эта поклонилась своим противникам, выпрямилась и опустила щит острым концом в землю рядом с собой. В таком положении она и замерла, лицом на юго-запад в сторону гавани, созерцая мир, будто стражник, чья смена окончена. Поза и внешность дали статуе имя, и теперь на неё с уважением смотрят жители южных районов.`,
      source: {
        id: SOURCE_WDH,
        page: 183,
      },
    },
    {
      header: 'Человек-ястреб',
      text: `Статуя выглядит как крылатое создание с головой ястреба, за что её так и прозвали. Могу уточнить, что на самом деле она похожа на [ааракокру](PC_RACE:${PC_RACE_AARAKOCRA}) — пернатый народ, который, по легендам, обитает в Звёздных горах в Высоком лесу. Крылья статуи сложены за спиной и ни разу не разворачивались, так что неизвестно, умеет ли она летать. Её победили во время разгрома города, и теперь она кренится на северо-восток, потому что правой ступни у неё не хватает — её давным-давно разобрали на строительные материалы, как и правую руку. Левая рука статуи вытянута на север, а ладонь выставлена в жесте, который будто говорит «Стой».

 В теле статуи вырезали полости и превратили её в башню, где обитают несколько зажиточных жильцов. Официально она называется Башней Спаронта в честь владельца. Левая ладонь нависает над двором на севере, где находится вход в туннель, прорезанный в руке. Гости и жители  звонят в колокольчик во дворе, и если привратник признаёт звонившего, он спускает верёвочную лестницу (или кресло на верёвках для гостей, которым трудно подниматься, или несущих тяжёлый груз).`,
      source: {
        id: SOURCE_WDH,
        page: 183,
      },
    },
    {
      header: 'Дева меча',
      text: `Эта статуя на вид почти не отличалась от Доблестного рыцаря, не считая того, что изображала женщину, а шлем у неё с открытым лицом. Её победили в дни Чумы Заклинаний после того, как она устроила сущий хаос и резню. Разгневанные жители Северного района выместили на ней обиду, разбив статую. Её части теперь можно найти по всему району — какие-то стали частями зданий, а какие-то установлены как скульптуры.

Голова ДЕвы меча стоит среди высоких деревьев в центре квартала, окружённого улицами Хассантира и Тарсара, проездом Вейлгонда и улицей Уссильбран. Вместо рта и середины подбородка у неё теперь дверь, ведущая в лавку «Находки Торта». Ундеввур Торт — пожилой искатель приключений в отставке, который ходит с тросточкой (впрочем, среди местных ходят слухи, что [тросточка непростая](MAGIC_ITEM:${MAGIC_ITEM_VETERAN_S_CANE})). Он живёт в своей лавке, помещения, лестницы и площадки которой заполняют внутренности головы. Лавка завалена диковинами, которые Торт покупает у искателей приключений и прочих путешественников. К предметам прилагаются ярлыки, на которых ровным и красивым почерком Торма подписано, что это такое (или хотя бы его предположения, что это может быть). Богатые купцы и благородные, которым нужен реквизит для тематических вечеринок, нередко арендуют товары Торта. А многие мудрецы, алхимики и волшебники часто посещают его в поисках потенциально полезных предметов.`,
      source: {
        id: SOURCE_WDH,
        page: 183,
      },
    },
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_WDH,
    page: 218,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 60,
  },
  params: {
    [PARAM_STR]: 30,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 27,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 1,
  },
  saveThrowList: [
    PARAM_CON,
  ],
  immunityList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_NONMAGIC_NONADAMANTINE_WEAPON,
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  cr: CR_18,
  featureList: [
    ABILITY_SIEGE_MONSTER,
    ABILITY_IMMUTABLE_FORM,
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Падение колосса',
      nameEn: 'Crumbling Colossus',
      description: `Когда хиты ★СУЩЕСТВО★ снижаются до 0, ★он★ разваливается и уничтожается. Все существа в пределах 30 футов от ★рушащегося★ ★СУЩЕСТВО★ должны пройти испытание Ловкости СЛ 22, получая 22 (4к10) дробящего урона при провале или половину при успехе.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 12,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Метание камня',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 16,
        range: {
          normal: 200,
          max: 800,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 6,
          diceType: 10,
          diceBonus: 10,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
