const {
  SOURCE_MOOT,
} = require('./../../../../sourceList')
const {
  CR_1,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_ANVILWROUGHT,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
  SPEED_CLIMB,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_ONE_OF_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_FALSE_APPEARANCE,
  ABILITY_PACK_TACTICS,
  ABILITY_SURPRISE_ATTACK,
} = require('./../../../../creatureAbilityList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  anvilwroughtDescription,
} = require('./../../../../textCommonParts')
const {
  CREATURE_BRONZE_SABLE,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Бронзовый соболь',
  nameEn: 'Bronze Sable',
  id: CREATURE_BRONZE_SABLE,
  description: [
    {
      header: 'Бронзовый соболь',
      text: `Первые бронзовые соболя был подарены Караметре Пирфором для охраны её храмов. Но некоторые из соболей, созданных позже, использовались в качестве ловких разведчиков, всегда готовых к опасности.`,
      source: {
        id: SOURCE_MOOT,
        page: 211,
      },
    },
    anvilwroughtDescription,
  ],
  note: {
    text: `Поборница в одиночестве защищала святилище Караметры от орды Возвращённых, сражая десятки врагов, но её окружали сотни. Она бы пала, если бы не помощь защитников святилища, которых пробудила Караметра.`,
    author: `Териада`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ANVILWROUGHT,
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MOOT,
    page: 211,
  },
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 39,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 1,
  },
  skillCollection: {
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ONE_OF_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_1,
  featureList: [
    ABILITY_PACK_TACTICS,
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычной статуи`,
    },
    {
      id: ABILITY_SURPRISE_ATTACK,
      damage: `10 (3к6)`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Укусом_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
  ],
}
