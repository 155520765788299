const {PC_SUBCLASS_DRUID_CIRCLE_OF_STARS_2020_02_24} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_02_24_SUBCLASSES_PART_3} = require('./../../../sourceList')
const {
  SPELL_AUGURY,
  SPELL_GUIDING_BOLT,
} = require('./../../../spellIdList')
const {
  FEATURE_STAR_MAP_2020_02_24,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_STAR_MAP_2020_02_24,
    name: `Звёздная карта`,
    nameEn: `Star Map`,
    lvl: 2,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_STARS_2020_02_24,
    spellIdList: [
      SPELL_AUGURY,
      SPELL_GUIDING_BOLT,
    ],
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 3,
    },
  },
]
