const {
  CREATURE_TYPE_KRUTHIK,
  CREATURE_TYPE_MONSTROSITY,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_CLIMB,
  SPEED_DIG,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SENSE_DARK_VISION,
  SENSE_VIBRATION_SENSE,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  kruthikDescription,
  kruthikNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_KEEN_SMELL,
  ABILITY_PACK_TACTICS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_KRUTHIK_ADULT,
  CREATURE_KRUTHIK_YOUNG,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_8} = require('./../../../../crList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {LANG_KRUTHIK} = require('./../../../../languageIdList')
const {SIZE_SMALL} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Молодой крутик',
  nameEn: 'Young Kruthik',
  id: CREATURE_KRUTHIK_YOUNG,
  description: [
    `Крутики вылупляются из яиц, отложенных [самками](CREATURE:${CREATURE_KRUTHIK_ADULT}). Каждое яйцо, размером с голову взрослого человека, вылупляется в течение месяца. Крошечные крутики-детёныши безвредны, редко отходят далеко от гнезда и питаются, в основном, различными отходами и друг другом. В течение месяца оставшиеся в живых становятся молодыми крутиками, достаточно большими, чтобы самостоятельно охотиться и защищаться.`,
    kruthikDescription,
  ],
  note: kruthikNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
    CREATURE_TYPE_KRUTHIK,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MTOF,
    page: 203,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_DIG]: 10,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 13,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 60,
    },
  ],
  languageList: [
    LANG_KRUTHIK,
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_KEEN_SMELL,
    ABILITY_PACK_TACTICS,
    {
      name: 'Прокладывание туннеля',
      description: `Крутик может копать сквозь сплошной камень со скоростью копания, уменьшенной вдвое, оставляя за собой туннель диаметром 2,5 фута.`,
    },
  ],
  actionList: [
    {
      name: 'Укол',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
