const {
  CREATURE_AIR_ELEMENTAL,
  CREATURE_DRAGON_BLUE_ANCIENT,
  CREATURE_DUST_DEVIL,
} = require('./../../creatureIdList')

module.exports = `Регион, в котором есть логово легендарного [синего дракона](CREATURE:${CREATURE_DRAGON_BLUE_ANCIENT}), искажается драконьей магией, которая создаёт один или несколько следующих эффектов:

* В пределах 6 миль от логова постоянно бушуют грозы.
* [Пыльные дьяволы](CREATURE:${CREATURE_DUST_DEVIL}) заселяют земли в пределах 6 миль от логова. [Пыльные дьяволы](CREATURE:${CREATURE_DUST_DEVIL}) используют статистику [воздушного элементаля](CREATURE:${CREATURE_AIR_ELEMENTAL}), но не могут летать, их скорость равна 50 футам, а Интеллект и Харизма равны 1 (−5). 
* Спрятанные провалы образовываются внутри драконьего логова и вокруг него. Провал можно заметить заранее, преуспев в проверке Мудрости (Внимательность) СЛ 20. В противном случае первое существо, ступившее на тонкую корочку, укрывающую провал, должно пройти испытание Ловкости СЛ 15, иначе упадёт в яму глубиной 1к6 × 10 футов.

Если дракон умирает, [пыльные дьяволы](CREATURE:${CREATURE_DUST_DEVIL}) немедленно исчезают, а грозы утихают за 1к10 дней. Созданные провалы остаются на своих местах.`
