const upLetter = require('./../../../../../../utils/upLetter')
const {damageTypeCollection} = require('./../../../../../damageTypeList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_POTION} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    id,
    damageTypeId,
  }
) => {
  const { nameByCase: {dative: name}, fullNameByCase: {dative: fullName}, nameEn } = damageTypeCollection[damageTypeId]

  return {
    id,
    name: `Зелье сопротивления ${name}`,
    nameEn: `Potion of Resistance to ${upLetter(nameEn)}`,
    type: MGC_TYPE_POTION,
    rarity: MGC_RARITY_UNCOMMON,
    isConsumable: true,
    description: `Когда Вы выпиваете это зелье, Вы получаете сопротивление к **${fullName}** на 1 час.`,
    genderId: GENDER_MIDDLE,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 166,
    },
  }
}
