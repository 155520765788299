const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {MAGIC_ITEM_QUAALS_FEATHER_TOKEN} = require('./../../../../../magicItemIdList')
const {
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_ANCHOR,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_BIRD,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_FAN,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_SWAN_BOAT,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_TREE,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_WHIP,
} = require('./../../../../../magicItemIdList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN,
  name: `Перо Кваля`,
  nameEn: `Quaal’s Feather Token`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_RARE,
  isConsumable: true,
  description: `Этот маленький предмет выглядит как перо.

Существует несколько разновидностей таких предметов, и каждый из которых обладает своим особым эффектом, используемым один раз. Мастер сам определяет разновидность пера или определяет её случайным образом. 

| к100  | Эффект                                                       | к100  | Эффект                                                                  |
|-------|--------------------------------------------------------------|-------|-------------------------------------------------------------------------|
| 01–15 | [Веер](MAGIC_ITEM:${MAGIC_ITEM_QUAALS_FEATHER_TOKEN_FAN})    | 51–65 | [Лодка-лебедь](MAGIC_ITEM:${MAGIC_ITEM_QUAALS_FEATHER_TOKEN_SWAN_BOAT}) |
| 16–40 | [Дерево](MAGIC_ITEM:${MAGIC_ITEM_QUAALS_FEATHER_TOKEN_TREE}) | 66–80 | [Птица](MAGIC_ITEM:${MAGIC_ITEM_QUAALS_FEATHER_TOKEN_BIRD})             |
| 41–50 | [Кнут](MAGIC_ITEM:${MAGIC_ITEM_QUAALS_FEATHER_TOKEN_WHIP})   | 81–00 | [Якорь](MAGIC_ITEM:${MAGIC_ITEM_QUAALS_FEATHER_TOKEN_ANCHOR})           |`,
  genderId: GENDER_MIDDLE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 189,
  },
}
