const {
    SOURCE_TOA,
  } = require('./../../../../sourceList'),
  {
    CR_1_4,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_UNDEAD,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
  } = require('./../../../../conditionList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_UNDEAD_FORTITUDE,
  } = require('./../../../../creatureAbilityList'),
  {
    yellowMuskDescription,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_YELLOW_MUSK_ZOMBIE,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Жёлтый мускусный зомби',
  nameEn: 'Yellow Musk Zombie',
  id: CREATURE_YELLOW_MUSK_ZOMBIE,
  description: yellowMuskDescription,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_TOA,
    page: 224,
  },
  armor: 9,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 9,
    [PARAM_CON]: 12,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 3,
  },
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
  ],
  cr: CR_1_4,
  featureList: [
    {
      id: ABILITY_UNDEAD_FORTITUDE,
      damageType: `огнём`,
    },
  ],
  actionList: [
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 1,
        },
      },
    },
  ],
}
