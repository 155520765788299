const {
  SPELL_BURNING_HANDS,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CONTROL_WATER,
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_FIREBALL,
  SPELL_GUST_OF_WIND,
  SPELL_ICE_STORM,
  SPELL_STONE_SHAPE,
  SPELL_STONESKIN,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_ICE,
  SPELL_WALL_OF_STONE,
  SPELL_WIND_WALL,
} = require('./../../../../../spellIdList')
const {
  CREATURE_AIR_ELEMENTAL,
  CREATURE_EARTH_ELEMENTAL,
  CREATURE_FIRE_ELEMENTAL,
  CREATURE_WATER_ELEMENTAL,
} = require('./../../../../../creatureIdList')
const {
  LANG_AQUAN,
  LANG_AURAN,
  LANG_IGNAN,
  LANG_TERRAN,
} = require('./../../../../../languageIdList')
const {
  CREATURE_TYPE_ELEMENTAL_AIR,
  CREATURE_TYPE_ELEMENTAL_EARTH,
  CREATURE_TYPE_ELEMENTAL_FIRE,
  CREATURE_TYPE_ELEMENTAL_WATER,
} = require('./../../../../../creatureTypeIdList')
const {
  MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_AIR,
  MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_EARTH,
  MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_FIRE,
  MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_WATER,
} = require('./../../../../../magicItemIdList')

const list = [
  {
    id: MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_AIR,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL_AIR,
    creatureId: CREATURE_AIR_ELEMENTAL,
    langId: LANG_AURAN,
    nameEn: `Air`,
    planeName: 'Воздуха',
    additionalBonus: 'если Вы падаете, Вы спускаетесь на 60 футов в раунд и не получаете урон от падения',
    killBonusList: [
      `Вы получаете сопротивление к урону электричеством.`,
      `Вы получаете скорость полёта, равную Вашей скорости ходьбы, и можете парить.`,
    ],
    spellList: [
      {
        id: SPELL_CHAIN_LIGHTNING,
        chargeCost: 3,
      },
      {
        id: SPELL_GUST_OF_WIND,
        chargeCost: 2,
      },
      {
        id: SPELL_WIND_WALL,
        chargeCost: 1,
      },
    ],
  },
  {
    id: MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_EARTH,
    nameEn: `Earth`,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL_EARTH,
    creatureId: CREATURE_EARTH_ELEMENTAL,
    langId: LANG_TERRAN,
    planeName: 'Земли',
    additionalBonus: 'Вы можете перемещаться по труднопроходимой местности, вызванной камнями, валунами или грязью, как если бы это была обычная местность',
    killBonusList: [
      `Вы получаете сопротивление к урону кислотой.`,
      `Вы можете перемещаться сквозь плотную землю или камень, как если бы они были труднопроходимой местностью. Если Вы оканчиваете ход в них, Вас выбрасывает в ближайшее свободное пространство, занимаемое Вами ранее.`,
    ],
    spellList: [
      {
        id: SPELL_STONE_SHAPE,
        chargeCost: 2,
      },
      {
        id: SPELL_STONESKIN,
        chargeCost: 3,
      },
      {
        id: SPELL_WALL_OF_STONE,
        chargeCost: 3,
      },
    ],
  },
  {
    id: MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_FIRE,
    nameEn: `Fire`,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL_FIRE,
    creatureId: CREATURE_FIRE_ELEMENTAL,
    langId: LANG_IGNAN,
    planeName: 'Огня',
    additionalBonus: 'Вы получаете сопротивление к урону огнём',
    killBonusList: [`Вы получаете иммунитет к урону огнём.`],
    spellList: [
      {
        id: SPELL_WALL_OF_FIRE,
        chargeCost: 3,
      },
      {
        id: SPELL_BURNING_HANDS,
        chargeCost: 1,
      },
      {
        id: SPELL_FIREBALL,
        chargeCost: 2,
      },
    ],
  },
  {
    id: MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_WATER,
    nameEn: `Water`,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL_WATER,
    creatureId: CREATURE_WATER_ELEMENTAL,
    langId: LANG_AQUAN,
    planeName: 'Воды',
    additionalBonus: 'Вы можете стоять на жидких поверхностях и ходить по ним, как если бы они были твёрдыми',
    killBonusList: [`Вы можете дышать под водой и получаете скорость плавания, равную скорости ходьбы.`],
    spellList: [
      {
        id: SPELL_CONTROL_WATER,
        chargeCost: 3,
      },
      {
        id: SPELL_ICE_STORM,
        chargeCost: 2,
      },
      {
        id: SPELL_WALL_OF_ICE,
        chargeCost: 3,
      },
      {
        id: SPELL_CREATE_OR_DESTROY_WATER,
        chargeCost: 1,
      },
    ],
  },
]

module.exports = list
