const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  SPELL_WATER_WALK,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_WATER_WALK,
  name: 'Хождение по воде',
  nameEn: 'Water Walk',
  description: `Это заклинание дарует способность перемещаться по жидкой поверхности — такой как вода, кислота, грязь, снег, зыбучий песок или лава — как если бы это была безвредная твёрдая поверхность (существа, идущие по жидкой лаве, всё равно получают урон от жара). До десяти согласных существ, видимых Вами в пределах дистанции, получают эту же способность на время действия заклинания.

Если Вы делаете целью существо, погружённое в жидкость, заклинание поднимает его на поверхность со скоростью 60 фт. в раунд. `,
  lvl: 3,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  isRitual: true,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: 'кусочек пробки',
  duration: { timeId: TIME_HOUR, count: 1 },
  source: [
    {
      id: SOURCE_PHB,
      page: 288,
    },
    {
      id: SOURCE_SRD,
      page: 191,
    },
  ],
}
