const {MGC_RARITY_COMMON} = require('./../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../magicItemTypeList')
const {gearCollection} = require('./../../../gearList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
  GENDER_MULTIPLE,
} = require('./../../../genderList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    gearId,
    id,
  }
) => {
  const {
    name,
    nameEn: gearNameEn,
    genderId,
  } = gearCollection[gearId]

  const {
    adjective,
    pronoun,
    pronoun3rdVerb,
    verb,
  } = {
    [GENDER_MALE]: {
      adjective: 'Дымящий',
      pronoun: 'этот',
      pronoun3rdVerb: 'он источает',
      verb: 'надет',
    },
    [GENDER_FEMALE]: {
      adjective: 'Дымящая',
      pronoun: 'эта',
      pronoun3rdVerb: 'она источает',
      verb: 'надета',
    },
    [GENDER_MIDDLE]: {
      adjective: 'Дымящее',
      pronoun: 'это',
      pronoun3rdVerb: 'оно источает',
      verb: 'надето',
    },
    [GENDER_MULTIPLE]: {
      adjective: 'Дымящие',
      pronoun: 'эти',
      pronoun3rdVerb: 'они источают',
      verb: 'надеты',
    },
  }[genderId]

  return {
    id,
    name: `${adjective} ${name.toLowerCase()}`,
    nameEn: `Smoldering ${gearNameEn}`,
    type: MGC_TYPE_ARMOR,
    gearType: gearId,
    rarity: MGC_RARITY_COMMON,
    description: `Когда ${pronoun} ${name.toLowerCase()} ${verb}, ${pronoun3rdVerb} клубы не имеющего запаха безвредного дыма.`,
    genderId,
    siblingIdList,
    source: {
      id: SOURCE_XGTE,
      page: 136,
    },
  }
}
