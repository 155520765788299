const {
  SPELL_BANISHMENT,
  SPELL_DARKNESS,
  SPELL_DISPEL_MAGIC,
  SPELL_GREATER_INVISIBILITY,
  SPELL_LEGEND_LORE,
  SPELL_LOCATE_OBJECT,
  SPELL_MAGE_HAND,
  SPELL_MINOR_ILLUSION,
  SPELL_PRESTIDIGITATION,
  SPELL_REMOVE_CURSE,
  SPELL_SUGGESTION,
  SPELL_TONGUES,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_PERCEPTION,
  SKILL_RELIGION,
} = require('./../../../../skillList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  LANG_COMMON,
  LANG_SPHINX,
} = require('./../../../../languageIdList')
const {
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  sphinxDescription,
  sphinxNote,
} = require('./../../../../textCommonParts')
const {
  ABILITY_MAGIC_WEAPONS,
  ABILITY_TELEPORT,
} = require('./../../../../creatureAbilityList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_11} = require('./../../../../crList')
const {CREATURE_GYNOSPHINX} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_MONSTROSITY} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Гиносфинкс`,
  nameEn: 'Gynosphinx',
  id: CREATURE_GYNOSPHINX,
  description: [
    `Тело гиносфинкса венчает голова гуманоидной женщины. Многие обладают благородными ликами земных цариц, но некоторые обладают дикими, львиными чертами. Глаза гиносфинкса взирают сквозь пространство и время, видя через завесы невидимости и магии. Гости, слишком глубоко заглянувшие в эти глаза, могут обнаружить себя магическим образом перенесёнными на какой-то далёкий план, где их ожидают сложные испытания.

Гиносфинксы являют собой кладези знаний и легенд. Они загадывают загадки и головоломки, чтобы проверить смекалку своих гостей, которые приходят вызнать их секреты. Некоторые из них готовы заключить с такими гостями сделку в обмен на сокровища или услуги.`,
    ...sphinxDescription,
  ],
  note: sphinxNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MM,
    page: 278,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 60,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 16,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 18,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_HISTORY]: PROF_DOUBLE,
    [SKILL_ARCANA]: PROF_DOUBLE,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_CHARMED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_SPHINX,
  ],
  cr: CR_11,
  featureList: [
    {
      name: `Непостижимая`,
      description: `★СУЩЕСТВО★ обладает иммунитетом ко всем эффектам, которые чувствуют ★его★ эмоции или читают мысли, а также заклинаниям школы Прорицания, неугодным ★ей★. Проверки Мудрости (Проницательность), совершённые для определения ★его★ намерений и искренности, совершаются с помехой.`,
    },
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    spellCasterLevel: 9,
    spellCasterClass: PC_CLASS_WIZARD,
    componentExclude: CAST_MATERIAL,
    spellIdList: [
      SPELL_BANISHMENT,
      SPELL_DARKNESS,
      SPELL_DISPEL_MAGIC,
      SPELL_GREATER_INVISIBILITY,
      SPELL_LEGEND_LORE,
      SPELL_LOCATE_OBJECT,
      SPELL_MAGE_HAND,
      SPELL_MINOR_ILLUSION,
      SPELL_PRESTIDIGITATION,
      SPELL_REMOVE_CURSE,
      SPELL_SUGGESTION,
      SPELL_TONGUES,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      2,
      1,
    ],
  },
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает две атаки _Когтем_.`,
    },
    {
      name: `Коготь`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 4,
        },
      },
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: `Атака когтем`,
      description: `★СУЩЕСТВО★ совершает одну атаку _Когтем_.`,
    },
    {
      id: ABILITY_TELEPORT,
      cost: 2,
      range: 120,
    },
    {
      name: `Накладывание заклинания`,
      cost: 3,
      description: `★СУЩЕСТВО★ сотворяет заклинание из списка подготовленных заклинаний, используя ячейку заклинаний, как обычно.`,
    },
  ],
  genderId: GENDER_FEMALE,
}
