const {ABILITY_UNYIELDING} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {CR_4} = require('./../../../../crList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_COMMON} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {blackEarthDescription} = require('./../../../../textCommonParts')
const {
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ANIMAL_HANDLING,
  SKILL_ATHLETICS,
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  GEAR_PLATE_ARMOR,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_BULETTE,
  CREATURE_BURROWSHARK,
  CREATURE_BLACK_EARTH_GUARD,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Подземная акула',
  nameEn: 'Burrowshark',
  id: CREATURE_BURROWSHARK,
  description: [
    {
      header: 'Подземные акулы',
      text: `Подземные акулы — это элитные воины культа земли, свирепость которых не знает себе равных. Они врываются в бой верхом на своих ездовых [панцирницах](CREATURE:${CREATURE_BULETTE}), а затем, пока эти могучие создания разрывают врагов на куски, спрыгивают на землю и безжалостно уничтожают противников.

Подземные акулы весьма напоминают [стражей Чёрной Земли](CREATURE:${CREATURE_BLACK_EARTH_GUARD}), так как оба этих рода войск имеют сверхъестественные способности, позволяющие им оставаться на ногах даже после самых тяжёлых ударов, и носят особые доспехи. В дополнение к этим преимуществам Огремох даровал подземным акулам возможность устанавливать магическую связь с [панцирницами](CREATURE:${CREATURE_BULETTE}), позволяя всаднику зарываться в землю вместе со своим скакуном и чувствовать то же, что и его скакун.`,
      source: {
        id: SOURCE_PotA,
        page: 201,
      },
    },
    blackEarthDescription,
  ],
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMAN,
    CREATURE_TYPE_HUMANOID,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_PotA,
    page: 201,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 11,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 13,
  },
  skillCollection: {
    [SKILL_ANIMAL_HANDLING]: PROF_NORMAL,
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_COMMON,
  ],
  cr: CR_4,
  featureList: [
    {
      name: 'Узы Чёрной Земли',
      description: `★СУЩЕСТВО★ имеет магическую связь со своей прирученной ездовой [панцирницей](CREATURE:${CREATURE_BULETTE}). Когда ★СУЩЕСТВО★ находится верхом на своей [панцирнице](CREATURE:${CREATURE_BULETTE}), ★он★ разделяет её восприятие и может ехать на ней, когда та зарывается в землю. Привязанная таким образом [панцирница](CREATURE:${CREATURE_BULETTE}) подчиняется командам ★СУЩЕСТВО★. Если ★его★ [панцирница](CREATURE:${CREATURE_BULETTE}) умирает, ★СУЩЕСТВО★ может обучить новую за один месяц.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три рукопашные атаки.`,
    },
    {
      gearId: GEAR_SPEAR,
    },
  ],
  reactionList: [
    ABILITY_UNYIELDING,
  ],
  genderId: GENDER_FEMALE,
}
