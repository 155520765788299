const {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_DEAFENED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PRONE,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ABILITY_AMORPHOUS,
  ABILITY_SPIDER_CLIMB,
} = require('./../../../../creatureAbilityList')
const {
  oozeDescriptionList,
  oozeNote,
} = require('./../../../../textCommonParts')
const {ALIGNMENT_NO} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_2} = require('./../../../../crList')
const {CREATURE_OCHRE_JELLY} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_OOZE} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: `Золотистый студень`,
  nameAlt: `Охристое желе`,
  nameEn: 'Ochre Jelly',
  id: CREATURE_OCHRE_JELLY,
  description: [
    `Золотистый студень — это желтоватое пятно слизи, проникающее под дверями и через щели в поисках существ для поглощения. Он обладает достаточным звериным разумом и избегает больших скоплений врагов.

Золотистый студень преследует свою жертву на безопасном расстоянии. Его пищеварительные ферменты быстро растворяют плоть, но не воздействуют на такие материалы как кость, древесина и металл.`,
    ...oozeDescriptionList,
  ],
  note: oozeNote,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_OOZE,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MM,
    page: 269,
  },
  armor: 8,
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 10,
    [SPEED_CLIMB]: 10,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 6,
    [PARAM_CON]: 14,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 1,
  },
  resistanceList: [
    DAMAGE_ACID,
  ],
  immunityList: [
    DAMAGE_SLASHING,
    DAMAGE_ELECTRICITY,
  ],
  immunityConditionList: [
    CONDITION_DEAFENED,
    CONDITION_FRIGHTENED,
    CONDITION_EXHAUSTION,
    CONDITION_BLINDED,
    CONDITION_CHARMED,
    CONDITION_PRONE,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
      isBlindOutside: true,
    },
  ],
  cr: CR_2,
  featureList: [
    ABILITY_AMORPHOUS,
    ABILITY_SPIDER_CLIMB,
  ],
  actionList: [
    {
      name: `Ложноножка`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 6,
            diceBonus: 2,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 1,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  reactionList: [
    {
      name: `Разделение`,
      description: `Если ★СУЩЕСТВО★ с размером не меньше Среднего получает рубящий урон или урон электричеством, ★он★ разделяется на два новых студня, если у ★него★ есть как минимум 10 хитов. У каждого нового студня хитов в два раза меньше, чем у исходного, округляя в меньшую сторону. Новые студни на один размер меньше чем исходный.`,
    },
  ],
  genderId: GENDER_MALE,
}
