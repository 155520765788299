const upLetter = require('./../../../../../../utils/upLetter')
const {damageTypeCollection} = require('./../../../../../damageTypeList')
const {GENDER_MIDDLE} = require('./../../../../../genderList')
const {MGC_RARITY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_RING} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

const generator = ({id, damageTypeId, gemName}) => {
  const { nameByCase: {dative: name}, fullNameByCase: {dative: fullName}, nameEn } = damageTypeCollection[damageTypeId]

  return {
    id,
    name: `Кольцо сопротивления ${name}`,
    nameEn: `Ring of Resistance to ${upLetter(nameEn)}`,
    type: MGC_TYPE_RING,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `В кольце есть драгоценный камень: **${gemName}**.

Вы обладаете сопротивлением к **${fullName}**, пока носите это кольцо`,
    genderId: GENDER_MIDDLE,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 177,
    },
  }
}

module.exports = generator
