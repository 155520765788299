const {CREATURE_BERSERKER} = require('./../../../../../creatureIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = (
  {
    berserkerCount,
    condition = '',
    id,
    name,
    nameEn,
    rarity,
  }
) => {
  const conditionText = condition
    ? `Если Вы подуете в рог, не **${condition}**, призванные берсерки нападут на Вас. Если Вы выполняете требования, они будут дружественны к Вам и Вашим спутникам, и будут выполнять Ваши команды.`
    : ``

  return {
    id,
    name,
    nameEn,
    rarity,
    type: MGC_TYPE_WONDROUS_ITEM,
    description: `Вы можете действием подуть в этот рог. После этого в пределах 60 футов от Вас появляются **${berserkerCount}** духов воителей с плана Асгард. Эти духи используют статистику [берсерков](CREATURE:${CREATURE_BERSERKER}) из Бестиария. Они возвращаются в Асгард через 1 час, или когда их хиты опускаются до 0. Рог нельзя использовать повторно, пока не пройдёт 7 дней.

${conditionText}`,
    genderId: GENDER_MALE,
    siblingIdList,
    source: {
      id: SOURCE_DMG,
      page: 198,
    },
  }
}
