const {GENDER_MIDDLE} = require('./../../genderList')
const {CREATURE_TYPE_STAR_SPAWN} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_STAR_SPAWN,
  nameEn: 'Star Spawn',
  genderId: GENDER_MIDDLE,
  hideFromStatBlock: true,
  name: {
    singular: {
      nominative: 'порождение звёзд',
      genitive: 'порождения звёзд',
      dative: 'порождению звёзд',
      accusative: 'порождение звёзд',
      instrumental: 'порождением звёзд',
      prepositional: 'порождении звёзд',
    },
    plural: {
      nominative: 'порождения звёзд',
      genitive: 'порождений звёзд',
      dative: 'порождениям звёзд',
      accusative: 'порождений звёзд',
      instrumental: 'порождениями звёзд',
      prepositional: 'порождениях звёзд',
    },
  },
}
