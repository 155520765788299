const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_DRUID,
  CREATURE_TREANT,
  CREATURE_WOOD_WOAD,
} = require('./../../../../creatureIdList')
const {
  GEAR_CLUB,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
  ABILITY_PLANT_CAMOUFLAGE,
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_5} = require('./../../../../crList')
const {CREATURE_TYPE_PLANT} = require('./../../../../creatureTypeIdList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_SYLVAN} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')

module.exports = {
  name: 'Лесная Вайда',
  nameEn: 'Wood Woad',
  id: CREATURE_WOOD_WOAD,
  description: [
    `Лесная Вайда — могущественное растение, обладающее формой гуманоида, в которое вселилась душа кого-либо, отдавшего жизнь, чтобы стать вечным стражем.`,
    {
      header: 'Рожденные жертвой',
      text: `Ритуал создания лесной вайды — это первобытный секрет, передающийся из поколения в поколение в диких обществах и кругах тёмных [друидов](CREATURE:${CREATURE_DRUID}). Выполнение ритуала это не обязательно злое деяние, если будущая жертва пошла на сделку, которая требует добровольного пожертвования себя.

В ритуале пронзается грудь существа и удаляется сердце. После этого в сердце засовывают семя и помещают в дерево. Подойдет любое дупло или пустота, но часто в стволе вырезается специальная полость. После этого дерево омывается и окропляется кровью жертвы, а тело зарывается у его корней. Через три дня из земли у основания появляется росток и быстро вырастает в гуманоидную форму.

Это новое тело, бронированное крепкой корой и снаряжённое корявой дубинкой и щитом, сразу же готово выполнять свой долг. Тот, кто проводил ритуал даёт лесной вайде задание, и существо выполняет эти приказы беспрестанно.`,
      source: {
        id: SOURCE_VGTM,
        page: 167,
      },
    },
    {
      header: 'Безжалостные защитники',
      text: `У лесной вайды дыра там, где должно быть сердце, так же как и её прежнего тела, зарытого в землю. Те, кто становятся лесными вайдами, обменивают свободную волю и все сентиментальные чувства на сверхъестественную силу и вечный долг. Они существуют только для того, чтобы защищать леса и тех, кто за ними ухаживает. Лицо лесной вайды пустое и без выражения, кроме сгустков света, парящих в глазницах. Лесные вайды мало разговаривают, и пока их не призвали к действию, они прорастают корнями в землю и подпитываются из неё.`,
      source: {
        id: SOURCE_VGTM,
        page: 167,
      },
    },
    {
      header: 'Выкорчеванное бессмертием',
      text: `Как и дереву, лесной вайде нужен лишь солнечный свет, воздух и питательные вещества из земли, чтобы жить. Благодаря своей нежизни, некоторые лесные вайды переживают свою изначальную цель. Место, охраняемое лесными вайдами может потерять свою силу или значение со временем, или те, кто отдавал им приказы, могут умереть. Освобождённая от конкретных обязанностей, лесная вайда может бродить в поисках другого места естественной красоты или места влияния фей, чтобы охранять его.

Лесную вайду привлекают существа, которые близки с природой, которые защищают и уважают землю, такие как [друиды](CREATURE:${CREATURE_DRUID}) и [тренты](CREATURE:${CREATURE_TREANT}). У некоторых трентов есть слуги лесные вайды, полученные за счёт старинных договоров с друидами или феями, которые выполняли ритуал, другим же помогают освобождённые лесных вайды, нашедшие новую цель в охране родственных существ.`,
      source: {
        id: SOURCE_VGTM,
        page: 167,
      },
    },
  ],
  note: {
    text: `Стойкие стражи, которые мало разговаривают. Лесные Вайды — превосходные соседи. Если только ты не дровосек`,
    author: `Эльминстер`,
  },
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_VGTM,
    page: 167,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL, gearId: GEAR_SHIELD,
  },
  hp: {
    diceCount: 10,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 16,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_SYLVAN,
  ],
  cr: CR_5,
  featureList: [
    {
      name: 'Магическая дубинка',
      description: `В руках лесной вайды ее дубинка магическая и наносит дополнительно 7 (3к4) урона (уже включено в атаки).`,
    },
    ABILITY_PLANT_CAMOUFLAGE,
    {
      id: ABILITY_REGENERATION,
      regen: 10,
      other: ` если находится в контакте с землей. Если ★СУЩЕСТВО★ получает урон огнём, то эта черта не работает в начале ★его★ следующего хода. ★СУЩЕСТВО★ умирает только если начинает ход с 0 хитов и не может регенерировать.`,
    },
    {
      name: 'Древесный шаг',
      description: `Один раз в каждый ход лесная вайда может потратить 10 футов движения и магически войти в живое дерево в 5 футах от себя и выйти из другого живого дерева, которое она может видеть не далее 60 футов от себя, появляясь на незанятом месте в 5 футах от второго дерева. Оба дерева должны быть Большими или больше.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Лесная вайда делает две атаки _Дубинкой_.`,
    },
    {
      name: 'Дубинка',
      // gearId: GEAR_CLUB, // TODO: Найти способ использовать gearId
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
