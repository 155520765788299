const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CAST_SOMATIC} = require('./../../../../../castComponentList')
const {DAMAGE_THUNDER} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_THUNDERCLAP} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_THUNDERCLAP,
  name: 'Раскат грома',
  nameEn: 'Thunderclap',
  description: `Вы вызываете оглушительный раскат грома, который слышен на расстоянии до 100 футов. Все существа кроме Вас в пределах 5 футов должны пройти испытание Телосложения. При провале существо получает урон звуком 1к6.`,
  highLvlCast: `Урон заклинания увеличивается на 1к6, когда Вы достигаете 5 уровня (2к6), 11 уровня (3к6) и 17 уровня (4к6).`,
  lvl: 0,
  magicSchoolId: MAGIC_EVOCATION,
  range: 5,
  componentIdList: [CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_CON,
    damage: {
      type: DAMAGE_THUNDER,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 165,
    },
    {
      id: SOURCE_EE,
      page: 22,
    },
  ],
}
