const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_ROBE_OF_THE_ARCHMAGI,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_ROBE_OF_THE_ARCHMAGI,
  name: `Мантия Архимага`,
  nameEn: `Robe of the Archmagi`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  attunementComment: `волшебником, колдуном или чародеем`,
  description: `Этот красивый наряд пошит из белой, серой или чёрной ткани и украшен серебристыми рунами. Цвет мантии соответствует мировоззрению, для которого она и создана. Белые мантии делают для добрых, серые для нейтральных, а чёрные для злых. Вы не можете настроиться на мантию архимага, чьё мировоззрение не совпадает с Вашим.

Вы получаете следующие преимущества, пока носите мантию:

* Если Вы не носите доспех, Ваш базовый Класс Доспеха равен 15 + Ваш модификатор Ловкости.
* Вы совершаете с преимуществом испытания от заклинаний и других магических эффектов.
* СЛ испытаний от Ваших заклинаний и бонус атаки заклинаниями увеличиваются на 2.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 181,
  },
}
