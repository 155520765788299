const PROGRESS_TABLE_ID = require('./../../PROGRESS_TABLE_ID')
const SPELL_SLOT_TABLE_ID = require('./../../SPELL_SLOT_TABLE_ID')
const {fullCasterSpellCircleList} = require('./../../spellCircleByCasterType')
const {PC_CLASS_BARD} = require('./../../pcClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
  SOURCE_ERRATA_PHB,
} = require('./../../sourceList')
const {SPELL_CURE_WOUNDS} = require('./../../spellIdList')
const {spellIdCollectionByPcClassId} = require('./../../spellIdPcClassIdBounds')

const {abilityScoreImprovementTemplate} = require('./../featureCommonRawList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_BARD,
  FEATURE_BARD_COLLEGE,
  FEATURE_BARDIC_INSPIRATION,
  FEATURE_BARDIC_VERSATILITY,
  FEATURE_COUNTERCHARM,
  FEATURE_EXPERTISE_BARD,
  FEATURE_FONT_OF_INSPIRATION,
  FEATURE_JACK_OF_ALL_TRADES,
  FEATURE_MAGICAL_INSPIRATION,
  FEATURE_MAGICAL_SECRETS,
  FEATURE_SONG_OF_REST,
  FEATURE_SPELLCASTING_BARD,
  FEATURE_SPELLCASTING_FOCUS_BARD,
  FEATURE_SUPERIOR_INSPIRATION,
  FEATURE_CANTRIPS_BARD,
  FEATURE_SPELL_SLOTS_BARD,
  FEATURE_SPELL_KNOWN_BARD,
  FEATURE_SPELLCASTING_ABILITY_BARD,
  FEATURE_RITUAL_CASTING_BARD,
} = require('./../../featureIdList')

module.exports = [
  {
    id: FEATURE_SPELLCASTING_BARD,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 1,
    text: `Вы научились изменять ткань реальности в соответствии с Вашими волей и музыкой.

Ваши заклинания являются частью Вашего обширного репертуара; это магия, которой Вы найдёте применение в любой ситуации.`,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_BARD],
    source: {
      id: SOURCE_PHB,
      page: 53,
    },
  },
  {
    id: FEATURE_CANTRIPS_BARD,
    name: `Заговоры`,
    nameEn: `Cantrips`,
    lvl: 1,
    text: `Вы знаете два заговора из списка доступных для барда на Ваш выбор.

При достижении более высоких уровней Вы выучите новые, в соответствии с колонкой _«[Заговоры](PC_CLASS:${PC_CLASS_BARD}#${PROGRESS_TABLE_ID})»_.`,
    source: {
      id: SOURCE_PHB,
      page: 53,
    },
  },
  {
    id: FEATURE_SPELL_SLOTS_BARD,
    name: `Ячейки заклинаний`,
    nameEn: `Spell Slots`,
    lvl: 1,
    text: `Таблица «[Ячейки заклинаний барда](PC_CLASS:${PC_CLASS_BARD}#${SPELL_SLOT_TABLE_ID})» показывает, сколько ячеек заклинаний у Вас есть для заклинаний 1 и других кругов. Для использования заклинания Вы должны потратить ячейку соответствующего, либо превышающего круга. Вы восстанавливаете все потраченные ячейки в конце длинного отдыха.

Например, если Вы знаете заклинание 1 круга [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}), и у Вас есть ячейки 1 и 2 кругов, Вы можете использовать его с помощью любой из этих ячеек.`,
    source: {
      id: SOURCE_PHB,
      page: 53,
    },
  },
  {
    id: FEATURE_SPELL_KNOWN_BARD,
    name: `Известные заклинания`,
    nameEn: `Spells Known`,
    lvl: 1,
    text: `Вы знаете четыре заклинания 1 круга на свой выбор из списка доступных барду.

Таблица _«[Ячейки заклинаний барда](PC_CLASS:${PC_CLASS_BARD}#${SPELL_SLOT_TABLE_ID})»_ показывает, когда Вы сможете выучить новые заклинания. Круг заклинаний не должен превышать круга самой высокой имеющейся у Вас ячейки заклинаний. Например, когда Вы достигнете 3 уровня в этом классе, Вы можете выучить одно новое заклинание 1 или 2 круга.

Кроме того, когда Вы получаете новый уровень в этом классе, Вы можете одно из известных Вам заклинаний барда заменить на другое из списка барда, круг которого тоже должен соответствовать имеющимся ячейкам заклинаний.`,
    source: {
      id: SOURCE_PHB,
      page: 53,
    },
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_BARD,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 1,
    text: `При накладывании заклинаний бард использует свою Харизму. Ваша магия проистекает из сердечности и душевности, которую Вы вкладываете в исполнение музыки и произнесение речей.

Вы используете Харизму в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете Харизму при определении СЛ испытаний от Ваших заклинаний, и при броске атаки заклинаниями.

**СЛ испытания** = 8 + бонус мастерства + модификатор Харизмы

**Модификатор броска атаки** = бонус мастерства + модификатор Харизмы`,
    source: {
      id: SOURCE_PHB,
      page: 53,
    },
  },
  {
    id: FEATURE_RITUAL_CASTING_BARD,
    name: `Исполнение ритуалов`,
    nameEn: `Ritual Casting`,
    lvl: 1,
    text: `Вы можете исполнить любое известное Вам заклинание барда в качестве ритуала, если заклинание позволяет это.`,
    source: {
      id: SOURCE_PHB,
      page: 53,
    },
  },
  {
    id: FEATURE_SPELLCASTING_FOCUS_BARD,
    name: `Фокусировка заклинания`,
    nameEn: `Spellcasting Focus`,
    lvl: 1,
    text: `Вы можете использовать музыкальный инструмент в качестве фокусировки для Ваших заклинаний барда.`,
    source: {
      id: SOURCE_PHB,
      page: 54,
    },
  },
  {
    id: FEATURE_BARDIC_INSPIRATION,
    name: `Вдохновение барда`,
    nameEn: `Bardic Inspiration`,
    lvl: [1, 5, 10, 15],
    text: `Своими словами или музыкой Вы можете вдохновлять других.

Для этого Вы должны бонусным действием выбрать одно существо, отличное от Вас, в пределах 60 футов, которое может Вас слышать. Это существо получает _Кость бардовского вдохновения_ — к6.

В течение следующих 10 минут это существо может один раз бросить эту _Кость_ и добавить результат к проверке характеристики, броску атаки или испытания, который оно совершает. Существо может принять решение о броске _Кости вдохновения_ уже после броска к20, но должно сделать это прежде, чем Мастер объявит результат броска. Как только _Кость бардовского вдохновения_ брошена, она исчезает. Существо может иметь только одну такую _Кость_ одновременно.

Вы можете использовать это умение количество раз, равное модификатору Вашей Харизмы, но как минимум один раз. Потраченные использования этого умения восстанавливаются после длинного отдыха.

Ваша _Кость бардовского вдохновения_ изменяется с ростом Вашего уровня в этом классе. Она становится к8 на 5 уровне, к10 на 10 уровне и к12 на 15 уровне.

| Уровень барда | Кость бардовского вдохновения |
|---------------|-------------------------------|
|  1            | к6                            |
|  5            | к8                            |
| 10            | к10                           |
| 15            | к12                           |
`,
    source: {
      id: SOURCE_PHB,
      page: 54,
    },
  },
  {
    id: FEATURE_JACK_OF_ALL_TRADES,
    name: `Мастер на все руки`,
    nameEn: `Jack of All Trades`,
    lvl: 2,
    text: `Вы можете добавлять половину бонуса мастерства, округлённую в меньшую сторону, ко всем проверкам характеристик, куда этот бонус ещё не включён. `,
    source: {
      id: SOURCE_PHB,
      page: 55,
    },
  },
  {
    id: FEATURE_SONG_OF_REST,
    name: `Песнь отдыха`,
    nameEn: `Song of Rest`,
    lvl: [2, 9, 13, 17],
    text: `Вы с помощью успокаивающей музыки или речей можете помочь своим раненым союзникам восстановить их силы во время короткого отдыха.

Если Вы, или любые союзные существа, способные слышать Ваше исполнение, восстанавливаете хиты в конце короткого отдыха, каждый из Вас восстанавливает дополнительно 1к6 хитов. Для того, чтобы восстановить дополнительные хиты, существо должно потратить в конце короткого отдыха как минимум одну Кость Хитов.

Количество дополнительно восстанавливаемых хитов растёт с Вашим уровнем в этом классе: 1к8 на 9 уровне, 1к10 на 13 уровне и 1к12 на 17 уровне.`,
    source: {
      id: SOURCE_PHB,
      page: 55,
    },
  },
  {
    id: FEATURE_MAGICAL_INSPIRATION,
    name: `Магическое вдохновение`,
    nameEn: `Magical Inspiration`,
    lvl: 2,
    text: `Если у существа есть Ваша кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и оно сотворяет заклинание, которое восстанавливает хиты или наносит урон, существо может бросить эту кость и выбрать цель, на которую действует заклинание.

Добавьте результат броска кости [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) к восстановленным хитам или нанесённому урону.

После этого кость [Бардовского вдохновения](FEATURE:${FEATURE_BARDIC_INSPIRATION}) тратится.`,
    source: {
      id: SOURCE_TCoE,
      page: 35,
    },
  },
  {
    id: FEATURE_BARD_COLLEGE,
    name: `Коллегия бардов`,
    nameEn: `Bard College`,
    lvl: [3, 6, 14],
    text: `Вы углубляетесь в традиции выбранной Вами коллегии бардов.

Этот выбор предоставляет Вам дополнительные умения.`,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 55,
    },
  },
  {
    id: FEATURE_EXPERTISE_BARD,
    name: `Компетентность`,
    nameEn: `Expertise`,
    lvl: [3, 10],
    text: `Выберите 2 навыка из тех, которыми Вы владеете. Ваш бонус мастерства для этих навыков удваивается.`,
    source: {
      id: SOURCE_PHB,
      page: 55,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_BARD,
    source: {
      id: SOURCE_PHB,
      page: 55,
    },
  },
  {
    id: FEATURE_BARDIC_VERSATILITY,
    name: `Гибкость барда`,
    nameEn: `Bardic Versatility`,
    lvl: abilityScoreImprovementTemplate.lvl,
    text: `Всякий раз, достигая нового уровня в этом классе, на котором Вы получаете способность [Увеличения характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_BARD}), Вы можете выполнить одно из следующего, демонстрируя смещение фокуса по мере использования Ваших навыков и магии:

* Замените один из навыков, который выбрали для способности [Компетентность](FEATURE:${FEATURE_EXPERTISE_BARD}), другим приобретенным навыком, который не зависит от опыта.
* Замените один заговор, который Вы выучили способностью [Заговоры](FEATURE:${FEATURE_SPELLCASTING_BARD}) этого класса, другим заговором из списка заклинаний барда.`,
    source: {
      id: SOURCE_TCoE,
      page: 35,
    },
  },
  {
    id: FEATURE_FONT_OF_INSPIRATION,
    name: `Источник вдохновения`,
    nameEn: `Font of Inspiration`,
    lvl: 5,
    text: `Вы восстанавливаете истраченные [Вдохновения барда](FEATURE:${FEATURE_BARDIC_INSPIRATION}) и после короткого и после длинного отдыха.`,
    source: {
      id: SOURCE_PHB,
      page: 55,
    },
  },
  {
    id: FEATURE_COUNTERCHARM,
    name: `Контрочарование`,
    nameEn: `Countercharm`,
    lvl: 6,
    text: `Вы получаете возможность использовать звуки или слова силы для разрушения воздействующих на разум эффектов.

Вы можете действием начать исполнение, которое продлится до конца Вашего следующего хода. В течение этого времени Вы и все дружественные существа в пределах 30 футов от Вас получают преимущество к испытаниям от испуга и очарования. Чтобы получить это преимущество, существа должны Вас слышать.

Исполнение заканчивается преждевременно, если Вы оказываетесь недееспособны, теряете способность говорить, или прекращаете исполнение добровольно (действие не требуется).`,
    source: {
      id: SOURCE_PHB,
      page: 55,
    },
  },
  {
    id: FEATURE_MAGICAL_SECRETS,
    name: `Тайны магии`,
    nameEn: `Magical Secrets`,
    lvl: [10, 14, 18],
    text: `Вы успели набрать знаний из самого широкого спектра магических дисциплин. Выберите два заклинания любых классов, включая Ваш собственный. Эти заклинания должны быть того круга, который Вы можете использовать, или являться заговорами.

Теперь эти заклинания считаются для Вас заклинаниями барда, и они уже включены в общее количество известных Вам заклинаний согласно таблице «[Развитие барда](PC_CLASS:${PC_CLASS_BARD}#${PROGRESS_TABLE_ID})».`,
    source: [
      {
        id: SOURCE_PHB,
        page: 55,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь заклинания можно выбирать из списков разных классов, а не одного',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_SUPERIOR_INSPIRATION,
    name: `Превосходное вдохновение`,
    nameEn: `Superior Inspiration`,
    lvl: 20,
    text: `Если на момент броска инициативы у Вас не осталось неиспользованных [Вдохновений](FEATURE:${FEATURE_BARDIC_INSPIRATION}), Вы получаете одно.`,
    source: {
      id: SOURCE_PHB,
      page: 55,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_BARD,
  })
)
