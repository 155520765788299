const {
  SPELL_BLUR,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FEATHER_FALL,
  SPELL_HASTE,
  SPELL_JUMP,
  SPELL_MAGE_HAND,
  SPELL_PLANE_SHIFT,
  SPELL_SEE_INVISIBILITY,
  SPELL_SHIELD,
  SPELL_TELEPORT,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ARCANA,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_GITH,
  CREATURE_TYPE_GITHZERAI,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  githzeraiDescription,
  githNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_GITHZERAI_ENLIGHTENED,
  CREATURE_GITHZERAI_ZERTH,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_LN} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_10} = require('./../../../../crList')
const {LANG_GITH} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гитцерай просвещённый',
  nameEn: 'Githzerai Enlightened',
  id: CREATURE_GITHZERAI_ENLIGHTENED,
  description: [
    `Гитцераи никогда не прекращают тренироваться. Они проводят много времени за медитацией для преодоления пределов своих тел и осознания природы реальности. [Зерты](CREATURE:${CREATURE_GITHZERAI_ZERTH}), завершившие следующую ступень своего обучения, становятся теми из гитцераев, что известны как просвещённые.`,
    ...githzeraiDescription,
  ],
  note: githNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHZERAI,
  ],
  alignmentId: ALIGNMENT_LN,
  source: {
    id: SOURCE_MTOF,
    page: 125,
  },
  armor: 18,
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 19,
    [PARAM_CON]: 16,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 19,
    [PARAM_CHA]: 13,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_ARCANA]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_GITH,
  ],
  cr: CR_10,
  featureList: [
    {
      name: 'Телепатическая защита',
      description: `Пока просвещённый не носит броню и не носит щит, к его классу доспеха добавляется модификатор Мудрости.`,
    },
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_WIT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_MAGE_HAND,
            comment: 'рука невидима',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_BLUR,
          SPELL_EXPEDITIOUS_RETREAT,
          SPELL_FEATHER_FALL,
          SPELL_JUMP,
          SPELL_SEE_INVISIBILITY,
          SPELL_SHIELD,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_HASTE,
          SPELL_PLANE_SHIFT,
          SPELL_TELEPORT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Гитцерай совершает три _Безоружные атаки_.`,
    },
    {
      name: 'Безоружная атака',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 4,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 3,
            diceType: 8,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Временной удар',
      description: `Цель должна пройти испытание Мудрости Сл. 16, либо переместится на 1 раунд вперёд. Перемещённая во времени цель временно исчезает. Когда эффект заканчивается, цель появляется в той же клетке, где была до этого или ближайшей к ней, если клетка занята.`,
      restore: 6,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 4,
          },
          {
            type: DAMAGE_PSYCHIC,
            diceCount: 8,
            diceType: 12,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
}
