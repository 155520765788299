const {
  CREATURE_GIANT_CENTIPEDE,
  CREATURE_GIANT_SCORPION,
  CREATURE_GIANT_SPIDER,
  CREATURE_GIANT_WASP,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_GIANT_INSECT,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GIANT_INSECT,
  name: 'Гигантское насекомое',
  nameEn: `Giant Insect`,
  description: `Вы превращаете на время действия заклинания до десяти многоножек, трёх пауков, пяти ос или одного скорпиона, находящихся в пределах дистанции, в гигантские версии своих сородичей. Многоножка становится [гигантской многоножкой](CREATURE:${CREATURE_GIANT_CENTIPEDE}), паук становится [гигантским пауком](CREATURE:${CREATURE_GIANT_SPIDER}), оса становится [гигантской осой](CREATURE:${CREATURE_GIANT_WASP}), а скорпион становится [гигантским скорпионом](CREATURE:${CREATURE_GIANT_SCORPION}).

Все существа повинуются Вашим устным командам, и в бою они в каждом раунде действуют в Ваш ход. Характеристики этих чудовищ есть у Мастера, и их действия и перемещения контролирует он.

Существо сохраняет гигантский размер всю длительность заклинания, пока его хиты не опустятся до 0, или пока Вы не окончите действием эффект на нём.

Мастер может позволить Вам выбирать другие цели. Например, если Вы превращаете жука, его гигантская версия может обладать теми же характеристиками, что и гигантская оса.`,
  lvl: 4,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 30,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: { timeId: TIME_MINUTE, count: 10 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 220,
    },
    {
      id: SOURCE_SRD,
      page: 149,
    },
  ],
}
