const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_SLAAD} = require('./../../creatureTypeIdList')

module.exports = {
  id: CREATURE_TYPE_SLAAD,
  nameEn: 'Slaad',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  notRealRace: true,
  name: {
    singular: {
      nominative: 'слаад',
      genitive: 'слаада',
      dative: 'слааду',
      accusative: 'слаада',
      instrumental: 'слаадом',
      prepositional: 'слааде',
    },
    plural: {
      nominative: 'слаады',
      genitive: 'слаадов',
      dative: 'слаадам',
      accusative: 'слаадов',
      instrumental: 'слаадами',
      prepositional: 'слаадах',
    },
  },
}
