const {
    SOURCE_SKT,
  } = require('./../../../../sourceList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_2,
  } = require('./../../../../crList'),
  {
    SIZE_LARGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_BLIND_VISION,
    SENSE_VIBRATION_SENSE,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    CREATURE_PURPLE_WORMLING,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Лиловый червёнок',
  nameAlt: 'Детёныш Пурпурного Червя',
  nameEn: 'Purple Wormling',
  id: CREATURE_PURPLE_WORMLING,
  description:  `Лиловый червёнок возрастом не больше шести недель. У него эластичное тело в 9 футов длиной и весом в 1 500 фунтов. Рот и мускулатура червёнка недостаточно развиты, чтобы он мог бурится сквозь скалу. Яд в жале на хвосте и кислота в пищеводе всё ещё достаточно слабы. Тем не менее, лиловый червёнок — жадный проглот и пытается съесть всё, что может засунуть в рот. Лиловый червёнок может проглотить за раз до четырёх Маленьких существ.`,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_SKT,
    page: 244,
  },
  armor: {
    ac: 12,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 16,
    [PARAM_DEX]: 7,
    [PARAM_CON]: 16,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 6,
    [PARAM_CHA]: 2,
  },
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_VIBRATION_SENSE,
      value: 30,
    },
  ],
  cr: CR_2,
  actionList: [
    {
      name: 'Мультиатака',
      description: `Червёнок совершает две атаки: одну _Укусом_ и одну — _Жалом_.`,
    },
    {
      name: 'Укус',
      description: `Если целью было существо Маленького размера или меньше, оно должно пройти испытание Ловкости СЛ 13 или будет проглочено червёнком. Проглоченное существо Ослеплено и Обездвижено, у него полное укрытие от атак и прочих эффектов извне червёнка и оно получает урон кислотой 3 (1к6) в начале каждого хода червёнка.

Если червёнок получает 10 или более урона за один ход от существа внутри него, он должен пройти испытание Телосложения СЛ 21 в конце этого хода или изрыгнуть всех проглоченных существ, которые падают Пластом в клетке в 10 футах от червёнка. Если червёнок умирает, проглоченное существо более не Обездвижено им и может сбежать из трупа, использовав 5 футов передвижения, чтобы выползти (лежа Пластом).`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Хвостовое жало',
      description: `Цель должна пройти испытание Телосложения СЛ 13. При провале цель получает 10 (3к6) урона ядом и только половину этого урона при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 3,
        },
      },
    },
  ],
}
