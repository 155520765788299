const {
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
  SOURCE_XGTE,
} = require('./../../../sourceList')
const {
  CAT_TOOLS,
} = require('./../../../gearCategoryList')
const {
  GEAR_INK,
  GEAR_INK_PEN,
  GEAR_NAVIGATORS_TOOLS,
  GEAR_PARCHMENT,
} = require('./../../../gearIdList')

module.exports = {
  id: GEAR_NAVIGATORS_TOOLS,
  genderId: GENDER_MULTIPLE,
  name: 'Инструменты навигатора',
  nameByCase: {
    nominative: 'инструменты навигатора',
    genitive: 'инструментов навигатора',
    accusative: 'инструменты навигатора',
    instrumental: 'инструментами навигатора',
  },
  nameEn: 'Navigator’s Tools',
  description: `Эти инструменты используются для навигации в море. Владение инструментами навигатора позволяет прокладывать курс корабля и пользоваться морскими картами. Кроме того, эти инструменты позволяют вам добавлять бонус мастерства к проверкам характеристик, совершённым, чтобы не потеряться в море.

Владение инструментами навигатора помогает вам определить истинный курс, основываясь на наблюдении за звёздами. Они также позволяют вам разбираться в чтении схем и карт, благодаря выработанному у вас чувству направления.

**Компоненты.** Инструменты навигатора включают в себя

* секстант,
* компас,
* кронциркуль,
* линейку,
* [пергамент](GEAR:${GEAR_PARCHMENT}),
* [чернила](GEAR:${GEAR_INK}),
* [писчее перо](GEAR:${GEAR_INK_PEN}).

**Выживание.** Умение обращаться с инструментами навигатора помогает вам не заблудиться и даёт знания о наиболее вероятном расположении дорог и населённых пунктов.

**Определение положения.** Проведя тщательные измерения, Вы можете определить своё положение на морской навигационной карте и время суток.

| Действие                                                      | СЛ |
|---------------------------------------------------------------|----|
| Проложить курс                                                | 10 |
| Определить своё местоположение на морской навигационной карте | 15 |
`,
  cost: 2500,
  weight: 2,
  source: [
    {
      id: SOURCE_PHB,
      page: 154,
    },
    {
      id: SOURCE_SRD,
      page: 154,
    },
    {
      id: SOURCE_XGTE,
      page: 85,
    },
  ],
  category: CAT_TOOLS,
}
