const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_THAYAN} = require('./../../languageIdList')
const {PLACE_THAY} = require('./../../placeIdList')
const {
  ALPHABET_INFERNAL,
  ALPHABET_THORASS,
} = require('./../../alphabetList')

module.exports = {
  id: LANG_THAYAN,
  alphabetId: [
    ALPHABET_INFERNAL,
    ALPHABET_THORASS,
  ],
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Тэйский',
    genitive: 'Тэйского',
    instrumental: 'Тэйским',
    prepositional: 'Тэйском',
  },
  nameEn: 'Thayan',
  spokenPlaceList: PLACE_THAY,
  typicalSpeakers: 'Тэйцы',
  isReady: false,
  isRealLang: true,
}
