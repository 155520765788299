const {
  SOURCE_PHB,
  SOURCE_TCoE,
  SOURCE_ERRATA_PHB,
} = require('./../../sourceList')
const {PC_CLASS_WARLOCK} = require('./../../pcClassIdList')
const {
  SPELL_FIND_FAMILIAR,
  SPELL_WITCH_BOLT,
} = require('./../../spellIdList')
const {
  CREATURE_IMP,
  CREATURE_PSEUDODRAGON,
  CREATURE_QUASIT,
  CREATURE_SPRITE,
} = require('./../../creatureIdList')
const {spellIdCollectionByPcClassId} = require('./../../spellIdPcClassIdBounds')
const {warlockSpellCircleList} = require('./../../spellCircleByCasterType')

const {abilityScoreImprovementTemplate} = require('./../featureCommonRawList')

const {
  FEATURE_ABILITY_SCORE_IMPROVEMENT_WARLOCK,
  FEATURE_CANTRIPS_WARLOCK,
  FEATURE_ELDRITCH_INVOCATIONS,
  FEATURE_ELDRITCH_MASTER,
  FEATURE_ELDRITCH_VERSATILITY,
  FEATURE_MYSTIC_ARCANUM,
  FEATURE_OTHERWORLDLY_PATRON,
  FEATURE_PACT_BOON,
  FEATURE_PACT_MAGIC,
  FEATURE_PACT_OF_THE_BLADE,
  FEATURE_PACT_OF_THE_CHAIN,
  FEATURE_PACT_OF_THE_TALISMAN,
  FEATURE_PACT_OF_THE_TOME,
  FEATURE_SPELL_KNOWN_WARLOCK,
  FEATURE_SPELL_SLOTS_WARLOCK,
  FEATURE_SPELLCASTING_ABILITY_WARLOCK,
  FEATURE_SPELLCASTING_FOCUS_WARLOCK,
} = require('./../../featureIdList')

const pickSpellIdListByLvl = require('./../../../utils/pickSpellIdListByLvl')

module.exports = [
  {
    id: FEATURE_OTHERWORLDLY_PATRON,
    name: `Потусторонний покровитель`,
    nameEn: `Otherworldly Patron`,
    lvl: [1, 6, 10, 14],
    text: `Вы заключаете сделку с потусторонним существом на Ваш выбор.

Ваш выбор определит умения, предоставляемые на 1, 6, 10 и 14 уровнях. `,
    showSubClassList: true,
    source: {
      id: SOURCE_PHB,
      page: 108,
    },
  },
  {
    id: FEATURE_PACT_MAGIC,
    name: `Магия договора`,
    nameEn: `Pact Magic`,
    lvl: 1,
    text: `Исследования тайн и магия, дарованная покровителем, дают Вам возможность использовать заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: spellIdCollectionByPcClassId[PC_CLASS_WARLOCK],
    source: [
      {
        id: SOURCE_PHB,
        page: 108,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Заклинание в примере изменено с Волны грома на Ведьмин снаряд',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_CANTRIPS_WARLOCK,
    name: `Заговоры`,
    nameEn: `Cantrips`,
    lvl: 1,
    text: `Вы знаете два заговора на свой выбор из списка заклинаний колдуна.

Вы узнаёте дополнительные заговоры колдуна на более высоких уровнях, как показано в колонке «известные заговоры».

| Уровень колдуна | Заговоры |
|-----------------|----------|
|  1–3            | 2        | 
|  4–9            | 3        | 
| 10–20           | 4        |
`,
    source: [
      {
        id: SOURCE_PHB,
        page: 108,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Заклинание в примере изменено с Волны грома на Ведьмин снаряд',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_SPELL_SLOTS_WARLOCK,
    name: `Ячейки заклинаний`,
    nameEn: `Spell Slots`,
    lvl: 1,
    text: `Таблица «Колдун» показывает, какое количество ячеек для сотворения заклинаний у Вас есть, а также круг этих ячеек — все Ваши ячейки заклинаний одного круга. Для сотворения одного из заклинаний колдуна 1 круга и выше Вы должны потратить ячейку заклинаний. Вы восстановите все потраченные ячейки, когда завершите короткий или длинный отдых.

Например, если Ваш персонаж 5 уровня, у Вас есть две ячейки заклинаний 3 круга. Чтобы использовать заклинание 1 круга [Ведьмин снаряд](SPELL:${SPELL_WITCH_BOLT}) Вы должны потратить одну из этих ячеек, и заклинание сработает как заклинание 3 круга.

## Ячейки заклинаний

| Уровень колдуна | Ячейки заклинаний |
|-----------------|-------------------|
|  1              | 1                 |
|  2–10           | 2                 |
| 11–16           | 3                 |
| 17–20           | 4                 |

## Круги ячеек

| Уровень колдуна | Круг ячеек |
|-----------------|------------|
| 1–2             | 1          |
| 3–4             | 2          |
| 5–6             | 3          |
| 7–8             | 4          |
| 9–20            | 5          |
`,
    source: [
      {
        id: SOURCE_PHB,
        page: 108,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Заклинание в примере изменено с Волны грома на Ведьмин снаряд',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_SPELL_KNOWN_WARLOCK,
    name: `Известные заклинания`,
    nameEn: `Spells Known`,
    lvl: 1,
    text: `На 1 уровне Вы знаете два заклинания 1 круга по своему выбору из списка заклинаний колдуна.

Колонка «известные заклинания» показывает, когда Вы сможете выучить новые заклинания 1 круга и выше. круг заклинания, которое Вы выбрали, не должен превышать круг ячеек, указанный в таблице для колдуна Вашего уровня. Например, когда Вы получите 6 уровень, Вы изучите новое заклинание колдуна, которое может быть 1, 2 или 3 круга.

Кроме того, когда Вы получаете уровень в этом классе, Вы можете выбрать одно из известных Вам заклинаний колдуна и заменить его другим заклинанием из списка заклинаний колдуна, с кругом, не превышающим круга ячеек заклинаний.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 108,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Заклинание в примере изменено с Волны грома на Ведьмин снаряд',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_WARLOCK,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 1,
    text: `При создании заклинаний колдун использует Харизму. Вы используете Харизму в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете модификатор Харизмы при определении СЛ испытаний от Ваших заклинаний колдуна, и при броске атаки заклинаниями.

**СЛ испытаний = 8 + бонус мастерства + модификатор Харизмы**

**Модификатор броска атаки = бонус мастерства + модификатор Харизмы**`,
    source: [
      {
        id: SOURCE_PHB,
        page: 108,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Заклинание в примере изменено с Волны грома на Ведьмин снаряд',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_SPELLCASTING_FOCUS_WARLOCK,
    name: `Фокусировка заклинания`,
    nameEn: `Spellcasting Focus`,
    lvl: 1,
    text: `Вы можете использовать магическую фокусировку в качестве заклинательной фокусировки для заклинаний колдуна.`,
    source: {
      id: SOURCE_PHB,
      page: 108,
    },
  },
  {
    id: FEATURE_ELDRITCH_INVOCATIONS,
    name: `Таинственные воззвания`,
    nameEn: `Eldritch Invocations`,
    lvl: 2,
    text: `В процессе изучения оккультных знаний Вы раскопали [Таинственные воззвания](/invocation-catalog): фрагменты запрещённых знаний, которые даруют магические способности.

На 2 уровне Вы получаете два [Воззвания](/invocation-catalog) на свой выбор. Получая новые уровни колдуна, Вы получаете дополнительные [Воззвания](/invocation-catalog) на свой выбор, как показано в колонке «известные воззвания».

Кроме того, когда Вы получаете новый уровень этого класса, Вы можете выбрать одно известное Вам [Воззвание](/invocation-catalog) и заменить его другим, которое Вы способны выучить на этом уровне.

| Уровень | Известные воззвания |
|---------|---------------------|
|    1    | —                   |
|  2–4    | 2                   |
|  5–6    | 3                   |
|  7–8    | 4                   |
|  9–11   | 5                   |
| 12–14   | 6                   |
| 15–17   | 7                   |
| 18–20   | 8                   |
`,
    listAllInvocations: true,
    source: {
      id: SOURCE_PHB,
      page: 108,
    },
  },
  {
    id: FEATURE_PACT_BOON,
    name: `Предмет договора`,
    nameEn: `Pact Boon`,
    lvl: 3,
    text: `Потусторонний покровитель дарует Вам подарок за верную службу.`,
    childFeatureIdList: [ // TODO
      FEATURE_PACT_OF_THE_BLADE,
      FEATURE_PACT_OF_THE_CHAIN,
      FEATURE_PACT_OF_THE_TALISMAN,
      FEATURE_PACT_OF_THE_TOME,
    ],
    source: {
      id: SOURCE_PHB,
      page: 108,
    },
  },
  {
    id: FEATURE_PACT_OF_THE_BLADE,
    name: 'Договор клинка',
    nameEn: 'Pact of the Blade',
    lvl: 3,
    text: `Вы можете действием создать оружие договора в своей пустой руке. Вы сами выбираете форму этого рукопашного оружия каждый раз, когда создаёте. Вы получаете владение этим оружием, пока используете его. Оружие считается магическим при определении преодоления сопротивления и иммунитета от немагических атак и урона.

Оружие договора исчезает, если оно в течении 1 минуты находится дальше 5 футов от Вас. Оно также исчезает, если Вы используете это умение ещё раз, отзываете оружие (действие не требуется), или умираете. Вы можете трансформировать одно магическое оружие в своё оружие договора, проведя специальный ритуал, держа это оружие. Ритуал совершается 1 час, его можно провести во время короткого отдыха. Впоследствии Вы можете отозвать оружие, помещая его между измерениями. Оно будет появляться в руке, когда Вы будете в дальнейшем создавать оружие договора.

Вы не можете сделать это с артефактом или разумным оружием. Оружие перестаёт быть оружием договора, когда Вы умираете, выполняете часовой ритуал с другим оружием или когда Вы исполните ритуал длиной в час для того, чтобы разорвать связь. Оружие материализуется у Ваших ног, если в момент разрыва связи оно находилось между измерениями.`,
    source: {
      id: SOURCE_PHB,
      page: 108,
    },
  },
  {
    id: FEATURE_PACT_OF_THE_CHAIN,
    name: 'Договор цепи',
    nameEn: 'Pact of the Chain',
    lvl: 3,
    text: `Вы узнаёте заклинание [Поиск фамильяра](SPELL:${SPELL_FIND_FAMILIAR}) и можете использовать его как ритуал. Это заклинание не учитывается при подсчёте числа заклинаний, которые Вы можете знать.

Когда Вы накладываете это заклинание, Вы можете выбрать одну из обычных форм для Вашего фамильяра, либо одну из особых форм: [бес](CREATURE:${CREATURE_IMP}), [квазит](CREATURE:${CREATURE_QUASIT}), [псевдодракон](CREATURE:${CREATURE_PSEUDODRAGON}) или [спрайт](CREATURE:${CREATURE_SPRITE}).

Кроме того, когда Вы совершаете действие Атака, Вы можете вместо одной своей атаки позволить атаковать один раз фамильяру. При этом он совершает свою атаку реакцией.`,
    spellIdList: SPELL_FIND_FAMILIAR,
    source: {
      id: SOURCE_PHB,
      page: 109,
    },
  },
  {
    id: FEATURE_PACT_OF_THE_TALISMAN,
    name: 'Договор талисмана',
    nameEn: 'Pact of the Talisman',
    lvl: 3,
    text: `Ваш покровитель даёт Вам амулет — талисман, который может помочь носителю, когда это нужнее всего.

Когда носитель проваливает проверку навыка, он может добавить к ней к4, потенциально превращая провал в успех.

Эту способность можно использовать количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.

Если Вы теряете талисман, то можете совершить часовую церемонию, чтобы получить замену от покровителя. Эту церемонию можно совершать во время короткого или длинного отдыха и она разрушает предыдущий амулет.

Талисман распадается в пыль, когда Вы умираете.`,
    source: {
      id: SOURCE_TCoE,
      page: 105,
    },
  },
  {
    id: FEATURE_PACT_OF_THE_TOME,
    name: 'Договор гримуара',
    nameEn: 'Pact of the Tome',
    lvl: 3,
    text: `Ваш покровитель дарует Вам гримуар, который называется «Книга теней». Выберите 3 заговора из списков любых классов. Пока книга с Вами, Вы можете применять эти заговоры неограниченно. Они не учитываются при подсчёте максимального числа заговоров, которые Вы можете знать, и считаются для Вас заклинаниями колдуна.

Если Вы теряете книгу, Вам нужно провести ритуал длительностью в 1 час, чтобы получить новую от своего покровителя. Вы можете провести этот ритуал во время короткого или длинного отдыха. Предыдущая книга при этом уничтожается. Книга обращается в прах при Вашей смерти.`,
    spellIdList: pickSpellIdListByLvl(0),
    source: {
      id: SOURCE_PHB,
      page: 108,
    },
  },
  {
    ...abilityScoreImprovementTemplate,
    id: FEATURE_ABILITY_SCORE_IMPROVEMENT_WARLOCK,
    source: {
      id: SOURCE_PHB,
      page: 109,
    },
  },
  {
    id: FEATURE_ELDRITCH_VERSATILITY,
    name: `Таинственная гибкость`,
    nameEn: `Eldritch Versatility`,
    lvl: abilityScoreImprovementTemplate.lvl,
    text: `Всякий раз, достигая в этом классе нового уровня, на котором Вы получаете способность [Увеличения характеристик](FEATURE:${FEATURE_ABILITY_SCORE_IMPROVEMENT_WARLOCK}), Вы можете выполнить одно из следующего, демонстрируя изменение фокуса Ваших оккультных исследований:

* Замените один заговор, который Вы выучили способностью [Заговоры](FEATURE:${FEATURE_PACT_MAGIC}), другим заговором из списка заклинаний колдуна.
* Замените вариант Вашего [предмета договора](FEATURE:${FEATURE_PACT_BOON}) на другой.
* Если Вы 12 уровня или Выше, замените одно заклинание, полученное Вами из [Таинственного арканума](FEATURE:${FEATURE_MYSTIC_ARCANUM}) на другое заклинание колдуна этого же круга.`,
    source: {
      id: SOURCE_TCoE,
      page: 105,
    },
  },
  {
    id: FEATURE_MYSTIC_ARCANUM ,
    name: `Таинственный арканум`,
    nameEn: `Mystic Arcanum`,
    lvl: [11, 13, 15, 17],
    text: `Ваш покровитель дарует Вам магический секрет, называемый _Арканумом_. Выберите одно заклинание 6 круга из списка заклинаний колдуна в качестве арканума.

Вы можете сотворить это заклинание, не используя ячейку заклинаний. Вы должны окончить длинный отдых, чтобы сделать это ещё раз.

На следующих уровнях Вы получаете новые заклинания, которые можно применить таким образом — одно 7 круга на 13 уровне, одно 8 круга на 15 уровне и одно 9 круга на 17 уровне.

После окончания длинного отдыха Вы восстанавливаете все потраченные использования арканумов.`,
    source: {
      id: SOURCE_PHB,
      page: 109,
    },
  },
  {
    id: FEATURE_ELDRITCH_MASTER,
    name: `Таинственный мастер`,
    nameEn: `Eldritch Master`,
    lvl: 20,
    text: `Вы можете обратиться к внутреннему резерву мистической силы, умоляя при этом покровителя восстановить потраченные ячейки заклинаний.

Вам надо потратить 1 минуту, умоляя покровителя, чтобы восстановить все использованные ячейки заклинаний, дарованные умением [Магия договора](FEATURE:${FEATURE_PACT_MAGIC}). Вы должны закончить длинный отдых, чтобы применить это умение вновь.`,
    source: {
      id: SOURCE_PHB,
      page: 109,
    },
  },
].map(
  feature => ({
    ...feature,
    pcClassId: PC_CLASS_WARLOCK,
  })
)
