const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  FEATURE_ANIMATING_PERFORMANCE,
} = require('./../../../../featureIdList')
const {
  PC_SUBCLASS_BARD_COLLEGE_OF_CREATION,
} = require('./../../../../pcSubClassIdList')
const {
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FORCE,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_IMMUTABLE_FORM,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_DANCING_ITEM,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Пляшущий предмет',
  nameEn: 'Dancing Item',
  id: CREATURE_DANCING_ITEM,
  description: `Создан [Оживляющим выступлением](FEATURE:${FEATURE_ANIMATING_PERFORMANCE}) Барда [Коллегии творения](PC_SUBCLASS:${PC_SUBCLASS_BARD_COLLEGE_OF_CREATION})`,
  createdByFeatureId: FEATURE_ANIMATING_PERFORMANCE,
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_TCoE,
    page: 37,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: `10 + пятикратный уровень барда`,
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: {
      value: 30,
      isFloating: true,
    },
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 4,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 6,
  },
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_PSYCHIC,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ITS_CREATOR,
  ],
  featureList: [
    ABILITY_IMMUTABLE_FORM,
    {
      name: 'Неугомонный пляс',
      nameEn: 'Irrepressible Dance',
      description: `Когда любое существо начинает свой ход в пределах 10 футов от ★него★, ★СУЩЕСТВО★ может увеличить или уменьшить (на Ваш выбор) скорость ходьбы этого существа на 10 футов до конца этого хода, если ★СУЩЕСТВО★ ★дееспособен★.`,
    },
  ],
  actionList: [
    {
      name: 'Усиленный магией удар',
      comment: `требует бонусного действия барда`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: `бонус к атаке заклинаниями барда`,
        range: 5,
        target: {
          count: 1,
          comment: 'видимая бардом',
        },
        hit: {
          type: DAMAGE_FORCE,
          diceCount: 1,
          diceType: 10,
          diceBonus: `бонус мастерства барда`,
        },
      },
    },
  ],
}
