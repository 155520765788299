const {CAST_VERBAL} = require('./../../../../../castComponentList')
const {MAGIC_ABJURATION} = require('./../../../../../magicList')
const {SOURCE_PHB} = require('./../../../../../sourceList')
const {SPELL_AURA_OF_LIFE} = require('./../../../../../spellIdList')
const {TIME_MINUTE} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_AURA_OF_LIFE,
  name: 'Аура жизни',
  nameEn: `Aura of Life`,
  description: `От Вас исходит защищающая жизнь аура с радиусом 30 футов. Пока заклинание активно, аура перемещается вместе с Вами, оставаясь с центром на Вас. Все невраждебные существа в ауре (включая Вас) обладают сопротивлением к урону некротической энергией, и максимум их хитов не может уменьшаться. Кроме того, невраждебные живые существа восстанавливают 1 хит, когда начинают ход в этой ауре с 0 хитов.`,
  lvl: 4,
  magicSchoolId: MAGIC_ABJURATION,
  range: -1,
  rangeComment: `30-футовый радиус`,
  componentIdList: [CAST_VERBAL],
  duration: { timeId: TIME_MINUTE, count: 10 },
  needConcentration: true,
  source: {
    id: SOURCE_PHB,
    page: 212,
  },
}
