const arrify = require('arrify')

const extractEffectProp = (propName, effect) => effect
  ? arrify(effect).reduce(
    (resultList, effectItem) => (
      effectItem[propName]
        ? arrify(effectItem[propName]).reduce(
        (list, param) => (
          list.includes(param)
            ? list
            : [
              ...list,
              param,
            ]
        ),
        resultList || []
        )
        : resultList
    ),
    null
  )
  : null

module.exports = extractEffectProp
