const {
  SOURCE_MOOT,
} = require('./../../../../sourceList')
const {
  CR_2,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_CONSTRUCT,
  CREATURE_TYPE_ANVILWROUGHT,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_NO,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  LANG_ONE_OF_ITS_CREATOR,
} = require('./../../../../languageIdList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_HEATED_BODY,
  ABILITY_SURE_FOOTED,
  ABILITY_CHARGE,
} = require('./../../../../creatureAbilityList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  anvilwroughtDescription,
} = require('./../../../../textCommonParts')
const {
  CREATURE_BURNISHED_HART,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Сверкающий олень',
  nameEn: 'Burnished Hart',
  id: CREATURE_BURNISHED_HART,
  description: [
    {
      header: 'Сверкающий олень',
      text: `Сверкающие олени были одними из первых откованных, созданных Пирфором.

Они бродят по миру смертных в поисках новых диковин, которые можно было бы принести богу-кузнецу. Во время каждого путешествия с горы Велус, где находится кузница Пирфора, они ищут потаённые закоулки мира, чтобы увидеть красоту во всех её формах. И когда они возвращаются к своему создателю, их умы полны рассказов о том, как великолепие жизни продолжает расти.`,
      source: {
        id: SOURCE_MOOT,
        page: 211,
      },
    },
    anvilwroughtDescription,
  ],
  note: `Выкован божественными руками, чтобы ходить по землям смертных.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_ANVILWROUGHT,
    CREATURE_TYPE_CONSTRUCT,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_MOOT,
    page: 212,
  },
  armor: {
    ac: 14,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 50,
  },
  params: {
    [PARAM_STR]: 17,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 3,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 1,
  },
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ONE_OF_ITS_CREATOR,
      doNotSpeak: true,
    },
  ],
  cr: CR_2,
  featureList: [
    {
      id: ABILITY_HEATED_BODY,
      damage: `5 (1к10)`,
    },
    ABILITY_SURE_FOOTED,
    {
      id: ABILITY_CHARGE,
      range: 20,
      attackName: 'Рогами',
      damageText: '7 (2к6) огненного урона',
      afterEffectText: 'Если цель — существо, она должна преуспеть в испытании Силы СЛ 13, иначе будет сбита с ног.',
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки: одну — _Рогами_ и одну — _Копытами_`,
    },
    {
      name: 'Рога',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Копыта',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 3,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 4,
          },
        ],
      },
    },
  ],
}
