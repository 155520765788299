const {FEATURE_FEY_WANDERER_MAGIC_2020_02_24} = require('./../../../featureIdList')
const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_RANGER_FEY_WANDERER_2020_02_24} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_02_24_SUBCLASSES_PART_3} = require('./../../../sourceList')

const {
  SPELL_BANISHMENT,
  SPELL_CHARM_PERSON,
  SPELL_DISPEL_MAGIC,
  SPELL_MISLEAD,
  SPELL_MISTY_STEP,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_FEY_WANDERER_MAGIC_2020_02_24,
    name: `Магия фейского странника`,
    nameEn: `Fey Wanderer Magic`,
    lvl: 3,
    text: `Вы узнаёте дополнительные заклинания, когда достигаете определённых уровней в этом классе.

Эти заклинания считаются для Вас заклинаниями следопыта, но не учитываются при подсчете известных Вам заклинаний следопыта.`,
    pcSubClassId: PC_SUBCLASS_RANGER_FEY_WANDERER_2020_02_24,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_BANISHMENT,
      SPELL_CHARM_PERSON,
      SPELL_DISPEL_MAGIC,
      SPELL_MISLEAD,
      SPELL_MISTY_STEP,
    ],
    source: {
      id: SOURCE_UA_2020_02_24_SUBCLASSES_PART_3,
      page: 5,
    },
  },
]
