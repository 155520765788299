const {CREATURE_TYPE_AUTOGNOME} = require('./../../creatureTypeIdList')
const {SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE} = require('./../../sourceList')
const {LANG_GNOMISH} = require ('./../../languageIdList')
const {PC_RACE_GNOME} = require ('./../../pcRaceIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')

module.exports = {
  id: CREATURE_TYPE_AUTOGNOME,
  nameEn: 'Autognome',
  genderId: GENDER_MALE,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'автогном',
        genitive: 'автогнома',
        dative: 'автогному',
        accusative: 'автогнома',
        instrumental: 'автогномом',
        prepositional: 'автогноме',
      },
      [GENDER_FEMALE]: {
        nominative: 'автогномка',
        genitive: 'автогномки',
        dative: 'автогномке',
        accusative: 'автогномку',
        instrumental: 'автогномкой',
        prepositional: 'автогномке',
      },
    },
    plural: {
      nominative: 'автогномы',
      genitive: 'автогномов',
      dative: 'автогномам',
      accusative: 'автогномов',
      instrumental: 'автогномами',
      prepositional: 'автогномах',
    },
  },
  description: [
    {
      header: 'Автогномы',
      text: `Автогномы — это механические создания, построенные [скальными гномами](PC_RACE:${PC_RACE_GNOME}) по своему образу и подобию, обычно для какой-то конкретной задачи. Например, [гном](PC_RACE:${PC_RACE_GNOME}) может построить автогнома как надёжного партнёра и верного спутника. Иногда, из-за сбоев или уникальных обстоятельств, автогномы покидают своего создателя и начинают действовать самостоятельно.

Автогномы обычно внешне схожи со своими создателями. Большинство автогномов запрограммировано говорить и понимать [гномий язык](LANG:${LANG_GNOMISH}). Внутренние компоненты автогномов сильно различаются. Один автогном может иметь в грудной полости настоящее бьющееся сердце, другой может питаться от звёздной пыли или удивительного заводного механизма.

Как и [гномы](PC_RACE:${PC_RACE_GNOME}), автогномы живут столетиями, доживая до 500 лет.`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 2,
      },
    },
  ],
}
