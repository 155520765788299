const {CREATURE_BEHOLDER} = require('./../../creatureIdList')
const {SOURCE_VGTM} = require('./../../sourceList')

module.exports = {
  header: 'Бехолдеры',
  text: `Истинные [бехолдеры](CREATURE:${CREATURE_BEHOLDER}) — изоляционисты, которые презирают остальных существ своего вида, но их сны могут создать множества меньших бехолдеро–подобных существ.`,
  source: {
    id: SOURCE_VGTM,
    page: 126,
  },
}
