const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_UNCONSCIOUS,
} = require('./../../../../conditionList')
const {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PSYCHIC,
  DAMAGE_THUNDER,
} = require('./../../../../damageTypeList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_TROLL,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_INCORPOREAL_MOVEMENT,
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_11} = require('./../../../../crList')
const {CREATURE_SPIRIT_TROLL} = require('./../../../../creatureIdList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {TARGET_CREATURE} = require('./../../../../targetList')
const {trollDescription} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Тролль-дух',
  nameEn: 'Spirit troll',
  id: CREATURE_SPIRIT_TROLL,
  description: [
    `Тролль, взорванный психической энергией может принимать бестелесную форму, когда будет регенерировать. Если его дух выдержит такую регенерацию, то тело становится таким же нематериальным, как и тень. При этом тролль может не догадываться о подобном изменении — он всё ещё будет двигаться и атаковать зубами и когтями, как и делал это всегда. Но вместо физических повреждений он теперь будет причинять повреждения разуму цели.`,
    ...trollDescription,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_TROLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 233,
  },
  armor: {
    ac: 17,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 13,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 9,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
    DAMAGE_THUNDER,
  ],
  immunityList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
    CONDITION_UNCONSCIOUS,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_11,
  featureList: [
    ABILITY_INCORPOREAL_MOVEMENT,
    {
      id: ABILITY_REGENERATION,
      regen: 10,
      other: `. Если ★он★ получает урон психической энергией или силовым полем, эта особенность не действует в начале его следующего хода. ★СУЩЕСТВО★ умирает только если начинает ход с 0 хитов и не может регенерировать`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Тролль совершает три атаки: одну _Укусом_ и две _Когтями_.`,
    },
    {
      name: 'Укус',
      description: `Цель должна пройти испытание Мудрости СЛ 15, иначе станет ошеломлённой на 1 минуту. Ошеломлённая цель повторяет испытание в конце каждого своего хода, завершая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 3,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 7,
        range: 5,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 2,
          diceType: 10,
          diceBonus: 3,
        },
      },
    },
  ],
}
