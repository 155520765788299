const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_ABYSSAL_WRETCH} = require('./../../../../creatureIdList')
const {demonDescription} = require('./../../../../textCommonParts')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {LANG_ABYSSAL} = require('./../../../../languageIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {
  SOURCE_BGDIA,
  SOURCE_MTOF,
} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Тварь Бездны',
  nameAlt: 'Отродье Бездны',
  nameEn: 'Abyssal Wretch',
  id: CREATURE_ABYSSAL_WRETCH,
  description: demonDescription,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 136,
    },
    {
      id: SOURCE_BGDIA,
      page: 118,
    },
  ],
  armor: 11,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 9,
    [PARAM_DEX]: 12,
    [PARAM_CON]: 11,
    [PARAM_INT]: 5,
    [PARAM_WIT]: 8,
    [PARAM_CHA]: 5,
  },
  resistanceList: [
    DAMAGE_FIRE,
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    {
      id: LANG_ABYSSAL,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_4,
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 1,
        },
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
