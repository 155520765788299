const {DAMAGE_FIRE} = require('./../../../damageTypeList')
const {GEAR_GUNPOWDER_KEG} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')
const {SOURCE_DMG} = require('./../../../sourceList')
const {PARAM_DEX} = require('./../../../paramList')
const {
  CAT_GOODS,
  CAT_GUNPOWDER,
  CAT_EXPLOSIVES,
  CAT_TECH_RENAISSANCE,
} = require('./../../../gearCategoryList')

module.exports = {
  id: GEAR_GUNPOWDER_KEG,
  name: 'Порох, бочонок',
  nameEn: 'Gunpowder, keg',
  nameByCase: {
    nominative: 'бочонок пороха',
    genitive: 'бочонка пороха',
    accusative: 'бочонок пороха',
    instrumental: 'бочонком пороха',
  },
  description: {
    header: 'Бочонок пороха',
    text: `Поджигание бочонка с порохом может вызвать взрыв, причиняющий 7к6 урона огнём всем существам в пределах 10 футов. Успех в испытании Ловкости СЛ 12 уменьшает урон вдвое.`,
    source: {
      id: SOURCE_DMG,
      page: 267,
    },
  },
  cost: 25000,
  weight: 20,
  range: 10,
  damageType: DAMAGE_FIRE,
  damage: {
    diceCount: 7,
    diceType: 6,
  },
  saveThrow: {
    type: PARAM_DEX,
    dc: 12,
  },
  genderId: GENDER_MALE,
  category: [
    CAT_GUNPOWDER,
    CAT_GOODS,
    CAT_EXPLOSIVES,
    CAT_TECH_RENAISSANCE,
  ],
  source: {
    id: SOURCE_DMG,
    page: 267,
  },
}
