const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_KEEN_SIGHT_AND_SMELL,
  ABILITY_REGENERATION,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_TROLL,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_13} = require('./../../../../crList')
const {CREATURE_DIRE_TROLL} = require('./../../../../creatureIdList')
const {LANG_GIANT} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')
const {trollDescription} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Лютый тролль',
  nameEn: 'Dire Troll',
  id: CREATURE_DIRE_TROLL,
  description: [
    `Тролли убивают и поедают всё подряд, в том числе, пусть и в редких случаях, других троллей. Такой каннибализм приводит к тому, что тролль вырастает до невероятных размеров. После чего у этих троллей появляется лютое желание поедать своих сородичей и продолжать всё больше расти.

Лютые тролли также увеличиваются в размерах, пересаживая на своё тело органы и плоть других существ. Когда кусок свежей плоти тролля совмещается с лютым троллем, его восстановительный фактор преобразует эту плоть в организм тролля. Ещё более ужасающими выглядят многочисленные руки, глаза, когти, и другие органы, которые ужасный тролль оторвал у своих жертв и пересадил в своё тело. Со временем, у этих существ собирается множество конечностей.`,
    ...trollDescription,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_GIANT,
    CREATURE_TYPE_TROLL,
  ],
  alignmentId: ALIGNMENT_CE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 233,
    },
    {
      url: `https://5e.tools/bestiary.html#dire%20troll_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Лютого тролля» вместо преимущества на проверки Мудрости почему-то указано проимуществона испытания Мудрости.`,
    },
  ],
  armor: {
    ac: 15,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 22,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 21,
    [PARAM_INT]: 9,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 5,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_GIANT,
  ],
  cr: CR_13,
  featureList: [
    ABILITY_KEEN_SIGHT_AND_SMELL,
    {
      id: ABILITY_REGENERATION,
      regen: 10,
      other: `. Если ★он★ ★получил★ перед этим урон огнём или кислотом, он восстанавливает только 5 хитов. ★СУЩЕСТВО★ умирает, только если причинить ★ему★ не менее 10 урона кислотой или огнём, пока ★он★ находится в 0 хитах`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает пять атак: одну _Укусом_ и четыре _Когтями_.`,
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 6,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 1,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Вихрь когтей',
      restore: 5,
      description: `Каждое существо в пределах 10 фт. от тролля должно пройти испытание Ловкости СЛ 19, получая 44 (8к10) рубящего урона при провале, и половину этого урона при успехе.`,
    },
  ],
}
