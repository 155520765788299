const { GEAR_WHIP } = require('./../../../../../gearIdList')
const { CREATURE_ROC } = require('./../../../../../creatureIdList')
const {
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_ANCHOR,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_BIRD,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_FAN,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_SWAN_BOAT,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_TREE,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_WHIP,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_FAN,
    name: `Перо Кваля: Веер`,
    nameEn: `Quaal’s Feather Token: Fan`,
    description: `Если Вы находитесь на корабле или лодке, то можете действием подбросить перо в воздух на расстояние до 10 футов. Этот предмет исчезнет, и на том месте, где это произошло, появится гигантский машущий веер. Этот веер парит в воздухе и создаёт ветер, достаточный для того, чтобы наполнить паруса корабля, увеличивая его скорость на 5 миль в час в течение 8 часов. Вы можете действием прервать этот эффект.`,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_TREE,
    name: `Перо Кваля: Дерево`,
    nameEn: `Quaal’s Feather Token: Tree`,
    description: `Для использования этого пера Вы должны находиться на открытом воздухе. Вы можете действием коснуться свободного пространства на поверхности земли. Перо исчезает, и на том месте, где Вы коснулись земли, вырастет дуб, не обладающий никакими магическими свойствами. Высотой дерево будет достигать 60 футов при диаметре ствола 5 футов и радиусе кроны 20 футов.`,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_WHIP,
    name: `Перо Кваля: Кнут`,
    nameEn: `Quaal’s Feather Token: Whip`,
    description: `Вы можете действием бросить это перо в любую точку пространства в пределах 10 футов от себя. Перо исчезает, и на его месте появится парящий над землёй [кнут](GEAR:${GEAR_WHIP}). После этого Вы можете бонусным действием совершить рукопашную атаку заклинанием по существу, находящемуся в пределах 10 футов от [кнута](GEAR:${GEAR_WHIP}), с бонусом атаки +9. При попадании цель получает урон силовым полем 1к6 + 5. В свой ход Вы можете бонусным действием приказать кнуту переместиться на 20 футов и повторить атаку по существу, находящемуся в пределах 10 футов от него. Кнут исчезает через 1 час, либо после того, как Вы действием отпустите его, либо же если Вы станете недееспособным или умрёте.`,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_SWAN_BOAT,
    name: `Перо Кваля: Лодка-лебедь`,
    nameEn: `Quaal’s Feather Token: Swan Boat`,
    description: `Вы можете действием прикоснуться этим предметом к водоёму с диаметром как минимум 60 футов. Перо исчезнет, и вместо него появляется лодка в форме лебедя длиной 50 и шириной 20 футов. Эта лодка может самостоятельно двигаться по водной глади со скоростью 6 миль в час. Находясь на лодке, Вы можете действием отдавать ей команды двигаться или совершить поворот на 90 градусов. Лодка может перевозить 32 существа Среднего или меньшего размера. Большое существо считается за четыре Средних, а Огромное за девять. Лодка остаётся в Вашем распоряжении 24 часа, после чего исчезает. Вы также можете действием прервать действие магии досрочно.`,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_BIRD,
    name: `Перо Кваля: Птица`,
    nameEn: `Quaal’s Feather Token: Bird`,
    description: `Вы можете действием подбросить перо в воздух на 5 футов. Перо исчезает, и на его месте появляется огромная, разноцветная птица. Используйте для неё параметры [рух](CREATURE:${CREATURE_ROC}), но она исполняет Ваши простые приказания и не может атаковать. Она может переносить до 500 фунтов, летя со своей максимальной скоростью (16 миль в час при максимуме в 144 мили в день, с часовыми отдыхами через каждые 3 часа), или 1000 фунтов с уменьшенной вдвое скоростью. Птица исчезнет после того, как пролетит свою максимальную дневную дистанцию или, если её хиты опустятся до 0. Вы также можете отпустить её действием.`,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_ANCHOR,
    name: `Перо Кваля: Якорь`,
    nameEn: `Quaal’s Feather Token: Anchor`,
    description: `Вы можете действием коснуться этим пером лодки или корабля. В течение следующих 24 часов это судно не может двинуться с места, какие бы средства не использовались для этого. Повторное прикосновение прекращает эффект. Когда эффект заканчивается, перо исчезает.`,
  },
]
