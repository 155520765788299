const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_BLINK_DOG,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  CREATURE_BLINK_DOG,
  CREATURE_DISPLACER_BEAST,
} = require('./../../../../creatureIdList')
const {ABILITY_KEEN_HEARING_AND_SMELL} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_LG} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_TYPE_FEY} = require('./../../../../creatureTypeIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Мерцающий пёс',
  nameEn: 'Blink Dog',
  id: CREATURE_BLINK_DOG,
  description: `Мерцающий пёс получил своё название за способность исчезать и возникать в другом месте. Он использует это умение и для нападения и для уклонения от чужих атак. У мерцающих псов старая вражда с [ускользающими зверьми](CREATURE:${CREATURE_DISPLACER_BEAST}), и они сразу нападают на них, как только заметят.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_LG,
  source: {
    id: SOURCE_MM,
    page: 334,
  },
  armor: 13,
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 12,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 13,
    [PARAM_CHA]: 11,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  languageList: [
    LANG_BLINK_DOG,
    {
      id: LANG_SYLVAN,
      doNotSpeak: true,
    },
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_KEEN_HEARING_AND_SMELL,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 1,
        },
      },
    },
    {
      name: 'Телепортация',
      restore: 4,
      description: `★СУЩЕСТВО★ магическим образом телепортируется вместе со всем несомым и носимым снаряжением, на расстояние до 40 футов в свободное пространство, которое ★он★ видит. До или после телепортации ★СУЩЕСТВО★ может совершить одну атаку _Укусом_.`,
    },
  ],
  genderId: GENDER_MALE,
}
