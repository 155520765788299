const {
  CR_3,
  CR_7,
  CR_9,
  CR_11,
  CR_12,
} = require('./../../../../crList')
const {
  SIZE_MEDIUM,
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_GHOST,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_MYRMIDON,
  CREATURE_TYPE_SHADAR_KAI,
  CREATURE_TYPE_SORROW_SWORN,
  CREATURE_TYPE_UNDEAD,
  CREATURE_TYPE_YUGOLOTH,
} = require('./../../../../creatureTypeIdList')
const {
  ALIGNMENT_ANY,
  ALIGNMENT_NE,
  ALIGNMENT_N,
} = require('./../../../../aligmentList')
const {
  SPEED_FLY,
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  LANG_ABYSSAL,
  LANG_ALL_KNOWN_AT_LIFE,
  LANG_AQUAN,
  LANG_AURAN,
  LANG_COMMON,
  LANG_ELVEN,
  LANG_IGNAN,
  LANG_INFERNAL,
  LANG_ONE_OF_ITS_CREATOR,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
  DAMAGE_THUNDER,
  DAMAGE_WEAPON_DARK,
} = require('./../../../../damageTypeList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  SKILL_DECEPTION,
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
  SKILL_SURVIVAL,
} = require('./../../../../skillList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SPELL_BESTOW_CURSE,
  SPELL_CHAIN_LIGHTNING,
  SPELL_CHARM_PERSON,
  SPELL_CHILL_TOUCH,
  SPELL_CONTROL_WATER,
  SPELL_CONTROL_WEATHER,
  SPELL_CROWN_OF_MADNESS,
  SPELL_DARKNESS,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_FEAR,
  SPELL_FEEBLEMIND,
  SPELL_FINGER_OF_DEATH,
  SPELL_GASEOUS_FORM,
  SPELL_GLOBE_OF_INVULNERABILITY,
  SPELL_GREATER_RESTORATION,
  SPELL_GUST_OF_WIND,
  SPELL_INVISIBILITY,
  SPELL_LIGHTNING_BOLT,
  SPELL_PHANTASMAL_KILLER,
  SPELL_POISON_SPRAY,
  SPELL_REMOVE_CURSE,
  SPELL_SEEMING,
  SPELL_SLEEP,
  SPELL_SUGGESTION,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_ICE,
  SPELL_WATER_WALK,
  SPELL_WISH,
} = require('./../../../../spellIdList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
} = require('./../../../../conditionList')
const {
  GEAR_PLATE_ARMOR,
  GEAR_SCIMITAR,
  GEAR_STUDDED_LEATHER_ARMOR,
  GEAR_TRIDENT,
} = require('./../../../../gearIdList')
const {
  elementalMyrmidonDescription,
  shadarKaiDescriptionList,
  sorrowSwornDescriptionList,
  yugolothDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_FEY_ANCESTRY,
  ABILITY_INCORPOREAL_MOVEMENT,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_TELEPORT,
  ABILITY_TURN_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_ARCANALOTH,
  CREATURE_DHERGOLOTH,
  CREATURE_EIDOLON,
  CREATURE_ELEMENTAL_MYRMIDON_AIR,
  CREATURE_ELEMENTAL_MYRMIDON_FIRE,
  CREATURE_ELEMENTAL_MYRMIDON_WATER,
  CREATURE_HYDROLOTH,
  CREATURE_MERRENOLOTH,
  CREATURE_OINOLOTH,
  CREATURE_SOUL_MONGER,
  CREATURE_THE_LONELY,
  CREATURE_ULTROLOTH,
  CREATURE_YAGNOLOTH,
} = require('./../../../../creatureIdList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = [
  {
    name: 'Одинокий',
    nameAlt: 'Одиночество',
    nameEn: 'The Lonely',
    id: CREATURE_THE_LONELY,
    description: [
      `Тоска от замкнутости поражает многих существ, скрывающихся в Царстве Теней, но потребность в компании ни у кого никогда не проявлялась сильнее, чем у Одиноких. Когда эти горезванные замечают других существ, они очень остро ощущают потребность в общении, и потому запускают свои гарпуновидные руки, чтобы подтащить жертв поближе.`,
        ...sorrowSwornDescriptionList,
    ],
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_MONSTROSITY,
      CREATURE_TYPE_SORROW_SWORN,
    ],
    alignmentId: ALIGNMENT_NE,
    source: {
      id: SOURCE_MTOF,
      page: 238,
    },
    armor: {
      ac: 16,
      type: ARMOR_TYPE_NATURAL,
    },
    hp: {
      diceCount: 15,
    },
    speed: {
      [SPEED_WALK]: 30,
    },
    params: {
      [PARAM_STR]: 16,
      [PARAM_DEX]: 12,
      [PARAM_CON]: 17,
      [PARAM_INT]: 6,
      [PARAM_WIT]: 11,
      [PARAM_CHA]: 6,
    },
    resistanceList: [
      DAMAGE_WEAPON_DARK,
    ],
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_COMMON,
    ],
    cr: CR_9,
    featureList: [
      {
        name: 'Телепатическая пиявка',
        description: `В начале хода Одинокого каждое существо в пределах 5 фт. от него должно пройти испытание Мудрости СЛ 15, или получить 10 (3к6) урона психической энергией.`,
      },
      {
        name: 'Душа компании',
        description: `Одинокий атакует с преимуществом, если в пределах 30 фт. от него есть как минимум два других существа. В противном случае он атакует с помехой.`,
      },
    ],
    actionList: [
      {
        name: 'Мультиатака',
        description: `Одинокий совершает атаку _Рукой-гарпуном_ и использует _Скорбное объятие_.`,
      },
      {
        name: 'Рука-гарпун',
        description: `Цель становится схваченной (СЛ высвобождения 15), если она Большого размера или меньше. У Одинокого две руки-гарпуна, каждой из которых он может схватить по одному существу.`,
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 7,
          range: 60,
          hit: {
            type: DAMAGE_PIERCING,
            diceCount: 4,
            diceType: 8,
            diceBonus: 3,
          },
        },
      },
      {
        name: 'Скорбное объятие',
        description: `Каждое схваченное Одиноким существо должно пройти испытание Мудрости СЛ 15. Существо получает 18 (4к8) урона психической энергией в случае провала, или половину урона в случае успеха. В обоих случаях Одинокий подтягивает существо на 30 фт. к себе.`,
      },
    ],
  },
  {
    name: 'Торговец душами',
    nameEn: 'Soul Monger',
    id: CREATURE_SOUL_MONGER,
    description: [
      `Отчаявшиеся от утраты воспоминаний о более ярких временах, торговцы душами жаждут жизненной силы других существ. Болезненная пустота внутри торговца душами излучается наружу, проявляя себя невыносимым бременем, истощая силы любого, кому настолько не повезёт, что он окажется рядом. Те, кто спасся от нападения торговца душами, едва могут забыть звук, который он издаёт — стон замученной души, потерявшейся в бездонном колодце трагедии.`,
      ...shadarKaiDescriptionList,
    ],
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_HUMANOID,
      CREATURE_TYPE_ELF,
      CREATURE_TYPE_SHADAR_KAI,
    ],
    alignmentId: ALIGNMENT_N,
    source: [
      {
        id: SOURCE_MTOF,
        page: 241,
      },
      {
        url: `https://5e.tools/bestiary.html#soul%20monger_mtf`,
        problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Торговца душами» вместо иммунитета к психическому урону почему-то указан иммунитет к урону ядом.`,
      },
    ],
    armor: {
      ac: 15,
      gearId: GEAR_STUDDED_LEATHER_ARMOR,
    },
    hp: {
      diceCount: 19,
    },
    speed: {
      [SPEED_WALK]: 30,
    },
    params: {
      [PARAM_STR]: 8,
      [PARAM_DEX]: 17,
      [PARAM_CON]: 14,
      [PARAM_INT]: 19,
      [PARAM_WIT]: 15,
      [PARAM_CHA]: 13,
    },
    saveThrowList: [
      PARAM_DEX,
      PARAM_WIT,
      PARAM_CHA,
    ],
    skillCollection: {
      [SKILL_PERCEPTION]: PROF_NORMAL,
    },
    immunityList: [
      DAMAGE_NECROTIC,
      DAMAGE_PSYCHIC,
    ],
    immunityConditionList: [
      CONDITION_CHARMED,
      CONDITION_EXHAUSTION,
      CONDITION_FRIGHTENED,
    ],
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_COMMON,
      LANG_ELVEN,
    ],
    cr: CR_11,
    featureList: [
      ABILITY_FEY_ANCESTRY,
      ABILITY_MAGIC_RESISTANCE,
      {
        name: 'Жажда душ',
        description: `Когда торговец душами опускает хиты существа до 0, он может получить временные хиты в количестве половины от максимальных хитов этого существа. Пока торговец душами обладает временными хитами, полученными от _Жажды душ_, он получает преимущество на атаки.`,
      },
      {
        name: 'Бремя поколений',
        description: `Каждый зверь или гуманоид, отличный от шадар-каев, начинающий свой ход в пределах 5 фт. от торговца душами, понижает свою скорость на 20 фт. до начала своего следующего хода.`,
      },
    ],
    spellCast: {
      baseStat: PARAM_INT,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_CHILL_TOUCH,
            SPELL_POISON_SPRAY,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_BESTOW_CURSE,
            SPELL_CHAIN_LIGHTNING,
            SPELL_FINGER_OF_DEATH,
            SPELL_GASEOUS_FORM,
            SPELL_PHANTASMAL_KILLER,
            SPELL_SEEMING,
          ],
        },
      ],
    },
    actionList: [
      {
        name: 'Мультиатака',
        description: `Торговец душами совершает две атаки _Призрачным кинжалом_.`,
      },
      {
        name: 'Призрачный кинжал',
        description: `После этого цель с помехой проходит испытания до начала следующего хода торговца душами.`,
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 7,
          range: 5,
          hit: [
            {
              type: DAMAGE_PIERCING,
              diceCount: 4,
              diceType: 4,
              diceBonus: 3,
            },
            {
              type: DAMAGE_NECROTIC,
              diceCount: 3,
              diceType: 12,
              diceBonus: 0,
            },
          ],
        },
      },
    ],
    reactionList: [
      {
        name: 'Волна утомления',
        restore: 4,
        description: `Торговец душами испускает утомление в кубе со стороной 60 футов. Каждое существо в этой области должно пройти испытание Телосложения СЛ 16. При провале существо получает 45 (10к8) урона психической энергией и 1 уровень Истощения. При успехе существо получает 22 (5к8) урона психической энергией.`,
      },
    ],
  },
  {
    name: 'Эйдолон',
    nameEn: 'Eidolon',
    id: CREATURE_EIDOLON,
    description: `У божеств есть много способов защитить места, которые они считают святыми. Одни из помощников, на которых они полагаются в этом, эйдолоны — призрачные духи, связанные священной клятвой защищать место, посвящённое своему божеству. Созданные из душ тех, кто доказал свою преданность, эйдолоны крадутся по храмам и склепам, по местам, где происходили чудеса и спрятаны реликвии, чтобы ни один враг не смог заполучить как-либо помешать божествам, захватив, осквернив, или верша насилие в подобных местах. Если враг с такими намерениями вступает в защищённое место, эйдолон входит в высеченный сосуд — статую, специально приготовленную для размещения душ этих защитников. Затем эйдолон оживляет статую и использует позаимствованное тело, чтобы выдавить злоумышленников, которые пришли красть реликвии, оставленные под охраной.

**Священные хранители.** Для создания эйдолона требуется фанатично преданный дух — личность человека, который при жизни беспрекословно служил своей вере. После смерти божество может наградить такого последователя вечным служением на защите священного места. У эйдолона нет других целей, кроме как охраны места, куда он был назначен, и он никогда не уйдёт.

**Анимированные статуи.** У эйдолонов мало способов защищать себя, кроме как пробуждением святых сосудов. Когда злоумышленники вторгаются, эйдолон сливается с одной или несколькими статуями. После этого он контролирует конструкта, будто тот — его собственное тело, используя кулаки для того чтобы прогнать нарушителей, разбить и сокрушить все, до чего достанет.

**Природа нежити.** Эйдолон не нуждается в воздухе, пище, питье или сне.`,
    note: {
      text: `Не только боги имеют достаточно силы, чтобы привязывать духов к своим идолам. Существа, такие как архидьяволы, могут делать это с душами своих культистов. Молох, например.`,
      author: `Архимаг Морденкайнен`,
    },
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_UNDEAD,
      CREATURE_TYPE_GHOST,
    ],
    alignmentId: ALIGNMENT_ANY,
    source: {
      id: SOURCE_MTOF,
      page: 242,
    },
    armor: 9,
    hp: {
      diceCount: 18,
    },
    speed: {
      [SPEED_WALK]: 0,
      [SPEED_FLY]: {
        value: 40,
        isFloating: true,
      },
    },
    params: {
      [PARAM_STR]: 7,
      [PARAM_DEX]: 8,
      [PARAM_CON]: 9,
      [PARAM_INT]: 14,
      [PARAM_WIT]: 19,
      [PARAM_CHA]: 16,
    },
    saveThrowList: [
      PARAM_WIT,
    ],
    skillCollection: {
      [SKILL_PERCEPTION]: PROF_NORMAL,
    },
    resistanceList: [
      DAMAGE_ACID,
      DAMAGE_FIRE,
      DAMAGE_ELECTRICITY,
      DAMAGE_THUNDER,
      DAMAGE_NONMAGIC_WEAPON,
    ],
    immunityList: [
      DAMAGE_COLD,
      DAMAGE_NECROTIC,
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_CHARMED,
      CONDITION_EXHAUSTION,
      CONDITION_FRIGHTENED,
      CONDITION_GRAPPLED,
      CONDITION_PARALYZED,
      CONDITION_PETRIFIED,
      CONDITION_POISONED,
      CONDITION_PRONE,
      CONDITION_RESTRAINED,
    ],
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_ALL_KNOWN_AT_LIFE,
    ],
    cr: CR_12,
    featureList: [
      ABILITY_INCORPOREAL_MOVEMENT,
      {
        name: 'Священное оживление',
        restore: 5,
        description: `Когда эйдолон двигается сквозь пространство, занятое священной статуей, эйдолон исчезает, превращая статую в существо, находящееся под его контролем. Эйдолон использует параметры священной статуи, заменяя ими свои параметры.`,
      },
      ABILITY_TURN_RESISTANCE,
    ],
    actionList: [
      {
        name: 'Божественный ужас',
        description: `Каждое существо в пределах 60 фт. от эйдолона, которое может его видеть, должно пройти испытание Мудрости СЛ 15, или испугаться на 1 минуту. Пока существо испугано таким образом, оно должно совершать действие Рывок и удаляться от эйдолона максимально безопасным маршрутом в начале каждого своего хода. Если существу некуда двигаться, то оно ошеломляется до того момента, пока не сможет удаляться от эйдолона вновь. Испуганное существо повторяет испытание в конце каждого своего хода, завершая эффект при успехе. При успешном испытании цель становится иммунной к _Божественному ужасу_ всех эйдолонов на следующие 24 часа.`,
      },
    ],
  },
  {
    name: 'Водный элементальный мирмидон',
    nameEn: 'Water Elemental Myrmidon',
    id: CREATURE_ELEMENTAL_MYRMIDON_WATER,
    description: elementalMyrmidonDescription,
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_ELEMENTAL,
      CREATURE_TYPE_MYRMIDON,
    ],
    alignmentId: ALIGNMENT_N,
    source: [
      {
        id: SOURCE_MTOF,
        page: 246,
      },
      {
        url: `https://5e.tools/bestiary.html#water%20elemental%20myrmidon_mtf`,
        problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Водного элементального мирмидона» не указано, что действие «Замораживающие удары» наносит дополнительный урон холодом, а не заменяет колющий урон трезубца на урон холодом.`,
      },
    ],
    armor: {
      ac: 18,
      gearId: GEAR_PLATE_ARMOR,
    },
    hp: {
      diceCount: 17,
    },
    speed: {
      [SPEED_WALK]: 40,
      [SPEED_SWIM]: 40,
    },
    params: {
      [PARAM_STR]: 18,
      [PARAM_DEX]: 14,
      [PARAM_CON]: 15,
      [PARAM_INT]: 8,
      [PARAM_WIT]: 10,
      [PARAM_CHA]: 10,
    },
    resistanceList: [
      DAMAGE_ACID,
      DAMAGE_NONMAGIC_WEAPON,
    ],
    immunityList: [
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_PARALYZED,
      CONDITION_PETRIFIED,
      CONDITION_POISONED,
      CONDITION_PRONE,
    ],
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_AQUAN,
      LANG_ONE_OF_ITS_CREATOR,
    ],
    cr: CR_7,
    featureList: [
      ABILITY_MAGIC_WEAPONS,
    ],
    actionList: [
      {
        name: 'Мультиатака',
        description: `Мирмидон совершает три атаки _Трезубцем_.`,
      },
      {
        gearId: GEAR_TRIDENT,
      },
      {
        name: 'Замораживающие удары',
        restore: 6,
        description: `Мирмидон использует _Мультиатаку_. Каждая успешная атака наносит дополнительно 5 (1к10) урона холодом. Скорость цели, поражённой таким образом, уменьшается на 10 фт. до конца следующего хода мирмидона.`,
      },
    ],
  },
  {
    name: 'Воздушный элементальный мирмидон',
    nameEn: 'Air Elemental Myrmidon',
    id: CREATURE_ELEMENTAL_MYRMIDON_AIR,
    description: elementalMyrmidonDescription,
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_ELEMENTAL,
      CREATURE_TYPE_MYRMIDON,
    ],
    alignmentId: ALIGNMENT_N,
    source: {
      id: SOURCE_MTOF,
      page: 246,
    },
    armor: {
      ac: 18,
      gearId: GEAR_PLATE_ARMOR,
    },
    hp: {
      diceCount: 18,
    },
    speed: {
      [SPEED_WALK]: 30,
      [SPEED_FLY]: {
        value: 30,
        isFloating: true,
      },
    },
    params: {
      [PARAM_STR]: 18,
      [PARAM_DEX]: 14,
      [PARAM_CON]: 14,
      [PARAM_INT]: 9,
      [PARAM_WIT]: 10,
      [PARAM_CHA]: 10,
    },
    resistanceList: [
      DAMAGE_ELECTRICITY,
      DAMAGE_THUNDER,
      DAMAGE_NONMAGIC_WEAPON,
    ],
    immunityList: [
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_PARALYZED,
      CONDITION_PETRIFIED,
      CONDITION_POISONED,
      CONDITION_PRONE,
    ],
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_AURAN,
      LANG_ONE_OF_ITS_CREATOR,
    ],
    cr: CR_7,
    featureList: [
      ABILITY_MAGIC_WEAPONS,
    ],
    actionList: [
      {
        name: 'Мультиатака',
        description: `Мирмидон совершает три атаки _Цепом_.`,
      },
      {
        name: 'Цеп',
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 7,
          range: 5,
          hit: {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 8,
            diceBonus: 4,
          },
        },
      },
      {
        name: 'Удар молнии',
        restore: 6,
        description: `Мирмидон совершает одну атаку _Цепом_. При попадании цель получает дополнительно 18 (4к8) урона электричеством и должна пройти испытание Телосложения СЛ 13. При провале цель становится Ошеломлённой до конца следующего хода мирмидона.`,
      },
    ],
  },
  {
    name: 'Огненный элементальный мирмидон',
    nameEn: 'Fire Elemental Myrmidon',
    id: CREATURE_ELEMENTAL_MYRMIDON_FIRE,
    description: elementalMyrmidonDescription,
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_ELEMENTAL,
      CREATURE_TYPE_MYRMIDON,
    ],
    alignmentId: ALIGNMENT_N,
    source: [
      {
        id: SOURCE_MTOF,
        page: 247,
      },
      {
        url: `https://5e.tools/bestiary.html#water%20elemental%20myrmidon_mtf`,
        problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Огненного элементального мирмидона» пропущена особенность «Чувствительность к воде».`,
      },
    ],
    armor: {
      ac: 18,
      gearId: GEAR_PLATE_ARMOR,
    },
    hp: {
      diceCount: 19,
    },
    speed: {
      [SPEED_WALK]: 40,
    },
    params: {
      [PARAM_STR]: 13,
      [PARAM_DEX]: 18,
      [PARAM_CON]: 15,
      [PARAM_INT]: 9,
      [PARAM_WIT]: 10,
      [PARAM_CHA]: 10,
    },
    resistanceList: [
      DAMAGE_NONMAGIC_WEAPON,
    ],
    immunityList: [
      DAMAGE_FIRE,
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_PARALYZED,
      CONDITION_PETRIFIED,
      CONDITION_POISONED,
      CONDITION_PRONE,
    ],
    senseList: [
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_IGNAN,
      LANG_ONE_OF_ITS_CREATOR,
    ],
    cr: CR_7,
    featureList: [
      {
        name: 'Свечение',
        description: `Элементаль испускает яркий свет в радиусе 20 футов и тусклый свет в пределах ещё 40 футов.`,
      },
      ABILITY_MAGIC_WEAPONS,
      {
        name: 'Чувствительность к воде',
        description: `На каждые 5 футов, которые мирмидон проходит в воде глубиной 1 фут и более, он получает 2 (1к4) урона холодом.`,
      },
    ],
    actionList: [
      {
        name: 'Мультиатака',
        description: `Мирмидон совершает три атаки _Скимитаром_.`,
      },
      {
        gearId: GEAR_SCIMITAR,
      },
      {
        name: 'Воспламеняющие удары',
        restore: 6,
        description: `Мирмидон использует _Мультиатаку_. Каждая успешная атака причиняет дополнительные 5 (1к10) огненного урона.`,
      },
    ],
  },
  {
    name: 'Гидролот',
    nameEn: 'Hydroloth',
    id: CREATURE_HYDROLOTH,
    description: [
      `Подобно крадущим мысли водам реки Стикс, в которой они обитают, гидролоты крадут мысли существ, которых они атакуют, забирая мысли для передачи любому хозяину, которому они служат. Гидролоты также умеют находить потерянные вещи, особенно те, что уже давно лежат в морской глубине.

В подводных конфликтах и нападениях, гидролотам нет равных среди прочих юголотов. Иногда они нанимают самих себя для нападений и ограблений на корабли, а также для совершения набегов на прибрежные поселения.`,
      ...yugolothDescriptionList,
    ],
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_FIEND,
      CREATURE_TYPE_YUGOLOTH,
    ],
    alignmentId: ALIGNMENT_NE,
    source: {
      id: SOURCE_MTOF,
      page: 248,
    },
    armor: 15,
    hp: {
      diceCount: 18,
    },
    speed: {
      [SPEED_WALK]: 20,
      [SPEED_SWIM]: 40,
    },
    params: {
      [PARAM_STR]: 12,
      [PARAM_DEX]: 21,
      [PARAM_CON]: 16,
      [PARAM_INT]: 19,
      [PARAM_WIT]: 10,
      [PARAM_CHA]: 14,
    },
    skillCollection: {
      [SKILL_INSIGHT]: PROF_NORMAL,
      [SKILL_PERCEPTION]: PROF_NORMAL,
    },
    vulnerabilityList: [
      DAMAGE_FIRE,
    ],
    resistanceList: [
      DAMAGE_COLD,
      DAMAGE_ELECTRICITY,
      DAMAGE_NONMAGIC_WEAPON,
    ],
    immunityList: [
      DAMAGE_ACID,
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_POISONED,
    ],
    senseList: [
      {
        id: SENSE_BLIND_VISION,
        value: 60,
      },
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_ABYSSAL,
      LANG_INFERNAL,
      {
        id: LANG_TELEPATHY,
        range: 60,
      },
    ],
    cr: CR_9,
    featureList: [
      ABILITY_AMPHIBIOUS,
      ABILITY_MAGIC_RESISTANCE,
      ABILITY_MAGIC_WEAPONS,
      {
        name: 'Защищённая память',
        description: `Гидролот невосприимчив к водам реки Стикс так же, как и к эффектам кражи или изменения воспоминаний, а также к попыткам прочитать его мысли.`,
      },
    ],
    spellCast: {
      baseStat: PARAM_INT,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DARKNESS,
            SPELL_DETECT_MAGIC,
            SPELL_DISPEL_MAGIC,
            SPELL_WATER_WALK,
            {
              id: SPELL_INVISIBILITY,
              comment: 'только на себя',
            },
          ],
        },
        {
          limit: {
            count: 3,
            period: 'день',
          },
          list: [
            SPELL_CONTROL_WATER,
            SPELL_CROWN_OF_MADNESS,
            SPELL_FEAR,
            SPELL_PHANTASMAL_KILLER,
            SPELL_SUGGESTION,
          ],
        },
      ],
    },
    actionList: [
      {
        name: 'Мультиатака',
        description: `Гидролот совершает две рукопашные атаки. Вместо одной из этих атак он может наложить заклинание, требующее 1 действие.`,
      },
      {
        name: 'Когти',
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 9,
          range: 5,
          hit: {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 5,
          },
        },
      },
      {
        name: 'Укус',
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 9,
          range: 5,
          hit: {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 10,
            diceBonus: 5,
          },
        },
      },
      {
        name: 'Кража памяти',
        limit: {
          count: 1,
          period: 'день',
        },
        description: `Гидролот выбирает целью видимое им существо, находящееся в пределах 60 футов от себя. Цель получает 4к6 урона психической энергией, и должна пройти испытание Интеллекта СЛ 16. При успехе цель становится невосприимчива к _Краже памяти_ этого гидролота на следующие 24 часа. При провалае цель теряет все бонусы мастерства, не может сотворять заклинания, не понимает языков, а значение её Харизмы и Интеллекта, которые были выше 5, становятся равны 5. Каждый раз, когда цель совершает длинный отдых, она может повторять испытание, заканчивая эффект при успехе. Заклинания [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}) и [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) могут завершить эффект на цели.`,
      },
      {
        id: ABILITY_TELEPORT,
        range: 60,
      },
    ],
  },
  {
    name: 'Дерголот',
    nameEn: 'Dhergoloth',
    id: CREATURE_DHERGOLOTH,
    description: [
      `Дерголоты врываются в битву подобно вихрям разрушений, высвобождая пять наборов когтей, растущих из своих бочкообразных тел. С ними заключают сделки для подавления восстания, избавления от толпы и уничтожения разведчиков и стрелков. Они наслаждаются устроенной ими резнёй, а их выбивающий из колеи смех заглушает крики собственных жертв.

Поскольку дерголоты являются не более чем глупыми громилами, нанимателям приходится проявлять особую осторожность в подаче указаний этим исчадиям. Дерголоты могут справиться с простыми заданиями, не требующих много времени на их выполнение. Если им дать задания посложнее, то они либо забудут сказанные указания, либо вообще прослушают их, а затем выполняют задания, провалив их.`,
      ...yugolothDescriptionList,
    ],
    note: {
      text: `Голова дерголота не поворачивается вместе с неистово вращающимся торсом, а его торс вращается в отдельном направлении от прыгающих ног. Я хотел бы провести вивисекцию (вскрытие живого организма) дерголота, чтоб выяснить, каким образом происходит этот процесс.`,
      author: `Архимаг Морденкайнен`,
    },
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_FIEND,
      CREATURE_TYPE_YUGOLOTH,
    ],
    alignmentId: ALIGNMENT_NE,
    source: {
      id: SOURCE_MTOF,
      page: 249,
    },
    armor: {
      ac: 15,
      type: ARMOR_TYPE_NATURAL,
    },
    hp: {
      diceCount: 14,
    },
    speed: {
      [SPEED_WALK]: 30,
    },
    params: {
      [PARAM_STR]: 17,
      [PARAM_DEX]: 10,
      [PARAM_CON]: 19,
      [PARAM_INT]: 7,
      [PARAM_WIT]: 10,
      [PARAM_CHA]: 9,
    },
    saveThrowList: [
      PARAM_STR,
    ],
    resistanceList: [
      DAMAGE_COLD,
      DAMAGE_FIRE,
      DAMAGE_ELECTRICITY,
      DAMAGE_NONMAGIC_WEAPON,
    ],
    immunityList: [
      DAMAGE_ACID,
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_POISONED,
    ],
    senseList: [
      {
        id: SENSE_BLIND_VISION,
        value: 60,
      },
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_ABYSSAL,
      LANG_INFERNAL,
      {
        id: LANG_TELEPATHY,
        range: 60,
      },
    ],
    cr: CR_7,
    featureList: [
      ABILITY_MAGIC_RESISTANCE,
      ABILITY_MAGIC_WEAPONS,
    ],
    spellCast: {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DARKNESS,
            SPELL_FEAR,
          ],
        },
        {
          limit: {
            count: 3,
            period: 'день',
          },
          list: [
            SPELL_SLEEP,
          ],
        },
      ],
    },
    actionList: [
      {
        name: 'Мультиатака',
        description: `Дерголот совершает две атаки _Когтями_.`,
      },
      {
        name: 'Когти',
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 6,
          range: 5,
          hit: {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 8,
            diceBonus: 3,
          },
        },
      },
      {
        name: 'Размахивание когтями',
        restore: 5,
        description: `Дерголот передвигается по прямой линии на расстояние, не превышающее его скорость, и выбирает целями всех существ на расстоянии 5 футов от себя, пока движется. Каждая цель должна пройти испытание Ловкости СЛ 14, иначе получит 22 (3к12 + 3) рубящего урона.`,
      },
      {
        id: ABILITY_TELEPORT,
        range: 60,
      },
    ],
  },
  {
    name: 'Мерренолот',
    nameEn: 'Merrenoloth',
    id: CREATURE_MERRENOLOTH,
    description: [
      `Мрачные, сухопарые капитаны паромов на реке Стикс, мерренолоты обладают полной властью над своими судами, гарантируя, что их пассажиры безопасно достигнут своей цели. Иногда мерренолотов можно уговорить покинуть Нижние Планы и взять командование над другим судном, обеспечив этим защиту корабля и экипажа.

Всякий раз, когда мерренолот заключает капитанский контракт на судно, он связывается с судном, чтобы быть уверенным, что во время путешествия всё будет так, как нужно. Мерренолот может безопасно вести своё судно через абсолютно любой шторм, всегда оставаясь на прежнем курсе, и не встречаясь с кучей проблем, что мешают прочим капитанам.

Мерренолот может постоять за себя в бою, но предпочитает, по возможности, избегать боя. На самом деле, он всегда указывает в контракте, что не обязан вступать в бой. Первая обязанность мерренолота — его судно.`,
      {
        header: 'Действия логова',
        text: `Любой корабль, с которым мерренолот заключает договор на становление капитаном судна, автоматически становится логовом этого существа. При сражении на корабле, мерренолот может использовать действия логова. При значении инициативы «20» (проигрыш при ничье), мерренолот может предпринять одно действие логова для создания одного из нижеследующих эффектов; он не может использовать один и тот же эффект два раза подряд.

* Корабль восстанавливает 22 (4к10) хитов.
* Сильный ветер подгоняет корабль, увеличивая скорость корабля на 30 фт. до достижения в следующем раунде, значения инициативы 20.
* На расстоянии 60 фт. вокруг корабля образуются вьющие ветра. Пока в следующем раунде инициатива не достигнет 20, эта область считается труднопроходимой местностью, и когда среднее или меньшее существо входит в эту область, или начинает свой раунд, будучи на лету, оно должно пройти испытание Силы СЛ 13, иначе будет сбито с ног`,
      },
      {
        header: 'Местные эффекты',
        text: `Мерренолот наполняет судно мощной магией, создавая один или несколько из нижеследующих эффектов:

* Корабль не тонет, даже если в корпусе пробоины.
* Корабль всегда держит курс до места назначения, указанного мерренолотом.
* Существа, по выбору мерренолота, которые находятся на корабле не испытывают дискомфорта от ветра или погоды, этот эффект не защищает от урона.

Если мерренолот умирает, эти эффекты пропадают в течении 1к6 часов.`,
      },
      ...yugolothDescriptionList,
    ],
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_FIEND,
      CREATURE_TYPE_YUGOLOTH,
    ],
    alignmentId: ALIGNMENT_NE,
    source: {
      id: SOURCE_MTOF,
      page: 251,
    },
    armor: 13,
    hp: {
      diceCount: 9,
    },
    speed: {
      [SPEED_WALK]: 30,
      [SPEED_SWIM]: 40,
    },
    params: {
      [PARAM_STR]: 8,
      [PARAM_DEX]: 17,
      [PARAM_CON]: 10,
      [PARAM_INT]: 17,
      [PARAM_WIT]: 14,
      [PARAM_CHA]: 11,
    },
    saveThrowList: [
      PARAM_DEX,
      PARAM_INT,
    ],
    skillCollection: {
      [SKILL_HISTORY]: PROF_NORMAL,
      [SKILL_NATURE]: PROF_NORMAL,
      [SKILL_PERCEPTION]: PROF_NORMAL,
      [SKILL_SURVIVAL]: PROF_NORMAL,
    },
    resistanceList: [
      DAMAGE_COLD,
      DAMAGE_FIRE,
      DAMAGE_ELECTRICITY,
      DAMAGE_NONMAGIC_WEAPON,
    ],
    immunityList: [
      DAMAGE_ACID,
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_POISONED,
    ],
    senseList: [
      {
        id: SENSE_BLIND_VISION,
        value: 60,
      },
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_ABYSSAL,
      LANG_INFERNAL,
      {
        id: LANG_TELEPATHY,
        range: 60,
      },
    ],
    cr: CR_3,
    featureList: [
      ABILITY_MAGIC_RESISTANCE,
      ABILITY_MAGIC_WEAPONS,
      {
        name: 'Телепортация',
        description: `Бонусным действием мерренолот может телепортироваться со всем снаряжением, которое он несёт и носит, на расстояние до 60 фт. в незанятое пространство, которое он может видеть.`,
      },
    ],
    spellCast: {
      baseStat: PARAM_INT,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_CHARM_PERSON,
            SPELL_DARKNESS,
            SPELL_DETECT_MAGIC,
            SPELL_DISPEL_MAGIC,
            SPELL_GUST_OF_WIND,
          ],
        },
        {
          limit: {
            count: 3,
            period: 'день',
          },
          list: [
            SPELL_CONTROL_WATER,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_CONTROL_WEATHER,
          ],
        },
      ],
    },
    actionList: [
      {
        name: 'Мультиатака',
        description: `Мерренолот один раз использует _Устрашающий взгляд_ и совершает одну атаку _Веслом_.`,
      },
      {
        name: 'Весло',
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 5,
          range: 5,
          hit: {
            type: DAMAGE_SLASHING,
            diceCount: 2,
            diceType: 4,
            diceBonus: 3,
          },
        },
      },
      {
        name: 'Устрашающий взгляд',
        description: `Мерренолот выбирает целью одно существо, которое может видеть в пределах 60 фт. от себя. Цель должна пройти испытание Мудрости СЛ 13, иначе станет испуганной мерренолотом на 1 минуту. Испуганная цель может повторять испытание в конце каждого своего хода, завершая эффект в случае успеха.`,
      },
    ],
  },
  {
    name: 'Ойнолот',
    nameEn: 'Oinoloth',
    id: CREATURE_OINOLOTH,
    description: [
      `Мрачные призраки смерти, ойнолоты несут чуму, куда бы они ни шли. Одно их простое появления заставляет солдат грозных армий оказаться сломленными духом и бежать, чтобы не поддаться одной из ужасных хворей, что источают ойнолоты.

Ойнолоты предоставляют окончательное решение трудных вопросов, как правило, убивая всех, кто замешан в этом. Их нанимают как крайнюю меру, когда осада проходит слишком долго или армия врага слишком сильна, чтобы её победить. Будучи призванными, ойнолоты скрываются на полях боя, отравляя землю и заражая встречных существ. Иногда их нанимают для борьбы с заражением, которую они сами распространили, но цена подобной работы весьма велика, а спасённые их силами существа превращаются в обессиливших.`,
      ...yugolothDescriptionList,
    ],
    sizeType: SIZE_MEDIUM,
    creatureTypeIdList: [
      CREATURE_TYPE_FIEND,
      CREATURE_TYPE_YUGOLOTH,
    ],
    alignmentId: ALIGNMENT_NE,
    source: {
      id: SOURCE_MTOF,
      page: 252,
    },
    armor: {
      ac: 17,
      type: ARMOR_TYPE_NATURAL,
    },
    hp: {
      diceCount: 12,
    },
    speed: {
      [SPEED_WALK]: 40,
    },
    params: {
      [PARAM_STR]: 19,
      [PARAM_DEX]: 17,
      [PARAM_CON]: 18,
      [PARAM_INT]: 17,
      [PARAM_WIT]: 16,
      [PARAM_CHA]: 19,
    },
    saveThrowList: [
      PARAM_CON,
      PARAM_WIT,
    ],
    skillCollection: {
      [SKILL_DECEPTION]: PROF_NORMAL,
      [SKILL_INTIMIDATION]: PROF_NORMAL,
      [SKILL_PERCEPTION]: PROF_NORMAL,
    },
    resistanceList: [
      DAMAGE_COLD,
      DAMAGE_FIRE,
      DAMAGE_ELECTRICITY,
      DAMAGE_NONMAGIC_WEAPON,
    ],
    immunityList: [
      DAMAGE_ACID,
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_POISONED,
    ],
    senseList: [
      {
        id: SENSE_BLIND_VISION,
        value: 60,
      },
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_ABYSSAL,
      LANG_INFERNAL,
      {
        id: LANG_TELEPATHY,
        range: 60,
      },
    ],
    cr: CR_12,
    featureList: [
      {
        name: 'Вестник чумы',
        restore: 5,
        description: `Бонусным действием ойнолот заражает пространство в пределах 30 футов от себя. Заражение длится 24 часа. Пока длится заражение, все обычные растения в области чахнут и умирают, а количество хитов, восстанавливаемых заклинаниями уменьшается вдвое.

Также, когда существо двигается через заражённую область или начинает в ней ход, оно должно пройти испытание Телосложения СЛ 16. При успехе существо становится невосприимчивым к способности _Вестник Чумы_ этого ойнолота на следующие 24 часа. При провале оно получает 14 (4к6) урона некротической энергией и становится отравленным. Отравленное существо не может восстанавливать хиты. Через каждые 24 часа оно может повторять испытание. При провале максимальное значение хитов существа уменьшается на 5 (1к10). Это понижение длится до завершения отравления, кроме того, цель погибает, если его максимальное значение хитов становится равным 0. Отравление проходит после того, как существо трижды преуспеет в испытании.`,
      },
      ABILITY_MAGIC_RESISTANCE,
      ABILITY_MAGIC_WEAPONS,
    ],
    spellCast: {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DARKNESS,
            SPELL_DETECT_MAGIC,
            SPELL_DISPEL_MAGIC,
            {
              id: SPELL_INVISIBILITY,
              comment: 'только на себя',
            },
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_FEEBLEMIND,
            SPELL_GLOBE_OF_INVULNERABILITY,
            SPELL_WALL_OF_FIRE,
            SPELL_WALL_OF_ICE,
          ],
        },
      ],
    },
    actionList: [
      {
        name: 'Мультиатака',
        description: `Ойнолот использует _Пронзающий взгляд_ и совершает две атаки _Когтями_.`,
      },
      {
        name: 'Когти',
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 8,
          range: 5,
          hit: [
            {
              type: DAMAGE_SLASHING,
              diceCount: 3,
              diceType: 6,
              diceBonus: 4,
            },
            {
              type: DAMAGE_NECROTIC,
              diceCount: 4,
              diceType: 10,
              diceBonus: 0,
            },
          ],
        },
      },
      {
        name: 'Испорченное исцеление',
        restore: 6,
        description: `Ойнолот прикасается к одному согласному существу в пределах 5 футов от себя. Цель восстанавливает все свои хиты. Помимо этого, ойнолот может завершить действие одной болезни на цели, или устранить один из эффектов: ослеплён, оглушён, парализован или отравлен. После этого цель получает 1 уровень Истощения, а её максимальное значение хитов уменьшается на 7 (2к6). Ослабления могут быть устранены только заклинанием [Исполнение желаний](SPELL:${SPELL_WISH}), или трижды сотворённым в течение часа заклинанием [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}). Цель погибает, если максимальное значение её хитов опустится до 0.`,
      },
      {
        id: ABILITY_TELEPORT,
        range: 60,
      },
      {
        name: 'Пронзающий взгляд',
        description: `Ойнолот выбирает целью одно существо, которое он может видеть в пределах 30 футов от себя. Цель должна пройти испытание Мудрости СЛ 16 против этой способности, иначе станет очарованной им до конца следующего хода ойнолота. Очарованная таким образом цель обездвиживается. При успехе, цель становится невосприимчивой к _Пронзающему взгляду_ этого ойнолота на следующие 24 часа.`,
      },
    ],
  },
  {
    name: 'Ягнолот',
    nameEn: 'Yagnoloth',
    id: CREATURE_YAGNOLOTH,
    description: [
      `Любой, кто хотел бы заключить сделку с юголотами, обычно попадает к ягнолоту. Хитроумные переговорщики, эти странные исчадия занимаются заключением контактов за весь свой род. Когда ягнолот нанимается, он сообщает намерения заказчика прочим исчадиям в виде приказов.

Хотя им поручено управлять низшими юголотами, ягнолоты полностью подчиняются приказам [арканалотов](CREATURE:${CREATURE_ARCANALOTH}) и [ультролотов](CREATURE:${CREATURE_ULTROLOTH}). Но, если не брать во внимание их место в иерархии, ягнолоты обладают полнотой власти и ожидают подчинения и повиновения от прочих исчадий.

У ягнолота имеется одна рука человеческого размера, а другая подобна руке великана. Он всегда закрывает одну из них длинным плащом. Во время переговоров яголот открывает человеческую руку, и использует её для составления и подписания контрактов. Когда необходимо показать силу, или проявить себя в бою, он перекидывает плащ и раскрывает свою зверскую, мощную гигантскую руку.`,
      ...yugolothDescriptionList,
    ],
    note: {
      text: `Составленные ягнолотами контракты так же коварны, как и сделки с дьяволами. Вот только юголоты не всегда выполняют свою часть контракта.`,
      author: `Архимаг Морденкайнен`,
    },
    sizeType: SIZE_LARGE,
    creatureTypeIdList: [
      CREATURE_TYPE_FIEND,
      CREATURE_TYPE_YUGOLOTH,
    ],
    alignmentId: ALIGNMENT_NE,
    source: {
      id: SOURCE_MTOF,
      page: 253,
    },
    armor: {
      ac: 17,
      type: ARMOR_TYPE_NATURAL,
    },
    hp: {
      diceCount: 14,
    },
    speed: {
      [SPEED_WALK]: 40,
    },
    params: {
      [PARAM_STR]: 19,
      [PARAM_DEX]: 14,
      [PARAM_CON]: 21,
      [PARAM_INT]: 16,
      [PARAM_WIT]: 15,
      [PARAM_CHA]: 18,
    },
    saveThrowList: [
      PARAM_DEX,
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    skillCollection: {
      [SKILL_DECEPTION]: PROF_NORMAL,
      [SKILL_INSIGHT]: PROF_NORMAL,
      [SKILL_PERCEPTION]: PROF_NORMAL,
      [SKILL_PERSUASION]: PROF_NORMAL,
    },
    resistanceList: [
      DAMAGE_COLD,
      DAMAGE_FIRE,
      DAMAGE_ELECTRICITY,
      DAMAGE_NONMAGIC_WEAPON,
    ],
    immunityList: [
      DAMAGE_ACID,
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_POISONED,
    ],
    senseList: [
      {
        id: SENSE_BLIND_VISION,
        value: 60,
      },
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    languageList: [
      LANG_ABYSSAL,
      LANG_INFERNAL,
      {
        id: LANG_TELEPATHY,
        range: 60,
      },
    ],
    cr: CR_11,
    featureList: [
      ABILITY_MAGIC_RESISTANCE,
      ABILITY_MAGIC_WEAPONS,
    ],
    spellCast: {
      baseStat: PARAM_CHA,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_DARKNESS,
            SPELL_DISPEL_MAGIC,
            SPELL_SUGGESTION,
            SPELL_DETECT_MAGIC,
            {
              id: SPELL_INVISIBILITY,
              comment: 'только на себя',
            },
          ],
        },
        {
          limit: {
            count: 3,
            period: 'день',
          },
          list: [
            SPELL_LIGHTNING_BOLT,
          ],
        },
      ],
    },
    actionList: [
      {
        name: 'Мультиатака',
        description: ` Ягнолот совершает атаку _Массивной рукой_ и одну атаку _Электрическим касанием_, или совершает одну атаку _Массивной рукой_ и _Телепортацию_ после атаки.`,
      },
      {
        name: 'Электрическое касание',
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 8,
          range: 5,
          hit: {
            type: DAMAGE_ELECTRICITY,
            diceCount: 6,
            diceType: 8,
            diceBonus: 0,
          },
        },
      },
      {
        name: 'Массивная рука',
        description: `Если целью является существо, оно должно пройти испытание телосложения СЛ 16, иначе станет ошеломлённым до конца следующего хода ягнолота. `,
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 8,
          range: 15,
          hit: {
            type: DAMAGE_BLUDGEONING,
            diceCount: 3,
            diceType: 12,
            diceBonus: 4,
          },
        },
      },
      {
        name: 'Вытягивание жизни',
        description: `Ягнолот прикасается к одному недееспособному существу в пределах 15 футов от себя. Цель получает 36 (7к8 + 4) урона некротической энергией, а ягнолот получает временные хиты в количестве, равном половине причинённого таким образом урона. Цель должна пройти испытание Телосложения СЛ 16, иначе её максимальное значение хитов уменьшится на значение причинённого ей урона. Уменьшение хитов длится до завершения длинного отдыха целью, и цель умирает, если её максимальное значение хитов будет уменьшено до 0.`,
      },
      {
        name: 'Коварное ведение боя',
        restore: 4,
        description: `До двух союзников ягнолота в пределах 60 футов от него, которые могут его слышать, используют свою реакцию, чтобы совершить по одной рукопашной атаке каждый.`,
      },
      {
        id: ABILITY_TELEPORT,
        range: 60,
      },
    ],
  },
]
