const {PC_SUBCLASS_RANGER_BEAST_MASTER} = require('./../../../pcSubClassIdList')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
  SOURCE_ERRATA_PHB,
} = require('./../../../sourceList')

const {
  CREATURE_BEAST_OF_THE_LAND,
  CREATURE_BEAST_OF_THE_SEA,
  CREATURE_BEAST_OF_THE_SKY,
  CREATURE_HAWK,
  CREATURE_MASTIFF,
  CREATURE_PANTHER,
} = require('./../../../creatureIdList')

const {
  FEATURE_BESTIAL_FURY,
  FEATURE_EXCEPTIONAL_TRAINING,
  FEATURE_EXTRA_ATTACK_RANGER,
  FEATURE_PRIMAL_COMPANION,
  FEATURE_RANGER_S_COMPANION,
  FEATURE_SHARE_SPELLS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_RANGER_S_COMPANION,
    name: `Спутник следопыта`,
    nameEn: `Ranger’s Companion `,
    lvl: 3,
    text: `Вы получаете зверя-спутника, который сопровождает Вас во время приключений и обучен сражаться вместе с Вами. Выберите зверя, с размером не больше Среднего, ПО которого ¼ или ниже (пример: [ястреб](CREATURE:${CREATURE_HAWK}), [мастиф](CREATURE:${CREATURE_MASTIFF}) или [пантера](CREATURE:${CREATURE_PANTHER})). Добавьте свой бонус мастерства к КД, броскам атаки и урона зверя, а также к испытаниям и навыкам, которыми он владеет. Максимум его хитов равен его обычному максимуму или Вашему четырёхкратному уровню следопыта, в зависимости от того, что выше.

Зверь подчиняется Вашим командам так хорошо, как только может. Он совершает ход с Вашей инициативой, хотя и не совершает действий, если Вы не прикажете обратное. Как любые другие существа, зверь может тратить Кости Хитов во время короткого отдыха. Во время своего хода Вы можете устно командовать зверем, куда ему двигаться (для этого от Вас не требуется никаких действий). Вы можете действием дать устную команду зверю совершить Атаку, Отход, Помощь, или Рывок. Если Вы не отдаёте команд, зверь совершает Уклонение.

Если у Вас есть умение [Дополнительная атака](FEATURE:${FEATURE_EXTRA_ATTACK_RANGER}), Вы можете сами совершить одну атаку оружием, когда приказываете зверю совершить действие Атака. Если Вы недееспособны или отсутствуете, зверь самостоятельно совершает действия, сосредотачиваясь на защите Вас и себя. Для того, чтобы он совершал реакции, Ваша команда не требуется, в том числе и при совершении провоцированной атаки.

Путешествуя по избранной местности только вдвоём со зверем, Вы можете передвигаться скрытно в нормальном темпе.

Если зверь умирает, Вы можете получить другого, потратив 8 часов на магическое связывание себя со зверем, не враждебным к Вам, того же вида, либо другого.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 93,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Явно оговорены действия зверя без команд и возможность тратить кости хитов.',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_PRIMAL_COMPANION,
    name: `Первобытный спутник`,
    nameEn: `Primal Companion `,
    lvl: 3,
    isOptional: true,
    creatureId: [
      CREATURE_BEAST_OF_THE_LAND,
      CREATURE_BEAST_OF_THE_SEA,
      CREATURE_BEAST_OF_THE_SKY,
    ],
    text: `_Опциональная способность. Вы можете заменить этой способностью способность [Спутник следопыта](FEATURE:${FEATURE_RANGER_S_COMPANION})._

Вы магически призываете первобытного зверя, берущего силу из Вашей связи с природой. Зверь дружественен к Вам и Вашим спутникам и подчиняется Вашим командам. Выберите его статблок: [Лесной зверь](CREATURE:${CREATURE_BEAST_OF_THE_LAND}), [Морской зверь](CREATURE:${CREATURE_BEAST_OF_THE_SEA}) или [Летучий зверь](CREATURE:${CREATURE_BEAST_OF_THE_SKY}).

Вы также выбираете вид животного, выбирая из подходящих для выбранного статблока. Какой бы статблок Вы ни выбрали, зверь носит первобытную метку, указывающую на его мистическое происхождение.

В бою зверь действует на Вашем ходу. Он может двигаться и использовать реакции самостоятельно, но единственное доступное ему действие — Уклонение, если Вы не потратите бонусное действие на своём ходу, приказывая ему совершить одно из действий из его стаблока или любое другое действие. Когда совершаете действие Атака, Вы также можете потратить одну из Ваших атак, приказывая зверю совершить действие Атака. Если Вы недееспособны, зверь может совершать одно любое действие, а не только Уклонение.

В течение часа после смерти зверя Вы можете действием коснуться его и потратить ячейку заклинания 1 круга или выше. Через минуту зверь возвращается к жизни с полными хитами.

Когда Вы завершаете длинный отдых, Вы можете призвать другого первобытного зверя. Новый зверь появляется в свободном пространстве в пределах 5 футов от Вас и Вы выбираете его статблок и внешность. Если у Вас есть другой первобытный зверь, призванный этой способностью, то он исчезает, когда появляется новый. Зверь также исчезает, если Вы умираете.`,
    source: {
      id: SOURCE_TCoE,
      page: 90,
    },
  },
  {
    id: FEATURE_EXCEPTIONAL_TRAINING,
    name: `Исключительная дрессировка`,
    nameEn: `Exceptional Training`,
    lvl: 7,
    text: `Если в Ваш ход зверь-спутник не нападает, Вы можете бонусным действием приказать зверю совершить Отход, Помощь или Рывок в его ход.

Кроме того, зверь теперь считается магическим при определении преодоления сопротивления и иммунитета к немагическим атакам и урону.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 94,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Убрано лишнее Уклонение и зверь теперь считается магическим.',
        page: 1,
      },
    ],
  },
  {
    id: FEATURE_BESTIAL_FURY,
    name: `Звериная ярость`,
    nameEn: `Bestial Fury`,
    lvl: 11,
    text: `Когда Вы приказываете своему зверю использовать действие Атака, он может совершить либо две атаки, либо действие _Мультиатака_, если у него оно есть.`,
    source: {
      id: SOURCE_PHB,
      page: 94,
    },
  },
  {
    id: FEATURE_SHARE_SPELLS,
    name: `Общие заклинания`,
    nameEn: `Share Spells`,
    lvl: 15,
    text: `Когда Вы накладываете заклинание, направленное на себя, Вы также можете распространить его эффект на зверя-спутника, если он находится в пределах 30 футов.`,
    source: {
      id: SOURCE_PHB,
      page: 94,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_RANGER_BEAST_MASTER,
  })
)
