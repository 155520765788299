const {
  CONDITION_EXHAUSTION,
  CONDITION_GRAPPLED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
  CONDITION_PRONE,
  CONDITION_RESTRAINED,
  CONDITION_STUNNED,
} = require('./../../../../conditionList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_POISON,
} = require('./../../../../damageTypeList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_LEGENDARY_RESISTANCE_3} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_N} = require('./../../../../aligmentList')
const {ancientElementalDescriptionList} = require('./../../../../textCommonParts')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_20} = require('./../../../../crList')
const {CREATURE_LEVIATHAN} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_ELEMENTAL} = require('./../../../../creatureTypeIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_GARGANTUA} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Левиафан',
  nameEn: 'Leviathan',
  id: CREATURE_LEVIATHAN,
  description: [
    `Возвышающаяся стена воды, что тянет корабли ко дну океана и смывает прибрежные поселения — это лишь малая толика тех разрушений, что Левиафан может принести миру. Когда его призывают, левиафан возникает из огромного количества воды, преобразуясь в огромное змееобразное существо.`,
    ...ancientElementalDescriptionList,
  ],
  sizeType: SIZE_GARGANTUA,
  creatureTypeIdList: [
    CREATURE_TYPE_ELEMENTAL,
  ],
  alignmentId: ALIGNMENT_N,
  source: {
    id: SOURCE_MTOF,
    page: 161,
  },
  armor: 17,
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_SWIM]: 120,
  },
  params: {
    [PARAM_STR]: 30,
    [PARAM_DEX]: 24,
    [PARAM_CON]: 30,
    [PARAM_INT]: 2,
    [PARAM_WIT]: 18,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityList: [
    DAMAGE_ACID,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_EXHAUSTION,
    CONDITION_GRAPPLED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
    CONDITION_PRONE,
    CONDITION_RESTRAINED,
    CONDITION_STUNNED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  cr: CR_20,
  featureList: [
    ABILITY_LEGENDARY_RESISTANCE_3,
    {
      name: 'Частичная заморозка',
      description: `Если Левиафан получает 50 урона холодом или более за один ход, Левиафан частично замораживается; до конца его следующего хода, его скорость уменьшается до 20 фт., и он делает атаки с помехой.`,
    },
    {
      name: 'Осадное чудовище',
      description: `Левиафан наносит двойные повреждения объектам и строениям (уже включено в _Волну прилива_).`,
    },
    {
      name: 'Водяное тело',
      description: `Левиафан может войти в клетку враждебного существа и остановиться там. Он может проходить через пространства размером более одного дюйма не протискиваясь.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Левиафан совершает две атаки: одну _Ударом_ и одну _Хвостом_.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 20,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 10,
            diceBonus: 10,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 1,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Хвост',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 16,
        range: 20,
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 1,
            diceType: 12,
            diceBonus: 10,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 1,
            diceType: 12,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Приливная волна',
      restore: 6,
      description: `Будучи погружённым в воду, Левиафан может создать магическую стену воды, с центром на себе. Стена имеет длину и высоту 250 футов, и ширину 50 футов.

Когда стена появляется, все остальные существа в зоне должны пройти испытание Силы СЛ 24. Существа получают 33 (6к10) дробящего урона при провале, или половину этого урона при успехе.

В начале каждого хода Левиафана, после которого появилась стена, стена вместе со всеми существами в ней сдвигается на 50 футов от Левиафана. Все существа, чей размер Огромный или меньше, находящиеся внутри стены или оказавшиеся в ней после её движения, должны пройти испытание силы СЛ 24 или получить 27 (5к10) дробящего урона. Существо получает этот урон не чаще, чем раз в ход. В конце каждого хода волна сдвигается, и её высота уменьшается на 50 футов, и урон получаемый существами в ней уменьшается на 1к10. Когда стена достигает высоты в 0 футов, она исчезает.

Существа, пойманные в волну, могут двигаться в ней, плавая. Из-за мощи волны, двигаться в ней крайне сложно и для этого потребуется проходить проверки Силы (Атлетика) СЛ 24, чтобы плыть один ход.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Удар',
      cost: 2,
      description: `Левиафан делает одну атаку _Ударом_.`,
    },
    {
      name: 'Перемещение',
      description: `Левиафан перемещается на расстояние, не превышающее его скорость.`,
    },
  ],
}
