const {GEAR_ROPE_SILK} = require('./../../../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROPE_OF_CLIMBING} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_UNCOMMON} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

module.exports = {
  id: MAGIC_ITEM_ROPE_OF_CLIMBING,
  name: `Верёвка лазания`,
  nameEn: `Rope of Climbing`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_ROPE_SILK,
  rarity: MGC_RARITY_UNCOMMON,
  description: `Эта 60-футовая [шёлковая верёвка](GEAR:${GEAR_ROPE_SILK}) весит 3 фунта и способна выдержать до 3000 фунтов веса. Если Вы удерживаете один конец верёвки, Вы можете действием произнести командное слово, чтобы оживить её. Бонусным действием Вы можете отдать приказ другому концу двигаться к какому-либо месту на Ваш выбор. Этот конец будет продвигаться в сторону указанного места со скоростью 10 футов за раунд, пока не достигнет его, или пока верёвка не размотается на свою полную длину, или же пока Вы не прикажете ей остановиться. Вы также можете приказать верёвке крепко обвязаться вокруг какого-либо предмета, отвязаться, навязать или развязать узлы по всей длине или самостоятельно скрутиться кольцом для удобства переноски.

Если Вы приказываете верёвке навязать узлы, то они появляются через каждый фут по всей её длине. При этом верёвка становится короче на 10 футов, но зато проверки, совершённые для лазания по ней, совершаются с преимуществом.

У верёвки КД 20 и 20 хитов. Она восстанавливает 1 хит каждые 5 минут, если у неё есть хотя бы 1 хит. Если хиты верёвки опускаются до 0, она уничтожается.`,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 154,
  },
}
