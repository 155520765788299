const {GEAR_BOOK} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_MANUAL_OF_GOLEMS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const manualOfGolemsTable = require('./manualOfGolemsTable')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_MANUAL_OF_GOLEMS,
  name: `Справочник по големам`,
  nameEn: `Manual of Golems`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_BOOK,
  rarity: MGC_RARITY_VERY_RARE,
  isConsumable: true,
  description: `В этом томе находится информация и чары, необходимые для создания конкретного вида голема. Для того чтобы расшифровать и использовать справочник, Вы должны быть заклинателем с как минимум двумя ячейками заклинаний 5 уровня. Существо, которое не может использовать _Справочник по големам_, но читает его, получает урон психической энергией 6к6.

|к20|Голем|Время|Стоимость|
|---|-----|-----|---------|
${manualOfGolemsTable}

Для того чтобы создать голема, Вы должны потратить время, указанное в таблице, работая без перерывов со _Справочником_ в руке, отдыхая не более 8 часов в день. Вы также должны потратить указанную стоимость, приобретая расходные материалы.

После того как голем создан, книга исчезает в магическом пламени. Голем оживает, когда его натрут пеплом _Справочника_. Он находится под Вашим контролем, понимает Ваши устные команды и выполняет их.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 202,
  },
}
