const {PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  SPELL_DREAM,
  SPELL_SCRYING,
  SPELL_TELEPORTATION_CIRCLE,
} = require('./../../../spellIdList')
const {
  FEATURE_BALM_OF_THE_SUMMER_COURT,
  FEATURE_HEARTH_OF_MOONLIGHT_AND_SHADOW,
  FEATURE_HIDDEN_PATHS,
  FEATURE_WALKER_IN_DREAMS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BALM_OF_THE_SUMMER_COURT,
    name: `Утешение Летнего Двора`,
    nameEn: `Balm of the Summer Court`,
    lvl: 2,
    text: `Вас наполняют блага Летнего Двора. Вы становитесь источником исчеляющей раны энергии. У Вас есть запас энергии фей представленный количеством костей к6, равным Вашему уровню Друида

Бонусным действием Вы можете выбрать одно видимое Вами в пределах 120 футов существо и потратить количество костей, равное половине Вашего уровня друида или меньше, чтобы восстановить количество хитов, равное результату броска этих потраченных костей. Кроме того, цель получает по одному временному хиту за каждую потраченную кость.

Вы восстанавливаете все потраченные кости, когда оканчиваете длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 29,
    },
  },
  {
    id: FEATURE_HEARTH_OF_MOONLIGHT_AND_SHADOW,
    name: `Очаг лунного света и тени`,
    nameEn: `Hearth of Moonlight and Shadow`,
    lvl: 6,
    text: `Дом возникает там, где Вы находитесь.

Во время короткого или длинного отдыха Вы можете призвать теневую силу Сумрачного двора, чтобы помочь защитить Вас во время отдыха. В начале отдыха Вы касаетесь точки в пространстве, и там появляется невидимая сфера радиусом 30 футов с центром в этой точке. Полное укрытие блокирует распространение сферы.

Находясь внутри этой сферы Вы и Ваши союзники получаете +5 бонус к проверкам Ловкости (Скрытность) и Мудрости (Восприятие), а любой свет от открытого огня в сфере (костер, факелы и т. д.) не виден снаружи.

Сфера исчезает в конце отдыха или когда Вы покидаете сферу.`,
    source: {
      id: SOURCE_XGTE,
      page: 30,
    },
  },
  {
    id: FEATURE_HIDDEN_PATHS,
    name: `Скрытые пути`,
    nameEn: `Hidden Paths`,
    lvl: 10,
    text: `Вы можете использовать скрытые магические пути, которые некоторые феи используют для перемещения.

Вы можете бонусным действием телепортироваться в видимое Вами в пределах 60 футов свободное пространство. Либо же Вы можете действием телепортировать одно согласное существо, которое касаетесь,  в видимое Вами в пределах 30 футов свободное пространство.

Вы можете использовать эту способность количество раз, равное Вашему модификатору Мудрости (минимум 1), и восстанавливаете все использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_XGTE,
      page: 30,
    },
  },
  {
    id: FEATURE_WALKER_IN_DREAMS,
    name: `Странник во снах`,
    nameEn: `Walker in Dreams`,
    lvl: 14,
    text: `Магия Страны Фей даёт Вам возможность путешествовать мысленно или физически через страну сновидений.

Когда Вы заканчиваете короткий отдых, Вы можете наложить одно из следующих заклинаний, не тратя ячейку заклинаний или материальный компонент:

* [Вещий сон](SPELL:${SPELL_DREAM}) (с Вами, как посыльным),
* [Круг телепортации](SPELL:${SPELL_TELEPORTATION_CIRCLE}),
* [Наблюдение](SPELL:${SPELL_SCRYING}).

Этот круг телепортации особенный. Вместо того, чтобы открывать портал в постоянный круг телепортации, он открывает портал в последнее место, где Вы закончили длинный отдых на этом плане. Если Вы не совершали длинного отдыха на этом плане, заклинание не срабатывает, но его использование не тратится.

После использования этой способности Вы должны завершить длинный отдых, чтобы снова её применить.`,
    spellIdList: [
      SPELL_DREAM,
      SPELL_SCRYING,
      SPELL_TELEPORTATION_CIRCLE,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 30,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_DRUID_CIRCLE_OF_DREAMS,
  })
)
