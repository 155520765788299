const {
  WEAPON_FINESSE,
  WEAPON_LIGHT,
} = require('./../../../weaponPropList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
} = require('./../../../sourceList')
const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_SLASHING} = require('./../../../damageTypeList')
const {GEAR_SCIMITAR} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_SCIMITAR,
  genderId: GENDER_MALE,
  name: 'Скимитар',
  nameByCase: {
    nominative: 'скимитар',
    genitive: 'скимитара',
    accusative: 'скимитар',
    instrumental: 'скимитаром',
  },
  nameEn: 'Scimitar',
  damage: {
    diceType: 6,
    diceCount: 1,
  },
  cost: 2500,
  damageType: DAMAGE_SLASHING,
  weight: 3,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    WEAPON_LIGHT,
    WEAPON_FINESSE,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
