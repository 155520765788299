const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_CORPSE_FLOWER,
  CREATURE_NECROMANCER,
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')
const {
  CONDITION_BLINDED,
  CONDITION_DEAFENED,
} = require('./../../../../conditionList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ABILITY_SPIDER_CLIMB} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_TYPE_PLANT} = require('./../../../../creatureTypeIdList')
const {DAMAGE_BLUDGEONING} = require('./../../../../damageTypeList')
const {SENSE_BLIND_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Трупоцвет',
  nameEn: 'Corpse Flower',
  id: CREATURE_CORPSE_FLOWER,
  description: [
    `Трупоцвет может вырасти на могиле злого [некроманта](CREATURE:${CREATURE_NECROMANCER}) или на останках мощной нежити. Если его не выкорчевать и не сжечь, пока трупоцвет ещё является ростком, он разрастётся до огромных размеров за несколько недель, чтобы после этого покинуть место произрастания и отправиться собирать трупы гуманоидов с полей битв и кладбищ. Используя свои волокнистые щупальца, он наполняет останками своё тело и питается падалью для самовосстановления. Это растение обладает злобными наклонностями и презрением к живым.`,
    {
      header: `Ужасный аромат`,
      text: `С трупами внутри или без них, трупоцвет выделяет гнилостное зловоние, которое может переполнять чувства окружающих, вызывая у них тошноту. Зловоние, служащее защитным механизмом, исчезает через 2к4 дня после того, как трупоцвет погибает.`,
      source: {
        id: SOURCE_MTOF,
        page: 235,
      },
    },
  ],
  note: {
    text: `Ростки трупоцвета очень полезны для различных целей. Просто убейте и похороните некроманта, и примерно через неделю у вас будет отличный урожай.`,
    author: `Архимаг Морденкайнен`,
  },
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 235,
  },
  armor: 12,
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_CLIMB]: 20,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 16,
    [PARAM_INT]: 7,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 3,
  },
  immunityConditionList: [
    CONDITION_BLINDED,
    CONDITION_DEAFENED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 120,
      isBlindOutside: true,
    },
  ],
  cr: CR_8,
  featureList: [
    {
      name: 'Трупы',
      description: `При первой встрече трупоцвет несёт в себе 1к6 + 3 трупа гуманоидов. Трупоцвет может содержать в себе до девяти трупов гуманоидов одновременно. Они имеют полное укрытие от атак и прочих эффектов, направленных на них извне трупоцвета. Если трупоцвет погибает, трупы выбрасываются наружу. Пока в трупоцвете находится хотя бы одно труп, он может бонусным действием использовать одну из следующих способностей:

* Трупоцвет переваривает один труп и мгновенно восстанавливает 11 (2к10) хитов, не оставляя ничего от переваренного тела. Любое снаряжение переваренного трупа выбрасывается в занимаемое цветком пространство.

* Трупоцвет оживляет одного мёртвого гуманоида, превращая его в [зомби](CREATURE:${CREATURE_ZOMBIE}). Зомби появляется в незанятом пространстве в пределах 5 фт от трупоцвета и действует сразу после него в порядке инициативы. Зомби действует как союзник цветка, но не находится под его контролем, а также он источает _Зловоние смерти_, которым пропитался в цветке.`,
    },
    ABILITY_SPIDER_CLIMB,
    {
      name: 'Зловоние смерти',
      description: `Каждое существо, начинающее свой ход в пределах 10 фт от трупоцвета или от его [зомби](CREATURE:${CREATURE_ZOMBIE}) должно пройти испытание Телосложения оСл 14, если оно не является конструктом или нежитью. При провале существо становится недееспособным до конца хода. Существа, иммунные к урону ядом, автоматически преуспевают в этом испытании. При успехе существо становится иммунным к _Зловониям смерти_ всех трупоцветов на следующие 24 часа.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Трупоцвет совершает три атаки _Щупальцем_`,
    },
    {
      name: 'Щупальце',
      description: `Цель должна пройти испытание Телосложения СЛ 14 или получить 14(4к6) урона ядом.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 2,
        },
      },
    },
    {
      name: 'Урожай мёртвых',
      description: `Трупоцвет хватает один неохраняемый труп гуманоида в пределах 10 футов от себя и заглатывает его в себя вместе со всем снаряжением, которое носил или нёс труп. Этот труп можно использовать особенностью _Трупы_.`,
    },
  ],
}
