const {
  MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_ABERRATIONS,
  MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_BEASTS,
  MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_CELESTIALS,
  MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_ELEMENTALS,
  MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_FEY,
  MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_FIENDS,
  MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_PLANTS,
  MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_UNDEAD,
} = require('./../../../../../magicItemIdList')
const {
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_CELESTIAL,
  CREATURE_TYPE_ELEMENTAL,
  CREATURE_TYPE_FEY,
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_PLANT,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../../creatureTypeIdList')

const list = [
  {
    id: MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_ABERRATIONS,
    creatureTypeId: CREATURE_TYPE_ABERRATION,
    nameEn: `Scroll of Protection from Aberrations`,
    diceText: `01–10`,
  },
  {
    id: MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_BEASTS,
    creatureTypeId: CREATURE_TYPE_BEAST,
    nameEn: `Scroll of Protection from Beasts`,
    diceText: `11–20`,
  },
  {
    id: MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_FIENDS,
    creatureTypeId: CREATURE_TYPE_FIEND,
    nameEn: `Scroll of Protection from Fiends`,
    diceText: `21–45`,
  },
  {
    id: MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_CELESTIALS,
    creatureTypeId: CREATURE_TYPE_CELESTIAL,
    nameEn: `Scroll of Protection from Celestials`,
    diceText: `46–55`,
  },
  {
    id: MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_UNDEAD,
    creatureTypeId: CREATURE_TYPE_UNDEAD,
    nameEn: `Scroll of Protection from Undead`,
    diceText: `56–75`,
  },
  {
    id: MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_PLANTS,
    creatureTypeId: CREATURE_TYPE_PLANT,
    nameEn: `Scroll of Protection from Plants`,
    diceText: `76–80`,
  },
  {
    id: MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_FEY,
    creatureTypeId: CREATURE_TYPE_FEY,
    nameEn: `Scroll of Protection from Fey`,
    diceText: `81–90`,
  },
  {
    id: MAGIC_ITEM_SCROLL_OF_PROTECTION_FROM_ELEMENTALS,
    creatureTypeId: CREATURE_TYPE_ELEMENTAL,
    nameEn: `Scroll of Protection from Elementals`,
    diceText: `91–00`,
  },
]

module.exports = list
