const {PC_SUBCLASS_ROGUE_ASSASSIN} = require('./../../../pcSubClassIdList')
const {SOURCE_PHB} = require('./../../../sourceList')
const {
  GEAR_DISGUISE_KIT,
  GEAR_POISONERS_KIT,
} = require('./../../../gearIdList')
const {
  FEATURE_ASSASSINATE,
  FEATURE_BONUS_PROFICIENCIES_ASSASSIN,
  FEATURE_DEATH_STRIKE,
  FEATURE_IMPOSTOR,
  FEATURE_INFILTRATION_EXPERTISE,
} = require('./../../../featureIdList')


module.exports = [
  {
    id: FEATURE_BONUS_PROFICIENCIES_ASSASSIN,
    name: `Дополнительные владения`,
    nameEn: `Bonus Proficiencies`,
    lvl: 3,
    text: `Вы получаете владение [Набором для грима](GEAR:${GEAR_DISGUISE_KIT}) и [Инструментами отравителя](GEAR:${GEAR_POISONERS_KIT}).`,
  },
  {
    id: FEATURE_ASSASSINATE,
    name: `Ликвидация`,
    nameEn: `ASSASSINATE`,
    lvl: 3,
    text: `Вы становитесь смертоносным для врагов. Вы совершаете с преимуществом броски атаки по всем существам, которые ещё не совершали ход в этом бою. Кроме того, все попадания по существам, захваченным врасплох, являются критическими попаданиями.`,
  },
  {
    id: FEATURE_INFILTRATION_EXPERTISE,
    name: `Мастер проникновения`,
    nameEn: `Infiltration Expertise`,
    lvl: 9,
    text: `Вы можете создавать себе ложную биографию. Вы должны потратить семь дней и 25 зм, чтобы сделать её достоверной и установить нужные связи. Вы не можете взять биографию, принадлежащую другому лицу. Например, Вы можете приобрести соответствующую одежду, рекомендательные письма и официального вида сертификаты, чтобы обозначить себя в качестве члена торгового дома из далёкого города, или внушить, что Вы из группы богатых торговцев.

После принятия новой личности в качестве маскировки другие существа верят, что Вы являетесь этой личностью, пока не подадите очевидный повод решить обратное. `,
  },
  {
    id: FEATURE_IMPOSTOR,
    name: `Самозванец`,
    nameEn: `Impostor`,
    lvl: 13,
    text: `Вы получаете способность безошибочно подражать чужой речи, почерку и привычкам. Вы должны провести не менее трёх часов изучения этих трёх компонентов поведения существа, слушая его речь, рассматривая почерк и наблюдая за привычками.

Ваши уловки не видны для случайного наблюдателя. Если осторожное существо подозревает что-то неладное, Вы совершаете с преимуществом все проверки Харизмы (Обман), чтобы избежать обнаружения.`,
  },
  {
    id: FEATURE_DEATH_STRIKE,
    name: `Смертельный удар`,
    nameEn: `Death Strike`,
    lvl: 17,
    text: `Вы становитесь мастером мгновенной смерти.

Если Вы атакуете и попадаете по существу, захваченному врасплох, оно должно пройти испытание Телосложения (СЛ 8 + Ваш модификатор Ловкости + Ваш бонус мастерства). Проваленное испытание означает удвоение урона от Вашей атаки по этому существу.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_ASSASSIN,
    source: {
      id: SOURCE_PHB,
      page: 98,
    },
  })
)

