const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {ALPHABET_IOKHARIC} = require('./../../alphabetList')
const {LANG_NEXALAN} = require('./../../languageIdList')
const {
  PLACE_MAZTICA,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_NEXALAN,
  alphabetId: ALPHABET_IOKHARIC,
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'Нексалан',
    genitive: 'Нексалана',
    instrumental: 'Нексаланом',
    prepositional: 'Нексалане',
  },
  nameEn: 'Nexalan',
  spokenPlaceList: PLACE_MAZTICA,
  isReady: false,
  isRealLang: true,
}
