const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  ABILITY_AMPHIBIOUS,
  ABILITY_OTHERWORLDLY_PERCEPTION,
  ABILITY_SLIPPERY,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KUO_TOA,
} = require('./../../../../creatureTypeIdList')
const {
  kuoToaDescriptionList,
  kuoToaNote,
} = require('./../../../../textCommonParts')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  SIZE_MEDIUM,
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  SPEED_SWIM,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  GEAR_NET,
  GEAR_SHIELD,
  GEAR_SPEAR,
} = require('./../../../../gearIdList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_KUO_TOA} = require('./../../../../creatureIdList')
const {DAMAGE_PIERCING} = require('./../../../../damageTypeList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_UNDERCOMMON} = require('./../../../../languageIdList')
const {PROF_DOUBLE} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Куо-тоа',
  nameEn: 'Kuo-toa',
  id: CREATURE_KUO_TOA,
  note: kuoToaNote,
  description: kuoToaDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_KUO_TOA,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_MM,
    page: 184,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
    gearId: GEAR_SHIELD,
  },
  hp: {
    diceCount: 4,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 13,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 11,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 8,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_DOUBLE,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_UNDERCOMMON,
  ],
  cr: CR_1_4,
  featureList: [
    ABILITY_AMPHIBIOUS,
    ABILITY_OTHERWORLDLY_PERCEPTION,
    ABILITY_SLIPPERY,
    ABILITY_SUNLIGHT_SENSITIVITY,
  ],
  actionList: [
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 3,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 1,
        },
      },
    },
    {
      gearId: GEAR_SPEAR,
    },
    {
      gearId: GEAR_NET,
      description: `Цель может действием совершить проверку Силы СЛ 10, чтобы освободиться самой или освободить из сети другое существо, оканчивая эффект при успехе. Причинение сети рубящего урона 5 (КД 10) освобождает цель, не причиняя ей урон, и уничтожает сеть.`,
      attack: {
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            size: {
              max: SIZE_LARGE,
            },
          },
        },
        hit: 'Цель обездвиживается',
      },
    },
  ],
  reactionList: [
    {
      name: 'Липкий щит',
      description: `Если существо промахивается по ★СУЩЕСТВО★ атакой рукопашным оружием, ★СУЩЕСТВО★ использует _Липкий щит_, чтобы поймать оружие. Атакующий должен пройти испытание Силы СЛ 11, иначе оружие приклеится к щиту. Если владелец оружия не хочет или не может отпустить оружие, он становится схваченным, пока не отклеит оружие. Приклеенное оружие нельзя использовать. Любое существо может оторвать оружие действием, если совершит проверку Силы СЛ 11 и преуспеет.`,
    },
  ],
  genderId: GENDER_MALE,
}
