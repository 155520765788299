const {
    SOURCE_MFFV_1_MMB,
  } = require('./../../../../sourceList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    CR_3,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_FEY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_ACROBATICS,
    SKILL_DECEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    LANG_COMMON,
    LANG_SYLVAN,
  } = require('./../../../../languageIdList'),
  {
    ALIGNMENT_CN,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_SPELL,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_COLD,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_AID,
    SPELL_EXPEDITIOUS_RETREAT,
    SPELL_FALSE_LIFE,
    SPELL_HEAL,
    SPELL_HEAT_METAL,
    SPELL_MINOR_ILLUSION,
    SPELL_MIRROR_IMAGE,
    SPELL_MISTY_STEP,
    SPELL_PRESTIDIGITATION,
    SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  } = require('./../../../../spellIdList'),
  {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    ABILITY_MAGIC_RESISTANCE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_FORLARREN,
    CREATURE_SATYR,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Форларрен',
  nameEn: 'Forlarren',
  id: CREATURE_FORLARREN,
  description: `Потомки [сатиров](CREATURE:${CREATURE_SATYR}), испорченных инфернальной силой, жалкие форларрены — несчастный союз жёстких требований Девяти Преисподних и импульсивного гедонизма Страны Фей. Движимые драматическими и непредсказуемыми эмоциями, эти дикие существа сеют хаос везде, куда бы они ни пошли.
    
**Испорченная сущность.** Дьяволы Аверно давно предприняли попытку экспедиции в Страну Фей, и форларрены — горькое напоминание об этой обречённой экскурсии. Архидьявол Фьёрна, соправитель Флегетоса, четвертого слоя Девяти Преисподних, ухаживала за могущественным феем в надежде искусить его заключить сделку на свою душу. Попытка провалилась, но не раньше, чем войско [сатиров](CREATURE:${CREATURE_SATYR}) было совращено тёмными прелестями Флегетоса. [Сатиры](CREATURE:${CREATURE_SATYR}) были привязаны к своему фею-повелителю и не могли свободно торговать своими душами. Поэтому, вместо того, чтобы требовать их души, Фьёрна исказила и выкрутила их тела, прежде чем вернуть их в Страну Фей, довольствуясь тем, что развращение [сатиров](CREATURE:${CREATURE_SATYR}) будет достойной компенсацией её провала.
    
**Раздвоенные души.** Разделённая природа форларренов отражает их происхождение, отмечая каждого как две сущности, пойманные в ловушку в одном теле. Один Форларрен может быть дружелюбным, приятным гидом, который поможет путешественникам выжить в Девяти Преисподних. Другой — воющий зверь, который пытается уничтожить всех на своем пути. Один из них проявит к незнакомцам милосердие и сочувствие заботливого родителя. Другой — веселый сибарит с неограниченной любовью к еде, выпивке и романтике. Имея дело с форларреном единственно постоянным является податливая, хаотичная и темпераментная природа, которая присуща им всем.

Когда просвечивает его дьявольский аспект, форларрен — хитрый, расчётливый и жестокий враг. Он может использовать своё природное обаяние, чтобы завоевать доверие, превращаясь в беспомощное существо, просящее о помощи, даже если оно ищет возможность предать и одолеть. Или он может стремиться доминировать над слабыми, стать мелким правителем и править всеми, кто попадает в его руки.

**Зловещий цикл.** Форларрен в своем фейском аспекте демонстрирует саморазрушительные порывы, отражающие внутреннее противоречия его натуры. Этот аспект может заставить его напиваться почти до смерти или посвятить себя заботе о других с такой неумолимой энергией, что в конечном итоге он погибает от истощения. К сожалению, когда он достигает этой критической точки, его дьявольская природа берёт верх, и он начнёт мыслить коварно и доминирующе, пока его планы не сорвутся и он не потерпит поражение. Затем, от страха и стыда, дьявольская природа форларрена исчезнет, и вновь проявляется его фейскость.

Несколько баллад Страны Фей рассказывают о драматическом падении некоего доблестного форларрена. Во всех этих рассказах герой совершает великое дело, только чтобы увидеть свою зловещую, дьявольскую вторую личность. Обманывая своих товарищей во время их самых сложных испытаний, форларрен предает их в самый неподходящий момент.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_CN,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 9,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 14,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 17,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 11,
    [PARAM_CHA]: 18,
  },
  skillCollection: {
    [SKILL_ACROBATICS]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_DOUBLE,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 30,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_SYLVAN,
  ],
  cr: CR_3,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_EXPEDITIOUS_RETREAT,
          SPELL_FALSE_LIFE,
          SPELL_MINOR_ILLUSION,
          SPELL_PRESTIDIGITATION,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_AID,
          SPELL_TASHA_S_HIDEOUS_LAUGHTER,
          SPELL_MISTY_STEP,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_HEAL,
          SPELL_HEAT_METAL,
          SPELL_MIRROR_IMAGE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Когтем_ и одну атаку _Боданием_ или совершает три атаки _Цветным лучом_.`,
    },
    {
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 6,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Бодание',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 5,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
    {
      name: 'Цветной луч',
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: 6,
        range: 60,
        hit: [
          {
            type: DAMAGE_ELECTRICITY,
            diceCount: 1,
            diceType: 6,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 1,
            diceType: 6,
          },
          {
            type: DAMAGE_COLD,
            diceCount: 1,
            diceType: 6,
          },
        ],
      },
    },
  ],
}
