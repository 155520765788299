const {SOURCE_MTOF} = require ('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_GITH} = require('./../../creatureTypeIdList')
const {
  githNote,
  githDescription,
} = require ('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_GITH,
  nameEn: 'Gith',
  genderId: GENDER_MALE,
  notRealRace: true,
  name: {
    singular: {
      nominative: 'гит',
      genitive: 'гита',
      dative: 'гиту',
      accusative: 'гита',
      instrumental: 'гитом',
      prepositional: 'гите',
    },
    plural: {
      nominative: 'гиты',
      genitive: 'гитов',
      dative: 'гитам',
      accusative: 'гитов',
      instrumental: 'гитами',
      prepositional: 'гитах',
    },
  },
  note: githNote,
  description: githDescription,
}
