const {
  SPELL_AURA_OF_PURITY,
  SPELL_AURA_OF_VITALITY,
  SPELL_BANISHING_SMITE,
  SPELL_BRANDING_SMITE,
  SPELL_CONJURE_BARRAGE,
  SPELL_FIRE_SHIELD,
  SPELL_HEROISM,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MENDING,
  SPELL_SHIELD,
  SPELL_WARDING_BOND,
} = require('./../../../spellIdList')

const {artificerSpellCircleList} = require('./../../../spellCircleByCasterType')
const {CREATURE_STEEL_DEFENDER} = require('./../../../creatureIdList')
const {GEAR_SMITHS_TOOLS} = require('./../../../gearIdList')
const {PC_SUBCLASS_ARTIFICER_BATTLE_SMITH} = require('./../../../pcSubClassIdList')
const {SOURCE_ERFTLW} = require('./../../../sourceList')
const {extraAttackTemplate} = require('./../../featureCommonRawList')

const {
  FEATURE_ARCANE_JOLT,
  FEATURE_BATTLE_READY,
  FEATURE_BATTLE_SMITH_SPELLS,
  FEATURE_EXTRA_ATTACK_ARTIFICER_BATTLE_SMITH,
  FEATURE_IMPROVED_DEFENDER,
  FEATURE_STEEL_DEFENDER,
  FEATURE_TOOL_PROFICIENCY_BATTLE_SMITH,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_BATTLE_SMITH_SPELLS,
    name: `Заклинания боевого кузнеца`,
    nameEn: `Battle Smith Spells`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
    text: `У Вас всегда подготовлены некоторые заклинания, когда Вы достигаете определённого уровня в этом классе.

Они не учитываются в количестве заклинаний изобретателя, которые Вы можете подготавливать и для Вас считаются заклинаниями изобретателя.`,
    spellCircleLvl: artificerSpellCircleList,
    spellIdList: [
      SPELL_AURA_OF_PURITY,
      SPELL_AURA_OF_VITALITY,
      SPELL_BANISHING_SMITE,
      SPELL_BRANDING_SMITE,
      SPELL_CONJURE_BARRAGE,
      SPELL_FIRE_SHIELD,
      SPELL_HEROISM,
      SPELL_MASS_CURE_WOUNDS,
      SPELL_SHIELD,
      SPELL_WARDING_BOND,
    ],
    source: {
      id: SOURCE_ERFTLW,
      page: 61,
    },
  },
  {
    id: FEATURE_TOOL_PROFICIENCY_BATTLE_SMITH,
    name: `Владение инструментами`,
    nameEn: `Tool Proficiency`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
    text: `Вы получаете владение [инструментами кузнеца](GEAR:${GEAR_SMITHS_TOOLS}). Если Вы уже ими владеете, то получаете владение одним другим видом ремесленных инструментов по Вашему выбору.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 62,
    },
  },
  {
    id: FEATURE_BATTLE_READY,
    name: `Боевая подготовка`,
    nameEn: `Battle Ready`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
    text: `Ваши боевые тренировки и эксперименты с магией приносят плоды.

* Вы получаете владение воинским оружием.
* Когда Вы атакуете магическим оружием, Вы можете использовать при совершении бросков атаки и урона модификатор Интеллекта вместо Силы или Ловкости.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 62,
    },
  },
  {
    id: FEATURE_STEEL_DEFENDER,
    name: `Стальной защитник`,
    nameEn: `Steel Defender`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
    creatureId: CREATURE_STEEL_DEFENDER,
    text: `Вы построили себе верного спутника — _Стального защитника_. Он дружелюбен к Вам и Вашим спутникам и подчиняется Вашим командам. Вы определяете то, как он выглядит и сколько у него ног — две или четыре; это не влияет на его параметры.

В бою _Стальной защитник_ действует на одной инициативе с Вами, но совершает свой ход сразу после Вашего. Он может двигаться и использовать реакции самостоятельно, но единственное доступное ему действие — Уклонение, если Вы не потратите бонусное действие на своём ходу, приказывая ему совершить Рывок, Отход, Помощь, Засаду, Поиск или одно из действий из его статблока.

Если на него сотворить заклинание [Починка](SPELL:${SPELL_MENDING}), он восстановит 2к6 хитов. Если _Стальной защитник_ уничтожен в течение последнего часа, Вы можете действием восстановить его своими [инструментами кузнеца](GEAR:${GEAR_SMITHS_TOOLS}), если находитесь в пределах 5 футов от него и потратите ячейку заклинания 1 круга или выше. _Стальной защитник_ оживёт через 1 минуту с полными хитами.

В конце длинного отдыха Вы можете создать нового _Стального защитника_, если у Вас при себе есть [инструменты кузнеца](GEAR:${GEAR_SMITHS_TOOLS}). Если у Вас уже есть _Стальной защитник_ от этой способности, то он немедленно гибнет.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 62,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_ARTIFICER_BATTLE_SMITH,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
    source: {
      id: SOURCE_ERFTLW,
      page: 62,
    },
  },
  {
    id: FEATURE_ARCANE_JOLT,
    name: `Волшебный заряд`,
    nameEn: `Arcane Jolt`,
    lvl: 9,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
    text: `Вы изучаете новые способы направления волшебной энергии на вред или пользу. Когда Вы попадаете по цели атакой магическим оружием или Ваш [Стальной защитник](FEATURE:${FEATURE_STEEL_DEFENDER}) попадает по цели своей атакой, Вы можете направить магическую энергию в этот удар, создавая один из эффектов:

* Цель получает дополнительно 2к6 урона силовым полем.
* Выберите одно существо или предмет, видимый Вами в пределах 30 футов от цели. Целительная энергия течёт через него, восстанавливая ему 2к6 хитов.

Вы можете использовать _Волшебный заряд_ число раз, равное Вашему модификатору Интеллекта (минимум один раз), но не чаще одного раза в ход. Вы восстанавливаете все потраченные использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 62,
    },
  },
  {
    id: FEATURE_IMPROVED_DEFENDER ,
    name: `Улучшенный защитник`,
    nameEn: `Improved Defender `,
    lvl: 15,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
    text: `Ваши [Волшебный заряд](FEATURE:${FEATURE_ARCANE_JOLT}) и [Стальной защитник](FEATURE:${FEATURE_STEEL_DEFENDER}) становятся мощнее:

* Дополнительный урон и исцеление от волшебного заряда увеличиваются до 4к6.
* [Стальной защитник](FEATURE:${FEATURE_STEEL_DEFENDER}) получает +2 к КД.
* Когда Ваш [Стальной защитник](FEATURE:${FEATURE_STEEL_DEFENDER}) использует свою реакцию _Отражение атаки_, атакующий получает урон силовым полем, равный 1к4 + Ваш модификатор Интеллекта.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 62,
    },
  },
]
