const upLetter = require('./../../../../../../utils/upLetter')

const {damageTypeCollection} = require('./../../../../../damageTypeList')
const {GEAR_SCALE_MAIL} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_DRAGON_SCALE_MAIL} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

const dragonTable = list.reduce(
  (text, {dragonTypeNameRu, damageTypeId}) => `${text}
| ${upLetter(dragonTypeNameRu.nominative)} | ${damageTypeCollection[damageTypeId].fullName} |`,
  `
| Дракон | Сопротивление |
|--------|---------------|`,
)

module.exports = {
  id: MAGIC_ITEM_DRAGON_SCALE_MAIL,
  name: `Доспех из чешуи дракона`,
  nameEn: 'Dragon Scale Mail',
  type: MGC_TYPE_ARMOR,
  gearType: GEAR_SCALE_MAIL,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `Этот доспех изготавливается из чешуи определённого дракона. Иногда драконы сами собирают сброшенные чешуйки и дарят их гуманоидам. В других случаях успешные охотники тщательно выделывают и хранят шкуры убитых драконов. В любом случае, доспехи из чешуи драконов высоко ценятся.

Пока Вы носите этот доспех, Вы получаете бонус +1 к КД, совершаете с преимуществом испытания от _Ужасающей внешности_ и _Оружия дыхания_ драконов, а также обладаете сопротивлением к одному виду урона, зависящему от вида дракона, чья чешуя была использована для изготовления доспеха (смотрите таблицу).

Кроме того, Вы можете действием сосредоточиться, чтобы с помощью магии определить расстояние и направление до ближайшего дракона в пределах 30 миль, вид которого совпадает с видом дракона, чья чешуя была использована для изготовления доспеха. Это особое действие нельзя повторно совершать до следующего рассвета.

${dragonTable}`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 159,
  },
}
