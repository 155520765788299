const {CONDITION_POISONED} = require('./../../../../../conditionList')
const {DAMAGE_POISON} = require('./../../../../../damageTypeList')
const {MAGIC_NECROMANCY} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SOURCE_PHB} = require('./../../../../../sourceList')
const {SPELL_RAY_OF_SICKNESS} = require('./../../../../../spellIdList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')

module.exports = {
  id: SPELL_RAY_OF_SICKNESS,
  name: 'Луч болезни',
  nameEn: 'Ray of Sickness',
  description: `Луч тошнотворно-зелёной энергии устремляется к существу, находящемуся в пределах дистанции. Совершите дальнобойную атаку заклинанием по цели. При попадании цель получает урон ядом 2к8 и должна пройти испытание Телосложения. При провале она становится также отравленной до конца Вашего следующего хода.`,
  highLvlCast: `Если Вы накладываете это заклинание, используя ячейку 2 круга или выше, урон увеличивается на 1к8 за каждый круг ячейки выше первого.`,
  lvl: 1,
  magicSchoolId: MAGIC_NECROMANCY,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_CON,
    condition: CONDITION_POISONED,
    damage: {
      type: DAMAGE_POISON,
      diceCount: 2,
      diceType: 8,
    },
  },
  source: {
    id: SOURCE_PHB,
    page: 237,
  },
}
