const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_SPHINX,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_SPHINX,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык сфинксов',
    genitive: 'Языка сфинксов',
    instrumental: 'Языком сфинксов',
    prepositional: 'Языке сфинксов',
  },
  nameEn: 'Sphinx',
  isReady: false,
  isRealLang: true,
}
