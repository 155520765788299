const {
  CREATURE_HALF_OGRE,
  CREATURE_OGRE,
} = require('./../../creatureIdList')
const {SOURCE_MM} = require('./../../sourceList')
const {
  GOD_GRUUMSH,
  GOD_LUTHIC,
} = require('./../../godIdList')
const {
  PC_RACE_DWARF,
  PC_RACE_HUMAN,
  PC_RACE_HALF_ORC,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')

module.exports = {
  header: 'Орочьи полукровки',
  text: `[Лутик](GOD:${GOD_LUTHIC}), орочья богиня плодородия и жена [Груумша](GOD:${GOD_GRUUMSH}), требует от [орков](PC_RACE:${PC_RACE_ORC}) постоянного и безразборного деторождения, тем самым увеличивая орду поколение за поколением.

Стремление к размножению у [орков](PC_RACE:${PC_RACE_ORC}) сильнее, чем у остальных рас, и они легко скрещиваются с другими видами.

При скрещивании с гуманоидом аналогичного размера и роста (например, [человеком](PC_RACE:${PC_RACE_HUMAN}) или [дварфом](PC_RACE:${PC_RACE_DWARF})), потомством будет орк или [полуорк](PC_RACE:${PC_RACE_HALF_ORC}).

В случаях скрещивания с [огром](CREATURE:${CREATURE_OGRE}), потомством будет [полуогр](CREATURE:${CREATURE_HALF_OGRE}) с пугающей силой и грубыми чертами, называемый _огриллоном_.`,
  source: {
    id: SOURCE_MM,
    page: 227,
  },
}
