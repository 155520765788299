const {FEATURE_PSIONIC_SPELLS_ABERRANT_MIND} = require('./../../../featureIdList')
const {fullCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_SORCERER_ABERRANT_MIND_2019_05_09} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_09_05_ABERRANT_LURK} = require('./../../../sourceList')
const {
  SPELL_ARMS_OF_HADAR,
  SPELL_CALM_EMOTIONS,
  SPELL_COMPULSION,
  SPELL_DETECT_THOUGHTS,
  SPELL_DISSONANT_WHISPERS,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_HUNGER_OF_HADAR,
  SPELL_MODIFY_MEMORY,
  SPELL_RARY_S_TELEPATHIC_BOND,
  SPELL_SENDING,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_PSIONIC_SPELLS_ABERRANT_MIND,
    name: `Псионические заклинания`,
    nameEn: `Psionic Spells`,
    lvl: 1,
    pcSubClassId: PC_SUBCLASS_SORCERER_ABERRANT_MIND_2019_05_09,
    spellCircleLvl: fullCasterSpellCircleList,
    spellIdList: [
      SPELL_ARMS_OF_HADAR,
      SPELL_CALM_EMOTIONS,
      SPELL_COMPULSION,
      SPELL_DETECT_THOUGHTS,
      SPELL_DISSONANT_WHISPERS,
      SPELL_EVARD_S_BLACK_TENTACLES,
      SPELL_HUNGER_OF_HADAR,
      SPELL_MODIFY_MEMORY,
      SPELL_RARY_S_TELEPATHIC_BOND,
      SPELL_SENDING,
    ],
    source: {
      id: SOURCE_UA_2019_09_05_ABERRANT_LURK,
      page: 1,
    },
  },
]
