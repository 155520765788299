const {
  CREATURE_TYPE_AARAKOCRA,
  CREATURE_TYPE_AASIMAR,
  CREATURE_TYPE_AUTOGNOME,
  CREATURE_TYPE_BUGBEAR,
  CREATURE_TYPE_CENTAUR,
  CREATURE_TYPE_CHANGELING,
  CREATURE_TYPE_DHAMPIR,
  CREATURE_TYPE_DRAGONBORN,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_FAIRY,
  CREATURE_TYPE_FILBORG,
  CREATURE_TYPE_GIFF,
  CREATURE_TYPE_GITH,
  CREATURE_TYPE_GLITCHLING,
  CREATURE_TYPE_GNOME,
  CREATURE_TYPE_GOBLIN,
  CREATURE_TYPE_GOLIATH,
  CREATURE_TYPE_GRUNG,
  CREATURE_TYPE_HADOZEE,
  CREATURE_TYPE_HALF_ELF,
  CREATURE_TYPE_HALF_ORC,
  CREATURE_TYPE_HALFLING,
  CREATURE_TYPE_HARENGON,
  CREATURE_TYPE_HEXBLOOD,
  CREATURE_TYPE_HOBGOBLIN,
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_KALASHTAR,
  CREATURE_TYPE_KENDER,
  CREATURE_TYPE_KENKU,
  CREATURE_TYPE_KOBOLD,
  CREATURE_TYPE_LEONIN,
  CREATURE_TYPE_LIZARDFOLK,
  CREATURE_TYPE_LOCATHAH,
  CREATURE_TYPE_LOXODON,
  CREATURE_TYPE_MINOTAUR,
  CREATURE_TYPE_ORC,
  CREATURE_TYPE_OWLIN,
  CREATURE_TYPE_PLASMOID,
  CREATURE_TYPE_REBORN,
  CREATURE_TYPE_SATYR,
  CREATURE_TYPE_SHIFTER,
  CREATURE_TYPE_SIMIC_HYBRID,
  CREATURE_TYPE_TABAXI,
  CREATURE_TYPE_THRI_KREEN,
  CREATURE_TYPE_TIEFLING,
  CREATURE_TYPE_TORTLE,
  CREATURE_TYPE_TRITON,
  CREATURE_TYPE_VEDALKEN,
  CREATURE_TYPE_VERDAN,
  CREATURE_TYPE_WARFORGED,
} = require('./creatureTypeIdList')

const PC_RACE_AARAKOCRA = CREATURE_TYPE_AARAKOCRA
const PC_RACE_AASIMAR = CREATURE_TYPE_AASIMAR
const PC_RACE_ANY = 'race_any'
const PC_RACE_AUTOGNOME = CREATURE_TYPE_AUTOGNOME
const PC_RACE_BUGBEAR = CREATURE_TYPE_BUGBEAR
const PC_RACE_CENTAUR = CREATURE_TYPE_CENTAUR
const PC_RACE_CHANGELING = CREATURE_TYPE_CHANGELING
const PC_RACE_CUSTOM = 'custom'
const PC_RACE_DHAMPIR = CREATURE_TYPE_DHAMPIR
const PC_RACE_DRAGONBORN = CREATURE_TYPE_DRAGONBORN
const PC_RACE_DWARF = CREATURE_TYPE_DWARF
const PC_RACE_ELF = CREATURE_TYPE_ELF
const PC_RACE_FAIRY = CREATURE_TYPE_FAIRY
const PC_RACE_FILBORG = CREATURE_TYPE_FILBORG
const PC_RACE_GENASI = 'genasi'
const PC_RACE_GIFF = CREATURE_TYPE_GIFF
const PC_RACE_GIFF_2021_10_08 = `${CREATURE_TYPE_GIFF}_2021_10_08`
const PC_RACE_GITH = CREATURE_TYPE_GITH
const PC_RACE_GLITCHLING = CREATURE_TYPE_GLITCHLING
const PC_RACE_GNOME = CREATURE_TYPE_GNOME
const PC_RACE_GOBLIN = CREATURE_TYPE_GOBLIN
const PC_RACE_GOLIATH = CREATURE_TYPE_GOLIATH
const PC_RACE_GRUNG = CREATURE_TYPE_GRUNG
const PC_RACE_HADOZEE = CREATURE_TYPE_HADOZEE
const PC_RACE_HALF_ELF = CREATURE_TYPE_HALF_ELF
const PC_RACE_HALF_ORC = CREATURE_TYPE_HALF_ORC
const PC_RACE_HALFLING = CREATURE_TYPE_HALFLING
const PC_RACE_HARENGON = CREATURE_TYPE_HARENGON
const PC_RACE_HEXBLOOD = CREATURE_TYPE_HEXBLOOD
const PC_RACE_HOBGOBLIN = CREATURE_TYPE_HOBGOBLIN
const PC_RACE_HUMAN = CREATURE_TYPE_HUMAN
const PC_RACE_KALASHTAR = CREATURE_TYPE_KALASHTAR
const PC_RACE_KENDER = CREATURE_TYPE_KENDER
const PC_RACE_KENKU = CREATURE_TYPE_KENKU
const PC_RACE_KOBOLD = CREATURE_TYPE_KOBOLD
const PC_RACE_LEONIN = CREATURE_TYPE_LEONIN
const PC_RACE_LIZARDFOLK = CREATURE_TYPE_LIZARDFOLK
const PC_RACE_LOCATHAH = CREATURE_TYPE_LOCATHAH
const PC_RACE_LOXODON = CREATURE_TYPE_LOXODON
const PC_RACE_MINOTAUR = CREATURE_TYPE_MINOTAUR
const PC_RACE_ORC = CREATURE_TYPE_ORC
const PC_RACE_OWLIN = CREATURE_TYPE_OWLIN
const PC_RACE_PLASMOID = CREATURE_TYPE_PLASMOID
const PC_RACE_REBORN = CREATURE_TYPE_REBORN
const PC_RACE_SATYR = CREATURE_TYPE_SATYR
const PC_RACE_SHIFTER = CREATURE_TYPE_SHIFTER
const PC_RACE_SIMIC_HYBRID = CREATURE_TYPE_SIMIC_HYBRID
const PC_RACE_TABAXI = CREATURE_TYPE_TABAXI
const PC_RACE_THRI_KREEN = CREATURE_TYPE_THRI_KREEN
const PC_RACE_TIEFLING = CREATURE_TYPE_TIEFLING
const PC_RACE_TORTLE = CREATURE_TYPE_TORTLE
const PC_RACE_TRITON = CREATURE_TYPE_TRITON
const PC_RACE_VEDALKEN = CREATURE_TYPE_VEDALKEN
const PC_RACE_VERDAN = CREATURE_TYPE_VERDAN
const PC_RACE_WARFORGED = CREATURE_TYPE_WARFORGED
const PC_RACE_YUAN_TI_PUREBLOOD = 'yuan_ti_pureblood'

module.exports = [
  PC_RACE_AARAKOCRA,
  PC_RACE_AASIMAR,
  PC_RACE_ANY,
  PC_RACE_AUTOGNOME,
  PC_RACE_BUGBEAR,
  PC_RACE_CENTAUR,
  PC_RACE_CHANGELING,
  PC_RACE_CUSTOM,
  PC_RACE_DHAMPIR,
  PC_RACE_DRAGONBORN,
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_FAIRY,
  PC_RACE_FILBORG,
  PC_RACE_GENASI,
  PC_RACE_GIFF,
  PC_RACE_GIFF_2021_10_08,
  PC_RACE_GITH,
  PC_RACE_GLITCHLING,
  PC_RACE_GNOME,
  PC_RACE_GOBLIN,
  PC_RACE_GOLIATH,
  PC_RACE_GRUNG,
  PC_RACE_HADOZEE,
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_HALFLING,
  PC_RACE_HARENGON,
  PC_RACE_HEXBLOOD,
  PC_RACE_HOBGOBLIN,
  PC_RACE_HUMAN,
  PC_RACE_KALASHTAR,
  PC_RACE_KENDER,
  PC_RACE_KENKU,
  PC_RACE_KOBOLD,
  PC_RACE_LEONIN,
  PC_RACE_LIZARDFOLK,
  PC_RACE_LOCATHAH,
  PC_RACE_LOXODON,
  PC_RACE_MINOTAUR,
  PC_RACE_ORC,
  PC_RACE_OWLIN,
  PC_RACE_PLASMOID,
  PC_RACE_REBORN,
  PC_RACE_SATYR,
  PC_RACE_SHIFTER,
  PC_RACE_SIMIC_HYBRID,
  PC_RACE_TABAXI,
  PC_RACE_THRI_KREEN,
  PC_RACE_TIEFLING,
  PC_RACE_TORTLE,
  PC_RACE_TRITON,
  PC_RACE_VEDALKEN,
  PC_RACE_VERDAN,
  PC_RACE_WARFORGED,
  PC_RACE_YUAN_TI_PUREBLOOD,
]

module.exports.PC_RACE_AARAKOCRA = PC_RACE_AARAKOCRA
module.exports.PC_RACE_AASIMAR = PC_RACE_AASIMAR
module.exports.PC_RACE_ANY = PC_RACE_ANY
module.exports.PC_RACE_AUTOGNOME = PC_RACE_AUTOGNOME
module.exports.PC_RACE_BUGBEAR = PC_RACE_BUGBEAR
module.exports.PC_RACE_CENTAUR = PC_RACE_CENTAUR
module.exports.PC_RACE_CHANGELING = PC_RACE_CHANGELING
module.exports.PC_RACE_CUSTOM = PC_RACE_CUSTOM
module.exports.PC_RACE_DHAMPIR = PC_RACE_DHAMPIR
module.exports.PC_RACE_DRAGONBORN = PC_RACE_DRAGONBORN
module.exports.PC_RACE_DWARF = PC_RACE_DWARF
module.exports.PC_RACE_ELF = PC_RACE_ELF
module.exports.PC_RACE_FAIRY = PC_RACE_FAIRY
module.exports.PC_RACE_FILBORG = PC_RACE_FILBORG
module.exports.PC_RACE_GENASI = PC_RACE_GENASI
module.exports.PC_RACE_GIFF = PC_RACE_GIFF
module.exports.PC_RACE_GIFF_2021_10_08 = PC_RACE_GIFF_2021_10_08
module.exports.PC_RACE_GITH = PC_RACE_GITH
module.exports.PC_RACE_GLITCHLING = PC_RACE_GLITCHLING
module.exports.PC_RACE_GNOME = PC_RACE_GNOME
module.exports.PC_RACE_GOBLIN = PC_RACE_GOBLIN
module.exports.PC_RACE_GOLIATH = PC_RACE_GOLIATH
module.exports.PC_RACE_GRUNG = PC_RACE_GRUNG
module.exports.PC_RACE_HADOZEE = PC_RACE_HADOZEE
module.exports.PC_RACE_HALF_ELF = PC_RACE_HALF_ELF
module.exports.PC_RACE_HALF_ORC = PC_RACE_HALF_ORC
module.exports.PC_RACE_HALFLING = PC_RACE_HALFLING
module.exports.PC_RACE_HARENGON = PC_RACE_HARENGON
module.exports.PC_RACE_HEXBLOOD = PC_RACE_HEXBLOOD
module.exports.PC_RACE_HOBGOBLIN = PC_RACE_HOBGOBLIN
module.exports.PC_RACE_HUMAN = PC_RACE_HUMAN
module.exports.PC_RACE_KALASHTAR = PC_RACE_KALASHTAR
module.exports.PC_RACE_KENDER = PC_RACE_KENDER
module.exports.PC_RACE_KENKU = PC_RACE_KENKU
module.exports.PC_RACE_KOBOLD = PC_RACE_KOBOLD
module.exports.PC_RACE_LEONIN = PC_RACE_LEONIN
module.exports.PC_RACE_LIZARDFOLK = PC_RACE_LIZARDFOLK
module.exports.PC_RACE_LOCATHAH = PC_RACE_LOCATHAH
module.exports.PC_RACE_LOXODON = PC_RACE_LOXODON
module.exports.PC_RACE_MINOTAUR = PC_RACE_MINOTAUR
module.exports.PC_RACE_ORC = PC_RACE_ORC
module.exports.PC_RACE_OWLIN = PC_RACE_OWLIN
module.exports.PC_RACE_PLASMOID = PC_RACE_PLASMOID
module.exports.PC_RACE_REBORN = PC_RACE_REBORN
module.exports.PC_RACE_SATYR = PC_RACE_SATYR
module.exports.PC_RACE_SHIFTER = PC_RACE_SHIFTER
module.exports.PC_RACE_SIMIC_HYBRID = PC_RACE_SIMIC_HYBRID
module.exports.PC_RACE_TABAXI = PC_RACE_TABAXI
module.exports.PC_RACE_THRI_KREEN = PC_RACE_THRI_KREEN
module.exports.PC_RACE_TIEFLING = PC_RACE_TIEFLING
module.exports.PC_RACE_TORTLE = PC_RACE_TORTLE
module.exports.PC_RACE_TRITON = PC_RACE_TRITON
module.exports.PC_RACE_VEDALKEN = PC_RACE_VEDALKEN
module.exports.PC_RACE_VERDAN = PC_RACE_VERDAN
module.exports.PC_RACE_WARFORGED = PC_RACE_WARFORGED
module.exports.PC_RACE_YUAN_TI_PUREBLOOD = PC_RACE_YUAN_TI_PUREBLOOD
