const {SOURCE_MGZN_DRGN_ANNUAL_1999} = require('./../../sourceList')
const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_COSH} = require('./../../languageIdList')
const {ALPHABET_THORASS} = require('./../../alphabetList')
const {LANG_SUBGROUP_WAELAN} = require('./../../languageSubGroupList')
const {
  PLACE_NELANTHER_ISLES,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_COSH,
  alphabetId: ALPHABET_THORASS,
  type: LANG_TYPE_ETHNIC,
  langSubGroupId: LANG_SUBGROUP_WAELAN,
  name: {
    nominative: 'Кош',
    genitive: 'Коша',
    instrumental: 'Кошем',
    prepositional: 'Коше',
  },
  nameEn: 'Cosh',
  description: [
    {
      header: `Кош`,
      text: `Кош — малоизвестный язык, составленный из множества креольских языков и патуа, на котором говорят в некоторых забытых анклавах Островов Нелантер.`,
      source: {
        id: SOURCE_MGZN_DRGN_ANNUAL_1999,
        page: 30,
      },
    },
  ],
  spokenPlaceList: PLACE_NELANTHER_ISLES,
  isReady: true,
  isRealLang: true,
}
