const {SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN} = require('./../../../sourceList')
const {PC_SUBCLASS_MAGE_OF_SILVERQUILL_2021_06_08} = require('./../../../pcSubClassIdList')
const {
  FEATURE_ELOQUENT_APPRENTICE_2021_06_08,
} = require('./../../../featureIdList')
const {
  SPELL_SACRED_FLAME,
  SPELL_VICIOUS_MOCKERY,
} = require('./../../../spellIdList')

module.exports = [
  {
    id: FEATURE_ELOQUENT_APPRENTICE_2021_06_08,
    name: `Начинающий краснобай`,
    nameEn: `Eloquent Apprentice`,
    lvl: 1,
    text: `You learn one cantrip of your choice, either sacred flame or vicious mockery. It doesn’t count against the number of cantrips you know, and it is added to your class spell list if it isn’t there already.

Additionally, you gain proficiency in your choice of two of the following skills: Deception, Intimidation, Performance, Persuasion, or Insight.`,
    spellIdList: [
      SPELL_SACRED_FLAME,
      SPELL_VICIOUS_MOCKERY,
    ],
    source: {
      id: SOURCE_UA_2021_06_08_MAGES_OF_STRIXHAVEN,
      page: 2,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_MAGE_OF_SILVERQUILL_2021_06_08,
  })
)

