const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_FRIGHTFUL_PRESENCE,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  LANG_DRACONIC,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_ABISHAI,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  abishaiDescriptionList,
  abishaiNote,
  devilDescriptionList,
} = require('./../../../../textCommonParts')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  CREATURE_ABISHAI_RED,
  CREATURE_TIAMAT,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_19} = require('./../../../../crList')
const {GEAR_MORNINGSTAR} = require('./../../../../gearIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Красный абишай',
  nameEn: 'Red Abishai',
  id: CREATURE_ABISHAI_RED,
  description: [
    `Красные абишаи не имеют себе равных среди остальных сородичей, когда дело доходит до лидерских способностей и грубой силы. Они могут использовать власть [Тиамат](CREATURE:${CREATURE_TIAMAT}), чтобы подчинить даже драконов своей воле. Красные абишаи ведут других дьяволов в бой или присматривают за проблемными культами, чтобы они продолжали выполнять поручения Тиамат. Грозный вид красного абишая может вдохновлять его союзников, наполняя их фанатичной готовностью сражаться.`,
    ...abishaiDescriptionList,
    ...devilDescriptionList,
  ],
  note: abishaiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ABISHAI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 177,
  },
  armor: {
    ac: 22,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 30,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 50,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 19,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_STR,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_19,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Абишай может использовать _Ужасающее присутствие_. Затем он совершает три атаки: одну _Моргенштерном_, одну _Когтями_ и одну — _Укусом_.`,
    },
    {
      gearId: GEAR_MORNINGSTAR,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 5,
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 5,
            diceType: 10,
            diceBonus: 6,
          },
          {
            type: DAMAGE_FIRE,
            diceCount: 7,
            diceType: 10,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      id: ABILITY_FRIGHTFUL_PRESENCE,
      range: 120,
      witSave_DC: 18,
    },
    {
      name: 'Побуждение к фанатизму',
      description: `Абишай выбирает до четырёх своих союзников в пределах 60 футов от себя, которые могут его увидеть. В течение 1 минуты каждый из этих союзников совершает атаки с преимуществом и не может быть напуган.`,
    },
    {
      name: 'Сила Королевы Драконов',
      description: `Абишай нацеливается на одного дракона, который он может видеть в пределах 120 футов от него. Дракон должен пройти испытание Харизмы СЛ 18. Цветные драконы совершают этот бросок с помехой. При успехе цель получает иммунитет к _Силе Королевы Драконов_ этого абишая на 1 час. При провале цель очаровывается абишаем на 1 час. Будучи очарованной таким образом, цель относится к абишаю как к надёжному другу, которого нужно оберегать и защищать. Этот эффект заканчивается, если абишай или его спутники наносят цели урон.`,
    },
  ],
}
