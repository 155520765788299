const {GEAR_SHIELD} = require('./../../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_ARMOR} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

const generator = (
  {
    bonus,
    id,
    rarity,
  }
) => ({
  id,
  name: `Щит +${bonus}`,
  nameEn: `Shield +${bonus}`,
  type: MGC_TYPE_ARMOR,
  gearType: GEAR_SHIELD,
  rarity,
  description: `Пока Вы держите этот щит, Вы получаете бонус **+${bonus} КД**.

Этот бонус идёт в дополнение к обычному бонусу щита к КД.`,
  genderId: GENDER_MALE,
  siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 214,
  },
})

module.exports = generator
