const {
    SOURCE_AI,
  } = require('./../../../../sourceList'),
  {
    CR_15,
  } = require('./../../../../crList'),
  {
    SIZE_HUGE,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_MONSTROSITY,
  } = require('./../../../../creatureTypeIdList'),
  {
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    ALIGNMENT_NO,
  } = require('./../../../../aligmentList'),
  {
    SPEED_FLY,
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    DAMAGE_NONMAGIC_WEAPON,
    DAMAGE_PIERCING,
    DAMAGE_SLASHING,
  } = require('./../../../../damageTypeList'),
  {
    LANG_DEEP_CROW,
  } = require('./../../../../languageIdList'),
  {
    SENSE_BLIND_VISION,
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SHADOW_STEALTH,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    ARMOR_TYPE_NATURAL,
  } = require('./../../../../armorTypeList'),
  {
    deepCrowDescriptionList,
    deepCrowNote,
  } = require('./../../../../textCommonParts'),
  {
    CREATURE_ANCIENT_DEEP_CROW,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Древняя глубинная ворона',
  nameEn: 'Ancient Deep Crow',
  id: CREATURE_ANCIENT_DEEP_CROW,
  description: [
    `Про глубинных ворон известно очень мало и ещё меньше — про их исполинских родичей — древних глубинных ворон. То ли некоторые представители этого вида живут так долго, что дорастают по титанических размеров, то ли эти исполины — некая высшая форма глубинных ворон, правящая остальными — это ещё предстоит выяснить. И лучше бы это выяснил кто-то другой. Серьёзно, держитесь подальше от этих тварей.`,
    ...deepCrowDescriptionList,
    {
      header: 'Логово древней глубинной вороны',
      text: `Глубинные вороны и древние глубинные вороны гнездятся в глубоких и тёплых местах, предпочитая области с открытой лавой. Совершенно обычным для них насестом может быть шпиль вулканической породы в месте, где температура лучше всего подходит для вылупления яиц.

# Местные эффекты

Местность вокруг логова древней глубинной вороны изменяется из-за присутствия этого существа, создавая один или несколько из следующих эффектов:

* Сверхъестественная тьма под землёй в пределах 6 миль от логова превращает весь яркий свет в тусклый.
* Со всех сторон в пределах 6 миль от логова слышно прерывистое гулкое карканье.
* Подземные звери в пределах 1 мили от логова служат ушами и глазами древней глубинной вороны, предупреждая о присутствии вторженцев. Из-за этого древнюю глубинную ворону практически невозможно застать врасплох.

Если древняя глубинная ворона умирает, эти эффекты немедленно прекращаются.`,
      source: {
        id: SOURCE_AI,
        page: 212,
      },
    },
  ],
  note: deepCrowNote,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_MONSTROSITY,
  ],
  alignmentId: ALIGNMENT_NO,
  source: {
    id: SOURCE_AI,
    page: 212,
  },
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 15,
  },
  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 80,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 23,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_DOUBLE,
  },
  resistanceList: [
    DAMAGE_NONMAGIC_WEAPON,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 60,
    },
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DEEP_CROW,
  ],
  cr: CR_15,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_SHADOW_STEALTH,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает три атаки: одну — _Жвалами_ и две — _Когтями_.`,
    },
    {
      name: 'Жвалы',
      description: `Цель схвачена (СЛ побега 19). Пока цель схвачена, она обездвижена и ★СУЩЕСТВО★ не может использовать _Жвалы_ против другой цели.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 11,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Тёмное карканье',
      description: `★СУЩЕСТВО★ испускает режущее уши карканье. Все существа в пределах 60 футов, способные слышать ★СУЩЕСТВО★, должны пройти испытание Телосложения СЛ 17. При провале существо получает 10 (3к6) психического урона.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
     {
       name: 'Обнаружение',
       description: `★СУЩЕСТВО★ совершает проверку Мудрости (Внимательность)`,
     },
     {
       name: 'Тёмное карканье',
       cost: 2,
       description: `★СУЩЕСТВО★ использует _Тёмное карканье_`,
     },
     {
       name: 'Атака крыльями',
       cost: 2,
       description: `★СУЩЕСТВО★ бьёт крыльями. Все существа в предела 10 футов должны пройти испытание Ловкости СЛ 19 или получить 13 (2к6 + 6) дробящего урона и упасть ничком. После этого ★СУЩЕСТВО★ может пролететь расстояние до половины своей скорости полёта.`,
     },
  ],
}
