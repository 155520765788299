const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1_4,
  } = require('./../../../../crList'),
  {
    SIZE_MEDIUM,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_ANY_RACE,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_ANY,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_ANY,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_BLUDGEONING,
  } = require('./../../../../damageTypeList'),
  {
  } = require('./../../../../senseList'),
  {
    SKILL_MEDICINE,
    SKILL_RELIGION,
  } = require('./../../../../skillList'),
  {
    PC_CLASS_CLERIC,
  } = require('./../../../../pcClassIdList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_BLESS,
    SPELL_CURE_WOUNDS,
    SPELL_LIGHT,
    SPELL_SACRED_FLAME,
    SPELL_SANCTUARY,
    SPELL_THAUMATURGY,
  } = require('./../../../../spellIdList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    PROF_DOUBLE,
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_ACOLYTE,
    CREATURE_PRIEST,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Прислужник',
  nameEn: 'Acolyte',
  id: CREATURE_ACOLYTE,
  description: `Прислужник это младший представитель духовенства, обычно, помогающий [священникам](CREATURE:${CREATURE_PRIEST}). Он выполняет в храме множество дел и получает от божества незначительные магические способности.`,
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_MM,
    page: 348,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  hp: {
    diceCount: 2,
  },
  armor: 10,
  cr: CR_1_4,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 10,
    [PARAM_CON]: 10,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 11,
  },
  languageList: [
    {
      id: LANG_ANY,
      count: 1,
    },
  ],
  skillCollection: {
    [SKILL_MEDICINE]: PROF_NORMAL,
    [SKILL_RELIGION]: PROF_NORMAL,
  },
  actionList: [
    {
      name: 'Дубина',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 1,
          diceType: 4,
        },
      },
    },
  ],
  spellCast: {
    spellCasterLevel: 1,
    spellCasterClass: PC_CLASS_CLERIC,
    spellIdList: [
      SPELL_BLESS,
      SPELL_CURE_WOUNDS,
      SPELL_LIGHT,
      SPELL_SACRED_FLAME,
      SPELL_SANCTUARY,
      SPELL_THAUMATURGY,
    ],
    slotCountList: [
      Infinity,
      3,
    ],
  },
  genderId: GENDER_MALE,
}
