const {LANG_TYPE_ETHNIC} = require('./../../languageTypeList')
const {LANG_SHOU} = require('./../../languageIdList')
const {
  ALPHABET_IOKHARIC,
  ALPHABET_THORASS,
} = require('./../../alphabetList')
const {
  PLACE_KARA_TUR,
} = require('./../../placeIdList')

module.exports = {
  id: LANG_SHOU,
  alphabetId: [
    ALPHABET_IOKHARIC,
    ALPHABET_THORASS,
  ],
  type: LANG_TYPE_ETHNIC,
  name: {
    nominative: 'язык Шу',
    genitive: 'языка Шу',
    instrumental: 'языком Шу',
    prepositional: 'языке Шу',
  },
  nameEn: 'Shou',
  typicalSpeakers: 'Шу',
  spokenPlaceList: PLACE_KARA_TUR,
  isReady: false,
  isRealLang: true,
}
