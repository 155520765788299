const {
  GENDER_MALE,
} = require('./../../genderList')
const {
  LANG_TYPE_HIDDEN,
} = require('./../../languageTypeList')
const {
  LANG_DEEP_CROW,
} = require('./../../languageIdList')

module.exports = {
  id: LANG_DEEP_CROW,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык глубинных ворон',
    genitive: 'Языка глубинных ворон',
    instrumental: 'Языком глубинных ворон',
    prepositional: 'Языке глубинных ворон',
  },
  nameEn: 'Deep Crow',
  isRealLang: true,
  isReady: false,
  genderId: GENDER_MALE,
}
