module.exports = [
  require('./list/clawfoot'),
  require('./list/dolgaunt'),
  require('./list/dolgrim'),
  require('./list/expeditious_messenger'),
  require('./list/fastieth'),
  require('./list/homunculus_servant'),
  require('./list/iron_defender'),
  require('./list/steel_defender'),
]
