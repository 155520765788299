const {
  CREATURE_FROG,
  CREATURE_TOAD,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Жаба',
  nameEn: 'Frog',
  id: CREATURE_TOAD,
  parentId: CREATURE_FROG,
}
