const {SOURCE_UA_2016_08_01_THE_FAITHFUL} = require('./../../../sourceList')
const {PC_SUBCLASS_WARLOCK_THE_SEEKER_2016_08_01} = require('./../../../pcSubClassIdList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_ARCANE_EYE,
  SPELL_AUGURY,
  SPELL_CLAIRVOYANCE,
  SPELL_FEATHER_FALL,
  SPELL_JUMP,
  SPELL_LEGEND_LORE,
  SPELL_LEVITATE,
  SPELL_LOCATE_CREATURE,
  SPELL_LOCATE_OBJECT,
  SPELL_PASSWALL,
  SPELL_SENDING,
} = require('./../../../spellIdList')
const {
  FEATURE_ASTRAL_REFUGE,
  FEATURE_ASTRAL_SEQUESTRATION,
  FEATURE_FAR_WANDERER,
  FEATURE_PACT_BOON,
  FEATURE_PACT_OF_THE_STAR_CHAIN,
  FEATURE_SEEKER_EXPANDED_SPELLS,
  FEATURE_SHIELDING_AURORA,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_SEEKER_EXPANDED_SPELLS,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    text: `Искатель позволяет Вам при изучении заклинаний колдуна выбирать из более широкого списка.

В Ваш список заклинаний колдуна добавляются следующие заклинания.`,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_ARCANE_EYE,
      SPELL_CLAIRVOYANCE,
      SPELL_FEATHER_FALL,
      SPELL_JUMP,
      SPELL_LEGEND_LORE,
      SPELL_LEVITATE,
      SPELL_LOCATE_CREATURE,
      SPELL_LOCATE_OBJECT,
      SPELL_PASSWALL,
      SPELL_SENDING,
    ],
    source: {
      id: SOURCE_UA_2016_08_01_THE_FAITHFUL,
      page: 1,
    },
  },
  {
    id: FEATURE_PACT_OF_THE_STAR_CHAIN,
    name: `Договор Звёздной цепи`,
    nameEn: `Pact of the Star Chain`,
    text: `Колдун Искателя может выбрать это умение вместо другого [Предмета договора](FEATURE:${FEATURE_PACT_BOON}).

Искатель даёт Вам цепь, выкованную из звёздного света, и украшенную семью сверкающими пылинками. Пока цепь надета на Вас, Вы знаете заклинание [Гадание](SPELL:${SPELL_AUGURY}) и можете сотворять его ритуалом. Это заклинание не учитывается при подсчёте числа заклинаний, которые Вы можете знать. 

Кроме того, Вы можете взывать к силе Искателя, чтобы получить преимущество к проверке Интеллекта, пока Звёздная Цепь находится у Вас. Использовав это умение, Вы не можете использовать его снова, не завершив короткого либо длинного отдыха.

Если Вы потеряете свою Звёздную Цепь, Вы можете провести часовую церемонию, чтобы получить от Искателя замену. Церемония разрушает предыдущую Цепь и может быть проведена во время короткого или длинного отдыха. Цепь исчезает во вспышке света, когда Вы умираете.

Точная форма этого предмета может отличаться в зависимости от Вашего покровителя. Звёздная Цепь вдохновлена божеством Селестианом из мира Серого Ястреба.`,
    lvl: 3,
    spellIdList: SPELL_AUGURY,
    source: {
      id: SOURCE_UA_2016_08_01_THE_FAITHFUL,
      page: 1,
    },
  },
  {
    id: FEATURE_SHIELDING_AURORA,
    name: `Защитное сияние`,
    nameEn: `Shielding  Aurora`,
    text: `Вы можете взывать к силе Искателя, чтобы защитить себя от вреда.

Бонусным действием Вы создаёте вращающийся вокруг Вас сияющий вихрь энергии.

До конца Вашего следующего хода Вы получаете сопротивление ко всему урону, и если враждебное существо заканчивает свой ход в пределах 10 футов от Вас, оно получает урон излучением, равный Вашему уровню колдуна + модификатор Харизмы.

Использовав это умение, Вы не можете использовать его снова, не завершив короткого либо длинного отдыха.`,
    lvl: 1,
    source: {
      id: SOURCE_UA_2016_08_01_THE_FAITHFUL,
      page: 1,
    },
  },
  {
    id: FEATURE_ASTRAL_REFUGE,
    name: `Астральное убежище`,
    nameEn: `Astral Refuge`,
    text: `Вы получаете возможность войти в астральное убежище.

Действием Вы на мгновение исчезаете из мира и входите на Астральный План, пользуясь его вневременной природой. Находясь в своём астральном убежище, Вы можете совершить два действия, сотворяя заклинания, нацеленных только на Вас.

После использования этих двух действий Вы возвращаетесь в занимаемое Вами пространство, и Ваш ход заканчивается.`,
    lvl: 6,
    source: {
      id: SOURCE_UA_2016_08_01_THE_FAITHFUL,
      page: 2,
    },
  },
  {
    id: FEATURE_FAR_WANDERER,
    name: `Дальний странник`,
    nameEn: `Far Wanderer`,
    text: `Вам больше не нужно дышать и Вы получаете сопротивление к урону огнём и холодом.`,
    lvl: 10,
    source: {
      id: SOURCE_UA_2016_08_01_THE_FAITHFUL,
      page: 2,
    },
  },
  {
    id: FEATURE_ASTRAL_SEQUESTRATION,
    name: `Астральное сокрытие`,
    nameEn: `Astral Sequestration`,
    text: `Вы получаете способность сокрыть себя и своих союзников на астральном плане.

Выполняя специальный ритуал в течение 5 минут, Вы перемещаете себя и до десяти видимых Вами согласных существ на Астральный План. Сокрытые на Астральном Плане существа получают все преимущества короткого отдыха. Затем все существа возвращаеются в места, которые они занимали, когда Вы использовали эту способность, при этом в Материальном плане не прошло времени.

Все сокрытые существа могут использовать любые опции короткого отдыха, которые затрагивают только Вас и других сокрытых Вами существ.

Использовав это умение, Вы не можете использовать его снова, не завершив длинного отдыха.`,
    lvl: 14,
    source: {
      id: SOURCE_UA_2016_08_01_THE_FAITHFUL,
      page: 2,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_SEEKER_2016_08_01,
  })
)
