const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_HEROISM_2019_09_18} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2019_09_18_ELOQUENT_HEROICS} = require('./../../../sourceList')

const {
  SPELL_COMMUNE,
  SPELL_COMPULSION,
  SPELL_CONJURE_VOLLEY,
  SPELL_ENHANCE_ABILITY,
  SPELL_ENTHRALL,
  SPELL_EXPEDITIOUS_RETREAT,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_GUIDING_BOLT,
  SPELL_HASTE,
  SPELL_PROTECTION_FROM_ENERGY,
} = require('./../../../spellIdList')

const {
  FEATURE_CHANNEL_DIVINITY_LEGENDARY_STRIKE_2019_09_18,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CHANNEL_DIVINITY_PEERLESS_ATHLETE_2019_09_18,
  FEATURE_GLORIOUS_DEFENSE_2019_09_18,
  FEATURE_LIVING_MYTH_2019_09_18,
  FEATURE_MIGHTY_DEED_2019_09_18,
  FEATURE_OATH_OF_HEROISM_SPELLS,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_HEROISM_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_HEROISM_2019_09_18,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_COMMUNE,
      SPELL_COMPULSION,
      SPELL_CONJURE_VOLLEY,
      SPELL_ENHANCE_ABILITY,
      SPELL_ENTHRALL,
      SPELL_EXPEDITIOUS_RETREAT,
      SPELL_FREEDOM_OF_MOVEMENT,
      SPELL_GUIDING_BOLT,
      SPELL_HASTE,
      SPELL_PROTECTION_FROM_ENERGY,
    ],
    source: {
      id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
      page: 2,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_PEERLESS_ATHLETE_2019_09_18,
    name: `Божественный канал: Несравненный атлет`,
    nameEn: `Channel Divinity: Peerless Athlete`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), усиливая свой атлетизм божественным благоволением.

Вы бонусным действием получаете преимущество к проверкам Силы (Атлетика) и Ловкости (Акробатика) на 10 минут.`,
    source: {
      id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
      page: 2,
    },
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_LEGENDARY_STRIKE_2019_09_18,
    name: `Божественный канал: Легендарный удар`,
    nameEn: `Channel Divinity: Legendary Strike`,
    lvl: 3,
    text: `Вы можете бонусным действием использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), направляя свои атаки. 

На 1 минуту Ваши атаки оружием считаются критическими попаданиями при выпадении 19 или 20 на к20.`,
    source: {
      id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
      page: 2,
    },
  },
  {
    id: FEATURE_MIGHTY_DEED_2019_09_18,
    name: `Могучее деяние`,
    nameEn: `Mighty Deed`,
    lvl: 7,
    text: `Ваши деяния на поле брани могут сверхъестественно поддерживать союзников и распугивать врагов.

Когда Вы наносите критический удар или уменьшаете хиты существа до 0, Вы можете выбрать одно или несколько видимых Вами в пределах 30 футов существ, количество которых не превышает Ваш модификатор Харизмы (минимум одно существо). Все выбранные существа получают один из следующих эффектов по Вашему выбору.

* Существо получает временные хиты, количество которых равно 1к6 + Ваш модификатор Харизмы (минимум 1 временный хит).
* Существо должно пройти испытание Мудрости или будет испугано Вами до начала Вашего следующего хода.

Сделав так, Вы сможете использовать эту способность снова, до начала Вашего следующего хода`,
    source: {
      id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
      page: 2,
    },
  },
  {
    id: FEATURE_GLORIOUS_DEFENSE_2019_09_18,
    name: `Славная защита`,
    nameEn: `Glorious Defense`,
    lvl: 15,
    text: `Ваша слава так сияет на поле брани, что может даже отводить от Вас атаки.

Когда видимое Вами существо попадает по Вам атакой, Вы можете реакцией получить бонус к КД от этой атаки, что потенциально  может привести к промаху.

Бонус равен Вашему модификатору Харизмы (минимум +1). Если атакующее существо промахивается, Вы можете совершить по нему одну атаку оружием как часть этой реакции.`,
    source: {
      id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
      page: 2,
    },
  },
  {
    id: FEATURE_LIVING_MYTH_2019_09_18,
    name: `Живой миф`,
    nameEn: `Living Myth`,
    lvl: 20,
    text: `Вы можете наполнять себя легендами о собственных свершениях — как правдивыми, так и преувеличенными.

Вы можете бонусным действием получить следующие преимущества на 10 минут.

* Вы наполняетесь неземным великолепием, получая преимущество ко всем проверкам Харизмы.
* Один раз на каждом своём ходу, когда Вы совершаете атаку и промахиваетесь, Вы можете изменить промах на попадание.
* Если Вы проваливаете испытание, то реакцией можете заменить провал на успех.

Использовав эту способность, Вы не сможете использовать её вновь, пока не завершите длинный отдых.`,
    source: {
      id: SOURCE_UA_2019_09_18_ELOQUENT_HEROICS,
      page: 3,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_HEROISM_2019_09_18,
  })
)
