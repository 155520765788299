const {extraAttackTemplate} = require('./../../featureCommonRawList')
const {PC_SUBCLASS_WIZARD_BLADESINGING} = require('./../../../pcSubClassIdList')
const {
  SOURCE_SCAG,
  SOURCE_TCoE,
} = require('./../../../sourceList')
const {GEAR_SHIELD} = require('./../../../gearIdList')

const {
  FEATURE_BLADESONG,
  FEATURE_EXTRA_ATTACK_WIZARD_BLADESINGING,
  FEATURE_SONG_OF_DEFENSE,
  FEATURE_SONG_OF_VICTORY,
  FEATURE_TRAINING_IN_WAR_AND_SONG,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_TRAINING_IN_WAR_AND_SONG,
    name: `Тренировка войны и песни`,
    nameEn: `Training in War and Song`,
    lvl: 2,
    text: `Вы получаете владение лёгкими доспехами и одним видом одноручного оружия ближнего боя по Вашему выбору.

Вы также получаете владение навыком Выступление, если до того не им владели.`,
    source: {
      id: SOURCE_TCoE,
      page: 115,
    },
  },
  {
    id: FEATURE_BLADESONG,
    name: `Песнь клинка`,
    nameEn: `Bladesong`,
    lvl: 2,
    text: `Вы можете использовать тайную эльфийскую магию, называемую _Песнью клинка_, при условии, что не носите средних или тяжёлых доспехов и не используете [щит](GEAR:${GEAR_SHIELD}). _Песнь клинка_ наделяет Вас сверхъестественной скоростью, ловкостью и сосредоточенностью.

Вы можете начать _Песнь клинка_ бонусным действием и она продлится 1 минуту. Она прервётся раньше, если Вы станете Недееспособны, наденете средний или тяжёлый доспех, [щит](GEAR:${GEAR_SHIELD}) или воспользуетесь обеими руками, чтобы совершить атаку оружием. Вы также можете прервать _Песнь клинка_ в любое время (действий не требуется).

Пока Вы исполняете _Песнь клинка_, Вы получаете следующие преимущества:

* Вы получаете бонус к КД, равный Вашему модификатору Интеллекта (минимум +1)
* Ваша скорость ходьбы увеличивается на 10 футов.
* Вы получаете преимущество на проверки Ловкости (Акробатики).
* Вы получаете бонус ко всем испытаниям Телосложения для поддержания концентрацию на заклинании, равный Вашему модификатору Интеллекта (минимум +1).

Вы можете использовать эту способность число раз, равное Вашему бонусу мастерства и восстанавливаете все использования, оканчивая длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 115,
    },
  },
  {
    ...extraAttackTemplate,
    id: FEATURE_EXTRA_ATTACK_WIZARD_BLADESINGING,
    lvl: 6,
    text: `${extraAttackTemplate.text}

Кроме того, Вы можете сотворить заговор вместо одной из этих атак.`,
    source: {
      id: SOURCE_TCoE,
      page: 115,
    },
  },
  {
    id: FEATURE_SONG_OF_DEFENSE,
    name: `Песнь защиты`,
    nameEn: `Song of Defense`,
    lvl: 10,
    text: `Вы можете направить свою магию на поглощение урона пока длится Ваша [Песнь клинка](FEATURE:${FEATURE_BLADESONG}).

Когда Вы получаете урон, Вы можете реакцией потратить одну ячейку заклинания для уменьшения урона по Вам на пятикратный круг ячейки.`,
    source: {
      id: SOURCE_TCoE,
      page: 116,
    },
  },
  {
    id: FEATURE_SONG_OF_VICTORY,
    name: `Песнь победы`,
    nameEn: `Song of Victory`,
    lvl: 14,
    text: `Пока длится [Песнь клинка](FEATURE:${FEATURE_BLADESONG}), Вы добавляете модификатор Интеллекта (минимум +1) к урону от Ваших рукопашных атак оружием.`,
    source: {
      id: SOURCE_TCoE,
      page: 116,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WIZARD_BLADESINGING,
    source: [
      {
        id: SOURCE_SCAG,
        page: 143,
      },
      feature.source,
    ],
  })
)

