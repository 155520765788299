const {
  SPELL_ALTER_SELF,
  SPELL_CHARM_PERSON,
  SPELL_CONFUSION,
  SPELL_DETECT_THOUGHTS,
  SPELL_DOMINATE_PERSON,
  SPELL_FEAR,
  SPELL_MAJOR_IMAGE,
  SPELL_MASS_SUGGESTION,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_COLD,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
  SKILL_PERSUASION,
} = require('./../../../../skillList')
const {
  LANG_DRACONIC,
  LANG_INFERNAL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_ABISHAI,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  abishaiDescriptionList,
  abishaiNote,
  devilDescriptionList,
} = require('./../../../../textCommonParts')
const {
  ABILITY_DEVIL_S_SIGHT,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_ABISHAI_GREEN,
  CREATURE_TIAMAT,
} = require('./../../../../creatureIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_15} = require('./../../../../crList')
const {GEAR_LONGSWORD} = require('./../../../../gearIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')

module.exports = {
  name: 'Зелёный абишай',
  nameEn: 'Green Abishai',
  id: CREATURE_ABISHAI_GREEN,
  description: [
    `Посланники армий [Тиамат](CREATURE:${CREATURE_TIAMAT}) — зелёные абишаи представляют интересы богини в Девяти Преисподних и за их пределами. Их острое чутьё позволяет им умело добывать секреты и другую конфиденциальную информацию, а благодаря дипломатическим навыкам и волшебству, они могут манипулировать даже самыми проницательными противниками.`,
    ...abishaiDescriptionList,
    ...devilDescriptionList,
  ],
  note: abishaiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
    CREATURE_TYPE_ABISHAI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: [
    {
      id: SOURCE_MTOF,
      page: 176,
    },
    {
      url: `https://5e.tools/bestiary.html#green%20abishai_mtf`,
      problemText: `В переводе «Тома Морденкайнена о врагах» группы переводов dungeonsanddragons.ru у «Зелёного Абишая» пропущен иммунитет к отравленному состоянию. Это нонсенс для дьяволов.`,

    },
  ],
  armor: {
    ac: 18,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 25,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_FLY]: 40,
  },
  params: {
    [PARAM_STR]: 12,
    [PARAM_DEX]: 17,
    [PARAM_CON]: 16,
    [PARAM_INT]: 17,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 19,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INSIGHT]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERSUASION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NONMAGIC_NONSILVER_WEAPON,
  ],
  immunityList: [
    DAMAGE_FIRE,
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_INFERNAL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_15,
  featureList: [
    ABILITY_DEVIL_S_SIGHT,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_ALTER_SELF,
          SPELL_MAJOR_IMAGE,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_CHARM_PERSON,
          SPELL_DETECT_THOUGHTS,
          SPELL_FEAR,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_CONFUSION,
          SPELL_DOMINATE_PERSON,
          SPELL_MASS_SUGGESTION,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Абишай совершает две атаки, одну — _Когтями_ и одну — _Длинным мечом_, или сотворяет одно заклинание _Врождённого колдовства_ и совершает атаку _Когтями_.`,
    },
    {
      gearId: GEAR_LONGSWORD,
    },
    {
      name: 'Когти',
      description: `Если цель — существо, оно должно пройти испытание Телосложения СЛ 16, при провале получая 11 (2к10) урона ядом и становясь отравленным на 1 минуту. Отравленное существо может проходить испытание в конце каждого своего хода, оканчивая эффект при успехе.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 5,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 8,
          diceBonus: 3,
        },
      },
    },
  ],
}
