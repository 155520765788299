const {
  SPELL_BLINDING_SMITE,
  SPELL_BRANDING_SMITE,
  SPELL_COMMAND,
  SPELL_DISPEL_MAGIC,
  SPELL_FIND_STEED,
  SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
  SPELL_THUNDEROUS_SMITE,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_ATHLETICS,
  SKILL_DECEPTION,
  SKILL_INTIMIDATION,
} = require('./../../../../skillList')
const {
  GEAR_GLAIVE,
  GEAR_PLATE_ARMOR,
  GEAR_SHORTBOW,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {ALIGNMENT_NOT_GOOD} = require('./../../../../aligmentList')
const {CR_8} = require('./../../../../crList')
const {CREATURE_BLACKGUARD} = require('./../../../../creatureIdList')
const {LANG_ANY} = require('./../../../../languageIdList')
const {PC_CLASS_PALADIN} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Чёрный страж',
  nameAlt: 'Тёмный страж',
  nameEn: 'Blackguard',
  id: CREATURE_BLACKGUARD,
  description: `Чёрные стражи — это паладины, которые нарушили свои священные клятвы, и теперь удовлетворяют свои собственные темные стремления. Они якшаются с исчадиями и нежитью, и отвергли всё добро из своих прошлых жизней.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_NOT_GOOD,
  source: {
    id: SOURCE_VGTM,
    page: 221,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 11,
    [PARAM_CON]: 18,
    [PARAM_INT]: 11,
    [PARAM_WIT]: 14,
    [PARAM_CHA]: 15,
  },
  saveThrowList: [
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_DECEPTION]: PROF_NORMAL,
    [SKILL_INTIMIDATION]: PROF_NORMAL,
  },
  languageList: [
    {
      id: LANG_ANY,
      comment: `обычно — Всеобщий`,
    },
  ],
  cr: CR_8,
  spellCast: {
    spellCasterLevel: 10,
    spellCasterClass: PC_CLASS_PALADIN,
    spellIdList: [
      SPELL_BLINDING_SMITE,
      SPELL_BRANDING_SMITE,
      SPELL_COMMAND,
      SPELL_DISPEL_MAGIC,
      SPELL_FIND_STEED,
      SPELL_PROTECTION_FROM_EVIL_AND_GOOD,
      SPELL_THUNDEROUS_SMITE,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Чёрный страж совершает три атаки _Глефой_ или _Коротким луком_.`,
    },
    {
      gearId: GEAR_GLAIVE,
    },
    {
      gearId: GEAR_SHORTBOW,
    },
    {
      name: 'Ужасный аспект',
      comment: `перезаряжается после короткого или длинного отдыха`,
      description: `Чёрный страж источает магическую угрозу. Все противники в пределах 30 футов от чёрного стража должна пройти испытание Мудрости СЛ 13 или испугаться на 1 минуту. Если испуганная цель заканчивает свой ход на расстоянии более 30 футов от чёрного стража, она может повторить испытание, заканчивая эффект на себя при успехе.`,
    },
  ],
}
