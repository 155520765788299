const {CREATURE_ELEPHANT} = require('./../../../creatureIdList')
const {POISON_TYPE_INHALED} = require('./../../../poisonTypeList')
const {PARAM_CON} = require('./../../../paramList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_PALADIN,
} = require('./../../../pcClassIdList')
const {
  SOURCE_DMG,
  SOURCE_PHB,
} = require('./../../../sourceList')
const {
  CAT_ANIMALS,
  CAT_HOLY_SYMBOL,
  CAT_EQUIPMENT_KIT,
  CAT_POISONS,
  CAT_SADDLES,
} = require('./../../../gearCategoryList')
const {
  GEAR_BACKPACK,
  GEAR_BEDROLL,
  GEAR_CANDLE,
  GEAR_CLOTHES_COSTUME,
  GEAR_DISGUISE_KIT,
  GEAR_ELEPHANT,
  GEAR_EMBLEM,
  GEAR_ENTERTAINER_S_PACK,
  GEAR_ESSENCE_OF_ETHER,
  GEAR_EXOTIC_SADDLE,
  GEAR_EXPLORER_S_PACK,
  GEAR_MESS_KIT,
  GEAR_RATIONS,
  GEAR_ROPE_HEMP,
  GEAR_SHIELD,
  GEAR_TINDERBOX,
  GEAR_TORCH,
  GEAR_WATERSKIN,
} = require('./../../../gearIdList')

module.exports = [
  {
    id: GEAR_EMBLEM,
    genderId: GENDER_FEMALE,
    name: 'Эмблема',
    nameByCase: {
      nominative: 'Эмблема',
      accusative: 'Эмблему',
      instrumental: 'Эмблемой',
    },
    nameEn: 'Emblem',
    description: `Священный символ изображает божество или целый пантеон. Это символ, выгравированный или выложенный камнями в качестве эмблемы на [щите](GEAR:${GEAR_SHIELD}). [Жрец](PC_CLASS:${PC_CLASS_CLERIC}) или [паладин](PC_CLASS:${PC_CLASS_PALADIN}) может использовать священный символ в качестве фокусировки для заклинаний. Для такого использования символа заклинатель должен держать его в руке, носить у всех на виду или нести на [щите](GEAR:${GEAR_SHIELD}).`,
    cost: 500,
    weight: 0,
    source: {
      id: SOURCE_PHB,
      page: 150,
    },
    category: CAT_HOLY_SYMBOL,
  },
  {
    id: GEAR_ENTERTAINER_S_PACK,
    genderId: GENDER_MALE,
    name: 'Набор артиста',
    nameByCase: {
      nominative: 'набор артиста',
      genitive: 'набора артиста',
      accusative: 'набор артиста',
      instrumental: 'набором артиста',
    },
    nameEn: 'Entertainer’s Pack',
    description: `Включает
* [рюкзак](GEAR:${GEAR_BACKPACK}),
* [спальник](GEAR:${GEAR_BEDROLL}),
* 2 [костюма](GEAR:${GEAR_CLOTHES_COSTUME}),
* 5 [свечек](GEAR:${GEAR_CANDLE}),
* [рационы](GEAR:${GEAR_RATIONS}) на 5 дней,
* [бурдюк](GEAR:${GEAR_WATERSKIN})
* [набор для грима](GEAR:${GEAR_DISGUISE_KIT}).`,
    cost: 4000,
    weight: 35,
    source: {
      id: SOURCE_PHB,
      page: 151,
    },
    category: CAT_EQUIPMENT_KIT,
  },
  {
    id: GEAR_EXPLORER_S_PACK,
    genderId: GENDER_MALE,
    name: 'Набор путешественника',
    nameByCase: {
      nominative: 'набор путешественника',
      genitive: 'набора путешественника',
      accusative: 'набор путешественника',
      instrumental: 'набором путешественника',
    },
    nameEn: 'Explorer’s Pack',
    description: `Любой искатель приключений может быть призван пройти долгий путь, выполняя задание, но те, кто выбрал жизнь исследователя, особенно хорошо подготовлены к путешествию.

Набор путешественника включает в себя [рюкзак](GEAR:${GEAR_BACKPACK}), простыню, [факелы](GEAR:${GEAR_TORCH}), коробку для трубочек, [бурдюк](GEAR:${GEAR_WATERSKIN}) и немного [веревки](GEAR:${GEAR_ROPE_HEMP}), а также [столовый набор](GEAR:${GEAR_MESS_KIT}) для приготовления и приёма пищи, который обычно включает в себя квадратную жестяную банку, чашку и столовые приборы. Набор также включает в себя [пайки](GEAR:${GEAR_RATIONS}) на несколько дней.

Включает
    
* [рюкзак](GEAR:${GEAR_BACKPACK}),
* [спальник](GEAR:${GEAR_BEDROLL}),
* [столовый набор](GEAR:${GEAR_MESS_KIT}),
* [трутницу](GEAR:${GEAR_TINDERBOX}),
* 10 [факелов](GEAR:${GEAR_TORCH}),
* [рационы](GEAR:${GEAR_RATIONS}) на 10 дней
* [бурдюк](GEAR:${GEAR_WATERSKIN}).

В набор также входит 50-футовая [пеньковая верёвка](GEAR:${GEAR_ROPE_HEMP}), закреплённая сбоку.`,
    cost: 1000,
    weight: 59,
    source: {
      id: SOURCE_PHB,
      page: 151,
    },
    category: CAT_EQUIPMENT_KIT,
  },
  {
    id: GEAR_ELEPHANT,
    genderId: GENDER_MALE,
    name: 'Слон',
    nameByCase: {
      nominative: 'слон',
      genitive: 'слона',
      accusative: 'слона',
      instrumental: 'слоном',
    },
    nameEn: 'Elephant',
    creatureId: CREATURE_ELEPHANT,
    description: 'Скорость: 40 фт. Грузоподъёмность 1320 фунтов.',
    cost: 20000,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_ANIMALS,
  },
  {
    id: GEAR_EXOTIC_SADDLE,
    genderId: GENDER_MIDDLE,
    name: 'Экзотическое седло',
    nameByCase: {
      nominative: 'экзотическое седло',
      genitive: 'экзотического седла',
      accusative: 'экзотическое седло',
      instrumental: 'экзотическим седлом',
    },
    nameEn: 'Exotic Saddle',
    description: 'Экзотические сёдла требуются для водных и летающих скакунов.',
    cost: 6000,
    weight: 40,
    source: {
      id: SOURCE_PHB,
      page: 157,
    },
    category: CAT_SADDLES,
  },
  {
    id: GEAR_ESSENCE_OF_ETHER,
    genderId: GENDER_FEMALE,
    name: 'Яд, эссенция эфира',
    nameByCase: {
      nominative: 'эссенция эфира',
      genitive: 'эссенции эфира',
      accusative: 'эссенцию эфира',
      instrumental: 'эссенцией эфира',
    },
    nameEn: 'Essence of Ether',
    description: `Существо, подвергшееся действию этого яда, должно пройти испытание Телосложения СЛ 15, иначе оно станет отравленным на 8 часов. Существо также лишено сознания, пока отравлено. Существо приходит в сознание, если получает урон или другое существо действием его потрясёт, чтобы разбудить.`,
    saveThrow: {
      type: PARAM_CON,
      dc: 15,
    },
    cost: 30000,
    poisonType: POISON_TYPE_INHALED,
    source: {
      id: SOURCE_DMG,
      page: 258,
    },
    category: CAT_POISONS,
  },
]
