const {SOURCE_DMG} = require('./../../../../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} = require('./../../../../../genderList')
const {
  GEAR_LONGBOW,
  GEAR_SHIELD,
  GEAR_SHORTBOW,
} = require('./../../../../../gearIdList')
const {
  SPELL_CONJURE_ELEMENTAL,
  SPELL_LEVITATE,
  SPELL_MAGIC_MISSILE,
} = require('./../../../../../spellIdList')
const {
  CREATURE_FIRE_ELEMENTAL,
  CREATURE_WATER_ELEMENTAL,
} = require('./../../../../../creatureIdList')
const {
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_BAG_OF_DEVOURING,
  MAGIC_ITEM_BAG_OF_HOLDING,
  MAGIC_ITEM_BEAD_OF_FORCE,
  MAGIC_ITEM_BELT_OF_DWARVENKIND,
  MAGIC_ITEM_BERSERKER_AXE,
  MAGIC_ITEM_BOOTS_OF_ELVENKIND,
  MAGIC_ITEM_BOOTS_OF_LEVITATION,
  MAGIC_ITEM_BOOTS_OF_SPEED,
  MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING,
  MAGIC_ITEM_BOOTS_OF_THE_WINTERLANDS,
  MAGIC_ITEM_BOWL_OF_COMMANDING_WATER_ELEMENTALS,
  MAGIC_ITEM_BRACERS_OF_ARCHERY,
  MAGIC_ITEM_BRACERS_OF_DEFENSE,
  MAGIC_ITEM_BRAZIER_OF_COMMANDING_FIRE_ELEMENTALS,
  MAGIC_ITEM_BROOCH_OF_SHIELDING,
  MAGIC_ITEM_BROOM_OF_FLYING,
} = require('./../../../../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_BOOTS_OF_ELVENKIND,
    name: `Эльфийские сапоги`,
    nameEn: `Boots of Elvenkind`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Пока Вы носите эти сапоги, Ваши шаги не издают звука, вне зависимости от поверхности, по которой Вы идёте.

Вы также совершаете с преимуществом проверки Ловкости (Скрытность), которые полагаются на бесшумное передвижение.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 215,
    },
  },
  {
    id: MAGIC_ITEM_BOWL_OF_COMMANDING_WATER_ELEMENTALS,
    name: `Чаша командования водяными элементалями`,
    nameEn: `Bowl of Commanding Water Elementals`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Если эта чаша наполнена водой, Вы можете действием произнести командное слово и призвать [Водяного элементаля](CREATURE:${CREATURE_WATER_ELEMENTAL}), как если бы наложили заклинание [Призыв элементаля](SPELL:${SPELL_CONJURE_ELEMENTAL}). Чашу нельзя использовать повторно, пока не наступит следующий рассвет.

Чаша диаметром примерно 1 фут, а в высоту она в два раза меньше. Она весит 3 фунта и вмещает 3 галлона.`,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_CONJURE_ELEMENTAL],
    source: {
      id: SOURCE_DMG,
      page: 212,
    },
  },
  {
    id: MAGIC_ITEM_BERSERKER_AXE,
    name: `Топор берсерка`,
    nameEn: `Berserker Axe`,
    type: MGC_TYPE_WEAPON,
    typeComment: `любой топор`,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Вы получаете бонус +1 к броскам атаки и урона, совершённым этим магическим оружием. Кроме того, пока Вы настроены на это оружие, максимум Ваших хитов увеличивается на 1 за каждый полученный уровень.`,
    curse: `Этот топор проклят, и это проклятье простирается и на того, кто настраивается на него. Пока Вы остаётесь прокляты, Вы не хотите расставаться с топором, и всегда держите его под рукой. Вы также совершаете с помехой броски атаки другим оружием, кроме случаев, когда в пределах 60 футов нет врагов, которых Вы можете видеть или слышать.

Каждый раз, когда враждебное существо причиняет Вам урон, а топор при этом находится при Вас, Вы должны пройти испытание Мудрости СЛ 15, иначе станете берсерком. Будучи берсерком, Вы обязаны своим действием в каждом раунде атаковать этим топором ближайшее существо. Если Вы можете совершать дополнительные атаки частью действия Атака, Вы совершаете эти атаки, переходя к следующему ближайшему существу, после того как расправитесь с текущей целью. Если у Вас есть несколько возможных целей, Вы атакуете того, кого выберете случайным образом. Вы остаётесь берсерком, пока не получится так, что в начале Вашего хода в пределах 60 футов от Вас не останется существ, которых Вы можете видеть или слышать.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 209,
    },
  },
  {
    id: MAGIC_ITEM_BAG_OF_DEVOURING,
    name: `Сумка пожирания`,
    nameEn: `Bag of Devouring`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_VERY_RARE,
    description: `На первый взгляд эта сумка напоминает [сумку хранения](MAGIC_ITEM:${MAGIC_ITEM_BAG_OF_HOLDING}), но на самом деле это ротовое отверстие гигантского межпространственного существа. Если сумку вывернуть наизнанку, ротовое отверстие закроется.

Существо, связанное с сумкой, может чувствовать всё, что помещают в сумку. Материя животного или растительного происхождения, помещённая в сумку, пожирается и исчезает навсегда. Если в сумку будет помещена часть живого существа, как в случае, когда кто-нибудь засовывает в неё руку, существует 50-процентный шанс, что существо затянет в сумку. Существо в сумке может действием попытаться высвободиться, совершая проверку Силы СЛ 15. Другое существо может действием залезть рукой в сумку, чтобы вытянуть оттуда другое существо, совершая проверку Силы СЛ 20 (при условии, что его самого не затянет туда). Все существа, начинающие ход в сумке, пожираются, а их тела уничтожаются.

Неживые предметы могут храниться в сумке, которая вмещает один кубический фут такой материи. Однако, один раз в день сумка пожирает все хранящиеся в ней предметы, выплёвывая их на другом плане существования. Мастер сам определяет время и план.

Если сумку проткнут или разорвут, она уничтожается, а всё её содержимое переносится в случайным образом выбранное место на Астральном Плане. `,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 205,
    },
  },
  {
    id: MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING,
    name: `Сапоги ходьбы и прыжков`,
    nameEn: `Boots of Striding and Springing`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите эти сапоги, Ваша скорость ходьбы становится равной 30 футов, если она была ниже, и Ваша скорость не уменьшается, когда Вы перегружены или носите тяжёлый доспех.

Кроме того, Вы можете совершать прыжки в три раза дальше, чем обычно, но Вы всё равно не можете прыгнуть дальше, чем осталось у Вас перемещения.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 199,
    },
  },
  {
    id: MAGIC_ITEM_BOOTS_OF_SPEED,
    name: `Сапоги скорости`,
    nameEn: `Boots of Speed`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Пока Вы носите эти сапоги, Вы можете бонусным действием щёлкнуть каблуками. Если Вы делаете это, то сапоги удваивают Вашу скорость ходьбы, а все существа, совершающие по Вам провоцированные атаки, совершают броски атаки с помехой. Если Вы щёлкнете каблуками повторно, Вы прекращаете действие эффекта.

Если свойства этих сапог использовались в общей сложности 10 минут, магия перестаёт работать, пока Вы не закончите длинный отдых.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 198,
    },
  },
  {
    id: MAGIC_ITEM_BOOTS_OF_LEVITATION,
    name: `Сапоги левитации`,
    nameEn: `Boots of Levitation`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Если Вы носите эти сапоги, Вы можете неограниченно действием сотворять на себя заклинание [Левитация](SPELL:${SPELL_LEVITATE}).`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_LEVITATE],
    source: {
      id: SOURCE_DMG,
      page: 198,
    },
  },
  {
    id: MAGIC_ITEM_BELT_OF_DWARVENKIND,
    name: `Пояс дварфов`,
    nameEn: `Belt of Dwarvenkind`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Пока Вы носите этот пояс, Вы получаете следующие преимущества:

* Ваше Телосложение увеличивается на 2, с максимумом 20.
* Вы совершаете с преимуществом проверки Харизмы (Убеждение) при взаимодействии с дварфами.

Кроме того, пока Вы настроены на этот пояс, у Вас каждый день есть 50-процентный шанс отрастить бороду, если она у Вас вообще растёт, или же Ваша борода станет гораздо гуще, если она уже есть.

Если Вы — не дварф, Вы получаете следующие дополнительные преимущества, пока носите этот пояс.

* Вы получаете преимущество к испытаниям от яда и сопротивление к урону ядом.
* Вы получаете тёмное зрение в пределах 60 футов.
* Вы можете говорить, читать и писать на Дварфийском языке.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 197,
    },
  },
  {
    id: MAGIC_ITEM_BROOM_OF_FLYING,
    name: `Помело полёта`,
    nameEn: `Broom of Flying`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    description: `Это деревянное помело весит 3 фунта и действует как обычная метла, пока Вы не сядете на неё верхом и не произнесёте командное слово. После этого она начинает парить и может подняться в воздух. У неё скорость полёта 50 футов. Она может поднимать до 400 фунтов, но при весе 200 фунтов её скорость уже уменьшается до 30 футов. Помело перестаёт парить, когда Вы приземляетесь.

Вы можете отправить помело в другое место, находящееся в пределах 1 мили, если произнесёте командное слово и назовёте место, знакомое Вам. Помело вернётся к Вам, когда Вы произнесёте второе командное слово, при условии, что помело всё ещё находится в пределах 1 мили.`,
    genderId: GENDER_MIDDLE,
    source: {
      id: SOURCE_DMG,
      page: 192,
    },
  },
  {
    id: MAGIC_ITEM_BRACERS_OF_ARCHERY,
    name: `Наручи стрельбы из лука`,
    nameEn: `Bracers of Archery`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите эти наручи, Вы получаете навык владения [длинным](GEAR:${GEAR_LONGBOW}) и [коротким луком](GEAR:${GEAR_SHORTBOW}) и бонус +2 к броскам урона дальнобойными атаки этим оружием.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 186,
    },
  },
  {
    id: MAGIC_ITEM_BRACERS_OF_DEFENSE,
    name: `Наручи защиты`,
    nameEn: `Bracers of Defense`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Пока Вы носите эти наручи, Вы получаете бонус +2 к КД, если не носите доспех и не используете [щит](GEAR:${GEAR_SHIELD}).`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 186,
    },
  },
  {
    id: MAGIC_ITEM_BOOTS_OF_THE_WINTERLANDS,
    name: `Заполярные сапоги`,
    nameEn: `Boots of the Winterlands`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Эти меховые сапоги очень плотные и тёплые. Пока Вы их носите, Вы получаете следующие преимущества:

* Вы получаете сопротивление к урону холодом.
* Вы игнорируете труднопроходимую местность, созданную льдом или снегом.
* Вы нормально выносите такую низкую температуру как −50 °F (−45 °C), без дополнительной тёплой одежды. Если Вы одеты очень тепло, то можете переносить температуру до −100 °F (−73 °C).`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_DMG,
      page: 165,
    },
  },
  {
    id: MAGIC_ITEM_BRAZIER_OF_COMMANDING_FIRE_ELEMENTALS,
    name: `Жаровня командования огненными элементалями`,
    nameEn: `Brazier of Commanding Fire Elementals`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Если в этой латунной жаровне горит огонь, Вы можете действием произнести командное слово и призвать [огненного элементаля](CREATURE:${CREATURE_FIRE_ELEMENTAL}), как если бы наложили заклинание [Призыв элементаля](SPELL:${SPELL_CONJURE_ELEMENTAL}). Жаровню нельзя использовать повторно, пока не наступит следующий рассвет.

Весит жаровня 5 фунтов.`,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_CONJURE_ELEMENTAL],
    source: {
      id: SOURCE_DMG,
      page: 160,
    },
  },
  {
    id: MAGIC_ITEM_BROOCH_OF_SHIELDING,
    name: `Брошь защиты`,
    nameEn: `Brooch of Shielding`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Пока Вы носите эту брошь, Вы обладаете сопротивлением к урону силовым полем и иммунитетом к урону от заклинания [Волшебная стрела](SPELL:${SPELL_MAGIC_MISSILE}). `,
    genderId: GENDER_FEMALE,
    spellIdList: [SPELL_MAGIC_MISSILE],
    source: {
      id: SOURCE_DMG,
      page: 152,
    },
  },
  {
    id: MAGIC_ITEM_BEAD_OF_FORCE,
    name: `Бусина силы`,
    nameEn: `Bead of Force`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    description: `Это маленький чёрный шарик 3/4 дюйма в диаметре и весом в одну унцию. Как правило, 1к4 + 4 бусин силы находят вместе.

Вы можете действием бросить бусину на расстояние до 60 футов. Бусина взрывается при ударе и уничтожается. Все существа, находящиеся в пределах 10 футов от места приземления бусины, должны пройти испытание Ловкости СЛ 15, иначе получат урон силовым полем 5к4. После этого на этой площади образуется прозрачная сфера из силового поля, остающаяся на этом месте в течение 1 минуты. Все существа, провалившие испытание и полностью находящиеся в пределах этой площади, оказывается запертым внутри сферы. Существа, прошедшие испытание, или находящиеся в этой сфере лишь частично, выталкиваются прочь от центра сферы, пока полностью не окажутся за пределами действия силового поля. Никакие атаки и эффекты не могут проникать сквозь сферу — только воздух, пригодный для дыхания.

Запертое внутри существо может действием толкать сферу, перемещая её со своей уменьшенной вдвое скоростью. Сферу можно легко поднять, так как вне зависимости от веса существ, находящихся в ней, она весит всего 1 фунт.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_DMG,
      page: 153,
    },
  },
]
