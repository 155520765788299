const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {
  SOURCE_EE,
  SOURCE_XGTE,
} = require('./../../../../../sourceList')
const {CONDITION_PRONE} = require('./../../../../../conditionList')
const {DAMAGE_BLUDGEONING} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_DEX} = require('./../../../../../paramList')
const {SPELL_EARTH_TREMOR} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_EARTH_TREMOR,
  name: 'Дрожь земли',
  nameEn: 'Earth Tremor',
  description: `Вы сотрясаете землю в пределах дистанции заклинания. Все существа, кроме вас, в этой области должны пройти испытание Ловкости. При провале существо получает дробящий урон 1к6 и сбивается с ног. Если поверхность представляет собой рыхлую землю или камень, то область воздействия становится труднопроходимой местностью до тех пор, пока её не расчистят. Каждая область диаметром 5 футов требует как минимум 1 минуту на расчистку вручную.`,
  note: {
    text: 'Каждый обвал в шахте — это Морадин, требующий оставить землю в покое',
    author: 'Хальгар, дварф-отшельник',
  },
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку 2 круга или выше, то урон увеличивается на 1к6 за каждый круг ячейки выше 1.`,
  lvl: 1,
  magicSchoolId: MAGIC_EVOCATION,
  range: 10,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  effect: {
    savethrowParam: PARAM_DEX,
    condition: CONDITION_PRONE,
    damage: {
      type: DAMAGE_BLUDGEONING,
      diceCount: 1,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_XGTE,
      page: 151,
    },
    {
      id: SOURCE_EE,
      page: 16,
    },
  ],
}
