const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_BONUS_ACTION,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_SHIELD_OF_FAITH,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_SHIELD_OF_FAITH,
  name: 'Щит веры',
  nameEn: 'Shield of Faith',
  description: `Мерцающее поле появляется, окружая выбранное Вами существо в пределах дистанции, даруя ему на время длительности бонус +2 к КД.`,
  lvl: 1,
  magicSchoolId: MAGIC_ABJURATION,
  range: 60,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `небольшой пергамент со святыми письменами`,
  duration: { timeId: TIME_MINUTE, count: 10 },
  castTime: { timeId: TIME_BONUS_ACTION, count: 1 },
  needConcentration: true,
  source: [
    {
      id: SOURCE_PHB,
      page: 289,
    },
    {
      id: SOURCE_SRD,
      page: 179,
    },
  ],
}
