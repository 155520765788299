const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_SOMATIC,
  CAST_MATERIAL,
  CAST_VERBAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
} = require('./../../../../../sourceList')
const {
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_FIZBAN_S_PLATINUM_SHIELD,
  SPELL_FIZBAN_S_PLATINUM_SHIELD_2021_04_14,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_FIZBAN_S_PLATINUM_SHIELD_2021_04_14,
  releasedAs: SPELL_FIZBAN_S_PLATINUM_SHIELD,
  name: 'Физбанов платиновый щит',
  nameEn: 'Fizban’s Platinum Shield',
  description: `Вы создаёте поле серебристого света, окружающего выбранное Вами в пределах дистанции существо. Поле испускает тусклый свет в пределах 5 футов.

На последующих ходах Вы можете бонусным действием сдвигать поле на другое существо в пределах 60 футов от поля.

Существо, защищаемое полем, получает следующие преимущества.

* Существо получает укрытие на половину.
* Существо получает сопротивление к урону кислотой, огнём, холодом, электричеством и ядом.
* Если существо попадает под действие эффекта, требующего пройти испытание Ловкости, чтобы получить только половину урона, вместо этого при успехе существо не получает вовсе никакого урона, и получает только половину урона при провале.`,
  lvl: 6,
  magicSchoolId: MAGIC_ABJURATION,
  range: 60,
  componentIdList: [
    CAST_SOMATIC,
    CAST_MATERIAL,
    CAST_VERBAL,
  ],
  materialText: `чешуйка платинового дракона, стоимостью не менее 500 зм`,
  hasCost: true,
  duration: { timeId: TIME_MINUTE, count: 1 },
  needConcentration: true,
  source: {
    id: SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
    page: 5,
  },
}
