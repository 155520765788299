const {
  SPEED_CLIMB,
  SPEED_DIG,
} = require('./../../../../speedList')
const {
  CREATURE_GUARD_DRAKE,
  CREATURE_GUARD_DRAKE_WHITE,
} = require('./../../../../creatureIdList')
const {DAMAGE_COLD} = require('./../../../../damageTypeList')

module.exports = {
  name: 'Белый сторожевой дрейк',
  nameEn: 'White Guard Drake',
  id: CREATURE_GUARD_DRAKE_WHITE,
  parentId: CREATURE_GUARD_DRAKE,
  extendPropCollection: {
    speed: {
      [SPEED_DIG]: 20,
      [SPEED_CLIMB]: 30,
    },
    resistanceList: [
      DAMAGE_COLD,
    ],
  },
}
