const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_KOBOLD,
} = require('./../../../../creatureIdList')
const {
  koboldDescriptionList,
  koboldNote,
} = require('./../../../../textCommonParts')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KOBOLD,
} = require('./../../../../creatureTypeIdList')
const {
  ABILITY_PACK_TACTICS,
  ABILITY_SUNLIGHT_SENSITIVITY,
} = require('./../../../../creatureAbilityList')
const {
  CR_1_8,
} = require('./../../../../crList')
const {
  ALIGNMENT_LE,
} = require('./../../../../aligmentList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  GEAR_DAGGER,
  GEAR_SLING,
} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Кобольд',
  nameEn: 'Kobold',
  id: CREATURE_KOBOLD,
  description: koboldDescriptionList,
  note: koboldNote,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_KOBOLD,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MM,
    page: 177,
  },
  armor: 12,
  hp: {
    diceCount: 2,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 7,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 9,
    [PARAM_INT]: 8,
    [PARAM_WIT]: 7,
    [PARAM_CHA]: 8,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_DRACONIC,
    LANG_COMMON,
  ],
  cr: CR_1_8,
  featureList: [
    ABILITY_SUNLIGHT_SENSITIVITY,
    ABILITY_PACK_TACTICS,
  ],
  actionList: [
    {
      gearId: GEAR_DAGGER,
    },
    {
      gearId: GEAR_SLING,
    },
  ],
  genderId: GENDER_MALE,
}
