const {
  CAST_VERBAL,
  CAST_SOMATIC,
} = require('./../../../../../castComponentList')
const {ATTACK_RANGE_SPELL} = require('./../../../../../attackTypeList')
const {DAMAGE_RADIANT} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {SOURCE_XGTE} = require('./../../../../../sourceList')
const {SPELL_CROWN_OF_STARS} = require('./../../../../../spellIdList')
const {TIME_HOUR} = require('./../../../../../timePeriodList')

module.exports = {
  id: SPELL_CROWN_OF_STARS,
  name: 'Корона звёзд',
  nameEn: 'Crown of Stars',
  description: `Семь похожих на звезды частиц света начинают вращаться вокруг Вашей головы, пока заклинание не закончится. Бонусным действием Вы можете отправить одну из частиц к одному существу или объекту на расстояние 120 футов от вас. Если Вы так делаете, совершите дальнобойную атаку заклинанием. При попадании цель получает 4к12 урона излучением.

Независимо от того, попали Вы или нет, частица света исчезает. Действие заклинания оканчивается, когда исчезнет последняя частица света.

Пока у Вас остается четыре или более частиц, они испускают яркий свет в радиусе 30 футов и тусклый свет в пределах еще 30 футов. Если у Вас осталось три или менее частиц, то они испускают тусклый свет в радиусе 30 футов.`,
  highLvlCast: `Если Вы сотворяете это заклинание, используя ячейку заклинания 8 круга или выше, количество создаваемых частиц света увеличивается на две за каждый круг ячейки выше 7.`,
  lvl: 7,
  magicSchoolId: MAGIC_EVOCATION,
  range: -1,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC],
  duration: {timeId: TIME_HOUR, count: 1},
  effect: {
    attackType: ATTACK_RANGE_SPELL,
    damage: {
      type: DAMAGE_RADIANT,
      diceCount: 4,
      diceType: 12,
    },
  },
  source: {
    id: SOURCE_XGTE,
    page: 155,
  },
}
