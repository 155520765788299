const generateRarityList = require('./../../../../../../utils/generateRarityList')

const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {MAGIC_ITEM_ARCANE_GRIMOIRE} = require('./../../../../../magicItemIdList')
const {SOURCE_TCoE} = require('./../../../../../sourceList')
const {GEAR_SPELLBOOK} = require('./../../../../../gearIdList')
const {
  FEATURE_ARCANE_RECOVERY,
  FEATURE_SPELLBOOK,
} = require('./../../../../../featureIdList')
const {PC_CLASS_WIZARD} = require('./../../../../../pcClassIdList')

const list = require('./list')
const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_ARCANE_GRIMOIRE,
  name: `Волшебный гримуар, +1, +2 или +3`,
  nameEn: `Arcane Grimoire, +1, +2 or +3`,
  rarity: generateRarityList(list),
  gearType: GEAR_SPELLBOOK,
  attunement: true,
  attunementComment: `волшебником`,
  type: MGC_TYPE_WONDROUS_ITEM,
  description: `Держа эту книгу в кожаном переплёте, Вы можете использовать её как заклинательную фокусировку для Ваших заклинаний [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}) и получаете бонус к броскам атаки заклинаниями и СЛ испытаний Ваших заклинаний Волшебника. Размер бонуса определяется редкостью _Гримуара_.

_Волшебный гримуар_ может использоваться как [книга заклинаний](FEATURE:${FEATURE_SPELLBOOK}).

Когда Вы используете свою способность [Магическое восстановление](FEATURE:${FEATURE_ARCANE_RECOVERY}), Вы увеличиваете на 1 суммарный круг восстанавливаемых ячеек.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_TCoE,
    page: 172,
  },
}
