const {LANG_TYPE_HIDDEN} = require('./../../languageTypeList')
const {LANG_WORGEN} = require('./../../languageIdList')

module.exports = {
  id: LANG_WORGEN,
  type: LANG_TYPE_HIDDEN,
  name: {
    nominative: 'Язык воргов',
    genitive: 'Языка воргов',
    instrumental: 'Языком воргов',
    prepositional: 'Языке воргов',
  },
  isRealLang: true,
}
