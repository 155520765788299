const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  DAMAGE_COLD,
  DAMAGE_NECROTIC,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  CREATURE_BONECLAW,
  CREATURE_LICH,
} = require('./../../../../creatureIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_MAIN_LANGUAGE_OF_ITS_MASTER,
} = require('./../../../../languageIdList')
const {
  CREATURE_TYPE_SKELETON,
  CREATURE_TYPE_UNDEAD,
} = require('./../../../../creatureTypeIdList')
const {ABILITY_SHADOW_STEALTH} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_12} = require('./../../../../crList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Костелап',
  nameAlt: 'Квазилич',
  nameEn: 'Boneclaw',
  id: CREATURE_BONECLAW,
  description: [
    {
      header: 'Костелап',
      text: `Волшебник, который попытался превратиться в [лича](CREATURE:${CREATURE_LICH}), но потерпел неудачу, может вместо этого превратиться в костелапа. Эта отвратительная и смертоносная разновидность нежити имеет пару схожих черт с [личом](CREATURE:${CREATURE_LICH}), однако, тогда как [личи](CREATURE:${CREATURE_LICH}) — это бессмертные мастера магии, то костелапы являются всего лишь рабами тьмы, ненависти и боли.

Самой важной частью ритуала перевоплощения является этап, в котором дух будущего [лича](CREATURE:${CREATURE_LICH}) переходит в специально заготовленный филактерий. Если заклинатель слишком физически или магически слаб, чтобы заточить свою душу в её новую тюрьму, то вместо этого его душа ищет себе нового хозяина — гуманоида, находящегося в пределах нескольких миль, с необычайно чёрствым сердцем. Душа привязывается к испоганенной сущности, которую она нашла в этом гуманоиде, создавая костелапа — нечто, навечно порабощённого желаниями и подсознательными стремлениями своего хозяина. Оно появляется из ниоткуда рядом со своим хозяином по его велению, либо даже перед тем, как хозяин призовёт его, и исполняет желания, удовлетворяя нужды повелителя.`,
      source: {
        id: SOURCE_MTOF,
        page: 202,
      },
    },
    {
      header: 'Ограниченное бессмертие',
      text: `Костелап не может умереть, пока жив его господин. Чтобы ни случилось с телом костелапа, оно в считанные часы восстанавливает свою прежнюю форму и продолжает служить своему хозяину.

Костелап служит только злым персонажам. Если его господин находит искупление или добросовестно отказывается следовать по тропе зла, то костелап исчезает навсегда.`,
      source: {
        id: SOURCE_MTOF,
        page: 202,
      },
    },
    {
      header: 'Безжалостные убийцы',
      text: `Костелапы получают непомерное удовольствие от убийств, и ничто так не нравится им, как причинение ужасающей боли жертве. Они прячутся словно пауки в тёмных уголках и выжидают появления жертвы, чтобы схватить её длинными костлявыми конечностями. Схватив существо, они втягивают его в кромешную тьму и расчленяют жертву, либо перемещаются в другое место и пытают её до смерти.`,
      source: {
        id: SOURCE_MTOF,
        page: 202,
      },
    },
    {
      header: 'Мрачные знамения',
      text: `Хозяин костелапа может не желать иметь такого слугу или даже не знать, что тот существует. Костелапы могут привязаться к мелким воришкам, задирам и иногда к жестоким детям. Даже если хозяин не догадывается о существовании своего нового жуткого телохранителя, вся округа столкнётся с чередой исчезновений и жестоких убийств, объединённых завистью или жаждой мести хозяина костелапа.`,
      source: {
        id: SOURCE_MTOF,
        page: 202,
      },
    },
    {
      header: 'Природа нежити',
      text: `Костелапу нет надобности в воздухе, еде, питье и сне.`,
      source: {
        id: SOURCE_MTOF,
        page: 202,
      },
    },
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
    CREATURE_TYPE_SKELETON,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 202,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 17,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 19,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 15,
    [PARAM_INT]: 13,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 9,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_NECROTIC,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_MAIN_LANGUAGE_OF_ITS_MASTER,
  ],
  cr: CR_12,
  featureList: [
    {
      name: 'Восстановление',
      description: `Пока его хозяин жив, уничтоженный костелап получает новое тело с полными хитами через 1к10 часов. Тело появляется в пределах 1 мили от хозяина.`,
    },
    ABILITY_SHADOW_STEALTH,
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Костелап совершает две атаки _Когтями_.`,
    },
    {
      name: 'Пронзающие когти',
      description: `Если цель — существо, то костелап может Схватить её и подтянуть к себе на 10 фт. У костелапа два набора когтей. Когда костелап хватает цель когтями, он может атаковать этими когтями только её.`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 15,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 3,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Теневой прыжок',
      description: `Если костелап находится в области тьмы или тусклого света, все существа по его выбору в пределах 5 фт от него должны пройти испытание Телосложения СЛ 14 или получить 34 (5к12 + 2) урона некротической энергией. После этого костелап магически телепортируется на расстояние до 60 фт в незанятое пространство, которое может видеть. Он может забрать с собой одно схваченное им существо, телепортируя и его в незанятое пространство в пределах 5 фт. от точки назначения. Места, куда телепортируется и костелап и его цель должны находиться в области тьмы или тусклого света.`,
    },
  ],
  reactionList: [
    {
      name: 'Смертельная досягаемость',
      description: `Если существо, передвигаясь, оказывается в зоне досягаемости костелапа, он может атаковать эту цель. Если атака попадает по цели, то костелап может совершить вторую атаку когтями по этой же цели.`,
    },
  ],
}
