const {
  WEAPON_AMMUNITION,
  WEAPON_LIGHT,
  WEAPON_RANGE,
  WEAPON_RELOADING,
} = require('./../../../weaponPropList')
const {
  SOURCE_PHB,
  SOURCE_AYAGWnW,
} = require('./../../../sourceList')
const crossbowsDescription = require('./../../constants/crossbowsDescription')
const {CAT_MARTIAL_RANGE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_CROSSBOW_HAND} = require('./../../../gearIdList')
const {GENDER_MALE} = require('./../../../genderList')

module.exports = {
  id: GEAR_CROSSBOW_HAND,
  genderId: GENDER_MALE,
  name: 'Ручной арбалет',
  nameByCase: {
    nominative: 'ручной арбалет',
    genitive: 'ручного арбалета',
    accusative: 'ручной арбалет',
    instrumental: 'ручным арбалетом',
  },
  nameEn: 'Crossbow, hand',
  description: crossbowsDescription,
  damage: {
    diceType: 6,
    diceCount: 1,
  },
  cost: 7500,
  damageType: DAMAGE_PIERCING,
  weight: 3,
  source: [
    {
      id: SOURCE_PHB,
      page: 149,
    },
    {
      id: SOURCE_AYAGWnW,
      page: 114,
    },
  ],
  weaponPropList: [
    WEAPON_AMMUNITION,
    WEAPON_LIGHT,
    WEAPON_RELOADING,
    {
      id: WEAPON_RANGE,
      value: {
        normal: 30,
        max: 120,
      },
    },
  ],
  category: CAT_MARTIAL_RANGE_WEAPON,
}
