const {
    SOURCE_MM,
  } = require('./../../../../sourceList'),
  {
    CR_1_2,
  } = require('./../../../../crList'),
  {
    SIZE_SMALL,
  } = require('./../../../../sizeList'),
  {
    CREATURE_TYPE_GNOME,
    CREATURE_TYPE_HUMANOID,
  } = require('./../../../../creatureTypeIdList'),
  {
    ALIGNMENT_NG,
  } = require('./../../../../aligmentList'),
  {
    SPEED_WALK,
  } = require('./../../../../speedList'),
  {
    ATTACK_MELEE_WEAPON,
    ATTACK_RANGE_WEAPON,
  } = require('./../../../../attackTypeList'),
  {
    LANG_GNOMISH,
    LANG_TERRAN,
    LANG_UNDERCOMMON,
  } = require('./../../../../languageIdList'),
  {
    DAMAGE_PIERCING,
  } = require('./../../../../damageTypeList'),
  {
    SENSE_DARK_VISION,
  } = require('./../../../../senseList'),
  {
    SKILL_INVESTIGATION,
    SKILL_PERCEPTION,
    SKILL_STEALTH,
  } = require('./../../../../skillList'),
  {
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  } = require('./../../../../paramList'),
  {
    SPELL_BLINDNESS_DEAFNESS,
    SPELL_BLUR,
    SPELL_DISGUISE_SELF,
    SPELL_NONDETECTION,
  } = require('./../../../../spellIdList'),
  {
    CAST_MATERIAL,
  } = require('./../../../../castComponentList'),
  {
    TARGET_CREATURE,
  } = require('./../../../../targetList'),
  {
    GENDER_MALE,
  } = require('./../../../../genderList'),
  {
    GEAR_CHAIN_SHIRT,
    GEAR_WAR_PICK,
  } = require('./../../../../gearIdList'),
  {
    ABILITY_GNOME_CUNNING,
    ABILITY_STONE_CAMOUFLAGE,
  } = require('./../../../../creatureAbilityList'),
  {
    PROF_NORMAL,
  } = require('./../../../../proficiencyList'),
  {
    CREATURE_BEHOLDER,
    CREATURE_DROW,
    CREATURE_DUERGAR,
    CREATURE_EARTH_ELEMENTAL,
    CREATURE_GNOME_DEEP,
    CREATURE_KUO_TOA,
    CREATURE_MIND_FLAYER,
    CREATURE_XORN,
  } = require('./../../../../creatureIdList')

module.exports = {
  name: 'Глубинный гном',
  nameAlt: 'Свирфнеблин',
  nameEn: 'Gnome, deep',
  nameEnAlt: 'Svirfneblin',
  id: CREATURE_GNOME_DEEP,
  description: `Глубинные гномы, или свирфнеблины, живут глубоко под поверхностью земли в запутанных подземных проходах и искусственных пещерах. Они выживают благодаря скрытности, уму и упорству. Их серая кожа позволяет сливаться с окружающим камнем. Для своего размера свирфнеблины удивительно сильны и тяжелы. В среднем, взрослый глубинный гном весит от 100 до 120 фунтов, а в высоту достигает 3 футов.

Обычно анклав глубинных гномов хорошо укреплён, и в нем проживает несколько сотен свирфнеблинов. На случае нападения врагов в поселениях глубинных гномов есть секретные туннели для эвакуации.

# Разделение по полу

Мужчины свирфнеблинов лысы, в то время как у женщин свалянные серые волосы. Пока мужчины ходят по окраинам в поисках врагов и месторождений драгоценных камней, женщины управляют анклавом.

# Собиратели драгоценных камней

Свирфнеблины ищут драгоценные камни, особенно рубины, которые добывают из шахт глубоко в Подземье. Именно охота за самоцветами втягивает их в конфликты со [злобоглазами](CREATURE:${CREATURE_BEHOLDER}), [дроу](CREATURE:${CREATURE_DROW}), [куо-тоа](CREATURE:${CREATURE_KUO_TOA}), [двергарами](CREATURE:${CREATURE_DUERGAR}) и [свежевателями разума](CREATURE:${CREATURE_MIND_FLAYER}). Но среди множества этих врагов больше всего гномы боятся и презирают безжалостных демонопоклонников дроу.

# Друзья земли

Глубинного гнома частенько можно встретить в компании существ со Стихийного Плана Земли. Некоторые свирфнеблины могут призывать этих существ. Поселения гномов охраняются [земляными существами](CREATURE:${CREATURE_EARTH_ELEMENTAL}), а также [зорнами](CREATURE:${CREATURE_XORN}), которых обещают кормить самоцветами.`,
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GNOME,
  ],
  alignmentId: ALIGNMENT_NG,
  source: {
    id: SOURCE_MM,
    page: 62,
  },
  armor: {
    ac: 15,
    gearId: GEAR_CHAIN_SHIRT,
  },
  hp: {
    diceCount: 3,
  },
  speed: {
    [SPEED_WALK]: 20,
  },
  params: {
    [PARAM_STR]: 15,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 14,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 10,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_INVESTIGATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_GNOMISH,
    LANG_UNDERCOMMON,
    LANG_TERRAN,
  ],
  cr: CR_1_2,
  featureList: [
    ABILITY_STONE_CAMOUFLAGE,
    ABILITY_GNOME_CUNNING,
  ],
  spellCast: {
    baseStat: PARAM_INT,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_NONDETECTION,
            comment: 'только на себя',
          },
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_BLINDNESS_DEAFNESS,
          SPELL_DISGUISE_SELF,
          SPELL_BLUR,
        ],
      },
    ],
  },
  actionList: [
    {
      gearId: GEAR_WAR_PICK,
    },
    {
      name: 'Отравленный дротик',
      description: `Цель должна пройти испытание Телосложения СЛ 12, иначе станет отравленной на 1 минуту. Цель может повторять это испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 4,
        range: {
          normal: 30,
          max: 120,
        },
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    },
  ],
  genderId: GENDER_MALE,
}
