const listToCollectionById = require('./../../utils/listToCollectionById')

const pcClassRawList = require('./pcClassRawList')

const pcClassList = pcClassRawList
  .sort(
    ({ name: { singular: { nominative: A } } }, { name: { singular: { nominative: B } } }) => A > B
      ? 1
      : -1,
  )

module.exports = pcClassList

module.exports.pcClassCollection = listToCollectionById(pcClassList)
