const {
  SPELL_BLUR,
  SPELL_BURNING_HANDS,
  SPELL_CONE_OF_COLD,
  SPELL_CONTROL_WATER,
  SPELL_CREATE_FOOD_AND_WATER,
  SPELL_CREATION,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_FIRE_SHIELD,
  SPELL_FIREBALL,
  SPELL_FLAME_STRIKE,
  SPELL_FOG_CLOUD,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GUST_OF_WIND,
  SPELL_MELD_INTO_STONE,
  SPELL_PHANTASMAL_FORCE,
  SPELL_PHANTASMAL_KILLER,
  SPELL_SANCTUARY,
  SPELL_SCORCHING_RAY,
  SPELL_SEEMING,
  SPELL_SLEET_STORM,
  SPELL_SPIKE_GROWTH,
  SPELL_STONE_SHAPE,
  SPELL_THUNDERWAVE,
  SPELL_WALL_OF_STONE,
  SPELL_WIND_WALL,
  SPELL_WISH,
} = require('./../../../spellIdList')
const {
  FEATURE_ELEMENTAL_GIFT_2020_05_12,
  FEATURE_EXPANDED_SPELLS_THE_GENIE_2020_05_12,
  FEATURE_EXPANDED_SPELLS_THE_GENIE_DAO_2020_05_12,
  FEATURE_EXPANDED_SPELLS_THE_GENIE_DJINNI_2020_05_12,
  FEATURE_EXPANDED_SPELLS_THE_GENIE_EFREETI_2020_05_12,
  FEATURE_EXPANDED_SPELLS_THE_GENIE_MARID_2020_05_12,
  FEATURE_GENIE_S_VESSEL_2020_05_12,
  FEATURE_LIMITED_WISH_2020_05_12,
  FEATURE_SANCTUARY_VESSEL_2020_05_12,
} = require('./../../../featureIdList')
const {PC_SUBCLASS_WARLOCK_THE_GENIE_TCoE_PC_WtG} = require('./../../../pcSubClassIdList')
const {SOURCE_UA_2020_09_15_TCoE_PC_WtG} = require('./../../../sourceList')
const {warlockSpellCircleList} = require('./../../../spellCircleByCasterType')

module.exports = [
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE_2020_05_12,
    name: `Дополнительные заклинания`,
    nameEn: `Expanded Spells`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_CREATE_FOOD_AND_WATER,
      SPELL_CREATION,
      SPELL_DETECT_EVIL_AND_GOOD,
      SPELL_PHANTASMAL_FORCE,
      SPELL_PHANTASMAL_KILLER,
      SPELL_WISH,
    ],
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
      page: 1,
    },
  },
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE_DAO_2020_05_12,
    name: `Дополнительные заклинания дао`,
    nameEn: `Expanded Spells: Dao`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_MELD_INTO_STONE,
      SPELL_SANCTUARY,
      SPELL_SPIKE_GROWTH,
      SPELL_STONE_SHAPE,
      SPELL_WALL_OF_STONE,
    ],
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
      page: 1,
    },
  },
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE_DJINNI_2020_05_12,
    name: `Дополнительные заклинания джинна`,
    nameEn: `Expanded Spells: Djinni`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_GREATER_INVISIBILITY,
      SPELL_GUST_OF_WIND,
      SPELL_SEEMING,
      SPELL_THUNDERWAVE,
      SPELL_WIND_WALL,
    ],
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
      page: 1,
    },
  },
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE_EFREETI_2020_05_12,
    name: `Дополнительные заклинания ифрита`,
    nameEn: `Expanded Spells: Efreeti`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_BURNING_HANDS,
      SPELL_FIRE_SHIELD,
      SPELL_FIREBALL,
      SPELL_FLAME_STRIKE,
      SPELL_SCORCHING_RAY,
    ],
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
      page: 1,
    },
  },
  {
    id: FEATURE_EXPANDED_SPELLS_THE_GENIE_MARID_2020_05_12,
    name: `Дополнительные заклинания марида`,
    nameEn: `Expanded Spells: Marid`,
    lvl: 1,
    spellCircleLvl: warlockSpellCircleList,
    spellIdList: [
      SPELL_BLUR,
      SPELL_CONE_OF_COLD,
      SPELL_CONTROL_WATER,
      SPELL_FOG_CLOUD,
      SPELL_SLEET_STORM,
    ],
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
      page: 1,
    },
  },
  {
    id: FEATURE_GENIE_S_VESSEL_2020_05_12,
    name: `Сосуд гения`,
    nameEn: `Genie’s Vessel`,
    lvl: 1,
    text: `Ваш покровитель дарит Вам магический сосуд, который даёт Вам часть силы гения. Сосуд — это Крохотный предмет и Вы можете использовать его как магическую фокусировку для заклинаний колдуна. Вы решаете что это за предмет или определите его случайно по таблице «Сосуды гения».

# Сосуды гения

| к6 | Сосуд                 |
|----|-----------------------|
| 1  | Масляная лампа        |
| 2  | Урна                  |
| 3  | Кольцо с секретом     |
| 4  | Запечатанная бутыль   |
| 5  | Полая статуэтка       |
| 6  | Изысканный светильник |

КД сосуда равен Вашей СЛ заклинаний. Его хиты равны Вашему уровню колдуна + Ваш бонус мастерства, и он иммунен к урону ядом и психическому урону.

Если сосуд уничтожен или Вы его потеряли, Вы можете совершить часовую церемонию, чтобы получить замену от покровителя. Эту церемонию можно провести во время короткого или длинного отдыха и она уничтожает предыдущий сосуд. Если Вы умираете, сосуд исчезает в стихийном пламени.

Держа этот сосуд, Вы можете использовать его следующими способами.

# Передышка в бутылке

Вы можете действием магически исчезнуть и войти в Ваш сосуд, который остаётся в покинутом Вами пространстве. Внутри сосуда находится межпространственное место цилиндрической формы радиусом и глубиной 20 футов, выглядящее как Ваш сосуд изнутри. Интерьер комфортно обставлен подушками и низкими столиками и имеет комфортную температуру. 

Будучи внутри, Вы слышите всё, что происходит снаружи, как если бы Вы сами там находились. Вы можете оставаться внутри количество часов, равное Вашему удвоенному бонусу мастерства. Вы выходите из сосуда до этого, если выходите из него бонусным действием, умираете, или если сосуд уничтожается. Когда Вы выходите из сосуда, то появляетесь в свободном пространстве рядом с ним.

Все предметы, оставленные в сосуде, остаются там, пока их оттуда не вынесут. Если сосуд будет уничтожен, все хранящиеся там предметы невредимо появляются в свободном пространстве рядом с ним. 

Войдя в сосуд, Вы не сможете войти в него снова, пока не завершите длинный отдых.

# Гнев гения

Один раз на каждом своём ходу, когда Вы попадаете броском атаки, Вы можете нанести цели дополнительный урон, равный Вашему бонусу мастерства. Тип урона определяется Вашим покровителем: дробящий (дао), звуком (джинн), огнём (ифрит) или холодом (марид).`,
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
      page: 1,
    },
  },
  {
    id: FEATURE_ELEMENTAL_GIFT_2020_05_12,
    name: `Дар стихий`,
    nameEn: `Elemental Gift`,
    lvl: 6,
    text: `Вы начинаете получать черты вида своего покровителя.

Теперь у Вас есть сопротивление к урону, определяемому видом Вашего покровителя: дробящий (дао), звуком (джинн), огнём (ифрит) или холодом (марид).

Кроме этого, Вы можете бонусным действием дать себе на 10 минут скорость полёта 30 футов, при этом Вы парите. Вы можете использовать эту способность количество раз, равное Вашему бонусу мастерства и восстанавливаете все использования, завершив длинный отдых.`,
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
      page: 2,
    },
  },
  {
    id: FEATURE_SANCTUARY_VESSEL_2020_05_12,
    name: `Сосуд-убежище`,
    nameEn: `Sanctuary Vessel`,
    lvl: 10,
    text: `Теперь, входя в свой [Сосуд гения](FEATURE:${FEATURE_GENIE_S_VESSEL_2020_05_12}) способностью _Передышка в бутылке_, Вы можете выбрать до пяти согласных существ, видимых Вами в пределах 30 футов и эти существа затягиваются в сосуд вместе с Вами.

Бонусным действием Вы можете выгнать любое число существ из сосуда и все они выгоняются отсюда, когда Вы покидаете сосуд или когда он разрушается.

Кроме этого, все существа (включая Вас), находившиеся в сосуде более 10 минут, получают преимущества короткого отдыха. Если существо тратило на этом коротком отдыхе кости хитов для восстановления хитов, то оно может добавить к восстанавливаемым хитам Ваш бонус мастерства.`,
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
      page: 2,
    },
  },
  {
    id: FEATURE_LIMITED_WISH_2020_05_12,
    name: `Ограниченное желание`,
    nameEn: `Limited Wish`,
    lvl: 14,
    text: `Вы умоляете своего покровителя исполнить Вам небольшое желание.

Вы можете действием высказать своё желание в свой [Сосуд гения](FEATURE:${FEATURE_GENIE_S_VESSEL_2020_05_12}), прося об эффекте одного заклинания 6 круга или ниже и имеющего время накладывания 1 действие. Заклинание может быть из списка заклинаний любого класса, и Вам не нужно соответствовать требованиям этого заклинания, включая дорогостоящие компоненты; эффект заклинания просто возникает как часть этого действия.

Использовав эту способность, Вы не сможете использовать её вновь, пока не завершите 1к4 длинных отдыха.`,
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_WtG,
      page: 2,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_WARLOCK_THE_GENIE_TCoE_PC_WtG,
  })
)
