const {CAT_MARTIAL_MELEE_WEAPON} = require('./../../../gearCategoryList')
const {DAMAGE_PIERCING} = require('./../../../damageTypeList')
const {GEAR_RAPIER} = require('./../../../gearIdList')
const {GENDER_FEMALE} = require('./../../../genderList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
} = require('./../../../sourceList')
const {WEAPON_FINESSE} = require('./../../../weaponPropList')

module.exports = {
  id: GEAR_RAPIER,
  genderId: GENDER_FEMALE,
  name: 'Рапира',
  nameByCase: {
    nominative: 'рапира',
    genitive: 'рапиры',
    accusative: 'рапиру',
    instrumental: 'рапирой',
  },
  nameEn: 'Rapier',
  description: {
    header: 'Рапира',
    text: `Узкий, острый клинок. Излюбленное оружие аристократии Глубоководья.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  damage: {
    diceType: 8,
    diceCount: 1,
  },
  cost: 2500,
  damageType: DAMAGE_PIERCING,
  weight: 2,
  source: {
    id: SOURCE_PHB,
    page: 149,
  },
  weaponPropList: [
    WEAPON_FINESSE,
  ],
  category: CAT_MARTIAL_MELEE_WEAPON,
}
