const {
    SOURCE_GOS,
  } = require('./../../sourceList'),
  {
    GENDER_FEMALE,
    GENDER_MALE,
  } = require('./../../genderList'),
  {
    MGC_TYPE_WONDROUS_ITEM,
  } = require('./../../magicItemTypeList'),
  {
    MGC_RARITY_COMMON,
    MGC_RARITY_RARE,
    MGC_RARITY_UNCOMMON,
  } = require('./../../magicItemRarityList'),
  {
    SPELL_REMOVE_CURSE,
    SPELL_SPEAK_WITH_PLANTS,
  } = require('./../../spellIdList'),
  {
    MAGIC_ITEM_CHARM_OF_PLANT_COMMAND,
    MAGIC_ITEM_CURSED_LUCKSTONE,
    MAGIC_ITEM_HELM_OF_UNDERWATER_ACTION,
    MAGIC_ITEM_PIPE_OF_REMEMBRANCE,
    MAGIC_ITEM_PRESSURE_CAPSULE,
    MAGIC_ITEM_SEKOLAHIAN_WORSHIPING_STATUETTE,
  } = require('./../../magicItemIdList')

module.exports = [
  {
    id: MAGIC_ITEM_CHARM_OF_PLANT_COMMAND,
    name: `Амулет командования растениями`,
    nameEn: `Charm of Plant Command`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_RARE,
    attunement: true,
    description: `Этот амулет размером с кулак изготовлен из пучка сушеных стеблей растений, обёрнутых серебряной нитью. Его обычно надевают на шею или прикрепляют к поясу на кожаном ремешке.

У амулета 3 заряда. Пока Вы его носите, то можете действием потратить 1 заряд, чтобы произнести заклинание [Разговор с растениями](SPELL:${SPELL_SPEAK_WITH_PLANTS}). На время действия заклинания Вы получаете преимущество к проверкам Харизмы, совершённым для влияния на поступки, манеру поведения и отношение растений.

Амулет восстанавливает все израсходованные заряды каждый день на рассвете.`,
    genderId: GENDER_MALE,
    spellIdList: [SPELL_SPEAK_WITH_PLANTS],
    source: {
      id: SOURCE_GOS,
      page: 230,
    },
  },
  {
    id: MAGIC_ITEM_CURSED_LUCKSTONE,
    name: `Проклятый камень удачи`,
    nameEn: `Cursed Luckstone`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `На этом плоском серо-чёрном речном камне вырезан неизвестный волшебный знак. Камень кажется прохладным на ощупь.

Держа этот камень, Вы можете получить преимущество к одной проверке способности по Вашему выбору. Камень нельзя использовать таким образом до следующего рассвета.`,
    curse: `Этот предмет проклят. Настройка на него проклинает Вас, пока Вы не станете целью заклинания [Снятия проклятия](SPELL:${SPELL_REMOVE_CURSE}) или подобной магии. Пока Вы прокляты, Вы не можете выбросить этот камень, потому что он немедленно телепортируется обратно в Ваш карман или рюкзак. После того, как Вы используете магию камня, Вы получите помеху к следующим двум проверкам способностей.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_GOS,
      page: 230,
    },
  },
  {
    id: MAGIC_ITEM_HELM_OF_UNDERWATER_ACTION,
    name: `Шлем подводных действий`,
    nameEn: `Helm of Underwater Action`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_UNCOMMON,
    attunement: true,
    description: `Нося этот латунный шлем, Вы можете дышать под водой, получаете тёмное зрение на 60 футов и скорость плавания 30 футов.`,
    genderId: GENDER_MALE,
    source: {
      id: SOURCE_GOS,
      page: 230,
    },
  },
  {
    id: MAGIC_ITEM_PIPE_OF_REMEMBRANCE,
    name: `Трубка воспоминаний`,
    nameEn: `Pipe of Remembrance`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Эту длинную изящную деревянную трубку венчает чаша из гладкого речного камня.

Когда трубка зажжена, дым, выходящий из неё, не рассеивается, а задерживается вокруг курильщика. Через 10 минут дым на 5 минут образовывает движущиеся фигуры, которые воспроизводят самые впечатляющие и героические достижения курильщика. Когда это реалистичное представление завершено, дым рассеивается. Трубку нельзя будет использовать таким образом до следующего рассвета.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_GOS,
      page: 230,
    },
  },
  {
    id: MAGIC_ITEM_PRESSURE_CAPSULE,
    name: `Капсула давления`,
    nameEn: `Pressure Capsule`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    isConsumable: true,
    description: `Эта маленькая капсула сделана из воска вперемешку с песком и множеством волшебных подводных растений.

Существо, съевшее капсулу, игнорирует эффекты плавания на глубинах свыше 100 футов.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_GOS,
      page: 230,
    },
  },
  {
    id: MAGIC_ITEM_SEKOLAHIAN_WORSHIPING_STATUETTE,
    name: `Статуэтка поклонения Секоле`,
    nameEn: `Sekolahian Worshiping Statuette`,
    type: MGC_TYPE_WONDROUS_ITEM,
    rarity: MGC_RARITY_COMMON,
    description: `Мастерски вырезанная из камня, эта статуэтка высотой в 1 фут изображает акулу, извивающуюся в воде с открытой пастью.

Если какое-либо Крошечное морское животное оказывается в пределах 1 дюйма от пасти статуэтки, акула оживает и наносит ему 1 колющего урона. Акула может наносить так урон не чаще одного раза в час.`,
    genderId: GENDER_FEMALE,
    source: {
      id: SOURCE_GOS,
      page: 230,
    },
  },
]
