const {
  SPELL_CHARM_PERSON,
  SPELL_DETECT_THOUGHTS,
  SPELL_HOLD_PERSON,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_HAND,
  SPELL_MINOR_ILLUSION,
  SPELL_RAY_OF_FROST,
  SPELL_SLEEP,
} = require('./../../../../spellIdList')
const {
  CREATURE_BONE_NAGA_GUARDIAN,
  CREATURE_BONE_NAGA_SPIRIT,
} = require('./../../../../creatureIdList')
const {CAST_VERBAL} = require('./../../../../castComponentList')
const {PARAM_INT} = require('./../../../../paramList')
const {PC_CLASS_WIZARD} = require('./../../../../pcClassIdList')

module.exports = {
  name: 'Костяная нага (духовная)',
  nameEn: 'Bone Naga (Spirit)',
  id: CREATURE_BONE_NAGA_SPIRIT,
  parentId: CREATURE_BONE_NAGA_GUARDIAN,
  spellCast: {
    spellCasterLevel: 5,
    spellCasterClass: PC_CLASS_WIZARD,
    componentOnly: CAST_VERBAL,
    spellIdList: [
      SPELL_CHARM_PERSON,
      SPELL_DETECT_THOUGHTS,
      SPELL_HOLD_PERSON,
      SPELL_LIGHTNING_BOLT,
      SPELL_MAGE_HAND,
      SPELL_MINOR_ILLUSION,
      SPELL_RAY_OF_FROST,
      SPELL_SLEEP,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      2,
    ],
  },
}
