const {GENDER_FEMALE} = require('./../../../../../genderList')
const {MAGIC_ITEM_ROBE_OF_STARS} = require('./../../../../../magicItemIdList')
const {MGC_RARITY_VERY_RARE} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WONDROUS_ITEM} = require('./../../../../../magicItemTypeList')
const {SOURCE_DMG} = require('./../../../../../sourceList')
const {SPELL_MAGIC_MISSILE} = require('./../../../../../spellIdList')

module.exports = {
  id: MAGIC_ITEM_ROBE_OF_STARS,
  name: `Мантия звёзд`,
  nameEn: `Robe of Stars`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  description: `Эта чёрная или тёмно-синяя мантия украшена белыми или серебристыми звёздочками. Вы получаете бонус +1 ко всем испытаниям, пока носите её.

Шесть звёзд, расположенные на груди, отличаются увеличенным размером. Если Вы носите эту мантию, Вы можете действием оторвать одну из звёзд и использовать её для наложения заклинания [Волшебная стрела](SPELL:${SPELL_MAGIC_MISSILE}) как заклинания 5 уровня. Каждый день на закате на мантии заново появляются 1к6 звёзд.

Пока Вы носите эту мантию, Вы можете действием перейти на Астральный План вместе со всем, что Вы несёте и носите. Вы остаётесь там, пока не вернётесь действием на тот же самый план. Вы появляетесь в пространстве, которое занимали ранее, или ближайшем пространстве, если прошлое место занято.`,
  genderId: GENDER_FEMALE,
  spellIdList: [SPELL_MAGIC_MISSILE],
  source: {
    id: SOURCE_DMG,
    page: 181,
  },
}
