const {SOURCE_AYAGWnW} = require('./../../sourceList')
const {
  GEAR_CROSSBOW_BOLTS,
  GEAR_CROSSBOW_HAND,
  GEAR_CROSSBOW_HEAVY,
  GEAR_CROSSBOW_LIGHT,
} = require('./../../gearIdList')

const crossbowsDescription = {
  header: 'Арбалеты',
  subHeader: 'Crossbows',
  text: `Арбалеты — это простое механическое оружие, активируемое нажатием на спусковой крючок, чтобы отпустить защёлку на тетиве, посылая [болт](GEAR:${GEAR_CROSSBOW_BOLTS}) к цели. Есть три популярных типа арбалетов. 

[Ручные арбалеты](GEAR:${GEAR_CROSSBOW_HAND}) маленькие и могут использоваться одной рукой, но имеют обычный дальность около тридцати футов, и тетиву нужно вытягивать назад вручную. [Лёгкие арбалеты](GEAR:${GEAR_CROSSBOW_LIGHT}) и [тяжелые арбалеты](GEAR:${GEAR_CROSSBOW_HEAVY}) придётся удерживать двумя руками, а тетива вытягивается рукояткой или рычагом. [Лёгкие арбалеты](GEAR:${GEAR_CROSSBOW_LIGHT}) имеют дальность в восемьдесят футов, в то время как большой и неповоротливый [тяжёлый арбалет](GEAR:${GEAR_CROSSBOW_HEAVY}) бьёт на сто футов. Большинство арбалетов могут делать только один выстрел за раз, и перезарядка может занять несколько минут.`,
  source: {
    id: SOURCE_AYAGWnW,
    page: 114,
  },
}

module.exports = crossbowsDescription
