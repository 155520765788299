const listToCollectionById = require('./../utils/listToCollectionById')
const { SOURCE_PHB } = require('./sourceList')

const PARAM_ANY = 'any'
const PARAM_STR = 'str'
const PARAM_DEX = 'dex'
const PARAM_CON = 'con'
const PARAM_INT = 'int'
const PARAM_WIT = 'wis'
const PARAM_CHA = 'cha'

const paramList = [
  {
    id: PARAM_STR,
    name: 'Сила',
    shortName: 'Сил',
    description: {
      text: `Сила означает физическую силу и атлетические тренировки.

# Проверки силы

Проверки Силы могут моделировать попытки поднять, толкнуть, подтянуть или сломать что-то, попытки втиснуть своё тело в некое пространство или другие попытки применения грубой силы.

Мастер может призвать к проверке Силы, если Вы пытаетесь сделать что-то из следующего:

* Попытка открыть застрявшую, запертую или заложенную на засов дверь.
* Попытка вырваться из оков.
* Попытка протиснуться по узкому туннелю.
* Попытка удержаться за фургон, не отцепившись от него во время движения.
* Опрокидывание статуи.
* Попытка удержать валун от падения.`,
      source: {
        id: SOURCE_PHB,
        page: 176,
      },
    },
  },
  {
    id: PARAM_DEX,
    name: 'Ловкость',
    shortName: 'Лов',
    description: {
      text: `Ловкость отвечает за проворство, реакцию и равновесие.

# Проверки ловкости

Проверка Ловкости может моделировать любую попытку перемещаться ловко, быстро или тихо, либо попытку не упасть с шаткой опоры. 

Мастер может призвать к проверке Ловкости, если Вы пытаетесь сделать что-то из следующего:

* Вы спускаетесь с тяжело нагруженной тачкой по крутому склону.
* Вы закладываете крутой вираж на карете.
* Вы вскрываете замок.
* Вы обезвреживаете ловушку.
* Вы связываете пленника.
* Вы пытаетесь высвободиться из пут.
* Вы играете на струнном инструменте.
* Вы создаёте мелкий или высокодетализированный предмет.`,
      source: {
        id: SOURCE_PHB,
        page: 177,
      },
    },
  },
  {
    id: PARAM_CON,
    name: 'Телосложение',
    shortName: 'Тел',
    description: {
      text: `Телосложение измеряет ваше здоровье, выносливость и жизненные силы.

# Проверки телосложения

Проверки Телосложения совершаются не часто, и от него не зависят никакие навыки, так как выносливость, которую отражает эта характеристика, пассивна, и персонаж или чудовище не может активно её использовать. Однако проверка Телосложения может моделировать вашу попытку сделать что-то необычное.

Мастер может призвать к проверке Телосложения, если Вы пытаетесь сделать что-то из следующего:

* Вы задерживаете дыхание.
* Вы совершаете марш-бросок или несколько часов без перерыва работаете.
* Вы долго не спите.
* У вас нет еды и воды.
* Вы пытаетесь осушить кружку пива одним махом.`,
      source: {
        id: SOURCE_PHB,
        page: 178,
      },
    },
  },
  {
    id: PARAM_INT,
    name: 'Интеллект',
    shortName: 'Инт',
    description: {
      text: `Интеллект измеряет силу ума, точность воспоминаний и способность здраво рассуждать.

# Проверки интеллекта

Проверки Интеллекта происходят когда Вы используете логику, образование, память или дедуктивное мышление. Навыки Анализ, История, Магия, Природа и Религия отражают особую склонность к некоторым проверкам Интеллекта. 

Мастер может призвать к проверке Интеллекта, если Вы пытаетесь сделать что-то из следующего:

* Вы общаетесь с существом без использования слов.
* Вы оцениваете стоимость ценного предмета.
* Вы маскируетесь под городского стража.
* Вы подделываете документ.
* Вы вспоминаете знание о ремесле или торговле.
* Вы соревнуетесь с кем-то другим.`,
      source: {
        id: SOURCE_PHB,
        page: 178,
      },
    },
  },
  {
    id: PARAM_WIT,
    name: 'Мудрость',
    shortName: 'Мдр',
    description: {
      text: `Мудрость определяет, как хорошо Вы настроены на окружающий мир и отражает восприимчивость и интуицию.

# Проверки мудрости

Проверки Мудрости могут отражать попытки понять язык тела, понять чьи-то переживания, заметить что-то в окружающем мире или позаботиться о раненом. 

Мастер может призвать к проверке Мудрости, если Вы пытаетесь сделать что-то из следующего:

* Вы пытаетесь понять, что делать дальше.
* Вы пытаетесь распознать в живом или вроде бы мёртвом существе нежить.`,
      source: {
        id: SOURCE_PHB,
        page: 179,
      },
    },
  },
  {
    id: PARAM_CHA,
    name: 'Харизма',
    shortName: 'Хар',
    description: {
      text: `Харизма измеряет вашу способность эффективно взаимодействовать с другими. Она охватывает такие факторы как уверенность в себе и красноречие, и может отражать привлекательную или внушительную внешность. 

# Проверки харизмы

Проверку Харизмы можно совершать при попытке повлиять на других или развлечь их, когда Вы пытаетесь произвести впечатление или убедительно соврать, или если Вы пытаетесь разобраться в сложной социальной ситуации. 

Мастер может призвать к проверке Харизмы, если Вы пытаетесь сделать что-то из следующего:

* Вы ищете, с кем лучше всего обсудить новости, слухи и сплетни.
* Вы сливаетесь с толпой, чтобы узнать основные обсуждаемые темы.`,
      source: {
        id: SOURCE_PHB,
        page: 179,
      },
    },
  },
]

const paramAny = {
  id: PARAM_ANY,
  name: 'Любая характеристика',
  shortName: 'Любая',
  description: `Произвольная характеристика на Ваш выбор`,
  children: [
    PARAM_STR,
    PARAM_DEX,
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
    PARAM_CHA,
  ],
}

module.exports = paramList

const paramFullList = [
  paramAny,
  ...paramList,
]

module.exports.paramFullList = paramFullList

module.exports.paramIdList = paramList.map(({id}) => id)

module.exports.paramCollection = listToCollectionById(paramFullList)

module.exports.PARAM_ANY = PARAM_ANY
module.exports.PARAM_STR = PARAM_STR
module.exports.PARAM_DEX = PARAM_DEX
module.exports.PARAM_CON = PARAM_CON
module.exports.PARAM_INT = PARAM_INT
module.exports.PARAM_WIT = PARAM_WIT
module.exports.PARAM_CHA = PARAM_CHA
