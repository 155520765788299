const {
  GOD_THRYM,
  GOD_VAPRAK,
} = require('./../../godIdList')
const {
  CREATURE_FROST_GIANT,
  CREATURE_FROST_GIANT_EVERLASTING_ONE,
  CREATURE_OGRE,
  CREATURE_TROLL,
} = require('./../../creatureIdList')
const {SOURCE_VGTM} = require('./../../sourceList')

module.exports = [
  {
    header: 'Извечный ледяной великан',
    text: `Чтобы поддерживать своё место или подниматься вверх по Уложению, [ледяной великан](CREATURE:${CREATURE_FROST_GIANT}) должен постоянно сталкиваться с могучими врагами в дуэли один на один. Некоторые ищут магию, которая может помочь, но зачарованные объекты можно украсть или потерять. Истинное величие лежит в личной доблести. Столкнувшись с этой правдой, [ледяной великан](CREATURE:${CREATURE_FROST_GIANT}) может захотеть сверхъестественный дар от [Вапрака Уничтожителя](GOD:${GOD_VAPRAK}).`,
    source: {
      id: SOURCE_VGTM,
      page: 134,
    },
  },
  {
    header: 'Пожиратель троллей',
    text: `Ледяные великаны обращаются к [Вапраку](GOD:${GOD_VAPRAK}), хищному богу силы и голода, которому поклоняются [огры](CREATURE:${CREATURE_OGRE}) и [тролли](CREATURE:${CREATURE_TROLL}), в основном из отчаяния. Вапраку нравится искушать ледяных великанов снами славы, за которыми следуют кошмары кровавого каннибализма. Те, кто не избегает таких видений или не докладывает о них жрецам [Трима](GOD:${GOD_THRYM}), начинают видеть их чаще. Если ледяной великан начинает наслаждаться этими снами и кошмарами, то Вапрак посылает [тролля](CREATURE:${CREATURE_TROLL}) на священное задание отыскать ледяного великана и втайне встретиться с ним. [Тролль](CREATURE:${CREATURE_TROLL}) предлагает великану съесть своё собственное тело во имя [Вапрака](GOD:${GOD_VAPRAK}). Только самые смелые и целеустремленные ледяные великаны могут завершить эту кровавую трапезу.`,
    source: {
      id: SOURCE_VGTM,
      page: 134,
    },
  },
  {
    header: 'Благословение Вапрака',
    text: `После поедания [тролля](CREATURE:${CREATURE_TROLL}), присланного [Вапраком](GOD:${GOD_VAPRAK}), целиком и полностью, ледяной великан становится [извечным](CREATURE:${CREATURE_FROST_GIANT_EVERLASTING_ONE}), получает потрясающую силу, злобный характер и регенеративные способности [тролля](CREATURE:${CREATURE_TROLL}). С такими дарами ледяной великан может быстро заполучить титул ярла и легко давать отпор соперникам десятилетиями. Однако, если ледяной великан недостаточно чтит [Вапрака](GOD:${GOD_VAPRAK}) или не уделяет внимания его видениям, то ранения ледяного великана плохо заживают, часто оставляя кожу другого цвета, шрамы, покрытые наростами, рудиментарные части тела, такие как дополнительные пальцы, конечности и даже дополнительные головы. После этого касание [Вапрака](GOD:${GOD_VAPRAK}) скрыть невозможно и [извечного](CREATURE:${CREATURE_FROST_GIANT_EVERLASTING_ONE}) убивают или изгоняют из клана. Иногда небольшие сообщества [извечных](CREATURE:${CREATURE_FROST_GIANT_EVERLASTING_ONE}) собираются и даже размножаются, передавая «благословение» и молитвы [Вапраку](GOD:${GOD_VAPRAK}) от одного поколения к другому.`,
    source: {
      id: SOURCE_VGTM,
      page: 134,
    },
  },
]
