const {ALPHABET_ESPRUAR} = require('./../../alphabetList')
const {LANG_TYPE_EXOTIC} = require('./../../languageTypeList')
const {LANG_UNDERCOMMON} = require('./../../languageIdList')
const {PLACE_UNDERDARK} = require('./../../placeIdList')
const {SOURCE_MGZN_DRGN_281} = require('./../../sourceList')

module.exports = {
  id: LANG_UNDERCOMMON,
  alphabetId: ALPHABET_ESPRUAR,
  type: LANG_TYPE_EXOTIC,
  name: {
    nominative: 'Подземный',
    genitive: 'Подземного',
    instrumental: 'Подземным',
    prepositional: 'Подземном',
  },
  nameEn: 'Undercommon',
  typicalSpeakers: 'Купцы Подземья',
  spokenPlaceList: {
    id: PLACE_UNDERDARK,
    comment: '(торговый язык)',
  },
  dictionary: {
    list: [
      ['благородный', 'ули'],
      ['боль', 'залли'],
      ['бояться', 'лугри'],
      ['великий', 'сайн'],
      ['владыка', 'босск'],
      ['выродок', 'хун'],
      ['голем', 'орделл'],
      ['гуманоид', 'орделл'],
      ['дворянин', 'ули'],
      ['дегустатор', 'аринт'],
      ['живодёр', 'тид'],
      ['завоеватель', 'телид'],
      ['закон', 'маанзе'],
      ['кредо', 'маанзе'],
      ['лидер', 'кориан'],
      ['лорд', 'босск'],
      ['магия', 'малин'],
      ['могучий', 'сайн'],
      ['мозг', 'ильсен'],
      ['мудрец', 'миос'],
      ['мысль', 'уллип'],
      ['мёртвый', 'аль'],
      ['омерзение', 'залли'],
      ['отвращение', 'залли'],
      ['отродье', 'хун'],
      ['память', 'абстэр'],
      ['повелитель', 'босск'],
      ['поглотитель', 'тарид'],
      ['поглощать', 'тарид'],
      ['поедатель', 'телид'],
      ['пожиратель разума', 'орделл'],
      ['пожиратель', 'тид'],
      ['почитаемый', 'атор'],
      ['почитаемый', 'тор'],
      ['правитель', 'тид'],
      ['псионика', 'малин'],
      ['раб', 'уроп'],
      ['разум', 'илли'],
      ['связывать', 'кориан'],
      ['слуга', 'уроп'],
      ['смерть', 'ал'],
      ['старейшина', 'атор'],
      ['старейшина', 'тор'],
      ['страх', 'лугри'],
      ['темнота', 'хион'],
      ['тёмный', 'хион'],
      ['учёный', 'миос'],
      ['хозяин', 'босск'],
      ['ценитель', 'аринт'],
      ['честь', 'кваз'],
      ['чужак', 'хун'],
      ['чёрный', 'хион'],
    ],
    source: {
      id: SOURCE_MGZN_DRGN_281,
      page: 48,
    },
  },
  isRealLang: true,
  isReady: true,
}
