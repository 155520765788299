const {
  SOURCE_LOR_DTOB,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
  SOURCE_UA_2020_09_15_TCoE_PC_BPoWM,
  SOURCE_UA_2019_08_15_WILD_ASTRAL,
  SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
  SOURCE_UA_2022_05_22_GIANT_OPTIONS,
  SOURCE_XGTE,
} = require('./../../sourceList')

const {
  PC_CLASS_BARBARIAN,
  PC_CLASS_DRUID,
  PC_CLASS_RANGER,
} = require('./../../pcClassIdList')

const {GOD_TEMPUS} = require('./../../godIdList')
const {SPELL_SPEAK_WITH_PLANTS} = require('./../../spellIdList')
const {GEAR_SPIKED_ARMOR} = require('./../../gearIdList')
const {
  FEATURE_ASPECT_OF_THE_BEAST,
  FEATURE_SPIRIT_WALKER,
  FEATURE_TOTEMIC_ATTUNEMENT,
} = require('./../../featureIdList')

const {
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ANCESTRAL_GUARDIAN,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ANCESTRAL_GUARDIAN_2017_05_01,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BATTLERAGER,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST_2020_01_14,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BERSERKER,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_DEPTHS,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_GIANT_2022_05_22,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_STORM_HERALD,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_TOTEM_WARRIOR,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_WILD_SOUL_2019_08_15,
  PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC,
  PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC_TCoE_PC_BPoWM,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ZEALOT,
} = require('./../../pcSubClassIdList')

module.exports = [
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ANCESTRAL_GUARDIAN,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: true,
    name: 'Путь предка-хранителя',
    nameEn: 'Path of the Ancestral Guardian',
    description: `Некоторые варвары происходят из культур, где почитают своих предков. Эти племена учат своих воинов, что ушедшие остаются в этом мире в качестве могучих духов, которые могут направлять и защищать живущих. Когда варвары, следующие этому пути, впадают в ярость, они пересекают барьер между нашим миром и миром духов и взывают к таким духам-хранителям за помощью.

Варвары, которые призывают своих предков-хранителей, могут лучше защитить своё племя и своих союзников. Чтобы укрепить связь со своими стражами предками, варвары, которые следуют этому пути, покрывают себя детально проработанными татуировками, которые отмечают дела их предков. Эти татуировки рассказывают саги о победах над ужасными монстрами или другими страшными противниками.`,
    note: {
      text: `Значит предки — это люди, которые занимались деторождением для производства других людей до твоего рождения? Сколько примерно людей? Что-то многовато деторождения. Фу. Вы отвратительны.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 17,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ANCESTRAL_GUARDIAN_2017_05_01,
    releasedAs: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ANCESTRAL_GUARDIAN,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: false,
    name: 'Путь предка-хранителя',
    nameEn: 'Path of the Ancestral Guardian',
    source: {
      id: SOURCE_UA_2017_05_01_REVISED_SUBCLASSES,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BATTLERAGER,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: true,
    name: 'Путь бушующего в бою',
    nameEn: 'Path of the Battlerager',
    description: [
      `Называемые на дварфийском языке _«Калджарх»_ (буквально _«идиоты топора»_), бушующие в бою — это дварфийские последователи богов войны, вставшие на Путь бушующего в Бою.

Они специализируются в ношении громоздких [шипованных доспехов](GEAR:${GEAR_SPIKED_ARMOR}), бросаются в бой, используя тело как оружие, и всецело отдаваясь ярости битвы.`,
      {
        header: 'Ограничение: Только для дварфов',
        text: `Только дварфы могут следовать по Пути бушующего в бою. Бушующие в бою занимают определенную нишу в культуре и обществе дварфов.

Ваш Мастер может снять это ограничение, если посчитает что так будет лучше для его кампании. Это ограничение существует только для сеттинга Забытых Королевств и может не применяться в сеттинге вашего Мастера или его версии Забытых Королевств.`,
        source: {
          id: SOURCE_SCAG,
          page: 122,
        },
      },
    ],
    source: {
      id: SOURCE_SCAG,
      page: 122,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: true,
    name: 'Путь зверя',
    nameEn: 'Path of the Beast',
    description: [
      `Варвары, идущие по Пути зверя, черпают ярость из звериной искры, горящей в глубине их душ. Этот зверь вырывается наружу через их ярость, что физически изменяет самого варвара.

Такой варвар может быть одержим первобытным духом или же иметь предков-оборотней. Вы можете выбрать происхождение своего звериного начала или определить его случайно по таблице «Происхождение зверя».`,
      {
        header: 'Происхождение зверя',
        text: `
| к4 | Происхождение |
|----|---------------|
| 1 | Один из Ваших родителей — ликантроп, и Вы унаследовали часть этого проклятия. |
| 2 | Вы — потомок архидруида и частично унаследовали его способность изменять свой облик. |
| 3 | Дух фей даровал Вам способность принимать различные звериные аспекты. |
| 4 | Внутри Вас обитает дух древнего животного, направляющий Вас идти этим путём. |
`,
        source: {
          id: SOURCE_TCoE,
          page: 30,
        },
      },
    ],
    note: {
      text: `Стоит уважать любого, кто выпускает своего внутреннего зверю на лёгкую прогулку со здоровым глотковырыванием`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 29,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST_2020_01_14,
    pcClassId: PC_CLASS_BARBARIAN,
    releasedAs: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST,
    isReady: false,
    name: 'Путь зверя',
    nameEn: 'Path of the Beast',
    source: {
      id: SOURCE_UA_2020_01_14_SUBCLASSES_PART_1,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BERSERKER,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: true,
    name: 'Путь берсерка',
    nameEn: 'Path of the Berserker',
    description: `Для некоторых варваров ярость это способ достижения цели, и этой целью является насилие.

Путь берсерка это путь, залитый кровью, путь несдерживаемой ярости. Впадая в ярость берсерка, Вы бросаете себя в хаос боя, не заботясь более о собственном здоровье.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 50,
      },
      {
        id: SOURCE_SRD,
        page: 9,
      },
    ],
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_STORM_HERALD,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: true,
    name: 'Путь буревестника',
    nameEn: 'Path of the Storm Herald',
    description: `Все варвары прячут свою ярость внутри. Их гнев дает им их превосходную силу, выносливость и скорость.

Варвары, следующие по Пути Буревестника, учатся трансформировать эту ярость в покров первобытной магии, который кружится вокруг них. Будучи в ярости, варвары этого пути используют силы природы для создания могущественных магических эффектов.

Буревестники — это обычно элитные чемпионы, тренирующиеся вместе с [друидами](PC_CLASS:${PC_CLASS_DRUID}), [следопытами](PC_CLASS:${PC_CLASS_RANGER}) и другими поклявшимися защищать природу. Другие буревестники оттачивают сво~ мастерство в областях, сокрушаемых бурями, на ледяных вершинах на краю света или в глубинах самых горячих пустынь.`,
    note: {
      text: `Ты знаешь одно из главных преимуществ жизни под землёй? Никакой погоды. Не порти это.`,
      author: `Занатар`,
    },
    source: {
      id: SOURCE_XGTE,
      page: 16,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_TOTEM_WARRIOR,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: true,
    name: 'Путь тотемного воина',
    nameEn: 'Path of the Totem Warrior',
    description: [
      `Путь тотемного воина — духовный путь, в котором дух зверя становится для варвара наставником, защитником и вдохновителем. В бою дух тотема дарует Вам сверхъестественную мощь, наполняя Вашу ярость магической силой.

Большинство варварских племён связывают свои кланы с конкретными духами-покровителями, и иметь несколько тотемных духов считается необычным, хотя такие исключения и случаются.`,
      {
        header: `Тотемы Утгардта`,
        text: `Тотемы утгардтских варваров Севера соотносятся с духами Пути тотемного воина по следующей таблице

| Тотем | Дух |
|-|-|
| Чёрный лев | Тигр |
| Чёрный ворон | Орёл |
| Синий медведь | Медведь |
| Серый волк | Волк |
| Великий змей | Волк |
| Грифон | Орёл |
| Красный Тигр | Тигр |
| Небесный пони | Орёл с [Аспектом зверя](FEATURE:${FEATURE_ASPECT_OF_THE_BEAST}) Лося |
| Громозверь | Медведь, с [Гармонией тотема](FEATURE:${FEATURE_TOTEMIC_ATTUNEMENT}) Тигра |
| Призрачное древо | Медведь, с [Разговором с растениями](SPELL:${SPELL_SPEAK_WITH_PLANTS}) вместо обычных ритуалов, положенных по способности [Гуляющий с духами](FEATURE:${FEATURE_SPIRIT_WALKER}). |

`,
        source: {
          id: SOURCE_SCAG,
          page: 123,
        },
      },
    ],
    source: [
      {
        id: SOURCE_PHB,
        page: 51,
      },
      {
        id: SOURCE_SCAG,
        page: 122,
      },
    ],
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_WILD_SOUL_2019_08_15,
    pcClassId: PC_CLASS_BARBARIAN,
    releasedAs: PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC,
    isReady: false,
    name: 'Путь дикой души',
    nameEn: 'Path of the Wild Soul',
    source: {
      id: SOURCE_UA_2019_08_15_WILD_ASTRAL,
      page: 1,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_ZEALOT,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: true,
    name: 'Путь фанатика',
    nameEn: 'Path of the Zealot',
    description: `Некоторые божества вдохновляют своих последователей вводить себя в свирепый боевой раж. Такие варвары — фанатики, воины, которые преобразуют свой гнев в могущественную демонстрацию божественной мощи

Множество богов в мирах D&D вдохновляют своих последователей принимать этот путь. [Темпус](GOD:${GOD_TEMPUS}) из Забытых Королевств или Гектор и Эритнул в Грейхоуке — отличные примеры. В целом, боги, которые вдохновляют фанатиков, — это боги войны, разрушения и насилия. Не все они злые, но мало кто из них добр.`,
    source: {
      id: SOURCE_XGTE,
      page: 18,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_DEPTHS,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: false,
    name: 'Путь Глубин',
    nameEn: 'Path of the Depths',
    source: {
      id: SOURCE_LOR_DTOB,
      url: 'https://www.dndbeyond.com/sources/lrdtob/heroes-and-scoundrels-of-bilgewater#BarbarianPathoftheDepths',
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: true,
    name: 'Путь дикой магии',
    nameEn: 'Path of Wild Magic',
    description: `Многие места в мультивселенной изобилуют красотой, сильными эмоциями и необузданной магией. Страна Фей, Верхние Планы и другие царства сверхъестественной мощи излучают такие силы и могут глубоко влиять на людей.

Варварские народы глубоко чувствуют окружающую природу, и потому особенно восприимчивы к этим диким влияниям, а некоторые варвары даже преображаются с помощью этой магии. Эти наполненные магией варвары идут по Пути Дикой Магии.

Варвары-эльфы, тифлинги, аазимары и генази часто ищут этот путь, стремясь проявить потустороннюю магию своих предков.`,
    note: {
      text: `Я бы не рекомендовала позволять магии перехватывать поводья, но я не Ваша мама. Живите со вкусом.`,
      author: `Таша`,
    },
    source: {
      id: SOURCE_TCoE,
      page: 31,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC_TCoE_PC_BPoWM,
    pcClassId: PC_CLASS_BARBARIAN,
    releasedAs: PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC,
    isReady: true,
    name: 'Путь дикой магии',
    nameEn: 'Path of Wild Magic',
    description: `Многие места в мультивселенной изобилуют красотой, сильными эмоциями и необузданной магией. Страна Фей, Верхние Планы и другие царства сверхъестественной мощи излучают такие силы и могут глубоко влиять на людей.

Варварские народы глубоко чувствуют окружающую природу, и потому особенно восприимчивы к этим диким влияниям, а некоторые варвары даже преображаются с помощью этой магии. Эти наполненные магией варвары идут по Пути Дикой Магии.

Варвары-эльфы, тифлинги, аазимары и генази часто ищут этот путь, стремясь проявить потустороннюю магию своих предков.`,
    source: {
      id: SOURCE_UA_2020_09_15_TCoE_PC_BPoWM,
    },
  },
  {
    id: PC_SUBCLASS_BARBARIAN_PATH_OF_THE_GIANT_2022_05_22,
    pcClassId: PC_CLASS_BARBARIAN,
    isReady: false,
    name: 'Путь великана',
    nameEn: 'Path of the Giant',
    description: `Barbarians who walk the Path of the Giant draw strength from the primal forces that are the Giants and their elemental ilk. Their rages surge with elemental power and cause these barbarians to grow in size, transforming them into avatars of primordial might.

During their rage, a barbarian may take after a legendary storm giant, hair turning white and eyes crackling with lightning. Others adopt the monstrous features of apocalyptic titans, rending the world around them so that it may be made anew.`,
    source: {
      id: SOURCE_UA_2022_05_22_GIANT_OPTIONS,
    },
  },
]
