const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  LANG_COMMON,
  LANG_DRUIDIC,
  LANG_ELVEN,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  DAMAGE_BLUDGEONING,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
} = require('./../../../../damageTypeList')
const {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  ABILITY_FALSE_APPEARANCE,
  ABILITY_SIEGE_MONSTER,
} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_CG} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CR_9} = require('./../../../../crList')
const {CREATURE_TREANT} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_PLANT} = require('./../../../../creatureTypeIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: `Трент`,
  nameEn: 'Treant',
  id: CREATURE_TREANT,
  description: [
    `Тренты — пробудившиеся деревья, живущие в древних лесах. Они предпочитают проводить дни, недели и годы в тихих раздумьях, но яростно обороняют свои лесные владения от внешних угроз.`,
    {
      header: 'Спящее дерево пробуждается',
      text: `Дерево, которому суждено стать трентом, в течение многих сезонов находится в медитативном состоянии, живя десятки или даже сотни лет, и лишь потом осознавая свой потенциал. Деревья пробуждаются только при особых обстоятельствах, и только в насыщенных природной магией местах. Тренты и могущественные друиды чувствуют, когда у дерева есть искра потенциала для пробуждения, и защищают такие деревья в потаённых рощах, пока момент пробуждения не наступит. В течение длинного процесса пробуждения на коре дерева проступают черты лица, нижняя часть ствола раздваивается, формируя ноги, а длинные ветви сгибаются вниз, становясь руками. Когда дерево готово, оно высвобождает свои ноги из земли и присоединяется к товарищам в охране своего лесного дома.`,
      source: {
        id: SOURCE_MM,
        page: 283,
      },
    },
    {
      header: 'Легендарные стражи',
      text: `Легендарные стражиПосле пробуждения трент продолжает расти точно так же, как когда он был обычным деревом. Пробудившиеся из самых крупных деревьев тренты могут достигать неимоверных размеров и развивать внутренние магические способности по управлению растениями и животными. Такие тренты могут оживлять растения, используя их как ловушки и силки против незваных гостей. Они могут призывать диких животных как помощников, или чтобы те переносили сообщения на дальние расстояния.`,
      source: {
        id: SOURCE_MM,
        page: 283,
      },
    },
    {
      header: 'Защитники дикой природы',
      text: `Даже после пробуждения тренты проводят значительную часть времени как обычные деревья. Укоренившийся в одном месте трент по-прежнему остаётся в курсе того, что его окружает, и может воспринимать эффекты происходящих на расстоянии многих миль от него событий, исходя из незначительных изменений окружающей трента местности.

Дровосеки, которые стараются не рубить здоровые живые деревья, и охотники, получающие от леса только необходимое, вряд ли вызовут гнев трента. А вот беспечно обращающиеся с огнём существа, те, кто отравляют лес, и кто уничтожает большие деревья, особенно если те близки к пробуждению, почувствуют на себе ярость трента.`,
      source: {
        id: SOURCE_MM,
        page: 283,
      },
    },
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_PLANT,
  ],
  alignmentId: ALIGNMENT_CG,
  source: {
    id: SOURCE_MM,
    page: 283,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 12,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 8,
    [PARAM_CON]: 21,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 16,
    [PARAM_CHA]: 12,
  },
  resistanceList: [
    DAMAGE_BLUDGEONING,
    DAMAGE_PIERCING,
  ],
  vulnerabilityList: [
    DAMAGE_FIRE,
  ],
  languageList: [
    LANG_DRUIDIC,
    LANG_COMMON,
    LANG_SYLVAN,
    LANG_ELVEN,
  ],
  cr: CR_9,
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычного дерева`,
    },
    ABILITY_SIEGE_MONSTER,
  ],
  actionList: [
    {
      name: `Мультиатака`,
      description: `★СУЩЕСТВО★ совершает два _Удара_.`,
    },
    {
      name: `Удар`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 5,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 3,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
    {
      name: `Камень`,
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 10,
        range: {
          normal: 60,
          max: 180,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 4,
          diceType: 10,
          diceBonus: 6,
        },
      },
    },
    {
      name: `Оживление деревьев`,
      limit: {
        count: 1,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ магическим образом оживляет до двух деревьев, которые видит в пределах 60 футов от себя. Деревья обладают статистикой трента, за исключением того, что у них Интеллект 1 и Харизма 1, они не могут говорить, и у них только один вариант действия — Удар. Оживлённые деревья действуют как союзники ★СУЩЕСТВО★. Дерево перестаёт быть живым через 1 день, когда умирает, когда ★СУЩЕСТВО★ умирает или отдаляется от него более чем на 120 футов, а также когда ★СУЩЕСТВО★ бонусным действием снова делает его неживым. Став снова неживым, дерево, при возможности, пускает корни.`,
    },
  ],
  genderId: GENDER_MALE,
}
