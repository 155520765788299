const {GENDER_MALE} = require('./../../../../../genderList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../../magicItemTypeList')
const {MAGIC_ITEM_VORPAL_SWORD} = require('./../../../../../magicItemIdList')
const {SOURCE_DMG} = require('./../../../../../sourceList')

const siblingIdList = require('./siblingIdList')

module.exports = {
  id: MAGIC_ITEM_VORPAL_SWORD,
  name: `Меч головоруб`,
  nameEn: `Vorpal Sword`,
  type: MGC_TYPE_WEAPON,
  typeComment: `любой меч, причиняющий рубящий урон`,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: `Вы получаете бонус +3 к броскам атаки и урона этим магическим оружием. Кроме того, это оружие игнорирует сопротивление к рубящему урону.

Если Вы атакуете этим оружием существо, у которого есть как минимум одна голова, и при броске атаки у Вас выпадает «20», Вы отрубаете одну из голов чудовища. Существо умирает, если не может жить без головы. Существо обладает иммунитетом к этому эффекту, если обладает иммунитетом к рубящему урону, не имеет головы или не нуждается в ней, обладает легендарными действиями или Мастер решает, что существо слишком большое, чтобы Вы могли отрубить ему голову этим оружием. Такие существа вместо этого получают рубящий урон 6к8.`,
  genderId: GENDER_MALE,
  childIdList: siblingIdList,
  source: {
    id: SOURCE_DMG,
    page: 184,
  },
}
