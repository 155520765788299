const {CREATURE_EARTH_ELEMENTAL} = require('./../../../../creatureIdList')
const {GEAR_WAR_PICK} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {LANG_TERRAN} = require('./../../../../languageIdList')
const {MGC_RARITY_LEGENDARY} = require('./../../../../magicItemRarityList')
const {MGC_TYPE_WEAPON} = require('./../../../../magicItemTypeList')
const {SOURCE_PotA} = require('./../../../../sourceList')
const {
  SPELL_DOMINATE_MONSTER,
  SPELL_SHATTER,
} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_DEVASTATION_ORB_EARTH,
  MAGIC_ITEM_IRONFANG,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_IRONFANG,
  name: 'Железный клык',
  nameEn: 'Ironfang',
  type: MGC_TYPE_WEAPON,
  gearType: GEAR_WAR_PICK,
  rarity: MGC_RARITY_LEGENDARY,
  attunement: true,
  description: [
    {
      header: 'Железный клык',
      text: `Сделанная из цельного куска железа, боевая кирка _Железный клык_ обладает похожим на длинный клюв остриём, покрытым древними рунами. Сперва кирка кажется тяжёлой, но когда её владелец начинает в ярости размахивать ей, то она становится почти невесомой. Это оружие обладает иммунитетом к коррозии, неуязвимо к воздействию кислоты — ничто не может испортить его, поскольку Железный Клык несёт в себе часть силы Огремоха, Принца Злой Земли.

Вы получаете +2 к броскам атаки и урона этим магическим оружием. При попадании цель получает дополнительный урон звуком 1к8.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Власть над землёй',
      text: `Если Вы вооружены _Железным клыком_, то получаете следующие преимущества:
      
* Вы свободно говорите на [Терране](LANG:${LANG_TERRAN}).
* Вы обладаете сопротивлением урону кислотой.
* Вы получаете чувство вибрации в радиусе 60 футов.
* Вы чувствуете залежи драгоценных камней и металлов в пределах 60 футов, но не знаете их чёткого местоположения.
* Вы можете сотворить заклинание [Подчинение чудовища](SPELL:${SPELL_DOMINATE_MONSTER}) (СЛ 17) на [земляного элементаля](CREATURE:${CREATURE_EARTH_ELEMENTAL}). Если Вы сотворили это заклинание, то не сможете вновь использовать данную особенность до следующего рассвета.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Дребезги',
      text: `_Железный клык_ содержит 3 заряда. Вы можете действием потратить один заряд и сотворить заклинание [Дребезги](SPELL:${SPELL_SHATTER}) (СЛ 17). Считается, что заклинание тратит ячейку 2 уровня. _Железный клык_ восстанавливает 1к3 заряда ежедневно на рассвете.`,
      source: {
        id: SOURCE_PotA,
        page: 229,
      },
    },
    {
      header: 'Грохот',
      text: `Находясь в стихийном узле земли, Вы, с помощью _Железного клыка_, можете совершить ритуал под названием «Грохот», который позволит Вам создать [земляную сферу опустошения](MAGIC_ITEM:${MAGIC_ITEM_DEVASTATION_ORB_EARTH}). После окончания ритуала, Вы не сможете повторить его до следующего рассвета.`,
      source: {
        id: SOURCE_PotA,
        page: 230,
      },
    },
    {
      header: 'Слабость',
      text: `_Железный Клык_ пробуждает разрушительную природу своего владельца. Пока существо настроено на это оружие, оно получает следующую слабость: _«Я люблю ломать и разрушать всё вокруг»_.`,
      source: {
        id: SOURCE_PotA,
        page: 230,
      },
    },
  ],
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PotA,
    page: 229,
  },
}
