const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_GRICK,
  CREATURE_GRICK_ALPHA,
} = require('./../../creatureIdList')

module.exports = [
  {
    header: 'Грик',
    text: `Похожий на червя [грик](CREATURE:${CREATURE_GRICK}) остаётся невидимым, сливаясь с камнями пещер и туннелей, в которых обитает. Лишь при приближении жертвы он вскакивает, а четыре его колючих щупальца открывают голодный щёлкающий клюв.`,
    source: {
      id: SOURCE_MM,
      page: 75,
    },
  },
  {
    header: 'Пассивные хищники',
    text: `Грики редко охотятся. Вместо этого их эластичные туши перемещаются в места, где постоянно проходят существа. Они скрываются среди каменных валунов и обломков, просачиваются в норы, отверстия и щели, взбираются на уступы или оборачиваются вокруг сталактитов, чтобы свалиться на неосмотрительную жертву. Грик поглощает практически что угодно, за исключением других гриков. Его целью является ближайшая жертва, повалив которую он хватает своими щупальцами и уносит, чтобы поесть в одиночестве.`,
    source: {
      id: SOURCE_MM,
      page: 75,
    },
  },
  {
    header: 'Бродячие налётчики',
    text: `Грики не меняют зону обитания, пока есть доступ к пище, который обычно прекращается, когда разумные существа узнают об их присутствии и составляют планы новых маршрутов в обход логова. Когда в Подземье жертв становится недостаточно, грики поднимаются на поверхность и охотятся в диких землях, взбираясь на деревья или скалистые уступы. Стаю гриков чаще всего возглавляет крупный откормленный [альфа-самец](CREATURE:${CREATURE_GRICK_ALPHA}), вокруг которого собираются остальные.`,
    source: {
      id: SOURCE_MM,
      page: 75,
    },
  },
  {
    header: 'Трофеи убийств',
    text: `Со временем в логовах гриков скапливаются ненужное имущество их разумных жертв, и умелые проводники могут это определить по явным признакам. Исследователи Подземья иногда запечатывают входы и выходы из логова гриков, моря их голодом, а затем забирая себе богатства глупых жертв.`,
    source: {
      id: SOURCE_MM,
      page: 75,
    },
  },
]
