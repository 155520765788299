const {CONDITION_BLINDED} = require('./../../../../../conditionList')
const {DAMAGE_RADIANT} = require('./../../../../../damageTypeList')
const {MAGIC_EVOCATION} = require('./../../../../../magicList')
const {PARAM_CON} = require('./../../../../../paramList')
const {SPELL_SUNBURST} = require('./../../../../../spellIdList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')

module.exports = {
  id: SPELL_SUNBURST,
  name: 'Солнечный ожог',
  nameEn: `Sunburst`,
  description: `Яркий солнечный свет освещает всё в 60-футовом радиусе с центром на точке, выбранной Вами в пределах дистанции. Все существа, находящиеся в нём, должны пройти испытание Телосложения. При провале существо получает урон излучением 12к6 и становится ослеплённым на 1 минуту. При успехе оно получает половину урона и не становится ослеплённым. Нежить и слизи проходят это испытание с помехой.

Существо, ослеплённое этим заклинанием, совершает повторные испытания Телосложения в конце каждого своего хода. При успехе оно перестаёт быть ослеплённым.

Это заклинание рассеивает любую тьму в своей области, созданную заклинанием.`,
  lvl: 8,
  magicSchoolId: MAGIC_EVOCATION,
  range: 150,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  materialText: `огонь и кусочек солнечного камня`,
  note: {
    text: `«Материальные компоненты: „огонь“»… Я бы посмотрел, как его достают из мешочка с компонентами. Особенно — под водой.`,
    author: `Эспумизан Коликит, гоблин волшебник`,
  },
  effect: {
    savethrowParam: PARAM_CON,
    condition: CONDITION_BLINDED,
    damage: {
      type: DAMAGE_RADIANT,
      halfOnSuccess: true,
      diceCount: 12,
      diceType: 6,
    },
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 279,
    },
    {
      id: SOURCE_SRD,
      page: 184,
    },
  ],
}
