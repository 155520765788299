const {halfCasterSubClassSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_PALADIN_OATH_OF_REDEMPTION} = require('./../../../pcSubClassIdList')
const {SOURCE_XGTE} = require('./../../../sourceList')
const {
  SPELL_CALM_EMOTIONS,
  SPELL_COUNTERSPELL,
  SPELL_HOLD_MONSTER,
  SPELL_HOLD_PERSON,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_OTILUKE_S_RESILIENT_SPHERE,
  SPELL_SANCTUARY,
  SPELL_SLEEP,
  SPELL_STONESKIN,
  SPELL_WALL_OF_FORCE,
} = require('./../../../spellIdList')
const {
  FEATURE_AURA_OF_THE_GUARDIAN,
  FEATURE_CHANNEL_DIVINITY_EMISSARY_OF_PEACE,
  FEATURE_CHANNEL_DIVINITY_PALADIN,
  FEATURE_CHANNEL_DIVINITY_REBUKE_THE_VIOLENT,
  FEATURE_EMISSARY_OF_REDEMPTION,
  FEATURE_OATH_OF_REDEMPTION_SPELLS,
  FEATURE_PROTECTIVE_SPIRIT,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_OATH_OF_REDEMPTION_SPELLS,
    name: `Заклинания клятвы`,
    nameEn: `Oath Spells`,
    lvl: 3,
    text: `Вы получаете заклинания клятвы на указанных уровнях паладина.`,
    spellCircleLvl: halfCasterSubClassSpellCircleList,
    spellIdList: [
      SPELL_CALM_EMOTIONS,
      SPELL_COUNTERSPELL,
      SPELL_HOLD_MONSTER,
      SPELL_HOLD_PERSON,
      SPELL_HYPNOTIC_PATTERN,
      SPELL_OTILUKE_S_RESILIENT_SPHERE,
      SPELL_SANCTUARY,
      SPELL_SLEEP,
      SPELL_STONESKIN,
      SPELL_WALL_OF_FORCE,
    ],
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_EMISSARY_OF_PEACE,
    name: `Божественный канал: Посол мира`,
    nameEn: `Channel Divinity: Emissary of Peace`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), чтобы наделить своё присутствие божественной силой.

Бонусным действием Вы даруете себе бонус +5 к проверкам Харизмы (Убеждение) на следующие 10 минут.`,
  },
  {
    id: FEATURE_CHANNEL_DIVINITY_REBUKE_THE_VIOLENT,
    name: `Божественный канал: Возмездие жестокости`,
    nameEn: `Channel Divinity: Rebuke the Violent`,
    lvl: 3,
    text: `Вы можете использовать [Божественный канал](FEATURE:${FEATURE_CHANNEL_DIVINITY_PALADIN}), чтобы дать отпор жестоким врагам.

Сразу же после того, как атакующий не далее 30 футов от Вас наносит урон атакой существу, отличному от Вас, Вы можете использовать реакцию, чтобы заставить атакующего пройти испытание Мудрости.

При провале атакующий получает столько урона излучением, сколько он нанес урона. При успешном испытание он получает половину этого урона.`,
  },
  {
    id: FEATURE_AURA_OF_THE_GUARDIAN,
    name: `Аура стража`,
    nameEn: `Aura of the Guardian`,
    lvl: [7, 18],
    text: `Вы можете прикрыть других от вреда ценой собственного здравия. Когда существо в 10 футах от Вас получает урон, Вы можете использовать свою реакцию, чтобы волшебным образом получить этот урон вместо него. Эта черта не переносит никакие другие эффекты, которые могут сопровождать урон и его невозможно уменьшить никаким способом.

На 18 уровне область этой ауры увеличивается до 30 футов.`,
  },
  {
    id: FEATURE_PROTECTIVE_SPIRIT,
    name: `Дух защитник`,
    nameEn: `Protective Spirit`,
    lvl: 15,
    text: `Святой дух лечит Ваши раны в бою. Вы восполняете хиты в размере 1к6 + половина Вашего уровня паладина, если в конце Вашего хода в бою у Вас менее половины Ваших хитов и Вы не недееспособны.`,
  },
  {
    id: FEATURE_EMISSARY_OF_REDEMPTION,
    name: `Эмиссар искупления`,
    nameEn: `Emissary of Redemption`,
    lvl: 20,
    text: `Вы становитесь аватаром мира, что даёт Вам два преимущества:

* Вы получаете сопротивление всему урону, который наносят другие существа (атаками, заклинаниями и другими эффектами)
* Когда существо попадает по Вам атакой, оно получает урон излучением, равный половине урона, который Вы получите от атаки.

Если Вы атакуете существо, творите заклинание на него или наносите урон ему каким-либо другим образом, кроме как при помощи этой черты, ни одно из преимуществ не работает против этого существа, пока Вы не завершите длительный отдых.`,
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_PALADIN_OATH_OF_REDEMPTION,
    source: {
      id: SOURCE_XGTE,
      page: 48,
    },
  })
)

