const {
  SPELL_BLUR,
  SPELL_JUMP,
  SPELL_MAGE_HAND,
  SPELL_MISTY_STEP,
  SPELL_NONDETECTION,
  SPELL_PLANE_SHIFT,
  SPELL_TELEKINESIS,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_GITH,
  CREATURE_TYPE_GITHYANKI,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  GEAR_GREATSWORD,
  GEAR_PLATE_ARMOR,
} = require('./../../../../gearIdList')
const {
  githyankiDescription,
  githyankiNote,
} = require('./../../../../textCommonParts')
const {
  CREATURE_GITHYANKI_KITHRAK,
  CREATURE_GITHYANKI_KNIGHT,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_LE} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_NONE} = require('./../../../../castComponentList')
const {CR_12} = require('./../../../../crList')
const {LANG_GITH} = require('./../../../../languageIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Гитьянки Кит’рак',
  nameEn: 'Githyanki Kith’rak',
  id: CREATURE_GITHYANKI_KITHRAK,
  description: [
    `Милитаризированная культура гитьянки распределяет ранги и обязанности своим гражданам. Группы из 10 воинов следуют приказам [сартов](CREATURE:${CREATURE_GITHYANKI_KNIGHT}), а 10 сартов подчиняются приказам могучих кит’раков. Эти чемпионы заслуживают свой статус через мучительные тренировки и псионические пытки, пока не смогут пользоваться уважением у своих подчинённых.`,
    ...githyankiDescription,
  ],
  note: githyankiNote,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_GITH,
    CREATURE_TYPE_GITHYANKI,
  ],
  alignmentId: ALIGNMENT_LE,
  source: {
    id: SOURCE_MTOF,
    page: 127,
  },
  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  hp: {
    diceCount: 24,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 16,
    [PARAM_CON]: 17,
    [PARAM_INT]: 16,
    [PARAM_WIT]: 15,
    [PARAM_CHA]: 17,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_GITH,
  ],
  cr: CR_12,
  featureList: [
    {
      name: `Воодушевление`,
      description: `Бонусным действием гитьянки может магически окончить состояния Очарованный и Испуганный на себе и всех существах на его выбор, которых он может видеть в 30 фт. от себя.`,
    },
  ],
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_INT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_MAGE_HAND,
            comment: 'рука невидима',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_BLUR,
          SPELL_JUMP,
          SPELL_MISTY_STEP,
          {
            id: SPELL_NONDETECTION,
            comment: 'только на себя',
          },
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_PLANE_SHIFT,
          SPELL_TELEKINESIS,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Гитьянки совершает три атаки _Двуручным мечом_.`,
    },
    {
      gearId: GEAR_GREATSWORD,
      attack: {
        hit: {
          type: DAMAGE_PSYCHIC,
          diceCount: 5,
          diceType: 6,
        },
      },
    },
  ],
  reactionList: [
    {
      name: 'Мультиатака',
      description: `Гитьянки добавляет 4 к своему КД против одной ближней атаки, которая попала бы по нему. Чтобы сделать это, он должен видеть нападающего и держать в руках рукопашное оружие.`,
    },
  ],
}
