const {artificerSpellCircleList} = require('./../../../spellCircleByCasterType')
const {PC_SUBCLASS_ARTIFICER_ARTILLERIST} = require('./../../../pcSubClassIdList')
const {SOURCE_ERFTLW} = require('./../../../sourceList')
const {
  GEAR_ROD,
  GEAR_SMITHS_TOOLS,
  GEAR_STAFF,
  GEAR_WAND,
  GEAR_WOODCARVERS_TOOLS,
} = require('./../../../gearIdList')
const {
  SPELL_CONE_OF_COLD,
  SPELL_FIREBALL,
  SPELL_ICE_STORM,
  SPELL_MENDING,
  SPELL_SCORCHING_RAY,
  SPELL_SHATTER,
  SPELL_SHIELD,
  SPELL_THUNDERWAVE,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_FORCE,
  SPELL_WIND_WALL,
} = require('./../../../spellIdList')

const {
  FEATURE_ARCANE_FIREARM,
  FEATURE_ARTILLERIST_SPELLS,
  FEATURE_ELDRITCH_CANNON,
  FEATURE_EXPLOSIVE_CANNON,
  FEATURE_FORTIFIED_POSITION,
  FEATURE_TOOL_PROFICIENCY_ARTILLERIST,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_ARTILLERIST_SPELLS,
    name: `Заклинания артиллериста`,
    nameEn: `Artillerist Spells`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARTILLERIST,
    text: `У Вас всегда подготовлены некоторые заклинания, когда Вы достигаете определённого уровня в этом классе.

Они не учитываются в количестве заклинаний изобретателя, которые Вы можете подготавливать и для Вас считаются заклинаниями изобретателя.`,
    spellCircleLvl: artificerSpellCircleList,
    spellIdList: [
      SPELL_CONE_OF_COLD,
      SPELL_FIREBALL,
      SPELL_ICE_STORM,
      SPELL_SCORCHING_RAY,
      SPELL_SHATTER,
      SPELL_SHIELD,
      SPELL_THUNDERWAVE,
      SPELL_WALL_OF_FIRE,
      SPELL_WALL_OF_FORCE,
      SPELL_WIND_WALL,
    ],
    source: {
      id: SOURCE_ERFTLW,
      page: 60,
    },
  },
  {
    id: FEATURE_TOOL_PROFICIENCY_ARTILLERIST,
    name: `Владение инструментами`,
    nameEn: `Tool Proficiency`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARTILLERIST,
    text: `Вы получаете владение [инструментами резчика по дереву](GEAR:${GEAR_WOODCARVERS_TOOLS}).

Если Вы уже ими владеете, то получаете владение одним другим видом ремесленных инструментов по Вашему выбору.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 60,
    },
  },
  {
    id: FEATURE_ELDRITCH_CANNON,
    name: `Волшебная пушка`,
    nameEn: `Eldritch Cannon`,
    lvl: 3,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARTILLERIST,
    text: `Вы узнаёте как создать магическую пушку. Используя [инструменты резчика по дереву](GEAR:${GEAR_WOODCARVERS_TOOLS}) или [инструменты кузнеца](GEAR:${GEAR_SMITHS_TOOLS}), Вы можете действием магически создать маленькую или крошечную _Волшебную пушку_ в свободном пространстве на горизонтальной поверхности в пределах 5 футов от Вас. Маленькая _Волшебная пушка_ занимает место, а крошечную можно держать в одной руке.

После создания _Пушки_, Вы не сможете сделать это снова, пока не закончите длинный отдых или не потратите ячейку заклинания 1 круга или выше. У Вас может быть только одна _Пушка_ одновременно и Вы не можете создать новую, пока существует старая.

_Пушка_ — это магический предмет. Вне зависимости от размера, у неё 18 КД, и число её хитов равно Вашему пятикратному уровню изобретателя. Она имунна к урону ядом, психическому урону и всем состояниям. Если она должна пройти проверку характеристики или испытание, считайте все её характеристики как 10 (+0). Если на неё сотворить заклинание [Починка](SPELL:${SPELL_MENDING}), она восстановит 2к6 хитов. Она исчезает, если её хиты опускаются до 0 или через 1 час после создания. Вы можете разобрать её раньше действием.

Когда Вы создаёте _Пушку_, Вы определяете её внешний вид и есть ли у неё ноги. Также Вы выбираете её тип из таблицы «Волшебные пушки». На каждом своём ходу Вы можете бонусным действием активировать _Пушку_, если находитесь в пределах 60 футов от неё. Частью этого же бонусного действия Вы можете отправить _Пушку_ идти или карабкаться в свободное пространство в пределах 15 футов, если у неё есть ноги.

# Волшебные пушки

| Пушка            | Активация |
|------------------|-----------|
| Огнемёт          | _Пушка_ исторгает огонь 15-футовым конусом в указанном Вами направлении. Все существа в этой области должны пройти испытание Ловкости против Вашей сложности заклинаний, получая 2к8 урона огнём при провале, или половину этого урона при успехе. Огонь поджигает все горючие предметы в этой области, никем не несомые и не носимые. |
| Силовая баллиста | Совершите дальнобойную атаку заклинанием, исходящим из _Пушки_, по одному существу или предмету в пределах 120 футов от неё. При попадании, цель получает 2к8 урона силовым полем и, если это существо, отталкивается на 5 футов от _Пушки_. |
| Защитник         | _Пушка_ испускает всплеск положительной энергии, что даёт ей и каждому существу по Вашему выбору в пределах 10 футов от неё временные хиты, равные 1к8 + Ваш модификатор Интеллекта (минимум 1 временный хит). |
`,
    source: {
      id: SOURCE_ERFTLW,
      page: 60,
    },
  },
  {
    id: FEATURE_ARCANE_FIREARM,
    name: `Чаромёт`,
    nameEn: `Arcane Firearm`,
    lvl: 5,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARTILLERIST,
    text: `Вы узнаёте как превратить [волшебную палочку](GEAR:${GEAR_WAND}), [посох](GEAR:${GEAR_STAFF}) или [жезл](GEAR:${GEAR_ROD}) в _Чаромёт_, направляющий Ваши разрушительные заклинания.

Когда Вы заканчиваете длинный отдых, Вы можете [инструментами резчика по дереву](GEAR:${GEAR_WOODCARVERS_TOOLS}) нанести особые метки на [волшебную палочку](GEAR:${GEAR_WAND}), [посох](GEAR:${GEAR_STAFF}) или [жезл](GEAR:${GEAR_ROD}) и этим превратить их в Ваш _Чаромёт_. Метки существуют на предмете вечно, но исчезают, если позже Вы вырежете их на другом предмете.

Вы можете использовать этот _Чаромёт_ как магическую фокусировку для заклинаний изобретателя. Когда Вы сотворяете заклинание изобретателя с помощью _Чаромёта_, добавьте 1к8 к любой одной кости урона этого заклинания.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 60,
    },
  },
  {
    id: FEATURE_EXPLOSIVE_CANNON,
    name: `Взрывная пушка`,
    nameEn: `Explosive Cannon`,
    lvl: 9,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARTILLERIST,
    text: `Создаваемые Вами [Волшебные пушки](FEATURE:${FEATURE_ELDRITCH_CANNON}) становятся разрушительнее.

* Урон всех типов [Пушек](FEATURE:${FEATURE_ELDRITCH_CANNON}) увеличивается на 1к8.
* Вы можете действием приказать [Пушке](FEATURE:${FEATURE_ELDRITCH_CANNON}) взорваться, если Вы находитесь в пределах 60 футов от неё. Взрыв разрушает [Пушку](FEATURE:${FEATURE_ELDRITCH_CANNON}) и все существа в пределах 20 футов от неё должны пройти испытание Ловкости против Вашей сложности заклинаний, получая 3к8 урона силовым полем, или половину этого урона при успехе.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 61,
    },
  },
  {
    id: FEATURE_FORTIFIED_POSITION,
    name: `Укреплённая позиция`,
    nameEn: `Fortified Position`,
    lvl: 15,
    pcSubClassId: PC_SUBCLASS_ARTIFICER_ARTILLERIST,
    text: `Вы узнаёте как укреплять огневую позицию своими [Волшебными пушками](FEATURE:${FEATURE_ELDRITCH_CANNON}).

* Вы и Ваши союзники получают укрытие на половину, пока находятся в пределах 10 футов от Вашей [Волшебной пушки](FEATURE:${FEATURE_ELDRITCH_CANNON}), из-за мерцающего магического защитного поля, которое она испускает.
* Теперь у Вас может быть две [Волшебных пушки](FEATURE:${FEATURE_ELDRITCH_CANNON}) одновременно. Вы можете создать обе [Пушки](FEATURE:${FEATURE_ELDRITCH_CANNON}) одним и тем же действием (но не одной и той же ячейкой заклинаний) и можете активировать обе одним и тем же бонусным действием. Вы определяете — одинаковые ли это [Пушки](FEATURE:${FEATURE_ELDRITCH_CANNON}) или разные. Вы не можете создать третью [Пушку](FEATURE:${FEATURE_ELDRITCH_CANNON}), пока у Вас уже есть две.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 61,
    },
  },
]
