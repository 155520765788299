const {SOURCE_MTOF} = require('./../../sourceList')

module.exports = [
  {
    header: 'Гиффы',
    text: `Гиффа легко заметить в комнате: рост в семь футов, бегемотоподобная голова, безвкусная военная одежда с блестящими пистолетами и мушкетами. Эти космические наёмники славятся своей боевой подготовкой и любовью к взрывчатым веществам.`,
    source: {
      id: SOURCE_MTOF,
      page: 128,
    },
  },
]
