const {
  SOURCE_SCAG,
} = require ('./../../sourceList')
const {
  LANG_ALZHEDO,
  LANG_CHESSENTAN,
  LANG_CHONDATHAN,
  LANG_COMMON,
  LANG_DAMARAN,
  LANG_DAMBRATHAN,
  LANG_GURAN,
  LANG_HALRUAAN,
  LANG_ILLUSKAN,
  LANG_MIDANI,
  LANG_MULHORANDI,
  LANG_RASHEMI,
  LANG_ROUSHOUM,
  LANG_SHAARAN,
  LANG_SHOU,
  LANG_THAYAN,
  LANG_THORASS,
  LANG_TUIGAN,
  LANG_TURMIC,
  LANG_ULUIK,
  LANG_UNTHERIC,
} = require ('./../../languageIdList')

module.exports = [
  {
    header: `Вариант: Человеческие языки`,
    text: `Множество человеческих этносов и наций на Фаэруне обладают собственным языком, в дополнение ко [Всеобщему](LANG:${LANG_COMMON}). Большинство таких языков используют Торасс, алфавит [Старовсеобщего](LANG:${LANG_THORASS}), происходящий из [чондатанского языка](LANG:${LANG_CHONDATHAN}), которым пользовались торговцы, как общим языком.

Некоторые человеческие языки используют другие алфавиты, включающие в себя Драконий, Детек и Эспруар.

# Языки народов

| Народ | Язык | Алфавит |
|-|-|-|
| Аркаюны | [Дамбратан](LANG:${LANG_DAMBRATHAN}) | Эспруар |
| Бедины | [Мидани](LANG:${LANG_MIDANI}) | Торасс |
| Гуры | [Гуран](LANG:${LANG_GURAN}) (смесь [Рушума](LANG:${LANG_ROUSHOUM}) и [Рашемийского](LANG:${LANG_RASHEMI})) | Торасс |
| Дамарцы | [Дамарский](LANG:${LANG_DAMARAN}) | Детек |
| Иллюсканцы | [Иллюсканский](LANG:${LANG_ILLUSKAN}) | Торасс |
| Имаскари | [Рушум](LANG:${LANG_ROUSHOUM}) | Торасс |
| Калишиты | [Алжедо](LANG:${LANG_ALZHEDO}) | Торасс |
| Муланы | [Чессентский](LANG:${LANG_CHESSENTAN}), [Мулхорандский](LANG:${LANG_MULHORANDI}), [Унтерский](LANG:${LANG_UNTHERIC}) или [Тэйский](LANG:${LANG_THAYAN}) | Торасс |
| Нары | [Дамарский](LANG:${LANG_DAMARAN}) | Торасс |
| Рашеми | [Рашемийский](LANG:${LANG_RASHEMI}) | Торасс |
| Тетирцы | [Чондатанский](LANG:${LANG_CHONDATHAN}) | Торасс |
| Туйганы | [Туйганский](LANG:${LANG_TUIGAN}) | Торасс |
| Тёрами | [Тёрамийский](LANG:${LANG_TURMIC}) | Торасс |
| Улутиуне | [Улуик](LANG:${LANG_ULUIK}) | Торасс |
| Ффолки | Ваэлан | Торасс |
| Халруанцы | [Халруанский](LANG:${LANG_HALRUAAN}) | Драконий |
| Чондатанцы | [Чондатанский](LANG:${LANG_CHONDATHAN}) | Торасс |
| Шаары | [Шаарский](LANG:${LANG_SHAARAN}) | Детек |
| Шу | [язык Шу](LANG:${LANG_SHOU}) | Торасс |

Если Ваш Мастер позволит этот вариант, тогда следует считать, что люди из этнических групп, имеющих свой язык, также знают этот язык так же, как и [Всеобщий](LANG:${LANG_COMMON}). Этот этнический язык считается для них свободным дополнительным языком.`,
    source: {
      id: SOURCE_SCAG,
      page: 113,
    },
  },
]
