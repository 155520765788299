const {
  CREATURE_AWAKENED_SHRUB,
  CREATURE_AWAKENED_TREE,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_TRANSMUTATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
} = require('./../../../../../timePeriodList')
const {
  CONDITION_CHARMED,
} = require('./../../../../../conditionList')
const {
  SPELL_AWAKEN,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_AWAKEN,
  name: 'Пробуждение разума',
  nameEn: `Awaken`,
  description: `Потратив время на прокладывание магических каналов в драгоценном камне, Вы касаетесь им зверя или растения с размером не больше Огромного. У цели либо не должно быть значения Интеллекта, либо Интеллект должен быть не больше 3. Цель получает Интеллект 10. Цель также получает способность говорить на одном известном Вам языке. Если цель — растение, оно получает возможность шевелить ветками, корнями, лианами, и так далее, и получает чувства, подобные человеческим. Мастер подбирает параметры для пробуждённого растения, будь то [куст](CREATURE:${CREATURE_AWAKENED_SHRUB}) или [дерево](CREATURE:${CREATURE_AWAKENED_TREE}).

Пробуждённый зверь или растение очарован Вами 30 дней, либо пока Вы или Ваши спутники не причините ему вред. Когда очарование спадает, пробуждённое существо решает, останется ли оно к Вам дружелюбно, в зависимости от того, как Вы с ним обращались.`,
  lvl: 5,
  magicSchoolId: MAGIC_TRANSMUTATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  hasConsumable: true,
  materialText: `агат, стоящий как минимум 1 000 зм, расходуемый заклинанием`,
  castTime: { timeId: TIME_HOUR, count: 8 },
  effect: {
    condition: CONDITION_CHARMED,
  },
  source: [
    {
      id: SOURCE_PHB,
      page: 269,
    },
    {
      id: SOURCE_SRD,
      page: 120,
    },
  ],
}
