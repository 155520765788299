const eldritchKnight_arcaneTrickster_spellIdList = require('./../../constants/eldritchKnight_arcaneTrickster_spellIdList')
const {GEAR_THIEVES_TOOLS} = require('./../../../gearIdList')
const {PC_CLASS_WIZARD} = require('./../../../pcClassIdList')
const {PC_SUBCLASS_ROGUE_ARCANE_TRICKSTER} = require('./../../../pcSubClassIdList')
const {
  SOURCE_PHB,
  SOURCE_ERRATA_PHB,
} = require('./../../../sourceList')
const {thirdCasterSpellCircleList} = require('./../../../spellCircleByCasterType')
const {
  SPELL_CHARM_PERSON,
  SPELL_MAGE_HAND,
} = require('./../../../spellIdList')

const {
  FEATURE_CANTRIPS_ARCANE_TRICKSTER,
  FEATURE_CUNNING_ACTION,
  FEATURE_MAGE_HAND_LEGERDEMAIN,
  FEATURE_MAGICAL_AMBUSH,
  FEATURE_SPELL_KNOWN_ARCANE_TRICKSTER,
  FEATURE_SPELL_SLOTS_ARCANE_TRICKSTER,
  FEATURE_SPELL_THIEF,
  FEATURE_SPELLCASTING_ABILITY_ARCANE_TRICKSTER,
  FEATURE_SPELLCASTING_ARCANE_TRICKSTER,
  FEATURE_VERSATILE_TRICKSTER,
} = require('./../../../featureIdList')

module.exports = [
  {
    id: FEATURE_SPELLCASTING_ARCANE_TRICKSTER,
    name: `Использование заклинаний`,
    nameEn: `Spellcasting`,
    lvl: 3,
    text: `Вы получаете способность сотворять заклинания.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 99
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_CANTRIPS_ARCANE_TRICKSTER,
    name: `Заговоры`,
    nameEn: `Cantrips`,
    lvl: 3,
    text: `Вы знаете три заговора: [Волшебная рука](SPELL:${SPELL_MAGE_HAND}) и два других заговора по своему выбору из списка заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}).

Вы узнаёте ещё один заговор [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) на 10 уровне.

| Уровень плута | Заговоры |
|---------------|----------|
|  3–9          | 3        |
| 10–20         | 4        |
`,
    source: [
      {
        id: SOURCE_PHB,
        page: 99
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_SPELL_SLOTS_ARCANE_TRICKSTER,
    name: `Ячейки заклинаний`,
    nameEn: `Spell Slots`,
    lvl: 3,
    text: `В приведённой таблице показано, сколько у Вас есть ячеек для сотворения заклинаний 1 круга и выше. Для сотворения одного из этих заклинаний Вы должны потрать ячейку заклинаний с кругом не ниже круга заклинания. Вы восстанавливаете все потраченные ячейки заклинаний, когда заканчиваете длинный отдых.

Например, если Вы знаете заклинание 1 круга [Очарование личности](SPELL:${SPELL_CHARM_PERSON}) и у Вас есть ячейки заклинания 1 и 2 круга, Вы можете сотворить это заклинание, используя любую из этих ячеек.

## Количество ячеек заклинаний

| Уровень плута | 1-й круг | 2-й круг | 3-й круг | 4-й круг | 
|---------------|----------|----------|----------|----------|
|  3            | 2        |          |          |          | 
|  4–6          | 3        |          |          |          | 
|  7–9          | 4        | 2        |          |          | 
| 10–12         | 4        | 3        |          |          | 
| 13–15         | 4        | 3        | 2        |          | 
| 16–18         | 4        | 3        | 3        |          | 
| 19–20         | 4        | 3        | 3        | 1        |
`,
    source: [
      {
        id: SOURCE_PHB,
        page: 99
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_SPELL_KNOWN_ARCANE_TRICKSTER,
    name: `Известные заклинания`,
    nameEn: `Spell Known`,
    lvl: 3,
    text: `Вы знаете три заклинания [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) 1 круга по своему выбору, два из которых должны быть выбраны из школ Иллюзий или Очарования.

Колонка «Заклинания» показывает, когда Вы сможете выучить новые заклинания [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) 1 круга и выше. Все эти заклинания должны принадлежать школе Иллюзии или Очарования, и должны быть того круга, для которого у Вас есть ячейки заклинаний. Например, когда Вы получите 7 уровень в этом классе, Вы можете узнать одно новое заклинание 1 или 2 круга.

Заклинания, которые Вы узнаёте на 8, 14 и 20 уровнях могут принадлежать любой школе магии.

Каждый раз, когда Вы получаете уровень в этом классе, Вы можете заменить одно известное заклинание [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) другим заклинанием по своему выбору из списка заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}). Круг нового заклинания не должен превышать круг самой большой ячейки заклинаний, и оно должно принадлежать школе Иллюзий или Очарования, если только Вы не заменяете заклинание любой школы магии, полученное на 3, 8, 14 или 20 уровне.

## Количество известных заклинаний

| Уровень плута | Заклинания | Уровень плута | Заклинания |
|---------------|------------|---------------|------------|
|  3            |  3         | 13            |  9         | 
|  4–6          |  4         | 14–15         | 10         | 
|  7            |  5         | 16–18         | 11         | 
|  8–9          |  6         | 19            | 12         | 
| 10            |  7         | 20            | 13         | 
| 11–12         |  8         |               |            |
`,
    spellCircleLvl: thirdCasterSpellCircleList,
    spellIdList: eldritchKnight_arcaneTrickster_spellIdList,
    source: [
      {
        id: SOURCE_PHB,
        page: 99
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_SPELLCASTING_ABILITY_ARCANE_TRICKSTER,
    name: `Заклинательная характеристика`,
    nameEn: `Spellcasting Ability`,
    lvl: 3,
    text: `При создании заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}) Вы используете Интеллект, так как Вы узнаёте их с помощью специальных исследований и запоминания.

Вы используете Интеллект в случаях, когда заклинание ссылается на заклинательную характеристику. Кроме того, Вы используете модификатор Интеллекта при определении СЛ Ваших заклинаний [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), и при броске атаки заклинаниями.

**СЛ испытания = 8 + бонус мастерства + модификатор Интеллекта**

**Модификатор броска атаки = бонус мастерства + модификатор Интеллекта**`,
    spellCircleLvl: thirdCasterSpellCircleList,
    spellIdList: eldritchKnight_arcaneTrickster_spellIdList,
    source: [
      {
        id: SOURCE_PHB,
        page: 99
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь можно заменять на заклинания любой школы и заклинания любой школы, полученные на 3 уровне',
        page: 1
      },
    ],
  },
  {
    id: FEATURE_MAGE_HAND_LEGERDEMAIN,
    name: `Улучшенная волшебная рука`,
    nameEn: `Mage Hand Legerdemain`,
    lvl: 3,
    text: `Когда Вы накладываете заклинание [Волшебная рука](SPELL:${SPELL_MAGE_HAND}), Вы можете создать руку невидимой, и совершать ею следующие дополнительные действия:

* Вы можете положить один предмет, удерживаемый рукой, в контейнер, носимый или несомый другим существом.
* Вы можете достать предмет из контейнера, носимого или несомого другим существом.
* Вы можете использовать [воровские инструменты](GEAR:${GEAR_THIEVES_TOOLS}) для вскрытия замков и обезвреживания ловушек в дистанции этого заклинания. 

Вы можете выполнить одно из этих действий, не будучи замеченным, если успешно совершите проверку Ловкости (Ловкость рук) против проверки Мудрости (Внимательность) существа.

Кроме того, Вы можете использовать бонусное действие, предоставленное умением [Хитрое действие](FEATURE:${FEATURE_CUNNING_ACTION}), для управления рукой. `,
    source: {
      id: SOURCE_PHB,
      page: 99,
    },
  },
  {
    id: FEATURE_MAGICAL_AMBUSH,
    name: `Магическая засада`,
    nameEn: `Magical Ambush`,
    lvl: 9,
    text: `Если Вы накладываете заклинание на существо, от которого скрыты, существо в этом ходу совершает с помехой испытания от этого заклинания.`,
    source: {
      id: SOURCE_PHB,
      page: 99,
    },
  },
  {
    id: FEATURE_VERSATILE_TRICKSTER,
    name: `Многогранный ловкач`,
    nameEn: `Versatile Trickster`,
    lvl: 13,
    text: `Вы получаете возможность отвлекать цель своей [Волшебной рукой](SPELL:${SPELL_MAGE_HAND}).

В свой ход Вы бонусным действием можете обозначить существо в пределах 5 футов от руки, сотворённой этим заклинанием. Если Вы так делаете, то до конца этого хода совершаете броски атаки по этому существу с преимуществом.`,
    source: {
      id: SOURCE_PHB,
      page: 99,
    },
  },
  {
    id: FEATURE_SPELL_THIEF,
    name: `Вор заклинаний`,
    nameEn: `Spell Thief`,
    lvl: 17,
    text: `Вы получаете способность магически воровать у другого колдующего знания о том, как творить заклинание.

Сразу после сотворения заклинания существом, целью которого являетесь вы, или же если Вы попали в область действия этого заклинания, Вы можете реакцией заставить существо совершить испытание его базовой характеристики. СЛ равна Вашей СЛ заклинаний. Если испытание провален, Вы отрицаете эффект заклинания на себе, и крадёте знание этого заклинания, если оно как минимум 1 уровня, и Вы можете сотворять заклинания этого уровня (оно не обязано быть заклинанием [волшебника](PC_CLASS:${PC_CLASS_WIZARD})). Вы знаете это заклинание в течение следующих 8 часов и можете его сотворять, используя свои ячейки заклинаний. Существо, у которого Вы украли заклинание, не может его творить на протяжении 8 часов.

Использовав это умение, Вы не можете использовать его повторно до окончания длинного отдыха.`,
    source: {
      id: SOURCE_PHB,
      page: 99,
    },
  },
].map(
  feature => ({
    ...feature,
    pcSubClassId: PC_SUBCLASS_ROGUE_ARCANE_TRICKSTER,
  })
)
