const {
  CREATURE_UMBER_HULK,
} = require('./../../../../../creatureIdList')
const {
  MAGIC_ABJURATION,
} = require('./../../../../../magicList')
const {
  CAST_VERBAL,
  CAST_SOMATIC,
  CAST_MATERIAL,
} = require('./../../../../../castComponentList')
const {
  SOURCE_PHB,
  SOURCE_SRD,
} = require('./../../../../../sourceList')
const {
  TIME_HOUR,
  TIME_MINUTE,
} = require('./../../../../../timePeriodList')
const {
  SPELL_ARCANE_LOCK,
  SPELL_DANCING_LIGHTS,
  SPELL_DISPEL_MAGIC,
  SPELL_GUARDS_AND_WARDS,
  SPELL_GUST_OF_WIND,
  SPELL_MAGIC_MOUTH,
  SPELL_MINOR_ILLUSION,
  SPELL_STINKING_CLOUD,
  SPELL_SUGGESTION,
  SPELL_WEB,
} = require('./../../../../../spellIdList')

module.exports = {
  id: SPELL_GUARDS_AND_WARDS,
  name: 'Стражи',
  nameEn: `Guards and Wards`,
  description: `Вы создаёте защиту, охраняющую 2 500 квадратных футов пола (квадрат с длиной стороны 50 футов, сто клеток 5 × 5 футов или двадцать пять квадратов с длиной стороны 10 футов). Охраняемая область может быть до 20 футов в высоту и может иметь любую форму. Вы можете защитить несколько этажей крепости, поделив их на части, при условии, что во время накладывания этого заклинания Вы можете туда попасть.

При накладывании заклинания Вы можете указать тех, кто не будет попадать под действие одного или всех выбранных Вами эффектов. Вы можете также указать пароль, произношение которого дарует иммунитет к этим эффектам.

Заклинание _стражи_ создаёт в охраняемой местности следующие эффекты:

* **Двери.** Все двери в охраняемой области запираются магией, как если бы они были закрыты заклинанием [волшебный замок](SPELL:${SPELL_ARCANE_LOCK}). Кроме того, Вы можете покрыть до десяти дверей иллюзией (эквивалентной функции _«иллюзорный предмет»_ заклинания [малая иллюзия](SPELL:${SPELL_MINOR_ILLUSION})), заставляя их выглядеть как гладкая стена.
* **Коридоры.** Коридоры заполняются туманом, делающим местность сильно заслоняющей. Кроме того, на каждом перекрёстке или развилке, где есть выбор направления, есть 50% шанс того, что существо (но не вы) будет считать, что идёт в сторону, противоположную той, в которую она на самом деле идёт.
* **Лестницы.** Все лестницы в охраняемой области сверху донизу заполняются [паутиной](SPELL:${SPELL_WEB}), как от одноимённого заклинания. Нити восстанавливаются через 10 минут, если их сожгут или разрежут, а заклинание _стражи_ ещё активно.
* **Прочие эффекты заклинания.** Вы можете добавить к охраняемой области один из следующих эффектов:
  * Поместите [пляшущие огоньки](SPELL:${SPELL_DANCING_LIGHTS}) в четыре коридора. Вы можете задать простую последовательность, которую огоньки будут выполнять, пока активно заклинание _стражи_.
  * Поместите [волшебные уста](SPELL:${SPELL_MAGIC_MOUTH}) в два места.
  * Поместите [зловонное облако](SPELL:${SPELL_STINKING_CLOUD}) в два места. Испарения появляются в указанных Вами местах; они возвращаются через 10 минут, если рассеются ветром, а заклинание _стражи_ ещё будет активно.
  * Поместите постоянный [порыв ветра](SPELL:${SPELL_GUST_OF_WIND}) в один коридор или комнату.
  * Поместите [внушение](SPELL:${SPELL_SUGGESTION}) в одно место. Выберите область с размером не больше клетки 5 × 5 футов, и все существа, входящие в эту область или проходящие по ней, мысленно воспринимают внушение.

Вся охраняемая область излучает магию. [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}), наложенное на конкретный эффект, в случае успеха устраняет только этот эффект.

Вы можете сделать эту магию постоянной, накладывая это заклинание каждый день в течение одного года.`,
  lvl: 6,
  magicSchoolId: MAGIC_ABJURATION,
  range: 0,
  componentIdList: [CAST_VERBAL, CAST_SOMATIC, CAST_MATERIAL],
  hasCost: true,
  materialText: `горящее благовоние, немного серы и масла, завязанная узлом тетива, немного крови [бурого увальня](CREATURE:${CREATURE_UMBER_HULK}) и маленький серебряный жезл, стоящий как минимум 10 зм`,
  duration: { timeId: TIME_HOUR, count: 24 },
  castTime: { timeId: TIME_MINUTE, count: 10 },
  source: [
    {
      id: SOURCE_PHB,
      page: 281,
    },
    {
      id: SOURCE_SRD,
      page: 151,
    },
  ],
}
