const {
  SPELL_BLADE_BARRIER,
  SPELL_COMMUNE,
  SPELL_CONTROL_WEATHER,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_FLAME_STRIKE,
  SPELL_INSECT_PLAGUE,
  SPELL_INVISIBILITY,
  SPELL_RAISE_DEAD,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_RADIANT,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
} = require('./../../../../conditionList')
const {
  ABILITY_DIVINE_AWARENES,
  ABILITY_MAGIC_RESISTANCE,
} = require('./../../../../creatureAbilityList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  SPEED_FLY,
  SPEED_WALK,
} = require('./../../../../speedList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {ALIGNMENT_LG} = require('./../../../../aligmentList')
const {angelDescriptionList} = require('./../../../../textCommonParts')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_16} = require('./../../../../crList')
const {CREATURE_PLANETAR} = require('./../../../../creatureIdList')
const {CREATURE_TYPE_CELESTIAL} = require('./../../../../creatureTypeIdList')
const {GEAR_GREATSWORD} = require('./../../../../gearIdList')
const {GENDER_MALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_LARGE} = require('./../../../../sizeList')
const {SKILL_PERCEPTION} = require('./../../../../skillList')
const {SOURCE_MM} = require('./../../../../sourceList')

module.exports = {
  name: 'Планетар',
  nameEn: 'Planetar',
  id: CREATURE_PLANETAR,
  description: [
    `Планетары это оружие богов, которым они служат, воплощение мощи своих божеств. Они могут вызвать дождь, чтобы облегчить засуху, или наслать насекомых, чтобы уничтожить урожай. Уши этих небожителей слышат любую ложь, а их сияющие глаза видят любой обман.

Планетары мускулистые и лысые. Кожа их зелёного цвета с молочным отливом, а крылья у них белые. Они возвышаются над большинством гуманоидов, и изящно пользуются огромными мечами. Иногда их отправляют на помощь могущественным смертным, занятым добрыми делами, но особенно планетары любят миссии, в которых можно сразиться с исчадиями.`,
    ...angelDescriptionList,
  ],
  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CELESTIAL,
  ],
  alignmentId: ALIGNMENT_LG,
  armor: {
    ac: 19,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 16,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_FLY]: 120,
  },
  params: {
    [PARAM_STR]: 24,
    [PARAM_DEX]: 20,
    [PARAM_CON]: 24,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 22,
    [PARAM_CHA]: 25,
  },
  saveThrowList: [
    PARAM_CON,
    PARAM_WIT,
    PARAM_CHA,
  ],
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_RADIANT,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
  ],
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_16,
  source: {
    id: SOURCE_MM,
    page: 20,
  },
  featureList: [
    {
      name: 'Оружие ангела',
      description: `Атаки ★его★ оружием являются магическими. Если ★СУЩЕСТВО★ попадает любым оружием, оно причиняет дополнительный урон излучением 5к8 (уже включено в атаку).`,
    },
    ABILITY_DIVINE_AWARENES,
    ABILITY_MAGIC_RESISTANCE,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_EVIL_AND_GOOD,
          SPELL_INVISIBILITY,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_BLADE_BARRIER,
          SPELL_DISPEL_EVIL_AND_GOOD,
          SPELL_FLAME_STRIKE,
          SPELL_RAISE_DEAD,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_COMMUNE,
          SPELL_CONTROL_WEATHER,
          SPELL_INSECT_PLAGUE,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две рукопашные атаки.`,
    },
    {
      name: 'Двуурчный меч',
      // gearId: GEAR_GREATSWORD, // TODO: Найти способ перетащить в оружие с модифицированными костями урона
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 12,
        range: 5,
        hit: [
          {
            type: DAMAGE_SLASHING,
            diceCount: 4,
            diceType: 6,
            diceBonus: 7,
          },
          {
            type: DAMAGE_RADIANT,
            diceCount: 5,
            diceType: 8,
          },
        ],
      },
    },
    {
      name: 'Целебное касание',
      limit: {
        count: 4,
        period: 'день',
      },
      description: `★СУЩЕСТВО★ прикасается к другому существу. Цель магическим образом восстанавливает 30 (6к8+3) хитов и избавляется от проклятий, болезней, отравлений, слепоты и глухоты.`,
    },
  ],
  genderId: GENDER_MALE,
}
