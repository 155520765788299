const {
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_DOMINATE_BEAST,
  SPELL_HUNTERS_MARK,
  SPELL_MAZE,
  SPELL_MIRAGE_ARCANE,
  SPELL_TELEPORT,
  SPELL_WALL_OF_STONE,
} = require('./../../../../spellIdList')
const {
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NONMAGIC_WEAPON,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_POISONED,
} = require('./../../../../conditionList')
const {
  ABILITY_LEGENDARY_RESISTANCE_3,
  ABILITY_MAGIC_RESISTANCE,
  ABILITY_MAGIC_WEAPONS,
  ABILITY_RECKLESS,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_BAPHOMET,
  CREATURE_CULTIST,
  CREATURE_GORISTRO,
  CREATURE_MINOTAUR,
  CREATURE_QUASIT,
} = require('./../../../../creatureIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_DEMON_LORD,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ALL,
  LANG_TELEPATHY,
} = require('./../../../../languageIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  SKILL_INTIMIDATION,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {ALIGNMENT_CE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CR_23} = require('./../../../../crList')
const {demonDescription} = require('./../../../../textCommonParts')
const {SENSE_TRUE_VISION} = require('./../../../../senseList')
const {SIZE_HUGE} = require('./../../../../sizeList')
const {SOURCE_MTOF} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Бафомет',
  nameAlt: [
    'Рогатый король',
    'Принц зверей',
  ],
  nameEn: 'Baphomet',
  id: CREATURE_BAPHOMET,
  hasSpoilers: true,
  description: [
    `Цивилизация есть слабость, а дикость — сила. Таково кредо Бафомета, Рогатого Короля и Принца Зверей. Он правит [минотаврами](CREATURE:${CREATURE_MINOTAUR}) и прочими существами, в чьих сердцах живёт свирепость. Ему поклоняются те, кто желает вырваться из оков цивилизации и высвободить своего зверя изнутри, ибо Бафомет предрекает мир без ограничений, где существа воплощают в жизнь свои самые дикие желания.

Культы, поклоняющиеся Бафомету, используют лабиринты и сложные узлы в качестве своим эмблем, а также создают для себя секретные убежища наподобие лабиринтов, которые любит их владыка. Испачканные кровью короны и железное либо медное оружие украшают их нечестивые алтари.

Со временем, [культисты](CREATURE:${CREATURE_CULTIST}) Бафомета начинают ощущать его влияние. Их глаза наливаются кровью, волосы грубеют и становятся толще. Иногда из лба пробиваются маленькие рога. В определённый момент, преданный культист может полностью превратиться в минотавра, что считается величайшим даром Принца Зверей.

Бафомет выглядит как огромный минотавр с чёрной шерстью. Он обладает ростом в 20 футов (чуть больше 6 метров) и шестью железными рогами. Его красные глаза пылают инфернальным светом. Несмотря на животную жажду крови, которой наполнен Бафомет, он обладает безжалостным и хитрым умом, устремлённым к разрушению цивилизации.

Бафомет вооружён гигантской глефой, что зовётся Сердцеруб. Иногда он отбрасывает это смертоносное оружие в сторону, чтобы насадить врагов на свои рога, втоптать их в землю и разорвать их плоть своими зубами, словно животное.`,
    {
      header: 'Логово Бафомета',
      text: `Логово Бафомета — это его дворец, Ликтион, что расположен на слое Бездны, известным под названием Бесконечный лабиринт. Спрятанный посреди извивающихся путей лабиринта, занимающего целый план, Ликтион поддерживается в безукоризненном порядке и окружён рвом, созданным на манер трёхмерного лабиринта. Сам дворец — громадное строение, внутреннее устройство которого так же запутанно, как план, на котором он расположен. Дворец населён [минотаврами](CREATURE:${CREATURE_MINOTAUR}), [гористро](CREATURE:${CREATURE_GORISTRO}) и [квазитами](CREATURE:${CREATURE_QUASIT}).

# Действия логова

При значении инициативы «20» (проигрыш при ничье), Бафомет может совершить действие в логове, чтобы создать один из следующих магических эффектов; он не может использовать один и тот же эффект два раунда подряд:

* Бафомет запечатывает один дверной проем или любой другой проход в логове. Проход должен быть незанятым. Он заполняется цельным камнем на одну минуту, либо до тех пор, пока Бафомет не создаст этот эффект снова. 
* Бафомет выбирает помещение на территории логова, не превышающее ни в одном из габаритов 100 футов. В следующий счёт инициативы 20, гравитация в этой комнате меняется на противоположную. Когда это случается, любые существа и предметы в помещении падают в направлении новой гравитации, если только у них нет способов остаться в воздухе. Бафомет может игнорировать изменение гравитации, если он в помещении, но он любит использовать это действие для того, чтобы, прыгая на потолок, атаковать цели, которые находились высоко под потолком. 
* Бафомет сотворяет заклинание [таинственный мираж](SPELL:${SPELL_MIRAGE_ARCANE}), которое воздействует на помещение, не превышающее ни в одном из габаритов 100 футов. Действие эффекта заканчивается в следующую очередь инициативы 20.

# Местные эффекты

Местность вокруг логова Бафомета окутана его магией, что создаёт один или больше следующих эффектов:

* Растительность в пределах мили от логова начинает расти густо и создаёт небольшие лабиринты со стенами из деревьев, кустов и прочей флоры. 
* Звери в пределах мили от логова напуганы и растеряны, словно они постоянно под угрозой того, что за ними охотятся. Они могут атаковать или впасть в панику, даже если поблизости нет видимой угрозы. 
* Если гуманоид проводит час в пределах одной мили от логова, он должен пройти испытание Мудрости СЛ 18 или поддаться безумию, суть которого определяется по таблице _«Безумие Бафомета»_. Существо, преуспевшее в испытании, не может попасть под действие этого регионального эффекта вновь в течение 24 часов.

Если Бафомет умирает, эти эффекты прекращаются в течение 1к10 дней.`,
      source: {
        id: SOURCE_MTOF,
        page: 141,
      },
    },
    {
      header: 'Безумие Бафомета',
      text: `Если существо сходит с ума в логове Бафомета или в поле зрения лорда демонов, совершите бросок по таблице Безумие Бафомета, чтобы определить природу безумия, которое становится слабостью персонажа и действует, пока не будет излечено.

| к100  | Слабость (длится пока не будет вылечена) |
|-------|------------------------------------------|
| 01-20 | _«Моя злость поглощает меня. Со мной невозможно договориться, когда я в ярости»_ |
| 21-40 | _«Я опускаюсь до звериного поведения, выглядя скорее диким животным, нежели разумным существом»_ |
| 41-60 | _«Весь мир — это мои охотничьи угодья. Все остальные — моя добыча»_ |
| 61-80 | _«Я легко поддаюсь ненависти, которая вскоре взрывается вспышкой ярости»_ |
| 81-00 | _«Я не считаю тех, кто выступает против меня, равными себе. Для меня они просто звери, созданные быть жертвами»_ |
`,
      source: {
        id: SOURCE_MTOF,
        page: 142,
      },
    },
    ...demonDescription,
  ],
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEMON,
    CREATURE_TYPE_DEMON_LORD,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_MTOF,
    page: 142,
  },
  armor: {
    ac: 22,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 19,
  },
  speed: {
    [SPEED_WALK]: 40,
  },
  params: {
    [PARAM_STR]: 30,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 26,
    [PARAM_INT]: 18,
    [PARAM_WIT]: 24,
    [PARAM_CHA]: 16,
  },
  saveThrowList: [
    PARAM_DEX,
    PARAM_CON,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_INTIMIDATION]: PROF_DOUBLE,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_COLD,
    DAMAGE_FIRE,
    DAMAGE_ELECTRICITY,
  ],
  immunityList: [
    DAMAGE_POISON,
    DAMAGE_NONMAGIC_WEAPON,
  ],
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_TRUE_VISION,
      value: 120,
    },
  ],
  languageList: [
    LANG_ALL,
    {
      id: LANG_TELEPATHY,
      range: 120,
    },
  ],
  cr: CR_23,
  featureList: [
    {
      name: 'Атака с разбега',
      description: `Если Бафомет перемещается по крайней мере 10 футов по прямой линии к цели, а затем попадает по ней своей атакой рогами в этот же ход, цель получает дополнительно 22 (4к10) колющего урона. Если цель является существом, она должна пройти испытание Силы СЛ 25 или будет отброшена на расстояние до 10 футов и упадет ничком.`,
    },
    {
      name: 'Запоминание лабиринта',
      description: `Бафомет способен в идеале запоминать любой путь, пройденный им, и обладает иммунитетом к заклинанию [лабиринт](SPELL:${SPELL_MAZE}).`,
    },
    ABILITY_LEGENDARY_RESISTANCE_3,
    ABILITY_MAGIC_RESISTANCE,
    ABILITY_MAGIC_WEAPONS,
    ABILITY_RECKLESS,
  ],
  spellCast: {
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          SPELL_DETECT_MAGIC,
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DISPEL_MAGIC,
          SPELL_DOMINATE_BEAST,
          SPELL_HUNTERS_MARK,
          SPELL_MAZE,
          SPELL_WALL_OF_STONE,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_TELEPORT,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      description: `Бафомет совершает три атаки: одну _Сердцерубом_, одну _Укусом_, и одну _рогами_.`,
    },
    {
      name: 'Сердцеруб',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 15,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 4,
          diceType: 6,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Укус',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Рога',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 17,
        range: 10,
        hit: {
          type: DAMAGE_PIERCING,
          diceCount: 2,
          diceType: 10,
          diceBonus: 10,
        },
      },
    },
    {
      name: 'Ужасающее присутствие',
      description: `Каждое существо на выбор Бафомета, находящееся на расстоянии 120 футов от него, и знающее о его присутствии, должно пройти испытание Мудрости СЛ 18 или станет испуганным на 1 минуту. Напуганное существо может повторять испытание в конце каждого своего хода, завершая действие эффекта в случае успеха. Такие повторные испытания проходятся с помехой, если Бафомет находится в поле зрения существа.

Если существо преуспевает в одном из этих испытаний или если действие эффекта завершается на нём, существо приобретает иммунитет к способности Бафомета _Ужасающее присутствие_ на следующие 24 часа.`,
    },
  ],
  legendaryPoints: 3,
  legendaryActionList: [
    {
      name: 'Атака Сердцерубом',
      description: `Бафомет совершает рукопашную атаку
Сердцерубом.`,
    },
    {
      name: 'Атака с разбега',
      cost: 2,
      description: `Бафомет перемещается со своей скоростью, а затем совершает атаку рогами.`,
    },
  ],
}
