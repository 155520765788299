const {
  SOURCE_TCoE,
} = require('./../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../genderList')
const {
  PC_CLASS_DRUID,
} = require('./../../../../pcClassIdList')
const {
  GEAR_POT_IRON,
} = require('./../../../../gearIdList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../magicItemTypeList')
const {
  MGC_RARITY_VERY_RARE,
} = require('./../../../../magicItemRarityList')
const {
  SPELL_RAISE_DEAD,
  SPELL_SCRYING,
} = require('./../../../../spellIdList')
const {
  MAGIC_ITEM_CAULDRON_OF_REBIRTH,
  MAGIC_ITEM_POTION_OF_GREATER_HEALING,
} = require('./../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_CAULDRON_OF_REBIRTH,
  name: `Котёл перерождения`,
  nameEn: `Cauldron of Rebirth`,
  type: MGC_TYPE_WONDROUS_ITEM,
  gearType: GEAR_POT_IRON,
  rarity: MGC_RARITY_VERY_RARE,
  attunement: true,
  attunementComment: `друидом или колдуном`,
  description: `Чугунные стенки этого Крохотного котла украшены рельефными изображениями героев.

Вы можете использовать _Котёл перерождения_ как заклинательную фокусировку для Ваших заклинаний [друида](PC_CLASS:${PC_CLASS_DRUID}) и его можно использовать как подходящий компонент для заклинания [Наблюдение](SPELL:${SPELL_SCRYING}).

Завершая длинный отдых, Вы можете использовать _Котёл перерождения_ для создания [Зелья большого лечения](SPELL:${MAGIC_ITEM_POTION_OF_GREATER_HEALING}). Это зелье действует 24 часа, после чего теряет свою магию, если не будет выпито.

Вы можете действием позволить _Котлу_ вырасти до размера, позволяющему ему вмещать свернувшееся калачиком существо Среднего размера. Вы можете действием вернуть _Котёл_ к своим нормальным размерам, безвредно выталкивая всё, что в него не влезает, в свободное окружающее пространство.

Если Вы поместите в _Котёл_ труп гуманоида и засыпете его 200 фунтами соли (стоящей 10 зм) не менее чем на 8 часов, соль будет поглощена и существо вернётся к жизни на следующем рассвете, как будто оно было целью заклинания [Оживление](SPELL:${SPELL_RAISE_DEAD}). Использовав _Котёл_ таким образом, Вы не сможете использовать его снова в течение 7 дней.`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_TCoE,
    page: 177,
  },
}
