const {
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_FIEND,
} = require('./../../../../creatureTypeIdList')
const {
  SPEED_FLY,
} = require('./../../../../speedList')
const {
  ATTACK_RANGE_SPELL,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_FIRE,
} = require('./../../../../damageTypeList')
const {
  ABILITY_DEVIL_S_SIGHT,
} = require('./../../../../creatureAbilityList')
const {
  CREATURE_DEVIL_SPIRIT,
  CREATURE_YUGOLOTH_SPIRIT,
} = require('./../../../../creatureIdList')

module.exports = {
  name: 'Дух дьявола',
  nameEn: 'Devil Spirit',
  id: CREATURE_DEVIL_SPIRIT,
  parentId: CREATURE_YUGOLOTH_SPIRIT,
  creatureTypeIdList: [
    CREATURE_TYPE_FIEND,
    CREATURE_TYPE_DEVIL,
  ],
  hp: `40 + 15-кратный круг заклинания выше 6-го`,
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает число атак, равное половине круга заклинания.`,
    },
    {
      name: 'Метание пламени',
      description: `Если цель — горючий предмет, который никто не несёт и не носит, она также загорается.`,
      attack: {
        type: ATTACK_RANGE_SPELL,
        bonus: `бонус заклинателя к атаке заклинаниями`,
        range: 150,
        hit: {
          type: DAMAGE_FIRE,
          diceCount: 2,
          diceType: 6,
          diceBonus: 3,
          comment: ` + круг заклинания`,
        },
      },
    },
  ],
  extendPropCollection: {
    speed: {
      [SPEED_FLY]: 60,
    },
    featureList: [
      ABILITY_DEVIL_S_SIGHT,
    ],
  },
}
