const {
  SPELL_ANIMAL_MESSENGER,
  SPELL_ANIMAL_SHAPES,
  SPELL_BEAST_SENSE,
  SPELL_COMMUNE_WITH_NATURE,
  SPELL_CONJURE_ANIMALS,
  SPELL_CURE_WOUNDS,
  SPELL_DOMINATE_BEAST,
  SPELL_DRUIDCRAFT,
  SPELL_ENTANGLE,
  SPELL_FAERIE_FIRE,
  SPELL_FIRE_STORM,
  SPELL_FORESIGHT,
  SPELL_HEAL,
  SPELL_HEROES_FEAST,
  SPELL_HOLD_PERSON,
  SPELL_LOCATE_CREATURE,
  SPELL_MASS_CURE_WOUNDS,
  SPELL_MELD_INTO_STONE,
  SPELL_MENDING,
  SPELL_POISON_SPRAY,
  SPELL_PRODUCE_FLAME,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_STONESKIN,
  SPELL_SUNBEAM,
  SPELL_TREE_STRIDE,
  SPELL_WALL_OF_FIRE,
  SPELL_WATER_BREATHING,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_MEDICINE,
  SKILL_NATURE,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  GEAR_HIDE_ARMOR,
  GEAR_SCIMITAR,
  GEAR_SHIELD,
} = require('./../../../../gearIdList')
const {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMANOID,
} = require('./../../../../creatureTypeIdList')
const {
  LANG_ANY,
  LANG_DRUIDIC,
} = require('./../../../../languageIdList')
const {
  CREATURE_ARCHDRUID,
  CREATURE_DRUID,
} = require('./../../../../creatureIdList')
const {ALIGNMENT_ANY} = require('./../../../../aligmentList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CR_12} = require('./../../../../crList')
const {DAMAGE_SLASHING} = require('./../../../../damageTypeList')
const {PC_CLASS_DRUID} = require('./../../../../pcClassIdList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {SPEED_WALK} = require('./../../../../speedList')

module.exports = {
  name: 'Архидруид',
  nameEn: 'Archdruid',
  id: CREATURE_ARCHDRUID,
  description: `Архидруиды следят за чудесами природы своих владений. Они редко взаимодействуют с цивилизованными народами, если нет серьёзной угрозы природному порядку. У архидруида, как правило, один или более учеников, которые являются [друидами](CREATURE:${CREATURE_DRUID}), и логово архидруида обычно охраняют лояльные к нему животные и фейские существа.`,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_ANY_RACE,
  ],
  alignmentId: ALIGNMENT_ANY,
  source: {
    id: SOURCE_VGTM,
    page: 211,
  },
  armor: {
    ac: 16,
    gearId: [GEAR_HIDE_ARMOR, GEAR_SHIELD],
  },
  hp: {
    diceCount: 24,
  },
  speed: {
    [SPEED_WALK]: 30,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 14,
    [PARAM_CON]: 12,
    [PARAM_INT]: 12,
    [PARAM_WIT]: 20,
    [PARAM_CHA]: 11,
  },
  saveThrowList: [
    PARAM_INT,
    PARAM_WIT,
  ],
  skillCollection: {
    [SKILL_MEDICINE]: PROF_NORMAL,
    [SKILL_NATURE]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  languageList: [
    LANG_DRUIDIC,
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_12,
  spellCast: {
    spellCasterLevel: 18,
    spellCasterClass: PC_CLASS_DRUID,
    spellIdList: [
      SPELL_ANIMAL_MESSENGER,
      SPELL_ANIMAL_SHAPES,
      SPELL_BEAST_SENSE,
      SPELL_COMMUNE_WITH_NATURE,
      SPELL_CONJURE_ANIMALS,
      SPELL_CURE_WOUNDS,
      SPELL_DOMINATE_BEAST,
      SPELL_DRUIDCRAFT,
      SPELL_ENTANGLE,
      SPELL_FAERIE_FIRE,
      SPELL_FIRE_STORM,
      SPELL_FORESIGHT,
      SPELL_HEAL,
      SPELL_HEROES_FEAST,
      SPELL_HOLD_PERSON,
      SPELL_LOCATE_CREATURE,
      SPELL_MASS_CURE_WOUNDS,
      SPELL_MELD_INTO_STONE,
      SPELL_MENDING,
      SPELL_POISON_SPRAY,
      SPELL_PRODUCE_FLAME,
      SPELL_SPEAK_WITH_ANIMALS,
      SPELL_STONESKIN,
      SPELL_SUNBEAM,
      SPELL_TREE_STRIDE,
      SPELL_WALL_OF_FIRE,
      SPELL_WATER_BREATHING,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      3,
      1,
      1,
      1,
      1,
    ],
  },
  actionList: [
    {
      gearId: GEAR_SCIMITAR,
    },
    {
      name: 'Изменение облика',
      limit: {
        count: 2,
        period: 'день',
      },
      description: `Архидруид при помощи магии превращается в животное или элементаля с показателем опасности 6 или меньше, и может оставаться в этом облике в течение 9 часов. Архидруид может выбрать, какое снаряжение останется лежать на земле, какое сольётся с новым обликом, а какое будет надето в новом облике. Архидруид возвращается в свой истинный облик, если он умрёт или потеряет сознание. Архидруид может вернуться в свой истинный облик бонусным действием в свой ход.

Находясь в новом облике, архидруид сохраняет свои игровые параметры и способность говорить, но его КД, способы передвижения, Сила и Ловкость заменяются таковыми для нового облика, и получает все особые чувства, мастерство, черты, действия и реакции (кроме особенностей класса, легендарных действий, и действий логова), который имеет новый облик и не имел друид. Он может сотворять свои заклинания с вербальными или соматическими компонентами в своем новом облике.

В новом облике атаки считаются магическими при определении сопротивления и иммунитета от немагических атак.`,
    },
  ],
}
