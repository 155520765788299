const {
  SOURCE_BGDIA,
  SOURCE_VGTM,
} = require('./../../../../sourceList')
const {
  ARMOR_TYPE_NATURAL,
} = require('./../../../../armorTypeList')
const {
  CR_3,
} = require('./../../../../crList')
const {
  SIZE_SMALL,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_FEY,
} = require('./../../../../creatureTypeIdList')
const {
  SKILL_ATHLETICS,
  SKILL_PERCEPTION,
} = require('./../../../../skillList')
const {
  LANG_COMMON,
  LANG_SYLVAN,
} = require('./../../../../languageIdList')
const {
  ALIGNMENT_CE,
} = require('./../../../../aligmentList')
const {
  SPEED_WALK,
} = require('./../../../../speedList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  CREATURE_REDCAP,
} = require('./../../../../creatureIdList')
const {
  PROF_NORMAL,
} = require('./../../../../proficiencyList')

module.exports = {
  name: 'Красный колпак',
  nameEn: 'Redcap',
  id: CREATURE_REDCAP,
  description: [
    {
      header: 'Красный колпак',
      text: `Красный колпак — это смертоносный фей, рождённый из жажды крови. Красные колпаки, хоть и маленькие, обладают недюжинной силой, которую используют для охоты и убийства без промедления и сожалений.`,
      source: {
        id: SOURCE_VGTM,
        page: 163,
      },
    },
    {
      header: 'Воплощённая жажда крови',
      text: `В Стране Фей, или там где этот план соприкасается с миром на перекрёстке фей, если разумное существо действует из сильного побуждения к кровопролитию, один или несколько красных колпаков появляются там, где кровь убитого впитывается в землю. Поначалу новые красные колпаки выглядят как крошечные окровавленные грибы, едва поднявшие свои шляпки над почвой. Когда лунный свет попадает на одну из этих шляпок, из земли возникает существо, которое выглядит как сморщенный и низкорослый гном со сгорбленной спиной и жилистым телом. У существа остроконечный кожаный колпак, штаны из похожего материала, тяжёлые железные сапоги и тяжелое клинковое оружие. С момента пробуждения красный колпак желает лишь убивать и резать, и он отправляется удовлетворять свои желания.

У красных колпаков нет утончённости. Они живут прямыми столкновениями и хаосом смертельной битвы. Даже если красный колпак хотел бы быть скрытным, его выдают тяжёлые гремящие шаги из-за железных ботинок. Хотя когда красный колпак подбирается к потенциальной добыче, он может быстро пойти на сближение и нанести злобный удар своим оружием до того, как цель отреагирует.`,
      source: {
        id: SOURCE_VGTM,
        page: 163,
      },
    },
    {
      header: 'Пропитанный резнёй',
      text: `Для поддержки своего противоестественного существования красный колпак должен смачивать свою шляпу в свежей крови своих жертв. Когда рождается красный колпак, его шляпа пропитана кровью и он знает, что если кровь не пополнять хотя бы раз в три дня, то он исчезнет словно его никогда и не существовало. Желание красного колпака убивать берёт свое начало от его воли к жизни.`,
      source: {
        id: SOURCE_VGTM,
        page: 163,
      },
    },
    {
      header: 'Кровожадные наёмники',
      text: `Красные колпаки обычно не работают группами, но в некоторых обстоятельствах их может нанять карга или тёмный маг, которые знают как призвать красных колпаков из Страны Фей и заставить работать на себя как страшных слуг.

Кроме того, некоторые красные колпаки могут почувствовать того, чьи убийства привели к их рождению. Красный колпак может использовать эту врождённую связь, чтобы найти создателя и сделать его своей первой жертвой. Другие же ищут создателя, чтобы насладиться близостью с родственной душой. Индивид, ответственный за создание нескольких красных колпаков на одном и том же месте, может привлечь всю группу на служение отрядом, чтобы они имитировали убийственную работу этого существа.

В любом случае, если красный колпак работает с другим существом, то он требует, чтобы ему платили жертвами. Покровитель, который пытается подавить естественную и необходимую жажду крови красного колпака, рискует стать его следующей целью.`,
      source: {
        id: SOURCE_VGTM,
        page: 163,
      },
    },
    {
      header: 'Вариант: Безумный колпак',
      text: `Безумный колпак — это Красный колпак, который искупал свой колпак в демоническом ихоре вместо крови. Контакт с ихором демона делает это существо ещё более ненормальным, и порождает в его голове совершенно иррациональную ненависть. Группа безумных колпаков иногда объединяется ненавистью к чему-то, например к музыке, существам с курчавыми волосами, слову «прохлада» или синему цвету.

Когда хиты безумного колпака опускаются до 0, его ненавистное существование заканчивается очень впечатляющим способом. Безумный колпак покрывается пламенем, уменьшаясь в размере, а его штаны и пропитанный ихором колпак рассыпается в пыль, оставляя лежать его оружие и дымящиеся кованые ботинки.`,
      source: {
        id: SOURCE_BGDIA,
        page: 241,
      },
    },
  ],
  note: {
    text: `Такие же утончённые, как взмах боевого топора.`,
    author: `Воло`,
  },
  sizeType: SIZE_SMALL,
  creatureTypeIdList: [
    CREATURE_TYPE_FEY,
  ],
  alignmentId: ALIGNMENT_CE,
  source: {
    id: SOURCE_VGTM,
    page: 163,
  },
  armor: {
    ac: 13,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 6,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  params: {
    [PARAM_STR]: 18,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 18,
    [PARAM_INT]: 10,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 9,
  },
  skillCollection: {
    [SKILL_ATHLETICS]: PROF_NORMAL,
    [SKILL_PERCEPTION]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    LANG_SYLVAN,
  ],
  cr: CR_3,
  featureList: [
    {
      name: 'Железные ботинки',
      description: `При передвижении ★СУЩЕСТВО★ получает помеху на проверки Ловкости (Скрытность).`,
    },
    {
      name: 'Нестандартная сила',
      description: `При захвате ★СУЩЕСТВО★ считается Среднего размера. Кроме того, использование тяжёлого оружия не даёт ★ему★ помеху на атаки.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ делает три атаки _Нечестивым серпом_.`,
    },
    {
      name: 'Нечестивый серп',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 6,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 4,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Кованое преследование',
      description: `★СУЩЕСТВО★ передвигается на расстояние не больше своей скорости к существу, которое может видеть, и пинает его железными ботинками. Цель должна пройти испытание Ловкости СЛ 14 или получить 20 (3к10 + 4) дробящего урона и быть Сбитой с ног. `,
    },
  ],
}
