const {GENDER_MALE} = require('./../../genderList')
const {CREATURE_TYPE_CERBERUS} = require('./../../creatureTypeIdList')
const {cerberiDescription} = require('./../../textCommonParts')

module.exports = {
  id: CREATURE_TYPE_CERBERUS,
  nameEn: 'Cerberus',
  genderId: GENDER_MALE,
  hideFromStatBlock: true,
  description: cerberiDescription,
  name: {
    singular: {
      nominative: 'цербер',
      genitive: 'цербера',
      dative: 'церберу',
      accusative: 'цербера',
      instrumental: 'цербером',
      prepositional: 'цербере',
    },
    plural: {
      nominative: 'церберы',
      genitive: 'церберов',
      dative: 'церберам',
      accusative: 'церберов',
      instrumental: 'церберами',
      prepositional: 'церберах',
    },
  },
}
