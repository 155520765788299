const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  SKILL_PERCEPTION,
  SKILL_PERFORMANCE,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_TABAXI,
} = require('./../../../../creatureTypeIdList')
const {
  ATTACK_MELEE_WEAPON,
} = require('./../../../../attackTypeList')
const {
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  LANG_ANY,
  LANG_COMMON,
} = require('./../../../../languageIdList')
const {
  PROF_DOUBLE,
  PROF_NORMAL,
} = require('./../../../../proficiencyList')
const {
  SPEED_WALK,
  SPEED_CLIMB,
} = require('./../../../../speedList')
const {ALIGNMENT_CG} = require('./../../../../aligmentList')
const {CR_1_4} = require('./../../../../crList')
const {CREATURE_TABAXI_MINSTREL} = require('./../../../../creatureIdList')
const {GEAR_DART} = require('./../../../../gearIdList')
const {SENSE_DARK_VISION} = require('./../../../../senseList')
const {SIZE_MEDIUM} = require('./../../../../sizeList')
const {SOURCE_TOA} = require('./../../../../sourceList')
const {tabaxiDescriptionList} = require('./../../../../textCommonParts')

module.exports = {
  name: 'Табакси-менестрель',
  nameEn: 'Tabaxi Minstrel',
  id: CREATURE_TABAXI_MINSTREL,
  description: tabaxiDescriptionList,
  sizeType: SIZE_MEDIUM,
  creatureTypeIdList: [
    CREATURE_TYPE_HUMANOID,
    CREATURE_TYPE_TABAXI,
  ],
  alignmentId: ALIGNMENT_CG,
  source: {
    id: SOURCE_TOA,
    page: 239,
  },
  armor: 12,
  hp: {
    diceCount: 5,
  },
  speed: {
    [SPEED_WALK]: 30,
    [SPEED_CLIMB]: 20,
  },
  params: {
    [PARAM_STR]: 10,
    [PARAM_DEX]: 15,
    [PARAM_CON]: 11,
    [PARAM_INT]: 14,
    [PARAM_WIT]: 12,
    [PARAM_CHA]: 16,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_PERFORMANCE]: PROF_DOUBLE,
    [SKILL_PERSUASION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  senseList: [
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_COMMON,
    {
      id: LANG_ANY,
      count: 2,
    },
  ],
  cr: CR_1_4,
  featureList: [
    {
      name: 'Кошачье проворство',
      description: `Когда ★СУЩЕСТВО★ двигается в бою в свой ход, он может удвоить свою скорость до конца хода. Использовав эту способность, ★СУЩЕСТВО★ не можете использовать её снова, до тех пор, пока не переместится на 0 футов за один из своих ходов.`,
    },
    {
      name: 'Вдохновение',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `В течение короткого отдыха ★СУЩЕСТВО★ может потратить 1 минуту на пение, игру на инструменте, рассказ истории или чтение стихотворения, чтобы успокоить и вдохновить других существ. До пяти существ по ★его★ выбору, которые могут видеть и слышать ★его★ выступление, получают 8 временных хитов в конце короткого отдыха.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `★СУЩЕСТВО★ совершает две атаки _Когтями_ или две — _Дротиком_.`,
    },
    {
      name: 'Когти',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 2,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
        },
      },
    },
    {
      gearId: GEAR_DART,
    },
  ],
}
