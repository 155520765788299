const {
  SOURCE_ERFTLW,
  SOURCE_MM,
} = require('./../../sourceList')
const {SPELL_CREATE_HOMUNCULUS} = require('./../../spellIdList')
const {
  PC_CLASS_ARTIFICER,
  PC_CLASS_WIZARD,
} = require('./../../pcClassIdList')

module.exports = [
  {
    header: 'Обмен сознаний',
    text: `Гомункул знает всё, что знает его создатель, включая языки, на которых тот может говорить и читать. Кроме того, всё, что ощущает конструкт, становится известно его хозяину, даже на невероятно далёких расстояниях, но только если они оба находятся на одном и том же плане. Действуя как шпион, разведчик, агент или посланец, гомункул это бесценный слуга для заклинателя, вовлечённого в секретный эксперимент или приключение.`,
    source: {
      id: SOURCE_MM,
      page: 69,
    },
  },
  {
    header: 'Гомункулы',
    text: `Гомункул — это слуга-конструкт, созданный для определённой задачи. Большинство существующих гомункулов создано [изобретателями](PC_CLASS:${PC_CLASS_ARTIFICER}) и [волшебниками](PC_CLASS:${PC_CLASS_WIZARD}).

Тела гомункулов создаются из разных материалов, включающих глину, железо и небольшое количество волос и перьев. Процесс создания гомункула выглядит как смешение этих материалов с кровью создателя и оживление [магическим ритуалом](SPELL:${SPELL_CREATE_HOMUNCULUS}).`,
    source: {
      id: SOURCE_ERFTLW,
      page: 294,
    },
  },
  {
    header: 'Природа конструктов',
    text: `Гомункулу не нужно дышать, есть, пить или спать.`,
    source: {
      id: SOURCE_ERFTLW,
      page: 294,
    },
  },
]
