const {
  SOURCE_DMG,
} = require('./../../../../../sourceList')
const {
  GENDER_MALE,
} = require('./../../../../../genderList')
const {
  MGC_TYPE_WONDROUS_ITEM,
} = require('./../../../../../magicItemTypeList')
const {
  MGC_RARITY_LEGENDARY,
} = require('./../../../../../magicItemRarityList')
const {
  MAGIC_ITEM_SOVEREIGN_GLUE,
  MAGIC_ITEM_UNIVERSAL_SOLVENT,
} = require('./../../../../../magicItemIdList')

module.exports = {
  id: MAGIC_ITEM_UNIVERSAL_SOLVENT,
  name: `Универсальный растворитель`,
  nameEn: `Universal Solvent`,
  type: MGC_TYPE_WONDROUS_ITEM,
  rarity: MGC_RARITY_LEGENDARY,
  isConsumable: true,
  note: [
    'Всего несколько капель могут растворить паутину самой Ллос.',
    {
      text: 'Несколько капель этого средства — и самые большие проблемы просто растворяются',
      author: 'Тамаз, изобретатель из Сварного Шва',
    },
  ],
  description: `В этом тюбике находится молочно-белая жидкость с сильным запахом спирта.

Вы можете действием вылить содержимое тюбика на поверхность в пределах досягаемости. Она мгновенно растворяет до 1 квадратного фута клейкой субстанции, которой касается, включая [превосходный клей](MAGIC_ITEM:${MAGIC_ITEM_SOVEREIGN_GLUE}).`,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_DMG,
    page: 211,
  },
}
