const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_DARKNESS,
  SPELL_DIVINE_WORD,
  SPELL_ENTANGLE,
  SPELL_FEAR,
  SPELL_HASTE,
  SPELL_POLYMORPH,
  SPELL_SUGGESTION,
} = require('./../../../../spellIdList')
const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../../../../paramList')
const {
  DAMAGE_ACID,
  DAMAGE_BLUDGEONING,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_SLASHING,
} = require('./../../../../damageTypeList')
const {
  SPEED_CLIMB,
  SPEED_WALK,
  SPEED_SWIM,
} = require('./../../../../speedList')
const {
  CREATURE_CULTIST,
  CREATURE_YUAN_TI_ABOMINATION,
  CREATURE_YUAN_TI_ANATHEMA,
} = require('./../../../../creatureIdList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../../../languageIdList')
const {
  SKILL_PERCEPTION,
  SKILL_STEALTH,
} = require('./../../../../skillList')
const {
  SIZE_HUGE,
  SIZE_LARGE,
} = require('./../../../../sizeList')
const {
  CREATURE_TYPE_SHAPESHIFTER,
  CREATURE_TYPE_YUAN_TI,
} = require('./../../../../creatureTypeIdList')
const {
  SENSE_BLIND_VISION,
  SENSE_DARK_VISION,
} = require('./../../../../senseList')
const {
  yuantiDescriptionList,
  yuantiNoteList,
} = require('./../../../../textCommonParts')
const {ABILITY_MAGIC_RESISTANCE} = require('./../../../../creatureAbilityList')
const {ALIGNMENT_NE} = require('./../../../../aligmentList')
const {ARMOR_TYPE_NATURAL} = require('./../../../../armorTypeList')
const {ATTACK_MELEE_WEAPON} = require('./../../../../attackTypeList')
const {CAST_MATERIAL} = require('./../../../../castComponentList')
const {CONDITION_POISONED} = require('./../../../../conditionList')
const {CR_12} = require('./../../../../crList')
const {GENDER_FEMALE} = require('./../../../../genderList')
const {PROF_NORMAL} = require('./../../../../proficiencyList')
const {SOURCE_VGTM} = require('./../../../../sourceList')
const {TARGET_CREATURE} = require('./../../../../targetList')

module.exports = {
  name: 'Юань-ти анафема',
  nameEn: 'Yuan-ti Anathema',
  id: CREATURE_YUAN_TI_ANATHEMA,
  description: [
    `Поиск [отродьем юань-ти](CREATURE:${CREATURE_YUAN_TI_ABOMINATION}) божественной мощи может привести его к совершению ритуала, который, в случае успеха, трансформирует его в высшую форму: юань-ти анафему. Этот ритуал требует жертвоприношения сотен змей и требует, чтобы [отродье](CREATURE:${CREATURE_YUAN_TI_ABOMINATION}) искупалось в крови своих врагов. Преобразование быстро, но болезненно.

Не все юань-ти хотят видеть в своих рядах анафему, ведь анафемы жестоко подчиняют себе нижестоящих для достижения собственных, злых целей.`,
    {
      header: 'Не совсем божество',
      text: `Анафемы считают себя полубогами, движущимися к полной божественности. Они требуют поклонения от более слабых юань-ти и используют все доступные ресурсы для мелкомасштабных войн с соседями. Каждое завоевание приносит новых рабов и жертвы, а также славу и богатство, которые анафема считает необходимым для достижения истинной божественности.

Наиболее преданные последователи анафемы видят в ней вершину змеиной формы, невероятное улучшение уже и так почти идеального [отродья](CREATURE:${CREATURE_YUAN_TI_ABOMINATION}). Приверженцы из числа людей считают её «божественной плотью в смертном теле». При присутствии анафемы [культисты](CREATURE:${CREATURE_CULTIST}), служащие ей, более кровожадны и готовы к самопожертвованию.`,
      source: {
        id: SOURCE_VGTM,
        page: 204,
      },
    },
    {
      header: 'Бессмертный',
      text: `Анафемы не стареют, что позволяет им преследовать свои цели вплоть до конца света. Поистине влиятельные особи управляют несколькими городами юань-ти и могут покорять целые регионы, которые включают области, заселенные гуманоидами.`,
      source: {
        id: SOURCE_VGTM,
        page: 204,
      },
    },
    ...yuantiDescriptionList,
  ],
  note: yuantiNoteList,
  sizeType: SIZE_HUGE,
  creatureTypeIdList: [
    CREATURE_TYPE_SHAPESHIFTER,
    CREATURE_TYPE_YUAN_TI,
  ],
  alignmentId: ALIGNMENT_NE,
  source: {
    id: SOURCE_VGTM,
    page: 204,
  },
  armor: {
    ac: 16,
    type: ARMOR_TYPE_NATURAL,
  },
  hp: {
    diceCount: 18,
  },
  speed: {
    [SPEED_WALK]: 40,
    [SPEED_CLIMB]: 30,
    [SPEED_SWIM]: 30,
  },
  params: {
    [PARAM_STR]: 23,
    [PARAM_DEX]: 13,
    [PARAM_CON]: 19,
    [PARAM_INT]: 19,
    [PARAM_WIT]: 17,
    [PARAM_CHA]: 20,
  },
  skillCollection: {
    [SKILL_PERCEPTION]: PROF_NORMAL,
    [SKILL_STEALTH]: PROF_NORMAL,
  },
  resistanceList: [
    DAMAGE_ACID,
    DAMAGE_ELECTRICITY,
    DAMAGE_FIRE,
  ],
  immunityList: [
    DAMAGE_POISON,
  ],
  immunityConditionList: [
    CONDITION_POISONED,
  ],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
    },
    {
      id: SENSE_DARK_VISION,
      value: 60,
    },
  ],
  languageList: [
    LANG_ABYSSAL,
    LANG_COMMON,
    LANG_DRACONIC,
  ],
  cr: CR_12,
  featureList: [
    ABILITY_MAGIC_RESISTANCE,
    {
      name: 'Аура офидиофобии',
      description: `Любое существо по выбору анафемы, кроме змей и юань-ти, которое начинает свой ход не далее чем в 30 футах от анафемы и может слышать или видеть её, должно пройти испытание Мудрости СЛ 17 или стать испуганным змеями и юань-ти. Испуганное существо может повторять испытание в конце каждого своего хода, окончивая эффект на себе при успехе. Если испытание цели успешно или эффект окончен, то цель получает иммунитет к этой ауре на следующие 24 часа.`,
    },
    {
      name: 'Перевёртыш',
      description: `Анафема может использовать действие, чтобы превратиться в Огромного удава или обратно в свою истинную форму. Её характеристики одинаковы в любой форме. Любое снаряжение, которое она несёт или носит, не трансформируется.`,
    },
    {
      name: 'Шесть голов',
      description: `Анафема имеет преимущество на проверки Мудрости (Внимательность) и на испытания от ослепления, очарования, ошеломления, оглушения, испуга или потери сознания.`,
    },
  ],
  spellCast: {
    comment: 'только в форме анафемы',
    baseStat: PARAM_CHA,
    componentExclude: CAST_MATERIAL,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_ANIMAL_FRIENDSHIP,
            comment: 'только змеи',
          },
        ],
      },
      {
        limit: {
          count: 3,
          period: 'день',
        },
        list: [
          SPELL_DARKNESS,
          SPELL_ENTANGLE,
          SPELL_FEAR,
          SPELL_HASTE,
          SPELL_SUGGESTION,
          SPELL_POLYMORPH,
        ],
      },
      {
        limit: {
          count: 1,
          period: 'день',
        },
        list: [
          SPELL_DIVINE_WORD,
        ],
      },
    ],
  },
  actionList: [
    {
      name: 'Мультиатака',
      comment: 'только в форме анафемы',
      description: `Анафема делает две атаки _Когтями_, одну атаку _Удушением_ и одну атаку _Шквалом укусов_.`,
    },
    {
      name: 'Когти',
      comment: 'только в форме анафемы',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 2,
          diceType: 6,
          diceBonus: 6,
        },
      },
    },
    {
      name: 'Сжимание',
      description: `Цель схвачена (СЛ высвобождения 16). До конца захвата цель обездвижена и получает 16 (3к6 + 6) дробящего урона плюс 7 (2к6) урона кислотой в начале каждого своего хода, и анафема не может сжимать другую цель`,
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 15,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
            size: {
              max: SIZE_LARGE,
            },
          },
        },
        hit: [
          {
            type: DAMAGE_BLUDGEONING,
            diceCount: 3,
            diceType: 6,
            diceBonus: 6,
          },
          {
            type: DAMAGE_ACID,
            diceCount: 2,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
    {
      name: 'Шквал укусов',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 10,
        range: 10,
        target: {
          count: 1,
          limit: {
            type: TARGET_CREATURE,
          },
        },
        hit: [
          {
            type: DAMAGE_PIERCING,
            diceCount: 6,
            diceType: 6,
            diceBonus: 6,
          },
          {
            type: DAMAGE_POISON,
            diceCount: 4,
            diceType: 6,
            diceBonus: 0,
          },
        ],
      },
    },
  ],
  genderId: GENDER_FEMALE,
}
