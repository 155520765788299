const {
  CREATURE_CLAY_CONSTRUCT_SPIRIT,
  CREATURE_METAL_CONSTRUCT_SPIRIT,
} = require('./../../../../creatureIdList')
const {
  ABILITY_HEATED_BODY,
} = require('./../../../../creatureAbilityList')

module.exports = {
  name: 'Дух металлического конструкта',
  nameEn: 'Metal Construct Spirit',
  id: CREATURE_METAL_CONSTRUCT_SPIRIT,
  parentId: CREATURE_CLAY_CONSTRUCT_SPIRIT,
  featureList: [
    {
      id: ABILITY_HEATED_BODY,
      damage: `5 (1к10)`,
    },
  ],
  reactionList: [],
}
